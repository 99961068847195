const UnitsSpišskáNováVes = {
  Vojkovce: [
    { lat: 48.945658, lng: 20.888223 },
    { lat: 48.945886, lng: 20.888427 },
    { lat: 48.947331, lng: 20.888578 },
    { lat: 48.947466, lng: 20.888566 },
    { lat: 48.948248, lng: 20.888523 },
    { lat: 48.9487534, lng: 20.8886219 },
    { lat: 48.949112, lng: 20.888692 },
    { lat: 48.949173, lng: 20.888705 },
    { lat: 48.949629, lng: 20.888795 },
    { lat: 48.949721, lng: 20.888357 },
    { lat: 48.949812, lng: 20.888137 },
    { lat: 48.949973, lng: 20.887962 },
    { lat: 48.950129, lng: 20.887437 },
    { lat: 48.950252, lng: 20.887121 },
    { lat: 48.950511, lng: 20.886365 },
    { lat: 48.950719, lng: 20.88631 },
    { lat: 48.950846, lng: 20.886239 },
    { lat: 48.951352, lng: 20.885599 },
    { lat: 48.952062, lng: 20.884699 },
    { lat: 48.953481, lng: 20.884753 },
    { lat: 48.95438, lng: 20.884962 },
    { lat: 48.956277, lng: 20.887073 },
    { lat: 48.957769, lng: 20.888781 },
    { lat: 48.958947, lng: 20.890077 },
    { lat: 48.959625, lng: 20.889836 },
    { lat: 48.960872, lng: 20.892437 },
    { lat: 48.960961, lng: 20.89304 },
    { lat: 48.961244, lng: 20.894488 },
    { lat: 48.961383, lng: 20.894947 },
    { lat: 48.961971, lng: 20.896098 },
    { lat: 48.962036, lng: 20.89639 },
    { lat: 48.962118, lng: 20.896494 },
    { lat: 48.96315, lng: 20.899107 },
    { lat: 48.963717, lng: 20.898804 },
    { lat: 48.96435, lng: 20.898697 },
    { lat: 48.9646, lng: 20.898655 },
    { lat: 48.964871, lng: 20.898671 },
    { lat: 48.9649111, lng: 20.8986381 },
    { lat: 48.964943, lng: 20.898612 },
    { lat: 48.965048, lng: 20.898526 },
    { lat: 48.966374, lng: 20.897719 },
    { lat: 48.966116, lng: 20.897141 },
    { lat: 48.966716, lng: 20.895764 },
    { lat: 48.967516, lng: 20.895661 },
    { lat: 48.967972, lng: 20.89505 },
    { lat: 48.96781, lng: 20.89396 },
    { lat: 48.968249, lng: 20.893117 },
    { lat: 48.967117, lng: 20.89239 },
    { lat: 48.967154, lng: 20.890551 },
    { lat: 48.966863, lng: 20.890081 },
    { lat: 48.967482, lng: 20.888456 },
    { lat: 48.968785, lng: 20.886419 },
    { lat: 48.968943, lng: 20.886338 },
    { lat: 48.969, lng: 20.886243 },
    { lat: 48.969059, lng: 20.886149 },
    { lat: 48.969241, lng: 20.885852 },
    { lat: 48.970031, lng: 20.884467 },
    { lat: 48.970065, lng: 20.884409 },
    { lat: 48.971456, lng: 20.881966 },
    { lat: 48.972522, lng: 20.881272 },
    { lat: 48.973128, lng: 20.880254 },
    { lat: 48.97538, lng: 20.877237 },
    { lat: 48.975171, lng: 20.876613 },
    { lat: 48.975151, lng: 20.876552 },
    { lat: 48.973898, lng: 20.872807 },
    { lat: 48.9733711, lng: 20.8722675 },
    { lat: 48.9708771, lng: 20.8717157 },
    { lat: 48.9680167, lng: 20.8706462 },
    { lat: 48.9664952, lng: 20.8695359 },
    { lat: 48.9656021, lng: 20.8696566 },
    { lat: 48.9646711, lng: 20.8693505 },
    { lat: 48.9638133, lng: 20.8665769 },
    { lat: 48.9637885, lng: 20.8659147 },
    { lat: 48.9637003, lng: 20.8657676 },
    { lat: 48.9636562, lng: 20.8652362 },
    { lat: 48.9631359, lng: 20.8647674 },
    { lat: 48.9625677, lng: 20.8637508 },
    { lat: 48.9624732, lng: 20.8638513 },
    { lat: 48.9621485, lng: 20.8625196 },
    { lat: 48.9619097, lng: 20.8619234 },
    { lat: 48.9615377, lng: 20.8613076 },
    { lat: 48.9616019, lng: 20.8607491 },
    { lat: 48.9617308, lng: 20.8602407 },
    { lat: 48.9614831, lng: 20.8600406 },
    { lat: 48.9608161, lng: 20.8600899 },
    { lat: 48.9606604, lng: 20.8592884 },
    { lat: 48.9605952, lng: 20.8585678 },
    { lat: 48.9605536, lng: 20.8584339 },
    { lat: 48.9604039, lng: 20.8584357 },
    { lat: 48.9602679, lng: 20.8577149 },
    { lat: 48.9602451, lng: 20.8559648 },
    { lat: 48.9598702, lng: 20.8556301 },
    { lat: 48.9596902, lng: 20.8555676 },
    { lat: 48.9595395, lng: 20.8552439 },
    { lat: 48.9590169, lng: 20.854937 },
    { lat: 48.9581205, lng: 20.8548934 },
    { lat: 48.9574314, lng: 20.8541928 },
    { lat: 48.9572654, lng: 20.854146 },
    { lat: 48.9568611, lng: 20.8537102 },
    { lat: 48.9567425, lng: 20.8534695 },
    { lat: 48.9568032, lng: 20.8534379 },
    { lat: 48.9566063, lng: 20.8531425 },
    { lat: 48.9565221, lng: 20.8525596 },
    { lat: 48.9561799, lng: 20.8530838 },
    { lat: 48.9562151, lng: 20.8531884 },
    { lat: 48.9561694, lng: 20.8534653 },
    { lat: 48.9559095, lng: 20.8535367 },
    { lat: 48.955908, lng: 20.8538839 },
    { lat: 48.9558357, lng: 20.8544054 },
    { lat: 48.9557251, lng: 20.8545656 },
    { lat: 48.9552138, lng: 20.8547191 },
    { lat: 48.9550349, lng: 20.8546516 },
    { lat: 48.9543629, lng: 20.8548858 },
    { lat: 48.9540025, lng: 20.8552051 },
    { lat: 48.9537985, lng: 20.8554959 },
    { lat: 48.9536135, lng: 20.8558113 },
    { lat: 48.9534799, lng: 20.8562824 },
    { lat: 48.9532153, lng: 20.8553245 },
    { lat: 48.9531594, lng: 20.8549747 },
    { lat: 48.953168, lng: 20.8513796 },
    { lat: 48.952671, lng: 20.8513941 },
    { lat: 48.9520344, lng: 20.8514082 },
    { lat: 48.9516565, lng: 20.8516139 },
    { lat: 48.9516125, lng: 20.8517757 },
    { lat: 48.9509567, lng: 20.8520542 },
    { lat: 48.9507986, lng: 20.8522335 },
    { lat: 48.9505258, lng: 20.8522895 },
    { lat: 48.9503763, lng: 20.8524907 },
    { lat: 48.9501982, lng: 20.8526144 },
    { lat: 48.9500623, lng: 20.852629 },
    { lat: 48.9499717, lng: 20.8524485 },
    { lat: 48.949806, lng: 20.8523416 },
    { lat: 48.9497993, lng: 20.8522419 },
    { lat: 48.9492891, lng: 20.8515108 },
    { lat: 48.9490526, lng: 20.85108 },
    { lat: 48.9490344, lng: 20.8509056 },
    { lat: 48.9489177, lng: 20.8507221 },
    { lat: 48.9487365, lng: 20.8506244 },
    { lat: 48.9486502, lng: 20.8505026 },
    { lat: 48.9485991, lng: 20.8501328 },
    { lat: 48.9485852, lng: 20.8501536 },
    { lat: 48.9481968, lng: 20.8507366 },
    { lat: 48.9480264, lng: 20.8509924 },
    { lat: 48.947609, lng: 20.8521809 },
    { lat: 48.9473983, lng: 20.8530337 },
    { lat: 48.9470884, lng: 20.8537074 },
    { lat: 48.9467579, lng: 20.8553976 },
    { lat: 48.9466395, lng: 20.8553271 },
    { lat: 48.9465347, lng: 20.8556831 },
    { lat: 48.9455839, lng: 20.8566136 },
    { lat: 48.9453217, lng: 20.8570177 },
    { lat: 48.9447487, lng: 20.8575736 },
    { lat: 48.9445222, lng: 20.8579248 },
    { lat: 48.9441596, lng: 20.8586119 },
    { lat: 48.9438545, lng: 20.859534 },
    { lat: 48.9437195, lng: 20.8597992 },
    { lat: 48.9431773, lng: 20.8606655 },
    { lat: 48.9426999, lng: 20.8612571 },
    { lat: 48.9424854, lng: 20.8618619 },
    { lat: 48.9423569, lng: 20.862485 },
    { lat: 48.9419378, lng: 20.8637222 },
    { lat: 48.9417135, lng: 20.8641463 },
    { lat: 48.9413116, lng: 20.864705 },
    { lat: 48.940863, lng: 20.8651137 },
    { lat: 48.9399814, lng: 20.8661312 },
    { lat: 48.9402738, lng: 20.8669464 },
    { lat: 48.9403184, lng: 20.8669679 },
    { lat: 48.9405878, lng: 20.8687238 },
    { lat: 48.9408237, lng: 20.8696033 },
    { lat: 48.9409266, lng: 20.8702422 },
    { lat: 48.941768, lng: 20.8716503 },
    { lat: 48.9418959, lng: 20.8718643 },
    { lat: 48.9419092, lng: 20.8720273 },
    { lat: 48.9422975, lng: 20.8728178 },
    { lat: 48.9419667, lng: 20.8731542 },
    { lat: 48.9416209, lng: 20.8737063 },
    { lat: 48.941563, lng: 20.8740571 },
    { lat: 48.9412331, lng: 20.8746838 },
    { lat: 48.9409256, lng: 20.8750391 },
    { lat: 48.9403896, lng: 20.8762273 },
    { lat: 48.9401252, lng: 20.8765234 },
    { lat: 48.9396826, lng: 20.8781024 },
    { lat: 48.9395286, lng: 20.8782402 },
    { lat: 48.939241, lng: 20.8792502 },
    { lat: 48.9411015, lng: 20.8802415 },
    { lat: 48.9415587, lng: 20.8806816 },
    { lat: 48.9417934, lng: 20.8810928 },
    { lat: 48.9412681, lng: 20.8818322 },
    { lat: 48.9412105, lng: 20.8822549 },
    { lat: 48.9420251, lng: 20.8829753 },
    { lat: 48.941802, lng: 20.8841632 },
    { lat: 48.9425363, lng: 20.8847044 },
    { lat: 48.942358, lng: 20.8852555 },
    { lat: 48.9430511, lng: 20.8859732 },
    { lat: 48.9442927, lng: 20.8875361 },
    { lat: 48.9448397, lng: 20.8876989 },
    { lat: 48.9451968, lng: 20.8875091 },
    { lat: 48.945658, lng: 20.888223 },
  ],
  Vítkovce: [
    { lat: 48.9255969, lng: 20.7118125 },
    { lat: 48.9255809, lng: 20.7118085 },
    { lat: 48.9253181, lng: 20.7117283 },
    { lat: 48.9254278, lng: 20.7107885 },
    { lat: 48.9254101, lng: 20.7102899 },
    { lat: 48.9253374, lng: 20.7101158 },
    { lat: 48.9245241, lng: 20.7089221 },
    { lat: 48.9240197, lng: 20.7085087 },
    { lat: 48.9238117, lng: 20.7084574 },
    { lat: 48.9236799, lng: 20.7082015 },
    { lat: 48.9233277, lng: 20.7079825 },
    { lat: 48.9231025, lng: 20.7073785 },
    { lat: 48.9224695, lng: 20.7071446 },
    { lat: 48.9222977, lng: 20.7068432 },
    { lat: 48.9220248, lng: 20.7067968 },
    { lat: 48.9219654, lng: 20.7066478 },
    { lat: 48.9218382, lng: 20.7065855 },
    { lat: 48.9213226, lng: 20.706865 },
    { lat: 48.9211287, lng: 20.7067008 },
    { lat: 48.9211198, lng: 20.7065763 },
    { lat: 48.9210535, lng: 20.7065165 },
    { lat: 48.9209769, lng: 20.7065769 },
    { lat: 48.9207103, lng: 20.7065571 },
    { lat: 48.9205533, lng: 20.7067003 },
    { lat: 48.9204517, lng: 20.7066885 },
    { lat: 48.9201328, lng: 20.7070278 },
    { lat: 48.9194273, lng: 20.708624 },
    { lat: 48.9186741, lng: 20.7098861 },
    { lat: 48.9184727, lng: 20.7108207 },
    { lat: 48.9180366, lng: 20.7123889 },
    { lat: 48.9141307, lng: 20.7164627 },
    { lat: 48.9119055, lng: 20.718279 },
    { lat: 48.910544, lng: 20.7207807 },
    { lat: 48.9087954, lng: 20.7206104 },
    { lat: 48.9086716, lng: 20.7204987 },
    { lat: 48.9052835, lng: 20.7174431 },
    { lat: 48.9045178, lng: 20.7169323 },
    { lat: 48.9035406, lng: 20.7164165 },
    { lat: 48.902679, lng: 20.7165663 },
    { lat: 48.9025437, lng: 20.716622 },
    { lat: 48.90245, lng: 20.7167402 },
    { lat: 48.9024408, lng: 20.7180968 },
    { lat: 48.9026637, lng: 20.7187717 },
    { lat: 48.9031046, lng: 20.7198436 },
    { lat: 48.9024439, lng: 20.7216883 },
    { lat: 48.9032795, lng: 20.7220181 },
    { lat: 48.9037743, lng: 20.722794 },
    { lat: 48.9041995, lng: 20.7230092 },
    { lat: 48.9051079, lng: 20.7237042 },
    { lat: 48.9052402, lng: 20.723561 },
    { lat: 48.9054001, lng: 20.7235952 },
    { lat: 48.9057825, lng: 20.723461 },
    { lat: 48.9064084, lng: 20.7234891 },
    { lat: 48.906831, lng: 20.7234061 },
    { lat: 48.9068851, lng: 20.7235359 },
    { lat: 48.9069827, lng: 20.7235261 },
    { lat: 48.9081305, lng: 20.7229792 },
    { lat: 48.9082397, lng: 20.7230376 },
    { lat: 48.9083666, lng: 20.7229632 },
    { lat: 48.9086209, lng: 20.72309 },
    { lat: 48.9088332, lng: 20.723331 },
    { lat: 48.9089488, lng: 20.723352 },
    { lat: 48.9095099, lng: 20.7238736 },
    { lat: 48.9104522, lng: 20.7241655 },
    { lat: 48.9109478, lng: 20.7245256 },
    { lat: 48.9110201, lng: 20.7246897 },
    { lat: 48.9113563, lng: 20.7249966 },
    { lat: 48.9114013, lng: 20.7251539 },
    { lat: 48.9114832, lng: 20.7251484 },
    { lat: 48.9116531, lng: 20.7253657 },
    { lat: 48.9117135, lng: 20.7253569 },
    { lat: 48.9120496, lng: 20.7258173 },
    { lat: 48.9124392, lng: 20.7263592 },
    { lat: 48.9128333, lng: 20.726654 },
    { lat: 48.9132074, lng: 20.7268526 },
    { lat: 48.9135515, lng: 20.7273517 },
    { lat: 48.9138696, lng: 20.7276279 },
    { lat: 48.914285, lng: 20.7282004 },
    { lat: 48.9144612, lng: 20.7283142 },
    { lat: 48.9145117, lng: 20.7285005 },
    { lat: 48.9146347, lng: 20.7286315 },
    { lat: 48.915006, lng: 20.7293927 },
    { lat: 48.915114, lng: 20.7294243 },
    { lat: 48.9153292, lng: 20.7301015 },
    { lat: 48.9154746, lng: 20.7308377 },
    { lat: 48.9156724, lng: 20.7311488 },
    { lat: 48.9157987, lng: 20.7315999 },
    { lat: 48.9161372, lng: 20.7318716 },
    { lat: 48.9163717, lng: 20.7322684 },
    { lat: 48.9165398, lng: 20.7329129 },
    { lat: 48.9175376, lng: 20.7338895 },
    { lat: 48.9177492, lng: 20.7341694 },
    { lat: 48.9179749, lng: 20.7347291 },
    { lat: 48.9180164, lng: 20.7352369 },
    { lat: 48.9179933, lng: 20.735631 },
    { lat: 48.918401, lng: 20.7366896 },
    { lat: 48.9184052, lng: 20.736937 },
    { lat: 48.9184984, lng: 20.7373782 },
    { lat: 48.9187256, lng: 20.7380749 },
    { lat: 48.9188448, lng: 20.7381991 },
    { lat: 48.9201598, lng: 20.7389386 },
    { lat: 48.9212863, lng: 20.7390551 },
    { lat: 48.9226022, lng: 20.7393142 },
    { lat: 48.9234562, lng: 20.7393344 },
    { lat: 48.923572, lng: 20.7393608 },
    { lat: 48.9236703, lng: 20.739467 },
    { lat: 48.9245204, lng: 20.7394381 },
    { lat: 48.9257806, lng: 20.7395789 },
    { lat: 48.9275874, lng: 20.7381772 },
    { lat: 48.9283856, lng: 20.737285 },
    { lat: 48.9285761, lng: 20.7372617 },
    { lat: 48.9291883, lng: 20.7376068 },
    { lat: 48.9296362, lng: 20.7376986 },
    { lat: 48.9312785, lng: 20.7370569 },
    { lat: 48.931434, lng: 20.7370647 },
    { lat: 48.9316939, lng: 20.7373096 },
    { lat: 48.9317793, lng: 20.7373302 },
    { lat: 48.9319811, lng: 20.737379 },
    { lat: 48.9321434, lng: 20.7373537 },
    { lat: 48.9324388, lng: 20.7372087 },
    { lat: 48.9331913, lng: 20.7368393 },
    { lat: 48.9334439, lng: 20.7367729 },
    { lat: 48.933806, lng: 20.7366777 },
    { lat: 48.9339655, lng: 20.7364452 },
    { lat: 48.9342666, lng: 20.736274 },
    { lat: 48.9344256, lng: 20.7360537 },
    { lat: 48.9347837, lng: 20.735866 },
    { lat: 48.9350269, lng: 20.7356132 },
    { lat: 48.9353025, lng: 20.7351594 },
    { lat: 48.9357454, lng: 20.7348831 },
    { lat: 48.9362474, lng: 20.7345698 },
    { lat: 48.9367056, lng: 20.7343439 },
    { lat: 48.9367356, lng: 20.7343991 },
    { lat: 48.9379418, lng: 20.7330113 },
    { lat: 48.9382964, lng: 20.7327243 },
    { lat: 48.9389643, lng: 20.7323195 },
    { lat: 48.9389633, lng: 20.7322902 },
    { lat: 48.9386987, lng: 20.731042 },
    { lat: 48.9385227, lng: 20.7303609 },
    { lat: 48.9384361, lng: 20.7302068 },
    { lat: 48.9383262, lng: 20.7294194 },
    { lat: 48.9379991, lng: 20.7282859 },
    { lat: 48.9380936, lng: 20.7282492 },
    { lat: 48.9375917, lng: 20.7267303 },
    { lat: 48.9375468, lng: 20.7263809 },
    { lat: 48.9373702, lng: 20.7257815 },
    { lat: 48.9372606, lng: 20.7251298 },
    { lat: 48.9372863, lng: 20.7247428 },
    { lat: 48.9370726, lng: 20.7238579 },
    { lat: 48.9372661, lng: 20.7233108 },
    { lat: 48.9372336, lng: 20.7221074 },
    { lat: 48.9372534, lng: 20.7203445 },
    { lat: 48.9372342, lng: 20.720249 },
    { lat: 48.9370444, lng: 20.7200053 },
    { lat: 48.9363599, lng: 20.719461 },
    { lat: 48.9364728, lng: 20.7189981 },
    { lat: 48.9358007, lng: 20.7186125 },
    { lat: 48.9359646, lng: 20.7157393 },
    { lat: 48.9355912, lng: 20.7145091 },
    { lat: 48.9343953, lng: 20.7141846 },
    { lat: 48.9321249, lng: 20.7127582 },
    { lat: 48.9314495, lng: 20.7140349 },
    { lat: 48.9303833, lng: 20.7130111 },
    { lat: 48.9301167, lng: 20.7130497 },
    { lat: 48.9300147, lng: 20.712429 },
    { lat: 48.9289483, lng: 20.7124187 },
    { lat: 48.9286944, lng: 20.7128146 },
    { lat: 48.9280251, lng: 20.7134661 },
    { lat: 48.9265112, lng: 20.7138213 },
    { lat: 48.9262349, lng: 20.7133928 },
    { lat: 48.9258707, lng: 20.7125817 },
    { lat: 48.9255969, lng: 20.7118125 },
  ],
  ChrasťnadHornádom: [
    { lat: 48.8961768, lng: 20.6890685 },
    { lat: 48.8954728, lng: 20.6902891 },
    { lat: 48.8945124, lng: 20.6917689 },
    { lat: 48.8942773, lng: 20.6930032 },
    { lat: 48.8941463, lng: 20.6934314 },
    { lat: 48.8938863, lng: 20.6940048 },
    { lat: 48.8938329, lng: 20.6948753 },
    { lat: 48.8938557, lng: 20.6950717 },
    { lat: 48.8942406, lng: 20.6958243 },
    { lat: 48.8945121, lng: 20.695957 },
    { lat: 48.8947774, lng: 20.696298 },
    { lat: 48.8953687, lng: 20.6965533 },
    { lat: 48.8955329, lng: 20.6968379 },
    { lat: 48.8957256, lng: 20.6969684 },
    { lat: 48.8956379, lng: 20.6974414 },
    { lat: 48.8958933, lng: 20.6979479 },
    { lat: 48.8961598, lng: 20.6981583 },
    { lat: 48.8967095, lng: 20.6991139 },
    { lat: 48.8971286, lng: 20.6990911 },
    { lat: 48.8972398, lng: 20.6992133 },
    { lat: 48.8976968, lng: 20.7009862 },
    { lat: 48.897713, lng: 20.7015985 },
    { lat: 48.8978865, lng: 20.7017289 },
    { lat: 48.8982987, lng: 20.7024605 },
    { lat: 48.8987141, lng: 20.7029052 },
    { lat: 48.898885, lng: 20.7032776 },
    { lat: 48.8981454, lng: 20.7047289 },
    { lat: 48.8981109, lng: 20.7049657 },
    { lat: 48.8977847, lng: 20.7054196 },
    { lat: 48.8977662, lng: 20.7059417 },
    { lat: 48.8979189, lng: 20.7065339 },
    { lat: 48.8980886, lng: 20.7067408 },
    { lat: 48.8982017, lng: 20.7070109 },
    { lat: 48.8983928, lng: 20.7082397 },
    { lat: 48.8984832, lng: 20.7084738 },
    { lat: 48.8984767, lng: 20.7087352 },
    { lat: 48.8985845, lng: 20.7091064 },
    { lat: 48.898609, lng: 20.7099563 },
    { lat: 48.8991165, lng: 20.7102973 },
    { lat: 48.8994901, lng: 20.7109259 },
    { lat: 48.8998229, lng: 20.7127528 },
    { lat: 48.9000259, lng: 20.7132755 },
    { lat: 48.9003003, lng: 20.7135504 },
    { lat: 48.9006952, lng: 20.7137697 },
    { lat: 48.9010303, lng: 20.7142471 },
    { lat: 48.9015473, lng: 20.7163959 },
    { lat: 48.9019417, lng: 20.7164428 },
    { lat: 48.90245, lng: 20.7167402 },
    { lat: 48.9025437, lng: 20.716622 },
    { lat: 48.902679, lng: 20.7165663 },
    { lat: 48.9035406, lng: 20.7164165 },
    { lat: 48.9045178, lng: 20.7169323 },
    { lat: 48.9052835, lng: 20.7174431 },
    { lat: 48.9086716, lng: 20.7204987 },
    { lat: 48.9087954, lng: 20.7206104 },
    { lat: 48.910544, lng: 20.7207807 },
    { lat: 48.9119055, lng: 20.718279 },
    { lat: 48.9141307, lng: 20.7164627 },
    { lat: 48.9180366, lng: 20.7123889 },
    { lat: 48.9184727, lng: 20.7108207 },
    { lat: 48.9186741, lng: 20.7098861 },
    { lat: 48.9194273, lng: 20.708624 },
    { lat: 48.9201328, lng: 20.7070278 },
    { lat: 48.9204517, lng: 20.7066885 },
    { lat: 48.9205533, lng: 20.7067003 },
    { lat: 48.9207103, lng: 20.7065571 },
    { lat: 48.9209769, lng: 20.7065769 },
    { lat: 48.9210535, lng: 20.7065165 },
    { lat: 48.9211198, lng: 20.7065763 },
    { lat: 48.9211287, lng: 20.7067008 },
    { lat: 48.9213226, lng: 20.706865 },
    { lat: 48.9218382, lng: 20.7065855 },
    { lat: 48.9219654, lng: 20.7066478 },
    { lat: 48.9220248, lng: 20.7067968 },
    { lat: 48.9222977, lng: 20.7068432 },
    { lat: 48.9224695, lng: 20.7071446 },
    { lat: 48.9231025, lng: 20.7073785 },
    { lat: 48.9233277, lng: 20.7079825 },
    { lat: 48.9236799, lng: 20.7082015 },
    { lat: 48.9238117, lng: 20.7084574 },
    { lat: 48.9240197, lng: 20.7085087 },
    { lat: 48.9245241, lng: 20.7089221 },
    { lat: 48.9253374, lng: 20.7101158 },
    { lat: 48.9254101, lng: 20.7102899 },
    { lat: 48.9254278, lng: 20.7107885 },
    { lat: 48.9253181, lng: 20.7117283 },
    { lat: 48.9255809, lng: 20.7118085 },
    { lat: 48.9255969, lng: 20.7118125 },
    { lat: 48.9260695, lng: 20.7119941 },
    { lat: 48.9264906, lng: 20.7116833 },
    { lat: 48.9267718, lng: 20.7113911 },
    { lat: 48.9273806, lng: 20.7111889 },
    { lat: 48.9274849, lng: 20.7107726 },
    { lat: 48.9279391, lng: 20.7096708 },
    { lat: 48.9289815, lng: 20.7094509 },
    { lat: 48.9292318, lng: 20.7085405 },
    { lat: 48.9292996, lng: 20.7079803 },
    { lat: 48.9295615, lng: 20.7074899 },
    { lat: 48.9297953, lng: 20.7068797 },
    { lat: 48.9300747, lng: 20.7064766 },
    { lat: 48.9301644, lng: 20.7060204 },
    { lat: 48.9304253, lng: 20.7054745 },
    { lat: 48.9304433, lng: 20.7047466 },
    { lat: 48.9314143, lng: 20.7026708 },
    { lat: 48.931501, lng: 20.7029272 },
    { lat: 48.9319851, lng: 20.7033397 },
    { lat: 48.9323509, lng: 20.7031784 },
    { lat: 48.9327941, lng: 20.702441 },
    { lat: 48.9331535, lng: 20.7016533 },
    { lat: 48.9338882, lng: 20.7006017 },
    { lat: 48.9344148, lng: 20.6995821 },
    { lat: 48.934431, lng: 20.6993688 },
    { lat: 48.9345239, lng: 20.6991027 },
    { lat: 48.9351436, lng: 20.6979151 },
    { lat: 48.9358157, lng: 20.6958942 },
    { lat: 48.9359537, lng: 20.6953132 },
    { lat: 48.9363772, lng: 20.6941473 },
    { lat: 48.9365965, lng: 20.6937029 },
    { lat: 48.9369057, lng: 20.6928339 },
    { lat: 48.9370455, lng: 20.692561 },
    { lat: 48.9369997, lng: 20.6923148 },
    { lat: 48.9365527, lng: 20.6909576 },
    { lat: 48.9365886, lng: 20.6894958 },
    { lat: 48.9368626, lng: 20.6889108 },
    { lat: 48.9372123, lng: 20.6879561 },
    { lat: 48.9380109, lng: 20.686326 },
    { lat: 48.9381476, lng: 20.685832 },
    { lat: 48.9382664, lng: 20.6851555 },
    { lat: 48.9385082, lng: 20.6846483 },
    { lat: 48.9390327, lng: 20.6832874 },
    { lat: 48.9392053, lng: 20.6826992 },
    { lat: 48.9392204, lng: 20.6824942 },
    { lat: 48.9393081, lng: 20.6824254 },
    { lat: 48.9394977, lng: 20.6820273 },
    { lat: 48.939795, lng: 20.681277 },
    { lat: 48.9397251, lng: 20.680674 },
    { lat: 48.9401971, lng: 20.679327 },
    { lat: 48.9392466, lng: 20.6774311 },
    { lat: 48.939027, lng: 20.6776433 },
    { lat: 48.9329094, lng: 20.6786808 },
    { lat: 48.9326767, lng: 20.6788016 },
    { lat: 48.9324384, lng: 20.6789254 },
    { lat: 48.9322365, lng: 20.6790302 },
    { lat: 48.9319869, lng: 20.6791598 },
    { lat: 48.9314086, lng: 20.67946 },
    { lat: 48.9304919, lng: 20.6797374 },
    { lat: 48.9288392, lng: 20.6807417 },
    { lat: 48.9274109, lng: 20.6812233 },
    { lat: 48.9272161, lng: 20.680494 },
    { lat: 48.9261248, lng: 20.6809873 },
    { lat: 48.9251369, lng: 20.6811868 },
    { lat: 48.9245442, lng: 20.6801355 },
    { lat: 48.9240642, lng: 20.6809411 },
    { lat: 48.9233567, lng: 20.6811181 },
    { lat: 48.9219582, lng: 20.6807271 },
    { lat: 48.9216985, lng: 20.6804921 },
    { lat: 48.9214888, lng: 20.6807285 },
    { lat: 48.9213562, lng: 20.6808675 },
    { lat: 48.9209014, lng: 20.680993 },
    { lat: 48.9189905, lng: 20.6809098 },
    { lat: 48.9164755, lng: 20.681984 },
    { lat: 48.915787, lng: 20.682405 },
    { lat: 48.9146676, lng: 20.6821116 },
    { lat: 48.9140495, lng: 20.6826453 },
    { lat: 48.9126699, lng: 20.683257 },
    { lat: 48.9121014, lng: 20.6839837 },
    { lat: 48.9120073, lng: 20.6847517 },
    { lat: 48.9118879, lng: 20.6852865 },
    { lat: 48.9113359, lng: 20.6849285 },
    { lat: 48.9110926, lng: 20.6849278 },
    { lat: 48.9109083, lng: 20.6848395 },
    { lat: 48.9095728, lng: 20.6849883 },
    { lat: 48.9069747, lng: 20.6854674 },
    { lat: 48.9051743, lng: 20.687001 },
    { lat: 48.9047448, lng: 20.6872989 },
    { lat: 48.9037036, lng: 20.6886884 },
    { lat: 48.9026046, lng: 20.6894395 },
    { lat: 48.9018391, lng: 20.6893797 },
    { lat: 48.9005569, lng: 20.6890667 },
    { lat: 48.9001758, lng: 20.6888568 },
    { lat: 48.8994109, lng: 20.6887833 },
    { lat: 48.8982812, lng: 20.6895226 },
    { lat: 48.8968005, lng: 20.6892939 },
    { lat: 48.8961768, lng: 20.6890685 },
  ],
  Mlynky: [
    { lat: 48.8574982, lng: 20.5147132 },
    { lat: 48.8576759, lng: 20.5143776 },
    { lat: 48.857851, lng: 20.5138456 },
    { lat: 48.8582219, lng: 20.5131367 },
    { lat: 48.8585027, lng: 20.5123643 },
    { lat: 48.8584934, lng: 20.5118434 },
    { lat: 48.8586256, lng: 20.5103689 },
    { lat: 48.8587395, lng: 20.5097724 },
    { lat: 48.8592776, lng: 20.5089869 },
    { lat: 48.8601773, lng: 20.5080202 },
    { lat: 48.860239, lng: 20.5075598 },
    { lat: 48.8607209, lng: 20.5063238 },
    { lat: 48.8609095, lng: 20.5055546 },
    { lat: 48.8609953, lng: 20.5048954 },
    { lat: 48.8612275, lng: 20.5043895 },
    { lat: 48.8616199, lng: 20.5040744 },
    { lat: 48.8631808, lng: 20.5020954 },
    { lat: 48.8635805, lng: 20.5002249 },
    { lat: 48.8634154, lng: 20.4990014 },
    { lat: 48.8631354, lng: 20.4982583 },
    { lat: 48.8632973, lng: 20.4973433 },
    { lat: 48.8633319, lng: 20.4966713 },
    { lat: 48.8635152, lng: 20.4960015 },
    { lat: 48.8630643, lng: 20.4952384 },
    { lat: 48.8629762, lng: 20.4953794 },
    { lat: 48.8623774, lng: 20.4955967 },
    { lat: 48.8620297, lng: 20.4958422 },
    { lat: 48.8620357, lng: 20.4954927 },
    { lat: 48.862347, lng: 20.4950215 },
    { lat: 48.862309, lng: 20.4947308 },
    { lat: 48.8621772, lng: 20.4944299 },
    { lat: 48.8618651, lng: 20.4942104 },
    { lat: 48.861182, lng: 20.4932885 },
    { lat: 48.8610837, lng: 20.4907198 },
    { lat: 48.861015, lng: 20.4904776 },
    { lat: 48.8610851, lng: 20.4892865 },
    { lat: 48.861236, lng: 20.4890769 },
    { lat: 48.8614326, lng: 20.4891869 },
    { lat: 48.8616825, lng: 20.4889737 },
    { lat: 48.8618151, lng: 20.4889927 },
    { lat: 48.8623354, lng: 20.4883622 },
    { lat: 48.8619986, lng: 20.4876975 },
    { lat: 48.8620891, lng: 20.4869906 },
    { lat: 48.8622907, lng: 20.4860921 },
    { lat: 48.8625885, lng: 20.4862256 },
    { lat: 48.8624599, lng: 20.4856781 },
    { lat: 48.862441, lng: 20.4853826 },
    { lat: 48.8625543, lng: 20.4850749 },
    { lat: 48.8631228, lng: 20.4841402 },
    { lat: 48.8632474, lng: 20.4836184 },
    { lat: 48.8632074, lng: 20.4833312 },
    { lat: 48.8635557, lng: 20.4821997 },
    { lat: 48.8635477, lng: 20.482018 },
    { lat: 48.8634734, lng: 20.4818353 },
    { lat: 48.863471, lng: 20.4815962 },
    { lat: 48.8636832, lng: 20.4813101 },
    { lat: 48.8638796, lng: 20.4809284 },
    { lat: 48.8639015, lng: 20.4804613 },
    { lat: 48.8640436, lng: 20.4801111 },
    { lat: 48.864239, lng: 20.4793465 },
    { lat: 48.8643268, lng: 20.4786548 },
    { lat: 48.8647104, lng: 20.4773836 },
    { lat: 48.8648037, lng: 20.4764808 },
    { lat: 48.8647477, lng: 20.4757132 },
    { lat: 48.8646496, lng: 20.4754077 },
    { lat: 48.8646748, lng: 20.4752109 },
    { lat: 48.8649216, lng: 20.4748277 },
    { lat: 48.8650615, lng: 20.4744759 },
    { lat: 48.8651875, lng: 20.4740237 },
    { lat: 48.8652925, lng: 20.473401 },
    { lat: 48.8652486, lng: 20.4726223 },
    { lat: 48.8652783, lng: 20.4720633 },
    { lat: 48.8652011, lng: 20.4716294 },
    { lat: 48.8651988, lng: 20.4712033 },
    { lat: 48.8653723, lng: 20.4706662 },
    { lat: 48.8651601, lng: 20.4696967 },
    { lat: 48.8650567, lng: 20.4687118 },
    { lat: 48.8646295, lng: 20.4686457 },
    { lat: 48.872028, lng: 20.4610441 },
    { lat: 48.8727521, lng: 20.4605676 },
    { lat: 48.87312, lng: 20.4601451 },
    { lat: 48.8735854, lng: 20.4598205 },
    { lat: 48.8738187, lng: 20.4554962 },
    { lat: 48.8738867, lng: 20.4553608 },
    { lat: 48.8739804, lng: 20.454892 },
    { lat: 48.8739723, lng: 20.4545279 },
    { lat: 48.8738529, lng: 20.4540771 },
    { lat: 48.8730066, lng: 20.4525091 },
    { lat: 48.872632, lng: 20.4515616 },
    { lat: 48.8723703, lng: 20.4510413 },
    { lat: 48.8721187, lng: 20.4493284 },
    { lat: 48.8720477, lng: 20.4484908 },
    { lat: 48.8720496, lng: 20.4472299 },
    { lat: 48.872001, lng: 20.4468127 },
    { lat: 48.8718923, lng: 20.4464716 },
    { lat: 48.871868, lng: 20.445767 },
    { lat: 48.8723539, lng: 20.4425993 },
    { lat: 48.8723357, lng: 20.4420862 },
    { lat: 48.872416, lng: 20.4417652 },
    { lat: 48.8723648, lng: 20.4410679 },
    { lat: 48.8725482, lng: 20.4400844 },
    { lat: 48.8726085, lng: 20.4394757 },
    { lat: 48.8725934, lng: 20.438928 },
    { lat: 48.8726648, lng: 20.4379918 },
    { lat: 48.8726047, lng: 20.4373514 },
    { lat: 48.8723617, lng: 20.4365552 },
    { lat: 48.8721986, lng: 20.4355808 },
    { lat: 48.8727285, lng: 20.4361914 },
    { lat: 48.8728826, lng: 20.4366025 },
    { lat: 48.8730302, lng: 20.4367868 },
    { lat: 48.8733338, lng: 20.4368973 },
    { lat: 48.8736567, lng: 20.4369765 },
    { lat: 48.8743263, lng: 20.436758 },
    { lat: 48.8748599, lng: 20.4369358 },
    { lat: 48.8751823, lng: 20.4368555 },
    { lat: 48.8754846, lng: 20.4365716 },
    { lat: 48.8756034, lng: 20.4363806 },
    { lat: 48.8756814, lng: 20.4358566 },
    { lat: 48.8759998, lng: 20.435254 },
    { lat: 48.8757983, lng: 20.4349546 },
    { lat: 48.8762007, lng: 20.4333013 },
    { lat: 48.8766197, lng: 20.4323855 },
    { lat: 48.8768192, lng: 20.4320673 },
    { lat: 48.8775694, lng: 20.4313594 },
    { lat: 48.8777677, lng: 20.4310642 },
    { lat: 48.8779447, lng: 20.4305987 },
    { lat: 48.8779785, lng: 20.4303879 },
    { lat: 48.8778913, lng: 20.4288366 },
    { lat: 48.8778042, lng: 20.4280847 },
    { lat: 48.8778888, lng: 20.4268393 },
    { lat: 48.8780376, lng: 20.4266066 },
    { lat: 48.8785316, lng: 20.4263614 },
    { lat: 48.8786519, lng: 20.4260926 },
    { lat: 48.878777, lng: 20.426214 },
    { lat: 48.8796873, lng: 20.4251311 },
    { lat: 48.8795209, lng: 20.4249253 },
    { lat: 48.8794747, lng: 20.424455 },
    { lat: 48.879393, lng: 20.4241115 },
    { lat: 48.879363, lng: 20.4236201 },
    { lat: 48.8794234, lng: 20.423012 },
    { lat: 48.8793733, lng: 20.4215511 },
    { lat: 48.8794961, lng: 20.4201023 },
    { lat: 48.8794651, lng: 20.41967 },
    { lat: 48.87896, lng: 20.4188541 },
    { lat: 48.878785, lng: 20.4187496 },
    { lat: 48.878052, lng: 20.4186037 },
    { lat: 48.8779277, lng: 20.418474 },
    { lat: 48.8777488, lng: 20.4180028 },
    { lat: 48.8777013, lng: 20.417735 },
    { lat: 48.8771947, lng: 20.4168149 },
    { lat: 48.876779, lng: 20.41549 },
    { lat: 48.876691, lng: 20.415234 },
    { lat: 48.876599, lng: 20.415099 },
    { lat: 48.876401, lng: 20.414925 },
    { lat: 48.876234, lng: 20.414912 },
    { lat: 48.876094, lng: 20.414953 },
    { lat: 48.875845, lng: 20.41492 },
    { lat: 48.875716, lng: 20.414907 },
    { lat: 48.875639, lng: 20.414871 },
    { lat: 48.875578, lng: 20.414788 },
    { lat: 48.875409, lng: 20.414315 },
    { lat: 48.875361, lng: 20.413964 },
    { lat: 48.875381, lng: 20.413488 },
    { lat: 48.87537, lng: 20.413337 },
    { lat: 48.875309, lng: 20.413049 },
    { lat: 48.87521, lng: 20.412789 },
    { lat: 48.875198, lng: 20.412551 },
    { lat: 48.875148, lng: 20.412263 },
    { lat: 48.875114, lng: 20.411994 },
    { lat: 48.874992, lng: 20.411462 },
    { lat: 48.874908, lng: 20.411217 },
    { lat: 48.874912, lng: 20.41106 },
    { lat: 48.874871, lng: 20.410941 },
    { lat: 48.874715, lng: 20.410761 },
    { lat: 48.874639, lng: 20.410547 },
    { lat: 48.874595, lng: 20.41028 },
    { lat: 48.874511, lng: 20.409584 },
    { lat: 48.874514, lng: 20.409284 },
    { lat: 48.8745, lng: 20.409041 },
    { lat: 48.874464, lng: 20.408863 },
    { lat: 48.874339, lng: 20.408689 },
    { lat: 48.8742, lng: 20.408172 },
    { lat: 48.874129, lng: 20.408032 },
    { lat: 48.874032, lng: 20.407909 },
    { lat: 48.873831, lng: 20.407458 },
    { lat: 48.873751, lng: 20.40723 },
    { lat: 48.873669, lng: 20.406949 },
    { lat: 48.873534, lng: 20.406779 },
    { lat: 48.873287, lng: 20.405934 },
    { lat: 48.873124, lng: 20.405529 },
    { lat: 48.872939, lng: 20.405084 },
    { lat: 48.872876, lng: 20.404909 },
    { lat: 48.872788, lng: 20.404621 },
    { lat: 48.872723, lng: 20.404458 },
    { lat: 48.872644, lng: 20.40425 },
    { lat: 48.872575, lng: 20.404069 },
    { lat: 48.872409, lng: 20.403809 },
    { lat: 48.872182, lng: 20.403366 },
    { lat: 48.872087, lng: 20.403023 },
    { lat: 48.871796, lng: 20.402198 },
    { lat: 48.871473, lng: 20.401364 },
    { lat: 48.871394, lng: 20.401187 },
    { lat: 48.871271, lng: 20.400971 },
    { lat: 48.871125, lng: 20.400748 },
    { lat: 48.870741, lng: 20.400299 },
    { lat: 48.870685, lng: 20.400213 },
    { lat: 48.870504, lng: 20.399929 },
    { lat: 48.870453, lng: 20.399763 },
    { lat: 48.870408, lng: 20.399209 },
    { lat: 48.870291, lng: 20.399098 },
    { lat: 48.870182, lng: 20.399 },
    { lat: 48.870085, lng: 20.398913 },
    { lat: 48.869872, lng: 20.398722 },
    { lat: 48.869756, lng: 20.398611 },
    { lat: 48.869434, lng: 20.398317 },
    { lat: 48.86943, lng: 20.398315 },
    { lat: 48.869283, lng: 20.398205 },
    { lat: 48.86899, lng: 20.398005 },
    { lat: 48.868789, lng: 20.397778 },
    { lat: 48.868733, lng: 20.397747 },
    { lat: 48.86859, lng: 20.397665 },
    { lat: 48.8685, lng: 20.397613 },
    { lat: 48.868401, lng: 20.397542 },
    { lat: 48.868254, lng: 20.397402 },
    { lat: 48.86813, lng: 20.397271 },
    { lat: 48.868073, lng: 20.397187 },
    { lat: 48.867969, lng: 20.396954 },
    { lat: 48.867869, lng: 20.396704 },
    { lat: 48.867776, lng: 20.396521 },
    { lat: 48.867591, lng: 20.396131 },
    { lat: 48.867416, lng: 20.395824 },
    { lat: 48.867273, lng: 20.395445 },
    { lat: 48.867219, lng: 20.39534 },
    { lat: 48.867151, lng: 20.395215 },
    { lat: 48.867077, lng: 20.395079 },
    { lat: 48.866996, lng: 20.394918 },
    { lat: 48.866893, lng: 20.394751 },
    { lat: 48.866859, lng: 20.39462 },
    { lat: 48.866792, lng: 20.394517 },
    { lat: 48.866534, lng: 20.394056 },
    { lat: 48.866494, lng: 20.393981 },
    { lat: 48.866434, lng: 20.393877 },
    { lat: 48.866424, lng: 20.393821 },
    { lat: 48.8664, lng: 20.393579 },
    { lat: 48.866236, lng: 20.393148 },
    { lat: 48.865882, lng: 20.392693 },
    { lat: 48.865701, lng: 20.392523 },
    { lat: 48.865533, lng: 20.392248 },
    { lat: 48.865421, lng: 20.391887 },
    { lat: 48.865239, lng: 20.391406 },
    { lat: 48.865188, lng: 20.390922 },
    { lat: 48.865137, lng: 20.39074 },
    { lat: 48.865041, lng: 20.390498 },
    { lat: 48.864987, lng: 20.39026 },
    { lat: 48.864958, lng: 20.390112 },
    { lat: 48.864129, lng: 20.389677 },
    { lat: 48.864004, lng: 20.389613 },
    { lat: 48.855951, lng: 20.38539 },
    { lat: 48.855915, lng: 20.385409 },
    { lat: 48.855824, lng: 20.38544 },
    { lat: 48.855766, lng: 20.385434 },
    { lat: 48.855702, lng: 20.385424 },
    { lat: 48.855429, lng: 20.38567 },
    { lat: 48.855136, lng: 20.385885 },
    { lat: 48.854867, lng: 20.385982 },
    { lat: 48.854427, lng: 20.385995 },
    { lat: 48.854373, lng: 20.385911 },
    { lat: 48.854313, lng: 20.385807 },
    { lat: 48.854307, lng: 20.385792 },
    { lat: 48.854242, lng: 20.385786 },
    { lat: 48.854007, lng: 20.385763 },
    { lat: 48.853886, lng: 20.385794 },
    { lat: 48.85372, lng: 20.385903 },
    { lat: 48.853711, lng: 20.385909 },
    { lat: 48.853646, lng: 20.38564 },
    { lat: 48.853608, lng: 20.385534 },
    { lat: 48.853526, lng: 20.38537 },
    { lat: 48.853505, lng: 20.385328 },
    { lat: 48.853373, lng: 20.385113 },
    { lat: 48.853308, lng: 20.384976 },
    { lat: 48.853264, lng: 20.384869 },
    { lat: 48.853134, lng: 20.384578 },
    { lat: 48.853108, lng: 20.384301 },
    { lat: 48.853017, lng: 20.384077 },
    { lat: 48.852979, lng: 20.38399 },
    { lat: 48.85294, lng: 20.383844 },
    { lat: 48.852932, lng: 20.383709 },
    { lat: 48.852732, lng: 20.382992 },
    { lat: 48.852459, lng: 20.382391 },
    { lat: 48.852175, lng: 20.381674 },
    { lat: 48.851829, lng: 20.380551 },
    { lat: 48.851712, lng: 20.380313 },
    { lat: 48.851532, lng: 20.380073 },
    { lat: 48.851364, lng: 20.379911 },
    { lat: 48.851132, lng: 20.37974 },
    { lat: 48.850761, lng: 20.379537 },
    { lat: 48.850641, lng: 20.379422 },
    { lat: 48.850493, lng: 20.379205 },
    { lat: 48.850268, lng: 20.378762 },
    { lat: 48.849959, lng: 20.378532 },
    { lat: 48.849837, lng: 20.378516 },
    { lat: 48.849381, lng: 20.378329 },
    { lat: 48.84867, lng: 20.377678 },
    { lat: 48.848626, lng: 20.377738 },
    { lat: 48.848595, lng: 20.377711 },
    { lat: 48.848517, lng: 20.377927 },
    { lat: 48.848431, lng: 20.378302 },
    { lat: 48.848297, lng: 20.378803 },
    { lat: 48.848235, lng: 20.379099 },
    { lat: 48.848222, lng: 20.379408 },
    { lat: 48.848157, lng: 20.379786 },
    { lat: 48.848059, lng: 20.380124 },
    { lat: 48.847911, lng: 20.380733 },
    { lat: 48.847822, lng: 20.380866 },
    { lat: 48.847684, lng: 20.380978 },
    { lat: 48.847588, lng: 20.381137 },
    { lat: 48.8474982, lng: 20.3812935 },
    { lat: 48.8474469, lng: 20.3814125 },
    { lat: 48.8473844, lng: 20.3818022 },
    { lat: 48.847342, lng: 20.382381 },
    { lat: 48.847354, lng: 20.382725 },
    { lat: 48.847394, lng: 20.383261 },
    { lat: 48.847366, lng: 20.383497 },
    { lat: 48.847235, lng: 20.384 },
    { lat: 48.847092, lng: 20.384349 },
    { lat: 48.846882, lng: 20.384831 },
    { lat: 48.846718, lng: 20.385263 },
    { lat: 48.846669, lng: 20.385444 },
    { lat: 48.846486, lng: 20.385925 },
    { lat: 48.846439, lng: 20.386084 },
    { lat: 48.846345, lng: 20.386311 },
    { lat: 48.84629, lng: 20.386654 },
    { lat: 48.846256, lng: 20.386762 },
    { lat: 48.846295, lng: 20.387047 },
    { lat: 48.846255, lng: 20.387152 },
    { lat: 48.846186, lng: 20.387207 },
    { lat: 48.846046, lng: 20.387272 },
    { lat: 48.845935, lng: 20.387386 },
    { lat: 48.845839, lng: 20.387511 },
    { lat: 48.84573, lng: 20.387604 },
    { lat: 48.845632, lng: 20.387659 },
    { lat: 48.845335, lng: 20.387725 },
    { lat: 48.845035, lng: 20.387879 },
    { lat: 48.844829, lng: 20.38805 },
    { lat: 48.844628, lng: 20.388301 },
    { lat: 48.84454, lng: 20.388463 },
    { lat: 48.844449, lng: 20.388649 },
    { lat: 48.844405, lng: 20.388799 },
    { lat: 48.84437, lng: 20.389024 },
    { lat: 48.844338, lng: 20.389275 },
    { lat: 48.844342, lng: 20.389451 },
    { lat: 48.844399, lng: 20.389985 },
    { lat: 48.844449, lng: 20.390538 },
    { lat: 48.844626, lng: 20.391145 },
    { lat: 48.844747, lng: 20.391817 },
    { lat: 48.844728, lng: 20.392077 },
    { lat: 48.844579, lng: 20.392332 },
    { lat: 48.844361, lng: 20.392548 },
    { lat: 48.843941, lng: 20.393199 },
    { lat: 48.843467, lng: 20.393979 },
    { lat: 48.843569, lng: 20.394475 },
    { lat: 48.84389, lng: 20.395247 },
    { lat: 48.844574, lng: 20.395866 },
    { lat: 48.844655, lng: 20.396271 },
    { lat: 48.844639, lng: 20.396803 },
    { lat: 48.844642, lng: 20.397429 },
    { lat: 48.844791, lng: 20.398069 },
    { lat: 48.844948, lng: 20.39837 },
    { lat: 48.845068, lng: 20.399386 },
    { lat: 48.84489, lng: 20.399892 },
    { lat: 48.844559, lng: 20.400466 },
    { lat: 48.844434, lng: 20.400737 },
    { lat: 48.844271, lng: 20.401198 },
    { lat: 48.844003, lng: 20.401624 },
    { lat: 48.84352, lng: 20.402317 },
    { lat: 48.843331, lng: 20.402486 },
    { lat: 48.842931, lng: 20.402585 },
    { lat: 48.842789, lng: 20.402991 },
    { lat: 48.842717, lng: 20.403318 },
    { lat: 48.842725, lng: 20.403662 },
    { lat: 48.842963, lng: 20.404141 },
    { lat: 48.843594, lng: 20.405723 },
    { lat: 48.84363, lng: 20.406425 },
    { lat: 48.843599, lng: 20.407506 },
    { lat: 48.843434, lng: 20.40827 },
    { lat: 48.843367, lng: 20.409034 },
    { lat: 48.843305, lng: 20.409934 },
    { lat: 48.843243, lng: 20.410838 },
    { lat: 48.842998, lng: 20.412681 },
    { lat: 48.842915, lng: 20.413144 },
    { lat: 48.842755, lng: 20.413489 },
    { lat: 48.842677, lng: 20.413991 },
    { lat: 48.842548, lng: 20.414645 },
    { lat: 48.842461, lng: 20.415285 },
    { lat: 48.842315, lng: 20.415831 },
    { lat: 48.842183, lng: 20.416198 },
    { lat: 48.842099, lng: 20.416503 },
    { lat: 48.842133, lng: 20.416723 },
    { lat: 48.842312, lng: 20.417087 },
    { lat: 48.842312, lng: 20.417485 },
    { lat: 48.842228, lng: 20.417568 },
    { lat: 48.842225, lng: 20.417621 },
    { lat: 48.841903, lng: 20.417922 },
    { lat: 48.841676, lng: 20.417911 },
    { lat: 48.841209, lng: 20.417909 },
    { lat: 48.840876, lng: 20.418142 },
    { lat: 48.840563, lng: 20.418415 },
    { lat: 48.839789, lng: 20.419608 },
    { lat: 48.839296, lng: 20.420879 },
    { lat: 48.839198, lng: 20.421183 },
    { lat: 48.839059, lng: 20.421705 },
    { lat: 48.838956, lng: 20.422214 },
    { lat: 48.838967, lng: 20.422651 },
    { lat: 48.838721, lng: 20.423183 },
    { lat: 48.838359, lng: 20.42352 },
    { lat: 48.838116, lng: 20.423784 },
    { lat: 48.837885, lng: 20.424114 },
    { lat: 48.83729, lng: 20.424176 },
    { lat: 48.837139, lng: 20.424192 },
    { lat: 48.836975, lng: 20.424408 },
    { lat: 48.836841, lng: 20.424609 },
    { lat: 48.836701, lng: 20.424503 },
    { lat: 48.836642, lng: 20.424531 },
    { lat: 48.836128, lng: 20.424768 },
    { lat: 48.835925, lng: 20.425321 },
    { lat: 48.836399, lng: 20.425571 },
    { lat: 48.836535, lng: 20.425508 },
    { lat: 48.836631, lng: 20.426222 },
    { lat: 48.836574, lng: 20.426513 },
    { lat: 48.83695, lng: 20.427058 },
    { lat: 48.837067, lng: 20.427615 },
    { lat: 48.83722, lng: 20.428176 },
    { lat: 48.837546, lng: 20.429226 },
    { lat: 48.837511, lng: 20.42954 },
    { lat: 48.837606, lng: 20.430054 },
    { lat: 48.83756, lng: 20.430553 },
    { lat: 48.837594, lng: 20.430779 },
    { lat: 48.837752, lng: 20.430967 },
    { lat: 48.837709, lng: 20.431348 },
    { lat: 48.837722, lng: 20.431399 },
    { lat: 48.83773, lng: 20.432074 },
    { lat: 48.837731, lng: 20.432147 },
    { lat: 48.837688, lng: 20.43258 },
    { lat: 48.837701, lng: 20.432745 },
    { lat: 48.837336, lng: 20.432664 },
    { lat: 48.837096, lng: 20.432626 },
    { lat: 48.836681, lng: 20.432575 },
    { lat: 48.836525, lng: 20.432593 },
    { lat: 48.836473, lng: 20.432663 },
    { lat: 48.836403, lng: 20.432761 },
    { lat: 48.836389, lng: 20.432801 },
    { lat: 48.83637, lng: 20.432859 },
    { lat: 48.836339, lng: 20.432953 },
    { lat: 48.83633, lng: 20.433056 },
    { lat: 48.836337, lng: 20.433119 },
    { lat: 48.83634, lng: 20.43314 },
    { lat: 48.836343, lng: 20.433158 },
    { lat: 48.836354, lng: 20.433218 },
    { lat: 48.836394, lng: 20.433315 },
    { lat: 48.836543, lng: 20.433478 },
    { lat: 48.83671, lng: 20.433648 },
    { lat: 48.836806, lng: 20.433741 },
    { lat: 48.837032, lng: 20.433958 },
    { lat: 48.83727, lng: 20.434225 },
    { lat: 48.83746, lng: 20.434493 },
    { lat: 48.837692, lng: 20.434888 },
    { lat: 48.837914, lng: 20.435171 },
    { lat: 48.83801, lng: 20.435266 },
    { lat: 48.838221, lng: 20.435496 },
    { lat: 48.838405, lng: 20.435696 },
    { lat: 48.838539, lng: 20.435866 },
    { lat: 48.838668, lng: 20.436067 },
    { lat: 48.83866, lng: 20.43622 },
    { lat: 48.838295, lng: 20.436628 },
    { lat: 48.838199, lng: 20.43676 },
    { lat: 48.837907, lng: 20.436961 },
    { lat: 48.837909, lng: 20.437175 },
    { lat: 48.838032, lng: 20.437337 },
    { lat: 48.838328, lng: 20.437724 },
    { lat: 48.838203, lng: 20.438937 },
    { lat: 48.838494, lng: 20.439287 },
    { lat: 48.838463, lng: 20.439417 },
    { lat: 48.838512, lng: 20.439771 },
    { lat: 48.838435, lng: 20.439906 },
    { lat: 48.838449, lng: 20.440113 },
    { lat: 48.838377, lng: 20.440319 },
    { lat: 48.838485, lng: 20.440514 },
    { lat: 48.838343, lng: 20.440714 },
    { lat: 48.838214, lng: 20.440775 },
    { lat: 48.83833, lng: 20.440969 },
    { lat: 48.838471, lng: 20.441443 },
    { lat: 48.838284, lng: 20.441624 },
    { lat: 48.838248, lng: 20.442082 },
    { lat: 48.838335, lng: 20.4421 },
    { lat: 48.838302, lng: 20.442594 },
    { lat: 48.838332, lng: 20.442655 },
    { lat: 48.838551, lng: 20.443124 },
    { lat: 48.838368, lng: 20.443469 },
    { lat: 48.838332, lng: 20.443536 },
    { lat: 48.838305, lng: 20.444025 },
    { lat: 48.838167, lng: 20.444856 },
    { lat: 48.837871, lng: 20.444855 },
    { lat: 48.837452, lng: 20.445 },
    { lat: 48.837145, lng: 20.444227 },
    { lat: 48.837062, lng: 20.44439 },
    { lat: 48.836823, lng: 20.444186 },
    { lat: 48.836669, lng: 20.44438 },
    { lat: 48.836619, lng: 20.444851 },
    { lat: 48.836794, lng: 20.445044 },
    { lat: 48.836712, lng: 20.445557 },
    { lat: 48.836349, lng: 20.445693 },
    { lat: 48.836158, lng: 20.445926 },
    { lat: 48.83608, lng: 20.446844 },
    { lat: 48.836134, lng: 20.447985 },
    { lat: 48.835791, lng: 20.448598 },
    { lat: 48.835635, lng: 20.449295 },
    { lat: 48.835286, lng: 20.449011 },
    { lat: 48.835059, lng: 20.449582 },
    { lat: 48.834672, lng: 20.449559 },
    { lat: 48.83448, lng: 20.450021 },
    { lat: 48.834398, lng: 20.450219 },
    { lat: 48.834266, lng: 20.450533 },
    { lat: 48.834169, lng: 20.450521 },
    { lat: 48.83406, lng: 20.452228 },
    { lat: 48.834404, lng: 20.45295 },
    { lat: 48.834135, lng: 20.453243 },
    { lat: 48.834467, lng: 20.454736 },
    { lat: 48.834142, lng: 20.454977 },
    { lat: 48.833805, lng: 20.45602 },
    { lat: 48.833791, lng: 20.456429 },
    { lat: 48.833743, lng: 20.45792 },
    { lat: 48.832417, lng: 20.460242 },
    { lat: 48.8347455, lng: 20.4620011 },
    { lat: 48.8400488, lng: 20.4631333 },
    { lat: 48.8409631, lng: 20.4634917 },
    { lat: 48.8420019, lng: 20.4642612 },
    { lat: 48.8401163, lng: 20.4676442 },
    { lat: 48.8419292, lng: 20.4686074 },
    { lat: 48.8426575, lng: 20.4696216 },
    { lat: 48.8432338, lng: 20.4708726 },
    { lat: 48.8442814, lng: 20.4719593 },
    { lat: 48.8450389, lng: 20.472497 },
    { lat: 48.8457138, lng: 20.4728631 },
    { lat: 48.8462587, lng: 20.4730155 },
    { lat: 48.8469312, lng: 20.4730808 },
    { lat: 48.8467746, lng: 20.4720905 },
    { lat: 48.846659, lng: 20.4717155 },
    { lat: 48.8464275, lng: 20.4704095 },
    { lat: 48.8462766, lng: 20.4698981 },
    { lat: 48.8463765, lng: 20.4683941 },
    { lat: 48.8463626, lng: 20.4678744 },
    { lat: 48.8462281, lng: 20.4674099 },
    { lat: 48.8462161, lng: 20.4672121 },
    { lat: 48.8463485, lng: 20.4663342 },
    { lat: 48.8462749, lng: 20.4651565 },
    { lat: 48.8464765, lng: 20.4650983 },
    { lat: 48.8497464, lng: 20.4655535 },
    { lat: 48.8497502, lng: 20.4660172 },
    { lat: 48.8498268, lng: 20.4661972 },
    { lat: 48.8500641, lng: 20.4664584 },
    { lat: 48.850131, lng: 20.4664369 },
    { lat: 48.8503207, lng: 20.4667265 },
    { lat: 48.8506494, lng: 20.4677664 },
    { lat: 48.8506318, lng: 20.4687357 },
    { lat: 48.8506766, lng: 20.4692109 },
    { lat: 48.8505269, lng: 20.4704988 },
    { lat: 48.8505382, lng: 20.4713072 },
    { lat: 48.8506929, lng: 20.4716446 },
    { lat: 48.8507818, lng: 20.471737 },
    { lat: 48.8513871, lng: 20.4718725 },
    { lat: 48.8517991, lng: 20.4722316 },
    { lat: 48.8520535, lng: 20.47256 },
    { lat: 48.8528562, lng: 20.4741744 },
    { lat: 48.8531821, lng: 20.4746965 },
    { lat: 48.8538135, lng: 20.4765181 },
    { lat: 48.8538642, lng: 20.477026 },
    { lat: 48.8538166, lng: 20.4771982 },
    { lat: 48.8531659, lng: 20.4776193 },
    { lat: 48.8528882, lng: 20.4779658 },
    { lat: 48.8527163, lng: 20.4784522 },
    { lat: 48.8526827, lng: 20.4789399 },
    { lat: 48.85274, lng: 20.4794155 },
    { lat: 48.8529043, lng: 20.4798025 },
    { lat: 48.8531652, lng: 20.4800009 },
    { lat: 48.8536733, lng: 20.4802189 },
    { lat: 48.8541294, lng: 20.4803122 },
    { lat: 48.8545518, lng: 20.4804979 },
    { lat: 48.854778, lng: 20.4806707 },
    { lat: 48.8554734, lng: 20.4822527 },
    { lat: 48.8557496, lng: 20.4834335 },
    { lat: 48.855852, lng: 20.4841262 },
    { lat: 48.8559032, lng: 20.4853385 },
    { lat: 48.8556799, lng: 20.4862616 },
    { lat: 48.8551465, lng: 20.488072 },
    { lat: 48.855084, lng: 20.4887471 },
    { lat: 48.8549827, lng: 20.4892303 },
    { lat: 48.8544621, lng: 20.4902401 },
    { lat: 48.8541553, lng: 20.4906479 },
    { lat: 48.8539855, lng: 20.4911931 },
    { lat: 48.8537972, lng: 20.491456 },
    { lat: 48.853329, lng: 20.4916425 },
    { lat: 48.853241, lng: 20.4917319 },
    { lat: 48.8531648, lng: 20.4919769 },
    { lat: 48.8531884, lng: 20.4921851 },
    { lat: 48.8533043, lng: 20.4923943 },
    { lat: 48.8536085, lng: 20.492705 },
    { lat: 48.8541658, lng: 20.4935774 },
    { lat: 48.8543625, lng: 20.4940171 },
    { lat: 48.8546457, lng: 20.4944855 },
    { lat: 48.8548615, lng: 20.4952271 },
    { lat: 48.8549356, lng: 20.4963945 },
    { lat: 48.8548698, lng: 20.4968371 },
    { lat: 48.8546295, lng: 20.4974198 },
    { lat: 48.8545074, lng: 20.4978904 },
    { lat: 48.8543855, lng: 20.4984858 },
    { lat: 48.8543643, lng: 20.4990064 },
    { lat: 48.8545787, lng: 20.4991689 },
    { lat: 48.8555343, lng: 20.4993708 },
    { lat: 48.8562129, lng: 20.4996623 },
    { lat: 48.85707, lng: 20.4998834 },
    { lat: 48.8580607, lng: 20.499852 },
    { lat: 48.8584037, lng: 20.4999241 },
    { lat: 48.8587175, lng: 20.5001604 },
    { lat: 48.8588943, lng: 20.5003687 },
    { lat: 48.8596368, lng: 20.5009011 },
    { lat: 48.8597456, lng: 20.5011278 },
    { lat: 48.8597108, lng: 20.5013721 },
    { lat: 48.8594948, lng: 20.50207 },
    { lat: 48.8593925, lng: 20.5034018 },
    { lat: 48.8590938, lng: 20.5043864 },
    { lat: 48.8588033, lng: 20.5057125 },
    { lat: 48.8579922, lng: 20.5073027 },
    { lat: 48.85778, lng: 20.5081676 },
    { lat: 48.8577435, lng: 20.508819 },
    { lat: 48.8577716, lng: 20.5096629 },
    { lat: 48.8577314, lng: 20.5099692 },
    { lat: 48.8573688, lng: 20.5113647 },
    { lat: 48.8571967, lng: 20.5117909 },
    { lat: 48.8570393, lng: 20.5132496 },
    { lat: 48.8571154, lng: 20.5141123 },
    { lat: 48.8572127, lng: 20.5144276 },
    { lat: 48.8574223, lng: 20.5148545 },
    { lat: 48.8574982, lng: 20.5147132 },
  ],
  Hrabušice: [
    { lat: 48.91126, lng: 20.391559 },
    { lat: 48.9136972, lng: 20.3922243 },
    { lat: 48.9153665, lng: 20.3929498 },
    { lat: 48.9172253, lng: 20.393059 },
    { lat: 48.9176498, lng: 20.3931767 },
    { lat: 48.9184036, lng: 20.3932181 },
    { lat: 48.9186307, lng: 20.3931226 },
    { lat: 48.919093, lng: 20.3932312 },
    { lat: 48.9194117, lng: 20.3936424 },
    { lat: 48.9195351, lng: 20.3941604 },
    { lat: 48.9194233, lng: 20.3951544 },
    { lat: 48.9176815, lng: 20.3970448 },
    { lat: 48.9198231, lng: 20.3985739 },
    { lat: 48.9208567, lng: 20.3976885 },
    { lat: 48.9229521, lng: 20.3969797 },
    { lat: 48.9228457, lng: 20.3961398 },
    { lat: 48.9225112, lng: 20.3948398 },
    { lat: 48.9225366, lng: 20.3948257 },
    { lat: 48.9232491, lng: 20.3949961 },
    { lat: 48.9249, lng: 20.3957106 },
    { lat: 48.9253153, lng: 20.395823 },
    { lat: 48.9253988, lng: 20.3957351 },
    { lat: 48.9260812, lng: 20.396065 },
    { lat: 48.926351, lng: 20.3959614 },
    { lat: 48.9270531, lng: 20.3958914 },
    { lat: 48.9275562, lng: 20.3959108 },
    { lat: 48.9279105, lng: 20.3960186 },
    { lat: 48.9284554, lng: 20.3959599 },
    { lat: 48.9293676, lng: 20.3962009 },
    { lat: 48.9297322, lng: 20.3964879 },
    { lat: 48.9300115, lng: 20.39711 },
    { lat: 48.9300201, lng: 20.3975995 },
    { lat: 48.930195, lng: 20.3982327 },
    { lat: 48.9303518, lng: 20.3983281 },
    { lat: 48.9305226, lng: 20.3987292 },
    { lat: 48.930611, lng: 20.3987469 },
    { lat: 48.9307724, lng: 20.3989615 },
    { lat: 48.9308777, lng: 20.3989762 },
    { lat: 48.9310737, lng: 20.3993877 },
    { lat: 48.9312576, lng: 20.3994041 },
    { lat: 48.9314256, lng: 20.399645 },
    { lat: 48.9316511, lng: 20.3997111 },
    { lat: 48.9317864, lng: 20.3995341 },
    { lat: 48.9320131, lng: 20.3996385 },
    { lat: 48.9321619, lng: 20.3998156 },
    { lat: 48.9324059, lng: 20.3998091 },
    { lat: 48.9326481, lng: 20.3998989 },
    { lat: 48.9328817, lng: 20.4002647 },
    { lat: 48.9329408, lng: 20.4004898 },
    { lat: 48.9333191, lng: 20.4007513 },
    { lat: 48.933407, lng: 20.401467 },
    { lat: 48.9334547, lng: 20.4015896 },
    { lat: 48.9334155, lng: 20.401775 },
    { lat: 48.9334501, lng: 20.4019456 },
    { lat: 48.9335415, lng: 20.4019795 },
    { lat: 48.9336183, lng: 20.4021629 },
    { lat: 48.9336692, lng: 20.4024628 },
    { lat: 48.9338497, lng: 20.4029462 },
    { lat: 48.933903, lng: 20.4029276 },
    { lat: 48.9339468, lng: 20.4030971 },
    { lat: 48.9344782, lng: 20.4035389 },
    { lat: 48.9347224, lng: 20.4040962 },
    { lat: 48.9351984, lng: 20.4049773 },
    { lat: 48.9356982, lng: 20.405299 },
    { lat: 48.9359413, lng: 20.4056172 },
    { lat: 48.9361827, lng: 20.4057787 },
    { lat: 48.9365719, lng: 20.4062398 },
    { lat: 48.9369708, lng: 20.4062517 },
    { lat: 48.9375359, lng: 20.4067773 },
    { lat: 48.9376071, lng: 20.4069605 },
    { lat: 48.9379008, lng: 20.4070787 },
    { lat: 48.9380043, lng: 20.4071943 },
    { lat: 48.9380851, lng: 20.4074798 },
    { lat: 48.9381831, lng: 20.4074841 },
    { lat: 48.9382579, lng: 20.40773 },
    { lat: 48.9383887, lng: 20.4077651 },
    { lat: 48.9384438, lng: 20.4078799 },
    { lat: 48.938543, lng: 20.4079117 },
    { lat: 48.9388498, lng: 20.408374 },
    { lat: 48.9391068, lng: 20.408437 },
    { lat: 48.9393161, lng: 20.4087274 },
    { lat: 48.9393764, lng: 20.4089234 },
    { lat: 48.9393001, lng: 20.4095222 },
    { lat: 48.9393585, lng: 20.4097237 },
    { lat: 48.9394308, lng: 20.4097879 },
    { lat: 48.9395811, lng: 20.4097747 },
    { lat: 48.9397991, lng: 20.4096155 },
    { lat: 48.9401977, lng: 20.4095 },
    { lat: 48.9405751, lng: 20.4096499 },
    { lat: 48.9422826, lng: 20.4094872 },
    { lat: 48.942061, lng: 20.4115952 },
    { lat: 48.9449726, lng: 20.4143123 },
    { lat: 48.9511702, lng: 20.4199176 },
    { lat: 48.9530588, lng: 20.4211448 },
    { lat: 48.9529643, lng: 20.421769 },
    { lat: 48.952842, lng: 20.4221347 },
    { lat: 48.9532236, lng: 20.4233796 },
    { lat: 48.9533827, lng: 20.42419 },
    { lat: 48.9535944, lng: 20.424964 },
    { lat: 48.9536474, lng: 20.4261499 },
    { lat: 48.9535743, lng: 20.4266915 },
    { lat: 48.953433, lng: 20.4269726 },
    { lat: 48.9528223, lng: 20.4269034 },
    { lat: 48.9527701, lng: 20.4269553 },
    { lat: 48.9527269, lng: 20.4269985 },
    { lat: 48.9525923, lng: 20.4274566 },
    { lat: 48.9526486, lng: 20.4280518 },
    { lat: 48.9527182, lng: 20.4281636 },
    { lat: 48.9529917, lng: 20.4283403 },
    { lat: 48.9532501, lng: 20.428652 },
    { lat: 48.9533277, lng: 20.4289433 },
    { lat: 48.9532777, lng: 20.4292927 },
    { lat: 48.9531652, lng: 20.4296496 },
    { lat: 48.953131, lng: 20.4299923 },
    { lat: 48.9531665, lng: 20.4301898 },
    { lat: 48.9533302, lng: 20.4304109 },
    { lat: 48.9535001, lng: 20.430467 },
    { lat: 48.9536417, lng: 20.430667 },
    { lat: 48.955014, lng: 20.4306335 },
    { lat: 48.955203, lng: 20.4306582 },
    { lat: 48.9553833, lng: 20.4308076 },
    { lat: 48.9557767, lng: 20.4308227 },
    { lat: 48.9563033, lng: 20.4304238 },
    { lat: 48.9568432, lng: 20.4301692 },
    { lat: 48.9572442, lng: 20.4296999 },
    { lat: 48.9574944, lng: 20.4296147 },
    { lat: 48.9591396, lng: 20.4295644 },
    { lat: 48.9601143, lng: 20.4293155 },
    { lat: 48.9616756, lng: 20.4290358 },
    { lat: 48.9626346, lng: 20.4287047 },
    { lat: 48.9641095, lng: 20.4298261 },
    { lat: 48.9648369, lng: 20.4301556 },
    { lat: 48.9669776, lng: 20.4301933 },
    { lat: 48.9687119, lng: 20.4303514 },
    { lat: 48.9697307, lng: 20.4301634 },
    { lat: 48.970316, lng: 20.4306664 },
    { lat: 48.9709316, lng: 20.4310469 },
    { lat: 48.9738186, lng: 20.4314015 },
    { lat: 48.9747161, lng: 20.4317636 },
    { lat: 48.9763765, lng: 20.4317221 },
    { lat: 48.9771271, lng: 20.4321212 },
    { lat: 48.9777449, lng: 20.4324496 },
    { lat: 48.978285, lng: 20.4324375 },
    { lat: 48.9787944, lng: 20.4323317 },
    { lat: 48.9808656, lng: 20.4316059 },
    { lat: 48.982828, lng: 20.4313621 },
    { lat: 48.983539, lng: 20.432089 },
    { lat: 48.9841154, lng: 20.4323562 },
    { lat: 48.9850346, lng: 20.4326308 },
    { lat: 48.9859215, lng: 20.4329785 },
    { lat: 48.9863695, lng: 20.4330656 },
    { lat: 48.9880039, lng: 20.4339148 },
    { lat: 48.9892113, lng: 20.4342837 },
    { lat: 48.9895591, lng: 20.4340748 },
    { lat: 48.9911445, lng: 20.4338639 },
    { lat: 48.9922037, lng: 20.4335595 },
    { lat: 48.9923294, lng: 20.4336704 },
    { lat: 48.9926383, lng: 20.4343949 },
    { lat: 48.9929204, lng: 20.4349185 },
    { lat: 48.9930007, lng: 20.4349581 },
    { lat: 48.9945703, lng: 20.4336181 },
    { lat: 48.994885, lng: 20.4334253 },
    { lat: 48.996473, lng: 20.433186 },
    { lat: 48.99648, lng: 20.433159 },
    { lat: 48.99819, lng: 20.433175 },
    { lat: 48.999062, lng: 20.433269 },
    { lat: 48.999501, lng: 20.433269 },
    { lat: 48.999921, lng: 20.433287 },
    { lat: 49.000329, lng: 20.433287 },
    { lat: 49.000954, lng: 20.433311 },
    { lat: 49.001511, lng: 20.4333 },
    { lat: 49.001511, lng: 20.433266 },
    { lat: 49.0016, lng: 20.432115 },
    { lat: 49.001678, lng: 20.430908 },
    { lat: 49.001751, lng: 20.430422 },
    { lat: 49.001778, lng: 20.429904 },
    { lat: 49.001772, lng: 20.429711 },
    { lat: 49.001755, lng: 20.429481 },
    { lat: 49.00167, lng: 20.428983 },
    { lat: 49.001689, lng: 20.428979 },
    { lat: 49.00199, lng: 20.42893 },
    { lat: 49.002029, lng: 20.428925 },
    { lat: 49.002849, lng: 20.428822 },
    { lat: 49.003009, lng: 20.428997 },
    { lat: 49.003053, lng: 20.429022 },
    { lat: 49.003263, lng: 20.428985 },
    { lat: 49.003506, lng: 20.428889 },
    { lat: 49.002951, lng: 20.427547 },
    { lat: 49.002666, lng: 20.426836 },
    { lat: 49.002089, lng: 20.427005 },
    { lat: 49.002067, lng: 20.427022 },
    { lat: 49.002055, lng: 20.427035 },
    { lat: 49.001934, lng: 20.426888 },
    { lat: 49.00175, lng: 20.426725 },
    { lat: 49.001181, lng: 20.426377 },
    { lat: 49.000911, lng: 20.426152 },
    { lat: 49.000488, lng: 20.425774 },
    { lat: 49.000091, lng: 20.425475 },
    { lat: 48.999608, lng: 20.425029 },
    { lat: 48.999586, lng: 20.425002 },
    { lat: 48.999466, lng: 20.424854 },
    { lat: 48.998919, lng: 20.424149 },
    { lat: 48.998631, lng: 20.423777 },
    { lat: 48.998442, lng: 20.423559 },
    { lat: 48.998347, lng: 20.423469 },
    { lat: 48.998282, lng: 20.423397 },
    { lat: 48.998048, lng: 20.423167 },
    { lat: 48.997945, lng: 20.423075 },
    { lat: 48.997797, lng: 20.422956 },
    { lat: 48.997664, lng: 20.422848 },
    { lat: 48.997589, lng: 20.422792 },
    { lat: 48.997397, lng: 20.422688 },
    { lat: 48.997097, lng: 20.422519 },
    { lat: 48.997015, lng: 20.422475 },
    { lat: 48.99695, lng: 20.422445 },
    { lat: 48.996874, lng: 20.422433 },
    { lat: 48.996561, lng: 20.422407 },
    { lat: 48.996451, lng: 20.422388 },
    { lat: 48.996326, lng: 20.422362 },
    { lat: 48.996238, lng: 20.422331 },
    { lat: 48.996187, lng: 20.422303 },
    { lat: 48.996057, lng: 20.42229 },
    { lat: 48.995878, lng: 20.422207 },
    { lat: 48.995247, lng: 20.422014 },
    { lat: 48.994488, lng: 20.421286 },
    { lat: 48.993324, lng: 20.42029 },
    { lat: 48.993325, lng: 20.420279 },
    { lat: 48.992655, lng: 20.419535 },
    { lat: 48.992462, lng: 20.419319 },
    { lat: 48.99204, lng: 20.41884 },
    { lat: 48.991781, lng: 20.41854 },
    { lat: 48.991744, lng: 20.418501 },
    { lat: 48.991372, lng: 20.418178 },
    { lat: 48.991155, lng: 20.417995 },
    { lat: 48.990545, lng: 20.417443 },
    { lat: 48.990208, lng: 20.41722 },
    { lat: 48.990009, lng: 20.417035 },
    { lat: 48.989857, lng: 20.417012 },
    { lat: 48.989692, lng: 20.4169 },
    { lat: 48.989597, lng: 20.416899 },
    { lat: 48.989152, lng: 20.416459 },
    { lat: 48.988889, lng: 20.416208 },
    { lat: 48.98858, lng: 20.415805 },
    { lat: 48.988366, lng: 20.415412 },
    { lat: 48.988051, lng: 20.414665 },
    { lat: 48.987896, lng: 20.414324 },
    { lat: 48.987725, lng: 20.41404 },
    { lat: 48.987576, lng: 20.413881 },
    { lat: 48.987577, lng: 20.413792 },
    { lat: 48.988717, lng: 20.412646 },
    { lat: 48.989581, lng: 20.411802 },
    { lat: 48.988929, lng: 20.410199 },
    { lat: 48.988528, lng: 20.408744 },
    { lat: 48.987849, lng: 20.407339 },
    { lat: 48.98792, lng: 20.407224 },
    { lat: 48.987368, lng: 20.40618 },
    { lat: 48.987154, lng: 20.405895 },
    { lat: 48.986631, lng: 20.405429 },
    { lat: 48.985903, lng: 20.404692 },
    { lat: 48.986035, lng: 20.404577 },
    { lat: 48.985696, lng: 20.404306 },
    { lat: 48.9855, lng: 20.403937 },
    { lat: 48.985469, lng: 20.403885 },
    { lat: 48.9852517, lng: 20.4035299 },
    { lat: 48.9850136, lng: 20.4032812 },
    { lat: 48.9840723, lng: 20.4028948 },
    { lat: 48.9839005, lng: 20.402969 },
    { lat: 48.9838286, lng: 20.4031202 },
    { lat: 48.9838243, lng: 20.4032417 },
    { lat: 48.9836656, lng: 20.4033302 },
    { lat: 48.9836007, lng: 20.4032553 },
    { lat: 48.9834604, lng: 20.4033881 },
    { lat: 48.9833394, lng: 20.4032833 },
    { lat: 48.98307, lng: 20.403336 },
    { lat: 48.9830542, lng: 20.4032739 },
    { lat: 48.9822484, lng: 20.402635 },
    { lat: 48.9814553, lng: 20.4017964 },
    { lat: 48.9784969, lng: 20.3994794 },
    { lat: 48.9754007, lng: 20.393912 },
    { lat: 48.9753809, lng: 20.3939474 },
    { lat: 48.9744862, lng: 20.3923725 },
    { lat: 48.9748043, lng: 20.3921764 },
    { lat: 48.974765, lng: 20.3918888 },
    { lat: 48.9748092, lng: 20.3917501 },
    { lat: 48.9747496, lng: 20.3916045 },
    { lat: 48.9748147, lng: 20.3914486 },
    { lat: 48.97505, lng: 20.3915375 },
    { lat: 48.9753808, lng: 20.3914994 },
    { lat: 48.9755416, lng: 20.3914343 },
    { lat: 48.9759125, lng: 20.391126 },
    { lat: 48.9763503, lng: 20.3912949 },
    { lat: 48.9764522, lng: 20.39122 },
    { lat: 48.9764744, lng: 20.3910958 },
    { lat: 48.976312, lng: 20.3903782 },
    { lat: 48.9760616, lng: 20.390106 },
    { lat: 48.9758892, lng: 20.3902509 },
    { lat: 48.9757942, lng: 20.3901213 },
    { lat: 48.9758201, lng: 20.3900309 },
    { lat: 48.9758745, lng: 20.3898409 },
    { lat: 48.9759582, lng: 20.3895484 },
    { lat: 48.9761615, lng: 20.3892434 },
    { lat: 48.9762667, lng: 20.3891932 },
    { lat: 48.9764459, lng: 20.3892592 },
    { lat: 48.9763589, lng: 20.3890736 },
    { lat: 48.9764285, lng: 20.3885671 },
    { lat: 48.9775808, lng: 20.3875163 },
    { lat: 48.977594, lng: 20.387423 },
    { lat: 48.9765501, lng: 20.3858846 },
    { lat: 48.9756675, lng: 20.3840679 },
    { lat: 48.9735958, lng: 20.3808372 },
    { lat: 48.9730307, lng: 20.3796915 },
    { lat: 48.9721281, lng: 20.3783839 },
    { lat: 48.9705935, lng: 20.3757437 },
    { lat: 48.969769, lng: 20.3749723 },
    { lat: 48.9694437, lng: 20.3745915 },
    { lat: 48.9691735, lng: 20.3739767 },
    { lat: 48.9677888, lng: 20.3712863 },
    { lat: 48.9675478, lng: 20.3705214 },
    { lat: 48.9674197, lng: 20.369998 },
    { lat: 48.9674473, lng: 20.3699243 },
    { lat: 48.9667698, lng: 20.367356 },
    { lat: 48.9663818, lng: 20.3663138 },
    { lat: 48.9666385, lng: 20.3658721 },
    { lat: 48.9663144, lng: 20.3651759 },
    { lat: 48.9658419, lng: 20.3638943 },
    { lat: 48.9656863, lng: 20.3619848 },
    { lat: 48.9656976, lng: 20.3613993 },
    { lat: 48.965602, lng: 20.3606996 },
    { lat: 48.9653858, lng: 20.3605256 },
    { lat: 48.9646614, lng: 20.3594353 },
    { lat: 48.9643019, lng: 20.3590157 },
    { lat: 48.9634271, lng: 20.3582337 },
    { lat: 48.9626459, lng: 20.3573025 },
    { lat: 48.9622511, lng: 20.3566703 },
    { lat: 48.9618666, lng: 20.3558961 },
    { lat: 48.9620689, lng: 20.3555925 },
    { lat: 48.9613694, lng: 20.3542109 },
    { lat: 48.9614881, lng: 20.3525878 },
    { lat: 48.9614155, lng: 20.3523997 },
    { lat: 48.9600891, lng: 20.3506171 },
    { lat: 48.9601806, lng: 20.3503334 },
    { lat: 48.9600302, lng: 20.3490661 },
    { lat: 48.959784, lng: 20.3486963 },
    { lat: 48.9595983, lng: 20.3480843 },
    { lat: 48.9594446, lng: 20.3479246 },
    { lat: 48.9590172, lng: 20.3465347 },
    { lat: 48.958906, lng: 20.3460179 },
    { lat: 48.958365, lng: 20.3448402 },
    { lat: 48.9580174, lng: 20.3437095 },
    { lat: 48.957982, lng: 20.3428226 },
    { lat: 48.9573267, lng: 20.3416007 },
    { lat: 48.9572202, lng: 20.3412627 },
    { lat: 48.9568605, lng: 20.340491 },
    { lat: 48.9563824, lng: 20.3387572 },
    { lat: 48.9563546, lng: 20.3378603 },
    { lat: 48.955816, lng: 20.3360208 },
    { lat: 48.9557297, lng: 20.3358597 },
    { lat: 48.9557074, lng: 20.3358911 },
    { lat: 48.9549948, lng: 20.3350808 },
    { lat: 48.9530528, lng: 20.332448 },
    { lat: 48.9529011, lng: 20.3317529 },
    { lat: 48.9526895, lng: 20.3316711 },
    { lat: 48.9523346, lng: 20.3316942 },
    { lat: 48.9517494, lng: 20.3312986 },
    { lat: 48.9515875, lng: 20.3308699 },
    { lat: 48.9513842, lng: 20.3299544 },
    { lat: 48.9510416, lng: 20.3294192 },
    { lat: 48.9504388, lng: 20.3292675 },
    { lat: 48.9499, lng: 20.3286704 },
    { lat: 48.9498298, lng: 20.3283728 },
    { lat: 48.949839, lng: 20.3280266 },
    { lat: 48.9496992, lng: 20.3272384 },
    { lat: 48.9492576, lng: 20.3267982 },
    { lat: 48.9489428, lng: 20.3260005 },
    { lat: 48.948813, lng: 20.3254715 },
    { lat: 48.9486814, lng: 20.325211 },
    { lat: 48.9486082, lng: 20.32518 },
    { lat: 48.9483536, lng: 20.3246256 },
    { lat: 48.9481086, lng: 20.3243193 },
    { lat: 48.9477987, lng: 20.3236735 },
    { lat: 48.9475589, lng: 20.3233303 },
    { lat: 48.9473673, lng: 20.3228624 },
    { lat: 48.9471266, lng: 20.3224626 },
    { lat: 48.9470628, lng: 20.3221785 },
    { lat: 48.9465314, lng: 20.3210797 },
    { lat: 48.9465894, lng: 20.3207503 },
    { lat: 48.9463248, lng: 20.32008 },
    { lat: 48.9461288, lng: 20.3190924 },
    { lat: 48.9458448, lng: 20.3185353 },
    { lat: 48.9452946, lng: 20.3166978 },
    { lat: 48.9450977, lng: 20.3163801 },
    { lat: 48.9448155, lng: 20.3160446 },
    { lat: 48.9445055, lng: 20.3160355 },
    { lat: 48.9443025, lng: 20.3157976 },
    { lat: 48.9441883, lng: 20.3153779 },
    { lat: 48.9439726, lng: 20.3152755 },
    { lat: 48.943557, lng: 20.3147818 },
    { lat: 48.942778, lng: 20.3144404 },
    { lat: 48.9420842, lng: 20.3139452 },
    { lat: 48.9418053, lng: 20.3139534 },
    { lat: 48.9417597, lng: 20.3135631 },
    { lat: 48.9413599, lng: 20.312871 },
    { lat: 48.9411242, lng: 20.3123072 },
    { lat: 48.9409201, lng: 20.3120404 },
    { lat: 48.9399941, lng: 20.3100935 },
    { lat: 48.9391756, lng: 20.3080454 },
    { lat: 48.9388564, lng: 20.3071037 },
    { lat: 48.938508, lng: 20.3064891 },
    { lat: 48.9384175, lng: 20.3059592 },
    { lat: 48.9381865, lng: 20.3057475 },
    { lat: 48.9378015, lng: 20.3050901 },
    { lat: 48.9375315, lng: 20.3049184 },
    { lat: 48.9369175, lng: 20.3050266 },
    { lat: 48.936421, lng: 20.3048881 },
    { lat: 48.936109, lng: 20.304218 },
    { lat: 48.935365, lng: 20.304749 },
    { lat: 48.935179, lng: 20.304944 },
    { lat: 48.935029, lng: 20.305438 },
    { lat: 48.934818, lng: 20.305617 },
    { lat: 48.934664, lng: 20.305832 },
    { lat: 48.934295, lng: 20.306205 },
    { lat: 48.933799, lng: 20.306689 },
    { lat: 48.933276, lng: 20.306552 },
    { lat: 48.932884, lng: 20.30719 },
    { lat: 48.932844, lng: 20.308162 },
    { lat: 48.932495, lng: 20.308833 },
    { lat: 48.932293, lng: 20.309775 },
    { lat: 48.932364, lng: 20.310065 },
    { lat: 48.932049, lng: 20.310692 },
    { lat: 48.93146, lng: 20.310895 },
    { lat: 48.931545, lng: 20.311409 },
    { lat: 48.931842, lng: 20.312172 },
    { lat: 48.931977, lng: 20.312269 },
    { lat: 48.932079, lng: 20.312452 },
    { lat: 48.93216, lng: 20.312686 },
    { lat: 48.932138, lng: 20.313017 },
    { lat: 48.932197, lng: 20.313404 },
    { lat: 48.932496, lng: 20.314009 },
    { lat: 48.933071, lng: 20.314857 },
    { lat: 48.933092, lng: 20.314856 },
    { lat: 48.933237, lng: 20.315706 },
    { lat: 48.933327, lng: 20.316079 },
    { lat: 48.933454, lng: 20.316445 },
    { lat: 48.933571, lng: 20.316805 },
    { lat: 48.933718, lng: 20.317091 },
    { lat: 48.933901, lng: 20.317277 },
    { lat: 48.933996, lng: 20.317412 },
    { lat: 48.934087, lng: 20.317571 },
    { lat: 48.934206, lng: 20.317747 },
    { lat: 48.934312, lng: 20.318021 },
    { lat: 48.934371, lng: 20.318236 },
    { lat: 48.934418, lng: 20.318405 },
    { lat: 48.934554, lng: 20.318984 },
    { lat: 48.934485, lng: 20.31966 },
    { lat: 48.934477, lng: 20.319876 },
    { lat: 48.934452, lng: 20.320061 },
    { lat: 48.934439, lng: 20.320188 },
    { lat: 48.934482, lng: 20.320509 },
    { lat: 48.934538, lng: 20.320649 },
    { lat: 48.934616, lng: 20.320782 },
    { lat: 48.934799, lng: 20.321028 },
    { lat: 48.934887, lng: 20.321149 },
    { lat: 48.935178, lng: 20.321712 },
    { lat: 48.935224, lng: 20.321822 },
    { lat: 48.935406, lng: 20.322081 },
    { lat: 48.935449, lng: 20.322221 },
    { lat: 48.935686, lng: 20.322604 },
    { lat: 48.93583, lng: 20.32279 },
    { lat: 48.935939, lng: 20.323104 },
    { lat: 48.935992, lng: 20.323322 },
    { lat: 48.935988, lng: 20.323408 },
    { lat: 48.935947, lng: 20.323676 },
    { lat: 48.935899, lng: 20.323814 },
    { lat: 48.935788, lng: 20.323973 },
    { lat: 48.935549, lng: 20.32428 },
    { lat: 48.935365, lng: 20.324615 },
    { lat: 48.935249, lng: 20.324893 },
    { lat: 48.93522, lng: 20.325316 },
    { lat: 48.935279, lng: 20.325711 },
    { lat: 48.935223, lng: 20.326029 },
    { lat: 48.935212, lng: 20.326026 },
    { lat: 48.935075, lng: 20.326154 },
    { lat: 48.934896, lng: 20.326228 },
    { lat: 48.934781, lng: 20.326533 },
    { lat: 48.934719, lng: 20.327555 },
    { lat: 48.934687, lng: 20.327764 },
    { lat: 48.934574, lng: 20.32798 },
    { lat: 48.934472, lng: 20.328202 },
    { lat: 48.934355, lng: 20.328407 },
    { lat: 48.934188, lng: 20.328613 },
    { lat: 48.933979, lng: 20.329009 },
    { lat: 48.934007, lng: 20.329102 },
    { lat: 48.933722, lng: 20.329977 },
    { lat: 48.933648, lng: 20.330131 },
    { lat: 48.933557, lng: 20.330458 },
    { lat: 48.933292, lng: 20.331851 },
    { lat: 48.93331, lng: 20.332139 },
    { lat: 48.933351, lng: 20.332607 },
    { lat: 48.933357, lng: 20.333048 },
    { lat: 48.93329, lng: 20.333254 },
    { lat: 48.933262, lng: 20.33328 },
    { lat: 48.933252, lng: 20.333288 },
    { lat: 48.933222, lng: 20.333315 },
    { lat: 48.933209, lng: 20.333326 },
    { lat: 48.933187, lng: 20.333281 },
    { lat: 48.933149, lng: 20.333201 },
    { lat: 48.933129, lng: 20.333159 },
    { lat: 48.933121, lng: 20.333178 },
    { lat: 48.933088, lng: 20.333257 },
    { lat: 48.933058, lng: 20.333329 },
    { lat: 48.933029, lng: 20.333396 },
    { lat: 48.932934, lng: 20.333623 },
    { lat: 48.932853, lng: 20.333935 },
    { lat: 48.932812, lng: 20.334091 },
    { lat: 48.932781, lng: 20.334208 },
    { lat: 48.932597, lng: 20.334275 },
    { lat: 48.932586, lng: 20.334385 },
    { lat: 48.932196, lng: 20.334252 },
    { lat: 48.93199, lng: 20.334247 },
    { lat: 48.931938, lng: 20.33427 },
    { lat: 48.931736, lng: 20.334445 },
    { lat: 48.93122, lng: 20.335068 },
    { lat: 48.931186, lng: 20.335093 },
    { lat: 48.931149, lng: 20.335102 },
    { lat: 48.930844, lng: 20.33528 },
    { lat: 48.93061, lng: 20.335711 },
    { lat: 48.930583, lng: 20.335746 },
    { lat: 48.930236, lng: 20.336175 },
    { lat: 48.930036, lng: 20.336282 },
    { lat: 48.929829, lng: 20.33632 },
    { lat: 48.929587, lng: 20.336216 },
    { lat: 48.929447, lng: 20.336253 },
    { lat: 48.92931, lng: 20.336315 },
    { lat: 48.929173, lng: 20.336421 },
    { lat: 48.929051, lng: 20.336553 },
    { lat: 48.928763, lng: 20.337097 },
    { lat: 48.92855, lng: 20.337351 },
    { lat: 48.928378, lng: 20.337463 },
    { lat: 48.928339, lng: 20.337499 },
    { lat: 48.928284, lng: 20.337568 },
    { lat: 48.928232, lng: 20.337659 },
    { lat: 48.928102, lng: 20.338256 },
    { lat: 48.927957, lng: 20.338543 },
    { lat: 48.927932, lng: 20.338575 },
    { lat: 48.927837, lng: 20.338601 },
    { lat: 48.927743, lng: 20.3386 },
    { lat: 48.927509, lng: 20.338506 },
    { lat: 48.92715, lng: 20.33845 },
    { lat: 48.92699, lng: 20.338362 },
    { lat: 48.926875, lng: 20.338362 },
    { lat: 48.926829, lng: 20.338371 },
    { lat: 48.926708, lng: 20.338415 },
    { lat: 48.926591, lng: 20.338554 },
    { lat: 48.926514, lng: 20.338644 },
    { lat: 48.926374, lng: 20.338937 },
    { lat: 48.92619, lng: 20.339343 },
    { lat: 48.925881, lng: 20.339665 },
    { lat: 48.925639, lng: 20.339826 },
    { lat: 48.925542, lng: 20.33995 },
    { lat: 48.925422, lng: 20.340296 },
    { lat: 48.925416, lng: 20.340476 },
    { lat: 48.925364, lng: 20.340865 },
    { lat: 48.92529, lng: 20.341143 },
    { lat: 48.925272, lng: 20.341194 },
    { lat: 48.925242, lng: 20.341232 },
    { lat: 48.92519, lng: 20.34126 },
    { lat: 48.925144, lng: 20.341263 },
    { lat: 48.924769, lng: 20.341093 },
    { lat: 48.924702, lng: 20.341089 },
    { lat: 48.924621, lng: 20.341113 },
    { lat: 48.924543, lng: 20.341253 },
    { lat: 48.924523, lng: 20.341296 },
    { lat: 48.924345, lng: 20.341905 },
    { lat: 48.924292, lng: 20.34198 },
    { lat: 48.92391, lng: 20.342148 },
    { lat: 48.923746, lng: 20.342164 },
    { lat: 48.923662, lng: 20.342268 },
    { lat: 48.923742, lng: 20.342396 },
    { lat: 48.923831, lng: 20.342706 },
    { lat: 48.92349, lng: 20.342967 },
    { lat: 48.923146, lng: 20.343526 },
    { lat: 48.92295, lng: 20.344344 },
    { lat: 48.922872, lng: 20.345273 },
    { lat: 48.922483, lng: 20.346766 },
    { lat: 48.922602, lng: 20.347147 },
    { lat: 48.922425, lng: 20.34859 },
    { lat: 48.921674, lng: 20.349356 },
    { lat: 48.920681, lng: 20.349505 },
    { lat: 48.920483, lng: 20.351205 },
    { lat: 48.919893, lng: 20.352338 },
    { lat: 48.919805, lng: 20.352955 },
    { lat: 48.919758, lng: 20.354199 },
    { lat: 48.919752, lng: 20.354378 },
    { lat: 48.919529, lng: 20.354546 },
    { lat: 48.919367, lng: 20.354806 },
    { lat: 48.919216, lng: 20.355984 },
    { lat: 48.919224, lng: 20.356224 },
    { lat: 48.919329, lng: 20.356578 },
    { lat: 48.91939, lng: 20.356798 },
    { lat: 48.919417, lng: 20.356964 },
    { lat: 48.919291, lng: 20.357075 },
    { lat: 48.919192, lng: 20.357204 },
    { lat: 48.919114, lng: 20.357406 },
    { lat: 48.919145, lng: 20.357637 },
    { lat: 48.919215, lng: 20.358 },
    { lat: 48.919241, lng: 20.358379 },
    { lat: 48.9192, lng: 20.358848 },
    { lat: 48.918969, lng: 20.359506 },
    { lat: 48.918974, lng: 20.360192 },
    { lat: 48.918835, lng: 20.361399 },
    { lat: 48.918676, lng: 20.362466 },
    { lat: 48.91804, lng: 20.362885 },
    { lat: 48.917856, lng: 20.36357 },
    { lat: 48.917217, lng: 20.364015 },
    { lat: 48.917099, lng: 20.364598 },
    { lat: 48.916874, lng: 20.365279 },
    { lat: 48.916164, lng: 20.365881 },
    { lat: 48.916089, lng: 20.36604 },
    { lat: 48.916141, lng: 20.366602 },
    { lat: 48.915621, lng: 20.367558 },
    { lat: 48.915423, lng: 20.368078 },
    { lat: 48.915248, lng: 20.368281 },
    { lat: 48.914998, lng: 20.368597 },
    { lat: 48.914778, lng: 20.368962 },
    { lat: 48.914625, lng: 20.369216 },
    { lat: 48.914522, lng: 20.369388 },
    { lat: 48.914341, lng: 20.369928 },
    { lat: 48.914395, lng: 20.370447 },
    { lat: 48.914378, lng: 20.370688 },
    { lat: 48.91434, lng: 20.371008 },
    { lat: 48.914301, lng: 20.371296 },
    { lat: 48.91427, lng: 20.371551 },
    { lat: 48.914266, lng: 20.371574 },
    { lat: 48.914204, lng: 20.371931 },
    { lat: 48.913836, lng: 20.372373 },
    { lat: 48.913547, lng: 20.372939 },
    { lat: 48.913332, lng: 20.373419 },
    { lat: 48.913204, lng: 20.373759 },
    { lat: 48.913138, lng: 20.373985 },
    { lat: 48.912784, lng: 20.374193 },
    { lat: 48.912481, lng: 20.37473 },
    { lat: 48.912134, lng: 20.375334 },
    { lat: 48.911935, lng: 20.375384 },
    { lat: 48.911535, lng: 20.375287 },
    { lat: 48.911285, lng: 20.3753 },
    { lat: 48.910903, lng: 20.37523 },
    { lat: 48.910695, lng: 20.375715 },
    { lat: 48.911018, lng: 20.376474 },
    { lat: 48.911128, lng: 20.37742 },
    { lat: 48.911048, lng: 20.377645 },
    { lat: 48.911227, lng: 20.378068 },
    { lat: 48.911373, lng: 20.378411 },
    { lat: 48.911422, lng: 20.378692 },
    { lat: 48.911611, lng: 20.379253 },
    { lat: 48.911894, lng: 20.379969 },
    { lat: 48.911919, lng: 20.380092 },
    { lat: 48.912181, lng: 20.381639 },
    { lat: 48.912159, lng: 20.382608 },
    { lat: 48.912339, lng: 20.383744 },
    { lat: 48.912485, lng: 20.384116 },
    { lat: 48.912617, lng: 20.384644 },
    { lat: 48.912588, lng: 20.385127 },
    { lat: 48.912511, lng: 20.385493 },
    { lat: 48.912529, lng: 20.385658 },
    { lat: 48.91253, lng: 20.385866 },
    { lat: 48.912358, lng: 20.38625 },
    { lat: 48.912517, lng: 20.387013 },
    { lat: 48.91245, lng: 20.387468 },
    { lat: 48.91201, lng: 20.387523 },
    { lat: 48.91125, lng: 20.388018 },
    { lat: 48.911209, lng: 20.388199 },
    { lat: 48.911323, lng: 20.388973 },
    { lat: 48.911379, lng: 20.388966 },
    { lat: 48.911343, lng: 20.389921 },
    { lat: 48.911342, lng: 20.389937 },
    { lat: 48.911337, lng: 20.390029 },
    { lat: 48.911337, lng: 20.390043 },
    { lat: 48.91126, lng: 20.391438 },
    { lat: 48.91126, lng: 20.391559 },
  ],
  Kaľava: [
    { lat: 48.9485991, lng: 20.8501328 },
    { lat: 48.9485684, lng: 20.8500901 },
    { lat: 48.9484006, lng: 20.8498867 },
    { lat: 48.9481852, lng: 20.8498775 },
    { lat: 48.9481507, lng: 20.8496554 },
    { lat: 48.9479423, lng: 20.849487 },
    { lat: 48.947726, lng: 20.8494544 },
    { lat: 48.9475712, lng: 20.8492044 },
    { lat: 48.9469651, lng: 20.8489941 },
    { lat: 48.9466425, lng: 20.8486783 },
    { lat: 48.946561, lng: 20.8484678 },
    { lat: 48.9462743, lng: 20.848542 },
    { lat: 48.9462288, lng: 20.8484317 },
    { lat: 48.9459092, lng: 20.8483628 },
    { lat: 48.9457832, lng: 20.8484311 },
    { lat: 48.945533, lng: 20.8482242 },
    { lat: 48.9448524, lng: 20.8480101 },
    { lat: 48.944778, lng: 20.8482058 },
    { lat: 48.9446923, lng: 20.8482666 },
    { lat: 48.9445046, lng: 20.848039 },
    { lat: 48.9444657, lng: 20.8476955 },
    { lat: 48.9443623, lng: 20.8476653 },
    { lat: 48.9441791, lng: 20.8478753 },
    { lat: 48.9439437, lng: 20.847798 },
    { lat: 48.943648, lng: 20.8469892 },
    { lat: 48.9435305, lng: 20.8469121 },
    { lat: 48.9434104, lng: 20.8469256 },
    { lat: 48.9432103, lng: 20.8465991 },
    { lat: 48.9429491, lng: 20.8465023 },
    { lat: 48.9427926, lng: 20.8461054 },
    { lat: 48.9425871, lng: 20.8460557 },
    { lat: 48.9420819, lng: 20.845637 },
    { lat: 48.9416718, lng: 20.845428 },
    { lat: 48.9415636, lng: 20.8452916 },
    { lat: 48.9413828, lng: 20.8448762 },
    { lat: 48.9411948, lng: 20.8448127 },
    { lat: 48.9409833, lng: 20.8448494 },
    { lat: 48.9408489, lng: 20.8445084 },
    { lat: 48.9407446, lng: 20.8444175 },
    { lat: 48.9403345, lng: 20.8446393 },
    { lat: 48.9401267, lng: 20.8443575 },
    { lat: 48.9398979, lng: 20.8443155 },
    { lat: 48.9398859, lng: 20.8437686 },
    { lat: 48.9394027, lng: 20.843564 },
    { lat: 48.9391021, lng: 20.8435262 },
    { lat: 48.9385542, lng: 20.8431088 },
    { lat: 48.9385373, lng: 20.8431119 },
    { lat: 48.9368655, lng: 20.8433344 },
    { lat: 48.9361365, lng: 20.8448546 },
    { lat: 48.9350082, lng: 20.8470042 },
    { lat: 48.934637, lng: 20.8473787 },
    { lat: 48.9344156, lng: 20.850111 },
    { lat: 48.9338144, lng: 20.8548033 },
    { lat: 48.9329183, lng: 20.8582915 },
    { lat: 48.9323629, lng: 20.8614724 },
    { lat: 48.9319578, lng: 20.8621669 },
    { lat: 48.9316578, lng: 20.86317 },
    { lat: 48.931669, lng: 20.8639596 },
    { lat: 48.9316268, lng: 20.8642718 },
    { lat: 48.9315562, lng: 20.8645101 },
    { lat: 48.9307858, lng: 20.865422 },
    { lat: 48.9304941, lng: 20.8658799 },
    { lat: 48.9299911, lng: 20.8668366 },
    { lat: 48.9298582, lng: 20.8669716 },
    { lat: 48.9297635, lng: 20.8671978 },
    { lat: 48.9292444, lng: 20.8680147 },
    { lat: 48.9290482, lng: 20.8682349 },
    { lat: 48.9286281, lng: 20.8684923 },
    { lat: 48.9284858, lng: 20.8688411 },
    { lat: 48.9286288, lng: 20.8690718 },
    { lat: 48.9284224, lng: 20.8694249 },
    { lat: 48.9283411, lng: 20.8696829 },
    { lat: 48.9283892, lng: 20.8698692 },
    { lat: 48.9285082, lng: 20.8700582 },
    { lat: 48.9285814, lng: 20.870089 },
    { lat: 48.9287551, lng: 20.8700019 },
    { lat: 48.9288547, lng: 20.8700525 },
    { lat: 48.9289454, lng: 20.8704702 },
    { lat: 48.9293253, lng: 20.871112 },
    { lat: 48.9294427, lng: 20.8716508 },
    { lat: 48.9292164, lng: 20.8717781 },
    { lat: 48.9295315, lng: 20.8723093 },
    { lat: 48.929357, lng: 20.872734 },
    { lat: 48.9293799, lng: 20.8730107 },
    { lat: 48.9292876, lng: 20.87314 },
    { lat: 48.929382, lng: 20.8735027 },
    { lat: 48.9295022, lng: 20.873692 },
    { lat: 48.9294824, lng: 20.8737705 },
    { lat: 48.9299063, lng: 20.8746844 },
    { lat: 48.9298045, lng: 20.8755952 },
    { lat: 48.929854, lng: 20.877142 },
    { lat: 48.929881, lng: 20.877636 },
    { lat: 48.929896, lng: 20.87809 },
    { lat: 48.929885, lng: 20.878315 },
    { lat: 48.929651, lng: 20.878937 },
    { lat: 48.929415, lng: 20.879237 },
    { lat: 48.929144, lng: 20.87953 },
    { lat: 48.929006, lng: 20.879822 },
    { lat: 48.928699, lng: 20.880822 },
    { lat: 48.928707, lng: 20.881084 },
    { lat: 48.928784, lng: 20.881361 },
    { lat: 48.929061, lng: 20.881884 },
    { lat: 48.929095, lng: 20.882368 },
    { lat: 48.929091, lng: 20.883105 },
    { lat: 48.929049, lng: 20.884127 },
    { lat: 48.929046, lng: 20.884646 },
    { lat: 48.929041, lng: 20.885062 },
    { lat: 48.929029, lng: 20.885548 },
    { lat: 48.92909, lng: 20.885907 },
    { lat: 48.92923, lng: 20.886225 },
    { lat: 48.929282, lng: 20.886464 },
    { lat: 48.929314, lng: 20.886919 },
    { lat: 48.929316, lng: 20.886958 },
    { lat: 48.929338, lng: 20.88697 },
    { lat: 48.929351, lng: 20.886977 },
    { lat: 48.931175, lng: 20.887874 },
    { lat: 48.931453, lng: 20.888025 },
    { lat: 48.932304, lng: 20.888701 },
    { lat: 48.932559, lng: 20.888921 },
    { lat: 48.932579, lng: 20.888942 },
    { lat: 48.932843, lng: 20.88913 },
    { lat: 48.932845, lng: 20.889132 },
    { lat: 48.933364, lng: 20.889618 },
    { lat: 48.933613, lng: 20.889885 },
    { lat: 48.933707, lng: 20.890008 },
    { lat: 48.935174, lng: 20.891916 },
    { lat: 48.935864, lng: 20.89314 },
    { lat: 48.935893, lng: 20.893485 },
    { lat: 48.935962, lng: 20.894046 },
    { lat: 48.937151, lng: 20.894083 },
    { lat: 48.937769, lng: 20.893783 },
    { lat: 48.93887, lng: 20.89339 },
    { lat: 48.93891, lng: 20.893066 },
    { lat: 48.939083, lng: 20.892151 },
    { lat: 48.939035, lng: 20.891159 },
    { lat: 48.938988, lng: 20.890862 },
    { lat: 48.938978, lng: 20.890338 },
    { lat: 48.939061, lng: 20.889542 },
    { lat: 48.939901, lng: 20.88872 },
    { lat: 48.941963, lng: 20.890379 },
    { lat: 48.943219, lng: 20.890833 },
    { lat: 48.945658, lng: 20.888223 },
    { lat: 48.9451968, lng: 20.8875091 },
    { lat: 48.9448397, lng: 20.8876989 },
    { lat: 48.9442927, lng: 20.8875361 },
    { lat: 48.9430511, lng: 20.8859732 },
    { lat: 48.942358, lng: 20.8852555 },
    { lat: 48.9425363, lng: 20.8847044 },
    { lat: 48.941802, lng: 20.8841632 },
    { lat: 48.9420251, lng: 20.8829753 },
    { lat: 48.9412105, lng: 20.8822549 },
    { lat: 48.9412681, lng: 20.8818322 },
    { lat: 48.9417934, lng: 20.8810928 },
    { lat: 48.9415587, lng: 20.8806816 },
    { lat: 48.9411015, lng: 20.8802415 },
    { lat: 48.939241, lng: 20.8792502 },
    { lat: 48.9395286, lng: 20.8782402 },
    { lat: 48.9396826, lng: 20.8781024 },
    { lat: 48.9401252, lng: 20.8765234 },
    { lat: 48.9403896, lng: 20.8762273 },
    { lat: 48.9409256, lng: 20.8750391 },
    { lat: 48.9412331, lng: 20.8746838 },
    { lat: 48.941563, lng: 20.8740571 },
    { lat: 48.9416209, lng: 20.8737063 },
    { lat: 48.9419667, lng: 20.8731542 },
    { lat: 48.9422975, lng: 20.8728178 },
    { lat: 48.9419092, lng: 20.8720273 },
    { lat: 48.9418959, lng: 20.8718643 },
    { lat: 48.941768, lng: 20.8716503 },
    { lat: 48.9409266, lng: 20.8702422 },
    { lat: 48.9408237, lng: 20.8696033 },
    { lat: 48.9405878, lng: 20.8687238 },
    { lat: 48.9403184, lng: 20.8669679 },
    { lat: 48.9402738, lng: 20.8669464 },
    { lat: 48.9399814, lng: 20.8661312 },
    { lat: 48.940863, lng: 20.8651137 },
    { lat: 48.9413116, lng: 20.864705 },
    { lat: 48.9417135, lng: 20.8641463 },
    { lat: 48.9419378, lng: 20.8637222 },
    { lat: 48.9423569, lng: 20.862485 },
    { lat: 48.9424854, lng: 20.8618619 },
    { lat: 48.9426999, lng: 20.8612571 },
    { lat: 48.9431773, lng: 20.8606655 },
    { lat: 48.9437195, lng: 20.8597992 },
    { lat: 48.9438545, lng: 20.859534 },
    { lat: 48.9441596, lng: 20.8586119 },
    { lat: 48.9445222, lng: 20.8579248 },
    { lat: 48.9447487, lng: 20.8575736 },
    { lat: 48.9453217, lng: 20.8570177 },
    { lat: 48.9455839, lng: 20.8566136 },
    { lat: 48.9465347, lng: 20.8556831 },
    { lat: 48.9466395, lng: 20.8553271 },
    { lat: 48.9467579, lng: 20.8553976 },
    { lat: 48.9470884, lng: 20.8537074 },
    { lat: 48.9473983, lng: 20.8530337 },
    { lat: 48.947609, lng: 20.8521809 },
    { lat: 48.9480264, lng: 20.8509924 },
    { lat: 48.9481968, lng: 20.8507366 },
    { lat: 48.9485852, lng: 20.8501536 },
    { lat: 48.9485991, lng: 20.8501328 },
  ],
  SpišskéTomášovce: [
    { lat: 48.9768853, lng: 20.4771945 },
    { lat: 48.9768764, lng: 20.4752545 },
    { lat: 48.9765152, lng: 20.4752596 },
    { lat: 48.9756899, lng: 20.4751349 },
    { lat: 48.9746228, lng: 20.474847 },
    { lat: 48.9743978, lng: 20.4747292 },
    { lat: 48.9750671, lng: 20.4740799 },
    { lat: 48.9754598, lng: 20.4735356 },
    { lat: 48.9763774, lng: 20.471849 },
    { lat: 48.9752435, lng: 20.4702584 },
    { lat: 48.9742739, lng: 20.4687514 },
    { lat: 48.971748, lng: 20.4644853 },
    { lat: 48.9710248, lng: 20.4636632 },
    { lat: 48.9709243, lng: 20.4633848 },
    { lat: 48.9700934, lng: 20.4628795 },
    { lat: 48.9703318, lng: 20.4617895 },
    { lat: 48.9679151, lng: 20.4604356 },
    { lat: 48.9660535, lng: 20.4591361 },
    { lat: 48.9645025, lng: 20.4577138 },
    { lat: 48.9639614, lng: 20.4571283 },
    { lat: 48.9638809, lng: 20.4569647 },
    { lat: 48.9637411, lng: 20.4556701 },
    { lat: 48.9635408, lng: 20.4522676 },
    { lat: 48.9630835, lng: 20.4504401 },
    { lat: 48.962903, lng: 20.4493887 },
    { lat: 48.9627901, lng: 20.4483343 },
    { lat: 48.9618559, lng: 20.4461366 },
    { lat: 48.9615691, lng: 20.4457259 },
    { lat: 48.9611762, lng: 20.4453982 },
    { lat: 48.9611928, lng: 20.4452072 },
    { lat: 48.9610953, lng: 20.4449552 },
    { lat: 48.9610818, lng: 20.4446554 },
    { lat: 48.9610185, lng: 20.4445033 },
    { lat: 48.9609862, lng: 20.4442087 },
    { lat: 48.9610095, lng: 20.4438781 },
    { lat: 48.9609176, lng: 20.4435872 },
    { lat: 48.9610635, lng: 20.4433395 },
    { lat: 48.9611896, lng: 20.4432798 },
    { lat: 48.9612061, lng: 20.4431465 },
    { lat: 48.9611375, lng: 20.4429686 },
    { lat: 48.960987, lng: 20.442919 },
    { lat: 48.960968, lng: 20.4428336 },
    { lat: 48.9610195, lng: 20.4427098 },
    { lat: 48.9609473, lng: 20.442499 },
    { lat: 48.9607566, lng: 20.4424848 },
    { lat: 48.9606914, lng: 20.4421017 },
    { lat: 48.9607402, lng: 20.4419312 },
    { lat: 48.9607236, lng: 20.4417595 },
    { lat: 48.9605524, lng: 20.4419063 },
    { lat: 48.9604157, lng: 20.4416559 },
    { lat: 48.9604201, lng: 20.4415649 },
    { lat: 48.9602916, lng: 20.4414435 },
    { lat: 48.9603549, lng: 20.4410876 },
    { lat: 48.9600764, lng: 20.4409607 },
    { lat: 48.9597655, lng: 20.4410759 },
    { lat: 48.9596467, lng: 20.4410339 },
    { lat: 48.9596252, lng: 20.4408212 },
    { lat: 48.9592567, lng: 20.4408092 },
    { lat: 48.9591965, lng: 20.4405898 },
    { lat: 48.9586191, lng: 20.4406842 },
    { lat: 48.9584515, lng: 20.4405673 },
    { lat: 48.9583392, lng: 20.4403378 },
    { lat: 48.9576988, lng: 20.4398117 },
    { lat: 48.9575148, lng: 20.4398575 },
    { lat: 48.957095, lng: 20.4397418 },
    { lat: 48.9569345, lng: 20.4397841 },
    { lat: 48.9567724, lng: 20.4396968 },
    { lat: 48.9565313, lng: 20.4397866 },
    { lat: 48.9560448, lng: 20.4403152 },
    { lat: 48.955638, lng: 20.4405775 },
    { lat: 48.955453, lng: 20.4407777 },
    { lat: 48.9554223, lng: 20.4408098 },
    { lat: 48.9550213, lng: 20.4411536 },
    { lat: 48.9547034, lng: 20.4417688 },
    { lat: 48.9545179, lng: 20.4419229 },
    { lat: 48.9545964, lng: 20.442542 },
    { lat: 48.9545132, lng: 20.4428118 },
    { lat: 48.9539305, lng: 20.4426752 },
    { lat: 48.9535843, lng: 20.442456 },
    { lat: 48.9532596, lng: 20.4423535 },
    { lat: 48.9528546, lng: 20.4428065 },
    { lat: 48.9528239, lng: 20.4436948 },
    { lat: 48.9531237, lng: 20.4449857 },
    { lat: 48.9533625, lng: 20.4455542 },
    { lat: 48.9533929, lng: 20.4458213 },
    { lat: 48.9533696, lng: 20.4461927 },
    { lat: 48.9531712, lng: 20.4465758 },
    { lat: 48.9527666, lng: 20.4469495 },
    { lat: 48.9521601, lng: 20.4472595 },
    { lat: 48.9520596, lng: 20.4480535 },
    { lat: 48.9521193, lng: 20.4483224 },
    { lat: 48.952548, lng: 20.4486176 },
    { lat: 48.9527701, lng: 20.448896 },
    { lat: 48.952891, lng: 20.4492047 },
    { lat: 48.952792, lng: 20.450163 },
    { lat: 48.9527487, lng: 20.4511932 },
    { lat: 48.9527291, lng: 20.4512529 },
    { lat: 48.9526899, lng: 20.4513729 },
    { lat: 48.9526216, lng: 20.4515814 },
    { lat: 48.9524057, lng: 20.4518005 },
    { lat: 48.9518518, lng: 20.4519224 },
    { lat: 48.9511158, lng: 20.4513627 },
    { lat: 48.9508718, lng: 20.4511041 },
    { lat: 48.9503838, lng: 20.4510022 },
    { lat: 48.9500723, lng: 20.450801 },
    { lat: 48.9499963, lng: 20.4508187 },
    { lat: 48.9494898, lng: 20.4513901 },
    { lat: 48.9490875, lng: 20.4515031 },
    { lat: 48.9487732, lng: 20.4522161 },
    { lat: 48.9486243, lng: 20.4529222 },
    { lat: 48.9487193, lng: 20.453322 },
    { lat: 48.9490222, lng: 20.4541901 },
    { lat: 48.9490841, lng: 20.4547877 },
    { lat: 48.9490569, lng: 20.4548731 },
    { lat: 48.9489186, lng: 20.4549969 },
    { lat: 48.9484973, lng: 20.4550174 },
    { lat: 48.9482491, lng: 20.4557837 },
    { lat: 48.9480336, lng: 20.4558919 },
    { lat: 48.947866, lng: 20.4558334 },
    { lat: 48.9476124, lng: 20.4555588 },
    { lat: 48.9475171, lng: 20.45536 },
    { lat: 48.9471569, lng: 20.4553054 },
    { lat: 48.9467508, lng: 20.4550157 },
    { lat: 48.9463578, lng: 20.4548367 },
    { lat: 48.9460798, lng: 20.4549296 },
    { lat: 48.9450618, lng: 20.4564458 },
    { lat: 48.9450245, lng: 20.4567732 },
    { lat: 48.9448742, lng: 20.4569424 },
    { lat: 48.9446693, lng: 20.4570003 },
    { lat: 48.9443321, lng: 20.457314 },
    { lat: 48.9437453, lng: 20.4557223 },
    { lat: 48.9432623, lng: 20.4537541 },
    { lat: 48.9431755, lng: 20.4535997 },
    { lat: 48.9426509, lng: 20.4532774 },
    { lat: 48.9424376, lng: 20.4528436 },
    { lat: 48.9422319, lng: 20.4525516 },
    { lat: 48.9419523, lng: 20.4517686 },
    { lat: 48.9417864, lng: 20.4510101 },
    { lat: 48.9416613, lng: 20.4507394 },
    { lat: 48.9414406, lng: 20.4504377 },
    { lat: 48.9413624, lng: 20.4499778 },
    { lat: 48.9413774, lng: 20.4498078 },
    { lat: 48.9412792, lng: 20.4495051 },
    { lat: 48.9412468, lng: 20.4491533 },
    { lat: 48.9412366, lng: 20.4480457 },
    { lat: 48.9411068, lng: 20.4475322 },
    { lat: 48.9410169, lng: 20.4473259 },
    { lat: 48.9406641, lng: 20.4469926 },
    { lat: 48.9402428, lng: 20.4464759 },
    { lat: 48.9401443, lng: 20.4461912 },
    { lat: 48.9400082, lng: 20.4454226 },
    { lat: 48.9401422, lng: 20.4444969 },
    { lat: 48.9402577, lng: 20.4442451 },
    { lat: 48.940268, lng: 20.4438932 },
    { lat: 48.9406692, lng: 20.443129 },
    { lat: 48.9407703, lng: 20.4426442 },
    { lat: 48.9410283, lng: 20.4423219 },
    { lat: 48.9411447, lng: 20.4420618 },
    { lat: 48.9412538, lng: 20.4419706 },
    { lat: 48.9414086, lng: 20.4411534 },
    { lat: 48.9415204, lng: 20.4408908 },
    { lat: 48.9415125, lng: 20.4408324 },
    { lat: 48.9413871, lng: 20.4407606 },
    { lat: 48.941253, lng: 20.4404893 },
    { lat: 48.9411244, lng: 20.4397383 },
    { lat: 48.9410693, lng: 20.4387564 },
    { lat: 48.9409396, lng: 20.4386344 },
    { lat: 48.9407407, lng: 20.4377527 },
    { lat: 48.9407346, lng: 20.437562 },
    { lat: 48.940926, lng: 20.436964 },
    { lat: 48.9411141, lng: 20.4366965 },
    { lat: 48.9411986, lng: 20.4364058 },
    { lat: 48.9409714, lng: 20.4357827 },
    { lat: 48.9405013, lng: 20.4349932 },
    { lat: 48.9403357, lng: 20.4348128 },
    { lat: 48.9398296, lng: 20.4347143 },
    { lat: 48.9395666, lng: 20.434516 },
    { lat: 48.9390862, lng: 20.4336059 },
    { lat: 48.9390474, lng: 20.4334721 },
    { lat: 48.9390785, lng: 20.4332842 },
    { lat: 48.9390285, lng: 20.4327058 },
    { lat: 48.9389544, lng: 20.4324988 },
    { lat: 48.9386188, lng: 20.4321743 },
    { lat: 48.9383515, lng: 20.43127 },
    { lat: 48.9381101, lng: 20.4294777 },
    { lat: 48.9380406, lng: 20.4292184 },
    { lat: 48.9377501, lng: 20.4286492 },
    { lat: 48.9377653, lng: 20.4285788 },
    { lat: 48.9372281, lng: 20.4279191 },
    { lat: 48.9370431, lng: 20.4274084 },
    { lat: 48.9368639, lng: 20.4271386 },
    { lat: 48.9368269, lng: 20.4267235 },
    { lat: 48.9366824, lng: 20.4265345 },
    { lat: 48.9358236, lng: 20.4264534 },
    { lat: 48.9357315, lng: 20.4262824 },
    { lat: 48.9356891, lng: 20.425967 },
    { lat: 48.9355558, lng: 20.4256567 },
    { lat: 48.9353745, lng: 20.4254505 },
    { lat: 48.9348158, lng: 20.4252623 },
    { lat: 48.934424, lng: 20.4252116 },
    { lat: 48.9342231, lng: 20.4254946 },
    { lat: 48.9341276, lng: 20.4255273 },
    { lat: 48.9333086, lng: 20.4254563 },
    { lat: 48.9324236, lng: 20.4256633 },
    { lat: 48.9321443, lng: 20.4255452 },
    { lat: 48.9319009, lng: 20.4255371 },
    { lat: 48.9318052, lng: 20.4257188 },
    { lat: 48.9309583, lng: 20.4258598 },
    { lat: 48.9307644, lng: 20.4257922 },
    { lat: 48.9304267, lng: 20.4258282 },
    { lat: 48.9302662, lng: 20.4256044 },
    { lat: 48.9301474, lng: 20.4255876 },
    { lat: 48.929941, lng: 20.4257879 },
    { lat: 48.9293826, lng: 20.4258467 },
    { lat: 48.9292931, lng: 20.4257127 },
    { lat: 48.9289892, lng: 20.4255717 },
    { lat: 48.9285465, lng: 20.4254747 },
    { lat: 48.9276257, lng: 20.425871 },
    { lat: 48.9267828, lng: 20.4255243 },
    { lat: 48.9265038, lng: 20.4258272 },
    { lat: 48.9263421, lng: 20.4259301 },
    { lat: 48.9263994, lng: 20.4261031 },
    { lat: 48.9264156, lng: 20.4266184 },
    { lat: 48.9263655, lng: 20.4269576 },
    { lat: 48.9263714, lng: 20.4274447 },
    { lat: 48.9261302, lng: 20.427908 },
    { lat: 48.9259277, lng: 20.4285656 },
    { lat: 48.9254293, lng: 20.4292042 },
    { lat: 48.9248821, lng: 20.4302442 },
    { lat: 48.9247656, lng: 20.4308254 },
    { lat: 48.9246001, lng: 20.4326402 },
    { lat: 48.9243599, lng: 20.4336125 },
    { lat: 48.9242742, lng: 20.433784 },
    { lat: 48.9240547, lng: 20.4350352 },
    { lat: 48.9240711, lng: 20.4357362 },
    { lat: 48.9241631, lng: 20.4366205 },
    { lat: 48.92414, lng: 20.4378818 },
    { lat: 48.9242211, lng: 20.4382395 },
    { lat: 48.9241941, lng: 20.438774 },
    { lat: 48.9242914, lng: 20.4391443 },
    { lat: 48.9242311, lng: 20.4395148 },
    { lat: 48.9245205, lng: 20.441422 },
    { lat: 48.9247006, lng: 20.4418671 },
    { lat: 48.9251007, lng: 20.4434274 },
    { lat: 48.9251781, lng: 20.4435711 },
    { lat: 48.9255156, lng: 20.4454405 },
    { lat: 48.9257141, lng: 20.4461361 },
    { lat: 48.9257951, lng: 20.4474428 },
    { lat: 48.9261333, lng: 20.4479902 },
    { lat: 48.9263042, lng: 20.4484606 },
    { lat: 48.9268869, lng: 20.4488969 },
    { lat: 48.9276383, lng: 20.4492308 },
    { lat: 48.9277956, lng: 20.4494349 },
    { lat: 48.928166, lng: 20.4495846 },
    { lat: 48.9286536, lng: 20.4494319 },
    { lat: 48.9295559, lng: 20.4497731 },
    { lat: 48.9296984, lng: 20.4499967 },
    { lat: 48.9301833, lng: 20.4503159 },
    { lat: 48.9305538, lng: 20.451633 },
    { lat: 48.9308692, lng: 20.4524998 },
    { lat: 48.9308825, lng: 20.4531221 },
    { lat: 48.9309626, lng: 20.4538757 },
    { lat: 48.9310882, lng: 20.4543106 },
    { lat: 48.9312561, lng: 20.4544905 },
    { lat: 48.9320144, lng: 20.4557038 },
    { lat: 48.9328512, lng: 20.4566922 },
    { lat: 48.9331136, lng: 20.4570885 },
    { lat: 48.9332897, lng: 20.4580551 },
    { lat: 48.9335851, lng: 20.4590809 },
    { lat: 48.93376, lng: 20.4595098 },
    { lat: 48.9339257, lng: 20.4602328 },
    { lat: 48.9344824, lng: 20.4608761 },
    { lat: 48.9346423, lng: 20.4613503 },
    { lat: 48.9349589, lng: 20.4618701 },
    { lat: 48.935137, lng: 20.4626529 },
    { lat: 48.9351913, lng: 20.4633193 },
    { lat: 48.9351783, lng: 20.4642899 },
    { lat: 48.9350434, lng: 20.4645924 },
    { lat: 48.9350316, lng: 20.4649078 },
    { lat: 48.9350927, lng: 20.4653638 },
    { lat: 48.9350962, lng: 20.4659981 },
    { lat: 48.9351734, lng: 20.4665211 },
    { lat: 48.9355605, lng: 20.4675992 },
    { lat: 48.9357651, lng: 20.4677684 },
    { lat: 48.9362847, lng: 20.4677843 },
    { lat: 48.937444, lng: 20.4683708 },
    { lat: 48.9382517, lng: 20.4696425 },
    { lat: 48.9384312, lng: 20.4703447 },
    { lat: 48.9386298, lng: 20.4703569 },
    { lat: 48.9388233, lng: 20.4701987 },
    { lat: 48.9390529, lng: 20.4704951 },
    { lat: 48.9390104, lng: 20.4711671 },
    { lat: 48.9391378, lng: 20.4715876 },
    { lat: 48.9394354, lng: 20.4718108 },
    { lat: 48.9404218, lng: 20.4731295 },
    { lat: 48.9405043, lng: 20.4732584 },
    { lat: 48.9405532, lng: 20.473544 },
    { lat: 48.9408027, lng: 20.4737989 },
    { lat: 48.9404302, lng: 20.4755169 },
    { lat: 48.9403972, lng: 20.4760778 },
    { lat: 48.9401387, lng: 20.4765081 },
    { lat: 48.9401361, lng: 20.4767092 },
    { lat: 48.940287, lng: 20.4776131 },
    { lat: 48.9405794, lng: 20.4786548 },
    { lat: 48.9409549, lng: 20.4789092 },
    { lat: 48.9412652, lng: 20.4792882 },
    { lat: 48.9415252, lng: 20.4803725 },
    { lat: 48.9414775, lng: 20.480936 },
    { lat: 48.9414596, lng: 20.4811468 },
    { lat: 48.9415378, lng: 20.4816658 },
    { lat: 48.9414185, lng: 20.4821044 },
    { lat: 48.9412976, lng: 20.4825594 },
    { lat: 48.9414372, lng: 20.4828923 },
    { lat: 48.9415116, lng: 20.4836756 },
    { lat: 48.9419975, lng: 20.4849263 },
    { lat: 48.942032, lng: 20.4851862 },
    { lat: 48.9419167, lng: 20.4860461 },
    { lat: 48.9417853, lng: 20.4864442 },
    { lat: 48.9417879, lng: 20.487031 },
    { lat: 48.9418904, lng: 20.4874194 },
    { lat: 48.9422087, lng: 20.4879568 },
    { lat: 48.9427776, lng: 20.4887466 },
    { lat: 48.9430124, lng: 20.4888811 },
    { lat: 48.9432068, lng: 20.4893844 },
    { lat: 48.9432799, lng: 20.4893194 },
    { lat: 48.9433842, lng: 20.4894605 },
    { lat: 48.9436184, lng: 20.4899425 },
    { lat: 48.9437786, lng: 20.490164 },
    { lat: 48.9441639, lng: 20.4905882 },
    { lat: 48.9442707, lng: 20.4905858 },
    { lat: 48.945523, lng: 20.4895682 },
    { lat: 48.9462286, lng: 20.4893508 },
    { lat: 48.946401, lng: 20.4893818 },
    { lat: 48.9467078, lng: 20.4891254 },
    { lat: 48.9471632, lng: 20.4891145 },
    { lat: 48.9482819, lng: 20.4887717 },
    { lat: 48.9488678, lng: 20.488868 },
    { lat: 48.949786, lng: 20.4892206 },
    { lat: 48.9501455, lng: 20.4890152 },
    { lat: 48.9511155, lng: 20.4891179 },
    { lat: 48.951125, lng: 20.4889569 },
    { lat: 48.9514284, lng: 20.4889361 },
    { lat: 48.9535921, lng: 20.4892322 },
    { lat: 48.9550207, lng: 20.4895297 },
    { lat: 48.9559465, lng: 20.4892325 },
    { lat: 48.9561194, lng: 20.4893709 },
    { lat: 48.956127, lng: 20.4894555 },
    { lat: 48.9568344, lng: 20.4867244 },
    { lat: 48.9577258, lng: 20.4870452 },
    { lat: 48.9591895, lng: 20.4873805 },
    { lat: 48.9605527, lng: 20.48779 },
    { lat: 48.9613033, lng: 20.4877805 },
    { lat: 48.9615233, lng: 20.4878529 },
    { lat: 48.9616784, lng: 20.4878173 },
    { lat: 48.9630628, lng: 20.4879527 },
    { lat: 48.9634602, lng: 20.4878611 },
    { lat: 48.9637469, lng: 20.4879134 },
    { lat: 48.9643754, lng: 20.4877665 },
    { lat: 48.9650005, lng: 20.4874736 },
    { lat: 48.9653631, lng: 20.4879583 },
    { lat: 48.966022, lng: 20.4890485 },
    { lat: 48.9670209, lng: 20.4897751 },
    { lat: 48.967799, lng: 20.490142 },
    { lat: 48.968072, lng: 20.488803 },
    { lat: 48.968158, lng: 20.488235 },
    { lat: 48.968163, lng: 20.488199 },
    { lat: 48.968174, lng: 20.488134 },
    { lat: 48.968179, lng: 20.488105 },
    { lat: 48.968205, lng: 20.487994 },
    { lat: 48.967677, lng: 20.487065 },
    { lat: 48.968219, lng: 20.486459 },
    { lat: 48.968381, lng: 20.486246 },
    { lat: 48.968683, lng: 20.485829 },
    { lat: 48.969426, lng: 20.485034 },
    { lat: 48.969619, lng: 20.484783 },
    { lat: 48.969737, lng: 20.484633 },
    { lat: 48.969825, lng: 20.484512 },
    { lat: 48.969932, lng: 20.484358 },
    { lat: 48.970009, lng: 20.484239 },
    { lat: 48.970056, lng: 20.484127 },
    { lat: 48.970081, lng: 20.484077 },
    { lat: 48.970187, lng: 20.483835 },
    { lat: 48.970351, lng: 20.483422 },
    { lat: 48.970439, lng: 20.483176 },
    { lat: 48.97049, lng: 20.483052 },
    { lat: 48.970593, lng: 20.482819 },
    { lat: 48.971017, lng: 20.48216 },
    { lat: 48.971264, lng: 20.481595 },
    { lat: 48.971609, lng: 20.481027 },
    { lat: 48.971932, lng: 20.480432 },
    { lat: 48.972007, lng: 20.480302 },
    { lat: 48.972148, lng: 20.480071 },
    { lat: 48.972367, lng: 20.479859 },
    { lat: 48.972412, lng: 20.479816 },
    { lat: 48.972516, lng: 20.479771 },
    { lat: 48.972614, lng: 20.479731 },
    { lat: 48.972576, lng: 20.479697 },
    { lat: 48.972505, lng: 20.47951 },
    { lat: 48.972619, lng: 20.47932 },
    { lat: 48.973111, lng: 20.478205 },
    { lat: 48.973614, lng: 20.477233 },
    { lat: 48.973968, lng: 20.476542 },
    { lat: 48.97431, lng: 20.476921 },
    { lat: 48.975701, lng: 20.477286 },
    { lat: 48.9765, lng: 20.477258 },
    { lat: 48.9768853, lng: 20.4771945 },
  ],
  Oľšavka: [
    { lat: 48.9611884, lng: 20.8336457 },
    { lat: 48.9612353, lng: 20.8337616 },
    { lat: 48.9617131, lng: 20.8349259 },
    { lat: 48.9627998, lng: 20.834412 },
    { lat: 48.9632691, lng: 20.8343479 },
    { lat: 48.9633858, lng: 20.8344357 },
    { lat: 48.9640249, lng: 20.8343976 },
    { lat: 48.9649194, lng: 20.834126 },
    { lat: 48.9653482, lng: 20.8341189 },
    { lat: 48.9653264, lng: 20.8346966 },
    { lat: 48.9653837, lng: 20.8348289 },
    { lat: 48.965904, lng: 20.8352584 },
    { lat: 48.966187, lng: 20.8356007 },
    { lat: 48.966368, lng: 20.8359191 },
    { lat: 48.9668544, lng: 20.835528 },
    { lat: 48.9669877, lng: 20.8361326 },
    { lat: 48.9673167, lng: 20.8361448 },
    { lat: 48.9674239, lng: 20.836218 },
    { lat: 48.968324, lng: 20.8374521 },
    { lat: 48.9687291, lng: 20.8381709 },
    { lat: 48.9688715, lng: 20.8385476 },
    { lat: 48.9691012, lng: 20.8399263 },
    { lat: 48.9694787, lng: 20.8398746 },
    { lat: 48.969809, lng: 20.841718 },
    { lat: 48.970487, lng: 20.841257 },
    { lat: 48.97113, lng: 20.84241 },
    { lat: 48.973741, lng: 20.843139 },
    { lat: 48.973821, lng: 20.843677 },
    { lat: 48.973681, lng: 20.844247 },
    { lat: 48.973652, lng: 20.844683 },
    { lat: 48.973763, lng: 20.845722 },
    { lat: 48.974027, lng: 20.846739 },
    { lat: 48.9744008, lng: 20.8473971 },
    { lat: 48.9745737, lng: 20.8478308 },
    { lat: 48.974902, lng: 20.849994 },
    { lat: 48.9747981, lng: 20.8475109 },
    { lat: 48.974559, lng: 20.847247 },
    { lat: 48.974277, lng: 20.846436 },
    { lat: 48.974018, lng: 20.84465 },
    { lat: 48.974259, lng: 20.843649 },
    { lat: 48.974799, lng: 20.841849 },
    { lat: 48.975479, lng: 20.839989 },
    { lat: 48.975609, lng: 20.838891 },
    { lat: 48.975383, lng: 20.837019 },
    { lat: 48.975093, lng: 20.835944 },
    { lat: 48.97476, lng: 20.835273 },
    { lat: 48.973317, lng: 20.83426 },
    { lat: 48.973189, lng: 20.832879 },
    { lat: 48.973671, lng: 20.832188 },
    { lat: 48.9736719, lng: 20.831946 },
    { lat: 48.973677, lng: 20.830646 },
    { lat: 48.973441, lng: 20.828886 },
    { lat: 48.973454, lng: 20.828855 },
    { lat: 48.973892, lng: 20.827136 },
    { lat: 48.974269, lng: 20.826386 },
    { lat: 48.974524, lng: 20.825624 },
    { lat: 48.974643, lng: 20.825234 },
    { lat: 48.974695, lng: 20.825063 },
    { lat: 48.975051, lng: 20.823888 },
    { lat: 48.975214, lng: 20.822768 },
    { lat: 48.976216, lng: 20.8217 },
    { lat: 48.977407, lng: 20.820511 },
    { lat: 48.9769659, lng: 20.8199394 },
    { lat: 48.9769077, lng: 20.8197825 },
    { lat: 48.976897, lng: 20.8192118 },
    { lat: 48.9771, lng: 20.8182117 },
    { lat: 48.9771271, lng: 20.8178204 },
    { lat: 48.9769897, lng: 20.8172028 },
    { lat: 48.9758852, lng: 20.8172607 },
    { lat: 48.975712, lng: 20.8171971 },
    { lat: 48.9754972, lng: 20.8170078 },
    { lat: 48.9753266, lng: 20.8167613 },
    { lat: 48.9752766, lng: 20.8164536 },
    { lat: 48.9753174, lng: 20.815552 },
    { lat: 48.9755338, lng: 20.8145867 },
    { lat: 48.9754893, lng: 20.814143 },
    { lat: 48.975318, lng: 20.8137117 },
    { lat: 48.97529, lng: 20.8135243 },
    { lat: 48.9753486, lng: 20.8127414 },
    { lat: 48.9753185, lng: 20.8123554 },
    { lat: 48.9751607, lng: 20.8119333 },
    { lat: 48.974883, lng: 20.8115123 },
    { lat: 48.9745092, lng: 20.8112218 },
    { lat: 48.9736098, lng: 20.8110248 },
    { lat: 48.9725455, lng: 20.8113029 },
    { lat: 48.9717773, lng: 20.811285 },
    { lat: 48.9711952, lng: 20.8132371 },
    { lat: 48.9708277, lng: 20.8131894 },
    { lat: 48.9699849, lng: 20.8128335 },
    { lat: 48.9697659, lng: 20.8133712 },
    { lat: 48.9696701, lng: 20.8133082 },
    { lat: 48.9693427, lng: 20.8142214 },
    { lat: 48.9680472, lng: 20.8138049 },
    { lat: 48.9671755, lng: 20.8137877 },
    { lat: 48.9665821, lng: 20.8139808 },
    { lat: 48.9636645, lng: 20.8132007 },
    { lat: 48.9635211, lng: 20.8134812 },
    { lat: 48.963091, lng: 20.8141277 },
    { lat: 48.9626595, lng: 20.8152568 },
    { lat: 48.9621078, lng: 20.8162973 },
    { lat: 48.9615036, lng: 20.8178718 },
    { lat: 48.9612637, lng: 20.8187782 },
    { lat: 48.9610654, lng: 20.8198124 },
    { lat: 48.9606118, lng: 20.8198141 },
    { lat: 48.9600373, lng: 20.8200945 },
    { lat: 48.9598763, lng: 20.8202914 },
    { lat: 48.958624, lng: 20.8206988 },
    { lat: 48.9582615, lng: 20.820925 },
    { lat: 48.958092, lng: 20.8211337 },
    { lat: 48.9579933, lng: 20.8214034 },
    { lat: 48.9575791, lng: 20.8215689 },
    { lat: 48.957384, lng: 20.8215611 },
    { lat: 48.9572777, lng: 20.8216742 },
    { lat: 48.9575938, lng: 20.8227469 },
    { lat: 48.9577613, lng: 20.8230351 },
    { lat: 48.9578332, lng: 20.8236498 },
    { lat: 48.9580945, lng: 20.8240184 },
    { lat: 48.9581964, lng: 20.8243262 },
    { lat: 48.9589, lng: 20.8257962 },
    { lat: 48.9591608, lng: 20.8264721 },
    { lat: 48.9601243, lng: 20.8284345 },
    { lat: 48.9604772, lng: 20.8292984 },
    { lat: 48.9605366, lng: 20.829716 },
    { lat: 48.9604555, lng: 20.8297446 },
    { lat: 48.9606692, lng: 20.8307582 },
    { lat: 48.9610234, lng: 20.8314626 },
    { lat: 48.9611476, lng: 20.8319997 },
    { lat: 48.9611337, lng: 20.8325425 },
    { lat: 48.9611884, lng: 20.8336457 },
  ],
  Iliašovce: [
    { lat: 49.011466, lng: 20.533957 },
    { lat: 49.011482, lng: 20.533944 },
    { lat: 49.011511, lng: 20.533918 },
    { lat: 49.011894, lng: 20.533575 },
    { lat: 49.012785, lng: 20.53275 },
    { lat: 49.013537, lng: 20.531697 },
    { lat: 49.013942, lng: 20.531062 },
    { lat: 49.013967, lng: 20.531023 },
    { lat: 49.014076, lng: 20.53086 },
    { lat: 49.014096, lng: 20.530825 },
    { lat: 49.014162, lng: 20.530713 },
    { lat: 49.014346, lng: 20.530757 },
    { lat: 49.014676, lng: 20.530835 },
    { lat: 49.015183, lng: 20.530933 },
    { lat: 49.016082, lng: 20.53104 },
    { lat: 49.016163, lng: 20.531053 },
    { lat: 49.017122, lng: 20.531204 },
    { lat: 49.017667, lng: 20.531297 },
    { lat: 49.018011, lng: 20.531403 },
    { lat: 49.018039, lng: 20.531422 },
    { lat: 49.018114, lng: 20.531423 },
    { lat: 49.018136, lng: 20.531423 },
    { lat: 49.018174, lng: 20.531423 },
    { lat: 49.018207, lng: 20.531424 },
    { lat: 49.01823, lng: 20.531424 },
    { lat: 49.018257, lng: 20.531425 },
    { lat: 49.018287, lng: 20.531425 },
    { lat: 49.018528, lng: 20.531428 },
    { lat: 49.018771, lng: 20.531452 },
    { lat: 49.018827, lng: 20.531458 },
    { lat: 49.018846, lng: 20.531461 },
    { lat: 49.018777, lng: 20.530944 },
    { lat: 49.018703, lng: 20.530339 },
    { lat: 49.018702, lng: 20.530325 },
    { lat: 49.018715, lng: 20.53032 },
    { lat: 49.018926, lng: 20.53024 },
    { lat: 49.019008, lng: 20.530215 },
    { lat: 49.019182, lng: 20.530186 },
    { lat: 49.01897, lng: 20.529541 },
    { lat: 49.018944, lng: 20.529474 },
    { lat: 49.018737, lng: 20.529087 },
    { lat: 49.01859, lng: 20.52902 },
    { lat: 49.018361, lng: 20.528874 },
    { lat: 49.018183, lng: 20.528761 },
    { lat: 49.018093, lng: 20.528646 },
    { lat: 49.018081, lng: 20.528598 },
    { lat: 49.017996, lng: 20.528314 },
    { lat: 49.017993, lng: 20.528302 },
    { lat: 49.017947, lng: 20.52815 },
    { lat: 49.017996, lng: 20.527878 },
    { lat: 49.017895, lng: 20.527612 },
    { lat: 49.017438, lng: 20.527177 },
    { lat: 49.017449, lng: 20.526718 },
    { lat: 49.017428, lng: 20.526684 },
    { lat: 49.017408, lng: 20.52664 },
    { lat: 49.01736, lng: 20.526502 },
    { lat: 49.017347, lng: 20.526232 },
    { lat: 49.017252, lng: 20.526102 },
    { lat: 49.017233, lng: 20.526075 },
    { lat: 49.017229, lng: 20.52581 },
    { lat: 49.017266, lng: 20.525782 },
    { lat: 49.017288, lng: 20.525797 },
    { lat: 49.017362, lng: 20.525848 },
    { lat: 49.017395, lng: 20.525808 },
    { lat: 49.017586, lng: 20.525582 },
    { lat: 49.017608, lng: 20.525536 },
    { lat: 49.0177, lng: 20.525222 },
    { lat: 49.017712, lng: 20.52475 },
    { lat: 49.017752, lng: 20.524507 },
    { lat: 49.017787, lng: 20.524189 },
    { lat: 49.017795, lng: 20.524113 },
    { lat: 49.017839, lng: 20.52404 },
    { lat: 49.018038, lng: 20.52391 },
    { lat: 49.018021, lng: 20.523815 },
    { lat: 49.018417, lng: 20.523584 },
    { lat: 49.018687, lng: 20.523317 },
    { lat: 49.01874, lng: 20.523275 },
    { lat: 49.018809, lng: 20.523224 },
    { lat: 49.018858, lng: 20.523211 },
    { lat: 49.018949, lng: 20.52319 },
    { lat: 49.018991, lng: 20.523177 },
    { lat: 49.019, lng: 20.523173 },
    { lat: 49.019031, lng: 20.523157 },
    { lat: 49.019133, lng: 20.523171 },
    { lat: 49.019658, lng: 20.522406 },
    { lat: 49.019733, lng: 20.522489 },
    { lat: 49.019797, lng: 20.522558 },
    { lat: 49.019884, lng: 20.522189 },
    { lat: 49.019877, lng: 20.521922 },
    { lat: 49.019823, lng: 20.521629 },
    { lat: 49.019773, lng: 20.521359 },
    { lat: 49.019689, lng: 20.520589 },
    { lat: 49.019738, lng: 20.520271 },
    { lat: 49.019783, lng: 20.520021 },
    { lat: 49.01973, lng: 20.519791 },
    { lat: 49.019762, lng: 20.519643 },
    { lat: 49.019688, lng: 20.519528 },
    { lat: 49.019204, lng: 20.519225 },
    { lat: 49.018934, lng: 20.518712 },
    { lat: 49.018713, lng: 20.518429 },
    { lat: 49.018596, lng: 20.518278 },
    { lat: 49.018448, lng: 20.51773 },
    { lat: 49.018396, lng: 20.517479 },
    { lat: 49.018329, lng: 20.517159 },
    { lat: 49.018235, lng: 20.517148 },
    { lat: 49.018206, lng: 20.517141 },
    { lat: 49.01807, lng: 20.517113 },
    { lat: 49.016772, lng: 20.516675 },
    { lat: 49.015589, lng: 20.515548 },
    { lat: 49.015017, lng: 20.515004 },
    { lat: 49.014999, lng: 20.514987 },
    { lat: 49.013925, lng: 20.513629 },
    { lat: 49.013007, lng: 20.512886 },
    { lat: 49.012149, lng: 20.512352 },
    { lat: 49.011789, lng: 20.512128 },
    { lat: 49.010996, lng: 20.511635 },
    { lat: 49.010817, lng: 20.512026 },
    { lat: 49.009809, lng: 20.510867 },
    { lat: 49.008755, lng: 20.509675 },
    { lat: 49.008484, lng: 20.509387 },
    { lat: 49.008012, lng: 20.508884 },
    { lat: 49.007726, lng: 20.50719 },
    { lat: 49.007618, lng: 20.506538 },
    { lat: 49.007439, lng: 20.506402 },
    { lat: 49.007275, lng: 20.506038 },
    { lat: 49.007176, lng: 20.505896 },
    { lat: 49.007088, lng: 20.505771 },
    { lat: 49.006747, lng: 20.505592 },
    { lat: 49.006204, lng: 20.505389 },
    { lat: 49.00618, lng: 20.505379 },
    { lat: 49.005909, lng: 20.505345 },
    { lat: 49.005432, lng: 20.505284 },
    { lat: 49.005161, lng: 20.505033 },
    { lat: 49.004998, lng: 20.504883 },
    { lat: 49.004961, lng: 20.504898 },
    { lat: 49.004773, lng: 20.504974 },
    { lat: 49.004401, lng: 20.505124 },
    { lat: 49.00439, lng: 20.5051 },
    { lat: 49.004239, lng: 20.505224 },
    { lat: 49.004201, lng: 20.505243 },
    { lat: 49.004094, lng: 20.505295 },
    { lat: 49.003957, lng: 20.505363 },
    { lat: 49.003692, lng: 20.505443 },
    { lat: 49.00314, lng: 20.505577 },
    { lat: 49.002716, lng: 20.505708 },
    { lat: 49.002441, lng: 20.505822 },
    { lat: 49.0024258, lng: 20.5054732 },
    { lat: 49.0016489, lng: 20.5053973 },
    { lat: 49.0017857, lng: 20.5045646 },
    { lat: 49.0018, lng: 20.503926 },
    { lat: 49.00179, lng: 20.503705 },
    { lat: 49.001791, lng: 20.50354 },
    { lat: 49.001809, lng: 20.502913 },
    { lat: 49.001846, lng: 20.502581 },
    { lat: 49.0018774, lng: 20.5015009 },
    { lat: 49.0019035, lng: 20.500719 },
    { lat: 49.002384, lng: 20.501379 },
    { lat: 49.0024107, lng: 20.5013822 },
    { lat: 49.0024363, lng: 20.5001514 },
    { lat: 49.0024672, lng: 20.4993553 },
    { lat: 49.0025263, lng: 20.4985721 },
    { lat: 49.0025639, lng: 20.4976655 },
    { lat: 49.0025694, lng: 20.4969496 },
    { lat: 49.002503, lng: 20.496366 },
    { lat: 49.002522, lng: 20.4962 },
    { lat: 49.002563, lng: 20.495814 },
    { lat: 49.002711, lng: 20.494457 },
    { lat: 49.002735, lng: 20.494253 },
    { lat: 49.002767, lng: 20.494037 },
    { lat: 49.002811, lng: 20.493812 },
    { lat: 49.002881, lng: 20.493487 },
    { lat: 49.003078, lng: 20.492967 },
    { lat: 49.003174, lng: 20.492534 },
    { lat: 49.003179, lng: 20.492315 },
    { lat: 49.003169, lng: 20.492219 },
    { lat: 49.003085, lng: 20.491395 },
    { lat: 49.003073, lng: 20.491205 },
    { lat: 49.003056, lng: 20.490993 },
    { lat: 49.00309, lng: 20.490718 },
    { lat: 49.003128, lng: 20.490403 },
    { lat: 49.003171, lng: 20.490018 },
    { lat: 49.003239, lng: 20.489418 },
    { lat: 49.003262, lng: 20.489234 },
    { lat: 49.003282, lng: 20.489118 },
    { lat: 49.003294, lng: 20.489047 },
    { lat: 49.003322, lng: 20.488727 },
    { lat: 49.003353, lng: 20.488307 },
    { lat: 49.003395, lng: 20.487726 },
    { lat: 49.003407, lng: 20.487448 },
    { lat: 49.003419, lng: 20.487173 },
    { lat: 49.003419, lng: 20.486539 },
    { lat: 49.003425, lng: 20.486205 },
    { lat: 49.003387, lng: 20.48562 },
    { lat: 49.003326, lng: 20.485227 },
    { lat: 49.00328, lng: 20.485158 },
    { lat: 49.00327, lng: 20.485142 },
    { lat: 49.003224, lng: 20.485073 },
    { lat: 49.003209, lng: 20.485048 },
    { lat: 49.003141, lng: 20.484472 },
    { lat: 49.003138, lng: 20.484063 },
    { lat: 49.003134, lng: 20.483836 },
    { lat: 49.003029, lng: 20.482891 },
    { lat: 49.003002, lng: 20.482614 },
    { lat: 49.002991, lng: 20.482507 },
    { lat: 49.002959, lng: 20.482241 },
    { lat: 49.002954, lng: 20.482187 },
    { lat: 49.002951, lng: 20.48214 },
    { lat: 49.002915, lng: 20.481585 },
    { lat: 49.002839, lng: 20.481575 },
    { lat: 49.002417, lng: 20.48288 },
    { lat: 49.002293, lng: 20.483159 },
    { lat: 49.002176, lng: 20.483437 },
    { lat: 49.001931, lng: 20.483929 },
    { lat: 49.001764, lng: 20.484261 },
    { lat: 49.001593, lng: 20.484585 },
    { lat: 49.001509, lng: 20.484717 },
    { lat: 49.001462, lng: 20.484845 },
    { lat: 49.001458, lng: 20.484856 },
    { lat: 49.001345, lng: 20.485109 },
    { lat: 49.001271, lng: 20.485289 },
    { lat: 49.001234, lng: 20.485374 },
    { lat: 49.001158, lng: 20.485555 },
    { lat: 49.001138, lng: 20.485606 },
    { lat: 49.001119, lng: 20.485637 },
    { lat: 49.000999, lng: 20.485884 },
    { lat: 49.000981, lng: 20.485922 },
    { lat: 49.000979, lng: 20.485925 },
    { lat: 49.000849, lng: 20.486432 },
    { lat: 49.000755, lng: 20.486795 },
    { lat: 49.000609, lng: 20.487393 },
    { lat: 49.000601, lng: 20.487424 },
    { lat: 49.000463, lng: 20.488009 },
    { lat: 49.00044, lng: 20.488112 },
    { lat: 49.000353, lng: 20.488474 },
    { lat: 49.000347, lng: 20.48852 },
    { lat: 49.000288, lng: 20.488772 },
    { lat: 49.00022, lng: 20.489059 },
    { lat: 49.00018, lng: 20.48922 },
    { lat: 49.000181, lng: 20.489257 },
    { lat: 49.000123, lng: 20.489276 },
    { lat: 48.999889, lng: 20.489334 },
    { lat: 48.999779, lng: 20.489415 },
    { lat: 48.999771, lng: 20.489421 },
    { lat: 48.999761, lng: 20.489429 },
    { lat: 48.999635, lng: 20.489604 },
    { lat: 48.999542, lng: 20.489821 },
    { lat: 48.999299, lng: 20.490662 },
    { lat: 48.999167, lng: 20.49124 },
    { lat: 48.999017, lng: 20.491886 },
    { lat: 48.998938, lng: 20.492221 },
    { lat: 48.998857, lng: 20.492611 },
    { lat: 48.998776, lng: 20.493273 },
    { lat: 48.9987721, lng: 20.4933469 },
    { lat: 48.998756, lng: 20.49365 },
    { lat: 48.998657, lng: 20.493958 },
    { lat: 48.99853, lng: 20.494205 },
    { lat: 48.998443, lng: 20.494407 },
    { lat: 48.998275, lng: 20.494799 },
    { lat: 48.998251, lng: 20.49496 },
    { lat: 48.998226, lng: 20.495044 },
    { lat: 48.998099, lng: 20.495598 },
    { lat: 48.998054, lng: 20.495782 },
    { lat: 48.997864, lng: 20.496265 },
    { lat: 48.99781, lng: 20.496511 },
    { lat: 48.997773, lng: 20.496764 },
    { lat: 48.997684, lng: 20.49721 },
    { lat: 48.997609, lng: 20.497566 },
    { lat: 48.997484, lng: 20.498311 },
    { lat: 48.99747, lng: 20.498393 },
    { lat: 48.997289, lng: 20.499066 },
    { lat: 48.997252, lng: 20.499215 },
    { lat: 48.9972136, lng: 20.4993912 },
    { lat: 48.9971959, lng: 20.4993745 },
    { lat: 48.99715, lng: 20.499505 },
    { lat: 48.997094, lng: 20.499683 },
    { lat: 48.997044, lng: 20.499844 },
    { lat: 48.997019, lng: 20.499939 },
    { lat: 48.996997, lng: 20.500023 },
    { lat: 48.996965, lng: 20.500144 },
    { lat: 48.996942, lng: 20.500227 },
    { lat: 48.996839, lng: 20.500564 },
    { lat: 48.996726, lng: 20.500787 },
    { lat: 48.996598, lng: 20.501017 },
    { lat: 48.996522, lng: 20.50122 },
    { lat: 48.996359, lng: 20.501814 },
    { lat: 48.996303, lng: 20.502109 },
    { lat: 48.996238, lng: 20.502439 },
    { lat: 48.995978, lng: 20.503421 },
    { lat: 48.995845, lng: 20.503837 },
    { lat: 48.995698, lng: 20.504359 },
    { lat: 48.995605, lng: 20.504679 },
    { lat: 48.995425, lng: 20.505124 },
    { lat: 48.995176, lng: 20.505589 },
    { lat: 48.995072, lng: 20.505793 },
    { lat: 48.995064, lng: 20.505812 },
    { lat: 48.994963, lng: 20.506041 },
    { lat: 48.994842, lng: 20.506386 },
    { lat: 48.994866, lng: 20.506708 },
    { lat: 48.994892, lng: 20.506951 },
    { lat: 48.994905, lng: 20.507122 },
    { lat: 48.994947, lng: 20.507822 },
    { lat: 48.994953, lng: 20.50788 },
    { lat: 48.99501, lng: 20.508329 },
    { lat: 48.995062, lng: 20.508687 },
    { lat: 48.995056, lng: 20.508847 },
    { lat: 48.995055, lng: 20.508905 },
    { lat: 48.995038, lng: 20.509352 },
    { lat: 48.995015, lng: 20.50935 },
    { lat: 48.994998, lng: 20.509352 },
    { lat: 48.994891, lng: 20.509386 },
    { lat: 48.994633, lng: 20.509492 },
    { lat: 48.994553, lng: 20.509524 },
    { lat: 48.994197, lng: 20.509663 },
    { lat: 48.993879, lng: 20.509751 },
    { lat: 48.993201, lng: 20.509907 },
    { lat: 48.992718, lng: 20.509899 },
    { lat: 48.992212, lng: 20.509878 },
    { lat: 48.991783, lng: 20.509931 },
    { lat: 48.991512, lng: 20.50997 },
    { lat: 48.99141, lng: 20.509992 },
    { lat: 48.991221, lng: 20.510034 },
    { lat: 48.990808, lng: 20.510144 },
    { lat: 48.990764, lng: 20.510166 },
    { lat: 48.990745, lng: 20.510175 },
    { lat: 48.99068, lng: 20.509926 },
    { lat: 48.990508, lng: 20.509453 },
    { lat: 48.990328, lng: 20.509042 },
    { lat: 48.98971, lng: 20.507699 },
    { lat: 48.989565, lng: 20.507409 },
    { lat: 48.989461, lng: 20.507199 },
    { lat: 48.989442, lng: 20.507161 },
    { lat: 48.989372, lng: 20.507022 },
    { lat: 48.989151, lng: 20.506565 },
    { lat: 48.989006, lng: 20.506295 },
    { lat: 48.988814, lng: 20.506001 },
    { lat: 48.98863, lng: 20.505708 },
    { lat: 48.988382, lng: 20.505304 },
    { lat: 48.988282, lng: 20.505064 },
    { lat: 48.988294, lng: 20.505032 },
    { lat: 48.988526, lng: 20.504425 },
    { lat: 48.988732, lng: 20.503859 },
    { lat: 48.988768, lng: 20.503759 },
    { lat: 48.988949, lng: 20.503299 },
    { lat: 48.988252, lng: 20.502575 },
    { lat: 48.988158, lng: 20.502477 },
    { lat: 48.98819, lng: 20.502392 },
    { lat: 48.988277, lng: 20.502158 },
    { lat: 48.988057, lng: 20.501813 },
    { lat: 48.987963, lng: 20.501665 },
    { lat: 48.987937, lng: 20.501626 },
    { lat: 48.987765, lng: 20.5015637 },
    { lat: 48.986643, lng: 20.5015777 },
    { lat: 48.9853763, lng: 20.5013948 },
    { lat: 48.9852097, lng: 20.5014894 },
    { lat: 48.9848857, lng: 20.5023414 },
    { lat: 48.9847989, lng: 20.5025195 },
    { lat: 48.9847283, lng: 20.5025571 },
    { lat: 48.9845647, lng: 20.5025468 },
    { lat: 48.984365, lng: 20.5023553 },
    { lat: 48.9837227, lng: 20.5020421 },
    { lat: 48.9829548, lng: 20.5047932 },
    { lat: 48.9824023, lng: 20.5060687 },
    { lat: 48.9821152, lng: 20.5068565 },
    { lat: 48.9817563, lng: 20.5079519 },
    { lat: 48.9816578, lng: 20.508545 },
    { lat: 48.980456, lng: 20.5080775 },
    { lat: 48.9804675, lng: 20.5082013 },
    { lat: 48.9805432, lng: 20.5088808 },
    { lat: 48.9790712, lng: 20.509138 },
    { lat: 48.9792509, lng: 20.5110344 },
    { lat: 48.9785831, lng: 20.5109988 },
    { lat: 48.977903, lng: 20.5105443 },
    { lat: 48.9768132, lng: 20.5100916 },
    { lat: 48.9768383, lng: 20.5128574 },
    { lat: 48.9767625, lng: 20.5157895 },
    { lat: 48.9768102, lng: 20.5164635 },
    { lat: 48.9757185, lng: 20.5170857 },
    { lat: 48.9746689, lng: 20.5175877 },
    { lat: 48.9746769, lng: 20.5185662 },
    { lat: 48.9747527, lng: 20.5192743 },
    { lat: 48.9737975, lng: 20.5195621 },
    { lat: 48.9737678, lng: 20.520557 },
    { lat: 48.9736325, lng: 20.5207947 },
    { lat: 48.9735408, lng: 20.5211136 },
    { lat: 48.9734897, lng: 20.5215248 },
    { lat: 48.9731962, lng: 20.5222935 },
    { lat: 48.9731151, lng: 20.5228993 },
    { lat: 48.9730353, lng: 20.5230272 },
    { lat: 48.972888, lng: 20.5231204 },
    { lat: 48.9726486, lng: 20.523528 },
    { lat: 48.9721796, lng: 20.5247575 },
    { lat: 48.9720457, lng: 20.5248488 },
    { lat: 48.9717545, lng: 20.5267458 },
    { lat: 48.9708163, lng: 20.5266457 },
    { lat: 48.9707346, lng: 20.5274461 },
    { lat: 48.9701471, lng: 20.5300062 },
    { lat: 48.9699843, lng: 20.5302351 },
    { lat: 48.969822, lng: 20.5302873 },
    { lat: 48.9696832, lng: 20.5307954 },
    { lat: 48.9694887, lng: 20.5314442 },
    { lat: 48.9693582, lng: 20.5324164 },
    { lat: 48.9684507, lng: 20.532874 },
    { lat: 48.968839, lng: 20.5357638 },
    { lat: 48.9683895, lng: 20.5359408 },
    { lat: 48.9684479, lng: 20.5370915 },
    { lat: 48.9701482, lng: 20.53777 },
    { lat: 48.9705811, lng: 20.538457 },
    { lat: 48.9706498, lng: 20.5387328 },
    { lat: 48.9707996, lng: 20.5388229 },
    { lat: 48.971072, lng: 20.5395558 },
    { lat: 48.9711002, lng: 20.5406525 },
    { lat: 48.9710285, lng: 20.5417322 },
    { lat: 48.9708855, lng: 20.5422808 },
    { lat: 48.9702633, lng: 20.5432945 },
    { lat: 48.9697521, lng: 20.5437254 },
    { lat: 48.9692947, lng: 20.5443842 },
    { lat: 48.9686945, lng: 20.5453902 },
    { lat: 48.9679365, lng: 20.5472446 },
    { lat: 48.9679703, lng: 20.5472456 },
    { lat: 48.9757162, lng: 20.5543412 },
    { lat: 48.9768702, lng: 20.5559928 },
    { lat: 48.9773445, lng: 20.5568281 },
    { lat: 48.9778622, lng: 20.5575558 },
    { lat: 48.9782834, lng: 20.5580193 },
    { lat: 48.9783798, lng: 20.5578499 },
    { lat: 48.9792569, lng: 20.5588388 },
    { lat: 48.9781511, lng: 20.5606078 },
    { lat: 48.9782491, lng: 20.5608536 },
    { lat: 48.9776354, lng: 20.5619238 },
    { lat: 48.9773703, lng: 20.5625179 },
    { lat: 48.977275, lng: 20.5628722 },
    { lat: 48.9773801, lng: 20.56285 },
    { lat: 48.9777477, lng: 20.5630489 },
    { lat: 48.9781326, lng: 20.5630457 },
    { lat: 48.978791, lng: 20.563249 },
    { lat: 48.9800759, lng: 20.56299 },
    { lat: 48.9807609, lng: 20.5636097 },
    { lat: 48.9805994, lng: 20.5637861 },
    { lat: 48.9805035, lng: 20.5639935 },
    { lat: 48.9810245, lng: 20.5647239 },
    { lat: 48.9815859, lng: 20.56431 },
    { lat: 48.9817029, lng: 20.5645861 },
    { lat: 48.9818829, lng: 20.5645537 },
    { lat: 48.9821776, lng: 20.5643302 },
    { lat: 48.9824196, lng: 20.5646358 },
    { lat: 48.9831612, lng: 20.5637067 },
    { lat: 48.9834071, lng: 20.5638375 },
    { lat: 48.9835202, lng: 20.5637963 },
    { lat: 48.983781, lng: 20.5642732 },
    { lat: 48.984034, lng: 20.564503 },
    { lat: 48.984043, lng: 20.56448 },
    { lat: 48.984273, lng: 20.563734 },
    { lat: 48.984731, lng: 20.562266 },
    { lat: 48.984837, lng: 20.561351 },
    { lat: 48.984957, lng: 20.560265 },
    { lat: 48.985018, lng: 20.559711 },
    { lat: 48.985382, lng: 20.559145 },
    { lat: 48.985752, lng: 20.558585 },
    { lat: 48.986097, lng: 20.558062 },
    { lat: 48.98624, lng: 20.557837 },
    { lat: 48.986861, lng: 20.557619 },
    { lat: 48.987151, lng: 20.557384 },
    { lat: 48.987367, lng: 20.557209 },
    { lat: 48.987554, lng: 20.557058 },
    { lat: 48.98781, lng: 20.556855 },
    { lat: 48.988167, lng: 20.556568 },
    { lat: 48.988407, lng: 20.556379 },
    { lat: 48.988206, lng: 20.55518 },
    { lat: 48.988081, lng: 20.553846 },
    { lat: 48.988041, lng: 20.553384 },
    { lat: 48.988024, lng: 20.553199 },
    { lat: 48.988369, lng: 20.553273 },
    { lat: 48.988716, lng: 20.553309 },
    { lat: 48.988972, lng: 20.553357 },
    { lat: 48.989058, lng: 20.553367 },
    { lat: 48.989133, lng: 20.553087 },
    { lat: 48.989172, lng: 20.552909 },
    { lat: 48.989205, lng: 20.552699 },
    { lat: 48.989672, lng: 20.552715 },
    { lat: 48.989919, lng: 20.552723 },
    { lat: 48.990223, lng: 20.552734 },
    { lat: 48.990225, lng: 20.552724 },
    { lat: 48.990341, lng: 20.551893 },
    { lat: 48.990341, lng: 20.551892 },
    { lat: 48.990381, lng: 20.551605 },
    { lat: 48.990386, lng: 20.551576 },
    { lat: 48.990578, lng: 20.550369 },
    { lat: 48.990585, lng: 20.550318 },
    { lat: 48.990598, lng: 20.550211 },
    { lat: 48.990601, lng: 20.550137 },
    { lat: 48.990634, lng: 20.549422 },
    { lat: 48.99064, lng: 20.549285 },
    { lat: 48.990648, lng: 20.549016 },
    { lat: 48.990646, lng: 20.548524 },
    { lat: 48.990606, lng: 20.547633 },
    { lat: 48.990602, lng: 20.546541 },
    { lat: 48.9906, lng: 20.546085 },
    { lat: 48.9906, lng: 20.546032 },
    { lat: 48.9906, lng: 20.546005 },
    { lat: 48.990614, lng: 20.546005 },
    { lat: 48.990624, lng: 20.546006 },
    { lat: 48.990628, lng: 20.545969 },
    { lat: 48.990693, lng: 20.545131 },
    { lat: 48.990687, lng: 20.544628 },
    { lat: 48.990681, lng: 20.544102 },
    { lat: 48.990698, lng: 20.543906 },
    { lat: 48.990701, lng: 20.543883 },
    { lat: 48.990722, lng: 20.543775 },
    { lat: 48.990766, lng: 20.543556 },
    { lat: 48.990797, lng: 20.543473 },
    { lat: 48.990875, lng: 20.543266 },
    { lat: 48.990937, lng: 20.543077 },
    { lat: 48.990954, lng: 20.543008 },
    { lat: 48.990962, lng: 20.542862 },
    { lat: 48.991055, lng: 20.542419 },
    { lat: 48.991236, lng: 20.541542 },
    { lat: 48.991249, lng: 20.541481 },
    { lat: 48.991306, lng: 20.541208 },
    { lat: 48.99135, lng: 20.540951 },
    { lat: 48.991371, lng: 20.540618 },
    { lat: 48.991272, lng: 20.539855 },
    { lat: 48.991226, lng: 20.539653 },
    { lat: 48.991067, lng: 20.538956 },
    { lat: 48.991075, lng: 20.538924 },
    { lat: 48.991309, lng: 20.538729 },
    { lat: 48.991498, lng: 20.538599 },
    { lat: 48.99169, lng: 20.538443 },
    { lat: 48.991853, lng: 20.538349 },
    { lat: 48.992449, lng: 20.538017 },
    { lat: 48.992755, lng: 20.537907 },
    { lat: 48.992852, lng: 20.537877 },
    { lat: 48.993413, lng: 20.537618 },
    { lat: 48.993616, lng: 20.537565 },
    { lat: 48.99384, lng: 20.537467 },
    { lat: 48.993969, lng: 20.537394 },
    { lat: 48.994129, lng: 20.537249 },
    { lat: 48.994277, lng: 20.537116 },
    { lat: 48.994504, lng: 20.536901 },
    { lat: 48.99482, lng: 20.536518 },
    { lat: 48.994935, lng: 20.536405 },
    { lat: 48.995047, lng: 20.536279 },
    { lat: 48.995105, lng: 20.536214 },
    { lat: 48.99538, lng: 20.535856 },
    { lat: 48.995412, lng: 20.535818 },
    { lat: 48.995648, lng: 20.535553 },
    { lat: 48.995805, lng: 20.535374 },
    { lat: 48.995864, lng: 20.535279 },
    { lat: 48.995877, lng: 20.535232 },
    { lat: 48.995901, lng: 20.535144 },
    { lat: 48.996076, lng: 20.53429 },
    { lat: 48.996295, lng: 20.533717 },
    { lat: 48.996352, lng: 20.533404 },
    { lat: 48.996367, lng: 20.5334 },
    { lat: 48.996409, lng: 20.533386 },
    { lat: 48.996443, lng: 20.533373 },
    { lat: 48.99648, lng: 20.533307 },
    { lat: 48.99651, lng: 20.533253 },
    { lat: 48.996728, lng: 20.532564 },
    { lat: 48.997052, lng: 20.531684 },
    { lat: 48.997209, lng: 20.531257 },
    { lat: 48.997237, lng: 20.531178 },
    { lat: 48.997344, lng: 20.530886 },
    { lat: 48.99735, lng: 20.530874 },
    { lat: 48.997371, lng: 20.530826 },
    { lat: 48.997375, lng: 20.530818 },
    { lat: 48.997496, lng: 20.530856 },
    { lat: 48.997798, lng: 20.530951 },
    { lat: 48.997973, lng: 20.530988 },
    { lat: 48.998148, lng: 20.531019 },
    { lat: 48.998201, lng: 20.531018 },
    { lat: 48.998301, lng: 20.531002 },
    { lat: 48.998376, lng: 20.53096 },
    { lat: 48.998421, lng: 20.530901 },
    { lat: 48.998469, lng: 20.530824 },
    { lat: 48.998666, lng: 20.530396 },
    { lat: 48.998648, lng: 20.530368 },
    { lat: 48.998624, lng: 20.53033 },
    { lat: 48.9988, lng: 20.529853 },
    { lat: 48.999218, lng: 20.528744 },
    { lat: 48.999611, lng: 20.527698 },
    { lat: 48.999778, lng: 20.527252 },
    { lat: 48.999789, lng: 20.527228 },
    { lat: 48.999794, lng: 20.527213 },
    { lat: 48.999804, lng: 20.527184 },
    { lat: 48.999645, lng: 20.526989 },
    { lat: 48.999538, lng: 20.526885 },
    { lat: 48.99946, lng: 20.52667 },
    { lat: 48.999346, lng: 20.526273 },
    { lat: 48.999266, lng: 20.526155 },
    { lat: 48.999387, lng: 20.526178 },
    { lat: 48.999401, lng: 20.526176 },
    { lat: 49.000232, lng: 20.526068 },
    { lat: 49.001003, lng: 20.526441 },
    { lat: 49.001582, lng: 20.526721 },
    { lat: 49.002639, lng: 20.527203 },
    { lat: 49.003006, lng: 20.527146 },
    { lat: 49.004801, lng: 20.526746 },
    { lat: 49.004946, lng: 20.526713 },
    { lat: 49.005861, lng: 20.52652 },
    { lat: 49.006038, lng: 20.526224 },
    { lat: 49.006478, lng: 20.525455 },
    { lat: 49.00664, lng: 20.525172 },
    { lat: 49.006947, lng: 20.525399 },
    { lat: 49.007009, lng: 20.525446 },
    { lat: 49.007354, lng: 20.5257 },
    { lat: 49.007542, lng: 20.525841 },
    { lat: 49.007708, lng: 20.525964 },
    { lat: 49.007702, lng: 20.526058 },
    { lat: 49.007661, lng: 20.526647 },
    { lat: 49.007636, lng: 20.526966 },
    { lat: 49.008867, lng: 20.52728 },
    { lat: 49.009385, lng: 20.527465 },
    { lat: 49.009785, lng: 20.527699 },
    { lat: 49.009895, lng: 20.527761 },
    { lat: 49.010013, lng: 20.527877 },
    { lat: 49.010151, lng: 20.528273 },
    { lat: 49.010345, lng: 20.528852 },
    { lat: 49.010622, lng: 20.529726 },
    { lat: 49.011077, lng: 20.531269 },
    { lat: 49.011235, lng: 20.532129 },
    { lat: 49.011361, lng: 20.533133 },
    { lat: 49.011466, lng: 20.533957 },
  ],
  Jamník: [
    { lat: 48.9392466, lng: 20.6774311 },
    { lat: 48.9406814, lng: 20.6760942 },
    { lat: 48.9414877, lng: 20.6748424 },
    { lat: 48.9429426, lng: 20.673059 },
    { lat: 48.9430921, lng: 20.6730245 },
    { lat: 48.9445188, lng: 20.6731614 },
    { lat: 48.945362, lng: 20.6723394 },
    { lat: 48.9457645, lng: 20.6720437 },
    { lat: 48.9486013, lng: 20.6724804 },
    { lat: 48.9486142, lng: 20.672051 },
    { lat: 48.9489779, lng: 20.6720893 },
    { lat: 48.9491138, lng: 20.6714821 },
    { lat: 48.950388, lng: 20.6714454 },
    { lat: 48.9505636, lng: 20.6699656 },
    { lat: 48.9511627, lng: 20.6698785 },
    { lat: 48.9533522, lng: 20.6692489 },
    { lat: 48.95344, lng: 20.6693409 },
    { lat: 48.9533115, lng: 20.6696201 },
    { lat: 48.9533431, lng: 20.6698715 },
    { lat: 48.9535997, lng: 20.6703109 },
    { lat: 48.9537634, lng: 20.6712051 },
    { lat: 48.9537913, lng: 20.6716104 },
    { lat: 48.953697, lng: 20.6724281 },
    { lat: 48.9535521, lng: 20.6729737 },
    { lat: 48.952992, lng: 20.673775 },
    { lat: 48.9525801, lng: 20.6741235 },
    { lat: 48.9526009, lng: 20.6742959 },
    { lat: 48.9526985, lng: 20.6745382 },
    { lat: 48.9533672, lng: 20.675611 },
    { lat: 48.9539274, lng: 20.6764144 },
    { lat: 48.9555325, lng: 20.678302 },
    { lat: 48.9565901, lng: 20.679887 },
    { lat: 48.956998, lng: 20.6807217 },
    { lat: 48.9578269, lng: 20.6826803 },
    { lat: 48.958427, lng: 20.6830962 },
    { lat: 48.9596641, lng: 20.6836936 },
    { lat: 48.9606391, lng: 20.6840099 },
    { lat: 48.9614241, lng: 20.6837977 },
    { lat: 48.9618129, lng: 20.6838123 },
    { lat: 48.962565, lng: 20.6839718 },
    { lat: 48.9630255, lng: 20.6841316 },
    { lat: 48.9636955, lng: 20.6846404 },
    { lat: 48.9638309, lng: 20.6846791 },
    { lat: 48.964006, lng: 20.6846356 },
    { lat: 48.964434, lng: 20.6843536 },
    { lat: 48.9646163, lng: 20.6839746 },
    { lat: 48.9649125, lng: 20.6826579 },
    { lat: 48.965593, lng: 20.682069 },
    { lat: 48.965215, lng: 20.681279 },
    { lat: 48.964684, lng: 20.680403 },
    { lat: 48.964148, lng: 20.679366 },
    { lat: 48.963795, lng: 20.679123 },
    { lat: 48.963431, lng: 20.678987 },
    { lat: 48.962885, lng: 20.67881 },
    { lat: 48.96246, lng: 20.678688 },
    { lat: 48.961768, lng: 20.678439 },
    { lat: 48.961308, lng: 20.678269 },
    { lat: 48.961044, lng: 20.678134 },
    { lat: 48.960418, lng: 20.677806 },
    { lat: 48.959896, lng: 20.677359 },
    { lat: 48.959495, lng: 20.676842 },
    { lat: 48.959198, lng: 20.676366 },
    { lat: 48.959067, lng: 20.676062 },
    { lat: 48.958792, lng: 20.675655 },
    { lat: 48.95843, lng: 20.675314 },
    { lat: 48.958098, lng: 20.675122 },
    { lat: 48.957917, lng: 20.674918 },
    { lat: 48.957746, lng: 20.674624 },
    { lat: 48.957558, lng: 20.67418 },
    { lat: 48.9574, lng: 20.673585 },
    { lat: 48.957284, lng: 20.673201 },
    { lat: 48.956986, lng: 20.672442 },
    { lat: 48.956679, lng: 20.671584 },
    { lat: 48.956204, lng: 20.670014 },
    { lat: 48.955699, lng: 20.668311 },
    { lat: 48.955994, lng: 20.667378 },
    { lat: 48.955917, lng: 20.667233 },
    { lat: 48.955364, lng: 20.666155 },
    { lat: 48.954623, lng: 20.66457 },
    { lat: 48.954459, lng: 20.664078 },
    { lat: 48.954487, lng: 20.662807 },
    { lat: 48.954453, lng: 20.662811 },
    { lat: 48.954447, lng: 20.662565 },
    { lat: 48.954374, lng: 20.661571 },
    { lat: 48.954257, lng: 20.659608 },
    { lat: 48.954238, lng: 20.659151 },
    { lat: 48.954121, lng: 20.658096 },
    { lat: 48.954079, lng: 20.657403 },
    { lat: 48.953923, lng: 20.657077 },
    { lat: 48.953827, lng: 20.656765 },
    { lat: 48.953917, lng: 20.65597 },
    { lat: 48.954077, lng: 20.655209 },
    { lat: 48.954629, lng: 20.652572 },
    { lat: 48.954642, lng: 20.652368 },
    { lat: 48.954773, lng: 20.651887 },
    { lat: 48.955119, lng: 20.650781 },
    { lat: 48.955875, lng: 20.648976 },
    { lat: 48.956882, lng: 20.64625 },
    { lat: 48.957263, lng: 20.64463 },
    { lat: 48.9572, lng: 20.643652 },
    { lat: 48.957042, lng: 20.643298 },
    { lat: 48.956951, lng: 20.643088 },
    { lat: 48.956133, lng: 20.642168 },
    { lat: 48.955045, lng: 20.640713 },
    { lat: 48.954997, lng: 20.640383 },
    { lat: 48.954942, lng: 20.640046 },
    { lat: 48.954564, lng: 20.639224 },
    { lat: 48.954246, lng: 20.63872 },
    { lat: 48.954224, lng: 20.638702 },
    { lat: 48.953225, lng: 20.636997 },
    { lat: 48.952981, lng: 20.634939 },
    { lat: 48.95224, lng: 20.632311 },
    { lat: 48.9512243, lng: 20.6323582 },
    { lat: 48.9509086, lng: 20.6317481 },
    { lat: 48.9504707, lng: 20.6322162 },
    { lat: 48.9498024, lng: 20.6314799 },
    { lat: 48.9488552, lng: 20.6323146 },
    { lat: 48.9482843, lng: 20.6317794 },
    { lat: 48.9482847, lng: 20.6313539 },
    { lat: 48.9481724, lng: 20.631798 },
    { lat: 48.9478523, lng: 20.6314121 },
    { lat: 48.9476819, lng: 20.63171 },
    { lat: 48.9475421, lng: 20.6314279 },
    { lat: 48.947165, lng: 20.6309916 },
    { lat: 48.9470209, lng: 20.6310591 },
    { lat: 48.9466025, lng: 20.6315975 },
    { lat: 48.9463835, lng: 20.6316072 },
    { lat: 48.9463139, lng: 20.632142 },
    { lat: 48.9454342, lng: 20.6326902 },
    { lat: 48.9447995, lng: 20.6338512 },
    { lat: 48.9444856, lng: 20.6348583 },
    { lat: 48.9461024, lng: 20.6365735 },
    { lat: 48.9469817, lng: 20.6368556 },
    { lat: 48.9434405, lng: 20.6455482 },
    { lat: 48.9430102, lng: 20.6467603 },
    { lat: 48.9426234, lng: 20.6475538 },
    { lat: 48.9420533, lng: 20.6483245 },
    { lat: 48.9410552, lng: 20.649905 },
    { lat: 48.9402184, lng: 20.6511096 },
    { lat: 48.9400968, lng: 20.6515505 },
    { lat: 48.9371115, lng: 20.6550308 },
    { lat: 48.9338125, lng: 20.6591221 },
    { lat: 48.9329793, lng: 20.6582039 },
    { lat: 48.931749, lng: 20.659497 },
    { lat: 48.9315919, lng: 20.6597925 },
    { lat: 48.9297042, lng: 20.6600808 },
    { lat: 48.9282122, lng: 20.6601047 },
    { lat: 48.9259328, lng: 20.6604891 },
    { lat: 48.9248886, lng: 20.6611568 },
    { lat: 48.9240689, lng: 20.6618142 },
    { lat: 48.9242429, lng: 20.6625538 },
    { lat: 48.92298, lng: 20.6642802 },
    { lat: 48.9214268, lng: 20.6641343 },
    { lat: 48.9200572, lng: 20.6656886 },
    { lat: 48.91983, lng: 20.6661613 },
    { lat: 48.9195263, lng: 20.6673633 },
    { lat: 48.9192698, lng: 20.6673323 },
    { lat: 48.9186429, lng: 20.6678107 },
    { lat: 48.9182249, lng: 20.6677972 },
    { lat: 48.9182241, lng: 20.6687827 },
    { lat: 48.9180684, lng: 20.6694993 },
    { lat: 48.9180411, lng: 20.6699608 },
    { lat: 48.918976, lng: 20.6715152 },
    { lat: 48.9196684, lng: 20.6728652 },
    { lat: 48.9203616, lng: 20.6734896 },
    { lat: 48.9213887, lng: 20.6751933 },
    { lat: 48.9221009, lng: 20.6760309 },
    { lat: 48.9223497, lng: 20.6766952 },
    { lat: 48.922405, lng: 20.6770486 },
    { lat: 48.9224359, lng: 20.6787296 },
    { lat: 48.9220979, lng: 20.6798165 },
    { lat: 48.922151, lng: 20.6798907 },
    { lat: 48.9218498, lng: 20.6805928 },
    { lat: 48.9216985, lng: 20.6804921 },
    { lat: 48.9219582, lng: 20.6807271 },
    { lat: 48.9233567, lng: 20.6811181 },
    { lat: 48.9240642, lng: 20.6809411 },
    { lat: 48.9245442, lng: 20.6801355 },
    { lat: 48.9251369, lng: 20.6811868 },
    { lat: 48.9261248, lng: 20.6809873 },
    { lat: 48.9272161, lng: 20.680494 },
    { lat: 48.9274109, lng: 20.6812233 },
    { lat: 48.9288392, lng: 20.6807417 },
    { lat: 48.9304919, lng: 20.6797374 },
    { lat: 48.9314086, lng: 20.67946 },
    { lat: 48.9319869, lng: 20.6791598 },
    { lat: 48.9322365, lng: 20.6790302 },
    { lat: 48.9324384, lng: 20.6789254 },
    { lat: 48.9326767, lng: 20.6788016 },
    { lat: 48.9329094, lng: 20.6786808 },
    { lat: 48.939027, lng: 20.6776433 },
    { lat: 48.9392466, lng: 20.6774311 },
  ],
  Kolinovce: [
    { lat: 48.9284858, lng: 20.8688411 },
    { lat: 48.9286281, lng: 20.8684923 },
    { lat: 48.9290482, lng: 20.8682349 },
    { lat: 48.9292444, lng: 20.8680147 },
    { lat: 48.9297635, lng: 20.8671978 },
    { lat: 48.9298582, lng: 20.8669716 },
    { lat: 48.9299911, lng: 20.8668366 },
    { lat: 48.9304941, lng: 20.8658799 },
    { lat: 48.9307858, lng: 20.865422 },
    { lat: 48.9315562, lng: 20.8645101 },
    { lat: 48.9316268, lng: 20.8642718 },
    { lat: 48.931669, lng: 20.8639596 },
    { lat: 48.9316578, lng: 20.86317 },
    { lat: 48.9319578, lng: 20.8621669 },
    { lat: 48.9323629, lng: 20.8614724 },
    { lat: 48.9329183, lng: 20.8582915 },
    { lat: 48.9338144, lng: 20.8548033 },
    { lat: 48.9344156, lng: 20.850111 },
    { lat: 48.934637, lng: 20.8473787 },
    { lat: 48.9350082, lng: 20.8470042 },
    { lat: 48.9361365, lng: 20.8448546 },
    { lat: 48.9368655, lng: 20.8433344 },
    { lat: 48.9385373, lng: 20.8431119 },
    { lat: 48.9385542, lng: 20.8431088 },
    { lat: 48.9379446, lng: 20.8423704 },
    { lat: 48.9379435, lng: 20.8421055 },
    { lat: 48.9376924, lng: 20.8417799 },
    { lat: 48.9368028, lng: 20.8416025 },
    { lat: 48.9361826, lng: 20.8409151 },
    { lat: 48.9357382, lng: 20.8405633 },
    { lat: 48.9348664, lng: 20.8401854 },
    { lat: 48.9343437, lng: 20.8397818 },
    { lat: 48.9338211, lng: 20.8395446 },
    { lat: 48.9333529, lng: 20.8396751 },
    { lat: 48.9328812, lng: 20.8394433 },
    { lat: 48.932564, lng: 20.8389285 },
    { lat: 48.9321291, lng: 20.8383894 },
    { lat: 48.9330494, lng: 20.8381451 },
    { lat: 48.9335883, lng: 20.8378478 },
    { lat: 48.933919, lng: 20.8375463 },
    { lat: 48.9334846, lng: 20.8369582 },
    { lat: 48.9330944, lng: 20.8366429 },
    { lat: 48.9304834, lng: 20.8339378 },
    { lat: 48.9303139, lng: 20.8340139 },
    { lat: 48.928677, lng: 20.8336829 },
    { lat: 48.928293, lng: 20.8334499 },
    { lat: 48.9282792, lng: 20.8332112 },
    { lat: 48.9278568, lng: 20.8334326 },
    { lat: 48.9276246, lng: 20.8334567 },
    { lat: 48.9267874, lng: 20.8328115 },
    { lat: 48.9267788, lng: 20.832597 },
    { lat: 48.9263197, lng: 20.8323395 },
    { lat: 48.9256798, lng: 20.8317664 },
    { lat: 48.9254047, lng: 20.8315927 },
    { lat: 48.9241611, lng: 20.8312679 },
    { lat: 48.9232799, lng: 20.8311116 },
    { lat: 48.9225036, lng: 20.8309885 },
    { lat: 48.9217894, lng: 20.8310723 },
    { lat: 48.9212305, lng: 20.831287 },
    { lat: 48.9208632, lng: 20.8313065 },
    { lat: 48.9203838, lng: 20.8314412 },
    { lat: 48.9193976, lng: 20.8319625 },
    { lat: 48.9171294, lng: 20.8321259 },
    { lat: 48.9166058, lng: 20.8322497 },
    { lat: 48.916187, lng: 20.8322471 },
    { lat: 48.9156825, lng: 20.8324406 },
    { lat: 48.9150687, lng: 20.8324589 },
    { lat: 48.9140413, lng: 20.8318793 },
    { lat: 48.9130451, lng: 20.8311657 },
    { lat: 48.9120753, lng: 20.8306897 },
    { lat: 48.9119335, lng: 20.8306551 },
    { lat: 48.9124627, lng: 20.8323291 },
    { lat: 48.9124949, lng: 20.8328039 },
    { lat: 48.9124088, lng: 20.8339631 },
    { lat: 48.9120509, lng: 20.8354054 },
    { lat: 48.9119594, lng: 20.8362156 },
    { lat: 48.9120066, lng: 20.8365043 },
    { lat: 48.9122519, lng: 20.8372231 },
    { lat: 48.9130165, lng: 20.8382766 },
    { lat: 48.9129837, lng: 20.8383039 },
    { lat: 48.9131945, lng: 20.839495 },
    { lat: 48.913258, lng: 20.8401023 },
    { lat: 48.9137275, lng: 20.8415381 },
    { lat: 48.9138418, lng: 20.8426056 },
    { lat: 48.9141018, lng: 20.8435896 },
    { lat: 48.9142963, lng: 20.8459111 },
    { lat: 48.9142898, lng: 20.8463461 },
    { lat: 48.9140572, lng: 20.8468197 },
    { lat: 48.9141396, lng: 20.8482654 },
    { lat: 48.9141175, lng: 20.8485441 },
    { lat: 48.9144892, lng: 20.8498636 },
    { lat: 48.9147243, lng: 20.8511674 },
    { lat: 48.9148417, lng: 20.8510851 },
    { lat: 48.915032, lng: 20.8513797 },
    { lat: 48.9155253, lng: 20.8517194 },
    { lat: 48.9158305, lng: 20.8515257 },
    { lat: 48.9159717, lng: 20.852031 },
    { lat: 48.9163483, lng: 20.8527389 },
    { lat: 48.9166737, lng: 20.8531355 },
    { lat: 48.9171396, lng: 20.853495 },
    { lat: 48.917727, lng: 20.8543097 },
    { lat: 48.9180376, lng: 20.8548371 },
    { lat: 48.9184654, lng: 20.855235 },
    { lat: 48.9188304, lng: 20.8558651 },
    { lat: 48.9189366, lng: 20.8558736 },
    { lat: 48.9190636, lng: 20.856001 },
    { lat: 48.9190944, lng: 20.85615 },
    { lat: 48.9192018, lng: 20.8562564 },
    { lat: 48.9193005, lng: 20.8564798 },
    { lat: 48.9193742, lng: 20.8571175 },
    { lat: 48.9195906, lng: 20.8576857 },
    { lat: 48.9196313, lng: 20.8580536 },
    { lat: 48.9198205, lng: 20.8584206 },
    { lat: 48.9199625, lng: 20.8585848 },
    { lat: 48.9200975, lng: 20.859043 },
    { lat: 48.9205108, lng: 20.8597746 },
    { lat: 48.9205443, lng: 20.8599968 },
    { lat: 48.9208266, lng: 20.8606521 },
    { lat: 48.9208184, lng: 20.8611015 },
    { lat: 48.9210403, lng: 20.861438 },
    { lat: 48.9212947, lng: 20.8622638 },
    { lat: 48.9214272, lng: 20.8623854 },
    { lat: 48.9217785, lng: 20.8619254 },
    { lat: 48.9221604, lng: 20.8616344 },
    { lat: 48.9226933, lng: 20.8616157 },
    { lat: 48.922892, lng: 20.8618696 },
    { lat: 48.9230471, lng: 20.8618252 },
    { lat: 48.9232263, lng: 20.8620634 },
    { lat: 48.9232289, lng: 20.8624351 },
    { lat: 48.9233273, lng: 20.8624159 },
    { lat: 48.9239305, lng: 20.861625 },
    { lat: 48.9242538, lng: 20.8616314 },
    { lat: 48.9246925, lng: 20.861918 },
    { lat: 48.9250598, lng: 20.8618586 },
    { lat: 48.925857, lng: 20.861574 },
    { lat: 48.9264685, lng: 20.8610175 },
    { lat: 48.9266662, lng: 20.8609783 },
    { lat: 48.926998, lng: 20.8623218 },
    { lat: 48.9269289, lng: 20.8624056 },
    { lat: 48.9278097, lng: 20.8657165 },
    { lat: 48.9279396, lng: 20.8668189 },
    { lat: 48.9282824, lng: 20.868818 },
    { lat: 48.9284858, lng: 20.8688411 },
  ],
  Teplička: [
    { lat: 48.9181944, lng: 20.5859385 },
    { lat: 48.9180879, lng: 20.5858906 },
    { lat: 48.917666, lng: 20.5848493 },
    { lat: 48.9169792, lng: 20.5827913 },
    { lat: 48.9164217, lng: 20.5814909 },
    { lat: 48.9161647, lng: 20.5811783 },
    { lat: 48.9161578, lng: 20.5811699 },
    { lat: 48.9160526, lng: 20.5805774 },
    { lat: 48.91611, lng: 20.5804179 },
    { lat: 48.9159892, lng: 20.5801661 },
    { lat: 48.9159461, lng: 20.5797377 },
    { lat: 48.9158801, lng: 20.5793398 },
    { lat: 48.915573, lng: 20.5779629 },
    { lat: 48.9154401, lng: 20.577162 },
    { lat: 48.9152316, lng: 20.5762621 },
    { lat: 48.9149745, lng: 20.5755758 },
    { lat: 48.9145712, lng: 20.5748335 },
    { lat: 48.9140322, lng: 20.5744101 },
    { lat: 48.9133167, lng: 20.5733509 },
    { lat: 48.9131473, lng: 20.5732145 },
    { lat: 48.9126136, lng: 20.5724485 },
    { lat: 48.9117609, lng: 20.5709034 },
    { lat: 48.9115905, lng: 20.5708298 },
    { lat: 48.9113194, lng: 20.5703168 },
    { lat: 48.9111628, lng: 20.5701239 },
    { lat: 48.9108713, lng: 20.5691185 },
    { lat: 48.9106483, lng: 20.5688406 },
    { lat: 48.9097463, lng: 20.5685299 },
    { lat: 48.9096491, lng: 20.5684276 },
    { lat: 48.9095824, lng: 20.5684475 },
    { lat: 48.9089424, lng: 20.5679175 },
    { lat: 48.9086062, lng: 20.5677097 },
    { lat: 48.9070558, lng: 20.5674936 },
    { lat: 48.9063592, lng: 20.5672837 },
    { lat: 48.9059415, lng: 20.5672876 },
    { lat: 48.9056459, lng: 20.567115 },
    { lat: 48.9052332, lng: 20.5670354 },
    { lat: 48.904861, lng: 20.5671742 },
    { lat: 48.9044113, lng: 20.5669721 },
    { lat: 48.9032333, lng: 20.5667865 },
    { lat: 48.9029413, lng: 20.5668018 },
    { lat: 48.9027284, lng: 20.5669193 },
    { lat: 48.9019278, lng: 20.567867 },
    { lat: 48.9016371, lng: 20.5679889 },
    { lat: 48.9010592, lng: 20.5677926 },
    { lat: 48.8995942, lng: 20.5681225 },
    { lat: 48.8989558, lng: 20.5681217 },
    { lat: 48.8983469, lng: 20.5680333 },
    { lat: 48.8982777, lng: 20.568967 },
    { lat: 48.8981611, lng: 20.5689536 },
    { lat: 48.8981304, lng: 20.5692496 },
    { lat: 48.8966159, lng: 20.5704388 },
    { lat: 48.893651, lng: 20.5729383 },
    { lat: 48.8931184, lng: 20.5736155 },
    { lat: 48.892477, lng: 20.5747555 },
    { lat: 48.8897857, lng: 20.5785813 },
    { lat: 48.8879403, lng: 20.5814648 },
    { lat: 48.8882807, lng: 20.5820954 },
    { lat: 48.8881858, lng: 20.5826991 },
    { lat: 48.8880602, lng: 20.5846176 },
    { lat: 48.8889497, lng: 20.5847423 },
    { lat: 48.8898114, lng: 20.5854336 },
    { lat: 48.8907226, lng: 20.5862743 },
    { lat: 48.8915606, lng: 20.5880692 },
    { lat: 48.8923478, lng: 20.5906599 },
    { lat: 48.8927351, lng: 20.5911887 },
    { lat: 48.8942223, lng: 20.5937121 },
    { lat: 48.8947074, lng: 20.5942635 },
    { lat: 48.895395, lng: 20.5948101 },
    { lat: 48.8958766, lng: 20.5947418 },
    { lat: 48.8959635, lng: 20.5947806 },
    { lat: 48.8973366, lng: 20.5962179 },
    { lat: 48.897529, lng: 20.5964933 },
    { lat: 48.8976742, lng: 20.596211 },
    { lat: 48.8979768, lng: 20.5960875 },
    { lat: 48.8985703, lng: 20.5961175 },
    { lat: 48.898996, lng: 20.5962499 },
    { lat: 48.8996829, lng: 20.5966224 },
    { lat: 48.9020645, lng: 20.5980245 },
    { lat: 48.9026783, lng: 20.5981537 },
    { lat: 48.9039843, lng: 20.5979385 },
    { lat: 48.9050597, lng: 20.5980655 },
    { lat: 48.9050621, lng: 20.5984733 },
    { lat: 48.9049869, lng: 20.5987863 },
    { lat: 48.9051273, lng: 20.5990909 },
    { lat: 48.9052763, lng: 20.6000575 },
    { lat: 48.9051968, lng: 20.6002202 },
    { lat: 48.9052378, lng: 20.600789 },
    { lat: 48.9051096, lng: 20.6025258 },
    { lat: 48.9052475, lng: 20.6027568 },
    { lat: 48.905309, lng: 20.6032674 },
    { lat: 48.9058312, lng: 20.6032633 },
    { lat: 48.9058465, lng: 20.603529 },
    { lat: 48.9060251, lng: 20.6034897 },
    { lat: 48.9060863, lng: 20.6042422 },
    { lat: 48.9068006, lng: 20.6040417 },
    { lat: 48.9072161, lng: 20.6041325 },
    { lat: 48.9083857, lng: 20.6041898 },
    { lat: 48.9082258, lng: 20.6056176 },
    { lat: 48.909653, lng: 20.605823 },
    { lat: 48.9103487, lng: 20.6060682 },
    { lat: 48.9113855, lng: 20.6059119 },
    { lat: 48.9112703, lng: 20.6045661 },
    { lat: 48.9112883, lng: 20.6042314 },
    { lat: 48.9116135, lng: 20.6020924 },
    { lat: 48.9147243, lng: 20.60423 },
    { lat: 48.9153879, lng: 20.6045524 },
    { lat: 48.9154013, lng: 20.604053 },
    { lat: 48.915485, lng: 20.6036959 },
    { lat: 48.915585, lng: 20.603655 },
    { lat: 48.9157649, lng: 20.602972 },
    { lat: 48.9159912, lng: 20.6028495 },
    { lat: 48.9160621, lng: 20.6026911 },
    { lat: 48.9161717, lng: 20.6020867 },
    { lat: 48.9163137, lng: 20.6022426 },
    { lat: 48.9163735, lng: 20.6021538 },
    { lat: 48.9163274, lng: 20.6014465 },
    { lat: 48.9164603, lng: 20.6010788 },
    { lat: 48.9166697, lng: 20.6008314 },
    { lat: 48.9167611, lng: 20.6003724 },
    { lat: 48.9169873, lng: 20.6000871 },
    { lat: 48.9170161, lng: 20.5998935 },
    { lat: 48.916826, lng: 20.5991835 },
    { lat: 48.9167118, lng: 20.5989239 },
    { lat: 48.9167132, lng: 20.5986833 },
    { lat: 48.9168499, lng: 20.5983904 },
    { lat: 48.9168341, lng: 20.5981748 },
    { lat: 48.9169528, lng: 20.5981563 },
    { lat: 48.9169277, lng: 20.5976837 },
    { lat: 48.9169937, lng: 20.5975773 },
    { lat: 48.9170964, lng: 20.5977404 },
    { lat: 48.9172614, lng: 20.597389 },
    { lat: 48.9172228, lng: 20.5971635 },
    { lat: 48.9177477, lng: 20.5972931 },
    { lat: 48.917818, lng: 20.5967177 },
    { lat: 48.9181926, lng: 20.5950145 },
    { lat: 48.9185745, lng: 20.5935735 },
    { lat: 48.9186535, lng: 20.5924997 },
    { lat: 48.9188608, lng: 20.5916046 },
    { lat: 48.9186463, lng: 20.590268 },
    { lat: 48.9184416, lng: 20.5895922 },
    { lat: 48.9180207, lng: 20.5888613 },
    { lat: 48.9179193, lng: 20.5885462 },
    { lat: 48.9178875, lng: 20.5882321 },
    { lat: 48.9179478, lng: 20.5870461 },
    { lat: 48.9181944, lng: 20.5859385 },
  ],
  Rudňany: [
    { lat: 48.89532, lng: 20.6825715 },
    { lat: 48.8951829, lng: 20.6810064 },
    { lat: 48.8951341, lng: 20.6796433 },
    { lat: 48.8951243, lng: 20.6779236 },
    { lat: 48.8951827, lng: 20.6768154 },
    { lat: 48.8957274, lng: 20.6756471 },
    { lat: 48.8962008, lng: 20.6740918 },
    { lat: 48.8966905, lng: 20.6733742 },
    { lat: 48.8971962, lng: 20.6719024 },
    { lat: 48.8973718, lng: 20.6711748 },
    { lat: 48.8973823, lng: 20.6708093 },
    { lat: 48.8980406, lng: 20.6693596 },
    { lat: 48.8996508, lng: 20.668431 },
    { lat: 48.8996674, lng: 20.668352 },
    { lat: 48.8987525, lng: 20.6650709 },
    { lat: 48.8992806, lng: 20.6643847 },
    { lat: 48.8996645, lng: 20.6637572 },
    { lat: 48.9004858, lng: 20.6627748 },
    { lat: 48.9012758, lng: 20.6613218 },
    { lat: 48.901717, lng: 20.6606924 },
    { lat: 48.9013524, lng: 20.6600073 },
    { lat: 48.9011878, lng: 20.6601261 },
    { lat: 48.9009142, lng: 20.6600299 },
    { lat: 48.900642, lng: 20.6600696 },
    { lat: 48.9003686, lng: 20.6602028 },
    { lat: 48.8997369, lng: 20.6594428 },
    { lat: 48.8986595, lng: 20.6588191 },
    { lat: 48.8974995, lng: 20.6584309 },
    { lat: 48.8972134, lng: 20.6585154 },
    { lat: 48.8970155, lng: 20.6583994 },
    { lat: 48.8966767, lng: 20.6584038 },
    { lat: 48.8960803, lng: 20.658221 },
    { lat: 48.8959015, lng: 20.6580712 },
    { lat: 48.8958141, lng: 20.6582054 },
    { lat: 48.895183, lng: 20.658152 },
    { lat: 48.895098, lng: 20.658242 },
    { lat: 48.895061, lng: 20.658285 },
    { lat: 48.894915, lng: 20.658247 },
    { lat: 48.894768, lng: 20.658212 },
    { lat: 48.894707, lng: 20.65819 },
    { lat: 48.89449, lng: 20.658107 },
    { lat: 48.8944, lng: 20.658147 },
    { lat: 48.894093, lng: 20.65815 },
    { lat: 48.893747, lng: 20.658148 },
    { lat: 48.893237, lng: 20.658148 },
    { lat: 48.892962, lng: 20.658058 },
    { lat: 48.892609, lng: 20.657942 },
    { lat: 48.892215, lng: 20.657812 },
    { lat: 48.891898, lng: 20.657713 },
    { lat: 48.891695, lng: 20.657646 },
    { lat: 48.891344, lng: 20.657941 },
    { lat: 48.89107, lng: 20.657186 },
    { lat: 48.890883, lng: 20.656674 },
    { lat: 48.890624, lng: 20.656725 },
    { lat: 48.890383, lng: 20.657102 },
    { lat: 48.890191, lng: 20.657403 },
    { lat: 48.889884, lng: 20.657342 },
    { lat: 48.889682, lng: 20.657422 },
    { lat: 48.889419, lng: 20.657449 },
    { lat: 48.888914, lng: 20.6575 },
    { lat: 48.88844, lng: 20.657539 },
    { lat: 48.888132, lng: 20.657275 },
    { lat: 48.887716, lng: 20.657139 },
    { lat: 48.887363, lng: 20.657614 },
    { lat: 48.8871, lng: 20.657971 },
    { lat: 48.88687, lng: 20.658277 },
    { lat: 48.886621, lng: 20.658368 },
    { lat: 48.886382, lng: 20.658452 },
    { lat: 48.886067, lng: 20.658425 },
    { lat: 48.885708, lng: 20.658398 },
    { lat: 48.885501, lng: 20.658382 },
    { lat: 48.885061, lng: 20.658347 },
    { lat: 48.884469, lng: 20.658301 },
    { lat: 48.883817, lng: 20.658242 },
    { lat: 48.883428, lng: 20.658252 },
    { lat: 48.882977, lng: 20.658273 },
    { lat: 48.882409, lng: 20.658291 },
    { lat: 48.881625, lng: 20.658321 },
    { lat: 48.881494, lng: 20.658925 },
    { lat: 48.881464, lng: 20.659084 },
    { lat: 48.881302, lng: 20.659005 },
    { lat: 48.881133, lng: 20.658931 },
    { lat: 48.880929, lng: 20.65883 },
    { lat: 48.880604, lng: 20.658676 },
    { lat: 48.880528, lng: 20.65863 },
    { lat: 48.879923, lng: 20.658332 },
    { lat: 48.879476, lng: 20.65811 },
    { lat: 48.879166, lng: 20.657966 },
    { lat: 48.878871, lng: 20.657817 },
    { lat: 48.87841, lng: 20.657595 },
    { lat: 48.878025, lng: 20.657406 },
    { lat: 48.877749, lng: 20.657265 },
    { lat: 48.877674, lng: 20.657233 },
    { lat: 48.877513, lng: 20.657156 },
    { lat: 48.877398, lng: 20.657309 },
    { lat: 48.877387, lng: 20.657324 },
    { lat: 48.877408, lng: 20.657567 },
    { lat: 48.877385, lng: 20.657702 },
    { lat: 48.877434, lng: 20.65778 },
    { lat: 48.877522, lng: 20.657792 },
    { lat: 48.877587, lng: 20.657913 },
    { lat: 48.877615, lng: 20.658148 },
    { lat: 48.877764, lng: 20.658309 },
    { lat: 48.877884, lng: 20.658273 },
    { lat: 48.877956, lng: 20.658373 },
    { lat: 48.878029, lng: 20.658792 },
    { lat: 48.878121, lng: 20.659209 },
    { lat: 48.878203, lng: 20.659416 },
    { lat: 48.878286, lng: 20.659629 },
    { lat: 48.878268, lng: 20.659639 },
    { lat: 48.878249, lng: 20.659681 },
    { lat: 48.878192, lng: 20.659741 },
    { lat: 48.878201, lng: 20.659756 },
    { lat: 48.878134, lng: 20.659804 },
    { lat: 48.878023, lng: 20.659885 },
    { lat: 48.877954, lng: 20.659946 },
    { lat: 48.877909, lng: 20.659991 },
    { lat: 48.87783, lng: 20.659992 },
    { lat: 48.87771, lng: 20.659991 },
    { lat: 48.877663, lng: 20.660057 },
    { lat: 48.877593, lng: 20.660017 },
    { lat: 48.877549, lng: 20.660105 },
    { lat: 48.877416, lng: 20.660216 },
    { lat: 48.87734, lng: 20.660237 },
    { lat: 48.87728, lng: 20.660181 },
    { lat: 48.877117, lng: 20.660313 },
    { lat: 48.87709, lng: 20.660411 },
    { lat: 48.876977, lng: 20.660348 },
    { lat: 48.876911, lng: 20.660435 },
    { lat: 48.876842, lng: 20.660487 },
    { lat: 48.876837, lng: 20.660588 },
    { lat: 48.876781, lng: 20.660596 },
    { lat: 48.876767, lng: 20.660599 },
    { lat: 48.876687, lng: 20.66056 },
    { lat: 48.876587, lng: 20.660614 },
    { lat: 48.876549, lng: 20.660678 },
    { lat: 48.87644, lng: 20.660663 },
    { lat: 48.876314, lng: 20.660709 },
    { lat: 48.87614, lng: 20.660766 },
    { lat: 48.876082, lng: 20.660749 },
    { lat: 48.875999, lng: 20.660858 },
    { lat: 48.875942, lng: 20.660903 },
    { lat: 48.87589, lng: 20.660852 },
    { lat: 48.875694, lng: 20.660872 },
    { lat: 48.875636, lng: 20.660935 },
    { lat: 48.875604, lng: 20.660987 },
    { lat: 48.875395, lng: 20.660952 },
    { lat: 48.875246, lng: 20.660891 },
    { lat: 48.875115, lng: 20.660919 },
    { lat: 48.875038, lng: 20.660849 },
    { lat: 48.874962, lng: 20.660746 },
    { lat: 48.874889, lng: 20.660678 },
    { lat: 48.874762, lng: 20.660805 },
    { lat: 48.874679, lng: 20.660719 },
    { lat: 48.874483, lng: 20.660741 },
    { lat: 48.874283, lng: 20.660765 },
    { lat: 48.874168, lng: 20.660838 },
    { lat: 48.873849, lng: 20.660786 },
    { lat: 48.87381, lng: 20.660741 },
    { lat: 48.873707, lng: 20.660789 },
    { lat: 48.873669, lng: 20.660716 },
    { lat: 48.873614, lng: 20.660702 },
    { lat: 48.873532, lng: 20.660662 },
    { lat: 48.873502, lng: 20.660586 },
    { lat: 48.873463, lng: 20.660553 },
    { lat: 48.873435, lng: 20.660458 },
    { lat: 48.873336, lng: 20.66039 },
    { lat: 48.873289, lng: 20.66042 },
    { lat: 48.873255, lng: 20.66031 },
    { lat: 48.873134, lng: 20.660216 },
    { lat: 48.873104, lng: 20.660284 },
    { lat: 48.873021, lng: 20.66039 },
    { lat: 48.872877, lng: 20.660309 },
    { lat: 48.872745, lng: 20.660483 },
    { lat: 48.872582, lng: 20.660475 },
    { lat: 48.87252, lng: 20.660511 },
    { lat: 48.872449, lng: 20.660398 },
    { lat: 48.872376, lng: 20.660381 },
    { lat: 48.872334, lng: 20.660315 },
    { lat: 48.872295, lng: 20.66036 },
    { lat: 48.872191, lng: 20.660403 },
    { lat: 48.872121, lng: 20.660314 },
    { lat: 48.872024, lng: 20.660301 },
    { lat: 48.871958, lng: 20.660249 },
    { lat: 48.871826, lng: 20.66022 },
    { lat: 48.871557, lng: 20.660126 },
    { lat: 48.871474, lng: 20.660029 },
    { lat: 48.871355, lng: 20.660028 },
    { lat: 48.871303, lng: 20.659977 },
    { lat: 48.871227, lng: 20.659987 },
    { lat: 48.871191, lng: 20.659935 },
    { lat: 48.871138, lng: 20.659984 },
    { lat: 48.870988, lng: 20.659917 },
    { lat: 48.870849, lng: 20.659855 },
    { lat: 48.8706, lng: 20.659678 },
    { lat: 48.870565, lng: 20.659612 },
    { lat: 48.870498, lng: 20.659673 },
    { lat: 48.870458, lng: 20.659579 },
    { lat: 48.870413, lng: 20.65955 },
    { lat: 48.870377, lng: 20.659473 },
    { lat: 48.87032, lng: 20.659467 },
    { lat: 48.87029, lng: 20.659331 },
    { lat: 48.870109, lng: 20.659209 },
    { lat: 48.869988, lng: 20.659124 },
    { lat: 48.869883, lng: 20.658938 },
    { lat: 48.869653, lng: 20.658695 },
    { lat: 48.869424, lng: 20.658638 },
    { lat: 48.869302, lng: 20.658494 },
    { lat: 48.869155, lng: 20.658367 },
    { lat: 48.868919, lng: 20.658177 },
    { lat: 48.868453, lng: 20.658155 },
    { lat: 48.868169, lng: 20.658287 },
    { lat: 48.867781, lng: 20.658475 },
    { lat: 48.867698, lng: 20.658472 },
    { lat: 48.867574, lng: 20.658468 },
    { lat: 48.867409, lng: 20.658472 },
    { lat: 48.867282, lng: 20.658479 },
    { lat: 48.867246, lng: 20.65848 },
    { lat: 48.867094, lng: 20.658589 },
    { lat: 48.866922, lng: 20.65871 },
    { lat: 48.86662, lng: 20.658781 },
    { lat: 48.866087, lng: 20.658931 },
    { lat: 48.865595, lng: 20.659065 },
    { lat: 48.865335, lng: 20.659037 },
    { lat: 48.865101, lng: 20.659287 },
    { lat: 48.864725, lng: 20.659538 },
    { lat: 48.86445, lng: 20.65972 },
    { lat: 48.864274, lng: 20.659523 },
    { lat: 48.863992, lng: 20.658915 },
    { lat: 48.863742, lng: 20.658369 },
    { lat: 48.863535, lng: 20.657918 },
    { lat: 48.863137, lng: 20.65745 },
    { lat: 48.862771, lng: 20.657017 },
    { lat: 48.862337, lng: 20.656495 },
    { lat: 48.862022, lng: 20.656125 },
    { lat: 48.861641, lng: 20.655675 },
    { lat: 48.861254, lng: 20.655216 },
    { lat: 48.861008, lng: 20.654927 },
    { lat: 48.860913, lng: 20.654815 },
    { lat: 48.860836, lng: 20.654729 },
    { lat: 48.860826, lng: 20.654718 },
    { lat: 48.860759, lng: 20.654947 },
    { lat: 48.860746, lng: 20.654995 },
    { lat: 48.860779, lng: 20.655015 },
    { lat: 48.860785, lng: 20.655141 },
    { lat: 48.86078, lng: 20.655249 },
    { lat: 48.860727, lng: 20.655298 },
    { lat: 48.86071, lng: 20.655386 },
    { lat: 48.860727, lng: 20.655616 },
    { lat: 48.860687, lng: 20.65577 },
    { lat: 48.860711, lng: 20.655842 },
    { lat: 48.860673, lng: 20.655906 },
    { lat: 48.86063, lng: 20.656031 },
    { lat: 48.860597, lng: 20.656106 },
    { lat: 48.860588, lng: 20.656175 },
    { lat: 48.860554, lng: 20.656209 },
    { lat: 48.860515, lng: 20.656311 },
    { lat: 48.86044, lng: 20.656426 },
    { lat: 48.860461, lng: 20.656601 },
    { lat: 48.860365, lng: 20.65688 },
    { lat: 48.860297, lng: 20.657072 },
    { lat: 48.860303, lng: 20.657197 },
    { lat: 48.860268, lng: 20.657418 },
    { lat: 48.860232, lng: 20.657612 },
    { lat: 48.860198, lng: 20.657787 },
    { lat: 48.860186, lng: 20.657977 },
    { lat: 48.860153, lng: 20.657995 },
    { lat: 48.860141, lng: 20.658027 },
    { lat: 48.860089, lng: 20.658037 },
    { lat: 48.860039, lng: 20.658124 },
    { lat: 48.859947, lng: 20.658194 },
    { lat: 48.85991, lng: 20.658275 },
    { lat: 48.859909, lng: 20.658331 },
    { lat: 48.859854, lng: 20.658571 },
    { lat: 48.85977, lng: 20.658661 },
    { lat: 48.859692, lng: 20.658676 },
    { lat: 48.859591, lng: 20.65875 },
    { lat: 48.859506, lng: 20.65883 },
    { lat: 48.859454, lng: 20.658892 },
    { lat: 48.859414, lng: 20.659009 },
    { lat: 48.859323, lng: 20.65914 },
    { lat: 48.859255, lng: 20.659158 },
    { lat: 48.859144, lng: 20.659278 },
    { lat: 48.859109, lng: 20.659284 },
    { lat: 48.859083, lng: 20.65927 },
    { lat: 48.859036, lng: 20.65929 },
    { lat: 48.85896, lng: 20.659361 },
    { lat: 48.858915, lng: 20.659319 },
    { lat: 48.858792, lng: 20.659307 },
    { lat: 48.858722, lng: 20.659299 },
    { lat: 48.858692, lng: 20.659295 },
    { lat: 48.858656, lng: 20.659242 },
    { lat: 48.858593, lng: 20.6592 },
    { lat: 48.858555, lng: 20.659269 },
    { lat: 48.858509, lng: 20.659316 },
    { lat: 48.858464, lng: 20.659308 },
    { lat: 48.85833, lng: 20.659269 },
    { lat: 48.858237, lng: 20.659205 },
    { lat: 48.858147, lng: 20.659166 },
    { lat: 48.858058, lng: 20.65918 },
    { lat: 48.857992, lng: 20.659144 },
    { lat: 48.857935, lng: 20.659182 },
    { lat: 48.857856, lng: 20.659161 },
    { lat: 48.857766, lng: 20.659092 },
    { lat: 48.857697, lng: 20.659038 },
    { lat: 48.857659, lng: 20.659104 },
    { lat: 48.857609, lng: 20.659135 },
    { lat: 48.857455, lng: 20.659131 },
    { lat: 48.857344, lng: 20.659113 },
    { lat: 48.857254, lng: 20.659099 },
    { lat: 48.857134, lng: 20.659182 },
    { lat: 48.857046, lng: 20.659255 },
    { lat: 48.856941, lng: 20.659276 },
    { lat: 48.856869, lng: 20.659294 },
    { lat: 48.856799, lng: 20.659392 },
    { lat: 48.856715, lng: 20.659503 },
    { lat: 48.856646, lng: 20.659498 },
    { lat: 48.856588, lng: 20.659555 },
    { lat: 48.856574, lng: 20.659561 },
    { lat: 48.85641, lng: 20.659641 },
    { lat: 48.856081, lng: 20.659801 },
    { lat: 48.855662, lng: 20.660007 },
    { lat: 48.855364, lng: 20.66016 },
    { lat: 48.854905, lng: 20.66033 },
    { lat: 48.854705, lng: 20.660404 },
    { lat: 48.854502, lng: 20.660448 },
    { lat: 48.854302, lng: 20.660487 },
    { lat: 48.854142, lng: 20.66052 },
    { lat: 48.854059, lng: 20.660546 },
    { lat: 48.853821, lng: 20.660629 },
    { lat: 48.853707, lng: 20.660692 },
    { lat: 48.853524, lng: 20.660761 },
    { lat: 48.853334, lng: 20.660791 },
    { lat: 48.853186, lng: 20.660877 },
    { lat: 48.853087, lng: 20.660929 },
    { lat: 48.852819, lng: 20.660995 },
    { lat: 48.852693, lng: 20.660868 },
    { lat: 48.852319, lng: 20.66048 },
    { lat: 48.852058, lng: 20.660212 },
    { lat: 48.851873, lng: 20.659721 },
    { lat: 48.85168, lng: 20.659219 },
    { lat: 48.851405, lng: 20.659858 },
    { lat: 48.851097, lng: 20.660575 },
    { lat: 48.850959, lng: 20.660895 },
    { lat: 48.850854, lng: 20.661156 },
    { lat: 48.850641, lng: 20.661518 },
    { lat: 48.850197, lng: 20.662196 },
    { lat: 48.850091, lng: 20.662399 },
    { lat: 48.849873, lng: 20.663018 },
    { lat: 48.849826, lng: 20.663198 },
    { lat: 48.849663, lng: 20.663496 },
    { lat: 48.84915, lng: 20.664363 },
    { lat: 48.848837, lng: 20.665147 },
    { lat: 48.848321, lng: 20.665729 },
    { lat: 48.848026, lng: 20.666047 },
    { lat: 48.847575, lng: 20.666684 },
    { lat: 48.847041, lng: 20.667431 },
    { lat: 48.846948, lng: 20.667588 },
    { lat: 48.846623, lng: 20.668129 },
    { lat: 48.846191, lng: 20.668612 },
    { lat: 48.846199, lng: 20.668643 },
    { lat: 48.846202, lng: 20.668668 },
    { lat: 48.846867, lng: 20.668316 },
    { lat: 48.847321, lng: 20.668078 },
    { lat: 48.848077, lng: 20.667666 },
    { lat: 48.848694, lng: 20.667328 },
    { lat: 48.849199, lng: 20.667053 },
    { lat: 48.849891, lng: 20.666679 },
    { lat: 48.850284, lng: 20.666463 },
    { lat: 48.850818, lng: 20.666173 },
    { lat: 48.851514, lng: 20.665792 },
    { lat: 48.852247, lng: 20.665431 },
    { lat: 48.852769, lng: 20.665167 },
    { lat: 48.853354, lng: 20.664877 },
    { lat: 48.854183, lng: 20.664459 },
    { lat: 48.8548, lng: 20.664164 },
    { lat: 48.855207, lng: 20.663962 },
    { lat: 48.855225, lng: 20.66395 },
    { lat: 48.85537, lng: 20.66422 },
    { lat: 48.855483, lng: 20.664395 },
    { lat: 48.855652, lng: 20.664657 },
    { lat: 48.856065, lng: 20.665034 },
    { lat: 48.856398, lng: 20.665318 },
    { lat: 48.856789, lng: 20.665769 },
    { lat: 48.856951, lng: 20.666012 },
    { lat: 48.857185, lng: 20.666437 },
    { lat: 48.857597, lng: 20.666881 },
    { lat: 48.85779, lng: 20.667084 },
    { lat: 48.858125, lng: 20.667331 },
    { lat: 48.858411, lng: 20.667474 },
    { lat: 48.858755, lng: 20.667621 },
    { lat: 48.859124, lng: 20.667902 },
    { lat: 48.859349, lng: 20.668027 },
    { lat: 48.859682, lng: 20.668115 },
    { lat: 48.860049, lng: 20.668119 },
    { lat: 48.860278, lng: 20.668096 },
    { lat: 48.860507, lng: 20.668027 },
    { lat: 48.861156, lng: 20.668167 },
    { lat: 48.861433, lng: 20.66836 },
    { lat: 48.862049, lng: 20.668982 },
    { lat: 48.862355, lng: 20.669423 },
    { lat: 48.86241, lng: 20.669518 },
    { lat: 48.862468, lng: 20.66964 },
    { lat: 48.86261, lng: 20.669983 },
    { lat: 48.862709, lng: 20.670793 },
    { lat: 48.862732, lng: 20.671542 },
    { lat: 48.862718, lng: 20.671715 },
    { lat: 48.862775, lng: 20.672139 },
    { lat: 48.862858, lng: 20.672804 },
    { lat: 48.862929, lng: 20.673489 },
    { lat: 48.862974, lng: 20.67388 },
    { lat: 48.862715, lng: 20.674821 },
    { lat: 48.862724, lng: 20.674829 },
    { lat: 48.862375, lng: 20.676208 },
    { lat: 48.862224, lng: 20.676874 },
    { lat: 48.862027, lng: 20.677748 },
    { lat: 48.862016, lng: 20.678653 },
    { lat: 48.861956, lng: 20.67894 },
    { lat: 48.861892, lng: 20.679971 },
    { lat: 48.861872, lng: 20.679967 },
    { lat: 48.862048, lng: 20.681243 },
    { lat: 48.862215, lng: 20.681919 },
    { lat: 48.862215, lng: 20.681942 },
    { lat: 48.862238, lng: 20.682051 },
    { lat: 48.862358, lng: 20.682885 },
    { lat: 48.862287, lng: 20.683417 },
    { lat: 48.862251, lng: 20.683903 },
    { lat: 48.862268, lng: 20.684503 },
    { lat: 48.862253, lng: 20.684504 },
    { lat: 48.862122, lng: 20.685067 },
    { lat: 48.862132, lng: 20.686173 },
    { lat: 48.862113, lng: 20.686634 },
    { lat: 48.861995, lng: 20.686859 },
    { lat: 48.861866, lng: 20.687105 },
    { lat: 48.861697, lng: 20.687423 },
    { lat: 48.861364, lng: 20.688068 },
    { lat: 48.861339, lng: 20.688076 },
    { lat: 48.860686, lng: 20.688557 },
    { lat: 48.860198, lng: 20.689394 },
    { lat: 48.859812, lng: 20.690056 },
    { lat: 48.85943, lng: 20.690684 },
    { lat: 48.859042, lng: 20.691311 },
    { lat: 48.858876, lng: 20.69158 },
    { lat: 48.8590853, lng: 20.6917812 },
    { lat: 48.8592209, lng: 20.6921452 },
    { lat: 48.8597353, lng: 20.6924845 },
    { lat: 48.8599553, lng: 20.6925344 },
    { lat: 48.8602004, lng: 20.6928396 },
    { lat: 48.8603709, lng: 20.6935175 },
    { lat: 48.8606459, lng: 20.6937756 },
    { lat: 48.8605378, lng: 20.6939321 },
    { lat: 48.8602959, lng: 20.6940023 },
    { lat: 48.8597549, lng: 20.6939523 },
    { lat: 48.8594403, lng: 20.6941618 },
    { lat: 48.8592963, lng: 20.6944761 },
    { lat: 48.8592834, lng: 20.6950606 },
    { lat: 48.8594351, lng: 20.6963163 },
    { lat: 48.8594517, lng: 20.6970011 },
    { lat: 48.8596926, lng: 20.698173 },
    { lat: 48.8598979, lng: 20.6986917 },
    { lat: 48.8604061, lng: 20.6994621 },
    { lat: 48.8606442, lng: 20.6996685 },
    { lat: 48.8609283, lng: 20.7010683 },
    { lat: 48.8610065, lng: 20.7020633 },
    { lat: 48.8610007, lng: 20.7028811 },
    { lat: 48.8611054, lng: 20.7032244 },
    { lat: 48.8613548, lng: 20.7036234 },
    { lat: 48.8616799, lng: 20.7039633 },
    { lat: 48.8621854, lng: 20.7043127 },
    { lat: 48.8625847, lng: 20.7049086 },
    { lat: 48.8630874, lng: 20.7050199 },
    { lat: 48.8638448, lng: 20.7055812 },
    { lat: 48.8640649, lng: 20.7058485 },
    { lat: 48.8643182, lng: 20.7065172 },
    { lat: 48.8650223, lng: 20.7077343 },
    { lat: 48.8654038, lng: 20.7082674 },
    { lat: 48.8659195, lng: 20.7086801 },
    { lat: 48.8666789, lng: 20.7097752 },
    { lat: 48.8672969, lng: 20.7101602 },
    { lat: 48.8676272, lng: 20.7107369 },
    { lat: 48.8679333, lng: 20.711053 },
    { lat: 48.8684665, lng: 20.7114547 },
    { lat: 48.8688095, lng: 20.7115414 },
    { lat: 48.8691308, lng: 20.7117144 },
    { lat: 48.8697621, lng: 20.7122708 },
    { lat: 48.8705646, lng: 20.7131558 },
    { lat: 48.8708484, lng: 20.7133815 },
    { lat: 48.8711555, lng: 20.7138185 },
    { lat: 48.8717164, lng: 20.7148416 },
    { lat: 48.8721379, lng: 20.715271 },
    { lat: 48.8724599, lng: 20.7157751 },
    { lat: 48.8731101, lng: 20.7169087 },
    { lat: 48.8732013, lng: 20.7171934 },
    { lat: 48.8735613, lng: 20.7179168 },
    { lat: 48.873868, lng: 20.7184057 },
    { lat: 48.874277, lng: 20.7188623 },
    { lat: 48.8743289, lng: 20.7187701 },
    { lat: 48.8749591, lng: 20.7186054 },
    { lat: 48.8751312, lng: 20.7186032 },
    { lat: 48.8759325, lng: 20.7189753 },
    { lat: 48.8760307, lng: 20.7188221 },
    { lat: 48.8766824, lng: 20.7189149 },
    { lat: 48.8773116, lng: 20.7187903 },
    { lat: 48.8776735, lng: 20.7186102 },
    { lat: 48.8787104, lng: 20.7176284 },
    { lat: 48.8790892, lng: 20.7169491 },
    { lat: 48.87916, lng: 20.7169769 },
    { lat: 48.8791739, lng: 20.71689 },
    { lat: 48.880029, lng: 20.7172988 },
    { lat: 48.8803063, lng: 20.7174314 },
    { lat: 48.8807923, lng: 20.7179587 },
    { lat: 48.88081, lng: 20.7174147 },
    { lat: 48.8808858, lng: 20.7166309 },
    { lat: 48.8809247, lng: 20.7164194 },
    { lat: 48.8809889, lng: 20.716071 },
    { lat: 48.8810128, lng: 20.7155419 },
    { lat: 48.8809337, lng: 20.7145483 },
    { lat: 48.8812425, lng: 20.7144369 },
    { lat: 48.8812791, lng: 20.7142597 },
    { lat: 48.8811635, lng: 20.7130609 },
    { lat: 48.8810247, lng: 20.7127414 },
    { lat: 48.8815172, lng: 20.7125006 },
    { lat: 48.8818741, lng: 20.7125322 },
    { lat: 48.8818721, lng: 20.7121558 },
    { lat: 48.8815468, lng: 20.7112721 },
    { lat: 48.8815062, lng: 20.710795 },
    { lat: 48.881637, lng: 20.7098208 },
    { lat: 48.8819533, lng: 20.7087387 },
    { lat: 48.8821865, lng: 20.7076069 },
    { lat: 48.882192, lng: 20.7074756 },
    { lat: 48.8821318, lng: 20.7073617 },
    { lat: 48.881769, lng: 20.7071635 },
    { lat: 48.8815936, lng: 20.7066607 },
    { lat: 48.8815365, lng: 20.706356 },
    { lat: 48.8815055, lng: 20.7055519 },
    { lat: 48.8816111, lng: 20.7042149 },
    { lat: 48.8816909, lng: 20.7037327 },
    { lat: 48.8817783, lng: 20.7033728 },
    { lat: 48.881873, lng: 20.703442 },
    { lat: 48.8820115, lng: 20.7029389 },
    { lat: 48.882525, lng: 20.7028174 },
    { lat: 48.8826229, lng: 20.7026574 },
    { lat: 48.8828822, lng: 20.7025386 },
    { lat: 48.8828784, lng: 20.7024008 },
    { lat: 48.88309, lng: 20.7022075 },
    { lat: 48.8837396, lng: 20.701861 },
    { lat: 48.884027, lng: 20.7018103 },
    { lat: 48.8836335, lng: 20.7012856 },
    { lat: 48.8835097, lng: 20.701221 },
    { lat: 48.8833829, lng: 20.7012636 },
    { lat: 48.8831547, lng: 20.701215 },
    { lat: 48.882958, lng: 20.7009385 },
    { lat: 48.8828938, lng: 20.70056 },
    { lat: 48.8829314, lng: 20.7001584 },
    { lat: 48.8828553, lng: 20.7001085 },
    { lat: 48.8828625, lng: 20.6990767 },
    { lat: 48.8827857, lng: 20.6982181 },
    { lat: 48.8828021, lng: 20.6981091 },
    { lat: 48.8830259, lng: 20.6980364 },
    { lat: 48.8830879, lng: 20.6981347 },
    { lat: 48.8831773, lng: 20.6981206 },
    { lat: 48.8832054, lng: 20.6979888 },
    { lat: 48.883301, lng: 20.6980123 },
    { lat: 48.8834972, lng: 20.6980604 },
    { lat: 48.8835991, lng: 20.6979812 },
    { lat: 48.8838614, lng: 20.6981994 },
    { lat: 48.8838588, lng: 20.6985709 },
    { lat: 48.8841003, lng: 20.6984288 },
    { lat: 48.8842188, lng: 20.6983132 },
    { lat: 48.8842592, lng: 20.6981139 },
    { lat: 48.8843194, lng: 20.6981046 },
    { lat: 48.8843785, lng: 20.6978952 },
    { lat: 48.8843337, lng: 20.6978413 },
    { lat: 48.8843428, lng: 20.6973366 },
    { lat: 48.8842802, lng: 20.6971897 },
    { lat: 48.8843278, lng: 20.6967905 },
    { lat: 48.8841941, lng: 20.6961619 },
    { lat: 48.884018, lng: 20.6961856 },
    { lat: 48.8837095, lng: 20.6953603 },
    { lat: 48.8836339, lng: 20.6952896 },
    { lat: 48.8830926, lng: 20.6952989 },
    { lat: 48.8829153, lng: 20.6951911 },
    { lat: 48.8830746, lng: 20.6946966 },
    { lat: 48.883305, lng: 20.6946527 },
    { lat: 48.8834714, lng: 20.6945249 },
    { lat: 48.8835716, lng: 20.6946827 },
    { lat: 48.8836722, lng: 20.6944398 },
    { lat: 48.883657, lng: 20.694332 },
    { lat: 48.8837423, lng: 20.6943207 },
    { lat: 48.883762, lng: 20.6940526 },
    { lat: 48.8839275, lng: 20.6939571 },
    { lat: 48.8840258, lng: 20.6940352 },
    { lat: 48.8839118, lng: 20.693776 },
    { lat: 48.8840049, lng: 20.6936088 },
    { lat: 48.8841626, lng: 20.6935469 },
    { lat: 48.8841992, lng: 20.6934684 },
    { lat: 48.8841699, lng: 20.6933721 },
    { lat: 48.8842623, lng: 20.693279 },
    { lat: 48.8842962, lng: 20.6930902 },
    { lat: 48.8842671, lng: 20.6929356 },
    { lat: 48.8843908, lng: 20.692938 },
    { lat: 48.8849825, lng: 20.6933301 },
    { lat: 48.8850546, lng: 20.6931432 },
    { lat: 48.8851654, lng: 20.6931867 },
    { lat: 48.885265, lng: 20.6929503 },
    { lat: 48.8852444, lng: 20.6927825 },
    { lat: 48.8852883, lng: 20.692566 },
    { lat: 48.8852144, lng: 20.6924841 },
    { lat: 48.8852626, lng: 20.6922548 },
    { lat: 48.8851197, lng: 20.6920973 },
    { lat: 48.8850381, lng: 20.6918947 },
    { lat: 48.8851097, lng: 20.6913756 },
    { lat: 48.8852466, lng: 20.691365 },
    { lat: 48.8852606, lng: 20.6911275 },
    { lat: 48.885024, lng: 20.6911208 },
    { lat: 48.8851074, lng: 20.6908671 },
    { lat: 48.8850885, lng: 20.6901862 },
    { lat: 48.8849783, lng: 20.6896161 },
    { lat: 48.8848548, lng: 20.6895686 },
    { lat: 48.8848307, lng: 20.6894594 },
    { lat: 48.8849114, lng: 20.6894152 },
    { lat: 48.8847047, lng: 20.6893331 },
    { lat: 48.8847856, lng: 20.6889168 },
    { lat: 48.8847173, lng: 20.6879552 },
    { lat: 48.8847362, lng: 20.6875988 },
    { lat: 48.8846315, lng: 20.6871249 },
    { lat: 48.8847357, lng: 20.6860082 },
    { lat: 48.885154, lng: 20.6855267 },
    { lat: 48.8852024, lng: 20.6853642 },
    { lat: 48.8855927, lng: 20.6854513 },
    { lat: 48.8858546, lng: 20.6859413 },
    { lat: 48.8860397, lng: 20.6857691 },
    { lat: 48.8860433, lng: 20.6856192 },
    { lat: 48.8859119, lng: 20.6854401 },
    { lat: 48.8859596, lng: 20.6852881 },
    { lat: 48.8861208, lng: 20.6853306 },
    { lat: 48.886157, lng: 20.6852676 },
    { lat: 48.8861488, lng: 20.6850121 },
    { lat: 48.89532, lng: 20.6825715 },
  ],
  Žehra: [
    { lat: 48.990424, lng: 20.839931 },
    { lat: 48.990444, lng: 20.839938 },
    { lat: 48.990483, lng: 20.839662 },
    { lat: 48.990535, lng: 20.839563 },
    { lat: 48.990608, lng: 20.839414 },
    { lat: 48.990631, lng: 20.839415 },
    { lat: 48.990742, lng: 20.839421 },
    { lat: 48.990835, lng: 20.838989 },
    { lat: 48.99092, lng: 20.838931 },
    { lat: 48.990956, lng: 20.838845 },
    { lat: 48.990799, lng: 20.838628 },
    { lat: 48.990781, lng: 20.838623 },
    { lat: 48.990804, lng: 20.838357 },
    { lat: 48.990859, lng: 20.838278 },
    { lat: 48.991003, lng: 20.838253 },
    { lat: 48.991051, lng: 20.838176 },
    { lat: 48.991039, lng: 20.838074 },
    { lat: 48.990955, lng: 20.837962 },
    { lat: 48.990913, lng: 20.837766 },
    { lat: 48.990993, lng: 20.837489 },
    { lat: 48.991111, lng: 20.837145 },
    { lat: 48.991045, lng: 20.836785 },
    { lat: 48.991106, lng: 20.836311 },
    { lat: 48.991079, lng: 20.836128 },
    { lat: 48.991115, lng: 20.835873 },
    { lat: 48.991122, lng: 20.835645 },
    { lat: 48.991226, lng: 20.835144 },
    { lat: 48.991165, lng: 20.834823 },
    { lat: 48.991095, lng: 20.834641 },
    { lat: 48.991088, lng: 20.834332 },
    { lat: 48.991044, lng: 20.834007 },
    { lat: 48.99105, lng: 20.83369 },
    { lat: 48.991032, lng: 20.833573 },
    { lat: 48.991047, lng: 20.833471 },
    { lat: 48.991007, lng: 20.833397 },
    { lat: 48.991, lng: 20.83337 },
    { lat: 48.991013, lng: 20.833071 },
    { lat: 48.990895, lng: 20.832838 },
    { lat: 48.990849, lng: 20.832784 },
    { lat: 48.990755, lng: 20.832478 },
    { lat: 48.990625, lng: 20.832261 },
    { lat: 48.990249, lng: 20.831757 },
    { lat: 48.99002, lng: 20.831257 },
    { lat: 48.989957, lng: 20.831272 },
    { lat: 48.989893, lng: 20.831153 },
    { lat: 48.989755, lng: 20.831012 },
    { lat: 48.989603, lng: 20.830772 },
    { lat: 48.989436, lng: 20.830669 },
    { lat: 48.98913, lng: 20.830363 },
    { lat: 48.989043, lng: 20.830223 },
    { lat: 48.988876, lng: 20.830037 },
    { lat: 48.988727, lng: 20.829748 },
    { lat: 48.98843, lng: 20.829218 },
    { lat: 48.988325, lng: 20.828963 },
    { lat: 48.988297, lng: 20.828853 },
    { lat: 48.988263, lng: 20.828631 },
    { lat: 48.988249, lng: 20.828589 },
    { lat: 48.988014, lng: 20.828269 },
    { lat: 48.987903, lng: 20.828024 },
    { lat: 48.987817, lng: 20.827783 },
    { lat: 48.987753, lng: 20.827351 },
    { lat: 48.987632, lng: 20.827113 },
    { lat: 48.987661, lng: 20.826948 },
    { lat: 48.987719, lng: 20.826793 },
    { lat: 48.987698, lng: 20.826705 },
    { lat: 48.987277, lng: 20.825097 },
    { lat: 48.986934, lng: 20.82369 },
    { lat: 48.987, lng: 20.823088 },
    { lat: 48.987011, lng: 20.822619 },
    { lat: 48.986989, lng: 20.822278 },
    { lat: 48.986951, lng: 20.821979 },
    { lat: 48.986943, lng: 20.821654 },
    { lat: 48.986959, lng: 20.82131 },
    { lat: 48.986944, lng: 20.821105 },
    { lat: 48.98698, lng: 20.821099 },
    { lat: 48.986866, lng: 20.820698 },
    { lat: 48.986839, lng: 20.820353 },
    { lat: 48.986869, lng: 20.820097 },
    { lat: 48.986864, lng: 20.820098 },
    { lat: 48.986901, lng: 20.819741 },
    { lat: 48.986886, lng: 20.819512 },
    { lat: 48.98696, lng: 20.818886 },
    { lat: 48.986908, lng: 20.818711 },
    { lat: 48.986804, lng: 20.818595 },
    { lat: 48.986775, lng: 20.81844 },
    { lat: 48.986814, lng: 20.818173 },
    { lat: 48.986769, lng: 20.817974 },
    { lat: 48.986801, lng: 20.81784 },
    { lat: 48.986911, lng: 20.817652 },
    { lat: 48.986919, lng: 20.817598 },
    { lat: 48.986898, lng: 20.817535 },
    { lat: 48.986868, lng: 20.817435 },
    { lat: 48.986864, lng: 20.817363 },
    { lat: 48.986894, lng: 20.817257 },
    { lat: 48.987115, lng: 20.81696 },
    { lat: 48.98714, lng: 20.816853 },
    { lat: 48.987005, lng: 20.816335 },
    { lat: 48.987013, lng: 20.816189 },
    { lat: 48.986979, lng: 20.816138 },
    { lat: 48.986806, lng: 20.81605 },
    { lat: 48.98682, lng: 20.81594 },
    { lat: 48.986873, lng: 20.815774 },
    { lat: 48.986911, lng: 20.815379 },
    { lat: 48.986922, lng: 20.815274 },
    { lat: 48.98694, lng: 20.815175 },
    { lat: 48.98698, lng: 20.815054 },
    { lat: 48.987043, lng: 20.814927 },
    { lat: 48.987056, lng: 20.814949 },
    { lat: 48.987523, lng: 20.815845 },
    { lat: 48.987552, lng: 20.815756 },
    { lat: 48.987716, lng: 20.813866 },
    { lat: 48.98774, lng: 20.813083 },
    { lat: 48.987804, lng: 20.812612 },
    { lat: 48.988069, lng: 20.811638 },
    { lat: 48.98832, lng: 20.810941 },
    { lat: 48.988411, lng: 20.81067 },
    { lat: 48.988531, lng: 20.810278 },
    { lat: 48.988697, lng: 20.809722 },
    { lat: 48.988765, lng: 20.809198 },
    { lat: 48.988819, lng: 20.808646 },
    { lat: 48.988811, lng: 20.808104 },
    { lat: 48.988819, lng: 20.807576 },
    { lat: 48.988851, lng: 20.80709 },
    { lat: 48.988939, lng: 20.806641 },
    { lat: 48.989011, lng: 20.806441 },
    { lat: 48.989074, lng: 20.806249 },
    { lat: 48.989195, lng: 20.805837 },
    { lat: 48.989292, lng: 20.805453 },
    { lat: 48.989416, lng: 20.805066 },
    { lat: 48.989662, lng: 20.804093 },
    { lat: 48.989844, lng: 20.803419 },
    { lat: 48.990041, lng: 20.802796 },
    { lat: 48.990241, lng: 20.802144 },
    { lat: 48.9904, lng: 20.801558 },
    { lat: 48.990504, lng: 20.801212 },
    { lat: 48.990682, lng: 20.800678 },
    { lat: 48.990778, lng: 20.800384 },
    { lat: 48.990907, lng: 20.799947 },
    { lat: 48.990941, lng: 20.799811 },
    { lat: 48.991078, lng: 20.799224 },
    { lat: 48.991087, lng: 20.799054 },
    { lat: 48.991085, lng: 20.798896 },
    { lat: 48.991044, lng: 20.798625 },
    { lat: 48.991364, lng: 20.798754 },
    { lat: 48.991472, lng: 20.798773 },
    { lat: 48.991544, lng: 20.798794 },
    { lat: 48.991647, lng: 20.798782 },
    { lat: 48.991667, lng: 20.798766 },
    { lat: 48.991731, lng: 20.798896 },
    { lat: 48.991797, lng: 20.798942 },
    { lat: 48.991839, lng: 20.799003 },
    { lat: 48.99189, lng: 20.799127 },
    { lat: 48.99196, lng: 20.799175 },
    { lat: 48.992077, lng: 20.799181 },
    { lat: 48.992174, lng: 20.799248 },
    { lat: 48.992397, lng: 20.799302 },
    { lat: 48.992568, lng: 20.799285 },
    { lat: 48.992583, lng: 20.79927 },
    { lat: 48.992617, lng: 20.799287 },
    { lat: 48.992662, lng: 20.799345 },
    { lat: 48.992825, lng: 20.7994 },
    { lat: 48.992931, lng: 20.79939 },
    { lat: 48.993071, lng: 20.799492 },
    { lat: 48.993131, lng: 20.799488 },
    { lat: 48.993189, lng: 20.799513 },
    { lat: 48.993211, lng: 20.799551 },
    { lat: 48.993231, lng: 20.799704 },
    { lat: 48.993332, lng: 20.799803 },
    { lat: 48.99347, lng: 20.799843 },
    { lat: 48.993459, lng: 20.799799 },
    { lat: 48.993509, lng: 20.799673 },
    { lat: 48.993535, lng: 20.799576 },
    { lat: 48.993596, lng: 20.79934 },
    { lat: 48.993661, lng: 20.799097 },
    { lat: 48.993764, lng: 20.798834 },
    { lat: 48.993774, lng: 20.798812 },
    { lat: 48.994096, lng: 20.798026 },
    { lat: 48.994175, lng: 20.797807 },
    { lat: 48.994342, lng: 20.797335 },
    { lat: 48.99456, lng: 20.796787 },
    { lat: 48.99475, lng: 20.796276 },
    { lat: 48.994848, lng: 20.795947 },
    { lat: 48.994986, lng: 20.795586 },
    { lat: 48.995207, lng: 20.795131 },
    { lat: 48.995446, lng: 20.794604 },
    { lat: 48.995581, lng: 20.794307 },
    { lat: 48.99584, lng: 20.793765 },
    { lat: 48.996039, lng: 20.79334 },
    { lat: 48.996339, lng: 20.792647 },
    { lat: 48.996649, lng: 20.791912 },
    { lat: 48.996823, lng: 20.791425 },
    { lat: 48.996932, lng: 20.791095 },
    { lat: 48.996946, lng: 20.791055 },
    { lat: 48.997117, lng: 20.790675 },
    { lat: 48.997483, lng: 20.789377 },
    { lat: 48.997532, lng: 20.789405 },
    { lat: 48.997635, lng: 20.789515 },
    { lat: 48.997773, lng: 20.789603 },
    { lat: 48.997839, lng: 20.789611 },
    { lat: 48.997931, lng: 20.789549 },
    { lat: 48.998059, lng: 20.789586 },
    { lat: 48.998338, lng: 20.789773 },
    { lat: 48.998524, lng: 20.789778 },
    { lat: 48.998889, lng: 20.78865 },
    { lat: 48.999137, lng: 20.7879 },
    { lat: 48.999465, lng: 20.783716 },
    { lat: 48.998713, lng: 20.779533 },
    { lat: 48.998663, lng: 20.77923 },
    { lat: 48.998674, lng: 20.779197 },
    { lat: 48.998698, lng: 20.779125 },
    { lat: 48.998707, lng: 20.779101 },
    { lat: 48.998721, lng: 20.779059 },
    { lat: 48.998678, lng: 20.77885 },
    { lat: 48.99867, lng: 20.778427 },
    { lat: 48.998695, lng: 20.778001 },
    { lat: 48.99883, lng: 20.77655 },
    { lat: 48.998104, lng: 20.776465 },
    { lat: 48.997715, lng: 20.776493 },
    { lat: 48.997438, lng: 20.776474 },
    { lat: 48.997111, lng: 20.776431 },
    { lat: 48.996376, lng: 20.776292 },
    { lat: 48.995538, lng: 20.776067 },
    { lat: 48.995271, lng: 20.776007 },
    { lat: 48.994954, lng: 20.775913 },
    { lat: 48.994434, lng: 20.775806 },
    { lat: 48.993589, lng: 20.775583 },
    { lat: 48.992819, lng: 20.775403 },
    { lat: 48.992762, lng: 20.775394 },
    { lat: 48.992342, lng: 20.77533 },
    { lat: 48.991692, lng: 20.775246 },
    { lat: 48.991749, lng: 20.774442 },
    { lat: 48.991801, lng: 20.773045 },
    { lat: 48.991809, lng: 20.772886 },
    { lat: 48.991947, lng: 20.772947 },
    { lat: 48.992385, lng: 20.773013 },
    { lat: 48.992428, lng: 20.773076 },
    { lat: 48.992565, lng: 20.773097 },
    { lat: 48.992574, lng: 20.773205 },
    { lat: 48.992649, lng: 20.773222 },
    { lat: 48.992752, lng: 20.773106 },
    { lat: 48.993062, lng: 20.773192 },
    { lat: 48.993311, lng: 20.773185 },
    { lat: 48.993587, lng: 20.773239 },
    { lat: 48.99394, lng: 20.773347 },
    { lat: 48.994717, lng: 20.773529 },
    { lat: 48.994751, lng: 20.773576 },
    { lat: 48.994823, lng: 20.773675 },
    { lat: 48.994921, lng: 20.773766 },
    { lat: 48.995145, lng: 20.773889 },
    { lat: 48.995285, lng: 20.773654 },
    { lat: 48.996914, lng: 20.774036 },
    { lat: 48.997417, lng: 20.774178 },
    { lat: 48.99776, lng: 20.774313 },
    { lat: 48.99892, lng: 20.773313 },
    { lat: 48.998963, lng: 20.773277 },
    { lat: 48.999026, lng: 20.773222 },
    { lat: 48.999067, lng: 20.773242 },
    { lat: 48.999096, lng: 20.773257 },
    { lat: 48.999102, lng: 20.773259 },
    { lat: 48.99922, lng: 20.772644 },
    { lat: 48.999327, lng: 20.772263 },
    { lat: 48.999348, lng: 20.771901 },
    { lat: 48.999324, lng: 20.771626 },
    { lat: 48.999289, lng: 20.771469 },
    { lat: 48.999315, lng: 20.771427 },
    { lat: 48.999489, lng: 20.771426 },
    { lat: 48.999621, lng: 20.771468 },
    { lat: 48.999825, lng: 20.771597 },
    { lat: 48.999878, lng: 20.771546 },
    { lat: 48.999976, lng: 20.771545 },
    { lat: 48.999919, lng: 20.771826 },
    { lat: 48.999915, lng: 20.772023 },
    { lat: 49.000011, lng: 20.772004 },
    { lat: 49.000146, lng: 20.771923 },
    { lat: 49.000255, lng: 20.771885 },
    { lat: 49.000309, lng: 20.771888 },
    { lat: 49.000427, lng: 20.771896 },
    { lat: 49.000628, lng: 20.771887 },
    { lat: 49.000758, lng: 20.77179 },
    { lat: 49.000879, lng: 20.771762 },
    { lat: 49.000974, lng: 20.771659 },
    { lat: 49.00108, lng: 20.771717 },
    { lat: 49.001361, lng: 20.771652 },
    { lat: 49.001574, lng: 20.771608 },
    { lat: 49.001674, lng: 20.771543 },
    { lat: 49.001647, lng: 20.771476 },
    { lat: 49.00156, lng: 20.771418 },
    { lat: 49.001472, lng: 20.771307 },
    { lat: 49.001461, lng: 20.771258 },
    { lat: 49.001689, lng: 20.771065 },
    { lat: 49.001825, lng: 20.771034 },
    { lat: 49.001847, lng: 20.770882 },
    { lat: 49.00208, lng: 20.770832 },
    { lat: 49.002235, lng: 20.770604 },
    { lat: 49.002326, lng: 20.770385 },
    { lat: 49.002422, lng: 20.770116 },
    { lat: 49.0024893, lng: 20.7698569 },
    { lat: 49.002503, lng: 20.769804 },
    { lat: 49.002583, lng: 20.769468 },
    { lat: 49.00258, lng: 20.769325 },
    { lat: 49.002565, lng: 20.769109 },
    { lat: 49.00303, lng: 20.769282 },
    { lat: 49.002966, lng: 20.768911 },
    { lat: 49.002849, lng: 20.768474 },
    { lat: 49.002752, lng: 20.768201 },
    { lat: 49.002746, lng: 20.768058 },
    { lat: 49.002796, lng: 20.767976 },
    { lat: 49.002756, lng: 20.767596 },
    { lat: 49.002632, lng: 20.767654 },
    { lat: 49.002583, lng: 20.76749 },
    { lat: 49.002398, lng: 20.767485 },
    { lat: 49.002243, lng: 20.767035 },
    { lat: 49.002153, lng: 20.76641 },
    { lat: 49.002129, lng: 20.766245 },
    { lat: 49.002105, lng: 20.766014 },
    { lat: 49.001624, lng: 20.76585 },
    { lat: 49.001358, lng: 20.765718 },
    { lat: 49.000205, lng: 20.765422 },
    { lat: 48.999286, lng: 20.765012 },
    { lat: 48.999105, lng: 20.764979 },
    { lat: 48.999037, lng: 20.764829 },
    { lat: 48.999028, lng: 20.764668 },
    { lat: 48.998967, lng: 20.764538 },
    { lat: 48.99897, lng: 20.764451 },
    { lat: 48.998934, lng: 20.764353 },
    { lat: 48.998919, lng: 20.764258 },
    { lat: 48.998847, lng: 20.764167 },
    { lat: 48.998448, lng: 20.764019 },
    { lat: 48.99841, lng: 20.763977 },
    { lat: 48.998165, lng: 20.764151 },
    { lat: 48.997857, lng: 20.76427 },
    { lat: 48.997909, lng: 20.764399 },
    { lat: 48.997819, lng: 20.764511 },
    { lat: 48.997751, lng: 20.764532 },
    { lat: 48.997628, lng: 20.76452 },
    { lat: 48.997592, lng: 20.764516 },
    { lat: 48.997262, lng: 20.764633 },
    { lat: 48.997211, lng: 20.764794 },
    { lat: 48.997228, lng: 20.764877 },
    { lat: 48.997042, lng: 20.765279 },
    { lat: 48.99697, lng: 20.765583 },
    { lat: 48.99697, lng: 20.765831 },
    { lat: 48.997105, lng: 20.766561 },
    { lat: 48.997123, lng: 20.766837 },
    { lat: 48.99709, lng: 20.766908 },
    { lat: 48.9971289, lng: 20.7672132 },
    { lat: 48.997242, lng: 20.767187 },
    { lat: 48.997354, lng: 20.767422 },
    { lat: 48.997438, lng: 20.767679 },
    { lat: 48.997567, lng: 20.767934 },
    { lat: 48.997714, lng: 20.768292 },
    { lat: 48.997878, lng: 20.768648 },
    { lat: 48.998034, lng: 20.76918 },
    { lat: 48.998039, lng: 20.769221 },
    { lat: 48.998065, lng: 20.769451 },
    { lat: 48.99797, lng: 20.769576 },
    { lat: 48.997989, lng: 20.769614 },
    { lat: 48.998332, lng: 20.769961 },
    { lat: 48.998485, lng: 20.770023 },
    { lat: 48.998473, lng: 20.770122 },
    { lat: 48.997911, lng: 20.770487 },
    { lat: 48.997284, lng: 20.770636 },
    { lat: 48.997136, lng: 20.770272 },
    { lat: 48.996691, lng: 20.769954 },
    { lat: 48.99661, lng: 20.77003 },
    { lat: 48.996567, lng: 20.770114 },
    { lat: 48.996115, lng: 20.769734 },
    { lat: 48.995818, lng: 20.769513 },
    { lat: 48.9958, lng: 20.769261 },
    { lat: 48.995301, lng: 20.769021 },
    { lat: 48.995353, lng: 20.768753 },
    { lat: 48.995375, lng: 20.768606 },
    { lat: 48.99493, lng: 20.768449 },
    { lat: 48.994912, lng: 20.768339 },
    { lat: 48.99469, lng: 20.768274 },
    { lat: 48.99463, lng: 20.768184 },
    { lat: 48.99462, lng: 20.768123 },
    { lat: 48.994448, lng: 20.767968 },
    { lat: 48.994791, lng: 20.768989 },
    { lat: 48.995089, lng: 20.769367 },
    { lat: 48.994142, lng: 20.770111 },
    { lat: 48.992733, lng: 20.771268 },
    { lat: 48.992599, lng: 20.771478 },
    { lat: 48.993072, lng: 20.771893 },
    { lat: 48.992849, lng: 20.772036 },
    { lat: 48.991956, lng: 20.772254 },
    { lat: 48.991595, lng: 20.77227 },
    { lat: 48.991619, lng: 20.772659 },
    { lat: 48.99152, lng: 20.7727 },
    { lat: 48.990546, lng: 20.773107 },
    { lat: 48.98847, lng: 20.774031 },
    { lat: 48.987042, lng: 20.774666 },
    { lat: 48.984277, lng: 20.772488 },
    { lat: 48.982067, lng: 20.771714 },
    { lat: 48.981241, lng: 20.771425 },
    { lat: 48.980138, lng: 20.771405 },
    { lat: 48.979101, lng: 20.771387 },
    { lat: 48.978904, lng: 20.771418 },
    { lat: 48.978748, lng: 20.771401 },
    { lat: 48.977947, lng: 20.771359 },
    { lat: 48.977902, lng: 20.771355 },
    { lat: 48.977875, lng: 20.771354 },
    { lat: 48.977839, lng: 20.771351 },
    { lat: 48.977767, lng: 20.771346 },
    { lat: 48.976232, lng: 20.771269 },
    { lat: 48.975966, lng: 20.77122 },
    { lat: 48.975805, lng: 20.77119 },
    { lat: 48.975785, lng: 20.771189 },
    { lat: 48.97571, lng: 20.771185 },
    { lat: 48.975673, lng: 20.771185 },
    { lat: 48.975658, lng: 20.771183 },
    { lat: 48.975549, lng: 20.771177 },
    { lat: 48.975025, lng: 20.773629 },
    { lat: 48.975005, lng: 20.773691 },
    { lat: 48.974977, lng: 20.773787 },
    { lat: 48.974749, lng: 20.774545 },
    { lat: 48.974712, lng: 20.774669 },
    { lat: 48.974611, lng: 20.775004 },
    { lat: 48.974581, lng: 20.775103 },
    { lat: 48.974258, lng: 20.776179 },
    { lat: 48.974185, lng: 20.776422 },
    { lat: 48.973655, lng: 20.778086 },
    { lat: 48.973659, lng: 20.778241 },
    { lat: 48.97363, lng: 20.778381 },
    { lat: 48.973412, lng: 20.778876 },
    { lat: 48.973408, lng: 20.778911 },
    { lat: 48.973265, lng: 20.779337 },
    { lat: 48.972813, lng: 20.779875 },
    { lat: 48.972718, lng: 20.779989 },
    { lat: 48.972502, lng: 20.780246 },
    { lat: 48.972434, lng: 20.780327 },
    { lat: 48.972423, lng: 20.780341 },
    { lat: 48.972366, lng: 20.780408 },
    { lat: 48.972341, lng: 20.780437 },
    { lat: 48.972099, lng: 20.780727 },
    { lat: 48.972086, lng: 20.780742 },
    { lat: 48.971954, lng: 20.780898 },
    { lat: 48.971932, lng: 20.780924 },
    { lat: 48.971841, lng: 20.781034 },
    { lat: 48.971819, lng: 20.781059 },
    { lat: 48.971794, lng: 20.78109 },
    { lat: 48.971665, lng: 20.781242 },
    { lat: 48.971593, lng: 20.781328 },
    { lat: 48.97155, lng: 20.78138 },
    { lat: 48.971522, lng: 20.781414 },
    { lat: 48.971513, lng: 20.781424 },
    { lat: 48.971419, lng: 20.781535 },
    { lat: 48.971341, lng: 20.781628 },
    { lat: 48.971285, lng: 20.781615 },
    { lat: 48.971288, lng: 20.78158 },
    { lat: 48.971295, lng: 20.7814 },
    { lat: 48.971287, lng: 20.781367 },
    { lat: 48.97119, lng: 20.780977 },
    { lat: 48.971107, lng: 20.780812 },
    { lat: 48.9710517, lng: 20.7809138 },
    { lat: 48.9710409, lng: 20.7813141 },
    { lat: 48.9709247, lng: 20.7817559 },
    { lat: 48.9709087, lng: 20.7820754 },
    { lat: 48.9707045, lng: 20.7828986 },
    { lat: 48.9706242, lng: 20.7828872 },
    { lat: 48.9701383, lng: 20.7831598 },
    { lat: 48.9703291, lng: 20.7838822 },
    { lat: 48.9702319, lng: 20.7841007 },
    { lat: 48.9702211, lng: 20.7842778 },
    { lat: 48.9702906, lng: 20.7844915 },
    { lat: 48.9702202, lng: 20.7849615 },
    { lat: 48.9702707, lng: 20.7852129 },
    { lat: 48.9701807, lng: 20.7854753 },
    { lat: 48.9702026, lng: 20.7855541 },
    { lat: 48.9696107, lng: 20.7864669 },
    { lat: 48.9695152, lng: 20.7866996 },
    { lat: 48.9693354, lng: 20.7875796 },
    { lat: 48.9694005, lng: 20.7888688 },
    { lat: 48.9694705, lng: 20.7889399 },
    { lat: 48.9695613, lng: 20.7892851 },
    { lat: 48.9695592, lng: 20.7899055 },
    { lat: 48.9696406, lng: 20.7905288 },
    { lat: 48.9698796, lng: 20.7917049 },
    { lat: 48.9701088, lng: 20.7938681 },
    { lat: 48.9704134, lng: 20.7948906 },
    { lat: 48.9703644, lng: 20.7955509 },
    { lat: 48.9698385, lng: 20.7968796 },
    { lat: 48.9697303, lng: 20.7975557 },
    { lat: 48.9695915, lng: 20.7980624 },
    { lat: 48.9695405, lng: 20.7985426 },
    { lat: 48.9693126, lng: 20.7988822 },
    { lat: 48.9691005, lng: 20.799423 },
    { lat: 48.9689445, lng: 20.7995637 },
    { lat: 48.9687197, lng: 20.800027 },
    { lat: 48.9684811, lng: 20.8007455 },
    { lat: 48.9684183, lng: 20.8012044 },
    { lat: 48.9682361, lng: 20.8015647 },
    { lat: 48.9677787, lng: 20.8021738 },
    { lat: 48.9668057, lng: 20.8044338 },
    { lat: 48.9659425, lng: 20.8059031 },
    { lat: 48.9662118, lng: 20.8063638 },
    { lat: 48.9658986, lng: 20.8068431 },
    { lat: 48.964656, lng: 20.8091874 },
    { lat: 48.9644684, lng: 20.8097491 },
    { lat: 48.9642287, lng: 20.8108952 },
    { lat: 48.9642227, lng: 20.8113084 },
    { lat: 48.9641346, lng: 20.8119191 },
    { lat: 48.9636645, lng: 20.8132007 },
    { lat: 48.9665821, lng: 20.8139808 },
    { lat: 48.9671755, lng: 20.8137877 },
    { lat: 48.9680472, lng: 20.8138049 },
    { lat: 48.9693427, lng: 20.8142214 },
    { lat: 48.9696701, lng: 20.8133082 },
    { lat: 48.9697659, lng: 20.8133712 },
    { lat: 48.9699849, lng: 20.8128335 },
    { lat: 48.9708277, lng: 20.8131894 },
    { lat: 48.9711952, lng: 20.8132371 },
    { lat: 48.9717773, lng: 20.811285 },
    { lat: 48.9725455, lng: 20.8113029 },
    { lat: 48.9736098, lng: 20.8110248 },
    { lat: 48.9745092, lng: 20.8112218 },
    { lat: 48.974883, lng: 20.8115123 },
    { lat: 48.9751607, lng: 20.8119333 },
    { lat: 48.9753185, lng: 20.8123554 },
    { lat: 48.9753486, lng: 20.8127414 },
    { lat: 48.97529, lng: 20.8135243 },
    { lat: 48.975318, lng: 20.8137117 },
    { lat: 48.9754893, lng: 20.814143 },
    { lat: 48.9755338, lng: 20.8145867 },
    { lat: 48.9753174, lng: 20.815552 },
    { lat: 48.9752766, lng: 20.8164536 },
    { lat: 48.9753266, lng: 20.8167613 },
    { lat: 48.9754972, lng: 20.8170078 },
    { lat: 48.975712, lng: 20.8171971 },
    { lat: 48.9758852, lng: 20.8172607 },
    { lat: 48.9769897, lng: 20.8172028 },
    { lat: 48.9771271, lng: 20.8178204 },
    { lat: 48.9771, lng: 20.8182117 },
    { lat: 48.976897, lng: 20.8192118 },
    { lat: 48.9769077, lng: 20.8197825 },
    { lat: 48.9769659, lng: 20.8199394 },
    { lat: 48.977407, lng: 20.820511 },
    { lat: 48.97742, lng: 20.820498 },
    { lat: 48.977432, lng: 20.820486 },
    { lat: 48.977509, lng: 20.820409 },
    { lat: 48.977213, lng: 20.820042 },
    { lat: 48.977237, lng: 20.819946 },
    { lat: 48.97877, lng: 20.817426 },
    { lat: 48.979112, lng: 20.818476 },
    { lat: 48.979958, lng: 20.818044 },
    { lat: 48.98061, lng: 20.817728 },
    { lat: 48.980956, lng: 20.818504 },
    { lat: 48.981339, lng: 20.819768 },
    { lat: 48.981796, lng: 20.821277 },
    { lat: 48.982199, lng: 20.821639 },
    { lat: 48.98278, lng: 20.822204 },
    { lat: 48.983385, lng: 20.823264 },
    { lat: 48.983734, lng: 20.824772 },
    { lat: 48.983923, lng: 20.824729 },
    { lat: 48.984167, lng: 20.826187 },
    { lat: 48.984174, lng: 20.826747 },
    { lat: 48.985192, lng: 20.82814 },
    { lat: 48.985602, lng: 20.828731 },
    { lat: 48.985711, lng: 20.831363 },
    { lat: 48.985943, lng: 20.830866 },
    { lat: 48.986102, lng: 20.831493 },
    { lat: 48.98593, lng: 20.832591 },
    { lat: 48.986223, lng: 20.833198 },
    { lat: 48.987324, lng: 20.832013 },
    { lat: 48.988005, lng: 20.832605 },
    { lat: 48.988623, lng: 20.833634 },
    { lat: 48.988898, lng: 20.834426 },
    { lat: 48.989148, lng: 20.835039 },
    { lat: 48.989195, lng: 20.835971 },
    { lat: 48.989262, lng: 20.836822 },
    { lat: 48.989158, lng: 20.837548 },
    { lat: 48.989301, lng: 20.83787 },
    { lat: 48.989415, lng: 20.838278 },
    { lat: 48.989486, lng: 20.838513 },
    { lat: 48.989529, lng: 20.838674 },
    { lat: 48.989678, lng: 20.83918 },
    { lat: 48.99007, lng: 20.840052 },
    { lat: 48.990424, lng: 20.839931 },
  ],
  Arnutovce: [
    { lat: 48.970676, lng: 20.492295 },
    { lat: 48.9705441, lng: 20.4930423 },
    { lat: 48.9705512, lng: 20.4932588 },
    { lat: 48.9710401, lng: 20.4935679 },
    { lat: 48.971164, lng: 20.4935709 },
    { lat: 48.9710941, lng: 20.4938962 },
    { lat: 48.9713436, lng: 20.4940969 },
    { lat: 48.9719516, lng: 20.4944004 },
    { lat: 48.9722396, lng: 20.4946376 },
    { lat: 48.9723179, lng: 20.4952261 },
    { lat: 48.97256, lng: 20.4964026 },
    { lat: 48.9728505, lng: 20.4964038 },
    { lat: 48.9739677, lng: 20.4966216 },
    { lat: 48.9740131, lng: 20.4973908 },
    { lat: 48.9742915, lng: 20.4973958 },
    { lat: 48.9743796, lng: 20.4980384 },
    { lat: 48.9747569, lng: 20.4982231 },
    { lat: 48.9756266, lng: 20.4992324 },
    { lat: 48.9756148, lng: 20.4993073 },
    { lat: 48.9761103, lng: 20.4995553 },
    { lat: 48.9767683, lng: 20.5001127 },
    { lat: 48.9769817, lng: 20.5004313 },
    { lat: 48.9770442, lng: 20.5006603 },
    { lat: 48.9776767, lng: 20.5015912 },
    { lat: 48.9779741, lng: 20.5021627 },
    { lat: 48.9784063, lng: 20.5033095 },
    { lat: 48.9788829, lng: 20.5041083 },
    { lat: 48.9792876, lng: 20.5046384 },
    { lat: 48.9795196, lng: 20.5048493 },
    { lat: 48.9801944, lng: 20.5052806 },
    { lat: 48.9800725, lng: 20.507147 },
    { lat: 48.9801195, lng: 20.5079297 },
    { lat: 48.980456, lng: 20.5080775 },
    { lat: 48.9816578, lng: 20.508545 },
    { lat: 48.9817563, lng: 20.5079519 },
    { lat: 48.9821152, lng: 20.5068565 },
    { lat: 48.9824023, lng: 20.5060687 },
    { lat: 48.9829548, lng: 20.5047932 },
    { lat: 48.9837227, lng: 20.5020421 },
    { lat: 48.984365, lng: 20.5023553 },
    { lat: 48.9845647, lng: 20.5025468 },
    { lat: 48.9847283, lng: 20.5025571 },
    { lat: 48.9847989, lng: 20.5025195 },
    { lat: 48.9848857, lng: 20.5023414 },
    { lat: 48.9852097, lng: 20.5014894 },
    { lat: 48.9853763, lng: 20.5013948 },
    { lat: 48.986643, lng: 20.5015777 },
    { lat: 48.987765, lng: 20.5015637 },
    { lat: 48.987937, lng: 20.501626 },
    { lat: 48.988065, lng: 20.501232 },
    { lat: 48.988073, lng: 20.500915 },
    { lat: 48.988019, lng: 20.500372 },
    { lat: 48.987996, lng: 20.499885 },
    { lat: 48.988081, lng: 20.498885 },
    { lat: 48.988194, lng: 20.498502 },
    { lat: 48.987609, lng: 20.497938 },
    { lat: 48.987752, lng: 20.497267 },
    { lat: 48.98793, lng: 20.496748 },
    { lat: 48.988137, lng: 20.496241 },
    { lat: 48.98837, lng: 20.495778 },
    { lat: 48.988126, lng: 20.495476 },
    { lat: 48.987818, lng: 20.495062 },
    { lat: 48.987446, lng: 20.494524 },
    { lat: 48.98669, lng: 20.493244 },
    { lat: 48.986501, lng: 20.492918 },
    { lat: 48.986457, lng: 20.492874 },
    { lat: 48.986174, lng: 20.492203 },
    { lat: 48.986022, lng: 20.491768 },
    { lat: 48.985866, lng: 20.491313 },
    { lat: 48.985782, lng: 20.491096 },
    { lat: 48.985636, lng: 20.4908 },
    { lat: 48.985344, lng: 20.490356 },
    { lat: 48.985096, lng: 20.490035 },
    { lat: 48.984846, lng: 20.489755 },
    { lat: 48.984578, lng: 20.489482 },
    { lat: 48.984612, lng: 20.489415 },
    { lat: 48.98491, lng: 20.488789 },
    { lat: 48.984856, lng: 20.48879 },
    { lat: 48.984884, lng: 20.488712 },
    { lat: 48.98498, lng: 20.488568 },
    { lat: 48.985283, lng: 20.488206 },
    { lat: 48.985533, lng: 20.487896 },
    { lat: 48.985712, lng: 20.487609 },
    { lat: 48.985953, lng: 20.487279 },
    { lat: 48.986112, lng: 20.487035 },
    { lat: 48.986614, lng: 20.486622 },
    { lat: 48.986848, lng: 20.486578 },
    { lat: 48.987115, lng: 20.486409 },
    { lat: 48.98712, lng: 20.486219 },
    { lat: 48.987255, lng: 20.486057 },
    { lat: 48.987485, lng: 20.485708 },
    { lat: 48.987568, lng: 20.485589 },
    { lat: 48.987648, lng: 20.485478 },
    { lat: 48.987731, lng: 20.485375 },
    { lat: 48.98802, lng: 20.485069 },
    { lat: 48.988339, lng: 20.484769 },
    { lat: 48.988504, lng: 20.484626 },
    { lat: 48.98868, lng: 20.484485 },
    { lat: 48.989078, lng: 20.484179 },
    { lat: 48.989487, lng: 20.483815 },
    { lat: 48.989515, lng: 20.483775 },
    { lat: 48.98937, lng: 20.48357 },
    { lat: 48.989233, lng: 20.483371 },
    { lat: 48.989189, lng: 20.483334 },
    { lat: 48.989004, lng: 20.483344 },
    { lat: 48.988567, lng: 20.483358 },
    { lat: 48.988211, lng: 20.483355 },
    { lat: 48.987704, lng: 20.483328 },
    { lat: 48.986944, lng: 20.483302 },
    { lat: 48.986417, lng: 20.483276 },
    { lat: 48.986242, lng: 20.483254 },
    { lat: 48.986003, lng: 20.483176 },
    { lat: 48.985762, lng: 20.483061 },
    { lat: 48.98556, lng: 20.482984 },
    { lat: 48.98512, lng: 20.482838 },
    { lat: 48.984735, lng: 20.482719 },
    { lat: 48.984373, lng: 20.482468 },
    { lat: 48.983995, lng: 20.482063 },
    { lat: 48.98378, lng: 20.481915 },
    { lat: 48.983635, lng: 20.481883 },
    { lat: 48.983525, lng: 20.481876 },
    { lat: 48.982973, lng: 20.481971 },
    { lat: 48.982869, lng: 20.481981 },
    { lat: 48.982642, lng: 20.481969 },
    { lat: 48.982347, lng: 20.481917 },
    { lat: 48.982388, lng: 20.481876 },
    { lat: 48.982286, lng: 20.481756 },
    { lat: 48.982169, lng: 20.481837 },
    { lat: 48.982011, lng: 20.481966 },
    { lat: 48.981956, lng: 20.482012 },
    { lat: 48.9814, lng: 20.482206 },
    { lat: 48.980951, lng: 20.482309 },
    { lat: 48.980798, lng: 20.482343 },
    { lat: 48.980465, lng: 20.482399 },
    { lat: 48.980236, lng: 20.482438 },
    { lat: 48.979646, lng: 20.482598 },
    { lat: 48.979211, lng: 20.482728 },
    { lat: 48.978635, lng: 20.482895 },
    { lat: 48.978463, lng: 20.482941 },
    { lat: 48.978037, lng: 20.483049 },
    { lat: 48.977399, lng: 20.483197 },
    { lat: 48.976936, lng: 20.483312 },
    { lat: 48.97649, lng: 20.483436 },
    { lat: 48.976024, lng: 20.483576 },
    { lat: 48.97529, lng: 20.483817 },
    { lat: 48.97508, lng: 20.483898 },
    { lat: 48.974749, lng: 20.484044 },
    { lat: 48.974101, lng: 20.484358 },
    { lat: 48.974088, lng: 20.484364 },
    { lat: 48.974086, lng: 20.484365 },
    { lat: 48.974059, lng: 20.484384 },
    { lat: 48.974211, lng: 20.485041 },
    { lat: 48.974327, lng: 20.486021 },
    { lat: 48.974439, lng: 20.48693 },
    { lat: 48.974467, lng: 20.487165 },
    { lat: 48.974559, lng: 20.487952 },
    { lat: 48.974656, lng: 20.488596 },
    { lat: 48.974769, lng: 20.489288 },
    { lat: 48.974767, lng: 20.489546 },
    { lat: 48.974704, lng: 20.489866 },
    { lat: 48.974673, lng: 20.489852 },
    { lat: 48.974645, lng: 20.489907 },
    { lat: 48.974642, lng: 20.489913 },
    { lat: 48.974593, lng: 20.489995 },
    { lat: 48.974597, lng: 20.490381 },
    { lat: 48.974574, lng: 20.49046 },
    { lat: 48.974556, lng: 20.490498 },
    { lat: 48.97455, lng: 20.49051 },
    { lat: 48.974537, lng: 20.490539 },
    { lat: 48.974528, lng: 20.490556 },
    { lat: 48.974484, lng: 20.490519 },
    { lat: 48.974434, lng: 20.490525 },
    { lat: 48.974402, lng: 20.490543 },
    { lat: 48.974365, lng: 20.490576 },
    { lat: 48.974344, lng: 20.490587 },
    { lat: 48.974296, lng: 20.490604 },
    { lat: 48.974256, lng: 20.490659 },
    { lat: 48.974147, lng: 20.490714 },
    { lat: 48.974047, lng: 20.490711 },
    { lat: 48.973946, lng: 20.490721 },
    { lat: 48.973812, lng: 20.490787 },
    { lat: 48.97373, lng: 20.490827 },
    { lat: 48.973589, lng: 20.49086 },
    { lat: 48.973426, lng: 20.490896 },
    { lat: 48.973331, lng: 20.490909 },
    { lat: 48.973283, lng: 20.490908 },
    { lat: 48.97323, lng: 20.490912 },
    { lat: 48.973151, lng: 20.490921 },
    { lat: 48.973068, lng: 20.490924 },
    { lat: 48.972922, lng: 20.490937 },
    { lat: 48.97279, lng: 20.490897 },
    { lat: 48.972741, lng: 20.490884 },
    { lat: 48.972676, lng: 20.49084 },
    { lat: 48.972621, lng: 20.490829 },
    { lat: 48.972556, lng: 20.490824 },
    { lat: 48.972435, lng: 20.490762 },
    { lat: 48.972325, lng: 20.490716 },
    { lat: 48.972259, lng: 20.490681 },
    { lat: 48.972174, lng: 20.490622 },
    { lat: 48.972058, lng: 20.49057 },
    { lat: 48.971912, lng: 20.490421 },
    { lat: 48.97179, lng: 20.490282 },
    { lat: 48.971735, lng: 20.490237 },
    { lat: 48.971702, lng: 20.490208 },
    { lat: 48.971655, lng: 20.490184 },
    { lat: 48.971582, lng: 20.4901 },
    { lat: 48.971528, lng: 20.490037 },
    { lat: 48.97145, lng: 20.489995 },
    { lat: 48.97141, lng: 20.489963 },
    { lat: 48.971385, lng: 20.489915 },
    { lat: 48.971311, lng: 20.489849 },
    { lat: 48.971277, lng: 20.489766 },
    { lat: 48.971265, lng: 20.489764 },
    { lat: 48.971264, lng: 20.489763 },
    { lat: 48.970908, lng: 20.489686 },
    { lat: 48.970891, lng: 20.489901 },
    { lat: 48.970889, lng: 20.489934 },
    { lat: 48.970877, lng: 20.490076 },
    { lat: 48.970871, lng: 20.49016 },
    { lat: 48.97082, lng: 20.490828 },
    { lat: 48.970817, lng: 20.490858 },
    { lat: 48.970789, lng: 20.491307 },
    { lat: 48.970676, lng: 20.492295 },
  ],
  Lieskovany: [
    { lat: 48.9323895, lng: 20.6026532 },
    { lat: 48.9319813, lng: 20.6017052 },
    { lat: 48.9313387, lng: 20.5990565 },
    { lat: 48.9309891, lng: 20.5964098 },
    { lat: 48.9304707, lng: 20.5940533 },
    { lat: 48.9300247, lng: 20.5924148 },
    { lat: 48.9299313, lng: 20.5923253 },
    { lat: 48.9294641, lng: 20.5926925 },
    { lat: 48.928146, lng: 20.5931617 },
    { lat: 48.9272232, lng: 20.5932622 },
    { lat: 48.9268665, lng: 20.5933931 },
    { lat: 48.9264932, lng: 20.5936544 },
    { lat: 48.9262825, lng: 20.5940802 },
    { lat: 48.9261812, lng: 20.5944868 },
    { lat: 48.9260413, lng: 20.5955852 },
    { lat: 48.9258024, lng: 20.5965379 },
    { lat: 48.9254526, lng: 20.597593 },
    { lat: 48.9252794, lng: 20.5979426 },
    { lat: 48.9239981, lng: 20.5996627 },
    { lat: 48.9232381, lng: 20.6005266 },
    { lat: 48.9225409, lng: 20.6011403 },
    { lat: 48.9215849, lng: 20.601779 },
    { lat: 48.9210814, lng: 20.6023929 },
    { lat: 48.9202246, lng: 20.6043127 },
    { lat: 48.919882, lng: 20.604888 },
    { lat: 48.919279, lng: 20.6061059 },
    { lat: 48.9186751, lng: 20.6071116 },
    { lat: 48.9233377, lng: 20.6124586 },
    { lat: 48.9229481, lng: 20.6129824 },
    { lat: 48.9228961, lng: 20.6133508 },
    { lat: 48.9229362, lng: 20.6135512 },
    { lat: 48.9232449, lng: 20.6140233 },
    { lat: 48.9260757, lng: 20.6109729 },
    { lat: 48.9286136, lng: 20.6144844 },
    { lat: 48.9313676, lng: 20.621129 },
    { lat: 48.9313756, lng: 20.621162 },
    { lat: 48.932801, lng: 20.6206999 },
    { lat: 48.9337277, lng: 20.6162655 },
    { lat: 48.9336207, lng: 20.6150581 },
    { lat: 48.9335816, lng: 20.611312 },
    { lat: 48.932771, lng: 20.6084294 },
    { lat: 48.9313323, lng: 20.6058086 },
    { lat: 48.9307014, lng: 20.6051121 },
    { lat: 48.9308568, lng: 20.6048051 },
    { lat: 48.9313135, lng: 20.6046347 },
    { lat: 48.9324036, lng: 20.6045243 },
    { lat: 48.9327991, lng: 20.6046892 },
    { lat: 48.9329977, lng: 20.6045458 },
    { lat: 48.9323895, lng: 20.6026532 },
  ],
  Hnilec: [
    { lat: 48.794174, lng: 20.5336 },
    { lat: 48.797505, lng: 20.530766 },
    { lat: 48.798071, lng: 20.530534 },
    { lat: 48.798552, lng: 20.530246 },
    { lat: 48.798843, lng: 20.530175 },
    { lat: 48.799202, lng: 20.530097 },
    { lat: 48.799543, lng: 20.530019 },
    { lat: 48.799889, lng: 20.529888 },
    { lat: 48.800267, lng: 20.529754 },
    { lat: 48.800633, lng: 20.529721 },
    { lat: 48.801011, lng: 20.529621 },
    { lat: 48.801109, lng: 20.529609 },
    { lat: 48.80121, lng: 20.529621 },
    { lat: 48.801398, lng: 20.529626 },
    { lat: 48.801582, lng: 20.529634 },
    { lat: 48.801772, lng: 20.529735 },
    { lat: 48.801934, lng: 20.530158 },
    { lat: 48.802081, lng: 20.530291 },
    { lat: 48.802172, lng: 20.530359 },
    { lat: 48.802293, lng: 20.530469 },
    { lat: 48.802504, lng: 20.530519 },
    { lat: 48.802794, lng: 20.53057 },
    { lat: 48.803112, lng: 20.530512 },
    { lat: 48.803255, lng: 20.53033 },
    { lat: 48.803686, lng: 20.529987 },
    { lat: 48.803868, lng: 20.53004 },
    { lat: 48.804004, lng: 20.530053 },
    { lat: 48.80427, lng: 20.530025 },
    { lat: 48.804374, lng: 20.530021 },
    { lat: 48.80447, lng: 20.53 },
    { lat: 48.804663, lng: 20.529903 },
    { lat: 48.80491, lng: 20.52975 },
    { lat: 48.805267, lng: 20.529796 },
    { lat: 48.805451, lng: 20.529831 },
    { lat: 48.805627, lng: 20.529839 },
    { lat: 48.805836, lng: 20.529874 },
    { lat: 48.806237, lng: 20.52994 },
    { lat: 48.806569, lng: 20.529997 },
    { lat: 48.806913, lng: 20.530102 },
    { lat: 48.807248, lng: 20.5302 },
    { lat: 48.807621, lng: 20.530289 },
    { lat: 48.807939, lng: 20.530568 },
    { lat: 48.808027, lng: 20.530616 },
    { lat: 48.808259, lng: 20.530872 },
    { lat: 48.808522, lng: 20.531163 },
    { lat: 48.808771, lng: 20.531448 },
    { lat: 48.809035, lng: 20.531428 },
    { lat: 48.809599, lng: 20.531359 },
    { lat: 48.809921, lng: 20.53135 },
    { lat: 48.810362, lng: 20.531344 },
    { lat: 48.810895, lng: 20.531122 },
    { lat: 48.811257, lng: 20.530971 },
    { lat: 48.811531, lng: 20.53073 },
    { lat: 48.811713, lng: 20.530653 },
    { lat: 48.81186, lng: 20.530596 },
    { lat: 48.812245, lng: 20.530442 },
    { lat: 48.812413, lng: 20.530369 },
    { lat: 48.812475, lng: 20.53039 },
    { lat: 48.812494, lng: 20.530397 },
    { lat: 48.81257, lng: 20.530424 },
    { lat: 48.81259, lng: 20.530432 },
    { lat: 48.812855, lng: 20.530458 },
    { lat: 48.813003, lng: 20.530299 },
    { lat: 48.813043, lng: 20.530256 },
    { lat: 48.813203, lng: 20.530128 },
    { lat: 48.813321, lng: 20.5301 },
    { lat: 48.813391, lng: 20.530037 },
    { lat: 48.813471, lng: 20.529966 },
    { lat: 48.813627, lng: 20.530205 },
    { lat: 48.813873, lng: 20.530626 },
    { lat: 48.814128, lng: 20.531124 },
    { lat: 48.81421, lng: 20.531277 },
    { lat: 48.814292, lng: 20.531379 },
    { lat: 48.814339, lng: 20.531507 },
    { lat: 48.814364, lng: 20.531631 },
    { lat: 48.814394, lng: 20.531798 },
    { lat: 48.814415, lng: 20.531985 },
    { lat: 48.814462, lng: 20.532871 },
    { lat: 48.814487, lng: 20.53315 },
    { lat: 48.814544, lng: 20.533526 },
    { lat: 48.814647, lng: 20.533977 },
    { lat: 48.814734, lng: 20.534432 },
    { lat: 48.814795, lng: 20.534776 },
    { lat: 48.814838, lng: 20.534903 },
    { lat: 48.814966, lng: 20.535235 },
    { lat: 48.815123, lng: 20.53568 },
    { lat: 48.815389, lng: 20.536231 },
    { lat: 48.815483, lng: 20.536421 },
    { lat: 48.815592, lng: 20.536727 },
    { lat: 48.81578, lng: 20.53736 },
    { lat: 48.815842, lng: 20.537766 },
    { lat: 48.815917, lng: 20.538175 },
    { lat: 48.815991, lng: 20.538469 },
    { lat: 48.816166, lng: 20.539173 },
    { lat: 48.816209, lng: 20.539295 },
    { lat: 48.816244, lng: 20.539385 },
    { lat: 48.81629, lng: 20.539492 },
    { lat: 48.816369, lng: 20.539652 },
    { lat: 48.816426, lng: 20.53978 },
    { lat: 48.81651, lng: 20.539917 },
    { lat: 48.816621, lng: 20.540083 },
    { lat: 48.816743, lng: 20.54034 },
    { lat: 48.816814, lng: 20.540519 },
    { lat: 48.816885, lng: 20.540718 },
    { lat: 48.816902, lng: 20.540836 },
    { lat: 48.816902, lng: 20.541033 },
    { lat: 48.816895, lng: 20.541237 },
    { lat: 48.816881, lng: 20.541386 },
    { lat: 48.816847, lng: 20.54169 },
    { lat: 48.816821, lng: 20.542055 },
    { lat: 48.81679, lng: 20.542405 },
    { lat: 48.816776, lng: 20.542862 },
    { lat: 48.816783, lng: 20.542965 },
    { lat: 48.816794, lng: 20.54306 },
    { lat: 48.816831, lng: 20.543314 },
    { lat: 48.81686, lng: 20.54347 },
    { lat: 48.816941, lng: 20.543577 },
    { lat: 48.81706, lng: 20.543739 },
    { lat: 48.817183, lng: 20.543876 },
    { lat: 48.817301, lng: 20.543988 },
    { lat: 48.817544, lng: 20.544316 },
    { lat: 48.817642, lng: 20.544533 },
    { lat: 48.817707, lng: 20.544734 },
    { lat: 48.817764, lng: 20.545413 },
    { lat: 48.817671, lng: 20.546041 },
    { lat: 48.817748, lng: 20.546134 },
    { lat: 48.817818, lng: 20.546191 },
    { lat: 48.818, lng: 20.546281 },
    { lat: 48.818093, lng: 20.546339 },
    { lat: 48.818255, lng: 20.546476 },
    { lat: 48.818262, lng: 20.546489 },
    { lat: 48.818288, lng: 20.546519 },
    { lat: 48.818298, lng: 20.546533 },
    { lat: 48.818408, lng: 20.546641 },
    { lat: 48.818541, lng: 20.547015 },
    { lat: 48.818616, lng: 20.54733 },
    { lat: 48.818661, lng: 20.547553 },
    { lat: 48.818699, lng: 20.547929 },
    { lat: 48.818718, lng: 20.548192 },
    { lat: 48.818701, lng: 20.548328 },
    { lat: 48.818652, lng: 20.548495 },
    { lat: 48.818543, lng: 20.548798 },
    { lat: 48.818554, lng: 20.549025 },
    { lat: 48.818581, lng: 20.549223 },
    { lat: 48.818644, lng: 20.549461 },
    { lat: 48.818773, lng: 20.550029 },
    { lat: 48.818844, lng: 20.550248 },
    { lat: 48.818978, lng: 20.550571 },
    { lat: 48.819022, lng: 20.550797 },
    { lat: 48.819053, lng: 20.551113 },
    { lat: 48.81904, lng: 20.551372 },
    { lat: 48.818965, lng: 20.551521 },
    { lat: 48.818718, lng: 20.55177 },
    { lat: 48.818434, lng: 20.552008 },
    { lat: 48.818423, lng: 20.55201 },
    { lat: 48.818383, lng: 20.552016 },
    { lat: 48.818248, lng: 20.552026 },
    { lat: 48.818064, lng: 20.552105 },
    { lat: 48.817727, lng: 20.552306 },
    { lat: 48.817458, lng: 20.552473 },
    { lat: 48.817057, lng: 20.552774 },
    { lat: 48.816858, lng: 20.553023 },
    { lat: 48.816724, lng: 20.553146 },
    { lat: 48.816547, lng: 20.553283 },
    { lat: 48.816355, lng: 20.55337 },
    { lat: 48.816129, lng: 20.553457 },
    { lat: 48.815941, lng: 20.553546 },
    { lat: 48.815602, lng: 20.553737 },
    { lat: 48.815212, lng: 20.553964 },
    { lat: 48.815245, lng: 20.554084 },
    { lat: 48.815295, lng: 20.554093 },
    { lat: 48.815339, lng: 20.554101 },
    { lat: 48.815382, lng: 20.55412 },
    { lat: 48.815521, lng: 20.554249 },
    { lat: 48.815758, lng: 20.554247 },
    { lat: 48.81591, lng: 20.554216 },
    { lat: 48.815912, lng: 20.554241 },
    { lat: 48.815918, lng: 20.554322 },
    { lat: 48.816014, lng: 20.554316 },
    { lat: 48.816203, lng: 20.554495 },
    { lat: 48.816334, lng: 20.554521 },
    { lat: 48.816406, lng: 20.554523 },
    { lat: 48.816405, lng: 20.554548 },
    { lat: 48.816745, lng: 20.554805 },
    { lat: 48.816933, lng: 20.55496 },
    { lat: 48.817018, lng: 20.555028 },
    { lat: 48.817114, lng: 20.554817 },
    { lat: 48.817314, lng: 20.55436 },
    { lat: 48.817307, lng: 20.554345 },
    { lat: 48.817613, lng: 20.554101 },
    { lat: 48.817774, lng: 20.553968 },
    { lat: 48.817893, lng: 20.553853 },
    { lat: 48.817901, lng: 20.553873 },
    { lat: 48.8192175, lng: 20.5518479 },
    { lat: 48.8194432, lng: 20.551647 },
    { lat: 48.8200227, lng: 20.551336 },
    { lat: 48.819642, lng: 20.5507191 },
    { lat: 48.8197579, lng: 20.5494366 },
    { lat: 48.819784, lng: 20.5493526 },
    { lat: 48.8205222, lng: 20.5486115 },
    { lat: 48.8201071, lng: 20.548377 },
    { lat: 48.820186, lng: 20.5477686 },
    { lat: 48.8203741, lng: 20.5471328 },
    { lat: 48.8201658, lng: 20.5464934 },
    { lat: 48.8200435, lng: 20.545681 },
    { lat: 48.8194846, lng: 20.5446351 },
    { lat: 48.8195457, lng: 20.5439722 },
    { lat: 48.8197935, lng: 20.5430644 },
    { lat: 48.8199199, lng: 20.5416409 },
    { lat: 48.8204166, lng: 20.540673 },
    { lat: 48.8204793, lng: 20.5403011 },
    { lat: 48.8204852, lng: 20.5398819 },
    { lat: 48.8204587, lng: 20.5395944 },
    { lat: 48.8200563, lng: 20.539157 },
    { lat: 48.8199934, lng: 20.5388525 },
    { lat: 48.8199916, lng: 20.5383468 },
    { lat: 48.819815, lng: 20.5372599 },
    { lat: 48.8195868, lng: 20.536723 },
    { lat: 48.8199846, lng: 20.5360047 },
    { lat: 48.820478, lng: 20.5357076 },
    { lat: 48.8211412, lng: 20.5348305 },
    { lat: 48.8215458, lng: 20.5341405 },
    { lat: 48.8216374, lng: 20.534455 },
    { lat: 48.8293728, lng: 20.5232723 },
    { lat: 48.8307003, lng: 20.5212461 },
    { lat: 48.8311093, lng: 20.5217683 },
    { lat: 48.8312893, lng: 20.5220996 },
    { lat: 48.8313531, lng: 20.5224172 },
    { lat: 48.831562, lng: 20.5230098 },
    { lat: 48.8321788, lng: 20.5237927 },
    { lat: 48.8325736, lng: 20.5241457 },
    { lat: 48.8328755, lng: 20.5243341 },
    { lat: 48.8332567, lng: 20.5248955 },
    { lat: 48.8340478, lng: 20.5253735 },
    { lat: 48.8345349, lng: 20.5254195 },
    { lat: 48.8346904, lng: 20.525511 },
    { lat: 48.8359238, lng: 20.5265877 },
    { lat: 48.8368467, lng: 20.5272793 },
    { lat: 48.8377771, lng: 20.5279234 },
    { lat: 48.8384824, lng: 20.5283333 },
    { lat: 48.8388498, lng: 20.52846 },
    { lat: 48.8393902, lng: 20.5288275 },
    { lat: 48.839917, lng: 20.5290661 },
    { lat: 48.8417702, lng: 20.5294851 },
    { lat: 48.842234, lng: 20.5297008 },
    { lat: 48.8426064, lng: 20.5299962 },
    { lat: 48.8436084, lng: 20.5296903 },
    { lat: 48.844147, lng: 20.529702 },
    { lat: 48.845095, lng: 20.5290911 },
    { lat: 48.8453054, lng: 20.5288483 },
    { lat: 48.8461153, lng: 20.5274621 },
    { lat: 48.8464376, lng: 20.5268001 },
    { lat: 48.8466221, lng: 20.5265541 },
    { lat: 48.8469529, lng: 20.5263379 },
    { lat: 48.8476049, lng: 20.5260685 },
    { lat: 48.8479873, lng: 20.5257228 },
    { lat: 48.8485649, lng: 20.5249925 },
    { lat: 48.8486475, lng: 20.5248228 },
    { lat: 48.8489716, lng: 20.5246672 },
    { lat: 48.8494906, lng: 20.5246977 },
    { lat: 48.8499354, lng: 20.5248774 },
    { lat: 48.850158, lng: 20.5248763 },
    { lat: 48.8504682, lng: 20.5249825 },
    { lat: 48.8508149, lng: 20.5253401 },
    { lat: 48.8512861, lng: 20.5263828 },
    { lat: 48.8516897, lng: 20.5270427 },
    { lat: 48.8520147, lng: 20.527459 },
    { lat: 48.8521529, lng: 20.5275706 },
    { lat: 48.8530509, lng: 20.5279528 },
    { lat: 48.8534039, lng: 20.5279677 },
    { lat: 48.854418, lng: 20.5275533 },
    { lat: 48.8547477, lng: 20.5274056 },
    { lat: 48.8550265, lng: 20.5270618 },
    { lat: 48.8552506, lng: 20.52652 },
    { lat: 48.8552599, lng: 20.5254695 },
    { lat: 48.8554096, lng: 20.5242504 },
    { lat: 48.8553265, lng: 20.5229231 },
    { lat: 48.8556342, lng: 20.521057 },
    { lat: 48.8557645, lng: 20.5206204 },
    { lat: 48.8560938, lng: 20.5199401 },
    { lat: 48.8562356, lng: 20.5194514 },
    { lat: 48.8567884, lng: 20.5171255 },
    { lat: 48.8568548, lng: 20.517 },
    { lat: 48.8568854, lng: 20.5165798 },
    { lat: 48.8571202, lng: 20.5160871 },
    { lat: 48.8572925, lng: 20.5159046 },
    { lat: 48.8575143, lng: 20.5157859 },
    { lat: 48.8575185, lng: 20.5151702 },
    { lat: 48.8574653, lng: 20.5149499 },
    { lat: 48.8574223, lng: 20.5148545 },
    { lat: 48.8572127, lng: 20.5144276 },
    { lat: 48.8571154, lng: 20.5141123 },
    { lat: 48.8570393, lng: 20.5132496 },
    { lat: 48.8571967, lng: 20.5117909 },
    { lat: 48.8573688, lng: 20.5113647 },
    { lat: 48.8577314, lng: 20.5099692 },
    { lat: 48.8577716, lng: 20.5096629 },
    { lat: 48.8577435, lng: 20.508819 },
    { lat: 48.85778, lng: 20.5081676 },
    { lat: 48.8579922, lng: 20.5073027 },
    { lat: 48.8588033, lng: 20.5057125 },
    { lat: 48.8590938, lng: 20.5043864 },
    { lat: 48.8593925, lng: 20.5034018 },
    { lat: 48.8594948, lng: 20.50207 },
    { lat: 48.8597108, lng: 20.5013721 },
    { lat: 48.8597456, lng: 20.5011278 },
    { lat: 48.8596368, lng: 20.5009011 },
    { lat: 48.8588943, lng: 20.5003687 },
    { lat: 48.8587175, lng: 20.5001604 },
    { lat: 48.8584037, lng: 20.4999241 },
    { lat: 48.8580607, lng: 20.499852 },
    { lat: 48.85707, lng: 20.4998834 },
    { lat: 48.8562129, lng: 20.4996623 },
    { lat: 48.8555343, lng: 20.4993708 },
    { lat: 48.8545787, lng: 20.4991689 },
    { lat: 48.8543643, lng: 20.4990064 },
    { lat: 48.8543855, lng: 20.4984858 },
    { lat: 48.8545074, lng: 20.4978904 },
    { lat: 48.8546295, lng: 20.4974198 },
    { lat: 48.8548698, lng: 20.4968371 },
    { lat: 48.8549356, lng: 20.4963945 },
    { lat: 48.8548615, lng: 20.4952271 },
    { lat: 48.8546457, lng: 20.4944855 },
    { lat: 48.8543625, lng: 20.4940171 },
    { lat: 48.8541658, lng: 20.4935774 },
    { lat: 48.8536085, lng: 20.492705 },
    { lat: 48.8533043, lng: 20.4923943 },
    { lat: 48.8531884, lng: 20.4921851 },
    { lat: 48.8531648, lng: 20.4919769 },
    { lat: 48.853241, lng: 20.4917319 },
    { lat: 48.853329, lng: 20.4916425 },
    { lat: 48.8537972, lng: 20.491456 },
    { lat: 48.8539855, lng: 20.4911931 },
    { lat: 48.8541553, lng: 20.4906479 },
    { lat: 48.8544621, lng: 20.4902401 },
    { lat: 48.8549827, lng: 20.4892303 },
    { lat: 48.855084, lng: 20.4887471 },
    { lat: 48.8551465, lng: 20.488072 },
    { lat: 48.8556799, lng: 20.4862616 },
    { lat: 48.8559032, lng: 20.4853385 },
    { lat: 48.855852, lng: 20.4841262 },
    { lat: 48.8557496, lng: 20.4834335 },
    { lat: 48.8554734, lng: 20.4822527 },
    { lat: 48.854778, lng: 20.4806707 },
    { lat: 48.8545518, lng: 20.4804979 },
    { lat: 48.8541294, lng: 20.4803122 },
    { lat: 48.8536733, lng: 20.4802189 },
    { lat: 48.8531652, lng: 20.4800009 },
    { lat: 48.8529043, lng: 20.4798025 },
    { lat: 48.85274, lng: 20.4794155 },
    { lat: 48.8526827, lng: 20.4789399 },
    { lat: 48.8527163, lng: 20.4784522 },
    { lat: 48.8528882, lng: 20.4779658 },
    { lat: 48.8531659, lng: 20.4776193 },
    { lat: 48.8538166, lng: 20.4771982 },
    { lat: 48.8538642, lng: 20.477026 },
    { lat: 48.8538135, lng: 20.4765181 },
    { lat: 48.8531821, lng: 20.4746965 },
    { lat: 48.8528562, lng: 20.4741744 },
    { lat: 48.8520535, lng: 20.47256 },
    { lat: 48.8517991, lng: 20.4722316 },
    { lat: 48.8513871, lng: 20.4718725 },
    { lat: 48.8507818, lng: 20.471737 },
    { lat: 48.8506929, lng: 20.4716446 },
    { lat: 48.8505382, lng: 20.4713072 },
    { lat: 48.8505269, lng: 20.4704988 },
    { lat: 48.8506766, lng: 20.4692109 },
    { lat: 48.8506318, lng: 20.4687357 },
    { lat: 48.8506494, lng: 20.4677664 },
    { lat: 48.8503207, lng: 20.4667265 },
    { lat: 48.850131, lng: 20.4664369 },
    { lat: 48.8500641, lng: 20.4664584 },
    { lat: 48.8498268, lng: 20.4661972 },
    { lat: 48.8497502, lng: 20.4660172 },
    { lat: 48.8497464, lng: 20.4655535 },
    { lat: 48.8464765, lng: 20.4650983 },
    { lat: 48.8462749, lng: 20.4651565 },
    { lat: 48.8463485, lng: 20.4663342 },
    { lat: 48.8462161, lng: 20.4672121 },
    { lat: 48.8462281, lng: 20.4674099 },
    { lat: 48.8463626, lng: 20.4678744 },
    { lat: 48.8463765, lng: 20.4683941 },
    { lat: 48.8462766, lng: 20.4698981 },
    { lat: 48.8464275, lng: 20.4704095 },
    { lat: 48.846659, lng: 20.4717155 },
    { lat: 48.8467746, lng: 20.4720905 },
    { lat: 48.8469312, lng: 20.4730808 },
    { lat: 48.8462587, lng: 20.4730155 },
    { lat: 48.8457138, lng: 20.4728631 },
    { lat: 48.8450389, lng: 20.472497 },
    { lat: 48.8442814, lng: 20.4719593 },
    { lat: 48.8432338, lng: 20.4708726 },
    { lat: 48.8426575, lng: 20.4696216 },
    { lat: 48.8419292, lng: 20.4686074 },
    { lat: 48.8401163, lng: 20.4676442 },
    { lat: 48.8420019, lng: 20.4642612 },
    { lat: 48.8409631, lng: 20.4634917 },
    { lat: 48.8400488, lng: 20.4631333 },
    { lat: 48.8347455, lng: 20.4620011 },
    { lat: 48.832417, lng: 20.460242 },
    { lat: 48.832193, lng: 20.459783 },
    { lat: 48.831615, lng: 20.458595 },
    { lat: 48.831464, lng: 20.458645 },
    { lat: 48.830927, lng: 20.459548 },
    { lat: 48.830855, lng: 20.459985 },
    { lat: 48.830753, lng: 20.460582 },
    { lat: 48.830169, lng: 20.461636 },
    { lat: 48.829554, lng: 20.462713 },
    { lat: 48.828646, lng: 20.462274 },
    { lat: 48.828215, lng: 20.462264 },
    { lat: 48.827754, lng: 20.462716 },
    { lat: 48.827293, lng: 20.462738 },
    { lat: 48.826881, lng: 20.462971 },
    { lat: 48.826631, lng: 20.463042 },
    { lat: 48.825976, lng: 20.462913 },
    { lat: 48.825523, lng: 20.462732 },
    { lat: 48.825119, lng: 20.462938 },
    { lat: 48.824442, lng: 20.462981 },
    { lat: 48.824305, lng: 20.463038 },
    { lat: 48.824017, lng: 20.463156 },
    { lat: 48.823542, lng: 20.463131 },
    { lat: 48.822881, lng: 20.463373 },
    { lat: 48.822581, lng: 20.463275 },
    { lat: 48.821803, lng: 20.463155 },
    { lat: 48.820992, lng: 20.462815 },
    { lat: 48.820447, lng: 20.462968 },
    { lat: 48.819954, lng: 20.462989 },
    { lat: 48.819674, lng: 20.463369 },
    { lat: 48.819422, lng: 20.46383 },
    { lat: 48.818708, lng: 20.465554 },
    { lat: 48.818289, lng: 20.466478 },
    { lat: 48.818253, lng: 20.46656 },
    { lat: 48.818234, lng: 20.466694 },
    { lat: 48.818207, lng: 20.466872 },
    { lat: 48.818139, lng: 20.467101 },
    { lat: 48.818087, lng: 20.467274 },
    { lat: 48.818023, lng: 20.467502 },
    { lat: 48.81796, lng: 20.467708 },
    { lat: 48.817902, lng: 20.467894 },
    { lat: 48.817846, lng: 20.468082 },
    { lat: 48.817827, lng: 20.468145 },
    { lat: 48.817806, lng: 20.468302 },
    { lat: 48.817766, lng: 20.468723 },
    { lat: 48.817828, lng: 20.469077 },
    { lat: 48.817683, lng: 20.470004 },
    { lat: 48.81747, lng: 20.470712 },
    { lat: 48.81709, lng: 20.47135 },
    { lat: 48.816771, lng: 20.472286 },
    { lat: 48.816624, lng: 20.473051 },
    { lat: 48.816321, lng: 20.473534 },
    { lat: 48.816067, lng: 20.474068 },
    { lat: 48.815725, lng: 20.474874 },
    { lat: 48.81556, lng: 20.475264 },
    { lat: 48.815432, lng: 20.475562 },
    { lat: 48.815353, lng: 20.475763 },
    { lat: 48.815223, lng: 20.476176 },
    { lat: 48.815157, lng: 20.476343 },
    { lat: 48.815106, lng: 20.476629 },
    { lat: 48.815089, lng: 20.476898 },
    { lat: 48.815049, lng: 20.477122 },
    { lat: 48.814971, lng: 20.47745 },
    { lat: 48.814811, lng: 20.47777 },
    { lat: 48.814762, lng: 20.477852 },
    { lat: 48.814679, lng: 20.477937 },
    { lat: 48.814565, lng: 20.478019 },
    { lat: 48.814341, lng: 20.478191 },
    { lat: 48.81418, lng: 20.478317 },
    { lat: 48.814052, lng: 20.478416 },
    { lat: 48.813738, lng: 20.478599 },
    { lat: 48.813632, lng: 20.478654 },
    { lat: 48.813528, lng: 20.47871 },
    { lat: 48.813397, lng: 20.4788 },
    { lat: 48.813018, lng: 20.479116 },
    { lat: 48.81294, lng: 20.479433 },
    { lat: 48.812948, lng: 20.479684 },
    { lat: 48.8129222, lng: 20.4799052 },
    { lat: 48.812886, lng: 20.480056 },
    { lat: 48.8128, lng: 20.480339 },
    { lat: 48.812785, lng: 20.480371 },
    { lat: 48.812723, lng: 20.480476 },
    { lat: 48.812698, lng: 20.480522 },
    { lat: 48.812645, lng: 20.480671 },
    { lat: 48.812572, lng: 20.480868 },
    { lat: 48.812597, lng: 20.481383 },
    { lat: 48.812516, lng: 20.481828 },
    { lat: 48.812445, lng: 20.482102 },
    { lat: 48.812393, lng: 20.482295 },
    { lat: 48.812373, lng: 20.482367 },
    { lat: 48.81221, lng: 20.482756 },
    { lat: 48.812201, lng: 20.48278 },
    { lat: 48.812046, lng: 20.482656 },
    { lat: 48.811991, lng: 20.482647 },
    { lat: 48.811903, lng: 20.48261 },
    { lat: 48.811711, lng: 20.482656 },
    { lat: 48.811545, lng: 20.482636 },
    { lat: 48.811437, lng: 20.482622 },
    { lat: 48.811381, lng: 20.482593 },
    { lat: 48.81126, lng: 20.48251 },
    { lat: 48.811095, lng: 20.482385 },
    { lat: 48.810944, lng: 20.48226 },
    { lat: 48.810851, lng: 20.482214 },
    { lat: 48.810838, lng: 20.482207 },
    { lat: 48.810611, lng: 20.482168 },
    { lat: 48.810405, lng: 20.482183 },
    { lat: 48.81017, lng: 20.482135 },
    { lat: 48.809971, lng: 20.482037 },
    { lat: 48.809806, lng: 20.482008 },
    { lat: 48.8096, lng: 20.482004 },
    { lat: 48.809373, lng: 20.482087 },
    { lat: 48.80877, lng: 20.482296 },
    { lat: 48.808585, lng: 20.482554 },
    { lat: 48.80837, lng: 20.482849 },
    { lat: 48.808233, lng: 20.483131 },
    { lat: 48.808009, lng: 20.483335 },
    { lat: 48.807822, lng: 20.483521 },
    { lat: 48.807513, lng: 20.483851 },
    { lat: 48.807082, lng: 20.484161 },
    { lat: 48.806878, lng: 20.484485 },
    { lat: 48.806716, lng: 20.484693 },
    { lat: 48.806579, lng: 20.484868 },
    { lat: 48.806369, lng: 20.485118 },
    { lat: 48.806273, lng: 20.485234 },
    { lat: 48.805996, lng: 20.485585 },
    { lat: 48.80586, lng: 20.485569 },
    { lat: 48.805832, lng: 20.485564 },
    { lat: 48.805749, lng: 20.485543 },
    { lat: 48.805723, lng: 20.485535 },
    { lat: 48.805533, lng: 20.485434 },
    { lat: 48.805032, lng: 20.484642 },
    { lat: 48.804877, lng: 20.484217 },
    { lat: 48.804808, lng: 20.484191 },
    { lat: 48.804528, lng: 20.484268 },
    { lat: 48.804473, lng: 20.484178 },
    { lat: 48.804429, lng: 20.484078 },
    { lat: 48.804399, lng: 20.483782 },
    { lat: 48.804073, lng: 20.483525 },
    { lat: 48.803434, lng: 20.482777 },
    { lat: 48.803113, lng: 20.482614 },
    { lat: 48.802817, lng: 20.482186 },
    { lat: 48.802079, lng: 20.481271 },
    { lat: 48.80192, lng: 20.481226 },
    { lat: 48.801773, lng: 20.481188 },
    { lat: 48.801632, lng: 20.481148 },
    { lat: 48.801496, lng: 20.4811 },
    { lat: 48.801361, lng: 20.481099 },
    { lat: 48.801265, lng: 20.481101 },
    { lat: 48.801091, lng: 20.481043 },
    { lat: 48.800987, lng: 20.481006 },
    { lat: 48.800959, lng: 20.480948 },
    { lat: 48.800933, lng: 20.480888 },
    { lat: 48.80077, lng: 20.480846 },
    { lat: 48.800444, lng: 20.480633 },
    { lat: 48.800128, lng: 20.480369 },
    { lat: 48.799678, lng: 20.479449 },
    { lat: 48.799584, lng: 20.479334 },
    { lat: 48.799131, lng: 20.478598 },
    { lat: 48.799069, lng: 20.47845 },
    { lat: 48.798891, lng: 20.478223 },
    { lat: 48.798844, lng: 20.477991 },
    { lat: 48.798624, lng: 20.477659 },
    { lat: 48.797955, lng: 20.477222 },
    { lat: 48.797929, lng: 20.477205 },
    { lat: 48.797866, lng: 20.477162 },
    { lat: 48.797845, lng: 20.477148 },
    { lat: 48.79779, lng: 20.477102 },
    { lat: 48.797676, lng: 20.476999 },
    { lat: 48.797557, lng: 20.476897 },
    { lat: 48.797469, lng: 20.476816 },
    { lat: 48.797437, lng: 20.476786 },
    { lat: 48.797312, lng: 20.476966 },
    { lat: 48.797182, lng: 20.47707 },
    { lat: 48.797099, lng: 20.47714 },
    { lat: 48.797008, lng: 20.477156 },
    { lat: 48.796939, lng: 20.477264 },
    { lat: 48.796671, lng: 20.477544 },
    { lat: 48.796554, lng: 20.477702 },
    { lat: 48.796268, lng: 20.477857 },
    { lat: 48.796137, lng: 20.477822 },
    { lat: 48.796024, lng: 20.47791 },
    { lat: 48.795866, lng: 20.478002 },
    { lat: 48.795741, lng: 20.478226 },
    { lat: 48.795602, lng: 20.478396 },
    { lat: 48.795408, lng: 20.478465 },
    { lat: 48.795316, lng: 20.478557 },
    { lat: 48.795282, lng: 20.478692 },
    { lat: 48.795287, lng: 20.478758 },
    { lat: 48.795027, lng: 20.478951 },
    { lat: 48.794875, lng: 20.478967 },
    { lat: 48.794782, lng: 20.478933 },
    { lat: 48.794693, lng: 20.478943 },
    { lat: 48.794623, lng: 20.478873 },
    { lat: 48.794415, lng: 20.478955 },
    { lat: 48.794287, lng: 20.478932 },
    { lat: 48.794078, lng: 20.479026 },
    { lat: 48.793869, lng: 20.479059 },
    { lat: 48.793586, lng: 20.479008 },
    { lat: 48.793443, lng: 20.479085 },
    { lat: 48.793212, lng: 20.478973 },
    { lat: 48.793057, lng: 20.478991 },
    { lat: 48.793016, lng: 20.478835 },
    { lat: 48.792747, lng: 20.478713 },
    { lat: 48.792655, lng: 20.47869 },
    { lat: 48.792395, lng: 20.478579 },
    { lat: 48.792342, lng: 20.478485 },
    { lat: 48.792126, lng: 20.478384 },
    { lat: 48.791992, lng: 20.478335 },
    { lat: 48.791862, lng: 20.478333 },
    { lat: 48.791599, lng: 20.478527 },
    { lat: 48.791454, lng: 20.478376 },
    { lat: 48.791235, lng: 20.478299 },
    { lat: 48.791104, lng: 20.478115 },
    { lat: 48.791009, lng: 20.477894 },
    { lat: 48.790843, lng: 20.477694 },
    { lat: 48.790722, lng: 20.477638 },
    { lat: 48.790645, lng: 20.47753 },
    { lat: 48.790608, lng: 20.477397 },
    { lat: 48.790406, lng: 20.477313 },
    { lat: 48.790322, lng: 20.477296 },
    { lat: 48.790188, lng: 20.477081 },
    { lat: 48.790048, lng: 20.476794 },
    { lat: 48.790027, lng: 20.476516 },
    { lat: 48.789877, lng: 20.476473 },
    { lat: 48.789563, lng: 20.476453 },
    { lat: 48.789479, lng: 20.47637 },
    { lat: 48.789242, lng: 20.476006 },
    { lat: 48.789209, lng: 20.475845 },
    { lat: 48.789084, lng: 20.47577 },
    { lat: 48.788905, lng: 20.475651 },
    { lat: 48.788881, lng: 20.475736 },
    { lat: 48.78912, lng: 20.476281 },
    { lat: 48.789132, lng: 20.47636 },
    { lat: 48.789416, lng: 20.476878 },
    { lat: 48.789749, lng: 20.477247 },
    { lat: 48.790159, lng: 20.477782 },
    { lat: 48.790547, lng: 20.478281 },
    { lat: 48.790881, lng: 20.478719 },
    { lat: 48.791211, lng: 20.479023 },
    { lat: 48.791464, lng: 20.479307 },
    { lat: 48.791702, lng: 20.47929 },
    { lat: 48.791715, lng: 20.479281 },
    { lat: 48.791744, lng: 20.479264 },
    { lat: 48.792694, lng: 20.480425 },
    { lat: 48.792763, lng: 20.480559 },
    { lat: 48.792926, lng: 20.480782 },
    { lat: 48.793009, lng: 20.480924 },
    { lat: 48.79333, lng: 20.481227 },
    { lat: 48.793577, lng: 20.481491 },
    { lat: 48.794099, lng: 20.481785 },
    { lat: 48.794501, lng: 20.482173 },
    { lat: 48.794659, lng: 20.482076 },
    { lat: 48.794913, lng: 20.482024 },
    { lat: 48.795051, lng: 20.482173 },
    { lat: 48.795174, lng: 20.482206 },
    { lat: 48.795354, lng: 20.482265 },
    { lat: 48.795558, lng: 20.482401 },
    { lat: 48.795711, lng: 20.482515 },
    { lat: 48.795947, lng: 20.482709 },
    { lat: 48.796249, lng: 20.482688 },
    { lat: 48.796657, lng: 20.482823 },
    { lat: 48.796844, lng: 20.482821 },
    { lat: 48.796951, lng: 20.482761 },
    { lat: 48.797287, lng: 20.482829 },
    { lat: 48.797519, lng: 20.482795 },
    { lat: 48.797929, lng: 20.482933 },
    { lat: 48.79803, lng: 20.482941 },
    { lat: 48.798236, lng: 20.482913 },
    { lat: 48.798417, lng: 20.482837 },
    { lat: 48.798814, lng: 20.483021 },
    { lat: 48.799164, lng: 20.483394 },
    { lat: 48.799286, lng: 20.483447 },
    { lat: 48.799431, lng: 20.483706 },
    { lat: 48.799548, lng: 20.484073 },
    { lat: 48.799589, lng: 20.484142 },
    { lat: 48.799741, lng: 20.484384 },
    { lat: 48.79992, lng: 20.484545 },
    { lat: 48.800135, lng: 20.484561 },
    { lat: 48.800363, lng: 20.484758 },
    { lat: 48.800674, lng: 20.484905 },
    { lat: 48.800801, lng: 20.485072 },
    { lat: 48.801167, lng: 20.485394 },
    { lat: 48.801466, lng: 20.485615 },
    { lat: 48.801639, lng: 20.485826 },
    { lat: 48.802322, lng: 20.486429 },
    { lat: 48.802779, lng: 20.487024 },
    { lat: 48.803154, lng: 20.487443 },
    { lat: 48.803699, lng: 20.487439 },
    { lat: 48.803936, lng: 20.487659 },
    { lat: 48.804151, lng: 20.487908 },
    { lat: 48.804624, lng: 20.488184 },
    { lat: 48.804836, lng: 20.488362 },
    { lat: 48.805333, lng: 20.488596 },
    { lat: 48.805426, lng: 20.48867 },
    { lat: 48.805685, lng: 20.488662 },
    { lat: 48.805895, lng: 20.489063 },
    { lat: 48.8062, lng: 20.489574 },
    { lat: 48.80635, lng: 20.489828 },
    { lat: 48.806524, lng: 20.490217 },
    { lat: 48.806585, lng: 20.490634 },
    { lat: 48.807168, lng: 20.491341 },
    { lat: 48.807183, lng: 20.492307 },
    { lat: 48.807187, lng: 20.492637 },
    { lat: 48.807184, lng: 20.493759 },
    { lat: 48.807387, lng: 20.494665 },
    { lat: 48.807188, lng: 20.496055 },
    { lat: 48.807301, lng: 20.497163 },
    { lat: 48.807117, lng: 20.499112 },
    { lat: 48.807104, lng: 20.499114 },
    { lat: 48.807103, lng: 20.499897 },
    { lat: 48.806885, lng: 20.50112 },
    { lat: 48.806703, lng: 20.501631 },
    { lat: 48.806627, lng: 20.502075 },
    { lat: 48.806559, lng: 20.502486 },
    { lat: 48.806453, lng: 20.50278 },
    { lat: 48.806254, lng: 20.503007 },
    { lat: 48.806078, lng: 20.503395 },
    { lat: 48.805963, lng: 20.504094 },
    { lat: 48.805687, lng: 20.504364 },
    { lat: 48.805461, lng: 20.504784 },
    { lat: 48.805344, lng: 20.505335 },
    { lat: 48.805041, lng: 20.505856 },
    { lat: 48.80417, lng: 20.506338 },
    { lat: 48.803763, lng: 20.506546 },
    { lat: 48.80335, lng: 20.506826 },
    { lat: 48.802821, lng: 20.50703 },
    { lat: 48.802251, lng: 20.507328 },
    { lat: 48.802067, lng: 20.507801 },
    { lat: 48.801963, lng: 20.507967 },
    { lat: 48.80175, lng: 20.508442 },
    { lat: 48.801131, lng: 20.509022 },
    { lat: 48.80061, lng: 20.5094 },
    { lat: 48.800169, lng: 20.509716 },
    { lat: 48.800033, lng: 20.509968 },
    { lat: 48.799618, lng: 20.510189 },
    { lat: 48.799588, lng: 20.510251 },
    { lat: 48.798847, lng: 20.510611 },
    { lat: 48.798348, lng: 20.51101 },
    { lat: 48.798005, lng: 20.511715 },
    { lat: 48.797635, lng: 20.513073 },
    { lat: 48.797377, lng: 20.515023 },
    { lat: 48.797249, lng: 20.515508 },
    { lat: 48.797227, lng: 20.516764 },
    { lat: 48.797269, lng: 20.517208 },
    { lat: 48.797067, lng: 20.518604 },
    { lat: 48.796991, lng: 20.519398 },
    { lat: 48.796941, lng: 20.52002 },
    { lat: 48.797015, lng: 20.520604 },
    { lat: 48.796778, lng: 20.52107 },
    { lat: 48.796607, lng: 20.521663 },
    { lat: 48.795095, lng: 20.524014 },
    { lat: 48.794795, lng: 20.525214 },
    { lat: 48.794125, lng: 20.526998 },
    { lat: 48.793612, lng: 20.527944 },
    { lat: 48.793377, lng: 20.528803 },
    { lat: 48.793203, lng: 20.529817 },
    { lat: 48.793285, lng: 20.530099 },
    { lat: 48.793118, lng: 20.530916 },
    { lat: 48.793546, lng: 20.533009 },
    { lat: 48.794174, lng: 20.5336 },
  ],
  Bystrany: [
    { lat: 48.9431951, lng: 20.7544984 },
    { lat: 48.9436561, lng: 20.7543251 },
    { lat: 48.9439909, lng: 20.7548179 },
    { lat: 48.9442706, lng: 20.7551531 },
    { lat: 48.9443783, lng: 20.7552085 },
    { lat: 48.9445477, lng: 20.7558279 },
    { lat: 48.9445682, lng: 20.7564146 },
    { lat: 48.9445479, lng: 20.7569563 },
    { lat: 48.9444511, lng: 20.7576692 },
    { lat: 48.9439321, lng: 20.7599472 },
    { lat: 48.9436272, lng: 20.7622605 },
    { lat: 48.9435945, lng: 20.7629363 },
    { lat: 48.94363, lng: 20.7636619 },
    { lat: 48.943812, lng: 20.7651935 },
    { lat: 48.9440154, lng: 20.765957 },
    { lat: 48.944142, lng: 20.7662322 },
    { lat: 48.9443354, lng: 20.7666102 },
    { lat: 48.9446533, lng: 20.767006 },
    { lat: 48.9450565, lng: 20.7677216 },
    { lat: 48.9452527, lng: 20.7682455 },
    { lat: 48.9452604, lng: 20.7689793 },
    { lat: 48.9452274, lng: 20.7691958 },
    { lat: 48.945296, lng: 20.7690674 },
    { lat: 48.9453592, lng: 20.7696203 },
    { lat: 48.9456043, lng: 20.7699633 },
    { lat: 48.9467382, lng: 20.7710022 },
    { lat: 48.9471585, lng: 20.7715019 },
    { lat: 48.9484721, lng: 20.7713367 },
    { lat: 48.9494785, lng: 20.7724013 },
    { lat: 48.9501755, lng: 20.7728924 },
    { lat: 48.9503837, lng: 20.7728918 },
    { lat: 48.9507464, lng: 20.7726029 },
    { lat: 48.9509622, lng: 20.7715637 },
    { lat: 48.9510767, lng: 20.7714252 },
    { lat: 48.9516536, lng: 20.771056 },
    { lat: 48.9518662, lng: 20.7709838 },
    { lat: 48.9521759, lng: 20.7710258 },
    { lat: 48.9531899, lng: 20.7714015 },
    { lat: 48.9548787, lng: 20.771538 },
    { lat: 48.9551533, lng: 20.7716348 },
    { lat: 48.9553809, lng: 20.7718009 },
    { lat: 48.9569117, lng: 20.7739618 },
    { lat: 48.9570633, lng: 20.7737215 },
    { lat: 48.9572782, lng: 20.7731418 },
    { lat: 48.9574819, lng: 20.7721736 },
    { lat: 48.960716, lng: 20.7688224 },
    { lat: 48.961777, lng: 20.7683876 },
    { lat: 48.9625173, lng: 20.7679603 },
    { lat: 48.964062, lng: 20.7666751 },
    { lat: 48.964919, lng: 20.7661681 },
    { lat: 48.9655757, lng: 20.7660876 },
    { lat: 48.966045, lng: 20.766103 },
    { lat: 48.966054, lng: 20.766099 },
    { lat: 48.9661, lng: 20.766076 },
    { lat: 48.96673, lng: 20.765617 },
    { lat: 48.966866, lng: 20.765539 },
    { lat: 48.967344, lng: 20.765393 },
    { lat: 48.967633, lng: 20.765276 },
    { lat: 48.967788, lng: 20.765189 },
    { lat: 48.968395, lng: 20.7645 },
    { lat: 48.968886, lng: 20.763619 },
    { lat: 48.969047, lng: 20.763116 },
    { lat: 48.969432, lng: 20.761764 },
    { lat: 48.969439, lng: 20.761598 },
    { lat: 48.969419, lng: 20.761125 },
    { lat: 48.969364, lng: 20.760801 },
    { lat: 48.969121, lng: 20.759936 },
    { lat: 48.968992, lng: 20.759387 },
    { lat: 48.968959, lng: 20.759216 },
    { lat: 48.968966, lng: 20.759045 },
    { lat: 48.969022, lng: 20.758865 },
    { lat: 48.969081, lng: 20.7587 },
    { lat: 48.969419, lng: 20.75812 },
    { lat: 48.969862, lng: 20.757159 },
    { lat: 48.969921, lng: 20.75695 },
    { lat: 48.969961, lng: 20.756789 },
    { lat: 48.970162, lng: 20.756459 },
    { lat: 48.970445, lng: 20.756275 },
    { lat: 48.970655, lng: 20.756061 },
    { lat: 48.970785, lng: 20.755958 },
    { lat: 48.971246, lng: 20.755779 },
    { lat: 48.971552, lng: 20.755739 },
    { lat: 48.971754, lng: 20.755658 },
    { lat: 48.971918, lng: 20.755565 },
    { lat: 48.972468, lng: 20.755358 },
    { lat: 48.972764, lng: 20.755277 },
    { lat: 48.97312, lng: 20.755261 },
    { lat: 48.97333, lng: 20.755193 },
    { lat: 48.973631, lng: 20.755005 },
    { lat: 48.973811, lng: 20.754909 },
    { lat: 48.973978, lng: 20.754867 },
    { lat: 48.974193, lng: 20.754723 },
    { lat: 48.974376, lng: 20.754635 },
    { lat: 48.974731, lng: 20.754433 },
    { lat: 48.975812, lng: 20.754063 },
    { lat: 48.976304, lng: 20.753861 },
    { lat: 48.976308, lng: 20.753834 },
    { lat: 48.977177, lng: 20.753323 },
    { lat: 48.977257, lng: 20.753341 },
    { lat: 48.97735, lng: 20.75332 },
    { lat: 48.977315, lng: 20.753192 },
    { lat: 48.977285, lng: 20.752914 },
    { lat: 48.977203, lng: 20.752618 },
    { lat: 48.977076, lng: 20.752384 },
    { lat: 48.97693, lng: 20.75211 },
    { lat: 48.97684, lng: 20.751884 },
    { lat: 48.976658, lng: 20.751276 },
    { lat: 48.976454, lng: 20.750706 },
    { lat: 48.976428, lng: 20.750563 },
    { lat: 48.97635, lng: 20.750195 },
    { lat: 48.976284, lng: 20.749868 },
    { lat: 48.976274, lng: 20.749844 },
    { lat: 48.976314, lng: 20.749814 },
    { lat: 48.976368, lng: 20.749803 },
    { lat: 48.976495, lng: 20.749772 },
    { lat: 48.976531, lng: 20.749797 },
    { lat: 48.976739, lng: 20.749676 },
    { lat: 48.976736, lng: 20.749542 },
    { lat: 48.976768, lng: 20.749483 },
    { lat: 48.976967, lng: 20.749442 },
    { lat: 48.976995, lng: 20.749332 },
    { lat: 48.976954, lng: 20.749263 },
    { lat: 48.97695, lng: 20.749254 },
    { lat: 48.976919, lng: 20.749187 },
    { lat: 48.976911, lng: 20.749169 },
    { lat: 48.976802, lng: 20.748965 },
    { lat: 48.976787, lng: 20.748917 },
    { lat: 48.976774, lng: 20.748891 },
    { lat: 48.976751, lng: 20.748829 },
    { lat: 48.976747, lng: 20.748814 },
    { lat: 48.976734, lng: 20.748765 },
    { lat: 48.976557, lng: 20.748828 },
    { lat: 48.976374, lng: 20.748896 },
    { lat: 48.976353, lng: 20.748801 },
    { lat: 48.976315, lng: 20.74864 },
    { lat: 48.976114, lng: 20.748237 },
    { lat: 48.976029, lng: 20.747989 },
    { lat: 48.975941, lng: 20.747795 },
    { lat: 48.97576, lng: 20.74733 },
    { lat: 48.975466, lng: 20.745774 },
    { lat: 48.975437, lng: 20.745646 },
    { lat: 48.97543, lng: 20.745614 },
    { lat: 48.975425, lng: 20.745583 },
    { lat: 48.974428, lng: 20.743313 },
    { lat: 48.974355, lng: 20.743319 },
    { lat: 48.974184, lng: 20.743115 },
    { lat: 48.97409, lng: 20.74308 },
    { lat: 48.973993, lng: 20.742942 },
    { lat: 48.973863, lng: 20.742677 },
    { lat: 48.973615, lng: 20.742201 },
    { lat: 48.973582, lng: 20.742128 },
    { lat: 48.973433, lng: 20.741965 },
    { lat: 48.973343, lng: 20.741868 },
    { lat: 48.973321, lng: 20.741819 },
    { lat: 48.973135, lng: 20.741329 },
    { lat: 48.973029, lng: 20.740944 },
    { lat: 48.972976, lng: 20.740746 },
    { lat: 48.973006, lng: 20.740637 },
    { lat: 48.973004, lng: 20.74043 },
    { lat: 48.972908, lng: 20.740047 },
    { lat: 48.97279, lng: 20.739869 },
    { lat: 48.971946, lng: 20.739754 },
    { lat: 48.971811, lng: 20.739757 },
    { lat: 48.97165, lng: 20.73953 },
    { lat: 48.971631, lng: 20.739418 },
    { lat: 48.971611, lng: 20.739299 },
    { lat: 48.971752, lng: 20.738762 },
    { lat: 48.971694, lng: 20.738694 },
    { lat: 48.971493, lng: 20.738537 },
    { lat: 48.971473, lng: 20.738493 },
    { lat: 48.971429, lng: 20.738541 },
    { lat: 48.9712826, lng: 20.7387662 },
    { lat: 48.97122, lng: 20.738929 },
    { lat: 48.971233, lng: 20.739239 },
    { lat: 48.971128, lng: 20.739164 },
    { lat: 48.971068, lng: 20.739091 },
    { lat: 48.971047, lng: 20.739063 },
    { lat: 48.970879, lng: 20.738863 },
    { lat: 48.970752, lng: 20.738605 },
    { lat: 48.970633, lng: 20.738387 },
    { lat: 48.970523, lng: 20.738217 },
    { lat: 48.970172, lng: 20.737801 },
    { lat: 48.969778, lng: 20.737361 },
    { lat: 48.968827, lng: 20.736379 },
    { lat: 48.968445, lng: 20.736073 },
    { lat: 48.968449, lng: 20.736036 },
    { lat: 48.9679, lng: 20.735342 },
    { lat: 48.967675, lng: 20.734994 },
    { lat: 48.967541, lng: 20.734746 },
    { lat: 48.967499, lng: 20.734672 },
    { lat: 48.967419, lng: 20.734495 },
    { lat: 48.967213, lng: 20.734244 },
    { lat: 48.9664482, lng: 20.7337135 },
    { lat: 48.9659158, lng: 20.7341953 },
    { lat: 48.9638624, lng: 20.7352737 },
    { lat: 48.9628003, lng: 20.7363151 },
    { lat: 48.9620261, lng: 20.7366109 },
    { lat: 48.9603879, lng: 20.7370478 },
    { lat: 48.9589387, lng: 20.738088 },
    { lat: 48.9580738, lng: 20.7385268 },
    { lat: 48.9574042, lng: 20.7387564 },
    { lat: 48.9559267, lng: 20.7388996 },
    { lat: 48.9548948, lng: 20.7392675 },
    { lat: 48.9542673, lng: 20.7397268 },
    { lat: 48.9534214, lng: 20.7401622 },
    { lat: 48.9527114, lng: 20.7406536 },
    { lat: 48.9517434, lng: 20.7414879 },
    { lat: 48.9512313, lng: 20.7406906 },
    { lat: 48.9506528, lng: 20.739286 },
    { lat: 48.9501137, lng: 20.7383337 },
    { lat: 48.9492859, lng: 20.7372928 },
    { lat: 48.9490649, lng: 20.7370927 },
    { lat: 48.9486448, lng: 20.7363858 },
    { lat: 48.9479254, lng: 20.7371165 },
    { lat: 48.9474199, lng: 20.7378792 },
    { lat: 48.9470403, lng: 20.7379386 },
    { lat: 48.9469104, lng: 20.7378944 },
    { lat: 48.9460958, lng: 20.7359923 },
    { lat: 48.9443485, lng: 20.7327329 },
    { lat: 48.9435254, lng: 20.7317688 },
    { lat: 48.9432334, lng: 20.731529 },
    { lat: 48.9427296, lng: 20.7313222 },
    { lat: 48.9424434, lng: 20.7309952 },
    { lat: 48.9422659, lng: 20.7311644 },
    { lat: 48.9420445, lng: 20.7313002 },
    { lat: 48.9412396, lng: 20.7312743 },
    { lat: 48.9413293, lng: 20.7336219 },
    { lat: 48.9415735, lng: 20.7360775 },
    { lat: 48.9419082, lng: 20.7380993 },
    { lat: 48.94184, lng: 20.740092 },
    { lat: 48.9418838, lng: 20.740507 },
    { lat: 48.9423085, lng: 20.7409657 },
    { lat: 48.9421915, lng: 20.741065 },
    { lat: 48.9419073, lng: 20.7415568 },
    { lat: 48.9418941, lng: 20.7417309 },
    { lat: 48.9419814, lng: 20.7424493 },
    { lat: 48.9418214, lng: 20.7434642 },
    { lat: 48.9418415, lng: 20.7441376 },
    { lat: 48.9424286, lng: 20.7456907 },
    { lat: 48.94263, lng: 20.7460389 },
    { lat: 48.942847, lng: 20.746634 },
    { lat: 48.9429037, lng: 20.7471609 },
    { lat: 48.9428726, lng: 20.7475569 },
    { lat: 48.9426607, lng: 20.7487981 },
    { lat: 48.9422504, lng: 20.7509305 },
    { lat: 48.9426014, lng: 20.7519513 },
    { lat: 48.9429917, lng: 20.7526796 },
    { lat: 48.9431663, lng: 20.7532259 },
    { lat: 48.9431385, lng: 20.7537177 },
    { lat: 48.9431861, lng: 20.7543684 },
    { lat: 48.9431951, lng: 20.7544984 },
  ],
  Hnilčík: [
    { lat: 48.871697, lng: 20.5751557 },
    { lat: 48.8716257, lng: 20.5740437 },
    { lat: 48.8713457, lng: 20.5731332 },
    { lat: 48.871052, lng: 20.5716366 },
    { lat: 48.8710871, lng: 20.5716394 },
    { lat: 48.8709912, lng: 20.5710205 },
    { lat: 48.870934, lng: 20.5710579 },
    { lat: 48.8709629, lng: 20.5709832 },
    { lat: 48.8697852, lng: 20.5699754 },
    { lat: 48.8711151, lng: 20.5679852 },
    { lat: 48.8747156, lng: 20.5629073 },
    { lat: 48.8747063, lng: 20.5628076 },
    { lat: 48.874702, lng: 20.56278 },
    { lat: 48.8746977, lng: 20.5627528 },
    { lat: 48.8774872, lng: 20.5532736 },
    { lat: 48.8781911, lng: 20.5387732 },
    { lat: 48.880342, lng: 20.5343427 },
    { lat: 48.8804554, lng: 20.5344551 },
    { lat: 48.8804928, lng: 20.5342147 },
    { lat: 48.880878, lng: 20.5332193 },
    { lat: 48.8812055, lng: 20.532579 },
    { lat: 48.881395, lng: 20.5323125 },
    { lat: 48.8814104, lng: 20.5320328 },
    { lat: 48.8813627, lng: 20.5318659 },
    { lat: 48.878968, lng: 20.5278322 },
    { lat: 48.8782447, lng: 20.5236634 },
    { lat: 48.8782395, lng: 20.5228833 },
    { lat: 48.8785161, lng: 20.5222123 },
    { lat: 48.878745, lng: 20.5218833 },
    { lat: 48.8783268, lng: 20.5208702 },
    { lat: 48.8785115, lng: 20.5203623 },
    { lat: 48.878559, lng: 20.5196979 },
    { lat: 48.8787397, lng: 20.518864 },
    { lat: 48.8786957, lng: 20.5176816 },
    { lat: 48.8784768, lng: 20.5167878 },
    { lat: 48.8779291, lng: 20.5152944 },
    { lat: 48.8773514, lng: 20.5143848 },
    { lat: 48.8770762, lng: 20.5138249 },
    { lat: 48.8764169, lng: 20.5128387 },
    { lat: 48.8758531, lng: 20.5122703 },
    { lat: 48.8745544, lng: 20.5127745 },
    { lat: 48.8701587, lng: 20.5132242 },
    { lat: 48.8698304, lng: 20.5139633 },
    { lat: 48.8692084, lng: 20.5133706 },
    { lat: 48.8689444, lng: 20.5129966 },
    { lat: 48.8678884, lng: 20.5118513 },
    { lat: 48.8677167, lng: 20.5117777 },
    { lat: 48.866884, lng: 20.5116669 },
    { lat: 48.8665694, lng: 20.5115003 },
    { lat: 48.8662083, lng: 20.5112115 },
    { lat: 48.8659167, lng: 20.5111471 },
    { lat: 48.8644008, lng: 20.5115021 },
    { lat: 48.8640655, lng: 20.5116606 },
    { lat: 48.8639215, lng: 20.511753 },
    { lat: 48.8636092, lng: 20.5123762 },
    { lat: 48.8634252, lng: 20.5126351 },
    { lat: 48.862446, lng: 20.5128562 },
    { lat: 48.8620304, lng: 20.5128224 },
    { lat: 48.8618339, lng: 20.513043 },
    { lat: 48.861444, lng: 20.5139153 },
    { lat: 48.8612487, lng: 20.5142009 },
    { lat: 48.8610388, lng: 20.5142531 },
    { lat: 48.8600737, lng: 20.5142127 },
    { lat: 48.8586697, lng: 20.5147579 },
    { lat: 48.8583825, lng: 20.5149733 },
    { lat: 48.8576171, lng: 20.5150366 },
    { lat: 48.8574982, lng: 20.5147132 },
    { lat: 48.8574223, lng: 20.5148545 },
    { lat: 48.8574653, lng: 20.5149499 },
    { lat: 48.8575185, lng: 20.5151702 },
    { lat: 48.8575143, lng: 20.5157859 },
    { lat: 48.8572925, lng: 20.5159046 },
    { lat: 48.8571202, lng: 20.5160871 },
    { lat: 48.8568854, lng: 20.5165798 },
    { lat: 48.8568548, lng: 20.517 },
    { lat: 48.8567884, lng: 20.5171255 },
    { lat: 48.8562356, lng: 20.5194514 },
    { lat: 48.8560938, lng: 20.5199401 },
    { lat: 48.8557645, lng: 20.5206204 },
    { lat: 48.8556342, lng: 20.521057 },
    { lat: 48.8553265, lng: 20.5229231 },
    { lat: 48.8554096, lng: 20.5242504 },
    { lat: 48.8552599, lng: 20.5254695 },
    { lat: 48.8552506, lng: 20.52652 },
    { lat: 48.8550265, lng: 20.5270618 },
    { lat: 48.8547477, lng: 20.5274056 },
    { lat: 48.854418, lng: 20.5275533 },
    { lat: 48.8534039, lng: 20.5279677 },
    { lat: 48.8530509, lng: 20.5279528 },
    { lat: 48.8521529, lng: 20.5275706 },
    { lat: 48.8520147, lng: 20.527459 },
    { lat: 48.8516897, lng: 20.5270427 },
    { lat: 48.8512861, lng: 20.5263828 },
    { lat: 48.8508149, lng: 20.5253401 },
    { lat: 48.8504682, lng: 20.5249825 },
    { lat: 48.850158, lng: 20.5248763 },
    { lat: 48.8499354, lng: 20.5248774 },
    { lat: 48.8494906, lng: 20.5246977 },
    { lat: 48.8489716, lng: 20.5246672 },
    { lat: 48.8486475, lng: 20.5248228 },
    { lat: 48.8485649, lng: 20.5249925 },
    { lat: 48.8479873, lng: 20.5257228 },
    { lat: 48.8476049, lng: 20.5260685 },
    { lat: 48.8469529, lng: 20.5263379 },
    { lat: 48.8466221, lng: 20.5265541 },
    { lat: 48.8464376, lng: 20.5268001 },
    { lat: 48.8461153, lng: 20.5274621 },
    { lat: 48.8453054, lng: 20.5288483 },
    { lat: 48.845095, lng: 20.5290911 },
    { lat: 48.844147, lng: 20.529702 },
    { lat: 48.8436084, lng: 20.5296903 },
    { lat: 48.8426064, lng: 20.5299962 },
    { lat: 48.842234, lng: 20.5297008 },
    { lat: 48.8417702, lng: 20.5294851 },
    { lat: 48.839917, lng: 20.5290661 },
    { lat: 48.8393902, lng: 20.5288275 },
    { lat: 48.8388498, lng: 20.52846 },
    { lat: 48.8384824, lng: 20.5283333 },
    { lat: 48.8377771, lng: 20.5279234 },
    { lat: 48.8368467, lng: 20.5272793 },
    { lat: 48.8359238, lng: 20.5265877 },
    { lat: 48.8346904, lng: 20.525511 },
    { lat: 48.8345349, lng: 20.5254195 },
    { lat: 48.8340478, lng: 20.5253735 },
    { lat: 48.8332567, lng: 20.5248955 },
    { lat: 48.8328755, lng: 20.5243341 },
    { lat: 48.8325736, lng: 20.5241457 },
    { lat: 48.8321788, lng: 20.5237927 },
    { lat: 48.831562, lng: 20.5230098 },
    { lat: 48.8313531, lng: 20.5224172 },
    { lat: 48.8312893, lng: 20.5220996 },
    { lat: 48.8311093, lng: 20.5217683 },
    { lat: 48.8307003, lng: 20.5212461 },
    { lat: 48.8293728, lng: 20.5232723 },
    { lat: 48.8216374, lng: 20.534455 },
    { lat: 48.8215458, lng: 20.5341405 },
    { lat: 48.8211412, lng: 20.5348305 },
    { lat: 48.820478, lng: 20.5357076 },
    { lat: 48.8199846, lng: 20.5360047 },
    { lat: 48.8195868, lng: 20.536723 },
    { lat: 48.819815, lng: 20.5372599 },
    { lat: 48.8199916, lng: 20.5383468 },
    { lat: 48.8199934, lng: 20.5388525 },
    { lat: 48.8200563, lng: 20.539157 },
    { lat: 48.8204587, lng: 20.5395944 },
    { lat: 48.8204852, lng: 20.5398819 },
    { lat: 48.8204793, lng: 20.5403011 },
    { lat: 48.8204166, lng: 20.540673 },
    { lat: 48.8199199, lng: 20.5416409 },
    { lat: 48.8197935, lng: 20.5430644 },
    { lat: 48.8195457, lng: 20.5439722 },
    { lat: 48.8194846, lng: 20.5446351 },
    { lat: 48.8200435, lng: 20.545681 },
    { lat: 48.8201658, lng: 20.5464934 },
    { lat: 48.8203741, lng: 20.5471328 },
    { lat: 48.820186, lng: 20.5477686 },
    { lat: 48.8201071, lng: 20.548377 },
    { lat: 48.8205222, lng: 20.5486115 },
    { lat: 48.819784, lng: 20.5493526 },
    { lat: 48.8197579, lng: 20.5494366 },
    { lat: 48.819642, lng: 20.5507191 },
    { lat: 48.8200227, lng: 20.551336 },
    { lat: 48.8194432, lng: 20.551647 },
    { lat: 48.8192175, lng: 20.5518479 },
    { lat: 48.817901, lng: 20.553873 },
    { lat: 48.818165, lng: 20.55423 },
    { lat: 48.81831, lng: 20.554024 },
    { lat: 48.818493, lng: 20.553708 },
    { lat: 48.818604, lng: 20.553497 },
    { lat: 48.81874, lng: 20.553304 },
    { lat: 48.818866, lng: 20.553594 },
    { lat: 48.819164, lng: 20.554168 },
    { lat: 48.819531, lng: 20.55447 },
    { lat: 48.819674, lng: 20.554569 },
    { lat: 48.819868, lng: 20.554695 },
    { lat: 48.820022, lng: 20.554752 },
    { lat: 48.820096, lng: 20.55491 },
    { lat: 48.820365, lng: 20.554627 },
    { lat: 48.820516, lng: 20.554477 },
    { lat: 48.820608, lng: 20.554428 },
    { lat: 48.820711, lng: 20.554355 },
    { lat: 48.820802, lng: 20.554282 },
    { lat: 48.82104, lng: 20.554192 },
    { lat: 48.821137, lng: 20.554098 },
    { lat: 48.821255, lng: 20.554038 },
    { lat: 48.821335, lng: 20.554046 },
    { lat: 48.821469, lng: 20.554145 },
    { lat: 48.821525, lng: 20.554267 },
    { lat: 48.821638, lng: 20.554366 },
    { lat: 48.82163, lng: 20.55459 },
    { lat: 48.82182, lng: 20.554418 },
    { lat: 48.821976, lng: 20.554576 },
    { lat: 48.822189, lng: 20.554657 },
    { lat: 48.822332, lng: 20.554701 },
    { lat: 48.822472, lng: 20.554846 },
    { lat: 48.82257, lng: 20.555002 },
    { lat: 48.8229, lng: 20.555326 },
    { lat: 48.823055, lng: 20.555329 },
    { lat: 48.823413, lng: 20.55522 },
    { lat: 48.823485, lng: 20.555204 },
    { lat: 48.823739, lng: 20.555289 },
    { lat: 48.823869, lng: 20.555249 },
    { lat: 48.824281, lng: 20.555504 },
    { lat: 48.824636, lng: 20.555602 },
    { lat: 48.824727, lng: 20.555528 },
    { lat: 48.824803, lng: 20.55551 },
    { lat: 48.824852, lng: 20.55562 },
    { lat: 48.825312, lng: 20.555939 },
    { lat: 48.825461, lng: 20.556119 },
    { lat: 48.825562, lng: 20.556163 },
    { lat: 48.82572, lng: 20.556338 },
    { lat: 48.825922, lng: 20.555725 },
    { lat: 48.82619, lng: 20.555503 },
    { lat: 48.826656, lng: 20.554519 },
    { lat: 48.827067, lng: 20.555003 },
    { lat: 48.827509, lng: 20.554871 },
    { lat: 48.82761, lng: 20.553713 },
    { lat: 48.828083, lng: 20.553049 },
    { lat: 48.828367, lng: 20.552673 },
    { lat: 48.828435, lng: 20.551874 },
    { lat: 48.828442, lng: 20.551796 },
    { lat: 48.828251, lng: 20.551161 },
    { lat: 48.828229, lng: 20.551077 },
    { lat: 48.828226, lng: 20.551059 },
    { lat: 48.828576, lng: 20.551017 },
    { lat: 48.828672, lng: 20.551052 },
    { lat: 48.828841, lng: 20.551022 },
    { lat: 48.828956, lng: 20.551063 },
    { lat: 48.829001, lng: 20.55114 },
    { lat: 48.82913, lng: 20.551136 },
    { lat: 48.82921, lng: 20.551159 },
    { lat: 48.829422, lng: 20.551455 },
    { lat: 48.829558, lng: 20.551371 },
    { lat: 48.829705, lng: 20.551267 },
    { lat: 48.8302, lng: 20.551183 },
    { lat: 48.83032, lng: 20.551234 },
    { lat: 48.830546, lng: 20.551251 },
    { lat: 48.830939, lng: 20.551628 },
    { lat: 48.830962, lng: 20.551649 },
    { lat: 48.831351, lng: 20.552028 },
    { lat: 48.831618, lng: 20.552223 },
    { lat: 48.83167, lng: 20.552373 },
    { lat: 48.831727, lng: 20.55241 },
    { lat: 48.831755, lng: 20.552565 },
    { lat: 48.83167, lng: 20.552804 },
    { lat: 48.831364, lng: 20.553175 },
    { lat: 48.831381, lng: 20.553327 },
    { lat: 48.83133, lng: 20.553496 },
    { lat: 48.831343, lng: 20.553641 },
    { lat: 48.831393, lng: 20.55367 },
    { lat: 48.831677, lng: 20.554066 },
    { lat: 48.832002, lng: 20.554519 },
    { lat: 48.832017, lng: 20.554545 },
    { lat: 48.832504, lng: 20.555169 },
    { lat: 48.832553, lng: 20.555297 },
    { lat: 48.832882, lng: 20.556194 },
    { lat: 48.833036, lng: 20.556291 },
    { lat: 48.833682, lng: 20.556701 },
    { lat: 48.833914, lng: 20.55694 },
    { lat: 48.834029, lng: 20.55706 },
    { lat: 48.834831, lng: 20.557759 },
    { lat: 48.835436, lng: 20.558493 },
    { lat: 48.835714, lng: 20.558829 },
    { lat: 48.835709, lng: 20.559373 },
    { lat: 48.835557, lng: 20.560132 },
    { lat: 48.835544, lng: 20.560822 },
    { lat: 48.83568, lng: 20.561181 },
    { lat: 48.835771, lng: 20.561345 },
    { lat: 48.835927, lng: 20.561604 },
    { lat: 48.83625, lng: 20.561957 },
    { lat: 48.836391, lng: 20.562236 },
    { lat: 48.836523, lng: 20.562631 },
    { lat: 48.836891, lng: 20.563622 },
    { lat: 48.836997, lng: 20.563903 },
    { lat: 48.83722, lng: 20.564333 },
    { lat: 48.83734, lng: 20.564562 },
    { lat: 48.837781, lng: 20.565411 },
    { lat: 48.838847, lng: 20.566291 },
    { lat: 48.839612, lng: 20.567094 },
    { lat: 48.840499, lng: 20.568006 },
    { lat: 48.840874, lng: 20.568323 },
    { lat: 48.841165, lng: 20.56857 },
    { lat: 48.841652, lng: 20.568688 },
    { lat: 48.841916, lng: 20.568752 },
    { lat: 48.842358, lng: 20.568988 },
    { lat: 48.843071, lng: 20.568783 },
    { lat: 48.843621, lng: 20.568946 },
    { lat: 48.843649, lng: 20.568822 },
    { lat: 48.843754, lng: 20.568346 },
    { lat: 48.843939, lng: 20.568387 },
    { lat: 48.843994, lng: 20.568497 },
    { lat: 48.843995, lng: 20.568846 },
    { lat: 48.844058, lng: 20.568876 },
    { lat: 48.844253, lng: 20.568815 },
    { lat: 48.844407, lng: 20.568832 },
    { lat: 48.844774, lng: 20.569128 },
    { lat: 48.845281, lng: 20.569159 },
    { lat: 48.845387, lng: 20.569301 },
    { lat: 48.84553, lng: 20.569501 },
    { lat: 48.845652, lng: 20.569715 },
    { lat: 48.845662, lng: 20.569876 },
    { lat: 48.845818, lng: 20.570465 },
    { lat: 48.846556, lng: 20.570747 },
    { lat: 48.846751, lng: 20.570801 },
    { lat: 48.847441, lng: 20.570895 },
    { lat: 48.847564, lng: 20.570911 },
    { lat: 48.847681, lng: 20.570864 },
    { lat: 48.847826, lng: 20.570484 },
    { lat: 48.847959, lng: 20.570448 },
    { lat: 48.848198, lng: 20.570544 },
    { lat: 48.848324, lng: 20.570487 },
    { lat: 48.84859, lng: 20.570527 },
    { lat: 48.848671, lng: 20.570862 },
    { lat: 48.84879, lng: 20.571331 },
    { lat: 48.848932, lng: 20.571875 },
    { lat: 48.849283, lng: 20.572854 },
    { lat: 48.849551, lng: 20.573602 },
    { lat: 48.850091, lng: 20.574767 },
    { lat: 48.851059, lng: 20.575991 },
    { lat: 48.851505, lng: 20.57702 },
    { lat: 48.852139, lng: 20.578409 },
    { lat: 48.852577, lng: 20.57916 },
    { lat: 48.852903, lng: 20.580131 },
    { lat: 48.853287, lng: 20.581135 },
    { lat: 48.853371, lng: 20.581312 },
    { lat: 48.853445, lng: 20.581548 },
    { lat: 48.853473, lng: 20.581825 },
    { lat: 48.853474, lng: 20.582001 },
    { lat: 48.853477, lng: 20.582155 },
    { lat: 48.853482, lng: 20.582193 },
    { lat: 48.853506, lng: 20.582353 },
    { lat: 48.85353, lng: 20.582505 },
    { lat: 48.853545, lng: 20.582637 },
    { lat: 48.853561, lng: 20.582763 },
    { lat: 48.853581, lng: 20.582921 },
    { lat: 48.853505, lng: 20.583004 },
    { lat: 48.854009, lng: 20.584019 },
    { lat: 48.854015, lng: 20.584077 },
    { lat: 48.85402, lng: 20.584196 },
    { lat: 48.854022, lng: 20.584231 },
    { lat: 48.854029, lng: 20.584366 },
    { lat: 48.854027, lng: 20.584388 },
    { lat: 48.854033, lng: 20.584484 },
    { lat: 48.854035, lng: 20.584499 },
    { lat: 48.854045, lng: 20.584717 },
    { lat: 48.853849, lng: 20.5849 },
    { lat: 48.853869, lng: 20.585208 },
    { lat: 48.854043, lng: 20.585097 },
    { lat: 48.854215, lng: 20.585426 },
    { lat: 48.854284, lng: 20.585427 },
    { lat: 48.854418, lng: 20.585854 },
    { lat: 48.854417, lng: 20.586253 },
    { lat: 48.854482, lng: 20.586197 },
    { lat: 48.85466, lng: 20.586109 },
    { lat: 48.85481, lng: 20.586043 },
    { lat: 48.854786, lng: 20.585636 },
    { lat: 48.854718, lng: 20.585401 },
    { lat: 48.854722, lng: 20.584889 },
    { lat: 48.854718, lng: 20.584536 },
    { lat: 48.854812, lng: 20.584479 },
    { lat: 48.85487, lng: 20.584452 },
    { lat: 48.855056, lng: 20.584259 },
    { lat: 48.855431, lng: 20.584268 },
    { lat: 48.855388, lng: 20.584518 },
    { lat: 48.855679, lng: 20.584805 },
    { lat: 48.855745, lng: 20.584716 },
    { lat: 48.856001, lng: 20.584354 },
    { lat: 48.856197, lng: 20.584075 },
    { lat: 48.855982, lng: 20.58384 },
    { lat: 48.855894, lng: 20.583744 },
    { lat: 48.855472, lng: 20.58328 },
    { lat: 48.855651, lng: 20.582673 },
    { lat: 48.8557, lng: 20.582264 },
    { lat: 48.855711, lng: 20.582013 },
    { lat: 48.855672, lng: 20.581654 },
    { lat: 48.85565, lng: 20.581472 },
    { lat: 48.855662, lng: 20.581249 },
    { lat: 48.8557, lng: 20.581111 },
    { lat: 48.85581, lng: 20.580702 },
    { lat: 48.855861, lng: 20.580527 },
    { lat: 48.855906, lng: 20.580372 },
    { lat: 48.856056, lng: 20.580384 },
    { lat: 48.856375, lng: 20.580442 },
    { lat: 48.856866, lng: 20.580522 },
    { lat: 48.857272, lng: 20.580599 },
    { lat: 48.857319, lng: 20.580607 },
    { lat: 48.857398, lng: 20.580628 },
    { lat: 48.858173, lng: 20.580318 },
    { lat: 48.858378, lng: 20.580244 },
    { lat: 48.859085, lng: 20.579994 },
    { lat: 48.859295, lng: 20.579917 },
    { lat: 48.859443, lng: 20.579877 },
    { lat: 48.859841, lng: 20.579773 },
    { lat: 48.860029, lng: 20.579691 },
    { lat: 48.86055, lng: 20.579462 },
    { lat: 48.860844, lng: 20.579329 },
    { lat: 48.861164, lng: 20.579212 },
    { lat: 48.861673, lng: 20.579018 },
    { lat: 48.862273, lng: 20.578794 },
    { lat: 48.863004, lng: 20.578527 },
    { lat: 48.863211, lng: 20.578454 },
    { lat: 48.863761, lng: 20.578256 },
    { lat: 48.864322, lng: 20.578058 },
    { lat: 48.864771, lng: 20.577896 },
    { lat: 48.864819, lng: 20.577879 },
    { lat: 48.864889, lng: 20.577855 },
    { lat: 48.864928, lng: 20.577842 },
    { lat: 48.864881, lng: 20.578119 },
    { lat: 48.864792, lng: 20.578473 },
    { lat: 48.864787, lng: 20.578494 },
    { lat: 48.86466, lng: 20.579047 },
    { lat: 48.864669, lng: 20.579257 },
    { lat: 48.864707, lng: 20.579463 },
    { lat: 48.864747, lng: 20.579594 },
    { lat: 48.864826, lng: 20.579774 },
    { lat: 48.864841, lng: 20.5798 },
    { lat: 48.864865, lng: 20.579843 },
    { lat: 48.864967, lng: 20.579955 },
    { lat: 48.865011, lng: 20.580091 },
    { lat: 48.864987, lng: 20.58023 },
    { lat: 48.865113, lng: 20.580147 },
    { lat: 48.865276, lng: 20.58002 },
    { lat: 48.865363, lng: 20.579951 },
    { lat: 48.865458, lng: 20.579908 },
    { lat: 48.865708, lng: 20.579798 },
    { lat: 48.865777, lng: 20.579761 },
    { lat: 48.865919, lng: 20.579686 },
    { lat: 48.866082, lng: 20.579576 },
    { lat: 48.866289, lng: 20.579443 },
    { lat: 48.866411, lng: 20.579337 },
    { lat: 48.866591, lng: 20.579193 },
    { lat: 48.86687, lng: 20.578997 },
    { lat: 48.866894, lng: 20.578998 },
    { lat: 48.866951, lng: 20.578998 },
    { lat: 48.867088, lng: 20.578918 },
    { lat: 48.867462, lng: 20.578878 },
    { lat: 48.867748, lng: 20.578727 },
    { lat: 48.868159, lng: 20.579008 },
    { lat: 48.868366, lng: 20.579133 },
    { lat: 48.86855, lng: 20.579254 },
    { lat: 48.868645, lng: 20.579314 },
    { lat: 48.868892, lng: 20.579385 },
    { lat: 48.869041, lng: 20.579509 },
    { lat: 48.869239, lng: 20.579666 },
    { lat: 48.869266, lng: 20.5797 },
    { lat: 48.869371, lng: 20.579817 },
    { lat: 48.86952, lng: 20.580114 },
    { lat: 48.869782, lng: 20.580588 },
    { lat: 48.870268, lng: 20.581454 },
    { lat: 48.870483, lng: 20.581839 },
    { lat: 48.870569, lng: 20.582016 },
    { lat: 48.870773, lng: 20.582601 },
    { lat: 48.870877, lng: 20.582921 },
    { lat: 48.870945, lng: 20.58316 },
    { lat: 48.871062, lng: 20.583513 },
    { lat: 48.871229, lng: 20.583948 },
    { lat: 48.871274, lng: 20.584159 },
    { lat: 48.871298, lng: 20.584315 },
    { lat: 48.871294, lng: 20.584594 },
    { lat: 48.87126, lng: 20.584863 },
    { lat: 48.8712, lng: 20.585374 },
    { lat: 48.871234, lng: 20.585815 },
    { lat: 48.871247, lng: 20.585985 },
    { lat: 48.871235, lng: 20.586458 },
    { lat: 48.871184, lng: 20.586847 },
    { lat: 48.871129, lng: 20.587116 },
    { lat: 48.870832, lng: 20.587496 },
    { lat: 48.870971, lng: 20.587586 },
    { lat: 48.871353, lng: 20.587824 },
    { lat: 48.871585, lng: 20.588199 },
    { lat: 48.871858, lng: 20.588442 },
    { lat: 48.872113, lng: 20.588512 },
    { lat: 48.872399, lng: 20.588697 },
    { lat: 48.87285, lng: 20.588719 },
    { lat: 48.873332, lng: 20.588725 },
    { lat: 48.873929, lng: 20.588734 },
    { lat: 48.873952, lng: 20.588732 },
    { lat: 48.873848, lng: 20.58851 },
    { lat: 48.873821, lng: 20.588316 },
    { lat: 48.873896, lng: 20.588008 },
    { lat: 48.873842, lng: 20.586998 },
    { lat: 48.873869, lng: 20.586691 },
    { lat: 48.873783, lng: 20.58615 },
    { lat: 48.873613, lng: 20.585865 },
    { lat: 48.873548, lng: 20.585762 },
    { lat: 48.87345, lng: 20.585629 },
    { lat: 48.873413, lng: 20.58558 },
    { lat: 48.873211, lng: 20.585291 },
    { lat: 48.873224, lng: 20.585271 },
    { lat: 48.873439, lng: 20.585153 },
    { lat: 48.873524, lng: 20.584918 },
    { lat: 48.873245, lng: 20.584122 },
    { lat: 48.873156, lng: 20.583881 },
    { lat: 48.873105, lng: 20.583467 },
    { lat: 48.873502, lng: 20.582575 },
    { lat: 48.873779, lng: 20.582783 },
    { lat: 48.87396, lng: 20.58288 },
    { lat: 48.874043, lng: 20.582925 },
    { lat: 48.874177, lng: 20.582994 },
    { lat: 48.874205, lng: 20.58291 },
    { lat: 48.874472, lng: 20.583004 },
    { lat: 48.874479, lng: 20.582976 },
    { lat: 48.874506, lng: 20.582852 },
    { lat: 48.874558, lng: 20.582629 },
    { lat: 48.874592, lng: 20.582547 },
    { lat: 48.874597, lng: 20.58253 },
    { lat: 48.874368, lng: 20.582419 },
    { lat: 48.874336, lng: 20.582415 },
    { lat: 48.874299, lng: 20.582398 },
    { lat: 48.874274, lng: 20.58233 },
    { lat: 48.874144, lng: 20.582052 },
    { lat: 48.874132, lng: 20.582035 },
    { lat: 48.874131, lng: 20.582007 },
    { lat: 48.874131, lng: 20.581997 },
    { lat: 48.8740606, lng: 20.5818016 },
    { lat: 48.871697, lng: 20.5751557 },
  ],
  Hincovce: [
    { lat: 48.967213, lng: 20.734244 },
    { lat: 48.967081, lng: 20.733963 },
    { lat: 48.967218, lng: 20.7338 },
    { lat: 48.967286, lng: 20.733667 },
    { lat: 48.967611, lng: 20.733306 },
    { lat: 48.968039, lng: 20.73283 },
    { lat: 48.969098, lng: 20.731486 },
    { lat: 48.970034, lng: 20.730273 },
    { lat: 48.97031, lng: 20.729765 },
    { lat: 48.970573, lng: 20.729399 },
    { lat: 48.971151, lng: 20.728875 },
    { lat: 48.971746, lng: 20.728184 },
    { lat: 48.971952, lng: 20.727936 },
    { lat: 48.972308, lng: 20.727612 },
    { lat: 48.972804, lng: 20.727125 },
    { lat: 48.973119, lng: 20.726951 },
    { lat: 48.9734, lng: 20.726649 },
    { lat: 48.973819, lng: 20.726343 },
    { lat: 48.973948, lng: 20.726232 },
    { lat: 48.974061, lng: 20.726134 },
    { lat: 48.974237, lng: 20.725925 },
    { lat: 48.974629, lng: 20.725491 },
    { lat: 48.974894, lng: 20.725054 },
    { lat: 48.974963, lng: 20.724905 },
    { lat: 48.975023, lng: 20.724652 },
    { lat: 48.975066, lng: 20.724542 },
    { lat: 48.975163, lng: 20.724434 },
    { lat: 48.975365, lng: 20.724281 },
    { lat: 48.975481, lng: 20.724125 },
    { lat: 48.975556, lng: 20.723969 },
    { lat: 48.975615, lng: 20.723883 },
    { lat: 48.975683, lng: 20.723908 },
    { lat: 48.976135, lng: 20.723578 },
    { lat: 48.976389, lng: 20.723347 },
    { lat: 48.976424, lng: 20.723315 },
    { lat: 48.976781, lng: 20.722991 },
    { lat: 48.976939, lng: 20.723036 },
    { lat: 48.977317, lng: 20.723002 },
    { lat: 48.977426, lng: 20.723212 },
    { lat: 48.977838, lng: 20.723187 },
    { lat: 48.978065, lng: 20.723084 },
    { lat: 48.978272, lng: 20.722837 },
    { lat: 48.978381, lng: 20.722374 },
    { lat: 48.978714, lng: 20.721886 },
    { lat: 48.978864, lng: 20.721836 },
    { lat: 48.979234, lng: 20.721715 },
    { lat: 48.979498, lng: 20.721628 },
    { lat: 48.979628, lng: 20.721533 },
    { lat: 48.979918, lng: 20.72104 },
    { lat: 48.980083, lng: 20.720809 },
    { lat: 48.980426, lng: 20.719791 },
    { lat: 48.980322, lng: 20.719121 },
    { lat: 48.980301, lng: 20.718772 },
    { lat: 48.980237, lng: 20.718563 },
    { lat: 48.980237, lng: 20.718325 },
    { lat: 48.98066, lng: 20.717254 },
    { lat: 48.981005, lng: 20.716648 },
    { lat: 48.981163, lng: 20.716472 },
    { lat: 48.981248, lng: 20.716421 },
    { lat: 48.981374, lng: 20.716344 },
    { lat: 48.981493, lng: 20.716364 },
    { lat: 48.981707, lng: 20.716506 },
    { lat: 48.982006, lng: 20.716643 },
    { lat: 48.982, lng: 20.716322 },
    { lat: 48.981986, lng: 20.715605 },
    { lat: 48.98184, lng: 20.714131 },
    { lat: 48.981732, lng: 20.713047 },
    { lat: 48.981825, lng: 20.712551 },
    { lat: 48.981897, lng: 20.711936 },
    { lat: 48.981951, lng: 20.71105 },
    { lat: 48.981873, lng: 20.710977 },
    { lat: 48.98155, lng: 20.71042 },
    { lat: 48.981567, lng: 20.710381 },
    { lat: 48.981398, lng: 20.710098 },
    { lat: 48.98114, lng: 20.709591 },
    { lat: 48.980953, lng: 20.7093 },
    { lat: 48.980901, lng: 20.709211 },
    { lat: 48.980826, lng: 20.709042 },
    { lat: 48.980501, lng: 20.708296 },
    { lat: 48.980567, lng: 20.708092 },
    { lat: 48.980617, lng: 20.707666 },
    { lat: 48.980292, lng: 20.706444 },
    { lat: 48.980253, lng: 20.706237 },
    { lat: 48.980182, lng: 20.705807 },
    { lat: 48.98011, lng: 20.705575 },
    { lat: 48.979796, lng: 20.704415 },
    { lat: 48.979682, lng: 20.703943 },
    { lat: 48.979677, lng: 20.703165 },
    { lat: 48.979568, lng: 20.701956 },
    { lat: 48.9793, lng: 20.700948 },
    { lat: 48.979293, lng: 20.700812 },
    { lat: 48.979217, lng: 20.700723 },
    { lat: 48.979175, lng: 20.700555 },
    { lat: 48.979231, lng: 20.700453 },
    { lat: 48.978896, lng: 20.699911 },
    { lat: 48.978869, lng: 20.699715 },
    { lat: 48.978842, lng: 20.699523 },
    { lat: 48.978815, lng: 20.699329 },
    { lat: 48.978688, lng: 20.699103 },
    { lat: 48.978327, lng: 20.698386 },
    { lat: 48.978106, lng: 20.697776 },
    { lat: 48.977931, lng: 20.697462 },
    { lat: 48.976842, lng: 20.695498 },
    { lat: 48.97541, lng: 20.694473 },
    { lat: 48.975404, lng: 20.69447 },
    { lat: 48.9751563, lng: 20.6945799 },
    { lat: 48.9748323, lng: 20.6948709 },
    { lat: 48.9745878, lng: 20.6952121 },
    { lat: 48.9746354, lng: 20.6953483 },
    { lat: 48.9745076, lng: 20.695582 },
    { lat: 48.9742915, lng: 20.6958603 },
    { lat: 48.9738169, lng: 20.6964714 },
    { lat: 48.9733253, lng: 20.6971045 },
    { lat: 48.9731567, lng: 20.6973215 },
    { lat: 48.9730345, lng: 20.6975934 },
    { lat: 48.9728019, lng: 20.698111 },
    { lat: 48.9727612, lng: 20.6982207 },
    { lat: 48.9725323, lng: 20.6988382 },
    { lat: 48.9724506, lng: 20.6990586 },
    { lat: 48.9723062, lng: 20.7004102 },
    { lat: 48.9723039, lng: 20.7007909 },
    { lat: 48.9723456, lng: 20.7010371 },
    { lat: 48.9724697, lng: 20.7017699 },
    { lat: 48.9725146, lng: 20.7020353 },
    { lat: 48.9724307, lng: 20.7024607 },
    { lat: 48.972189, lng: 20.703067 },
    { lat: 48.9719347, lng: 20.7037049 },
    { lat: 48.9714491, lng: 20.7042997 },
    { lat: 48.9709866, lng: 20.7049641 },
    { lat: 48.9704382, lng: 20.7057518 },
    { lat: 48.9700652, lng: 20.7062876 },
    { lat: 48.9697812, lng: 20.7066955 },
    { lat: 48.9696937, lng: 20.7068212 },
    { lat: 48.9695811, lng: 20.7066901 },
    { lat: 48.9693791, lng: 20.7064546 },
    { lat: 48.9692768, lng: 20.7063355 },
    { lat: 48.9691235, lng: 20.7065276 },
    { lat: 48.9689048, lng: 20.7068016 },
    { lat: 48.9684561, lng: 20.7070832 },
    { lat: 48.9681274, lng: 20.7072895 },
    { lat: 48.968063, lng: 20.7073489 },
    { lat: 48.9678179, lng: 20.7075749 },
    { lat: 48.9671404, lng: 20.7083924 },
    { lat: 48.9663943, lng: 20.7092927 },
    { lat: 48.9656965, lng: 20.7101347 },
    { lat: 48.9655271, lng: 20.7103391 },
    { lat: 48.9646782, lng: 20.7115066 },
    { lat: 48.9639901, lng: 20.7121397 },
    { lat: 48.9634476, lng: 20.7128393 },
    { lat: 48.9628461, lng: 20.7131811 },
    { lat: 48.9623642, lng: 20.7137475 },
    { lat: 48.9621618, lng: 20.714233 },
    { lat: 48.9621237, lng: 20.7147075 },
    { lat: 48.9619664, lng: 20.7147494 },
    { lat: 48.961835, lng: 20.7140307 },
    { lat: 48.9613456, lng: 20.713466 },
    { lat: 48.9610629, lng: 20.7133648 },
    { lat: 48.9609207, lng: 20.713451 },
    { lat: 48.9606009, lng: 20.713447 },
    { lat: 48.9604064, lng: 20.7135514 },
    { lat: 48.9601525, lng: 20.7137881 },
    { lat: 48.958735, lng: 20.7157187 },
    { lat: 48.9570095, lng: 20.7172183 },
    { lat: 48.9566144, lng: 20.7175053 },
    { lat: 48.9547082, lng: 20.7185463 },
    { lat: 48.9536894, lng: 20.7192151 },
    { lat: 48.9512913, lng: 20.720222 },
    { lat: 48.9504007, lng: 20.7209512 },
    { lat: 48.9491817, lng: 20.7217468 },
    { lat: 48.9488143, lng: 20.7220905 },
    { lat: 48.9485852, lng: 20.7223911 },
    { lat: 48.9482082, lng: 20.7232548 },
    { lat: 48.9477366, lng: 20.724049 },
    { lat: 48.9470907, lng: 20.72533 },
    { lat: 48.9465799, lng: 20.7261069 },
    { lat: 48.9459712, lng: 20.7271667 },
    { lat: 48.945615, lng: 20.7275993 },
    { lat: 48.9443783, lng: 20.7288195 },
    { lat: 48.943845, lng: 20.7295956 },
    { lat: 48.9428533, lng: 20.7303954 },
    { lat: 48.9424299, lng: 20.7309999 },
    { lat: 48.9424434, lng: 20.7309952 },
    { lat: 48.9427296, lng: 20.7313222 },
    { lat: 48.9432334, lng: 20.731529 },
    { lat: 48.9435254, lng: 20.7317688 },
    { lat: 48.9443485, lng: 20.7327329 },
    { lat: 48.9460958, lng: 20.7359923 },
    { lat: 48.9469104, lng: 20.7378944 },
    { lat: 48.9470403, lng: 20.7379386 },
    { lat: 48.9474199, lng: 20.7378792 },
    { lat: 48.9479254, lng: 20.7371165 },
    { lat: 48.9486448, lng: 20.7363858 },
    { lat: 48.9490649, lng: 20.7370927 },
    { lat: 48.9492859, lng: 20.7372928 },
    { lat: 48.9501137, lng: 20.7383337 },
    { lat: 48.9506528, lng: 20.739286 },
    { lat: 48.9512313, lng: 20.7406906 },
    { lat: 48.9517434, lng: 20.7414879 },
    { lat: 48.9527114, lng: 20.7406536 },
    { lat: 48.9534214, lng: 20.7401622 },
    { lat: 48.9542673, lng: 20.7397268 },
    { lat: 48.9548948, lng: 20.7392675 },
    { lat: 48.9559267, lng: 20.7388996 },
    { lat: 48.9574042, lng: 20.7387564 },
    { lat: 48.9580738, lng: 20.7385268 },
    { lat: 48.9589387, lng: 20.738088 },
    { lat: 48.9603879, lng: 20.7370478 },
    { lat: 48.9620261, lng: 20.7366109 },
    { lat: 48.9628003, lng: 20.7363151 },
    { lat: 48.9638624, lng: 20.7352737 },
    { lat: 48.9659158, lng: 20.7341953 },
    { lat: 48.9664482, lng: 20.7337135 },
    { lat: 48.967213, lng: 20.734244 },
  ],
  Odorín: [
    { lat: 48.9313756, lng: 20.621162 },
    { lat: 48.930571, lng: 20.621763 },
    { lat: 48.930047, lng: 20.622341 },
    { lat: 48.9284093, lng: 20.6246796 },
    { lat: 48.9271193, lng: 20.6262995 },
    { lat: 48.9265293, lng: 20.6269201 },
    { lat: 48.9261486, lng: 20.6278186 },
    { lat: 48.9259734, lng: 20.6280509 },
    { lat: 48.9256862, lng: 20.6282302 },
    { lat: 48.9251648, lng: 20.6290703 },
    { lat: 48.9246571, lng: 20.6297534 },
    { lat: 48.9243229, lng: 20.6304081 },
    { lat: 48.923853, lng: 20.6317672 },
    { lat: 48.9231364, lng: 20.6329055 },
    { lat: 48.9220369, lng: 20.6350938 },
    { lat: 48.9215664, lng: 20.6365337 },
    { lat: 48.9207628, lng: 20.637662 },
    { lat: 48.92054, lng: 20.6378151 },
    { lat: 48.9189322, lng: 20.6411182 },
    { lat: 48.9178273, lng: 20.6428791 },
    { lat: 48.9155818, lng: 20.6437059 },
    { lat: 48.9149795, lng: 20.6444242 },
    { lat: 48.9140103, lng: 20.6461425 },
    { lat: 48.9140703, lng: 20.6480889 },
    { lat: 48.9146216, lng: 20.6480185 },
    { lat: 48.9148618, lng: 20.6481879 },
    { lat: 48.9150473, lng: 20.6484596 },
    { lat: 48.9153021, lng: 20.6497503 },
    { lat: 48.9153807, lng: 20.6499012 },
    { lat: 48.9160574, lng: 20.650561 },
    { lat: 48.9161974, lng: 20.6508508 },
    { lat: 48.9163778, lng: 20.6515517 },
    { lat: 48.9164851, lng: 20.6524677 },
    { lat: 48.9164617, lng: 20.6528795 },
    { lat: 48.9163507, lng: 20.6535169 },
    { lat: 48.9161906, lng: 20.6539196 },
    { lat: 48.9159874, lng: 20.654062 },
    { lat: 48.9157434, lng: 20.6544735 },
    { lat: 48.915773, lng: 20.6555194 },
    { lat: 48.9162427, lng: 20.6569634 },
    { lat: 48.916465, lng: 20.6578832 },
    { lat: 48.9165259, lng: 20.6584226 },
    { lat: 48.9164857, lng: 20.6590573 },
    { lat: 48.9163747, lng: 20.6593213 },
    { lat: 48.916219, lng: 20.6595908 },
    { lat: 48.9156611, lng: 20.6601356 },
    { lat: 48.9155012, lng: 20.6605117 },
    { lat: 48.9155365, lng: 20.662151 },
    { lat: 48.9158298, lng: 20.6643681 },
    { lat: 48.9159924, lng: 20.6648805 },
    { lat: 48.9161455, lng: 20.6651631 },
    { lat: 48.9163174, lng: 20.6654048 },
    { lat: 48.9165196, lng: 20.6655764 },
    { lat: 48.916665, lng: 20.6656191 },
    { lat: 48.9170134, lng: 20.6654733 },
    { lat: 48.9171169, lng: 20.6653141 },
    { lat: 48.9176572, lng: 20.6653163 },
    { lat: 48.9179396, lng: 20.666001 },
    { lat: 48.9179851, lng: 20.6662306 },
    { lat: 48.9179177, lng: 20.6663636 },
    { lat: 48.9177922, lng: 20.6665011 },
    { lat: 48.9179252, lng: 20.6665062 },
    { lat: 48.9178199, lng: 20.6666488 },
    { lat: 48.9178605, lng: 20.6669636 },
    { lat: 48.9182208, lng: 20.6674332 },
    { lat: 48.9182234, lng: 20.6675712 },
    { lat: 48.9182249, lng: 20.6677972 },
    { lat: 48.9186429, lng: 20.6678107 },
    { lat: 48.9192698, lng: 20.6673323 },
    { lat: 48.9195263, lng: 20.6673633 },
    { lat: 48.91983, lng: 20.6661613 },
    { lat: 48.9200572, lng: 20.6656886 },
    { lat: 48.9214268, lng: 20.6641343 },
    { lat: 48.92298, lng: 20.6642802 },
    { lat: 48.9242429, lng: 20.6625538 },
    { lat: 48.9240689, lng: 20.6618142 },
    { lat: 48.9248886, lng: 20.6611568 },
    { lat: 48.9259328, lng: 20.6604891 },
    { lat: 48.9282122, lng: 20.6601047 },
    { lat: 48.9297042, lng: 20.6600808 },
    { lat: 48.9315919, lng: 20.6597925 },
    { lat: 48.931749, lng: 20.659497 },
    { lat: 48.9329793, lng: 20.6582039 },
    { lat: 48.9338125, lng: 20.6591221 },
    { lat: 48.9371115, lng: 20.6550308 },
    { lat: 48.9400968, lng: 20.6515505 },
    { lat: 48.9402184, lng: 20.6511096 },
    { lat: 48.9410552, lng: 20.649905 },
    { lat: 48.9420533, lng: 20.6483245 },
    { lat: 48.9426234, lng: 20.6475538 },
    { lat: 48.9430102, lng: 20.6467603 },
    { lat: 48.9434405, lng: 20.6455482 },
    { lat: 48.9469817, lng: 20.6368556 },
    { lat: 48.9461024, lng: 20.6365735 },
    { lat: 48.9444856, lng: 20.6348583 },
    { lat: 48.9447995, lng: 20.6338512 },
    { lat: 48.9454342, lng: 20.6326902 },
    { lat: 48.9463139, lng: 20.632142 },
    { lat: 48.9463835, lng: 20.6316072 },
    { lat: 48.9466025, lng: 20.6315975 },
    { lat: 48.9470209, lng: 20.6310591 },
    { lat: 48.9465768, lng: 20.6299848 },
    { lat: 48.946025, lng: 20.6295745 },
    { lat: 48.9460096, lng: 20.629272 },
    { lat: 48.9465802, lng: 20.6274007 },
    { lat: 48.9446921, lng: 20.6252786 },
    { lat: 48.9453211, lng: 20.624773 },
    { lat: 48.9441367, lng: 20.622689 },
    { lat: 48.9440654, lng: 20.6227078 },
    { lat: 48.943675, lng: 20.6204505 },
    { lat: 48.9442656, lng: 20.6169608 },
    { lat: 48.9450024, lng: 20.6153617 },
    { lat: 48.9452821, lng: 20.6128634 },
    { lat: 48.9453486, lng: 20.6118762 },
    { lat: 48.9448887, lng: 20.6099699 },
    { lat: 48.9463947, lng: 20.6094297 },
    { lat: 48.9477566, lng: 20.6086806 },
    { lat: 48.9476648, lng: 20.6084136 },
    { lat: 48.947609, lng: 20.6082739 },
    { lat: 48.9463178, lng: 20.6083961 },
    { lat: 48.9448549, lng: 20.6087289 },
    { lat: 48.9435201, lng: 20.6094492 },
    { lat: 48.9430427, lng: 20.6099107 },
    { lat: 48.9417636, lng: 20.6094122 },
    { lat: 48.9413808, lng: 20.6091678 },
    { lat: 48.9410824, lng: 20.6087269 },
    { lat: 48.9409694, lng: 20.6084688 },
    { lat: 48.9407253, lng: 20.6076065 },
    { lat: 48.9407255, lng: 20.6066857 },
    { lat: 48.9406358, lng: 20.6056139 },
    { lat: 48.9406859, lng: 20.6039562 },
    { lat: 48.9405186, lng: 20.6031641 },
    { lat: 48.9402816, lng: 20.602655 },
    { lat: 48.9398115, lng: 20.6021437 },
    { lat: 48.9394777, lng: 20.6014454 },
    { lat: 48.939284, lng: 20.6004855 },
    { lat: 48.9391517, lng: 20.6004998 },
    { lat: 48.9391349, lng: 20.6002258 },
    { lat: 48.9388758, lng: 20.6004975 },
    { lat: 48.9385475, lng: 20.6012248 },
    { lat: 48.938214, lng: 20.6017724 },
    { lat: 48.9380275, lng: 20.6015685 },
    { lat: 48.9378724, lng: 20.6017588 },
    { lat: 48.9373683, lng: 20.602103 },
    { lat: 48.9367161, lng: 20.6027153 },
    { lat: 48.9360023, lng: 20.6031777 },
    { lat: 48.9350131, lng: 20.6031901 },
    { lat: 48.9350658, lng: 20.6030568 },
    { lat: 48.9350413, lng: 20.602941 },
    { lat: 48.9350853, lng: 20.6029112 },
    { lat: 48.9349773, lng: 20.6025772 },
    { lat: 48.9346845, lng: 20.6026977 },
    { lat: 48.9341617, lng: 20.6012656 },
    { lat: 48.9323895, lng: 20.6026532 },
    { lat: 48.9329977, lng: 20.6045458 },
    { lat: 48.9327991, lng: 20.6046892 },
    { lat: 48.9324036, lng: 20.6045243 },
    { lat: 48.9313135, lng: 20.6046347 },
    { lat: 48.9308568, lng: 20.6048051 },
    { lat: 48.9307014, lng: 20.6051121 },
    { lat: 48.9313323, lng: 20.6058086 },
    { lat: 48.932771, lng: 20.6084294 },
    { lat: 48.9335816, lng: 20.611312 },
    { lat: 48.9336207, lng: 20.6150581 },
    { lat: 48.9337277, lng: 20.6162655 },
    { lat: 48.932801, lng: 20.6206999 },
    { lat: 48.9313756, lng: 20.621162 },
  ],
  Harichovce: [
    { lat: 48.9477566, lng: 20.6086806 },
    { lat: 48.9478133, lng: 20.6086906 },
    { lat: 48.9492528, lng: 20.6076261 },
    { lat: 48.949352, lng: 20.6077246 },
    { lat: 48.9499743, lng: 20.6075617 },
    { lat: 48.9502629, lng: 20.6076371 },
    { lat: 48.950378, lng: 20.6085369 },
    { lat: 48.9505541, lng: 20.6090459 },
    { lat: 48.9507227, lng: 20.6105899 },
    { lat: 48.9524247, lng: 20.6104312 },
    { lat: 48.9530286, lng: 20.6105655 },
    { lat: 48.9539314, lng: 20.6109032 },
    { lat: 48.9543538, lng: 20.6109725 },
    { lat: 48.9548441, lng: 20.611175 },
    { lat: 48.9553433, lng: 20.6111873 },
    { lat: 48.9561829, lng: 20.6113641 },
    { lat: 48.9575939, lng: 20.6114337 },
    { lat: 48.9582377, lng: 20.6113361 },
    { lat: 48.9595553, lng: 20.6108833 },
    { lat: 48.9600331, lng: 20.6106183 },
    { lat: 48.9606527, lng: 20.6101035 },
    { lat: 48.9617001, lng: 20.6094314 },
    { lat: 48.9630283, lng: 20.6088364 },
    { lat: 48.964328, lng: 20.6077981 },
    { lat: 48.9648106, lng: 20.6073213 },
    { lat: 48.9653926, lng: 20.6074452 },
    { lat: 48.9657362, lng: 20.6073573 },
    { lat: 48.9659203, lng: 20.6071268 },
    { lat: 48.9669772, lng: 20.6064388 },
    { lat: 48.968468, lng: 20.6051754 },
    { lat: 48.96879, lng: 20.6049613 },
    { lat: 48.9691648, lng: 20.6048371 },
    { lat: 48.9696468, lng: 20.6048053 },
    { lat: 48.9699456, lng: 20.6051579 },
    { lat: 48.9700848, lng: 20.6053666 },
    { lat: 48.9704888, lng: 20.6065374 },
    { lat: 48.9711146, lng: 20.6080559 },
    { lat: 48.9717964, lng: 20.6091594 },
    { lat: 48.9723185, lng: 20.6094263 },
    { lat: 48.972632, lng: 20.6097838 },
    { lat: 48.9728455, lng: 20.6097684 },
    { lat: 48.9730572, lng: 20.6095909 },
    { lat: 48.9751174, lng: 20.6065495 },
    { lat: 48.9756056, lng: 20.6061058 },
    { lat: 48.9760431, lng: 20.6059716 },
    { lat: 48.976541, lng: 20.605638 },
    { lat: 48.97654, lng: 20.605625 },
    { lat: 48.97654, lng: 20.605621 },
    { lat: 48.976337, lng: 20.604916 },
    { lat: 48.976058, lng: 20.603953 },
    { lat: 48.975808, lng: 20.603088 },
    { lat: 48.975502, lng: 20.602046 },
    { lat: 48.975276, lng: 20.601263 },
    { lat: 48.975118, lng: 20.600997 },
    { lat: 48.97487, lng: 20.600578 },
    { lat: 48.97462, lng: 20.600163 },
    { lat: 48.974341, lng: 20.599697 },
    { lat: 48.974128, lng: 20.599342 },
    { lat: 48.973887, lng: 20.598944 },
    { lat: 48.973869, lng: 20.598913 },
    { lat: 48.973854, lng: 20.598889 },
    { lat: 48.973675, lng: 20.598659 },
    { lat: 48.973787, lng: 20.598465 },
    { lat: 48.973883, lng: 20.59825 },
    { lat: 48.97399, lng: 20.597993 },
    { lat: 48.974064, lng: 20.597812 },
    { lat: 48.974136, lng: 20.597659 },
    { lat: 48.974174, lng: 20.597523 },
    { lat: 48.974271, lng: 20.597323 },
    { lat: 48.974364, lng: 20.59705 },
    { lat: 48.974408, lng: 20.596653 },
    { lat: 48.974494, lng: 20.596475 },
    { lat: 48.974577, lng: 20.596292 },
    { lat: 48.974659, lng: 20.596115 },
    { lat: 48.974729, lng: 20.595969 },
    { lat: 48.974766, lng: 20.595882 },
    { lat: 48.974776, lng: 20.5958 },
    { lat: 48.974746, lng: 20.595736 },
    { lat: 48.974792, lng: 20.595557 },
    { lat: 48.974819, lng: 20.595302 },
    { lat: 48.97483, lng: 20.595135 },
    { lat: 48.974846, lng: 20.594846 },
    { lat: 48.974909, lng: 20.594621 },
    { lat: 48.974874, lng: 20.594451 },
    { lat: 48.974881, lng: 20.594326 },
    { lat: 48.974938, lng: 20.594205 },
    { lat: 48.974938, lng: 20.594097 },
    { lat: 48.9749, lng: 20.593938 },
    { lat: 48.97489, lng: 20.593792 },
    { lat: 48.974912, lng: 20.593556 },
    { lat: 48.974886, lng: 20.593418 },
    { lat: 48.974873, lng: 20.59328 },
    { lat: 48.97487, lng: 20.593111 },
    { lat: 48.974867, lng: 20.592985 },
    { lat: 48.974854, lng: 20.592762 },
    { lat: 48.974807, lng: 20.592579 },
    { lat: 48.974779, lng: 20.592473 },
    { lat: 48.974767, lng: 20.592403 },
    { lat: 48.974723, lng: 20.592173 },
    { lat: 48.974703, lng: 20.592027 },
    { lat: 48.974675, lng: 20.591832 },
    { lat: 48.974621, lng: 20.59159 },
    { lat: 48.974555, lng: 20.591356 },
    { lat: 48.97455, lng: 20.591283 },
    { lat: 48.974546, lng: 20.591236 },
    { lat: 48.974538, lng: 20.591127 },
    { lat: 48.974519, lng: 20.591049 },
    { lat: 48.974488, lng: 20.590957 },
    { lat: 48.974395, lng: 20.590857 },
    { lat: 48.974309, lng: 20.590766 },
    { lat: 48.974208, lng: 20.590689 },
    { lat: 48.974061, lng: 20.590582 },
    { lat: 48.973896, lng: 20.590461 },
    { lat: 48.973691, lng: 20.590334 },
    { lat: 48.973642, lng: 20.590284 },
    { lat: 48.973544, lng: 20.59018 },
    { lat: 48.973459, lng: 20.590077 },
    { lat: 48.973362, lng: 20.589926 },
    { lat: 48.973282, lng: 20.589803 },
    { lat: 48.973228, lng: 20.589713 },
    { lat: 48.97316, lng: 20.589556 },
    { lat: 48.973123, lng: 20.589381 },
    { lat: 48.973099, lng: 20.589262 },
    { lat: 48.973083, lng: 20.589146 },
    { lat: 48.973077, lng: 20.589073 },
    { lat: 48.973073, lng: 20.588988 },
    { lat: 48.973077, lng: 20.58893 },
    { lat: 48.973097, lng: 20.58879 },
    { lat: 48.973133, lng: 20.588578 },
    { lat: 48.973164, lng: 20.588363 },
    { lat: 48.973183, lng: 20.588206 },
    { lat: 48.973184, lng: 20.58807 },
    { lat: 48.973185, lng: 20.587992 },
    { lat: 48.973175, lng: 20.587929 },
    { lat: 48.973165, lng: 20.587896 },
    { lat: 48.973149, lng: 20.587838 },
    { lat: 48.973149, lng: 20.58783 },
    { lat: 48.973133, lng: 20.587702 },
    { lat: 48.973119, lng: 20.587576 },
    { lat: 48.973101, lng: 20.587384 },
    { lat: 48.973073, lng: 20.587127 },
    { lat: 48.973021, lng: 20.586648 },
    { lat: 48.973018, lng: 20.58662 },
    { lat: 48.9729988, lng: 20.5864442 },
    { lat: 48.972993, lng: 20.586391 },
    { lat: 48.972978, lng: 20.586205 },
    { lat: 48.972946, lng: 20.58577 },
    { lat: 48.972915, lng: 20.585352 },
    { lat: 48.972898, lng: 20.585144 },
    { lat: 48.972854, lng: 20.584548 },
    { lat: 48.97288, lng: 20.584294 },
    { lat: 48.97292, lng: 20.584302 },
    { lat: 48.973015, lng: 20.584358 },
    { lat: 48.973068, lng: 20.584389 },
    { lat: 48.973151, lng: 20.584457 },
    { lat: 48.973213, lng: 20.584527 },
    { lat: 48.973256, lng: 20.584574 },
    { lat: 48.973359, lng: 20.584801 },
    { lat: 48.973332, lng: 20.583412 },
    { lat: 48.97331, lng: 20.582297 },
    { lat: 48.97331, lng: 20.58229 },
    { lat: 48.973309, lng: 20.582234 },
    { lat: 48.973182, lng: 20.582247 },
    { lat: 48.972921, lng: 20.582269 },
    { lat: 48.972775, lng: 20.582285 },
    { lat: 48.972361, lng: 20.582319 },
    { lat: 48.972288, lng: 20.582327 },
    { lat: 48.972285, lng: 20.582318 },
    { lat: 48.972275, lng: 20.582281 },
    { lat: 48.972263, lng: 20.582239 },
    { lat: 48.97225, lng: 20.58219 },
    { lat: 48.972244, lng: 20.582164 },
    { lat: 48.972241, lng: 20.582147 },
    { lat: 48.972174, lng: 20.58182 },
    { lat: 48.972073, lng: 20.581332 },
    { lat: 48.972058, lng: 20.581254 },
    { lat: 48.972037, lng: 20.581251 },
    { lat: 48.971913, lng: 20.581214 },
    { lat: 48.971868, lng: 20.5812 },
    { lat: 48.971844, lng: 20.581195 },
    { lat: 48.971889, lng: 20.581005 },
    { lat: 48.971865, lng: 20.58089 },
    { lat: 48.97184, lng: 20.58077 },
    { lat: 48.971826, lng: 20.580691 },
    { lat: 48.971814, lng: 20.580636 },
    { lat: 48.971808, lng: 20.58057 },
    { lat: 48.971808, lng: 20.580497 },
    { lat: 48.971817, lng: 20.580435 },
    { lat: 48.971834, lng: 20.580333 },
    { lat: 48.971843, lng: 20.580295 },
    { lat: 48.971903, lng: 20.580168 },
    { lat: 48.971898, lng: 20.580159 },
    { lat: 48.971892, lng: 20.580147 },
    { lat: 48.97188, lng: 20.580125 },
    { lat: 48.971872, lng: 20.580107 },
    { lat: 48.971878, lng: 20.579989 },
    { lat: 48.971836, lng: 20.579877 },
    { lat: 48.971797, lng: 20.579763 },
    { lat: 48.971769, lng: 20.579669 },
    { lat: 48.971779, lng: 20.579594 },
    { lat: 48.971778, lng: 20.579505 },
    { lat: 48.971777, lng: 20.579388 },
    { lat: 48.97181, lng: 20.579311 },
    { lat: 48.971808, lng: 20.579234 },
    { lat: 48.971803, lng: 20.579133 },
    { lat: 48.971799, lng: 20.579051 },
    { lat: 48.971795, lng: 20.578975 },
    { lat: 48.971792, lng: 20.578903 },
    { lat: 48.971788, lng: 20.57881 },
    { lat: 48.971787, lng: 20.578782 },
    { lat: 48.971785, lng: 20.578754 },
    { lat: 48.971782, lng: 20.578696 },
    { lat: 48.971779, lng: 20.578628 },
    { lat: 48.971774, lng: 20.578534 },
    { lat: 48.97177, lng: 20.578466 },
    { lat: 48.971767, lng: 20.578401 },
    { lat: 48.971764, lng: 20.578352 },
    { lat: 48.971763, lng: 20.578307 },
    { lat: 48.97176, lng: 20.578257 },
    { lat: 48.971758, lng: 20.578205 },
    { lat: 48.971751, lng: 20.578097 },
    { lat: 48.971742, lng: 20.578093 },
    { lat: 48.971677, lng: 20.578057 },
    { lat: 48.971637, lng: 20.578036 },
    { lat: 48.971611, lng: 20.578023 },
    { lat: 48.971593, lng: 20.578013 },
    { lat: 48.971518, lng: 20.577973 },
    { lat: 48.971485, lng: 20.577956 },
    { lat: 48.971439, lng: 20.577932 },
    { lat: 48.971357, lng: 20.577889 },
    { lat: 48.971315, lng: 20.577867 },
    { lat: 48.971267, lng: 20.577842 },
    { lat: 48.971222, lng: 20.577818 },
    { lat: 48.971174, lng: 20.577793 },
    { lat: 48.971102, lng: 20.577756 },
    { lat: 48.971098, lng: 20.577754 },
    { lat: 48.971054, lng: 20.577731 },
    { lat: 48.97101, lng: 20.577708 },
    { lat: 48.970973, lng: 20.577689 },
    { lat: 48.970922, lng: 20.577663 },
    { lat: 48.97088, lng: 20.577642 },
    { lat: 48.970822, lng: 20.577612 },
    { lat: 48.970712, lng: 20.577556 },
    { lat: 48.970653, lng: 20.577525 },
    { lat: 48.970653, lng: 20.57752 },
    { lat: 48.970662, lng: 20.577397 },
    { lat: 48.970675, lng: 20.577222 },
    { lat: 48.970682, lng: 20.577139 },
    { lat: 48.970691, lng: 20.577026 },
    { lat: 48.970691, lng: 20.577022 },
    { lat: 48.970696, lng: 20.576966 },
    { lat: 48.970696, lng: 20.576956 },
    { lat: 48.970715, lng: 20.576816 },
    { lat: 48.970742, lng: 20.576628 },
    { lat: 48.97078, lng: 20.576353 },
    { lat: 48.970775, lng: 20.576335 },
    { lat: 48.970782, lng: 20.576325 },
    { lat: 48.970883, lng: 20.576187 },
    { lat: 48.971005, lng: 20.576093 },
    { lat: 48.971127, lng: 20.575999 },
    { lat: 48.971209, lng: 20.575928 },
    { lat: 48.971352, lng: 20.575799 },
    { lat: 48.971435, lng: 20.575762 },
    { lat: 48.971475, lng: 20.57574 },
    { lat: 48.971545, lng: 20.575702 },
    { lat: 48.971644, lng: 20.57563 },
    { lat: 48.971769, lng: 20.575533 },
    { lat: 48.972001, lng: 20.575278 },
    { lat: 48.972002, lng: 20.575277 },
    { lat: 48.972012, lng: 20.575273 },
    { lat: 48.9721328, lng: 20.5752101 },
    { lat: 48.972188, lng: 20.575232 },
    { lat: 48.972383, lng: 20.575261 },
    { lat: 48.972719, lng: 20.575387 },
    { lat: 48.972922, lng: 20.575421 },
    { lat: 48.973014, lng: 20.575282 },
    { lat: 48.973053, lng: 20.575207 },
    { lat: 48.973132, lng: 20.575039 },
    { lat: 48.973225, lng: 20.574831 },
    { lat: 48.973269, lng: 20.574742 },
    { lat: 48.973364, lng: 20.574532 },
    { lat: 48.973433, lng: 20.574407 },
    { lat: 48.973567, lng: 20.57417 },
    { lat: 48.973679, lng: 20.573983 },
    { lat: 48.973808, lng: 20.573781 },
    { lat: 48.973939, lng: 20.573536 },
    { lat: 48.974077, lng: 20.57326 },
    { lat: 48.97417, lng: 20.573067 },
    { lat: 48.974297, lng: 20.57281 },
    { lat: 48.97437, lng: 20.572666 },
    { lat: 48.974534, lng: 20.572416 },
    { lat: 48.974652, lng: 20.572209 },
    { lat: 48.974771, lng: 20.572047 },
    { lat: 48.974944, lng: 20.571849 },
    { lat: 48.975009, lng: 20.57177 },
    { lat: 48.975112, lng: 20.571671 },
    { lat: 48.975336, lng: 20.571515 },
    { lat: 48.975514, lng: 20.571394 },
    { lat: 48.975661, lng: 20.571286 },
    { lat: 48.975817, lng: 20.571178 },
    { lat: 48.975992, lng: 20.571053 },
    { lat: 48.976181, lng: 20.570918 },
    { lat: 48.976307, lng: 20.570829 },
    { lat: 48.976434, lng: 20.570738 },
    { lat: 48.976613, lng: 20.570607 },
    { lat: 48.976745, lng: 20.570539 },
    { lat: 48.976891, lng: 20.570463 },
    { lat: 48.977084, lng: 20.570364 },
    { lat: 48.977274, lng: 20.570267 },
    { lat: 48.977513, lng: 20.570146 },
    { lat: 48.977956, lng: 20.569936 },
    { lat: 48.978124, lng: 20.569858 },
    { lat: 48.97829, lng: 20.569797 },
    { lat: 48.978652, lng: 20.569615 },
    { lat: 48.979059, lng: 20.569459 },
    { lat: 48.979226, lng: 20.569396 },
    { lat: 48.979434, lng: 20.569319 },
    { lat: 48.97958, lng: 20.569266 },
    { lat: 48.979826, lng: 20.569093 },
    { lat: 48.980102, lng: 20.56886 },
    { lat: 48.980241, lng: 20.568743 },
    { lat: 48.980416, lng: 20.568587 },
    { lat: 48.980522, lng: 20.568495 },
    { lat: 48.980712, lng: 20.568333 },
    { lat: 48.981084, lng: 20.56811 },
    { lat: 48.981234, lng: 20.568019 },
    { lat: 48.98131, lng: 20.567975 },
    { lat: 48.981437, lng: 20.5679 },
    { lat: 48.98156, lng: 20.567831 },
    { lat: 48.981629, lng: 20.567777 },
    { lat: 48.981832, lng: 20.567545 },
    { lat: 48.982043, lng: 20.567302 },
    { lat: 48.982283, lng: 20.567025 },
    { lat: 48.982292, lng: 20.567015 },
    { lat: 48.98251, lng: 20.566766 },
    { lat: 48.982729, lng: 20.566511 },
    { lat: 48.982937, lng: 20.566271 },
    { lat: 48.983178, lng: 20.565994 },
    { lat: 48.983392, lng: 20.565631 },
    { lat: 48.98349, lng: 20.56546 },
    { lat: 48.983543, lng: 20.56537 },
    { lat: 48.983634, lng: 20.565216 },
    { lat: 48.983802, lng: 20.564927 },
    { lat: 48.984045, lng: 20.564514 },
    { lat: 48.984034, lng: 20.564503 },
    { lat: 48.983781, lng: 20.5642732 },
    { lat: 48.9835202, lng: 20.5637963 },
    { lat: 48.9834071, lng: 20.5638375 },
    { lat: 48.9831612, lng: 20.5637067 },
    { lat: 48.9824196, lng: 20.5646358 },
    { lat: 48.9821776, lng: 20.5643302 },
    { lat: 48.9818829, lng: 20.5645537 },
    { lat: 48.9817029, lng: 20.5645861 },
    { lat: 48.9815859, lng: 20.56431 },
    { lat: 48.9810245, lng: 20.5647239 },
    { lat: 48.9805035, lng: 20.5639935 },
    { lat: 48.9805994, lng: 20.5637861 },
    { lat: 48.9807609, lng: 20.5636097 },
    { lat: 48.9800759, lng: 20.56299 },
    { lat: 48.978791, lng: 20.563249 },
    { lat: 48.9781326, lng: 20.5630457 },
    { lat: 48.9777477, lng: 20.5630489 },
    { lat: 48.9773801, lng: 20.56285 },
    { lat: 48.977275, lng: 20.5628722 },
    { lat: 48.9773703, lng: 20.5625179 },
    { lat: 48.9776354, lng: 20.5619238 },
    { lat: 48.9782491, lng: 20.5608536 },
    { lat: 48.9781511, lng: 20.5606078 },
    { lat: 48.9792569, lng: 20.5588388 },
    { lat: 48.9783798, lng: 20.5578499 },
    { lat: 48.9782834, lng: 20.5580193 },
    { lat: 48.9778622, lng: 20.5575558 },
    { lat: 48.9773445, lng: 20.5568281 },
    { lat: 48.9768702, lng: 20.5559928 },
    { lat: 48.9757162, lng: 20.5543412 },
    { lat: 48.9679703, lng: 20.5472456 },
    { lat: 48.9679365, lng: 20.5472446 },
    { lat: 48.9676302, lng: 20.5472434 },
    { lat: 48.9672301, lng: 20.5469241 },
    { lat: 48.9664908, lng: 20.5491333 },
    { lat: 48.9663434, lng: 20.5498406 },
    { lat: 48.9659698, lng: 20.5524737 },
    { lat: 48.9656822, lng: 20.5539288 },
    { lat: 48.9647481, lng: 20.5559275 },
    { lat: 48.964016, lng: 20.5579965 },
    { lat: 48.9638727, lng: 20.5585411 },
    { lat: 48.9638002, lng: 20.5590832 },
    { lat: 48.9636553, lng: 20.5612632 },
    { lat: 48.9635241, lng: 20.5613717 },
    { lat: 48.9633944, lng: 20.5613612 },
    { lat: 48.9630889, lng: 20.5630711 },
    { lat: 48.9626885, lng: 20.5643984 },
    { lat: 48.9624241, lng: 20.5650184 },
    { lat: 48.9622725, lng: 20.56509 },
    { lat: 48.9617268, lng: 20.5662024 },
    { lat: 48.9613963, lng: 20.5671028 },
    { lat: 48.9609502, lng: 20.5692132 },
    { lat: 48.9607356, lng: 20.5707009 },
    { lat: 48.9606803, lng: 20.570788 },
    { lat: 48.9598698, lng: 20.5712811 },
    { lat: 48.9596028, lng: 20.5715251 },
    { lat: 48.9589199, lng: 20.5721839 },
    { lat: 48.9578933, lng: 20.5733196 },
    { lat: 48.9563329, lng: 20.5746709 },
    { lat: 48.9552157, lng: 20.5758023 },
    { lat: 48.9544206, lng: 20.5759157 },
    { lat: 48.9534715, lng: 20.5764382 },
    { lat: 48.9527806, lng: 20.5771865 },
    { lat: 48.9524428, lng: 20.5776455 },
    { lat: 48.9512793, lng: 20.5786467 },
    { lat: 48.9510255, lng: 20.5787244 },
    { lat: 48.9503094, lng: 20.5795213 },
    { lat: 48.9474831, lng: 20.5816673 },
    { lat: 48.9482638, lng: 20.5853742 },
    { lat: 48.9485824, lng: 20.5866988 },
    { lat: 48.9498197, lng: 20.5913545 },
    { lat: 48.9492067, lng: 20.5917095 },
    { lat: 48.9475718, lng: 20.5923817 },
    { lat: 48.9464699, lng: 20.5924855 },
    { lat: 48.9448191, lng: 20.5942951 },
    { lat: 48.9439147, lng: 20.5956089 },
    { lat: 48.9434586, lng: 20.5959194 },
    { lat: 48.9435686, lng: 20.5961204 },
    { lat: 48.9433689, lng: 20.5966339 },
    { lat: 48.9428836, lng: 20.5970693 },
    { lat: 48.9430238, lng: 20.5980403 },
    { lat: 48.9428271, lng: 20.5982688 },
    { lat: 48.9425922, lng: 20.598367 },
    { lat: 48.9424387, lng: 20.5983584 },
    { lat: 48.9421579, lng: 20.597695 },
    { lat: 48.942041, lng: 20.5975494 },
    { lat: 48.9418454, lng: 20.5976468 },
    { lat: 48.9417119, lng: 20.5979381 },
    { lat: 48.9416653, lng: 20.5982385 },
    { lat: 48.9417712, lng: 20.5986723 },
    { lat: 48.9417386, lng: 20.5989823 },
    { lat: 48.9414441, lng: 20.5994919 },
    { lat: 48.941261, lng: 20.5996527 },
    { lat: 48.9410927, lng: 20.5997008 },
    { lat: 48.9409399, lng: 20.5995873 },
    { lat: 48.9408574, lng: 20.5992386 },
    { lat: 48.940486, lng: 20.5989335 },
    { lat: 48.940139, lng: 20.5983692 },
    { lat: 48.9399942, lng: 20.5982872 },
    { lat: 48.9398651, lng: 20.5983893 },
    { lat: 48.9398085, lng: 20.5985395 },
    { lat: 48.939607, lng: 20.5995537 },
    { lat: 48.9395072, lng: 20.5998135 },
    { lat: 48.9393051, lng: 20.6000153 },
    { lat: 48.9392769, lng: 20.6002006 },
    { lat: 48.9391349, lng: 20.6002258 },
    { lat: 48.9391517, lng: 20.6004998 },
    { lat: 48.939284, lng: 20.6004855 },
    { lat: 48.9394777, lng: 20.6014454 },
    { lat: 48.9398115, lng: 20.6021437 },
    { lat: 48.9402816, lng: 20.602655 },
    { lat: 48.9405186, lng: 20.6031641 },
    { lat: 48.9406859, lng: 20.6039562 },
    { lat: 48.9406358, lng: 20.6056139 },
    { lat: 48.9407255, lng: 20.6066857 },
    { lat: 48.9407253, lng: 20.6076065 },
    { lat: 48.9409694, lng: 20.6084688 },
    { lat: 48.9410824, lng: 20.6087269 },
    { lat: 48.9413808, lng: 20.6091678 },
    { lat: 48.9417636, lng: 20.6094122 },
    { lat: 48.9430427, lng: 20.6099107 },
    { lat: 48.9435201, lng: 20.6094492 },
    { lat: 48.9448549, lng: 20.6087289 },
    { lat: 48.9463178, lng: 20.6083961 },
    { lat: 48.947609, lng: 20.6082739 },
    { lat: 48.9476648, lng: 20.6084136 },
    { lat: 48.9477566, lng: 20.6086806 },
  ],
  Slatvina: [
    { lat: 48.9611884, lng: 20.8336457 },
    { lat: 48.9602677, lng: 20.8340426 },
    { lat: 48.9590201, lng: 20.8347476 },
    { lat: 48.9581553, lng: 20.8349645 },
    { lat: 48.9572163, lng: 20.8347312 },
    { lat: 48.9545536, lng: 20.8346165 },
    { lat: 48.9534412, lng: 20.8347408 },
    { lat: 48.9530303, lng: 20.8350965 },
    { lat: 48.9528108, lng: 20.8354233 },
    { lat: 48.9524779, lng: 20.8357642 },
    { lat: 48.9523486, lng: 20.8360559 },
    { lat: 48.9517715, lng: 20.8364996 },
    { lat: 48.9515759, lng: 20.8367199 },
    { lat: 48.9512515, lng: 20.8370851 },
    { lat: 48.9506434, lng: 20.8376333 },
    { lat: 48.9507481, lng: 20.8378993 },
    { lat: 48.9508854, lng: 20.838031 },
    { lat: 48.9510045, lng: 20.8384509 },
    { lat: 48.9511157, lng: 20.8385424 },
    { lat: 48.9511327, lng: 20.8387423 },
    { lat: 48.9510839, lng: 20.8390245 },
    { lat: 48.9513713, lng: 20.8396234 },
    { lat: 48.9513267, lng: 20.8396367 },
    { lat: 48.9513203, lng: 20.8405991 },
    { lat: 48.9515456, lng: 20.8409974 },
    { lat: 48.9519067, lng: 20.8412847 },
    { lat: 48.9524135, lng: 20.8412897 },
    { lat: 48.9526243, lng: 20.8411699 },
    { lat: 48.9530009, lng: 20.8411533 },
    { lat: 48.9530373, lng: 20.8413621 },
    { lat: 48.9531026, lng: 20.8414658 },
    { lat: 48.9532012, lng: 20.8413466 },
    { lat: 48.9533719, lng: 20.8413055 },
    { lat: 48.9535578, lng: 20.8413743 },
    { lat: 48.9538276, lng: 20.8415936 },
    { lat: 48.9538041, lng: 20.8443869 },
    { lat: 48.9538634, lng: 20.8450018 },
    { lat: 48.9538789, lng: 20.8458792 },
    { lat: 48.9538395, lng: 20.8466702 },
    { lat: 48.9534644, lng: 20.8467912 },
    { lat: 48.9532558, lng: 20.8472092 },
    { lat: 48.9531846, lng: 20.8474591 },
    { lat: 48.9530357, lng: 20.8486692 },
    { lat: 48.9528276, lng: 20.8494926 },
    { lat: 48.9527839, lng: 20.8499577 },
    { lat: 48.9528584, lng: 20.8504263 },
    { lat: 48.953168, lng: 20.8513796 },
    { lat: 48.9531594, lng: 20.8549747 },
    { lat: 48.9532153, lng: 20.8553245 },
    { lat: 48.9534799, lng: 20.8562824 },
    { lat: 48.9536135, lng: 20.8558113 },
    { lat: 48.9537985, lng: 20.8554959 },
    { lat: 48.9540025, lng: 20.8552051 },
    { lat: 48.9543629, lng: 20.8548858 },
    { lat: 48.9550349, lng: 20.8546516 },
    { lat: 48.9552138, lng: 20.8547191 },
    { lat: 48.9557251, lng: 20.8545656 },
    { lat: 48.9558357, lng: 20.8544054 },
    { lat: 48.955908, lng: 20.8538839 },
    { lat: 48.9559095, lng: 20.8535367 },
    { lat: 48.9561694, lng: 20.8534653 },
    { lat: 48.9562151, lng: 20.8531884 },
    { lat: 48.9561799, lng: 20.8530838 },
    { lat: 48.9565221, lng: 20.8525596 },
    { lat: 48.9566063, lng: 20.8531425 },
    { lat: 48.9568032, lng: 20.8534379 },
    { lat: 48.9567425, lng: 20.8534695 },
    { lat: 48.9568611, lng: 20.8537102 },
    { lat: 48.9572654, lng: 20.854146 },
    { lat: 48.9574314, lng: 20.8541928 },
    { lat: 48.9581205, lng: 20.8548934 },
    { lat: 48.9590169, lng: 20.854937 },
    { lat: 48.9595395, lng: 20.8552439 },
    { lat: 48.9596902, lng: 20.8555676 },
    { lat: 48.9598702, lng: 20.8556301 },
    { lat: 48.9602451, lng: 20.8559648 },
    { lat: 48.9602679, lng: 20.8577149 },
    { lat: 48.9604039, lng: 20.8584357 },
    { lat: 48.9605536, lng: 20.8584339 },
    { lat: 48.9605952, lng: 20.8585678 },
    { lat: 48.9606604, lng: 20.8592884 },
    { lat: 48.9608161, lng: 20.8600899 },
    { lat: 48.9614831, lng: 20.8600406 },
    { lat: 48.9617308, lng: 20.8602407 },
    { lat: 48.9616019, lng: 20.8607491 },
    { lat: 48.9615377, lng: 20.8613076 },
    { lat: 48.9619097, lng: 20.8619234 },
    { lat: 48.9621485, lng: 20.8625196 },
    { lat: 48.9624732, lng: 20.8638513 },
    { lat: 48.9625677, lng: 20.8637508 },
    { lat: 48.9631359, lng: 20.8647674 },
    { lat: 48.9636562, lng: 20.8652362 },
    { lat: 48.9637003, lng: 20.8657676 },
    { lat: 48.9637885, lng: 20.8659147 },
    { lat: 48.9638133, lng: 20.8665769 },
    { lat: 48.9646711, lng: 20.8693505 },
    { lat: 48.9656021, lng: 20.8696566 },
    { lat: 48.9664952, lng: 20.8695359 },
    { lat: 48.9680167, lng: 20.8706462 },
    { lat: 48.9708771, lng: 20.8717157 },
    { lat: 48.9733711, lng: 20.8722675 },
    { lat: 48.973898, lng: 20.872807 },
    { lat: 48.97625, lng: 20.872586 },
    { lat: 48.976148, lng: 20.870877 },
    { lat: 48.976143, lng: 20.870796 },
    { lat: 48.976098, lng: 20.870034 },
    { lat: 48.975763, lng: 20.868995 },
    { lat: 48.975679, lng: 20.868625 },
    { lat: 48.975408, lng: 20.867593 },
    { lat: 48.975369, lng: 20.867438 },
    { lat: 48.975268, lng: 20.867107 },
    { lat: 48.975076, lng: 20.86659 },
    { lat: 48.974757, lng: 20.866127 },
    { lat: 48.974713, lng: 20.866063 },
    { lat: 48.974508, lng: 20.865766 },
    { lat: 48.974395, lng: 20.865018 },
    { lat: 48.97446, lng: 20.863773 },
    { lat: 48.97446, lng: 20.863721 },
    { lat: 48.974454, lng: 20.863538 },
    { lat: 48.974388, lng: 20.862812 },
    { lat: 48.974093, lng: 20.861202 },
    { lat: 48.973883, lng: 20.860369 },
    { lat: 48.973684, lng: 20.859723 },
    { lat: 48.973475, lng: 20.859272 },
    { lat: 48.972834, lng: 20.856911 },
    { lat: 48.973126, lng: 20.856493 },
    { lat: 48.972838, lng: 20.855872 },
    { lat: 48.972381, lng: 20.854837 },
    { lat: 48.971081, lng: 20.852277 },
    { lat: 48.970714, lng: 20.851017 },
    { lat: 48.970699, lng: 20.850965 },
    { lat: 48.970636, lng: 20.85075 },
    { lat: 48.970596, lng: 20.849692 },
    { lat: 48.970243, lng: 20.848368 },
    { lat: 48.970661, lng: 20.847028 },
    { lat: 48.97057, lng: 20.846477 },
    { lat: 48.97045, lng: 20.845749 },
    { lat: 48.970428, lng: 20.844676 },
    { lat: 48.970397, lng: 20.843933 },
    { lat: 48.97038, lng: 20.84387 },
    { lat: 48.969992, lng: 20.842573 },
    { lat: 48.969809, lng: 20.841718 },
    { lat: 48.9694787, lng: 20.8398746 },
    { lat: 48.9691012, lng: 20.8399263 },
    { lat: 48.9688715, lng: 20.8385476 },
    { lat: 48.9687291, lng: 20.8381709 },
    { lat: 48.968324, lng: 20.8374521 },
    { lat: 48.9674239, lng: 20.836218 },
    { lat: 48.9673167, lng: 20.8361448 },
    { lat: 48.9669877, lng: 20.8361326 },
    { lat: 48.9668544, lng: 20.835528 },
    { lat: 48.966368, lng: 20.8359191 },
    { lat: 48.966187, lng: 20.8356007 },
    { lat: 48.965904, lng: 20.8352584 },
    { lat: 48.9653837, lng: 20.8348289 },
    { lat: 48.9653264, lng: 20.8346966 },
    { lat: 48.9653482, lng: 20.8341189 },
    { lat: 48.9649194, lng: 20.834126 },
    { lat: 48.9640249, lng: 20.8343976 },
    { lat: 48.9633858, lng: 20.8344357 },
    { lat: 48.9632691, lng: 20.8343479 },
    { lat: 48.9627998, lng: 20.834412 },
    { lat: 48.9617131, lng: 20.8349259 },
    { lat: 48.9612353, lng: 20.8337616 },
    { lat: 48.9611884, lng: 20.8336457 },
  ],
  Letanovce: [
    { lat: 48.999123, lng: 20.442738 },
    { lat: 48.998756, lng: 20.442216 },
    { lat: 48.998746, lng: 20.442201 },
    { lat: 48.998737, lng: 20.442185 },
    { lat: 48.998474, lng: 20.44172 },
    { lat: 48.998318, lng: 20.441412 },
    { lat: 48.998091, lng: 20.440924 },
    { lat: 48.998024, lng: 20.440775 },
    { lat: 48.99793, lng: 20.440475 },
    { lat: 48.997849, lng: 20.440131 },
    { lat: 48.997738, lng: 20.438927 },
    { lat: 48.997718, lng: 20.43868 },
    { lat: 48.997716, lng: 20.438457 },
    { lat: 48.997661, lng: 20.438042 },
    { lat: 48.997286, lng: 20.436469 },
    { lat: 48.997192, lng: 20.436134 },
    { lat: 48.997003, lng: 20.435589 },
    { lat: 48.996908, lng: 20.435207 },
    { lat: 48.996718, lng: 20.434493 },
    { lat: 48.996589, lng: 20.433901 },
    { lat: 48.996477, lng: 20.433213 },
    { lat: 48.996473, lng: 20.433186 },
    { lat: 48.994885, lng: 20.4334253 },
    { lat: 48.9945703, lng: 20.4336181 },
    { lat: 48.9930007, lng: 20.4349581 },
    { lat: 48.9929204, lng: 20.4349185 },
    { lat: 48.9926383, lng: 20.4343949 },
    { lat: 48.9923294, lng: 20.4336704 },
    { lat: 48.9922037, lng: 20.4335595 },
    { lat: 48.9911445, lng: 20.4338639 },
    { lat: 48.9895591, lng: 20.4340748 },
    { lat: 48.9892113, lng: 20.4342837 },
    { lat: 48.9880039, lng: 20.4339148 },
    { lat: 48.9863695, lng: 20.4330656 },
    { lat: 48.9859215, lng: 20.4329785 },
    { lat: 48.9850346, lng: 20.4326308 },
    { lat: 48.9841154, lng: 20.4323562 },
    { lat: 48.983539, lng: 20.432089 },
    { lat: 48.982828, lng: 20.4313621 },
    { lat: 48.9808656, lng: 20.4316059 },
    { lat: 48.9787944, lng: 20.4323317 },
    { lat: 48.978285, lng: 20.4324375 },
    { lat: 48.9777449, lng: 20.4324496 },
    { lat: 48.9771271, lng: 20.4321212 },
    { lat: 48.9763765, lng: 20.4317221 },
    { lat: 48.9747161, lng: 20.4317636 },
    { lat: 48.9738186, lng: 20.4314015 },
    { lat: 48.9709316, lng: 20.4310469 },
    { lat: 48.970316, lng: 20.4306664 },
    { lat: 48.9697307, lng: 20.4301634 },
    { lat: 48.9687119, lng: 20.4303514 },
    { lat: 48.9669776, lng: 20.4301933 },
    { lat: 48.9648369, lng: 20.4301556 },
    { lat: 48.9641095, lng: 20.4298261 },
    { lat: 48.9626346, lng: 20.4287047 },
    { lat: 48.9616756, lng: 20.4290358 },
    { lat: 48.9601143, lng: 20.4293155 },
    { lat: 48.9591396, lng: 20.4295644 },
    { lat: 48.9574944, lng: 20.4296147 },
    { lat: 48.9572442, lng: 20.4296999 },
    { lat: 48.9568432, lng: 20.4301692 },
    { lat: 48.9563033, lng: 20.4304238 },
    { lat: 48.9557767, lng: 20.4308227 },
    { lat: 48.9553833, lng: 20.4308076 },
    { lat: 48.955203, lng: 20.4306582 },
    { lat: 48.955014, lng: 20.4306335 },
    { lat: 48.9536417, lng: 20.430667 },
    { lat: 48.9535001, lng: 20.430467 },
    { lat: 48.9533302, lng: 20.4304109 },
    { lat: 48.9531665, lng: 20.4301898 },
    { lat: 48.953131, lng: 20.4299923 },
    { lat: 48.9531652, lng: 20.4296496 },
    { lat: 48.9532777, lng: 20.4292927 },
    { lat: 48.9533277, lng: 20.4289433 },
    { lat: 48.9532501, lng: 20.428652 },
    { lat: 48.9529917, lng: 20.4283403 },
    { lat: 48.9527182, lng: 20.4281636 },
    { lat: 48.9526486, lng: 20.4280518 },
    { lat: 48.9525923, lng: 20.4274566 },
    { lat: 48.9527269, lng: 20.4269985 },
    { lat: 48.9527701, lng: 20.4269553 },
    { lat: 48.9528223, lng: 20.4269034 },
    { lat: 48.953433, lng: 20.4269726 },
    { lat: 48.9535743, lng: 20.4266915 },
    { lat: 48.9536474, lng: 20.4261499 },
    { lat: 48.9535944, lng: 20.424964 },
    { lat: 48.9533827, lng: 20.42419 },
    { lat: 48.9532236, lng: 20.4233796 },
    { lat: 48.952842, lng: 20.4221347 },
    { lat: 48.9529643, lng: 20.421769 },
    { lat: 48.9530588, lng: 20.4211448 },
    { lat: 48.9511702, lng: 20.4199176 },
    { lat: 48.9449726, lng: 20.4143123 },
    { lat: 48.942061, lng: 20.4115952 },
    { lat: 48.9422826, lng: 20.4094872 },
    { lat: 48.9405751, lng: 20.4096499 },
    { lat: 48.9401977, lng: 20.4095 },
    { lat: 48.9397991, lng: 20.4096155 },
    { lat: 48.9395811, lng: 20.4097747 },
    { lat: 48.9394308, lng: 20.4097879 },
    { lat: 48.9393585, lng: 20.4097237 },
    { lat: 48.9393001, lng: 20.4095222 },
    { lat: 48.9393764, lng: 20.4089234 },
    { lat: 48.9393161, lng: 20.4087274 },
    { lat: 48.9391068, lng: 20.408437 },
    { lat: 48.9388498, lng: 20.408374 },
    { lat: 48.938543, lng: 20.4079117 },
    { lat: 48.9384438, lng: 20.4078799 },
    { lat: 48.9383887, lng: 20.4077651 },
    { lat: 48.9382579, lng: 20.40773 },
    { lat: 48.9381831, lng: 20.4074841 },
    { lat: 48.9380851, lng: 20.4074798 },
    { lat: 48.9380043, lng: 20.4071943 },
    { lat: 48.9379008, lng: 20.4070787 },
    { lat: 48.9376071, lng: 20.4069605 },
    { lat: 48.9375359, lng: 20.4067773 },
    { lat: 48.9369708, lng: 20.4062517 },
    { lat: 48.9365719, lng: 20.4062398 },
    { lat: 48.9361827, lng: 20.4057787 },
    { lat: 48.9359413, lng: 20.4056172 },
    { lat: 48.9356982, lng: 20.405299 },
    { lat: 48.9351984, lng: 20.4049773 },
    { lat: 48.9347224, lng: 20.4040962 },
    { lat: 48.9344782, lng: 20.4035389 },
    { lat: 48.9339468, lng: 20.4030971 },
    { lat: 48.933903, lng: 20.4029276 },
    { lat: 48.9338497, lng: 20.4029462 },
    { lat: 48.9336692, lng: 20.4024628 },
    { lat: 48.9336183, lng: 20.4021629 },
    { lat: 48.9335415, lng: 20.4019795 },
    { lat: 48.9334501, lng: 20.4019456 },
    { lat: 48.9334155, lng: 20.401775 },
    { lat: 48.9334547, lng: 20.4015896 },
    { lat: 48.933407, lng: 20.401467 },
    { lat: 48.9333191, lng: 20.4007513 },
    { lat: 48.9329408, lng: 20.4004898 },
    { lat: 48.9328817, lng: 20.4002647 },
    { lat: 48.9326481, lng: 20.3998989 },
    { lat: 48.9324059, lng: 20.3998091 },
    { lat: 48.9321619, lng: 20.3998156 },
    { lat: 48.9320131, lng: 20.3996385 },
    { lat: 48.9317864, lng: 20.3995341 },
    { lat: 48.9316511, lng: 20.3997111 },
    { lat: 48.9314256, lng: 20.399645 },
    { lat: 48.9312576, lng: 20.3994041 },
    { lat: 48.9310737, lng: 20.3993877 },
    { lat: 48.9308777, lng: 20.3989762 },
    { lat: 48.9307724, lng: 20.3989615 },
    { lat: 48.930611, lng: 20.3987469 },
    { lat: 48.9305226, lng: 20.3987292 },
    { lat: 48.9303518, lng: 20.3983281 },
    { lat: 48.930195, lng: 20.3982327 },
    { lat: 48.9300201, lng: 20.3975995 },
    { lat: 48.9300115, lng: 20.39711 },
    { lat: 48.9297322, lng: 20.3964879 },
    { lat: 48.9293676, lng: 20.3962009 },
    { lat: 48.9284554, lng: 20.3959599 },
    { lat: 48.9279105, lng: 20.3960186 },
    { lat: 48.9275562, lng: 20.3959108 },
    { lat: 48.9270531, lng: 20.3958914 },
    { lat: 48.926351, lng: 20.3959614 },
    { lat: 48.9260812, lng: 20.396065 },
    { lat: 48.9253988, lng: 20.3957351 },
    { lat: 48.9253153, lng: 20.395823 },
    { lat: 48.9249, lng: 20.3957106 },
    { lat: 48.9232491, lng: 20.3949961 },
    { lat: 48.9225366, lng: 20.3948257 },
    { lat: 48.9225112, lng: 20.3948398 },
    { lat: 48.9228457, lng: 20.3961398 },
    { lat: 48.9229521, lng: 20.3969797 },
    { lat: 48.9208567, lng: 20.3976885 },
    { lat: 48.9198231, lng: 20.3985739 },
    { lat: 48.9176815, lng: 20.3970448 },
    { lat: 48.9194233, lng: 20.3951544 },
    { lat: 48.9195351, lng: 20.3941604 },
    { lat: 48.9194117, lng: 20.3936424 },
    { lat: 48.919093, lng: 20.3932312 },
    { lat: 48.9186307, lng: 20.3931226 },
    { lat: 48.9184036, lng: 20.3932181 },
    { lat: 48.9176498, lng: 20.3931767 },
    { lat: 48.9172253, lng: 20.393059 },
    { lat: 48.9153665, lng: 20.3929498 },
    { lat: 48.9136972, lng: 20.3922243 },
    { lat: 48.91126, lng: 20.391559 },
    { lat: 48.909418, lng: 20.391091 },
    { lat: 48.908881, lng: 20.390926 },
    { lat: 48.908969, lng: 20.391195 },
    { lat: 48.908676, lng: 20.393213 },
    { lat: 48.908461, lng: 20.394609 },
    { lat: 48.90837, lng: 20.395585 },
    { lat: 48.908263, lng: 20.396993 },
    { lat: 48.9082865, lng: 20.3980494 },
    { lat: 48.9082882, lng: 20.3986831 },
    { lat: 48.908217, lng: 20.400212 },
    { lat: 48.908113, lng: 20.4027218 },
    { lat: 48.907955, lng: 20.403935 },
    { lat: 48.907888, lng: 20.404306 },
    { lat: 48.907752, lng: 20.405413 },
    { lat: 48.90736, lng: 20.407364 },
    { lat: 48.907339, lng: 20.407801 },
    { lat: 48.907392, lng: 20.408539 },
    { lat: 48.907934, lng: 20.411669 },
    { lat: 48.908428, lng: 20.414503 },
    { lat: 48.908376, lng: 20.414902 },
    { lat: 48.9083, lng: 20.414997 },
    { lat: 48.90828, lng: 20.415042 },
    { lat: 48.9085188, lng: 20.4151376 },
    { lat: 48.9087167, lng: 20.4155698 },
    { lat: 48.9088055, lng: 20.415658 },
    { lat: 48.9090496, lng: 20.4156789 },
    { lat: 48.9093375, lng: 20.4162332 },
    { lat: 48.9096567, lng: 20.4163373 },
    { lat: 48.910012, lng: 20.4166223 },
    { lat: 48.9104762, lng: 20.4172199 },
    { lat: 48.9110827, lng: 20.4174768 },
    { lat: 48.9116517, lng: 20.4179586 },
    { lat: 48.9118336, lng: 20.4179154 },
    { lat: 48.9119518, lng: 20.4179763 },
    { lat: 48.9120294, lng: 20.4181181 },
    { lat: 48.9120524, lng: 20.4183062 },
    { lat: 48.9121314, lng: 20.4184049 },
    { lat: 48.9126544, lng: 20.4188016 },
    { lat: 48.9127784, lng: 20.4188029 },
    { lat: 48.9129704, lng: 20.4186153 },
    { lat: 48.9134727, lng: 20.4185907 },
    { lat: 48.9137444, lng: 20.4187731 },
    { lat: 48.914151, lng: 20.4187762 },
    { lat: 48.9145147, lng: 20.4189259 },
    { lat: 48.9148335, lng: 20.4191933 },
    { lat: 48.9152774, lng: 20.4194398 },
    { lat: 48.9155917, lng: 20.4198308 },
    { lat: 48.9158294, lng: 20.419863 },
    { lat: 48.9162118, lng: 20.4200608 },
    { lat: 48.9164055, lng: 20.4200299 },
    { lat: 48.9167321, lng: 20.4201492 },
    { lat: 48.9169438, lng: 20.4200952 },
    { lat: 48.9172139, lng: 20.4202667 },
    { lat: 48.9174588, lng: 20.4202796 },
    { lat: 48.9177542, lng: 20.42059 },
    { lat: 48.9179059, lng: 20.4210865 },
    { lat: 48.9180629, lng: 20.4213066 },
    { lat: 48.9184897, lng: 20.4214536 },
    { lat: 48.9188336, lng: 20.4214948 },
    { lat: 48.9191287, lng: 20.4218517 },
    { lat: 48.9191592, lng: 20.4221526 },
    { lat: 48.9192994, lng: 20.422401 },
    { lat: 48.9196355, lng: 20.4226835 },
    { lat: 48.9197304, lng: 20.4227231 },
    { lat: 48.9198958, lng: 20.4225734 },
    { lat: 48.9203897, lng: 20.4226816 },
    { lat: 48.9205824, lng: 20.4231023 },
    { lat: 48.9207363, lng: 20.4232406 },
    { lat: 48.920991, lng: 20.4233362 },
    { lat: 48.9212348, lng: 20.423264 },
    { lat: 48.9216358, lng: 20.4235598 },
    { lat: 48.9219522, lng: 20.4240065 },
    { lat: 48.9223095, lng: 20.4240462 },
    { lat: 48.9225925, lng: 20.423938 },
    { lat: 48.9237399, lng: 20.4244149 },
    { lat: 48.9239974, lng: 20.4244618 },
    { lat: 48.9247844, lng: 20.4251722 },
    { lat: 48.9249075, lng: 20.4252151 },
    { lat: 48.925196, lng: 20.4250377 },
    { lat: 48.9256604, lng: 20.4252009 },
    { lat: 48.9258961, lng: 20.4255699 },
    { lat: 48.9259881, lng: 20.4258297 },
    { lat: 48.9262137, lng: 20.4259851 },
    { lat: 48.9263421, lng: 20.4259301 },
    { lat: 48.9265038, lng: 20.4258272 },
    { lat: 48.9267828, lng: 20.4255243 },
    { lat: 48.9276257, lng: 20.425871 },
    { lat: 48.9285465, lng: 20.4254747 },
    { lat: 48.9289892, lng: 20.4255717 },
    { lat: 48.9292931, lng: 20.4257127 },
    { lat: 48.9293826, lng: 20.4258467 },
    { lat: 48.929941, lng: 20.4257879 },
    { lat: 48.9301474, lng: 20.4255876 },
    { lat: 48.9302662, lng: 20.4256044 },
    { lat: 48.9304267, lng: 20.4258282 },
    { lat: 48.9307644, lng: 20.4257922 },
    { lat: 48.9309583, lng: 20.4258598 },
    { lat: 48.9318052, lng: 20.4257188 },
    { lat: 48.9319009, lng: 20.4255371 },
    { lat: 48.9321443, lng: 20.4255452 },
    { lat: 48.9324236, lng: 20.4256633 },
    { lat: 48.9333086, lng: 20.4254563 },
    { lat: 48.9341276, lng: 20.4255273 },
    { lat: 48.9342231, lng: 20.4254946 },
    { lat: 48.934424, lng: 20.4252116 },
    { lat: 48.9348158, lng: 20.4252623 },
    { lat: 48.9353745, lng: 20.4254505 },
    { lat: 48.9355558, lng: 20.4256567 },
    { lat: 48.9356891, lng: 20.425967 },
    { lat: 48.9357315, lng: 20.4262824 },
    { lat: 48.9358236, lng: 20.4264534 },
    { lat: 48.9366824, lng: 20.4265345 },
    { lat: 48.9368269, lng: 20.4267235 },
    { lat: 48.9368639, lng: 20.4271386 },
    { lat: 48.9370431, lng: 20.4274084 },
    { lat: 48.9372281, lng: 20.4279191 },
    { lat: 48.9377653, lng: 20.4285788 },
    { lat: 48.9377501, lng: 20.4286492 },
    { lat: 48.9380406, lng: 20.4292184 },
    { lat: 48.9381101, lng: 20.4294777 },
    { lat: 48.9383515, lng: 20.43127 },
    { lat: 48.9386188, lng: 20.4321743 },
    { lat: 48.9389544, lng: 20.4324988 },
    { lat: 48.9390285, lng: 20.4327058 },
    { lat: 48.9390785, lng: 20.4332842 },
    { lat: 48.9390474, lng: 20.4334721 },
    { lat: 48.9390862, lng: 20.4336059 },
    { lat: 48.9395666, lng: 20.434516 },
    { lat: 48.9398296, lng: 20.4347143 },
    { lat: 48.9403357, lng: 20.4348128 },
    { lat: 48.9405013, lng: 20.4349932 },
    { lat: 48.9409714, lng: 20.4357827 },
    { lat: 48.9411986, lng: 20.4364058 },
    { lat: 48.9411141, lng: 20.4366965 },
    { lat: 48.940926, lng: 20.436964 },
    { lat: 48.9407346, lng: 20.437562 },
    { lat: 48.9407407, lng: 20.4377527 },
    { lat: 48.9409396, lng: 20.4386344 },
    { lat: 48.9410693, lng: 20.4387564 },
    { lat: 48.9411244, lng: 20.4397383 },
    { lat: 48.941253, lng: 20.4404893 },
    { lat: 48.9413871, lng: 20.4407606 },
    { lat: 48.9415125, lng: 20.4408324 },
    { lat: 48.9415204, lng: 20.4408908 },
    { lat: 48.9414086, lng: 20.4411534 },
    { lat: 48.9412538, lng: 20.4419706 },
    { lat: 48.9411447, lng: 20.4420618 },
    { lat: 48.9410283, lng: 20.4423219 },
    { lat: 48.9407703, lng: 20.4426442 },
    { lat: 48.9406692, lng: 20.443129 },
    { lat: 48.940268, lng: 20.4438932 },
    { lat: 48.9402577, lng: 20.4442451 },
    { lat: 48.9401422, lng: 20.4444969 },
    { lat: 48.9400082, lng: 20.4454226 },
    { lat: 48.9401443, lng: 20.4461912 },
    { lat: 48.9402428, lng: 20.4464759 },
    { lat: 48.9406641, lng: 20.4469926 },
    { lat: 48.9410169, lng: 20.4473259 },
    { lat: 48.9411068, lng: 20.4475322 },
    { lat: 48.9412366, lng: 20.4480457 },
    { lat: 48.9412468, lng: 20.4491533 },
    { lat: 48.9412792, lng: 20.4495051 },
    { lat: 48.9413774, lng: 20.4498078 },
    { lat: 48.9413624, lng: 20.4499778 },
    { lat: 48.9414406, lng: 20.4504377 },
    { lat: 48.9416613, lng: 20.4507394 },
    { lat: 48.9417864, lng: 20.4510101 },
    { lat: 48.9419523, lng: 20.4517686 },
    { lat: 48.9422319, lng: 20.4525516 },
    { lat: 48.9424376, lng: 20.4528436 },
    { lat: 48.9426509, lng: 20.4532774 },
    { lat: 48.9431755, lng: 20.4535997 },
    { lat: 48.9432623, lng: 20.4537541 },
    { lat: 48.9437453, lng: 20.4557223 },
    { lat: 48.9443321, lng: 20.457314 },
    { lat: 48.9446693, lng: 20.4570003 },
    { lat: 48.9448742, lng: 20.4569424 },
    { lat: 48.9450245, lng: 20.4567732 },
    { lat: 48.9450618, lng: 20.4564458 },
    { lat: 48.9460798, lng: 20.4549296 },
    { lat: 48.9463578, lng: 20.4548367 },
    { lat: 48.9467508, lng: 20.4550157 },
    { lat: 48.9471569, lng: 20.4553054 },
    { lat: 48.9475171, lng: 20.45536 },
    { lat: 48.9476124, lng: 20.4555588 },
    { lat: 48.947866, lng: 20.4558334 },
    { lat: 48.9480336, lng: 20.4558919 },
    { lat: 48.9482491, lng: 20.4557837 },
    { lat: 48.9484973, lng: 20.4550174 },
    { lat: 48.9489186, lng: 20.4549969 },
    { lat: 48.9490569, lng: 20.4548731 },
    { lat: 48.9490841, lng: 20.4547877 },
    { lat: 48.9490222, lng: 20.4541901 },
    { lat: 48.9487193, lng: 20.453322 },
    { lat: 48.9486243, lng: 20.4529222 },
    { lat: 48.9487732, lng: 20.4522161 },
    { lat: 48.9490875, lng: 20.4515031 },
    { lat: 48.9494898, lng: 20.4513901 },
    { lat: 48.9499963, lng: 20.4508187 },
    { lat: 48.9500723, lng: 20.450801 },
    { lat: 48.9503838, lng: 20.4510022 },
    { lat: 48.9508718, lng: 20.4511041 },
    { lat: 48.9511158, lng: 20.4513627 },
    { lat: 48.9518518, lng: 20.4519224 },
    { lat: 48.9524057, lng: 20.4518005 },
    { lat: 48.9526216, lng: 20.4515814 },
    { lat: 48.9526899, lng: 20.4513729 },
    { lat: 48.9527291, lng: 20.4512529 },
    { lat: 48.9527487, lng: 20.4511932 },
    { lat: 48.952792, lng: 20.450163 },
    { lat: 48.952891, lng: 20.4492047 },
    { lat: 48.9527701, lng: 20.448896 },
    { lat: 48.952548, lng: 20.4486176 },
    { lat: 48.9521193, lng: 20.4483224 },
    { lat: 48.9520596, lng: 20.4480535 },
    { lat: 48.9521601, lng: 20.4472595 },
    { lat: 48.9527666, lng: 20.4469495 },
    { lat: 48.9531712, lng: 20.4465758 },
    { lat: 48.9533696, lng: 20.4461927 },
    { lat: 48.9533929, lng: 20.4458213 },
    { lat: 48.9533625, lng: 20.4455542 },
    { lat: 48.9531237, lng: 20.4449857 },
    { lat: 48.9528239, lng: 20.4436948 },
    { lat: 48.9528546, lng: 20.4428065 },
    { lat: 48.9532596, lng: 20.4423535 },
    { lat: 48.9535843, lng: 20.442456 },
    { lat: 48.9539305, lng: 20.4426752 },
    { lat: 48.9545132, lng: 20.4428118 },
    { lat: 48.9545964, lng: 20.442542 },
    { lat: 48.9545179, lng: 20.4419229 },
    { lat: 48.9547034, lng: 20.4417688 },
    { lat: 48.9550213, lng: 20.4411536 },
    { lat: 48.9554223, lng: 20.4408098 },
    { lat: 48.955453, lng: 20.4407777 },
    { lat: 48.955638, lng: 20.4405775 },
    { lat: 48.9560448, lng: 20.4403152 },
    { lat: 48.9565313, lng: 20.4397866 },
    { lat: 48.9567724, lng: 20.4396968 },
    { lat: 48.9569345, lng: 20.4397841 },
    { lat: 48.957095, lng: 20.4397418 },
    { lat: 48.9575148, lng: 20.4398575 },
    { lat: 48.9576988, lng: 20.4398117 },
    { lat: 48.9583392, lng: 20.4403378 },
    { lat: 48.9584515, lng: 20.4405673 },
    { lat: 48.9586191, lng: 20.4406842 },
    { lat: 48.9591965, lng: 20.4405898 },
    { lat: 48.9592567, lng: 20.4408092 },
    { lat: 48.9596252, lng: 20.4408212 },
    { lat: 48.9596467, lng: 20.4410339 },
    { lat: 48.9597655, lng: 20.4410759 },
    { lat: 48.9600764, lng: 20.4409607 },
    { lat: 48.9603549, lng: 20.4410876 },
    { lat: 48.9602916, lng: 20.4414435 },
    { lat: 48.9604201, lng: 20.4415649 },
    { lat: 48.9604157, lng: 20.4416559 },
    { lat: 48.9605524, lng: 20.4419063 },
    { lat: 48.9607236, lng: 20.4417595 },
    { lat: 48.9607402, lng: 20.4419312 },
    { lat: 48.9606914, lng: 20.4421017 },
    { lat: 48.9607566, lng: 20.4424848 },
    { lat: 48.9609473, lng: 20.442499 },
    { lat: 48.9610195, lng: 20.4427098 },
    { lat: 48.960968, lng: 20.4428336 },
    { lat: 48.960987, lng: 20.442919 },
    { lat: 48.9611375, lng: 20.4429686 },
    { lat: 48.9612061, lng: 20.4431465 },
    { lat: 48.9611896, lng: 20.4432798 },
    { lat: 48.9610635, lng: 20.4433395 },
    { lat: 48.9609176, lng: 20.4435872 },
    { lat: 48.9610095, lng: 20.4438781 },
    { lat: 48.9609862, lng: 20.4442087 },
    { lat: 48.9610185, lng: 20.4445033 },
    { lat: 48.9610818, lng: 20.4446554 },
    { lat: 48.9610953, lng: 20.4449552 },
    { lat: 48.9611928, lng: 20.4452072 },
    { lat: 48.9611762, lng: 20.4453982 },
    { lat: 48.9615691, lng: 20.4457259 },
    { lat: 48.9618559, lng: 20.4461366 },
    { lat: 48.9627901, lng: 20.4483343 },
    { lat: 48.962903, lng: 20.4493887 },
    { lat: 48.9630835, lng: 20.4504401 },
    { lat: 48.9635408, lng: 20.4522676 },
    { lat: 48.9637411, lng: 20.4556701 },
    { lat: 48.9638809, lng: 20.4569647 },
    { lat: 48.9639614, lng: 20.4571283 },
    { lat: 48.9645025, lng: 20.4577138 },
    { lat: 48.9660535, lng: 20.4591361 },
    { lat: 48.9679151, lng: 20.4604356 },
    { lat: 48.9703318, lng: 20.4617895 },
    { lat: 48.9700934, lng: 20.4628795 },
    { lat: 48.9709243, lng: 20.4633848 },
    { lat: 48.9710248, lng: 20.4636632 },
    { lat: 48.971748, lng: 20.4644853 },
    { lat: 48.9742739, lng: 20.4687514 },
    { lat: 48.9752435, lng: 20.4702584 },
    { lat: 48.9763774, lng: 20.471849 },
    { lat: 48.9754598, lng: 20.4735356 },
    { lat: 48.9750671, lng: 20.4740799 },
    { lat: 48.9743978, lng: 20.4747292 },
    { lat: 48.9746228, lng: 20.474847 },
    { lat: 48.9756899, lng: 20.4751349 },
    { lat: 48.9765152, lng: 20.4752596 },
    { lat: 48.9768764, lng: 20.4752545 },
    { lat: 48.9768853, lng: 20.4771945 },
    { lat: 48.977004, lng: 20.477175 },
    { lat: 48.977005, lng: 20.477174 },
    { lat: 48.977347, lng: 20.47705 },
    { lat: 48.977592, lng: 20.47696 },
    { lat: 48.977833, lng: 20.476876 },
    { lat: 48.977913, lng: 20.476861 },
    { lat: 48.978114, lng: 20.476818 },
    { lat: 48.978468, lng: 20.476734 },
    { lat: 48.978469, lng: 20.476734 },
    { lat: 48.978597, lng: 20.476689 },
    { lat: 48.978788, lng: 20.476622 },
    { lat: 48.978971, lng: 20.476557 },
    { lat: 48.979097, lng: 20.476521 },
    { lat: 48.979161, lng: 20.476503 },
    { lat: 48.979452, lng: 20.476423 },
    { lat: 48.979657, lng: 20.476366 },
    { lat: 48.979916, lng: 20.47626 },
    { lat: 48.980376, lng: 20.476241 },
    { lat: 48.980781, lng: 20.47627 },
    { lat: 48.981524, lng: 20.476325 },
    { lat: 48.981963, lng: 20.477248 },
    { lat: 48.982719, lng: 20.478751 },
    { lat: 48.982731, lng: 20.478777 },
    { lat: 48.983218, lng: 20.478613 },
    { lat: 48.98348, lng: 20.478549 },
    { lat: 48.983669, lng: 20.478519 },
    { lat: 48.984016, lng: 20.478449 },
    { lat: 48.98453, lng: 20.47835 },
    { lat: 48.984956, lng: 20.478279 },
    { lat: 48.985089, lng: 20.478257 },
    { lat: 48.98542, lng: 20.478154 },
    { lat: 48.985526, lng: 20.478139 },
    { lat: 48.985633, lng: 20.478057 },
    { lat: 48.985853, lng: 20.477917 },
    { lat: 48.985642, lng: 20.477428 },
    { lat: 48.986435, lng: 20.476375 },
    { lat: 48.986519, lng: 20.476241 },
    { lat: 48.986733, lng: 20.475896 },
    { lat: 48.987107, lng: 20.475296 },
    { lat: 48.987351, lng: 20.474927 },
    { lat: 48.987661, lng: 20.474522 },
    { lat: 48.987877, lng: 20.47425 },
    { lat: 48.987936, lng: 20.474175 },
    { lat: 48.988269, lng: 20.473755 },
    { lat: 48.988436, lng: 20.473569 },
    { lat: 48.988685, lng: 20.473291 },
    { lat: 48.988862, lng: 20.473093 },
    { lat: 48.989019, lng: 20.472898 },
    { lat: 48.989078, lng: 20.472924 },
    { lat: 48.989128, lng: 20.472923 },
    { lat: 48.989317, lng: 20.472829 },
    { lat: 48.989345, lng: 20.472744 },
    { lat: 48.989335, lng: 20.472653 },
    { lat: 48.989394, lng: 20.47245 },
    { lat: 48.989423, lng: 20.47235 },
    { lat: 48.989653, lng: 20.472124 },
    { lat: 48.989875, lng: 20.471828 },
    { lat: 48.990397, lng: 20.471161 },
    { lat: 48.990688, lng: 20.470791 },
    { lat: 48.990876, lng: 20.470551 },
    { lat: 48.991095, lng: 20.470292 },
    { lat: 48.99137, lng: 20.46997 },
    { lat: 48.991619, lng: 20.46968 },
    { lat: 48.991929, lng: 20.469315 },
    { lat: 48.992168, lng: 20.469035 },
    { lat: 48.992503, lng: 20.468622 },
    { lat: 48.992513, lng: 20.468609 },
    { lat: 48.992893, lng: 20.46827 },
    { lat: 48.993271, lng: 20.46785 },
    { lat: 48.993528, lng: 20.467612 },
    { lat: 48.993529, lng: 20.467612 },
    { lat: 48.993884, lng: 20.467276 },
    { lat: 48.994048, lng: 20.467118 },
    { lat: 48.994266, lng: 20.466908 },
    { lat: 48.994099, lng: 20.466549 },
    { lat: 48.993685, lng: 20.465744 },
    { lat: 48.99337, lng: 20.465186 },
    { lat: 48.993085, lng: 20.464627 },
    { lat: 48.992569, lng: 20.463719 },
    { lat: 48.992367, lng: 20.463256 },
    { lat: 48.99173, lng: 20.461813 },
    { lat: 48.991295, lng: 20.460836 },
    { lat: 48.990788, lng: 20.459745 },
    { lat: 48.990435, lng: 20.459004 },
    { lat: 48.99027, lng: 20.458685 },
    { lat: 48.989983, lng: 20.458176 },
    { lat: 48.989838, lng: 20.457984 },
    { lat: 48.989487, lng: 20.457623 },
    { lat: 48.989271, lng: 20.457417 },
    { lat: 48.98919, lng: 20.457363 },
    { lat: 48.989064, lng: 20.457218 },
    { lat: 48.988993, lng: 20.456959 },
    { lat: 48.988733, lng: 20.45582 },
    { lat: 48.988746, lng: 20.45581 },
    { lat: 48.989394, lng: 20.455363 },
    { lat: 48.989429, lng: 20.455339 },
    { lat: 48.98944, lng: 20.455331 },
    { lat: 48.989454, lng: 20.455459 },
    { lat: 48.989474, lng: 20.455575 },
    { lat: 48.98948, lng: 20.455675 },
    { lat: 48.989507, lng: 20.455698 },
    { lat: 48.989527, lng: 20.455766 },
    { lat: 48.989583, lng: 20.455797 },
    { lat: 48.989664, lng: 20.455777 },
    { lat: 48.989776, lng: 20.45576 },
    { lat: 48.989862, lng: 20.455775 },
    { lat: 48.989863, lng: 20.455803 },
    { lat: 48.990003, lng: 20.456061 },
    { lat: 48.990115, lng: 20.456068 },
    { lat: 48.990201, lng: 20.456003 },
    { lat: 48.990251, lng: 20.45591 },
    { lat: 48.990387, lng: 20.455725 },
    { lat: 48.990362, lng: 20.455526 },
    { lat: 48.990358, lng: 20.4555 },
    { lat: 48.990428, lng: 20.45548 },
    { lat: 48.990464, lng: 20.455497 },
    { lat: 48.990528, lng: 20.455634 },
    { lat: 48.990526, lng: 20.455676 },
    { lat: 48.990523, lng: 20.455754 },
    { lat: 48.990614, lng: 20.455828 },
    { lat: 48.990883, lng: 20.455825 },
    { lat: 48.991001, lng: 20.455733 },
    { lat: 48.991027, lng: 20.455714 },
    { lat: 48.991054, lng: 20.455562 },
    { lat: 48.991096, lng: 20.455521 },
    { lat: 48.991207, lng: 20.455409 },
    { lat: 48.991303, lng: 20.455312 },
    { lat: 48.991393, lng: 20.455224 },
    { lat: 48.991583, lng: 20.455037 },
    { lat: 48.991626, lng: 20.454995 },
    { lat: 48.991729, lng: 20.454912 },
    { lat: 48.991789, lng: 20.454857 },
    { lat: 48.991979, lng: 20.454682 },
    { lat: 48.992008, lng: 20.454661 },
    { lat: 48.992085, lng: 20.454605 },
    { lat: 48.992134, lng: 20.454478 },
    { lat: 48.992203, lng: 20.454431 },
    { lat: 48.99222, lng: 20.454377 },
    { lat: 48.992248, lng: 20.454285 },
    { lat: 48.992287, lng: 20.454248 },
    { lat: 48.992371, lng: 20.454033 },
    { lat: 48.992427, lng: 20.453967 },
    { lat: 48.992508, lng: 20.453752 },
    { lat: 48.992527, lng: 20.453718 },
    { lat: 48.992676, lng: 20.453446 },
    { lat: 48.992762, lng: 20.45329 },
    { lat: 48.992845, lng: 20.45314 },
    { lat: 48.992949, lng: 20.452948 },
    { lat: 48.993159, lng: 20.452566 },
    { lat: 48.993467, lng: 20.452096 },
    { lat: 48.993468, lng: 20.452095 },
    { lat: 48.993555, lng: 20.451973 },
    { lat: 48.993632, lng: 20.451856 },
    { lat: 48.993636, lng: 20.451847 },
    { lat: 48.993709, lng: 20.451727 },
    { lat: 48.993713, lng: 20.45172 },
    { lat: 48.993796, lng: 20.451601 },
    { lat: 48.9938, lng: 20.451595 },
    { lat: 48.993874, lng: 20.451469 },
    { lat: 48.993875, lng: 20.451469 },
    { lat: 48.993948, lng: 20.451341 },
    { lat: 48.994025, lng: 20.451243 },
    { lat: 48.994105, lng: 20.451126 },
    { lat: 48.994252, lng: 20.450876 },
    { lat: 48.994332, lng: 20.450735 },
    { lat: 48.994367, lng: 20.450669 },
    { lat: 48.994361, lng: 20.450653 },
    { lat: 48.994428, lng: 20.450583 },
    { lat: 48.994492, lng: 20.450456 },
    { lat: 48.994547, lng: 20.450385 },
    { lat: 48.994651, lng: 20.450256 },
    { lat: 48.994687, lng: 20.4502 },
    { lat: 48.994723, lng: 20.45017 },
    { lat: 48.994745, lng: 20.450121 },
    { lat: 48.994774, lng: 20.45008 },
    { lat: 48.994803, lng: 20.449986 },
    { lat: 48.994825, lng: 20.449937 },
    { lat: 48.994909, lng: 20.449846 },
    { lat: 48.994981, lng: 20.449743 },
    { lat: 48.995036, lng: 20.449625 },
    { lat: 48.995158, lng: 20.449456 },
    { lat: 48.995237, lng: 20.449337 },
    { lat: 48.995345, lng: 20.449197 },
    { lat: 48.995352, lng: 20.449189 },
    { lat: 48.995326, lng: 20.44913 },
    { lat: 48.99543, lng: 20.448982 },
    { lat: 48.99545, lng: 20.449036 },
    { lat: 48.995485, lng: 20.448983 },
    { lat: 48.995527, lng: 20.448946 },
    { lat: 48.995584, lng: 20.448897 },
    { lat: 48.995613, lng: 20.448952 },
    { lat: 48.995637, lng: 20.448964 },
    { lat: 48.995673, lng: 20.448953 },
    { lat: 48.995688, lng: 20.448913 },
    { lat: 48.995701, lng: 20.448853 },
    { lat: 48.995699, lng: 20.448815 },
    { lat: 48.995687, lng: 20.448783 },
    { lat: 48.99566, lng: 20.448703 },
    { lat: 48.99564, lng: 20.448558 },
    { lat: 48.995645, lng: 20.448538 },
    { lat: 48.995744, lng: 20.448466 },
    { lat: 48.995821, lng: 20.448442 },
    { lat: 48.995842, lng: 20.448397 },
    { lat: 48.995857, lng: 20.448381 },
    { lat: 48.995925, lng: 20.448375 },
    { lat: 48.995959, lng: 20.448361 },
    { lat: 48.996002, lng: 20.448338 },
    { lat: 48.996009, lng: 20.448318 },
    { lat: 48.99602, lng: 20.448287 },
    { lat: 48.996089, lng: 20.448236 },
    { lat: 48.996115, lng: 20.448143 },
    { lat: 48.996131, lng: 20.448114 },
    { lat: 48.996183, lng: 20.44806 },
    { lat: 48.996198, lng: 20.448019 },
    { lat: 48.996203, lng: 20.447905 },
    { lat: 48.99621, lng: 20.447861 },
    { lat: 48.996282, lng: 20.447824 },
    { lat: 48.996293, lng: 20.447818 },
    { lat: 48.996304, lng: 20.447731 },
    { lat: 48.996378, lng: 20.447719 },
    { lat: 48.996407, lng: 20.447705 },
    { lat: 48.99642, lng: 20.447651 },
    { lat: 48.996439, lng: 20.447603 },
    { lat: 48.996495, lng: 20.447567 },
    { lat: 48.996558, lng: 20.447504 },
    { lat: 48.996586, lng: 20.44746 },
    { lat: 48.996586, lng: 20.447358 },
    { lat: 48.996598, lng: 20.447301 },
    { lat: 48.996646, lng: 20.447261 },
    { lat: 48.996649, lng: 20.44718 },
    { lat: 48.996664, lng: 20.447104 },
    { lat: 48.996707, lng: 20.447015 },
    { lat: 48.996763, lng: 20.446938 },
    { lat: 48.996814, lng: 20.446845 },
    { lat: 48.996896, lng: 20.446788 },
    { lat: 48.99701, lng: 20.446618 },
    { lat: 48.997149, lng: 20.446449 },
    { lat: 48.997203, lng: 20.446351 },
    { lat: 48.997231, lng: 20.446249 },
    { lat: 48.997252, lng: 20.446211 },
    { lat: 48.997302, lng: 20.446201 },
    { lat: 48.997343, lng: 20.446185 },
    { lat: 48.997398, lng: 20.446079 },
    { lat: 48.997417, lng: 20.446022 },
    { lat: 48.997419, lng: 20.445944 },
    { lat: 48.99737, lng: 20.445837 },
    { lat: 48.997322, lng: 20.445763 },
    { lat: 48.997291, lng: 20.445672 },
    { lat: 48.997264, lng: 20.445485 },
    { lat: 48.997304, lng: 20.445452 },
    { lat: 48.997315, lng: 20.445382 },
    { lat: 48.997456, lng: 20.445324 },
    { lat: 48.997554, lng: 20.445237 },
    { lat: 48.997592, lng: 20.445169 },
    { lat: 48.99771, lng: 20.445068 },
    { lat: 48.997747, lng: 20.444969 },
    { lat: 48.997874, lng: 20.444744 },
    { lat: 48.997894, lng: 20.44477 },
    { lat: 48.998069, lng: 20.445045 },
    { lat: 48.998275, lng: 20.444574 },
    { lat: 48.998804, lng: 20.443341 },
    { lat: 48.999123, lng: 20.442738 },
  ],
  Smižany: [
    { lat: 48.980456, lng: 20.5080775 },
    { lat: 48.9801195, lng: 20.5079297 },
    { lat: 48.9800725, lng: 20.507147 },
    { lat: 48.9801944, lng: 20.5052806 },
    { lat: 48.9795196, lng: 20.5048493 },
    { lat: 48.9792876, lng: 20.5046384 },
    { lat: 48.9788829, lng: 20.5041083 },
    { lat: 48.9784063, lng: 20.5033095 },
    { lat: 48.9779741, lng: 20.5021627 },
    { lat: 48.9776767, lng: 20.5015912 },
    { lat: 48.9770442, lng: 20.5006603 },
    { lat: 48.9769817, lng: 20.5004313 },
    { lat: 48.9767683, lng: 20.5001127 },
    { lat: 48.9761103, lng: 20.4995553 },
    { lat: 48.9756148, lng: 20.4993073 },
    { lat: 48.9756266, lng: 20.4992324 },
    { lat: 48.9747569, lng: 20.4982231 },
    { lat: 48.9743796, lng: 20.4980384 },
    { lat: 48.9742915, lng: 20.4973958 },
    { lat: 48.9740131, lng: 20.4973908 },
    { lat: 48.9739677, lng: 20.4966216 },
    { lat: 48.9728505, lng: 20.4964038 },
    { lat: 48.97256, lng: 20.4964026 },
    { lat: 48.9723179, lng: 20.4952261 },
    { lat: 48.9722396, lng: 20.4946376 },
    { lat: 48.9719516, lng: 20.4944004 },
    { lat: 48.9713436, lng: 20.4940969 },
    { lat: 48.9710941, lng: 20.4938962 },
    { lat: 48.971164, lng: 20.4935709 },
    { lat: 48.9710401, lng: 20.4935679 },
    { lat: 48.9705512, lng: 20.4932588 },
    { lat: 48.9705441, lng: 20.4930423 },
    { lat: 48.970676, lng: 20.492295 },
    { lat: 48.970217, lng: 20.492264 },
    { lat: 48.97004, lng: 20.492251 },
    { lat: 48.969561, lng: 20.492235 },
    { lat: 48.96936, lng: 20.492174 },
    { lat: 48.968798, lng: 20.491566 },
    { lat: 48.968191, lng: 20.490957 },
    { lat: 48.967917, lng: 20.490519 },
    { lat: 48.9679, lng: 20.490456 },
    { lat: 48.967883, lng: 20.490395 },
    { lat: 48.967863, lng: 20.490319 },
    { lat: 48.967799, lng: 20.490142 },
    { lat: 48.9670209, lng: 20.4897751 },
    { lat: 48.966022, lng: 20.4890485 },
    { lat: 48.9653631, lng: 20.4879583 },
    { lat: 48.9650005, lng: 20.4874736 },
    { lat: 48.9643754, lng: 20.4877665 },
    { lat: 48.9637469, lng: 20.4879134 },
    { lat: 48.9634602, lng: 20.4878611 },
    { lat: 48.9630628, lng: 20.4879527 },
    { lat: 48.9616784, lng: 20.4878173 },
    { lat: 48.9615233, lng: 20.4878529 },
    { lat: 48.9613033, lng: 20.4877805 },
    { lat: 48.9605527, lng: 20.48779 },
    { lat: 48.9591895, lng: 20.4873805 },
    { lat: 48.9577258, lng: 20.4870452 },
    { lat: 48.9568344, lng: 20.4867244 },
    { lat: 48.956127, lng: 20.4894555 },
    { lat: 48.9561194, lng: 20.4893709 },
    { lat: 48.9559465, lng: 20.4892325 },
    { lat: 48.9550207, lng: 20.4895297 },
    { lat: 48.9535921, lng: 20.4892322 },
    { lat: 48.9514284, lng: 20.4889361 },
    { lat: 48.951125, lng: 20.4889569 },
    { lat: 48.9511155, lng: 20.4891179 },
    { lat: 48.9501455, lng: 20.4890152 },
    { lat: 48.949786, lng: 20.4892206 },
    { lat: 48.9488678, lng: 20.488868 },
    { lat: 48.9482819, lng: 20.4887717 },
    { lat: 48.9471632, lng: 20.4891145 },
    { lat: 48.9467078, lng: 20.4891254 },
    { lat: 48.946401, lng: 20.4893818 },
    { lat: 48.9462286, lng: 20.4893508 },
    { lat: 48.945523, lng: 20.4895682 },
    { lat: 48.9442707, lng: 20.4905858 },
    { lat: 48.9441639, lng: 20.4905882 },
    { lat: 48.9437786, lng: 20.490164 },
    { lat: 48.9436184, lng: 20.4899425 },
    { lat: 48.9433842, lng: 20.4894605 },
    { lat: 48.9432799, lng: 20.4893194 },
    { lat: 48.9432068, lng: 20.4893844 },
    { lat: 48.9430124, lng: 20.4888811 },
    { lat: 48.9427776, lng: 20.4887466 },
    { lat: 48.9422087, lng: 20.4879568 },
    { lat: 48.9418904, lng: 20.4874194 },
    { lat: 48.9417879, lng: 20.487031 },
    { lat: 48.9417853, lng: 20.4864442 },
    { lat: 48.9419167, lng: 20.4860461 },
    { lat: 48.942032, lng: 20.4851862 },
    { lat: 48.9419975, lng: 20.4849263 },
    { lat: 48.9415116, lng: 20.4836756 },
    { lat: 48.9414372, lng: 20.4828923 },
    { lat: 48.9412976, lng: 20.4825594 },
    { lat: 48.9414185, lng: 20.4821044 },
    { lat: 48.9415378, lng: 20.4816658 },
    { lat: 48.9414596, lng: 20.4811468 },
    { lat: 48.9414775, lng: 20.480936 },
    { lat: 48.9415252, lng: 20.4803725 },
    { lat: 48.9412652, lng: 20.4792882 },
    { lat: 48.9409549, lng: 20.4789092 },
    { lat: 48.9405794, lng: 20.4786548 },
    { lat: 48.940287, lng: 20.4776131 },
    { lat: 48.9401361, lng: 20.4767092 },
    { lat: 48.9401387, lng: 20.4765081 },
    { lat: 48.9403972, lng: 20.4760778 },
    { lat: 48.9404302, lng: 20.4755169 },
    { lat: 48.9408027, lng: 20.4737989 },
    { lat: 48.9405532, lng: 20.473544 },
    { lat: 48.9405043, lng: 20.4732584 },
    { lat: 48.9404218, lng: 20.4731295 },
    { lat: 48.9394354, lng: 20.4718108 },
    { lat: 48.9391378, lng: 20.4715876 },
    { lat: 48.9390104, lng: 20.4711671 },
    { lat: 48.9390529, lng: 20.4704951 },
    { lat: 48.9388233, lng: 20.4701987 },
    { lat: 48.9386298, lng: 20.4703569 },
    { lat: 48.9384312, lng: 20.4703447 },
    { lat: 48.9382517, lng: 20.4696425 },
    { lat: 48.937444, lng: 20.4683708 },
    { lat: 48.9362847, lng: 20.4677843 },
    { lat: 48.9357651, lng: 20.4677684 },
    { lat: 48.9355605, lng: 20.4675992 },
    { lat: 48.9351734, lng: 20.4665211 },
    { lat: 48.9350962, lng: 20.4659981 },
    { lat: 48.9350927, lng: 20.4653638 },
    { lat: 48.9350316, lng: 20.4649078 },
    { lat: 48.9350434, lng: 20.4645924 },
    { lat: 48.9351783, lng: 20.4642899 },
    { lat: 48.9351913, lng: 20.4633193 },
    { lat: 48.935137, lng: 20.4626529 },
    { lat: 48.9349589, lng: 20.4618701 },
    { lat: 48.9346423, lng: 20.4613503 },
    { lat: 48.9344824, lng: 20.4608761 },
    { lat: 48.9339257, lng: 20.4602328 },
    { lat: 48.93376, lng: 20.4595098 },
    { lat: 48.9335851, lng: 20.4590809 },
    { lat: 48.9332897, lng: 20.4580551 },
    { lat: 48.9331136, lng: 20.4570885 },
    { lat: 48.9328512, lng: 20.4566922 },
    { lat: 48.9320144, lng: 20.4557038 },
    { lat: 48.9312561, lng: 20.4544905 },
    { lat: 48.9310882, lng: 20.4543106 },
    { lat: 48.9309626, lng: 20.4538757 },
    { lat: 48.9308825, lng: 20.4531221 },
    { lat: 48.9308692, lng: 20.4524998 },
    { lat: 48.9305538, lng: 20.451633 },
    { lat: 48.9301833, lng: 20.4503159 },
    { lat: 48.9296984, lng: 20.4499967 },
    { lat: 48.9295559, lng: 20.4497731 },
    { lat: 48.9286536, lng: 20.4494319 },
    { lat: 48.928166, lng: 20.4495846 },
    { lat: 48.9277956, lng: 20.4494349 },
    { lat: 48.9276383, lng: 20.4492308 },
    { lat: 48.9268869, lng: 20.4488969 },
    { lat: 48.9263042, lng: 20.4484606 },
    { lat: 48.9261333, lng: 20.4479902 },
    { lat: 48.9257951, lng: 20.4474428 },
    { lat: 48.9257141, lng: 20.4461361 },
    { lat: 48.9255156, lng: 20.4454405 },
    { lat: 48.9251781, lng: 20.4435711 },
    { lat: 48.9251007, lng: 20.4434274 },
    { lat: 48.9247006, lng: 20.4418671 },
    { lat: 48.9245205, lng: 20.441422 },
    { lat: 48.9242311, lng: 20.4395148 },
    { lat: 48.9242914, lng: 20.4391443 },
    { lat: 48.9241941, lng: 20.438774 },
    { lat: 48.9242211, lng: 20.4382395 },
    { lat: 48.92414, lng: 20.4378818 },
    { lat: 48.9241631, lng: 20.4366205 },
    { lat: 48.9240711, lng: 20.4357362 },
    { lat: 48.9240547, lng: 20.4350352 },
    { lat: 48.9242742, lng: 20.433784 },
    { lat: 48.9243599, lng: 20.4336125 },
    { lat: 48.9246001, lng: 20.4326402 },
    { lat: 48.9247656, lng: 20.4308254 },
    { lat: 48.9248821, lng: 20.4302442 },
    { lat: 48.9254293, lng: 20.4292042 },
    { lat: 48.9259277, lng: 20.4285656 },
    { lat: 48.9261302, lng: 20.427908 },
    { lat: 48.9263714, lng: 20.4274447 },
    { lat: 48.9263655, lng: 20.4269576 },
    { lat: 48.9264156, lng: 20.4266184 },
    { lat: 48.9263994, lng: 20.4261031 },
    { lat: 48.9263421, lng: 20.4259301 },
    { lat: 48.9262137, lng: 20.4259851 },
    { lat: 48.9259881, lng: 20.4258297 },
    { lat: 48.9258961, lng: 20.4255699 },
    { lat: 48.9256604, lng: 20.4252009 },
    { lat: 48.925196, lng: 20.4250377 },
    { lat: 48.9249075, lng: 20.4252151 },
    { lat: 48.9247844, lng: 20.4251722 },
    { lat: 48.9239974, lng: 20.4244618 },
    { lat: 48.9237399, lng: 20.4244149 },
    { lat: 48.9225925, lng: 20.423938 },
    { lat: 48.9223095, lng: 20.4240462 },
    { lat: 48.9219522, lng: 20.4240065 },
    { lat: 48.9216358, lng: 20.4235598 },
    { lat: 48.9212348, lng: 20.423264 },
    { lat: 48.920991, lng: 20.4233362 },
    { lat: 48.9207363, lng: 20.4232406 },
    { lat: 48.9205824, lng: 20.4231023 },
    { lat: 48.9203897, lng: 20.4226816 },
    { lat: 48.9198958, lng: 20.4225734 },
    { lat: 48.9197304, lng: 20.4227231 },
    { lat: 48.9196355, lng: 20.4226835 },
    { lat: 48.9192994, lng: 20.422401 },
    { lat: 48.9191592, lng: 20.4221526 },
    { lat: 48.9191287, lng: 20.4218517 },
    { lat: 48.9188336, lng: 20.4214948 },
    { lat: 48.9184897, lng: 20.4214536 },
    { lat: 48.9180629, lng: 20.4213066 },
    { lat: 48.9179059, lng: 20.4210865 },
    { lat: 48.9177542, lng: 20.42059 },
    { lat: 48.9174588, lng: 20.4202796 },
    { lat: 48.9172139, lng: 20.4202667 },
    { lat: 48.9169438, lng: 20.4200952 },
    { lat: 48.9167321, lng: 20.4201492 },
    { lat: 48.9164055, lng: 20.4200299 },
    { lat: 48.9162118, lng: 20.4200608 },
    { lat: 48.9158294, lng: 20.419863 },
    { lat: 48.9155917, lng: 20.4198308 },
    { lat: 48.9152774, lng: 20.4194398 },
    { lat: 48.9148335, lng: 20.4191933 },
    { lat: 48.9145147, lng: 20.4189259 },
    { lat: 48.914151, lng: 20.4187762 },
    { lat: 48.9137444, lng: 20.4187731 },
    { lat: 48.9134727, lng: 20.4185907 },
    { lat: 48.9129704, lng: 20.4186153 },
    { lat: 48.9127784, lng: 20.4188029 },
    { lat: 48.9126544, lng: 20.4188016 },
    { lat: 48.9121314, lng: 20.4184049 },
    { lat: 48.9120524, lng: 20.4183062 },
    { lat: 48.9120294, lng: 20.4181181 },
    { lat: 48.9119518, lng: 20.4179763 },
    { lat: 48.9118336, lng: 20.4179154 },
    { lat: 48.9116517, lng: 20.4179586 },
    { lat: 48.9110827, lng: 20.4174768 },
    { lat: 48.9104762, lng: 20.4172199 },
    { lat: 48.910012, lng: 20.4166223 },
    { lat: 48.9096567, lng: 20.4163373 },
    { lat: 48.9093375, lng: 20.4162332 },
    { lat: 48.9090496, lng: 20.4156789 },
    { lat: 48.9088055, lng: 20.415658 },
    { lat: 48.9087167, lng: 20.4155698 },
    { lat: 48.9085188, lng: 20.4151376 },
    { lat: 48.90828, lng: 20.415042 },
    { lat: 48.908174, lng: 20.414958 },
    { lat: 48.908054, lng: 20.414975 },
    { lat: 48.907851, lng: 20.414781 },
    { lat: 48.907704, lng: 20.414786 },
    { lat: 48.907586, lng: 20.414909 },
    { lat: 48.90746, lng: 20.414837 },
    { lat: 48.907425, lng: 20.414835 },
    { lat: 48.906981, lng: 20.415021 },
    { lat: 48.906969, lng: 20.415014 },
    { lat: 48.906656, lng: 20.41465 },
    { lat: 48.906447, lng: 20.414759 },
    { lat: 48.906105, lng: 20.41462 },
    { lat: 48.905969, lng: 20.414324 },
    { lat: 48.905595, lng: 20.413909 },
    { lat: 48.905573, lng: 20.41362 },
    { lat: 48.905456, lng: 20.41344 },
    { lat: 48.905289, lng: 20.413396 },
    { lat: 48.904937, lng: 20.412612 },
    { lat: 48.904803, lng: 20.412615 },
    { lat: 48.904711, lng: 20.412483 },
    { lat: 48.904761, lng: 20.412338 },
    { lat: 48.904785, lng: 20.412146 },
    { lat: 48.904682, lng: 20.411876 },
    { lat: 48.904736, lng: 20.411692 },
    { lat: 48.90464, lng: 20.411411 },
    { lat: 48.904699, lng: 20.411305 },
    { lat: 48.904639, lng: 20.411096 },
    { lat: 48.904543, lng: 20.411002 },
    { lat: 48.904475, lng: 20.410821 },
    { lat: 48.90446, lng: 20.410361 },
    { lat: 48.904419, lng: 20.410263 },
    { lat: 48.904118, lng: 20.41003 },
    { lat: 48.904129, lng: 20.409748 },
    { lat: 48.904065, lng: 20.409569 },
    { lat: 48.904005, lng: 20.409574 },
    { lat: 48.903951, lng: 20.409342 },
    { lat: 48.904051, lng: 20.409089 },
    { lat: 48.904022, lng: 20.408905 },
    { lat: 48.903931, lng: 20.408814 },
    { lat: 48.903671, lng: 20.408801 },
    { lat: 48.903559, lng: 20.408558 },
    { lat: 48.903531, lng: 20.408144 },
    { lat: 48.903527, lng: 20.408042 },
    { lat: 48.903485, lng: 20.407988 },
    { lat: 48.903379, lng: 20.407865 },
    { lat: 48.903383, lng: 20.407608 },
    { lat: 48.90323, lng: 20.407254 },
    { lat: 48.903269, lng: 20.407079 },
    { lat: 48.903294, lng: 20.407003 },
    { lat: 48.903376, lng: 20.406877 },
    { lat: 48.903391, lng: 20.406506 },
    { lat: 48.903226, lng: 20.406275 },
    { lat: 48.903048, lng: 20.405976 },
    { lat: 48.903064, lng: 20.405823 },
    { lat: 48.903222, lng: 20.405725 },
    { lat: 48.903344, lng: 20.405559 },
    { lat: 48.903202, lng: 20.405227 },
    { lat: 48.903011, lng: 20.405083 },
    { lat: 48.902988, lng: 20.405027 },
    { lat: 48.90297, lng: 20.404938 },
    { lat: 48.90305, lng: 20.404767 },
    { lat: 48.902996, lng: 20.404539 },
    { lat: 48.903009, lng: 20.404275 },
    { lat: 48.90298, lng: 20.404037 },
    { lat: 48.902886, lng: 20.403931 },
    { lat: 48.902883, lng: 20.403563 },
    { lat: 48.90278, lng: 20.403349 },
    { lat: 48.902691, lng: 20.403296 },
    { lat: 48.902634, lng: 20.403262 },
    { lat: 48.902616, lng: 20.403251 },
    { lat: 48.90255, lng: 20.402926 },
    { lat: 48.902548, lng: 20.402894 },
    { lat: 48.902536, lng: 20.4026 },
    { lat: 48.902468, lng: 20.402575 },
    { lat: 48.902508, lng: 20.402305 },
    { lat: 48.902487, lng: 20.402255 },
    { lat: 48.902503, lng: 20.401778 },
    { lat: 48.902412, lng: 20.401676 },
    { lat: 48.902413, lng: 20.401301 },
    { lat: 48.902347, lng: 20.401203 },
    { lat: 48.902377, lng: 20.401096 },
    { lat: 48.902255, lng: 20.400861 },
    { lat: 48.902142, lng: 20.400783 },
    { lat: 48.902066, lng: 20.400321 },
    { lat: 48.90201, lng: 20.400224 },
    { lat: 48.902094, lng: 20.40007 },
    { lat: 48.901968, lng: 20.399924 },
    { lat: 48.902094, lng: 20.399571 },
    { lat: 48.902005, lng: 20.399391 },
    { lat: 48.90207, lng: 20.399106 },
    { lat: 48.901999, lng: 20.398923 },
    { lat: 48.902037, lng: 20.398768 },
    { lat: 48.901927, lng: 20.398684 },
    { lat: 48.90188, lng: 20.398284 },
    { lat: 48.901976, lng: 20.398227 },
    { lat: 48.901982, lng: 20.39809 },
    { lat: 48.901939, lng: 20.398 },
    { lat: 48.902042, lng: 20.397874 },
    { lat: 48.901909, lng: 20.397536 },
    { lat: 48.901968, lng: 20.397339 },
    { lat: 48.901829, lng: 20.396933 },
    { lat: 48.901978, lng: 20.396667 },
    { lat: 48.901956, lng: 20.396506 },
    { lat: 48.901994, lng: 20.396431 },
    { lat: 48.902073, lng: 20.396272 },
    { lat: 48.902078, lng: 20.396051 },
    { lat: 48.902048, lng: 20.396022 },
    { lat: 48.902012, lng: 20.395986 },
    { lat: 48.90197, lng: 20.395944 },
    { lat: 48.901989, lng: 20.395724 },
    { lat: 48.901981, lng: 20.395459 },
    { lat: 48.901991, lng: 20.39521 },
    { lat: 48.902054, lng: 20.39516 },
    { lat: 48.902092, lng: 20.395107 },
    { lat: 48.902142, lng: 20.395038 },
    { lat: 48.902172, lng: 20.394944 },
    { lat: 48.90218, lng: 20.394821 },
    { lat: 48.902403, lng: 20.3946 },
    { lat: 48.902525, lng: 20.394603 },
    { lat: 48.902639, lng: 20.394503 },
    { lat: 48.902832, lng: 20.394081 },
    { lat: 48.902938, lng: 20.394051 },
    { lat: 48.903099, lng: 20.393716 },
    { lat: 48.903112, lng: 20.393531 },
    { lat: 48.90314, lng: 20.393271 },
    { lat: 48.903181, lng: 20.393085 },
    { lat: 48.903188, lng: 20.392723 },
    { lat: 48.903544, lng: 20.392288 },
    { lat: 48.903666, lng: 20.391878 },
    { lat: 48.903815, lng: 20.391617 },
    { lat: 48.903646, lng: 20.390975 },
    { lat: 48.903748, lng: 20.389882 },
    { lat: 48.903555, lng: 20.389667 },
    { lat: 48.903572, lng: 20.389137 },
    { lat: 48.903235, lng: 20.388529 },
    { lat: 48.903134, lng: 20.388021 },
    { lat: 48.903055, lng: 20.387196 },
    { lat: 48.903101, lng: 20.386915 },
    { lat: 48.903003, lng: 20.386507 },
    { lat: 48.902509, lng: 20.38552 },
    { lat: 48.902133, lng: 20.385144 },
    { lat: 48.901674, lng: 20.384407 },
    { lat: 48.901437, lng: 20.383663 },
    { lat: 48.900873, lng: 20.383286 },
    { lat: 48.900446, lng: 20.383111 },
    { lat: 48.899961, lng: 20.383078 },
    { lat: 48.899624, lng: 20.383221 },
    { lat: 48.899113, lng: 20.383374 },
    { lat: 48.898592, lng: 20.383585 },
    { lat: 48.898428, lng: 20.383562 },
    { lat: 48.898228, lng: 20.383496 },
    { lat: 48.897625, lng: 20.382822 },
    { lat: 48.897539, lng: 20.38177 },
    { lat: 48.896957, lng: 20.382065 },
    { lat: 48.896453, lng: 20.382698 },
    { lat: 48.896004, lng: 20.383394 },
    { lat: 48.895427, lng: 20.384163 },
    { lat: 48.894522, lng: 20.38481 },
    { lat: 48.894351, lng: 20.386371 },
    { lat: 48.894031, lng: 20.387039 },
    { lat: 48.893984, lng: 20.387222 },
    { lat: 48.893839, lng: 20.387413 },
    { lat: 48.893775, lng: 20.38741 },
    { lat: 48.893497, lng: 20.387441 },
    { lat: 48.893216, lng: 20.38771 },
    { lat: 48.89295, lng: 20.388058 },
    { lat: 48.892616, lng: 20.388726 },
    { lat: 48.892172, lng: 20.38869 },
    { lat: 48.8915, lng: 20.388903 },
    { lat: 48.891164, lng: 20.389085 },
    { lat: 48.891037, lng: 20.38932 },
    { lat: 48.890835, lng: 20.389695 },
    { lat: 48.890529, lng: 20.390264 },
    { lat: 48.890275, lng: 20.390922 },
    { lat: 48.890198, lng: 20.391147 },
    { lat: 48.89012, lng: 20.392298 },
    { lat: 48.889906, lng: 20.392883 },
    { lat: 48.889412, lng: 20.39277 },
    { lat: 48.888966, lng: 20.392407 },
    { lat: 48.888488, lng: 20.39223 },
    { lat: 48.888016, lng: 20.391959 },
    { lat: 48.887796, lng: 20.391749 },
    { lat: 48.887484, lng: 20.391474 },
    { lat: 48.887232, lng: 20.391386 },
    { lat: 48.88714, lng: 20.39133 },
    { lat: 48.886672, lng: 20.391046 },
    { lat: 48.886593, lng: 20.390998 },
    { lat: 48.885903, lng: 20.390419 },
    { lat: 48.885771, lng: 20.390167 },
    { lat: 48.88543, lng: 20.390162 },
    { lat: 48.884559, lng: 20.389559 },
    { lat: 48.884253, lng: 20.389583 },
    { lat: 48.884025, lng: 20.389916 },
    { lat: 48.883625, lng: 20.390505 },
    { lat: 48.882834, lng: 20.391078 },
    { lat: 48.882501, lng: 20.391321 },
    { lat: 48.880825, lng: 20.392493 },
    { lat: 48.880157, lng: 20.392265 },
    { lat: 48.880141, lng: 20.39226 },
    { lat: 48.880128, lng: 20.392275 },
    { lat: 48.879635, lng: 20.392834 },
    { lat: 48.879331, lng: 20.393016 },
    { lat: 48.878937, lng: 20.393079 },
    { lat: 48.878627, lng: 20.392402 },
    { lat: 48.87801, lng: 20.391601 },
    { lat: 48.877775, lng: 20.391692 },
    { lat: 48.876911, lng: 20.391713 },
    { lat: 48.876503, lng: 20.391672 },
    { lat: 48.876352, lng: 20.391579 },
    { lat: 48.876316, lng: 20.391534 },
    { lat: 48.876187, lng: 20.391345 },
    { lat: 48.876079, lng: 20.39092 },
    { lat: 48.875085, lng: 20.391205 },
    { lat: 48.874793, lng: 20.391458 },
    { lat: 48.874531, lng: 20.392454 },
    { lat: 48.874496, lng: 20.392581 },
    { lat: 48.874383, lng: 20.392878 },
    { lat: 48.874339, lng: 20.392994 },
    { lat: 48.874167, lng: 20.393443 },
    { lat: 48.874399, lng: 20.393924 },
    { lat: 48.874458, lng: 20.394044 },
    { lat: 48.874307, lng: 20.394432 },
    { lat: 48.874157, lng: 20.39521 },
    { lat: 48.873994, lng: 20.397028 },
    { lat: 48.873993, lng: 20.397048 },
    { lat: 48.873797, lng: 20.397064 },
    { lat: 48.873557, lng: 20.39712 },
    { lat: 48.873278, lng: 20.397194 },
    { lat: 48.872839, lng: 20.397394 },
    { lat: 48.872332, lng: 20.398098 },
    { lat: 48.872107, lng: 20.39835 },
    { lat: 48.87167, lng: 20.398761 },
    { lat: 48.871555, lng: 20.398908 },
    { lat: 48.871288, lng: 20.399193 },
    { lat: 48.87141, lng: 20.399864 },
    { lat: 48.871601, lng: 20.400206 },
    { lat: 48.871921, lng: 20.400471 },
    { lat: 48.872136, lng: 20.401058 },
    { lat: 48.872452, lng: 20.401875 },
    { lat: 48.872664, lng: 20.402409 },
    { lat: 48.873005, lng: 20.403182 },
    { lat: 48.873216, lng: 20.40366 },
    { lat: 48.873535, lng: 20.404408 },
    { lat: 48.873861, lng: 20.404983 },
    { lat: 48.874141, lng: 20.405638 },
    { lat: 48.874738, lng: 20.40599 },
    { lat: 48.874741, lng: 20.405997 },
    { lat: 48.87485, lng: 20.406296 },
    { lat: 48.874871, lng: 20.406351 },
    { lat: 48.875091, lng: 20.406918 },
    { lat: 48.875256, lng: 20.407387 },
    { lat: 48.875302, lng: 20.407507 },
    { lat: 48.875325, lng: 20.408258 },
    { lat: 48.875335, lng: 20.408686 },
    { lat: 48.875339, lng: 20.408824 },
    { lat: 48.875341, lng: 20.408981 },
    { lat: 48.875228, lng: 20.409579 },
    { lat: 48.875165, lng: 20.410005 },
    { lat: 48.875097, lng: 20.410471 },
    { lat: 48.875233, lng: 20.411557 },
    { lat: 48.875373, lng: 20.412202 },
    { lat: 48.87541, lng: 20.412286 },
    { lat: 48.875587, lng: 20.412897 },
    { lat: 48.875696, lng: 20.413297 },
    { lat: 48.875954, lng: 20.414074 },
    { lat: 48.876352, lng: 20.414028 },
    { lat: 48.876499, lng: 20.414089 },
    { lat: 48.876694, lng: 20.414622 },
    { lat: 48.876785, lng: 20.414999 },
    { lat: 48.876831, lng: 20.415301 },
    { lat: 48.876795, lng: 20.415477 },
    { lat: 48.876779, lng: 20.41549 },
    { lat: 48.8771947, lng: 20.4168149 },
    { lat: 48.8777013, lng: 20.417735 },
    { lat: 48.8777488, lng: 20.4180028 },
    { lat: 48.8779277, lng: 20.418474 },
    { lat: 48.878052, lng: 20.4186037 },
    { lat: 48.878785, lng: 20.4187496 },
    { lat: 48.87896, lng: 20.4188541 },
    { lat: 48.8794651, lng: 20.41967 },
    { lat: 48.8794961, lng: 20.4201023 },
    { lat: 48.8793733, lng: 20.4215511 },
    { lat: 48.8794234, lng: 20.423012 },
    { lat: 48.879363, lng: 20.4236201 },
    { lat: 48.879393, lng: 20.4241115 },
    { lat: 48.8794747, lng: 20.424455 },
    { lat: 48.8795209, lng: 20.4249253 },
    { lat: 48.8796873, lng: 20.4251311 },
    { lat: 48.8796999, lng: 20.425134 },
    { lat: 48.879878, lng: 20.4251395 },
    { lat: 48.8799297, lng: 20.4252577 },
    { lat: 48.8802445, lng: 20.4252417 },
    { lat: 48.8802897, lng: 20.4251565 },
    { lat: 48.8804488, lng: 20.4251419 },
    { lat: 48.8804846, lng: 20.4251899 },
    { lat: 48.8807278, lng: 20.425076 },
    { lat: 48.8810444, lng: 20.4251042 },
    { lat: 48.8812565, lng: 20.4249984 },
    { lat: 48.8817504, lng: 20.4249338 },
    { lat: 48.882433, lng: 20.4246724 },
    { lat: 48.8826146, lng: 20.4246762 },
    { lat: 48.8830137, lng: 20.4243677 },
    { lat: 48.8837402, lng: 20.4241075 },
    { lat: 48.8843706, lng: 20.4241007 },
    { lat: 48.8845776, lng: 20.4243306 },
    { lat: 48.8849092, lng: 20.4243785 },
    { lat: 48.8853356, lng: 20.4246336 },
    { lat: 48.8854723, lng: 20.424647 },
    { lat: 48.8860621, lng: 20.425056 },
    { lat: 48.8862045, lng: 20.4252196 },
    { lat: 48.8864218, lng: 20.42563 },
    { lat: 48.8867055, lng: 20.4259565 },
    { lat: 48.8869052, lng: 20.4263878 },
    { lat: 48.887412, lng: 20.4268577 },
    { lat: 48.8875499, lng: 20.4271723 },
    { lat: 48.8881992, lng: 20.4277865 },
    { lat: 48.888324, lng: 20.4282225 },
    { lat: 48.8884546, lng: 20.4284916 },
    { lat: 48.8886563, lng: 20.4287892 },
    { lat: 48.8888557, lng: 20.429237 },
    { lat: 48.8891745, lng: 20.4296861 },
    { lat: 48.889915, lng: 20.4297954 },
    { lat: 48.8905094, lng: 20.4301497 },
    { lat: 48.8911367, lng: 20.4303036 },
    { lat: 48.8914556, lng: 20.4306053 },
    { lat: 48.8917167, lng: 20.4312259 },
    { lat: 48.8917928, lng: 20.4316018 },
    { lat: 48.8916814, lng: 20.4323722 },
    { lat: 48.8916985, lng: 20.4328878 },
    { lat: 48.8918197, lng: 20.4337378 },
    { lat: 48.8920707, lng: 20.4342666 },
    { lat: 48.8922687, lng: 20.4348573 },
    { lat: 48.8925005, lng: 20.4361625 },
    { lat: 48.8928085, lng: 20.4368624 },
    { lat: 48.8929471, lng: 20.4379378 },
    { lat: 48.8930463, lng: 20.438263 },
    { lat: 48.893281, lng: 20.4395286 },
    { lat: 48.8936444, lng: 20.4404391 },
    { lat: 48.8942354, lng: 20.4415664 },
    { lat: 48.8945217, lng: 20.4422622 },
    { lat: 48.8946291, lng: 20.4426608 },
    { lat: 48.894845, lng: 20.4431297 },
    { lat: 48.8952272, lng: 20.4437098 },
    { lat: 48.8957813, lng: 20.4441863 },
    { lat: 48.8963143, lng: 20.4445239 },
    { lat: 48.8965578, lng: 20.4447732 },
    { lat: 48.8973908, lng: 20.4458989 },
    { lat: 48.8984781, lng: 20.4471138 },
    { lat: 48.8989725, lng: 20.4479161 },
    { lat: 48.9001982, lng: 20.4493961 },
    { lat: 48.9005029, lng: 20.4498925 },
    { lat: 48.900931, lng: 20.4504075 },
    { lat: 48.9019013, lng: 20.4520658 },
    { lat: 48.9022169, lng: 20.4527653 },
    { lat: 48.9028623, lng: 20.454561 },
    { lat: 48.9030403, lng: 20.4554901 },
    { lat: 48.9030763, lng: 20.456984 },
    { lat: 48.9030652, lng: 20.4572294 },
    { lat: 48.902853, lng: 20.4581959 },
    { lat: 48.9028636, lng: 20.4599433 },
    { lat: 48.903545, lng: 20.4614157 },
    { lat: 48.9041058, lng: 20.4623431 },
    { lat: 48.9042934, lng: 20.4630523 },
    { lat: 48.9043697, lng: 20.4638489 },
    { lat: 48.9047489, lng: 20.4656714 },
    { lat: 48.9047998, lng: 20.466395 },
    { lat: 48.9050367, lng: 20.4672177 },
    { lat: 48.9056809, lng: 20.4682683 },
    { lat: 48.9058578, lng: 20.4687501 },
    { lat: 48.90652, lng: 20.4695959 },
    { lat: 48.9071604, lng: 20.4701243 },
    { lat: 48.9077543, lng: 20.4708915 },
    { lat: 48.9082928, lng: 20.4721578 },
    { lat: 48.9089136, lng: 20.4741204 },
    { lat: 48.9095762, lng: 20.4758679 },
    { lat: 48.9096805, lng: 20.4763732 },
    { lat: 48.9097416, lng: 20.4774604 },
    { lat: 48.9098168, lng: 20.4777535 },
    { lat: 48.9098802, lng: 20.4788151 },
    { lat: 48.9100566, lng: 20.4800336 },
    { lat: 48.9101098, lng: 20.4807922 },
    { lat: 48.9104531, lng: 20.4821091 },
    { lat: 48.9105027, lng: 20.4827721 },
    { lat: 48.9106673, lng: 20.4833245 },
    { lat: 48.9105791, lng: 20.4846354 },
    { lat: 48.9106196, lng: 20.4854151 },
    { lat: 48.9106955, lng: 20.4856705 },
    { lat: 48.9106919, lng: 20.4858727 },
    { lat: 48.9106302, lng: 20.4860559 },
    { lat: 48.9104249, lng: 20.486321 },
    { lat: 48.9102976, lng: 20.4863799 },
    { lat: 48.9096936, lng: 20.4863943 },
    { lat: 48.9091345, lng: 20.486648 },
    { lat: 48.9086876, lng: 20.4871006 },
    { lat: 48.9082787, lng: 20.4880335 },
    { lat: 48.908056, lng: 20.4890284 },
    { lat: 48.9079983, lng: 20.4900165 },
    { lat: 48.9077141, lng: 20.4913259 },
    { lat: 48.9076836, lng: 20.4917151 },
    { lat: 48.9076162, lng: 20.4920183 },
    { lat: 48.9073516, lng: 20.492471 },
    { lat: 48.9072675, lng: 20.4934163 },
    { lat: 48.9073061, lng: 20.4940257 },
    { lat: 48.9072633, lng: 20.4951711 },
    { lat: 48.907392, lng: 20.4954854 },
    { lat: 48.9075883, lng: 20.4956167 },
    { lat: 48.9084233, lng: 20.495604 },
    { lat: 48.9089006, lng: 20.4957831 },
    { lat: 48.9091499, lng: 20.4959435 },
    { lat: 48.9096073, lng: 20.4959804 },
    { lat: 48.9097159, lng: 20.4959378 },
    { lat: 48.9101205, lng: 20.4954943 },
    { lat: 48.9107427, lng: 20.4950918 },
    { lat: 48.9109672, lng: 20.49507 },
    { lat: 48.9113814, lng: 20.4956737 },
    { lat: 48.9117437, lng: 20.4959675 },
    { lat: 48.9129742, lng: 20.4972284 },
    { lat: 48.9134172, lng: 20.4978773 },
    { lat: 48.9135512, lng: 20.4979402 },
    { lat: 48.9140389, lng: 20.4986683 },
    { lat: 48.9140684, lng: 20.4987932 },
    { lat: 48.9139022, lng: 20.5003464 },
    { lat: 48.9138106, lng: 20.5008124 },
    { lat: 48.9138236, lng: 20.5022503 },
    { lat: 48.9137926, lng: 20.5025057 },
    { lat: 48.9136741, lng: 20.5029536 },
    { lat: 48.9131426, lng: 20.5037978 },
    { lat: 48.9131348, lng: 20.504189 },
    { lat: 48.9130212, lng: 20.5049762 },
    { lat: 48.9130222, lng: 20.5060919 },
    { lat: 48.9131766, lng: 20.5066732 },
    { lat: 48.9132401, lng: 20.5077123 },
    { lat: 48.9130419, lng: 20.5085547 },
    { lat: 48.9131135, lng: 20.508754 },
    { lat: 48.9133632, lng: 20.5091096 },
    { lat: 48.9135325, lng: 20.5101213 },
    { lat: 48.9134756, lng: 20.5104383 },
    { lat: 48.9134384, lng: 20.511771 },
    { lat: 48.9135221, lng: 20.5122971 },
    { lat: 48.9135788, lng: 20.5124555 },
    { lat: 48.9138971, lng: 20.5129015 },
    { lat: 48.9142577, lng: 20.5136091 },
    { lat: 48.9149042, lng: 20.5143083 },
    { lat: 48.9151814, lng: 20.5152847 },
    { lat: 48.9154951, lng: 20.5158156 },
    { lat: 48.9156292, lng: 20.5167309 },
    { lat: 48.9159244, lng: 20.5172226 },
    { lat: 48.9162349, lng: 20.517336 },
    { lat: 48.9164566, lng: 20.5171828 },
    { lat: 48.9167185, lng: 20.5166227 },
    { lat: 48.9168421, lng: 20.5159169 },
    { lat: 48.9169175, lng: 20.5157701 },
    { lat: 48.9170692, lng: 20.5156687 },
    { lat: 48.9172313, lng: 20.5157007 },
    { lat: 48.9174479, lng: 20.5159474 },
    { lat: 48.9176555, lng: 20.5169939 },
    { lat: 48.9180067, lng: 20.5173544 },
    { lat: 48.9181161, lng: 20.5175416 },
    { lat: 48.9186775, lng: 20.5186828 },
    { lat: 48.9193279, lng: 20.5203395 },
    { lat: 48.9204263, lng: 20.5219321 },
    { lat: 48.9210683, lng: 20.5239093 },
    { lat: 48.9219131, lng: 20.5246835 },
    { lat: 48.9226624, lng: 20.5242715 },
    { lat: 48.923183, lng: 20.5241601 },
    { lat: 48.9233342, lng: 20.5242048 },
    { lat: 48.9240762, lng: 20.524904 },
    { lat: 48.9247797, lng: 20.52587 },
    { lat: 48.9257481, lng: 20.5268403 },
    { lat: 48.9260904, lng: 20.5269042 },
    { lat: 48.926858, lng: 20.5269013 },
    { lat: 48.9270894, lng: 20.5269768 },
    { lat: 48.9273632, lng: 20.5273168 },
    { lat: 48.9279421, lng: 20.5276846 },
    { lat: 48.9280166, lng: 20.527811 },
    { lat: 48.9283772, lng: 20.5280348 },
    { lat: 48.9288461, lng: 20.5284395 },
    { lat: 48.9289917, lng: 20.5284536 },
    { lat: 48.9292363, lng: 20.5283045 },
    { lat: 48.9297035, lng: 20.527517 },
    { lat: 48.9302834, lng: 20.5269712 },
    { lat: 48.9306148, lng: 20.5268291 },
    { lat: 48.9306918, lng: 20.5285097 },
    { lat: 48.9312852, lng: 20.5285748 },
    { lat: 48.9319058, lng: 20.5287961 },
    { lat: 48.9330481, lng: 20.5293911 },
    { lat: 48.9342638, lng: 20.5296602 },
    { lat: 48.9345957, lng: 20.529803 },
    { lat: 48.9350501, lng: 20.5298488 },
    { lat: 48.9355305, lng: 20.5297233 },
    { lat: 48.9375264, lng: 20.5298624 },
    { lat: 48.9396865, lng: 20.5302826 },
    { lat: 48.9401681, lng: 20.5303013 },
    { lat: 48.9416954, lng: 20.5305932 },
    { lat: 48.9435937, lng: 20.531077 },
    { lat: 48.9441429, lng: 20.5311018 },
    { lat: 48.9443713, lng: 20.53104 },
    { lat: 48.944526, lng: 20.530746 },
    { lat: 48.9448473, lng: 20.5307566 },
    { lat: 48.946671, lng: 20.5314194 },
    { lat: 48.9484505, lng: 20.5318067 },
    { lat: 48.9487073, lng: 20.5318346 },
    { lat: 48.9487935, lng: 20.5317592 },
    { lat: 48.9489679, lng: 20.5317622 },
    { lat: 48.9497967, lng: 20.5314619 },
    { lat: 48.949841, lng: 20.5312252 },
    { lat: 48.9499837, lng: 20.5313693 },
    { lat: 48.9513876, lng: 20.5308518 },
    { lat: 48.9516142, lng: 20.530739 },
    { lat: 48.9516872, lng: 20.5303989 },
    { lat: 48.9545792, lng: 20.531215 },
    { lat: 48.9563456, lng: 20.5316346 },
    { lat: 48.9563762, lng: 20.5322464 },
    { lat: 48.9591715, lng: 20.5327011 },
    { lat: 48.9615109, lng: 20.532144 },
    { lat: 48.9623719, lng: 20.5318347 },
    { lat: 48.9623701, lng: 20.5316962 },
    { lat: 48.9628961, lng: 20.5317316 },
    { lat: 48.9655159, lng: 20.531608 },
    { lat: 48.9677427, lng: 20.5311148 },
    { lat: 48.9696832, lng: 20.5307954 },
    { lat: 48.969822, lng: 20.5302873 },
    { lat: 48.9699843, lng: 20.5302351 },
    { lat: 48.9701471, lng: 20.5300062 },
    { lat: 48.9707346, lng: 20.5274461 },
    { lat: 48.9708163, lng: 20.5266457 },
    { lat: 48.9717545, lng: 20.5267458 },
    { lat: 48.9720457, lng: 20.5248488 },
    { lat: 48.9721796, lng: 20.5247575 },
    { lat: 48.9726486, lng: 20.523528 },
    { lat: 48.972888, lng: 20.5231204 },
    { lat: 48.9730353, lng: 20.5230272 },
    { lat: 48.9731151, lng: 20.5228993 },
    { lat: 48.9731962, lng: 20.5222935 },
    { lat: 48.9734897, lng: 20.5215248 },
    { lat: 48.9735408, lng: 20.5211136 },
    { lat: 48.9736325, lng: 20.5207947 },
    { lat: 48.9737678, lng: 20.520557 },
    { lat: 48.9737975, lng: 20.5195621 },
    { lat: 48.9747527, lng: 20.5192743 },
    { lat: 48.9746769, lng: 20.5185662 },
    { lat: 48.9746689, lng: 20.5175877 },
    { lat: 48.9757185, lng: 20.5170857 },
    { lat: 48.9768102, lng: 20.5164635 },
    { lat: 48.9767625, lng: 20.5157895 },
    { lat: 48.9768383, lng: 20.5128574 },
    { lat: 48.9768132, lng: 20.5100916 },
    { lat: 48.977903, lng: 20.5105443 },
    { lat: 48.9785831, lng: 20.5109988 },
    { lat: 48.9792509, lng: 20.5110344 },
    { lat: 48.9790712, lng: 20.509138 },
    { lat: 48.9805432, lng: 20.5088808 },
    { lat: 48.9804675, lng: 20.5082013 },
    { lat: 48.980456, lng: 20.5080775 },
  ],
  SpišskáNováVes: [
    { lat: 48.9696832, lng: 20.5307954 },
    { lat: 48.9677427, lng: 20.5311148 },
    { lat: 48.9655159, lng: 20.531608 },
    { lat: 48.9628961, lng: 20.5317316 },
    { lat: 48.9623701, lng: 20.5316962 },
    { lat: 48.9623719, lng: 20.5318347 },
    { lat: 48.9615109, lng: 20.532144 },
    { lat: 48.9591715, lng: 20.5327011 },
    { lat: 48.9563762, lng: 20.5322464 },
    { lat: 48.9563456, lng: 20.5316346 },
    { lat: 48.9545792, lng: 20.531215 },
    { lat: 48.9516872, lng: 20.5303989 },
    { lat: 48.9516142, lng: 20.530739 },
    { lat: 48.9513876, lng: 20.5308518 },
    { lat: 48.9499837, lng: 20.5313693 },
    { lat: 48.949841, lng: 20.5312252 },
    { lat: 48.9497967, lng: 20.5314619 },
    { lat: 48.9489679, lng: 20.5317622 },
    { lat: 48.9487935, lng: 20.5317592 },
    { lat: 48.9487073, lng: 20.5318346 },
    { lat: 48.9484505, lng: 20.5318067 },
    { lat: 48.946671, lng: 20.5314194 },
    { lat: 48.9448473, lng: 20.5307566 },
    { lat: 48.944526, lng: 20.530746 },
    { lat: 48.9443713, lng: 20.53104 },
    { lat: 48.9441429, lng: 20.5311018 },
    { lat: 48.9435937, lng: 20.531077 },
    { lat: 48.9416954, lng: 20.5305932 },
    { lat: 48.9401681, lng: 20.5303013 },
    { lat: 48.9396865, lng: 20.5302826 },
    { lat: 48.9375264, lng: 20.5298624 },
    { lat: 48.9355305, lng: 20.5297233 },
    { lat: 48.9350501, lng: 20.5298488 },
    { lat: 48.9345957, lng: 20.529803 },
    { lat: 48.9342638, lng: 20.5296602 },
    { lat: 48.9330481, lng: 20.5293911 },
    { lat: 48.9319058, lng: 20.5287961 },
    { lat: 48.9312852, lng: 20.5285748 },
    { lat: 48.9306918, lng: 20.5285097 },
    { lat: 48.9306148, lng: 20.5268291 },
    { lat: 48.9302834, lng: 20.5269712 },
    { lat: 48.9297035, lng: 20.527517 },
    { lat: 48.9292363, lng: 20.5283045 },
    { lat: 48.9289917, lng: 20.5284536 },
    { lat: 48.9288461, lng: 20.5284395 },
    { lat: 48.9283772, lng: 20.5280348 },
    { lat: 48.9280166, lng: 20.527811 },
    { lat: 48.9279421, lng: 20.5276846 },
    { lat: 48.9273632, lng: 20.5273168 },
    { lat: 48.9270894, lng: 20.5269768 },
    { lat: 48.926858, lng: 20.5269013 },
    { lat: 48.9260904, lng: 20.5269042 },
    { lat: 48.9257481, lng: 20.5268403 },
    { lat: 48.9247797, lng: 20.52587 },
    { lat: 48.9240762, lng: 20.524904 },
    { lat: 48.9233342, lng: 20.5242048 },
    { lat: 48.923183, lng: 20.5241601 },
    { lat: 48.9226624, lng: 20.5242715 },
    { lat: 48.9219131, lng: 20.5246835 },
    { lat: 48.9210683, lng: 20.5239093 },
    { lat: 48.9204263, lng: 20.5219321 },
    { lat: 48.9193279, lng: 20.5203395 },
    { lat: 48.9186775, lng: 20.5186828 },
    { lat: 48.9181161, lng: 20.5175416 },
    { lat: 48.9180067, lng: 20.5173544 },
    { lat: 48.9176555, lng: 20.5169939 },
    { lat: 48.9174479, lng: 20.5159474 },
    { lat: 48.9172313, lng: 20.5157007 },
    { lat: 48.9170692, lng: 20.5156687 },
    { lat: 48.9169175, lng: 20.5157701 },
    { lat: 48.9168421, lng: 20.5159169 },
    { lat: 48.9167185, lng: 20.5166227 },
    { lat: 48.9164566, lng: 20.5171828 },
    { lat: 48.9162349, lng: 20.517336 },
    { lat: 48.9159244, lng: 20.5172226 },
    { lat: 48.9156292, lng: 20.5167309 },
    { lat: 48.9154951, lng: 20.5158156 },
    { lat: 48.9151814, lng: 20.5152847 },
    { lat: 48.9149042, lng: 20.5143083 },
    { lat: 48.9142577, lng: 20.5136091 },
    { lat: 48.9138971, lng: 20.5129015 },
    { lat: 48.9135788, lng: 20.5124555 },
    { lat: 48.9135221, lng: 20.5122971 },
    { lat: 48.9134384, lng: 20.511771 },
    { lat: 48.9134756, lng: 20.5104383 },
    { lat: 48.9135325, lng: 20.5101213 },
    { lat: 48.9133632, lng: 20.5091096 },
    { lat: 48.9131135, lng: 20.508754 },
    { lat: 48.9130419, lng: 20.5085547 },
    { lat: 48.9132401, lng: 20.5077123 },
    { lat: 48.9131766, lng: 20.5066732 },
    { lat: 48.9130222, lng: 20.5060919 },
    { lat: 48.9130212, lng: 20.5049762 },
    { lat: 48.9131348, lng: 20.504189 },
    { lat: 48.9131426, lng: 20.5037978 },
    { lat: 48.9136741, lng: 20.5029536 },
    { lat: 48.9137926, lng: 20.5025057 },
    { lat: 48.9138236, lng: 20.5022503 },
    { lat: 48.9138106, lng: 20.5008124 },
    { lat: 48.9139022, lng: 20.5003464 },
    { lat: 48.9140684, lng: 20.4987932 },
    { lat: 48.9140389, lng: 20.4986683 },
    { lat: 48.9135512, lng: 20.4979402 },
    { lat: 48.9134172, lng: 20.4978773 },
    { lat: 48.9129742, lng: 20.4972284 },
    { lat: 48.9117437, lng: 20.4959675 },
    { lat: 48.9113814, lng: 20.4956737 },
    { lat: 48.9109672, lng: 20.49507 },
    { lat: 48.9107427, lng: 20.4950918 },
    { lat: 48.9101205, lng: 20.4954943 },
    { lat: 48.9097159, lng: 20.4959378 },
    { lat: 48.9096073, lng: 20.4959804 },
    { lat: 48.9091499, lng: 20.4959435 },
    { lat: 48.9089006, lng: 20.4957831 },
    { lat: 48.9084233, lng: 20.495604 },
    { lat: 48.9075883, lng: 20.4956167 },
    { lat: 48.907392, lng: 20.4954854 },
    { lat: 48.9072633, lng: 20.4951711 },
    { lat: 48.9073061, lng: 20.4940257 },
    { lat: 48.9072675, lng: 20.4934163 },
    { lat: 48.9073516, lng: 20.492471 },
    { lat: 48.9076162, lng: 20.4920183 },
    { lat: 48.9076836, lng: 20.4917151 },
    { lat: 48.9077141, lng: 20.4913259 },
    { lat: 48.9079983, lng: 20.4900165 },
    { lat: 48.908056, lng: 20.4890284 },
    { lat: 48.9082787, lng: 20.4880335 },
    { lat: 48.9086876, lng: 20.4871006 },
    { lat: 48.9091345, lng: 20.486648 },
    { lat: 48.9096936, lng: 20.4863943 },
    { lat: 48.9102976, lng: 20.4863799 },
    { lat: 48.9104249, lng: 20.486321 },
    { lat: 48.9106302, lng: 20.4860559 },
    { lat: 48.9106919, lng: 20.4858727 },
    { lat: 48.9106955, lng: 20.4856705 },
    { lat: 48.9106196, lng: 20.4854151 },
    { lat: 48.9105791, lng: 20.4846354 },
    { lat: 48.9106673, lng: 20.4833245 },
    { lat: 48.9105027, lng: 20.4827721 },
    { lat: 48.9104531, lng: 20.4821091 },
    { lat: 48.9101098, lng: 20.4807922 },
    { lat: 48.9100566, lng: 20.4800336 },
    { lat: 48.9098802, lng: 20.4788151 },
    { lat: 48.9098168, lng: 20.4777535 },
    { lat: 48.9097416, lng: 20.4774604 },
    { lat: 48.9096805, lng: 20.4763732 },
    { lat: 48.9095762, lng: 20.4758679 },
    { lat: 48.9089136, lng: 20.4741204 },
    { lat: 48.9082928, lng: 20.4721578 },
    { lat: 48.9077543, lng: 20.4708915 },
    { lat: 48.9071604, lng: 20.4701243 },
    { lat: 48.90652, lng: 20.4695959 },
    { lat: 48.9058578, lng: 20.4687501 },
    { lat: 48.9056809, lng: 20.4682683 },
    { lat: 48.9050367, lng: 20.4672177 },
    { lat: 48.9047998, lng: 20.466395 },
    { lat: 48.9047489, lng: 20.4656714 },
    { lat: 48.9043697, lng: 20.4638489 },
    { lat: 48.9042934, lng: 20.4630523 },
    { lat: 48.9041058, lng: 20.4623431 },
    { lat: 48.903545, lng: 20.4614157 },
    { lat: 48.9028636, lng: 20.4599433 },
    { lat: 48.902853, lng: 20.4581959 },
    { lat: 48.9030652, lng: 20.4572294 },
    { lat: 48.9030763, lng: 20.456984 },
    { lat: 48.9030403, lng: 20.4554901 },
    { lat: 48.9028623, lng: 20.454561 },
    { lat: 48.9022169, lng: 20.4527653 },
    { lat: 48.9019013, lng: 20.4520658 },
    { lat: 48.900931, lng: 20.4504075 },
    { lat: 48.9005029, lng: 20.4498925 },
    { lat: 48.9001982, lng: 20.4493961 },
    { lat: 48.8989725, lng: 20.4479161 },
    { lat: 48.8984781, lng: 20.4471138 },
    { lat: 48.8973908, lng: 20.4458989 },
    { lat: 48.8965578, lng: 20.4447732 },
    { lat: 48.8963143, lng: 20.4445239 },
    { lat: 48.8957813, lng: 20.4441863 },
    { lat: 48.8952272, lng: 20.4437098 },
    { lat: 48.894845, lng: 20.4431297 },
    { lat: 48.8946291, lng: 20.4426608 },
    { lat: 48.8945217, lng: 20.4422622 },
    { lat: 48.8942354, lng: 20.4415664 },
    { lat: 48.8936444, lng: 20.4404391 },
    { lat: 48.893281, lng: 20.4395286 },
    { lat: 48.8930463, lng: 20.438263 },
    { lat: 48.8929471, lng: 20.4379378 },
    { lat: 48.8928085, lng: 20.4368624 },
    { lat: 48.8925005, lng: 20.4361625 },
    { lat: 48.8922687, lng: 20.4348573 },
    { lat: 48.8920707, lng: 20.4342666 },
    { lat: 48.8918197, lng: 20.4337378 },
    { lat: 48.8916985, lng: 20.4328878 },
    { lat: 48.8916814, lng: 20.4323722 },
    { lat: 48.8917928, lng: 20.4316018 },
    { lat: 48.8917167, lng: 20.4312259 },
    { lat: 48.8914556, lng: 20.4306053 },
    { lat: 48.8911367, lng: 20.4303036 },
    { lat: 48.8905094, lng: 20.4301497 },
    { lat: 48.889915, lng: 20.4297954 },
    { lat: 48.8891745, lng: 20.4296861 },
    { lat: 48.8888557, lng: 20.429237 },
    { lat: 48.8886563, lng: 20.4287892 },
    { lat: 48.8884546, lng: 20.4284916 },
    { lat: 48.888324, lng: 20.4282225 },
    { lat: 48.8881992, lng: 20.4277865 },
    { lat: 48.8875499, lng: 20.4271723 },
    { lat: 48.887412, lng: 20.4268577 },
    { lat: 48.8869052, lng: 20.4263878 },
    { lat: 48.8867055, lng: 20.4259565 },
    { lat: 48.8864218, lng: 20.42563 },
    { lat: 48.8862045, lng: 20.4252196 },
    { lat: 48.8860621, lng: 20.425056 },
    { lat: 48.8854723, lng: 20.424647 },
    { lat: 48.8853356, lng: 20.4246336 },
    { lat: 48.8849092, lng: 20.4243785 },
    { lat: 48.8845776, lng: 20.4243306 },
    { lat: 48.8843706, lng: 20.4241007 },
    { lat: 48.8837402, lng: 20.4241075 },
    { lat: 48.8830137, lng: 20.4243677 },
    { lat: 48.8826146, lng: 20.4246762 },
    { lat: 48.882433, lng: 20.4246724 },
    { lat: 48.8817504, lng: 20.4249338 },
    { lat: 48.8812565, lng: 20.4249984 },
    { lat: 48.8810444, lng: 20.4251042 },
    { lat: 48.8807278, lng: 20.425076 },
    { lat: 48.8804846, lng: 20.4251899 },
    { lat: 48.8804488, lng: 20.4251419 },
    { lat: 48.8802897, lng: 20.4251565 },
    { lat: 48.8802445, lng: 20.4252417 },
    { lat: 48.8799297, lng: 20.4252577 },
    { lat: 48.879878, lng: 20.4251395 },
    { lat: 48.8796999, lng: 20.425134 },
    { lat: 48.8796873, lng: 20.4251311 },
    { lat: 48.878777, lng: 20.426214 },
    { lat: 48.8786519, lng: 20.4260926 },
    { lat: 48.8785316, lng: 20.4263614 },
    { lat: 48.8780376, lng: 20.4266066 },
    { lat: 48.8778888, lng: 20.4268393 },
    { lat: 48.8778042, lng: 20.4280847 },
    { lat: 48.8778913, lng: 20.4288366 },
    { lat: 48.8779785, lng: 20.4303879 },
    { lat: 48.8779447, lng: 20.4305987 },
    { lat: 48.8777677, lng: 20.4310642 },
    { lat: 48.8775694, lng: 20.4313594 },
    { lat: 48.8768192, lng: 20.4320673 },
    { lat: 48.8766197, lng: 20.4323855 },
    { lat: 48.8762007, lng: 20.4333013 },
    { lat: 48.8757983, lng: 20.4349546 },
    { lat: 48.8759998, lng: 20.435254 },
    { lat: 48.8756814, lng: 20.4358566 },
    { lat: 48.8756034, lng: 20.4363806 },
    { lat: 48.8754846, lng: 20.4365716 },
    { lat: 48.8751823, lng: 20.4368555 },
    { lat: 48.8748599, lng: 20.4369358 },
    { lat: 48.8743263, lng: 20.436758 },
    { lat: 48.8736567, lng: 20.4369765 },
    { lat: 48.8733338, lng: 20.4368973 },
    { lat: 48.8730302, lng: 20.4367868 },
    { lat: 48.8728826, lng: 20.4366025 },
    { lat: 48.8727285, lng: 20.4361914 },
    { lat: 48.8721986, lng: 20.4355808 },
    { lat: 48.8723617, lng: 20.4365552 },
    { lat: 48.8726047, lng: 20.4373514 },
    { lat: 48.8726648, lng: 20.4379918 },
    { lat: 48.8725934, lng: 20.438928 },
    { lat: 48.8726085, lng: 20.4394757 },
    { lat: 48.8725482, lng: 20.4400844 },
    { lat: 48.8723648, lng: 20.4410679 },
    { lat: 48.872416, lng: 20.4417652 },
    { lat: 48.8723357, lng: 20.4420862 },
    { lat: 48.8723539, lng: 20.4425993 },
    { lat: 48.871868, lng: 20.445767 },
    { lat: 48.8718923, lng: 20.4464716 },
    { lat: 48.872001, lng: 20.4468127 },
    { lat: 48.8720496, lng: 20.4472299 },
    { lat: 48.8720477, lng: 20.4484908 },
    { lat: 48.8721187, lng: 20.4493284 },
    { lat: 48.8723703, lng: 20.4510413 },
    { lat: 48.872632, lng: 20.4515616 },
    { lat: 48.8730066, lng: 20.4525091 },
    { lat: 48.8738529, lng: 20.4540771 },
    { lat: 48.8739723, lng: 20.4545279 },
    { lat: 48.8739804, lng: 20.454892 },
    { lat: 48.8738867, lng: 20.4553608 },
    { lat: 48.8738187, lng: 20.4554962 },
    { lat: 48.8735854, lng: 20.4598205 },
    { lat: 48.87312, lng: 20.4601451 },
    { lat: 48.8727521, lng: 20.4605676 },
    { lat: 48.872028, lng: 20.4610441 },
    { lat: 48.8646295, lng: 20.4686457 },
    { lat: 48.8650567, lng: 20.4687118 },
    { lat: 48.8651601, lng: 20.4696967 },
    { lat: 48.8653723, lng: 20.4706662 },
    { lat: 48.8651988, lng: 20.4712033 },
    { lat: 48.8652011, lng: 20.4716294 },
    { lat: 48.8652783, lng: 20.4720633 },
    { lat: 48.8652486, lng: 20.4726223 },
    { lat: 48.8652925, lng: 20.473401 },
    { lat: 48.8651875, lng: 20.4740237 },
    { lat: 48.8650615, lng: 20.4744759 },
    { lat: 48.8649216, lng: 20.4748277 },
    { lat: 48.8646748, lng: 20.4752109 },
    { lat: 48.8646496, lng: 20.4754077 },
    { lat: 48.8647477, lng: 20.4757132 },
    { lat: 48.8648037, lng: 20.4764808 },
    { lat: 48.8647104, lng: 20.4773836 },
    { lat: 48.8643268, lng: 20.4786548 },
    { lat: 48.864239, lng: 20.4793465 },
    { lat: 48.8640436, lng: 20.4801111 },
    { lat: 48.8639015, lng: 20.4804613 },
    { lat: 48.8638796, lng: 20.4809284 },
    { lat: 48.8636832, lng: 20.4813101 },
    { lat: 48.863471, lng: 20.4815962 },
    { lat: 48.8634734, lng: 20.4818353 },
    { lat: 48.8635477, lng: 20.482018 },
    { lat: 48.8635557, lng: 20.4821997 },
    { lat: 48.8632074, lng: 20.4833312 },
    { lat: 48.8632474, lng: 20.4836184 },
    { lat: 48.8631228, lng: 20.4841402 },
    { lat: 48.8625543, lng: 20.4850749 },
    { lat: 48.862441, lng: 20.4853826 },
    { lat: 48.8624599, lng: 20.4856781 },
    { lat: 48.8625885, lng: 20.4862256 },
    { lat: 48.8622907, lng: 20.4860921 },
    { lat: 48.8620891, lng: 20.4869906 },
    { lat: 48.8619986, lng: 20.4876975 },
    { lat: 48.8623354, lng: 20.4883622 },
    { lat: 48.8618151, lng: 20.4889927 },
    { lat: 48.8616825, lng: 20.4889737 },
    { lat: 48.8614326, lng: 20.4891869 },
    { lat: 48.861236, lng: 20.4890769 },
    { lat: 48.8610851, lng: 20.4892865 },
    { lat: 48.861015, lng: 20.4904776 },
    { lat: 48.8610837, lng: 20.4907198 },
    { lat: 48.861182, lng: 20.4932885 },
    { lat: 48.8618651, lng: 20.4942104 },
    { lat: 48.8621772, lng: 20.4944299 },
    { lat: 48.862309, lng: 20.4947308 },
    { lat: 48.862347, lng: 20.4950215 },
    { lat: 48.8620357, lng: 20.4954927 },
    { lat: 48.8620297, lng: 20.4958422 },
    { lat: 48.8623774, lng: 20.4955967 },
    { lat: 48.8629762, lng: 20.4953794 },
    { lat: 48.8630643, lng: 20.4952384 },
    { lat: 48.8635152, lng: 20.4960015 },
    { lat: 48.8633319, lng: 20.4966713 },
    { lat: 48.8632973, lng: 20.4973433 },
    { lat: 48.8631354, lng: 20.4982583 },
    { lat: 48.8634154, lng: 20.4990014 },
    { lat: 48.8635805, lng: 20.5002249 },
    { lat: 48.8631808, lng: 20.5020954 },
    { lat: 48.8616199, lng: 20.5040744 },
    { lat: 48.8612275, lng: 20.5043895 },
    { lat: 48.8609953, lng: 20.5048954 },
    { lat: 48.8609095, lng: 20.5055546 },
    { lat: 48.8607209, lng: 20.5063238 },
    { lat: 48.860239, lng: 20.5075598 },
    { lat: 48.8601773, lng: 20.5080202 },
    { lat: 48.8592776, lng: 20.5089869 },
    { lat: 48.8587395, lng: 20.5097724 },
    { lat: 48.8586256, lng: 20.5103689 },
    { lat: 48.8584934, lng: 20.5118434 },
    { lat: 48.8585027, lng: 20.5123643 },
    { lat: 48.8582219, lng: 20.5131367 },
    { lat: 48.857851, lng: 20.5138456 },
    { lat: 48.8576759, lng: 20.5143776 },
    { lat: 48.8574982, lng: 20.5147132 },
    { lat: 48.8576171, lng: 20.5150366 },
    { lat: 48.8583825, lng: 20.5149733 },
    { lat: 48.8586697, lng: 20.5147579 },
    { lat: 48.8600737, lng: 20.5142127 },
    { lat: 48.8610388, lng: 20.5142531 },
    { lat: 48.8612487, lng: 20.5142009 },
    { lat: 48.861444, lng: 20.5139153 },
    { lat: 48.8618339, lng: 20.513043 },
    { lat: 48.8620304, lng: 20.5128224 },
    { lat: 48.862446, lng: 20.5128562 },
    { lat: 48.8634252, lng: 20.5126351 },
    { lat: 48.8636092, lng: 20.5123762 },
    { lat: 48.8639215, lng: 20.511753 },
    { lat: 48.8640655, lng: 20.5116606 },
    { lat: 48.8644008, lng: 20.5115021 },
    { lat: 48.8659167, lng: 20.5111471 },
    { lat: 48.8662083, lng: 20.5112115 },
    { lat: 48.8665694, lng: 20.5115003 },
    { lat: 48.866884, lng: 20.5116669 },
    { lat: 48.8677167, lng: 20.5117777 },
    { lat: 48.8678884, lng: 20.5118513 },
    { lat: 48.8689444, lng: 20.5129966 },
    { lat: 48.8692084, lng: 20.5133706 },
    { lat: 48.8698304, lng: 20.5139633 },
    { lat: 48.8701587, lng: 20.5132242 },
    { lat: 48.8745544, lng: 20.5127745 },
    { lat: 48.8758531, lng: 20.5122703 },
    { lat: 48.8764169, lng: 20.5128387 },
    { lat: 48.8770762, lng: 20.5138249 },
    { lat: 48.8773514, lng: 20.5143848 },
    { lat: 48.8779291, lng: 20.5152944 },
    { lat: 48.8784768, lng: 20.5167878 },
    { lat: 48.8786957, lng: 20.5176816 },
    { lat: 48.8787397, lng: 20.518864 },
    { lat: 48.878559, lng: 20.5196979 },
    { lat: 48.8785115, lng: 20.5203623 },
    { lat: 48.8783268, lng: 20.5208702 },
    { lat: 48.878745, lng: 20.5218833 },
    { lat: 48.8785161, lng: 20.5222123 },
    { lat: 48.8782395, lng: 20.5228833 },
    { lat: 48.8782447, lng: 20.5236634 },
    { lat: 48.878968, lng: 20.5278322 },
    { lat: 48.8813627, lng: 20.5318659 },
    { lat: 48.8814104, lng: 20.5320328 },
    { lat: 48.881395, lng: 20.5323125 },
    { lat: 48.8812055, lng: 20.532579 },
    { lat: 48.880878, lng: 20.5332193 },
    { lat: 48.8804928, lng: 20.5342147 },
    { lat: 48.8804554, lng: 20.5344551 },
    { lat: 48.880342, lng: 20.5343427 },
    { lat: 48.8781911, lng: 20.5387732 },
    { lat: 48.8774872, lng: 20.5532736 },
    { lat: 48.8746977, lng: 20.5627528 },
    { lat: 48.874702, lng: 20.56278 },
    { lat: 48.8755878, lng: 20.5622255 },
    { lat: 48.8760926, lng: 20.5618069 },
    { lat: 48.8765834, lng: 20.5617714 },
    { lat: 48.8772296, lng: 20.5618938 },
    { lat: 48.8778547, lng: 20.5615945 },
    { lat: 48.8782207, lng: 20.5617187 },
    { lat: 48.8796761, lng: 20.5624427 },
    { lat: 48.8799099, lng: 20.5623735 },
    { lat: 48.8801331, lng: 20.5621679 },
    { lat: 48.8803222, lng: 20.5616776 },
    { lat: 48.8812786, lng: 20.5607074 },
    { lat: 48.8819155, lng: 20.55911 },
    { lat: 48.8819547, lng: 20.5588927 },
    { lat: 48.8842709, lng: 20.5607922 },
    { lat: 48.8865976, lng: 20.5623125 },
    { lat: 48.88745, lng: 20.5632943 },
    { lat: 48.8880465, lng: 20.564696 },
    { lat: 48.8895644, lng: 20.5659909 },
    { lat: 48.8897216, lng: 20.5653697 },
    { lat: 48.889872, lng: 20.5654761 },
    { lat: 48.8902168, lng: 20.5654849 },
    { lat: 48.8905587, lng: 20.5655948 },
    { lat: 48.8906743, lng: 20.5656788 },
    { lat: 48.8908213, lng: 20.5659111 },
    { lat: 48.8910335, lng: 20.5656835 },
    { lat: 48.8913251, lng: 20.5658352 },
    { lat: 48.8931009, lng: 20.5656554 },
    { lat: 48.8937266, lng: 20.5661377 },
    { lat: 48.894595, lng: 20.5673134 },
    { lat: 48.8963943, lng: 20.5679324 },
    { lat: 48.8982949, lng: 20.5680305 },
    { lat: 48.8983469, lng: 20.5680333 },
    { lat: 48.8989558, lng: 20.5681217 },
    { lat: 48.8995942, lng: 20.5681225 },
    { lat: 48.9010592, lng: 20.5677926 },
    { lat: 48.9016371, lng: 20.5679889 },
    { lat: 48.9019278, lng: 20.567867 },
    { lat: 48.9027284, lng: 20.5669193 },
    { lat: 48.9029413, lng: 20.5668018 },
    { lat: 48.9032333, lng: 20.5667865 },
    { lat: 48.9044113, lng: 20.5669721 },
    { lat: 48.904861, lng: 20.5671742 },
    { lat: 48.9052332, lng: 20.5670354 },
    { lat: 48.9056459, lng: 20.567115 },
    { lat: 48.9059415, lng: 20.5672876 },
    { lat: 48.9063592, lng: 20.5672837 },
    { lat: 48.9070558, lng: 20.5674936 },
    { lat: 48.9086062, lng: 20.5677097 },
    { lat: 48.9089424, lng: 20.5679175 },
    { lat: 48.9095824, lng: 20.5684475 },
    { lat: 48.9096491, lng: 20.5684276 },
    { lat: 48.9097463, lng: 20.5685299 },
    { lat: 48.9106483, lng: 20.5688406 },
    { lat: 48.9108713, lng: 20.5691185 },
    { lat: 48.9111628, lng: 20.5701239 },
    { lat: 48.9113194, lng: 20.5703168 },
    { lat: 48.9115905, lng: 20.5708298 },
    { lat: 48.9117609, lng: 20.5709034 },
    { lat: 48.9126136, lng: 20.5724485 },
    { lat: 48.9131473, lng: 20.5732145 },
    { lat: 48.9133167, lng: 20.5733509 },
    { lat: 48.9140322, lng: 20.5744101 },
    { lat: 48.9145712, lng: 20.5748335 },
    { lat: 48.9149745, lng: 20.5755758 },
    { lat: 48.9152316, lng: 20.5762621 },
    { lat: 48.9154401, lng: 20.577162 },
    { lat: 48.915573, lng: 20.5779629 },
    { lat: 48.9158801, lng: 20.5793398 },
    { lat: 48.9159461, lng: 20.5797377 },
    { lat: 48.9159892, lng: 20.5801661 },
    { lat: 48.91611, lng: 20.5804179 },
    { lat: 48.9160526, lng: 20.5805774 },
    { lat: 48.9161578, lng: 20.5811699 },
    { lat: 48.9161647, lng: 20.5811783 },
    { lat: 48.9164217, lng: 20.5814909 },
    { lat: 48.9169792, lng: 20.5827913 },
    { lat: 48.917666, lng: 20.5848493 },
    { lat: 48.9180879, lng: 20.5858906 },
    { lat: 48.9181944, lng: 20.5859385 },
    { lat: 48.9188639, lng: 20.5862083 },
    { lat: 48.920567, lng: 20.5871567 },
    { lat: 48.9208325, lng: 20.5872121 },
    { lat: 48.9213329, lng: 20.5876897 },
    { lat: 48.9221908, lng: 20.588307 },
    { lat: 48.9240466, lng: 20.5901607 },
    { lat: 48.9247305, lng: 20.5910405 },
    { lat: 48.924992, lng: 20.5914495 },
    { lat: 48.9250673, lng: 20.5916641 },
    { lat: 48.925269, lng: 20.5919146 },
    { lat: 48.9255101, lng: 20.5920457 },
    { lat: 48.9263236, lng: 20.5923035 },
    { lat: 48.9263785, lng: 20.5923937 },
    { lat: 48.9268971, lng: 20.5927168 },
    { lat: 48.9271559, lng: 20.5930538 },
    { lat: 48.9272232, lng: 20.5932622 },
    { lat: 48.928146, lng: 20.5931617 },
    { lat: 48.9294641, lng: 20.5926925 },
    { lat: 48.9299313, lng: 20.5923253 },
    { lat: 48.9300247, lng: 20.5924148 },
    { lat: 48.9304707, lng: 20.5940533 },
    { lat: 48.9309891, lng: 20.5964098 },
    { lat: 48.9313387, lng: 20.5990565 },
    { lat: 48.9319813, lng: 20.6017052 },
    { lat: 48.9323895, lng: 20.6026532 },
    { lat: 48.9341617, lng: 20.6012656 },
    { lat: 48.9346845, lng: 20.6026977 },
    { lat: 48.9349773, lng: 20.6025772 },
    { lat: 48.9350853, lng: 20.6029112 },
    { lat: 48.9350413, lng: 20.602941 },
    { lat: 48.9350658, lng: 20.6030568 },
    { lat: 48.9350131, lng: 20.6031901 },
    { lat: 48.9360023, lng: 20.6031777 },
    { lat: 48.9367161, lng: 20.6027153 },
    { lat: 48.9373683, lng: 20.602103 },
    { lat: 48.9378724, lng: 20.6017588 },
    { lat: 48.9380275, lng: 20.6015685 },
    { lat: 48.938214, lng: 20.6017724 },
    { lat: 48.9385475, lng: 20.6012248 },
    { lat: 48.9388758, lng: 20.6004975 },
    { lat: 48.9391349, lng: 20.6002258 },
    { lat: 48.9392769, lng: 20.6002006 },
    { lat: 48.9393051, lng: 20.6000153 },
    { lat: 48.9395072, lng: 20.5998135 },
    { lat: 48.939607, lng: 20.5995537 },
    { lat: 48.9398085, lng: 20.5985395 },
    { lat: 48.9398651, lng: 20.5983893 },
    { lat: 48.9399942, lng: 20.5982872 },
    { lat: 48.940139, lng: 20.5983692 },
    { lat: 48.940486, lng: 20.5989335 },
    { lat: 48.9408574, lng: 20.5992386 },
    { lat: 48.9409399, lng: 20.5995873 },
    { lat: 48.9410927, lng: 20.5997008 },
    { lat: 48.941261, lng: 20.5996527 },
    { lat: 48.9414441, lng: 20.5994919 },
    { lat: 48.9417386, lng: 20.5989823 },
    { lat: 48.9417712, lng: 20.5986723 },
    { lat: 48.9416653, lng: 20.5982385 },
    { lat: 48.9417119, lng: 20.5979381 },
    { lat: 48.9418454, lng: 20.5976468 },
    { lat: 48.942041, lng: 20.5975494 },
    { lat: 48.9421579, lng: 20.597695 },
    { lat: 48.9424387, lng: 20.5983584 },
    { lat: 48.9425922, lng: 20.598367 },
    { lat: 48.9428271, lng: 20.5982688 },
    { lat: 48.9430238, lng: 20.5980403 },
    { lat: 48.9428836, lng: 20.5970693 },
    { lat: 48.9433689, lng: 20.5966339 },
    { lat: 48.9435686, lng: 20.5961204 },
    { lat: 48.9434586, lng: 20.5959194 },
    { lat: 48.9439147, lng: 20.5956089 },
    { lat: 48.9448191, lng: 20.5942951 },
    { lat: 48.9464699, lng: 20.5924855 },
    { lat: 48.9475718, lng: 20.5923817 },
    { lat: 48.9492067, lng: 20.5917095 },
    { lat: 48.9498197, lng: 20.5913545 },
    { lat: 48.9485824, lng: 20.5866988 },
    { lat: 48.9482638, lng: 20.5853742 },
    { lat: 48.9474831, lng: 20.5816673 },
    { lat: 48.9503094, lng: 20.5795213 },
    { lat: 48.9510255, lng: 20.5787244 },
    { lat: 48.9512793, lng: 20.5786467 },
    { lat: 48.9524428, lng: 20.5776455 },
    { lat: 48.9527806, lng: 20.5771865 },
    { lat: 48.9534715, lng: 20.5764382 },
    { lat: 48.9544206, lng: 20.5759157 },
    { lat: 48.9552157, lng: 20.5758023 },
    { lat: 48.9563329, lng: 20.5746709 },
    { lat: 48.9578933, lng: 20.5733196 },
    { lat: 48.9589199, lng: 20.5721839 },
    { lat: 48.9596028, lng: 20.5715251 },
    { lat: 48.9598698, lng: 20.5712811 },
    { lat: 48.9606803, lng: 20.570788 },
    { lat: 48.9607356, lng: 20.5707009 },
    { lat: 48.9609502, lng: 20.5692132 },
    { lat: 48.9613963, lng: 20.5671028 },
    { lat: 48.9617268, lng: 20.5662024 },
    { lat: 48.9622725, lng: 20.56509 },
    { lat: 48.9624241, lng: 20.5650184 },
    { lat: 48.9626885, lng: 20.5643984 },
    { lat: 48.9630889, lng: 20.5630711 },
    { lat: 48.9633944, lng: 20.5613612 },
    { lat: 48.9635241, lng: 20.5613717 },
    { lat: 48.9636553, lng: 20.5612632 },
    { lat: 48.9638002, lng: 20.5590832 },
    { lat: 48.9638727, lng: 20.5585411 },
    { lat: 48.964016, lng: 20.5579965 },
    { lat: 48.9647481, lng: 20.5559275 },
    { lat: 48.9656822, lng: 20.5539288 },
    { lat: 48.9659698, lng: 20.5524737 },
    { lat: 48.9663434, lng: 20.5498406 },
    { lat: 48.9664908, lng: 20.5491333 },
    { lat: 48.9672301, lng: 20.5469241 },
    { lat: 48.9676302, lng: 20.5472434 },
    { lat: 48.9679365, lng: 20.5472446 },
    { lat: 48.9686945, lng: 20.5453902 },
    { lat: 48.9692947, lng: 20.5443842 },
    { lat: 48.9697521, lng: 20.5437254 },
    { lat: 48.9702633, lng: 20.5432945 },
    { lat: 48.9708855, lng: 20.5422808 },
    { lat: 48.9710285, lng: 20.5417322 },
    { lat: 48.9711002, lng: 20.5406525 },
    { lat: 48.971072, lng: 20.5395558 },
    { lat: 48.9707996, lng: 20.5388229 },
    { lat: 48.9706498, lng: 20.5387328 },
    { lat: 48.9705811, lng: 20.538457 },
    { lat: 48.9701482, lng: 20.53777 },
    { lat: 48.9684479, lng: 20.5370915 },
    { lat: 48.9683895, lng: 20.5359408 },
    { lat: 48.968839, lng: 20.5357638 },
    { lat: 48.9684507, lng: 20.532874 },
    { lat: 48.9693582, lng: 20.5324164 },
    { lat: 48.9694887, lng: 20.5314442 },
    { lat: 48.9696832, lng: 20.5307954 },
  ],
  Markušovce: [
    { lat: 48.9177922, lng: 20.6665011 },
    { lat: 48.9179177, lng: 20.6663636 },
    { lat: 48.9179851, lng: 20.6662306 },
    { lat: 48.9179396, lng: 20.666001 },
    { lat: 48.9176572, lng: 20.6653163 },
    { lat: 48.9171169, lng: 20.6653141 },
    { lat: 48.9170134, lng: 20.6654733 },
    { lat: 48.916665, lng: 20.6656191 },
    { lat: 48.9165196, lng: 20.6655764 },
    { lat: 48.9163174, lng: 20.6654048 },
    { lat: 48.9161455, lng: 20.6651631 },
    { lat: 48.9159924, lng: 20.6648805 },
    { lat: 48.9158298, lng: 20.6643681 },
    { lat: 48.9155365, lng: 20.662151 },
    { lat: 48.9155012, lng: 20.6605117 },
    { lat: 48.9156611, lng: 20.6601356 },
    { lat: 48.916219, lng: 20.6595908 },
    { lat: 48.9163747, lng: 20.6593213 },
    { lat: 48.9164857, lng: 20.6590573 },
    { lat: 48.9165259, lng: 20.6584226 },
    { lat: 48.916465, lng: 20.6578832 },
    { lat: 48.9162427, lng: 20.6569634 },
    { lat: 48.915773, lng: 20.6555194 },
    { lat: 48.9157434, lng: 20.6544735 },
    { lat: 48.9159874, lng: 20.654062 },
    { lat: 48.9161906, lng: 20.6539196 },
    { lat: 48.9163507, lng: 20.6535169 },
    { lat: 48.9164617, lng: 20.6528795 },
    { lat: 48.9164851, lng: 20.6524677 },
    { lat: 48.9163778, lng: 20.6515517 },
    { lat: 48.9161974, lng: 20.6508508 },
    { lat: 48.9160574, lng: 20.650561 },
    { lat: 48.9153807, lng: 20.6499012 },
    { lat: 48.9153021, lng: 20.6497503 },
    { lat: 48.9150473, lng: 20.6484596 },
    { lat: 48.9148618, lng: 20.6481879 },
    { lat: 48.9146216, lng: 20.6480185 },
    { lat: 48.9140703, lng: 20.6480889 },
    { lat: 48.9140103, lng: 20.6461425 },
    { lat: 48.9149795, lng: 20.6444242 },
    { lat: 48.9155818, lng: 20.6437059 },
    { lat: 48.9178273, lng: 20.6428791 },
    { lat: 48.9189322, lng: 20.6411182 },
    { lat: 48.92054, lng: 20.6378151 },
    { lat: 48.9207628, lng: 20.637662 },
    { lat: 48.9215664, lng: 20.6365337 },
    { lat: 48.9220369, lng: 20.6350938 },
    { lat: 48.9231364, lng: 20.6329055 },
    { lat: 48.923853, lng: 20.6317672 },
    { lat: 48.9243229, lng: 20.6304081 },
    { lat: 48.9246571, lng: 20.6297534 },
    { lat: 48.9251648, lng: 20.6290703 },
    { lat: 48.9256862, lng: 20.6282302 },
    { lat: 48.9259734, lng: 20.6280509 },
    { lat: 48.9261486, lng: 20.6278186 },
    { lat: 48.9265293, lng: 20.6269201 },
    { lat: 48.9271193, lng: 20.6262995 },
    { lat: 48.9284093, lng: 20.6246796 },
    { lat: 48.930047, lng: 20.622341 },
    { lat: 48.930571, lng: 20.621763 },
    { lat: 48.9313756, lng: 20.621162 },
    { lat: 48.9313676, lng: 20.621129 },
    { lat: 48.9286136, lng: 20.6144844 },
    { lat: 48.9260757, lng: 20.6109729 },
    { lat: 48.9232449, lng: 20.6140233 },
    { lat: 48.9229362, lng: 20.6135512 },
    { lat: 48.9228961, lng: 20.6133508 },
    { lat: 48.9229481, lng: 20.6129824 },
    { lat: 48.9233377, lng: 20.6124586 },
    { lat: 48.9186751, lng: 20.6071116 },
    { lat: 48.919279, lng: 20.6061059 },
    { lat: 48.919882, lng: 20.604888 },
    { lat: 48.9202246, lng: 20.6043127 },
    { lat: 48.9210814, lng: 20.6023929 },
    { lat: 48.9215849, lng: 20.601779 },
    { lat: 48.9225409, lng: 20.6011403 },
    { lat: 48.9232381, lng: 20.6005266 },
    { lat: 48.9239981, lng: 20.5996627 },
    { lat: 48.9252794, lng: 20.5979426 },
    { lat: 48.9254526, lng: 20.597593 },
    { lat: 48.9258024, lng: 20.5965379 },
    { lat: 48.9260413, lng: 20.5955852 },
    { lat: 48.9261812, lng: 20.5944868 },
    { lat: 48.9262825, lng: 20.5940802 },
    { lat: 48.9264932, lng: 20.5936544 },
    { lat: 48.9268665, lng: 20.5933931 },
    { lat: 48.9272232, lng: 20.5932622 },
    { lat: 48.9271559, lng: 20.5930538 },
    { lat: 48.9268971, lng: 20.5927168 },
    { lat: 48.9263785, lng: 20.5923937 },
    { lat: 48.9263236, lng: 20.5923035 },
    { lat: 48.9255101, lng: 20.5920457 },
    { lat: 48.925269, lng: 20.5919146 },
    { lat: 48.9250673, lng: 20.5916641 },
    { lat: 48.924992, lng: 20.5914495 },
    { lat: 48.9247305, lng: 20.5910405 },
    { lat: 48.9240466, lng: 20.5901607 },
    { lat: 48.9221908, lng: 20.588307 },
    { lat: 48.9213329, lng: 20.5876897 },
    { lat: 48.9208325, lng: 20.5872121 },
    { lat: 48.920567, lng: 20.5871567 },
    { lat: 48.9188639, lng: 20.5862083 },
    { lat: 48.9181944, lng: 20.5859385 },
    { lat: 48.9179478, lng: 20.5870461 },
    { lat: 48.9178875, lng: 20.5882321 },
    { lat: 48.9179193, lng: 20.5885462 },
    { lat: 48.9180207, lng: 20.5888613 },
    { lat: 48.9184416, lng: 20.5895922 },
    { lat: 48.9186463, lng: 20.590268 },
    { lat: 48.9188608, lng: 20.5916046 },
    { lat: 48.9186535, lng: 20.5924997 },
    { lat: 48.9185745, lng: 20.5935735 },
    { lat: 48.9181926, lng: 20.5950145 },
    { lat: 48.917818, lng: 20.5967177 },
    { lat: 48.9177477, lng: 20.5972931 },
    { lat: 48.9172228, lng: 20.5971635 },
    { lat: 48.9172614, lng: 20.597389 },
    { lat: 48.9170964, lng: 20.5977404 },
    { lat: 48.9169937, lng: 20.5975773 },
    { lat: 48.9169277, lng: 20.5976837 },
    { lat: 48.9169528, lng: 20.5981563 },
    { lat: 48.9168341, lng: 20.5981748 },
    { lat: 48.9168499, lng: 20.5983904 },
    { lat: 48.9167132, lng: 20.5986833 },
    { lat: 48.9167118, lng: 20.5989239 },
    { lat: 48.916826, lng: 20.5991835 },
    { lat: 48.9170161, lng: 20.5998935 },
    { lat: 48.9169873, lng: 20.6000871 },
    { lat: 48.9167611, lng: 20.6003724 },
    { lat: 48.9166697, lng: 20.6008314 },
    { lat: 48.9164603, lng: 20.6010788 },
    { lat: 48.9163274, lng: 20.6014465 },
    { lat: 48.9163735, lng: 20.6021538 },
    { lat: 48.9163137, lng: 20.6022426 },
    { lat: 48.9161717, lng: 20.6020867 },
    { lat: 48.9160621, lng: 20.6026911 },
    { lat: 48.9159912, lng: 20.6028495 },
    { lat: 48.9157649, lng: 20.602972 },
    { lat: 48.915585, lng: 20.603655 },
    { lat: 48.915485, lng: 20.6036959 },
    { lat: 48.9154013, lng: 20.604053 },
    { lat: 48.9153879, lng: 20.6045524 },
    { lat: 48.9147243, lng: 20.60423 },
    { lat: 48.9116135, lng: 20.6020924 },
    { lat: 48.9112883, lng: 20.6042314 },
    { lat: 48.9112703, lng: 20.6045661 },
    { lat: 48.9113855, lng: 20.6059119 },
    { lat: 48.9103487, lng: 20.6060682 },
    { lat: 48.909653, lng: 20.605823 },
    { lat: 48.9082258, lng: 20.6056176 },
    { lat: 48.9083857, lng: 20.6041898 },
    { lat: 48.9072161, lng: 20.6041325 },
    { lat: 48.9068006, lng: 20.6040417 },
    { lat: 48.9060863, lng: 20.6042422 },
    { lat: 48.9060251, lng: 20.6034897 },
    { lat: 48.9058465, lng: 20.603529 },
    { lat: 48.9058312, lng: 20.6032633 },
    { lat: 48.905309, lng: 20.6032674 },
    { lat: 48.9052475, lng: 20.6027568 },
    { lat: 48.9051096, lng: 20.6025258 },
    { lat: 48.9052378, lng: 20.600789 },
    { lat: 48.9051968, lng: 20.6002202 },
    { lat: 48.9052763, lng: 20.6000575 },
    { lat: 48.9051273, lng: 20.5990909 },
    { lat: 48.9049869, lng: 20.5987863 },
    { lat: 48.9050621, lng: 20.5984733 },
    { lat: 48.9050597, lng: 20.5980655 },
    { lat: 48.9039843, lng: 20.5979385 },
    { lat: 48.9026783, lng: 20.5981537 },
    { lat: 48.9020645, lng: 20.5980245 },
    { lat: 48.8996829, lng: 20.5966224 },
    { lat: 48.898996, lng: 20.5962499 },
    { lat: 48.8985703, lng: 20.5961175 },
    { lat: 48.8979768, lng: 20.5960875 },
    { lat: 48.8976742, lng: 20.596211 },
    { lat: 48.897529, lng: 20.5964933 },
    { lat: 48.8973366, lng: 20.5962179 },
    { lat: 48.8959635, lng: 20.5947806 },
    { lat: 48.8958766, lng: 20.5947418 },
    { lat: 48.895395, lng: 20.5948101 },
    { lat: 48.8947074, lng: 20.5942635 },
    { lat: 48.8942223, lng: 20.5937121 },
    { lat: 48.8927351, lng: 20.5911887 },
    { lat: 48.8923478, lng: 20.5906599 },
    { lat: 48.8915606, lng: 20.5880692 },
    { lat: 48.8907226, lng: 20.5862743 },
    { lat: 48.8898114, lng: 20.5854336 },
    { lat: 48.8889497, lng: 20.5847423 },
    { lat: 48.8880602, lng: 20.5846176 },
    { lat: 48.8881858, lng: 20.5826991 },
    { lat: 48.8882807, lng: 20.5820954 },
    { lat: 48.8879403, lng: 20.5814648 },
    { lat: 48.8897857, lng: 20.5785813 },
    { lat: 48.892477, lng: 20.5747555 },
    { lat: 48.8931184, lng: 20.5736155 },
    { lat: 48.893651, lng: 20.5729383 },
    { lat: 48.8966159, lng: 20.5704388 },
    { lat: 48.8981304, lng: 20.5692496 },
    { lat: 48.8981611, lng: 20.5689536 },
    { lat: 48.8982777, lng: 20.568967 },
    { lat: 48.8983469, lng: 20.5680333 },
    { lat: 48.8982949, lng: 20.5680305 },
    { lat: 48.8963943, lng: 20.5679324 },
    { lat: 48.894595, lng: 20.5673134 },
    { lat: 48.8937266, lng: 20.5661377 },
    { lat: 48.8929969, lng: 20.5664728 },
    { lat: 48.8922137, lng: 20.5667894 },
    { lat: 48.8911223, lng: 20.5671164 },
    { lat: 48.8870433, lng: 20.5689866 },
    { lat: 48.8841632, lng: 20.5700873 },
    { lat: 48.8829744, lng: 20.5706391 },
    { lat: 48.8819307, lng: 20.5710074 },
    { lat: 48.8805082, lng: 20.5716343 },
    { lat: 48.8733348, lng: 20.5743481 },
    { lat: 48.871697, lng: 20.5751557 },
    { lat: 48.8740606, lng: 20.5818016 },
    { lat: 48.874131, lng: 20.581997 },
    { lat: 48.874141, lng: 20.582002 },
    { lat: 48.874207, lng: 20.582037 },
    { lat: 48.874399, lng: 20.581888 },
    { lat: 48.8745, lng: 20.581978 },
    { lat: 48.874559, lng: 20.581944 },
    { lat: 48.874645, lng: 20.582077 },
    { lat: 48.874708, lng: 20.5822 },
    { lat: 48.874727, lng: 20.582242 },
    { lat: 48.874775, lng: 20.582268 },
    { lat: 48.874822, lng: 20.582236 },
    { lat: 48.874866, lng: 20.582256 },
    { lat: 48.874954, lng: 20.582233 },
    { lat: 48.875017, lng: 20.58229 },
    { lat: 48.875066, lng: 20.582276 },
    { lat: 48.875155, lng: 20.582379 },
    { lat: 48.87521, lng: 20.582479 },
    { lat: 48.875281, lng: 20.582464 },
    { lat: 48.875388, lng: 20.582506 },
    { lat: 48.875459, lng: 20.58244 },
    { lat: 48.875534, lng: 20.582427 },
    { lat: 48.875563, lng: 20.582543 },
    { lat: 48.875634, lng: 20.582623 },
    { lat: 48.875651, lng: 20.582723 },
    { lat: 48.875688, lng: 20.582779 },
    { lat: 48.875822, lng: 20.582897 },
    { lat: 48.875854, lng: 20.582927 },
    { lat: 48.875902, lng: 20.582916 },
    { lat: 48.87596, lng: 20.583054 },
    { lat: 48.875999, lng: 20.583139 },
    { lat: 48.876028, lng: 20.583189 },
    { lat: 48.876154, lng: 20.583238 },
    { lat: 48.876353, lng: 20.583326 },
    { lat: 48.876354, lng: 20.583364 },
    { lat: 48.876345, lng: 20.583434 },
    { lat: 48.876336, lng: 20.583483 },
    { lat: 48.876396, lng: 20.583556 },
    { lat: 48.876453, lng: 20.583666 },
    { lat: 48.876515, lng: 20.583895 },
    { lat: 48.876592, lng: 20.583864 },
    { lat: 48.876671, lng: 20.583993 },
    { lat: 48.876755, lng: 20.584058 },
    { lat: 48.87689, lng: 20.584158 },
    { lat: 48.877049, lng: 20.584311 },
    { lat: 48.877172, lng: 20.584419 },
    { lat: 48.877298, lng: 20.584514 },
    { lat: 48.877425, lng: 20.584585 },
    { lat: 48.877567, lng: 20.584663 },
    { lat: 48.877781, lng: 20.584793 },
    { lat: 48.877851, lng: 20.584709 },
    { lat: 48.877901, lng: 20.584604 },
    { lat: 48.877953, lng: 20.584653 },
    { lat: 48.878024, lng: 20.58467 },
    { lat: 48.878074, lng: 20.584681 },
    { lat: 48.878191, lng: 20.584633 },
    { lat: 48.878271, lng: 20.584621 },
    { lat: 48.878317, lng: 20.584673 },
    { lat: 48.878367, lng: 20.584669 },
    { lat: 48.878465, lng: 20.584713 },
    { lat: 48.878551, lng: 20.584691 },
    { lat: 48.878625, lng: 20.584645 },
    { lat: 48.878705, lng: 20.584686 },
    { lat: 48.878763, lng: 20.584634 },
    { lat: 48.878893, lng: 20.58469 },
    { lat: 48.878949, lng: 20.584717 },
    { lat: 48.878974, lng: 20.584784 },
    { lat: 48.879061, lng: 20.58483 },
    { lat: 48.879113, lng: 20.584788 },
    { lat: 48.879171, lng: 20.584847 },
    { lat: 48.879213, lng: 20.584788 },
    { lat: 48.879326, lng: 20.584777 },
    { lat: 48.879345, lng: 20.584718 },
    { lat: 48.879384, lng: 20.58464 },
    { lat: 48.879429, lng: 20.584598 },
    { lat: 48.879499, lng: 20.584625 },
    { lat: 48.879618, lng: 20.584686 },
    { lat: 48.879652, lng: 20.584706 },
    { lat: 48.87977, lng: 20.58488 },
    { lat: 48.879902, lng: 20.584971 },
    { lat: 48.879894, lng: 20.585087 },
    { lat: 48.879892, lng: 20.585107 },
    { lat: 48.879915, lng: 20.585215 },
    { lat: 48.879972, lng: 20.585207 },
    { lat: 48.880048, lng: 20.585236 },
    { lat: 48.880137, lng: 20.585227 },
    { lat: 48.880129, lng: 20.585348 },
    { lat: 48.880223, lng: 20.585581 },
    { lat: 48.880238, lng: 20.585451 },
    { lat: 48.880367, lng: 20.585539 },
    { lat: 48.880326, lng: 20.585768 },
    { lat: 48.880309, lng: 20.585829 },
    { lat: 48.880312, lng: 20.586062 },
    { lat: 48.880405, lng: 20.58611 },
    { lat: 48.880516, lng: 20.58616 },
    { lat: 48.880582, lng: 20.586328 },
    { lat: 48.880549, lng: 20.586415 },
    { lat: 48.880476, lng: 20.586454 },
    { lat: 48.880478, lng: 20.586539 },
    { lat: 48.880674, lng: 20.586686 },
    { lat: 48.880719, lng: 20.587255 },
    { lat: 48.880883, lng: 20.587484 },
    { lat: 48.880967, lng: 20.587665 },
    { lat: 48.881055, lng: 20.587687 },
    { lat: 48.881133, lng: 20.587756 },
    { lat: 48.881121, lng: 20.588122 },
    { lat: 48.881373, lng: 20.588336 },
    { lat: 48.881315, lng: 20.58856 },
    { lat: 48.881407, lng: 20.588673 },
    { lat: 48.881462, lng: 20.58884 },
    { lat: 48.881566, lng: 20.588942 },
    { lat: 48.881553, lng: 20.589028 },
    { lat: 48.881445, lng: 20.589107 },
    { lat: 48.881505, lng: 20.589349 },
    { lat: 48.881502, lng: 20.589485 },
    { lat: 48.881542, lng: 20.589775 },
    { lat: 48.881481, lng: 20.589972 },
    { lat: 48.881546, lng: 20.590073 },
    { lat: 48.881567, lng: 20.590305 },
    { lat: 48.881655, lng: 20.590407 },
    { lat: 48.881658, lng: 20.590538 },
    { lat: 48.881618, lng: 20.590622 },
    { lat: 48.881848, lng: 20.590843 },
    { lat: 48.881821, lng: 20.590969 },
    { lat: 48.881673, lng: 20.591019 },
    { lat: 48.881695, lng: 20.591203 },
    { lat: 48.881778, lng: 20.591427 },
    { lat: 48.881838, lng: 20.591567 },
    { lat: 48.881892, lng: 20.591669 },
    { lat: 48.881966, lng: 20.591789 },
    { lat: 48.882029, lng: 20.591889 },
    { lat: 48.882053, lng: 20.591957 },
    { lat: 48.882008, lng: 20.592032 },
    { lat: 48.882091, lng: 20.592185 },
    { lat: 48.882129, lng: 20.592242 },
    { lat: 48.882175, lng: 20.592299 },
    { lat: 48.882221, lng: 20.592354 },
    { lat: 48.88223, lng: 20.5924 },
    { lat: 48.882257, lng: 20.592623 },
    { lat: 48.882304, lng: 20.592668 },
    { lat: 48.882328, lng: 20.592754 },
    { lat: 48.882433, lng: 20.592727 },
    { lat: 48.882479, lng: 20.592851 },
    { lat: 48.8824, lng: 20.59289 },
    { lat: 48.882393, lng: 20.592988 },
    { lat: 48.882441, lng: 20.593055 },
    { lat: 48.882464, lng: 20.593085 },
    { lat: 48.882419, lng: 20.593165 },
    { lat: 48.882423, lng: 20.593265 },
    { lat: 48.882389, lng: 20.59344 },
    { lat: 48.882472, lng: 20.593443 },
    { lat: 48.882499, lng: 20.593556 },
    { lat: 48.882516, lng: 20.593653 },
    { lat: 48.882486, lng: 20.593759 },
    { lat: 48.882484, lng: 20.593855 },
    { lat: 48.882549, lng: 20.593881 },
    { lat: 48.882542, lng: 20.594032 },
    { lat: 48.882531, lng: 20.594187 },
    { lat: 48.8826, lng: 20.594225 },
    { lat: 48.88261, lng: 20.594278 },
    { lat: 48.88261, lng: 20.594328 },
    { lat: 48.882582, lng: 20.594461 },
    { lat: 48.882579, lng: 20.594634 },
    { lat: 48.882697, lng: 20.594735 },
    { lat: 48.88279, lng: 20.594812 },
    { lat: 48.882824, lng: 20.594842 },
    { lat: 48.882916, lng: 20.594805 },
    { lat: 48.882927, lng: 20.594928 },
    { lat: 48.883001, lng: 20.595017 },
    { lat: 48.883073, lng: 20.595189 },
    { lat: 48.883108, lng: 20.595289 },
    { lat: 48.883124, lng: 20.595355 },
    { lat: 48.883122, lng: 20.595489 },
    { lat: 48.883207, lng: 20.595586 },
    { lat: 48.883386, lng: 20.595547 },
    { lat: 48.883431, lng: 20.595553 },
    { lat: 48.883528, lng: 20.595564 },
    { lat: 48.883703, lng: 20.595577 },
    { lat: 48.88381, lng: 20.595636 },
    { lat: 48.883884, lng: 20.595704 },
    { lat: 48.883906, lng: 20.595735 },
    { lat: 48.883957, lng: 20.595897 },
    { lat: 48.883994, lng: 20.595946 },
    { lat: 48.884033, lng: 20.595981 },
    { lat: 48.884237, lng: 20.596053 },
    { lat: 48.884385, lng: 20.596408 },
    { lat: 48.884428, lng: 20.596647 },
    { lat: 48.884553, lng: 20.596834 },
    { lat: 48.884636, lng: 20.597018 },
    { lat: 48.884782, lng: 20.597172 },
    { lat: 48.884877, lng: 20.597361 },
    { lat: 48.884958, lng: 20.597478 },
    { lat: 48.885019, lng: 20.597699 },
    { lat: 48.885021, lng: 20.597846 },
    { lat: 48.884949, lng: 20.597996 },
    { lat: 48.884909, lng: 20.598074 },
    { lat: 48.884913, lng: 20.598223 },
    { lat: 48.884993, lng: 20.598265 },
    { lat: 48.885099, lng: 20.598329 },
    { lat: 48.885225, lng: 20.598408 },
    { lat: 48.885285, lng: 20.598462 },
    { lat: 48.885373, lng: 20.598544 },
    { lat: 48.885452, lng: 20.598627 },
    { lat: 48.885493, lng: 20.598657 },
    { lat: 48.885557, lng: 20.598691 },
    { lat: 48.885607, lng: 20.598772 },
    { lat: 48.885657, lng: 20.598849 },
    { lat: 48.885709, lng: 20.598927 },
    { lat: 48.885762, lng: 20.599006 },
    { lat: 48.885809, lng: 20.59908 },
    { lat: 48.886, lng: 20.599379 },
    { lat: 48.886075, lng: 20.599708 },
    { lat: 48.886168, lng: 20.599795 },
    { lat: 48.886237, lng: 20.599957 },
    { lat: 48.886418, lng: 20.600128 },
    { lat: 48.886501, lng: 20.600211 },
    { lat: 48.886575, lng: 20.600403 },
    { lat: 48.886728, lng: 20.600504 },
    { lat: 48.886801, lng: 20.600552 },
    { lat: 48.886908, lng: 20.600696 },
    { lat: 48.886955, lng: 20.60086 },
    { lat: 48.887033, lng: 20.601006 },
    { lat: 48.887132, lng: 20.601054 },
    { lat: 48.887283, lng: 20.601398 },
    { lat: 48.887342, lng: 20.601532 },
    { lat: 48.887405, lng: 20.601676 },
    { lat: 48.887411, lng: 20.601786 },
    { lat: 48.887529, lng: 20.601953 },
    { lat: 48.88766, lng: 20.602387 },
    { lat: 48.887813, lng: 20.602871 },
    { lat: 48.887957, lng: 20.603023 },
    { lat: 48.887975, lng: 20.603043 },
    { lat: 48.887965, lng: 20.603004 },
    { lat: 48.887998, lng: 20.602979 },
    { lat: 48.888005, lng: 20.602996 },
    { lat: 48.888014, lng: 20.603016 },
    { lat: 48.888194, lng: 20.603145 },
    { lat: 48.88825, lng: 20.6034 },
    { lat: 48.888327, lng: 20.603706 },
    { lat: 48.888404, lng: 20.604003 },
    { lat: 48.888492, lng: 20.604267 },
    { lat: 48.888547, lng: 20.604427 },
    { lat: 48.888681, lng: 20.604608 },
    { lat: 48.888822, lng: 20.60481 },
    { lat: 48.888948, lng: 20.604848 },
    { lat: 48.889095, lng: 20.604778 },
    { lat: 48.889221, lng: 20.60496 },
    { lat: 48.889187, lng: 20.605191 },
    { lat: 48.889193, lng: 20.605382 },
    { lat: 48.889247, lng: 20.605529 },
    { lat: 48.889374, lng: 20.605624 },
    { lat: 48.889414, lng: 20.605757 },
    { lat: 48.889401, lng: 20.60602 },
    { lat: 48.889456, lng: 20.606135 },
    { lat: 48.889528, lng: 20.606155 },
    { lat: 48.889596, lng: 20.606187 },
    { lat: 48.889716, lng: 20.606104 },
    { lat: 48.88975, lng: 20.606085 },
    { lat: 48.889827, lng: 20.606144 },
    { lat: 48.889849, lng: 20.606365 },
    { lat: 48.890002, lng: 20.606456 },
    { lat: 48.890128, lng: 20.606688 },
    { lat: 48.890235, lng: 20.606874 },
    { lat: 48.890343, lng: 20.607053 },
    { lat: 48.890425, lng: 20.607168 },
    { lat: 48.890537, lng: 20.607155 },
    { lat: 48.890653, lng: 20.607326 },
    { lat: 48.890694, lng: 20.607492 },
    { lat: 48.890759, lng: 20.607643 },
    { lat: 48.890861, lng: 20.607697 },
    { lat: 48.890958, lng: 20.607971 },
    { lat: 48.891066, lng: 20.608268 },
    { lat: 48.891157, lng: 20.608522 },
    { lat: 48.891148, lng: 20.60867 },
    { lat: 48.891192, lng: 20.608802 },
    { lat: 48.891294, lng: 20.60896 },
    { lat: 48.89135, lng: 20.60911 },
    { lat: 48.891452, lng: 20.609254 },
    { lat: 48.891618, lng: 20.609624 },
    { lat: 48.89152, lng: 20.609729 },
    { lat: 48.891465, lng: 20.609612 },
    { lat: 48.891353, lng: 20.609819 },
    { lat: 48.891395, lng: 20.610093 },
    { lat: 48.891426, lng: 20.610285 },
    { lat: 48.891483, lng: 20.61042 },
    { lat: 48.891583, lng: 20.610513 },
    { lat: 48.891581, lng: 20.610648 },
    { lat: 48.891632, lng: 20.610776 },
    { lat: 48.891736, lng: 20.610838 },
    { lat: 48.89186, lng: 20.610859 },
    { lat: 48.891889, lng: 20.610876 },
    { lat: 48.891985, lng: 20.611064 },
    { lat: 48.892, lng: 20.611266 },
    { lat: 48.892001, lng: 20.611451 },
    { lat: 48.892168, lng: 20.611552 },
    { lat: 48.892222, lng: 20.612066 },
    { lat: 48.892246, lng: 20.6123 },
    { lat: 48.892361, lng: 20.6125 },
    { lat: 48.892479, lng: 20.61292 },
    { lat: 48.892508, lng: 20.613402 },
    { lat: 48.892462, lng: 20.613695 },
    { lat: 48.892528, lng: 20.613945 },
    { lat: 48.892635, lng: 20.614134 },
    { lat: 48.892723, lng: 20.614298 },
    { lat: 48.892741, lng: 20.614574 },
    { lat: 48.892798, lng: 20.614713 },
    { lat: 48.892767, lng: 20.615014 },
    { lat: 48.892674, lng: 20.61525 },
    { lat: 48.892744, lng: 20.615603 },
    { lat: 48.89291, lng: 20.61574 },
    { lat: 48.893021, lng: 20.615833 },
    { lat: 48.893147, lng: 20.616037 },
    { lat: 48.89315, lng: 20.616296 },
    { lat: 48.893133, lng: 20.616632 },
    { lat: 48.893214, lng: 20.61687 },
    { lat: 48.893276, lng: 20.617052 },
    { lat: 48.893334, lng: 20.617106 },
    { lat: 48.893435, lng: 20.617202 },
    { lat: 48.893501, lng: 20.617269 },
    { lat: 48.893578, lng: 20.617391 },
    { lat: 48.893563, lng: 20.617576 },
    { lat: 48.893585, lng: 20.617739 },
    { lat: 48.893647, lng: 20.617892 },
    { lat: 48.893714, lng: 20.617938 },
    { lat: 48.893797, lng: 20.618002 },
    { lat: 48.893813, lng: 20.618064 },
    { lat: 48.893835, lng: 20.618152 },
    { lat: 48.893797, lng: 20.618259 },
    { lat: 48.893741, lng: 20.618394 },
    { lat: 48.893785, lng: 20.618536 },
    { lat: 48.89392, lng: 20.61858 },
    { lat: 48.893946, lng: 20.618847 },
    { lat: 48.893997, lng: 20.61923 },
    { lat: 48.894086, lng: 20.619362 },
    { lat: 48.894162, lng: 20.619474 },
    { lat: 48.894247, lng: 20.619514 },
    { lat: 48.894374, lng: 20.619652 },
    { lat: 48.89448, lng: 20.619632 },
    { lat: 48.894651, lng: 20.619683 },
    { lat: 48.894678, lng: 20.62 },
    { lat: 48.894719, lng: 20.620234 },
    { lat: 48.894734, lng: 20.620462 },
    { lat: 48.894672, lng: 20.620625 },
    { lat: 48.894646, lng: 20.620888 },
    { lat: 48.894657, lng: 20.621085 },
    { lat: 48.89476, lng: 20.62114 },
    { lat: 48.8948, lng: 20.621131 },
    { lat: 48.894946, lng: 20.62109 },
    { lat: 48.895154, lng: 20.620953 },
    { lat: 48.895239, lng: 20.620977 },
    { lat: 48.895281, lng: 20.621161 },
    { lat: 48.895318, lng: 20.621347 },
    { lat: 48.895326, lng: 20.621383 },
    { lat: 48.895333, lng: 20.621418 },
    { lat: 48.89533, lng: 20.621521 },
    { lat: 48.895321, lng: 20.621623 },
    { lat: 48.895309, lng: 20.621686 },
    { lat: 48.89527, lng: 20.621922 },
    { lat: 48.895248, lng: 20.621984 },
    { lat: 48.895233, lng: 20.622095 },
    { lat: 48.895252, lng: 20.622216 },
    { lat: 48.895258, lng: 20.622322 },
    { lat: 48.895272, lng: 20.62262 },
    { lat: 48.895273, lng: 20.622737 },
    { lat: 48.895271, lng: 20.622843 },
    { lat: 48.895263, lng: 20.62296 },
    { lat: 48.895212, lng: 20.623168 },
    { lat: 48.895183, lng: 20.623273 },
    { lat: 48.895104, lng: 20.623563 },
    { lat: 48.894995, lng: 20.623693 },
    { lat: 48.894952, lng: 20.62386 },
    { lat: 48.894846, lng: 20.624001 },
    { lat: 48.894686, lng: 20.624198 },
    { lat: 48.894559, lng: 20.624318 },
    { lat: 48.894525, lng: 20.624381 },
    { lat: 48.894527, lng: 20.624409 },
    { lat: 48.894545, lng: 20.624634 },
    { lat: 48.894547, lng: 20.624697 },
    { lat: 48.894583, lng: 20.624769 },
    { lat: 48.894602, lng: 20.62482 },
    { lat: 48.894642, lng: 20.625041 },
    { lat: 48.894667, lng: 20.625181 },
    { lat: 48.894685, lng: 20.625282 },
    { lat: 48.894697, lng: 20.625482 },
    { lat: 48.894704, lng: 20.625654 },
    { lat: 48.894711, lng: 20.625821 },
    { lat: 48.894654, lng: 20.62602 },
    { lat: 48.894532, lng: 20.62612 },
    { lat: 48.894493, lng: 20.62612 },
    { lat: 48.894392, lng: 20.626223 },
    { lat: 48.894326, lng: 20.626587 },
    { lat: 48.894325, lng: 20.626756 },
    { lat: 48.894294, lng: 20.626914 },
    { lat: 48.894342, lng: 20.627172 },
    { lat: 48.894443, lng: 20.627266 },
    { lat: 48.894546, lng: 20.627429 },
    { lat: 48.894609, lng: 20.627528 },
    { lat: 48.894615, lng: 20.627628 },
    { lat: 48.89456, lng: 20.627785 },
    { lat: 48.894571, lng: 20.628126 },
    { lat: 48.894634, lng: 20.628463 },
    { lat: 48.894612, lng: 20.628737 },
    { lat: 48.894546, lng: 20.628845 },
    { lat: 48.894507, lng: 20.629164 },
    { lat: 48.894524, lng: 20.629256 },
    { lat: 48.894608, lng: 20.629343 },
    { lat: 48.894744, lng: 20.629706 },
    { lat: 48.894779, lng: 20.629901 },
    { lat: 48.894813, lng: 20.630056 },
    { lat: 48.894842, lng: 20.630204 },
    { lat: 48.894895, lng: 20.63032 },
    { lat: 48.894961, lng: 20.630448 },
    { lat: 48.89504, lng: 20.630596 },
    { lat: 48.895139, lng: 20.630725 },
    { lat: 48.89519, lng: 20.630776 },
    { lat: 48.895241, lng: 20.630831 },
    { lat: 48.895308, lng: 20.63091 },
    { lat: 48.895387, lng: 20.631004 },
    { lat: 48.895466, lng: 20.631128 },
    { lat: 48.895533, lng: 20.631211 },
    { lat: 48.895615, lng: 20.631296 },
    { lat: 48.895686, lng: 20.631378 },
    { lat: 48.895746, lng: 20.631491 },
    { lat: 48.895808, lng: 20.631547 },
    { lat: 48.895854, lng: 20.631602 },
    { lat: 48.895909, lng: 20.631676 },
    { lat: 48.895926, lng: 20.631706 },
    { lat: 48.895991, lng: 20.631908 },
    { lat: 48.896091, lng: 20.6322 },
    { lat: 48.896169, lng: 20.632433 },
    { lat: 48.89622, lng: 20.63259 },
    { lat: 48.896269, lng: 20.63274 },
    { lat: 48.896282, lng: 20.632802 },
    { lat: 48.896294, lng: 20.633085 },
    { lat: 48.896267, lng: 20.633323 },
    { lat: 48.896302, lng: 20.633466 },
    { lat: 48.896342, lng: 20.633584 },
    { lat: 48.896345, lng: 20.633636 },
    { lat: 48.896338, lng: 20.633827 },
    { lat: 48.896321, lng: 20.634061 },
    { lat: 48.896331, lng: 20.634269 },
    { lat: 48.896341, lng: 20.634422 },
    { lat: 48.896331, lng: 20.634626 },
    { lat: 48.896324, lng: 20.634811 },
    { lat: 48.896301, lng: 20.63493 },
    { lat: 48.896224, lng: 20.635339 },
    { lat: 48.896205, lng: 20.635398 },
    { lat: 48.896088, lng: 20.635689 },
    { lat: 48.896164, lng: 20.635787 },
    { lat: 48.896197, lng: 20.635846 },
    { lat: 48.896275, lng: 20.636114 },
    { lat: 48.896332, lng: 20.636184 },
    { lat: 48.896385, lng: 20.636436 },
    { lat: 48.896388, lng: 20.636531 },
    { lat: 48.896467, lng: 20.63658 },
    { lat: 48.896499, lng: 20.636676 },
    { lat: 48.896563, lng: 20.636626 },
    { lat: 48.896617, lng: 20.636596 },
    { lat: 48.896677, lng: 20.636665 },
    { lat: 48.896923, lng: 20.636952 },
    { lat: 48.897034, lng: 20.637008 },
    { lat: 48.897067, lng: 20.637039 },
    { lat: 48.897102, lng: 20.63707 },
    { lat: 48.897197, lng: 20.637248 },
    { lat: 48.897345, lng: 20.637521 },
    { lat: 48.897363, lng: 20.637639 },
    { lat: 48.89746, lng: 20.637802 },
    { lat: 48.897443, lng: 20.637925 },
    { lat: 48.897546, lng: 20.638104 },
    { lat: 48.897594, lng: 20.638197 },
    { lat: 48.897516, lng: 20.638306 },
    { lat: 48.897575, lng: 20.638553 },
    { lat: 48.897683, lng: 20.638606 },
    { lat: 48.897769, lng: 20.638653 },
    { lat: 48.897807, lng: 20.638752 },
    { lat: 48.897928, lng: 20.63883 },
    { lat: 48.897995, lng: 20.638876 },
    { lat: 48.898129, lng: 20.638871 },
    { lat: 48.898156, lng: 20.638883 },
    { lat: 48.898178, lng: 20.638909 },
    { lat: 48.898193, lng: 20.638956 },
    { lat: 48.898197, lng: 20.639015 },
    { lat: 48.898193, lng: 20.639119 },
    { lat: 48.898297, lng: 20.63936 },
    { lat: 48.898358, lng: 20.639286 },
    { lat: 48.89845, lng: 20.639393 },
    { lat: 48.89849, lng: 20.639454 },
    { lat: 48.898526, lng: 20.639524 },
    { lat: 48.898556, lng: 20.639614 },
    { lat: 48.89862, lng: 20.639656 },
    { lat: 48.898665, lng: 20.639776 },
    { lat: 48.898796, lng: 20.64015 },
    { lat: 48.898815, lng: 20.640166 },
    { lat: 48.898856, lng: 20.640148 },
    { lat: 48.898959, lng: 20.640534 },
    { lat: 48.899002, lng: 20.64069 },
    { lat: 48.899048, lng: 20.640859 },
    { lat: 48.899075, lng: 20.640961 },
    { lat: 48.899055, lng: 20.641178 },
    { lat: 48.899037, lng: 20.641165 },
    { lat: 48.898934, lng: 20.64111 },
    { lat: 48.898461, lng: 20.640835 },
    { lat: 48.898332, lng: 20.640811 },
    { lat: 48.898269, lng: 20.640794 },
    { lat: 48.898139, lng: 20.640826 },
    { lat: 48.898066, lng: 20.640838 },
    { lat: 48.898051, lng: 20.640848 },
    { lat: 48.898066, lng: 20.640969 },
    { lat: 48.898127, lng: 20.641107 },
    { lat: 48.898186, lng: 20.641254 },
    { lat: 48.898263, lng: 20.641435 },
    { lat: 48.898362, lng: 20.641673 },
    { lat: 48.89851, lng: 20.642111 },
    { lat: 48.898638, lng: 20.642477 },
    { lat: 48.89877, lng: 20.642869 },
    { lat: 48.898821, lng: 20.643009 },
    { lat: 48.898874, lng: 20.643297 },
    { lat: 48.89893, lng: 20.643611 },
    { lat: 48.89896, lng: 20.643764 },
    { lat: 48.898936, lng: 20.643761 },
    { lat: 48.898751, lng: 20.643789 },
    { lat: 48.898401, lng: 20.643828 },
    { lat: 48.897954, lng: 20.643873 },
    { lat: 48.897454, lng: 20.643932 },
    { lat: 48.897037, lng: 20.643981 },
    { lat: 48.896982, lng: 20.643989 },
    { lat: 48.896876, lng: 20.644 },
    { lat: 48.896603, lng: 20.644027 },
    { lat: 48.895998, lng: 20.644095 },
    { lat: 48.895482, lng: 20.644153 },
    { lat: 48.89527, lng: 20.64418 },
    { lat: 48.895055, lng: 20.644201 },
    { lat: 48.894955, lng: 20.644204 },
    { lat: 48.894666, lng: 20.644235 },
    { lat: 48.894349, lng: 20.64427 },
    { lat: 48.894206, lng: 20.644289 },
    { lat: 48.894175, lng: 20.644293 },
    { lat: 48.894211, lng: 20.644859 },
    { lat: 48.894255, lng: 20.645628 },
    { lat: 48.894273, lng: 20.645957 },
    { lat: 48.894337, lng: 20.647088 },
    { lat: 48.89438, lng: 20.64782 },
    { lat: 48.894471, lng: 20.648728 },
    { lat: 48.894561, lng: 20.649589 },
    { lat: 48.894606, lng: 20.650034 },
    { lat: 48.894633, lng: 20.650334 },
    { lat: 48.894696, lng: 20.650938 },
    { lat: 48.894716, lng: 20.65109 },
    { lat: 48.894738, lng: 20.65142 },
    { lat: 48.894749, lng: 20.651575 },
    { lat: 48.894762, lng: 20.651747 },
    { lat: 48.894787, lng: 20.652131 },
    { lat: 48.89481, lng: 20.652477 },
    { lat: 48.894839, lng: 20.652919 },
    { lat: 48.894896, lng: 20.653752 },
    { lat: 48.89492, lng: 20.654066 },
    { lat: 48.894989, lng: 20.655119 },
    { lat: 48.895016, lng: 20.655502 },
    { lat: 48.895066, lng: 20.656259 },
    { lat: 48.895085, lng: 20.656557 },
    { lat: 48.895119, lng: 20.657115 },
    { lat: 48.895146, lng: 20.657532 },
    { lat: 48.895183, lng: 20.658152 },
    { lat: 48.8958141, lng: 20.6582054 },
    { lat: 48.8959015, lng: 20.6580712 },
    { lat: 48.8960803, lng: 20.658221 },
    { lat: 48.8966767, lng: 20.6584038 },
    { lat: 48.8970155, lng: 20.6583994 },
    { lat: 48.8972134, lng: 20.6585154 },
    { lat: 48.8974995, lng: 20.6584309 },
    { lat: 48.8986595, lng: 20.6588191 },
    { lat: 48.8997369, lng: 20.6594428 },
    { lat: 48.9003686, lng: 20.6602028 },
    { lat: 48.900642, lng: 20.6600696 },
    { lat: 48.9009142, lng: 20.6600299 },
    { lat: 48.9011878, lng: 20.6601261 },
    { lat: 48.9013524, lng: 20.6600073 },
    { lat: 48.901717, lng: 20.6606924 },
    { lat: 48.9012758, lng: 20.6613218 },
    { lat: 48.9004858, lng: 20.6627748 },
    { lat: 48.8996645, lng: 20.6637572 },
    { lat: 48.8992806, lng: 20.6643847 },
    { lat: 48.8987525, lng: 20.6650709 },
    { lat: 48.8996674, lng: 20.668352 },
    { lat: 48.8996508, lng: 20.668431 },
    { lat: 48.9001083, lng: 20.6684398 },
    { lat: 48.9007939, lng: 20.6681903 },
    { lat: 48.9012655, lng: 20.6681858 },
    { lat: 48.9019971, lng: 20.6674634 },
    { lat: 48.902274, lng: 20.6673028 },
    { lat: 48.9031701, lng: 20.6671762 },
    { lat: 48.9034897, lng: 20.6669845 },
    { lat: 48.9038783, lng: 20.6677086 },
    { lat: 48.9045882, lng: 20.6678897 },
    { lat: 48.905018, lng: 20.6679151 },
    { lat: 48.9069678, lng: 20.6677838 },
    { lat: 48.9089013, lng: 20.6682076 },
    { lat: 48.9096602, lng: 20.6685282 },
    { lat: 48.9113698, lng: 20.6683571 },
    { lat: 48.912711, lng: 20.6662954 },
    { lat: 48.9131489, lng: 20.6659214 },
    { lat: 48.913145, lng: 20.6657818 },
    { lat: 48.9135588, lng: 20.6644219 },
    { lat: 48.9134201, lng: 20.6642243 },
    { lat: 48.9144429, lng: 20.6634268 },
    { lat: 48.9143108, lng: 20.6630054 },
    { lat: 48.9146885, lng: 20.6627122 },
    { lat: 48.9156153, lng: 20.6658243 },
    { lat: 48.9158884, lng: 20.6656525 },
    { lat: 48.9159464, lng: 20.6654476 },
    { lat: 48.9164198, lng: 20.6662465 },
    { lat: 48.9166156, lng: 20.6668112 },
    { lat: 48.9171065, lng: 20.6665294 },
    { lat: 48.9177922, lng: 20.6665011 },
  ],
  MatejovcenadHornádom: [
    { lat: 48.9216985, lng: 20.6804921 },
    { lat: 48.9218498, lng: 20.6805928 },
    { lat: 48.922151, lng: 20.6798907 },
    { lat: 48.9220979, lng: 20.6798165 },
    { lat: 48.9224359, lng: 20.6787296 },
    { lat: 48.922405, lng: 20.6770486 },
    { lat: 48.9223497, lng: 20.6766952 },
    { lat: 48.9221009, lng: 20.6760309 },
    { lat: 48.9213887, lng: 20.6751933 },
    { lat: 48.9203616, lng: 20.6734896 },
    { lat: 48.9196684, lng: 20.6728652 },
    { lat: 48.918976, lng: 20.6715152 },
    { lat: 48.9180411, lng: 20.6699608 },
    { lat: 48.9180684, lng: 20.6694993 },
    { lat: 48.9182241, lng: 20.6687827 },
    { lat: 48.9182249, lng: 20.6677972 },
    { lat: 48.9182234, lng: 20.6675712 },
    { lat: 48.9182208, lng: 20.6674332 },
    { lat: 48.9178605, lng: 20.6669636 },
    { lat: 48.9178199, lng: 20.6666488 },
    { lat: 48.9179252, lng: 20.6665062 },
    { lat: 48.9177922, lng: 20.6665011 },
    { lat: 48.9171065, lng: 20.6665294 },
    { lat: 48.9166156, lng: 20.6668112 },
    { lat: 48.9164198, lng: 20.6662465 },
    { lat: 48.9159464, lng: 20.6654476 },
    { lat: 48.9158884, lng: 20.6656525 },
    { lat: 48.9156153, lng: 20.6658243 },
    { lat: 48.9146885, lng: 20.6627122 },
    { lat: 48.9143108, lng: 20.6630054 },
    { lat: 48.9144429, lng: 20.6634268 },
    { lat: 48.9134201, lng: 20.6642243 },
    { lat: 48.9135588, lng: 20.6644219 },
    { lat: 48.913145, lng: 20.6657818 },
    { lat: 48.9131489, lng: 20.6659214 },
    { lat: 48.912711, lng: 20.6662954 },
    { lat: 48.9113698, lng: 20.6683571 },
    { lat: 48.9096602, lng: 20.6685282 },
    { lat: 48.9089013, lng: 20.6682076 },
    { lat: 48.9069678, lng: 20.6677838 },
    { lat: 48.905018, lng: 20.6679151 },
    { lat: 48.9045882, lng: 20.6678897 },
    { lat: 48.9038783, lng: 20.6677086 },
    { lat: 48.9034897, lng: 20.6669845 },
    { lat: 48.9031701, lng: 20.6671762 },
    { lat: 48.902274, lng: 20.6673028 },
    { lat: 48.9019971, lng: 20.6674634 },
    { lat: 48.9012655, lng: 20.6681858 },
    { lat: 48.9007939, lng: 20.6681903 },
    { lat: 48.9001083, lng: 20.6684398 },
    { lat: 48.8996508, lng: 20.668431 },
    { lat: 48.8980406, lng: 20.6693596 },
    { lat: 48.8973823, lng: 20.6708093 },
    { lat: 48.8973718, lng: 20.6711748 },
    { lat: 48.8971962, lng: 20.6719024 },
    { lat: 48.8966905, lng: 20.6733742 },
    { lat: 48.8962008, lng: 20.6740918 },
    { lat: 48.8957274, lng: 20.6756471 },
    { lat: 48.8951827, lng: 20.6768154 },
    { lat: 48.8951243, lng: 20.6779236 },
    { lat: 48.8951341, lng: 20.6796433 },
    { lat: 48.8951829, lng: 20.6810064 },
    { lat: 48.89532, lng: 20.6825715 },
    { lat: 48.8953198, lng: 20.6825983 },
    { lat: 48.8953205, lng: 20.6829357 },
    { lat: 48.8950729, lng: 20.684415 },
    { lat: 48.8946757, lng: 20.6852423 },
    { lat: 48.8944968, lng: 20.6870444 },
    { lat: 48.894561, lng: 20.6879218 },
    { lat: 48.8961768, lng: 20.6890685 },
    { lat: 48.8968005, lng: 20.6892939 },
    { lat: 48.8982812, lng: 20.6895226 },
    { lat: 48.8994109, lng: 20.6887833 },
    { lat: 48.9001758, lng: 20.6888568 },
    { lat: 48.9005569, lng: 20.6890667 },
    { lat: 48.9018391, lng: 20.6893797 },
    { lat: 48.9026046, lng: 20.6894395 },
    { lat: 48.9037036, lng: 20.6886884 },
    { lat: 48.9047448, lng: 20.6872989 },
    { lat: 48.9051743, lng: 20.687001 },
    { lat: 48.9069747, lng: 20.6854674 },
    { lat: 48.9095728, lng: 20.6849883 },
    { lat: 48.9109083, lng: 20.6848395 },
    { lat: 48.9110926, lng: 20.6849278 },
    { lat: 48.9113359, lng: 20.6849285 },
    { lat: 48.9118879, lng: 20.6852865 },
    { lat: 48.9120073, lng: 20.6847517 },
    { lat: 48.9121014, lng: 20.6839837 },
    { lat: 48.9126699, lng: 20.683257 },
    { lat: 48.9140495, lng: 20.6826453 },
    { lat: 48.9146676, lng: 20.6821116 },
    { lat: 48.915787, lng: 20.682405 },
    { lat: 48.9164755, lng: 20.681984 },
    { lat: 48.9189905, lng: 20.6809098 },
    { lat: 48.9209014, lng: 20.680993 },
    { lat: 48.9213562, lng: 20.6808675 },
    { lat: 48.9214888, lng: 20.6807285 },
    { lat: 48.9216985, lng: 20.6804921 },
  ],
  Betlanovce: [
    { lat: 48.936109, lng: 20.304218 },
    { lat: 48.936421, lng: 20.3048881 },
    { lat: 48.9369175, lng: 20.3050266 },
    { lat: 48.9375315, lng: 20.3049184 },
    { lat: 48.9378015, lng: 20.3050901 },
    { lat: 48.9381865, lng: 20.3057475 },
    { lat: 48.9384175, lng: 20.3059592 },
    { lat: 48.938508, lng: 20.3064891 },
    { lat: 48.9388564, lng: 20.3071037 },
    { lat: 48.9391756, lng: 20.3080454 },
    { lat: 48.9399941, lng: 20.3100935 },
    { lat: 48.9409201, lng: 20.3120404 },
    { lat: 48.9411242, lng: 20.3123072 },
    { lat: 48.9413599, lng: 20.312871 },
    { lat: 48.9417597, lng: 20.3135631 },
    { lat: 48.9418053, lng: 20.3139534 },
    { lat: 48.9420842, lng: 20.3139452 },
    { lat: 48.942778, lng: 20.3144404 },
    { lat: 48.943557, lng: 20.3147818 },
    { lat: 48.9439726, lng: 20.3152755 },
    { lat: 48.9441883, lng: 20.3153779 },
    { lat: 48.9443025, lng: 20.3157976 },
    { lat: 48.9445055, lng: 20.3160355 },
    { lat: 48.9448155, lng: 20.3160446 },
    { lat: 48.9450977, lng: 20.3163801 },
    { lat: 48.9452946, lng: 20.3166978 },
    { lat: 48.9458448, lng: 20.3185353 },
    { lat: 48.9461288, lng: 20.3190924 },
    { lat: 48.9463248, lng: 20.32008 },
    { lat: 48.9465894, lng: 20.3207503 },
    { lat: 48.9465314, lng: 20.3210797 },
    { lat: 48.9470628, lng: 20.3221785 },
    { lat: 48.9471266, lng: 20.3224626 },
    { lat: 48.9473673, lng: 20.3228624 },
    { lat: 48.9475589, lng: 20.3233303 },
    { lat: 48.9477987, lng: 20.3236735 },
    { lat: 48.9481086, lng: 20.3243193 },
    { lat: 48.9483536, lng: 20.3246256 },
    { lat: 48.9486082, lng: 20.32518 },
    { lat: 48.9486814, lng: 20.325211 },
    { lat: 48.948813, lng: 20.3254715 },
    { lat: 48.9489428, lng: 20.3260005 },
    { lat: 48.9492576, lng: 20.3267982 },
    { lat: 48.9496992, lng: 20.3272384 },
    { lat: 48.949839, lng: 20.3280266 },
    { lat: 48.9498298, lng: 20.3283728 },
    { lat: 48.9499, lng: 20.3286704 },
    { lat: 48.9504388, lng: 20.3292675 },
    { lat: 48.9510416, lng: 20.3294192 },
    { lat: 48.9513842, lng: 20.3299544 },
    { lat: 48.9515875, lng: 20.3308699 },
    { lat: 48.9517494, lng: 20.3312986 },
    { lat: 48.9523346, lng: 20.3316942 },
    { lat: 48.9526895, lng: 20.3316711 },
    { lat: 48.9529011, lng: 20.3317529 },
    { lat: 48.9530528, lng: 20.332448 },
    { lat: 48.9549948, lng: 20.3350808 },
    { lat: 48.9557074, lng: 20.3358911 },
    { lat: 48.9557297, lng: 20.3358597 },
    { lat: 48.955816, lng: 20.3360208 },
    { lat: 48.9563546, lng: 20.3378603 },
    { lat: 48.9563824, lng: 20.3387572 },
    { lat: 48.9568605, lng: 20.340491 },
    { lat: 48.9572202, lng: 20.3412627 },
    { lat: 48.9573267, lng: 20.3416007 },
    { lat: 48.957982, lng: 20.3428226 },
    { lat: 48.9580174, lng: 20.3437095 },
    { lat: 48.958365, lng: 20.3448402 },
    { lat: 48.958906, lng: 20.3460179 },
    { lat: 48.9590172, lng: 20.3465347 },
    { lat: 48.9594446, lng: 20.3479246 },
    { lat: 48.9595983, lng: 20.3480843 },
    { lat: 48.959784, lng: 20.3486963 },
    { lat: 48.9600302, lng: 20.3490661 },
    { lat: 48.9601806, lng: 20.3503334 },
    { lat: 48.9600891, lng: 20.3506171 },
    { lat: 48.9614155, lng: 20.3523997 },
    { lat: 48.9614881, lng: 20.3525878 },
    { lat: 48.9613694, lng: 20.3542109 },
    { lat: 48.9620689, lng: 20.3555925 },
    { lat: 48.9618666, lng: 20.3558961 },
    { lat: 48.9622511, lng: 20.3566703 },
    { lat: 48.9626459, lng: 20.3573025 },
    { lat: 48.9634271, lng: 20.3582337 },
    { lat: 48.9643019, lng: 20.3590157 },
    { lat: 48.9646614, lng: 20.3594353 },
    { lat: 48.9653858, lng: 20.3605256 },
    { lat: 48.965602, lng: 20.3606996 },
    { lat: 48.9656976, lng: 20.3613993 },
    { lat: 48.9656863, lng: 20.3619848 },
    { lat: 48.9658419, lng: 20.3638943 },
    { lat: 48.9663144, lng: 20.3651759 },
    { lat: 48.9666385, lng: 20.3658721 },
    { lat: 48.9663818, lng: 20.3663138 },
    { lat: 48.9667698, lng: 20.367356 },
    { lat: 48.9674473, lng: 20.3699243 },
    { lat: 48.9674197, lng: 20.369998 },
    { lat: 48.9675478, lng: 20.3705214 },
    { lat: 48.9677888, lng: 20.3712863 },
    { lat: 48.9691735, lng: 20.3739767 },
    { lat: 48.9694437, lng: 20.3745915 },
    { lat: 48.969769, lng: 20.3749723 },
    { lat: 48.9705935, lng: 20.3757437 },
    { lat: 48.9721281, lng: 20.3783839 },
    { lat: 48.9730307, lng: 20.3796915 },
    { lat: 48.9735958, lng: 20.3808372 },
    { lat: 48.9756675, lng: 20.3840679 },
    { lat: 48.9765501, lng: 20.3858846 },
    { lat: 48.977594, lng: 20.387423 },
    { lat: 48.9775808, lng: 20.3875163 },
    { lat: 48.9764285, lng: 20.3885671 },
    { lat: 48.9763589, lng: 20.3890736 },
    { lat: 48.9764459, lng: 20.3892592 },
    { lat: 48.9762667, lng: 20.3891932 },
    { lat: 48.9761615, lng: 20.3892434 },
    { lat: 48.9759582, lng: 20.3895484 },
    { lat: 48.9758745, lng: 20.3898409 },
    { lat: 48.9758201, lng: 20.3900309 },
    { lat: 48.9757942, lng: 20.3901213 },
    { lat: 48.9758892, lng: 20.3902509 },
    { lat: 48.9760616, lng: 20.390106 },
    { lat: 48.976312, lng: 20.3903782 },
    { lat: 48.9764744, lng: 20.3910958 },
    { lat: 48.9764522, lng: 20.39122 },
    { lat: 48.9763503, lng: 20.3912949 },
    { lat: 48.9759125, lng: 20.391126 },
    { lat: 48.9755416, lng: 20.3914343 },
    { lat: 48.9753808, lng: 20.3914994 },
    { lat: 48.97505, lng: 20.3915375 },
    { lat: 48.9748147, lng: 20.3914486 },
    { lat: 48.9747496, lng: 20.3916045 },
    { lat: 48.9748092, lng: 20.3917501 },
    { lat: 48.974765, lng: 20.3918888 },
    { lat: 48.9748043, lng: 20.3921764 },
    { lat: 48.9744862, lng: 20.3923725 },
    { lat: 48.9753809, lng: 20.3939474 },
    { lat: 48.9754007, lng: 20.393912 },
    { lat: 48.9784969, lng: 20.3994794 },
    { lat: 48.9814553, lng: 20.4017964 },
    { lat: 48.9822484, lng: 20.402635 },
    { lat: 48.9830542, lng: 20.4032739 },
    { lat: 48.98307, lng: 20.403336 },
    { lat: 48.9833394, lng: 20.4032833 },
    { lat: 48.9834604, lng: 20.4033881 },
    { lat: 48.9836007, lng: 20.4032553 },
    { lat: 48.9836656, lng: 20.4033302 },
    { lat: 48.9838243, lng: 20.4032417 },
    { lat: 48.9838286, lng: 20.4031202 },
    { lat: 48.9839005, lng: 20.402969 },
    { lat: 48.9840723, lng: 20.4028948 },
    { lat: 48.9850136, lng: 20.4032812 },
    { lat: 48.9852517, lng: 20.4035299 },
    { lat: 48.985469, lng: 20.403885 },
    { lat: 48.986048, lng: 20.404248 },
    { lat: 48.987014, lng: 20.404389 },
    { lat: 48.987366, lng: 20.404384 },
    { lat: 48.987933, lng: 20.404333 },
    { lat: 48.988037, lng: 20.404321 },
    { lat: 48.988578, lng: 20.404234 },
    { lat: 48.988711, lng: 20.40418 },
    { lat: 48.989016, lng: 20.404167 },
    { lat: 48.989856, lng: 20.404058 },
    { lat: 48.990221, lng: 20.403897 },
    { lat: 48.990756, lng: 20.403644 },
    { lat: 48.991237, lng: 20.403356 },
    { lat: 48.992097, lng: 20.402861 },
    { lat: 48.991188, lng: 20.40049 },
    { lat: 48.990403, lng: 20.398475 },
    { lat: 48.989965, lng: 20.39738 },
    { lat: 48.98996, lng: 20.397359 },
    { lat: 48.989956, lng: 20.397342 },
    { lat: 48.98979, lng: 20.396725 },
    { lat: 48.989462, lng: 20.395442 },
    { lat: 48.989615, lng: 20.395274 },
    { lat: 48.989374, lng: 20.394344 },
    { lat: 48.988402, lng: 20.390146 },
    { lat: 48.988228, lng: 20.389508 },
    { lat: 48.987823, lng: 20.38825 },
    { lat: 48.988232, lng: 20.387765 },
    { lat: 48.98843, lng: 20.387532 },
    { lat: 48.988039, lng: 20.3867 },
    { lat: 48.988028, lng: 20.386716 },
    { lat: 48.987961, lng: 20.38659 },
    { lat: 48.987839, lng: 20.386364 },
    { lat: 48.987018, lng: 20.385165 },
    { lat: 48.986925, lng: 20.385071 },
    { lat: 48.986811, lng: 20.385057 },
    { lat: 48.986803, lng: 20.385033 },
    { lat: 48.986789, lng: 20.384985 },
    { lat: 48.986769, lng: 20.384956 },
    { lat: 48.986277, lng: 20.385652 },
    { lat: 48.986192, lng: 20.385674 },
    { lat: 48.986098, lng: 20.385593 },
    { lat: 48.98601, lng: 20.385303 },
    { lat: 48.985972, lng: 20.385227 },
    { lat: 48.985692, lng: 20.384817 },
    { lat: 48.985568, lng: 20.384671 },
    { lat: 48.985478, lng: 20.384528 },
    { lat: 48.985296, lng: 20.384135 },
    { lat: 48.9851964, lng: 20.3840984 },
    { lat: 48.98513, lng: 20.384074 },
    { lat: 48.985037, lng: 20.383957 },
    { lat: 48.984939, lng: 20.383778 },
    { lat: 48.984926, lng: 20.383615 },
    { lat: 48.984586, lng: 20.383091 },
    { lat: 48.984607, lng: 20.38305 },
    { lat: 48.984571, lng: 20.382862 },
    { lat: 48.98439, lng: 20.382709 },
    { lat: 48.984333, lng: 20.382684 },
    { lat: 48.98419, lng: 20.382675 },
    { lat: 48.98412, lng: 20.382559 },
    { lat: 48.98407, lng: 20.38255 },
    { lat: 48.983598, lng: 20.382698 },
    { lat: 48.983573, lng: 20.382702 },
    { lat: 48.983074, lng: 20.381932 },
    { lat: 48.982906, lng: 20.380961 },
    { lat: 48.98282, lng: 20.380574 },
    { lat: 48.982366, lng: 20.379291 },
    { lat: 48.982212, lng: 20.378975 },
    { lat: 48.981867, lng: 20.378506 },
    { lat: 48.981664, lng: 20.378094 },
    { lat: 48.981348, lng: 20.377578 },
    { lat: 48.981096, lng: 20.377285 },
    { lat: 48.981045, lng: 20.377303 },
    { lat: 48.980935, lng: 20.377132 },
    { lat: 48.981027, lng: 20.377007 },
    { lat: 48.980946, lng: 20.376838 },
    { lat: 48.980896, lng: 20.376918 },
    { lat: 48.980809, lng: 20.376865 },
    { lat: 48.980811, lng: 20.376831 },
    { lat: 48.980854, lng: 20.376806 },
    { lat: 48.980874, lng: 20.376721 },
    { lat: 48.980849, lng: 20.376609 },
    { lat: 48.98091, lng: 20.376519 },
    { lat: 48.980974, lng: 20.376574 },
    { lat: 48.981076, lng: 20.376504 },
    { lat: 48.980996, lng: 20.376418 },
    { lat: 48.980998, lng: 20.376238 },
    { lat: 48.981049, lng: 20.37617 },
    { lat: 48.981046, lng: 20.376003 },
    { lat: 48.980989, lng: 20.375952 },
    { lat: 48.980985, lng: 20.37587 },
    { lat: 48.981035, lng: 20.3758 },
    { lat: 48.981008, lng: 20.375736 },
    { lat: 48.981081, lng: 20.375676 },
    { lat: 48.981023, lng: 20.375525 },
    { lat: 48.981092, lng: 20.37553 },
    { lat: 48.981116, lng: 20.375446 },
    { lat: 48.981178, lng: 20.37542 },
    { lat: 48.981182, lng: 20.375561 },
    { lat: 48.981262, lng: 20.37562 },
    { lat: 48.981324, lng: 20.375579 },
    { lat: 48.981373, lng: 20.375481 },
    { lat: 48.981351, lng: 20.375383 },
    { lat: 48.98132, lng: 20.375384 },
    { lat: 48.981279, lng: 20.375385 },
    { lat: 48.981249, lng: 20.375285 },
    { lat: 48.981111, lng: 20.375047 },
    { lat: 48.98113, lng: 20.374929 },
    { lat: 48.981057, lng: 20.374894 },
    { lat: 48.981068, lng: 20.374598 },
    { lat: 48.981038, lng: 20.374352 },
    { lat: 48.981065, lng: 20.374306 },
    { lat: 48.981116, lng: 20.374356 },
    { lat: 48.98117, lng: 20.374363 },
    { lat: 48.981162, lng: 20.374322 },
    { lat: 48.981149, lng: 20.374313 },
    { lat: 48.981132, lng: 20.374212 },
    { lat: 48.981015, lng: 20.374106 },
    { lat: 48.980996, lng: 20.374165 },
    { lat: 48.980958, lng: 20.374172 },
    { lat: 48.980862, lng: 20.374073 },
    { lat: 48.980926, lng: 20.37386 },
    { lat: 48.981005, lng: 20.373783 },
    { lat: 48.980985, lng: 20.373698 },
    { lat: 48.980881, lng: 20.373698 },
    { lat: 48.980893, lng: 20.373598 },
    { lat: 48.980831, lng: 20.373537 },
    { lat: 48.980819, lng: 20.373479 },
    { lat: 48.980857, lng: 20.373384 },
    { lat: 48.980876, lng: 20.373289 },
    { lat: 48.980859, lng: 20.373226 },
    { lat: 48.980777, lng: 20.373264 },
    { lat: 48.980751, lng: 20.373214 },
    { lat: 48.980757, lng: 20.373075 },
    { lat: 48.980699, lng: 20.373011 },
    { lat: 48.980761, lng: 20.372808 },
    { lat: 48.980703, lng: 20.372707 },
    { lat: 48.980742, lng: 20.372599 },
    { lat: 48.980726, lng: 20.372555 },
    { lat: 48.980751, lng: 20.372505 },
    { lat: 48.980806, lng: 20.372452 },
    { lat: 48.980783, lng: 20.372432 },
    { lat: 48.980798, lng: 20.372286 },
    { lat: 48.980888, lng: 20.372162 },
    { lat: 48.980828, lng: 20.372084 },
    { lat: 48.980849, lng: 20.371971 },
    { lat: 48.980855, lng: 20.371936 },
    { lat: 48.980816, lng: 20.371877 },
    { lat: 48.980893, lng: 20.371766 },
    { lat: 48.980851, lng: 20.371616 },
    { lat: 48.980808, lng: 20.371616 },
    { lat: 48.980706, lng: 20.371546 },
    { lat: 48.980691, lng: 20.371641 },
    { lat: 48.980581, lng: 20.371628 },
    { lat: 48.980561, lng: 20.371395 },
    { lat: 48.980577, lng: 20.37136 },
    { lat: 48.980634, lng: 20.371369 },
    { lat: 48.980682, lng: 20.371317 },
    { lat: 48.980695, lng: 20.371156 },
    { lat: 48.980585, lng: 20.371097 },
    { lat: 48.980586, lng: 20.370958 },
    { lat: 48.980523, lng: 20.370906 },
    { lat: 48.980465, lng: 20.370681 },
    { lat: 48.980436, lng: 20.370418 },
    { lat: 48.980629, lng: 20.370219 },
    { lat: 48.980634, lng: 20.370126 },
    { lat: 48.980634, lng: 20.37011 },
    { lat: 48.980587, lng: 20.36998 },
    { lat: 48.980483, lng: 20.369843 },
    { lat: 48.980557, lng: 20.369136 },
    { lat: 48.980696, lng: 20.368714 },
    { lat: 48.98088, lng: 20.368211 },
    { lat: 48.980921, lng: 20.368102 },
    { lat: 48.980981, lng: 20.368016 },
    { lat: 48.981019, lng: 20.367912 },
    { lat: 48.981052, lng: 20.367801 },
    { lat: 48.981073, lng: 20.367692 },
    { lat: 48.981156, lng: 20.367566 },
    { lat: 48.981246, lng: 20.367301 },
    { lat: 48.981316, lng: 20.367109 },
    { lat: 48.981294, lng: 20.367111 },
    { lat: 48.980933, lng: 20.366479 },
    { lat: 48.980883, lng: 20.366383 },
    { lat: 48.980672, lng: 20.365956 },
    { lat: 48.980503, lng: 20.365593 },
    { lat: 48.980154, lng: 20.364831 },
    { lat: 48.97987, lng: 20.36431 },
    { lat: 48.979685, lng: 20.364183 },
    { lat: 48.979466, lng: 20.363651 },
    { lat: 48.979311, lng: 20.363375 },
    { lat: 48.979042, lng: 20.362635 },
    { lat: 48.978739, lng: 20.361988 },
    { lat: 48.97858, lng: 20.361539 },
    { lat: 48.978485, lng: 20.361239 },
    { lat: 48.978417, lng: 20.361001 },
    { lat: 48.978406, lng: 20.360963 },
    { lat: 48.978167, lng: 20.360134 },
    { lat: 48.977844, lng: 20.359167 },
    { lat: 48.977437, lng: 20.357635 },
    { lat: 48.97743, lng: 20.35765 },
    { lat: 48.977341, lng: 20.357307 },
    { lat: 48.977189, lng: 20.356999 },
    { lat: 48.976874, lng: 20.356154 },
    { lat: 48.976612, lng: 20.355457 },
    { lat: 48.976303, lng: 20.354821 },
    { lat: 48.9762, lng: 20.35456 },
    { lat: 48.976157, lng: 20.354404 },
    { lat: 48.976174, lng: 20.354323 },
    { lat: 48.975942, lng: 20.353866 },
    { lat: 48.975786, lng: 20.353462 },
    { lat: 48.975664, lng: 20.353173 },
    { lat: 48.975651, lng: 20.35315 },
    { lat: 48.975637, lng: 20.353118 },
    { lat: 48.975632, lng: 20.353109 },
    { lat: 48.975305, lng: 20.35246 },
    { lat: 48.974853, lng: 20.35158 },
    { lat: 48.97462, lng: 20.351188 },
    { lat: 48.974449, lng: 20.351022 },
    { lat: 48.974398, lng: 20.350973 },
    { lat: 48.974279, lng: 20.350611 },
    { lat: 48.973689, lng: 20.349121 },
    { lat: 48.972771, lng: 20.347947 },
    { lat: 48.972713, lng: 20.34796 },
    { lat: 48.972699, lng: 20.347948 },
    { lat: 48.972359, lng: 20.347949 },
    { lat: 48.97165, lng: 20.348043 },
    { lat: 48.97133, lng: 20.348004 },
    { lat: 48.97024, lng: 20.347601 },
    { lat: 48.970135, lng: 20.347402 },
    { lat: 48.970082, lng: 20.34714 },
    { lat: 48.969966, lng: 20.346874 },
    { lat: 48.969919, lng: 20.346707 },
    { lat: 48.969915, lng: 20.34669 },
    { lat: 48.96992, lng: 20.346654 },
    { lat: 48.96993, lng: 20.346636 },
    { lat: 48.969826, lng: 20.346389 },
    { lat: 48.969562, lng: 20.345328 },
    { lat: 48.969395, lng: 20.344893 },
    { lat: 48.969276, lng: 20.344684 },
    { lat: 48.969032, lng: 20.34439 },
    { lat: 48.968692, lng: 20.344058 },
    { lat: 48.968564, lng: 20.343887 },
    { lat: 48.968452, lng: 20.343577 },
    { lat: 48.968367, lng: 20.343427 },
    { lat: 48.968243, lng: 20.343255 },
    { lat: 48.968157, lng: 20.343154 },
    { lat: 48.968029, lng: 20.342757 },
    { lat: 48.967893, lng: 20.342477 },
    { lat: 48.967671, lng: 20.342039 },
    { lat: 48.967594, lng: 20.341955 },
    { lat: 48.967486, lng: 20.341879 },
    { lat: 48.967338, lng: 20.341838 },
    { lat: 48.967307, lng: 20.341827 },
    { lat: 48.966778, lng: 20.341537 },
    { lat: 48.966652, lng: 20.341529 },
    { lat: 48.966416, lng: 20.341506 },
    { lat: 48.966397, lng: 20.341496 },
    { lat: 48.966315, lng: 20.341427 },
    { lat: 48.966191, lng: 20.341274 },
    { lat: 48.966151, lng: 20.341213 },
    { lat: 48.965996, lng: 20.340704 },
    { lat: 48.965897, lng: 20.340393 },
    { lat: 48.965887, lng: 20.34027 },
    { lat: 48.965937, lng: 20.339998 },
    { lat: 48.965961, lng: 20.339852 },
    { lat: 48.966001, lng: 20.339639 },
    { lat: 48.966066, lng: 20.339266 },
    { lat: 48.966083, lng: 20.339105 },
    { lat: 48.966115, lng: 20.338744 },
    { lat: 48.966143, lng: 20.338471 },
    { lat: 48.966165, lng: 20.338308 },
    { lat: 48.966193, lng: 20.338154 },
    { lat: 48.96618, lng: 20.338028 },
    { lat: 48.966171, lng: 20.337747 },
    { lat: 48.966216, lng: 20.337255 },
    { lat: 48.966266, lng: 20.336846 },
    { lat: 48.96628, lng: 20.336691 },
    { lat: 48.966267, lng: 20.336208 },
    { lat: 48.966286, lng: 20.336072 },
    { lat: 48.966332, lng: 20.335675 },
    { lat: 48.966344, lng: 20.335198 },
    { lat: 48.966377, lng: 20.33478 },
    { lat: 48.966301, lng: 20.33437 },
    { lat: 48.966298, lng: 20.334338 },
    { lat: 48.966335, lng: 20.333592 },
    { lat: 48.966384, lng: 20.333006 },
    { lat: 48.966384, lng: 20.332812 },
    { lat: 48.966387, lng: 20.332633 },
    { lat: 48.966467, lng: 20.331745 },
    { lat: 48.966472, lng: 20.331393 },
    { lat: 48.966458, lng: 20.331393 },
    { lat: 48.966433, lng: 20.331414 },
    { lat: 48.965922, lng: 20.330672 },
    { lat: 48.965468, lng: 20.330025 },
    { lat: 48.965443, lng: 20.330041 },
    { lat: 48.965431, lng: 20.329928 },
    { lat: 48.965402, lng: 20.329461 },
    { lat: 48.965258, lng: 20.329204 },
    { lat: 48.96515, lng: 20.329105 },
    { lat: 48.965112, lng: 20.329035 },
    { lat: 48.964985, lng: 20.328659 },
    { lat: 48.964896, lng: 20.328631 },
    { lat: 48.964885, lng: 20.328512 },
    { lat: 48.964733, lng: 20.328254 },
    { lat: 48.964471, lng: 20.327939 },
    { lat: 48.964418, lng: 20.327858 },
    { lat: 48.964354, lng: 20.327823 },
    { lat: 48.964307, lng: 20.327744 },
    { lat: 48.964187, lng: 20.327669 },
    { lat: 48.964005, lng: 20.327581 },
    { lat: 48.963922, lng: 20.327513 },
    { lat: 48.9638019, lng: 20.3272742 },
    { lat: 48.963753, lng: 20.327177 },
    { lat: 48.963658, lng: 20.326976 },
    { lat: 48.963542, lng: 20.326552 },
    { lat: 48.96345, lng: 20.32616 },
    { lat: 48.963227, lng: 20.32612 },
    { lat: 48.963187, lng: 20.326175 },
    { lat: 48.963029, lng: 20.326079 },
    { lat: 48.962878, lng: 20.325873 },
    { lat: 48.962791, lng: 20.325781 },
    { lat: 48.962666, lng: 20.325685 },
    { lat: 48.962555, lng: 20.325513 },
    { lat: 48.962456, lng: 20.325407 },
    { lat: 48.962276, lng: 20.32535 },
    { lat: 48.962205, lng: 20.32511 },
    { lat: 48.96202, lng: 20.32497 },
    { lat: 48.961849, lng: 20.324826 },
    { lat: 48.961634, lng: 20.324446 },
    { lat: 48.961542, lng: 20.324321 },
    { lat: 48.961471, lng: 20.32426 },
    { lat: 48.961417, lng: 20.324192 },
    { lat: 48.961352, lng: 20.324089 },
    { lat: 48.961276, lng: 20.323934 },
    { lat: 48.961027, lng: 20.323697 },
    { lat: 48.960904, lng: 20.323663 },
    { lat: 48.960872, lng: 20.32356 },
    { lat: 48.960772, lng: 20.3235 },
    { lat: 48.960523, lng: 20.323102 },
    { lat: 48.960366, lng: 20.322811 },
    { lat: 48.96033, lng: 20.32266 },
    { lat: 48.96023, lng: 20.322544 },
    { lat: 48.960203, lng: 20.322427 },
    { lat: 48.96016, lng: 20.322358 },
    { lat: 48.960039, lng: 20.321973 },
    { lat: 48.959993, lng: 20.321929 },
    { lat: 48.959903, lng: 20.321628 },
    { lat: 48.959832, lng: 20.321483 },
    { lat: 48.959785, lng: 20.32131 },
    { lat: 48.959749, lng: 20.32123 },
    { lat: 48.959742, lng: 20.321027 },
    { lat: 48.959752, lng: 20.32084 },
    { lat: 48.959715, lng: 20.320628 },
    { lat: 48.95957, lng: 20.319998 },
    { lat: 48.959564, lng: 20.319982 },
    { lat: 48.959464, lng: 20.320103 },
    { lat: 48.959244, lng: 20.320632 },
    { lat: 48.959169, lng: 20.320707 },
    { lat: 48.958934, lng: 20.320859 },
    { lat: 48.958574, lng: 20.321096 },
    { lat: 48.958257, lng: 20.32139 },
    { lat: 48.958012, lng: 20.321577 },
    { lat: 48.95778, lng: 20.320579 },
    { lat: 48.957801, lng: 20.320321 },
    { lat: 48.957753, lng: 20.31975 },
    { lat: 48.957713, lng: 20.319575 },
    { lat: 48.957503, lng: 20.319184 },
    { lat: 48.957294, lng: 20.31897 },
    { lat: 48.957076, lng: 20.318509 },
    { lat: 48.956789, lng: 20.317877 },
    { lat: 48.956743, lng: 20.317465 },
    { lat: 48.956716, lng: 20.317337 },
    { lat: 48.956591, lng: 20.317118 },
    { lat: 48.956512, lng: 20.316711 },
    { lat: 48.956437, lng: 20.316466 },
    { lat: 48.956301, lng: 20.316267 },
    { lat: 48.956224, lng: 20.316177 },
    { lat: 48.956198, lng: 20.316147 },
    { lat: 48.956217, lng: 20.316144 },
    { lat: 48.956035, lng: 20.315961 },
    { lat: 48.955975, lng: 20.315822 },
    { lat: 48.955956, lng: 20.315773 },
    { lat: 48.95588, lng: 20.315441 },
    { lat: 48.955774, lng: 20.315278 },
    { lat: 48.955439, lng: 20.314996 },
    { lat: 48.955373, lng: 20.314864 },
    { lat: 48.955323, lng: 20.314678 },
    { lat: 48.955211, lng: 20.314571 },
    { lat: 48.95519, lng: 20.314564 },
    { lat: 48.955175, lng: 20.314559 },
    { lat: 48.955157, lng: 20.314565 },
    { lat: 48.954875, lng: 20.314658 },
    { lat: 48.954805, lng: 20.31465 },
    { lat: 48.954691, lng: 20.314617 },
    { lat: 48.954486, lng: 20.314524 },
    { lat: 48.954368, lng: 20.314457 },
    { lat: 48.953954, lng: 20.313967 },
    { lat: 48.953575, lng: 20.31363 },
    { lat: 48.953171, lng: 20.313372 },
    { lat: 48.952648, lng: 20.313102 },
    { lat: 48.952274, lng: 20.312791 },
    { lat: 48.952032, lng: 20.312671 },
    { lat: 48.951753, lng: 20.312572 },
    { lat: 48.951642, lng: 20.31245 },
    { lat: 48.95154, lng: 20.312428 },
    { lat: 48.95146, lng: 20.312501 },
    { lat: 48.951403, lng: 20.312613 },
    { lat: 48.951346, lng: 20.312756 },
    { lat: 48.951326, lng: 20.312859 },
    { lat: 48.951313, lng: 20.312866 },
    { lat: 48.951291, lng: 20.312879 },
    { lat: 48.950775, lng: 20.313176 },
    { lat: 48.950627, lng: 20.313196 },
    { lat: 48.950319, lng: 20.312938 },
    { lat: 48.950037, lng: 20.312626 },
    { lat: 48.949731, lng: 20.312405 },
    { lat: 48.94922, lng: 20.311773 },
    { lat: 48.949008, lng: 20.311454 },
    { lat: 48.948747, lng: 20.310566 },
    { lat: 48.94876, lng: 20.310549 },
    { lat: 48.948631, lng: 20.310171 },
    { lat: 48.948445, lng: 20.309648 },
    { lat: 48.948006, lng: 20.308878 },
    { lat: 48.947832, lng: 20.308435 },
    { lat: 48.947787, lng: 20.308225 },
    { lat: 48.947592, lng: 20.308 },
    { lat: 48.947519, lng: 20.30786 },
    { lat: 48.947354, lng: 20.307316 },
    { lat: 48.946985, lng: 20.306467 },
    { lat: 48.946781, lng: 20.30582 },
    { lat: 48.946717, lng: 20.305539 },
    { lat: 48.946544, lng: 20.305164 },
    { lat: 48.946533, lng: 20.30518 },
    { lat: 48.946315, lng: 20.304635 },
    { lat: 48.946203, lng: 20.304514 },
    { lat: 48.946064, lng: 20.3036 },
    { lat: 48.946051, lng: 20.303532 },
    { lat: 48.945769, lng: 20.303092 },
    { lat: 48.945582, lng: 20.302895 },
    { lat: 48.94543, lng: 20.302015 },
    { lat: 48.945204, lng: 20.301321 },
    { lat: 48.945178, lng: 20.300912 },
    { lat: 48.945071, lng: 20.300721 },
    { lat: 48.945014, lng: 20.3007 },
    { lat: 48.944383, lng: 20.300375 },
    { lat: 48.943947, lng: 20.300117 },
    { lat: 48.943732, lng: 20.300037 },
    { lat: 48.943531, lng: 20.299923 },
    { lat: 48.943429, lng: 20.299828 },
    { lat: 48.943223, lng: 20.299727 },
    { lat: 48.943126, lng: 20.299531 },
    { lat: 48.942833, lng: 20.299437 },
    { lat: 48.942239, lng: 20.299076 },
    { lat: 48.941732, lng: 20.298396 },
    { lat: 48.941441, lng: 20.297812 },
    { lat: 48.941321, lng: 20.297646 },
    { lat: 48.941048, lng: 20.297209 },
    { lat: 48.941003, lng: 20.297133 },
    { lat: 48.940935, lng: 20.297083 },
    { lat: 48.940903, lng: 20.297016 },
    { lat: 48.940841, lng: 20.296992 },
    { lat: 48.939961, lng: 20.297834 },
    { lat: 48.939765, lng: 20.297622 },
    { lat: 48.939505, lng: 20.297703 },
    { lat: 48.9389, lng: 20.297623 },
    { lat: 48.938894, lng: 20.297639 },
    { lat: 48.938623, lng: 20.297348 },
    { lat: 48.938047, lng: 20.296808 },
    { lat: 48.937544, lng: 20.296506 },
    { lat: 48.937307, lng: 20.296317 },
    { lat: 48.93714, lng: 20.296243 },
    { lat: 48.937065, lng: 20.296261 },
    { lat: 48.936953, lng: 20.296336 },
    { lat: 48.936689, lng: 20.296701 },
    { lat: 48.936628, lng: 20.296762 },
    { lat: 48.936073, lng: 20.29687 },
    { lat: 48.935753, lng: 20.297159 },
    { lat: 48.93547, lng: 20.297691 },
    { lat: 48.935413, lng: 20.2979 },
    { lat: 48.935074, lng: 20.298248 },
    { lat: 48.934695, lng: 20.298537 },
    { lat: 48.934642, lng: 20.298623 },
    { lat: 48.934605, lng: 20.298783 },
    { lat: 48.93455, lng: 20.298944 },
    { lat: 48.934465, lng: 20.299087 },
    { lat: 48.934443, lng: 20.299182 },
    { lat: 48.934466, lng: 20.299557 },
    { lat: 48.934498, lng: 20.299757 },
    { lat: 48.934455, lng: 20.29995 },
    { lat: 48.934414, lng: 20.300197 },
    { lat: 48.934443, lng: 20.300368 },
    { lat: 48.934506, lng: 20.300602 },
    { lat: 48.934624, lng: 20.301238 },
    { lat: 48.934702, lng: 20.301552 },
    { lat: 48.934744, lng: 20.301676 },
    { lat: 48.934807, lng: 20.301768 },
    { lat: 48.934959, lng: 20.301803 },
    { lat: 48.935065, lng: 20.301939 },
    { lat: 48.935215, lng: 20.30205 },
    { lat: 48.935298, lng: 20.302093 },
    { lat: 48.935346, lng: 20.302133 },
    { lat: 48.935453, lng: 20.30228 },
    { lat: 48.935724, lng: 20.302507 },
    { lat: 48.935783, lng: 20.302542 },
    { lat: 48.93588, lng: 20.302914 },
    { lat: 48.935897, lng: 20.302911 },
    { lat: 48.93596, lng: 20.303859 },
    { lat: 48.935945, lng: 20.304087 },
    { lat: 48.936093, lng: 20.304179 },
    { lat: 48.936109, lng: 20.304218 },
  ],
  SpišskéVlachy: [
    { lat: 48.9611884, lng: 20.8336457 },
    { lat: 48.9611337, lng: 20.8325425 },
    { lat: 48.9611476, lng: 20.8319997 },
    { lat: 48.9610234, lng: 20.8314626 },
    { lat: 48.9606692, lng: 20.8307582 },
    { lat: 48.9604555, lng: 20.8297446 },
    { lat: 48.9605366, lng: 20.829716 },
    { lat: 48.9604772, lng: 20.8292984 },
    { lat: 48.9601243, lng: 20.8284345 },
    { lat: 48.9591608, lng: 20.8264721 },
    { lat: 48.9589, lng: 20.8257962 },
    { lat: 48.9581964, lng: 20.8243262 },
    { lat: 48.9580945, lng: 20.8240184 },
    { lat: 48.9578332, lng: 20.8236498 },
    { lat: 48.9577613, lng: 20.8230351 },
    { lat: 48.9575938, lng: 20.8227469 },
    { lat: 48.9572777, lng: 20.8216742 },
    { lat: 48.957384, lng: 20.8215611 },
    { lat: 48.9575791, lng: 20.8215689 },
    { lat: 48.9579933, lng: 20.8214034 },
    { lat: 48.958092, lng: 20.8211337 },
    { lat: 48.9582615, lng: 20.820925 },
    { lat: 48.958624, lng: 20.8206988 },
    { lat: 48.9598763, lng: 20.8202914 },
    { lat: 48.9600373, lng: 20.8200945 },
    { lat: 48.9606118, lng: 20.8198141 },
    { lat: 48.9610654, lng: 20.8198124 },
    { lat: 48.9612637, lng: 20.8187782 },
    { lat: 48.9615036, lng: 20.8178718 },
    { lat: 48.9621078, lng: 20.8162973 },
    { lat: 48.9626595, lng: 20.8152568 },
    { lat: 48.963091, lng: 20.8141277 },
    { lat: 48.9635211, lng: 20.8134812 },
    { lat: 48.9636645, lng: 20.8132007 },
    { lat: 48.9641346, lng: 20.8119191 },
    { lat: 48.9642227, lng: 20.8113084 },
    { lat: 48.9642287, lng: 20.8108952 },
    { lat: 48.9644684, lng: 20.8097491 },
    { lat: 48.964656, lng: 20.8091874 },
    { lat: 48.9658986, lng: 20.8068431 },
    { lat: 48.9662118, lng: 20.8063638 },
    { lat: 48.9659425, lng: 20.8059031 },
    { lat: 48.9668057, lng: 20.8044338 },
    { lat: 48.9677787, lng: 20.8021738 },
    { lat: 48.9682361, lng: 20.8015647 },
    { lat: 48.9684183, lng: 20.8012044 },
    { lat: 48.9684811, lng: 20.8007455 },
    { lat: 48.9687197, lng: 20.800027 },
    { lat: 48.9689445, lng: 20.7995637 },
    { lat: 48.9691005, lng: 20.799423 },
    { lat: 48.9693126, lng: 20.7988822 },
    { lat: 48.9695405, lng: 20.7985426 },
    { lat: 48.9695915, lng: 20.7980624 },
    { lat: 48.9697303, lng: 20.7975557 },
    { lat: 48.9698385, lng: 20.7968796 },
    { lat: 48.9703644, lng: 20.7955509 },
    { lat: 48.9704134, lng: 20.7948906 },
    { lat: 48.9701088, lng: 20.7938681 },
    { lat: 48.9698796, lng: 20.7917049 },
    { lat: 48.9696406, lng: 20.7905288 },
    { lat: 48.9695592, lng: 20.7899055 },
    { lat: 48.9695613, lng: 20.7892851 },
    { lat: 48.9694705, lng: 20.7889399 },
    { lat: 48.9694005, lng: 20.7888688 },
    { lat: 48.9693354, lng: 20.7875796 },
    { lat: 48.9695152, lng: 20.7866996 },
    { lat: 48.9696107, lng: 20.7864669 },
    { lat: 48.9702026, lng: 20.7855541 },
    { lat: 48.9701807, lng: 20.7854753 },
    { lat: 48.9702707, lng: 20.7852129 },
    { lat: 48.9702202, lng: 20.7849615 },
    { lat: 48.9702906, lng: 20.7844915 },
    { lat: 48.9702211, lng: 20.7842778 },
    { lat: 48.9702319, lng: 20.7841007 },
    { lat: 48.9703291, lng: 20.7838822 },
    { lat: 48.9701383, lng: 20.7831598 },
    { lat: 48.9706242, lng: 20.7828872 },
    { lat: 48.9707045, lng: 20.7828986 },
    { lat: 48.9709087, lng: 20.7820754 },
    { lat: 48.9709247, lng: 20.7817559 },
    { lat: 48.9710409, lng: 20.7813141 },
    { lat: 48.9710517, lng: 20.7809138 },
    { lat: 48.971107, lng: 20.780812 },
    { lat: 48.971185, lng: 20.780503 },
    { lat: 48.971177, lng: 20.780414 },
    { lat: 48.971171, lng: 20.780336 },
    { lat: 48.971192, lng: 20.780193 },
    { lat: 48.971205, lng: 20.780019 },
    { lat: 48.971298, lng: 20.779609 },
    { lat: 48.971293, lng: 20.779204 },
    { lat: 48.971226, lng: 20.778972 },
    { lat: 48.971037, lng: 20.77868 },
    { lat: 48.970769, lng: 20.778305 },
    { lat: 48.970772, lng: 20.777877 },
    { lat: 48.97078, lng: 20.777687 },
    { lat: 48.970739, lng: 20.777582 },
    { lat: 48.970714, lng: 20.777319 },
    { lat: 48.970621, lng: 20.777213 },
    { lat: 48.970429, lng: 20.776801 },
    { lat: 48.970272, lng: 20.776582 },
    { lat: 48.970204, lng: 20.776309 },
    { lat: 48.969835, lng: 20.775472 },
    { lat: 48.969659, lng: 20.775061 },
    { lat: 48.9693156, lng: 20.774595 },
    { lat: 48.969379, lng: 20.7744 },
    { lat: 48.968996, lng: 20.773704 },
    { lat: 48.968909, lng: 20.773432 },
    { lat: 48.968848, lng: 20.77312 },
    { lat: 48.968917, lng: 20.773051 },
    { lat: 48.968943, lng: 20.772845 },
    { lat: 48.96895, lng: 20.772615 },
    { lat: 48.968817, lng: 20.772246 },
    { lat: 48.968674, lng: 20.77205 },
    { lat: 48.968587, lng: 20.771904 },
    { lat: 48.968531, lng: 20.771815 },
    { lat: 48.968501, lng: 20.771727 },
    { lat: 48.968479, lng: 20.771615 },
    { lat: 48.968458, lng: 20.771344 },
    { lat: 48.968447, lng: 20.771225 },
    { lat: 48.968425, lng: 20.771049 },
    { lat: 48.968383, lng: 20.770921 },
    { lat: 48.968155, lng: 20.770778 },
    { lat: 48.967791, lng: 20.770726 },
    { lat: 48.967573, lng: 20.770116 },
    { lat: 48.967419, lng: 20.769611 },
    { lat: 48.967369, lng: 20.769291 },
    { lat: 48.967434, lng: 20.768762 },
    { lat: 48.967519, lng: 20.768288 },
    { lat: 48.96752, lng: 20.768082 },
    { lat: 48.967489, lng: 20.767882 },
    { lat: 48.967403, lng: 20.767491 },
    { lat: 48.967339, lng: 20.767282 },
    { lat: 48.967223, lng: 20.766992 },
    { lat: 48.967047, lng: 20.766724 },
    { lat: 48.96693, lng: 20.766371 },
    { lat: 48.966052, lng: 20.766127 },
    { lat: 48.966045, lng: 20.766103 },
    { lat: 48.9655757, lng: 20.7660876 },
    { lat: 48.964919, lng: 20.7661681 },
    { lat: 48.964062, lng: 20.7666751 },
    { lat: 48.9625173, lng: 20.7679603 },
    { lat: 48.961777, lng: 20.7683876 },
    { lat: 48.960716, lng: 20.7688224 },
    { lat: 48.9574819, lng: 20.7721736 },
    { lat: 48.9572782, lng: 20.7731418 },
    { lat: 48.9570633, lng: 20.7737215 },
    { lat: 48.9569117, lng: 20.7739618 },
    { lat: 48.9553809, lng: 20.7718009 },
    { lat: 48.9551533, lng: 20.7716348 },
    { lat: 48.9548787, lng: 20.771538 },
    { lat: 48.9531899, lng: 20.7714015 },
    { lat: 48.9521759, lng: 20.7710258 },
    { lat: 48.9518662, lng: 20.7709838 },
    { lat: 48.9516536, lng: 20.771056 },
    { lat: 48.9510767, lng: 20.7714252 },
    { lat: 48.9509622, lng: 20.7715637 },
    { lat: 48.9507464, lng: 20.7726029 },
    { lat: 48.9503837, lng: 20.7728918 },
    { lat: 48.9501755, lng: 20.7728924 },
    { lat: 48.9494785, lng: 20.7724013 },
    { lat: 48.9484721, lng: 20.7713367 },
    { lat: 48.9471585, lng: 20.7715019 },
    { lat: 48.9467382, lng: 20.7710022 },
    { lat: 48.9456043, lng: 20.7699633 },
    { lat: 48.9453592, lng: 20.7696203 },
    { lat: 48.945296, lng: 20.7690674 },
    { lat: 48.9452274, lng: 20.7691958 },
    { lat: 48.9452604, lng: 20.7689793 },
    { lat: 48.9452527, lng: 20.7682455 },
    { lat: 48.9450565, lng: 20.7677216 },
    { lat: 48.9446533, lng: 20.767006 },
    { lat: 48.9443354, lng: 20.7666102 },
    { lat: 48.944142, lng: 20.7662322 },
    { lat: 48.9440154, lng: 20.765957 },
    { lat: 48.943812, lng: 20.7651935 },
    { lat: 48.94363, lng: 20.7636619 },
    { lat: 48.9435945, lng: 20.7629363 },
    { lat: 48.9436272, lng: 20.7622605 },
    { lat: 48.9439321, lng: 20.7599472 },
    { lat: 48.9444511, lng: 20.7576692 },
    { lat: 48.9445479, lng: 20.7569563 },
    { lat: 48.9445682, lng: 20.7564146 },
    { lat: 48.9445477, lng: 20.7558279 },
    { lat: 48.9443783, lng: 20.7552085 },
    { lat: 48.9442706, lng: 20.7551531 },
    { lat: 48.9439909, lng: 20.7548179 },
    { lat: 48.9436561, lng: 20.7543251 },
    { lat: 48.9431951, lng: 20.7544984 },
    { lat: 48.9412729, lng: 20.7551846 },
    { lat: 48.9402942, lng: 20.7551699 },
    { lat: 48.9398753, lng: 20.7551046 },
    { lat: 48.9394674, lng: 20.7549295 },
    { lat: 48.9391446, lng: 20.7551955 },
    { lat: 48.9383586, lng: 20.7560561 },
    { lat: 48.9376438, lng: 20.7566309 },
    { lat: 48.9375828, lng: 20.7568231 },
    { lat: 48.9361789, lng: 20.7579349 },
    { lat: 48.9358161, lng: 20.7582857 },
    { lat: 48.9328388, lng: 20.759794 },
    { lat: 48.9330649, lng: 20.7606586 },
    { lat: 48.9332147, lng: 20.7606261 },
    { lat: 48.9336036, lng: 20.7603556 },
    { lat: 48.9339318, lng: 20.7603699 },
    { lat: 48.9341668, lng: 20.7605614 },
    { lat: 48.9343727, lng: 20.760944 },
    { lat: 48.9343945, lng: 20.7611753 },
    { lat: 48.9344638, lng: 20.762966 },
    { lat: 48.9343999, lng: 20.7631334 },
    { lat: 48.9330249, lng: 20.7649811 },
    { lat: 48.9333447, lng: 20.7658529 },
    { lat: 48.9312732, lng: 20.76723 },
    { lat: 48.929145, lng: 20.7680474 },
    { lat: 48.927075, lng: 20.7689538 },
    { lat: 48.9256492, lng: 20.7694176 },
    { lat: 48.9216944, lng: 20.7710788 },
    { lat: 48.9211776, lng: 20.7712754 },
    { lat: 48.9208908, lng: 20.7713103 },
    { lat: 48.920382, lng: 20.7715671 },
    { lat: 48.9202136, lng: 20.771614 },
    { lat: 48.9200507, lng: 20.7714632 },
    { lat: 48.9192071, lng: 20.7700643 },
    { lat: 48.9192924, lng: 20.7694135 },
    { lat: 48.9194232, lng: 20.7690658 },
    { lat: 48.9194641, lng: 20.7681115 },
    { lat: 48.9195664, lng: 20.76759 },
    { lat: 48.9198227, lng: 20.7653637 },
    { lat: 48.9196329, lng: 20.7626792 },
    { lat: 48.9196483, lng: 20.7621557 },
    { lat: 48.9194962, lng: 20.7610859 },
    { lat: 48.9192841, lng: 20.7564517 },
    { lat: 48.9191828, lng: 20.7564546 },
    { lat: 48.9181811, lng: 20.7569088 },
    { lat: 48.9179492, lng: 20.7568749 },
    { lat: 48.9177557, lng: 20.757008 },
    { lat: 48.9172438, lng: 20.7570801 },
    { lat: 48.9170725, lng: 20.7572668 },
    { lat: 48.9166082, lng: 20.7572521 },
    { lat: 48.9164799, lng: 20.7575076 },
    { lat: 48.9161212, lng: 20.7574557 },
    { lat: 48.9160223, lng: 20.7573663 },
    { lat: 48.9158845, lng: 20.7573878 },
    { lat: 48.9158421, lng: 20.75765 },
    { lat: 48.9157071, lng: 20.7575263 },
    { lat: 48.9156591, lng: 20.7577263 },
    { lat: 48.9155063, lng: 20.7575798 },
    { lat: 48.9150824, lng: 20.7577804 },
    { lat: 48.9149798, lng: 20.7576817 },
    { lat: 48.9146997, lng: 20.7576249 },
    { lat: 48.9145135, lng: 20.7577416 },
    { lat: 48.914356, lng: 20.7579336 },
    { lat: 48.9143197, lng: 20.7582758 },
    { lat: 48.9141578, lng: 20.7583494 },
    { lat: 48.9140676, lng: 20.7585059 },
    { lat: 48.9139644, lng: 20.7584487 },
    { lat: 48.913533, lng: 20.7587323 },
    { lat: 48.9129909, lng: 20.7586193 },
    { lat: 48.9128566, lng: 20.7588642 },
    { lat: 48.912645, lng: 20.758994 },
    { lat: 48.9124805, lng: 20.7588617 },
    { lat: 48.9123868, lng: 20.7588556 },
    { lat: 48.9122711, lng: 20.7587018 },
    { lat: 48.9119357, lng: 20.7587986 },
    { lat: 48.9106152, lng: 20.7608517 },
    { lat: 48.9100522, lng: 20.7621647 },
    { lat: 48.9091456, lng: 20.7633747 },
    { lat: 48.9078691, lng: 20.7654666 },
    { lat: 48.9073631, lng: 20.7664138 },
    { lat: 48.9068494, lng: 20.766881 },
    { lat: 48.9061344, lng: 20.7673924 },
    { lat: 48.9059175, lng: 20.7676933 },
    { lat: 48.9056549, lng: 20.7679402 },
    { lat: 48.9044262, lng: 20.7680238 },
    { lat: 48.9040227, lng: 20.7684578 },
    { lat: 48.9035472, lng: 20.7687 },
    { lat: 48.9017213, lng: 20.7705659 },
    { lat: 48.9015506, lng: 20.7709307 },
    { lat: 48.9012953, lng: 20.7711666 },
    { lat: 48.900968, lng: 20.7710141 },
    { lat: 48.9001434, lng: 20.7709287 },
    { lat: 48.8992283, lng: 20.7706417 },
    { lat: 48.8991041, lng: 20.7704714 },
    { lat: 48.8990178, lng: 20.7699667 },
    { lat: 48.8987738, lng: 20.7695932 },
    { lat: 48.8984769, lng: 20.7693061 },
    { lat: 48.8979244, lng: 20.7690887 },
    { lat: 48.8972044, lng: 20.7691324 },
    { lat: 48.8971037, lng: 20.769377 },
    { lat: 48.8960495, lng: 20.7708378 },
    { lat: 48.894278, lng: 20.7723624 },
    { lat: 48.8937472, lng: 20.7726043 },
    { lat: 48.8933727, lng: 20.7725797 },
    { lat: 48.8923972, lng: 20.7728549 },
    { lat: 48.8924451, lng: 20.7760264 },
    { lat: 48.8925784, lng: 20.7768116 },
    { lat: 48.8929784, lng: 20.7784358 },
    { lat: 48.89261, lng: 20.7814257 },
    { lat: 48.8914668, lng: 20.7856356 },
    { lat: 48.8914994, lng: 20.7872477 },
    { lat: 48.8917738, lng: 20.7876643 },
    { lat: 48.8920116, lng: 20.7882888 },
    { lat: 48.892238, lng: 20.7886466 },
    { lat: 48.8925964, lng: 20.7890476 },
    { lat: 48.8927903, lng: 20.7914793 },
    { lat: 48.8920495, lng: 20.7928315 },
    { lat: 48.8909094, lng: 20.7978227 },
    { lat: 48.8917014, lng: 20.7991446 },
    { lat: 48.8917334, lng: 20.8006441 },
    { lat: 48.8919505, lng: 20.8012238 },
    { lat: 48.8923792, lng: 20.8017075 },
    { lat: 48.892606, lng: 20.8023366 },
    { lat: 48.892593, lng: 20.8037166 },
    { lat: 48.8926858, lng: 20.8046011 },
    { lat: 48.8927089, lng: 20.8067302 },
    { lat: 48.8923057, lng: 20.8079586 },
    { lat: 48.8917262, lng: 20.8084506 },
    { lat: 48.8917157, lng: 20.8091565 },
    { lat: 48.8902737, lng: 20.8106804 },
    { lat: 48.8901052, lng: 20.8110848 },
    { lat: 48.889829, lng: 20.8121764 },
    { lat: 48.8896597, lng: 20.8132687 },
    { lat: 48.8896734, lng: 20.8144144 },
    { lat: 48.8901519, lng: 20.8155564 },
    { lat: 48.8912719, lng: 20.8185447 },
    { lat: 48.8914179, lng: 20.8187009 },
    { lat: 48.8915245, lng: 20.8193077 },
    { lat: 48.8917272, lng: 20.8199298 },
    { lat: 48.8920538, lng: 20.8221195 },
    { lat: 48.8920075, lng: 20.8229571 },
    { lat: 48.8920848, lng: 20.8241596 },
    { lat: 48.8920601, lng: 20.8246166 },
    { lat: 48.8916059, lng: 20.8253799 },
    { lat: 48.8915001, lng: 20.8257725 },
    { lat: 48.8918505, lng: 20.8272532 },
    { lat: 48.8922873, lng: 20.828462 },
    { lat: 48.8925404, lng: 20.8287481 },
    { lat: 48.8930567, lng: 20.8288989 },
    { lat: 48.8937791, lng: 20.8286978 },
    { lat: 48.8943402, lng: 20.8283177 },
    { lat: 48.8951741, lng: 20.8303394 },
    { lat: 48.8952743, lng: 20.8308312 },
    { lat: 48.8953498, lng: 20.8312209 },
    { lat: 48.8962597, lng: 20.8307112 },
    { lat: 48.8976164, lng: 20.8310183 },
    { lat: 48.8985061, lng: 20.831713 },
    { lat: 48.8996252, lng: 20.8320114 },
    { lat: 48.9000729, lng: 20.8322042 },
    { lat: 48.901215, lng: 20.8316614 },
    { lat: 48.901768, lng: 20.8316709 },
    { lat: 48.9030365, lng: 20.8325868 },
    { lat: 48.9035545, lng: 20.8326917 },
    { lat: 48.9041208, lng: 20.832464 },
    { lat: 48.9049731, lng: 20.8322748 },
    { lat: 48.9057921, lng: 20.8322256 },
    { lat: 48.9060881, lng: 20.8320962 },
    { lat: 48.9069646, lng: 20.8320185 },
    { lat: 48.9074163, lng: 20.8318002 },
    { lat: 48.9081489, lng: 20.8316956 },
    { lat: 48.9086275, lng: 20.8312798 },
    { lat: 48.9106019, lng: 20.8300364 },
    { lat: 48.9112811, lng: 20.8300173 },
    { lat: 48.9118244, lng: 20.8304315 },
    { lat: 48.9119335, lng: 20.8306551 },
    { lat: 48.9120753, lng: 20.8306897 },
    { lat: 48.9130451, lng: 20.8311657 },
    { lat: 48.9140413, lng: 20.8318793 },
    { lat: 48.9150687, lng: 20.8324589 },
    { lat: 48.9156825, lng: 20.8324406 },
    { lat: 48.916187, lng: 20.8322471 },
    { lat: 48.9166058, lng: 20.8322497 },
    { lat: 48.9171294, lng: 20.8321259 },
    { lat: 48.9193976, lng: 20.8319625 },
    { lat: 48.9203838, lng: 20.8314412 },
    { lat: 48.9208632, lng: 20.8313065 },
    { lat: 48.9212305, lng: 20.831287 },
    { lat: 48.9217894, lng: 20.8310723 },
    { lat: 48.9225036, lng: 20.8309885 },
    { lat: 48.9232799, lng: 20.8311116 },
    { lat: 48.9241611, lng: 20.8312679 },
    { lat: 48.9254047, lng: 20.8315927 },
    { lat: 48.9256798, lng: 20.8317664 },
    { lat: 48.9263197, lng: 20.8323395 },
    { lat: 48.9267788, lng: 20.832597 },
    { lat: 48.9267874, lng: 20.8328115 },
    { lat: 48.9276246, lng: 20.8334567 },
    { lat: 48.9278568, lng: 20.8334326 },
    { lat: 48.9282792, lng: 20.8332112 },
    { lat: 48.928293, lng: 20.8334499 },
    { lat: 48.928677, lng: 20.8336829 },
    { lat: 48.9303139, lng: 20.8340139 },
    { lat: 48.9304834, lng: 20.8339378 },
    { lat: 48.9330944, lng: 20.8366429 },
    { lat: 48.9334846, lng: 20.8369582 },
    { lat: 48.933919, lng: 20.8375463 },
    { lat: 48.9335883, lng: 20.8378478 },
    { lat: 48.9330494, lng: 20.8381451 },
    { lat: 48.9321291, lng: 20.8383894 },
    { lat: 48.932564, lng: 20.8389285 },
    { lat: 48.9328812, lng: 20.8394433 },
    { lat: 48.9333529, lng: 20.8396751 },
    { lat: 48.9338211, lng: 20.8395446 },
    { lat: 48.9343437, lng: 20.8397818 },
    { lat: 48.9348664, lng: 20.8401854 },
    { lat: 48.9357382, lng: 20.8405633 },
    { lat: 48.9361826, lng: 20.8409151 },
    { lat: 48.9368028, lng: 20.8416025 },
    { lat: 48.9376924, lng: 20.8417799 },
    { lat: 48.9379435, lng: 20.8421055 },
    { lat: 48.9379446, lng: 20.8423704 },
    { lat: 48.9385542, lng: 20.8431088 },
    { lat: 48.9391021, lng: 20.8435262 },
    { lat: 48.9394027, lng: 20.843564 },
    { lat: 48.9398859, lng: 20.8437686 },
    { lat: 48.9398979, lng: 20.8443155 },
    { lat: 48.9401267, lng: 20.8443575 },
    { lat: 48.9403345, lng: 20.8446393 },
    { lat: 48.9407446, lng: 20.8444175 },
    { lat: 48.9408489, lng: 20.8445084 },
    { lat: 48.9409833, lng: 20.8448494 },
    { lat: 48.9411948, lng: 20.8448127 },
    { lat: 48.9413828, lng: 20.8448762 },
    { lat: 48.9415636, lng: 20.8452916 },
    { lat: 48.9416718, lng: 20.845428 },
    { lat: 48.9420819, lng: 20.845637 },
    { lat: 48.9425871, lng: 20.8460557 },
    { lat: 48.9427926, lng: 20.8461054 },
    { lat: 48.9429491, lng: 20.8465023 },
    { lat: 48.9432103, lng: 20.8465991 },
    { lat: 48.9434104, lng: 20.8469256 },
    { lat: 48.9435305, lng: 20.8469121 },
    { lat: 48.943648, lng: 20.8469892 },
    { lat: 48.9439437, lng: 20.847798 },
    { lat: 48.9441791, lng: 20.8478753 },
    { lat: 48.9443623, lng: 20.8476653 },
    { lat: 48.9444657, lng: 20.8476955 },
    { lat: 48.9445046, lng: 20.848039 },
    { lat: 48.9446923, lng: 20.8482666 },
    { lat: 48.944778, lng: 20.8482058 },
    { lat: 48.9448524, lng: 20.8480101 },
    { lat: 48.945533, lng: 20.8482242 },
    { lat: 48.9457832, lng: 20.8484311 },
    { lat: 48.9459092, lng: 20.8483628 },
    { lat: 48.9462288, lng: 20.8484317 },
    { lat: 48.9462743, lng: 20.848542 },
    { lat: 48.946561, lng: 20.8484678 },
    { lat: 48.9466425, lng: 20.8486783 },
    { lat: 48.9469651, lng: 20.8489941 },
    { lat: 48.9475712, lng: 20.8492044 },
    { lat: 48.947726, lng: 20.8494544 },
    { lat: 48.9479423, lng: 20.849487 },
    { lat: 48.9481507, lng: 20.8496554 },
    { lat: 48.9481852, lng: 20.8498775 },
    { lat: 48.9484006, lng: 20.8498867 },
    { lat: 48.9485684, lng: 20.8500901 },
    { lat: 48.9485991, lng: 20.8501328 },
    { lat: 48.9486502, lng: 20.8505026 },
    { lat: 48.9487365, lng: 20.8506244 },
    { lat: 48.9489177, lng: 20.8507221 },
    { lat: 48.9490344, lng: 20.8509056 },
    { lat: 48.9490526, lng: 20.85108 },
    { lat: 48.9492891, lng: 20.8515108 },
    { lat: 48.9497993, lng: 20.8522419 },
    { lat: 48.949806, lng: 20.8523416 },
    { lat: 48.9499717, lng: 20.8524485 },
    { lat: 48.9500623, lng: 20.852629 },
    { lat: 48.9501982, lng: 20.8526144 },
    { lat: 48.9503763, lng: 20.8524907 },
    { lat: 48.9505258, lng: 20.8522895 },
    { lat: 48.9507986, lng: 20.8522335 },
    { lat: 48.9509567, lng: 20.8520542 },
    { lat: 48.9516125, lng: 20.8517757 },
    { lat: 48.9516565, lng: 20.8516139 },
    { lat: 48.9520344, lng: 20.8514082 },
    { lat: 48.952671, lng: 20.8513941 },
    { lat: 48.953168, lng: 20.8513796 },
    { lat: 48.9528584, lng: 20.8504263 },
    { lat: 48.9527839, lng: 20.8499577 },
    { lat: 48.9528276, lng: 20.8494926 },
    { lat: 48.9530357, lng: 20.8486692 },
    { lat: 48.9531846, lng: 20.8474591 },
    { lat: 48.9532558, lng: 20.8472092 },
    { lat: 48.9534644, lng: 20.8467912 },
    { lat: 48.9538395, lng: 20.8466702 },
    { lat: 48.9538789, lng: 20.8458792 },
    { lat: 48.9538634, lng: 20.8450018 },
    { lat: 48.9538041, lng: 20.8443869 },
    { lat: 48.9538276, lng: 20.8415936 },
    { lat: 48.9535578, lng: 20.8413743 },
    { lat: 48.9533719, lng: 20.8413055 },
    { lat: 48.9532012, lng: 20.8413466 },
    { lat: 48.9531026, lng: 20.8414658 },
    { lat: 48.9530373, lng: 20.8413621 },
    { lat: 48.9530009, lng: 20.8411533 },
    { lat: 48.9526243, lng: 20.8411699 },
    { lat: 48.9524135, lng: 20.8412897 },
    { lat: 48.9519067, lng: 20.8412847 },
    { lat: 48.9515456, lng: 20.8409974 },
    { lat: 48.9513203, lng: 20.8405991 },
    { lat: 48.9513267, lng: 20.8396367 },
    { lat: 48.9513713, lng: 20.8396234 },
    { lat: 48.9510839, lng: 20.8390245 },
    { lat: 48.9511327, lng: 20.8387423 },
    { lat: 48.9511157, lng: 20.8385424 },
    { lat: 48.9510045, lng: 20.8384509 },
    { lat: 48.9508854, lng: 20.838031 },
    { lat: 48.9507481, lng: 20.8378993 },
    { lat: 48.9506434, lng: 20.8376333 },
    { lat: 48.9512515, lng: 20.8370851 },
    { lat: 48.9515759, lng: 20.8367199 },
    { lat: 48.9517715, lng: 20.8364996 },
    { lat: 48.9523486, lng: 20.8360559 },
    { lat: 48.9524779, lng: 20.8357642 },
    { lat: 48.9528108, lng: 20.8354233 },
    { lat: 48.9530303, lng: 20.8350965 },
    { lat: 48.9534412, lng: 20.8347408 },
    { lat: 48.9545536, lng: 20.8346165 },
    { lat: 48.9572163, lng: 20.8347312 },
    { lat: 48.9581553, lng: 20.8349645 },
    { lat: 48.9590201, lng: 20.8347476 },
    { lat: 48.9602677, lng: 20.8340426 },
    { lat: 48.9611884, lng: 20.8336457 },
  ],
  Krompachy: [
    { lat: 48.9119335, lng: 20.8306551 },
    { lat: 48.9118244, lng: 20.8304315 },
    { lat: 48.9112811, lng: 20.8300173 },
    { lat: 48.9106019, lng: 20.8300364 },
    { lat: 48.9086275, lng: 20.8312798 },
    { lat: 48.9081489, lng: 20.8316956 },
    { lat: 48.9074163, lng: 20.8318002 },
    { lat: 48.9069646, lng: 20.8320185 },
    { lat: 48.9060881, lng: 20.8320962 },
    { lat: 48.9057921, lng: 20.8322256 },
    { lat: 48.9049731, lng: 20.8322748 },
    { lat: 48.9041208, lng: 20.832464 },
    { lat: 48.9035545, lng: 20.8326917 },
    { lat: 48.9030365, lng: 20.8325868 },
    { lat: 48.901768, lng: 20.8316709 },
    { lat: 48.901215, lng: 20.8316614 },
    { lat: 48.9000729, lng: 20.8322042 },
    { lat: 48.8996252, lng: 20.8320114 },
    { lat: 48.8985061, lng: 20.831713 },
    { lat: 48.8976164, lng: 20.8310183 },
    { lat: 48.8962597, lng: 20.8307112 },
    { lat: 48.8953498, lng: 20.8312209 },
    { lat: 48.8967193, lng: 20.8345768 },
    { lat: 48.8964117, lng: 20.8364464 },
    { lat: 48.896415, lng: 20.8364952 },
    { lat: 48.8955039, lng: 20.8387721 },
    { lat: 48.8951593, lng: 20.839913 },
    { lat: 48.8950129, lng: 20.8401396 },
    { lat: 48.8948634, lng: 20.8405746 },
    { lat: 48.8948079, lng: 20.8409571 },
    { lat: 48.8944204, lng: 20.841534 },
    { lat: 48.8943105, lng: 20.8415842 },
    { lat: 48.8937525, lng: 20.8423466 },
    { lat: 48.8934107, lng: 20.8426824 },
    { lat: 48.8930525, lng: 20.8428355 },
    { lat: 48.892711, lng: 20.8433533 },
    { lat: 48.8927246, lng: 20.8435154 },
    { lat: 48.8930951, lng: 20.8442062 },
    { lat: 48.8935468, lng: 20.8446803 },
    { lat: 48.8935421, lng: 20.8460822 },
    { lat: 48.8936983, lng: 20.8468608 },
    { lat: 48.893917, lng: 20.847232 },
    { lat: 48.8939896, lng: 20.8476098 },
    { lat: 48.8937498, lng: 20.8487266 },
    { lat: 48.8936952, lng: 20.8493605 },
    { lat: 48.8934994, lng: 20.8498546 },
    { lat: 48.893381, lng: 20.8504066 },
    { lat: 48.8928121, lng: 20.8515411 },
    { lat: 48.8924066, lng: 20.8519944 },
    { lat: 48.8917367, lng: 20.852484 },
    { lat: 48.8912884, lng: 20.8526725 },
    { lat: 48.8909816, lng: 20.8527847 },
    { lat: 48.8902004, lng: 20.8526538 },
    { lat: 48.8898254, lng: 20.8528465 },
    { lat: 48.8896827, lng: 20.8530209 },
    { lat: 48.889343, lng: 20.8531567 },
    { lat: 48.8890689, lng: 20.8534992 },
    { lat: 48.8887907, lng: 20.8536617 },
    { lat: 48.8886961, lng: 20.8538548 },
    { lat: 48.8883096, lng: 20.8540709 },
    { lat: 48.8878352, lng: 20.8546477 },
    { lat: 48.8871691, lng: 20.8556115 },
    { lat: 48.8873808, lng: 20.8563392 },
    { lat: 48.8877996, lng: 20.8567615 },
    { lat: 48.888034, lng: 20.8569033 },
    { lat: 48.8883152, lng: 20.8572295 },
    { lat: 48.8885366, lng: 20.8578398 },
    { lat: 48.8892478, lng: 20.8585568 },
    { lat: 48.8890398, lng: 20.859124 },
    { lat: 48.8880234, lng: 20.8583388 },
    { lat: 48.8879716, lng: 20.8583336 },
    { lat: 48.8875634, lng: 20.8588379 },
    { lat: 48.8883461, lng: 20.8595775 },
    { lat: 48.8883342, lng: 20.8596582 },
    { lat: 48.8885198, lng: 20.859817 },
    { lat: 48.8884777, lng: 20.8599981 },
    { lat: 48.8882774, lng: 20.8602324 },
    { lat: 48.8881399, lng: 20.8602257 },
    { lat: 48.8877447, lng: 20.859927 },
    { lat: 48.8875914, lng: 20.8596583 },
    { lat: 48.8875717, lng: 20.8597089 },
    { lat: 48.8871607, lng: 20.8594358 },
    { lat: 48.8866472, lng: 20.8604837 },
    { lat: 48.8844938, lng: 20.8652555 },
    { lat: 48.8800035, lng: 20.8703226 },
    { lat: 48.8789961, lng: 20.8718457 },
    { lat: 48.8782349, lng: 20.8740779 },
    { lat: 48.8770497, lng: 20.8771214 },
    { lat: 48.8752393, lng: 20.8803113 },
    { lat: 48.8748062, lng: 20.8826026 },
    { lat: 48.8747192, lng: 20.8833879 },
    { lat: 48.874936, lng: 20.884339 },
    { lat: 48.874984, lng: 20.88438 },
    { lat: 48.874994, lng: 20.884364 },
    { lat: 48.87519, lng: 20.88464 },
    { lat: 48.875528, lng: 20.884802 },
    { lat: 48.875714, lng: 20.885103 },
    { lat: 48.875763, lng: 20.885195 },
    { lat: 48.875891, lng: 20.885318 },
    { lat: 48.876062, lng: 20.885583 },
    { lat: 48.876615, lng: 20.886017 },
    { lat: 48.876708, lng: 20.88609 },
    { lat: 48.87726, lng: 20.886246 },
    { lat: 48.877465, lng: 20.886183 },
    { lat: 48.877909, lng: 20.88574 },
    { lat: 48.877942, lng: 20.885689 },
    { lat: 48.878116, lng: 20.885493 },
    { lat: 48.878388, lng: 20.885315 },
    { lat: 48.878637, lng: 20.885298 },
    { lat: 48.878755, lng: 20.885344 },
    { lat: 48.87886, lng: 20.885452 },
    { lat: 48.879087, lng: 20.885711 },
    { lat: 48.879264, lng: 20.885994 },
    { lat: 48.879424, lng: 20.886296 },
    { lat: 48.879464, lng: 20.886349 },
    { lat: 48.879647, lng: 20.886565 },
    { lat: 48.879742, lng: 20.886609 },
    { lat: 48.879878, lng: 20.886674 },
    { lat: 48.879965, lng: 20.88671 },
    { lat: 48.880086, lng: 20.886769 },
    { lat: 48.88017, lng: 20.886803 },
    { lat: 48.880207, lng: 20.886833 },
    { lat: 48.880243, lng: 20.886866 },
    { lat: 48.880274, lng: 20.886908 },
    { lat: 48.880303, lng: 20.886957 },
    { lat: 48.880328, lng: 20.88703 },
    { lat: 48.880347, lng: 20.887464 },
    { lat: 48.880357, lng: 20.887507 },
    { lat: 48.880442, lng: 20.887814 },
    { lat: 48.880582, lng: 20.887982 },
    { lat: 48.880768, lng: 20.888206 },
    { lat: 48.880879, lng: 20.888326 },
    { lat: 48.881062, lng: 20.888525 },
    { lat: 48.881142, lng: 20.888586 },
    { lat: 48.881482, lng: 20.888848 },
    { lat: 48.881649, lng: 20.888976 },
    { lat: 48.881756, lng: 20.889056 },
    { lat: 48.881975, lng: 20.88925 },
    { lat: 48.882148, lng: 20.889415 },
    { lat: 48.882348, lng: 20.889553 },
    { lat: 48.882539, lng: 20.889692 },
    { lat: 48.882624, lng: 20.889768 },
    { lat: 48.882681, lng: 20.889825 },
    { lat: 48.882725, lng: 20.889883 },
    { lat: 48.882889, lng: 20.890135 },
    { lat: 48.882884, lng: 20.89017 },
    { lat: 48.882868, lng: 20.890437 },
    { lat: 48.88285, lng: 20.890551 },
    { lat: 48.883255, lng: 20.890782 },
    { lat: 48.883306, lng: 20.890816 },
    { lat: 48.884166, lng: 20.891365 },
    { lat: 48.88443, lng: 20.891535 },
    { lat: 48.884754, lng: 20.891745 },
    { lat: 48.885825, lng: 20.89382 },
    { lat: 48.886041, lng: 20.894239 },
    { lat: 48.88606, lng: 20.894276 },
    { lat: 48.886136, lng: 20.894423 },
    { lat: 48.886224, lng: 20.8945 },
    { lat: 48.886947, lng: 20.895136 },
    { lat: 48.887572, lng: 20.895674 },
    { lat: 48.887869, lng: 20.896307 },
    { lat: 48.887876, lng: 20.89631 },
    { lat: 48.888583, lng: 20.89666 },
    { lat: 48.888919, lng: 20.89756 },
    { lat: 48.889072, lng: 20.898189 },
    { lat: 48.889094, lng: 20.898355 },
    { lat: 48.889238, lng: 20.898766 },
    { lat: 48.890153, lng: 20.899863 },
    { lat: 48.889982, lng: 20.900502 },
    { lat: 48.889964, lng: 20.90049 },
    { lat: 48.88988, lng: 20.901072 },
    { lat: 48.889872, lng: 20.901344 },
    { lat: 48.889819, lng: 20.901655 },
    { lat: 48.889838, lng: 20.901662 },
    { lat: 48.889973, lng: 20.901872 },
    { lat: 48.889912, lng: 20.902047 },
    { lat: 48.89062, lng: 20.902844 },
    { lat: 48.890904, lng: 20.903199 },
    { lat: 48.89113, lng: 20.903853 },
    { lat: 48.891486, lng: 20.904814 },
    { lat: 48.891528, lng: 20.904857 },
    { lat: 48.891533, lng: 20.904871 },
    { lat: 48.891569, lng: 20.904965 },
    { lat: 48.891693, lng: 20.904987 },
    { lat: 48.891749, lng: 20.904913 },
    { lat: 48.891771, lng: 20.905073 },
    { lat: 48.891863, lng: 20.905112 },
    { lat: 48.891899, lng: 20.905292 },
    { lat: 48.891903, lng: 20.905301 },
    { lat: 48.892054, lng: 20.905617 },
    { lat: 48.892097, lng: 20.905666 },
    { lat: 48.892098, lng: 20.905808 },
    { lat: 48.892191, lng: 20.9058 },
    { lat: 48.892356, lng: 20.906067 },
    { lat: 48.892801, lng: 20.906149 },
    { lat: 48.892934, lng: 20.906289 },
    { lat: 48.89293, lng: 20.906307 },
    { lat: 48.892963, lng: 20.90633 },
    { lat: 48.893098, lng: 20.906612 },
    { lat: 48.893213, lng: 20.906859 },
    { lat: 48.893298, lng: 20.906974 },
    { lat: 48.893458, lng: 20.907032 },
    { lat: 48.8936, lng: 20.907172 },
    { lat: 48.893669, lng: 20.907442 },
    { lat: 48.893746, lng: 20.907621 },
    { lat: 48.893847, lng: 20.907674 },
    { lat: 48.893974, lng: 20.907676 },
    { lat: 48.8942, lng: 20.907654 },
    { lat: 48.894315, lng: 20.907822 },
    { lat: 48.894397, lng: 20.908046 },
    { lat: 48.894477, lng: 20.908119 },
    { lat: 48.894613, lng: 20.908159 },
    { lat: 48.894801, lng: 20.908085 },
    { lat: 48.895115, lng: 20.908318 },
    { lat: 48.895394, lng: 20.908511 },
    { lat: 48.895647, lng: 20.908641 },
    { lat: 48.895827, lng: 20.908733 },
    { lat: 48.89601, lng: 20.908759 },
    { lat: 48.896277, lng: 20.908829 },
    { lat: 48.896488, lng: 20.908917 },
    { lat: 48.896708, lng: 20.90895 },
    { lat: 48.896838, lng: 20.909045 },
    { lat: 48.896944, lng: 20.909127 },
    { lat: 48.897176, lng: 20.909296 },
    { lat: 48.897328, lng: 20.909301 },
    { lat: 48.897575, lng: 20.909255 },
    { lat: 48.897648, lng: 20.909285 },
    { lat: 48.897782, lng: 20.909352 },
    { lat: 48.897814, lng: 20.909389 },
    { lat: 48.89797, lng: 20.909526 },
    { lat: 48.898076, lng: 20.909698 },
    { lat: 48.898191, lng: 20.910007 },
    { lat: 48.898315, lng: 20.91015 },
    { lat: 48.898516, lng: 20.910221 },
    { lat: 48.898674, lng: 20.910313 },
    { lat: 48.898848, lng: 20.910551 },
    { lat: 48.898897, lng: 20.910695 },
    { lat: 48.898931, lng: 20.910932 },
    { lat: 48.898937, lng: 20.911095 },
    { lat: 48.898971, lng: 20.911374 },
    { lat: 48.899032, lng: 20.91168 },
    { lat: 48.89901, lng: 20.911697 },
    { lat: 48.899078, lng: 20.912052 },
    { lat: 48.899221, lng: 20.912578 },
    { lat: 48.899357, lng: 20.912866 },
    { lat: 48.899471, lng: 20.91322 },
    { lat: 48.899612, lng: 20.913592 },
    { lat: 48.899676, lng: 20.913728 },
    { lat: 48.899728, lng: 20.913967 },
    { lat: 48.899842, lng: 20.914374 },
    { lat: 48.900502, lng: 20.914803 },
    { lat: 48.900987, lng: 20.915119 },
    { lat: 48.901635, lng: 20.915846 },
    { lat: 48.902267, lng: 20.916558 },
    { lat: 48.902021, lng: 20.916802 },
    { lat: 48.902281, lng: 20.917041 },
    { lat: 48.90243, lng: 20.917278 },
    { lat: 48.902622, lng: 20.917376 },
    { lat: 48.902781, lng: 20.917471 },
    { lat: 48.90297, lng: 20.917662 },
    { lat: 48.903078, lng: 20.917596 },
    { lat: 48.903142, lng: 20.917679 },
    { lat: 48.90352, lng: 20.918544 },
    { lat: 48.903633, lng: 20.918889 },
    { lat: 48.903935, lng: 20.919235 },
    { lat: 48.904049, lng: 20.919408 },
    { lat: 48.904184, lng: 20.919519 },
    { lat: 48.904247, lng: 20.919733 },
    { lat: 48.904257, lng: 20.920038 },
    { lat: 48.904306, lng: 20.920377 },
    { lat: 48.904309, lng: 20.920502 },
    { lat: 48.904545, lng: 20.920716 },
    { lat: 48.904543, lng: 20.920668 },
    { lat: 48.904545, lng: 20.920649 },
    { lat: 48.904597, lng: 20.920693 },
    { lat: 48.904789, lng: 20.920759 },
    { lat: 48.90512, lng: 20.920746 },
    { lat: 48.905477, lng: 20.920734 },
    { lat: 48.905741, lng: 20.92085 },
    { lat: 48.906044, lng: 20.920887 },
    { lat: 48.906464, lng: 20.920903 },
    { lat: 48.906729, lng: 20.920913 },
    { lat: 48.907006, lng: 20.921063 },
    { lat: 48.90701, lng: 20.921049 },
    { lat: 48.907046, lng: 20.921061 },
    { lat: 48.907095, lng: 20.921068 },
    { lat: 48.907104, lng: 20.921068 },
    { lat: 48.907114, lng: 20.920938 },
    { lat: 48.907126, lng: 20.9208 },
    { lat: 48.907145, lng: 20.920654 },
    { lat: 48.907149, lng: 20.920531 },
    { lat: 48.907141, lng: 20.920451 },
    { lat: 48.90709, lng: 20.920318 },
    { lat: 48.907079, lng: 20.920247 },
    { lat: 48.907098, lng: 20.920149 },
    { lat: 48.907059, lng: 20.920025 },
    { lat: 48.907023, lng: 20.91996 },
    { lat: 48.90706, lng: 20.919835 },
    { lat: 48.907075, lng: 20.919836 },
    { lat: 48.907101, lng: 20.919817 },
    { lat: 48.907235, lng: 20.919724 },
    { lat: 48.907404, lng: 20.919449 },
    { lat: 48.907533, lng: 20.919277 },
    { lat: 48.907652, lng: 20.919156 },
    { lat: 48.907672, lng: 20.919014 },
    { lat: 48.90785, lng: 20.918905 },
    { lat: 48.907943, lng: 20.918712 },
    { lat: 48.90807, lng: 20.918515 },
    { lat: 48.908391, lng: 20.918284 },
    { lat: 48.908582, lng: 20.918318 },
    { lat: 48.908897, lng: 20.918195 },
    { lat: 48.909182, lng: 20.918017 },
    { lat: 48.90935, lng: 20.91788 },
    { lat: 48.909423, lng: 20.917885 },
    { lat: 48.90948, lng: 20.917839 },
    { lat: 48.909495, lng: 20.917661 },
    { lat: 48.909494, lng: 20.917515 },
    { lat: 48.909411, lng: 20.916646 },
    { lat: 48.909521, lng: 20.916099 },
    { lat: 48.909444, lng: 20.91555 },
    { lat: 48.909183, lng: 20.91511 },
    { lat: 48.908978, lng: 20.91473 },
    { lat: 48.908982, lng: 20.914486 },
    { lat: 48.909098, lng: 20.91452 },
    { lat: 48.909312, lng: 20.914596 },
    { lat: 48.912186, lng: 20.912538 },
    { lat: 48.913027, lng: 20.911612 },
    { lat: 48.913584, lng: 20.910994 },
    { lat: 48.913666, lng: 20.90816 },
    { lat: 48.914203, lng: 20.907137 },
    { lat: 48.914418, lng: 20.907169 },
    { lat: 48.914478, lng: 20.907179 },
    { lat: 48.915225, lng: 20.907268 },
    { lat: 48.915931, lng: 20.907351 },
    { lat: 48.91648, lng: 20.907424 },
    { lat: 48.916737, lng: 20.907449 },
    { lat: 48.917249, lng: 20.907564 },
    { lat: 48.917783, lng: 20.907673 },
    { lat: 48.918146, lng: 20.907693 },
    { lat: 48.918523, lng: 20.90778 },
    { lat: 48.918761, lng: 20.907802 },
    { lat: 48.919177, lng: 20.907856 },
    { lat: 48.91918, lng: 20.907876 },
    { lat: 48.919375, lng: 20.907759 },
    { lat: 48.919689, lng: 20.907671 },
    { lat: 48.919957, lng: 20.907373 },
    { lat: 48.920071, lng: 20.907262 },
    { lat: 48.920191, lng: 20.9072 },
    { lat: 48.920312, lng: 20.907094 },
    { lat: 48.920451, lng: 20.907075 },
    { lat: 48.920589, lng: 20.906799 },
    { lat: 48.920736, lng: 20.906607 },
    { lat: 48.920872, lng: 20.906334 },
    { lat: 48.921055, lng: 20.90614 },
    { lat: 48.921397, lng: 20.905877 },
    { lat: 48.921429, lng: 20.905825 },
    { lat: 48.921236, lng: 20.904727 },
    { lat: 48.923476, lng: 20.902982 },
    { lat: 48.923576, lng: 20.903103 },
    { lat: 48.923844, lng: 20.902823 },
    { lat: 48.923847, lng: 20.902821 },
    { lat: 48.923911, lng: 20.902753 },
    { lat: 48.924158, lng: 20.902497 },
    { lat: 48.924209, lng: 20.902443 },
    { lat: 48.924374, lng: 20.902257 },
    { lat: 48.924448, lng: 20.902176 },
    { lat: 48.92462, lng: 20.901985 },
    { lat: 48.924704, lng: 20.901959 },
    { lat: 48.924686, lng: 20.901453 },
    { lat: 48.924692, lng: 20.901392 },
    { lat: 48.924696, lng: 20.901347 },
    { lat: 48.92487, lng: 20.901475 },
    { lat: 48.924913, lng: 20.901507 },
    { lat: 48.924897, lng: 20.901268 },
    { lat: 48.924823, lng: 20.901068 },
    { lat: 48.924812, lng: 20.90104 },
    { lat: 48.924731, lng: 20.900755 },
    { lat: 48.924503, lng: 20.90082 },
    { lat: 48.924273, lng: 20.900832 },
    { lat: 48.924017, lng: 20.900664 },
    { lat: 48.923904, lng: 20.900514 },
    { lat: 48.923829, lng: 20.900415 },
    { lat: 48.923755, lng: 20.900315 },
    { lat: 48.923586, lng: 20.899957 },
    { lat: 48.923522, lng: 20.899571 },
    { lat: 48.923572, lng: 20.899366 },
    { lat: 48.923593, lng: 20.898726 },
    { lat: 48.923629, lng: 20.898584 },
    { lat: 48.923653, lng: 20.898487 },
    { lat: 48.923765, lng: 20.898036 },
    { lat: 48.923855, lng: 20.897924 },
    { lat: 48.923965, lng: 20.898182 },
    { lat: 48.924023, lng: 20.898314 },
    { lat: 48.924131, lng: 20.898454 },
    { lat: 48.924201, lng: 20.898491 },
    { lat: 48.924367, lng: 20.898579 },
    { lat: 48.924626, lng: 20.89849 },
    { lat: 48.924765, lng: 20.898262 },
    { lat: 48.924782, lng: 20.898494 },
    { lat: 48.925529, lng: 20.898323 },
    { lat: 48.925536, lng: 20.898322 },
    { lat: 48.925619, lng: 20.898302 },
    { lat: 48.925755, lng: 20.89669 },
    { lat: 48.92561, lng: 20.894988 },
    { lat: 48.925638, lng: 20.894217 },
    { lat: 48.925667, lng: 20.894085 },
    { lat: 48.925787, lng: 20.893288 },
    { lat: 48.925791, lng: 20.892926 },
    { lat: 48.925682, lng: 20.89147 },
    { lat: 48.925558, lng: 20.890928 },
    { lat: 48.925284, lng: 20.890232 },
    { lat: 48.925269, lng: 20.890123 },
    { lat: 48.925163, lng: 20.889338 },
    { lat: 48.925107, lng: 20.888932 },
    { lat: 48.92513, lng: 20.88864 },
    { lat: 48.925149, lng: 20.888388 },
    { lat: 48.925228, lng: 20.888063 },
    { lat: 48.925266, lng: 20.887908 },
    { lat: 48.925351, lng: 20.8874 },
    { lat: 48.925358, lng: 20.887016 },
    { lat: 48.925301, lng: 20.88622 },
    { lat: 48.925111, lng: 20.88556 },
    { lat: 48.925032, lng: 20.88528 },
    { lat: 48.92508, lng: 20.885012 },
    { lat: 48.925085, lng: 20.884974 },
    { lat: 48.925118, lng: 20.884819 },
    { lat: 48.925137, lng: 20.88478 },
    { lat: 48.925182, lng: 20.884744 },
    { lat: 48.925047, lng: 20.882762 },
    { lat: 48.925034, lng: 20.88191 },
    { lat: 48.925072, lng: 20.880643 },
    { lat: 48.925093, lng: 20.880197 },
    { lat: 48.92507, lng: 20.880097 },
    { lat: 48.925156, lng: 20.879831 },
    { lat: 48.925165, lng: 20.87973 },
    { lat: 48.925212, lng: 20.879357 },
    { lat: 48.925231, lng: 20.879196 },
    { lat: 48.925231, lng: 20.878991 },
    { lat: 48.925236, lng: 20.878723 },
    { lat: 48.926483, lng: 20.878816 },
    { lat: 48.926524, lng: 20.87882 },
    { lat: 48.926524, lng: 20.878797 },
    { lat: 48.926915, lng: 20.878752 },
    { lat: 48.92713, lng: 20.8786 },
    { lat: 48.927137, lng: 20.878612 },
    { lat: 48.927438, lng: 20.878457 },
    { lat: 48.927645, lng: 20.878448 },
    { lat: 48.927973, lng: 20.878502 },
    { lat: 48.928263, lng: 20.87846 },
    { lat: 48.928501, lng: 20.878469 },
    { lat: 48.928764, lng: 20.878653 },
    { lat: 48.92896, lng: 20.8786 },
    { lat: 48.929146, lng: 20.878304 },
    { lat: 48.92934, lng: 20.878133 },
    { lat: 48.929599, lng: 20.87784 },
    { lat: 48.929753, lng: 20.87753 },
    { lat: 48.929835, lng: 20.877141 },
    { lat: 48.929854, lng: 20.877142 },
    { lat: 48.9298045, lng: 20.8755952 },
    { lat: 48.9299063, lng: 20.8746844 },
    { lat: 48.9294824, lng: 20.8737705 },
    { lat: 48.9295022, lng: 20.873692 },
    { lat: 48.929382, lng: 20.8735027 },
    { lat: 48.9292876, lng: 20.87314 },
    { lat: 48.9293799, lng: 20.8730107 },
    { lat: 48.929357, lng: 20.872734 },
    { lat: 48.9295315, lng: 20.8723093 },
    { lat: 48.9292164, lng: 20.8717781 },
    { lat: 48.9294427, lng: 20.8716508 },
    { lat: 48.9293253, lng: 20.871112 },
    { lat: 48.9289454, lng: 20.8704702 },
    { lat: 48.9288547, lng: 20.8700525 },
    { lat: 48.9287551, lng: 20.8700019 },
    { lat: 48.9285814, lng: 20.870089 },
    { lat: 48.9285082, lng: 20.8700582 },
    { lat: 48.9283892, lng: 20.8698692 },
    { lat: 48.9283411, lng: 20.8696829 },
    { lat: 48.9284224, lng: 20.8694249 },
    { lat: 48.9286288, lng: 20.8690718 },
    { lat: 48.9284858, lng: 20.8688411 },
    { lat: 48.9282824, lng: 20.868818 },
    { lat: 48.9279396, lng: 20.8668189 },
    { lat: 48.9278097, lng: 20.8657165 },
    { lat: 48.9269289, lng: 20.8624056 },
    { lat: 48.926998, lng: 20.8623218 },
    { lat: 48.9266662, lng: 20.8609783 },
    { lat: 48.9264685, lng: 20.8610175 },
    { lat: 48.925857, lng: 20.861574 },
    { lat: 48.9250598, lng: 20.8618586 },
    { lat: 48.9246925, lng: 20.861918 },
    { lat: 48.9242538, lng: 20.8616314 },
    { lat: 48.9239305, lng: 20.861625 },
    { lat: 48.9233273, lng: 20.8624159 },
    { lat: 48.9232289, lng: 20.8624351 },
    { lat: 48.9232263, lng: 20.8620634 },
    { lat: 48.9230471, lng: 20.8618252 },
    { lat: 48.922892, lng: 20.8618696 },
    { lat: 48.9226933, lng: 20.8616157 },
    { lat: 48.9221604, lng: 20.8616344 },
    { lat: 48.9217785, lng: 20.8619254 },
    { lat: 48.9214272, lng: 20.8623854 },
    { lat: 48.9212947, lng: 20.8622638 },
    { lat: 48.9210403, lng: 20.861438 },
    { lat: 48.9208184, lng: 20.8611015 },
    { lat: 48.9208266, lng: 20.8606521 },
    { lat: 48.9205443, lng: 20.8599968 },
    { lat: 48.9205108, lng: 20.8597746 },
    { lat: 48.9200975, lng: 20.859043 },
    { lat: 48.9199625, lng: 20.8585848 },
    { lat: 48.9198205, lng: 20.8584206 },
    { lat: 48.9196313, lng: 20.8580536 },
    { lat: 48.9195906, lng: 20.8576857 },
    { lat: 48.9193742, lng: 20.8571175 },
    { lat: 48.9193005, lng: 20.8564798 },
    { lat: 48.9192018, lng: 20.8562564 },
    { lat: 48.9190944, lng: 20.85615 },
    { lat: 48.9190636, lng: 20.856001 },
    { lat: 48.9189366, lng: 20.8558736 },
    { lat: 48.9188304, lng: 20.8558651 },
    { lat: 48.9184654, lng: 20.855235 },
    { lat: 48.9180376, lng: 20.8548371 },
    { lat: 48.917727, lng: 20.8543097 },
    { lat: 48.9171396, lng: 20.853495 },
    { lat: 48.9166737, lng: 20.8531355 },
    { lat: 48.9163483, lng: 20.8527389 },
    { lat: 48.9159717, lng: 20.852031 },
    { lat: 48.9158305, lng: 20.8515257 },
    { lat: 48.9155253, lng: 20.8517194 },
    { lat: 48.915032, lng: 20.8513797 },
    { lat: 48.9148417, lng: 20.8510851 },
    { lat: 48.9147243, lng: 20.8511674 },
    { lat: 48.9144892, lng: 20.8498636 },
    { lat: 48.9141175, lng: 20.8485441 },
    { lat: 48.9141396, lng: 20.8482654 },
    { lat: 48.9140572, lng: 20.8468197 },
    { lat: 48.9142898, lng: 20.8463461 },
    { lat: 48.9142963, lng: 20.8459111 },
    { lat: 48.9141018, lng: 20.8435896 },
    { lat: 48.9138418, lng: 20.8426056 },
    { lat: 48.9137275, lng: 20.8415381 },
    { lat: 48.913258, lng: 20.8401023 },
    { lat: 48.9131945, lng: 20.839495 },
    { lat: 48.9129837, lng: 20.8383039 },
    { lat: 48.9130165, lng: 20.8382766 },
    { lat: 48.9122519, lng: 20.8372231 },
    { lat: 48.9120066, lng: 20.8365043 },
    { lat: 48.9119594, lng: 20.8362156 },
    { lat: 48.9120509, lng: 20.8354054 },
    { lat: 48.9124088, lng: 20.8339631 },
    { lat: 48.9124949, lng: 20.8328039 },
    { lat: 48.9124627, lng: 20.8323291 },
    { lat: 48.9119335, lng: 20.8306551 },
  ],
  Olcnava: [
    { lat: 48.9389633, lng: 20.7322902 },
    { lat: 48.9389643, lng: 20.7323195 },
    { lat: 48.9382964, lng: 20.7327243 },
    { lat: 48.9379418, lng: 20.7330113 },
    { lat: 48.9367356, lng: 20.7343991 },
    { lat: 48.9367056, lng: 20.7343439 },
    { lat: 48.9362474, lng: 20.7345698 },
    { lat: 48.9357454, lng: 20.7348831 },
    { lat: 48.9353025, lng: 20.7351594 },
    { lat: 48.9350269, lng: 20.7356132 },
    { lat: 48.9347837, lng: 20.735866 },
    { lat: 48.9344256, lng: 20.7360537 },
    { lat: 48.9342666, lng: 20.736274 },
    { lat: 48.9339655, lng: 20.7364452 },
    { lat: 48.933806, lng: 20.7366777 },
    { lat: 48.9334439, lng: 20.7367729 },
    { lat: 48.9331913, lng: 20.7368393 },
    { lat: 48.9324388, lng: 20.7372087 },
    { lat: 48.9321434, lng: 20.7373537 },
    { lat: 48.9319811, lng: 20.737379 },
    { lat: 48.9317793, lng: 20.7373302 },
    { lat: 48.9316939, lng: 20.7373096 },
    { lat: 48.931434, lng: 20.7370647 },
    { lat: 48.9312785, lng: 20.7370569 },
    { lat: 48.9296362, lng: 20.7376986 },
    { lat: 48.9291883, lng: 20.7376068 },
    { lat: 48.9285761, lng: 20.7372617 },
    { lat: 48.9283856, lng: 20.737285 },
    { lat: 48.9275874, lng: 20.7381772 },
    { lat: 48.9257806, lng: 20.7395789 },
    { lat: 48.9245204, lng: 20.7394381 },
    { lat: 48.9236703, lng: 20.739467 },
    { lat: 48.923572, lng: 20.7393608 },
    { lat: 48.9234562, lng: 20.7393344 },
    { lat: 48.9226022, lng: 20.7393142 },
    { lat: 48.9212863, lng: 20.7390551 },
    { lat: 48.9201598, lng: 20.7389386 },
    { lat: 48.9188448, lng: 20.7381991 },
    { lat: 48.9187256, lng: 20.7380749 },
    { lat: 48.9184984, lng: 20.7373782 },
    { lat: 48.9184052, lng: 20.736937 },
    { lat: 48.918401, lng: 20.7366896 },
    { lat: 48.9179933, lng: 20.735631 },
    { lat: 48.9180164, lng: 20.7352369 },
    { lat: 48.9179749, lng: 20.7347291 },
    { lat: 48.9177492, lng: 20.7341694 },
    { lat: 48.9175376, lng: 20.7338895 },
    { lat: 48.9165398, lng: 20.7329129 },
    { lat: 48.9163717, lng: 20.7322684 },
    { lat: 48.9161372, lng: 20.7318716 },
    { lat: 48.9157987, lng: 20.7315999 },
    { lat: 48.9156724, lng: 20.7311488 },
    { lat: 48.9154746, lng: 20.7308377 },
    { lat: 48.9153292, lng: 20.7301015 },
    { lat: 48.915114, lng: 20.7294243 },
    { lat: 48.915006, lng: 20.7293927 },
    { lat: 48.9146347, lng: 20.7286315 },
    { lat: 48.9145117, lng: 20.7285005 },
    { lat: 48.9144612, lng: 20.7283142 },
    { lat: 48.914285, lng: 20.7282004 },
    { lat: 48.9138696, lng: 20.7276279 },
    { lat: 48.9135515, lng: 20.7273517 },
    { lat: 48.9132074, lng: 20.7268526 },
    { lat: 48.9128333, lng: 20.726654 },
    { lat: 48.9124392, lng: 20.7263592 },
    { lat: 48.9120496, lng: 20.7258173 },
    { lat: 48.9117135, lng: 20.7253569 },
    { lat: 48.9116531, lng: 20.7253657 },
    { lat: 48.9114832, lng: 20.7251484 },
    { lat: 48.9114013, lng: 20.7251539 },
    { lat: 48.9113563, lng: 20.7249966 },
    { lat: 48.9110201, lng: 20.7246897 },
    { lat: 48.9109478, lng: 20.7245256 },
    { lat: 48.9104522, lng: 20.7241655 },
    { lat: 48.9095099, lng: 20.7238736 },
    { lat: 48.9089488, lng: 20.723352 },
    { lat: 48.9088332, lng: 20.723331 },
    { lat: 48.9086209, lng: 20.72309 },
    { lat: 48.9083666, lng: 20.7229632 },
    { lat: 48.9082397, lng: 20.7230376 },
    { lat: 48.9081305, lng: 20.7229792 },
    { lat: 48.9069827, lng: 20.7235261 },
    { lat: 48.9068851, lng: 20.7235359 },
    { lat: 48.906831, lng: 20.7234061 },
    { lat: 48.9064084, lng: 20.7234891 },
    { lat: 48.9057825, lng: 20.723461 },
    { lat: 48.9054001, lng: 20.7235952 },
    { lat: 48.9052402, lng: 20.723561 },
    { lat: 48.9051079, lng: 20.7237042 },
    { lat: 48.9049078, lng: 20.7240354 },
    { lat: 48.9046884, lng: 20.7244154 },
    { lat: 48.9043312, lng: 20.7248205 },
    { lat: 48.9032147, lng: 20.7270265 },
    { lat: 48.9027936, lng: 20.7272461 },
    { lat: 48.9025222, lng: 20.7274639 },
    { lat: 48.901299, lng: 20.7278619 },
    { lat: 48.9006918, lng: 20.72842 },
    { lat: 48.9004607, lng: 20.728665 },
    { lat: 48.8999403, lng: 20.7304122 },
    { lat: 48.8990772, lng: 20.7305139 },
    { lat: 48.8984782, lng: 20.7309858 },
    { lat: 48.8974938, lng: 20.7323742 },
    { lat: 48.8971351, lng: 20.7327035 },
    { lat: 48.8969861, lng: 20.7327593 },
    { lat: 48.8965912, lng: 20.733147 },
    { lat: 48.8961953, lng: 20.733665 },
    { lat: 48.8958667, lng: 20.7346609 },
    { lat: 48.8956777, lng: 20.7348222 },
    { lat: 48.8955473, lng: 20.7353358 },
    { lat: 48.8956305, lng: 20.7355877 },
    { lat: 48.8956352, lng: 20.7358496 },
    { lat: 48.8954641, lng: 20.7361422 },
    { lat: 48.8952512, lng: 20.7362908 },
    { lat: 48.8950237, lng: 20.7363561 },
    { lat: 48.8947373, lng: 20.737585 },
    { lat: 48.8945122, lng: 20.7381317 },
    { lat: 48.8945191, lng: 20.7398609 },
    { lat: 48.8948305, lng: 20.7410578 },
    { lat: 48.8950906, lng: 20.7425031 },
    { lat: 48.8945323, lng: 20.7428865 },
    { lat: 48.8934203, lng: 20.7496078 },
    { lat: 48.8934866, lng: 20.7503051 },
    { lat: 48.8932102, lng: 20.7512544 },
    { lat: 48.8925948, lng: 20.752543 },
    { lat: 48.8915625, lng: 20.7522526 },
    { lat: 48.8908949, lng: 20.7525738 },
    { lat: 48.89054, lng: 20.7525975 },
    { lat: 48.8883553, lng: 20.7537553 },
    { lat: 48.8880837, lng: 20.7545534 },
    { lat: 48.8869974, lng: 20.7551899 },
    { lat: 48.8868372, lng: 20.7554046 },
    { lat: 48.8861904, lng: 20.7553835 },
    { lat: 48.8852252, lng: 20.7556618 },
    { lat: 48.8835114, lng: 20.7559039 },
    { lat: 48.8821715, lng: 20.7579163 },
    { lat: 48.8815815, lng: 20.7582658 },
    { lat: 48.8806073, lng: 20.7580916 },
    { lat: 48.8783514, lng: 20.7583527 },
    { lat: 48.8783493, lng: 20.7584476 },
    { lat: 48.878296, lng: 20.7586955 },
    { lat: 48.8781623, lng: 20.7591211 },
    { lat: 48.8781779, lng: 20.7593366 },
    { lat: 48.8780758, lng: 20.7597669 },
    { lat: 48.8780811, lng: 20.7609398 },
    { lat: 48.8780411, lng: 20.7614412 },
    { lat: 48.8781271, lng: 20.7619368 },
    { lat: 48.8780671, lng: 20.762114 },
    { lat: 48.8781996, lng: 20.7628122 },
    { lat: 48.8783935, lng: 20.7631102 },
    { lat: 48.8786131, lng: 20.7636275 },
    { lat: 48.8787135, lng: 20.7639885 },
    { lat: 48.8787362, lng: 20.764345 },
    { lat: 48.8788135, lng: 20.7646528 },
    { lat: 48.8789057, lng: 20.7647795 },
    { lat: 48.8789914, lng: 20.7651403 },
    { lat: 48.8789989, lng: 20.7653864 },
    { lat: 48.8790055, lng: 20.7654145 },
    { lat: 48.8791831, lng: 20.7659846 },
    { lat: 48.8792908, lng: 20.7667767 },
    { lat: 48.8793583, lng: 20.7669987 },
    { lat: 48.8792335, lng: 20.7674233 },
    { lat: 48.8792592, lng: 20.7676741 },
    { lat: 48.8792306, lng: 20.7678205 },
    { lat: 48.8793134, lng: 20.7682906 },
    { lat: 48.8793764, lng: 20.7684801 },
    { lat: 48.8795757, lng: 20.7685803 },
    { lat: 48.8796023, lng: 20.7687602 },
    { lat: 48.8796772, lng: 20.7704154 },
    { lat: 48.8798719, lng: 20.7708021 },
    { lat: 48.8800673, lng: 20.771433 },
    { lat: 48.8802627, lng: 20.7718736 },
    { lat: 48.8802996, lng: 20.7721847 },
    { lat: 48.8809966, lng: 20.7735138 },
    { lat: 48.8812601, lng: 20.773836 },
    { lat: 48.8815637, lng: 20.7743352 },
    { lat: 48.8817667, lng: 20.7748675 },
    { lat: 48.8820379, lng: 20.7752478 },
    { lat: 48.8822819, lng: 20.7753995 },
    { lat: 48.8828843, lng: 20.776208 },
    { lat: 48.8830962, lng: 20.7766681 },
    { lat: 48.8831691, lng: 20.7771169 },
    { lat: 48.8833504, lng: 20.7777264 },
    { lat: 48.8836292, lng: 20.7784548 },
    { lat: 48.8837348, lng: 20.7790354 },
    { lat: 48.8837815, lng: 20.7801415 },
    { lat: 48.8837537, lng: 20.7806019 },
    { lat: 48.8835714, lng: 20.7811592 },
    { lat: 48.8835449, lng: 20.7814256 },
    { lat: 48.8837038, lng: 20.7820803 },
    { lat: 48.8838173, lng: 20.78204 },
    { lat: 48.8841562, lng: 20.7816262 },
    { lat: 48.8857497, lng: 20.7812551 },
    { lat: 48.886602, lng: 20.7809063 },
    { lat: 48.8870918, lng: 20.7805833 },
    { lat: 48.8874774, lng: 20.7792525 },
    { lat: 48.8876031, lng: 20.7789802 },
    { lat: 48.8877274, lng: 20.7781228 },
    { lat: 48.8878135, lng: 20.7779785 },
    { lat: 48.8878123, lng: 20.7778166 },
    { lat: 48.8878993, lng: 20.777532 },
    { lat: 48.888123, lng: 20.7772368 },
    { lat: 48.8881513, lng: 20.7770757 },
    { lat: 48.88852, lng: 20.776678 },
    { lat: 48.8888832, lng: 20.7765333 },
    { lat: 48.8892869, lng: 20.776043 },
    { lat: 48.8900479, lng: 20.7754642 },
    { lat: 48.8902857, lng: 20.7753764 },
    { lat: 48.8905286, lng: 20.7750864 },
    { lat: 48.8923972, lng: 20.7728549 },
    { lat: 48.8933727, lng: 20.7725797 },
    { lat: 48.8937472, lng: 20.7726043 },
    { lat: 48.894278, lng: 20.7723624 },
    { lat: 48.8960495, lng: 20.7708378 },
    { lat: 48.8971037, lng: 20.769377 },
    { lat: 48.8972044, lng: 20.7691324 },
    { lat: 48.8979244, lng: 20.7690887 },
    { lat: 48.8984769, lng: 20.7693061 },
    { lat: 48.8987738, lng: 20.7695932 },
    { lat: 48.8990178, lng: 20.7699667 },
    { lat: 48.8991041, lng: 20.7704714 },
    { lat: 48.8992283, lng: 20.7706417 },
    { lat: 48.9001434, lng: 20.7709287 },
    { lat: 48.900968, lng: 20.7710141 },
    { lat: 48.9012953, lng: 20.7711666 },
    { lat: 48.9015506, lng: 20.7709307 },
    { lat: 48.9017213, lng: 20.7705659 },
    { lat: 48.9035472, lng: 20.7687 },
    { lat: 48.9040227, lng: 20.7684578 },
    { lat: 48.9044262, lng: 20.7680238 },
    { lat: 48.9056549, lng: 20.7679402 },
    { lat: 48.9059175, lng: 20.7676933 },
    { lat: 48.9061344, lng: 20.7673924 },
    { lat: 48.9068494, lng: 20.766881 },
    { lat: 48.9073631, lng: 20.7664138 },
    { lat: 48.9078691, lng: 20.7654666 },
    { lat: 48.9091456, lng: 20.7633747 },
    { lat: 48.9100522, lng: 20.7621647 },
    { lat: 48.9106152, lng: 20.7608517 },
    { lat: 48.9119357, lng: 20.7587986 },
    { lat: 48.9122711, lng: 20.7587018 },
    { lat: 48.9123868, lng: 20.7588556 },
    { lat: 48.9124805, lng: 20.7588617 },
    { lat: 48.912645, lng: 20.758994 },
    { lat: 48.9128566, lng: 20.7588642 },
    { lat: 48.9129909, lng: 20.7586193 },
    { lat: 48.913533, lng: 20.7587323 },
    { lat: 48.9139644, lng: 20.7584487 },
    { lat: 48.9140676, lng: 20.7585059 },
    { lat: 48.9141578, lng: 20.7583494 },
    { lat: 48.9143197, lng: 20.7582758 },
    { lat: 48.914356, lng: 20.7579336 },
    { lat: 48.9145135, lng: 20.7577416 },
    { lat: 48.9146997, lng: 20.7576249 },
    { lat: 48.9149798, lng: 20.7576817 },
    { lat: 48.9150824, lng: 20.7577804 },
    { lat: 48.9155063, lng: 20.7575798 },
    { lat: 48.9156591, lng: 20.7577263 },
    { lat: 48.9157071, lng: 20.7575263 },
    { lat: 48.9158421, lng: 20.75765 },
    { lat: 48.9158845, lng: 20.7573878 },
    { lat: 48.9160223, lng: 20.7573663 },
    { lat: 48.9161212, lng: 20.7574557 },
    { lat: 48.9164799, lng: 20.7575076 },
    { lat: 48.9166082, lng: 20.7572521 },
    { lat: 48.9170725, lng: 20.7572668 },
    { lat: 48.9172438, lng: 20.7570801 },
    { lat: 48.9177557, lng: 20.757008 },
    { lat: 48.9179492, lng: 20.7568749 },
    { lat: 48.9181811, lng: 20.7569088 },
    { lat: 48.9191828, lng: 20.7564546 },
    { lat: 48.9192841, lng: 20.7564517 },
    { lat: 48.9194962, lng: 20.7610859 },
    { lat: 48.9196483, lng: 20.7621557 },
    { lat: 48.9196329, lng: 20.7626792 },
    { lat: 48.9198227, lng: 20.7653637 },
    { lat: 48.9195664, lng: 20.76759 },
    { lat: 48.9194641, lng: 20.7681115 },
    { lat: 48.9194232, lng: 20.7690658 },
    { lat: 48.9192924, lng: 20.7694135 },
    { lat: 48.9192071, lng: 20.7700643 },
    { lat: 48.9200507, lng: 20.7714632 },
    { lat: 48.9202136, lng: 20.771614 },
    { lat: 48.920382, lng: 20.7715671 },
    { lat: 48.9208908, lng: 20.7713103 },
    { lat: 48.9211776, lng: 20.7712754 },
    { lat: 48.9216944, lng: 20.7710788 },
    { lat: 48.9256492, lng: 20.7694176 },
    { lat: 48.927075, lng: 20.7689538 },
    { lat: 48.929145, lng: 20.7680474 },
    { lat: 48.9312732, lng: 20.76723 },
    { lat: 48.9333447, lng: 20.7658529 },
    { lat: 48.9330249, lng: 20.7649811 },
    { lat: 48.9343999, lng: 20.7631334 },
    { lat: 48.9344638, lng: 20.762966 },
    { lat: 48.9343945, lng: 20.7611753 },
    { lat: 48.9343727, lng: 20.760944 },
    { lat: 48.9341668, lng: 20.7605614 },
    { lat: 48.9339318, lng: 20.7603699 },
    { lat: 48.9336036, lng: 20.7603556 },
    { lat: 48.9332147, lng: 20.7606261 },
    { lat: 48.9330649, lng: 20.7606586 },
    { lat: 48.9328388, lng: 20.759794 },
    { lat: 48.9358161, lng: 20.7582857 },
    { lat: 48.9361789, lng: 20.7579349 },
    { lat: 48.9375828, lng: 20.7568231 },
    { lat: 48.9376438, lng: 20.7566309 },
    { lat: 48.9383586, lng: 20.7560561 },
    { lat: 48.9391446, lng: 20.7551955 },
    { lat: 48.9394674, lng: 20.7549295 },
    { lat: 48.9398753, lng: 20.7551046 },
    { lat: 48.9402942, lng: 20.7551699 },
    { lat: 48.9412729, lng: 20.7551846 },
    { lat: 48.9431951, lng: 20.7544984 },
    { lat: 48.9431861, lng: 20.7543684 },
    { lat: 48.9431385, lng: 20.7537177 },
    { lat: 48.9431663, lng: 20.7532259 },
    { lat: 48.9429917, lng: 20.7526796 },
    { lat: 48.9426014, lng: 20.7519513 },
    { lat: 48.9422504, lng: 20.7509305 },
    { lat: 48.9426607, lng: 20.7487981 },
    { lat: 48.9428726, lng: 20.7475569 },
    { lat: 48.9429037, lng: 20.7471609 },
    { lat: 48.942847, lng: 20.746634 },
    { lat: 48.94263, lng: 20.7460389 },
    { lat: 48.9424286, lng: 20.7456907 },
    { lat: 48.9418415, lng: 20.7441376 },
    { lat: 48.9418214, lng: 20.7434642 },
    { lat: 48.9419814, lng: 20.7424493 },
    { lat: 48.9418941, lng: 20.7417309 },
    { lat: 48.9419073, lng: 20.7415568 },
    { lat: 48.9421915, lng: 20.741065 },
    { lat: 48.9423085, lng: 20.7409657 },
    { lat: 48.9418838, lng: 20.740507 },
    { lat: 48.94184, lng: 20.740092 },
    { lat: 48.9419082, lng: 20.7380993 },
    { lat: 48.9415735, lng: 20.7360775 },
    { lat: 48.9413293, lng: 20.7336219 },
    { lat: 48.9412396, lng: 20.7312743 },
    { lat: 48.9409616, lng: 20.731337 },
    { lat: 48.9396541, lng: 20.7318674 },
    { lat: 48.9389633, lng: 20.7322902 },
  ],
  SpišskýHrušov: [
    { lat: 48.965593, lng: 20.682069 },
    { lat: 48.9649125, lng: 20.6826579 },
    { lat: 48.9646163, lng: 20.6839746 },
    { lat: 48.964434, lng: 20.6843536 },
    { lat: 48.964006, lng: 20.6846356 },
    { lat: 48.9638309, lng: 20.6846791 },
    { lat: 48.9636955, lng: 20.6846404 },
    { lat: 48.9630255, lng: 20.6841316 },
    { lat: 48.962565, lng: 20.6839718 },
    { lat: 48.9618129, lng: 20.6838123 },
    { lat: 48.9614241, lng: 20.6837977 },
    { lat: 48.9606391, lng: 20.6840099 },
    { lat: 48.9596641, lng: 20.6836936 },
    { lat: 48.958427, lng: 20.6830962 },
    { lat: 48.9578269, lng: 20.6826803 },
    { lat: 48.956998, lng: 20.6807217 },
    { lat: 48.9565901, lng: 20.679887 },
    { lat: 48.9555325, lng: 20.678302 },
    { lat: 48.9539274, lng: 20.6764144 },
    { lat: 48.9533672, lng: 20.675611 },
    { lat: 48.9526985, lng: 20.6745382 },
    { lat: 48.9526009, lng: 20.6742959 },
    { lat: 48.9525801, lng: 20.6741235 },
    { lat: 48.952992, lng: 20.673775 },
    { lat: 48.9535521, lng: 20.6729737 },
    { lat: 48.953697, lng: 20.6724281 },
    { lat: 48.9537913, lng: 20.6716104 },
    { lat: 48.9537634, lng: 20.6712051 },
    { lat: 48.9535997, lng: 20.6703109 },
    { lat: 48.9533431, lng: 20.6698715 },
    { lat: 48.9533115, lng: 20.6696201 },
    { lat: 48.95344, lng: 20.6693409 },
    { lat: 48.9533522, lng: 20.6692489 },
    { lat: 48.9511627, lng: 20.6698785 },
    { lat: 48.9505636, lng: 20.6699656 },
    { lat: 48.950388, lng: 20.6714454 },
    { lat: 48.9491138, lng: 20.6714821 },
    { lat: 48.9489779, lng: 20.6720893 },
    { lat: 48.9486142, lng: 20.672051 },
    { lat: 48.9486013, lng: 20.6724804 },
    { lat: 48.9457645, lng: 20.6720437 },
    { lat: 48.945362, lng: 20.6723394 },
    { lat: 48.9445188, lng: 20.6731614 },
    { lat: 48.9430921, lng: 20.6730245 },
    { lat: 48.9429426, lng: 20.673059 },
    { lat: 48.9414877, lng: 20.6748424 },
    { lat: 48.9406814, lng: 20.6760942 },
    { lat: 48.9392466, lng: 20.6774311 },
    { lat: 48.9401971, lng: 20.679327 },
    { lat: 48.9397251, lng: 20.680674 },
    { lat: 48.939795, lng: 20.681277 },
    { lat: 48.9394977, lng: 20.6820273 },
    { lat: 48.9393081, lng: 20.6824254 },
    { lat: 48.9392204, lng: 20.6824942 },
    { lat: 48.9392053, lng: 20.6826992 },
    { lat: 48.9390327, lng: 20.6832874 },
    { lat: 48.9385082, lng: 20.6846483 },
    { lat: 48.9382664, lng: 20.6851555 },
    { lat: 48.9381476, lng: 20.685832 },
    { lat: 48.9380109, lng: 20.686326 },
    { lat: 48.9372123, lng: 20.6879561 },
    { lat: 48.9368626, lng: 20.6889108 },
    { lat: 48.9365886, lng: 20.6894958 },
    { lat: 48.9365527, lng: 20.6909576 },
    { lat: 48.9369997, lng: 20.6923148 },
    { lat: 48.9370455, lng: 20.692561 },
    { lat: 48.9369057, lng: 20.6928339 },
    { lat: 48.9365965, lng: 20.6937029 },
    { lat: 48.9363772, lng: 20.6941473 },
    { lat: 48.9359537, lng: 20.6953132 },
    { lat: 48.9358157, lng: 20.6958942 },
    { lat: 48.9351436, lng: 20.6979151 },
    { lat: 48.9345239, lng: 20.6991027 },
    { lat: 48.934431, lng: 20.6993688 },
    { lat: 48.9344148, lng: 20.6995821 },
    { lat: 48.9338882, lng: 20.7006017 },
    { lat: 48.9331535, lng: 20.7016533 },
    { lat: 48.9327941, lng: 20.702441 },
    { lat: 48.9323509, lng: 20.7031784 },
    { lat: 48.9319851, lng: 20.7033397 },
    { lat: 48.931501, lng: 20.7029272 },
    { lat: 48.9314143, lng: 20.7026708 },
    { lat: 48.9304433, lng: 20.7047466 },
    { lat: 48.9304253, lng: 20.7054745 },
    { lat: 48.9301644, lng: 20.7060204 },
    { lat: 48.9300747, lng: 20.7064766 },
    { lat: 48.9297953, lng: 20.7068797 },
    { lat: 48.9295615, lng: 20.7074899 },
    { lat: 48.9292996, lng: 20.7079803 },
    { lat: 48.9292318, lng: 20.7085405 },
    { lat: 48.9289815, lng: 20.7094509 },
    { lat: 48.9279391, lng: 20.7096708 },
    { lat: 48.9274849, lng: 20.7107726 },
    { lat: 48.9273806, lng: 20.7111889 },
    { lat: 48.9267718, lng: 20.7113911 },
    { lat: 48.9264906, lng: 20.7116833 },
    { lat: 48.9260695, lng: 20.7119941 },
    { lat: 48.9255969, lng: 20.7118125 },
    { lat: 48.9258707, lng: 20.7125817 },
    { lat: 48.9262349, lng: 20.7133928 },
    { lat: 48.9265112, lng: 20.7138213 },
    { lat: 48.9280251, lng: 20.7134661 },
    { lat: 48.9286944, lng: 20.7128146 },
    { lat: 48.9289483, lng: 20.7124187 },
    { lat: 48.9300147, lng: 20.712429 },
    { lat: 48.9301167, lng: 20.7130497 },
    { lat: 48.9303833, lng: 20.7130111 },
    { lat: 48.9314495, lng: 20.7140349 },
    { lat: 48.9321249, lng: 20.7127582 },
    { lat: 48.9343953, lng: 20.7141846 },
    { lat: 48.9355912, lng: 20.7145091 },
    { lat: 48.9359646, lng: 20.7157393 },
    { lat: 48.9358007, lng: 20.7186125 },
    { lat: 48.9364728, lng: 20.7189981 },
    { lat: 48.9363599, lng: 20.719461 },
    { lat: 48.9370444, lng: 20.7200053 },
    { lat: 48.9372342, lng: 20.720249 },
    { lat: 48.9372534, lng: 20.7203445 },
    { lat: 48.9372336, lng: 20.7221074 },
    { lat: 48.9372661, lng: 20.7233108 },
    { lat: 48.9370726, lng: 20.7238579 },
    { lat: 48.9372863, lng: 20.7247428 },
    { lat: 48.9372606, lng: 20.7251298 },
    { lat: 48.9373702, lng: 20.7257815 },
    { lat: 48.9375468, lng: 20.7263809 },
    { lat: 48.9375917, lng: 20.7267303 },
    { lat: 48.9380936, lng: 20.7282492 },
    { lat: 48.9379991, lng: 20.7282859 },
    { lat: 48.9383262, lng: 20.7294194 },
    { lat: 48.9384361, lng: 20.7302068 },
    { lat: 48.9385227, lng: 20.7303609 },
    { lat: 48.9386987, lng: 20.731042 },
    { lat: 48.9389633, lng: 20.7322902 },
    { lat: 48.9396541, lng: 20.7318674 },
    { lat: 48.9409616, lng: 20.731337 },
    { lat: 48.9412396, lng: 20.7312743 },
    { lat: 48.9420445, lng: 20.7313002 },
    { lat: 48.9422659, lng: 20.7311644 },
    { lat: 48.9424434, lng: 20.7309952 },
    { lat: 48.9424299, lng: 20.7309999 },
    { lat: 48.9428533, lng: 20.7303954 },
    { lat: 48.943845, lng: 20.7295956 },
    { lat: 48.9443783, lng: 20.7288195 },
    { lat: 48.945615, lng: 20.7275993 },
    { lat: 48.9459712, lng: 20.7271667 },
    { lat: 48.9465799, lng: 20.7261069 },
    { lat: 48.9470907, lng: 20.72533 },
    { lat: 48.9477366, lng: 20.724049 },
    { lat: 48.9482082, lng: 20.7232548 },
    { lat: 48.9485852, lng: 20.7223911 },
    { lat: 48.9488143, lng: 20.7220905 },
    { lat: 48.9491817, lng: 20.7217468 },
    { lat: 48.9504007, lng: 20.7209512 },
    { lat: 48.9512913, lng: 20.720222 },
    { lat: 48.9536894, lng: 20.7192151 },
    { lat: 48.9547082, lng: 20.7185463 },
    { lat: 48.9566144, lng: 20.7175053 },
    { lat: 48.9570095, lng: 20.7172183 },
    { lat: 48.958735, lng: 20.7157187 },
    { lat: 48.9601525, lng: 20.7137881 },
    { lat: 48.9604064, lng: 20.7135514 },
    { lat: 48.9606009, lng: 20.713447 },
    { lat: 48.9609207, lng: 20.713451 },
    { lat: 48.9610629, lng: 20.7133648 },
    { lat: 48.9613456, lng: 20.713466 },
    { lat: 48.961835, lng: 20.7140307 },
    { lat: 48.9619664, lng: 20.7147494 },
    { lat: 48.9621237, lng: 20.7147075 },
    { lat: 48.9621618, lng: 20.714233 },
    { lat: 48.9623642, lng: 20.7137475 },
    { lat: 48.9628461, lng: 20.7131811 },
    { lat: 48.9634476, lng: 20.7128393 },
    { lat: 48.9639901, lng: 20.7121397 },
    { lat: 48.9646782, lng: 20.7115066 },
    { lat: 48.9655271, lng: 20.7103391 },
    { lat: 48.9656965, lng: 20.7101347 },
    { lat: 48.9663943, lng: 20.7092927 },
    { lat: 48.9671404, lng: 20.7083924 },
    { lat: 48.9678179, lng: 20.7075749 },
    { lat: 48.968063, lng: 20.7073489 },
    { lat: 48.9681274, lng: 20.7072895 },
    { lat: 48.9684561, lng: 20.7070832 },
    { lat: 48.9689048, lng: 20.7068016 },
    { lat: 48.9691235, lng: 20.7065276 },
    { lat: 48.9692768, lng: 20.7063355 },
    { lat: 48.9693791, lng: 20.7064546 },
    { lat: 48.9695811, lng: 20.7066901 },
    { lat: 48.9696937, lng: 20.7068212 },
    { lat: 48.9697812, lng: 20.7066955 },
    { lat: 48.9700652, lng: 20.7062876 },
    { lat: 48.9704382, lng: 20.7057518 },
    { lat: 48.9709866, lng: 20.7049641 },
    { lat: 48.9714491, lng: 20.7042997 },
    { lat: 48.9719347, lng: 20.7037049 },
    { lat: 48.972189, lng: 20.703067 },
    { lat: 48.9724307, lng: 20.7024607 },
    { lat: 48.9725146, lng: 20.7020353 },
    { lat: 48.9724697, lng: 20.7017699 },
    { lat: 48.9723456, lng: 20.7010371 },
    { lat: 48.9723039, lng: 20.7007909 },
    { lat: 48.9723062, lng: 20.7004102 },
    { lat: 48.9724506, lng: 20.6990586 },
    { lat: 48.9725323, lng: 20.6988382 },
    { lat: 48.9727612, lng: 20.6982207 },
    { lat: 48.9728019, lng: 20.698111 },
    { lat: 48.9730345, lng: 20.6975934 },
    { lat: 48.9731567, lng: 20.6973215 },
    { lat: 48.9733253, lng: 20.6971045 },
    { lat: 48.9738169, lng: 20.6964714 },
    { lat: 48.9742915, lng: 20.6958603 },
    { lat: 48.9745076, lng: 20.695582 },
    { lat: 48.9746354, lng: 20.6953483 },
    { lat: 48.9745878, lng: 20.6952121 },
    { lat: 48.9748323, lng: 20.6948709 },
    { lat: 48.9751563, lng: 20.6945799 },
    { lat: 48.975404, lng: 20.69447 },
    { lat: 48.975602, lng: 20.694355 },
    { lat: 48.975847, lng: 20.694002 },
    { lat: 48.976218, lng: 20.69331 },
    { lat: 48.976381, lng: 20.692875 },
    { lat: 48.976643, lng: 20.691877 },
    { lat: 48.97674, lng: 20.691688 },
    { lat: 48.976806, lng: 20.691569 },
    { lat: 48.976895, lng: 20.691405 },
    { lat: 48.977065, lng: 20.690891 },
    { lat: 48.977161, lng: 20.690559 },
    { lat: 48.977332, lng: 20.690053 },
    { lat: 48.977568, lng: 20.689102 },
    { lat: 48.976763, lng: 20.688403 },
    { lat: 48.976061, lng: 20.687585 },
    { lat: 48.976051, lng: 20.68756 },
    { lat: 48.97564, lng: 20.68699 },
    { lat: 48.974842, lng: 20.685664 },
    { lat: 48.974443, lng: 20.684612 },
    { lat: 48.974247, lng: 20.683787 },
    { lat: 48.974166, lng: 20.683329 },
    { lat: 48.974178, lng: 20.683008 },
    { lat: 48.973589, lng: 20.67996 },
    { lat: 48.97312, lng: 20.678286 },
    { lat: 48.972244, lng: 20.678816 },
    { lat: 48.971854, lng: 20.678465 },
    { lat: 48.971639, lng: 20.678438 },
    { lat: 48.970308, lng: 20.678807 },
    { lat: 48.96967, lng: 20.679105 },
    { lat: 48.968329, lng: 20.679822 },
    { lat: 48.965593, lng: 20.682069 },
  ],
  Poráč: [
    { lat: 48.8783493, lng: 20.7584476 },
    { lat: 48.8783514, lng: 20.7583527 },
    { lat: 48.8806073, lng: 20.7580916 },
    { lat: 48.8815815, lng: 20.7582658 },
    { lat: 48.8821715, lng: 20.7579163 },
    { lat: 48.8835114, lng: 20.7559039 },
    { lat: 48.8852252, lng: 20.7556618 },
    { lat: 48.8861904, lng: 20.7553835 },
    { lat: 48.8868372, lng: 20.7554046 },
    { lat: 48.8869974, lng: 20.7551899 },
    { lat: 48.8880837, lng: 20.7545534 },
    { lat: 48.8883553, lng: 20.7537553 },
    { lat: 48.89054, lng: 20.7525975 },
    { lat: 48.8908949, lng: 20.7525738 },
    { lat: 48.8915625, lng: 20.7522526 },
    { lat: 48.8925948, lng: 20.752543 },
    { lat: 48.8932102, lng: 20.7512544 },
    { lat: 48.8934866, lng: 20.7503051 },
    { lat: 48.8934203, lng: 20.7496078 },
    { lat: 48.8945323, lng: 20.7428865 },
    { lat: 48.8950906, lng: 20.7425031 },
    { lat: 48.8948305, lng: 20.7410578 },
    { lat: 48.8945191, lng: 20.7398609 },
    { lat: 48.8945122, lng: 20.7381317 },
    { lat: 48.8947373, lng: 20.737585 },
    { lat: 48.8950237, lng: 20.7363561 },
    { lat: 48.8952512, lng: 20.7362908 },
    { lat: 48.8954641, lng: 20.7361422 },
    { lat: 48.8956352, lng: 20.7358496 },
    { lat: 48.8956305, lng: 20.7355877 },
    { lat: 48.8955473, lng: 20.7353358 },
    { lat: 48.8956777, lng: 20.7348222 },
    { lat: 48.8958667, lng: 20.7346609 },
    { lat: 48.8961953, lng: 20.733665 },
    { lat: 48.8965912, lng: 20.733147 },
    { lat: 48.8969861, lng: 20.7327593 },
    { lat: 48.8971351, lng: 20.7327035 },
    { lat: 48.8974938, lng: 20.7323742 },
    { lat: 48.8984782, lng: 20.7309858 },
    { lat: 48.8990772, lng: 20.7305139 },
    { lat: 48.8999403, lng: 20.7304122 },
    { lat: 48.9004607, lng: 20.728665 },
    { lat: 48.9006918, lng: 20.72842 },
    { lat: 48.901299, lng: 20.7278619 },
    { lat: 48.9025222, lng: 20.7274639 },
    { lat: 48.9027936, lng: 20.7272461 },
    { lat: 48.9032147, lng: 20.7270265 },
    { lat: 48.9043312, lng: 20.7248205 },
    { lat: 48.9046884, lng: 20.7244154 },
    { lat: 48.9049078, lng: 20.7240354 },
    { lat: 48.9051079, lng: 20.7237042 },
    { lat: 48.9041995, lng: 20.7230092 },
    { lat: 48.9037743, lng: 20.722794 },
    { lat: 48.9032795, lng: 20.7220181 },
    { lat: 48.9024439, lng: 20.7216883 },
    { lat: 48.9031046, lng: 20.7198436 },
    { lat: 48.9026637, lng: 20.7187717 },
    { lat: 48.9024408, lng: 20.7180968 },
    { lat: 48.90245, lng: 20.7167402 },
    { lat: 48.9019417, lng: 20.7164428 },
    { lat: 48.9015473, lng: 20.7163959 },
    { lat: 48.9010303, lng: 20.7142471 },
    { lat: 48.9006952, lng: 20.7137697 },
    { lat: 48.9003003, lng: 20.7135504 },
    { lat: 48.9000259, lng: 20.7132755 },
    { lat: 48.8998229, lng: 20.7127528 },
    { lat: 48.8994901, lng: 20.7109259 },
    { lat: 48.8991165, lng: 20.7102973 },
    { lat: 48.898609, lng: 20.7099563 },
    { lat: 48.8985845, lng: 20.7091064 },
    { lat: 48.8984767, lng: 20.7087352 },
    { lat: 48.8984832, lng: 20.7084738 },
    { lat: 48.8983928, lng: 20.7082397 },
    { lat: 48.8982017, lng: 20.7070109 },
    { lat: 48.8980886, lng: 20.7067408 },
    { lat: 48.8979189, lng: 20.7065339 },
    { lat: 48.8977662, lng: 20.7059417 },
    { lat: 48.8977847, lng: 20.7054196 },
    { lat: 48.8981109, lng: 20.7049657 },
    { lat: 48.8981454, lng: 20.7047289 },
    { lat: 48.898885, lng: 20.7032776 },
    { lat: 48.8987141, lng: 20.7029052 },
    { lat: 48.8982987, lng: 20.7024605 },
    { lat: 48.8978865, lng: 20.7017289 },
    { lat: 48.897713, lng: 20.7015985 },
    { lat: 48.8976968, lng: 20.7009862 },
    { lat: 48.8972398, lng: 20.6992133 },
    { lat: 48.8971286, lng: 20.6990911 },
    { lat: 48.8967095, lng: 20.6991139 },
    { lat: 48.8961598, lng: 20.6981583 },
    { lat: 48.8958933, lng: 20.6979479 },
    { lat: 48.8956379, lng: 20.6974414 },
    { lat: 48.8957256, lng: 20.6969684 },
    { lat: 48.8955329, lng: 20.6968379 },
    { lat: 48.8953687, lng: 20.6965533 },
    { lat: 48.8947774, lng: 20.696298 },
    { lat: 48.8945121, lng: 20.695957 },
    { lat: 48.8942406, lng: 20.6958243 },
    { lat: 48.8938557, lng: 20.6950717 },
    { lat: 48.8938329, lng: 20.6948753 },
    { lat: 48.8938863, lng: 20.6940048 },
    { lat: 48.8941463, lng: 20.6934314 },
    { lat: 48.8942773, lng: 20.6930032 },
    { lat: 48.8945124, lng: 20.6917689 },
    { lat: 48.8954728, lng: 20.6902891 },
    { lat: 48.8961768, lng: 20.6890685 },
    { lat: 48.894561, lng: 20.6879218 },
    { lat: 48.8944968, lng: 20.6870444 },
    { lat: 48.8946757, lng: 20.6852423 },
    { lat: 48.8950729, lng: 20.684415 },
    { lat: 48.8953205, lng: 20.6829357 },
    { lat: 48.8953198, lng: 20.6825983 },
    { lat: 48.89532, lng: 20.6825715 },
    { lat: 48.8861488, lng: 20.6850121 },
    { lat: 48.886157, lng: 20.6852676 },
    { lat: 48.8861208, lng: 20.6853306 },
    { lat: 48.8859596, lng: 20.6852881 },
    { lat: 48.8859119, lng: 20.6854401 },
    { lat: 48.8860433, lng: 20.6856192 },
    { lat: 48.8860397, lng: 20.6857691 },
    { lat: 48.8858546, lng: 20.6859413 },
    { lat: 48.8855927, lng: 20.6854513 },
    { lat: 48.8852024, lng: 20.6853642 },
    { lat: 48.885154, lng: 20.6855267 },
    { lat: 48.8847357, lng: 20.6860082 },
    { lat: 48.8846315, lng: 20.6871249 },
    { lat: 48.8847362, lng: 20.6875988 },
    { lat: 48.8847173, lng: 20.6879552 },
    { lat: 48.8847856, lng: 20.6889168 },
    { lat: 48.8847047, lng: 20.6893331 },
    { lat: 48.8849114, lng: 20.6894152 },
    { lat: 48.8848307, lng: 20.6894594 },
    { lat: 48.8848548, lng: 20.6895686 },
    { lat: 48.8849783, lng: 20.6896161 },
    { lat: 48.8850885, lng: 20.6901862 },
    { lat: 48.8851074, lng: 20.6908671 },
    { lat: 48.885024, lng: 20.6911208 },
    { lat: 48.8852606, lng: 20.6911275 },
    { lat: 48.8852466, lng: 20.691365 },
    { lat: 48.8851097, lng: 20.6913756 },
    { lat: 48.8850381, lng: 20.6918947 },
    { lat: 48.8851197, lng: 20.6920973 },
    { lat: 48.8852626, lng: 20.6922548 },
    { lat: 48.8852144, lng: 20.6924841 },
    { lat: 48.8852883, lng: 20.692566 },
    { lat: 48.8852444, lng: 20.6927825 },
    { lat: 48.885265, lng: 20.6929503 },
    { lat: 48.8851654, lng: 20.6931867 },
    { lat: 48.8850546, lng: 20.6931432 },
    { lat: 48.8849825, lng: 20.6933301 },
    { lat: 48.8843908, lng: 20.692938 },
    { lat: 48.8842671, lng: 20.6929356 },
    { lat: 48.8842962, lng: 20.6930902 },
    { lat: 48.8842623, lng: 20.693279 },
    { lat: 48.8841699, lng: 20.6933721 },
    { lat: 48.8841992, lng: 20.6934684 },
    { lat: 48.8841626, lng: 20.6935469 },
    { lat: 48.8840049, lng: 20.6936088 },
    { lat: 48.8839118, lng: 20.693776 },
    { lat: 48.8840258, lng: 20.6940352 },
    { lat: 48.8839275, lng: 20.6939571 },
    { lat: 48.883762, lng: 20.6940526 },
    { lat: 48.8837423, lng: 20.6943207 },
    { lat: 48.883657, lng: 20.694332 },
    { lat: 48.8836722, lng: 20.6944398 },
    { lat: 48.8835716, lng: 20.6946827 },
    { lat: 48.8834714, lng: 20.6945249 },
    { lat: 48.883305, lng: 20.6946527 },
    { lat: 48.8830746, lng: 20.6946966 },
    { lat: 48.8829153, lng: 20.6951911 },
    { lat: 48.8830926, lng: 20.6952989 },
    { lat: 48.8836339, lng: 20.6952896 },
    { lat: 48.8837095, lng: 20.6953603 },
    { lat: 48.884018, lng: 20.6961856 },
    { lat: 48.8841941, lng: 20.6961619 },
    { lat: 48.8843278, lng: 20.6967905 },
    { lat: 48.8842802, lng: 20.6971897 },
    { lat: 48.8843428, lng: 20.6973366 },
    { lat: 48.8843337, lng: 20.6978413 },
    { lat: 48.8843785, lng: 20.6978952 },
    { lat: 48.8843194, lng: 20.6981046 },
    { lat: 48.8842592, lng: 20.6981139 },
    { lat: 48.8842188, lng: 20.6983132 },
    { lat: 48.8841003, lng: 20.6984288 },
    { lat: 48.8838588, lng: 20.6985709 },
    { lat: 48.8838614, lng: 20.6981994 },
    { lat: 48.8835991, lng: 20.6979812 },
    { lat: 48.8834972, lng: 20.6980604 },
    { lat: 48.883301, lng: 20.6980123 },
    { lat: 48.8832054, lng: 20.6979888 },
    { lat: 48.8831773, lng: 20.6981206 },
    { lat: 48.8830879, lng: 20.6981347 },
    { lat: 48.8830259, lng: 20.6980364 },
    { lat: 48.8828021, lng: 20.6981091 },
    { lat: 48.8827857, lng: 20.6982181 },
    { lat: 48.8828625, lng: 20.6990767 },
    { lat: 48.8828553, lng: 20.7001085 },
    { lat: 48.8829314, lng: 20.7001584 },
    { lat: 48.8828938, lng: 20.70056 },
    { lat: 48.882958, lng: 20.7009385 },
    { lat: 48.8831547, lng: 20.701215 },
    { lat: 48.8833829, lng: 20.7012636 },
    { lat: 48.8835097, lng: 20.701221 },
    { lat: 48.8836335, lng: 20.7012856 },
    { lat: 48.884027, lng: 20.7018103 },
    { lat: 48.8837396, lng: 20.701861 },
    { lat: 48.88309, lng: 20.7022075 },
    { lat: 48.8828784, lng: 20.7024008 },
    { lat: 48.8828822, lng: 20.7025386 },
    { lat: 48.8826229, lng: 20.7026574 },
    { lat: 48.882525, lng: 20.7028174 },
    { lat: 48.8820115, lng: 20.7029389 },
    { lat: 48.881873, lng: 20.703442 },
    { lat: 48.8817783, lng: 20.7033728 },
    { lat: 48.8816909, lng: 20.7037327 },
    { lat: 48.8816111, lng: 20.7042149 },
    { lat: 48.8815055, lng: 20.7055519 },
    { lat: 48.8815365, lng: 20.706356 },
    { lat: 48.8815936, lng: 20.7066607 },
    { lat: 48.881769, lng: 20.7071635 },
    { lat: 48.8821318, lng: 20.7073617 },
    { lat: 48.882192, lng: 20.7074756 },
    { lat: 48.8821865, lng: 20.7076069 },
    { lat: 48.8819533, lng: 20.7087387 },
    { lat: 48.881637, lng: 20.7098208 },
    { lat: 48.8815062, lng: 20.710795 },
    { lat: 48.8815468, lng: 20.7112721 },
    { lat: 48.8818721, lng: 20.7121558 },
    { lat: 48.8818741, lng: 20.7125322 },
    { lat: 48.8815172, lng: 20.7125006 },
    { lat: 48.8810247, lng: 20.7127414 },
    { lat: 48.8811635, lng: 20.7130609 },
    { lat: 48.8812791, lng: 20.7142597 },
    { lat: 48.8812425, lng: 20.7144369 },
    { lat: 48.8809337, lng: 20.7145483 },
    { lat: 48.8810128, lng: 20.7155419 },
    { lat: 48.8809889, lng: 20.716071 },
    { lat: 48.8809247, lng: 20.7164194 },
    { lat: 48.8808858, lng: 20.7166309 },
    { lat: 48.88081, lng: 20.7174147 },
    { lat: 48.8807923, lng: 20.7179587 },
    { lat: 48.8803063, lng: 20.7174314 },
    { lat: 48.880029, lng: 20.7172988 },
    { lat: 48.8791739, lng: 20.71689 },
    { lat: 48.87916, lng: 20.7169769 },
    { lat: 48.8790892, lng: 20.7169491 },
    { lat: 48.8787104, lng: 20.7176284 },
    { lat: 48.8776735, lng: 20.7186102 },
    { lat: 48.8773116, lng: 20.7187903 },
    { lat: 48.8766824, lng: 20.7189149 },
    { lat: 48.8760307, lng: 20.7188221 },
    { lat: 48.8759325, lng: 20.7189753 },
    { lat: 48.8751312, lng: 20.7186032 },
    { lat: 48.8749591, lng: 20.7186054 },
    { lat: 48.8743289, lng: 20.7187701 },
    { lat: 48.874277, lng: 20.7188623 },
    { lat: 48.873868, lng: 20.7184057 },
    { lat: 48.8735613, lng: 20.7179168 },
    { lat: 48.8732013, lng: 20.7171934 },
    { lat: 48.8731101, lng: 20.7169087 },
    { lat: 48.8724599, lng: 20.7157751 },
    { lat: 48.8721379, lng: 20.715271 },
    { lat: 48.8717164, lng: 20.7148416 },
    { lat: 48.8711555, lng: 20.7138185 },
    { lat: 48.8708484, lng: 20.7133815 },
    { lat: 48.8705646, lng: 20.7131558 },
    { lat: 48.8697621, lng: 20.7122708 },
    { lat: 48.8691308, lng: 20.7117144 },
    { lat: 48.8688095, lng: 20.7115414 },
    { lat: 48.8684665, lng: 20.7114547 },
    { lat: 48.8679333, lng: 20.711053 },
    { lat: 48.8676272, lng: 20.7107369 },
    { lat: 48.8672969, lng: 20.7101602 },
    { lat: 48.8666789, lng: 20.7097752 },
    { lat: 48.8659195, lng: 20.7086801 },
    { lat: 48.8654038, lng: 20.7082674 },
    { lat: 48.8650223, lng: 20.7077343 },
    { lat: 48.8643182, lng: 20.7065172 },
    { lat: 48.8640649, lng: 20.7058485 },
    { lat: 48.8638448, lng: 20.7055812 },
    { lat: 48.8630874, lng: 20.7050199 },
    { lat: 48.8625847, lng: 20.7049086 },
    { lat: 48.8621854, lng: 20.7043127 },
    { lat: 48.8616799, lng: 20.7039633 },
    { lat: 48.8613548, lng: 20.7036234 },
    { lat: 48.8611054, lng: 20.7032244 },
    { lat: 48.8610007, lng: 20.7028811 },
    { lat: 48.8610065, lng: 20.7020633 },
    { lat: 48.8609283, lng: 20.7010683 },
    { lat: 48.8606442, lng: 20.6996685 },
    { lat: 48.8604061, lng: 20.6994621 },
    { lat: 48.8598979, lng: 20.6986917 },
    { lat: 48.8596926, lng: 20.698173 },
    { lat: 48.8594517, lng: 20.6970011 },
    { lat: 48.8594351, lng: 20.6963163 },
    { lat: 48.8592834, lng: 20.6950606 },
    { lat: 48.8592963, lng: 20.6944761 },
    { lat: 48.8594403, lng: 20.6941618 },
    { lat: 48.8597549, lng: 20.6939523 },
    { lat: 48.8602959, lng: 20.6940023 },
    { lat: 48.8605378, lng: 20.6939321 },
    { lat: 48.8606459, lng: 20.6937756 },
    { lat: 48.8603709, lng: 20.6935175 },
    { lat: 48.8602004, lng: 20.6928396 },
    { lat: 48.8599553, lng: 20.6925344 },
    { lat: 48.8597353, lng: 20.6924845 },
    { lat: 48.8592209, lng: 20.6921452 },
    { lat: 48.8590853, lng: 20.6917812 },
    { lat: 48.858876, lng: 20.69158 },
    { lat: 48.858252, lng: 20.691948 },
    { lat: 48.858042, lng: 20.692309 },
    { lat: 48.857592, lng: 20.693086 },
    { lat: 48.857098, lng: 20.693908 },
    { lat: 48.857075, lng: 20.693949 },
    { lat: 48.856615, lng: 20.694714 },
    { lat: 48.856685, lng: 20.695273 },
    { lat: 48.855882, lng: 20.696915 },
    { lat: 48.856124, lng: 20.698009 },
    { lat: 48.856036, lng: 20.69866 },
    { lat: 48.856013, lng: 20.698848 },
    { lat: 48.855959, lng: 20.699315 },
    { lat: 48.855939, lng: 20.69953 },
    { lat: 48.855902, lng: 20.699938 },
    { lat: 48.855932, lng: 20.700229 },
    { lat: 48.855966, lng: 20.700559 },
    { lat: 48.855982, lng: 20.700882 },
    { lat: 48.856051, lng: 20.702155 },
    { lat: 48.856057, lng: 20.702819 },
    { lat: 48.856127, lng: 20.703633 },
    { lat: 48.856114, lng: 20.704319 },
    { lat: 48.856055, lng: 20.704822 },
    { lat: 48.856013, lng: 20.705174 },
    { lat: 48.856105, lng: 20.706173 },
    { lat: 48.856148, lng: 20.70652 },
    { lat: 48.856146, lng: 20.70694 },
    { lat: 48.856291, lng: 20.708242 },
    { lat: 48.856299, lng: 20.708997 },
    { lat: 48.856261, lng: 20.7094 },
    { lat: 48.85617, lng: 20.7104 },
    { lat: 48.856062, lng: 20.710624 },
    { lat: 48.855798, lng: 20.710949 },
    { lat: 48.855526, lng: 20.711294 },
    { lat: 48.855394, lng: 20.711562 },
    { lat: 48.855059, lng: 20.712242 },
    { lat: 48.854508, lng: 20.713334 },
    { lat: 48.854147, lng: 20.713874 },
    { lat: 48.853972, lng: 20.714142 },
    { lat: 48.853621, lng: 20.714685 },
    { lat: 48.853333, lng: 20.715127 },
    { lat: 48.852729, lng: 20.715947 },
    { lat: 48.852532, lng: 20.716206 },
    { lat: 48.85219, lng: 20.716708 },
    { lat: 48.851537, lng: 20.717652 },
    { lat: 48.851502, lng: 20.717733 },
    { lat: 48.851288, lng: 20.71819 },
    { lat: 48.851073, lng: 20.718656 },
    { lat: 48.85106, lng: 20.718681 },
    { lat: 48.850894, lng: 20.718915 },
    { lat: 48.850239, lng: 20.719841 },
    { lat: 48.850159, lng: 20.719956 },
    { lat: 48.849968, lng: 20.720226 },
    { lat: 48.849621, lng: 20.720648 },
    { lat: 48.84929, lng: 20.721049 },
    { lat: 48.850785, lng: 20.7234253 },
    { lat: 48.8509506, lng: 20.7238126 },
    { lat: 48.8512016, lng: 20.7255244 },
    { lat: 48.8516142, lng: 20.726338 },
    { lat: 48.8525084, lng: 20.7276877 },
    { lat: 48.8525725, lng: 20.7281007 },
    { lat: 48.8531112, lng: 20.7290042 },
    { lat: 48.8542762, lng: 20.7319642 },
    { lat: 48.8546881, lng: 20.7333253 },
    { lat: 48.8554817, lng: 20.735305 },
    { lat: 48.8557859, lng: 20.7370652 },
    { lat: 48.855756, lng: 20.7377815 },
    { lat: 48.855606, lng: 20.7383757 },
    { lat: 48.8557656, lng: 20.738909 },
    { lat: 48.855796, lng: 20.7397108 },
    { lat: 48.8559335, lng: 20.7404451 },
    { lat: 48.8560379, lng: 20.7407573 },
    { lat: 48.8561021, lng: 20.7414002 },
    { lat: 48.8559916, lng: 20.7422264 },
    { lat: 48.8558832, lng: 20.7426909 },
    { lat: 48.8559077, lng: 20.7438361 },
    { lat: 48.855966, lng: 20.7454279 },
    { lat: 48.8562492, lng: 20.7470391 },
    { lat: 48.8562721, lng: 20.7480779 },
    { lat: 48.8560042, lng: 20.7499659 },
    { lat: 48.856044, lng: 20.7501987 },
    { lat: 48.8563138, lng: 20.7509261 },
    { lat: 48.8565791, lng: 20.7514281 },
    { lat: 48.8580745, lng: 20.75342 },
    { lat: 48.8589435, lng: 20.7535812 },
    { lat: 48.8632318, lng: 20.7537618 },
    { lat: 48.8647978, lng: 20.7537852 },
    { lat: 48.8663212, lng: 20.753808 },
    { lat: 48.8675148, lng: 20.7538789 },
    { lat: 48.8677713, lng: 20.7539694 },
    { lat: 48.8686101, lng: 20.753941 },
    { lat: 48.8699806, lng: 20.7540599 },
    { lat: 48.8717028, lng: 20.7542093 },
    { lat: 48.8726637, lng: 20.7542926 },
    { lat: 48.8738177, lng: 20.7543328 },
    { lat: 48.8750824, lng: 20.755242 },
    { lat: 48.8752121, lng: 20.756402 },
    { lat: 48.8766965, lng: 20.7583492 },
    { lat: 48.8783401, lng: 20.7584473 },
    { lat: 48.8783493, lng: 20.7584476 },
  ],
  Slovinky: [
    { lat: 48.8953498, lng: 20.8312209 },
    { lat: 48.8952743, lng: 20.8308312 },
    { lat: 48.8951741, lng: 20.8303394 },
    { lat: 48.8943402, lng: 20.8283177 },
    { lat: 48.8937791, lng: 20.8286978 },
    { lat: 48.8930567, lng: 20.8288989 },
    { lat: 48.8925404, lng: 20.8287481 },
    { lat: 48.8922873, lng: 20.828462 },
    { lat: 48.8918505, lng: 20.8272532 },
    { lat: 48.8915001, lng: 20.8257725 },
    { lat: 48.8916059, lng: 20.8253799 },
    { lat: 48.8920601, lng: 20.8246166 },
    { lat: 48.8920848, lng: 20.8241596 },
    { lat: 48.8920075, lng: 20.8229571 },
    { lat: 48.8920538, lng: 20.8221195 },
    { lat: 48.8917272, lng: 20.8199298 },
    { lat: 48.8915245, lng: 20.8193077 },
    { lat: 48.8914179, lng: 20.8187009 },
    { lat: 48.8912719, lng: 20.8185447 },
    { lat: 48.8901519, lng: 20.8155564 },
    { lat: 48.8896734, lng: 20.8144144 },
    { lat: 48.8896597, lng: 20.8132687 },
    { lat: 48.889829, lng: 20.8121764 },
    { lat: 48.8901052, lng: 20.8110848 },
    { lat: 48.8902737, lng: 20.8106804 },
    { lat: 48.8917157, lng: 20.8091565 },
    { lat: 48.8917262, lng: 20.8084506 },
    { lat: 48.8923057, lng: 20.8079586 },
    { lat: 48.8927089, lng: 20.8067302 },
    { lat: 48.8926858, lng: 20.8046011 },
    { lat: 48.892593, lng: 20.8037166 },
    { lat: 48.892606, lng: 20.8023366 },
    { lat: 48.8923792, lng: 20.8017075 },
    { lat: 48.8919505, lng: 20.8012238 },
    { lat: 48.8917334, lng: 20.8006441 },
    { lat: 48.8917014, lng: 20.7991446 },
    { lat: 48.8909094, lng: 20.7978227 },
    { lat: 48.8920495, lng: 20.7928315 },
    { lat: 48.8927903, lng: 20.7914793 },
    { lat: 48.8925964, lng: 20.7890476 },
    { lat: 48.892238, lng: 20.7886466 },
    { lat: 48.8920116, lng: 20.7882888 },
    { lat: 48.8917738, lng: 20.7876643 },
    { lat: 48.8914994, lng: 20.7872477 },
    { lat: 48.8914668, lng: 20.7856356 },
    { lat: 48.89261, lng: 20.7814257 },
    { lat: 48.8929784, lng: 20.7784358 },
    { lat: 48.8925784, lng: 20.7768116 },
    { lat: 48.8924451, lng: 20.7760264 },
    { lat: 48.8923972, lng: 20.7728549 },
    { lat: 48.8905286, lng: 20.7750864 },
    { lat: 48.8902857, lng: 20.7753764 },
    { lat: 48.8900479, lng: 20.7754642 },
    { lat: 48.8892869, lng: 20.776043 },
    { lat: 48.8888832, lng: 20.7765333 },
    { lat: 48.88852, lng: 20.776678 },
    { lat: 48.8881513, lng: 20.7770757 },
    { lat: 48.888123, lng: 20.7772368 },
    { lat: 48.8878993, lng: 20.777532 },
    { lat: 48.8878123, lng: 20.7778166 },
    { lat: 48.8878135, lng: 20.7779785 },
    { lat: 48.8877274, lng: 20.7781228 },
    { lat: 48.8876031, lng: 20.7789802 },
    { lat: 48.8874774, lng: 20.7792525 },
    { lat: 48.8870918, lng: 20.7805833 },
    { lat: 48.886602, lng: 20.7809063 },
    { lat: 48.8857497, lng: 20.7812551 },
    { lat: 48.8841562, lng: 20.7816262 },
    { lat: 48.8838173, lng: 20.78204 },
    { lat: 48.8837038, lng: 20.7820803 },
    { lat: 48.8835449, lng: 20.7814256 },
    { lat: 48.8835714, lng: 20.7811592 },
    { lat: 48.8837537, lng: 20.7806019 },
    { lat: 48.8837815, lng: 20.7801415 },
    { lat: 48.8837348, lng: 20.7790354 },
    { lat: 48.8836292, lng: 20.7784548 },
    { lat: 48.8833504, lng: 20.7777264 },
    { lat: 48.8831691, lng: 20.7771169 },
    { lat: 48.8830962, lng: 20.7766681 },
    { lat: 48.8828843, lng: 20.776208 },
    { lat: 48.8822819, lng: 20.7753995 },
    { lat: 48.8820379, lng: 20.7752478 },
    { lat: 48.8817667, lng: 20.7748675 },
    { lat: 48.8815637, lng: 20.7743352 },
    { lat: 48.8812601, lng: 20.773836 },
    { lat: 48.8809966, lng: 20.7735138 },
    { lat: 48.8802996, lng: 20.7721847 },
    { lat: 48.8802627, lng: 20.7718736 },
    { lat: 48.8800673, lng: 20.771433 },
    { lat: 48.8798719, lng: 20.7708021 },
    { lat: 48.8796772, lng: 20.7704154 },
    { lat: 48.8796023, lng: 20.7687602 },
    { lat: 48.8795757, lng: 20.7685803 },
    { lat: 48.8793764, lng: 20.7684801 },
    { lat: 48.8793134, lng: 20.7682906 },
    { lat: 48.8792306, lng: 20.7678205 },
    { lat: 48.8792592, lng: 20.7676741 },
    { lat: 48.8792335, lng: 20.7674233 },
    { lat: 48.8793583, lng: 20.7669987 },
    { lat: 48.8792908, lng: 20.7667767 },
    { lat: 48.8791831, lng: 20.7659846 },
    { lat: 48.8790055, lng: 20.7654145 },
    { lat: 48.8789989, lng: 20.7653864 },
    { lat: 48.8789914, lng: 20.7651403 },
    { lat: 48.8789057, lng: 20.7647795 },
    { lat: 48.8788135, lng: 20.7646528 },
    { lat: 48.8787362, lng: 20.764345 },
    { lat: 48.8787135, lng: 20.7639885 },
    { lat: 48.8786131, lng: 20.7636275 },
    { lat: 48.8783935, lng: 20.7631102 },
    { lat: 48.8781996, lng: 20.7628122 },
    { lat: 48.8780671, lng: 20.762114 },
    { lat: 48.8781271, lng: 20.7619368 },
    { lat: 48.8780411, lng: 20.7614412 },
    { lat: 48.8780811, lng: 20.7609398 },
    { lat: 48.8780758, lng: 20.7597669 },
    { lat: 48.8781779, lng: 20.7593366 },
    { lat: 48.8781623, lng: 20.7591211 },
    { lat: 48.878296, lng: 20.7586955 },
    { lat: 48.8783493, lng: 20.7584476 },
    { lat: 48.8783401, lng: 20.7584473 },
    { lat: 48.8766965, lng: 20.7583492 },
    { lat: 48.8752121, lng: 20.756402 },
    { lat: 48.8750824, lng: 20.755242 },
    { lat: 48.8738177, lng: 20.7543328 },
    { lat: 48.8726637, lng: 20.7542926 },
    { lat: 48.8717028, lng: 20.7542093 },
    { lat: 48.8699806, lng: 20.7540599 },
    { lat: 48.8686101, lng: 20.753941 },
    { lat: 48.8677713, lng: 20.7539694 },
    { lat: 48.8675148, lng: 20.7538789 },
    { lat: 48.8663212, lng: 20.753808 },
    { lat: 48.8647978, lng: 20.7537852 },
    { lat: 48.8632318, lng: 20.7537618 },
    { lat: 48.8589435, lng: 20.7535812 },
    { lat: 48.8580745, lng: 20.75342 },
    { lat: 48.8565791, lng: 20.7514281 },
    { lat: 48.8563138, lng: 20.7509261 },
    { lat: 48.856044, lng: 20.7501987 },
    { lat: 48.8560042, lng: 20.7499659 },
    { lat: 48.8562721, lng: 20.7480779 },
    { lat: 48.8562492, lng: 20.7470391 },
    { lat: 48.855966, lng: 20.7454279 },
    { lat: 48.8559077, lng: 20.7438361 },
    { lat: 48.8558832, lng: 20.7426909 },
    { lat: 48.8559916, lng: 20.7422264 },
    { lat: 48.8561021, lng: 20.7414002 },
    { lat: 48.8560379, lng: 20.7407573 },
    { lat: 48.8559335, lng: 20.7404451 },
    { lat: 48.855796, lng: 20.7397108 },
    { lat: 48.8557656, lng: 20.738909 },
    { lat: 48.855606, lng: 20.7383757 },
    { lat: 48.855756, lng: 20.7377815 },
    { lat: 48.8557859, lng: 20.7370652 },
    { lat: 48.8554817, lng: 20.735305 },
    { lat: 48.8546881, lng: 20.7333253 },
    { lat: 48.8542762, lng: 20.7319642 },
    { lat: 48.8531112, lng: 20.7290042 },
    { lat: 48.8525725, lng: 20.7281007 },
    { lat: 48.8525084, lng: 20.7276877 },
    { lat: 48.8516142, lng: 20.726338 },
    { lat: 48.8512016, lng: 20.7255244 },
    { lat: 48.8509506, lng: 20.7238126 },
    { lat: 48.850785, lng: 20.7234253 },
    { lat: 48.84929, lng: 20.721049 },
    { lat: 48.848924, lng: 20.72143 },
    { lat: 48.84842, lng: 20.721946 },
    { lat: 48.848138, lng: 20.722222 },
    { lat: 48.846957, lng: 20.723296 },
    { lat: 48.846767, lng: 20.723533 },
    { lat: 48.846102, lng: 20.724368 },
    { lat: 48.845731, lng: 20.72552 },
    { lat: 48.845676, lng: 20.725691 },
    { lat: 48.845582, lng: 20.725986 },
    { lat: 48.845436, lng: 20.726443 },
    { lat: 48.845423, lng: 20.726706 },
    { lat: 48.845411, lng: 20.726816 },
    { lat: 48.845401, lng: 20.726935 },
    { lat: 48.845389, lng: 20.727047 },
    { lat: 48.84538, lng: 20.72716 },
    { lat: 48.845367, lng: 20.727267 },
    { lat: 48.84535, lng: 20.727499 },
    { lat: 48.845317, lng: 20.727876 },
    { lat: 48.845255, lng: 20.728586 },
    { lat: 48.84521, lng: 20.729065 },
    { lat: 48.845112, lng: 20.729565 },
    { lat: 48.84509, lng: 20.729673 },
    { lat: 48.845005, lng: 20.730505 },
    { lat: 48.844909, lng: 20.731371 },
    { lat: 48.844871, lng: 20.731762 },
    { lat: 48.844864, lng: 20.732193 },
    { lat: 48.844855, lng: 20.732556 },
    { lat: 48.844742, lng: 20.733037 },
    { lat: 48.844677, lng: 20.733314 },
    { lat: 48.844564, lng: 20.733805 },
    { lat: 48.844522, lng: 20.734241 },
    { lat: 48.844455, lng: 20.735011 },
    { lat: 48.8444, lng: 20.735638 },
    { lat: 48.844331, lng: 20.735785 },
    { lat: 48.844193, lng: 20.736076 },
    { lat: 48.844085, lng: 20.736314 },
    { lat: 48.84404, lng: 20.736408 },
    { lat: 48.843997, lng: 20.736503 },
    { lat: 48.84395, lng: 20.736598 },
    { lat: 48.843907, lng: 20.736692 },
    { lat: 48.843862, lng: 20.73679 },
    { lat: 48.843818, lng: 20.736879 },
    { lat: 48.843551, lng: 20.737147 },
    { lat: 48.843063, lng: 20.73765 },
    { lat: 48.842898, lng: 20.737824 },
    { lat: 48.842496, lng: 20.738292 },
    { lat: 48.842003, lng: 20.738857 },
    { lat: 48.841426, lng: 20.739522 },
    { lat: 48.840582, lng: 20.740478 },
    { lat: 48.840541, lng: 20.740515 },
    { lat: 48.840126, lng: 20.74092 },
    { lat: 48.83952, lng: 20.741504 },
    { lat: 48.839439, lng: 20.741583 },
    { lat: 48.839378, lng: 20.741642 },
    { lat: 48.839318, lng: 20.741705 },
    { lat: 48.839279, lng: 20.74174 },
    { lat: 48.839254, lng: 20.741856 },
    { lat: 48.839232, lng: 20.741972 },
    { lat: 48.839226, lng: 20.742092 },
    { lat: 48.839282, lng: 20.742675 },
    { lat: 48.839239, lng: 20.742874 },
    { lat: 48.839003, lng: 20.743408 },
    { lat: 48.838919, lng: 20.743642 },
    { lat: 48.838874, lng: 20.743746 },
    { lat: 48.838853, lng: 20.743795 },
    { lat: 48.838785, lng: 20.744108 },
    { lat: 48.838772, lng: 20.744172 },
    { lat: 48.838733, lng: 20.744535 },
    { lat: 48.838742, lng: 20.744826 },
    { lat: 48.838708, lng: 20.745254 },
    { lat: 48.838656, lng: 20.745473 },
    { lat: 48.838545, lng: 20.745676 },
    { lat: 48.838397, lng: 20.745789 },
    { lat: 48.838207, lng: 20.745924 },
    { lat: 48.838004, lng: 20.746064 },
    { lat: 48.837918, lng: 20.746156 },
    { lat: 48.837759, lng: 20.746265 },
    { lat: 48.837485, lng: 20.746418 },
    { lat: 48.837396, lng: 20.746452 },
    { lat: 48.837321, lng: 20.746487 },
    { lat: 48.837174, lng: 20.746551 },
    { lat: 48.837065, lng: 20.746483 },
    { lat: 48.836985, lng: 20.746436 },
    { lat: 48.836636, lng: 20.746235 },
    { lat: 48.836422, lng: 20.746168 },
    { lat: 48.836122, lng: 20.746073 },
    { lat: 48.836069, lng: 20.746057 },
    { lat: 48.835871, lng: 20.746104 },
    { lat: 48.835699, lng: 20.746145 },
    { lat: 48.835296, lng: 20.746572 },
    { lat: 48.834839, lng: 20.747108 },
    { lat: 48.834788, lng: 20.747192 },
    { lat: 48.834573, lng: 20.74764 },
    { lat: 48.834435, lng: 20.747928 },
    { lat: 48.83436, lng: 20.748159 },
    { lat: 48.834266, lng: 20.748445 },
    { lat: 48.833994, lng: 20.749335 },
    { lat: 48.833838, lng: 20.749786 },
    { lat: 48.833664, lng: 20.75016 },
    { lat: 48.833449, lng: 20.750454 },
    { lat: 48.833389, lng: 20.750537 },
    { lat: 48.833328, lng: 20.750624 },
    { lat: 48.833256, lng: 20.750717 },
    { lat: 48.83336, lng: 20.751384 },
    { lat: 48.833516, lng: 20.752414 },
    { lat: 48.833585, lng: 20.75319 },
    { lat: 48.833631, lng: 20.753765 },
    { lat: 48.83379, lng: 20.754375 },
    { lat: 48.8339041, lng: 20.7548163 },
    { lat: 48.833941, lng: 20.754959 },
    { lat: 48.834291, lng: 20.756272 },
    { lat: 48.83445, lng: 20.756551 },
    { lat: 48.834773, lng: 20.757138 },
    { lat: 48.834694, lng: 20.757522 },
    { lat: 48.834613, lng: 20.757941 },
    { lat: 48.834491, lng: 20.758572 },
    { lat: 48.834332, lng: 20.75942 },
    { lat: 48.834266, lng: 20.759759 },
    { lat: 48.834055, lng: 20.760839 },
    { lat: 48.834026, lng: 20.761956 },
    { lat: 48.834009, lng: 20.761978 },
    { lat: 48.834151, lng: 20.762345 },
    { lat: 48.834165, lng: 20.762571 },
    { lat: 48.834082, lng: 20.763002 },
    { lat: 48.834057, lng: 20.763803 },
    { lat: 48.834068, lng: 20.763805 },
    { lat: 48.834078, lng: 20.763808 },
    { lat: 48.834194, lng: 20.764174 },
    { lat: 48.834221, lng: 20.764358 },
    { lat: 48.834439, lng: 20.765877 },
    { lat: 48.834556, lng: 20.766657 },
    { lat: 48.834596, lng: 20.767419 },
    { lat: 48.834626, lng: 20.767882 },
    { lat: 48.83485, lng: 20.768131 },
    { lat: 48.835111, lng: 20.76853 },
    { lat: 48.835163, lng: 20.768778 },
    { lat: 48.835318, lng: 20.769607 },
    { lat: 48.835505, lng: 20.769975 },
    { lat: 48.835738, lng: 20.770234 },
    { lat: 48.835932, lng: 20.770605 },
    { lat: 48.83607, lng: 20.771072 },
    { lat: 48.83629, lng: 20.771475 },
    { lat: 48.836382, lng: 20.771681 },
    { lat: 48.836432, lng: 20.771901 },
    { lat: 48.836741, lng: 20.772329 },
    { lat: 48.837109, lng: 20.77291 },
    { lat: 48.837274, lng: 20.773371 },
    { lat: 48.83742, lng: 20.774106 },
    { lat: 48.837559, lng: 20.774621 },
    { lat: 48.83777, lng: 20.775234 },
    { lat: 48.837961, lng: 20.775705 },
    { lat: 48.838198, lng: 20.776193 },
    { lat: 48.838472, lng: 20.776753 },
    { lat: 48.838642, lng: 20.776941 },
    { lat: 48.838702, lng: 20.777014 },
    { lat: 48.838829, lng: 20.777179 },
    { lat: 48.838917, lng: 20.777406 },
    { lat: 48.839054, lng: 20.777519 },
    { lat: 48.83913, lng: 20.777572 },
    { lat: 48.839561, lng: 20.777798 },
    { lat: 48.839761, lng: 20.778175 },
    { lat: 48.839924, lng: 20.778666 },
    { lat: 48.840013, lng: 20.778923 },
    { lat: 48.84012, lng: 20.779493 },
    { lat: 48.840216, lng: 20.77996 },
    { lat: 48.84022, lng: 20.780164 },
    { lat: 48.840247, lng: 20.780532 },
    { lat: 48.840187, lng: 20.781303 },
    { lat: 48.840047, lng: 20.782365 },
    { lat: 48.839914, lng: 20.782748 },
    { lat: 48.839849, lng: 20.783053 },
    { lat: 48.839838, lng: 20.783299 },
    { lat: 48.839914, lng: 20.784153 },
    { lat: 48.839912, lng: 20.784674 },
    { lat: 48.839908, lng: 20.785265 },
    { lat: 48.839893, lng: 20.785733 },
    { lat: 48.83999, lng: 20.786408 },
    { lat: 48.840138, lng: 20.787047 },
    { lat: 48.840326, lng: 20.787901 },
    { lat: 48.840776, lng: 20.788476 },
    { lat: 48.841003, lng: 20.788856 },
    { lat: 48.841138, lng: 20.789068 },
    { lat: 48.841299, lng: 20.789795 },
    { lat: 48.841453, lng: 20.790495 },
    { lat: 48.841515, lng: 20.79084 },
    { lat: 48.841787, lng: 20.791559 },
    { lat: 48.842059, lng: 20.79226 },
    { lat: 48.84221, lng: 20.792379 },
    { lat: 48.84229, lng: 20.792517 },
    { lat: 48.842447, lng: 20.793019 },
    { lat: 48.842533, lng: 20.793404 },
    { lat: 48.842809, lng: 20.794119 },
    { lat: 48.84288, lng: 20.794531 },
    { lat: 48.842975, lng: 20.795073 },
    { lat: 48.843078, lng: 20.795819 },
    { lat: 48.843192, lng: 20.796118 },
    { lat: 48.843223, lng: 20.796219 },
    { lat: 48.843417, lng: 20.796692 },
    { lat: 48.843637, lng: 20.797256 },
    { lat: 48.843762, lng: 20.797927 },
    { lat: 48.843865, lng: 20.798507 },
    { lat: 48.843798, lng: 20.799212 },
    { lat: 48.843752, lng: 20.799957 },
    { lat: 48.843741, lng: 20.800565 },
    { lat: 48.843765, lng: 20.800905 },
    { lat: 48.843795, lng: 20.801272 },
    { lat: 48.843897, lng: 20.802048 },
    { lat: 48.843954, lng: 20.802661 },
    { lat: 48.844017, lng: 20.803322 },
    { lat: 48.843985, lng: 20.805064 },
    { lat: 48.844049, lng: 20.806411 },
    { lat: 48.844063, lng: 20.806751 },
    { lat: 48.844066, lng: 20.80695 },
    { lat: 48.844076, lng: 20.807542 },
    { lat: 48.844108, lng: 20.808031 },
    { lat: 48.844126, lng: 20.808271 },
    { lat: 48.844247, lng: 20.808793 },
    { lat: 48.844476, lng: 20.80952 },
    { lat: 48.844305, lng: 20.810317 },
    { lat: 48.844299, lng: 20.810365 },
    { lat: 48.844285, lng: 20.810364 },
    { lat: 48.84433, lng: 20.810932 },
    { lat: 48.844388, lng: 20.811211 },
    { lat: 48.844582, lng: 20.811637 },
    { lat: 48.844712, lng: 20.812077 },
    { lat: 48.844758, lng: 20.812295 },
    { lat: 48.844814, lng: 20.812598 },
    { lat: 48.844975, lng: 20.813248 },
    { lat: 48.845107, lng: 20.813614 },
    { lat: 48.845167, lng: 20.813769 },
    { lat: 48.845303, lng: 20.814149 },
    { lat: 48.845411, lng: 20.814565 },
    { lat: 48.845485, lng: 20.81501 },
    { lat: 48.845509, lng: 20.815607 },
    { lat: 48.845571, lng: 20.815944 },
    { lat: 48.845672, lng: 20.81648 },
    { lat: 48.845733, lng: 20.816801 },
    { lat: 48.845815, lng: 20.817093 },
    { lat: 48.845915, lng: 20.817456 },
    { lat: 48.84596, lng: 20.817616 },
    { lat: 48.846156, lng: 20.818135 },
    { lat: 48.846279, lng: 20.818441 },
    { lat: 48.846348, lng: 20.818994 },
    { lat: 48.846374, lng: 20.818995 },
    { lat: 48.846561, lng: 20.819335 },
    { lat: 48.846859, lng: 20.819883 },
    { lat: 48.84714, lng: 20.820569 },
    { lat: 48.847169, lng: 20.820738 },
    { lat: 48.847243, lng: 20.821203 },
    { lat: 48.847263, lng: 20.821347 },
    { lat: 48.847339, lng: 20.821699 },
    { lat: 48.847446, lng: 20.82218 },
    { lat: 48.847601, lng: 20.822879 },
    { lat: 48.847722, lng: 20.823451 },
    { lat: 48.847876, lng: 20.824205 },
    { lat: 48.847932, lng: 20.824488 },
    { lat: 48.848055, lng: 20.825095 },
    { lat: 48.84824, lng: 20.826129 },
    { lat: 48.848375, lng: 20.826869 },
    { lat: 48.848419, lng: 20.827114 },
    { lat: 48.848586, lng: 20.828054 },
    { lat: 48.848692, lng: 20.828678 },
    { lat: 48.848793, lng: 20.829269 },
    { lat: 48.848759, lng: 20.829591 },
    { lat: 48.848653, lng: 20.830486 },
    { lat: 48.848565, lng: 20.831224 },
    { lat: 48.848475, lng: 20.831825 },
    { lat: 48.848373, lng: 20.832516 },
    { lat: 48.848273, lng: 20.83318 },
    { lat: 48.848283, lng: 20.833959 },
    { lat: 48.848293, lng: 20.834606 },
    { lat: 48.848307, lng: 20.835295 },
    { lat: 48.848274, lng: 20.835307 },
    { lat: 48.848331, lng: 20.83555 },
    { lat: 48.848378, lng: 20.835839 },
    { lat: 48.848378, lng: 20.836218 },
    { lat: 48.848438, lng: 20.836527 },
    { lat: 48.848535, lng: 20.836859 },
    { lat: 48.848617, lng: 20.837315 },
    { lat: 48.848752, lng: 20.837772 },
    { lat: 48.848878, lng: 20.838371 },
    { lat: 48.848958, lng: 20.838577 },
    { lat: 48.848991, lng: 20.838736 },
    { lat: 48.849023, lng: 20.838723 },
    { lat: 48.848979, lng: 20.839723 },
    { lat: 48.84895, lng: 20.840452 },
    { lat: 48.848929, lng: 20.840948 },
    { lat: 48.848922, lng: 20.841395 },
    { lat: 48.848913, lng: 20.841928 },
    { lat: 48.849163, lng: 20.842462 },
    { lat: 48.849464, lng: 20.84315 },
    { lat: 48.849761, lng: 20.843811 },
    { lat: 48.850046, lng: 20.844401 },
    { lat: 48.850156, lng: 20.844469 },
    { lat: 48.850422, lng: 20.844624 },
    { lat: 48.850747, lng: 20.84503 },
    { lat: 48.851086, lng: 20.845434 },
    { lat: 48.85151, lng: 20.846066 },
    { lat: 48.851921, lng: 20.846687 },
    { lat: 48.852033, lng: 20.848503 },
    { lat: 48.852191, lng: 20.849433 },
    { lat: 48.852338, lng: 20.8503 },
    { lat: 48.852404, lng: 20.850415 },
    { lat: 48.852648, lng: 20.850893 },
    { lat: 48.852917, lng: 20.851245 },
    { lat: 48.853303, lng: 20.851889 },
    { lat: 48.853421, lng: 20.852551 },
    { lat: 48.853523, lng: 20.853089 },
    { lat: 48.853724, lng: 20.853598 },
    { lat: 48.854018, lng: 20.854409 },
    { lat: 48.854076, lng: 20.855258 },
    { lat: 48.854131, lng: 20.855995 },
    { lat: 48.854147, lng: 20.856473 },
    { lat: 48.854342, lng: 20.857457 },
    { lat: 48.85437, lng: 20.858179 },
    { lat: 48.854584, lng: 20.859042 },
    { lat: 48.854717, lng: 20.859257 },
    { lat: 48.855034, lng: 20.859717 },
    { lat: 48.855773, lng: 20.862021 },
    { lat: 48.855828, lng: 20.862247 },
    { lat: 48.855882, lng: 20.862509 },
    { lat: 48.855887, lng: 20.862536 },
    { lat: 48.856004, lng: 20.863098 },
    { lat: 48.855997, lng: 20.863233 },
    { lat: 48.8561, lng: 20.863708 },
    { lat: 48.856172, lng: 20.864532 },
    { lat: 48.856204, lng: 20.864989 },
    { lat: 48.856253, lng: 20.865493 },
    { lat: 48.85642, lng: 20.866189 },
    { lat: 48.8565, lng: 20.866553 },
    { lat: 48.856646, lng: 20.867139 },
    { lat: 48.856769, lng: 20.867488 },
    { lat: 48.856959, lng: 20.868029 },
    { lat: 48.857154, lng: 20.868509 },
    { lat: 48.857504, lng: 20.869449 },
    { lat: 48.857749, lng: 20.870158 },
    { lat: 48.8579, lng: 20.87058 },
    { lat: 48.858234, lng: 20.871415 },
    { lat: 48.858407, lng: 20.872038 },
    { lat: 48.858526, lng: 20.872401 },
    { lat: 48.858786, lng: 20.87323 },
    { lat: 48.85897, lng: 20.873813 },
    { lat: 48.859068, lng: 20.874111 },
    { lat: 48.859254, lng: 20.874348 },
    { lat: 48.859343, lng: 20.874721 },
    { lat: 48.859396, lng: 20.875014 },
    { lat: 48.859569, lng: 20.875401 },
    { lat: 48.859585, lng: 20.875718 },
    { lat: 48.859662, lng: 20.875702 },
    { lat: 48.860326, lng: 20.875563 },
    { lat: 48.860581, lng: 20.875506 },
    { lat: 48.861121, lng: 20.87559 },
    { lat: 48.861717, lng: 20.875689 },
    { lat: 48.862055, lng: 20.875746 },
    { lat: 48.862416, lng: 20.875971 },
    { lat: 48.862481, lng: 20.876022 },
    { lat: 48.863059, lng: 20.876501 },
    { lat: 48.863588, lng: 20.876803 },
    { lat: 48.863909, lng: 20.877173 },
    { lat: 48.863961, lng: 20.877209 },
    { lat: 48.86487, lng: 20.877616 },
    { lat: 48.865222, lng: 20.877718 },
    { lat: 48.865598, lng: 20.877821 },
    { lat: 48.866041, lng: 20.877949 },
    { lat: 48.866773, lng: 20.87832 },
    { lat: 48.867277, lng: 20.878961 },
    { lat: 48.867567, lng: 20.879328 },
    { lat: 48.867693, lng: 20.879487 },
    { lat: 48.868074, lng: 20.879818 },
    { lat: 48.868419, lng: 20.880125 },
    { lat: 48.868478, lng: 20.880161 },
    { lat: 48.868599, lng: 20.880239 },
    { lat: 48.86903, lng: 20.880503 },
    { lat: 48.869091, lng: 20.88054 },
    { lat: 48.869373, lng: 20.880556 },
    { lat: 48.86975, lng: 20.880519 },
    { lat: 48.870378, lng: 20.880466 },
    { lat: 48.870649, lng: 20.880986 },
    { lat: 48.870782, lng: 20.88124 },
    { lat: 48.870907, lng: 20.881479 },
    { lat: 48.871235, lng: 20.881784 },
    { lat: 48.871399, lng: 20.881935 },
    { lat: 48.871993, lng: 20.882486 },
    { lat: 48.872427, lng: 20.882454 },
    { lat: 48.872549, lng: 20.882539 },
    { lat: 48.872984, lng: 20.882902 },
    { lat: 48.873485, lng: 20.883271 },
    { lat: 48.873856, lng: 20.883533 },
    { lat: 48.87413, lng: 20.883741 },
    { lat: 48.874545, lng: 20.884049 },
    { lat: 48.874788, lng: 20.884226 },
    { lat: 48.874936, lng: 20.884339 },
    { lat: 48.8747192, lng: 20.8833879 },
    { lat: 48.8748062, lng: 20.8826026 },
    { lat: 48.8752393, lng: 20.8803113 },
    { lat: 48.8770497, lng: 20.8771214 },
    { lat: 48.8782349, lng: 20.8740779 },
    { lat: 48.8789961, lng: 20.8718457 },
    { lat: 48.8800035, lng: 20.8703226 },
    { lat: 48.8844938, lng: 20.8652555 },
    { lat: 48.8866472, lng: 20.8604837 },
    { lat: 48.8871607, lng: 20.8594358 },
    { lat: 48.8875717, lng: 20.8597089 },
    { lat: 48.8875914, lng: 20.8596583 },
    { lat: 48.8877447, lng: 20.859927 },
    { lat: 48.8881399, lng: 20.8602257 },
    { lat: 48.8882774, lng: 20.8602324 },
    { lat: 48.8884777, lng: 20.8599981 },
    { lat: 48.8885198, lng: 20.859817 },
    { lat: 48.8883342, lng: 20.8596582 },
    { lat: 48.8883461, lng: 20.8595775 },
    { lat: 48.8875634, lng: 20.8588379 },
    { lat: 48.8879716, lng: 20.8583336 },
    { lat: 48.8880234, lng: 20.8583388 },
    { lat: 48.8890398, lng: 20.859124 },
    { lat: 48.8892478, lng: 20.8585568 },
    { lat: 48.8885366, lng: 20.8578398 },
    { lat: 48.8883152, lng: 20.8572295 },
    { lat: 48.888034, lng: 20.8569033 },
    { lat: 48.8877996, lng: 20.8567615 },
    { lat: 48.8873808, lng: 20.8563392 },
    { lat: 48.8871691, lng: 20.8556115 },
    { lat: 48.8878352, lng: 20.8546477 },
    { lat: 48.8883096, lng: 20.8540709 },
    { lat: 48.8886961, lng: 20.8538548 },
    { lat: 48.8887907, lng: 20.8536617 },
    { lat: 48.8890689, lng: 20.8534992 },
    { lat: 48.889343, lng: 20.8531567 },
    { lat: 48.8896827, lng: 20.8530209 },
    { lat: 48.8898254, lng: 20.8528465 },
    { lat: 48.8902004, lng: 20.8526538 },
    { lat: 48.8909816, lng: 20.8527847 },
    { lat: 48.8912884, lng: 20.8526725 },
    { lat: 48.8917367, lng: 20.852484 },
    { lat: 48.8924066, lng: 20.8519944 },
    { lat: 48.8928121, lng: 20.8515411 },
    { lat: 48.893381, lng: 20.8504066 },
    { lat: 48.8934994, lng: 20.8498546 },
    { lat: 48.8936952, lng: 20.8493605 },
    { lat: 48.8937498, lng: 20.8487266 },
    { lat: 48.8939896, lng: 20.8476098 },
    { lat: 48.893917, lng: 20.847232 },
    { lat: 48.8936983, lng: 20.8468608 },
    { lat: 48.8935421, lng: 20.8460822 },
    { lat: 48.8935468, lng: 20.8446803 },
    { lat: 48.8930951, lng: 20.8442062 },
    { lat: 48.8927246, lng: 20.8435154 },
    { lat: 48.892711, lng: 20.8433533 },
    { lat: 48.8930525, lng: 20.8428355 },
    { lat: 48.8934107, lng: 20.8426824 },
    { lat: 48.8937525, lng: 20.8423466 },
    { lat: 48.8943105, lng: 20.8415842 },
    { lat: 48.8944204, lng: 20.841534 },
    { lat: 48.8948079, lng: 20.8409571 },
    { lat: 48.8948634, lng: 20.8405746 },
    { lat: 48.8950129, lng: 20.8401396 },
    { lat: 48.8951593, lng: 20.839913 },
    { lat: 48.8955039, lng: 20.8387721 },
    { lat: 48.896415, lng: 20.8364952 },
    { lat: 48.8964117, lng: 20.8364464 },
    { lat: 48.8967193, lng: 20.8345768 },
    { lat: 48.8953498, lng: 20.8312209 },
  ],
  Danišovce: [
    { lat: 48.9470209, lng: 20.6310591 },
    { lat: 48.947165, lng: 20.6309916 },
    { lat: 48.9475421, lng: 20.6314279 },
    { lat: 48.9476819, lng: 20.63171 },
    { lat: 48.9478523, lng: 20.6314121 },
    { lat: 48.9481724, lng: 20.631798 },
    { lat: 48.9482847, lng: 20.6313539 },
    { lat: 48.9482843, lng: 20.6317794 },
    { lat: 48.9488552, lng: 20.6323146 },
    { lat: 48.9498024, lng: 20.6314799 },
    { lat: 48.9504707, lng: 20.6322162 },
    { lat: 48.9509086, lng: 20.6317481 },
    { lat: 48.9512243, lng: 20.6323582 },
    { lat: 48.95224, lng: 20.632311 },
    { lat: 48.952733, lng: 20.632103 },
    { lat: 48.952533, lng: 20.631183 },
    { lat: 48.952473, lng: 20.630982 },
    { lat: 48.954078, lng: 20.630236 },
    { lat: 48.955002, lng: 20.629737 },
    { lat: 48.95517, lng: 20.629611 },
    { lat: 48.955209, lng: 20.629583 },
    { lat: 48.955269, lng: 20.629537 },
    { lat: 48.955407, lng: 20.629433 },
    { lat: 48.955479, lng: 20.629379 },
    { lat: 48.955513, lng: 20.629353 },
    { lat: 48.955583, lng: 20.6293 },
    { lat: 48.955933, lng: 20.628922 },
    { lat: 48.955959, lng: 20.628907 },
    { lat: 48.955998, lng: 20.628875 },
    { lat: 48.95628, lng: 20.628703 },
    { lat: 48.956552, lng: 20.628539 },
    { lat: 48.956617, lng: 20.628499 },
    { lat: 48.959699, lng: 20.627151 },
    { lat: 48.963295, lng: 20.625565 },
    { lat: 48.964469, lng: 20.6251 },
    { lat: 48.964887, lng: 20.621155 },
    { lat: 48.964905, lng: 20.620989 },
    { lat: 48.964906, lng: 20.620984 },
    { lat: 48.964923, lng: 20.62095 },
    { lat: 48.964945, lng: 20.620909 },
    { lat: 48.964958, lng: 20.620883 },
    { lat: 48.964964, lng: 20.620794 },
    { lat: 48.965001, lng: 20.620268 },
    { lat: 48.964999, lng: 20.619611 },
    { lat: 48.965, lng: 20.619547 },
    { lat: 48.965001, lng: 20.619507 },
    { lat: 48.965055, lng: 20.619511 },
    { lat: 48.965078, lng: 20.619513 },
    { lat: 48.965081, lng: 20.619521 },
    { lat: 48.965086, lng: 20.619539 },
    { lat: 48.965141, lng: 20.619753 },
    { lat: 48.965408, lng: 20.619947 },
    { lat: 48.965467, lng: 20.619991 },
    { lat: 48.965601, lng: 20.620397 },
    { lat: 48.965748, lng: 20.620277 },
    { lat: 48.965958, lng: 20.620502 },
    { lat: 48.966259, lng: 20.620405 },
    { lat: 48.966471, lng: 20.620324 },
    { lat: 48.966676, lng: 20.620658 },
    { lat: 48.966911, lng: 20.620471 },
    { lat: 48.966874, lng: 20.620124 },
    { lat: 48.966927, lng: 20.620117 },
    { lat: 48.96756, lng: 20.620041 },
    { lat: 48.967638, lng: 20.61998 },
    { lat: 48.967867, lng: 20.619801 },
    { lat: 48.968186, lng: 20.619531 },
    { lat: 48.968355, lng: 20.619704 },
    { lat: 48.968472, lng: 20.619547 },
    { lat: 48.969099, lng: 20.619407 },
    { lat: 48.969312, lng: 20.61905 },
    { lat: 48.969451, lng: 20.619057 },
    { lat: 48.969785, lng: 20.619061 },
    { lat: 48.96995, lng: 20.618659 },
    { lat: 48.970335, lng: 20.618612 },
    { lat: 48.970702, lng: 20.618311 },
    { lat: 48.970922, lng: 20.618207 },
    { lat: 48.971307, lng: 20.617653 },
    { lat: 48.97141, lng: 20.617616 },
    { lat: 48.973432, lng: 20.616904 },
    { lat: 48.973948, lng: 20.617207 },
    { lat: 48.974325, lng: 20.617088 },
    { lat: 48.974834, lng: 20.617187 },
    { lat: 48.974963, lng: 20.617163 },
    { lat: 48.97567, lng: 20.617146 },
    { lat: 48.975702, lng: 20.616956 },
    { lat: 48.977712, lng: 20.617211 },
    { lat: 48.977918, lng: 20.617094 },
    { lat: 48.978352, lng: 20.616943 },
    { lat: 48.978637, lng: 20.617256 },
    { lat: 48.978641, lng: 20.617684 },
    { lat: 48.978798, lng: 20.617906 },
    { lat: 48.978816, lng: 20.617891 },
    { lat: 48.97865, lng: 20.617615 },
    { lat: 48.978664, lng: 20.617547 },
    { lat: 48.978677, lng: 20.617495 },
    { lat: 48.978691, lng: 20.617427 },
    { lat: 48.978744, lng: 20.61719 },
    { lat: 48.97877, lng: 20.617075 },
    { lat: 48.978807, lng: 20.616907 },
    { lat: 48.978582, lng: 20.616838 },
    { lat: 48.978565, lng: 20.616714 },
    { lat: 48.978559, lng: 20.616671 },
    { lat: 48.978545, lng: 20.616598 },
    { lat: 48.978438, lng: 20.616361 },
    { lat: 48.978115, lng: 20.615647 },
    { lat: 48.977944, lng: 20.614664 },
    { lat: 48.977818, lng: 20.613964 },
    { lat: 48.977667, lng: 20.613079 },
    { lat: 48.97743, lng: 20.611694 },
    { lat: 48.977241, lng: 20.610512 },
    { lat: 48.977207, lng: 20.610304 },
    { lat: 48.977173, lng: 20.61009 },
    { lat: 48.977138, lng: 20.609872 },
    { lat: 48.977027, lng: 20.609172 },
    { lat: 48.97694, lng: 20.608594 },
    { lat: 48.976859, lng: 20.608081 },
    { lat: 48.976769, lng: 20.60747 },
    { lat: 48.976676, lng: 20.606866 },
    { lat: 48.976595, lng: 20.606378 },
    { lat: 48.976541, lng: 20.605638 },
    { lat: 48.9760431, lng: 20.6059716 },
    { lat: 48.9756056, lng: 20.6061058 },
    { lat: 48.9751174, lng: 20.6065495 },
    { lat: 48.9730572, lng: 20.6095909 },
    { lat: 48.9728455, lng: 20.6097684 },
    { lat: 48.972632, lng: 20.6097838 },
    { lat: 48.9723185, lng: 20.6094263 },
    { lat: 48.9717964, lng: 20.6091594 },
    { lat: 48.9711146, lng: 20.6080559 },
    { lat: 48.9704888, lng: 20.6065374 },
    { lat: 48.9700848, lng: 20.6053666 },
    { lat: 48.9699456, lng: 20.6051579 },
    { lat: 48.9696468, lng: 20.6048053 },
    { lat: 48.9691648, lng: 20.6048371 },
    { lat: 48.96879, lng: 20.6049613 },
    { lat: 48.968468, lng: 20.6051754 },
    { lat: 48.9669772, lng: 20.6064388 },
    { lat: 48.9659203, lng: 20.6071268 },
    { lat: 48.9657362, lng: 20.6073573 },
    { lat: 48.9653926, lng: 20.6074452 },
    { lat: 48.9648106, lng: 20.6073213 },
    { lat: 48.964328, lng: 20.6077981 },
    { lat: 48.9630283, lng: 20.6088364 },
    { lat: 48.9617001, lng: 20.6094314 },
    { lat: 48.9606527, lng: 20.6101035 },
    { lat: 48.9600331, lng: 20.6106183 },
    { lat: 48.9595553, lng: 20.6108833 },
    { lat: 48.9582377, lng: 20.6113361 },
    { lat: 48.9575939, lng: 20.6114337 },
    { lat: 48.9561829, lng: 20.6113641 },
    { lat: 48.9553433, lng: 20.6111873 },
    { lat: 48.9548441, lng: 20.611175 },
    { lat: 48.9543538, lng: 20.6109725 },
    { lat: 48.9539314, lng: 20.6109032 },
    { lat: 48.9530286, lng: 20.6105655 },
    { lat: 48.9524247, lng: 20.6104312 },
    { lat: 48.9507227, lng: 20.6105899 },
    { lat: 48.9505541, lng: 20.6090459 },
    { lat: 48.950378, lng: 20.6085369 },
    { lat: 48.9502629, lng: 20.6076371 },
    { lat: 48.9499743, lng: 20.6075617 },
    { lat: 48.949352, lng: 20.6077246 },
    { lat: 48.9492528, lng: 20.6076261 },
    { lat: 48.9478133, lng: 20.6086906 },
    { lat: 48.9477566, lng: 20.6086806 },
    { lat: 48.9463947, lng: 20.6094297 },
    { lat: 48.9448887, lng: 20.6099699 },
    { lat: 48.9453486, lng: 20.6118762 },
    { lat: 48.9452821, lng: 20.6128634 },
    { lat: 48.9450024, lng: 20.6153617 },
    { lat: 48.9442656, lng: 20.6169608 },
    { lat: 48.943675, lng: 20.6204505 },
    { lat: 48.9440654, lng: 20.6227078 },
    { lat: 48.9441367, lng: 20.622689 },
    { lat: 48.9453211, lng: 20.624773 },
    { lat: 48.9446921, lng: 20.6252786 },
    { lat: 48.9465802, lng: 20.6274007 },
    { lat: 48.9460096, lng: 20.629272 },
    { lat: 48.946025, lng: 20.6295745 },
    { lat: 48.9465768, lng: 20.6299848 },
    { lat: 48.9470209, lng: 20.6310591 },
  ],
};

export default UnitsSpišskáNováVes;
