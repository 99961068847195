const UnitsBrezno = {
  LomnadRimavicou: [
    { lat: 48.619159, lng: 19.672291 },
    { lat: 48.619304, lng: 19.672469 },
    { lat: 48.619342, lng: 19.672516 },
    { lat: 48.619438, lng: 19.672635 },
    { lat: 48.619497, lng: 19.672707 },
    { lat: 48.61985, lng: 19.673142 },
    { lat: 48.620006, lng: 19.673311 },
    { lat: 48.620275, lng: 19.673636 },
    { lat: 48.620535, lng: 19.673937 },
    { lat: 48.620837, lng: 19.674465 },
    { lat: 48.62112, lng: 19.674958 },
    { lat: 48.621308, lng: 19.675398 },
    { lat: 48.621502, lng: 19.675848 },
    { lat: 48.621775, lng: 19.676276 },
    { lat: 48.622027, lng: 19.676647 },
    { lat: 48.622394, lng: 19.677251 },
    { lat: 48.622926, lng: 19.677741 },
    { lat: 48.623154, lng: 19.678065 },
    { lat: 48.623388, lng: 19.678431 },
    { lat: 48.623712, lng: 19.678923 },
    { lat: 48.624049, lng: 19.679327 },
    { lat: 48.624317, lng: 19.679746 },
    { lat: 48.624685, lng: 19.680284 },
    { lat: 48.625044, lng: 19.680827 },
    { lat: 48.625205, lng: 19.68105 },
    { lat: 48.625244, lng: 19.681195 },
    { lat: 48.625229, lng: 19.681406 },
    { lat: 48.625262, lng: 19.681594 },
    { lat: 48.625297, lng: 19.681723 },
    { lat: 48.625361, lng: 19.681973 },
    { lat: 48.625324, lng: 19.682148 },
    { lat: 48.625254, lng: 19.682355 },
    { lat: 48.625278, lng: 19.682469 },
    { lat: 48.625391, lng: 19.682704 },
    { lat: 48.625431, lng: 19.682956 },
    { lat: 48.625485, lng: 19.68301 },
    { lat: 48.625507, lng: 19.683251 },
    { lat: 48.625543, lng: 19.683415 },
    { lat: 48.62567, lng: 19.683456 },
    { lat: 48.625694, lng: 19.683723 },
    { lat: 48.625759, lng: 19.683773 },
    { lat: 48.625847, lng: 19.683964 },
    { lat: 48.625912, lng: 19.684152 },
    { lat: 48.626259, lng: 19.684349 },
    { lat: 48.626417, lng: 19.684374 },
    { lat: 48.626505, lng: 19.684583 },
    { lat: 48.626551, lng: 19.684636 },
    { lat: 48.626596, lng: 19.684758 },
    { lat: 48.62672, lng: 19.684948 },
    { lat: 48.626905, lng: 19.685093 },
    { lat: 48.626877, lng: 19.685213 },
    { lat: 48.626948, lng: 19.685391 },
    { lat: 48.627, lng: 19.685443 },
    { lat: 48.627072, lng: 19.685438 },
    { lat: 48.627117, lng: 19.685549 },
    { lat: 48.627337, lng: 19.68568 },
    { lat: 48.627357, lng: 19.685815 },
    { lat: 48.627334, lng: 19.685962 },
    { lat: 48.62741, lng: 19.686108 },
    { lat: 48.627345, lng: 19.686257 },
    { lat: 48.62737, lng: 19.686406 },
    { lat: 48.627494, lng: 19.686416 },
    { lat: 48.627534, lng: 19.686481 },
    { lat: 48.627533, lng: 19.686876 },
    { lat: 48.627605, lng: 19.68693 },
    { lat: 48.627641, lng: 19.687026 },
    { lat: 48.627725, lng: 19.687101 },
    { lat: 48.627681, lng: 19.687297 },
    { lat: 48.627704, lng: 19.687511 },
    { lat: 48.627675, lng: 19.68775 },
    { lat: 48.627689, lng: 19.687812 },
    { lat: 48.627874, lng: 19.68784 },
    { lat: 48.627938, lng: 19.68791 },
    { lat: 48.627975, lng: 19.687981 },
    { lat: 48.628078, lng: 19.688084 },
    { lat: 48.628142, lng: 19.688244 },
    { lat: 48.628191, lng: 19.688353 },
    { lat: 48.628293, lng: 19.688449 },
    { lat: 48.628379, lng: 19.688455 },
    { lat: 48.628446, lng: 19.688542 },
    { lat: 48.62846, lng: 19.688723 },
    { lat: 48.62865, lng: 19.689136 },
    { lat: 48.628722, lng: 19.689389 },
    { lat: 48.628872, lng: 19.68947 },
    { lat: 48.628941, lng: 19.689558 },
    { lat: 48.62903, lng: 19.689597 },
    { lat: 48.629051, lng: 19.689635 },
    { lat: 48.629057, lng: 19.689765 },
    { lat: 48.629121, lng: 19.689998 },
    { lat: 48.629148, lng: 19.690182 },
    { lat: 48.629202, lng: 19.690385 },
    { lat: 48.629265, lng: 19.690575 },
    { lat: 48.629208, lng: 19.690907 },
    { lat: 48.629237, lng: 19.691014 },
    { lat: 48.629257, lng: 19.691352 },
    { lat: 48.62941, lng: 19.691738 },
    { lat: 48.629433, lng: 19.691947 },
    { lat: 48.629427, lng: 19.692211 },
    { lat: 48.629488, lng: 19.692373 },
    { lat: 48.629575, lng: 19.692601 },
    { lat: 48.629647, lng: 19.692675 },
    { lat: 48.629725, lng: 19.692826 },
    { lat: 48.629777, lng: 19.692874 },
    { lat: 48.629908, lng: 19.69309 },
    { lat: 48.629918, lng: 19.693207 },
    { lat: 48.62999, lng: 19.693328 },
    { lat: 48.630076, lng: 19.693434 },
    { lat: 48.630208, lng: 19.693528 },
    { lat: 48.630286, lng: 19.69369 },
    { lat: 48.630316, lng: 19.693905 },
    { lat: 48.630398, lng: 19.694085 },
    { lat: 48.630421, lng: 19.694222 },
    { lat: 48.630462, lng: 19.694302 },
    { lat: 48.630632, lng: 19.694399 },
    { lat: 48.630704, lng: 19.694518 },
    { lat: 48.630724, lng: 19.69483 },
    { lat: 48.630762, lng: 19.694863 },
    { lat: 48.630937, lng: 19.694954 },
    { lat: 48.631086, lng: 19.695112 },
    { lat: 48.631217, lng: 19.695209 },
    { lat: 48.631234, lng: 19.695219 },
    { lat: 48.631343, lng: 19.695341 },
    { lat: 48.631477, lng: 19.695529 },
    { lat: 48.631495, lng: 19.695659 },
    { lat: 48.631505, lng: 19.695675 },
    { lat: 48.63171, lng: 19.695798 },
    { lat: 48.631788, lng: 19.695759 },
    { lat: 48.631908, lng: 19.695775 },
    { lat: 48.632053, lng: 19.695985 },
    { lat: 48.632071, lng: 19.695988 },
    { lat: 48.632162, lng: 19.695934 },
    { lat: 48.632249, lng: 19.695977 },
    { lat: 48.632425, lng: 19.695961 },
    { lat: 48.632696, lng: 19.695997 },
    { lat: 48.632766, lng: 19.696268 },
    { lat: 48.632816, lng: 19.696326 },
    { lat: 48.632961, lng: 19.696327 },
    { lat: 48.633057, lng: 19.696379 },
    { lat: 48.633243, lng: 19.696638 },
    { lat: 48.633309, lng: 19.696712 },
    { lat: 48.633331, lng: 19.696824 },
    { lat: 48.633371, lng: 19.69686 },
    { lat: 48.633412, lng: 19.697048 },
    { lat: 48.633415, lng: 19.697219 },
    { lat: 48.633424, lng: 19.697246 },
    { lat: 48.633637, lng: 19.69761 },
    { lat: 48.633707, lng: 19.697696 },
    { lat: 48.633824, lng: 19.69802 },
    { lat: 48.633854, lng: 19.698193 },
    { lat: 48.633978, lng: 19.698568 },
    { lat: 48.634139, lng: 19.698666 },
    { lat: 48.634188, lng: 19.698736 },
    { lat: 48.634361, lng: 19.699236 },
    { lat: 48.634505, lng: 19.699557 },
    { lat: 48.63468, lng: 19.699679 },
    { lat: 48.63489, lng: 19.700031 },
    { lat: 48.635035, lng: 19.700433 },
    { lat: 48.635179, lng: 19.700594 },
    { lat: 48.635272, lng: 19.700729 },
    { lat: 48.63541, lng: 19.700824 },
    { lat: 48.635507, lng: 19.701011 },
    { lat: 48.635547, lng: 19.701339 },
    { lat: 48.635567, lng: 19.701374 },
    { lat: 48.635736, lng: 19.701581 },
    { lat: 48.635906, lng: 19.701709 },
    { lat: 48.635993, lng: 19.701853 },
    { lat: 48.63615, lng: 19.702381 },
    { lat: 48.636107, lng: 19.702547 },
    { lat: 48.636098, lng: 19.702757 },
    { lat: 48.636113, lng: 19.702807 },
    { lat: 48.63623, lng: 19.70302 },
    { lat: 48.63632, lng: 19.703325 },
    { lat: 48.636348, lng: 19.703725 },
    { lat: 48.636422, lng: 19.703939 },
    { lat: 48.636475, lng: 19.704139 },
    { lat: 48.636483, lng: 19.704407 },
    { lat: 48.636708, lng: 19.704701 },
    { lat: 48.636787, lng: 19.704902 },
    { lat: 48.636888, lng: 19.705298 },
    { lat: 48.636883, lng: 19.705494 },
    { lat: 48.636919, lng: 19.705671 },
    { lat: 48.63691, lng: 19.705831 },
    { lat: 48.637102, lng: 19.706093 },
    { lat: 48.637124, lng: 19.706326 },
    { lat: 48.637189, lng: 19.706563 },
    { lat: 48.637172, lng: 19.706887 },
    { lat: 48.637044, lng: 19.707138 },
    { lat: 48.636883, lng: 19.707858 },
    { lat: 48.636883, lng: 19.708076 },
    { lat: 48.637037, lng: 19.708273 },
    { lat: 48.63711, lng: 19.70871 },
    { lat: 48.637291, lng: 19.708988 },
    { lat: 48.63746, lng: 19.709055 },
    { lat: 48.637502, lng: 19.709429 },
    { lat: 48.637605, lng: 19.709676 },
    { lat: 48.637588, lng: 19.70983 },
    { lat: 48.637596, lng: 19.710305 },
    { lat: 48.637557, lng: 19.710485 },
    { lat: 48.637551, lng: 19.71135 },
    { lat: 48.637593, lng: 19.711545 },
    { lat: 48.63755, lng: 19.711802 },
    { lat: 48.637568, lng: 19.712624 },
    { lat: 48.637663, lng: 19.712846 },
    { lat: 48.637654, lng: 19.713265 },
    { lat: 48.637892, lng: 19.713606 },
    { lat: 48.6379, lng: 19.713824 },
    { lat: 48.638039, lng: 19.71419 },
    { lat: 48.638146, lng: 19.714362 },
    { lat: 48.63826, lng: 19.714675 },
    { lat: 48.638373, lng: 19.7151 },
    { lat: 48.638682, lng: 19.71544 },
    { lat: 48.63886, lng: 19.715716 },
    { lat: 48.639108, lng: 19.716319 },
    { lat: 48.639267, lng: 19.716631 },
    { lat: 48.63937, lng: 19.717211 },
    { lat: 48.63945, lng: 19.717348 },
    { lat: 48.63967, lng: 19.717486 },
    { lat: 48.639815, lng: 19.717772 },
    { lat: 48.639816, lng: 19.717791 },
    { lat: 48.639824, lng: 19.717978 },
    { lat: 48.639837, lng: 19.718191 },
    { lat: 48.639866, lng: 19.718237 },
    { lat: 48.639915, lng: 19.718312 },
    { lat: 48.639917, lng: 19.718388 },
    { lat: 48.639964, lng: 19.718447 },
    { lat: 48.639993, lng: 19.718487 },
    { lat: 48.640047, lng: 19.718555 },
    { lat: 48.640069, lng: 19.718583 },
    { lat: 48.640111, lng: 19.718636 },
    { lat: 48.64013, lng: 19.71866 },
    { lat: 48.640241, lng: 19.718814 },
    { lat: 48.640278, lng: 19.718894 },
    { lat: 48.640523, lng: 19.719432 },
    { lat: 48.640782, lng: 19.720183 },
    { lat: 48.640851, lng: 19.720427 },
    { lat: 48.640955, lng: 19.720791 },
    { lat: 48.64104, lng: 19.721084 },
    { lat: 48.641104, lng: 19.721272 },
    { lat: 48.641197, lng: 19.72159 },
    { lat: 48.641293, lng: 19.7219 },
    { lat: 48.641461, lng: 19.722477 },
    { lat: 48.641537, lng: 19.722669 },
    { lat: 48.641625, lng: 19.722983 },
    { lat: 48.641777, lng: 19.723598 },
    { lat: 48.642029, lng: 19.724572 },
    { lat: 48.642216, lng: 19.725142 },
    { lat: 48.642344, lng: 19.725595 },
    { lat: 48.642522, lng: 19.726295 },
    { lat: 48.642715, lng: 19.72701 },
    { lat: 48.642927, lng: 19.72779 },
    { lat: 48.643005, lng: 19.728089 },
    { lat: 48.643077, lng: 19.728297 },
    { lat: 48.643269, lng: 19.728821 },
    { lat: 48.643491, lng: 19.72954 },
    { lat: 48.643512, lng: 19.729619 },
    { lat: 48.643617, lng: 19.730037 },
    { lat: 48.643838, lng: 19.730795 },
    { lat: 48.644059, lng: 19.731579 },
    { lat: 48.644404, lng: 19.732639 },
    { lat: 48.644728, lng: 19.733809 },
    { lat: 48.64484, lng: 19.734382 },
    { lat: 48.644841, lng: 19.734386 },
    { lat: 48.644853, lng: 19.73444 },
    { lat: 48.644992, lng: 19.735124 },
    { lat: 48.645077, lng: 19.735388 },
    { lat: 48.645152, lng: 19.735622 },
    { lat: 48.645304, lng: 19.736095 },
    { lat: 48.645719, lng: 19.737302 },
    { lat: 48.645906, lng: 19.737861 },
    { lat: 48.646051, lng: 19.738294 },
    { lat: 48.646248, lng: 19.738994 },
    { lat: 48.646265, lng: 19.739055 },
    { lat: 48.646275, lng: 19.739093 },
    { lat: 48.646583, lng: 19.740203 },
    { lat: 48.647017, lng: 19.741823 },
    { lat: 48.64734, lng: 19.743022 },
    { lat: 48.647401, lng: 19.743255 },
    { lat: 48.647415, lng: 19.743308 },
    { lat: 48.647623, lng: 19.744113 },
    { lat: 48.647634, lng: 19.744151 },
    { lat: 48.64764, lng: 19.744178 },
    { lat: 48.647915, lng: 19.745111 },
    { lat: 48.648176, lng: 19.746055 },
    { lat: 48.648478, lng: 19.747156 },
    { lat: 48.648666, lng: 19.747886 },
    { lat: 48.648777, lng: 19.748368 },
    { lat: 48.648794, lng: 19.748384 },
    { lat: 48.64885, lng: 19.748281 },
    { lat: 48.649314, lng: 19.747482 },
    { lat: 48.649448, lng: 19.747127 },
    { lat: 48.649543, lng: 19.74685 },
    { lat: 48.649588, lng: 19.746448 },
    { lat: 48.649723, lng: 19.745877 },
    { lat: 48.649806, lng: 19.745657 },
    { lat: 48.649923, lng: 19.745495 },
    { lat: 48.650208, lng: 19.744999 },
    { lat: 48.650446, lng: 19.744725 },
    { lat: 48.650491, lng: 19.744598 },
    { lat: 48.650644, lng: 19.744147 },
    { lat: 48.650909, lng: 19.743703 },
    { lat: 48.651158, lng: 19.743417 },
    { lat: 48.651234, lng: 19.743335 },
    { lat: 48.651351, lng: 19.743194 },
    { lat: 48.651493, lng: 19.742936 },
    { lat: 48.651701, lng: 19.742668 },
    { lat: 48.651971, lng: 19.742409 },
    { lat: 48.652149, lng: 19.742226 },
    { lat: 48.652142, lng: 19.742199 },
    { lat: 48.652431, lng: 19.742033 },
    { lat: 48.652511, lng: 19.741987 },
    { lat: 48.652735, lng: 19.741865 },
    { lat: 48.653217, lng: 19.741808 },
    { lat: 48.653234, lng: 19.741804 },
    { lat: 48.653333, lng: 19.741784 },
    { lat: 48.653478, lng: 19.741689 },
    { lat: 48.653775, lng: 19.741469 },
    { lat: 48.654026, lng: 19.741257 },
    { lat: 48.654265, lng: 19.741109 },
    { lat: 48.654406, lng: 19.740915 },
    { lat: 48.654702, lng: 19.740363 },
    { lat: 48.654871, lng: 19.740045 },
    { lat: 48.654894, lng: 19.739771 },
    { lat: 48.654933, lng: 19.739338 },
    { lat: 48.655034, lng: 19.738754 },
    { lat: 48.655076, lng: 19.738509 },
    { lat: 48.655124, lng: 19.738235 },
    { lat: 48.655305, lng: 19.737823 },
    { lat: 48.655369, lng: 19.737714 },
    { lat: 48.655864, lng: 19.736859 },
    { lat: 48.655943, lng: 19.736725 },
    { lat: 48.656565, lng: 19.735656 },
    { lat: 48.656686, lng: 19.735448 },
    { lat: 48.65671, lng: 19.735408 },
    { lat: 48.656765, lng: 19.735312 },
    { lat: 48.656813, lng: 19.735258 },
    { lat: 48.656939, lng: 19.735119 },
    { lat: 48.657618, lng: 19.734367 },
    { lat: 48.658369, lng: 19.733534 },
    { lat: 48.658596, lng: 19.733009 },
    { lat: 48.658914, lng: 19.731572 },
    { lat: 48.659187, lng: 19.730503 },
    { lat: 48.659307, lng: 19.730263 },
    { lat: 48.659672, lng: 19.729531 },
    { lat: 48.660416, lng: 19.728249 },
    { lat: 48.66051, lng: 19.728092 },
    { lat: 48.660511, lng: 19.728014 },
    { lat: 48.6601061, lng: 19.7260156 },
    { lat: 48.6586119, lng: 19.7213218 },
    { lat: 48.6578927, lng: 19.7207825 },
    { lat: 48.6565398, lng: 19.7188521 },
    { lat: 48.6561291, lng: 19.7183319 },
    { lat: 48.6555481, lng: 19.717759 },
    { lat: 48.654686, lng: 19.7161516 },
    { lat: 48.6545138, lng: 19.7155436 },
    { lat: 48.6545812, lng: 19.7132571 },
    { lat: 48.6544179, lng: 19.7120613 },
    { lat: 48.6546679, lng: 19.7098729 },
    { lat: 48.655192, lng: 19.708129 },
    { lat: 48.656776, lng: 19.7041139 },
    { lat: 48.657375, lng: 19.7033487 },
    { lat: 48.6578162, lng: 19.7020951 },
    { lat: 48.6581452, lng: 19.7003697 },
    { lat: 48.6580342, lng: 19.6996004 },
    { lat: 48.6569686, lng: 19.6947654 },
    { lat: 48.6567596, lng: 19.6945047 },
    { lat: 48.6560709, lng: 19.6936341 },
    { lat: 48.6559966, lng: 19.6936106 },
    { lat: 48.6557885, lng: 19.6933163 },
    { lat: 48.6555565, lng: 19.6931168 },
    { lat: 48.6551873, lng: 19.6920623 },
    { lat: 48.6552306, lng: 19.6915141 },
    { lat: 48.6550663, lng: 19.6912175 },
    { lat: 48.6550945, lng: 19.6904559 },
    { lat: 48.6550051, lng: 19.6899454 },
    { lat: 48.6547455, lng: 19.6890737 },
    { lat: 48.6543354, lng: 19.6883417 },
    { lat: 48.6542691, lng: 19.6884233 },
    { lat: 48.6539262, lng: 19.6879809 },
    { lat: 48.6536947, lng: 19.6878198 },
    { lat: 48.6533827, lng: 19.6874417 },
    { lat: 48.6531616, lng: 19.686528 },
    { lat: 48.6529385, lng: 19.6866305 },
    { lat: 48.6528412, lng: 19.6865171 },
    { lat: 48.652026, lng: 19.6862542 },
    { lat: 48.6518177, lng: 19.6846119 },
    { lat: 48.6516291, lng: 19.6841472 },
    { lat: 48.6514571, lng: 19.6833518 },
    { lat: 48.6520322, lng: 19.679701 },
    { lat: 48.6516453, lng: 19.6792899 },
    { lat: 48.6514625, lng: 19.6792839 },
    { lat: 48.6513107, lng: 19.6791629 },
    { lat: 48.6511518, lng: 19.6787825 },
    { lat: 48.650011, lng: 19.678137 },
    { lat: 48.6505265, lng: 19.6775732 },
    { lat: 48.6508086, lng: 19.6771294 },
    { lat: 48.6510951, lng: 19.6763009 },
    { lat: 48.650867, lng: 19.676063 },
    { lat: 48.6507909, lng: 19.6757756 },
    { lat: 48.6505292, lng: 19.6755165 },
    { lat: 48.6505604, lng: 19.6753569 },
    { lat: 48.6499738, lng: 19.674457 },
    { lat: 48.6491196, lng: 19.6752612 },
    { lat: 48.6489873, lng: 19.6744729 },
    { lat: 48.6488867, lng: 19.6735047 },
    { lat: 48.6491743, lng: 19.6716749 },
    { lat: 48.6493247, lng: 19.6710296 },
    { lat: 48.6498995, lng: 19.6691155 },
    { lat: 48.6498594, lng: 19.6691275 },
    { lat: 48.649574, lng: 19.6680159 },
    { lat: 48.6494237, lng: 19.6676756 },
    { lat: 48.6493878, lng: 19.6674794 },
    { lat: 48.6496031, lng: 19.6672058 },
    { lat: 48.6491605, lng: 19.6659518 },
    { lat: 48.6499063, lng: 19.6656983 },
    { lat: 48.6498106, lng: 19.6652957 },
    { lat: 48.6496186, lng: 19.6650349 },
    { lat: 48.6494789, lng: 19.6646575 },
    { lat: 48.6492411, lng: 19.6636245 },
    { lat: 48.6492639, lng: 19.6632259 },
    { lat: 48.6487315, lng: 19.6628106 },
    { lat: 48.6485112, lng: 19.6620734 },
    { lat: 48.6485991, lng: 19.6618688 },
    { lat: 48.6485684, lng: 19.6615088 },
    { lat: 48.648626, lng: 19.6612364 },
    { lat: 48.6486028, lng: 19.6609181 },
    { lat: 48.6480255, lng: 19.6594526 },
    { lat: 48.6476651, lng: 19.6592297 },
    { lat: 48.6475521, lng: 19.6590228 },
    { lat: 48.6474655, lng: 19.6585488 },
    { lat: 48.6475199, lng: 19.6577244 },
    { lat: 48.6473253, lng: 19.6570815 },
    { lat: 48.6472929, lng: 19.6566583 },
    { lat: 48.6474355, lng: 19.6564488 },
    { lat: 48.6478351, lng: 19.6562138 },
    { lat: 48.6478189, lng: 19.6561527 },
    { lat: 48.6473794, lng: 19.6547713 },
    { lat: 48.6471968, lng: 19.6543909 },
    { lat: 48.6469927, lng: 19.6541145 },
    { lat: 48.6468579, lng: 19.6533943 },
    { lat: 48.646657, lng: 19.6531127 },
    { lat: 48.6462499, lng: 19.6528789 },
    { lat: 48.6458445, lng: 19.6525319 },
    { lat: 48.6457517, lng: 19.6522692 },
    { lat: 48.6454353, lng: 19.6507975 },
    { lat: 48.6452797, lng: 19.6508451 },
    { lat: 48.6446556, lng: 19.6504967 },
    { lat: 48.644092, lng: 19.6492877 },
    { lat: 48.6446797, lng: 19.6489258 },
    { lat: 48.6446114, lng: 19.6483085 },
    { lat: 48.6445558, lng: 19.6483224 },
    { lat: 48.6444258, lng: 19.6471845 },
    { lat: 48.644152, lng: 19.6472592 },
    { lat: 48.6441269, lng: 19.6470526 },
    { lat: 48.6441639, lng: 19.6469718 },
    { lat: 48.6436984, lng: 19.6467701 },
    { lat: 48.6437264, lng: 19.645864 },
    { lat: 48.6436399, lng: 19.6453694 },
    { lat: 48.6435194, lng: 19.6450675 },
    { lat: 48.643012, lng: 19.644215 },
    { lat: 48.64297, lng: 19.644281 },
    { lat: 48.642836, lng: 19.644434 },
    { lat: 48.642809, lng: 19.644465 },
    { lat: 48.642681, lng: 19.644613 },
    { lat: 48.642656, lng: 19.644662 },
    { lat: 48.642578, lng: 19.644801 },
    { lat: 48.642525, lng: 19.644935 },
    { lat: 48.642362, lng: 19.645395 },
    { lat: 48.642306, lng: 19.645375 },
    { lat: 48.642269, lng: 19.645573 },
    { lat: 48.642163, lng: 19.64578 },
    { lat: 48.641929, lng: 19.645919 },
    { lat: 48.641807, lng: 19.646038 },
    { lat: 48.641714, lng: 19.646175 },
    { lat: 48.641601, lng: 19.64636 },
    { lat: 48.641572, lng: 19.646349 },
    { lat: 48.641481, lng: 19.646593 },
    { lat: 48.641321, lng: 19.646635 },
    { lat: 48.641218, lng: 19.646664 },
    { lat: 48.64117, lng: 19.646669 },
    { lat: 48.64113, lng: 19.646662 },
    { lat: 48.641021, lng: 19.646651 },
    { lat: 48.640914, lng: 19.646638 },
    { lat: 48.640875, lng: 19.646685 },
    { lat: 48.640794, lng: 19.646687 },
    { lat: 48.640664, lng: 19.646702 },
    { lat: 48.640412, lng: 19.646776 },
    { lat: 48.640412, lng: 19.646758 },
    { lat: 48.640251, lng: 19.646825 },
    { lat: 48.640157, lng: 19.646844 },
    { lat: 48.639971, lng: 19.646896 },
    { lat: 48.639766, lng: 19.646903 },
    { lat: 48.6394, lng: 19.646872 },
    { lat: 48.639367, lng: 19.646865 },
    { lat: 48.639229, lng: 19.646881 },
    { lat: 48.638376, lng: 19.646812 },
    { lat: 48.638209, lng: 19.646806 },
    { lat: 48.637953, lng: 19.646833 },
    { lat: 48.637566, lng: 19.646793 },
    { lat: 48.636541, lng: 19.646735 },
    { lat: 48.634754, lng: 19.645978 },
    { lat: 48.634026, lng: 19.645485 },
    { lat: 48.633423, lng: 19.644423 },
    { lat: 48.6328, lng: 19.642857 },
    { lat: 48.632685, lng: 19.642299 },
    { lat: 48.6325991, lng: 19.6419031 },
    { lat: 48.6325794, lng: 19.6414869 },
    { lat: 48.6325362, lng: 19.641165 },
    { lat: 48.632272, lng: 19.640365 },
    { lat: 48.632237, lng: 19.640231 },
    { lat: 48.632127, lng: 19.639826 },
    { lat: 48.632076, lng: 19.63929 },
    { lat: 48.631947, lng: 19.639091 },
    { lat: 48.63165, lng: 19.638655 },
    { lat: 48.631118, lng: 19.63811 },
    { lat: 48.630839, lng: 19.637746 },
    { lat: 48.630374, lng: 19.637303 },
    { lat: 48.630026, lng: 19.637124 },
    { lat: 48.629766, lng: 19.637096 },
    { lat: 48.629585, lng: 19.636991 },
    { lat: 48.629349, lng: 19.636925 },
    { lat: 48.628612, lng: 19.637026 },
    { lat: 48.628126, lng: 19.637141 },
    { lat: 48.627813, lng: 19.637407 },
    { lat: 48.627604, lng: 19.637527 },
    { lat: 48.627305, lng: 19.637609 },
    { lat: 48.626789, lng: 19.637872 },
    { lat: 48.626212, lng: 19.63805 },
    { lat: 48.625861, lng: 19.638086 },
    { lat: 48.625278, lng: 19.637969 },
    { lat: 48.624893, lng: 19.637798 },
    { lat: 48.624889, lng: 19.637852 },
    { lat: 48.624861, lng: 19.638367 },
    { lat: 48.624959, lng: 19.639387 },
    { lat: 48.62478, lng: 19.640275 },
    { lat: 48.624685, lng: 19.640755 },
    { lat: 48.624731, lng: 19.641312 },
    { lat: 48.624757, lng: 19.641931 },
    { lat: 48.624813, lng: 19.642546 },
    { lat: 48.624813, lng: 19.64255 },
    { lat: 48.624822, lng: 19.642584 },
    { lat: 48.624888, lng: 19.642821 },
    { lat: 48.625042, lng: 19.643417 },
    { lat: 48.624876, lng: 19.644298 },
    { lat: 48.62488, lng: 19.64444 },
    { lat: 48.624904, lng: 19.645283 },
    { lat: 48.624881, lng: 19.645556 },
    { lat: 48.624902, lng: 19.64583 },
    { lat: 48.624872, lng: 19.645907 },
    { lat: 48.624823, lng: 19.646035 },
    { lat: 48.624655, lng: 19.646571 },
    { lat: 48.62463, lng: 19.647242 },
    { lat: 48.624551, lng: 19.64782 },
    { lat: 48.624639, lng: 19.647975 },
    { lat: 48.624655, lng: 19.648002 },
    { lat: 48.624842, lng: 19.648332 },
    { lat: 48.624899, lng: 19.648689 },
    { lat: 48.62502, lng: 19.649451 },
    { lat: 48.624912, lng: 19.64966 },
    { lat: 48.624891, lng: 19.649699 },
    { lat: 48.624842, lng: 19.650065 },
    { lat: 48.624866, lng: 19.65114 },
    { lat: 48.62487, lng: 19.651796 },
    { lat: 48.624807, lng: 19.652076 },
    { lat: 48.624789, lng: 19.652675 },
    { lat: 48.624677, lng: 19.652887 },
    { lat: 48.624084, lng: 19.654008 },
    { lat: 48.624076, lng: 19.654222 },
    { lat: 48.624116, lng: 19.65441 },
    { lat: 48.624226, lng: 19.654811 },
    { lat: 48.62431, lng: 19.655546 },
    { lat: 48.6239281, lng: 19.6571659 },
    { lat: 48.623885, lng: 19.657468 },
    { lat: 48.623833, lng: 19.657726 },
    { lat: 48.623773, lng: 19.658584 },
    { lat: 48.623791, lng: 19.659171 },
    { lat: 48.623886, lng: 19.660236 },
    { lat: 48.623847, lng: 19.660646 },
    { lat: 48.623707, lng: 19.66115 },
    { lat: 48.623673, lng: 19.661272 },
    { lat: 48.623655, lng: 19.661249 },
    { lat: 48.623605, lng: 19.661315 },
    { lat: 48.623547, lng: 19.661374 },
    { lat: 48.62312, lng: 19.661802 },
    { lat: 48.622734, lng: 19.662256 },
    { lat: 48.622335, lng: 19.662765 },
    { lat: 48.622192, lng: 19.663004 },
    { lat: 48.622157, lng: 19.663064 },
    { lat: 48.622069, lng: 19.663212 },
    { lat: 48.621914, lng: 19.66364 },
    { lat: 48.62174, lng: 19.66409 },
    { lat: 48.621443, lng: 19.664958 },
    { lat: 48.621466, lng: 19.664973 },
    { lat: 48.621065, lng: 19.666538 },
    { lat: 48.620976, lng: 19.666945 },
    { lat: 48.620829, lng: 19.667772 },
    { lat: 48.620802, lng: 19.668175 },
    { lat: 48.620577, lng: 19.668609 },
    { lat: 48.620529, lng: 19.669027 },
    { lat: 48.620408, lng: 19.66953 },
    { lat: 48.620398, lng: 19.670118 },
    { lat: 48.62047, lng: 19.670955 },
    { lat: 48.620454, lng: 19.671149 },
    { lat: 48.620421, lng: 19.671234 },
    { lat: 48.620217, lng: 19.671589 },
    { lat: 48.620066, lng: 19.671807 },
    { lat: 48.619914, lng: 19.671982 },
    { lat: 48.61975, lng: 19.672091 },
    { lat: 48.619614, lng: 19.672159 },
    { lat: 48.619159, lng: 19.672291 },
  ],
  Bacúch: [
    { lat: 48.7872977, lng: 19.8320497 },
    { lat: 48.788048, lng: 19.833088 },
    { lat: 48.7896535, lng: 19.8349763 },
    { lat: 48.7903893, lng: 19.8359418 },
    { lat: 48.7908513, lng: 19.8383488 },
    { lat: 48.7907902, lng: 19.841033 },
    { lat: 48.7909435, lng: 19.8440626 },
    { lat: 48.7919579, lng: 19.8436592 },
    { lat: 48.7934661, lng: 19.8439919 },
    { lat: 48.7948052, lng: 19.8446915 },
    { lat: 48.7957063, lng: 19.844768 },
    { lat: 48.796252, lng: 19.8446723 },
    { lat: 48.7969499, lng: 19.8450268 },
    { lat: 48.7975663, lng: 19.8451371 },
    { lat: 48.7984867, lng: 19.8456834 },
    { lat: 48.7990747, lng: 19.8458651 },
    { lat: 48.8012387, lng: 19.8461497 },
    { lat: 48.8022169, lng: 19.8464995 },
    { lat: 48.8035915, lng: 19.8471847 },
    { lat: 48.805044, lng: 19.8458341 },
    { lat: 48.8074935, lng: 19.8438035 },
    { lat: 48.8095974, lng: 19.8428802 },
    { lat: 48.8113358, lng: 19.8423335 },
    { lat: 48.8136029, lng: 19.8388147 },
    { lat: 48.8149359, lng: 19.8372799 },
    { lat: 48.8155735, lng: 19.8367064 },
    { lat: 48.8166601, lng: 19.8354407 },
    { lat: 48.8174239, lng: 19.8347392 },
    { lat: 48.8182083, lng: 19.8344046 },
    { lat: 48.8189149, lng: 19.8342038 },
    { lat: 48.8201774, lng: 19.8349247 },
    { lat: 48.8215912, lng: 19.8340108 },
    { lat: 48.822902, lng: 19.8320134 },
    { lat: 48.8244978, lng: 19.8308382 },
    { lat: 48.8251945, lng: 19.8301671 },
    { lat: 48.8261637, lng: 19.8294501 },
    { lat: 48.8271956, lng: 19.8284547 },
    { lat: 48.8278045, lng: 19.8277133 },
    { lat: 48.8285803, lng: 19.8269724 },
    { lat: 48.8302647, lng: 19.8268977 },
    { lat: 48.8316042, lng: 19.8270845 },
    { lat: 48.8322744, lng: 19.8273699 },
    { lat: 48.8339358, lng: 19.8272038 },
    { lat: 48.8361952, lng: 19.826232 },
    { lat: 48.8372984, lng: 19.8258999 },
    { lat: 48.8414414, lng: 19.8243209 },
    { lat: 48.8459023, lng: 19.8240321 },
    { lat: 48.847522, lng: 19.8232665 },
    { lat: 48.8476636, lng: 19.8231087 },
    { lat: 48.8478415, lng: 19.8235489 },
    { lat: 48.8481558, lng: 19.8238832 },
    { lat: 48.8483761, lng: 19.823949 },
    { lat: 48.848704, lng: 19.8229614 },
    { lat: 48.8489025, lng: 19.8225522 },
    { lat: 48.8491013, lng: 19.8213896 },
    { lat: 48.8490368, lng: 19.8212022 },
    { lat: 48.8500028, lng: 19.8204609 },
    { lat: 48.8506224, lng: 19.819885 },
    { lat: 48.8510668, lng: 19.8193247 },
    { lat: 48.8516997, lng: 19.818174 },
    { lat: 48.8544637, lng: 19.8147252 },
    { lat: 48.8563478, lng: 19.81177 },
    { lat: 48.8569354, lng: 19.8110833 },
    { lat: 48.857769, lng: 19.8102505 },
    { lat: 48.8589358, lng: 19.8092317 },
    { lat: 48.8600037, lng: 19.8079265 },
    { lat: 48.8607685, lng: 19.8068364 },
    { lat: 48.8616704, lng: 19.8064589 },
    { lat: 48.8638601, lng: 19.8060082 },
    { lat: 48.8645424, lng: 19.8054826 },
    { lat: 48.8649374, lng: 19.8053907 },
    { lat: 48.8656339, lng: 19.8053982 },
    { lat: 48.8662322, lng: 19.8055869 },
    { lat: 48.8679047, lng: 19.8058572 },
    { lat: 48.8686695, lng: 19.805469 },
    { lat: 48.8706626, lng: 19.8053158 },
    { lat: 48.8709133, lng: 19.8052017 },
    { lat: 48.8714265, lng: 19.805108 },
    { lat: 48.8718756, lng: 19.8048933 },
    { lat: 48.872245, lng: 19.8048368 },
    { lat: 48.8724204, lng: 19.8047545 },
    { lat: 48.8728293, lng: 19.8043631 },
    { lat: 48.8731158, lng: 19.8042418 },
    { lat: 48.8734057, lng: 19.8042738 },
    { lat: 48.8739568, lng: 19.8044622 },
    { lat: 48.8742887, lng: 19.8043747 },
    { lat: 48.8744791, lng: 19.8041661 },
    { lat: 48.8748017, lng: 19.8039392 },
    { lat: 48.8751664, lng: 19.8034387 },
    { lat: 48.875488, lng: 19.8035051 },
    { lat: 48.8768533, lng: 19.8029849 },
    { lat: 48.8781633, lng: 19.8015504 },
    { lat: 48.8784471, lng: 19.8013382 },
    { lat: 48.878782, lng: 19.8008803 },
    { lat: 48.8790384, lng: 19.8007119 },
    { lat: 48.8794245, lng: 19.7997199 },
    { lat: 48.8796784, lng: 19.7994988 },
    { lat: 48.880124, lng: 19.7992789 },
    { lat: 48.8803444, lng: 19.7992924 },
    { lat: 48.8811624, lng: 19.7990375 },
    { lat: 48.8818348, lng: 19.7990348 },
    { lat: 48.8824263, lng: 19.798542 },
    { lat: 48.8829776, lng: 19.7982883 },
    { lat: 48.883418, lng: 19.7979766 },
    { lat: 48.8849994, lng: 19.7964642 },
    { lat: 48.8860672, lng: 19.7959703 },
    { lat: 48.8875664, lng: 19.7955217 },
    { lat: 48.8881347, lng: 19.7949648 },
    { lat: 48.8897095, lng: 19.7926552 },
    { lat: 48.8901064, lng: 19.7928683 },
    { lat: 48.8908405, lng: 19.7920292 },
    { lat: 48.8912091, lng: 19.7919813 },
    { lat: 48.8923186, lng: 19.7916391 },
    { lat: 48.8929502, lng: 19.7916039 },
    { lat: 48.8934174, lng: 19.7913656 },
    { lat: 48.8944195, lng: 19.789562 },
    { lat: 48.8946415, lng: 19.7892533 },
    { lat: 48.8950704, lng: 19.7889877 },
    { lat: 48.8955156, lng: 19.7884982 },
    { lat: 48.8963909, lng: 19.7878005 },
    { lat: 48.8970293, lng: 19.7870914 },
    { lat: 48.8977952, lng: 19.7866761 },
    { lat: 48.8981411, lng: 19.7863611 },
    { lat: 48.8989242, lng: 19.7849686 },
    { lat: 48.8994672, lng: 19.7842687 },
    { lat: 48.9003613, lng: 19.7834591 },
    { lat: 48.900853, lng: 19.7827685 },
    { lat: 48.9015147, lng: 19.7814074 },
    { lat: 48.9030709, lng: 19.7795262 },
    { lat: 48.9032703, lng: 19.7791664 },
    { lat: 48.905189, lng: 19.7776197 },
    { lat: 48.906102, lng: 19.777009 },
    { lat: 48.905849, lng: 19.776169 },
    { lat: 48.905659, lng: 19.775541 },
    { lat: 48.905542, lng: 19.774734 },
    { lat: 48.905424, lng: 19.774273 },
    { lat: 48.905336, lng: 19.773733 },
    { lat: 48.905292, lng: 19.773424 },
    { lat: 48.905208, lng: 19.772716 },
    { lat: 48.905218, lng: 19.771708 },
    { lat: 48.905178, lng: 19.771244 },
    { lat: 48.904942, lng: 19.769562 },
    { lat: 48.904762, lng: 19.769162 },
    { lat: 48.904495, lng: 19.768289 },
    { lat: 48.904295, lng: 19.767558 },
    { lat: 48.904268, lng: 19.767348 },
    { lat: 48.904212, lng: 19.767011 },
    { lat: 48.904066, lng: 19.766237 },
    { lat: 48.904117, lng: 19.765888 },
    { lat: 48.904048, lng: 19.765277 },
    { lat: 48.903942, lng: 19.764128 },
    { lat: 48.90404, lng: 19.763763 },
    { lat: 48.90411, lng: 19.763351 },
    { lat: 48.904176, lng: 19.763046 },
    { lat: 48.904258, lng: 19.762774 },
    { lat: 48.905268, lng: 19.761417 },
    { lat: 48.904859, lng: 19.761125 },
    { lat: 48.904621, lng: 19.760771 },
    { lat: 48.904175, lng: 19.760375 },
    { lat: 48.903086, lng: 19.75937 },
    { lat: 48.902825, lng: 19.759056 },
    { lat: 48.902169, lng: 19.75848 },
    { lat: 48.901948, lng: 19.758288 },
    { lat: 48.90149, lng: 19.757486 },
    { lat: 48.900968, lng: 19.756905 },
    { lat: 48.900779, lng: 19.756707 },
    { lat: 48.900695, lng: 19.756488 },
    { lat: 48.900394, lng: 19.755877 },
    { lat: 48.900254, lng: 19.755558 },
    { lat: 48.900121, lng: 19.754773 },
    { lat: 48.900073, lng: 19.75432 },
    { lat: 48.900016, lng: 19.753739 },
    { lat: 48.900011, lng: 19.753418 },
    { lat: 48.899965, lng: 19.75319 },
    { lat: 48.899777, lng: 19.752971 },
    { lat: 48.899549, lng: 19.752674 },
    { lat: 48.899443, lng: 19.752396 },
    { lat: 48.899256, lng: 19.751973 },
    { lat: 48.899203, lng: 19.751877 },
    { lat: 48.899158, lng: 19.751779 },
    { lat: 48.899025, lng: 19.751585 },
    { lat: 48.898969, lng: 19.751449 },
    { lat: 48.898847, lng: 19.751029 },
    { lat: 48.898313, lng: 19.750438 },
    { lat: 48.8983, lng: 19.750267 },
    { lat: 48.898264, lng: 19.750118 },
    { lat: 48.898226, lng: 19.749862 },
    { lat: 48.897767, lng: 19.748328 },
    { lat: 48.897412, lng: 19.746719 },
    { lat: 48.896834, lng: 19.744618 },
    { lat: 48.8967503, lng: 19.7443945 },
    { lat: 48.8965088, lng: 19.7442287 },
    { lat: 48.8957292, lng: 19.7442043 },
    { lat: 48.8948328, lng: 19.7438547 },
    { lat: 48.8940589, lng: 19.7437244 },
    { lat: 48.8934176, lng: 19.7437712 },
    { lat: 48.8929594, lng: 19.7439607 },
    { lat: 48.8920767, lng: 19.7435894 },
    { lat: 48.8915954, lng: 19.7432463 },
    { lat: 48.8911761, lng: 19.7426545 },
    { lat: 48.890789, lng: 19.7419944 },
    { lat: 48.8896104, lng: 19.7410676 },
    { lat: 48.8890416, lng: 19.7409962 },
    { lat: 48.8885998, lng: 19.7405807 },
    { lat: 48.8883564, lng: 19.7404786 },
    { lat: 48.888111, lng: 19.7404536 },
    { lat: 48.8878463, lng: 19.7403822 },
    { lat: 48.8876633, lng: 19.7407261 },
    { lat: 48.8873444, lng: 19.7411353 },
    { lat: 48.8866332, lng: 19.7422625 },
    { lat: 48.8864046, lng: 19.7427778 },
    { lat: 48.8858299, lng: 19.7438245 },
    { lat: 48.8855733, lng: 19.7448091 },
    { lat: 48.8853181, lng: 19.7468231 },
    { lat: 48.8850908, lng: 19.7472913 },
    { lat: 48.8848401, lng: 19.7476091 },
    { lat: 48.8846325, lng: 19.7481952 },
    { lat: 48.8844126, lng: 19.7485732 },
    { lat: 48.8841398, lng: 19.7497298 },
    { lat: 48.8840489, lng: 19.7503263 },
    { lat: 48.8840249, lng: 19.7509903 },
    { lat: 48.8838763, lng: 19.7516353 },
    { lat: 48.8834163, lng: 19.7522408 },
    { lat: 48.8831585, lng: 19.7524772 },
    { lat: 48.8828137, lng: 19.7531212 },
    { lat: 48.8825389, lng: 19.7537628 },
    { lat: 48.8823879, lng: 19.7544885 },
    { lat: 48.8824247, lng: 19.7550418 },
    { lat: 48.8821941, lng: 19.7557176 },
    { lat: 48.8819292, lng: 19.7570014 },
    { lat: 48.8817872, lng: 19.7573639 },
    { lat: 48.8814992, lng: 19.7578883 },
    { lat: 48.881459, lng: 19.7582539 },
    { lat: 48.8812457, lng: 19.758784 },
    { lat: 48.8807561, lng: 19.7590817 },
    { lat: 48.8803619, lng: 19.7595748 },
    { lat: 48.8803242, lng: 19.7599476 },
    { lat: 48.8786696, lng: 19.7614999 },
    { lat: 48.8784287, lng: 19.7624587 },
    { lat: 48.8783856, lng: 19.762914 },
    { lat: 48.8781359, lng: 19.7640866 },
    { lat: 48.8783609, lng: 19.7650812 },
    { lat: 48.8786874, lng: 19.7661082 },
    { lat: 48.8787586, lng: 19.7671426 },
    { lat: 48.8783232, lng: 19.7682934 },
    { lat: 48.877896, lng: 19.7692461 },
    { lat: 48.8778349, lng: 19.7696266 },
    { lat: 48.877662, lng: 19.7700227 },
    { lat: 48.8774821, lng: 19.7707795 },
    { lat: 48.8773123, lng: 19.7707364 },
    { lat: 48.876992, lng: 19.7717853 },
    { lat: 48.8766402, lng: 19.7722988 },
    { lat: 48.876489, lng: 19.7726529 },
    { lat: 48.876236, lng: 19.7729213 },
    { lat: 48.8758855, lng: 19.7731479 },
    { lat: 48.8753974, lng: 19.7733254 },
    { lat: 48.8742584, lng: 19.7742785 },
    { lat: 48.8734701, lng: 19.7761717 },
    { lat: 48.8732936, lng: 19.7767251 },
    { lat: 48.8729757, lng: 19.7771342 },
    { lat: 48.8725524, lng: 19.7779131 },
    { lat: 48.8722921, lng: 19.778252 },
    { lat: 48.8721454, lng: 19.7783186 },
    { lat: 48.8715306, lng: 19.7792816 },
    { lat: 48.8711728, lng: 19.7794746 },
    { lat: 48.8705872, lng: 19.7796388 },
    { lat: 48.8702614, lng: 19.7799144 },
    { lat: 48.8697462, lng: 19.780092 },
    { lat: 48.8681556, lng: 19.7801203 },
    { lat: 48.8673742, lng: 19.7802064 },
    { lat: 48.8669314, lng: 19.7803613 },
    { lat: 48.8666982, lng: 19.7805937 },
    { lat: 48.866218, lng: 19.7807401 },
    { lat: 48.8660428, lng: 19.780859 },
    { lat: 48.8657211, lng: 19.7812275 },
    { lat: 48.8646755, lng: 19.7820557 },
    { lat: 48.864026, lng: 19.7823748 },
    { lat: 48.8630644, lng: 19.7826979 },
    { lat: 48.8625984, lng: 19.7826837 },
    { lat: 48.8621612, lng: 19.782575 },
    { lat: 48.861222, lng: 19.7828715 },
    { lat: 48.8612208, lng: 19.7829997 },
    { lat: 48.8608529, lng: 19.7836541 },
    { lat: 48.8607408, lng: 19.7834388 },
    { lat: 48.8604841, lng: 19.7837504 },
    { lat: 48.8599259, lng: 19.7841762 },
    { lat: 48.859485, lng: 19.7846328 },
    { lat: 48.8579979, lng: 19.7865236 },
    { lat: 48.857241, lng: 19.7880521 },
    { lat: 48.8542843, lng: 19.7916135 },
    { lat: 48.8538979, lng: 19.7918971 },
    { lat: 48.8526047, lng: 19.7925497 },
    { lat: 48.8516652, lng: 19.7938701 },
    { lat: 48.8508716, lng: 19.7946934 },
    { lat: 48.8502134, lng: 19.7957457 },
    { lat: 48.8491011, lng: 19.7963077 },
    { lat: 48.8477219, lng: 19.7963802 },
    { lat: 48.8474554, lng: 19.7960005 },
    { lat: 48.8449159, lng: 19.7957127 },
    { lat: 48.844607, lng: 19.7962253 },
    { lat: 48.8445012, lng: 19.7961007 },
    { lat: 48.8445848, lng: 19.7959961 },
    { lat: 48.844582, lng: 19.7954928 },
    { lat: 48.844494, lng: 19.7947927 },
    { lat: 48.8441945, lng: 19.7941984 },
    { lat: 48.8439448, lng: 19.7941078 },
    { lat: 48.8438874, lng: 19.7940045 },
    { lat: 48.8438176, lng: 19.7940801 },
    { lat: 48.8435202, lng: 19.7933051 },
    { lat: 48.843056, lng: 19.7932012 },
    { lat: 48.8429044, lng: 19.7932388 },
    { lat: 48.8427796, lng: 19.7931422 },
    { lat: 48.8426001, lng: 19.7932864 },
    { lat: 48.8425418, lng: 19.7931145 },
    { lat: 48.8421846, lng: 19.7933411 },
    { lat: 48.8419632, lng: 19.7933807 },
    { lat: 48.8420171, lng: 19.7935331 },
    { lat: 48.8408994, lng: 19.7927276 },
    { lat: 48.8402117, lng: 19.7918099 },
    { lat: 48.8398338, lng: 19.7911213 },
    { lat: 48.8395953, lng: 19.7905615 },
    { lat: 48.8383944, lng: 19.7895531 },
    { lat: 48.8375783, lng: 19.7884841 },
    { lat: 48.8374406, lng: 19.788608 },
    { lat: 48.8374163, lng: 19.788668 },
    { lat: 48.8373631, lng: 19.7888182 },
    { lat: 48.8370982, lng: 19.7888926 },
    { lat: 48.8370333, lng: 19.7890085 },
    { lat: 48.8368394, lng: 19.7891076 },
    { lat: 48.8350475, lng: 19.7879299 },
    { lat: 48.834825, lng: 19.7877311 },
    { lat: 48.8347034, lng: 19.7873467 },
    { lat: 48.8346516, lng: 19.7863141 },
    { lat: 48.8344352, lng: 19.7863375 },
    { lat: 48.8343808, lng: 19.7859943 },
    { lat: 48.8342422, lng: 19.7860704 },
    { lat: 48.8340783, lng: 19.7858511 },
    { lat: 48.8340011, lng: 19.7858613 },
    { lat: 48.8339297, lng: 19.7853124 },
    { lat: 48.8337391, lng: 19.7848115 },
    { lat: 48.8335573, lng: 19.7839013 },
    { lat: 48.833164, lng: 19.7832509 },
    { lat: 48.8324482, lng: 19.7823902 },
    { lat: 48.831973, lng: 19.7814365 },
    { lat: 48.8318248, lng: 19.7803879 },
    { lat: 48.8314207, lng: 19.7793888 },
    { lat: 48.8312363, lng: 19.7787765 },
    { lat: 48.8306994, lng: 19.7774972 },
    { lat: 48.8306315, lng: 19.7773098 },
    { lat: 48.8306248, lng: 19.777131 },
    { lat: 48.8303037, lng: 19.7770795 },
    { lat: 48.8297812, lng: 19.7768247 },
    { lat: 48.8294799, lng: 19.7767633 },
    { lat: 48.8288945, lng: 19.7769009 },
    { lat: 48.8281178, lng: 19.7766565 },
    { lat: 48.8274867, lng: 19.7766014 },
    { lat: 48.8268438, lng: 19.7768989 },
    { lat: 48.8264861, lng: 19.776984 },
    { lat: 48.826448, lng: 19.7768895 },
    { lat: 48.8261177, lng: 19.7771654 },
    { lat: 48.8260047, lng: 19.777182 },
    { lat: 48.8257214, lng: 19.7769673 },
    { lat: 48.8254074, lng: 19.7769987 },
    { lat: 48.8251485, lng: 19.7771834 },
    { lat: 48.8247683, lng: 19.7773005 },
    { lat: 48.8246274, lng: 19.7770142 },
    { lat: 48.8243556, lng: 19.7768559 },
    { lat: 48.8242791, lng: 19.7766233 },
    { lat: 48.8240822, lng: 19.7765804 },
    { lat: 48.8233505, lng: 19.7768746 },
    { lat: 48.8233914, lng: 19.7769088 },
    { lat: 48.8231513, lng: 19.7769847 },
    { lat: 48.8229865, lng: 19.7771295 },
    { lat: 48.8225925, lng: 19.7770596 },
    { lat: 48.8223448, lng: 19.7771666 },
    { lat: 48.8222062, lng: 19.7770357 },
    { lat: 48.822102, lng: 19.7770518 },
    { lat: 48.8220374, lng: 19.7767605 },
    { lat: 48.8215909, lng: 19.7768013 },
    { lat: 48.8215122, lng: 19.7766769 },
    { lat: 48.8214598, lng: 19.776794 },
    { lat: 48.8212727, lng: 19.776841 },
    { lat: 48.8208783, lng: 19.7765233 },
    { lat: 48.8205701, lng: 19.7765102 },
    { lat: 48.8205193, lng: 19.7763258 },
    { lat: 48.8204039, lng: 19.7763159 },
    { lat: 48.8202994, lng: 19.7765287 },
    { lat: 48.820076, lng: 19.7766237 },
    { lat: 48.8199507, lng: 19.7765937 },
    { lat: 48.819925, lng: 19.7764969 },
    { lat: 48.8199636, lng: 19.7763961 },
    { lat: 48.8200504, lng: 19.7764267 },
    { lat: 48.8201103, lng: 19.776302 },
    { lat: 48.8201274, lng: 19.7760705 },
    { lat: 48.8192286, lng: 19.7757944 },
    { lat: 48.8193212, lng: 19.7761844 },
    { lat: 48.8192126, lng: 19.7763036 },
    { lat: 48.818868, lng: 19.7763592 },
    { lat: 48.8186951, lng: 19.7759636 },
    { lat: 48.8184035, lng: 19.7757351 },
    { lat: 48.8183851, lng: 19.7755939 },
    { lat: 48.8175962, lng: 19.7752554 },
    { lat: 48.8169478, lng: 19.7751612 },
    { lat: 48.8160674, lng: 19.7744569 },
    { lat: 48.8151006, lng: 19.7732882 },
    { lat: 48.8149421, lng: 19.7731944 },
    { lat: 48.8141264, lng: 19.7731438 },
    { lat: 48.8132994, lng: 19.7733228 },
    { lat: 48.8128915, lng: 19.773631 },
    { lat: 48.8122874, lng: 19.7742145 },
    { lat: 48.8119169, lng: 19.7744504 },
    { lat: 48.8106245, lng: 19.7745237 },
    { lat: 48.8089352, lng: 19.7739486 },
    { lat: 48.8084738, lng: 19.7738823 },
    { lat: 48.8079617, lng: 19.7737127 },
    { lat: 48.8063936, lng: 19.773819 },
    { lat: 48.8055472, lng: 19.7742247 },
    { lat: 48.8053271, lng: 19.7742646 },
    { lat: 48.8047786, lng: 19.7746801 },
    { lat: 48.8038382, lng: 19.7746878 },
    { lat: 48.8035959, lng: 19.7755622 },
    { lat: 48.8035172, lng: 19.7758782 },
    { lat: 48.8035538, lng: 19.7764288 },
    { lat: 48.8034487, lng: 19.77738 },
    { lat: 48.8035275, lng: 19.7786169 },
    { lat: 48.8034927, lng: 19.7792923 },
    { lat: 48.8036633, lng: 19.780204 },
    { lat: 48.8038677, lng: 19.7808637 },
    { lat: 48.8038811, lng: 19.7825399 },
    { lat: 48.8040413, lng: 19.7835207 },
    { lat: 48.8043028, lng: 19.7841968 },
    { lat: 48.8043199, lng: 19.7848763 },
    { lat: 48.8046389, lng: 19.7864309 },
    { lat: 48.8046212, lng: 19.7873415 },
    { lat: 48.8040667, lng: 19.7887846 },
    { lat: 48.8031983, lng: 19.7924347 },
    { lat: 48.8031823, lng: 19.7935167 },
    { lat: 48.8030608, lng: 19.794245 },
    { lat: 48.8030873, lng: 19.7945161 },
    { lat: 48.8034386, lng: 19.795213 },
    { lat: 48.8034488, lng: 19.7954246 },
    { lat: 48.8032774, lng: 19.7961961 },
    { lat: 48.8005395, lng: 19.7996889 },
    { lat: 48.7986576, lng: 19.8026015 },
    { lat: 48.7977967, lng: 19.8035326 },
    { lat: 48.7953956, lng: 19.8079351 },
    { lat: 48.7949551, lng: 19.8089215 },
    { lat: 48.7935598, lng: 19.8103105 },
    { lat: 48.7916587, lng: 19.815101 },
    { lat: 48.7897017, lng: 19.8182168 },
    { lat: 48.7882963, lng: 19.8208596 },
    { lat: 48.7885163, lng: 19.8236248 },
    { lat: 48.7880315, lng: 19.8294995 },
    { lat: 48.7872977, lng: 19.8320497 },
  ],
  Drábsko: [
    { lat: 48.6727783, lng: 19.6605897 },
    { lat: 48.6728038, lng: 19.6600502 },
    { lat: 48.6726986, lng: 19.6593362 },
    { lat: 48.6723701, lng: 19.6583187 },
    { lat: 48.6714845, lng: 19.657087 },
    { lat: 48.6707072, lng: 19.6561857 },
    { lat: 48.6697772, lng: 19.6555493 },
    { lat: 48.6694064, lng: 19.6553943 },
    { lat: 48.6686202, lng: 19.6552808 },
    { lat: 48.665896, lng: 19.6542557 },
    { lat: 48.6648713, lng: 19.6543708 },
    { lat: 48.6637449, lng: 19.6533745 },
    { lat: 48.6629643, lng: 19.6530523 },
    { lat: 48.6624784, lng: 19.6534734 },
    { lat: 48.6615113, lng: 19.6541436 },
    { lat: 48.6611149, lng: 19.6546022 },
    { lat: 48.6598885, lng: 19.6551949 },
    { lat: 48.6590818, lng: 19.6553443 },
    { lat: 48.6584536, lng: 19.6553572 },
    { lat: 48.6558071, lng: 19.6553116 },
    { lat: 48.6555393, lng: 19.6552213 },
    { lat: 48.655515, lng: 19.6552787 },
    { lat: 48.6548953, lng: 19.6550819 },
    { lat: 48.6549293, lng: 19.6550071 },
    { lat: 48.6544743, lng: 19.6548401 },
    { lat: 48.6541491, lng: 19.6545961 },
    { lat: 48.6535361, lng: 19.6543673 },
    { lat: 48.6527309, lng: 19.6538295 },
    { lat: 48.6524171, lng: 19.6534884 },
    { lat: 48.6520964, lng: 19.6533509 },
    { lat: 48.6516145, lng: 19.6529811 },
    { lat: 48.6500756, lng: 19.6520309 },
    { lat: 48.6495543, lng: 19.6518383 },
    { lat: 48.6484903, lng: 19.6510046 },
    { lat: 48.6477114, lng: 19.6506232 },
    { lat: 48.6471926, lng: 19.6505376 },
    { lat: 48.6467088, lng: 19.6506737 },
    { lat: 48.6456004, lng: 19.6507343 },
    { lat: 48.6454353, lng: 19.6507975 },
    { lat: 48.6457517, lng: 19.6522692 },
    { lat: 48.6458445, lng: 19.6525319 },
    { lat: 48.6462499, lng: 19.6528789 },
    { lat: 48.646657, lng: 19.6531127 },
    { lat: 48.6468579, lng: 19.6533943 },
    { lat: 48.6469927, lng: 19.6541145 },
    { lat: 48.6471968, lng: 19.6543909 },
    { lat: 48.6473794, lng: 19.6547713 },
    { lat: 48.6478189, lng: 19.6561527 },
    { lat: 48.6478351, lng: 19.6562138 },
    { lat: 48.6474355, lng: 19.6564488 },
    { lat: 48.6472929, lng: 19.6566583 },
    { lat: 48.6473253, lng: 19.6570815 },
    { lat: 48.6475199, lng: 19.6577244 },
    { lat: 48.6474655, lng: 19.6585488 },
    { lat: 48.6475521, lng: 19.6590228 },
    { lat: 48.6476651, lng: 19.6592297 },
    { lat: 48.6480255, lng: 19.6594526 },
    { lat: 48.6486028, lng: 19.6609181 },
    { lat: 48.648626, lng: 19.6612364 },
    { lat: 48.6485684, lng: 19.6615088 },
    { lat: 48.6485991, lng: 19.6618688 },
    { lat: 48.6485112, lng: 19.6620734 },
    { lat: 48.6487315, lng: 19.6628106 },
    { lat: 48.6492639, lng: 19.6632259 },
    { lat: 48.6492411, lng: 19.6636245 },
    { lat: 48.6494789, lng: 19.6646575 },
    { lat: 48.6496186, lng: 19.6650349 },
    { lat: 48.6498106, lng: 19.6652957 },
    { lat: 48.6499063, lng: 19.6656983 },
    { lat: 48.6491605, lng: 19.6659518 },
    { lat: 48.6496031, lng: 19.6672058 },
    { lat: 48.6493878, lng: 19.6674794 },
    { lat: 48.6494237, lng: 19.6676756 },
    { lat: 48.649574, lng: 19.6680159 },
    { lat: 48.6498594, lng: 19.6691275 },
    { lat: 48.6498995, lng: 19.6691155 },
    { lat: 48.6493247, lng: 19.6710296 },
    { lat: 48.6491743, lng: 19.6716749 },
    { lat: 48.6488867, lng: 19.6735047 },
    { lat: 48.6489873, lng: 19.6744729 },
    { lat: 48.6491196, lng: 19.6752612 },
    { lat: 48.6499738, lng: 19.674457 },
    { lat: 48.6505604, lng: 19.6753569 },
    { lat: 48.6505292, lng: 19.6755165 },
    { lat: 48.6507909, lng: 19.6757756 },
    { lat: 48.650867, lng: 19.676063 },
    { lat: 48.6510951, lng: 19.6763009 },
    { lat: 48.6508086, lng: 19.6771294 },
    { lat: 48.6505265, lng: 19.6775732 },
    { lat: 48.650011, lng: 19.678137 },
    { lat: 48.6511518, lng: 19.6787825 },
    { lat: 48.6513107, lng: 19.6791629 },
    { lat: 48.6514625, lng: 19.6792839 },
    { lat: 48.6516453, lng: 19.6792899 },
    { lat: 48.6520322, lng: 19.679701 },
    { lat: 48.6514571, lng: 19.6833518 },
    { lat: 48.6516291, lng: 19.6841472 },
    { lat: 48.6518177, lng: 19.6846119 },
    { lat: 48.652026, lng: 19.6862542 },
    { lat: 48.6528412, lng: 19.6865171 },
    { lat: 48.6529385, lng: 19.6866305 },
    { lat: 48.6531616, lng: 19.686528 },
    { lat: 48.6533827, lng: 19.6874417 },
    { lat: 48.6536947, lng: 19.6878198 },
    { lat: 48.6539262, lng: 19.6879809 },
    { lat: 48.6542691, lng: 19.6884233 },
    { lat: 48.6543354, lng: 19.6883417 },
    { lat: 48.6547455, lng: 19.6890737 },
    { lat: 48.6550051, lng: 19.6899454 },
    { lat: 48.6550945, lng: 19.6904559 },
    { lat: 48.6550663, lng: 19.6912175 },
    { lat: 48.6552306, lng: 19.6915141 },
    { lat: 48.6551873, lng: 19.6920623 },
    { lat: 48.6555565, lng: 19.6931168 },
    { lat: 48.6557885, lng: 19.6933163 },
    { lat: 48.6559966, lng: 19.6936106 },
    { lat: 48.6560709, lng: 19.6936341 },
    { lat: 48.6567596, lng: 19.6945047 },
    { lat: 48.6569686, lng: 19.6947654 },
    { lat: 48.6577671, lng: 19.6939748 },
    { lat: 48.6584503, lng: 19.6931233 },
    { lat: 48.658789, lng: 19.6925796 },
    { lat: 48.6592969, lng: 19.6919938 },
    { lat: 48.6600198, lng: 19.6912497 },
    { lat: 48.6609846, lng: 19.6904728 },
    { lat: 48.6621385, lng: 19.6890106 },
    { lat: 48.6625602, lng: 19.6887421 },
    { lat: 48.6621741, lng: 19.6882956 },
    { lat: 48.661805, lng: 19.6861796 },
    { lat: 48.6617561, lng: 19.6852108 },
    { lat: 48.6617466, lng: 19.6829485 },
    { lat: 48.6614437, lng: 19.6816905 },
    { lat: 48.6612, lng: 19.6801185 },
    { lat: 48.661022, lng: 19.6775423 },
    { lat: 48.6610511, lng: 19.6766094 },
    { lat: 48.6617919, lng: 19.6751052 },
    { lat: 48.6629576, lng: 19.6743139 },
    { lat: 48.6639209, lng: 19.6731504 },
    { lat: 48.6645431, lng: 19.6716203 },
    { lat: 48.6656438, lng: 19.6698773 },
    { lat: 48.6660902, lng: 19.6693542 },
    { lat: 48.6670893, lng: 19.668743 },
    { lat: 48.6677255, lng: 19.6667949 },
    { lat: 48.6682002, lng: 19.6664752 },
    { lat: 48.6687173, lng: 19.6662214 },
    { lat: 48.6693324, lng: 19.6657694 },
    { lat: 48.6697394, lng: 19.664765 },
    { lat: 48.6704331, lng: 19.6633467 },
    { lat: 48.6709206, lng: 19.6625879 },
    { lat: 48.6717698, lng: 19.6617461 },
    { lat: 48.6727783, lng: 19.6605897 },
  ],
  Jarabá: [
    { lat: 48.8865324, lng: 19.7370939 },
    { lat: 48.8875042, lng: 19.7383611 },
    { lat: 48.8876772, lng: 19.7393289 },
    { lat: 48.8882302, lng: 19.740119 },
    { lat: 48.8883564, lng: 19.7404786 },
    { lat: 48.8885998, lng: 19.7405807 },
    { lat: 48.8890416, lng: 19.7409962 },
    { lat: 48.8896104, lng: 19.7410676 },
    { lat: 48.890789, lng: 19.7419944 },
    { lat: 48.8911761, lng: 19.7426545 },
    { lat: 48.8915954, lng: 19.7432463 },
    { lat: 48.8920767, lng: 19.7435894 },
    { lat: 48.8929594, lng: 19.7439607 },
    { lat: 48.8934176, lng: 19.7437712 },
    { lat: 48.8940589, lng: 19.7437244 },
    { lat: 48.8948328, lng: 19.7438547 },
    { lat: 48.8957292, lng: 19.7442043 },
    { lat: 48.8965088, lng: 19.7442287 },
    { lat: 48.8967503, lng: 19.7443945 },
    { lat: 48.896834, lng: 19.744618 },
    { lat: 48.897873, lng: 19.741581 },
    { lat: 48.898121, lng: 19.740607 },
    { lat: 48.900028, lng: 19.737315 },
    { lat: 48.901321, lng: 19.736133 },
    { lat: 48.902469, lng: 19.73648 },
    { lat: 48.902609, lng: 19.736446 },
    { lat: 48.902835, lng: 19.736491 },
    { lat: 48.903005, lng: 19.736555 },
    { lat: 48.903056, lng: 19.736665 },
    { lat: 48.903298, lng: 19.736606 },
    { lat: 48.903516, lng: 19.736642 },
    { lat: 48.904181, lng: 19.736445 },
    { lat: 48.904464, lng: 19.736134 },
    { lat: 48.904723, lng: 19.735871 },
    { lat: 48.905264, lng: 19.736052 },
    { lat: 48.905426, lng: 19.736121 },
    { lat: 48.905476, lng: 19.736084 },
    { lat: 48.905496, lng: 19.736075 },
    { lat: 48.905585, lng: 19.736052 },
    { lat: 48.905687, lng: 19.736007 },
    { lat: 48.905796, lng: 19.73596 },
    { lat: 48.905825, lng: 19.735946 },
    { lat: 48.906247, lng: 19.73556 },
    { lat: 48.90629, lng: 19.735521 },
    { lat: 48.906498, lng: 19.735126 },
    { lat: 48.906786, lng: 19.734574 },
    { lat: 48.907012, lng: 19.734267 },
    { lat: 48.907031, lng: 19.734241 },
    { lat: 48.907033, lng: 19.734238 },
    { lat: 48.90751, lng: 19.733689 },
    { lat: 48.907662, lng: 19.733419 },
    { lat: 48.907858, lng: 19.731924 },
    { lat: 48.907599, lng: 19.730571 },
    { lat: 48.907637, lng: 19.727776 },
    { lat: 48.908108, lng: 19.724936 },
    { lat: 48.908217, lng: 19.724034 },
    { lat: 48.9069618, lng: 19.7204833 },
    { lat: 48.9067799, lng: 19.7194205 },
    { lat: 48.9080256, lng: 19.7167563 },
    { lat: 48.9069914, lng: 19.7145849 },
    { lat: 48.9047447, lng: 19.7095575 },
    { lat: 48.9049516, lng: 19.7096158 },
    { lat: 48.9081605, lng: 19.7105201 },
    { lat: 48.9086266, lng: 19.7108968 },
    { lat: 48.9111879, lng: 19.706876 },
    { lat: 48.9116562, lng: 19.7084449 },
    { lat: 48.9126607, lng: 19.7083588 },
    { lat: 48.9160077, lng: 19.7047352 },
    { lat: 48.9158148, lng: 19.7016699 },
    { lat: 48.9160749, lng: 19.7016882 },
    { lat: 48.9174787, lng: 19.6990947 },
    { lat: 48.9166035, lng: 19.6901703 },
    { lat: 48.9157854, lng: 19.6916881 },
    { lat: 48.9134663, lng: 19.6902056 },
    { lat: 48.9137743, lng: 19.6912588 },
    { lat: 48.9104768, lng: 19.6904766 },
    { lat: 48.9100384, lng: 19.6924315 },
    { lat: 48.9093491, lng: 19.6915977 },
    { lat: 48.9092075, lng: 19.6904324 },
    { lat: 48.9072542, lng: 19.6904404 },
    { lat: 48.9071131, lng: 19.690612 },
    { lat: 48.9062451, lng: 19.6896888 },
    { lat: 48.9076175, lng: 19.6890058 },
    { lat: 48.9064752, lng: 19.6823526 },
    { lat: 48.9074737, lng: 19.6827431 },
    { lat: 48.9062147, lng: 19.6808507 },
    { lat: 48.9059676, lng: 19.680944 },
    { lat: 48.9049077, lng: 19.6786681 },
    { lat: 48.9028645, lng: 19.6776684 },
    { lat: 48.9025751, lng: 19.6779769 },
    { lat: 48.9015373, lng: 19.6786331 },
    { lat: 48.9005547, lng: 19.6788833 },
    { lat: 48.9003693, lng: 19.6787425 },
    { lat: 48.8997546, lng: 19.6788297 },
    { lat: 48.8986849, lng: 19.6791714 },
    { lat: 48.8975304, lng: 19.6800171 },
    { lat: 48.8973811, lng: 19.6798558 },
    { lat: 48.8955214, lng: 19.6794296 },
    { lat: 48.8948438, lng: 19.6790162 },
    { lat: 48.893954, lng: 19.6789213 },
    { lat: 48.8929481, lng: 19.6786725 },
    { lat: 48.8917941, lng: 19.6777285 },
    { lat: 48.8903958, lng: 19.676812 },
    { lat: 48.889287, lng: 19.6765062 },
    { lat: 48.8881288, lng: 19.6757442 },
    { lat: 48.887748, lng: 19.6757589 },
    { lat: 48.8876027, lng: 19.6758446 },
    { lat: 48.8875323, lng: 19.6759082 },
    { lat: 48.8875111, lng: 19.6762474 },
    { lat: 48.8872928, lng: 19.6769131 },
    { lat: 48.8873159, lng: 19.6772526 },
    { lat: 48.8870174, lng: 19.6789561 },
    { lat: 48.8868732, lng: 19.6793332 },
    { lat: 48.8867332, lng: 19.6804713 },
    { lat: 48.8868549, lng: 19.6807496 },
    { lat: 48.8867589, lng: 19.6818366 },
    { lat: 48.8865477, lng: 19.6821779 },
    { lat: 48.8863812, lng: 19.6826131 },
    { lat: 48.8855551, lng: 19.6830654 },
    { lat: 48.8851545, lng: 19.6831307 },
    { lat: 48.8849193, lng: 19.6833094 },
    { lat: 48.8848345, lng: 19.6835844 },
    { lat: 48.8832852, lng: 19.6847951 },
    { lat: 48.8825124, lng: 19.6856132 },
    { lat: 48.8822127, lng: 19.6854361 },
    { lat: 48.8816566, lng: 19.6852699 },
    { lat: 48.8812628, lng: 19.6853809 },
    { lat: 48.8797, lng: 19.6852784 },
    { lat: 48.8792143, lng: 19.6849464 },
    { lat: 48.8787905, lng: 19.6847917 },
    { lat: 48.8785054, lng: 19.6846146 },
    { lat: 48.8775289, lng: 19.6844423 },
    { lat: 48.8773497, lng: 19.6847462 },
    { lat: 48.8770318, lng: 19.6847922 },
    { lat: 48.8767475, lng: 19.6850876 },
    { lat: 48.8764943, lng: 19.6856503 },
    { lat: 48.876397, lng: 19.6864033 },
    { lat: 48.87643, lng: 19.6872007 },
    { lat: 48.876217, lng: 19.6879615 },
    { lat: 48.8762378, lng: 19.6886048 },
    { lat: 48.8765168, lng: 19.6898638 },
    { lat: 48.8766367, lng: 19.6911942 },
    { lat: 48.8773352, lng: 19.6937113 },
    { lat: 48.8772386, lng: 19.6944295 },
    { lat: 48.8768994, lng: 19.6955239 },
    { lat: 48.8765718, lng: 19.6961901 },
    { lat: 48.8758119, lng: 19.6982615 },
    { lat: 48.8755245, lng: 19.6993523 },
    { lat: 48.8751896, lng: 19.6997812 },
    { lat: 48.8743647, lng: 19.7003892 },
    { lat: 48.8739657, lng: 19.7008156 },
    { lat: 48.8735603, lng: 19.7016353 },
    { lat: 48.8721279, lng: 19.7031034 },
    { lat: 48.8719074, lng: 19.7035396 },
    { lat: 48.871298, lng: 19.7044619 },
    { lat: 48.871523, lng: 19.7053685 },
    { lat: 48.8716319, lng: 19.7064072 },
    { lat: 48.8718073, lng: 19.7070096 },
    { lat: 48.872311, lng: 19.7074713 },
    { lat: 48.8730413, lng: 19.7089786 },
    { lat: 48.8734044, lng: 19.7099027 },
    { lat: 48.875148, lng: 19.7138221 },
    { lat: 48.8763352, lng: 19.7145799 },
    { lat: 48.8776915, lng: 19.7148578 },
    { lat: 48.8788639, lng: 19.7156105 },
    { lat: 48.8791196, lng: 19.7156658 },
    { lat: 48.8793475, lng: 19.7158496 },
    { lat: 48.8795383, lng: 19.7162676 },
    { lat: 48.8797871, lng: 19.7165921 },
    { lat: 48.8802161, lng: 19.7168446 },
    { lat: 48.880324, lng: 19.7173008 },
    { lat: 48.8817114, lng: 19.7174908 },
    { lat: 48.8815777, lng: 19.7196683 },
    { lat: 48.8814235, lng: 19.7254242 },
    { lat: 48.881473, lng: 19.7258297 },
    { lat: 48.8812063, lng: 19.727527 },
    { lat: 48.880564, lng: 19.7295725 },
    { lat: 48.880696, lng: 19.7302908 },
    { lat: 48.8807287, lng: 19.7308295 },
    { lat: 48.8808963, lng: 19.7311576 },
    { lat: 48.8813466, lng: 19.7323291 },
    { lat: 48.882218, lng: 19.7330532 },
    { lat: 48.8829109, lng: 19.7333919 },
    { lat: 48.883506, lng: 19.7333414 },
    { lat: 48.8838621, lng: 19.7331407 },
    { lat: 48.8843494, lng: 19.7339993 },
    { lat: 48.885402, lng: 19.7356198 },
    { lat: 48.8865324, lng: 19.7370939 },
  ],
  Sihla: [
    { lat: 48.6454353, lng: 19.6507975 },
    { lat: 48.6456004, lng: 19.6507343 },
    { lat: 48.6467088, lng: 19.6506737 },
    { lat: 48.6471926, lng: 19.6505376 },
    { lat: 48.6477114, lng: 19.6506232 },
    { lat: 48.6484903, lng: 19.6510046 },
    { lat: 48.6495543, lng: 19.6518383 },
    { lat: 48.6500756, lng: 19.6520309 },
    { lat: 48.6516145, lng: 19.6529811 },
    { lat: 48.6520964, lng: 19.6533509 },
    { lat: 48.6524171, lng: 19.6534884 },
    { lat: 48.6527309, lng: 19.6538295 },
    { lat: 48.6535361, lng: 19.6543673 },
    { lat: 48.6541491, lng: 19.6545961 },
    { lat: 48.6544743, lng: 19.6548401 },
    { lat: 48.6549293, lng: 19.6550071 },
    { lat: 48.6548953, lng: 19.6550819 },
    { lat: 48.655515, lng: 19.6552787 },
    { lat: 48.6555393, lng: 19.6552213 },
    { lat: 48.6558071, lng: 19.6553116 },
    { lat: 48.6584536, lng: 19.6553572 },
    { lat: 48.6590818, lng: 19.6553443 },
    { lat: 48.6598885, lng: 19.6551949 },
    { lat: 48.6611149, lng: 19.6546022 },
    { lat: 48.6615113, lng: 19.6541436 },
    { lat: 48.6624784, lng: 19.6534734 },
    { lat: 48.6629643, lng: 19.6530523 },
    { lat: 48.6637449, lng: 19.6533745 },
    { lat: 48.6648713, lng: 19.6543708 },
    { lat: 48.665896, lng: 19.6542557 },
    { lat: 48.6686202, lng: 19.6552808 },
    { lat: 48.6694064, lng: 19.6553943 },
    { lat: 48.6697772, lng: 19.6555493 },
    { lat: 48.6707072, lng: 19.6561857 },
    { lat: 48.6714845, lng: 19.657087 },
    { lat: 48.6723701, lng: 19.6583187 },
    { lat: 48.6726986, lng: 19.6593362 },
    { lat: 48.6728038, lng: 19.6600502 },
    { lat: 48.6727783, lng: 19.6605897 },
    { lat: 48.6729699, lng: 19.6600653 },
    { lat: 48.6734228, lng: 19.6592073 },
    { lat: 48.6745918, lng: 19.6573837 },
    { lat: 48.674668, lng: 19.6572791 },
    { lat: 48.6752252, lng: 19.6561546 },
    { lat: 48.6753788, lng: 19.6550843 },
    { lat: 48.6756074, lng: 19.6539735 },
    { lat: 48.6756396, lng: 19.6533398 },
    { lat: 48.6759437, lng: 19.6527543 },
    { lat: 48.6765848, lng: 19.6518509 },
    { lat: 48.6768218, lng: 19.6509335 },
    { lat: 48.6775726, lng: 19.6502592 },
    { lat: 48.6778189, lng: 19.6489978 },
    { lat: 48.6789649, lng: 19.6483959 },
    { lat: 48.6793511, lng: 19.6477385 },
    { lat: 48.6795499, lng: 19.6471708 },
    { lat: 48.6798583, lng: 19.6468141 },
    { lat: 48.6804937, lng: 19.6455566 },
    { lat: 48.6807307, lng: 19.6452621 },
    { lat: 48.6809461, lng: 19.6448699 },
    { lat: 48.6811683, lng: 19.6442698 },
    { lat: 48.6813346, lng: 19.6434577 },
    { lat: 48.6815075, lng: 19.6416154 },
    { lat: 48.6819622, lng: 19.6409255 },
    { lat: 48.682757, lng: 19.6401604 },
    { lat: 48.6829855, lng: 19.6391246 },
    { lat: 48.682834, lng: 19.639137 },
    { lat: 48.6828527, lng: 19.6384026 },
    { lat: 48.6826992, lng: 19.6367196 },
    { lat: 48.6827197, lng: 19.6346458 },
    { lat: 48.6823097, lng: 19.6341758 },
    { lat: 48.6817656, lng: 19.6316993 },
    { lat: 48.6812325, lng: 19.6314499 },
    { lat: 48.6811597, lng: 19.6305303 },
    { lat: 48.6814157, lng: 19.6286134 },
    { lat: 48.6817596, lng: 19.6275436 },
    { lat: 48.6822, lng: 19.6269367 },
    { lat: 48.6824728, lng: 19.6268385 },
    { lat: 48.6825654, lng: 19.62662 },
    { lat: 48.6826534, lng: 19.6261987 },
    { lat: 48.6829063, lng: 19.6257865 },
    { lat: 48.6831453, lng: 19.6246886 },
    { lat: 48.6832589, lng: 19.6244833 },
    { lat: 48.6832373, lng: 19.6240309 },
    { lat: 48.6829612, lng: 19.6229574 },
    { lat: 48.6829378, lng: 19.6219361 },
    { lat: 48.6826222, lng: 19.6211381 },
    { lat: 48.6827237, lng: 19.620765 },
    { lat: 48.6829079, lng: 19.6204254 },
    { lat: 48.6831533, lng: 19.6194434 },
    { lat: 48.6833359, lng: 19.6191008 },
    { lat: 48.6833362, lng: 19.6183801 },
    { lat: 48.6833833, lng: 19.6185044 },
    { lat: 48.6837006, lng: 19.6172956 },
    { lat: 48.6838711, lng: 19.6164019 },
    { lat: 48.6846259, lng: 19.6138811 },
    { lat: 48.68525, lng: 19.6132565 },
    { lat: 48.6857491, lng: 19.6121045 },
    { lat: 48.6860612, lng: 19.6110332 },
    { lat: 48.6861387, lng: 19.6099596 },
    { lat: 48.6868888, lng: 19.6087571 },
    { lat: 48.6871536, lng: 19.6078828 },
    { lat: 48.6870189, lng: 19.6070982 },
    { lat: 48.686836, lng: 19.6063964 },
    { lat: 48.6869025, lng: 19.6052722 },
    { lat: 48.6859315, lng: 19.6042845 },
    { lat: 48.6853297, lng: 19.6035885 },
    { lat: 48.6847251, lng: 19.6032458 },
    { lat: 48.6846462, lng: 19.6025268 },
    { lat: 48.6846911, lng: 19.6012031 },
    { lat: 48.6845695, lng: 19.6006362 },
    { lat: 48.6845568, lng: 19.6001564 },
    { lat: 48.6846149, lng: 19.5999236 },
    { lat: 48.6844724, lng: 19.5995878 },
    { lat: 48.6843877, lng: 19.5991351 },
    { lat: 48.684413, lng: 19.5986732 },
    { lat: 48.6843155, lng: 19.5980385 },
    { lat: 48.6840998, lng: 19.5976087 },
    { lat: 48.6840512, lng: 19.5971045 },
    { lat: 48.6840686, lng: 19.5968209 },
    { lat: 48.6838683, lng: 19.5963292 },
    { lat: 48.6836181, lng: 19.5954055 },
    { lat: 48.6836166, lng: 19.5951007 },
    { lat: 48.6837011, lng: 19.5947525 },
    { lat: 48.6839134, lng: 19.5942174 },
    { lat: 48.6839857, lng: 19.5933758 },
    { lat: 48.6841194, lng: 19.5927732 },
    { lat: 48.6842546, lng: 19.5924005 },
    { lat: 48.6844158, lng: 19.5915564 },
    { lat: 48.6845124, lng: 19.5904595 },
    { lat: 48.6842014, lng: 19.5898138 },
    { lat: 48.6841847, lng: 19.5894728 },
    { lat: 48.6841083, lng: 19.5892258 },
    { lat: 48.6841022, lng: 19.5888209 },
    { lat: 48.6839971, lng: 19.5885658 },
    { lat: 48.68313, lng: 19.5877071 },
    { lat: 48.6828819, lng: 19.5860186 },
    { lat: 48.6829273, lng: 19.5855521 },
    { lat: 48.6832989, lng: 19.5850004 },
    { lat: 48.6835381, lng: 19.5848144 },
    { lat: 48.683644, lng: 19.5845403 },
    { lat: 48.684345, lng: 19.5834491 },
    { lat: 48.6845588, lng: 19.5827241 },
    { lat: 48.684369, lng: 19.5826632 },
    { lat: 48.6842538, lng: 19.5824098 },
    { lat: 48.6841007, lng: 19.582885 },
    { lat: 48.6839677, lng: 19.5828845 },
    { lat: 48.6836858, lng: 19.5830297 },
    { lat: 48.6834736, lng: 19.5829479 },
    { lat: 48.6831762, lng: 19.5830975 },
    { lat: 48.6828703, lng: 19.5826635 },
    { lat: 48.6829235, lng: 19.5823748 },
    { lat: 48.6827211, lng: 19.5820606 },
    { lat: 48.6819512, lng: 19.5811352 },
    { lat: 48.682399, lng: 19.5811729 },
    { lat: 48.6825232, lng: 19.5808298 },
    { lat: 48.6825677, lng: 19.580495 },
    { lat: 48.6823384, lng: 19.5799393 },
    { lat: 48.6820233, lng: 19.5797245 },
    { lat: 48.6819445, lng: 19.5795298 },
    { lat: 48.6808196, lng: 19.579669 },
    { lat: 48.6805351, lng: 19.579313 },
    { lat: 48.6803139, lng: 19.5793802 },
    { lat: 48.6794257, lng: 19.5793476 },
    { lat: 48.6793977, lng: 19.5787835 },
    { lat: 48.6788561, lng: 19.5784222 },
    { lat: 48.6790527, lng: 19.5781094 },
    { lat: 48.6790039, lng: 19.5771136 },
    { lat: 48.6791209, lng: 19.5769099 },
    { lat: 48.6792989, lng: 19.5762917 },
    { lat: 48.6797512, lng: 19.5764505 },
    { lat: 48.6800119, lng: 19.5761866 },
    { lat: 48.6798995, lng: 19.5752516 },
    { lat: 48.67973, lng: 19.5745482 },
    { lat: 48.6792553, lng: 19.5742709 },
    { lat: 48.6790666, lng: 19.5740833 },
    { lat: 48.678944, lng: 19.573472 },
    { lat: 48.6795712, lng: 19.5729176 },
    { lat: 48.6798494, lng: 19.5740744 },
    { lat: 48.6805236, lng: 19.5746572 },
    { lat: 48.6808977, lng: 19.5752235 },
    { lat: 48.6810998, lng: 19.5751547 },
    { lat: 48.6812209, lng: 19.5748483 },
    { lat: 48.6818379, lng: 19.5746612 },
    { lat: 48.6821254, lng: 19.5753588 },
    { lat: 48.6826387, lng: 19.5761407 },
    { lat: 48.682798, lng: 19.5761245 },
    { lat: 48.6832419, lng: 19.5754026 },
    { lat: 48.6833279, lng: 19.5750153 },
    { lat: 48.6830219, lng: 19.5747811 },
    { lat: 48.6828264, lng: 19.5748389 },
    { lat: 48.6825704, lng: 19.5747135 },
    { lat: 48.6827178, lng: 19.5738659 },
    { lat: 48.682605, lng: 19.5736651 },
    { lat: 48.682616, lng: 19.5733152 },
    { lat: 48.6829454, lng: 19.5725581 },
    { lat: 48.683141, lng: 19.5724722 },
    { lat: 48.6831174, lng: 19.5719276 },
    { lat: 48.6828205, lng: 19.571522 },
    { lat: 48.6821999, lng: 19.5708847 },
    { lat: 48.6817089, lng: 19.571063 },
    { lat: 48.6814421, lng: 19.5709449 },
    { lat: 48.6812359, lng: 19.5709681 },
    { lat: 48.6809112, lng: 19.5707198 },
    { lat: 48.6811894, lng: 19.5700013 },
    { lat: 48.6814368, lng: 19.5699627 },
    { lat: 48.68165, lng: 19.5700461 },
    { lat: 48.6819646, lng: 19.5700013 },
    { lat: 48.6822228, lng: 19.5701745 },
    { lat: 48.6825884, lng: 19.5705594 },
    { lat: 48.6827681, lng: 19.5705165 },
    { lat: 48.6827775, lng: 19.5700601 },
    { lat: 48.6826037, lng: 19.5699313 },
    { lat: 48.6823371, lng: 19.5689519 },
    { lat: 48.6821295, lng: 19.5686722 },
    { lat: 48.6817026, lng: 19.5685536 },
    { lat: 48.6814487, lng: 19.568396 },
    { lat: 48.6811673, lng: 19.5680355 },
    { lat: 48.6810287, lng: 19.5677147 },
    { lat: 48.6811104, lng: 19.5673518 },
    { lat: 48.6818185, lng: 19.5670403 },
    { lat: 48.6820858, lng: 19.566801 },
    { lat: 48.6822133, lng: 19.5665592 },
    { lat: 48.6821565, lng: 19.5663008 },
    { lat: 48.6818446, lng: 19.5660594 },
    { lat: 48.6819967, lng: 19.5652427 },
    { lat: 48.6821888, lng: 19.5651567 },
    { lat: 48.6823132, lng: 19.5648924 },
    { lat: 48.6822984, lng: 19.5640505 },
    { lat: 48.6823687, lng: 19.5636906 },
    { lat: 48.6827994, lng: 19.5631272 },
    { lat: 48.6830977, lng: 19.5630983 },
    { lat: 48.6832318, lng: 19.5633208 },
    { lat: 48.683561, lng: 19.5619399 },
    { lat: 48.6835011, lng: 19.5614665 },
    { lat: 48.6836077, lng: 19.5608414 },
    { lat: 48.6835294, lng: 19.5603831 },
    { lat: 48.6831784, lng: 19.5599271 },
    { lat: 48.6835707, lng: 19.5592668 },
    { lat: 48.6838468, lng: 19.5590202 },
    { lat: 48.6838142, lng: 19.5584772 },
    { lat: 48.6842155, lng: 19.5582304 },
    { lat: 48.6846254, lng: 19.5583029 },
    { lat: 48.6849568, lng: 19.5584506 },
    { lat: 48.6854256, lng: 19.5591446 },
    { lat: 48.686501, lng: 19.5587062 },
    { lat: 48.6872581, lng: 19.5587543 },
    { lat: 48.6873858, lng: 19.559353 },
    { lat: 48.6876394, lng: 19.5595548 },
    { lat: 48.6876655, lng: 19.5597015 },
    { lat: 48.6881166, lng: 19.5598325 },
    { lat: 48.6883256, lng: 19.5594776 },
    { lat: 48.688407, lng: 19.5595059 },
    { lat: 48.6887444, lng: 19.5601355 },
    { lat: 48.689053, lng: 19.5605488 },
    { lat: 48.6892276, lng: 19.5604283 },
    { lat: 48.6892303, lng: 19.5600157 },
    { lat: 48.6892756, lng: 19.5598705 },
    { lat: 48.688999, lng: 19.5589014 },
    { lat: 48.6888742, lng: 19.5587585 },
    { lat: 48.6887998, lng: 19.5583351 },
    { lat: 48.6885471, lng: 19.5578371 },
    { lat: 48.6878175, lng: 19.5578504 },
    { lat: 48.6877925, lng: 19.5575038 },
    { lat: 48.6875753, lng: 19.5571117 },
    { lat: 48.6873939, lng: 19.5565222 },
    { lat: 48.687613, lng: 19.5561384 },
    { lat: 48.6872432, lng: 19.5560347 },
    { lat: 48.6867028, lng: 19.5554527 },
    { lat: 48.6863917, lng: 19.5554045 },
    { lat: 48.6863608, lng: 19.5560347 },
    { lat: 48.6859804, lng: 19.5560198 },
    { lat: 48.6859419, lng: 19.555695 },
    { lat: 48.6852292, lng: 19.5554141 },
    { lat: 48.6853695, lng: 19.5549808 },
    { lat: 48.685543, lng: 19.5549274 },
    { lat: 48.6855407, lng: 19.5545567 },
    { lat: 48.6850709, lng: 19.5538992 },
    { lat: 48.6851538, lng: 19.5536402 },
    { lat: 48.6843622, lng: 19.5516829 },
    { lat: 48.6842026, lng: 19.5515641 },
    { lat: 48.6838503, lng: 19.5514514 },
    { lat: 48.6835705, lng: 19.5510994 },
    { lat: 48.6833867, lng: 19.5514641 },
    { lat: 48.6823248, lng: 19.5524454 },
    { lat: 48.6816723, lng: 19.5535287 },
    { lat: 48.6812811, lng: 19.5542891 },
    { lat: 48.6810216, lng: 19.5545262 },
    { lat: 48.6796274, lng: 19.5552841 },
    { lat: 48.6794497, lng: 19.5556853 },
    { lat: 48.6792549, lng: 19.5564801 },
    { lat: 48.679015, lng: 19.5571943 },
    { lat: 48.6787175, lng: 19.5578355 },
    { lat: 48.6781018, lng: 19.5589123 },
    { lat: 48.6776526, lng: 19.559367 },
    { lat: 48.6770186, lng: 19.5591802 },
    { lat: 48.6764431, lng: 19.5597265 },
    { lat: 48.6753805, lng: 19.5598522 },
    { lat: 48.6750681, lng: 19.5597478 },
    { lat: 48.6747917, lng: 19.5597669 },
    { lat: 48.6738821, lng: 19.559636 },
    { lat: 48.6725992, lng: 19.5605347 },
    { lat: 48.671347, lng: 19.561197 },
    { lat: 48.6707979, lng: 19.5607209 },
    { lat: 48.6705547, lng: 19.5602082 },
    { lat: 48.6694416, lng: 19.5588546 },
    { lat: 48.6689128, lng: 19.5589239 },
    { lat: 48.6684931, lng: 19.5591348 },
    { lat: 48.6676901, lng: 19.5589703 },
    { lat: 48.6673357, lng: 19.5587591 },
    { lat: 48.666594, lng: 19.5581662 },
    { lat: 48.6646251, lng: 19.5576169 },
    { lat: 48.6642018, lng: 19.5578214 },
    { lat: 48.6639257, lng: 19.5577721 },
    { lat: 48.6629007, lng: 19.5582749 },
    { lat: 48.6626679, lng: 19.5581153 },
    { lat: 48.6623916, lng: 19.5582552 },
    { lat: 48.6619265, lng: 19.5583386 },
    { lat: 48.6615968, lng: 19.5580536 },
    { lat: 48.6611077, lng: 19.5578753 },
    { lat: 48.660725, lng: 19.5579858 },
    { lat: 48.6600411, lng: 19.5585542 },
    { lat: 48.6585331, lng: 19.560003 },
    { lat: 48.658312, lng: 19.5601205 },
    { lat: 48.657892, lng: 19.560208 },
    { lat: 48.658192, lng: 19.561413 },
    { lat: 48.658647, lng: 19.562561 },
    { lat: 48.658872, lng: 19.563644 },
    { lat: 48.658793, lng: 19.564388 },
    { lat: 48.658589, lng: 19.565672 },
    { lat: 48.658398, lng: 19.566519 },
    { lat: 48.657771, lng: 19.566876 },
    { lat: 48.657082, lng: 19.567837 },
    { lat: 48.656087, lng: 19.568407 },
    { lat: 48.655562, lng: 19.568977 },
    { lat: 48.655074, lng: 19.569885 },
    { lat: 48.654582, lng: 19.572172 },
    { lat: 48.654605, lng: 19.573581 },
    { lat: 48.654347, lng: 19.578254 },
    { lat: 48.653541, lng: 19.581062 },
    { lat: 48.653037, lng: 19.583508 },
    { lat: 48.65327, lng: 19.584485 },
    { lat: 48.653368, lng: 19.58641 },
    { lat: 48.65329, lng: 19.586889 },
    { lat: 48.65283, lng: 19.587766 },
    { lat: 48.652501, lng: 19.589112 },
    { lat: 48.652148, lng: 19.590297 },
    { lat: 48.652862, lng: 19.591915 },
    { lat: 48.653573, lng: 19.593118 },
    { lat: 48.652907, lng: 19.594301 },
    { lat: 48.652053, lng: 19.596638 },
    { lat: 48.651606, lng: 19.598629 },
    { lat: 48.651446, lng: 19.599115 },
    { lat: 48.651286, lng: 19.599749 },
    { lat: 48.650648, lng: 19.601654 },
    { lat: 48.649972, lng: 19.602725 },
    { lat: 48.649416, lng: 19.603478 },
    { lat: 48.648127, lng: 19.604129 },
    { lat: 48.647556, lng: 19.603556 },
    { lat: 48.647259, lng: 19.604486 },
    { lat: 48.64704, lng: 19.606012 },
    { lat: 48.646531, lng: 19.606632 },
    { lat: 48.645806, lng: 19.607574 },
    { lat: 48.645249, lng: 19.608978 },
    { lat: 48.645201, lng: 19.610095 },
    { lat: 48.645411, lng: 19.613242 },
    { lat: 48.645285, lng: 19.614139 },
    { lat: 48.645145, lng: 19.614685 },
    { lat: 48.645237, lng: 19.615778 },
    { lat: 48.645541, lng: 19.616419 },
    { lat: 48.645822, lng: 19.617181 },
    { lat: 48.646013, lng: 19.618311 },
    { lat: 48.646037, lng: 19.618942 },
    { lat: 48.645033, lng: 19.620772 },
    { lat: 48.644984, lng: 19.620868 },
    { lat: 48.644921, lng: 19.620939 },
    { lat: 48.644781, lng: 19.621095 },
    { lat: 48.644727, lng: 19.621156 },
    { lat: 48.644476, lng: 19.621404 },
    { lat: 48.643741, lng: 19.622063 },
    { lat: 48.64328, lng: 19.622716 },
    { lat: 48.64293, lng: 19.623521 },
    { lat: 48.64296, lng: 19.625935 },
    { lat: 48.642873, lng: 19.626144 },
    { lat: 48.642598, lng: 19.626915 },
    { lat: 48.642394, lng: 19.627473 },
    { lat: 48.642359, lng: 19.627569 },
    { lat: 48.642254, lng: 19.627902 },
    { lat: 48.642151, lng: 19.628307 },
    { lat: 48.642089, lng: 19.628629 },
    { lat: 48.642042, lng: 19.628976 },
    { lat: 48.641858, lng: 19.629525 },
    { lat: 48.641877, lng: 19.630341 },
    { lat: 48.642364, lng: 19.632093 },
    { lat: 48.642405, lng: 19.632886 },
    { lat: 48.641827, lng: 19.633768 },
    { lat: 48.641579, lng: 19.634608 },
    { lat: 48.642659, lng: 19.638086 },
    { lat: 48.642719, lng: 19.638731 },
    { lat: 48.642413, lng: 19.641101 },
    { lat: 48.642406, lng: 19.641381 },
    { lat: 48.642562, lng: 19.642677 },
    { lat: 48.642645, lng: 19.643214 },
    { lat: 48.643012, lng: 19.644215 },
    { lat: 48.6435194, lng: 19.6450675 },
    { lat: 48.6436399, lng: 19.6453694 },
    { lat: 48.6437264, lng: 19.645864 },
    { lat: 48.6436984, lng: 19.6467701 },
    { lat: 48.6441639, lng: 19.6469718 },
    { lat: 48.6441269, lng: 19.6470526 },
    { lat: 48.644152, lng: 19.6472592 },
    { lat: 48.6444258, lng: 19.6471845 },
    { lat: 48.6445558, lng: 19.6483224 },
    { lat: 48.6446114, lng: 19.6483085 },
    { lat: 48.6446797, lng: 19.6489258 },
    { lat: 48.644092, lng: 19.6492877 },
    { lat: 48.6446556, lng: 19.6504967 },
    { lat: 48.6452797, lng: 19.6508451 },
    { lat: 48.6454353, lng: 19.6507975 },
  ],
  Telgárt: [
    { lat: 48.796091, lng: 20.168255 },
    { lat: 48.796114, lng: 20.168861 },
    { lat: 48.795839, lng: 20.169925 },
    { lat: 48.795094, lng: 20.170949 },
    { lat: 48.795009, lng: 20.171069 },
    { lat: 48.794871, lng: 20.171231 },
    { lat: 48.794365, lng: 20.171828 },
    { lat: 48.793948, lng: 20.17233 },
    { lat: 48.793484, lng: 20.172887 },
    { lat: 48.793139, lng: 20.173239 },
    { lat: 48.793079, lng: 20.173299 },
    { lat: 48.792589, lng: 20.173807 },
    { lat: 48.792314, lng: 20.174033 },
    { lat: 48.792138, lng: 20.174177 },
    { lat: 48.79186, lng: 20.174378 },
    { lat: 48.791532, lng: 20.174614 },
    { lat: 48.791287, lng: 20.174927 },
    { lat: 48.79101, lng: 20.175224 },
    { lat: 48.790757, lng: 20.17563 },
    { lat: 48.790578, lng: 20.176002 },
    { lat: 48.790339, lng: 20.176293 },
    { lat: 48.790117, lng: 20.17646 },
    { lat: 48.789709, lng: 20.176647 },
    { lat: 48.78925, lng: 20.176689 },
    { lat: 48.7891, lng: 20.176799 },
    { lat: 48.788995, lng: 20.176985 },
    { lat: 48.788938, lng: 20.177247 },
    { lat: 48.788898, lng: 20.177994 },
    { lat: 48.788852, lng: 20.178883 },
    { lat: 48.789249, lng: 20.180636 },
    { lat: 48.789402, lng: 20.181602 },
    { lat: 48.789143, lng: 20.183069 },
    { lat: 48.789347, lng: 20.184311 },
    { lat: 48.78944, lng: 20.185046 },
    { lat: 48.7895, lng: 20.18594 },
    { lat: 48.789292, lng: 20.186538 },
    { lat: 48.789388, lng: 20.187185 },
    { lat: 48.789494, lng: 20.187956 },
    { lat: 48.789664, lng: 20.188648 },
    { lat: 48.789924, lng: 20.189569 },
    { lat: 48.789995, lng: 20.190358 },
    { lat: 48.790226, lng: 20.19287 },
    { lat: 48.790588, lng: 20.194319 },
    { lat: 48.791533, lng: 20.194765 },
    { lat: 48.792034, lng: 20.195513 },
    { lat: 48.792561, lng: 20.19643 },
    { lat: 48.793109, lng: 20.197035 },
    { lat: 48.793168, lng: 20.197099 },
    { lat: 48.793538, lng: 20.197953 },
    { lat: 48.795268, lng: 20.199844 },
    { lat: 48.795383, lng: 20.199895 },
    { lat: 48.795455, lng: 20.199928 },
    { lat: 48.795682, lng: 20.20003 },
    { lat: 48.796452, lng: 20.200378 },
    { lat: 48.797871, lng: 20.198545 },
    { lat: 48.797898, lng: 20.198507 },
    { lat: 48.798424, lng: 20.197786 },
    { lat: 48.799512, lng: 20.196099 },
    { lat: 48.800391, lng: 20.195522 },
    { lat: 48.801464, lng: 20.19532 },
    { lat: 48.803709, lng: 20.196995 },
    { lat: 48.805159, lng: 20.198811 },
    { lat: 48.806332, lng: 20.20032 },
    { lat: 48.806976, lng: 20.200679 },
    { lat: 48.80719, lng: 20.200518 },
    { lat: 48.808415, lng: 20.199595 },
    { lat: 48.809764, lng: 20.198553 },
    { lat: 48.809958, lng: 20.198403 },
    { lat: 48.81145, lng: 20.197565 },
    { lat: 48.81271, lng: 20.197473 },
    { lat: 48.815263, lng: 20.196719 },
    { lat: 48.81669, lng: 20.196894 },
    { lat: 48.817646, lng: 20.197039 },
    { lat: 48.818585, lng: 20.197184 },
    { lat: 48.818796, lng: 20.197591 },
    { lat: 48.819107, lng: 20.19819 },
    { lat: 48.819335, lng: 20.198628 },
    { lat: 48.819834, lng: 20.199592 },
    { lat: 48.819982, lng: 20.199877 },
    { lat: 48.820447, lng: 20.200854 },
    { lat: 48.82045, lng: 20.20086 },
    { lat: 48.820487, lng: 20.200937 },
    { lat: 48.820954, lng: 20.20192 },
    { lat: 48.821467, lng: 20.202872 },
    { lat: 48.821553, lng: 20.203033 },
    { lat: 48.822013, lng: 20.203886 },
    { lat: 48.822607, lng: 20.204979 },
    { lat: 48.823127, lng: 20.206002 },
    { lat: 48.823838, lng: 20.207401 },
    { lat: 48.824576, lng: 20.208855 },
    { lat: 48.825238, lng: 20.210204 },
    { lat: 48.825739, lng: 20.211077 },
    { lat: 48.826224, lng: 20.211903 },
    { lat: 48.826862, lng: 20.212989 },
    { lat: 48.827527, lng: 20.213998 },
    { lat: 48.82769, lng: 20.214247 },
    { lat: 48.827927, lng: 20.214603 },
    { lat: 48.828272, lng: 20.215116 },
    { lat: 48.828862, lng: 20.215988 },
    { lat: 48.829411, lng: 20.216328 },
    { lat: 48.830346, lng: 20.216906 },
    { lat: 48.831921, lng: 20.217815 },
    { lat: 48.833805, lng: 20.218653 },
    { lat: 48.834408, lng: 20.220379 },
    { lat: 48.835175, lng: 20.224328 },
    { lat: 48.835415, lng: 20.224831 },
    { lat: 48.835571, lng: 20.225155 },
    { lat: 48.83614, lng: 20.226463 },
    { lat: 48.837089, lng: 20.229076 },
    { lat: 48.837611, lng: 20.230729 },
    { lat: 48.837902, lng: 20.230933 },
    { lat: 48.838282, lng: 20.231211 },
    { lat: 48.839169, lng: 20.231829 },
    { lat: 48.840769, lng: 20.232304 },
    { lat: 48.84096, lng: 20.232362 },
    { lat: 48.841237, lng: 20.232584 },
    { lat: 48.841348, lng: 20.232671 },
    { lat: 48.841522, lng: 20.23281 },
    { lat: 48.841706, lng: 20.232956 },
    { lat: 48.841908, lng: 20.233118 },
    { lat: 48.842061, lng: 20.233241 },
    { lat: 48.842222, lng: 20.233371 },
    { lat: 48.842446, lng: 20.233552 },
    { lat: 48.842605, lng: 20.233678 },
    { lat: 48.842768, lng: 20.233813 },
    { lat: 48.843143, lng: 20.234537 },
    { lat: 48.843219, lng: 20.235046 },
    { lat: 48.843267, lng: 20.235379 },
    { lat: 48.843321, lng: 20.235651 },
    { lat: 48.843514, lng: 20.236496 },
    { lat: 48.843516, lng: 20.236761 },
    { lat: 48.843517, lng: 20.237125 },
    { lat: 48.843519, lng: 20.237462 },
    { lat: 48.843704, lng: 20.237869 },
    { lat: 48.843806, lng: 20.238241 },
    { lat: 48.843811, lng: 20.238421 },
    { lat: 48.843801, lng: 20.238625 },
    { lat: 48.843837, lng: 20.238824 },
    { lat: 48.843898, lng: 20.238989 },
    { lat: 48.843923, lng: 20.239434 },
    { lat: 48.843946, lng: 20.239526 },
    { lat: 48.844036, lng: 20.239694 },
    { lat: 48.8440853, lng: 20.2399065 },
    { lat: 48.844125, lng: 20.2400484 },
    { lat: 48.844174, lng: 20.240195 },
    { lat: 48.844296, lng: 20.240524 },
    { lat: 48.844377, lng: 20.24062 },
    { lat: 48.844495, lng: 20.241007 },
    { lat: 48.844791, lng: 20.241258 },
    { lat: 48.84514, lng: 20.24124 },
    { lat: 48.845195, lng: 20.241255 },
    { lat: 48.845226, lng: 20.241256 },
    { lat: 48.845251, lng: 20.241274 },
    { lat: 48.845428, lng: 20.241404 },
    { lat: 48.845675, lng: 20.241523 },
    { lat: 48.84588, lng: 20.241434 },
    { lat: 48.846096, lng: 20.241345 },
    { lat: 48.846279, lng: 20.241265 },
    { lat: 48.846547, lng: 20.241152 },
    { lat: 48.846677, lng: 20.240976 },
    { lat: 48.846782, lng: 20.240952 },
    { lat: 48.846938, lng: 20.241011 },
    { lat: 48.847117, lng: 20.241008 },
    { lat: 48.847164, lng: 20.240956 },
    { lat: 48.847337, lng: 20.240944 },
    { lat: 48.847673, lng: 20.240832 },
    { lat: 48.848153, lng: 20.240796 },
    { lat: 48.848309, lng: 20.240836 },
    { lat: 48.848451, lng: 20.240787 },
    { lat: 48.848515, lng: 20.240772 },
    { lat: 48.848645, lng: 20.240743 },
    { lat: 48.848828, lng: 20.24073 },
    { lat: 48.84895, lng: 20.240764 },
    { lat: 48.849137, lng: 20.240817 },
    { lat: 48.849279, lng: 20.24087 },
    { lat: 48.849453, lng: 20.240937 },
    { lat: 48.849521, lng: 20.240972 },
    { lat: 48.849589, lng: 20.241008 },
    { lat: 48.849637, lng: 20.241034 },
    { lat: 48.849727, lng: 20.241109 },
    { lat: 48.849874, lng: 20.24125 },
    { lat: 48.849991, lng: 20.241318 },
    { lat: 48.850221, lng: 20.241448 },
    { lat: 48.850319, lng: 20.241433 },
    { lat: 48.850364, lng: 20.241499 },
    { lat: 48.850384, lng: 20.241568 },
    { lat: 48.850404, lng: 20.241648 },
    { lat: 48.850465, lng: 20.241735 },
    { lat: 48.850786, lng: 20.241807 },
    { lat: 48.85095, lng: 20.24186 },
    { lat: 48.851273, lng: 20.241972 },
    { lat: 48.85151, lng: 20.242051 },
    { lat: 48.851587, lng: 20.242025 },
    { lat: 48.851646, lng: 20.242093 },
    { lat: 48.851693, lng: 20.242176 },
    { lat: 48.851721, lng: 20.242177 },
    { lat: 48.85182, lng: 20.242083 },
    { lat: 48.852012, lng: 20.242048 },
    { lat: 48.852064, lng: 20.24205 },
    { lat: 48.852141, lng: 20.242061 },
    { lat: 48.852206, lng: 20.242066 },
    { lat: 48.85237, lng: 20.242141 },
    { lat: 48.852421, lng: 20.242148 },
    { lat: 48.852522, lng: 20.242041 },
    { lat: 48.852659, lng: 20.241955 },
    { lat: 48.852741, lng: 20.241985 },
    { lat: 48.852969, lng: 20.241872 },
    { lat: 48.853125, lng: 20.241836 },
    { lat: 48.853176, lng: 20.241917 },
    { lat: 48.853325, lng: 20.242008 },
    { lat: 48.853382, lng: 20.242043 },
    { lat: 48.853433, lng: 20.242118 },
    { lat: 48.853711, lng: 20.242118 },
    { lat: 48.853891, lng: 20.2422 },
    { lat: 48.854047, lng: 20.242464 },
    { lat: 48.85415, lng: 20.242477 },
    { lat: 48.854263, lng: 20.242542 },
    { lat: 48.854369, lng: 20.242573 },
    { lat: 48.854639, lng: 20.242745 },
    { lat: 48.854665, lng: 20.242778 },
    { lat: 48.854821, lng: 20.242864 },
    { lat: 48.855046, lng: 20.242855 },
    { lat: 48.855185, lng: 20.242726 },
    { lat: 48.855497, lng: 20.242682 },
    { lat: 48.855826, lng: 20.242574 },
    { lat: 48.855977, lng: 20.242402 },
    { lat: 48.85614, lng: 20.242311 },
    { lat: 48.856154, lng: 20.242287 },
    { lat: 48.856168, lng: 20.242242 },
    { lat: 48.856439, lng: 20.242105 },
    { lat: 48.856934, lng: 20.242292 },
    { lat: 48.857043, lng: 20.242287 },
    { lat: 48.857088, lng: 20.242267 },
    { lat: 48.85711, lng: 20.242286 },
    { lat: 48.857129, lng: 20.24245 },
    { lat: 48.857167, lng: 20.242501 },
    { lat: 48.857192, lng: 20.242505 },
    { lat: 48.857371, lng: 20.242557 },
    { lat: 48.857421, lng: 20.242521 },
    { lat: 48.85747, lng: 20.242523 },
    { lat: 48.857662, lng: 20.242714 },
    { lat: 48.857823, lng: 20.242747 },
    { lat: 48.858017, lng: 20.242793 },
    { lat: 48.85809, lng: 20.242814 },
    { lat: 48.858363, lng: 20.242986 },
    { lat: 48.858504, lng: 20.243043 },
    { lat: 48.858608, lng: 20.243115 },
    { lat: 48.858686, lng: 20.243132 },
    { lat: 48.858776, lng: 20.243158 },
    { lat: 48.858931, lng: 20.243152 },
    { lat: 48.859038, lng: 20.24315 },
    { lat: 48.859308, lng: 20.243318 },
    { lat: 48.859565, lng: 20.243275 },
    { lat: 48.859877, lng: 20.243193 },
    { lat: 48.859991, lng: 20.243097 },
    { lat: 48.86014, lng: 20.243047 },
    { lat: 48.860377, lng: 20.242975 },
    { lat: 48.860617, lng: 20.243004 },
    { lat: 48.860707, lng: 20.24307 },
    { lat: 48.860853, lng: 20.243179 },
    { lat: 48.860994, lng: 20.243254 },
    { lat: 48.861166, lng: 20.243452 },
    { lat: 48.861368, lng: 20.243777 },
    { lat: 48.861392, lng: 20.243955 },
    { lat: 48.861662, lng: 20.244246 },
    { lat: 48.86176, lng: 20.24439 },
    { lat: 48.861862, lng: 20.244601 },
    { lat: 48.86197, lng: 20.244712 },
    { lat: 48.862173, lng: 20.244749 },
    { lat: 48.862303, lng: 20.244896 },
    { lat: 48.862407, lng: 20.2451 },
    { lat: 48.86258, lng: 20.245278 },
    { lat: 48.862917, lng: 20.245252 },
    { lat: 48.863111, lng: 20.24521 },
    { lat: 48.863218, lng: 20.245134 },
    { lat: 48.863308, lng: 20.245075 },
    { lat: 48.863353, lng: 20.24498 },
    { lat: 48.863508, lng: 20.244948 },
    { lat: 48.863747, lng: 20.244808 },
    { lat: 48.863848, lng: 20.244756 },
    { lat: 48.864165, lng: 20.244621 },
    { lat: 48.864557, lng: 20.244477 },
    { lat: 48.86474, lng: 20.244606 },
    { lat: 48.864927, lng: 20.244524 },
    { lat: 48.865067, lng: 20.24461 },
    { lat: 48.865335, lng: 20.244758 },
    { lat: 48.865439, lng: 20.244724 },
    { lat: 48.865676, lng: 20.244759 },
    { lat: 48.865834, lng: 20.244958 },
    { lat: 48.865893, lng: 20.244985 },
    { lat: 48.865945, lng: 20.245028 },
    { lat: 48.866389, lng: 20.245503 },
    { lat: 48.866586, lng: 20.24571 },
    { lat: 48.866784, lng: 20.246123 },
    { lat: 48.867163, lng: 20.246481 },
    { lat: 48.867532, lng: 20.246948 },
    { lat: 48.868012, lng: 20.247141 },
    { lat: 48.868718, lng: 20.247905 },
    { lat: 48.869298, lng: 20.247849 },
    { lat: 48.869519, lng: 20.247925 },
    { lat: 48.869871, lng: 20.248058 },
    { lat: 48.870037, lng: 20.248111 },
    { lat: 48.870121, lng: 20.248137 },
    { lat: 48.870441, lng: 20.24824 },
    { lat: 48.870508, lng: 20.248261 },
    { lat: 48.870518, lng: 20.248263 },
    { lat: 48.870506, lng: 20.248579 },
    { lat: 48.870499, lng: 20.248897 },
    { lat: 48.870467, lng: 20.249532 },
    { lat: 48.870608, lng: 20.250036 },
    { lat: 48.870647, lng: 20.250083 },
    { lat: 48.87068, lng: 20.250206 },
    { lat: 48.870653, lng: 20.250257 },
    { lat: 48.870659, lng: 20.250407 },
    { lat: 48.870632, lng: 20.250729 },
    { lat: 48.870678, lng: 20.250969 },
    { lat: 48.870634, lng: 20.251197 },
    { lat: 48.870582, lng: 20.25131 },
    { lat: 48.870447, lng: 20.251514 },
    { lat: 48.870423, lng: 20.25157 },
    { lat: 48.870452, lng: 20.251807 },
    { lat: 48.870469, lng: 20.252012 },
    { lat: 48.870424, lng: 20.252181 },
    { lat: 48.870373, lng: 20.252353 },
    { lat: 48.870321, lng: 20.252465 },
    { lat: 48.870263, lng: 20.252506 },
    { lat: 48.870182, lng: 20.252606 },
    { lat: 48.870211, lng: 20.252704 },
    { lat: 48.870246, lng: 20.25303 },
    { lat: 48.870319, lng: 20.253234 },
    { lat: 48.870306, lng: 20.253536 },
    { lat: 48.870349, lng: 20.253811 },
    { lat: 48.870324, lng: 20.25405 },
    { lat: 48.870247, lng: 20.254181 },
    { lat: 48.870217, lng: 20.254382 },
    { lat: 48.87014, lng: 20.254551 },
    { lat: 48.870084, lng: 20.25468 },
    { lat: 48.870082, lng: 20.254706 },
    { lat: 48.870085, lng: 20.254732 },
    { lat: 48.8702, lng: 20.254883 },
    { lat: 48.870122, lng: 20.255178 },
    { lat: 48.870136, lng: 20.25557 },
    { lat: 48.870001, lng: 20.256058 },
    { lat: 48.869901, lng: 20.256128 },
    { lat: 48.870053, lng: 20.256718 },
    { lat: 48.870074, lng: 20.256889 },
    { lat: 48.870115, lng: 20.257286 },
    { lat: 48.870076, lng: 20.257442 },
    { lat: 48.870142, lng: 20.25769 },
    { lat: 48.870106, lng: 20.257763 },
    { lat: 48.870074, lng: 20.257803 },
    { lat: 48.870001, lng: 20.257893 },
    { lat: 48.869933, lng: 20.258022 },
    { lat: 48.870044, lng: 20.258354 },
    { lat: 48.870076, lng: 20.258459 },
    { lat: 48.870088, lng: 20.258537 },
    { lat: 48.870086, lng: 20.258581 },
    { lat: 48.870083, lng: 20.258641 },
    { lat: 48.870025, lng: 20.258833 },
    { lat: 48.870003, lng: 20.259206 },
    { lat: 48.870133, lng: 20.259398 },
    { lat: 48.870204, lng: 20.259557 },
    { lat: 48.870319, lng: 20.259817 },
    { lat: 48.87042, lng: 20.260006 },
    { lat: 48.870407, lng: 20.260275 },
    { lat: 48.870477, lng: 20.260332 },
    { lat: 48.870564, lng: 20.260939 },
    { lat: 48.870543, lng: 20.261123 },
    { lat: 48.870722, lng: 20.261136 },
    { lat: 48.870883, lng: 20.261332 },
    { lat: 48.87089, lng: 20.261453 },
    { lat: 48.870967, lng: 20.261617 },
    { lat: 48.871062, lng: 20.261759 },
    { lat: 48.871083, lng: 20.261915 },
    { lat: 48.871199, lng: 20.262151 },
    { lat: 48.871293, lng: 20.262507 },
    { lat: 48.871306, lng: 20.262782 },
    { lat: 48.871385, lng: 20.262982 },
    { lat: 48.871492, lng: 20.26326 },
    { lat: 48.871645, lng: 20.263373 },
    { lat: 48.871694, lng: 20.263602 },
    { lat: 48.871747, lng: 20.263912 },
    { lat: 48.871845, lng: 20.264 },
    { lat: 48.871971, lng: 20.264117 },
    { lat: 48.872055, lng: 20.264211 },
    { lat: 48.872215, lng: 20.264373 },
    { lat: 48.872277, lng: 20.264545 },
    { lat: 48.872353, lng: 20.264651 },
    { lat: 48.872579, lng: 20.264971 },
    { lat: 48.872675, lng: 20.265115 },
    { lat: 48.872707, lng: 20.265202 },
    { lat: 48.872749, lng: 20.265315 },
    { lat: 48.87283, lng: 20.2655 },
    { lat: 48.872914, lng: 20.265648 },
    { lat: 48.873014, lng: 20.265808 },
    { lat: 48.87312, lng: 20.265976 },
    { lat: 48.873158, lng: 20.266037 },
    { lat: 48.873224, lng: 20.266179 },
    { lat: 48.873277, lng: 20.266459 },
    { lat: 48.87343, lng: 20.266388 },
    { lat: 48.873629, lng: 20.266516 },
    { lat: 48.873786, lng: 20.266889 },
    { lat: 48.873878, lng: 20.267238 },
    { lat: 48.874057, lng: 20.26744 },
    { lat: 48.874075, lng: 20.267459 },
    { lat: 48.874576, lng: 20.266623 },
    { lat: 48.874861, lng: 20.265726 },
    { lat: 48.87505, lng: 20.264924 },
    { lat: 48.875206, lng: 20.264437 },
    { lat: 48.875356, lng: 20.264056 },
    { lat: 48.875532, lng: 20.26351 },
    { lat: 48.87567, lng: 20.263138 },
    { lat: 48.875777, lng: 20.26277 },
    { lat: 48.876466, lng: 20.261795 },
    { lat: 48.877149, lng: 20.260989 },
    { lat: 48.877611, lng: 20.260381 },
    { lat: 48.878165, lng: 20.259891 },
    { lat: 48.878388, lng: 20.259663 },
    { lat: 48.878494, lng: 20.259491 },
    { lat: 48.87876, lng: 20.259122 },
    { lat: 48.879501, lng: 20.258226 },
    { lat: 48.879636, lng: 20.257454 },
    { lat: 48.879658, lng: 20.257014 },
    { lat: 48.879777, lng: 20.256777 },
    { lat: 48.879975, lng: 20.256569 },
    { lat: 48.880166, lng: 20.255926 },
    { lat: 48.880221, lng: 20.25563 },
    { lat: 48.880352, lng: 20.255287 },
    { lat: 48.880342, lng: 20.255162 },
    { lat: 48.880298, lng: 20.255062 },
    { lat: 48.88017, lng: 20.254859 },
    { lat: 48.87991, lng: 20.254479 },
    { lat: 48.87989, lng: 20.254391 },
    { lat: 48.879912, lng: 20.254254 },
    { lat: 48.879952, lng: 20.254162 },
    { lat: 48.880088, lng: 20.254028 },
    { lat: 48.88042, lng: 20.253073 },
    { lat: 48.88067, lng: 20.252776 },
    { lat: 48.880825, lng: 20.252608 },
    { lat: 48.881008, lng: 20.252461 },
    { lat: 48.881115, lng: 20.25221 },
    { lat: 48.88126, lng: 20.252009 },
    { lat: 48.881401, lng: 20.251761 },
    { lat: 48.881606, lng: 20.251707 },
    { lat: 48.881853, lng: 20.251689 },
    { lat: 48.882086, lng: 20.251493 },
    { lat: 48.882129, lng: 20.251441 },
    { lat: 48.882186, lng: 20.251249 },
    { lat: 48.882426, lng: 20.251252 },
    { lat: 48.882516, lng: 20.251208 },
    { lat: 48.88254, lng: 20.250987 },
    { lat: 48.882633, lng: 20.250949 },
    { lat: 48.882746, lng: 20.250902 },
    { lat: 48.88296, lng: 20.250167 },
    { lat: 48.883347, lng: 20.249972 },
    { lat: 48.8835, lng: 20.249852 },
    { lat: 48.883787, lng: 20.249415 },
    { lat: 48.883797, lng: 20.249327 },
    { lat: 48.883696, lng: 20.248992 },
    { lat: 48.88373, lng: 20.248738 },
    { lat: 48.883827, lng: 20.24838 },
    { lat: 48.883948, lng: 20.24821 },
    { lat: 48.884175, lng: 20.24812 },
    { lat: 48.884313, lng: 20.247834 },
    { lat: 48.884399, lng: 20.247729 },
    { lat: 48.884438, lng: 20.247656 },
    { lat: 48.884461, lng: 20.247592 },
    { lat: 48.884536, lng: 20.247216 },
    { lat: 48.884804, lng: 20.246783 },
    { lat: 48.88477, lng: 20.246575 },
    { lat: 48.884688, lng: 20.246485 },
    { lat: 48.884677, lng: 20.24608 },
    { lat: 48.884618, lng: 20.245901 },
    { lat: 48.884639, lng: 20.245801 },
    { lat: 48.884712, lng: 20.245715 },
    { lat: 48.884739, lng: 20.245529 },
    { lat: 48.884866, lng: 20.24552 },
    { lat: 48.884962, lng: 20.245457 },
    { lat: 48.885159, lng: 20.2451 },
    { lat: 48.88525, lng: 20.244585 },
    { lat: 48.885247, lng: 20.244281 },
    { lat: 48.88529, lng: 20.244034 },
    { lat: 48.885449, lng: 20.243679 },
    { lat: 48.885546, lng: 20.243338 },
    { lat: 48.885469, lng: 20.243045 },
    { lat: 48.885387, lng: 20.242804 },
    { lat: 48.885141, lng: 20.24219 },
    { lat: 48.885128, lng: 20.241666 },
    { lat: 48.885052, lng: 20.24126 },
    { lat: 48.884986, lng: 20.240868 },
    { lat: 48.884916, lng: 20.240491 },
    { lat: 48.88498, lng: 20.240237 },
    { lat: 48.884992, lng: 20.240058 },
    { lat: 48.884956, lng: 20.239911 },
    { lat: 48.885049, lng: 20.239739 },
    { lat: 48.885106, lng: 20.239599 },
    { lat: 48.885122, lng: 20.239481 },
    { lat: 48.885124, lng: 20.239347 },
    { lat: 48.885126, lng: 20.239222 },
    { lat: 48.88513, lng: 20.239103 },
    { lat: 48.88515, lng: 20.238976 },
    { lat: 48.885141, lng: 20.238809 },
    { lat: 48.885088, lng: 20.238444 },
    { lat: 48.885039, lng: 20.238217 },
    { lat: 48.884962, lng: 20.237982 },
    { lat: 48.884935, lng: 20.237886 },
    { lat: 48.884902, lng: 20.237749 },
    { lat: 48.884881, lng: 20.237642 },
    { lat: 48.884914, lng: 20.237585 },
    { lat: 48.8849, lng: 20.237442 },
    { lat: 48.884885, lng: 20.237324 },
    { lat: 48.884896, lng: 20.237243 },
    { lat: 48.884994, lng: 20.23717 },
    { lat: 48.884955, lng: 20.236979 },
    { lat: 48.884937, lng: 20.236786 },
    { lat: 48.884991, lng: 20.236671 },
    { lat: 48.885075, lng: 20.236324 },
    { lat: 48.885143, lng: 20.236206 },
    { lat: 48.88528, lng: 20.236194 },
    { lat: 48.885316, lng: 20.236092 },
    { lat: 48.885324, lng: 20.235952 },
    { lat: 48.88546, lng: 20.235615 },
    { lat: 48.885463, lng: 20.235491 },
    { lat: 48.885482, lng: 20.235396 },
    { lat: 48.885447, lng: 20.235216 },
    { lat: 48.885521, lng: 20.23511 },
    { lat: 48.88552, lng: 20.234976 },
    { lat: 48.885592, lng: 20.234872 },
    { lat: 48.885586, lng: 20.234704 },
    { lat: 48.885622, lng: 20.234453 },
    { lat: 48.885738, lng: 20.234393 },
    { lat: 48.885762, lng: 20.234265 },
    { lat: 48.885826, lng: 20.234183 },
    { lat: 48.885821, lng: 20.234072 },
    { lat: 48.885828, lng: 20.234013 },
    { lat: 48.885914, lng: 20.233946 },
    { lat: 48.885928, lng: 20.233858 },
    { lat: 48.885927, lng: 20.233677 },
    { lat: 48.886035, lng: 20.233587 },
    { lat: 48.886162, lng: 20.233558 },
    { lat: 48.88622, lng: 20.233458 },
    { lat: 48.886247, lng: 20.233339 },
    { lat: 48.886281, lng: 20.233221 },
    { lat: 48.886298, lng: 20.233108 },
    { lat: 48.88637, lng: 20.233033 },
    { lat: 48.886374, lng: 20.23297 },
    { lat: 48.88633, lng: 20.23289 },
    { lat: 48.886316, lng: 20.232722 },
    { lat: 48.886381, lng: 20.232485 },
    { lat: 48.886431, lng: 20.232348 },
    { lat: 48.886435, lng: 20.232202 },
    { lat: 48.88658, lng: 20.231852 },
    { lat: 48.886735, lng: 20.231696 },
    { lat: 48.886813, lng: 20.231468 },
    { lat: 48.886914, lng: 20.231233 },
    { lat: 48.886936, lng: 20.231026 },
    { lat: 48.887192, lng: 20.230699 },
    { lat: 48.887206, lng: 20.230555 },
    { lat: 48.887273, lng: 20.23036 },
    { lat: 48.887361, lng: 20.229933 },
    { lat: 48.887431, lng: 20.229647 },
    { lat: 48.887534, lng: 20.229415 },
    { lat: 48.887674, lng: 20.229041 },
    { lat: 48.887738, lng: 20.228796 },
    { lat: 48.887848, lng: 20.228561 },
    { lat: 48.888, lng: 20.228392 },
    { lat: 48.888348, lng: 20.228392 },
    { lat: 48.888497, lng: 20.228168 },
    { lat: 48.888783, lng: 20.227572 },
    { lat: 48.889105, lng: 20.227303 },
    { lat: 48.889231, lng: 20.227119 },
    { lat: 48.889336, lng: 20.226928 },
    { lat: 48.889434, lng: 20.226712 },
    { lat: 48.889722, lng: 20.226245 },
    { lat: 48.88998, lng: 20.225914 },
    { lat: 48.890318, lng: 20.225361 },
    { lat: 48.89047, lng: 20.225174 },
    { lat: 48.890796, lng: 20.224655 },
    { lat: 48.890876, lng: 20.224483 },
    { lat: 48.890939, lng: 20.224236 },
    { lat: 48.891204, lng: 20.223824 },
    { lat: 48.891294, lng: 20.223707 },
    { lat: 48.891398, lng: 20.223486 },
    { lat: 48.891623, lng: 20.223079 },
    { lat: 48.891757, lng: 20.222872 },
    { lat: 48.892005, lng: 20.222126 },
    { lat: 48.892034, lng: 20.221878 },
    { lat: 48.892103, lng: 20.221632 },
    { lat: 48.892164, lng: 20.221228 },
    { lat: 48.892192, lng: 20.220936 },
    { lat: 48.892247, lng: 20.220715 },
    { lat: 48.892355, lng: 20.220504 },
    { lat: 48.892415, lng: 20.220237 },
    { lat: 48.892494, lng: 20.22001 },
    { lat: 48.892504, lng: 20.219718 },
    { lat: 48.892584, lng: 20.219557 },
    { lat: 48.892677, lng: 20.21939 },
    { lat: 48.892721, lng: 20.219229 },
    { lat: 48.892638, lng: 20.218341 },
    { lat: 48.892656, lng: 20.218127 },
    { lat: 48.892655, lng: 20.217898 },
    { lat: 48.892887, lng: 20.217364 },
    { lat: 48.892949, lng: 20.217103 },
    { lat: 48.893097, lng: 20.216504 },
    { lat: 48.893238, lng: 20.216095 },
    { lat: 48.893385, lng: 20.215918 },
    { lat: 48.89351, lng: 20.215823 },
    { lat: 48.893599, lng: 20.215624 },
    { lat: 48.893715, lng: 20.215085 },
    { lat: 48.893969, lng: 20.214792 },
    { lat: 48.894138, lng: 20.214488 },
    { lat: 48.894154, lng: 20.214214 },
    { lat: 48.894219, lng: 20.213939 },
    { lat: 48.894301, lng: 20.213759 },
    { lat: 48.894729, lng: 20.213149 },
    { lat: 48.894991, lng: 20.212304 },
    { lat: 48.894911, lng: 20.211709 },
    { lat: 48.894884, lng: 20.211341 },
    { lat: 48.894832, lng: 20.211095 },
    { lat: 48.894662, lng: 20.21061 },
    { lat: 48.894516, lng: 20.210259 },
    { lat: 48.894352, lng: 20.210025 },
    { lat: 48.894359, lng: 20.210008 },
    { lat: 48.894356, lng: 20.208744 },
    { lat: 48.894413, lng: 20.208122 },
    { lat: 48.894417, lng: 20.208083 },
    { lat: 48.894561, lng: 20.206518 },
    { lat: 48.894868, lng: 20.203686 },
    { lat: 48.894736, lng: 20.202442 },
    { lat: 48.898558, lng: 20.193436 },
    { lat: 48.899081, lng: 20.191017 },
    { lat: 48.899572, lng: 20.188364 },
    { lat: 48.900127, lng: 20.186624 },
    { lat: 48.900313, lng: 20.186363 },
    { lat: 48.90067, lng: 20.185845 },
    { lat: 48.901094, lng: 20.185267 },
    { lat: 48.906132, lng: 20.178262 },
    { lat: 48.907242, lng: 20.176708 },
    { lat: 48.907974, lng: 20.175661 },
    { lat: 48.90754, lng: 20.175227 },
    { lat: 48.907297, lng: 20.174996 },
    { lat: 48.906855, lng: 20.174586 },
    { lat: 48.906501, lng: 20.174253 },
    { lat: 48.906182, lng: 20.173951 },
    { lat: 48.905877, lng: 20.173664 },
    { lat: 48.905571, lng: 20.173378 },
    { lat: 48.905413, lng: 20.173227 },
    { lat: 48.905068, lng: 20.172905 },
    { lat: 48.904525, lng: 20.172406 },
    { lat: 48.903115, lng: 20.171174 },
    { lat: 48.901755, lng: 20.170172 },
    { lat: 48.897777, lng: 20.167221 },
    { lat: 48.896791, lng: 20.166021 },
    { lat: 48.896673, lng: 20.165875 },
    { lat: 48.896256, lng: 20.165355 },
    { lat: 48.895936, lng: 20.164957 },
    { lat: 48.895167, lng: 20.164014 },
    { lat: 48.894894, lng: 20.163675 },
    { lat: 48.894729, lng: 20.163471 },
    { lat: 48.89457, lng: 20.163279 },
    { lat: 48.89311, lng: 20.161861 },
    { lat: 48.892744, lng: 20.161505 },
    { lat: 48.892337, lng: 20.16111 },
    { lat: 48.891987, lng: 20.160771 },
    { lat: 48.891633, lng: 20.160429 },
    { lat: 48.891252, lng: 20.160064 },
    { lat: 48.891084, lng: 20.159897 },
    { lat: 48.889202, lng: 20.158067 },
    { lat: 48.8818528, lng: 20.1478076 },
    { lat: 48.8755199, lng: 20.1535247 },
    { lat: 48.8713773, lng: 20.1580945 },
    { lat: 48.865856, lng: 20.1571606 },
    { lat: 48.8617228, lng: 20.1549823 },
    { lat: 48.8613099, lng: 20.1548557 },
    { lat: 48.8586587, lng: 20.1556053 },
    { lat: 48.8569484, lng: 20.1562602 },
    { lat: 48.8562124, lng: 20.1564682 },
    { lat: 48.8542417, lng: 20.1567819 },
    { lat: 48.8525699, lng: 20.157215 },
    { lat: 48.8509529, lng: 20.1571416 },
    { lat: 48.849502, lng: 20.1579961 },
    { lat: 48.8473277, lng: 20.1584584 },
    { lat: 48.8463491, lng: 20.158461 },
    { lat: 48.8458596, lng: 20.1586354 },
    { lat: 48.8442478, lng: 20.1594986 },
    { lat: 48.8433633, lng: 20.1599094 },
    { lat: 48.8430631, lng: 20.1599751 },
    { lat: 48.8427198, lng: 20.1601746 },
    { lat: 48.8421442, lng: 20.1603448 },
    { lat: 48.8409459, lng: 20.1610152 },
    { lat: 48.8401149, lng: 20.1613548 },
    { lat: 48.8383777, lng: 20.1622337 },
    { lat: 48.8363039, lng: 20.1630869 },
    { lat: 48.8357018, lng: 20.1634307 },
    { lat: 48.8350916, lng: 20.1639139 },
    { lat: 48.8339663, lng: 20.1645513 },
    { lat: 48.8324672, lng: 20.1649072 },
    { lat: 48.8313343, lng: 20.1649997 },
    { lat: 48.8306502, lng: 20.1649836 },
    { lat: 48.8298152, lng: 20.1648595 },
    { lat: 48.8285927, lng: 20.1648774 },
    { lat: 48.8277515, lng: 20.1647685 },
    { lat: 48.8272056, lng: 20.1648322 },
    { lat: 48.8266673, lng: 20.1650267 },
    { lat: 48.8262992, lng: 20.1652569 },
    { lat: 48.8230623, lng: 20.1659223 },
    { lat: 48.8199871, lng: 20.168479 },
    { lat: 48.8184517, lng: 20.1714422 },
    { lat: 48.8157028, lng: 20.1704625 },
    { lat: 48.8118565, lng: 20.1688399 },
    { lat: 48.8111682, lng: 20.1689424 },
    { lat: 48.8102043, lng: 20.168813 },
    { lat: 48.8090641, lng: 20.169889 },
    { lat: 48.8084861, lng: 20.1708313 },
    { lat: 48.8076032, lng: 20.1708081 },
    { lat: 48.8070119, lng: 20.170783 },
    { lat: 48.8059137, lng: 20.1705234 },
    { lat: 48.80364, lng: 20.170139 },
    { lat: 48.8023483, lng: 20.1700866 },
    { lat: 48.8013702, lng: 20.1699018 },
    { lat: 48.7989695, lng: 20.16885 },
    { lat: 48.796091, lng: 20.168255 },
  ],
  HornáLehota: [
    { lat: 48.933463, lng: 19.6276383 },
    { lat: 48.933888, lng: 19.6294751 },
    { lat: 48.936396, lng: 19.640266 },
    { lat: 48.936439, lng: 19.640074 },
    { lat: 48.936996, lng: 19.637295 },
    { lat: 48.937282, lng: 19.636801 },
    { lat: 48.937389, lng: 19.635162 },
    { lat: 48.937697, lng: 19.632964 },
    { lat: 48.937874, lng: 19.632134 },
    { lat: 48.938054, lng: 19.632107 },
    { lat: 48.938473, lng: 19.631335 },
    { lat: 48.939498, lng: 19.629889 },
    { lat: 48.938412, lng: 19.625963 },
    { lat: 48.93849, lng: 19.624794 },
    { lat: 48.938527, lng: 19.624231 },
    { lat: 48.939066, lng: 19.622435 },
    { lat: 48.939521, lng: 19.620898 },
    { lat: 48.939532, lng: 19.620848 },
    { lat: 48.940314, lng: 19.618249 },
    { lat: 48.94108, lng: 19.615923 },
    { lat: 48.941809, lng: 19.613528 },
    { lat: 48.941709, lng: 19.612225 },
    { lat: 48.941923, lng: 19.611077 },
    { lat: 48.941264, lng: 19.609564 },
    { lat: 48.941071, lng: 19.60565 },
    { lat: 48.941099, lng: 19.605385 },
    { lat: 48.941194, lng: 19.604146 },
    { lat: 48.941064, lng: 19.602532 },
    { lat: 48.940653, lng: 19.600846 },
    { lat: 48.940866, lng: 19.600165 },
    { lat: 48.941163, lng: 19.597394 },
    { lat: 48.94176, lng: 19.596192 },
    { lat: 48.94301, lng: 19.592725 },
    { lat: 48.943723, lng: 19.590672 },
    { lat: 48.943225, lng: 19.588412 },
    { lat: 48.94201, lng: 19.582897 },
    { lat: 48.941276, lng: 19.582105 },
    { lat: 48.941098, lng: 19.581533 },
    { lat: 48.940769, lng: 19.581137 },
    { lat: 48.940226, lng: 19.579599 },
    { lat: 48.94023, lng: 19.57879 },
    { lat: 48.939756, lng: 19.57791 },
    { lat: 48.940044, lng: 19.57645 },
    { lat: 48.940054, lng: 19.575388 },
    { lat: 48.940538, lng: 19.572775 },
    { lat: 48.9276604, lng: 19.5754473 },
    { lat: 48.922825, lng: 19.5763599 },
    { lat: 48.9185294, lng: 19.5772989 },
    { lat: 48.9170554, lng: 19.5796312 },
    { lat: 48.9166119, lng: 19.5800149 },
    { lat: 48.9159885, lng: 19.580259 },
    { lat: 48.9140468, lng: 19.580653 },
    { lat: 48.9136542, lng: 19.5804134 },
    { lat: 48.9128613, lng: 19.5796514 },
    { lat: 48.910951, lng: 19.5781002 },
    { lat: 48.9101236, lng: 19.5779447 },
    { lat: 48.9082202, lng: 19.5773906 },
    { lat: 48.9067681, lng: 19.5767744 },
    { lat: 48.9052841, lng: 19.5766039 },
    { lat: 48.9043752, lng: 19.5756643 },
    { lat: 48.9012415, lng: 19.5760853 },
    { lat: 48.9012235, lng: 19.5759396 },
    { lat: 48.9005017, lng: 19.5754243 },
    { lat: 48.8997909, lng: 19.5740358 },
    { lat: 48.8994915, lng: 19.5728025 },
    { lat: 48.898703, lng: 19.5725875 },
    { lat: 48.8982809, lng: 19.5718028 },
    { lat: 48.8973151, lng: 19.5692868 },
    { lat: 48.8963851, lng: 19.5684102 },
    { lat: 48.8962154, lng: 19.5674015 },
    { lat: 48.895338, lng: 19.5656983 },
    { lat: 48.8944522, lng: 19.5637361 },
    { lat: 48.8942604, lng: 19.5630625 },
    { lat: 48.8936239, lng: 19.5613306 },
    { lat: 48.8930818, lng: 19.5605834 },
    { lat: 48.892835, lng: 19.5599908 },
    { lat: 48.8913954, lng: 19.5588587 },
    { lat: 48.8908354, lng: 19.5579411 },
    { lat: 48.8896108, lng: 19.5579715 },
    { lat: 48.8884056, lng: 19.5576851 },
    { lat: 48.8868612, lng: 19.5568763 },
    { lat: 48.8858676, lng: 19.5565395 },
    { lat: 48.8848898, lng: 19.555803 },
    { lat: 48.8845012, lng: 19.5554351 },
    { lat: 48.8829803, lng: 19.5537075 },
    { lat: 48.8812413, lng: 19.552315 },
    { lat: 48.8803888, lng: 19.5515354 },
    { lat: 48.8793322, lng: 19.5507278 },
    { lat: 48.8783637, lng: 19.5504895 },
    { lat: 48.8777103, lng: 19.5500965 },
    { lat: 48.8764658, lng: 19.5499895 },
    { lat: 48.8761108, lng: 19.5502608 },
    { lat: 48.8760499, lng: 19.5499106 },
    { lat: 48.8753058, lng: 19.5501779 },
    { lat: 48.8741836, lng: 19.5503245 },
    { lat: 48.8738685, lng: 19.5502347 },
    { lat: 48.8732557, lng: 19.5504603 },
    { lat: 48.8731475, lng: 19.5506628 },
    { lat: 48.8730438, lng: 19.550563 },
    { lat: 48.8725643, lng: 19.5504507 },
    { lat: 48.8720808, lng: 19.5503944 },
    { lat: 48.8715786, lng: 19.5504527 },
    { lat: 48.8710478, lng: 19.550131 },
    { lat: 48.8705859, lng: 19.5496385 },
    { lat: 48.8705201, lng: 19.5504952 },
    { lat: 48.8703901, lng: 19.5506838 },
    { lat: 48.8702032, lng: 19.5503397 },
    { lat: 48.870251, lng: 19.5498798 },
    { lat: 48.8701033, lng: 19.5496071 },
    { lat: 48.8693568, lng: 19.5488542 },
    { lat: 48.8691639, lng: 19.5485548 },
    { lat: 48.8691357, lng: 19.5483555 },
    { lat: 48.8692017, lng: 19.547923 },
    { lat: 48.8690956, lng: 19.5478171 },
    { lat: 48.8689379, lng: 19.5474503 },
    { lat: 48.8678842, lng: 19.5471276 },
    { lat: 48.8670943, lng: 19.5467322 },
    { lat: 48.8671908, lng: 19.54628 },
    { lat: 48.8668748, lng: 19.5462176 },
    { lat: 48.8664986, lng: 19.5459263 },
    { lat: 48.8659131, lng: 19.5452908 },
    { lat: 48.8661518, lng: 19.5448449 },
    { lat: 48.8663608, lng: 19.5442876 },
    { lat: 48.8670916, lng: 19.5419328 },
    { lat: 48.8667548, lng: 19.5397133 },
    { lat: 48.8669375, lng: 19.5394435 },
    { lat: 48.8668727, lng: 19.5391619 },
    { lat: 48.8665423, lng: 19.5383909 },
    { lat: 48.8663286, lng: 19.5384001 },
    { lat: 48.8657716, lng: 19.5367875 },
    { lat: 48.8655959, lng: 19.5360028 },
    { lat: 48.8653887, lng: 19.5354121 },
    { lat: 48.8651104, lng: 19.534994 },
    { lat: 48.8645344, lng: 19.5343014 },
    { lat: 48.8642584, lng: 19.5342292 },
    { lat: 48.8633961, lng: 19.5332854 },
    { lat: 48.8630907, lng: 19.5330863 },
    { lat: 48.8627517, lng: 19.5329695 },
    { lat: 48.8613573, lng: 19.5327108 },
    { lat: 48.8607448, lng: 19.5326739 },
    { lat: 48.8598989, lng: 19.531672 },
    { lat: 48.85955, lng: 19.5310387 },
    { lat: 48.8593821, lng: 19.5304505 },
    { lat: 48.8589412, lng: 19.5305681 },
    { lat: 48.858109, lng: 19.5309941 },
    { lat: 48.8573518, lng: 19.5310468 },
    { lat: 48.8574076, lng: 19.5294596 },
    { lat: 48.8558554, lng: 19.530113 },
    { lat: 48.8536371, lng: 19.5314003 },
    { lat: 48.8529158, lng: 19.5309776 },
    { lat: 48.8528878, lng: 19.5285964 },
    { lat: 48.8524307, lng: 19.5284323 },
    { lat: 48.8512159, lng: 19.5295139 },
    { lat: 48.8504457, lng: 19.5284928 },
    { lat: 48.849561, lng: 19.526127 },
    { lat: 48.8484861, lng: 19.5248755 },
    { lat: 48.8482311, lng: 19.5244733 },
    { lat: 48.846582, lng: 19.5236493 },
    { lat: 48.845867, lng: 19.5251653 },
    { lat: 48.8431579, lng: 19.5241766 },
    { lat: 48.842865, lng: 19.5247201 },
    { lat: 48.8422137, lng: 19.5245743 },
    { lat: 48.8417033, lng: 19.5256071 },
    { lat: 48.8407406, lng: 19.5251331 },
    { lat: 48.8405379, lng: 19.5262387 },
    { lat: 48.8383469, lng: 19.5256442 },
    { lat: 48.8382105, lng: 19.5260821 },
    { lat: 48.8364349, lng: 19.5297821 },
    { lat: 48.8347088, lng: 19.5294416 },
    { lat: 48.8327077, lng: 19.5287316 },
    { lat: 48.8306915, lng: 19.529168 },
    { lat: 48.8289126, lng: 19.5277015 },
    { lat: 48.8287256, lng: 19.5277544 },
    { lat: 48.8282609, lng: 19.5281606 },
    { lat: 48.8280905, lng: 19.5284968 },
    { lat: 48.8272928, lng: 19.5290176 },
    { lat: 48.8271673, lng: 19.5292826 },
    { lat: 48.8268116, lng: 19.5293121 },
    { lat: 48.8266179, lng: 19.5295631 },
    { lat: 48.8263944, lng: 19.5295816 },
    { lat: 48.8260758, lng: 19.5299766 },
    { lat: 48.8260511, lng: 19.5302524 },
    { lat: 48.8247734, lng: 19.530819 },
    { lat: 48.8240933, lng: 19.5305857 },
    { lat: 48.8240264, lng: 19.530405 },
    { lat: 48.8237893, lng: 19.5303205 },
    { lat: 48.8234754, lng: 19.529866 },
    { lat: 48.8234566, lng: 19.5302649 },
    { lat: 48.8228678, lng: 19.5431648 },
    { lat: 48.8228597, lng: 19.5455773 },
    { lat: 48.8228444, lng: 19.5457323 },
    { lat: 48.8223302, lng: 19.5464431 },
    { lat: 48.822426, lng: 19.5466644 },
    { lat: 48.822355, lng: 19.5466393 },
    { lat: 48.8224309, lng: 19.5467716 },
    { lat: 48.823151, lng: 19.5477847 },
    { lat: 48.823898, lng: 19.5496605 },
    { lat: 48.8243592, lng: 19.5503074 },
    { lat: 48.8251553, lng: 19.5510406 },
    { lat: 48.8254436, lng: 19.5517031 },
    { lat: 48.8263227, lng: 19.5530134 },
    { lat: 48.8267215, lng: 19.5539501 },
    { lat: 48.8276037, lng: 19.5552866 },
    { lat: 48.826088, lng: 19.5582781 },
    { lat: 48.8265494, lng: 19.5589574 },
    { lat: 48.8274072, lng: 19.5597747 },
    { lat: 48.8317035, lng: 19.5633145 },
    { lat: 48.8322087, lng: 19.563618 },
    { lat: 48.8324501, lng: 19.563895 },
    { lat: 48.8333816, lng: 19.5639155 },
    { lat: 48.8345785, lng: 19.5658007 },
    { lat: 48.8352532, lng: 19.5675792 },
    { lat: 48.8363294, lng: 19.5687717 },
    { lat: 48.8337673, lng: 19.5732187 },
    { lat: 48.8336734, lng: 19.5735829 },
    { lat: 48.8338516, lng: 19.5744771 },
    { lat: 48.8355273, lng: 19.5781805 },
    { lat: 48.8354988, lng: 19.5785462 },
    { lat: 48.8353575, lng: 19.5789343 },
    { lat: 48.8355137, lng: 19.5796706 },
    { lat: 48.8363656, lng: 19.580987 },
    { lat: 48.8370743, lng: 19.5826262 },
    { lat: 48.8373545, lng: 19.5830917 },
    { lat: 48.8372019, lng: 19.5834027 },
    { lat: 48.8371337, lng: 19.5841856 },
    { lat: 48.8372713, lng: 19.5857598 },
    { lat: 48.8374743, lng: 19.5861841 },
    { lat: 48.8386994, lng: 19.5875061 },
    { lat: 48.8390791, lng: 19.5878311 },
    { lat: 48.8390911, lng: 19.5880689 },
    { lat: 48.8402429, lng: 19.5897177 },
    { lat: 48.8403772, lng: 19.5902703 },
    { lat: 48.8407153, lng: 19.5906183 },
    { lat: 48.840939, lng: 19.5907364 },
    { lat: 48.8428234, lng: 19.5942063 },
    { lat: 48.8429365, lng: 19.5943427 },
    { lat: 48.8435958, lng: 19.5959631 },
    { lat: 48.8436295, lng: 19.5960347 },
    { lat: 48.8437232, lng: 19.5960386 },
    { lat: 48.8437604, lng: 19.5965112 },
    { lat: 48.8441799, lng: 19.5975699 },
    { lat: 48.8443975, lng: 19.598393 },
    { lat: 48.8445677, lng: 19.5987424 },
    { lat: 48.8446897, lng: 19.5987981 },
    { lat: 48.8447044, lng: 19.5996723 },
    { lat: 48.845294, lng: 19.6007295 },
    { lat: 48.8454427, lng: 19.6008285 },
    { lat: 48.8456008, lng: 19.6012865 },
    { lat: 48.8457707, lng: 19.6014567 },
    { lat: 48.8459519, lng: 19.6015249 },
    { lat: 48.8467645, lng: 19.6016274 },
    { lat: 48.8468612, lng: 19.6021951 },
    { lat: 48.8465165, lng: 19.6021751 },
    { lat: 48.8460915, lng: 19.6026667 },
    { lat: 48.8459003, lng: 19.6027058 },
    { lat: 48.8456872, lng: 19.6028738 },
    { lat: 48.8455358, lng: 19.6035911 },
    { lat: 48.845797, lng: 19.6041346 },
    { lat: 48.8460714, lng: 19.6043974 },
    { lat: 48.8465754, lng: 19.60448 },
    { lat: 48.8480887, lng: 19.6043805 },
    { lat: 48.8480793, lng: 19.6045958 },
    { lat: 48.8485566, lng: 19.6049595 },
    { lat: 48.8495382, lng: 19.6049888 },
    { lat: 48.8496973, lng: 19.6048195 },
    { lat: 48.8506505, lng: 19.6045219 },
    { lat: 48.8508649, lng: 19.6042496 },
    { lat: 48.8528015, lng: 19.6036417 },
    { lat: 48.8535166, lng: 19.6035615 },
    { lat: 48.8541951, lng: 19.6038891 },
    { lat: 48.8545476, lng: 19.6038096 },
    { lat: 48.8548536, lng: 19.6034505 },
    { lat: 48.8554281, lng: 19.6030203 },
    { lat: 48.8557801, lng: 19.6026059 },
    { lat: 48.8570007, lng: 19.6026143 },
    { lat: 48.8574515, lng: 19.6028667 },
    { lat: 48.8581635, lng: 19.6026659 },
    { lat: 48.8588122, lng: 19.6035573 },
    { lat: 48.8587829, lng: 19.6037561 },
    { lat: 48.8589108, lng: 19.6038691 },
    { lat: 48.8591781, lng: 19.6043732 },
    { lat: 48.8594676, lng: 19.6044985 },
    { lat: 48.859847, lng: 19.6045916 },
    { lat: 48.860232, lng: 19.6045055 },
    { lat: 48.860508, lng: 19.604332 },
    { lat: 48.8608451, lng: 19.6043103 },
    { lat: 48.861606, lng: 19.6039688 },
    { lat: 48.8622898, lng: 19.6041405 },
    { lat: 48.8629569, lng: 19.6041634 },
    { lat: 48.8632146, lng: 19.6044319 },
    { lat: 48.8636341, lng: 19.6045412 },
    { lat: 48.8639414, lng: 19.6049306 },
    { lat: 48.8640564, lng: 19.6049577 },
    { lat: 48.8651566, lng: 19.604819 },
    { lat: 48.8658626, lng: 19.6050608 },
    { lat: 48.8662011, lng: 19.6052683 },
    { lat: 48.8669497, lng: 19.6050784 },
    { lat: 48.8674654, lng: 19.6051894 },
    { lat: 48.8678149, lng: 19.6052786 },
    { lat: 48.8683305, lng: 19.6057159 },
    { lat: 48.8684711, lng: 19.6060116 },
    { lat: 48.8687317, lng: 19.6063396 },
    { lat: 48.8689726, lng: 19.6064883 },
    { lat: 48.8694418, lng: 19.6066211 },
    { lat: 48.8696641, lng: 19.6065809 },
    { lat: 48.8700814, lng: 19.6066418 },
    { lat: 48.8702835, lng: 19.6061749 },
    { lat: 48.8707772, lng: 19.6058295 },
    { lat: 48.8712523, lng: 19.6060183 },
    { lat: 48.8716002, lng: 19.6060109 },
    { lat: 48.8718161, lng: 19.6061539 },
    { lat: 48.8720909, lng: 19.6060474 },
    { lat: 48.8722941, lng: 19.6061548 },
    { lat: 48.8725608, lng: 19.6061473 },
    { lat: 48.8727768, lng: 19.6060422 },
    { lat: 48.8729651, lng: 19.605669 },
    { lat: 48.873441, lng: 19.6053573 },
    { lat: 48.8746062, lng: 19.6054356 },
    { lat: 48.8748424, lng: 19.6048502 },
    { lat: 48.8758487, lng: 19.6045254 },
    { lat: 48.8762335, lng: 19.6040377 },
    { lat: 48.876543, lng: 19.6039791 },
    { lat: 48.8770087, lng: 19.6037662 },
    { lat: 48.8772613, lng: 19.60389 },
    { lat: 48.8777363, lng: 19.6039048 },
    { lat: 48.8782629, lng: 19.6041416 },
    { lat: 48.8786686, lng: 19.6040183 },
    { lat: 48.8790715, lng: 19.6037578 },
    { lat: 48.8796087, lng: 19.6037479 },
    { lat: 48.8802388, lng: 19.6036321 },
    { lat: 48.8808391, lng: 19.6033515 },
    { lat: 48.8813289, lng: 19.6035681 },
    { lat: 48.8818927, lng: 19.6035261 },
    { lat: 48.8822332, lng: 19.6033061 },
    { lat: 48.8828826, lng: 19.6026434 },
    { lat: 48.8833364, lng: 19.6024871 },
    { lat: 48.8846895, lng: 19.601809 },
    { lat: 48.8852589, lng: 19.6017181 },
    { lat: 48.8861926, lng: 19.601758 },
    { lat: 48.8867115, lng: 19.6021438 },
    { lat: 48.8869866, lng: 19.6024733 },
    { lat: 48.8876295, lng: 19.6025882 },
    { lat: 48.8886629, lng: 19.602665 },
    { lat: 48.8889531, lng: 19.6026088 },
    { lat: 48.8892509, lng: 19.6020852 },
    { lat: 48.8896926, lng: 19.6018396 },
    { lat: 48.890489, lng: 19.6019077 },
    { lat: 48.8908443, lng: 19.6016431 },
    { lat: 48.8912647, lng: 19.6014942 },
    { lat: 48.8917916, lng: 19.6010632 },
    { lat: 48.8920484, lng: 19.6009556 },
    { lat: 48.8924851, lng: 19.6010231 },
    { lat: 48.8928984, lng: 19.6006941 },
    { lat: 48.8936661, lng: 19.600729 },
    { lat: 48.894042, lng: 19.6003144 },
    { lat: 48.8942601, lng: 19.6003604 },
    { lat: 48.8947739, lng: 19.6007792 },
    { lat: 48.8951395, lng: 19.6007924 },
    { lat: 48.8956221, lng: 19.6003148 },
    { lat: 48.8961251, lng: 19.6001877 },
    { lat: 48.8963189, lng: 19.5999341 },
    { lat: 48.8968993, lng: 19.5997414 },
    { lat: 48.8977931, lng: 19.5990488 },
    { lat: 48.8984227, lng: 19.5990675 },
    { lat: 48.8989019, lng: 19.5993483 },
    { lat: 48.8994107, lng: 19.5993886 },
    { lat: 48.8999905, lng: 19.5992769 },
    { lat: 48.9004064, lng: 19.5996767 },
    { lat: 48.9011078, lng: 19.5998904 },
    { lat: 48.9033526, lng: 19.5985256 },
    { lat: 48.9037682, lng: 19.5984434 },
    { lat: 48.905108, lng: 19.5988222 },
    { lat: 48.9053359, lng: 19.5989787 },
    { lat: 48.9054372, lng: 19.5991988 },
    { lat: 48.9055425, lng: 19.5998122 },
    { lat: 48.9057104, lng: 19.600361 },
    { lat: 48.9059844, lng: 19.6007148 },
    { lat: 48.9064182, lng: 19.6008166 },
    { lat: 48.9067132, lng: 19.600787 },
    { lat: 48.9079504, lng: 19.5998588 },
    { lat: 48.9082293, lng: 19.5997986 },
    { lat: 48.9089127, lng: 19.600136 },
    { lat: 48.9098114, lng: 19.6000793 },
    { lat: 48.909974, lng: 19.5999367 },
    { lat: 48.9101193, lng: 19.5996816 },
    { lat: 48.9102856, lng: 19.599573 },
    { lat: 48.9112052, lng: 19.5996494 },
    { lat: 48.9123092, lng: 19.6003974 },
    { lat: 48.9123963, lng: 19.6004023 },
    { lat: 48.9126747, lng: 19.6000582 },
    { lat: 48.9129829, lng: 19.5999957 },
    { lat: 48.9134233, lng: 19.6006417 },
    { lat: 48.913932, lng: 19.6011517 },
    { lat: 48.9143341, lng: 19.6014245 },
    { lat: 48.9150091, lng: 19.6015479 },
    { lat: 48.915432, lng: 19.6014814 },
    { lat: 48.9157738, lng: 19.601265 },
    { lat: 48.9170672, lng: 19.6007675 },
    { lat: 48.9176004, lng: 19.6007221 },
    { lat: 48.9182499, lng: 19.6013057 },
    { lat: 48.9196476, lng: 19.6023676 },
    { lat: 48.9200869, lng: 19.6028164 },
    { lat: 48.9201956, lng: 19.6030753 },
    { lat: 48.9206082, lng: 19.6053036 },
    { lat: 48.9207636, lng: 19.6056554 },
    { lat: 48.9209691, lng: 19.6064857 },
    { lat: 48.9213766, lng: 19.6071833 },
    { lat: 48.9216285, lng: 19.6074452 },
    { lat: 48.9221144, lng: 19.6081784 },
    { lat: 48.9223586, lng: 19.60842 },
    { lat: 48.9226024, lng: 19.6089071 },
    { lat: 48.922888, lng: 19.6091928 },
    { lat: 48.9246058, lng: 19.6101545 },
    { lat: 48.9250369, lng: 19.6111256 },
    { lat: 48.9252107, lng: 19.6119819 },
    { lat: 48.9252147, lng: 19.6132982 },
    { lat: 48.9253248, lng: 19.6138408 },
    { lat: 48.9253334, lng: 19.6162624 },
    { lat: 48.9254912, lng: 19.6172153 },
    { lat: 48.9255269, lng: 19.6181364 },
    { lat: 48.9256329, lng: 19.6185152 },
    { lat: 48.9257076, lng: 19.6191253 },
    { lat: 48.9260936, lng: 19.6207443 },
    { lat: 48.9260687, lng: 19.621041 },
    { lat: 48.9261467, lng: 19.621349 },
    { lat: 48.9264366, lng: 19.6219572 },
    { lat: 48.9264911, lng: 19.6224711 },
    { lat: 48.9266713, lng: 19.6231734 },
    { lat: 48.926687, lng: 19.6243743 },
    { lat: 48.9267821, lng: 19.6246364 },
    { lat: 48.9270183, lng: 19.6248288 },
    { lat: 48.9280695, lng: 19.6254296 },
    { lat: 48.9284025, lng: 19.6257147 },
    { lat: 48.9286109, lng: 19.6258932 },
    { lat: 48.929456, lng: 19.6264258 },
    { lat: 48.9305461, lng: 19.6266102 },
    { lat: 48.9320236, lng: 19.6271728 },
    { lat: 48.9329835, lng: 19.6273703 },
    { lat: 48.933463, lng: 19.6276383 },
  ],
  Vaľkovňa: [
    { lat: 48.8388301, lng: 20.0446478 },
    { lat: 48.8385684, lng: 20.0424615 },
    { lat: 48.8377849, lng: 20.0397984 },
    { lat: 48.8377803, lng: 20.0365612 },
    { lat: 48.8375998, lng: 20.0357642 },
    { lat: 48.8371559, lng: 20.0331512 },
    { lat: 48.835628, lng: 20.0286542 },
    { lat: 48.8340048, lng: 20.0265808 },
    { lat: 48.8336313, lng: 20.0246954 },
    { lat: 48.8330134, lng: 20.0233756 },
    { lat: 48.8328116, lng: 20.0231546 },
    { lat: 48.8315128, lng: 20.0222021 },
    { lat: 48.83076, lng: 20.0217268 },
    { lat: 48.8280945, lng: 20.0224709 },
    { lat: 48.8280921, lng: 20.023096 },
    { lat: 48.8276505, lng: 20.0231187 },
    { lat: 48.8274418, lng: 20.0233559 },
    { lat: 48.8272034, lng: 20.0230948 },
    { lat: 48.8261741, lng: 20.0231799 },
    { lat: 48.8263035, lng: 20.0227944 },
    { lat: 48.825328, lng: 20.0221198 },
    { lat: 48.8221077, lng: 20.0210578 },
    { lat: 48.8201654, lng: 20.0215359 },
    { lat: 48.8192226, lng: 20.0204766 },
    { lat: 48.8190789, lng: 20.0203809 },
    { lat: 48.8181224, lng: 20.021486 },
    { lat: 48.8170635, lng: 20.0222981 },
    { lat: 48.8169574, lng: 20.0221285 },
    { lat: 48.8170655, lng: 20.0218851 },
    { lat: 48.8169427, lng: 20.0216956 },
    { lat: 48.8168343, lng: 20.0217953 },
    { lat: 48.816711, lng: 20.0215961 },
    { lat: 48.8166724, lng: 20.0214265 },
    { lat: 48.8165932, lng: 20.0214694 },
    { lat: 48.8164581, lng: 20.0213096 },
    { lat: 48.816563, lng: 20.0200659 },
    { lat: 48.8165426, lng: 20.0188155 },
    { lat: 48.8164541, lng: 20.0184451 },
    { lat: 48.8164482, lng: 20.0176007 },
    { lat: 48.8165774, lng: 20.0167553 },
    { lat: 48.816563, lng: 20.0162177 },
    { lat: 48.8163572, lng: 20.0154106 },
    { lat: 48.8162573, lng: 20.0152844 },
    { lat: 48.8162042, lng: 20.0153066 },
    { lat: 48.8161321, lng: 20.0152027 },
    { lat: 48.8160743, lng: 20.0152489 },
    { lat: 48.8159581, lng: 20.0151906 },
    { lat: 48.8159219, lng: 20.0150454 },
    { lat: 48.8157691, lng: 20.0150325 },
    { lat: 48.8157201, lng: 20.014911 },
    { lat: 48.8156829, lng: 20.0149628 },
    { lat: 48.8154368, lng: 20.0147604 },
    { lat: 48.8153648, lng: 20.0147905 },
    { lat: 48.8152797, lng: 20.0146667 },
    { lat: 48.8151041, lng: 20.0146952 },
    { lat: 48.8150388, lng: 20.0145335 },
    { lat: 48.8149728, lng: 20.0145477 },
    { lat: 48.8148446, lng: 20.0144437 },
    { lat: 48.8146422, lng: 20.0145031 },
    { lat: 48.8145635, lng: 20.0143715 },
    { lat: 48.814359, lng: 20.0145719 },
    { lat: 48.8142078, lng: 20.0145917 },
    { lat: 48.8140769, lng: 20.0143164 },
    { lat: 48.8139901, lng: 20.0143687 },
    { lat: 48.8139761, lng: 20.0142771 },
    { lat: 48.8138782, lng: 20.0142553 },
    { lat: 48.8138418, lng: 20.014162 },
    { lat: 48.8137801, lng: 20.0142229 },
    { lat: 48.8137505, lng: 20.0141356 },
    { lat: 48.8136932, lng: 20.0142437 },
    { lat: 48.8135711, lng: 20.0141002 },
    { lat: 48.813605, lng: 20.0142114 },
    { lat: 48.8135704, lng: 20.0142432 },
    { lat: 48.813429, lng: 20.0141509 },
    { lat: 48.8133074, lng: 20.014259 },
    { lat: 48.8131952, lng: 20.0140246 },
    { lat: 48.8130776, lng: 20.0140679 },
    { lat: 48.8129725, lng: 20.0142763 },
    { lat: 48.813041, lng: 20.0143784 },
    { lat: 48.8130296, lng: 20.0144864 },
    { lat: 48.8129774, lng: 20.0144792 },
    { lat: 48.8129232, lng: 20.014692 },
    { lat: 48.8128039, lng: 20.0146659 },
    { lat: 48.8128076, lng: 20.0145401 },
    { lat: 48.8127065, lng: 20.0144646 },
    { lat: 48.8125565, lng: 20.0145125 },
    { lat: 48.8124734, lng: 20.0143807 },
    { lat: 48.812436, lng: 20.0141853 },
    { lat: 48.8123361, lng: 20.0141673 },
    { lat: 48.8122757, lng: 20.0139911 },
    { lat: 48.812186, lng: 20.0139754 },
    { lat: 48.8121158, lng: 20.0140968 },
    { lat: 48.8118339, lng: 20.0139303 },
    { lat: 48.811612, lng: 20.0134412 },
    { lat: 48.8115513, lng: 20.0133898 },
    { lat: 48.811414, lng: 20.0134193 },
    { lat: 48.8113904, lng: 20.01326 },
    { lat: 48.8113261, lng: 20.0133673 },
    { lat: 48.8111179, lng: 20.0133157 },
    { lat: 48.8110814, lng: 20.0131694 },
    { lat: 48.8108134, lng: 20.0131732 },
    { lat: 48.8105617, lng: 20.0128881 },
    { lat: 48.8105856, lng: 20.0127064 },
    { lat: 48.8105007, lng: 20.012697 },
    { lat: 48.8104538, lng: 20.012461 },
    { lat: 48.8103636, lng: 20.0123738 },
    { lat: 48.810264, lng: 20.0124709 },
    { lat: 48.8102283, lng: 20.0123231 },
    { lat: 48.8101283, lng: 20.0123537 },
    { lat: 48.8101027, lng: 20.0124405 },
    { lat: 48.8098332, lng: 20.0125952 },
    { lat: 48.809778, lng: 20.0125161 },
    { lat: 48.8096589, lng: 20.0125765 },
    { lat: 48.8096373, lng: 20.0126797 },
    { lat: 48.8093825, lng: 20.0126218 },
    { lat: 48.8092379, lng: 20.0123393 },
    { lat: 48.8091055, lng: 20.0123798 },
    { lat: 48.809066, lng: 20.0124576 },
    { lat: 48.8088891, lng: 20.0123458 },
    { lat: 48.808861, lng: 20.0122431 },
    { lat: 48.8087616, lng: 20.0122086 },
    { lat: 48.8087031, lng: 20.0120233 },
    { lat: 48.8085212, lng: 20.011792 },
    { lat: 48.80848, lng: 20.0118006 },
    { lat: 48.8084104, lng: 20.0115356 },
    { lat: 48.8083086, lng: 20.011421 },
    { lat: 48.8081956, lng: 20.0109218 },
    { lat: 48.8082717, lng: 20.0108291 },
    { lat: 48.8084969, lng: 20.010858 },
    { lat: 48.8085314, lng: 20.0109273 },
    { lat: 48.8085772, lng: 20.010864 },
    { lat: 48.8082222, lng: 20.0077742 },
    { lat: 48.8053963, lng: 19.9988403 },
    { lat: 48.8058185, lng: 19.9971101 },
    { lat: 48.8058672, lng: 19.9964661 },
    { lat: 48.8061141, lng: 19.9952082 },
    { lat: 48.8058651, lng: 19.9953093 },
    { lat: 48.805459, lng: 19.9951873 },
    { lat: 48.8052194, lng: 19.9951988 },
    { lat: 48.8045917, lng: 19.9948405 },
    { lat: 48.8042534, lng: 19.9947207 },
    { lat: 48.802965, lng: 19.9945939 },
    { lat: 48.8025266, lng: 19.9943993 },
    { lat: 48.802265, lng: 19.994071 },
    { lat: 48.8021738, lng: 19.9938529 },
    { lat: 48.8016461, lng: 19.9939734 },
    { lat: 48.8012243, lng: 19.9936408 },
    { lat: 48.8009718, lng: 19.9936067 },
    { lat: 48.8008273, lng: 19.9931164 },
    { lat: 48.800696, lng: 19.9930975 },
    { lat: 48.8005256, lng: 19.9922839 },
    { lat: 48.8002528, lng: 19.9914732 },
    { lat: 48.7999707, lng: 19.9911322 },
    { lat: 48.7998361, lng: 19.9904759 },
    { lat: 48.7996423, lng: 19.9898849 },
    { lat: 48.798447, lng: 19.9886364 },
    { lat: 48.7980392, lng: 19.9874822 },
    { lat: 48.7976802, lng: 19.9868674 },
    { lat: 48.7966419, lng: 19.9871435 },
    { lat: 48.7963333, lng: 19.9878408 },
    { lat: 48.7959436, lng: 19.9882479 },
    { lat: 48.7956226, lng: 19.9879822 },
    { lat: 48.7942258, lng: 19.9889771 },
    { lat: 48.7921562, lng: 19.9913367 },
    { lat: 48.789639, lng: 19.992973 },
    { lat: 48.789633, lng: 19.993702 },
    { lat: 48.789721, lng: 19.994076 },
    { lat: 48.789828, lng: 19.994952 },
    { lat: 48.789906, lng: 19.995736 },
    { lat: 48.789995, lng: 19.996122 },
    { lat: 48.790076, lng: 19.996469 },
    { lat: 48.790069, lng: 19.996784 },
    { lat: 48.790063, lng: 19.99703 },
    { lat: 48.790102, lng: 19.997408 },
    { lat: 48.790055, lng: 19.99789 },
    { lat: 48.790407, lng: 19.998647 },
    { lat: 48.790647, lng: 19.999074 },
    { lat: 48.79081, lng: 19.999413 },
    { lat: 48.791153, lng: 20.000321 },
    { lat: 48.791299, lng: 20.000853 },
    { lat: 48.791295, lng: 20.001467 },
    { lat: 48.791767, lng: 20.002159 },
    { lat: 48.791916, lng: 20.002662 },
    { lat: 48.791988, lng: 20.002997 },
    { lat: 48.792186, lng: 20.003359 },
    { lat: 48.792313, lng: 20.003579 },
    { lat: 48.792442, lng: 20.004412 },
    { lat: 48.792797, lng: 20.00476 },
    { lat: 48.792963, lng: 20.004921 },
    { lat: 48.793199, lng: 20.005308 },
    { lat: 48.793217, lng: 20.005647 },
    { lat: 48.793056, lng: 20.006058 },
    { lat: 48.793183, lng: 20.006414 },
    { lat: 48.793258, lng: 20.006803 },
    { lat: 48.79344, lng: 20.007181 },
    { lat: 48.793567, lng: 20.007523 },
    { lat: 48.793774, lng: 20.007763 },
    { lat: 48.793805, lng: 20.008142 },
    { lat: 48.793908, lng: 20.008568 },
    { lat: 48.794684, lng: 20.010881 },
    { lat: 48.795405, lng: 20.012653 },
    { lat: 48.795678, lng: 20.013324 },
    { lat: 48.795824, lng: 20.013678 },
    { lat: 48.797123, lng: 20.013786 },
    { lat: 48.797646, lng: 20.013831 },
    { lat: 48.799077, lng: 20.013954 },
    { lat: 48.800404, lng: 20.014946 },
    { lat: 48.801113, lng: 20.015233 },
    { lat: 48.801752, lng: 20.015595 },
    { lat: 48.801995, lng: 20.016022 },
    { lat: 48.802275, lng: 20.016377 },
    { lat: 48.802313, lng: 20.016674 },
    { lat: 48.802207, lng: 20.016984 },
    { lat: 48.801965, lng: 20.017525 },
    { lat: 48.801958, lng: 20.017868 },
    { lat: 48.801688, lng: 20.018561 },
    { lat: 48.80161, lng: 20.018855 },
    { lat: 48.801721, lng: 20.020714 },
    { lat: 48.803524, lng: 20.025658 },
    { lat: 48.803424, lng: 20.025736 },
    { lat: 48.803409, lng: 20.025737 },
    { lat: 48.8037, lng: 20.026528 },
    { lat: 48.803792, lng: 20.026792 },
    { lat: 48.803987, lng: 20.027251 },
    { lat: 48.804072, lng: 20.027843 },
    { lat: 48.804423, lng: 20.02811 },
    { lat: 48.804536, lng: 20.028235 },
    { lat: 48.804637, lng: 20.028348 },
    { lat: 48.804844, lng: 20.02852 },
    { lat: 48.804877, lng: 20.028598 },
    { lat: 48.804907, lng: 20.028799 },
    { lat: 48.804967, lng: 20.029216 },
    { lat: 48.805115, lng: 20.02957 },
    { lat: 48.805237, lng: 20.029858 },
    { lat: 48.805482, lng: 20.030176 },
    { lat: 48.805724, lng: 20.030727 },
    { lat: 48.805858, lng: 20.03145 },
    { lat: 48.805948, lng: 20.03181 },
    { lat: 48.80597, lng: 20.031808 },
    { lat: 48.805962, lng: 20.03185 },
    { lat: 48.806127, lng: 20.032011 },
    { lat: 48.806213, lng: 20.032195 },
    { lat: 48.806388, lng: 20.033337 },
    { lat: 48.806572, lng: 20.033524 },
    { lat: 48.806726, lng: 20.033742 },
    { lat: 48.8073759, lng: 20.0348778 },
    { lat: 48.8075078, lng: 20.0355882 },
    { lat: 48.8075236, lng: 20.0365415 },
    { lat: 48.8074993, lng: 20.0381815 },
    { lat: 48.8071754, lng: 20.0423047 },
    { lat: 48.8065617, lng: 20.0456096 },
    { lat: 48.8079823, lng: 20.049459 },
    { lat: 48.8096385, lng: 20.0524937 },
    { lat: 48.8099038, lng: 20.0525915 },
    { lat: 48.8105541, lng: 20.0522713 },
    { lat: 48.8106706, lng: 20.0514415 },
    { lat: 48.8108366, lng: 20.0513168 },
    { lat: 48.8110459, lng: 20.0513378 },
    { lat: 48.8117556, lng: 20.0519664 },
    { lat: 48.8119502, lng: 20.0519072 },
    { lat: 48.8121747, lng: 20.0513128 },
    { lat: 48.8120703, lng: 20.0503678 },
    { lat: 48.8121196, lng: 20.0497899 },
    { lat: 48.8122341, lng: 20.0496701 },
    { lat: 48.8124354, lng: 20.0500195 },
    { lat: 48.8126583, lng: 20.0499573 },
    { lat: 48.8128494, lng: 20.0495436 },
    { lat: 48.8128484, lng: 20.0492163 },
    { lat: 48.8127249, lng: 20.0484093 },
    { lat: 48.8157687, lng: 20.0479027 },
    { lat: 48.8161462, lng: 20.0477443 },
    { lat: 48.8174821, lng: 20.0475499 },
    { lat: 48.817791, lng: 20.0475889 },
    { lat: 48.8184588, lng: 20.0474985 },
    { lat: 48.8187964, lng: 20.0463746 },
    { lat: 48.8189344, lng: 20.0457339 },
    { lat: 48.8190453, lng: 20.0442494 },
    { lat: 48.8195002, lng: 20.0439197 },
    { lat: 48.8197481, lng: 20.0440874 },
    { lat: 48.8199748, lng: 20.0447757 },
    { lat: 48.820063, lng: 20.0455726 },
    { lat: 48.8203737, lng: 20.0454614 },
    { lat: 48.8215058, lng: 20.0453541 },
    { lat: 48.8217291, lng: 20.0452235 },
    { lat: 48.8224857, lng: 20.0442103 },
    { lat: 48.8229558, lng: 20.044575 },
    { lat: 48.8233711, lng: 20.0452452 },
    { lat: 48.8234946, lng: 20.0456703 },
    { lat: 48.8231574, lng: 20.0461798 },
    { lat: 48.8225645, lng: 20.0463861 },
    { lat: 48.8224243, lng: 20.046918 },
    { lat: 48.8224232, lng: 20.0476277 },
    { lat: 48.8226408, lng: 20.0482894 },
    { lat: 48.8232875, lng: 20.0487876 },
    { lat: 48.8235439, lng: 20.0481492 },
    { lat: 48.8237677, lng: 20.0481963 },
    { lat: 48.8242183, lng: 20.0474305 },
    { lat: 48.8244432, lng: 20.0476411 },
    { lat: 48.8246855, lng: 20.048164 },
    { lat: 48.8248499, lng: 20.0484076 },
    { lat: 48.8254159, lng: 20.0482314 },
    { lat: 48.8255854, lng: 20.0482425 },
    { lat: 48.8258742, lng: 20.0484471 },
    { lat: 48.8261245, lng: 20.0477403 },
    { lat: 48.8267158, lng: 20.0479027 },
    { lat: 48.8267158, lng: 20.048776 },
    { lat: 48.8269036, lng: 20.0492087 },
    { lat: 48.8271908, lng: 20.0496726 },
    { lat: 48.827514, lng: 20.0505429 },
    { lat: 48.8273873, lng: 20.0517288 },
    { lat: 48.8275517, lng: 20.0529826 },
    { lat: 48.8276924, lng: 20.0536378 },
    { lat: 48.827652, lng: 20.0549793 },
    { lat: 48.8273204, lng: 20.0554611 },
    { lat: 48.827234, lng: 20.0557695 },
    { lat: 48.8273445, lng: 20.0560182 },
    { lat: 48.8268486, lng: 20.0564744 },
    { lat: 48.826926, lng: 20.057013 },
    { lat: 48.8265842, lng: 20.0586968 },
    { lat: 48.8259463, lng: 20.0604897 },
    { lat: 48.8257607, lng: 20.0608486 },
    { lat: 48.8255918, lng: 20.0616427 },
    { lat: 48.825396, lng: 20.0618385 },
    { lat: 48.8251333, lng: 20.0624639 },
    { lat: 48.825154, lng: 20.06354 },
    { lat: 48.8254264, lng: 20.0650152 },
    { lat: 48.8249227, lng: 20.0649809 },
    { lat: 48.8247398, lng: 20.0652441 },
    { lat: 48.824582, lng: 20.0659003 },
    { lat: 48.8246975, lng: 20.066517 },
    { lat: 48.824473, lng: 20.0668656 },
    { lat: 48.824481, lng: 20.0671923 },
    { lat: 48.8241134, lng: 20.0681689 },
    { lat: 48.8239556, lng: 20.0683339 },
    { lat: 48.8238575, lng: 20.0685751 },
    { lat: 48.8239415, lng: 20.0690855 },
    { lat: 48.824181, lng: 20.0695948 },
    { lat: 48.8240608, lng: 20.0702885 },
    { lat: 48.8240315, lng: 20.0712192 },
    { lat: 48.8238729, lng: 20.0712752 },
    { lat: 48.8238996, lng: 20.0714364 },
    { lat: 48.8238642, lng: 20.0716442 },
    { lat: 48.8239058, lng: 20.0717768 },
    { lat: 48.823839, lng: 20.0720423 },
    { lat: 48.8240935, lng: 20.0726463 },
    { lat: 48.8240475, lng: 20.0730328 },
    { lat: 48.8241024, lng: 20.0732185 },
    { lat: 48.8240811, lng: 20.073466 },
    { lat: 48.82414, lng: 20.0736925 },
    { lat: 48.8241499, lng: 20.0739918 },
    { lat: 48.8243301, lng: 20.0741249 },
    { lat: 48.8244557, lng: 20.0743043 },
    { lat: 48.8246087, lng: 20.0747946 },
    { lat: 48.8247568, lng: 20.0748901 },
    { lat: 48.824755, lng: 20.0752313 },
    { lat: 48.8247169, lng: 20.0753439 },
    { lat: 48.8248272, lng: 20.076057 },
    { lat: 48.8245965, lng: 20.0768836 },
    { lat: 48.8246749, lng: 20.0773949 },
    { lat: 48.8247832, lng: 20.077453 },
    { lat: 48.8247859, lng: 20.0773573 },
    { lat: 48.8248734, lng: 20.0773215 },
    { lat: 48.8249855, lng: 20.0777746 },
    { lat: 48.8251023, lng: 20.077982 },
    { lat: 48.8251162, lng: 20.0781594 },
    { lat: 48.8251885, lng: 20.0782481 },
    { lat: 48.8251999, lng: 20.0784382 },
    { lat: 48.8255728, lng: 20.0787713 },
    { lat: 48.8256091, lng: 20.0789727 },
    { lat: 48.8257713, lng: 20.0791348 },
    { lat: 48.8257424, lng: 20.0795879 },
    { lat: 48.8257991, lng: 20.079842 },
    { lat: 48.8261278, lng: 20.080575 },
    { lat: 48.826141, lng: 20.0808465 },
    { lat: 48.8260726, lng: 20.0810438 },
    { lat: 48.8261549, lng: 20.0812137 },
    { lat: 48.82639, lng: 20.0812324 },
    { lat: 48.8265471, lng: 20.0814088 },
    { lat: 48.8263169, lng: 20.0821947 },
    { lat: 48.8262193, lng: 20.0822039 },
    { lat: 48.8261743, lng: 20.0824809 },
    { lat: 48.8261853, lng: 20.0828073 },
    { lat: 48.8258477, lng: 20.0829484 },
    { lat: 48.8253713, lng: 20.0834979 },
    { lat: 48.824648, lng: 20.0836342 },
    { lat: 48.8246429, lng: 20.0837576 },
    { lat: 48.8239816, lng: 20.0841883 },
    { lat: 48.8226871, lng: 20.0851696 },
    { lat: 48.8229298, lng: 20.085788 },
    { lat: 48.8230263, lng: 20.0858337 },
    { lat: 48.8231347, lng: 20.0867514 },
    { lat: 48.8232979, lng: 20.0868862 },
    { lat: 48.8235077, lng: 20.0873576 },
    { lat: 48.8235669, lng: 20.0880072 },
    { lat: 48.823425, lng: 20.0880066 },
    { lat: 48.8233553, lng: 20.0883952 },
    { lat: 48.8236122, lng: 20.0889169 },
    { lat: 48.8241093, lng: 20.0882972 },
    { lat: 48.8248051, lng: 20.0894733 },
    { lat: 48.8249228, lng: 20.0894894 },
    { lat: 48.824952, lng: 20.0898145 },
    { lat: 48.8253189, lng: 20.0898072 },
    { lat: 48.8255522, lng: 20.0899762 },
    { lat: 48.8260326, lng: 20.0907636 },
    { lat: 48.8261735, lng: 20.0908733 },
    { lat: 48.8265149, lng: 20.0914007 },
    { lat: 48.8271841, lng: 20.0914608 },
    { lat: 48.8271969, lng: 20.0917735 },
    { lat: 48.8273231, lng: 20.0920208 },
    { lat: 48.8273926, lng: 20.0934473 },
    { lat: 48.8274477, lng: 20.0936059 },
    { lat: 48.82757, lng: 20.0946178 },
    { lat: 48.827588, lng: 20.0959127 },
    { lat: 48.8274811, lng: 20.0970147 },
    { lat: 48.8275923, lng: 20.098165 },
    { lat: 48.8277196, lng: 20.0981821 },
    { lat: 48.8283409, lng: 20.0983479 },
    { lat: 48.8283418, lng: 20.0977934 },
    { lat: 48.8284043, lng: 20.0974465 },
    { lat: 48.8282496, lng: 20.095837 },
    { lat: 48.8286208, lng: 20.0958841 },
    { lat: 48.8287175, lng: 20.0970079 },
    { lat: 48.8289104, lng: 20.0971535 },
    { lat: 48.8289539, lng: 20.0974222 },
    { lat: 48.8290701, lng: 20.0976433 },
    { lat: 48.829714, lng: 20.0977466 },
    { lat: 48.8301061, lng: 20.0980784 },
    { lat: 48.8305889, lng: 20.098238 },
    { lat: 48.8311138, lng: 20.0987075 },
    { lat: 48.8310534, lng: 20.0981126 },
    { lat: 48.8307975, lng: 20.0971403 },
    { lat: 48.8308447, lng: 20.0970812 },
    { lat: 48.8306543, lng: 20.0962529 },
    { lat: 48.8305667, lng: 20.0960155 },
    { lat: 48.8302092, lng: 20.0953937 },
    { lat: 48.8301094, lng: 20.0950757 },
    { lat: 48.8302953, lng: 20.0948537 },
    { lat: 48.8302, lng: 20.0946714 },
    { lat: 48.8300349, lng: 20.0946054 },
    { lat: 48.8307127, lng: 20.0942415 },
    { lat: 48.8315198, lng: 20.0934834 },
    { lat: 48.8315412, lng: 20.0932905 },
    { lat: 48.8314022, lng: 20.0930304 },
    { lat: 48.8313786, lng: 20.0927598 },
    { lat: 48.8316243, lng: 20.0928186 },
    { lat: 48.831818, lng: 20.0927869 },
    { lat: 48.8319201, lng: 20.0924223 },
    { lat: 48.8325066, lng: 20.0917803 },
    { lat: 48.8323758, lng: 20.0917518 },
    { lat: 48.8323982, lng: 20.0916131 },
    { lat: 48.8328961, lng: 20.0899015 },
    { lat: 48.8328325, lng: 20.0898663 },
    { lat: 48.8328562, lng: 20.0895913 },
    { lat: 48.8328075, lng: 20.0895548 },
    { lat: 48.8328593, lng: 20.0887856 },
    { lat: 48.8329334, lng: 20.0884512 },
    { lat: 48.8328503, lng: 20.0884043 },
    { lat: 48.8329195, lng: 20.0880292 },
    { lat: 48.832002, lng: 20.0876245 },
    { lat: 48.832065, lng: 20.087018 },
    { lat: 48.8321402, lng: 20.0870385 },
    { lat: 48.8323559, lng: 20.0858989 },
    { lat: 48.8318779, lng: 20.0856435 },
    { lat: 48.8314383, lng: 20.0852483 },
    { lat: 48.8315929, lng: 20.0846196 },
    { lat: 48.8309814, lng: 20.0842402 },
    { lat: 48.8311685, lng: 20.0833629 },
    { lat: 48.8320545, lng: 20.0839345 },
    { lat: 48.8327081, lng: 20.0844601 },
    { lat: 48.8328614, lng: 20.0839952 },
    { lat: 48.8332972, lng: 20.0830319 },
    { lat: 48.8325408, lng: 20.0824967 },
    { lat: 48.8326149, lng: 20.0822713 },
    { lat: 48.8324255, lng: 20.0821529 },
    { lat: 48.8324798, lng: 20.0820795 },
    { lat: 48.8323649, lng: 20.0819945 },
    { lat: 48.832589, lng: 20.0810865 },
    { lat: 48.832777, lng: 20.0799634 },
    { lat: 48.8324278, lng: 20.0796823 },
    { lat: 48.832654, lng: 20.0790472 },
    { lat: 48.832957, lng: 20.0785 },
    { lat: 48.8331314, lng: 20.0786884 },
    { lat: 48.833222, lng: 20.0783728 },
    { lat: 48.8334688, lng: 20.0768412 },
    { lat: 48.8334667, lng: 20.076623 },
    { lat: 48.833302, lng: 20.0762838 },
    { lat: 48.8330977, lng: 20.0761937 },
    { lat: 48.8334928, lng: 20.0753378 },
    { lat: 48.8336981, lng: 20.0756187 },
    { lat: 48.8338993, lng: 20.0754768 },
    { lat: 48.8344205, lng: 20.075605 },
    { lat: 48.8345063, lng: 20.0755562 },
    { lat: 48.834647, lng: 20.0756384 },
    { lat: 48.8350594, lng: 20.0756403 },
    { lat: 48.8351766, lng: 20.0758613 },
    { lat: 48.8353197, lng: 20.0756158 },
    { lat: 48.8355939, lng: 20.0754945 },
    { lat: 48.8356742, lng: 20.0755824 },
    { lat: 48.8356837, lng: 20.075486 },
    { lat: 48.8357572, lng: 20.0754927 },
    { lat: 48.8358615, lng: 20.0756195 },
    { lat: 48.835949, lng: 20.0756112 },
    { lat: 48.8360641, lng: 20.0758052 },
    { lat: 48.8363876, lng: 20.0758152 },
    { lat: 48.8368297, lng: 20.0761557 },
    { lat: 48.8369212, lng: 20.0761882 },
    { lat: 48.8371887, lng: 20.0761219 },
    { lat: 48.8374428, lng: 20.0764119 },
    { lat: 48.837593, lng: 20.076726 },
    { lat: 48.8377473, lng: 20.076725 },
    { lat: 48.8377721, lng: 20.0768046 },
    { lat: 48.8379026, lng: 20.076724 },
    { lat: 48.8379964, lng: 20.0767835 },
    { lat: 48.8382599, lng: 20.0774137 },
    { lat: 48.8382706, lng: 20.0770987 },
    { lat: 48.8381142, lng: 20.0763351 },
    { lat: 48.8381189, lng: 20.0751061 },
    { lat: 48.8381735, lng: 20.0743777 },
    { lat: 48.8383348, lng: 20.0734479 },
    { lat: 48.8383272, lng: 20.0712643 },
    { lat: 48.8388719, lng: 20.0712813 },
    { lat: 48.8385869, lng: 20.0687553 },
    { lat: 48.8387725, lng: 20.0687242 },
    { lat: 48.8387703, lng: 20.0678781 },
    { lat: 48.8389406, lng: 20.0670021 },
    { lat: 48.8396731, lng: 20.0674242 },
    { lat: 48.8398633, lng: 20.0666554 },
    { lat: 48.8403391, lng: 20.0653819 },
    { lat: 48.8408167, lng: 20.0645863 },
    { lat: 48.8400509, lng: 20.0636893 },
    { lat: 48.8406756, lng: 20.0619787 },
    { lat: 48.8409101, lng: 20.0615467 },
    { lat: 48.841029, lng: 20.0614812 },
    { lat: 48.8412726, lng: 20.0609665 },
    { lat: 48.8412732, lng: 20.0608438 },
    { lat: 48.8411038, lng: 20.0606953 },
    { lat: 48.8414661, lng: 20.0596238 },
    { lat: 48.8417115, lng: 20.0582761 },
    { lat: 48.8413092, lng: 20.057918 },
    { lat: 48.8410221, lng: 20.0575085 },
    { lat: 48.8416587, lng: 20.0563423 },
    { lat: 48.8426978, lng: 20.0549886 },
    { lat: 48.8419661, lng: 20.0541295 },
    { lat: 48.8419897, lng: 20.0541001 },
    { lat: 48.8413778, lng: 20.0531343 },
    { lat: 48.8415247, lng: 20.0528744 },
    { lat: 48.8404516, lng: 20.0511606 },
    { lat: 48.8412292, lng: 20.0499402 },
    { lat: 48.8417807, lng: 20.0488095 },
    { lat: 48.8417694, lng: 20.0487016 },
    { lat: 48.8416812, lng: 20.048505 },
    { lat: 48.8412896, lng: 20.0480233 },
    { lat: 48.8410608, lng: 20.0479631 },
    { lat: 48.8406194, lng: 20.0476362 },
    { lat: 48.8406345, lng: 20.0474027 },
    { lat: 48.8405851, lng: 20.0470527 },
    { lat: 48.8403707, lng: 20.0466089 },
    { lat: 48.840263, lng: 20.0465916 },
    { lat: 48.8400587, lng: 20.0461197 },
    { lat: 48.8401637, lng: 20.0458776 },
    { lat: 48.8400918, lng: 20.045612 },
    { lat: 48.8397457, lng: 20.0452214 },
    { lat: 48.8396818, lng: 20.0453366 },
    { lat: 48.8394698, lng: 20.0450567 },
    { lat: 48.8394383, lng: 20.0450868 },
    { lat: 48.8393019, lng: 20.0446492 },
    { lat: 48.8388022, lng: 20.0448196 },
    { lat: 48.8388301, lng: 20.0446478 },
  ],
  Osrblie: [
    { lat: 48.7772116, lng: 19.5098482 },
    { lat: 48.776746, lng: 19.5092084 },
    { lat: 48.7761192, lng: 19.5086631 },
    { lat: 48.7758198, lng: 19.5066635 },
    { lat: 48.7749422, lng: 19.5021911 },
    { lat: 48.7747655, lng: 19.501487 },
    { lat: 48.7744476, lng: 19.5010457 },
    { lat: 48.7740403, lng: 19.5001805 },
    { lat: 48.7729897, lng: 19.4985833 },
    { lat: 48.7717097, lng: 19.4979541 },
    { lat: 48.7712349, lng: 19.4977207 },
    { lat: 48.769991, lng: 19.4976055 },
    { lat: 48.7694806, lng: 19.4970207 },
    { lat: 48.7676414, lng: 19.4942845 },
    { lat: 48.7666494, lng: 19.4932019 },
    { lat: 48.7658675, lng: 19.4921332 },
    { lat: 48.7644994, lng: 19.4887668 },
    { lat: 48.7642795, lng: 19.4880099 },
    { lat: 48.7641918, lng: 19.4873283 },
    { lat: 48.7642142, lng: 19.4864473 },
    { lat: 48.7640258, lng: 19.486055 },
    { lat: 48.7637626, lng: 19.4856492 },
    { lat: 48.7626842, lng: 19.4850685 },
    { lat: 48.7625069, lng: 19.4847169 },
    { lat: 48.7623058, lng: 19.4845356 },
    { lat: 48.7620354, lng: 19.4837809 },
    { lat: 48.7612401, lng: 19.4823774 },
    { lat: 48.7599145, lng: 19.4798132 },
    { lat: 48.755008, lng: 19.471946 },
    { lat: 48.75483, lng: 19.472283 },
    { lat: 48.754609, lng: 19.472444 },
    { lat: 48.75387, lng: 19.473126 },
    { lat: 48.753786, lng: 19.473204 },
    { lat: 48.75358, lng: 19.473393 },
    { lat: 48.753163, lng: 19.473772 },
    { lat: 48.751662, lng: 19.475086 },
    { lat: 48.749241, lng: 19.477174 },
    { lat: 48.747833, lng: 19.478467 },
    { lat: 48.747814, lng: 19.478484 },
    { lat: 48.747808, lng: 19.478492 },
    { lat: 48.74775, lng: 19.478559 },
    { lat: 48.746284, lng: 19.480009 },
    { lat: 48.745717, lng: 19.480425 },
    { lat: 48.744654, lng: 19.481204 },
    { lat: 48.744612, lng: 19.481263 },
    { lat: 48.743517, lng: 19.482551 },
    { lat: 48.7430388, lng: 19.4830117 },
    { lat: 48.741769, lng: 19.484235 },
    { lat: 48.738672, lng: 19.487217 },
    { lat: 48.738654, lng: 19.488199 },
    { lat: 48.738735, lng: 19.489042 },
    { lat: 48.736643, lng: 19.489892 },
    { lat: 48.735007, lng: 19.488224 },
    { lat: 48.734494, lng: 19.488824 },
    { lat: 48.733125, lng: 19.489912 },
    { lat: 48.7329415, lng: 19.4903666 },
    { lat: 48.7326751, lng: 19.4910265 },
    { lat: 48.732423, lng: 19.491651 },
    { lat: 48.73237, lng: 19.491751 },
    { lat: 48.732325, lng: 19.491836 },
    { lat: 48.732213, lng: 19.49232 },
    { lat: 48.731532, lng: 19.493379 },
    { lat: 48.731495, lng: 19.493443 },
    { lat: 48.731473, lng: 19.493481 },
    { lat: 48.730455, lng: 19.495448 },
    { lat: 48.730013, lng: 19.496328 },
    { lat: 48.729004, lng: 19.4985 },
    { lat: 48.728907, lng: 19.499019 },
    { lat: 48.728883, lng: 19.49909 },
    { lat: 48.728133, lng: 19.500489 },
    { lat: 48.72736, lng: 19.502018 },
    { lat: 48.727498, lng: 19.503812 },
    { lat: 48.726534, lng: 19.504243 },
    { lat: 48.726072, lng: 19.504476 },
    { lat: 48.726009, lng: 19.504379 },
    { lat: 48.72596, lng: 19.504303 },
    { lat: 48.725846, lng: 19.504212 },
    { lat: 48.72581, lng: 19.504183 },
    { lat: 48.725666, lng: 19.503994 },
    { lat: 48.725598, lng: 19.503899 },
    { lat: 48.725477, lng: 19.503791 },
    { lat: 48.725389, lng: 19.503712 },
    { lat: 48.72523, lng: 19.503569 },
    { lat: 48.723974, lng: 19.502945 },
    { lat: 48.722986, lng: 19.502176 },
    { lat: 48.721485, lng: 19.500823 },
    { lat: 48.720955, lng: 19.500581 },
    { lat: 48.719983, lng: 19.500263 },
    { lat: 48.719948, lng: 19.500225 },
    { lat: 48.719875, lng: 19.500152 },
    { lat: 48.719256, lng: 19.4999 },
    { lat: 48.71801, lng: 19.499638 },
    { lat: 48.716714, lng: 19.49922 },
    { lat: 48.715478, lng: 19.498119 },
    { lat: 48.714886, lng: 19.497626 },
    { lat: 48.713826, lng: 19.49691 },
    { lat: 48.71292, lng: 19.496124 },
    { lat: 48.712531, lng: 19.49637 },
    { lat: 48.711478, lng: 19.497476 },
    { lat: 48.711107, lng: 19.498026 },
    { lat: 48.709699, lng: 19.498901 },
    { lat: 48.708831, lng: 19.499332 },
    { lat: 48.708659, lng: 19.498938 },
    { lat: 48.708488, lng: 19.498833 },
    { lat: 48.708086, lng: 19.49831 },
    { lat: 48.707387, lng: 19.497583 },
    { lat: 48.707258, lng: 19.4981205 },
    { lat: 48.7071274, lng: 19.4982677 },
    { lat: 48.7071936, lng: 19.4984784 },
    { lat: 48.7071381, lng: 19.4988431 },
    { lat: 48.7073222, lng: 19.4998745 },
    { lat: 48.7072968, lng: 19.5004481 },
    { lat: 48.7074467, lng: 19.5011783 },
    { lat: 48.7076085, lng: 19.5015637 },
    { lat: 48.7080127, lng: 19.501869 },
    { lat: 48.7084384, lng: 19.5020879 },
    { lat: 48.7088099, lng: 19.5024202 },
    { lat: 48.709519, lng: 19.5034994 },
    { lat: 48.7096218, lng: 19.5046278 },
    { lat: 48.7095872, lng: 19.5063977 },
    { lat: 48.7098439, lng: 19.5065855 },
    { lat: 48.7105324, lng: 19.5076334 },
    { lat: 48.7108702, lng: 19.5079704 },
    { lat: 48.7111546, lng: 19.5087154 },
    { lat: 48.7114751, lng: 19.5090639 },
    { lat: 48.7120366, lng: 19.5100014 },
    { lat: 48.7121615, lng: 19.5104154 },
    { lat: 48.7122035, lng: 19.510912 },
    { lat: 48.7126627, lng: 19.5120737 },
    { lat: 48.712986, lng: 19.5124832 },
    { lat: 48.7137648, lng: 19.5131003 },
    { lat: 48.7142294, lng: 19.513691 },
    { lat: 48.7146585, lng: 19.5138817 },
    { lat: 48.714893, lng: 19.51418 },
    { lat: 48.7156145, lng: 19.5146269 },
    { lat: 48.7157495, lng: 19.5150363 },
    { lat: 48.7163575, lng: 19.5152975 },
    { lat: 48.7172506, lng: 19.5159955 },
    { lat: 48.7176044, lng: 19.5160429 },
    { lat: 48.7182428, lng: 19.5167877 },
    { lat: 48.718648, lng: 19.5169985 },
    { lat: 48.7192116, lng: 19.5177358 },
    { lat: 48.7193212, lng: 19.5182546 },
    { lat: 48.7196291, lng: 19.5186041 },
    { lat: 48.7198955, lng: 19.5191546 },
    { lat: 48.72069, lng: 19.5201382 },
    { lat: 48.7218048, lng: 19.5210515 },
    { lat: 48.7221937, lng: 19.5214657 },
    { lat: 48.7226122, lng: 19.5221152 },
    { lat: 48.722708, lng: 19.5229457 },
    { lat: 48.7232383, lng: 19.5233825 },
    { lat: 48.7235634, lng: 19.5241757 },
    { lat: 48.7239222, lng: 19.5247505 },
    { lat: 48.7245542, lng: 19.5249151 },
    { lat: 48.7249941, lng: 19.5251473 },
    { lat: 48.7253862, lng: 19.5251704 },
    { lat: 48.7264662, lng: 19.5259444 },
    { lat: 48.7281014, lng: 19.5278556 },
    { lat: 48.7280923, lng: 19.5279561 },
    { lat: 48.7288699, lng: 19.5283211 },
    { lat: 48.729038, lng: 19.5289156 },
    { lat: 48.729038, lng: 19.5294578 },
    { lat: 48.7293788, lng: 19.5294164 },
    { lat: 48.7294734, lng: 19.5296613 },
    { lat: 48.7297796, lng: 19.5300711 },
    { lat: 48.729999, lng: 19.5300163 },
    { lat: 48.7301724, lng: 19.5301113 },
    { lat: 48.7308473, lng: 19.5307751 },
    { lat: 48.7309933, lng: 19.5304671 },
    { lat: 48.7318545, lng: 19.531528 },
    { lat: 48.7320899, lng: 19.5316711 },
    { lat: 48.7328432, lng: 19.5313416 },
    { lat: 48.7333652, lng: 19.5312994 },
    { lat: 48.7339089, lng: 19.5315062 },
    { lat: 48.7338068, lng: 19.5319842 },
    { lat: 48.734215, lng: 19.5323066 },
    { lat: 48.7347004, lng: 19.5320829 },
    { lat: 48.7357564, lng: 19.5331639 },
    { lat: 48.7368095, lng: 19.5334969 },
    { lat: 48.7384259, lng: 19.5341468 },
    { lat: 48.7385828, lng: 19.5343232 },
    { lat: 48.7387911, lng: 19.5344174 },
    { lat: 48.7390505, lng: 19.5346415 },
    { lat: 48.7397304, lng: 19.5348741 },
    { lat: 48.7400178, lng: 19.534849 },
    { lat: 48.7401815, lng: 19.5346855 },
    { lat: 48.7406166, lng: 19.5347092 },
    { lat: 48.741177, lng: 19.5343745 },
    { lat: 48.7416427, lng: 19.5336445 },
    { lat: 48.7417372, lng: 19.5333929 },
    { lat: 48.7421938, lng: 19.5336171 },
    { lat: 48.7425614, lng: 19.5340195 },
    { lat: 48.7430652, lng: 19.5339699 },
    { lat: 48.7434295, lng: 19.5340517 },
    { lat: 48.7438259, lng: 19.5343426 },
    { lat: 48.744047, lng: 19.5349378 },
    { lat: 48.7442568, lng: 19.5358256 },
    { lat: 48.7442836, lng: 19.5366336 },
    { lat: 48.7446743, lng: 19.5376824 },
    { lat: 48.7451598, lng: 19.5383932 },
    { lat: 48.7451135, lng: 19.5384148 },
    { lat: 48.74523, lng: 19.5390649 },
    { lat: 48.7455985, lng: 19.5396305 },
    { lat: 48.7459204, lng: 19.5398228 },
    { lat: 48.7459471, lng: 19.53974 },
    { lat: 48.746424, lng: 19.5404817 },
    { lat: 48.7470232, lng: 19.5411385 },
    { lat: 48.7473933, lng: 19.541425 },
    { lat: 48.7477389, lng: 19.5414892 },
    { lat: 48.7478766, lng: 19.5418147 },
    { lat: 48.7487857, lng: 19.5422499 },
    { lat: 48.7489555, lng: 19.5426892 },
    { lat: 48.7492558, lng: 19.5430698 },
    { lat: 48.7494478, lng: 19.5435704 },
    { lat: 48.7497969, lng: 19.5441562 },
    { lat: 48.7501614, lng: 19.5444623 },
    { lat: 48.7503924, lng: 19.5449809 },
    { lat: 48.7506117, lng: 19.544922 },
    { lat: 48.7510285, lng: 19.5449701 },
    { lat: 48.7512997, lng: 19.5447861 },
    { lat: 48.751601, lng: 19.5443275 },
    { lat: 48.7518705, lng: 19.5444318 },
    { lat: 48.7520571, lng: 19.5443958 },
    { lat: 48.7523535, lng: 19.5442009 },
    { lat: 48.7526775, lng: 19.5441145 },
    { lat: 48.7533827, lng: 19.5440133 },
    { lat: 48.7541572, lng: 19.5437715 },
    { lat: 48.7545219, lng: 19.5437623 },
    { lat: 48.7559028, lng: 19.5447128 },
    { lat: 48.7566613, lng: 19.5449095 },
    { lat: 48.7574339, lng: 19.5449762 },
    { lat: 48.7578463, lng: 19.545247 },
    { lat: 48.7581419, lng: 19.5455771 },
    { lat: 48.7586969, lng: 19.5458323 },
    { lat: 48.7592241, lng: 19.5458304 },
    { lat: 48.7602792, lng: 19.5462558 },
    { lat: 48.7609441, lng: 19.5473191 },
    { lat: 48.7612686, lng: 19.5484267 },
    { lat: 48.7616428, lng: 19.5490716 },
    { lat: 48.7621228, lng: 19.5491428 },
    { lat: 48.7626929, lng: 19.5493415 },
    { lat: 48.7629849, lng: 19.5492913 },
    { lat: 48.7632706, lng: 19.5489857 },
    { lat: 48.7636091, lng: 19.5488746 },
    { lat: 48.7637781, lng: 19.5485994 },
    { lat: 48.7640568, lng: 19.5487574 },
    { lat: 48.7643987, lng: 19.5488392 },
    { lat: 48.7647745, lng: 19.5491978 },
    { lat: 48.7653117, lng: 19.549141 },
    { lat: 48.7657491, lng: 19.5487773 },
    { lat: 48.766257, lng: 19.5480052 },
    { lat: 48.7668433, lng: 19.5473224 },
    { lat: 48.7670405, lng: 19.5462123 },
    { lat: 48.7670228, lng: 19.5453561 },
    { lat: 48.767157, lng: 19.5448416 },
    { lat: 48.7680869, lng: 19.5445768 },
    { lat: 48.7690967, lng: 19.544679 },
    { lat: 48.7702948, lng: 19.5439435 },
    { lat: 48.770765, lng: 19.5434596 },
    { lat: 48.7717447, lng: 19.5427515 },
    { lat: 48.7727057, lng: 19.5418836 },
    { lat: 48.7735167, lng: 19.5418044 },
    { lat: 48.7738563, lng: 19.5419146 },
    { lat: 48.7745737, lng: 19.541583 },
    { lat: 48.7755306, lng: 19.5414888 },
    { lat: 48.7754289, lng: 19.5409879 },
    { lat: 48.77526, lng: 19.5405994 },
    { lat: 48.7750997, lng: 19.5398791 },
    { lat: 48.7751393, lng: 19.5394888 },
    { lat: 48.7753008, lng: 19.5391532 },
    { lat: 48.7752779, lng: 19.5389944 },
    { lat: 48.7755843, lng: 19.5388687 },
    { lat: 48.7756153, lng: 19.5387101 },
    { lat: 48.7760325, lng: 19.5378511 },
    { lat: 48.7773055, lng: 19.5366151 },
    { lat: 48.7774633, lng: 19.5363931 },
    { lat: 48.7773764, lng: 19.535578 },
    { lat: 48.7776137, lng: 19.5350261 },
    { lat: 48.7775007, lng: 19.5332769 },
    { lat: 48.7773242, lng: 19.5320524 },
    { lat: 48.7773545, lng: 19.5313647 },
    { lat: 48.7770226, lng: 19.529656 },
    { lat: 48.7768211, lng: 19.528969 },
    { lat: 48.7766868, lng: 19.5281553 },
    { lat: 48.7765996, lng: 19.5278779 },
    { lat: 48.7764248, lng: 19.5276497 },
    { lat: 48.7758934, lng: 19.5263339 },
    { lat: 48.7760325, lng: 19.5261746 },
    { lat: 48.7761363, lng: 19.52588 },
    { lat: 48.7764831, lng: 19.5263663 },
    { lat: 48.7762878, lng: 19.524763 },
    { lat: 48.7761558, lng: 19.52368 },
    { lat: 48.7761317, lng: 19.5221198 },
    { lat: 48.7760229, lng: 19.5212477 },
    { lat: 48.7759954, lng: 19.5195696 },
    { lat: 48.776506, lng: 19.5148902 },
    { lat: 48.776862, lng: 19.5123464 },
    { lat: 48.7772116, lng: 19.5098482 },
  ],
  Jasenie: [
    { lat: 48.940893, lng: 19.507301 },
    { lat: 48.940562, lng: 19.505009 },
    { lat: 48.94029, lng: 19.503387 },
    { lat: 48.94049, lng: 19.501916 },
    { lat: 48.940564, lng: 19.50063 },
    { lat: 48.940991, lng: 19.496798 },
    { lat: 48.941231, lng: 19.495536 },
    { lat: 48.941356, lng: 19.494885 },
    { lat: 48.942147, lng: 19.493588 },
    { lat: 48.942345, lng: 19.492641 },
    { lat: 48.943267, lng: 19.491013 },
    { lat: 48.942045, lng: 19.488524 },
    { lat: 48.941189, lng: 19.487704 },
    { lat: 48.9402, lng: 19.486059 },
    { lat: 48.939843, lng: 19.483882 },
    { lat: 48.939931, lng: 19.483349 },
    { lat: 48.940233, lng: 19.481461 },
    { lat: 48.940235, lng: 19.480694 },
    { lat: 48.939801, lng: 19.480325 },
    { lat: 48.93871, lng: 19.479161 },
    { lat: 48.938, lng: 19.477297 },
    { lat: 48.937851, lng: 19.475272 },
    { lat: 48.937638, lng: 19.47166 },
    { lat: 48.936378, lng: 19.465182 },
    { lat: 48.934155, lng: 19.463649 },
    { lat: 48.931953, lng: 19.461705 },
    { lat: 48.930991, lng: 19.458818 },
    { lat: 48.931135, lng: 19.458344 },
    { lat: 48.931007, lng: 19.457321 },
    { lat: 48.931041, lng: 19.455844 },
    { lat: 48.931131, lng: 19.455048 },
    { lat: 48.93133, lng: 19.453228 },
    { lat: 48.92893, lng: 19.449285 },
    { lat: 48.928523, lng: 19.448368 },
    { lat: 48.927448, lng: 19.440842 },
    { lat: 48.927753, lng: 19.436961 },
    { lat: 48.92749, lng: 19.436643 },
    { lat: 48.926988, lng: 19.429419 },
    { lat: 48.926978, lng: 19.429226 },
    { lat: 48.927236, lng: 19.428402 },
    { lat: 48.927282, lng: 19.427813 },
    { lat: 48.927268, lng: 19.427584 },
    { lat: 48.927282, lng: 19.427334 },
    { lat: 48.927258, lng: 19.426911 },
    { lat: 48.927275, lng: 19.426471 },
    { lat: 48.927352, lng: 19.426204 },
    { lat: 48.92735, lng: 19.425783 },
    { lat: 48.927375, lng: 19.425405 },
    { lat: 48.927348, lng: 19.424888 },
    { lat: 48.927369, lng: 19.424483 },
    { lat: 48.927169, lng: 19.422374 },
    { lat: 48.927167, lng: 19.421771 },
    { lat: 48.927194, lng: 19.420979 },
    { lat: 48.927047, lng: 19.420546 },
    { lat: 48.927125, lng: 19.419963 },
    { lat: 48.926895, lng: 19.418936 },
    { lat: 48.92691, lng: 19.418425 },
    { lat: 48.926981, lng: 19.417768 },
    { lat: 48.927059, lng: 19.417174 },
    { lat: 48.92763, lng: 19.414472 },
    { lat: 48.92773, lng: 19.414008 },
    { lat: 48.927781, lng: 19.412724 },
    { lat: 48.927752, lng: 19.412297 },
    { lat: 48.927492, lng: 19.410358 },
    { lat: 48.927197, lng: 19.408853 },
    { lat: 48.926936, lng: 19.406787 },
    { lat: 48.924936, lng: 19.404556 },
    { lat: 48.925313, lng: 19.403874 },
    { lat: 48.9257, lng: 19.403274 },
    { lat: 48.926011, lng: 19.401926 },
    { lat: 48.926156, lng: 19.401368 },
    { lat: 48.927675, lng: 19.395335 },
    { lat: 48.926555, lng: 19.390772 },
    { lat: 48.926683, lng: 19.388479 },
    { lat: 48.926153, lng: 19.384609 },
    { lat: 48.925557, lng: 19.383499 },
    { lat: 48.923261, lng: 19.37922 },
    { lat: 48.921595, lng: 19.37716 },
    { lat: 48.919167, lng: 19.375144 },
    { lat: 48.916746, lng: 19.371972 },
    { lat: 48.914809, lng: 19.369639 },
    { lat: 48.914131, lng: 19.368823 },
    { lat: 48.912148, lng: 19.366436 },
    { lat: 48.910419, lng: 19.365805 },
    { lat: 48.90935, lng: 19.364939 },
    { lat: 48.909069, lng: 19.36473 },
    { lat: 48.908793, lng: 19.364353 },
    { lat: 48.908234, lng: 19.363942 },
    { lat: 48.90773, lng: 19.363253 },
    { lat: 48.907143, lng: 19.36207 },
    { lat: 48.906623, lng: 19.360755 },
    { lat: 48.904849, lng: 19.359326 },
    { lat: 48.903951, lng: 19.357463 },
    { lat: 48.903666, lng: 19.356515 },
    { lat: 48.901704, lng: 19.355526 },
    { lat: 48.901322, lng: 19.355469 },
    { lat: 48.901049, lng: 19.354972 },
    { lat: 48.900682, lng: 19.354676 },
    { lat: 48.900545, lng: 19.354471 },
    { lat: 48.899953, lng: 19.353485 },
    { lat: 48.898065, lng: 19.351284 },
    { lat: 48.897577, lng: 19.350434 },
    { lat: 48.895053, lng: 19.352035 },
    { lat: 48.894359, lng: 19.352623 },
    { lat: 48.892517, lng: 19.354061 },
    { lat: 48.891905, lng: 19.354823 },
    { lat: 48.891131, lng: 19.355554 },
    { lat: 48.890656, lng: 19.356318 },
    { lat: 48.889542, lng: 19.357817 },
    { lat: 48.889074, lng: 19.35805 },
    { lat: 48.887982, lng: 19.358367 },
    { lat: 48.887693, lng: 19.358452 },
    { lat: 48.886696, lng: 19.359815 },
    { lat: 48.885361, lng: 19.363558 },
    { lat: 48.885455, lng: 19.365524 },
    { lat: 48.885456, lng: 19.370789 },
    { lat: 48.885455, lng: 19.371639 },
    { lat: 48.885456, lng: 19.372348 },
    { lat: 48.885015, lng: 19.373435 },
    { lat: 48.884059, lng: 19.375791 },
    { lat: 48.883903, lng: 19.376177 },
    { lat: 48.883272, lng: 19.377201 },
    { lat: 48.882648, lng: 19.37785 },
    { lat: 48.882419, lng: 19.378089 },
    { lat: 48.882322, lng: 19.379233 },
    { lat: 48.882142, lng: 19.381378 },
    { lat: 48.882, lng: 19.382246 },
    { lat: 48.881847, lng: 19.383181 },
    { lat: 48.881904, lng: 19.383876 },
    { lat: 48.881992, lng: 19.384932 },
    { lat: 48.880721, lng: 19.385137 },
    { lat: 48.878904, lng: 19.385175 },
    { lat: 48.877352, lng: 19.384959 },
    { lat: 48.876067, lng: 19.384983 },
    { lat: 48.875556, lng: 19.385413 },
    { lat: 48.874899, lng: 19.386093 },
    { lat: 48.874742, lng: 19.386349 },
    { lat: 48.874608, lng: 19.386568 },
    { lat: 48.873983, lng: 19.387306 },
    { lat: 48.873602, lng: 19.387755 },
    { lat: 48.873214, lng: 19.388523 },
    { lat: 48.87242, lng: 19.389834 },
    { lat: 48.871888, lng: 19.390486 },
    { lat: 48.871663, lng: 19.390762 },
    { lat: 48.871007, lng: 19.391681 },
    { lat: 48.870558, lng: 19.392131 },
    { lat: 48.869832, lng: 19.392858 },
    { lat: 48.869009, lng: 19.39344 },
    { lat: 48.868614, lng: 19.393653 },
    { lat: 48.868114, lng: 19.393836 },
    { lat: 48.867352, lng: 19.394315 },
    { lat: 48.866413, lng: 19.396124 },
    { lat: 48.866215, lng: 19.39641 },
    { lat: 48.865901, lng: 19.39685 },
    { lat: 48.865574, lng: 19.397486 },
    { lat: 48.865107, lng: 19.397999 },
    { lat: 48.864277, lng: 19.398602 },
    { lat: 48.863427, lng: 19.398983 },
    { lat: 48.862908, lng: 19.399143 },
    { lat: 48.862548, lng: 19.39932 },
    { lat: 48.861525, lng: 19.399956 },
    { lat: 48.860863, lng: 19.40046 },
    { lat: 48.860259, lng: 19.400583 },
    { lat: 48.859044, lng: 19.400017 },
    { lat: 48.858449, lng: 19.399872 },
    { lat: 48.857693, lng: 19.399981 },
    { lat: 48.856854, lng: 19.400524 },
    { lat: 48.856406, lng: 19.400824 },
    { lat: 48.856123, lng: 19.40108 },
    { lat: 48.855297, lng: 19.401897 },
    { lat: 48.854458, lng: 19.402121 },
    { lat: 48.853513, lng: 19.403018 },
    { lat: 48.8533092, lng: 19.4032233 },
    { lat: 48.8516676, lng: 19.4042892 },
    { lat: 48.8506338, lng: 19.4053899 },
    { lat: 48.8501941, lng: 19.4061053 },
    { lat: 48.849901, lng: 19.4068961 },
    { lat: 48.8494479, lng: 19.4090425 },
    { lat: 48.8495019, lng: 19.4114918 },
    { lat: 48.8494027, lng: 19.4120714 },
    { lat: 48.8491959, lng: 19.4128551 },
    { lat: 48.8486318, lng: 19.4139727 },
    { lat: 48.8483903, lng: 19.4140842 },
    { lat: 48.8481312, lng: 19.4144022 },
    { lat: 48.8480595, lng: 19.4148182 },
    { lat: 48.8480284, lng: 19.4152133 },
    { lat: 48.8480044, lng: 19.4172057 },
    { lat: 48.8482102, lng: 19.4196322 },
    { lat: 48.8475423, lng: 19.4198595 },
    { lat: 48.8470269, lng: 19.4201138 },
    { lat: 48.8440975, lng: 19.4217944 },
    { lat: 48.8410317, lng: 19.4258125 },
    { lat: 48.8398338, lng: 19.4263698 },
    { lat: 48.839548, lng: 19.4269607 },
    { lat: 48.8393728, lng: 19.4277461 },
    { lat: 48.8392362, lng: 19.4293571 },
    { lat: 48.8383299, lng: 19.4325901 },
    { lat: 48.8377357, lng: 19.4333084 },
    { lat: 48.837154, lng: 19.4325557 },
    { lat: 48.8370056, lng: 19.4329486 },
    { lat: 48.8368928, lng: 19.4334318 },
    { lat: 48.8364401, lng: 19.4340662 },
    { lat: 48.8363953, lng: 19.4358361 },
    { lat: 48.8362243, lng: 19.436352 },
    { lat: 48.8346881, lng: 19.4390829 },
    { lat: 48.8326676, lng: 19.4418414 },
    { lat: 48.832038, lng: 19.4428662 },
    { lat: 48.831828, lng: 19.4435844 },
    { lat: 48.8297864, lng: 19.4479279 },
    { lat: 48.8290335, lng: 19.4492118 },
    { lat: 48.8280861, lng: 19.4516441 },
    { lat: 48.8277477, lng: 19.452387 },
    { lat: 48.8269681, lng: 19.4549883 },
    { lat: 48.8268759, lng: 19.4547972 },
    { lat: 48.8266352, lng: 19.4551014 },
    { lat: 48.8262012, lng: 19.4552907 },
    { lat: 48.8267249, lng: 19.4565236 },
    { lat: 48.8269538, lng: 19.4570604 },
    { lat: 48.8270996, lng: 19.457189 },
    { lat: 48.8273003, lng: 19.4578944 },
    { lat: 48.8266271, lng: 19.4581299 },
    { lat: 48.826034, lng: 19.4584841 },
    { lat: 48.8247299, lng: 19.4594728 },
    { lat: 48.8246639, lng: 19.4605048 },
    { lat: 48.8246717, lng: 19.4618641 },
    { lat: 48.8249555, lng: 19.4615671 },
    { lat: 48.8254474, lng: 19.461485 },
    { lat: 48.8268257, lng: 19.4614843 },
    { lat: 48.8276993, lng: 19.4617551 },
    { lat: 48.827768, lng: 19.4629009 },
    { lat: 48.8278724, lng: 19.4628658 },
    { lat: 48.8278785, lng: 19.4635315 },
    { lat: 48.8273033, lng: 19.4635222 },
    { lat: 48.8272426, lng: 19.4636249 },
    { lat: 48.8272703, lng: 19.4636609 },
    { lat: 48.8272329, lng: 19.4641431 },
    { lat: 48.8273045, lng: 19.4646183 },
    { lat: 48.830276, lng: 19.4650745 },
    { lat: 48.8311745, lng: 19.4651077 },
    { lat: 48.8314623, lng: 19.4652395 },
    { lat: 48.8321864, lng: 19.4653224 },
    { lat: 48.8326666, lng: 19.4676132 },
    { lat: 48.8352427, lng: 19.4673436 },
    { lat: 48.8353041, lng: 19.4677707 },
    { lat: 48.8355975, lng: 19.4689206 },
    { lat: 48.8357629, lng: 19.468893 },
    { lat: 48.837113, lng: 19.4695512 },
    { lat: 48.8389341, lng: 19.4708867 },
    { lat: 48.8391285, lng: 19.4711941 },
    { lat: 48.8453523, lng: 19.4791346 },
    { lat: 48.8459199, lng: 19.4810276 },
    { lat: 48.8466115, lng: 19.4825349 },
    { lat: 48.847361, lng: 19.4851994 },
    { lat: 48.8476168, lng: 19.4847547 },
    { lat: 48.8479093, lng: 19.4845745 },
    { lat: 48.8481737, lng: 19.4845415 },
    { lat: 48.8486047, lng: 19.48465 },
    { lat: 48.8489733, lng: 19.4845319 },
    { lat: 48.849336, lng: 19.4846033 },
    { lat: 48.8502353, lng: 19.484089 },
    { lat: 48.8507327, lng: 19.4839779 },
    { lat: 48.8519894, lng: 19.4842283 },
    { lat: 48.8523276, lng: 19.4845914 },
    { lat: 48.8525585, lng: 19.4850833 },
    { lat: 48.8529337, lng: 19.4852965 },
    { lat: 48.853503, lng: 19.4853056 },
    { lat: 48.8546263, lng: 19.4845431 },
    { lat: 48.8550539, lng: 19.4845278 },
    { lat: 48.8558747, lng: 19.4848334 },
    { lat: 48.85684, lng: 19.4846992 },
    { lat: 48.8572027, lng: 19.4850623 },
    { lat: 48.8573033, lng: 19.4852416 },
    { lat: 48.8575035, lng: 19.4853285 },
    { lat: 48.8580227, lng: 19.4849321 },
    { lat: 48.8582826, lng: 19.4850437 },
    { lat: 48.8590459, lng: 19.4854964 },
    { lat: 48.8595883, lng: 19.4860126 },
    { lat: 48.8599043, lng: 19.4861469 },
    { lat: 48.8610093, lng: 19.4861844 },
    { lat: 48.8615648, lng: 19.486278 },
    { lat: 48.8621793, lng: 19.4860186 },
    { lat: 48.8625388, lng: 19.4860477 },
    { lat: 48.8635583, lng: 19.4855979 },
    { lat: 48.8641979, lng: 19.4859044 },
    { lat: 48.8646066, lng: 19.4860197 },
    { lat: 48.8648286, lng: 19.4863376 },
    { lat: 48.8657993, lng: 19.4862494 },
    { lat: 48.8663317, lng: 19.4857459 },
    { lat: 48.8668824, lng: 19.485593 },
    { lat: 48.8670782, lng: 19.485855 },
    { lat: 48.8673109, lng: 19.48599 },
    { lat: 48.867415, lng: 19.4862922 },
    { lat: 48.8678107, lng: 19.4862237 },
    { lat: 48.8678756, lng: 19.4867033 },
    { lat: 48.8682366, lng: 19.4869077 },
    { lat: 48.8691509, lng: 19.4869883 },
    { lat: 48.8700922, lng: 19.4876814 },
    { lat: 48.8705299, lng: 19.487106 },
    { lat: 48.8706555, lng: 19.4870171 },
    { lat: 48.870942, lng: 19.4870448 },
    { lat: 48.8710523, lng: 19.4869697 },
    { lat: 48.8710658, lng: 19.4871183 },
    { lat: 48.871272, lng: 19.4871408 },
    { lat: 48.8716499, lng: 19.4874176 },
    { lat: 48.871618, lng: 19.4876318 },
    { lat: 48.871726, lng: 19.487656 },
    { lat: 48.8719198, lng: 19.4876993 },
    { lat: 48.872233, lng: 19.4876303 },
    { lat: 48.873004, lng: 19.4874605 },
    { lat: 48.8732897, lng: 19.4872819 },
    { lat: 48.8737762, lng: 19.4869778 },
    { lat: 48.8739845, lng: 19.4868476 },
    { lat: 48.8750807, lng: 19.4864285 },
    { lat: 48.8763333, lng: 19.4865914 },
    { lat: 48.8764153, lng: 19.4866579 },
    { lat: 48.8765422, lng: 19.4864515 },
    { lat: 48.8766888, lng: 19.4859175 },
    { lat: 48.8773285, lng: 19.4860033 },
    { lat: 48.8778862, lng: 19.4858332 },
    { lat: 48.8784802, lng: 19.4858615 },
    { lat: 48.8792481, lng: 19.4856777 },
    { lat: 48.882959, lng: 19.4853858 },
    { lat: 48.8832476, lng: 19.4858515 },
    { lat: 48.8837826, lng: 19.4863318 },
    { lat: 48.8841729, lng: 19.4874169 },
    { lat: 48.8851102, lng: 19.4892679 },
    { lat: 48.8852556, lng: 19.4894401 },
    { lat: 48.8854571, lng: 19.4903905 },
    { lat: 48.8857308, lng: 19.4911439 },
    { lat: 48.8869357, lng: 19.4921753 },
    { lat: 48.8869889, lng: 19.4924293 },
    { lat: 48.8902791, lng: 19.4926935 },
    { lat: 48.8954334, lng: 19.5005776 },
    { lat: 48.8966509, lng: 19.5004663 },
    { lat: 48.8971566, lng: 19.5003341 },
    { lat: 48.8987882, lng: 19.4995472 },
    { lat: 48.8992029, lng: 19.4995023 },
    { lat: 48.8996899, lng: 19.4993082 },
    { lat: 48.9008993, lng: 19.4990205 },
    { lat: 48.9010601, lng: 19.4990614 },
    { lat: 48.9016256, lng: 19.4986649 },
    { lat: 48.9023238, lng: 19.498413 },
    { lat: 48.90241, lng: 19.4974676 },
    { lat: 48.9023263, lng: 19.497239 },
    { lat: 48.9026616, lng: 19.4961784 },
    { lat: 48.9033254, lng: 19.4960802 },
    { lat: 48.9039684, lng: 19.4950439 },
    { lat: 48.9034812, lng: 19.4937859 },
    { lat: 48.9037013, lng: 19.493038 },
    { lat: 48.9041994, lng: 19.4919572 },
    { lat: 48.9047611, lng: 19.4926083 },
    { lat: 48.9059197, lng: 19.4916051 },
    { lat: 48.907695, lng: 19.4912525 },
    { lat: 48.9095592, lng: 19.4892251 },
    { lat: 48.913986, lng: 19.4891637 },
    { lat: 48.9118825, lng: 19.4946054 },
    { lat: 48.9132139, lng: 19.4973107 },
    { lat: 48.9135824, lng: 19.4979255 },
    { lat: 48.9136621, lng: 19.4990478 },
    { lat: 48.9144619, lng: 19.499823 },
    { lat: 48.9149856, lng: 19.5010186 },
    { lat: 48.9162436, lng: 19.502281 },
    { lat: 48.9167393, lng: 19.5021283 },
    { lat: 48.917188, lng: 19.503409 },
    { lat: 48.9172248, lng: 19.5034543 },
    { lat: 48.9180322, lng: 19.5045103 },
    { lat: 48.9181973, lng: 19.5046537 },
    { lat: 48.9195898, lng: 19.5054301 },
    { lat: 48.9214374, lng: 19.5075065 },
    { lat: 48.922794, lng: 19.5099104 },
    { lat: 48.923136, lng: 19.5103899 },
    { lat: 48.9238764, lng: 19.510588 },
    { lat: 48.9247569, lng: 19.5112453 },
    { lat: 48.9248074, lng: 19.5112413 },
    { lat: 48.9252595, lng: 19.5115585 },
    { lat: 48.9268686, lng: 19.5135228 },
    { lat: 48.9278515, lng: 19.5138702 },
    { lat: 48.928957, lng: 19.5171207 },
    { lat: 48.9290578, lng: 19.5179924 },
    { lat: 48.9295075, lng: 19.518685 },
    { lat: 48.9303361, lng: 19.5183063 },
    { lat: 48.9303969, lng: 19.5183693 },
    { lat: 48.9330498, lng: 19.5193134 },
    { lat: 48.9331052, lng: 19.5193412 },
    { lat: 48.9331661, lng: 19.5193537 },
    { lat: 48.9345325, lng: 19.5201185 },
    { lat: 48.9348638, lng: 19.5206595 },
    { lat: 48.9359527, lng: 19.5196608 },
    { lat: 48.936572, lng: 19.5188212 },
    { lat: 48.9366336, lng: 19.518803 },
    { lat: 48.9372472, lng: 19.5183523 },
    { lat: 48.9374371, lng: 19.5182191 },
    { lat: 48.9381804, lng: 19.5176667 },
    { lat: 48.938864, lng: 19.517291 },
    { lat: 48.939137, lng: 19.515825 },
    { lat: 48.939225, lng: 19.515358 },
    { lat: 48.939647, lng: 19.51227 },
    { lat: 48.939683, lng: 19.512003 },
    { lat: 48.940445, lng: 19.508514 },
    { lat: 48.940893, lng: 19.507301 },
  ],
  Pohorelá: [
    { lat: 48.8471264, lng: 20.0460891 },
    { lat: 48.8471788, lng: 20.046181 },
    { lat: 48.8473228, lng: 20.0462339 },
    { lat: 48.8478326, lng: 20.0461984 },
    { lat: 48.8479006, lng: 20.046261 },
    { lat: 48.8480889, lng: 20.0462678 },
    { lat: 48.8483794, lng: 20.0461001 },
    { lat: 48.8485344, lng: 20.0461437 },
    { lat: 48.8489162, lng: 20.0460049 },
    { lat: 48.8490834, lng: 20.0460173 },
    { lat: 48.8492992, lng: 20.0461459 },
    { lat: 48.8498218, lng: 20.0461708 },
    { lat: 48.8499392, lng: 20.0460699 },
    { lat: 48.8501299, lng: 20.0460754 },
    { lat: 48.8501691, lng: 20.0459901 },
    { lat: 48.8503687, lng: 20.0460517 },
    { lat: 48.8506198, lng: 20.0459649 },
    { lat: 48.8507165, lng: 20.0458259 },
    { lat: 48.8507282, lng: 20.0456174 },
    { lat: 48.8506732, lng: 20.0455418 },
    { lat: 48.8507324, lng: 20.0454446 },
    { lat: 48.850992, lng: 20.0453821 },
    { lat: 48.8512021, lng: 20.0450904 },
    { lat: 48.8512749, lng: 20.0450824 },
    { lat: 48.8513885, lng: 20.045136 },
    { lat: 48.851648, lng: 20.0451153 },
    { lat: 48.8521021, lng: 20.0453952 },
    { lat: 48.8523793, lng: 20.0453689 },
    { lat: 48.8529363, lng: 20.0455221 },
    { lat: 48.8530764, lng: 20.0456694 },
    { lat: 48.8531345, lng: 20.0456013 },
    { lat: 48.8535282, lng: 20.0458065 },
    { lat: 48.8536695, lng: 20.0457069 },
    { lat: 48.8539225, lng: 20.045811 },
    { lat: 48.8540657, lng: 20.0460023 },
    { lat: 48.8541171, lng: 20.0459681 },
    { lat: 48.8542714, lng: 20.0462403 },
    { lat: 48.8543619, lng: 20.0463058 },
    { lat: 48.8544159, lng: 20.0462481 },
    { lat: 48.8552438, lng: 20.046983 },
    { lat: 48.8553005, lng: 20.0472412 },
    { lat: 48.8553881, lng: 20.0472611 },
    { lat: 48.8554222, lng: 20.0474578 },
    { lat: 48.8557755, lng: 20.0478056 },
    { lat: 48.8559784, lng: 20.0487074 },
    { lat: 48.8559422, lng: 20.0489798 },
    { lat: 48.8559753, lng: 20.0492317 },
    { lat: 48.8559031, lng: 20.0493133 },
    { lat: 48.8559201, lng: 20.0500804 },
    { lat: 48.8558599, lng: 20.050423 },
    { lat: 48.8558631, lng: 20.0508544 },
    { lat: 48.8560009, lng: 20.0515245 },
    { lat: 48.8563034, lng: 20.0522992 },
    { lat: 48.8565541, lng: 20.0526163 },
    { lat: 48.8567221, lng: 20.0530043 },
    { lat: 48.8570764, lng: 20.0534278 },
    { lat: 48.8571715, lng: 20.0534385 },
    { lat: 48.8574299, lng: 20.0537538 },
    { lat: 48.8580219, lng: 20.0541775 },
    { lat: 48.8585137, lng: 20.0546646 },
    { lat: 48.8590826, lng: 20.0553748 },
    { lat: 48.8602687, lng: 20.0574013 },
    { lat: 48.8603991, lng: 20.0574998 },
    { lat: 48.8606867, lng: 20.0579795 },
    { lat: 48.8608653, lng: 20.0581851 },
    { lat: 48.8610853, lng: 20.0582194 },
    { lat: 48.8615419, lng: 20.0586988 },
    { lat: 48.8617226, lng: 20.0590122 },
    { lat: 48.8620756, lng: 20.0592386 },
    { lat: 48.8628546, lng: 20.060325 },
    { lat: 48.862908, lng: 20.0605267 },
    { lat: 48.8628765, lng: 20.0614018 },
    { lat: 48.862641, lng: 20.0620279 },
    { lat: 48.8624714, lng: 20.0621809 },
    { lat: 48.8620183, lng: 20.0629664 },
    { lat: 48.8618748, lng: 20.0634439 },
    { lat: 48.8617824, lng: 20.0641874 },
    { lat: 48.8619547, lng: 20.0650293 },
    { lat: 48.8623319, lng: 20.065771 },
    { lat: 48.8623829, lng: 20.0661847 },
    { lat: 48.8624812, lng: 20.0664122 },
    { lat: 48.8625848, lng: 20.0674853 },
    { lat: 48.8625155, lng: 20.0680734 },
    { lat: 48.8624828, lng: 20.0689733 },
    { lat: 48.862584, lng: 20.0695458 },
    { lat: 48.8629849, lng: 20.0698195 },
    { lat: 48.8640843, lng: 20.0691348 },
    { lat: 48.8641162, lng: 20.0689816 },
    { lat: 48.8642569, lng: 20.0688674 },
    { lat: 48.8643054, lng: 20.0687044 },
    { lat: 48.8651526, lng: 20.0679995 },
    { lat: 48.8674739, lng: 20.068215 },
    { lat: 48.8717607, lng: 20.0719502 },
    { lat: 48.8736952, lng: 20.0722393 },
    { lat: 48.8805857, lng: 20.0738215 },
    { lat: 48.8877537, lng: 20.0815767 },
    { lat: 48.8890629, lng: 20.0864319 },
    { lat: 48.896721, lng: 20.090055 },
    { lat: 48.89695, lng: 20.081345 },
    { lat: 48.898134, lng: 20.074984 },
    { lat: 48.898449, lng: 20.072348 },
    { lat: 48.898553, lng: 20.071265 },
    { lat: 48.897755, lng: 20.067861 },
    { lat: 48.896708, lng: 20.06585 },
    { lat: 48.896651, lng: 20.065623 },
    { lat: 48.896769, lng: 20.065181 },
    { lat: 48.896894, lng: 20.064448 },
    { lat: 48.896895, lng: 20.064184 },
    { lat: 48.897002, lng: 20.063692 },
    { lat: 48.897006, lng: 20.063426 },
    { lat: 48.897076, lng: 20.062693 },
    { lat: 48.8971, lng: 20.061712 },
    { lat: 48.897046, lng: 20.059146 },
    { lat: 48.89701, lng: 20.058921 },
    { lat: 48.896948, lng: 20.058179 },
    { lat: 48.896981, lng: 20.05723 },
    { lat: 48.89699, lng: 20.056517 },
    { lat: 48.897022, lng: 20.056032 },
    { lat: 48.897151, lng: 20.055569 },
    { lat: 48.897282, lng: 20.05441 },
    { lat: 48.897421, lng: 20.05353 },
    { lat: 48.897881, lng: 20.052086 },
    { lat: 48.897923, lng: 20.052019 },
    { lat: 48.899854, lng: 20.049705 },
    { lat: 48.900032, lng: 20.048365 },
    { lat: 48.900665, lng: 20.044158 },
    { lat: 48.901436, lng: 20.034099 },
    { lat: 48.90089, lng: 20.031868 },
    { lat: 48.900233, lng: 20.030241 },
    { lat: 48.900389, lng: 20.027409 },
    { lat: 48.900695, lng: 20.021153 },
    { lat: 48.900793, lng: 20.019167 },
    { lat: 48.901158, lng: 20.015253 },
    { lat: 48.901889, lng: 20.012841 },
    { lat: 48.902256, lng: 20.010718 },
    { lat: 48.902551, lng: 20.009832 },
    { lat: 48.902959, lng: 20.008759 },
    { lat: 48.903629, lng: 20.005743 },
    { lat: 48.903778, lng: 20.00515 },
    { lat: 48.903776, lng: 20.004597 },
    { lat: 48.904051, lng: 20.00389 },
    { lat: 48.903961, lng: 20.003552 },
    { lat: 48.904063, lng: 20.002915 },
    { lat: 48.904336, lng: 20.001219 },
    { lat: 48.904096, lng: 19.996381 },
    { lat: 48.904831, lng: 19.995546 },
    { lat: 48.9026759, lng: 19.9964582 },
    { lat: 48.9004916, lng: 19.9945665 },
    { lat: 48.899775, lng: 19.9931287 },
    { lat: 48.8976905, lng: 19.9926775 },
    { lat: 48.8973824, lng: 19.9925571 },
    { lat: 48.8956757, lng: 19.9915231 },
    { lat: 48.8940873, lng: 19.9917063 },
    { lat: 48.8921685, lng: 19.9915858 },
    { lat: 48.8918579, lng: 19.9917263 },
    { lat: 48.8912388, lng: 19.9913039 },
    { lat: 48.8908826, lng: 19.9913531 },
    { lat: 48.8899245, lng: 19.9912836 },
    { lat: 48.8896439, lng: 19.991094 },
    { lat: 48.8888596, lng: 19.9913543 },
    { lat: 48.8869877, lng: 19.9907619 },
    { lat: 48.8862927, lng: 19.9903573 },
    { lat: 48.8855954, lng: 19.9900559 },
    { lat: 48.8852613, lng: 19.9901503 },
    { lat: 48.884045, lng: 19.9900476 },
    { lat: 48.8830621, lng: 19.9902436 },
    { lat: 48.8827811, lng: 19.9898286 },
    { lat: 48.8825381, lng: 19.9897514 },
    { lat: 48.8815995, lng: 19.9899918 },
    { lat: 48.8806106, lng: 19.9903669 },
    { lat: 48.8804135, lng: 19.9899374 },
    { lat: 48.8801147, lng: 19.9900112 },
    { lat: 48.8793407, lng: 19.9900042 },
    { lat: 48.8785612, lng: 19.9901152 },
    { lat: 48.8781402, lng: 19.9902801 },
    { lat: 48.8780384, lng: 19.9904098 },
    { lat: 48.8778291, lng: 19.9905165 },
    { lat: 48.8765249, lng: 19.9910063 },
    { lat: 48.8751393, lng: 19.9917611 },
    { lat: 48.8731625, lng: 19.9924896 },
    { lat: 48.8728187, lng: 19.9926733 },
    { lat: 48.8728111, lng: 19.9929209 },
    { lat: 48.8726018, lng: 19.9930587 },
    { lat: 48.8714753, lng: 19.9935478 },
    { lat: 48.8710444, lng: 19.993641 },
    { lat: 48.86977, lng: 19.9937063 },
    { lat: 48.8685802, lng: 19.9942379 },
    { lat: 48.8684441, lng: 19.9942187 },
    { lat: 48.867608, lng: 19.9945629 },
    { lat: 48.8671688, lng: 19.9946702 },
    { lat: 48.8657884, lng: 19.9947939 },
    { lat: 48.8636737, lng: 19.9940493 },
    { lat: 48.8620196, lng: 19.9940626 },
    { lat: 48.8616657, lng: 19.9940039 },
    { lat: 48.8606265, lng: 19.9935859 },
    { lat: 48.8603677, lng: 19.9935896 },
    { lat: 48.8599315, lng: 19.9934765 },
    { lat: 48.8595706, lng: 19.9932221 },
    { lat: 48.8582438, lng: 19.9926028 },
    { lat: 48.8580292, lng: 19.9925629 },
    { lat: 48.857888, lng: 19.9929567 },
    { lat: 48.8567319, lng: 19.9926229 },
    { lat: 48.8562515, lng: 19.992175 },
    { lat: 48.8558585, lng: 19.9919101 },
    { lat: 48.8558773, lng: 19.9917949 },
    { lat: 48.8553524, lng: 19.9913383 },
    { lat: 48.8538253, lng: 19.9902655 },
    { lat: 48.8520632, lng: 19.9870495 },
    { lat: 48.8516445, lng: 19.9867564 },
    { lat: 48.8515396, lng: 19.9864589 },
    { lat: 48.8514498, lng: 19.9864124 },
    { lat: 48.8512551, lng: 19.9860623 },
    { lat: 48.8506766, lng: 19.9856175 },
    { lat: 48.8497943, lng: 19.9851557 },
    { lat: 48.849511, lng: 19.9848138 },
    { lat: 48.8492827, lng: 19.9847975 },
    { lat: 48.8488183, lng: 19.9841722 },
    { lat: 48.8483958, lng: 19.9837096 },
    { lat: 48.8482961, lng: 19.9837489 },
    { lat: 48.8478984, lng: 19.9832635 },
    { lat: 48.8474855, lng: 19.982978 },
    { lat: 48.8469058, lng: 19.9827201 },
    { lat: 48.8465075, lng: 19.9826493 },
    { lat: 48.8459059, lng: 19.9826459 },
    { lat: 48.8443559, lng: 19.9823192 },
    { lat: 48.8423621, lng: 19.982319 },
    { lat: 48.8416242, lng: 19.9822383 },
    { lat: 48.8412955, lng: 19.9823196 },
    { lat: 48.8403712, lng: 19.982309 },
    { lat: 48.8394136, lng: 19.9819268 },
    { lat: 48.8388272, lng: 19.9815358 },
    { lat: 48.8383368, lng: 19.9814964 },
    { lat: 48.8371333, lng: 19.9810024 },
    { lat: 48.835666, lng: 19.9799062 },
    { lat: 48.8355761, lng: 19.9799424 },
    { lat: 48.8346832, lng: 19.9797836 },
    { lat: 48.834598, lng: 19.9799599 },
    { lat: 48.8339029, lng: 19.9794421 },
    { lat: 48.8336233, lng: 19.9794389 },
    { lat: 48.8327633, lng: 19.9796099 },
    { lat: 48.8319316, lng: 19.9806487 },
    { lat: 48.8311299, lng: 19.9812784 },
    { lat: 48.8308158, lng: 19.9813079 },
    { lat: 48.8302417, lng: 19.9821524 },
    { lat: 48.8301314, lng: 19.9828559 },
    { lat: 48.8298806, lng: 19.9830201 },
    { lat: 48.8294596, lng: 19.9834574 },
    { lat: 48.8290332, lng: 19.9841368 },
    { lat: 48.8289428, lng: 19.9844026 },
    { lat: 48.8282611, lng: 19.9848023 },
    { lat: 48.8258966, lng: 19.984443 },
    { lat: 48.8258027, lng: 19.9842747 },
    { lat: 48.8255817, lng: 19.984189 },
    { lat: 48.8253617, lng: 19.9842609 },
    { lat: 48.825192, lng: 19.9841878 },
    { lat: 48.8246193, lng: 19.9843672 },
    { lat: 48.8242588, lng: 19.9846867 },
    { lat: 48.8241372, lng: 19.9848775 },
    { lat: 48.8239423, lng: 19.9849012 },
    { lat: 48.8235022, lng: 19.984696 },
    { lat: 48.8230564, lng: 19.9846759 },
    { lat: 48.8220021, lng: 19.9851842 },
    { lat: 48.8217212, lng: 19.9854221 },
    { lat: 48.8212893, lng: 19.9856476 },
    { lat: 48.821096, lng: 19.9859307 },
    { lat: 48.8210329, lng: 19.9861137 },
    { lat: 48.8208198, lng: 19.9863518 },
    { lat: 48.8200492, lng: 19.986676 },
    { lat: 48.8197816, lng: 19.9867106 },
    { lat: 48.8194808, lng: 19.9870368 },
    { lat: 48.8192777, lng: 19.987106 },
    { lat: 48.8188146, lng: 19.9878006 },
    { lat: 48.8185694, lng: 19.9880775 },
    { lat: 48.8183646, lng: 19.9884649 },
    { lat: 48.8179923, lng: 19.9887455 },
    { lat: 48.8176639, lng: 19.9888888 },
    { lat: 48.8173663, lng: 19.9891854 },
    { lat: 48.8167769, lng: 19.9894718 },
    { lat: 48.8164593, lng: 19.9897446 },
    { lat: 48.8160685, lng: 19.9896895 },
    { lat: 48.8154428, lng: 19.9898949 },
    { lat: 48.8148552, lng: 19.9898758 },
    { lat: 48.8147448, lng: 19.9901416 },
    { lat: 48.8146034, lng: 19.9902649 },
    { lat: 48.8145061, lng: 19.9905233 },
    { lat: 48.8143936, lng: 19.9906349 },
    { lat: 48.8138897, lng: 19.9908651 },
    { lat: 48.8138814, lng: 19.9913165 },
    { lat: 48.813755, lng: 19.9919302 },
    { lat: 48.8128608, lng: 19.9927699 },
    { lat: 48.8126191, lng: 19.9928588 },
    { lat: 48.812392, lng: 19.9928184 },
    { lat: 48.8122104, lng: 19.9928894 },
    { lat: 48.8118769, lng: 19.9932119 },
    { lat: 48.8115038, lng: 19.9933677 },
    { lat: 48.8107223, lng: 19.994316 },
    { lat: 48.8096752, lng: 19.9944617 },
    { lat: 48.8094171, lng: 19.9941655 },
    { lat: 48.8090647, lng: 19.9939237 },
    { lat: 48.8088607, lng: 19.9940851 },
    { lat: 48.8083303, lng: 19.9943046 },
    { lat: 48.8077977, lng: 19.9943363 },
    { lat: 48.8074699, lng: 19.9946307 },
    { lat: 48.8071386, lng: 19.9947639 },
    { lat: 48.8068356, lng: 19.9946514 },
    { lat: 48.8065859, lng: 19.9950423 },
    { lat: 48.8061141, lng: 19.9952082 },
    { lat: 48.8058672, lng: 19.9964661 },
    { lat: 48.8058185, lng: 19.9971101 },
    { lat: 48.8053963, lng: 19.9988403 },
    { lat: 48.8082222, lng: 20.0077742 },
    { lat: 48.8085772, lng: 20.010864 },
    { lat: 48.8085314, lng: 20.0109273 },
    { lat: 48.8084969, lng: 20.010858 },
    { lat: 48.8082717, lng: 20.0108291 },
    { lat: 48.8081956, lng: 20.0109218 },
    { lat: 48.8083086, lng: 20.011421 },
    { lat: 48.8084104, lng: 20.0115356 },
    { lat: 48.80848, lng: 20.0118006 },
    { lat: 48.8085212, lng: 20.011792 },
    { lat: 48.8087031, lng: 20.0120233 },
    { lat: 48.8087616, lng: 20.0122086 },
    { lat: 48.808861, lng: 20.0122431 },
    { lat: 48.8088891, lng: 20.0123458 },
    { lat: 48.809066, lng: 20.0124576 },
    { lat: 48.8091055, lng: 20.0123798 },
    { lat: 48.8092379, lng: 20.0123393 },
    { lat: 48.8093825, lng: 20.0126218 },
    { lat: 48.8096373, lng: 20.0126797 },
    { lat: 48.8096589, lng: 20.0125765 },
    { lat: 48.809778, lng: 20.0125161 },
    { lat: 48.8098332, lng: 20.0125952 },
    { lat: 48.8101027, lng: 20.0124405 },
    { lat: 48.8101283, lng: 20.0123537 },
    { lat: 48.8102283, lng: 20.0123231 },
    { lat: 48.810264, lng: 20.0124709 },
    { lat: 48.8103636, lng: 20.0123738 },
    { lat: 48.8104538, lng: 20.012461 },
    { lat: 48.8105007, lng: 20.012697 },
    { lat: 48.8105856, lng: 20.0127064 },
    { lat: 48.8105617, lng: 20.0128881 },
    { lat: 48.8108134, lng: 20.0131732 },
    { lat: 48.8110814, lng: 20.0131694 },
    { lat: 48.8111179, lng: 20.0133157 },
    { lat: 48.8113261, lng: 20.0133673 },
    { lat: 48.8113904, lng: 20.01326 },
    { lat: 48.811414, lng: 20.0134193 },
    { lat: 48.8115513, lng: 20.0133898 },
    { lat: 48.811612, lng: 20.0134412 },
    { lat: 48.8118339, lng: 20.0139303 },
    { lat: 48.8121158, lng: 20.0140968 },
    { lat: 48.812186, lng: 20.0139754 },
    { lat: 48.8122757, lng: 20.0139911 },
    { lat: 48.8123361, lng: 20.0141673 },
    { lat: 48.812436, lng: 20.0141853 },
    { lat: 48.8124734, lng: 20.0143807 },
    { lat: 48.8125565, lng: 20.0145125 },
    { lat: 48.8127065, lng: 20.0144646 },
    { lat: 48.8128076, lng: 20.0145401 },
    { lat: 48.8128039, lng: 20.0146659 },
    { lat: 48.8129232, lng: 20.014692 },
    { lat: 48.8129774, lng: 20.0144792 },
    { lat: 48.8130296, lng: 20.0144864 },
    { lat: 48.813041, lng: 20.0143784 },
    { lat: 48.8129725, lng: 20.0142763 },
    { lat: 48.8130776, lng: 20.0140679 },
    { lat: 48.8131952, lng: 20.0140246 },
    { lat: 48.8133074, lng: 20.014259 },
    { lat: 48.813429, lng: 20.0141509 },
    { lat: 48.8135704, lng: 20.0142432 },
    { lat: 48.813605, lng: 20.0142114 },
    { lat: 48.8135711, lng: 20.0141002 },
    { lat: 48.8136932, lng: 20.0142437 },
    { lat: 48.8137505, lng: 20.0141356 },
    { lat: 48.8137801, lng: 20.0142229 },
    { lat: 48.8138418, lng: 20.014162 },
    { lat: 48.8138782, lng: 20.0142553 },
    { lat: 48.8139761, lng: 20.0142771 },
    { lat: 48.8139901, lng: 20.0143687 },
    { lat: 48.8140769, lng: 20.0143164 },
    { lat: 48.8142078, lng: 20.0145917 },
    { lat: 48.814359, lng: 20.0145719 },
    { lat: 48.8145635, lng: 20.0143715 },
    { lat: 48.8146422, lng: 20.0145031 },
    { lat: 48.8148446, lng: 20.0144437 },
    { lat: 48.8149728, lng: 20.0145477 },
    { lat: 48.8150388, lng: 20.0145335 },
    { lat: 48.8151041, lng: 20.0146952 },
    { lat: 48.8152797, lng: 20.0146667 },
    { lat: 48.8153648, lng: 20.0147905 },
    { lat: 48.8154368, lng: 20.0147604 },
    { lat: 48.8156829, lng: 20.0149628 },
    { lat: 48.8157201, lng: 20.014911 },
    { lat: 48.8157691, lng: 20.0150325 },
    { lat: 48.8159219, lng: 20.0150454 },
    { lat: 48.8159581, lng: 20.0151906 },
    { lat: 48.8160743, lng: 20.0152489 },
    { lat: 48.8161321, lng: 20.0152027 },
    { lat: 48.8162042, lng: 20.0153066 },
    { lat: 48.8162573, lng: 20.0152844 },
    { lat: 48.8163572, lng: 20.0154106 },
    { lat: 48.816563, lng: 20.0162177 },
    { lat: 48.8165774, lng: 20.0167553 },
    { lat: 48.8164482, lng: 20.0176007 },
    { lat: 48.8164541, lng: 20.0184451 },
    { lat: 48.8165426, lng: 20.0188155 },
    { lat: 48.816563, lng: 20.0200659 },
    { lat: 48.8164581, lng: 20.0213096 },
    { lat: 48.8165932, lng: 20.0214694 },
    { lat: 48.8166724, lng: 20.0214265 },
    { lat: 48.816711, lng: 20.0215961 },
    { lat: 48.8168343, lng: 20.0217953 },
    { lat: 48.8169427, lng: 20.0216956 },
    { lat: 48.8170655, lng: 20.0218851 },
    { lat: 48.8169574, lng: 20.0221285 },
    { lat: 48.8170635, lng: 20.0222981 },
    { lat: 48.8181224, lng: 20.021486 },
    { lat: 48.8190789, lng: 20.0203809 },
    { lat: 48.8192226, lng: 20.0204766 },
    { lat: 48.8201654, lng: 20.0215359 },
    { lat: 48.8221077, lng: 20.0210578 },
    { lat: 48.825328, lng: 20.0221198 },
    { lat: 48.8263035, lng: 20.0227944 },
    { lat: 48.8261741, lng: 20.0231799 },
    { lat: 48.8272034, lng: 20.0230948 },
    { lat: 48.8274418, lng: 20.0233559 },
    { lat: 48.8276505, lng: 20.0231187 },
    { lat: 48.8280921, lng: 20.023096 },
    { lat: 48.8280945, lng: 20.0224709 },
    { lat: 48.83076, lng: 20.0217268 },
    { lat: 48.8315128, lng: 20.0222021 },
    { lat: 48.8328116, lng: 20.0231546 },
    { lat: 48.8330134, lng: 20.0233756 },
    { lat: 48.8336313, lng: 20.0246954 },
    { lat: 48.8340048, lng: 20.0265808 },
    { lat: 48.835628, lng: 20.0286542 },
    { lat: 48.8371559, lng: 20.0331512 },
    { lat: 48.8375998, lng: 20.0357642 },
    { lat: 48.8377803, lng: 20.0365612 },
    { lat: 48.8377849, lng: 20.0397984 },
    { lat: 48.8385684, lng: 20.0424615 },
    { lat: 48.8388301, lng: 20.0446478 },
    { lat: 48.838997, lng: 20.0447117 },
    { lat: 48.8392433, lng: 20.0446455 },
    { lat: 48.8394502, lng: 20.0442279 },
    { lat: 48.839775, lng: 20.0433132 },
    { lat: 48.8399358, lng: 20.0432239 },
    { lat: 48.8401911, lng: 20.0428343 },
    { lat: 48.8405504, lng: 20.0425907 },
    { lat: 48.840731, lng: 20.0423808 },
    { lat: 48.8413405, lng: 20.0422168 },
    { lat: 48.8417689, lng: 20.0419305 },
    { lat: 48.842135, lng: 20.041413 },
    { lat: 48.8423193, lng: 20.0406636 },
    { lat: 48.8424081, lng: 20.0407421 },
    { lat: 48.8426383, lng: 20.0409455 },
    { lat: 48.8429548, lng: 20.041872 },
    { lat: 48.8431281, lng: 20.0420032 },
    { lat: 48.8434021, lng: 20.0423638 },
    { lat: 48.8434893, lng: 20.042594 },
    { lat: 48.8437256, lng: 20.0428852 },
    { lat: 48.8440272, lng: 20.0434273 },
    { lat: 48.8444317, lng: 20.0435699 },
    { lat: 48.8444412, lng: 20.0437128 },
    { lat: 48.8445048, lng: 20.0437847 },
    { lat: 48.8446106, lng: 20.0437239 },
    { lat: 48.8447883, lng: 20.0438544 },
    { lat: 48.8452059, lng: 20.0444212 },
    { lat: 48.8454662, lng: 20.0446143 },
    { lat: 48.8459859, lng: 20.0454205 },
    { lat: 48.8462609, lng: 20.0456165 },
    { lat: 48.8466173, lng: 20.0460325 },
    { lat: 48.8466201, lng: 20.0461807 },
    { lat: 48.846708, lng: 20.0462689 },
    { lat: 48.8469175, lng: 20.0463456 },
    { lat: 48.8471264, lng: 20.0460891 },
  ],
  Nemecká: [
    { lat: 48.8262012, lng: 19.4552907 },
    { lat: 48.8266352, lng: 19.4551014 },
    { lat: 48.8268759, lng: 19.4547972 },
    { lat: 48.8269681, lng: 19.4549883 },
    { lat: 48.8277477, lng: 19.452387 },
    { lat: 48.8280861, lng: 19.4516441 },
    { lat: 48.8290335, lng: 19.4492118 },
    { lat: 48.8297864, lng: 19.4479279 },
    { lat: 48.831828, lng: 19.4435844 },
    { lat: 48.832038, lng: 19.4428662 },
    { lat: 48.8326676, lng: 19.4418414 },
    { lat: 48.8346881, lng: 19.4390829 },
    { lat: 48.8362243, lng: 19.436352 },
    { lat: 48.8363953, lng: 19.4358361 },
    { lat: 48.8364401, lng: 19.4340662 },
    { lat: 48.8368928, lng: 19.4334318 },
    { lat: 48.8370056, lng: 19.4329486 },
    { lat: 48.837154, lng: 19.4325557 },
    { lat: 48.8377357, lng: 19.4333084 },
    { lat: 48.8383299, lng: 19.4325901 },
    { lat: 48.8392362, lng: 19.4293571 },
    { lat: 48.8393728, lng: 19.4277461 },
    { lat: 48.839548, lng: 19.4269607 },
    { lat: 48.8398338, lng: 19.4263698 },
    { lat: 48.8410317, lng: 19.4258125 },
    { lat: 48.8440975, lng: 19.4217944 },
    { lat: 48.8470269, lng: 19.4201138 },
    { lat: 48.8475423, lng: 19.4198595 },
    { lat: 48.8482102, lng: 19.4196322 },
    { lat: 48.8480044, lng: 19.4172057 },
    { lat: 48.8480284, lng: 19.4152133 },
    { lat: 48.8480595, lng: 19.4148182 },
    { lat: 48.8461047, lng: 19.4143718 },
    { lat: 48.8453142, lng: 19.4141225 },
    { lat: 48.8449749, lng: 19.4139704 },
    { lat: 48.8442633, lng: 19.4134812 },
    { lat: 48.8437295, lng: 19.4129138 },
    { lat: 48.8434394, lng: 19.4135525 },
    { lat: 48.8431822, lng: 19.413788 },
    { lat: 48.8425177, lng: 19.4135073 },
    { lat: 48.8424455, lng: 19.4132671 },
    { lat: 48.8422058, lng: 19.4133222 },
    { lat: 48.8418761, lng: 19.4127769 },
    { lat: 48.8412288, lng: 19.4126507 },
    { lat: 48.8411427, lng: 19.4124703 },
    { lat: 48.8408471, lng: 19.4126087 },
    { lat: 48.8406279, lng: 19.4125961 },
    { lat: 48.8405445, lng: 19.4127382 },
    { lat: 48.8401335, lng: 19.4127948 },
    { lat: 48.8397031, lng: 19.4131817 },
    { lat: 48.8391871, lng: 19.4134235 },
    { lat: 48.8388092, lng: 19.4133728 },
    { lat: 48.8368777, lng: 19.4126505 },
    { lat: 48.836563, lng: 19.4125973 },
    { lat: 48.8360087, lng: 19.4125513 },
    { lat: 48.8342919, lng: 19.4126373 },
    { lat: 48.8336549, lng: 19.412753 },
    { lat: 48.8330386, lng: 19.4129497 },
    { lat: 48.8322912, lng: 19.4136932 },
    { lat: 48.8315185, lng: 19.4147838 },
    { lat: 48.8308337, lng: 19.4151528 },
    { lat: 48.8289246, lng: 19.4156116 },
    { lat: 48.8289068, lng: 19.4156661 },
    { lat: 48.8259576, lng: 19.4167491 },
    { lat: 48.8254129, lng: 19.4166709 },
    { lat: 48.8251621, lng: 19.4167304 },
    { lat: 48.824829, lng: 19.4162583 },
    { lat: 48.8248034, lng: 19.4159491 },
    { lat: 48.8243949, lng: 19.4151686 },
    { lat: 48.8243014, lng: 19.414881 },
    { lat: 48.8237391, lng: 19.4140383 },
    { lat: 48.8234007, lng: 19.4136938 },
    { lat: 48.8231745, lng: 19.4133672 },
    { lat: 48.8225071, lng: 19.4129528 },
    { lat: 48.8222145, lng: 19.4122879 },
    { lat: 48.8217258, lng: 19.4115482 },
    { lat: 48.8212949, lng: 19.4112032 },
    { lat: 48.8209475, lng: 19.4107381 },
    { lat: 48.8197546, lng: 19.4096681 },
    { lat: 48.818873, lng: 19.4089886 },
    { lat: 48.8178288, lng: 19.4079908 },
    { lat: 48.8173239, lng: 19.4079928 },
    { lat: 48.81698, lng: 19.4081073 },
    { lat: 48.8167289, lng: 19.40811 },
    { lat: 48.8166498, lng: 19.4085129 },
    { lat: 48.8165463, lng: 19.408351 },
    { lat: 48.8163546, lng: 19.4084224 },
    { lat: 48.816158, lng: 19.408628 },
    { lat: 48.8158472, lng: 19.4085616 },
    { lat: 48.8156196, lng: 19.4084166 },
    { lat: 48.8153391, lng: 19.4080798 },
    { lat: 48.8154285, lng: 19.4081138 },
    { lat: 48.8151468, lng: 19.4077762 },
    { lat: 48.8150293, lng: 19.4075066 },
    { lat: 48.8148489, lng: 19.4074813 },
    { lat: 48.8147149, lng: 19.4073645 },
    { lat: 48.8146707, lng: 19.4071415 },
    { lat: 48.8144995, lng: 19.4070451 },
    { lat: 48.8143009, lng: 19.4066573 },
    { lat: 48.8139242, lng: 19.4061943 },
    { lat: 48.8136623, lng: 19.4057297 },
    { lat: 48.8135024, lng: 19.4056096 },
    { lat: 48.8130256, lng: 19.4055379 },
    { lat: 48.812616, lng: 19.4049719 },
    { lat: 48.8124697, lng: 19.404669 },
    { lat: 48.8121545, lng: 19.4035476 },
    { lat: 48.8114844, lng: 19.4027833 },
    { lat: 48.8107389, lng: 19.4023679 },
    { lat: 48.8098552, lng: 19.4029617 },
    { lat: 48.8096936, lng: 19.4033892 },
    { lat: 48.809461, lng: 19.4037103 },
    { lat: 48.8093339, lng: 19.4037904 },
    { lat: 48.8092445, lng: 19.4037575 },
    { lat: 48.8087077, lng: 19.4043 },
    { lat: 48.8085573, lng: 19.4046291 },
    { lat: 48.8083031, lng: 19.4042298 },
    { lat: 48.8081442, lng: 19.4035604 },
    { lat: 48.8080513, lng: 19.4021796 },
    { lat: 48.8079254, lng: 19.4014895 },
    { lat: 48.8068877, lng: 19.3998642 },
    { lat: 48.8056746, lng: 19.3987476 },
    { lat: 48.8052422, lng: 19.3980591 },
    { lat: 48.8047801, lng: 19.3969304 },
    { lat: 48.8046617, lng: 19.3964995 },
    { lat: 48.8043842, lng: 19.3958472 },
    { lat: 48.80375, lng: 19.39394 },
    { lat: 48.803642, lng: 19.393457 },
    { lat: 48.803519, lng: 19.393222 },
    { lat: 48.802632, lng: 19.392614 },
    { lat: 48.802186, lng: 19.392305 },
    { lat: 48.801957, lng: 19.392133 },
    { lat: 48.801903, lng: 19.39206 },
    { lat: 48.801725, lng: 19.391844 },
    { lat: 48.800756, lng: 19.390526 },
    { lat: 48.800599, lng: 19.390286 },
    { lat: 48.800414, lng: 19.389941 },
    { lat: 48.80024, lng: 19.38955 },
    { lat: 48.80009, lng: 19.389163 },
    { lat: 48.80007, lng: 19.389118 },
    { lat: 48.799461, lng: 19.387973 },
    { lat: 48.799367, lng: 19.387868 },
    { lat: 48.79904, lng: 19.387577 },
    { lat: 48.798695, lng: 19.387128 },
    { lat: 48.798539, lng: 19.386926 },
    { lat: 48.798437, lng: 19.386717 },
    { lat: 48.798434, lng: 19.386707 },
    { lat: 48.798317, lng: 19.386828 },
    { lat: 48.798303, lng: 19.386842 },
    { lat: 48.798288, lng: 19.386864 },
    { lat: 48.798192, lng: 19.386994 },
    { lat: 48.796474, lng: 19.390166 },
    { lat: 48.796691, lng: 19.390865 },
    { lat: 48.796721, lng: 19.391072 },
    { lat: 48.796744, lng: 19.391153 },
    { lat: 48.797015, lng: 19.392106 },
    { lat: 48.797107, lng: 19.392835 },
    { lat: 48.797242, lng: 19.393422 },
    { lat: 48.796792, lng: 19.393712 },
    { lat: 48.796864, lng: 19.39445 },
    { lat: 48.797054, lng: 19.396156 },
    { lat: 48.797188, lng: 19.397063 },
    { lat: 48.797247, lng: 19.398177 },
    { lat: 48.797113, lng: 19.398578 },
    { lat: 48.797321, lng: 19.39881 },
    { lat: 48.797866, lng: 19.399559 },
    { lat: 48.798231, lng: 19.400548 },
    { lat: 48.797727, lng: 19.401189 },
    { lat: 48.797581, lng: 19.401573 },
    { lat: 48.797242, lng: 19.401791 },
    { lat: 48.797042, lng: 19.401924 },
    { lat: 48.797121, lng: 19.403258 },
    { lat: 48.797245, lng: 19.40404 },
    { lat: 48.797296, lng: 19.404342 },
    { lat: 48.797317, lng: 19.404916 },
    { lat: 48.797339, lng: 19.405307 },
    { lat: 48.797252, lng: 19.405392 },
    { lat: 48.797079, lng: 19.405767 },
    { lat: 48.796104, lng: 19.40723 },
    { lat: 48.795681, lng: 19.407805 },
    { lat: 48.794483, lng: 19.408415 },
    { lat: 48.794291, lng: 19.408014 },
    { lat: 48.794241, lng: 19.407903 },
    { lat: 48.793517, lng: 19.407033 },
    { lat: 48.79331, lng: 19.406918 },
    { lat: 48.793027, lng: 19.406799 },
    { lat: 48.793007, lng: 19.40681 },
    { lat: 48.792974, lng: 19.406827 },
    { lat: 48.792843, lng: 19.406895 },
    { lat: 48.792654, lng: 19.40715 },
    { lat: 48.792602, lng: 19.407263 },
    { lat: 48.792164, lng: 19.407596 },
    { lat: 48.791713, lng: 19.407937 },
    { lat: 48.791545, lng: 19.408085 },
    { lat: 48.791001, lng: 19.40838 },
    { lat: 48.789061, lng: 19.409103 },
    { lat: 48.789141, lng: 19.409387 },
    { lat: 48.788977, lng: 19.410503 },
    { lat: 48.788524, lng: 19.411594 },
    { lat: 48.788318, lng: 19.412588 },
    { lat: 48.788145, lng: 19.412916 },
    { lat: 48.787824, lng: 19.413308 },
    { lat: 48.78767, lng: 19.413649 },
    { lat: 48.787854, lng: 19.415711 },
    { lat: 48.787768, lng: 19.416609 },
    { lat: 48.787664, lng: 19.4169 },
    { lat: 48.787726, lng: 19.41772 },
    { lat: 48.787744, lng: 19.418072 },
    { lat: 48.787276, lng: 19.419505 },
    { lat: 48.78708, lng: 19.420138 },
    { lat: 48.786946, lng: 19.420849 },
    { lat: 48.786735, lng: 19.421529 },
    { lat: 48.786312, lng: 19.422297 },
    { lat: 48.785962, lng: 19.422228 },
    { lat: 48.785606, lng: 19.422238 },
    { lat: 48.785349, lng: 19.42239 },
    { lat: 48.784872, lng: 19.422451 },
    { lat: 48.784543, lng: 19.422248 },
    { lat: 48.784009, lng: 19.422134 },
    { lat: 48.783335, lng: 19.422142 },
    { lat: 48.782914, lng: 19.422457 },
    { lat: 48.782572, lng: 19.424258 },
    { lat: 48.782376, lng: 19.424295 },
    { lat: 48.782156, lng: 19.424725 },
    { lat: 48.780272, lng: 19.428283 },
    { lat: 48.779182, lng: 19.42858 },
    { lat: 48.778638, lng: 19.428626 },
    { lat: 48.778058, lng: 19.428606 },
    { lat: 48.777638, lng: 19.428545 },
    { lat: 48.777303, lng: 19.429203 },
    { lat: 48.776748, lng: 19.429366 },
    { lat: 48.776024, lng: 19.430412 },
    { lat: 48.775726, lng: 19.430589 },
    { lat: 48.774885, lng: 19.431722 },
    { lat: 48.774753, lng: 19.432033 },
    { lat: 48.774674, lng: 19.432256 },
    { lat: 48.774019, lng: 19.432441 },
    { lat: 48.773792, lng: 19.4326 },
    { lat: 48.773323, lng: 19.432704 },
    { lat: 48.773257, lng: 19.432717 },
    { lat: 48.77262, lng: 19.432963 },
    { lat: 48.772022, lng: 19.433431 },
    { lat: 48.771802, lng: 19.43355 },
    { lat: 48.771734, lng: 19.434117 },
    { lat: 48.771321, lng: 19.434734 },
    { lat: 48.771178, lng: 19.435253 },
    { lat: 48.770972, lng: 19.436067 },
    { lat: 48.770851, lng: 19.436817 },
    { lat: 48.770682, lng: 19.437564 },
    { lat: 48.770659, lng: 19.437632 },
    { lat: 48.770621, lng: 19.437761 },
    { lat: 48.770132, lng: 19.439293 },
    { lat: 48.769268, lng: 19.442644 },
    { lat: 48.76914, lng: 19.443125 },
    { lat: 48.769109, lng: 19.443633 },
    { lat: 48.769554, lng: 19.445727 },
    { lat: 48.769573, lng: 19.446258 },
    { lat: 48.769054, lng: 19.447898 },
    { lat: 48.769125, lng: 19.448253 },
    { lat: 48.76913, lng: 19.449263 },
    { lat: 48.769531, lng: 19.449078 },
    { lat: 48.7698, lng: 19.449761 },
    { lat: 48.769849, lng: 19.450168 },
    { lat: 48.769989, lng: 19.450919 },
    { lat: 48.770084, lng: 19.451294 },
    { lat: 48.769774, lng: 19.451813 },
    { lat: 48.770044, lng: 19.452405 },
    { lat: 48.769976, lng: 19.45261 },
    { lat: 48.769824, lng: 19.453028 },
    { lat: 48.769984, lng: 19.453507 },
    { lat: 48.770122, lng: 19.453519 },
    { lat: 48.770119, lng: 19.453868 },
    { lat: 48.770163, lng: 19.454049 },
    { lat: 48.770366, lng: 19.454331 },
    { lat: 48.770758, lng: 19.455118 },
    { lat: 48.771187, lng: 19.455595 },
    { lat: 48.771328, lng: 19.456655 },
    { lat: 48.771276, lng: 19.456967 },
    { lat: 48.7717351, lng: 19.4569304 },
    { lat: 48.7719571, lng: 19.4579993 },
    { lat: 48.7719627, lng: 19.4585103 },
    { lat: 48.7725879, lng: 19.4584778 },
    { lat: 48.7727525, lng: 19.4591327 },
    { lat: 48.7731019, lng: 19.4589687 },
    { lat: 48.7735638, lng: 19.4608792 },
    { lat: 48.7736801, lng: 19.4608365 },
    { lat: 48.7736195, lng: 19.4602134 },
    { lat: 48.7737403, lng: 19.4601652 },
    { lat: 48.7738589, lng: 19.4614813 },
    { lat: 48.7737976, lng: 19.461983 },
    { lat: 48.7739957, lng: 19.462027 },
    { lat: 48.774042, lng: 19.4621757 },
    { lat: 48.7740639, lng: 19.4625707 },
    { lat: 48.7742773, lng: 19.4631397 },
    { lat: 48.7743564, lng: 19.4639434 },
    { lat: 48.7742528, lng: 19.463969 },
    { lat: 48.7741693, lng: 19.4641848 },
    { lat: 48.774746, lng: 19.4648627 },
    { lat: 48.7750334, lng: 19.4639372 },
    { lat: 48.7757862, lng: 19.4648862 },
    { lat: 48.7764559, lng: 19.4651095 },
    { lat: 48.7771242, lng: 19.4639709 },
    { lat: 48.777206, lng: 19.4634744 },
    { lat: 48.7778671, lng: 19.4634167 },
    { lat: 48.7778056, lng: 19.4636465 },
    { lat: 48.7775265, lng: 19.4637051 },
    { lat: 48.7776027, lng: 19.4645174 },
    { lat: 48.7776607, lng: 19.4646017 },
    { lat: 48.778145, lng: 19.464671 },
    { lat: 48.7792719, lng: 19.4645945 },
    { lat: 48.7798754, lng: 19.4648777 },
    { lat: 48.7803405, lng: 19.4649926 },
    { lat: 48.7806019, lng: 19.464987 },
    { lat: 48.7809624, lng: 19.4653982 },
    { lat: 48.7816779, lng: 19.4639872 },
    { lat: 48.781677, lng: 19.4635092 },
    { lat: 48.7818737, lng: 19.4631077 },
    { lat: 48.7817245, lng: 19.4629567 },
    { lat: 48.781578, lng: 19.4624463 },
    { lat: 48.7818765, lng: 19.4622212 },
    { lat: 48.7821905, lng: 19.4621396 },
    { lat: 48.7822398, lng: 19.4623763 },
    { lat: 48.7824944, lng: 19.4626937 },
    { lat: 48.7826515, lng: 19.4633549 },
    { lat: 48.7825533, lng: 19.4637891 },
    { lat: 48.7828349, lng: 19.4642491 },
    { lat: 48.7830819, lng: 19.4642036 },
    { lat: 48.7835237, lng: 19.4639883 },
    { lat: 48.7842342, lng: 19.463341 },
    { lat: 48.7843856, lng: 19.4633768 },
    { lat: 48.7843853, lng: 19.4632946 },
    { lat: 48.7844587, lng: 19.4632982 },
    { lat: 48.7842775, lng: 19.4626975 },
    { lat: 48.7838256, lng: 19.4626737 },
    { lat: 48.7838504, lng: 19.4628655 },
    { lat: 48.783664, lng: 19.4629049 },
    { lat: 48.783641, lng: 19.4626537 },
    { lat: 48.7834798, lng: 19.4625282 },
    { lat: 48.7835156, lng: 19.4623786 },
    { lat: 48.783816, lng: 19.4624657 },
    { lat: 48.7844746, lng: 19.462282 },
    { lat: 48.7844569, lng: 19.4619991 },
    { lat: 48.7845948, lng: 19.4619115 },
    { lat: 48.7848052, lng: 19.4621951 },
    { lat: 48.7850811, lng: 19.4620714 },
    { lat: 48.7850322, lng: 19.4623246 },
    { lat: 48.7851735, lng: 19.4626712 },
    { lat: 48.7852259, lng: 19.4611562 },
    { lat: 48.7851306, lng: 19.4607814 },
    { lat: 48.7848019, lng: 19.460741 },
    { lat: 48.7846755, lng: 19.4603499 },
    { lat: 48.7847945, lng: 19.4597333 },
    { lat: 48.7849865, lng: 19.4596668 },
    { lat: 48.7849717, lng: 19.4597866 },
    { lat: 48.7851456, lng: 19.4598385 },
    { lat: 48.7855878, lng: 19.4598272 },
    { lat: 48.7888877, lng: 19.4560496 },
    { lat: 48.791052, lng: 19.4567399 },
    { lat: 48.7918006, lng: 19.4570848 },
    { lat: 48.7919702, lng: 19.4573155 },
    { lat: 48.7934371, lng: 19.4575483 },
    { lat: 48.7947953, lng: 19.4580889 },
    { lat: 48.7951039, lng: 19.4580125 },
    { lat: 48.7953248, lng: 19.458059 },
    { lat: 48.7955892, lng: 19.4581481 },
    { lat: 48.7958976, lng: 19.4584342 },
    { lat: 48.7960235, lng: 19.4583786 },
    { lat: 48.7961375, lng: 19.4573342 },
    { lat: 48.7962593, lng: 19.4570953 },
    { lat: 48.7966116, lng: 19.4570121 },
    { lat: 48.7972273, lng: 19.4571663 },
    { lat: 48.7975957, lng: 19.4574106 },
    { lat: 48.7977932, lng: 19.4574636 },
    { lat: 48.7983902, lng: 19.4573375 },
    { lat: 48.7986664, lng: 19.4574114 },
    { lat: 48.7990362, lng: 19.4573656 },
    { lat: 48.79929, lng: 19.4563781 },
    { lat: 48.7997227, lng: 19.4554802 },
    { lat: 48.799783, lng: 19.455176 },
    { lat: 48.799758, lng: 19.4548834 },
    { lat: 48.7998757, lng: 19.454528 },
    { lat: 48.7998402, lng: 19.4543695 },
    { lat: 48.8002314, lng: 19.4537874 },
    { lat: 48.8004429, lng: 19.4533702 },
    { lat: 48.8004915, lng: 19.4531555 },
    { lat: 48.8008243, lng: 19.4528981 },
    { lat: 48.801397, lng: 19.4530004 },
    { lat: 48.8020232, lng: 19.4532569 },
    { lat: 48.8017808, lng: 19.454733 },
    { lat: 48.802433, lng: 19.4545549 },
    { lat: 48.803942, lng: 19.4545347 },
    { lat: 48.8042496, lng: 19.4543646 },
    { lat: 48.8044186, lng: 19.4545311 },
    { lat: 48.80493, lng: 19.4524156 },
    { lat: 48.8052899, lng: 19.4515768 },
    { lat: 48.8059682, lng: 19.4507269 },
    { lat: 48.80723, lng: 19.449655 },
    { lat: 48.8075595, lng: 19.4493021 },
    { lat: 48.8081018, lng: 19.4501824 },
    { lat: 48.8079174, lng: 19.4510169 },
    { lat: 48.8079319, lng: 19.4516625 },
    { lat: 48.8079614, lng: 19.4518148 },
    { lat: 48.8084847, lng: 19.452989 },
    { lat: 48.8080849, lng: 19.453939 },
    { lat: 48.8100706, lng: 19.4539884 },
    { lat: 48.8124144, lng: 19.4542987 },
    { lat: 48.8165004, lng: 19.4546319 },
    { lat: 48.8190746, lng: 19.4550743 },
    { lat: 48.8196966, lng: 19.4553875 },
    { lat: 48.8200549, lng: 19.4552431 },
    { lat: 48.8205615, lng: 19.4556207 },
    { lat: 48.8206383, lng: 19.4557424 },
    { lat: 48.8207186, lng: 19.4565513 },
    { lat: 48.8211334, lng: 19.4568945 },
    { lat: 48.8220723, lng: 19.4571893 },
    { lat: 48.8225915, lng: 19.4570353 },
    { lat: 48.8231749, lng: 19.4565197 },
    { lat: 48.8246274, lng: 19.4556186 },
    { lat: 48.825196, lng: 19.4554139 },
    { lat: 48.8261163, lng: 19.4553792 },
    { lat: 48.8262012, lng: 19.4552907 },
  ],
  DolnáLehota: [
    { lat: 48.8234754, lng: 19.529866 },
    { lat: 48.8237893, lng: 19.5303205 },
    { lat: 48.8240264, lng: 19.530405 },
    { lat: 48.8240933, lng: 19.5305857 },
    { lat: 48.8247734, lng: 19.530819 },
    { lat: 48.8260511, lng: 19.5302524 },
    { lat: 48.8260758, lng: 19.5299766 },
    { lat: 48.8263944, lng: 19.5295816 },
    { lat: 48.8266179, lng: 19.5295631 },
    { lat: 48.8268116, lng: 19.5293121 },
    { lat: 48.8271673, lng: 19.5292826 },
    { lat: 48.8272928, lng: 19.5290176 },
    { lat: 48.8280905, lng: 19.5284968 },
    { lat: 48.8282609, lng: 19.5281606 },
    { lat: 48.8287256, lng: 19.5277544 },
    { lat: 48.8289126, lng: 19.5277015 },
    { lat: 48.8306915, lng: 19.529168 },
    { lat: 48.8327077, lng: 19.5287316 },
    { lat: 48.8347088, lng: 19.5294416 },
    { lat: 48.8364349, lng: 19.5297821 },
    { lat: 48.8382105, lng: 19.5260821 },
    { lat: 48.8383469, lng: 19.5256442 },
    { lat: 48.8405379, lng: 19.5262387 },
    { lat: 48.8407406, lng: 19.5251331 },
    { lat: 48.8417033, lng: 19.5256071 },
    { lat: 48.8422137, lng: 19.5245743 },
    { lat: 48.842865, lng: 19.5247201 },
    { lat: 48.8431579, lng: 19.5241766 },
    { lat: 48.845867, lng: 19.5251653 },
    { lat: 48.846582, lng: 19.5236493 },
    { lat: 48.8482311, lng: 19.5244733 },
    { lat: 48.8484861, lng: 19.5248755 },
    { lat: 48.849561, lng: 19.526127 },
    { lat: 48.8504457, lng: 19.5284928 },
    { lat: 48.8512159, lng: 19.5295139 },
    { lat: 48.8524307, lng: 19.5284323 },
    { lat: 48.8528878, lng: 19.5285964 },
    { lat: 48.8529158, lng: 19.5309776 },
    { lat: 48.8536371, lng: 19.5314003 },
    { lat: 48.8558554, lng: 19.530113 },
    { lat: 48.8574076, lng: 19.5294596 },
    { lat: 48.8573518, lng: 19.5310468 },
    { lat: 48.858109, lng: 19.5309941 },
    { lat: 48.8589412, lng: 19.5305681 },
    { lat: 48.8593821, lng: 19.5304505 },
    { lat: 48.85955, lng: 19.5310387 },
    { lat: 48.8598989, lng: 19.531672 },
    { lat: 48.8607448, lng: 19.5326739 },
    { lat: 48.8613573, lng: 19.5327108 },
    { lat: 48.8627517, lng: 19.5329695 },
    { lat: 48.8630907, lng: 19.5330863 },
    { lat: 48.8633961, lng: 19.5332854 },
    { lat: 48.8642584, lng: 19.5342292 },
    { lat: 48.8645344, lng: 19.5343014 },
    { lat: 48.8651104, lng: 19.534994 },
    { lat: 48.8653887, lng: 19.5354121 },
    { lat: 48.8655959, lng: 19.5360028 },
    { lat: 48.8657716, lng: 19.5367875 },
    { lat: 48.8663286, lng: 19.5384001 },
    { lat: 48.8665423, lng: 19.5383909 },
    { lat: 48.8668727, lng: 19.5391619 },
    { lat: 48.8669375, lng: 19.5394435 },
    { lat: 48.8667548, lng: 19.5397133 },
    { lat: 48.8670916, lng: 19.5419328 },
    { lat: 48.8663608, lng: 19.5442876 },
    { lat: 48.8661518, lng: 19.5448449 },
    { lat: 48.8659131, lng: 19.5452908 },
    { lat: 48.8664986, lng: 19.5459263 },
    { lat: 48.8668748, lng: 19.5462176 },
    { lat: 48.8671908, lng: 19.54628 },
    { lat: 48.8670943, lng: 19.5467322 },
    { lat: 48.8678842, lng: 19.5471276 },
    { lat: 48.8689379, lng: 19.5474503 },
    { lat: 48.8690956, lng: 19.5478171 },
    { lat: 48.8692017, lng: 19.547923 },
    { lat: 48.8691357, lng: 19.5483555 },
    { lat: 48.8691639, lng: 19.5485548 },
    { lat: 48.8693568, lng: 19.5488542 },
    { lat: 48.8701033, lng: 19.5496071 },
    { lat: 48.870251, lng: 19.5498798 },
    { lat: 48.8702032, lng: 19.5503397 },
    { lat: 48.8703901, lng: 19.5506838 },
    { lat: 48.8705201, lng: 19.5504952 },
    { lat: 48.8705859, lng: 19.5496385 },
    { lat: 48.8710478, lng: 19.550131 },
    { lat: 48.8715786, lng: 19.5504527 },
    { lat: 48.8720808, lng: 19.5503944 },
    { lat: 48.8725643, lng: 19.5504507 },
    { lat: 48.8730438, lng: 19.550563 },
    { lat: 48.8731475, lng: 19.5506628 },
    { lat: 48.8732557, lng: 19.5504603 },
    { lat: 48.8738685, lng: 19.5502347 },
    { lat: 48.8741836, lng: 19.5503245 },
    { lat: 48.8753058, lng: 19.5501779 },
    { lat: 48.8760499, lng: 19.5499106 },
    { lat: 48.8761108, lng: 19.5502608 },
    { lat: 48.8764658, lng: 19.5499895 },
    { lat: 48.8777103, lng: 19.5500965 },
    { lat: 48.8783637, lng: 19.5504895 },
    { lat: 48.8793322, lng: 19.5507278 },
    { lat: 48.8803888, lng: 19.5515354 },
    { lat: 48.8812413, lng: 19.552315 },
    { lat: 48.8829803, lng: 19.5537075 },
    { lat: 48.8845012, lng: 19.5554351 },
    { lat: 48.8848898, lng: 19.555803 },
    { lat: 48.8858676, lng: 19.5565395 },
    { lat: 48.8868612, lng: 19.5568763 },
    { lat: 48.8884056, lng: 19.5576851 },
    { lat: 48.8896108, lng: 19.5579715 },
    { lat: 48.8908354, lng: 19.5579411 },
    { lat: 48.8913954, lng: 19.5588587 },
    { lat: 48.892835, lng: 19.5599908 },
    { lat: 48.8930818, lng: 19.5605834 },
    { lat: 48.8936239, lng: 19.5613306 },
    { lat: 48.8942604, lng: 19.5630625 },
    { lat: 48.8944522, lng: 19.5637361 },
    { lat: 48.895338, lng: 19.5656983 },
    { lat: 48.8962154, lng: 19.5674015 },
    { lat: 48.8963851, lng: 19.5684102 },
    { lat: 48.8973151, lng: 19.5692868 },
    { lat: 48.8982809, lng: 19.5718028 },
    { lat: 48.898703, lng: 19.5725875 },
    { lat: 48.8994915, lng: 19.5728025 },
    { lat: 48.8997909, lng: 19.5740358 },
    { lat: 48.9005017, lng: 19.5754243 },
    { lat: 48.9012235, lng: 19.5759396 },
    { lat: 48.9012415, lng: 19.5760853 },
    { lat: 48.9043752, lng: 19.5756643 },
    { lat: 48.9052841, lng: 19.5766039 },
    { lat: 48.9067681, lng: 19.5767744 },
    { lat: 48.9082202, lng: 19.5773906 },
    { lat: 48.9101236, lng: 19.5779447 },
    { lat: 48.910951, lng: 19.5781002 },
    { lat: 48.9128613, lng: 19.5796514 },
    { lat: 48.9136542, lng: 19.5804134 },
    { lat: 48.9140468, lng: 19.580653 },
    { lat: 48.9159885, lng: 19.580259 },
    { lat: 48.9166119, lng: 19.5800149 },
    { lat: 48.9170554, lng: 19.5796312 },
    { lat: 48.9185294, lng: 19.5772989 },
    { lat: 48.922825, lng: 19.5763599 },
    { lat: 48.9276604, lng: 19.5754473 },
    { lat: 48.940538, lng: 19.572775 },
    { lat: 48.94067, lng: 19.572086 },
    { lat: 48.940934, lng: 19.571301 },
    { lat: 48.940936, lng: 19.571193 },
    { lat: 48.940779, lng: 19.568895 },
    { lat: 48.940911, lng: 19.568362 },
    { lat: 48.940832, lng: 19.566709 },
    { lat: 48.941264, lng: 19.564212 },
    { lat: 48.941544, lng: 19.563685 },
    { lat: 48.942001, lng: 19.561288 },
    { lat: 48.941825, lng: 19.558993 },
    { lat: 48.941815, lng: 19.558868 },
    { lat: 48.94188, lng: 19.557338 },
    { lat: 48.941773, lng: 19.556098 },
    { lat: 48.9418, lng: 19.555519 },
    { lat: 48.941851, lng: 19.555331 },
    { lat: 48.941921, lng: 19.55507 },
    { lat: 48.941913, lng: 19.554279 },
    { lat: 48.941727, lng: 19.552428 },
    { lat: 48.94177, lng: 19.551158 },
    { lat: 48.941647, lng: 19.549975 },
    { lat: 48.941667, lng: 19.548402 },
    { lat: 48.941961, lng: 19.546431 },
    { lat: 48.942236, lng: 19.545583 },
    { lat: 48.942566, lng: 19.544571 },
    { lat: 48.943026, lng: 19.543902 },
    { lat: 48.943356, lng: 19.543425 },
    { lat: 48.944098, lng: 19.542964 },
    { lat: 48.944777, lng: 19.542549 },
    { lat: 48.945532, lng: 19.541861 },
    { lat: 48.945921, lng: 19.54151 },
    { lat: 48.946563, lng: 19.540925 },
    { lat: 48.947248, lng: 19.539928 },
    { lat: 48.946978, lng: 19.538343 },
    { lat: 48.947146, lng: 19.536653 },
    { lat: 48.9465206, lng: 19.5356411 },
    { lat: 48.946431, lng: 19.535488 },
    { lat: 48.946367, lng: 19.534508 },
    { lat: 48.946493, lng: 19.533086 },
    { lat: 48.943362, lng: 19.526532 },
    { lat: 48.941813, lng: 19.523753 },
    { lat: 48.941296, lng: 19.522713 },
    { lat: 48.938896, lng: 19.51742 },
    { lat: 48.938864, lng: 19.517291 },
    { lat: 48.9381804, lng: 19.5176667 },
    { lat: 48.9374371, lng: 19.5182191 },
    { lat: 48.9372472, lng: 19.5183523 },
    { lat: 48.9366336, lng: 19.518803 },
    { lat: 48.936572, lng: 19.5188212 },
    { lat: 48.9359527, lng: 19.5196608 },
    { lat: 48.9348638, lng: 19.5206595 },
    { lat: 48.9345325, lng: 19.5201185 },
    { lat: 48.9331661, lng: 19.5193537 },
    { lat: 48.9331052, lng: 19.5193412 },
    { lat: 48.9330498, lng: 19.5193134 },
    { lat: 48.9303969, lng: 19.5183693 },
    { lat: 48.9303361, lng: 19.5183063 },
    { lat: 48.9295075, lng: 19.518685 },
    { lat: 48.9290578, lng: 19.5179924 },
    { lat: 48.928957, lng: 19.5171207 },
    { lat: 48.9278515, lng: 19.5138702 },
    { lat: 48.9268686, lng: 19.5135228 },
    { lat: 48.9252595, lng: 19.5115585 },
    { lat: 48.9248074, lng: 19.5112413 },
    { lat: 48.9247569, lng: 19.5112453 },
    { lat: 48.9238764, lng: 19.510588 },
    { lat: 48.923136, lng: 19.5103899 },
    { lat: 48.922794, lng: 19.5099104 },
    { lat: 48.9214374, lng: 19.5075065 },
    { lat: 48.9195898, lng: 19.5054301 },
    { lat: 48.9181973, lng: 19.5046537 },
    { lat: 48.9180322, lng: 19.5045103 },
    { lat: 48.9172248, lng: 19.5034543 },
    { lat: 48.917188, lng: 19.503409 },
    { lat: 48.9167393, lng: 19.5021283 },
    { lat: 48.9162436, lng: 19.502281 },
    { lat: 48.9149856, lng: 19.5010186 },
    { lat: 48.9144619, lng: 19.499823 },
    { lat: 48.9136621, lng: 19.4990478 },
    { lat: 48.9135824, lng: 19.4979255 },
    { lat: 48.9132139, lng: 19.4973107 },
    { lat: 48.9118825, lng: 19.4946054 },
    { lat: 48.913986, lng: 19.4891637 },
    { lat: 48.9095592, lng: 19.4892251 },
    { lat: 48.907695, lng: 19.4912525 },
    { lat: 48.9059197, lng: 19.4916051 },
    { lat: 48.9047611, lng: 19.4926083 },
    { lat: 48.9041994, lng: 19.4919572 },
    { lat: 48.9037013, lng: 19.493038 },
    { lat: 48.9034812, lng: 19.4937859 },
    { lat: 48.9039684, lng: 19.4950439 },
    { lat: 48.9033254, lng: 19.4960802 },
    { lat: 48.9026616, lng: 19.4961784 },
    { lat: 48.9023263, lng: 19.497239 },
    { lat: 48.90241, lng: 19.4974676 },
    { lat: 48.9023238, lng: 19.498413 },
    { lat: 48.9016256, lng: 19.4986649 },
    { lat: 48.9010601, lng: 19.4990614 },
    { lat: 48.9008993, lng: 19.4990205 },
    { lat: 48.8996899, lng: 19.4993082 },
    { lat: 48.8992029, lng: 19.4995023 },
    { lat: 48.8987882, lng: 19.4995472 },
    { lat: 48.8971566, lng: 19.5003341 },
    { lat: 48.8966509, lng: 19.5004663 },
    { lat: 48.8954334, lng: 19.5005776 },
    { lat: 48.8902791, lng: 19.4926935 },
    { lat: 48.8869889, lng: 19.4924293 },
    { lat: 48.8869357, lng: 19.4921753 },
    { lat: 48.8857308, lng: 19.4911439 },
    { lat: 48.8854571, lng: 19.4903905 },
    { lat: 48.8852556, lng: 19.4894401 },
    { lat: 48.8851102, lng: 19.4892679 },
    { lat: 48.8841729, lng: 19.4874169 },
    { lat: 48.8837826, lng: 19.4863318 },
    { lat: 48.8832476, lng: 19.4858515 },
    { lat: 48.882959, lng: 19.4853858 },
    { lat: 48.8792481, lng: 19.4856777 },
    { lat: 48.8784802, lng: 19.4858615 },
    { lat: 48.8778862, lng: 19.4858332 },
    { lat: 48.8773285, lng: 19.4860033 },
    { lat: 48.8766888, lng: 19.4859175 },
    { lat: 48.8765422, lng: 19.4864515 },
    { lat: 48.8764153, lng: 19.4866579 },
    { lat: 48.8763333, lng: 19.4865914 },
    { lat: 48.8750807, lng: 19.4864285 },
    { lat: 48.8739845, lng: 19.4868476 },
    { lat: 48.8737762, lng: 19.4869778 },
    { lat: 48.8732897, lng: 19.4872819 },
    { lat: 48.873004, lng: 19.4874605 },
    { lat: 48.872233, lng: 19.4876303 },
    { lat: 48.8719198, lng: 19.4876993 },
    { lat: 48.871726, lng: 19.487656 },
    { lat: 48.871618, lng: 19.4876318 },
    { lat: 48.8716499, lng: 19.4874176 },
    { lat: 48.871272, lng: 19.4871408 },
    { lat: 48.8710658, lng: 19.4871183 },
    { lat: 48.8710523, lng: 19.4869697 },
    { lat: 48.870942, lng: 19.4870448 },
    { lat: 48.8706555, lng: 19.4870171 },
    { lat: 48.8705299, lng: 19.487106 },
    { lat: 48.8700922, lng: 19.4876814 },
    { lat: 48.8691509, lng: 19.4869883 },
    { lat: 48.8682366, lng: 19.4869077 },
    { lat: 48.8678756, lng: 19.4867033 },
    { lat: 48.8678107, lng: 19.4862237 },
    { lat: 48.867415, lng: 19.4862922 },
    { lat: 48.8673109, lng: 19.48599 },
    { lat: 48.8670782, lng: 19.485855 },
    { lat: 48.8668824, lng: 19.485593 },
    { lat: 48.8663317, lng: 19.4857459 },
    { lat: 48.8657993, lng: 19.4862494 },
    { lat: 48.8648286, lng: 19.4863376 },
    { lat: 48.8646066, lng: 19.4860197 },
    { lat: 48.8641979, lng: 19.4859044 },
    { lat: 48.8635583, lng: 19.4855979 },
    { lat: 48.8625388, lng: 19.4860477 },
    { lat: 48.8621793, lng: 19.4860186 },
    { lat: 48.8615648, lng: 19.486278 },
    { lat: 48.8610093, lng: 19.4861844 },
    { lat: 48.8599043, lng: 19.4861469 },
    { lat: 48.8595883, lng: 19.4860126 },
    { lat: 48.8590459, lng: 19.4854964 },
    { lat: 48.8582826, lng: 19.4850437 },
    { lat: 48.8580227, lng: 19.4849321 },
    { lat: 48.8575035, lng: 19.4853285 },
    { lat: 48.8573033, lng: 19.4852416 },
    { lat: 48.8572027, lng: 19.4850623 },
    { lat: 48.85684, lng: 19.4846992 },
    { lat: 48.8558747, lng: 19.4848334 },
    { lat: 48.8550539, lng: 19.4845278 },
    { lat: 48.8546263, lng: 19.4845431 },
    { lat: 48.853503, lng: 19.4853056 },
    { lat: 48.8529337, lng: 19.4852965 },
    { lat: 48.8525585, lng: 19.4850833 },
    { lat: 48.8523276, lng: 19.4845914 },
    { lat: 48.8519894, lng: 19.4842283 },
    { lat: 48.8507327, lng: 19.4839779 },
    { lat: 48.8502353, lng: 19.484089 },
    { lat: 48.849336, lng: 19.4846033 },
    { lat: 48.8489733, lng: 19.4845319 },
    { lat: 48.8486047, lng: 19.48465 },
    { lat: 48.8481737, lng: 19.4845415 },
    { lat: 48.8479093, lng: 19.4845745 },
    { lat: 48.8476168, lng: 19.4847547 },
    { lat: 48.847361, lng: 19.4851994 },
    { lat: 48.8468243, lng: 19.4853386 },
    { lat: 48.84634, lng: 19.4851498 },
    { lat: 48.8459061, lng: 19.4853654 },
    { lat: 48.8456953, lng: 19.4858994 },
    { lat: 48.8450789, lng: 19.4863182 },
    { lat: 48.8444196, lng: 19.4870485 },
    { lat: 48.843796, lng: 19.4875986 },
    { lat: 48.8434304, lng: 19.487638 },
    { lat: 48.8433265, lng: 19.4879242 },
    { lat: 48.8431485, lng: 19.4880725 },
    { lat: 48.8429806, lng: 19.4887801 },
    { lat: 48.8413601, lng: 19.4886079 },
    { lat: 48.8405694, lng: 19.4895477 },
    { lat: 48.8393444, lng: 19.4895186 },
    { lat: 48.83836, lng: 19.4900213 },
    { lat: 48.8362366, lng: 19.4909168 },
    { lat: 48.8343742, lng: 19.491829 },
    { lat: 48.8338151, lng: 19.4925835 },
    { lat: 48.8334713, lng: 19.4932909 },
    { lat: 48.8315849, lng: 19.493949 },
    { lat: 48.8304305, lng: 19.4953069 },
    { lat: 48.8296094, lng: 19.495291 },
    { lat: 48.829012, lng: 19.4951425 },
    { lat: 48.8286771, lng: 19.4955059 },
    { lat: 48.8287147, lng: 19.4966135 },
    { lat: 48.8286351, lng: 19.4969152 },
    { lat: 48.8281354, lng: 19.4971504 },
    { lat: 48.8278258, lng: 19.4974271 },
    { lat: 48.8276277, lng: 19.4980893 },
    { lat: 48.8275596, lng: 19.498619 },
    { lat: 48.8270322, lng: 19.4999862 },
    { lat: 48.8267193, lng: 19.5006308 },
    { lat: 48.825964, lng: 19.5015772 },
    { lat: 48.825518, lng: 19.5027387 },
    { lat: 48.8255288, lng: 19.5028967 },
    { lat: 48.8252306, lng: 19.503446 },
    { lat: 48.8248209, lng: 19.5038462 },
    { lat: 48.8247143, lng: 19.5048595 },
    { lat: 48.8252607, lng: 19.5057417 },
    { lat: 48.8262845, lng: 19.5068074 },
    { lat: 48.8261618, lng: 19.5072975 },
    { lat: 48.8263324, lng: 19.5074533 },
    { lat: 48.8268687, lng: 19.5072583 },
    { lat: 48.8278294, lng: 19.5078823 },
    { lat: 48.8277893, lng: 19.5080882 },
    { lat: 48.8284263, lng: 19.5084423 },
    { lat: 48.8280482, lng: 19.5090148 },
    { lat: 48.82723, lng: 19.5114415 },
    { lat: 48.826617, lng: 19.514654 },
    { lat: 48.8260605, lng: 19.5150975 },
    { lat: 48.8253722, lng: 19.5167475 },
    { lat: 48.8256665, lng: 19.5173152 },
    { lat: 48.8251123, lng: 19.520609 },
    { lat: 48.8250726, lng: 19.5220025 },
    { lat: 48.8264028, lng: 19.5248435 },
    { lat: 48.8250674, lng: 19.5272258 },
    { lat: 48.824412, lng: 19.5285886 },
    { lat: 48.8235136, lng: 19.5294418 },
    { lat: 48.8234754, lng: 19.529866 },
  ],
  Šumiac: [
    { lat: 48.796091, lng: 20.168255 },
    { lat: 48.7989695, lng: 20.16885 },
    { lat: 48.8013702, lng: 20.1699018 },
    { lat: 48.8023483, lng: 20.1700866 },
    { lat: 48.80364, lng: 20.170139 },
    { lat: 48.8059137, lng: 20.1705234 },
    { lat: 48.8070119, lng: 20.170783 },
    { lat: 48.8076032, lng: 20.1708081 },
    { lat: 48.8084861, lng: 20.1708313 },
    { lat: 48.8090641, lng: 20.169889 },
    { lat: 48.8102043, lng: 20.168813 },
    { lat: 48.8111682, lng: 20.1689424 },
    { lat: 48.8118565, lng: 20.1688399 },
    { lat: 48.8157028, lng: 20.1704625 },
    { lat: 48.8184517, lng: 20.1714422 },
    { lat: 48.8199871, lng: 20.168479 },
    { lat: 48.8230623, lng: 20.1659223 },
    { lat: 48.8262992, lng: 20.1652569 },
    { lat: 48.8266673, lng: 20.1650267 },
    { lat: 48.8272056, lng: 20.1648322 },
    { lat: 48.8277515, lng: 20.1647685 },
    { lat: 48.8285927, lng: 20.1648774 },
    { lat: 48.8298152, lng: 20.1648595 },
    { lat: 48.8306502, lng: 20.1649836 },
    { lat: 48.8313343, lng: 20.1649997 },
    { lat: 48.8324672, lng: 20.1649072 },
    { lat: 48.8339663, lng: 20.1645513 },
    { lat: 48.8350916, lng: 20.1639139 },
    { lat: 48.8357018, lng: 20.1634307 },
    { lat: 48.8363039, lng: 20.1630869 },
    { lat: 48.8383777, lng: 20.1622337 },
    { lat: 48.8401149, lng: 20.1613548 },
    { lat: 48.8409459, lng: 20.1610152 },
    { lat: 48.8421442, lng: 20.1603448 },
    { lat: 48.8427198, lng: 20.1601746 },
    { lat: 48.8430631, lng: 20.1599751 },
    { lat: 48.8433633, lng: 20.1599094 },
    { lat: 48.8442478, lng: 20.1594986 },
    { lat: 48.8458596, lng: 20.1586354 },
    { lat: 48.8463491, lng: 20.158461 },
    { lat: 48.8473277, lng: 20.1584584 },
    { lat: 48.849502, lng: 20.1579961 },
    { lat: 48.8509529, lng: 20.1571416 },
    { lat: 48.8525699, lng: 20.157215 },
    { lat: 48.8542417, lng: 20.1567819 },
    { lat: 48.8562124, lng: 20.1564682 },
    { lat: 48.8569484, lng: 20.1562602 },
    { lat: 48.8586587, lng: 20.1556053 },
    { lat: 48.8613099, lng: 20.1548557 },
    { lat: 48.8617228, lng: 20.1549823 },
    { lat: 48.865856, lng: 20.1571606 },
    { lat: 48.8713773, lng: 20.1580945 },
    { lat: 48.8755199, lng: 20.1535247 },
    { lat: 48.8818528, lng: 20.1478076 },
    { lat: 48.889202, lng: 20.158067 },
    { lat: 48.889334, lng: 20.156927 },
    { lat: 48.889428, lng: 20.1561 },
    { lat: 48.889521, lng: 20.155268 },
    { lat: 48.889636, lng: 20.154258 },
    { lat: 48.889738, lng: 20.153357 },
    { lat: 48.889911, lng: 20.151862 },
    { lat: 48.890051, lng: 20.150632 },
    { lat: 48.890149, lng: 20.149763 },
    { lat: 48.890246, lng: 20.148924 },
    { lat: 48.890393, lng: 20.147615 },
    { lat: 48.890541, lng: 20.146317 },
    { lat: 48.890623, lng: 20.145616 },
    { lat: 48.890721, lng: 20.144715 },
    { lat: 48.890847, lng: 20.143542 },
    { lat: 48.89093, lng: 20.142761 },
    { lat: 48.891063, lng: 20.141513 },
    { lat: 48.891205, lng: 20.140193 },
    { lat: 48.891345, lng: 20.138889 },
    { lat: 48.891493, lng: 20.137524 },
    { lat: 48.891591, lng: 20.136585 },
    { lat: 48.891674, lng: 20.135925 },
    { lat: 48.891795, lng: 20.134956 },
    { lat: 48.891894, lng: 20.134166 },
    { lat: 48.89198, lng: 20.133484 },
    { lat: 48.891998, lng: 20.133351 },
    { lat: 48.892065, lng: 20.132764 },
    { lat: 48.892169, lng: 20.131896 },
    { lat: 48.89229, lng: 20.130885 },
    { lat: 48.892402, lng: 20.129981 },
    { lat: 48.892427, lng: 20.12968 },
    { lat: 48.892519, lng: 20.128646 },
    { lat: 48.892592, lng: 20.127812 },
    { lat: 48.892663, lng: 20.126973 },
    { lat: 48.892736, lng: 20.126134 },
    { lat: 48.892824, lng: 20.125148 },
    { lat: 48.892889, lng: 20.124409 },
    { lat: 48.892956, lng: 20.123636 },
    { lat: 48.893061, lng: 20.122443 },
    { lat: 48.893164, lng: 20.121237 },
    { lat: 48.893263, lng: 20.120127 },
    { lat: 48.893307, lng: 20.119653 },
    { lat: 48.893355, lng: 20.119329 },
    { lat: 48.893513, lng: 20.118207 },
    { lat: 48.893647, lng: 20.117249 },
    { lat: 48.893792, lng: 20.116231 },
    { lat: 48.893947, lng: 20.115124 },
    { lat: 48.894109, lng: 20.11399 },
    { lat: 48.894229, lng: 20.113122 },
    { lat: 48.894291, lng: 20.112726 },
    { lat: 48.894399, lng: 20.112026 },
    { lat: 48.894498, lng: 20.111157 },
    { lat: 48.894638, lng: 20.109899 },
    { lat: 48.894728, lng: 20.109109 },
    { lat: 48.894781, lng: 20.108644 },
    { lat: 48.894839, lng: 20.108139 },
    { lat: 48.894897, lng: 20.107538 },
    { lat: 48.894927, lng: 20.107312 },
    { lat: 48.894957, lng: 20.107029 },
    { lat: 48.895023, lng: 20.106464 },
    { lat: 48.89507, lng: 20.106035 },
    { lat: 48.895185, lng: 20.105044 },
    { lat: 48.895272, lng: 20.104284 },
    { lat: 48.895274, lng: 20.10391 },
    { lat: 48.895286, lng: 20.102868 },
    { lat: 48.895328, lng: 20.101802 },
    { lat: 48.895378, lng: 20.100571 },
    { lat: 48.895405, lng: 20.099417 },
    { lat: 48.895451, lng: 20.098492 },
    { lat: 48.895498, lng: 20.097528 },
    { lat: 48.895549, lng: 20.096483 },
    { lat: 48.895612, lng: 20.095204 },
    { lat: 48.895879, lng: 20.093956 },
    { lat: 48.896095, lng: 20.092942 },
    { lat: 48.896384, lng: 20.091594 },
    { lat: 48.89662, lng: 20.090491 },
    { lat: 48.896721, lng: 20.090055 },
    { lat: 48.8890629, lng: 20.0864319 },
    { lat: 48.8877537, lng: 20.0815767 },
    { lat: 48.8805857, lng: 20.0738215 },
    { lat: 48.8736952, lng: 20.0722393 },
    { lat: 48.8717607, lng: 20.0719502 },
    { lat: 48.8674739, lng: 20.068215 },
    { lat: 48.8651526, lng: 20.0679995 },
    { lat: 48.8643054, lng: 20.0687044 },
    { lat: 48.8642569, lng: 20.0688674 },
    { lat: 48.8641162, lng: 20.0689816 },
    { lat: 48.8640843, lng: 20.0691348 },
    { lat: 48.8629849, lng: 20.0698195 },
    { lat: 48.862584, lng: 20.0695458 },
    { lat: 48.8624828, lng: 20.0689733 },
    { lat: 48.8625155, lng: 20.0680734 },
    { lat: 48.8625848, lng: 20.0674853 },
    { lat: 48.8624812, lng: 20.0664122 },
    { lat: 48.8623829, lng: 20.0661847 },
    { lat: 48.8623319, lng: 20.065771 },
    { lat: 48.8619547, lng: 20.0650293 },
    { lat: 48.8617824, lng: 20.0641874 },
    { lat: 48.8618748, lng: 20.0634439 },
    { lat: 48.8620183, lng: 20.0629664 },
    { lat: 48.8624714, lng: 20.0621809 },
    { lat: 48.862641, lng: 20.0620279 },
    { lat: 48.8628765, lng: 20.0614018 },
    { lat: 48.862908, lng: 20.0605267 },
    { lat: 48.8628546, lng: 20.060325 },
    { lat: 48.8620756, lng: 20.0592386 },
    { lat: 48.8617226, lng: 20.0590122 },
    { lat: 48.8615419, lng: 20.0586988 },
    { lat: 48.8610853, lng: 20.0582194 },
    { lat: 48.8608653, lng: 20.0581851 },
    { lat: 48.8606867, lng: 20.0579795 },
    { lat: 48.8603991, lng: 20.0574998 },
    { lat: 48.8602687, lng: 20.0574013 },
    { lat: 48.8590826, lng: 20.0553748 },
    { lat: 48.8585137, lng: 20.0546646 },
    { lat: 48.8580219, lng: 20.0541775 },
    { lat: 48.8574299, lng: 20.0537538 },
    { lat: 48.8571715, lng: 20.0534385 },
    { lat: 48.8570764, lng: 20.0534278 },
    { lat: 48.8567221, lng: 20.0530043 },
    { lat: 48.8565541, lng: 20.0526163 },
    { lat: 48.8563034, lng: 20.0522992 },
    { lat: 48.8560009, lng: 20.0515245 },
    { lat: 48.8558631, lng: 20.0508544 },
    { lat: 48.8558599, lng: 20.050423 },
    { lat: 48.8559201, lng: 20.0500804 },
    { lat: 48.8559031, lng: 20.0493133 },
    { lat: 48.8559753, lng: 20.0492317 },
    { lat: 48.8559422, lng: 20.0489798 },
    { lat: 48.8559784, lng: 20.0487074 },
    { lat: 48.8557755, lng: 20.0478056 },
    { lat: 48.8554222, lng: 20.0474578 },
    { lat: 48.8553881, lng: 20.0472611 },
    { lat: 48.8553005, lng: 20.0472412 },
    { lat: 48.8552438, lng: 20.046983 },
    { lat: 48.8544159, lng: 20.0462481 },
    { lat: 48.8543619, lng: 20.0463058 },
    { lat: 48.8542714, lng: 20.0462403 },
    { lat: 48.8541171, lng: 20.0459681 },
    { lat: 48.8540657, lng: 20.0460023 },
    { lat: 48.8539225, lng: 20.045811 },
    { lat: 48.8536695, lng: 20.0457069 },
    { lat: 48.8535282, lng: 20.0458065 },
    { lat: 48.8531345, lng: 20.0456013 },
    { lat: 48.8530764, lng: 20.0456694 },
    { lat: 48.8529363, lng: 20.0455221 },
    { lat: 48.8523793, lng: 20.0453689 },
    { lat: 48.8521021, lng: 20.0453952 },
    { lat: 48.851648, lng: 20.0451153 },
    { lat: 48.8513885, lng: 20.045136 },
    { lat: 48.8512749, lng: 20.0450824 },
    { lat: 48.8512021, lng: 20.0450904 },
    { lat: 48.850992, lng: 20.0453821 },
    { lat: 48.8507324, lng: 20.0454446 },
    { lat: 48.8506732, lng: 20.0455418 },
    { lat: 48.8507282, lng: 20.0456174 },
    { lat: 48.8507165, lng: 20.0458259 },
    { lat: 48.8506198, lng: 20.0459649 },
    { lat: 48.8503687, lng: 20.0460517 },
    { lat: 48.8501691, lng: 20.0459901 },
    { lat: 48.8501299, lng: 20.0460754 },
    { lat: 48.8499392, lng: 20.0460699 },
    { lat: 48.8498218, lng: 20.0461708 },
    { lat: 48.8492992, lng: 20.0461459 },
    { lat: 48.8490834, lng: 20.0460173 },
    { lat: 48.8489162, lng: 20.0460049 },
    { lat: 48.8485344, lng: 20.0461437 },
    { lat: 48.8483794, lng: 20.0461001 },
    { lat: 48.8480889, lng: 20.0462678 },
    { lat: 48.8479006, lng: 20.046261 },
    { lat: 48.8478326, lng: 20.0461984 },
    { lat: 48.8473228, lng: 20.0462339 },
    { lat: 48.8471788, lng: 20.046181 },
    { lat: 48.8471264, lng: 20.0460891 },
    { lat: 48.8469175, lng: 20.0463456 },
    { lat: 48.846708, lng: 20.0462689 },
    { lat: 48.8466201, lng: 20.0461807 },
    { lat: 48.8466173, lng: 20.0460325 },
    { lat: 48.8462609, lng: 20.0456165 },
    { lat: 48.8459859, lng: 20.0454205 },
    { lat: 48.8454662, lng: 20.0446143 },
    { lat: 48.8452059, lng: 20.0444212 },
    { lat: 48.8447883, lng: 20.0438544 },
    { lat: 48.8446106, lng: 20.0437239 },
    { lat: 48.8445048, lng: 20.0437847 },
    { lat: 48.8444412, lng: 20.0437128 },
    { lat: 48.8444317, lng: 20.0435699 },
    { lat: 48.8440272, lng: 20.0434273 },
    { lat: 48.8437256, lng: 20.0428852 },
    { lat: 48.8434893, lng: 20.042594 },
    { lat: 48.8434021, lng: 20.0423638 },
    { lat: 48.8431281, lng: 20.0420032 },
    { lat: 48.8429548, lng: 20.041872 },
    { lat: 48.8426383, lng: 20.0409455 },
    { lat: 48.8424081, lng: 20.0407421 },
    { lat: 48.8423193, lng: 20.0406636 },
    { lat: 48.842135, lng: 20.041413 },
    { lat: 48.8417689, lng: 20.0419305 },
    { lat: 48.8413405, lng: 20.0422168 },
    { lat: 48.840731, lng: 20.0423808 },
    { lat: 48.8405504, lng: 20.0425907 },
    { lat: 48.8401911, lng: 20.0428343 },
    { lat: 48.8399358, lng: 20.0432239 },
    { lat: 48.839775, lng: 20.0433132 },
    { lat: 48.8394502, lng: 20.0442279 },
    { lat: 48.8392433, lng: 20.0446455 },
    { lat: 48.838997, lng: 20.0447117 },
    { lat: 48.8388301, lng: 20.0446478 },
    { lat: 48.8388022, lng: 20.0448196 },
    { lat: 48.8393019, lng: 20.0446492 },
    { lat: 48.8394383, lng: 20.0450868 },
    { lat: 48.8394698, lng: 20.0450567 },
    { lat: 48.8396818, lng: 20.0453366 },
    { lat: 48.8397457, lng: 20.0452214 },
    { lat: 48.8400918, lng: 20.045612 },
    { lat: 48.8401637, lng: 20.0458776 },
    { lat: 48.8400587, lng: 20.0461197 },
    { lat: 48.840263, lng: 20.0465916 },
    { lat: 48.8403707, lng: 20.0466089 },
    { lat: 48.8405851, lng: 20.0470527 },
    { lat: 48.8406345, lng: 20.0474027 },
    { lat: 48.8406194, lng: 20.0476362 },
    { lat: 48.8410608, lng: 20.0479631 },
    { lat: 48.8412896, lng: 20.0480233 },
    { lat: 48.8416812, lng: 20.048505 },
    { lat: 48.8417694, lng: 20.0487016 },
    { lat: 48.8417807, lng: 20.0488095 },
    { lat: 48.8412292, lng: 20.0499402 },
    { lat: 48.8404516, lng: 20.0511606 },
    { lat: 48.8415247, lng: 20.0528744 },
    { lat: 48.8413778, lng: 20.0531343 },
    { lat: 48.8419897, lng: 20.0541001 },
    { lat: 48.8419661, lng: 20.0541295 },
    { lat: 48.8426978, lng: 20.0549886 },
    { lat: 48.8416587, lng: 20.0563423 },
    { lat: 48.8410221, lng: 20.0575085 },
    { lat: 48.8413092, lng: 20.057918 },
    { lat: 48.8417115, lng: 20.0582761 },
    { lat: 48.8414661, lng: 20.0596238 },
    { lat: 48.8411038, lng: 20.0606953 },
    { lat: 48.8412732, lng: 20.0608438 },
    { lat: 48.8412726, lng: 20.0609665 },
    { lat: 48.841029, lng: 20.0614812 },
    { lat: 48.8409101, lng: 20.0615467 },
    { lat: 48.8406756, lng: 20.0619787 },
    { lat: 48.8400509, lng: 20.0636893 },
    { lat: 48.8408167, lng: 20.0645863 },
    { lat: 48.8403391, lng: 20.0653819 },
    { lat: 48.8398633, lng: 20.0666554 },
    { lat: 48.8396731, lng: 20.0674242 },
    { lat: 48.8389406, lng: 20.0670021 },
    { lat: 48.8387703, lng: 20.0678781 },
    { lat: 48.8387725, lng: 20.0687242 },
    { lat: 48.8385869, lng: 20.0687553 },
    { lat: 48.8388719, lng: 20.0712813 },
    { lat: 48.8383272, lng: 20.0712643 },
    { lat: 48.8383348, lng: 20.0734479 },
    { lat: 48.8381735, lng: 20.0743777 },
    { lat: 48.8381189, lng: 20.0751061 },
    { lat: 48.8381142, lng: 20.0763351 },
    { lat: 48.8382706, lng: 20.0770987 },
    { lat: 48.8382599, lng: 20.0774137 },
    { lat: 48.8379964, lng: 20.0767835 },
    { lat: 48.8379026, lng: 20.076724 },
    { lat: 48.8377721, lng: 20.0768046 },
    { lat: 48.8377473, lng: 20.076725 },
    { lat: 48.837593, lng: 20.076726 },
    { lat: 48.8374428, lng: 20.0764119 },
    { lat: 48.8371887, lng: 20.0761219 },
    { lat: 48.8369212, lng: 20.0761882 },
    { lat: 48.8368297, lng: 20.0761557 },
    { lat: 48.8363876, lng: 20.0758152 },
    { lat: 48.8360641, lng: 20.0758052 },
    { lat: 48.835949, lng: 20.0756112 },
    { lat: 48.8358615, lng: 20.0756195 },
    { lat: 48.8357572, lng: 20.0754927 },
    { lat: 48.8356837, lng: 20.075486 },
    { lat: 48.8356742, lng: 20.0755824 },
    { lat: 48.8355939, lng: 20.0754945 },
    { lat: 48.8353197, lng: 20.0756158 },
    { lat: 48.8351766, lng: 20.0758613 },
    { lat: 48.8350594, lng: 20.0756403 },
    { lat: 48.834647, lng: 20.0756384 },
    { lat: 48.8345063, lng: 20.0755562 },
    { lat: 48.8344205, lng: 20.075605 },
    { lat: 48.8338993, lng: 20.0754768 },
    { lat: 48.8336981, lng: 20.0756187 },
    { lat: 48.8334928, lng: 20.0753378 },
    { lat: 48.8330977, lng: 20.0761937 },
    { lat: 48.833302, lng: 20.0762838 },
    { lat: 48.8334667, lng: 20.076623 },
    { lat: 48.8334688, lng: 20.0768412 },
    { lat: 48.833222, lng: 20.0783728 },
    { lat: 48.8331314, lng: 20.0786884 },
    { lat: 48.832957, lng: 20.0785 },
    { lat: 48.832654, lng: 20.0790472 },
    { lat: 48.8324278, lng: 20.0796823 },
    { lat: 48.832777, lng: 20.0799634 },
    { lat: 48.832589, lng: 20.0810865 },
    { lat: 48.8323649, lng: 20.0819945 },
    { lat: 48.8324798, lng: 20.0820795 },
    { lat: 48.8324255, lng: 20.0821529 },
    { lat: 48.8326149, lng: 20.0822713 },
    { lat: 48.8325408, lng: 20.0824967 },
    { lat: 48.8332972, lng: 20.0830319 },
    { lat: 48.8328614, lng: 20.0839952 },
    { lat: 48.8327081, lng: 20.0844601 },
    { lat: 48.8320545, lng: 20.0839345 },
    { lat: 48.8311685, lng: 20.0833629 },
    { lat: 48.8309814, lng: 20.0842402 },
    { lat: 48.8315929, lng: 20.0846196 },
    { lat: 48.8314383, lng: 20.0852483 },
    { lat: 48.8318779, lng: 20.0856435 },
    { lat: 48.8323559, lng: 20.0858989 },
    { lat: 48.8321402, lng: 20.0870385 },
    { lat: 48.832065, lng: 20.087018 },
    { lat: 48.832002, lng: 20.0876245 },
    { lat: 48.8329195, lng: 20.0880292 },
    { lat: 48.8328503, lng: 20.0884043 },
    { lat: 48.8329334, lng: 20.0884512 },
    { lat: 48.8328593, lng: 20.0887856 },
    { lat: 48.8328075, lng: 20.0895548 },
    { lat: 48.8328562, lng: 20.0895913 },
    { lat: 48.8328325, lng: 20.0898663 },
    { lat: 48.8328961, lng: 20.0899015 },
    { lat: 48.8323982, lng: 20.0916131 },
    { lat: 48.8323758, lng: 20.0917518 },
    { lat: 48.8325066, lng: 20.0917803 },
    { lat: 48.8319201, lng: 20.0924223 },
    { lat: 48.831818, lng: 20.0927869 },
    { lat: 48.8316243, lng: 20.0928186 },
    { lat: 48.8313786, lng: 20.0927598 },
    { lat: 48.8314022, lng: 20.0930304 },
    { lat: 48.8315412, lng: 20.0932905 },
    { lat: 48.8315198, lng: 20.0934834 },
    { lat: 48.8307127, lng: 20.0942415 },
    { lat: 48.8300349, lng: 20.0946054 },
    { lat: 48.8302, lng: 20.0946714 },
    { lat: 48.8302953, lng: 20.0948537 },
    { lat: 48.8301094, lng: 20.0950757 },
    { lat: 48.8302092, lng: 20.0953937 },
    { lat: 48.8305667, lng: 20.0960155 },
    { lat: 48.8306543, lng: 20.0962529 },
    { lat: 48.8308447, lng: 20.0970812 },
    { lat: 48.8307975, lng: 20.0971403 },
    { lat: 48.8310534, lng: 20.0981126 },
    { lat: 48.8311138, lng: 20.0987075 },
    { lat: 48.8305889, lng: 20.098238 },
    { lat: 48.8301061, lng: 20.0980784 },
    { lat: 48.829714, lng: 20.0977466 },
    { lat: 48.8290701, lng: 20.0976433 },
    { lat: 48.8289539, lng: 20.0974222 },
    { lat: 48.8289104, lng: 20.0971535 },
    { lat: 48.8287175, lng: 20.0970079 },
    { lat: 48.8286208, lng: 20.0958841 },
    { lat: 48.8282496, lng: 20.095837 },
    { lat: 48.8284043, lng: 20.0974465 },
    { lat: 48.8283418, lng: 20.0977934 },
    { lat: 48.8283409, lng: 20.0983479 },
    { lat: 48.8277196, lng: 20.0981821 },
    { lat: 48.8275923, lng: 20.098165 },
    { lat: 48.8274811, lng: 20.0970147 },
    { lat: 48.827588, lng: 20.0959127 },
    { lat: 48.82757, lng: 20.0946178 },
    { lat: 48.8274477, lng: 20.0936059 },
    { lat: 48.8273926, lng: 20.0934473 },
    { lat: 48.8273231, lng: 20.0920208 },
    { lat: 48.8271969, lng: 20.0917735 },
    { lat: 48.8271841, lng: 20.0914608 },
    { lat: 48.8265149, lng: 20.0914007 },
    { lat: 48.8261735, lng: 20.0908733 },
    { lat: 48.8260326, lng: 20.0907636 },
    { lat: 48.8255522, lng: 20.0899762 },
    { lat: 48.8253189, lng: 20.0898072 },
    { lat: 48.824952, lng: 20.0898145 },
    { lat: 48.8249228, lng: 20.0894894 },
    { lat: 48.8248051, lng: 20.0894733 },
    { lat: 48.8241093, lng: 20.0882972 },
    { lat: 48.8236122, lng: 20.0889169 },
    { lat: 48.8233553, lng: 20.0883952 },
    { lat: 48.823425, lng: 20.0880066 },
    { lat: 48.8235669, lng: 20.0880072 },
    { lat: 48.8235077, lng: 20.0873576 },
    { lat: 48.8232979, lng: 20.0868862 },
    { lat: 48.8231347, lng: 20.0867514 },
    { lat: 48.8230263, lng: 20.0858337 },
    { lat: 48.8229298, lng: 20.085788 },
    { lat: 48.8226871, lng: 20.0851696 },
    { lat: 48.8239816, lng: 20.0841883 },
    { lat: 48.8246429, lng: 20.0837576 },
    { lat: 48.824648, lng: 20.0836342 },
    { lat: 48.8253713, lng: 20.0834979 },
    { lat: 48.8258477, lng: 20.0829484 },
    { lat: 48.8261853, lng: 20.0828073 },
    { lat: 48.8261743, lng: 20.0824809 },
    { lat: 48.8262193, lng: 20.0822039 },
    { lat: 48.8263169, lng: 20.0821947 },
    { lat: 48.8265471, lng: 20.0814088 },
    { lat: 48.82639, lng: 20.0812324 },
    { lat: 48.8261549, lng: 20.0812137 },
    { lat: 48.8260726, lng: 20.0810438 },
    { lat: 48.826141, lng: 20.0808465 },
    { lat: 48.8261278, lng: 20.080575 },
    { lat: 48.8257991, lng: 20.079842 },
    { lat: 48.8257424, lng: 20.0795879 },
    { lat: 48.8257713, lng: 20.0791348 },
    { lat: 48.8256091, lng: 20.0789727 },
    { lat: 48.8255728, lng: 20.0787713 },
    { lat: 48.8251999, lng: 20.0784382 },
    { lat: 48.8251885, lng: 20.0782481 },
    { lat: 48.8251162, lng: 20.0781594 },
    { lat: 48.8251023, lng: 20.077982 },
    { lat: 48.8249855, lng: 20.0777746 },
    { lat: 48.8248734, lng: 20.0773215 },
    { lat: 48.8247859, lng: 20.0773573 },
    { lat: 48.8247832, lng: 20.077453 },
    { lat: 48.8246749, lng: 20.0773949 },
    { lat: 48.8245965, lng: 20.0768836 },
    { lat: 48.8248272, lng: 20.076057 },
    { lat: 48.8247169, lng: 20.0753439 },
    { lat: 48.824755, lng: 20.0752313 },
    { lat: 48.8247568, lng: 20.0748901 },
    { lat: 48.8246087, lng: 20.0747946 },
    { lat: 48.8244557, lng: 20.0743043 },
    { lat: 48.8243301, lng: 20.0741249 },
    { lat: 48.8241499, lng: 20.0739918 },
    { lat: 48.82414, lng: 20.0736925 },
    { lat: 48.8240811, lng: 20.073466 },
    { lat: 48.8241024, lng: 20.0732185 },
    { lat: 48.8240475, lng: 20.0730328 },
    { lat: 48.8240935, lng: 20.0726463 },
    { lat: 48.823839, lng: 20.0720423 },
    { lat: 48.8239058, lng: 20.0717768 },
    { lat: 48.8238642, lng: 20.0716442 },
    { lat: 48.8238996, lng: 20.0714364 },
    { lat: 48.8238729, lng: 20.0712752 },
    { lat: 48.8240315, lng: 20.0712192 },
    { lat: 48.8240608, lng: 20.0702885 },
    { lat: 48.824181, lng: 20.0695948 },
    { lat: 48.8239415, lng: 20.0690855 },
    { lat: 48.8238575, lng: 20.0685751 },
    { lat: 48.8239556, lng: 20.0683339 },
    { lat: 48.8241134, lng: 20.0681689 },
    { lat: 48.824481, lng: 20.0671923 },
    { lat: 48.824473, lng: 20.0668656 },
    { lat: 48.8246975, lng: 20.066517 },
    { lat: 48.824582, lng: 20.0659003 },
    { lat: 48.8247398, lng: 20.0652441 },
    { lat: 48.8249227, lng: 20.0649809 },
    { lat: 48.8254264, lng: 20.0650152 },
    { lat: 48.825154, lng: 20.06354 },
    { lat: 48.8251333, lng: 20.0624639 },
    { lat: 48.825396, lng: 20.0618385 },
    { lat: 48.8255918, lng: 20.0616427 },
    { lat: 48.8257607, lng: 20.0608486 },
    { lat: 48.8259463, lng: 20.0604897 },
    { lat: 48.8265842, lng: 20.0586968 },
    { lat: 48.826926, lng: 20.057013 },
    { lat: 48.8268486, lng: 20.0564744 },
    { lat: 48.8273445, lng: 20.0560182 },
    { lat: 48.827234, lng: 20.0557695 },
    { lat: 48.8273204, lng: 20.0554611 },
    { lat: 48.827652, lng: 20.0549793 },
    { lat: 48.8276924, lng: 20.0536378 },
    { lat: 48.8275517, lng: 20.0529826 },
    { lat: 48.8273873, lng: 20.0517288 },
    { lat: 48.827514, lng: 20.0505429 },
    { lat: 48.8271908, lng: 20.0496726 },
    { lat: 48.8269036, lng: 20.0492087 },
    { lat: 48.8267158, lng: 20.048776 },
    { lat: 48.8267158, lng: 20.0479027 },
    { lat: 48.8261245, lng: 20.0477403 },
    { lat: 48.8258742, lng: 20.0484471 },
    { lat: 48.8255854, lng: 20.0482425 },
    { lat: 48.8254159, lng: 20.0482314 },
    { lat: 48.8248499, lng: 20.0484076 },
    { lat: 48.8246855, lng: 20.048164 },
    { lat: 48.8244432, lng: 20.0476411 },
    { lat: 48.8242183, lng: 20.0474305 },
    { lat: 48.8237677, lng: 20.0481963 },
    { lat: 48.8235439, lng: 20.0481492 },
    { lat: 48.8232875, lng: 20.0487876 },
    { lat: 48.8226408, lng: 20.0482894 },
    { lat: 48.8224232, lng: 20.0476277 },
    { lat: 48.8224243, lng: 20.046918 },
    { lat: 48.8225645, lng: 20.0463861 },
    { lat: 48.8231574, lng: 20.0461798 },
    { lat: 48.8234946, lng: 20.0456703 },
    { lat: 48.8233711, lng: 20.0452452 },
    { lat: 48.8229558, lng: 20.044575 },
    { lat: 48.8224857, lng: 20.0442103 },
    { lat: 48.8217291, lng: 20.0452235 },
    { lat: 48.8215058, lng: 20.0453541 },
    { lat: 48.8203737, lng: 20.0454614 },
    { lat: 48.820063, lng: 20.0455726 },
    { lat: 48.8199748, lng: 20.0447757 },
    { lat: 48.8197481, lng: 20.0440874 },
    { lat: 48.8195002, lng: 20.0439197 },
    { lat: 48.8190453, lng: 20.0442494 },
    { lat: 48.8189344, lng: 20.0457339 },
    { lat: 48.8187964, lng: 20.0463746 },
    { lat: 48.8184588, lng: 20.0474985 },
    { lat: 48.817791, lng: 20.0475889 },
    { lat: 48.8174821, lng: 20.0475499 },
    { lat: 48.8161462, lng: 20.0477443 },
    { lat: 48.8157687, lng: 20.0479027 },
    { lat: 48.8127249, lng: 20.0484093 },
    { lat: 48.8128484, lng: 20.0492163 },
    { lat: 48.8128494, lng: 20.0495436 },
    { lat: 48.8126583, lng: 20.0499573 },
    { lat: 48.8124354, lng: 20.0500195 },
    { lat: 48.8122341, lng: 20.0496701 },
    { lat: 48.8121196, lng: 20.0497899 },
    { lat: 48.8120703, lng: 20.0503678 },
    { lat: 48.8121747, lng: 20.0513128 },
    { lat: 48.8119502, lng: 20.0519072 },
    { lat: 48.8117556, lng: 20.0519664 },
    { lat: 48.8110459, lng: 20.0513378 },
    { lat: 48.8108366, lng: 20.0513168 },
    { lat: 48.8106706, lng: 20.0514415 },
    { lat: 48.8105541, lng: 20.0522713 },
    { lat: 48.8099038, lng: 20.0525915 },
    { lat: 48.8096385, lng: 20.0524937 },
    { lat: 48.8079823, lng: 20.049459 },
    { lat: 48.8065617, lng: 20.0456096 },
    { lat: 48.8071754, lng: 20.0423047 },
    { lat: 48.8074993, lng: 20.0381815 },
    { lat: 48.8075236, lng: 20.0365415 },
    { lat: 48.8075078, lng: 20.0355882 },
    { lat: 48.8073759, lng: 20.0348778 },
    { lat: 48.806726, lng: 20.033742 },
    { lat: 48.806725, lng: 20.033791 },
    { lat: 48.806681, lng: 20.034317 },
    { lat: 48.806606, lng: 20.035225 },
    { lat: 48.806561, lng: 20.035771 },
    { lat: 48.806516, lng: 20.036289 },
    { lat: 48.806454, lng: 20.036942 },
    { lat: 48.806397, lng: 20.037509 },
    { lat: 48.80627, lng: 20.037954 },
    { lat: 48.806168, lng: 20.038351 },
    { lat: 48.806072, lng: 20.038679 },
    { lat: 48.805872, lng: 20.039365 },
    { lat: 48.805737, lng: 20.03983 },
    { lat: 48.805675, lng: 20.040009 },
    { lat: 48.805427, lng: 20.040693 },
    { lat: 48.805237, lng: 20.041214 },
    { lat: 48.80517, lng: 20.04174 },
    { lat: 48.805115, lng: 20.04214 },
    { lat: 48.8049, lng: 20.042612 },
    { lat: 48.804879, lng: 20.042973 },
    { lat: 48.804738, lng: 20.043367 },
    { lat: 48.804515, lng: 20.043443 },
    { lat: 48.804323, lng: 20.043513 },
    { lat: 48.804169, lng: 20.043932 },
    { lat: 48.803904, lng: 20.044646 },
    { lat: 48.803936, lng: 20.044915 },
    { lat: 48.804045, lng: 20.0458 },
    { lat: 48.804161, lng: 20.046735 },
    { lat: 48.804196, lng: 20.047017 },
    { lat: 48.804087, lng: 20.047562 },
    { lat: 48.804168, lng: 20.048003 },
    { lat: 48.804251, lng: 20.048311 },
    { lat: 48.804601, lng: 20.049047 },
    { lat: 48.804645, lng: 20.04959 },
    { lat: 48.804656, lng: 20.050028 },
    { lat: 48.804658, lng: 20.050319 },
    { lat: 48.804783, lng: 20.051036 },
    { lat: 48.80497, lng: 20.052089 },
    { lat: 48.805034, lng: 20.052471 },
    { lat: 48.804998, lng: 20.052533 },
    { lat: 48.804691, lng: 20.053065 },
    { lat: 48.804394, lng: 20.05358 },
    { lat: 48.804231, lng: 20.053861 },
    { lat: 48.804131, lng: 20.054039 },
    { lat: 48.803902, lng: 20.054436 },
    { lat: 48.803531, lng: 20.055063 },
    { lat: 48.803311, lng: 20.054584 },
    { lat: 48.803132, lng: 20.054208 },
    { lat: 48.803042, lng: 20.05401 },
    { lat: 48.80291, lng: 20.053744 },
    { lat: 48.802617, lng: 20.053139 },
    { lat: 48.802443, lng: 20.052777 },
    { lat: 48.802417, lng: 20.052726 },
    { lat: 48.80216, lng: 20.052418 },
    { lat: 48.801784, lng: 20.051967 },
    { lat: 48.801556, lng: 20.051692 },
    { lat: 48.801225, lng: 20.051293 },
    { lat: 48.801035, lng: 20.051641 },
    { lat: 48.800694, lng: 20.052249 },
    { lat: 48.800412, lng: 20.05245 },
    { lat: 48.800317, lng: 20.052522 },
    { lat: 48.799942, lng: 20.052715 },
    { lat: 48.799703, lng: 20.052836 },
    { lat: 48.799642, lng: 20.052554 },
    { lat: 48.79944, lng: 20.052257 },
    { lat: 48.799274, lng: 20.05177 },
    { lat: 48.799192, lng: 20.05163 },
    { lat: 48.798955, lng: 20.051403 },
    { lat: 48.798689, lng: 20.05089 },
    { lat: 48.798492, lng: 20.050426 },
    { lat: 48.798505, lng: 20.04991 },
    { lat: 48.798421, lng: 20.04958 },
    { lat: 48.798232, lng: 20.050231 },
    { lat: 48.798047, lng: 20.050885 },
    { lat: 48.797838, lng: 20.05162 },
    { lat: 48.797653, lng: 20.052274 },
    { lat: 48.797746, lng: 20.052867 },
    { lat: 48.797809, lng: 20.053285 },
    { lat: 48.797874, lng: 20.053695 },
    { lat: 48.797905, lng: 20.053911 },
    { lat: 48.797942, lng: 20.054152 },
    { lat: 48.798068, lng: 20.054945 },
    { lat: 48.79823, lng: 20.055985 },
    { lat: 48.798344, lng: 20.056723 },
    { lat: 48.79849, lng: 20.057647 },
    { lat: 48.798646, lng: 20.058646 },
    { lat: 48.79884, lng: 20.059885 },
    { lat: 48.799002, lng: 20.060934 },
    { lat: 48.799155, lng: 20.061916 },
    { lat: 48.799296, lng: 20.062822 },
    { lat: 48.799369, lng: 20.062926 },
    { lat: 48.799858, lng: 20.06366 },
    { lat: 48.800168, lng: 20.064134 },
    { lat: 48.800448, lng: 20.064553 },
    { lat: 48.800886, lng: 20.06521 },
    { lat: 48.801316, lng: 20.065855 },
    { lat: 48.801863, lng: 20.066681 },
    { lat: 48.802207, lng: 20.067343 },
    { lat: 48.802673, lng: 20.068244 },
    { lat: 48.803143, lng: 20.06916 },
    { lat: 48.80365, lng: 20.070138 },
    { lat: 48.803776, lng: 20.070387 },
    { lat: 48.803803, lng: 20.071229 },
    { lat: 48.803838, lng: 20.072477 },
    { lat: 48.803872, lng: 20.07351 },
    { lat: 48.803902, lng: 20.074468 },
    { lat: 48.803917, lng: 20.07502 },
    { lat: 48.803927, lng: 20.076029 },
    { lat: 48.803941, lng: 20.07732 },
    { lat: 48.803943, lng: 20.077818 },
    { lat: 48.803798, lng: 20.07805 },
    { lat: 48.803413, lng: 20.078644 },
    { lat: 48.802936, lng: 20.079387 },
    { lat: 48.802819, lng: 20.079567 },
    { lat: 48.802658, lng: 20.079825 },
    { lat: 48.80256, lng: 20.079984 },
    { lat: 48.802382, lng: 20.080267 },
    { lat: 48.802489, lng: 20.080737 },
    { lat: 48.802627, lng: 20.081363 },
    { lat: 48.802798, lng: 20.082109 },
    { lat: 48.802961, lng: 20.082848 },
    { lat: 48.803267, lng: 20.083163 },
    { lat: 48.80367, lng: 20.083578 },
    { lat: 48.803981, lng: 20.083903 },
    { lat: 48.803993, lng: 20.084018 },
    { lat: 48.804039, lng: 20.085036 },
    { lat: 48.804104, lng: 20.086312 },
    { lat: 48.804125, lng: 20.086844 },
    { lat: 48.803893, lng: 20.08734 },
    { lat: 48.803583, lng: 20.088012 },
    { lat: 48.803159, lng: 20.088924 },
    { lat: 48.802806, lng: 20.089679 },
    { lat: 48.802785, lng: 20.090473 },
    { lat: 48.80276, lng: 20.09145 },
    { lat: 48.802423, lng: 20.091271 },
    { lat: 48.80227, lng: 20.091187 },
    { lat: 48.802086, lng: 20.090943 },
    { lat: 48.801693, lng: 20.090422 },
    { lat: 48.80156, lng: 20.090037 },
    { lat: 48.801343, lng: 20.090052 },
    { lat: 48.801179, lng: 20.08984 },
    { lat: 48.80086, lng: 20.089636 },
    { lat: 48.800639, lng: 20.089651 },
    { lat: 48.800279, lng: 20.089682 },
    { lat: 48.799864, lng: 20.08971 },
    { lat: 48.799389, lng: 20.089746 },
    { lat: 48.799249, lng: 20.089757 },
    { lat: 48.799028, lng: 20.08968 },
    { lat: 48.798786, lng: 20.089597 },
    { lat: 48.798605, lng: 20.08976 },
    { lat: 48.798341, lng: 20.089737 },
    { lat: 48.798192, lng: 20.089726 },
    { lat: 48.797967, lng: 20.089813 },
    { lat: 48.79778, lng: 20.089876 },
    { lat: 48.797744, lng: 20.089873 },
    { lat: 48.797441, lng: 20.089832 },
    { lat: 48.796952, lng: 20.089769 },
    { lat: 48.796734, lng: 20.08974 },
    { lat: 48.796329, lng: 20.089643 },
    { lat: 48.796156, lng: 20.089603 },
    { lat: 48.796028, lng: 20.08969 },
    { lat: 48.79585, lng: 20.089823 },
    { lat: 48.795727, lng: 20.089819 },
    { lat: 48.795479, lng: 20.089807 },
    { lat: 48.795229, lng: 20.090108 },
    { lat: 48.794979, lng: 20.090405 },
    { lat: 48.79482, lng: 20.090479 },
    { lat: 48.794381, lng: 20.090682 },
    { lat: 48.793988, lng: 20.090869 },
    { lat: 48.793833, lng: 20.090938 },
    { lat: 48.793654, lng: 20.090953 },
    { lat: 48.793348, lng: 20.090975 },
    { lat: 48.792886, lng: 20.091011 },
    { lat: 48.792525, lng: 20.091038 },
    { lat: 48.792318, lng: 20.091049 },
    { lat: 48.792069, lng: 20.091064 },
    { lat: 48.792027, lng: 20.091063 },
    { lat: 48.791682, lng: 20.091102 },
    { lat: 48.791502, lng: 20.09134 },
    { lat: 48.791334, lng: 20.091268 },
    { lat: 48.791151, lng: 20.091537 },
    { lat: 48.791067, lng: 20.09171 },
    { lat: 48.791001, lng: 20.091849 },
    { lat: 48.790759, lng: 20.091946 },
    { lat: 48.790709, lng: 20.091937 },
    { lat: 48.790576, lng: 20.091901 },
    { lat: 48.79046, lng: 20.092066 },
    { lat: 48.790418, lng: 20.09212 },
    { lat: 48.790273, lng: 20.092264 },
    { lat: 48.790064, lng: 20.092334 },
    { lat: 48.790055, lng: 20.092326 },
    { lat: 48.789995, lng: 20.092653 },
    { lat: 48.789977, lng: 20.092662 },
    { lat: 48.789866, lng: 20.092622 },
    { lat: 48.78985, lng: 20.092629 },
    { lat: 48.789842, lng: 20.092777 },
    { lat: 48.789888, lng: 20.092904 },
    { lat: 48.789888, lng: 20.092933 },
    { lat: 48.789808, lng: 20.092991 },
    { lat: 48.789384, lng: 20.093261 },
    { lat: 48.789384, lng: 20.093279 },
    { lat: 48.789421, lng: 20.093335 },
    { lat: 48.789411, lng: 20.093364 },
    { lat: 48.789372, lng: 20.093411 },
    { lat: 48.78939, lng: 20.093479 },
    { lat: 48.789394, lng: 20.093557 },
    { lat: 48.789441, lng: 20.093651 },
    { lat: 48.789516, lng: 20.093765 },
    { lat: 48.789506, lng: 20.093803 },
    { lat: 48.789465, lng: 20.093789 },
    { lat: 48.78945, lng: 20.093806 },
    { lat: 48.789473, lng: 20.093917 },
    { lat: 48.789437, lng: 20.094001 },
    { lat: 48.789429, lng: 20.093994 },
    { lat: 48.789326, lng: 20.094509 },
    { lat: 48.789314, lng: 20.094569 },
    { lat: 48.789153, lng: 20.095284 },
    { lat: 48.78895, lng: 20.096168 },
    { lat: 48.788753, lng: 20.097044 },
    { lat: 48.788562, lng: 20.097879 },
    { lat: 48.788372, lng: 20.098708 },
    { lat: 48.788272, lng: 20.099149 },
    { lat: 48.788216, lng: 20.099336 },
    { lat: 48.788008, lng: 20.100034 },
    { lat: 48.787885, lng: 20.100455 },
    { lat: 48.787825, lng: 20.100797 },
    { lat: 48.787672, lng: 20.101657 },
    { lat: 48.787541, lng: 20.102393 },
    { lat: 48.787376, lng: 20.103329 },
    { lat: 48.78722, lng: 20.104214 },
    { lat: 48.787114, lng: 20.104805 },
    { lat: 48.787025, lng: 20.105309 },
    { lat: 48.786988, lng: 20.105503 },
    { lat: 48.786929, lng: 20.105846 },
    { lat: 48.78692, lng: 20.105883 },
    { lat: 48.78683, lng: 20.106038 },
    { lat: 48.786596, lng: 20.106445 },
    { lat: 48.786325, lng: 20.106908 },
    { lat: 48.786019, lng: 20.107433 },
    { lat: 48.785647, lng: 20.108079 },
    { lat: 48.785353, lng: 20.108581 },
    { lat: 48.78502, lng: 20.10916 },
    { lat: 48.784593, lng: 20.109899 },
    { lat: 48.784569, lng: 20.109933 },
    { lat: 48.784546, lng: 20.109957 },
    { lat: 48.784518, lng: 20.109994 },
    { lat: 48.784498, lng: 20.110015 },
    { lat: 48.784418, lng: 20.110183 },
    { lat: 48.784214, lng: 20.110611 },
    { lat: 48.784268, lng: 20.111008 },
    { lat: 48.784352, lng: 20.111736 },
    { lat: 48.784489, lng: 20.112853 },
    { lat: 48.784584, lng: 20.113656 },
    { lat: 48.784458, lng: 20.114185 },
    { lat: 48.784227, lng: 20.115162 },
    { lat: 48.784155, lng: 20.115453 },
    { lat: 48.784179, lng: 20.115603 },
    { lat: 48.784334, lng: 20.116666 },
    { lat: 48.784363, lng: 20.116847 },
    { lat: 48.784503, lng: 20.117085 },
    { lat: 48.784715, lng: 20.117434 },
    { lat: 48.784869, lng: 20.117759 },
    { lat: 48.785061, lng: 20.118159 },
    { lat: 48.785229, lng: 20.118734 },
    { lat: 48.785506, lng: 20.119206 },
    { lat: 48.785777, lng: 20.120103 },
    { lat: 48.785978, lng: 20.120674 },
    { lat: 48.786181, lng: 20.121054 },
    { lat: 48.786371, lng: 20.121471 },
    { lat: 48.786611, lng: 20.121919 },
    { lat: 48.786892, lng: 20.122443 },
    { lat: 48.787025, lng: 20.122841 },
    { lat: 48.787357, lng: 20.123317 },
    { lat: 48.787572, lng: 20.123969 },
    { lat: 48.787717, lng: 20.124169 },
    { lat: 48.78795, lng: 20.12474 },
    { lat: 48.788005, lng: 20.124874 },
    { lat: 48.788218, lng: 20.125339 },
    { lat: 48.78838, lng: 20.125697 },
    { lat: 48.788519, lng: 20.126122 },
    { lat: 48.788639, lng: 20.126496 },
    { lat: 48.788859, lng: 20.127031 },
    { lat: 48.789021, lng: 20.127572 },
    { lat: 48.789276, lng: 20.128423 },
    { lat: 48.789462, lng: 20.128674 },
    { lat: 48.789627, lng: 20.129138 },
    { lat: 48.789806, lng: 20.129644 },
    { lat: 48.790137, lng: 20.130898 },
    { lat: 48.79033, lng: 20.131055 },
    { lat: 48.790387, lng: 20.131092 },
    { lat: 48.790351, lng: 20.131183 },
    { lat: 48.790294, lng: 20.13131 },
    { lat: 48.790149, lng: 20.131651 },
    { lat: 48.790119, lng: 20.131685 },
    { lat: 48.790084, lng: 20.131712 },
    { lat: 48.790104, lng: 20.131746 },
    { lat: 48.790115, lng: 20.131758 },
    { lat: 48.790131, lng: 20.131796 },
    { lat: 48.79015, lng: 20.132097 },
    { lat: 48.790195, lng: 20.132208 },
    { lat: 48.790346, lng: 20.132438 },
    { lat: 48.790427, lng: 20.132787 },
    { lat: 48.790446, lng: 20.132928 },
    { lat: 48.790392, lng: 20.1335 },
    { lat: 48.790341, lng: 20.13385 },
    { lat: 48.790273, lng: 20.134342 },
    { lat: 48.790241, lng: 20.134781 },
    { lat: 48.790187, lng: 20.134898 },
    { lat: 48.789901, lng: 20.135536 },
    { lat: 48.789622, lng: 20.136153 },
    { lat: 48.789452, lng: 20.136395 },
    { lat: 48.78904, lng: 20.136981 },
    { lat: 48.788954, lng: 20.137095 },
    { lat: 48.78887, lng: 20.137251 },
    { lat: 48.788487, lng: 20.13797 },
    { lat: 48.788338, lng: 20.1384 },
    { lat: 48.78816, lng: 20.138923 },
    { lat: 48.787965, lng: 20.1391 },
    { lat: 48.787699, lng: 20.139343 },
    { lat: 48.787442, lng: 20.139476 },
    { lat: 48.786899, lng: 20.139759 },
    { lat: 48.786666, lng: 20.139878 },
    { lat: 48.786319, lng: 20.140153 },
    { lat: 48.786015, lng: 20.140403 },
    { lat: 48.785915, lng: 20.140476 },
    { lat: 48.785675, lng: 20.140747 },
    { lat: 48.785508, lng: 20.14121 },
    { lat: 48.785444, lng: 20.141389 },
    { lat: 48.785361, lng: 20.141455 },
    { lat: 48.78494, lng: 20.141808 },
    { lat: 48.784675, lng: 20.142027 },
    { lat: 48.784281, lng: 20.142352 },
    { lat: 48.783828, lng: 20.142722 },
    { lat: 48.783461, lng: 20.143029 },
    { lat: 48.783254, lng: 20.143198 },
    { lat: 48.783134, lng: 20.143444 },
    { lat: 48.782743, lng: 20.144251 },
    { lat: 48.782365, lng: 20.145032 },
    { lat: 48.782065, lng: 20.145653 },
    { lat: 48.782017, lng: 20.146323 },
    { lat: 48.781947, lng: 20.147318 },
    { lat: 48.781961, lng: 20.147373 },
    { lat: 48.782094, lng: 20.147793 },
    { lat: 48.782243, lng: 20.148328 },
    { lat: 48.782304, lng: 20.14855 },
    { lat: 48.78235, lng: 20.149151 },
    { lat: 48.782431, lng: 20.149482 },
    { lat: 48.782549, lng: 20.149957 },
    { lat: 48.782731, lng: 20.150738 },
    { lat: 48.782808, lng: 20.150807 },
    { lat: 48.783413, lng: 20.151318 },
    { lat: 48.783506, lng: 20.151397 },
    { lat: 48.783643, lng: 20.151742 },
    { lat: 48.783739, lng: 20.151798 },
    { lat: 48.784285, lng: 20.152085 },
    { lat: 48.784557, lng: 20.152622 },
    { lat: 48.784789, lng: 20.152804 },
    { lat: 48.784977, lng: 20.152949 },
    { lat: 48.785275, lng: 20.153344 },
    { lat: 48.785426, lng: 20.15354 },
    { lat: 48.785931, lng: 20.154005 },
    { lat: 48.786467, lng: 20.154265 },
    { lat: 48.78686, lng: 20.154489 },
    { lat: 48.787077, lng: 20.154727 },
    { lat: 48.787521, lng: 20.15521 },
    { lat: 48.787659, lng: 20.15536 },
    { lat: 48.787807, lng: 20.155512 },
    { lat: 48.787979, lng: 20.155678 },
    { lat: 48.788067, lng: 20.155811 },
    { lat: 48.788428, lng: 20.15637 },
    { lat: 48.788728, lng: 20.15684 },
    { lat: 48.788808, lng: 20.156959 },
    { lat: 48.788863, lng: 20.157047 },
    { lat: 48.788954, lng: 20.157187 },
    { lat: 48.78904, lng: 20.157359 },
    { lat: 48.789143, lng: 20.157572 },
    { lat: 48.789309, lng: 20.157837 },
    { lat: 48.789629, lng: 20.158356 },
    { lat: 48.789903, lng: 20.158799 },
    { lat: 48.79022, lng: 20.159313 },
    { lat: 48.790374, lng: 20.159492 },
    { lat: 48.790694, lng: 20.15986 },
    { lat: 48.790878, lng: 20.160143 },
    { lat: 48.791164, lng: 20.160576 },
    { lat: 48.791365, lng: 20.160867 },
    { lat: 48.79142, lng: 20.160943 },
    { lat: 48.791739, lng: 20.161382 },
    { lat: 48.791797, lng: 20.161481 },
    { lat: 48.792099, lng: 20.162017 },
    { lat: 48.792537, lng: 20.162804 },
    { lat: 48.79306, lng: 20.163703 },
    { lat: 48.79322, lng: 20.163983 },
    { lat: 48.793433, lng: 20.164176 },
    { lat: 48.793778, lng: 20.164713 },
    { lat: 48.794086, lng: 20.165181 },
    { lat: 48.794442, lng: 20.165746 },
    { lat: 48.794614, lng: 20.166018 },
    { lat: 48.794774, lng: 20.166285 },
    { lat: 48.79516, lng: 20.166933 },
    { lat: 48.795511, lng: 20.167555 },
    { lat: 48.795717, lng: 20.16793 },
    { lat: 48.796054, lng: 20.168234 },
    { lat: 48.796091, lng: 20.168255 },
  ],
  Hronec: [
    { lat: 48.8048086, lng: 19.5606623 },
    { lat: 48.8048189, lng: 19.560469 },
    { lat: 48.8044731, lng: 19.5601243 },
    { lat: 48.8044618, lng: 19.5602439 },
    { lat: 48.8037157, lng: 19.5592765 },
    { lat: 48.8030629, lng: 19.5587231 },
    { lat: 48.803033, lng: 19.5583669 },
    { lat: 48.802833, lng: 19.5580297 },
    { lat: 48.8026615, lng: 19.55761 },
    { lat: 48.8026587, lng: 19.5573976 },
    { lat: 48.8020867, lng: 19.5549088 },
    { lat: 48.8020209, lng: 19.5544559 },
    { lat: 48.8019583, lng: 19.5535281 },
    { lat: 48.801949, lng: 19.5508289 },
    { lat: 48.8020332, lng: 19.5503297 },
    { lat: 48.8025071, lng: 19.5490837 },
    { lat: 48.8031759, lng: 19.5481069 },
    { lat: 48.8036062, lng: 19.5477101 },
    { lat: 48.8044264, lng: 19.5472117 },
    { lat: 48.8047853, lng: 19.5471284 },
    { lat: 48.8051721, lng: 19.546886 },
    { lat: 48.8057078, lng: 19.546377 },
    { lat: 48.805774, lng: 19.5462253 },
    { lat: 48.8057017, lng: 19.5457788 },
    { lat: 48.8049923, lng: 19.5458599 },
    { lat: 48.8042004, lng: 19.5447338 },
    { lat: 48.8034471, lng: 19.5435434 },
    { lat: 48.8031607, lng: 19.5430907 },
    { lat: 48.80281, lng: 19.5423932 },
    { lat: 48.8018065, lng: 19.5413441 },
    { lat: 48.8012508, lng: 19.5405523 },
    { lat: 48.8006433, lng: 19.5392949 },
    { lat: 48.8001658, lng: 19.5389524 },
    { lat: 48.7995881, lng: 19.5387631 },
    { lat: 48.7991244, lng: 19.5382588 },
    { lat: 48.7962651, lng: 19.5380988 },
    { lat: 48.7950923, lng: 19.5386989 },
    { lat: 48.7949092, lng: 19.5387324 },
    { lat: 48.7946424, lng: 19.5391832 },
    { lat: 48.793519, lng: 19.5391594 },
    { lat: 48.7933146, lng: 19.5389288 },
    { lat: 48.7910295, lng: 19.5391801 },
    { lat: 48.7902325, lng: 19.5387497 },
    { lat: 48.7897324, lng: 19.5380709 },
    { lat: 48.7887297, lng: 19.5358549 },
    { lat: 48.7887214, lng: 19.5351832 },
    { lat: 48.7888604, lng: 19.5346762 },
    { lat: 48.7893638, lng: 19.5334384 },
    { lat: 48.7894774, lng: 19.532989 },
    { lat: 48.7898034, lng: 19.5305136 },
    { lat: 48.7898529, lng: 19.5295782 },
    { lat: 48.7899435, lng: 19.528825 },
    { lat: 48.7898336, lng: 19.5282004 },
    { lat: 48.7895555, lng: 19.5272429 },
    { lat: 48.7891404, lng: 19.5264974 },
    { lat: 48.788982, lng: 19.5254773 },
    { lat: 48.7890599, lng: 19.5249156 },
    { lat: 48.7889679, lng: 19.5243109 },
    { lat: 48.7886199, lng: 19.5238232 },
    { lat: 48.7878491, lng: 19.5231845 },
    { lat: 48.7870682, lng: 19.522351 },
    { lat: 48.785988, lng: 19.5210573 },
    { lat: 48.7845004, lng: 19.5195811 },
    { lat: 48.7839848, lng: 19.5187101 },
    { lat: 48.7837685, lng: 19.5184881 },
    { lat: 48.7831337, lng: 19.516914 },
    { lat: 48.782828, lng: 19.514035 },
    { lat: 48.7823144, lng: 19.5134277 },
    { lat: 48.7817214, lng: 19.5134212 },
    { lat: 48.7815528, lng: 19.513237 },
    { lat: 48.7810725, lng: 19.5129149 },
    { lat: 48.7806506, lng: 19.5128075 },
    { lat: 48.7804411, lng: 19.5125648 },
    { lat: 48.779474, lng: 19.5122128 },
    { lat: 48.7781808, lng: 19.5110981 },
    { lat: 48.7772116, lng: 19.5098482 },
    { lat: 48.776862, lng: 19.5123464 },
    { lat: 48.776506, lng: 19.5148902 },
    { lat: 48.7759954, lng: 19.5195696 },
    { lat: 48.7760229, lng: 19.5212477 },
    { lat: 48.7761317, lng: 19.5221198 },
    { lat: 48.7761558, lng: 19.52368 },
    { lat: 48.7762878, lng: 19.524763 },
    { lat: 48.7764831, lng: 19.5263663 },
    { lat: 48.7761363, lng: 19.52588 },
    { lat: 48.7760325, lng: 19.5261746 },
    { lat: 48.7758934, lng: 19.5263339 },
    { lat: 48.7764248, lng: 19.5276497 },
    { lat: 48.7765996, lng: 19.5278779 },
    { lat: 48.7766868, lng: 19.5281553 },
    { lat: 48.7768211, lng: 19.528969 },
    { lat: 48.7770226, lng: 19.529656 },
    { lat: 48.7773545, lng: 19.5313647 },
    { lat: 48.7773242, lng: 19.5320524 },
    { lat: 48.7775007, lng: 19.5332769 },
    { lat: 48.7776137, lng: 19.5350261 },
    { lat: 48.7773764, lng: 19.535578 },
    { lat: 48.7774633, lng: 19.5363931 },
    { lat: 48.7773055, lng: 19.5366151 },
    { lat: 48.7760325, lng: 19.5378511 },
    { lat: 48.7756153, lng: 19.5387101 },
    { lat: 48.7755843, lng: 19.5388687 },
    { lat: 48.7752779, lng: 19.5389944 },
    { lat: 48.7753008, lng: 19.5391532 },
    { lat: 48.7751393, lng: 19.5394888 },
    { lat: 48.7750997, lng: 19.5398791 },
    { lat: 48.77526, lng: 19.5405994 },
    { lat: 48.7754289, lng: 19.5409879 },
    { lat: 48.7755306, lng: 19.5414888 },
    { lat: 48.7745737, lng: 19.541583 },
    { lat: 48.7738563, lng: 19.5419146 },
    { lat: 48.7735167, lng: 19.5418044 },
    { lat: 48.7727057, lng: 19.5418836 },
    { lat: 48.7717447, lng: 19.5427515 },
    { lat: 48.770765, lng: 19.5434596 },
    { lat: 48.7702948, lng: 19.5439435 },
    { lat: 48.7690967, lng: 19.544679 },
    { lat: 48.7680869, lng: 19.5445768 },
    { lat: 48.767157, lng: 19.5448416 },
    { lat: 48.7670228, lng: 19.5453561 },
    { lat: 48.7670405, lng: 19.5462123 },
    { lat: 48.7668433, lng: 19.5473224 },
    { lat: 48.766257, lng: 19.5480052 },
    { lat: 48.7657491, lng: 19.5487773 },
    { lat: 48.7653117, lng: 19.549141 },
    { lat: 48.7647745, lng: 19.5491978 },
    { lat: 48.7643987, lng: 19.5488392 },
    { lat: 48.7640568, lng: 19.5487574 },
    { lat: 48.7637781, lng: 19.5485994 },
    { lat: 48.7636091, lng: 19.5488746 },
    { lat: 48.7632706, lng: 19.5489857 },
    { lat: 48.7629849, lng: 19.5492913 },
    { lat: 48.7626929, lng: 19.5493415 },
    { lat: 48.7621228, lng: 19.5491428 },
    { lat: 48.7616428, lng: 19.5490716 },
    { lat: 48.7612686, lng: 19.5484267 },
    { lat: 48.7609441, lng: 19.5473191 },
    { lat: 48.7602792, lng: 19.5462558 },
    { lat: 48.7592241, lng: 19.5458304 },
    { lat: 48.7586969, lng: 19.5458323 },
    { lat: 48.7581419, lng: 19.5455771 },
    { lat: 48.7578463, lng: 19.545247 },
    { lat: 48.7574339, lng: 19.5449762 },
    { lat: 48.7566613, lng: 19.5449095 },
    { lat: 48.7559028, lng: 19.5447128 },
    { lat: 48.7545219, lng: 19.5437623 },
    { lat: 48.7541572, lng: 19.5437715 },
    { lat: 48.7533827, lng: 19.5440133 },
    { lat: 48.7526775, lng: 19.5441145 },
    { lat: 48.7523535, lng: 19.5442009 },
    { lat: 48.7520571, lng: 19.5443958 },
    { lat: 48.7518705, lng: 19.5444318 },
    { lat: 48.751601, lng: 19.5443275 },
    { lat: 48.7512997, lng: 19.5447861 },
    { lat: 48.7510285, lng: 19.5449701 },
    { lat: 48.7506117, lng: 19.544922 },
    { lat: 48.7503924, lng: 19.5449809 },
    { lat: 48.7501614, lng: 19.5444623 },
    { lat: 48.7497969, lng: 19.5441562 },
    { lat: 48.7494478, lng: 19.5435704 },
    { lat: 48.7492558, lng: 19.5430698 },
    { lat: 48.7489555, lng: 19.5426892 },
    { lat: 48.7487857, lng: 19.5422499 },
    { lat: 48.7478766, lng: 19.5418147 },
    { lat: 48.7477389, lng: 19.5414892 },
    { lat: 48.7473933, lng: 19.541425 },
    { lat: 48.7470232, lng: 19.5411385 },
    { lat: 48.746424, lng: 19.5404817 },
    { lat: 48.7459471, lng: 19.53974 },
    { lat: 48.7459204, lng: 19.5398228 },
    { lat: 48.7455985, lng: 19.5396305 },
    { lat: 48.74523, lng: 19.5390649 },
    { lat: 48.7451135, lng: 19.5384148 },
    { lat: 48.7451598, lng: 19.5383932 },
    { lat: 48.7446743, lng: 19.5376824 },
    { lat: 48.7442836, lng: 19.5366336 },
    { lat: 48.7442568, lng: 19.5358256 },
    { lat: 48.744047, lng: 19.5349378 },
    { lat: 48.7438259, lng: 19.5343426 },
    { lat: 48.7434295, lng: 19.5340517 },
    { lat: 48.7430652, lng: 19.5339699 },
    { lat: 48.7425614, lng: 19.5340195 },
    { lat: 48.7421938, lng: 19.5336171 },
    { lat: 48.7417372, lng: 19.5333929 },
    { lat: 48.7416427, lng: 19.5336445 },
    { lat: 48.741177, lng: 19.5343745 },
    { lat: 48.7406166, lng: 19.5347092 },
    { lat: 48.7401815, lng: 19.5346855 },
    { lat: 48.7400178, lng: 19.534849 },
    { lat: 48.7397304, lng: 19.5348741 },
    { lat: 48.7390505, lng: 19.5346415 },
    { lat: 48.7387911, lng: 19.5344174 },
    { lat: 48.7385828, lng: 19.5343232 },
    { lat: 48.7384259, lng: 19.5341468 },
    { lat: 48.7368095, lng: 19.5334969 },
    { lat: 48.7357564, lng: 19.5331639 },
    { lat: 48.7347004, lng: 19.5320829 },
    { lat: 48.734215, lng: 19.5323066 },
    { lat: 48.7338068, lng: 19.5319842 },
    { lat: 48.7339089, lng: 19.5315062 },
    { lat: 48.7333652, lng: 19.5312994 },
    { lat: 48.7328432, lng: 19.5313416 },
    { lat: 48.7320899, lng: 19.5316711 },
    { lat: 48.7318545, lng: 19.531528 },
    { lat: 48.7309933, lng: 19.5304671 },
    { lat: 48.7308473, lng: 19.5307751 },
    { lat: 48.7301724, lng: 19.5301113 },
    { lat: 48.729999, lng: 19.5300163 },
    { lat: 48.7297796, lng: 19.5300711 },
    { lat: 48.7294734, lng: 19.5296613 },
    { lat: 48.7293788, lng: 19.5294164 },
    { lat: 48.729038, lng: 19.5294578 },
    { lat: 48.729038, lng: 19.5289156 },
    { lat: 48.7288699, lng: 19.5283211 },
    { lat: 48.7280923, lng: 19.5279561 },
    { lat: 48.7281014, lng: 19.5278556 },
    { lat: 48.7264662, lng: 19.5259444 },
    { lat: 48.7253862, lng: 19.5251704 },
    { lat: 48.7249941, lng: 19.5251473 },
    { lat: 48.7245542, lng: 19.5249151 },
    { lat: 48.7239222, lng: 19.5247505 },
    { lat: 48.7235634, lng: 19.5241757 },
    { lat: 48.7232383, lng: 19.5233825 },
    { lat: 48.722708, lng: 19.5229457 },
    { lat: 48.7226122, lng: 19.5221152 },
    { lat: 48.7221937, lng: 19.5214657 },
    { lat: 48.7218048, lng: 19.5210515 },
    { lat: 48.72069, lng: 19.5201382 },
    { lat: 48.7198955, lng: 19.5191546 },
    { lat: 48.7196291, lng: 19.5186041 },
    { lat: 48.7193212, lng: 19.5182546 },
    { lat: 48.7192116, lng: 19.5177358 },
    { lat: 48.718648, lng: 19.5169985 },
    { lat: 48.7182428, lng: 19.5167877 },
    { lat: 48.7176044, lng: 19.5160429 },
    { lat: 48.7172506, lng: 19.5159955 },
    { lat: 48.7163575, lng: 19.5152975 },
    { lat: 48.7157495, lng: 19.5150363 },
    { lat: 48.7156145, lng: 19.5146269 },
    { lat: 48.714893, lng: 19.51418 },
    { lat: 48.7146585, lng: 19.5138817 },
    { lat: 48.7142294, lng: 19.513691 },
    { lat: 48.7137648, lng: 19.5131003 },
    { lat: 48.712986, lng: 19.5124832 },
    { lat: 48.7126627, lng: 19.5120737 },
    { lat: 48.7122035, lng: 19.510912 },
    { lat: 48.7121615, lng: 19.5104154 },
    { lat: 48.7120366, lng: 19.5100014 },
    { lat: 48.7114751, lng: 19.5090639 },
    { lat: 48.7111546, lng: 19.5087154 },
    { lat: 48.7108702, lng: 19.5079704 },
    { lat: 48.7105324, lng: 19.5076334 },
    { lat: 48.7098439, lng: 19.5065855 },
    { lat: 48.7095872, lng: 19.5063977 },
    { lat: 48.7096218, lng: 19.5046278 },
    { lat: 48.709519, lng: 19.5034994 },
    { lat: 48.7088099, lng: 19.5024202 },
    { lat: 48.7084384, lng: 19.5020879 },
    { lat: 48.7080127, lng: 19.501869 },
    { lat: 48.7076085, lng: 19.5015637 },
    { lat: 48.7074467, lng: 19.5011783 },
    { lat: 48.7072968, lng: 19.5004481 },
    { lat: 48.7073222, lng: 19.4998745 },
    { lat: 48.7071381, lng: 19.4988431 },
    { lat: 48.7071936, lng: 19.4984784 },
    { lat: 48.7071274, lng: 19.4982677 },
    { lat: 48.707258, lng: 19.4981205 },
    { lat: 48.707387, lng: 19.497583 },
    { lat: 48.70727, lng: 19.497243 },
    { lat: 48.70718, lng: 19.497233 },
    { lat: 48.707098, lng: 19.496827 },
    { lat: 48.706882, lng: 19.496353 },
    { lat: 48.706637, lng: 19.496274 },
    { lat: 48.706351, lng: 19.495962 },
    { lat: 48.704888, lng: 19.494169 },
    { lat: 48.704776, lng: 19.49399 },
    { lat: 48.704429, lng: 19.493631 },
    { lat: 48.703929, lng: 19.493195 },
    { lat: 48.702599, lng: 19.492366 },
    { lat: 48.701394, lng: 19.491402 },
    { lat: 48.701266, lng: 19.491221 },
    { lat: 48.700955, lng: 19.491025 },
    { lat: 48.700464, lng: 19.49081 },
    { lat: 48.700222, lng: 19.490732 },
    { lat: 48.699872, lng: 19.490613 },
    { lat: 48.699639, lng: 19.490486 },
    { lat: 48.699271, lng: 19.490384 },
    { lat: 48.699248, lng: 19.490381 },
    { lat: 48.699189, lng: 19.490353 },
    { lat: 48.699136, lng: 19.490327 },
    { lat: 48.698536, lng: 19.490039 },
    { lat: 48.698133, lng: 19.4899 },
    { lat: 48.69756, lng: 19.489115 },
    { lat: 48.69714, lng: 19.488178 },
    { lat: 48.697065, lng: 19.488071 },
    { lat: 48.696373, lng: 19.487053 },
    { lat: 48.696263, lng: 19.486891 },
    { lat: 48.695299, lng: 19.485471 },
    { lat: 48.694993, lng: 19.484801 },
    { lat: 48.694843, lng: 19.484517 },
    { lat: 48.694255, lng: 19.483596 },
    { lat: 48.69408, lng: 19.483285 },
    { lat: 48.693556, lng: 19.482533 },
    { lat: 48.693421, lng: 19.482146 },
    { lat: 48.693248, lng: 19.481625 },
    { lat: 48.692176, lng: 19.478955 },
    { lat: 48.691836, lng: 19.478181 },
    { lat: 48.691667, lng: 19.477517 },
    { lat: 48.691592, lng: 19.477221 },
    { lat: 48.69128, lng: 19.476274 },
    { lat: 48.690943, lng: 19.475004 },
    { lat: 48.690971, lng: 19.474395 },
    { lat: 48.691118, lng: 19.471191 },
    { lat: 48.691128, lng: 19.470211 },
    { lat: 48.690659, lng: 19.467241 },
    { lat: 48.690118, lng: 19.466462 },
    { lat: 48.689291, lng: 19.466371 },
    { lat: 48.68899, lng: 19.466298 },
    { lat: 48.688812, lng: 19.466255 },
    { lat: 48.688145, lng: 19.465861 },
    { lat: 48.687507, lng: 19.464704 },
    { lat: 48.686336, lng: 19.463449 },
    { lat: 48.68615, lng: 19.463314 },
    { lat: 48.685777, lng: 19.463311 },
    { lat: 48.685283, lng: 19.463381 },
    { lat: 48.684809, lng: 19.463745 },
    { lat: 48.684647, lng: 19.46387 },
    { lat: 48.683842, lng: 19.464883 },
    { lat: 48.683419, lng: 19.465484 },
    { lat: 48.683268, lng: 19.465699 },
    { lat: 48.682823, lng: 19.466117 },
    { lat: 48.682474, lng: 19.466339 },
    { lat: 48.682218, lng: 19.466503 },
    { lat: 48.681207, lng: 19.467249 },
    { lat: 48.6806, lng: 19.467845 },
    { lat: 48.680571, lng: 19.467887 },
    { lat: 48.68028, lng: 19.468318 },
    { lat: 48.680196, lng: 19.468442 },
    { lat: 48.679221, lng: 19.469888 },
    { lat: 48.678891, lng: 19.4702 },
    { lat: 48.678665, lng: 19.47061 },
    { lat: 48.678281, lng: 19.471019 },
    { lat: 48.677862, lng: 19.471434 },
    { lat: 48.677568, lng: 19.471637 },
    { lat: 48.677241, lng: 19.471785 },
    { lat: 48.6769, lng: 19.472159 },
    { lat: 48.676291, lng: 19.47231 },
    { lat: 48.675829, lng: 19.472832 },
    { lat: 48.675778, lng: 19.472975 },
    { lat: 48.675719, lng: 19.473259 },
    { lat: 48.675388, lng: 19.474167 },
    { lat: 48.67513, lng: 19.475427 },
    { lat: 48.674828, lng: 19.476461 },
    { lat: 48.674689, lng: 19.477057 },
    { lat: 48.674543, lng: 19.477573 },
    { lat: 48.674422, lng: 19.47842 },
    { lat: 48.674441, lng: 19.478969 },
    { lat: 48.674457, lng: 19.479583 },
    { lat: 48.674083, lng: 19.48027 },
    { lat: 48.673762, lng: 19.480685 },
    { lat: 48.6742693, lng: 19.4813871 },
    { lat: 48.674393, lng: 19.4818946 },
    { lat: 48.6750135, lng: 19.4836101 },
    { lat: 48.6751616, lng: 19.4842777 },
    { lat: 48.6753537, lng: 19.48553 },
    { lat: 48.6758713, lng: 19.4865586 },
    { lat: 48.6760004, lng: 19.4870353 },
    { lat: 48.6765147, lng: 19.4880907 },
    { lat: 48.6770568, lng: 19.4901348 },
    { lat: 48.6770594, lng: 19.4911914 },
    { lat: 48.676986, lng: 19.4924059 },
    { lat: 48.6772372, lng: 19.4937686 },
    { lat: 48.6779925, lng: 19.4956763 },
    { lat: 48.6782188, lng: 19.4964476 },
    { lat: 48.6786264, lng: 19.4967595 },
    { lat: 48.6795529, lng: 19.4977134 },
    { lat: 48.679989, lng: 19.4982227 },
    { lat: 48.6806551, lng: 19.4992487 },
    { lat: 48.6811082, lng: 19.499717 },
    { lat: 48.6816526, lng: 19.5001796 },
    { lat: 48.6824208, lng: 19.5007109 },
    { lat: 48.6829468, lng: 19.5018534 },
    { lat: 48.6833087, lng: 19.5030022 },
    { lat: 48.6835137, lng: 19.5031971 },
    { lat: 48.6842276, lng: 19.5032071 },
    { lat: 48.6849065, lng: 19.5034634 },
    { lat: 48.685563, lng: 19.5035515 },
    { lat: 48.6859573, lng: 19.5037429 },
    { lat: 48.6862605, lng: 19.5049864 },
    { lat: 48.686422, lng: 19.5054565 },
    { lat: 48.686414, lng: 19.5061439 },
    { lat: 48.6866004, lng: 19.5073744 },
    { lat: 48.6867288, lng: 19.5116763 },
    { lat: 48.6868112, lng: 19.5125304 },
    { lat: 48.6879681, lng: 19.5151411 },
    { lat: 48.688477, lng: 19.5169925 },
    { lat: 48.6891506, lng: 19.5191156 },
    { lat: 48.6898889, lng: 19.5201241 },
    { lat: 48.6905381, lng: 19.520566 },
    { lat: 48.6912656, lng: 19.5215098 },
    { lat: 48.6914066, lng: 19.5218679 },
    { lat: 48.6915538, lng: 19.5222417 },
    { lat: 48.6925122, lng: 19.5237231 },
    { lat: 48.6929207, lng: 19.5240526 },
    { lat: 48.6940145, lng: 19.524658 },
    { lat: 48.6942807, lng: 19.5250043 },
    { lat: 48.6946998, lng: 19.5248069 },
    { lat: 48.6952715, lng: 19.5255981 },
    { lat: 48.6955988, lng: 19.5248279 },
    { lat: 48.6957211, lng: 19.5246438 },
    { lat: 48.6960547, lng: 19.5245685 },
    { lat: 48.6964626, lng: 19.5242004 },
    { lat: 48.6967249, lng: 19.5231687 },
    { lat: 48.6969001, lng: 19.523008 },
    { lat: 48.6972259, lng: 19.5230826 },
    { lat: 48.6976748, lng: 19.52292 },
    { lat: 48.6978117, lng: 19.52281 },
    { lat: 48.6986097, lng: 19.5226688 },
    { lat: 48.6992826, lng: 19.5229378 },
    { lat: 48.6997078, lng: 19.5233887 },
    { lat: 48.7004595, lng: 19.5239552 },
    { lat: 48.7011991, lng: 19.5246489 },
    { lat: 48.7014206, lng: 19.5250034 },
    { lat: 48.7020497, lng: 19.5255478 },
    { lat: 48.7028544, lng: 19.5265341 },
    { lat: 48.7036551, lng: 19.5269895 },
    { lat: 48.7036902, lng: 19.5270658 },
    { lat: 48.7037892, lng: 19.5269637 },
    { lat: 48.7038633, lng: 19.5267629 },
    { lat: 48.7048491, lng: 19.527711 },
    { lat: 48.704959, lng: 19.5279245 },
    { lat: 48.7055813, lng: 19.5285429 },
    { lat: 48.705885, lng: 19.5285018 },
    { lat: 48.7062052, lng: 19.5294383 },
    { lat: 48.7066348, lng: 19.5314098 },
    { lat: 48.7067451, lng: 19.531724 },
    { lat: 48.7071051, lng: 19.5323756 },
    { lat: 48.7075043, lng: 19.5329492 },
    { lat: 48.7079415, lng: 19.5331671 },
    { lat: 48.7082329, lng: 19.5334052 },
    { lat: 48.7087321, lng: 19.5339459 },
    { lat: 48.709461, lng: 19.5353403 },
    { lat: 48.7098371, lng: 19.53574 },
    { lat: 48.7099632, lng: 19.535874 },
    { lat: 48.7113115, lng: 19.5363255 },
    { lat: 48.7115642, lng: 19.5363326 },
    { lat: 48.7118531, lng: 19.5361696 },
    { lat: 48.7122071, lng: 19.5357058 },
    { lat: 48.7126072, lng: 19.5355843 },
    { lat: 48.7134477, lng: 19.5355299 },
    { lat: 48.7136866, lng: 19.5357539 },
    { lat: 48.7139427, lng: 19.5363244 },
    { lat: 48.7143478, lng: 19.536975 },
    { lat: 48.7147502, lng: 19.5374453 },
    { lat: 48.7150045, lng: 19.5376085 },
    { lat: 48.7152086, lng: 19.5376371 },
    { lat: 48.7159996, lng: 19.5374141 },
    { lat: 48.7168309, lng: 19.5370131 },
    { lat: 48.7171273, lng: 19.5369892 },
    { lat: 48.7173191, lng: 19.5370676 },
    { lat: 48.717865, lng: 19.5374733 },
    { lat: 48.7183725, lng: 19.5380928 },
    { lat: 48.7188223, lng: 19.5391524 },
    { lat: 48.7190613, lng: 19.5395035 },
    { lat: 48.7192367, lng: 19.5396415 },
    { lat: 48.7198042, lng: 19.5398027 },
    { lat: 48.7197655, lng: 19.5400679 },
    { lat: 48.7202816, lng: 19.5402598 },
    { lat: 48.7206685, lng: 19.5400263 },
    { lat: 48.7212134, lng: 19.5403333 },
    { lat: 48.7213644, lng: 19.5404792 },
    { lat: 48.7215849, lng: 19.5404472 },
    { lat: 48.7217144, lng: 19.5405425 },
    { lat: 48.7220532, lng: 19.540511 },
    { lat: 48.7224054, lng: 19.5404249 },
    { lat: 48.7229716, lng: 19.539997 },
    { lat: 48.7238019, lng: 19.5395232 },
    { lat: 48.7240909, lng: 19.5394594 },
    { lat: 48.7247173, lng: 19.5394799 },
    { lat: 48.7249372, lng: 19.5393623 },
    { lat: 48.725616, lng: 19.5397867 },
    { lat: 48.7259309, lng: 19.5398258 },
    { lat: 48.727171, lng: 19.5394883 },
    { lat: 48.7289174, lng: 19.5399316 },
    { lat: 48.7295816, lng: 19.540455 },
    { lat: 48.7314257, lng: 19.5411337 },
    { lat: 48.7321553, lng: 19.5412666 },
    { lat: 48.7333796, lng: 19.5413269 },
    { lat: 48.7340824, lng: 19.5416882 },
    { lat: 48.7349728, lng: 19.54324 },
    { lat: 48.7351584, lng: 19.5444165 },
    { lat: 48.7351843, lng: 19.5454005 },
    { lat: 48.7350138, lng: 19.5458856 },
    { lat: 48.7348924, lng: 19.5465101 },
    { lat: 48.7352833, lng: 19.5475145 },
    { lat: 48.7353006, lng: 19.5481664 },
    { lat: 48.7354648, lng: 19.5488517 },
    { lat: 48.7357678, lng: 19.549564 },
    { lat: 48.7361105, lng: 19.549912 },
    { lat: 48.7364342, lng: 19.5499685 },
    { lat: 48.7367259, lng: 19.5501611 },
    { lat: 48.7370397, lng: 19.5516024 },
    { lat: 48.7372859, lng: 19.5521325 },
    { lat: 48.7374173, lng: 19.5532569 },
    { lat: 48.7377017, lng: 19.5536377 },
    { lat: 48.737978, lng: 19.5538388 },
    { lat: 48.7388813, lng: 19.5542765 },
    { lat: 48.7392363, lng: 19.5543247 },
    { lat: 48.7398447, lng: 19.5550877 },
    { lat: 48.7398876, lng: 19.5558765 },
    { lat: 48.7401853, lng: 19.556744 },
    { lat: 48.7415353, lng: 19.5594162 },
    { lat: 48.741944, lng: 19.5603482 },
    { lat: 48.742607, lng: 19.5607764 },
    { lat: 48.7432812, lng: 19.560488 },
    { lat: 48.7436913, lng: 19.5604863 },
    { lat: 48.7440696, lng: 19.5609214 },
    { lat: 48.7444057, lng: 19.5615704 },
    { lat: 48.7449475, lng: 19.5630242 },
    { lat: 48.7458683, lng: 19.5632039 },
    { lat: 48.7461164, lng: 19.5633316 },
    { lat: 48.7464377, lng: 19.5647953 },
    { lat: 48.7468433, lng: 19.5655574 },
    { lat: 48.7477614, lng: 19.5669147 },
    { lat: 48.7480058, lng: 19.5670082 },
    { lat: 48.7486498, lng: 19.5674663 },
    { lat: 48.7490885, lng: 19.5673267 },
    { lat: 48.7492319, lng: 19.5672354 },
    { lat: 48.7494426, lng: 19.5669153 },
    { lat: 48.7500498, lng: 19.5664407 },
    { lat: 48.7503777, lng: 19.5663655 },
    { lat: 48.7511995, lng: 19.5652122 },
    { lat: 48.7514229, lng: 19.5651193 },
    { lat: 48.7520453, lng: 19.5653976 },
    { lat: 48.7521275, lng: 19.5656676 },
    { lat: 48.7527542, lng: 19.5660683 },
    { lat: 48.7530343, lng: 19.5659595 },
    { lat: 48.7534855, lng: 19.5662639 },
    { lat: 48.7548992, lng: 19.5656634 },
    { lat: 48.7552858, lng: 19.5656128 },
    { lat: 48.7556427, lng: 19.5663002 },
    { lat: 48.7570894, lng: 19.5671848 },
    { lat: 48.7610929, lng: 19.567086 },
    { lat: 48.7616289, lng: 19.5669512 },
    { lat: 48.761787, lng: 19.5670112 },
    { lat: 48.7629262, lng: 19.5680262 },
    { lat: 48.7640667, lng: 19.5686736 },
    { lat: 48.7646658, lng: 19.5688626 },
    { lat: 48.765809, lng: 19.5705088 },
    { lat: 48.7661989, lng: 19.5715237 },
    { lat: 48.7662452, lng: 19.5719236 },
    { lat: 48.7662017, lng: 19.5732976 },
    { lat: 48.766309, lng: 19.574293 },
    { lat: 48.7665437, lng: 19.5747701 },
    { lat: 48.7667253, lng: 19.5749998 },
    { lat: 48.7670496, lng: 19.5752658 },
    { lat: 48.7675887, lng: 19.5755476 },
    { lat: 48.7678165, lng: 19.5760515 },
    { lat: 48.7686865, lng: 19.5773166 },
    { lat: 48.7692504, lng: 19.5784457 },
    { lat: 48.7701203, lng: 19.5798081 },
    { lat: 48.7703346, lng: 19.5800855 },
    { lat: 48.7712398, lng: 19.5809363 },
    { lat: 48.7721627, lng: 19.5819081 },
    { lat: 48.7727616, lng: 19.5830652 },
    { lat: 48.7730679, lng: 19.583481 },
    { lat: 48.7733026, lng: 19.583612 },
    { lat: 48.7736683, lng: 19.5836737 },
    { lat: 48.7756051, lng: 19.5849362 },
    { lat: 48.7763216, lng: 19.5849075 },
    { lat: 48.776732, lng: 19.5846155 },
    { lat: 48.777105, lng: 19.584739 },
    { lat: 48.7774092, lng: 19.584965 },
    { lat: 48.7778553, lng: 19.5851116 },
    { lat: 48.7784635, lng: 19.5840127 },
    { lat: 48.7789426, lng: 19.583472 },
    { lat: 48.7791104, lng: 19.5829447 },
    { lat: 48.77947, lng: 19.5822786 },
    { lat: 48.7797495, lng: 19.5831513 },
    { lat: 48.7797918, lng: 19.5841302 },
    { lat: 48.7797168, lng: 19.5849861 },
    { lat: 48.7795512, lng: 19.5858051 },
    { lat: 48.7795776, lng: 19.5862422 },
    { lat: 48.7801084, lng: 19.5865383 },
    { lat: 48.7802786, lng: 19.5867142 },
    { lat: 48.7802695, lng: 19.5870092 },
    { lat: 48.7807604, lng: 19.5878218 },
    { lat: 48.780911, lng: 19.5882643 },
    { lat: 48.7810581, lng: 19.58892 },
    { lat: 48.7812249, lng: 19.5888234 },
    { lat: 48.7819334, lng: 19.5862062 },
    { lat: 48.7826625, lng: 19.5839717 },
    { lat: 48.7841181, lng: 19.5821532 },
    { lat: 48.7842537, lng: 19.5816664 },
    { lat: 48.7848039, lng: 19.5815728 },
    { lat: 48.7840067, lng: 19.5825543 },
    { lat: 48.7835151, lng: 19.5835912 },
    { lat: 48.7831848, lng: 19.5849286 },
    { lat: 48.7828927, lng: 19.5871839 },
    { lat: 48.7829693, lng: 19.5872335 },
    { lat: 48.7831409, lng: 19.586868 },
    { lat: 48.7835189, lng: 19.5874019 },
    { lat: 48.7839127, lng: 19.5876158 },
    { lat: 48.7852296, lng: 19.5850696 },
    { lat: 48.7856436, lng: 19.5846583 },
    { lat: 48.7857971, lng: 19.584581 },
    { lat: 48.7882649, lng: 19.585116 },
    { lat: 48.7888749, lng: 19.5890982 },
    { lat: 48.7900552, lng: 19.5901553 },
    { lat: 48.7957828, lng: 19.5876681 },
    { lat: 48.7982813, lng: 19.5864372 },
    { lat: 48.7981577, lng: 19.585326 },
    { lat: 48.7983265, lng: 19.5847985 },
    { lat: 48.79842, lng: 19.5840532 },
    { lat: 48.7986673, lng: 19.5836189 },
    { lat: 48.7983502, lng: 19.583109 },
    { lat: 48.7997677, lng: 19.5817457 },
    { lat: 48.7999483, lng: 19.5813016 },
    { lat: 48.8031081, lng: 19.5776352 },
    { lat: 48.8046111, lng: 19.5729412 },
    { lat: 48.8043393, lng: 19.5727586 },
    { lat: 48.8043149, lng: 19.5726474 },
    { lat: 48.8043827, lng: 19.5726497 },
    { lat: 48.8044019, lng: 19.5725004 },
    { lat: 48.8044177, lng: 19.5717624 },
    { lat: 48.8043649, lng: 19.5713632 },
    { lat: 48.804269, lng: 19.5719351 },
    { lat: 48.8040708, lng: 19.5721099 },
    { lat: 48.8036408, lng: 19.5722447 },
    { lat: 48.8034038, lng: 19.5723867 },
    { lat: 48.8033659, lng: 19.5723416 },
    { lat: 48.803568, lng: 19.5719828 },
    { lat: 48.8036142, lng: 19.5718089 },
    { lat: 48.8039957, lng: 19.5713506 },
    { lat: 48.8043087, lng: 19.5707771 },
    { lat: 48.8048387, lng: 19.5697149 },
    { lat: 48.8051455, lng: 19.5687811 },
    { lat: 48.8051837, lng: 19.5684597 },
    { lat: 48.8051483, lng: 19.5674825 },
    { lat: 48.804575, lng: 19.5648101 },
    { lat: 48.8044692, lng: 19.5640937 },
    { lat: 48.8044941, lng: 19.5631824 },
    { lat: 48.8047325, lng: 19.5617345 },
    { lat: 48.8046965, lng: 19.5612649 },
    { lat: 48.8047987, lng: 19.5607145 },
    { lat: 48.8048086, lng: 19.5606623 },
  ],
  Ráztoka: [
    { lat: 48.853513, lng: 19.403018 },
    { lat: 48.853045, lng: 19.402585 },
    { lat: 48.852724, lng: 19.402288 },
    { lat: 48.851849, lng: 19.402182 },
    { lat: 48.851154, lng: 19.402392 },
    { lat: 48.850463, lng: 19.402609 },
    { lat: 48.849899, lng: 19.402687 },
    { lat: 48.849834, lng: 19.402624 },
    { lat: 48.849669, lng: 19.402426 },
    { lat: 48.848461, lng: 19.400983 },
    { lat: 48.847148, lng: 19.399493 },
    { lat: 48.846717, lng: 19.399024 },
    { lat: 48.846263, lng: 19.398496 },
    { lat: 48.845403, lng: 19.397502 },
    { lat: 48.844883, lng: 19.396892 },
    { lat: 48.844582, lng: 19.396546 },
    { lat: 48.843461, lng: 19.395215 },
    { lat: 48.842909, lng: 19.394591 },
    { lat: 48.842639, lng: 19.394277 },
    { lat: 48.841709, lng: 19.393371 },
    { lat: 48.841515, lng: 19.392991 },
    { lat: 48.841399, lng: 19.392881 },
    { lat: 48.841024, lng: 19.392441 },
    { lat: 48.840612, lng: 19.391955 },
    { lat: 48.840552, lng: 19.391897 },
    { lat: 48.840414, lng: 19.39172 },
    { lat: 48.84004, lng: 19.39142 },
    { lat: 48.838888, lng: 19.390042 },
    { lat: 48.838326, lng: 19.389399 },
    { lat: 48.83726, lng: 19.38807 },
    { lat: 48.837223, lng: 19.388015 },
    { lat: 48.83673, lng: 19.387453 },
    { lat: 48.836718, lng: 19.387438 },
    { lat: 48.836657, lng: 19.387365 },
    { lat: 48.836453, lng: 19.387118 },
    { lat: 48.836247, lng: 19.386881 },
    { lat: 48.835823, lng: 19.386398 },
    { lat: 48.83572, lng: 19.38627 },
    { lat: 48.835651, lng: 19.386189 },
    { lat: 48.8353, lng: 19.385806 },
    { lat: 48.834751, lng: 19.38515 },
    { lat: 48.834715, lng: 19.385109 },
    { lat: 48.834673, lng: 19.38506 },
    { lat: 48.833124, lng: 19.383271 },
    { lat: 48.832238, lng: 19.382268 },
    { lat: 48.83198, lng: 19.381964 },
    { lat: 48.830894, lng: 19.382194 },
    { lat: 48.828451, lng: 19.382681 },
    { lat: 48.827555, lng: 19.382889 },
    { lat: 48.825867, lng: 19.383225 },
    { lat: 48.824967, lng: 19.383355 },
    { lat: 48.824022, lng: 19.383499 },
    { lat: 48.823862, lng: 19.38408 },
    { lat: 48.823126, lng: 19.384069 },
    { lat: 48.822215, lng: 19.384061 },
    { lat: 48.820958, lng: 19.384043 },
    { lat: 48.819367, lng: 19.384021 },
    { lat: 48.819251, lng: 19.384019 },
    { lat: 48.818735, lng: 19.384011 },
    { lat: 48.818636, lng: 19.38401 },
    { lat: 48.817512, lng: 19.383992 },
    { lat: 48.816213, lng: 19.384905 },
    { lat: 48.815694, lng: 19.385574 },
    { lat: 48.815207, lng: 19.386324 },
    { lat: 48.814681, lng: 19.387066 },
    { lat: 48.813674, lng: 19.388459 },
    { lat: 48.813274, lng: 19.389013 },
    { lat: 48.813171, lng: 19.38917 },
    { lat: 48.812667, lng: 19.389949 },
    { lat: 48.810881, lng: 19.392774 },
    { lat: 48.810179, lng: 19.392785 },
    { lat: 48.809373, lng: 19.392793 },
    { lat: 48.808443, lng: 19.392811 },
    { lat: 48.808312, lng: 19.39293 },
    { lat: 48.808241, lng: 19.392994 },
    { lat: 48.808064, lng: 19.393155 },
    { lat: 48.807914, lng: 19.393292 },
    { lat: 48.806932, lng: 19.394183 },
    { lat: 48.804635, lng: 19.393739 },
    { lat: 48.804536, lng: 19.39372 },
    { lat: 48.804521, lng: 19.393689 },
    { lat: 48.804506, lng: 19.393662 },
    { lat: 48.804502, lng: 19.393667 },
    { lat: 48.804452, lng: 19.393725 },
    { lat: 48.804446, lng: 19.393732 },
    { lat: 48.804423, lng: 19.39376 },
    { lat: 48.804416, lng: 19.393767 },
    { lat: 48.804338, lng: 19.393857 },
    { lat: 48.804333, lng: 19.393864 },
    { lat: 48.804323, lng: 19.393878 },
    { lat: 48.80423, lng: 19.393914 },
    { lat: 48.804146, lng: 19.393917 },
    { lat: 48.804025, lng: 19.393831 },
    { lat: 48.803975, lng: 19.393777 },
    { lat: 48.803887, lng: 19.393655 },
    { lat: 48.803892, lng: 19.39383 },
    { lat: 48.803895, lng: 19.393891 },
    { lat: 48.803895, lng: 19.3938912 },
    { lat: 48.80375, lng: 19.39394 },
    { lat: 48.8043842, lng: 19.3958472 },
    { lat: 48.8046617, lng: 19.3964995 },
    { lat: 48.8047801, lng: 19.3969304 },
    { lat: 48.8052422, lng: 19.3980591 },
    { lat: 48.8056746, lng: 19.3987476 },
    { lat: 48.8068877, lng: 19.3998642 },
    { lat: 48.8079254, lng: 19.4014895 },
    { lat: 48.8080513, lng: 19.4021796 },
    { lat: 48.8081442, lng: 19.4035604 },
    { lat: 48.8083031, lng: 19.4042298 },
    { lat: 48.8085573, lng: 19.4046291 },
    { lat: 48.8087077, lng: 19.4043 },
    { lat: 48.8092445, lng: 19.4037575 },
    { lat: 48.8093339, lng: 19.4037904 },
    { lat: 48.809461, lng: 19.4037103 },
    { lat: 48.8096936, lng: 19.4033892 },
    { lat: 48.8098552, lng: 19.4029617 },
    { lat: 48.8107389, lng: 19.4023679 },
    { lat: 48.8114844, lng: 19.4027833 },
    { lat: 48.8121545, lng: 19.4035476 },
    { lat: 48.8124697, lng: 19.404669 },
    { lat: 48.812616, lng: 19.4049719 },
    { lat: 48.8130256, lng: 19.4055379 },
    { lat: 48.8135024, lng: 19.4056096 },
    { lat: 48.8136623, lng: 19.4057297 },
    { lat: 48.8139242, lng: 19.4061943 },
    { lat: 48.8143009, lng: 19.4066573 },
    { lat: 48.8144995, lng: 19.4070451 },
    { lat: 48.8146707, lng: 19.4071415 },
    { lat: 48.8147149, lng: 19.4073645 },
    { lat: 48.8148489, lng: 19.4074813 },
    { lat: 48.8150293, lng: 19.4075066 },
    { lat: 48.8151468, lng: 19.4077762 },
    { lat: 48.8154285, lng: 19.4081138 },
    { lat: 48.8153391, lng: 19.4080798 },
    { lat: 48.8156196, lng: 19.4084166 },
    { lat: 48.8158472, lng: 19.4085616 },
    { lat: 48.816158, lng: 19.408628 },
    { lat: 48.8163546, lng: 19.4084224 },
    { lat: 48.8165463, lng: 19.408351 },
    { lat: 48.8166498, lng: 19.4085129 },
    { lat: 48.8167289, lng: 19.40811 },
    { lat: 48.81698, lng: 19.4081073 },
    { lat: 48.8173239, lng: 19.4079928 },
    { lat: 48.8178288, lng: 19.4079908 },
    { lat: 48.818873, lng: 19.4089886 },
    { lat: 48.8197546, lng: 19.4096681 },
    { lat: 48.8209475, lng: 19.4107381 },
    { lat: 48.8212949, lng: 19.4112032 },
    { lat: 48.8217258, lng: 19.4115482 },
    { lat: 48.8222145, lng: 19.4122879 },
    { lat: 48.8225071, lng: 19.4129528 },
    { lat: 48.8231745, lng: 19.4133672 },
    { lat: 48.8234007, lng: 19.4136938 },
    { lat: 48.8237391, lng: 19.4140383 },
    { lat: 48.8243014, lng: 19.414881 },
    { lat: 48.8243949, lng: 19.4151686 },
    { lat: 48.8248034, lng: 19.4159491 },
    { lat: 48.824829, lng: 19.4162583 },
    { lat: 48.8251621, lng: 19.4167304 },
    { lat: 48.8254129, lng: 19.4166709 },
    { lat: 48.8259576, lng: 19.4167491 },
    { lat: 48.8289068, lng: 19.4156661 },
    { lat: 48.8289246, lng: 19.4156116 },
    { lat: 48.8308337, lng: 19.4151528 },
    { lat: 48.8315185, lng: 19.4147838 },
    { lat: 48.8322912, lng: 19.4136932 },
    { lat: 48.8330386, lng: 19.4129497 },
    { lat: 48.8336549, lng: 19.412753 },
    { lat: 48.8342919, lng: 19.4126373 },
    { lat: 48.8360087, lng: 19.4125513 },
    { lat: 48.836563, lng: 19.4125973 },
    { lat: 48.8368777, lng: 19.4126505 },
    { lat: 48.8388092, lng: 19.4133728 },
    { lat: 48.8391871, lng: 19.4134235 },
    { lat: 48.8397031, lng: 19.4131817 },
    { lat: 48.8401335, lng: 19.4127948 },
    { lat: 48.8405445, lng: 19.4127382 },
    { lat: 48.8406279, lng: 19.4125961 },
    { lat: 48.8408471, lng: 19.4126087 },
    { lat: 48.8411427, lng: 19.4124703 },
    { lat: 48.8412288, lng: 19.4126507 },
    { lat: 48.8418761, lng: 19.4127769 },
    { lat: 48.8422058, lng: 19.4133222 },
    { lat: 48.8424455, lng: 19.4132671 },
    { lat: 48.8425177, lng: 19.4135073 },
    { lat: 48.8431822, lng: 19.413788 },
    { lat: 48.8434394, lng: 19.4135525 },
    { lat: 48.8437295, lng: 19.4129138 },
    { lat: 48.8442633, lng: 19.4134812 },
    { lat: 48.8449749, lng: 19.4139704 },
    { lat: 48.8453142, lng: 19.4141225 },
    { lat: 48.8461047, lng: 19.4143718 },
    { lat: 48.8480595, lng: 19.4148182 },
    { lat: 48.8481312, lng: 19.4144022 },
    { lat: 48.8483903, lng: 19.4140842 },
    { lat: 48.8486318, lng: 19.4139727 },
    { lat: 48.8491959, lng: 19.4128551 },
    { lat: 48.8494027, lng: 19.4120714 },
    { lat: 48.8495019, lng: 19.4114918 },
    { lat: 48.8494479, lng: 19.4090425 },
    { lat: 48.849901, lng: 19.4068961 },
    { lat: 48.8501941, lng: 19.4061053 },
    { lat: 48.8506338, lng: 19.4053899 },
    { lat: 48.8516676, lng: 19.4042892 },
    { lat: 48.8533092, lng: 19.4032233 },
    { lat: 48.853513, lng: 19.403018 },
  ],
  ČiernyBalog: [
    { lat: 48.7686754, lng: 19.664498 },
    { lat: 48.7683394, lng: 19.6640373 },
    { lat: 48.7684277, lng: 19.6636679 },
    { lat: 48.768294, lng: 19.6631701 },
    { lat: 48.7684771, lng: 19.6629077 },
    { lat: 48.7686111, lng: 19.6625515 },
    { lat: 48.7684153, lng: 19.6624551 },
    { lat: 48.7681948, lng: 19.6626405 },
    { lat: 48.7678937, lng: 19.6624602 },
    { lat: 48.7681205, lng: 19.6623145 },
    { lat: 48.7683455, lng: 19.6620822 },
    { lat: 48.7685607, lng: 19.6617258 },
    { lat: 48.7686187, lng: 19.6617388 },
    { lat: 48.768851, lng: 19.6600531 },
    { lat: 48.7691061, lng: 19.6574217 },
    { lat: 48.7690548, lng: 19.6564511 },
    { lat: 48.7694318, lng: 19.6556631 },
    { lat: 48.7694122, lng: 19.6553472 },
    { lat: 48.7700481, lng: 19.6549313 },
    { lat: 48.7700595, lng: 19.6544522 },
    { lat: 48.7690564, lng: 19.6545228 },
    { lat: 48.7686429, lng: 19.6548492 },
    { lat: 48.7684266, lng: 19.6544481 },
    { lat: 48.7680733, lng: 19.6543339 },
    { lat: 48.7672873, lng: 19.6542194 },
    { lat: 48.7673417, lng: 19.6529183 },
    { lat: 48.7676224, lng: 19.6529699 },
    { lat: 48.7676951, lng: 19.6524056 },
    { lat: 48.7677809, lng: 19.6524076 },
    { lat: 48.7679788, lng: 19.6517976 },
    { lat: 48.7676537, lng: 19.6516073 },
    { lat: 48.7677775, lng: 19.6507727 },
    { lat: 48.7674704, lng: 19.6508575 },
    { lat: 48.7673293, lng: 19.650828 },
    { lat: 48.7670525, lng: 19.6509371 },
    { lat: 48.7667808, lng: 19.6497005 },
    { lat: 48.7666933, lng: 19.6494859 },
    { lat: 48.766779, lng: 19.6493864 },
    { lat: 48.7666387, lng: 19.6492222 },
    { lat: 48.767066, lng: 19.6484458 },
    { lat: 48.7671526, lng: 19.6483723 },
    { lat: 48.76748, lng: 19.6477058 },
    { lat: 48.7676392, lng: 19.6475387 },
    { lat: 48.767582, lng: 19.6473682 },
    { lat: 48.7672826, lng: 19.6468935 },
    { lat: 48.7671815, lng: 19.6469303 },
    { lat: 48.7672218, lng: 19.6466965 },
    { lat: 48.7671852, lng: 19.6461798 },
    { lat: 48.767114, lng: 19.645646 },
    { lat: 48.7669635, lng: 19.6452068 },
    { lat: 48.7671039, lng: 19.645247 },
    { lat: 48.7672205, lng: 19.6450579 },
    { lat: 48.7672231, lng: 19.6449401 },
    { lat: 48.7667878, lng: 19.6441272 },
    { lat: 48.7666815, lng: 19.6440681 },
    { lat: 48.7665767, lng: 19.6438953 },
    { lat: 48.7659469, lng: 19.642488 },
    { lat: 48.7658225, lng: 19.6419994 },
    { lat: 48.7665201, lng: 19.6415545 },
    { lat: 48.766418, lng: 19.6411367 },
    { lat: 48.7668141, lng: 19.6409714 },
    { lat: 48.7669635, lng: 19.6412918 },
    { lat: 48.7679814, lng: 19.6407254 },
    { lat: 48.7681982, lng: 19.6406838 },
    { lat: 48.7683356, lng: 19.640404 },
    { lat: 48.7686499, lng: 19.6394005 },
    { lat: 48.7689267, lng: 19.6399432 },
    { lat: 48.7692816, lng: 19.6404189 },
    { lat: 48.7694474, lng: 19.6399979 },
    { lat: 48.7695247, lng: 19.639611 },
    { lat: 48.7693791, lng: 19.6384515 },
    { lat: 48.7695225, lng: 19.6384064 },
    { lat: 48.7696102, lng: 19.6367457 },
    { lat: 48.7696844, lng: 19.6364441 },
    { lat: 48.7696847, lng: 19.636024 },
    { lat: 48.770263, lng: 19.6357052 },
    { lat: 48.7705022, lng: 19.6350347 },
    { lat: 48.7710086, lng: 19.6345129 },
    { lat: 48.7714853, lng: 19.6340338 },
    { lat: 48.7712781, lng: 19.6358748 },
    { lat: 48.7720823, lng: 19.637047 },
    { lat: 48.7722263, lng: 19.6367926 },
    { lat: 48.7726981, lng: 19.6375896 },
    { lat: 48.7742373, lng: 19.6364851 },
    { lat: 48.7740547, lng: 19.6362359 },
    { lat: 48.7743134, lng: 19.6359446 },
    { lat: 48.7744446, lng: 19.6362323 },
    { lat: 48.7752898, lng: 19.6347789 },
    { lat: 48.7755747, lng: 19.6337272 },
    { lat: 48.7744457, lng: 19.6328002 },
    { lat: 48.7746024, lng: 19.6323247 },
    { lat: 48.7735869, lng: 19.6312378 },
    { lat: 48.7734598, lng: 19.6314202 },
    { lat: 48.772921, lng: 19.6309645 },
    { lat: 48.7723962, lng: 19.6306706 },
    { lat: 48.7720634, lng: 19.6305848 },
    { lat: 48.7718952, lng: 19.6306564 },
    { lat: 48.7720609, lng: 19.6302304 },
    { lat: 48.7723105, lng: 19.6300682 },
    { lat: 48.7724228, lng: 19.6298566 },
    { lat: 48.7724844, lng: 19.6295255 },
    { lat: 48.7720638, lng: 19.6296914 },
    { lat: 48.7721546, lng: 19.6287072 },
    { lat: 48.77227, lng: 19.6284163 },
    { lat: 48.772345, lng: 19.6278799 },
    { lat: 48.771946, lng: 19.6278285 },
    { lat: 48.7721033, lng: 19.6264902 },
    { lat: 48.7725013, lng: 19.6266675 },
    { lat: 48.7728394, lng: 19.6253195 },
    { lat: 48.7729913, lng: 19.6254892 },
    { lat: 48.7736771, lng: 19.6232513 },
    { lat: 48.7743652, lng: 19.6213467 },
    { lat: 48.7744582, lng: 19.6208051 },
    { lat: 48.7741879, lng: 19.6205126 },
    { lat: 48.7739107, lng: 19.6203611 },
    { lat: 48.7736736, lng: 19.6213976 },
    { lat: 48.7732393, lng: 19.6205886 },
    { lat: 48.7734678, lng: 19.6193819 },
    { lat: 48.7736518, lng: 19.618043 },
    { lat: 48.7738616, lng: 19.618171 },
    { lat: 48.7743299, lng: 19.6167584 },
    { lat: 48.7745007, lng: 19.6158502 },
    { lat: 48.7743814, lng: 19.6157282 },
    { lat: 48.7749417, lng: 19.6147122 },
    { lat: 48.7746149, lng: 19.6142101 },
    { lat: 48.7748933, lng: 19.613613 },
    { lat: 48.7745193, lng: 19.613164 },
    { lat: 48.7745708, lng: 19.6130065 },
    { lat: 48.7743482, lng: 19.6119452 },
    { lat: 48.7739449, lng: 19.6117004 },
    { lat: 48.7739224, lng: 19.6113483 },
    { lat: 48.7738404, lng: 19.61108 },
    { lat: 48.7734436, lng: 19.6104765 },
    { lat: 48.7735777, lng: 19.6093556 },
    { lat: 48.7735211, lng: 19.6090224 },
    { lat: 48.7732704, lng: 19.6085896 },
    { lat: 48.7730383, lng: 19.6079149 },
    { lat: 48.7734765, lng: 19.6074131 },
    { lat: 48.7737494, lng: 19.607417 },
    { lat: 48.7738089, lng: 19.6077634 },
    { lat: 48.7734893, lng: 19.6083198 },
    { lat: 48.7734306, lng: 19.6085449 },
    { lat: 48.7736068, lng: 19.6087513 },
    { lat: 48.7738545, lng: 19.6085989 },
    { lat: 48.7739502, lng: 19.6091442 },
    { lat: 48.774141, lng: 19.6091777 },
    { lat: 48.7742967, lng: 19.6089598 },
    { lat: 48.7748923, lng: 19.609149 },
    { lat: 48.7747466, lng: 19.6096631 },
    { lat: 48.7757068, lng: 19.609869 },
    { lat: 48.7757665, lng: 19.6090951 },
    { lat: 48.7762033, lng: 19.6091353 },
    { lat: 48.7767205, lng: 19.6090336 },
    { lat: 48.7767343, lng: 19.6093651 },
    { lat: 48.7773858, lng: 19.609022 },
    { lat: 48.7772683, lng: 19.6084179 },
    { lat: 48.7770621, lng: 19.6077415 },
    { lat: 48.7774898, lng: 19.6073092 },
    { lat: 48.7780551, lng: 19.607597 },
    { lat: 48.778236, lng: 19.6074883 },
    { lat: 48.7784964, lng: 19.6080147 },
    { lat: 48.7786664, lng: 19.60801 },
    { lat: 48.7787675, lng: 19.6083032 },
    { lat: 48.7793281, lng: 19.608117 },
    { lat: 48.7798067, lng: 19.6071869 },
    { lat: 48.7794874, lng: 19.6069033 },
    { lat: 48.7800206, lng: 19.6053623 },
    { lat: 48.779643, lng: 19.6048115 },
    { lat: 48.779526, lng: 19.6038175 },
    { lat: 48.7794256, lng: 19.6039668 },
    { lat: 48.7793421, lng: 19.6039637 },
    { lat: 48.7790302, lng: 19.6037441 },
    { lat: 48.7789, lng: 19.60358 },
    { lat: 48.7782718, lng: 19.6035928 },
    { lat: 48.778064, lng: 19.603505 },
    { lat: 48.7780611, lng: 19.6032677 },
    { lat: 48.7782011, lng: 19.602598 },
    { lat: 48.7784565, lng: 19.6023929 },
    { lat: 48.778536, lng: 19.6024805 },
    { lat: 48.7787298, lng: 19.6021553 },
    { lat: 48.7789089, lng: 19.6023308 },
    { lat: 48.7790161, lng: 19.6023618 },
    { lat: 48.7789482, lng: 19.6027504 },
    { lat: 48.7792595, lng: 19.6028114 },
    { lat: 48.7792755, lng: 19.6025375 },
    { lat: 48.7791623, lng: 19.6018569 },
    { lat: 48.7796868, lng: 19.6014449 },
    { lat: 48.7800115, lng: 19.6007518 },
    { lat: 48.780047, lng: 19.6004148 },
    { lat: 48.7802487, lng: 19.6000134 },
    { lat: 48.7805828, lng: 19.5996268 },
    { lat: 48.7809701, lng: 19.5992988 },
    { lat: 48.7816775, lng: 19.5990433 },
    { lat: 48.7819876, lng: 19.5996979 },
    { lat: 48.7818911, lng: 19.5991315 },
    { lat: 48.7815553, lng: 19.5983624 },
    { lat: 48.7812697, lng: 19.5979743 },
    { lat: 48.7813444, lng: 19.5978351 },
    { lat: 48.7819848, lng: 19.5984137 },
    { lat: 48.7819854, lng: 19.5990496 },
    { lat: 48.7820997, lng: 19.5992582 },
    { lat: 48.7821799, lng: 19.5996637 },
    { lat: 48.7827717, lng: 19.5990707 },
    { lat: 48.782746, lng: 19.5989262 },
    { lat: 48.7828617, lng: 19.598768 },
    { lat: 48.7828211, lng: 19.5984932 },
    { lat: 48.7827314, lng: 19.598351 },
    { lat: 48.7824437, lng: 19.5981203 },
    { lat: 48.7824091, lng: 19.5978045 },
    { lat: 48.7815071, lng: 19.5970472 },
    { lat: 48.7815489, lng: 19.5968753 },
    { lat: 48.7817098, lng: 19.5968465 },
    { lat: 48.7821505, lng: 19.5970456 },
    { lat: 48.7822679, lng: 19.5970013 },
    { lat: 48.7826588, lng: 19.5972955 },
    { lat: 48.7827924, lng: 19.5971856 },
    { lat: 48.7832958, lng: 19.5975543 },
    { lat: 48.783856, lng: 19.597611 },
    { lat: 48.7840291, lng: 19.5975473 },
    { lat: 48.7840047, lng: 19.5974116 },
    { lat: 48.7845744, lng: 19.5971224 },
    { lat: 48.7851612, lng: 19.5969194 },
    { lat: 48.7852254, lng: 19.5965955 },
    { lat: 48.7855684, lng: 19.5964313 },
    { lat: 48.7856063, lng: 19.5967418 },
    { lat: 48.7858617, lng: 19.5965638 },
    { lat: 48.7859796, lng: 19.5963558 },
    { lat: 48.7861635, lng: 19.5954641 },
    { lat: 48.7862776, lng: 19.5951936 },
    { lat: 48.786387, lng: 19.5946734 },
    { lat: 48.7872054, lng: 19.5934365 },
    { lat: 48.7863401, lng: 19.5932154 },
    { lat: 48.7837913, lng: 19.5920962 },
    { lat: 48.7835397, lng: 19.5917396 },
    { lat: 48.7832373, lng: 19.5915055 },
    { lat: 48.7830086, lng: 19.5919826 },
    { lat: 48.7826562, lng: 19.592235 },
    { lat: 48.7817495, lng: 19.5921679 },
    { lat: 48.7811189, lng: 19.5923791 },
    { lat: 48.7808011, lng: 19.5923777 },
    { lat: 48.7801937, lng: 19.592226 },
    { lat: 48.7799726, lng: 19.5920453 },
    { lat: 48.7798, lng: 19.5921918 },
    { lat: 48.7794884, lng: 19.5930744 },
    { lat: 48.7793973, lng: 19.5955948 },
    { lat: 48.7791102, lng: 19.5966896 },
    { lat: 48.778856, lng: 19.5971004 },
    { lat: 48.7785069, lng: 19.5973795 },
    { lat: 48.77739, lng: 19.5974498 },
    { lat: 48.776903, lng: 19.5974163 },
    { lat: 48.7760736, lng: 19.5968453 },
    { lat: 48.7753827, lng: 19.5965935 },
    { lat: 48.7751048, lng: 19.5966984 },
    { lat: 48.7749162, lng: 19.5969127 },
    { lat: 48.7747785, lng: 19.597356 },
    { lat: 48.7747445, lng: 19.5977525 },
    { lat: 48.774767, lng: 19.5982499 },
    { lat: 48.7749062, lng: 19.5988107 },
    { lat: 48.7753334, lng: 19.5999343 },
    { lat: 48.7752643, lng: 19.6007513 },
    { lat: 48.7753229, lng: 19.601374 },
    { lat: 48.7755096, lng: 19.6019156 },
    { lat: 48.7760214, lng: 19.6043841 },
    { lat: 48.7760785, lng: 19.6048733 },
    { lat: 48.7759653, lng: 19.6052623 },
    { lat: 48.7757836, lng: 19.605559 },
    { lat: 48.7751474, lng: 19.605917 },
    { lat: 48.7748599, lng: 19.6060125 },
    { lat: 48.7744121, lng: 19.6058531 },
    { lat: 48.7741623, lng: 19.6055856 },
    { lat: 48.7739089, lng: 19.6051881 },
    { lat: 48.773733, lng: 19.6046355 },
    { lat: 48.7729246, lng: 19.6041025 },
    { lat: 48.7726884, lng: 19.6041584 },
    { lat: 48.7723306, lng: 19.604498 },
    { lat: 48.7720955, lng: 19.6050774 },
    { lat: 48.7719438, lng: 19.6056611 },
    { lat: 48.7719079, lng: 19.6062628 },
    { lat: 48.7720819, lng: 19.6090154 },
    { lat: 48.7718965, lng: 19.609903 },
    { lat: 48.7716056, lng: 19.6106 },
    { lat: 48.7710618, lng: 19.6116549 },
    { lat: 48.7708896, lng: 19.6121621 },
    { lat: 48.7703885, lng: 19.6130647 },
    { lat: 48.7699007, lng: 19.6137667 },
    { lat: 48.769717, lng: 19.614165 },
    { lat: 48.7683499, lng: 19.6156085 },
    { lat: 48.7679345, lng: 19.6167383 },
    { lat: 48.7674811, lng: 19.6174073 },
    { lat: 48.7674197, lng: 19.6184445 },
    { lat: 48.7675704, lng: 19.6194351 },
    { lat: 48.7669532, lng: 19.6215937 },
    { lat: 48.7667316, lng: 19.6221983 },
    { lat: 48.7664484, lng: 19.6225422 },
    { lat: 48.7659831, lng: 19.6232692 },
    { lat: 48.7658508, lng: 19.6236082 },
    { lat: 48.7658073, lng: 19.6235694 },
    { lat: 48.7656726, lng: 19.6236558 },
    { lat: 48.7651131, lng: 19.6242925 },
    { lat: 48.7647006, lng: 19.6245389 },
    { lat: 48.7639978, lng: 19.6252496 },
    { lat: 48.763458, lng: 19.6254741 },
    { lat: 48.763054, lng: 19.6255311 },
    { lat: 48.7624247, lng: 19.625393 },
    { lat: 48.7614544, lng: 19.6235144 },
    { lat: 48.7603023, lng: 19.6214518 },
    { lat: 48.7597846, lng: 19.622313 },
    { lat: 48.7595933, lng: 19.622397 },
    { lat: 48.7593048, lng: 19.6223192 },
    { lat: 48.7588028, lng: 19.6231563 },
    { lat: 48.7585953, lng: 19.6232048 },
    { lat: 48.7582836, lng: 19.6224391 },
    { lat: 48.7576039, lng: 19.6214564 },
    { lat: 48.7560236, lng: 19.6197966 },
    { lat: 48.7554629, lng: 19.6197812 },
    { lat: 48.7551458, lng: 19.6202949 },
    { lat: 48.7542333, lng: 19.6209985 },
    { lat: 48.753918, lng: 19.6208296 },
    { lat: 48.7536459, lng: 19.6204385 },
    { lat: 48.7535487, lng: 19.6198332 },
    { lat: 48.753306, lng: 19.6194255 },
    { lat: 48.7535658, lng: 19.6185156 },
    { lat: 48.7536693, lng: 19.617659 },
    { lat: 48.753451, lng: 19.6166677 },
    { lat: 48.7528811, lng: 19.6167469 },
    { lat: 48.7526436, lng: 19.6171774 },
    { lat: 48.7525126, lng: 19.6172194 },
    { lat: 48.7521548, lng: 19.6173328 },
    { lat: 48.7518305, lng: 19.6171895 },
    { lat: 48.7513118, lng: 19.6173526 },
    { lat: 48.7508445, lng: 19.6179389 },
    { lat: 48.7506854, lng: 19.6173804 },
    { lat: 48.7507103, lng: 19.6170108 },
    { lat: 48.7506524, lng: 19.6160259 },
    { lat: 48.7507063, lng: 19.6156704 },
    { lat: 48.7502712, lng: 19.6155946 },
    { lat: 48.7499345, lng: 19.6154212 },
    { lat: 48.7494179, lng: 19.615311 },
    { lat: 48.7492128, lng: 19.6151614 },
    { lat: 48.7483322, lng: 19.6139987 },
    { lat: 48.7482722, lng: 19.6123696 },
    { lat: 48.7480527, lng: 19.6123251 },
    { lat: 48.7477967, lng: 19.6127211 },
    { lat: 48.7475621, lng: 19.6126675 },
    { lat: 48.7468592, lng: 19.614195 },
    { lat: 48.7466906, lng: 19.6143252 },
    { lat: 48.7466413, lng: 19.6140593 },
    { lat: 48.746128, lng: 19.6144193 },
    { lat: 48.745481, lng: 19.6143411 },
    { lat: 48.7452491, lng: 19.6143719 },
    { lat: 48.7448647, lng: 19.6145918 },
    { lat: 48.7446163, lng: 19.6146028 },
    { lat: 48.7445303, lng: 19.61437 },
    { lat: 48.7441791, lng: 19.6143784 },
    { lat: 48.7439298, lng: 19.6142745 },
    { lat: 48.7438198, lng: 19.613831 },
    { lat: 48.743669, lng: 19.6137638 },
    { lat: 48.743503, lng: 19.6132571 },
    { lat: 48.7433818, lng: 19.6124921 },
    { lat: 48.7431712, lng: 19.6125452 },
    { lat: 48.7430057, lng: 19.6128754 },
    { lat: 48.7427028, lng: 19.6131259 },
    { lat: 48.742494, lng: 19.6131453 },
    { lat: 48.7420139, lng: 19.612966 },
    { lat: 48.7417935, lng: 19.6123258 },
    { lat: 48.7416643, lng: 19.6120896 },
    { lat: 48.7415802, lng: 19.611697 },
    { lat: 48.7409733, lng: 19.6123551 },
    { lat: 48.7404083, lng: 19.6121453 },
    { lat: 48.7400844, lng: 19.613208 },
    { lat: 48.7396245, lng: 19.6127417 },
    { lat: 48.7395766, lng: 19.6126931 },
    { lat: 48.739136, lng: 19.6120907 },
    { lat: 48.7387754, lng: 19.6114932 },
    { lat: 48.7387401, lng: 19.6108118 },
    { lat: 48.7381424, lng: 19.6107996 },
    { lat: 48.7378888, lng: 19.6106748 },
    { lat: 48.7379545, lng: 19.6109582 },
    { lat: 48.7378552, lng: 19.611404 },
    { lat: 48.7376294, lng: 19.6120402 },
    { lat: 48.7374693, lng: 19.6120146 },
    { lat: 48.7370055, lng: 19.6115992 },
    { lat: 48.7363278, lng: 19.6112115 },
    { lat: 48.7361877, lng: 19.6118521 },
    { lat: 48.7360081, lng: 19.6124193 },
    { lat: 48.7354873, lng: 19.6121871 },
    { lat: 48.734773, lng: 19.611569 },
    { lat: 48.7338765, lng: 19.6106014 },
    { lat: 48.7335812, lng: 19.6102014 },
    { lat: 48.7332563, lng: 19.6104424 },
    { lat: 48.7333554, lng: 19.610743 },
    { lat: 48.733341, lng: 19.6110462 },
    { lat: 48.7332805, lng: 19.6112541 },
    { lat: 48.7334412, lng: 19.6115934 },
    { lat: 48.7329705, lng: 19.612096 },
    { lat: 48.7326495, lng: 19.611478 },
    { lat: 48.7323055, lng: 19.611122 },
    { lat: 48.7318599, lng: 19.6109856 },
    { lat: 48.7317904, lng: 19.6108436 },
    { lat: 48.7316022, lng: 19.6107695 },
    { lat: 48.7313966, lng: 19.6108078 },
    { lat: 48.7312465, lng: 19.6111478 },
    { lat: 48.7311366, lng: 19.611162 },
    { lat: 48.7310437, lng: 19.6115524 },
    { lat: 48.7308562, lng: 19.6119648 },
    { lat: 48.7303907, lng: 19.6118368 },
    { lat: 48.7302627, lng: 19.611622 },
    { lat: 48.7305185, lng: 19.6107761 },
    { lat: 48.7302348, lng: 19.6105273 },
    { lat: 48.7302847, lng: 19.6103623 },
    { lat: 48.7299187, lng: 19.610142 },
    { lat: 48.7298029, lng: 19.6098017 },
    { lat: 48.7298748, lng: 19.6094951 },
    { lat: 48.7301272, lng: 19.6090461 },
    { lat: 48.7303362, lng: 19.6090356 },
    { lat: 48.7297939, lng: 19.608578 },
    { lat: 48.7295854, lng: 19.6082023 },
    { lat: 48.7293169, lng: 19.608169 },
    { lat: 48.7291376, lng: 19.6083192 },
    { lat: 48.7289176, lng: 19.6087599 },
    { lat: 48.7289965, lng: 19.6088863 },
    { lat: 48.7286415, lng: 19.6092334 },
    { lat: 48.7282844, lng: 19.6093099 },
    { lat: 48.7280332, lng: 19.6082666 },
    { lat: 48.7279992, lng: 19.6078375 },
    { lat: 48.7277045, lng: 19.6071527 },
    { lat: 48.7270756, lng: 19.6073965 },
    { lat: 48.7274957, lng: 19.6082418 },
    { lat: 48.7276781, lng: 19.6087619 },
    { lat: 48.7274904, lng: 19.6088723 },
    { lat: 48.7272976, lng: 19.6083672 },
    { lat: 48.7268798, lng: 19.6081509 },
    { lat: 48.7263691, lng: 19.6070708 },
    { lat: 48.7261195, lng: 19.6067372 },
    { lat: 48.7258268, lng: 19.6066193 },
    { lat: 48.7257026, lng: 19.6063634 },
    { lat: 48.725933, lng: 19.605729 },
    { lat: 48.7259333, lng: 19.6052874 },
    { lat: 48.7253735, lng: 19.6055655 },
    { lat: 48.725206, lng: 19.6049736 },
    { lat: 48.7251, lng: 19.6047747 },
    { lat: 48.7251348, lng: 19.6046472 },
    { lat: 48.7249256, lng: 19.6040111 },
    { lat: 48.7245424, lng: 19.6041053 },
    { lat: 48.7246146, lng: 19.60356 },
    { lat: 48.7247717, lng: 19.603421 },
    { lat: 48.7247357, lng: 19.6030964 },
    { lat: 48.7246736, lng: 19.6030686 },
    { lat: 48.7247488, lng: 19.6024148 },
    { lat: 48.7242876, lng: 19.6023087 },
    { lat: 48.7242702, lng: 19.6021238 },
    { lat: 48.7239689, lng: 19.6013413 },
    { lat: 48.7236473, lng: 19.600735 },
    { lat: 48.7233334, lng: 19.6004716 },
    { lat: 48.7230323, lng: 19.5998718 },
    { lat: 48.7227274, lng: 19.5984887 },
    { lat: 48.7231734, lng: 19.5955229 },
    { lat: 48.7230272, lng: 19.594734 },
    { lat: 48.7226559, lng: 19.5943741 },
    { lat: 48.7226336, lng: 19.5941052 },
    { lat: 48.7218592, lng: 19.5932008 },
    { lat: 48.7212498, lng: 19.5917915 },
    { lat: 48.7208587, lng: 19.5906236 },
    { lat: 48.7205243, lng: 19.5899813 },
    { lat: 48.7201386, lng: 19.58898 },
    { lat: 48.7197524, lng: 19.5881917 },
    { lat: 48.7194941, lng: 19.5857055 },
    { lat: 48.7192267, lng: 19.5848047 },
    { lat: 48.7191017, lng: 19.5841361 },
    { lat: 48.7187761, lng: 19.5832431 },
    { lat: 48.7185077, lng: 19.5813789 },
    { lat: 48.7187794, lng: 19.5794968 },
    { lat: 48.7197276, lng: 19.5764885 },
    { lat: 48.7197628, lng: 19.5758496 },
    { lat: 48.7196346, lng: 19.5743716 },
    { lat: 48.7193432, lng: 19.5738838 },
    { lat: 48.7189595, lng: 19.5735982 },
    { lat: 48.7187697, lng: 19.5732668 },
    { lat: 48.7187346, lng: 19.5727933 },
    { lat: 48.7185482, lng: 19.572484 },
    { lat: 48.7177382, lng: 19.5721464 },
    { lat: 48.7172345, lng: 19.5716475 },
    { lat: 48.7165731, lng: 19.5712788 },
    { lat: 48.7160778, lng: 19.5707197 },
    { lat: 48.7149843, lng: 19.5700941 },
    { lat: 48.7143864, lng: 19.5696311 },
    { lat: 48.7140219, lng: 19.5696451 },
    { lat: 48.7137913, lng: 19.5690874 },
    { lat: 48.7134474, lng: 19.5689095 },
    { lat: 48.7128708, lng: 19.5684224 },
    { lat: 48.712736, lng: 19.5682049 },
    { lat: 48.7114165, lng: 19.566946 },
    { lat: 48.7112752, lng: 19.5668861 },
    { lat: 48.7103248, lng: 19.5652467 },
    { lat: 48.7104198, lng: 19.5650566 },
    { lat: 48.7098717, lng: 19.5638842 },
    { lat: 48.7092499, lng: 19.5629781 },
    { lat: 48.7086742, lng: 19.5618171 },
    { lat: 48.7083461, lng: 19.55959 },
    { lat: 48.7073205, lng: 19.5572921 },
    { lat: 48.7069696, lng: 19.557014 },
    { lat: 48.7063967, lng: 19.5561858 },
    { lat: 48.706369, lng: 19.5558023 },
    { lat: 48.7047494, lng: 19.5536775 },
    { lat: 48.7040193, lng: 19.5519999 },
    { lat: 48.7035424, lng: 19.5516501 },
    { lat: 48.7035142, lng: 19.5509099 },
    { lat: 48.7031176, lng: 19.5504939 },
    { lat: 48.7022969, lng: 19.5501205 },
    { lat: 48.7020881, lng: 19.5499386 },
    { lat: 48.7017703, lng: 19.5493967 },
    { lat: 48.701349, lng: 19.548415 },
    { lat: 48.7011879, lng: 19.5481657 },
    { lat: 48.7010935, lng: 19.5476833 },
    { lat: 48.7009584, lng: 19.5475379 },
    { lat: 48.7006133, lng: 19.5468184 },
    { lat: 48.6992778, lng: 19.5457315 },
    { lat: 48.6983053, lng: 19.5440063 },
    { lat: 48.6982451, lng: 19.5437037 },
    { lat: 48.6979235, lng: 19.5432236 },
    { lat: 48.6978925, lng: 19.5430414 },
    { lat: 48.6969998, lng: 19.5415348 },
    { lat: 48.6967211, lng: 19.5414505 },
    { lat: 48.6965395, lng: 19.5410782 },
    { lat: 48.6962436, lng: 19.5408406 },
    { lat: 48.6960782, lng: 19.5406021 },
    { lat: 48.6958895, lng: 19.5401738 },
    { lat: 48.6957878, lng: 19.5401905 },
    { lat: 48.6958859, lng: 19.5406111 },
    { lat: 48.6957423, lng: 19.5408477 },
    { lat: 48.6958486, lng: 19.5414975 },
    { lat: 48.6956248, lng: 19.5416012 },
    { lat: 48.6955083, lng: 19.541373 },
    { lat: 48.695215, lng: 19.5416858 },
    { lat: 48.6952374, lng: 19.5419745 },
    { lat: 48.6951358, lng: 19.5421442 },
    { lat: 48.6953519, lng: 19.5423856 },
    { lat: 48.6950983, lng: 19.5425822 },
    { lat: 48.6950869, lng: 19.5427974 },
    { lat: 48.6950214, lng: 19.5429905 },
    { lat: 48.6949224, lng: 19.5429241 },
    { lat: 48.6948076, lng: 19.5431741 },
    { lat: 48.694727, lng: 19.5432353 },
    { lat: 48.6943178, lng: 19.5429567 },
    { lat: 48.694244, lng: 19.5431716 },
    { lat: 48.6938713, lng: 19.5428553 },
    { lat: 48.6936494, lng: 19.5432013 },
    { lat: 48.6932004, lng: 19.543286 },
    { lat: 48.6927185, lng: 19.5435468 },
    { lat: 48.6923721, lng: 19.5434111 },
    { lat: 48.6922145, lng: 19.5432193 },
    { lat: 48.6917703, lng: 19.5430421 },
    { lat: 48.691717, lng: 19.5431839 },
    { lat: 48.6917833, lng: 19.5432505 },
    { lat: 48.6917279, lng: 19.5433968 },
    { lat: 48.6917944, lng: 19.5434194 },
    { lat: 48.6917818, lng: 19.5435891 },
    { lat: 48.6920697, lng: 19.5436457 },
    { lat: 48.6921097, lng: 19.5439322 },
    { lat: 48.6924817, lng: 19.5441084 },
    { lat: 48.6927037, lng: 19.5443527 },
    { lat: 48.6926482, lng: 19.5444958 },
    { lat: 48.6925723, lng: 19.5444831 },
    { lat: 48.6926581, lng: 19.5448814 },
    { lat: 48.6923019, lng: 19.5454726 },
    { lat: 48.6922773, lng: 19.5456474 },
    { lat: 48.6926405, lng: 19.5459012 },
    { lat: 48.692591, lng: 19.5462221 },
    { lat: 48.6928007, lng: 19.5463728 },
    { lat: 48.6939394, lng: 19.5466583 },
    { lat: 48.6942224, lng: 19.5479393 },
    { lat: 48.6941641, lng: 19.5486355 },
    { lat: 48.6943316, lng: 19.5486534 },
    { lat: 48.6943502, lng: 19.5483946 },
    { lat: 48.6946857, lng: 19.5482119 },
    { lat: 48.694636, lng: 19.5478647 },
    { lat: 48.6949505, lng: 19.5476988 },
    { lat: 48.6950644, lng: 19.5477985 },
    { lat: 48.6953919, lng: 19.5475403 },
    { lat: 48.6957166, lng: 19.547449 },
    { lat: 48.6960103, lng: 19.5476584 },
    { lat: 48.6963224, lng: 19.5477799 },
    { lat: 48.6964136, lng: 19.5475813 },
    { lat: 48.6964959, lng: 19.5475838 },
    { lat: 48.696593, lng: 19.5477124 },
    { lat: 48.6965333, lng: 19.5480286 },
    { lat: 48.6966206, lng: 19.5481675 },
    { lat: 48.6969246, lng: 19.5480605 },
    { lat: 48.696964, lng: 19.5479158 },
    { lat: 48.6969268, lng: 19.5476911 },
    { lat: 48.69678, lng: 19.5477342 },
    { lat: 48.6967082, lng: 19.547593 },
    { lat: 48.6968051, lng: 19.5472251 },
    { lat: 48.6966727, lng: 19.546721 },
    { lat: 48.6970511, lng: 19.5462227 },
    { lat: 48.6976062, lng: 19.5465307 },
    { lat: 48.6976526, lng: 19.5469814 },
    { lat: 48.6976057, lng: 19.5472378 },
    { lat: 48.6974146, lng: 19.5476373 },
    { lat: 48.6972826, lng: 19.5476115 },
    { lat: 48.697141, lng: 19.5477679 },
    { lat: 48.6970388, lng: 19.5479961 },
    { lat: 48.6971631, lng: 19.5487686 },
    { lat: 48.6970307, lng: 19.5489027 },
    { lat: 48.6967693, lng: 19.5488051 },
    { lat: 48.6963452, lng: 19.5481078 },
    { lat: 48.6961513, lng: 19.5479586 },
    { lat: 48.6960022, lng: 19.5482196 },
    { lat: 48.6956685, lng: 19.5482174 },
    { lat: 48.6953945, lng: 19.5485117 },
    { lat: 48.6953177, lng: 19.548727 },
    { lat: 48.6951003, lng: 19.5489751 },
    { lat: 48.695136, lng: 19.5491422 },
    { lat: 48.6954906, lng: 19.5493317 },
    { lat: 48.6956056, lng: 19.549625 },
    { lat: 48.6954884, lng: 19.5497985 },
    { lat: 48.6956136, lng: 19.5499249 },
    { lat: 48.6955675, lng: 19.5501494 },
    { lat: 48.6957112, lng: 19.5502832 },
    { lat: 48.695746, lng: 19.5503826 },
    { lat: 48.6953104, lng: 19.5507092 },
    { lat: 48.695136, lng: 19.5507431 },
    { lat: 48.6950602, lng: 19.55098 },
    { lat: 48.6952377, lng: 19.5512408 },
    { lat: 48.6952146, lng: 19.5515724 },
    { lat: 48.6949142, lng: 19.551659 },
    { lat: 48.6947772, lng: 19.5515196 },
    { lat: 48.6947406, lng: 19.5512345 },
    { lat: 48.6946075, lng: 19.5511346 },
    { lat: 48.6943664, lng: 19.5515318 },
    { lat: 48.6944499, lng: 19.5517303 },
    { lat: 48.6940368, lng: 19.5522803 },
    { lat: 48.6936603, lng: 19.5519593 },
    { lat: 48.6935024, lng: 19.5517322 },
    { lat: 48.6931367, lng: 19.5518427 },
    { lat: 48.6926359, lng: 19.5515907 },
    { lat: 48.6924678, lng: 19.5512152 },
    { lat: 48.6923269, lng: 19.5513608 },
    { lat: 48.691981, lng: 19.5512364 },
    { lat: 48.6919444, lng: 19.5513508 },
    { lat: 48.6921537, lng: 19.5517598 },
    { lat: 48.69251, lng: 19.5520383 },
    { lat: 48.6925799, lng: 19.5522104 },
    { lat: 48.6924406, lng: 19.5524154 },
    { lat: 48.6915408, lng: 19.5521355 },
    { lat: 48.6913215, lng: 19.5525136 },
    { lat: 48.6909376, lng: 19.5523486 },
    { lat: 48.6905987, lng: 19.5518372 },
    { lat: 48.6898082, lng: 19.5503204 },
    { lat: 48.6893439, lng: 19.5498553 },
    { lat: 48.6892778, lng: 19.5500088 },
    { lat: 48.6892971, lng: 19.5511224 },
    { lat: 48.6891926, lng: 19.551328 },
    { lat: 48.688969, lng: 19.5511926 },
    { lat: 48.6885545, lng: 19.5513472 },
    { lat: 48.6883027, lng: 19.5509362 },
    { lat: 48.687969, lng: 19.5512553 },
    { lat: 48.6875939, lng: 19.5514556 },
    { lat: 48.6875102, lng: 19.5515966 },
    { lat: 48.6879996, lng: 19.5523163 },
    { lat: 48.6883118, lng: 19.552049 },
    { lat: 48.6884732, lng: 19.5522544 },
    { lat: 48.6880886, lng: 19.552638 },
    { lat: 48.6881759, lng: 19.5530169 },
    { lat: 48.6881033, lng: 19.5532053 },
    { lat: 48.6880822, lng: 19.5534839 },
    { lat: 48.6881933, lng: 19.5534498 },
    { lat: 48.6885097, lng: 19.5535673 },
    { lat: 48.6886418, lng: 19.5538963 },
    { lat: 48.6884972, lng: 19.5540851 },
    { lat: 48.6884682, lng: 19.5545104 },
    { lat: 48.6886879, lng: 19.5545577 },
    { lat: 48.6886738, lng: 19.5550867 },
    { lat: 48.688525, lng: 19.5551328 },
    { lat: 48.6881993, lng: 19.5548879 },
    { lat: 48.6879807, lng: 19.5548119 },
    { lat: 48.6882682, lng: 19.5551614 },
    { lat: 48.6881522, lng: 19.5554599 },
    { lat: 48.6877231, lng: 19.555612 },
    { lat: 48.6876111, lng: 19.5554831 },
    { lat: 48.6876955, lng: 19.5552047 },
    { lat: 48.6872982, lng: 19.5551416 },
    { lat: 48.6872699, lng: 19.5549659 },
    { lat: 48.6871064, lng: 19.5549641 },
    { lat: 48.686829, lng: 19.5550836 },
    { lat: 48.6864676, lng: 19.5544262 },
    { lat: 48.6860778, lng: 19.55423 },
    { lat: 48.6858924, lng: 19.5539965 },
    { lat: 48.6855998, lng: 19.55393 },
    { lat: 48.6851538, lng: 19.5536402 },
    { lat: 48.6850709, lng: 19.5538992 },
    { lat: 48.6855407, lng: 19.5545567 },
    { lat: 48.685543, lng: 19.5549274 },
    { lat: 48.6853695, lng: 19.5549808 },
    { lat: 48.6852292, lng: 19.5554141 },
    { lat: 48.6859419, lng: 19.555695 },
    { lat: 48.6859804, lng: 19.5560198 },
    { lat: 48.6863608, lng: 19.5560347 },
    { lat: 48.6863917, lng: 19.5554045 },
    { lat: 48.6867028, lng: 19.5554527 },
    { lat: 48.6872432, lng: 19.5560347 },
    { lat: 48.687613, lng: 19.5561384 },
    { lat: 48.6873939, lng: 19.5565222 },
    { lat: 48.6875753, lng: 19.5571117 },
    { lat: 48.6877925, lng: 19.5575038 },
    { lat: 48.6878175, lng: 19.5578504 },
    { lat: 48.6885471, lng: 19.5578371 },
    { lat: 48.6887998, lng: 19.5583351 },
    { lat: 48.6888742, lng: 19.5587585 },
    { lat: 48.688999, lng: 19.5589014 },
    { lat: 48.6892756, lng: 19.5598705 },
    { lat: 48.6892303, lng: 19.5600157 },
    { lat: 48.6892276, lng: 19.5604283 },
    { lat: 48.689053, lng: 19.5605488 },
    { lat: 48.6887444, lng: 19.5601355 },
    { lat: 48.688407, lng: 19.5595059 },
    { lat: 48.6883256, lng: 19.5594776 },
    { lat: 48.6881166, lng: 19.5598325 },
    { lat: 48.6876655, lng: 19.5597015 },
    { lat: 48.6876394, lng: 19.5595548 },
    { lat: 48.6873858, lng: 19.559353 },
    { lat: 48.6872581, lng: 19.5587543 },
    { lat: 48.686501, lng: 19.5587062 },
    { lat: 48.6854256, lng: 19.5591446 },
    { lat: 48.6849568, lng: 19.5584506 },
    { lat: 48.6846254, lng: 19.5583029 },
    { lat: 48.6842155, lng: 19.5582304 },
    { lat: 48.6838142, lng: 19.5584772 },
    { lat: 48.6838468, lng: 19.5590202 },
    { lat: 48.6835707, lng: 19.5592668 },
    { lat: 48.6831784, lng: 19.5599271 },
    { lat: 48.6835294, lng: 19.5603831 },
    { lat: 48.6836077, lng: 19.5608414 },
    { lat: 48.6835011, lng: 19.5614665 },
    { lat: 48.683561, lng: 19.5619399 },
    { lat: 48.6832318, lng: 19.5633208 },
    { lat: 48.6830977, lng: 19.5630983 },
    { lat: 48.6827994, lng: 19.5631272 },
    { lat: 48.6823687, lng: 19.5636906 },
    { lat: 48.6822984, lng: 19.5640505 },
    { lat: 48.6823132, lng: 19.5648924 },
    { lat: 48.6821888, lng: 19.5651567 },
    { lat: 48.6819967, lng: 19.5652427 },
    { lat: 48.6818446, lng: 19.5660594 },
    { lat: 48.6821565, lng: 19.5663008 },
    { lat: 48.6822133, lng: 19.5665592 },
    { lat: 48.6820858, lng: 19.566801 },
    { lat: 48.6818185, lng: 19.5670403 },
    { lat: 48.6811104, lng: 19.5673518 },
    { lat: 48.6810287, lng: 19.5677147 },
    { lat: 48.6811673, lng: 19.5680355 },
    { lat: 48.6814487, lng: 19.568396 },
    { lat: 48.6817026, lng: 19.5685536 },
    { lat: 48.6821295, lng: 19.5686722 },
    { lat: 48.6823371, lng: 19.5689519 },
    { lat: 48.6826037, lng: 19.5699313 },
    { lat: 48.6827775, lng: 19.5700601 },
    { lat: 48.6827681, lng: 19.5705165 },
    { lat: 48.6825884, lng: 19.5705594 },
    { lat: 48.6822228, lng: 19.5701745 },
    { lat: 48.6819646, lng: 19.5700013 },
    { lat: 48.68165, lng: 19.5700461 },
    { lat: 48.6814368, lng: 19.5699627 },
    { lat: 48.6811894, lng: 19.5700013 },
    { lat: 48.6809112, lng: 19.5707198 },
    { lat: 48.6812359, lng: 19.5709681 },
    { lat: 48.6814421, lng: 19.5709449 },
    { lat: 48.6817089, lng: 19.571063 },
    { lat: 48.6821999, lng: 19.5708847 },
    { lat: 48.6828205, lng: 19.571522 },
    { lat: 48.6831174, lng: 19.5719276 },
    { lat: 48.683141, lng: 19.5724722 },
    { lat: 48.6829454, lng: 19.5725581 },
    { lat: 48.682616, lng: 19.5733152 },
    { lat: 48.682605, lng: 19.5736651 },
    { lat: 48.6827178, lng: 19.5738659 },
    { lat: 48.6825704, lng: 19.5747135 },
    { lat: 48.6828264, lng: 19.5748389 },
    { lat: 48.6830219, lng: 19.5747811 },
    { lat: 48.6833279, lng: 19.5750153 },
    { lat: 48.6832419, lng: 19.5754026 },
    { lat: 48.682798, lng: 19.5761245 },
    { lat: 48.6826387, lng: 19.5761407 },
    { lat: 48.6821254, lng: 19.5753588 },
    { lat: 48.6818379, lng: 19.5746612 },
    { lat: 48.6812209, lng: 19.5748483 },
    { lat: 48.6810998, lng: 19.5751547 },
    { lat: 48.6808977, lng: 19.5752235 },
    { lat: 48.6805236, lng: 19.5746572 },
    { lat: 48.6798494, lng: 19.5740744 },
    { lat: 48.6795712, lng: 19.5729176 },
    { lat: 48.678944, lng: 19.573472 },
    { lat: 48.6790666, lng: 19.5740833 },
    { lat: 48.6792553, lng: 19.5742709 },
    { lat: 48.67973, lng: 19.5745482 },
    { lat: 48.6798995, lng: 19.5752516 },
    { lat: 48.6800119, lng: 19.5761866 },
    { lat: 48.6797512, lng: 19.5764505 },
    { lat: 48.6792989, lng: 19.5762917 },
    { lat: 48.6791209, lng: 19.5769099 },
    { lat: 48.6790039, lng: 19.5771136 },
    { lat: 48.6790527, lng: 19.5781094 },
    { lat: 48.6788561, lng: 19.5784222 },
    { lat: 48.6793977, lng: 19.5787835 },
    { lat: 48.6794257, lng: 19.5793476 },
    { lat: 48.6803139, lng: 19.5793802 },
    { lat: 48.6805351, lng: 19.579313 },
    { lat: 48.6808196, lng: 19.579669 },
    { lat: 48.6819445, lng: 19.5795298 },
    { lat: 48.6820233, lng: 19.5797245 },
    { lat: 48.6823384, lng: 19.5799393 },
    { lat: 48.6825677, lng: 19.580495 },
    { lat: 48.6825232, lng: 19.5808298 },
    { lat: 48.682399, lng: 19.5811729 },
    { lat: 48.6819512, lng: 19.5811352 },
    { lat: 48.6827211, lng: 19.5820606 },
    { lat: 48.6829235, lng: 19.5823748 },
    { lat: 48.6828703, lng: 19.5826635 },
    { lat: 48.6831762, lng: 19.5830975 },
    { lat: 48.6834736, lng: 19.5829479 },
    { lat: 48.6836858, lng: 19.5830297 },
    { lat: 48.6839677, lng: 19.5828845 },
    { lat: 48.6841007, lng: 19.582885 },
    { lat: 48.6842538, lng: 19.5824098 },
    { lat: 48.684369, lng: 19.5826632 },
    { lat: 48.6845588, lng: 19.5827241 },
    { lat: 48.684345, lng: 19.5834491 },
    { lat: 48.683644, lng: 19.5845403 },
    { lat: 48.6835381, lng: 19.5848144 },
    { lat: 48.6832989, lng: 19.5850004 },
    { lat: 48.6829273, lng: 19.5855521 },
    { lat: 48.6828819, lng: 19.5860186 },
    { lat: 48.68313, lng: 19.5877071 },
    { lat: 48.6839971, lng: 19.5885658 },
    { lat: 48.6841022, lng: 19.5888209 },
    { lat: 48.6841083, lng: 19.5892258 },
    { lat: 48.6841847, lng: 19.5894728 },
    { lat: 48.6842014, lng: 19.5898138 },
    { lat: 48.6845124, lng: 19.5904595 },
    { lat: 48.6844158, lng: 19.5915564 },
    { lat: 48.6842546, lng: 19.5924005 },
    { lat: 48.6841194, lng: 19.5927732 },
    { lat: 48.6839857, lng: 19.5933758 },
    { lat: 48.6839134, lng: 19.5942174 },
    { lat: 48.6837011, lng: 19.5947525 },
    { lat: 48.6836166, lng: 19.5951007 },
    { lat: 48.6836181, lng: 19.5954055 },
    { lat: 48.6838683, lng: 19.5963292 },
    { lat: 48.6840686, lng: 19.5968209 },
    { lat: 48.6840512, lng: 19.5971045 },
    { lat: 48.6840998, lng: 19.5976087 },
    { lat: 48.6843155, lng: 19.5980385 },
    { lat: 48.684413, lng: 19.5986732 },
    { lat: 48.6843877, lng: 19.5991351 },
    { lat: 48.6844724, lng: 19.5995878 },
    { lat: 48.6846149, lng: 19.5999236 },
    { lat: 48.6845568, lng: 19.6001564 },
    { lat: 48.6845695, lng: 19.6006362 },
    { lat: 48.6846911, lng: 19.6012031 },
    { lat: 48.6846462, lng: 19.6025268 },
    { lat: 48.6847251, lng: 19.6032458 },
    { lat: 48.6853297, lng: 19.6035885 },
    { lat: 48.6859315, lng: 19.6042845 },
    { lat: 48.6869025, lng: 19.6052722 },
    { lat: 48.686836, lng: 19.6063964 },
    { lat: 48.6870189, lng: 19.6070982 },
    { lat: 48.6871536, lng: 19.6078828 },
    { lat: 48.6868888, lng: 19.6087571 },
    { lat: 48.6861387, lng: 19.6099596 },
    { lat: 48.6860612, lng: 19.6110332 },
    { lat: 48.6857491, lng: 19.6121045 },
    { lat: 48.68525, lng: 19.6132565 },
    { lat: 48.6846259, lng: 19.6138811 },
    { lat: 48.6838711, lng: 19.6164019 },
    { lat: 48.6837006, lng: 19.6172956 },
    { lat: 48.6833833, lng: 19.6185044 },
    { lat: 48.6833362, lng: 19.6183801 },
    { lat: 48.6833359, lng: 19.6191008 },
    { lat: 48.6831533, lng: 19.6194434 },
    { lat: 48.6829079, lng: 19.6204254 },
    { lat: 48.6827237, lng: 19.620765 },
    { lat: 48.6826222, lng: 19.6211381 },
    { lat: 48.6829378, lng: 19.6219361 },
    { lat: 48.6829612, lng: 19.6229574 },
    { lat: 48.6832373, lng: 19.6240309 },
    { lat: 48.6832589, lng: 19.6244833 },
    { lat: 48.6831453, lng: 19.6246886 },
    { lat: 48.6829063, lng: 19.6257865 },
    { lat: 48.6826534, lng: 19.6261987 },
    { lat: 48.6825654, lng: 19.62662 },
    { lat: 48.6824728, lng: 19.6268385 },
    { lat: 48.6822, lng: 19.6269367 },
    { lat: 48.6817596, lng: 19.6275436 },
    { lat: 48.6814157, lng: 19.6286134 },
    { lat: 48.6811597, lng: 19.6305303 },
    { lat: 48.6812325, lng: 19.6314499 },
    { lat: 48.6817656, lng: 19.6316993 },
    { lat: 48.6823097, lng: 19.6341758 },
    { lat: 48.6827197, lng: 19.6346458 },
    { lat: 48.6826992, lng: 19.6367196 },
    { lat: 48.6828527, lng: 19.6384026 },
    { lat: 48.682834, lng: 19.639137 },
    { lat: 48.6829855, lng: 19.6391246 },
    { lat: 48.682757, lng: 19.6401604 },
    { lat: 48.6819622, lng: 19.6409255 },
    { lat: 48.6815075, lng: 19.6416154 },
    { lat: 48.6813346, lng: 19.6434577 },
    { lat: 48.6811683, lng: 19.6442698 },
    { lat: 48.6809461, lng: 19.6448699 },
    { lat: 48.6807307, lng: 19.6452621 },
    { lat: 48.6804937, lng: 19.6455566 },
    { lat: 48.6798583, lng: 19.6468141 },
    { lat: 48.6795499, lng: 19.6471708 },
    { lat: 48.6793511, lng: 19.6477385 },
    { lat: 48.6789649, lng: 19.6483959 },
    { lat: 48.6778189, lng: 19.6489978 },
    { lat: 48.6775726, lng: 19.6502592 },
    { lat: 48.6768218, lng: 19.6509335 },
    { lat: 48.6765848, lng: 19.6518509 },
    { lat: 48.6759437, lng: 19.6527543 },
    { lat: 48.6756396, lng: 19.6533398 },
    { lat: 48.6756074, lng: 19.6539735 },
    { lat: 48.6753788, lng: 19.6550843 },
    { lat: 48.6752252, lng: 19.6561546 },
    { lat: 48.674668, lng: 19.6572791 },
    { lat: 48.6745918, lng: 19.6573837 },
    { lat: 48.6734228, lng: 19.6592073 },
    { lat: 48.6729699, lng: 19.6600653 },
    { lat: 48.6727783, lng: 19.6605897 },
    { lat: 48.6717698, lng: 19.6617461 },
    { lat: 48.6709206, lng: 19.6625879 },
    { lat: 48.6704331, lng: 19.6633467 },
    { lat: 48.6697394, lng: 19.664765 },
    { lat: 48.6693324, lng: 19.6657694 },
    { lat: 48.6687173, lng: 19.6662214 },
    { lat: 48.6682002, lng: 19.6664752 },
    { lat: 48.6677255, lng: 19.6667949 },
    { lat: 48.6670893, lng: 19.668743 },
    { lat: 48.6660902, lng: 19.6693542 },
    { lat: 48.6656438, lng: 19.6698773 },
    { lat: 48.6645431, lng: 19.6716203 },
    { lat: 48.6639209, lng: 19.6731504 },
    { lat: 48.6629576, lng: 19.6743139 },
    { lat: 48.6617919, lng: 19.6751052 },
    { lat: 48.6610511, lng: 19.6766094 },
    { lat: 48.661022, lng: 19.6775423 },
    { lat: 48.6612, lng: 19.6801185 },
    { lat: 48.6614437, lng: 19.6816905 },
    { lat: 48.6617466, lng: 19.6829485 },
    { lat: 48.6617561, lng: 19.6852108 },
    { lat: 48.661805, lng: 19.6861796 },
    { lat: 48.6621741, lng: 19.6882956 },
    { lat: 48.6625602, lng: 19.6887421 },
    { lat: 48.6621385, lng: 19.6890106 },
    { lat: 48.6609846, lng: 19.6904728 },
    { lat: 48.6600198, lng: 19.6912497 },
    { lat: 48.6592969, lng: 19.6919938 },
    { lat: 48.658789, lng: 19.6925796 },
    { lat: 48.6584503, lng: 19.6931233 },
    { lat: 48.6577671, lng: 19.6939748 },
    { lat: 48.6569686, lng: 19.6947654 },
    { lat: 48.6580342, lng: 19.6996004 },
    { lat: 48.6581452, lng: 19.7003697 },
    { lat: 48.6578162, lng: 19.7020951 },
    { lat: 48.657375, lng: 19.7033487 },
    { lat: 48.656776, lng: 19.7041139 },
    { lat: 48.655192, lng: 19.708129 },
    { lat: 48.6546679, lng: 19.7098729 },
    { lat: 48.6544179, lng: 19.7120613 },
    { lat: 48.6545812, lng: 19.7132571 },
    { lat: 48.6545138, lng: 19.7155436 },
    { lat: 48.654686, lng: 19.7161516 },
    { lat: 48.6555481, lng: 19.717759 },
    { lat: 48.6561291, lng: 19.7183319 },
    { lat: 48.6565398, lng: 19.7188521 },
    { lat: 48.6578927, lng: 19.7207825 },
    { lat: 48.6586119, lng: 19.7213218 },
    { lat: 48.6601061, lng: 19.7260156 },
    { lat: 48.660511, lng: 19.728014 },
    { lat: 48.660534, lng: 19.728005 },
    { lat: 48.66135, lng: 19.728223 },
    { lat: 48.662122, lng: 19.728458 },
    { lat: 48.663279, lng: 19.729553 },
    { lat: 48.663548, lng: 19.729867 },
    { lat: 48.663819, lng: 19.730179 },
    { lat: 48.664083, lng: 19.730485 },
    { lat: 48.664404, lng: 19.730857 },
    { lat: 48.665153, lng: 19.731838 },
    { lat: 48.665291, lng: 19.732024 },
    { lat: 48.666193, lng: 19.732095 },
    { lat: 48.666479, lng: 19.732113 },
    { lat: 48.66707, lng: 19.732157 },
    { lat: 48.667122, lng: 19.73216 },
    { lat: 48.667489, lng: 19.732186 },
    { lat: 48.667973, lng: 19.732213 },
    { lat: 48.668093, lng: 19.732249 },
    { lat: 48.668594, lng: 19.732401 },
    { lat: 48.668906, lng: 19.732493 },
    { lat: 48.669276, lng: 19.732603 },
    { lat: 48.670029, lng: 19.732821 },
    { lat: 48.670686, lng: 19.733819 },
    { lat: 48.671177, lng: 19.735624 },
    { lat: 48.671576, lng: 19.73715 },
    { lat: 48.671646, lng: 19.737399 },
    { lat: 48.671975, lng: 19.738574 },
    { lat: 48.672196, lng: 19.739476 },
    { lat: 48.672533, lng: 19.741253 },
    { lat: 48.672547, lng: 19.74148 },
    { lat: 48.672708, lng: 19.742467 },
    { lat: 48.672999, lng: 19.744372 },
    { lat: 48.673067, lng: 19.745297 },
    { lat: 48.673071, lng: 19.74555 },
    { lat: 48.673073, lng: 19.745696 },
    { lat: 48.67309, lng: 19.746714 },
    { lat: 48.672979, lng: 19.747156 },
    { lat: 48.672993, lng: 19.74755 },
    { lat: 48.673096, lng: 19.748047 },
    { lat: 48.673472, lng: 19.749211 },
    { lat: 48.674052, lng: 19.750456 },
    { lat: 48.674712, lng: 19.751831 },
    { lat: 48.67489, lng: 19.7522 },
    { lat: 48.675497, lng: 19.753413 },
    { lat: 48.675583, lng: 19.753882 },
    { lat: 48.675694, lng: 19.754469 },
    { lat: 48.675699, lng: 19.7545 },
    { lat: 48.67574, lng: 19.754707 },
    { lat: 48.67595, lng: 19.755824 },
    { lat: 48.676483, lng: 19.757943 },
    { lat: 48.67662, lng: 19.759372 },
    { lat: 48.676943, lng: 19.760521 },
    { lat: 48.676974, lng: 19.761675 },
    { lat: 48.676993, lng: 19.763058 },
    { lat: 48.677437, lng: 19.764554 },
    { lat: 48.678306, lng: 19.765541 },
    { lat: 48.679268, lng: 19.766723 },
    { lat: 48.681831, lng: 19.765818 },
    { lat: 48.682173, lng: 19.765698 },
    { lat: 48.683641, lng: 19.765211 },
    { lat: 48.684594, lng: 19.764719 },
    { lat: 48.684843, lng: 19.764592 },
    { lat: 48.68507, lng: 19.764476 },
    { lat: 48.685435, lng: 19.764228 },
    { lat: 48.686231, lng: 19.765106 },
    { lat: 48.686917, lng: 19.765576 },
    { lat: 48.687369, lng: 19.766114 },
    { lat: 48.68769, lng: 19.766445 },
    { lat: 48.687943, lng: 19.76682 },
    { lat: 48.688199, lng: 19.767777 },
    { lat: 48.689122, lng: 19.769719 },
    { lat: 48.689912, lng: 19.770849 },
    { lat: 48.690536, lng: 19.771725 },
    { lat: 48.691463, lng: 19.773273 },
    { lat: 48.691795, lng: 19.773832 },
    { lat: 48.691897, lng: 19.774058 },
    { lat: 48.691974, lng: 19.774478 },
    { lat: 48.692046, lng: 19.774849 },
    { lat: 48.692125, lng: 19.775547 },
    { lat: 48.692052, lng: 19.776785 },
    { lat: 48.691949, lng: 19.777903 },
    { lat: 48.69222, lng: 19.778813 },
    { lat: 48.692361, lng: 19.779725 },
    { lat: 48.692347, lng: 19.780358 },
    { lat: 48.692075, lng: 19.781757 },
    { lat: 48.691845, lng: 19.782888 },
    { lat: 48.691538, lng: 19.783447 },
    { lat: 48.691387, lng: 19.783751 },
    { lat: 48.691112, lng: 19.784555 },
    { lat: 48.690946, lng: 19.785133 },
    { lat: 48.690862, lng: 19.785438 },
    { lat: 48.690704, lng: 19.785879 },
    { lat: 48.690695, lng: 19.785929 },
    { lat: 48.690448, lng: 19.787225 },
    { lat: 48.690541, lng: 19.787737 },
    { lat: 48.690583, lng: 19.78788 },
    { lat: 48.690765, lng: 19.788306 },
    { lat: 48.690844, lng: 19.788852 },
    { lat: 48.691186, lng: 19.789469 },
    { lat: 48.691444, lng: 19.790013 },
    { lat: 48.691632, lng: 19.790397 },
    { lat: 48.691975, lng: 19.790988 },
    { lat: 48.692182, lng: 19.791556 },
    { lat: 48.692471, lng: 19.792089 },
    { lat: 48.692603, lng: 19.792347 },
    { lat: 48.693071, lng: 19.793765 },
    { lat: 48.693332, lng: 19.794816 },
    { lat: 48.693458, lng: 19.797181 },
    { lat: 48.693569, lng: 19.79767 },
    { lat: 48.693575, lng: 19.79829 },
    { lat: 48.693711, lng: 19.799666 },
    { lat: 48.693851, lng: 19.800562 },
    { lat: 48.694023, lng: 19.801265 },
    { lat: 48.69417, lng: 19.801627 },
    { lat: 48.694575, lng: 19.802677 },
    { lat: 48.694788, lng: 19.803276 },
    { lat: 48.695125, lng: 19.804326 },
    { lat: 48.695146, lng: 19.80439 },
    { lat: 48.695953, lng: 19.805658 },
    { lat: 48.696416, lng: 19.80619 },
    { lat: 48.696831, lng: 19.806381 },
    { lat: 48.697226, lng: 19.806368 },
    { lat: 48.697874, lng: 19.80628 },
    { lat: 48.698691, lng: 19.806231 },
    { lat: 48.699075, lng: 19.806201 },
    { lat: 48.700262, lng: 19.806809 },
    { lat: 48.70117, lng: 19.806447 },
    { lat: 48.70236, lng: 19.806289 },
    { lat: 48.702862, lng: 19.806288 },
    { lat: 48.70315, lng: 19.806291 },
    { lat: 48.70354, lng: 19.806498 },
    { lat: 48.703998, lng: 19.806738 },
    { lat: 48.704266, lng: 19.807187 },
    { lat: 48.704623, lng: 19.808057 },
    { lat: 48.705137, lng: 19.809074 },
    { lat: 48.705426, lng: 19.809449 },
    { lat: 48.706397, lng: 19.810229 },
    { lat: 48.706687, lng: 19.810652 },
    { lat: 48.707324, lng: 19.811215 },
    { lat: 48.707328, lng: 19.811205 },
    { lat: 48.70745, lng: 19.811351 },
    { lat: 48.707618, lng: 19.811496 },
    { lat: 48.707832, lng: 19.811664 },
    { lat: 48.707828, lng: 19.811675 },
    { lat: 48.708673, lng: 19.812433 },
    { lat: 48.70912, lng: 19.812485 },
    { lat: 48.709428, lng: 19.812274 },
    { lat: 48.709744, lng: 19.812171 },
    { lat: 48.7097092, lng: 19.8118999 },
    { lat: 48.7097941, lng: 19.8117292 },
    { lat: 48.7097778, lng: 19.8116095 },
    { lat: 48.7097058, lng: 19.8113538 },
    { lat: 48.7096127, lng: 19.8113348 },
    { lat: 48.7095448, lng: 19.8111197 },
    { lat: 48.7096542, lng: 19.8109715 },
    { lat: 48.7096506, lng: 19.8104978 },
    { lat: 48.7093863, lng: 19.8102483 },
    { lat: 48.7094637, lng: 19.8086625 },
    { lat: 48.7092969, lng: 19.8084539 },
    { lat: 48.7092073, lng: 19.8078974 },
    { lat: 48.7089324, lng: 19.8072966 },
    { lat: 48.7087381, lng: 19.8059893 },
    { lat: 48.7085029, lng: 19.8053459 },
    { lat: 48.7088292, lng: 19.8045387 },
    { lat: 48.7094203, lng: 19.8037527 },
    { lat: 48.7102975, lng: 19.8029902 },
    { lat: 48.711106, lng: 19.8025175 },
    { lat: 48.7116542, lng: 19.8024034 },
    { lat: 48.7119757, lng: 19.802385 },
    { lat: 48.7122466, lng: 19.8024484 },
    { lat: 48.7121973, lng: 19.8028938 },
    { lat: 48.7122615, lng: 19.8032841 },
    { lat: 48.7125274, lng: 19.8032866 },
    { lat: 48.7132703, lng: 19.8035299 },
    { lat: 48.7136814, lng: 19.8034234 },
    { lat: 48.7139267, lng: 19.8033081 },
    { lat: 48.7144841, lng: 19.8028924 },
    { lat: 48.714717, lng: 19.8025478 },
    { lat: 48.7153181, lng: 19.8020168 },
    { lat: 48.7155024, lng: 19.8019285 },
    { lat: 48.7157698, lng: 19.8016286 },
    { lat: 48.7160747, lng: 19.8017717 },
    { lat: 48.7161384, lng: 19.8017119 },
    { lat: 48.7175501, lng: 19.8038085 },
    { lat: 48.7184693, lng: 19.8023307 },
    { lat: 48.7186538, lng: 19.8021176 },
    { lat: 48.7189561, lng: 19.8020486 },
    { lat: 48.7192975, lng: 19.8021264 },
    { lat: 48.7198589, lng: 19.8020292 },
    { lat: 48.7207229, lng: 19.8020539 },
    { lat: 48.7210105, lng: 19.8014373 },
    { lat: 48.7210743, lng: 19.8012001 },
    { lat: 48.7212991, lng: 19.8008755 },
    { lat: 48.7216023, lng: 19.80124 },
    { lat: 48.7220222, lng: 19.8014933 },
    { lat: 48.7224928, lng: 19.8010736 },
    { lat: 48.7228335, lng: 19.8011068 },
    { lat: 48.7233479, lng: 19.8020284 },
    { lat: 48.723495, lng: 19.802483 },
    { lat: 48.7236756, lng: 19.8026202 },
    { lat: 48.7243601, lng: 19.8028665 },
    { lat: 48.7242739, lng: 19.8017904 },
    { lat: 48.7240592, lng: 19.8005636 },
    { lat: 48.7245228, lng: 19.79972 },
    { lat: 48.7248265, lng: 19.7994514 },
    { lat: 48.7252457, lng: 19.7992727 },
    { lat: 48.7254132, lng: 19.7993659 },
    { lat: 48.7258157, lng: 19.7991428 },
    { lat: 48.7260866, lng: 19.7993096 },
    { lat: 48.7263874, lng: 19.7992287 },
    { lat: 48.7268726, lng: 19.7994038 },
    { lat: 48.7272248, lng: 19.7993427 },
    { lat: 48.7275499, lng: 19.7993821 },
    { lat: 48.7275231, lng: 19.7988466 },
    { lat: 48.7278049, lng: 19.7988992 },
    { lat: 48.7279828, lng: 19.7990553 },
    { lat: 48.7283234, lng: 19.7991399 },
    { lat: 48.7284796, lng: 19.7987418 },
    { lat: 48.7284099, lng: 19.7986642 },
    { lat: 48.7284116, lng: 19.798422 },
    { lat: 48.7284885, lng: 19.7979105 },
    { lat: 48.7285601, lng: 19.7978195 },
    { lat: 48.7285987, lng: 19.7974374 },
    { lat: 48.7285375, lng: 19.7971495 },
    { lat: 48.7287117, lng: 19.796933 },
    { lat: 48.7287879, lng: 19.7966642 },
    { lat: 48.7289255, lng: 19.7965124 },
    { lat: 48.7296573, lng: 19.7962683 },
    { lat: 48.7295908, lng: 19.7961603 },
    { lat: 48.7287349, lng: 19.7956542 },
    { lat: 48.728647, lng: 19.7954467 },
    { lat: 48.728259, lng: 19.7954111 },
    { lat: 48.7278861, lng: 19.7955928 },
    { lat: 48.7275994, lng: 19.795525 },
    { lat: 48.7274083, lng: 19.7953292 },
    { lat: 48.7273761, lng: 19.795159 },
    { lat: 48.7271652, lng: 19.7946904 },
    { lat: 48.7270109, lng: 19.7935278 },
    { lat: 48.7268994, lng: 19.7932927 },
    { lat: 48.7266011, lng: 19.7930962 },
    { lat: 48.7266982, lng: 19.7927885 },
    { lat: 48.7271594, lng: 19.7917902 },
    { lat: 48.7281258, lng: 19.7918902 },
    { lat: 48.7281964, lng: 19.7917493 },
    { lat: 48.7281685, lng: 19.7913153 },
    { lat: 48.7282887, lng: 19.790641 },
    { lat: 48.7283702, lng: 19.7905976 },
    { lat: 48.7284925, lng: 19.7903481 },
    { lat: 48.728524, lng: 19.7900916 },
    { lat: 48.7286666, lng: 19.7899032 },
    { lat: 48.72886, lng: 19.7897626 },
    { lat: 48.7289764, lng: 19.7897681 },
    { lat: 48.7291468, lng: 19.7900106 },
    { lat: 48.7292793, lng: 19.7900491 },
    { lat: 48.7296136, lng: 19.7903812 },
    { lat: 48.7298317, lng: 19.7903955 },
    { lat: 48.7300532, lng: 19.7899966 },
    { lat: 48.7302797, lng: 19.790231 },
    { lat: 48.7306826, lng: 19.7902989 },
    { lat: 48.7305555, lng: 19.7908715 },
    { lat: 48.7311814, lng: 19.79104 },
    { lat: 48.7314918, lng: 19.7901241 },
    { lat: 48.7316331, lng: 19.789338 },
    { lat: 48.7319239, lng: 19.7894925 },
    { lat: 48.7319037, lng: 19.7897757 },
    { lat: 48.731965, lng: 19.7906939 },
    { lat: 48.731942, lng: 19.7912023 },
    { lat: 48.7326348, lng: 19.7913281 },
    { lat: 48.7334851, lng: 19.7911927 },
    { lat: 48.7345155, lng: 19.7907684 },
    { lat: 48.7347572, lng: 19.7905509 },
    { lat: 48.7349689, lng: 19.7897692 },
    { lat: 48.735286, lng: 19.7892133 },
    { lat: 48.7355435, lng: 19.7882758 },
    { lat: 48.7360088, lng: 19.7887137 },
    { lat: 48.7357322, lng: 19.7891146 },
    { lat: 48.7353649, lng: 19.7892676 },
    { lat: 48.7353118, lng: 19.7895994 },
    { lat: 48.7354934, lng: 19.789763 },
    { lat: 48.735943, lng: 19.7899155 },
    { lat: 48.7358616, lng: 19.7914062 },
    { lat: 48.7359401, lng: 19.7919434 },
    { lat: 48.7361979, lng: 19.7920915 },
    { lat: 48.7359908, lng: 19.7930798 },
    { lat: 48.7364722, lng: 19.7938873 },
    { lat: 48.7366861, lng: 19.7947119 },
    { lat: 48.7368883, lng: 19.79511 },
    { lat: 48.7370576, lng: 19.7956353 },
    { lat: 48.7369495, lng: 19.79618 },
    { lat: 48.7368136, lng: 19.7965548 },
    { lat: 48.7373642, lng: 19.7964146 },
    { lat: 48.7379795, lng: 19.7965736 },
    { lat: 48.7382389, lng: 19.7967148 },
    { lat: 48.7384051, lng: 19.7965448 },
    { lat: 48.7393354, lng: 19.7965378 },
    { lat: 48.7396804, lng: 19.7964146 },
    { lat: 48.7402439, lng: 19.7953312 },
    { lat: 48.740497, lng: 19.7945996 },
    { lat: 48.7408144, lng: 19.7939462 },
    { lat: 48.7407499, lng: 19.7937356 },
    { lat: 48.7407975, lng: 19.7930945 },
    { lat: 48.7408955, lng: 19.7930676 },
    { lat: 48.7412653, lng: 19.7935615 },
    { lat: 48.7414429, lng: 19.7934774 },
    { lat: 48.7416554, lng: 19.7935209 },
    { lat: 48.7418557, lng: 19.793437 },
    { lat: 48.7422064, lng: 19.7934731 },
    { lat: 48.7423556, lng: 19.7935307 },
    { lat: 48.7423926, lng: 19.7936816 },
    { lat: 48.7424943, lng: 19.7940013 },
    { lat: 48.7425674, lng: 19.793813 },
    { lat: 48.7427851, lng: 19.7932023 },
    { lat: 48.7432729, lng: 19.7926358 },
    { lat: 48.7439652, lng: 19.7913539 },
    { lat: 48.7443735, lng: 19.790485 },
    { lat: 48.7446194, lng: 19.7892272 },
    { lat: 48.7446358, lng: 19.7884663 },
    { lat: 48.7451448, lng: 19.786757 },
    { lat: 48.7451189, lng: 19.7859853 },
    { lat: 48.7451887, lng: 19.7854418 },
    { lat: 48.7454098, lng: 19.7844113 },
    { lat: 48.7457085, lng: 19.7834119 },
    { lat: 48.7459087, lng: 19.7824995 },
    { lat: 48.7458222, lng: 19.781756 },
    { lat: 48.7454033, lng: 19.7801122 },
    { lat: 48.7455374, lng: 19.7784374 },
    { lat: 48.7459097, lng: 19.7780868 },
    { lat: 48.7458831, lng: 19.7775797 },
    { lat: 48.7457629, lng: 19.7772279 },
    { lat: 48.7456278, lng: 19.7754026 },
    { lat: 48.7456674, lng: 19.7751738 },
    { lat: 48.7460612, lng: 19.7747271 },
    { lat: 48.7462344, lng: 19.7743035 },
    { lat: 48.7463859, lng: 19.7741606 },
    { lat: 48.7464434, lng: 19.7738792 },
    { lat: 48.746434, lng: 19.773561 },
    { lat: 48.746323, lng: 19.7734743 },
    { lat: 48.746288, lng: 19.7733429 },
    { lat: 48.7469613, lng: 19.773072 },
    { lat: 48.7470023, lng: 19.7730054 },
    { lat: 48.7469314, lng: 19.7724431 },
    { lat: 48.7466248, lng: 19.7715114 },
    { lat: 48.746413, lng: 19.7711277 },
    { lat: 48.7461742, lng: 19.7709474 },
    { lat: 48.7460231, lng: 19.7706341 },
    { lat: 48.7460764, lng: 19.7704871 },
    { lat: 48.7456028, lng: 19.7697316 },
    { lat: 48.7455094, lng: 19.7691684 },
    { lat: 48.745644, lng: 19.7686141 },
    { lat: 48.7455576, lng: 19.768496 },
    { lat: 48.7454397, lng: 19.766935 },
    { lat: 48.7456564, lng: 19.7659444 },
    { lat: 48.7458788, lng: 19.7655391 },
    { lat: 48.746365, lng: 19.7644219 },
    { lat: 48.7465027, lng: 19.7642514 },
    { lat: 48.7464734, lng: 19.7640926 },
    { lat: 48.7465959, lng: 19.7638822 },
    { lat: 48.7466864, lng: 19.7638624 },
    { lat: 48.7471207, lng: 19.7633659 },
    { lat: 48.7471791, lng: 19.7630237 },
    { lat: 48.7469874, lng: 19.7626613 },
    { lat: 48.7468165, lng: 19.7620284 },
    { lat: 48.7466164, lng: 19.7622191 },
    { lat: 48.7465458, lng: 19.7623786 },
    { lat: 48.7463804, lng: 19.7624087 },
    { lat: 48.7463275, lng: 19.7615144 },
    { lat: 48.7461749, lng: 19.7609096 },
    { lat: 48.746099, lng: 19.7601051 },
    { lat: 48.7461847, lng: 19.7593347 },
    { lat: 48.7459543, lng: 19.757781 },
    { lat: 48.7459835, lng: 19.7562894 },
    { lat: 48.7458424, lng: 19.7548239 },
    { lat: 48.7457905, lng: 19.7536407 },
    { lat: 48.7468785, lng: 19.7537888 },
    { lat: 48.7485193, lng: 19.7522831 },
    { lat: 48.7486682, lng: 19.7513371 },
    { lat: 48.7495647, lng: 19.7503066 },
    { lat: 48.7500912, lng: 19.7500056 },
    { lat: 48.7505641, lng: 19.7486645 },
    { lat: 48.7501414, lng: 19.7468684 },
    { lat: 48.7498909, lng: 19.7441949 },
    { lat: 48.7501602, lng: 19.7430185 },
    { lat: 48.7504672, lng: 19.7411857 },
    { lat: 48.7503438, lng: 19.7400725 },
    { lat: 48.7504485, lng: 19.7395603 },
    { lat: 48.7502125, lng: 19.7377347 },
    { lat: 48.7501097, lng: 19.7365494 },
    { lat: 48.7502454, lng: 19.73636 },
    { lat: 48.7506118, lng: 19.735418 },
    { lat: 48.7514543, lng: 19.7341081 },
    { lat: 48.7516473, lng: 19.7330183 },
    { lat: 48.7518971, lng: 19.7319637 },
    { lat: 48.752908, lng: 19.7285987 },
    { lat: 48.7533821, lng: 19.7263464 },
    { lat: 48.7540372, lng: 19.7252873 },
    { lat: 48.7546131, lng: 19.7239894 },
    { lat: 48.754627, lng: 19.7227751 },
    { lat: 48.7545515, lng: 19.7210942 },
    { lat: 48.7540485, lng: 19.7197836 },
    { lat: 48.7539005, lng: 19.7185974 },
    { lat: 48.7538394, lng: 19.7159942 },
    { lat: 48.7549723, lng: 19.7150426 },
    { lat: 48.7551671, lng: 19.7135125 },
    { lat: 48.7551646, lng: 19.7120309 },
    { lat: 48.7558971, lng: 19.7114207 },
    { lat: 48.7567343, lng: 19.709748 },
    { lat: 48.7569152, lng: 19.7086666 },
    { lat: 48.7567481, lng: 19.7074634 },
    { lat: 48.7570588, lng: 19.7062861 },
    { lat: 48.7575378, lng: 19.7040817 },
    { lat: 48.7575848, lng: 19.7026022 },
    { lat: 48.7584752, lng: 19.7017948 },
    { lat: 48.7592086, lng: 19.7001684 },
    { lat: 48.7592279, lng: 19.7003 },
    { lat: 48.7598652, lng: 19.7005681 },
    { lat: 48.760025, lng: 19.7000827 },
    { lat: 48.7604892, lng: 19.700158 },
    { lat: 48.7606147, lng: 19.6998357 },
    { lat: 48.7606461, lng: 19.6993783 },
    { lat: 48.7608689, lng: 19.6985839 },
    { lat: 48.7612579, lng: 19.6981576 },
    { lat: 48.7612128, lng: 19.6977251 },
    { lat: 48.7613801, lng: 19.6971821 },
    { lat: 48.7613154, lng: 19.6970162 },
    { lat: 48.76154, lng: 19.6968002 },
    { lat: 48.7617166, lng: 19.6961097 },
    { lat: 48.7613894, lng: 19.6959702 },
    { lat: 48.7614727, lng: 19.6955871 },
    { lat: 48.7616168, lng: 19.6953814 },
    { lat: 48.7617716, lng: 19.6954438 },
    { lat: 48.7618152, lng: 19.6953309 },
    { lat: 48.7617971, lng: 19.6949752 },
    { lat: 48.7618705, lng: 19.6945496 },
    { lat: 48.762222, lng: 19.6939124 },
    { lat: 48.762305, lng: 19.693473 },
    { lat: 48.7625963, lng: 19.6932855 },
    { lat: 48.7626289, lng: 19.6929529 },
    { lat: 48.7631797, lng: 19.6924412 },
    { lat: 48.7633149, lng: 19.6920371 },
    { lat: 48.7638958, lng: 19.691188 },
    { lat: 48.7637986, lng: 19.6910572 },
    { lat: 48.7639025, lng: 19.6906317 },
    { lat: 48.7638831, lng: 19.6904993 },
    { lat: 48.7640529, lng: 19.6899574 },
    { lat: 48.7642175, lng: 19.6899653 },
    { lat: 48.7642592, lng: 19.6896336 },
    { lat: 48.763908, lng: 19.6893375 },
    { lat: 48.7639655, lng: 19.6891088 },
    { lat: 48.7641442, lng: 19.6892232 },
    { lat: 48.7643298, lng: 19.6887886 },
    { lat: 48.7645522, lng: 19.6889002 },
    { lat: 48.7647127, lng: 19.6881257 },
    { lat: 48.7647106, lng: 19.6876994 },
    { lat: 48.7648029, lng: 19.6875683 },
    { lat: 48.7648553, lng: 19.6873558 },
    { lat: 48.7651063, lng: 19.6872238 },
    { lat: 48.7653479, lng: 19.6867796 },
    { lat: 48.765479, lng: 19.6863087 },
    { lat: 48.7656146, lng: 19.6864899 },
    { lat: 48.7658674, lng: 19.6862699 },
    { lat: 48.7659078, lng: 19.6858358 },
    { lat: 48.7660495, lng: 19.6858588 },
    { lat: 48.7660965, lng: 19.685444 },
    { lat: 48.7663036, lng: 19.6851372 },
    { lat: 48.7662518, lng: 19.684861 },
    { lat: 48.7663192, lng: 19.6846038 },
    { lat: 48.7664009, lng: 19.683579 },
    { lat: 48.7663601, lng: 19.6834678 },
    { lat: 48.766344, lng: 19.6828312 },
    { lat: 48.7659473, lng: 19.6828031 },
    { lat: 48.7659068, lng: 19.6825779 },
    { lat: 48.7659564, lng: 19.6817678 },
    { lat: 48.7659292, lng: 19.6812309 },
    { lat: 48.7660977, lng: 19.6801326 },
    { lat: 48.7662815, lng: 19.67986 },
    { lat: 48.7664319, lng: 19.6789364 },
    { lat: 48.766573, lng: 19.6788391 },
    { lat: 48.7666364, lng: 19.6789212 },
    { lat: 48.7667809, lng: 19.67835 },
    { lat: 48.7670061, lng: 19.6782164 },
    { lat: 48.7670709, lng: 19.6777269 },
    { lat: 48.7673659, lng: 19.6771146 },
    { lat: 48.7673529, lng: 19.6769234 },
    { lat: 48.7672645, lng: 19.6769364 },
    { lat: 48.7672334, lng: 19.6764131 },
    { lat: 48.7673998, lng: 19.6765075 },
    { lat: 48.7675308, lng: 19.6758816 },
    { lat: 48.7680007, lng: 19.6754272 },
    { lat: 48.768123, lng: 19.6745114 },
    { lat: 48.7682842, lng: 19.6741082 },
    { lat: 48.7685726, lng: 19.6730944 },
    { lat: 48.7689685, lng: 19.6727503 },
    { lat: 48.7692629, lng: 19.6727324 },
    { lat: 48.7694189, lng: 19.6728487 },
    { lat: 48.76948, lng: 19.6723224 },
    { lat: 48.7699475, lng: 19.6723182 },
    { lat: 48.7699509, lng: 19.6717117 },
    { lat: 48.7694771, lng: 19.6710197 },
    { lat: 48.7695383, lng: 19.6703958 },
    { lat: 48.7699714, lng: 19.6701298 },
    { lat: 48.769746, lng: 19.6695231 },
    { lat: 48.7699079, lng: 19.6689804 },
    { lat: 48.7687968, lng: 19.6677913 },
    { lat: 48.7691077, lng: 19.6677163 },
    { lat: 48.7688554, lng: 19.6672113 },
    { lat: 48.7681269, lng: 19.6680808 },
    { lat: 48.7679787, lng: 19.6678667 },
    { lat: 48.7676921, lng: 19.6681088 },
    { lat: 48.7670143, lng: 19.6693328 },
    { lat: 48.7666437, lng: 19.6690335 },
    { lat: 48.766505, lng: 19.6688294 },
    { lat: 48.7666219, lng: 19.6683175 },
    { lat: 48.7668404, lng: 19.6686162 },
    { lat: 48.7669814, lng: 19.6682943 },
    { lat: 48.7666705, lng: 19.6678414 },
    { lat: 48.767421, lng: 19.6668811 },
    { lat: 48.7673684, lng: 19.6667925 },
    { lat: 48.7685564, lng: 19.6651106 },
    { lat: 48.7684567, lng: 19.6648718 },
    { lat: 48.7686754, lng: 19.664498 },
  ],
  Podbrezová: [
    { lat: 48.8048086, lng: 19.5606623 },
    { lat: 48.8050895, lng: 19.5609665 },
    { lat: 48.8050142, lng: 19.5603767 },
    { lat: 48.8059382, lng: 19.5605226 },
    { lat: 48.8066474, lng: 19.5609555 },
    { lat: 48.8075952, lng: 19.5622197 },
    { lat: 48.8077079, lng: 19.5615784 },
    { lat: 48.807867, lng: 19.561407 },
    { lat: 48.8079314, lng: 19.5615089 },
    { lat: 48.8081884, lng: 19.5611625 },
    { lat: 48.8081256, lng: 19.5609767 },
    { lat: 48.8080066, lng: 19.5608826 },
    { lat: 48.8078056, lng: 19.5605304 },
    { lat: 48.807816, lng: 19.5603838 },
    { lat: 48.8077442, lng: 19.5600728 },
    { lat: 48.8090412, lng: 19.5568589 },
    { lat: 48.8093691, lng: 19.5571057 },
    { lat: 48.8095891, lng: 19.5573851 },
    { lat: 48.8097584, lng: 19.5579791 },
    { lat: 48.8096627, lng: 19.556449 },
    { lat: 48.8095467, lng: 19.555956 },
    { lat: 48.8093965, lng: 19.5555784 },
    { lat: 48.8091341, lng: 19.5551622 },
    { lat: 48.8082723, lng: 19.554697 },
    { lat: 48.8083835, lng: 19.5542207 },
    { lat: 48.8089017, lng: 19.5543635 },
    { lat: 48.8089387, lng: 19.5542384 },
    { lat: 48.8092048, lng: 19.5542678 },
    { lat: 48.8092975, lng: 19.5536028 },
    { lat: 48.8087518, lng: 19.5530807 },
    { lat: 48.8086874, lng: 19.5529008 },
    { lat: 48.8096745, lng: 19.5509497 },
    { lat: 48.8100352, lng: 19.5489292 },
    { lat: 48.8106683, lng: 19.5472717 },
    { lat: 48.8107083, lng: 19.5469632 },
    { lat: 48.8113655, lng: 19.5457114 },
    { lat: 48.8121789, lng: 19.5458351 },
    { lat: 48.8135816, lng: 19.5454604 },
    { lat: 48.8137245, lng: 19.545921 },
    { lat: 48.8138604, lng: 19.5460845 },
    { lat: 48.8140009, lng: 19.5461259 },
    { lat: 48.8143661, lng: 19.5459836 },
    { lat: 48.8147035, lng: 19.5457456 },
    { lat: 48.8157885, lng: 19.545445 },
    { lat: 48.8165432, lng: 19.5455658 },
    { lat: 48.8169172, lng: 19.5455595 },
    { lat: 48.8175846, lng: 19.5452 },
    { lat: 48.8182021, lng: 19.5450242 },
    { lat: 48.8185586, lng: 19.545034 },
    { lat: 48.8192977, lng: 19.5452034 },
    { lat: 48.8201885, lng: 19.5450464 },
    { lat: 48.820586, lng: 19.5450895 },
    { lat: 48.8207577, lng: 19.5451735 },
    { lat: 48.8209744, lng: 19.5453539 },
    { lat: 48.8210093, lng: 19.545548 },
    { lat: 48.8215639, lng: 19.5462201 },
    { lat: 48.8222497, lng: 19.54663 },
    { lat: 48.822355, lng: 19.5466393 },
    { lat: 48.822426, lng: 19.5466644 },
    { lat: 48.8223302, lng: 19.5464431 },
    { lat: 48.8228444, lng: 19.5457323 },
    { lat: 48.8228597, lng: 19.5455773 },
    { lat: 48.8228678, lng: 19.5431648 },
    { lat: 48.8234566, lng: 19.5302649 },
    { lat: 48.8234754, lng: 19.529866 },
    { lat: 48.8235136, lng: 19.5294418 },
    { lat: 48.824412, lng: 19.5285886 },
    { lat: 48.8250674, lng: 19.5272258 },
    { lat: 48.8264028, lng: 19.5248435 },
    { lat: 48.8250726, lng: 19.5220025 },
    { lat: 48.8251123, lng: 19.520609 },
    { lat: 48.8256665, lng: 19.5173152 },
    { lat: 48.8253722, lng: 19.5167475 },
    { lat: 48.8260605, lng: 19.5150975 },
    { lat: 48.826617, lng: 19.514654 },
    { lat: 48.82723, lng: 19.5114415 },
    { lat: 48.8280482, lng: 19.5090148 },
    { lat: 48.8284263, lng: 19.5084423 },
    { lat: 48.8277893, lng: 19.5080882 },
    { lat: 48.8278294, lng: 19.5078823 },
    { lat: 48.8268687, lng: 19.5072583 },
    { lat: 48.8263324, lng: 19.5074533 },
    { lat: 48.8261618, lng: 19.5072975 },
    { lat: 48.8262845, lng: 19.5068074 },
    { lat: 48.8252607, lng: 19.5057417 },
    { lat: 48.8247143, lng: 19.5048595 },
    { lat: 48.8248209, lng: 19.5038462 },
    { lat: 48.8252306, lng: 19.503446 },
    { lat: 48.8255288, lng: 19.5028967 },
    { lat: 48.825518, lng: 19.5027387 },
    { lat: 48.825964, lng: 19.5015772 },
    { lat: 48.8267193, lng: 19.5006308 },
    { lat: 48.8270322, lng: 19.4999862 },
    { lat: 48.8275596, lng: 19.498619 },
    { lat: 48.8276277, lng: 19.4980893 },
    { lat: 48.8278258, lng: 19.4974271 },
    { lat: 48.8281354, lng: 19.4971504 },
    { lat: 48.8286351, lng: 19.4969152 },
    { lat: 48.8287147, lng: 19.4966135 },
    { lat: 48.8286771, lng: 19.4955059 },
    { lat: 48.829012, lng: 19.4951425 },
    { lat: 48.8296094, lng: 19.495291 },
    { lat: 48.8304305, lng: 19.4953069 },
    { lat: 48.8315849, lng: 19.493949 },
    { lat: 48.8334713, lng: 19.4932909 },
    { lat: 48.8338151, lng: 19.4925835 },
    { lat: 48.8343742, lng: 19.491829 },
    { lat: 48.833356, lng: 19.4885182 },
    { lat: 48.8333172, lng: 19.4881428 },
    { lat: 48.834696, lng: 19.4858609 },
    { lat: 48.8342039, lng: 19.4853046 },
    { lat: 48.8335895, lng: 19.4837858 },
    { lat: 48.8331272, lng: 19.4841246 },
    { lat: 48.8327246, lng: 19.4841908 },
    { lat: 48.8324665, lng: 19.4844829 },
    { lat: 48.8322971, lng: 19.4847941 },
    { lat: 48.8320077, lng: 19.4847944 },
    { lat: 48.8316905, lng: 19.4851006 },
    { lat: 48.8312084, lng: 19.4853015 },
    { lat: 48.8310873, lng: 19.4855586 },
    { lat: 48.8305572, lng: 19.485672 },
    { lat: 48.8302852, lng: 19.4856118 },
    { lat: 48.8302323, lng: 19.4859074 },
    { lat: 48.8296783, lng: 19.4861586 },
    { lat: 48.829527, lng: 19.4860068 },
    { lat: 48.8291678, lng: 19.4861844 },
    { lat: 48.828543, lng: 19.4870492 },
    { lat: 48.8273442, lng: 19.4882531 },
    { lat: 48.8271877, lng: 19.4886223 },
    { lat: 48.8270855, lng: 19.4883849 },
    { lat: 48.8270689, lng: 19.4876806 },
    { lat: 48.8258321, lng: 19.4861593 },
    { lat: 48.8249535, lng: 19.4858228 },
    { lat: 48.8249601, lng: 19.4859677 },
    { lat: 48.8247539, lng: 19.4860417 },
    { lat: 48.8246396, lng: 19.4864197 },
    { lat: 48.8244214, lng: 19.4867475 },
    { lat: 48.8237571, lng: 19.4872946 },
    { lat: 48.8233272, lng: 19.4874549 },
    { lat: 48.8229822, lng: 19.4877675 },
    { lat: 48.8226939, lng: 19.4881871 },
    { lat: 48.8221665, lng: 19.4891867 },
    { lat: 48.821852, lng: 19.4895783 },
    { lat: 48.8218241, lng: 19.4898981 },
    { lat: 48.8216623, lng: 19.4900558 },
    { lat: 48.8216343, lng: 19.4900119 },
    { lat: 48.82204, lng: 19.4883043 },
    { lat: 48.8211747, lng: 19.4884509 },
    { lat: 48.821113, lng: 19.4890418 },
    { lat: 48.8208516, lng: 19.4895079 },
    { lat: 48.8194601, lng: 19.4909529 },
    { lat: 48.8194391, lng: 19.4914543 },
    { lat: 48.8181321, lng: 19.4910147 },
    { lat: 48.8174701, lng: 19.4909821 },
    { lat: 48.8167263, lng: 19.4907594 },
    { lat: 48.8140232, lng: 19.4906717 },
    { lat: 48.8138047, lng: 19.4907206 },
    { lat: 48.8118333, lng: 19.4902216 },
    { lat: 48.811494, lng: 19.4902957 },
    { lat: 48.8105914, lng: 19.4892443 },
    { lat: 48.8094891, lng: 19.4900213 },
    { lat: 48.8091684, lng: 19.4905954 },
    { lat: 48.8097024, lng: 19.4928117 },
    { lat: 48.8098548, lng: 19.4931363 },
    { lat: 48.8102036, lng: 19.4944935 },
    { lat: 48.8105975, lng: 19.4954114 },
    { lat: 48.8107795, lng: 19.4956918 },
    { lat: 48.8111976, lng: 19.4965961 },
    { lat: 48.8114638, lng: 19.496726 },
    { lat: 48.8115758, lng: 19.4973206 },
    { lat: 48.8120621, lng: 19.4976518 },
    { lat: 48.8124014, lng: 19.4979992 },
    { lat: 48.8120665, lng: 19.4983099 },
    { lat: 48.8119412, lng: 19.4982813 },
    { lat: 48.8108, lng: 19.4986311 },
    { lat: 48.8104473, lng: 19.4992161 },
    { lat: 48.809813, lng: 19.4988054 },
    { lat: 48.8094968, lng: 19.4984984 },
    { lat: 48.8089183, lng: 19.4981455 },
    { lat: 48.8088481, lng: 19.4983331 },
    { lat: 48.8092085, lng: 19.4988167 },
    { lat: 48.8092217, lng: 19.4991027 },
    { lat: 48.8094109, lng: 19.4994939 },
    { lat: 48.8098512, lng: 19.4998757 },
    { lat: 48.8091588, lng: 19.4997922 },
    { lat: 48.8081813, lng: 19.499484 },
    { lat: 48.8079645, lng: 19.4993027 },
    { lat: 48.8076614, lng: 19.4991567 },
    { lat: 48.8074008, lng: 19.4991285 },
    { lat: 48.8072613, lng: 19.4992519 },
    { lat: 48.8067981, lng: 19.4994245 },
    { lat: 48.8065473, lng: 19.4994338 },
    { lat: 48.805382, lng: 19.498774 },
    { lat: 48.8049159, lng: 19.4986105 },
    { lat: 48.8046549, lng: 19.4986438 },
    { lat: 48.8037113, lng: 19.4990033 },
    { lat: 48.8021407, lng: 19.4993762 },
    { lat: 48.8013594, lng: 19.4997568 },
    { lat: 48.8011849, lng: 19.4997634 },
    { lat: 48.8009122, lng: 19.5000266 },
    { lat: 48.8003443, lng: 19.5005746 },
    { lat: 48.7998487, lng: 19.5007831 },
    { lat: 48.7993837, lng: 19.5009788 },
    { lat: 48.7987569, lng: 19.5009731 },
    { lat: 48.79796, lng: 19.5012599 },
    { lat: 48.796848, lng: 19.5012915 },
    { lat: 48.7960014, lng: 19.5014288 },
    { lat: 48.7955457, lng: 19.5017371 },
    { lat: 48.7945626, lng: 19.5020953 },
    { lat: 48.7936436, lng: 19.5022791 },
    { lat: 48.7925858, lng: 19.5020669 },
    { lat: 48.7918077, lng: 19.5023478 },
    { lat: 48.7916269, lng: 19.5019708 },
    { lat: 48.791612, lng: 19.5014728 },
    { lat: 48.7911938, lng: 19.501201 },
    { lat: 48.7910877, lng: 19.5013945 },
    { lat: 48.7910266, lng: 19.5018051 },
    { lat: 48.7906073, lng: 19.5017057 },
    { lat: 48.7897871, lng: 19.5017865 },
    { lat: 48.7895284, lng: 19.5019411 },
    { lat: 48.7894382, lng: 19.5018644 },
    { lat: 48.7891594, lng: 19.5018553 },
    { lat: 48.7879691, lng: 19.5009449 },
    { lat: 48.7863109, lng: 19.4999052 },
    { lat: 48.7859465, lng: 19.4993823 },
    { lat: 48.7856961, lng: 19.4991332 },
    { lat: 48.7848281, lng: 19.4985659 },
    { lat: 48.7843188, lng: 19.4984967 },
    { lat: 48.7825608, lng: 19.4964383 },
    { lat: 48.7811581, lng: 19.4952254 },
    { lat: 48.7810524, lng: 19.4941324 },
    { lat: 48.7808421, lng: 19.4933117 },
    { lat: 48.77924, lng: 19.4906079 },
    { lat: 48.7774889, lng: 19.4893681 },
    { lat: 48.7764114, lng: 19.4879028 },
    { lat: 48.7754657, lng: 19.4867995 },
    { lat: 48.7749713, lng: 19.4866586 },
    { lat: 48.7734459, lng: 19.4856942 },
    { lat: 48.7722495, lng: 19.4859944 },
    { lat: 48.7715227, lng: 19.4860202 },
    { lat: 48.7703335, lng: 19.4857463 },
    { lat: 48.7696638, lng: 19.4853984 },
    { lat: 48.7692228, lng: 19.4852686 },
    { lat: 48.7690822, lng: 19.4851492 },
    { lat: 48.7685673, lng: 19.485229 },
    { lat: 48.7685033, lng: 19.4851361 },
    { lat: 48.7677059, lng: 19.4849233 },
    { lat: 48.7656504, lng: 19.4848754 },
    { lat: 48.7652404, lng: 19.4845873 },
    { lat: 48.7647442, lng: 19.4843822 },
    { lat: 48.7639947, lng: 19.4844087 },
    { lat: 48.7634138, lng: 19.4842501 },
    { lat: 48.7629635, lng: 19.483383 },
    { lat: 48.7622356, lng: 19.483555 },
    { lat: 48.7620354, lng: 19.4837809 },
    { lat: 48.7623058, lng: 19.4845356 },
    { lat: 48.7625069, lng: 19.4847169 },
    { lat: 48.7626842, lng: 19.4850685 },
    { lat: 48.7637626, lng: 19.4856492 },
    { lat: 48.7640258, lng: 19.486055 },
    { lat: 48.7642142, lng: 19.4864473 },
    { lat: 48.7641918, lng: 19.4873283 },
    { lat: 48.7642795, lng: 19.4880099 },
    { lat: 48.7644994, lng: 19.4887668 },
    { lat: 48.7658675, lng: 19.4921332 },
    { lat: 48.7666494, lng: 19.4932019 },
    { lat: 48.7676414, lng: 19.4942845 },
    { lat: 48.7694806, lng: 19.4970207 },
    { lat: 48.769991, lng: 19.4976055 },
    { lat: 48.7712349, lng: 19.4977207 },
    { lat: 48.7717097, lng: 19.4979541 },
    { lat: 48.7729897, lng: 19.4985833 },
    { lat: 48.7740403, lng: 19.5001805 },
    { lat: 48.7744476, lng: 19.5010457 },
    { lat: 48.7747655, lng: 19.501487 },
    { lat: 48.7749422, lng: 19.5021911 },
    { lat: 48.7758198, lng: 19.5066635 },
    { lat: 48.7761192, lng: 19.5086631 },
    { lat: 48.776746, lng: 19.5092084 },
    { lat: 48.7772116, lng: 19.5098482 },
    { lat: 48.7781808, lng: 19.5110981 },
    { lat: 48.779474, lng: 19.5122128 },
    { lat: 48.7804411, lng: 19.5125648 },
    { lat: 48.7806506, lng: 19.5128075 },
    { lat: 48.7810725, lng: 19.5129149 },
    { lat: 48.7815528, lng: 19.513237 },
    { lat: 48.7817214, lng: 19.5134212 },
    { lat: 48.7823144, lng: 19.5134277 },
    { lat: 48.782828, lng: 19.514035 },
    { lat: 48.7831337, lng: 19.516914 },
    { lat: 48.7837685, lng: 19.5184881 },
    { lat: 48.7839848, lng: 19.5187101 },
    { lat: 48.7845004, lng: 19.5195811 },
    { lat: 48.785988, lng: 19.5210573 },
    { lat: 48.7870682, lng: 19.522351 },
    { lat: 48.7878491, lng: 19.5231845 },
    { lat: 48.7886199, lng: 19.5238232 },
    { lat: 48.7889679, lng: 19.5243109 },
    { lat: 48.7890599, lng: 19.5249156 },
    { lat: 48.788982, lng: 19.5254773 },
    { lat: 48.7891404, lng: 19.5264974 },
    { lat: 48.7895555, lng: 19.5272429 },
    { lat: 48.7898336, lng: 19.5282004 },
    { lat: 48.7899435, lng: 19.528825 },
    { lat: 48.7898529, lng: 19.5295782 },
    { lat: 48.7898034, lng: 19.5305136 },
    { lat: 48.7894774, lng: 19.532989 },
    { lat: 48.7893638, lng: 19.5334384 },
    { lat: 48.7888604, lng: 19.5346762 },
    { lat: 48.7887214, lng: 19.5351832 },
    { lat: 48.7887297, lng: 19.5358549 },
    { lat: 48.7897324, lng: 19.5380709 },
    { lat: 48.7902325, lng: 19.5387497 },
    { lat: 48.7910295, lng: 19.5391801 },
    { lat: 48.7933146, lng: 19.5389288 },
    { lat: 48.793519, lng: 19.5391594 },
    { lat: 48.7946424, lng: 19.5391832 },
    { lat: 48.7949092, lng: 19.5387324 },
    { lat: 48.7950923, lng: 19.5386989 },
    { lat: 48.7962651, lng: 19.5380988 },
    { lat: 48.7991244, lng: 19.5382588 },
    { lat: 48.7995881, lng: 19.5387631 },
    { lat: 48.8001658, lng: 19.5389524 },
    { lat: 48.8006433, lng: 19.5392949 },
    { lat: 48.8012508, lng: 19.5405523 },
    { lat: 48.8018065, lng: 19.5413441 },
    { lat: 48.80281, lng: 19.5423932 },
    { lat: 48.8031607, lng: 19.5430907 },
    { lat: 48.8034471, lng: 19.5435434 },
    { lat: 48.8042004, lng: 19.5447338 },
    { lat: 48.8049923, lng: 19.5458599 },
    { lat: 48.8057017, lng: 19.5457788 },
    { lat: 48.805774, lng: 19.5462253 },
    { lat: 48.8057078, lng: 19.546377 },
    { lat: 48.8051721, lng: 19.546886 },
    { lat: 48.8047853, lng: 19.5471284 },
    { lat: 48.8044264, lng: 19.5472117 },
    { lat: 48.8036062, lng: 19.5477101 },
    { lat: 48.8031759, lng: 19.5481069 },
    { lat: 48.8025071, lng: 19.5490837 },
    { lat: 48.8020332, lng: 19.5503297 },
    { lat: 48.801949, lng: 19.5508289 },
    { lat: 48.8019583, lng: 19.5535281 },
    { lat: 48.8020209, lng: 19.5544559 },
    { lat: 48.8020867, lng: 19.5549088 },
    { lat: 48.8026587, lng: 19.5573976 },
    { lat: 48.8026615, lng: 19.55761 },
    { lat: 48.802833, lng: 19.5580297 },
    { lat: 48.803033, lng: 19.5583669 },
    { lat: 48.8030629, lng: 19.5587231 },
    { lat: 48.8037157, lng: 19.5592765 },
    { lat: 48.8044618, lng: 19.5602439 },
    { lat: 48.8044731, lng: 19.5601243 },
    { lat: 48.8048189, lng: 19.560469 },
    { lat: 48.8048086, lng: 19.5606623 },
  ],
  Brezno: [
    { lat: 48.8767475, lng: 19.6850876 },
    { lat: 48.8770318, lng: 19.6847922 },
    { lat: 48.8773497, lng: 19.6847462 },
    { lat: 48.8775289, lng: 19.6844423 },
    { lat: 48.8785054, lng: 19.6846146 },
    { lat: 48.8787905, lng: 19.6847917 },
    { lat: 48.8792143, lng: 19.6849464 },
    { lat: 48.8797, lng: 19.6852784 },
    { lat: 48.8812628, lng: 19.6853809 },
    { lat: 48.8816566, lng: 19.6852699 },
    { lat: 48.8822127, lng: 19.6854361 },
    { lat: 48.8825124, lng: 19.6856132 },
    { lat: 48.8832852, lng: 19.6847951 },
    { lat: 48.8848345, lng: 19.6835844 },
    { lat: 48.8849193, lng: 19.6833094 },
    { lat: 48.8851545, lng: 19.6831307 },
    { lat: 48.8855551, lng: 19.6830654 },
    { lat: 48.8863812, lng: 19.6826131 },
    { lat: 48.8865477, lng: 19.6821779 },
    { lat: 48.8867589, lng: 19.6818366 },
    { lat: 48.8868549, lng: 19.6807496 },
    { lat: 48.8867332, lng: 19.6804713 },
    { lat: 48.8868732, lng: 19.6793332 },
    { lat: 48.8870174, lng: 19.6789561 },
    { lat: 48.8873159, lng: 19.6772526 },
    { lat: 48.8872928, lng: 19.6769131 },
    { lat: 48.8875111, lng: 19.6762474 },
    { lat: 48.8875323, lng: 19.6759082 },
    { lat: 48.8876027, lng: 19.6758446 },
    { lat: 48.887748, lng: 19.6757589 },
    { lat: 48.8881288, lng: 19.6757442 },
    { lat: 48.889287, lng: 19.6765062 },
    { lat: 48.8903958, lng: 19.676812 },
    { lat: 48.8917941, lng: 19.6777285 },
    { lat: 48.8929481, lng: 19.6786725 },
    { lat: 48.893954, lng: 19.6789213 },
    { lat: 48.8948438, lng: 19.6790162 },
    { lat: 48.8955214, lng: 19.6794296 },
    { lat: 48.8973811, lng: 19.6798558 },
    { lat: 48.8975304, lng: 19.6800171 },
    { lat: 48.8986849, lng: 19.6791714 },
    { lat: 48.8997546, lng: 19.6788297 },
    { lat: 48.9003693, lng: 19.6787425 },
    { lat: 48.9005547, lng: 19.6788833 },
    { lat: 48.9015373, lng: 19.6786331 },
    { lat: 48.9025751, lng: 19.6779769 },
    { lat: 48.9028645, lng: 19.6776684 },
    { lat: 48.9049077, lng: 19.6786681 },
    { lat: 48.9059676, lng: 19.680944 },
    { lat: 48.9062147, lng: 19.6808507 },
    { lat: 48.9074737, lng: 19.6827431 },
    { lat: 48.9064752, lng: 19.6823526 },
    { lat: 48.9076175, lng: 19.6890058 },
    { lat: 48.9062451, lng: 19.6896888 },
    { lat: 48.9071131, lng: 19.690612 },
    { lat: 48.9072542, lng: 19.6904404 },
    { lat: 48.9092075, lng: 19.6904324 },
    { lat: 48.9093491, lng: 19.6915977 },
    { lat: 48.9100384, lng: 19.6924315 },
    { lat: 48.9104768, lng: 19.6904766 },
    { lat: 48.9137743, lng: 19.6912588 },
    { lat: 48.9134663, lng: 19.6902056 },
    { lat: 48.9157854, lng: 19.6916881 },
    { lat: 48.9166035, lng: 19.6901703 },
    { lat: 48.9174787, lng: 19.6990947 },
    { lat: 48.9160749, lng: 19.7016882 },
    { lat: 48.9158148, lng: 19.7016699 },
    { lat: 48.9160077, lng: 19.7047352 },
    { lat: 48.9126607, lng: 19.7083588 },
    { lat: 48.9116562, lng: 19.7084449 },
    { lat: 48.9111879, lng: 19.706876 },
    { lat: 48.9086266, lng: 19.7108968 },
    { lat: 48.9081605, lng: 19.7105201 },
    { lat: 48.9049516, lng: 19.7096158 },
    { lat: 48.9047447, lng: 19.7095575 },
    { lat: 48.9069914, lng: 19.7145849 },
    { lat: 48.9080256, lng: 19.7167563 },
    { lat: 48.9067799, lng: 19.7194205 },
    { lat: 48.9069618, lng: 19.7204833 },
    { lat: 48.908217, lng: 19.724034 },
    { lat: 48.908444, lng: 19.721982 },
    { lat: 48.911072, lng: 19.719127 },
    { lat: 48.911277, lng: 19.718329 },
    { lat: 48.911245, lng: 19.717081 },
    { lat: 48.911294, lng: 19.716584 },
    { lat: 48.911533, lng: 19.715198 },
    { lat: 48.911988, lng: 19.712944 },
    { lat: 48.91223, lng: 19.712577 },
    { lat: 48.912471, lng: 19.712224 },
    { lat: 48.913244, lng: 19.711852 },
    { lat: 48.9136499, lng: 19.7117296 },
    { lat: 48.91398, lng: 19.71163 },
    { lat: 48.914157, lng: 19.711496 },
    { lat: 48.914403, lng: 19.711266 },
    { lat: 48.915225, lng: 19.7104 },
    { lat: 48.915843, lng: 19.709738 },
    { lat: 48.916148, lng: 19.709524 },
    { lat: 48.916476, lng: 19.709186 },
    { lat: 48.916751, lng: 19.70888 },
    { lat: 48.917429, lng: 19.708342 },
    { lat: 48.917822, lng: 19.707446 },
    { lat: 48.918079, lng: 19.707115 },
    { lat: 48.9180882, lng: 19.7070959 },
    { lat: 48.918285, lng: 19.706689 },
    { lat: 48.918232, lng: 19.706318 },
    { lat: 48.918252, lng: 19.705707 },
    { lat: 48.91816, lng: 19.703952 },
    { lat: 48.919032, lng: 19.704388 },
    { lat: 48.921096, lng: 19.70261 },
    { lat: 48.921332, lng: 19.702154 },
    { lat: 48.922005, lng: 19.698911 },
    { lat: 48.922208, lng: 19.698495 },
    { lat: 48.923191, lng: 19.696479 },
    { lat: 48.923281, lng: 19.696162 },
    { lat: 48.9234678, lng: 19.6945337 },
    { lat: 48.9235448, lng: 19.6938623 },
    { lat: 48.923617, lng: 19.693233 },
    { lat: 48.923698, lng: 19.690142 },
    { lat: 48.923154, lng: 19.687505 },
    { lat: 48.921951, lng: 19.682475 },
    { lat: 48.921354, lng: 19.681236 },
    { lat: 48.921152, lng: 19.680673 },
    { lat: 48.9211253, lng: 19.6804926 },
    { lat: 48.920951, lng: 19.679317 },
    { lat: 48.920782, lng: 19.67833 },
    { lat: 48.919782, lng: 19.677217 },
    { lat: 48.919235, lng: 19.676474 },
    { lat: 48.918191, lng: 19.67527 },
    { lat: 48.919159, lng: 19.669322 },
    { lat: 48.921327, lng: 19.665658 },
    { lat: 48.921552, lng: 19.665257 },
    { lat: 48.921629, lng: 19.664684 },
    { lat: 48.921748, lng: 19.663805 },
    { lat: 48.922171, lng: 19.662189 },
    { lat: 48.922434, lng: 19.660352 },
    { lat: 48.922688, lng: 19.659374 },
    { lat: 48.92286, lng: 19.658708 },
    { lat: 48.923285, lng: 19.657761 },
    { lat: 48.924067, lng: 19.655509 },
    { lat: 48.924733, lng: 19.654635 },
    { lat: 48.925207, lng: 19.654133 },
    { lat: 48.925538, lng: 19.653425 },
    { lat: 48.925759, lng: 19.652936 },
    { lat: 48.926494, lng: 19.650699 },
    { lat: 48.926803, lng: 19.649794 },
    { lat: 48.92703, lng: 19.649581 },
    { lat: 48.927199, lng: 19.649422 },
    { lat: 48.927359, lng: 19.649271 },
    { lat: 48.932974, lng: 19.648062 },
    { lat: 48.934345, lng: 19.64494 },
    { lat: 48.936203, lng: 19.640706 },
    { lat: 48.936396, lng: 19.640266 },
    { lat: 48.933888, lng: 19.6294751 },
    { lat: 48.933463, lng: 19.6276383 },
    { lat: 48.9333505, lng: 19.627966 },
    { lat: 48.9274351, lng: 19.6392814 },
    { lat: 48.9264678, lng: 19.6388172 },
    { lat: 48.9256401, lng: 19.6379684 },
    { lat: 48.9176875, lng: 19.6186732 },
    { lat: 48.9155843, lng: 19.6177559 },
    { lat: 48.914513, lng: 19.6175862 },
    { lat: 48.9015244, lng: 19.6253184 },
    { lat: 48.8970911, lng: 19.6281878 },
    { lat: 48.8958297, lng: 19.6292064 },
    { lat: 48.8950884, lng: 19.6292077 },
    { lat: 48.8930618, lng: 19.6294674 },
    { lat: 48.8923102, lng: 19.62944 },
    { lat: 48.8917775, lng: 19.6295974 },
    { lat: 48.8907853, lng: 19.6297066 },
    { lat: 48.8900026, lng: 19.6299352 },
    { lat: 48.8893598, lng: 19.6299822 },
    { lat: 48.8889859, lng: 19.6302843 },
    { lat: 48.8880866, lng: 19.6318168 },
    { lat: 48.8872932, lng: 19.632914 },
    { lat: 48.8865929, lng: 19.6343571 },
    { lat: 48.8858248, lng: 19.6357717 },
    { lat: 48.8848164, lng: 19.6366314 },
    { lat: 48.8839113, lng: 19.6378577 },
    { lat: 48.8832871, lng: 19.6381583 },
    { lat: 48.8828419, lng: 19.6386291 },
    { lat: 48.8813462, lng: 19.6399534 },
    { lat: 48.8802522, lng: 19.6406578 },
    { lat: 48.8792107, lng: 19.6410768 },
    { lat: 48.878426, lng: 19.6412639 },
    { lat: 48.8775185, lng: 19.6419616 },
    { lat: 48.8751903, lng: 19.6430864 },
    { lat: 48.8737905, lng: 19.6436037 },
    { lat: 48.8729187, lng: 19.644062 },
    { lat: 48.8725856, lng: 19.6444474 },
    { lat: 48.8715899, lng: 19.645285 },
    { lat: 48.871236, lng: 19.6455115 },
    { lat: 48.8710595, lng: 19.6458986 },
    { lat: 48.8708813, lng: 19.6461231 },
    { lat: 48.8708061, lng: 19.6461516 },
    { lat: 48.8706948, lng: 19.6469615 },
    { lat: 48.8705488, lng: 19.6475768 },
    { lat: 48.8704756, lng: 19.6482332 },
    { lat: 48.8724364, lng: 19.6493248 },
    { lat: 48.8724084, lng: 19.6494318 },
    { lat: 48.8736304, lng: 19.6501009 },
    { lat: 48.8727455, lng: 19.6511209 },
    { lat: 48.8725755, lng: 19.6519079 },
    { lat: 48.8719582, lng: 19.652064 },
    { lat: 48.8714808, lng: 19.6519162 },
    { lat: 48.8708704, lng: 19.6515956 },
    { lat: 48.8702181, lng: 19.651042 },
    { lat: 48.8673373, lng: 19.6548297 },
    { lat: 48.8647386, lng: 19.6594878 },
    { lat: 48.8642011, lng: 19.6593594 },
    { lat: 48.8640376, lng: 19.659229 },
    { lat: 48.8637135, lng: 19.6592401 },
    { lat: 48.8635867, lng: 19.6596263 },
    { lat: 48.8630464, lng: 19.6601967 },
    { lat: 48.8626552, lng: 19.6602658 },
    { lat: 48.8618807, lng: 19.6600786 },
    { lat: 48.8619223, lng: 19.6589951 },
    { lat: 48.8616789, lng: 19.658688 },
    { lat: 48.861177, lng: 19.658398 },
    { lat: 48.8606982, lng: 19.658499 },
    { lat: 48.860477, lng: 19.6582662 },
    { lat: 48.8607053, lng: 19.6578292 },
    { lat: 48.8608237, lng: 19.6580052 },
    { lat: 48.8609499, lng: 19.6579064 },
    { lat: 48.8610219, lng: 19.6577537 },
    { lat: 48.8610106, lng: 19.6575008 },
    { lat: 48.8608849, lng: 19.657055 },
    { lat: 48.8605239, lng: 19.6570963 },
    { lat: 48.8606555, lng: 19.6565854 },
    { lat: 48.8603979, lng: 19.6562419 },
    { lat: 48.8603657, lng: 19.6560755 },
    { lat: 48.8596152, lng: 19.6561729 },
    { lat: 48.8589697, lng: 19.6568596 },
    { lat: 48.8592186, lng: 19.6574649 },
    { lat: 48.8590702, lng: 19.6576931 },
    { lat: 48.8589626, lng: 19.6579854 },
    { lat: 48.859184, lng: 19.6582486 },
    { lat: 48.859251, lng: 19.6584177 },
    { lat: 48.859697, lng: 19.6588869 },
    { lat: 48.8599119, lng: 19.6587829 },
    { lat: 48.860048, lng: 19.659558 },
    { lat: 48.8597759, lng: 19.6600786 },
    { lat: 48.8588093, lng: 19.6600532 },
    { lat: 48.8584457, lng: 19.6597831 },
    { lat: 48.8581374, lng: 19.6598422 },
    { lat: 48.8578593, lng: 19.6600264 },
    { lat: 48.8574611, lng: 19.6601406 },
    { lat: 48.8574464, lng: 19.6597585 },
    { lat: 48.8575341, lng: 19.6598343 },
    { lat: 48.8579469, lng: 19.6594872 },
    { lat: 48.8580001, lng: 19.6587113 },
    { lat: 48.8581778, lng: 19.6578952 },
    { lat: 48.8580022, lng: 19.6578234 },
    { lat: 48.8581006, lng: 19.6573789 },
    { lat: 48.8584002, lng: 19.6566211 },
    { lat: 48.8578158, lng: 19.6560967 },
    { lat: 48.8576379, lng: 19.6569086 },
    { lat: 48.8574275, lng: 19.656893 },
    { lat: 48.8571363, lng: 19.6561718 },
    { lat: 48.8570061, lng: 19.6571673 },
    { lat: 48.8566557, lng: 19.6574956 },
    { lat: 48.8565282, lng: 19.6577967 },
    { lat: 48.856255, lng: 19.65796 },
    { lat: 48.8558772, lng: 19.6588582 },
    { lat: 48.8559205, lng: 19.659072 },
    { lat: 48.8545276, lng: 19.659116 },
    { lat: 48.8546984, lng: 19.6585994 },
    { lat: 48.8549632, lng: 19.6583765 },
    { lat: 48.8550345, lng: 19.6581535 },
    { lat: 48.8550123, lng: 19.6578584 },
    { lat: 48.8554178, lng: 19.6569993 },
    { lat: 48.8556626, lng: 19.6567799 },
    { lat: 48.8560549, lng: 19.6569375 },
    { lat: 48.8565907, lng: 19.6564693 },
    { lat: 48.856458, lng: 19.6559193 },
    { lat: 48.8563379, lng: 19.6558565 },
    { lat: 48.8554573, lng: 19.6558149 },
    { lat: 48.855448, lng: 19.6556809 },
    { lat: 48.8542473, lng: 19.6559157 },
    { lat: 48.853905, lng: 19.6547401 },
    { lat: 48.8538892, lng: 19.6545084 },
    { lat: 48.8539842, lng: 19.6533831 },
    { lat: 48.8540871, lng: 19.6531886 },
    { lat: 48.8541597, lng: 19.6533272 },
    { lat: 48.8546265, lng: 19.6534345 },
    { lat: 48.8548724, lng: 19.6535957 },
    { lat: 48.8549344, lng: 19.6538459 },
    { lat: 48.8556143, lng: 19.6542352 },
    { lat: 48.8559063, lng: 19.6545931 },
    { lat: 48.8559495, lng: 19.6549536 },
    { lat: 48.8561016, lng: 19.6550543 },
    { lat: 48.8562151, lng: 19.6548208 },
    { lat: 48.8561884, lng: 19.6544492 },
    { lat: 48.8561172, lng: 19.654293 },
    { lat: 48.8561083, lng: 19.6541208 },
    { lat: 48.8562689, lng: 19.654159 },
    { lat: 48.8560924, lng: 19.6537664 },
    { lat: 48.8561226, lng: 19.6531776 },
    { lat: 48.8557617, lng: 19.6530429 },
    { lat: 48.8559512, lng: 19.6528245 },
    { lat: 48.8561738, lng: 19.6527401 },
    { lat: 48.856892, lng: 19.6528753 },
    { lat: 48.8570859, lng: 19.6524711 },
    { lat: 48.8581021, lng: 19.6517417 },
    { lat: 48.8583136, lng: 19.651302 },
    { lat: 48.858427, lng: 19.6512202 },
    { lat: 48.8584577, lng: 19.6513781 },
    { lat: 48.8587758, lng: 19.6514324 },
    { lat: 48.8587137, lng: 19.6508258 },
    { lat: 48.8585634, lng: 19.6507704 },
    { lat: 48.8586833, lng: 19.6502253 },
    { lat: 48.8586777, lng: 19.6500216 },
    { lat: 48.858511, lng: 19.6500992 },
    { lat: 48.8582487, lng: 19.6500513 },
    { lat: 48.8583947, lng: 19.650217 },
    { lat: 48.8583394, lng: 19.6504699 },
    { lat: 48.8584445, lng: 19.650778 },
    { lat: 48.857846, lng: 19.6508981 },
    { lat: 48.8576938, lng: 19.6505209 },
    { lat: 48.8575321, lng: 19.6505844 },
    { lat: 48.8573733, lng: 19.6507885 },
    { lat: 48.8571589, lng: 19.6508814 },
    { lat: 48.8569217, lng: 19.6507902 },
    { lat: 48.85681, lng: 19.650661 },
    { lat: 48.8566862, lng: 19.6506916 },
    { lat: 48.8564083, lng: 19.6509241 },
    { lat: 48.8562107, lng: 19.6504392 },
    { lat: 48.8559623, lng: 19.6507549 },
    { lat: 48.8556605, lng: 19.6507318 },
    { lat: 48.8554755, lng: 19.6508775 },
    { lat: 48.8552914, lng: 19.6509204 },
    { lat: 48.8552689, lng: 19.6504386 },
    { lat: 48.8548709, lng: 19.6500075 },
    { lat: 48.8544491, lng: 19.650428 },
    { lat: 48.8543322, lng: 19.6504586 },
    { lat: 48.8542149, lng: 19.6505815 },
    { lat: 48.8542135, lng: 19.6506772 },
    { lat: 48.8543246, lng: 19.6508633 },
    { lat: 48.8544482, lng: 19.6509093 },
    { lat: 48.8544734, lng: 19.6511443 },
    { lat: 48.8543083, lng: 19.6511333 },
    { lat: 48.8543165, lng: 19.6512896 },
    { lat: 48.8543956, lng: 19.6513483 },
    { lat: 48.854407, lng: 19.6515236 },
    { lat: 48.8544905, lng: 19.6516545 },
    { lat: 48.8549116, lng: 19.6517197 },
    { lat: 48.8551634, lng: 19.6514581 },
    { lat: 48.8552692, lng: 19.6514272 },
    { lat: 48.8552932, lng: 19.6516168 },
    { lat: 48.8556917, lng: 19.6512768 },
    { lat: 48.8557296, lng: 19.6513434 },
    { lat: 48.8556109, lng: 19.6516608 },
    { lat: 48.8555169, lng: 19.6521302 },
    { lat: 48.8554162, lng: 19.6522769 },
    { lat: 48.8557806, lng: 19.652131 },
    { lat: 48.8558626, lng: 19.6519723 },
    { lat: 48.8561299, lng: 19.6519588 },
    { lat: 48.8562005, lng: 19.6518217 },
    { lat: 48.8563321, lng: 19.6517646 },
    { lat: 48.8564079, lng: 19.6515958 },
    { lat: 48.8565525, lng: 19.6515566 },
    { lat: 48.8565156, lng: 19.6514119 },
    { lat: 48.8566131, lng: 19.6512741 },
    { lat: 48.8566328, lng: 19.6509082 },
    { lat: 48.856819, lng: 19.6509903 },
    { lat: 48.8568319, lng: 19.6512517 },
    { lat: 48.8564466, lng: 19.6519081 },
    { lat: 48.8563984, lng: 19.6520644 },
    { lat: 48.8558107, lng: 19.6523551 },
    { lat: 48.8553089, lng: 19.6524421 },
    { lat: 48.8552237, lng: 19.6526541 },
    { lat: 48.8550415, lng: 19.6523611 },
    { lat: 48.8548049, lng: 19.6521063 },
    { lat: 48.8542773, lng: 19.6524571 },
    { lat: 48.85421, lng: 19.652361 },
    { lat: 48.8536361, lng: 19.6524997 },
    { lat: 48.8534709, lng: 19.6524111 },
    { lat: 48.8533671, lng: 19.6527838 },
    { lat: 48.8531823, lng: 19.6523835 },
    { lat: 48.8528534, lng: 19.6523364 },
    { lat: 48.8526065, lng: 19.6524594 },
    { lat: 48.8524896, lng: 19.6523905 },
    { lat: 48.8524187, lng: 19.6522411 },
    { lat: 48.851701, lng: 19.6521896 },
    { lat: 48.8515997, lng: 19.6521155 },
    { lat: 48.8513712, lng: 19.6520954 },
    { lat: 48.8511215, lng: 19.6521281 },
    { lat: 48.8509037, lng: 19.6518718 },
    { lat: 48.8505422, lng: 19.6517508 },
    { lat: 48.8501496, lng: 19.651409 },
    { lat: 48.8499555, lng: 19.6514286 },
    { lat: 48.8499943, lng: 19.6511613 },
    { lat: 48.8497742, lng: 19.6509753 },
    { lat: 48.8491386, lng: 19.6510484 },
    { lat: 48.8491384, lng: 19.6512706 },
    { lat: 48.8489978, lng: 19.6512013 },
    { lat: 48.8487494, lng: 19.6514429 },
    { lat: 48.8480732, lng: 19.6513106 },
    { lat: 48.8480535, lng: 19.6512232 },
    { lat: 48.8475619, lng: 19.6512665 },
    { lat: 48.847597, lng: 19.65165 },
    { lat: 48.8474846, lng: 19.6516282 },
    { lat: 48.8471976, lng: 19.6511601 },
    { lat: 48.8470072, lng: 19.6510569 },
    { lat: 48.8468604, lng: 19.6513543 },
    { lat: 48.8464978, lng: 19.6513815 },
    { lat: 48.8453577, lng: 19.6520644 },
    { lat: 48.8444543, lng: 19.6530765 },
    { lat: 48.8438797, lng: 19.6538856 },
    { lat: 48.8437552, lng: 19.6536741 },
    { lat: 48.8434447, lng: 19.6541592 },
    { lat: 48.8434408, lng: 19.6542784 },
    { lat: 48.8429678, lng: 19.6551099 },
    { lat: 48.842667, lng: 19.6550882 },
    { lat: 48.8423571, lng: 19.6548109 },
    { lat: 48.8423051, lng: 19.6546299 },
    { lat: 48.8421874, lng: 19.6545912 },
    { lat: 48.8419262, lng: 19.6543189 },
    { lat: 48.8418733, lng: 19.6538644 },
    { lat: 48.8422521, lng: 19.653265 },
    { lat: 48.8431107, lng: 19.6525594 },
    { lat: 48.8434538, lng: 19.6514153 },
    { lat: 48.84356, lng: 19.6500891 },
    { lat: 48.8435347, lng: 19.6495723 },
    { lat: 48.8431054, lng: 19.6475609 },
    { lat: 48.8430858, lng: 19.6473491 },
    { lat: 48.843139, lng: 19.6469766 },
    { lat: 48.8430645, lng: 19.6459662 },
    { lat: 48.8431692, lng: 19.6454843 },
    { lat: 48.8431255, lng: 19.6452134 },
    { lat: 48.8429181, lng: 19.6450583 },
    { lat: 48.842985, lng: 19.6442384 },
    { lat: 48.8428781, lng: 19.6435643 },
    { lat: 48.8426566, lng: 19.6440028 },
    { lat: 48.8425446, lng: 19.643868 },
    { lat: 48.8426268, lng: 19.6436374 },
    { lat: 48.8425319, lng: 19.6434314 },
    { lat: 48.8422526, lng: 19.6433586 },
    { lat: 48.8420693, lng: 19.6426878 },
    { lat: 48.8417284, lng: 19.6429238 },
    { lat: 48.8412297, lng: 19.643135 },
    { lat: 48.8410796, lng: 19.6433572 },
    { lat: 48.8406169, lng: 19.6432177 },
    { lat: 48.8404703, lng: 19.6433122 },
    { lat: 48.8402376, lng: 19.6439041 },
    { lat: 48.8401671, lng: 19.6446457 },
    { lat: 48.8400028, lng: 19.6445707 },
    { lat: 48.8399862, lng: 19.6442791 },
    { lat: 48.8394243, lng: 19.6445597 },
    { lat: 48.8394643, lng: 19.6447995 },
    { lat: 48.8393572, lng: 19.6449028 },
    { lat: 48.8391994, lng: 19.6448729 },
    { lat: 48.8391296, lng: 19.6444751 },
    { lat: 48.8388268, lng: 19.6445302 },
    { lat: 48.8384968, lng: 19.6447319 },
    { lat: 48.8384161, lng: 19.6442369 },
    { lat: 48.8388332, lng: 19.6430867 },
    { lat: 48.8389853, lng: 19.6423074 },
    { lat: 48.838373, lng: 19.6427498 },
    { lat: 48.8382715, lng: 19.6425696 },
    { lat: 48.8384554, lng: 19.6422277 },
    { lat: 48.8384036, lng: 19.6414984 },
    { lat: 48.838321, lng: 19.6414886 },
    { lat: 48.8383454, lng: 19.6410258 },
    { lat: 48.8383066, lng: 19.6405877 },
    { lat: 48.8376728, lng: 19.6397737 },
    { lat: 48.8376046, lng: 19.639334 },
    { lat: 48.837202, lng: 19.6393468 },
    { lat: 48.8370912, lng: 19.6385091 },
    { lat: 48.8370484, lng: 19.6369279 },
    { lat: 48.8369203, lng: 19.6362562 },
    { lat: 48.8366562, lng: 19.6356971 },
    { lat: 48.8369912, lng: 19.6353275 },
    { lat: 48.8371881, lng: 19.6355737 },
    { lat: 48.8377005, lng: 19.6365446 },
    { lat: 48.8393386, lng: 19.6348336 },
    { lat: 48.8390455, lng: 19.6344521 },
    { lat: 48.8387075, lng: 19.6343284 },
    { lat: 48.8382551, lng: 19.6343429 },
    { lat: 48.8381086, lng: 19.6344141 },
    { lat: 48.8377121, lng: 19.6339656 },
    { lat: 48.8381591, lng: 19.632579 },
    { lat: 48.8374737, lng: 19.6319153 },
    { lat: 48.8376921, lng: 19.6316381 },
    { lat: 48.8375854, lng: 19.6312957 },
    { lat: 48.8372685, lng: 19.630612 },
    { lat: 48.8367986, lng: 19.630484 },
    { lat: 48.8364048, lng: 19.6295189 },
    { lat: 48.8364742, lng: 19.6291802 },
    { lat: 48.8365802, lng: 19.6278312 },
    { lat: 48.8370274, lng: 19.6256719 },
    { lat: 48.8366647, lng: 19.6257962 },
    { lat: 48.8371453, lng: 19.6238821 },
    { lat: 48.8358506, lng: 19.6226512 },
    { lat: 48.8360579, lng: 19.6214505 },
    { lat: 48.836977, lng: 19.6221193 },
    { lat: 48.8373997, lng: 19.6215763 },
    { lat: 48.8375075, lng: 19.6210634 },
    { lat: 48.837772, lng: 19.6206083 },
    { lat: 48.8379392, lng: 19.620841 },
    { lat: 48.8383453, lng: 19.620626 },
    { lat: 48.8388274, lng: 19.6198204 },
    { lat: 48.8383822, lng: 19.6195187 },
    { lat: 48.8386021, lng: 19.617895 },
    { lat: 48.8386471, lng: 19.6172161 },
    { lat: 48.8394837, lng: 19.6172806 },
    { lat: 48.839389, lng: 19.6160055 },
    { lat: 48.8395713, lng: 19.6150209 },
    { lat: 48.8397657, lng: 19.6131618 },
    { lat: 48.8389521, lng: 19.6118316 },
    { lat: 48.8386609, lng: 19.6127463 },
    { lat: 48.8383371, lng: 19.6128607 },
    { lat: 48.8378603, lng: 19.6125859 },
    { lat: 48.8374883, lng: 19.611855 },
    { lat: 48.8378317, lng: 19.6115752 },
    { lat: 48.8378369, lng: 19.6114115 },
    { lat: 48.8372297, lng: 19.6112665 },
    { lat: 48.8369287, lng: 19.6106878 },
    { lat: 48.8362853, lng: 19.6108141 },
    { lat: 48.8361009, lng: 19.6104473 },
    { lat: 48.8361645, lng: 19.6100079 },
    { lat: 48.8365268, lng: 19.6088477 },
    { lat: 48.836507, lng: 19.6083371 },
    { lat: 48.8356424, lng: 19.6089231 },
    { lat: 48.8355494, lng: 19.608888 },
    { lat: 48.8356054, lng: 19.6086522 },
    { lat: 48.8352045, lng: 19.6084344 },
    { lat: 48.835028, lng: 19.6081944 },
    { lat: 48.8350986, lng: 19.608058 },
    { lat: 48.8350785, lng: 19.6077657 },
    { lat: 48.8339932, lng: 19.6078627 },
    { lat: 48.8324366, lng: 19.6083457 },
    { lat: 48.8322764, lng: 19.6081906 },
    { lat: 48.8318707, lng: 19.6080691 },
    { lat: 48.8315684, lng: 19.6080572 },
    { lat: 48.8313121, lng: 19.6078981 },
    { lat: 48.830997, lng: 19.6080312 },
    { lat: 48.8305711, lng: 19.6080599 },
    { lat: 48.8304985, lng: 19.6082719 },
    { lat: 48.8300064, lng: 19.6083007 },
    { lat: 48.8297799, lng: 19.6083055 },
    { lat: 48.8295283, lng: 19.6082193 },
    { lat: 48.8291, lng: 19.6078416 },
    { lat: 48.8286192, lng: 19.6082272 },
    { lat: 48.8284665, lng: 19.6080885 },
    { lat: 48.8282172, lng: 19.6079834 },
    { lat: 48.8277282, lng: 19.6080565 },
    { lat: 48.8277761, lng: 19.6078477 },
    { lat: 48.8275667, lng: 19.6073237 },
    { lat: 48.827479, lng: 19.6069769 },
    { lat: 48.8275218, lng: 19.6068781 },
    { lat: 48.8273426, lng: 19.6066814 },
    { lat: 48.8274715, lng: 19.6062618 },
    { lat: 48.8269392, lng: 19.6055802 },
    { lat: 48.8267295, lng: 19.6050766 },
    { lat: 48.8264861, lng: 19.6041294 },
    { lat: 48.8263055, lng: 19.603645 },
    { lat: 48.8259771, lng: 19.6036343 },
    { lat: 48.8257879, lng: 19.6040097 },
    { lat: 48.8253254, lng: 19.6043977 },
    { lat: 48.8251423, lng: 19.6042865 },
    { lat: 48.8240072, lng: 19.6040279 },
    { lat: 48.8236932, lng: 19.6038601 },
    { lat: 48.8233878, lng: 19.6035867 },
    { lat: 48.822881, lng: 19.6033185 },
    { lat: 48.8229169, lng: 19.6032128 },
    { lat: 48.8222659, lng: 19.6025969 },
    { lat: 48.8221354, lng: 19.6026283 },
    { lat: 48.8218707, lng: 19.6024301 },
    { lat: 48.8218301, lng: 19.6022537 },
    { lat: 48.8203113, lng: 19.6017592 },
    { lat: 48.8202775, lng: 19.6016601 },
    { lat: 48.8200323, lng: 19.6016934 },
    { lat: 48.8190859, lng: 19.603015 },
    { lat: 48.8184709, lng: 19.602321 },
    { lat: 48.8182614, lng: 19.6023974 },
    { lat: 48.8182587, lng: 19.6029666 },
    { lat: 48.8181512, lng: 19.603105 },
    { lat: 48.8181152, lng: 19.603258 },
    { lat: 48.8180917, lng: 19.6033575 },
    { lat: 48.8178237, lng: 19.6033606 },
    { lat: 48.8175675, lng: 19.6032262 },
    { lat: 48.8173417, lng: 19.6034652 },
    { lat: 48.8168794, lng: 19.6019131 },
    { lat: 48.8166915, lng: 19.6019175 },
    { lat: 48.8159828, lng: 19.5997524 },
    { lat: 48.8156417, lng: 19.5990076 },
    { lat: 48.8152296, lng: 19.5984207 },
    { lat: 48.8127824, lng: 19.598043 },
    { lat: 48.8121977, lng: 19.5980722 },
    { lat: 48.8122374, lng: 19.5989983 },
    { lat: 48.8119357, lng: 19.5989527 },
    { lat: 48.8118795, lng: 19.600504 },
    { lat: 48.8115858, lng: 19.6005155 },
    { lat: 48.8113895, lng: 19.6002827 },
    { lat: 48.8112495, lng: 19.6002522 },
    { lat: 48.8093882, lng: 19.6002327 },
    { lat: 48.8092325, lng: 19.6018711 },
    { lat: 48.8090089, lng: 19.6018331 },
    { lat: 48.8086519, lng: 19.6042599 },
    { lat: 48.8084709, lng: 19.6049936 },
    { lat: 48.8081267, lng: 19.6048533 },
    { lat: 48.8071619, lng: 19.6041986 },
    { lat: 48.8065324, lng: 19.6043563 },
    { lat: 48.806038, lng: 19.6043138 },
    { lat: 48.8058153, lng: 19.6039734 },
    { lat: 48.8056566, lng: 19.6032041 },
    { lat: 48.8055323, lng: 19.6030196 },
    { lat: 48.8055106, lng: 19.602816 },
    { lat: 48.8052262, lng: 19.6019564 },
    { lat: 48.8048836, lng: 19.6015801 },
    { lat: 48.8041792, lng: 19.6011819 },
    { lat: 48.8039069, lng: 19.6016888 },
    { lat: 48.8038319, lng: 19.6013491 },
    { lat: 48.8032398, lng: 19.6013096 },
    { lat: 48.8028092, lng: 19.6012077 },
    { lat: 48.8015312, lng: 19.6017268 },
    { lat: 48.8012873, lng: 19.6007927 },
    { lat: 48.8009519, lng: 19.6007259 },
    { lat: 48.7997569, lng: 19.5996659 },
    { lat: 48.8000945, lng: 19.599285 },
    { lat: 48.8000877, lng: 19.5990079 },
    { lat: 48.7993261, lng: 19.5990118 },
    { lat: 48.7984052, lng: 19.5969109 },
    { lat: 48.7972412, lng: 19.5950701 },
    { lat: 48.7963961, lng: 19.5956652 },
    { lat: 48.7947589, lng: 19.5973617 },
    { lat: 48.7941475, lng: 19.5968486 },
    { lat: 48.7937268, lng: 19.5975123 },
    { lat: 48.7933818, lng: 19.5982539 },
    { lat: 48.7931257, lng: 19.5985484 },
    { lat: 48.7929773, lng: 19.5985499 },
    { lat: 48.7923455, lng: 19.5987858 },
    { lat: 48.7913023, lng: 19.5968691 },
    { lat: 48.7901271, lng: 19.595753 },
    { lat: 48.7890823, lng: 19.5951208 },
    { lat: 48.7889607, lng: 19.5947775 },
    { lat: 48.7888595, lng: 19.594257 },
    { lat: 48.788408, lng: 19.593944 },
    { lat: 48.7882942, lng: 19.5944661 },
    { lat: 48.7878047, lng: 19.5944035 },
    { lat: 48.7874476, lng: 19.5935622 },
    { lat: 48.7872054, lng: 19.5934365 },
    { lat: 48.786387, lng: 19.5946734 },
    { lat: 48.7862776, lng: 19.5951936 },
    { lat: 48.7861635, lng: 19.5954641 },
    { lat: 48.7859796, lng: 19.5963558 },
    { lat: 48.7858617, lng: 19.5965638 },
    { lat: 48.7856063, lng: 19.5967418 },
    { lat: 48.7855684, lng: 19.5964313 },
    { lat: 48.7852254, lng: 19.5965955 },
    { lat: 48.7851612, lng: 19.5969194 },
    { lat: 48.7845744, lng: 19.5971224 },
    { lat: 48.7840047, lng: 19.5974116 },
    { lat: 48.7840291, lng: 19.5975473 },
    { lat: 48.783856, lng: 19.597611 },
    { lat: 48.7832958, lng: 19.5975543 },
    { lat: 48.7827924, lng: 19.5971856 },
    { lat: 48.7826588, lng: 19.5972955 },
    { lat: 48.7822679, lng: 19.5970013 },
    { lat: 48.7821505, lng: 19.5970456 },
    { lat: 48.7817098, lng: 19.5968465 },
    { lat: 48.7815489, lng: 19.5968753 },
    { lat: 48.7815071, lng: 19.5970472 },
    { lat: 48.7824091, lng: 19.5978045 },
    { lat: 48.7824437, lng: 19.5981203 },
    { lat: 48.7827314, lng: 19.598351 },
    { lat: 48.7828211, lng: 19.5984932 },
    { lat: 48.7828617, lng: 19.598768 },
    { lat: 48.782746, lng: 19.5989262 },
    { lat: 48.7827717, lng: 19.5990707 },
    { lat: 48.7821799, lng: 19.5996637 },
    { lat: 48.7820997, lng: 19.5992582 },
    { lat: 48.7819854, lng: 19.5990496 },
    { lat: 48.7819848, lng: 19.5984137 },
    { lat: 48.7813444, lng: 19.5978351 },
    { lat: 48.7812697, lng: 19.5979743 },
    { lat: 48.7815553, lng: 19.5983624 },
    { lat: 48.7818911, lng: 19.5991315 },
    { lat: 48.7819876, lng: 19.5996979 },
    { lat: 48.7816775, lng: 19.5990433 },
    { lat: 48.7809701, lng: 19.5992988 },
    { lat: 48.7805828, lng: 19.5996268 },
    { lat: 48.7802487, lng: 19.6000134 },
    { lat: 48.780047, lng: 19.6004148 },
    { lat: 48.7800115, lng: 19.6007518 },
    { lat: 48.7796868, lng: 19.6014449 },
    { lat: 48.7791623, lng: 19.6018569 },
    { lat: 48.7792755, lng: 19.6025375 },
    { lat: 48.7792595, lng: 19.6028114 },
    { lat: 48.7789482, lng: 19.6027504 },
    { lat: 48.7790161, lng: 19.6023618 },
    { lat: 48.7789089, lng: 19.6023308 },
    { lat: 48.7787298, lng: 19.6021553 },
    { lat: 48.778536, lng: 19.6024805 },
    { lat: 48.7784565, lng: 19.6023929 },
    { lat: 48.7782011, lng: 19.602598 },
    { lat: 48.7780611, lng: 19.6032677 },
    { lat: 48.778064, lng: 19.603505 },
    { lat: 48.7782718, lng: 19.6035928 },
    { lat: 48.7789, lng: 19.60358 },
    { lat: 48.7790302, lng: 19.6037441 },
    { lat: 48.7793421, lng: 19.6039637 },
    { lat: 48.7794256, lng: 19.6039668 },
    { lat: 48.779526, lng: 19.6038175 },
    { lat: 48.779643, lng: 19.6048115 },
    { lat: 48.7800206, lng: 19.6053623 },
    { lat: 48.7794874, lng: 19.6069033 },
    { lat: 48.7798067, lng: 19.6071869 },
    { lat: 48.7793281, lng: 19.608117 },
    { lat: 48.7787675, lng: 19.6083032 },
    { lat: 48.7786664, lng: 19.60801 },
    { lat: 48.7784964, lng: 19.6080147 },
    { lat: 48.778236, lng: 19.6074883 },
    { lat: 48.7780551, lng: 19.607597 },
    { lat: 48.7774898, lng: 19.6073092 },
    { lat: 48.7770621, lng: 19.6077415 },
    { lat: 48.7772683, lng: 19.6084179 },
    { lat: 48.7773858, lng: 19.609022 },
    { lat: 48.7767343, lng: 19.6093651 },
    { lat: 48.7767205, lng: 19.6090336 },
    { lat: 48.7762033, lng: 19.6091353 },
    { lat: 48.7757665, lng: 19.6090951 },
    { lat: 48.7757068, lng: 19.609869 },
    { lat: 48.7747466, lng: 19.6096631 },
    { lat: 48.7748923, lng: 19.609149 },
    { lat: 48.7742967, lng: 19.6089598 },
    { lat: 48.774141, lng: 19.6091777 },
    { lat: 48.7739502, lng: 19.6091442 },
    { lat: 48.7738545, lng: 19.6085989 },
    { lat: 48.7736068, lng: 19.6087513 },
    { lat: 48.7734306, lng: 19.6085449 },
    { lat: 48.7734893, lng: 19.6083198 },
    { lat: 48.7738089, lng: 19.6077634 },
    { lat: 48.7737494, lng: 19.607417 },
    { lat: 48.7734765, lng: 19.6074131 },
    { lat: 48.7730383, lng: 19.6079149 },
    { lat: 48.7732704, lng: 19.6085896 },
    { lat: 48.7735211, lng: 19.6090224 },
    { lat: 48.7735777, lng: 19.6093556 },
    { lat: 48.7734436, lng: 19.6104765 },
    { lat: 48.7738404, lng: 19.61108 },
    { lat: 48.7739224, lng: 19.6113483 },
    { lat: 48.7739449, lng: 19.6117004 },
    { lat: 48.7743482, lng: 19.6119452 },
    { lat: 48.7745708, lng: 19.6130065 },
    { lat: 48.7745193, lng: 19.613164 },
    { lat: 48.7748933, lng: 19.613613 },
    { lat: 48.7746149, lng: 19.6142101 },
    { lat: 48.7749417, lng: 19.6147122 },
    { lat: 48.7743814, lng: 19.6157282 },
    { lat: 48.7745007, lng: 19.6158502 },
    { lat: 48.7743299, lng: 19.6167584 },
    { lat: 48.7738616, lng: 19.618171 },
    { lat: 48.7736518, lng: 19.618043 },
    { lat: 48.7734678, lng: 19.6193819 },
    { lat: 48.7732393, lng: 19.6205886 },
    { lat: 48.7736736, lng: 19.6213976 },
    { lat: 48.7739107, lng: 19.6203611 },
    { lat: 48.7741879, lng: 19.6205126 },
    { lat: 48.7744582, lng: 19.6208051 },
    { lat: 48.7743652, lng: 19.6213467 },
    { lat: 48.7736771, lng: 19.6232513 },
    { lat: 48.7729913, lng: 19.6254892 },
    { lat: 48.7728394, lng: 19.6253195 },
    { lat: 48.7725013, lng: 19.6266675 },
    { lat: 48.7721033, lng: 19.6264902 },
    { lat: 48.771946, lng: 19.6278285 },
    { lat: 48.772345, lng: 19.6278799 },
    { lat: 48.77227, lng: 19.6284163 },
    { lat: 48.7721546, lng: 19.6287072 },
    { lat: 48.7720638, lng: 19.6296914 },
    { lat: 48.7724844, lng: 19.6295255 },
    { lat: 48.7724228, lng: 19.6298566 },
    { lat: 48.7723105, lng: 19.6300682 },
    { lat: 48.7720609, lng: 19.6302304 },
    { lat: 48.7718952, lng: 19.6306564 },
    { lat: 48.7720634, lng: 19.6305848 },
    { lat: 48.7723962, lng: 19.6306706 },
    { lat: 48.772921, lng: 19.6309645 },
    { lat: 48.7734598, lng: 19.6314202 },
    { lat: 48.7735869, lng: 19.6312378 },
    { lat: 48.7746024, lng: 19.6323247 },
    { lat: 48.7744457, lng: 19.6328002 },
    { lat: 48.7755747, lng: 19.6337272 },
    { lat: 48.7752898, lng: 19.6347789 },
    { lat: 48.7744446, lng: 19.6362323 },
    { lat: 48.7743134, lng: 19.6359446 },
    { lat: 48.7740547, lng: 19.6362359 },
    { lat: 48.7742373, lng: 19.6364851 },
    { lat: 48.7726981, lng: 19.6375896 },
    { lat: 48.7722263, lng: 19.6367926 },
    { lat: 48.7720823, lng: 19.637047 },
    { lat: 48.7712781, lng: 19.6358748 },
    { lat: 48.7714853, lng: 19.6340338 },
    { lat: 48.7710086, lng: 19.6345129 },
    { lat: 48.7705022, lng: 19.6350347 },
    { lat: 48.770263, lng: 19.6357052 },
    { lat: 48.7696847, lng: 19.636024 },
    { lat: 48.7696844, lng: 19.6364441 },
    { lat: 48.7696102, lng: 19.6367457 },
    { lat: 48.7695225, lng: 19.6384064 },
    { lat: 48.7693791, lng: 19.6384515 },
    { lat: 48.7695247, lng: 19.639611 },
    { lat: 48.7694474, lng: 19.6399979 },
    { lat: 48.7692816, lng: 19.6404189 },
    { lat: 48.7689267, lng: 19.6399432 },
    { lat: 48.7686499, lng: 19.6394005 },
    { lat: 48.7683356, lng: 19.640404 },
    { lat: 48.7681982, lng: 19.6406838 },
    { lat: 48.7679814, lng: 19.6407254 },
    { lat: 48.7669635, lng: 19.6412918 },
    { lat: 48.7668141, lng: 19.6409714 },
    { lat: 48.766418, lng: 19.6411367 },
    { lat: 48.7665201, lng: 19.6415545 },
    { lat: 48.7658225, lng: 19.6419994 },
    { lat: 48.7659469, lng: 19.642488 },
    { lat: 48.7665767, lng: 19.6438953 },
    { lat: 48.7666815, lng: 19.6440681 },
    { lat: 48.7667878, lng: 19.6441272 },
    { lat: 48.7672231, lng: 19.6449401 },
    { lat: 48.7672205, lng: 19.6450579 },
    { lat: 48.7671039, lng: 19.645247 },
    { lat: 48.7669635, lng: 19.6452068 },
    { lat: 48.767114, lng: 19.645646 },
    { lat: 48.7671852, lng: 19.6461798 },
    { lat: 48.7672218, lng: 19.6466965 },
    { lat: 48.7671815, lng: 19.6469303 },
    { lat: 48.7672826, lng: 19.6468935 },
    { lat: 48.767582, lng: 19.6473682 },
    { lat: 48.7676392, lng: 19.6475387 },
    { lat: 48.76748, lng: 19.6477058 },
    { lat: 48.7671526, lng: 19.6483723 },
    { lat: 48.767066, lng: 19.6484458 },
    { lat: 48.7666387, lng: 19.6492222 },
    { lat: 48.766779, lng: 19.6493864 },
    { lat: 48.7666933, lng: 19.6494859 },
    { lat: 48.7667808, lng: 19.6497005 },
    { lat: 48.7670525, lng: 19.6509371 },
    { lat: 48.7673293, lng: 19.650828 },
    { lat: 48.7674704, lng: 19.6508575 },
    { lat: 48.7677775, lng: 19.6507727 },
    { lat: 48.7676537, lng: 19.6516073 },
    { lat: 48.7679788, lng: 19.6517976 },
    { lat: 48.7677809, lng: 19.6524076 },
    { lat: 48.7676951, lng: 19.6524056 },
    { lat: 48.7676224, lng: 19.6529699 },
    { lat: 48.7673417, lng: 19.6529183 },
    { lat: 48.7672873, lng: 19.6542194 },
    { lat: 48.7680733, lng: 19.6543339 },
    { lat: 48.7684266, lng: 19.6544481 },
    { lat: 48.7686429, lng: 19.6548492 },
    { lat: 48.7690564, lng: 19.6545228 },
    { lat: 48.7700595, lng: 19.6544522 },
    { lat: 48.7700481, lng: 19.6549313 },
    { lat: 48.7694122, lng: 19.6553472 },
    { lat: 48.7694318, lng: 19.6556631 },
    { lat: 48.7690548, lng: 19.6564511 },
    { lat: 48.7691061, lng: 19.6574217 },
    { lat: 48.768851, lng: 19.6600531 },
    { lat: 48.7686187, lng: 19.6617388 },
    { lat: 48.7685607, lng: 19.6617258 },
    { lat: 48.7683455, lng: 19.6620822 },
    { lat: 48.7681205, lng: 19.6623145 },
    { lat: 48.7678937, lng: 19.6624602 },
    { lat: 48.7681948, lng: 19.6626405 },
    { lat: 48.7684153, lng: 19.6624551 },
    { lat: 48.7686111, lng: 19.6625515 },
    { lat: 48.7684771, lng: 19.6629077 },
    { lat: 48.768294, lng: 19.6631701 },
    { lat: 48.7684277, lng: 19.6636679 },
    { lat: 48.7683394, lng: 19.6640373 },
    { lat: 48.7686754, lng: 19.664498 },
    { lat: 48.7684567, lng: 19.6648718 },
    { lat: 48.7685564, lng: 19.6651106 },
    { lat: 48.7673684, lng: 19.6667925 },
    { lat: 48.767421, lng: 19.6668811 },
    { lat: 48.7666705, lng: 19.6678414 },
    { lat: 48.7669814, lng: 19.6682943 },
    { lat: 48.7668404, lng: 19.6686162 },
    { lat: 48.7666219, lng: 19.6683175 },
    { lat: 48.766505, lng: 19.6688294 },
    { lat: 48.7666437, lng: 19.6690335 },
    { lat: 48.7670143, lng: 19.6693328 },
    { lat: 48.7676921, lng: 19.6681088 },
    { lat: 48.7679787, lng: 19.6678667 },
    { lat: 48.7681269, lng: 19.6680808 },
    { lat: 48.7688554, lng: 19.6672113 },
    { lat: 48.7691077, lng: 19.6677163 },
    { lat: 48.7687968, lng: 19.6677913 },
    { lat: 48.7699079, lng: 19.6689804 },
    { lat: 48.769746, lng: 19.6695231 },
    { lat: 48.7699714, lng: 19.6701298 },
    { lat: 48.7695383, lng: 19.6703958 },
    { lat: 48.7694771, lng: 19.6710197 },
    { lat: 48.7699509, lng: 19.6717117 },
    { lat: 48.7699475, lng: 19.6723182 },
    { lat: 48.76948, lng: 19.6723224 },
    { lat: 48.7694189, lng: 19.6728487 },
    { lat: 48.7692629, lng: 19.6727324 },
    { lat: 48.7689685, lng: 19.6727503 },
    { lat: 48.7685726, lng: 19.6730944 },
    { lat: 48.7682842, lng: 19.6741082 },
    { lat: 48.768123, lng: 19.6745114 },
    { lat: 48.7680007, lng: 19.6754272 },
    { lat: 48.7675308, lng: 19.6758816 },
    { lat: 48.7673998, lng: 19.6765075 },
    { lat: 48.7672334, lng: 19.6764131 },
    { lat: 48.7672645, lng: 19.6769364 },
    { lat: 48.7673529, lng: 19.6769234 },
    { lat: 48.7673659, lng: 19.6771146 },
    { lat: 48.7670709, lng: 19.6777269 },
    { lat: 48.7670061, lng: 19.6782164 },
    { lat: 48.7667809, lng: 19.67835 },
    { lat: 48.7666364, lng: 19.6789212 },
    { lat: 48.766573, lng: 19.6788391 },
    { lat: 48.7664319, lng: 19.6789364 },
    { lat: 48.7662815, lng: 19.67986 },
    { lat: 48.7660977, lng: 19.6801326 },
    { lat: 48.7659292, lng: 19.6812309 },
    { lat: 48.7659564, lng: 19.6817678 },
    { lat: 48.7659068, lng: 19.6825779 },
    { lat: 48.7659473, lng: 19.6828031 },
    { lat: 48.766344, lng: 19.6828312 },
    { lat: 48.7663601, lng: 19.6834678 },
    { lat: 48.7664009, lng: 19.683579 },
    { lat: 48.7663192, lng: 19.6846038 },
    { lat: 48.7662518, lng: 19.684861 },
    { lat: 48.7663036, lng: 19.6851372 },
    { lat: 48.7660965, lng: 19.685444 },
    { lat: 48.7660495, lng: 19.6858588 },
    { lat: 48.7659078, lng: 19.6858358 },
    { lat: 48.7658674, lng: 19.6862699 },
    { lat: 48.7656146, lng: 19.6864899 },
    { lat: 48.765479, lng: 19.6863087 },
    { lat: 48.7653479, lng: 19.6867796 },
    { lat: 48.7651063, lng: 19.6872238 },
    { lat: 48.7648553, lng: 19.6873558 },
    { lat: 48.7648029, lng: 19.6875683 },
    { lat: 48.7647106, lng: 19.6876994 },
    { lat: 48.7647127, lng: 19.6881257 },
    { lat: 48.7645522, lng: 19.6889002 },
    { lat: 48.7643298, lng: 19.6887886 },
    { lat: 48.7641442, lng: 19.6892232 },
    { lat: 48.7639655, lng: 19.6891088 },
    { lat: 48.763908, lng: 19.6893375 },
    { lat: 48.7642592, lng: 19.6896336 },
    { lat: 48.7642175, lng: 19.6899653 },
    { lat: 48.7640529, lng: 19.6899574 },
    { lat: 48.7638831, lng: 19.6904993 },
    { lat: 48.7639025, lng: 19.6906317 },
    { lat: 48.7637986, lng: 19.6910572 },
    { lat: 48.7638958, lng: 19.691188 },
    { lat: 48.7633149, lng: 19.6920371 },
    { lat: 48.7631797, lng: 19.6924412 },
    { lat: 48.7626289, lng: 19.6929529 },
    { lat: 48.7625963, lng: 19.6932855 },
    { lat: 48.762305, lng: 19.693473 },
    { lat: 48.762222, lng: 19.6939124 },
    { lat: 48.7618705, lng: 19.6945496 },
    { lat: 48.7617971, lng: 19.6949752 },
    { lat: 48.7618152, lng: 19.6953309 },
    { lat: 48.7617716, lng: 19.6954438 },
    { lat: 48.7616168, lng: 19.6953814 },
    { lat: 48.7614727, lng: 19.6955871 },
    { lat: 48.7613894, lng: 19.6959702 },
    { lat: 48.7617166, lng: 19.6961097 },
    { lat: 48.76154, lng: 19.6968002 },
    { lat: 48.7613154, lng: 19.6970162 },
    { lat: 48.7613801, lng: 19.6971821 },
    { lat: 48.7612128, lng: 19.6977251 },
    { lat: 48.7612579, lng: 19.6981576 },
    { lat: 48.7608689, lng: 19.6985839 },
    { lat: 48.7606461, lng: 19.6993783 },
    { lat: 48.7606147, lng: 19.6998357 },
    { lat: 48.7604892, lng: 19.700158 },
    { lat: 48.760025, lng: 19.7000827 },
    { lat: 48.7598652, lng: 19.7005681 },
    { lat: 48.7592279, lng: 19.7003 },
    { lat: 48.7592086, lng: 19.7001684 },
    { lat: 48.7584752, lng: 19.7017948 },
    { lat: 48.7575848, lng: 19.7026022 },
    { lat: 48.7575378, lng: 19.7040817 },
    { lat: 48.7570588, lng: 19.7062861 },
    { lat: 48.7567481, lng: 19.7074634 },
    { lat: 48.7569152, lng: 19.7086666 },
    { lat: 48.7567343, lng: 19.709748 },
    { lat: 48.7558971, lng: 19.7114207 },
    { lat: 48.7551646, lng: 19.7120309 },
    { lat: 48.7551671, lng: 19.7135125 },
    { lat: 48.7549723, lng: 19.7150426 },
    { lat: 48.7538394, lng: 19.7159942 },
    { lat: 48.7539005, lng: 19.7185974 },
    { lat: 48.7540485, lng: 19.7197836 },
    { lat: 48.7545515, lng: 19.7210942 },
    { lat: 48.754627, lng: 19.7227751 },
    { lat: 48.7546131, lng: 19.7239894 },
    { lat: 48.7540372, lng: 19.7252873 },
    { lat: 48.7533821, lng: 19.7263464 },
    { lat: 48.752908, lng: 19.7285987 },
    { lat: 48.7518971, lng: 19.7319637 },
    { lat: 48.7516473, lng: 19.7330183 },
    { lat: 48.7514543, lng: 19.7341081 },
    { lat: 48.7506118, lng: 19.735418 },
    { lat: 48.7502454, lng: 19.73636 },
    { lat: 48.7501097, lng: 19.7365494 },
    { lat: 48.7502125, lng: 19.7377347 },
    { lat: 48.7504485, lng: 19.7395603 },
    { lat: 48.7503438, lng: 19.7400725 },
    { lat: 48.7504672, lng: 19.7411857 },
    { lat: 48.7501602, lng: 19.7430185 },
    { lat: 48.7498909, lng: 19.7441949 },
    { lat: 48.7501414, lng: 19.7468684 },
    { lat: 48.7505641, lng: 19.7486645 },
    { lat: 48.7500912, lng: 19.7500056 },
    { lat: 48.7495647, lng: 19.7503066 },
    { lat: 48.7486682, lng: 19.7513371 },
    { lat: 48.7485193, lng: 19.7522831 },
    { lat: 48.7468785, lng: 19.7537888 },
    { lat: 48.7457905, lng: 19.7536407 },
    { lat: 48.7458424, lng: 19.7548239 },
    { lat: 48.7459835, lng: 19.7562894 },
    { lat: 48.7459543, lng: 19.757781 },
    { lat: 48.7461847, lng: 19.7593347 },
    { lat: 48.746099, lng: 19.7601051 },
    { lat: 48.7461749, lng: 19.7609096 },
    { lat: 48.7463275, lng: 19.7615144 },
    { lat: 48.7463804, lng: 19.7624087 },
    { lat: 48.7465458, lng: 19.7623786 },
    { lat: 48.7466164, lng: 19.7622191 },
    { lat: 48.7468165, lng: 19.7620284 },
    { lat: 48.7469874, lng: 19.7626613 },
    { lat: 48.7471791, lng: 19.7630237 },
    { lat: 48.7471207, lng: 19.7633659 },
    { lat: 48.7466864, lng: 19.7638624 },
    { lat: 48.7465959, lng: 19.7638822 },
    { lat: 48.7464734, lng: 19.7640926 },
    { lat: 48.7465027, lng: 19.7642514 },
    { lat: 48.746365, lng: 19.7644219 },
    { lat: 48.7458788, lng: 19.7655391 },
    { lat: 48.7456564, lng: 19.7659444 },
    { lat: 48.7454397, lng: 19.766935 },
    { lat: 48.7455576, lng: 19.768496 },
    { lat: 48.745644, lng: 19.7686141 },
    { lat: 48.7455094, lng: 19.7691684 },
    { lat: 48.7456028, lng: 19.7697316 },
    { lat: 48.7460764, lng: 19.7704871 },
    { lat: 48.7460231, lng: 19.7706341 },
    { lat: 48.7461742, lng: 19.7709474 },
    { lat: 48.746413, lng: 19.7711277 },
    { lat: 48.7466248, lng: 19.7715114 },
    { lat: 48.7469314, lng: 19.7724431 },
    { lat: 48.7470023, lng: 19.7730054 },
    { lat: 48.7469613, lng: 19.773072 },
    { lat: 48.746288, lng: 19.7733429 },
    { lat: 48.746323, lng: 19.7734743 },
    { lat: 48.746434, lng: 19.773561 },
    { lat: 48.7464434, lng: 19.7738792 },
    { lat: 48.7463859, lng: 19.7741606 },
    { lat: 48.7462344, lng: 19.7743035 },
    { lat: 48.7460612, lng: 19.7747271 },
    { lat: 48.7456674, lng: 19.7751738 },
    { lat: 48.7456278, lng: 19.7754026 },
    { lat: 48.7457629, lng: 19.7772279 },
    { lat: 48.7458831, lng: 19.7775797 },
    { lat: 48.7459097, lng: 19.7780868 },
    { lat: 48.7455374, lng: 19.7784374 },
    { lat: 48.7454033, lng: 19.7801122 },
    { lat: 48.7458222, lng: 19.781756 },
    { lat: 48.7459087, lng: 19.7824995 },
    { lat: 48.7457085, lng: 19.7834119 },
    { lat: 48.7454098, lng: 19.7844113 },
    { lat: 48.7451887, lng: 19.7854418 },
    { lat: 48.7451189, lng: 19.7859853 },
    { lat: 48.7451448, lng: 19.786757 },
    { lat: 48.7446358, lng: 19.7884663 },
    { lat: 48.7446194, lng: 19.7892272 },
    { lat: 48.7443735, lng: 19.790485 },
    { lat: 48.7439652, lng: 19.7913539 },
    { lat: 48.7432729, lng: 19.7926358 },
    { lat: 48.7427851, lng: 19.7932023 },
    { lat: 48.7425674, lng: 19.793813 },
    { lat: 48.7424943, lng: 19.7940013 },
    { lat: 48.742605, lng: 19.7940584 },
    { lat: 48.7427761, lng: 19.793874 },
    { lat: 48.7430513, lng: 19.7934161 },
    { lat: 48.743727, lng: 19.7940911 },
    { lat: 48.7438463, lng: 19.7942103 },
    { lat: 48.744091, lng: 19.7943437 },
    { lat: 48.7444755, lng: 19.7944554 },
    { lat: 48.7449303, lng: 19.7947302 },
    { lat: 48.7455253, lng: 19.7946057 },
    { lat: 48.7461505, lng: 19.7949371 },
    { lat: 48.7470667, lng: 19.7956216 },
    { lat: 48.7476444, lng: 19.7961581 },
    { lat: 48.748183, lng: 19.7969848 },
    { lat: 48.7484279, lng: 19.797252 },
    { lat: 48.7488331, lng: 19.797247 },
    { lat: 48.7491676, lng: 19.7973187 },
    { lat: 48.7496995, lng: 19.7976106 },
    { lat: 48.7503256, lng: 19.7976004 },
    { lat: 48.7508978, lng: 19.7974956 },
    { lat: 48.7519449, lng: 19.7976341 },
    { lat: 48.7530501, lng: 19.7976858 },
    { lat: 48.7532866, lng: 19.7972441 },
    { lat: 48.753431, lng: 19.794717 },
    { lat: 48.7544985, lng: 19.7920114 },
    { lat: 48.7551382, lng: 19.7903038 },
    { lat: 48.7554121, lng: 19.7895321 },
    { lat: 48.7556312, lng: 19.788432 },
    { lat: 48.7565265, lng: 19.7863842 },
    { lat: 48.7603927, lng: 19.7791231 },
    { lat: 48.7593448, lng: 19.7789408 },
    { lat: 48.7601283, lng: 19.7759869 },
    { lat: 48.7617862, lng: 19.7764921 },
    { lat: 48.7629137, lng: 19.7743735 },
    { lat: 48.7656002, lng: 19.7686175 },
    { lat: 48.7668683, lng: 19.7660602 },
    { lat: 48.7683784, lng: 19.7649892 },
    { lat: 48.76842, lng: 19.765094 },
    { lat: 48.7685013, lng: 19.7651226 },
    { lat: 48.7684966, lng: 19.7652459 },
    { lat: 48.7685455, lng: 19.7653064 },
    { lat: 48.7685226, lng: 19.7654783 },
    { lat: 48.7686059, lng: 19.7656825 },
    { lat: 48.7684805, lng: 19.765724 },
    { lat: 48.7684747, lng: 19.7658253 },
    { lat: 48.7685481, lng: 19.7660126 },
    { lat: 48.7684993, lng: 19.7660521 },
    { lat: 48.7685397, lng: 19.7661284 },
    { lat: 48.7684462, lng: 19.7662835 },
    { lat: 48.7684338, lng: 19.7666158 },
    { lat: 48.768165, lng: 19.7668824 },
    { lat: 48.7680974, lng: 19.7670152 },
    { lat: 48.7681957, lng: 19.7671739 },
    { lat: 48.7686665, lng: 19.7675036 },
    { lat: 48.7699512, lng: 19.7689306 },
    { lat: 48.7724292, lng: 19.7699464 },
    { lat: 48.7726411, lng: 19.7701559 },
    { lat: 48.7750866, lng: 19.7715076 },
    { lat: 48.7776227, lng: 19.7724389 },
    { lat: 48.7786481, lng: 19.7733968 },
    { lat: 48.7797652, lng: 19.7740805 },
    { lat: 48.7807964, lng: 19.7741409 },
    { lat: 48.7818469, lng: 19.7737121 },
    { lat: 48.7834915, lng: 19.7732177 },
    { lat: 48.7848577, lng: 19.7727208 },
    { lat: 48.7853439, lng: 19.7724728 },
    { lat: 48.7857348, lng: 19.7724994 },
    { lat: 48.7874102, lng: 19.7705227 },
    { lat: 48.7892044, lng: 19.7693515 },
    { lat: 48.7902402, lng: 19.7689476 },
    { lat: 48.7912967, lng: 19.7687599 },
    { lat: 48.7916139, lng: 19.7687732 },
    { lat: 48.7928852, lng: 19.7691192 },
    { lat: 48.7952049, lng: 19.7708467 },
    { lat: 48.7963969, lng: 19.7718243 },
    { lat: 48.7974161, lng: 19.7729265 },
    { lat: 48.7980275, lng: 19.7733855 },
    { lat: 48.8000664, lng: 19.7741226 },
    { lat: 48.8020675, lng: 19.7750591 },
    { lat: 48.8028863, lng: 19.7755961 },
    { lat: 48.8035172, lng: 19.7758782 },
    { lat: 48.8035959, lng: 19.7755622 },
    { lat: 48.8038382, lng: 19.7746878 },
    { lat: 48.8040073, lng: 19.7732191 },
    { lat: 48.8041152, lng: 19.7722108 },
    { lat: 48.8039614, lng: 19.7711155 },
    { lat: 48.8041325, lng: 19.7702349 },
    { lat: 48.8047948, lng: 19.7698207 },
    { lat: 48.8058106, lng: 19.7695552 },
    { lat: 48.806591, lng: 19.7692612 },
    { lat: 48.8071193, lng: 19.7686667 },
    { lat: 48.8077904, lng: 19.7668038 },
    { lat: 48.8081458, lng: 19.7655043 },
    { lat: 48.8083038, lng: 19.7642725 },
    { lat: 48.8082857, lng: 19.7635498 },
    { lat: 48.8082156, lng: 19.7632115 },
    { lat: 48.8082079, lng: 19.7625711 },
    { lat: 48.8079835, lng: 19.7612797 },
    { lat: 48.8079722, lng: 19.7608716 },
    { lat: 48.8076762, lng: 19.7599517 },
    { lat: 48.8076359, lng: 19.7595979 },
    { lat: 48.8074007, lng: 19.7587291 },
    { lat: 48.80719, lng: 19.7576751 },
    { lat: 48.8069426, lng: 19.7569373 },
    { lat: 48.8065951, lng: 19.7566215 },
    { lat: 48.8061185, lng: 19.7557441 },
    { lat: 48.8058815, lng: 19.755735 },
    { lat: 48.8056647, lng: 19.7554144 },
    { lat: 48.8055184, lng: 19.7554702 },
    { lat: 48.8052639, lng: 19.7551627 },
    { lat: 48.8050974, lng: 19.7553499 },
    { lat: 48.8047675, lng: 19.7549714 },
    { lat: 48.8046406, lng: 19.7542579 },
    { lat: 48.8043419, lng: 19.7534612 },
    { lat: 48.8042097, lng: 19.7529659 },
    { lat: 48.8039176, lng: 19.7525993 },
    { lat: 48.80332, lng: 19.7520222 },
    { lat: 48.8033902, lng: 19.7519265 },
    { lat: 48.8033708, lng: 19.751818 },
    { lat: 48.803078, lng: 19.7519236 },
    { lat: 48.8030018, lng: 19.7514738 },
    { lat: 48.8030148, lng: 19.7511029 },
    { lat: 48.8031898, lng: 19.7501357 },
    { lat: 48.8028404, lng: 19.7500558 },
    { lat: 48.8031182, lng: 19.7485282 },
    { lat: 48.8026679, lng: 19.7484059 },
    { lat: 48.8020971, lng: 19.7480595 },
    { lat: 48.8022222, lng: 19.7479829 },
    { lat: 48.8019783, lng: 19.7475357 },
    { lat: 48.8018687, lng: 19.747733 },
    { lat: 48.8015292, lng: 19.7470567 },
    { lat: 48.8011931, lng: 19.7472792 },
    { lat: 48.8008789, lng: 19.7478444 },
    { lat: 48.8004999, lng: 19.7471442 },
    { lat: 48.8002992, lng: 19.7473963 },
    { lat: 48.7998215, lng: 19.7458352 },
    { lat: 48.799665, lng: 19.7460393 },
    { lat: 48.7986066, lng: 19.7457981 },
    { lat: 48.7982496, lng: 19.7458494 },
    { lat: 48.7976842, lng: 19.7465482 },
    { lat: 48.7973913, lng: 19.7462185 },
    { lat: 48.7967577, lng: 19.7450273 },
    { lat: 48.7968739, lng: 19.7445935 },
    { lat: 48.7977244, lng: 19.7432589 },
    { lat: 48.7971683, lng: 19.7429865 },
    { lat: 48.7961164, lng: 19.742227 },
    { lat: 48.7959577, lng: 19.7426396 },
    { lat: 48.7951302, lng: 19.7418336 },
    { lat: 48.7956432, lng: 19.7408953 },
    { lat: 48.7953261, lng: 19.7405278 },
    { lat: 48.7952444, lng: 19.7401783 },
    { lat: 48.795238, lng: 19.7399317 },
    { lat: 48.7949059, lng: 19.7395799 },
    { lat: 48.7949595, lng: 19.7392837 },
    { lat: 48.795036, lng: 19.7391301 },
    { lat: 48.7954018, lng: 19.7393791 },
    { lat: 48.7955957, lng: 19.7390483 },
    { lat: 48.7961411, lng: 19.7384913 },
    { lat: 48.7959751, lng: 19.7377858 },
    { lat: 48.7958986, lng: 19.737176 },
    { lat: 48.7963258, lng: 19.7365655 },
    { lat: 48.7966227, lng: 19.7371698 },
    { lat: 48.7967617, lng: 19.7372813 },
    { lat: 48.7971173, lng: 19.737374 },
    { lat: 48.7973806, lng: 19.7375962 },
    { lat: 48.7973117, lng: 19.7377722 },
    { lat: 48.7971327, lng: 19.7379821 },
    { lat: 48.7974868, lng: 19.7383724 },
    { lat: 48.7975887, lng: 19.7388967 },
    { lat: 48.7983076, lng: 19.7396901 },
    { lat: 48.7979986, lng: 19.7400944 },
    { lat: 48.7985616, lng: 19.7401606 },
    { lat: 48.7990237, lng: 19.7403466 },
    { lat: 48.7993956, lng: 19.7408098 },
    { lat: 48.7998585, lng: 19.7411674 },
    { lat: 48.8001952, lng: 19.7416241 },
    { lat: 48.80066, lng: 19.7428406 },
    { lat: 48.8005868, lng: 19.7431196 },
    { lat: 48.8006086, lng: 19.7433647 },
    { lat: 48.8009849, lng: 19.7454375 },
    { lat: 48.8014569, lng: 19.7452341 },
    { lat: 48.8016927, lng: 19.7434989 },
    { lat: 48.8014533, lng: 19.7432771 },
    { lat: 48.8015196, lng: 19.7421229 },
    { lat: 48.8017161, lng: 19.7410077 },
    { lat: 48.8011561, lng: 19.7407503 },
    { lat: 48.8015881, lng: 19.7392247 },
    { lat: 48.8021831, lng: 19.7380239 },
    { lat: 48.8019308, lng: 19.7376927 },
    { lat: 48.8024661, lng: 19.7368006 },
    { lat: 48.8029796, lng: 19.7374325 },
    { lat: 48.8036052, lng: 19.7366435 },
    { lat: 48.8037426, lng: 19.7360589 },
    { lat: 48.8030742, lng: 19.7357421 },
    { lat: 48.8030393, lng: 19.7355666 },
    { lat: 48.8027074, lng: 19.7353685 },
    { lat: 48.8028823, lng: 19.7342522 },
    { lat: 48.8030523, lng: 19.7337872 },
    { lat: 48.8034351, lng: 19.7330649 },
    { lat: 48.8031152, lng: 19.7321708 },
    { lat: 48.8032609, lng: 19.7320524 },
    { lat: 48.8031482, lng: 19.7318733 },
    { lat: 48.8033213, lng: 19.7315324 },
    { lat: 48.8035429, lng: 19.7314181 },
    { lat: 48.8039079, lng: 19.7317294 },
    { lat: 48.8040652, lng: 19.7316124 },
    { lat: 48.804684, lng: 19.7302403 },
    { lat: 48.8050666, lng: 19.7305877 },
    { lat: 48.8056589, lng: 19.7296582 },
    { lat: 48.805779, lng: 19.7293695 },
    { lat: 48.8053276, lng: 19.7294279 },
    { lat: 48.8051812, lng: 19.7289931 },
    { lat: 48.8055369, lng: 19.7279907 },
    { lat: 48.8053228, lng: 19.7277339 },
    { lat: 48.8056463, lng: 19.7272809 },
    { lat: 48.806559, lng: 19.7275893 },
    { lat: 48.8070708, lng: 19.7279526 },
    { lat: 48.8070445, lng: 19.7272271 },
    { lat: 48.8071217, lng: 19.7269866 },
    { lat: 48.8075723, lng: 19.7261665 },
    { lat: 48.8076302, lng: 19.7262272 },
    { lat: 48.807908, lng: 19.7259088 },
    { lat: 48.8086505, lng: 19.7256579 },
    { lat: 48.8089285, lng: 19.725243 },
    { lat: 48.8091232, lng: 19.7255672 },
    { lat: 48.8096334, lng: 19.72477 },
    { lat: 48.8095352, lng: 19.7246684 },
    { lat: 48.8096003, lng: 19.7242497 },
    { lat: 48.8099957, lng: 19.7233075 },
    { lat: 48.8091542, lng: 19.7222094 },
    { lat: 48.8095963, lng: 19.7220192 },
    { lat: 48.8095183, lng: 19.7217551 },
    { lat: 48.8100396, lng: 19.7213653 },
    { lat: 48.8097773, lng: 19.7207341 },
    { lat: 48.8097274, lng: 19.719705 },
    { lat: 48.8098193, lng: 19.7193383 },
    { lat: 48.8101341, lng: 19.7189661 },
    { lat: 48.810815, lng: 19.7184646 },
    { lat: 48.8106577, lng: 19.7182429 },
    { lat: 48.810946, lng: 19.7178326 },
    { lat: 48.8112245, lng: 19.7180182 },
    { lat: 48.8114942, lng: 19.7187401 },
    { lat: 48.8121349, lng: 19.7168968 },
    { lat: 48.8117662, lng: 19.7162997 },
    { lat: 48.8120501, lng: 19.7159174 },
    { lat: 48.8126619, lng: 19.7154328 },
    { lat: 48.8134049, lng: 19.7145035 },
    { lat: 48.8132216, lng: 19.7135205 },
    { lat: 48.8132139, lng: 19.71263 },
    { lat: 48.8138101, lng: 19.7108272 },
    { lat: 48.8145982, lng: 19.7104334 },
    { lat: 48.8146796, lng: 19.7099293 },
    { lat: 48.8146778, lng: 19.7094519 },
    { lat: 48.8147607, lng: 19.7086302 },
    { lat: 48.8146546, lng: 19.7079631 },
    { lat: 48.8146864, lng: 19.7077629 },
    { lat: 48.8147955, lng: 19.707635 },
    { lat: 48.8149096, lng: 19.7072319 },
    { lat: 48.8152002, lng: 19.7069265 },
    { lat: 48.815366, lng: 19.7065975 },
    { lat: 48.8152657, lng: 19.7061401 },
    { lat: 48.8152806, lng: 19.7057886 },
    { lat: 48.8153423, lng: 19.7052522 },
    { lat: 48.8155981, lng: 19.7044597 },
    { lat: 48.8159078, lng: 19.7043321 },
    { lat: 48.8160527, lng: 19.7040964 },
    { lat: 48.8161199, lng: 19.7037789 },
    { lat: 48.8159796, lng: 19.7034582 },
    { lat: 48.8158793, lng: 19.7034866 },
    { lat: 48.8158235, lng: 19.7033175 },
    { lat: 48.815892, lng: 19.7031375 },
    { lat: 48.8162675, lng: 19.7033475 },
    { lat: 48.8162431, lng: 19.7027443 },
    { lat: 48.8164611, lng: 19.7024526 },
    { lat: 48.8167398, lng: 19.7024879 },
    { lat: 48.8168658, lng: 19.7023586 },
    { lat: 48.8170658, lng: 19.7023951 },
    { lat: 48.8172757, lng: 19.7017936 },
    { lat: 48.8173773, lng: 19.7016424 },
    { lat: 48.8171857, lng: 19.7015435 },
    { lat: 48.8171283, lng: 19.7011861 },
    { lat: 48.8172113, lng: 19.7008949 },
    { lat: 48.8172892, lng: 19.701129 },
    { lat: 48.8174634, lng: 19.7010427 },
    { lat: 48.8177687, lng: 19.7001851 },
    { lat: 48.8180563, lng: 19.7005713 },
    { lat: 48.8180728, lng: 19.7008942 },
    { lat: 48.8181699, lng: 19.7009766 },
    { lat: 48.8181504, lng: 19.7013243 },
    { lat: 48.8182983, lng: 19.7014805 },
    { lat: 48.8184343, lng: 19.7018324 },
    { lat: 48.8183348, lng: 19.7020281 },
    { lat: 48.8188969, lng: 19.7023049 },
    { lat: 48.8189582, lng: 19.7021371 },
    { lat: 48.8190808, lng: 19.7021068 },
    { lat: 48.8190884, lng: 19.7022247 },
    { lat: 48.8193047, lng: 19.7022531 },
    { lat: 48.8192154, lng: 19.7024213 },
    { lat: 48.8188401, lng: 19.7027748 },
    { lat: 48.8186262, lng: 19.7032881 },
    { lat: 48.8185867, lng: 19.7035141 },
    { lat: 48.8180983, lng: 19.7044432 },
    { lat: 48.8175873, lng: 19.7055934 },
    { lat: 48.8172514, lng: 19.7064848 },
    { lat: 48.8168625, lng: 19.7072951 },
    { lat: 48.8169003, lng: 19.7073331 },
    { lat: 48.8166328, lng: 19.7081371 },
    { lat: 48.8165976, lng: 19.7081321 },
    { lat: 48.8165121, lng: 19.7083334 },
    { lat: 48.8164762, lng: 19.7087983 },
    { lat: 48.816495, lng: 19.7092489 },
    { lat: 48.8166548, lng: 19.7104375 },
    { lat: 48.8168708, lng: 19.7117087 },
    { lat: 48.8172318, lng: 19.7127875 },
    { lat: 48.817562, lng: 19.7141419 },
    { lat: 48.817723, lng: 19.7146204 },
    { lat: 48.8179208, lng: 19.7161152 },
    { lat: 48.8181093, lng: 19.716273 },
    { lat: 48.8182119, lng: 19.7167092 },
    { lat: 48.8181514, lng: 19.7170774 },
    { lat: 48.8179342, lng: 19.7173393 },
    { lat: 48.8181489, lng: 19.7178113 },
    { lat: 48.8183134, lng: 19.7186548 },
    { lat: 48.8183004, lng: 19.7190709 },
    { lat: 48.818346, lng: 19.7194406 },
    { lat: 48.8184578, lng: 19.719908 },
    { lat: 48.8188856, lng: 19.7194714 },
    { lat: 48.8192277, lng: 19.7193095 },
    { lat: 48.8199775, lng: 19.7197809 },
    { lat: 48.8204847, lng: 19.7211886 },
    { lat: 48.8207615, lng: 19.7216665 },
    { lat: 48.8210493, lng: 19.7214962 },
    { lat: 48.8211838, lng: 19.7207396 },
    { lat: 48.8210907, lng: 19.7202935 },
    { lat: 48.8210952, lng: 19.7200861 },
    { lat: 48.821243, lng: 19.7196578 },
    { lat: 48.8211403, lng: 19.7192474 },
    { lat: 48.8216902, lng: 19.7190176 },
    { lat: 48.821818, lng: 19.718799 },
    { lat: 48.8210466, lng: 19.7166919 },
    { lat: 48.8202488, lng: 19.7149014 },
    { lat: 48.8201881, lng: 19.71488 },
    { lat: 48.8201648, lng: 19.7147644 },
    { lat: 48.8195863, lng: 19.7137044 },
    { lat: 48.8192033, lng: 19.712154 },
    { lat: 48.8186203, lng: 19.7104609 },
    { lat: 48.8180013, lng: 19.7091694 },
    { lat: 48.8178285, lng: 19.7084226 },
    { lat: 48.8178018, lng: 19.7079963 },
    { lat: 48.8180189, lng: 19.7072042 },
    { lat: 48.8181037, lng: 19.7064265 },
    { lat: 48.8207718, lng: 19.7009095 },
    { lat: 48.8214075, lng: 19.6991609 },
    { lat: 48.8216814, lng: 19.6985542 },
    { lat: 48.8219662, lng: 19.6981405 },
    { lat: 48.8231779, lng: 19.6967312 },
    { lat: 48.8233221, lng: 19.6963192 },
    { lat: 48.8233946, lng: 19.6959486 },
    { lat: 48.8233241, lng: 19.695044 },
    { lat: 48.8231685, lng: 19.6939753 },
    { lat: 48.8224758, lng: 19.6914175 },
    { lat: 48.8223841, lng: 19.6911536 },
    { lat: 48.821686, lng: 19.6903784 },
    { lat: 48.8212521, lng: 19.6897683 },
    { lat: 48.8218243, lng: 19.688396 },
    { lat: 48.8219526, lng: 19.6879821 },
    { lat: 48.8231511, lng: 19.6857207 },
    { lat: 48.823594, lng: 19.6840748 },
    { lat: 48.8240795, lng: 19.6826804 },
    { lat: 48.8245546, lng: 19.6818099 },
    { lat: 48.8259719, lng: 19.6808973 },
    { lat: 48.8276251, lng: 19.6814888 },
    { lat: 48.8283167, lng: 19.6822168 },
    { lat: 48.8304775, lng: 19.6827949 },
    { lat: 48.831983, lng: 19.6834102 },
    { lat: 48.834049, lng: 19.6839079 },
    { lat: 48.8345639, lng: 19.6839595 },
    { lat: 48.8352286, lng: 19.6833506 },
    { lat: 48.8369701, lng: 19.6824808 },
    { lat: 48.837549, lng: 19.6826514 },
    { lat: 48.8378684, lng: 19.6829411 },
    { lat: 48.8383782, lng: 19.6835647 },
    { lat: 48.8394171, lng: 19.6836129 },
    { lat: 48.8400493, lng: 19.6842544 },
    { lat: 48.8411891, lng: 19.6843703 },
    { lat: 48.842729, lng: 19.6842132 },
    { lat: 48.8437607, lng: 19.6845097 },
    { lat: 48.8448759, lng: 19.6852659 },
    { lat: 48.8467543, lng: 19.6859303 },
    { lat: 48.8487629, lng: 19.6859223 },
    { lat: 48.8501217, lng: 19.6864037 },
    { lat: 48.851174, lng: 19.6870898 },
    { lat: 48.851769, lng: 19.6865852 },
    { lat: 48.852579, lng: 19.685313 },
    { lat: 48.8539254, lng: 19.6843782 },
    { lat: 48.8546604, lng: 19.6842814 },
    { lat: 48.8550708, lng: 19.6839332 },
    { lat: 48.8586443, lng: 19.6832422 },
    { lat: 48.8598403, lng: 19.6831803 },
    { lat: 48.8600902, lng: 19.6833283 },
    { lat: 48.8604679, lng: 19.6834315 },
    { lat: 48.8609718, lng: 19.683184 },
    { lat: 48.8613641, lng: 19.6831657 },
    { lat: 48.8620285, lng: 19.6828271 },
    { lat: 48.8621989, lng: 19.6827825 },
    { lat: 48.8623893, lng: 19.6828342 },
    { lat: 48.8628772, lng: 19.6825536 },
    { lat: 48.864216, lng: 19.6815205 },
    { lat: 48.8648487, lng: 19.6814627 },
    { lat: 48.8668749, lng: 19.6815207 },
    { lat: 48.8680113, lng: 19.6812833 },
    { lat: 48.8683824, lng: 19.6813667 },
    { lat: 48.8706671, lng: 19.6807837 },
    { lat: 48.8718617, lng: 19.6802301 },
    { lat: 48.8724683, lng: 19.6803431 },
    { lat: 48.8734217, lng: 19.6801677 },
    { lat: 48.8741211, lng: 19.6802931 },
    { lat: 48.8742941, lng: 19.6807376 },
    { lat: 48.8745783, lng: 19.6812519 },
    { lat: 48.8751419, lng: 19.6825981 },
    { lat: 48.8757679, lng: 19.6831201 },
    { lat: 48.8761734, lng: 19.683777 },
    { lat: 48.8765083, lng: 19.6839859 },
    { lat: 48.876573, lng: 19.684508 },
    { lat: 48.8767475, lng: 19.6850876 },
  ],
  Valaská: [
    { lat: 48.6851538, lng: 19.5536402 },
    { lat: 48.6855998, lng: 19.55393 },
    { lat: 48.6858924, lng: 19.5539965 },
    { lat: 48.6860778, lng: 19.55423 },
    { lat: 48.6864676, lng: 19.5544262 },
    { lat: 48.686829, lng: 19.5550836 },
    { lat: 48.6871064, lng: 19.5549641 },
    { lat: 48.6872699, lng: 19.5549659 },
    { lat: 48.6872982, lng: 19.5551416 },
    { lat: 48.6876955, lng: 19.5552047 },
    { lat: 48.6876111, lng: 19.5554831 },
    { lat: 48.6877231, lng: 19.555612 },
    { lat: 48.6881522, lng: 19.5554599 },
    { lat: 48.6882682, lng: 19.5551614 },
    { lat: 48.6879807, lng: 19.5548119 },
    { lat: 48.6881993, lng: 19.5548879 },
    { lat: 48.688525, lng: 19.5551328 },
    { lat: 48.6886738, lng: 19.5550867 },
    { lat: 48.6886879, lng: 19.5545577 },
    { lat: 48.6884682, lng: 19.5545104 },
    { lat: 48.6884972, lng: 19.5540851 },
    { lat: 48.6886418, lng: 19.5538963 },
    { lat: 48.6885097, lng: 19.5535673 },
    { lat: 48.6881933, lng: 19.5534498 },
    { lat: 48.6880822, lng: 19.5534839 },
    { lat: 48.6881033, lng: 19.5532053 },
    { lat: 48.6881759, lng: 19.5530169 },
    { lat: 48.6880886, lng: 19.552638 },
    { lat: 48.6884732, lng: 19.5522544 },
    { lat: 48.6883118, lng: 19.552049 },
    { lat: 48.6879996, lng: 19.5523163 },
    { lat: 48.6875102, lng: 19.5515966 },
    { lat: 48.6875939, lng: 19.5514556 },
    { lat: 48.687969, lng: 19.5512553 },
    { lat: 48.6883027, lng: 19.5509362 },
    { lat: 48.6885545, lng: 19.5513472 },
    { lat: 48.688969, lng: 19.5511926 },
    { lat: 48.6891926, lng: 19.551328 },
    { lat: 48.6892971, lng: 19.5511224 },
    { lat: 48.6892778, lng: 19.5500088 },
    { lat: 48.6893439, lng: 19.5498553 },
    { lat: 48.6898082, lng: 19.5503204 },
    { lat: 48.6905987, lng: 19.5518372 },
    { lat: 48.6909376, lng: 19.5523486 },
    { lat: 48.6913215, lng: 19.5525136 },
    { lat: 48.6915408, lng: 19.5521355 },
    { lat: 48.6924406, lng: 19.5524154 },
    { lat: 48.6925799, lng: 19.5522104 },
    { lat: 48.69251, lng: 19.5520383 },
    { lat: 48.6921537, lng: 19.5517598 },
    { lat: 48.6919444, lng: 19.5513508 },
    { lat: 48.691981, lng: 19.5512364 },
    { lat: 48.6923269, lng: 19.5513608 },
    { lat: 48.6924678, lng: 19.5512152 },
    { lat: 48.6926359, lng: 19.5515907 },
    { lat: 48.6931367, lng: 19.5518427 },
    { lat: 48.6935024, lng: 19.5517322 },
    { lat: 48.6936603, lng: 19.5519593 },
    { lat: 48.6940368, lng: 19.5522803 },
    { lat: 48.6944499, lng: 19.5517303 },
    { lat: 48.6943664, lng: 19.5515318 },
    { lat: 48.6946075, lng: 19.5511346 },
    { lat: 48.6947406, lng: 19.5512345 },
    { lat: 48.6947772, lng: 19.5515196 },
    { lat: 48.6949142, lng: 19.551659 },
    { lat: 48.6952146, lng: 19.5515724 },
    { lat: 48.6952377, lng: 19.5512408 },
    { lat: 48.6950602, lng: 19.55098 },
    { lat: 48.695136, lng: 19.5507431 },
    { lat: 48.6953104, lng: 19.5507092 },
    { lat: 48.695746, lng: 19.5503826 },
    { lat: 48.6957112, lng: 19.5502832 },
    { lat: 48.6955675, lng: 19.5501494 },
    { lat: 48.6956136, lng: 19.5499249 },
    { lat: 48.6954884, lng: 19.5497985 },
    { lat: 48.6956056, lng: 19.549625 },
    { lat: 48.6954906, lng: 19.5493317 },
    { lat: 48.695136, lng: 19.5491422 },
    { lat: 48.6951003, lng: 19.5489751 },
    { lat: 48.6953177, lng: 19.548727 },
    { lat: 48.6953945, lng: 19.5485117 },
    { lat: 48.6956685, lng: 19.5482174 },
    { lat: 48.6960022, lng: 19.5482196 },
    { lat: 48.6961513, lng: 19.5479586 },
    { lat: 48.6963452, lng: 19.5481078 },
    { lat: 48.6967693, lng: 19.5488051 },
    { lat: 48.6970307, lng: 19.5489027 },
    { lat: 48.6971631, lng: 19.5487686 },
    { lat: 48.6970388, lng: 19.5479961 },
    { lat: 48.697141, lng: 19.5477679 },
    { lat: 48.6972826, lng: 19.5476115 },
    { lat: 48.6974146, lng: 19.5476373 },
    { lat: 48.6976057, lng: 19.5472378 },
    { lat: 48.6976526, lng: 19.5469814 },
    { lat: 48.6976062, lng: 19.5465307 },
    { lat: 48.6970511, lng: 19.5462227 },
    { lat: 48.6966727, lng: 19.546721 },
    { lat: 48.6968051, lng: 19.5472251 },
    { lat: 48.6967082, lng: 19.547593 },
    { lat: 48.69678, lng: 19.5477342 },
    { lat: 48.6969268, lng: 19.5476911 },
    { lat: 48.696964, lng: 19.5479158 },
    { lat: 48.6969246, lng: 19.5480605 },
    { lat: 48.6966206, lng: 19.5481675 },
    { lat: 48.6965333, lng: 19.5480286 },
    { lat: 48.696593, lng: 19.5477124 },
    { lat: 48.6964959, lng: 19.5475838 },
    { lat: 48.6964136, lng: 19.5475813 },
    { lat: 48.6963224, lng: 19.5477799 },
    { lat: 48.6960103, lng: 19.5476584 },
    { lat: 48.6957166, lng: 19.547449 },
    { lat: 48.6953919, lng: 19.5475403 },
    { lat: 48.6950644, lng: 19.5477985 },
    { lat: 48.6949505, lng: 19.5476988 },
    { lat: 48.694636, lng: 19.5478647 },
    { lat: 48.6946857, lng: 19.5482119 },
    { lat: 48.6943502, lng: 19.5483946 },
    { lat: 48.6943316, lng: 19.5486534 },
    { lat: 48.6941641, lng: 19.5486355 },
    { lat: 48.6942224, lng: 19.5479393 },
    { lat: 48.6939394, lng: 19.5466583 },
    { lat: 48.6928007, lng: 19.5463728 },
    { lat: 48.692591, lng: 19.5462221 },
    { lat: 48.6926405, lng: 19.5459012 },
    { lat: 48.6922773, lng: 19.5456474 },
    { lat: 48.6923019, lng: 19.5454726 },
    { lat: 48.6926581, lng: 19.5448814 },
    { lat: 48.6925723, lng: 19.5444831 },
    { lat: 48.6926482, lng: 19.5444958 },
    { lat: 48.6927037, lng: 19.5443527 },
    { lat: 48.6924817, lng: 19.5441084 },
    { lat: 48.6921097, lng: 19.5439322 },
    { lat: 48.6920697, lng: 19.5436457 },
    { lat: 48.6917818, lng: 19.5435891 },
    { lat: 48.6917944, lng: 19.5434194 },
    { lat: 48.6917279, lng: 19.5433968 },
    { lat: 48.6917833, lng: 19.5432505 },
    { lat: 48.691717, lng: 19.5431839 },
    { lat: 48.6917703, lng: 19.5430421 },
    { lat: 48.6922145, lng: 19.5432193 },
    { lat: 48.6923721, lng: 19.5434111 },
    { lat: 48.6927185, lng: 19.5435468 },
    { lat: 48.6932004, lng: 19.543286 },
    { lat: 48.6936494, lng: 19.5432013 },
    { lat: 48.6938713, lng: 19.5428553 },
    { lat: 48.694244, lng: 19.5431716 },
    { lat: 48.6943178, lng: 19.5429567 },
    { lat: 48.694727, lng: 19.5432353 },
    { lat: 48.6948076, lng: 19.5431741 },
    { lat: 48.6949224, lng: 19.5429241 },
    { lat: 48.6950214, lng: 19.5429905 },
    { lat: 48.6950869, lng: 19.5427974 },
    { lat: 48.6950983, lng: 19.5425822 },
    { lat: 48.6953519, lng: 19.5423856 },
    { lat: 48.6951358, lng: 19.5421442 },
    { lat: 48.6952374, lng: 19.5419745 },
    { lat: 48.695215, lng: 19.5416858 },
    { lat: 48.6955083, lng: 19.541373 },
    { lat: 48.6956248, lng: 19.5416012 },
    { lat: 48.6958486, lng: 19.5414975 },
    { lat: 48.6957423, lng: 19.5408477 },
    { lat: 48.6958859, lng: 19.5406111 },
    { lat: 48.6957878, lng: 19.5401905 },
    { lat: 48.6958895, lng: 19.5401738 },
    { lat: 48.6960782, lng: 19.5406021 },
    { lat: 48.6962436, lng: 19.5408406 },
    { lat: 48.6965395, lng: 19.5410782 },
    { lat: 48.6967211, lng: 19.5414505 },
    { lat: 48.6969998, lng: 19.5415348 },
    { lat: 48.6978925, lng: 19.5430414 },
    { lat: 48.6979235, lng: 19.5432236 },
    { lat: 48.6982451, lng: 19.5437037 },
    { lat: 48.6983053, lng: 19.5440063 },
    { lat: 48.6992778, lng: 19.5457315 },
    { lat: 48.7006133, lng: 19.5468184 },
    { lat: 48.7009584, lng: 19.5475379 },
    { lat: 48.7010935, lng: 19.5476833 },
    { lat: 48.7011879, lng: 19.5481657 },
    { lat: 48.701349, lng: 19.548415 },
    { lat: 48.7017703, lng: 19.5493967 },
    { lat: 48.7020881, lng: 19.5499386 },
    { lat: 48.7022969, lng: 19.5501205 },
    { lat: 48.7031176, lng: 19.5504939 },
    { lat: 48.7035142, lng: 19.5509099 },
    { lat: 48.7035424, lng: 19.5516501 },
    { lat: 48.7040193, lng: 19.5519999 },
    { lat: 48.7047494, lng: 19.5536775 },
    { lat: 48.706369, lng: 19.5558023 },
    { lat: 48.7063967, lng: 19.5561858 },
    { lat: 48.7069696, lng: 19.557014 },
    { lat: 48.7073205, lng: 19.5572921 },
    { lat: 48.7083461, lng: 19.55959 },
    { lat: 48.7086742, lng: 19.5618171 },
    { lat: 48.7092499, lng: 19.5629781 },
    { lat: 48.7098717, lng: 19.5638842 },
    { lat: 48.7104198, lng: 19.5650566 },
    { lat: 48.7103248, lng: 19.5652467 },
    { lat: 48.7112752, lng: 19.5668861 },
    { lat: 48.7114165, lng: 19.566946 },
    { lat: 48.712736, lng: 19.5682049 },
    { lat: 48.7128708, lng: 19.5684224 },
    { lat: 48.7134474, lng: 19.5689095 },
    { lat: 48.7137913, lng: 19.5690874 },
    { lat: 48.7140219, lng: 19.5696451 },
    { lat: 48.7143864, lng: 19.5696311 },
    { lat: 48.7149843, lng: 19.5700941 },
    { lat: 48.7160778, lng: 19.5707197 },
    { lat: 48.7165731, lng: 19.5712788 },
    { lat: 48.7172345, lng: 19.5716475 },
    { lat: 48.7177382, lng: 19.5721464 },
    { lat: 48.7185482, lng: 19.572484 },
    { lat: 48.7187346, lng: 19.5727933 },
    { lat: 48.7187697, lng: 19.5732668 },
    { lat: 48.7189595, lng: 19.5735982 },
    { lat: 48.7193432, lng: 19.5738838 },
    { lat: 48.7196346, lng: 19.5743716 },
    { lat: 48.7197628, lng: 19.5758496 },
    { lat: 48.7197276, lng: 19.5764885 },
    { lat: 48.7187794, lng: 19.5794968 },
    { lat: 48.7185077, lng: 19.5813789 },
    { lat: 48.7187761, lng: 19.5832431 },
    { lat: 48.7191017, lng: 19.5841361 },
    { lat: 48.7192267, lng: 19.5848047 },
    { lat: 48.7194941, lng: 19.5857055 },
    { lat: 48.7197524, lng: 19.5881917 },
    { lat: 48.7201386, lng: 19.58898 },
    { lat: 48.7205243, lng: 19.5899813 },
    { lat: 48.7208587, lng: 19.5906236 },
    { lat: 48.7212498, lng: 19.5917915 },
    { lat: 48.7218592, lng: 19.5932008 },
    { lat: 48.7226336, lng: 19.5941052 },
    { lat: 48.7226559, lng: 19.5943741 },
    { lat: 48.7230272, lng: 19.594734 },
    { lat: 48.7231734, lng: 19.5955229 },
    { lat: 48.7227274, lng: 19.5984887 },
    { lat: 48.7230323, lng: 19.5998718 },
    { lat: 48.7233334, lng: 19.6004716 },
    { lat: 48.7236473, lng: 19.600735 },
    { lat: 48.7239689, lng: 19.6013413 },
    { lat: 48.7242702, lng: 19.6021238 },
    { lat: 48.7242876, lng: 19.6023087 },
    { lat: 48.7247488, lng: 19.6024148 },
    { lat: 48.7246736, lng: 19.6030686 },
    { lat: 48.7247357, lng: 19.6030964 },
    { lat: 48.7247717, lng: 19.603421 },
    { lat: 48.7246146, lng: 19.60356 },
    { lat: 48.7245424, lng: 19.6041053 },
    { lat: 48.7249256, lng: 19.6040111 },
    { lat: 48.7251348, lng: 19.6046472 },
    { lat: 48.7251, lng: 19.6047747 },
    { lat: 48.725206, lng: 19.6049736 },
    { lat: 48.7253735, lng: 19.6055655 },
    { lat: 48.7259333, lng: 19.6052874 },
    { lat: 48.725933, lng: 19.605729 },
    { lat: 48.7257026, lng: 19.6063634 },
    { lat: 48.7258268, lng: 19.6066193 },
    { lat: 48.7261195, lng: 19.6067372 },
    { lat: 48.7263691, lng: 19.6070708 },
    { lat: 48.7268798, lng: 19.6081509 },
    { lat: 48.7272976, lng: 19.6083672 },
    { lat: 48.7274904, lng: 19.6088723 },
    { lat: 48.7276781, lng: 19.6087619 },
    { lat: 48.7274957, lng: 19.6082418 },
    { lat: 48.7270756, lng: 19.6073965 },
    { lat: 48.7277045, lng: 19.6071527 },
    { lat: 48.7279992, lng: 19.6078375 },
    { lat: 48.7280332, lng: 19.6082666 },
    { lat: 48.7282844, lng: 19.6093099 },
    { lat: 48.7286415, lng: 19.6092334 },
    { lat: 48.7289965, lng: 19.6088863 },
    { lat: 48.7289176, lng: 19.6087599 },
    { lat: 48.7291376, lng: 19.6083192 },
    { lat: 48.7293169, lng: 19.608169 },
    { lat: 48.7295854, lng: 19.6082023 },
    { lat: 48.7297939, lng: 19.608578 },
    { lat: 48.7303362, lng: 19.6090356 },
    { lat: 48.7301272, lng: 19.6090461 },
    { lat: 48.7298748, lng: 19.6094951 },
    { lat: 48.7298029, lng: 19.6098017 },
    { lat: 48.7299187, lng: 19.610142 },
    { lat: 48.7302847, lng: 19.6103623 },
    { lat: 48.7302348, lng: 19.6105273 },
    { lat: 48.7305185, lng: 19.6107761 },
    { lat: 48.7302627, lng: 19.611622 },
    { lat: 48.7303907, lng: 19.6118368 },
    { lat: 48.7308562, lng: 19.6119648 },
    { lat: 48.7310437, lng: 19.6115524 },
    { lat: 48.7311366, lng: 19.611162 },
    { lat: 48.7312465, lng: 19.6111478 },
    { lat: 48.7313966, lng: 19.6108078 },
    { lat: 48.7316022, lng: 19.6107695 },
    { lat: 48.7317904, lng: 19.6108436 },
    { lat: 48.7318599, lng: 19.6109856 },
    { lat: 48.7323055, lng: 19.611122 },
    { lat: 48.7326495, lng: 19.611478 },
    { lat: 48.7329705, lng: 19.612096 },
    { lat: 48.7334412, lng: 19.6115934 },
    { lat: 48.7332805, lng: 19.6112541 },
    { lat: 48.733341, lng: 19.6110462 },
    { lat: 48.7333554, lng: 19.610743 },
    { lat: 48.7332563, lng: 19.6104424 },
    { lat: 48.7335812, lng: 19.6102014 },
    { lat: 48.7338765, lng: 19.6106014 },
    { lat: 48.734773, lng: 19.611569 },
    { lat: 48.7354873, lng: 19.6121871 },
    { lat: 48.7360081, lng: 19.6124193 },
    { lat: 48.7361877, lng: 19.6118521 },
    { lat: 48.7363278, lng: 19.6112115 },
    { lat: 48.7370055, lng: 19.6115992 },
    { lat: 48.7374693, lng: 19.6120146 },
    { lat: 48.7376294, lng: 19.6120402 },
    { lat: 48.7378552, lng: 19.611404 },
    { lat: 48.7379545, lng: 19.6109582 },
    { lat: 48.7378888, lng: 19.6106748 },
    { lat: 48.7381424, lng: 19.6107996 },
    { lat: 48.7387401, lng: 19.6108118 },
    { lat: 48.7387754, lng: 19.6114932 },
    { lat: 48.739136, lng: 19.6120907 },
    { lat: 48.7395766, lng: 19.6126931 },
    { lat: 48.7396245, lng: 19.6127417 },
    { lat: 48.7400844, lng: 19.613208 },
    { lat: 48.7404083, lng: 19.6121453 },
    { lat: 48.7409733, lng: 19.6123551 },
    { lat: 48.7415802, lng: 19.611697 },
    { lat: 48.7416643, lng: 19.6120896 },
    { lat: 48.7417935, lng: 19.6123258 },
    { lat: 48.7420139, lng: 19.612966 },
    { lat: 48.742494, lng: 19.6131453 },
    { lat: 48.7427028, lng: 19.6131259 },
    { lat: 48.7430057, lng: 19.6128754 },
    { lat: 48.7431712, lng: 19.6125452 },
    { lat: 48.7433818, lng: 19.6124921 },
    { lat: 48.743503, lng: 19.6132571 },
    { lat: 48.743669, lng: 19.6137638 },
    { lat: 48.7438198, lng: 19.613831 },
    { lat: 48.7439298, lng: 19.6142745 },
    { lat: 48.7441791, lng: 19.6143784 },
    { lat: 48.7445303, lng: 19.61437 },
    { lat: 48.7446163, lng: 19.6146028 },
    { lat: 48.7448647, lng: 19.6145918 },
    { lat: 48.7452491, lng: 19.6143719 },
    { lat: 48.745481, lng: 19.6143411 },
    { lat: 48.746128, lng: 19.6144193 },
    { lat: 48.7466413, lng: 19.6140593 },
    { lat: 48.7466906, lng: 19.6143252 },
    { lat: 48.7468592, lng: 19.614195 },
    { lat: 48.7475621, lng: 19.6126675 },
    { lat: 48.7477967, lng: 19.6127211 },
    { lat: 48.7480527, lng: 19.6123251 },
    { lat: 48.7482722, lng: 19.6123696 },
    { lat: 48.7483322, lng: 19.6139987 },
    { lat: 48.7492128, lng: 19.6151614 },
    { lat: 48.7494179, lng: 19.615311 },
    { lat: 48.7499345, lng: 19.6154212 },
    { lat: 48.7502712, lng: 19.6155946 },
    { lat: 48.7507063, lng: 19.6156704 },
    { lat: 48.7506524, lng: 19.6160259 },
    { lat: 48.7507103, lng: 19.6170108 },
    { lat: 48.7506854, lng: 19.6173804 },
    { lat: 48.7508445, lng: 19.6179389 },
    { lat: 48.7513118, lng: 19.6173526 },
    { lat: 48.7518305, lng: 19.6171895 },
    { lat: 48.7521548, lng: 19.6173328 },
    { lat: 48.7525126, lng: 19.6172194 },
    { lat: 48.7526436, lng: 19.6171774 },
    { lat: 48.7528811, lng: 19.6167469 },
    { lat: 48.753451, lng: 19.6166677 },
    { lat: 48.7536693, lng: 19.617659 },
    { lat: 48.7535658, lng: 19.6185156 },
    { lat: 48.753306, lng: 19.6194255 },
    { lat: 48.7535487, lng: 19.6198332 },
    { lat: 48.7536459, lng: 19.6204385 },
    { lat: 48.753918, lng: 19.6208296 },
    { lat: 48.7542333, lng: 19.6209985 },
    { lat: 48.7551458, lng: 19.6202949 },
    { lat: 48.7554629, lng: 19.6197812 },
    { lat: 48.7560236, lng: 19.6197966 },
    { lat: 48.7576039, lng: 19.6214564 },
    { lat: 48.7582836, lng: 19.6224391 },
    { lat: 48.7585953, lng: 19.6232048 },
    { lat: 48.7588028, lng: 19.6231563 },
    { lat: 48.7593048, lng: 19.6223192 },
    { lat: 48.7595933, lng: 19.622397 },
    { lat: 48.7597846, lng: 19.622313 },
    { lat: 48.7603023, lng: 19.6214518 },
    { lat: 48.7614544, lng: 19.6235144 },
    { lat: 48.7624247, lng: 19.625393 },
    { lat: 48.763054, lng: 19.6255311 },
    { lat: 48.763458, lng: 19.6254741 },
    { lat: 48.7639978, lng: 19.6252496 },
    { lat: 48.7647006, lng: 19.6245389 },
    { lat: 48.7651131, lng: 19.6242925 },
    { lat: 48.7656726, lng: 19.6236558 },
    { lat: 48.7658073, lng: 19.6235694 },
    { lat: 48.7658508, lng: 19.6236082 },
    { lat: 48.7659831, lng: 19.6232692 },
    { lat: 48.7664484, lng: 19.6225422 },
    { lat: 48.7667316, lng: 19.6221983 },
    { lat: 48.7669532, lng: 19.6215937 },
    { lat: 48.7675704, lng: 19.6194351 },
    { lat: 48.7674197, lng: 19.6184445 },
    { lat: 48.7674811, lng: 19.6174073 },
    { lat: 48.7679345, lng: 19.6167383 },
    { lat: 48.7683499, lng: 19.6156085 },
    { lat: 48.769717, lng: 19.614165 },
    { lat: 48.7699007, lng: 19.6137667 },
    { lat: 48.7703885, lng: 19.6130647 },
    { lat: 48.7708896, lng: 19.6121621 },
    { lat: 48.7710618, lng: 19.6116549 },
    { lat: 48.7716056, lng: 19.6106 },
    { lat: 48.7718965, lng: 19.609903 },
    { lat: 48.7720819, lng: 19.6090154 },
    { lat: 48.7719079, lng: 19.6062628 },
    { lat: 48.7719438, lng: 19.6056611 },
    { lat: 48.7720955, lng: 19.6050774 },
    { lat: 48.7723306, lng: 19.604498 },
    { lat: 48.7726884, lng: 19.6041584 },
    { lat: 48.7729246, lng: 19.6041025 },
    { lat: 48.773733, lng: 19.6046355 },
    { lat: 48.7739089, lng: 19.6051881 },
    { lat: 48.7741623, lng: 19.6055856 },
    { lat: 48.7744121, lng: 19.6058531 },
    { lat: 48.7748599, lng: 19.6060125 },
    { lat: 48.7751474, lng: 19.605917 },
    { lat: 48.7757836, lng: 19.605559 },
    { lat: 48.7759653, lng: 19.6052623 },
    { lat: 48.7760785, lng: 19.6048733 },
    { lat: 48.7760214, lng: 19.6043841 },
    { lat: 48.7755096, lng: 19.6019156 },
    { lat: 48.7753229, lng: 19.601374 },
    { lat: 48.7752643, lng: 19.6007513 },
    { lat: 48.7753334, lng: 19.5999343 },
    { lat: 48.7749062, lng: 19.5988107 },
    { lat: 48.774767, lng: 19.5982499 },
    { lat: 48.7747445, lng: 19.5977525 },
    { lat: 48.7747785, lng: 19.597356 },
    { lat: 48.7749162, lng: 19.5969127 },
    { lat: 48.7751048, lng: 19.5966984 },
    { lat: 48.7753827, lng: 19.5965935 },
    { lat: 48.7760736, lng: 19.5968453 },
    { lat: 48.776903, lng: 19.5974163 },
    { lat: 48.77739, lng: 19.5974498 },
    { lat: 48.7785069, lng: 19.5973795 },
    { lat: 48.778856, lng: 19.5971004 },
    { lat: 48.7791102, lng: 19.5966896 },
    { lat: 48.7793973, lng: 19.5955948 },
    { lat: 48.7794884, lng: 19.5930744 },
    { lat: 48.7798, lng: 19.5921918 },
    { lat: 48.7799726, lng: 19.5920453 },
    { lat: 48.7801937, lng: 19.592226 },
    { lat: 48.7808011, lng: 19.5923777 },
    { lat: 48.7811189, lng: 19.5923791 },
    { lat: 48.7817495, lng: 19.5921679 },
    { lat: 48.7826562, lng: 19.592235 },
    { lat: 48.7830086, lng: 19.5919826 },
    { lat: 48.7832373, lng: 19.5915055 },
    { lat: 48.7835397, lng: 19.5917396 },
    { lat: 48.7837913, lng: 19.5920962 },
    { lat: 48.7863401, lng: 19.5932154 },
    { lat: 48.7872054, lng: 19.5934365 },
    { lat: 48.7874476, lng: 19.5935622 },
    { lat: 48.7878047, lng: 19.5944035 },
    { lat: 48.7882942, lng: 19.5944661 },
    { lat: 48.788408, lng: 19.593944 },
    { lat: 48.7888595, lng: 19.594257 },
    { lat: 48.7889607, lng: 19.5947775 },
    { lat: 48.7890823, lng: 19.5951208 },
    { lat: 48.7901271, lng: 19.595753 },
    { lat: 48.7913023, lng: 19.5968691 },
    { lat: 48.7923455, lng: 19.5987858 },
    { lat: 48.7929773, lng: 19.5985499 },
    { lat: 48.7931257, lng: 19.5985484 },
    { lat: 48.7933818, lng: 19.5982539 },
    { lat: 48.7937268, lng: 19.5975123 },
    { lat: 48.7941475, lng: 19.5968486 },
    { lat: 48.7947589, lng: 19.5973617 },
    { lat: 48.7963961, lng: 19.5956652 },
    { lat: 48.7972412, lng: 19.5950701 },
    { lat: 48.7984052, lng: 19.5969109 },
    { lat: 48.7993261, lng: 19.5990118 },
    { lat: 48.8000877, lng: 19.5990079 },
    { lat: 48.8000945, lng: 19.599285 },
    { lat: 48.7997569, lng: 19.5996659 },
    { lat: 48.8009519, lng: 19.6007259 },
    { lat: 48.8012873, lng: 19.6007927 },
    { lat: 48.8015312, lng: 19.6017268 },
    { lat: 48.8028092, lng: 19.6012077 },
    { lat: 48.8032398, lng: 19.6013096 },
    { lat: 48.8038319, lng: 19.6013491 },
    { lat: 48.8039069, lng: 19.6016888 },
    { lat: 48.8041792, lng: 19.6011819 },
    { lat: 48.8048836, lng: 19.6015801 },
    { lat: 48.8052262, lng: 19.6019564 },
    { lat: 48.8055106, lng: 19.602816 },
    { lat: 48.8055323, lng: 19.6030196 },
    { lat: 48.8056566, lng: 19.6032041 },
    { lat: 48.8058153, lng: 19.6039734 },
    { lat: 48.806038, lng: 19.6043138 },
    { lat: 48.8065324, lng: 19.6043563 },
    { lat: 48.8071619, lng: 19.6041986 },
    { lat: 48.8081267, lng: 19.6048533 },
    { lat: 48.8084709, lng: 19.6049936 },
    { lat: 48.8086519, lng: 19.6042599 },
    { lat: 48.8090089, lng: 19.6018331 },
    { lat: 48.8092325, lng: 19.6018711 },
    { lat: 48.8093882, lng: 19.6002327 },
    { lat: 48.8112495, lng: 19.6002522 },
    { lat: 48.8113895, lng: 19.6002827 },
    { lat: 48.8115858, lng: 19.6005155 },
    { lat: 48.8118795, lng: 19.600504 },
    { lat: 48.8119357, lng: 19.5989527 },
    { lat: 48.8122374, lng: 19.5989983 },
    { lat: 48.8121977, lng: 19.5980722 },
    { lat: 48.8127824, lng: 19.598043 },
    { lat: 48.8152296, lng: 19.5984207 },
    { lat: 48.8156417, lng: 19.5990076 },
    { lat: 48.8159828, lng: 19.5997524 },
    { lat: 48.8166915, lng: 19.6019175 },
    { lat: 48.8168794, lng: 19.6019131 },
    { lat: 48.8173417, lng: 19.6034652 },
    { lat: 48.8175675, lng: 19.6032262 },
    { lat: 48.8178237, lng: 19.6033606 },
    { lat: 48.8180917, lng: 19.6033575 },
    { lat: 48.8181152, lng: 19.603258 },
    { lat: 48.8181512, lng: 19.603105 },
    { lat: 48.8182587, lng: 19.6029666 },
    { lat: 48.8182614, lng: 19.6023974 },
    { lat: 48.8184709, lng: 19.602321 },
    { lat: 48.8190859, lng: 19.603015 },
    { lat: 48.8200323, lng: 19.6016934 },
    { lat: 48.8202775, lng: 19.6016601 },
    { lat: 48.8203113, lng: 19.6017592 },
    { lat: 48.8218301, lng: 19.6022537 },
    { lat: 48.8218707, lng: 19.6024301 },
    { lat: 48.8221354, lng: 19.6026283 },
    { lat: 48.8222659, lng: 19.6025969 },
    { lat: 48.8229169, lng: 19.6032128 },
    { lat: 48.822881, lng: 19.6033185 },
    { lat: 48.8233878, lng: 19.6035867 },
    { lat: 48.8236932, lng: 19.6038601 },
    { lat: 48.8240072, lng: 19.6040279 },
    { lat: 48.8251423, lng: 19.6042865 },
    { lat: 48.8253254, lng: 19.6043977 },
    { lat: 48.8257879, lng: 19.6040097 },
    { lat: 48.8259771, lng: 19.6036343 },
    { lat: 48.8263055, lng: 19.603645 },
    { lat: 48.8264861, lng: 19.6041294 },
    { lat: 48.8267295, lng: 19.6050766 },
    { lat: 48.8269392, lng: 19.6055802 },
    { lat: 48.8274715, lng: 19.6062618 },
    { lat: 48.8273426, lng: 19.6066814 },
    { lat: 48.8275218, lng: 19.6068781 },
    { lat: 48.827479, lng: 19.6069769 },
    { lat: 48.8275667, lng: 19.6073237 },
    { lat: 48.8277761, lng: 19.6078477 },
    { lat: 48.8277282, lng: 19.6080565 },
    { lat: 48.8282172, lng: 19.6079834 },
    { lat: 48.8284665, lng: 19.6080885 },
    { lat: 48.8286192, lng: 19.6082272 },
    { lat: 48.8291, lng: 19.6078416 },
    { lat: 48.8295283, lng: 19.6082193 },
    { lat: 48.8297799, lng: 19.6083055 },
    { lat: 48.8300064, lng: 19.6083007 },
    { lat: 48.8304985, lng: 19.6082719 },
    { lat: 48.8305711, lng: 19.6080599 },
    { lat: 48.830997, lng: 19.6080312 },
    { lat: 48.8313121, lng: 19.6078981 },
    { lat: 48.8315684, lng: 19.6080572 },
    { lat: 48.8318707, lng: 19.6080691 },
    { lat: 48.8322764, lng: 19.6081906 },
    { lat: 48.8324366, lng: 19.6083457 },
    { lat: 48.8339932, lng: 19.6078627 },
    { lat: 48.8350785, lng: 19.6077657 },
    { lat: 48.8350986, lng: 19.608058 },
    { lat: 48.835028, lng: 19.6081944 },
    { lat: 48.8352045, lng: 19.6084344 },
    { lat: 48.8356054, lng: 19.6086522 },
    { lat: 48.8355494, lng: 19.608888 },
    { lat: 48.8356424, lng: 19.6089231 },
    { lat: 48.836507, lng: 19.6083371 },
    { lat: 48.8373182, lng: 19.6075857 },
    { lat: 48.8382189, lng: 19.6069459 },
    { lat: 48.8391352, lng: 19.6069502 },
    { lat: 48.8401184, lng: 19.6060392 },
    { lat: 48.8404369, lng: 19.606007 },
    { lat: 48.8412913, lng: 19.605539 },
    { lat: 48.8418434, lng: 19.6048136 },
    { lat: 48.8418236, lng: 19.6047244 },
    { lat: 48.8417616, lng: 19.6043506 },
    { lat: 48.8417742, lng: 19.6039289 },
    { lat: 48.8420229, lng: 19.6034696 },
    { lat: 48.8412495, lng: 19.6009416 },
    { lat: 48.8412432, lng: 19.6004522 },
    { lat: 48.8410743, lng: 19.5993576 },
    { lat: 48.8410497, lng: 19.5982387 },
    { lat: 48.8409422, lng: 19.5976053 },
    { lat: 48.840944, lng: 19.5970495 },
    { lat: 48.841351, lng: 19.596704 },
    { lat: 48.8412438, lng: 19.5963792 },
    { lat: 48.8411757, lng: 19.5959434 },
    { lat: 48.8416322, lng: 19.5957465 },
    { lat: 48.8415572, lng: 19.5952567 },
    { lat: 48.8419189, lng: 19.595158 },
    { lat: 48.8420208, lng: 19.5955942 },
    { lat: 48.8423968, lng: 19.5953882 },
    { lat: 48.8424972, lng: 19.5955137 },
    { lat: 48.8426745, lng: 19.5964225 },
    { lat: 48.8425908, lng: 19.596765 },
    { lat: 48.8428312, lng: 19.5967236 },
    { lat: 48.8435958, lng: 19.5959631 },
    { lat: 48.8429365, lng: 19.5943427 },
    { lat: 48.8428234, lng: 19.5942063 },
    { lat: 48.840939, lng: 19.5907364 },
    { lat: 48.8407153, lng: 19.5906183 },
    { lat: 48.8403772, lng: 19.5902703 },
    { lat: 48.8402429, lng: 19.5897177 },
    { lat: 48.8390911, lng: 19.5880689 },
    { lat: 48.8390791, lng: 19.5878311 },
    { lat: 48.8386994, lng: 19.5875061 },
    { lat: 48.8374743, lng: 19.5861841 },
    { lat: 48.8372713, lng: 19.5857598 },
    { lat: 48.8371337, lng: 19.5841856 },
    { lat: 48.8372019, lng: 19.5834027 },
    { lat: 48.8373545, lng: 19.5830917 },
    { lat: 48.8370743, lng: 19.5826262 },
    { lat: 48.8363656, lng: 19.580987 },
    { lat: 48.8355137, lng: 19.5796706 },
    { lat: 48.8353575, lng: 19.5789343 },
    { lat: 48.8354988, lng: 19.5785462 },
    { lat: 48.8355273, lng: 19.5781805 },
    { lat: 48.8338516, lng: 19.5744771 },
    { lat: 48.8336734, lng: 19.5735829 },
    { lat: 48.8337673, lng: 19.5732187 },
    { lat: 48.8363294, lng: 19.5687717 },
    { lat: 48.8352532, lng: 19.5675792 },
    { lat: 48.8345785, lng: 19.5658007 },
    { lat: 48.8333816, lng: 19.5639155 },
    { lat: 48.8324501, lng: 19.563895 },
    { lat: 48.8322087, lng: 19.563618 },
    { lat: 48.8317035, lng: 19.5633145 },
    { lat: 48.8274072, lng: 19.5597747 },
    { lat: 48.8265494, lng: 19.5589574 },
    { lat: 48.826088, lng: 19.5582781 },
    { lat: 48.8276037, lng: 19.5552866 },
    { lat: 48.8267215, lng: 19.5539501 },
    { lat: 48.8263227, lng: 19.5530134 },
    { lat: 48.8254436, lng: 19.5517031 },
    { lat: 48.8251553, lng: 19.5510406 },
    { lat: 48.8243592, lng: 19.5503074 },
    { lat: 48.823898, lng: 19.5496605 },
    { lat: 48.823151, lng: 19.5477847 },
    { lat: 48.8224309, lng: 19.5467716 },
    { lat: 48.822355, lng: 19.5466393 },
    { lat: 48.8222497, lng: 19.54663 },
    { lat: 48.8215639, lng: 19.5462201 },
    { lat: 48.8210093, lng: 19.545548 },
    { lat: 48.8209744, lng: 19.5453539 },
    { lat: 48.8207577, lng: 19.5451735 },
    { lat: 48.820586, lng: 19.5450895 },
    { lat: 48.8201885, lng: 19.5450464 },
    { lat: 48.8192977, lng: 19.5452034 },
    { lat: 48.8185586, lng: 19.545034 },
    { lat: 48.8182021, lng: 19.5450242 },
    { lat: 48.8175846, lng: 19.5452 },
    { lat: 48.8169172, lng: 19.5455595 },
    { lat: 48.8165432, lng: 19.5455658 },
    { lat: 48.8157885, lng: 19.545445 },
    { lat: 48.8147035, lng: 19.5457456 },
    { lat: 48.8143661, lng: 19.5459836 },
    { lat: 48.8140009, lng: 19.5461259 },
    { lat: 48.8138604, lng: 19.5460845 },
    { lat: 48.8137245, lng: 19.545921 },
    { lat: 48.8135816, lng: 19.5454604 },
    { lat: 48.8121789, lng: 19.5458351 },
    { lat: 48.8113655, lng: 19.5457114 },
    { lat: 48.8107083, lng: 19.5469632 },
    { lat: 48.8106683, lng: 19.5472717 },
    { lat: 48.8100352, lng: 19.5489292 },
    { lat: 48.8096745, lng: 19.5509497 },
    { lat: 48.8086874, lng: 19.5529008 },
    { lat: 48.8087518, lng: 19.5530807 },
    { lat: 48.8092975, lng: 19.5536028 },
    { lat: 48.8092048, lng: 19.5542678 },
    { lat: 48.8089387, lng: 19.5542384 },
    { lat: 48.8089017, lng: 19.5543635 },
    { lat: 48.8083835, lng: 19.5542207 },
    { lat: 48.8082723, lng: 19.554697 },
    { lat: 48.8091341, lng: 19.5551622 },
    { lat: 48.8093965, lng: 19.5555784 },
    { lat: 48.8095467, lng: 19.555956 },
    { lat: 48.8096627, lng: 19.556449 },
    { lat: 48.8097584, lng: 19.5579791 },
    { lat: 48.8095891, lng: 19.5573851 },
    { lat: 48.8093691, lng: 19.5571057 },
    { lat: 48.8090412, lng: 19.5568589 },
    { lat: 48.8077442, lng: 19.5600728 },
    { lat: 48.807816, lng: 19.5603838 },
    { lat: 48.8078056, lng: 19.5605304 },
    { lat: 48.8080066, lng: 19.5608826 },
    { lat: 48.8081256, lng: 19.5609767 },
    { lat: 48.8081884, lng: 19.5611625 },
    { lat: 48.8079314, lng: 19.5615089 },
    { lat: 48.807867, lng: 19.561407 },
    { lat: 48.8077079, lng: 19.5615784 },
    { lat: 48.8075952, lng: 19.5622197 },
    { lat: 48.8066474, lng: 19.5609555 },
    { lat: 48.8059382, lng: 19.5605226 },
    { lat: 48.8050142, lng: 19.5603767 },
    { lat: 48.8050895, lng: 19.5609665 },
    { lat: 48.8048086, lng: 19.5606623 },
    { lat: 48.8047987, lng: 19.5607145 },
    { lat: 48.8046965, lng: 19.5612649 },
    { lat: 48.8047325, lng: 19.5617345 },
    { lat: 48.8044941, lng: 19.5631824 },
    { lat: 48.8044692, lng: 19.5640937 },
    { lat: 48.804575, lng: 19.5648101 },
    { lat: 48.8051483, lng: 19.5674825 },
    { lat: 48.8051837, lng: 19.5684597 },
    { lat: 48.8051455, lng: 19.5687811 },
    { lat: 48.8048387, lng: 19.5697149 },
    { lat: 48.8043087, lng: 19.5707771 },
    { lat: 48.8039957, lng: 19.5713506 },
    { lat: 48.8036142, lng: 19.5718089 },
    { lat: 48.803568, lng: 19.5719828 },
    { lat: 48.8033659, lng: 19.5723416 },
    { lat: 48.8034038, lng: 19.5723867 },
    { lat: 48.8036408, lng: 19.5722447 },
    { lat: 48.8040708, lng: 19.5721099 },
    { lat: 48.804269, lng: 19.5719351 },
    { lat: 48.8043649, lng: 19.5713632 },
    { lat: 48.8044177, lng: 19.5717624 },
    { lat: 48.8044019, lng: 19.5725004 },
    { lat: 48.8043827, lng: 19.5726497 },
    { lat: 48.8043149, lng: 19.5726474 },
    { lat: 48.8043393, lng: 19.5727586 },
    { lat: 48.8046111, lng: 19.5729412 },
    { lat: 48.8031081, lng: 19.5776352 },
    { lat: 48.7999483, lng: 19.5813016 },
    { lat: 48.7997677, lng: 19.5817457 },
    { lat: 48.7983502, lng: 19.583109 },
    { lat: 48.7986673, lng: 19.5836189 },
    { lat: 48.79842, lng: 19.5840532 },
    { lat: 48.7983265, lng: 19.5847985 },
    { lat: 48.7981577, lng: 19.585326 },
    { lat: 48.7982813, lng: 19.5864372 },
    { lat: 48.7957828, lng: 19.5876681 },
    { lat: 48.7900552, lng: 19.5901553 },
    { lat: 48.7888749, lng: 19.5890982 },
    { lat: 48.7882649, lng: 19.585116 },
    { lat: 48.7857971, lng: 19.584581 },
    { lat: 48.7856436, lng: 19.5846583 },
    { lat: 48.7852296, lng: 19.5850696 },
    { lat: 48.7839127, lng: 19.5876158 },
    { lat: 48.7835189, lng: 19.5874019 },
    { lat: 48.7831409, lng: 19.586868 },
    { lat: 48.7829693, lng: 19.5872335 },
    { lat: 48.7828927, lng: 19.5871839 },
    { lat: 48.7831848, lng: 19.5849286 },
    { lat: 48.7835151, lng: 19.5835912 },
    { lat: 48.7840067, lng: 19.5825543 },
    { lat: 48.7848039, lng: 19.5815728 },
    { lat: 48.7842537, lng: 19.5816664 },
    { lat: 48.7841181, lng: 19.5821532 },
    { lat: 48.7826625, lng: 19.5839717 },
    { lat: 48.7819334, lng: 19.5862062 },
    { lat: 48.7812249, lng: 19.5888234 },
    { lat: 48.7810581, lng: 19.58892 },
    { lat: 48.780911, lng: 19.5882643 },
    { lat: 48.7807604, lng: 19.5878218 },
    { lat: 48.7802695, lng: 19.5870092 },
    { lat: 48.7802786, lng: 19.5867142 },
    { lat: 48.7801084, lng: 19.5865383 },
    { lat: 48.7795776, lng: 19.5862422 },
    { lat: 48.7795512, lng: 19.5858051 },
    { lat: 48.7797168, lng: 19.5849861 },
    { lat: 48.7797918, lng: 19.5841302 },
    { lat: 48.7797495, lng: 19.5831513 },
    { lat: 48.77947, lng: 19.5822786 },
    { lat: 48.7791104, lng: 19.5829447 },
    { lat: 48.7789426, lng: 19.583472 },
    { lat: 48.7784635, lng: 19.5840127 },
    { lat: 48.7778553, lng: 19.5851116 },
    { lat: 48.7774092, lng: 19.584965 },
    { lat: 48.777105, lng: 19.584739 },
    { lat: 48.776732, lng: 19.5846155 },
    { lat: 48.7763216, lng: 19.5849075 },
    { lat: 48.7756051, lng: 19.5849362 },
    { lat: 48.7736683, lng: 19.5836737 },
    { lat: 48.7733026, lng: 19.583612 },
    { lat: 48.7730679, lng: 19.583481 },
    { lat: 48.7727616, lng: 19.5830652 },
    { lat: 48.7721627, lng: 19.5819081 },
    { lat: 48.7712398, lng: 19.5809363 },
    { lat: 48.7703346, lng: 19.5800855 },
    { lat: 48.7701203, lng: 19.5798081 },
    { lat: 48.7692504, lng: 19.5784457 },
    { lat: 48.7686865, lng: 19.5773166 },
    { lat: 48.7678165, lng: 19.5760515 },
    { lat: 48.7675887, lng: 19.5755476 },
    { lat: 48.7670496, lng: 19.5752658 },
    { lat: 48.7667253, lng: 19.5749998 },
    { lat: 48.7665437, lng: 19.5747701 },
    { lat: 48.766309, lng: 19.574293 },
    { lat: 48.7662017, lng: 19.5732976 },
    { lat: 48.7662452, lng: 19.5719236 },
    { lat: 48.7661989, lng: 19.5715237 },
    { lat: 48.765809, lng: 19.5705088 },
    { lat: 48.7646658, lng: 19.5688626 },
    { lat: 48.7640667, lng: 19.5686736 },
    { lat: 48.7629262, lng: 19.5680262 },
    { lat: 48.761787, lng: 19.5670112 },
    { lat: 48.7616289, lng: 19.5669512 },
    { lat: 48.7610929, lng: 19.567086 },
    { lat: 48.7570894, lng: 19.5671848 },
    { lat: 48.7556427, lng: 19.5663002 },
    { lat: 48.7552858, lng: 19.5656128 },
    { lat: 48.7548992, lng: 19.5656634 },
    { lat: 48.7534855, lng: 19.5662639 },
    { lat: 48.7530343, lng: 19.5659595 },
    { lat: 48.7527542, lng: 19.5660683 },
    { lat: 48.7521275, lng: 19.5656676 },
    { lat: 48.7520453, lng: 19.5653976 },
    { lat: 48.7514229, lng: 19.5651193 },
    { lat: 48.7511995, lng: 19.5652122 },
    { lat: 48.7503777, lng: 19.5663655 },
    { lat: 48.7500498, lng: 19.5664407 },
    { lat: 48.7494426, lng: 19.5669153 },
    { lat: 48.7492319, lng: 19.5672354 },
    { lat: 48.7490885, lng: 19.5673267 },
    { lat: 48.7486498, lng: 19.5674663 },
    { lat: 48.7480058, lng: 19.5670082 },
    { lat: 48.7477614, lng: 19.5669147 },
    { lat: 48.7468433, lng: 19.5655574 },
    { lat: 48.7464377, lng: 19.5647953 },
    { lat: 48.7461164, lng: 19.5633316 },
    { lat: 48.7458683, lng: 19.5632039 },
    { lat: 48.7449475, lng: 19.5630242 },
    { lat: 48.7444057, lng: 19.5615704 },
    { lat: 48.7440696, lng: 19.5609214 },
    { lat: 48.7436913, lng: 19.5604863 },
    { lat: 48.7432812, lng: 19.560488 },
    { lat: 48.742607, lng: 19.5607764 },
    { lat: 48.741944, lng: 19.5603482 },
    { lat: 48.7415353, lng: 19.5594162 },
    { lat: 48.7401853, lng: 19.556744 },
    { lat: 48.7398876, lng: 19.5558765 },
    { lat: 48.7398447, lng: 19.5550877 },
    { lat: 48.7392363, lng: 19.5543247 },
    { lat: 48.7388813, lng: 19.5542765 },
    { lat: 48.737978, lng: 19.5538388 },
    { lat: 48.7377017, lng: 19.5536377 },
    { lat: 48.7374173, lng: 19.5532569 },
    { lat: 48.7372859, lng: 19.5521325 },
    { lat: 48.7370397, lng: 19.5516024 },
    { lat: 48.7367259, lng: 19.5501611 },
    { lat: 48.7364342, lng: 19.5499685 },
    { lat: 48.7361105, lng: 19.549912 },
    { lat: 48.7357678, lng: 19.549564 },
    { lat: 48.7354648, lng: 19.5488517 },
    { lat: 48.7353006, lng: 19.5481664 },
    { lat: 48.7352833, lng: 19.5475145 },
    { lat: 48.7348924, lng: 19.5465101 },
    { lat: 48.7350138, lng: 19.5458856 },
    { lat: 48.7351843, lng: 19.5454005 },
    { lat: 48.7351584, lng: 19.5444165 },
    { lat: 48.7349728, lng: 19.54324 },
    { lat: 48.7340824, lng: 19.5416882 },
    { lat: 48.7333796, lng: 19.5413269 },
    { lat: 48.7321553, lng: 19.5412666 },
    { lat: 48.7314257, lng: 19.5411337 },
    { lat: 48.7295816, lng: 19.540455 },
    { lat: 48.7289174, lng: 19.5399316 },
    { lat: 48.727171, lng: 19.5394883 },
    { lat: 48.7259309, lng: 19.5398258 },
    { lat: 48.725616, lng: 19.5397867 },
    { lat: 48.7249372, lng: 19.5393623 },
    { lat: 48.7247173, lng: 19.5394799 },
    { lat: 48.7240909, lng: 19.5394594 },
    { lat: 48.7238019, lng: 19.5395232 },
    { lat: 48.7229716, lng: 19.539997 },
    { lat: 48.7224054, lng: 19.5404249 },
    { lat: 48.7220532, lng: 19.540511 },
    { lat: 48.7217144, lng: 19.5405425 },
    { lat: 48.7215849, lng: 19.5404472 },
    { lat: 48.7213644, lng: 19.5404792 },
    { lat: 48.7212134, lng: 19.5403333 },
    { lat: 48.7206685, lng: 19.5400263 },
    { lat: 48.7202816, lng: 19.5402598 },
    { lat: 48.7197655, lng: 19.5400679 },
    { lat: 48.7198042, lng: 19.5398027 },
    { lat: 48.7192367, lng: 19.5396415 },
    { lat: 48.7190613, lng: 19.5395035 },
    { lat: 48.7188223, lng: 19.5391524 },
    { lat: 48.7183725, lng: 19.5380928 },
    { lat: 48.717865, lng: 19.5374733 },
    { lat: 48.7173191, lng: 19.5370676 },
    { lat: 48.7171273, lng: 19.5369892 },
    { lat: 48.7168309, lng: 19.5370131 },
    { lat: 48.7159996, lng: 19.5374141 },
    { lat: 48.7152086, lng: 19.5376371 },
    { lat: 48.7150045, lng: 19.5376085 },
    { lat: 48.7147502, lng: 19.5374453 },
    { lat: 48.7143478, lng: 19.536975 },
    { lat: 48.7139427, lng: 19.5363244 },
    { lat: 48.7136866, lng: 19.5357539 },
    { lat: 48.7134477, lng: 19.5355299 },
    { lat: 48.7126072, lng: 19.5355843 },
    { lat: 48.7122071, lng: 19.5357058 },
    { lat: 48.7118531, lng: 19.5361696 },
    { lat: 48.7115642, lng: 19.5363326 },
    { lat: 48.7113115, lng: 19.5363255 },
    { lat: 48.7099632, lng: 19.535874 },
    { lat: 48.7098371, lng: 19.53574 },
    { lat: 48.709461, lng: 19.5353403 },
    { lat: 48.7087321, lng: 19.5339459 },
    { lat: 48.7082329, lng: 19.5334052 },
    { lat: 48.7079415, lng: 19.5331671 },
    { lat: 48.7075043, lng: 19.5329492 },
    { lat: 48.7071051, lng: 19.5323756 },
    { lat: 48.7067451, lng: 19.531724 },
    { lat: 48.7066348, lng: 19.5314098 },
    { lat: 48.7062052, lng: 19.5294383 },
    { lat: 48.705885, lng: 19.5285018 },
    { lat: 48.7055813, lng: 19.5285429 },
    { lat: 48.704959, lng: 19.5279245 },
    { lat: 48.7048491, lng: 19.527711 },
    { lat: 48.7038633, lng: 19.5267629 },
    { lat: 48.7037892, lng: 19.5269637 },
    { lat: 48.7036902, lng: 19.5270658 },
    { lat: 48.7036551, lng: 19.5269895 },
    { lat: 48.7028544, lng: 19.5265341 },
    { lat: 48.7020497, lng: 19.5255478 },
    { lat: 48.7014206, lng: 19.5250034 },
    { lat: 48.7011991, lng: 19.5246489 },
    { lat: 48.7004595, lng: 19.5239552 },
    { lat: 48.6997078, lng: 19.5233887 },
    { lat: 48.6992826, lng: 19.5229378 },
    { lat: 48.6986097, lng: 19.5226688 },
    { lat: 48.6978117, lng: 19.52281 },
    { lat: 48.6976748, lng: 19.52292 },
    { lat: 48.6972259, lng: 19.5230826 },
    { lat: 48.6969001, lng: 19.523008 },
    { lat: 48.6967249, lng: 19.5231687 },
    { lat: 48.6964626, lng: 19.5242004 },
    { lat: 48.6960547, lng: 19.5245685 },
    { lat: 48.6957211, lng: 19.5246438 },
    { lat: 48.6955988, lng: 19.5248279 },
    { lat: 48.6952715, lng: 19.5255981 },
    { lat: 48.6946998, lng: 19.5248069 },
    { lat: 48.6942807, lng: 19.5250043 },
    { lat: 48.6940145, lng: 19.524658 },
    { lat: 48.6929207, lng: 19.5240526 },
    { lat: 48.6925122, lng: 19.5237231 },
    { lat: 48.6915538, lng: 19.5222417 },
    { lat: 48.6914066, lng: 19.5218679 },
    { lat: 48.6912656, lng: 19.5215098 },
    { lat: 48.6905381, lng: 19.520566 },
    { lat: 48.6898889, lng: 19.5201241 },
    { lat: 48.6891506, lng: 19.5191156 },
    { lat: 48.688477, lng: 19.5169925 },
    { lat: 48.6879681, lng: 19.5151411 },
    { lat: 48.6868112, lng: 19.5125304 },
    { lat: 48.6867288, lng: 19.5116763 },
    { lat: 48.6866004, lng: 19.5073744 },
    { lat: 48.686414, lng: 19.5061439 },
    { lat: 48.686422, lng: 19.5054565 },
    { lat: 48.6862605, lng: 19.5049864 },
    { lat: 48.6859573, lng: 19.5037429 },
    { lat: 48.685563, lng: 19.5035515 },
    { lat: 48.6849065, lng: 19.5034634 },
    { lat: 48.6842276, lng: 19.5032071 },
    { lat: 48.6835137, lng: 19.5031971 },
    { lat: 48.6833087, lng: 19.5030022 },
    { lat: 48.6829468, lng: 19.5018534 },
    { lat: 48.6824208, lng: 19.5007109 },
    { lat: 48.6816526, lng: 19.5001796 },
    { lat: 48.6811082, lng: 19.499717 },
    { lat: 48.6806551, lng: 19.4992487 },
    { lat: 48.679989, lng: 19.4982227 },
    { lat: 48.6795529, lng: 19.4977134 },
    { lat: 48.6786264, lng: 19.4967595 },
    { lat: 48.6782188, lng: 19.4964476 },
    { lat: 48.6779925, lng: 19.4956763 },
    { lat: 48.6772372, lng: 19.4937686 },
    { lat: 48.676986, lng: 19.4924059 },
    { lat: 48.6770594, lng: 19.4911914 },
    { lat: 48.6770568, lng: 19.4901348 },
    { lat: 48.6765147, lng: 19.4880907 },
    { lat: 48.6760004, lng: 19.4870353 },
    { lat: 48.6758713, lng: 19.4865586 },
    { lat: 48.6753537, lng: 19.48553 },
    { lat: 48.6751616, lng: 19.4842777 },
    { lat: 48.6750135, lng: 19.4836101 },
    { lat: 48.674393, lng: 19.4818946 },
    { lat: 48.6742693, lng: 19.4813871 },
    { lat: 48.673762, lng: 19.480685 },
    { lat: 48.673162, lng: 19.481068 },
    { lat: 48.672687, lng: 19.481136 },
    { lat: 48.672587, lng: 19.481341 },
    { lat: 48.672165, lng: 19.481218 },
    { lat: 48.6717066, lng: 19.4807067 },
    { lat: 48.6716404, lng: 19.4806329 },
    { lat: 48.671402, lng: 19.480367 },
    { lat: 48.67098, lng: 19.480207 },
    { lat: 48.670406, lng: 19.479929 },
    { lat: 48.669519, lng: 19.479277 },
    { lat: 48.669187, lng: 19.47996 },
    { lat: 48.668885, lng: 19.480414 },
    { lat: 48.668412, lng: 19.481833 },
    { lat: 48.668248, lng: 19.482701 },
    { lat: 48.667997, lng: 19.483624 },
    { lat: 48.667934, lng: 19.484014 },
    { lat: 48.667807, lng: 19.484807 },
    { lat: 48.667412, lng: 19.485837 },
    { lat: 48.666587, lng: 19.486087 },
    { lat: 48.665699, lng: 19.486132 },
    { lat: 48.665161, lng: 19.485999 },
    { lat: 48.664455, lng: 19.486338 },
    { lat: 48.664062, lng: 19.48704 },
    { lat: 48.663813, lng: 19.487597 },
    { lat: 48.663588, lng: 19.488553 },
    { lat: 48.663388, lng: 19.489566 },
    { lat: 48.663359, lng: 19.489711 },
    { lat: 48.663326, lng: 19.489881 },
    { lat: 48.662794, lng: 19.492422 },
    { lat: 48.662548, lng: 19.492902 },
    { lat: 48.662016, lng: 19.49334 },
    { lat: 48.6617766, lng: 19.4933965 },
    { lat: 48.660665, lng: 19.493659 },
    { lat: 48.659891, lng: 19.493775 },
    { lat: 48.658904, lng: 19.493784 },
    { lat: 48.65782, lng: 19.492512 },
    { lat: 48.657109, lng: 19.492496 },
    { lat: 48.656899, lng: 19.493227 },
    { lat: 48.656051, lng: 19.494572 },
    { lat: 48.655736, lng: 19.494592 },
    { lat: 48.655182, lng: 19.494455 },
    { lat: 48.654299, lng: 19.494255 },
    { lat: 48.654471, lng: 19.49523 },
    { lat: 48.654576, lng: 19.496437 },
    { lat: 48.654754, lng: 19.497375 },
    { lat: 48.655155, lng: 19.498173 },
    { lat: 48.655317, lng: 19.498495 },
    { lat: 48.655867, lng: 19.4986 },
    { lat: 48.656712, lng: 19.499798 },
    { lat: 48.656749, lng: 19.500563 },
    { lat: 48.656811, lng: 19.501559 },
    { lat: 48.65709, lng: 19.502955 },
    { lat: 48.657251, lng: 19.503947 },
    { lat: 48.657655, lng: 19.504461 },
    { lat: 48.658342, lng: 19.505645 },
    { lat: 48.658402, lng: 19.506564 },
    { lat: 48.658745, lng: 19.50714 },
    { lat: 48.659091, lng: 19.50896 },
    { lat: 48.659146, lng: 19.509286 },
    { lat: 48.658976, lng: 19.510422 },
    { lat: 48.658826, lng: 19.511056 },
    { lat: 48.658623, lng: 19.512447 },
    { lat: 48.658417, lng: 19.513471 },
    { lat: 48.658521, lng: 19.514617 },
    { lat: 48.658402, lng: 19.515517 },
    { lat: 48.658531, lng: 19.515903 },
    { lat: 48.659143, lng: 19.516003 },
    { lat: 48.659655, lng: 19.51659 },
    { lat: 48.660165, lng: 19.517515 },
    { lat: 48.660611, lng: 19.518532 },
    { lat: 48.660879, lng: 19.519211 },
    { lat: 48.661196, lng: 19.521287 },
    { lat: 48.660947, lng: 19.523024 },
    { lat: 48.661021, lng: 19.523846 },
    { lat: 48.660904, lng: 19.525407 },
    { lat: 48.660854, lng: 19.526339 },
    { lat: 48.660811, lng: 19.527372 },
    { lat: 48.660682, lng: 19.528543 },
    { lat: 48.660669, lng: 19.528907 },
    { lat: 48.660753, lng: 19.530511 },
    { lat: 48.660793, lng: 19.531174 },
    { lat: 48.661141, lng: 19.532216 },
    { lat: 48.66138, lng: 19.533132 },
    { lat: 48.66142, lng: 19.53388 },
    { lat: 48.661431, lng: 19.53449 },
    { lat: 48.661465, lng: 19.536056 },
    { lat: 48.661465, lng: 19.537365 },
    { lat: 48.661485, lng: 19.538804 },
    { lat: 48.660985, lng: 19.53991 },
    { lat: 48.660798, lng: 19.540168 },
    { lat: 48.660308, lng: 19.540611 },
    { lat: 48.659567, lng: 19.541238 },
    { lat: 48.659107, lng: 19.541956 },
    { lat: 48.658872, lng: 19.54305 },
    { lat: 48.658663, lng: 19.543866 },
    { lat: 48.658537, lng: 19.544501 },
    { lat: 48.65844, lng: 19.54565 },
    { lat: 48.658313, lng: 19.546512 },
    { lat: 48.658272, lng: 19.546916 },
    { lat: 48.658145, lng: 19.548269 },
    { lat: 48.65792, lng: 19.550559 },
    { lat: 48.657792, lng: 19.551077 },
    { lat: 48.657728, lng: 19.551892 },
    { lat: 48.657681, lng: 19.552258 },
    { lat: 48.657627, lng: 19.553123 },
    { lat: 48.657374, lng: 19.55396 },
    { lat: 48.656403, lng: 19.555189 },
    { lat: 48.655542, lng: 19.556095 },
    { lat: 48.655243, lng: 19.556308 },
    { lat: 48.65556, lng: 19.556391 },
    { lat: 48.655981, lng: 19.556746 },
    { lat: 48.656803, lng: 19.55834 },
    { lat: 48.657892, lng: 19.560208 },
    { lat: 48.658312, lng: 19.5601205 },
    { lat: 48.6585331, lng: 19.560003 },
    { lat: 48.6600411, lng: 19.5585542 },
    { lat: 48.660725, lng: 19.5579858 },
    { lat: 48.6611077, lng: 19.5578753 },
    { lat: 48.6615968, lng: 19.5580536 },
    { lat: 48.6619265, lng: 19.5583386 },
    { lat: 48.6623916, lng: 19.5582552 },
    { lat: 48.6626679, lng: 19.5581153 },
    { lat: 48.6629007, lng: 19.5582749 },
    { lat: 48.6639257, lng: 19.5577721 },
    { lat: 48.6642018, lng: 19.5578214 },
    { lat: 48.6646251, lng: 19.5576169 },
    { lat: 48.666594, lng: 19.5581662 },
    { lat: 48.6673357, lng: 19.5587591 },
    { lat: 48.6676901, lng: 19.5589703 },
    { lat: 48.6684931, lng: 19.5591348 },
    { lat: 48.6689128, lng: 19.5589239 },
    { lat: 48.6694416, lng: 19.5588546 },
    { lat: 48.6705547, lng: 19.5602082 },
    { lat: 48.6707979, lng: 19.5607209 },
    { lat: 48.671347, lng: 19.561197 },
    { lat: 48.6725992, lng: 19.5605347 },
    { lat: 48.6738821, lng: 19.559636 },
    { lat: 48.6747917, lng: 19.5597669 },
    { lat: 48.6750681, lng: 19.5597478 },
    { lat: 48.6753805, lng: 19.5598522 },
    { lat: 48.6764431, lng: 19.5597265 },
    { lat: 48.6770186, lng: 19.5591802 },
    { lat: 48.6776526, lng: 19.559367 },
    { lat: 48.6781018, lng: 19.5589123 },
    { lat: 48.6787175, lng: 19.5578355 },
    { lat: 48.679015, lng: 19.5571943 },
    { lat: 48.6792549, lng: 19.5564801 },
    { lat: 48.6794497, lng: 19.5556853 },
    { lat: 48.6796274, lng: 19.5552841 },
    { lat: 48.6810216, lng: 19.5545262 },
    { lat: 48.6812811, lng: 19.5542891 },
    { lat: 48.6816723, lng: 19.5535287 },
    { lat: 48.6823248, lng: 19.5524454 },
    { lat: 48.6833867, lng: 19.5514641 },
    { lat: 48.6835705, lng: 19.5510994 },
    { lat: 48.6838503, lng: 19.5514514 },
    { lat: 48.6842026, lng: 19.5515641 },
    { lat: 48.6843622, lng: 19.5516829 },
    { lat: 48.6851538, lng: 19.5536402 },
  ],
  ZávadkanadHronom: [
    { lat: 48.785364, lng: 19.977053 },
    { lat: 48.7888083, lng: 19.9771565 },
    { lat: 48.7896305, lng: 19.9769942 },
    { lat: 48.7919178, lng: 19.9767148 },
    { lat: 48.7920183, lng: 19.9772546 },
    { lat: 48.7921432, lng: 19.9769847 },
    { lat: 48.7922102, lng: 19.9764613 },
    { lat: 48.7928293, lng: 19.9766908 },
    { lat: 48.7934984, lng: 19.9768027 },
    { lat: 48.7940512, lng: 19.9766327 },
    { lat: 48.7946174, lng: 19.9765983 },
    { lat: 48.7967495, lng: 19.9769955 },
    { lat: 48.7979522, lng: 19.9769599 },
    { lat: 48.7969558, lng: 19.975251 },
    { lat: 48.79653, lng: 19.9751176 },
    { lat: 48.796612, lng: 19.9747374 },
    { lat: 48.7970498, lng: 19.9751852 },
    { lat: 48.797724, lng: 19.9754412 },
    { lat: 48.7982903, lng: 19.975594 },
    { lat: 48.798348, lng: 19.9753866 },
    { lat: 48.7985397, lng: 19.9755525 },
    { lat: 48.7990894, lng: 19.9757423 },
    { lat: 48.7993138, lng: 19.9757172 },
    { lat: 48.7993767, lng: 19.975873 },
    { lat: 48.7994999, lng: 19.9759915 },
    { lat: 48.8000198, lng: 19.9759834 },
    { lat: 48.8005828, lng: 19.9734528 },
    { lat: 48.8013373, lng: 19.97224 },
    { lat: 48.8017947, lng: 19.9702544 },
    { lat: 48.802077, lng: 19.9687708 },
    { lat: 48.8026531, lng: 19.9674429 },
    { lat: 48.8027026, lng: 19.9671441 },
    { lat: 48.8029575, lng: 19.9663741 },
    { lat: 48.8036077, lng: 19.9658491 },
    { lat: 48.8043744, lng: 19.965647 },
    { lat: 48.8058056, lng: 19.9646726 },
    { lat: 48.8073268, lng: 19.9634529 },
    { lat: 48.8079947, lng: 19.9628103 },
    { lat: 48.8086484, lng: 19.9620433 },
    { lat: 48.8093832, lng: 19.9615382 },
    { lat: 48.8099922, lng: 19.9606461 },
    { lat: 48.8106265, lng: 19.9601146 },
    { lat: 48.8110652, lng: 19.9594847 },
    { lat: 48.8117551, lng: 19.9592302 },
    { lat: 48.8128901, lng: 19.959675 },
    { lat: 48.8135429, lng: 19.9598469 },
    { lat: 48.8148235, lng: 19.9595246 },
    { lat: 48.8165215, lng: 19.956961 },
    { lat: 48.8169292, lng: 19.9565524 },
    { lat: 48.8172228, lng: 19.9562582 },
    { lat: 48.8186279, lng: 19.9552811 },
    { lat: 48.8198277, lng: 19.9547782 },
    { lat: 48.8203651, lng: 19.9547524 },
    { lat: 48.8208293, lng: 19.9548848 },
    { lat: 48.8218393, lng: 19.9556016 },
    { lat: 48.8220514, lng: 19.9575064 },
    { lat: 48.8230363, lng: 19.9572748 },
    { lat: 48.8237029, lng: 19.9577009 },
    { lat: 48.8243184, lng: 19.9579232 },
    { lat: 48.8256541, lng: 19.9581575 },
    { lat: 48.8268868, lng: 19.9575812 },
    { lat: 48.8280482, lng: 19.9567813 },
    { lat: 48.8295588, lng: 19.9561692 },
    { lat: 48.8306225, lng: 19.9547569 },
    { lat: 48.8324509, lng: 19.9528627 },
    { lat: 48.8330049, lng: 19.9519267 },
    { lat: 48.8336641, lng: 19.9511311 },
    { lat: 48.8344328, lng: 19.950367 },
    { lat: 48.8357103, lng: 19.9499635 },
    { lat: 48.8363228, lng: 19.9490229 },
    { lat: 48.837742, lng: 19.9484104 },
    { lat: 48.8383213, lng: 19.9484438 },
    { lat: 48.8413386, lng: 19.9476816 },
    { lat: 48.8424487, lng: 19.947777 },
    { lat: 48.8430044, lng: 19.9449563 },
    { lat: 48.8432817, lng: 19.9444227 },
    { lat: 48.8446953, lng: 19.9427674 },
    { lat: 48.8462899, lng: 19.941506 },
    { lat: 48.8475882, lng: 19.9403148 },
    { lat: 48.8484448, lng: 19.9398505 },
    { lat: 48.8491746, lng: 19.9393402 },
    { lat: 48.8508529, lng: 19.9375258 },
    { lat: 48.8527068, lng: 19.9368785 },
    { lat: 48.8531412, lng: 19.936841 },
    { lat: 48.8535119, lng: 19.9366577 },
    { lat: 48.8537953, lng: 19.9365958 },
    { lat: 48.8539578, lng: 19.936652 },
    { lat: 48.8542144, lng: 19.9366206 },
    { lat: 48.85409, lng: 19.9362628 },
    { lat: 48.8538271, lng: 19.9357715 },
    { lat: 48.853566, lng: 19.9357539 },
    { lat: 48.8534863, lng: 19.9356007 },
    { lat: 48.8535102, lng: 19.9353097 },
    { lat: 48.8535909, lng: 19.935143 },
    { lat: 48.8536763, lng: 19.9351347 },
    { lat: 48.8536579, lng: 19.9347012 },
    { lat: 48.8538719, lng: 19.933715 },
    { lat: 48.8538796, lng: 19.9335229 },
    { lat: 48.8536914, lng: 19.9332808 },
    { lat: 48.8536984, lng: 19.9331284 },
    { lat: 48.8537493, lng: 19.9330537 },
    { lat: 48.8538474, lng: 19.9330574 },
    { lat: 48.8539279, lng: 19.9333376 },
    { lat: 48.8541183, lng: 19.933469 },
    { lat: 48.8542585, lng: 19.9337744 },
    { lat: 48.8546847, lng: 19.9337839 },
    { lat: 48.8548842, lng: 19.9334676 },
    { lat: 48.8550376, lng: 19.9333669 },
    { lat: 48.8551974, lng: 19.9333886 },
    { lat: 48.855227, lng: 19.9331281 },
    { lat: 48.8554275, lng: 19.9330468 },
    { lat: 48.8554974, lng: 19.9329184 },
    { lat: 48.8556054, lng: 19.9329379 },
    { lat: 48.8560067, lng: 19.9327334 },
    { lat: 48.8561611, lng: 19.9327899 },
    { lat: 48.8562083, lng: 19.9325714 },
    { lat: 48.8563034, lng: 19.9325812 },
    { lat: 48.8563487, lng: 19.9325077 },
    { lat: 48.8566381, lng: 19.9324016 },
    { lat: 48.8566693, lng: 19.9322591 },
    { lat: 48.8567993, lng: 19.9322672 },
    { lat: 48.8570012, lng: 19.9320348 },
    { lat: 48.8570762, lng: 19.9320279 },
    { lat: 48.8571871, lng: 19.9319055 },
    { lat: 48.8572544, lng: 19.9319215 },
    { lat: 48.8572724, lng: 19.9317906 },
    { lat: 48.8577494, lng: 19.9316999 },
    { lat: 48.8588145, lng: 19.9317618 },
    { lat: 48.8588246, lng: 19.9313916 },
    { lat: 48.8589785, lng: 19.9314593 },
    { lat: 48.8597138, lng: 19.9320676 },
    { lat: 48.860189, lng: 19.9321416 },
    { lat: 48.8602936, lng: 19.9324606 },
    { lat: 48.8603176, lng: 19.932415 },
    { lat: 48.8610263, lng: 19.9326574 },
    { lat: 48.8617913, lng: 19.9333273 },
    { lat: 48.8621073, lng: 19.9335259 },
    { lat: 48.862404, lng: 19.9334585 },
    { lat: 48.8630391, lng: 19.9336398 },
    { lat: 48.8635682, lng: 19.9341637 },
    { lat: 48.8638181, lng: 19.9348822 },
    { lat: 48.8641895, lng: 19.9347123 },
    { lat: 48.8647027, lng: 19.9352661 },
    { lat: 48.8647134, lng: 19.9357598 },
    { lat: 48.8649277, lng: 19.9360331 },
    { lat: 48.8656193, lng: 19.9360032 },
    { lat: 48.8657891, lng: 19.9363699 },
    { lat: 48.865856, lng: 19.936752 },
    { lat: 48.8666287, lng: 19.9373146 },
    { lat: 48.8671536, lng: 19.9375524 },
    { lat: 48.867451, lng: 19.9378209 },
    { lat: 48.8677979, lng: 19.9383508 },
    { lat: 48.8686204, lng: 19.9388614 },
    { lat: 48.8689019, lng: 19.9391772 },
    { lat: 48.8691437, lng: 19.9395713 },
    { lat: 48.8693128, lng: 19.9395453 },
    { lat: 48.8694909, lng: 19.9400071 },
    { lat: 48.8696804, lng: 19.9403304 },
    { lat: 48.8697117, lng: 19.9405915 },
    { lat: 48.8698704, lng: 19.9408058 },
    { lat: 48.8701568, lng: 19.9407859 },
    { lat: 48.8704505, lng: 19.940853 },
    { lat: 48.8711015, lng: 19.9403458 },
    { lat: 48.8720976, lng: 19.9403548 },
    { lat: 48.8725017, lng: 19.9404845 },
    { lat: 48.8728158, lng: 19.9407965 },
    { lat: 48.8734387, lng: 19.9407962 },
    { lat: 48.8746257, lng: 19.9406035 },
    { lat: 48.8757095, lng: 19.940181 },
    { lat: 48.8759203, lng: 19.9399698 },
    { lat: 48.8764282, lng: 19.9396779 },
    { lat: 48.8771082, lng: 19.9391611 },
    { lat: 48.8775378, lng: 19.9387132 },
    { lat: 48.8778995, lng: 19.9384262 },
    { lat: 48.8789956, lng: 19.9382666 },
    { lat: 48.8793799, lng: 19.9380042 },
    { lat: 48.8796028, lng: 19.9380795 },
    { lat: 48.879824, lng: 19.9380119 },
    { lat: 48.8799961, lng: 19.9382489 },
    { lat: 48.8801683, lng: 19.9381621 },
    { lat: 48.8804934, lng: 19.9386316 },
    { lat: 48.8809465, lng: 19.9390951 },
    { lat: 48.8810841, lng: 19.9389644 },
    { lat: 48.8813081, lng: 19.9389591 },
    { lat: 48.8817035, lng: 19.9382955 },
    { lat: 48.8820331, lng: 19.9379619 },
    { lat: 48.8823942, lng: 19.9377654 },
    { lat: 48.8846861, lng: 19.9369006 },
    { lat: 48.8852878, lng: 19.9364499 },
    { lat: 48.8858835, lng: 19.9361498 },
    { lat: 48.8868005, lng: 19.9358095 },
    { lat: 48.8884101, lng: 19.935485 },
    { lat: 48.8889952, lng: 19.9356083 },
    { lat: 48.8899205, lng: 19.9364402 },
    { lat: 48.8913797, lng: 19.936989 },
    { lat: 48.8916995, lng: 19.937171 },
    { lat: 48.8923441, lng: 19.9373397 },
    { lat: 48.8927872, lng: 19.9372172 },
    { lat: 48.8927967, lng: 19.9373079 },
    { lat: 48.8934298, lng: 19.9374144 },
    { lat: 48.8944001, lng: 19.9366816 },
    { lat: 48.8951804, lng: 19.9362997 },
    { lat: 48.8956253, lng: 19.9362698 },
    { lat: 48.8962451, lng: 19.9365977 },
    { lat: 48.8964094, lng: 19.9369129 },
    { lat: 48.8968337, lng: 19.936876 },
    { lat: 48.8982347, lng: 19.9382308 },
    { lat: 48.898591, lng: 19.9385094 },
    { lat: 48.8987761, lng: 19.9388112 },
    { lat: 48.8989405, lng: 19.9389159 },
    { lat: 48.8996482, lng: 19.9390284 },
    { lat: 48.9004685, lng: 19.9387447 },
    { lat: 48.9009798, lng: 19.9379894 },
    { lat: 48.9012472, lng: 19.9366405 },
    { lat: 48.9013084, lng: 19.9365974 },
    { lat: 48.9015746, lng: 19.935907 },
    { lat: 48.9021364, lng: 19.9347289 },
    { lat: 48.906739, lng: 19.933277 },
    { lat: 48.906137, lng: 19.931221 },
    { lat: 48.905617, lng: 19.929444 },
    { lat: 48.90522, lng: 19.928436 },
    { lat: 48.906112, lng: 19.925835 },
    { lat: 48.906329, lng: 19.924708 },
    { lat: 48.907223, lng: 19.923868 },
    { lat: 48.90841, lng: 19.922701 },
    { lat: 48.909081, lng: 19.922531 },
    { lat: 48.908378, lng: 19.91919 },
    { lat: 48.908382, lng: 19.918471 },
    { lat: 48.908368, lng: 19.916952 },
    { lat: 48.9075324, lng: 19.9173693 },
    { lat: 48.9015317, lng: 19.9170476 },
    { lat: 48.9012848, lng: 19.9171755 },
    { lat: 48.9012377, lng: 19.91713 },
    { lat: 48.9000035, lng: 19.9174318 },
    { lat: 48.8995275, lng: 19.9174671 },
    { lat: 48.8984724, lng: 19.9170794 },
    { lat: 48.8980397, lng: 19.917127 },
    { lat: 48.8973795, lng: 19.9170449 },
    { lat: 48.8965567, lng: 19.916873 },
    { lat: 48.8961004, lng: 19.9167106 },
    { lat: 48.8959321, lng: 19.9167556 },
    { lat: 48.8954501, lng: 19.9166505 },
    { lat: 48.8952293, lng: 19.9165481 },
    { lat: 48.8943654, lng: 19.9165761 },
    { lat: 48.8942839, lng: 19.9164856 },
    { lat: 48.8940066, lng: 19.9165087 },
    { lat: 48.8936202, lng: 19.9163215 },
    { lat: 48.8932379, lng: 19.9162292 },
    { lat: 48.8931545, lng: 19.9161267 },
    { lat: 48.8929354, lng: 19.9161631 },
    { lat: 48.8928609, lng: 19.9160537 },
    { lat: 48.8925238, lng: 19.91602 },
    { lat: 48.8919021, lng: 19.915622 },
    { lat: 48.8915087, lng: 19.9155128 },
    { lat: 48.89109, lng: 19.915011 },
    { lat: 48.8909823, lng: 19.9150483 },
    { lat: 48.8907117, lng: 19.9147017 },
    { lat: 48.8902051, lng: 19.9145446 },
    { lat: 48.8895666, lng: 19.9141289 },
    { lat: 48.8888672, lng: 19.9140507 },
    { lat: 48.8887388, lng: 19.9140235 },
    { lat: 48.8886146, lng: 19.9139096 },
    { lat: 48.8883219, lng: 19.9138891 },
    { lat: 48.8879301, lng: 19.9136836 },
    { lat: 48.8874631, lng: 19.9135595 },
    { lat: 48.887097, lng: 19.9133475 },
    { lat: 48.8870114, lng: 19.9133773 },
    { lat: 48.8867483, lng: 19.9131445 },
    { lat: 48.8857987, lng: 19.9128012 },
    { lat: 48.8856473, lng: 19.9128163 },
    { lat: 48.8853148, lng: 19.912707 },
    { lat: 48.8852222, lng: 19.9126204 },
    { lat: 48.8846914, lng: 19.9127234 },
    { lat: 48.8845463, lng: 19.9126459 },
    { lat: 48.8844891, lng: 19.9127839 },
    { lat: 48.8842793, lng: 19.9128584 },
    { lat: 48.8841886, lng: 19.9127681 },
    { lat: 48.8837684, lng: 19.9129505 },
    { lat: 48.8830475, lng: 19.9130057 },
    { lat: 48.88241, lng: 19.9133238 },
    { lat: 48.8822159, lng: 19.9132103 },
    { lat: 48.8816954, lng: 19.9132332 },
    { lat: 48.8811225, lng: 19.9126591 },
    { lat: 48.8800143, lng: 19.9120317 },
    { lat: 48.8795971, lng: 19.9114901 },
    { lat: 48.8793501, lng: 19.9112988 },
    { lat: 48.8786806, lng: 19.9104629 },
    { lat: 48.8785975, lng: 19.9104177 },
    { lat: 48.8782789, lng: 19.9096031 },
    { lat: 48.8783069, lng: 19.9088789 },
    { lat: 48.8776488, lng: 19.9083451 },
    { lat: 48.8774096, lng: 19.9083581 },
    { lat: 48.8773926, lng: 19.9082245 },
    { lat: 48.8771712, lng: 19.9077862 },
    { lat: 48.8763234, lng: 19.9081174 },
    { lat: 48.8759723, lng: 19.9084937 },
    { lat: 48.8755813, lng: 19.9082524 },
    { lat: 48.8749294, lng: 19.9081811 },
    { lat: 48.8739505, lng: 19.9086091 },
    { lat: 48.873592, lng: 19.9086759 },
    { lat: 48.8728859, lng: 19.9086307 },
    { lat: 48.8719272, lng: 19.9062434 },
    { lat: 48.8717255, lng: 19.9060026 },
    { lat: 48.8715753, lng: 19.9052229 },
    { lat: 48.8701525, lng: 19.9046655 },
    { lat: 48.8695182, lng: 19.9029648 },
    { lat: 48.8694407, lng: 19.9026013 },
    { lat: 48.869288, lng: 19.9013424 },
    { lat: 48.8692579, lng: 19.9001581 },
    { lat: 48.8687653, lng: 19.9002027 },
    { lat: 48.8680004, lng: 19.9000177 },
    { lat: 48.8671999, lng: 19.9000287 },
    { lat: 48.8667135, lng: 19.8999023 },
    { lat: 48.8656324, lng: 19.9001747 },
    { lat: 48.8653441, lng: 19.9000394 },
    { lat: 48.8651312, lng: 19.8996698 },
    { lat: 48.8638551, lng: 19.8989857 },
    { lat: 48.8635962, lng: 19.8989931 },
    { lat: 48.8632032, lng: 19.8988612 },
    { lat: 48.8630103, lng: 19.8989087 },
    { lat: 48.8628976, lng: 19.8988306 },
    { lat: 48.861978, lng: 19.8986375 },
    { lat: 48.8611377, lng: 19.898183 },
    { lat: 48.8604796, lng: 19.8972292 },
    { lat: 48.8598868, lng: 19.8960028 },
    { lat: 48.8594453, lng: 19.8952143 },
    { lat: 48.8591792, lng: 19.8950471 },
    { lat: 48.8580122, lng: 19.8939117 },
    { lat: 48.8571464, lng: 19.8938458 },
    { lat: 48.8566013, lng: 19.8939782 },
    { lat: 48.8559189, lng: 19.8942775 },
    { lat: 48.8552334, lng: 19.8952421 },
    { lat: 48.8533602, lng: 19.8966469 },
    { lat: 48.8527442, lng: 19.8969929 },
    { lat: 48.8509384, lng: 19.896991 },
    { lat: 48.8507687, lng: 19.8968921 },
    { lat: 48.8507316, lng: 19.8968964 },
    { lat: 48.8507577, lng: 19.8970348 },
    { lat: 48.8506605, lng: 19.8969728 },
    { lat: 48.8482493, lng: 19.8971692 },
    { lat: 48.8466466, lng: 19.8976535 },
    { lat: 48.8462761, lng: 19.8976021 },
    { lat: 48.8455179, lng: 19.8972842 },
    { lat: 48.8452975, lng: 19.897348 },
    { lat: 48.844745, lng: 19.8972293 },
    { lat: 48.844014, lng: 19.8978672 },
    { lat: 48.8428173, lng: 19.8978808 },
    { lat: 48.8421192, lng: 19.8980993 },
    { lat: 48.8414842, lng: 19.8981099 },
    { lat: 48.8398562, lng: 19.8979171 },
    { lat: 48.8382853, lng: 19.8972986 },
    { lat: 48.8373518, lng: 19.8963793 },
    { lat: 48.8366773, lng: 19.8959893 },
    { lat: 48.8353938, lng: 19.8961877 },
    { lat: 48.8336351, lng: 19.8961845 },
    { lat: 48.8333132, lng: 19.8965346 },
    { lat: 48.8326918, lng: 19.8964659 },
    { lat: 48.8325408, lng: 19.8967005 },
    { lat: 48.8313888, lng: 19.8963115 },
    { lat: 48.830747, lng: 19.896001 },
    { lat: 48.8298035, lng: 19.8964897 },
    { lat: 48.8294495, lng: 19.8968518 },
    { lat: 48.8288461, lng: 19.8972598 },
    { lat: 48.8287053, lng: 19.8968645 },
    { lat: 48.8275208, lng: 19.8973249 },
    { lat: 48.8267016, lng: 19.8967911 },
    { lat: 48.8263399, lng: 19.897238 },
    { lat: 48.8266309, lng: 19.8998362 },
    { lat: 48.8124175, lng: 19.9147548 },
    { lat: 48.8115233, lng: 19.916245 },
    { lat: 48.8101497, lng: 19.9175613 },
    { lat: 48.8059896, lng: 19.9174303 },
    { lat: 48.8049296, lng: 19.9168974 },
    { lat: 48.8042243, lng: 19.9167373 },
    { lat: 48.8036038, lng: 19.916262 },
    { lat: 48.8030249, lng: 19.9162419 },
    { lat: 48.8023509, lng: 19.9164193 },
    { lat: 48.7999859, lng: 19.9116555 },
    { lat: 48.7986789, lng: 19.911517 },
    { lat: 48.796374, lng: 19.912599 },
    { lat: 48.796106, lng: 19.91239 },
    { lat: 48.7940605, lng: 19.9155217 },
    { lat: 48.7923619, lng: 19.9238877 },
    { lat: 48.787006, lng: 19.9374898 },
    { lat: 48.7868949, lng: 19.9387265 },
    { lat: 48.786835, lng: 19.938914 },
    { lat: 48.786766, lng: 19.939113 },
    { lat: 48.784201, lng: 19.940849 },
    { lat: 48.783981, lng: 19.940623 },
    { lat: 48.782691, lng: 19.941871 },
    { lat: 48.781442, lng: 19.942717 },
    { lat: 48.781613, lng: 19.948603 },
    { lat: 48.781694, lng: 19.950115 },
    { lat: 48.782288, lng: 19.958351 },
    { lat: 48.781718, lng: 19.964344 },
    { lat: 48.782197, lng: 19.964277 },
    { lat: 48.782254, lng: 19.965642 },
    { lat: 48.782257, lng: 19.965702 },
    { lat: 48.782048, lng: 19.966652 },
    { lat: 48.781023, lng: 19.971296 },
    { lat: 48.785454, lng: 19.973091 },
    { lat: 48.785499, lng: 19.974316 },
    { lat: 48.785015, lng: 19.975881 },
    { lat: 48.785364, lng: 19.977053 },
  ],
  MýtopodĎumbierom: [
    { lat: 48.8418434, lng: 19.6048136 },
    { lat: 48.8412913, lng: 19.605539 },
    { lat: 48.8404369, lng: 19.606007 },
    { lat: 48.8401184, lng: 19.6060392 },
    { lat: 48.8391352, lng: 19.6069502 },
    { lat: 48.8382189, lng: 19.6069459 },
    { lat: 48.8373182, lng: 19.6075857 },
    { lat: 48.836507, lng: 19.6083371 },
    { lat: 48.8365268, lng: 19.6088477 },
    { lat: 48.8361645, lng: 19.6100079 },
    { lat: 48.8361009, lng: 19.6104473 },
    { lat: 48.8362853, lng: 19.6108141 },
    { lat: 48.8369287, lng: 19.6106878 },
    { lat: 48.8372297, lng: 19.6112665 },
    { lat: 48.8378369, lng: 19.6114115 },
    { lat: 48.8378317, lng: 19.6115752 },
    { lat: 48.8374883, lng: 19.611855 },
    { lat: 48.8378603, lng: 19.6125859 },
    { lat: 48.8383371, lng: 19.6128607 },
    { lat: 48.8386609, lng: 19.6127463 },
    { lat: 48.8389521, lng: 19.6118316 },
    { lat: 48.8397657, lng: 19.6131618 },
    { lat: 48.8395713, lng: 19.6150209 },
    { lat: 48.839389, lng: 19.6160055 },
    { lat: 48.8394837, lng: 19.6172806 },
    { lat: 48.8386471, lng: 19.6172161 },
    { lat: 48.8386021, lng: 19.617895 },
    { lat: 48.8383822, lng: 19.6195187 },
    { lat: 48.8388274, lng: 19.6198204 },
    { lat: 48.8383453, lng: 19.620626 },
    { lat: 48.8379392, lng: 19.620841 },
    { lat: 48.837772, lng: 19.6206083 },
    { lat: 48.8375075, lng: 19.6210634 },
    { lat: 48.8373997, lng: 19.6215763 },
    { lat: 48.836977, lng: 19.6221193 },
    { lat: 48.8360579, lng: 19.6214505 },
    { lat: 48.8358506, lng: 19.6226512 },
    { lat: 48.8371453, lng: 19.6238821 },
    { lat: 48.8366647, lng: 19.6257962 },
    { lat: 48.8370274, lng: 19.6256719 },
    { lat: 48.8365802, lng: 19.6278312 },
    { lat: 48.8364742, lng: 19.6291802 },
    { lat: 48.8364048, lng: 19.6295189 },
    { lat: 48.8367986, lng: 19.630484 },
    { lat: 48.8372685, lng: 19.630612 },
    { lat: 48.8375854, lng: 19.6312957 },
    { lat: 48.8376921, lng: 19.6316381 },
    { lat: 48.8374737, lng: 19.6319153 },
    { lat: 48.8381591, lng: 19.632579 },
    { lat: 48.8377121, lng: 19.6339656 },
    { lat: 48.8381086, lng: 19.6344141 },
    { lat: 48.8382551, lng: 19.6343429 },
    { lat: 48.8387075, lng: 19.6343284 },
    { lat: 48.8390455, lng: 19.6344521 },
    { lat: 48.8393386, lng: 19.6348336 },
    { lat: 48.8377005, lng: 19.6365446 },
    { lat: 48.8371881, lng: 19.6355737 },
    { lat: 48.8369912, lng: 19.6353275 },
    { lat: 48.8366562, lng: 19.6356971 },
    { lat: 48.8369203, lng: 19.6362562 },
    { lat: 48.8370484, lng: 19.6369279 },
    { lat: 48.8370912, lng: 19.6385091 },
    { lat: 48.837202, lng: 19.6393468 },
    { lat: 48.8376046, lng: 19.639334 },
    { lat: 48.8376728, lng: 19.6397737 },
    { lat: 48.8383066, lng: 19.6405877 },
    { lat: 48.8383454, lng: 19.6410258 },
    { lat: 48.838321, lng: 19.6414886 },
    { lat: 48.8384036, lng: 19.6414984 },
    { lat: 48.8384554, lng: 19.6422277 },
    { lat: 48.8382715, lng: 19.6425696 },
    { lat: 48.838373, lng: 19.6427498 },
    { lat: 48.8389853, lng: 19.6423074 },
    { lat: 48.8388332, lng: 19.6430867 },
    { lat: 48.8384161, lng: 19.6442369 },
    { lat: 48.8384968, lng: 19.6447319 },
    { lat: 48.8388268, lng: 19.6445302 },
    { lat: 48.8391296, lng: 19.6444751 },
    { lat: 48.8391994, lng: 19.6448729 },
    { lat: 48.8393572, lng: 19.6449028 },
    { lat: 48.8394643, lng: 19.6447995 },
    { lat: 48.8394243, lng: 19.6445597 },
    { lat: 48.8399862, lng: 19.6442791 },
    { lat: 48.8400028, lng: 19.6445707 },
    { lat: 48.8401671, lng: 19.6446457 },
    { lat: 48.8402376, lng: 19.6439041 },
    { lat: 48.8404703, lng: 19.6433122 },
    { lat: 48.8406169, lng: 19.6432177 },
    { lat: 48.8410796, lng: 19.6433572 },
    { lat: 48.8412297, lng: 19.643135 },
    { lat: 48.8417284, lng: 19.6429238 },
    { lat: 48.8420693, lng: 19.6426878 },
    { lat: 48.8422526, lng: 19.6433586 },
    { lat: 48.8425319, lng: 19.6434314 },
    { lat: 48.8426268, lng: 19.6436374 },
    { lat: 48.8425446, lng: 19.643868 },
    { lat: 48.8426566, lng: 19.6440028 },
    { lat: 48.8428781, lng: 19.6435643 },
    { lat: 48.842985, lng: 19.6442384 },
    { lat: 48.8429181, lng: 19.6450583 },
    { lat: 48.8431255, lng: 19.6452134 },
    { lat: 48.8431692, lng: 19.6454843 },
    { lat: 48.8430645, lng: 19.6459662 },
    { lat: 48.843139, lng: 19.6469766 },
    { lat: 48.8430858, lng: 19.6473491 },
    { lat: 48.8431054, lng: 19.6475609 },
    { lat: 48.8435347, lng: 19.6495723 },
    { lat: 48.84356, lng: 19.6500891 },
    { lat: 48.8434538, lng: 19.6514153 },
    { lat: 48.8431107, lng: 19.6525594 },
    { lat: 48.8422521, lng: 19.653265 },
    { lat: 48.8418733, lng: 19.6538644 },
    { lat: 48.8419262, lng: 19.6543189 },
    { lat: 48.8421874, lng: 19.6545912 },
    { lat: 48.8423051, lng: 19.6546299 },
    { lat: 48.8423571, lng: 19.6548109 },
    { lat: 48.842667, lng: 19.6550882 },
    { lat: 48.8429678, lng: 19.6551099 },
    { lat: 48.8434408, lng: 19.6542784 },
    { lat: 48.8434447, lng: 19.6541592 },
    { lat: 48.8437552, lng: 19.6536741 },
    { lat: 48.8438797, lng: 19.6538856 },
    { lat: 48.8444543, lng: 19.6530765 },
    { lat: 48.8453577, lng: 19.6520644 },
    { lat: 48.8464978, lng: 19.6513815 },
    { lat: 48.8468604, lng: 19.6513543 },
    { lat: 48.8470072, lng: 19.6510569 },
    { lat: 48.8471976, lng: 19.6511601 },
    { lat: 48.8474846, lng: 19.6516282 },
    { lat: 48.847597, lng: 19.65165 },
    { lat: 48.8475619, lng: 19.6512665 },
    { lat: 48.8480535, lng: 19.6512232 },
    { lat: 48.8480732, lng: 19.6513106 },
    { lat: 48.8487494, lng: 19.6514429 },
    { lat: 48.8489978, lng: 19.6512013 },
    { lat: 48.8491384, lng: 19.6512706 },
    { lat: 48.8491386, lng: 19.6510484 },
    { lat: 48.8497742, lng: 19.6509753 },
    { lat: 48.8499943, lng: 19.6511613 },
    { lat: 48.8499555, lng: 19.6514286 },
    { lat: 48.8501496, lng: 19.651409 },
    { lat: 48.8505422, lng: 19.6517508 },
    { lat: 48.8509037, lng: 19.6518718 },
    { lat: 48.8511215, lng: 19.6521281 },
    { lat: 48.8513712, lng: 19.6520954 },
    { lat: 48.8515997, lng: 19.6521155 },
    { lat: 48.851701, lng: 19.6521896 },
    { lat: 48.8524187, lng: 19.6522411 },
    { lat: 48.8524896, lng: 19.6523905 },
    { lat: 48.8526065, lng: 19.6524594 },
    { lat: 48.8528534, lng: 19.6523364 },
    { lat: 48.8531823, lng: 19.6523835 },
    { lat: 48.8533671, lng: 19.6527838 },
    { lat: 48.8534709, lng: 19.6524111 },
    { lat: 48.8536361, lng: 19.6524997 },
    { lat: 48.85421, lng: 19.652361 },
    { lat: 48.8542773, lng: 19.6524571 },
    { lat: 48.8548049, lng: 19.6521063 },
    { lat: 48.8550415, lng: 19.6523611 },
    { lat: 48.8552237, lng: 19.6526541 },
    { lat: 48.8553089, lng: 19.6524421 },
    { lat: 48.8558107, lng: 19.6523551 },
    { lat: 48.8563984, lng: 19.6520644 },
    { lat: 48.8564466, lng: 19.6519081 },
    { lat: 48.8568319, lng: 19.6512517 },
    { lat: 48.856819, lng: 19.6509903 },
    { lat: 48.8566328, lng: 19.6509082 },
    { lat: 48.8566131, lng: 19.6512741 },
    { lat: 48.8565156, lng: 19.6514119 },
    { lat: 48.8565525, lng: 19.6515566 },
    { lat: 48.8564079, lng: 19.6515958 },
    { lat: 48.8563321, lng: 19.6517646 },
    { lat: 48.8562005, lng: 19.6518217 },
    { lat: 48.8561299, lng: 19.6519588 },
    { lat: 48.8558626, lng: 19.6519723 },
    { lat: 48.8557806, lng: 19.652131 },
    { lat: 48.8554162, lng: 19.6522769 },
    { lat: 48.8555169, lng: 19.6521302 },
    { lat: 48.8556109, lng: 19.6516608 },
    { lat: 48.8557296, lng: 19.6513434 },
    { lat: 48.8556917, lng: 19.6512768 },
    { lat: 48.8552932, lng: 19.6516168 },
    { lat: 48.8552692, lng: 19.6514272 },
    { lat: 48.8551634, lng: 19.6514581 },
    { lat: 48.8549116, lng: 19.6517197 },
    { lat: 48.8544905, lng: 19.6516545 },
    { lat: 48.854407, lng: 19.6515236 },
    { lat: 48.8543956, lng: 19.6513483 },
    { lat: 48.8543165, lng: 19.6512896 },
    { lat: 48.8543083, lng: 19.6511333 },
    { lat: 48.8544734, lng: 19.6511443 },
    { lat: 48.8544482, lng: 19.6509093 },
    { lat: 48.8543246, lng: 19.6508633 },
    { lat: 48.8542135, lng: 19.6506772 },
    { lat: 48.8542149, lng: 19.6505815 },
    { lat: 48.8543322, lng: 19.6504586 },
    { lat: 48.8544491, lng: 19.650428 },
    { lat: 48.8548709, lng: 19.6500075 },
    { lat: 48.8552689, lng: 19.6504386 },
    { lat: 48.8552914, lng: 19.6509204 },
    { lat: 48.8554755, lng: 19.6508775 },
    { lat: 48.8556605, lng: 19.6507318 },
    { lat: 48.8559623, lng: 19.6507549 },
    { lat: 48.8562107, lng: 19.6504392 },
    { lat: 48.8564083, lng: 19.6509241 },
    { lat: 48.8566862, lng: 19.6506916 },
    { lat: 48.85681, lng: 19.650661 },
    { lat: 48.8569217, lng: 19.6507902 },
    { lat: 48.8571589, lng: 19.6508814 },
    { lat: 48.8573733, lng: 19.6507885 },
    { lat: 48.8575321, lng: 19.6505844 },
    { lat: 48.8576938, lng: 19.6505209 },
    { lat: 48.857846, lng: 19.6508981 },
    { lat: 48.8584445, lng: 19.650778 },
    { lat: 48.8583394, lng: 19.6504699 },
    { lat: 48.8583947, lng: 19.650217 },
    { lat: 48.8582487, lng: 19.6500513 },
    { lat: 48.858511, lng: 19.6500992 },
    { lat: 48.8586777, lng: 19.6500216 },
    { lat: 48.8586833, lng: 19.6502253 },
    { lat: 48.8585634, lng: 19.6507704 },
    { lat: 48.8587137, lng: 19.6508258 },
    { lat: 48.8587758, lng: 19.6514324 },
    { lat: 48.8584577, lng: 19.6513781 },
    { lat: 48.858427, lng: 19.6512202 },
    { lat: 48.8583136, lng: 19.651302 },
    { lat: 48.8581021, lng: 19.6517417 },
    { lat: 48.8570859, lng: 19.6524711 },
    { lat: 48.856892, lng: 19.6528753 },
    { lat: 48.8561738, lng: 19.6527401 },
    { lat: 48.8559512, lng: 19.6528245 },
    { lat: 48.8557617, lng: 19.6530429 },
    { lat: 48.8561226, lng: 19.6531776 },
    { lat: 48.8560924, lng: 19.6537664 },
    { lat: 48.8562689, lng: 19.654159 },
    { lat: 48.8561083, lng: 19.6541208 },
    { lat: 48.8561172, lng: 19.654293 },
    { lat: 48.8561884, lng: 19.6544492 },
    { lat: 48.8562151, lng: 19.6548208 },
    { lat: 48.8561016, lng: 19.6550543 },
    { lat: 48.8559495, lng: 19.6549536 },
    { lat: 48.8559063, lng: 19.6545931 },
    { lat: 48.8556143, lng: 19.6542352 },
    { lat: 48.8549344, lng: 19.6538459 },
    { lat: 48.8548724, lng: 19.6535957 },
    { lat: 48.8546265, lng: 19.6534345 },
    { lat: 48.8541597, lng: 19.6533272 },
    { lat: 48.8540871, lng: 19.6531886 },
    { lat: 48.8539842, lng: 19.6533831 },
    { lat: 48.8538892, lng: 19.6545084 },
    { lat: 48.853905, lng: 19.6547401 },
    { lat: 48.8542473, lng: 19.6559157 },
    { lat: 48.855448, lng: 19.6556809 },
    { lat: 48.8554573, lng: 19.6558149 },
    { lat: 48.8563379, lng: 19.6558565 },
    { lat: 48.856458, lng: 19.6559193 },
    { lat: 48.8565907, lng: 19.6564693 },
    { lat: 48.8560549, lng: 19.6569375 },
    { lat: 48.8556626, lng: 19.6567799 },
    { lat: 48.8554178, lng: 19.6569993 },
    { lat: 48.8550123, lng: 19.6578584 },
    { lat: 48.8550345, lng: 19.6581535 },
    { lat: 48.8549632, lng: 19.6583765 },
    { lat: 48.8546984, lng: 19.6585994 },
    { lat: 48.8545276, lng: 19.659116 },
    { lat: 48.8559205, lng: 19.659072 },
    { lat: 48.8558772, lng: 19.6588582 },
    { lat: 48.856255, lng: 19.65796 },
    { lat: 48.8565282, lng: 19.6577967 },
    { lat: 48.8566557, lng: 19.6574956 },
    { lat: 48.8570061, lng: 19.6571673 },
    { lat: 48.8571363, lng: 19.6561718 },
    { lat: 48.8574275, lng: 19.656893 },
    { lat: 48.8576379, lng: 19.6569086 },
    { lat: 48.8578158, lng: 19.6560967 },
    { lat: 48.8584002, lng: 19.6566211 },
    { lat: 48.8581006, lng: 19.6573789 },
    { lat: 48.8580022, lng: 19.6578234 },
    { lat: 48.8581778, lng: 19.6578952 },
    { lat: 48.8580001, lng: 19.6587113 },
    { lat: 48.8579469, lng: 19.6594872 },
    { lat: 48.8575341, lng: 19.6598343 },
    { lat: 48.8574464, lng: 19.6597585 },
    { lat: 48.8574611, lng: 19.6601406 },
    { lat: 48.8578593, lng: 19.6600264 },
    { lat: 48.8581374, lng: 19.6598422 },
    { lat: 48.8584457, lng: 19.6597831 },
    { lat: 48.8588093, lng: 19.6600532 },
    { lat: 48.8597759, lng: 19.6600786 },
    { lat: 48.860048, lng: 19.659558 },
    { lat: 48.8599119, lng: 19.6587829 },
    { lat: 48.859697, lng: 19.6588869 },
    { lat: 48.859251, lng: 19.6584177 },
    { lat: 48.859184, lng: 19.6582486 },
    { lat: 48.8589626, lng: 19.6579854 },
    { lat: 48.8590702, lng: 19.6576931 },
    { lat: 48.8592186, lng: 19.6574649 },
    { lat: 48.8589697, lng: 19.6568596 },
    { lat: 48.8596152, lng: 19.6561729 },
    { lat: 48.8603657, lng: 19.6560755 },
    { lat: 48.8603979, lng: 19.6562419 },
    { lat: 48.8606555, lng: 19.6565854 },
    { lat: 48.8605239, lng: 19.6570963 },
    { lat: 48.8608849, lng: 19.657055 },
    { lat: 48.8610106, lng: 19.6575008 },
    { lat: 48.8610219, lng: 19.6577537 },
    { lat: 48.8609499, lng: 19.6579064 },
    { lat: 48.8608237, lng: 19.6580052 },
    { lat: 48.8607053, lng: 19.6578292 },
    { lat: 48.860477, lng: 19.6582662 },
    { lat: 48.8606982, lng: 19.658499 },
    { lat: 48.861177, lng: 19.658398 },
    { lat: 48.8616789, lng: 19.658688 },
    { lat: 48.8619223, lng: 19.6589951 },
    { lat: 48.8618807, lng: 19.6600786 },
    { lat: 48.8626552, lng: 19.6602658 },
    { lat: 48.8630464, lng: 19.6601967 },
    { lat: 48.8635867, lng: 19.6596263 },
    { lat: 48.8637135, lng: 19.6592401 },
    { lat: 48.8640376, lng: 19.659229 },
    { lat: 48.8642011, lng: 19.6593594 },
    { lat: 48.8647386, lng: 19.6594878 },
    { lat: 48.8673373, lng: 19.6548297 },
    { lat: 48.8702181, lng: 19.651042 },
    { lat: 48.8708704, lng: 19.6515956 },
    { lat: 48.8714808, lng: 19.6519162 },
    { lat: 48.8719582, lng: 19.652064 },
    { lat: 48.8725755, lng: 19.6519079 },
    { lat: 48.8727455, lng: 19.6511209 },
    { lat: 48.8736304, lng: 19.6501009 },
    { lat: 48.8724084, lng: 19.6494318 },
    { lat: 48.8724364, lng: 19.6493248 },
    { lat: 48.8704756, lng: 19.6482332 },
    { lat: 48.8705488, lng: 19.6475768 },
    { lat: 48.8706948, lng: 19.6469615 },
    { lat: 48.8708061, lng: 19.6461516 },
    { lat: 48.8708813, lng: 19.6461231 },
    { lat: 48.8705916, lng: 19.6456905 },
    { lat: 48.8705876, lng: 19.6454479 },
    { lat: 48.8704323, lng: 19.6454542 },
    { lat: 48.8703093, lng: 19.6452746 },
    { lat: 48.8702255, lng: 19.6450374 },
    { lat: 48.8700776, lng: 19.645156 },
    { lat: 48.8699484, lng: 19.6451657 },
    { lat: 48.869554, lng: 19.6450649 },
    { lat: 48.8695015, lng: 19.6448466 },
    { lat: 48.8693389, lng: 19.6449378 },
    { lat: 48.8692883, lng: 19.6447139 },
    { lat: 48.8691985, lng: 19.6446963 },
    { lat: 48.8690776, lng: 19.6444894 },
    { lat: 48.8687628, lng: 19.6443782 },
    { lat: 48.8685802, lng: 19.6445026 },
    { lat: 48.8684196, lng: 19.6443631 },
    { lat: 48.8682667, lng: 19.6447259 },
    { lat: 48.868097, lng: 19.6447581 },
    { lat: 48.8677497, lng: 19.6446266 },
    { lat: 48.8676136, lng: 19.6445029 },
    { lat: 48.8678037, lng: 19.6444726 },
    { lat: 48.8680329, lng: 19.6441054 },
    { lat: 48.8683526, lng: 19.6441763 },
    { lat: 48.8686204, lng: 19.6439917 },
    { lat: 48.8687551, lng: 19.6437258 },
    { lat: 48.868096, lng: 19.6428481 },
    { lat: 48.868007, lng: 19.6420932 },
    { lat: 48.8688511, lng: 19.6417926 },
    { lat: 48.8695321, lng: 19.6432139 },
    { lat: 48.8697675, lng: 19.6431622 },
    { lat: 48.8695344, lng: 19.6422112 },
    { lat: 48.8701043, lng: 19.64243 },
    { lat: 48.8705093, lng: 19.6432236 },
    { lat: 48.8707859, lng: 19.6433376 },
    { lat: 48.8710751, lng: 19.6427449 },
    { lat: 48.8712162, lng: 19.642701 },
    { lat: 48.871366, lng: 19.6422708 },
    { lat: 48.8711096, lng: 19.6417818 },
    { lat: 48.8710488, lng: 19.6414573 },
    { lat: 48.8714939, lng: 19.6408521 },
    { lat: 48.871553, lng: 19.6404365 },
    { lat: 48.8714707, lng: 19.6397796 },
    { lat: 48.8717873, lng: 19.6388533 },
    { lat: 48.8717051, lng: 19.6385508 },
    { lat: 48.8711759, lng: 19.6381335 },
    { lat: 48.870988, lng: 19.6374335 },
    { lat: 48.8706734, lng: 19.6372776 },
    { lat: 48.8706477, lng: 19.63698 },
    { lat: 48.8700689, lng: 19.6369877 },
    { lat: 48.8698524, lng: 19.6364076 },
    { lat: 48.8703089, lng: 19.6346995 },
    { lat: 48.8705842, lng: 19.6341035 },
    { lat: 48.8705312, lng: 19.6339794 },
    { lat: 48.8707731, lng: 19.6336936 },
    { lat: 48.8708745, lng: 19.633769 },
    { lat: 48.8712048, lng: 19.6335743 },
    { lat: 48.8713218, lng: 19.6336943 },
    { lat: 48.8721478, lng: 19.6333962 },
    { lat: 48.8733949, lng: 19.6344685 },
    { lat: 48.8736856, lng: 19.6348967 },
    { lat: 48.8737235, lng: 19.6352944 },
    { lat: 48.8736571, lng: 19.6361459 },
    { lat: 48.8741731, lng: 19.6366766 },
    { lat: 48.8744594, lng: 19.6364993 },
    { lat: 48.8745769, lng: 19.6360812 },
    { lat: 48.874603, lng: 19.6355044 },
    { lat: 48.8741653, lng: 19.6352209 },
    { lat: 48.8738454, lng: 19.6346952 },
    { lat: 48.8734631, lng: 19.6343841 },
    { lat: 48.8732875, lng: 19.6337329 },
    { lat: 48.873416, lng: 19.6332597 },
    { lat: 48.872909, lng: 19.6328055 },
    { lat: 48.8729925, lng: 19.631882 },
    { lat: 48.8732698, lng: 19.6318849 },
    { lat: 48.8736532, lng: 19.6310848 },
    { lat: 48.8743537, lng: 19.6312044 },
    { lat: 48.8748404, lng: 19.6307748 },
    { lat: 48.8753919, lng: 19.6305651 },
    { lat: 48.875365, lng: 19.630338 },
    { lat: 48.8745296, lng: 19.6301787 },
    { lat: 48.8737411, lng: 19.6304347 },
    { lat: 48.8737325, lng: 19.630117 },
    { lat: 48.8734851, lng: 19.6286224 },
    { lat: 48.873073, lng: 19.629006 },
    { lat: 48.8731298, lng: 19.6292654 },
    { lat: 48.8729344, lng: 19.6296835 },
    { lat: 48.87206, lng: 19.6298339 },
    { lat: 48.8717448, lng: 19.6290865 },
    { lat: 48.872343, lng: 19.6276553 },
    { lat: 48.8710631, lng: 19.6272512 },
    { lat: 48.8709673, lng: 19.6270532 },
    { lat: 48.8710236, lng: 19.6258727 },
    { lat: 48.8709867, lng: 19.6251767 },
    { lat: 48.8705149, lng: 19.6240773 },
    { lat: 48.870235, lng: 19.6222629 },
    { lat: 48.8695275, lng: 19.6230411 },
    { lat: 48.8689898, lng: 19.6217112 },
    { lat: 48.8684694, lng: 19.6224159 },
    { lat: 48.8685517, lng: 19.6229686 },
    { lat: 48.8688559, lng: 19.6234186 },
    { lat: 48.8686254, lng: 19.62368 },
    { lat: 48.8677521, lng: 19.6226726 },
    { lat: 48.8678663, lng: 19.6231582 },
    { lat: 48.868279, lng: 19.6235971 },
    { lat: 48.8691572, lng: 19.6245313 },
    { lat: 48.8700233, lng: 19.6249222 },
    { lat: 48.8701526, lng: 19.6259712 },
    { lat: 48.8700774, lng: 19.6266303 },
    { lat: 48.8699646, lng: 19.6271454 },
    { lat: 48.8699492, lng: 19.6272155 },
    { lat: 48.86995, lng: 19.6277061 },
    { lat: 48.8690355, lng: 19.6281684 },
    { lat: 48.8685502, lng: 19.6292535 },
    { lat: 48.8686212, lng: 19.6300092 },
    { lat: 48.868485, lng: 19.6320472 },
    { lat: 48.8685534, lng: 19.6321132 },
    { lat: 48.8686391, lng: 19.6330925 },
    { lat: 48.8685228, lng: 19.6335153 },
    { lat: 48.8680843, lng: 19.6345891 },
    { lat: 48.8679169, lng: 19.6346261 },
    { lat: 48.8675935, lng: 19.6343466 },
    { lat: 48.8673613, lng: 19.6345512 },
    { lat: 48.8671607, lng: 19.6351521 },
    { lat: 48.866705, lng: 19.6355884 },
    { lat: 48.8660545, lng: 19.6359353 },
    { lat: 48.8659665, lng: 19.6360589 },
    { lat: 48.8654525, lng: 19.6358001 },
    { lat: 48.8662192, lng: 19.6352122 },
    { lat: 48.8667339, lng: 19.6343754 },
    { lat: 48.8666706, lng: 19.6340222 },
    { lat: 48.8670661, lng: 19.6329433 },
    { lat: 48.8671967, lng: 19.6328124 },
    { lat: 48.867226, lng: 19.6313088 },
    { lat: 48.8668456, lng: 19.6311647 },
    { lat: 48.8667545, lng: 19.630167 },
    { lat: 48.8668144, lng: 19.6299912 },
    { lat: 48.8667952, lng: 19.6287113 },
    { lat: 48.8666976, lng: 19.6286734 },
    { lat: 48.8665387, lng: 19.6274432 },
    { lat: 48.8663727, lng: 19.6270792 },
    { lat: 48.8664308, lng: 19.6269705 },
    { lat: 48.8663688, lng: 19.6265453 },
    { lat: 48.8654761, lng: 19.6270884 },
    { lat: 48.8655109, lng: 19.6272644 },
    { lat: 48.8651964, lng: 19.6272582 },
    { lat: 48.8651855, lng: 19.6271427 },
    { lat: 48.8646899, lng: 19.6268216 },
    { lat: 48.8645636, lng: 19.6273947 },
    { lat: 48.8641602, lng: 19.6274432 },
    { lat: 48.8635393, lng: 19.6272141 },
    { lat: 48.8632188, lng: 19.6273528 },
    { lat: 48.8628516, lng: 19.6279826 },
    { lat: 48.8628322, lng: 19.6287336 },
    { lat: 48.8622923, lng: 19.6292033 },
    { lat: 48.8615734, lng: 19.6292799 },
    { lat: 48.861123, lng: 19.6291085 },
    { lat: 48.8608735, lng: 19.6286454 },
    { lat: 48.8614838, lng: 19.628488 },
    { lat: 48.8618028, lng: 19.6282675 },
    { lat: 48.8623922, lng: 19.6280425 },
    { lat: 48.862486, lng: 19.6276438 },
    { lat: 48.8622815, lng: 19.6269352 },
    { lat: 48.8624115, lng: 19.6265382 },
    { lat: 48.862004, lng: 19.6261698 },
    { lat: 48.8618196, lng: 19.6269321 },
    { lat: 48.8607122, lng: 19.6269118 },
    { lat: 48.8607999, lng: 19.6279856 },
    { lat: 48.8606679, lng: 19.628084 },
    { lat: 48.8606516, lng: 19.6284464 },
    { lat: 48.8601768, lng: 19.628212 },
    { lat: 48.8599963, lng: 19.6278828 },
    { lat: 48.8594111, lng: 19.6277492 },
    { lat: 48.858873, lng: 19.6268275 },
    { lat: 48.8596598, lng: 19.6266361 },
    { lat: 48.8597958, lng: 19.6263046 },
    { lat: 48.860116, lng: 19.6264308 },
    { lat: 48.860651, lng: 19.6257748 },
    { lat: 48.8605286, lng: 19.6254092 },
    { lat: 48.8590728, lng: 19.6262894 },
    { lat: 48.8589542, lng: 19.6260544 },
    { lat: 48.8587704, lng: 19.6261993 },
    { lat: 48.8587155, lng: 19.6260078 },
    { lat: 48.8588869, lng: 19.625808 },
    { lat: 48.8588567, lng: 19.6255417 },
    { lat: 48.8596013, lng: 19.6248343 },
    { lat: 48.8598405, lng: 19.6252408 },
    { lat: 48.8601758, lng: 19.6248044 },
    { lat: 48.8602874, lng: 19.6242042 },
    { lat: 48.8596606, lng: 19.6235432 },
    { lat: 48.8600086, lng: 19.6233437 },
    { lat: 48.8601089, lng: 19.6228914 },
    { lat: 48.8610514, lng: 19.622228 },
    { lat: 48.8610177, lng: 19.6219774 },
    { lat: 48.8604019, lng: 19.6221924 },
    { lat: 48.8600132, lng: 19.6219112 },
    { lat: 48.85982, lng: 19.6210752 },
    { lat: 48.8601581, lng: 19.6200265 },
    { lat: 48.8595403, lng: 19.6186636 },
    { lat: 48.8589321, lng: 19.6185436 },
    { lat: 48.8595199, lng: 19.6182878 },
    { lat: 48.8598023, lng: 19.6185775 },
    { lat: 48.8601833, lng: 19.6186079 },
    { lat: 48.8607226, lng: 19.6189229 },
    { lat: 48.8611442, lng: 19.6182753 },
    { lat: 48.8610203, lng: 19.6179503 },
    { lat: 48.8601297, lng: 19.6176891 },
    { lat: 48.8601665, lng: 19.6173572 },
    { lat: 48.8604603, lng: 19.6173282 },
    { lat: 48.8603069, lng: 19.6160963 },
    { lat: 48.85949, lng: 19.6158716 },
    { lat: 48.8594738, lng: 19.6157104 },
    { lat: 48.8592566, lng: 19.6155393 },
    { lat: 48.8588205, lng: 19.6155916 },
    { lat: 48.8587586, lng: 19.6153901 },
    { lat: 48.8590903, lng: 19.6151017 },
    { lat: 48.8596276, lng: 19.6153467 },
    { lat: 48.8596953, lng: 19.6148466 },
    { lat: 48.8585198, lng: 19.614366 },
    { lat: 48.8580976, lng: 19.6145753 },
    { lat: 48.8576771, lng: 19.6149945 },
    { lat: 48.8567253, lng: 19.6142245 },
    { lat: 48.8562971, lng: 19.6123498 },
    { lat: 48.8561959, lng: 19.6106016 },
    { lat: 48.8562938, lng: 19.6098508 },
    { lat: 48.855966, lng: 19.6094496 },
    { lat: 48.8559552, lng: 19.6089129 },
    { lat: 48.8559031, lng: 19.608757 },
    { lat: 48.8553581, lng: 19.6085139 },
    { lat: 48.8551845, lng: 19.6085651 },
    { lat: 48.8551123, lng: 19.6083633 },
    { lat: 48.8548415, lng: 19.6085527 },
    { lat: 48.8547633, lng: 19.6082427 },
    { lat: 48.8548656, lng: 19.6081856 },
    { lat: 48.8547406, lng: 19.6076121 },
    { lat: 48.8540918, lng: 19.6079169 },
    { lat: 48.8539142, lng: 19.6076816 },
    { lat: 48.8548149, lng: 19.6071868 },
    { lat: 48.8547314, lng: 19.6067132 },
    { lat: 48.8547625, lng: 19.6063299 },
    { lat: 48.8545731, lng: 19.6063496 },
    { lat: 48.8535172, lng: 19.6067931 },
    { lat: 48.8533462, lng: 19.6062534 },
    { lat: 48.853207, lng: 19.606188 },
    { lat: 48.8526587, lng: 19.6066219 },
    { lat: 48.8519322, lng: 19.6069784 },
    { lat: 48.8513899, lng: 19.6065712 },
    { lat: 48.8499647, lng: 19.6075234 },
    { lat: 48.8497192, lng: 19.6073034 },
    { lat: 48.8495266, lng: 19.607254 },
    { lat: 48.8492824, lng: 19.607309 },
    { lat: 48.8491013, lng: 19.6074658 },
    { lat: 48.8487403, lng: 19.6075354 },
    { lat: 48.8484988, lng: 19.6073751 },
    { lat: 48.8472432, lng: 19.6073706 },
    { lat: 48.8472395, lng: 19.6073098 },
    { lat: 48.8467617, lng: 19.6070849 },
    { lat: 48.8467206, lng: 19.6072218 },
    { lat: 48.8466382, lng: 19.6072679 },
    { lat: 48.8464758, lng: 19.6071649 },
    { lat: 48.8448805, lng: 19.6070475 },
    { lat: 48.8446162, lng: 19.6073331 },
    { lat: 48.8444764, lng: 19.6073798 },
    { lat: 48.8445026, lng: 19.607684 },
    { lat: 48.8444652, lng: 19.6077537 },
    { lat: 48.8446196, lng: 19.6081845 },
    { lat: 48.8446698, lng: 19.6084963 },
    { lat: 48.8448308, lng: 19.6084682 },
    { lat: 48.8449918, lng: 19.6088651 },
    { lat: 48.8448028, lng: 19.6090463 },
    { lat: 48.8450194, lng: 19.6095523 },
    { lat: 48.8450615, lng: 19.6099096 },
    { lat: 48.8449579, lng: 19.6103135 },
    { lat: 48.8449097, lng: 19.6114654 },
    { lat: 48.8447721, lng: 19.6122366 },
    { lat: 48.8443649, lng: 19.6118298 },
    { lat: 48.8437861, lng: 19.6099368 },
    { lat: 48.8437996, lng: 19.6097132 },
    { lat: 48.8436228, lng: 19.609714 },
    { lat: 48.8437081, lng: 19.6092798 },
    { lat: 48.8435138, lng: 19.6090195 },
    { lat: 48.843404, lng: 19.6087361 },
    { lat: 48.8431706, lng: 19.6078595 },
    { lat: 48.8425969, lng: 19.6068293 },
    { lat: 48.8426059, lng: 19.6067511 },
    { lat: 48.8424414, lng: 19.6066279 },
    { lat: 48.8423058, lng: 19.6058206 },
    { lat: 48.8420228, lng: 19.6055913 },
    { lat: 48.8419589, lng: 19.6050023 },
    { lat: 48.8418434, lng: 19.6048136 },
  ],
  Michalová: [
    { lat: 48.8035172, lng: 19.7758782 },
    { lat: 48.8028863, lng: 19.7755961 },
    { lat: 48.8020675, lng: 19.7750591 },
    { lat: 48.8000664, lng: 19.7741226 },
    { lat: 48.7980275, lng: 19.7733855 },
    { lat: 48.7974161, lng: 19.7729265 },
    { lat: 48.7963969, lng: 19.7718243 },
    { lat: 48.7952049, lng: 19.7708467 },
    { lat: 48.7928852, lng: 19.7691192 },
    { lat: 48.7916139, lng: 19.7687732 },
    { lat: 48.7912967, lng: 19.7687599 },
    { lat: 48.7902402, lng: 19.7689476 },
    { lat: 48.7892044, lng: 19.7693515 },
    { lat: 48.7874102, lng: 19.7705227 },
    { lat: 48.7857348, lng: 19.7724994 },
    { lat: 48.7853439, lng: 19.7724728 },
    { lat: 48.7848577, lng: 19.7727208 },
    { lat: 48.7834915, lng: 19.7732177 },
    { lat: 48.7818469, lng: 19.7737121 },
    { lat: 48.7807964, lng: 19.7741409 },
    { lat: 48.7797652, lng: 19.7740805 },
    { lat: 48.7786481, lng: 19.7733968 },
    { lat: 48.7776227, lng: 19.7724389 },
    { lat: 48.7750866, lng: 19.7715076 },
    { lat: 48.7726411, lng: 19.7701559 },
    { lat: 48.7724292, lng: 19.7699464 },
    { lat: 48.7699512, lng: 19.7689306 },
    { lat: 48.7686665, lng: 19.7675036 },
    { lat: 48.7681957, lng: 19.7671739 },
    { lat: 48.7680974, lng: 19.7670152 },
    { lat: 48.768165, lng: 19.7668824 },
    { lat: 48.7684338, lng: 19.7666158 },
    { lat: 48.7684462, lng: 19.7662835 },
    { lat: 48.7685397, lng: 19.7661284 },
    { lat: 48.7684993, lng: 19.7660521 },
    { lat: 48.7685481, lng: 19.7660126 },
    { lat: 48.7684747, lng: 19.7658253 },
    { lat: 48.7684805, lng: 19.765724 },
    { lat: 48.7686059, lng: 19.7656825 },
    { lat: 48.7685226, lng: 19.7654783 },
    { lat: 48.7685455, lng: 19.7653064 },
    { lat: 48.7684966, lng: 19.7652459 },
    { lat: 48.7685013, lng: 19.7651226 },
    { lat: 48.76842, lng: 19.765094 },
    { lat: 48.7683784, lng: 19.7649892 },
    { lat: 48.7668683, lng: 19.7660602 },
    { lat: 48.7656002, lng: 19.7686175 },
    { lat: 48.7629137, lng: 19.7743735 },
    { lat: 48.7617862, lng: 19.7764921 },
    { lat: 48.7601283, lng: 19.7759869 },
    { lat: 48.7593448, lng: 19.7789408 },
    { lat: 48.7603927, lng: 19.7791231 },
    { lat: 48.7565265, lng: 19.7863842 },
    { lat: 48.7556312, lng: 19.788432 },
    { lat: 48.7554121, lng: 19.7895321 },
    { lat: 48.7551382, lng: 19.7903038 },
    { lat: 48.7562399, lng: 19.7909734 },
    { lat: 48.7564552, lng: 19.7911787 },
    { lat: 48.756863, lng: 19.7923184 },
    { lat: 48.7571061, lng: 19.7926491 },
    { lat: 48.7574981, lng: 19.7916921 },
    { lat: 48.7576354, lng: 19.791868 },
    { lat: 48.7576055, lng: 19.7919356 },
    { lat: 48.7576881, lng: 19.7920162 },
    { lat: 48.7577188, lng: 19.7919457 },
    { lat: 48.7579501, lng: 19.7921615 },
    { lat: 48.7578959, lng: 19.7922883 },
    { lat: 48.7580155, lng: 19.7924206 },
    { lat: 48.7580581, lng: 19.7922618 },
    { lat: 48.7581674, lng: 19.792363 },
    { lat: 48.7580895, lng: 19.7925422 },
    { lat: 48.7581067, lng: 19.7926008 },
    { lat: 48.7581944, lng: 19.7926467 },
    { lat: 48.758273, lng: 19.7924621 },
    { lat: 48.7584784, lng: 19.7926523 },
    { lat: 48.7592955, lng: 19.7909794 },
    { lat: 48.7595633, lng: 19.7908945 },
    { lat: 48.7607459, lng: 19.7886387 },
    { lat: 48.7607329, lng: 19.7884925 },
    { lat: 48.7611495, lng: 19.7871705 },
    { lat: 48.7613698, lng: 19.78659 },
    { lat: 48.7613956, lng: 19.7866146 },
    { lat: 48.7615477, lng: 19.7863569 },
    { lat: 48.761643, lng: 19.7861117 },
    { lat: 48.7613509, lng: 19.7858499 },
    { lat: 48.7615403, lng: 19.7855642 },
    { lat: 48.7616353, lng: 19.7855222 },
    { lat: 48.7617549, lng: 19.7856285 },
    { lat: 48.7618696, lng: 19.7856281 },
    { lat: 48.7620297, lng: 19.7854982 },
    { lat: 48.7620349, lng: 19.7853106 },
    { lat: 48.7619036, lng: 19.7850384 },
    { lat: 48.7619352, lng: 19.7848063 },
    { lat: 48.7621386, lng: 19.7839904 },
    { lat: 48.7625083, lng: 19.7828883 },
    { lat: 48.762805, lng: 19.7825623 },
    { lat: 48.7631446, lng: 19.7824673 },
    { lat: 48.7640378, lng: 19.7823895 },
    { lat: 48.7641552, lng: 19.7824449 },
    { lat: 48.7644404, lng: 19.7824228 },
    { lat: 48.7650299, lng: 19.7820186 },
    { lat: 48.7650821, lng: 19.7814302 },
    { lat: 48.7656297, lng: 19.7813034 },
    { lat: 48.7661018, lng: 19.7813284 },
    { lat: 48.7662196, lng: 19.7828901 },
    { lat: 48.766341, lng: 19.7855967 },
    { lat: 48.7661644, lng: 19.7858129 },
    { lat: 48.7652425, lng: 19.7866027 },
    { lat: 48.7650423, lng: 19.7869702 },
    { lat: 48.7647106, lng: 19.7871708 },
    { lat: 48.7642405, lng: 19.7872398 },
    { lat: 48.7639286, lng: 19.7874766 },
    { lat: 48.7628599, lng: 19.7887778 },
    { lat: 48.7617878, lng: 19.7904663 },
    { lat: 48.7610538, lng: 19.7921874 },
    { lat: 48.7620514, lng: 19.7922779 },
    { lat: 48.762797, lng: 19.7922461 },
    { lat: 48.7633555, lng: 19.7924939 },
    { lat: 48.7638429, lng: 19.7927467 },
    { lat: 48.7641859, lng: 19.7930929 },
    { lat: 48.7645582, lng: 19.7936725 },
    { lat: 48.7657217, lng: 19.7946021 },
    { lat: 48.7660924, lng: 19.7950155 },
    { lat: 48.7669864, lng: 19.7967121 },
    { lat: 48.7691886, lng: 19.7988016 },
    { lat: 48.7705174, lng: 19.8009111 },
    { lat: 48.7715682, lng: 19.8033941 },
    { lat: 48.7720933, lng: 19.805828 },
    { lat: 48.7724064, lng: 19.8068408 },
    { lat: 48.7727758, lng: 19.8077484 },
    { lat: 48.7728327, lng: 19.8080417 },
    { lat: 48.7730692, lng: 19.8085079 },
    { lat: 48.7731929, lng: 19.8093331 },
    { lat: 48.77263, lng: 19.8134728 },
    { lat: 48.7720829, lng: 19.8150157 },
    { lat: 48.7717759, lng: 19.8156216 },
    { lat: 48.7714178, lng: 19.8162275 },
    { lat: 48.770848, lng: 19.8169597 },
    { lat: 48.7710733, lng: 19.8183124 },
    { lat: 48.7709487, lng: 19.8187105 },
    { lat: 48.7711587, lng: 19.8192904 },
    { lat: 48.7719311, lng: 19.8200363 },
    { lat: 48.771885, lng: 19.8205041 },
    { lat: 48.7721433, lng: 19.821605 },
    { lat: 48.7721419, lng: 19.8223047 },
    { lat: 48.7722518, lng: 19.8233328 },
    { lat: 48.7727418, lng: 19.8235103 },
    { lat: 48.7731457, lng: 19.8238632 },
    { lat: 48.7735737, lng: 19.8249069 },
    { lat: 48.7740548, lng: 19.8251903 },
    { lat: 48.774325, lng: 19.8251864 },
    { lat: 48.7743691, lng: 19.8254528 },
    { lat: 48.7746999, lng: 19.825405 },
    { lat: 48.7749962, lng: 19.8253621 },
    { lat: 48.7747994, lng: 19.8247762 },
    { lat: 48.7749508, lng: 19.8246512 },
    { lat: 48.7751554, lng: 19.824982 },
    { lat: 48.7757941, lng: 19.8244351 },
    { lat: 48.7761854, lng: 19.8252507 },
    { lat: 48.7774361, lng: 19.8258284 },
    { lat: 48.7775607, lng: 19.825582 },
    { lat: 48.7778214, lng: 19.8256977 },
    { lat: 48.7780197, lng: 19.8256452 },
    { lat: 48.7782136, lng: 19.825727 },
    { lat: 48.7783868, lng: 19.826191 },
    { lat: 48.7786665, lng: 19.826428 },
    { lat: 48.7789118, lng: 19.8259551 },
    { lat: 48.7791843, lng: 19.8261374 },
    { lat: 48.779585, lng: 19.8266178 },
    { lat: 48.7797392, lng: 19.8266204 },
    { lat: 48.7800294, lng: 19.8271017 },
    { lat: 48.7801357, lng: 19.8270869 },
    { lat: 48.7803179, lng: 19.827365 },
    { lat: 48.7803015, lng: 19.8275867 },
    { lat: 48.7810682, lng: 19.8284091 },
    { lat: 48.7820195, lng: 19.8288611 },
    { lat: 48.7823064, lng: 19.8290596 },
    { lat: 48.7825773, lng: 19.8290463 },
    { lat: 48.7830992, lng: 19.8296291 },
    { lat: 48.7835479, lng: 19.829376 },
    { lat: 48.7838151, lng: 19.82972 },
    { lat: 48.7846185, lng: 19.8295905 },
    { lat: 48.7847874, lng: 19.8295102 },
    { lat: 48.7853746, lng: 19.8299594 },
    { lat: 48.7855828, lng: 19.8300373 },
    { lat: 48.7862474, lng: 19.8308407 },
    { lat: 48.7864208, lng: 19.8314895 },
    { lat: 48.7867288, lng: 19.8316287 },
    { lat: 48.7872977, lng: 19.8320497 },
    { lat: 48.7880315, lng: 19.8294995 },
    { lat: 48.7885163, lng: 19.8236248 },
    { lat: 48.7882963, lng: 19.8208596 },
    { lat: 48.7897017, lng: 19.8182168 },
    { lat: 48.7916587, lng: 19.815101 },
    { lat: 48.7935598, lng: 19.8103105 },
    { lat: 48.7949551, lng: 19.8089215 },
    { lat: 48.7953956, lng: 19.8079351 },
    { lat: 48.7977967, lng: 19.8035326 },
    { lat: 48.7986576, lng: 19.8026015 },
    { lat: 48.8005395, lng: 19.7996889 },
    { lat: 48.8032774, lng: 19.7961961 },
    { lat: 48.8034488, lng: 19.7954246 },
    { lat: 48.8034386, lng: 19.795213 },
    { lat: 48.8030873, lng: 19.7945161 },
    { lat: 48.8030608, lng: 19.794245 },
    { lat: 48.8031823, lng: 19.7935167 },
    { lat: 48.8031983, lng: 19.7924347 },
    { lat: 48.8040667, lng: 19.7887846 },
    { lat: 48.8046212, lng: 19.7873415 },
    { lat: 48.8046389, lng: 19.7864309 },
    { lat: 48.8043199, lng: 19.7848763 },
    { lat: 48.8043028, lng: 19.7841968 },
    { lat: 48.8040413, lng: 19.7835207 },
    { lat: 48.8038811, lng: 19.7825399 },
    { lat: 48.8038677, lng: 19.7808637 },
    { lat: 48.8036633, lng: 19.780204 },
    { lat: 48.8034927, lng: 19.7792923 },
    { lat: 48.8035275, lng: 19.7786169 },
    { lat: 48.8034487, lng: 19.77738 },
    { lat: 48.8035538, lng: 19.7764288 },
    { lat: 48.8035172, lng: 19.7758782 },
  ],
  Bystrá: [
    { lat: 48.933463, lng: 19.6276383 },
    { lat: 48.9329835, lng: 19.6273703 },
    { lat: 48.9320236, lng: 19.6271728 },
    { lat: 48.9305461, lng: 19.6266102 },
    { lat: 48.929456, lng: 19.6264258 },
    { lat: 48.9286109, lng: 19.6258932 },
    { lat: 48.9284025, lng: 19.6257147 },
    { lat: 48.9280695, lng: 19.6254296 },
    { lat: 48.9270183, lng: 19.6248288 },
    { lat: 48.9267821, lng: 19.6246364 },
    { lat: 48.926687, lng: 19.6243743 },
    { lat: 48.9266713, lng: 19.6231734 },
    { lat: 48.9264911, lng: 19.6224711 },
    { lat: 48.9264366, lng: 19.6219572 },
    { lat: 48.9261467, lng: 19.621349 },
    { lat: 48.9260687, lng: 19.621041 },
    { lat: 48.9260936, lng: 19.6207443 },
    { lat: 48.9257076, lng: 19.6191253 },
    { lat: 48.9256329, lng: 19.6185152 },
    { lat: 48.9255269, lng: 19.6181364 },
    { lat: 48.9254912, lng: 19.6172153 },
    { lat: 48.9253334, lng: 19.6162624 },
    { lat: 48.9253248, lng: 19.6138408 },
    { lat: 48.9252147, lng: 19.6132982 },
    { lat: 48.9252107, lng: 19.6119819 },
    { lat: 48.9250369, lng: 19.6111256 },
    { lat: 48.9246058, lng: 19.6101545 },
    { lat: 48.922888, lng: 19.6091928 },
    { lat: 48.9226024, lng: 19.6089071 },
    { lat: 48.9223586, lng: 19.60842 },
    { lat: 48.9221144, lng: 19.6081784 },
    { lat: 48.9216285, lng: 19.6074452 },
    { lat: 48.9213766, lng: 19.6071833 },
    { lat: 48.9209691, lng: 19.6064857 },
    { lat: 48.9207636, lng: 19.6056554 },
    { lat: 48.9206082, lng: 19.6053036 },
    { lat: 48.9201956, lng: 19.6030753 },
    { lat: 48.9200869, lng: 19.6028164 },
    { lat: 48.9196476, lng: 19.6023676 },
    { lat: 48.9182499, lng: 19.6013057 },
    { lat: 48.9176004, lng: 19.6007221 },
    { lat: 48.9170672, lng: 19.6007675 },
    { lat: 48.9157738, lng: 19.601265 },
    { lat: 48.915432, lng: 19.6014814 },
    { lat: 48.9150091, lng: 19.6015479 },
    { lat: 48.9143341, lng: 19.6014245 },
    { lat: 48.913932, lng: 19.6011517 },
    { lat: 48.9134233, lng: 19.6006417 },
    { lat: 48.9129829, lng: 19.5999957 },
    { lat: 48.9126747, lng: 19.6000582 },
    { lat: 48.9123963, lng: 19.6004023 },
    { lat: 48.9123092, lng: 19.6003974 },
    { lat: 48.9112052, lng: 19.5996494 },
    { lat: 48.9102856, lng: 19.599573 },
    { lat: 48.9101193, lng: 19.5996816 },
    { lat: 48.909974, lng: 19.5999367 },
    { lat: 48.9098114, lng: 19.6000793 },
    { lat: 48.9089127, lng: 19.600136 },
    { lat: 48.9082293, lng: 19.5997986 },
    { lat: 48.9079504, lng: 19.5998588 },
    { lat: 48.9067132, lng: 19.600787 },
    { lat: 48.9064182, lng: 19.6008166 },
    { lat: 48.9059844, lng: 19.6007148 },
    { lat: 48.9057104, lng: 19.600361 },
    { lat: 48.9055425, lng: 19.5998122 },
    { lat: 48.9054372, lng: 19.5991988 },
    { lat: 48.9053359, lng: 19.5989787 },
    { lat: 48.905108, lng: 19.5988222 },
    { lat: 48.9037682, lng: 19.5984434 },
    { lat: 48.9033526, lng: 19.5985256 },
    { lat: 48.9011078, lng: 19.5998904 },
    { lat: 48.9004064, lng: 19.5996767 },
    { lat: 48.8999905, lng: 19.5992769 },
    { lat: 48.8994107, lng: 19.5993886 },
    { lat: 48.8989019, lng: 19.5993483 },
    { lat: 48.8984227, lng: 19.5990675 },
    { lat: 48.8977931, lng: 19.5990488 },
    { lat: 48.8968993, lng: 19.5997414 },
    { lat: 48.8963189, lng: 19.5999341 },
    { lat: 48.8961251, lng: 19.6001877 },
    { lat: 48.8956221, lng: 19.6003148 },
    { lat: 48.8951395, lng: 19.6007924 },
    { lat: 48.8947739, lng: 19.6007792 },
    { lat: 48.8942601, lng: 19.6003604 },
    { lat: 48.894042, lng: 19.6003144 },
    { lat: 48.8936661, lng: 19.600729 },
    { lat: 48.8928984, lng: 19.6006941 },
    { lat: 48.8924851, lng: 19.6010231 },
    { lat: 48.8920484, lng: 19.6009556 },
    { lat: 48.8917916, lng: 19.6010632 },
    { lat: 48.8912647, lng: 19.6014942 },
    { lat: 48.8908443, lng: 19.6016431 },
    { lat: 48.890489, lng: 19.6019077 },
    { lat: 48.8896926, lng: 19.6018396 },
    { lat: 48.8892509, lng: 19.6020852 },
    { lat: 48.8889531, lng: 19.6026088 },
    { lat: 48.8886629, lng: 19.602665 },
    { lat: 48.8876295, lng: 19.6025882 },
    { lat: 48.8869866, lng: 19.6024733 },
    { lat: 48.8867115, lng: 19.6021438 },
    { lat: 48.8861926, lng: 19.601758 },
    { lat: 48.8852589, lng: 19.6017181 },
    { lat: 48.8846895, lng: 19.601809 },
    { lat: 48.8833364, lng: 19.6024871 },
    { lat: 48.8828826, lng: 19.6026434 },
    { lat: 48.8822332, lng: 19.6033061 },
    { lat: 48.8818927, lng: 19.6035261 },
    { lat: 48.8813289, lng: 19.6035681 },
    { lat: 48.8808391, lng: 19.6033515 },
    { lat: 48.8802388, lng: 19.6036321 },
    { lat: 48.8796087, lng: 19.6037479 },
    { lat: 48.8790715, lng: 19.6037578 },
    { lat: 48.8786686, lng: 19.6040183 },
    { lat: 48.8782629, lng: 19.6041416 },
    { lat: 48.8777363, lng: 19.6039048 },
    { lat: 48.8772613, lng: 19.60389 },
    { lat: 48.8770087, lng: 19.6037662 },
    { lat: 48.876543, lng: 19.6039791 },
    { lat: 48.8762335, lng: 19.6040377 },
    { lat: 48.8758487, lng: 19.6045254 },
    { lat: 48.8748424, lng: 19.6048502 },
    { lat: 48.8746062, lng: 19.6054356 },
    { lat: 48.873441, lng: 19.6053573 },
    { lat: 48.8729651, lng: 19.605669 },
    { lat: 48.8727768, lng: 19.6060422 },
    { lat: 48.8725608, lng: 19.6061473 },
    { lat: 48.8722941, lng: 19.6061548 },
    { lat: 48.8720909, lng: 19.6060474 },
    { lat: 48.8718161, lng: 19.6061539 },
    { lat: 48.8716002, lng: 19.6060109 },
    { lat: 48.8712523, lng: 19.6060183 },
    { lat: 48.8707772, lng: 19.6058295 },
    { lat: 48.8702835, lng: 19.6061749 },
    { lat: 48.8700814, lng: 19.6066418 },
    { lat: 48.8696641, lng: 19.6065809 },
    { lat: 48.8694418, lng: 19.6066211 },
    { lat: 48.8689726, lng: 19.6064883 },
    { lat: 48.8687317, lng: 19.6063396 },
    { lat: 48.8684711, lng: 19.6060116 },
    { lat: 48.8683305, lng: 19.6057159 },
    { lat: 48.8678149, lng: 19.6052786 },
    { lat: 48.8674654, lng: 19.6051894 },
    { lat: 48.8669497, lng: 19.6050784 },
    { lat: 48.8662011, lng: 19.6052683 },
    { lat: 48.8658626, lng: 19.6050608 },
    { lat: 48.8651566, lng: 19.604819 },
    { lat: 48.8640564, lng: 19.6049577 },
    { lat: 48.8639414, lng: 19.6049306 },
    { lat: 48.8636341, lng: 19.6045412 },
    { lat: 48.8632146, lng: 19.6044319 },
    { lat: 48.8629569, lng: 19.6041634 },
    { lat: 48.8622898, lng: 19.6041405 },
    { lat: 48.861606, lng: 19.6039688 },
    { lat: 48.8608451, lng: 19.6043103 },
    { lat: 48.860508, lng: 19.604332 },
    { lat: 48.860232, lng: 19.6045055 },
    { lat: 48.859847, lng: 19.6045916 },
    { lat: 48.8594676, lng: 19.6044985 },
    { lat: 48.8591781, lng: 19.6043732 },
    { lat: 48.8589108, lng: 19.6038691 },
    { lat: 48.8587829, lng: 19.6037561 },
    { lat: 48.8588122, lng: 19.6035573 },
    { lat: 48.8581635, lng: 19.6026659 },
    { lat: 48.8574515, lng: 19.6028667 },
    { lat: 48.8570007, lng: 19.6026143 },
    { lat: 48.8557801, lng: 19.6026059 },
    { lat: 48.8554281, lng: 19.6030203 },
    { lat: 48.8548536, lng: 19.6034505 },
    { lat: 48.8545476, lng: 19.6038096 },
    { lat: 48.8541951, lng: 19.6038891 },
    { lat: 48.8535166, lng: 19.6035615 },
    { lat: 48.8528015, lng: 19.6036417 },
    { lat: 48.8508649, lng: 19.6042496 },
    { lat: 48.8506505, lng: 19.6045219 },
    { lat: 48.8496973, lng: 19.6048195 },
    { lat: 48.8495382, lng: 19.6049888 },
    { lat: 48.8485566, lng: 19.6049595 },
    { lat: 48.8480793, lng: 19.6045958 },
    { lat: 48.8480887, lng: 19.6043805 },
    { lat: 48.8465754, lng: 19.60448 },
    { lat: 48.8460714, lng: 19.6043974 },
    { lat: 48.845797, lng: 19.6041346 },
    { lat: 48.8455358, lng: 19.6035911 },
    { lat: 48.8456872, lng: 19.6028738 },
    { lat: 48.8459003, lng: 19.6027058 },
    { lat: 48.8460915, lng: 19.6026667 },
    { lat: 48.8465165, lng: 19.6021751 },
    { lat: 48.8468612, lng: 19.6021951 },
    { lat: 48.8467645, lng: 19.6016274 },
    { lat: 48.8459519, lng: 19.6015249 },
    { lat: 48.8457707, lng: 19.6014567 },
    { lat: 48.8456008, lng: 19.6012865 },
    { lat: 48.8454427, lng: 19.6008285 },
    { lat: 48.845294, lng: 19.6007295 },
    { lat: 48.8447044, lng: 19.5996723 },
    { lat: 48.8446897, lng: 19.5987981 },
    { lat: 48.8445677, lng: 19.5987424 },
    { lat: 48.8443975, lng: 19.598393 },
    { lat: 48.8441799, lng: 19.5975699 },
    { lat: 48.8437604, lng: 19.5965112 },
    { lat: 48.8437232, lng: 19.5960386 },
    { lat: 48.8436295, lng: 19.5960347 },
    { lat: 48.8435958, lng: 19.5959631 },
    { lat: 48.8428312, lng: 19.5967236 },
    { lat: 48.8425908, lng: 19.596765 },
    { lat: 48.8426745, lng: 19.5964225 },
    { lat: 48.8424972, lng: 19.5955137 },
    { lat: 48.8423968, lng: 19.5953882 },
    { lat: 48.8420208, lng: 19.5955942 },
    { lat: 48.8419189, lng: 19.595158 },
    { lat: 48.8415572, lng: 19.5952567 },
    { lat: 48.8416322, lng: 19.5957465 },
    { lat: 48.8411757, lng: 19.5959434 },
    { lat: 48.8412438, lng: 19.5963792 },
    { lat: 48.841351, lng: 19.596704 },
    { lat: 48.840944, lng: 19.5970495 },
    { lat: 48.8409422, lng: 19.5976053 },
    { lat: 48.8410497, lng: 19.5982387 },
    { lat: 48.8410743, lng: 19.5993576 },
    { lat: 48.8412432, lng: 19.6004522 },
    { lat: 48.8412495, lng: 19.6009416 },
    { lat: 48.8420229, lng: 19.6034696 },
    { lat: 48.8417742, lng: 19.6039289 },
    { lat: 48.8417616, lng: 19.6043506 },
    { lat: 48.8418236, lng: 19.6047244 },
    { lat: 48.8418434, lng: 19.6048136 },
    { lat: 48.8419589, lng: 19.6050023 },
    { lat: 48.8420228, lng: 19.6055913 },
    { lat: 48.8423058, lng: 19.6058206 },
    { lat: 48.8424414, lng: 19.6066279 },
    { lat: 48.8426059, lng: 19.6067511 },
    { lat: 48.8425969, lng: 19.6068293 },
    { lat: 48.8431706, lng: 19.6078595 },
    { lat: 48.843404, lng: 19.6087361 },
    { lat: 48.8435138, lng: 19.6090195 },
    { lat: 48.8437081, lng: 19.6092798 },
    { lat: 48.8436228, lng: 19.609714 },
    { lat: 48.8437996, lng: 19.6097132 },
    { lat: 48.8437861, lng: 19.6099368 },
    { lat: 48.8443649, lng: 19.6118298 },
    { lat: 48.8447721, lng: 19.6122366 },
    { lat: 48.8449097, lng: 19.6114654 },
    { lat: 48.8449579, lng: 19.6103135 },
    { lat: 48.8450615, lng: 19.6099096 },
    { lat: 48.8450194, lng: 19.6095523 },
    { lat: 48.8448028, lng: 19.6090463 },
    { lat: 48.8449918, lng: 19.6088651 },
    { lat: 48.8448308, lng: 19.6084682 },
    { lat: 48.8446698, lng: 19.6084963 },
    { lat: 48.8446196, lng: 19.6081845 },
    { lat: 48.8444652, lng: 19.6077537 },
    { lat: 48.8445026, lng: 19.607684 },
    { lat: 48.8444764, lng: 19.6073798 },
    { lat: 48.8446162, lng: 19.6073331 },
    { lat: 48.8448805, lng: 19.6070475 },
    { lat: 48.8464758, lng: 19.6071649 },
    { lat: 48.8466382, lng: 19.6072679 },
    { lat: 48.8467206, lng: 19.6072218 },
    { lat: 48.8467617, lng: 19.6070849 },
    { lat: 48.8472395, lng: 19.6073098 },
    { lat: 48.8472432, lng: 19.6073706 },
    { lat: 48.8484988, lng: 19.6073751 },
    { lat: 48.8487403, lng: 19.6075354 },
    { lat: 48.8491013, lng: 19.6074658 },
    { lat: 48.8492824, lng: 19.607309 },
    { lat: 48.8495266, lng: 19.607254 },
    { lat: 48.8497192, lng: 19.6073034 },
    { lat: 48.8499647, lng: 19.6075234 },
    { lat: 48.8513899, lng: 19.6065712 },
    { lat: 48.8519322, lng: 19.6069784 },
    { lat: 48.8526587, lng: 19.6066219 },
    { lat: 48.853207, lng: 19.606188 },
    { lat: 48.8533462, lng: 19.6062534 },
    { lat: 48.8535172, lng: 19.6067931 },
    { lat: 48.8545731, lng: 19.6063496 },
    { lat: 48.8547625, lng: 19.6063299 },
    { lat: 48.8547314, lng: 19.6067132 },
    { lat: 48.8548149, lng: 19.6071868 },
    { lat: 48.8539142, lng: 19.6076816 },
    { lat: 48.8540918, lng: 19.6079169 },
    { lat: 48.8547406, lng: 19.6076121 },
    { lat: 48.8548656, lng: 19.6081856 },
    { lat: 48.8547633, lng: 19.6082427 },
    { lat: 48.8548415, lng: 19.6085527 },
    { lat: 48.8551123, lng: 19.6083633 },
    { lat: 48.8551845, lng: 19.6085651 },
    { lat: 48.8553581, lng: 19.6085139 },
    { lat: 48.8559031, lng: 19.608757 },
    { lat: 48.8559552, lng: 19.6089129 },
    { lat: 48.855966, lng: 19.6094496 },
    { lat: 48.8562938, lng: 19.6098508 },
    { lat: 48.8561959, lng: 19.6106016 },
    { lat: 48.8562971, lng: 19.6123498 },
    { lat: 48.8567253, lng: 19.6142245 },
    { lat: 48.8576771, lng: 19.6149945 },
    { lat: 48.8580976, lng: 19.6145753 },
    { lat: 48.8585198, lng: 19.614366 },
    { lat: 48.8596953, lng: 19.6148466 },
    { lat: 48.8596276, lng: 19.6153467 },
    { lat: 48.8590903, lng: 19.6151017 },
    { lat: 48.8587586, lng: 19.6153901 },
    { lat: 48.8588205, lng: 19.6155916 },
    { lat: 48.8592566, lng: 19.6155393 },
    { lat: 48.8594738, lng: 19.6157104 },
    { lat: 48.85949, lng: 19.6158716 },
    { lat: 48.8603069, lng: 19.6160963 },
    { lat: 48.8604603, lng: 19.6173282 },
    { lat: 48.8601665, lng: 19.6173572 },
    { lat: 48.8601297, lng: 19.6176891 },
    { lat: 48.8610203, lng: 19.6179503 },
    { lat: 48.8611442, lng: 19.6182753 },
    { lat: 48.8607226, lng: 19.6189229 },
    { lat: 48.8601833, lng: 19.6186079 },
    { lat: 48.8598023, lng: 19.6185775 },
    { lat: 48.8595199, lng: 19.6182878 },
    { lat: 48.8589321, lng: 19.6185436 },
    { lat: 48.8595403, lng: 19.6186636 },
    { lat: 48.8601581, lng: 19.6200265 },
    { lat: 48.85982, lng: 19.6210752 },
    { lat: 48.8600132, lng: 19.6219112 },
    { lat: 48.8604019, lng: 19.6221924 },
    { lat: 48.8610177, lng: 19.6219774 },
    { lat: 48.8610514, lng: 19.622228 },
    { lat: 48.8601089, lng: 19.6228914 },
    { lat: 48.8600086, lng: 19.6233437 },
    { lat: 48.8596606, lng: 19.6235432 },
    { lat: 48.8602874, lng: 19.6242042 },
    { lat: 48.8601758, lng: 19.6248044 },
    { lat: 48.8598405, lng: 19.6252408 },
    { lat: 48.8596013, lng: 19.6248343 },
    { lat: 48.8588567, lng: 19.6255417 },
    { lat: 48.8588869, lng: 19.625808 },
    { lat: 48.8587155, lng: 19.6260078 },
    { lat: 48.8587704, lng: 19.6261993 },
    { lat: 48.8589542, lng: 19.6260544 },
    { lat: 48.8590728, lng: 19.6262894 },
    { lat: 48.8605286, lng: 19.6254092 },
    { lat: 48.860651, lng: 19.6257748 },
    { lat: 48.860116, lng: 19.6264308 },
    { lat: 48.8597958, lng: 19.6263046 },
    { lat: 48.8596598, lng: 19.6266361 },
    { lat: 48.858873, lng: 19.6268275 },
    { lat: 48.8594111, lng: 19.6277492 },
    { lat: 48.8599963, lng: 19.6278828 },
    { lat: 48.8601768, lng: 19.628212 },
    { lat: 48.8606516, lng: 19.6284464 },
    { lat: 48.8606679, lng: 19.628084 },
    { lat: 48.8607999, lng: 19.6279856 },
    { lat: 48.8607122, lng: 19.6269118 },
    { lat: 48.8618196, lng: 19.6269321 },
    { lat: 48.862004, lng: 19.6261698 },
    { lat: 48.8624115, lng: 19.6265382 },
    { lat: 48.8622815, lng: 19.6269352 },
    { lat: 48.862486, lng: 19.6276438 },
    { lat: 48.8623922, lng: 19.6280425 },
    { lat: 48.8618028, lng: 19.6282675 },
    { lat: 48.8614838, lng: 19.628488 },
    { lat: 48.8608735, lng: 19.6286454 },
    { lat: 48.861123, lng: 19.6291085 },
    { lat: 48.8615734, lng: 19.6292799 },
    { lat: 48.8622923, lng: 19.6292033 },
    { lat: 48.8628322, lng: 19.6287336 },
    { lat: 48.8628516, lng: 19.6279826 },
    { lat: 48.8632188, lng: 19.6273528 },
    { lat: 48.8635393, lng: 19.6272141 },
    { lat: 48.8641602, lng: 19.6274432 },
    { lat: 48.8645636, lng: 19.6273947 },
    { lat: 48.8646899, lng: 19.6268216 },
    { lat: 48.8651855, lng: 19.6271427 },
    { lat: 48.8651964, lng: 19.6272582 },
    { lat: 48.8655109, lng: 19.6272644 },
    { lat: 48.8654761, lng: 19.6270884 },
    { lat: 48.8663688, lng: 19.6265453 },
    { lat: 48.8664308, lng: 19.6269705 },
    { lat: 48.8663727, lng: 19.6270792 },
    { lat: 48.8665387, lng: 19.6274432 },
    { lat: 48.8666976, lng: 19.6286734 },
    { lat: 48.8667952, lng: 19.6287113 },
    { lat: 48.8668144, lng: 19.6299912 },
    { lat: 48.8667545, lng: 19.630167 },
    { lat: 48.8668456, lng: 19.6311647 },
    { lat: 48.867226, lng: 19.6313088 },
    { lat: 48.8671967, lng: 19.6328124 },
    { lat: 48.8670661, lng: 19.6329433 },
    { lat: 48.8666706, lng: 19.6340222 },
    { lat: 48.8667339, lng: 19.6343754 },
    { lat: 48.8662192, lng: 19.6352122 },
    { lat: 48.8654525, lng: 19.6358001 },
    { lat: 48.8659665, lng: 19.6360589 },
    { lat: 48.8660545, lng: 19.6359353 },
    { lat: 48.866705, lng: 19.6355884 },
    { lat: 48.8671607, lng: 19.6351521 },
    { lat: 48.8673613, lng: 19.6345512 },
    { lat: 48.8675935, lng: 19.6343466 },
    { lat: 48.8679169, lng: 19.6346261 },
    { lat: 48.8680843, lng: 19.6345891 },
    { lat: 48.8685228, lng: 19.6335153 },
    { lat: 48.8686391, lng: 19.6330925 },
    { lat: 48.8685534, lng: 19.6321132 },
    { lat: 48.868485, lng: 19.6320472 },
    { lat: 48.8686212, lng: 19.6300092 },
    { lat: 48.8685502, lng: 19.6292535 },
    { lat: 48.8690355, lng: 19.6281684 },
    { lat: 48.86995, lng: 19.6277061 },
    { lat: 48.8699492, lng: 19.6272155 },
    { lat: 48.8699646, lng: 19.6271454 },
    { lat: 48.8700774, lng: 19.6266303 },
    { lat: 48.8701526, lng: 19.6259712 },
    { lat: 48.8700233, lng: 19.6249222 },
    { lat: 48.8691572, lng: 19.6245313 },
    { lat: 48.868279, lng: 19.6235971 },
    { lat: 48.8678663, lng: 19.6231582 },
    { lat: 48.8677521, lng: 19.6226726 },
    { lat: 48.8686254, lng: 19.62368 },
    { lat: 48.8688559, lng: 19.6234186 },
    { lat: 48.8685517, lng: 19.6229686 },
    { lat: 48.8684694, lng: 19.6224159 },
    { lat: 48.8689898, lng: 19.6217112 },
    { lat: 48.8695275, lng: 19.6230411 },
    { lat: 48.870235, lng: 19.6222629 },
    { lat: 48.8705149, lng: 19.6240773 },
    { lat: 48.8709867, lng: 19.6251767 },
    { lat: 48.8710236, lng: 19.6258727 },
    { lat: 48.8709673, lng: 19.6270532 },
    { lat: 48.8710631, lng: 19.6272512 },
    { lat: 48.872343, lng: 19.6276553 },
    { lat: 48.8717448, lng: 19.6290865 },
    { lat: 48.87206, lng: 19.6298339 },
    { lat: 48.8729344, lng: 19.6296835 },
    { lat: 48.8731298, lng: 19.6292654 },
    { lat: 48.873073, lng: 19.629006 },
    { lat: 48.8734851, lng: 19.6286224 },
    { lat: 48.8737325, lng: 19.630117 },
    { lat: 48.8737411, lng: 19.6304347 },
    { lat: 48.8745296, lng: 19.6301787 },
    { lat: 48.875365, lng: 19.630338 },
    { lat: 48.8753919, lng: 19.6305651 },
    { lat: 48.8748404, lng: 19.6307748 },
    { lat: 48.8743537, lng: 19.6312044 },
    { lat: 48.8736532, lng: 19.6310848 },
    { lat: 48.8732698, lng: 19.6318849 },
    { lat: 48.8729925, lng: 19.631882 },
    { lat: 48.872909, lng: 19.6328055 },
    { lat: 48.873416, lng: 19.6332597 },
    { lat: 48.8732875, lng: 19.6337329 },
    { lat: 48.8734631, lng: 19.6343841 },
    { lat: 48.8738454, lng: 19.6346952 },
    { lat: 48.8741653, lng: 19.6352209 },
    { lat: 48.874603, lng: 19.6355044 },
    { lat: 48.8745769, lng: 19.6360812 },
    { lat: 48.8744594, lng: 19.6364993 },
    { lat: 48.8741731, lng: 19.6366766 },
    { lat: 48.8736571, lng: 19.6361459 },
    { lat: 48.8737235, lng: 19.6352944 },
    { lat: 48.8736856, lng: 19.6348967 },
    { lat: 48.8733949, lng: 19.6344685 },
    { lat: 48.8721478, lng: 19.6333962 },
    { lat: 48.8713218, lng: 19.6336943 },
    { lat: 48.8712048, lng: 19.6335743 },
    { lat: 48.8708745, lng: 19.633769 },
    { lat: 48.8707731, lng: 19.6336936 },
    { lat: 48.8705312, lng: 19.6339794 },
    { lat: 48.8705842, lng: 19.6341035 },
    { lat: 48.8703089, lng: 19.6346995 },
    { lat: 48.8698524, lng: 19.6364076 },
    { lat: 48.8700689, lng: 19.6369877 },
    { lat: 48.8706477, lng: 19.63698 },
    { lat: 48.8706734, lng: 19.6372776 },
    { lat: 48.870988, lng: 19.6374335 },
    { lat: 48.8711759, lng: 19.6381335 },
    { lat: 48.8717051, lng: 19.6385508 },
    { lat: 48.8717873, lng: 19.6388533 },
    { lat: 48.8714707, lng: 19.6397796 },
    { lat: 48.871553, lng: 19.6404365 },
    { lat: 48.8714939, lng: 19.6408521 },
    { lat: 48.8710488, lng: 19.6414573 },
    { lat: 48.8711096, lng: 19.6417818 },
    { lat: 48.871366, lng: 19.6422708 },
    { lat: 48.8712162, lng: 19.642701 },
    { lat: 48.8710751, lng: 19.6427449 },
    { lat: 48.8707859, lng: 19.6433376 },
    { lat: 48.8705093, lng: 19.6432236 },
    { lat: 48.8701043, lng: 19.64243 },
    { lat: 48.8695344, lng: 19.6422112 },
    { lat: 48.8697675, lng: 19.6431622 },
    { lat: 48.8695321, lng: 19.6432139 },
    { lat: 48.8688511, lng: 19.6417926 },
    { lat: 48.868007, lng: 19.6420932 },
    { lat: 48.868096, lng: 19.6428481 },
    { lat: 48.8687551, lng: 19.6437258 },
    { lat: 48.8686204, lng: 19.6439917 },
    { lat: 48.8683526, lng: 19.6441763 },
    { lat: 48.8680329, lng: 19.6441054 },
    { lat: 48.8678037, lng: 19.6444726 },
    { lat: 48.8676136, lng: 19.6445029 },
    { lat: 48.8677497, lng: 19.6446266 },
    { lat: 48.868097, lng: 19.6447581 },
    { lat: 48.8682667, lng: 19.6447259 },
    { lat: 48.8684196, lng: 19.6443631 },
    { lat: 48.8685802, lng: 19.6445026 },
    { lat: 48.8687628, lng: 19.6443782 },
    { lat: 48.8690776, lng: 19.6444894 },
    { lat: 48.8691985, lng: 19.6446963 },
    { lat: 48.8692883, lng: 19.6447139 },
    { lat: 48.8693389, lng: 19.6449378 },
    { lat: 48.8695015, lng: 19.6448466 },
    { lat: 48.869554, lng: 19.6450649 },
    { lat: 48.8699484, lng: 19.6451657 },
    { lat: 48.8700776, lng: 19.645156 },
    { lat: 48.8702255, lng: 19.6450374 },
    { lat: 48.8703093, lng: 19.6452746 },
    { lat: 48.8704323, lng: 19.6454542 },
    { lat: 48.8705876, lng: 19.6454479 },
    { lat: 48.8705916, lng: 19.6456905 },
    { lat: 48.8708813, lng: 19.6461231 },
    { lat: 48.8710595, lng: 19.6458986 },
    { lat: 48.871236, lng: 19.6455115 },
    { lat: 48.8715899, lng: 19.645285 },
    { lat: 48.8725856, lng: 19.6444474 },
    { lat: 48.8729187, lng: 19.644062 },
    { lat: 48.8737905, lng: 19.6436037 },
    { lat: 48.8751903, lng: 19.6430864 },
    { lat: 48.8775185, lng: 19.6419616 },
    { lat: 48.878426, lng: 19.6412639 },
    { lat: 48.8792107, lng: 19.6410768 },
    { lat: 48.8802522, lng: 19.6406578 },
    { lat: 48.8813462, lng: 19.6399534 },
    { lat: 48.8828419, lng: 19.6386291 },
    { lat: 48.8832871, lng: 19.6381583 },
    { lat: 48.8839113, lng: 19.6378577 },
    { lat: 48.8848164, lng: 19.6366314 },
    { lat: 48.8858248, lng: 19.6357717 },
    { lat: 48.8865929, lng: 19.6343571 },
    { lat: 48.8872932, lng: 19.632914 },
    { lat: 48.8880866, lng: 19.6318168 },
    { lat: 48.8889859, lng: 19.6302843 },
    { lat: 48.8893598, lng: 19.6299822 },
    { lat: 48.8900026, lng: 19.6299352 },
    { lat: 48.8907853, lng: 19.6297066 },
    { lat: 48.8917775, lng: 19.6295974 },
    { lat: 48.8923102, lng: 19.62944 },
    { lat: 48.8930618, lng: 19.6294674 },
    { lat: 48.8950884, lng: 19.6292077 },
    { lat: 48.8958297, lng: 19.6292064 },
    { lat: 48.8970911, lng: 19.6281878 },
    { lat: 48.9015244, lng: 19.6253184 },
    { lat: 48.914513, lng: 19.6175862 },
    { lat: 48.9155843, lng: 19.6177559 },
    { lat: 48.9176875, lng: 19.6186732 },
    { lat: 48.9256401, lng: 19.6379684 },
    { lat: 48.9264678, lng: 19.6388172 },
    { lat: 48.9274351, lng: 19.6392814 },
    { lat: 48.9333505, lng: 19.627966 },
    { lat: 48.933463, lng: 19.6276383 },
  ],
  Polomka: [
    { lat: 48.909632, lng: 19.881341 },
    { lat: 48.909856, lng: 19.880884 },
    { lat: 48.909562, lng: 19.879796 },
    { lat: 48.909464, lng: 19.878395 },
    { lat: 48.909042, lng: 19.877666 },
    { lat: 48.908246, lng: 19.877602 },
    { lat: 48.906898, lng: 19.874991 },
    { lat: 48.905815, lng: 19.872896 },
    { lat: 48.906031, lng: 19.870225 },
    { lat: 48.906474, lng: 19.868628 },
    { lat: 48.907185, lng: 19.866479 },
    { lat: 48.909005, lng: 19.863137 },
    { lat: 48.908229, lng: 19.862855 },
    { lat: 48.907358, lng: 19.860051 },
    { lat: 48.90806, lng: 19.856383 },
    { lat: 48.908153, lng: 19.855171 },
    { lat: 48.908082, lng: 19.85478 },
    { lat: 48.90794, lng: 19.854413 },
    { lat: 48.907827, lng: 19.85371 },
    { lat: 48.907719, lng: 19.852769 },
    { lat: 48.907678, lng: 19.852114 },
    { lat: 48.907583, lng: 19.851222 },
    { lat: 48.907377, lng: 19.850506 },
    { lat: 48.907202, lng: 19.849938 },
    { lat: 48.907149, lng: 19.849539 },
    { lat: 48.90705, lng: 19.849224 },
    { lat: 48.907009, lng: 19.84884 },
    { lat: 48.906794, lng: 19.847942 },
    { lat: 48.906848, lng: 19.847465 },
    { lat: 48.905967, lng: 19.846985 },
    { lat: 48.905284, lng: 19.844593 },
    { lat: 48.904715, lng: 19.842614 },
    { lat: 48.904455, lng: 19.840338 },
    { lat: 48.904222, lng: 19.83832 },
    { lat: 48.904838, lng: 19.837807 },
    { lat: 48.905034, lng: 19.837567 },
    { lat: 48.905352, lng: 19.837261 },
    { lat: 48.905575, lng: 19.836854 },
    { lat: 48.905879, lng: 19.835515 },
    { lat: 48.906265, lng: 19.834726 },
    { lat: 48.906126, lng: 19.832397 },
    { lat: 48.906473, lng: 19.830838 },
    { lat: 48.906734, lng: 19.829812 },
    { lat: 48.907261, lng: 19.828296 },
    { lat: 48.907835, lng: 19.827712 },
    { lat: 48.90806, lng: 19.827141 },
    { lat: 48.90813, lng: 19.826648 },
    { lat: 48.908033, lng: 19.825214 },
    { lat: 48.907778, lng: 19.824074 },
    { lat: 48.907995, lng: 19.823592 },
    { lat: 48.908358, lng: 19.822691 },
    { lat: 48.908956, lng: 19.82228 },
    { lat: 48.90941, lng: 19.821952 },
    { lat: 48.909864, lng: 19.821653 },
    { lat: 48.910125, lng: 19.821387 },
    { lat: 48.910535, lng: 19.821152 },
    { lat: 48.911021, lng: 19.821172 },
    { lat: 48.911127, lng: 19.82118 },
    { lat: 48.911378, lng: 19.820866 },
    { lat: 48.911885, lng: 19.820382 },
    { lat: 48.91194, lng: 19.820315 },
    { lat: 48.912369, lng: 19.819962 },
    { lat: 48.912538, lng: 19.819884 },
    { lat: 48.912706, lng: 19.81968 },
    { lat: 48.913222, lng: 19.818939 },
    { lat: 48.913417, lng: 19.818915 },
    { lat: 48.91378, lng: 19.818778 },
    { lat: 48.914716, lng: 19.818627 },
    { lat: 48.915145, lng: 19.818425 },
    { lat: 48.915499, lng: 19.818135 },
    { lat: 48.916109, lng: 19.8179 },
    { lat: 48.916365, lng: 19.817217 },
    { lat: 48.916217, lng: 19.816802 },
    { lat: 48.915899, lng: 19.816152 },
    { lat: 48.915551, lng: 19.815269 },
    { lat: 48.915158, lng: 19.814379 },
    { lat: 48.915017, lng: 19.81368 },
    { lat: 48.914822, lng: 19.812888 },
    { lat: 48.914755, lng: 19.812505 },
    { lat: 48.914618, lng: 19.811781 },
    { lat: 48.914426, lng: 19.810553 },
    { lat: 48.91436, lng: 19.810001 },
    { lat: 48.914328, lng: 19.809411 },
    { lat: 48.914333, lng: 19.809189 },
    { lat: 48.914387, lng: 19.808738 },
    { lat: 48.914541, lng: 19.807348 },
    { lat: 48.914962, lng: 19.806009 },
    { lat: 48.915553, lng: 19.804359 },
    { lat: 48.915471, lng: 19.804191 },
    { lat: 48.915421, lng: 19.804032 },
    { lat: 48.915107, lng: 19.803283 },
    { lat: 48.914884, lng: 19.802876 },
    { lat: 48.91474, lng: 19.802546 },
    { lat: 48.913616, lng: 19.80006 },
    { lat: 48.912959, lng: 19.799307 },
    { lat: 48.912528, lng: 19.798624 },
    { lat: 48.912395, lng: 19.797858 },
    { lat: 48.912366, lng: 19.797526 },
    { lat: 48.9123, lng: 19.796761 },
    { lat: 48.912848, lng: 19.795694 },
    { lat: 48.912652, lng: 19.79532 },
    { lat: 48.912526, lng: 19.794871 },
    { lat: 48.912532, lng: 19.794581 },
    { lat: 48.912524, lng: 19.794189 },
    { lat: 48.912447, lng: 19.793719 },
    { lat: 48.912307, lng: 19.79286 },
    { lat: 48.912032, lng: 19.792095 },
    { lat: 48.911586, lng: 19.791032 },
    { lat: 48.911445, lng: 19.790812 },
    { lat: 48.911338, lng: 19.790358 },
    { lat: 48.910875, lng: 19.789291 },
    { lat: 48.910776, lng: 19.78871 },
    { lat: 48.910671, lng: 19.788383 },
    { lat: 48.910592, lng: 19.787689 },
    { lat: 48.910597, lng: 19.786801 },
    { lat: 48.910204, lng: 19.786448 },
    { lat: 48.909724, lng: 19.78599 },
    { lat: 48.90957, lng: 19.785738 },
    { lat: 48.909376, lng: 19.78532 },
    { lat: 48.909314, lng: 19.785002 },
    { lat: 48.909182, lng: 19.784536 },
    { lat: 48.909128, lng: 19.784247 },
    { lat: 48.909031, lng: 19.783995 },
    { lat: 48.908889, lng: 19.783737 },
    { lat: 48.908587, lng: 19.783351 },
    { lat: 48.908441, lng: 19.782995 },
    { lat: 48.908324, lng: 19.782532 },
    { lat: 48.908241, lng: 19.781997 },
    { lat: 48.908253, lng: 19.781809 },
    { lat: 48.908019, lng: 19.781237 },
    { lat: 48.907841, lng: 19.780476 },
    { lat: 48.907266, lng: 19.779272 },
    { lat: 48.90706, lng: 19.778905 },
    { lat: 48.906588, lng: 19.777731 },
    { lat: 48.906434, lng: 19.777552 },
    { lat: 48.906281, lng: 19.777233 },
    { lat: 48.906102, lng: 19.777009 },
    { lat: 48.905189, lng: 19.7776197 },
    { lat: 48.9032703, lng: 19.7791664 },
    { lat: 48.9030709, lng: 19.7795262 },
    { lat: 48.9015147, lng: 19.7814074 },
    { lat: 48.900853, lng: 19.7827685 },
    { lat: 48.9003613, lng: 19.7834591 },
    { lat: 48.8994672, lng: 19.7842687 },
    { lat: 48.8989242, lng: 19.7849686 },
    { lat: 48.8981411, lng: 19.7863611 },
    { lat: 48.8977952, lng: 19.7866761 },
    { lat: 48.8970293, lng: 19.7870914 },
    { lat: 48.8963909, lng: 19.7878005 },
    { lat: 48.8955156, lng: 19.7884982 },
    { lat: 48.8950704, lng: 19.7889877 },
    { lat: 48.8946415, lng: 19.7892533 },
    { lat: 48.8944195, lng: 19.789562 },
    { lat: 48.8934174, lng: 19.7913656 },
    { lat: 48.8929502, lng: 19.7916039 },
    { lat: 48.8923186, lng: 19.7916391 },
    { lat: 48.8912091, lng: 19.7919813 },
    { lat: 48.8908405, lng: 19.7920292 },
    { lat: 48.8901064, lng: 19.7928683 },
    { lat: 48.8897095, lng: 19.7926552 },
    { lat: 48.8881347, lng: 19.7949648 },
    { lat: 48.8875664, lng: 19.7955217 },
    { lat: 48.8860672, lng: 19.7959703 },
    { lat: 48.8849994, lng: 19.7964642 },
    { lat: 48.883418, lng: 19.7979766 },
    { lat: 48.8829776, lng: 19.7982883 },
    { lat: 48.8824263, lng: 19.798542 },
    { lat: 48.8818348, lng: 19.7990348 },
    { lat: 48.8811624, lng: 19.7990375 },
    { lat: 48.8803444, lng: 19.7992924 },
    { lat: 48.880124, lng: 19.7992789 },
    { lat: 48.8796784, lng: 19.7994988 },
    { lat: 48.8794245, lng: 19.7997199 },
    { lat: 48.8790384, lng: 19.8007119 },
    { lat: 48.878782, lng: 19.8008803 },
    { lat: 48.8784471, lng: 19.8013382 },
    { lat: 48.8781633, lng: 19.8015504 },
    { lat: 48.8768533, lng: 19.8029849 },
    { lat: 48.875488, lng: 19.8035051 },
    { lat: 48.8751664, lng: 19.8034387 },
    { lat: 48.8748017, lng: 19.8039392 },
    { lat: 48.8744791, lng: 19.8041661 },
    { lat: 48.8742887, lng: 19.8043747 },
    { lat: 48.8739568, lng: 19.8044622 },
    { lat: 48.8734057, lng: 19.8042738 },
    { lat: 48.8731158, lng: 19.8042418 },
    { lat: 48.8728293, lng: 19.8043631 },
    { lat: 48.8724204, lng: 19.8047545 },
    { lat: 48.872245, lng: 19.8048368 },
    { lat: 48.8718756, lng: 19.8048933 },
    { lat: 48.8714265, lng: 19.805108 },
    { lat: 48.8709133, lng: 19.8052017 },
    { lat: 48.8706626, lng: 19.8053158 },
    { lat: 48.8686695, lng: 19.805469 },
    { lat: 48.8679047, lng: 19.8058572 },
    { lat: 48.8662322, lng: 19.8055869 },
    { lat: 48.8656339, lng: 19.8053982 },
    { lat: 48.8649374, lng: 19.8053907 },
    { lat: 48.8645424, lng: 19.8054826 },
    { lat: 48.8638601, lng: 19.8060082 },
    { lat: 48.8616704, lng: 19.8064589 },
    { lat: 48.8607685, lng: 19.8068364 },
    { lat: 48.8600037, lng: 19.8079265 },
    { lat: 48.8589358, lng: 19.8092317 },
    { lat: 48.857769, lng: 19.8102505 },
    { lat: 48.8569354, lng: 19.8110833 },
    { lat: 48.8563478, lng: 19.81177 },
    { lat: 48.8544637, lng: 19.8147252 },
    { lat: 48.8516997, lng: 19.818174 },
    { lat: 48.8510668, lng: 19.8193247 },
    { lat: 48.8506224, lng: 19.819885 },
    { lat: 48.8500028, lng: 19.8204609 },
    { lat: 48.8490368, lng: 19.8212022 },
    { lat: 48.8491013, lng: 19.8213896 },
    { lat: 48.8489025, lng: 19.8225522 },
    { lat: 48.848704, lng: 19.8229614 },
    { lat: 48.8483761, lng: 19.823949 },
    { lat: 48.8481558, lng: 19.8238832 },
    { lat: 48.8478415, lng: 19.8235489 },
    { lat: 48.8476636, lng: 19.8231087 },
    { lat: 48.847522, lng: 19.8232665 },
    { lat: 48.8459023, lng: 19.8240321 },
    { lat: 48.8414414, lng: 19.8243209 },
    { lat: 48.8372984, lng: 19.8258999 },
    { lat: 48.8361952, lng: 19.826232 },
    { lat: 48.8339358, lng: 19.8272038 },
    { lat: 48.8322744, lng: 19.8273699 },
    { lat: 48.8316042, lng: 19.8270845 },
    { lat: 48.8302647, lng: 19.8268977 },
    { lat: 48.8285803, lng: 19.8269724 },
    { lat: 48.8278045, lng: 19.8277133 },
    { lat: 48.8271956, lng: 19.8284547 },
    { lat: 48.8261637, lng: 19.8294501 },
    { lat: 48.8251945, lng: 19.8301671 },
    { lat: 48.8244978, lng: 19.8308382 },
    { lat: 48.822902, lng: 19.8320134 },
    { lat: 48.8215912, lng: 19.8340108 },
    { lat: 48.8201774, lng: 19.8349247 },
    { lat: 48.8189149, lng: 19.8342038 },
    { lat: 48.8182083, lng: 19.8344046 },
    { lat: 48.8174239, lng: 19.8347392 },
    { lat: 48.8166601, lng: 19.8354407 },
    { lat: 48.8155735, lng: 19.8367064 },
    { lat: 48.8149359, lng: 19.8372799 },
    { lat: 48.8136029, lng: 19.8388147 },
    { lat: 48.8113358, lng: 19.8423335 },
    { lat: 48.8095974, lng: 19.8428802 },
    { lat: 48.8074935, lng: 19.8438035 },
    { lat: 48.805044, lng: 19.8458341 },
    { lat: 48.8035915, lng: 19.8471847 },
    { lat: 48.8022169, lng: 19.8464995 },
    { lat: 48.8012387, lng: 19.8461497 },
    { lat: 48.7990747, lng: 19.8458651 },
    { lat: 48.7984867, lng: 19.8456834 },
    { lat: 48.7975663, lng: 19.8451371 },
    { lat: 48.7969499, lng: 19.8450268 },
    { lat: 48.796252, lng: 19.8446723 },
    { lat: 48.7957063, lng: 19.844768 },
    { lat: 48.7948052, lng: 19.8446915 },
    { lat: 48.7934661, lng: 19.8439919 },
    { lat: 48.7919579, lng: 19.8436592 },
    { lat: 48.7909435, lng: 19.8440626 },
    { lat: 48.7907117, lng: 19.8447272 },
    { lat: 48.7897653, lng: 19.846189 },
    { lat: 48.7893072, lng: 19.8478426 },
    { lat: 48.7874153, lng: 19.8512062 },
    { lat: 48.7848617, lng: 19.8534149 },
    { lat: 48.7843493, lng: 19.8539162 },
    { lat: 48.784222, lng: 19.8543106 },
    { lat: 48.784104, lng: 19.8550252 },
    { lat: 48.784184, lng: 19.8554696 },
    { lat: 48.7841248, lng: 19.8559256 },
    { lat: 48.7841735, lng: 19.856219 },
    { lat: 48.7840654, lng: 19.8564544 },
    { lat: 48.784147, lng: 19.857069 },
    { lat: 48.7844102, lng: 19.8583491 },
    { lat: 48.7839713, lng: 19.8603738 },
    { lat: 48.7838473, lng: 19.8612127 },
    { lat: 48.7836639, lng: 19.8614547 },
    { lat: 48.7833494, lng: 19.86213 },
    { lat: 48.7831426, lng: 19.8629048 },
    { lat: 48.7819003, lng: 19.8651684 },
    { lat: 48.7813924, lng: 19.8674245 },
    { lat: 48.7821315, lng: 19.8699523 },
    { lat: 48.7823597, lng: 19.8709908 },
    { lat: 48.7829041, lng: 19.872572 },
    { lat: 48.7828235, lng: 19.8728775 },
    { lat: 48.7830901, lng: 19.8738951 },
    { lat: 48.7833919, lng: 19.8762049 },
    { lat: 48.7830396, lng: 19.8769214 },
    { lat: 48.7828704, lng: 19.8770751 },
    { lat: 48.782594, lng: 19.8774845 },
    { lat: 48.7822726, lng: 19.8781886 },
    { lat: 48.7821044, lng: 19.8783897 },
    { lat: 48.7819537, lng: 19.8788435 },
    { lat: 48.7814307, lng: 19.8794981 },
    { lat: 48.780031, lng: 19.8804836 },
    { lat: 48.7794804, lng: 19.8806183 },
    { lat: 48.7790575, lng: 19.8808476 },
    { lat: 48.7786485, lng: 19.8809205 },
    { lat: 48.7776463, lng: 19.8812769 },
    { lat: 48.7766715, lng: 19.8822393 },
    { lat: 48.7764537, lng: 19.8825438 },
    { lat: 48.7760617, lng: 19.8828604 },
    { lat: 48.7754467, lng: 19.8832068 },
    { lat: 48.7751406, lng: 19.8837671 },
    { lat: 48.7750072, lng: 19.8838594 },
    { lat: 48.7742524, lng: 19.8848119 },
    { lat: 48.7739534, lng: 19.8850083 },
    { lat: 48.7733511, lng: 19.8852037 },
    { lat: 48.772064, lng: 19.885814 },
    { lat: 48.773418, lng: 19.886122 },
    { lat: 48.775804, lng: 19.886842 },
    { lat: 48.777057, lng: 19.886558 },
    { lat: 48.777201, lng: 19.886525 },
    { lat: 48.778534, lng: 19.887181 },
    { lat: 48.778669, lng: 19.887247 },
    { lat: 48.77962, lng: 19.887113 },
    { lat: 48.780414, lng: 19.887984 },
    { lat: 48.781776, lng: 19.889394 },
    { lat: 48.781885, lng: 19.889507 },
    { lat: 48.7831, lng: 19.891075 },
    { lat: 48.783586, lng: 19.891301 },
    { lat: 48.783686, lng: 19.893448 },
    { lat: 48.783828, lng: 19.894851 },
    { lat: 48.783468, lng: 19.895682 },
    { lat: 48.783435, lng: 19.895762 },
    { lat: 48.783115, lng: 19.8965 },
    { lat: 48.782501, lng: 19.897169 },
    { lat: 48.78247, lng: 19.897368 },
    { lat: 48.782383, lng: 19.897917 },
    { lat: 48.782324, lng: 19.898294 },
    { lat: 48.781993, lng: 19.900386 },
    { lat: 48.781946, lng: 19.900733 },
    { lat: 48.781713, lng: 19.902529 },
    { lat: 48.781714, lng: 19.902568 },
    { lat: 48.781496, lng: 19.902967 },
    { lat: 48.781355, lng: 19.903268 },
    { lat: 48.781296, lng: 19.903313 },
    { lat: 48.78129, lng: 19.903388 },
    { lat: 48.78104, lng: 19.90393 },
    { lat: 48.780813, lng: 19.904345 },
    { lat: 48.780778, lng: 19.904476 },
    { lat: 48.780768, lng: 19.90463 },
    { lat: 48.78067, lng: 19.905042 },
    { lat: 48.780617, lng: 19.905493 },
    { lat: 48.780567, lng: 19.905581 },
    { lat: 48.780322, lng: 19.905793 },
    { lat: 48.780215, lng: 19.906093 },
    { lat: 48.780106, lng: 19.906284 },
    { lat: 48.780034, lng: 19.906663 },
    { lat: 48.779946, lng: 19.906818 },
    { lat: 48.779729, lng: 19.907345 },
    { lat: 48.779707, lng: 19.907755 },
    { lat: 48.779652, lng: 19.907902 },
    { lat: 48.779574, lng: 19.908419 },
    { lat: 48.779495, lng: 19.908759 },
    { lat: 48.779327, lng: 19.909308 },
    { lat: 48.779301, lng: 19.910318 },
    { lat: 48.779281, lng: 19.910557 },
    { lat: 48.779115, lng: 19.911129 },
    { lat: 48.779169, lng: 19.911436 },
    { lat: 48.77913, lng: 19.911642 },
    { lat: 48.779017, lng: 19.912101 },
    { lat: 48.779039, lng: 19.912381 },
    { lat: 48.778989, lng: 19.912618 },
    { lat: 48.778899, lng: 19.912855 },
    { lat: 48.77884, lng: 19.913108 },
    { lat: 48.778982, lng: 19.913511 },
    { lat: 48.779408, lng: 19.914634 },
    { lat: 48.778749, lng: 19.915603 },
    { lat: 48.778744, lng: 19.915577 },
    { lat: 48.778439, lng: 19.915701 },
    { lat: 48.778321, lng: 19.915842 },
    { lat: 48.778034, lng: 19.916357 },
    { lat: 48.777763, lng: 19.916717 },
    { lat: 48.777588, lng: 19.916844 },
    { lat: 48.777598, lng: 19.916861 },
    { lat: 48.776775, lng: 19.917462 },
    { lat: 48.776685, lng: 19.917725 },
    { lat: 48.776656, lng: 19.917818 },
    { lat: 48.77664, lng: 19.917872 },
    { lat: 48.776788, lng: 19.918021 },
    { lat: 48.77677, lng: 19.918082 },
    { lat: 48.776796, lng: 19.918152 },
    { lat: 48.776777, lng: 19.918207 },
    { lat: 48.776898, lng: 19.918294 },
    { lat: 48.776938, lng: 19.918338 },
    { lat: 48.777034, lng: 19.918388 },
    { lat: 48.777335, lng: 19.918761 },
    { lat: 48.77763, lng: 19.918998 },
    { lat: 48.777931, lng: 19.919118 },
    { lat: 48.778362, lng: 19.919074 },
    { lat: 48.779838, lng: 19.920373 },
    { lat: 48.77988, lng: 19.9208 },
    { lat: 48.779943, lng: 19.920979 },
    { lat: 48.7802949, lng: 19.9213949 },
    { lat: 48.780482, lng: 19.921616 },
    { lat: 48.780745, lng: 19.922299 },
    { lat: 48.781224, lng: 19.923053 },
    { lat: 48.781499, lng: 19.92333 },
    { lat: 48.7828, lng: 19.923976 },
    { lat: 48.783067, lng: 19.924523 },
    { lat: 48.783242, lng: 19.925392 },
    { lat: 48.7833042, lng: 19.9254857 },
    { lat: 48.783749, lng: 19.926156 },
    { lat: 48.78378, lng: 19.926165 },
    { lat: 48.783812, lng: 19.926155 },
    { lat: 48.784563, lng: 19.925654 },
    { lat: 48.785018, lng: 19.925729 },
    { lat: 48.785043, lng: 19.92574 },
    { lat: 48.785064, lng: 19.925773 },
    { lat: 48.785237, lng: 19.92617 },
    { lat: 48.785247, lng: 19.926233 },
    { lat: 48.78524, lng: 19.926296 },
    { lat: 48.7851348, lng: 19.9267225 },
    { lat: 48.785071, lng: 19.926981 },
    { lat: 48.78515, lng: 19.928727 },
    { lat: 48.78545, lng: 19.929367 },
    { lat: 48.786071, lng: 19.929795 },
    { lat: 48.786276, lng: 19.930323 },
    { lat: 48.786627, lng: 19.930767 },
    { lat: 48.786743, lng: 19.931102 },
    { lat: 48.78666, lng: 19.931626 },
    { lat: 48.786659, lng: 19.931692 },
    { lat: 48.786664, lng: 19.931762 },
    { lat: 48.786692, lng: 19.931994 },
    { lat: 48.786696, lng: 19.932046 },
    { lat: 48.786687, lng: 19.932091 },
    { lat: 48.786319, lng: 19.933429 },
    { lat: 48.786307, lng: 19.933492 },
    { lat: 48.786308, lng: 19.933546 },
    { lat: 48.786372, lng: 19.934579 },
    { lat: 48.78637, lng: 19.934647 },
    { lat: 48.786108, lng: 19.935189 },
    { lat: 48.785697, lng: 19.936249 },
    { lat: 48.785676, lng: 19.936338 },
    { lat: 48.785577, lng: 19.936725 },
    { lat: 48.785581, lng: 19.936836 },
    { lat: 48.785947, lng: 19.937393 },
    { lat: 48.785956, lng: 19.937446 },
    { lat: 48.785873, lng: 19.93801 },
    { lat: 48.785876, lng: 19.938067 },
    { lat: 48.785904, lng: 19.938124 },
    { lat: 48.78626, lng: 19.938603 },
    { lat: 48.786581, lng: 19.938577 },
    { lat: 48.7867541, lng: 19.9388067 },
    { lat: 48.786835, lng: 19.938914 },
    { lat: 48.7868949, lng: 19.9387265 },
    { lat: 48.787006, lng: 19.9374898 },
    { lat: 48.7923619, lng: 19.9238877 },
    { lat: 48.7940605, lng: 19.9155217 },
    { lat: 48.796106, lng: 19.91239 },
    { lat: 48.796374, lng: 19.912599 },
    { lat: 48.7986789, lng: 19.911517 },
    { lat: 48.7999859, lng: 19.9116555 },
    { lat: 48.8023509, lng: 19.9164193 },
    { lat: 48.8030249, lng: 19.9162419 },
    { lat: 48.8036038, lng: 19.916262 },
    { lat: 48.8042243, lng: 19.9167373 },
    { lat: 48.8049296, lng: 19.9168974 },
    { lat: 48.8059896, lng: 19.9174303 },
    { lat: 48.8101497, lng: 19.9175613 },
    { lat: 48.8115233, lng: 19.916245 },
    { lat: 48.8124175, lng: 19.9147548 },
    { lat: 48.8266309, lng: 19.8998362 },
    { lat: 48.8263399, lng: 19.897238 },
    { lat: 48.8267016, lng: 19.8967911 },
    { lat: 48.8275208, lng: 19.8973249 },
    { lat: 48.8287053, lng: 19.8968645 },
    { lat: 48.8288461, lng: 19.8972598 },
    { lat: 48.8294495, lng: 19.8968518 },
    { lat: 48.8298035, lng: 19.8964897 },
    { lat: 48.830747, lng: 19.896001 },
    { lat: 48.8313888, lng: 19.8963115 },
    { lat: 48.8325408, lng: 19.8967005 },
    { lat: 48.8326918, lng: 19.8964659 },
    { lat: 48.8333132, lng: 19.8965346 },
    { lat: 48.8336351, lng: 19.8961845 },
    { lat: 48.8353938, lng: 19.8961877 },
    { lat: 48.8366773, lng: 19.8959893 },
    { lat: 48.8373518, lng: 19.8963793 },
    { lat: 48.8382853, lng: 19.8972986 },
    { lat: 48.8398562, lng: 19.8979171 },
    { lat: 48.8414842, lng: 19.8981099 },
    { lat: 48.8421192, lng: 19.8980993 },
    { lat: 48.8428173, lng: 19.8978808 },
    { lat: 48.844014, lng: 19.8978672 },
    { lat: 48.844745, lng: 19.8972293 },
    { lat: 48.8452975, lng: 19.897348 },
    { lat: 48.8455179, lng: 19.8972842 },
    { lat: 48.8462761, lng: 19.8976021 },
    { lat: 48.8466466, lng: 19.8976535 },
    { lat: 48.8482493, lng: 19.8971692 },
    { lat: 48.8506605, lng: 19.8969728 },
    { lat: 48.8507577, lng: 19.8970348 },
    { lat: 48.8507316, lng: 19.8968964 },
    { lat: 48.8507687, lng: 19.8968921 },
    { lat: 48.8509384, lng: 19.896991 },
    { lat: 48.8527442, lng: 19.8969929 },
    { lat: 48.8533602, lng: 19.8966469 },
    { lat: 48.8552334, lng: 19.8952421 },
    { lat: 48.8559189, lng: 19.8942775 },
    { lat: 48.8566013, lng: 19.8939782 },
    { lat: 48.8571464, lng: 19.8938458 },
    { lat: 48.8580122, lng: 19.8939117 },
    { lat: 48.8591792, lng: 19.8950471 },
    { lat: 48.8594453, lng: 19.8952143 },
    { lat: 48.8598868, lng: 19.8960028 },
    { lat: 48.8604796, lng: 19.8972292 },
    { lat: 48.8611377, lng: 19.898183 },
    { lat: 48.861978, lng: 19.8986375 },
    { lat: 48.8628976, lng: 19.8988306 },
    { lat: 48.8630103, lng: 19.8989087 },
    { lat: 48.8632032, lng: 19.8988612 },
    { lat: 48.8635962, lng: 19.8989931 },
    { lat: 48.8638551, lng: 19.8989857 },
    { lat: 48.8651312, lng: 19.8996698 },
    { lat: 48.8653441, lng: 19.9000394 },
    { lat: 48.8656324, lng: 19.9001747 },
    { lat: 48.8667135, lng: 19.8999023 },
    { lat: 48.8671999, lng: 19.9000287 },
    { lat: 48.8680004, lng: 19.9000177 },
    { lat: 48.8687653, lng: 19.9002027 },
    { lat: 48.8692579, lng: 19.9001581 },
    { lat: 48.869288, lng: 19.9013424 },
    { lat: 48.8694407, lng: 19.9026013 },
    { lat: 48.8695182, lng: 19.9029648 },
    { lat: 48.8701525, lng: 19.9046655 },
    { lat: 48.8715753, lng: 19.9052229 },
    { lat: 48.8717255, lng: 19.9060026 },
    { lat: 48.8719272, lng: 19.9062434 },
    { lat: 48.8728859, lng: 19.9086307 },
    { lat: 48.873592, lng: 19.9086759 },
    { lat: 48.8739505, lng: 19.9086091 },
    { lat: 48.8749294, lng: 19.9081811 },
    { lat: 48.8755813, lng: 19.9082524 },
    { lat: 48.8759723, lng: 19.9084937 },
    { lat: 48.8763234, lng: 19.9081174 },
    { lat: 48.8771712, lng: 19.9077862 },
    { lat: 48.8773926, lng: 19.9082245 },
    { lat: 48.8774096, lng: 19.9083581 },
    { lat: 48.8776488, lng: 19.9083451 },
    { lat: 48.8783069, lng: 19.9088789 },
    { lat: 48.8782789, lng: 19.9096031 },
    { lat: 48.8785975, lng: 19.9104177 },
    { lat: 48.8786806, lng: 19.9104629 },
    { lat: 48.8793501, lng: 19.9112988 },
    { lat: 48.8795971, lng: 19.9114901 },
    { lat: 48.8800143, lng: 19.9120317 },
    { lat: 48.8811225, lng: 19.9126591 },
    { lat: 48.8816954, lng: 19.9132332 },
    { lat: 48.8822159, lng: 19.9132103 },
    { lat: 48.88241, lng: 19.9133238 },
    { lat: 48.8830475, lng: 19.9130057 },
    { lat: 48.8837684, lng: 19.9129505 },
    { lat: 48.8841886, lng: 19.9127681 },
    { lat: 48.8842793, lng: 19.9128584 },
    { lat: 48.8844891, lng: 19.9127839 },
    { lat: 48.8845463, lng: 19.9126459 },
    { lat: 48.8846914, lng: 19.9127234 },
    { lat: 48.8852222, lng: 19.9126204 },
    { lat: 48.8853148, lng: 19.912707 },
    { lat: 48.8856473, lng: 19.9128163 },
    { lat: 48.8857987, lng: 19.9128012 },
    { lat: 48.8867483, lng: 19.9131445 },
    { lat: 48.8870114, lng: 19.9133773 },
    { lat: 48.887097, lng: 19.9133475 },
    { lat: 48.8874631, lng: 19.9135595 },
    { lat: 48.8879301, lng: 19.9136836 },
    { lat: 48.8883219, lng: 19.9138891 },
    { lat: 48.8886146, lng: 19.9139096 },
    { lat: 48.8887388, lng: 19.9140235 },
    { lat: 48.8888672, lng: 19.9140507 },
    { lat: 48.8895666, lng: 19.9141289 },
    { lat: 48.8902051, lng: 19.9145446 },
    { lat: 48.8907117, lng: 19.9147017 },
    { lat: 48.8909823, lng: 19.9150483 },
    { lat: 48.89109, lng: 19.915011 },
    { lat: 48.8915087, lng: 19.9155128 },
    { lat: 48.8919021, lng: 19.915622 },
    { lat: 48.8925238, lng: 19.91602 },
    { lat: 48.8928609, lng: 19.9160537 },
    { lat: 48.8929354, lng: 19.9161631 },
    { lat: 48.8931545, lng: 19.9161267 },
    { lat: 48.8932379, lng: 19.9162292 },
    { lat: 48.8936202, lng: 19.9163215 },
    { lat: 48.8940066, lng: 19.9165087 },
    { lat: 48.8942839, lng: 19.9164856 },
    { lat: 48.8943654, lng: 19.9165761 },
    { lat: 48.8952293, lng: 19.9165481 },
    { lat: 48.8954501, lng: 19.9166505 },
    { lat: 48.8959321, lng: 19.9167556 },
    { lat: 48.8961004, lng: 19.9167106 },
    { lat: 48.8965567, lng: 19.916873 },
    { lat: 48.8973795, lng: 19.9170449 },
    { lat: 48.8980397, lng: 19.917127 },
    { lat: 48.8984724, lng: 19.9170794 },
    { lat: 48.8995275, lng: 19.9174671 },
    { lat: 48.9000035, lng: 19.9174318 },
    { lat: 48.9012377, lng: 19.91713 },
    { lat: 48.9012848, lng: 19.9171755 },
    { lat: 48.9015317, lng: 19.9170476 },
    { lat: 48.9075324, lng: 19.9173693 },
    { lat: 48.908368, lng: 19.916952 },
    { lat: 48.908136, lng: 19.915632 },
    { lat: 48.907699, lng: 19.912869 },
    { lat: 48.907926, lng: 19.908969 },
    { lat: 48.908813, lng: 19.906853 },
    { lat: 48.909228, lng: 19.905379 },
    { lat: 48.909188, lng: 19.903183 },
    { lat: 48.909116, lng: 19.900892 },
    { lat: 48.909879, lng: 19.898707 },
    { lat: 48.908217, lng: 19.889915 },
    { lat: 48.909632, lng: 19.881341 },
  ],
  Predajná: [
    { lat: 48.8343742, lng: 19.491829 },
    { lat: 48.8362366, lng: 19.4909168 },
    { lat: 48.83836, lng: 19.4900213 },
    { lat: 48.8393444, lng: 19.4895186 },
    { lat: 48.8405694, lng: 19.4895477 },
    { lat: 48.8413601, lng: 19.4886079 },
    { lat: 48.8429806, lng: 19.4887801 },
    { lat: 48.8431485, lng: 19.4880725 },
    { lat: 48.8433265, lng: 19.4879242 },
    { lat: 48.8434304, lng: 19.487638 },
    { lat: 48.843796, lng: 19.4875986 },
    { lat: 48.8444196, lng: 19.4870485 },
    { lat: 48.8450789, lng: 19.4863182 },
    { lat: 48.8456953, lng: 19.4858994 },
    { lat: 48.8459061, lng: 19.4853654 },
    { lat: 48.84634, lng: 19.4851498 },
    { lat: 48.8468243, lng: 19.4853386 },
    { lat: 48.847361, lng: 19.4851994 },
    { lat: 48.8466115, lng: 19.4825349 },
    { lat: 48.8459199, lng: 19.4810276 },
    { lat: 48.8453523, lng: 19.4791346 },
    { lat: 48.8391285, lng: 19.4711941 },
    { lat: 48.8389341, lng: 19.4708867 },
    { lat: 48.837113, lng: 19.4695512 },
    { lat: 48.8357629, lng: 19.468893 },
    { lat: 48.8355975, lng: 19.4689206 },
    { lat: 48.8353041, lng: 19.4677707 },
    { lat: 48.8352427, lng: 19.4673436 },
    { lat: 48.8326666, lng: 19.4676132 },
    { lat: 48.8321864, lng: 19.4653224 },
    { lat: 48.8314623, lng: 19.4652395 },
    { lat: 48.8311745, lng: 19.4651077 },
    { lat: 48.830276, lng: 19.4650745 },
    { lat: 48.8273045, lng: 19.4646183 },
    { lat: 48.8272329, lng: 19.4641431 },
    { lat: 48.8272703, lng: 19.4636609 },
    { lat: 48.8272426, lng: 19.4636249 },
    { lat: 48.8273033, lng: 19.4635222 },
    { lat: 48.8278785, lng: 19.4635315 },
    { lat: 48.8278724, lng: 19.4628658 },
    { lat: 48.827768, lng: 19.4629009 },
    { lat: 48.8276993, lng: 19.4617551 },
    { lat: 48.8268257, lng: 19.4614843 },
    { lat: 48.8254474, lng: 19.461485 },
    { lat: 48.8249555, lng: 19.4615671 },
    { lat: 48.8246717, lng: 19.4618641 },
    { lat: 48.8246639, lng: 19.4605048 },
    { lat: 48.8247299, lng: 19.4594728 },
    { lat: 48.826034, lng: 19.4584841 },
    { lat: 48.8266271, lng: 19.4581299 },
    { lat: 48.8273003, lng: 19.4578944 },
    { lat: 48.8270996, lng: 19.457189 },
    { lat: 48.8269538, lng: 19.4570604 },
    { lat: 48.8267249, lng: 19.4565236 },
    { lat: 48.8262012, lng: 19.4552907 },
    { lat: 48.8261163, lng: 19.4553792 },
    { lat: 48.825196, lng: 19.4554139 },
    { lat: 48.8246274, lng: 19.4556186 },
    { lat: 48.8231749, lng: 19.4565197 },
    { lat: 48.8225915, lng: 19.4570353 },
    { lat: 48.8220723, lng: 19.4571893 },
    { lat: 48.8211334, lng: 19.4568945 },
    { lat: 48.8207186, lng: 19.4565513 },
    { lat: 48.8206383, lng: 19.4557424 },
    { lat: 48.8205615, lng: 19.4556207 },
    { lat: 48.8200549, lng: 19.4552431 },
    { lat: 48.8196966, lng: 19.4553875 },
    { lat: 48.8190746, lng: 19.4550743 },
    { lat: 48.8165004, lng: 19.4546319 },
    { lat: 48.8124144, lng: 19.4542987 },
    { lat: 48.8100706, lng: 19.4539884 },
    { lat: 48.8080849, lng: 19.453939 },
    { lat: 48.8084847, lng: 19.452989 },
    { lat: 48.8079614, lng: 19.4518148 },
    { lat: 48.8079319, lng: 19.4516625 },
    { lat: 48.8079174, lng: 19.4510169 },
    { lat: 48.8081018, lng: 19.4501824 },
    { lat: 48.8075595, lng: 19.4493021 },
    { lat: 48.80723, lng: 19.449655 },
    { lat: 48.8059682, lng: 19.4507269 },
    { lat: 48.8052899, lng: 19.4515768 },
    { lat: 48.80493, lng: 19.4524156 },
    { lat: 48.8044186, lng: 19.4545311 },
    { lat: 48.8042496, lng: 19.4543646 },
    { lat: 48.803942, lng: 19.4545347 },
    { lat: 48.802433, lng: 19.4545549 },
    { lat: 48.8017808, lng: 19.454733 },
    { lat: 48.8020232, lng: 19.4532569 },
    { lat: 48.801397, lng: 19.4530004 },
    { lat: 48.8008243, lng: 19.4528981 },
    { lat: 48.8004915, lng: 19.4531555 },
    { lat: 48.8004429, lng: 19.4533702 },
    { lat: 48.8002314, lng: 19.4537874 },
    { lat: 48.7998402, lng: 19.4543695 },
    { lat: 48.7998757, lng: 19.454528 },
    { lat: 48.799758, lng: 19.4548834 },
    { lat: 48.799783, lng: 19.455176 },
    { lat: 48.7997227, lng: 19.4554802 },
    { lat: 48.79929, lng: 19.4563781 },
    { lat: 48.7990362, lng: 19.4573656 },
    { lat: 48.7986664, lng: 19.4574114 },
    { lat: 48.7983902, lng: 19.4573375 },
    { lat: 48.7977932, lng: 19.4574636 },
    { lat: 48.7975957, lng: 19.4574106 },
    { lat: 48.7972273, lng: 19.4571663 },
    { lat: 48.7966116, lng: 19.4570121 },
    { lat: 48.7962593, lng: 19.4570953 },
    { lat: 48.7961375, lng: 19.4573342 },
    { lat: 48.7960235, lng: 19.4583786 },
    { lat: 48.7958976, lng: 19.4584342 },
    { lat: 48.7955892, lng: 19.4581481 },
    { lat: 48.7953248, lng: 19.458059 },
    { lat: 48.7951039, lng: 19.4580125 },
    { lat: 48.7947953, lng: 19.4580889 },
    { lat: 48.7934371, lng: 19.4575483 },
    { lat: 48.7919702, lng: 19.4573155 },
    { lat: 48.7918006, lng: 19.4570848 },
    { lat: 48.791052, lng: 19.4567399 },
    { lat: 48.7888877, lng: 19.4560496 },
    { lat: 48.7855878, lng: 19.4598272 },
    { lat: 48.7851456, lng: 19.4598385 },
    { lat: 48.7849717, lng: 19.4597866 },
    { lat: 48.7849865, lng: 19.4596668 },
    { lat: 48.7847945, lng: 19.4597333 },
    { lat: 48.7846755, lng: 19.4603499 },
    { lat: 48.7848019, lng: 19.460741 },
    { lat: 48.7851306, lng: 19.4607814 },
    { lat: 48.7852259, lng: 19.4611562 },
    { lat: 48.7851735, lng: 19.4626712 },
    { lat: 48.7850322, lng: 19.4623246 },
    { lat: 48.7850811, lng: 19.4620714 },
    { lat: 48.7848052, lng: 19.4621951 },
    { lat: 48.7845948, lng: 19.4619115 },
    { lat: 48.7844569, lng: 19.4619991 },
    { lat: 48.7844746, lng: 19.462282 },
    { lat: 48.783816, lng: 19.4624657 },
    { lat: 48.7835156, lng: 19.4623786 },
    { lat: 48.7834798, lng: 19.4625282 },
    { lat: 48.783641, lng: 19.4626537 },
    { lat: 48.783664, lng: 19.4629049 },
    { lat: 48.7838504, lng: 19.4628655 },
    { lat: 48.7838256, lng: 19.4626737 },
    { lat: 48.7842775, lng: 19.4626975 },
    { lat: 48.7844587, lng: 19.4632982 },
    { lat: 48.7843853, lng: 19.4632946 },
    { lat: 48.7843856, lng: 19.4633768 },
    { lat: 48.7842342, lng: 19.463341 },
    { lat: 48.7835237, lng: 19.4639883 },
    { lat: 48.7830819, lng: 19.4642036 },
    { lat: 48.7828349, lng: 19.4642491 },
    { lat: 48.7825533, lng: 19.4637891 },
    { lat: 48.7826515, lng: 19.4633549 },
    { lat: 48.7824944, lng: 19.4626937 },
    { lat: 48.7822398, lng: 19.4623763 },
    { lat: 48.7821905, lng: 19.4621396 },
    { lat: 48.7818765, lng: 19.4622212 },
    { lat: 48.781578, lng: 19.4624463 },
    { lat: 48.7817245, lng: 19.4629567 },
    { lat: 48.7818737, lng: 19.4631077 },
    { lat: 48.781677, lng: 19.4635092 },
    { lat: 48.7816779, lng: 19.4639872 },
    { lat: 48.7809624, lng: 19.4653982 },
    { lat: 48.7806019, lng: 19.464987 },
    { lat: 48.7803405, lng: 19.4649926 },
    { lat: 48.7798754, lng: 19.4648777 },
    { lat: 48.7792719, lng: 19.4645945 },
    { lat: 48.778145, lng: 19.464671 },
    { lat: 48.7776607, lng: 19.4646017 },
    { lat: 48.7776027, lng: 19.4645174 },
    { lat: 48.7775265, lng: 19.4637051 },
    { lat: 48.7778056, lng: 19.4636465 },
    { lat: 48.7778671, lng: 19.4634167 },
    { lat: 48.777206, lng: 19.4634744 },
    { lat: 48.7771242, lng: 19.4639709 },
    { lat: 48.7764559, lng: 19.4651095 },
    { lat: 48.7757862, lng: 19.4648862 },
    { lat: 48.7750334, lng: 19.4639372 },
    { lat: 48.774746, lng: 19.4648627 },
    { lat: 48.7741693, lng: 19.4641848 },
    { lat: 48.7742528, lng: 19.463969 },
    { lat: 48.7743564, lng: 19.4639434 },
    { lat: 48.7742773, lng: 19.4631397 },
    { lat: 48.7740639, lng: 19.4625707 },
    { lat: 48.774042, lng: 19.4621757 },
    { lat: 48.7739957, lng: 19.462027 },
    { lat: 48.7737976, lng: 19.461983 },
    { lat: 48.7738589, lng: 19.4614813 },
    { lat: 48.7737403, lng: 19.4601652 },
    { lat: 48.7736195, lng: 19.4602134 },
    { lat: 48.7736801, lng: 19.4608365 },
    { lat: 48.7735638, lng: 19.4608792 },
    { lat: 48.7731019, lng: 19.4589687 },
    { lat: 48.7727525, lng: 19.4591327 },
    { lat: 48.7725879, lng: 19.4584778 },
    { lat: 48.7719627, lng: 19.4585103 },
    { lat: 48.7719571, lng: 19.4579993 },
    { lat: 48.7717351, lng: 19.4569304 },
    { lat: 48.771276, lng: 19.456967 },
    { lat: 48.770954, lng: 19.456661 },
    { lat: 48.770604, lng: 19.456524 },
    { lat: 48.770324, lng: 19.457181 },
    { lat: 48.770751, lng: 19.457684 },
    { lat: 48.770941, lng: 19.458238 },
    { lat: 48.770767, lng: 19.458627 },
    { lat: 48.770278, lng: 19.458701 },
    { lat: 48.77009, lng: 19.458928 },
    { lat: 48.769881, lng: 19.459161 },
    { lat: 48.769592, lng: 19.459767 },
    { lat: 48.769217, lng: 19.460045 },
    { lat: 48.768805, lng: 19.459979 },
    { lat: 48.768375, lng: 19.459865 },
    { lat: 48.767988, lng: 19.459867 },
    { lat: 48.767632, lng: 19.45982 },
    { lat: 48.767466, lng: 19.459715 },
    { lat: 48.76681, lng: 19.459025 },
    { lat: 48.7652, lng: 19.457032 },
    { lat: 48.765177, lng: 19.456736 },
    { lat: 48.765163, lng: 19.456445 },
    { lat: 48.765, lng: 19.456123 },
    { lat: 48.764825, lng: 19.456057 },
    { lat: 48.764636, lng: 19.455777 },
    { lat: 48.76418, lng: 19.455899 },
    { lat: 48.764081, lng: 19.455962 },
    { lat: 48.763799, lng: 19.456248 },
    { lat: 48.762808, lng: 19.45661 },
    { lat: 48.762353, lng: 19.456598 },
    { lat: 48.761243, lng: 19.455852 },
    { lat: 48.759831, lng: 19.454232 },
    { lat: 48.758875, lng: 19.454654 },
    { lat: 48.757655, lng: 19.455056 },
    { lat: 48.756724, lng: 19.455275 },
    { lat: 48.756169, lng: 19.455104 },
    { lat: 48.756017, lng: 19.455425 },
    { lat: 48.754995, lng: 19.455691 },
    { lat: 48.754412, lng: 19.455847 },
    { lat: 48.754241, lng: 19.456101 },
    { lat: 48.753778, lng: 19.456098 },
    { lat: 48.753458, lng: 19.456679 },
    { lat: 48.753658, lng: 19.458073 },
    { lat: 48.753863, lng: 19.45945 },
    { lat: 48.753922, lng: 19.461951 },
    { lat: 48.754806, lng: 19.46417 },
    { lat: 48.754881, lng: 19.466629 },
    { lat: 48.755299, lng: 19.469341 },
    { lat: 48.755008, lng: 19.471946 },
    { lat: 48.7599145, lng: 19.4798132 },
    { lat: 48.7612401, lng: 19.4823774 },
    { lat: 48.7620354, lng: 19.4837809 },
    { lat: 48.7622356, lng: 19.483555 },
    { lat: 48.7629635, lng: 19.483383 },
    { lat: 48.7634138, lng: 19.4842501 },
    { lat: 48.7639947, lng: 19.4844087 },
    { lat: 48.7647442, lng: 19.4843822 },
    { lat: 48.7652404, lng: 19.4845873 },
    { lat: 48.7656504, lng: 19.4848754 },
    { lat: 48.7677059, lng: 19.4849233 },
    { lat: 48.7685033, lng: 19.4851361 },
    { lat: 48.7685673, lng: 19.485229 },
    { lat: 48.7690822, lng: 19.4851492 },
    { lat: 48.7692228, lng: 19.4852686 },
    { lat: 48.7696638, lng: 19.4853984 },
    { lat: 48.7703335, lng: 19.4857463 },
    { lat: 48.7715227, lng: 19.4860202 },
    { lat: 48.7722495, lng: 19.4859944 },
    { lat: 48.7734459, lng: 19.4856942 },
    { lat: 48.7749713, lng: 19.4866586 },
    { lat: 48.7754657, lng: 19.4867995 },
    { lat: 48.7764114, lng: 19.4879028 },
    { lat: 48.7774889, lng: 19.4893681 },
    { lat: 48.77924, lng: 19.4906079 },
    { lat: 48.7808421, lng: 19.4933117 },
    { lat: 48.7810524, lng: 19.4941324 },
    { lat: 48.7811581, lng: 19.4952254 },
    { lat: 48.7825608, lng: 19.4964383 },
    { lat: 48.7843188, lng: 19.4984967 },
    { lat: 48.7848281, lng: 19.4985659 },
    { lat: 48.7856961, lng: 19.4991332 },
    { lat: 48.7859465, lng: 19.4993823 },
    { lat: 48.7863109, lng: 19.4999052 },
    { lat: 48.7879691, lng: 19.5009449 },
    { lat: 48.7891594, lng: 19.5018553 },
    { lat: 48.7894382, lng: 19.5018644 },
    { lat: 48.7895284, lng: 19.5019411 },
    { lat: 48.7897871, lng: 19.5017865 },
    { lat: 48.7906073, lng: 19.5017057 },
    { lat: 48.7910266, lng: 19.5018051 },
    { lat: 48.7910877, lng: 19.5013945 },
    { lat: 48.7911938, lng: 19.501201 },
    { lat: 48.791612, lng: 19.5014728 },
    { lat: 48.7916269, lng: 19.5019708 },
    { lat: 48.7918077, lng: 19.5023478 },
    { lat: 48.7925858, lng: 19.5020669 },
    { lat: 48.7936436, lng: 19.5022791 },
    { lat: 48.7945626, lng: 19.5020953 },
    { lat: 48.7955457, lng: 19.5017371 },
    { lat: 48.7960014, lng: 19.5014288 },
    { lat: 48.796848, lng: 19.5012915 },
    { lat: 48.79796, lng: 19.5012599 },
    { lat: 48.7987569, lng: 19.5009731 },
    { lat: 48.7993837, lng: 19.5009788 },
    { lat: 48.7998487, lng: 19.5007831 },
    { lat: 48.8003443, lng: 19.5005746 },
    { lat: 48.8009122, lng: 19.5000266 },
    { lat: 48.8011849, lng: 19.4997634 },
    { lat: 48.8013594, lng: 19.4997568 },
    { lat: 48.8021407, lng: 19.4993762 },
    { lat: 48.8037113, lng: 19.4990033 },
    { lat: 48.8046549, lng: 19.4986438 },
    { lat: 48.8049159, lng: 19.4986105 },
    { lat: 48.805382, lng: 19.498774 },
    { lat: 48.8065473, lng: 19.4994338 },
    { lat: 48.8067981, lng: 19.4994245 },
    { lat: 48.8072613, lng: 19.4992519 },
    { lat: 48.8074008, lng: 19.4991285 },
    { lat: 48.8076614, lng: 19.4991567 },
    { lat: 48.8079645, lng: 19.4993027 },
    { lat: 48.8081813, lng: 19.499484 },
    { lat: 48.8091588, lng: 19.4997922 },
    { lat: 48.8098512, lng: 19.4998757 },
    { lat: 48.8094109, lng: 19.4994939 },
    { lat: 48.8092217, lng: 19.4991027 },
    { lat: 48.8092085, lng: 19.4988167 },
    { lat: 48.8088481, lng: 19.4983331 },
    { lat: 48.8089183, lng: 19.4981455 },
    { lat: 48.8094968, lng: 19.4984984 },
    { lat: 48.809813, lng: 19.4988054 },
    { lat: 48.8104473, lng: 19.4992161 },
    { lat: 48.8108, lng: 19.4986311 },
    { lat: 48.8119412, lng: 19.4982813 },
    { lat: 48.8120665, lng: 19.4983099 },
    { lat: 48.8124014, lng: 19.4979992 },
    { lat: 48.8120621, lng: 19.4976518 },
    { lat: 48.8115758, lng: 19.4973206 },
    { lat: 48.8114638, lng: 19.496726 },
    { lat: 48.8111976, lng: 19.4965961 },
    { lat: 48.8107795, lng: 19.4956918 },
    { lat: 48.8105975, lng: 19.4954114 },
    { lat: 48.8102036, lng: 19.4944935 },
    { lat: 48.8098548, lng: 19.4931363 },
    { lat: 48.8097024, lng: 19.4928117 },
    { lat: 48.8091684, lng: 19.4905954 },
    { lat: 48.8094891, lng: 19.4900213 },
    { lat: 48.8105914, lng: 19.4892443 },
    { lat: 48.811494, lng: 19.4902957 },
    { lat: 48.8118333, lng: 19.4902216 },
    { lat: 48.8138047, lng: 19.4907206 },
    { lat: 48.8140232, lng: 19.4906717 },
    { lat: 48.8167263, lng: 19.4907594 },
    { lat: 48.8174701, lng: 19.4909821 },
    { lat: 48.8181321, lng: 19.4910147 },
    { lat: 48.8194391, lng: 19.4914543 },
    { lat: 48.8194601, lng: 19.4909529 },
    { lat: 48.8208516, lng: 19.4895079 },
    { lat: 48.821113, lng: 19.4890418 },
    { lat: 48.8211747, lng: 19.4884509 },
    { lat: 48.82204, lng: 19.4883043 },
    { lat: 48.8216343, lng: 19.4900119 },
    { lat: 48.8216623, lng: 19.4900558 },
    { lat: 48.8218241, lng: 19.4898981 },
    { lat: 48.821852, lng: 19.4895783 },
    { lat: 48.8221665, lng: 19.4891867 },
    { lat: 48.8226939, lng: 19.4881871 },
    { lat: 48.8229822, lng: 19.4877675 },
    { lat: 48.8233272, lng: 19.4874549 },
    { lat: 48.8237571, lng: 19.4872946 },
    { lat: 48.8244214, lng: 19.4867475 },
    { lat: 48.8246396, lng: 19.4864197 },
    { lat: 48.8247539, lng: 19.4860417 },
    { lat: 48.8249601, lng: 19.4859677 },
    { lat: 48.8249535, lng: 19.4858228 },
    { lat: 48.8258321, lng: 19.4861593 },
    { lat: 48.8270689, lng: 19.4876806 },
    { lat: 48.8270855, lng: 19.4883849 },
    { lat: 48.8271877, lng: 19.4886223 },
    { lat: 48.8273442, lng: 19.4882531 },
    { lat: 48.828543, lng: 19.4870492 },
    { lat: 48.8291678, lng: 19.4861844 },
    { lat: 48.829527, lng: 19.4860068 },
    { lat: 48.8296783, lng: 19.4861586 },
    { lat: 48.8302323, lng: 19.4859074 },
    { lat: 48.8302852, lng: 19.4856118 },
    { lat: 48.8305572, lng: 19.485672 },
    { lat: 48.8310873, lng: 19.4855586 },
    { lat: 48.8312084, lng: 19.4853015 },
    { lat: 48.8316905, lng: 19.4851006 },
    { lat: 48.8320077, lng: 19.4847944 },
    { lat: 48.8322971, lng: 19.4847941 },
    { lat: 48.8324665, lng: 19.4844829 },
    { lat: 48.8327246, lng: 19.4841908 },
    { lat: 48.8331272, lng: 19.4841246 },
    { lat: 48.8335895, lng: 19.4837858 },
    { lat: 48.8342039, lng: 19.4853046 },
    { lat: 48.834696, lng: 19.4858609 },
    { lat: 48.8333172, lng: 19.4881428 },
    { lat: 48.833356, lng: 19.4885182 },
    { lat: 48.8343742, lng: 19.491829 },
  ],
  PohronskáPolhora: [
    { lat: 48.7872977, lng: 19.8320497 },
    { lat: 48.7867288, lng: 19.8316287 },
    { lat: 48.7864208, lng: 19.8314895 },
    { lat: 48.7862474, lng: 19.8308407 },
    { lat: 48.7855828, lng: 19.8300373 },
    { lat: 48.7853746, lng: 19.8299594 },
    { lat: 48.7847874, lng: 19.8295102 },
    { lat: 48.7846185, lng: 19.8295905 },
    { lat: 48.7838151, lng: 19.82972 },
    { lat: 48.7835479, lng: 19.829376 },
    { lat: 48.7830992, lng: 19.8296291 },
    { lat: 48.7825773, lng: 19.8290463 },
    { lat: 48.7823064, lng: 19.8290596 },
    { lat: 48.7820195, lng: 19.8288611 },
    { lat: 48.7810682, lng: 19.8284091 },
    { lat: 48.7803015, lng: 19.8275867 },
    { lat: 48.7803179, lng: 19.827365 },
    { lat: 48.7801357, lng: 19.8270869 },
    { lat: 48.7800294, lng: 19.8271017 },
    { lat: 48.7797392, lng: 19.8266204 },
    { lat: 48.779585, lng: 19.8266178 },
    { lat: 48.7791843, lng: 19.8261374 },
    { lat: 48.7789118, lng: 19.8259551 },
    { lat: 48.7786665, lng: 19.826428 },
    { lat: 48.7783868, lng: 19.826191 },
    { lat: 48.7782136, lng: 19.825727 },
    { lat: 48.7780197, lng: 19.8256452 },
    { lat: 48.7778214, lng: 19.8256977 },
    { lat: 48.7775607, lng: 19.825582 },
    { lat: 48.7774361, lng: 19.8258284 },
    { lat: 48.7761854, lng: 19.8252507 },
    { lat: 48.7757941, lng: 19.8244351 },
    { lat: 48.7751554, lng: 19.824982 },
    { lat: 48.7749508, lng: 19.8246512 },
    { lat: 48.7747994, lng: 19.8247762 },
    { lat: 48.7749962, lng: 19.8253621 },
    { lat: 48.7746999, lng: 19.825405 },
    { lat: 48.7743691, lng: 19.8254528 },
    { lat: 48.774325, lng: 19.8251864 },
    { lat: 48.7740548, lng: 19.8251903 },
    { lat: 48.7735737, lng: 19.8249069 },
    { lat: 48.7731457, lng: 19.8238632 },
    { lat: 48.7727418, lng: 19.8235103 },
    { lat: 48.7722518, lng: 19.8233328 },
    { lat: 48.7721419, lng: 19.8223047 },
    { lat: 48.7721433, lng: 19.821605 },
    { lat: 48.771885, lng: 19.8205041 },
    { lat: 48.7719311, lng: 19.8200363 },
    { lat: 48.7711587, lng: 19.8192904 },
    { lat: 48.7709487, lng: 19.8187105 },
    { lat: 48.7710733, lng: 19.8183124 },
    { lat: 48.770848, lng: 19.8169597 },
    { lat: 48.7714178, lng: 19.8162275 },
    { lat: 48.7717759, lng: 19.8156216 },
    { lat: 48.7720829, lng: 19.8150157 },
    { lat: 48.77263, lng: 19.8134728 },
    { lat: 48.7731929, lng: 19.8093331 },
    { lat: 48.7730692, lng: 19.8085079 },
    { lat: 48.7728327, lng: 19.8080417 },
    { lat: 48.7727758, lng: 19.8077484 },
    { lat: 48.7724064, lng: 19.8068408 },
    { lat: 48.7720933, lng: 19.805828 },
    { lat: 48.7715682, lng: 19.8033941 },
    { lat: 48.7705174, lng: 19.8009111 },
    { lat: 48.7691886, lng: 19.7988016 },
    { lat: 48.7669864, lng: 19.7967121 },
    { lat: 48.7660924, lng: 19.7950155 },
    { lat: 48.7657217, lng: 19.7946021 },
    { lat: 48.7645582, lng: 19.7936725 },
    { lat: 48.7641859, lng: 19.7930929 },
    { lat: 48.7638429, lng: 19.7927467 },
    { lat: 48.7633555, lng: 19.7924939 },
    { lat: 48.762797, lng: 19.7922461 },
    { lat: 48.7620514, lng: 19.7922779 },
    { lat: 48.7610538, lng: 19.7921874 },
    { lat: 48.7617878, lng: 19.7904663 },
    { lat: 48.7628599, lng: 19.7887778 },
    { lat: 48.7639286, lng: 19.7874766 },
    { lat: 48.7642405, lng: 19.7872398 },
    { lat: 48.7647106, lng: 19.7871708 },
    { lat: 48.7650423, lng: 19.7869702 },
    { lat: 48.7652425, lng: 19.7866027 },
    { lat: 48.7661644, lng: 19.7858129 },
    { lat: 48.766341, lng: 19.7855967 },
    { lat: 48.7662196, lng: 19.7828901 },
    { lat: 48.7661018, lng: 19.7813284 },
    { lat: 48.7656297, lng: 19.7813034 },
    { lat: 48.7650821, lng: 19.7814302 },
    { lat: 48.7650299, lng: 19.7820186 },
    { lat: 48.7644404, lng: 19.7824228 },
    { lat: 48.7641552, lng: 19.7824449 },
    { lat: 48.7640378, lng: 19.7823895 },
    { lat: 48.7631446, lng: 19.7824673 },
    { lat: 48.762805, lng: 19.7825623 },
    { lat: 48.7625083, lng: 19.7828883 },
    { lat: 48.7621386, lng: 19.7839904 },
    { lat: 48.7619352, lng: 19.7848063 },
    { lat: 48.7619036, lng: 19.7850384 },
    { lat: 48.7620349, lng: 19.7853106 },
    { lat: 48.7620297, lng: 19.7854982 },
    { lat: 48.7618696, lng: 19.7856281 },
    { lat: 48.7617549, lng: 19.7856285 },
    { lat: 48.7616353, lng: 19.7855222 },
    { lat: 48.7615403, lng: 19.7855642 },
    { lat: 48.7613509, lng: 19.7858499 },
    { lat: 48.761643, lng: 19.7861117 },
    { lat: 48.7615477, lng: 19.7863569 },
    { lat: 48.7613956, lng: 19.7866146 },
    { lat: 48.7613698, lng: 19.78659 },
    { lat: 48.7611495, lng: 19.7871705 },
    { lat: 48.7607329, lng: 19.7884925 },
    { lat: 48.7607459, lng: 19.7886387 },
    { lat: 48.7595633, lng: 19.7908945 },
    { lat: 48.7592955, lng: 19.7909794 },
    { lat: 48.7584784, lng: 19.7926523 },
    { lat: 48.758273, lng: 19.7924621 },
    { lat: 48.7581944, lng: 19.7926467 },
    { lat: 48.7581067, lng: 19.7926008 },
    { lat: 48.7580895, lng: 19.7925422 },
    { lat: 48.7581674, lng: 19.792363 },
    { lat: 48.7580581, lng: 19.7922618 },
    { lat: 48.7580155, lng: 19.7924206 },
    { lat: 48.7578959, lng: 19.7922883 },
    { lat: 48.7579501, lng: 19.7921615 },
    { lat: 48.7577188, lng: 19.7919457 },
    { lat: 48.7576881, lng: 19.7920162 },
    { lat: 48.7576055, lng: 19.7919356 },
    { lat: 48.7576354, lng: 19.791868 },
    { lat: 48.7574981, lng: 19.7916921 },
    { lat: 48.7571061, lng: 19.7926491 },
    { lat: 48.756863, lng: 19.7923184 },
    { lat: 48.7564552, lng: 19.7911787 },
    { lat: 48.7562399, lng: 19.7909734 },
    { lat: 48.7551382, lng: 19.7903038 },
    { lat: 48.7544985, lng: 19.7920114 },
    { lat: 48.753431, lng: 19.794717 },
    { lat: 48.7532866, lng: 19.7972441 },
    { lat: 48.7530501, lng: 19.7976858 },
    { lat: 48.7519449, lng: 19.7976341 },
    { lat: 48.7508978, lng: 19.7974956 },
    { lat: 48.7503256, lng: 19.7976004 },
    { lat: 48.7496995, lng: 19.7976106 },
    { lat: 48.7491676, lng: 19.7973187 },
    { lat: 48.7488331, lng: 19.797247 },
    { lat: 48.7484279, lng: 19.797252 },
    { lat: 48.748183, lng: 19.7969848 },
    { lat: 48.7476444, lng: 19.7961581 },
    { lat: 48.7470667, lng: 19.7956216 },
    { lat: 48.7461505, lng: 19.7949371 },
    { lat: 48.7455253, lng: 19.7946057 },
    { lat: 48.7449303, lng: 19.7947302 },
    { lat: 48.7444755, lng: 19.7944554 },
    { lat: 48.744091, lng: 19.7943437 },
    { lat: 48.7438463, lng: 19.7942103 },
    { lat: 48.743727, lng: 19.7940911 },
    { lat: 48.7430513, lng: 19.7934161 },
    { lat: 48.7427761, lng: 19.793874 },
    { lat: 48.742605, lng: 19.7940584 },
    { lat: 48.7424943, lng: 19.7940013 },
    { lat: 48.7423926, lng: 19.7936816 },
    { lat: 48.7423556, lng: 19.7935307 },
    { lat: 48.7422064, lng: 19.7934731 },
    { lat: 48.7418557, lng: 19.793437 },
    { lat: 48.7416554, lng: 19.7935209 },
    { lat: 48.7414429, lng: 19.7934774 },
    { lat: 48.7412653, lng: 19.7935615 },
    { lat: 48.7408955, lng: 19.7930676 },
    { lat: 48.7407975, lng: 19.7930945 },
    { lat: 48.7407499, lng: 19.7937356 },
    { lat: 48.7408144, lng: 19.7939462 },
    { lat: 48.740497, lng: 19.7945996 },
    { lat: 48.7402439, lng: 19.7953312 },
    { lat: 48.7396804, lng: 19.7964146 },
    { lat: 48.7393354, lng: 19.7965378 },
    { lat: 48.7384051, lng: 19.7965448 },
    { lat: 48.7382389, lng: 19.7967148 },
    { lat: 48.7379795, lng: 19.7965736 },
    { lat: 48.7373642, lng: 19.7964146 },
    { lat: 48.7368136, lng: 19.7965548 },
    { lat: 48.7369495, lng: 19.79618 },
    { lat: 48.7370576, lng: 19.7956353 },
    { lat: 48.7368883, lng: 19.79511 },
    { lat: 48.7366861, lng: 19.7947119 },
    { lat: 48.7364722, lng: 19.7938873 },
    { lat: 48.7359908, lng: 19.7930798 },
    { lat: 48.7361979, lng: 19.7920915 },
    { lat: 48.7359401, lng: 19.7919434 },
    { lat: 48.7358616, lng: 19.7914062 },
    { lat: 48.735943, lng: 19.7899155 },
    { lat: 48.7354934, lng: 19.789763 },
    { lat: 48.7353118, lng: 19.7895994 },
    { lat: 48.7353649, lng: 19.7892676 },
    { lat: 48.7357322, lng: 19.7891146 },
    { lat: 48.7360088, lng: 19.7887137 },
    { lat: 48.7355435, lng: 19.7882758 },
    { lat: 48.735286, lng: 19.7892133 },
    { lat: 48.7349689, lng: 19.7897692 },
    { lat: 48.7347572, lng: 19.7905509 },
    { lat: 48.7345155, lng: 19.7907684 },
    { lat: 48.7334851, lng: 19.7911927 },
    { lat: 48.7326348, lng: 19.7913281 },
    { lat: 48.731942, lng: 19.7912023 },
    { lat: 48.731965, lng: 19.7906939 },
    { lat: 48.7319037, lng: 19.7897757 },
    { lat: 48.7319239, lng: 19.7894925 },
    { lat: 48.7316331, lng: 19.789338 },
    { lat: 48.7314918, lng: 19.7901241 },
    { lat: 48.7311814, lng: 19.79104 },
    { lat: 48.7305555, lng: 19.7908715 },
    { lat: 48.7306826, lng: 19.7902989 },
    { lat: 48.7302797, lng: 19.790231 },
    { lat: 48.7300532, lng: 19.7899966 },
    { lat: 48.7298317, lng: 19.7903955 },
    { lat: 48.7296136, lng: 19.7903812 },
    { lat: 48.7292793, lng: 19.7900491 },
    { lat: 48.7291468, lng: 19.7900106 },
    { lat: 48.7289764, lng: 19.7897681 },
    { lat: 48.72886, lng: 19.7897626 },
    { lat: 48.7286666, lng: 19.7899032 },
    { lat: 48.728524, lng: 19.7900916 },
    { lat: 48.7284925, lng: 19.7903481 },
    { lat: 48.7283702, lng: 19.7905976 },
    { lat: 48.7282887, lng: 19.790641 },
    { lat: 48.7281685, lng: 19.7913153 },
    { lat: 48.7281964, lng: 19.7917493 },
    { lat: 48.7281258, lng: 19.7918902 },
    { lat: 48.7271594, lng: 19.7917902 },
    { lat: 48.7266982, lng: 19.7927885 },
    { lat: 48.7266011, lng: 19.7930962 },
    { lat: 48.7268994, lng: 19.7932927 },
    { lat: 48.7270109, lng: 19.7935278 },
    { lat: 48.7271652, lng: 19.7946904 },
    { lat: 48.7273761, lng: 19.795159 },
    { lat: 48.7274083, lng: 19.7953292 },
    { lat: 48.7275994, lng: 19.795525 },
    { lat: 48.7278861, lng: 19.7955928 },
    { lat: 48.728259, lng: 19.7954111 },
    { lat: 48.728647, lng: 19.7954467 },
    { lat: 48.7287349, lng: 19.7956542 },
    { lat: 48.7295908, lng: 19.7961603 },
    { lat: 48.7296573, lng: 19.7962683 },
    { lat: 48.7289255, lng: 19.7965124 },
    { lat: 48.7287879, lng: 19.7966642 },
    { lat: 48.7287117, lng: 19.796933 },
    { lat: 48.7285375, lng: 19.7971495 },
    { lat: 48.7285987, lng: 19.7974374 },
    { lat: 48.7285601, lng: 19.7978195 },
    { lat: 48.7284885, lng: 19.7979105 },
    { lat: 48.7284116, lng: 19.798422 },
    { lat: 48.7284099, lng: 19.7986642 },
    { lat: 48.7284796, lng: 19.7987418 },
    { lat: 48.7283234, lng: 19.7991399 },
    { lat: 48.7279828, lng: 19.7990553 },
    { lat: 48.7278049, lng: 19.7988992 },
    { lat: 48.7275231, lng: 19.7988466 },
    { lat: 48.7275499, lng: 19.7993821 },
    { lat: 48.7272248, lng: 19.7993427 },
    { lat: 48.7268726, lng: 19.7994038 },
    { lat: 48.7263874, lng: 19.7992287 },
    { lat: 48.7260866, lng: 19.7993096 },
    { lat: 48.7258157, lng: 19.7991428 },
    { lat: 48.7254132, lng: 19.7993659 },
    { lat: 48.7252457, lng: 19.7992727 },
    { lat: 48.7248265, lng: 19.7994514 },
    { lat: 48.7245228, lng: 19.79972 },
    { lat: 48.7240592, lng: 19.8005636 },
    { lat: 48.7242739, lng: 19.8017904 },
    { lat: 48.7243601, lng: 19.8028665 },
    { lat: 48.7236756, lng: 19.8026202 },
    { lat: 48.723495, lng: 19.802483 },
    { lat: 48.7233479, lng: 19.8020284 },
    { lat: 48.7228335, lng: 19.8011068 },
    { lat: 48.7224928, lng: 19.8010736 },
    { lat: 48.7220222, lng: 19.8014933 },
    { lat: 48.7216023, lng: 19.80124 },
    { lat: 48.7212991, lng: 19.8008755 },
    { lat: 48.7210743, lng: 19.8012001 },
    { lat: 48.7210105, lng: 19.8014373 },
    { lat: 48.7207229, lng: 19.8020539 },
    { lat: 48.7198589, lng: 19.8020292 },
    { lat: 48.7192975, lng: 19.8021264 },
    { lat: 48.7189561, lng: 19.8020486 },
    { lat: 48.7186538, lng: 19.8021176 },
    { lat: 48.7184693, lng: 19.8023307 },
    { lat: 48.7175501, lng: 19.8038085 },
    { lat: 48.7161384, lng: 19.8017119 },
    { lat: 48.7160747, lng: 19.8017717 },
    { lat: 48.7157698, lng: 19.8016286 },
    { lat: 48.7155024, lng: 19.8019285 },
    { lat: 48.7153181, lng: 19.8020168 },
    { lat: 48.714717, lng: 19.8025478 },
    { lat: 48.7144841, lng: 19.8028924 },
    { lat: 48.7139267, lng: 19.8033081 },
    { lat: 48.7136814, lng: 19.8034234 },
    { lat: 48.7132703, lng: 19.8035299 },
    { lat: 48.7125274, lng: 19.8032866 },
    { lat: 48.7122615, lng: 19.8032841 },
    { lat: 48.7121973, lng: 19.8028938 },
    { lat: 48.7122466, lng: 19.8024484 },
    { lat: 48.7119757, lng: 19.802385 },
    { lat: 48.7116542, lng: 19.8024034 },
    { lat: 48.711106, lng: 19.8025175 },
    { lat: 48.7102975, lng: 19.8029902 },
    { lat: 48.7094203, lng: 19.8037527 },
    { lat: 48.7088292, lng: 19.8045387 },
    { lat: 48.7085029, lng: 19.8053459 },
    { lat: 48.7087381, lng: 19.8059893 },
    { lat: 48.7089324, lng: 19.8072966 },
    { lat: 48.7092073, lng: 19.8078974 },
    { lat: 48.7092969, lng: 19.8084539 },
    { lat: 48.7094637, lng: 19.8086625 },
    { lat: 48.7093863, lng: 19.8102483 },
    { lat: 48.7096506, lng: 19.8104978 },
    { lat: 48.7096542, lng: 19.8109715 },
    { lat: 48.7095448, lng: 19.8111197 },
    { lat: 48.7096127, lng: 19.8113348 },
    { lat: 48.7097058, lng: 19.8113538 },
    { lat: 48.7097778, lng: 19.8116095 },
    { lat: 48.7097941, lng: 19.8117292 },
    { lat: 48.7097092, lng: 19.8118999 },
    { lat: 48.709744, lng: 19.812171 },
    { lat: 48.709837, lng: 19.812141 },
    { lat: 48.709982, lng: 19.812076 },
    { lat: 48.710079, lng: 19.812053 },
    { lat: 48.710343, lng: 19.812014 },
    { lat: 48.710517, lng: 19.811985 },
    { lat: 48.710674, lng: 19.811962 },
    { lat: 48.710765, lng: 19.811948 },
    { lat: 48.711259, lng: 19.81183 },
    { lat: 48.711264, lng: 19.811819 },
    { lat: 48.711673, lng: 19.811704 },
    { lat: 48.712019, lng: 19.811635 },
    { lat: 48.712378, lng: 19.813069 },
    { lat: 48.712571, lng: 19.814015 },
    { lat: 48.712278, lng: 19.814752 },
    { lat: 48.712082, lng: 19.815415 },
    { lat: 48.711574, lng: 19.817038 },
    { lat: 48.711057, lng: 19.818721 },
    { lat: 48.711077, lng: 19.81919 },
    { lat: 48.711081, lng: 19.82049 },
    { lat: 48.711114, lng: 19.821281 },
    { lat: 48.71099, lng: 19.822454 },
    { lat: 48.711011, lng: 19.823773 },
    { lat: 48.711036, lng: 19.82386 },
    { lat: 48.711289, lng: 19.825429 },
    { lat: 48.711472, lng: 19.826247 },
    { lat: 48.711657, lng: 19.827108 },
    { lat: 48.711806, lng: 19.827889 },
    { lat: 48.712192, lng: 19.827981 },
    { lat: 48.712792, lng: 19.828097 },
    { lat: 48.713493, lng: 19.828223 },
    { lat: 48.714076, lng: 19.828233 },
    { lat: 48.714587, lng: 19.828308 },
    { lat: 48.714999, lng: 19.828447 },
    { lat: 48.715709, lng: 19.828636 },
    { lat: 48.716207, lng: 19.828961 },
    { lat: 48.717123, lng: 19.828834 },
    { lat: 48.717827, lng: 19.828743 },
    { lat: 48.718853, lng: 19.828619 },
    { lat: 48.719688, lng: 19.828534 },
    { lat: 48.720526, lng: 19.828421 },
    { lat: 48.721363, lng: 19.828469 },
    { lat: 48.721855, lng: 19.828393 },
    { lat: 48.722493, lng: 19.82827 },
    { lat: 48.723098, lng: 19.828044 },
    { lat: 48.723705, lng: 19.827823 },
    { lat: 48.724006, lng: 19.827962 },
    { lat: 48.724799, lng: 19.828328 },
    { lat: 48.725341, lng: 19.82901 },
    { lat: 48.725641, lng: 19.829239 },
    { lat: 48.725999, lng: 19.829618 },
    { lat: 48.726257, lng: 19.829895 },
    { lat: 48.726306, lng: 19.830324 },
    { lat: 48.72655, lng: 19.830335 },
    { lat: 48.726865, lng: 19.830369 },
    { lat: 48.727701, lng: 19.830114 },
    { lat: 48.728177, lng: 19.83134 },
    { lat: 48.728798, lng: 19.832851 },
    { lat: 48.729138, lng: 19.833643 },
    { lat: 48.729307, lng: 19.834037 },
    { lat: 48.729336, lng: 19.834105 },
    { lat: 48.729485, lng: 19.834326 },
    { lat: 48.729741, lng: 19.834785 },
    { lat: 48.729898, lng: 19.834972 },
    { lat: 48.730133, lng: 19.835089 },
    { lat: 48.730502, lng: 19.835155 },
    { lat: 48.730976, lng: 19.83534 },
    { lat: 48.731311, lng: 19.83538 },
    { lat: 48.731528, lng: 19.835453 },
    { lat: 48.731972, lng: 19.835732 },
    { lat: 48.732186, lng: 19.835829 },
    { lat: 48.73305, lng: 19.835853 },
    { lat: 48.734077, lng: 19.835487 },
    { lat: 48.734139, lng: 19.835909 },
    { lat: 48.734301, lng: 19.836871 },
    { lat: 48.734364, lng: 19.837625 },
    { lat: 48.734667, lng: 19.839688 },
    { lat: 48.73497, lng: 19.840339 },
    { lat: 48.734986, lng: 19.840696 },
    { lat: 48.735244, lng: 19.841233 },
    { lat: 48.735384, lng: 19.841492 },
    { lat: 48.735761, lng: 19.841872 },
    { lat: 48.735986, lng: 19.842155 },
    { lat: 48.73652, lng: 19.842899 },
    { lat: 48.737108, lng: 19.843979 },
    { lat: 48.737496, lng: 19.845202 },
    { lat: 48.737919, lng: 19.845302 },
    { lat: 48.738164, lng: 19.846447 },
    { lat: 48.73866, lng: 19.847728 },
    { lat: 48.738873, lng: 19.848258 },
    { lat: 48.739102, lng: 19.848306 },
    { lat: 48.739332, lng: 19.848412 },
    { lat: 48.740066, lng: 19.848636 },
    { lat: 48.740577, lng: 19.848831 },
    { lat: 48.740924, lng: 19.849173 },
    { lat: 48.741709, lng: 19.850038 },
    { lat: 48.742279, lng: 19.850577 },
    { lat: 48.742697, lng: 19.850715 },
    { lat: 48.743187, lng: 19.850792 },
    { lat: 48.743457, lng: 19.850854 },
    { lat: 48.744055, lng: 19.850987 },
    { lat: 48.744141, lng: 19.851135 },
    { lat: 48.744865, lng: 19.85214 },
    { lat: 48.745092, lng: 19.852616 },
    { lat: 48.745785, lng: 19.853363 },
    { lat: 48.746276, lng: 19.85383 },
    { lat: 48.746384, lng: 19.854162 },
    { lat: 48.74668, lng: 19.854795 },
    { lat: 48.747423, lng: 19.855979 },
    { lat: 48.747765, lng: 19.856935 },
    { lat: 48.748153, lng: 19.857417 },
    { lat: 48.748784, lng: 19.858593 },
    { lat: 48.749339, lng: 19.859843 },
    { lat: 48.749552, lng: 19.860101 },
    { lat: 48.750034, lng: 19.861205 },
    { lat: 48.749996, lng: 19.862199 },
    { lat: 48.75001, lng: 19.862539 },
    { lat: 48.749954, lng: 19.862984 },
    { lat: 48.749865, lng: 19.863434 },
    { lat: 48.749456, lng: 19.864905 },
    { lat: 48.749502, lng: 19.865751 },
    { lat: 48.749057, lng: 19.866356 },
    { lat: 48.748756, lng: 19.866862 },
    { lat: 48.748223, lng: 19.867386 },
    { lat: 48.747671, lng: 19.868495 },
    { lat: 48.747752, lng: 19.869948 },
    { lat: 48.747654, lng: 19.871203 },
    { lat: 48.747185, lng: 19.872476 },
    { lat: 48.746951, lng: 19.873518 },
    { lat: 48.746743, lng: 19.874271 },
    { lat: 48.746807, lng: 19.874684 },
    { lat: 48.746906, lng: 19.874882 },
    { lat: 48.747304, lng: 19.876039 },
    { lat: 48.747397, lng: 19.87667 },
    { lat: 48.74851, lng: 19.87688 },
    { lat: 48.74942, lng: 19.877057 },
    { lat: 48.749868, lng: 19.877032 },
    { lat: 48.749947, lng: 19.877599 },
    { lat: 48.750359, lng: 19.87848 },
    { lat: 48.750473, lng: 19.879081 },
    { lat: 48.75089, lng: 19.879954 },
    { lat: 48.751407, lng: 19.880678 },
    { lat: 48.751849, lng: 19.881707 },
    { lat: 48.752603, lng: 19.880967 },
    { lat: 48.753476, lng: 19.880901 },
    { lat: 48.753836, lng: 19.880874 },
    { lat: 48.754252, lng: 19.880845 },
    { lat: 48.754961, lng: 19.880797 },
    { lat: 48.755407, lng: 19.880722 },
    { lat: 48.755839, lng: 19.880607 },
    { lat: 48.756686, lng: 19.880199 },
    { lat: 48.757265, lng: 19.87981 },
    { lat: 48.75778, lng: 19.879521 },
    { lat: 48.758225, lng: 19.879427 },
    { lat: 48.758983, lng: 19.879638 },
    { lat: 48.759641, lng: 19.879944 },
    { lat: 48.760061, lng: 19.880624 },
    { lat: 48.760266, lng: 19.880879 },
    { lat: 48.760888, lng: 19.881456 },
    { lat: 48.761488, lng: 19.881951 },
    { lat: 48.761905, lng: 19.88242 },
    { lat: 48.763107, lng: 19.883319 },
    { lat: 48.763957, lng: 19.883957 },
    { lat: 48.764605, lng: 19.884627 },
    { lat: 48.765406, lng: 19.885452 },
    { lat: 48.766343, lng: 19.886412 },
    { lat: 48.767331, lng: 19.887234 },
    { lat: 48.768064, lng: 19.887844 },
    { lat: 48.768692, lng: 19.888155 },
    { lat: 48.768921, lng: 19.888188 },
    { lat: 48.769128, lng: 19.888265 },
    { lat: 48.769574, lng: 19.888174 },
    { lat: 48.769584, lng: 19.888171 },
    { lat: 48.769622, lng: 19.888156 },
    { lat: 48.76994, lng: 19.887884 },
    { lat: 48.770295, lng: 19.887365 },
    { lat: 48.770502, lng: 19.887111 },
    { lat: 48.770872, lng: 19.886661 },
    { lat: 48.771072, lng: 19.886539 },
    { lat: 48.772064, lng: 19.885814 },
    { lat: 48.7733511, lng: 19.8852037 },
    { lat: 48.7739534, lng: 19.8850083 },
    { lat: 48.7742524, lng: 19.8848119 },
    { lat: 48.7750072, lng: 19.8838594 },
    { lat: 48.7751406, lng: 19.8837671 },
    { lat: 48.7754467, lng: 19.8832068 },
    { lat: 48.7760617, lng: 19.8828604 },
    { lat: 48.7764537, lng: 19.8825438 },
    { lat: 48.7766715, lng: 19.8822393 },
    { lat: 48.7776463, lng: 19.8812769 },
    { lat: 48.7786485, lng: 19.8809205 },
    { lat: 48.7790575, lng: 19.8808476 },
    { lat: 48.7794804, lng: 19.8806183 },
    { lat: 48.780031, lng: 19.8804836 },
    { lat: 48.7814307, lng: 19.8794981 },
    { lat: 48.7819537, lng: 19.8788435 },
    { lat: 48.7821044, lng: 19.8783897 },
    { lat: 48.7822726, lng: 19.8781886 },
    { lat: 48.782594, lng: 19.8774845 },
    { lat: 48.7828704, lng: 19.8770751 },
    { lat: 48.7830396, lng: 19.8769214 },
    { lat: 48.7833919, lng: 19.8762049 },
    { lat: 48.7830901, lng: 19.8738951 },
    { lat: 48.7828235, lng: 19.8728775 },
    { lat: 48.7829041, lng: 19.872572 },
    { lat: 48.7823597, lng: 19.8709908 },
    { lat: 48.7821315, lng: 19.8699523 },
    { lat: 48.7813924, lng: 19.8674245 },
    { lat: 48.7819003, lng: 19.8651684 },
    { lat: 48.7831426, lng: 19.8629048 },
    { lat: 48.7833494, lng: 19.86213 },
    { lat: 48.7836639, lng: 19.8614547 },
    { lat: 48.7838473, lng: 19.8612127 },
    { lat: 48.7839713, lng: 19.8603738 },
    { lat: 48.7844102, lng: 19.8583491 },
    { lat: 48.784147, lng: 19.857069 },
    { lat: 48.7840654, lng: 19.8564544 },
    { lat: 48.7841735, lng: 19.856219 },
    { lat: 48.7841248, lng: 19.8559256 },
    { lat: 48.784184, lng: 19.8554696 },
    { lat: 48.784104, lng: 19.8550252 },
    { lat: 48.784222, lng: 19.8543106 },
    { lat: 48.7843493, lng: 19.8539162 },
    { lat: 48.7848617, lng: 19.8534149 },
    { lat: 48.7874153, lng: 19.8512062 },
    { lat: 48.7893072, lng: 19.8478426 },
    { lat: 48.7897653, lng: 19.846189 },
    { lat: 48.7907117, lng: 19.8447272 },
    { lat: 48.7909435, lng: 19.8440626 },
    { lat: 48.7907902, lng: 19.841033 },
    { lat: 48.7908513, lng: 19.8383488 },
    { lat: 48.7903893, lng: 19.8359418 },
    { lat: 48.7896535, lng: 19.8349763 },
    { lat: 48.788048, lng: 19.833088 },
    { lat: 48.7872977, lng: 19.8320497 },
  ],
  Heľpa: [
    { lat: 48.8538253, lng: 19.9902655 },
    { lat: 48.8553524, lng: 19.9913383 },
    { lat: 48.8558773, lng: 19.9917949 },
    { lat: 48.8558585, lng: 19.9919101 },
    { lat: 48.8562515, lng: 19.992175 },
    { lat: 48.8567319, lng: 19.9926229 },
    { lat: 48.857888, lng: 19.9929567 },
    { lat: 48.8580292, lng: 19.9925629 },
    { lat: 48.8582438, lng: 19.9926028 },
    { lat: 48.8595706, lng: 19.9932221 },
    { lat: 48.8599315, lng: 19.9934765 },
    { lat: 48.8603677, lng: 19.9935896 },
    { lat: 48.8606265, lng: 19.9935859 },
    { lat: 48.8616657, lng: 19.9940039 },
    { lat: 48.8620196, lng: 19.9940626 },
    { lat: 48.8636737, lng: 19.9940493 },
    { lat: 48.8657884, lng: 19.9947939 },
    { lat: 48.8671688, lng: 19.9946702 },
    { lat: 48.867608, lng: 19.9945629 },
    { lat: 48.8684441, lng: 19.9942187 },
    { lat: 48.8685802, lng: 19.9942379 },
    { lat: 48.86977, lng: 19.9937063 },
    { lat: 48.8710444, lng: 19.993641 },
    { lat: 48.8714753, lng: 19.9935478 },
    { lat: 48.8726018, lng: 19.9930587 },
    { lat: 48.8728111, lng: 19.9929209 },
    { lat: 48.8728187, lng: 19.9926733 },
    { lat: 48.8731625, lng: 19.9924896 },
    { lat: 48.8751393, lng: 19.9917611 },
    { lat: 48.8765249, lng: 19.9910063 },
    { lat: 48.8778291, lng: 19.9905165 },
    { lat: 48.8780384, lng: 19.9904098 },
    { lat: 48.8781402, lng: 19.9902801 },
    { lat: 48.8785612, lng: 19.9901152 },
    { lat: 48.8793407, lng: 19.9900042 },
    { lat: 48.8801147, lng: 19.9900112 },
    { lat: 48.8804135, lng: 19.9899374 },
    { lat: 48.8806106, lng: 19.9903669 },
    { lat: 48.8815995, lng: 19.9899918 },
    { lat: 48.8825381, lng: 19.9897514 },
    { lat: 48.8827811, lng: 19.9898286 },
    { lat: 48.8830621, lng: 19.9902436 },
    { lat: 48.884045, lng: 19.9900476 },
    { lat: 48.8852613, lng: 19.9901503 },
    { lat: 48.8855954, lng: 19.9900559 },
    { lat: 48.8862927, lng: 19.9903573 },
    { lat: 48.8869877, lng: 19.9907619 },
    { lat: 48.8888596, lng: 19.9913543 },
    { lat: 48.8896439, lng: 19.991094 },
    { lat: 48.8899245, lng: 19.9912836 },
    { lat: 48.8908826, lng: 19.9913531 },
    { lat: 48.8912388, lng: 19.9913039 },
    { lat: 48.8918579, lng: 19.9917263 },
    { lat: 48.8921685, lng: 19.9915858 },
    { lat: 48.8940873, lng: 19.9917063 },
    { lat: 48.8956757, lng: 19.9915231 },
    { lat: 48.8973824, lng: 19.9925571 },
    { lat: 48.8976905, lng: 19.9926775 },
    { lat: 48.899775, lng: 19.9931287 },
    { lat: 48.9004916, lng: 19.9945665 },
    { lat: 48.9026759, lng: 19.9964582 },
    { lat: 48.904831, lng: 19.995546 },
    { lat: 48.907501, lng: 19.992261 },
    { lat: 48.90824, lng: 19.990996 },
    { lat: 48.9083063, lng: 19.9909129 },
    { lat: 48.9103, lng: 19.988416 },
    { lat: 48.910364, lng: 19.988339 },
    { lat: 48.910542, lng: 19.988105 },
    { lat: 48.912986, lng: 19.983683 },
    { lat: 48.913819, lng: 19.981923 },
    { lat: 48.914277, lng: 19.980674 },
    { lat: 48.915911, lng: 19.976884 },
    { lat: 48.915728, lng: 19.974981 },
    { lat: 48.9147291, lng: 19.9730372 },
    { lat: 48.914526, lng: 19.972676 },
    { lat: 48.91379, lng: 19.972194 },
    { lat: 48.913738, lng: 19.972194 },
    { lat: 48.911599, lng: 19.969924 },
    { lat: 48.911128, lng: 19.968653 },
    { lat: 48.910454, lng: 19.965954 },
    { lat: 48.910308, lng: 19.965373 },
    { lat: 48.909697, lng: 19.963844 },
    { lat: 48.908655, lng: 19.96168 },
    { lat: 48.908437, lng: 19.961218 },
    { lat: 48.908178, lng: 19.960148 },
    { lat: 48.907614, lng: 19.956345 },
    { lat: 48.907541, lng: 19.95592 },
    { lat: 48.907418, lng: 19.955576 },
    { lat: 48.907394, lng: 19.955496 },
    { lat: 48.907093, lng: 19.954468 },
    { lat: 48.907031, lng: 19.954277 },
    { lat: 48.907012, lng: 19.952364 },
    { lat: 48.906941, lng: 19.952111 },
    { lat: 48.906954, lng: 19.951857 },
    { lat: 48.907096, lng: 19.951284 },
    { lat: 48.907085, lng: 19.950998 },
    { lat: 48.907134, lng: 19.950791 },
    { lat: 48.907227, lng: 19.950568 },
    { lat: 48.907276, lng: 19.950317 },
    { lat: 48.907292, lng: 19.950035 },
    { lat: 48.907343, lng: 19.948297 },
    { lat: 48.907364, lng: 19.947736 },
    { lat: 48.907397, lng: 19.946672 },
    { lat: 48.907443, lng: 19.945871 },
    { lat: 48.907503, lng: 19.942805 },
    { lat: 48.907322, lng: 19.941855 },
    { lat: 48.907194, lng: 19.94096 },
    { lat: 48.906983, lng: 19.939841 },
    { lat: 48.906887, lng: 19.938984 },
    { lat: 48.907214, lng: 19.936386 },
    { lat: 48.907383, lng: 19.935524 },
    { lat: 48.907761, lng: 19.934914 },
    { lat: 48.906739, lng: 19.933277 },
    { lat: 48.9021364, lng: 19.9347289 },
    { lat: 48.9015746, lng: 19.935907 },
    { lat: 48.9013084, lng: 19.9365974 },
    { lat: 48.9012472, lng: 19.9366405 },
    { lat: 48.9009798, lng: 19.9379894 },
    { lat: 48.9004685, lng: 19.9387447 },
    { lat: 48.8996482, lng: 19.9390284 },
    { lat: 48.8989405, lng: 19.9389159 },
    { lat: 48.8987761, lng: 19.9388112 },
    { lat: 48.898591, lng: 19.9385094 },
    { lat: 48.8982347, lng: 19.9382308 },
    { lat: 48.8968337, lng: 19.936876 },
    { lat: 48.8964094, lng: 19.9369129 },
    { lat: 48.8962451, lng: 19.9365977 },
    { lat: 48.8956253, lng: 19.9362698 },
    { lat: 48.8951804, lng: 19.9362997 },
    { lat: 48.8944001, lng: 19.9366816 },
    { lat: 48.8934298, lng: 19.9374144 },
    { lat: 48.8927967, lng: 19.9373079 },
    { lat: 48.8927872, lng: 19.9372172 },
    { lat: 48.8923441, lng: 19.9373397 },
    { lat: 48.8916995, lng: 19.937171 },
    { lat: 48.8913797, lng: 19.936989 },
    { lat: 48.8899205, lng: 19.9364402 },
    { lat: 48.8889952, lng: 19.9356083 },
    { lat: 48.8884101, lng: 19.935485 },
    { lat: 48.8868005, lng: 19.9358095 },
    { lat: 48.8858835, lng: 19.9361498 },
    { lat: 48.8852878, lng: 19.9364499 },
    { lat: 48.8846861, lng: 19.9369006 },
    { lat: 48.8823942, lng: 19.9377654 },
    { lat: 48.8820331, lng: 19.9379619 },
    { lat: 48.8817035, lng: 19.9382955 },
    { lat: 48.8813081, lng: 19.9389591 },
    { lat: 48.8810841, lng: 19.9389644 },
    { lat: 48.8809465, lng: 19.9390951 },
    { lat: 48.8804934, lng: 19.9386316 },
    { lat: 48.8801683, lng: 19.9381621 },
    { lat: 48.8799961, lng: 19.9382489 },
    { lat: 48.879824, lng: 19.9380119 },
    { lat: 48.8796028, lng: 19.9380795 },
    { lat: 48.8793799, lng: 19.9380042 },
    { lat: 48.8789956, lng: 19.9382666 },
    { lat: 48.8778995, lng: 19.9384262 },
    { lat: 48.8775378, lng: 19.9387132 },
    { lat: 48.8771082, lng: 19.9391611 },
    { lat: 48.8764282, lng: 19.9396779 },
    { lat: 48.8759203, lng: 19.9399698 },
    { lat: 48.8757095, lng: 19.940181 },
    { lat: 48.8746257, lng: 19.9406035 },
    { lat: 48.8734387, lng: 19.9407962 },
    { lat: 48.8728158, lng: 19.9407965 },
    { lat: 48.8725017, lng: 19.9404845 },
    { lat: 48.8720976, lng: 19.9403548 },
    { lat: 48.8711015, lng: 19.9403458 },
    { lat: 48.8704505, lng: 19.940853 },
    { lat: 48.8701568, lng: 19.9407859 },
    { lat: 48.8698704, lng: 19.9408058 },
    { lat: 48.8697117, lng: 19.9405915 },
    { lat: 48.8696804, lng: 19.9403304 },
    { lat: 48.8694909, lng: 19.9400071 },
    { lat: 48.8693128, lng: 19.9395453 },
    { lat: 48.8691437, lng: 19.9395713 },
    { lat: 48.8689019, lng: 19.9391772 },
    { lat: 48.8686204, lng: 19.9388614 },
    { lat: 48.8677979, lng: 19.9383508 },
    { lat: 48.867451, lng: 19.9378209 },
    { lat: 48.8671536, lng: 19.9375524 },
    { lat: 48.8666287, lng: 19.9373146 },
    { lat: 48.865856, lng: 19.936752 },
    { lat: 48.8657891, lng: 19.9363699 },
    { lat: 48.8656193, lng: 19.9360032 },
    { lat: 48.8649277, lng: 19.9360331 },
    { lat: 48.8647134, lng: 19.9357598 },
    { lat: 48.8647027, lng: 19.9352661 },
    { lat: 48.8641895, lng: 19.9347123 },
    { lat: 48.8638181, lng: 19.9348822 },
    { lat: 48.8635682, lng: 19.9341637 },
    { lat: 48.8630391, lng: 19.9336398 },
    { lat: 48.862404, lng: 19.9334585 },
    { lat: 48.8621073, lng: 19.9335259 },
    { lat: 48.8617913, lng: 19.9333273 },
    { lat: 48.8610263, lng: 19.9326574 },
    { lat: 48.8603176, lng: 19.932415 },
    { lat: 48.8602936, lng: 19.9324606 },
    { lat: 48.860189, lng: 19.9321416 },
    { lat: 48.8597138, lng: 19.9320676 },
    { lat: 48.8589785, lng: 19.9314593 },
    { lat: 48.8588246, lng: 19.9313916 },
    { lat: 48.8588145, lng: 19.9317618 },
    { lat: 48.8577494, lng: 19.9316999 },
    { lat: 48.8572724, lng: 19.9317906 },
    { lat: 48.8572544, lng: 19.9319215 },
    { lat: 48.8571871, lng: 19.9319055 },
    { lat: 48.8570762, lng: 19.9320279 },
    { lat: 48.8570012, lng: 19.9320348 },
    { lat: 48.8567993, lng: 19.9322672 },
    { lat: 48.8566693, lng: 19.9322591 },
    { lat: 48.8566381, lng: 19.9324016 },
    { lat: 48.8563487, lng: 19.9325077 },
    { lat: 48.8563034, lng: 19.9325812 },
    { lat: 48.8562083, lng: 19.9325714 },
    { lat: 48.8561611, lng: 19.9327899 },
    { lat: 48.8560067, lng: 19.9327334 },
    { lat: 48.8556054, lng: 19.9329379 },
    { lat: 48.8554974, lng: 19.9329184 },
    { lat: 48.8554275, lng: 19.9330468 },
    { lat: 48.855227, lng: 19.9331281 },
    { lat: 48.8551974, lng: 19.9333886 },
    { lat: 48.8550376, lng: 19.9333669 },
    { lat: 48.8548842, lng: 19.9334676 },
    { lat: 48.8546847, lng: 19.9337839 },
    { lat: 48.8542585, lng: 19.9337744 },
    { lat: 48.8541183, lng: 19.933469 },
    { lat: 48.8539279, lng: 19.9333376 },
    { lat: 48.8538474, lng: 19.9330574 },
    { lat: 48.8537493, lng: 19.9330537 },
    { lat: 48.8536984, lng: 19.9331284 },
    { lat: 48.8536914, lng: 19.9332808 },
    { lat: 48.8538796, lng: 19.9335229 },
    { lat: 48.8538719, lng: 19.933715 },
    { lat: 48.8536579, lng: 19.9347012 },
    { lat: 48.8536763, lng: 19.9351347 },
    { lat: 48.8535909, lng: 19.935143 },
    { lat: 48.8535102, lng: 19.9353097 },
    { lat: 48.8534863, lng: 19.9356007 },
    { lat: 48.853566, lng: 19.9357539 },
    { lat: 48.8538271, lng: 19.9357715 },
    { lat: 48.85409, lng: 19.9362628 },
    { lat: 48.8542144, lng: 19.9366206 },
    { lat: 48.8539578, lng: 19.936652 },
    { lat: 48.8537953, lng: 19.9365958 },
    { lat: 48.8535119, lng: 19.9366577 },
    { lat: 48.8531412, lng: 19.936841 },
    { lat: 48.8527068, lng: 19.9368785 },
    { lat: 48.8508529, lng: 19.9375258 },
    { lat: 48.8491746, lng: 19.9393402 },
    { lat: 48.8484448, lng: 19.9398505 },
    { lat: 48.8475882, lng: 19.9403148 },
    { lat: 48.8462899, lng: 19.941506 },
    { lat: 48.8446953, lng: 19.9427674 },
    { lat: 48.8432817, lng: 19.9444227 },
    { lat: 48.8430044, lng: 19.9449563 },
    { lat: 48.8424487, lng: 19.947777 },
    { lat: 48.8413386, lng: 19.9476816 },
    { lat: 48.8383213, lng: 19.9484438 },
    { lat: 48.837742, lng: 19.9484104 },
    { lat: 48.8363228, lng: 19.9490229 },
    { lat: 48.8357103, lng: 19.9499635 },
    { lat: 48.8344328, lng: 19.950367 },
    { lat: 48.8336641, lng: 19.9511311 },
    { lat: 48.8330049, lng: 19.9519267 },
    { lat: 48.8324509, lng: 19.9528627 },
    { lat: 48.8306225, lng: 19.9547569 },
    { lat: 48.8295588, lng: 19.9561692 },
    { lat: 48.8280482, lng: 19.9567813 },
    { lat: 48.8268868, lng: 19.9575812 },
    { lat: 48.8256541, lng: 19.9581575 },
    { lat: 48.8243184, lng: 19.9579232 },
    { lat: 48.8237029, lng: 19.9577009 },
    { lat: 48.8230363, lng: 19.9572748 },
    { lat: 48.8220514, lng: 19.9575064 },
    { lat: 48.8218393, lng: 19.9556016 },
    { lat: 48.8208293, lng: 19.9548848 },
    { lat: 48.8203651, lng: 19.9547524 },
    { lat: 48.8198277, lng: 19.9547782 },
    { lat: 48.8186279, lng: 19.9552811 },
    { lat: 48.8172228, lng: 19.9562582 },
    { lat: 48.8169292, lng: 19.9565524 },
    { lat: 48.8165215, lng: 19.956961 },
    { lat: 48.8148235, lng: 19.9595246 },
    { lat: 48.8135429, lng: 19.9598469 },
    { lat: 48.8128901, lng: 19.959675 },
    { lat: 48.8117551, lng: 19.9592302 },
    { lat: 48.8110652, lng: 19.9594847 },
    { lat: 48.8106265, lng: 19.9601146 },
    { lat: 48.8099922, lng: 19.9606461 },
    { lat: 48.8093832, lng: 19.9615382 },
    { lat: 48.8086484, lng: 19.9620433 },
    { lat: 48.8079947, lng: 19.9628103 },
    { lat: 48.8073268, lng: 19.9634529 },
    { lat: 48.8058056, lng: 19.9646726 },
    { lat: 48.8043744, lng: 19.965647 },
    { lat: 48.8036077, lng: 19.9658491 },
    { lat: 48.8029575, lng: 19.9663741 },
    { lat: 48.8027026, lng: 19.9671441 },
    { lat: 48.8026531, lng: 19.9674429 },
    { lat: 48.802077, lng: 19.9687708 },
    { lat: 48.8017947, lng: 19.9702544 },
    { lat: 48.8013373, lng: 19.97224 },
    { lat: 48.8005828, lng: 19.9734528 },
    { lat: 48.8000198, lng: 19.9759834 },
    { lat: 48.7994999, lng: 19.9759915 },
    { lat: 48.7993767, lng: 19.975873 },
    { lat: 48.7993138, lng: 19.9757172 },
    { lat: 48.7990894, lng: 19.9757423 },
    { lat: 48.7985397, lng: 19.9755525 },
    { lat: 48.798348, lng: 19.9753866 },
    { lat: 48.7982903, lng: 19.975594 },
    { lat: 48.797724, lng: 19.9754412 },
    { lat: 48.7970498, lng: 19.9751852 },
    { lat: 48.796612, lng: 19.9747374 },
    { lat: 48.79653, lng: 19.9751176 },
    { lat: 48.7969558, lng: 19.975251 },
    { lat: 48.7979522, lng: 19.9769599 },
    { lat: 48.7967495, lng: 19.9769955 },
    { lat: 48.7946174, lng: 19.9765983 },
    { lat: 48.7940512, lng: 19.9766327 },
    { lat: 48.7934984, lng: 19.9768027 },
    { lat: 48.7928293, lng: 19.9766908 },
    { lat: 48.7922102, lng: 19.9764613 },
    { lat: 48.7921432, lng: 19.9769847 },
    { lat: 48.7920183, lng: 19.9772546 },
    { lat: 48.7919178, lng: 19.9767148 },
    { lat: 48.7896305, lng: 19.9769942 },
    { lat: 48.7888083, lng: 19.9771565 },
    { lat: 48.785364, lng: 19.977053 },
    { lat: 48.78552, lng: 19.977723 },
    { lat: 48.785518, lng: 19.98103 },
    { lat: 48.785459, lng: 19.981232 },
    { lat: 48.785527, lng: 19.982118 },
    { lat: 48.785726, lng: 19.982513 },
    { lat: 48.786057, lng: 19.984046 },
    { lat: 48.785808, lng: 19.984506 },
    { lat: 48.785834, lng: 19.984777 },
    { lat: 48.785873, lng: 19.985386 },
    { lat: 48.785809, lng: 19.985641 },
    { lat: 48.785628, lng: 19.986184 },
    { lat: 48.785625, lng: 19.986747 },
    { lat: 48.785838, lng: 19.987406 },
    { lat: 48.785822, lng: 19.987771 },
    { lat: 48.785885, lng: 19.988354 },
    { lat: 48.786368, lng: 19.988898 },
    { lat: 48.786512, lng: 19.98972 },
    { lat: 48.786937, lng: 19.99002 },
    { lat: 48.787169, lng: 19.990432 },
    { lat: 48.787322, lng: 19.991114 },
    { lat: 48.787403, lng: 19.991401 },
    { lat: 48.787493, lng: 19.991826 },
    { lat: 48.788192, lng: 19.992181 },
    { lat: 48.788941, lng: 19.992104 },
    { lat: 48.789051, lng: 19.991998 },
    { lat: 48.789639, lng: 19.992973 },
    { lat: 48.7921562, lng: 19.9913367 },
    { lat: 48.7942258, lng: 19.9889771 },
    { lat: 48.7956226, lng: 19.9879822 },
    { lat: 48.7959436, lng: 19.9882479 },
    { lat: 48.7963333, lng: 19.9878408 },
    { lat: 48.7966419, lng: 19.9871435 },
    { lat: 48.7976802, lng: 19.9868674 },
    { lat: 48.7980392, lng: 19.9874822 },
    { lat: 48.798447, lng: 19.9886364 },
    { lat: 48.7996423, lng: 19.9898849 },
    { lat: 48.7998361, lng: 19.9904759 },
    { lat: 48.7999707, lng: 19.9911322 },
    { lat: 48.8002528, lng: 19.9914732 },
    { lat: 48.8005256, lng: 19.9922839 },
    { lat: 48.800696, lng: 19.9930975 },
    { lat: 48.8008273, lng: 19.9931164 },
    { lat: 48.8009718, lng: 19.9936067 },
    { lat: 48.8012243, lng: 19.9936408 },
    { lat: 48.8016461, lng: 19.9939734 },
    { lat: 48.8021738, lng: 19.9938529 },
    { lat: 48.802265, lng: 19.994071 },
    { lat: 48.8025266, lng: 19.9943993 },
    { lat: 48.802965, lng: 19.9945939 },
    { lat: 48.8042534, lng: 19.9947207 },
    { lat: 48.8045917, lng: 19.9948405 },
    { lat: 48.8052194, lng: 19.9951988 },
    { lat: 48.805459, lng: 19.9951873 },
    { lat: 48.8058651, lng: 19.9953093 },
    { lat: 48.8061141, lng: 19.9952082 },
    { lat: 48.8065859, lng: 19.9950423 },
    { lat: 48.8068356, lng: 19.9946514 },
    { lat: 48.8071386, lng: 19.9947639 },
    { lat: 48.8074699, lng: 19.9946307 },
    { lat: 48.8077977, lng: 19.9943363 },
    { lat: 48.8083303, lng: 19.9943046 },
    { lat: 48.8088607, lng: 19.9940851 },
    { lat: 48.8090647, lng: 19.9939237 },
    { lat: 48.8094171, lng: 19.9941655 },
    { lat: 48.8096752, lng: 19.9944617 },
    { lat: 48.8107223, lng: 19.994316 },
    { lat: 48.8115038, lng: 19.9933677 },
    { lat: 48.8118769, lng: 19.9932119 },
    { lat: 48.8122104, lng: 19.9928894 },
    { lat: 48.812392, lng: 19.9928184 },
    { lat: 48.8126191, lng: 19.9928588 },
    { lat: 48.8128608, lng: 19.9927699 },
    { lat: 48.813755, lng: 19.9919302 },
    { lat: 48.8138814, lng: 19.9913165 },
    { lat: 48.8138897, lng: 19.9908651 },
    { lat: 48.8143936, lng: 19.9906349 },
    { lat: 48.8145061, lng: 19.9905233 },
    { lat: 48.8146034, lng: 19.9902649 },
    { lat: 48.8147448, lng: 19.9901416 },
    { lat: 48.8148552, lng: 19.9898758 },
    { lat: 48.8154428, lng: 19.9898949 },
    { lat: 48.8160685, lng: 19.9896895 },
    { lat: 48.8164593, lng: 19.9897446 },
    { lat: 48.8167769, lng: 19.9894718 },
    { lat: 48.8173663, lng: 19.9891854 },
    { lat: 48.8176639, lng: 19.9888888 },
    { lat: 48.8179923, lng: 19.9887455 },
    { lat: 48.8183646, lng: 19.9884649 },
    { lat: 48.8185694, lng: 19.9880775 },
    { lat: 48.8188146, lng: 19.9878006 },
    { lat: 48.8192777, lng: 19.987106 },
    { lat: 48.8194808, lng: 19.9870368 },
    { lat: 48.8197816, lng: 19.9867106 },
    { lat: 48.8200492, lng: 19.986676 },
    { lat: 48.8208198, lng: 19.9863518 },
    { lat: 48.8210329, lng: 19.9861137 },
    { lat: 48.821096, lng: 19.9859307 },
    { lat: 48.8212893, lng: 19.9856476 },
    { lat: 48.8217212, lng: 19.9854221 },
    { lat: 48.8220021, lng: 19.9851842 },
    { lat: 48.8230564, lng: 19.9846759 },
    { lat: 48.8235022, lng: 19.984696 },
    { lat: 48.8239423, lng: 19.9849012 },
    { lat: 48.8241372, lng: 19.9848775 },
    { lat: 48.8242588, lng: 19.9846867 },
    { lat: 48.8246193, lng: 19.9843672 },
    { lat: 48.825192, lng: 19.9841878 },
    { lat: 48.8253617, lng: 19.9842609 },
    { lat: 48.8255817, lng: 19.984189 },
    { lat: 48.8258027, lng: 19.9842747 },
    { lat: 48.8258966, lng: 19.984443 },
    { lat: 48.8282611, lng: 19.9848023 },
    { lat: 48.8289428, lng: 19.9844026 },
    { lat: 48.8290332, lng: 19.9841368 },
    { lat: 48.8294596, lng: 19.9834574 },
    { lat: 48.8298806, lng: 19.9830201 },
    { lat: 48.8301314, lng: 19.9828559 },
    { lat: 48.8302417, lng: 19.9821524 },
    { lat: 48.8308158, lng: 19.9813079 },
    { lat: 48.8311299, lng: 19.9812784 },
    { lat: 48.8319316, lng: 19.9806487 },
    { lat: 48.8327633, lng: 19.9796099 },
    { lat: 48.8336233, lng: 19.9794389 },
    { lat: 48.8339029, lng: 19.9794421 },
    { lat: 48.834598, lng: 19.9799599 },
    { lat: 48.8346832, lng: 19.9797836 },
    { lat: 48.8355761, lng: 19.9799424 },
    { lat: 48.835666, lng: 19.9799062 },
    { lat: 48.8371333, lng: 19.9810024 },
    { lat: 48.8383368, lng: 19.9814964 },
    { lat: 48.8388272, lng: 19.9815358 },
    { lat: 48.8394136, lng: 19.9819268 },
    { lat: 48.8403712, lng: 19.982309 },
    { lat: 48.8412955, lng: 19.9823196 },
    { lat: 48.8416242, lng: 19.9822383 },
    { lat: 48.8423621, lng: 19.982319 },
    { lat: 48.8443559, lng: 19.9823192 },
    { lat: 48.8459059, lng: 19.9826459 },
    { lat: 48.8465075, lng: 19.9826493 },
    { lat: 48.8469058, lng: 19.9827201 },
    { lat: 48.8474855, lng: 19.982978 },
    { lat: 48.8478984, lng: 19.9832635 },
    { lat: 48.8482961, lng: 19.9837489 },
    { lat: 48.8483958, lng: 19.9837096 },
    { lat: 48.8488183, lng: 19.9841722 },
    { lat: 48.8492827, lng: 19.9847975 },
    { lat: 48.849511, lng: 19.9848138 },
    { lat: 48.8497943, lng: 19.9851557 },
    { lat: 48.8506766, lng: 19.9856175 },
    { lat: 48.8512551, lng: 19.9860623 },
    { lat: 48.8514498, lng: 19.9864124 },
    { lat: 48.8515396, lng: 19.9864589 },
    { lat: 48.8516445, lng: 19.9867564 },
    { lat: 48.8520632, lng: 19.9870495 },
    { lat: 48.8538253, lng: 19.9902655 },
  ],
  Braväcovo: [
    { lat: 48.8883564, lng: 19.7404786 },
    { lat: 48.8882302, lng: 19.740119 },
    { lat: 48.8876772, lng: 19.7393289 },
    { lat: 48.8875042, lng: 19.7383611 },
    { lat: 48.8865324, lng: 19.7370939 },
    { lat: 48.885402, lng: 19.7356198 },
    { lat: 48.8843494, lng: 19.7339993 },
    { lat: 48.8838621, lng: 19.7331407 },
    { lat: 48.883506, lng: 19.7333414 },
    { lat: 48.8829109, lng: 19.7333919 },
    { lat: 48.882218, lng: 19.7330532 },
    { lat: 48.8813466, lng: 19.7323291 },
    { lat: 48.8808963, lng: 19.7311576 },
    { lat: 48.8807287, lng: 19.7308295 },
    { lat: 48.880696, lng: 19.7302908 },
    { lat: 48.880564, lng: 19.7295725 },
    { lat: 48.8812063, lng: 19.727527 },
    { lat: 48.881473, lng: 19.7258297 },
    { lat: 48.8810854, lng: 19.7258053 },
    { lat: 48.881091, lng: 19.725111 },
    { lat: 48.8804898, lng: 19.7247398 },
    { lat: 48.8802644, lng: 19.7238194 },
    { lat: 48.880227, lng: 19.7237584 },
    { lat: 48.8801518, lng: 19.7238201 },
    { lat: 48.87944, lng: 19.7229522 },
    { lat: 48.8792007, lng: 19.7212075 },
    { lat: 48.8791317, lng: 19.7203332 },
    { lat: 48.8783248, lng: 19.7195571 },
    { lat: 48.8781964, lng: 19.7193278 },
    { lat: 48.8779947, lng: 19.7191238 },
    { lat: 48.87751, lng: 19.7188873 },
    { lat: 48.8772876, lng: 19.7189306 },
    { lat: 48.877049, lng: 19.7188839 },
    { lat: 48.87593, lng: 19.7191588 },
    { lat: 48.8755365, lng: 19.7193583 },
    { lat: 48.8739754, lng: 19.719367 },
    { lat: 48.8738799, lng: 19.7193208 },
    { lat: 48.8737797, lng: 19.7194262 },
    { lat: 48.873237, lng: 19.7191 },
    { lat: 48.8726049, lng: 19.718997 },
    { lat: 48.8723108, lng: 19.7187952 },
    { lat: 48.8718818, lng: 19.7186447 },
    { lat: 48.8717664, lng: 19.7185065 },
    { lat: 48.8713928, lng: 19.7184119 },
    { lat: 48.8706674, lng: 19.7177772 },
    { lat: 48.8700372, lng: 19.7169518 },
    { lat: 48.8694777, lng: 19.7160695 },
    { lat: 48.8690843, lng: 19.7155546 },
    { lat: 48.8687774, lng: 19.7149838 },
    { lat: 48.8677726, lng: 19.7140963 },
    { lat: 48.8673523, lng: 19.7140194 },
    { lat: 48.8666315, lng: 19.7137418 },
    { lat: 48.864177, lng: 19.7139293 },
    { lat: 48.863762, lng: 19.7139002 },
    { lat: 48.8628636, lng: 19.7135824 },
    { lat: 48.8621174, lng: 19.7128569 },
    { lat: 48.8617791, lng: 19.7123649 },
    { lat: 48.8614931, lng: 19.7122683 },
    { lat: 48.8610161, lng: 19.7122602 },
    { lat: 48.8600374, lng: 19.7124178 },
    { lat: 48.859505, lng: 19.7125838 },
    { lat: 48.8582704, lng: 19.713175 },
    { lat: 48.857665, lng: 19.7132421 },
    { lat: 48.8571812, lng: 19.7131553 },
    { lat: 48.8569306, lng: 19.7130469 },
    { lat: 48.8556638, lng: 19.7120151 },
    { lat: 48.8548656, lng: 19.7116689 },
    { lat: 48.8546068, lng: 19.7116251 },
    { lat: 48.8540724, lng: 19.71167 },
    { lat: 48.8537308, lng: 19.7117673 },
    { lat: 48.8534154, lng: 19.7117413 },
    { lat: 48.8525844, lng: 19.7119816 },
    { lat: 48.8520992, lng: 19.7120409 },
    { lat: 48.8507374, lng: 19.7116178 },
    { lat: 48.8496428, lng: 19.7117811 },
    { lat: 48.8483704, lng: 19.7117188 },
    { lat: 48.8479195, lng: 19.7117907 },
    { lat: 48.8471648, lng: 19.711541 },
    { lat: 48.846829, lng: 19.7115118 },
    { lat: 48.8459201, lng: 19.7112398 },
    { lat: 48.8457732, lng: 19.7113295 },
    { lat: 48.8443405, lng: 19.7114949 },
    { lat: 48.8438465, lng: 19.7114807 },
    { lat: 48.8435666, lng: 19.7113982 },
    { lat: 48.8430761, lng: 19.7114916 },
    { lat: 48.842643, lng: 19.7114289 },
    { lat: 48.8416356, lng: 19.7118026 },
    { lat: 48.8412934, lng: 19.7118656 },
    { lat: 48.8406852, lng: 19.7118164 },
    { lat: 48.8401975, lng: 19.7119467 },
    { lat: 48.8399645, lng: 19.7121557 },
    { lat: 48.8397595, lng: 19.7121582 },
    { lat: 48.8392761, lng: 19.7123099 },
    { lat: 48.8389732, lng: 19.7122895 },
    { lat: 48.8386521, lng: 19.7120867 },
    { lat: 48.8369101, lng: 19.7114701 },
    { lat: 48.8357948, lng: 19.7108809 },
    { lat: 48.8356875, lng: 19.7111082 },
    { lat: 48.8343303, lng: 19.710149 },
    { lat: 48.832789, lng: 19.7098444 },
    { lat: 48.8313529, lng: 19.7093425 },
    { lat: 48.8302755, lng: 19.708929 },
    { lat: 48.8299026, lng: 19.7086754 },
    { lat: 48.8285143, lng: 19.7080346 },
    { lat: 48.8282524, lng: 19.7081234 },
    { lat: 48.8274224, lng: 19.7079284 },
    { lat: 48.8266884, lng: 19.707837 },
    { lat: 48.8259706, lng: 19.7078094 },
    { lat: 48.8248846, lng: 19.7085246 },
    { lat: 48.8243421, lng: 19.7086919 },
    { lat: 48.8244074, lng: 19.7089191 },
    { lat: 48.8241601, lng: 19.7089585 },
    { lat: 48.8241253, lng: 19.7091119 },
    { lat: 48.823879, lng: 19.7091995 },
    { lat: 48.8237334, lng: 19.7091441 },
    { lat: 48.8229367, lng: 19.709343 },
    { lat: 48.8224156, lng: 19.7095546 },
    { lat: 48.8210988, lng: 19.7096178 },
    { lat: 48.8210854, lng: 19.7094643 },
    { lat: 48.820921, lng: 19.7095425 },
    { lat: 48.8209272, lng: 19.7097078 },
    { lat: 48.8210942, lng: 19.7097416 },
    { lat: 48.8218021, lng: 19.7103098 },
    { lat: 48.8221626, lng: 19.7111009 },
    { lat: 48.8223106, lng: 19.7112604 },
    { lat: 48.8224216, lng: 19.7112234 },
    { lat: 48.8226497, lng: 19.7113636 },
    { lat: 48.8227483, lng: 19.7120391 },
    { lat: 48.8223732, lng: 19.7126213 },
    { lat: 48.8222731, lng: 19.7130122 },
    { lat: 48.8227277, lng: 19.7135321 },
    { lat: 48.8227252, lng: 19.7139085 },
    { lat: 48.8228182, lng: 19.7144107 },
    { lat: 48.8230584, lng: 19.715074 },
    { lat: 48.8229587, lng: 19.7151148 },
    { lat: 48.8229958, lng: 19.7152655 },
    { lat: 48.8232675, lng: 19.7152716 },
    { lat: 48.8237423, lng: 19.7157891 },
    { lat: 48.8238889, lng: 19.7160464 },
    { lat: 48.8238055, lng: 19.7162525 },
    { lat: 48.8242441, lng: 19.7165885 },
    { lat: 48.8242152, lng: 19.7171568 },
    { lat: 48.8243326, lng: 19.7175235 },
    { lat: 48.8245648, lng: 19.7176806 },
    { lat: 48.825037, lng: 19.7178348 },
    { lat: 48.8250419, lng: 19.7179197 },
    { lat: 48.824752, lng: 19.7179105 },
    { lat: 48.8247202, lng: 19.7181368 },
    { lat: 48.824989, lng: 19.7185834 },
    { lat: 48.8252624, lng: 19.7189086 },
    { lat: 48.8256732, lng: 19.7181075 },
    { lat: 48.8260113, lng: 19.7180811 },
    { lat: 48.8267766, lng: 19.7178591 },
    { lat: 48.8273036, lng: 19.7174482 },
    { lat: 48.8273605, lng: 19.7175343 },
    { lat: 48.8276792, lng: 19.7173518 },
    { lat: 48.8277698, lng: 19.717441 },
    { lat: 48.8279081, lng: 19.7173612 },
    { lat: 48.8278978, lng: 19.7171008 },
    { lat: 48.828003, lng: 19.7170812 },
    { lat: 48.8281063, lng: 19.7160527 },
    { lat: 48.828298, lng: 19.7159314 },
    { lat: 48.8284824, lng: 19.7153845 },
    { lat: 48.8286351, lng: 19.7151978 },
    { lat: 48.8287784, lng: 19.7145399 },
    { lat: 48.828637, lng: 19.7141931 },
    { lat: 48.828571, lng: 19.7132608 },
    { lat: 48.8286018, lng: 19.7128632 },
    { lat: 48.829091, lng: 19.7128422 },
    { lat: 48.8298772, lng: 19.7126338 },
    { lat: 48.8303173, lng: 19.7129274 },
    { lat: 48.830907, lng: 19.7131684 },
    { lat: 48.8314511, lng: 19.7132459 },
    { lat: 48.83175, lng: 19.712947 },
    { lat: 48.8321618, lng: 19.712701 },
    { lat: 48.8328108, lng: 19.7126045 },
    { lat: 48.834195, lng: 19.7129476 },
    { lat: 48.8347756, lng: 19.713163 },
    { lat: 48.8354423, lng: 19.7132041 },
    { lat: 48.8362437, lng: 19.713929 },
    { lat: 48.8384715, lng: 19.7151108 },
    { lat: 48.8390224, lng: 19.7156941 },
    { lat: 48.8397352, lng: 19.7159141 },
    { lat: 48.840131, lng: 19.7159773 },
    { lat: 48.8405327, lng: 19.7159369 },
    { lat: 48.8407437, lng: 19.7172976 },
    { lat: 48.8406941, lng: 19.7176556 },
    { lat: 48.8405484, lng: 19.7181837 },
    { lat: 48.8404949, lng: 19.7189613 },
    { lat: 48.8406956, lng: 19.7202139 },
    { lat: 48.8406937, lng: 19.7207871 },
    { lat: 48.8406113, lng: 19.7211127 },
    { lat: 48.8403925, lng: 19.7215419 },
    { lat: 48.8400719, lng: 19.7219113 },
    { lat: 48.8398444, lng: 19.7222716 },
    { lat: 48.83975, lng: 19.7227587 },
    { lat: 48.8396869, lng: 19.7237597 },
    { lat: 48.839527, lng: 19.7249362 },
    { lat: 48.8399189, lng: 19.7260285 },
    { lat: 48.8397761, lng: 19.7260126 },
    { lat: 48.8395649, lng: 19.7262285 },
    { lat: 48.8385473, lng: 19.7264791 },
    { lat: 48.8379592, lng: 19.7256327 },
    { lat: 48.8376252, lng: 19.7253174 },
    { lat: 48.8370914, lng: 19.724659 },
    { lat: 48.8368463, lng: 19.7242636 },
    { lat: 48.8367675, lng: 19.7222941 },
    { lat: 48.8366604, lng: 19.7218565 },
    { lat: 48.8366733, lng: 19.721539 },
    { lat: 48.8365863, lng: 19.7211255 },
    { lat: 48.8363955, lng: 19.720707 },
    { lat: 48.8362074, lng: 19.7205315 },
    { lat: 48.8362317, lng: 19.7204661 },
    { lat: 48.8357203, lng: 19.7199854 },
    { lat: 48.8357486, lng: 19.7198348 },
    { lat: 48.8351225, lng: 19.7195345 },
    { lat: 48.8344093, lng: 19.7193331 },
    { lat: 48.8343266, lng: 19.7192446 },
    { lat: 48.8339328, lng: 19.7194118 },
    { lat: 48.8339345, lng: 19.7195779 },
    { lat: 48.8333507, lng: 19.7198788 },
    { lat: 48.8331562, lng: 19.7200662 },
    { lat: 48.8327059, lng: 19.7199745 },
    { lat: 48.8313693, lng: 19.7200137 },
    { lat: 48.8298729, lng: 19.7198591 },
    { lat: 48.828759, lng: 19.719227 },
    { lat: 48.8285716, lng: 19.7191899 },
    { lat: 48.8280091, lng: 19.7192626 },
    { lat: 48.827276, lng: 19.7198067 },
    { lat: 48.8272556, lng: 19.7199332 },
    { lat: 48.8270798, lng: 19.7200043 },
    { lat: 48.8264227, lng: 19.7205643 },
    { lat: 48.8258993, lng: 19.720908 },
    { lat: 48.8253095, lng: 19.7211465 },
    { lat: 48.8238373, lng: 19.7219977 },
    { lat: 48.8237697, lng: 19.7223292 },
    { lat: 48.8244548, lng: 19.7222041 },
    { lat: 48.8249779, lng: 19.7219577 },
    { lat: 48.8252733, lng: 19.721734 },
    { lat: 48.8254478, lng: 19.723413 },
    { lat: 48.8255657, lng: 19.7239131 },
    { lat: 48.8259452, lng: 19.7238082 },
    { lat: 48.8266299, lng: 19.7240307 },
    { lat: 48.8266364, lng: 19.7240762 },
    { lat: 48.826472, lng: 19.7240295 },
    { lat: 48.8264812, lng: 19.7243143 },
    { lat: 48.8265368, lng: 19.7244781 },
    { lat: 48.8264912, lng: 19.7246687 },
    { lat: 48.8266747, lng: 19.7248196 },
    { lat: 48.8269272, lng: 19.7252782 },
    { lat: 48.8272675, lng: 19.7255095 },
    { lat: 48.8272458, lng: 19.725635 },
    { lat: 48.8275883, lng: 19.7259655 },
    { lat: 48.8282031, lng: 19.726848 },
    { lat: 48.828053, lng: 19.7269995 },
    { lat: 48.8280833, lng: 19.7276883 },
    { lat: 48.8283244, lng: 19.728584 },
    { lat: 48.8282146, lng: 19.7286734 },
    { lat: 48.8283177, lng: 19.7289397 },
    { lat: 48.8283546, lng: 19.7289371 },
    { lat: 48.828439, lng: 19.7292647 },
    { lat: 48.8286113, lng: 19.7302835 },
    { lat: 48.8292504, lng: 19.7321578 },
    { lat: 48.8288886, lng: 19.7324883 },
    { lat: 48.82905, lng: 19.7337243 },
    { lat: 48.8293457, lng: 19.7338145 },
    { lat: 48.8295921, lng: 19.7336237 },
    { lat: 48.8296684, lng: 19.7338724 },
    { lat: 48.8302454, lng: 19.7332388 },
    { lat: 48.8312318, lng: 19.7326871 },
    { lat: 48.8314326, lng: 19.7333066 },
    { lat: 48.8306486, lng: 19.7340541 },
    { lat: 48.8296808, lng: 19.735151 },
    { lat: 48.8295499, lng: 19.7355357 },
    { lat: 48.8284255, lng: 19.7367599 },
    { lat: 48.8277562, lng: 19.7376838 },
    { lat: 48.8280363, lng: 19.7380836 },
    { lat: 48.828044, lng: 19.738204 },
    { lat: 48.828303, lng: 19.7379952 },
    { lat: 48.8285018, lng: 19.7379841 },
    { lat: 48.8285779, lng: 19.7380733 },
    { lat: 48.8289973, lng: 19.7379784 },
    { lat: 48.8299284, lng: 19.7373499 },
    { lat: 48.8302873, lng: 19.7371853 },
    { lat: 48.8305451, lng: 19.7369218 },
    { lat: 48.8306433, lng: 19.7373124 },
    { lat: 48.8309038, lng: 19.7372132 },
    { lat: 48.8314593, lng: 19.7372404 },
    { lat: 48.8313136, lng: 19.7376689 },
    { lat: 48.8310772, lng: 19.7379287 },
    { lat: 48.8305114, lng: 19.73964 },
    { lat: 48.8300557, lng: 19.7404219 },
    { lat: 48.8294001, lng: 19.7410934 },
    { lat: 48.8292114, lng: 19.7416671 },
    { lat: 48.829536, lng: 19.7424943 },
    { lat: 48.8297323, lng: 19.7438548 },
    { lat: 48.8308102, lng: 19.7439032 },
    { lat: 48.8308364, lng: 19.7436763 },
    { lat: 48.8314457, lng: 19.7436753 },
    { lat: 48.8318172, lng: 19.7435381 },
    { lat: 48.8321553, lng: 19.7440007 },
    { lat: 48.8323252, lng: 19.744456 },
    { lat: 48.8325775, lng: 19.7447925 },
    { lat: 48.8328359, lng: 19.7448501 },
    { lat: 48.8324828, lng: 19.745917 },
    { lat: 48.8320359, lng: 19.7468257 },
    { lat: 48.8319837, lng: 19.7475075 },
    { lat: 48.8317577, lng: 19.7480606 },
    { lat: 48.8316622, lng: 19.748858 },
    { lat: 48.8313212, lng: 19.749949 },
    { lat: 48.8314493, lng: 19.7503763 },
    { lat: 48.8311847, lng: 19.7507387 },
    { lat: 48.8312529, lng: 19.7509042 },
    { lat: 48.8316389, lng: 19.7512323 },
    { lat: 48.8315583, lng: 19.7516498 },
    { lat: 48.8320862, lng: 19.7516943 },
    { lat: 48.8321292, lng: 19.7521853 },
    { lat: 48.8318098, lng: 19.7527388 },
    { lat: 48.8319805, lng: 19.7529555 },
    { lat: 48.8319314, lng: 19.7530459 },
    { lat: 48.8320034, lng: 19.7532246 },
    { lat: 48.8316967, lng: 19.7537021 },
    { lat: 48.8314489, lng: 19.7537792 },
    { lat: 48.8310839, lng: 19.7537044 },
    { lat: 48.8308524, lng: 19.7539014 },
    { lat: 48.8307271, lng: 19.7540742 },
    { lat: 48.830373, lng: 19.755066 },
    { lat: 48.8305682, lng: 19.7550228 },
    { lat: 48.8307716, lng: 19.7545243 },
    { lat: 48.8309541, lng: 19.7542395 },
    { lat: 48.8313567, lng: 19.7541715 },
    { lat: 48.8313858, lng: 19.7543491 },
    { lat: 48.8316364, lng: 19.7543311 },
    { lat: 48.8319076, lng: 19.7544057 },
    { lat: 48.8318522, lng: 19.7548586 },
    { lat: 48.8316312, lng: 19.7548322 },
    { lat: 48.8316408, lng: 19.7546889 },
    { lat: 48.8315064, lng: 19.7547361 },
    { lat: 48.8314988, lng: 19.7548173 },
    { lat: 48.8312873, lng: 19.755032 },
    { lat: 48.8310541, lng: 19.7555729 },
    { lat: 48.830959, lng: 19.7559715 },
    { lat: 48.8310143, lng: 19.7560758 },
    { lat: 48.8307794, lng: 19.7568614 },
    { lat: 48.8306471, lng: 19.7571085 },
    { lat: 48.8310524, lng: 19.7569448 },
    { lat: 48.8312052, lng: 19.7566005 },
    { lat: 48.8315282, lng: 19.756672 },
    { lat: 48.8315808, lng: 19.7571019 },
    { lat: 48.831768, lng: 19.7572305 },
    { lat: 48.8328551, lng: 19.758361 },
    { lat: 48.8331978, lng: 19.7583709 },
    { lat: 48.8334303, lng: 19.7576588 },
    { lat: 48.8345288, lng: 19.7578351 },
    { lat: 48.8346417, lng: 19.7576911 },
    { lat: 48.8348647, lng: 19.7576887 },
    { lat: 48.8350546, lng: 19.7577773 },
    { lat: 48.8351582, lng: 19.7579053 },
    { lat: 48.835252, lng: 19.7588414 },
    { lat: 48.8354435, lng: 19.7593807 },
    { lat: 48.8354174, lng: 19.7599158 },
    { lat: 48.8351589, lng: 19.7600834 },
    { lat: 48.8348465, lng: 19.7596396 },
    { lat: 48.8345968, lng: 19.7603706 },
    { lat: 48.8344648, lng: 19.7605204 },
    { lat: 48.8346962, lng: 19.7609896 },
    { lat: 48.8350056, lng: 19.7614202 },
    { lat: 48.8351033, lng: 19.7617212 },
    { lat: 48.8352596, lng: 19.761974 },
    { lat: 48.8357283, lng: 19.7624123 },
    { lat: 48.8363092, lng: 19.7623257 },
    { lat: 48.8363514, lng: 19.7629287 },
    { lat: 48.8369919, lng: 19.7635581 },
    { lat: 48.8365501, lng: 19.7642262 },
    { lat: 48.8361518, lng: 19.7642905 },
    { lat: 48.8352871, lng: 19.7651018 },
    { lat: 48.8348935, lng: 19.7661677 },
    { lat: 48.834598, lng: 19.7672437 },
    { lat: 48.8338807, lng: 19.7686674 },
    { lat: 48.8336278, lng: 19.7698442 },
    { lat: 48.8333003, lng: 19.7709077 },
    { lat: 48.832847, lng: 19.7710522 },
    { lat: 48.8324655, lng: 19.7717059 },
    { lat: 48.8324835, lng: 19.7720977 },
    { lat: 48.8320814, lng: 19.7724084 },
    { lat: 48.8317066, lng: 19.7724384 },
    { lat: 48.8311933, lng: 19.7721673 },
    { lat: 48.8309786, lng: 19.7724077 },
    { lat: 48.8308171, lng: 19.7722169 },
    { lat: 48.830382, lng: 19.771207 },
    { lat: 48.8301465, lng: 19.7713873 },
    { lat: 48.8301896, lng: 19.7714244 },
    { lat: 48.8307581, lng: 19.7734514 },
    { lat: 48.8309325, lng: 19.7742435 },
    { lat: 48.8310654, lng: 19.774169 },
    { lat: 48.8311003, lng: 19.7743458 },
    { lat: 48.8309732, lng: 19.774376 },
    { lat: 48.8316344, lng: 19.7772359 },
    { lat: 48.8319043, lng: 19.7777946 },
    { lat: 48.8321823, lng: 19.7782313 },
    { lat: 48.8332551, lng: 19.7796293 },
    { lat: 48.8335589, lng: 19.7796218 },
    { lat: 48.8335882, lng: 19.7796704 },
    { lat: 48.8335409, lng: 19.7800116 },
    { lat: 48.8344643, lng: 19.781497 },
    { lat: 48.8347911, lng: 19.7822503 },
    { lat: 48.8361156, lng: 19.786431 },
    { lat: 48.8364778, lng: 19.787375 },
    { lat: 48.836906, lng: 19.7880238 },
    { lat: 48.8372159, lng: 19.7883598 },
    { lat: 48.8374406, lng: 19.788608 },
    { lat: 48.8375783, lng: 19.7884841 },
    { lat: 48.8383944, lng: 19.7895531 },
    { lat: 48.8395953, lng: 19.7905615 },
    { lat: 48.8398338, lng: 19.7911213 },
    { lat: 48.8402117, lng: 19.7918099 },
    { lat: 48.8408994, lng: 19.7927276 },
    { lat: 48.8420171, lng: 19.7935331 },
    { lat: 48.8419632, lng: 19.7933807 },
    { lat: 48.8421846, lng: 19.7933411 },
    { lat: 48.8425418, lng: 19.7931145 },
    { lat: 48.8426001, lng: 19.7932864 },
    { lat: 48.8427796, lng: 19.7931422 },
    { lat: 48.8429044, lng: 19.7932388 },
    { lat: 48.843056, lng: 19.7932012 },
    { lat: 48.8435202, lng: 19.7933051 },
    { lat: 48.8438176, lng: 19.7940801 },
    { lat: 48.8438874, lng: 19.7940045 },
    { lat: 48.8439448, lng: 19.7941078 },
    { lat: 48.8441945, lng: 19.7941984 },
    { lat: 48.844494, lng: 19.7947927 },
    { lat: 48.844582, lng: 19.7954928 },
    { lat: 48.8445848, lng: 19.7959961 },
    { lat: 48.8445012, lng: 19.7961007 },
    { lat: 48.844607, lng: 19.7962253 },
    { lat: 48.8449159, lng: 19.7957127 },
    { lat: 48.8474554, lng: 19.7960005 },
    { lat: 48.8477219, lng: 19.7963802 },
    { lat: 48.8491011, lng: 19.7963077 },
    { lat: 48.8502134, lng: 19.7957457 },
    { lat: 48.8508716, lng: 19.7946934 },
    { lat: 48.8516652, lng: 19.7938701 },
    { lat: 48.8526047, lng: 19.7925497 },
    { lat: 48.8538979, lng: 19.7918971 },
    { lat: 48.8542843, lng: 19.7916135 },
    { lat: 48.857241, lng: 19.7880521 },
    { lat: 48.8579979, lng: 19.7865236 },
    { lat: 48.859485, lng: 19.7846328 },
    { lat: 48.8599259, lng: 19.7841762 },
    { lat: 48.8604841, lng: 19.7837504 },
    { lat: 48.8607408, lng: 19.7834388 },
    { lat: 48.8608529, lng: 19.7836541 },
    { lat: 48.8612208, lng: 19.7829997 },
    { lat: 48.861222, lng: 19.7828715 },
    { lat: 48.8621612, lng: 19.782575 },
    { lat: 48.8625984, lng: 19.7826837 },
    { lat: 48.8630644, lng: 19.7826979 },
    { lat: 48.864026, lng: 19.7823748 },
    { lat: 48.8646755, lng: 19.7820557 },
    { lat: 48.8657211, lng: 19.7812275 },
    { lat: 48.8660428, lng: 19.780859 },
    { lat: 48.866218, lng: 19.7807401 },
    { lat: 48.8666982, lng: 19.7805937 },
    { lat: 48.8669314, lng: 19.7803613 },
    { lat: 48.8673742, lng: 19.7802064 },
    { lat: 48.8681556, lng: 19.7801203 },
    { lat: 48.8697462, lng: 19.780092 },
    { lat: 48.8702614, lng: 19.7799144 },
    { lat: 48.8705872, lng: 19.7796388 },
    { lat: 48.8711728, lng: 19.7794746 },
    { lat: 48.8715306, lng: 19.7792816 },
    { lat: 48.8721454, lng: 19.7783186 },
    { lat: 48.8722921, lng: 19.778252 },
    { lat: 48.8725524, lng: 19.7779131 },
    { lat: 48.8729757, lng: 19.7771342 },
    { lat: 48.8732936, lng: 19.7767251 },
    { lat: 48.8734701, lng: 19.7761717 },
    { lat: 48.8742584, lng: 19.7742785 },
    { lat: 48.8753974, lng: 19.7733254 },
    { lat: 48.8758855, lng: 19.7731479 },
    { lat: 48.876236, lng: 19.7729213 },
    { lat: 48.876489, lng: 19.7726529 },
    { lat: 48.8766402, lng: 19.7722988 },
    { lat: 48.876992, lng: 19.7717853 },
    { lat: 48.8773123, lng: 19.7707364 },
    { lat: 48.8774821, lng: 19.7707795 },
    { lat: 48.877662, lng: 19.7700227 },
    { lat: 48.8778349, lng: 19.7696266 },
    { lat: 48.877896, lng: 19.7692461 },
    { lat: 48.8783232, lng: 19.7682934 },
    { lat: 48.8787586, lng: 19.7671426 },
    { lat: 48.8786874, lng: 19.7661082 },
    { lat: 48.8783609, lng: 19.7650812 },
    { lat: 48.8781359, lng: 19.7640866 },
    { lat: 48.8783856, lng: 19.762914 },
    { lat: 48.8784287, lng: 19.7624587 },
    { lat: 48.8786696, lng: 19.7614999 },
    { lat: 48.8803242, lng: 19.7599476 },
    { lat: 48.8803619, lng: 19.7595748 },
    { lat: 48.8807561, lng: 19.7590817 },
    { lat: 48.8812457, lng: 19.758784 },
    { lat: 48.881459, lng: 19.7582539 },
    { lat: 48.8814992, lng: 19.7578883 },
    { lat: 48.8817872, lng: 19.7573639 },
    { lat: 48.8819292, lng: 19.7570014 },
    { lat: 48.8821941, lng: 19.7557176 },
    { lat: 48.8824247, lng: 19.7550418 },
    { lat: 48.8823879, lng: 19.7544885 },
    { lat: 48.8825389, lng: 19.7537628 },
    { lat: 48.8828137, lng: 19.7531212 },
    { lat: 48.8831585, lng: 19.7524772 },
    { lat: 48.8834163, lng: 19.7522408 },
    { lat: 48.8838763, lng: 19.7516353 },
    { lat: 48.8840249, lng: 19.7509903 },
    { lat: 48.8840489, lng: 19.7503263 },
    { lat: 48.8841398, lng: 19.7497298 },
    { lat: 48.8844126, lng: 19.7485732 },
    { lat: 48.8846325, lng: 19.7481952 },
    { lat: 48.8848401, lng: 19.7476091 },
    { lat: 48.8850908, lng: 19.7472913 },
    { lat: 48.8853181, lng: 19.7468231 },
    { lat: 48.8855733, lng: 19.7448091 },
    { lat: 48.8858299, lng: 19.7438245 },
    { lat: 48.8864046, lng: 19.7427778 },
    { lat: 48.8866332, lng: 19.7422625 },
    { lat: 48.8873444, lng: 19.7411353 },
    { lat: 48.8876633, lng: 19.7407261 },
    { lat: 48.8878463, lng: 19.7403822 },
    { lat: 48.888111, lng: 19.7404536 },
    { lat: 48.8883564, lng: 19.7404786 },
  ],
  Beňuš: [
    { lat: 48.8304775, lng: 19.6827949 },
    { lat: 48.8283167, lng: 19.6822168 },
    { lat: 48.8276251, lng: 19.6814888 },
    { lat: 48.8259719, lng: 19.6808973 },
    { lat: 48.8245546, lng: 19.6818099 },
    { lat: 48.8240795, lng: 19.6826804 },
    { lat: 48.823594, lng: 19.6840748 },
    { lat: 48.8231511, lng: 19.6857207 },
    { lat: 48.8219526, lng: 19.6879821 },
    { lat: 48.8218243, lng: 19.688396 },
    { lat: 48.8212521, lng: 19.6897683 },
    { lat: 48.821686, lng: 19.6903784 },
    { lat: 48.8223841, lng: 19.6911536 },
    { lat: 48.8224758, lng: 19.6914175 },
    { lat: 48.8231685, lng: 19.6939753 },
    { lat: 48.8233241, lng: 19.695044 },
    { lat: 48.8233946, lng: 19.6959486 },
    { lat: 48.8233221, lng: 19.6963192 },
    { lat: 48.8231779, lng: 19.6967312 },
    { lat: 48.8219662, lng: 19.6981405 },
    { lat: 48.8216814, lng: 19.6985542 },
    { lat: 48.8214075, lng: 19.6991609 },
    { lat: 48.8207718, lng: 19.7009095 },
    { lat: 48.8181037, lng: 19.7064265 },
    { lat: 48.8180189, lng: 19.7072042 },
    { lat: 48.8178018, lng: 19.7079963 },
    { lat: 48.8178285, lng: 19.7084226 },
    { lat: 48.8180013, lng: 19.7091694 },
    { lat: 48.8186203, lng: 19.7104609 },
    { lat: 48.8192033, lng: 19.712154 },
    { lat: 48.8195863, lng: 19.7137044 },
    { lat: 48.8201648, lng: 19.7147644 },
    { lat: 48.8201881, lng: 19.71488 },
    { lat: 48.8202488, lng: 19.7149014 },
    { lat: 48.8210466, lng: 19.7166919 },
    { lat: 48.821818, lng: 19.718799 },
    { lat: 48.8216902, lng: 19.7190176 },
    { lat: 48.8211403, lng: 19.7192474 },
    { lat: 48.821243, lng: 19.7196578 },
    { lat: 48.8210952, lng: 19.7200861 },
    { lat: 48.8210907, lng: 19.7202935 },
    { lat: 48.8211838, lng: 19.7207396 },
    { lat: 48.8210493, lng: 19.7214962 },
    { lat: 48.8207615, lng: 19.7216665 },
    { lat: 48.8204847, lng: 19.7211886 },
    { lat: 48.8199775, lng: 19.7197809 },
    { lat: 48.8192277, lng: 19.7193095 },
    { lat: 48.8188856, lng: 19.7194714 },
    { lat: 48.8184578, lng: 19.719908 },
    { lat: 48.818346, lng: 19.7194406 },
    { lat: 48.8183004, lng: 19.7190709 },
    { lat: 48.8183134, lng: 19.7186548 },
    { lat: 48.8181489, lng: 19.7178113 },
    { lat: 48.8179342, lng: 19.7173393 },
    { lat: 48.8181514, lng: 19.7170774 },
    { lat: 48.8182119, lng: 19.7167092 },
    { lat: 48.8181093, lng: 19.716273 },
    { lat: 48.8179208, lng: 19.7161152 },
    { lat: 48.817723, lng: 19.7146204 },
    { lat: 48.817562, lng: 19.7141419 },
    { lat: 48.8172318, lng: 19.7127875 },
    { lat: 48.8168708, lng: 19.7117087 },
    { lat: 48.8166548, lng: 19.7104375 },
    { lat: 48.816495, lng: 19.7092489 },
    { lat: 48.8164762, lng: 19.7087983 },
    { lat: 48.8165121, lng: 19.7083334 },
    { lat: 48.8165976, lng: 19.7081321 },
    { lat: 48.8166328, lng: 19.7081371 },
    { lat: 48.8169003, lng: 19.7073331 },
    { lat: 48.8168625, lng: 19.7072951 },
    { lat: 48.8172514, lng: 19.7064848 },
    { lat: 48.8175873, lng: 19.7055934 },
    { lat: 48.8180983, lng: 19.7044432 },
    { lat: 48.8185867, lng: 19.7035141 },
    { lat: 48.8186262, lng: 19.7032881 },
    { lat: 48.8188401, lng: 19.7027748 },
    { lat: 48.8192154, lng: 19.7024213 },
    { lat: 48.8193047, lng: 19.7022531 },
    { lat: 48.8190884, lng: 19.7022247 },
    { lat: 48.8190808, lng: 19.7021068 },
    { lat: 48.8189582, lng: 19.7021371 },
    { lat: 48.8188969, lng: 19.7023049 },
    { lat: 48.8183348, lng: 19.7020281 },
    { lat: 48.8184343, lng: 19.7018324 },
    { lat: 48.8182983, lng: 19.7014805 },
    { lat: 48.8181504, lng: 19.7013243 },
    { lat: 48.8181699, lng: 19.7009766 },
    { lat: 48.8180728, lng: 19.7008942 },
    { lat: 48.8180563, lng: 19.7005713 },
    { lat: 48.8177687, lng: 19.7001851 },
    { lat: 48.8174634, lng: 19.7010427 },
    { lat: 48.8172892, lng: 19.701129 },
    { lat: 48.8172113, lng: 19.7008949 },
    { lat: 48.8171283, lng: 19.7011861 },
    { lat: 48.8171857, lng: 19.7015435 },
    { lat: 48.8173773, lng: 19.7016424 },
    { lat: 48.8172757, lng: 19.7017936 },
    { lat: 48.8170658, lng: 19.7023951 },
    { lat: 48.8168658, lng: 19.7023586 },
    { lat: 48.8167398, lng: 19.7024879 },
    { lat: 48.8164611, lng: 19.7024526 },
    { lat: 48.8162431, lng: 19.7027443 },
    { lat: 48.8162675, lng: 19.7033475 },
    { lat: 48.815892, lng: 19.7031375 },
    { lat: 48.8158235, lng: 19.7033175 },
    { lat: 48.8158793, lng: 19.7034866 },
    { lat: 48.8159796, lng: 19.7034582 },
    { lat: 48.8161199, lng: 19.7037789 },
    { lat: 48.8160527, lng: 19.7040964 },
    { lat: 48.8159078, lng: 19.7043321 },
    { lat: 48.8155981, lng: 19.7044597 },
    { lat: 48.8153423, lng: 19.7052522 },
    { lat: 48.8152806, lng: 19.7057886 },
    { lat: 48.8152657, lng: 19.7061401 },
    { lat: 48.815366, lng: 19.7065975 },
    { lat: 48.8152002, lng: 19.7069265 },
    { lat: 48.8149096, lng: 19.7072319 },
    { lat: 48.8147955, lng: 19.707635 },
    { lat: 48.8146864, lng: 19.7077629 },
    { lat: 48.8146546, lng: 19.7079631 },
    { lat: 48.8147607, lng: 19.7086302 },
    { lat: 48.8146778, lng: 19.7094519 },
    { lat: 48.8146796, lng: 19.7099293 },
    { lat: 48.8145982, lng: 19.7104334 },
    { lat: 48.8138101, lng: 19.7108272 },
    { lat: 48.8132139, lng: 19.71263 },
    { lat: 48.8132216, lng: 19.7135205 },
    { lat: 48.8134049, lng: 19.7145035 },
    { lat: 48.8126619, lng: 19.7154328 },
    { lat: 48.8120501, lng: 19.7159174 },
    { lat: 48.8117662, lng: 19.7162997 },
    { lat: 48.8121349, lng: 19.7168968 },
    { lat: 48.8114942, lng: 19.7187401 },
    { lat: 48.8112245, lng: 19.7180182 },
    { lat: 48.810946, lng: 19.7178326 },
    { lat: 48.8106577, lng: 19.7182429 },
    { lat: 48.810815, lng: 19.7184646 },
    { lat: 48.8101341, lng: 19.7189661 },
    { lat: 48.8098193, lng: 19.7193383 },
    { lat: 48.8097274, lng: 19.719705 },
    { lat: 48.8097773, lng: 19.7207341 },
    { lat: 48.8100396, lng: 19.7213653 },
    { lat: 48.8095183, lng: 19.7217551 },
    { lat: 48.8095963, lng: 19.7220192 },
    { lat: 48.8091542, lng: 19.7222094 },
    { lat: 48.8099957, lng: 19.7233075 },
    { lat: 48.8096003, lng: 19.7242497 },
    { lat: 48.8095352, lng: 19.7246684 },
    { lat: 48.8096334, lng: 19.72477 },
    { lat: 48.8091232, lng: 19.7255672 },
    { lat: 48.8089285, lng: 19.725243 },
    { lat: 48.8086505, lng: 19.7256579 },
    { lat: 48.807908, lng: 19.7259088 },
    { lat: 48.8076302, lng: 19.7262272 },
    { lat: 48.8075723, lng: 19.7261665 },
    { lat: 48.8071217, lng: 19.7269866 },
    { lat: 48.8070445, lng: 19.7272271 },
    { lat: 48.8070708, lng: 19.7279526 },
    { lat: 48.806559, lng: 19.7275893 },
    { lat: 48.8056463, lng: 19.7272809 },
    { lat: 48.8053228, lng: 19.7277339 },
    { lat: 48.8055369, lng: 19.7279907 },
    { lat: 48.8051812, lng: 19.7289931 },
    { lat: 48.8053276, lng: 19.7294279 },
    { lat: 48.805779, lng: 19.7293695 },
    { lat: 48.8056589, lng: 19.7296582 },
    { lat: 48.8050666, lng: 19.7305877 },
    { lat: 48.804684, lng: 19.7302403 },
    { lat: 48.8040652, lng: 19.7316124 },
    { lat: 48.8039079, lng: 19.7317294 },
    { lat: 48.8035429, lng: 19.7314181 },
    { lat: 48.8033213, lng: 19.7315324 },
    { lat: 48.8031482, lng: 19.7318733 },
    { lat: 48.8032609, lng: 19.7320524 },
    { lat: 48.8031152, lng: 19.7321708 },
    { lat: 48.8034351, lng: 19.7330649 },
    { lat: 48.8030523, lng: 19.7337872 },
    { lat: 48.8028823, lng: 19.7342522 },
    { lat: 48.8027074, lng: 19.7353685 },
    { lat: 48.8030393, lng: 19.7355666 },
    { lat: 48.8030742, lng: 19.7357421 },
    { lat: 48.8037426, lng: 19.7360589 },
    { lat: 48.8036052, lng: 19.7366435 },
    { lat: 48.8029796, lng: 19.7374325 },
    { lat: 48.8024661, lng: 19.7368006 },
    { lat: 48.8019308, lng: 19.7376927 },
    { lat: 48.8021831, lng: 19.7380239 },
    { lat: 48.8015881, lng: 19.7392247 },
    { lat: 48.8011561, lng: 19.7407503 },
    { lat: 48.8017161, lng: 19.7410077 },
    { lat: 48.8015196, lng: 19.7421229 },
    { lat: 48.8014533, lng: 19.7432771 },
    { lat: 48.8016927, lng: 19.7434989 },
    { lat: 48.8014569, lng: 19.7452341 },
    { lat: 48.8009849, lng: 19.7454375 },
    { lat: 48.8006086, lng: 19.7433647 },
    { lat: 48.8005868, lng: 19.7431196 },
    { lat: 48.80066, lng: 19.7428406 },
    { lat: 48.8001952, lng: 19.7416241 },
    { lat: 48.7998585, lng: 19.7411674 },
    { lat: 48.7993956, lng: 19.7408098 },
    { lat: 48.7990237, lng: 19.7403466 },
    { lat: 48.7985616, lng: 19.7401606 },
    { lat: 48.7979986, lng: 19.7400944 },
    { lat: 48.7983076, lng: 19.7396901 },
    { lat: 48.7975887, lng: 19.7388967 },
    { lat: 48.7974868, lng: 19.7383724 },
    { lat: 48.7971327, lng: 19.7379821 },
    { lat: 48.7973117, lng: 19.7377722 },
    { lat: 48.7973806, lng: 19.7375962 },
    { lat: 48.7971173, lng: 19.737374 },
    { lat: 48.7967617, lng: 19.7372813 },
    { lat: 48.7966227, lng: 19.7371698 },
    { lat: 48.7963258, lng: 19.7365655 },
    { lat: 48.7958986, lng: 19.737176 },
    { lat: 48.7959751, lng: 19.7377858 },
    { lat: 48.7961411, lng: 19.7384913 },
    { lat: 48.7955957, lng: 19.7390483 },
    { lat: 48.7954018, lng: 19.7393791 },
    { lat: 48.795036, lng: 19.7391301 },
    { lat: 48.7949595, lng: 19.7392837 },
    { lat: 48.7949059, lng: 19.7395799 },
    { lat: 48.795238, lng: 19.7399317 },
    { lat: 48.7952444, lng: 19.7401783 },
    { lat: 48.7953261, lng: 19.7405278 },
    { lat: 48.7956432, lng: 19.7408953 },
    { lat: 48.7951302, lng: 19.7418336 },
    { lat: 48.7959577, lng: 19.7426396 },
    { lat: 48.7961164, lng: 19.742227 },
    { lat: 48.7971683, lng: 19.7429865 },
    { lat: 48.7977244, lng: 19.7432589 },
    { lat: 48.7968739, lng: 19.7445935 },
    { lat: 48.7967577, lng: 19.7450273 },
    { lat: 48.7973913, lng: 19.7462185 },
    { lat: 48.7976842, lng: 19.7465482 },
    { lat: 48.7982496, lng: 19.7458494 },
    { lat: 48.7986066, lng: 19.7457981 },
    { lat: 48.799665, lng: 19.7460393 },
    { lat: 48.7998215, lng: 19.7458352 },
    { lat: 48.8002992, lng: 19.7473963 },
    { lat: 48.8004999, lng: 19.7471442 },
    { lat: 48.8008789, lng: 19.7478444 },
    { lat: 48.8011931, lng: 19.7472792 },
    { lat: 48.8015292, lng: 19.7470567 },
    { lat: 48.8018687, lng: 19.747733 },
    { lat: 48.8019783, lng: 19.7475357 },
    { lat: 48.8022222, lng: 19.7479829 },
    { lat: 48.8020971, lng: 19.7480595 },
    { lat: 48.8026679, lng: 19.7484059 },
    { lat: 48.8031182, lng: 19.7485282 },
    { lat: 48.8028404, lng: 19.7500558 },
    { lat: 48.8031898, lng: 19.7501357 },
    { lat: 48.8030148, lng: 19.7511029 },
    { lat: 48.8030018, lng: 19.7514738 },
    { lat: 48.803078, lng: 19.7519236 },
    { lat: 48.8033708, lng: 19.751818 },
    { lat: 48.8033902, lng: 19.7519265 },
    { lat: 48.80332, lng: 19.7520222 },
    { lat: 48.8039176, lng: 19.7525993 },
    { lat: 48.8042097, lng: 19.7529659 },
    { lat: 48.8043419, lng: 19.7534612 },
    { lat: 48.8046406, lng: 19.7542579 },
    { lat: 48.8047675, lng: 19.7549714 },
    { lat: 48.8050974, lng: 19.7553499 },
    { lat: 48.8052639, lng: 19.7551627 },
    { lat: 48.8055184, lng: 19.7554702 },
    { lat: 48.8056647, lng: 19.7554144 },
    { lat: 48.8058815, lng: 19.755735 },
    { lat: 48.8061185, lng: 19.7557441 },
    { lat: 48.8065951, lng: 19.7566215 },
    { lat: 48.8069426, lng: 19.7569373 },
    { lat: 48.80719, lng: 19.7576751 },
    { lat: 48.8074007, lng: 19.7587291 },
    { lat: 48.8076359, lng: 19.7595979 },
    { lat: 48.8076762, lng: 19.7599517 },
    { lat: 48.8079722, lng: 19.7608716 },
    { lat: 48.8079835, lng: 19.7612797 },
    { lat: 48.8082079, lng: 19.7625711 },
    { lat: 48.8082156, lng: 19.7632115 },
    { lat: 48.8082857, lng: 19.7635498 },
    { lat: 48.8083038, lng: 19.7642725 },
    { lat: 48.8081458, lng: 19.7655043 },
    { lat: 48.8077904, lng: 19.7668038 },
    { lat: 48.8071193, lng: 19.7686667 },
    { lat: 48.806591, lng: 19.7692612 },
    { lat: 48.8058106, lng: 19.7695552 },
    { lat: 48.8047948, lng: 19.7698207 },
    { lat: 48.8041325, lng: 19.7702349 },
    { lat: 48.8039614, lng: 19.7711155 },
    { lat: 48.8041152, lng: 19.7722108 },
    { lat: 48.8040073, lng: 19.7732191 },
    { lat: 48.8038382, lng: 19.7746878 },
    { lat: 48.8047786, lng: 19.7746801 },
    { lat: 48.8053271, lng: 19.7742646 },
    { lat: 48.8055472, lng: 19.7742247 },
    { lat: 48.8063936, lng: 19.773819 },
    { lat: 48.8079617, lng: 19.7737127 },
    { lat: 48.8084738, lng: 19.7738823 },
    { lat: 48.8089352, lng: 19.7739486 },
    { lat: 48.8106245, lng: 19.7745237 },
    { lat: 48.8119169, lng: 19.7744504 },
    { lat: 48.8122874, lng: 19.7742145 },
    { lat: 48.8128915, lng: 19.773631 },
    { lat: 48.8132994, lng: 19.7733228 },
    { lat: 48.8141264, lng: 19.7731438 },
    { lat: 48.8149421, lng: 19.7731944 },
    { lat: 48.8151006, lng: 19.7732882 },
    { lat: 48.8160674, lng: 19.7744569 },
    { lat: 48.8169478, lng: 19.7751612 },
    { lat: 48.8175962, lng: 19.7752554 },
    { lat: 48.8183851, lng: 19.7755939 },
    { lat: 48.8184035, lng: 19.7757351 },
    { lat: 48.8186951, lng: 19.7759636 },
    { lat: 48.818868, lng: 19.7763592 },
    { lat: 48.8192126, lng: 19.7763036 },
    { lat: 48.8193212, lng: 19.7761844 },
    { lat: 48.8192286, lng: 19.7757944 },
    { lat: 48.8201274, lng: 19.7760705 },
    { lat: 48.8201103, lng: 19.776302 },
    { lat: 48.8200504, lng: 19.7764267 },
    { lat: 48.8199636, lng: 19.7763961 },
    { lat: 48.819925, lng: 19.7764969 },
    { lat: 48.8199507, lng: 19.7765937 },
    { lat: 48.820076, lng: 19.7766237 },
    { lat: 48.8202994, lng: 19.7765287 },
    { lat: 48.8204039, lng: 19.7763159 },
    { lat: 48.8205193, lng: 19.7763258 },
    { lat: 48.8205701, lng: 19.7765102 },
    { lat: 48.8208783, lng: 19.7765233 },
    { lat: 48.8212727, lng: 19.776841 },
    { lat: 48.8214598, lng: 19.776794 },
    { lat: 48.8215122, lng: 19.7766769 },
    { lat: 48.8215909, lng: 19.7768013 },
    { lat: 48.8220374, lng: 19.7767605 },
    { lat: 48.822102, lng: 19.7770518 },
    { lat: 48.8222062, lng: 19.7770357 },
    { lat: 48.8223448, lng: 19.7771666 },
    { lat: 48.8225925, lng: 19.7770596 },
    { lat: 48.8229865, lng: 19.7771295 },
    { lat: 48.8231513, lng: 19.7769847 },
    { lat: 48.8233914, lng: 19.7769088 },
    { lat: 48.8233505, lng: 19.7768746 },
    { lat: 48.8240822, lng: 19.7765804 },
    { lat: 48.8242791, lng: 19.7766233 },
    { lat: 48.8243556, lng: 19.7768559 },
    { lat: 48.8246274, lng: 19.7770142 },
    { lat: 48.8247683, lng: 19.7773005 },
    { lat: 48.8251485, lng: 19.7771834 },
    { lat: 48.8254074, lng: 19.7769987 },
    { lat: 48.8257214, lng: 19.7769673 },
    { lat: 48.8260047, lng: 19.777182 },
    { lat: 48.8261177, lng: 19.7771654 },
    { lat: 48.826448, lng: 19.7768895 },
    { lat: 48.8264861, lng: 19.776984 },
    { lat: 48.8268438, lng: 19.7768989 },
    { lat: 48.8274867, lng: 19.7766014 },
    { lat: 48.8281178, lng: 19.7766565 },
    { lat: 48.8288945, lng: 19.7769009 },
    { lat: 48.8294799, lng: 19.7767633 },
    { lat: 48.8297812, lng: 19.7768247 },
    { lat: 48.8303037, lng: 19.7770795 },
    { lat: 48.8306248, lng: 19.777131 },
    { lat: 48.8306315, lng: 19.7773098 },
    { lat: 48.8306994, lng: 19.7774972 },
    { lat: 48.8312363, lng: 19.7787765 },
    { lat: 48.8314207, lng: 19.7793888 },
    { lat: 48.8318248, lng: 19.7803879 },
    { lat: 48.831973, lng: 19.7814365 },
    { lat: 48.8324482, lng: 19.7823902 },
    { lat: 48.833164, lng: 19.7832509 },
    { lat: 48.8335573, lng: 19.7839013 },
    { lat: 48.8337391, lng: 19.7848115 },
    { lat: 48.8339297, lng: 19.7853124 },
    { lat: 48.8340011, lng: 19.7858613 },
    { lat: 48.8340783, lng: 19.7858511 },
    { lat: 48.8342422, lng: 19.7860704 },
    { lat: 48.8343808, lng: 19.7859943 },
    { lat: 48.8344352, lng: 19.7863375 },
    { lat: 48.8346516, lng: 19.7863141 },
    { lat: 48.8347034, lng: 19.7873467 },
    { lat: 48.834825, lng: 19.7877311 },
    { lat: 48.8350475, lng: 19.7879299 },
    { lat: 48.8368394, lng: 19.7891076 },
    { lat: 48.8370333, lng: 19.7890085 },
    { lat: 48.8370982, lng: 19.7888926 },
    { lat: 48.8373631, lng: 19.7888182 },
    { lat: 48.8374163, lng: 19.788668 },
    { lat: 48.8374406, lng: 19.788608 },
    { lat: 48.8372159, lng: 19.7883598 },
    { lat: 48.836906, lng: 19.7880238 },
    { lat: 48.8364778, lng: 19.787375 },
    { lat: 48.8361156, lng: 19.786431 },
    { lat: 48.8347911, lng: 19.7822503 },
    { lat: 48.8344643, lng: 19.781497 },
    { lat: 48.8335409, lng: 19.7800116 },
    { lat: 48.8335882, lng: 19.7796704 },
    { lat: 48.8335589, lng: 19.7796218 },
    { lat: 48.8332551, lng: 19.7796293 },
    { lat: 48.8321823, lng: 19.7782313 },
    { lat: 48.8319043, lng: 19.7777946 },
    { lat: 48.8316344, lng: 19.7772359 },
    { lat: 48.8309732, lng: 19.774376 },
    { lat: 48.8311003, lng: 19.7743458 },
    { lat: 48.8310654, lng: 19.774169 },
    { lat: 48.8309325, lng: 19.7742435 },
    { lat: 48.8307581, lng: 19.7734514 },
    { lat: 48.8301896, lng: 19.7714244 },
    { lat: 48.8301465, lng: 19.7713873 },
    { lat: 48.830382, lng: 19.771207 },
    { lat: 48.8308171, lng: 19.7722169 },
    { lat: 48.8309786, lng: 19.7724077 },
    { lat: 48.8311933, lng: 19.7721673 },
    { lat: 48.8317066, lng: 19.7724384 },
    { lat: 48.8320814, lng: 19.7724084 },
    { lat: 48.8324835, lng: 19.7720977 },
    { lat: 48.8324655, lng: 19.7717059 },
    { lat: 48.832847, lng: 19.7710522 },
    { lat: 48.8333003, lng: 19.7709077 },
    { lat: 48.8336278, lng: 19.7698442 },
    { lat: 48.8338807, lng: 19.7686674 },
    { lat: 48.834598, lng: 19.7672437 },
    { lat: 48.8348935, lng: 19.7661677 },
    { lat: 48.8352871, lng: 19.7651018 },
    { lat: 48.8361518, lng: 19.7642905 },
    { lat: 48.8365501, lng: 19.7642262 },
    { lat: 48.8369919, lng: 19.7635581 },
    { lat: 48.8363514, lng: 19.7629287 },
    { lat: 48.8363092, lng: 19.7623257 },
    { lat: 48.8357283, lng: 19.7624123 },
    { lat: 48.8352596, lng: 19.761974 },
    { lat: 48.8351033, lng: 19.7617212 },
    { lat: 48.8350056, lng: 19.7614202 },
    { lat: 48.8346962, lng: 19.7609896 },
    { lat: 48.8344648, lng: 19.7605204 },
    { lat: 48.8345968, lng: 19.7603706 },
    { lat: 48.8348465, lng: 19.7596396 },
    { lat: 48.8351589, lng: 19.7600834 },
    { lat: 48.8354174, lng: 19.7599158 },
    { lat: 48.8354435, lng: 19.7593807 },
    { lat: 48.835252, lng: 19.7588414 },
    { lat: 48.8351582, lng: 19.7579053 },
    { lat: 48.8350546, lng: 19.7577773 },
    { lat: 48.8348647, lng: 19.7576887 },
    { lat: 48.8346417, lng: 19.7576911 },
    { lat: 48.8345288, lng: 19.7578351 },
    { lat: 48.8334303, lng: 19.7576588 },
    { lat: 48.8331978, lng: 19.7583709 },
    { lat: 48.8328551, lng: 19.758361 },
    { lat: 48.831768, lng: 19.7572305 },
    { lat: 48.8315808, lng: 19.7571019 },
    { lat: 48.8315282, lng: 19.756672 },
    { lat: 48.8312052, lng: 19.7566005 },
    { lat: 48.8310524, lng: 19.7569448 },
    { lat: 48.8306471, lng: 19.7571085 },
    { lat: 48.8307794, lng: 19.7568614 },
    { lat: 48.8310143, lng: 19.7560758 },
    { lat: 48.830959, lng: 19.7559715 },
    { lat: 48.8310541, lng: 19.7555729 },
    { lat: 48.8312873, lng: 19.755032 },
    { lat: 48.8314988, lng: 19.7548173 },
    { lat: 48.8315064, lng: 19.7547361 },
    { lat: 48.8316408, lng: 19.7546889 },
    { lat: 48.8316312, lng: 19.7548322 },
    { lat: 48.8318522, lng: 19.7548586 },
    { lat: 48.8319076, lng: 19.7544057 },
    { lat: 48.8316364, lng: 19.7543311 },
    { lat: 48.8313858, lng: 19.7543491 },
    { lat: 48.8313567, lng: 19.7541715 },
    { lat: 48.8309541, lng: 19.7542395 },
    { lat: 48.8307716, lng: 19.7545243 },
    { lat: 48.8305682, lng: 19.7550228 },
    { lat: 48.830373, lng: 19.755066 },
    { lat: 48.8307271, lng: 19.7540742 },
    { lat: 48.8308524, lng: 19.7539014 },
    { lat: 48.8310839, lng: 19.7537044 },
    { lat: 48.8314489, lng: 19.7537792 },
    { lat: 48.8316967, lng: 19.7537021 },
    { lat: 48.8320034, lng: 19.7532246 },
    { lat: 48.8319314, lng: 19.7530459 },
    { lat: 48.8319805, lng: 19.7529555 },
    { lat: 48.8318098, lng: 19.7527388 },
    { lat: 48.8321292, lng: 19.7521853 },
    { lat: 48.8320862, lng: 19.7516943 },
    { lat: 48.8315583, lng: 19.7516498 },
    { lat: 48.8316389, lng: 19.7512323 },
    { lat: 48.8312529, lng: 19.7509042 },
    { lat: 48.8311847, lng: 19.7507387 },
    { lat: 48.8314493, lng: 19.7503763 },
    { lat: 48.8313212, lng: 19.749949 },
    { lat: 48.8316622, lng: 19.748858 },
    { lat: 48.8317577, lng: 19.7480606 },
    { lat: 48.8319837, lng: 19.7475075 },
    { lat: 48.8320359, lng: 19.7468257 },
    { lat: 48.8324828, lng: 19.745917 },
    { lat: 48.8328359, lng: 19.7448501 },
    { lat: 48.8325775, lng: 19.7447925 },
    { lat: 48.8323252, lng: 19.744456 },
    { lat: 48.8321553, lng: 19.7440007 },
    { lat: 48.8318172, lng: 19.7435381 },
    { lat: 48.8314457, lng: 19.7436753 },
    { lat: 48.8308364, lng: 19.7436763 },
    { lat: 48.8308102, lng: 19.7439032 },
    { lat: 48.8297323, lng: 19.7438548 },
    { lat: 48.829536, lng: 19.7424943 },
    { lat: 48.8292114, lng: 19.7416671 },
    { lat: 48.8294001, lng: 19.7410934 },
    { lat: 48.8300557, lng: 19.7404219 },
    { lat: 48.8305114, lng: 19.73964 },
    { lat: 48.8310772, lng: 19.7379287 },
    { lat: 48.8313136, lng: 19.7376689 },
    { lat: 48.8314593, lng: 19.7372404 },
    { lat: 48.8309038, lng: 19.7372132 },
    { lat: 48.8306433, lng: 19.7373124 },
    { lat: 48.8305451, lng: 19.7369218 },
    { lat: 48.8302873, lng: 19.7371853 },
    { lat: 48.8299284, lng: 19.7373499 },
    { lat: 48.8289973, lng: 19.7379784 },
    { lat: 48.8285779, lng: 19.7380733 },
    { lat: 48.8285018, lng: 19.7379841 },
    { lat: 48.828303, lng: 19.7379952 },
    { lat: 48.828044, lng: 19.738204 },
    { lat: 48.8280363, lng: 19.7380836 },
    { lat: 48.8277562, lng: 19.7376838 },
    { lat: 48.8284255, lng: 19.7367599 },
    { lat: 48.8295499, lng: 19.7355357 },
    { lat: 48.8296808, lng: 19.735151 },
    { lat: 48.8306486, lng: 19.7340541 },
    { lat: 48.8314326, lng: 19.7333066 },
    { lat: 48.8312318, lng: 19.7326871 },
    { lat: 48.8302454, lng: 19.7332388 },
    { lat: 48.8296684, lng: 19.7338724 },
    { lat: 48.8295921, lng: 19.7336237 },
    { lat: 48.8293457, lng: 19.7338145 },
    { lat: 48.82905, lng: 19.7337243 },
    { lat: 48.8288886, lng: 19.7324883 },
    { lat: 48.8292504, lng: 19.7321578 },
    { lat: 48.8286113, lng: 19.7302835 },
    { lat: 48.828439, lng: 19.7292647 },
    { lat: 48.8283546, lng: 19.7289371 },
    { lat: 48.8283177, lng: 19.7289397 },
    { lat: 48.8282146, lng: 19.7286734 },
    { lat: 48.8283244, lng: 19.728584 },
    { lat: 48.8280833, lng: 19.7276883 },
    { lat: 48.828053, lng: 19.7269995 },
    { lat: 48.8282031, lng: 19.726848 },
    { lat: 48.8275883, lng: 19.7259655 },
    { lat: 48.8272458, lng: 19.725635 },
    { lat: 48.8272675, lng: 19.7255095 },
    { lat: 48.8269272, lng: 19.7252782 },
    { lat: 48.8266747, lng: 19.7248196 },
    { lat: 48.8264912, lng: 19.7246687 },
    { lat: 48.8265368, lng: 19.7244781 },
    { lat: 48.8264812, lng: 19.7243143 },
    { lat: 48.826472, lng: 19.7240295 },
    { lat: 48.8266364, lng: 19.7240762 },
    { lat: 48.8266299, lng: 19.7240307 },
    { lat: 48.8259452, lng: 19.7238082 },
    { lat: 48.8255657, lng: 19.7239131 },
    { lat: 48.8254478, lng: 19.723413 },
    { lat: 48.8252733, lng: 19.721734 },
    { lat: 48.8249779, lng: 19.7219577 },
    { lat: 48.8244548, lng: 19.7222041 },
    { lat: 48.8237697, lng: 19.7223292 },
    { lat: 48.8238373, lng: 19.7219977 },
    { lat: 48.8253095, lng: 19.7211465 },
    { lat: 48.8258993, lng: 19.720908 },
    { lat: 48.8264227, lng: 19.7205643 },
    { lat: 48.8270798, lng: 19.7200043 },
    { lat: 48.8272556, lng: 19.7199332 },
    { lat: 48.827276, lng: 19.7198067 },
    { lat: 48.8280091, lng: 19.7192626 },
    { lat: 48.8285716, lng: 19.7191899 },
    { lat: 48.828759, lng: 19.719227 },
    { lat: 48.8298729, lng: 19.7198591 },
    { lat: 48.8313693, lng: 19.7200137 },
    { lat: 48.8327059, lng: 19.7199745 },
    { lat: 48.8331562, lng: 19.7200662 },
    { lat: 48.8333507, lng: 19.7198788 },
    { lat: 48.8339345, lng: 19.7195779 },
    { lat: 48.8339328, lng: 19.7194118 },
    { lat: 48.8343266, lng: 19.7192446 },
    { lat: 48.8344093, lng: 19.7193331 },
    { lat: 48.8351225, lng: 19.7195345 },
    { lat: 48.8357486, lng: 19.7198348 },
    { lat: 48.8357203, lng: 19.7199854 },
    { lat: 48.8362317, lng: 19.7204661 },
    { lat: 48.8362074, lng: 19.7205315 },
    { lat: 48.8363955, lng: 19.720707 },
    { lat: 48.8365863, lng: 19.7211255 },
    { lat: 48.8366733, lng: 19.721539 },
    { lat: 48.8366604, lng: 19.7218565 },
    { lat: 48.8367675, lng: 19.7222941 },
    { lat: 48.8368463, lng: 19.7242636 },
    { lat: 48.8370914, lng: 19.724659 },
    { lat: 48.8376252, lng: 19.7253174 },
    { lat: 48.8379592, lng: 19.7256327 },
    { lat: 48.8385473, lng: 19.7264791 },
    { lat: 48.8395649, lng: 19.7262285 },
    { lat: 48.8397761, lng: 19.7260126 },
    { lat: 48.8399189, lng: 19.7260285 },
    { lat: 48.839527, lng: 19.7249362 },
    { lat: 48.8396869, lng: 19.7237597 },
    { lat: 48.83975, lng: 19.7227587 },
    { lat: 48.8398444, lng: 19.7222716 },
    { lat: 48.8400719, lng: 19.7219113 },
    { lat: 48.8403925, lng: 19.7215419 },
    { lat: 48.8406113, lng: 19.7211127 },
    { lat: 48.8406937, lng: 19.7207871 },
    { lat: 48.8406956, lng: 19.7202139 },
    { lat: 48.8404949, lng: 19.7189613 },
    { lat: 48.8405484, lng: 19.7181837 },
    { lat: 48.8406941, lng: 19.7176556 },
    { lat: 48.8407437, lng: 19.7172976 },
    { lat: 48.8405327, lng: 19.7159369 },
    { lat: 48.840131, lng: 19.7159773 },
    { lat: 48.8397352, lng: 19.7159141 },
    { lat: 48.8390224, lng: 19.7156941 },
    { lat: 48.8384715, lng: 19.7151108 },
    { lat: 48.8362437, lng: 19.713929 },
    { lat: 48.8354423, lng: 19.7132041 },
    { lat: 48.8347756, lng: 19.713163 },
    { lat: 48.834195, lng: 19.7129476 },
    { lat: 48.8328108, lng: 19.7126045 },
    { lat: 48.8321618, lng: 19.712701 },
    { lat: 48.83175, lng: 19.712947 },
    { lat: 48.8314511, lng: 19.7132459 },
    { lat: 48.830907, lng: 19.7131684 },
    { lat: 48.8303173, lng: 19.7129274 },
    { lat: 48.8298772, lng: 19.7126338 },
    { lat: 48.829091, lng: 19.7128422 },
    { lat: 48.8286018, lng: 19.7128632 },
    { lat: 48.828571, lng: 19.7132608 },
    { lat: 48.828637, lng: 19.7141931 },
    { lat: 48.8287784, lng: 19.7145399 },
    { lat: 48.8286351, lng: 19.7151978 },
    { lat: 48.8284824, lng: 19.7153845 },
    { lat: 48.828298, lng: 19.7159314 },
    { lat: 48.8281063, lng: 19.7160527 },
    { lat: 48.828003, lng: 19.7170812 },
    { lat: 48.8278978, lng: 19.7171008 },
    { lat: 48.8279081, lng: 19.7173612 },
    { lat: 48.8277698, lng: 19.717441 },
    { lat: 48.8276792, lng: 19.7173518 },
    { lat: 48.8273605, lng: 19.7175343 },
    { lat: 48.8273036, lng: 19.7174482 },
    { lat: 48.8267766, lng: 19.7178591 },
    { lat: 48.8260113, lng: 19.7180811 },
    { lat: 48.8256732, lng: 19.7181075 },
    { lat: 48.8252624, lng: 19.7189086 },
    { lat: 48.824989, lng: 19.7185834 },
    { lat: 48.8247202, lng: 19.7181368 },
    { lat: 48.824752, lng: 19.7179105 },
    { lat: 48.8250419, lng: 19.7179197 },
    { lat: 48.825037, lng: 19.7178348 },
    { lat: 48.8245648, lng: 19.7176806 },
    { lat: 48.8243326, lng: 19.7175235 },
    { lat: 48.8242152, lng: 19.7171568 },
    { lat: 48.8242441, lng: 19.7165885 },
    { lat: 48.8238055, lng: 19.7162525 },
    { lat: 48.8238889, lng: 19.7160464 },
    { lat: 48.8237423, lng: 19.7157891 },
    { lat: 48.8232675, lng: 19.7152716 },
    { lat: 48.8229958, lng: 19.7152655 },
    { lat: 48.8229587, lng: 19.7151148 },
    { lat: 48.8230584, lng: 19.715074 },
    { lat: 48.8228182, lng: 19.7144107 },
    { lat: 48.8227252, lng: 19.7139085 },
    { lat: 48.8227277, lng: 19.7135321 },
    { lat: 48.8222731, lng: 19.7130122 },
    { lat: 48.8223732, lng: 19.7126213 },
    { lat: 48.8227483, lng: 19.7120391 },
    { lat: 48.8226497, lng: 19.7113636 },
    { lat: 48.8224216, lng: 19.7112234 },
    { lat: 48.8223106, lng: 19.7112604 },
    { lat: 48.8221626, lng: 19.7111009 },
    { lat: 48.8218021, lng: 19.7103098 },
    { lat: 48.8210942, lng: 19.7097416 },
    { lat: 48.8209272, lng: 19.7097078 },
    { lat: 48.820921, lng: 19.7095425 },
    { lat: 48.8210854, lng: 19.7094643 },
    { lat: 48.8210988, lng: 19.7096178 },
    { lat: 48.8224156, lng: 19.7095546 },
    { lat: 48.8229367, lng: 19.709343 },
    { lat: 48.8237334, lng: 19.7091441 },
    { lat: 48.823879, lng: 19.7091995 },
    { lat: 48.8241253, lng: 19.7091119 },
    { lat: 48.8241601, lng: 19.7089585 },
    { lat: 48.8244074, lng: 19.7089191 },
    { lat: 48.8243421, lng: 19.7086919 },
    { lat: 48.8248846, lng: 19.7085246 },
    { lat: 48.8259706, lng: 19.7078094 },
    { lat: 48.8266884, lng: 19.707837 },
    { lat: 48.8274224, lng: 19.7079284 },
    { lat: 48.8282524, lng: 19.7081234 },
    { lat: 48.8285143, lng: 19.7080346 },
    { lat: 48.8299026, lng: 19.7086754 },
    { lat: 48.8302755, lng: 19.708929 },
    { lat: 48.8313529, lng: 19.7093425 },
    { lat: 48.832789, lng: 19.7098444 },
    { lat: 48.8343303, lng: 19.710149 },
    { lat: 48.8356875, lng: 19.7111082 },
    { lat: 48.8357948, lng: 19.7108809 },
    { lat: 48.8369101, lng: 19.7114701 },
    { lat: 48.8386521, lng: 19.7120867 },
    { lat: 48.8389732, lng: 19.7122895 },
    { lat: 48.8392761, lng: 19.7123099 },
    { lat: 48.8397595, lng: 19.7121582 },
    { lat: 48.8399645, lng: 19.7121557 },
    { lat: 48.8401975, lng: 19.7119467 },
    { lat: 48.8406852, lng: 19.7118164 },
    { lat: 48.8412934, lng: 19.7118656 },
    { lat: 48.8416356, lng: 19.7118026 },
    { lat: 48.842643, lng: 19.7114289 },
    { lat: 48.8430761, lng: 19.7114916 },
    { lat: 48.8435666, lng: 19.7113982 },
    { lat: 48.8438465, lng: 19.7114807 },
    { lat: 48.8443405, lng: 19.7114949 },
    { lat: 48.8457732, lng: 19.7113295 },
    { lat: 48.8459201, lng: 19.7112398 },
    { lat: 48.846829, lng: 19.7115118 },
    { lat: 48.8471648, lng: 19.711541 },
    { lat: 48.8479195, lng: 19.7117907 },
    { lat: 48.8483704, lng: 19.7117188 },
    { lat: 48.8496428, lng: 19.7117811 },
    { lat: 48.8507374, lng: 19.7116178 },
    { lat: 48.8520992, lng: 19.7120409 },
    { lat: 48.8525844, lng: 19.7119816 },
    { lat: 48.8534154, lng: 19.7117413 },
    { lat: 48.8537308, lng: 19.7117673 },
    { lat: 48.8540724, lng: 19.71167 },
    { lat: 48.8546068, lng: 19.7116251 },
    { lat: 48.8548656, lng: 19.7116689 },
    { lat: 48.8556638, lng: 19.7120151 },
    { lat: 48.8569306, lng: 19.7130469 },
    { lat: 48.8571812, lng: 19.7131553 },
    { lat: 48.857665, lng: 19.7132421 },
    { lat: 48.8582704, lng: 19.713175 },
    { lat: 48.859505, lng: 19.7125838 },
    { lat: 48.8600374, lng: 19.7124178 },
    { lat: 48.8610161, lng: 19.7122602 },
    { lat: 48.8614931, lng: 19.7122683 },
    { lat: 48.8617791, lng: 19.7123649 },
    { lat: 48.8621174, lng: 19.7128569 },
    { lat: 48.8628636, lng: 19.7135824 },
    { lat: 48.863762, lng: 19.7139002 },
    { lat: 48.864177, lng: 19.7139293 },
    { lat: 48.8666315, lng: 19.7137418 },
    { lat: 48.8673523, lng: 19.7140194 },
    { lat: 48.8677726, lng: 19.7140963 },
    { lat: 48.8687774, lng: 19.7149838 },
    { lat: 48.8690843, lng: 19.7155546 },
    { lat: 48.8694777, lng: 19.7160695 },
    { lat: 48.8700372, lng: 19.7169518 },
    { lat: 48.8706674, lng: 19.7177772 },
    { lat: 48.8713928, lng: 19.7184119 },
    { lat: 48.8717664, lng: 19.7185065 },
    { lat: 48.8718818, lng: 19.7186447 },
    { lat: 48.8723108, lng: 19.7187952 },
    { lat: 48.8726049, lng: 19.718997 },
    { lat: 48.873237, lng: 19.7191 },
    { lat: 48.8737797, lng: 19.7194262 },
    { lat: 48.8738799, lng: 19.7193208 },
    { lat: 48.8739754, lng: 19.719367 },
    { lat: 48.8755365, lng: 19.7193583 },
    { lat: 48.87593, lng: 19.7191588 },
    { lat: 48.877049, lng: 19.7188839 },
    { lat: 48.8772876, lng: 19.7189306 },
    { lat: 48.87751, lng: 19.7188873 },
    { lat: 48.8779947, lng: 19.7191238 },
    { lat: 48.8781964, lng: 19.7193278 },
    { lat: 48.8783248, lng: 19.7195571 },
    { lat: 48.8791317, lng: 19.7203332 },
    { lat: 48.8792007, lng: 19.7212075 },
    { lat: 48.87944, lng: 19.7229522 },
    { lat: 48.8801518, lng: 19.7238201 },
    { lat: 48.880227, lng: 19.7237584 },
    { lat: 48.8802644, lng: 19.7238194 },
    { lat: 48.8804898, lng: 19.7247398 },
    { lat: 48.881091, lng: 19.725111 },
    { lat: 48.8810854, lng: 19.7258053 },
    { lat: 48.881473, lng: 19.7258297 },
    { lat: 48.8814235, lng: 19.7254242 },
    { lat: 48.8815777, lng: 19.7196683 },
    { lat: 48.8817114, lng: 19.7174908 },
    { lat: 48.880324, lng: 19.7173008 },
    { lat: 48.8802161, lng: 19.7168446 },
    { lat: 48.8797871, lng: 19.7165921 },
    { lat: 48.8795383, lng: 19.7162676 },
    { lat: 48.8793475, lng: 19.7158496 },
    { lat: 48.8791196, lng: 19.7156658 },
    { lat: 48.8788639, lng: 19.7156105 },
    { lat: 48.8776915, lng: 19.7148578 },
    { lat: 48.8763352, lng: 19.7145799 },
    { lat: 48.875148, lng: 19.7138221 },
    { lat: 48.8734044, lng: 19.7099027 },
    { lat: 48.8730413, lng: 19.7089786 },
    { lat: 48.872311, lng: 19.7074713 },
    { lat: 48.8718073, lng: 19.7070096 },
    { lat: 48.8716319, lng: 19.7064072 },
    { lat: 48.871523, lng: 19.7053685 },
    { lat: 48.871298, lng: 19.7044619 },
    { lat: 48.8719074, lng: 19.7035396 },
    { lat: 48.8721279, lng: 19.7031034 },
    { lat: 48.8735603, lng: 19.7016353 },
    { lat: 48.8739657, lng: 19.7008156 },
    { lat: 48.8743647, lng: 19.7003892 },
    { lat: 48.8751896, lng: 19.6997812 },
    { lat: 48.8755245, lng: 19.6993523 },
    { lat: 48.8758119, lng: 19.6982615 },
    { lat: 48.8765718, lng: 19.6961901 },
    { lat: 48.8768994, lng: 19.6955239 },
    { lat: 48.8772386, lng: 19.6944295 },
    { lat: 48.8773352, lng: 19.6937113 },
    { lat: 48.8766367, lng: 19.6911942 },
    { lat: 48.8765168, lng: 19.6898638 },
    { lat: 48.8762378, lng: 19.6886048 },
    { lat: 48.876217, lng: 19.6879615 },
    { lat: 48.87643, lng: 19.6872007 },
    { lat: 48.876397, lng: 19.6864033 },
    { lat: 48.8764943, lng: 19.6856503 },
    { lat: 48.8767475, lng: 19.6850876 },
    { lat: 48.876573, lng: 19.684508 },
    { lat: 48.8765083, lng: 19.6839859 },
    { lat: 48.8761734, lng: 19.683777 },
    { lat: 48.8757679, lng: 19.6831201 },
    { lat: 48.8751419, lng: 19.6825981 },
    { lat: 48.8745783, lng: 19.6812519 },
    { lat: 48.8742941, lng: 19.6807376 },
    { lat: 48.8741211, lng: 19.6802931 },
    { lat: 48.8734217, lng: 19.6801677 },
    { lat: 48.8724683, lng: 19.6803431 },
    { lat: 48.8718617, lng: 19.6802301 },
    { lat: 48.8706671, lng: 19.6807837 },
    { lat: 48.8683824, lng: 19.6813667 },
    { lat: 48.8680113, lng: 19.6812833 },
    { lat: 48.8668749, lng: 19.6815207 },
    { lat: 48.8648487, lng: 19.6814627 },
    { lat: 48.864216, lng: 19.6815205 },
    { lat: 48.8628772, lng: 19.6825536 },
    { lat: 48.8623893, lng: 19.6828342 },
    { lat: 48.8621989, lng: 19.6827825 },
    { lat: 48.8620285, lng: 19.6828271 },
    { lat: 48.8613641, lng: 19.6831657 },
    { lat: 48.8609718, lng: 19.683184 },
    { lat: 48.8604679, lng: 19.6834315 },
    { lat: 48.8600902, lng: 19.6833283 },
    { lat: 48.8598403, lng: 19.6831803 },
    { lat: 48.8586443, lng: 19.6832422 },
    { lat: 48.8550708, lng: 19.6839332 },
    { lat: 48.8546604, lng: 19.6842814 },
    { lat: 48.8539254, lng: 19.6843782 },
    { lat: 48.852579, lng: 19.685313 },
    { lat: 48.851769, lng: 19.6865852 },
    { lat: 48.851174, lng: 19.6870898 },
    { lat: 48.8501217, lng: 19.6864037 },
    { lat: 48.8487629, lng: 19.6859223 },
    { lat: 48.8467543, lng: 19.6859303 },
    { lat: 48.8448759, lng: 19.6852659 },
    { lat: 48.8437607, lng: 19.6845097 },
    { lat: 48.842729, lng: 19.6842132 },
    { lat: 48.8411891, lng: 19.6843703 },
    { lat: 48.8400493, lng: 19.6842544 },
    { lat: 48.8394171, lng: 19.6836129 },
    { lat: 48.8383782, lng: 19.6835647 },
    { lat: 48.8378684, lng: 19.6829411 },
    { lat: 48.837549, lng: 19.6826514 },
    { lat: 48.8369701, lng: 19.6824808 },
    { lat: 48.8352286, lng: 19.6833506 },
    { lat: 48.8345639, lng: 19.6839595 },
    { lat: 48.834049, lng: 19.6839079 },
    { lat: 48.831983, lng: 19.6834102 },
    { lat: 48.8304775, lng: 19.6827949 },
  ],
};

export default UnitsBrezno;
