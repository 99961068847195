const UnitsDunajskáStreda = {
  Boheľov: [
    { lat: 47.9316054, lng: 17.6991589 },
    { lat: 47.9315692, lng: 17.6988531 },
    { lat: 47.9312513, lng: 17.6968681 },
    { lat: 47.9309584, lng: 17.6954757 },
    { lat: 47.9304395, lng: 17.6939218 },
    { lat: 47.9301981, lng: 17.693333 },
    { lat: 47.9300877, lng: 17.6934103 },
    { lat: 47.9297752, lng: 17.6927677 },
    { lat: 47.9286809, lng: 17.6901422 },
    { lat: 47.9278276, lng: 17.6904332 },
    { lat: 47.9274068, lng: 17.6906901 },
    { lat: 47.9267607, lng: 17.6896117 },
    { lat: 47.9263679, lng: 17.6887695 },
    { lat: 47.9260152, lng: 17.6889967 },
    { lat: 47.9258358, lng: 17.6884958 },
    { lat: 47.9253541, lng: 17.6887545 },
    { lat: 47.9245839, lng: 17.689055 },
    { lat: 47.9236543, lng: 17.6889234 },
    { lat: 47.923246, lng: 17.6889683 },
    { lat: 47.9216764, lng: 17.6904344 },
    { lat: 47.921536, lng: 17.6906985 },
    { lat: 47.9213727, lng: 17.6905605 },
    { lat: 47.9212171, lng: 17.6904931 },
    { lat: 47.9184523, lng: 17.6901183 },
    { lat: 47.9181925, lng: 17.688217 },
    { lat: 47.9181527, lng: 17.688232 },
    { lat: 47.9181362, lng: 17.6881125 },
    { lat: 47.9172656, lng: 17.6885224 },
    { lat: 47.9171577, lng: 17.6887106 },
    { lat: 47.9169931, lng: 17.6885161 },
    { lat: 47.9167719, lng: 17.6889147 },
    { lat: 47.9167154, lng: 17.6888412 },
    { lat: 47.9164263, lng: 17.6893264 },
    { lat: 47.9163873, lng: 17.6891154 },
    { lat: 47.9162594, lng: 17.688934 },
    { lat: 47.9161413, lng: 17.6890486 },
    { lat: 47.9162225, lng: 17.6892457 },
    { lat: 47.9160808, lng: 17.689377 },
    { lat: 47.9158093, lng: 17.6887961 },
    { lat: 47.9149844, lng: 17.6896624 },
    { lat: 47.9150226, lng: 17.6897503 },
    { lat: 47.9146125, lng: 17.690197 },
    { lat: 47.912191, lng: 17.6867043 },
    { lat: 47.9115129, lng: 17.6852606 },
    { lat: 47.9115744, lng: 17.6842846 },
    { lat: 47.9107354, lng: 17.6817727 },
    { lat: 47.9099593, lng: 17.6823578 },
    { lat: 47.909141, lng: 17.6835459 },
    { lat: 47.9051958, lng: 17.6815911 },
    { lat: 47.9031666, lng: 17.680241 },
    { lat: 47.9012197, lng: 17.6793616 },
    { lat: 47.8964703, lng: 17.6764888 },
    { lat: 47.8954765, lng: 17.6775425 },
    { lat: 47.8945828, lng: 17.6787669 },
    { lat: 47.8942874, lng: 17.6799212 },
    { lat: 47.8939889, lng: 17.6803238 },
    { lat: 47.8936953, lng: 17.6807179 },
    { lat: 47.8925364, lng: 17.6817829 },
    { lat: 47.8913302, lng: 17.6811517 },
    { lat: 47.8903912, lng: 17.6803887 },
    { lat: 47.8886673, lng: 17.679622 },
    { lat: 47.8878468, lng: 17.6796832 },
    { lat: 47.8872983, lng: 17.6804548 },
    { lat: 47.886797, lng: 17.6812809 },
    { lat: 47.8855739, lng: 17.68552 },
    { lat: 47.8850296, lng: 17.6868986 },
    { lat: 47.8847628, lng: 17.6872161 },
    { lat: 47.88494, lng: 17.6903662 },
    { lat: 47.8852332, lng: 17.6914014 },
    { lat: 47.8852799, lng: 17.6925943 },
    { lat: 47.883901, lng: 17.6941122 },
    { lat: 47.8844453, lng: 17.6945609 },
    { lat: 47.8883863, lng: 17.69787 },
    { lat: 47.8897085, lng: 17.6983231 },
    { lat: 47.8909692, lng: 17.6990135 },
    { lat: 47.8921893, lng: 17.7002999 },
    { lat: 47.8935222, lng: 17.7019622 },
    { lat: 47.8950531, lng: 17.7041705 },
    { lat: 47.8955171, lng: 17.7046003 },
    { lat: 47.8959835, lng: 17.7053197 },
    { lat: 47.8965442, lng: 17.7065361 },
    { lat: 47.8967401, lng: 17.7071569 },
    { lat: 47.896844, lng: 17.7080013 },
    { lat: 47.8968124, lng: 17.7084144 },
    { lat: 47.8973878, lng: 17.7087683 },
    { lat: 47.8979553, lng: 17.7090304 },
    { lat: 47.9009636, lng: 17.70983 },
    { lat: 47.9012184, lng: 17.7111563 },
    { lat: 47.9014326, lng: 17.7127671 },
    { lat: 47.9018434, lng: 17.7143673 },
    { lat: 47.9022922, lng: 17.715566 },
    { lat: 47.9027943, lng: 17.7164759 },
    { lat: 47.9032694, lng: 17.717069 },
    { lat: 47.9041029, lng: 17.7177215 },
    { lat: 47.9045281, lng: 17.7179113 },
    { lat: 47.9056339, lng: 17.718133 },
    { lat: 47.9063102, lng: 17.7180814 },
    { lat: 47.9085401, lng: 17.7175243 },
    { lat: 47.9092531, lng: 17.7186141 },
    { lat: 47.9095691, lng: 17.7185562 },
    { lat: 47.9108035, lng: 17.7181658 },
    { lat: 47.9114341, lng: 17.7178868 },
    { lat: 47.9161305, lng: 17.7144071 },
    { lat: 47.9164568, lng: 17.714045 },
    { lat: 47.9168174, lng: 17.7137472 },
    { lat: 47.9177627, lng: 17.7126896 },
    { lat: 47.9181724, lng: 17.7120939 },
    { lat: 47.9186954, lng: 17.7110361 },
    { lat: 47.9189041, lng: 17.7103698 },
    { lat: 47.9190398, lng: 17.7092373 },
    { lat: 47.9190193, lng: 17.7084799 },
    { lat: 47.9189636, lng: 17.7081318 },
    { lat: 47.9186406, lng: 17.706602 },
    { lat: 47.9182712, lng: 17.7054321 },
    { lat: 47.9181483, lng: 17.7052721 },
    { lat: 47.9181013, lng: 17.7048813 },
    { lat: 47.9179327, lng: 17.7044806 },
    { lat: 47.9174848, lng: 17.7037865 },
    { lat: 47.9163422, lng: 17.7023328 },
    { lat: 47.9155014, lng: 17.7016712 },
    { lat: 47.9149345, lng: 17.7015276 },
    { lat: 47.9144294, lng: 17.7012156 },
    { lat: 47.9142397, lng: 17.701197 },
    { lat: 47.9153814, lng: 17.6957056 },
    { lat: 47.9170256, lng: 17.6942734 },
    { lat: 47.9182427, lng: 17.6962302 },
    { lat: 47.9182518, lng: 17.696356 },
    { lat: 47.9186493, lng: 17.6973417 },
    { lat: 47.9189413, lng: 17.6968879 },
    { lat: 47.9193653, lng: 17.6977419 },
    { lat: 47.9194547, lng: 17.6979973 },
    { lat: 47.9194223, lng: 17.6981824 },
    { lat: 47.9194619, lng: 17.6988463 },
    { lat: 47.9194403, lng: 17.7001738 },
    { lat: 47.920137, lng: 17.7007664 },
    { lat: 47.9203442, lng: 17.7011356 },
    { lat: 47.920337, lng: 17.7016875 },
    { lat: 47.9203874, lng: 17.7016981 },
    { lat: 47.9225536, lng: 17.6979702 },
    { lat: 47.922732, lng: 17.6980453 },
    { lat: 47.9225647, lng: 17.6978982 },
    { lat: 47.9278247, lng: 17.6958633 },
    { lat: 47.928308, lng: 17.7005904 },
    { lat: 47.9283412, lng: 17.7005766 },
    { lat: 47.9316054, lng: 17.6991589 },
  ],
  Mad: [
    { lat: 47.9590254, lng: 17.6494264 },
    { lat: 47.9567721, lng: 17.6477387 },
    { lat: 47.9554763, lng: 17.6460173 },
    { lat: 47.9546571, lng: 17.6446708 },
    { lat: 47.9534121, lng: 17.6415553 },
    { lat: 47.9535579, lng: 17.6398294 },
    { lat: 47.9535514, lng: 17.6383907 },
    { lat: 47.953428, lng: 17.6369248 },
    { lat: 47.9523584, lng: 17.634979 },
    { lat: 47.9518021, lng: 17.6343957 },
    { lat: 47.9513543, lng: 17.6338348 },
    { lat: 47.9503886, lng: 17.6323327 },
    { lat: 47.9499147, lng: 17.6319453 },
    { lat: 47.9491708, lng: 17.6317364 },
    { lat: 47.9476889, lng: 17.6311255 },
    { lat: 47.9451554, lng: 17.632001 },
    { lat: 47.9410313, lng: 17.6325459 },
    { lat: 47.9400172, lng: 17.6326697 },
    { lat: 47.9397135, lng: 17.6365859 },
    { lat: 47.9390317, lng: 17.6370876 },
    { lat: 47.9391387, lng: 17.6383698 },
    { lat: 47.9401412, lng: 17.6405902 },
    { lat: 47.9401289, lng: 17.6420402 },
    { lat: 47.9398186, lng: 17.6436634 },
    { lat: 47.9394327, lng: 17.6447923 },
    { lat: 47.9389973, lng: 17.645833 },
    { lat: 47.9382916, lng: 17.648508 },
    { lat: 47.9378151, lng: 17.6494023 },
    { lat: 47.9369061, lng: 17.6507001 },
    { lat: 47.9363722, lng: 17.6512034 },
    { lat: 47.9358123, lng: 17.6515463 },
    { lat: 47.9347872, lng: 17.6529761 },
    { lat: 47.9356553, lng: 17.6583649 },
    { lat: 47.9384976, lng: 17.6610329 },
    { lat: 47.9391874, lng: 17.6611029 },
    { lat: 47.9402408, lng: 17.6613168 },
    { lat: 47.9418546, lng: 17.6639299 },
    { lat: 47.9435598, lng: 17.6669389 },
    { lat: 47.9437993, lng: 17.6676551 },
    { lat: 47.9444086, lng: 17.6675252 },
    { lat: 47.9449135, lng: 17.6670084 },
    { lat: 47.9454938, lng: 17.6660772 },
    { lat: 47.9459039, lng: 17.666565 },
    { lat: 47.9467536, lng: 17.6679158 },
    { lat: 47.9466384, lng: 17.6680579 },
    { lat: 47.9477243, lng: 17.6704147 },
    { lat: 47.9475173, lng: 17.6706965 },
    { lat: 47.9470166, lng: 17.6707069 },
    { lat: 47.9465324, lng: 17.6708704 },
    { lat: 47.947153, lng: 17.6726716 },
    { lat: 47.9463443, lng: 17.6730804 },
    { lat: 47.9465884, lng: 17.6735848 },
    { lat: 47.9457031, lng: 17.6749977 },
    { lat: 47.9451214, lng: 17.6765147 },
    { lat: 47.9447915, lng: 17.6776585 },
    { lat: 47.9420932, lng: 17.6766259 },
    { lat: 47.9415691, lng: 17.6765304 },
    { lat: 47.9410814, lng: 17.6775739 },
    { lat: 47.9406527, lng: 17.6782419 },
    { lat: 47.9401586, lng: 17.6788376 },
    { lat: 47.9397589, lng: 17.6795723 },
    { lat: 47.9395391, lng: 17.680403 },
    { lat: 47.9392582, lng: 17.6804094 },
    { lat: 47.9397725, lng: 17.6813845 },
    { lat: 47.9402168, lng: 17.681913 },
    { lat: 47.9412505, lng: 17.682315 },
    { lat: 47.9415383, lng: 17.6823498 },
    { lat: 47.9418736, lng: 17.6825273 },
    { lat: 47.9419395, lng: 17.6822333 },
    { lat: 47.9428056, lng: 17.6828241 },
    { lat: 47.9431578, lng: 17.683237 },
    { lat: 47.9441346, lng: 17.6835948 },
    { lat: 47.9446544, lng: 17.6839078 },
    { lat: 47.9454547, lng: 17.6849611 },
    { lat: 47.9459842, lng: 17.6860357 },
    { lat: 47.9444601, lng: 17.6890361 },
    { lat: 47.9453281, lng: 17.689337 },
    { lat: 47.9462472, lng: 17.6902147 },
    { lat: 47.9459517, lng: 17.6912616 },
    { lat: 47.944929, lng: 17.6926134 },
    { lat: 47.9449426, lng: 17.6933676 },
    { lat: 47.9450322, lng: 17.6937164 },
    { lat: 47.9444411, lng: 17.694497 },
    { lat: 47.9441345, lng: 17.6952574 },
    { lat: 47.9438913, lng: 17.6964558 },
    { lat: 47.9438833, lng: 17.6974566 },
    { lat: 47.9441583, lng: 17.698441 },
    { lat: 47.9452279, lng: 17.6999912 },
    { lat: 47.9461661, lng: 17.7015185 },
    { lat: 47.9477973, lng: 17.7039056 },
    { lat: 47.9485711, lng: 17.7056187 },
    { lat: 47.9494128, lng: 17.7071924 },
    { lat: 47.9500252, lng: 17.7072552 },
    { lat: 47.9499969, lng: 17.7044484 },
    { lat: 47.9500927, lng: 17.7035433 },
    { lat: 47.9502823, lng: 17.7027337 },
    { lat: 47.9505231, lng: 17.7011742 },
    { lat: 47.9509723, lng: 17.700058 },
    { lat: 47.9514013, lng: 17.6993386 },
    { lat: 47.9517474, lng: 17.6989171 },
    { lat: 47.9546643, lng: 17.6961018 },
    { lat: 47.9556862, lng: 17.6939835 },
    { lat: 47.9559554, lng: 17.6936703 },
    { lat: 47.9561907, lng: 17.6911441 },
    { lat: 47.9553771, lng: 17.6889444 },
    { lat: 47.9555813, lng: 17.6877413 },
    { lat: 47.955713, lng: 17.6864243 },
    { lat: 47.9554107, lng: 17.6852974 },
    { lat: 47.9553139, lng: 17.6846396 },
    { lat: 47.9547752, lng: 17.6842794 },
    { lat: 47.953305, lng: 17.6847987 },
    { lat: 47.9523693, lng: 17.6850511 },
    { lat: 47.951409, lng: 17.6834843 },
    { lat: 47.9507756, lng: 17.6811513 },
    { lat: 47.9505261, lng: 17.6782499 },
    { lat: 47.9504517, lng: 17.6765856 },
    { lat: 47.9512035, lng: 17.6723578 },
    { lat: 47.9529824, lng: 17.6693778 },
    { lat: 47.9541996, lng: 17.6680326 },
    { lat: 47.9553259, lng: 17.6669157 },
    { lat: 47.9566335, lng: 17.6652879 },
    { lat: 47.9575281, lng: 17.6642761 },
    { lat: 47.9585778, lng: 17.6641081 },
    { lat: 47.9601785, lng: 17.663262 },
    { lat: 47.960358, lng: 17.6631707 },
    { lat: 47.9600554, lng: 17.6604545 },
    { lat: 47.9597492, lng: 17.6589681 },
    { lat: 47.9594835, lng: 17.6560316 },
    { lat: 47.9597944, lng: 17.6534457 },
    { lat: 47.960636, lng: 17.6512927 },
    { lat: 47.9590438, lng: 17.6498264 },
    { lat: 47.9590254, lng: 17.6494264 },
  ],
  HornýBar: [
    { lat: 47.9478573, lng: 17.4819267 },
    { lat: 47.9478192, lng: 17.4817078 },
    { lat: 47.9487514, lng: 17.4799684 },
    { lat: 47.9490257, lng: 17.4795493 },
    { lat: 47.9494988, lng: 17.4790231 },
    { lat: 47.9497681, lng: 17.4786315 },
    { lat: 47.9503778, lng: 17.4780187 },
    { lat: 47.9519928, lng: 17.4761216 },
    { lat: 47.9521754, lng: 17.4759583 },
    { lat: 47.9543364, lng: 17.4750941 },
    { lat: 47.9555318, lng: 17.4741783 },
    { lat: 47.9557207, lng: 17.4739757 },
    { lat: 47.9560792, lng: 17.4743283 },
    { lat: 47.9571855, lng: 17.4725638 },
    { lat: 47.9577959, lng: 17.4732847 },
    { lat: 47.9582035, lng: 17.4722562 },
    { lat: 47.9584453, lng: 17.4725357 },
    { lat: 47.9591653, lng: 17.4712234 },
    { lat: 47.959563, lng: 17.4713131 },
    { lat: 47.9606593, lng: 17.4697736 },
    { lat: 47.9626462, lng: 17.4667663 },
    { lat: 47.9644088, lng: 17.4639062 },
    { lat: 47.9646566, lng: 17.4634442 },
    { lat: 47.9650657, lng: 17.4621259 },
    { lat: 47.965769, lng: 17.4602775 },
    { lat: 47.965804, lng: 17.4601573 },
    { lat: 47.9652654, lng: 17.4601069 },
    { lat: 47.9654622, lng: 17.4591715 },
    { lat: 47.964235, lng: 17.4583249 },
    { lat: 47.9647473, lng: 17.456634 },
    { lat: 47.9654737, lng: 17.4536631 },
    { lat: 47.9667814, lng: 17.4495986 },
    { lat: 47.967563, lng: 17.4467519 },
    { lat: 47.9676869, lng: 17.445858 },
    { lat: 47.9673774, lng: 17.4457363 },
    { lat: 47.9621228, lng: 17.4440456 },
    { lat: 47.9616771, lng: 17.4447111 },
    { lat: 47.9610226, lng: 17.445204 },
    { lat: 47.9607592, lng: 17.4456374 },
    { lat: 47.9568206, lng: 17.4416413 },
    { lat: 47.9569255, lng: 17.4415152 },
    { lat: 47.9572513, lng: 17.4408664 },
    { lat: 47.9580449, lng: 17.438364 },
    { lat: 47.9556082, lng: 17.4341401 },
    { lat: 47.9551443, lng: 17.4334715 },
    { lat: 47.9549928, lng: 17.4334352 },
    { lat: 47.9544141, lng: 17.4326285 },
    { lat: 47.9542056, lng: 17.4321854 },
    { lat: 47.9539493, lng: 17.4318769 },
    { lat: 47.9536417, lng: 17.4313668 },
    { lat: 47.9532097, lng: 17.4310321 },
    { lat: 47.9530191, lng: 17.4307915 },
    { lat: 47.9519982, lng: 17.4314165 },
    { lat: 47.9507019, lng: 17.4313695 },
    { lat: 47.9497121, lng: 17.4297539 },
    { lat: 47.9494694, lng: 17.4300673 },
    { lat: 47.9488432, lng: 17.430942 },
    { lat: 47.948711, lng: 17.4310266 },
    { lat: 47.9485775, lng: 17.4309183 },
    { lat: 47.9484361, lng: 17.4311234 },
    { lat: 47.9484913, lng: 17.4312947 },
    { lat: 47.9484543, lng: 17.4314713 },
    { lat: 47.946069, lng: 17.4347115 },
    { lat: 47.9456473, lng: 17.4354667 },
    { lat: 47.9456457, lng: 17.4347343 },
    { lat: 47.9453272, lng: 17.4346802 },
    { lat: 47.9452613, lng: 17.4352308 },
    { lat: 47.9450866, lng: 17.4357393 },
    { lat: 47.9449313, lng: 17.4360249 },
    { lat: 47.9423185, lng: 17.4401397 },
    { lat: 47.9419952, lng: 17.4403283 },
    { lat: 47.9412489, lng: 17.4417523 },
    { lat: 47.9408026, lng: 17.4423594 },
    { lat: 47.9389591, lng: 17.4453205 },
    { lat: 47.9390418, lng: 17.4454593 },
    { lat: 47.9388866, lng: 17.4456782 },
    { lat: 47.9386433, lng: 17.4459281 },
    { lat: 47.938826, lng: 17.4464315 },
    { lat: 47.9387816, lng: 17.4465816 },
    { lat: 47.9386219, lng: 17.4469575 },
    { lat: 47.9365905, lng: 17.4505377 },
    { lat: 47.9364818, lng: 17.4507443 },
    { lat: 47.9350158, lng: 17.4531556 },
    { lat: 47.9324604, lng: 17.4571568 },
    { lat: 47.9324146, lng: 17.4575659 },
    { lat: 47.9302489, lng: 17.4611857 },
    { lat: 47.9276244, lng: 17.4654316 },
    { lat: 47.9271438, lng: 17.4661863 },
    { lat: 47.9270349, lng: 17.4662488 },
    { lat: 47.9274166, lng: 17.467677 },
    { lat: 47.9278161, lng: 17.4701091 },
    { lat: 47.9279284, lng: 17.4721346 },
    { lat: 47.927937, lng: 17.4730165 },
    { lat: 47.928128, lng: 17.4741659 },
    { lat: 47.9280838, lng: 17.4748589 },
    { lat: 47.9281662, lng: 17.4755756 },
    { lat: 47.9281627, lng: 17.4758866 },
    { lat: 47.9279919, lng: 17.4765626 },
    { lat: 47.9280483, lng: 17.4771517 },
    { lat: 47.9279874, lng: 17.4771724 },
    { lat: 47.9279326, lng: 17.4763785 },
    { lat: 47.9281214, lng: 17.4760489 },
    { lat: 47.9280626, lng: 17.4759778 },
    { lat: 47.9222603, lng: 17.4853425 },
    { lat: 47.9233472, lng: 17.486518 },
    { lat: 47.9255474, lng: 17.4882324 },
    { lat: 47.9278578, lng: 17.4886809 },
    { lat: 47.9278456, lng: 17.4880394 },
    { lat: 47.9276994, lng: 17.4872983 },
    { lat: 47.9276313, lng: 17.4871887 },
    { lat: 47.9278966, lng: 17.486787 },
    { lat: 47.9281761, lng: 17.4861476 },
    { lat: 47.9287698, lng: 17.4851494 },
    { lat: 47.9293789, lng: 17.4842487 },
    { lat: 47.9310981, lng: 17.4868538 },
    { lat: 47.9328739, lng: 17.4863186 },
    { lat: 47.9335866, lng: 17.486252 },
    { lat: 47.9340075, lng: 17.4862797 },
    { lat: 47.9344826, lng: 17.4865014 },
    { lat: 47.9351719, lng: 17.4868389 },
    { lat: 47.9354272, lng: 17.4869449 },
    { lat: 47.9360759, lng: 17.4871242 },
    { lat: 47.9370618, lng: 17.487267 },
    { lat: 47.9392139, lng: 17.4873618 },
    { lat: 47.9395284, lng: 17.4875477 },
    { lat: 47.9396566, lng: 17.4876935 },
    { lat: 47.9403451, lng: 17.4869922 },
    { lat: 47.9422081, lng: 17.4866705 },
    { lat: 47.9427669, lng: 17.4864928 },
    { lat: 47.9432698, lng: 17.4864267 },
    { lat: 47.9434056, lng: 17.4864843 },
    { lat: 47.9437577, lng: 17.4869516 },
    { lat: 47.9438701, lng: 17.4869598 },
    { lat: 47.9474109, lng: 17.4824991 },
    { lat: 47.9478573, lng: 17.4819267 },
  ],
  DunajskáStreda: [
    { lat: 47.9871543, lng: 17.6387537 },
    { lat: 47.9871615, lng: 17.6387587 },
    { lat: 47.9888117, lng: 17.6402282 },
    { lat: 47.9891935, lng: 17.6406092 },
    { lat: 47.9897299, lng: 17.641286 },
    { lat: 47.9901529, lng: 17.6418152 },
    { lat: 47.9912133, lng: 17.6430667 },
    { lat: 47.9914336, lng: 17.6434436 },
    { lat: 47.99166, lng: 17.6452916 },
    { lat: 47.9917495, lng: 17.6455618 },
    { lat: 47.9922346, lng: 17.6459847 },
    { lat: 47.9924432, lng: 17.645317 },
    { lat: 47.9927071, lng: 17.6447424 },
    { lat: 47.9928737, lng: 17.644503 },
    { lat: 47.9932326, lng: 17.6441582 },
    { lat: 47.9933892, lng: 17.6440834 },
    { lat: 47.9938937, lng: 17.6441849 },
    { lat: 47.9955463, lng: 17.6451066 },
    { lat: 47.996536, lng: 17.6452966 },
    { lat: 47.9977102, lng: 17.6453816 },
    { lat: 47.9981226, lng: 17.6451379 },
    { lat: 47.9986836, lng: 17.6445998 },
    { lat: 47.9988529, lng: 17.6443465 },
    { lat: 47.9999763, lng: 17.641777 },
    { lat: 48.0003771, lng: 17.6406849 },
    { lat: 48.0005827, lng: 17.6397426 },
    { lat: 48.0006581, lng: 17.6387765 },
    { lat: 48.000903, lng: 17.6375953 },
    { lat: 48.0012046, lng: 17.6362978 },
    { lat: 48.001695, lng: 17.6346786 },
    { lat: 48.0019472, lng: 17.6334384 },
    { lat: 48.0031603, lng: 17.6337808 },
    { lat: 48.0048612, lng: 17.6345379 },
    { lat: 48.0055119, lng: 17.6327787 },
    { lat: 48.0062751, lng: 17.6334336 },
    { lat: 48.0074752, lng: 17.6342888 },
    { lat: 48.0074739, lng: 17.6349444 },
    { lat: 48.0080559, lng: 17.6345244 },
    { lat: 48.0085908, lng: 17.6343928 },
    { lat: 48.0090767, lng: 17.6345406 },
    { lat: 48.0100042, lng: 17.6350664 },
    { lat: 48.0115571, lng: 17.6355532 },
    { lat: 48.0126044, lng: 17.6357028 },
    { lat: 48.0126487, lng: 17.6356176 },
    { lat: 48.0146448, lng: 17.6358129 },
    { lat: 48.0154082, lng: 17.6360078 },
    { lat: 48.0170116, lng: 17.6369881 },
    { lat: 48.0172787, lng: 17.6372686 },
    { lat: 48.0183524, lng: 17.6380861 },
    { lat: 48.0221233, lng: 17.6405947 },
    { lat: 48.0229394, lng: 17.6413402 },
    { lat: 48.0240593, lng: 17.6430317 },
    { lat: 48.0266415, lng: 17.6463897 },
    { lat: 48.0276317, lng: 17.6472986 },
    { lat: 48.0291872, lng: 17.649452 },
    { lat: 48.0300953, lng: 17.6508865 },
    { lat: 48.0328051, lng: 17.6558586 },
    { lat: 48.0356923, lng: 17.660994 },
    { lat: 48.0366729, lng: 17.6629172 },
    { lat: 48.0393945, lng: 17.6660952 },
    { lat: 48.0405132, lng: 17.6676319 },
    { lat: 48.0421144, lng: 17.668977 },
    { lat: 48.0419988, lng: 17.669171 },
    { lat: 48.0418783, lng: 17.6693721 },
    { lat: 48.0422126, lng: 17.669714 },
    { lat: 48.0423489, lng: 17.6698534 },
    { lat: 48.0427751, lng: 17.6695379 },
    { lat: 48.0433282, lng: 17.6693906 },
    { lat: 48.0436615, lng: 17.6695736 },
    { lat: 48.0440669, lng: 17.6700605 },
    { lat: 48.0446324, lng: 17.6715254 },
    { lat: 48.0448517, lng: 17.6719611 },
    { lat: 48.045388, lng: 17.6726775 },
    { lat: 48.0457246, lng: 17.673022 },
    { lat: 48.0461571, lng: 17.6731827 },
    { lat: 48.0464369, lng: 17.6731435 },
    { lat: 48.0468078, lng: 17.6735482 },
    { lat: 48.0468806, lng: 17.6739311 },
    { lat: 48.0469496, lng: 17.6740611 },
    { lat: 48.0471549, lng: 17.6742184 },
    { lat: 48.0475854, lng: 17.674276 },
    { lat: 48.0476944, lng: 17.6744107 },
    { lat: 48.0478244, lng: 17.6747923 },
    { lat: 48.0480973, lng: 17.6751433 },
    { lat: 48.0485939, lng: 17.6752858 },
    { lat: 48.0488606, lng: 17.6752182 },
    { lat: 48.0492669, lng: 17.6759856 },
    { lat: 48.0492543, lng: 17.6766998 },
    { lat: 48.0491649, lng: 17.6773436 },
    { lat: 48.0486315, lng: 17.6783573 },
    { lat: 48.0479246, lng: 17.6794159 },
    { lat: 48.047642, lng: 17.6797349 },
    { lat: 48.0472981, lng: 17.6803152 },
    { lat: 48.0470384, lng: 17.6812693 },
    { lat: 48.0468603, lng: 17.6827635 },
    { lat: 48.0468272, lng: 17.6835662 },
    { lat: 48.046859, lng: 17.6845575 },
    { lat: 48.0468065, lng: 17.6874611 },
    { lat: 48.0470271, lng: 17.6896437 },
    { lat: 48.0473256, lng: 17.6910149 },
    { lat: 48.0475503, lng: 17.6917372 },
    { lat: 48.0479202, lng: 17.6926806 },
    { lat: 48.0484819, lng: 17.6934466 },
    { lat: 48.0489267, lng: 17.6938047 },
    { lat: 48.049376, lng: 17.6940377 },
    { lat: 48.0498421, lng: 17.6941247 },
    { lat: 48.0499335, lng: 17.6942133 },
    { lat: 48.0502171, lng: 17.6942344 },
    { lat: 48.050632, lng: 17.6941341 },
    { lat: 48.0506483, lng: 17.6940351 },
    { lat: 48.0518096, lng: 17.6936112 },
    { lat: 48.0521332, lng: 17.6932442 },
    { lat: 48.0528102, lng: 17.6918362 },
    { lat: 48.0531364, lng: 17.6907196 },
    { lat: 48.0532374, lng: 17.690149 },
    { lat: 48.0532986, lng: 17.6893986 },
    { lat: 48.0531903, lng: 17.6877834 },
    { lat: 48.052777, lng: 17.6872257 },
    { lat: 48.0525372, lng: 17.6867302 },
    { lat: 48.0516812, lng: 17.6854538 },
    { lat: 48.050351, lng: 17.6831685 },
    { lat: 48.0498029, lng: 17.6824057 },
    { lat: 48.0496323, lng: 17.681831 },
    { lat: 48.0494529, lng: 17.6809189 },
    { lat: 48.0494014, lng: 17.6792902 },
    { lat: 48.0495349, lng: 17.6787909 },
    { lat: 48.0497797, lng: 17.6784482 },
    { lat: 48.0503057, lng: 17.678177 },
    { lat: 48.0507775, lng: 17.6780644 },
    { lat: 48.0510626, lng: 17.6781477 },
    { lat: 48.0514702, lng: 17.6783793 },
    { lat: 48.0515749, lng: 17.6782115 },
    { lat: 48.051845, lng: 17.6791699 },
    { lat: 48.0519214, lng: 17.6798082 },
    { lat: 48.0518792, lng: 17.6804137 },
    { lat: 48.0516501, lng: 17.6810788 },
    { lat: 48.0516603, lng: 17.6816173 },
    { lat: 48.0517339, lng: 17.6819641 },
    { lat: 48.0519028, lng: 17.6823135 },
    { lat: 48.0525832, lng: 17.6831521 },
    { lat: 48.0528714, lng: 17.6837121 },
    { lat: 48.0530492, lng: 17.6842791 },
    { lat: 48.0538601, lng: 17.6862574 },
    { lat: 48.0541108, lng: 17.6867083 },
    { lat: 48.0542974, lng: 17.6872715 },
    { lat: 48.0547727, lng: 17.6881527 },
    { lat: 48.0550885, lng: 17.6885589 },
    { lat: 48.0552002, lng: 17.6885736 },
    { lat: 48.0556308, lng: 17.6883476 },
    { lat: 48.0560874, lng: 17.6878543 },
    { lat: 48.0566353, lng: 17.6864733 },
    { lat: 48.0567015, lng: 17.6859262 },
    { lat: 48.0566033, lng: 17.6852796 },
    { lat: 48.0564793, lng: 17.6848339 },
    { lat: 48.0557619, lng: 17.6834781 },
    { lat: 48.0554758, lng: 17.6826273 },
    { lat: 48.0554647, lng: 17.6819837 },
    { lat: 48.055545, lng: 17.6808181 },
    { lat: 48.0555977, lng: 17.68058 },
    { lat: 48.0558053, lng: 17.6803445 },
    { lat: 48.0563541, lng: 17.6800228 },
    { lat: 48.0568639, lng: 17.6791859 },
    { lat: 48.0576037, lng: 17.6787793 },
    { lat: 48.0581972, lng: 17.6787545 },
    { lat: 48.0589677, lng: 17.6789209 },
    { lat: 48.0603707, lng: 17.6790228 },
    { lat: 48.0604103, lng: 17.6793473 },
    { lat: 48.0605082, lng: 17.6795189 },
    { lat: 48.0607972, lng: 17.6798254 },
    { lat: 48.060885, lng: 17.6797856 },
    { lat: 48.0614796, lng: 17.6805336 },
    { lat: 48.0619937, lng: 17.6813988 },
    { lat: 48.0621052, lng: 17.6816638 },
    { lat: 48.0620475, lng: 17.6817358 },
    { lat: 48.062057, lng: 17.682158 },
    { lat: 48.0621592, lng: 17.6825422 },
    { lat: 48.0622862, lng: 17.6826582 },
    { lat: 48.0623211, lng: 17.6829802 },
    { lat: 48.0624314, lng: 17.6830297 },
    { lat: 48.0626822, lng: 17.6834806 },
    { lat: 48.0632195, lng: 17.6846476 },
    { lat: 48.063301, lng: 17.6850986 },
    { lat: 48.0632694, lng: 17.6856042 },
    { lat: 48.0633245, lng: 17.686534 },
    { lat: 48.0634233, lng: 17.6870641 },
    { lat: 48.0636036, lng: 17.6874709 },
    { lat: 48.0636924, lng: 17.6875545 },
    { lat: 48.0642567, lng: 17.6876762 },
    { lat: 48.0648848, lng: 17.687558 },
    { lat: 48.0661869, lng: 17.6879016 },
    { lat: 48.066673, lng: 17.68788 },
    { lat: 48.066663, lng: 17.687568 },
    { lat: 48.066611, lng: 17.687157 },
    { lat: 48.066554, lng: 17.686978 },
    { lat: 48.066544, lng: 17.686843 },
    { lat: 48.06642, lng: 17.68653 },
    { lat: 48.066127, lng: 17.685918 },
    { lat: 48.06609, lng: 17.685818 },
    { lat: 48.065649, lng: 17.685162 },
    { lat: 48.065509, lng: 17.684872 },
    { lat: 48.065426, lng: 17.684666 },
    { lat: 48.065347, lng: 17.684254 },
    { lat: 48.065282, lng: 17.683622 },
    { lat: 48.065242, lng: 17.683309 },
    { lat: 48.065191, lng: 17.683011 },
    { lat: 48.065141, lng: 17.682605 },
    { lat: 48.065084, lng: 17.681972 },
    { lat: 48.065072, lng: 17.681707 },
    { lat: 48.065094, lng: 17.681322 },
    { lat: 48.065123, lng: 17.680875 },
    { lat: 48.065305, lng: 17.680267 },
    { lat: 48.065381, lng: 17.680015 },
    { lat: 48.065449, lng: 17.679847 },
    { lat: 48.065602, lng: 17.6795 },
    { lat: 48.065676, lng: 17.679359 },
    { lat: 48.065724, lng: 17.67929 },
    { lat: 48.065863, lng: 17.679132 },
    { lat: 48.065917, lng: 17.679077 },
    { lat: 48.066334, lng: 17.678793 },
    { lat: 48.066484, lng: 17.678706 },
    { lat: 48.066634, lng: 17.678616 },
    { lat: 48.066713, lng: 17.678605 },
    { lat: 48.067012, lng: 17.678588 },
    { lat: 48.067341, lng: 17.678578 },
    { lat: 48.067649, lng: 17.678596 },
    { lat: 48.068098, lng: 17.678606 },
    { lat: 48.068113, lng: 17.678607 },
    { lat: 48.068293, lng: 17.6786 },
    { lat: 48.068391, lng: 17.67863 },
    { lat: 48.068433, lng: 17.678649 },
    { lat: 48.068461, lng: 17.678672 },
    { lat: 48.068544, lng: 17.678742 },
    { lat: 48.068607, lng: 17.678778 },
    { lat: 48.068824, lng: 17.678894 },
    { lat: 48.069148, lng: 17.679089 },
    { lat: 48.069368, lng: 17.679223 },
    { lat: 48.069589, lng: 17.679367 },
    { lat: 48.069761, lng: 17.679498 },
    { lat: 48.069923, lng: 17.679619 },
    { lat: 48.069968, lng: 17.679647 },
    { lat: 48.07009, lng: 17.679742 },
    { lat: 48.070243, lng: 17.679901 },
    { lat: 48.0703, lng: 17.679931 },
    { lat: 48.070331, lng: 17.679942 },
    { lat: 48.070449, lng: 17.680002 },
    { lat: 48.070562, lng: 17.680046 },
    { lat: 48.070713, lng: 17.68013 },
    { lat: 48.070975, lng: 17.680311 },
    { lat: 48.071103, lng: 17.680391 },
    { lat: 48.071173, lng: 17.680445 },
    { lat: 48.071358, lng: 17.680548 },
    { lat: 48.071448, lng: 17.680616 },
    { lat: 48.071573, lng: 17.680686 },
    { lat: 48.071701, lng: 17.680772 },
    { lat: 48.0718, lng: 17.680851 },
    { lat: 48.071965, lng: 17.680948 },
    { lat: 48.072133, lng: 17.681023 },
    { lat: 48.072207, lng: 17.681033 },
    { lat: 48.072284, lng: 17.681058 },
    { lat: 48.072311, lng: 17.681084 },
    { lat: 48.072346, lng: 17.681106 },
    { lat: 48.072413, lng: 17.681107 },
    { lat: 48.072663, lng: 17.681181 },
    { lat: 48.072942, lng: 17.681243 },
    { lat: 48.07326, lng: 17.681281 },
    { lat: 48.073436, lng: 17.681268 },
    { lat: 48.073554, lng: 17.681263 },
    { lat: 48.073807, lng: 17.681236 },
    { lat: 48.073918, lng: 17.681184 },
    { lat: 48.074071, lng: 17.681131 },
    { lat: 48.074252, lng: 17.68107 },
    { lat: 48.074373, lng: 17.681008 },
    { lat: 48.074462, lng: 17.680942 },
    { lat: 48.074638, lng: 17.680803 },
    { lat: 48.074758, lng: 17.680712 },
    { lat: 48.074808, lng: 17.680646 },
    { lat: 48.074942, lng: 17.680429 },
    { lat: 48.07504, lng: 17.680321 },
    { lat: 48.075096, lng: 17.680233 },
    { lat: 48.07518, lng: 17.680087 },
    { lat: 48.075281, lng: 17.679891 },
    { lat: 48.07536, lng: 17.679716 },
    { lat: 48.075443, lng: 17.67952 },
    { lat: 48.075534, lng: 17.679227 },
    { lat: 48.075635, lng: 17.678845 },
    { lat: 48.075772, lng: 17.678272 },
    { lat: 48.075794, lng: 17.67814 },
    { lat: 48.075841, lng: 17.677442 },
    { lat: 48.075844, lng: 17.677193 },
    { lat: 48.075827, lng: 17.676995 },
    { lat: 48.075816, lng: 17.676568 },
    { lat: 48.075801, lng: 17.67632 },
    { lat: 48.075731, lng: 17.675669 },
    { lat: 48.075628, lng: 17.67491 },
    { lat: 48.07558, lng: 17.674514 },
    { lat: 48.075535, lng: 17.674179 },
    { lat: 48.075528, lng: 17.674059 },
    { lat: 48.07551, lng: 17.673834 },
    { lat: 48.075429, lng: 17.673081 },
    { lat: 48.075421, lng: 17.672207 },
    { lat: 48.075437, lng: 17.671568 },
    { lat: 48.075489, lng: 17.670808 },
    { lat: 48.075525, lng: 17.670556 },
    { lat: 48.075582, lng: 17.670287 },
    { lat: 48.075711, lng: 17.669919 },
    { lat: 48.0738664, lng: 17.6675265 },
    { lat: 48.0701205, lng: 17.6632336 },
    { lat: 48.0682205, lng: 17.6612316 },
    { lat: 48.0667497, lng: 17.6598233 },
    { lat: 48.0612828, lng: 17.6555345 },
    { lat: 48.0606315, lng: 17.6549278 },
    { lat: 48.0576683, lng: 17.6517385 },
    { lat: 48.0550173, lng: 17.6492006 },
    { lat: 48.0521463, lng: 17.6467225 },
    { lat: 48.0505332, lng: 17.6454049 },
    { lat: 48.0494624, lng: 17.6446503 },
    { lat: 48.0492184, lng: 17.6445794 },
    { lat: 48.0490987, lng: 17.6440581 },
    { lat: 48.0486242, lng: 17.6430915 },
    { lat: 48.0466242, lng: 17.6407273 },
    { lat: 48.0434132, lng: 17.6373423 },
    { lat: 48.0361582, lng: 17.6303103 },
    { lat: 48.0332811, lng: 17.6262118 },
    { lat: 48.0324783, lng: 17.6251784 },
    { lat: 48.0313381, lng: 17.6243805 },
    { lat: 48.0286359, lng: 17.6222607 },
    { lat: 48.0244699, lng: 17.6188532 },
    { lat: 48.0228357, lng: 17.6160919 },
    { lat: 48.0220908, lng: 17.6146045 },
    { lat: 48.0211708, lng: 17.613685 },
    { lat: 48.0209876, lng: 17.613519 },
    { lat: 48.0190915, lng: 17.6123422 },
    { lat: 48.0181547, lng: 17.6114763 },
    { lat: 48.0164082, lng: 17.6105946 },
    { lat: 48.0149812, lng: 17.6091247 },
    { lat: 48.0144573, lng: 17.6087191 },
    { lat: 48.0139449, lng: 17.6084902 },
    { lat: 48.0137659, lng: 17.6083766 },
    { lat: 48.0137777, lng: 17.6083319 },
    { lat: 48.0140527, lng: 17.6072372 },
    { lat: 48.0115204, lng: 17.6056644 },
    { lat: 48.0108143, lng: 17.6053215 },
    { lat: 48.0110421, lng: 17.6037917 },
    { lat: 48.011053, lng: 17.6033592 },
    { lat: 48.0109648, lng: 17.6028392 },
    { lat: 48.0110169, lng: 17.6019213 },
    { lat: 48.0111665, lng: 17.6011487 },
    { lat: 48.0116249, lng: 17.5999043 },
    { lat: 48.0118572, lng: 17.5991041 },
    { lat: 48.0119853, lng: 17.5982235 },
    { lat: 48.0127437, lng: 17.5946528 },
    { lat: 48.0127217, lng: 17.5946418 },
    { lat: 48.0105593, lng: 17.5935103 },
    { lat: 48.0106004, lng: 17.5932312 },
    { lat: 48.0085348, lng: 17.5920406 },
    { lat: 48.0085754, lng: 17.5914386 },
    { lat: 48.008097, lng: 17.5911089 },
    { lat: 48.0073571, lng: 17.5915806 },
    { lat: 48.0065164, lng: 17.5906858 },
    { lat: 48.0051439, lng: 17.5890853 },
    { lat: 48.0051215, lng: 17.5889263 },
    { lat: 48.0036802, lng: 17.5870083 },
    { lat: 48.00334, lng: 17.5866863 },
    { lat: 48.002653, lng: 17.5847484 },
    { lat: 48.0018394, lng: 17.5840643 },
    { lat: 48.0024262, lng: 17.5826244 },
    { lat: 48.000635, lng: 17.5814203 },
    { lat: 48.0009938, lng: 17.5799281 },
    { lat: 47.9999683, lng: 17.5794652 },
    { lat: 47.9996772, lng: 17.5794165 },
    { lat: 47.9986127, lng: 17.5794764 },
    { lat: 47.9986026, lng: 17.57953 },
    { lat: 47.9982063, lng: 17.5813511 },
    { lat: 47.9977536, lng: 17.5827899 },
    { lat: 47.9969126, lng: 17.5834456 },
    { lat: 47.9973231, lng: 17.5841126 },
    { lat: 47.9982982, lng: 17.5851465 },
    { lat: 47.9990576, lng: 17.5865909 },
    { lat: 47.9990862, lng: 17.5867536 },
    { lat: 47.999063, lng: 17.5867456 },
    { lat: 47.9990193, lng: 17.5867317 },
    { lat: 47.9989466, lng: 17.5870812 },
    { lat: 47.9987627, lng: 17.5872948 },
    { lat: 47.9940643, lng: 17.5898169 },
    { lat: 47.9935793, lng: 17.5891347 },
    { lat: 47.9938494, lng: 17.588605 },
    { lat: 47.9938281, lng: 17.5876855 },
    { lat: 47.9888095, lng: 17.5888134 },
    { lat: 47.9884721, lng: 17.587227 },
    { lat: 47.987345, lng: 17.5877718 },
    { lat: 47.9877151, lng: 17.589448 },
    { lat: 47.9862064, lng: 17.5903886 },
    { lat: 47.9846404, lng: 17.5915766 },
    { lat: 47.9835239, lng: 17.5927513 },
    { lat: 47.9832777, lng: 17.593035 },
    { lat: 47.9826147, lng: 17.5941832 },
    { lat: 47.9816015, lng: 17.5957401 },
    { lat: 47.9818131, lng: 17.5960633 },
    { lat: 47.9811823, lng: 17.5970449 },
    { lat: 47.9800359, lng: 17.5989401 },
    { lat: 47.9797433, lng: 17.5993286 },
    { lat: 47.9785251, lng: 17.5998633 },
    { lat: 47.9780872, lng: 17.5998829 },
    { lat: 47.976502, lng: 17.5979906 },
    { lat: 47.9761729, lng: 17.5976787 },
    { lat: 47.9751508, lng: 17.5978182 },
    { lat: 47.9746849, lng: 17.5979865 },
    { lat: 47.974624, lng: 17.5980219 },
    { lat: 47.9741797, lng: 17.5983874 },
    { lat: 47.9725384, lng: 17.60001 },
    { lat: 47.9721765, lng: 17.6001267 },
    { lat: 47.9698556, lng: 17.5990031 },
    { lat: 47.9678749, lng: 17.598433 },
    { lat: 47.9662885, lng: 17.5982568 },
    { lat: 47.9654034, lng: 17.598366 },
    { lat: 47.9641558, lng: 17.5988321 },
    { lat: 47.9634984, lng: 17.5990057 },
    { lat: 47.9629606, lng: 17.5990548 },
    { lat: 47.9624839, lng: 17.5989784 },
    { lat: 47.9610716, lng: 17.5994452 },
    { lat: 47.9607261, lng: 17.5987207 },
    { lat: 47.9606044, lng: 17.5981031 },
    { lat: 47.959736, lng: 17.5975895 },
    { lat: 47.9580956, lng: 17.5994755 },
    { lat: 47.9583576, lng: 17.6006789 },
    { lat: 47.9580499, lng: 17.6017513 },
    { lat: 47.9580058, lng: 17.6035967 },
    { lat: 47.9578766, lng: 17.6060534 },
    { lat: 47.9579511, lng: 17.6075784 },
    { lat: 47.9575298, lng: 17.6083565 },
    { lat: 47.9570256, lng: 17.6098236 },
    { lat: 47.9566863, lng: 17.6118543 },
    { lat: 47.9567087, lng: 17.6129548 },
    { lat: 47.9568442, lng: 17.6135039 },
    { lat: 47.9564073, lng: 17.6152289 },
    { lat: 47.9564039, lng: 17.6152621 },
    { lat: 47.9572626, lng: 17.6147924 },
    { lat: 47.9593093, lng: 17.6138818 },
    { lat: 47.9598801, lng: 17.6136278 },
    { lat: 47.9603559, lng: 17.6134161 },
    { lat: 47.9607932, lng: 17.6132215 },
    { lat: 47.9611742, lng: 17.6126401 },
    { lat: 47.9613952, lng: 17.6123028 },
    { lat: 47.9615435, lng: 17.6122104 },
    { lat: 47.9619826, lng: 17.6119367 },
    { lat: 47.9621446, lng: 17.6118357 },
    { lat: 47.9626115, lng: 17.6117623 },
    { lat: 47.9630885, lng: 17.6116872 },
    { lat: 47.9632235, lng: 17.611666 },
    { lat: 47.9634177, lng: 17.6117106 },
    { lat: 47.9637421, lng: 17.6117853 },
    { lat: 47.9639586, lng: 17.611835 },
    { lat: 47.9642767, lng: 17.6119082 },
    { lat: 47.9643878, lng: 17.6119689 },
    { lat: 47.9649158, lng: 17.6122572 },
    { lat: 47.9652874, lng: 17.6124601 },
    { lat: 47.9653608, lng: 17.6125302 },
    { lat: 47.965736, lng: 17.6128888 },
    { lat: 47.9660534, lng: 17.6131921 },
    { lat: 47.9662311, lng: 17.6133619 },
    { lat: 47.9663063, lng: 17.6134963 },
    { lat: 47.9664936, lng: 17.6138309 },
    { lat: 47.9667585, lng: 17.614304 },
    { lat: 47.9667992, lng: 17.6143979 },
    { lat: 47.9669178, lng: 17.6146712 },
    { lat: 47.967174, lng: 17.6152619 },
    { lat: 47.9671991, lng: 17.6153197 },
    { lat: 47.9674366, lng: 17.6156679 },
    { lat: 47.9676577, lng: 17.6159922 },
    { lat: 47.967877, lng: 17.6163137 },
    { lat: 47.9680471, lng: 17.6165631 },
    { lat: 47.9681258, lng: 17.6165907 },
    { lat: 47.9684411, lng: 17.6167015 },
    { lat: 47.9688075, lng: 17.6168301 },
    { lat: 47.9689779, lng: 17.61689 },
    { lat: 47.9693432, lng: 17.6169483 },
    { lat: 47.9701256, lng: 17.6170732 },
    { lat: 47.9702976, lng: 17.6170708 },
    { lat: 47.9709079, lng: 17.6170623 },
    { lat: 47.9714828, lng: 17.6170543 },
    { lat: 47.9715892, lng: 17.6170476 },
    { lat: 47.9741827, lng: 17.6168831 },
    { lat: 47.974664, lng: 17.6168526 },
    { lat: 47.9749434, lng: 17.6168349 },
    { lat: 47.9750757, lng: 17.6168826 },
    { lat: 47.9753509, lng: 17.6169819 },
    { lat: 47.9756541, lng: 17.6170913 },
    { lat: 47.9759623, lng: 17.6172025 },
    { lat: 47.9761152, lng: 17.6172576 },
    { lat: 47.9763865, lng: 17.6173962 },
    { lat: 47.9766403, lng: 17.6175258 },
    { lat: 47.9768641, lng: 17.6177299 },
    { lat: 47.9770482, lng: 17.6178977 },
    { lat: 47.977219, lng: 17.6180534 },
    { lat: 47.9774217, lng: 17.6182382 },
    { lat: 47.9773977, lng: 17.6183751 },
    { lat: 47.9775202, lng: 17.618533 },
    { lat: 47.9777724, lng: 17.6188581 },
    { lat: 47.977934, lng: 17.6190664 },
    { lat: 47.9779764, lng: 17.6191385 },
    { lat: 47.978029, lng: 17.6192278 },
    { lat: 47.9779164, lng: 17.619411 },
    { lat: 47.9783267, lng: 17.6203432 },
    { lat: 47.9786806, lng: 17.6214897 },
    { lat: 47.979077, lng: 17.6233401 },
    { lat: 47.9793477, lng: 17.6243497 },
    { lat: 47.9783534, lng: 17.6294667 },
    { lat: 47.977576, lng: 17.6334658 },
    { lat: 47.977609, lng: 17.6336479 },
    { lat: 47.9788977, lng: 17.6338459 },
    { lat: 47.9792635, lng: 17.6340637 },
    { lat: 47.9825898, lng: 17.6358397 },
    { lat: 47.9855958, lng: 17.6373757 },
    { lat: 47.9869619, lng: 17.6386228 },
    { lat: 47.9871543, lng: 17.6387537 },
  ],
  DunajskýKlátov: [
    { lat: 48.0287706, lng: 17.7097771 },
    { lat: 48.0288578, lng: 17.7096588 },
    { lat: 48.0295871, lng: 17.7086112 },
    { lat: 48.0295934, lng: 17.7076661 },
    { lat: 48.0294924, lng: 17.7071338 },
    { lat: 48.0294271, lng: 17.706345 },
    { lat: 48.0296058, lng: 17.7053794 },
    { lat: 48.0296513, lng: 17.7048782 },
    { lat: 48.0297755, lng: 17.7044097 },
    { lat: 48.0300159, lng: 17.7038662 },
    { lat: 48.0300582, lng: 17.7034808 },
    { lat: 48.030164, lng: 17.7032597 },
    { lat: 48.0301813, lng: 17.7027095 },
    { lat: 48.0305763, lng: 17.702598 },
    { lat: 48.0308858, lng: 17.7024176 },
    { lat: 48.0317169, lng: 17.7017363 },
    { lat: 48.0312243, lng: 17.7005591 },
    { lat: 48.0313528, lng: 17.699926 },
    { lat: 48.0314798, lng: 17.6988924 },
    { lat: 48.0319737, lng: 17.6966842 },
    { lat: 48.0320949, lng: 17.6958482 },
    { lat: 48.0323157, lng: 17.6948847 },
    { lat: 48.0326781, lng: 17.6941096 },
    { lat: 48.0332167, lng: 17.693483 },
    { lat: 48.0335481, lng: 17.6932206 },
    { lat: 48.0341576, lng: 17.6929309 },
    { lat: 48.0345538, lng: 17.6928731 },
    { lat: 48.0350219, lng: 17.6930113 },
    { lat: 48.0354203, lng: 17.693439 },
    { lat: 48.035547, lng: 17.6938027 },
    { lat: 48.0358358, lng: 17.695401 },
    { lat: 48.0364846, lng: 17.6953408 },
    { lat: 48.0371764, lng: 17.6957477 },
    { lat: 48.0379679, lng: 17.6965221 },
    { lat: 48.0385831, lng: 17.6972579 },
    { lat: 48.038906, lng: 17.6974383 },
    { lat: 48.0404802, lng: 17.6974963 },
    { lat: 48.0413985, lng: 17.6971435 },
    { lat: 48.0422366, lng: 17.6963901 },
    { lat: 48.0426034, lng: 17.6961724 },
    { lat: 48.0433969, lng: 17.6958807 },
    { lat: 48.043858, lng: 17.6956018 },
    { lat: 48.0449822, lng: 17.693703 },
    { lat: 48.0452686, lng: 17.6929778 },
    { lat: 48.0454586, lng: 17.6916552 },
    { lat: 48.0454553, lng: 17.6910075 },
    { lat: 48.0453255, lng: 17.6904315 },
    { lat: 48.0449565, lng: 17.6895752 },
    { lat: 48.0445675, lng: 17.689096 },
    { lat: 48.0428214, lng: 17.6889793 },
    { lat: 48.0419319, lng: 17.6882836 },
    { lat: 48.0414335, lng: 17.6878071 },
    { lat: 48.0411682, lng: 17.6874871 },
    { lat: 48.0406413, lng: 17.6860514 },
    { lat: 48.0399895, lng: 17.6851154 },
    { lat: 48.039648, lng: 17.6842783 },
    { lat: 48.0396371, lng: 17.6838716 },
    { lat: 48.0397067, lng: 17.6835595 },
    { lat: 48.0397854, lng: 17.6834106 },
    { lat: 48.0404714, lng: 17.6827911 },
    { lat: 48.0407601, lng: 17.6821944 },
    { lat: 48.0412299, lng: 17.6799345 },
    { lat: 48.041459, lng: 17.6782284 },
    { lat: 48.0416201, lng: 17.6775912 },
    { lat: 48.0416455, lng: 17.6770817 },
    { lat: 48.041544, lng: 17.675359 },
    { lat: 48.0416203, lng: 17.6731277 },
    { lat: 48.0417041, lng: 17.6720737 },
    { lat: 48.041976, lng: 17.6707097 },
    { lat: 48.0423489, lng: 17.6698534 },
    { lat: 48.0422126, lng: 17.669714 },
    { lat: 48.0418783, lng: 17.6693721 },
    { lat: 48.0413051, lng: 17.6705983 },
    { lat: 48.0408055, lng: 17.6711262 },
    { lat: 48.0404775, lng: 17.6713697 },
    { lat: 48.0402647, lng: 17.6717026 },
    { lat: 48.040065, lng: 17.6722771 },
    { lat: 48.0399219, lng: 17.6728383 },
    { lat: 48.0400111, lng: 17.6737897 },
    { lat: 48.0399839, lng: 17.6754351 },
    { lat: 48.0398083, lng: 17.6765036 },
    { lat: 48.0397045, lng: 17.6768463 },
    { lat: 48.0392199, lng: 17.677831 },
    { lat: 48.0383409, lng: 17.6800644 },
    { lat: 48.0381389, lng: 17.6807852 },
    { lat: 48.0380201, lng: 17.6815173 },
    { lat: 48.0380475, lng: 17.6826526 },
    { lat: 48.0379224, lng: 17.6831439 },
    { lat: 48.0375051, lng: 17.6841569 },
    { lat: 48.0372018, lng: 17.6845224 },
    { lat: 48.0349119, lng: 17.6823646 },
    { lat: 48.0308936, lng: 17.6779895 },
    { lat: 48.0286085, lng: 17.675658 },
    { lat: 48.0270943, lng: 17.6733778 },
    { lat: 48.0257153, lng: 17.6712757 },
    { lat: 48.0256978, lng: 17.6713025 },
    { lat: 48.022339, lng: 17.6763121 },
    { lat: 48.0222987, lng: 17.676262 },
    { lat: 48.0221165, lng: 17.6765248 },
    { lat: 48.0220185, lng: 17.6765809 },
    { lat: 48.0216428, lng: 17.6766595 },
    { lat: 48.0213225, lng: 17.6772217 },
    { lat: 48.0210525, lng: 17.6774495 },
    { lat: 48.0207865, lng: 17.6778891 },
    { lat: 48.0205111, lng: 17.6784831 },
    { lat: 48.0202493, lng: 17.6787747 },
    { lat: 48.0199187, lng: 17.67892 },
    { lat: 48.019222, lng: 17.6808587 },
    { lat: 48.0186508, lng: 17.6820272 },
    { lat: 48.018934, lng: 17.6832794 },
    { lat: 48.0188448, lng: 17.6834939 },
    { lat: 48.0188969, lng: 17.683676 },
    { lat: 48.0191969, lng: 17.6864191 },
    { lat: 48.0192198, lng: 17.686911 },
    { lat: 48.0191558, lng: 17.6880658 },
    { lat: 48.0190486, lng: 17.6887127 },
    { lat: 48.0186767, lng: 17.6902155 },
    { lat: 48.0175919, lng: 17.6921439 },
    { lat: 48.0175166, lng: 17.6926703 },
    { lat: 48.0175409, lng: 17.693039 },
    { lat: 48.0177638, lng: 17.69472 },
    { lat: 48.0172792, lng: 17.6960642 },
    { lat: 48.0171483, lng: 17.6968928 },
    { lat: 48.0171568, lng: 17.6969032 },
    { lat: 48.0189365, lng: 17.6990827 },
    { lat: 48.0212131, lng: 17.7020597 },
    { lat: 48.0219088, lng: 17.702223 },
    { lat: 48.0220129, lng: 17.7025516 },
    { lat: 48.0224983, lng: 17.7034662 },
    { lat: 48.0230419, lng: 17.7043067 },
    { lat: 48.023371, lng: 17.7046725 },
    { lat: 48.0243603, lng: 17.7056858 },
    { lat: 48.0248791, lng: 17.7058735 },
    { lat: 48.0254756, lng: 17.7058821 },
    { lat: 48.0258851, lng: 17.7064176 },
    { lat: 48.0272367, lng: 17.7076433 },
    { lat: 48.027604, lng: 17.7079163 },
    { lat: 48.0284977, lng: 17.7092821 },
    { lat: 48.0287706, lng: 17.7097771 },
  ],
  MaléDvorníky: [
    { lat: 47.9988529, lng: 17.6443465 },
    { lat: 47.9989707, lng: 17.6444248 },
    { lat: 48.0002421, lng: 17.6450505 },
    { lat: 48.0008932, lng: 17.645203 },
    { lat: 48.0009042, lng: 17.6455381 },
    { lat: 48.0021209, lng: 17.6459056 },
    { lat: 48.0022707, lng: 17.6464152 },
    { lat: 48.0034131, lng: 17.6467671 },
    { lat: 48.0039492, lng: 17.6472119 },
    { lat: 48.0048503, lng: 17.6480881 },
    { lat: 48.0061051, lng: 17.6495575 },
    { lat: 48.0059958, lng: 17.6498306 },
    { lat: 48.0086432, lng: 17.653466 },
    { lat: 48.0090868, lng: 17.6536798 },
    { lat: 48.0105766, lng: 17.6548499 },
    { lat: 48.0138607, lng: 17.6571459 },
    { lat: 48.014204, lng: 17.6563251 },
    { lat: 48.0155224, lng: 17.6576269 },
    { lat: 48.0157393, lng: 17.6571794 },
    { lat: 48.0171296, lng: 17.6584384 },
    { lat: 48.0187338, lng: 17.6601514 },
    { lat: 48.0219062, lng: 17.6654259 },
    { lat: 48.0257153, lng: 17.6712757 },
    { lat: 48.0270943, lng: 17.6733778 },
    { lat: 48.0286085, lng: 17.675658 },
    { lat: 48.0308936, lng: 17.6779895 },
    { lat: 48.0349119, lng: 17.6823646 },
    { lat: 48.0372018, lng: 17.6845224 },
    { lat: 48.0375051, lng: 17.6841569 },
    { lat: 48.0379224, lng: 17.6831439 },
    { lat: 48.0380475, lng: 17.6826526 },
    { lat: 48.0380201, lng: 17.6815173 },
    { lat: 48.0381389, lng: 17.6807852 },
    { lat: 48.0383409, lng: 17.6800644 },
    { lat: 48.0392199, lng: 17.677831 },
    { lat: 48.0397045, lng: 17.6768463 },
    { lat: 48.0398083, lng: 17.6765036 },
    { lat: 48.0399839, lng: 17.6754351 },
    { lat: 48.0400111, lng: 17.6737897 },
    { lat: 48.0399219, lng: 17.6728383 },
    { lat: 48.040065, lng: 17.6722771 },
    { lat: 48.0402647, lng: 17.6717026 },
    { lat: 48.0404775, lng: 17.6713697 },
    { lat: 48.0408055, lng: 17.6711262 },
    { lat: 48.0413051, lng: 17.6705983 },
    { lat: 48.0418783, lng: 17.6693721 },
    { lat: 48.0419988, lng: 17.669171 },
    { lat: 48.0421144, lng: 17.668977 },
    { lat: 48.0405132, lng: 17.6676319 },
    { lat: 48.0393945, lng: 17.6660952 },
    { lat: 48.0366729, lng: 17.6629172 },
    { lat: 48.0356923, lng: 17.660994 },
    { lat: 48.0328051, lng: 17.6558586 },
    { lat: 48.0300953, lng: 17.6508865 },
    { lat: 48.0291872, lng: 17.649452 },
    { lat: 48.0276317, lng: 17.6472986 },
    { lat: 48.0266415, lng: 17.6463897 },
    { lat: 48.0240593, lng: 17.6430317 },
    { lat: 48.0229394, lng: 17.6413402 },
    { lat: 48.0221233, lng: 17.6405947 },
    { lat: 48.0183524, lng: 17.6380861 },
    { lat: 48.0172787, lng: 17.6372686 },
    { lat: 48.0170116, lng: 17.6369881 },
    { lat: 48.0154082, lng: 17.6360078 },
    { lat: 48.0146448, lng: 17.6358129 },
    { lat: 48.0126487, lng: 17.6356176 },
    { lat: 48.0126044, lng: 17.6357028 },
    { lat: 48.0115571, lng: 17.6355532 },
    { lat: 48.0100042, lng: 17.6350664 },
    { lat: 48.0090767, lng: 17.6345406 },
    { lat: 48.0085908, lng: 17.6343928 },
    { lat: 48.0080559, lng: 17.6345244 },
    { lat: 48.0074739, lng: 17.6349444 },
    { lat: 48.0074752, lng: 17.6342888 },
    { lat: 48.0062751, lng: 17.6334336 },
    { lat: 48.0055119, lng: 17.6327787 },
    { lat: 48.0048612, lng: 17.6345379 },
    { lat: 48.0031603, lng: 17.6337808 },
    { lat: 48.0019472, lng: 17.6334384 },
    { lat: 48.001695, lng: 17.6346786 },
    { lat: 48.0012046, lng: 17.6362978 },
    { lat: 48.000903, lng: 17.6375953 },
    { lat: 48.0006581, lng: 17.6387765 },
    { lat: 48.0005827, lng: 17.6397426 },
    { lat: 48.0003771, lng: 17.6406849 },
    { lat: 47.9999763, lng: 17.641777 },
    { lat: 47.9988529, lng: 17.6443465 },
  ],
  HornéMýto: [
    { lat: 48.031824, lng: 17.768269 },
    { lat: 48.0321, lng: 17.7682 },
    { lat: 48.032238, lng: 17.768131 },
    { lat: 48.03252, lng: 17.767985 },
    { lat: 48.032683, lng: 17.767888 },
    { lat: 48.032921, lng: 17.767705 },
    { lat: 48.033304, lng: 17.767319 },
    { lat: 48.033502, lng: 17.767002 },
    { lat: 48.033641, lng: 17.766619 },
    { lat: 48.033743, lng: 17.766248 },
    { lat: 48.033954, lng: 17.766065 },
    { lat: 48.033982, lng: 17.765848 },
    { lat: 48.034034, lng: 17.765486 },
    { lat: 48.034027, lng: 17.764972 },
    { lat: 48.034008, lng: 17.764805 },
    { lat: 48.033969, lng: 17.764571 },
    { lat: 48.033952, lng: 17.764366 },
    { lat: 48.033922, lng: 17.764151 },
    { lat: 48.033862, lng: 17.76391 },
    { lat: 48.033758, lng: 17.763573 },
    { lat: 48.033707, lng: 17.763499 },
    { lat: 48.033647, lng: 17.763338 },
    { lat: 48.033444, lng: 17.762766 },
    { lat: 48.0332108, lng: 17.7622627 },
    { lat: 48.0327212, lng: 17.7608331 },
    { lat: 48.0323801, lng: 17.7601765 },
    { lat: 48.031121, lng: 17.7583923 },
    { lat: 48.0303342, lng: 17.7573975 },
    { lat: 48.0296873, lng: 17.7559999 },
    { lat: 48.0294927, lng: 17.7552484 },
    { lat: 48.0293241, lng: 17.7542281 },
    { lat: 48.0293883, lng: 17.7534267 },
    { lat: 48.0295796, lng: 17.7528168 },
    { lat: 48.0299051, lng: 17.7522805 },
    { lat: 48.0302038, lng: 17.7519266 },
    { lat: 48.0305697, lng: 17.7517314 },
    { lat: 48.0310149, lng: 17.75168 },
    { lat: 48.0314242, lng: 17.7518706 },
    { lat: 48.0319911, lng: 17.752347 },
    { lat: 48.0326686, lng: 17.75311 },
    { lat: 48.0336592, lng: 17.7543877 },
    { lat: 48.0343376, lng: 17.7550395 },
    { lat: 48.0348319, lng: 17.7552537 },
    { lat: 48.0357392, lng: 17.755428 },
    { lat: 48.0363664, lng: 17.7553261 },
    { lat: 48.0368476, lng: 17.7551703 },
    { lat: 48.0370613, lng: 17.7549918 },
    { lat: 48.037553, lng: 17.7544388 },
    { lat: 48.037994, lng: 17.7536521 },
    { lat: 48.0382263, lng: 17.7529592 },
    { lat: 48.0386193, lng: 17.7512013 },
    { lat: 48.0390262, lng: 17.7499007 },
    { lat: 48.0401517, lng: 17.7471826 },
    { lat: 48.0406422, lng: 17.7456863 },
    { lat: 48.0408951, lng: 17.7442563 },
    { lat: 48.0409434, lng: 17.7436714 },
    { lat: 48.0409447, lng: 17.7426753 },
    { lat: 48.0407947, lng: 17.7416278 },
    { lat: 48.0403766, lng: 17.7404807 },
    { lat: 48.0397257, lng: 17.7396558 },
    { lat: 48.0391844, lng: 17.73916 },
    { lat: 48.0384746, lng: 17.7389124 },
    { lat: 48.0375739, lng: 17.7388824 },
    { lat: 48.0371357, lng: 17.738897 },
    { lat: 48.0362505, lng: 17.7391326 },
    { lat: 48.0355448, lng: 17.7392407 },
    { lat: 48.035006, lng: 17.7392152 },
    { lat: 48.034297, lng: 17.7390381 },
    { lat: 48.0338119, lng: 17.7388083 },
    { lat: 48.0335995, lng: 17.738624 },
    { lat: 48.0329155, lng: 17.737649 },
    { lat: 48.0326816, lng: 17.7369418 },
    { lat: 48.0326292, lng: 17.736609 },
    { lat: 48.0322933, lng: 17.7359124 },
    { lat: 48.0320468, lng: 17.7351092 },
    { lat: 48.0319208, lng: 17.7336512 },
    { lat: 48.0320111, lng: 17.7325286 },
    { lat: 48.0322609, lng: 17.7315588 },
    { lat: 48.0324638, lng: 17.7311166 },
    { lat: 48.0328561, lng: 17.7307118 },
    { lat: 48.0331644, lng: 17.7306027 },
    { lat: 48.0334937, lng: 17.7306699 },
    { lat: 48.0340953, lng: 17.7311014 },
    { lat: 48.0355147, lng: 17.7326611 },
    { lat: 48.0365371, lng: 17.7330864 },
    { lat: 48.0377446, lng: 17.7334381 },
    { lat: 48.0383975, lng: 17.7319064 },
    { lat: 48.0389526, lng: 17.7307787 },
    { lat: 48.0390532, lng: 17.7303464 },
    { lat: 48.0391657, lng: 17.7302323 },
    { lat: 48.0392522, lng: 17.7299907 },
    { lat: 48.0392915, lng: 17.7296426 },
    { lat: 48.0392516, lng: 17.7293307 },
    { lat: 48.0394477, lng: 17.7291443 },
    { lat: 48.0394929, lng: 17.728852 },
    { lat: 48.0396934, lng: 17.7288661 },
    { lat: 48.0400042, lng: 17.7284701 },
    { lat: 48.0407277, lng: 17.7268427 },
    { lat: 48.0409392, lng: 17.7264867 },
    { lat: 48.0409432, lng: 17.7263147 },
    { lat: 48.0411359, lng: 17.7255142 },
    { lat: 48.0403277, lng: 17.7257912 },
    { lat: 48.0400105, lng: 17.7257531 },
    { lat: 48.0392859, lng: 17.7253613 },
    { lat: 48.0388921, lng: 17.7252576 },
    { lat: 48.0384815, lng: 17.7255503 },
    { lat: 48.0381738, lng: 17.7255409 },
    { lat: 48.0379054, lng: 17.7256717 },
    { lat: 48.037634, lng: 17.7255764 },
    { lat: 48.0354102, lng: 17.72551 },
    { lat: 48.0344111, lng: 17.7243376 },
    { lat: 48.0342217, lng: 17.7237981 },
    { lat: 48.0342804, lng: 17.7230407 },
    { lat: 48.0343652, lng: 17.7224458 },
    { lat: 48.034486, lng: 17.7219585 },
    { lat: 48.0346778, lng: 17.7214697 },
    { lat: 48.0339635, lng: 17.7205919 },
    { lat: 48.0333217, lng: 17.7195717 },
    { lat: 48.032974, lng: 17.7185258 },
    { lat: 48.0317757, lng: 17.7171835 },
    { lat: 48.0315266, lng: 17.7167747 },
    { lat: 48.0315242, lng: 17.7160333 },
    { lat: 48.0313699, lng: 17.7152653 },
    { lat: 48.0307501, lng: 17.7139671 },
    { lat: 48.029495, lng: 17.7119904 },
    { lat: 48.0284832, lng: 17.7101832 },
    { lat: 48.0284492, lng: 17.7101273 },
    { lat: 48.0279774, lng: 17.7108525 },
    { lat: 48.0271916, lng: 17.7116638 },
    { lat: 48.0270226, lng: 17.7121723 },
    { lat: 48.0264014, lng: 17.713198 },
    { lat: 48.0258978, lng: 17.7141893 },
    { lat: 48.0257448, lng: 17.7145577 },
    { lat: 48.0256043, lng: 17.7153976 },
    { lat: 48.0251718, lng: 17.7160661 },
    { lat: 48.0249478, lng: 17.7162211 },
    { lat: 48.0245647, lng: 17.716323 },
    { lat: 48.0242567, lng: 17.71629 },
    { lat: 48.023964, lng: 17.7160878 },
    { lat: 48.0233408, lng: 17.7154902 },
    { lat: 48.0228357, lng: 17.7153062 },
    { lat: 48.0222526, lng: 17.7155869 },
    { lat: 48.0219911, lng: 17.7157898 },
    { lat: 48.0214452, lng: 17.7163781 },
    { lat: 48.0211475, lng: 17.7170508 },
    { lat: 48.0210636, lng: 17.7176228 },
    { lat: 48.0212207, lng: 17.7184358 },
    { lat: 48.0215389, lng: 17.7190824 },
    { lat: 48.0216061, lng: 17.7193287 },
    { lat: 48.0221834, lng: 17.7202173 },
    { lat: 48.0223882, lng: 17.7209308 },
    { lat: 48.0225878, lng: 17.7213231 },
    { lat: 48.0228912, lng: 17.7216791 },
    { lat: 48.0238437, lng: 17.7222845 },
    { lat: 48.0245784, lng: 17.7229104 },
    { lat: 48.0247485, lng: 17.7232856 },
    { lat: 48.0248286, lng: 17.723825 },
    { lat: 48.0248321, lng: 17.7245119 },
    { lat: 48.0247503, lng: 17.7250855 },
    { lat: 48.0246616, lng: 17.7254001 },
    { lat: 48.0245132, lng: 17.7256982 },
    { lat: 48.0242165, lng: 17.7260801 },
    { lat: 48.0240263, lng: 17.7261817 },
    { lat: 48.022758, lng: 17.7261229 },
    { lat: 48.0224365, lng: 17.7261803 },
    { lat: 48.0221498, lng: 17.7264144 },
    { lat: 48.0219855, lng: 17.72664 },
    { lat: 48.0212137, lng: 17.7285824 },
    { lat: 48.0211408, lng: 17.7297623 },
    { lat: 48.0212091, lng: 17.7303679 },
    { lat: 48.0213284, lng: 17.7307781 },
    { lat: 48.0214482, lng: 17.7310178 },
    { lat: 48.0216627, lng: 17.7312709 },
    { lat: 48.0220422, lng: 17.7314337 },
    { lat: 48.0227235, lng: 17.7314964 },
    { lat: 48.0235219, lng: 17.7316963 },
    { lat: 48.0241426, lng: 17.7321822 },
    { lat: 48.0246147, lng: 17.7328362 },
    { lat: 48.0249904, lng: 17.7334651 },
    { lat: 48.0259214, lng: 17.7347058 },
    { lat: 48.0261624, lng: 17.7351277 },
    { lat: 48.0262534, lng: 17.7354296 },
    { lat: 48.0262922, lng: 17.7359233 },
    { lat: 48.0261739, lng: 17.7370683 },
    { lat: 48.0253733, lng: 17.7386042 },
    { lat: 48.0251721, lng: 17.7388896 },
    { lat: 48.024902, lng: 17.7390656 },
    { lat: 48.0246659, lng: 17.7391188 },
    { lat: 48.023822, lng: 17.7387131 },
    { lat: 48.0233377, lng: 17.7385884 },
    { lat: 48.0229455, lng: 17.7385772 },
    { lat: 48.0219477, lng: 17.7388375 },
    { lat: 48.0216801, lng: 17.7388189 },
    { lat: 48.0214938, lng: 17.7387287 },
    { lat: 48.0213389, lng: 17.7385978 },
    { lat: 48.0211911, lng: 17.7383112 },
    { lat: 48.0210654, lng: 17.7378314 },
    { lat: 48.0210577, lng: 17.737204 },
    { lat: 48.0211377, lng: 17.7362799 },
    { lat: 48.0210749, lng: 17.735797 },
    { lat: 48.0204082, lng: 17.7351559 },
    { lat: 48.0201776, lng: 17.7350388 },
    { lat: 48.0198007, lng: 17.7349718 },
    { lat: 48.0194158, lng: 17.7350478 },
    { lat: 48.019055, lng: 17.7352181 },
    { lat: 48.0181036, lng: 17.7359104 },
    { lat: 48.0166029, lng: 17.7372098 },
    { lat: 48.0162011, lng: 17.737481 },
    { lat: 48.0148707, lng: 17.7378361 },
    { lat: 48.0137295, lng: 17.7384033 },
    { lat: 48.013323, lng: 17.7384925 },
    { lat: 48.0128802, lng: 17.7384142 },
    { lat: 48.011503, lng: 17.7372953 },
    { lat: 48.0112249, lng: 17.7365514 },
    { lat: 48.0112988, lng: 17.7361583 },
    { lat: 48.0100007, lng: 17.7356786 },
    { lat: 48.0091719, lng: 17.7347724 },
    { lat: 48.0097113, lng: 17.7336988 },
    { lat: 48.0104428, lng: 17.7325241 },
    { lat: 48.0111814, lng: 17.7309182 },
    { lat: 48.0109141, lng: 17.730017 },
    { lat: 48.0107122, lng: 17.7296219 },
    { lat: 48.0099689, lng: 17.7290023 },
    { lat: 48.00781, lng: 17.7277524 },
    { lat: 48.0070455, lng: 17.7271375 },
    { lat: 48.0059806, lng: 17.7260859 },
    { lat: 48.0057068, lng: 17.7258952 },
    { lat: 48.0052456, lng: 17.7252929 },
    { lat: 48.0042426, lng: 17.7241485 },
    { lat: 48.0040914, lng: 17.7242048 },
    { lat: 48.0031888, lng: 17.7229676 },
    { lat: 48.0030471, lng: 17.7232858 },
    { lat: 48.0025748, lng: 17.722664 },
    { lat: 48.0023817, lng: 17.72196 },
    { lat: 48.0020487, lng: 17.7217616 },
    { lat: 48.0001681, lng: 17.722651 },
    { lat: 47.9981631, lng: 17.7231977 },
    { lat: 47.9974802, lng: 17.7234727 },
    { lat: 47.9961268, lng: 17.7243966 },
    { lat: 47.9950036, lng: 17.7253027 },
    { lat: 47.9942239, lng: 17.7256002 },
    { lat: 47.9934263, lng: 17.7261127 },
    { lat: 47.9928295, lng: 17.726358 },
    { lat: 47.9921209, lng: 17.7264698 },
    { lat: 47.9913759, lng: 17.7264748 },
    { lat: 47.9903016, lng: 17.7267896 },
    { lat: 47.9884564, lng: 17.7268035 },
    { lat: 47.9883309, lng: 17.7288231 },
    { lat: 47.9884171, lng: 17.7294596 },
    { lat: 47.9885997, lng: 17.7301067 },
    { lat: 47.9887223, lng: 17.730746 },
    { lat: 47.9887297, lng: 17.7322199 },
    { lat: 47.9887966, lng: 17.7324436 },
    { lat: 47.9888945, lng: 17.7326016 },
    { lat: 47.9893237, lng: 17.7329985 },
    { lat: 47.9895846, lng: 17.7333627 },
    { lat: 47.9899205, lng: 17.7343118 },
    { lat: 47.9905167, lng: 17.7353601 },
    { lat: 47.9907481, lng: 17.7362811 },
    { lat: 47.9914738, lng: 17.7369312 },
    { lat: 47.9916713, lng: 17.7374339 },
    { lat: 47.9919981, lng: 17.7387611 },
    { lat: 47.992364, lng: 17.7396691 },
    { lat: 47.9926833, lng: 17.7400432 },
    { lat: 47.9930019, lng: 17.7404831 },
    { lat: 47.993611, lng: 17.7414508 },
    { lat: 47.993999, lng: 17.7418605 },
    { lat: 47.9941168, lng: 17.7424487 },
    { lat: 47.994247, lng: 17.7427595 },
    { lat: 47.9944205, lng: 17.7429544 },
    { lat: 47.9950348, lng: 17.7433547 },
    { lat: 47.9951684, lng: 17.7435208 },
    { lat: 47.9952672, lng: 17.7439481 },
    { lat: 47.995241, lng: 17.745357 },
    { lat: 47.9954011, lng: 17.7459849 },
    { lat: 47.995419, lng: 17.7462196 },
    { lat: 47.9953499, lng: 17.7467769 },
    { lat: 47.9950395, lng: 17.7478272 },
    { lat: 47.9955498, lng: 17.7482451 },
    { lat: 47.996384, lng: 17.7495083 },
    { lat: 47.9971269, lng: 17.7507622 },
    { lat: 47.9980513, lng: 17.7518074 },
    { lat: 47.9988215, lng: 17.7523378 },
    { lat: 47.9994146, lng: 17.752504 },
    { lat: 47.9994018, lng: 17.7528427 },
    { lat: 47.9995075, lng: 17.7534185 },
    { lat: 47.9996945, lng: 17.7555517 },
    { lat: 47.9997837, lng: 17.7559652 },
    { lat: 47.9999145, lng: 17.7563448 },
    { lat: 48.0001643, lng: 17.7567132 },
    { lat: 48.0007569, lng: 17.757093 },
    { lat: 48.0008327, lng: 17.7573494 },
    { lat: 48.0008323, lng: 17.7577767 },
    { lat: 48.0008897, lng: 17.7579908 },
    { lat: 48.0011939, lng: 17.7585988 },
    { lat: 48.0016869, lng: 17.759302 },
    { lat: 48.0020897, lng: 17.7595654 },
    { lat: 48.0029541, lng: 17.7599218 },
    { lat: 48.0038551, lng: 17.7605056 },
    { lat: 48.0043135, lng: 17.7605811 },
    { lat: 48.0052909, lng: 17.7603005 },
    { lat: 48.0075973, lng: 17.759392 },
    { lat: 48.0084379, lng: 17.7592399 },
    { lat: 48.0088826, lng: 17.7592913 },
    { lat: 48.0090467, lng: 17.7593701 },
    { lat: 48.0097494, lng: 17.7599757 },
    { lat: 48.0102842, lng: 17.7605872 },
    { lat: 48.010584, lng: 17.761032 },
    { lat: 48.0107792, lng: 17.7615578 },
    { lat: 48.0111686, lng: 17.7622117 },
    { lat: 48.0120953, lng: 17.7634936 },
    { lat: 48.0125334, lng: 17.7642768 },
    { lat: 48.0128295, lng: 17.7646827 },
    { lat: 48.0140349, lng: 17.7660567 },
    { lat: 48.014084, lng: 17.7659942 },
    { lat: 48.0140531, lng: 17.7653684 },
    { lat: 48.0139529, lng: 17.7640639 },
    { lat: 48.0138485, lng: 17.7634778 },
    { lat: 48.0147648, lng: 17.7634849 },
    { lat: 48.0152878, lng: 17.7634194 },
    { lat: 48.0166768, lng: 17.7635505 },
    { lat: 48.0176186, lng: 17.7633742 },
    { lat: 48.0187283, lng: 17.7628094 },
    { lat: 48.0194887, lng: 17.7618027 },
    { lat: 48.0210037, lng: 17.7602191 },
    { lat: 48.0217092, lng: 17.7596377 },
    { lat: 48.0227278, lng: 17.7588978 },
    { lat: 48.0243971, lng: 17.7583263 },
    { lat: 48.0251758, lng: 17.7579072 },
    { lat: 48.0259204, lng: 17.7571705 },
    { lat: 48.0270736, lng: 17.7561992 },
    { lat: 48.0290957, lng: 17.7548766 },
    { lat: 48.0291967, lng: 17.755446 },
    { lat: 48.0293906, lng: 17.7561665 },
    { lat: 48.0299621, lng: 17.7574239 },
    { lat: 48.0300333, lng: 17.7577322 },
    { lat: 48.0306919, lng: 17.7586813 },
    { lat: 48.0308641, lng: 17.758856 },
    { lat: 48.0309966, lng: 17.7610933 },
    { lat: 48.0311153, lng: 17.7615153 },
    { lat: 48.0319233, lng: 17.7631609 },
    { lat: 48.0321555, lng: 17.7637373 },
    { lat: 48.0322996, lng: 17.764457 },
    { lat: 48.0322904, lng: 17.7648346 },
    { lat: 48.0322204, lng: 17.7654552 },
    { lat: 48.0317902, lng: 17.7676888 },
    { lat: 48.031824, lng: 17.768269 },
  ],
  Čakany: [
    { lat: 48.1336927, lng: 17.3667437 },
    { lat: 48.132626, lng: 17.366495 },
    { lat: 48.131868, lng: 17.3661 },
    { lat: 48.13132, lng: 17.365231 },
    { lat: 48.130851, lng: 17.364332 },
    { lat: 48.128129, lng: 17.362347 },
    { lat: 48.127657, lng: 17.36189 },
    { lat: 48.127079, lng: 17.361082 },
    { lat: 48.126828, lng: 17.359936 },
    { lat: 48.126328, lng: 17.358086 },
    { lat: 48.126637, lng: 17.357603 },
    { lat: 48.126456, lng: 17.356904 },
    { lat: 48.125719, lng: 17.35603 },
    { lat: 48.125242, lng: 17.354891 },
    { lat: 48.125002, lng: 17.353932 },
    { lat: 48.123742, lng: 17.352174 },
    { lat: 48.123188, lng: 17.351242 },
    { lat: 48.122442, lng: 17.349759 },
    { lat: 48.122192, lng: 17.3491 },
    { lat: 48.123, lng: 17.347797 },
    { lat: 48.12265, lng: 17.347536 },
    { lat: 48.122267, lng: 17.346692 },
    { lat: 48.122055, lng: 17.345664 },
    { lat: 48.121594, lng: 17.344859 },
    { lat: 48.121046, lng: 17.344032 },
    { lat: 48.120261, lng: 17.343127 },
    { lat: 48.119712, lng: 17.342125 },
    { lat: 48.119183, lng: 17.341573 },
    { lat: 48.118621, lng: 17.341106 },
    { lat: 48.118445, lng: 17.340467 },
    { lat: 48.1181318, lng: 17.3408773 },
    { lat: 48.1178342, lng: 17.3411242 },
    { lat: 48.1149188, lng: 17.3440788 },
    { lat: 48.1138958, lng: 17.3449966 },
    { lat: 48.11012, lng: 17.3486437 },
    { lat: 48.1056304, lng: 17.3532003 },
    { lat: 48.1053065, lng: 17.3534803 },
    { lat: 48.1046797, lng: 17.3536379 },
    { lat: 48.1046725, lng: 17.3541305 },
    { lat: 48.1045864, lng: 17.3546248 },
    { lat: 48.1042036, lng: 17.35588 },
    { lat: 48.1040501, lng: 17.3562279 },
    { lat: 48.1038888, lng: 17.3564717 },
    { lat: 48.1034542, lng: 17.356853 },
    { lat: 48.1025606, lng: 17.3573313 },
    { lat: 48.1022125, lng: 17.3577441 },
    { lat: 48.1021332, lng: 17.3579278 },
    { lat: 48.1019414, lng: 17.3590326 },
    { lat: 48.102026, lng: 17.3595817 },
    { lat: 48.1023919, lng: 17.3611099 },
    { lat: 48.1024244, lng: 17.3618649 },
    { lat: 48.1024003, lng: 17.3623406 },
    { lat: 48.1022008, lng: 17.363568 },
    { lat: 48.1021192, lng: 17.3644238 },
    { lat: 48.1016257, lng: 17.3666141 },
    { lat: 48.101578, lng: 17.3674194 },
    { lat: 48.1014571, lng: 17.3680883 },
    { lat: 48.1013163, lng: 17.3684923 },
    { lat: 48.1009245, lng: 17.3691305 },
    { lat: 48.1008501, lng: 17.3693389 },
    { lat: 48.100845, lng: 17.3700545 },
    { lat: 48.1005878, lng: 17.3717134 },
    { lat: 48.1007521, lng: 17.3723413 },
    { lat: 48.1007584, lng: 17.37268 },
    { lat: 48.100614, lng: 17.3735975 },
    { lat: 48.1004146, lng: 17.3742235 },
    { lat: 48.0999926, lng: 17.3750085 },
    { lat: 48.0991015, lng: 17.3760241 },
    { lat: 48.0985248, lng: 17.3769977 },
    { lat: 48.0983294, lng: 17.3775153 },
    { lat: 48.0981865, lng: 17.3781778 },
    { lat: 48.0982743, lng: 17.380555 },
    { lat: 48.098394, lng: 17.3812778 },
    { lat: 48.098581, lng: 17.3818921 },
    { lat: 48.0989467, lng: 17.3829038 },
    { lat: 48.0992527, lng: 17.383558 },
    { lat: 48.0997121, lng: 17.3842776 },
    { lat: 48.1005122, lng: 17.3852156 },
    { lat: 48.101049, lng: 17.3862942 },
    { lat: 48.101393, lng: 17.3872684 },
    { lat: 48.1015425, lng: 17.388588 },
    { lat: 48.1015563, lng: 17.3892291 },
    { lat: 48.1016484, lng: 17.390033 },
    { lat: 48.1040946, lng: 17.3923112 },
    { lat: 48.1049389, lng: 17.3926115 },
    { lat: 48.1056135, lng: 17.3929786 },
    { lat: 48.1060482, lng: 17.3934768 },
    { lat: 48.1067632, lng: 17.3945152 },
    { lat: 48.1078961, lng: 17.3951732 },
    { lat: 48.1085115, lng: 17.3956511 },
    { lat: 48.1088511, lng: 17.3962197 },
    { lat: 48.1092951, lng: 17.3974478 },
    { lat: 48.1100505, lng: 17.3990505 },
    { lat: 48.111228, lng: 17.4002722 },
    { lat: 48.1134372, lng: 17.4017906 },
    { lat: 48.1142141, lng: 17.4025505 },
    { lat: 48.1144878, lng: 17.4028174 },
    { lat: 48.1167885, lng: 17.4026084 },
    { lat: 48.1183933, lng: 17.4001108 },
    { lat: 48.1188564, lng: 17.4001649 },
    { lat: 48.1189736, lng: 17.4002299 },
    { lat: 48.119522, lng: 17.4010539 },
    { lat: 48.1207279, lng: 17.4011155 },
    { lat: 48.1209693, lng: 17.4012306 },
    { lat: 48.1210395, lng: 17.4010775 },
    { lat: 48.1214819, lng: 17.3998628 },
    { lat: 48.1234023, lng: 17.3961096 },
    { lat: 48.1250876, lng: 17.3924815 },
    { lat: 48.1255415, lng: 17.3914771 },
    { lat: 48.1285766, lng: 17.3845154 },
    { lat: 48.1294516, lng: 17.3815734 },
    { lat: 48.130462, lng: 17.378969 },
    { lat: 48.132215, lng: 17.372521 },
    { lat: 48.1336927, lng: 17.3667437 },
  ],
  Okoč: [
    { lat: 47.959083, lng: 17.877922 },
    { lat: 47.9588482, lng: 17.8761371 },
    { lat: 47.958493, lng: 17.8747981 },
    { lat: 47.9579241, lng: 17.8722574 },
    { lat: 47.9577036, lng: 17.8710717 },
    { lat: 47.9576008, lng: 17.8697274 },
    { lat: 47.9575773, lng: 17.8685868 },
    { lat: 47.957717, lng: 17.8673849 },
    { lat: 47.9579781, lng: 17.8658654 },
    { lat: 47.958585, lng: 17.864089 },
    { lat: 47.9588494, lng: 17.8635255 },
    { lat: 47.9592912, lng: 17.8628488 },
    { lat: 47.9597814, lng: 17.8623024 },
    { lat: 47.9603676, lng: 17.8617747 },
    { lat: 47.960937, lng: 17.8615064 },
    { lat: 47.9608184, lng: 17.860934 },
    { lat: 47.9606061, lng: 17.8605459 },
    { lat: 47.9597925, lng: 17.8594116 },
    { lat: 47.959289, lng: 17.8588589 },
    { lat: 47.9588593, lng: 17.8582057 },
    { lat: 47.9516874, lng: 17.8487559 },
    { lat: 47.9510065, lng: 17.8477427 },
    { lat: 47.9476992, lng: 17.8415492 },
    { lat: 47.9436862, lng: 17.8338171 },
    { lat: 47.941429, lng: 17.8292861 },
    { lat: 47.9394995, lng: 17.8251808 },
    { lat: 47.9394234, lng: 17.824956 },
    { lat: 47.9374421, lng: 17.812693 },
    { lat: 47.9365482, lng: 17.8087356 },
    { lat: 47.9360549, lng: 17.8053053 },
    { lat: 47.9358191, lng: 17.804102 },
    { lat: 47.9357062, lng: 17.8041196 },
    { lat: 47.934202, lng: 17.8003929 },
    { lat: 47.9323817, lng: 17.7952669 },
    { lat: 47.9318286, lng: 17.7933026 },
    { lat: 47.9315808, lng: 17.7922017 },
    { lat: 47.931027, lng: 17.7885521 },
    { lat: 47.9317858, lng: 17.7874069 },
    { lat: 47.9330558, lng: 17.7853318 },
    { lat: 47.933873, lng: 17.7824215 },
    { lat: 47.9347664, lng: 17.7788849 },
    { lat: 47.9354643, lng: 17.7757454 },
    { lat: 47.9332661, lng: 17.7737253 },
    { lat: 47.9341793, lng: 17.7715123 },
    { lat: 47.9329365, lng: 17.7687545 },
    { lat: 47.9326953, lng: 17.7680175 },
    { lat: 47.9322155, lng: 17.7677474 },
    { lat: 47.9318367, lng: 17.7673026 },
    { lat: 47.9311494, lng: 17.7662187 },
    { lat: 47.930781, lng: 17.7655229 },
    { lat: 47.9303068, lng: 17.7640545 },
    { lat: 47.9297854, lng: 17.7631847 },
    { lat: 47.9293221, lng: 17.7622366 },
    { lat: 47.9289292, lng: 17.760768 },
    { lat: 47.9289538, lng: 17.7599289 },
    { lat: 47.929054, lng: 17.7588372 },
    { lat: 47.9290152, lng: 17.7581424 },
    { lat: 47.92871, lng: 17.7573756 },
    { lat: 47.9280535, lng: 17.7564282 },
    { lat: 47.9276125, lng: 17.7550954 },
    { lat: 47.9273432, lng: 17.7544339 },
    { lat: 47.9272629, lng: 17.7543232 },
    { lat: 47.9271123, lng: 17.7541155 },
    { lat: 47.921861, lng: 17.7502895 },
    { lat: 47.9210554, lng: 17.7495952 },
    { lat: 47.9197012, lng: 17.748788 },
    { lat: 47.9186257, lng: 17.7485075 },
    { lat: 47.9180788, lng: 17.7485729 },
    { lat: 47.9176148, lng: 17.7487545 },
    { lat: 47.9174064, lng: 17.7485802 },
    { lat: 47.9168287, lng: 17.7478456 },
    { lat: 47.9165129, lng: 17.7477059 },
    { lat: 47.9158377, lng: 17.7475398 },
    { lat: 47.914342, lng: 17.7480319 },
    { lat: 47.9135506, lng: 17.7479962 },
    { lat: 47.9130829, lng: 17.747629 },
    { lat: 47.9120853, lng: 17.7464789 },
    { lat: 47.9117386, lng: 17.7463142 },
    { lat: 47.9113489, lng: 17.7463663 },
    { lat: 47.9109496, lng: 17.7466432 },
    { lat: 47.9101765, lng: 17.7475688 },
    { lat: 47.9095351, lng: 17.7485807 },
    { lat: 47.9084531, lng: 17.7492795 },
    { lat: 47.9082298, lng: 17.7495721 },
    { lat: 47.9079208, lng: 17.7505711 },
    { lat: 47.9078164, lng: 17.7512875 },
    { lat: 47.9078281, lng: 17.7520347 },
    { lat: 47.908194, lng: 17.7540285 },
    { lat: 47.908118, lng: 17.7547822 },
    { lat: 47.9079544, lng: 17.7552347 },
    { lat: 47.9076725, lng: 17.7557293 },
    { lat: 47.9072639, lng: 17.7562955 },
    { lat: 47.907118, lng: 17.7564508 },
    { lat: 47.9063576, lng: 17.7568034 },
    { lat: 47.9053248, lng: 17.7569912 },
    { lat: 47.904765, lng: 17.7568644 },
    { lat: 47.9044852, lng: 17.7568854 },
    { lat: 47.9036713, lng: 17.7571426 },
    { lat: 47.9028529, lng: 17.7575236 },
    { lat: 47.9010397, lng: 17.7566007 },
    { lat: 47.9010742, lng: 17.7549272 },
    { lat: 47.900856, lng: 17.7532918 },
    { lat: 47.9005045, lng: 17.752039 },
    { lat: 47.8999597, lng: 17.7509971 },
    { lat: 47.8993355, lng: 17.7502027 },
    { lat: 47.8988488, lng: 17.7498432 },
    { lat: 47.8982829, lng: 17.7496001 },
    { lat: 47.8976075, lng: 17.7495245 },
    { lat: 47.8971118, lng: 17.7495046 },
    { lat: 47.8963337, lng: 17.7496307 },
    { lat: 47.8954565, lng: 17.7504072 },
    { lat: 47.8950283, lng: 17.751612 },
    { lat: 47.89452, lng: 17.753999 },
    { lat: 47.8940102, lng: 17.7569373 },
    { lat: 47.8935179, lng: 17.7585839 },
    { lat: 47.8931194, lng: 17.7604107 },
    { lat: 47.8923605, lng: 17.7633424 },
    { lat: 47.8916135, lng: 17.7658498 },
    { lat: 47.8909804, lng: 17.767159 },
    { lat: 47.8902956, lng: 17.7680908 },
    { lat: 47.8896682, lng: 17.7686759 },
    { lat: 47.889229, lng: 17.7690016 },
    { lat: 47.8881646, lng: 17.7696198 },
    { lat: 47.8876832, lng: 17.7697606 },
    { lat: 47.8872857, lng: 17.7697942 },
    { lat: 47.8860247, lng: 17.7695573 },
    { lat: 47.8865023, lng: 17.7709016 },
    { lat: 47.8861259, lng: 17.7718017 },
    { lat: 47.8855152, lng: 17.7727277 },
    { lat: 47.8848673, lng: 17.7725457 },
    { lat: 47.8809555, lng: 17.7728573 },
    { lat: 47.8800847, lng: 17.7716721 },
    { lat: 47.8793688, lng: 17.7710173 },
    { lat: 47.8781147, lng: 17.7697329 },
    { lat: 47.8757085, lng: 17.768981 },
    { lat: 47.8750831, lng: 17.7695646 },
    { lat: 47.8731293, lng: 17.7702413 },
    { lat: 47.8734958, lng: 17.7717201 },
    { lat: 47.8728804, lng: 17.7730263 },
    { lat: 47.8723166, lng: 17.7745605 },
    { lat: 47.8718169, lng: 17.7786502 },
    { lat: 47.8717659, lng: 17.7812757 },
    { lat: 47.8715807, lng: 17.7824934 },
    { lat: 47.8709452, lng: 17.7828198 },
    { lat: 47.8711572, lng: 17.785215 },
    { lat: 47.8717831, lng: 17.7889432 },
    { lat: 47.8723393, lng: 17.7921949 },
    { lat: 47.8725631, lng: 17.7957467 },
    { lat: 47.8716231, lng: 17.7957328 },
    { lat: 47.8719055, lng: 17.7994116 },
    { lat: 47.8716651, lng: 17.8011431 },
    { lat: 47.8709967, lng: 17.8025461 },
    { lat: 47.8702099, lng: 17.8037627 },
    { lat: 47.8689049, lng: 17.8051698 },
    { lat: 47.869241, lng: 17.806367 },
    { lat: 47.869372, lng: 17.806617 },
    { lat: 47.869835, lng: 17.80707 },
    { lat: 47.870602, lng: 17.807691 },
    { lat: 47.870324, lng: 17.808194 },
    { lat: 47.870611, lng: 17.808839 },
    { lat: 47.870843, lng: 17.809589 },
    { lat: 47.871041, lng: 17.810036 },
    { lat: 47.871265, lng: 17.810335 },
    { lat: 47.871356, lng: 17.811045 },
    { lat: 47.871363, lng: 17.811538 },
    { lat: 47.871726, lng: 17.812701 },
    { lat: 47.871833, lng: 17.812986 },
    { lat: 47.871963, lng: 17.813329 },
    { lat: 47.872069, lng: 17.813614 },
    { lat: 47.872038, lng: 17.814009 },
    { lat: 47.872174, lng: 17.814393 },
    { lat: 47.872419, lng: 17.814933 },
    { lat: 47.872629, lng: 17.815292 },
    { lat: 47.87239, lng: 17.815614 },
    { lat: 47.872848, lng: 17.816548 },
    { lat: 47.873562, lng: 17.817817 },
    { lat: 47.873609, lng: 17.818941 },
    { lat: 47.87392, lng: 17.820988 },
    { lat: 47.873235, lng: 17.821489 },
    { lat: 47.872825, lng: 17.821953 },
    { lat: 47.872624, lng: 17.823388 },
    { lat: 47.871925, lng: 17.824713 },
    { lat: 47.871817, lng: 17.82492 },
    { lat: 47.871725, lng: 17.825093 },
    { lat: 47.87162, lng: 17.825293 },
    { lat: 47.871619, lng: 17.825294 },
    { lat: 47.873356, lng: 17.828893 },
    { lat: 47.874251, lng: 17.830909 },
    { lat: 47.874265, lng: 17.830943 },
    { lat: 47.875061, lng: 17.832736 },
    { lat: 47.876941, lng: 17.834252 },
    { lat: 47.878684, lng: 17.835665 },
    { lat: 47.87944, lng: 17.836236 },
    { lat: 47.883971, lng: 17.839815 },
    { lat: 47.888655, lng: 17.843898 },
    { lat: 47.889214, lng: 17.844392 },
    { lat: 47.891861, lng: 17.846298 },
    { lat: 47.892594, lng: 17.847688 },
    { lat: 47.892638, lng: 17.847776 },
    { lat: 47.892758, lng: 17.848015 },
    { lat: 47.892794, lng: 17.848086 },
    { lat: 47.894187, lng: 17.850737 },
    { lat: 47.895002, lng: 17.852644 },
    { lat: 47.894455, lng: 17.853762 },
    { lat: 47.89591, lng: 17.857543 },
    { lat: 47.897413, lng: 17.861532 },
    { lat: 47.897894, lng: 17.862395 },
    { lat: 47.899416, lng: 17.864947 },
    { lat: 47.89992, lng: 17.86593 },
    { lat: 47.901227, lng: 17.868753 },
    { lat: 47.901336, lng: 17.868898 },
    { lat: 47.901642, lng: 17.869661 },
    { lat: 47.902334, lng: 17.871017 },
    { lat: 47.90336, lng: 17.874113 },
    { lat: 47.902551, lng: 17.874864 },
    { lat: 47.903028, lng: 17.875355 },
    { lat: 47.903309, lng: 17.875679 },
    { lat: 47.903652, lng: 17.876075 },
    { lat: 47.903845, lng: 17.876299 },
    { lat: 47.90387, lng: 17.876327 },
    { lat: 47.903918, lng: 17.876383 },
    { lat: 47.903966, lng: 17.876438 },
    { lat: 47.904211, lng: 17.876721 },
    { lat: 47.90459, lng: 17.877158 },
    { lat: 47.905131, lng: 17.877773 },
    { lat: 47.905488, lng: 17.878164 },
    { lat: 47.905552, lng: 17.878234 },
    { lat: 47.905612, lng: 17.878292 },
    { lat: 47.905752, lng: 17.878429 },
    { lat: 47.905981, lng: 17.878652 },
    { lat: 47.905996, lng: 17.878665 },
    { lat: 47.906334, lng: 17.878947 },
    { lat: 47.906383, lng: 17.878986 },
    { lat: 47.906396, lng: 17.879015 },
    { lat: 47.90647, lng: 17.879165 },
    { lat: 47.906594, lng: 17.879507 },
    { lat: 47.906645, lng: 17.879622 },
    { lat: 47.906745, lng: 17.879846 },
    { lat: 47.906825, lng: 17.880031 },
    { lat: 47.906896, lng: 17.880189 },
    { lat: 47.906976, lng: 17.880358 },
    { lat: 47.907204, lng: 17.880688 },
    { lat: 47.907249, lng: 17.880783 },
    { lat: 47.90732, lng: 17.881 },
    { lat: 47.907355, lng: 17.881102 },
    { lat: 47.907432, lng: 17.881328 },
    { lat: 47.907571, lng: 17.881653 },
    { lat: 47.907626, lng: 17.881773 },
    { lat: 47.907714, lng: 17.88195 },
    { lat: 47.907819, lng: 17.882165 },
    { lat: 47.907884, lng: 17.882297 },
    { lat: 47.907953, lng: 17.882461 },
    { lat: 47.907959, lng: 17.882476 },
    { lat: 47.908067, lng: 17.882731 },
    { lat: 47.90807, lng: 17.882737 },
    { lat: 47.908098, lng: 17.882829 },
    { lat: 47.908237, lng: 17.883275 },
    { lat: 47.908303, lng: 17.883486 },
    { lat: 47.908339, lng: 17.88357 },
    { lat: 47.908414, lng: 17.883747 },
    { lat: 47.908432, lng: 17.883765 },
    { lat: 47.908515, lng: 17.883847 },
    { lat: 47.908536, lng: 17.883859 },
    { lat: 47.908623, lng: 17.883915 },
    { lat: 47.908654, lng: 17.883921 },
    { lat: 47.908724, lng: 17.883937 },
    { lat: 47.908759, lng: 17.883933 },
    { lat: 47.908856, lng: 17.883922 },
    { lat: 47.9091, lng: 17.883864 },
    { lat: 47.910065, lng: 17.885945 },
    { lat: 47.9101, lng: 17.886011 },
    { lat: 47.910439, lng: 17.886901 },
    { lat: 47.910607, lng: 17.887117 },
    { lat: 47.910787, lng: 17.88729 },
    { lat: 47.910992, lng: 17.887472 },
    { lat: 47.911082, lng: 17.887515 },
    { lat: 47.911103, lng: 17.887525 },
    { lat: 47.911206, lng: 17.887508 },
    { lat: 47.911218, lng: 17.887506 },
    { lat: 47.911922, lng: 17.887321 },
    { lat: 47.912693, lng: 17.88821 },
    { lat: 47.913246, lng: 17.888833 },
    { lat: 47.913372, lng: 17.888913 },
    { lat: 47.913491, lng: 17.888923 },
    { lat: 47.913626, lng: 17.888846 },
    { lat: 47.913707, lng: 17.888611 },
    { lat: 47.913752, lng: 17.888479 },
    { lat: 47.913923, lng: 17.888017 },
    { lat: 47.913945, lng: 17.887959 },
    { lat: 47.914007, lng: 17.887835 },
    { lat: 47.914025, lng: 17.887798 },
    { lat: 47.91404, lng: 17.887789 },
    { lat: 47.914089, lng: 17.88776 },
    { lat: 47.914107, lng: 17.88775 },
    { lat: 47.914181, lng: 17.887767 },
    { lat: 47.914344, lng: 17.887917 },
    { lat: 47.914402, lng: 17.88797 },
    { lat: 47.914422, lng: 17.887988 },
    { lat: 47.914441, lng: 17.888006 },
    { lat: 47.914443, lng: 17.888008 },
    { lat: 47.914548, lng: 17.888011 },
    { lat: 47.914755, lng: 17.887933 },
    { lat: 47.914775, lng: 17.887926 },
    { lat: 47.914909, lng: 17.887838 },
    { lat: 47.914923, lng: 17.88782 },
    { lat: 47.91529, lng: 17.887325 },
    { lat: 47.915428, lng: 17.887188 },
    { lat: 47.915548, lng: 17.887115 },
    { lat: 47.915553, lng: 17.887114 },
    { lat: 47.915582, lng: 17.88711 },
    { lat: 47.915689, lng: 17.887288 },
    { lat: 47.915733, lng: 17.887297 },
    { lat: 47.91574, lng: 17.887298 },
    { lat: 47.915802, lng: 17.887265 },
    { lat: 47.915899, lng: 17.887192 },
    { lat: 47.915904, lng: 17.887186 },
    { lat: 47.91602, lng: 17.887054 },
    { lat: 47.916161, lng: 17.886785 },
    { lat: 47.916167, lng: 17.886779 },
    { lat: 47.91627, lng: 17.886683 },
    { lat: 47.91629, lng: 17.886664 },
    { lat: 47.916413, lng: 17.886585 },
    { lat: 47.916463, lng: 17.886552 },
    { lat: 47.916518, lng: 17.886514 },
    { lat: 47.916531, lng: 17.886476 },
    { lat: 47.916554, lng: 17.886414 },
    { lat: 47.916571, lng: 17.886189 },
    { lat: 47.916573, lng: 17.886173 },
    { lat: 47.916601, lng: 17.886104 },
    { lat: 47.916609, lng: 17.886084 },
    { lat: 47.916634, lng: 17.886026 },
    { lat: 47.916644, lng: 17.885999 },
    { lat: 47.916686, lng: 17.886013 },
    { lat: 47.916722, lng: 17.886025 },
    { lat: 47.916736, lng: 17.886034 },
    { lat: 47.916979, lng: 17.886194 },
    { lat: 47.916993, lng: 17.886203 },
    { lat: 47.917062, lng: 17.886243 },
    { lat: 47.91707, lng: 17.886248 },
    { lat: 47.917085, lng: 17.886256 },
    { lat: 47.917091, lng: 17.886246 },
    { lat: 47.917189, lng: 17.886334 },
    { lat: 47.917228, lng: 17.886369 },
    { lat: 47.917318, lng: 17.886415 },
    { lat: 47.917338, lng: 17.886425 },
    { lat: 47.917439, lng: 17.886441 },
    { lat: 47.917447, lng: 17.886442 },
    { lat: 47.917518, lng: 17.886408 },
    { lat: 47.917549, lng: 17.886393 },
    { lat: 47.917705, lng: 17.886259 },
    { lat: 47.917812, lng: 17.886177 },
    { lat: 47.917865, lng: 17.886193 },
    { lat: 47.917879, lng: 17.886197 },
    { lat: 47.917989, lng: 17.886258 },
    { lat: 47.918369, lng: 17.886718 },
    { lat: 47.918411, lng: 17.886769 },
    { lat: 47.91886, lng: 17.887269 },
    { lat: 47.919117, lng: 17.887571 },
    { lat: 47.919287, lng: 17.887765 },
    { lat: 47.919379, lng: 17.887625 },
    { lat: 47.919397, lng: 17.887598 },
    { lat: 47.919438, lng: 17.887534 },
    { lat: 47.919485, lng: 17.887463 },
    { lat: 47.919591, lng: 17.8873 },
    { lat: 47.919628, lng: 17.887243 },
    { lat: 47.91965, lng: 17.887211 },
    { lat: 47.919763, lng: 17.887038 },
    { lat: 47.919824, lng: 17.886943 },
    { lat: 47.919847, lng: 17.886904 },
    { lat: 47.919933, lng: 17.886755 },
    { lat: 47.919955, lng: 17.88672 },
    { lat: 47.92003, lng: 17.886603 },
    { lat: 47.920054, lng: 17.886587 },
    { lat: 47.920063, lng: 17.886581 },
    { lat: 47.920089, lng: 17.886563 },
    { lat: 47.920144, lng: 17.886525 },
    { lat: 47.92037, lng: 17.886374 },
    { lat: 47.920382, lng: 17.886365 },
    { lat: 47.920479, lng: 17.886281 },
    { lat: 47.920546, lng: 17.886224 },
    { lat: 47.920565, lng: 17.886207 },
    { lat: 47.920615, lng: 17.886122 },
    { lat: 47.920681, lng: 17.885998 },
    { lat: 47.920686, lng: 17.88599 },
    { lat: 47.920692, lng: 17.885959 },
    { lat: 47.920715, lng: 17.885853 },
    { lat: 47.920724, lng: 17.885813 },
    { lat: 47.920723, lng: 17.88574 },
    { lat: 47.920719, lng: 17.885557 },
    { lat: 47.920716, lng: 17.885539 },
    { lat: 47.92065, lng: 17.885179 },
    { lat: 47.920637, lng: 17.885104 },
    { lat: 47.920634, lng: 17.885088 },
    { lat: 47.920621, lng: 17.884815 },
    { lat: 47.920618, lng: 17.884727 },
    { lat: 47.920621, lng: 17.884602 },
    { lat: 47.920623, lng: 17.884511 },
    { lat: 47.920625, lng: 17.884499 },
    { lat: 47.920645, lng: 17.884398 },
    { lat: 47.920662, lng: 17.884319 },
    { lat: 47.920715, lng: 17.88415 },
    { lat: 47.920732, lng: 17.884095 },
    { lat: 47.920763, lng: 17.884003 },
    { lat: 47.920849, lng: 17.883744 },
    { lat: 47.920949, lng: 17.883441 },
    { lat: 47.92097, lng: 17.88338 },
    { lat: 47.921023, lng: 17.883218 },
    { lat: 47.921057, lng: 17.883117 },
    { lat: 47.921064, lng: 17.883103 },
    { lat: 47.921114, lng: 17.882987 },
    { lat: 47.921178, lng: 17.882844 },
    { lat: 47.921183, lng: 17.882836 },
    { lat: 47.921297, lng: 17.882691 },
    { lat: 47.921346, lng: 17.882628 },
    { lat: 47.92144, lng: 17.882535 },
    { lat: 47.921546, lng: 17.882428 },
    { lat: 47.92157, lng: 17.882405 },
    { lat: 47.921585, lng: 17.882389 },
    { lat: 47.921704, lng: 17.8823 },
    { lat: 47.921795, lng: 17.882274 },
    { lat: 47.921955, lng: 17.882229 },
    { lat: 47.921979, lng: 17.882222 },
    { lat: 47.922142, lng: 17.882218 },
    { lat: 47.922257, lng: 17.882216 },
    { lat: 47.922391, lng: 17.882213 },
    { lat: 47.922457, lng: 17.882212 },
    { lat: 47.92247, lng: 17.882206 },
    { lat: 47.92257, lng: 17.882158 },
    { lat: 47.922588, lng: 17.88215 },
    { lat: 47.922617, lng: 17.88211 },
    { lat: 47.922658, lng: 17.882054 },
    { lat: 47.922664, lng: 17.882045 },
    { lat: 47.922702, lng: 17.881978 },
    { lat: 47.922726, lng: 17.881935 },
    { lat: 47.922738, lng: 17.881913 },
    { lat: 47.92275, lng: 17.881735 },
    { lat: 47.92275, lng: 17.881724 },
    { lat: 47.922736, lng: 17.881598 },
    { lat: 47.922731, lng: 17.881553 },
    { lat: 47.922692, lng: 17.881291 },
    { lat: 47.92269, lng: 17.881275 },
    { lat: 47.922674, lng: 17.881081 },
    { lat: 47.922669, lng: 17.881019 },
    { lat: 47.922671, lng: 17.881 },
    { lat: 47.922677, lng: 17.880939 },
    { lat: 47.922684, lng: 17.880883 },
    { lat: 47.922712, lng: 17.880821 },
    { lat: 47.922731, lng: 17.880778 },
    { lat: 47.922749, lng: 17.880739 },
    { lat: 47.922807, lng: 17.880652 },
    { lat: 47.92283, lng: 17.880617 },
    { lat: 47.922958, lng: 17.880488 },
    { lat: 47.92299, lng: 17.880455 },
    { lat: 47.923004, lng: 17.880441 },
    { lat: 47.923035, lng: 17.880376 },
    { lat: 47.923076, lng: 17.880289 },
    { lat: 47.923127, lng: 17.88018 },
    { lat: 47.923144, lng: 17.880129 },
    { lat: 47.923195, lng: 17.879971 },
    { lat: 47.923206, lng: 17.879941 },
    { lat: 47.923226, lng: 17.879848 },
    { lat: 47.923236, lng: 17.879806 },
    { lat: 47.923254, lng: 17.879729 },
    { lat: 47.923285, lng: 17.87959 },
    { lat: 47.92329, lng: 17.879568 },
    { lat: 47.923295, lng: 17.879546 },
    { lat: 47.923302, lng: 17.879529 },
    { lat: 47.923368, lng: 17.87938 },
    { lat: 47.923425, lng: 17.879249 },
    { lat: 47.923465, lng: 17.879189 },
    { lat: 47.923533, lng: 17.87909 },
    { lat: 47.923596, lng: 17.878996 },
    { lat: 47.92366, lng: 17.878902 },
    { lat: 47.923813, lng: 17.878675 },
    { lat: 47.923863, lng: 17.878602 },
    { lat: 47.924025, lng: 17.878361 },
    { lat: 47.924045, lng: 17.878325 },
    { lat: 47.924155, lng: 17.878111 },
    { lat: 47.924171, lng: 17.87809 },
    { lat: 47.924399, lng: 17.877801 },
    { lat: 47.924527, lng: 17.877553 },
    { lat: 47.924553, lng: 17.8775 },
    { lat: 47.924571, lng: 17.877479 },
    { lat: 47.924674, lng: 17.877357 },
    { lat: 47.924852, lng: 17.877145 },
    { lat: 47.924966, lng: 17.87701 },
    { lat: 47.924984, lng: 17.876987 },
    { lat: 47.925189, lng: 17.876858 },
    { lat: 47.925412, lng: 17.876761 },
    { lat: 47.925618, lng: 17.876672 },
    { lat: 47.92566, lng: 17.87664 },
    { lat: 47.925769, lng: 17.876559 },
    { lat: 47.92583, lng: 17.876513 },
    { lat: 47.925835, lng: 17.876505 },
    { lat: 47.925985, lng: 17.876301 },
    { lat: 47.925987, lng: 17.876294 },
    { lat: 47.92606, lng: 17.87612 },
    { lat: 47.926123, lng: 17.875968 },
    { lat: 47.926185, lng: 17.875818 },
    { lat: 47.926212, lng: 17.875752 },
    { lat: 47.926221, lng: 17.87571 },
    { lat: 47.926333, lng: 17.875193 },
    { lat: 47.92634, lng: 17.875158 },
    { lat: 47.926346, lng: 17.875129 },
    { lat: 47.926356, lng: 17.875091 },
    { lat: 47.926377, lng: 17.875015 },
    { lat: 47.926406, lng: 17.874908 },
    { lat: 47.926486, lng: 17.874749 },
    { lat: 47.926535, lng: 17.874654 },
    { lat: 47.926565, lng: 17.87462 },
    { lat: 47.926736, lng: 17.874431 },
    { lat: 47.926969, lng: 17.874171 },
    { lat: 47.927084, lng: 17.874071 },
    { lat: 47.927104, lng: 17.874038 },
    { lat: 47.927133, lng: 17.873993 },
    { lat: 47.92721, lng: 17.873869 },
    { lat: 47.927243, lng: 17.873816 },
    { lat: 47.927246, lng: 17.87381 },
    { lat: 47.927317, lng: 17.873551 },
    { lat: 47.927338, lng: 17.873474 },
    { lat: 47.927341, lng: 17.873461 },
    { lat: 47.927361, lng: 17.873272 },
    { lat: 47.927367, lng: 17.873222 },
    { lat: 47.92737, lng: 17.873194 },
    { lat: 47.92737, lng: 17.873187 },
    { lat: 47.927375, lng: 17.873166 },
    { lat: 47.9274, lng: 17.87303 },
    { lat: 47.927411, lng: 17.872979 },
    { lat: 47.927439, lng: 17.872904 },
    { lat: 47.927568, lng: 17.872571 },
    { lat: 47.927587, lng: 17.872524 },
    { lat: 47.92759, lng: 17.872511 },
    { lat: 47.927612, lng: 17.872403 },
    { lat: 47.927671, lng: 17.872124 },
    { lat: 47.927684, lng: 17.872063 },
    { lat: 47.927698, lng: 17.871995 },
    { lat: 47.927711, lng: 17.871961 },
    { lat: 47.927717, lng: 17.871942 },
    { lat: 47.92772, lng: 17.871934 },
    { lat: 47.927754, lng: 17.871841 },
    { lat: 47.927787, lng: 17.871749 },
    { lat: 47.927863, lng: 17.871534 },
    { lat: 47.927904, lng: 17.871419 },
    { lat: 47.92793, lng: 17.871431 },
    { lat: 47.928048, lng: 17.871482 },
    { lat: 47.928078, lng: 17.871496 },
    { lat: 47.928279, lng: 17.871634 },
    { lat: 47.928621, lng: 17.871813 },
    { lat: 47.928941, lng: 17.871979 },
    { lat: 47.929363, lng: 17.872199 },
    { lat: 47.929729, lng: 17.872389 },
    { lat: 47.930143, lng: 17.872604 },
    { lat: 47.930166, lng: 17.872617 },
    { lat: 47.93019, lng: 17.872629 },
    { lat: 47.930241, lng: 17.87267 },
    { lat: 47.930789, lng: 17.87311 },
    { lat: 47.931132, lng: 17.873385 },
    { lat: 47.931365, lng: 17.873572 },
    { lat: 47.931452, lng: 17.873641 },
    { lat: 47.931607, lng: 17.873765 },
    { lat: 47.931803, lng: 17.873923 },
    { lat: 47.932012, lng: 17.87409 },
    { lat: 47.93218, lng: 17.874224 },
    { lat: 47.932326, lng: 17.874342 },
    { lat: 47.932337, lng: 17.874355 },
    { lat: 47.932442, lng: 17.874489 },
    { lat: 47.932633, lng: 17.874731 },
    { lat: 47.932826, lng: 17.874977 },
    { lat: 47.932949, lng: 17.875132 },
    { lat: 47.93314, lng: 17.875375 },
    { lat: 47.933365, lng: 17.87566 },
    { lat: 47.933458, lng: 17.875779 },
    { lat: 47.933534, lng: 17.875875 },
    { lat: 47.933564, lng: 17.875914 },
    { lat: 47.933796, lng: 17.876218 },
    { lat: 47.934038, lng: 17.876533 },
    { lat: 47.934264, lng: 17.876827 },
    { lat: 47.934525, lng: 17.87717 },
    { lat: 47.93473, lng: 17.877437 },
    { lat: 47.93474, lng: 17.877449 },
    { lat: 47.93484, lng: 17.877583 },
    { lat: 47.935007, lng: 17.877805 },
    { lat: 47.935177, lng: 17.878033 },
    { lat: 47.935433, lng: 17.878373 },
    { lat: 47.935678, lng: 17.878699 },
    { lat: 47.935975, lng: 17.879095 },
    { lat: 47.935979, lng: 17.879098 },
    { lat: 47.935991, lng: 17.879116 },
    { lat: 47.935995, lng: 17.87912 },
    { lat: 47.936018, lng: 17.879152 },
    { lat: 47.936159, lng: 17.879339 },
    { lat: 47.936161, lng: 17.879341 },
    { lat: 47.936169, lng: 17.879354 },
    { lat: 47.936189, lng: 17.879378 },
    { lat: 47.936194, lng: 17.879387 },
    { lat: 47.936293, lng: 17.879518 },
    { lat: 47.936474, lng: 17.87976 },
    { lat: 47.936531, lng: 17.879834 },
    { lat: 47.936718, lng: 17.880083 },
    { lat: 47.936766, lng: 17.880148 },
    { lat: 47.936775, lng: 17.880156 },
    { lat: 47.936862, lng: 17.880235 },
    { lat: 47.936963, lng: 17.880327 },
    { lat: 47.937254, lng: 17.880591 },
    { lat: 47.937445, lng: 17.880763 },
    { lat: 47.93771, lng: 17.881002 },
    { lat: 47.937951, lng: 17.881222 },
    { lat: 47.938146, lng: 17.881399 },
    { lat: 47.938167, lng: 17.881417 },
    { lat: 47.938246, lng: 17.881489 },
    { lat: 47.938331, lng: 17.881567 },
    { lat: 47.938507, lng: 17.881728 },
    { lat: 47.938689, lng: 17.881894 },
    { lat: 47.938936, lng: 17.882122 },
    { lat: 47.939079, lng: 17.882252 },
    { lat: 47.939224, lng: 17.882387 },
    { lat: 47.939356, lng: 17.882506 },
    { lat: 47.939419, lng: 17.882565 },
    { lat: 47.939527, lng: 17.882667 },
    { lat: 47.939794, lng: 17.882922 },
    { lat: 47.940041, lng: 17.883156 },
    { lat: 47.940318, lng: 17.883418 },
    { lat: 47.940578, lng: 17.883665 },
    { lat: 47.940644, lng: 17.883728 },
    { lat: 47.940898, lng: 17.88397 },
    { lat: 47.941225, lng: 17.884279 },
    { lat: 47.941239, lng: 17.884293 },
    { lat: 47.941392, lng: 17.884438 },
    { lat: 47.941547, lng: 17.884585 },
    { lat: 47.941584, lng: 17.884617 },
    { lat: 47.941646, lng: 17.884673 },
    { lat: 47.941729, lng: 17.884746 },
    { lat: 47.941769, lng: 17.884781 },
    { lat: 47.941773, lng: 17.884785 },
    { lat: 47.941833, lng: 17.884838 },
    { lat: 47.941865, lng: 17.884866 },
    { lat: 47.941874, lng: 17.884873 },
    { lat: 47.94197, lng: 17.884959 },
    { lat: 47.941983, lng: 17.884971 },
    { lat: 47.942002, lng: 17.884987 },
    { lat: 47.942195, lng: 17.885157 },
    { lat: 47.942205, lng: 17.885166 },
    { lat: 47.942323, lng: 17.885272 },
    { lat: 47.94239, lng: 17.88533 },
    { lat: 47.942394, lng: 17.885339 },
    { lat: 47.942412, lng: 17.885381 },
    { lat: 47.94242, lng: 17.885397 },
    { lat: 47.942683, lng: 17.885979 },
    { lat: 47.942689, lng: 17.885993 },
    { lat: 47.943205, lng: 17.885515 },
    { lat: 47.94332, lng: 17.885426 },
    { lat: 47.943396, lng: 17.885367 },
    { lat: 47.943509, lng: 17.88528 },
    { lat: 47.943603, lng: 17.885207 },
    { lat: 47.943621, lng: 17.885193 },
    { lat: 47.943922, lng: 17.885093 },
    { lat: 47.944058, lng: 17.885047 },
    { lat: 47.944068, lng: 17.885044 },
    { lat: 47.9441, lng: 17.88504 },
    { lat: 47.944368, lng: 17.885005 },
    { lat: 47.9446, lng: 17.884975 },
    { lat: 47.944671, lng: 17.884965 },
    { lat: 47.944879, lng: 17.884938 },
    { lat: 47.944901, lng: 17.88494 },
    { lat: 47.945207, lng: 17.884966 },
    { lat: 47.945435, lng: 17.884985 },
    { lat: 47.945522, lng: 17.885003 },
    { lat: 47.945604, lng: 17.885019 },
    { lat: 47.945625, lng: 17.885027 },
    { lat: 47.945788, lng: 17.885091 },
    { lat: 47.945939, lng: 17.885151 },
    { lat: 47.946107, lng: 17.885218 },
    { lat: 47.946124, lng: 17.885224 },
    { lat: 47.946154, lng: 17.885229 },
    { lat: 47.946251, lng: 17.885242 },
    { lat: 47.946389, lng: 17.88528 },
    { lat: 47.946861, lng: 17.885412 },
    { lat: 47.947039, lng: 17.885462 },
    { lat: 47.947089, lng: 17.885483 },
    { lat: 47.947492, lng: 17.885656 },
    { lat: 47.947538, lng: 17.885668 },
    { lat: 47.947679, lng: 17.885705 },
    { lat: 47.947931, lng: 17.885771 },
    { lat: 47.948108, lng: 17.885816 },
    { lat: 47.948293, lng: 17.885865 },
    { lat: 47.948418, lng: 17.885897 },
    { lat: 47.948538, lng: 17.885928 },
    { lat: 47.948621, lng: 17.88595 },
    { lat: 47.948654, lng: 17.885948 },
    { lat: 47.948898, lng: 17.885934 },
    { lat: 47.949165, lng: 17.885918 },
    { lat: 47.949262, lng: 17.885908 },
    { lat: 47.949463, lng: 17.885887 },
    { lat: 47.94989, lng: 17.885841 },
    { lat: 47.950243, lng: 17.885804 },
    { lat: 47.950276, lng: 17.8858 },
    { lat: 47.950353, lng: 17.885783 },
    { lat: 47.950713, lng: 17.8857 },
    { lat: 47.950869, lng: 17.885664 },
    { lat: 47.950985, lng: 17.885643 },
    { lat: 47.951157, lng: 17.885612 },
    { lat: 47.951169, lng: 17.88561 },
    { lat: 47.951534, lng: 17.885526 },
    { lat: 47.951605, lng: 17.88551 },
    { lat: 47.952038, lng: 17.885411 },
    { lat: 47.952084, lng: 17.885401 },
    { lat: 47.952164, lng: 17.885379 },
    { lat: 47.952232, lng: 17.88536 },
    { lat: 47.952484, lng: 17.88529 },
    { lat: 47.952761, lng: 17.885213 },
    { lat: 47.952945, lng: 17.885163 },
    { lat: 47.953049, lng: 17.885134 },
    { lat: 47.953196, lng: 17.885099 },
    { lat: 47.953246, lng: 17.885088 },
    { lat: 47.95363, lng: 17.884997 },
    { lat: 47.953794, lng: 17.884949 },
    { lat: 47.953921, lng: 17.884912 },
    { lat: 47.954067, lng: 17.884868 },
    { lat: 47.954264, lng: 17.88481 },
    { lat: 47.954361, lng: 17.884782 },
    { lat: 47.95447, lng: 17.884739 },
    { lat: 47.954645, lng: 17.884672 },
    { lat: 47.954713, lng: 17.884646 },
    { lat: 47.955003, lng: 17.884526 },
    { lat: 47.95522, lng: 17.884435 },
    { lat: 47.955267, lng: 17.884416 },
    { lat: 47.95556, lng: 17.884256 },
    { lat: 47.95578, lng: 17.884137 },
    { lat: 47.955844, lng: 17.884097 },
    { lat: 47.956235, lng: 17.883855 },
    { lat: 47.956298, lng: 17.883816 },
    { lat: 47.956484, lng: 17.883677 },
    { lat: 47.956645, lng: 17.883557 },
    { lat: 47.956696, lng: 17.883519 },
    { lat: 47.956866, lng: 17.883374 },
    { lat: 47.956878, lng: 17.883364 },
    { lat: 47.957014, lng: 17.883239 },
    { lat: 47.957114, lng: 17.883135 },
    { lat: 47.957177, lng: 17.88307 },
    { lat: 47.957239, lng: 17.882991 },
    { lat: 47.95748, lng: 17.882682 },
    { lat: 47.957631, lng: 17.882494 },
    { lat: 47.957778, lng: 17.882312 },
    { lat: 47.957791, lng: 17.882292 },
    { lat: 47.958226, lng: 17.881655 },
    { lat: 47.958243, lng: 17.881631 },
    { lat: 47.958279, lng: 17.881565 },
    { lat: 47.958465, lng: 17.881224 },
    { lat: 47.958519, lng: 17.881115 },
    { lat: 47.958688, lng: 17.880769 },
    { lat: 47.958698, lng: 17.880747 },
    { lat: 47.958972, lng: 17.87993 },
    { lat: 47.958997, lng: 17.879857 },
    { lat: 47.959, lng: 17.879835 },
    { lat: 47.959049, lng: 17.879528 },
    { lat: 47.959081, lng: 17.879328 },
    { lat: 47.959092, lng: 17.879213 },
    { lat: 47.959113, lng: 17.878969 },
    { lat: 47.959127, lng: 17.878825 },
    { lat: 47.959129, lng: 17.878698 },
    { lat: 47.959139, lng: 17.87824 },
    { lat: 47.959136, lng: 17.878217 },
    { lat: 47.959083, lng: 17.877922 },
  ],
  Ohrady: [
    { lat: 48.0171483, lng: 17.6968928 },
    { lat: 48.0043259, lng: 17.6820971 },
    { lat: 48.0018787, lng: 17.680458 },
    { lat: 48.0009735, lng: 17.6800141 },
    { lat: 47.995165, lng: 17.6766625 },
    { lat: 47.9948936, lng: 17.6765059 },
    { lat: 47.9926658, lng: 17.6753574 },
    { lat: 47.9917108, lng: 17.6749273 },
    { lat: 47.9914412, lng: 17.6748613 },
    { lat: 47.9910602, lng: 17.6762325 },
    { lat: 47.990815, lng: 17.6765882 },
    { lat: 47.9904946, lng: 17.6769509 },
    { lat: 47.9901157, lng: 17.6771931 },
    { lat: 47.9894641, lng: 17.67728 },
    { lat: 47.9890319, lng: 17.6772131 },
    { lat: 47.9888128, lng: 17.6784685 },
    { lat: 47.9885413, lng: 17.6789435 },
    { lat: 47.987498, lng: 17.6796412 },
    { lat: 47.9870809, lng: 17.6799955 },
    { lat: 47.9868537, lng: 17.6802751 },
    { lat: 47.9812061, lng: 17.6909205 },
    { lat: 47.979195, lng: 17.6943675 },
    { lat: 47.978282, lng: 17.6957922 },
    { lat: 47.9775132, lng: 17.6963186 },
    { lat: 47.9773068, lng: 17.6973069 },
    { lat: 47.9780049, lng: 17.6987894 },
    { lat: 47.9786444, lng: 17.6999093 },
    { lat: 47.978571, lng: 17.7003064 },
    { lat: 47.9786985, lng: 17.7007175 },
    { lat: 47.9785831, lng: 17.7011437 },
    { lat: 47.9782665, lng: 17.7024148 },
    { lat: 47.9808517, lng: 17.7035067 },
    { lat: 47.9816445, lng: 17.7036722 },
    { lat: 47.9823678, lng: 17.7041516 },
    { lat: 47.9830148, lng: 17.7051413 },
    { lat: 47.9835875, lng: 17.7064078 },
    { lat: 47.9838183, lng: 17.7073663 },
    { lat: 47.9838752, lng: 17.7087416 },
    { lat: 47.9836138, lng: 17.7099578 },
    { lat: 47.9831546, lng: 17.7110786 },
    { lat: 47.984188, lng: 17.7122374 },
    { lat: 47.9860622, lng: 17.7140941 },
    { lat: 47.9866722, lng: 17.7149115 },
    { lat: 47.987221, lng: 17.7164434 },
    { lat: 47.9875149, lng: 17.7175476 },
    { lat: 47.9879708, lng: 17.7187903 },
    { lat: 47.9884363, lng: 17.719876 },
    { lat: 47.9885174, lng: 17.7205217 },
    { lat: 47.9884879, lng: 17.7214953 },
    { lat: 47.9885481, lng: 17.7224372 },
    { lat: 47.9879682, lng: 17.7239799 },
    { lat: 47.9878488, lng: 17.7247609 },
    { lat: 47.9878432, lng: 17.7253933 },
    { lat: 47.987994, lng: 17.7258904 },
    { lat: 47.9883933, lng: 17.7268045 },
    { lat: 47.9884564, lng: 17.7268035 },
    { lat: 47.9903016, lng: 17.7267896 },
    { lat: 47.9913759, lng: 17.7264748 },
    { lat: 47.9921209, lng: 17.7264698 },
    { lat: 47.9928295, lng: 17.726358 },
    { lat: 47.9934263, lng: 17.7261127 },
    { lat: 47.9942239, lng: 17.7256002 },
    { lat: 47.9950036, lng: 17.7253027 },
    { lat: 47.9961268, lng: 17.7243966 },
    { lat: 47.9974802, lng: 17.7234727 },
    { lat: 47.9981631, lng: 17.7231977 },
    { lat: 48.0001681, lng: 17.722651 },
    { lat: 48.0020487, lng: 17.7217616 },
    { lat: 48.0023817, lng: 17.72196 },
    { lat: 48.0025748, lng: 17.722664 },
    { lat: 48.0030471, lng: 17.7232858 },
    { lat: 48.0031888, lng: 17.7229676 },
    { lat: 48.0040914, lng: 17.7242048 },
    { lat: 48.0042426, lng: 17.7241485 },
    { lat: 48.0052456, lng: 17.7252929 },
    { lat: 48.0057068, lng: 17.7258952 },
    { lat: 48.0059806, lng: 17.7260859 },
    { lat: 48.0070455, lng: 17.7271375 },
    { lat: 48.00781, lng: 17.7277524 },
    { lat: 48.0099689, lng: 17.7290023 },
    { lat: 48.0107122, lng: 17.7296219 },
    { lat: 48.0109141, lng: 17.730017 },
    { lat: 48.0111814, lng: 17.7309182 },
    { lat: 48.0104428, lng: 17.7325241 },
    { lat: 48.0097113, lng: 17.7336988 },
    { lat: 48.0091719, lng: 17.7347724 },
    { lat: 48.0100007, lng: 17.7356786 },
    { lat: 48.0112988, lng: 17.7361583 },
    { lat: 48.0112249, lng: 17.7365514 },
    { lat: 48.011503, lng: 17.7372953 },
    { lat: 48.0128802, lng: 17.7384142 },
    { lat: 48.013323, lng: 17.7384925 },
    { lat: 48.0137295, lng: 17.7384033 },
    { lat: 48.0148707, lng: 17.7378361 },
    { lat: 48.0162011, lng: 17.737481 },
    { lat: 48.0166029, lng: 17.7372098 },
    { lat: 48.0181036, lng: 17.7359104 },
    { lat: 48.019055, lng: 17.7352181 },
    { lat: 48.0194158, lng: 17.7350478 },
    { lat: 48.0198007, lng: 17.7349718 },
    { lat: 48.0201776, lng: 17.7350388 },
    { lat: 48.0204082, lng: 17.7351559 },
    { lat: 48.0210749, lng: 17.735797 },
    { lat: 48.0211377, lng: 17.7362799 },
    { lat: 48.0210577, lng: 17.737204 },
    { lat: 48.0210654, lng: 17.7378314 },
    { lat: 48.0211911, lng: 17.7383112 },
    { lat: 48.0213389, lng: 17.7385978 },
    { lat: 48.0214938, lng: 17.7387287 },
    { lat: 48.0216801, lng: 17.7388189 },
    { lat: 48.0219477, lng: 17.7388375 },
    { lat: 48.0229455, lng: 17.7385772 },
    { lat: 48.0233377, lng: 17.7385884 },
    { lat: 48.023822, lng: 17.7387131 },
    { lat: 48.0246659, lng: 17.7391188 },
    { lat: 48.024902, lng: 17.7390656 },
    { lat: 48.0251721, lng: 17.7388896 },
    { lat: 48.0253733, lng: 17.7386042 },
    { lat: 48.0261739, lng: 17.7370683 },
    { lat: 48.0262922, lng: 17.7359233 },
    { lat: 48.0262534, lng: 17.7354296 },
    { lat: 48.0261624, lng: 17.7351277 },
    { lat: 48.0259214, lng: 17.7347058 },
    { lat: 48.0249904, lng: 17.7334651 },
    { lat: 48.0246147, lng: 17.7328362 },
    { lat: 48.0241426, lng: 17.7321822 },
    { lat: 48.0235219, lng: 17.7316963 },
    { lat: 48.0227235, lng: 17.7314964 },
    { lat: 48.0220422, lng: 17.7314337 },
    { lat: 48.0216627, lng: 17.7312709 },
    { lat: 48.0214482, lng: 17.7310178 },
    { lat: 48.0213284, lng: 17.7307781 },
    { lat: 48.0212091, lng: 17.7303679 },
    { lat: 48.0211408, lng: 17.7297623 },
    { lat: 48.0212137, lng: 17.7285824 },
    { lat: 48.0219855, lng: 17.72664 },
    { lat: 48.0221498, lng: 17.7264144 },
    { lat: 48.0224365, lng: 17.7261803 },
    { lat: 48.022758, lng: 17.7261229 },
    { lat: 48.0240263, lng: 17.7261817 },
    { lat: 48.0242165, lng: 17.7260801 },
    { lat: 48.0245132, lng: 17.7256982 },
    { lat: 48.0246616, lng: 17.7254001 },
    { lat: 48.0247503, lng: 17.7250855 },
    { lat: 48.0248321, lng: 17.7245119 },
    { lat: 48.0248286, lng: 17.723825 },
    { lat: 48.0247485, lng: 17.7232856 },
    { lat: 48.0245784, lng: 17.7229104 },
    { lat: 48.0238437, lng: 17.7222845 },
    { lat: 48.0228912, lng: 17.7216791 },
    { lat: 48.0225878, lng: 17.7213231 },
    { lat: 48.0223882, lng: 17.7209308 },
    { lat: 48.0221834, lng: 17.7202173 },
    { lat: 48.0216061, lng: 17.7193287 },
    { lat: 48.0215389, lng: 17.7190824 },
    { lat: 48.0212207, lng: 17.7184358 },
    { lat: 48.0210636, lng: 17.7176228 },
    { lat: 48.0211475, lng: 17.7170508 },
    { lat: 48.0214452, lng: 17.7163781 },
    { lat: 48.0219911, lng: 17.7157898 },
    { lat: 48.0222526, lng: 17.7155869 },
    { lat: 48.0228357, lng: 17.7153062 },
    { lat: 48.0233408, lng: 17.7154902 },
    { lat: 48.023964, lng: 17.7160878 },
    { lat: 48.0242567, lng: 17.71629 },
    { lat: 48.0245647, lng: 17.716323 },
    { lat: 48.0249478, lng: 17.7162211 },
    { lat: 48.0251718, lng: 17.7160661 },
    { lat: 48.0256043, lng: 17.7153976 },
    { lat: 48.0257448, lng: 17.7145577 },
    { lat: 48.0258978, lng: 17.7141893 },
    { lat: 48.0264014, lng: 17.713198 },
    { lat: 48.0270226, lng: 17.7121723 },
    { lat: 48.0271916, lng: 17.7116638 },
    { lat: 48.0279774, lng: 17.7108525 },
    { lat: 48.0284492, lng: 17.7101273 },
    { lat: 48.0286179, lng: 17.7099204 },
    { lat: 48.0287706, lng: 17.7097771 },
    { lat: 48.0284977, lng: 17.7092821 },
    { lat: 48.027604, lng: 17.7079163 },
    { lat: 48.0272367, lng: 17.7076433 },
    { lat: 48.0258851, lng: 17.7064176 },
    { lat: 48.0254756, lng: 17.7058821 },
    { lat: 48.0248791, lng: 17.7058735 },
    { lat: 48.0243603, lng: 17.7056858 },
    { lat: 48.023371, lng: 17.7046725 },
    { lat: 48.0230419, lng: 17.7043067 },
    { lat: 48.0224983, lng: 17.7034662 },
    { lat: 48.0220129, lng: 17.7025516 },
    { lat: 48.0219088, lng: 17.702223 },
    { lat: 48.0212131, lng: 17.7020597 },
    { lat: 48.0189365, lng: 17.6990827 },
    { lat: 48.0171568, lng: 17.6969032 },
    { lat: 48.0171483, lng: 17.6968928 },
  ],
  TrstenánaOstrove: [
    { lat: 47.9197551, lng: 17.5296218 },
    { lat: 47.9207033, lng: 17.5304569 },
    { lat: 47.9224874, lng: 17.5313325 },
    { lat: 47.9234595, lng: 17.531626 },
    { lat: 47.9242112, lng: 17.528025 },
    { lat: 47.9260453, lng: 17.5198374 },
    { lat: 47.9260999, lng: 17.5179023 },
    { lat: 47.9263233, lng: 17.516549 },
    { lat: 47.9267974, lng: 17.5148103 },
    { lat: 47.9270633, lng: 17.5143649 },
    { lat: 47.9272068, lng: 17.513453 },
    { lat: 47.9273303, lng: 17.5128135 },
    { lat: 47.9278737, lng: 17.5112412 },
    { lat: 47.9280127, lng: 17.5098133 },
    { lat: 47.9281247, lng: 17.5091092 },
    { lat: 47.9281803, lng: 17.5082587 },
    { lat: 47.9285488, lng: 17.5071786 },
    { lat: 47.9290975, lng: 17.506022 },
    { lat: 47.9291811, lng: 17.505185 },
    { lat: 47.9293935, lng: 17.5044321 },
    { lat: 47.9297592, lng: 17.5039421 },
    { lat: 47.9301189, lng: 17.5015902 },
    { lat: 47.9302951, lng: 17.5015031 },
    { lat: 47.9315695, lng: 17.4996921 },
    { lat: 47.9335539, lng: 17.4945062 },
    { lat: 47.9346508, lng: 17.4895714 },
    { lat: 47.9351719, lng: 17.4868389 },
    { lat: 47.9344826, lng: 17.4865014 },
    { lat: 47.9340075, lng: 17.4862797 },
    { lat: 47.9335866, lng: 17.486252 },
    { lat: 47.9328739, lng: 17.4863186 },
    { lat: 47.9310981, lng: 17.4868538 },
    { lat: 47.9293789, lng: 17.4842487 },
    { lat: 47.9287698, lng: 17.4851494 },
    { lat: 47.9281761, lng: 17.4861476 },
    { lat: 47.9278966, lng: 17.486787 },
    { lat: 47.9276313, lng: 17.4871887 },
    { lat: 47.9276994, lng: 17.4872983 },
    { lat: 47.9278456, lng: 17.4880394 },
    { lat: 47.9278578, lng: 17.4886809 },
    { lat: 47.9255474, lng: 17.4882324 },
    { lat: 47.9233472, lng: 17.486518 },
    { lat: 47.9222603, lng: 17.4853425 },
    { lat: 47.9222182, lng: 17.4853021 },
    { lat: 47.9219385, lng: 17.4850281 },
    { lat: 47.920031, lng: 17.4848192 },
    { lat: 47.9179139, lng: 17.4878275 },
    { lat: 47.9166798, lng: 17.4894544 },
    { lat: 47.9153372, lng: 17.4910108 },
    { lat: 47.9118416, lng: 17.4954355 },
    { lat: 47.9101458, lng: 17.4979239 },
    { lat: 47.9086535, lng: 17.4998205 },
    { lat: 47.9073243, lng: 17.5009571 },
    { lat: 47.9065179, lng: 17.5019868 },
    { lat: 47.9060904, lng: 17.5028676 },
    { lat: 47.9058529, lng: 17.5030374 },
    { lat: 47.905288, lng: 17.50314 },
    { lat: 47.9049236, lng: 17.5033192 },
    { lat: 47.9045533, lng: 17.5039507 },
    { lat: 47.9045101, lng: 17.5039811 },
    { lat: 47.9042505, lng: 17.504366 },
    { lat: 47.903993, lng: 17.5051341 },
    { lat: 47.9041515, lng: 17.5065073 },
    { lat: 47.9041077, lng: 17.5071921 },
    { lat: 47.9038991, lng: 17.5080011 },
    { lat: 47.9038054, lng: 17.5085938 },
    { lat: 47.9050663, lng: 17.5092502 },
    { lat: 47.9065928, lng: 17.5097175 },
    { lat: 47.9071712, lng: 17.5100555 },
    { lat: 47.9075364, lng: 17.5104116 },
    { lat: 47.907992, lng: 17.5110142 },
    { lat: 47.9081782, lng: 17.5111161 },
    { lat: 47.9088044, lng: 17.5123311 },
    { lat: 47.909574, lng: 17.5135302 },
    { lat: 47.9100164, lng: 17.5141921 },
    { lat: 47.910211, lng: 17.5143868 },
    { lat: 47.9102448, lng: 17.5145112 },
    { lat: 47.9108402, lng: 17.5150393 },
    { lat: 47.9113035, lng: 17.5156923 },
    { lat: 47.9120998, lng: 17.5168663 },
    { lat: 47.9133572, lng: 17.5190108 },
    { lat: 47.9139188, lng: 17.5196125 },
    { lat: 47.9153138, lng: 17.5214034 },
    { lat: 47.91745, lng: 17.524324 },
    { lat: 47.9186846, lng: 17.5261508 },
    { lat: 47.9187506, lng: 17.5271746 },
    { lat: 47.9189652, lng: 17.5281047 },
    { lat: 47.9192941, lng: 17.5289855 },
    { lat: 47.9197551, lng: 17.5296218 },
  ],
  Gabčíkovo: [
    { lat: 47.8609283, lng: 17.6225849 },
    { lat: 47.8611366, lng: 17.6223953 },
    { lat: 47.8613494, lng: 17.6221214 },
    { lat: 47.8614808, lng: 17.6221448 },
    { lat: 47.8617745, lng: 17.6223585 },
    { lat: 47.8620426, lng: 17.6224217 },
    { lat: 47.8623104, lng: 17.6222273 },
    { lat: 47.8625134, lng: 17.6217818 },
    { lat: 47.8628583, lng: 17.6214858 },
    { lat: 47.8631946, lng: 17.6212898 },
    { lat: 47.8634369, lng: 17.6212635 },
    { lat: 47.8637981, lng: 17.621404 },
    { lat: 47.864276, lng: 17.6211758 },
    { lat: 47.8648291, lng: 17.6211927 },
    { lat: 47.8651522, lng: 17.6209645 },
    { lat: 47.8653072, lng: 17.6207556 },
    { lat: 47.8656138, lng: 17.6200715 },
    { lat: 47.8659522, lng: 17.6197673 },
    { lat: 47.8667083, lng: 17.618802 },
    { lat: 47.8671479, lng: 17.6185925 },
    { lat: 47.8680138, lng: 17.6179848 },
    { lat: 47.8686196, lng: 17.6199638 },
    { lat: 47.8688351, lng: 17.6208338 },
    { lat: 47.8690976, lng: 17.6232008 },
    { lat: 47.8693104, lng: 17.6244893 },
    { lat: 47.8696042, lng: 17.6268705 },
    { lat: 47.8700301, lng: 17.6288989 },
    { lat: 47.8707956, lng: 17.6286321 },
    { lat: 47.8711545, lng: 17.6287017 },
    { lat: 47.8723275, lng: 17.6312583 },
    { lat: 47.8722346, lng: 17.6319082 },
    { lat: 47.8723554, lng: 17.6335082 },
    { lat: 47.8724819, lng: 17.6339547 },
    { lat: 47.8721861, lng: 17.6352541 },
    { lat: 47.8721092, lng: 17.6359292 },
    { lat: 47.8721447, lng: 17.6362383 },
    { lat: 47.8724735, lng: 17.6370421 },
    { lat: 47.8728808, lng: 17.6375354 },
    { lat: 47.8732109, lng: 17.6377198 },
    { lat: 47.8732621, lng: 17.6378136 },
    { lat: 47.8738549, lng: 17.6379376 },
    { lat: 47.8740058, lng: 17.637934 },
    { lat: 47.8754076, lng: 17.6373172 },
    { lat: 47.8758876, lng: 17.6372675 },
    { lat: 47.8760301, lng: 17.6373238 },
    { lat: 47.8761107, lng: 17.6374191 },
    { lat: 47.8761839, lng: 17.6376663 },
    { lat: 47.8762127, lng: 17.6378528 },
    { lat: 47.8761816, lng: 17.6380921 },
    { lat: 47.8760717, lng: 17.6384944 },
    { lat: 47.8754862, lng: 17.6399084 },
    { lat: 47.8754236, lng: 17.6403996 },
    { lat: 47.8754524, lng: 17.6407111 },
    { lat: 47.8755746, lng: 17.6414817 },
    { lat: 47.8757589, lng: 17.642 },
    { lat: 47.8765963, lng: 17.643027 },
    { lat: 47.876778, lng: 17.6440416 },
    { lat: 47.8769634, lng: 17.644738 },
    { lat: 47.8770826, lng: 17.6449791 },
    { lat: 47.8773315, lng: 17.6453258 },
    { lat: 47.8793472, lng: 17.6441026 },
    { lat: 47.8799316, lng: 17.6445758 },
    { lat: 47.880318, lng: 17.6452557 },
    { lat: 47.8804914, lng: 17.6459936 },
    { lat: 47.8804625, lng: 17.6475167 },
    { lat: 47.8802716, lng: 17.6485142 },
    { lat: 47.8800582, lng: 17.6508119 },
    { lat: 47.8804864, lng: 17.6515314 },
    { lat: 47.8808647, lng: 17.6516206 },
    { lat: 47.8813064, lng: 17.6514638 },
    { lat: 47.8830794, lng: 17.6533567 },
    { lat: 47.8843628, lng: 17.6549756 },
    { lat: 47.8852111, lng: 17.6554067 },
    { lat: 47.8859008, lng: 17.6551885 },
    { lat: 47.8861375, lng: 17.653684 },
    { lat: 47.8862707, lng: 17.6528092 },
    { lat: 47.8869396, lng: 17.6511428 },
    { lat: 47.8872221, lng: 17.6500406 },
    { lat: 47.8875048, lng: 17.6493154 },
    { lat: 47.8878679, lng: 17.64863 },
    { lat: 47.8881616, lng: 17.6482395 },
    { lat: 47.8886335, lng: 17.6477925 },
    { lat: 47.8890574, lng: 17.6474805 },
    { lat: 47.8905518, lng: 17.6466341 },
    { lat: 47.8913496, lng: 17.6463789 },
    { lat: 47.8922504, lng: 17.6462298 },
    { lat: 47.8921646, lng: 17.6453394 },
    { lat: 47.8930931, lng: 17.6441852 },
    { lat: 47.8933592, lng: 17.6439305 },
    { lat: 47.8937867, lng: 17.6436567 },
    { lat: 47.894613, lng: 17.6434568 },
    { lat: 47.8954038, lng: 17.6412037 },
    { lat: 47.8948629, lng: 17.6407399 },
    { lat: 47.8947446, lng: 17.6404423 },
    { lat: 47.894717, lng: 17.6402189 },
    { lat: 47.8947869, lng: 17.6398959 },
    { lat: 47.8950574, lng: 17.6393567 },
    { lat: 47.8952677, lng: 17.638151 },
    { lat: 47.8946084, lng: 17.6381858 },
    { lat: 47.8939004, lng: 17.6376152 },
    { lat: 47.8937439, lng: 17.6360209 },
    { lat: 47.8937623, lng: 17.6355131 },
    { lat: 47.8940506, lng: 17.6339683 },
    { lat: 47.8946114, lng: 17.6322912 },
    { lat: 47.8947929, lng: 17.6310579 },
    { lat: 47.8941821, lng: 17.6303148 },
    { lat: 47.8945417, lng: 17.6293453 },
    { lat: 47.8949267, lng: 17.6286675 },
    { lat: 47.8953465, lng: 17.6275984 },
    { lat: 47.8953964, lng: 17.6257893 },
    { lat: 47.8966732, lng: 17.6259086 },
    { lat: 47.8974859, lng: 17.6251917 },
    { lat: 47.8980987, lng: 17.6242457 },
    { lat: 47.8985668, lng: 17.62296 },
    { lat: 47.9006369, lng: 17.6193417 },
    { lat: 47.9008209, lng: 17.6191607 },
    { lat: 47.9013063, lng: 17.6176859 },
    { lat: 47.9026117, lng: 17.6149187 },
    { lat: 47.9036745, lng: 17.612441 },
    { lat: 47.9020055, lng: 17.6094953 },
    { lat: 47.9026128, lng: 17.6089597 },
    { lat: 47.9041057, lng: 17.6089595 },
    { lat: 47.9042496, lng: 17.6084546 },
    { lat: 47.9057473, lng: 17.608194 },
    { lat: 47.9066681, lng: 17.6076202 },
    { lat: 47.9061439, lng: 17.6065338 },
    { lat: 47.9066154, lng: 17.6050164 },
    { lat: 47.9067897, lng: 17.6046884 },
    { lat: 47.9079295, lng: 17.604481 },
    { lat: 47.9082086, lng: 17.6043449 },
    { lat: 47.9086681, lng: 17.6036874 },
    { lat: 47.9092338, lng: 17.6030674 },
    { lat: 47.9096219, lng: 17.6024394 },
    { lat: 47.9096904, lng: 17.6020451 },
    { lat: 47.9100917, lng: 17.6011125 },
    { lat: 47.9102944, lng: 17.6002913 },
    { lat: 47.9103122, lng: 17.6000351 },
    { lat: 47.9101598, lng: 17.5988005 },
    { lat: 47.9098577, lng: 17.598293 },
    { lat: 47.9097491, lng: 17.5977959 },
    { lat: 47.9096678, lng: 17.5970137 },
    { lat: 47.9096471, lng: 17.5955775 },
    { lat: 47.9094743, lng: 17.5951676 },
    { lat: 47.9093563, lng: 17.5951769 },
    { lat: 47.9090441, lng: 17.5956271 },
    { lat: 47.9088819, lng: 17.5957074 },
    { lat: 47.9086546, lng: 17.5956124 },
    { lat: 47.9087424, lng: 17.595323 },
    { lat: 47.9092012, lng: 17.5944805 },
    { lat: 47.9095025, lng: 17.5941047 },
    { lat: 47.9097601, lng: 17.5939324 },
    { lat: 47.9102873, lng: 17.5937352 },
    { lat: 47.9104991, lng: 17.593744 },
    { lat: 47.9108265, lng: 17.5938312 },
    { lat: 47.9111567, lng: 17.5940147 },
    { lat: 47.911563, lng: 17.594385 },
    { lat: 47.9124318, lng: 17.59503 },
    { lat: 47.9127664, lng: 17.5949254 },
    { lat: 47.9134312, lng: 17.5945345 },
    { lat: 47.9136966, lng: 17.5940922 },
    { lat: 47.9134852, lng: 17.5926699 },
    { lat: 47.9137518, lng: 17.5874536 },
    { lat: 47.9147329, lng: 17.5847476 },
    { lat: 47.9139288, lng: 17.5838796 },
    { lat: 47.9139471, lng: 17.5833001 },
    { lat: 47.9137559, lng: 17.5813518 },
    { lat: 47.9138176, lng: 17.580762 },
    { lat: 47.9139911, lng: 17.5798748 },
    { lat: 47.9141026, lng: 17.5788622 },
    { lat: 47.9142527, lng: 17.5779969 },
    { lat: 47.9144594, lng: 17.577414 },
    { lat: 47.9149787, lng: 17.5765291 },
    { lat: 47.9154839, lng: 17.5759001 },
    { lat: 47.9159607, lng: 17.5765918 },
    { lat: 47.9166469, lng: 17.5754222 },
    { lat: 47.9163192, lng: 17.5741827 },
    { lat: 47.9155846, lng: 17.5733268 },
    { lat: 47.9150245, lng: 17.5719144 },
    { lat: 47.9150853, lng: 17.5711539 },
    { lat: 47.916423, lng: 17.5695568 },
    { lat: 47.91703, lng: 17.5686672 },
    { lat: 47.9173007, lng: 17.5679385 },
    { lat: 47.9177069, lng: 17.5661188 },
    { lat: 47.9173649, lng: 17.5645336 },
    { lat: 47.9169133, lng: 17.5627925 },
    { lat: 47.9161909, lng: 17.5584115 },
    { lat: 47.9171853, lng: 17.5555155 },
    { lat: 47.9184233, lng: 17.5555556 },
    { lat: 47.92009, lng: 17.555062 },
    { lat: 47.9212097, lng: 17.5541722 },
    { lat: 47.9210789, lng: 17.5532441 },
    { lat: 47.9206489, lng: 17.5510303 },
    { lat: 47.9174788, lng: 17.5500922 },
    { lat: 47.9158638, lng: 17.5497028 },
    { lat: 47.9142778, lng: 17.5487648 },
    { lat: 47.9123283, lng: 17.5484764 },
    { lat: 47.9108496, lng: 17.5490142 },
    { lat: 47.9078473, lng: 17.5514461 },
    { lat: 47.9045761, lng: 17.5528224 },
    { lat: 47.9042739, lng: 17.5527436 },
    { lat: 47.9041581, lng: 17.5530489 },
    { lat: 47.9037771, lng: 17.553727 },
    { lat: 47.9035948, lng: 17.5539499 },
    { lat: 47.9031216, lng: 17.5541431 },
    { lat: 47.9027068, lng: 17.5540855 },
    { lat: 47.9021119, lng: 17.5541403 },
    { lat: 47.9017875, lng: 17.5542578 },
    { lat: 47.9016049, lng: 17.5545473 },
    { lat: 47.9013281, lng: 17.5555656 },
    { lat: 47.8992835, lng: 17.5550087 },
    { lat: 47.8974965, lng: 17.5531355 },
    { lat: 47.8974257, lng: 17.5529995 },
    { lat: 47.8967281, lng: 17.5531288 },
    { lat: 47.8957283, lng: 17.5531764 },
    { lat: 47.8957399, lng: 17.5530724 },
    { lat: 47.8939087, lng: 17.5525691 },
    { lat: 47.8921964, lng: 17.550737 },
    { lat: 47.8909575, lng: 17.5496973 },
    { lat: 47.8902496, lng: 17.5482775 },
    { lat: 47.8880271, lng: 17.5452502 },
    { lat: 47.8869696, lng: 17.5435573 },
    { lat: 47.8863842, lng: 17.5418818 },
    { lat: 47.8862734, lng: 17.5413332 },
    { lat: 47.8859117, lng: 17.5401357 },
    { lat: 47.8848854, lng: 17.5369279 },
    { lat: 47.8839994, lng: 17.5343209 },
    { lat: 47.8839274, lng: 17.5343051 },
    { lat: 47.883826, lng: 17.5349063 },
    { lat: 47.8837275, lng: 17.5350783 },
    { lat: 47.8830667, lng: 17.5356538 },
    { lat: 47.8822919, lng: 17.5365651 },
    { lat: 47.8819161, lng: 17.5367458 },
    { lat: 47.8817602, lng: 17.5369186 },
    { lat: 47.8815524, lng: 17.5374114 },
    { lat: 47.881021, lng: 17.5376808 },
    { lat: 47.8802878, lng: 17.5382381 },
    { lat: 47.879651, lng: 17.5385218 },
    { lat: 47.8794698, lng: 17.5387255 },
    { lat: 47.8773518, lng: 17.5362042 },
    { lat: 47.8746772, lng: 17.5331443 },
    { lat: 47.8733026, lng: 17.5314736 },
    { lat: 47.8733303, lng: 17.530449 },
    { lat: 47.8725815, lng: 17.5297129 },
    { lat: 47.8722557, lng: 17.5293171 },
    { lat: 47.8708092, lng: 17.5280596 },
    { lat: 47.8703002, lng: 17.5284482 },
    { lat: 47.8694434, lng: 17.5288571 },
    { lat: 47.8696213, lng: 17.5274472 },
    { lat: 47.869373, lng: 17.526311 },
    { lat: 47.8654259, lng: 17.5268369 },
    { lat: 47.864778, lng: 17.527616 },
    { lat: 47.863978, lng: 17.528346 },
    { lat: 47.86234, lng: 17.529639 },
    { lat: 47.860258, lng: 17.531403 },
    { lat: 47.858917, lng: 17.532763 },
    { lat: 47.857464, lng: 17.534524 },
    { lat: 47.8569964, lng: 17.5351352 },
    { lat: 47.856671, lng: 17.535598 },
    { lat: 47.856099, lng: 17.536623 },
    { lat: 47.855638, lng: 17.537767 },
    { lat: 47.855134, lng: 17.539431 },
    { lat: 47.854929, lng: 17.539925 },
    { lat: 47.854446, lng: 17.540838 },
    { lat: 47.854174, lng: 17.541256 },
    { lat: 47.853571, lng: 17.541995 },
    { lat: 47.852354, lng: 17.543079 },
    { lat: 47.851807, lng: 17.543323 },
    { lat: 47.851524, lng: 17.543379 },
    { lat: 47.849962, lng: 17.543505 },
    { lat: 47.849372, lng: 17.543348 },
    { lat: 47.847965, lng: 17.542714 },
    { lat: 47.847546, lng: 17.542601 },
    { lat: 47.846698, lng: 17.54253 },
    { lat: 47.845854, lng: 17.542665 },
    { lat: 47.843595, lng: 17.54335 },
    { lat: 47.842858, lng: 17.543675 },
    { lat: 47.842163, lng: 17.544158 },
    { lat: 47.841523, lng: 17.544789 },
    { lat: 47.84031, lng: 17.546335 },
    { lat: 47.839591, lng: 17.546953 },
    { lat: 47.839209, lng: 17.547195 },
    { lat: 47.836527, lng: 17.548438 },
    { lat: 47.834814, lng: 17.548975 },
    { lat: 47.83345, lng: 17.54912 },
    { lat: 47.832794, lng: 17.549283 },
    { lat: 47.832155, lng: 17.549571 },
    { lat: 47.831552, lng: 17.549969 },
    { lat: 47.830991, lng: 17.55046 },
    { lat: 47.830469, lng: 17.55104 },
    { lat: 47.826398, lng: 17.556754 },
    { lat: 47.825468, lng: 17.557887 },
    { lat: 47.824423, lng: 17.558774 },
    { lat: 47.821289, lng: 17.560463 },
    { lat: 47.8208125, lng: 17.5608245 },
    { lat: 47.8217425, lng: 17.5639411 },
    { lat: 47.8281491, lng: 17.5646878 },
    { lat: 47.8350936, lng: 17.565616 },
    { lat: 47.8381413, lng: 17.560544 },
    { lat: 47.8417868, lng: 17.5628564 },
    { lat: 47.8399284, lng: 17.5672438 },
    { lat: 47.842102, lng: 17.5716273 },
    { lat: 47.8425089, lng: 17.5725342 },
    { lat: 47.8425649, lng: 17.5730528 },
    { lat: 47.8425586, lng: 17.5739068 },
    { lat: 47.8425292, lng: 17.5742572 },
    { lat: 47.8433823, lng: 17.573615 },
    { lat: 47.8438996, lng: 17.5738297 },
    { lat: 47.8443967, lng: 17.5741837 },
    { lat: 47.844628, lng: 17.5747282 },
    { lat: 47.8447065, lng: 17.575071 },
    { lat: 47.844292, lng: 17.5748769 },
    { lat: 47.8430398, lng: 17.5754609 },
    { lat: 47.8428429, lng: 17.5759106 },
    { lat: 47.8424801, lng: 17.5762065 },
    { lat: 47.8410626, lng: 17.5765888 },
    { lat: 47.8408107, lng: 17.5767629 },
    { lat: 47.8402255, lng: 17.5774839 },
    { lat: 47.8395891, lng: 17.5780736 },
    { lat: 47.8394599, lng: 17.5777664 },
    { lat: 47.8388278, lng: 17.5778951 },
    { lat: 47.8383916, lng: 17.5776239 },
    { lat: 47.8379461, lng: 17.580456 },
    { lat: 47.837473, lng: 17.581955 },
    { lat: 47.8372705, lng: 17.5823737 },
    { lat: 47.8370998, lng: 17.5828789 },
    { lat: 47.8369161, lng: 17.5835902 },
    { lat: 47.8366197, lng: 17.5842172 },
    { lat: 47.8374017, lng: 17.5849865 },
    { lat: 47.8380017, lng: 17.5857875 },
    { lat: 47.8385688, lng: 17.5870258 },
    { lat: 47.838876, lng: 17.5880516 },
    { lat: 47.8394557, lng: 17.5895605 },
    { lat: 47.8396121, lng: 17.5901342 },
    { lat: 47.8397107, lng: 17.5905814 },
    { lat: 47.8397306, lng: 17.5909441 },
    { lat: 47.839669, lng: 17.591305 },
    { lat: 47.8395099, lng: 17.591804 },
    { lat: 47.8389356, lng: 17.5931703 },
    { lat: 47.838989, lng: 17.5932091 },
    { lat: 47.8395876, lng: 17.5936379 },
    { lat: 47.8411164, lng: 17.5941309 },
    { lat: 47.8417177, lng: 17.5944043 },
    { lat: 47.8422355, lng: 17.5949612 },
    { lat: 47.8425756, lng: 17.5951414 },
    { lat: 47.8430969, lng: 17.5952598 },
    { lat: 47.8438737, lng: 17.5958073 },
    { lat: 47.844409, lng: 17.5963564 },
    { lat: 47.8445875, lng: 17.5966216 },
    { lat: 47.8446398, lng: 17.5968213 },
    { lat: 47.8446946, lng: 17.5973271 },
    { lat: 47.8449616, lng: 17.5987854 },
    { lat: 47.8452451, lng: 17.5991381 },
    { lat: 47.8454375, lng: 17.5995353 },
    { lat: 47.8457198, lng: 17.6005103 },
    { lat: 47.8458097, lng: 17.6004467 },
    { lat: 47.8459575, lng: 17.6004635 },
    { lat: 47.846408, lng: 17.6010637 },
    { lat: 47.8470957, lng: 17.6017922 },
    { lat: 47.8471592, lng: 17.6018124 },
    { lat: 47.8476962, lng: 17.6014165 },
    { lat: 47.8478888, lng: 17.6014421 },
    { lat: 47.8479654, lng: 17.6015118 },
    { lat: 47.8482091, lng: 17.6019496 },
    { lat: 47.8483698, lng: 17.6025773 },
    { lat: 47.8482124, lng: 17.6028501 },
    { lat: 47.8481886, lng: 17.602955 },
    { lat: 47.8482255, lng: 17.6030739 },
    { lat: 47.8486874, lng: 17.6037116 },
    { lat: 47.8492012, lng: 17.6042073 },
    { lat: 47.8492548, lng: 17.6043583 },
    { lat: 47.8493031, lng: 17.6048848 },
    { lat: 47.8493915, lng: 17.6051904 },
    { lat: 47.8496145, lng: 17.6055011 },
    { lat: 47.8499646, lng: 17.6057657 },
    { lat: 47.8505103, lng: 17.6064988 },
    { lat: 47.8506945, lng: 17.607349 },
    { lat: 47.8509645, lng: 17.6075648 },
    { lat: 47.8512975, lng: 17.6081127 },
    { lat: 47.8513967, lng: 17.6083951 },
    { lat: 47.8514246, lng: 17.6090619 },
    { lat: 47.8516368, lng: 17.6095372 },
    { lat: 47.8517433, lng: 17.6098794 },
    { lat: 47.8517836, lng: 17.6101919 },
    { lat: 47.851817, lng: 17.6110903 },
    { lat: 47.8514818, lng: 17.611483 },
    { lat: 47.8513773, lng: 17.6117729 },
    { lat: 47.8513359, lng: 17.6121528 },
    { lat: 47.8513797, lng: 17.6126616 },
    { lat: 47.8514507, lng: 17.6127842 },
    { lat: 47.8518058, lng: 17.6129164 },
    { lat: 47.8518577, lng: 17.613004 },
    { lat: 47.8518775, lng: 17.6132803 },
    { lat: 47.8516764, lng: 17.6140577 },
    { lat: 47.8516413, lng: 17.6144652 },
    { lat: 47.8514977, lng: 17.6149223 },
    { lat: 47.851315, lng: 17.6160244 },
    { lat: 47.8509384, lng: 17.6167952 },
    { lat: 47.8509646, lng: 17.6169947 },
    { lat: 47.851032, lng: 17.6170766 },
    { lat: 47.8515793, lng: 17.6169125 },
    { lat: 47.8516442, lng: 17.6169737 },
    { lat: 47.8515867, lng: 17.6174532 },
    { lat: 47.8516635, lng: 17.6185184 },
    { lat: 47.851786, lng: 17.6192194 },
    { lat: 47.8538968, lng: 17.6188691 },
    { lat: 47.855582, lng: 17.6183823 },
    { lat: 47.8569207, lng: 17.617664 },
    { lat: 47.86012, lng: 17.6203495 },
    { lat: 47.8609283, lng: 17.6225849 },
  ],
  Trnávka: [
    { lat: 48.0021854, lng: 17.409328 },
    { lat: 48.0022183, lng: 17.4093628 },
    { lat: 48.0149402, lng: 17.424223 },
    { lat: 48.0157917, lng: 17.4222992 },
    { lat: 48.0162019, lng: 17.4225799 },
    { lat: 48.0165401, lng: 17.4214433 },
    { lat: 48.0171871, lng: 17.4187758 },
    { lat: 48.0187461, lng: 17.4161129 },
    { lat: 48.0263356, lng: 17.4269331 },
    { lat: 48.0272899, lng: 17.4270712 },
    { lat: 48.0278306, lng: 17.427392 },
    { lat: 48.0290011, lng: 17.4285467 },
    { lat: 48.0290193, lng: 17.4285278 },
    { lat: 48.0293082, lng: 17.4278329 },
    { lat: 48.0296543, lng: 17.4268163 },
    { lat: 48.0301067, lng: 17.4251806 },
    { lat: 48.0300838, lng: 17.4251561 },
    { lat: 48.0302243, lng: 17.4247846 },
    { lat: 48.0310924, lng: 17.422751 },
    { lat: 48.0315123, lng: 17.4219491 },
    { lat: 48.0317329, lng: 17.4210198 },
    { lat: 48.0318117, lng: 17.4203736 },
    { lat: 48.0317202, lng: 17.4199405 },
    { lat: 48.0314134, lng: 17.4195471 },
    { lat: 48.0316234, lng: 17.4175551 },
    { lat: 48.0317164, lng: 17.4169396 },
    { lat: 48.0322407, lng: 17.4160071 },
    { lat: 48.0323567, lng: 17.4151374 },
    { lat: 48.0323448, lng: 17.4143307 },
    { lat: 48.0322936, lng: 17.4139638 },
    { lat: 48.0328627, lng: 17.4110367 },
    { lat: 48.0329176, lng: 17.4104595 },
    { lat: 48.0335757, lng: 17.4099684 },
    { lat: 48.0339053, lng: 17.4095839 },
    { lat: 48.033484, lng: 17.4085833 },
    { lat: 48.0330864, lng: 17.4079419 },
    { lat: 48.0323418, lng: 17.4075903 },
    { lat: 48.0319125, lng: 17.4071568 },
    { lat: 48.0301282, lng: 17.4042233 },
    { lat: 48.0289571, lng: 17.4005988 },
    { lat: 48.0285958, lng: 17.3999418 },
    { lat: 48.0279195, lng: 17.3991879 },
    { lat: 48.0274766, lng: 17.3980474 },
    { lat: 48.0273582, lng: 17.3969609 },
    { lat: 48.0265791, lng: 17.3942017 },
    { lat: 48.0256448, lng: 17.3925269 },
    { lat: 48.0248614, lng: 17.3912719 },
    { lat: 48.0239286, lng: 17.390292 },
    { lat: 48.0230391, lng: 17.3889407 },
    { lat: 48.0218846, lng: 17.3874564 },
    { lat: 48.0211656, lng: 17.386532 },
    { lat: 48.0205069, lng: 17.3854241 },
    { lat: 48.0203633, lng: 17.3851826 },
    { lat: 48.0195966, lng: 17.3840122 },
    { lat: 48.0190736, lng: 17.3829947 },
    { lat: 48.0182296, lng: 17.3819588 },
    { lat: 48.0181647, lng: 17.3817266 },
    { lat: 48.0175013, lng: 17.3816701 },
    { lat: 48.0169816, lng: 17.3814428 },
    { lat: 48.0159698, lng: 17.3815168 },
    { lat: 48.0149821, lng: 17.3813351 },
    { lat: 48.012593, lng: 17.3817438 },
    { lat: 48.0116356, lng: 17.3831481 },
    { lat: 48.0114222, lng: 17.3839754 },
    { lat: 48.0110888, lng: 17.3846458 },
    { lat: 48.0104951, lng: 17.3838931 },
    { lat: 48.009805, lng: 17.3837537 },
    { lat: 48.0081999, lng: 17.3828422 },
    { lat: 48.0080137, lng: 17.3827053 },
    { lat: 48.0075889, lng: 17.3821873 },
    { lat: 48.0064674, lng: 17.3845645 },
    { lat: 48.0060971, lng: 17.3840354 },
    { lat: 48.0060462, lng: 17.3840679 },
    { lat: 48.0059978, lng: 17.3840012 },
    { lat: 48.0052393, lng: 17.3861968 },
    { lat: 48.0043406, lng: 17.3884112 },
    { lat: 48.0035858, lng: 17.3898196 },
    { lat: 48.0031482, lng: 17.390448 },
    { lat: 48.0015969, lng: 17.3929758 },
    { lat: 48.000994, lng: 17.3942047 },
    { lat: 48.0009573, lng: 17.394161 },
    { lat: 48.000938, lng: 17.3942128 },
    { lat: 47.9991387, lng: 17.3989195 },
    { lat: 48.001273, lng: 17.4010195 },
    { lat: 48.0019387, lng: 17.4022009 },
    { lat: 48.0018347, lng: 17.4040639 },
    { lat: 48.0019904, lng: 17.4039084 },
    { lat: 48.0024936, lng: 17.403604 },
    { lat: 48.0041485, lng: 17.4028487 },
    { lat: 48.004553, lng: 17.4025738 },
    { lat: 48.0047729, lng: 17.4031242 },
    { lat: 48.0047811, lng: 17.4035236 },
    { lat: 48.004692, lng: 17.4040614 },
    { lat: 48.0047078, lng: 17.4043352 },
    { lat: 48.0045278, lng: 17.4054712 },
    { lat: 48.0043153, lng: 17.4063513 },
    { lat: 48.0035623, lng: 17.4069604 },
    { lat: 48.0033465, lng: 17.4074614 },
    { lat: 48.0024356, lng: 17.4087126 },
    { lat: 48.0021854, lng: 17.409328 },
  ],
  Holice: [
    { lat: 47.9943968, lng: 17.5184536 },
    { lat: 47.994501, lng: 17.5182674 },
    { lat: 47.9947729, lng: 17.5177522 },
    { lat: 47.9950546, lng: 17.5162171 },
    { lat: 47.9957268, lng: 17.5144847 },
    { lat: 47.9965172, lng: 17.5144299 },
    { lat: 47.9965341, lng: 17.5136602 },
    { lat: 47.9966545, lng: 17.5128479 },
    { lat: 47.9970946, lng: 17.5123139 },
    { lat: 47.9974851, lng: 17.5120645 },
    { lat: 47.9976783, lng: 17.5112065 },
    { lat: 47.9981577, lng: 17.5115519 },
    { lat: 47.9981791, lng: 17.5109868 },
    { lat: 47.9986467, lng: 17.5109358 },
    { lat: 47.9987611, lng: 17.5098916 },
    { lat: 47.9990915, lng: 17.509252 },
    { lat: 47.9991538, lng: 17.5082402 },
    { lat: 47.9995041, lng: 17.5072106 },
    { lat: 48.0002146, lng: 17.5066343 },
    { lat: 48.0012036, lng: 17.5061721 },
    { lat: 48.0009806, lng: 17.5060901 },
    { lat: 48.0010577, lng: 17.5060199 },
    { lat: 48.0018524, lng: 17.5048178 },
    { lat: 48.0027087, lng: 17.5042241 },
    { lat: 48.0034256, lng: 17.5039012 },
    { lat: 48.003589, lng: 17.5038682 },
    { lat: 48.0029981, lng: 17.5031793 },
    { lat: 48.0025966, lng: 17.5036419 },
    { lat: 47.9983289, lng: 17.502456 },
    { lat: 47.9982043, lng: 17.5010537 },
    { lat: 47.9981228, lng: 17.5010323 },
    { lat: 47.9980385, lng: 17.4999007 },
    { lat: 47.9980164, lng: 17.4982608 },
    { lat: 47.9979206, lng: 17.4972811 },
    { lat: 47.9978621, lng: 17.4968199 },
    { lat: 47.9978535, lng: 17.4966451 },
    { lat: 47.9978861, lng: 17.49632 },
    { lat: 47.9979398, lng: 17.4960894 },
    { lat: 47.9979829, lng: 17.4959963 },
    { lat: 47.9980318, lng: 17.4958946 },
    { lat: 47.9980931, lng: 17.4957829 },
    { lat: 47.998189, lng: 17.4956282 },
    { lat: 47.9982934, lng: 17.4954936 },
    { lat: 47.9985656, lng: 17.4950825 },
    { lat: 47.9986327, lng: 17.4949722 },
    { lat: 47.9987957, lng: 17.4946385 },
    { lat: 47.9992461, lng: 17.4937103 },
    { lat: 47.9994953, lng: 17.4930945 },
    { lat: 47.9996007, lng: 17.4927765 },
    { lat: 47.9998076, lng: 17.4920629 },
    { lat: 47.9999787, lng: 17.4918528 },
    { lat: 48.0054135, lng: 17.4941838 },
    { lat: 48.0052215, lng: 17.4953004 },
    { lat: 48.0082018, lng: 17.4997274 },
    { lat: 48.0086236, lng: 17.5003984 },
    { lat: 48.009668, lng: 17.4989114 },
    { lat: 48.0099091, lng: 17.4985143 },
    { lat: 48.0102392, lng: 17.4977507 },
    { lat: 48.0102973, lng: 17.4976178 },
    { lat: 48.0106202, lng: 17.4955521 },
    { lat: 48.0107842, lng: 17.4942621 },
    { lat: 48.0107788, lng: 17.4942078 },
    { lat: 48.0107383, lng: 17.4939263 },
    { lat: 48.0134127, lng: 17.4925056 },
    { lat: 48.0134586, lng: 17.4924796 },
    { lat: 48.0172908, lng: 17.4944789 },
    { lat: 48.0180886, lng: 17.4945564 },
    { lat: 48.0182498, lng: 17.4942186 },
    { lat: 48.0180438, lng: 17.4935134 },
    { lat: 48.0179714, lng: 17.4928991 },
    { lat: 48.0181975, lng: 17.4888991 },
    { lat: 48.0184692, lng: 17.4862343 },
    { lat: 48.0185597, lng: 17.4857666 },
    { lat: 48.0187394, lng: 17.4852081 },
    { lat: 48.0189856, lng: 17.484704 },
    { lat: 48.019404, lng: 17.4841691 },
    { lat: 48.0207656, lng: 17.4827473 },
    { lat: 48.0210339, lng: 17.4825824 },
    { lat: 48.0216576, lng: 17.4824152 },
    { lat: 48.0221173, lng: 17.4824134 },
    { lat: 48.0221386, lng: 17.4822014 },
    { lat: 48.0214116, lng: 17.4795198 },
    { lat: 48.0216764, lng: 17.4793512 },
    { lat: 48.0206114, lng: 17.4759312 },
    { lat: 48.0205532, lng: 17.4753462 },
    { lat: 48.0205982, lng: 17.4744889 },
    { lat: 48.0206884, lng: 17.473667 },
    { lat: 48.0210242, lng: 17.4709687 },
    { lat: 48.0203842, lng: 17.4691277 },
    { lat: 48.0214469, lng: 17.467558 },
    { lat: 48.0234956, lng: 17.4639161 },
    { lat: 48.0241815, lng: 17.4631396 },
    { lat: 48.024427, lng: 17.4627793 },
    { lat: 48.0243142, lng: 17.4624487 },
    { lat: 48.0259733, lng: 17.4590714 },
    { lat: 48.0246387, lng: 17.4582753 },
    { lat: 48.0239015, lng: 17.4577437 },
    { lat: 48.0228226, lng: 17.4567443 },
    { lat: 48.0217259, lng: 17.4554388 },
    { lat: 48.0201599, lng: 17.4581788 },
    { lat: 48.0198822, lng: 17.4585815 },
    { lat: 48.0197544, lng: 17.458717 },
    { lat: 48.0177018, lng: 17.4559602 },
    { lat: 48.0176716, lng: 17.4559141 },
    { lat: 48.0154019, lng: 17.4580062 },
    { lat: 48.0147117, lng: 17.4585628 },
    { lat: 48.0140507, lng: 17.4571287 },
    { lat: 48.0098948, lng: 17.4610671 },
    { lat: 48.00621, lng: 17.4580124 },
    { lat: 48.0060507, lng: 17.4578538 },
    { lat: 48.005872, lng: 17.4575371 },
    { lat: 48.005559, lng: 17.4573424 },
    { lat: 48.0055334, lng: 17.4578878 },
    { lat: 48.004776, lng: 17.4591667 },
    { lat: 48.003609, lng: 17.4609867 },
    { lat: 48.0029683, lng: 17.4617663 },
    { lat: 48.0023199, lng: 17.4624789 },
    { lat: 48.0009075, lng: 17.4620667 },
    { lat: 48.000784, lng: 17.4620263 },
    { lat: 48.0001387, lng: 17.46167 },
    { lat: 47.9992026, lng: 17.4614649 },
    { lat: 47.9987528, lng: 17.4611316 },
    { lat: 47.9991325, lng: 17.4593748 },
    { lat: 47.9992087, lng: 17.4583063 },
    { lat: 48.0000318, lng: 17.4580583 },
    { lat: 48.0000105, lng: 17.455521 },
    { lat: 47.9998632, lng: 17.4532537 },
    { lat: 47.9996106, lng: 17.451295 },
    { lat: 47.9989248, lng: 17.4507496 },
    { lat: 47.997952, lng: 17.4507637 },
    { lat: 47.9973139, lng: 17.4501717 },
    { lat: 47.9966, lng: 17.4510924 },
    { lat: 47.9956618, lng: 17.4494122 },
    { lat: 47.9949936, lng: 17.4483729 },
    { lat: 47.9922381, lng: 17.4503941 },
    { lat: 47.9908195, lng: 17.4498188 },
    { lat: 47.9903671, lng: 17.4501584 },
    { lat: 47.9900143, lng: 17.4507251 },
    { lat: 47.9893791, lng: 17.4517411 },
    { lat: 47.9888483, lng: 17.4531693 },
    { lat: 47.9883185, lng: 17.4524341 },
    { lat: 47.987734, lng: 17.4513321 },
    { lat: 47.987482, lng: 17.4510716 },
    { lat: 47.9857045, lng: 17.4498381 },
    { lat: 47.9849441, lng: 17.4492086 },
    { lat: 47.9838265, lng: 17.4485378 },
    { lat: 47.9815483, lng: 17.4485376 },
    { lat: 47.9782586, lng: 17.4498062 },
    { lat: 47.9779558, lng: 17.4502753 },
    { lat: 47.9775815, lng: 17.451362 },
    { lat: 47.9772464, lng: 17.4519764 },
    { lat: 47.9769122, lng: 17.4523947 },
    { lat: 47.9761029, lng: 17.4531144 },
    { lat: 47.9759734, lng: 17.4531539 },
    { lat: 47.9756703, lng: 17.4530961 },
    { lat: 47.9749707, lng: 17.4528628 },
    { lat: 47.9744589, lng: 17.4521143 },
    { lat: 47.9726943, lng: 17.4491696 },
    { lat: 47.9723693, lng: 17.4485648 },
    { lat: 47.9716753, lng: 17.4469947 },
    { lat: 47.9714492, lng: 17.4466262 },
    { lat: 47.9711765, lng: 17.4464975 },
    { lat: 47.9701651, lng: 17.4490071 },
    { lat: 47.9685221, lng: 17.4534754 },
    { lat: 47.9672253, lng: 17.4564783 },
    { lat: 47.9667831, lng: 17.4579992 },
    { lat: 47.9664496, lng: 17.4595262 },
    { lat: 47.9663432, lng: 17.4602343 },
    { lat: 47.9659854, lng: 17.4601833 },
    { lat: 47.965804, lng: 17.4601573 },
    { lat: 47.965769, lng: 17.4602775 },
    { lat: 47.9650657, lng: 17.4621259 },
    { lat: 47.9646566, lng: 17.4634442 },
    { lat: 47.9644088, lng: 17.4639062 },
    { lat: 47.9626462, lng: 17.4667663 },
    { lat: 47.9606593, lng: 17.4697736 },
    { lat: 47.959563, lng: 17.4713131 },
    { lat: 47.9591653, lng: 17.4712234 },
    { lat: 47.9584453, lng: 17.4725357 },
    { lat: 47.9582035, lng: 17.4722562 },
    { lat: 47.9577959, lng: 17.4732847 },
    { lat: 47.9571855, lng: 17.4725638 },
    { lat: 47.9560792, lng: 17.4743283 },
    { lat: 47.9557207, lng: 17.4739757 },
    { lat: 47.9555318, lng: 17.4741783 },
    { lat: 47.9543364, lng: 17.4750941 },
    { lat: 47.9521754, lng: 17.4759583 },
    { lat: 47.9519928, lng: 17.4761216 },
    { lat: 47.9503778, lng: 17.4780187 },
    { lat: 47.9497681, lng: 17.4786315 },
    { lat: 47.9494988, lng: 17.4790231 },
    { lat: 47.9490257, lng: 17.4795493 },
    { lat: 47.9487514, lng: 17.4799684 },
    { lat: 47.9478192, lng: 17.4817078 },
    { lat: 47.9478573, lng: 17.4819267 },
    { lat: 47.9474109, lng: 17.4824991 },
    { lat: 47.9438701, lng: 17.4869598 },
    { lat: 47.9439781, lng: 17.4870746 },
    { lat: 47.9473709, lng: 17.4888602 },
    { lat: 47.9487331, lng: 17.4895085 },
    { lat: 47.9501555, lng: 17.4901092 },
    { lat: 47.9515649, lng: 17.4903197 },
    { lat: 47.9526249, lng: 17.4896661 },
    { lat: 47.9533515, lng: 17.4889937 },
    { lat: 47.953641, lng: 17.4886478 },
    { lat: 47.9538536, lng: 17.4878145 },
    { lat: 47.9542223, lng: 17.4870204 },
    { lat: 47.9546988, lng: 17.4867738 },
    { lat: 47.9551319, lng: 17.4861191 },
    { lat: 47.955709, lng: 17.485895 },
    { lat: 47.9561887, lng: 17.4849299 },
    { lat: 47.9567484, lng: 17.4845016 },
    { lat: 47.9588914, lng: 17.4820868 },
    { lat: 47.9627208, lng: 17.4834297 },
    { lat: 47.9653782, lng: 17.4838648 },
    { lat: 47.9673531, lng: 17.4846618 },
    { lat: 47.968154, lng: 17.4851893 },
    { lat: 47.970019, lng: 17.4875323 },
    { lat: 47.9708976, lng: 17.4888265 },
    { lat: 47.9707792, lng: 17.4892463 },
    { lat: 47.9707309, lng: 17.4898967 },
    { lat: 47.9709067, lng: 17.4906937 },
    { lat: 47.9713168, lng: 17.4911881 },
    { lat: 47.9729728, lng: 17.4921708 },
    { lat: 47.9735306, lng: 17.4926755 },
    { lat: 47.9741207, lng: 17.4934174 },
    { lat: 47.9751974, lng: 17.4936172 },
    { lat: 47.9755366, lng: 17.4936225 },
    { lat: 47.9758961, lng: 17.4937871 },
    { lat: 47.9767734, lng: 17.4936959 },
    { lat: 47.9776635, lng: 17.4946246 },
    { lat: 47.9784085, lng: 17.4975916 },
    { lat: 47.9784325, lng: 17.4978236 },
    { lat: 47.9783632, lng: 17.4979192 },
    { lat: 47.9791303, lng: 17.499186 },
    { lat: 47.9797418, lng: 17.500435 },
    { lat: 47.9801533, lng: 17.5010362 },
    { lat: 47.9805295, lng: 17.5022118 },
    { lat: 47.980647, lng: 17.5024371 },
    { lat: 47.9802251, lng: 17.5044572 },
    { lat: 47.9801019, lng: 17.5055774 },
    { lat: 47.9802702, lng: 17.5055935 },
    { lat: 47.9803104, lng: 17.5057284 },
    { lat: 47.9804178, lng: 17.5056954 },
    { lat: 47.9814042, lng: 17.505703 },
    { lat: 47.9819152, lng: 17.5060091 },
    { lat: 47.9824007, lng: 17.5066016 },
    { lat: 47.9831162, lng: 17.5085373 },
    { lat: 47.9837285, lng: 17.5098004 },
    { lat: 47.9838309, lng: 17.5099024 },
    { lat: 47.9852438, lng: 17.5105492 },
    { lat: 47.9867662, lng: 17.5109546 },
    { lat: 47.9873205, lng: 17.5117038 },
    { lat: 47.9878346, lng: 17.512759 },
    { lat: 47.9881711, lng: 17.5133208 },
    { lat: 47.9884731, lng: 17.5136773 },
    { lat: 47.9892366, lng: 17.5143437 },
    { lat: 47.9911887, lng: 17.5156183 },
    { lat: 47.99182, lng: 17.5161788 },
    { lat: 47.9920799, lng: 17.5166215 },
    { lat: 47.992223, lng: 17.516668 },
    { lat: 47.9924039, lng: 17.5166178 },
    { lat: 47.9931863, lng: 17.5158767 },
    { lat: 47.9934993, lng: 17.5168089 },
    { lat: 47.9936541, lng: 17.5170921 },
    { lat: 47.9939131, lng: 17.5173805 },
    { lat: 47.9942861, lng: 17.5176645 },
    { lat: 47.9943968, lng: 17.5184536 },
  ],
  Mierovo: [
    { lat: 48.0804522, lng: 17.3823402 },
    { lat: 48.0804382, lng: 17.3823683 },
    { lat: 48.0804224, lng: 17.382401 },
    { lat: 48.0754641, lng: 17.3770776 },
    { lat: 48.07509, lng: 17.3765919 },
    { lat: 48.0715273, lng: 17.37287 },
    { lat: 48.0703548, lng: 17.3776403 },
    { lat: 48.0700164, lng: 17.3774563 },
    { lat: 48.0695544, lng: 17.379354 },
    { lat: 48.0672011, lng: 17.3770361 },
    { lat: 48.0687644, lng: 17.3841734 },
    { lat: 48.0687125, lng: 17.3843098 },
    { lat: 48.0518738, lng: 17.3688448 },
    { lat: 48.0517809, lng: 17.3687556 },
    { lat: 48.0486253, lng: 17.3658682 },
    { lat: 48.0484558, lng: 17.3667296 },
    { lat: 48.0479367, lng: 17.3684563 },
    { lat: 48.0478812, lng: 17.3685915 },
    { lat: 48.047311, lng: 17.3691464 },
    { lat: 48.0476777, lng: 17.3694137 },
    { lat: 48.0481983, lng: 17.3698086 },
    { lat: 48.0483377, lng: 17.3701074 },
    { lat: 48.0492343, lng: 17.3712409 },
    { lat: 48.0500828, lng: 17.3727762 },
    { lat: 48.0505516, lng: 17.3737749 },
    { lat: 48.0514318, lng: 17.3751729 },
    { lat: 48.0540016, lng: 17.376812 },
    { lat: 48.0552495, lng: 17.3773622 },
    { lat: 48.0553201, lng: 17.3788502 },
    { lat: 48.0554049, lng: 17.379491 },
    { lat: 48.0554062, lng: 17.3806802 },
    { lat: 48.0553382, lng: 17.3820747 },
    { lat: 48.0552454, lng: 17.3827093 },
    { lat: 48.0552478, lng: 17.3830899 },
    { lat: 48.0555787, lng: 17.3851779 },
    { lat: 48.0561835, lng: 17.3878065 },
    { lat: 48.0562672, lng: 17.3900004 },
    { lat: 48.0559983, lng: 17.3916562 },
    { lat: 48.0553227, lng: 17.3937595 },
    { lat: 48.0543591, lng: 17.3962269 },
    { lat: 48.054335, lng: 17.3969802 },
    { lat: 48.0540783, lng: 17.3986179 },
    { lat: 48.0537496, lng: 17.3998063 },
    { lat: 48.0527101, lng: 17.402914 },
    { lat: 48.0524004, lng: 17.4038249 },
    { lat: 48.0529261, lng: 17.4041622 },
    { lat: 48.0540362, lng: 17.4051447 },
    { lat: 48.0603137, lng: 17.4118917 },
    { lat: 48.0608518, lng: 17.4127782 },
    { lat: 48.0624292, lng: 17.4140875 },
    { lat: 48.0624326, lng: 17.4144006 },
    { lat: 48.0661756, lng: 17.4163416 },
    { lat: 48.066431, lng: 17.41648 },
    { lat: 48.0664698, lng: 17.4158981 },
    { lat: 48.066543, lng: 17.4152713 },
    { lat: 48.067066, lng: 17.412796 },
    { lat: 48.0680502, lng: 17.4084923 },
    { lat: 48.0690578, lng: 17.4051215 },
    { lat: 48.0697462, lng: 17.403622 },
    { lat: 48.0705412, lng: 17.4027927 },
    { lat: 48.071693, lng: 17.4019207 },
    { lat: 48.0716612, lng: 17.4018006 },
    { lat: 48.0724465, lng: 17.4008876 },
    { lat: 48.0731099, lng: 17.3997704 },
    { lat: 48.0733856, lng: 17.3990791 },
    { lat: 48.0740117, lng: 17.3971501 },
    { lat: 48.0741971, lng: 17.3966873 },
    { lat: 48.0746168, lng: 17.3958839 },
    { lat: 48.0749204, lng: 17.3954124 },
    { lat: 48.0758927, lng: 17.3945471 },
    { lat: 48.077588, lng: 17.3926643 },
    { lat: 48.0776568, lng: 17.3925759 },
    { lat: 48.0786292, lng: 17.3913153 },
    { lat: 48.0804329, lng: 17.3881516 },
    { lat: 48.0813031, lng: 17.38678 },
    { lat: 48.0822119, lng: 17.3854766 },
    { lat: 48.082776, lng: 17.3847766 },
    { lat: 48.080807, lng: 17.3826872 },
    { lat: 48.0804522, lng: 17.3823402 },
  ],
  Vrakúň: [
    { lat: 47.9268469, lng: 17.556707 },
    { lat: 47.9268249, lng: 17.5566453 },
    { lat: 47.9266294, lng: 17.5563974 },
    { lat: 47.9259753, lng: 17.5562156 },
    { lat: 47.9255772, lng: 17.5558194 },
    { lat: 47.9253518, lng: 17.5553338 },
    { lat: 47.9251742, lng: 17.5547123 },
    { lat: 47.9206489, lng: 17.5510303 },
    { lat: 47.9210789, lng: 17.5532441 },
    { lat: 47.9212097, lng: 17.5541722 },
    { lat: 47.92009, lng: 17.555062 },
    { lat: 47.9184233, lng: 17.5555556 },
    { lat: 47.9171853, lng: 17.5555155 },
    { lat: 47.9161909, lng: 17.5584115 },
    { lat: 47.9169133, lng: 17.5627925 },
    { lat: 47.9173649, lng: 17.5645336 },
    { lat: 47.9177069, lng: 17.5661188 },
    { lat: 47.9173007, lng: 17.5679385 },
    { lat: 47.91703, lng: 17.5686672 },
    { lat: 47.916423, lng: 17.5695568 },
    { lat: 47.9150853, lng: 17.5711539 },
    { lat: 47.9150245, lng: 17.5719144 },
    { lat: 47.9155846, lng: 17.5733268 },
    { lat: 47.9163192, lng: 17.5741827 },
    { lat: 47.9166469, lng: 17.5754222 },
    { lat: 47.9159607, lng: 17.5765918 },
    { lat: 47.9154839, lng: 17.5759001 },
    { lat: 47.9149787, lng: 17.5765291 },
    { lat: 47.9144594, lng: 17.577414 },
    { lat: 47.9142527, lng: 17.5779969 },
    { lat: 47.9141026, lng: 17.5788622 },
    { lat: 47.9139911, lng: 17.5798748 },
    { lat: 47.9138176, lng: 17.580762 },
    { lat: 47.9137559, lng: 17.5813518 },
    { lat: 47.9139471, lng: 17.5833001 },
    { lat: 47.9139288, lng: 17.5838796 },
    { lat: 47.9147329, lng: 17.5847476 },
    { lat: 47.9137518, lng: 17.5874536 },
    { lat: 47.9134852, lng: 17.5926699 },
    { lat: 47.9136966, lng: 17.5940922 },
    { lat: 47.9134312, lng: 17.5945345 },
    { lat: 47.9127664, lng: 17.5949254 },
    { lat: 47.9124318, lng: 17.59503 },
    { lat: 47.911563, lng: 17.594385 },
    { lat: 47.9111567, lng: 17.5940147 },
    { lat: 47.9108265, lng: 17.5938312 },
    { lat: 47.9104991, lng: 17.593744 },
    { lat: 47.9102873, lng: 17.5937352 },
    { lat: 47.9097601, lng: 17.5939324 },
    { lat: 47.9095025, lng: 17.5941047 },
    { lat: 47.9092012, lng: 17.5944805 },
    { lat: 47.9087424, lng: 17.595323 },
    { lat: 47.9086546, lng: 17.5956124 },
    { lat: 47.9088819, lng: 17.5957074 },
    { lat: 47.9090441, lng: 17.5956271 },
    { lat: 47.9093563, lng: 17.5951769 },
    { lat: 47.9094743, lng: 17.5951676 },
    { lat: 47.9096471, lng: 17.5955775 },
    { lat: 47.9096678, lng: 17.5970137 },
    { lat: 47.9097491, lng: 17.5977959 },
    { lat: 47.9098577, lng: 17.598293 },
    { lat: 47.9101598, lng: 17.5988005 },
    { lat: 47.9103122, lng: 17.6000351 },
    { lat: 47.9102944, lng: 17.6002913 },
    { lat: 47.9100917, lng: 17.6011125 },
    { lat: 47.9096904, lng: 17.6020451 },
    { lat: 47.9096219, lng: 17.6024394 },
    { lat: 47.9092338, lng: 17.6030674 },
    { lat: 47.9086681, lng: 17.6036874 },
    { lat: 47.9082086, lng: 17.6043449 },
    { lat: 47.9079295, lng: 17.604481 },
    { lat: 47.9067897, lng: 17.6046884 },
    { lat: 47.9066154, lng: 17.6050164 },
    { lat: 47.9061439, lng: 17.6065338 },
    { lat: 47.9066681, lng: 17.6076202 },
    { lat: 47.9057473, lng: 17.608194 },
    { lat: 47.9042496, lng: 17.6084546 },
    { lat: 47.9041057, lng: 17.6089595 },
    { lat: 47.9026128, lng: 17.6089597 },
    { lat: 47.9020055, lng: 17.6094953 },
    { lat: 47.9036745, lng: 17.612441 },
    { lat: 47.9026117, lng: 17.6149187 },
    { lat: 47.9013063, lng: 17.6176859 },
    { lat: 47.9008209, lng: 17.6191607 },
    { lat: 47.9006369, lng: 17.6193417 },
    { lat: 47.8985668, lng: 17.62296 },
    { lat: 47.8980987, lng: 17.6242457 },
    { lat: 47.8974859, lng: 17.6251917 },
    { lat: 47.8966732, lng: 17.6259086 },
    { lat: 47.8953964, lng: 17.6257893 },
    { lat: 47.8953465, lng: 17.6275984 },
    { lat: 47.8949267, lng: 17.6286675 },
    { lat: 47.8945417, lng: 17.6293453 },
    { lat: 47.8941821, lng: 17.6303148 },
    { lat: 47.8947929, lng: 17.6310579 },
    { lat: 47.8946114, lng: 17.6322912 },
    { lat: 47.8940506, lng: 17.6339683 },
    { lat: 47.8937623, lng: 17.6355131 },
    { lat: 47.8937439, lng: 17.6360209 },
    { lat: 47.8939004, lng: 17.6376152 },
    { lat: 47.8946084, lng: 17.6381858 },
    { lat: 47.8952677, lng: 17.638151 },
    { lat: 47.8950574, lng: 17.6393567 },
    { lat: 47.8947869, lng: 17.6398959 },
    { lat: 47.894717, lng: 17.6402189 },
    { lat: 47.8947446, lng: 17.6404423 },
    { lat: 47.8948629, lng: 17.6407399 },
    { lat: 47.8954038, lng: 17.6412037 },
    { lat: 47.894613, lng: 17.6434568 },
    { lat: 47.8937867, lng: 17.6436567 },
    { lat: 47.8933592, lng: 17.6439305 },
    { lat: 47.8930931, lng: 17.6441852 },
    { lat: 47.8921646, lng: 17.6453394 },
    { lat: 47.8922504, lng: 17.6462298 },
    { lat: 47.8913496, lng: 17.6463789 },
    { lat: 47.8905518, lng: 17.6466341 },
    { lat: 47.8890574, lng: 17.6474805 },
    { lat: 47.8886335, lng: 17.6477925 },
    { lat: 47.8881616, lng: 17.6482395 },
    { lat: 47.8878679, lng: 17.64863 },
    { lat: 47.8875048, lng: 17.6493154 },
    { lat: 47.8872221, lng: 17.6500406 },
    { lat: 47.8869396, lng: 17.6511428 },
    { lat: 47.8862707, lng: 17.6528092 },
    { lat: 47.8861375, lng: 17.653684 },
    { lat: 47.8859008, lng: 17.6551885 },
    { lat: 47.8859373, lng: 17.6557138 },
    { lat: 47.8859704, lng: 17.6560574 },
    { lat: 47.8861841, lng: 17.6568461 },
    { lat: 47.886462, lng: 17.6575473 },
    { lat: 47.8867748, lng: 17.658154 },
    { lat: 47.8868549, lng: 17.6580622 },
    { lat: 47.886941, lng: 17.6579612 },
    { lat: 47.8873321, lng: 17.6584327 },
    { lat: 47.8885602, lng: 17.6594968 },
    { lat: 47.8898436, lng: 17.6602422 },
    { lat: 47.8909536, lng: 17.6607075 },
    { lat: 47.8924213, lng: 17.6610068 },
    { lat: 47.8931458, lng: 17.6612503 },
    { lat: 47.8939539, lng: 17.6617798 },
    { lat: 47.8947433, lng: 17.6626573 },
    { lat: 47.8953362, lng: 17.6636598 },
    { lat: 47.8959457, lng: 17.6649815 },
    { lat: 47.8973479, lng: 17.666783 },
    { lat: 47.8983072, lng: 17.6670433 },
    { lat: 47.8992213, lng: 17.6666595 },
    { lat: 47.9009834, lng: 17.6657251 },
    { lat: 47.9018482, lng: 17.6649913 },
    { lat: 47.9022892, lng: 17.6644293 },
    { lat: 47.9030018, lng: 17.6630025 },
    { lat: 47.9036816, lng: 17.6608764 },
    { lat: 47.9041837, lng: 17.6594955 },
    { lat: 47.9045651, lng: 17.6586697 },
    { lat: 47.9051157, lng: 17.6577521 },
    { lat: 47.9059058, lng: 17.6568037 },
    { lat: 47.9066674, lng: 17.6565972 },
    { lat: 47.9076116, lng: 17.65678 },
    { lat: 47.908291, lng: 17.6566867 },
    { lat: 47.909034, lng: 17.6567591 },
    { lat: 47.9091351, lng: 17.6563567 },
    { lat: 47.9091245, lng: 17.6554749 },
    { lat: 47.909191, lng: 17.6547797 },
    { lat: 47.9108818, lng: 17.6554264 },
    { lat: 47.911308, lng: 17.655454 },
    { lat: 47.9131081, lng: 17.6540854 },
    { lat: 47.913818, lng: 17.6531881 },
    { lat: 47.9143328, lng: 17.6520619 },
    { lat: 47.914511, lng: 17.651539 },
    { lat: 47.9154765, lng: 17.6495982 },
    { lat: 47.9158896, lng: 17.6484908 },
    { lat: 47.9158721, lng: 17.6470906 },
    { lat: 47.9153399, lng: 17.6454797 },
    { lat: 47.9148649, lng: 17.6438456 },
    { lat: 47.9141998, lng: 17.6421357 },
    { lat: 47.9142251, lng: 17.6419819 },
    { lat: 47.9143032, lng: 17.641877 },
    { lat: 47.9149018, lng: 17.641578 },
    { lat: 47.9153594, lng: 17.6416349 },
    { lat: 47.9157632, lng: 17.6414868 },
    { lat: 47.9166257, lng: 17.6413235 },
    { lat: 47.9169714, lng: 17.6413095 },
    { lat: 47.9174496, lng: 17.6414248 },
    { lat: 47.9178188, lng: 17.641442 },
    { lat: 47.9183959, lng: 17.6413896 },
    { lat: 47.9198567, lng: 17.6423111 },
    { lat: 47.9203611, lng: 17.6424853 },
    { lat: 47.9210307, lng: 17.6424326 },
    { lat: 47.9216303, lng: 17.6422169 },
    { lat: 47.9227397, lng: 17.6416747 },
    { lat: 47.9258293, lng: 17.6481324 },
    { lat: 47.9278895, lng: 17.6515163 },
    { lat: 47.9280054, lng: 17.6512399 },
    { lat: 47.9283774, lng: 17.6509825 },
    { lat: 47.9288585, lng: 17.6507144 },
    { lat: 47.9294652, lng: 17.6505615 },
    { lat: 47.9299224, lng: 17.6505066 },
    { lat: 47.9304674, lng: 17.6505337 },
    { lat: 47.9315121, lng: 17.6507183 },
    { lat: 47.9317469, lng: 17.6508945 },
    { lat: 47.9341248, lng: 17.6490928 },
    { lat: 47.9353966, lng: 17.6461361 },
    { lat: 47.9358238, lng: 17.6455554 },
    { lat: 47.9361848, lng: 17.6452812 },
    { lat: 47.9369874, lng: 17.6455468 },
    { lat: 47.9383157, lng: 17.6451775 },
    { lat: 47.9389973, lng: 17.645833 },
    { lat: 47.9394327, lng: 17.6447923 },
    { lat: 47.9398186, lng: 17.6436634 },
    { lat: 47.9401289, lng: 17.6420402 },
    { lat: 47.9401412, lng: 17.6405902 },
    { lat: 47.9391387, lng: 17.6383698 },
    { lat: 47.9390317, lng: 17.6370876 },
    { lat: 47.9397135, lng: 17.6365859 },
    { lat: 47.9400172, lng: 17.6326697 },
    { lat: 47.9410313, lng: 17.6325459 },
    { lat: 47.9451554, lng: 17.632001 },
    { lat: 47.9476889, lng: 17.6311255 },
    { lat: 47.9491708, lng: 17.6317364 },
    { lat: 47.9499147, lng: 17.6319453 },
    { lat: 47.9503886, lng: 17.6323327 },
    { lat: 47.9513543, lng: 17.6338348 },
    { lat: 47.9518021, lng: 17.6343957 },
    { lat: 47.9523584, lng: 17.634979 },
    { lat: 47.953428, lng: 17.6369248 },
    { lat: 47.9535514, lng: 17.6383907 },
    { lat: 47.9535579, lng: 17.6398294 },
    { lat: 47.9534121, lng: 17.6415553 },
    { lat: 47.9546571, lng: 17.6446708 },
    { lat: 47.9554763, lng: 17.6460173 },
    { lat: 47.9567721, lng: 17.6477387 },
    { lat: 47.9590254, lng: 17.6494264 },
    { lat: 47.9592108, lng: 17.6488079 },
    { lat: 47.9598898, lng: 17.6470827 },
    { lat: 47.9606958, lng: 17.6465984 },
    { lat: 47.9610451, lng: 17.646282 },
    { lat: 47.9619399, lng: 17.6458253 },
    { lat: 47.9632533, lng: 17.6415294 },
    { lat: 47.9669744, lng: 17.6336815 },
    { lat: 47.9668945, lng: 17.6335599 },
    { lat: 47.9661728, lng: 17.6329694 },
    { lat: 47.9648841, lng: 17.6317512 },
    { lat: 47.9629029, lng: 17.6292656 },
    { lat: 47.9627356, lng: 17.6285722 },
    { lat: 47.9624221, lng: 17.6276883 },
    { lat: 47.9619589, lng: 17.6265631 },
    { lat: 47.9618949, lng: 17.6265133 },
    { lat: 47.9614102, lng: 17.626493 },
    { lat: 47.9612518, lng: 17.6265583 },
    { lat: 47.9611926, lng: 17.626692 },
    { lat: 47.9611811, lng: 17.6283983 },
    { lat: 47.9610968, lng: 17.6290648 },
    { lat: 47.9608589, lng: 17.6301892 },
    { lat: 47.9601624, lng: 17.6298183 },
    { lat: 47.959547, lng: 17.6296945 },
    { lat: 47.9591774, lng: 17.6293139 },
    { lat: 47.9586355, lng: 17.6291016 },
    { lat: 47.95734, lng: 17.6274079 },
    { lat: 47.9560892, lng: 17.6254901 },
    { lat: 47.9554021, lng: 17.6243437 },
    { lat: 47.9544068, lng: 17.6219715 },
    { lat: 47.9548554, lng: 17.6209619 },
    { lat: 47.9553481, lng: 17.6195628 },
    { lat: 47.9562438, lng: 17.615837 },
    { lat: 47.9564039, lng: 17.6152621 },
    { lat: 47.9564073, lng: 17.6152289 },
    { lat: 47.9568442, lng: 17.6135039 },
    { lat: 47.9567087, lng: 17.6129548 },
    { lat: 47.9566863, lng: 17.6118543 },
    { lat: 47.9570256, lng: 17.6098236 },
    { lat: 47.9575298, lng: 17.6083565 },
    { lat: 47.9579511, lng: 17.6075784 },
    { lat: 47.9578766, lng: 17.6060534 },
    { lat: 47.9580058, lng: 17.6035967 },
    { lat: 47.9580499, lng: 17.6017513 },
    { lat: 47.9583576, lng: 17.6006789 },
    { lat: 47.9580956, lng: 17.5994755 },
    { lat: 47.959736, lng: 17.5975895 },
    { lat: 47.9597406, lng: 17.596934 },
    { lat: 47.9597566, lng: 17.5916258 },
    { lat: 47.9585203, lng: 17.5907769 },
    { lat: 47.9579985, lng: 17.5901042 },
    { lat: 47.957138, lng: 17.5895903 },
    { lat: 47.9576269, lng: 17.5866684 },
    { lat: 47.9574527, lng: 17.5847179 },
    { lat: 47.957163, lng: 17.583064 },
    { lat: 47.9564319, lng: 17.5819765 },
    { lat: 47.9563317, lng: 17.5816958 },
    { lat: 47.9554325, lng: 17.5790409 },
    { lat: 47.9552253, lng: 17.5782709 },
    { lat: 47.9550246, lng: 17.577679 },
    { lat: 47.9548991, lng: 17.5774629 },
    { lat: 47.954671, lng: 17.5760851 },
    { lat: 47.9541616, lng: 17.5760117 },
    { lat: 47.9536671, lng: 17.5760825 },
    { lat: 47.9515272, lng: 17.5747424 },
    { lat: 47.9506552, lng: 17.5747384 },
    { lat: 47.9500987, lng: 17.574813 },
    { lat: 47.9487214, lng: 17.573812 },
    { lat: 47.9476377, lng: 17.5726327 },
    { lat: 47.9464613, lng: 17.5702389 },
    { lat: 47.9463178, lng: 17.5704473 },
    { lat: 47.9461706, lng: 17.5705586 },
    { lat: 47.9459883, lng: 17.5706965 },
    { lat: 47.9452026, lng: 17.5708776 },
    { lat: 47.944822, lng: 17.5708611 },
    { lat: 47.9444419, lng: 17.5709375 },
    { lat: 47.943982, lng: 17.5708463 },
    { lat: 47.9436517, lng: 17.5706075 },
    { lat: 47.9434224, lng: 17.5702519 },
    { lat: 47.9432262, lng: 17.5697627 },
    { lat: 47.9431548, lng: 17.5690536 },
    { lat: 47.9430126, lng: 17.5683141 },
    { lat: 47.9428242, lng: 17.5677333 },
    { lat: 47.9416711, lng: 17.5648396 },
    { lat: 47.9411626, lng: 17.5638235 },
    { lat: 47.9408293, lng: 17.5631576 },
    { lat: 47.9406314, lng: 17.5628159 },
    { lat: 47.9402288, lng: 17.56233 },
    { lat: 47.9401394, lng: 17.5618938 },
    { lat: 47.9400101, lng: 17.5612629 },
    { lat: 47.9401552, lng: 17.5601777 },
    { lat: 47.9401768, lng: 17.5592988 },
    { lat: 47.9399299, lng: 17.5590944 },
    { lat: 47.9396864, lng: 17.5588877 },
    { lat: 47.9388708, lng: 17.5579146 },
    { lat: 47.9382208, lng: 17.5574279 },
    { lat: 47.9367287, lng: 17.5558022 },
    { lat: 47.9358126, lng: 17.5549551 },
    { lat: 47.9342566, lng: 17.5531242 },
    { lat: 47.9333301, lng: 17.55236 },
    { lat: 47.9329312, lng: 17.5525153 },
    { lat: 47.9327604, lng: 17.5527924 },
    { lat: 47.9321459, lng: 17.5542842 },
    { lat: 47.9318048, lng: 17.5548654 },
    { lat: 47.9313285, lng: 17.5552204 },
    { lat: 47.931112, lng: 17.5551385 },
    { lat: 47.9307356, lng: 17.5556609 },
    { lat: 47.9303529, lng: 17.5559621 },
    { lat: 47.9299167, lng: 17.5561863 },
    { lat: 47.9297259, lng: 17.5562219 },
    { lat: 47.929199, lng: 17.5561051 },
    { lat: 47.9289108, lng: 17.5559249 },
    { lat: 47.9285285, lng: 17.5553877 },
    { lat: 47.9281846, lng: 17.5553978 },
    { lat: 47.9277107, lng: 17.555702 },
    { lat: 47.9272895, lng: 17.5561266 },
    { lat: 47.9268469, lng: 17.556707 },
  ],
  TrhováHradská: [
    { lat: 48.031824, lng: 17.768269 },
    { lat: 48.0317902, lng: 17.7676888 },
    { lat: 48.0322204, lng: 17.7654552 },
    { lat: 48.0322904, lng: 17.7648346 },
    { lat: 48.0322996, lng: 17.764457 },
    { lat: 48.0321555, lng: 17.7637373 },
    { lat: 48.0319233, lng: 17.7631609 },
    { lat: 48.0311153, lng: 17.7615153 },
    { lat: 48.0309966, lng: 17.7610933 },
    { lat: 48.0308641, lng: 17.758856 },
    { lat: 48.0306919, lng: 17.7586813 },
    { lat: 48.0300333, lng: 17.7577322 },
    { lat: 48.0299621, lng: 17.7574239 },
    { lat: 48.0293906, lng: 17.7561665 },
    { lat: 48.0291967, lng: 17.755446 },
    { lat: 48.0290957, lng: 17.7548766 },
    { lat: 48.0270736, lng: 17.7561992 },
    { lat: 48.0259204, lng: 17.7571705 },
    { lat: 48.0251758, lng: 17.7579072 },
    { lat: 48.0243971, lng: 17.7583263 },
    { lat: 48.0227278, lng: 17.7588978 },
    { lat: 48.0217092, lng: 17.7596377 },
    { lat: 48.0210037, lng: 17.7602191 },
    { lat: 48.0194887, lng: 17.7618027 },
    { lat: 48.0187283, lng: 17.7628094 },
    { lat: 48.0176186, lng: 17.7633742 },
    { lat: 48.0166768, lng: 17.7635505 },
    { lat: 48.0152878, lng: 17.7634194 },
    { lat: 48.0147648, lng: 17.7634849 },
    { lat: 48.0138485, lng: 17.7634778 },
    { lat: 48.0139529, lng: 17.7640639 },
    { lat: 48.0140531, lng: 17.7653684 },
    { lat: 48.014084, lng: 17.7659942 },
    { lat: 48.0140349, lng: 17.7660567 },
    { lat: 48.0128295, lng: 17.7646827 },
    { lat: 48.0125334, lng: 17.7642768 },
    { lat: 48.0120953, lng: 17.7634936 },
    { lat: 48.0111686, lng: 17.7622117 },
    { lat: 48.0107792, lng: 17.7615578 },
    { lat: 48.010584, lng: 17.761032 },
    { lat: 48.0102842, lng: 17.7605872 },
    { lat: 48.0097494, lng: 17.7599757 },
    { lat: 48.0090467, lng: 17.7593701 },
    { lat: 48.0088826, lng: 17.7592913 },
    { lat: 48.0084379, lng: 17.7592399 },
    { lat: 48.0075973, lng: 17.759392 },
    { lat: 48.0052909, lng: 17.7603005 },
    { lat: 48.0043135, lng: 17.7605811 },
    { lat: 48.0038551, lng: 17.7605056 },
    { lat: 48.0029541, lng: 17.7599218 },
    { lat: 48.0020897, lng: 17.7595654 },
    { lat: 48.0016869, lng: 17.759302 },
    { lat: 48.0011939, lng: 17.7585988 },
    { lat: 48.0008897, lng: 17.7579908 },
    { lat: 48.0008323, lng: 17.7577767 },
    { lat: 48.0008327, lng: 17.7573494 },
    { lat: 48.0007569, lng: 17.757093 },
    { lat: 48.0001643, lng: 17.7567132 },
    { lat: 47.9999145, lng: 17.7563448 },
    { lat: 47.9997837, lng: 17.7559652 },
    { lat: 47.9996945, lng: 17.7555517 },
    { lat: 47.9995075, lng: 17.7534185 },
    { lat: 47.9994018, lng: 17.7528427 },
    { lat: 47.9994146, lng: 17.752504 },
    { lat: 47.9988215, lng: 17.7523378 },
    { lat: 47.9980513, lng: 17.7518074 },
    { lat: 47.9971269, lng: 17.7507622 },
    { lat: 47.996384, lng: 17.7495083 },
    { lat: 47.9955498, lng: 17.7482451 },
    { lat: 47.9950395, lng: 17.7478272 },
    { lat: 47.9953499, lng: 17.7467769 },
    { lat: 47.995419, lng: 17.7462196 },
    { lat: 47.9954011, lng: 17.7459849 },
    { lat: 47.995241, lng: 17.745357 },
    { lat: 47.9952672, lng: 17.7439481 },
    { lat: 47.9951684, lng: 17.7435208 },
    { lat: 47.9950348, lng: 17.7433547 },
    { lat: 47.9944205, lng: 17.7429544 },
    { lat: 47.994247, lng: 17.7427595 },
    { lat: 47.9941168, lng: 17.7424487 },
    { lat: 47.993999, lng: 17.7418605 },
    { lat: 47.993611, lng: 17.7414508 },
    { lat: 47.9930019, lng: 17.7404831 },
    { lat: 47.9926833, lng: 17.7400432 },
    { lat: 47.992364, lng: 17.7396691 },
    { lat: 47.9919981, lng: 17.7387611 },
    { lat: 47.9916713, lng: 17.7374339 },
    { lat: 47.9914738, lng: 17.7369312 },
    { lat: 47.9907481, lng: 17.7362811 },
    { lat: 47.9905167, lng: 17.7353601 },
    { lat: 47.9899205, lng: 17.7343118 },
    { lat: 47.9895846, lng: 17.7333627 },
    { lat: 47.9893237, lng: 17.7329985 },
    { lat: 47.9888945, lng: 17.7326016 },
    { lat: 47.9887966, lng: 17.7324436 },
    { lat: 47.9887297, lng: 17.7322199 },
    { lat: 47.9887223, lng: 17.730746 },
    { lat: 47.9885997, lng: 17.7301067 },
    { lat: 47.9884171, lng: 17.7294596 },
    { lat: 47.9883309, lng: 17.7288231 },
    { lat: 47.9884564, lng: 17.7268035 },
    { lat: 47.9883933, lng: 17.7268045 },
    { lat: 47.987994, lng: 17.7258904 },
    { lat: 47.9878432, lng: 17.7253933 },
    { lat: 47.9878488, lng: 17.7247609 },
    { lat: 47.9879682, lng: 17.7239799 },
    { lat: 47.9885481, lng: 17.7224372 },
    { lat: 47.9884879, lng: 17.7214953 },
    { lat: 47.9885174, lng: 17.7205217 },
    { lat: 47.9884363, lng: 17.719876 },
    { lat: 47.9879708, lng: 17.7187903 },
    { lat: 47.9875149, lng: 17.7175476 },
    { lat: 47.987221, lng: 17.7164434 },
    { lat: 47.9866722, lng: 17.7149115 },
    { lat: 47.9860622, lng: 17.7140941 },
    { lat: 47.984188, lng: 17.7122374 },
    { lat: 47.9831546, lng: 17.7110786 },
    { lat: 47.9836138, lng: 17.7099578 },
    { lat: 47.9838752, lng: 17.7087416 },
    { lat: 47.9838183, lng: 17.7073663 },
    { lat: 47.9835875, lng: 17.7064078 },
    { lat: 47.9830148, lng: 17.7051413 },
    { lat: 47.9823678, lng: 17.7041516 },
    { lat: 47.9816445, lng: 17.7036722 },
    { lat: 47.9808517, lng: 17.7035067 },
    { lat: 47.9782665, lng: 17.7024148 },
    { lat: 47.9785316, lng: 17.7025357 },
    { lat: 47.9789529, lng: 17.7027386 },
    { lat: 47.9803077, lng: 17.7036892 },
    { lat: 47.9816363, lng: 17.7051052 },
    { lat: 47.9824828, lng: 17.7061654 },
    { lat: 47.9821206, lng: 17.7085459 },
    { lat: 47.978663, lng: 17.7128406 },
    { lat: 47.9783242, lng: 17.7134184 },
    { lat: 47.9777217, lng: 17.7151526 },
    { lat: 47.977455, lng: 17.7165558 },
    { lat: 47.9769201, lng: 17.7177651 },
    { lat: 47.9767446, lng: 17.7187521 },
    { lat: 47.9767348, lng: 17.7192792 },
    { lat: 47.9767934, lng: 17.720215 },
    { lat: 47.9766658, lng: 17.7207926 },
    { lat: 47.9762974, lng: 17.7215971 },
    { lat: 47.975953, lng: 17.7220487 },
    { lat: 47.9750513, lng: 17.7223419 },
    { lat: 47.9747012, lng: 17.7225586 },
    { lat: 47.9742243, lng: 17.723093 },
    { lat: 47.9741767, lng: 17.7230535 },
    { lat: 47.97272, lng: 17.725469 },
    { lat: 47.9715712, lng: 17.7271384 },
    { lat: 47.9701983, lng: 17.7282931 },
    { lat: 47.9692748, lng: 17.7295736 },
    { lat: 47.968523, lng: 17.7315087 },
    { lat: 47.9682568, lng: 17.73175 },
    { lat: 47.9682102, lng: 17.7317085 },
    { lat: 47.9671645, lng: 17.7340894 },
    { lat: 47.9664305, lng: 17.7355485 },
    { lat: 47.9650509, lng: 17.7367069 },
    { lat: 47.962368, lng: 17.7369189 },
    { lat: 47.9606627, lng: 17.7371988 },
    { lat: 47.9589652, lng: 17.7378424 },
    { lat: 47.9590112, lng: 17.7378896 },
    { lat: 47.9602937, lng: 17.7392787 },
    { lat: 47.9603436, lng: 17.7393578 },
    { lat: 47.9649283, lng: 17.7466078 },
    { lat: 47.9739424, lng: 17.7597447 },
    { lat: 47.9753356, lng: 17.7617195 },
    { lat: 47.9774389, lng: 17.763805 },
    { lat: 47.9800603, lng: 17.7687215 },
    { lat: 47.9812006, lng: 17.7715037 },
    { lat: 47.9824878, lng: 17.7753038 },
    { lat: 47.9826477, lng: 17.7760623 },
    { lat: 47.9833319, lng: 17.7775326 },
    { lat: 47.9835576, lng: 17.7778958 },
    { lat: 47.9837251, lng: 17.7782839 },
    { lat: 47.9838327, lng: 17.7783819 },
    { lat: 47.9848165, lng: 17.7812815 },
    { lat: 47.9851325, lng: 17.7820428 },
    { lat: 47.9847502, lng: 17.7823981 },
    { lat: 47.9842606, lng: 17.7826015 },
    { lat: 47.9835129, lng: 17.7827062 },
    { lat: 47.9829374, lng: 17.7826737 },
    { lat: 47.9823771, lng: 17.7825768 },
    { lat: 47.9817172, lng: 17.782297 },
    { lat: 47.9816848, lng: 17.7826478 },
    { lat: 47.9817353, lng: 17.7828963 },
    { lat: 47.9826695, lng: 17.7840895 },
    { lat: 47.982958, lng: 17.7845885 },
    { lat: 47.9830226, lng: 17.7847414 },
    { lat: 47.9832767, lng: 17.7860348 },
    { lat: 47.9836582, lng: 17.7873788 },
    { lat: 47.9845431, lng: 17.7894884 },
    { lat: 47.9848637, lng: 17.7903813 },
    { lat: 47.9862952, lng: 17.7926959 },
    { lat: 47.9865403, lng: 17.7932416 },
    { lat: 47.9860138, lng: 17.7938932 },
    { lat: 47.985836, lng: 17.794559 },
    { lat: 47.9860246, lng: 17.7951403 },
    { lat: 47.9870928, lng: 17.8002329 },
    { lat: 47.9877165, lng: 17.8014123 },
    { lat: 47.9900311, lng: 17.8013585 },
    { lat: 47.9904205, lng: 17.8006665 },
    { lat: 47.9910222, lng: 17.8025799 },
    { lat: 47.9920655, lng: 17.8055423 },
    { lat: 47.9929073, lng: 17.8070863 },
    { lat: 47.9936522, lng: 17.8078321 },
    { lat: 47.9939029, lng: 17.8079457 },
    { lat: 47.9940233, lng: 17.8088671 },
    { lat: 47.9939882, lng: 17.8096892 },
    { lat: 47.9969516, lng: 17.8141508 },
    { lat: 47.999597, lng: 17.817185 },
    { lat: 47.999868, lng: 17.817046 },
    { lat: 48.000206, lng: 17.816994 },
    { lat: 48.000475, lng: 17.81695 },
    { lat: 48.000725, lng: 17.816849 },
    { lat: 48.000922, lng: 17.816704 },
    { lat: 48.001229, lng: 17.81658 },
    { lat: 48.001757, lng: 17.816601 },
    { lat: 48.002204, lng: 17.816625 },
    { lat: 48.002504, lng: 17.816575 },
    { lat: 48.002762, lng: 17.816558 },
    { lat: 48.003256, lng: 17.81658 },
    { lat: 48.003534, lng: 17.816469 },
    { lat: 48.003794, lng: 17.816272 },
    { lat: 48.003994, lng: 17.816016 },
    { lat: 48.00408, lng: 17.815787 },
    { lat: 48.004115, lng: 17.815811 },
    { lat: 48.00432, lng: 17.815931 },
    { lat: 48.004562, lng: 17.815828 },
    { lat: 48.004829, lng: 17.815645 },
    { lat: 48.005209, lng: 17.815331 },
    { lat: 48.005764, lng: 17.814739 },
    { lat: 48.006247, lng: 17.814337 },
    { lat: 48.005708, lng: 17.813301 },
    { lat: 48.005443, lng: 17.812787 },
    { lat: 48.005278, lng: 17.812498 },
    { lat: 48.005565, lng: 17.812156 },
    { lat: 48.005687, lng: 17.811913 },
    { lat: 48.005788, lng: 17.811616 },
    { lat: 48.005856, lng: 17.811346 },
    { lat: 48.00592, lng: 17.811075 },
    { lat: 48.006017, lng: 17.810796 },
    { lat: 48.006137, lng: 17.810589 },
    { lat: 48.006239, lng: 17.810468 },
    { lat: 48.006332, lng: 17.810402 },
    { lat: 48.006762, lng: 17.810195 },
    { lat: 48.007045, lng: 17.810036 },
    { lat: 48.007229, lng: 17.809951 },
    { lat: 48.007332, lng: 17.809879 },
    { lat: 48.00729, lng: 17.809742 },
    { lat: 48.006636, lng: 17.807618 },
    { lat: 48.005857, lng: 17.805089 },
    { lat: 48.00584, lng: 17.805037 },
    { lat: 48.00581, lng: 17.804941 },
    { lat: 48.005798, lng: 17.804899 },
    { lat: 48.006409, lng: 17.804489 },
    { lat: 48.00681, lng: 17.804261 },
    { lat: 48.007176, lng: 17.804081 },
    { lat: 48.006911, lng: 17.803592 },
    { lat: 48.007634, lng: 17.802272 },
    { lat: 48.007924, lng: 17.801388 },
    { lat: 48.008021, lng: 17.801446 },
    { lat: 48.00773, lng: 17.802636 },
    { lat: 48.007742, lng: 17.802682 },
    { lat: 48.0078, lng: 17.802963 },
    { lat: 48.007812, lng: 17.803044 },
    { lat: 48.00794, lng: 17.802987 },
    { lat: 48.008059, lng: 17.802935 },
    { lat: 48.008181, lng: 17.802843 },
    { lat: 48.008313, lng: 17.802741 },
    { lat: 48.008439, lng: 17.802648 },
    { lat: 48.008567, lng: 17.802547 },
    { lat: 48.008696, lng: 17.802446 },
    { lat: 48.008822, lng: 17.802351 },
    { lat: 48.008953, lng: 17.802253 },
    { lat: 48.00908, lng: 17.802157 },
    { lat: 48.009208, lng: 17.80206 },
    { lat: 48.009334, lng: 17.801963 },
    { lat: 48.00948, lng: 17.801859 },
    { lat: 48.009606, lng: 17.801764 },
    { lat: 48.009722, lng: 17.801673 },
    { lat: 48.009839, lng: 17.801558 },
    { lat: 48.00996, lng: 17.80144 },
    { lat: 48.010081, lng: 17.801322 },
    { lat: 48.0102, lng: 17.801207 },
    { lat: 48.010325, lng: 17.801088 },
    { lat: 48.010443, lng: 17.800971 },
    { lat: 48.010567, lng: 17.800853 },
    { lat: 48.010686, lng: 17.800735 },
    { lat: 48.010807, lng: 17.800618 },
    { lat: 48.01092, lng: 17.800503 },
    { lat: 48.011045, lng: 17.800381 },
    { lat: 48.011166, lng: 17.800266 },
    { lat: 48.011283, lng: 17.800145 },
    { lat: 48.011406, lng: 17.800026 },
    { lat: 48.011523, lng: 17.79991 },
    { lat: 48.01164, lng: 17.799791 },
    { lat: 48.011764, lng: 17.799672 },
    { lat: 48.011883, lng: 17.79955 },
    { lat: 48.012, lng: 17.799437 },
    { lat: 48.012128, lng: 17.799314 },
    { lat: 48.012245, lng: 17.799182 },
    { lat: 48.012293, lng: 17.799109 },
    { lat: 48.012404, lng: 17.798941 },
    { lat: 48.012505, lng: 17.798789 },
    { lat: 48.012609, lng: 17.798641 },
    { lat: 48.012713, lng: 17.798488 },
    { lat: 48.01281, lng: 17.79835 },
    { lat: 48.012904, lng: 17.798197 },
    { lat: 48.012975, lng: 17.79807 },
    { lat: 48.013011, lng: 17.798029 },
    { lat: 48.01311, lng: 17.797892 },
    { lat: 48.013292, lng: 17.797704 },
    { lat: 48.013414, lng: 17.797571 },
    { lat: 48.013539, lng: 17.797446 },
    { lat: 48.013652, lng: 17.797323 },
    { lat: 48.013765, lng: 17.797202 },
    { lat: 48.013621, lng: 17.7969 },
    { lat: 48.013641, lng: 17.796764 },
    { lat: 48.0136569, lng: 17.7967705 },
    { lat: 48.014034, lng: 17.796924 },
    { lat: 48.014542, lng: 17.796971 },
    { lat: 48.014717, lng: 17.796987 },
    { lat: 48.015043, lng: 17.796945 },
    { lat: 48.015213, lng: 17.796921 },
    { lat: 48.015377, lng: 17.796899 },
    { lat: 48.015447, lng: 17.796891 },
    { lat: 48.016183, lng: 17.796758 },
    { lat: 48.016177, lng: 17.796551 },
    { lat: 48.016417, lng: 17.796446 },
    { lat: 48.016726, lng: 17.796249 },
    { lat: 48.016841, lng: 17.796177 },
    { lat: 48.0169, lng: 17.796136 },
    { lat: 48.016979, lng: 17.796058 },
    { lat: 48.017084, lng: 17.795943 },
    { lat: 48.017301, lng: 17.79571 },
    { lat: 48.017457, lng: 17.795534 },
    { lat: 48.017698, lng: 17.795258 },
    { lat: 48.017746, lng: 17.795173 },
    { lat: 48.017815, lng: 17.795055 },
    { lat: 48.017869, lng: 17.794953 },
    { lat: 48.017933, lng: 17.794846 },
    { lat: 48.018037, lng: 17.794633 },
    { lat: 48.018146, lng: 17.7944 },
    { lat: 48.018222, lng: 17.794226 },
    { lat: 48.018284, lng: 17.794053 },
    { lat: 48.018295, lng: 17.794032 },
    { lat: 48.018369, lng: 17.793797 },
    { lat: 48.018441, lng: 17.793573 },
    { lat: 48.018503, lng: 17.793308 },
    { lat: 48.018568, lng: 17.793047 },
    { lat: 48.018599, lng: 17.792843 },
    { lat: 48.01863, lng: 17.792635 },
    { lat: 48.018687, lng: 17.792227 },
    { lat: 48.018717, lng: 17.791954 },
    { lat: 48.01872, lng: 17.791576 },
    { lat: 48.018723, lng: 17.791204 },
    { lat: 48.01872, lng: 17.791093 },
    { lat: 48.01874, lng: 17.790962 },
    { lat: 48.018739, lng: 17.790932 },
    { lat: 48.018671, lng: 17.790601 },
    { lat: 48.01862, lng: 17.790383 },
    { lat: 48.018478, lng: 17.789991 },
    { lat: 48.018504, lng: 17.789969 },
    { lat: 48.018586, lng: 17.789886 },
    { lat: 48.018359, lng: 17.789218 },
    { lat: 48.018317, lng: 17.789172 },
    { lat: 48.018326, lng: 17.789151 },
    { lat: 48.017957, lng: 17.788145 },
    { lat: 48.017992, lng: 17.788043 },
    { lat: 48.018051, lng: 17.787837 },
    { lat: 48.018109, lng: 17.787894 },
    { lat: 48.018646, lng: 17.788463 },
    { lat: 48.019073, lng: 17.788892 },
    { lat: 48.019618, lng: 17.789458 },
    { lat: 48.019619, lng: 17.789324 },
    { lat: 48.019661, lng: 17.789035 },
    { lat: 48.019744, lng: 17.788693 },
    { lat: 48.019755, lng: 17.78832 },
    { lat: 48.019675, lng: 17.787904 },
    { lat: 48.019636, lng: 17.787806 },
    { lat: 48.01937, lng: 17.787459 },
    { lat: 48.019202, lng: 17.787085 },
    { lat: 48.018994, lng: 17.786405 },
    { lat: 48.018922, lng: 17.785981 },
    { lat: 48.018957, lng: 17.78552 },
    { lat: 48.019039, lng: 17.785092 },
    { lat: 48.019081, lng: 17.78481 },
    { lat: 48.019087, lng: 17.784775 },
    { lat: 48.019168, lng: 17.784669 },
    { lat: 48.019281, lng: 17.784547 },
    { lat: 48.019444, lng: 17.783615 },
    { lat: 48.019469, lng: 17.78329 },
    { lat: 48.019599, lng: 17.782795 },
    { lat: 48.019772, lng: 17.782409 },
    { lat: 48.020125, lng: 17.781925 },
    { lat: 48.020298, lng: 17.78161 },
    { lat: 48.020432, lng: 17.781564 },
    { lat: 48.020758, lng: 17.78154 },
    { lat: 48.020923, lng: 17.78163 },
    { lat: 48.021075, lng: 17.781735 },
    { lat: 48.021281, lng: 17.781798 },
    { lat: 48.021562, lng: 17.781774 },
    { lat: 48.021702, lng: 17.781717 },
    { lat: 48.021928, lng: 17.781642 },
    { lat: 48.022049, lng: 17.781643 },
    { lat: 48.022537, lng: 17.781808 },
    { lat: 48.022705, lng: 17.781802 },
    { lat: 48.023215, lng: 17.781334 },
    { lat: 48.023369, lng: 17.781277 },
    { lat: 48.023409, lng: 17.781257 },
    { lat: 48.023719, lng: 17.781047 },
    { lat: 48.02399, lng: 17.780711 },
    { lat: 48.024086, lng: 17.780668 },
    { lat: 48.024154, lng: 17.780587 },
    { lat: 48.024169, lng: 17.780449 },
    { lat: 48.024169, lng: 17.780233 },
    { lat: 48.024123, lng: 17.780123 },
    { lat: 48.024029, lng: 17.779839 },
    { lat: 48.024015, lng: 17.779663 },
    { lat: 48.024024, lng: 17.779458 },
    { lat: 48.024039, lng: 17.779248 },
    { lat: 48.024108, lng: 17.779156 },
    { lat: 48.024215, lng: 17.779043 },
    { lat: 48.024331, lng: 17.778985 },
    { lat: 48.024571, lng: 17.778849 },
    { lat: 48.024712, lng: 17.778761 },
    { lat: 48.02492, lng: 17.778666 },
    { lat: 48.025075, lng: 17.778658 },
    { lat: 48.025405, lng: 17.7788 },
    { lat: 48.025622, lng: 17.77872 },
    { lat: 48.025648, lng: 17.778733 },
    { lat: 48.026014, lng: 17.778836 },
    { lat: 48.026278, lng: 17.778779 },
    { lat: 48.026536, lng: 17.778617 },
    { lat: 48.026753, lng: 17.778371 },
    { lat: 48.026908, lng: 17.778085 },
    { lat: 48.027033, lng: 17.77788 },
    { lat: 48.027182, lng: 17.777749 },
    { lat: 48.02735, lng: 17.777633 },
    { lat: 48.027591, lng: 17.777424 },
    { lat: 48.027918, lng: 17.777313 },
    { lat: 48.028076, lng: 17.777109 },
    { lat: 48.028631, lng: 17.776721 },
    { lat: 48.028883, lng: 17.776536 },
    { lat: 48.029236, lng: 17.776291 },
    { lat: 48.029611, lng: 17.776024 },
    { lat: 48.029658, lng: 17.775953 },
    { lat: 48.029856, lng: 17.775637 },
    { lat: 48.029975, lng: 17.77543 },
    { lat: 48.030129, lng: 17.775233 },
    { lat: 48.030231, lng: 17.775091 },
    { lat: 48.030371, lng: 17.774827 },
    { lat: 48.030471, lng: 17.774676 },
    { lat: 48.0306, lng: 17.774487 },
    { lat: 48.030715, lng: 17.77426 },
    { lat: 48.030848, lng: 17.774083 },
    { lat: 48.030928, lng: 17.773839 },
    { lat: 48.031088, lng: 17.773694 },
    { lat: 48.031311, lng: 17.773421 },
    { lat: 48.031565, lng: 17.773075 },
    { lat: 48.031658, lng: 17.772948 },
    { lat: 48.031742, lng: 17.772669 },
    { lat: 48.031795, lng: 17.772339 },
    { lat: 48.031846, lng: 17.772127 },
    { lat: 48.03185, lng: 17.772089 },
    { lat: 48.031898, lng: 17.771269 },
    { lat: 48.03191, lng: 17.770915 },
    { lat: 48.031869, lng: 17.77039 },
    { lat: 48.031871, lng: 17.769878 },
    { lat: 48.031864, lng: 17.769355 },
    { lat: 48.031855, lng: 17.768967 },
    { lat: 48.031862, lng: 17.768845 },
    { lat: 48.031843, lng: 17.768415 },
    { lat: 48.031824, lng: 17.768269 },
  ],
  Medveďov: [
    { lat: 47.773996, lng: 17.690003 },
    { lat: 47.7744137, lng: 17.6914063 },
    { lat: 47.7762237, lng: 17.6922661 },
    { lat: 47.7770534, lng: 17.6922657 },
    { lat: 47.7778835, lng: 17.6923582 },
    { lat: 47.7796729, lng: 17.6919279 },
    { lat: 47.7864181, lng: 17.6900847 },
    { lat: 47.7874122, lng: 17.6907214 },
    { lat: 47.7890678, lng: 17.6919624 },
    { lat: 47.7909223, lng: 17.6930821 },
    { lat: 47.7918031, lng: 17.6934264 },
    { lat: 47.7938602, lng: 17.6938194 },
    { lat: 47.7942217, lng: 17.693733 },
    { lat: 47.7946639, lng: 17.6935294 },
    { lat: 47.7955616, lng: 17.6929571 },
    { lat: 47.7969255, lng: 17.6916511 },
    { lat: 47.7976668, lng: 17.6907296 },
    { lat: 47.7980056, lng: 17.6901211 },
    { lat: 47.7982663, lng: 17.6897649 },
    { lat: 47.7985368, lng: 17.6887343 },
    { lat: 47.7989631, lng: 17.6874032 },
    { lat: 47.799243, lng: 17.6867386 },
    { lat: 47.8007184, lng: 17.6867537 },
    { lat: 47.8020296, lng: 17.6864021 },
    { lat: 47.8021348, lng: 17.6867077 },
    { lat: 47.8034912, lng: 17.6882905 },
    { lat: 47.8036214, lng: 17.6883855 },
    { lat: 47.8039073, lng: 17.6881389 },
    { lat: 47.8044724, lng: 17.6878264 },
    { lat: 47.8046753, lng: 17.68782 },
    { lat: 47.8052178, lng: 17.6881123 },
    { lat: 47.8060293, lng: 17.6889147 },
    { lat: 47.8063282, lng: 17.6891422 },
    { lat: 47.8065476, lng: 17.6892213 },
    { lat: 47.8071185, lng: 17.6892318 },
    { lat: 47.8075842, lng: 17.6893321 },
    { lat: 47.8081346, lng: 17.6896437 },
    { lat: 47.808756, lng: 17.6901857 },
    { lat: 47.8099652, lng: 17.6909396 },
    { lat: 47.8102651, lng: 17.6910921 },
    { lat: 47.8106448, lng: 17.6911517 },
    { lat: 47.8106544, lng: 17.6911227 },
    { lat: 47.8108515, lng: 17.6906802 },
    { lat: 47.8113971, lng: 17.6894551 },
    { lat: 47.8116092, lng: 17.6891481 },
    { lat: 47.8118073, lng: 17.6889243 },
    { lat: 47.8123641, lng: 17.6886038 },
    { lat: 47.8125156, lng: 17.6884285 },
    { lat: 47.8148429, lng: 17.6822601 },
    { lat: 47.8155319, lng: 17.6806194 },
    { lat: 47.8156948, lng: 17.6800512 },
    { lat: 47.8168707, lng: 17.6805982 },
    { lat: 47.8175706, lng: 17.6811875 },
    { lat: 47.818002, lng: 17.6816688 },
    { lat: 47.8183271, lng: 17.681849 },
    { lat: 47.8189952, lng: 17.6820761 },
    { lat: 47.819058, lng: 17.6816209 },
    { lat: 47.8191448, lng: 17.680778 },
    { lat: 47.8191152, lng: 17.6798284 },
    { lat: 47.8190392, lng: 17.6794985 },
    { lat: 47.8186993, lng: 17.6794805 },
    { lat: 47.8183037, lng: 17.6792078 },
    { lat: 47.818131, lng: 17.6788673 },
    { lat: 47.8180074, lng: 17.6783934 },
    { lat: 47.817937, lng: 17.6778893 },
    { lat: 47.8178749, lng: 17.6766372 },
    { lat: 47.8177361, lng: 17.6750842 },
    { lat: 47.8177215, lng: 17.6734939 },
    { lat: 47.8176572, lng: 17.6725144 },
    { lat: 47.8174194, lng: 17.6713054 },
    { lat: 47.8168695, lng: 17.6693924 },
    { lat: 47.8162289, lng: 17.6675504 },
    { lat: 47.8156218, lng: 17.6664281 },
    { lat: 47.8143537, lng: 17.6649639 },
    { lat: 47.8144202, lng: 17.6647484 },
    { lat: 47.8144034, lng: 17.6647332 },
    { lat: 47.8137414, lng: 17.6641954 },
    { lat: 47.8131534, lng: 17.6638047 },
    { lat: 47.8132895, lng: 17.6629385 },
    { lat: 47.8127461, lng: 17.6622749 },
    { lat: 47.812076, lng: 17.6616083 },
    { lat: 47.8117176, lng: 17.661064 },
    { lat: 47.8115013, lng: 17.6604658 },
    { lat: 47.811283, lng: 17.6594812 },
    { lat: 47.8109786, lng: 17.6593446 },
    { lat: 47.8110526, lng: 17.6583905 },
    { lat: 47.811006, lng: 17.656999 },
    { lat: 47.8107735, lng: 17.655465 },
    { lat: 47.810721, lng: 17.6549051 },
    { lat: 47.8106651, lng: 17.6543783 },
    { lat: 47.8104268, lng: 17.6537351 },
    { lat: 47.8089148, lng: 17.6520975 },
    { lat: 47.8084736, lng: 17.6516196 },
    { lat: 47.8079577, lng: 17.6514796 },
    { lat: 47.8069371, lng: 17.6516415 },
    { lat: 47.8064036, lng: 17.6518618 },
    { lat: 47.8049829, lng: 17.6526061 },
    { lat: 47.8044679, lng: 17.6526412 },
    { lat: 47.8040718, lng: 17.6522529 },
    { lat: 47.8037452, lng: 17.6507888 },
    { lat: 47.8031363, lng: 17.6495197 },
    { lat: 47.8027432, lng: 17.6477731 },
    { lat: 47.7961537, lng: 17.6469363 },
    { lat: 47.7953776, lng: 17.6469421 },
    { lat: 47.7945452, lng: 17.6489183 },
    { lat: 47.7933649, lng: 17.6514134 },
    { lat: 47.7927487, lng: 17.6528686 },
    { lat: 47.791091, lng: 17.6565667 },
    { lat: 47.789457, lng: 17.660157 },
    { lat: 47.787536, lng: 17.663548 },
    { lat: 47.785542, lng: 17.666842 },
    { lat: 47.7835872, lng: 17.6703119 },
    { lat: 47.778452, lng: 17.679427 },
    { lat: 47.777913, lng: 17.680711 },
    { lat: 47.776836, lng: 17.684036 },
    { lat: 47.776052, lng: 17.685951 },
    { lat: 47.775193, lng: 17.687793 },
    { lat: 47.773996, lng: 17.690003 },
  ],
  Oľdza: [
    { lat: 48.0753571, lng: 17.4598315 },
    { lat: 48.0756494, lng: 17.4598999 },
    { lat: 48.0758022, lng: 17.4600085 },
    { lat: 48.0764867, lng: 17.4609663 },
    { lat: 48.0771091, lng: 17.4612834 },
    { lat: 48.0774551, lng: 17.4613938 },
    { lat: 48.0778764, lng: 17.4613757 },
    { lat: 48.0787328, lng: 17.4609802 },
    { lat: 48.0790263, lng: 17.4607684 },
    { lat: 48.0793851, lng: 17.460345 },
    { lat: 48.0799366, lng: 17.4593924 },
    { lat: 48.0801788, lng: 17.4591568 },
    { lat: 48.0804331, lng: 17.4590898 },
    { lat: 48.0806847, lng: 17.4591732 },
    { lat: 48.0810516, lng: 17.4594345 },
    { lat: 48.0814989, lng: 17.4599051 },
    { lat: 48.0816779, lng: 17.4599979 },
    { lat: 48.082052, lng: 17.4599347 },
    { lat: 48.0822802, lng: 17.4598117 },
    { lat: 48.0824972, lng: 17.459482 },
    { lat: 48.0835954, lng: 17.456631 },
    { lat: 48.0841424, lng: 17.4537994 },
    { lat: 48.0845379, lng: 17.4528437 },
    { lat: 48.0847751, lng: 17.4524951 },
    { lat: 48.0854415, lng: 17.4511396 },
    { lat: 48.0854737, lng: 17.4506952 },
    { lat: 48.0855738, lng: 17.4503232 },
    { lat: 48.0856494, lng: 17.4501816 },
    { lat: 48.0857433, lng: 17.450149 },
    { lat: 48.0862077, lng: 17.4502765 },
    { lat: 48.0864902, lng: 17.4502431 },
    { lat: 48.0869161, lng: 17.449982 },
    { lat: 48.0870688, lng: 17.44976 },
    { lat: 48.0873381, lng: 17.4487055 },
    { lat: 48.0878812, lng: 17.4480597 },
    { lat: 48.087977, lng: 17.4477777 },
    { lat: 48.0881264, lng: 17.446808 },
    { lat: 48.0885361, lng: 17.445777 },
    { lat: 48.088595, lng: 17.4453283 },
    { lat: 48.0885583, lng: 17.4447631 },
    { lat: 48.0886084, lng: 17.4444737 },
    { lat: 48.0887296, lng: 17.4442519 },
    { lat: 48.0891898, lng: 17.4438593 },
    { lat: 48.0904362, lng: 17.439595 },
    { lat: 48.0896749, lng: 17.4380625 },
    { lat: 48.0903638, lng: 17.4354719 },
    { lat: 48.0905017, lng: 17.435281 },
    { lat: 48.0906465, lng: 17.4348326 },
    { lat: 48.0906274, lng: 17.4334799 },
    { lat: 48.0906615, lng: 17.4332068 },
    { lat: 48.0907804, lng: 17.4330193 },
    { lat: 48.0911718, lng: 17.4328043 },
    { lat: 48.0916638, lng: 17.4323116 },
    { lat: 48.0918952, lng: 17.4319795 },
    { lat: 48.0920941, lng: 17.4315438 },
    { lat: 48.0922582, lng: 17.4309272 },
    { lat: 48.0922732, lng: 17.4305491 },
    { lat: 48.0916845, lng: 17.4291037 },
    { lat: 48.0913677, lng: 17.4281929 },
    { lat: 48.0914465, lng: 17.4276325 },
    { lat: 48.091575, lng: 17.4271455 },
    { lat: 48.0917058, lng: 17.4267944 },
    { lat: 48.0933999, lng: 17.4275535 },
    { lat: 48.09475, lng: 17.4281516 },
    { lat: 48.0977499, lng: 17.4189914 },
    { lat: 48.0989859, lng: 17.4199009 },
    { lat: 48.1006574, lng: 17.4208363 },
    { lat: 48.1011696, lng: 17.4192815 },
    { lat: 48.1016709, lng: 17.4184818 },
    { lat: 48.1019885, lng: 17.4178774 },
    { lat: 48.1017887, lng: 17.4177252 },
    { lat: 48.1002456, lng: 17.4164359 },
    { lat: 48.0990164, lng: 17.4151661 },
    { lat: 48.0971906, lng: 17.4135135 },
    { lat: 48.0967028, lng: 17.4129587 },
    { lat: 48.0951195, lng: 17.4115585 },
    { lat: 48.0934364, lng: 17.4098406 },
    { lat: 48.0908512, lng: 17.4069043 },
    { lat: 48.0891785, lng: 17.404811 },
    { lat: 48.0879632, lng: 17.4034451 },
    { lat: 48.0879894, lng: 17.4033791 },
    { lat: 48.0877632, lng: 17.4030976 },
    { lat: 48.0853821, lng: 17.4010376 },
    { lat: 48.0839244, lng: 17.3995927 },
    { lat: 48.081231, lng: 17.3963992 },
    { lat: 48.0799338, lng: 17.3951476 },
    { lat: 48.077588, lng: 17.3926643 },
    { lat: 48.0758927, lng: 17.3945471 },
    { lat: 48.0749204, lng: 17.3954124 },
    { lat: 48.0746168, lng: 17.3958839 },
    { lat: 48.0741971, lng: 17.3966873 },
    { lat: 48.0740117, lng: 17.3971501 },
    { lat: 48.0733856, lng: 17.3990791 },
    { lat: 48.0731099, lng: 17.3997704 },
    { lat: 48.0724465, lng: 17.4008876 },
    { lat: 48.0716612, lng: 17.4018006 },
    { lat: 48.071693, lng: 17.4019207 },
    { lat: 48.0705412, lng: 17.4027927 },
    { lat: 48.0697462, lng: 17.403622 },
    { lat: 48.0690578, lng: 17.4051215 },
    { lat: 48.0680502, lng: 17.4084923 },
    { lat: 48.067066, lng: 17.412796 },
    { lat: 48.066543, lng: 17.4152713 },
    { lat: 48.0664698, lng: 17.4158981 },
    { lat: 48.066431, lng: 17.41648 },
    { lat: 48.0663179, lng: 17.4171695 },
    { lat: 48.0664195, lng: 17.4172575 },
    { lat: 48.070583, lng: 17.4203092 },
    { lat: 48.073449, lng: 17.4224862 },
    { lat: 48.0752899, lng: 17.4239762 },
    { lat: 48.0762774, lng: 17.4250567 },
    { lat: 48.0771277, lng: 17.4258704 },
    { lat: 48.0782124, lng: 17.4273176 },
    { lat: 48.0785645, lng: 17.4278331 },
    { lat: 48.0788371, lng: 17.4284466 },
    { lat: 48.0790961, lng: 17.4292663 },
    { lat: 48.0791584, lng: 17.4309543 },
    { lat: 48.0791068, lng: 17.4323668 },
    { lat: 48.0782543, lng: 17.4374217 },
    { lat: 48.0773319, lng: 17.4422432 },
    { lat: 48.0768225, lng: 17.4454583 },
    { lat: 48.0760782, lng: 17.4514795 },
    { lat: 48.0754259, lng: 17.4590623 },
    { lat: 48.0753571, lng: 17.4598315 },
  ],
  Vydrany: [
    { lat: 48.0868698, lng: 17.6630168 },
    { lat: 48.0846955, lng: 17.6590804 },
    { lat: 48.0796657, lng: 17.6517448 },
    { lat: 48.0742532, lng: 17.6455299 },
    { lat: 48.072454, lng: 17.6438454 },
    { lat: 48.0707227, lng: 17.6423552 },
    { lat: 48.0690531, lng: 17.6394516 },
    { lat: 48.0686642, lng: 17.638926 },
    { lat: 48.0672365, lng: 17.6372754 },
    { lat: 48.0636239, lng: 17.6322352 },
    { lat: 48.0595358, lng: 17.6272861 },
    { lat: 48.055663, lng: 17.6227986 },
    { lat: 48.0532844, lng: 17.6197622 },
    { lat: 48.0471946, lng: 17.6129471 },
    { lat: 48.039505, lng: 17.6025694 },
    { lat: 48.0384612, lng: 17.6009644 },
    { lat: 48.0318748, lng: 17.5922025 },
    { lat: 48.02789, lng: 17.5868013 },
    { lat: 48.0265563, lng: 17.5848338 },
    { lat: 48.0253328, lng: 17.5836367 },
    { lat: 48.0243103, lng: 17.5829054 },
    { lat: 48.0229418, lng: 17.5820549 },
    { lat: 48.0200847, lng: 17.580425 },
    { lat: 48.0193284, lng: 17.57972 },
    { lat: 48.0179616, lng: 17.5789198 },
    { lat: 48.0173782, lng: 17.5786759 },
    { lat: 48.0169763, lng: 17.5784299 },
    { lat: 48.0163318, lng: 17.5778861 },
    { lat: 48.0153622, lng: 17.5767614 },
    { lat: 48.014747, lng: 17.576199 },
    { lat: 48.0138724, lng: 17.5783477 },
    { lat: 48.0127302, lng: 17.5806587 },
    { lat: 48.0139874, lng: 17.582161 },
    { lat: 48.0137854, lng: 17.5828587 },
    { lat: 48.0138219, lng: 17.5850076 },
    { lat: 48.0138883, lng: 17.5860869 },
    { lat: 48.0133682, lng: 17.5886077 },
    { lat: 48.0129416, lng: 17.590383 },
    { lat: 48.0134496, lng: 17.5906123 },
    { lat: 48.013892, lng: 17.5909084 },
    { lat: 48.0134535, lng: 17.5924823 },
    { lat: 48.0128182, lng: 17.5944226 },
    { lat: 48.0127437, lng: 17.5946528 },
    { lat: 48.0119853, lng: 17.5982235 },
    { lat: 48.0118572, lng: 17.5991041 },
    { lat: 48.0116249, lng: 17.5999043 },
    { lat: 48.0111665, lng: 17.6011487 },
    { lat: 48.0110169, lng: 17.6019213 },
    { lat: 48.0109648, lng: 17.6028392 },
    { lat: 48.011053, lng: 17.6033592 },
    { lat: 48.0110421, lng: 17.6037917 },
    { lat: 48.0108143, lng: 17.6053215 },
    { lat: 48.0115204, lng: 17.6056644 },
    { lat: 48.0140527, lng: 17.6072372 },
    { lat: 48.0137777, lng: 17.6083319 },
    { lat: 48.0137659, lng: 17.6083766 },
    { lat: 48.0139449, lng: 17.6084902 },
    { lat: 48.0144573, lng: 17.6087191 },
    { lat: 48.0149812, lng: 17.6091247 },
    { lat: 48.0164082, lng: 17.6105946 },
    { lat: 48.0181547, lng: 17.6114763 },
    { lat: 48.0190915, lng: 17.6123422 },
    { lat: 48.0209876, lng: 17.613519 },
    { lat: 48.0211708, lng: 17.613685 },
    { lat: 48.0220908, lng: 17.6146045 },
    { lat: 48.0228357, lng: 17.6160919 },
    { lat: 48.0244699, lng: 17.6188532 },
    { lat: 48.0286359, lng: 17.6222607 },
    { lat: 48.0313381, lng: 17.6243805 },
    { lat: 48.0324783, lng: 17.6251784 },
    { lat: 48.0332811, lng: 17.6262118 },
    { lat: 48.0361582, lng: 17.6303103 },
    { lat: 48.0434132, lng: 17.6373423 },
    { lat: 48.0466242, lng: 17.6407273 },
    { lat: 48.0486242, lng: 17.6430915 },
    { lat: 48.0490987, lng: 17.6440581 },
    { lat: 48.0492184, lng: 17.6445794 },
    { lat: 48.0494624, lng: 17.6446503 },
    { lat: 48.0505332, lng: 17.6454049 },
    { lat: 48.0521463, lng: 17.6467225 },
    { lat: 48.0550173, lng: 17.6492006 },
    { lat: 48.0576683, lng: 17.6517385 },
    { lat: 48.0606315, lng: 17.6549278 },
    { lat: 48.0612828, lng: 17.6555345 },
    { lat: 48.0667497, lng: 17.6598233 },
    { lat: 48.0682205, lng: 17.6612316 },
    { lat: 48.0701205, lng: 17.6632336 },
    { lat: 48.0738664, lng: 17.6675265 },
    { lat: 48.075711, lng: 17.669919 },
    { lat: 48.075737, lng: 17.669798 },
    { lat: 48.075812, lng: 17.669601 },
    { lat: 48.075871, lng: 17.669445 },
    { lat: 48.075943, lng: 17.669341 },
    { lat: 48.075997, lng: 17.66909 },
    { lat: 48.076106, lng: 17.668812 },
    { lat: 48.076153, lng: 17.668766 },
    { lat: 48.07618, lng: 17.668674 },
    { lat: 48.076236, lng: 17.668582 },
    { lat: 48.076365, lng: 17.668366 },
    { lat: 48.076496, lng: 17.668136 },
    { lat: 48.076595, lng: 17.668024 },
    { lat: 48.076746, lng: 17.667828 },
    { lat: 48.076824, lng: 17.667747 },
    { lat: 48.077066, lng: 17.66751 },
    { lat: 48.077129, lng: 17.667475 },
    { lat: 48.07727, lng: 17.667326 },
    { lat: 48.077516, lng: 17.667144 },
    { lat: 48.077797, lng: 17.667059 },
    { lat: 48.0776717, lng: 17.6663306 },
    { lat: 48.0777595, lng: 17.6659299 },
    { lat: 48.0785808, lng: 17.6640021 },
    { lat: 48.0789777, lng: 17.6634559 },
    { lat: 48.0811095, lng: 17.6616975 },
    { lat: 48.0822879, lng: 17.661084 },
    { lat: 48.0824708, lng: 17.6610457 },
    { lat: 48.0827595, lng: 17.6611475 },
    { lat: 48.0833736, lng: 17.6615221 },
    { lat: 48.0836997, lng: 17.6614654 },
    { lat: 48.0841913, lng: 17.6615818 },
    { lat: 48.0845258, lng: 17.6615839 },
    { lat: 48.0856078, lng: 17.6626472 },
    { lat: 48.0858979, lng: 17.6627901 },
    { lat: 48.0867865, lng: 17.6630023 },
    { lat: 48.0868698, lng: 17.6630168 },
  ],
  Rohovce: [
    { lat: 48.0009573, lng: 17.394161 },
    { lat: 47.99914, lng: 17.3921104 },
    { lat: 47.9983161, lng: 17.3913779 },
    { lat: 47.9982611, lng: 17.3912099 },
    { lat: 47.9979898, lng: 17.3909122 },
    { lat: 47.9978657, lng: 17.3895201 },
    { lat: 47.9970461, lng: 17.3876973 },
    { lat: 47.9937145, lng: 17.3831918 },
    { lat: 47.9937009, lng: 17.3831727 },
    { lat: 47.9930215, lng: 17.3841492 },
    { lat: 47.9926239, lng: 17.3845604 },
    { lat: 47.9919478, lng: 17.3850855 },
    { lat: 47.9916085, lng: 17.3854226 },
    { lat: 47.9912813, lng: 17.385858 },
    { lat: 47.9904712, lng: 17.3871451 },
    { lat: 47.9898086, lng: 17.3878934 },
    { lat: 47.9895025, lng: 17.3885137 },
    { lat: 47.9895378, lng: 17.3890703 },
    { lat: 47.989301, lng: 17.3895229 },
    { lat: 47.9892869, lng: 17.3897925 },
    { lat: 47.9889577, lng: 17.3907635 },
    { lat: 47.9884593, lng: 17.3915887 },
    { lat: 47.9877591, lng: 17.3923822 },
    { lat: 47.9870239, lng: 17.3923671 },
    { lat: 47.987044, lng: 17.3923949 },
    { lat: 47.9875816, lng: 17.3931372 },
    { lat: 47.9870082, lng: 17.3937631 },
    { lat: 47.9863749, lng: 17.3924245 },
    { lat: 47.9859594, lng: 17.3924796 },
    { lat: 47.9848457, lng: 17.3924102 },
    { lat: 47.9847154, lng: 17.3922307 },
    { lat: 47.9839451, lng: 17.3917849 },
    { lat: 47.9836131, lng: 17.3910953 },
    { lat: 47.9832418, lng: 17.3903233 },
    { lat: 47.9828044, lng: 17.390854 },
    { lat: 47.981533, lng: 17.3928061 },
    { lat: 47.9793058, lng: 17.3906569 },
    { lat: 47.9786395, lng: 17.3895179 },
    { lat: 47.9781373, lng: 17.3888552 },
    { lat: 47.9771149, lng: 17.3880585 },
    { lat: 47.9757729, lng: 17.387315 },
    { lat: 47.9749381, lng: 17.3886754 },
    { lat: 47.974841, lng: 17.3886053 },
    { lat: 47.9746983, lng: 17.388791 },
    { lat: 47.9747806, lng: 17.3889412 },
    { lat: 47.9733385, lng: 17.3913242 },
    { lat: 47.9729079, lng: 17.3920301 },
    { lat: 47.9720501, lng: 17.3933868 },
    { lat: 47.9712474, lng: 17.3947633 },
    { lat: 47.9704987, lng: 17.3960159 },
    { lat: 47.9699469, lng: 17.3969445 },
    { lat: 47.9691266, lng: 17.398287 },
    { lat: 47.9666218, lng: 17.4024836 },
    { lat: 47.966738, lng: 17.4033986 },
    { lat: 47.9663436, lng: 17.4055026 },
    { lat: 47.9656712, lng: 17.4081368 },
    { lat: 47.9653695, lng: 17.4098281 },
    { lat: 47.9652474, lng: 17.4113315 },
    { lat: 47.9651861, lng: 17.4116347 },
    { lat: 47.9635382, lng: 17.4127221 },
    { lat: 47.9616445, lng: 17.4140758 },
    { lat: 47.961062, lng: 17.4140316 },
    { lat: 47.9601054, lng: 17.4146015 },
    { lat: 47.959746, lng: 17.4150489 },
    { lat: 47.9596329, lng: 17.4151139 },
    { lat: 47.9593941, lng: 17.4150562 },
    { lat: 47.959238, lng: 17.4151006 },
    { lat: 47.959063, lng: 17.4152401 },
    { lat: 47.9578602, lng: 17.4166789 },
    { lat: 47.9526564, lng: 17.4166035 },
    { lat: 47.9518641, lng: 17.416592 },
    { lat: 47.9512915, lng: 17.4180292 },
    { lat: 47.9510601, lng: 17.4183241 },
    { lat: 47.9509091, lng: 17.4183823 },
    { lat: 47.9505061, lng: 17.4192854 },
    { lat: 47.9489729, lng: 17.4214735 },
    { lat: 47.9484737, lng: 17.4223711 },
    { lat: 47.9479741, lng: 17.4234738 },
    { lat: 47.9472249, lng: 17.4254911 },
    { lat: 47.946735, lng: 17.4268295 },
    { lat: 47.9472638, lng: 17.4276795 },
    { lat: 47.947623, lng: 17.4280454 },
    { lat: 47.9481567, lng: 17.4282447 },
    { lat: 47.9488926, lng: 17.4284089 },
    { lat: 47.9490105, lng: 17.4285878 },
    { lat: 47.9497121, lng: 17.4297539 },
    { lat: 47.9507019, lng: 17.4313695 },
    { lat: 47.9519982, lng: 17.4314165 },
    { lat: 47.9530191, lng: 17.4307915 },
    { lat: 47.9532097, lng: 17.4310321 },
    { lat: 47.9536417, lng: 17.4313668 },
    { lat: 47.9539493, lng: 17.4318769 },
    { lat: 47.9542056, lng: 17.4321854 },
    { lat: 47.9544141, lng: 17.4326285 },
    { lat: 47.9549928, lng: 17.4334352 },
    { lat: 47.9551443, lng: 17.4334715 },
    { lat: 47.9556082, lng: 17.4341401 },
    { lat: 47.9580449, lng: 17.438364 },
    { lat: 47.9572513, lng: 17.4408664 },
    { lat: 47.9569255, lng: 17.4415152 },
    { lat: 47.9568206, lng: 17.4416413 },
    { lat: 47.9607592, lng: 17.4456374 },
    { lat: 47.9610226, lng: 17.445204 },
    { lat: 47.9616771, lng: 17.4447111 },
    { lat: 47.9621228, lng: 17.4440456 },
    { lat: 47.9673774, lng: 17.4457363 },
    { lat: 47.9676869, lng: 17.445858 },
    { lat: 47.9677914, lng: 17.4451215 },
    { lat: 47.9704062, lng: 17.4460443 },
    { lat: 47.9708581, lng: 17.4463069 },
    { lat: 47.9711765, lng: 17.4464975 },
    { lat: 47.9714492, lng: 17.4466262 },
    { lat: 47.9720443, lng: 17.4450125 },
    { lat: 47.9722928, lng: 17.4448202 },
    { lat: 47.9729218, lng: 17.4446805 },
    { lat: 47.9735873, lng: 17.444128 },
    { lat: 47.9738551, lng: 17.4441154 },
    { lat: 47.9752057, lng: 17.4448422 },
    { lat: 47.9761081, lng: 17.4451871 },
    { lat: 47.9767774, lng: 17.4453034 },
    { lat: 47.9772518, lng: 17.4455638 },
    { lat: 47.9781176, lng: 17.4461981 },
    { lat: 47.9781517, lng: 17.4461689 },
    { lat: 47.9810217, lng: 17.4419706 },
    { lat: 47.9823856, lng: 17.4383706 },
    { lat: 47.9845218, lng: 17.4357437 },
    { lat: 47.9869092, lng: 17.4317337 },
    { lat: 47.9888106, lng: 17.4314139 },
    { lat: 47.9898456, lng: 17.4271741 },
    { lat: 47.9907971, lng: 17.4242712 },
    { lat: 47.991104, lng: 17.4228841 },
    { lat: 47.993363, lng: 17.4220891 },
    { lat: 47.9943952, lng: 17.4220179 },
    { lat: 47.996496, lng: 17.42245 },
    { lat: 47.9977971, lng: 17.4222961 },
    { lat: 47.9989989, lng: 17.4215011 },
    { lat: 47.9995386, lng: 17.4197461 },
    { lat: 47.9999901, lng: 17.417581 },
    { lat: 48.0008692, lng: 17.4144709 },
    { lat: 48.0020592, lng: 17.4092099 },
    { lat: 48.0021325, lng: 17.4092782 },
    { lat: 48.0021854, lng: 17.409328 },
    { lat: 48.0024356, lng: 17.4087126 },
    { lat: 48.0033465, lng: 17.4074614 },
    { lat: 48.0035623, lng: 17.4069604 },
    { lat: 48.0043153, lng: 17.4063513 },
    { lat: 48.0045278, lng: 17.4054712 },
    { lat: 48.0047078, lng: 17.4043352 },
    { lat: 48.004692, lng: 17.4040614 },
    { lat: 48.0047811, lng: 17.4035236 },
    { lat: 48.0047729, lng: 17.4031242 },
    { lat: 48.004553, lng: 17.4025738 },
    { lat: 48.0041485, lng: 17.4028487 },
    { lat: 48.0024936, lng: 17.403604 },
    { lat: 48.0019904, lng: 17.4039084 },
    { lat: 48.0018347, lng: 17.4040639 },
    { lat: 48.0019387, lng: 17.4022009 },
    { lat: 48.001273, lng: 17.4010195 },
    { lat: 47.9991387, lng: 17.3989195 },
    { lat: 48.000938, lng: 17.3942128 },
    { lat: 48.0009573, lng: 17.394161 },
  ],
  ZlatéKlasy: [
    { lat: 48.141606, lng: 17.470099 },
    { lat: 48.141894, lng: 17.469059 },
    { lat: 48.14274, lng: 17.467546 },
    { lat: 48.143704, lng: 17.466737 },
    { lat: 48.144103, lng: 17.466642 },
    { lat: 48.144211, lng: 17.466654 },
    { lat: 48.144936, lng: 17.466684 },
    { lat: 48.146845, lng: 17.467145 },
    { lat: 48.147367, lng: 17.466995 },
    { lat: 48.14876, lng: 17.466975 },
    { lat: 48.149125, lng: 17.466855 },
    { lat: 48.14947, lng: 17.466796 },
    { lat: 48.149808, lng: 17.466834 },
    { lat: 48.14994, lng: 17.466866 },
    { lat: 48.150326, lng: 17.467045 },
    { lat: 48.150916, lng: 17.467605 },
    { lat: 48.151382, lng: 17.467722 },
    { lat: 48.151836, lng: 17.46761 },
    { lat: 48.152296, lng: 17.467401 },
    { lat: 48.152472, lng: 17.467355 },
    { lat: 48.152834, lng: 17.46709 },
    { lat: 48.153546, lng: 17.466439 },
    { lat: 48.154116, lng: 17.465628 },
    { lat: 48.154798, lng: 17.464045 },
    { lat: 48.1549953, lng: 17.4634468 },
    { lat: 48.155061, lng: 17.463247 },
    { lat: 48.155133, lng: 17.462706 },
    { lat: 48.155113, lng: 17.462311 },
    { lat: 48.154919, lng: 17.461014 },
    { lat: 48.15453, lng: 17.459182 },
    { lat: 48.154233, lng: 17.458236 },
    { lat: 48.153635, lng: 17.457084 },
    { lat: 48.153094, lng: 17.456487 },
    { lat: 48.151795, lng: 17.455224 },
    { lat: 48.150992, lng: 17.454222 },
    { lat: 48.1504, lng: 17.45317 },
    { lat: 48.149098, lng: 17.451419 },
    { lat: 48.148692, lng: 17.450978 },
    { lat: 48.147355, lng: 17.449797 },
    { lat: 48.146448, lng: 17.448496 },
    { lat: 48.146129, lng: 17.447796 },
    { lat: 48.145575, lng: 17.446375 },
    { lat: 48.145373, lng: 17.445545 },
    { lat: 48.145118, lng: 17.443563 },
    { lat: 48.145085, lng: 17.441906 },
    { lat: 48.145005, lng: 17.441401 },
    { lat: 48.144319, lng: 17.440068 },
    { lat: 48.144159, lng: 17.438768 },
    { lat: 48.144055, lng: 17.436964 },
    { lat: 48.144045, lng: 17.435967 },
    { lat: 48.144085, lng: 17.4352033 },
    { lat: 48.1436132, lng: 17.4339349 },
    { lat: 48.1408855, lng: 17.4304736 },
    { lat: 48.1408788, lng: 17.4301779 },
    { lat: 48.1397377, lng: 17.4293579 },
    { lat: 48.1393461, lng: 17.4288232 },
    { lat: 48.1390861, lng: 17.4287495 },
    { lat: 48.1381456, lng: 17.4276937 },
    { lat: 48.1368827, lng: 17.4265991 },
    { lat: 48.135417, lng: 17.4243279 },
    { lat: 48.1334033, lng: 17.4220219 },
    { lat: 48.1314483, lng: 17.4191086 },
    { lat: 48.1306136, lng: 17.4179457 },
    { lat: 48.1296511, lng: 17.4163985 },
    { lat: 48.1289445, lng: 17.4150191 },
    { lat: 48.1275167, lng: 17.4119289 },
    { lat: 48.1271956, lng: 17.4111607 },
    { lat: 48.1272322, lng: 17.4110324 },
    { lat: 48.1268959, lng: 17.4102378 },
    { lat: 48.1260666, lng: 17.4083793 },
    { lat: 48.1255866, lng: 17.4074784 },
    { lat: 48.1252414, lng: 17.4068824 },
    { lat: 48.1228732, lng: 17.40332 },
    { lat: 48.1223902, lng: 17.4027003 },
    { lat: 48.1219201, lng: 17.4022509 },
    { lat: 48.1219553, lng: 17.4021471 },
    { lat: 48.1217107, lng: 17.4018784 },
    { lat: 48.1209951, lng: 17.4012639 },
    { lat: 48.1209693, lng: 17.4012306 },
    { lat: 48.1207279, lng: 17.4011155 },
    { lat: 48.119522, lng: 17.4010539 },
    { lat: 48.1189736, lng: 17.4002299 },
    { lat: 48.1188564, lng: 17.4001649 },
    { lat: 48.1183933, lng: 17.4001108 },
    { lat: 48.1167885, lng: 17.4026084 },
    { lat: 48.1144878, lng: 17.4028174 },
    { lat: 48.1142141, lng: 17.4025505 },
    { lat: 48.1134372, lng: 17.4017906 },
    { lat: 48.110704, lng: 17.4057742 },
    { lat: 48.1107693, lng: 17.4058749 },
    { lat: 48.1078674, lng: 17.4104265 },
    { lat: 48.107328, lng: 17.4111961 },
    { lat: 48.1060398, lng: 17.4128153 },
    { lat: 48.1056169, lng: 17.4131906 },
    { lat: 48.1048974, lng: 17.4136912 },
    { lat: 48.1047368, lng: 17.4138612 },
    { lat: 48.1122468, lng: 17.425017 },
    { lat: 48.1140661, lng: 17.4276179 },
    { lat: 48.1140077, lng: 17.4276904 },
    { lat: 48.1141649, lng: 17.4278842 },
    { lat: 48.1141857, lng: 17.4279098 },
    { lat: 48.1162801, lng: 17.4304869 },
    { lat: 48.1192019, lng: 17.4339245 },
    { lat: 48.1214025, lng: 17.4360343 },
    { lat: 48.1214693, lng: 17.4358637 },
    { lat: 48.1229804, lng: 17.4375501 },
    { lat: 48.1226665, lng: 17.4381067 },
    { lat: 48.1230361, lng: 17.4388078 },
    { lat: 48.1229981, lng: 17.4388649 },
    { lat: 48.123221, lng: 17.439218 },
    { lat: 48.1270498, lng: 17.4437776 },
    { lat: 48.1281027, lng: 17.4449148 },
    { lat: 48.128494, lng: 17.445409 },
    { lat: 48.1309535, lng: 17.4477703 },
    { lat: 48.1311999, lng: 17.4479496 },
    { lat: 48.1313473, lng: 17.44888 },
    { lat: 48.1313436, lng: 17.4493486 },
    { lat: 48.1305821, lng: 17.4495507 },
    { lat: 48.1298883, lng: 17.4503807 },
    { lat: 48.1297586, lng: 17.4506982 },
    { lat: 48.129441, lng: 17.4523495 },
    { lat: 48.1291433, lng: 17.4549213 },
    { lat: 48.1294227, lng: 17.4566178 },
    { lat: 48.1313206, lng: 17.4594157 },
    { lat: 48.1307147, lng: 17.460595 },
    { lat: 48.1301187, lng: 17.4613835 },
    { lat: 48.1297517, lng: 17.4617512 },
    { lat: 48.1293705, lng: 17.4620222 },
    { lat: 48.1293906, lng: 17.462194 },
    { lat: 48.1295477, lng: 17.4625966 },
    { lat: 48.12979, lng: 17.4629208 },
    { lat: 48.1304329, lng: 17.4634639 },
    { lat: 48.1318234, lng: 17.4644121 },
    { lat: 48.1325263, lng: 17.4650817 },
    { lat: 48.1331938, lng: 17.4672647 },
    { lat: 48.1325225, lng: 17.4679209 },
    { lat: 48.1322802, lng: 17.4682578 },
    { lat: 48.131663, lng: 17.4695616 },
    { lat: 48.1312731, lng: 17.4708343 },
    { lat: 48.1311072, lng: 17.4721631 },
    { lat: 48.1311527, lng: 17.4728997 },
    { lat: 48.1312283, lng: 17.4733378 },
    { lat: 48.1316494, lng: 17.4752215 },
    { lat: 48.1321257, lng: 17.4764761 },
    { lat: 48.1325492, lng: 17.4771599 },
    { lat: 48.1326422, lng: 17.4776036 },
    { lat: 48.1330013, lng: 17.4779535 },
    { lat: 48.1337014, lng: 17.4783345 },
    { lat: 48.1352251, lng: 17.4782659 },
    { lat: 48.1353746, lng: 17.4782214 },
    { lat: 48.1360799, lng: 17.4777241 },
    { lat: 48.137787, lng: 17.4758771 },
    { lat: 48.1378631, lng: 17.4751498 },
    { lat: 48.1380899, lng: 17.4740454 },
    { lat: 48.1382544, lng: 17.4734955 },
    { lat: 48.1387721, lng: 17.4721755 },
    { lat: 48.1393186, lng: 17.471264 },
    { lat: 48.1412938, lng: 17.4698468 },
    { lat: 48.141606, lng: 17.470099 },
  ],
  VeľkéBlahovo: [
    { lat: 48.0104118, lng: 17.5547977 },
    { lat: 48.0103203, lng: 17.5555707 },
    { lat: 48.0102514, lng: 17.5562057 },
    { lat: 48.0100883, lng: 17.5568002 },
    { lat: 48.0096547, lng: 17.5575933 },
    { lat: 48.0091564, lng: 17.5581335 },
    { lat: 48.0085095, lng: 17.5586151 },
    { lat: 48.0058956, lng: 17.5587596 },
    { lat: 48.0043309, lng: 17.5585031 },
    { lat: 48.0035572, lng: 17.5584456 },
    { lat: 48.0023565, lng: 17.5587256 },
    { lat: 48.0018834, lng: 17.5590065 },
    { lat: 48.0016751, lng: 17.5591229 },
    { lat: 48.0010708, lng: 17.5597574 },
    { lat: 48.0006769, lng: 17.5605721 },
    { lat: 48.0003926, lng: 17.5615271 },
    { lat: 48.0003166, lng: 17.5623513 },
    { lat: 48.0003487, lng: 17.563438 },
    { lat: 48.0004018, lng: 17.5639124 },
    { lat: 48.000571, lng: 17.5650417 },
    { lat: 48.0010911, lng: 17.5667985 },
    { lat: 48.0007549, lng: 17.5675472 },
    { lat: 48.0003861, lng: 17.5692475 },
    { lat: 47.9997673, lng: 17.5711207 },
    { lat: 47.9998942, lng: 17.5713037 },
    { lat: 48.0000509, lng: 17.571707 },
    { lat: 48.0004044, lng: 17.5729248 },
    { lat: 47.9988671, lng: 17.5735567 },
    { lat: 47.9987898, lng: 17.5739016 },
    { lat: 47.9985021, lng: 17.5739392 },
    { lat: 47.9986632, lng: 17.5764989 },
    { lat: 47.998687, lng: 17.5775278 },
    { lat: 47.9985089, lng: 17.5792119 },
    { lat: 47.9986127, lng: 17.5794764 },
    { lat: 47.9996772, lng: 17.5794165 },
    { lat: 47.9999683, lng: 17.5794652 },
    { lat: 48.0009938, lng: 17.5799281 },
    { lat: 48.000635, lng: 17.5814203 },
    { lat: 48.0024262, lng: 17.5826244 },
    { lat: 48.0018394, lng: 17.5840643 },
    { lat: 48.002653, lng: 17.5847484 },
    { lat: 48.00334, lng: 17.5866863 },
    { lat: 48.0036802, lng: 17.5870083 },
    { lat: 48.0051215, lng: 17.5889263 },
    { lat: 48.0051439, lng: 17.5890853 },
    { lat: 48.0065164, lng: 17.5906858 },
    { lat: 48.0073571, lng: 17.5915806 },
    { lat: 48.008097, lng: 17.5911089 },
    { lat: 48.0085754, lng: 17.5914386 },
    { lat: 48.0085348, lng: 17.5920406 },
    { lat: 48.0106004, lng: 17.5932312 },
    { lat: 48.0105593, lng: 17.5935103 },
    { lat: 48.0127217, lng: 17.5946418 },
    { lat: 48.0127437, lng: 17.5946528 },
    { lat: 48.0128182, lng: 17.5944226 },
    { lat: 48.0134535, lng: 17.5924823 },
    { lat: 48.013892, lng: 17.5909084 },
    { lat: 48.0134496, lng: 17.5906123 },
    { lat: 48.0129416, lng: 17.590383 },
    { lat: 48.0133682, lng: 17.5886077 },
    { lat: 48.0138883, lng: 17.5860869 },
    { lat: 48.0138219, lng: 17.5850076 },
    { lat: 48.0137854, lng: 17.5828587 },
    { lat: 48.0139874, lng: 17.582161 },
    { lat: 48.0127302, lng: 17.5806587 },
    { lat: 48.0138724, lng: 17.5783477 },
    { lat: 48.014747, lng: 17.576199 },
    { lat: 48.0153622, lng: 17.5767614 },
    { lat: 48.0163318, lng: 17.5778861 },
    { lat: 48.0169763, lng: 17.5784299 },
    { lat: 48.0173782, lng: 17.5786759 },
    { lat: 48.0179616, lng: 17.5789198 },
    { lat: 48.0193284, lng: 17.57972 },
    { lat: 48.0200847, lng: 17.580425 },
    { lat: 48.0229418, lng: 17.5820549 },
    { lat: 48.0243103, lng: 17.5829054 },
    { lat: 48.0253328, lng: 17.5836367 },
    { lat: 48.0265563, lng: 17.5848338 },
    { lat: 48.02789, lng: 17.5868013 },
    { lat: 48.0318748, lng: 17.5922025 },
    { lat: 48.0384612, lng: 17.6009644 },
    { lat: 48.039505, lng: 17.6025694 },
    { lat: 48.0471946, lng: 17.6129471 },
    { lat: 48.0532844, lng: 17.6197622 },
    { lat: 48.055663, lng: 17.6227986 },
    { lat: 48.0595358, lng: 17.6272861 },
    { lat: 48.0636239, lng: 17.6322352 },
    { lat: 48.0672365, lng: 17.6372754 },
    { lat: 48.0686642, lng: 17.638926 },
    { lat: 48.0690531, lng: 17.6394516 },
    { lat: 48.0707227, lng: 17.6423552 },
    { lat: 48.072454, lng: 17.6438454 },
    { lat: 48.0742532, lng: 17.6455299 },
    { lat: 48.0796657, lng: 17.6517448 },
    { lat: 48.0846955, lng: 17.6590804 },
    { lat: 48.0868698, lng: 17.6630168 },
    { lat: 48.08701, lng: 17.662336 },
    { lat: 48.086757, lng: 17.661651 },
    { lat: 48.086577, lng: 17.661213 },
    { lat: 48.086194, lng: 17.660443 },
    { lat: 48.086023, lng: 17.659562 },
    { lat: 48.085963, lng: 17.65937 },
    { lat: 48.085866, lng: 17.659061 },
    { lat: 48.085715, lng: 17.658552 },
    { lat: 48.085613, lng: 17.658185 },
    { lat: 48.085444, lng: 17.657549 },
    { lat: 48.085306, lng: 17.657038 },
    { lat: 48.085172, lng: 17.656531 },
    { lat: 48.085101, lng: 17.656186 },
    { lat: 48.084966, lng: 17.655526 },
    { lat: 48.084899, lng: 17.655019 },
    { lat: 48.08496, lng: 17.65438 },
    { lat: 48.0839364, lng: 17.6532171 },
    { lat: 48.0839091, lng: 17.6525716 },
    { lat: 48.0839426, lng: 17.651778 },
    { lat: 48.0841374, lng: 17.6508946 },
    { lat: 48.0843847, lng: 17.6504446 },
    { lat: 48.084778, lng: 17.6501464 },
    { lat: 48.0858122, lng: 17.6516495 },
    { lat: 48.0859096, lng: 17.6517788 },
    { lat: 48.0860502, lng: 17.6515729 },
    { lat: 48.0867595, lng: 17.6510216 },
    { lat: 48.0875626, lng: 17.6506837 },
    { lat: 48.087606, lng: 17.650687 },
    { lat: 48.087648, lng: 17.650616 },
    { lat: 48.088184, lng: 17.650055 },
    { lat: 48.088587, lng: 17.649612 },
    { lat: 48.088973, lng: 17.649045 },
    { lat: 48.08997, lng: 17.647463 },
    { lat: 48.0896641, lng: 17.6467875 },
    { lat: 48.0895577, lng: 17.6467141 },
    { lat: 48.0886268, lng: 17.6480811 },
    { lat: 48.0881551, lng: 17.6486411 },
    { lat: 48.0878615, lng: 17.6489455 },
    { lat: 48.087211, lng: 17.6494147 },
    { lat: 48.0868092, lng: 17.6495606 },
    { lat: 48.0864536, lng: 17.6495986 },
    { lat: 48.0862582, lng: 17.6495621 },
    { lat: 48.0858263, lng: 17.6491931 },
    { lat: 48.0855148, lng: 17.6486598 },
    { lat: 48.0854224, lng: 17.6483945 },
    { lat: 48.0853673, lng: 17.6480708 },
    { lat: 48.0854022, lng: 17.6477272 },
    { lat: 48.0855016, lng: 17.6474677 },
    { lat: 48.0855965, lng: 17.6473199 },
    { lat: 48.0875363, lng: 17.6466015 },
    { lat: 48.087995, lng: 17.6462657 },
    { lat: 48.0872606, lng: 17.6451496 },
    { lat: 48.086374, lng: 17.6439656 },
    { lat: 48.084814, lng: 17.6421679 },
    { lat: 48.083939, lng: 17.6412571 },
    { lat: 48.0817939, lng: 17.6378704 },
    { lat: 48.0808777, lng: 17.6378242 },
    { lat: 48.0806924, lng: 17.6377525 },
    { lat: 48.0804271, lng: 17.6375918 },
    { lat: 48.0798684, lng: 17.6369173 },
    { lat: 48.0796934, lng: 17.6363649 },
    { lat: 48.0795875, lng: 17.6357453 },
    { lat: 48.0795932, lng: 17.635282 },
    { lat: 48.0796683, lng: 17.6348081 },
    { lat: 48.0780187, lng: 17.6327023 },
    { lat: 48.075085, lng: 17.6293812 },
    { lat: 48.0747072, lng: 17.6277814 },
    { lat: 48.0731879, lng: 17.6251001 },
    { lat: 48.0722259, lng: 17.6239429 },
    { lat: 48.0715738, lng: 17.6230248 },
    { lat: 48.0711217, lng: 17.6225731 },
    { lat: 48.068987, lng: 17.6198904 },
    { lat: 48.0682342, lng: 17.6200945 },
    { lat: 48.0683102, lng: 17.6198109 },
    { lat: 48.0678986, lng: 17.6194478 },
    { lat: 48.0675817, lng: 17.6188825 },
    { lat: 48.0663137, lng: 17.6172109 },
    { lat: 48.0663464, lng: 17.6170153 },
    { lat: 48.0656196, lng: 17.6160243 },
    { lat: 48.0648815, lng: 17.6142325 },
    { lat: 48.0639249, lng: 17.6112234 },
    { lat: 48.0630678, lng: 17.6088901 },
    { lat: 48.0622213, lng: 17.606943 },
    { lat: 48.0604147, lng: 17.6036929 },
    { lat: 48.0576671, lng: 17.599621 },
    { lat: 48.0544671, lng: 17.5953079 },
    { lat: 48.0497578, lng: 17.589554 },
    { lat: 48.0466574, lng: 17.584012 },
    { lat: 48.0458787, lng: 17.5828357 },
    { lat: 48.0439756, lng: 17.5805066 },
    { lat: 48.0328095, lng: 17.563936 },
    { lat: 48.0328754, lng: 17.5631826 },
    { lat: 48.0314764, lng: 17.561562 },
    { lat: 48.0300042, lng: 17.5607362 },
    { lat: 48.0297648, lng: 17.55952 },
    { lat: 48.029484, lng: 17.5585887 },
    { lat: 48.0284355, lng: 17.5578209 },
    { lat: 48.0277866, lng: 17.5575276 },
    { lat: 48.0271812, lng: 17.5571382 },
    { lat: 48.0267381, lng: 17.5565154 },
    { lat: 48.026472, lng: 17.5563099 },
    { lat: 48.0255915, lng: 17.5551835 },
    { lat: 48.0243872, lng: 17.5538708 },
    { lat: 48.023716, lng: 17.5549773 },
    { lat: 48.0224559, lng: 17.5536736 },
    { lat: 48.0218121, lng: 17.554185 },
    { lat: 48.0213589, lng: 17.5544086 },
    { lat: 48.0199647, lng: 17.5531276 },
    { lat: 48.0197251, lng: 17.5538128 },
    { lat: 48.0189209, lng: 17.5532758 },
    { lat: 48.0188581, lng: 17.5537357 },
    { lat: 48.0187936, lng: 17.5537266 },
    { lat: 48.0182684, lng: 17.5542162 },
    { lat: 48.0176892, lng: 17.5548541 },
    { lat: 48.0178023, lng: 17.5551572 },
    { lat: 48.0174082, lng: 17.5552619 },
    { lat: 48.0144195, lng: 17.5551996 },
    { lat: 48.0131926, lng: 17.5552969 },
    { lat: 48.01168, lng: 17.554971 },
    { lat: 48.0104118, lng: 17.5547977 },
  ],
  Sap: [
    { lat: 47.8132743, lng: 17.6193714 },
    { lat: 47.803402, lng: 17.630927 },
    { lat: 47.801929, lng: 17.633435 },
    { lat: 47.800525, lng: 17.636033 },
    { lat: 47.799197, lng: 17.638714 },
    { lat: 47.797945, lng: 17.641474 },
    { lat: 47.7953776, lng: 17.6469421 },
    { lat: 47.7961537, lng: 17.6469363 },
    { lat: 47.8027432, lng: 17.6477731 },
    { lat: 47.8031363, lng: 17.6495197 },
    { lat: 47.8037452, lng: 17.6507888 },
    { lat: 47.8040718, lng: 17.6522529 },
    { lat: 47.8044679, lng: 17.6526412 },
    { lat: 47.8049829, lng: 17.6526061 },
    { lat: 47.8064036, lng: 17.6518618 },
    { lat: 47.8069371, lng: 17.6516415 },
    { lat: 47.8079577, lng: 17.6514796 },
    { lat: 47.8084736, lng: 17.6516196 },
    { lat: 47.8089148, lng: 17.6520975 },
    { lat: 47.8104268, lng: 17.6537351 },
    { lat: 47.8106651, lng: 17.6543783 },
    { lat: 47.810721, lng: 17.6549051 },
    { lat: 47.8107735, lng: 17.655465 },
    { lat: 47.8121119, lng: 17.6545833 },
    { lat: 47.8132086, lng: 17.6540683 },
    { lat: 47.81374, lng: 17.6538848 },
    { lat: 47.8146111, lng: 17.6537164 },
    { lat: 47.8149968, lng: 17.6534068 },
    { lat: 47.8150886, lng: 17.6525372 },
    { lat: 47.8152616, lng: 17.651795 },
    { lat: 47.8155619, lng: 17.6509047 },
    { lat: 47.816026, lng: 17.6502455 },
    { lat: 47.8170108, lng: 17.6493772 },
    { lat: 47.8171297, lng: 17.6488115 },
    { lat: 47.8178011, lng: 17.6483446 },
    { lat: 47.8202262, lng: 17.6472726 },
    { lat: 47.8208637, lng: 17.646393 },
    { lat: 47.8210818, lng: 17.6458436 },
    { lat: 47.8216343, lng: 17.6434469 },
    { lat: 47.8214701, lng: 17.642195 },
    { lat: 47.8209929, lng: 17.6410807 },
    { lat: 47.8207345, lng: 17.6400155 },
    { lat: 47.8213508, lng: 17.6388218 },
    { lat: 47.8209425, lng: 17.6378135 },
    { lat: 47.8191363, lng: 17.6346949 },
    { lat: 47.8189869, lng: 17.6339974 },
    { lat: 47.8184739, lng: 17.6323367 },
    { lat: 47.8183838, lng: 17.6309378 },
    { lat: 47.8183992, lng: 17.6297444 },
    { lat: 47.8189889, lng: 17.6283601 },
    { lat: 47.8198212, lng: 17.6267489 },
    { lat: 47.8217787, lng: 17.6223765 },
    { lat: 47.8229525, lng: 17.6204364 },
    { lat: 47.8233317, lng: 17.6202563 },
    { lat: 47.8247981, lng: 17.619151 },
    { lat: 47.8260755, lng: 17.6178265 },
    { lat: 47.8269, lng: 17.6141257 },
    { lat: 47.8271259, lng: 17.6126368 },
    { lat: 47.8273406, lng: 17.6121808 },
    { lat: 47.8274792, lng: 17.6117312 },
    { lat: 47.8280397, lng: 17.6091373 },
    { lat: 47.8281119, lng: 17.6090474 },
    { lat: 47.8283907, lng: 17.6080549 },
    { lat: 47.8286441, lng: 17.6075656 },
    { lat: 47.8291143, lng: 17.6069713 },
    { lat: 47.8294668, lng: 17.6067446 },
    { lat: 47.8296625, lng: 17.6066964 },
    { lat: 47.829984, lng: 17.6068848 },
    { lat: 47.8291152, lng: 17.6054802 },
    { lat: 47.8362411, lng: 17.5958041 },
    { lat: 47.837393, lng: 17.597409 },
    { lat: 47.8375358, lng: 17.5977006 },
    { lat: 47.8375703, lng: 17.5974101 },
    { lat: 47.8375533, lng: 17.5968252 },
    { lat: 47.8377482, lng: 17.5960055 },
    { lat: 47.8388521, lng: 17.5931177 },
    { lat: 47.8389356, lng: 17.5931703 },
    { lat: 47.8395099, lng: 17.591804 },
    { lat: 47.839669, lng: 17.591305 },
    { lat: 47.8397306, lng: 17.5909441 },
    { lat: 47.8397107, lng: 17.5905814 },
    { lat: 47.8396121, lng: 17.5901342 },
    { lat: 47.8394557, lng: 17.5895605 },
    { lat: 47.838876, lng: 17.5880516 },
    { lat: 47.8385688, lng: 17.5870258 },
    { lat: 47.8380017, lng: 17.5857875 },
    { lat: 47.8374017, lng: 17.5849865 },
    { lat: 47.8366197, lng: 17.5842172 },
    { lat: 47.8369161, lng: 17.5835902 },
    { lat: 47.8370998, lng: 17.5828789 },
    { lat: 47.8372705, lng: 17.5823737 },
    { lat: 47.837473, lng: 17.581955 },
    { lat: 47.8379461, lng: 17.580456 },
    { lat: 47.8383916, lng: 17.5776239 },
    { lat: 47.8388278, lng: 17.5778951 },
    { lat: 47.8394599, lng: 17.5777664 },
    { lat: 47.8395891, lng: 17.5780736 },
    { lat: 47.8402255, lng: 17.5774839 },
    { lat: 47.8408107, lng: 17.5767629 },
    { lat: 47.8410626, lng: 17.5765888 },
    { lat: 47.8424801, lng: 17.5762065 },
    { lat: 47.8428429, lng: 17.5759106 },
    { lat: 47.8430398, lng: 17.5754609 },
    { lat: 47.844292, lng: 17.5748769 },
    { lat: 47.8447065, lng: 17.575071 },
    { lat: 47.844628, lng: 17.5747282 },
    { lat: 47.8443967, lng: 17.5741837 },
    { lat: 47.8438996, lng: 17.5738297 },
    { lat: 47.8433823, lng: 17.573615 },
    { lat: 47.8425292, lng: 17.5742572 },
    { lat: 47.8425586, lng: 17.5739068 },
    { lat: 47.8425649, lng: 17.5730528 },
    { lat: 47.8425089, lng: 17.5725342 },
    { lat: 47.842102, lng: 17.5716273 },
    { lat: 47.8399284, lng: 17.5672438 },
    { lat: 47.8417868, lng: 17.5628564 },
    { lat: 47.8381413, lng: 17.560544 },
    { lat: 47.8350936, lng: 17.565616 },
    { lat: 47.8281491, lng: 17.5646878 },
    { lat: 47.8217425, lng: 17.5639411 },
    { lat: 47.8208125, lng: 17.5608245 },
    { lat: 47.82022, lng: 17.561274 },
    { lat: 47.819564, lng: 17.561957 },
    { lat: 47.81592, lng: 17.566319 },
    { lat: 47.815636, lng: 17.566709 },
    { lat: 47.815129, lng: 17.567578 },
    { lat: 47.814404, lng: 17.569161 },
    { lat: 47.814121, lng: 17.570101 },
    { lat: 47.814024, lng: 17.570596 },
    { lat: 47.813957, lng: 17.571101 },
    { lat: 47.813917, lng: 17.572131 },
    { lat: 47.814003, lng: 17.573153 },
    { lat: 47.814093, lng: 17.573651 },
    { lat: 47.814361, lng: 17.574601 },
    { lat: 47.814964, lng: 17.576001 },
    { lat: 47.819735, lng: 17.585056 },
    { lat: 47.820164, lng: 17.585974 },
    { lat: 47.820509, lng: 17.586968 },
    { lat: 47.820859, lng: 17.588561 },
    { lat: 47.821234, lng: 17.592059 },
    { lat: 47.821276, lng: 17.593096 },
    { lat: 47.821223, lng: 17.594901 },
    { lat: 47.82132, lng: 17.596355 },
    { lat: 47.82155, lng: 17.597775 },
    { lat: 47.822069, lng: 17.599692 },
    { lat: 47.822326, lng: 17.600838 },
    { lat: 47.822545, lng: 17.602621 },
    { lat: 47.822557, lng: 17.604432 },
    { lat: 47.822442, lng: 17.605768 },
    { lat: 47.822199, lng: 17.607209 },
    { lat: 47.821886, lng: 17.608402 },
    { lat: 47.818944, lng: 17.612502 },
    { lat: 47.817708, lng: 17.614074 },
    { lat: 47.8139211, lng: 17.6185899 },
    { lat: 47.8132743, lng: 17.6193714 },
  ],
  KráľovičoveKračany: [
    { lat: 47.9830311, lng: 17.5673105 },
    { lat: 47.9833504, lng: 17.5675712 },
    { lat: 47.9840164, lng: 17.568116 },
    { lat: 47.9842851, lng: 17.5670903 },
    { lat: 47.9857239, lng: 17.5635298 },
    { lat: 47.9857962, lng: 17.563247 },
    { lat: 47.9861347, lng: 17.5636815 },
    { lat: 47.9862234, lng: 17.5638691 },
    { lat: 47.9864202, lng: 17.5647362 },
    { lat: 47.9867771, lng: 17.5659721 },
    { lat: 47.9875111, lng: 17.5672257 },
    { lat: 47.988046, lng: 17.5662001 },
    { lat: 47.9884438, lng: 17.5665219 },
    { lat: 47.9888688, lng: 17.5665113 },
    { lat: 47.9895546, lng: 17.5663561 },
    { lat: 47.9899487, lng: 17.566199 },
    { lat: 47.990774, lng: 17.5656976 },
    { lat: 47.99128, lng: 17.565488 },
    { lat: 47.991859, lng: 17.5655729 },
    { lat: 47.9923418, lng: 17.5659369 },
    { lat: 47.9923529, lng: 17.5661422 },
    { lat: 47.9924376, lng: 17.5663209 },
    { lat: 47.992476, lng: 17.5670096 },
    { lat: 47.9922542, lng: 17.5686105 },
    { lat: 47.992451, lng: 17.5697089 },
    { lat: 47.9926089, lng: 17.5701836 },
    { lat: 47.99298, lng: 17.5705466 },
    { lat: 47.9932495, lng: 17.5707177 },
    { lat: 47.9938436, lng: 17.5709155 },
    { lat: 47.9946056, lng: 17.5709082 },
    { lat: 47.9950252, lng: 17.5708134 },
    { lat: 47.9954837, lng: 17.5705675 },
    { lat: 47.9963929, lng: 17.570447 },
    { lat: 47.9969023, lng: 17.5705376 },
    { lat: 47.9975156, lng: 17.570874 },
    { lat: 47.997899, lng: 17.5712899 },
    { lat: 47.9980476, lng: 17.5715632 },
    { lat: 47.9982551, lng: 17.5721779 },
    { lat: 47.998377, lng: 17.5726898 },
    { lat: 47.9985021, lng: 17.5739392 },
    { lat: 47.9987898, lng: 17.5739016 },
    { lat: 47.9988671, lng: 17.5735567 },
    { lat: 48.0004044, lng: 17.5729248 },
    { lat: 48.0000509, lng: 17.571707 },
    { lat: 47.9998942, lng: 17.5713037 },
    { lat: 47.9997673, lng: 17.5711207 },
    { lat: 48.0003861, lng: 17.5692475 },
    { lat: 48.0007549, lng: 17.5675472 },
    { lat: 48.0010911, lng: 17.5667985 },
    { lat: 48.000571, lng: 17.5650417 },
    { lat: 48.0004018, lng: 17.5639124 },
    { lat: 48.0003487, lng: 17.563438 },
    { lat: 48.0003166, lng: 17.5623513 },
    { lat: 48.0003926, lng: 17.5615271 },
    { lat: 48.0006769, lng: 17.5605721 },
    { lat: 48.0010708, lng: 17.5597574 },
    { lat: 48.0016751, lng: 17.5591229 },
    { lat: 48.0018834, lng: 17.5590065 },
    { lat: 48.0023565, lng: 17.5587256 },
    { lat: 48.0035572, lng: 17.5584456 },
    { lat: 48.0043309, lng: 17.5585031 },
    { lat: 48.0058956, lng: 17.5587596 },
    { lat: 48.0085095, lng: 17.5586151 },
    { lat: 48.0091564, lng: 17.5581335 },
    { lat: 48.0096547, lng: 17.5575933 },
    { lat: 48.0100883, lng: 17.5568002 },
    { lat: 48.0102514, lng: 17.5562057 },
    { lat: 48.0103203, lng: 17.5555707 },
    { lat: 48.0104118, lng: 17.5547977 },
    { lat: 48.0104177, lng: 17.5547305 },
    { lat: 48.0105119, lng: 17.5535886 },
    { lat: 48.0104874, lng: 17.550828 },
    { lat: 48.0100718, lng: 17.5493651 },
    { lat: 48.009552, lng: 17.5484276 },
    { lat: 48.0085119, lng: 17.5473337 },
    { lat: 48.0078228, lng: 17.5469427 },
    { lat: 48.0065152, lng: 17.5463792 },
    { lat: 48.0056863, lng: 17.5454985 },
    { lat: 48.0051696, lng: 17.5443975 },
    { lat: 48.004733, lng: 17.542734 },
    { lat: 48.00448, lng: 17.5414819 },
    { lat: 48.0045008, lng: 17.5413823 },
    { lat: 48.0047416, lng: 17.5402263 },
    { lat: 48.0044632, lng: 17.540059 },
    { lat: 48.0037022, lng: 17.5389544 },
    { lat: 48.0023414, lng: 17.5364012 },
    { lat: 48.0017803, lng: 17.5355101 },
    { lat: 48.0001322, lng: 17.5336539 },
    { lat: 47.9990137, lng: 17.5353873 },
    { lat: 47.9976244, lng: 17.5372824 },
    { lat: 47.9970579, lng: 17.5382033 },
    { lat: 47.9961936, lng: 17.5392343 },
    { lat: 47.9954079, lng: 17.540789 },
    { lat: 47.9913257, lng: 17.5350937 },
    { lat: 47.9912079, lng: 17.5349472 },
    { lat: 47.9901156, lng: 17.5349249 },
    { lat: 47.9880369, lng: 17.5331898 },
    { lat: 47.9862938, lng: 17.5319673 },
    { lat: 47.9860594, lng: 17.5325743 },
    { lat: 47.9855939, lng: 17.533291 },
    { lat: 47.9847179, lng: 17.5344378 },
    { lat: 47.9846852, lng: 17.5344551 },
    { lat: 47.9844855, lng: 17.53465 },
    { lat: 47.9841497, lng: 17.5347313 },
    { lat: 47.983795, lng: 17.5347836 },
    { lat: 47.9831816, lng: 17.534746 },
    { lat: 47.9818867, lng: 17.5337857 },
    { lat: 47.9815147, lng: 17.5332702 },
    { lat: 47.9815564, lng: 17.5330574 },
    { lat: 47.9817372, lng: 17.5328846 },
    { lat: 47.98204, lng: 17.5328168 },
    { lat: 47.9823454, lng: 17.5325758 },
    { lat: 47.9825497, lng: 17.5322742 },
    { lat: 47.9826096, lng: 17.5319442 },
    { lat: 47.9825912, lng: 17.5316392 },
    { lat: 47.9824635, lng: 17.5313208 },
    { lat: 47.9822177, lng: 17.5309244 },
    { lat: 47.9816582, lng: 17.5294241 },
    { lat: 47.9810149, lng: 17.5281465 },
    { lat: 47.9808183, lng: 17.527882 },
    { lat: 47.9805579, lng: 17.5277662 },
    { lat: 47.9803257, lng: 17.5277732 },
    { lat: 47.9800762, lng: 17.5275286 },
    { lat: 47.9793345, lng: 17.5259712 },
    { lat: 47.978964, lng: 17.5254774 },
    { lat: 47.978267, lng: 17.5249637 },
    { lat: 47.9777598, lng: 17.5248049 },
    { lat: 47.9773185, lng: 17.5247727 },
    { lat: 47.9770779, lng: 17.5248761 },
    { lat: 47.9768065, lng: 17.525083 },
    { lat: 47.9767268, lng: 17.525212 },
    { lat: 47.9760194, lng: 17.5256975 },
    { lat: 47.9755585, lng: 17.5259406 },
    { lat: 47.9750562, lng: 17.5264531 },
    { lat: 47.9745796, lng: 17.5271195 },
    { lat: 47.9745685, lng: 17.5273703 },
    { lat: 47.9746662, lng: 17.5281676 },
    { lat: 47.9746497, lng: 17.5299317 },
    { lat: 47.9747708, lng: 17.5317636 },
    { lat: 47.9748473, lng: 17.5321615 },
    { lat: 47.9751902, lng: 17.5331745 },
    { lat: 47.9756784, lng: 17.533532 },
    { lat: 47.9761739, lng: 17.5340175 },
    { lat: 47.9767315, lng: 17.534122 },
    { lat: 47.977282, lng: 17.5343219 },
    { lat: 47.9777968, lng: 17.5339324 },
    { lat: 47.9781111, lng: 17.5339733 },
    { lat: 47.9783345, lng: 17.5341793 },
    { lat: 47.9785747, lng: 17.5351781 },
    { lat: 47.9788354, lng: 17.5355603 },
    { lat: 47.9790519, lng: 17.5361015 },
    { lat: 47.9791569, lng: 17.5368736 },
    { lat: 47.9794082, lng: 17.5377097 },
    { lat: 47.9798219, lng: 17.5384551 },
    { lat: 47.9802671, lng: 17.5390039 },
    { lat: 47.9805685, lng: 17.5396006 },
    { lat: 47.9806138, lng: 17.5400073 },
    { lat: 47.9805211, lng: 17.5407085 },
    { lat: 47.9803514, lng: 17.5412648 },
    { lat: 47.9802066, lng: 17.5415262 },
    { lat: 47.9799394, lng: 17.5414969 },
    { lat: 47.9793329, lng: 17.5411424 },
    { lat: 47.9786814, lng: 17.5410949 },
    { lat: 47.9778831, lng: 17.5407099 },
    { lat: 47.9773526, lng: 17.5406963 },
    { lat: 47.9770786, lng: 17.5407705 },
    { lat: 47.9766482, lng: 17.5409999 },
    { lat: 47.9755386, lng: 17.5417444 },
    { lat: 47.9749508, lng: 17.5422457 },
    { lat: 47.9743134, lng: 17.5423435 },
    { lat: 47.973701, lng: 17.5421621 },
    { lat: 47.9733626, lng: 17.5417832 },
    { lat: 47.9731212, lng: 17.5413298 },
    { lat: 47.9735989, lng: 17.5401672 },
    { lat: 47.9734422, lng: 17.5391855 },
    { lat: 47.9725416, lng: 17.5385798 },
    { lat: 47.9711935, lng: 17.5386019 },
    { lat: 47.9704562, lng: 17.5385142 },
    { lat: 47.9692256, lng: 17.536923 },
    { lat: 47.9686413, lng: 17.5355625 },
    { lat: 47.9676113, lng: 17.5329432 },
    { lat: 47.9670483, lng: 17.5302119 },
    { lat: 47.9666622, lng: 17.5271242 },
    { lat: 47.965706, lng: 17.5251461 },
    { lat: 47.9654137, lng: 17.5240948 },
    { lat: 47.9640553, lng: 17.5222366 },
    { lat: 47.9630656, lng: 17.5213176 },
    { lat: 47.9624619, lng: 17.5215003 },
    { lat: 47.961988, lng: 17.5219295 },
    { lat: 47.9614815, lng: 17.5228861 },
    { lat: 47.9615207, lng: 17.5255808 },
    { lat: 47.9614745, lng: 17.5275834 },
    { lat: 47.961502, lng: 17.5280114 },
    { lat: 47.9616658, lng: 17.5287322 },
    { lat: 47.9622648, lng: 17.5308125 },
    { lat: 47.9629506, lng: 17.53057 },
    { lat: 47.9635041, lng: 17.5310291 },
    { lat: 47.9637476, lng: 17.5319006 },
    { lat: 47.96366, lng: 17.5327499 },
    { lat: 47.9628947, lng: 17.5335464 },
    { lat: 47.9624502, lng: 17.5338352 },
    { lat: 47.9617466, lng: 17.5340112 },
    { lat: 47.961093, lng: 17.5340152 },
    { lat: 47.9607274, lng: 17.53372 },
    { lat: 47.9602679, lng: 17.5328271 },
    { lat: 47.9599397, lng: 17.53183 },
    { lat: 47.9598063, lng: 17.5306863 },
    { lat: 47.9596378, lng: 17.5304035 },
    { lat: 47.9595693, lng: 17.5303023 },
    { lat: 47.9593888, lng: 17.5302371 },
    { lat: 47.9587703, lng: 17.5302411 },
    { lat: 47.9585012, lng: 17.5305352 },
    { lat: 47.9582605, lng: 17.5312337 },
    { lat: 47.9582752, lng: 17.5330449 },
    { lat: 47.9589085, lng: 17.5349374 },
    { lat: 47.9589877, lng: 17.535783 },
    { lat: 47.9588034, lng: 17.5364832 },
    { lat: 47.9584452, lng: 17.5369531 },
    { lat: 47.957662, lng: 17.5364534 },
    { lat: 47.9570376, lng: 17.5362077 },
    { lat: 47.9565223, lng: 17.5357456 },
    { lat: 47.9563374, lng: 17.5352576 },
    { lat: 47.9565978, lng: 17.534723 },
    { lat: 47.9569813, lng: 17.5341731 },
    { lat: 47.9570403, lng: 17.5332459 },
    { lat: 47.9571942, lng: 17.532655 },
    { lat: 47.957318, lng: 17.5319271 },
    { lat: 47.9572122, lng: 17.5301053 },
    { lat: 47.9567443, lng: 17.5282763 },
    { lat: 47.9563385, lng: 17.5280041 },
    { lat: 47.9555644, lng: 17.5278245 },
    { lat: 47.9551251, lng: 17.5280686 },
    { lat: 47.9546148, lng: 17.528159 },
    { lat: 47.95422, lng: 17.5285143 },
    { lat: 47.9539799, lng: 17.5289431 },
    { lat: 47.9537989, lng: 17.5294287 },
    { lat: 47.9537355, lng: 17.5301655 },
    { lat: 47.9537729, lng: 17.5308527 },
    { lat: 47.9542723, lng: 17.5316644 },
    { lat: 47.9542646, lng: 17.5317912 },
    { lat: 47.9535294, lng: 17.5338782 },
    { lat: 47.9532796, lng: 17.5344021 },
    { lat: 47.9528831, lng: 17.5358395 },
    { lat: 47.9527542, lng: 17.5362936 },
    { lat: 47.9527086, lng: 17.5366552 },
    { lat: 47.9528277, lng: 17.5375615 },
    { lat: 47.9529773, lng: 17.537999 },
    { lat: 47.9536743, lng: 17.5387226 },
    { lat: 47.9542556, lng: 17.5391357 },
    { lat: 47.9546603, lng: 17.5397541 },
    { lat: 47.9547748, lng: 17.5400788 },
    { lat: 47.9548667, lng: 17.5403679 },
    { lat: 47.9549148, lng: 17.5406398 },
    { lat: 47.9548443, lng: 17.5407397 },
    { lat: 47.9545482, lng: 17.5407871 },
    { lat: 47.9533639, lng: 17.5408285 },
    { lat: 47.9528915, lng: 17.5410849 },
    { lat: 47.952563, lng: 17.5409478 },
    { lat: 47.9525725, lng: 17.5388794 },
    { lat: 47.9521168, lng: 17.5391007 },
    { lat: 47.9516227, lng: 17.5392485 },
    { lat: 47.9510583, lng: 17.539233 },
    { lat: 47.9503919, lng: 17.539354 },
    { lat: 47.9484558, lng: 17.5399966 },
    { lat: 47.948039, lng: 17.5405227 },
    { lat: 47.947332, lng: 17.5415584 },
    { lat: 47.9470847, lng: 17.5421738 },
    { lat: 47.9470485, lng: 17.5425611 },
    { lat: 47.9471244, lng: 17.5429867 },
    { lat: 47.9463105, lng: 17.5437664 },
    { lat: 47.9447409, lng: 17.5444166 },
    { lat: 47.9439962, lng: 17.5452706 },
    { lat: 47.9435435, lng: 17.5462408 },
    { lat: 47.9434628, lng: 17.5465823 },
    { lat: 47.9433894, lng: 17.5471129 },
    { lat: 47.943529, lng: 17.5472822 },
    { lat: 47.9436498, lng: 17.5474207 },
    { lat: 47.9444866, lng: 17.5484066 },
    { lat: 47.9450174, lng: 17.5491296 },
    { lat: 47.9460965, lng: 17.5503209 },
    { lat: 47.9466948, lng: 17.5508974 },
    { lat: 47.9487199, lng: 17.55224 },
    { lat: 47.9507359, lng: 17.5534076 },
    { lat: 47.9542894, lng: 17.555041 },
    { lat: 47.9545345, lng: 17.5550917 },
    { lat: 47.9545527, lng: 17.5550958 },
    { lat: 47.9546593, lng: 17.5551187 },
    { lat: 47.9550163, lng: 17.5554756 },
    { lat: 47.9556643, lng: 17.5562301 },
    { lat: 47.9593626, lng: 17.5562113 },
    { lat: 47.960055, lng: 17.5561584 },
    { lat: 47.9613464, lng: 17.5556244 },
    { lat: 47.96224, lng: 17.5553311 },
    { lat: 47.9657216, lng: 17.5559132 },
    { lat: 47.9670509, lng: 17.5559153 },
    { lat: 47.9680899, lng: 17.5563244 },
    { lat: 47.9681563, lng: 17.5563562 },
    { lat: 47.9692172, lng: 17.5571724 },
    { lat: 47.9700958, lng: 17.5583538 },
    { lat: 47.9705483, lng: 17.5587723 },
    { lat: 47.9710834, lng: 17.5592284 },
    { lat: 47.9715529, lng: 17.5595056 },
    { lat: 47.9718339, lng: 17.5595919 },
    { lat: 47.9723468, lng: 17.5595948 },
    { lat: 47.9727892, lng: 17.5593597 },
    { lat: 47.9733543, lng: 17.5588051 },
    { lat: 47.9735601, lng: 17.5586673 },
    { lat: 47.9747074, lng: 17.5582439 },
    { lat: 47.9750719, lng: 17.5581861 },
    { lat: 47.9753549, lng: 17.5586227 },
    { lat: 47.9761482, lng: 17.5597763 },
    { lat: 47.9767088, lng: 17.5603828 },
    { lat: 47.9765154, lng: 17.5606626 },
    { lat: 47.9780227, lng: 17.5623693 },
    { lat: 47.9789491, lng: 17.5608576 },
    { lat: 47.9801545, lng: 17.5597179 },
    { lat: 47.9805646, lng: 17.5588388 },
    { lat: 47.9813216, lng: 17.5596554 },
    { lat: 47.9827804, lng: 17.5609225 },
    { lat: 47.9825515, lng: 17.5616352 },
    { lat: 47.9814389, lng: 17.5651782 },
    { lat: 47.981274, lng: 17.5651227 },
    { lat: 47.9813841, lng: 17.5662448 },
    { lat: 47.9830311, lng: 17.5673105 },
  ],
  Báč: [
    { lat: 48.0009573, lng: 17.394161 },
    { lat: 48.000994, lng: 17.3942047 },
    { lat: 48.0015969, lng: 17.3929758 },
    { lat: 48.0031482, lng: 17.390448 },
    { lat: 48.0035858, lng: 17.3898196 },
    { lat: 48.0043406, lng: 17.3884112 },
    { lat: 48.0052393, lng: 17.3861968 },
    { lat: 48.0059978, lng: 17.3840012 },
    { lat: 48.0060462, lng: 17.3840679 },
    { lat: 48.0060971, lng: 17.3840354 },
    { lat: 48.0064674, lng: 17.3845645 },
    { lat: 48.0075889, lng: 17.3821873 },
    { lat: 48.0080137, lng: 17.3827053 },
    { lat: 48.0081999, lng: 17.3828422 },
    { lat: 48.009805, lng: 17.3837537 },
    { lat: 48.0104951, lng: 17.3838931 },
    { lat: 48.0110888, lng: 17.3846458 },
    { lat: 48.0114222, lng: 17.3839754 },
    { lat: 48.0116356, lng: 17.3831481 },
    { lat: 48.012593, lng: 17.3817438 },
    { lat: 48.0149821, lng: 17.3813351 },
    { lat: 48.0159698, lng: 17.3815168 },
    { lat: 48.0169816, lng: 17.3814428 },
    { lat: 48.0175013, lng: 17.3816701 },
    { lat: 48.0181647, lng: 17.3817266 },
    { lat: 48.0181656, lng: 17.3816188 },
    { lat: 48.018526, lng: 17.3813102 },
    { lat: 48.0192925, lng: 17.3802365 },
    { lat: 48.0200365, lng: 17.3786279 },
    { lat: 48.020501, lng: 17.3779121 },
    { lat: 48.0207908, lng: 17.3772638 },
    { lat: 48.0209114, lng: 17.3768144 },
    { lat: 48.020922, lng: 17.3765292 },
    { lat: 48.0206617, lng: 17.3754522 },
    { lat: 48.0204864, lng: 17.3744508 },
    { lat: 48.0204474, lng: 17.3735459 },
    { lat: 48.0208319, lng: 17.3726798 },
    { lat: 48.0217, lng: 17.3713613 },
    { lat: 48.0216448, lng: 17.3712103 },
    { lat: 48.0198624, lng: 17.3691796 },
    { lat: 48.0180733, lng: 17.3672876 },
    { lat: 48.0177745, lng: 17.366869 },
    { lat: 48.0155545, lng: 17.3645229 },
    { lat: 48.0153627, lng: 17.3642151 },
    { lat: 48.0143401, lng: 17.3626489 },
    { lat: 48.0141133, lng: 17.36322 },
    { lat: 48.0130538, lng: 17.3651085 },
    { lat: 48.0119663, lng: 17.3647321 },
    { lat: 48.0113117, lng: 17.3643984 },
    { lat: 48.0102235, lng: 17.3635657 },
    { lat: 48.0091229, lng: 17.3629427 },
    { lat: 48.0071855, lng: 17.3608945 },
    { lat: 48.007044, lng: 17.3606643 },
    { lat: 48.006088, lng: 17.3598094 },
    { lat: 48.0053682, lng: 17.3597787 },
    { lat: 48.00471, lng: 17.3600417 },
    { lat: 48.0045418, lng: 17.3601117 },
    { lat: 48.0032478, lng: 17.3628648 },
    { lat: 48.0022896, lng: 17.3649043 },
    { lat: 48.0022203, lng: 17.3648301 },
    { lat: 48.0021261, lng: 17.3649962 },
    { lat: 48.0020768, lng: 17.3662371 },
    { lat: 48.0008509, lng: 17.369431 },
    { lat: 48.0010656, lng: 17.3696031 },
    { lat: 48.0011455, lng: 17.3696673 },
    { lat: 48.0015916, lng: 17.3705591 },
    { lat: 48.0016548, lng: 17.3708334 },
    { lat: 47.998453, lng: 17.3750695 },
    { lat: 47.9976224, lng: 17.3773008 },
    { lat: 47.9962637, lng: 17.3798166 },
    { lat: 47.9946855, lng: 17.3821182 },
    { lat: 47.9943945, lng: 17.3824853 },
    { lat: 47.9938187, lng: 17.3830596 },
    { lat: 47.9937009, lng: 17.3831727 },
    { lat: 47.9937145, lng: 17.3831918 },
    { lat: 47.9970461, lng: 17.3876973 },
    { lat: 47.9978657, lng: 17.3895201 },
    { lat: 47.9979898, lng: 17.3909122 },
    { lat: 47.9982611, lng: 17.3912099 },
    { lat: 47.9983161, lng: 17.3913779 },
    { lat: 47.99914, lng: 17.3921104 },
    { lat: 48.0009573, lng: 17.394161 },
  ],
  Macov: [
    { lat: 48.0290011, lng: 17.4285467 },
    { lat: 48.0278306, lng: 17.427392 },
    { lat: 48.0272899, lng: 17.4270712 },
    { lat: 48.0263356, lng: 17.4269331 },
    { lat: 48.0187461, lng: 17.4161129 },
    { lat: 48.0171871, lng: 17.4187758 },
    { lat: 48.0165401, lng: 17.4214433 },
    { lat: 48.0162019, lng: 17.4225799 },
    { lat: 48.0157917, lng: 17.4222992 },
    { lat: 48.0149402, lng: 17.424223 },
    { lat: 48.0149246, lng: 17.4242605 },
    { lat: 48.0146115, lng: 17.4250004 },
    { lat: 48.0130321, lng: 17.4280796 },
    { lat: 48.0156766, lng: 17.4304097 },
    { lat: 48.0159689, lng: 17.4308066 },
    { lat: 48.0162427, lng: 17.4309328 },
    { lat: 48.0164354, lng: 17.4311003 },
    { lat: 48.0166334, lng: 17.4317828 },
    { lat: 48.0169903, lng: 17.4336907 },
    { lat: 48.017157, lng: 17.4340487 },
    { lat: 48.01758, lng: 17.4347325 },
    { lat: 48.0183064, lng: 17.435672 },
    { lat: 48.0184573, lng: 17.4357359 },
    { lat: 48.0172786, lng: 17.4389012 },
    { lat: 48.0178698, lng: 17.4397047 },
    { lat: 48.0192656, lng: 17.4413529 },
    { lat: 48.020723, lng: 17.4443991 },
    { lat: 48.0220667, lng: 17.4436293 },
    { lat: 48.0225184, lng: 17.4436127 },
    { lat: 48.0231188, lng: 17.4439006 },
    { lat: 48.023766, lng: 17.4445614 },
    { lat: 48.0241604, lng: 17.4453134 },
    { lat: 48.0243824, lng: 17.4460338 },
    { lat: 48.024523, lng: 17.4468934 },
    { lat: 48.0246146, lng: 17.4478508 },
    { lat: 48.0248295, lng: 17.4478046 },
    { lat: 48.0251435, lng: 17.4476834 },
    { lat: 48.0257318, lng: 17.4472121 },
    { lat: 48.0261766, lng: 17.4470872 },
    { lat: 48.0264457, lng: 17.4468133 },
    { lat: 48.0269944, lng: 17.4464296 },
    { lat: 48.0272439, lng: 17.4460796 },
    { lat: 48.0274852, lng: 17.4455688 },
    { lat: 48.0278968, lng: 17.4449176 },
    { lat: 48.0279558, lng: 17.4448855 },
    { lat: 48.0288616, lng: 17.4450947 },
    { lat: 48.0292772, lng: 17.4449885 },
    { lat: 48.0295606, lng: 17.4447471 },
    { lat: 48.0298735, lng: 17.4442768 },
    { lat: 48.0301007, lng: 17.4438297 },
    { lat: 48.0302694, lng: 17.4426014 },
    { lat: 48.0303412, lng: 17.4423663 },
    { lat: 48.0304509, lng: 17.442198 },
    { lat: 48.0308701, lng: 17.4418008 },
    { lat: 48.0309834, lng: 17.441753 },
    { lat: 48.0312272, lng: 17.4418737 },
    { lat: 48.0314804, lng: 17.4421021 },
    { lat: 48.0317851, lng: 17.4426534 },
    { lat: 48.0320303, lng: 17.4429154 },
    { lat: 48.0324254, lng: 17.4436243 },
    { lat: 48.034454, lng: 17.4413872 },
    { lat: 48.033994, lng: 17.4409654 },
    { lat: 48.0318451, lng: 17.4389909 },
    { lat: 48.0332386, lng: 17.435844 },
    { lat: 48.0317573, lng: 17.4349489 },
    { lat: 48.0321145, lng: 17.434609 },
    { lat: 48.0322641, lng: 17.4343904 },
    { lat: 48.0324431, lng: 17.4340692 },
    { lat: 48.0326404, lng: 17.4335621 },
    { lat: 48.0327879, lng: 17.4329483 },
    { lat: 48.032817, lng: 17.432508 },
    { lat: 48.0326714, lng: 17.4320245 },
    { lat: 48.0324217, lng: 17.4316601 },
    { lat: 48.0322127, lng: 17.4315362 },
    { lat: 48.0315994, lng: 17.431535 },
    { lat: 48.030818, lng: 17.4310528 },
    { lat: 48.0290011, lng: 17.4285467 },
  ],
  HornáPotôň: [
    { lat: 48.0184692, lng: 17.4862343 },
    { lat: 48.0181975, lng: 17.4888991 },
    { lat: 48.0179714, lng: 17.4928991 },
    { lat: 48.0180438, lng: 17.4935134 },
    { lat: 48.0182498, lng: 17.4942186 },
    { lat: 48.0180886, lng: 17.4945564 },
    { lat: 48.0195496, lng: 17.4958964 },
    { lat: 48.0204096, lng: 17.4964676 },
    { lat: 48.0221203, lng: 17.499455 },
    { lat: 48.0231914, lng: 17.5015288 },
    { lat: 48.0247981, lng: 17.5007521 },
    { lat: 48.0245672, lng: 17.5030024 },
    { lat: 48.0260534, lng: 17.503387 },
    { lat: 48.0264478, lng: 17.5057577 },
    { lat: 48.0271549, lng: 17.5063186 },
    { lat: 48.0277329, lng: 17.5065078 },
    { lat: 48.027906, lng: 17.506578 },
    { lat: 48.0294789, lng: 17.5078775 },
    { lat: 48.0297981, lng: 17.5082544 },
    { lat: 48.0301885, lng: 17.5087415 },
    { lat: 48.0305703, lng: 17.5093745 },
    { lat: 48.0315428, lng: 17.5111983 },
    { lat: 48.0319566, lng: 17.5117152 },
    { lat: 48.0325996, lng: 17.5122308 },
    { lat: 48.0330981, lng: 17.5129222 },
    { lat: 48.0337307, lng: 17.5147469 },
    { lat: 48.0341282, lng: 17.515624 },
    { lat: 48.0359665, lng: 17.5183429 },
    { lat: 48.0358977, lng: 17.5185009 },
    { lat: 48.0395786, lng: 17.5216344 },
    { lat: 48.0451898, lng: 17.5326842 },
    { lat: 48.0469436, lng: 17.5351117 },
    { lat: 48.0495003, lng: 17.5388631 },
    { lat: 48.0520616, lng: 17.5429131 },
    { lat: 48.0553153, lng: 17.5478344 },
    { lat: 48.0572977, lng: 17.5549847 },
    { lat: 48.0575936, lng: 17.5556743 },
    { lat: 48.0581534, lng: 17.5562533 },
    { lat: 48.0594033, lng: 17.556938 },
    { lat: 48.0601102, lng: 17.5582067 },
    { lat: 48.0609913, lng: 17.5601552 },
    { lat: 48.0627388, lng: 17.5629725 },
    { lat: 48.0637414, lng: 17.564241 },
    { lat: 48.0647438, lng: 17.5658092 },
    { lat: 48.0679886, lng: 17.5739531 },
    { lat: 48.0688022, lng: 17.5761768 },
    { lat: 48.069311, lng: 17.5773202 },
    { lat: 48.07028, lng: 17.5791453 },
    { lat: 48.0716499, lng: 17.581518 },
    { lat: 48.07472, lng: 17.5859462 },
    { lat: 48.0748535, lng: 17.5862708 },
    { lat: 48.0764144, lng: 17.588138 },
    { lat: 48.0774496, lng: 17.5891067 },
    { lat: 48.0794139, lng: 17.591833 },
    { lat: 48.0810664, lng: 17.5948031 },
    { lat: 48.0820155, lng: 17.596251 },
    { lat: 48.0823277, lng: 17.5968439 },
    { lat: 48.0838519, lng: 17.6010007 },
    { lat: 48.0854925, lng: 17.6044102 },
    { lat: 48.088022, lng: 17.6099404 },
    { lat: 48.0850351, lng: 17.6120023 },
    { lat: 48.0848188, lng: 17.6121557 },
    { lat: 48.0860021, lng: 17.6138779 },
    { lat: 48.0861359, lng: 17.6139769 },
    { lat: 48.0864964, lng: 17.6151571 },
    { lat: 48.0862889, lng: 17.6165499 },
    { lat: 48.0859872, lng: 17.616707 },
    { lat: 48.0857348, lng: 17.6165742 },
    { lat: 48.0853236, lng: 17.6167131 },
    { lat: 48.0844783, lng: 17.6172707 },
    { lat: 48.0838683, lng: 17.6177344 },
    { lat: 48.083627, lng: 17.6179892 },
    { lat: 48.0833691, lng: 17.6183879 },
    { lat: 48.0832874, lng: 17.6183835 },
    { lat: 48.0831496, lng: 17.6186699 },
    { lat: 48.0828036, lng: 17.6200879 },
    { lat: 48.0827538, lng: 17.6209032 },
    { lat: 48.0830916, lng: 17.621869 },
    { lat: 48.0834542, lng: 17.6224599 },
    { lat: 48.0838879, lng: 17.6227627 },
    { lat: 48.0850794, lng: 17.6231229 },
    { lat: 48.0855753, lng: 17.6231787 },
    { lat: 48.0861941, lng: 17.6230002 },
    { lat: 48.0867848, lng: 17.6226464 },
    { lat: 48.087111, lng: 17.622291 },
    { lat: 48.0873618, lng: 17.6219162 },
    { lat: 48.0881337, lng: 17.6204825 },
    { lat: 48.0897135, lng: 17.618666 },
    { lat: 48.0899922, lng: 17.6185726 },
    { lat: 48.0915843, lng: 17.6185929 },
    { lat: 48.0920672, lng: 17.6188348 },
    { lat: 48.0924035, lng: 17.6191691 },
    { lat: 48.09277, lng: 17.619769 },
    { lat: 48.0930459, lng: 17.6205046 },
    { lat: 48.093184, lng: 17.62106 },
    { lat: 48.093546, lng: 17.62097 },
    { lat: 48.093569, lng: 17.620964 },
    { lat: 48.093601, lng: 17.620956 },
    { lat: 48.0937, lng: 17.620926 },
    { lat: 48.093667, lng: 17.620738 },
    { lat: 48.093677, lng: 17.620666 },
    { lat: 48.093686, lng: 17.6206 },
    { lat: 48.093722, lng: 17.620551 },
    { lat: 48.093747, lng: 17.620518 },
    { lat: 48.093847, lng: 17.620524 },
    { lat: 48.094654, lng: 17.620635 },
    { lat: 48.094887, lng: 17.620668 },
    { lat: 48.094788, lng: 17.619998 },
    { lat: 48.094675, lng: 17.619228 },
    { lat: 48.094545, lng: 17.618306 },
    { lat: 48.094403, lng: 17.617283 },
    { lat: 48.09432, lng: 17.616837 },
    { lat: 48.094186, lng: 17.616083 },
    { lat: 48.094075, lng: 17.615725 },
    { lat: 48.093935, lng: 17.615266 },
    { lat: 48.093886, lng: 17.615066 },
    { lat: 48.093716, lng: 17.614377 },
    { lat: 48.093672, lng: 17.614239 },
    { lat: 48.093483, lng: 17.613538 },
    { lat: 48.093445, lng: 17.613397 },
    { lat: 48.093611, lng: 17.613432 },
    { lat: 48.093894, lng: 17.613509 },
    { lat: 48.094208, lng: 17.613608 },
    { lat: 48.094345, lng: 17.613633 },
    { lat: 48.094589, lng: 17.613561 },
    { lat: 48.094665, lng: 17.61349 },
    { lat: 48.094893, lng: 17.613166 },
    { lat: 48.095287, lng: 17.612648 },
    { lat: 48.095657, lng: 17.612199 },
    { lat: 48.095972, lng: 17.611818 },
    { lat: 48.096272, lng: 17.611344 },
    { lat: 48.096645, lng: 17.611002 },
    { lat: 48.096936, lng: 17.610678 },
    { lat: 48.097098, lng: 17.61093 },
    { lat: 48.097165, lng: 17.61102 },
    { lat: 48.097719, lng: 17.612051 },
    { lat: 48.098163, lng: 17.613035 },
    { lat: 48.098203, lng: 17.613146 },
    { lat: 48.098542, lng: 17.614076 },
    { lat: 48.098842, lng: 17.614961 },
    { lat: 48.098874, lng: 17.615054 },
    { lat: 48.099144, lng: 17.615721 },
    { lat: 48.099302, lng: 17.615978 },
    { lat: 48.099576, lng: 17.616297 },
    { lat: 48.099945, lng: 17.616727 },
    { lat: 48.100197, lng: 17.61702 },
    { lat: 48.1009, lng: 17.617475 },
    { lat: 48.101141, lng: 17.617628 },
    { lat: 48.101497, lng: 17.61773 },
    { lat: 48.101642, lng: 17.617809 },
    { lat: 48.101725, lng: 17.617852 },
    { lat: 48.101944, lng: 17.617937 },
    { lat: 48.102226, lng: 17.618002 },
    { lat: 48.102481, lng: 17.618089 },
    { lat: 48.102722, lng: 17.618139 },
    { lat: 48.102762, lng: 17.618153 },
    { lat: 48.102886, lng: 17.618201 },
    { lat: 48.103188, lng: 17.618343 },
    { lat: 48.103757, lng: 17.618509 },
    { lat: 48.10414, lng: 17.618246 },
    { lat: 48.104943, lng: 17.617788 },
    { lat: 48.105072, lng: 17.617723 },
    { lat: 48.106112, lng: 17.617216 },
    { lat: 48.106547, lng: 17.616943 },
    { lat: 48.106903, lng: 17.616741 },
    { lat: 48.107213, lng: 17.616508 },
    { lat: 48.107245, lng: 17.616484 },
    { lat: 48.107317, lng: 17.61643 },
    { lat: 48.107786, lng: 17.616148 },
    { lat: 48.108022, lng: 17.615951 },
    { lat: 48.108398, lng: 17.615586 },
    { lat: 48.108738, lng: 17.615292 },
    { lat: 48.108956, lng: 17.615027 },
    { lat: 48.109265, lng: 17.61457 },
    { lat: 48.109445, lng: 17.614337 },
    { lat: 48.10961, lng: 17.614153 },
    { lat: 48.109756, lng: 17.613924 },
    { lat: 48.110042, lng: 17.613367 },
    { lat: 48.110057, lng: 17.613342 },
    { lat: 48.110416, lng: 17.612782 },
    { lat: 48.110605, lng: 17.612502 },
    { lat: 48.110675, lng: 17.612401 },
    { lat: 48.11087, lng: 17.612158 },
    { lat: 48.111284, lng: 17.61186 },
    { lat: 48.111394, lng: 17.61166 },
    { lat: 48.111501, lng: 17.611287 },
    { lat: 48.111546, lng: 17.611008 },
    { lat: 48.111582, lng: 17.610675 },
    { lat: 48.111612, lng: 17.610311 },
    { lat: 48.111631, lng: 17.609955 },
    { lat: 48.111598, lng: 17.609415 },
    { lat: 48.111565, lng: 17.609105 },
    { lat: 48.111552, lng: 17.608866 },
    { lat: 48.111437, lng: 17.608194 },
    { lat: 48.111415, lng: 17.607981 },
    { lat: 48.111376, lng: 17.607426 },
    { lat: 48.111322, lng: 17.607111 },
    { lat: 48.111274, lng: 17.606915 },
    { lat: 48.111187, lng: 17.606688 },
    { lat: 48.111121, lng: 17.60654 },
    { lat: 48.11101, lng: 17.606248 },
    { lat: 48.11087, lng: 17.605699 },
    { lat: 48.110851, lng: 17.605632 },
    { lat: 48.110795, lng: 17.605483 },
    { lat: 48.11075, lng: 17.60538 },
    { lat: 48.110731, lng: 17.60534 },
    { lat: 48.110661, lng: 17.605225 },
    { lat: 48.110514, lng: 17.60499 },
    { lat: 48.110482, lng: 17.604941 },
    { lat: 48.110284, lng: 17.60464 },
    { lat: 48.110098, lng: 17.604392 },
    { lat: 48.109873, lng: 17.604152 },
    { lat: 48.109526, lng: 17.603801 },
    { lat: 48.109242, lng: 17.603396 },
    { lat: 48.109099, lng: 17.603229 },
    { lat: 48.108762, lng: 17.603035 },
    { lat: 48.108566, lng: 17.603026 },
    { lat: 48.108354, lng: 17.603087 },
    { lat: 48.108058, lng: 17.603141 },
    { lat: 48.10787, lng: 17.603148 },
    { lat: 48.107277, lng: 17.60336 },
    { lat: 48.10696, lng: 17.603502 },
    { lat: 48.106702, lng: 17.603557 },
    { lat: 48.106361, lng: 17.603565 },
    { lat: 48.106225, lng: 17.603596 },
    { lat: 48.105869, lng: 17.60372 },
    { lat: 48.105672, lng: 17.603794 },
    { lat: 48.104651, lng: 17.604005 },
    { lat: 48.104528, lng: 17.604031 },
    { lat: 48.104365, lng: 17.604043 },
    { lat: 48.104203, lng: 17.603944 },
    { lat: 48.104116, lng: 17.603872 },
    { lat: 48.104013, lng: 17.603828 },
    { lat: 48.103908, lng: 17.603773 },
    { lat: 48.103822, lng: 17.60366 },
    { lat: 48.103785, lng: 17.603577 },
    { lat: 48.103758, lng: 17.60348 },
    { lat: 48.103657, lng: 17.603183 },
    { lat: 48.10355, lng: 17.602923 },
    { lat: 48.103511, lng: 17.602821 },
    { lat: 48.103397, lng: 17.602507 },
    { lat: 48.103339, lng: 17.602336 },
    { lat: 48.10328, lng: 17.602103 },
    { lat: 48.103226, lng: 17.601901 },
    { lat: 48.103118, lng: 17.601379 },
    { lat: 48.102999, lng: 17.600966 },
    { lat: 48.102864, lng: 17.600388 },
    { lat: 48.102712, lng: 17.599872 },
    { lat: 48.102617, lng: 17.599499 },
    { lat: 48.102577, lng: 17.599191 },
    { lat: 48.10254, lng: 17.599037 },
    { lat: 48.102515, lng: 17.59895 },
    { lat: 48.10246, lng: 17.598751 },
    { lat: 48.102342, lng: 17.598427 },
    { lat: 48.102286, lng: 17.598312 },
    { lat: 48.102111, lng: 17.598211 },
    { lat: 48.101958, lng: 17.598137 },
    { lat: 48.10175, lng: 17.598098 },
    { lat: 48.101486, lng: 17.598045 },
    { lat: 48.101336, lng: 17.598068 },
    { lat: 48.10127, lng: 17.598101 },
    { lat: 48.101166, lng: 17.59816 },
    { lat: 48.10082, lng: 17.59854 },
    { lat: 48.10058, lng: 17.598694 },
    { lat: 48.100472, lng: 17.598729 },
    { lat: 48.100333, lng: 17.598693 },
    { lat: 48.099963, lng: 17.598576 },
    { lat: 48.099823, lng: 17.598647 },
    { lat: 48.099728, lng: 17.598676 },
    { lat: 48.099686, lng: 17.598697 },
    { lat: 48.099651, lng: 17.598704 },
    { lat: 48.099584, lng: 17.598697 },
    { lat: 48.099541, lng: 17.598616 },
    { lat: 48.099516, lng: 17.598509 },
    { lat: 48.099492, lng: 17.598409 },
    { lat: 48.099476, lng: 17.598317 },
    { lat: 48.099452, lng: 17.598145 },
    { lat: 48.099437, lng: 17.597985 },
    { lat: 48.099439, lng: 17.597715 },
    { lat: 48.099429, lng: 17.597595 },
    { lat: 48.09942, lng: 17.597487 },
    { lat: 48.099357, lng: 17.597291 },
    { lat: 48.09928, lng: 17.59711 },
    { lat: 48.099211, lng: 17.596967 },
    { lat: 48.099184, lng: 17.596914 },
    { lat: 48.099136, lng: 17.596828 },
    { lat: 48.099098, lng: 17.59678 },
    { lat: 48.098977, lng: 17.596635 },
    { lat: 48.098898, lng: 17.596555 },
    { lat: 48.098884, lng: 17.596543 },
    { lat: 48.098838, lng: 17.596529 },
    { lat: 48.098757, lng: 17.596509 },
    { lat: 48.098544, lng: 17.596492 },
    { lat: 48.098403, lng: 17.596415 },
    { lat: 48.0977194, lng: 17.5990194 },
    { lat: 48.0979091, lng: 17.5993207 },
    { lat: 48.0988766, lng: 17.6017882 },
    { lat: 48.0994503, lng: 17.6031246 },
    { lat: 48.0998677, lng: 17.6051112 },
    { lat: 48.0997422, lng: 17.6060485 },
    { lat: 48.0990409, lng: 17.6082718 },
    { lat: 48.1002082, lng: 17.6088199 },
    { lat: 48.1000813, lng: 17.6095956 },
    { lat: 48.0992391, lng: 17.6104522 },
    { lat: 48.0990986, lng: 17.6102139 },
    { lat: 48.098435, lng: 17.6094179 },
    { lat: 48.097515, lng: 17.6105441 },
    { lat: 48.0968405, lng: 17.609666 },
    { lat: 48.0959977, lng: 17.6105311 },
    { lat: 48.0955374, lng: 17.6108255 },
    { lat: 48.0947101, lng: 17.6111144 },
    { lat: 48.0942719, lng: 17.6112014 },
    { lat: 48.0927545, lng: 17.6111539 },
    { lat: 48.0923454, lng: 17.6097961 },
    { lat: 48.0919634, lng: 17.6100034 },
    { lat: 48.0909947, lng: 17.6103106 },
    { lat: 48.0908225, lng: 17.6090175 },
    { lat: 48.0906092, lng: 17.6079002 },
    { lat: 48.0896654, lng: 17.606605 },
    { lat: 48.0897032, lng: 17.6065785 },
    { lat: 48.0896686, lng: 17.6065286 },
    { lat: 48.0915809, lng: 17.6051855 },
    { lat: 48.0915714, lng: 17.6051254 },
    { lat: 48.0888945, lng: 17.6005656 },
    { lat: 48.0871219, lng: 17.5977238 },
    { lat: 48.085293, lng: 17.5951374 },
    { lat: 48.0844332, lng: 17.593551 },
    { lat: 48.083943, lng: 17.5927863 },
    { lat: 48.0826767, lng: 17.5911658 },
    { lat: 48.0818124, lng: 17.5899665 },
    { lat: 48.0806318, lng: 17.5885412 },
    { lat: 48.0800604, lng: 17.5877585 },
    { lat: 48.0779847, lng: 17.5847265 },
    { lat: 48.076716, lng: 17.5826964 },
    { lat: 48.0777731, lng: 17.5806777 },
    { lat: 48.0730112, lng: 17.5727274 },
    { lat: 48.0744823, lng: 17.5706346 },
    { lat: 48.074655, lng: 17.5703852 },
    { lat: 48.0831325, lng: 17.5582931 },
    { lat: 48.0847947, lng: 17.5559251 },
    { lat: 48.083189, lng: 17.55259 },
    { lat: 48.080864, lng: 17.5489964 },
    { lat: 48.0791948, lng: 17.5462062 },
    { lat: 48.0786614, lng: 17.5437316 },
    { lat: 48.0770924, lng: 17.5390028 },
    { lat: 48.0761044, lng: 17.5364153 },
    { lat: 48.0737015, lng: 17.5241931 },
    { lat: 48.0748513, lng: 17.5218051 },
    { lat: 48.0752515, lng: 17.5207559 },
    { lat: 48.0753141, lng: 17.5205895 },
    { lat: 48.0754456, lng: 17.5202447 },
    { lat: 48.0761673, lng: 17.5180838 },
    { lat: 48.0766423, lng: 17.5150918 },
    { lat: 48.076378, lng: 17.5123703 },
    { lat: 48.0758247, lng: 17.509038 },
    { lat: 48.0748892, lng: 17.5072921 },
    { lat: 48.0732341, lng: 17.5039343 },
    { lat: 48.0716497, lng: 17.5015362 },
    { lat: 48.0695748, lng: 17.4998304 },
    { lat: 48.0654865, lng: 17.49778 },
    { lat: 48.0650012, lng: 17.497539 },
    { lat: 48.0632294, lng: 17.4962811 },
    { lat: 48.0544125, lng: 17.4896325 },
    { lat: 48.0506733, lng: 17.4869054 },
    { lat: 48.0496019, lng: 17.4862628 },
    { lat: 48.0469374, lng: 17.4849815 },
    { lat: 48.0462589, lng: 17.4847254 },
    { lat: 48.0441169, lng: 17.4833516 },
    { lat: 48.0425083, lng: 17.4828904 },
    { lat: 48.0414839, lng: 17.4829568 },
    { lat: 48.0408376, lng: 17.4824671 },
    { lat: 48.0380017, lng: 17.4806664 },
    { lat: 48.0368292, lng: 17.4796148 },
    { lat: 48.0355252, lng: 17.4786456 },
    { lat: 48.0335937, lng: 17.4776266 },
    { lat: 48.0329765, lng: 17.4772161 },
    { lat: 48.0321856, lng: 17.4766538 },
    { lat: 48.0316251, lng: 17.4761773 },
    { lat: 48.0306343, lng: 17.4752134 },
    { lat: 48.0295372, lng: 17.4738435 },
    { lat: 48.0291233, lng: 17.4728238 },
    { lat: 48.0285541, lng: 17.4723701 },
    { lat: 48.0279518, lng: 17.4721907 },
    { lat: 48.0262726, lng: 17.4720175 },
    { lat: 48.0251045, lng: 17.471576 },
    { lat: 48.0248399, lng: 17.4730199 },
    { lat: 48.0246851, lng: 17.4742399 },
    { lat: 48.0241632, lng: 17.4741838 },
    { lat: 48.0236174, lng: 17.4743375 },
    { lat: 48.0220474, lng: 17.474527 },
    { lat: 48.0205982, lng: 17.4744889 },
    { lat: 48.0205532, lng: 17.4753462 },
    { lat: 48.0206114, lng: 17.4759312 },
    { lat: 48.0216764, lng: 17.4793512 },
    { lat: 48.0214116, lng: 17.4795198 },
    { lat: 48.0221386, lng: 17.4822014 },
    { lat: 48.0221173, lng: 17.4824134 },
    { lat: 48.0216576, lng: 17.4824152 },
    { lat: 48.0210339, lng: 17.4825824 },
    { lat: 48.0207656, lng: 17.4827473 },
    { lat: 48.019404, lng: 17.4841691 },
    { lat: 48.0189856, lng: 17.484704 },
    { lat: 48.0187394, lng: 17.4852081 },
    { lat: 48.0185597, lng: 17.4857666 },
    { lat: 48.0184692, lng: 17.4862343 },
  ],
  Čenkovce: [
    { lat: 48.0735037, lng: 17.4805462 },
    { lat: 48.0737836, lng: 17.4822225 },
    { lat: 48.0743971, lng: 17.4854395 },
    { lat: 48.0754052, lng: 17.4881299 },
    { lat: 48.076236, lng: 17.4884537 },
    { lat: 48.0766354, lng: 17.4886954 },
    { lat: 48.0769406, lng: 17.4889796 },
    { lat: 48.0779554, lng: 17.4896873 },
    { lat: 48.0779979, lng: 17.4897335 },
    { lat: 48.078461, lng: 17.4902616 },
    { lat: 48.0792385, lng: 17.4914576 },
    { lat: 48.0792637, lng: 17.4914272 },
    { lat: 48.0807695, lng: 17.4929745 },
    { lat: 48.0814288, lng: 17.4926026 },
    { lat: 48.0820668, lng: 17.4920599 },
    { lat: 48.0838099, lng: 17.4903161 },
    { lat: 48.0852335, lng: 17.4881908 },
    { lat: 48.0867345, lng: 17.4874551 },
    { lat: 48.087988, lng: 17.4860898 },
    { lat: 48.0890271, lng: 17.4849898 },
    { lat: 48.0902023, lng: 17.4833912 },
    { lat: 48.0914171, lng: 17.4822986 },
    { lat: 48.0923676, lng: 17.4810509 },
    { lat: 48.0936318, lng: 17.479607 },
    { lat: 48.0939417, lng: 17.479667 },
    { lat: 48.0941737, lng: 17.4787815 },
    { lat: 48.0943959, lng: 17.4781846 },
    { lat: 48.0952601, lng: 17.4766298 },
    { lat: 48.0961197, lng: 17.4748512 },
    { lat: 48.0962105, lng: 17.4744223 },
    { lat: 48.0962713, lng: 17.474315 },
    { lat: 48.097982, lng: 17.4682864 },
    { lat: 48.0981571, lng: 17.4680774 },
    { lat: 48.1003257, lng: 17.4671527 },
    { lat: 48.1005831, lng: 17.4669764 },
    { lat: 48.100883, lng: 17.4665166 },
    { lat: 48.1013505, lng: 17.4648397 },
    { lat: 48.1028856, lng: 17.459226 },
    { lat: 48.107137, lng: 17.4436771 },
    { lat: 48.107188, lng: 17.4435069 },
    { lat: 48.1073998, lng: 17.4436709 },
    { lat: 48.1076279, lng: 17.4428354 },
    { lat: 48.1074102, lng: 17.4427015 },
    { lat: 48.1075043, lng: 17.4394887 },
    { lat: 48.1076539, lng: 17.4386105 },
    { lat: 48.1077867, lng: 17.4382032 },
    { lat: 48.1081909, lng: 17.4373818 },
    { lat: 48.1116091, lng: 17.4314123 },
    { lat: 48.1139879, lng: 17.4281831 },
    { lat: 48.1141857, lng: 17.4279098 },
    { lat: 48.1141649, lng: 17.4278842 },
    { lat: 48.1140077, lng: 17.4276904 },
    { lat: 48.1140661, lng: 17.4276179 },
    { lat: 48.1122468, lng: 17.425017 },
    { lat: 48.1047368, lng: 17.4138612 },
    { lat: 48.1044319, lng: 17.4141578 },
    { lat: 48.1035796, lng: 17.4152209 },
    { lat: 48.1030813, lng: 17.4157028 },
    { lat: 48.1027341, lng: 17.4163208 },
    { lat: 48.1025259, lng: 17.4169026 },
    { lat: 48.1021885, lng: 17.4176385 },
    { lat: 48.1019885, lng: 17.4178774 },
    { lat: 48.1016709, lng: 17.4184818 },
    { lat: 48.1011696, lng: 17.4192815 },
    { lat: 48.1006574, lng: 17.4208363 },
    { lat: 48.0989859, lng: 17.4199009 },
    { lat: 48.0977499, lng: 17.4189914 },
    { lat: 48.09475, lng: 17.4281516 },
    { lat: 48.0933999, lng: 17.4275535 },
    { lat: 48.0917058, lng: 17.4267944 },
    { lat: 48.091575, lng: 17.4271455 },
    { lat: 48.0914465, lng: 17.4276325 },
    { lat: 48.0913677, lng: 17.4281929 },
    { lat: 48.0916845, lng: 17.4291037 },
    { lat: 48.0922732, lng: 17.4305491 },
    { lat: 48.0922582, lng: 17.4309272 },
    { lat: 48.0920941, lng: 17.4315438 },
    { lat: 48.0918952, lng: 17.4319795 },
    { lat: 48.0916638, lng: 17.4323116 },
    { lat: 48.0911718, lng: 17.4328043 },
    { lat: 48.0907804, lng: 17.4330193 },
    { lat: 48.0906615, lng: 17.4332068 },
    { lat: 48.0906274, lng: 17.4334799 },
    { lat: 48.0906465, lng: 17.4348326 },
    { lat: 48.0905017, lng: 17.435281 },
    { lat: 48.0903638, lng: 17.4354719 },
    { lat: 48.0896749, lng: 17.4380625 },
    { lat: 48.0904362, lng: 17.439595 },
    { lat: 48.0891898, lng: 17.4438593 },
    { lat: 48.0887296, lng: 17.4442519 },
    { lat: 48.0886084, lng: 17.4444737 },
    { lat: 48.0885583, lng: 17.4447631 },
    { lat: 48.088595, lng: 17.4453283 },
    { lat: 48.0885361, lng: 17.445777 },
    { lat: 48.0881264, lng: 17.446808 },
    { lat: 48.087977, lng: 17.4477777 },
    { lat: 48.0878812, lng: 17.4480597 },
    { lat: 48.0873381, lng: 17.4487055 },
    { lat: 48.0870688, lng: 17.44976 },
    { lat: 48.0869161, lng: 17.449982 },
    { lat: 48.0864902, lng: 17.4502431 },
    { lat: 48.0862077, lng: 17.4502765 },
    { lat: 48.0857433, lng: 17.450149 },
    { lat: 48.0856494, lng: 17.4501816 },
    { lat: 48.0855738, lng: 17.4503232 },
    { lat: 48.0854737, lng: 17.4506952 },
    { lat: 48.0854415, lng: 17.4511396 },
    { lat: 48.0847751, lng: 17.4524951 },
    { lat: 48.0845379, lng: 17.4528437 },
    { lat: 48.0841424, lng: 17.4537994 },
    { lat: 48.0835954, lng: 17.456631 },
    { lat: 48.0824972, lng: 17.459482 },
    { lat: 48.0822802, lng: 17.4598117 },
    { lat: 48.082052, lng: 17.4599347 },
    { lat: 48.0816779, lng: 17.4599979 },
    { lat: 48.0814989, lng: 17.4599051 },
    { lat: 48.0810516, lng: 17.4594345 },
    { lat: 48.0806847, lng: 17.4591732 },
    { lat: 48.0804331, lng: 17.4590898 },
    { lat: 48.0801788, lng: 17.4591568 },
    { lat: 48.0799366, lng: 17.4593924 },
    { lat: 48.0793851, lng: 17.460345 },
    { lat: 48.0790263, lng: 17.4607684 },
    { lat: 48.0787328, lng: 17.4609802 },
    { lat: 48.0778764, lng: 17.4613757 },
    { lat: 48.0774551, lng: 17.4613938 },
    { lat: 48.0771091, lng: 17.4612834 },
    { lat: 48.0764867, lng: 17.4609663 },
    { lat: 48.0758022, lng: 17.4600085 },
    { lat: 48.0756494, lng: 17.4598999 },
    { lat: 48.0753571, lng: 17.4598315 },
    { lat: 48.0750779, lng: 17.4601246 },
    { lat: 48.074724, lng: 17.4605027 },
    { lat: 48.0743473, lng: 17.4616433 },
    { lat: 48.0741599, lng: 17.4628456 },
    { lat: 48.0741955, lng: 17.4631682 },
    { lat: 48.0745357, lng: 17.4641496 },
    { lat: 48.0746159, lng: 17.4646564 },
    { lat: 48.0744846, lng: 17.465228 },
    { lat: 48.073952, lng: 17.4661248 },
    { lat: 48.0738596, lng: 17.4665806 },
    { lat: 48.0739219, lng: 17.4673676 },
    { lat: 48.0740144, lng: 17.4679267 },
    { lat: 48.0745257, lng: 17.4698397 },
    { lat: 48.0746103, lng: 17.4704847 },
    { lat: 48.0746383, lng: 17.4714599 },
    { lat: 48.0743977, lng: 17.4723456 },
    { lat: 48.0743521, lng: 17.4733717 },
    { lat: 48.0739298, lng: 17.4742119 },
    { lat: 48.073756, lng: 17.4746667 },
    { lat: 48.0734905, lng: 17.4750662 },
    { lat: 48.0732008, lng: 17.475425 },
    { lat: 48.0725224, lng: 17.4760043 },
    { lat: 48.0727217, lng: 17.4763641 },
    { lat: 48.0735037, lng: 17.4805462 },
  ],
  LúčnaOstrove: [
    { lat: 47.9803104, lng: 17.5057284 },
    { lat: 47.9802702, lng: 17.5055935 },
    { lat: 47.9801019, lng: 17.5055774 },
    { lat: 47.9802251, lng: 17.5044572 },
    { lat: 47.980647, lng: 17.5024371 },
    { lat: 47.9805295, lng: 17.5022118 },
    { lat: 47.9801533, lng: 17.5010362 },
    { lat: 47.9797418, lng: 17.500435 },
    { lat: 47.9791303, lng: 17.499186 },
    { lat: 47.9783632, lng: 17.4979192 },
    { lat: 47.9784325, lng: 17.4978236 },
    { lat: 47.9784085, lng: 17.4975916 },
    { lat: 47.9776635, lng: 17.4946246 },
    { lat: 47.9767734, lng: 17.4936959 },
    { lat: 47.9758961, lng: 17.4937871 },
    { lat: 47.9755366, lng: 17.4936225 },
    { lat: 47.9751974, lng: 17.4936172 },
    { lat: 47.9741207, lng: 17.4934174 },
    { lat: 47.9735306, lng: 17.4926755 },
    { lat: 47.9729728, lng: 17.4921708 },
    { lat: 47.9713168, lng: 17.4911881 },
    { lat: 47.9709067, lng: 17.4906937 },
    { lat: 47.9707309, lng: 17.4898967 },
    { lat: 47.9707792, lng: 17.4892463 },
    { lat: 47.9708976, lng: 17.4888265 },
    { lat: 47.970019, lng: 17.4875323 },
    { lat: 47.968154, lng: 17.4851893 },
    { lat: 47.9673531, lng: 17.4846618 },
    { lat: 47.9653782, lng: 17.4838648 },
    { lat: 47.9627208, lng: 17.4834297 },
    { lat: 47.9588914, lng: 17.4820868 },
    { lat: 47.9567484, lng: 17.4845016 },
    { lat: 47.9561887, lng: 17.4849299 },
    { lat: 47.955709, lng: 17.485895 },
    { lat: 47.9551319, lng: 17.4861191 },
    { lat: 47.9546988, lng: 17.4867738 },
    { lat: 47.9542223, lng: 17.4870204 },
    { lat: 47.9538536, lng: 17.4878145 },
    { lat: 47.953641, lng: 17.4886478 },
    { lat: 47.9533515, lng: 17.4889937 },
    { lat: 47.9526249, lng: 17.4896661 },
    { lat: 47.9515649, lng: 17.4903197 },
    { lat: 47.9501555, lng: 17.4901092 },
    { lat: 47.9487331, lng: 17.4895085 },
    { lat: 47.9473709, lng: 17.4888602 },
    { lat: 47.9439781, lng: 17.4870746 },
    { lat: 47.9438701, lng: 17.4869598 },
    { lat: 47.9437577, lng: 17.4869516 },
    { lat: 47.9437405, lng: 17.48707 },
    { lat: 47.9434498, lng: 17.4889633 },
    { lat: 47.9428022, lng: 17.4914343 },
    { lat: 47.9419952, lng: 17.4942559 },
    { lat: 47.9406904, lng: 17.4977926 },
    { lat: 47.9404074, lng: 17.497713 },
    { lat: 47.9399976, lng: 17.4977139 },
    { lat: 47.9398022, lng: 17.4978032 },
    { lat: 47.9394723, lng: 17.4981832 },
    { lat: 47.9392599, lng: 17.4986732 },
    { lat: 47.9391621, lng: 17.499082 },
    { lat: 47.9390014, lng: 17.500195 },
    { lat: 47.9388732, lng: 17.5007822 },
    { lat: 47.9384825, lng: 17.5018291 },
    { lat: 47.9384225, lng: 17.5021434 },
    { lat: 47.9383906, lng: 17.5025068 },
    { lat: 47.9384197, lng: 17.502832 },
    { lat: 47.9387395, lng: 17.5040794 },
    { lat: 47.9389414, lng: 17.5051423 },
    { lat: 47.9395433, lng: 17.5058589 },
    { lat: 47.940327, lng: 17.5071887 },
    { lat: 47.9406296, lng: 17.5078136 },
    { lat: 47.9418578, lng: 17.5089281 },
    { lat: 47.9424512, lng: 17.5094072 },
    { lat: 47.9429746, lng: 17.5095895 },
    { lat: 47.9433318, lng: 17.5101234 },
    { lat: 47.9435697, lng: 17.5113239 },
    { lat: 47.9440703, lng: 17.5120625 },
    { lat: 47.9447661, lng: 17.5128186 },
    { lat: 47.9464705, lng: 17.5139249 },
    { lat: 47.9471544, lng: 17.5141819 },
    { lat: 47.9491205, lng: 17.5147584 },
    { lat: 47.9496343, lng: 17.5150052 },
    { lat: 47.9501665, lng: 17.5153938 },
    { lat: 47.9504246, lng: 17.51591 },
    { lat: 47.9507346, lng: 17.5172687 },
    { lat: 47.9506313, lng: 17.5182582 },
    { lat: 47.9502314, lng: 17.5190628 },
    { lat: 47.9501255, lng: 17.5195929 },
    { lat: 47.9506346, lng: 17.5209937 },
    { lat: 47.950733, lng: 17.5221354 },
    { lat: 47.9505231, lng: 17.5226991 },
    { lat: 47.9501069, lng: 17.5229677 },
    { lat: 47.9495647, lng: 17.5228274 },
    { lat: 47.9489021, lng: 17.5222354 },
    { lat: 47.9483779, lng: 17.5215109 },
    { lat: 47.9477842, lng: 17.5213065 },
    { lat: 47.9474483, lng: 17.5215982 },
    { lat: 47.9464046, lng: 17.5233649 },
    { lat: 47.9461611, lng: 17.5242133 },
    { lat: 47.9459075, lng: 17.5255562 },
    { lat: 47.9459136, lng: 17.5289497 },
    { lat: 47.9461093, lng: 17.5289901 },
    { lat: 47.946554, lng: 17.5288436 },
    { lat: 47.9470043, lng: 17.5292944 },
    { lat: 47.9474061, lng: 17.5308549 },
    { lat: 47.9475376, lng: 17.532075 },
    { lat: 47.9475831, lng: 17.5331308 },
    { lat: 47.9475827, lng: 17.5337219 },
    { lat: 47.9474149, lng: 17.5342583 },
    { lat: 47.9478823, lng: 17.5366627 },
    { lat: 47.9482919, lng: 17.5394687 },
    { lat: 47.9484558, lng: 17.5399966 },
    { lat: 47.9503919, lng: 17.539354 },
    { lat: 47.9510583, lng: 17.539233 },
    { lat: 47.9516227, lng: 17.5392485 },
    { lat: 47.9521168, lng: 17.5391007 },
    { lat: 47.9525725, lng: 17.5388794 },
    { lat: 47.952563, lng: 17.5409478 },
    { lat: 47.9528915, lng: 17.5410849 },
    { lat: 47.9533639, lng: 17.5408285 },
    { lat: 47.9545482, lng: 17.5407871 },
    { lat: 47.9548443, lng: 17.5407397 },
    { lat: 47.9549148, lng: 17.5406398 },
    { lat: 47.9548667, lng: 17.5403679 },
    { lat: 47.9547748, lng: 17.5400788 },
    { lat: 47.9546603, lng: 17.5397541 },
    { lat: 47.9542556, lng: 17.5391357 },
    { lat: 47.9536743, lng: 17.5387226 },
    { lat: 47.9529773, lng: 17.537999 },
    { lat: 47.9528277, lng: 17.5375615 },
    { lat: 47.9527086, lng: 17.5366552 },
    { lat: 47.9527542, lng: 17.5362936 },
    { lat: 47.9528831, lng: 17.5358395 },
    { lat: 47.9532796, lng: 17.5344021 },
    { lat: 47.9535294, lng: 17.5338782 },
    { lat: 47.9542646, lng: 17.5317912 },
    { lat: 47.9542723, lng: 17.5316644 },
    { lat: 47.9537729, lng: 17.5308527 },
    { lat: 47.9537355, lng: 17.5301655 },
    { lat: 47.9537989, lng: 17.5294287 },
    { lat: 47.9539799, lng: 17.5289431 },
    { lat: 47.95422, lng: 17.5285143 },
    { lat: 47.9546148, lng: 17.528159 },
    { lat: 47.9551251, lng: 17.5280686 },
    { lat: 47.9555644, lng: 17.5278245 },
    { lat: 47.9563385, lng: 17.5280041 },
    { lat: 47.9567443, lng: 17.5282763 },
    { lat: 47.9572122, lng: 17.5301053 },
    { lat: 47.957318, lng: 17.5319271 },
    { lat: 47.9571942, lng: 17.532655 },
    { lat: 47.9570403, lng: 17.5332459 },
    { lat: 47.9569813, lng: 17.5341731 },
    { lat: 47.9565978, lng: 17.534723 },
    { lat: 47.9563374, lng: 17.5352576 },
    { lat: 47.9565223, lng: 17.5357456 },
    { lat: 47.9570376, lng: 17.5362077 },
    { lat: 47.957662, lng: 17.5364534 },
    { lat: 47.9584452, lng: 17.5369531 },
    { lat: 47.9588034, lng: 17.5364832 },
    { lat: 47.9589877, lng: 17.535783 },
    { lat: 47.9589085, lng: 17.5349374 },
    { lat: 47.9582752, lng: 17.5330449 },
    { lat: 47.9582605, lng: 17.5312337 },
    { lat: 47.9585012, lng: 17.5305352 },
    { lat: 47.9587703, lng: 17.5302411 },
    { lat: 47.9593888, lng: 17.5302371 },
    { lat: 47.9595693, lng: 17.5303023 },
    { lat: 47.9596378, lng: 17.5304035 },
    { lat: 47.9598063, lng: 17.5306863 },
    { lat: 47.9599397, lng: 17.53183 },
    { lat: 47.9602679, lng: 17.5328271 },
    { lat: 47.9607274, lng: 17.53372 },
    { lat: 47.961093, lng: 17.5340152 },
    { lat: 47.9617466, lng: 17.5340112 },
    { lat: 47.9624502, lng: 17.5338352 },
    { lat: 47.9628947, lng: 17.5335464 },
    { lat: 47.96366, lng: 17.5327499 },
    { lat: 47.9637476, lng: 17.5319006 },
    { lat: 47.9635041, lng: 17.5310291 },
    { lat: 47.9629506, lng: 17.53057 },
    { lat: 47.9622648, lng: 17.5308125 },
    { lat: 47.9616658, lng: 17.5287322 },
    { lat: 47.961502, lng: 17.5280114 },
    { lat: 47.9614745, lng: 17.5275834 },
    { lat: 47.9615207, lng: 17.5255808 },
    { lat: 47.9614815, lng: 17.5228861 },
    { lat: 47.961988, lng: 17.5219295 },
    { lat: 47.9624619, lng: 17.5215003 },
    { lat: 47.9630656, lng: 17.5213176 },
    { lat: 47.9640553, lng: 17.5222366 },
    { lat: 47.9654137, lng: 17.5240948 },
    { lat: 47.965706, lng: 17.5251461 },
    { lat: 47.9666622, lng: 17.5271242 },
    { lat: 47.9670483, lng: 17.5302119 },
    { lat: 47.9676113, lng: 17.5329432 },
    { lat: 47.9686413, lng: 17.5355625 },
    { lat: 47.9692256, lng: 17.536923 },
    { lat: 47.9704562, lng: 17.5385142 },
    { lat: 47.9711935, lng: 17.5386019 },
    { lat: 47.9725416, lng: 17.5385798 },
    { lat: 47.9734422, lng: 17.5391855 },
    { lat: 47.9735989, lng: 17.5401672 },
    { lat: 47.9731212, lng: 17.5413298 },
    { lat: 47.9733626, lng: 17.5417832 },
    { lat: 47.973701, lng: 17.5421621 },
    { lat: 47.9743134, lng: 17.5423435 },
    { lat: 47.9749508, lng: 17.5422457 },
    { lat: 47.9755386, lng: 17.5417444 },
    { lat: 47.9766482, lng: 17.5409999 },
    { lat: 47.9770786, lng: 17.5407705 },
    { lat: 47.9773526, lng: 17.5406963 },
    { lat: 47.9778831, lng: 17.5407099 },
    { lat: 47.9786814, lng: 17.5410949 },
    { lat: 47.9793329, lng: 17.5411424 },
    { lat: 47.9799394, lng: 17.5414969 },
    { lat: 47.9802066, lng: 17.5415262 },
    { lat: 47.9803514, lng: 17.5412648 },
    { lat: 47.9805211, lng: 17.5407085 },
    { lat: 47.9806138, lng: 17.5400073 },
    { lat: 47.9805685, lng: 17.5396006 },
    { lat: 47.9802671, lng: 17.5390039 },
    { lat: 47.9798219, lng: 17.5384551 },
    { lat: 47.9794082, lng: 17.5377097 },
    { lat: 47.9791569, lng: 17.5368736 },
    { lat: 47.9790519, lng: 17.5361015 },
    { lat: 47.9788354, lng: 17.5355603 },
    { lat: 47.9785747, lng: 17.5351781 },
    { lat: 47.9783345, lng: 17.5341793 },
    { lat: 47.9781111, lng: 17.5339733 },
    { lat: 47.9777968, lng: 17.5339324 },
    { lat: 47.977282, lng: 17.5343219 },
    { lat: 47.9767315, lng: 17.534122 },
    { lat: 47.9761739, lng: 17.5340175 },
    { lat: 47.9756784, lng: 17.533532 },
    { lat: 47.9751902, lng: 17.5331745 },
    { lat: 47.9748473, lng: 17.5321615 },
    { lat: 47.9747708, lng: 17.5317636 },
    { lat: 47.9746497, lng: 17.5299317 },
    { lat: 47.9746662, lng: 17.5281676 },
    { lat: 47.9745685, lng: 17.5273703 },
    { lat: 47.9745796, lng: 17.5271195 },
    { lat: 47.9750562, lng: 17.5264531 },
    { lat: 47.9755585, lng: 17.5259406 },
    { lat: 47.9760194, lng: 17.5256975 },
    { lat: 47.9767268, lng: 17.525212 },
    { lat: 47.9768065, lng: 17.525083 },
    { lat: 47.9770779, lng: 17.5248761 },
    { lat: 47.9773185, lng: 17.5247727 },
    { lat: 47.9777598, lng: 17.5248049 },
    { lat: 47.978267, lng: 17.5249637 },
    { lat: 47.978964, lng: 17.5254774 },
    { lat: 47.9793345, lng: 17.5259712 },
    { lat: 47.9800762, lng: 17.5275286 },
    { lat: 47.9803257, lng: 17.5277732 },
    { lat: 47.9805579, lng: 17.5277662 },
    { lat: 47.9808183, lng: 17.527882 },
    { lat: 47.9810149, lng: 17.5281465 },
    { lat: 47.9816582, lng: 17.5294241 },
    { lat: 47.9822177, lng: 17.5309244 },
    { lat: 47.9824635, lng: 17.5313208 },
    { lat: 47.9825912, lng: 17.5316392 },
    { lat: 47.9826096, lng: 17.5319442 },
    { lat: 47.9825497, lng: 17.5322742 },
    { lat: 47.9823454, lng: 17.5325758 },
    { lat: 47.98204, lng: 17.5328168 },
    { lat: 47.9817372, lng: 17.5328846 },
    { lat: 47.9815564, lng: 17.5330574 },
    { lat: 47.9815147, lng: 17.5332702 },
    { lat: 47.9818867, lng: 17.5337857 },
    { lat: 47.9831816, lng: 17.534746 },
    { lat: 47.983795, lng: 17.5347836 },
    { lat: 47.9841497, lng: 17.5347313 },
    { lat: 47.9844855, lng: 17.53465 },
    { lat: 47.9846852, lng: 17.5344551 },
    { lat: 47.9847179, lng: 17.5344378 },
    { lat: 47.9855939, lng: 17.533291 },
    { lat: 47.9860594, lng: 17.5325743 },
    { lat: 47.9862938, lng: 17.5319673 },
    { lat: 47.9880369, lng: 17.5331898 },
    { lat: 47.9901156, lng: 17.5349249 },
    { lat: 47.9912079, lng: 17.5349472 },
    { lat: 47.9919282, lng: 17.5339773 },
    { lat: 47.9923495, lng: 17.5331864 },
    { lat: 47.9933451, lng: 17.5308967 },
    { lat: 47.9939479, lng: 17.529098 },
    { lat: 47.9940571, lng: 17.5280586 },
    { lat: 47.993982, lng: 17.5272584 },
    { lat: 47.9935176, lng: 17.5268847 },
    { lat: 47.9935354, lng: 17.5264768 },
    { lat: 47.9936238, lng: 17.524622 },
    { lat: 47.9937194, lng: 17.5235617 },
    { lat: 47.9940151, lng: 17.5219971 },
    { lat: 47.9942494, lng: 17.5220754 },
    { lat: 47.9944025, lng: 17.5189831 },
    { lat: 47.9943968, lng: 17.5184536 },
    { lat: 47.9942861, lng: 17.5176645 },
    { lat: 47.9939131, lng: 17.5173805 },
    { lat: 47.9936541, lng: 17.5170921 },
    { lat: 47.9934993, lng: 17.5168089 },
    { lat: 47.9931863, lng: 17.5158767 },
    { lat: 47.9924039, lng: 17.5166178 },
    { lat: 47.992223, lng: 17.516668 },
    { lat: 47.9920799, lng: 17.5166215 },
    { lat: 47.99182, lng: 17.5161788 },
    { lat: 47.9911887, lng: 17.5156183 },
    { lat: 47.9892366, lng: 17.5143437 },
    { lat: 47.9884731, lng: 17.5136773 },
    { lat: 47.9881711, lng: 17.5133208 },
    { lat: 47.9878346, lng: 17.512759 },
    { lat: 47.9873205, lng: 17.5117038 },
    { lat: 47.9867662, lng: 17.5109546 },
    { lat: 47.9852438, lng: 17.5105492 },
    { lat: 47.9838309, lng: 17.5099024 },
    { lat: 47.9837285, lng: 17.5098004 },
    { lat: 47.9831162, lng: 17.5085373 },
    { lat: 47.9824007, lng: 17.5066016 },
    { lat: 47.9819152, lng: 17.5060091 },
    { lat: 47.9814042, lng: 17.505703 },
    { lat: 47.9804178, lng: 17.5056954 },
    { lat: 47.9803104, lng: 17.5057284 },
  ],
  Povoda: [
    { lat: 47.9590254, lng: 17.6494264 },
    { lat: 47.9590438, lng: 17.6498264 },
    { lat: 47.960636, lng: 17.6512927 },
    { lat: 47.9597944, lng: 17.6534457 },
    { lat: 47.9594835, lng: 17.6560316 },
    { lat: 47.9597492, lng: 17.6589681 },
    { lat: 47.9600554, lng: 17.6604545 },
    { lat: 47.960358, lng: 17.6631707 },
    { lat: 47.9621482, lng: 17.6649341 },
    { lat: 47.9632643, lng: 17.662456 },
    { lat: 47.9633645, lng: 17.6625565 },
    { lat: 47.9652247, lng: 17.6591691 },
    { lat: 47.9654911, lng: 17.6586854 },
    { lat: 47.9657059, lng: 17.6582946 },
    { lat: 47.9689725, lng: 17.6523643 },
    { lat: 47.9691532, lng: 17.6520606 },
    { lat: 47.9696913, lng: 17.6514285 },
    { lat: 47.9722615, lng: 17.6485756 },
    { lat: 47.9737778, lng: 17.6504859 },
    { lat: 47.9747341, lng: 17.648734 },
    { lat: 47.9745834, lng: 17.6481354 },
    { lat: 47.9747985, lng: 17.647948 },
    { lat: 47.974945, lng: 17.6481586 },
    { lat: 47.9751937, lng: 17.6483433 },
    { lat: 47.9753024, lng: 17.6485616 },
    { lat: 47.97558, lng: 17.6487708 },
    { lat: 47.97779, lng: 17.644725 },
    { lat: 47.9825014, lng: 17.6360135 },
    { lat: 47.9825898, lng: 17.6358397 },
    { lat: 47.9792635, lng: 17.6340637 },
    { lat: 47.9788977, lng: 17.6338459 },
    { lat: 47.977609, lng: 17.6336479 },
    { lat: 47.977576, lng: 17.6334658 },
    { lat: 47.9783534, lng: 17.6294667 },
    { lat: 47.9793477, lng: 17.6243497 },
    { lat: 47.979077, lng: 17.6233401 },
    { lat: 47.9786806, lng: 17.6214897 },
    { lat: 47.9783267, lng: 17.6203432 },
    { lat: 47.9779164, lng: 17.619411 },
    { lat: 47.978029, lng: 17.6192278 },
    { lat: 47.9779764, lng: 17.6191385 },
    { lat: 47.977934, lng: 17.6190664 },
    { lat: 47.9777724, lng: 17.6188581 },
    { lat: 47.9775202, lng: 17.618533 },
    { lat: 47.9773977, lng: 17.6183751 },
    { lat: 47.9774217, lng: 17.6182382 },
    { lat: 47.977219, lng: 17.6180534 },
    { lat: 47.9770482, lng: 17.6178977 },
    { lat: 47.9768641, lng: 17.6177299 },
    { lat: 47.9766403, lng: 17.6175258 },
    { lat: 47.9763865, lng: 17.6173962 },
    { lat: 47.9761152, lng: 17.6172576 },
    { lat: 47.9759623, lng: 17.6172025 },
    { lat: 47.9756541, lng: 17.6170913 },
    { lat: 47.9753509, lng: 17.6169819 },
    { lat: 47.9750757, lng: 17.6168826 },
    { lat: 47.9749434, lng: 17.6168349 },
    { lat: 47.974664, lng: 17.6168526 },
    { lat: 47.9741827, lng: 17.6168831 },
    { lat: 47.9715892, lng: 17.6170476 },
    { lat: 47.9714828, lng: 17.6170543 },
    { lat: 47.9709079, lng: 17.6170623 },
    { lat: 47.9702976, lng: 17.6170708 },
    { lat: 47.9701256, lng: 17.6170732 },
    { lat: 47.9693432, lng: 17.6169483 },
    { lat: 47.9689779, lng: 17.61689 },
    { lat: 47.9688075, lng: 17.6168301 },
    { lat: 47.9684411, lng: 17.6167015 },
    { lat: 47.9681258, lng: 17.6165907 },
    { lat: 47.9680471, lng: 17.6165631 },
    { lat: 47.967877, lng: 17.6163137 },
    { lat: 47.9676577, lng: 17.6159922 },
    { lat: 47.9674366, lng: 17.6156679 },
    { lat: 47.9671991, lng: 17.6153197 },
    { lat: 47.967174, lng: 17.6152619 },
    { lat: 47.9669178, lng: 17.6146712 },
    { lat: 47.9667992, lng: 17.6143979 },
    { lat: 47.9667585, lng: 17.614304 },
    { lat: 47.9664936, lng: 17.6138309 },
    { lat: 47.9663063, lng: 17.6134963 },
    { lat: 47.9662311, lng: 17.6133619 },
    { lat: 47.9660534, lng: 17.6131921 },
    { lat: 47.965736, lng: 17.6128888 },
    { lat: 47.9653608, lng: 17.6125302 },
    { lat: 47.9652874, lng: 17.6124601 },
    { lat: 47.9649158, lng: 17.6122572 },
    { lat: 47.9643878, lng: 17.6119689 },
    { lat: 47.9642767, lng: 17.6119082 },
    { lat: 47.9639586, lng: 17.611835 },
    { lat: 47.9637421, lng: 17.6117853 },
    { lat: 47.9634177, lng: 17.6117106 },
    { lat: 47.9632235, lng: 17.611666 },
    { lat: 47.9630885, lng: 17.6116872 },
    { lat: 47.9626115, lng: 17.6117623 },
    { lat: 47.9621446, lng: 17.6118357 },
    { lat: 47.9619826, lng: 17.6119367 },
    { lat: 47.9615435, lng: 17.6122104 },
    { lat: 47.9613952, lng: 17.6123028 },
    { lat: 47.9611742, lng: 17.6126401 },
    { lat: 47.9607932, lng: 17.6132215 },
    { lat: 47.9603559, lng: 17.6134161 },
    { lat: 47.9598801, lng: 17.6136278 },
    { lat: 47.9593093, lng: 17.6138818 },
    { lat: 47.9572626, lng: 17.6147924 },
    { lat: 47.9564039, lng: 17.6152621 },
    { lat: 47.9562438, lng: 17.615837 },
    { lat: 47.9553481, lng: 17.6195628 },
    { lat: 47.9548554, lng: 17.6209619 },
    { lat: 47.9544068, lng: 17.6219715 },
    { lat: 47.9554021, lng: 17.6243437 },
    { lat: 47.9560892, lng: 17.6254901 },
    { lat: 47.95734, lng: 17.6274079 },
    { lat: 47.9586355, lng: 17.6291016 },
    { lat: 47.9591774, lng: 17.6293139 },
    { lat: 47.959547, lng: 17.6296945 },
    { lat: 47.9601624, lng: 17.6298183 },
    { lat: 47.9608589, lng: 17.6301892 },
    { lat: 47.9610968, lng: 17.6290648 },
    { lat: 47.9611811, lng: 17.6283983 },
    { lat: 47.9611926, lng: 17.626692 },
    { lat: 47.9612518, lng: 17.6265583 },
    { lat: 47.9614102, lng: 17.626493 },
    { lat: 47.9618949, lng: 17.6265133 },
    { lat: 47.9619589, lng: 17.6265631 },
    { lat: 47.9624221, lng: 17.6276883 },
    { lat: 47.9627356, lng: 17.6285722 },
    { lat: 47.9629029, lng: 17.6292656 },
    { lat: 47.9648841, lng: 17.6317512 },
    { lat: 47.9661728, lng: 17.6329694 },
    { lat: 47.9668945, lng: 17.6335599 },
    { lat: 47.9669744, lng: 17.6336815 },
    { lat: 47.9632533, lng: 17.6415294 },
    { lat: 47.9619399, lng: 17.6458253 },
    { lat: 47.9610451, lng: 17.646282 },
    { lat: 47.9606958, lng: 17.6465984 },
    { lat: 47.9598898, lng: 17.6470827 },
    { lat: 47.9592108, lng: 17.6488079 },
    { lat: 47.9590254, lng: 17.6494264 },
  ],
  NovýŽivot: [
    { lat: 48.0868883, lng: 17.4941822 },
    { lat: 48.0889569, lng: 17.4926872 },
    { lat: 48.0900926, lng: 17.4944668 },
    { lat: 48.0945732, lng: 17.5004122 },
    { lat: 48.0954609, lng: 17.501518 },
    { lat: 48.0981741, lng: 17.503751 },
    { lat: 48.0985181, lng: 17.5039217 },
    { lat: 48.0994774, lng: 17.501228 },
    { lat: 48.100639, lng: 17.5002377 },
    { lat: 48.1019238, lng: 17.4989876 },
    { lat: 48.102365, lng: 17.4996651 },
    { lat: 48.1026076, lng: 17.5005037 },
    { lat: 48.1041172, lng: 17.5012339 },
    { lat: 48.1089183, lng: 17.5036061 },
    { lat: 48.1089043, lng: 17.5039841 },
    { lat: 48.1088662, lng: 17.5052517 },
    { lat: 48.1021229, lng: 17.5356327 },
    { lat: 48.1064838, lng: 17.5377928 },
    { lat: 48.106696, lng: 17.538052 },
    { lat: 48.106939, lng: 17.537647 },
    { lat: 48.107194, lng: 17.537331 },
    { lat: 48.107462, lng: 17.537178 },
    { lat: 48.107883, lng: 17.537053 },
    { lat: 48.1082, lng: 17.537005 },
    { lat: 48.108559, lng: 17.537039 },
    { lat: 48.108919, lng: 17.537138 },
    { lat: 48.109129, lng: 17.537223 },
    { lat: 48.10954, lng: 17.537423 },
    { lat: 48.10987, lng: 17.537612 },
    { lat: 48.110206, lng: 17.537881 },
    { lat: 48.110438, lng: 17.538133 },
    { lat: 48.110793, lng: 17.538576 },
    { lat: 48.110925, lng: 17.538753 },
    { lat: 48.111229, lng: 17.539358 },
    { lat: 48.111596, lng: 17.540269 },
    { lat: 48.111723, lng: 17.540672 },
    { lat: 48.111798, lng: 17.540959 },
    { lat: 48.111869, lng: 17.541254 },
    { lat: 48.112045, lng: 17.541716 },
    { lat: 48.112163, lng: 17.542126 },
    { lat: 48.112365, lng: 17.542602 },
    { lat: 48.112774, lng: 17.543893 },
    { lat: 48.113081, lng: 17.544617 },
    { lat: 48.113723, lng: 17.545588 },
    { lat: 48.114335, lng: 17.546321 },
    { lat: 48.11451, lng: 17.546474 },
    { lat: 48.115082, lng: 17.546848 },
    { lat: 48.115232, lng: 17.546915 },
    { lat: 48.116098, lng: 17.547207 },
    { lat: 48.117022, lng: 17.547255 },
    { lat: 48.117533, lng: 17.547228 },
    { lat: 48.117877, lng: 17.547063 },
    { lat: 48.118361, lng: 17.546789 },
    { lat: 48.118936, lng: 17.546356 },
    { lat: 48.119248, lng: 17.546148 },
    { lat: 48.119356, lng: 17.546076 },
    { lat: 48.11952, lng: 17.545952 },
    { lat: 48.120229, lng: 17.545414 },
    { lat: 48.122076, lng: 17.543888 },
    { lat: 48.121845, lng: 17.543276 },
    { lat: 48.121822, lng: 17.543204 },
    { lat: 48.121798, lng: 17.543135 },
    { lat: 48.121727, lng: 17.542918 },
    { lat: 48.121499, lng: 17.542251 },
    { lat: 48.121263, lng: 17.541523 },
    { lat: 48.121185, lng: 17.541194 },
    { lat: 48.121374, lng: 17.540829 },
    { lat: 48.121587, lng: 17.540142 },
    { lat: 48.121931, lng: 17.539185 },
    { lat: 48.122266, lng: 17.538116 },
    { lat: 48.122391, lng: 17.536524 },
    { lat: 48.122341, lng: 17.535678 },
    { lat: 48.122338, lng: 17.534832 },
    { lat: 48.122331, lng: 17.534232 },
    { lat: 48.122216, lng: 17.533804 },
    { lat: 48.122125, lng: 17.533478 },
    { lat: 48.122011, lng: 17.533187 },
    { lat: 48.121809, lng: 17.53278 },
    { lat: 48.121683, lng: 17.532504 },
    { lat: 48.121471, lng: 17.532055 },
    { lat: 48.121155, lng: 17.531568 },
    { lat: 48.121003, lng: 17.531257 },
    { lat: 48.121002, lng: 17.531253 },
    { lat: 48.120902, lng: 17.53105 },
    { lat: 48.120878, lng: 17.531 },
    { lat: 48.120659, lng: 17.530552 },
    { lat: 48.120341, lng: 17.530716 },
    { lat: 48.120198, lng: 17.530862 },
    { lat: 48.119768, lng: 17.53101 },
    { lat: 48.118858, lng: 17.531373 },
    { lat: 48.118099, lng: 17.531484 },
    { lat: 48.117459, lng: 17.531481 },
    { lat: 48.117204, lng: 17.53145 },
    { lat: 48.116993, lng: 17.53139 },
    { lat: 48.116637, lng: 17.531246 },
    { lat: 48.116385, lng: 17.531095 },
    { lat: 48.115943, lng: 17.530757 },
    { lat: 48.115603, lng: 17.530504 },
    { lat: 48.115335, lng: 17.530249 },
    { lat: 48.114804, lng: 17.529517 },
    { lat: 48.114311, lng: 17.528603 },
    { lat: 48.113941, lng: 17.527399 },
    { lat: 48.113767, lng: 17.526536 },
    { lat: 48.113696, lng: 17.525771 },
    { lat: 48.113681, lng: 17.525377 },
    { lat: 48.113771, lng: 17.524492 },
    { lat: 48.113807, lng: 17.524128 },
    { lat: 48.113993, lng: 17.52294 },
    { lat: 48.114249, lng: 17.522017 },
    { lat: 48.114544, lng: 17.521516 },
    { lat: 48.114906, lng: 17.520762 },
    { lat: 48.115897, lng: 17.519198 },
    { lat: 48.116226, lng: 17.518466 },
    { lat: 48.116366, lng: 17.518171 },
    { lat: 48.116708, lng: 17.51742 },
    { lat: 48.117234, lng: 17.51611 },
    { lat: 48.117632, lng: 17.515222 },
    { lat: 48.117943, lng: 17.514702 },
    { lat: 48.118399, lng: 17.514167 },
    { lat: 48.118606, lng: 17.514094 },
    { lat: 48.118688, lng: 17.514056 },
    { lat: 48.119386, lng: 17.513681 },
    { lat: 48.119853, lng: 17.513409 },
    { lat: 48.120096, lng: 17.513203 },
    { lat: 48.120309, lng: 17.513195 },
    { lat: 48.121113, lng: 17.513214 },
    { lat: 48.12164, lng: 17.513376 },
    { lat: 48.122238, lng: 17.513606 },
    { lat: 48.122029, lng: 17.512629 },
    { lat: 48.122015, lng: 17.512559 },
    { lat: 48.122008, lng: 17.512514 },
    { lat: 48.122004, lng: 17.512494 },
    { lat: 48.1219112, lng: 17.5120418 },
    { lat: 48.121609, lng: 17.51057 },
    { lat: 48.121618, lng: 17.510534 },
    { lat: 48.121615, lng: 17.510499 },
    { lat: 48.121747, lng: 17.509791 },
    { lat: 48.121906, lng: 17.509049 },
    { lat: 48.121918, lng: 17.508689 },
    { lat: 48.121643, lng: 17.507813 },
    { lat: 48.121642, lng: 17.507809 },
    { lat: 48.121617, lng: 17.507726 },
    { lat: 48.12133, lng: 17.506785 },
    { lat: 48.121299, lng: 17.506677 },
    { lat: 48.121266, lng: 17.506567 },
    { lat: 48.121269, lng: 17.506456 },
    { lat: 48.12127, lng: 17.506442 },
    { lat: 48.121271, lng: 17.506377 },
    { lat: 48.121271, lng: 17.506364 },
    { lat: 48.121294, lng: 17.505157 },
    { lat: 48.121294, lng: 17.505125 },
    { lat: 48.121309, lng: 17.505024 },
    { lat: 48.121354, lng: 17.504736 },
    { lat: 48.12138, lng: 17.504561 },
    { lat: 48.121414, lng: 17.504336 },
    { lat: 48.121468, lng: 17.503979 },
    { lat: 48.12147, lng: 17.503954 },
    { lat: 48.121499, lng: 17.503589 },
    { lat: 48.121508, lng: 17.503409 },
    { lat: 48.121508, lng: 17.503398 },
    { lat: 48.121514, lng: 17.5033 },
    { lat: 48.121518, lng: 17.50318 },
    { lat: 48.121519, lng: 17.503141 },
    { lat: 48.12153, lng: 17.502884 },
    { lat: 48.121549, lng: 17.502667 },
    { lat: 48.121829, lng: 17.502166 },
    { lat: 48.12216, lng: 17.501625 },
    { lat: 48.122187, lng: 17.501582 },
    { lat: 48.122217, lng: 17.501533 },
    { lat: 48.122234, lng: 17.501505 },
    { lat: 48.122266, lng: 17.501453 },
    { lat: 48.122315, lng: 17.501373 },
    { lat: 48.122319, lng: 17.501365 },
    { lat: 48.122355, lng: 17.501321 },
    { lat: 48.122611, lng: 17.500999 },
    { lat: 48.122728, lng: 17.500944 },
    { lat: 48.123433, lng: 17.500116 },
    { lat: 48.125385, lng: 17.49772 },
    { lat: 48.12537, lng: 17.497684 },
    { lat: 48.125238, lng: 17.497379 },
    { lat: 48.125093, lng: 17.49693 },
    { lat: 48.125076, lng: 17.496804 },
    { lat: 48.125074, lng: 17.496671 },
    { lat: 48.12507, lng: 17.496638 },
    { lat: 48.124981, lng: 17.495993 },
    { lat: 48.124962, lng: 17.495715 },
    { lat: 48.124941, lng: 17.495607 },
    { lat: 48.124944, lng: 17.495442 },
    { lat: 48.124995, lng: 17.495219 },
    { lat: 48.124999, lng: 17.495095 },
    { lat: 48.125177, lng: 17.494668 },
    { lat: 48.125173, lng: 17.494457 },
    { lat: 48.125224, lng: 17.494312 },
    { lat: 48.125409, lng: 17.494097 },
    { lat: 48.125455, lng: 17.493977 },
    { lat: 48.12527, lng: 17.493804 },
    { lat: 48.125195, lng: 17.493785 },
    { lat: 48.125098, lng: 17.493793 },
    { lat: 48.125017, lng: 17.493713 },
    { lat: 48.124955, lng: 17.493472 },
    { lat: 48.124884, lng: 17.493121 },
    { lat: 48.124878, lng: 17.493092 },
    { lat: 48.124841, lng: 17.492919 },
    { lat: 48.124993, lng: 17.492413 },
    { lat: 48.125237, lng: 17.491643 },
    { lat: 48.125445, lng: 17.491266 },
    { lat: 48.125463, lng: 17.491234 },
    { lat: 48.125532, lng: 17.491106 },
    { lat: 48.125582, lng: 17.491027 },
    { lat: 48.125663, lng: 17.4909 },
    { lat: 48.125728, lng: 17.490799 },
    { lat: 48.125756, lng: 17.490756 },
    { lat: 48.125822, lng: 17.490673 },
    { lat: 48.12597, lng: 17.490484 },
    { lat: 48.126106, lng: 17.49031 },
    { lat: 48.126434, lng: 17.489893 },
    { lat: 48.126451, lng: 17.489871 },
    { lat: 48.126532, lng: 17.489768 },
    { lat: 48.126725, lng: 17.489522 },
    { lat: 48.127314, lng: 17.489079 },
    { lat: 48.127348, lng: 17.489053 },
    { lat: 48.12742, lng: 17.488999 },
    { lat: 48.127427, lng: 17.488996 },
    { lat: 48.127461, lng: 17.488983 },
    { lat: 48.127572, lng: 17.488941 },
    { lat: 48.127602, lng: 17.48893 },
    { lat: 48.127648, lng: 17.488914 },
    { lat: 48.127671, lng: 17.488905 },
    { lat: 48.12788, lng: 17.488827 },
    { lat: 48.127886, lng: 17.488825 },
    { lat: 48.128085, lng: 17.488769 },
    { lat: 48.128126, lng: 17.488768 },
    { lat: 48.1281272, lng: 17.4887679 },
    { lat: 48.128187, lng: 17.488765 },
    { lat: 48.128305, lng: 17.488761 },
    { lat: 48.128393, lng: 17.488758 },
    { lat: 48.128536, lng: 17.488753 },
    { lat: 48.12865, lng: 17.488749 },
    { lat: 48.128762, lng: 17.488745 },
    { lat: 48.128847, lng: 17.488742 },
    { lat: 48.128931, lng: 17.488738 },
    { lat: 48.129043, lng: 17.488735 },
    { lat: 48.129208, lng: 17.488729 },
    { lat: 48.129289, lng: 17.488726 },
    { lat: 48.129344, lng: 17.488723 },
    { lat: 48.129364, lng: 17.488723 },
    { lat: 48.129455, lng: 17.48873 },
    { lat: 48.129566, lng: 17.488739 },
    { lat: 48.129676, lng: 17.488748 },
    { lat: 48.129787, lng: 17.488758 },
    { lat: 48.129896, lng: 17.488766 },
    { lat: 48.129967, lng: 17.488772 },
    { lat: 48.129977, lng: 17.488771 },
    { lat: 48.130053, lng: 17.488759 },
    { lat: 48.130155, lng: 17.488742 },
    { lat: 48.130206, lng: 17.488735 },
    { lat: 48.130258, lng: 17.488727 },
    { lat: 48.130462, lng: 17.488695 },
    { lat: 48.130543, lng: 17.488683 },
    { lat: 48.130908, lng: 17.488564 },
    { lat: 48.130956, lng: 17.488539 },
    { lat: 48.131051, lng: 17.488489 },
    { lat: 48.131145, lng: 17.488439 },
    { lat: 48.131279, lng: 17.488369 },
    { lat: 48.131294, lng: 17.488361 },
    { lat: 48.131345, lng: 17.488334 },
    { lat: 48.13136, lng: 17.488326 },
    { lat: 48.131694, lng: 17.48815 },
    { lat: 48.13226, lng: 17.48787 },
    { lat: 48.132389, lng: 17.487807 },
    { lat: 48.132422, lng: 17.487791 },
    { lat: 48.132558, lng: 17.48771 },
    { lat: 48.132915, lng: 17.487525 },
    { lat: 48.133284, lng: 17.487237 },
    { lat: 48.134109, lng: 17.486659 },
    { lat: 48.134742, lng: 17.485879 },
    { lat: 48.134958, lng: 17.485532 },
    { lat: 48.135354, lng: 17.484853 },
    { lat: 48.135485, lng: 17.484627 },
    { lat: 48.135763, lng: 17.484147 },
    { lat: 48.135777, lng: 17.484114 },
    { lat: 48.135784, lng: 17.484086 },
    { lat: 48.135815, lng: 17.483983 },
    { lat: 48.13583, lng: 17.483941 },
    { lat: 48.135852, lng: 17.483915 },
    { lat: 48.135868, lng: 17.483924 },
    { lat: 48.13618, lng: 17.483975 },
    { lat: 48.136928, lng: 17.484119 },
    { lat: 48.137338, lng: 17.484308 },
    { lat: 48.137796, lng: 17.484452 },
    { lat: 48.138304, lng: 17.484581 },
    { lat: 48.138588, lng: 17.484714 },
    { lat: 48.138753, lng: 17.4848 },
    { lat: 48.139059, lng: 17.484858 },
    { lat: 48.139295, lng: 17.484889 },
    { lat: 48.139524, lng: 17.484852 },
    { lat: 48.140437, lng: 17.485035 },
    { lat: 48.140702, lng: 17.485058 },
    { lat: 48.14091, lng: 17.485 },
    { lat: 48.141127, lng: 17.484852 },
    { lat: 48.141218, lng: 17.484846 },
    { lat: 48.141415, lng: 17.484935 },
    { lat: 48.141528, lng: 17.48493 },
    { lat: 48.141638, lng: 17.484825 },
    { lat: 48.141838, lng: 17.484728 },
    { lat: 48.142132, lng: 17.484782 },
    { lat: 48.142388, lng: 17.484888 },
    { lat: 48.142582, lng: 17.484945 },
    { lat: 48.142671, lng: 17.484921 },
    { lat: 48.143252, lng: 17.484962 },
    { lat: 48.143324, lng: 17.48497 },
    { lat: 48.143448, lng: 17.484839 },
    { lat: 48.14373, lng: 17.484538 },
    { lat: 48.1434, lng: 17.483845 },
    { lat: 48.143266, lng: 17.48358 },
    { lat: 48.143144, lng: 17.483351 },
    { lat: 48.142745, lng: 17.482421 },
    { lat: 48.142653, lng: 17.481969 },
    { lat: 48.142601, lng: 17.48169 },
    { lat: 48.142401, lng: 17.480834 },
    { lat: 48.141874, lng: 17.478375 },
    { lat: 48.141978, lng: 17.477752 },
    { lat: 48.141656, lng: 17.476933 },
    { lat: 48.141613, lng: 17.47629 },
    { lat: 48.141444, lng: 17.47614 },
    { lat: 48.141439, lng: 17.475873 },
    { lat: 48.141441, lng: 17.475621 },
    { lat: 48.141392, lng: 17.475191 },
    { lat: 48.141411, lng: 17.475074 },
    { lat: 48.141451, lng: 17.474632 },
    { lat: 48.141439, lng: 17.474528 },
    { lat: 48.141397, lng: 17.474248 },
    { lat: 48.141371, lng: 17.473857 },
    { lat: 48.141355, lng: 17.473662 },
    { lat: 48.141353, lng: 17.473457 },
    { lat: 48.141358, lng: 17.473151 },
    { lat: 48.141364, lng: 17.472927 },
    { lat: 48.141359, lng: 17.472701 },
    { lat: 48.141364, lng: 17.472565 },
    { lat: 48.141366, lng: 17.472451 },
    { lat: 48.141447, lng: 17.472065 },
    { lat: 48.141478, lng: 17.471701 },
    { lat: 48.14147, lng: 17.471627 },
    { lat: 48.141418, lng: 17.471237 },
    { lat: 48.141478, lng: 17.470973 },
    { lat: 48.141547, lng: 17.470745 },
    { lat: 48.141543, lng: 17.470589 },
    { lat: 48.141559, lng: 17.470472 },
    { lat: 48.141571, lng: 17.470262 },
    { lat: 48.141606, lng: 17.470099 },
    { lat: 48.1412938, lng: 17.4698468 },
    { lat: 48.1393186, lng: 17.471264 },
    { lat: 48.1387721, lng: 17.4721755 },
    { lat: 48.1382544, lng: 17.4734955 },
    { lat: 48.1380899, lng: 17.4740454 },
    { lat: 48.1378631, lng: 17.4751498 },
    { lat: 48.137787, lng: 17.4758771 },
    { lat: 48.1360799, lng: 17.4777241 },
    { lat: 48.1353746, lng: 17.4782214 },
    { lat: 48.1352251, lng: 17.4782659 },
    { lat: 48.1337014, lng: 17.4783345 },
    { lat: 48.1330013, lng: 17.4779535 },
    { lat: 48.1326422, lng: 17.4776036 },
    { lat: 48.1325492, lng: 17.4771599 },
    { lat: 48.1321257, lng: 17.4764761 },
    { lat: 48.1316494, lng: 17.4752215 },
    { lat: 48.1312283, lng: 17.4733378 },
    { lat: 48.1311527, lng: 17.4728997 },
    { lat: 48.1311072, lng: 17.4721631 },
    { lat: 48.1312731, lng: 17.4708343 },
    { lat: 48.131663, lng: 17.4695616 },
    { lat: 48.1322802, lng: 17.4682578 },
    { lat: 48.1325225, lng: 17.4679209 },
    { lat: 48.1331938, lng: 17.4672647 },
    { lat: 48.1325263, lng: 17.4650817 },
    { lat: 48.1318234, lng: 17.4644121 },
    { lat: 48.1304329, lng: 17.4634639 },
    { lat: 48.12979, lng: 17.4629208 },
    { lat: 48.1295477, lng: 17.4625966 },
    { lat: 48.1293906, lng: 17.462194 },
    { lat: 48.1293705, lng: 17.4620222 },
    { lat: 48.1297517, lng: 17.4617512 },
    { lat: 48.1301187, lng: 17.4613835 },
    { lat: 48.1307147, lng: 17.460595 },
    { lat: 48.1313206, lng: 17.4594157 },
    { lat: 48.1294227, lng: 17.4566178 },
    { lat: 48.1291433, lng: 17.4549213 },
    { lat: 48.129441, lng: 17.4523495 },
    { lat: 48.1297586, lng: 17.4506982 },
    { lat: 48.1298883, lng: 17.4503807 },
    { lat: 48.1305821, lng: 17.4495507 },
    { lat: 48.1313436, lng: 17.4493486 },
    { lat: 48.1313473, lng: 17.44888 },
    { lat: 48.1311999, lng: 17.4479496 },
    { lat: 48.1309535, lng: 17.4477703 },
    { lat: 48.128494, lng: 17.445409 },
    { lat: 48.1281027, lng: 17.4449148 },
    { lat: 48.1270498, lng: 17.4437776 },
    { lat: 48.123221, lng: 17.439218 },
    { lat: 48.1229981, lng: 17.4388649 },
    { lat: 48.1230361, lng: 17.4388078 },
    { lat: 48.1226665, lng: 17.4381067 },
    { lat: 48.1229804, lng: 17.4375501 },
    { lat: 48.1214693, lng: 17.4358637 },
    { lat: 48.1214025, lng: 17.4360343 },
    { lat: 48.1192019, lng: 17.4339245 },
    { lat: 48.1162801, lng: 17.4304869 },
    { lat: 48.1141857, lng: 17.4279098 },
    { lat: 48.1139879, lng: 17.4281831 },
    { lat: 48.1116091, lng: 17.4314123 },
    { lat: 48.1081909, lng: 17.4373818 },
    { lat: 48.1077867, lng: 17.4382032 },
    { lat: 48.1076539, lng: 17.4386105 },
    { lat: 48.1075043, lng: 17.4394887 },
    { lat: 48.1074102, lng: 17.4427015 },
    { lat: 48.1076279, lng: 17.4428354 },
    { lat: 48.1073998, lng: 17.4436709 },
    { lat: 48.107188, lng: 17.4435069 },
    { lat: 48.107137, lng: 17.4436771 },
    { lat: 48.1028856, lng: 17.459226 },
    { lat: 48.1013505, lng: 17.4648397 },
    { lat: 48.100883, lng: 17.4665166 },
    { lat: 48.1005831, lng: 17.4669764 },
    { lat: 48.1003257, lng: 17.4671527 },
    { lat: 48.0981571, lng: 17.4680774 },
    { lat: 48.097982, lng: 17.4682864 },
    { lat: 48.0962713, lng: 17.474315 },
    { lat: 48.0962105, lng: 17.4744223 },
    { lat: 48.0961197, lng: 17.4748512 },
    { lat: 48.0952601, lng: 17.4766298 },
    { lat: 48.0943959, lng: 17.4781846 },
    { lat: 48.0941737, lng: 17.4787815 },
    { lat: 48.0939417, lng: 17.479667 },
    { lat: 48.0936318, lng: 17.479607 },
    { lat: 48.0923676, lng: 17.4810509 },
    { lat: 48.0914171, lng: 17.4822986 },
    { lat: 48.0902023, lng: 17.4833912 },
    { lat: 48.0890271, lng: 17.4849898 },
    { lat: 48.087988, lng: 17.4860898 },
    { lat: 48.0881398, lng: 17.4869192 },
    { lat: 48.0882942, lng: 17.4877214 },
    { lat: 48.0881318, lng: 17.4876865 },
    { lat: 48.0875941, lng: 17.4878963 },
    { lat: 48.0872078, lng: 17.4883859 },
    { lat: 48.0869548, lng: 17.4888917 },
    { lat: 48.0866583, lng: 17.4901798 },
    { lat: 48.0865228, lng: 17.4912808 },
    { lat: 48.0865159, lng: 17.491978 },
    { lat: 48.0866343, lng: 17.4929923 },
    { lat: 48.0868883, lng: 17.4941822 },
  ],
  VeľkáPaka: [
    { lat: 48.161947, lng: 17.376649 },
    { lat: 48.161254, lng: 17.375836 },
    { lat: 48.160364, lng: 17.37418 },
    { lat: 48.15964, lng: 17.375788 },
    { lat: 48.159142, lng: 17.376741 },
    { lat: 48.159086, lng: 17.376954 },
    { lat: 48.159052, lng: 17.37783 },
    { lat: 48.158825, lng: 17.377975 },
    { lat: 48.158332, lng: 17.378693 },
    { lat: 48.158306, lng: 17.380157 },
    { lat: 48.158242, lng: 17.380301 },
    { lat: 48.157952, lng: 17.380528 },
    { lat: 48.157677, lng: 17.38128 },
    { lat: 48.157338, lng: 17.381118 },
    { lat: 48.156735, lng: 17.380261 },
    { lat: 48.156106, lng: 17.379776 },
    { lat: 48.156079, lng: 17.379476 },
    { lat: 48.15616, lng: 17.379072 },
    { lat: 48.15636, lng: 17.378508 },
    { lat: 48.156248, lng: 17.37812 },
    { lat: 48.155738, lng: 17.377128 },
    { lat: 48.155659, lng: 17.376802 },
    { lat: 48.155429, lng: 17.376223 },
    { lat: 48.155154, lng: 17.374927 },
    { lat: 48.155029, lng: 17.374567 },
    { lat: 48.154621, lng: 17.374067 },
    { lat: 48.153776, lng: 17.373741 },
    { lat: 48.153211, lng: 17.373879 },
    { lat: 48.152666, lng: 17.37384 },
    { lat: 48.151902, lng: 17.374265 },
    { lat: 48.151559, lng: 17.374761 },
    { lat: 48.151293, lng: 17.375582 },
    { lat: 48.151013, lng: 17.377262 },
    { lat: 48.151004, lng: 17.377919 },
    { lat: 48.151208, lng: 17.379187 },
    { lat: 48.1515907, lng: 17.379855 },
    { lat: 48.1521486, lng: 17.3805096 },
    { lat: 48.1526469, lng: 17.3809776 },
    { lat: 48.1528535, lng: 17.3812884 },
    { lat: 48.1530017, lng: 17.3818458 },
    { lat: 48.1532206, lng: 17.3837423 },
    { lat: 48.1534053, lng: 17.3848051 },
    { lat: 48.1536321, lng: 17.3851643 },
    { lat: 48.1539875, lng: 17.3852773 },
    { lat: 48.1542432, lng: 17.3852686 },
    { lat: 48.1549224, lng: 17.3855999 },
    { lat: 48.1550236, lng: 17.3855766 },
    { lat: 48.1552829, lng: 17.3856721 },
    { lat: 48.1563782, lng: 17.3869993 },
    { lat: 48.1572142, lng: 17.3875728 },
    { lat: 48.1576364, lng: 17.3879982 },
    { lat: 48.1583546, lng: 17.3881195 },
    { lat: 48.1591132, lng: 17.3881127 },
    { lat: 48.159206, lng: 17.388182 },
    { lat: 48.1594698, lng: 17.3885747 },
    { lat: 48.1597868, lng: 17.3893803 },
    { lat: 48.1598846, lng: 17.3907722 },
    { lat: 48.1598469, lng: 17.3909557 },
    { lat: 48.1596381, lng: 17.3913583 },
    { lat: 48.1605854, lng: 17.3922181 },
    { lat: 48.1610039, lng: 17.3917941 },
    { lat: 48.1614564, lng: 17.3915625 },
    { lat: 48.1618921, lng: 17.3914702 },
    { lat: 48.1624062, lng: 17.3915608 },
    { lat: 48.1631559, lng: 17.3921257 },
    { lat: 48.1634317, lng: 17.3928401 },
    { lat: 48.1636397, lng: 17.3936362 },
    { lat: 48.1640466, lng: 17.3945944 },
    { lat: 48.1645708, lng: 17.395649 },
    { lat: 48.1650672, lng: 17.3963168 },
    { lat: 48.1652535, lng: 17.3964697 },
    { lat: 48.1657582, lng: 17.3965723 },
    { lat: 48.1663555, lng: 17.396568 },
    { lat: 48.1668392, lng: 17.3966605 },
    { lat: 48.1676534, lng: 17.3966065 },
    { lat: 48.1678841, lng: 17.3965223 },
    { lat: 48.168248, lng: 17.396247 },
    { lat: 48.168514, lng: 17.395923 },
    { lat: 48.168743, lng: 17.395291 },
    { lat: 48.168926, lng: 17.394508 },
    { lat: 48.169054, lng: 17.392645 },
    { lat: 48.1689485, lng: 17.3897837 },
    { lat: 48.169104, lng: 17.388444 },
    { lat: 48.169366, lng: 17.387084 },
    { lat: 48.169636, lng: 17.385269 },
    { lat: 48.170038, lng: 17.38122 },
    { lat: 48.170071, lng: 17.380068 },
    { lat: 48.169039, lng: 17.379986 },
    { lat: 48.168101, lng: 17.380168 },
    { lat: 48.167808, lng: 17.381287 },
    { lat: 48.167529, lng: 17.381656 },
    { lat: 48.167154, lng: 17.381829 },
    { lat: 48.16694, lng: 17.381793 },
    { lat: 48.166611, lng: 17.381603 },
    { lat: 48.166027, lng: 17.38122 },
    { lat: 48.165901, lng: 17.38108 },
    { lat: 48.165614, lng: 17.380517 },
    { lat: 48.16543, lng: 17.379987 },
    { lat: 48.165401, lng: 17.379763 },
    { lat: 48.165428, lng: 17.379541 },
    { lat: 48.165697, lng: 17.378659 },
    { lat: 48.165738, lng: 17.378162 },
    { lat: 48.164903, lng: 17.378011 },
    { lat: 48.164448, lng: 17.377719 },
    { lat: 48.164249, lng: 17.377459 },
    { lat: 48.163509, lng: 17.377576 },
    { lat: 48.163216, lng: 17.377752 },
    { lat: 48.161947, lng: 17.376649 },
  ],
  VeľkáPakaExt: [
    { lat: 48.0181647, lng: 17.3817266 },
    { lat: 48.0182296, lng: 17.3819588 },
    { lat: 48.0190736, lng: 17.3829947 },
    { lat: 48.0195966, lng: 17.3840122 },
    { lat: 48.0203633, lng: 17.3851826 },
    { lat: 48.0205069, lng: 17.3854241 },
    { lat: 48.0211656, lng: 17.386532 },
    { lat: 48.0218846, lng: 17.3874564 },
    { lat: 48.0230391, lng: 17.3889407 },
    { lat: 48.0239286, lng: 17.390292 },
    { lat: 48.0248614, lng: 17.3912719 },
    { lat: 48.0256448, lng: 17.3925269 },
    { lat: 48.0265791, lng: 17.3942017 },
    { lat: 48.0273582, lng: 17.3969609 },
    { lat: 48.0274766, lng: 17.3980474 },
    { lat: 48.0279195, lng: 17.3991879 },
    { lat: 48.0285958, lng: 17.3999418 },
    { lat: 48.0289571, lng: 17.4005988 },
    { lat: 48.0301282, lng: 17.4042233 },
    { lat: 48.0319125, lng: 17.4071568 },
    { lat: 48.0323418, lng: 17.4075903 },
    { lat: 48.0330864, lng: 17.4079419 },
    { lat: 48.033484, lng: 17.4085833 },
    { lat: 48.0339053, lng: 17.4095839 },
    { lat: 48.0335757, lng: 17.4099684 },
    { lat: 48.0329176, lng: 17.4104595 },
    { lat: 48.0328627, lng: 17.4110367 },
    { lat: 48.0322936, lng: 17.4139638 },
    { lat: 48.0323448, lng: 17.4143307 },
    { lat: 48.0323567, lng: 17.4151374 },
    { lat: 48.0322407, lng: 17.4160071 },
    { lat: 48.0317164, lng: 17.4169396 },
    { lat: 48.0316234, lng: 17.4175551 },
    { lat: 48.0314134, lng: 17.4195471 },
    { lat: 48.0317202, lng: 17.4199405 },
    { lat: 48.0318117, lng: 17.4203736 },
    { lat: 48.0317329, lng: 17.4210198 },
    { lat: 48.0315123, lng: 17.4219491 },
    { lat: 48.0310924, lng: 17.422751 },
    { lat: 48.0302243, lng: 17.4247846 },
    { lat: 48.0300838, lng: 17.4251561 },
    { lat: 48.0301067, lng: 17.4251806 },
    { lat: 48.0296543, lng: 17.4268163 },
    { lat: 48.0293082, lng: 17.4278329 },
    { lat: 48.0290193, lng: 17.4285278 },
    { lat: 48.0290011, lng: 17.4285467 },
    { lat: 48.030818, lng: 17.4310528 },
    { lat: 48.0315994, lng: 17.431535 },
    { lat: 48.0322127, lng: 17.4315362 },
    { lat: 48.0324217, lng: 17.4316601 },
    { lat: 48.0326714, lng: 17.4320245 },
    { lat: 48.032817, lng: 17.432508 },
    { lat: 48.0327879, lng: 17.4329483 },
    { lat: 48.0326404, lng: 17.4335621 },
    { lat: 48.0324431, lng: 17.4340692 },
    { lat: 48.0322641, lng: 17.4343904 },
    { lat: 48.0321145, lng: 17.434609 },
    { lat: 48.0317573, lng: 17.4349489 },
    { lat: 48.0332386, lng: 17.435844 },
    { lat: 48.0318451, lng: 17.4389909 },
    { lat: 48.033994, lng: 17.4409654 },
    { lat: 48.034454, lng: 17.4413872 },
    { lat: 48.0359693, lng: 17.4397147 },
    { lat: 48.0380366, lng: 17.4432126 },
    { lat: 48.0384171, lng: 17.4428806 },
    { lat: 48.0385513, lng: 17.4426685 },
    { lat: 48.0387478, lng: 17.4420253 },
    { lat: 48.0388816, lng: 17.4418779 },
    { lat: 48.0395809, lng: 17.4414797 },
    { lat: 48.0402986, lng: 17.4408123 },
    { lat: 48.0406154, lng: 17.44068 },
    { lat: 48.0413204, lng: 17.4406497 },
    { lat: 48.0417372, lng: 17.4407003 },
    { lat: 48.0421463, lng: 17.4406016 },
    { lat: 48.0421885, lng: 17.4405913 },
    { lat: 48.0430929, lng: 17.4400832 },
    { lat: 48.0434042, lng: 17.4398152 },
    { lat: 48.0436692, lng: 17.4394973 },
    { lat: 48.0442532, lng: 17.4384395 },
    { lat: 48.0443968, lng: 17.4380926 },
    { lat: 48.044546, lng: 17.4364979 },
    { lat: 48.0447089, lng: 17.4357014 },
    { lat: 48.0447126, lng: 17.4351365 },
    { lat: 48.0448224, lng: 17.4346194 },
    { lat: 48.0448924, lng: 17.4344956 },
    { lat: 48.0450188, lng: 17.434357 },
    { lat: 48.0458547, lng: 17.4334225 },
    { lat: 48.0461091, lng: 17.4330066 },
    { lat: 48.046467, lng: 17.4322079 },
    { lat: 48.0467424, lng: 17.4317191 },
    { lat: 48.0468059, lng: 17.4311321 },
    { lat: 48.0468826, lng: 17.4309576 },
    { lat: 48.0469834, lng: 17.4308773 },
    { lat: 48.0471284, lng: 17.4309153 },
    { lat: 48.047469, lng: 17.4311162 },
    { lat: 48.0482661, lng: 17.4320872 },
    { lat: 48.0484187, lng: 17.4319472 },
    { lat: 48.048927, lng: 17.4311183 },
    { lat: 48.0492686, lng: 17.4301701 },
    { lat: 48.0500769, lng: 17.4294188 },
    { lat: 48.0504171, lng: 17.4297317 },
    { lat: 48.0514781, lng: 17.4313896 },
    { lat: 48.0522428, lng: 17.4322736 },
    { lat: 48.0528359, lng: 17.4328482 },
    { lat: 48.0533806, lng: 17.4336547 },
    { lat: 48.0535423, lng: 17.4333093 },
    { lat: 48.0535905, lng: 17.4330605 },
    { lat: 48.0536181, lng: 17.43227 },
    { lat: 48.0536682, lng: 17.4319998 },
    { lat: 48.0538997, lng: 17.4311999 },
    { lat: 48.0540276, lng: 17.4309625 },
    { lat: 48.0541413, lng: 17.4308331 },
    { lat: 48.0544063, lng: 17.4306895 },
    { lat: 48.0551578, lng: 17.4304356 },
    { lat: 48.0557592, lng: 17.4301314 },
    { lat: 48.0559544, lng: 17.4298503 },
    { lat: 48.0566967, lng: 17.4281171 },
    { lat: 48.0568626, lng: 17.427841 },
    { lat: 48.0570366, lng: 17.4278236 },
    { lat: 48.0575108, lng: 17.427978 },
    { lat: 48.0578156, lng: 17.4279052 },
    { lat: 48.0581019, lng: 17.4276717 },
    { lat: 48.0583289, lng: 17.4272723 },
    { lat: 48.0585007, lng: 17.4265954 },
    { lat: 48.0589947, lng: 17.425113 },
    { lat: 48.0591984, lng: 17.4246333 },
    { lat: 48.0597086, lng: 17.423985 },
    { lat: 48.0598711, lng: 17.4236387 },
    { lat: 48.0598875, lng: 17.4234217 },
    { lat: 48.0598487, lng: 17.4231897 },
    { lat: 48.0596586, lng: 17.4225415 },
    { lat: 48.0611948, lng: 17.4183856 },
    { lat: 48.061729, lng: 17.4164259 },
    { lat: 48.0622179, lng: 17.4150213 },
    { lat: 48.0624326, lng: 17.4144006 },
    { lat: 48.0624292, lng: 17.4140875 },
    { lat: 48.0608518, lng: 17.4127782 },
    { lat: 48.0603137, lng: 17.4118917 },
    { lat: 48.0540362, lng: 17.4051447 },
    { lat: 48.0529261, lng: 17.4041622 },
    { lat: 48.0524004, lng: 17.4038249 },
    { lat: 48.0527101, lng: 17.402914 },
    { lat: 48.0537496, lng: 17.3998063 },
    { lat: 48.0540783, lng: 17.3986179 },
    { lat: 48.054335, lng: 17.3969802 },
    { lat: 48.0543591, lng: 17.3962269 },
    { lat: 48.0553227, lng: 17.3937595 },
    { lat: 48.0559983, lng: 17.3916562 },
    { lat: 48.0562672, lng: 17.3900004 },
    { lat: 48.0561835, lng: 17.3878065 },
    { lat: 48.0555787, lng: 17.3851779 },
    { lat: 48.0552478, lng: 17.3830899 },
    { lat: 48.0552454, lng: 17.3827093 },
    { lat: 48.0553382, lng: 17.3820747 },
    { lat: 48.0554062, lng: 17.3806802 },
    { lat: 48.0554049, lng: 17.379491 },
    { lat: 48.0553201, lng: 17.3788502 },
    { lat: 48.0552495, lng: 17.3773622 },
    { lat: 48.0540016, lng: 17.376812 },
    { lat: 48.0514318, lng: 17.3751729 },
    { lat: 48.0505516, lng: 17.3737749 },
    { lat: 48.0500828, lng: 17.3727762 },
    { lat: 48.0492343, lng: 17.3712409 },
    { lat: 48.0483377, lng: 17.3701074 },
    { lat: 48.0481983, lng: 17.3698086 },
    { lat: 48.0476777, lng: 17.3694137 },
    { lat: 48.047311, lng: 17.3691464 },
    { lat: 48.0467675, lng: 17.3696755 },
    { lat: 48.0461751, lng: 17.3686875 },
    { lat: 48.0455903, lng: 17.3673846 },
    { lat: 48.0447163, lng: 17.3649274 },
    { lat: 48.0444193, lng: 17.363333 },
    { lat: 48.0443306, lng: 17.3622213 },
    { lat: 48.0440642, lng: 17.3614848 },
    { lat: 48.0442392, lng: 17.3602976 },
    { lat: 48.0441741, lng: 17.3602171 },
    { lat: 48.0428885, lng: 17.3585495 },
    { lat: 48.0420817, lng: 17.3593352 },
    { lat: 48.0418851, lng: 17.3597289 },
    { lat: 48.0402807, lng: 17.3600845 },
    { lat: 48.0387443, lng: 17.3605946 },
    { lat: 48.0373308, lng: 17.3609487 },
    { lat: 48.0372352, lng: 17.3613852 },
    { lat: 48.0367249, lng: 17.3626449 },
    { lat: 48.0363378, lng: 17.3632167 },
    { lat: 48.0355638, lng: 17.3660151 },
    { lat: 48.0353286, lng: 17.3665237 },
    { lat: 48.0352271, lng: 17.3670252 },
    { lat: 48.0350176, lng: 17.370803 },
    { lat: 48.0350315, lng: 17.3729583 },
    { lat: 48.0345635, lng: 17.373227 },
    { lat: 48.0337092, lng: 17.3734289 },
    { lat: 48.0330221, lng: 17.3734568 },
    { lat: 48.03238, lng: 17.3733275 },
    { lat: 48.0308723, lng: 17.372643 },
    { lat: 48.0291217, lng: 17.3714594 },
    { lat: 48.0288845, lng: 17.3714587 },
    { lat: 48.0279296, lng: 17.3697308 },
    { lat: 48.0270687, lng: 17.3677523 },
    { lat: 48.0269655, lng: 17.3673482 },
    { lat: 48.0261565, lng: 17.368293 },
    { lat: 48.0262571, lng: 17.3688087 },
    { lat: 48.0262282, lng: 17.3690923 },
    { lat: 48.0261135, lng: 17.3694079 },
    { lat: 48.0258081, lng: 17.369727 },
    { lat: 48.0245932, lng: 17.3697706 },
    { lat: 48.0239807, lng: 17.3700241 },
    { lat: 48.0232815, lng: 17.3706758 },
    { lat: 48.0229438, lng: 17.3708817 },
    { lat: 48.0222722, lng: 17.370978 },
    { lat: 48.0219986, lng: 17.3711618 },
    { lat: 48.0217, lng: 17.3713613 },
    { lat: 48.0208319, lng: 17.3726798 },
    { lat: 48.0204474, lng: 17.3735459 },
    { lat: 48.0204864, lng: 17.3744508 },
    { lat: 48.0206617, lng: 17.3754522 },
    { lat: 48.020922, lng: 17.3765292 },
    { lat: 48.0209114, lng: 17.3768144 },
    { lat: 48.0207908, lng: 17.3772638 },
    { lat: 48.020501, lng: 17.3779121 },
    { lat: 48.0200365, lng: 17.3786279 },
    { lat: 48.0192925, lng: 17.3802365 },
    { lat: 48.018526, lng: 17.3813102 },
    { lat: 48.0181656, lng: 17.3816188 },
    { lat: 48.0181647, lng: 17.3817266 },
  ],
  PotônskeLúky: [
    { lat: 48.074655, lng: 17.5703852 },
    { lat: 48.0744823, lng: 17.5706346 },
    { lat: 48.0730112, lng: 17.5727274 },
    { lat: 48.0777731, lng: 17.5806777 },
    { lat: 48.076716, lng: 17.5826964 },
    { lat: 48.0779847, lng: 17.5847265 },
    { lat: 48.0800604, lng: 17.5877585 },
    { lat: 48.0806318, lng: 17.5885412 },
    { lat: 48.0818124, lng: 17.5899665 },
    { lat: 48.0826767, lng: 17.5911658 },
    { lat: 48.083943, lng: 17.5927863 },
    { lat: 48.0844332, lng: 17.593551 },
    { lat: 48.085293, lng: 17.5951374 },
    { lat: 48.0871219, lng: 17.5977238 },
    { lat: 48.0888945, lng: 17.6005656 },
    { lat: 48.0915714, lng: 17.6051254 },
    { lat: 48.0915809, lng: 17.6051855 },
    { lat: 48.0896686, lng: 17.6065286 },
    { lat: 48.0897032, lng: 17.6065785 },
    { lat: 48.0896654, lng: 17.606605 },
    { lat: 48.0906092, lng: 17.6079002 },
    { lat: 48.0908225, lng: 17.6090175 },
    { lat: 48.0909947, lng: 17.6103106 },
    { lat: 48.0919634, lng: 17.6100034 },
    { lat: 48.0923454, lng: 17.6097961 },
    { lat: 48.0927545, lng: 17.6111539 },
    { lat: 48.0942719, lng: 17.6112014 },
    { lat: 48.0947101, lng: 17.6111144 },
    { lat: 48.0955374, lng: 17.6108255 },
    { lat: 48.0959977, lng: 17.6105311 },
    { lat: 48.0968405, lng: 17.609666 },
    { lat: 48.097515, lng: 17.6105441 },
    { lat: 48.098435, lng: 17.6094179 },
    { lat: 48.0990986, lng: 17.6102139 },
    { lat: 48.0992391, lng: 17.6104522 },
    { lat: 48.1000813, lng: 17.6095956 },
    { lat: 48.1002082, lng: 17.6088199 },
    { lat: 48.0990409, lng: 17.6082718 },
    { lat: 48.0997422, lng: 17.6060485 },
    { lat: 48.0998677, lng: 17.6051112 },
    { lat: 48.0994503, lng: 17.6031246 },
    { lat: 48.0988766, lng: 17.6017882 },
    { lat: 48.0979091, lng: 17.5993207 },
    { lat: 48.0977194, lng: 17.5990194 },
    { lat: 48.098403, lng: 17.596415 },
    { lat: 48.098241, lng: 17.596342 },
    { lat: 48.098156, lng: 17.59631 },
    { lat: 48.098114, lng: 17.596295 },
    { lat: 48.098055, lng: 17.596276 },
    { lat: 48.097986, lng: 17.596252 },
    { lat: 48.097901, lng: 17.596223 },
    { lat: 48.097719, lng: 17.596174 },
    { lat: 48.097648, lng: 17.596158 },
    { lat: 48.097242, lng: 17.596064 },
    { lat: 48.096699, lng: 17.596052 },
    { lat: 48.096496, lng: 17.596045 },
    { lat: 48.096481, lng: 17.596044 },
    { lat: 48.096474, lng: 17.596045 },
    { lat: 48.09622, lng: 17.596024 },
    { lat: 48.095921, lng: 17.596002 },
    { lat: 48.095907, lng: 17.596 },
    { lat: 48.095647, lng: 17.595911 },
    { lat: 48.095592, lng: 17.595895 },
    { lat: 48.095362, lng: 17.595827 },
    { lat: 48.094997, lng: 17.595698 },
    { lat: 48.09465, lng: 17.595577 },
    { lat: 48.094182, lng: 17.595408 },
    { lat: 48.094168, lng: 17.595402 },
    { lat: 48.094137, lng: 17.59539 },
    { lat: 48.093914, lng: 17.595297 },
    { lat: 48.093786, lng: 17.595245 },
    { lat: 48.093475, lng: 17.595116 },
    { lat: 48.093031, lng: 17.594947 },
    { lat: 48.0926, lng: 17.594788 },
    { lat: 48.09257, lng: 17.594777 },
    { lat: 48.092517, lng: 17.594757 },
    { lat: 48.092159, lng: 17.594619 },
    { lat: 48.091812, lng: 17.594263 },
    { lat: 48.091698, lng: 17.594042 },
    { lat: 48.091364, lng: 17.593395 },
    { lat: 48.091722, lng: 17.593036 },
    { lat: 48.092203, lng: 17.592426 },
    { lat: 48.092766, lng: 17.591955 },
    { lat: 48.093174, lng: 17.591682 },
    { lat: 48.093405, lng: 17.59159 },
    { lat: 48.093743, lng: 17.591516 },
    { lat: 48.094415, lng: 17.591461 },
    { lat: 48.095364, lng: 17.591249 },
    { lat: 48.095613, lng: 17.591217 },
    { lat: 48.096168, lng: 17.591058 },
    { lat: 48.096631, lng: 17.590946 },
    { lat: 48.097136, lng: 17.590793 },
    { lat: 48.0962816, lng: 17.5893028 },
    { lat: 48.0947695, lng: 17.5859295 },
    { lat: 48.0945678, lng: 17.5853849 },
    { lat: 48.0939562, lng: 17.5822538 },
    { lat: 48.0913863, lng: 17.5779714 },
    { lat: 48.0901901, lng: 17.5764046 },
    { lat: 48.0893219, lng: 17.5748863 },
    { lat: 48.0884895, lng: 17.5731849 },
    { lat: 48.0858566, lng: 17.5671909 },
    { lat: 48.0866185, lng: 17.5658775 },
    { lat: 48.0868067, lng: 17.5656777 },
    { lat: 48.0873163, lng: 17.5653474 },
    { lat: 48.0867209, lng: 17.5643351 },
    { lat: 48.08723, lng: 17.5630767 },
    { lat: 48.0875804, lng: 17.5619687 },
    { lat: 48.0875643, lng: 17.5617492 },
    { lat: 48.0873433, lng: 17.5609422 },
    { lat: 48.0865878, lng: 17.5596878 },
    { lat: 48.0849762, lng: 17.5563107 },
    { lat: 48.0847947, lng: 17.5559251 },
    { lat: 48.0831325, lng: 17.5582931 },
    { lat: 48.074655, lng: 17.5703852 },
  ],
  VeľkéDvorníky: [
    { lat: 47.9914412, lng: 17.6748613 },
    { lat: 47.9917108, lng: 17.6749273 },
    { lat: 47.9926658, lng: 17.6753574 },
    { lat: 47.9948936, lng: 17.6765059 },
    { lat: 47.995165, lng: 17.6766625 },
    { lat: 48.0009735, lng: 17.6800141 },
    { lat: 48.0018787, lng: 17.680458 },
    { lat: 48.0043259, lng: 17.6820971 },
    { lat: 48.0171483, lng: 17.6968928 },
    { lat: 48.0172792, lng: 17.6960642 },
    { lat: 48.0177638, lng: 17.69472 },
    { lat: 48.0175409, lng: 17.693039 },
    { lat: 48.0175166, lng: 17.6926703 },
    { lat: 48.0175919, lng: 17.6921439 },
    { lat: 48.0186767, lng: 17.6902155 },
    { lat: 48.0190486, lng: 17.6887127 },
    { lat: 48.0191558, lng: 17.6880658 },
    { lat: 48.0192198, lng: 17.686911 },
    { lat: 48.0191969, lng: 17.6864191 },
    { lat: 48.0188969, lng: 17.683676 },
    { lat: 48.0188448, lng: 17.6834939 },
    { lat: 48.018934, lng: 17.6832794 },
    { lat: 48.0186508, lng: 17.6820272 },
    { lat: 48.019222, lng: 17.6808587 },
    { lat: 48.0199187, lng: 17.67892 },
    { lat: 48.0202493, lng: 17.6787747 },
    { lat: 48.0205111, lng: 17.6784831 },
    { lat: 48.0207865, lng: 17.6778891 },
    { lat: 48.0210525, lng: 17.6774495 },
    { lat: 48.0213225, lng: 17.6772217 },
    { lat: 48.0216428, lng: 17.6766595 },
    { lat: 48.0220185, lng: 17.6765809 },
    { lat: 48.0221165, lng: 17.6765248 },
    { lat: 48.0222987, lng: 17.676262 },
    { lat: 48.022339, lng: 17.6763121 },
    { lat: 48.0256978, lng: 17.6713025 },
    { lat: 48.0257153, lng: 17.6712757 },
    { lat: 48.0219062, lng: 17.6654259 },
    { lat: 48.0187338, lng: 17.6601514 },
    { lat: 48.0171296, lng: 17.6584384 },
    { lat: 48.0157393, lng: 17.6571794 },
    { lat: 48.0155224, lng: 17.6576269 },
    { lat: 48.014204, lng: 17.6563251 },
    { lat: 48.0138607, lng: 17.6571459 },
    { lat: 48.0105766, lng: 17.6548499 },
    { lat: 48.0090868, lng: 17.6536798 },
    { lat: 48.0086432, lng: 17.653466 },
    { lat: 48.0059958, lng: 17.6498306 },
    { lat: 48.0061051, lng: 17.6495575 },
    { lat: 48.0048503, lng: 17.6480881 },
    { lat: 48.0039492, lng: 17.6472119 },
    { lat: 48.0034131, lng: 17.6467671 },
    { lat: 48.0022707, lng: 17.6464152 },
    { lat: 48.0021209, lng: 17.6459056 },
    { lat: 48.0009042, lng: 17.6455381 },
    { lat: 48.0008932, lng: 17.645203 },
    { lat: 48.0002421, lng: 17.6450505 },
    { lat: 47.9989707, lng: 17.6444248 },
    { lat: 47.9988529, lng: 17.6443465 },
    { lat: 47.9986836, lng: 17.6445998 },
    { lat: 47.9981226, lng: 17.6451379 },
    { lat: 47.9977102, lng: 17.6453816 },
    { lat: 47.996536, lng: 17.6452966 },
    { lat: 47.9955463, lng: 17.6451066 },
    { lat: 47.9938937, lng: 17.6441849 },
    { lat: 47.9933892, lng: 17.6440834 },
    { lat: 47.9932326, lng: 17.6441582 },
    { lat: 47.9928737, lng: 17.644503 },
    { lat: 47.9927071, lng: 17.6447424 },
    { lat: 47.9924432, lng: 17.645317 },
    { lat: 47.9922346, lng: 17.6459847 },
    { lat: 47.9923392, lng: 17.6460666 },
    { lat: 47.9928217, lng: 17.6464465 },
    { lat: 47.9930088, lng: 17.6466917 },
    { lat: 47.9935584, lng: 17.6484542 },
    { lat: 47.9935865, lng: 17.6490961 },
    { lat: 47.9934485, lng: 17.6502526 },
    { lat: 47.9934414, lng: 17.6513379 },
    { lat: 47.9935638, lng: 17.6529186 },
    { lat: 47.9935594, lng: 17.6546697 },
    { lat: 47.9934028, lng: 17.6576068 },
    { lat: 47.9932711, lng: 17.6589013 },
    { lat: 47.9932406, lng: 17.6605471 },
    { lat: 47.9931755, lng: 17.6613941 },
    { lat: 47.9925606, lng: 17.6652127 },
    { lat: 47.9924513, lng: 17.6663074 },
    { lat: 47.9924661, lng: 17.6670074 },
    { lat: 47.9923422, lng: 17.6683216 },
    { lat: 47.9923669, lng: 17.6690372 },
    { lat: 47.9923226, lng: 17.6695506 },
    { lat: 47.9918191, lng: 17.6725791 },
    { lat: 47.991581, lng: 17.6733709 },
    { lat: 47.9913954, lng: 17.6741867 },
    { lat: 47.9913848, lng: 17.674485 },
    { lat: 47.9914412, lng: 17.6748613 },
  ],
  Baloň: [
    { lat: 47.8189952, lng: 17.6820761 },
    { lat: 47.8189971, lng: 17.6821404 },
    { lat: 47.8191162, lng: 17.6828465 },
    { lat: 47.8194246, lng: 17.6834397 },
    { lat: 47.819779, lng: 17.6835271 },
    { lat: 47.8205286, lng: 17.6832046 },
    { lat: 47.8217552, lng: 17.6824606 },
    { lat: 47.8223357, lng: 17.682462 },
    { lat: 47.8229978, lng: 17.6827756 },
    { lat: 47.8235601, lng: 17.6839354 },
    { lat: 47.8242298, lng: 17.6846731 },
    { lat: 47.8285295, lng: 17.6812061 },
    { lat: 47.8287451, lng: 17.6816339 },
    { lat: 47.828976, lng: 17.6819629 },
    { lat: 47.830799, lng: 17.6836206 },
    { lat: 47.8314416, lng: 17.6844837 },
    { lat: 47.8318199, lng: 17.6851092 },
    { lat: 47.8319332, lng: 17.6853869 },
    { lat: 47.8324146, lng: 17.6873062 },
    { lat: 47.8328168, lng: 17.6871329 },
    { lat: 47.833563, lng: 17.6870945 },
    { lat: 47.8346947, lng: 17.6874929 },
    { lat: 47.8353474, lng: 17.6878196 },
    { lat: 47.8362515, lng: 17.6878129 },
    { lat: 47.8373974, lng: 17.6875429 },
    { lat: 47.8376792, lng: 17.687555 },
    { lat: 47.8377905, lng: 17.6870791 },
    { lat: 47.8381124, lng: 17.6861143 },
    { lat: 47.8383619, lng: 17.6856846 },
    { lat: 47.838675, lng: 17.685439 },
    { lat: 47.8390228, lng: 17.685352 },
    { lat: 47.8397107, lng: 17.6854435 },
    { lat: 47.8400615, lng: 17.6853677 },
    { lat: 47.8406372, lng: 17.6848665 },
    { lat: 47.8410795, lng: 17.6845895 },
    { lat: 47.8411689, lng: 17.6843993 },
    { lat: 47.8411898, lng: 17.6837441 },
    { lat: 47.8413684, lng: 17.6830343 },
    { lat: 47.8413367, lng: 17.6826184 },
    { lat: 47.841212, lng: 17.6821462 },
    { lat: 47.8412341, lng: 17.6819779 },
    { lat: 47.8413429, lng: 17.6818426 },
    { lat: 47.8415888, lng: 17.6817121 },
    { lat: 47.8416856, lng: 17.6815299 },
    { lat: 47.8417333, lng: 17.6813363 },
    { lat: 47.8416842, lng: 17.6809709 },
    { lat: 47.8418019, lng: 17.6808498 },
    { lat: 47.8423304, lng: 17.6806905 },
    { lat: 47.8427313, lng: 17.6807447 },
    { lat: 47.8429614, lng: 17.6806153 },
    { lat: 47.8430059, lng: 17.6802819 },
    { lat: 47.842871, lng: 17.6796828 },
    { lat: 47.8428754, lng: 17.6794147 },
    { lat: 47.842967, lng: 17.6792392 },
    { lat: 47.8432594, lng: 17.6791103 },
    { lat: 47.8435941, lng: 17.6791023 },
    { lat: 47.8440577, lng: 17.6783802 },
    { lat: 47.844216, lng: 17.6783089 },
    { lat: 47.845104, lng: 17.6782657 },
    { lat: 47.8452936, lng: 17.6781208 },
    { lat: 47.8457823, lng: 17.6774036 },
    { lat: 47.8460744, lng: 17.677165 },
    { lat: 47.8464899, lng: 17.6765209 },
    { lat: 47.8467412, lng: 17.6760121 },
    { lat: 47.8471994, lng: 17.6754192 },
    { lat: 47.8475789, lng: 17.6750633 },
    { lat: 47.847709, lng: 17.6748716 },
    { lat: 47.8481593, lng: 17.6736693 },
    { lat: 47.8483844, lng: 17.6734608 },
    { lat: 47.8491145, lng: 17.6731688 },
    { lat: 47.8494087, lng: 17.6727503 },
    { lat: 47.849701, lng: 17.6720336 },
    { lat: 47.8503731, lng: 17.6707177 },
    { lat: 47.8521603, lng: 17.6695183 },
    { lat: 47.8525201, lng: 17.6684926 },
    { lat: 47.8525705, lng: 17.6683077 },
    { lat: 47.8531535, lng: 17.6637767 },
    { lat: 47.8533683, lng: 17.6638374 },
    { lat: 47.8536424, lng: 17.6640474 },
    { lat: 47.8539676, lng: 17.6641169 },
    { lat: 47.8544032, lng: 17.6640234 },
    { lat: 47.8554082, lng: 17.6634718 },
    { lat: 47.8559654, lng: 17.6633757 },
    { lat: 47.8563569, lng: 17.6630668 },
    { lat: 47.8566849, lng: 17.6627015 },
    { lat: 47.8571509, lng: 17.6624265 },
    { lat: 47.8582708, lng: 17.6615487 },
    { lat: 47.8585541, lng: 17.661405 },
    { lat: 47.8593747, lng: 17.6611705 },
    { lat: 47.8598866, lng: 17.6612847 },
    { lat: 47.8602129, lng: 17.6612851 },
    { lat: 47.8613408, lng: 17.6611578 },
    { lat: 47.8616319, lng: 17.6609568 },
    { lat: 47.8623583, lng: 17.6597489 },
    { lat: 47.8626719, lng: 17.6593552 },
    { lat: 47.8632215, lng: 17.6585098 },
    { lat: 47.863656, lng: 17.657382 },
    { lat: 47.8637555, lng: 17.6563857 },
    { lat: 47.8640394, lng: 17.6556263 },
    { lat: 47.8641181, lng: 17.6551772 },
    { lat: 47.8638682, lng: 17.6545293 },
    { lat: 47.8638033, lng: 17.6541808 },
    { lat: 47.8639188, lng: 17.6532955 },
    { lat: 47.8637998, lng: 17.6527889 },
    { lat: 47.8638126, lng: 17.6525277 },
    { lat: 47.8639862, lng: 17.652182 },
    { lat: 47.8639931, lng: 17.6520074 },
    { lat: 47.8638547, lng: 17.6517658 },
    { lat: 47.8635609, lng: 17.6516543 },
    { lat: 47.8626199, lng: 17.6515233 },
    { lat: 47.8621983, lng: 17.6515531 },
    { lat: 47.8619124, lng: 17.651354 },
    { lat: 47.8615689, lng: 17.6508315 },
    { lat: 47.8612861, lng: 17.6500399 },
    { lat: 47.8611157, lng: 17.6492407 },
    { lat: 47.8610045, lng: 17.6489113 },
    { lat: 47.8608499, lng: 17.6486636 },
    { lat: 47.8602017, lng: 17.6481992 },
    { lat: 47.8597061, lng: 17.6477373 },
    { lat: 47.8596474, lng: 17.6475585 },
    { lat: 47.8595323, lng: 17.646579 },
    { lat: 47.8598033, lng: 17.6457247 },
    { lat: 47.8598681, lng: 17.6451277 },
    { lat: 47.8600253, lng: 17.6444019 },
    { lat: 47.8603824, lng: 17.6434441 },
    { lat: 47.8607068, lng: 17.6429507 },
    { lat: 47.8609349, lng: 17.6427904 },
    { lat: 47.8615201, lng: 17.6427581 },
    { lat: 47.8617037, lng: 17.6428076 },
    { lat: 47.8623733, lng: 17.6431283 },
    { lat: 47.8631871, lng: 17.6438533 },
    { lat: 47.8635381, lng: 17.643795 },
    { lat: 47.8638789, lng: 17.6435988 },
    { lat: 47.864252, lng: 17.6432846 },
    { lat: 47.864367, lng: 17.6431202 },
    { lat: 47.8644398, lng: 17.6429327 },
    { lat: 47.8644649, lng: 17.6426012 },
    { lat: 47.8644347, lng: 17.6421775 },
    { lat: 47.86422, lng: 17.6416759 },
    { lat: 47.8634769, lng: 17.6408571 },
    { lat: 47.8630929, lng: 17.6401082 },
    { lat: 47.8630242, lng: 17.6397897 },
    { lat: 47.8631486, lng: 17.6393117 },
    { lat: 47.8633803, lng: 17.6389586 },
    { lat: 47.8634831, lng: 17.6386203 },
    { lat: 47.8641088, lng: 17.6382675 },
    { lat: 47.8643036, lng: 17.6382764 },
    { lat: 47.8645744, lng: 17.6384347 },
    { lat: 47.8653881, lng: 17.6386772 },
    { lat: 47.8657637, lng: 17.6384216 },
    { lat: 47.8664171, lng: 17.6376335 },
    { lat: 47.8666468, lng: 17.6370545 },
    { lat: 47.8668441, lng: 17.636749 },
    { lat: 47.8673684, lng: 17.6364505 },
    { lat: 47.8677126, lng: 17.6359862 },
    { lat: 47.8680585, lng: 17.6357774 },
    { lat: 47.8681896, lng: 17.6355835 },
    { lat: 47.868442, lng: 17.6349524 },
    { lat: 47.868593, lng: 17.6347526 },
    { lat: 47.8688183, lng: 17.634638 },
    { lat: 47.8689111, lng: 17.634515 },
    { lat: 47.8691278, lng: 17.6337327 },
    { lat: 47.8693575, lng: 17.6331544 },
    { lat: 47.8695001, lng: 17.632449 },
    { lat: 47.8695895, lng: 17.6319716 },
    { lat: 47.8695393, lng: 17.6315018 },
    { lat: 47.8694916, lng: 17.6313921 },
    { lat: 47.8695101, lng: 17.6312351 },
    { lat: 47.8698582, lng: 17.6302649 },
    { lat: 47.8697871, lng: 17.6296968 },
    { lat: 47.8699302, lng: 17.6295139 },
    { lat: 47.8700709, lng: 17.6294892 },
    { lat: 47.8700301, lng: 17.6288989 },
    { lat: 47.8696042, lng: 17.6268705 },
    { lat: 47.8693104, lng: 17.6244893 },
    { lat: 47.8690976, lng: 17.6232008 },
    { lat: 47.8688351, lng: 17.6208338 },
    { lat: 47.8686196, lng: 17.6199638 },
    { lat: 47.8680138, lng: 17.6179848 },
    { lat: 47.8671479, lng: 17.6185925 },
    { lat: 47.8667083, lng: 17.618802 },
    { lat: 47.8659522, lng: 17.6197673 },
    { lat: 47.8656138, lng: 17.6200715 },
    { lat: 47.8653072, lng: 17.6207556 },
    { lat: 47.8651522, lng: 17.6209645 },
    { lat: 47.8648291, lng: 17.6211927 },
    { lat: 47.864276, lng: 17.6211758 },
    { lat: 47.8637981, lng: 17.621404 },
    { lat: 47.8634369, lng: 17.6212635 },
    { lat: 47.8631946, lng: 17.6212898 },
    { lat: 47.8628583, lng: 17.6214858 },
    { lat: 47.8625134, lng: 17.6217818 },
    { lat: 47.8623104, lng: 17.6222273 },
    { lat: 47.8620426, lng: 17.6224217 },
    { lat: 47.8617745, lng: 17.6223585 },
    { lat: 47.8614808, lng: 17.6221448 },
    { lat: 47.8613494, lng: 17.6221214 },
    { lat: 47.8611366, lng: 17.6223953 },
    { lat: 47.8609283, lng: 17.6225849 },
    { lat: 47.8608465, lng: 17.6226115 },
    { lat: 47.8604583, lng: 17.6224899 },
    { lat: 47.8601855, lng: 17.6221264 },
    { lat: 47.8600321, lng: 17.6221286 },
    { lat: 47.8599538, lng: 17.6222443 },
    { lat: 47.8599688, lng: 17.6226065 },
    { lat: 47.8597654, lng: 17.6229051 },
    { lat: 47.8593365, lng: 17.6239888 },
    { lat: 47.8591381, lng: 17.6243138 },
    { lat: 47.8589313, lng: 17.624418 },
    { lat: 47.8584878, lng: 17.6244068 },
    { lat: 47.8581786, lng: 17.6249055 },
    { lat: 47.8578627, lng: 17.6249274 },
    { lat: 47.8574812, lng: 17.6250906 },
    { lat: 47.8573045, lng: 17.6249376 },
    { lat: 47.8569622, lng: 17.6248137 },
    { lat: 47.8567315, lng: 17.624504 },
    { lat: 47.8566655, lng: 17.623344 },
    { lat: 47.8564555, lng: 17.6227575 },
    { lat: 47.8563099, lng: 17.6225818 },
    { lat: 47.8550405, lng: 17.6227717 },
    { lat: 47.8548942, lng: 17.6226934 },
    { lat: 47.8543668, lng: 17.6220862 },
    { lat: 47.8542172, lng: 17.622078 },
    { lat: 47.8536792, lng: 17.6222288 },
    { lat: 47.8531452, lng: 17.6224961 },
    { lat: 47.852696, lng: 17.6225356 },
    { lat: 47.8522923, lng: 17.6224204 },
    { lat: 47.8520724, lng: 17.6225294 },
    { lat: 47.8515358, lng: 17.6230961 },
    { lat: 47.8506066, lng: 17.6234523 },
    { lat: 47.8501502, lng: 17.6238922 },
    { lat: 47.8499797, lng: 17.6241576 },
    { lat: 47.8494899, lng: 17.6256901 },
    { lat: 47.8488023, lng: 17.627271 },
    { lat: 47.8486022, lng: 17.6276035 },
    { lat: 47.8482275, lng: 17.6279623 },
    { lat: 47.8477999, lng: 17.6282522 },
    { lat: 47.847199, lng: 17.6290275 },
    { lat: 47.846572, lng: 17.629467 },
    { lat: 47.8461558, lng: 17.6298473 },
    { lat: 47.8458796, lng: 17.6299799 },
    { lat: 47.845216, lng: 17.6306769 },
    { lat: 47.8441891, lng: 17.631184 },
    { lat: 47.8440419, lng: 17.6313206 },
    { lat: 47.843855, lng: 17.6316487 },
    { lat: 47.8435816, lng: 17.6318589 },
    { lat: 47.8435412, lng: 17.631794 },
    { lat: 47.8435377, lng: 17.6314367 },
    { lat: 47.8434655, lng: 17.631347 },
    { lat: 47.8433105, lng: 17.63131 },
    { lat: 47.8430339, lng: 17.63154 },
    { lat: 47.8424116, lng: 17.6315728 },
    { lat: 47.841864, lng: 17.6317325 },
    { lat: 47.8409064, lng: 17.6317645 },
    { lat: 47.8397973, lng: 17.6321355 },
    { lat: 47.8391396, lng: 17.6327141 },
    { lat: 47.8384375, lng: 17.6334086 },
    { lat: 47.8377252, lng: 17.6344091 },
    { lat: 47.8367051, lng: 17.6355375 },
    { lat: 47.8362798, lng: 17.6358477 },
    { lat: 47.8359798, lng: 17.6364214 },
    { lat: 47.8352308, lng: 17.6370538 },
    { lat: 47.8347901, lng: 17.6373341 },
    { lat: 47.8346861, lng: 17.6374921 },
    { lat: 47.8344676, lng: 17.6380864 },
    { lat: 47.8341424, lng: 17.6399178 },
    { lat: 47.8339765, lng: 17.6406304 },
    { lat: 47.8338612, lng: 17.6409298 },
    { lat: 47.8334242, lng: 17.6415529 },
    { lat: 47.8325973, lng: 17.6424568 },
    { lat: 47.8324252, lng: 17.64291 },
    { lat: 47.8322907, lng: 17.643568 },
    { lat: 47.832212, lng: 17.6449076 },
    { lat: 47.8321569, lng: 17.645214 },
    { lat: 47.832028, lng: 17.6455138 },
    { lat: 47.8308283, lng: 17.647142 },
    { lat: 47.830688, lng: 17.6478131 },
    { lat: 47.8307006, lng: 17.6481729 },
    { lat: 47.8308132, lng: 17.6485233 },
    { lat: 47.8308704, lng: 17.6489429 },
    { lat: 47.8308423, lng: 17.6491422 },
    { lat: 47.8307836, lng: 17.6493029 },
    { lat: 47.8303221, lng: 17.6499645 },
    { lat: 47.8294222, lng: 17.6514814 },
    { lat: 47.8292035, lng: 17.6522354 },
    { lat: 47.8291502, lng: 17.6527123 },
    { lat: 47.8279479, lng: 17.6534077 },
    { lat: 47.8264486, lng: 17.6540884 },
    { lat: 47.8253234, lng: 17.6548812 },
    { lat: 47.8239771, lng: 17.6555344 },
    { lat: 47.8226852, lng: 17.6564418 },
    { lat: 47.8221791, lng: 17.6569014 },
    { lat: 47.8210032, lng: 17.657177 },
    { lat: 47.8203004, lng: 17.657168 },
    { lat: 47.8189109, lng: 17.6575321 },
    { lat: 47.8184272, lng: 17.6580756 },
    { lat: 47.8144202, lng: 17.6647484 },
    { lat: 47.8143537, lng: 17.6649639 },
    { lat: 47.8156218, lng: 17.6664281 },
    { lat: 47.8162289, lng: 17.6675504 },
    { lat: 47.8168695, lng: 17.6693924 },
    { lat: 47.8174194, lng: 17.6713054 },
    { lat: 47.8176572, lng: 17.6725144 },
    { lat: 47.8177215, lng: 17.6734939 },
    { lat: 47.8177361, lng: 17.6750842 },
    { lat: 47.8178749, lng: 17.6766372 },
    { lat: 47.817937, lng: 17.6778893 },
    { lat: 47.8180074, lng: 17.6783934 },
    { lat: 47.818131, lng: 17.6788673 },
    { lat: 47.8183037, lng: 17.6792078 },
    { lat: 47.8186993, lng: 17.6794805 },
    { lat: 47.8190392, lng: 17.6794985 },
    { lat: 47.8191152, lng: 17.6798284 },
    { lat: 47.8191448, lng: 17.680778 },
    { lat: 47.819058, lng: 17.6816209 },
    { lat: 47.8189952, lng: 17.6820761 },
  ],
  VeľkýMeder: [
    { lat: 47.829593, lng: 17.79979 },
    { lat: 47.829774, lng: 17.799847 },
    { lat: 47.829971, lng: 17.79991 },
    { lat: 47.830011, lng: 17.799922 },
    { lat: 47.830184, lng: 17.799978 },
    { lat: 47.830317, lng: 17.80002 },
    { lat: 47.830453, lng: 17.800063 },
    { lat: 47.830778, lng: 17.800166 },
    { lat: 47.830904, lng: 17.800206 },
    { lat: 47.83091, lng: 17.800206 },
    { lat: 47.831035, lng: 17.800197 },
    { lat: 47.831133, lng: 17.80019 },
    { lat: 47.831238, lng: 17.800183 },
    { lat: 47.831334, lng: 17.800176 },
    { lat: 47.831382, lng: 17.800172 },
    { lat: 47.83143, lng: 17.800169 },
    { lat: 47.831501, lng: 17.800143 },
    { lat: 47.831609, lng: 17.800106 },
    { lat: 47.831731, lng: 17.800063 },
    { lat: 47.831887, lng: 17.800008 },
    { lat: 47.832081, lng: 17.799939 },
    { lat: 47.832273, lng: 17.799872 },
    { lat: 47.832426, lng: 17.799817 },
    { lat: 47.832456, lng: 17.799807 },
    { lat: 47.832638, lng: 17.799747 },
    { lat: 47.832662, lng: 17.79974 },
    { lat: 47.832681, lng: 17.799733 },
    { lat: 47.832709, lng: 17.799723 },
    { lat: 47.832808, lng: 17.79969 },
    { lat: 47.832861, lng: 17.799673 },
    { lat: 47.832913, lng: 17.799656 },
    { lat: 47.833006, lng: 17.799625 },
    { lat: 47.83304, lng: 17.799613 },
    { lat: 47.833157, lng: 17.799575 },
    { lat: 47.83345, lng: 17.799477 },
    { lat: 47.833535, lng: 17.799883 },
    { lat: 47.833559, lng: 17.799986 },
    { lat: 47.833563, lng: 17.800004 },
    { lat: 47.83364, lng: 17.80038 },
    { lat: 47.834048, lng: 17.800228 },
    { lat: 47.834352, lng: 17.800114 },
    { lat: 47.834486, lng: 17.800058 },
    { lat: 47.834626, lng: 17.8 },
    { lat: 47.834837, lng: 17.799912 },
    { lat: 47.835031, lng: 17.799831 },
    { lat: 47.835336, lng: 17.799705 },
    { lat: 47.835582, lng: 17.79961 },
    { lat: 47.835852, lng: 17.799507 },
    { lat: 47.835975, lng: 17.799459 },
    { lat: 47.83624, lng: 17.799368 },
    { lat: 47.836614, lng: 17.799239 },
    { lat: 47.836686, lng: 17.799217 },
    { lat: 47.836912, lng: 17.79915 },
    { lat: 47.837257, lng: 17.799047 },
    { lat: 47.837543, lng: 17.798863 },
    { lat: 47.837872, lng: 17.798651 },
    { lat: 47.838255, lng: 17.798438 },
    { lat: 47.838387, lng: 17.798363 },
    { lat: 47.839048, lng: 17.797993 },
    { lat: 47.839222, lng: 17.797989 },
    { lat: 47.839599, lng: 17.797982 },
    { lat: 47.839906, lng: 17.797976 },
    { lat: 47.840226, lng: 17.797969 },
    { lat: 47.840587, lng: 17.79805 },
    { lat: 47.840604, lng: 17.798054 },
    { lat: 47.840989, lng: 17.797987 },
    { lat: 47.841342, lng: 17.797925 },
    { lat: 47.841474, lng: 17.797902 },
    { lat: 47.841598, lng: 17.79788 },
    { lat: 47.841866, lng: 17.797831 },
    { lat: 47.842088, lng: 17.797792 },
    { lat: 47.84221, lng: 17.79777 },
    { lat: 47.84238, lng: 17.797723 },
    { lat: 47.842913, lng: 17.797576 },
    { lat: 47.843156, lng: 17.797509 },
    { lat: 47.843231, lng: 17.797479 },
    { lat: 47.843452, lng: 17.797394 },
    { lat: 47.843526, lng: 17.797365 },
    { lat: 47.843564, lng: 17.797351 },
    { lat: 47.843604, lng: 17.797335 },
    { lat: 47.843733, lng: 17.797286 },
    { lat: 47.843861, lng: 17.797236 },
    { lat: 47.843917, lng: 17.797214 },
    { lat: 47.844013, lng: 17.797177 },
    { lat: 47.844042, lng: 17.797165 },
    { lat: 47.844084, lng: 17.797153 },
    { lat: 47.844156, lng: 17.797131 },
    { lat: 47.844412, lng: 17.797055 },
    { lat: 47.844449, lng: 17.797044 },
    { lat: 47.844534, lng: 17.797018 },
    { lat: 47.844562, lng: 17.79701 },
    { lat: 47.844634, lng: 17.796989 },
    { lat: 47.844643, lng: 17.796986 },
    { lat: 47.844669, lng: 17.796979 },
    { lat: 47.84472, lng: 17.796965 },
    { lat: 47.844775, lng: 17.79695 },
    { lat: 47.844826, lng: 17.796936 },
    { lat: 47.844956, lng: 17.796901 },
    { lat: 47.845015, lng: 17.796884 },
    { lat: 47.845075, lng: 17.796868 },
    { lat: 47.845272, lng: 17.796815 },
    { lat: 47.845352, lng: 17.796793 },
    { lat: 47.845394, lng: 17.796781 },
    { lat: 47.845547, lng: 17.79674 },
    { lat: 47.845755, lng: 17.796683 },
    { lat: 47.845869, lng: 17.796651 },
    { lat: 47.846144, lng: 17.796573 },
    { lat: 47.846367, lng: 17.79651 },
    { lat: 47.846432, lng: 17.796492 },
    { lat: 47.846539, lng: 17.796461 },
    { lat: 47.8467, lng: 17.796415 },
    { lat: 47.846935, lng: 17.796349 },
    { lat: 47.846975, lng: 17.796338 },
    { lat: 47.846984, lng: 17.796335 },
    { lat: 47.84702, lng: 17.796325 },
    { lat: 47.847042, lng: 17.796319 },
    { lat: 47.847061, lng: 17.796314 },
    { lat: 47.847125, lng: 17.796296 },
    { lat: 47.847166, lng: 17.796284 },
    { lat: 47.847207, lng: 17.796273 },
    { lat: 47.84727, lng: 17.796255 },
    { lat: 47.847385, lng: 17.796224 },
    { lat: 47.847436, lng: 17.796209 },
    { lat: 47.847507, lng: 17.796189 },
    { lat: 47.847782, lng: 17.796113 },
    { lat: 47.848187, lng: 17.796 },
    { lat: 47.848338, lng: 17.795958 },
    { lat: 47.848435, lng: 17.795925 },
    { lat: 47.848558, lng: 17.795885 },
    { lat: 47.848776, lng: 17.795812 },
    { lat: 47.849021, lng: 17.79573 },
    { lat: 47.849439, lng: 17.795589 },
    { lat: 47.849502, lng: 17.795568 },
    { lat: 47.849644, lng: 17.79552 },
    { lat: 47.849682, lng: 17.795507 },
    { lat: 47.849726, lng: 17.795492 },
    { lat: 47.849863, lng: 17.795445 },
    { lat: 47.849898, lng: 17.795442 },
    { lat: 47.849933, lng: 17.79544 },
    { lat: 47.850151, lng: 17.795424 },
    { lat: 47.850241, lng: 17.795417 },
    { lat: 47.850396, lng: 17.795404 },
    { lat: 47.850442, lng: 17.795401 },
    { lat: 47.850497, lng: 17.795397 },
    { lat: 47.850634, lng: 17.795387 },
    { lat: 47.850976, lng: 17.79536 },
    { lat: 47.851078, lng: 17.795353 },
    { lat: 47.851219, lng: 17.795342 },
    { lat: 47.85128, lng: 17.795338 },
    { lat: 47.851399, lng: 17.79534 },
    { lat: 47.851506, lng: 17.795342 },
    { lat: 47.851514, lng: 17.795343 },
    { lat: 47.851554, lng: 17.795343 },
    { lat: 47.851568, lng: 17.795343 },
    { lat: 47.851582, lng: 17.795342 },
    { lat: 47.851735, lng: 17.795344 },
    { lat: 47.851762, lng: 17.795344 },
    { lat: 47.851933, lng: 17.795348 },
    { lat: 47.852115, lng: 17.795352 },
    { lat: 47.852196, lng: 17.795353 },
    { lat: 47.852383, lng: 17.795357 },
    { lat: 47.852468, lng: 17.795358 },
    { lat: 47.852476, lng: 17.795359 },
    { lat: 47.852513, lng: 17.795369 },
    { lat: 47.852638, lng: 17.795402 },
    { lat: 47.852751, lng: 17.795432 },
    { lat: 47.852858, lng: 17.795461 },
    { lat: 47.852975, lng: 17.795492 },
    { lat: 47.853082, lng: 17.795521 },
    { lat: 47.853392, lng: 17.795604 },
    { lat: 47.853581, lng: 17.795755 },
    { lat: 47.853586, lng: 17.79576 },
    { lat: 47.853602, lng: 17.795771 },
    { lat: 47.853663, lng: 17.795811 },
    { lat: 47.853683, lng: 17.795825 },
    { lat: 47.853689, lng: 17.795829 },
    { lat: 47.853905, lng: 17.795973 },
    { lat: 47.853973, lng: 17.796018 },
    { lat: 47.854045, lng: 17.796065 },
    { lat: 47.854102, lng: 17.796075 },
    { lat: 47.854394, lng: 17.796121 },
    { lat: 47.854503, lng: 17.796138 },
    { lat: 47.854724, lng: 17.796174 },
    { lat: 47.854812, lng: 17.796188 },
    { lat: 47.855268, lng: 17.796525 },
    { lat: 47.855411, lng: 17.796632 },
    { lat: 47.85554, lng: 17.796729 },
    { lat: 47.855891, lng: 17.796994 },
    { lat: 47.856079, lng: 17.797146 },
    { lat: 47.85624, lng: 17.797275 },
    { lat: 47.856247, lng: 17.797281 },
    { lat: 47.856368, lng: 17.797376 },
    { lat: 47.856519, lng: 17.797496 },
    { lat: 47.856569, lng: 17.797537 },
    { lat: 47.856851, lng: 17.797764 },
    { lat: 47.85706, lng: 17.797933 },
    { lat: 47.857217, lng: 17.79806 },
    { lat: 47.857414, lng: 17.798224 },
    { lat: 47.857527, lng: 17.79832 },
    { lat: 47.857695, lng: 17.798458 },
    { lat: 47.85774, lng: 17.798495 },
    { lat: 47.858001, lng: 17.798712 },
    { lat: 47.858011, lng: 17.798719 },
    { lat: 47.858188, lng: 17.798866 },
    { lat: 47.858229, lng: 17.7989 },
    { lat: 47.858307, lng: 17.798964 },
    { lat: 47.858335, lng: 17.798987 },
    { lat: 47.858593, lng: 17.799201 },
    { lat: 47.858622, lng: 17.799224 },
    { lat: 47.858865, lng: 17.799425 },
    { lat: 47.859097, lng: 17.799616 },
    { lat: 47.859163, lng: 17.799671 },
    { lat: 47.859181, lng: 17.799686 },
    { lat: 47.859235, lng: 17.799729 },
    { lat: 47.859374, lng: 17.799844 },
    { lat: 47.859438, lng: 17.799898 },
    { lat: 47.859748, lng: 17.800152 },
    { lat: 47.859797, lng: 17.800193 },
    { lat: 47.859906, lng: 17.800282 },
    { lat: 47.859942, lng: 17.800312 },
    { lat: 47.859958, lng: 17.800325 },
    { lat: 47.859985, lng: 17.800348 },
    { lat: 47.859999, lng: 17.800359 },
    { lat: 47.860053, lng: 17.800403 },
    { lat: 47.86037, lng: 17.800665 },
    { lat: 47.860417, lng: 17.800705 },
    { lat: 47.860422, lng: 17.800707 },
    { lat: 47.860475, lng: 17.800767 },
    { lat: 47.860564, lng: 17.800863 },
    { lat: 47.860615, lng: 17.800919 },
    { lat: 47.860664, lng: 17.800973 },
    { lat: 47.86076, lng: 17.801078 },
    { lat: 47.860823, lng: 17.801147 },
    { lat: 47.860902, lng: 17.801232 },
    { lat: 47.861045, lng: 17.801387 },
    { lat: 47.861197, lng: 17.801553 },
    { lat: 47.861299, lng: 17.801662 },
    { lat: 47.861363, lng: 17.801732 },
    { lat: 47.861523, lng: 17.801904 },
    { lat: 47.861599, lng: 17.801986 },
    { lat: 47.861676, lng: 17.802071 },
    { lat: 47.861893, lng: 17.802305 },
    { lat: 47.861927, lng: 17.80233 },
    { lat: 47.86204, lng: 17.802413 },
    { lat: 47.862155, lng: 17.802498 },
    { lat: 47.862699, lng: 17.8029 },
    { lat: 47.862782, lng: 17.802965 },
    { lat: 47.863105, lng: 17.803268 },
    { lat: 47.863448, lng: 17.803608 },
    { lat: 47.863567, lng: 17.803762 },
    { lat: 47.863772, lng: 17.804062 },
    { lat: 47.86412, lng: 17.804726 },
    { lat: 47.864218, lng: 17.804964 },
    { lat: 47.864405, lng: 17.805419 },
    { lat: 47.864557, lng: 17.805783 },
    { lat: 47.864741, lng: 17.806025 },
    { lat: 47.864969, lng: 17.806326 },
    { lat: 47.865177, lng: 17.806599 },
    { lat: 47.865182, lng: 17.806606 },
    { lat: 47.865397, lng: 17.806701 },
    { lat: 47.865716, lng: 17.806848 },
    { lat: 47.866174, lng: 17.807058 },
    { lat: 47.866207, lng: 17.807074 },
    { lat: 47.866712, lng: 17.807306 },
    { lat: 47.866909, lng: 17.807397 },
    { lat: 47.867199, lng: 17.807531 },
    { lat: 47.867204, lng: 17.807534 },
    { lat: 47.867974, lng: 17.807138 },
    { lat: 47.868241, lng: 17.807 },
    { lat: 47.868326, lng: 17.806944 },
    { lat: 47.868397, lng: 17.806898 },
    { lat: 47.868892, lng: 17.806586 },
    { lat: 47.869241, lng: 17.806367 },
    { lat: 47.8689049, lng: 17.8051698 },
    { lat: 47.8702099, lng: 17.8037627 },
    { lat: 47.8709967, lng: 17.8025461 },
    { lat: 47.8716651, lng: 17.8011431 },
    { lat: 47.8719055, lng: 17.7994116 },
    { lat: 47.8716231, lng: 17.7957328 },
    { lat: 47.8725631, lng: 17.7957467 },
    { lat: 47.8723393, lng: 17.7921949 },
    { lat: 47.8717831, lng: 17.7889432 },
    { lat: 47.8711572, lng: 17.785215 },
    { lat: 47.8709452, lng: 17.7828198 },
    { lat: 47.8715807, lng: 17.7824934 },
    { lat: 47.8717659, lng: 17.7812757 },
    { lat: 47.8718169, lng: 17.7786502 },
    { lat: 47.8723166, lng: 17.7745605 },
    { lat: 47.8728804, lng: 17.7730263 },
    { lat: 47.8734958, lng: 17.7717201 },
    { lat: 47.8731293, lng: 17.7702413 },
    { lat: 47.8750831, lng: 17.7695646 },
    { lat: 47.8757085, lng: 17.768981 },
    { lat: 47.8781147, lng: 17.7697329 },
    { lat: 47.8793688, lng: 17.7710173 },
    { lat: 47.8800847, lng: 17.7716721 },
    { lat: 47.8809555, lng: 17.7728573 },
    { lat: 47.8848673, lng: 17.7725457 },
    { lat: 47.8855152, lng: 17.7727277 },
    { lat: 47.8861259, lng: 17.7718017 },
    { lat: 47.8865023, lng: 17.7709016 },
    { lat: 47.8860247, lng: 17.7695573 },
    { lat: 47.8872857, lng: 17.7697942 },
    { lat: 47.8876832, lng: 17.7697606 },
    { lat: 47.8881646, lng: 17.7696198 },
    { lat: 47.889229, lng: 17.7690016 },
    { lat: 47.8896682, lng: 17.7686759 },
    { lat: 47.8902956, lng: 17.7680908 },
    { lat: 47.8909804, lng: 17.767159 },
    { lat: 47.8916135, lng: 17.7658498 },
    { lat: 47.8923605, lng: 17.7633424 },
    { lat: 47.8931194, lng: 17.7604107 },
    { lat: 47.8935179, lng: 17.7585839 },
    { lat: 47.8940102, lng: 17.7569373 },
    { lat: 47.89452, lng: 17.753999 },
    { lat: 47.8950283, lng: 17.751612 },
    { lat: 47.8954565, lng: 17.7504072 },
    { lat: 47.8963337, lng: 17.7496307 },
    { lat: 47.8971118, lng: 17.7495046 },
    { lat: 47.8976075, lng: 17.7495245 },
    { lat: 47.8982829, lng: 17.7496001 },
    { lat: 47.8988488, lng: 17.7498432 },
    { lat: 47.8993355, lng: 17.7502027 },
    { lat: 47.9002448, lng: 17.7478601 },
    { lat: 47.9003227, lng: 17.7453686 },
    { lat: 47.9002768, lng: 17.7437816 },
    { lat: 47.9003053, lng: 17.7414936 },
    { lat: 47.9017228, lng: 17.7411246 },
    { lat: 47.9024023, lng: 17.7408308 },
    { lat: 47.9033795, lng: 17.7403358 },
    { lat: 47.9042349, lng: 17.7396422 },
    { lat: 47.9035497, lng: 17.7379464 },
    { lat: 47.9038591, lng: 17.7376433 },
    { lat: 47.9045545, lng: 17.736721 },
    { lat: 47.9054634, lng: 17.7353662 },
    { lat: 47.9058043, lng: 17.7346811 },
    { lat: 47.9062451, lng: 17.7335698 },
    { lat: 47.9063737, lng: 17.7330432 },
    { lat: 47.9063661, lng: 17.7325822 },
    { lat: 47.9062086, lng: 17.732046 },
    { lat: 47.9056961, lng: 17.7309297 },
    { lat: 47.9052024, lng: 17.7298085 },
    { lat: 47.9048157, lng: 17.7288057 },
    { lat: 47.9044444, lng: 17.727493 },
    { lat: 47.9043732, lng: 17.727005 },
    { lat: 47.9043234, lng: 17.7257037 },
    { lat: 47.9045584, lng: 17.7237796 },
    { lat: 47.9044982, lng: 17.7235395 },
    { lat: 47.9041495, lng: 17.7231632 },
    { lat: 47.9039961, lng: 17.7230936 },
    { lat: 47.9030872, lng: 17.7228224 },
    { lat: 47.9021924, lng: 17.7224186 },
    { lat: 47.9012355, lng: 17.7221037 },
    { lat: 47.9006419, lng: 17.7211861 },
    { lat: 47.9003872, lng: 17.7202743 },
    { lat: 47.9000613, lng: 17.7195441 },
    { lat: 47.8996977, lng: 17.7191294 },
    { lat: 47.8990135, lng: 17.7179598 },
    { lat: 47.8988246, lng: 17.7172869 },
    { lat: 47.898614, lng: 17.7157674 },
    { lat: 47.8983621, lng: 17.7146101 },
    { lat: 47.8980306, lng: 17.7121554 },
    { lat: 47.8979648, lng: 17.7093633 },
    { lat: 47.8979553, lng: 17.7090304 },
    { lat: 47.8973878, lng: 17.7087683 },
    { lat: 47.8968124, lng: 17.7084144 },
    { lat: 47.896844, lng: 17.7080013 },
    { lat: 47.8967401, lng: 17.7071569 },
    { lat: 47.8965442, lng: 17.7065361 },
    { lat: 47.8959835, lng: 17.7053197 },
    { lat: 47.8955171, lng: 17.7046003 },
    { lat: 47.8950531, lng: 17.7041705 },
    { lat: 47.8935222, lng: 17.7019622 },
    { lat: 47.8921893, lng: 17.7002999 },
    { lat: 47.8909692, lng: 17.6990135 },
    { lat: 47.8897085, lng: 17.6983231 },
    { lat: 47.8883863, lng: 17.69787 },
    { lat: 47.8844453, lng: 17.6945609 },
    { lat: 47.883901, lng: 17.6941122 },
    { lat: 47.8832636, lng: 17.6854802 },
    { lat: 47.8823566, lng: 17.6849175 },
    { lat: 47.8796375, lng: 17.6839672 },
    { lat: 47.8795039, lng: 17.6839228 },
    { lat: 47.8794302, lng: 17.6844733 },
    { lat: 47.8788475, lng: 17.6857604 },
    { lat: 47.8785537, lng: 17.686133 },
    { lat: 47.8772707, lng: 17.6874191 },
    { lat: 47.8769023, lng: 17.6876476 },
    { lat: 47.875586, lng: 17.6873635 },
    { lat: 47.8751954, lng: 17.6870796 },
    { lat: 47.8750064, lng: 17.686839 },
    { lat: 47.8747401, lng: 17.6863373 },
    { lat: 47.874597, lng: 17.6862004 },
    { lat: 47.8744554, lng: 17.6861572 },
    { lat: 47.8742795, lng: 17.6862337 },
    { lat: 47.8741504, lng: 17.6864606 },
    { lat: 47.8741238, lng: 17.6866992 },
    { lat: 47.8743863, lng: 17.6877479 },
    { lat: 47.874898, lng: 17.6887204 },
    { lat: 47.8756378, lng: 17.690533 },
    { lat: 47.8762751, lng: 17.6922442 },
    { lat: 47.876384, lng: 17.6925909 },
    { lat: 47.8764254, lng: 17.6929108 },
    { lat: 47.8764639, lng: 17.693683 },
    { lat: 47.8763601, lng: 17.6942555 },
    { lat: 47.8762066, lng: 17.694637 },
    { lat: 47.8760343, lng: 17.6950006 },
    { lat: 47.8758374, lng: 17.6952429 },
    { lat: 47.8751968, lng: 17.6958127 },
    { lat: 47.8748423, lng: 17.6960291 },
    { lat: 47.8745624, lng: 17.6959396 },
    { lat: 47.8740798, lng: 17.6958801 },
    { lat: 47.87331, lng: 17.695414 },
    { lat: 47.8728433, lng: 17.6952059 },
    { lat: 47.8720361, lng: 17.694846 },
    { lat: 47.8718438, lng: 17.6947602 },
    { lat: 47.8716195, lng: 17.6945305 },
    { lat: 47.8714939, lng: 17.6940613 },
    { lat: 47.8713641, lng: 17.6931457 },
    { lat: 47.8710138, lng: 17.6914946 },
    { lat: 47.8708616, lng: 17.6910852 },
    { lat: 47.8704483, lng: 17.689973 },
    { lat: 47.8696763, lng: 17.6888849 },
    { lat: 47.869399, lng: 17.6886784 },
    { lat: 47.8690698, lng: 17.6885934 },
    { lat: 47.8689033, lng: 17.6886395 },
    { lat: 47.8687574, lng: 17.6890321 },
    { lat: 47.868584, lng: 17.6892342 },
    { lat: 47.8683515, lng: 17.6897205 },
    { lat: 47.868183, lng: 17.6905048 },
    { lat: 47.8681467, lng: 17.6910922 },
    { lat: 47.8679346, lng: 17.6922585 },
    { lat: 47.8677532, lng: 17.6929665 },
    { lat: 47.8676523, lng: 17.6931743 },
    { lat: 47.8673157, lng: 17.6935485 },
    { lat: 47.8656274, lng: 17.6950139 },
    { lat: 47.8653414, lng: 17.6952066 },
    { lat: 47.8645882, lng: 17.6954379 },
    { lat: 47.8641051, lng: 17.6954948 },
    { lat: 47.8638211, lng: 17.6954482 },
    { lat: 47.8627345, lng: 17.6951 },
    { lat: 47.8623687, lng: 17.6947951 },
    { lat: 47.8619861, lng: 17.6945807 },
    { lat: 47.861527, lng: 17.6946094 },
    { lat: 47.8606086, lng: 17.695263 },
    { lat: 47.860434, lng: 17.6955186 },
    { lat: 47.8594123, lng: 17.6965811 },
    { lat: 47.8592686, lng: 17.6969146 },
    { lat: 47.859247, lng: 17.6971651 },
    { lat: 47.8597384, lng: 17.6982429 },
    { lat: 47.8601964, lng: 17.6987762 },
    { lat: 47.8603517, lng: 17.699128 },
    { lat: 47.8604806, lng: 17.6995253 },
    { lat: 47.8604848, lng: 17.6996639 },
    { lat: 47.8602882, lng: 17.7002878 },
    { lat: 47.8600589, lng: 17.7007204 },
    { lat: 47.8590558, lng: 17.7018758 },
    { lat: 47.857675, lng: 17.7032772 },
    { lat: 47.8566737, lng: 17.7040898 },
    { lat: 47.855775, lng: 17.7046448 },
    { lat: 47.8556529, lng: 17.7047866 },
    { lat: 47.8554558, lng: 17.7051883 },
    { lat: 47.855408, lng: 17.7055419 },
    { lat: 47.8554378, lng: 17.7059079 },
    { lat: 47.855682, lng: 17.7069356 },
    { lat: 47.8564941, lng: 17.7076603 },
    { lat: 47.8575614, lng: 17.7088684 },
    { lat: 47.8583334, lng: 17.7096036 },
    { lat: 47.8586734, lng: 17.7098366 },
    { lat: 47.8590758, lng: 17.7102958 },
    { lat: 47.8591906, lng: 17.71097 },
    { lat: 47.8589724, lng: 17.711704 },
    { lat: 47.8587968, lng: 17.7121059 },
    { lat: 47.8572964, lng: 17.7128255 },
    { lat: 47.8566381, lng: 17.7129892 },
    { lat: 47.8562964, lng: 17.7128865 },
    { lat: 47.8557764, lng: 17.7125719 },
    { lat: 47.8552272, lng: 17.7123662 },
    { lat: 47.8539684, lng: 17.7123678 },
    { lat: 47.8533127, lng: 17.7122801 },
    { lat: 47.8526829, lng: 17.7125175 },
    { lat: 47.8521922, lng: 17.7128858 },
    { lat: 47.8518656, lng: 17.7132383 },
    { lat: 47.8510356, lng: 17.7143154 },
    { lat: 47.8508107, lng: 17.7146893 },
    { lat: 47.8503443, lng: 17.7156407 },
    { lat: 47.8501905, lng: 17.7161232 },
    { lat: 47.8499904, lng: 17.7174458 },
    { lat: 47.8499548, lng: 17.7181944 },
    { lat: 47.8499454, lng: 17.7187075 },
    { lat: 47.8500359, lng: 17.7202791 },
    { lat: 47.8498925, lng: 17.7209791 },
    { lat: 47.849585, lng: 17.721602 },
    { lat: 47.8484054, lng: 17.7228665 },
    { lat: 47.8480307, lng: 17.7231724 },
    { lat: 47.8474179, lng: 17.7235367 },
    { lat: 47.8472229, lng: 17.723525 },
    { lat: 47.8465428, lng: 17.7231036 },
    { lat: 47.8463931, lng: 17.7229507 },
    { lat: 47.8463332, lng: 17.7228212 },
    { lat: 47.8463838, lng: 17.7227313 },
    { lat: 47.8463443, lng: 17.7225867 },
    { lat: 47.8462132, lng: 17.722459 },
    { lat: 47.8458344, lng: 17.7224133 },
    { lat: 47.8455463, lng: 17.7225 },
    { lat: 47.8451402, lng: 17.7229168 },
    { lat: 47.8443204, lng: 17.7248258 },
    { lat: 47.8438568, lng: 17.725337 },
    { lat: 47.8436276, lng: 17.7256235 },
    { lat: 47.8435838, lng: 17.7257712 },
    { lat: 47.8432074, lng: 17.7261396 },
    { lat: 47.8430767, lng: 17.7263453 },
    { lat: 47.8426582, lng: 17.7273946 },
    { lat: 47.8425343, lng: 17.7279023 },
    { lat: 47.8423342, lng: 17.7294317 },
    { lat: 47.8423706, lng: 17.7294533 },
    { lat: 47.8422496, lng: 17.730078 },
    { lat: 47.8422254, lng: 17.7305938 },
    { lat: 47.841976, lng: 17.7309368 },
    { lat: 47.8416785, lng: 17.7311617 },
    { lat: 47.8407031, lng: 17.7313627 },
    { lat: 47.839329, lng: 17.7314673 },
    { lat: 47.8388643, lng: 17.7313161 },
    { lat: 47.8381612, lng: 17.7305805 },
    { lat: 47.8371922, lng: 17.7290443 },
    { lat: 47.8370478, lng: 17.7289905 },
    { lat: 47.8364787, lng: 17.7291893 },
    { lat: 47.8360896, lng: 17.7296613 },
    { lat: 47.8355605, lng: 17.7306987 },
    { lat: 47.8350782, lng: 17.7321336 },
    { lat: 47.8346508, lng: 17.7331143 },
    { lat: 47.834387, lng: 17.7334649 },
    { lat: 47.8341099, lng: 17.7337088 },
    { lat: 47.8336169, lng: 17.733947 },
    { lat: 47.8327303, lng: 17.7340705 },
    { lat: 47.8323516, lng: 17.7340449 },
    { lat: 47.8318588, lng: 17.7339085 },
    { lat: 47.8311866, lng: 17.7334511 },
    { lat: 47.8306442, lng: 17.7329769 },
    { lat: 47.83024, lng: 17.7325258 },
    { lat: 47.8298778, lng: 17.7323109 },
    { lat: 47.8296786, lng: 17.7323554 },
    { lat: 47.8295148, lng: 17.7325891 },
    { lat: 47.8293553, lng: 17.7330714 },
    { lat: 47.8291475, lng: 17.7344749 },
    { lat: 47.8285555, lng: 17.7367582 },
    { lat: 47.8285449, lng: 17.7375821 },
    { lat: 47.8288507, lng: 17.739508 },
    { lat: 47.8289323, lng: 17.7406857 },
    { lat: 47.828911, lng: 17.7413171 },
    { lat: 47.8286433, lng: 17.7416401 },
    { lat: 47.8281492, lng: 17.7417367 },
    { lat: 47.827762, lng: 17.7415572 },
    { lat: 47.827282, lng: 17.7411597 },
    { lat: 47.8268852, lng: 17.7406798 },
    { lat: 47.8269192, lng: 17.7404471 },
    { lat: 47.826861, lng: 17.7399527 },
    { lat: 47.8266263, lng: 17.7388184 },
    { lat: 47.8263415, lng: 17.7366322 },
    { lat: 47.8263183, lng: 17.7358987 },
    { lat: 47.8264393, lng: 17.7344433 },
    { lat: 47.8264171, lng: 17.7341797 },
    { lat: 47.8264831, lng: 17.7339528 },
    { lat: 47.8265005, lng: 17.7336184 },
    { lat: 47.8264952, lng: 17.7329576 },
    { lat: 47.8264353, lng: 17.7325787 },
    { lat: 47.8263385, lng: 17.7321888 },
    { lat: 47.8262007, lng: 17.7318625 },
    { lat: 47.8262059, lng: 17.7317462 },
    { lat: 47.8261583, lng: 17.7316892 },
    { lat: 47.8260324, lng: 17.7316989 },
    { lat: 47.8256796, lng: 17.7313471 },
    { lat: 47.8253365, lng: 17.7311163 },
    { lat: 47.8251236, lng: 17.731144 },
    { lat: 47.8249222, lng: 17.7312976 },
    { lat: 47.8240959, lng: 17.7324678 },
    { lat: 47.8233846, lng: 17.7332961 },
    { lat: 47.8227681, lng: 17.7341618 },
    { lat: 47.8220005, lng: 17.7342352 },
    { lat: 47.8216453, lng: 17.7340444 },
    { lat: 47.8212935, lng: 17.7337621 },
    { lat: 47.8211026, lng: 17.7337238 },
    { lat: 47.8209362, lng: 17.7337903 },
    { lat: 47.8207581, lng: 17.7339576 },
    { lat: 47.8204774, lng: 17.7345756 },
    { lat: 47.8203085, lng: 17.7351227 },
    { lat: 47.8201791, lng: 17.7360331 },
    { lat: 47.8200578, lng: 17.7373029 },
    { lat: 47.8199877, lng: 17.7392136 },
    { lat: 47.8201544, lng: 17.7415315 },
    { lat: 47.8204448, lng: 17.7426373 },
    { lat: 47.8204312, lng: 17.7429988 },
    { lat: 47.8206227, lng: 17.7437821 },
    { lat: 47.8205939, lng: 17.7442452 },
    { lat: 47.8204477, lng: 17.7446862 },
    { lat: 47.8203388, lng: 17.7448403 },
    { lat: 47.8200741, lng: 17.745011 },
    { lat: 47.8197179, lng: 17.7450015 },
    { lat: 47.8193719, lng: 17.7447952 },
    { lat: 47.8186518, lng: 17.743949 },
    { lat: 47.8175308, lng: 17.7429044 },
    { lat: 47.8169434, lng: 17.7425949 },
    { lat: 47.8155324, lng: 17.7426505 },
    { lat: 47.8141992, lng: 17.742132 },
    { lat: 47.8129391, lng: 17.7423999 },
    { lat: 47.8128326, lng: 17.7423574 },
    { lat: 47.8125554, lng: 17.7421688 },
    { lat: 47.8116727, lng: 17.7411783 },
    { lat: 47.8107886, lng: 17.7403667 },
    { lat: 47.8100725, lng: 17.7393695 },
    { lat: 47.8093975, lng: 17.7385611 },
    { lat: 47.808812, lng: 17.737634 },
    { lat: 47.808784, lng: 17.737704 },
    { lat: 47.808678, lng: 17.73796 },
    { lat: 47.808636, lng: 17.738063 },
    { lat: 47.808576, lng: 17.73821 },
    { lat: 47.808094, lng: 17.738803 },
    { lat: 47.808081, lng: 17.738819 },
    { lat: 47.808016, lng: 17.738898 },
    { lat: 47.808009, lng: 17.738907 },
    { lat: 47.807198, lng: 17.739544 },
    { lat: 47.807103, lng: 17.739696 },
    { lat: 47.806855, lng: 17.740094 },
    { lat: 47.80679, lng: 17.740213 },
    { lat: 47.806501, lng: 17.740749 },
    { lat: 47.806405, lng: 17.740949 },
    { lat: 47.806374, lng: 17.741017 },
    { lat: 47.806186, lng: 17.741434 },
    { lat: 47.806102, lng: 17.741829 },
    { lat: 47.80614, lng: 17.742135 },
    { lat: 47.806148, lng: 17.742205 },
    { lat: 47.806673, lng: 17.743245 },
    { lat: 47.806775, lng: 17.743618 },
    { lat: 47.806851, lng: 17.743893 },
    { lat: 47.807109, lng: 17.744829 },
    { lat: 47.807277, lng: 17.745476 },
    { lat: 47.807359, lng: 17.745792 },
    { lat: 47.807479, lng: 17.746258 },
    { lat: 47.807483, lng: 17.746318 },
    { lat: 47.807511, lng: 17.746736 },
    { lat: 47.807511, lng: 17.746818 },
    { lat: 47.807506, lng: 17.747156 },
    { lat: 47.807503, lng: 17.747446 },
    { lat: 47.807472, lng: 17.747661 },
    { lat: 47.807443, lng: 17.747862 },
    { lat: 47.807392, lng: 17.747963 },
    { lat: 47.807298, lng: 17.748147 },
    { lat: 47.80719, lng: 17.748357 },
    { lat: 47.806994, lng: 17.748632 },
    { lat: 47.806818, lng: 17.748878 },
    { lat: 47.806793, lng: 17.748905 },
    { lat: 47.806597, lng: 17.749119 },
    { lat: 47.806433, lng: 17.749298 },
    { lat: 47.8064, lng: 17.749326 },
    { lat: 47.806094, lng: 17.749583 },
    { lat: 47.805796, lng: 17.749833 },
    { lat: 47.805682, lng: 17.749928 },
    { lat: 47.805055, lng: 17.750047 },
    { lat: 47.804368, lng: 17.750016 },
    { lat: 47.804448, lng: 17.750312 },
    { lat: 47.804541, lng: 17.750647 },
    { lat: 47.80462, lng: 17.751304 },
    { lat: 47.804817, lng: 17.751942 },
    { lat: 47.805121, lng: 17.752707 },
    { lat: 47.805145, lng: 17.752767 },
    { lat: 47.805236, lng: 17.752858 },
    { lat: 47.805289, lng: 17.752911 },
    { lat: 47.805386, lng: 17.753006 },
    { lat: 47.805565, lng: 17.753185 },
    { lat: 47.805645, lng: 17.753264 },
    { lat: 47.805728, lng: 17.753351 },
    { lat: 47.805832, lng: 17.753457 },
    { lat: 47.805972, lng: 17.753602 },
    { lat: 47.80605, lng: 17.753684 },
    { lat: 47.806086, lng: 17.753912 },
    { lat: 47.806213, lng: 17.754741 },
    { lat: 47.80622, lng: 17.754785 },
    { lat: 47.80605, lng: 17.755102 },
    { lat: 47.805894, lng: 17.755392 },
    { lat: 47.805717, lng: 17.755662 },
    { lat: 47.805453, lng: 17.756063 },
    { lat: 47.80505, lng: 17.756678 },
    { lat: 47.804828, lng: 17.757016 },
    { lat: 47.804634, lng: 17.757244 },
    { lat: 47.804573, lng: 17.757315 },
    { lat: 47.804476, lng: 17.757367 },
    { lat: 47.804361, lng: 17.757428 },
    { lat: 47.804099, lng: 17.757566 },
    { lat: 47.803931, lng: 17.757655 },
    { lat: 47.803867, lng: 17.757668 },
    { lat: 47.803761, lng: 17.757686 },
    { lat: 47.803649, lng: 17.757707 },
    { lat: 47.803471, lng: 17.757739 },
    { lat: 47.803424, lng: 17.757832 },
    { lat: 47.803265, lng: 17.758143 },
    { lat: 47.803139, lng: 17.758389 },
    { lat: 47.802996, lng: 17.758668 },
    { lat: 47.802746, lng: 17.759157 },
    { lat: 47.802691, lng: 17.759533 },
    { lat: 47.802647, lng: 17.759838 },
    { lat: 47.8026, lng: 17.760168 },
    { lat: 47.802554, lng: 17.760493 },
    { lat: 47.802509, lng: 17.760813 },
    { lat: 47.802469, lng: 17.761109 },
    { lat: 47.80245, lng: 17.761242 },
    { lat: 47.802409, lng: 17.761391 },
    { lat: 47.802315, lng: 17.761722 },
    { lat: 47.802235, lng: 17.762007 },
    { lat: 47.802143, lng: 17.762336 },
    { lat: 47.802068, lng: 17.762601 },
    { lat: 47.803322, lng: 17.762641 },
    { lat: 47.804314, lng: 17.762678 },
    { lat: 47.804616, lng: 17.762692 },
    { lat: 47.805011, lng: 17.762976 },
    { lat: 47.805522, lng: 17.763921 },
    { lat: 47.806262, lng: 17.765308 },
    { lat: 47.807134, lng: 17.766421 },
    { lat: 47.807338, lng: 17.766665 },
    { lat: 47.807762, lng: 17.766964 },
    { lat: 47.808362, lng: 17.767354 },
    { lat: 47.808862, lng: 17.76754 },
    { lat: 47.810481, lng: 17.768144 },
    { lat: 47.811015, lng: 17.768357 },
    { lat: 47.81259, lng: 17.769059 },
    { lat: 47.8126187, lng: 17.7690805 },
    { lat: 47.813018, lng: 17.76938 },
    { lat: 47.813423, lng: 17.769683 },
    { lat: 47.813928, lng: 17.770349 },
    { lat: 47.814186, lng: 17.770692 },
    { lat: 47.8153478, lng: 17.7735111 },
    { lat: 47.816139, lng: 17.775431 },
    { lat: 47.8162854, lng: 17.7758044 },
    { lat: 47.816557, lng: 17.776497 },
    { lat: 47.817135, lng: 17.777983 },
    { lat: 47.81718, lng: 17.778095 },
    { lat: 47.817553, lng: 17.779054 },
    { lat: 47.818113, lng: 17.781565 },
    { lat: 47.818118, lng: 17.781707 },
    { lat: 47.818149, lng: 17.781939 },
    { lat: 47.81822, lng: 17.782477 },
    { lat: 47.818522, lng: 17.784849 },
    { lat: 47.818864, lng: 17.786153 },
    { lat: 47.819388, lng: 17.787503 },
    { lat: 47.819415, lng: 17.78757 },
    { lat: 47.819429, lng: 17.787617 },
    { lat: 47.819683, lng: 17.788423 },
    { lat: 47.820279, lng: 17.790698 },
    { lat: 47.820596, lng: 17.792816 },
    { lat: 47.820606, lng: 17.792833 },
    { lat: 47.820632, lng: 17.792869 },
    { lat: 47.820662, lng: 17.792913 },
    { lat: 47.82071, lng: 17.792982 },
    { lat: 47.820747, lng: 17.793035 },
    { lat: 47.820833, lng: 17.793158 },
    { lat: 47.820875, lng: 17.793217 },
    { lat: 47.820887, lng: 17.793235 },
    { lat: 47.820899, lng: 17.79325 },
    { lat: 47.820915, lng: 17.793273 },
    { lat: 47.820927, lng: 17.793293 },
    { lat: 47.820937, lng: 17.793306 },
    { lat: 47.820947, lng: 17.793321 },
    { lat: 47.820957, lng: 17.793335 },
    { lat: 47.821015, lng: 17.793418 },
    { lat: 47.821074, lng: 17.793502 },
    { lat: 47.821145, lng: 17.793603 },
    { lat: 47.821212, lng: 17.793698 },
    { lat: 47.821351, lng: 17.793898 },
    { lat: 47.821425, lng: 17.794004 },
    { lat: 47.82144, lng: 17.794024 },
    { lat: 47.821548, lng: 17.794177 },
    { lat: 47.821561, lng: 17.794194 },
    { lat: 47.821576, lng: 17.794211 },
    { lat: 47.821641, lng: 17.794287 },
    { lat: 47.821716, lng: 17.794374 },
    { lat: 47.821752, lng: 17.794417 },
    { lat: 47.821841, lng: 17.79452 },
    { lat: 47.821887, lng: 17.794576 },
    { lat: 47.821905, lng: 17.794596 },
    { lat: 47.821927, lng: 17.794621 },
    { lat: 47.82194, lng: 17.794636 },
    { lat: 47.821944, lng: 17.79464 },
    { lat: 47.822063, lng: 17.794735 },
    { lat: 47.822114, lng: 17.794778 },
    { lat: 47.822151, lng: 17.794807 },
    { lat: 47.82217, lng: 17.794822 },
    { lat: 47.82234, lng: 17.79496 },
    { lat: 47.822356, lng: 17.794972 },
    { lat: 47.822372, lng: 17.794985 },
    { lat: 47.822386, lng: 17.794997 },
    { lat: 47.822402, lng: 17.795009 },
    { lat: 47.822417, lng: 17.795022 },
    { lat: 47.82243, lng: 17.795032 },
    { lat: 47.822449, lng: 17.795047 },
    { lat: 47.822523, lng: 17.795107 },
    { lat: 47.822549, lng: 17.795128 },
    { lat: 47.82263, lng: 17.795193 },
    { lat: 47.822855, lng: 17.795374 },
    { lat: 47.823038, lng: 17.795522 },
    { lat: 47.823097, lng: 17.795571 },
    { lat: 47.82316, lng: 17.795621 },
    { lat: 47.823172, lng: 17.795631 },
    { lat: 47.823199, lng: 17.795652 },
    { lat: 47.823214, lng: 17.795664 },
    { lat: 47.823228, lng: 17.795676 },
    { lat: 47.82325, lng: 17.795694 },
    { lat: 47.823385, lng: 17.795802 },
    { lat: 47.823399, lng: 17.795815 },
    { lat: 47.823501, lng: 17.795896 },
    { lat: 47.823548, lng: 17.795931 },
    { lat: 47.823622, lng: 17.795987 },
    { lat: 47.823634, lng: 17.795995 },
    { lat: 47.823654, lng: 17.79601 },
    { lat: 47.823677, lng: 17.796026 },
    { lat: 47.823765, lng: 17.796092 },
    { lat: 47.823777, lng: 17.7961 },
    { lat: 47.823818, lng: 17.796132 },
    { lat: 47.82384, lng: 17.796147 },
    { lat: 47.82385, lng: 17.796155 },
    { lat: 47.823863, lng: 17.796165 },
    { lat: 47.823878, lng: 17.796175 },
    { lat: 47.823896, lng: 17.79619 },
    { lat: 47.823917, lng: 17.796205 },
    { lat: 47.823936, lng: 17.796219 },
    { lat: 47.823976, lng: 17.796248 },
    { lat: 47.823989, lng: 17.796258 },
    { lat: 47.824008, lng: 17.796272 },
    { lat: 47.824031, lng: 17.796289 },
    { lat: 47.824075, lng: 17.796322 },
    { lat: 47.824092, lng: 17.796335 },
    { lat: 47.824111, lng: 17.796348 },
    { lat: 47.824137, lng: 17.796367 },
    { lat: 47.82421, lng: 17.796423 },
    { lat: 47.824294, lng: 17.796485 },
    { lat: 47.824382, lng: 17.796549 },
    { lat: 47.82444, lng: 17.796593 },
    { lat: 47.824607, lng: 17.796716 },
    { lat: 47.825238, lng: 17.797134 },
    { lat: 47.825269, lng: 17.797156 },
    { lat: 47.825304, lng: 17.797178 },
    { lat: 47.825344, lng: 17.797204 },
    { lat: 47.825433, lng: 17.797264 },
    { lat: 47.825632, lng: 17.797395 },
    { lat: 47.825685, lng: 17.79743 },
    { lat: 47.825729, lng: 17.797459 },
    { lat: 47.825745, lng: 17.79747 },
    { lat: 47.825762, lng: 17.797482 },
    { lat: 47.825798, lng: 17.797506 },
    { lat: 47.825825, lng: 17.797524 },
    { lat: 47.825848, lng: 17.797539 },
    { lat: 47.825873, lng: 17.797557 },
    { lat: 47.825898, lng: 17.797574 },
    { lat: 47.825975, lng: 17.797626 },
    { lat: 47.826114, lng: 17.797719 },
    { lat: 47.826147, lng: 17.797742 },
    { lat: 47.826168, lng: 17.797756 },
    { lat: 47.826208, lng: 17.797782 },
    { lat: 47.826255, lng: 17.797815 },
    { lat: 47.826332, lng: 17.797866 },
    { lat: 47.826375, lng: 17.797896 },
    { lat: 47.826387, lng: 17.797904 },
    { lat: 47.826421, lng: 17.797928 },
    { lat: 47.826716, lng: 17.798126 },
    { lat: 47.826807, lng: 17.798188 },
    { lat: 47.826868, lng: 17.798228 },
    { lat: 47.826939, lng: 17.798277 },
    { lat: 47.82699, lng: 17.798311 },
    { lat: 47.827033, lng: 17.79834 },
    { lat: 47.827053, lng: 17.798354 },
    { lat: 47.827066, lng: 17.798363 },
    { lat: 47.827077, lng: 17.79837 },
    { lat: 47.827088, lng: 17.798377 },
    { lat: 47.82711, lng: 17.798392 },
    { lat: 47.827141, lng: 17.798414 },
    { lat: 47.82718, lng: 17.798441 },
    { lat: 47.827194, lng: 17.79845 },
    { lat: 47.827203, lng: 17.798455 },
    { lat: 47.827209, lng: 17.798459 },
    { lat: 47.827243, lng: 17.798482 },
    { lat: 47.827262, lng: 17.798495 },
    { lat: 47.827282, lng: 17.798508 },
    { lat: 47.827317, lng: 17.798532 },
    { lat: 47.82733, lng: 17.798541 },
    { lat: 47.827339, lng: 17.798547 },
    { lat: 47.827353, lng: 17.798557 },
    { lat: 47.827385, lng: 17.798576 },
    { lat: 47.827412, lng: 17.798592 },
    { lat: 47.82743, lng: 17.798602 },
    { lat: 47.827451, lng: 17.798615 },
    { lat: 47.827467, lng: 17.798624 },
    { lat: 47.827531, lng: 17.798663 },
    { lat: 47.827555, lng: 17.798678 },
    { lat: 47.827642, lng: 17.79873 },
    { lat: 47.827685, lng: 17.798756 },
    { lat: 47.827703, lng: 17.798765 },
    { lat: 47.827745, lng: 17.798791 },
    { lat: 47.827789, lng: 17.798817 },
    { lat: 47.827819, lng: 17.798834 },
    { lat: 47.827833, lng: 17.798843 },
    { lat: 47.827847, lng: 17.798851 },
    { lat: 47.827895, lng: 17.79888 },
    { lat: 47.827939, lng: 17.798906 },
    { lat: 47.827962, lng: 17.79892 },
    { lat: 47.827976, lng: 17.798929 },
    { lat: 47.828002, lng: 17.798944 },
    { lat: 47.828053, lng: 17.798975 },
    { lat: 47.828079, lng: 17.798991 },
    { lat: 47.828151, lng: 17.799033 },
    { lat: 47.828168, lng: 17.799043 },
    { lat: 47.828188, lng: 17.799055 },
    { lat: 47.828206, lng: 17.799065 },
    { lat: 47.828228, lng: 17.799079 },
    { lat: 47.828252, lng: 17.799094 },
    { lat: 47.82827, lng: 17.799104 },
    { lat: 47.828289, lng: 17.799116 },
    { lat: 47.828355, lng: 17.799155 },
    { lat: 47.828405, lng: 17.799185 },
    { lat: 47.828425, lng: 17.799197 },
    { lat: 47.828465, lng: 17.799221 },
    { lat: 47.828521, lng: 17.799254 },
    { lat: 47.828581, lng: 17.799284 },
    { lat: 47.828614, lng: 17.799301 },
    { lat: 47.828634, lng: 17.799311 },
    { lat: 47.828664, lng: 17.799326 },
    { lat: 47.828674, lng: 17.799331 },
    { lat: 47.828687, lng: 17.799338 },
    { lat: 47.828712, lng: 17.79935 },
    { lat: 47.828775, lng: 17.799382 },
    { lat: 47.82879, lng: 17.799389 },
    { lat: 47.828811, lng: 17.799399 },
    { lat: 47.828835, lng: 17.799411 },
    { lat: 47.828867, lng: 17.799427 },
    { lat: 47.828924, lng: 17.799456 },
    { lat: 47.829052, lng: 17.79952 },
    { lat: 47.829093, lng: 17.79954 },
    { lat: 47.829113, lng: 17.79955 },
    { lat: 47.829142, lng: 17.799565 },
    { lat: 47.829215, lng: 17.799601 },
    { lat: 47.82928, lng: 17.799634 },
    { lat: 47.829351, lng: 17.799668 },
    { lat: 47.82938, lng: 17.799684 },
    { lat: 47.829484, lng: 17.799735 },
    { lat: 47.829524, lng: 17.799755 },
    { lat: 47.829533, lng: 17.79976 },
    { lat: 47.829593, lng: 17.79979 },
  ],
  Dobrohošť: [
    { lat: 48.0010656, lng: 17.3696031 },
    { lat: 48.0008509, lng: 17.369431 },
    { lat: 48.0020768, lng: 17.3662371 },
    { lat: 48.0021261, lng: 17.3649962 },
    { lat: 48.0022203, lng: 17.3648301 },
    { lat: 48.0022896, lng: 17.3649043 },
    { lat: 48.0032478, lng: 17.3628648 },
    { lat: 48.0045418, lng: 17.3601117 },
    { lat: 48.0037439, lng: 17.3598132 },
    { lat: 48.0031769, lng: 17.3593331 },
    { lat: 48.0024008, lng: 17.3583002 },
    { lat: 47.9992011, lng: 17.35616 },
    { lat: 47.9984543, lng: 17.3554369 },
    { lat: 47.997424, lng: 17.3547132 },
    { lat: 47.9959119, lng: 17.3532422 },
    { lat: 47.9950143, lng: 17.3525303 },
    { lat: 47.9932905, lng: 17.3518231 },
    { lat: 47.9926558, lng: 17.3514473 },
    { lat: 47.9888465, lng: 17.3487684 },
    { lat: 47.9882568, lng: 17.3481318 },
    { lat: 47.982885, lng: 17.346507 },
    { lat: 47.98255, lng: 17.346821 },
    { lat: 47.98149, lng: 17.347968 },
    { lat: 47.980017, lng: 17.349761 },
    { lat: 47.978589, lng: 17.351631 },
    { lat: 47.977437, lng: 17.35332 },
    { lat: 47.97582, lng: 17.356011 },
    { lat: 47.974233, lng: 17.359151 },
    { lat: 47.973269, lng: 17.360904 },
    { lat: 47.972221, lng: 17.362546 },
    { lat: 47.971096, lng: 17.36407 },
    { lat: 47.968272, lng: 17.367348 },
    { lat: 47.9676652, lng: 17.3678981 },
    { lat: 47.9688347, lng: 17.3686723 },
    { lat: 47.969515, lng: 17.3691956 },
    { lat: 47.975592, lng: 17.3683885 },
    { lat: 47.976072, lng: 17.3683707 },
    { lat: 47.9792979, lng: 17.3689039 },
    { lat: 47.9804638, lng: 17.369336 },
    { lat: 47.9798269, lng: 17.370301 },
    { lat: 47.9787638, lng: 17.371743 },
    { lat: 47.9783234, lng: 17.3727241 },
    { lat: 47.9821, lng: 17.3767757 },
    { lat: 47.9840209, lng: 17.3733296 },
    { lat: 47.9840343, lng: 17.3734133 },
    { lat: 47.9843069, lng: 17.3731247 },
    { lat: 47.9856428, lng: 17.3723151 },
    { lat: 47.9872043, lng: 17.3716675 },
    { lat: 47.9883356, lng: 17.3710753 },
    { lat: 47.9890152, lng: 17.3705034 },
    { lat: 47.9972906, lng: 17.3682093 },
    { lat: 48.000886, lng: 17.3700652 },
    { lat: 48.0010656, lng: 17.3696031 },
  ],
  BellovaVes: [
    { lat: 48.1026076, lng: 17.5005037 },
    { lat: 48.102365, lng: 17.4996651 },
    { lat: 48.1019238, lng: 17.4989876 },
    { lat: 48.100639, lng: 17.5002377 },
    { lat: 48.0994774, lng: 17.501228 },
    { lat: 48.0985181, lng: 17.5039217 },
    { lat: 48.0981741, lng: 17.503751 },
    { lat: 48.0954609, lng: 17.501518 },
    { lat: 48.0945732, lng: 17.5004122 },
    { lat: 48.0900926, lng: 17.4944668 },
    { lat: 48.0889569, lng: 17.4926872 },
    { lat: 48.0868883, lng: 17.4941822 },
    { lat: 48.0869178, lng: 17.4944626 },
    { lat: 48.0867655, lng: 17.4952725 },
    { lat: 48.0860343, lng: 17.4974721 },
    { lat: 48.0859355, lng: 17.4980577 },
    { lat: 48.0858793, lng: 17.4989919 },
    { lat: 48.0841833, lng: 17.501837 },
    { lat: 48.085028, lng: 17.5033405 },
    { lat: 48.080623, lng: 17.5098398 },
    { lat: 48.0787122, lng: 17.506787 },
    { lat: 48.0782683, lng: 17.5074348 },
    { lat: 48.0780658, lng: 17.506712 },
    { lat: 48.075008, lng: 17.5019509 },
    { lat: 48.0734532, lng: 17.4997979 },
    { lat: 48.0694947, lng: 17.4949283 },
    { lat: 48.0691057, lng: 17.4944485 },
    { lat: 48.0672539, lng: 17.4957489 },
    { lat: 48.0654865, lng: 17.49778 },
    { lat: 48.0695748, lng: 17.4998304 },
    { lat: 48.0716497, lng: 17.5015362 },
    { lat: 48.0732341, lng: 17.5039343 },
    { lat: 48.0748892, lng: 17.5072921 },
    { lat: 48.0758247, lng: 17.509038 },
    { lat: 48.076378, lng: 17.5123703 },
    { lat: 48.0766423, lng: 17.5150918 },
    { lat: 48.0761673, lng: 17.5180838 },
    { lat: 48.0754456, lng: 17.5202447 },
    { lat: 48.0753141, lng: 17.5205895 },
    { lat: 48.0771271, lng: 17.521631 },
    { lat: 48.0800925, lng: 17.5234725 },
    { lat: 48.080154, lng: 17.5236745 },
    { lat: 48.0832239, lng: 17.5192342 },
    { lat: 48.085981, lng: 17.5253959 },
    { lat: 48.0877967, lng: 17.522753 },
    { lat: 48.090803, lng: 17.5295078 },
    { lat: 48.0930344, lng: 17.5269023 },
    { lat: 48.0938535, lng: 17.5294256 },
    { lat: 48.0950223, lng: 17.5321513 },
    { lat: 48.1018251, lng: 17.5354799 },
    { lat: 48.1021229, lng: 17.5356327 },
    { lat: 48.1088662, lng: 17.5052517 },
    { lat: 48.1089043, lng: 17.5039841 },
    { lat: 48.1089183, lng: 17.5036061 },
    { lat: 48.1041172, lng: 17.5012339 },
    { lat: 48.1026076, lng: 17.5005037 },
  ],
  Kyselica: [
    { lat: 48.0010656, lng: 17.3696031 },
    { lat: 48.000886, lng: 17.3700652 },
    { lat: 47.9972906, lng: 17.3682093 },
    { lat: 47.9890152, lng: 17.3705034 },
    { lat: 47.9883356, lng: 17.3710753 },
    { lat: 47.9872043, lng: 17.3716675 },
    { lat: 47.9856428, lng: 17.3723151 },
    { lat: 47.9843069, lng: 17.3731247 },
    { lat: 47.9840343, lng: 17.3734133 },
    { lat: 47.9840209, lng: 17.3733296 },
    { lat: 47.9821, lng: 17.3767757 },
    { lat: 47.9783234, lng: 17.3727241 },
    { lat: 47.9787638, lng: 17.371743 },
    { lat: 47.9798269, lng: 17.370301 },
    { lat: 47.9804638, lng: 17.369336 },
    { lat: 47.9792979, lng: 17.3689039 },
    { lat: 47.976072, lng: 17.3683707 },
    { lat: 47.975592, lng: 17.3683885 },
    { lat: 47.969515, lng: 17.3691956 },
    { lat: 47.9688347, lng: 17.3686723 },
    { lat: 47.9676652, lng: 17.3678981 },
    { lat: 47.962641, lng: 17.372452 },
    { lat: 47.9622587, lng: 17.3727578 },
    { lat: 47.9694009, lng: 17.3775991 },
    { lat: 47.9709203, lng: 17.3775175 },
    { lat: 47.973489, lng: 17.3782773 },
    { lat: 47.9735741, lng: 17.3782125 },
    { lat: 47.9738344, lng: 17.3784219 },
    { lat: 47.9740257, lng: 17.3784336 },
    { lat: 47.9741116, lng: 17.3783512 },
    { lat: 47.9743819, lng: 17.3784046 },
    { lat: 47.9754776, lng: 17.3774107 },
    { lat: 47.9755409, lng: 17.3774773 },
    { lat: 47.9756087, lng: 17.3774293 },
    { lat: 47.9780773, lng: 17.3800887 },
    { lat: 47.9776824, lng: 17.3805928 },
    { lat: 47.9775947, lng: 17.3811851 },
    { lat: 47.9773624, lng: 17.3815873 },
    { lat: 47.977247, lng: 17.3820471 },
    { lat: 47.9820557, lng: 17.387911 },
    { lat: 47.9832418, lng: 17.3903233 },
    { lat: 47.9836131, lng: 17.3910953 },
    { lat: 47.9839451, lng: 17.3917849 },
    { lat: 47.9847154, lng: 17.3922307 },
    { lat: 47.9848457, lng: 17.3924102 },
    { lat: 47.9859594, lng: 17.3924796 },
    { lat: 47.9863749, lng: 17.3924245 },
    { lat: 47.9870082, lng: 17.3937631 },
    { lat: 47.9875816, lng: 17.3931372 },
    { lat: 47.987044, lng: 17.3923949 },
    { lat: 47.9870239, lng: 17.3923671 },
    { lat: 47.9877591, lng: 17.3923822 },
    { lat: 47.9884593, lng: 17.3915887 },
    { lat: 47.9889577, lng: 17.3907635 },
    { lat: 47.9892869, lng: 17.3897925 },
    { lat: 47.989301, lng: 17.3895229 },
    { lat: 47.9895378, lng: 17.3890703 },
    { lat: 47.9895025, lng: 17.3885137 },
    { lat: 47.9898086, lng: 17.3878934 },
    { lat: 47.9904712, lng: 17.3871451 },
    { lat: 47.9912813, lng: 17.385858 },
    { lat: 47.9916085, lng: 17.3854226 },
    { lat: 47.9919478, lng: 17.3850855 },
    { lat: 47.9926239, lng: 17.3845604 },
    { lat: 47.9930215, lng: 17.3841492 },
    { lat: 47.9937009, lng: 17.3831727 },
    { lat: 47.9938187, lng: 17.3830596 },
    { lat: 47.9943945, lng: 17.3824853 },
    { lat: 47.9946855, lng: 17.3821182 },
    { lat: 47.9962637, lng: 17.3798166 },
    { lat: 47.9976224, lng: 17.3773008 },
    { lat: 47.998453, lng: 17.3750695 },
    { lat: 48.0016548, lng: 17.3708334 },
    { lat: 48.0015916, lng: 17.3705591 },
    { lat: 48.0011455, lng: 17.3696673 },
    { lat: 48.0010656, lng: 17.3696031 },
  ],
  ČiližskáRadvaň: [
    { lat: 47.8656274, lng: 17.6950139 },
    { lat: 47.865567, lng: 17.6948774 },
    { lat: 47.8643388, lng: 17.6914591 },
    { lat: 47.86314, lng: 17.6915528 },
    { lat: 47.8589912, lng: 17.692062 },
    { lat: 47.8587707, lng: 17.6917538 },
    { lat: 47.8586941, lng: 17.6914139 },
    { lat: 47.858548, lng: 17.6910507 },
    { lat: 47.8581344, lng: 17.6904347 },
    { lat: 47.8580987, lng: 17.6902618 },
    { lat: 47.8583329, lng: 17.6892673 },
    { lat: 47.8583702, lng: 17.6887998 },
    { lat: 47.8581858, lng: 17.6873272 },
    { lat: 47.8582028, lng: 17.6871135 },
    { lat: 47.8583336, lng: 17.6867515 },
    { lat: 47.8587755, lng: 17.6859366 },
    { lat: 47.8589124, lng: 17.6855822 },
    { lat: 47.8589977, lng: 17.6848563 },
    { lat: 47.8589758, lng: 17.6842958 },
    { lat: 47.8588131, lng: 17.6836146 },
    { lat: 47.8586577, lng: 17.6832956 },
    { lat: 47.8586196, lng: 17.6831047 },
    { lat: 47.8585972, lng: 17.6823211 },
    { lat: 47.8585552, lng: 17.6819743 },
    { lat: 47.8584957, lng: 17.6818378 },
    { lat: 47.858395, lng: 17.6817084 },
    { lat: 47.8580937, lng: 17.6817475 },
    { lat: 47.8577884, lng: 17.6815805 },
    { lat: 47.8574954, lng: 17.681197 },
    { lat: 47.8575147, lng: 17.6810716 },
    { lat: 47.8580058, lng: 17.6805149 },
    { lat: 47.8581256, lng: 17.6802662 },
    { lat: 47.8580039, lng: 17.6800012 },
    { lat: 47.8576547, lng: 17.6797848 },
    { lat: 47.8575299, lng: 17.6793961 },
    { lat: 47.8574716, lng: 17.6787433 },
    { lat: 47.8576392, lng: 17.6783575 },
    { lat: 47.8579758, lng: 17.6780195 },
    { lat: 47.8580541, lng: 17.6777376 },
    { lat: 47.8577101, lng: 17.6767607 },
    { lat: 47.8577, lng: 17.6759223 },
    { lat: 47.857636, lng: 17.675444 },
    { lat: 47.8570997, lng: 17.6750716 },
    { lat: 47.856739, lng: 17.67472 },
    { lat: 47.8568038, lng: 17.6742814 },
    { lat: 47.8569778, lng: 17.6736943 },
    { lat: 47.8570805, lng: 17.6731565 },
    { lat: 47.8570819, lng: 17.6713923 },
    { lat: 47.8571887, lng: 17.670438 },
    { lat: 47.8571196, lng: 17.6700743 },
    { lat: 47.8570691, lng: 17.669205 },
    { lat: 47.8571425, lng: 17.6686881 },
    { lat: 47.8546058, lng: 17.6685338 },
    { lat: 47.8525201, lng: 17.6684926 },
    { lat: 47.8521603, lng: 17.6695183 },
    { lat: 47.8503731, lng: 17.6707177 },
    { lat: 47.849701, lng: 17.6720336 },
    { lat: 47.8494087, lng: 17.6727503 },
    { lat: 47.8491145, lng: 17.6731688 },
    { lat: 47.8483844, lng: 17.6734608 },
    { lat: 47.8481593, lng: 17.6736693 },
    { lat: 47.847709, lng: 17.6748716 },
    { lat: 47.8475789, lng: 17.6750633 },
    { lat: 47.8471994, lng: 17.6754192 },
    { lat: 47.8467412, lng: 17.6760121 },
    { lat: 47.8464899, lng: 17.6765209 },
    { lat: 47.8460744, lng: 17.677165 },
    { lat: 47.8457823, lng: 17.6774036 },
    { lat: 47.8452936, lng: 17.6781208 },
    { lat: 47.845104, lng: 17.6782657 },
    { lat: 47.844216, lng: 17.6783089 },
    { lat: 47.8440577, lng: 17.6783802 },
    { lat: 47.8435941, lng: 17.6791023 },
    { lat: 47.8432594, lng: 17.6791103 },
    { lat: 47.842967, lng: 17.6792392 },
    { lat: 47.8428754, lng: 17.6794147 },
    { lat: 47.842871, lng: 17.6796828 },
    { lat: 47.8430059, lng: 17.6802819 },
    { lat: 47.8429614, lng: 17.6806153 },
    { lat: 47.8427313, lng: 17.6807447 },
    { lat: 47.8423304, lng: 17.6806905 },
    { lat: 47.8418019, lng: 17.6808498 },
    { lat: 47.8416842, lng: 17.6809709 },
    { lat: 47.8417333, lng: 17.6813363 },
    { lat: 47.8416856, lng: 17.6815299 },
    { lat: 47.8415888, lng: 17.6817121 },
    { lat: 47.8413429, lng: 17.6818426 },
    { lat: 47.8412341, lng: 17.6819779 },
    { lat: 47.841212, lng: 17.6821462 },
    { lat: 47.8413367, lng: 17.6826184 },
    { lat: 47.8413684, lng: 17.6830343 },
    { lat: 47.8411898, lng: 17.6837441 },
    { lat: 47.8411689, lng: 17.6843993 },
    { lat: 47.8410795, lng: 17.6845895 },
    { lat: 47.8406372, lng: 17.6848665 },
    { lat: 47.8400615, lng: 17.6853677 },
    { lat: 47.8397107, lng: 17.6854435 },
    { lat: 47.8390228, lng: 17.685352 },
    { lat: 47.838675, lng: 17.685439 },
    { lat: 47.8383619, lng: 17.6856846 },
    { lat: 47.8381124, lng: 17.6861143 },
    { lat: 47.8377905, lng: 17.6870791 },
    { lat: 47.8376792, lng: 17.687555 },
    { lat: 47.8373974, lng: 17.6875429 },
    { lat: 47.8362515, lng: 17.6878129 },
    { lat: 47.8353474, lng: 17.6878196 },
    { lat: 47.8346947, lng: 17.6874929 },
    { lat: 47.833563, lng: 17.6870945 },
    { lat: 47.8328168, lng: 17.6871329 },
    { lat: 47.8324146, lng: 17.6873062 },
    { lat: 47.8319332, lng: 17.6853869 },
    { lat: 47.8318199, lng: 17.6851092 },
    { lat: 47.8314416, lng: 17.6844837 },
    { lat: 47.830799, lng: 17.6836206 },
    { lat: 47.828976, lng: 17.6819629 },
    { lat: 47.8287451, lng: 17.6816339 },
    { lat: 47.8285295, lng: 17.6812061 },
    { lat: 47.8242298, lng: 17.6846731 },
    { lat: 47.8235601, lng: 17.6839354 },
    { lat: 47.8229978, lng: 17.6827756 },
    { lat: 47.8223357, lng: 17.682462 },
    { lat: 47.8217552, lng: 17.6824606 },
    { lat: 47.8205286, lng: 17.6832046 },
    { lat: 47.819779, lng: 17.6835271 },
    { lat: 47.8194246, lng: 17.6834397 },
    { lat: 47.8191162, lng: 17.6828465 },
    { lat: 47.8189971, lng: 17.6821404 },
    { lat: 47.8189952, lng: 17.6820761 },
    { lat: 47.8183271, lng: 17.681849 },
    { lat: 47.818002, lng: 17.6816688 },
    { lat: 47.8175706, lng: 17.6811875 },
    { lat: 47.8168707, lng: 17.6805982 },
    { lat: 47.8156948, lng: 17.6800512 },
    { lat: 47.8155319, lng: 17.6806194 },
    { lat: 47.8148429, lng: 17.6822601 },
    { lat: 47.8125156, lng: 17.6884285 },
    { lat: 47.8123641, lng: 17.6886038 },
    { lat: 47.8118073, lng: 17.6889243 },
    { lat: 47.8116092, lng: 17.6891481 },
    { lat: 47.8113971, lng: 17.6894551 },
    { lat: 47.8108515, lng: 17.6906802 },
    { lat: 47.8106544, lng: 17.6911227 },
    { lat: 47.8106448, lng: 17.6911517 },
    { lat: 47.8106194, lng: 17.6912515 },
    { lat: 47.8107883, lng: 17.6929094 },
    { lat: 47.8108277, lng: 17.6937486 },
    { lat: 47.8107981, lng: 17.6951404 },
    { lat: 47.810162, lng: 17.6966402 },
    { lat: 47.8097427, lng: 17.6964881 },
    { lat: 47.8091713, lng: 17.696507 },
    { lat: 47.8083602, lng: 17.6966597 },
    { lat: 47.8078819, lng: 17.6962713 },
    { lat: 47.807271, lng: 17.695554 },
    { lat: 47.8067716, lng: 17.6948482 },
    { lat: 47.8063146, lng: 17.6943368 },
    { lat: 47.8059964, lng: 17.6941748 },
    { lat: 47.8053331, lng: 17.6942027 },
    { lat: 47.8041112, lng: 17.6944679 },
    { lat: 47.8022656, lng: 17.6940823 },
    { lat: 47.8008954, lng: 17.6942491 },
    { lat: 47.8006502, lng: 17.6943 },
    { lat: 47.7998433, lng: 17.6947523 },
    { lat: 47.7993037, lng: 17.695132 },
    { lat: 47.7989619, lng: 17.6954937 },
    { lat: 47.7986852, lng: 17.6959051 },
    { lat: 47.7984444, lng: 17.6964536 },
    { lat: 47.7982353, lng: 17.6971343 },
    { lat: 47.7978218, lng: 17.6988666 },
    { lat: 47.7995246, lng: 17.6997824 },
    { lat: 47.8006556, lng: 17.7004753 },
    { lat: 47.8006682, lng: 17.7010357 },
    { lat: 47.8009233, lng: 17.7013564 },
    { lat: 47.8013082, lng: 17.7016608 },
    { lat: 47.8016334, lng: 17.701391 },
    { lat: 47.8024848, lng: 17.7016792 },
    { lat: 47.8035446, lng: 17.7018917 },
    { lat: 47.8044902, lng: 17.7023488 },
    { lat: 47.8047001, lng: 17.7029549 },
    { lat: 47.8059512, lng: 17.7033152 },
    { lat: 47.8060448, lng: 17.7034237 },
    { lat: 47.8062527, lng: 17.7038356 },
    { lat: 47.8064161, lng: 17.7043868 },
    { lat: 47.8065136, lng: 17.7053454 },
    { lat: 47.8068819, lng: 17.7077688 },
    { lat: 47.806635, lng: 17.7096257 },
    { lat: 47.806596, lng: 17.7108712 },
    { lat: 47.8066692, lng: 17.7114258 },
    { lat: 47.8068563, lng: 17.7119403 },
    { lat: 47.806618, lng: 17.7123874 },
    { lat: 47.8069001, lng: 17.7127086 },
    { lat: 47.8076631, lng: 17.714341 },
    { lat: 47.8079256, lng: 17.7146565 },
    { lat: 47.8081828, lng: 17.7148288 },
    { lat: 47.8092183, lng: 17.7152273 },
    { lat: 47.8096219, lng: 17.7154717 },
    { lat: 47.8093143, lng: 17.7179794 },
    { lat: 47.8089987, lng: 17.7214565 },
    { lat: 47.8084426, lng: 17.7262286 },
    { lat: 47.8086089, lng: 17.7284115 },
    { lat: 47.805521, lng: 17.73195 },
    { lat: 47.805595, lng: 17.732038 },
    { lat: 47.80611, lng: 17.73255 },
    { lat: 47.806391, lng: 17.73288 },
    { lat: 47.806499, lng: 17.733121 },
    { lat: 47.806486, lng: 17.733518 },
    { lat: 47.80648, lng: 17.734182 },
    { lat: 47.806443, lng: 17.734706 },
    { lat: 47.806437, lng: 17.734955 },
    { lat: 47.806464, lng: 17.735349 },
    { lat: 47.806489, lng: 17.735463 },
    { lat: 47.806521, lng: 17.735547 },
    { lat: 47.806662, lng: 17.735814 },
    { lat: 47.807009, lng: 17.736287 },
    { lat: 47.807749, lng: 17.736724 },
    { lat: 47.808148, lng: 17.73691 },
    { lat: 47.80821, lng: 17.736949 },
    { lat: 47.808361, lng: 17.737064 },
    { lat: 47.808562, lng: 17.737276 },
    { lat: 47.808812, lng: 17.737634 },
    { lat: 47.8093975, lng: 17.7385611 },
    { lat: 47.8100725, lng: 17.7393695 },
    { lat: 47.8107886, lng: 17.7403667 },
    { lat: 47.8116727, lng: 17.7411783 },
    { lat: 47.8125554, lng: 17.7421688 },
    { lat: 47.8128326, lng: 17.7423574 },
    { lat: 47.8129391, lng: 17.7423999 },
    { lat: 47.8141992, lng: 17.742132 },
    { lat: 47.8155324, lng: 17.7426505 },
    { lat: 47.8169434, lng: 17.7425949 },
    { lat: 47.8175308, lng: 17.7429044 },
    { lat: 47.8186518, lng: 17.743949 },
    { lat: 47.8193719, lng: 17.7447952 },
    { lat: 47.8197179, lng: 17.7450015 },
    { lat: 47.8200741, lng: 17.745011 },
    { lat: 47.8203388, lng: 17.7448403 },
    { lat: 47.8204477, lng: 17.7446862 },
    { lat: 47.8205939, lng: 17.7442452 },
    { lat: 47.8206227, lng: 17.7437821 },
    { lat: 47.8204312, lng: 17.7429988 },
    { lat: 47.8204448, lng: 17.7426373 },
    { lat: 47.8201544, lng: 17.7415315 },
    { lat: 47.8199877, lng: 17.7392136 },
    { lat: 47.8200578, lng: 17.7373029 },
    { lat: 47.8201791, lng: 17.7360331 },
    { lat: 47.8203085, lng: 17.7351227 },
    { lat: 47.8204774, lng: 17.7345756 },
    { lat: 47.8207581, lng: 17.7339576 },
    { lat: 47.8209362, lng: 17.7337903 },
    { lat: 47.8211026, lng: 17.7337238 },
    { lat: 47.8212935, lng: 17.7337621 },
    { lat: 47.8216453, lng: 17.7340444 },
    { lat: 47.8220005, lng: 17.7342352 },
    { lat: 47.8227681, lng: 17.7341618 },
    { lat: 47.8233846, lng: 17.7332961 },
    { lat: 47.8240959, lng: 17.7324678 },
    { lat: 47.8249222, lng: 17.7312976 },
    { lat: 47.8251236, lng: 17.731144 },
    { lat: 47.8253365, lng: 17.7311163 },
    { lat: 47.8256796, lng: 17.7313471 },
    { lat: 47.8260324, lng: 17.7316989 },
    { lat: 47.8261583, lng: 17.7316892 },
    { lat: 47.8262059, lng: 17.7317462 },
    { lat: 47.8262007, lng: 17.7318625 },
    { lat: 47.8263385, lng: 17.7321888 },
    { lat: 47.8264353, lng: 17.7325787 },
    { lat: 47.8264952, lng: 17.7329576 },
    { lat: 47.8265005, lng: 17.7336184 },
    { lat: 47.8264831, lng: 17.7339528 },
    { lat: 47.8264171, lng: 17.7341797 },
    { lat: 47.8264393, lng: 17.7344433 },
    { lat: 47.8263183, lng: 17.7358987 },
    { lat: 47.8263415, lng: 17.7366322 },
    { lat: 47.8266263, lng: 17.7388184 },
    { lat: 47.826861, lng: 17.7399527 },
    { lat: 47.8269192, lng: 17.7404471 },
    { lat: 47.8268852, lng: 17.7406798 },
    { lat: 47.827282, lng: 17.7411597 },
    { lat: 47.827762, lng: 17.7415572 },
    { lat: 47.8281492, lng: 17.7417367 },
    { lat: 47.8286433, lng: 17.7416401 },
    { lat: 47.828911, lng: 17.7413171 },
    { lat: 47.8289323, lng: 17.7406857 },
    { lat: 47.8288507, lng: 17.739508 },
    { lat: 47.8285449, lng: 17.7375821 },
    { lat: 47.8285555, lng: 17.7367582 },
    { lat: 47.8291475, lng: 17.7344749 },
    { lat: 47.8293553, lng: 17.7330714 },
    { lat: 47.8295148, lng: 17.7325891 },
    { lat: 47.8296786, lng: 17.7323554 },
    { lat: 47.8298778, lng: 17.7323109 },
    { lat: 47.83024, lng: 17.7325258 },
    { lat: 47.8306442, lng: 17.7329769 },
    { lat: 47.8311866, lng: 17.7334511 },
    { lat: 47.8318588, lng: 17.7339085 },
    { lat: 47.8323516, lng: 17.7340449 },
    { lat: 47.8327303, lng: 17.7340705 },
    { lat: 47.8336169, lng: 17.733947 },
    { lat: 47.8341099, lng: 17.7337088 },
    { lat: 47.834387, lng: 17.7334649 },
    { lat: 47.8346508, lng: 17.7331143 },
    { lat: 47.8350782, lng: 17.7321336 },
    { lat: 47.8355605, lng: 17.7306987 },
    { lat: 47.8360896, lng: 17.7296613 },
    { lat: 47.8364787, lng: 17.7291893 },
    { lat: 47.8370478, lng: 17.7289905 },
    { lat: 47.8371922, lng: 17.7290443 },
    { lat: 47.8381612, lng: 17.7305805 },
    { lat: 47.8388643, lng: 17.7313161 },
    { lat: 47.839329, lng: 17.7314673 },
    { lat: 47.8407031, lng: 17.7313627 },
    { lat: 47.8416785, lng: 17.7311617 },
    { lat: 47.841976, lng: 17.7309368 },
    { lat: 47.8422254, lng: 17.7305938 },
    { lat: 47.8422496, lng: 17.730078 },
    { lat: 47.8423706, lng: 17.7294533 },
    { lat: 47.8423342, lng: 17.7294317 },
    { lat: 47.8425343, lng: 17.7279023 },
    { lat: 47.8426582, lng: 17.7273946 },
    { lat: 47.8430767, lng: 17.7263453 },
    { lat: 47.8432074, lng: 17.7261396 },
    { lat: 47.8435838, lng: 17.7257712 },
    { lat: 47.8436276, lng: 17.7256235 },
    { lat: 47.8438568, lng: 17.725337 },
    { lat: 47.8443204, lng: 17.7248258 },
    { lat: 47.8451402, lng: 17.7229168 },
    { lat: 47.8455463, lng: 17.7225 },
    { lat: 47.8458344, lng: 17.7224133 },
    { lat: 47.8462132, lng: 17.722459 },
    { lat: 47.8463443, lng: 17.7225867 },
    { lat: 47.8463838, lng: 17.7227313 },
    { lat: 47.8463332, lng: 17.7228212 },
    { lat: 47.8463931, lng: 17.7229507 },
    { lat: 47.8465428, lng: 17.7231036 },
    { lat: 47.8472229, lng: 17.723525 },
    { lat: 47.8474179, lng: 17.7235367 },
    { lat: 47.8480307, lng: 17.7231724 },
    { lat: 47.8484054, lng: 17.7228665 },
    { lat: 47.849585, lng: 17.721602 },
    { lat: 47.8498925, lng: 17.7209791 },
    { lat: 47.8500359, lng: 17.7202791 },
    { lat: 47.8499454, lng: 17.7187075 },
    { lat: 47.8499548, lng: 17.7181944 },
    { lat: 47.8499904, lng: 17.7174458 },
    { lat: 47.8501905, lng: 17.7161232 },
    { lat: 47.8503443, lng: 17.7156407 },
    { lat: 47.8508107, lng: 17.7146893 },
    { lat: 47.8510356, lng: 17.7143154 },
    { lat: 47.8518656, lng: 17.7132383 },
    { lat: 47.8521922, lng: 17.7128858 },
    { lat: 47.8526829, lng: 17.7125175 },
    { lat: 47.8533127, lng: 17.7122801 },
    { lat: 47.8539684, lng: 17.7123678 },
    { lat: 47.8552272, lng: 17.7123662 },
    { lat: 47.8557764, lng: 17.7125719 },
    { lat: 47.8562964, lng: 17.7128865 },
    { lat: 47.8566381, lng: 17.7129892 },
    { lat: 47.8572964, lng: 17.7128255 },
    { lat: 47.8587968, lng: 17.7121059 },
    { lat: 47.8589724, lng: 17.711704 },
    { lat: 47.8591906, lng: 17.71097 },
    { lat: 47.8590758, lng: 17.7102958 },
    { lat: 47.8586734, lng: 17.7098366 },
    { lat: 47.8583334, lng: 17.7096036 },
    { lat: 47.8575614, lng: 17.7088684 },
    { lat: 47.8564941, lng: 17.7076603 },
    { lat: 47.855682, lng: 17.7069356 },
    { lat: 47.8554378, lng: 17.7059079 },
    { lat: 47.855408, lng: 17.7055419 },
    { lat: 47.8554558, lng: 17.7051883 },
    { lat: 47.8556529, lng: 17.7047866 },
    { lat: 47.855775, lng: 17.7046448 },
    { lat: 47.8566737, lng: 17.7040898 },
    { lat: 47.857675, lng: 17.7032772 },
    { lat: 47.8590558, lng: 17.7018758 },
    { lat: 47.8600589, lng: 17.7007204 },
    { lat: 47.8602882, lng: 17.7002878 },
    { lat: 47.8604848, lng: 17.6996639 },
    { lat: 47.8604806, lng: 17.6995253 },
    { lat: 47.8603517, lng: 17.699128 },
    { lat: 47.8601964, lng: 17.6987762 },
    { lat: 47.8597384, lng: 17.6982429 },
    { lat: 47.859247, lng: 17.6971651 },
    { lat: 47.8592686, lng: 17.6969146 },
    { lat: 47.8594123, lng: 17.6965811 },
    { lat: 47.860434, lng: 17.6955186 },
    { lat: 47.8606086, lng: 17.695263 },
    { lat: 47.861527, lng: 17.6946094 },
    { lat: 47.8619861, lng: 17.6945807 },
    { lat: 47.8623687, lng: 17.6947951 },
    { lat: 47.8627345, lng: 17.6951 },
    { lat: 47.8638211, lng: 17.6954482 },
    { lat: 47.8641051, lng: 17.6954948 },
    { lat: 47.8645882, lng: 17.6954379 },
    { lat: 47.8653414, lng: 17.6952066 },
    { lat: 47.8656274, lng: 17.6950139 },
  ],
  Lehnice: [
    { lat: 48.0868883, lng: 17.4941822 },
    { lat: 48.0866343, lng: 17.4929923 },
    { lat: 48.0865159, lng: 17.491978 },
    { lat: 48.0865228, lng: 17.4912808 },
    { lat: 48.0866583, lng: 17.4901798 },
    { lat: 48.0869548, lng: 17.4888917 },
    { lat: 48.0872078, lng: 17.4883859 },
    { lat: 48.0875941, lng: 17.4878963 },
    { lat: 48.0881318, lng: 17.4876865 },
    { lat: 48.0882942, lng: 17.4877214 },
    { lat: 48.0881398, lng: 17.4869192 },
    { lat: 48.087988, lng: 17.4860898 },
    { lat: 48.0867345, lng: 17.4874551 },
    { lat: 48.0852335, lng: 17.4881908 },
    { lat: 48.0838099, lng: 17.4903161 },
    { lat: 48.0820668, lng: 17.4920599 },
    { lat: 48.0814288, lng: 17.4926026 },
    { lat: 48.0807695, lng: 17.4929745 },
    { lat: 48.0792637, lng: 17.4914272 },
    { lat: 48.0792385, lng: 17.4914576 },
    { lat: 48.078461, lng: 17.4902616 },
    { lat: 48.0779979, lng: 17.4897335 },
    { lat: 48.0779554, lng: 17.4896873 },
    { lat: 48.0769406, lng: 17.4889796 },
    { lat: 48.0766354, lng: 17.4886954 },
    { lat: 48.076236, lng: 17.4884537 },
    { lat: 48.0754052, lng: 17.4881299 },
    { lat: 48.0743971, lng: 17.4854395 },
    { lat: 48.0737836, lng: 17.4822225 },
    { lat: 48.0735037, lng: 17.4805462 },
    { lat: 48.0727217, lng: 17.4763641 },
    { lat: 48.0725224, lng: 17.4760043 },
    { lat: 48.0732008, lng: 17.475425 },
    { lat: 48.0734905, lng: 17.4750662 },
    { lat: 48.073756, lng: 17.4746667 },
    { lat: 48.0739298, lng: 17.4742119 },
    { lat: 48.0743521, lng: 17.4733717 },
    { lat: 48.0743977, lng: 17.4723456 },
    { lat: 48.0746383, lng: 17.4714599 },
    { lat: 48.0746103, lng: 17.4704847 },
    { lat: 48.0745257, lng: 17.4698397 },
    { lat: 48.0740144, lng: 17.4679267 },
    { lat: 48.0739219, lng: 17.4673676 },
    { lat: 48.0738596, lng: 17.4665806 },
    { lat: 48.073952, lng: 17.4661248 },
    { lat: 48.0744846, lng: 17.465228 },
    { lat: 48.0746159, lng: 17.4646564 },
    { lat: 48.0745357, lng: 17.4641496 },
    { lat: 48.0741955, lng: 17.4631682 },
    { lat: 48.0741599, lng: 17.4628456 },
    { lat: 48.0743473, lng: 17.4616433 },
    { lat: 48.074724, lng: 17.4605027 },
    { lat: 48.0750779, lng: 17.4601246 },
    { lat: 48.0753571, lng: 17.4598315 },
    { lat: 48.0754259, lng: 17.4590623 },
    { lat: 48.0760782, lng: 17.4514795 },
    { lat: 48.0768225, lng: 17.4454583 },
    { lat: 48.0773319, lng: 17.4422432 },
    { lat: 48.0782543, lng: 17.4374217 },
    { lat: 48.0791068, lng: 17.4323668 },
    { lat: 48.0791584, lng: 17.4309543 },
    { lat: 48.0790961, lng: 17.4292663 },
    { lat: 48.0788371, lng: 17.4284466 },
    { lat: 48.0785645, lng: 17.4278331 },
    { lat: 48.0782124, lng: 17.4273176 },
    { lat: 48.0771277, lng: 17.4258704 },
    { lat: 48.0762774, lng: 17.4250567 },
    { lat: 48.0752899, lng: 17.4239762 },
    { lat: 48.073449, lng: 17.4224862 },
    { lat: 48.070583, lng: 17.4203092 },
    { lat: 48.0664195, lng: 17.4172575 },
    { lat: 48.0663179, lng: 17.4171695 },
    { lat: 48.066431, lng: 17.41648 },
    { lat: 48.0661756, lng: 17.4163416 },
    { lat: 48.0624326, lng: 17.4144006 },
    { lat: 48.0622179, lng: 17.4150213 },
    { lat: 48.061729, lng: 17.4164259 },
    { lat: 48.0611948, lng: 17.4183856 },
    { lat: 48.0596586, lng: 17.4225415 },
    { lat: 48.0598487, lng: 17.4231897 },
    { lat: 48.0598875, lng: 17.4234217 },
    { lat: 48.0598711, lng: 17.4236387 },
    { lat: 48.0597086, lng: 17.423985 },
    { lat: 48.0591984, lng: 17.4246333 },
    { lat: 48.0589947, lng: 17.425113 },
    { lat: 48.0585007, lng: 17.4265954 },
    { lat: 48.0583289, lng: 17.4272723 },
    { lat: 48.0581019, lng: 17.4276717 },
    { lat: 48.0578156, lng: 17.4279052 },
    { lat: 48.0575108, lng: 17.427978 },
    { lat: 48.0570366, lng: 17.4278236 },
    { lat: 48.0568626, lng: 17.427841 },
    { lat: 48.0566967, lng: 17.4281171 },
    { lat: 48.0559544, lng: 17.4298503 },
    { lat: 48.0557592, lng: 17.4301314 },
    { lat: 48.0551578, lng: 17.4304356 },
    { lat: 48.0544063, lng: 17.4306895 },
    { lat: 48.0541413, lng: 17.4308331 },
    { lat: 48.0540276, lng: 17.4309625 },
    { lat: 48.0538997, lng: 17.4311999 },
    { lat: 48.0536682, lng: 17.4319998 },
    { lat: 48.0536181, lng: 17.43227 },
    { lat: 48.0535905, lng: 17.4330605 },
    { lat: 48.0535423, lng: 17.4333093 },
    { lat: 48.0533806, lng: 17.4336547 },
    { lat: 48.0528359, lng: 17.4328482 },
    { lat: 48.0522428, lng: 17.4322736 },
    { lat: 48.0514781, lng: 17.4313896 },
    { lat: 48.0504171, lng: 17.4297317 },
    { lat: 48.0500769, lng: 17.4294188 },
    { lat: 48.0492686, lng: 17.4301701 },
    { lat: 48.048927, lng: 17.4311183 },
    { lat: 48.0484187, lng: 17.4319472 },
    { lat: 48.0482661, lng: 17.4320872 },
    { lat: 48.047469, lng: 17.4311162 },
    { lat: 48.0471284, lng: 17.4309153 },
    { lat: 48.0469834, lng: 17.4308773 },
    { lat: 48.0468826, lng: 17.4309576 },
    { lat: 48.0468059, lng: 17.4311321 },
    { lat: 48.0467424, lng: 17.4317191 },
    { lat: 48.046467, lng: 17.4322079 },
    { lat: 48.0461091, lng: 17.4330066 },
    { lat: 48.0458547, lng: 17.4334225 },
    { lat: 48.0450188, lng: 17.434357 },
    { lat: 48.0448924, lng: 17.4344956 },
    { lat: 48.0448224, lng: 17.4346194 },
    { lat: 48.0447126, lng: 17.4351365 },
    { lat: 48.0447089, lng: 17.4357014 },
    { lat: 48.044546, lng: 17.4364979 },
    { lat: 48.0443968, lng: 17.4380926 },
    { lat: 48.0442532, lng: 17.4384395 },
    { lat: 48.0436692, lng: 17.4394973 },
    { lat: 48.0434042, lng: 17.4398152 },
    { lat: 48.0430929, lng: 17.4400832 },
    { lat: 48.0421885, lng: 17.4405913 },
    { lat: 48.0421463, lng: 17.4406016 },
    { lat: 48.0417372, lng: 17.4407003 },
    { lat: 48.0413204, lng: 17.4406497 },
    { lat: 48.0406154, lng: 17.44068 },
    { lat: 48.0402986, lng: 17.4408123 },
    { lat: 48.0395809, lng: 17.4414797 },
    { lat: 48.0388816, lng: 17.4418779 },
    { lat: 48.0387478, lng: 17.4420253 },
    { lat: 48.0385513, lng: 17.4426685 },
    { lat: 48.0384171, lng: 17.4428806 },
    { lat: 48.0380366, lng: 17.4432126 },
    { lat: 48.0359693, lng: 17.4397147 },
    { lat: 48.034454, lng: 17.4413872 },
    { lat: 48.0324254, lng: 17.4436243 },
    { lat: 48.0320303, lng: 17.4429154 },
    { lat: 48.0317851, lng: 17.4426534 },
    { lat: 48.0314804, lng: 17.4421021 },
    { lat: 48.0312272, lng: 17.4418737 },
    { lat: 48.0309834, lng: 17.441753 },
    { lat: 48.0308701, lng: 17.4418008 },
    { lat: 48.0304509, lng: 17.442198 },
    { lat: 48.0303412, lng: 17.4423663 },
    { lat: 48.0302694, lng: 17.4426014 },
    { lat: 48.0301007, lng: 17.4438297 },
    { lat: 48.0298735, lng: 17.4442768 },
    { lat: 48.0295606, lng: 17.4447471 },
    { lat: 48.0292772, lng: 17.4449885 },
    { lat: 48.0288616, lng: 17.4450947 },
    { lat: 48.0279558, lng: 17.4448855 },
    { lat: 48.0278968, lng: 17.4449176 },
    { lat: 48.0274852, lng: 17.4455688 },
    { lat: 48.0272439, lng: 17.4460796 },
    { lat: 48.0269944, lng: 17.4464296 },
    { lat: 48.0264457, lng: 17.4468133 },
    { lat: 48.0261766, lng: 17.4470872 },
    { lat: 48.0257318, lng: 17.4472121 },
    { lat: 48.0251435, lng: 17.4476834 },
    { lat: 48.0248295, lng: 17.4478046 },
    { lat: 48.0246146, lng: 17.4478508 },
    { lat: 48.0246219, lng: 17.4479827 },
    { lat: 48.024631, lng: 17.448312 },
    { lat: 48.0245827, lng: 17.4485952 },
    { lat: 48.024172, lng: 17.4500812 },
    { lat: 48.0238799, lng: 17.4508339 },
    { lat: 48.0234928, lng: 17.45148 },
    { lat: 48.0229993, lng: 17.4518803 },
    { lat: 48.022437, lng: 17.4519908 },
    { lat: 48.0212593, lng: 17.4515917 },
    { lat: 48.0207699, lng: 17.4513593 },
    { lat: 48.0204899, lng: 17.452579 },
    { lat: 48.0203022, lng: 17.4530463 },
    { lat: 48.0193851, lng: 17.45454 },
    { lat: 48.0193508, lng: 17.4545876 },
    { lat: 48.0189597, lng: 17.4543561 },
    { lat: 48.0186275, lng: 17.4546676 },
    { lat: 48.0176716, lng: 17.4559141 },
    { lat: 48.0177018, lng: 17.4559602 },
    { lat: 48.0197544, lng: 17.458717 },
    { lat: 48.0198822, lng: 17.4585815 },
    { lat: 48.0201599, lng: 17.4581788 },
    { lat: 48.0217259, lng: 17.4554388 },
    { lat: 48.0228226, lng: 17.4567443 },
    { lat: 48.0239015, lng: 17.4577437 },
    { lat: 48.0246387, lng: 17.4582753 },
    { lat: 48.0259733, lng: 17.4590714 },
    { lat: 48.0243142, lng: 17.4624487 },
    { lat: 48.024427, lng: 17.4627793 },
    { lat: 48.0241815, lng: 17.4631396 },
    { lat: 48.0234956, lng: 17.4639161 },
    { lat: 48.0214469, lng: 17.467558 },
    { lat: 48.0203842, lng: 17.4691277 },
    { lat: 48.0210242, lng: 17.4709687 },
    { lat: 48.0206884, lng: 17.473667 },
    { lat: 48.0205982, lng: 17.4744889 },
    { lat: 48.0220474, lng: 17.474527 },
    { lat: 48.0236174, lng: 17.4743375 },
    { lat: 48.0241632, lng: 17.4741838 },
    { lat: 48.0246851, lng: 17.4742399 },
    { lat: 48.0248399, lng: 17.4730199 },
    { lat: 48.0251045, lng: 17.471576 },
    { lat: 48.0262726, lng: 17.4720175 },
    { lat: 48.0279518, lng: 17.4721907 },
    { lat: 48.0285541, lng: 17.4723701 },
    { lat: 48.0291233, lng: 17.4728238 },
    { lat: 48.0295372, lng: 17.4738435 },
    { lat: 48.0306343, lng: 17.4752134 },
    { lat: 48.0316251, lng: 17.4761773 },
    { lat: 48.0321856, lng: 17.4766538 },
    { lat: 48.0329765, lng: 17.4772161 },
    { lat: 48.0335937, lng: 17.4776266 },
    { lat: 48.0355252, lng: 17.4786456 },
    { lat: 48.0368292, lng: 17.4796148 },
    { lat: 48.0380017, lng: 17.4806664 },
    { lat: 48.0408376, lng: 17.4824671 },
    { lat: 48.0414839, lng: 17.4829568 },
    { lat: 48.0425083, lng: 17.4828904 },
    { lat: 48.0441169, lng: 17.4833516 },
    { lat: 48.0462589, lng: 17.4847254 },
    { lat: 48.0469374, lng: 17.4849815 },
    { lat: 48.0496019, lng: 17.4862628 },
    { lat: 48.0506733, lng: 17.4869054 },
    { lat: 48.0544125, lng: 17.4896325 },
    { lat: 48.0632294, lng: 17.4962811 },
    { lat: 48.0650012, lng: 17.497539 },
    { lat: 48.0654865, lng: 17.49778 },
    { lat: 48.0672539, lng: 17.4957489 },
    { lat: 48.0691057, lng: 17.4944485 },
    { lat: 48.0694947, lng: 17.4949283 },
    { lat: 48.0734532, lng: 17.4997979 },
    { lat: 48.075008, lng: 17.5019509 },
    { lat: 48.0780658, lng: 17.506712 },
    { lat: 48.0782683, lng: 17.5074348 },
    { lat: 48.0787122, lng: 17.506787 },
    { lat: 48.080623, lng: 17.5098398 },
    { lat: 48.085028, lng: 17.5033405 },
    { lat: 48.0841833, lng: 17.501837 },
    { lat: 48.0858793, lng: 17.4989919 },
    { lat: 48.0859355, lng: 17.4980577 },
    { lat: 48.0860343, lng: 17.4974721 },
    { lat: 48.0867655, lng: 17.4952725 },
    { lat: 48.0869178, lng: 17.4944626 },
    { lat: 48.0868883, lng: 17.4941822 },
  ],
  Šamorín: [
    { lat: 47.9928982, lng: 17.3350585 },
    { lat: 47.992273, lng: 17.336182 },
    { lat: 47.989723, lng: 17.339563 },
    { lat: 47.987422, lng: 17.342357 },
    { lat: 47.986837, lng: 17.343001 },
    { lat: 47.985625, lng: 17.344196 },
    { lat: 47.982885, lng: 17.346507 },
    { lat: 47.9882568, lng: 17.3481318 },
    { lat: 47.9888465, lng: 17.3487684 },
    { lat: 47.9926558, lng: 17.3514473 },
    { lat: 47.9932905, lng: 17.3518231 },
    { lat: 47.9950143, lng: 17.3525303 },
    { lat: 47.9959119, lng: 17.3532422 },
    { lat: 47.997424, lng: 17.3547132 },
    { lat: 47.9984543, lng: 17.3554369 },
    { lat: 47.9992011, lng: 17.35616 },
    { lat: 48.0024008, lng: 17.3583002 },
    { lat: 48.0031769, lng: 17.3593331 },
    { lat: 48.0037439, lng: 17.3598132 },
    { lat: 48.0045418, lng: 17.3601117 },
    { lat: 48.00471, lng: 17.3600417 },
    { lat: 48.0053682, lng: 17.3597787 },
    { lat: 48.006088, lng: 17.3598094 },
    { lat: 48.007044, lng: 17.3606643 },
    { lat: 48.0071855, lng: 17.3608945 },
    { lat: 48.0091229, lng: 17.3629427 },
    { lat: 48.0102235, lng: 17.3635657 },
    { lat: 48.0113117, lng: 17.3643984 },
    { lat: 48.0119663, lng: 17.3647321 },
    { lat: 48.0130538, lng: 17.3651085 },
    { lat: 48.0141133, lng: 17.36322 },
    { lat: 48.0143401, lng: 17.3626489 },
    { lat: 48.0153627, lng: 17.3642151 },
    { lat: 48.0155545, lng: 17.3645229 },
    { lat: 48.0177745, lng: 17.366869 },
    { lat: 48.0180733, lng: 17.3672876 },
    { lat: 48.0198624, lng: 17.3691796 },
    { lat: 48.0216448, lng: 17.3712103 },
    { lat: 48.0217, lng: 17.3713613 },
    { lat: 48.0219986, lng: 17.3711618 },
    { lat: 48.0222722, lng: 17.370978 },
    { lat: 48.0229438, lng: 17.3708817 },
    { lat: 48.0232815, lng: 17.3706758 },
    { lat: 48.0239807, lng: 17.3700241 },
    { lat: 48.0245932, lng: 17.3697706 },
    { lat: 48.0258081, lng: 17.369727 },
    { lat: 48.0261135, lng: 17.3694079 },
    { lat: 48.0262282, lng: 17.3690923 },
    { lat: 48.0262571, lng: 17.3688087 },
    { lat: 48.0261565, lng: 17.368293 },
    { lat: 48.0269655, lng: 17.3673482 },
    { lat: 48.0270687, lng: 17.3677523 },
    { lat: 48.0279296, lng: 17.3697308 },
    { lat: 48.0288845, lng: 17.3714587 },
    { lat: 48.0291217, lng: 17.3714594 },
    { lat: 48.0308723, lng: 17.372643 },
    { lat: 48.03238, lng: 17.3733275 },
    { lat: 48.0330221, lng: 17.3734568 },
    { lat: 48.0337092, lng: 17.3734289 },
    { lat: 48.0345635, lng: 17.373227 },
    { lat: 48.0350315, lng: 17.3729583 },
    { lat: 48.0350176, lng: 17.370803 },
    { lat: 48.0352271, lng: 17.3670252 },
    { lat: 48.0353286, lng: 17.3665237 },
    { lat: 48.0355638, lng: 17.3660151 },
    { lat: 48.0363378, lng: 17.3632167 },
    { lat: 48.0367249, lng: 17.3626449 },
    { lat: 48.0372352, lng: 17.3613852 },
    { lat: 48.0373308, lng: 17.3609487 },
    { lat: 48.0387443, lng: 17.3605946 },
    { lat: 48.0402807, lng: 17.3600845 },
    { lat: 48.0418851, lng: 17.3597289 },
    { lat: 48.0420817, lng: 17.3593352 },
    { lat: 48.0428885, lng: 17.3585495 },
    { lat: 48.0441741, lng: 17.3602171 },
    { lat: 48.0442392, lng: 17.3602976 },
    { lat: 48.0469461, lng: 17.3564235 },
    { lat: 48.0446404, lng: 17.3539298 },
    { lat: 48.040555, lng: 17.3497615 },
    { lat: 48.0409066, lng: 17.348857 },
    { lat: 48.0410096, lng: 17.3480191 },
    { lat: 48.0409549, lng: 17.347671 },
    { lat: 48.0404207, lng: 17.3468125 },
    { lat: 48.0396828, lng: 17.3460382 },
    { lat: 48.0390762, lng: 17.3456932 },
    { lat: 48.0377615, lng: 17.3437921 },
    { lat: 48.037166, lng: 17.3430341 },
    { lat: 48.036809, lng: 17.3424511 },
    { lat: 48.0362095, lng: 17.3416337 },
    { lat: 48.0359754, lng: 17.341199 },
    { lat: 48.0358315, lng: 17.3407291 },
    { lat: 48.0357705, lng: 17.3396846 },
    { lat: 48.0354594, lng: 17.3373832 },
    { lat: 48.035193, lng: 17.3362904 },
    { lat: 48.0350979, lng: 17.3361152 },
    { lat: 48.0350802, lng: 17.3360839 },
    { lat: 48.0350913, lng: 17.3360654 },
    { lat: 48.0377753, lng: 17.3332785 },
    { lat: 48.0377958, lng: 17.3333125 },
    { lat: 48.0379055, lng: 17.3332276 },
    { lat: 48.0396998, lng: 17.330446 },
    { lat: 48.0401688, lng: 17.3296293 },
    { lat: 48.0406908, lng: 17.328569 },
    { lat: 48.0407712, lng: 17.3286938 },
    { lat: 48.041609, lng: 17.3273537 },
    { lat: 48.0427904, lng: 17.3256406 },
    { lat: 48.0431022, lng: 17.3259647 },
    { lat: 48.0438402, lng: 17.3247419 },
    { lat: 48.0455658, lng: 17.3237606 },
    { lat: 48.0508417, lng: 17.3167098 },
    { lat: 48.0527831, lng: 17.3139197 },
    { lat: 48.0554544, lng: 17.3096809 },
    { lat: 48.0570719, lng: 17.3111658 },
    { lat: 48.0584116, lng: 17.3128362 },
    { lat: 48.059949, lng: 17.314056 },
    { lat: 48.060021, lng: 17.313816 },
    { lat: 48.06053, lng: 17.312783 },
    { lat: 48.061911, lng: 17.311217 },
    { lat: 48.063377, lng: 17.310396 },
    { lat: 48.064686, lng: 17.309112 },
    { lat: 48.065529, lng: 17.306532 },
    { lat: 48.066201, lng: 17.305391 },
    { lat: 48.067036, lng: 17.304157 },
    { lat: 48.06845, lng: 17.30253 },
    { lat: 48.067631, lng: 17.300467 },
    { lat: 48.066763, lng: 17.298491 },
    { lat: 48.0661604, lng: 17.2965011 },
    { lat: 48.064965, lng: 17.2973 },
    { lat: 48.063623, lng: 17.298067 },
    { lat: 48.06215, lng: 17.298743 },
    { lat: 48.060542, lng: 17.298572 },
    { lat: 48.059099, lng: 17.298631 },
    { lat: 48.058566, lng: 17.298466 },
    { lat: 48.057364, lng: 17.298831 },
    { lat: 48.05613, lng: 17.299615 },
    { lat: 48.054708, lng: 17.300646 },
    { lat: 48.054141, lng: 17.301185 },
    { lat: 48.053121, lng: 17.3017 },
    { lat: 48.051881, lng: 17.302833 },
    { lat: 48.050528, lng: 17.304681 },
    { lat: 48.050155, lng: 17.305405 },
    { lat: 48.049786, lng: 17.306407 },
    { lat: 48.047994, lng: 17.304398 },
    { lat: 48.047373, lng: 17.304102 },
    { lat: 48.046273, lng: 17.303009 },
    { lat: 48.0449, lng: 17.301448 },
    { lat: 48.045422, lng: 17.300345 },
    { lat: 48.045909, lng: 17.299587 },
    { lat: 48.04486, lng: 17.295538 },
    { lat: 48.042792, lng: 17.281716 },
    { lat: 48.035529, lng: 17.276492 },
    { lat: 48.0298237, lng: 17.270495 },
    { lat: 48.0296374, lng: 17.2702992 },
    { lat: 48.0294952, lng: 17.2701498 },
    { lat: 48.0293638, lng: 17.2700117 },
    { lat: 48.0292356, lng: 17.2698769 },
    { lat: 48.028641, lng: 17.269252 },
    { lat: 48.0284522, lng: 17.2686814 },
    { lat: 48.026083, lng: 17.261522 },
    { lat: 48.029042, lng: 17.258899 },
    { lat: 48.029118, lng: 17.254935 },
    { lat: 48.028918, lng: 17.253501 },
    { lat: 48.028109, lng: 17.253473 },
    { lat: 48.022608, lng: 17.258852 },
    { lat: 48.012391, lng: 17.248785 },
    { lat: 48.012014, lng: 17.24743 },
    { lat: 48.008684, lng: 17.247638 },
    { lat: 48.006626, lng: 17.247506 },
    { lat: 48.006148, lng: 17.247527 },
    { lat: 48.005201, lng: 17.247724 },
    { lat: 48.004738, lng: 17.2479 },
    { lat: 48.003843, lng: 17.2484 },
    { lat: 48.003416, lng: 17.248722 },
    { lat: 48.002617, lng: 17.249502 },
    { lat: 48.001574, lng: 17.250878 },
    { lat: 48.000964, lng: 17.251922 },
    { lat: 48.000429, lng: 17.253054 },
    { lat: 47.999133, lng: 17.256511 },
    { lat: 47.998655, lng: 17.257955 },
    { lat: 47.998252, lng: 17.259449 },
    { lat: 47.997924, lng: 17.260983 },
    { lat: 47.997425, lng: 17.264183 },
    { lat: 47.997089, lng: 17.265782 },
    { lat: 47.996597, lng: 17.267903 },
    { lat: 47.995581, lng: 17.271653 },
    { lat: 47.994896, lng: 17.27502 },
    { lat: 47.994299, lng: 17.277251 },
    { lat: 47.994048, lng: 17.278672 },
    { lat: 47.993973, lng: 17.279398 },
    { lat: 47.993926, lng: 17.280555 },
    { lat: 47.993923, lng: 17.280865 },
    { lat: 47.994007, lng: 17.284381 },
    { lat: 47.99427, lng: 17.288728 },
    { lat: 47.994629, lng: 17.291209 },
    { lat: 47.995373, lng: 17.294484 },
    { lat: 47.995773, lng: 17.29706 },
    { lat: 47.99592, lng: 17.29881 },
    { lat: 47.996052, lng: 17.303347 },
    { lat: 47.996061, lng: 17.306303 },
    { lat: 47.995915, lng: 17.310731 },
    { lat: 47.995757, lng: 17.312273 },
    { lat: 47.995229, lng: 17.314926 },
    { lat: 47.995048, lng: 17.316717 },
    { lat: 47.995073, lng: 17.318528 },
    { lat: 47.995366, lng: 17.322793 },
    { lat: 47.995346, lng: 17.324128 },
    { lat: 47.995214, lng: 17.325448 },
    { lat: 47.994619, lng: 17.329267 },
    { lat: 47.994211, lng: 17.331312 },
    { lat: 47.99396, lng: 17.33231 },
    { lat: 47.993589, lng: 17.333517 },
    { lat: 47.993125, lng: 17.334651 },
    { lat: 47.9928982, lng: 17.3350585 },
  ],
  OrechováPotôň: [
    { lat: 48.08496, lng: 17.65438 },
    { lat: 48.085004, lng: 17.654252 },
    { lat: 48.085066, lng: 17.653997 },
    { lat: 48.085227, lng: 17.653449 },
    { lat: 48.085419, lng: 17.653119 },
    { lat: 48.085513, lng: 17.652981 },
    { lat: 48.085712, lng: 17.652697 },
    { lat: 48.086077, lng: 17.652297 },
    { lat: 48.086118, lng: 17.652253 },
    { lat: 48.086182, lng: 17.652184 },
    { lat: 48.086219, lng: 17.652143 },
    { lat: 48.086258, lng: 17.652105 },
    { lat: 48.086277, lng: 17.652087 },
    { lat: 48.08631, lng: 17.652052 },
    { lat: 48.086322, lng: 17.652035 },
    { lat: 48.08657, lng: 17.651772 },
    { lat: 48.086954, lng: 17.651384 },
    { lat: 48.087201, lng: 17.651117 },
    { lat: 48.087606, lng: 17.650687 },
    { lat: 48.0875626, lng: 17.6506837 },
    { lat: 48.0867595, lng: 17.6510216 },
    { lat: 48.0860502, lng: 17.6515729 },
    { lat: 48.0859096, lng: 17.6517788 },
    { lat: 48.0858122, lng: 17.6516495 },
    { lat: 48.084778, lng: 17.6501464 },
    { lat: 48.0843847, lng: 17.6504446 },
    { lat: 48.0841374, lng: 17.6508946 },
    { lat: 48.0839426, lng: 17.651778 },
    { lat: 48.0839091, lng: 17.6525716 },
    { lat: 48.0839364, lng: 17.6532171 },
    { lat: 48.08496, lng: 17.65438 },
  ],
  OrechováPotôňExt: [
    { lat: 48.0868698, lng: 17.6630168 },
    { lat: 48.0867865, lng: 17.6630023 },
    { lat: 48.0858979, lng: 17.6627901 },
    { lat: 48.0856078, lng: 17.6626472 },
    { lat: 48.0845258, lng: 17.6615839 },
    { lat: 48.0841913, lng: 17.6615818 },
    { lat: 48.0836997, lng: 17.6614654 },
    { lat: 48.0833736, lng: 17.6615221 },
    { lat: 48.0827595, lng: 17.6611475 },
    { lat: 48.0824708, lng: 17.6610457 },
    { lat: 48.0822879, lng: 17.661084 },
    { lat: 48.0811095, lng: 17.6616975 },
    { lat: 48.0789777, lng: 17.6634559 },
    { lat: 48.0785808, lng: 17.6640021 },
    { lat: 48.0777595, lng: 17.6659299 },
    { lat: 48.0776717, lng: 17.6663306 },
    { lat: 48.077797, lng: 17.667059 },
    { lat: 48.077825, lng: 17.667056 },
    { lat: 48.078126, lng: 17.666951 },
    { lat: 48.07828, lng: 17.666948 },
    { lat: 48.078458, lng: 17.666913 },
    { lat: 48.078573, lng: 17.666892 },
    { lat: 48.078941, lng: 17.666881 },
    { lat: 48.079468, lng: 17.66694 },
    { lat: 48.079555, lng: 17.666961 },
    { lat: 48.079659, lng: 17.667009 },
    { lat: 48.079781, lng: 17.667048 },
    { lat: 48.07987, lng: 17.667067 },
    { lat: 48.079973, lng: 17.667082 },
    { lat: 48.080284, lng: 17.667229 },
    { lat: 48.080338, lng: 17.667259 },
    { lat: 48.080656, lng: 17.667458 },
    { lat: 48.08082, lng: 17.667558 },
    { lat: 48.080945, lng: 17.667677 },
    { lat: 48.081001, lng: 17.667729 },
    { lat: 48.081292, lng: 17.667941 },
    { lat: 48.081485, lng: 17.668075 },
    { lat: 48.081544, lng: 17.668156 },
    { lat: 48.081685, lng: 17.668259 },
    { lat: 48.081804, lng: 17.668358 },
    { lat: 48.082119, lng: 17.668559 },
    { lat: 48.082557, lng: 17.668854 },
    { lat: 48.083028, lng: 17.669159 },
    { lat: 48.083508, lng: 17.669444 },
    { lat: 48.083692, lng: 17.669533 },
    { lat: 48.08394, lng: 17.669634 },
    { lat: 48.08424, lng: 17.669763 },
    { lat: 48.084361, lng: 17.669809 },
    { lat: 48.084624, lng: 17.669886 },
    { lat: 48.08485, lng: 17.669922 },
    { lat: 48.08498, lng: 17.669935 },
    { lat: 48.085055, lng: 17.669936 },
    { lat: 48.085409, lng: 17.669895 },
    { lat: 48.085597, lng: 17.669857 },
    { lat: 48.085847, lng: 17.669766 },
    { lat: 48.086031, lng: 17.669692 },
    { lat: 48.08617, lng: 17.669619 },
    { lat: 48.086378, lng: 17.669474 },
    { lat: 48.086546, lng: 17.669333 },
    { lat: 48.086898, lng: 17.668992 },
    { lat: 48.087066, lng: 17.668825 },
    { lat: 48.087148, lng: 17.668714 },
    { lat: 48.087259, lng: 17.66857 },
    { lat: 48.087336, lng: 17.668489 },
    { lat: 48.087554, lng: 17.668301 },
    { lat: 48.08776, lng: 17.668086 },
    { lat: 48.087948, lng: 17.667857 },
    { lat: 48.088063, lng: 17.667686 },
    { lat: 48.088156, lng: 17.667524 },
    { lat: 48.088269, lng: 17.667268 },
    { lat: 48.088343, lng: 17.667072 },
    { lat: 48.088447, lng: 17.666728 },
    { lat: 48.088515, lng: 17.66642 },
    { lat: 48.088441, lng: 17.666236 },
    { lat: 48.088416, lng: 17.666124 },
    { lat: 48.088094, lng: 17.665067 },
    { lat: 48.08788, lng: 17.664335 },
    { lat: 48.087767, lng: 17.66407 },
    { lat: 48.08735, lng: 17.663116 },
    { lat: 48.08701, lng: 17.662336 },
    { lat: 48.0868698, lng: 17.6630168 },
  ],
  OrechováPotôňExt1: [
    { lat: 48.08997, lng: 17.647463 },
    { lat: 48.089985, lng: 17.647437 },
    { lat: 48.090103, lng: 17.647231 },
    { lat: 48.090206, lng: 17.647038 },
    { lat: 48.09041, lng: 17.646619 },
    { lat: 48.090526, lng: 17.646379 },
    { lat: 48.090703, lng: 17.645984 },
    { lat: 48.090848, lng: 17.645658 },
    { lat: 48.091065, lng: 17.645149 },
    { lat: 48.091275, lng: 17.644645 },
    { lat: 48.09148, lng: 17.644103 },
    { lat: 48.091642, lng: 17.643634 },
    { lat: 48.091791, lng: 17.643217 },
    { lat: 48.091808, lng: 17.643165 },
    { lat: 48.092018, lng: 17.642519 },
    { lat: 48.092021, lng: 17.642507 },
    { lat: 48.092276, lng: 17.641733 },
    { lat: 48.092445, lng: 17.641043 },
    { lat: 48.092562, lng: 17.640587 },
    { lat: 48.092676, lng: 17.640042 },
    { lat: 48.092736, lng: 17.639738 },
    { lat: 48.092797, lng: 17.639452 },
    { lat: 48.092823, lng: 17.639297 },
    { lat: 48.092873, lng: 17.639032 },
    { lat: 48.092943, lng: 17.638688 },
    { lat: 48.093029, lng: 17.638256 },
    { lat: 48.093128, lng: 17.637713 },
    { lat: 48.093162, lng: 17.637513 },
    { lat: 48.093254, lng: 17.636891 },
    { lat: 48.093275, lng: 17.63678 },
    { lat: 48.093337, lng: 17.63635 },
    { lat: 48.093395, lng: 17.635973 },
    { lat: 48.093401, lng: 17.635935 },
    { lat: 48.093431, lng: 17.635623 },
    { lat: 48.093461, lng: 17.635281 },
    { lat: 48.093505, lng: 17.634785 },
    { lat: 48.093543, lng: 17.634466 },
    { lat: 48.09356, lng: 17.634154 },
    { lat: 48.093579, lng: 17.633934 },
    { lat: 48.0936, lng: 17.633612 },
    { lat: 48.093627, lng: 17.633129 },
    { lat: 48.093624, lng: 17.632668 },
    { lat: 48.093617, lng: 17.632268 },
    { lat: 48.093614, lng: 17.631952 },
    { lat: 48.093597, lng: 17.631502 },
    { lat: 48.093597, lng: 17.631476 },
    { lat: 48.093595, lng: 17.630867 },
    { lat: 48.093618, lng: 17.630038 },
    { lat: 48.093631, lng: 17.629507 },
    { lat: 48.093645, lng: 17.628905 },
    { lat: 48.093664, lng: 17.628286 },
    { lat: 48.093669, lng: 17.628154 },
    { lat: 48.093686, lng: 17.627783 },
    { lat: 48.093698, lng: 17.627429 },
    { lat: 48.093699, lng: 17.627397 },
    { lat: 48.093722, lng: 17.62699 },
    { lat: 48.093745, lng: 17.626626 },
    { lat: 48.093616, lng: 17.626048 },
    { lat: 48.09361, lng: 17.626017 },
    { lat: 48.093618, lng: 17.625602 },
    { lat: 48.09363, lng: 17.624931 },
    { lat: 48.093621, lng: 17.624024 },
    { lat: 48.093588, lng: 17.623619 },
    { lat: 48.093454, lng: 17.622702 },
    { lat: 48.093406, lng: 17.622301 },
    { lat: 48.093348, lng: 17.622009 },
    { lat: 48.093295, lng: 17.621691 },
    { lat: 48.093263, lng: 17.621632 },
    { lat: 48.093231, lng: 17.621451 },
    { lat: 48.093214, lng: 17.621203 },
    { lat: 48.093184, lng: 17.62106 },
    { lat: 48.0930459, lng: 17.6205046 },
    { lat: 48.09277, lng: 17.619769 },
    { lat: 48.0924035, lng: 17.6191691 },
    { lat: 48.0920672, lng: 17.6188348 },
    { lat: 48.0915843, lng: 17.6185929 },
    { lat: 48.0899922, lng: 17.6185726 },
    { lat: 48.0897135, lng: 17.618666 },
    { lat: 48.0881337, lng: 17.6204825 },
    { lat: 48.0873618, lng: 17.6219162 },
    { lat: 48.087111, lng: 17.622291 },
    { lat: 48.0867848, lng: 17.6226464 },
    { lat: 48.0861941, lng: 17.6230002 },
    { lat: 48.0855753, lng: 17.6231787 },
    { lat: 48.0850794, lng: 17.6231229 },
    { lat: 48.0838879, lng: 17.6227627 },
    { lat: 48.0834542, lng: 17.6224599 },
    { lat: 48.0830916, lng: 17.621869 },
    { lat: 48.0827538, lng: 17.6209032 },
    { lat: 48.0828036, lng: 17.6200879 },
    { lat: 48.0831496, lng: 17.6186699 },
    { lat: 48.0832874, lng: 17.6183835 },
    { lat: 48.0833691, lng: 17.6183879 },
    { lat: 48.083627, lng: 17.6179892 },
    { lat: 48.0838683, lng: 17.6177344 },
    { lat: 48.0844783, lng: 17.6172707 },
    { lat: 48.0853236, lng: 17.6167131 },
    { lat: 48.0857348, lng: 17.6165742 },
    { lat: 48.0859872, lng: 17.616707 },
    { lat: 48.0862889, lng: 17.6165499 },
    { lat: 48.0864964, lng: 17.6151571 },
    { lat: 48.0861359, lng: 17.6139769 },
    { lat: 48.0860021, lng: 17.6138779 },
    { lat: 48.0848188, lng: 17.6121557 },
    { lat: 48.0841923, lng: 17.6113031 },
    { lat: 48.0824464, lng: 17.6088619 },
    { lat: 48.0807139, lng: 17.6066155 },
    { lat: 48.0774553, lng: 17.6026926 },
    { lat: 48.074131, lng: 17.5988723 },
    { lat: 48.0724875, lng: 17.5967863 },
    { lat: 48.0708245, lng: 17.5945024 },
    { lat: 48.0666903, lng: 17.5880954 },
    { lat: 48.0648177, lng: 17.5853071 },
    { lat: 48.064212, lng: 17.5830127 },
    { lat: 48.063892, lng: 17.5809876 },
    { lat: 48.0638094, lng: 17.5808235 },
    { lat: 48.0636755, lng: 17.5807278 },
    { lat: 48.0630859, lng: 17.5806547 },
    { lat: 48.0629374, lng: 17.5805748 },
    { lat: 48.062723, lng: 17.5803306 },
    { lat: 48.062528, lng: 17.5798739 },
    { lat: 48.0624164, lng: 17.5794146 },
    { lat: 48.0623153, lng: 17.5785758 },
    { lat: 48.0622941, lng: 17.5762945 },
    { lat: 48.0621497, lng: 17.5748285 },
    { lat: 48.0620074, lng: 17.5742598 },
    { lat: 48.0616823, lng: 17.5736455 },
    { lat: 48.0613892, lng: 17.5733504 },
    { lat: 48.0605679, lng: 17.5729019 },
    { lat: 48.0602164, lng: 17.5721922 },
    { lat: 48.0602547, lng: 17.5715506 },
    { lat: 48.0583406, lng: 17.5665814 },
    { lat: 48.0573095, lng: 17.5632956 },
    { lat: 48.0536306, lng: 17.5555116 },
    { lat: 48.0511165, lng: 17.5519416 },
    { lat: 48.0453061, lng: 17.5460833 },
    { lat: 48.0335254, lng: 17.5362241 },
    { lat: 48.0326324, lng: 17.5356458 },
    { lat: 48.0308265, lng: 17.5341821 },
    { lat: 48.0288988, lng: 17.5316169 },
    { lat: 48.0278862, lng: 17.5337684 },
    { lat: 48.0262345, lng: 17.5327708 },
    { lat: 48.026443, lng: 17.5313236 },
    { lat: 48.025874, lng: 17.5303937 },
    { lat: 48.0246966, lng: 17.5287867 },
    { lat: 48.0232172, lng: 17.5273961 },
    { lat: 48.0231593, lng: 17.5274961 },
    { lat: 48.0217802, lng: 17.5260181 },
    { lat: 48.0216392, lng: 17.5263346 },
    { lat: 48.0201131, lng: 17.5245913 },
    { lat: 48.0200737, lng: 17.5246629 },
    { lat: 48.0199154, lng: 17.5244866 },
    { lat: 48.0191363, lng: 17.5235571 },
    { lat: 48.0186906, lng: 17.523033 },
    { lat: 48.0182939, lng: 17.5239451 },
    { lat: 48.0171295, lng: 17.5217278 },
    { lat: 48.0172699, lng: 17.5215412 },
    { lat: 48.0162107, lng: 17.5192984 },
    { lat: 48.0165606, lng: 17.5176978 },
    { lat: 48.0159738, lng: 17.5163536 },
    { lat: 48.0152261, lng: 17.5142642 },
    { lat: 48.0140506, lng: 17.5104786 },
    { lat: 48.0126018, lng: 17.5060329 },
    { lat: 48.0114678, lng: 17.5035589 },
    { lat: 48.0115597, lng: 17.503198 },
    { lat: 48.0114468, lng: 17.5023053 },
    { lat: 48.0111889, lng: 17.5013562 },
    { lat: 48.0111043, lng: 17.500658 },
    { lat: 48.0106905, lng: 17.4988481 },
    { lat: 48.0104463, lng: 17.4979686 },
    { lat: 48.0102973, lng: 17.4976178 },
    { lat: 48.0102392, lng: 17.4977507 },
    { lat: 48.0099091, lng: 17.4985143 },
    { lat: 48.009668, lng: 17.4989114 },
    { lat: 48.0086236, lng: 17.5003984 },
    { lat: 48.0079938, lng: 17.5019459 },
    { lat: 48.0079177, lng: 17.5021158 },
    { lat: 48.0078743, lng: 17.5022137 },
    { lat: 48.0073648, lng: 17.5031201 },
    { lat: 48.0067595, lng: 17.5039798 },
    { lat: 48.0072611, lng: 17.5046637 },
    { lat: 48.0075619, lng: 17.5050723 },
    { lat: 48.0072503, lng: 17.5061446 },
    { lat: 48.0073897, lng: 17.5063948 },
    { lat: 48.0078664, lng: 17.5079043 },
    { lat: 48.0077055, lng: 17.5081249 },
    { lat: 48.0080543, lng: 17.5095233 },
    { lat: 48.0078487, lng: 17.5104488 },
    { lat: 48.0072947, lng: 17.5135084 },
    { lat: 48.0067499, lng: 17.5156394 },
    { lat: 48.0081925, lng: 17.5169295 },
    { lat: 48.0085451, lng: 17.5171425 },
    { lat: 48.0099407, lng: 17.5178574 },
    { lat: 48.0116653, lng: 17.5185991 },
    { lat: 48.0122129, lng: 17.5193859 },
    { lat: 48.0126211, lng: 17.5201127 },
    { lat: 48.0129018, lng: 17.5213306 },
    { lat: 48.0134017, lng: 17.522046 },
    { lat: 48.0139765, lng: 17.5226086 },
    { lat: 48.0141296, lng: 17.5231599 },
    { lat: 48.0140939, lng: 17.5238009 },
    { lat: 48.0140795, lng: 17.5239966 },
    { lat: 48.0138447, lng: 17.5248801 },
    { lat: 48.0136257, lng: 17.5252126 },
    { lat: 48.0127856, lng: 17.5251982 },
    { lat: 48.0125648, lng: 17.5256286 },
    { lat: 48.012636, lng: 17.5264194 },
    { lat: 48.0129127, lng: 17.5281387 },
    { lat: 48.0129173, lng: 17.5292674 },
    { lat: 48.0127494, lng: 17.5304654 },
    { lat: 48.0125338, lng: 17.5310153 },
    { lat: 48.0121857, lng: 17.5314671 },
    { lat: 48.0117392, lng: 17.5318105 },
    { lat: 48.0109995, lng: 17.531952 },
    { lat: 48.0104112, lng: 17.5319703 },
    { lat: 48.0098394, lng: 17.5317003 },
    { lat: 48.0092522, lng: 17.5313306 },
    { lat: 48.0088138, lng: 17.5314492 },
    { lat: 48.0085683, lng: 17.5321998 },
    { lat: 48.0083342, lng: 17.5324754 },
    { lat: 48.0081009, lng: 17.5326406 },
    { lat: 48.0071374, lng: 17.5329893 },
    { lat: 48.0065657, lng: 17.5332838 },
    { lat: 48.0057823, lng: 17.5338533 },
    { lat: 48.0053288, lng: 17.5343538 },
    { lat: 48.00567, lng: 17.5351624 },
    { lat: 48.0064114, lng: 17.5364383 },
    { lat: 48.0066891, lng: 17.5374062 },
    { lat: 48.0065529, lng: 17.5381845 },
    { lat: 48.0061858, lng: 17.5388332 },
    { lat: 48.0047416, lng: 17.5402263 },
    { lat: 48.0045008, lng: 17.5413823 },
    { lat: 48.00448, lng: 17.5414819 },
    { lat: 48.004733, lng: 17.542734 },
    { lat: 48.0051696, lng: 17.5443975 },
    { lat: 48.0056863, lng: 17.5454985 },
    { lat: 48.0065152, lng: 17.5463792 },
    { lat: 48.0078228, lng: 17.5469427 },
    { lat: 48.0085119, lng: 17.5473337 },
    { lat: 48.009552, lng: 17.5484276 },
    { lat: 48.0100718, lng: 17.5493651 },
    { lat: 48.0104874, lng: 17.550828 },
    { lat: 48.0105119, lng: 17.5535886 },
    { lat: 48.0104177, lng: 17.5547305 },
    { lat: 48.0104118, lng: 17.5547977 },
    { lat: 48.01168, lng: 17.554971 },
    { lat: 48.0131926, lng: 17.5552969 },
    { lat: 48.0144195, lng: 17.5551996 },
    { lat: 48.0174082, lng: 17.5552619 },
    { lat: 48.0178023, lng: 17.5551572 },
    { lat: 48.0176892, lng: 17.5548541 },
    { lat: 48.0182684, lng: 17.5542162 },
    { lat: 48.0187936, lng: 17.5537266 },
    { lat: 48.0188581, lng: 17.5537357 },
    { lat: 48.0189209, lng: 17.5532758 },
    { lat: 48.0197251, lng: 17.5538128 },
    { lat: 48.0199647, lng: 17.5531276 },
    { lat: 48.0213589, lng: 17.5544086 },
    { lat: 48.0218121, lng: 17.554185 },
    { lat: 48.0224559, lng: 17.5536736 },
    { lat: 48.023716, lng: 17.5549773 },
    { lat: 48.0243872, lng: 17.5538708 },
    { lat: 48.0255915, lng: 17.5551835 },
    { lat: 48.026472, lng: 17.5563099 },
    { lat: 48.0267381, lng: 17.5565154 },
    { lat: 48.0271812, lng: 17.5571382 },
    { lat: 48.0277866, lng: 17.5575276 },
    { lat: 48.0284355, lng: 17.5578209 },
    { lat: 48.029484, lng: 17.5585887 },
    { lat: 48.0297648, lng: 17.55952 },
    { lat: 48.0300042, lng: 17.5607362 },
    { lat: 48.0314764, lng: 17.561562 },
    { lat: 48.0328754, lng: 17.5631826 },
    { lat: 48.0328095, lng: 17.563936 },
    { lat: 48.0439756, lng: 17.5805066 },
    { lat: 48.0458787, lng: 17.5828357 },
    { lat: 48.0466574, lng: 17.584012 },
    { lat: 48.0497578, lng: 17.589554 },
    { lat: 48.0544671, lng: 17.5953079 },
    { lat: 48.0576671, lng: 17.599621 },
    { lat: 48.0604147, lng: 17.6036929 },
    { lat: 48.0622213, lng: 17.606943 },
    { lat: 48.0630678, lng: 17.6088901 },
    { lat: 48.0639249, lng: 17.6112234 },
    { lat: 48.0648815, lng: 17.6142325 },
    { lat: 48.0656196, lng: 17.6160243 },
    { lat: 48.0663464, lng: 17.6170153 },
    { lat: 48.0663137, lng: 17.6172109 },
    { lat: 48.0675817, lng: 17.6188825 },
    { lat: 48.0678986, lng: 17.6194478 },
    { lat: 48.0683102, lng: 17.6198109 },
    { lat: 48.0682342, lng: 17.6200945 },
    { lat: 48.068987, lng: 17.6198904 },
    { lat: 48.0711217, lng: 17.6225731 },
    { lat: 48.0715738, lng: 17.6230248 },
    { lat: 48.0722259, lng: 17.6239429 },
    { lat: 48.0731879, lng: 17.6251001 },
    { lat: 48.0747072, lng: 17.6277814 },
    { lat: 48.075085, lng: 17.6293812 },
    { lat: 48.0780187, lng: 17.6327023 },
    { lat: 48.0796683, lng: 17.6348081 },
    { lat: 48.0795932, lng: 17.635282 },
    { lat: 48.0795875, lng: 17.6357453 },
    { lat: 48.0796934, lng: 17.6363649 },
    { lat: 48.0798684, lng: 17.6369173 },
    { lat: 48.0804271, lng: 17.6375918 },
    { lat: 48.0806924, lng: 17.6377525 },
    { lat: 48.0808777, lng: 17.6378242 },
    { lat: 48.0817939, lng: 17.6378704 },
    { lat: 48.083939, lng: 17.6412571 },
    { lat: 48.084814, lng: 17.6421679 },
    { lat: 48.086374, lng: 17.6439656 },
    { lat: 48.0872606, lng: 17.6451496 },
    { lat: 48.087995, lng: 17.6462657 },
    { lat: 48.0875363, lng: 17.6466015 },
    { lat: 48.0855965, lng: 17.6473199 },
    { lat: 48.0855016, lng: 17.6474677 },
    { lat: 48.0854022, lng: 17.6477272 },
    { lat: 48.0853673, lng: 17.6480708 },
    { lat: 48.0854224, lng: 17.6483945 },
    { lat: 48.0855148, lng: 17.6486598 },
    { lat: 48.0858263, lng: 17.6491931 },
    { lat: 48.0862582, lng: 17.6495621 },
    { lat: 48.0864536, lng: 17.6495986 },
    { lat: 48.0868092, lng: 17.6495606 },
    { lat: 48.087211, lng: 17.6494147 },
    { lat: 48.0878615, lng: 17.6489455 },
    { lat: 48.0881551, lng: 17.6486411 },
    { lat: 48.0886268, lng: 17.6480811 },
    { lat: 48.0895577, lng: 17.6467141 },
    { lat: 48.0896641, lng: 17.6467875 },
    { lat: 48.08997, lng: 17.647463 },
  ],
  Kľúčovec: [
    { lat: 47.8106448, lng: 17.6911517 },
    { lat: 47.8102651, lng: 17.6910921 },
    { lat: 47.8099652, lng: 17.6909396 },
    { lat: 47.808756, lng: 17.6901857 },
    { lat: 47.8081346, lng: 17.6896437 },
    { lat: 47.8075842, lng: 17.6893321 },
    { lat: 47.8071185, lng: 17.6892318 },
    { lat: 47.8065476, lng: 17.6892213 },
    { lat: 47.8063282, lng: 17.6891422 },
    { lat: 47.8060293, lng: 17.6889147 },
    { lat: 47.8052178, lng: 17.6881123 },
    { lat: 47.8046753, lng: 17.68782 },
    { lat: 47.8044724, lng: 17.6878264 },
    { lat: 47.8039073, lng: 17.6881389 },
    { lat: 47.8036214, lng: 17.6883855 },
    { lat: 47.8034912, lng: 17.6882905 },
    { lat: 47.8021348, lng: 17.6867077 },
    { lat: 47.8020296, lng: 17.6864021 },
    { lat: 47.8007184, lng: 17.6867537 },
    { lat: 47.799243, lng: 17.6867386 },
    { lat: 47.7989631, lng: 17.6874032 },
    { lat: 47.7985368, lng: 17.6887343 },
    { lat: 47.7982663, lng: 17.6897649 },
    { lat: 47.7980056, lng: 17.6901211 },
    { lat: 47.7976668, lng: 17.6907296 },
    { lat: 47.7969255, lng: 17.6916511 },
    { lat: 47.7955616, lng: 17.6929571 },
    { lat: 47.7946639, lng: 17.6935294 },
    { lat: 47.7942217, lng: 17.693733 },
    { lat: 47.7938602, lng: 17.6938194 },
    { lat: 47.7918031, lng: 17.6934264 },
    { lat: 47.7909223, lng: 17.6930821 },
    { lat: 47.7890678, lng: 17.6919624 },
    { lat: 47.7874122, lng: 17.6907214 },
    { lat: 47.7864181, lng: 17.6900847 },
    { lat: 47.7796729, lng: 17.6919279 },
    { lat: 47.7778835, lng: 17.6923582 },
    { lat: 47.7770534, lng: 17.6922657 },
    { lat: 47.7762237, lng: 17.6922661 },
    { lat: 47.7744137, lng: 17.6914063 },
    { lat: 47.773996, lng: 17.690003 },
    { lat: 47.773261, lng: 17.691233 },
    { lat: 47.772195, lng: 17.692818 },
    { lat: 47.771066, lng: 17.694306 },
    { lat: 47.76988, lng: 17.695692 },
    { lat: 47.768962, lng: 17.696623 },
    { lat: 47.768007, lng: 17.697467 },
    { lat: 47.762095, lng: 17.702039 },
    { lat: 47.760485, lng: 17.703741 },
    { lat: 47.758985, lng: 17.705419 },
    { lat: 47.760536, lng: 17.707118 },
    { lat: 47.759689, lng: 17.708175 },
    { lat: 47.760627, lng: 17.708584 },
    { lat: 47.762741, lng: 17.70951 },
    { lat: 47.762876, lng: 17.71021 },
    { lat: 47.762997, lng: 17.710306 },
    { lat: 47.76315, lng: 17.710426 },
    { lat: 47.763167, lng: 17.710439 },
    { lat: 47.763211, lng: 17.71051 },
    { lat: 47.763243, lng: 17.710562 },
    { lat: 47.763272, lng: 17.710607 },
    { lat: 47.763368, lng: 17.710758 },
    { lat: 47.763463, lng: 17.710911 },
    { lat: 47.763517, lng: 17.711014 },
    { lat: 47.763603, lng: 17.711177 },
    { lat: 47.763613, lng: 17.711198 },
    { lat: 47.763805, lng: 17.711473 },
    { lat: 47.763843, lng: 17.711526 },
    { lat: 47.764023, lng: 17.71181 },
    { lat: 47.764078, lng: 17.711897 },
    { lat: 47.764232, lng: 17.712133 },
    { lat: 47.764314, lng: 17.712259 },
    { lat: 47.764361, lng: 17.712338 },
    { lat: 47.764469, lng: 17.71252 },
    { lat: 47.764504, lng: 17.712579 },
    { lat: 47.764537, lng: 17.712637 },
    { lat: 47.764645, lng: 17.712763 },
    { lat: 47.764705, lng: 17.712833 },
    { lat: 47.764767, lng: 17.712905 },
    { lat: 47.764884, lng: 17.713043 },
    { lat: 47.764903, lng: 17.713066 },
    { lat: 47.765112, lng: 17.713322 },
    { lat: 47.765167, lng: 17.713396 },
    { lat: 47.765223, lng: 17.71347 },
    { lat: 47.765279, lng: 17.713545 },
    { lat: 47.765336, lng: 17.713621 },
    { lat: 47.765403, lng: 17.713712 },
    { lat: 47.765473, lng: 17.713805 },
    { lat: 47.765551, lng: 17.713908 },
    { lat: 47.765658, lng: 17.71402 },
    { lat: 47.765773, lng: 17.714141 },
    { lat: 47.765855, lng: 17.714228 },
    { lat: 47.765935, lng: 17.714311 },
    { lat: 47.765976, lng: 17.714353 },
    { lat: 47.766008, lng: 17.714396 },
    { lat: 47.766118, lng: 17.714537 },
    { lat: 47.766223, lng: 17.714674 },
    { lat: 47.766263, lng: 17.714726 },
    { lat: 47.766296, lng: 17.714769 },
    { lat: 47.766335, lng: 17.714819 },
    { lat: 47.766372, lng: 17.714867 },
    { lat: 47.766447, lng: 17.714963 },
    { lat: 47.766551, lng: 17.715099 },
    { lat: 47.766656, lng: 17.715234 },
    { lat: 47.766759, lng: 17.715368 },
    { lat: 47.76697, lng: 17.715844 },
    { lat: 47.767017, lng: 17.715951 },
    { lat: 47.767168, lng: 17.716292 },
    { lat: 47.76725, lng: 17.716475 },
    { lat: 47.76733, lng: 17.716656 },
    { lat: 47.767413, lng: 17.716842 },
    { lat: 47.767439, lng: 17.716901 },
    { lat: 47.767503, lng: 17.717039 },
    { lat: 47.767679, lng: 17.717422 },
    { lat: 47.767701, lng: 17.717472 },
    { lat: 47.767724, lng: 17.717523 },
    { lat: 47.767819, lng: 17.717727 },
    { lat: 47.767832, lng: 17.717759 },
    { lat: 47.767913, lng: 17.717934 },
    { lat: 47.767922, lng: 17.717954 },
    { lat: 47.768407, lng: 17.719025 },
    { lat: 47.768445, lng: 17.71911 },
    { lat: 47.768519, lng: 17.719277 },
    { lat: 47.768584, lng: 17.719425 },
    { lat: 47.768734, lng: 17.719765 },
    { lat: 47.768797, lng: 17.719907 },
    { lat: 47.768987, lng: 17.720325 },
    { lat: 47.769, lng: 17.720355 },
    { lat: 47.769179, lng: 17.720744 },
    { lat: 47.769278, lng: 17.720962 },
    { lat: 47.769328, lng: 17.721066 },
    { lat: 47.769366, lng: 17.721154 },
    { lat: 47.769375, lng: 17.721173 },
    { lat: 47.769487, lng: 17.721425 },
    { lat: 47.769609, lng: 17.721701 },
    { lat: 47.769641, lng: 17.721773 },
    { lat: 47.769658, lng: 17.72181 },
    { lat: 47.769734, lng: 17.721981 },
    { lat: 47.769841, lng: 17.722221 },
    { lat: 47.770107, lng: 17.722789 },
    { lat: 47.770328, lng: 17.723261 },
    { lat: 47.770458, lng: 17.723531 },
    { lat: 47.770776, lng: 17.724208 },
    { lat: 47.770929, lng: 17.724535 },
    { lat: 47.771011, lng: 17.724632 },
    { lat: 47.771315, lng: 17.724811 },
    { lat: 47.771679, lng: 17.725128 },
    { lat: 47.771759, lng: 17.725193 },
    { lat: 47.772191, lng: 17.725534 },
    { lat: 47.772668, lng: 17.72594 },
    { lat: 47.772875, lng: 17.72617 },
    { lat: 47.77343, lng: 17.726594 },
    { lat: 47.773585, lng: 17.726742 },
    { lat: 47.773901, lng: 17.727201 },
    { lat: 47.774383, lng: 17.727826 },
    { lat: 47.774692, lng: 17.728095 },
    { lat: 47.774984, lng: 17.728346 },
    { lat: 47.775353, lng: 17.728751 },
    { lat: 47.775767, lng: 17.729944 },
    { lat: 47.775771, lng: 17.730052 },
    { lat: 47.776627, lng: 17.729768 },
    { lat: 47.777476, lng: 17.729487 },
    { lat: 47.777698, lng: 17.729348 },
    { lat: 47.778007, lng: 17.729269 },
    { lat: 47.779513, lng: 17.728694 },
    { lat: 47.779989, lng: 17.728451 },
    { lat: 47.780358, lng: 17.728223 },
    { lat: 47.781326, lng: 17.727484 },
    { lat: 47.782201, lng: 17.726833 },
    { lat: 47.783187, lng: 17.725978 },
    { lat: 47.78352, lng: 17.72567 },
    { lat: 47.783983, lng: 17.72506 },
    { lat: 47.784287, lng: 17.724546 },
    { lat: 47.7844, lng: 17.724767 },
    { lat: 47.784472, lng: 17.724697 },
    { lat: 47.784873, lng: 17.725122 },
    { lat: 47.784878, lng: 17.725184 },
    { lat: 47.784915, lng: 17.725367 },
    { lat: 47.784936, lng: 17.72543 },
    { lat: 47.785118, lng: 17.725736 },
    { lat: 47.785144, lng: 17.72581 },
    { lat: 47.785169, lng: 17.726334 },
    { lat: 47.785026, lng: 17.726924 },
    { lat: 47.784643, lng: 17.727793 },
    { lat: 47.784555, lng: 17.728108 },
    { lat: 47.784524, lng: 17.728433 },
    { lat: 47.78471, lng: 17.729513 },
    { lat: 47.784835, lng: 17.730356 },
    { lat: 47.784855, lng: 17.73052 },
    { lat: 47.784884, lng: 17.730691 },
    { lat: 47.784967, lng: 17.730868 },
    { lat: 47.785048, lng: 17.731002 },
    { lat: 47.785185, lng: 17.731097 },
    { lat: 47.785277, lng: 17.731099 },
    { lat: 47.785336, lng: 17.731084 },
    { lat: 47.785368, lng: 17.731049 },
    { lat: 47.78543, lng: 17.730975 },
    { lat: 47.785518, lng: 17.730845 },
    { lat: 47.785579, lng: 17.730722 },
    { lat: 47.785706, lng: 17.73041 },
    { lat: 47.785821, lng: 17.730085 },
    { lat: 47.785876, lng: 17.729879 },
    { lat: 47.785938, lng: 17.729681 },
    { lat: 47.785998, lng: 17.729463 },
    { lat: 47.786109, lng: 17.728944 },
    { lat: 47.786179, lng: 17.728573 },
    { lat: 47.786237, lng: 17.728293 },
    { lat: 47.786345, lng: 17.727949 },
    { lat: 47.786452, lng: 17.727697 },
    { lat: 47.786616, lng: 17.727427 },
    { lat: 47.786752, lng: 17.727256 },
    { lat: 47.786884, lng: 17.727138 },
    { lat: 47.787105, lng: 17.726958 },
    { lat: 47.787222, lng: 17.726904 },
    { lat: 47.787394, lng: 17.726848 },
    { lat: 47.78797, lng: 17.726795 },
    { lat: 47.788152, lng: 17.726839 },
    { lat: 47.788502, lng: 17.727103 },
    { lat: 47.788591, lng: 17.727214 },
    { lat: 47.78872, lng: 17.727453 },
    { lat: 47.788799, lng: 17.727696 },
    { lat: 47.788833, lng: 17.728229 },
    { lat: 47.788828, lng: 17.72844 },
    { lat: 47.788857, lng: 17.728898 },
    { lat: 47.788816, lng: 17.729593 },
    { lat: 47.788765, lng: 17.73002 },
    { lat: 47.788654, lng: 17.730633 },
    { lat: 47.788523, lng: 17.731481 },
    { lat: 47.788486, lng: 17.73194 },
    { lat: 47.788486, lng: 17.732052 },
    { lat: 47.788516, lng: 17.732276 },
    { lat: 47.788571, lng: 17.732453 },
    { lat: 47.788619, lng: 17.732622 },
    { lat: 47.78872, lng: 17.732806 },
    { lat: 47.788854, lng: 17.733004 },
    { lat: 47.788984, lng: 17.733157 },
    { lat: 47.789474, lng: 17.733622 },
    { lat: 47.789844, lng: 17.73396 },
    { lat: 47.790069, lng: 17.734123 },
    { lat: 47.790258, lng: 17.734239 },
    { lat: 47.791142, lng: 17.734747 },
    { lat: 47.791323, lng: 17.734848 },
    { lat: 47.79149, lng: 17.734858 },
    { lat: 47.791993, lng: 17.734588 },
    { lat: 47.792251, lng: 17.734503 },
    { lat: 47.792412, lng: 17.73436 },
    { lat: 47.792485, lng: 17.734278 },
    { lat: 47.792545, lng: 17.734158 },
    { lat: 47.792606, lng: 17.733992 },
    { lat: 47.792633, lng: 17.733881 },
    { lat: 47.792628, lng: 17.733711 },
    { lat: 47.792572, lng: 17.733391 },
    { lat: 47.792522, lng: 17.733172 },
    { lat: 47.792385, lng: 17.732687 },
    { lat: 47.792265, lng: 17.732322 },
    { lat: 47.792195, lng: 17.731916 },
    { lat: 47.792168, lng: 17.731577 },
    { lat: 47.792185, lng: 17.731203 },
    { lat: 47.792257, lng: 17.730969 },
    { lat: 47.792411, lng: 17.730514 },
    { lat: 47.792483, lng: 17.730426 },
    { lat: 47.792584, lng: 17.730393 },
    { lat: 47.792657, lng: 17.730355 },
    { lat: 47.792788, lng: 17.730184 },
    { lat: 47.79294, lng: 17.730168 },
    { lat: 47.792984, lng: 17.729954 },
    { lat: 47.793018, lng: 17.729811 },
    { lat: 47.793132, lng: 17.729378 },
    { lat: 47.793399, lng: 17.728216 },
    { lat: 47.79341, lng: 17.728169 },
    { lat: 47.79382, lng: 17.726412 },
    { lat: 47.793883, lng: 17.726447 },
    { lat: 47.794042, lng: 17.72654 },
    { lat: 47.794171, lng: 17.726614 },
    { lat: 47.794297, lng: 17.726694 },
    { lat: 47.794807, lng: 17.727322 },
    { lat: 47.794955, lng: 17.727354 },
    { lat: 47.795104, lng: 17.727385 },
    { lat: 47.7954, lng: 17.72745 },
    { lat: 47.795559, lng: 17.727485 },
    { lat: 47.795739, lng: 17.727525 },
    { lat: 47.796054, lng: 17.727594 },
    { lat: 47.796358, lng: 17.727654 },
    { lat: 47.796706, lng: 17.727723 },
    { lat: 47.796924, lng: 17.727769 },
    { lat: 47.79698, lng: 17.727781 },
    { lat: 47.797032, lng: 17.727793 },
    { lat: 47.797254, lng: 17.727848 },
    { lat: 47.797742, lng: 17.727977 },
    { lat: 47.798727, lng: 17.728251 },
    { lat: 47.799563, lng: 17.728538 },
    { lat: 47.799808, lng: 17.72872 },
    { lat: 47.80005, lng: 17.728899 },
    { lat: 47.800093, lng: 17.72893 },
    { lat: 47.800174, lng: 17.728985 },
    { lat: 47.800257, lng: 17.729036 },
    { lat: 47.800374, lng: 17.729107 },
    { lat: 47.8012, lng: 17.729613 },
    { lat: 47.801694, lng: 17.729916 },
    { lat: 47.801863, lng: 17.730011 },
    { lat: 47.802131, lng: 17.730163 },
    { lat: 47.802344, lng: 17.730282 },
    { lat: 47.802765, lng: 17.730572 },
    { lat: 47.802975, lng: 17.730715 },
    { lat: 47.803605, lng: 17.731194 },
    { lat: 47.803643, lng: 17.731223 },
    { lat: 47.803716, lng: 17.73128 },
    { lat: 47.803857, lng: 17.731387 },
    { lat: 47.803893, lng: 17.731415 },
    { lat: 47.804155, lng: 17.730353 },
    { lat: 47.804199, lng: 17.730197 },
    { lat: 47.804271, lng: 17.729999 },
    { lat: 47.804344, lng: 17.729846 },
    { lat: 47.8044, lng: 17.729772 },
    { lat: 47.804576, lng: 17.72969 },
    { lat: 47.8047, lng: 17.72968 },
    { lat: 47.804754, lng: 17.729702 },
    { lat: 47.804863, lng: 17.729818 },
    { lat: 47.804975, lng: 17.730024 },
    { lat: 47.805028, lng: 17.730187 },
    { lat: 47.805209, lng: 17.73101 },
    { lat: 47.805375, lng: 17.731702 },
    { lat: 47.805446, lng: 17.731838 },
    { lat: 47.805521, lng: 17.73195 },
    { lat: 47.8086089, lng: 17.7284115 },
    { lat: 47.8084426, lng: 17.7262286 },
    { lat: 47.8089987, lng: 17.7214565 },
    { lat: 47.8093143, lng: 17.7179794 },
    { lat: 47.8096219, lng: 17.7154717 },
    { lat: 47.8092183, lng: 17.7152273 },
    { lat: 47.8081828, lng: 17.7148288 },
    { lat: 47.8079256, lng: 17.7146565 },
    { lat: 47.8076631, lng: 17.714341 },
    { lat: 47.8069001, lng: 17.7127086 },
    { lat: 47.806618, lng: 17.7123874 },
    { lat: 47.8068563, lng: 17.7119403 },
    { lat: 47.8066692, lng: 17.7114258 },
    { lat: 47.806596, lng: 17.7108712 },
    { lat: 47.806635, lng: 17.7096257 },
    { lat: 47.8068819, lng: 17.7077688 },
    { lat: 47.8065136, lng: 17.7053454 },
    { lat: 47.8064161, lng: 17.7043868 },
    { lat: 47.8062527, lng: 17.7038356 },
    { lat: 47.8060448, lng: 17.7034237 },
    { lat: 47.8059512, lng: 17.7033152 },
    { lat: 47.8047001, lng: 17.7029549 },
    { lat: 47.8044902, lng: 17.7023488 },
    { lat: 47.8035446, lng: 17.7018917 },
    { lat: 47.8024848, lng: 17.7016792 },
    { lat: 47.8016334, lng: 17.701391 },
    { lat: 47.8013082, lng: 17.7016608 },
    { lat: 47.8009233, lng: 17.7013564 },
    { lat: 47.8006682, lng: 17.7010357 },
    { lat: 47.8006556, lng: 17.7004753 },
    { lat: 47.7995246, lng: 17.6997824 },
    { lat: 47.7978218, lng: 17.6988666 },
    { lat: 47.7982353, lng: 17.6971343 },
    { lat: 47.7984444, lng: 17.6964536 },
    { lat: 47.7986852, lng: 17.6959051 },
    { lat: 47.7989619, lng: 17.6954937 },
    { lat: 47.7993037, lng: 17.695132 },
    { lat: 47.7998433, lng: 17.6947523 },
    { lat: 47.8006502, lng: 17.6943 },
    { lat: 47.8008954, lng: 17.6942491 },
    { lat: 47.8022656, lng: 17.6940823 },
    { lat: 47.8041112, lng: 17.6944679 },
    { lat: 47.8053331, lng: 17.6942027 },
    { lat: 47.8059964, lng: 17.6941748 },
    { lat: 47.8063146, lng: 17.6943368 },
    { lat: 47.8067716, lng: 17.6948482 },
    { lat: 47.807271, lng: 17.695554 },
    { lat: 47.8078819, lng: 17.6962713 },
    { lat: 47.8083602, lng: 17.6966597 },
    { lat: 47.8091713, lng: 17.696507 },
    { lat: 47.8097427, lng: 17.6964881 },
    { lat: 47.810162, lng: 17.6966402 },
    { lat: 47.8107981, lng: 17.6951404 },
    { lat: 47.8108277, lng: 17.6937486 },
    { lat: 47.8107883, lng: 17.6929094 },
    { lat: 47.8106194, lng: 17.6912515 },
    { lat: 47.8106448, lng: 17.6911517 },
  ],
  Vieska: [
    { lat: 48.0079177, lng: 17.5021158 },
    { lat: 48.0079938, lng: 17.5019459 },
    { lat: 48.0086236, lng: 17.5003984 },
    { lat: 48.0082018, lng: 17.4997274 },
    { lat: 48.0052215, lng: 17.4953004 },
    { lat: 48.0054135, lng: 17.4941838 },
    { lat: 47.9999787, lng: 17.4918528 },
    { lat: 47.9998076, lng: 17.4920629 },
    { lat: 47.9996007, lng: 17.4927765 },
    { lat: 47.9994953, lng: 17.4930945 },
    { lat: 47.9992461, lng: 17.4937103 },
    { lat: 47.9987957, lng: 17.4946385 },
    { lat: 47.9986327, lng: 17.4949722 },
    { lat: 47.9985656, lng: 17.4950825 },
    { lat: 47.9982934, lng: 17.4954936 },
    { lat: 47.998189, lng: 17.4956282 },
    { lat: 47.9980931, lng: 17.4957829 },
    { lat: 47.9980318, lng: 17.4958946 },
    { lat: 47.9979829, lng: 17.4959963 },
    { lat: 47.9979398, lng: 17.4960894 },
    { lat: 47.9978861, lng: 17.49632 },
    { lat: 47.9978535, lng: 17.4966451 },
    { lat: 47.9978621, lng: 17.4968199 },
    { lat: 47.9979206, lng: 17.4972811 },
    { lat: 47.9980164, lng: 17.4982608 },
    { lat: 47.9980385, lng: 17.4999007 },
    { lat: 47.9981228, lng: 17.5010323 },
    { lat: 47.9982043, lng: 17.5010537 },
    { lat: 47.9983289, lng: 17.502456 },
    { lat: 48.0025966, lng: 17.5036419 },
    { lat: 48.0029981, lng: 17.5031793 },
    { lat: 48.003589, lng: 17.5038682 },
    { lat: 48.0034256, lng: 17.5039012 },
    { lat: 48.0027087, lng: 17.5042241 },
    { lat: 48.0018524, lng: 17.5048178 },
    { lat: 48.0010577, lng: 17.5060199 },
    { lat: 48.0009806, lng: 17.5060901 },
    { lat: 48.0012036, lng: 17.5061721 },
    { lat: 48.0002146, lng: 17.5066343 },
    { lat: 47.9995041, lng: 17.5072106 },
    { lat: 47.9991538, lng: 17.5082402 },
    { lat: 47.9990915, lng: 17.509252 },
    { lat: 47.9987611, lng: 17.5098916 },
    { lat: 47.9986467, lng: 17.5109358 },
    { lat: 47.9981791, lng: 17.5109868 },
    { lat: 47.9981577, lng: 17.5115519 },
    { lat: 47.9976783, lng: 17.5112065 },
    { lat: 47.9974851, lng: 17.5120645 },
    { lat: 47.9970946, lng: 17.5123139 },
    { lat: 47.9966545, lng: 17.5128479 },
    { lat: 47.9965341, lng: 17.5136602 },
    { lat: 47.9965172, lng: 17.5144299 },
    { lat: 47.9957268, lng: 17.5144847 },
    { lat: 47.9950546, lng: 17.5162171 },
    { lat: 47.9947729, lng: 17.5177522 },
    { lat: 47.994501, lng: 17.5182674 },
    { lat: 47.9943968, lng: 17.5184536 },
    { lat: 47.9944025, lng: 17.5189831 },
    { lat: 47.9942494, lng: 17.5220754 },
    { lat: 47.9940151, lng: 17.5219971 },
    { lat: 47.9937194, lng: 17.5235617 },
    { lat: 47.9936238, lng: 17.524622 },
    { lat: 47.9935354, lng: 17.5264768 },
    { lat: 47.9935176, lng: 17.5268847 },
    { lat: 47.993982, lng: 17.5272584 },
    { lat: 47.9940571, lng: 17.5280586 },
    { lat: 47.9939479, lng: 17.529098 },
    { lat: 47.9933451, lng: 17.5308967 },
    { lat: 47.9923495, lng: 17.5331864 },
    { lat: 47.9919282, lng: 17.5339773 },
    { lat: 47.9912079, lng: 17.5349472 },
    { lat: 47.9913257, lng: 17.5350937 },
    { lat: 47.9954079, lng: 17.540789 },
    { lat: 47.9961936, lng: 17.5392343 },
    { lat: 47.9970579, lng: 17.5382033 },
    { lat: 47.9976244, lng: 17.5372824 },
    { lat: 47.9990137, lng: 17.5353873 },
    { lat: 48.0001322, lng: 17.5336539 },
    { lat: 48.0017803, lng: 17.5355101 },
    { lat: 48.0023414, lng: 17.5364012 },
    { lat: 48.0037022, lng: 17.5389544 },
    { lat: 48.0044632, lng: 17.540059 },
    { lat: 48.0047416, lng: 17.5402263 },
    { lat: 48.0061858, lng: 17.5388332 },
    { lat: 48.0065529, lng: 17.5381845 },
    { lat: 48.0066891, lng: 17.5374062 },
    { lat: 48.0064114, lng: 17.5364383 },
    { lat: 48.00567, lng: 17.5351624 },
    { lat: 48.0053288, lng: 17.5343538 },
    { lat: 48.0057823, lng: 17.5338533 },
    { lat: 48.0065657, lng: 17.5332838 },
    { lat: 48.0071374, lng: 17.5329893 },
    { lat: 48.0081009, lng: 17.5326406 },
    { lat: 48.0083342, lng: 17.5324754 },
    { lat: 48.0085683, lng: 17.5321998 },
    { lat: 48.0088138, lng: 17.5314492 },
    { lat: 48.0092522, lng: 17.5313306 },
    { lat: 48.0098394, lng: 17.5317003 },
    { lat: 48.0104112, lng: 17.5319703 },
    { lat: 48.0109995, lng: 17.531952 },
    { lat: 48.0117392, lng: 17.5318105 },
    { lat: 48.0121857, lng: 17.5314671 },
    { lat: 48.0125338, lng: 17.5310153 },
    { lat: 48.0127494, lng: 17.5304654 },
    { lat: 48.0129173, lng: 17.5292674 },
    { lat: 48.0129127, lng: 17.5281387 },
    { lat: 48.012636, lng: 17.5264194 },
    { lat: 48.0125648, lng: 17.5256286 },
    { lat: 48.0127856, lng: 17.5251982 },
    { lat: 48.0136257, lng: 17.5252126 },
    { lat: 48.0138447, lng: 17.5248801 },
    { lat: 48.0140795, lng: 17.5239966 },
    { lat: 48.0140939, lng: 17.5238009 },
    { lat: 48.0141296, lng: 17.5231599 },
    { lat: 48.0139765, lng: 17.5226086 },
    { lat: 48.0134017, lng: 17.522046 },
    { lat: 48.0129018, lng: 17.5213306 },
    { lat: 48.0126211, lng: 17.5201127 },
    { lat: 48.0122129, lng: 17.5193859 },
    { lat: 48.0116653, lng: 17.5185991 },
    { lat: 48.0099407, lng: 17.5178574 },
    { lat: 48.0085451, lng: 17.5171425 },
    { lat: 48.0081925, lng: 17.5169295 },
    { lat: 48.0067499, lng: 17.5156394 },
    { lat: 48.0072947, lng: 17.5135084 },
    { lat: 48.0078487, lng: 17.5104488 },
    { lat: 48.0080543, lng: 17.5095233 },
    { lat: 48.0077055, lng: 17.5081249 },
    { lat: 48.0078664, lng: 17.5079043 },
    { lat: 48.0073897, lng: 17.5063948 },
    { lat: 48.0072503, lng: 17.5061446 },
    { lat: 48.0075619, lng: 17.5050723 },
    { lat: 48.0072611, lng: 17.5046637 },
    { lat: 48.0067595, lng: 17.5039798 },
    { lat: 48.0073648, lng: 17.5031201 },
    { lat: 48.0078743, lng: 17.5022137 },
    { lat: 48.0079177, lng: 17.5021158 },
  ],
  KostolnéKračany: [
    { lat: 47.959736, lng: 17.5975895 },
    { lat: 47.9606044, lng: 17.5981031 },
    { lat: 47.9607261, lng: 17.5987207 },
    { lat: 47.9610716, lng: 17.5994452 },
    { lat: 47.9624839, lng: 17.5989784 },
    { lat: 47.9629606, lng: 17.5990548 },
    { lat: 47.9634984, lng: 17.5990057 },
    { lat: 47.9641558, lng: 17.5988321 },
    { lat: 47.9654034, lng: 17.598366 },
    { lat: 47.9662885, lng: 17.5982568 },
    { lat: 47.9678749, lng: 17.598433 },
    { lat: 47.9698556, lng: 17.5990031 },
    { lat: 47.9721765, lng: 17.6001267 },
    { lat: 47.9725384, lng: 17.60001 },
    { lat: 47.9741797, lng: 17.5983874 },
    { lat: 47.974624, lng: 17.5980219 },
    { lat: 47.9746849, lng: 17.5979865 },
    { lat: 47.9751508, lng: 17.5978182 },
    { lat: 47.9761729, lng: 17.5976787 },
    { lat: 47.976502, lng: 17.5979906 },
    { lat: 47.9780872, lng: 17.5998829 },
    { lat: 47.9785251, lng: 17.5998633 },
    { lat: 47.9797433, lng: 17.5993286 },
    { lat: 47.9800359, lng: 17.5989401 },
    { lat: 47.9811823, lng: 17.5970449 },
    { lat: 47.9818131, lng: 17.5960633 },
    { lat: 47.9816015, lng: 17.5957401 },
    { lat: 47.9826147, lng: 17.5941832 },
    { lat: 47.9832777, lng: 17.593035 },
    { lat: 47.9835239, lng: 17.5927513 },
    { lat: 47.9846404, lng: 17.5915766 },
    { lat: 47.9862064, lng: 17.5903886 },
    { lat: 47.9877151, lng: 17.589448 },
    { lat: 47.987345, lng: 17.5877718 },
    { lat: 47.9884721, lng: 17.587227 },
    { lat: 47.9888095, lng: 17.5888134 },
    { lat: 47.9938281, lng: 17.5876855 },
    { lat: 47.9938494, lng: 17.588605 },
    { lat: 47.9935793, lng: 17.5891347 },
    { lat: 47.9940643, lng: 17.5898169 },
    { lat: 47.9987627, lng: 17.5872948 },
    { lat: 47.9989466, lng: 17.5870812 },
    { lat: 47.9990193, lng: 17.5867317 },
    { lat: 47.999063, lng: 17.5867456 },
    { lat: 47.9990862, lng: 17.5867536 },
    { lat: 47.9990576, lng: 17.5865909 },
    { lat: 47.9982982, lng: 17.5851465 },
    { lat: 47.9973231, lng: 17.5841126 },
    { lat: 47.9969126, lng: 17.5834456 },
    { lat: 47.9977536, lng: 17.5827899 },
    { lat: 47.9982063, lng: 17.5813511 },
    { lat: 47.9986026, lng: 17.57953 },
    { lat: 47.9986127, lng: 17.5794764 },
    { lat: 47.9985089, lng: 17.5792119 },
    { lat: 47.998687, lng: 17.5775278 },
    { lat: 47.9986632, lng: 17.5764989 },
    { lat: 47.9985021, lng: 17.5739392 },
    { lat: 47.998377, lng: 17.5726898 },
    { lat: 47.9982551, lng: 17.5721779 },
    { lat: 47.9980476, lng: 17.5715632 },
    { lat: 47.997899, lng: 17.5712899 },
    { lat: 47.9975156, lng: 17.570874 },
    { lat: 47.9969023, lng: 17.5705376 },
    { lat: 47.9963929, lng: 17.570447 },
    { lat: 47.9954837, lng: 17.5705675 },
    { lat: 47.9950252, lng: 17.5708134 },
    { lat: 47.9946056, lng: 17.5709082 },
    { lat: 47.9938436, lng: 17.5709155 },
    { lat: 47.9932495, lng: 17.5707177 },
    { lat: 47.99298, lng: 17.5705466 },
    { lat: 47.9926089, lng: 17.5701836 },
    { lat: 47.992451, lng: 17.5697089 },
    { lat: 47.9922542, lng: 17.5686105 },
    { lat: 47.992476, lng: 17.5670096 },
    { lat: 47.9924376, lng: 17.5663209 },
    { lat: 47.9923529, lng: 17.5661422 },
    { lat: 47.9923418, lng: 17.5659369 },
    { lat: 47.991859, lng: 17.5655729 },
    { lat: 47.99128, lng: 17.565488 },
    { lat: 47.990774, lng: 17.5656976 },
    { lat: 47.9899487, lng: 17.566199 },
    { lat: 47.9895546, lng: 17.5663561 },
    { lat: 47.9888688, lng: 17.5665113 },
    { lat: 47.9884438, lng: 17.5665219 },
    { lat: 47.988046, lng: 17.5662001 },
    { lat: 47.9875111, lng: 17.5672257 },
    { lat: 47.9867771, lng: 17.5659721 },
    { lat: 47.9864202, lng: 17.5647362 },
    { lat: 47.9862234, lng: 17.5638691 },
    { lat: 47.9861347, lng: 17.5636815 },
    { lat: 47.9857962, lng: 17.563247 },
    { lat: 47.9857239, lng: 17.5635298 },
    { lat: 47.9842851, lng: 17.5670903 },
    { lat: 47.9840164, lng: 17.568116 },
    { lat: 47.9833504, lng: 17.5675712 },
    { lat: 47.9830311, lng: 17.5673105 },
    { lat: 47.9813841, lng: 17.5662448 },
    { lat: 47.981274, lng: 17.5651227 },
    { lat: 47.9814389, lng: 17.5651782 },
    { lat: 47.9825515, lng: 17.5616352 },
    { lat: 47.9827804, lng: 17.5609225 },
    { lat: 47.9813216, lng: 17.5596554 },
    { lat: 47.9805646, lng: 17.5588388 },
    { lat: 47.9801545, lng: 17.5597179 },
    { lat: 47.9789491, lng: 17.5608576 },
    { lat: 47.9780227, lng: 17.5623693 },
    { lat: 47.9765154, lng: 17.5606626 },
    { lat: 47.9767088, lng: 17.5603828 },
    { lat: 47.9761482, lng: 17.5597763 },
    { lat: 47.9753549, lng: 17.5586227 },
    { lat: 47.9750719, lng: 17.5581861 },
    { lat: 47.9747074, lng: 17.5582439 },
    { lat: 47.9735601, lng: 17.5586673 },
    { lat: 47.9733543, lng: 17.5588051 },
    { lat: 47.9727892, lng: 17.5593597 },
    { lat: 47.9723468, lng: 17.5595948 },
    { lat: 47.9718339, lng: 17.5595919 },
    { lat: 47.9715529, lng: 17.5595056 },
    { lat: 47.9710834, lng: 17.5592284 },
    { lat: 47.9705483, lng: 17.5587723 },
    { lat: 47.9700958, lng: 17.5583538 },
    { lat: 47.9692172, lng: 17.5571724 },
    { lat: 47.9681563, lng: 17.5563562 },
    { lat: 47.9680899, lng: 17.5563244 },
    { lat: 47.9670509, lng: 17.5559153 },
    { lat: 47.9657216, lng: 17.5559132 },
    { lat: 47.96224, lng: 17.5553311 },
    { lat: 47.9613464, lng: 17.5556244 },
    { lat: 47.960055, lng: 17.5561584 },
    { lat: 47.9593626, lng: 17.5562113 },
    { lat: 47.9556643, lng: 17.5562301 },
    { lat: 47.9550163, lng: 17.5554756 },
    { lat: 47.9546593, lng: 17.5551187 },
    { lat: 47.9545527, lng: 17.5550958 },
    { lat: 47.9545345, lng: 17.5550917 },
    { lat: 47.9542894, lng: 17.555041 },
    { lat: 47.9507359, lng: 17.5534076 },
    { lat: 47.9487199, lng: 17.55224 },
    { lat: 47.9466948, lng: 17.5508974 },
    { lat: 47.9460965, lng: 17.5503209 },
    { lat: 47.9450174, lng: 17.5491296 },
    { lat: 47.9444866, lng: 17.5484066 },
    { lat: 47.9436498, lng: 17.5474207 },
    { lat: 47.943529, lng: 17.5472822 },
    { lat: 47.9433297, lng: 17.5484879 },
    { lat: 47.9426431, lng: 17.5509336 },
    { lat: 47.9429449, lng: 17.5511823 },
    { lat: 47.9434338, lng: 17.5520793 },
    { lat: 47.943607, lng: 17.5527732 },
    { lat: 47.9437268, lng: 17.5537807 },
    { lat: 47.943736, lng: 17.5542052 },
    { lat: 47.9436053, lng: 17.5553374 },
    { lat: 47.9433774, lng: 17.5560974 },
    { lat: 47.9427744, lng: 17.5571882 },
    { lat: 47.9427474, lng: 17.5572096 },
    { lat: 47.9426468, lng: 17.5571549 },
    { lat: 47.9423339, lng: 17.5570641 },
    { lat: 47.9417911, lng: 17.557158 },
    { lat: 47.9412897, lng: 17.5574057 },
    { lat: 47.9409079, lng: 17.5577029 },
    { lat: 47.9405371, lng: 17.5581024 },
    { lat: 47.9403381, lng: 17.558523 },
    { lat: 47.9401768, lng: 17.5592988 },
    { lat: 47.9401552, lng: 17.5601777 },
    { lat: 47.9400101, lng: 17.5612629 },
    { lat: 47.9401394, lng: 17.5618938 },
    { lat: 47.9402288, lng: 17.56233 },
    { lat: 47.9406314, lng: 17.5628159 },
    { lat: 47.9408293, lng: 17.5631576 },
    { lat: 47.9411626, lng: 17.5638235 },
    { lat: 47.9416711, lng: 17.5648396 },
    { lat: 47.9428242, lng: 17.5677333 },
    { lat: 47.9430126, lng: 17.5683141 },
    { lat: 47.9431548, lng: 17.5690536 },
    { lat: 47.9432262, lng: 17.5697627 },
    { lat: 47.9434224, lng: 17.5702519 },
    { lat: 47.9436517, lng: 17.5706075 },
    { lat: 47.943982, lng: 17.5708463 },
    { lat: 47.9444419, lng: 17.5709375 },
    { lat: 47.944822, lng: 17.5708611 },
    { lat: 47.9452026, lng: 17.5708776 },
    { lat: 47.9459883, lng: 17.5706965 },
    { lat: 47.9461706, lng: 17.5705586 },
    { lat: 47.9463178, lng: 17.5704473 },
    { lat: 47.9464613, lng: 17.5702389 },
    { lat: 47.9476377, lng: 17.5726327 },
    { lat: 47.9487214, lng: 17.573812 },
    { lat: 47.9500987, lng: 17.574813 },
    { lat: 47.9506552, lng: 17.5747384 },
    { lat: 47.9515272, lng: 17.5747424 },
    { lat: 47.9536671, lng: 17.5760825 },
    { lat: 47.9541616, lng: 17.5760117 },
    { lat: 47.954671, lng: 17.5760851 },
    { lat: 47.9548991, lng: 17.5774629 },
    { lat: 47.9550246, lng: 17.577679 },
    { lat: 47.9552253, lng: 17.5782709 },
    { lat: 47.9554325, lng: 17.5790409 },
    { lat: 47.9563317, lng: 17.5816958 },
    { lat: 47.9564319, lng: 17.5819765 },
    { lat: 47.957163, lng: 17.583064 },
    { lat: 47.9574527, lng: 17.5847179 },
    { lat: 47.9576269, lng: 17.5866684 },
    { lat: 47.957138, lng: 17.5895903 },
    { lat: 47.9579985, lng: 17.5901042 },
    { lat: 47.9585203, lng: 17.5907769 },
    { lat: 47.9597566, lng: 17.5916258 },
    { lat: 47.9597406, lng: 17.596934 },
    { lat: 47.959736, lng: 17.5975895 },
  ],
  Padáň: [
    { lat: 47.8867748, lng: 17.658154 },
    { lat: 47.8850256, lng: 17.6601485 },
    { lat: 47.8849697, lng: 17.6608532 },
    { lat: 47.8844895, lng: 17.6626171 },
    { lat: 47.8841407, lng: 17.6642448 },
    { lat: 47.883965, lng: 17.6646956 },
    { lat: 47.8834651, lng: 17.6655242 },
    { lat: 47.8829054, lng: 17.6660638 },
    { lat: 47.8824518, lng: 17.6663565 },
    { lat: 47.8817593, lng: 17.6666368 },
    { lat: 47.8811652, lng: 17.6668281 },
    { lat: 47.8808529, lng: 17.6668172 },
    { lat: 47.8804349, lng: 17.6666733 },
    { lat: 47.8799367, lng: 17.6663634 },
    { lat: 47.8795124, lng: 17.6659763 },
    { lat: 47.8788939, lng: 17.6652267 },
    { lat: 47.8786943, lng: 17.6651087 },
    { lat: 47.8784136, lng: 17.6650969 },
    { lat: 47.8768464, lng: 17.6665431 },
    { lat: 47.8766265, lng: 17.6668147 },
    { lat: 47.876326, lng: 17.6675819 },
    { lat: 47.8762609, lng: 17.667821 },
    { lat: 47.8764022, lng: 17.6697531 },
    { lat: 47.8765721, lng: 17.670424 },
    { lat: 47.8768501, lng: 17.6710692 },
    { lat: 47.877591, lng: 17.6719502 },
    { lat: 47.8779919, lng: 17.6723265 },
    { lat: 47.8793115, lng: 17.6731264 },
    { lat: 47.8794779, lng: 17.6734197 },
    { lat: 47.8795546, lng: 17.6737203 },
    { lat: 47.8795101, lng: 17.6740001 },
    { lat: 47.8793531, lng: 17.6743579 },
    { lat: 47.8785553, lng: 17.6748835 },
    { lat: 47.8778589, lng: 17.6751174 },
    { lat: 47.8770164, lng: 17.6755279 },
    { lat: 47.8763626, lng: 17.6761336 },
    { lat: 47.8761337, lng: 17.6767123 },
    { lat: 47.8758506, lng: 17.6778276 },
    { lat: 47.8758556, lng: 17.678443 },
    { lat: 47.8759221, lng: 17.6788153 },
    { lat: 47.8761647, lng: 17.6796896 },
    { lat: 47.877236, lng: 17.6810138 },
    { lat: 47.8786866, lng: 17.6822255 },
    { lat: 47.8791342, lng: 17.6829681 },
    { lat: 47.8795131, lng: 17.6837468 },
    { lat: 47.8795039, lng: 17.6839228 },
    { lat: 47.8796375, lng: 17.6839672 },
    { lat: 47.8823566, lng: 17.6849175 },
    { lat: 47.8832636, lng: 17.6854802 },
    { lat: 47.883901, lng: 17.6941122 },
    { lat: 47.8852799, lng: 17.6925943 },
    { lat: 47.8852332, lng: 17.6914014 },
    { lat: 47.88494, lng: 17.6903662 },
    { lat: 47.8847628, lng: 17.6872161 },
    { lat: 47.8850296, lng: 17.6868986 },
    { lat: 47.8855739, lng: 17.68552 },
    { lat: 47.886797, lng: 17.6812809 },
    { lat: 47.8872983, lng: 17.6804548 },
    { lat: 47.8878468, lng: 17.6796832 },
    { lat: 47.8886673, lng: 17.679622 },
    { lat: 47.8903912, lng: 17.6803887 },
    { lat: 47.8913302, lng: 17.6811517 },
    { lat: 47.8925364, lng: 17.6817829 },
    { lat: 47.8936953, lng: 17.6807179 },
    { lat: 47.8939889, lng: 17.6803238 },
    { lat: 47.8942874, lng: 17.6799212 },
    { lat: 47.8945828, lng: 17.6787669 },
    { lat: 47.8954765, lng: 17.6775425 },
    { lat: 47.8964703, lng: 17.6764888 },
    { lat: 47.9012197, lng: 17.6793616 },
    { lat: 47.9031666, lng: 17.680241 },
    { lat: 47.9051958, lng: 17.6815911 },
    { lat: 47.909141, lng: 17.6835459 },
    { lat: 47.9099593, lng: 17.6823578 },
    { lat: 47.9107354, lng: 17.6817727 },
    { lat: 47.9115744, lng: 17.6842846 },
    { lat: 47.9115129, lng: 17.6852606 },
    { lat: 47.912191, lng: 17.6867043 },
    { lat: 47.9146125, lng: 17.690197 },
    { lat: 47.9150226, lng: 17.6897503 },
    { lat: 47.9149844, lng: 17.6896624 },
    { lat: 47.9158093, lng: 17.6887961 },
    { lat: 47.9160808, lng: 17.689377 },
    { lat: 47.9162225, lng: 17.6892457 },
    { lat: 47.9161413, lng: 17.6890486 },
    { lat: 47.9162594, lng: 17.688934 },
    { lat: 47.9163873, lng: 17.6891154 },
    { lat: 47.9164263, lng: 17.6893264 },
    { lat: 47.9167154, lng: 17.6888412 },
    { lat: 47.9167719, lng: 17.6889147 },
    { lat: 47.9169931, lng: 17.6885161 },
    { lat: 47.9171577, lng: 17.6887106 },
    { lat: 47.9172656, lng: 17.6885224 },
    { lat: 47.9181362, lng: 17.6881125 },
    { lat: 47.9181527, lng: 17.688232 },
    { lat: 47.9181925, lng: 17.688217 },
    { lat: 47.9184523, lng: 17.6901183 },
    { lat: 47.9212171, lng: 17.6904931 },
    { lat: 47.9213727, lng: 17.6905605 },
    { lat: 47.921536, lng: 17.6906985 },
    { lat: 47.9216764, lng: 17.6904344 },
    { lat: 47.923246, lng: 17.6889683 },
    { lat: 47.9236543, lng: 17.6889234 },
    { lat: 47.9245839, lng: 17.689055 },
    { lat: 47.9253541, lng: 17.6887545 },
    { lat: 47.9258358, lng: 17.6884958 },
    { lat: 47.9260152, lng: 17.6889967 },
    { lat: 47.9263679, lng: 17.6887695 },
    { lat: 47.9267607, lng: 17.6896117 },
    { lat: 47.9274068, lng: 17.6906901 },
    { lat: 47.9278276, lng: 17.6904332 },
    { lat: 47.9286809, lng: 17.6901422 },
    { lat: 47.9297752, lng: 17.6927677 },
    { lat: 47.9300877, lng: 17.6934103 },
    { lat: 47.9301981, lng: 17.693333 },
    { lat: 47.9304395, lng: 17.6939218 },
    { lat: 47.9309584, lng: 17.6954757 },
    { lat: 47.9312513, lng: 17.6968681 },
    { lat: 47.9315692, lng: 17.6988531 },
    { lat: 47.9316054, lng: 17.6991589 },
    { lat: 47.9314386, lng: 17.6998996 },
    { lat: 47.931631, lng: 17.7010941 },
    { lat: 47.9326353, lng: 17.7007069 },
    { lat: 47.9326796, lng: 17.7018252 },
    { lat: 47.9332448, lng: 17.7028792 },
    { lat: 47.9350302, lng: 17.7016454 },
    { lat: 47.9364827, lng: 17.7029301 },
    { lat: 47.9368315, lng: 17.7023507 },
    { lat: 47.9371308, lng: 17.702589 },
    { lat: 47.9379893, lng: 17.7032769 },
    { lat: 47.9384287, lng: 17.7023471 },
    { lat: 47.9389517, lng: 17.701629 },
    { lat: 47.93927, lng: 17.7013403 },
    { lat: 47.9412604, lng: 17.7010676 },
    { lat: 47.9412013, lng: 17.7005086 },
    { lat: 47.9408097, lng: 17.6988021 },
    { lat: 47.9406335, lng: 17.6980453 },
    { lat: 47.940423, lng: 17.697463 },
    { lat: 47.9404397, lng: 17.6967962 },
    { lat: 47.9403374, lng: 17.6943073 },
    { lat: 47.9401171, lng: 17.6920084 },
    { lat: 47.9401886, lng: 17.6911136 },
    { lat: 47.9404083, lng: 17.6902287 },
    { lat: 47.940425, lng: 17.689975 },
    { lat: 47.940396, lng: 17.6890811 },
    { lat: 47.9401821, lng: 17.6875665 },
    { lat: 47.9402955, lng: 17.6868039 },
    { lat: 47.9401878, lng: 17.6865809 },
    { lat: 47.941528, lng: 17.6837643 },
    { lat: 47.9417855, lng: 17.6829956 },
    { lat: 47.9418736, lng: 17.6825273 },
    { lat: 47.9415383, lng: 17.6823498 },
    { lat: 47.9412505, lng: 17.682315 },
    { lat: 47.9402168, lng: 17.681913 },
    { lat: 47.9397725, lng: 17.6813845 },
    { lat: 47.9392582, lng: 17.6804094 },
    { lat: 47.9395391, lng: 17.680403 },
    { lat: 47.9397589, lng: 17.6795723 },
    { lat: 47.9401586, lng: 17.6788376 },
    { lat: 47.9406527, lng: 17.6782419 },
    { lat: 47.9410814, lng: 17.6775739 },
    { lat: 47.9415691, lng: 17.6765304 },
    { lat: 47.9420932, lng: 17.6766259 },
    { lat: 47.9447915, lng: 17.6776585 },
    { lat: 47.9451214, lng: 17.6765147 },
    { lat: 47.9457031, lng: 17.6749977 },
    { lat: 47.9465884, lng: 17.6735848 },
    { lat: 47.9463443, lng: 17.6730804 },
    { lat: 47.947153, lng: 17.6726716 },
    { lat: 47.9465324, lng: 17.6708704 },
    { lat: 47.9470166, lng: 17.6707069 },
    { lat: 47.9475173, lng: 17.6706965 },
    { lat: 47.9477243, lng: 17.6704147 },
    { lat: 47.9466384, lng: 17.6680579 },
    { lat: 47.9467536, lng: 17.6679158 },
    { lat: 47.9459039, lng: 17.666565 },
    { lat: 47.9454938, lng: 17.6660772 },
    { lat: 47.9449135, lng: 17.6670084 },
    { lat: 47.9444086, lng: 17.6675252 },
    { lat: 47.9437993, lng: 17.6676551 },
    { lat: 47.9435598, lng: 17.6669389 },
    { lat: 47.9418546, lng: 17.6639299 },
    { lat: 47.9402408, lng: 17.6613168 },
    { lat: 47.9391874, lng: 17.6611029 },
    { lat: 47.9384976, lng: 17.6610329 },
    { lat: 47.9356553, lng: 17.6583649 },
    { lat: 47.9347872, lng: 17.6529761 },
    { lat: 47.9358123, lng: 17.6515463 },
    { lat: 47.9363722, lng: 17.6512034 },
    { lat: 47.9369061, lng: 17.6507001 },
    { lat: 47.9378151, lng: 17.6494023 },
    { lat: 47.9382916, lng: 17.648508 },
    { lat: 47.9389973, lng: 17.645833 },
    { lat: 47.9383157, lng: 17.6451775 },
    { lat: 47.9369874, lng: 17.6455468 },
    { lat: 47.9361848, lng: 17.6452812 },
    { lat: 47.9358238, lng: 17.6455554 },
    { lat: 47.9353966, lng: 17.6461361 },
    { lat: 47.9341248, lng: 17.6490928 },
    { lat: 47.9317469, lng: 17.6508945 },
    { lat: 47.9315121, lng: 17.6507183 },
    { lat: 47.9304674, lng: 17.6505337 },
    { lat: 47.9299224, lng: 17.6505066 },
    { lat: 47.9294652, lng: 17.6505615 },
    { lat: 47.9288585, lng: 17.6507144 },
    { lat: 47.9283774, lng: 17.6509825 },
    { lat: 47.9280054, lng: 17.6512399 },
    { lat: 47.9278895, lng: 17.6515163 },
    { lat: 47.9258293, lng: 17.6481324 },
    { lat: 47.9227397, lng: 17.6416747 },
    { lat: 47.9216303, lng: 17.6422169 },
    { lat: 47.9210307, lng: 17.6424326 },
    { lat: 47.9203611, lng: 17.6424853 },
    { lat: 47.9198567, lng: 17.6423111 },
    { lat: 47.9183959, lng: 17.6413896 },
    { lat: 47.9178188, lng: 17.641442 },
    { lat: 47.9174496, lng: 17.6414248 },
    { lat: 47.9169714, lng: 17.6413095 },
    { lat: 47.9166257, lng: 17.6413235 },
    { lat: 47.9157632, lng: 17.6414868 },
    { lat: 47.9153594, lng: 17.6416349 },
    { lat: 47.9149018, lng: 17.641578 },
    { lat: 47.9143032, lng: 17.641877 },
    { lat: 47.9142251, lng: 17.6419819 },
    { lat: 47.9141998, lng: 17.6421357 },
    { lat: 47.9148649, lng: 17.6438456 },
    { lat: 47.9153399, lng: 17.6454797 },
    { lat: 47.9158721, lng: 17.6470906 },
    { lat: 47.9158896, lng: 17.6484908 },
    { lat: 47.9154765, lng: 17.6495982 },
    { lat: 47.914511, lng: 17.651539 },
    { lat: 47.9143328, lng: 17.6520619 },
    { lat: 47.913818, lng: 17.6531881 },
    { lat: 47.9131081, lng: 17.6540854 },
    { lat: 47.911308, lng: 17.655454 },
    { lat: 47.9108818, lng: 17.6554264 },
    { lat: 47.909191, lng: 17.6547797 },
    { lat: 47.9091245, lng: 17.6554749 },
    { lat: 47.9091351, lng: 17.6563567 },
    { lat: 47.909034, lng: 17.6567591 },
    { lat: 47.908291, lng: 17.6566867 },
    { lat: 47.9076116, lng: 17.65678 },
    { lat: 47.9066674, lng: 17.6565972 },
    { lat: 47.9059058, lng: 17.6568037 },
    { lat: 47.9051157, lng: 17.6577521 },
    { lat: 47.9045651, lng: 17.6586697 },
    { lat: 47.9041837, lng: 17.6594955 },
    { lat: 47.9036816, lng: 17.6608764 },
    { lat: 47.9030018, lng: 17.6630025 },
    { lat: 47.9022892, lng: 17.6644293 },
    { lat: 47.9018482, lng: 17.6649913 },
    { lat: 47.9009834, lng: 17.6657251 },
    { lat: 47.8992213, lng: 17.6666595 },
    { lat: 47.8983072, lng: 17.6670433 },
    { lat: 47.8973479, lng: 17.666783 },
    { lat: 47.8959457, lng: 17.6649815 },
    { lat: 47.8953362, lng: 17.6636598 },
    { lat: 47.8947433, lng: 17.6626573 },
    { lat: 47.8939539, lng: 17.6617798 },
    { lat: 47.8931458, lng: 17.6612503 },
    { lat: 47.8924213, lng: 17.6610068 },
    { lat: 47.8909536, lng: 17.6607075 },
    { lat: 47.8898436, lng: 17.6602422 },
    { lat: 47.8885602, lng: 17.6594968 },
    { lat: 47.8873321, lng: 17.6584327 },
    { lat: 47.886941, lng: 17.6579612 },
    { lat: 47.8868549, lng: 17.6580622 },
    { lat: 47.8867748, lng: 17.658154 },
  ],
  Baka: [
    { lat: 47.8654259, lng: 17.5268369 },
    { lat: 47.869373, lng: 17.526311 },
    { lat: 47.8696213, lng: 17.5274472 },
    { lat: 47.8694434, lng: 17.5288571 },
    { lat: 47.8703002, lng: 17.5284482 },
    { lat: 47.8708092, lng: 17.5280596 },
    { lat: 47.8722557, lng: 17.5293171 },
    { lat: 47.8725815, lng: 17.5297129 },
    { lat: 47.8733303, lng: 17.530449 },
    { lat: 47.8733026, lng: 17.5314736 },
    { lat: 47.8746772, lng: 17.5331443 },
    { lat: 47.8773518, lng: 17.5362042 },
    { lat: 47.8794698, lng: 17.5387255 },
    { lat: 47.879651, lng: 17.5385218 },
    { lat: 47.8802878, lng: 17.5382381 },
    { lat: 47.881021, lng: 17.5376808 },
    { lat: 47.8815524, lng: 17.5374114 },
    { lat: 47.8817602, lng: 17.5369186 },
    { lat: 47.8819161, lng: 17.5367458 },
    { lat: 47.8822919, lng: 17.5365651 },
    { lat: 47.8830667, lng: 17.5356538 },
    { lat: 47.8837275, lng: 17.5350783 },
    { lat: 47.883826, lng: 17.5349063 },
    { lat: 47.8839274, lng: 17.5343051 },
    { lat: 47.8839994, lng: 17.5343209 },
    { lat: 47.8848854, lng: 17.5369279 },
    { lat: 47.8859117, lng: 17.5401357 },
    { lat: 47.8862734, lng: 17.5413332 },
    { lat: 47.8863842, lng: 17.5418818 },
    { lat: 47.8869696, lng: 17.5435573 },
    { lat: 47.8880271, lng: 17.5452502 },
    { lat: 47.8902496, lng: 17.5482775 },
    { lat: 47.8909575, lng: 17.5496973 },
    { lat: 47.8921964, lng: 17.550737 },
    { lat: 47.8939087, lng: 17.5525691 },
    { lat: 47.8957399, lng: 17.5530724 },
    { lat: 47.8957283, lng: 17.5531764 },
    { lat: 47.8967281, lng: 17.5531288 },
    { lat: 47.8974257, lng: 17.5529995 },
    { lat: 47.8974965, lng: 17.5531355 },
    { lat: 47.8992835, lng: 17.5550087 },
    { lat: 47.9013281, lng: 17.5555656 },
    { lat: 47.9016049, lng: 17.5545473 },
    { lat: 47.9017875, lng: 17.5542578 },
    { lat: 47.9021119, lng: 17.5541403 },
    { lat: 47.9027068, lng: 17.5540855 },
    { lat: 47.9031216, lng: 17.5541431 },
    { lat: 47.9035948, lng: 17.5539499 },
    { lat: 47.9037771, lng: 17.553727 },
    { lat: 47.9041581, lng: 17.5530489 },
    { lat: 47.9042739, lng: 17.5527436 },
    { lat: 47.9045761, lng: 17.5528224 },
    { lat: 47.9078473, lng: 17.5514461 },
    { lat: 47.9108496, lng: 17.5490142 },
    { lat: 47.9123283, lng: 17.5484764 },
    { lat: 47.9142778, lng: 17.5487648 },
    { lat: 47.9158638, lng: 17.5497028 },
    { lat: 47.9174788, lng: 17.5500922 },
    { lat: 47.9206489, lng: 17.5510303 },
    { lat: 47.9204144, lng: 17.5492419 },
    { lat: 47.9200632, lng: 17.5459368 },
    { lat: 47.9210442, lng: 17.5428515 },
    { lat: 47.9199893, lng: 17.5411283 },
    { lat: 47.9196864, lng: 17.5404222 },
    { lat: 47.9196261, lng: 17.539097 },
    { lat: 47.9193612, lng: 17.5369978 },
    { lat: 47.9194861, lng: 17.5357426 },
    { lat: 47.9199661, lng: 17.5352694 },
    { lat: 47.9185697, lng: 17.5328758 },
    { lat: 47.9197551, lng: 17.5296218 },
    { lat: 47.9192941, lng: 17.5289855 },
    { lat: 47.9189652, lng: 17.5281047 },
    { lat: 47.9187506, lng: 17.5271746 },
    { lat: 47.9186846, lng: 17.5261508 },
    { lat: 47.91745, lng: 17.524324 },
    { lat: 47.9153138, lng: 17.5214034 },
    { lat: 47.9139188, lng: 17.5196125 },
    { lat: 47.9133572, lng: 17.5190108 },
    { lat: 47.9120998, lng: 17.5168663 },
    { lat: 47.9113035, lng: 17.5156923 },
    { lat: 47.9108402, lng: 17.5150393 },
    { lat: 47.9102448, lng: 17.5145112 },
    { lat: 47.910211, lng: 17.5143868 },
    { lat: 47.9100164, lng: 17.5141921 },
    { lat: 47.909574, lng: 17.5135302 },
    { lat: 47.9088044, lng: 17.5123311 },
    { lat: 47.9081782, lng: 17.5111161 },
    { lat: 47.907992, lng: 17.5110142 },
    { lat: 47.9075364, lng: 17.5104116 },
    { lat: 47.9071712, lng: 17.5100555 },
    { lat: 47.9065928, lng: 17.5097175 },
    { lat: 47.9050663, lng: 17.5092502 },
    { lat: 47.9038054, lng: 17.5085938 },
    { lat: 47.9038991, lng: 17.5080011 },
    { lat: 47.9041077, lng: 17.5071921 },
    { lat: 47.9041515, lng: 17.5065073 },
    { lat: 47.903993, lng: 17.5051341 },
    { lat: 47.9042505, lng: 17.504366 },
    { lat: 47.9045101, lng: 17.5039811 },
    { lat: 47.9045533, lng: 17.5039507 },
    { lat: 47.9040906, lng: 17.5036564 },
    { lat: 47.9031985, lng: 17.5032733 },
    { lat: 47.901688, lng: 17.5028638 },
    { lat: 47.8992333, lng: 17.5023296 },
    { lat: 47.8990252, lng: 17.4998611 },
    { lat: 47.8987958, lng: 17.4988797 },
    { lat: 47.8979789, lng: 17.4963777 },
    { lat: 47.8963057, lng: 17.4981654 },
    { lat: 47.8947967, lng: 17.4957378 },
    { lat: 47.8943029, lng: 17.4936374 },
    { lat: 47.8936935, lng: 17.4933836 },
    { lat: 47.892688, lng: 17.4937105 },
    { lat: 47.892235, lng: 17.4937612 },
    { lat: 47.8910836, lng: 17.4937335 },
    { lat: 47.8902658, lng: 17.4938329 },
    { lat: 47.8901965, lng: 17.4937053 },
    { lat: 47.8883797, lng: 17.4923172 },
    { lat: 47.8830455, lng: 17.4883319 },
    { lat: 47.8805299, lng: 17.4779779 },
    { lat: 47.8794016, lng: 17.4738581 },
    { lat: 47.879161, lng: 17.475191 },
    { lat: 47.878721, lng: 17.476811 },
    { lat: 47.878155, lng: 17.478343 },
    { lat: 47.877223, lng: 17.48039 },
    { lat: 47.875121, lng: 17.484347 },
    { lat: 47.871879, lng: 17.490098 },
    { lat: 47.871055, lng: 17.491394 },
    { lat: 47.86938, lng: 17.493634 },
    { lat: 47.868756, lng: 17.494617 },
    { lat: 47.868478, lng: 17.49515 },
    { lat: 47.867989, lng: 17.49629 },
    { lat: 47.867603, lng: 17.497512 },
    { lat: 47.867449, lng: 17.498147 },
    { lat: 47.86712, lng: 17.499985 },
    { lat: 47.866799, lng: 17.502391 },
    { lat: 47.866592, lng: 17.504823 },
    { lat: 47.866502, lng: 17.507271 },
    { lat: 47.866558, lng: 17.51334 },
    { lat: 47.866632, lng: 17.514647 },
    { lat: 47.866824, lng: 17.515929 },
    { lat: 47.867417, lng: 17.518364 },
    { lat: 47.867508, lng: 17.518988 },
    { lat: 47.867585, lng: 17.520259 },
    { lat: 47.867519, lng: 17.521531 },
    { lat: 47.867433, lng: 17.522157 },
    { lat: 47.867157, lng: 17.523366 },
    { lat: 47.866947, lng: 17.523991 },
    { lat: 47.866445, lng: 17.525172 },
    { lat: 47.865844, lng: 17.526246 },
    { lat: 47.865509, lng: 17.526737 },
    { lat: 47.8654259, lng: 17.5268369 },
  ],
  Pataš: [
    { lat: 47.8525201, lng: 17.6684926 },
    { lat: 47.8546058, lng: 17.6685338 },
    { lat: 47.8571425, lng: 17.6686881 },
    { lat: 47.8570691, lng: 17.669205 },
    { lat: 47.8571196, lng: 17.6700743 },
    { lat: 47.8571887, lng: 17.670438 },
    { lat: 47.8570819, lng: 17.6713923 },
    { lat: 47.8570805, lng: 17.6731565 },
    { lat: 47.8569778, lng: 17.6736943 },
    { lat: 47.8568038, lng: 17.6742814 },
    { lat: 47.856739, lng: 17.67472 },
    { lat: 47.8570997, lng: 17.6750716 },
    { lat: 47.857636, lng: 17.675444 },
    { lat: 47.8577, lng: 17.6759223 },
    { lat: 47.8577101, lng: 17.6767607 },
    { lat: 47.8580541, lng: 17.6777376 },
    { lat: 47.8579758, lng: 17.6780195 },
    { lat: 47.8576392, lng: 17.6783575 },
    { lat: 47.8574716, lng: 17.6787433 },
    { lat: 47.8575299, lng: 17.6793961 },
    { lat: 47.8576547, lng: 17.6797848 },
    { lat: 47.8580039, lng: 17.6800012 },
    { lat: 47.8581256, lng: 17.6802662 },
    { lat: 47.8580058, lng: 17.6805149 },
    { lat: 47.8575147, lng: 17.6810716 },
    { lat: 47.8574954, lng: 17.681197 },
    { lat: 47.8577884, lng: 17.6815805 },
    { lat: 47.8580937, lng: 17.6817475 },
    { lat: 47.858395, lng: 17.6817084 },
    { lat: 47.8584957, lng: 17.6818378 },
    { lat: 47.8585552, lng: 17.6819743 },
    { lat: 47.8585972, lng: 17.6823211 },
    { lat: 47.8586196, lng: 17.6831047 },
    { lat: 47.8586577, lng: 17.6832956 },
    { lat: 47.8588131, lng: 17.6836146 },
    { lat: 47.8589758, lng: 17.6842958 },
    { lat: 47.8589977, lng: 17.6848563 },
    { lat: 47.8589124, lng: 17.6855822 },
    { lat: 47.8587755, lng: 17.6859366 },
    { lat: 47.8583336, lng: 17.6867515 },
    { lat: 47.8582028, lng: 17.6871135 },
    { lat: 47.8581858, lng: 17.6873272 },
    { lat: 47.8583702, lng: 17.6887998 },
    { lat: 47.8583329, lng: 17.6892673 },
    { lat: 47.8580987, lng: 17.6902618 },
    { lat: 47.8581344, lng: 17.6904347 },
    { lat: 47.858548, lng: 17.6910507 },
    { lat: 47.8586941, lng: 17.6914139 },
    { lat: 47.8587707, lng: 17.6917538 },
    { lat: 47.8589912, lng: 17.692062 },
    { lat: 47.86314, lng: 17.6915528 },
    { lat: 47.8643388, lng: 17.6914591 },
    { lat: 47.865567, lng: 17.6948774 },
    { lat: 47.8656274, lng: 17.6950139 },
    { lat: 47.8673157, lng: 17.6935485 },
    { lat: 47.8676523, lng: 17.6931743 },
    { lat: 47.8677532, lng: 17.6929665 },
    { lat: 47.8679346, lng: 17.6922585 },
    { lat: 47.8681467, lng: 17.6910922 },
    { lat: 47.868183, lng: 17.6905048 },
    { lat: 47.8683515, lng: 17.6897205 },
    { lat: 47.868584, lng: 17.6892342 },
    { lat: 47.8687574, lng: 17.6890321 },
    { lat: 47.8689033, lng: 17.6886395 },
    { lat: 47.8690698, lng: 17.6885934 },
    { lat: 47.869399, lng: 17.6886784 },
    { lat: 47.8696763, lng: 17.6888849 },
    { lat: 47.8704483, lng: 17.689973 },
    { lat: 47.8708616, lng: 17.6910852 },
    { lat: 47.8710138, lng: 17.6914946 },
    { lat: 47.8713641, lng: 17.6931457 },
    { lat: 47.8714939, lng: 17.6940613 },
    { lat: 47.8716195, lng: 17.6945305 },
    { lat: 47.8718438, lng: 17.6947602 },
    { lat: 47.8720361, lng: 17.694846 },
    { lat: 47.8728433, lng: 17.6952059 },
    { lat: 47.87331, lng: 17.695414 },
    { lat: 47.8740798, lng: 17.6958801 },
    { lat: 47.8745624, lng: 17.6959396 },
    { lat: 47.8748423, lng: 17.6960291 },
    { lat: 47.8751968, lng: 17.6958127 },
    { lat: 47.8758374, lng: 17.6952429 },
    { lat: 47.8760343, lng: 17.6950006 },
    { lat: 47.8762066, lng: 17.694637 },
    { lat: 47.8763601, lng: 17.6942555 },
    { lat: 47.8764639, lng: 17.693683 },
    { lat: 47.8764254, lng: 17.6929108 },
    { lat: 47.876384, lng: 17.6925909 },
    { lat: 47.8762751, lng: 17.6922442 },
    { lat: 47.8756378, lng: 17.690533 },
    { lat: 47.874898, lng: 17.6887204 },
    { lat: 47.8743863, lng: 17.6877479 },
    { lat: 47.8741238, lng: 17.6866992 },
    { lat: 47.8741504, lng: 17.6864606 },
    { lat: 47.8742795, lng: 17.6862337 },
    { lat: 47.8744554, lng: 17.6861572 },
    { lat: 47.874597, lng: 17.6862004 },
    { lat: 47.8747401, lng: 17.6863373 },
    { lat: 47.8750064, lng: 17.686839 },
    { lat: 47.8751954, lng: 17.6870796 },
    { lat: 47.875586, lng: 17.6873635 },
    { lat: 47.8769023, lng: 17.6876476 },
    { lat: 47.8772707, lng: 17.6874191 },
    { lat: 47.8785537, lng: 17.686133 },
    { lat: 47.8788475, lng: 17.6857604 },
    { lat: 47.8794302, lng: 17.6844733 },
    { lat: 47.8795039, lng: 17.6839228 },
    { lat: 47.8795131, lng: 17.6837468 },
    { lat: 47.8791342, lng: 17.6829681 },
    { lat: 47.8786866, lng: 17.6822255 },
    { lat: 47.877236, lng: 17.6810138 },
    { lat: 47.8761647, lng: 17.6796896 },
    { lat: 47.8759221, lng: 17.6788153 },
    { lat: 47.8758556, lng: 17.678443 },
    { lat: 47.8758506, lng: 17.6778276 },
    { lat: 47.8761337, lng: 17.6767123 },
    { lat: 47.8763626, lng: 17.6761336 },
    { lat: 47.8770164, lng: 17.6755279 },
    { lat: 47.8778589, lng: 17.6751174 },
    { lat: 47.8785553, lng: 17.6748835 },
    { lat: 47.8793531, lng: 17.6743579 },
    { lat: 47.8795101, lng: 17.6740001 },
    { lat: 47.8795546, lng: 17.6737203 },
    { lat: 47.8794779, lng: 17.6734197 },
    { lat: 47.8793115, lng: 17.6731264 },
    { lat: 47.8779919, lng: 17.6723265 },
    { lat: 47.877591, lng: 17.6719502 },
    { lat: 47.8768501, lng: 17.6710692 },
    { lat: 47.8765721, lng: 17.670424 },
    { lat: 47.8764022, lng: 17.6697531 },
    { lat: 47.8762609, lng: 17.667821 },
    { lat: 47.876326, lng: 17.6675819 },
    { lat: 47.8766265, lng: 17.6668147 },
    { lat: 47.8768464, lng: 17.6665431 },
    { lat: 47.8784136, lng: 17.6650969 },
    { lat: 47.8786943, lng: 17.6651087 },
    { lat: 47.8788939, lng: 17.6652267 },
    { lat: 47.8795124, lng: 17.6659763 },
    { lat: 47.8799367, lng: 17.6663634 },
    { lat: 47.8804349, lng: 17.6666733 },
    { lat: 47.8808529, lng: 17.6668172 },
    { lat: 47.8811652, lng: 17.6668281 },
    { lat: 47.8817593, lng: 17.6666368 },
    { lat: 47.8824518, lng: 17.6663565 },
    { lat: 47.8829054, lng: 17.6660638 },
    { lat: 47.8834651, lng: 17.6655242 },
    { lat: 47.883965, lng: 17.6646956 },
    { lat: 47.8841407, lng: 17.6642448 },
    { lat: 47.8844895, lng: 17.6626171 },
    { lat: 47.8849697, lng: 17.6608532 },
    { lat: 47.8850256, lng: 17.6601485 },
    { lat: 47.8867748, lng: 17.658154 },
    { lat: 47.886462, lng: 17.6575473 },
    { lat: 47.8861841, lng: 17.6568461 },
    { lat: 47.8859704, lng: 17.6560574 },
    { lat: 47.8859373, lng: 17.6557138 },
    { lat: 47.8859008, lng: 17.6551885 },
    { lat: 47.8852111, lng: 17.6554067 },
    { lat: 47.8843628, lng: 17.6549756 },
    { lat: 47.8830794, lng: 17.6533567 },
    { lat: 47.8813064, lng: 17.6514638 },
    { lat: 47.8808647, lng: 17.6516206 },
    { lat: 47.8804864, lng: 17.6515314 },
    { lat: 47.8800582, lng: 17.6508119 },
    { lat: 47.8802716, lng: 17.6485142 },
    { lat: 47.8804625, lng: 17.6475167 },
    { lat: 47.8804914, lng: 17.6459936 },
    { lat: 47.880318, lng: 17.6452557 },
    { lat: 47.8799316, lng: 17.6445758 },
    { lat: 47.8793472, lng: 17.6441026 },
    { lat: 47.8773315, lng: 17.6453258 },
    { lat: 47.8770826, lng: 17.6449791 },
    { lat: 47.8769634, lng: 17.644738 },
    { lat: 47.876778, lng: 17.6440416 },
    { lat: 47.8765963, lng: 17.643027 },
    { lat: 47.8757589, lng: 17.642 },
    { lat: 47.8755746, lng: 17.6414817 },
    { lat: 47.8754524, lng: 17.6407111 },
    { lat: 47.8754236, lng: 17.6403996 },
    { lat: 47.8754862, lng: 17.6399084 },
    { lat: 47.8760717, lng: 17.6384944 },
    { lat: 47.8761816, lng: 17.6380921 },
    { lat: 47.8762127, lng: 17.6378528 },
    { lat: 47.8761839, lng: 17.6376663 },
    { lat: 47.8761107, lng: 17.6374191 },
    { lat: 47.8760301, lng: 17.6373238 },
    { lat: 47.8758876, lng: 17.6372675 },
    { lat: 47.8754076, lng: 17.6373172 },
    { lat: 47.8740058, lng: 17.637934 },
    { lat: 47.8738549, lng: 17.6379376 },
    { lat: 47.8732621, lng: 17.6378136 },
    { lat: 47.8732109, lng: 17.6377198 },
    { lat: 47.8728808, lng: 17.6375354 },
    { lat: 47.8724735, lng: 17.6370421 },
    { lat: 47.8721447, lng: 17.6362383 },
    { lat: 47.8721092, lng: 17.6359292 },
    { lat: 47.8721861, lng: 17.6352541 },
    { lat: 47.8724819, lng: 17.6339547 },
    { lat: 47.8723554, lng: 17.6335082 },
    { lat: 47.8722346, lng: 17.6319082 },
    { lat: 47.8723275, lng: 17.6312583 },
    { lat: 47.8711545, lng: 17.6287017 },
    { lat: 47.8707956, lng: 17.6286321 },
    { lat: 47.8700301, lng: 17.6288989 },
    { lat: 47.8700709, lng: 17.6294892 },
    { lat: 47.8699302, lng: 17.6295139 },
    { lat: 47.8697871, lng: 17.6296968 },
    { lat: 47.8698582, lng: 17.6302649 },
    { lat: 47.8695101, lng: 17.6312351 },
    { lat: 47.8694916, lng: 17.6313921 },
    { lat: 47.8695393, lng: 17.6315018 },
    { lat: 47.8695895, lng: 17.6319716 },
    { lat: 47.8695001, lng: 17.632449 },
    { lat: 47.8693575, lng: 17.6331544 },
    { lat: 47.8691278, lng: 17.6337327 },
    { lat: 47.8689111, lng: 17.634515 },
    { lat: 47.8688183, lng: 17.634638 },
    { lat: 47.868593, lng: 17.6347526 },
    { lat: 47.868442, lng: 17.6349524 },
    { lat: 47.8681896, lng: 17.6355835 },
    { lat: 47.8680585, lng: 17.6357774 },
    { lat: 47.8677126, lng: 17.6359862 },
    { lat: 47.8673684, lng: 17.6364505 },
    { lat: 47.8668441, lng: 17.636749 },
    { lat: 47.8666468, lng: 17.6370545 },
    { lat: 47.8664171, lng: 17.6376335 },
    { lat: 47.8657637, lng: 17.6384216 },
    { lat: 47.8653881, lng: 17.6386772 },
    { lat: 47.8645744, lng: 17.6384347 },
    { lat: 47.8643036, lng: 17.6382764 },
    { lat: 47.8641088, lng: 17.6382675 },
    { lat: 47.8634831, lng: 17.6386203 },
    { lat: 47.8633803, lng: 17.6389586 },
    { lat: 47.8631486, lng: 17.6393117 },
    { lat: 47.8630242, lng: 17.6397897 },
    { lat: 47.8630929, lng: 17.6401082 },
    { lat: 47.8634769, lng: 17.6408571 },
    { lat: 47.86422, lng: 17.6416759 },
    { lat: 47.8644347, lng: 17.6421775 },
    { lat: 47.8644649, lng: 17.6426012 },
    { lat: 47.8644398, lng: 17.6429327 },
    { lat: 47.864367, lng: 17.6431202 },
    { lat: 47.864252, lng: 17.6432846 },
    { lat: 47.8638789, lng: 17.6435988 },
    { lat: 47.8635381, lng: 17.643795 },
    { lat: 47.8631871, lng: 17.6438533 },
    { lat: 47.8623733, lng: 17.6431283 },
    { lat: 47.8617037, lng: 17.6428076 },
    { lat: 47.8615201, lng: 17.6427581 },
    { lat: 47.8609349, lng: 17.6427904 },
    { lat: 47.8607068, lng: 17.6429507 },
    { lat: 47.8603824, lng: 17.6434441 },
    { lat: 47.8600253, lng: 17.6444019 },
    { lat: 47.8598681, lng: 17.6451277 },
    { lat: 47.8598033, lng: 17.6457247 },
    { lat: 47.8595323, lng: 17.646579 },
    { lat: 47.8596474, lng: 17.6475585 },
    { lat: 47.8597061, lng: 17.6477373 },
    { lat: 47.8602017, lng: 17.6481992 },
    { lat: 47.8608499, lng: 17.6486636 },
    { lat: 47.8610045, lng: 17.6489113 },
    { lat: 47.8611157, lng: 17.6492407 },
    { lat: 47.8612861, lng: 17.6500399 },
    { lat: 47.8615689, lng: 17.6508315 },
    { lat: 47.8619124, lng: 17.651354 },
    { lat: 47.8621983, lng: 17.6515531 },
    { lat: 47.8626199, lng: 17.6515233 },
    { lat: 47.8635609, lng: 17.6516543 },
    { lat: 47.8638547, lng: 17.6517658 },
    { lat: 47.8639931, lng: 17.6520074 },
    { lat: 47.8639862, lng: 17.652182 },
    { lat: 47.8638126, lng: 17.6525277 },
    { lat: 47.8637998, lng: 17.6527889 },
    { lat: 47.8639188, lng: 17.6532955 },
    { lat: 47.8638033, lng: 17.6541808 },
    { lat: 47.8638682, lng: 17.6545293 },
    { lat: 47.8641181, lng: 17.6551772 },
    { lat: 47.8640394, lng: 17.6556263 },
    { lat: 47.8637555, lng: 17.6563857 },
    { lat: 47.863656, lng: 17.657382 },
    { lat: 47.8632215, lng: 17.6585098 },
    { lat: 47.8626719, lng: 17.6593552 },
    { lat: 47.8623583, lng: 17.6597489 },
    { lat: 47.8616319, lng: 17.6609568 },
    { lat: 47.8613408, lng: 17.6611578 },
    { lat: 47.8602129, lng: 17.6612851 },
    { lat: 47.8598866, lng: 17.6612847 },
    { lat: 47.8593747, lng: 17.6611705 },
    { lat: 47.8585541, lng: 17.661405 },
    { lat: 47.8582708, lng: 17.6615487 },
    { lat: 47.8571509, lng: 17.6624265 },
    { lat: 47.8566849, lng: 17.6627015 },
    { lat: 47.8563569, lng: 17.6630668 },
    { lat: 47.8559654, lng: 17.6633757 },
    { lat: 47.8554082, lng: 17.6634718 },
    { lat: 47.8544032, lng: 17.6640234 },
    { lat: 47.8539676, lng: 17.6641169 },
    { lat: 47.8536424, lng: 17.6640474 },
    { lat: 47.8533683, lng: 17.6638374 },
    { lat: 47.8531535, lng: 17.6637767 },
    { lat: 47.8525705, lng: 17.6683077 },
    { lat: 47.8525201, lng: 17.6684926 },
  ],
  Jurová: [
    { lat: 47.9206489, lng: 17.5510303 },
    { lat: 47.9251742, lng: 17.5547123 },
    { lat: 47.9253518, lng: 17.5553338 },
    { lat: 47.9255772, lng: 17.5558194 },
    { lat: 47.9259753, lng: 17.5562156 },
    { lat: 47.9266294, lng: 17.5563974 },
    { lat: 47.9268249, lng: 17.5566453 },
    { lat: 47.9268469, lng: 17.556707 },
    { lat: 47.9272895, lng: 17.5561266 },
    { lat: 47.9277107, lng: 17.555702 },
    { lat: 47.9281846, lng: 17.5553978 },
    { lat: 47.9285285, lng: 17.5553877 },
    { lat: 47.9289108, lng: 17.5559249 },
    { lat: 47.929199, lng: 17.5561051 },
    { lat: 47.9297259, lng: 17.5562219 },
    { lat: 47.9299167, lng: 17.5561863 },
    { lat: 47.9303529, lng: 17.5559621 },
    { lat: 47.9307356, lng: 17.5556609 },
    { lat: 47.931112, lng: 17.5551385 },
    { lat: 47.9313285, lng: 17.5552204 },
    { lat: 47.9318048, lng: 17.5548654 },
    { lat: 47.9321459, lng: 17.5542842 },
    { lat: 47.9327604, lng: 17.5527924 },
    { lat: 47.9329312, lng: 17.5525153 },
    { lat: 47.9333301, lng: 17.55236 },
    { lat: 47.9342566, lng: 17.5531242 },
    { lat: 47.9358126, lng: 17.5549551 },
    { lat: 47.9367287, lng: 17.5558022 },
    { lat: 47.9382208, lng: 17.5574279 },
    { lat: 47.9388708, lng: 17.5579146 },
    { lat: 47.9396864, lng: 17.5588877 },
    { lat: 47.9399299, lng: 17.5590944 },
    { lat: 47.9401768, lng: 17.5592988 },
    { lat: 47.9403381, lng: 17.558523 },
    { lat: 47.9405371, lng: 17.5581024 },
    { lat: 47.9409079, lng: 17.5577029 },
    { lat: 47.9412897, lng: 17.5574057 },
    { lat: 47.9417911, lng: 17.557158 },
    { lat: 47.9423339, lng: 17.5570641 },
    { lat: 47.9426468, lng: 17.5571549 },
    { lat: 47.9427474, lng: 17.5572096 },
    { lat: 47.9427744, lng: 17.5571882 },
    { lat: 47.9433774, lng: 17.5560974 },
    { lat: 47.9436053, lng: 17.5553374 },
    { lat: 47.943736, lng: 17.5542052 },
    { lat: 47.9437268, lng: 17.5537807 },
    { lat: 47.943607, lng: 17.5527732 },
    { lat: 47.9434338, lng: 17.5520793 },
    { lat: 47.9429449, lng: 17.5511823 },
    { lat: 47.9426431, lng: 17.5509336 },
    { lat: 47.9433297, lng: 17.5484879 },
    { lat: 47.943529, lng: 17.5472822 },
    { lat: 47.9433894, lng: 17.5471129 },
    { lat: 47.9434628, lng: 17.5465823 },
    { lat: 47.9435435, lng: 17.5462408 },
    { lat: 47.9439962, lng: 17.5452706 },
    { lat: 47.9447409, lng: 17.5444166 },
    { lat: 47.9463105, lng: 17.5437664 },
    { lat: 47.9471244, lng: 17.5429867 },
    { lat: 47.9470485, lng: 17.5425611 },
    { lat: 47.9470847, lng: 17.5421738 },
    { lat: 47.947332, lng: 17.5415584 },
    { lat: 47.948039, lng: 17.5405227 },
    { lat: 47.9484558, lng: 17.5399966 },
    { lat: 47.9482919, lng: 17.5394687 },
    { lat: 47.9478823, lng: 17.5366627 },
    { lat: 47.9474149, lng: 17.5342583 },
    { lat: 47.9475827, lng: 17.5337219 },
    { lat: 47.9475831, lng: 17.5331308 },
    { lat: 47.9475376, lng: 17.532075 },
    { lat: 47.9474061, lng: 17.5308549 },
    { lat: 47.9470043, lng: 17.5292944 },
    { lat: 47.946554, lng: 17.5288436 },
    { lat: 47.9461093, lng: 17.5289901 },
    { lat: 47.9459136, lng: 17.5289497 },
    { lat: 47.9459075, lng: 17.5255562 },
    { lat: 47.9461611, lng: 17.5242133 },
    { lat: 47.9464046, lng: 17.5233649 },
    { lat: 47.9474483, lng: 17.5215982 },
    { lat: 47.9477842, lng: 17.5213065 },
    { lat: 47.9483779, lng: 17.5215109 },
    { lat: 47.9489021, lng: 17.5222354 },
    { lat: 47.9495647, lng: 17.5228274 },
    { lat: 47.9501069, lng: 17.5229677 },
    { lat: 47.9505231, lng: 17.5226991 },
    { lat: 47.950733, lng: 17.5221354 },
    { lat: 47.9506346, lng: 17.5209937 },
    { lat: 47.9501255, lng: 17.5195929 },
    { lat: 47.9502314, lng: 17.5190628 },
    { lat: 47.9506313, lng: 17.5182582 },
    { lat: 47.9507346, lng: 17.5172687 },
    { lat: 47.9504246, lng: 17.51591 },
    { lat: 47.9501665, lng: 17.5153938 },
    { lat: 47.9496343, lng: 17.5150052 },
    { lat: 47.9491205, lng: 17.5147584 },
    { lat: 47.9471544, lng: 17.5141819 },
    { lat: 47.9464705, lng: 17.5139249 },
    { lat: 47.9447661, lng: 17.5128186 },
    { lat: 47.9440703, lng: 17.5120625 },
    { lat: 47.9435697, lng: 17.5113239 },
    { lat: 47.9433318, lng: 17.5101234 },
    { lat: 47.9429746, lng: 17.5095895 },
    { lat: 47.9424512, lng: 17.5094072 },
    { lat: 47.9418578, lng: 17.5089281 },
    { lat: 47.9406296, lng: 17.5078136 },
    { lat: 47.940327, lng: 17.5071887 },
    { lat: 47.9395433, lng: 17.5058589 },
    { lat: 47.9389414, lng: 17.5051423 },
    { lat: 47.9387395, lng: 17.5040794 },
    { lat: 47.9384197, lng: 17.502832 },
    { lat: 47.9383906, lng: 17.5025068 },
    { lat: 47.9384225, lng: 17.5021434 },
    { lat: 47.9384825, lng: 17.5018291 },
    { lat: 47.9388732, lng: 17.5007822 },
    { lat: 47.9390014, lng: 17.500195 },
    { lat: 47.9391621, lng: 17.499082 },
    { lat: 47.9392599, lng: 17.4986732 },
    { lat: 47.9394723, lng: 17.4981832 },
    { lat: 47.9398022, lng: 17.4978032 },
    { lat: 47.9399976, lng: 17.4977139 },
    { lat: 47.9404074, lng: 17.497713 },
    { lat: 47.9406904, lng: 17.4977926 },
    { lat: 47.9419952, lng: 17.4942559 },
    { lat: 47.9428022, lng: 17.4914343 },
    { lat: 47.9434498, lng: 17.4889633 },
    { lat: 47.9437405, lng: 17.48707 },
    { lat: 47.9437577, lng: 17.4869516 },
    { lat: 47.9434056, lng: 17.4864843 },
    { lat: 47.9432698, lng: 17.4864267 },
    { lat: 47.9427669, lng: 17.4864928 },
    { lat: 47.9422081, lng: 17.4866705 },
    { lat: 47.9403451, lng: 17.4869922 },
    { lat: 47.9396566, lng: 17.4876935 },
    { lat: 47.9395284, lng: 17.4875477 },
    { lat: 47.9392139, lng: 17.4873618 },
    { lat: 47.9370618, lng: 17.487267 },
    { lat: 47.9360759, lng: 17.4871242 },
    { lat: 47.9354272, lng: 17.4869449 },
    { lat: 47.9351719, lng: 17.4868389 },
    { lat: 47.9346508, lng: 17.4895714 },
    { lat: 47.9335539, lng: 17.4945062 },
    { lat: 47.9315695, lng: 17.4996921 },
    { lat: 47.9302951, lng: 17.5015031 },
    { lat: 47.9301189, lng: 17.5015902 },
    { lat: 47.9297592, lng: 17.5039421 },
    { lat: 47.9293935, lng: 17.5044321 },
    { lat: 47.9291811, lng: 17.505185 },
    { lat: 47.9290975, lng: 17.506022 },
    { lat: 47.9285488, lng: 17.5071786 },
    { lat: 47.9281803, lng: 17.5082587 },
    { lat: 47.9281247, lng: 17.5091092 },
    { lat: 47.9280127, lng: 17.5098133 },
    { lat: 47.9278737, lng: 17.5112412 },
    { lat: 47.9273303, lng: 17.5128135 },
    { lat: 47.9272068, lng: 17.513453 },
    { lat: 47.9270633, lng: 17.5143649 },
    { lat: 47.9267974, lng: 17.5148103 },
    { lat: 47.9263233, lng: 17.516549 },
    { lat: 47.9260999, lng: 17.5179023 },
    { lat: 47.9260453, lng: 17.5198374 },
    { lat: 47.9242112, lng: 17.528025 },
    { lat: 47.9234595, lng: 17.531626 },
    { lat: 47.9224874, lng: 17.5313325 },
    { lat: 47.9207033, lng: 17.5304569 },
    { lat: 47.9197551, lng: 17.5296218 },
    { lat: 47.9185697, lng: 17.5328758 },
    { lat: 47.9199661, lng: 17.5352694 },
    { lat: 47.9194861, lng: 17.5357426 },
    { lat: 47.9193612, lng: 17.5369978 },
    { lat: 47.9196261, lng: 17.539097 },
    { lat: 47.9196864, lng: 17.5404222 },
    { lat: 47.9199893, lng: 17.5411283 },
    { lat: 47.9210442, lng: 17.5428515 },
    { lat: 47.9200632, lng: 17.5459368 },
    { lat: 47.9204144, lng: 17.5492419 },
    { lat: 47.9206489, lng: 17.5510303 },
  ],
  DolnýBar: [
    { lat: 47.9782665, lng: 17.7024148 },
    { lat: 47.9773893, lng: 17.7021482 },
    { lat: 47.9758205, lng: 17.7019421 },
    { lat: 47.9730083, lng: 17.6988169 },
    { lat: 47.9718068, lng: 17.6968734 },
    { lat: 47.9712203, lng: 17.6960176 },
    { lat: 47.9691182, lng: 17.6934021 },
    { lat: 47.9687091, lng: 17.6914009 },
    { lat: 47.968924, lng: 17.6901513 },
    { lat: 47.9691685, lng: 17.6892438 },
    { lat: 47.9703382, lng: 17.687628 },
    { lat: 47.9710084, lng: 17.6871015 },
    { lat: 47.9712975, lng: 17.6869579 },
    { lat: 47.9717246, lng: 17.6868712 },
    { lat: 47.9720841, lng: 17.6869514 },
    { lat: 47.9720307, lng: 17.6867313 },
    { lat: 47.9720169, lng: 17.6866744 },
    { lat: 47.9715154, lng: 17.6841751 },
    { lat: 47.9721359, lng: 17.6830192 },
    { lat: 47.9700465, lng: 17.6795656 },
    { lat: 47.9694466, lng: 17.6789708 },
    { lat: 47.9693368, lng: 17.6787339 },
    { lat: 47.9692082, lng: 17.6784309 },
    { lat: 47.9680537, lng: 17.674408 },
    { lat: 47.9674161, lng: 17.6726024 },
    { lat: 47.9663695, lng: 17.6719966 },
    { lat: 47.9658506, lng: 17.6715008 },
    { lat: 47.9653684, lng: 17.670462 },
    { lat: 47.9649738, lng: 17.6695966 },
    { lat: 47.9643001, lng: 17.6682591 },
    { lat: 47.9612912, lng: 17.6736124 },
    { lat: 47.960957, lng: 17.6739564 },
    { lat: 47.9603232, lng: 17.6743064 },
    { lat: 47.9595931, lng: 17.6748699 },
    { lat: 47.9590027, lng: 17.6756945 },
    { lat: 47.9584665, lng: 17.6762538 },
    { lat: 47.9571246, lng: 17.6778396 },
    { lat: 47.9567508, lng: 17.6786819 },
    { lat: 47.95576, lng: 17.6818272 },
    { lat: 47.9559436, lng: 17.6821228 },
    { lat: 47.9561022, lng: 17.6825452 },
    { lat: 47.9558189, lng: 17.6833716 },
    { lat: 47.9553699, lng: 17.6841837 },
    { lat: 47.9553139, lng: 17.6846396 },
    { lat: 47.9554107, lng: 17.6852974 },
    { lat: 47.955713, lng: 17.6864243 },
    { lat: 47.9555813, lng: 17.6877413 },
    { lat: 47.9553771, lng: 17.6889444 },
    { lat: 47.9561907, lng: 17.6911441 },
    { lat: 47.9559554, lng: 17.6936703 },
    { lat: 47.9556862, lng: 17.6939835 },
    { lat: 47.9546643, lng: 17.6961018 },
    { lat: 47.9517474, lng: 17.6989171 },
    { lat: 47.9514013, lng: 17.6993386 },
    { lat: 47.9509723, lng: 17.700058 },
    { lat: 47.9505231, lng: 17.7011742 },
    { lat: 47.9502823, lng: 17.7027337 },
    { lat: 47.9500927, lng: 17.7035433 },
    { lat: 47.9499969, lng: 17.7044484 },
    { lat: 47.9500252, lng: 17.7072552 },
    { lat: 47.9505034, lng: 17.7075342 },
    { lat: 47.9523536, lng: 17.7086103 },
    { lat: 47.9551943, lng: 17.7089138 },
    { lat: 47.9569432, lng: 17.7139737 },
    { lat: 47.9571533, lng: 17.7137582 },
    { lat: 47.9580168, lng: 17.7119204 },
    { lat: 47.9586269, lng: 17.7101234 },
    { lat: 47.9590722, lng: 17.7089797 },
    { lat: 47.9593959, lng: 17.7084867 },
    { lat: 47.9611056, lng: 17.7104385 },
    { lat: 47.9622966, lng: 17.7118951 },
    { lat: 47.9626038, lng: 17.7125612 },
    { lat: 47.9636772, lng: 17.7156546 },
    { lat: 47.9641692, lng: 17.717633 },
    { lat: 47.9643595, lng: 17.7197156 },
    { lat: 47.9644602, lng: 17.7199362 },
    { lat: 47.9640486, lng: 17.7215476 },
    { lat: 47.9636333, lng: 17.7237491 },
    { lat: 47.9633493, lng: 17.7240122 },
    { lat: 47.9626889, lng: 17.7257453 },
    { lat: 47.9625348, lng: 17.7259154 },
    { lat: 47.962821, lng: 17.7261976 },
    { lat: 47.9629672, lng: 17.7265102 },
    { lat: 47.9630184, lng: 17.7270942 },
    { lat: 47.9626278, lng: 17.7286638 },
    { lat: 47.9615927, lng: 17.7306721 },
    { lat: 47.9589652, lng: 17.7378424 },
    { lat: 47.9606627, lng: 17.7371988 },
    { lat: 47.962368, lng: 17.7369189 },
    { lat: 47.9650509, lng: 17.7367069 },
    { lat: 47.9664305, lng: 17.7355485 },
    { lat: 47.9671645, lng: 17.7340894 },
    { lat: 47.9682102, lng: 17.7317085 },
    { lat: 47.9682568, lng: 17.73175 },
    { lat: 47.968523, lng: 17.7315087 },
    { lat: 47.9692748, lng: 17.7295736 },
    { lat: 47.9701983, lng: 17.7282931 },
    { lat: 47.9715712, lng: 17.7271384 },
    { lat: 47.97272, lng: 17.725469 },
    { lat: 47.9741767, lng: 17.7230535 },
    { lat: 47.9742243, lng: 17.723093 },
    { lat: 47.9747012, lng: 17.7225586 },
    { lat: 47.9750513, lng: 17.7223419 },
    { lat: 47.975953, lng: 17.7220487 },
    { lat: 47.9762974, lng: 17.7215971 },
    { lat: 47.9766658, lng: 17.7207926 },
    { lat: 47.9767934, lng: 17.720215 },
    { lat: 47.9767348, lng: 17.7192792 },
    { lat: 47.9767446, lng: 17.7187521 },
    { lat: 47.9769201, lng: 17.7177651 },
    { lat: 47.977455, lng: 17.7165558 },
    { lat: 47.9777217, lng: 17.7151526 },
    { lat: 47.9783242, lng: 17.7134184 },
    { lat: 47.978663, lng: 17.7128406 },
    { lat: 47.9821206, lng: 17.7085459 },
    { lat: 47.9824828, lng: 17.7061654 },
    { lat: 47.9816363, lng: 17.7051052 },
    { lat: 47.9803077, lng: 17.7036892 },
    { lat: 47.9789529, lng: 17.7027386 },
    { lat: 47.9785316, lng: 17.7025357 },
    { lat: 47.9782665, lng: 17.7024148 },
  ],
  Kvetoslavov: [
    { lat: 48.059949, lng: 17.314056 },
    { lat: 48.0584116, lng: 17.3128362 },
    { lat: 48.0570719, lng: 17.3111658 },
    { lat: 48.0554544, lng: 17.3096809 },
    { lat: 48.0527831, lng: 17.3139197 },
    { lat: 48.0508417, lng: 17.3167098 },
    { lat: 48.0455658, lng: 17.3237606 },
    { lat: 48.0438402, lng: 17.3247419 },
    { lat: 48.0431022, lng: 17.3259647 },
    { lat: 48.0427904, lng: 17.3256406 },
    { lat: 48.041609, lng: 17.3273537 },
    { lat: 48.0407712, lng: 17.3286938 },
    { lat: 48.0406908, lng: 17.328569 },
    { lat: 48.0401688, lng: 17.3296293 },
    { lat: 48.0396998, lng: 17.330446 },
    { lat: 48.0379055, lng: 17.3332276 },
    { lat: 48.0377958, lng: 17.3333125 },
    { lat: 48.0377753, lng: 17.3332785 },
    { lat: 48.0350913, lng: 17.3360654 },
    { lat: 48.0350802, lng: 17.3360839 },
    { lat: 48.0350979, lng: 17.3361152 },
    { lat: 48.035193, lng: 17.3362904 },
    { lat: 48.0354594, lng: 17.3373832 },
    { lat: 48.0357705, lng: 17.3396846 },
    { lat: 48.0358315, lng: 17.3407291 },
    { lat: 48.0359754, lng: 17.341199 },
    { lat: 48.0362095, lng: 17.3416337 },
    { lat: 48.036809, lng: 17.3424511 },
    { lat: 48.037166, lng: 17.3430341 },
    { lat: 48.0377615, lng: 17.3437921 },
    { lat: 48.0390762, lng: 17.3456932 },
    { lat: 48.0396828, lng: 17.3460382 },
    { lat: 48.0404207, lng: 17.3468125 },
    { lat: 48.0409549, lng: 17.347671 },
    { lat: 48.0410096, lng: 17.3480191 },
    { lat: 48.0409066, lng: 17.348857 },
    { lat: 48.040555, lng: 17.3497615 },
    { lat: 48.0446404, lng: 17.3539298 },
    { lat: 48.0469461, lng: 17.3564235 },
    { lat: 48.0442392, lng: 17.3602976 },
    { lat: 48.0440642, lng: 17.3614848 },
    { lat: 48.0443306, lng: 17.3622213 },
    { lat: 48.0444193, lng: 17.363333 },
    { lat: 48.0447163, lng: 17.3649274 },
    { lat: 48.0455903, lng: 17.3673846 },
    { lat: 48.0461751, lng: 17.3686875 },
    { lat: 48.0467675, lng: 17.3696755 },
    { lat: 48.047311, lng: 17.3691464 },
    { lat: 48.0478812, lng: 17.3685915 },
    { lat: 48.0479367, lng: 17.3684563 },
    { lat: 48.0484558, lng: 17.3667296 },
    { lat: 48.0486253, lng: 17.3658682 },
    { lat: 48.0517809, lng: 17.3687556 },
    { lat: 48.0518738, lng: 17.3688448 },
    { lat: 48.0523851, lng: 17.3676196 },
    { lat: 48.053036, lng: 17.366142 },
    { lat: 48.053108, lng: 17.3658293 },
    { lat: 48.053722, lng: 17.3645308 },
    { lat: 48.0540338, lng: 17.3637251 },
    { lat: 48.0541369, lng: 17.3638146 },
    { lat: 48.0537892, lng: 17.3646968 },
    { lat: 48.0560802, lng: 17.3667808 },
    { lat: 48.0565611, lng: 17.3655824 },
    { lat: 48.0578584, lng: 17.3623499 },
    { lat: 48.0590022, lng: 17.3633757 },
    { lat: 48.0601293, lng: 17.3606903 },
    { lat: 48.0577774, lng: 17.358211 },
    { lat: 48.056593, lng: 17.3571213 },
    { lat: 48.0566677, lng: 17.3570179 },
    { lat: 48.0571088, lng: 17.357388 },
    { lat: 48.0572899, lng: 17.3560382 },
    { lat: 48.0572614, lng: 17.3557592 },
    { lat: 48.0575819, lng: 17.3545861 },
    { lat: 48.0576451, lng: 17.3541979 },
    { lat: 48.057758, lng: 17.3535039 },
    { lat: 48.0579688, lng: 17.3505266 },
    { lat: 48.0588032, lng: 17.3456117 },
    { lat: 48.0588526, lng: 17.3457613 },
    { lat: 48.0589609, lng: 17.3452634 },
    { lat: 48.0588848, lng: 17.3451711 },
    { lat: 48.0590866, lng: 17.3442896 },
    { lat: 48.0593678, lng: 17.3434483 },
    { lat: 48.0600534, lng: 17.3418369 },
    { lat: 48.0602551, lng: 17.3419994 },
    { lat: 48.0603061, lng: 17.3418465 },
    { lat: 48.060227, lng: 17.3417769 },
    { lat: 48.0602576, lng: 17.3412823 },
    { lat: 48.060529, lng: 17.3406196 },
    { lat: 48.0605011, lng: 17.3405386 },
    { lat: 48.0621502, lng: 17.3362855 },
    { lat: 48.0622901, lng: 17.3363857 },
    { lat: 48.0623401, lng: 17.3363049 },
    { lat: 48.0622909, lng: 17.3362447 },
    { lat: 48.0622969, lng: 17.3359084 },
    { lat: 48.0633597, lng: 17.3331828 },
    { lat: 48.0638183, lng: 17.3324632 },
    { lat: 48.0644282, lng: 17.331142 },
    { lat: 48.0652096, lng: 17.3281757 },
    { lat: 48.0639995, lng: 17.3273675 },
    { lat: 48.0639844, lng: 17.3270025 },
    { lat: 48.0642777, lng: 17.3256786 },
    { lat: 48.0644564, lng: 17.3230801 },
    { lat: 48.063796, lng: 17.3224028 },
    { lat: 48.0639764, lng: 17.3216795 },
    { lat: 48.0624759, lng: 17.3201356 },
    { lat: 48.06272, lng: 17.318914 },
    { lat: 48.063211, lng: 17.317624 },
    { lat: 48.060693, lng: 17.315451 },
    { lat: 48.060865, lng: 17.315194 },
    { lat: 48.059949, lng: 17.314056 },
  ],
  Ňárad: [
    { lat: 47.8144202, lng: 17.6647484 },
    { lat: 47.8184272, lng: 17.6580756 },
    { lat: 47.8189109, lng: 17.6575321 },
    { lat: 47.8203004, lng: 17.657168 },
    { lat: 47.8210032, lng: 17.657177 },
    { lat: 47.8221791, lng: 17.6569014 },
    { lat: 47.8226852, lng: 17.6564418 },
    { lat: 47.8239771, lng: 17.6555344 },
    { lat: 47.8253234, lng: 17.6548812 },
    { lat: 47.8264486, lng: 17.6540884 },
    { lat: 47.8279479, lng: 17.6534077 },
    { lat: 47.8291502, lng: 17.6527123 },
    { lat: 47.8292035, lng: 17.6522354 },
    { lat: 47.8294222, lng: 17.6514814 },
    { lat: 47.8303221, lng: 17.6499645 },
    { lat: 47.8307836, lng: 17.6493029 },
    { lat: 47.8308423, lng: 17.6491422 },
    { lat: 47.8308704, lng: 17.6489429 },
    { lat: 47.8308132, lng: 17.6485233 },
    { lat: 47.8307006, lng: 17.6481729 },
    { lat: 47.830688, lng: 17.6478131 },
    { lat: 47.8308283, lng: 17.647142 },
    { lat: 47.832028, lng: 17.6455138 },
    { lat: 47.8321569, lng: 17.645214 },
    { lat: 47.832212, lng: 17.6449076 },
    { lat: 47.8322907, lng: 17.643568 },
    { lat: 47.8324252, lng: 17.64291 },
    { lat: 47.8325973, lng: 17.6424568 },
    { lat: 47.8334242, lng: 17.6415529 },
    { lat: 47.8338612, lng: 17.6409298 },
    { lat: 47.8339765, lng: 17.6406304 },
    { lat: 47.8341424, lng: 17.6399178 },
    { lat: 47.8344676, lng: 17.6380864 },
    { lat: 47.8346861, lng: 17.6374921 },
    { lat: 47.8347901, lng: 17.6373341 },
    { lat: 47.8352308, lng: 17.6370538 },
    { lat: 47.8359798, lng: 17.6364214 },
    { lat: 47.8362798, lng: 17.6358477 },
    { lat: 47.8367051, lng: 17.6355375 },
    { lat: 47.8377252, lng: 17.6344091 },
    { lat: 47.8384375, lng: 17.6334086 },
    { lat: 47.8391396, lng: 17.6327141 },
    { lat: 47.8397973, lng: 17.6321355 },
    { lat: 47.8409064, lng: 17.6317645 },
    { lat: 47.841864, lng: 17.6317325 },
    { lat: 47.8424116, lng: 17.6315728 },
    { lat: 47.8430339, lng: 17.63154 },
    { lat: 47.8433105, lng: 17.63131 },
    { lat: 47.8434655, lng: 17.631347 },
    { lat: 47.8435377, lng: 17.6314367 },
    { lat: 47.8435412, lng: 17.631794 },
    { lat: 47.8435816, lng: 17.6318589 },
    { lat: 47.843855, lng: 17.6316487 },
    { lat: 47.8440419, lng: 17.6313206 },
    { lat: 47.8441891, lng: 17.631184 },
    { lat: 47.845216, lng: 17.6306769 },
    { lat: 47.8458796, lng: 17.6299799 },
    { lat: 47.8461558, lng: 17.6298473 },
    { lat: 47.846572, lng: 17.629467 },
    { lat: 47.847199, lng: 17.6290275 },
    { lat: 47.8477999, lng: 17.6282522 },
    { lat: 47.8482275, lng: 17.6279623 },
    { lat: 47.8486022, lng: 17.6276035 },
    { lat: 47.8488023, lng: 17.627271 },
    { lat: 47.8494899, lng: 17.6256901 },
    { lat: 47.8499797, lng: 17.6241576 },
    { lat: 47.8501502, lng: 17.6238922 },
    { lat: 47.8506066, lng: 17.6234523 },
    { lat: 47.8515358, lng: 17.6230961 },
    { lat: 47.8520724, lng: 17.6225294 },
    { lat: 47.8522923, lng: 17.6224204 },
    { lat: 47.852696, lng: 17.6225356 },
    { lat: 47.8531452, lng: 17.6224961 },
    { lat: 47.8536792, lng: 17.6222288 },
    { lat: 47.8542172, lng: 17.622078 },
    { lat: 47.8543668, lng: 17.6220862 },
    { lat: 47.8548942, lng: 17.6226934 },
    { lat: 47.8550405, lng: 17.6227717 },
    { lat: 47.8563099, lng: 17.6225818 },
    { lat: 47.8564555, lng: 17.6227575 },
    { lat: 47.8566655, lng: 17.623344 },
    { lat: 47.8567315, lng: 17.624504 },
    { lat: 47.8569622, lng: 17.6248137 },
    { lat: 47.8573045, lng: 17.6249376 },
    { lat: 47.8574812, lng: 17.6250906 },
    { lat: 47.8578627, lng: 17.6249274 },
    { lat: 47.8581786, lng: 17.6249055 },
    { lat: 47.8584878, lng: 17.6244068 },
    { lat: 47.8589313, lng: 17.624418 },
    { lat: 47.8591381, lng: 17.6243138 },
    { lat: 47.8593365, lng: 17.6239888 },
    { lat: 47.8597654, lng: 17.6229051 },
    { lat: 47.8599688, lng: 17.6226065 },
    { lat: 47.8599538, lng: 17.6222443 },
    { lat: 47.8600321, lng: 17.6221286 },
    { lat: 47.8601855, lng: 17.6221264 },
    { lat: 47.8604583, lng: 17.6224899 },
    { lat: 47.8608465, lng: 17.6226115 },
    { lat: 47.8609283, lng: 17.6225849 },
    { lat: 47.86012, lng: 17.6203495 },
    { lat: 47.8569207, lng: 17.617664 },
    { lat: 47.855582, lng: 17.6183823 },
    { lat: 47.8538968, lng: 17.6188691 },
    { lat: 47.851786, lng: 17.6192194 },
    { lat: 47.8516635, lng: 17.6185184 },
    { lat: 47.8515867, lng: 17.6174532 },
    { lat: 47.8516442, lng: 17.6169737 },
    { lat: 47.8515793, lng: 17.6169125 },
    { lat: 47.851032, lng: 17.6170766 },
    { lat: 47.8509646, lng: 17.6169947 },
    { lat: 47.8509384, lng: 17.6167952 },
    { lat: 47.851315, lng: 17.6160244 },
    { lat: 47.8514977, lng: 17.6149223 },
    { lat: 47.8516413, lng: 17.6144652 },
    { lat: 47.8516764, lng: 17.6140577 },
    { lat: 47.8518775, lng: 17.6132803 },
    { lat: 47.8518577, lng: 17.613004 },
    { lat: 47.8518058, lng: 17.6129164 },
    { lat: 47.8514507, lng: 17.6127842 },
    { lat: 47.8513797, lng: 17.6126616 },
    { lat: 47.8513359, lng: 17.6121528 },
    { lat: 47.8513773, lng: 17.6117729 },
    { lat: 47.8514818, lng: 17.611483 },
    { lat: 47.851817, lng: 17.6110903 },
    { lat: 47.8517836, lng: 17.6101919 },
    { lat: 47.8517433, lng: 17.6098794 },
    { lat: 47.8516368, lng: 17.6095372 },
    { lat: 47.8514246, lng: 17.6090619 },
    { lat: 47.8513967, lng: 17.6083951 },
    { lat: 47.8512975, lng: 17.6081127 },
    { lat: 47.8509645, lng: 17.6075648 },
    { lat: 47.8506945, lng: 17.607349 },
    { lat: 47.8505103, lng: 17.6064988 },
    { lat: 47.8499646, lng: 17.6057657 },
    { lat: 47.8496145, lng: 17.6055011 },
    { lat: 47.8493915, lng: 17.6051904 },
    { lat: 47.8493031, lng: 17.6048848 },
    { lat: 47.8492548, lng: 17.6043583 },
    { lat: 47.8492012, lng: 17.6042073 },
    { lat: 47.8486874, lng: 17.6037116 },
    { lat: 47.8482255, lng: 17.6030739 },
    { lat: 47.8481886, lng: 17.602955 },
    { lat: 47.8482124, lng: 17.6028501 },
    { lat: 47.8483698, lng: 17.6025773 },
    { lat: 47.8482091, lng: 17.6019496 },
    { lat: 47.8479654, lng: 17.6015118 },
    { lat: 47.8478888, lng: 17.6014421 },
    { lat: 47.8476962, lng: 17.6014165 },
    { lat: 47.8471592, lng: 17.6018124 },
    { lat: 47.8470957, lng: 17.6017922 },
    { lat: 47.846408, lng: 17.6010637 },
    { lat: 47.8459575, lng: 17.6004635 },
    { lat: 47.8458097, lng: 17.6004467 },
    { lat: 47.8457198, lng: 17.6005103 },
    { lat: 47.8454375, lng: 17.5995353 },
    { lat: 47.8452451, lng: 17.5991381 },
    { lat: 47.8449616, lng: 17.5987854 },
    { lat: 47.8446946, lng: 17.5973271 },
    { lat: 47.8446398, lng: 17.5968213 },
    { lat: 47.8445875, lng: 17.5966216 },
    { lat: 47.844409, lng: 17.5963564 },
    { lat: 47.8438737, lng: 17.5958073 },
    { lat: 47.8430969, lng: 17.5952598 },
    { lat: 47.8425756, lng: 17.5951414 },
    { lat: 47.8422355, lng: 17.5949612 },
    { lat: 47.8417177, lng: 17.5944043 },
    { lat: 47.8411164, lng: 17.5941309 },
    { lat: 47.8395876, lng: 17.5936379 },
    { lat: 47.838989, lng: 17.5932091 },
    { lat: 47.8389356, lng: 17.5931703 },
    { lat: 47.8388521, lng: 17.5931177 },
    { lat: 47.8377482, lng: 17.5960055 },
    { lat: 47.8375533, lng: 17.5968252 },
    { lat: 47.8375703, lng: 17.5974101 },
    { lat: 47.8375358, lng: 17.5977006 },
    { lat: 47.837393, lng: 17.597409 },
    { lat: 47.8362411, lng: 17.5958041 },
    { lat: 47.8291152, lng: 17.6054802 },
    { lat: 47.829984, lng: 17.6068848 },
    { lat: 47.8296625, lng: 17.6066964 },
    { lat: 47.8294668, lng: 17.6067446 },
    { lat: 47.8291143, lng: 17.6069713 },
    { lat: 47.8286441, lng: 17.6075656 },
    { lat: 47.8283907, lng: 17.6080549 },
    { lat: 47.8281119, lng: 17.6090474 },
    { lat: 47.8280397, lng: 17.6091373 },
    { lat: 47.8274792, lng: 17.6117312 },
    { lat: 47.8273406, lng: 17.6121808 },
    { lat: 47.8271259, lng: 17.6126368 },
    { lat: 47.8269, lng: 17.6141257 },
    { lat: 47.8260755, lng: 17.6178265 },
    { lat: 47.8247981, lng: 17.619151 },
    { lat: 47.8233317, lng: 17.6202563 },
    { lat: 47.8229525, lng: 17.6204364 },
    { lat: 47.8217787, lng: 17.6223765 },
    { lat: 47.8198212, lng: 17.6267489 },
    { lat: 47.8189889, lng: 17.6283601 },
    { lat: 47.8183992, lng: 17.6297444 },
    { lat: 47.8183838, lng: 17.6309378 },
    { lat: 47.8184739, lng: 17.6323367 },
    { lat: 47.8189869, lng: 17.6339974 },
    { lat: 47.8191363, lng: 17.6346949 },
    { lat: 47.8209425, lng: 17.6378135 },
    { lat: 47.8213508, lng: 17.6388218 },
    { lat: 47.8207345, lng: 17.6400155 },
    { lat: 47.8209929, lng: 17.6410807 },
    { lat: 47.8214701, lng: 17.642195 },
    { lat: 47.8216343, lng: 17.6434469 },
    { lat: 47.8210818, lng: 17.6458436 },
    { lat: 47.8208637, lng: 17.646393 },
    { lat: 47.8202262, lng: 17.6472726 },
    { lat: 47.8178011, lng: 17.6483446 },
    { lat: 47.8171297, lng: 17.6488115 },
    { lat: 47.8170108, lng: 17.6493772 },
    { lat: 47.816026, lng: 17.6502455 },
    { lat: 47.8155619, lng: 17.6509047 },
    { lat: 47.8152616, lng: 17.651795 },
    { lat: 47.8150886, lng: 17.6525372 },
    { lat: 47.8149968, lng: 17.6534068 },
    { lat: 47.8146111, lng: 17.6537164 },
    { lat: 47.81374, lng: 17.6538848 },
    { lat: 47.8132086, lng: 17.6540683 },
    { lat: 47.8121119, lng: 17.6545833 },
    { lat: 47.8107735, lng: 17.655465 },
    { lat: 47.811006, lng: 17.656999 },
    { lat: 47.8110526, lng: 17.6583905 },
    { lat: 47.8109786, lng: 17.6593446 },
    { lat: 47.811283, lng: 17.6594812 },
    { lat: 47.8115013, lng: 17.6604658 },
    { lat: 47.8117176, lng: 17.661064 },
    { lat: 47.812076, lng: 17.6616083 },
    { lat: 47.8127461, lng: 17.6622749 },
    { lat: 47.8132895, lng: 17.6629385 },
    { lat: 47.8131534, lng: 17.6638047 },
    { lat: 47.8137414, lng: 17.6641954 },
    { lat: 47.8144034, lng: 17.6647332 },
    { lat: 47.8144202, lng: 17.6647484 },
  ],
  Janíky: [
    { lat: 48.1527976, lng: 17.4207791 },
    { lat: 48.153129, lng: 17.419956 },
    { lat: 48.153389, lng: 17.419007 },
    { lat: 48.153523, lng: 17.418247 },
    { lat: 48.153572, lng: 17.417599 },
    { lat: 48.153578, lng: 17.416592 },
    { lat: 48.153123, lng: 17.41503 },
    { lat: 48.15273, lng: 17.413898 },
    { lat: 48.152314, lng: 17.412313 },
    { lat: 48.151694, lng: 17.409078 },
    { lat: 48.1515836, lng: 17.4079616 },
    { lat: 48.151555, lng: 17.407673 },
    { lat: 48.15154, lng: 17.407052 },
    { lat: 48.151663, lng: 17.405551 },
    { lat: 48.151612, lng: 17.404681 },
    { lat: 48.151741, lng: 17.403068 },
    { lat: 48.151892, lng: 17.402379 },
    { lat: 48.151998, lng: 17.402142 },
    { lat: 48.152232, lng: 17.401219 },
    { lat: 48.152462, lng: 17.400842 },
    { lat: 48.153034, lng: 17.399485 },
    { lat: 48.153339, lng: 17.398975 },
    { lat: 48.153735, lng: 17.398508 },
    { lat: 48.154437, lng: 17.397995 },
    { lat: 48.155099, lng: 17.397696 },
    { lat: 48.15606, lng: 17.397549 },
    { lat: 48.157157, lng: 17.398115 },
    { lat: 48.158339, lng: 17.399297 },
    { lat: 48.159187, lng: 17.400379 },
    { lat: 48.15985, lng: 17.401381 },
    { lat: 48.160362, lng: 17.402491 },
    { lat: 48.160536, lng: 17.403047 },
    { lat: 48.160662, lng: 17.403628 },
    { lat: 48.160715, lng: 17.404131 },
    { lat: 48.160917, lng: 17.40498 },
    { lat: 48.161726, lng: 17.407965 },
    { lat: 48.16223, lng: 17.40893 },
    { lat: 48.162475, lng: 17.409308 },
    { lat: 48.16338, lng: 17.410028 },
    { lat: 48.164179, lng: 17.410229 },
    { lat: 48.165114, lng: 17.410069 },
    { lat: 48.165724, lng: 17.409454 },
    { lat: 48.165932, lng: 17.409133 },
    { lat: 48.166299, lng: 17.408523 },
    { lat: 48.166517, lng: 17.407865 },
    { lat: 48.166631, lng: 17.407225 },
    { lat: 48.16666, lng: 17.406237 },
    { lat: 48.166971, lng: 17.406183 },
    { lat: 48.167135, lng: 17.406075 },
    { lat: 48.169422, lng: 17.404351 },
    { lat: 48.170926, lng: 17.402912 },
    { lat: 48.171306, lng: 17.402692 },
    { lat: 48.172199, lng: 17.402322 },
    { lat: 48.172422, lng: 17.402002 },
    { lat: 48.172766, lng: 17.40166 },
    { lat: 48.173241, lng: 17.401485 },
    { lat: 48.174089, lng: 17.40038 },
    { lat: 48.174105, lng: 17.399651 },
    { lat: 48.173902, lng: 17.399058 },
    { lat: 48.173499, lng: 17.398538 },
    { lat: 48.172918, lng: 17.39811 },
    { lat: 48.172087, lng: 17.397662 },
    { lat: 48.171306, lng: 17.396952 },
    { lat: 48.17113, lng: 17.396857 },
    { lat: 48.170904, lng: 17.396894 },
    { lat: 48.170242, lng: 17.397471 },
    { lat: 48.169763, lng: 17.397668 },
    { lat: 48.169372, lng: 17.397521 },
    { lat: 48.169031, lng: 17.397304 },
    { lat: 48.16885, lng: 17.397131 },
    { lat: 48.168248, lng: 17.396247 },
    { lat: 48.1678841, lng: 17.3965223 },
    { lat: 48.1676534, lng: 17.3966065 },
    { lat: 48.1668392, lng: 17.3966605 },
    { lat: 48.1663555, lng: 17.396568 },
    { lat: 48.1657582, lng: 17.3965723 },
    { lat: 48.1652535, lng: 17.3964697 },
    { lat: 48.1650672, lng: 17.3963168 },
    { lat: 48.1645708, lng: 17.395649 },
    { lat: 48.1640466, lng: 17.3945944 },
    { lat: 48.1636397, lng: 17.3936362 },
    { lat: 48.1634317, lng: 17.3928401 },
    { lat: 48.1631559, lng: 17.3921257 },
    { lat: 48.1624062, lng: 17.3915608 },
    { lat: 48.1618921, lng: 17.3914702 },
    { lat: 48.1614564, lng: 17.3915625 },
    { lat: 48.1610039, lng: 17.3917941 },
    { lat: 48.1605854, lng: 17.3922181 },
    { lat: 48.1596381, lng: 17.3913583 },
    { lat: 48.1598469, lng: 17.3909557 },
    { lat: 48.1598846, lng: 17.3907722 },
    { lat: 48.1597868, lng: 17.3893803 },
    { lat: 48.1594698, lng: 17.3885747 },
    { lat: 48.159206, lng: 17.388182 },
    { lat: 48.1591132, lng: 17.3881127 },
    { lat: 48.1583546, lng: 17.3881195 },
    { lat: 48.1576364, lng: 17.3879982 },
    { lat: 48.1572142, lng: 17.3875728 },
    { lat: 48.1563782, lng: 17.3869993 },
    { lat: 48.1552829, lng: 17.3856721 },
    { lat: 48.1550236, lng: 17.3855766 },
    { lat: 48.1549224, lng: 17.3855999 },
    { lat: 48.1542432, lng: 17.3852686 },
    { lat: 48.1539875, lng: 17.3852773 },
    { lat: 48.1536321, lng: 17.3851643 },
    { lat: 48.1534053, lng: 17.3848051 },
    { lat: 48.1532206, lng: 17.3837423 },
    { lat: 48.1530017, lng: 17.3818458 },
    { lat: 48.1528535, lng: 17.3812884 },
    { lat: 48.1526469, lng: 17.3809776 },
    { lat: 48.1521486, lng: 17.3805096 },
    { lat: 48.1515907, lng: 17.379855 },
    { lat: 48.151208, lng: 17.379187 },
    { lat: 48.147461, lng: 17.377746 },
    { lat: 48.147398, lng: 17.378439 },
    { lat: 48.145105, lng: 17.380323 },
    { lat: 48.1448, lng: 17.38115 },
    { lat: 48.14437, lng: 17.382914 },
    { lat: 48.144089, lng: 17.383821 },
    { lat: 48.143835, lng: 17.385309 },
    { lat: 48.143535, lng: 17.387603 },
    { lat: 48.1429904, lng: 17.3909734 },
    { lat: 48.141712, lng: 17.390392 },
    { lat: 48.140791, lng: 17.388923 },
    { lat: 48.140364, lng: 17.387885 },
    { lat: 48.139751, lng: 17.38718 },
    { lat: 48.139402, lng: 17.386914 },
    { lat: 48.138249, lng: 17.38627 },
    { lat: 48.1367, lng: 17.385561 },
    { lat: 48.136246, lng: 17.384962 },
    { lat: 48.13517, lng: 17.384055 },
    { lat: 48.134683, lng: 17.383572 },
    { lat: 48.132968, lng: 17.381235 },
    { lat: 48.132083, lng: 17.380255 },
    { lat: 48.130922, lng: 17.379157 },
    { lat: 48.130462, lng: 17.378969 },
    { lat: 48.1294516, lng: 17.3815734 },
    { lat: 48.1285766, lng: 17.3845154 },
    { lat: 48.1255415, lng: 17.3914771 },
    { lat: 48.1250876, lng: 17.3924815 },
    { lat: 48.1234023, lng: 17.3961096 },
    { lat: 48.1214819, lng: 17.3998628 },
    { lat: 48.1210395, lng: 17.4010775 },
    { lat: 48.1209693, lng: 17.4012306 },
    { lat: 48.1209951, lng: 17.4012639 },
    { lat: 48.1217107, lng: 17.4018784 },
    { lat: 48.1219553, lng: 17.4021471 },
    { lat: 48.1219201, lng: 17.4022509 },
    { lat: 48.1223902, lng: 17.4027003 },
    { lat: 48.1228732, lng: 17.40332 },
    { lat: 48.1252414, lng: 17.4068824 },
    { lat: 48.1255866, lng: 17.4074784 },
    { lat: 48.1260666, lng: 17.4083793 },
    { lat: 48.1268959, lng: 17.4102378 },
    { lat: 48.1272322, lng: 17.4110324 },
    { lat: 48.1271956, lng: 17.4111607 },
    { lat: 48.1275167, lng: 17.4119289 },
    { lat: 48.1289445, lng: 17.4150191 },
    { lat: 48.1296511, lng: 17.4163985 },
    { lat: 48.1306136, lng: 17.4179457 },
    { lat: 48.1314483, lng: 17.4191086 },
    { lat: 48.1334033, lng: 17.4220219 },
    { lat: 48.135417, lng: 17.4243279 },
    { lat: 48.1368827, lng: 17.4265991 },
    { lat: 48.1381456, lng: 17.4276937 },
    { lat: 48.1390861, lng: 17.4287495 },
    { lat: 48.1393461, lng: 17.4288232 },
    { lat: 48.1397377, lng: 17.4293579 },
    { lat: 48.1408788, lng: 17.4301779 },
    { lat: 48.1408855, lng: 17.4304736 },
    { lat: 48.1436132, lng: 17.4339349 },
    { lat: 48.144085, lng: 17.4352033 },
    { lat: 48.14414, lng: 17.434151 },
    { lat: 48.144321, lng: 17.432839 },
    { lat: 48.1447, lng: 17.431976 },
    { lat: 48.145188, lng: 17.431291 },
    { lat: 48.147064, lng: 17.429102 },
    { lat: 48.147655, lng: 17.428485 },
    { lat: 48.148751, lng: 17.427091 },
    { lat: 48.149141, lng: 17.426778 },
    { lat: 48.149789, lng: 17.426447 },
    { lat: 48.150462, lng: 17.425589 },
    { lat: 48.150858, lng: 17.424928 },
    { lat: 48.151288, lng: 17.424017 },
    { lat: 48.151847, lng: 17.42314 },
    { lat: 48.1527976, lng: 17.4207791 },
  ],
  Topoľníky: [
    { lat: 47.988195, lng: 17.830452 },
    { lat: 47.988412, lng: 17.830331 },
    { lat: 47.98864, lng: 17.830151 },
    { lat: 47.988869, lng: 17.829955 },
    { lat: 47.989059, lng: 17.829815 },
    { lat: 47.989144, lng: 17.829751 },
    { lat: 47.989321, lng: 17.829646 },
    { lat: 47.989465, lng: 17.829524 },
    { lat: 47.989641, lng: 17.829394 },
    { lat: 47.989713, lng: 17.829353 },
    { lat: 47.989773, lng: 17.829337 },
    { lat: 47.989828, lng: 17.829316 },
    { lat: 47.989856, lng: 17.829301 },
    { lat: 47.989991, lng: 17.829238 },
    { lat: 47.990048, lng: 17.829235 },
    { lat: 47.990218, lng: 17.829221 },
    { lat: 47.990275, lng: 17.8292 },
    { lat: 47.99046, lng: 17.829134 },
    { lat: 47.990535, lng: 17.829108 },
    { lat: 47.990631, lng: 17.829056 },
    { lat: 47.990758, lng: 17.82897 },
    { lat: 47.990793, lng: 17.828942 },
    { lat: 47.990822, lng: 17.828911 },
    { lat: 47.990854, lng: 17.828874 },
    { lat: 47.990878, lng: 17.828848 },
    { lat: 47.990981, lng: 17.828782 },
    { lat: 47.99107, lng: 17.828679 },
    { lat: 47.991155, lng: 17.82857 },
    { lat: 47.991189, lng: 17.82853 },
    { lat: 47.99124, lng: 17.828497 },
    { lat: 47.991326, lng: 17.828435 },
    { lat: 47.991353, lng: 17.828415 },
    { lat: 47.991547, lng: 17.828377 },
    { lat: 47.991626, lng: 17.82836 },
    { lat: 47.991715, lng: 17.828348 },
    { lat: 47.991891, lng: 17.828322 },
    { lat: 47.991953, lng: 17.828306 },
    { lat: 47.992005, lng: 17.828287 },
    { lat: 47.992121, lng: 17.828213 },
    { lat: 47.992298, lng: 17.828167 },
    { lat: 47.992379, lng: 17.828143 },
    { lat: 47.992461, lng: 17.828114 },
    { lat: 47.99258, lng: 17.828062 },
    { lat: 47.992597, lng: 17.828054 },
    { lat: 47.992708, lng: 17.82796 },
    { lat: 47.992817, lng: 17.827852 },
    { lat: 47.992899, lng: 17.827702 },
    { lat: 47.992984, lng: 17.827543 },
    { lat: 47.993062, lng: 17.827373 },
    { lat: 47.993147, lng: 17.827157 },
    { lat: 47.993187, lng: 17.826946 },
    { lat: 47.993209, lng: 17.826674 },
    { lat: 47.993179, lng: 17.826374 },
    { lat: 47.993163, lng: 17.82623 },
    { lat: 47.993125, lng: 17.825572 },
    { lat: 47.993114, lng: 17.825233 },
    { lat: 47.993125, lng: 17.825152 },
    { lat: 47.993139, lng: 17.82507 },
    { lat: 47.993149, lng: 17.824998 },
    { lat: 47.993167, lng: 17.824914 },
    { lat: 47.99319, lng: 17.82478 },
    { lat: 47.993272, lng: 17.824529 },
    { lat: 47.993388, lng: 17.824307 },
    { lat: 47.993434, lng: 17.824235 },
    { lat: 47.9936, lng: 17.824048 },
    { lat: 47.993662, lng: 17.823998 },
    { lat: 47.993719, lng: 17.823948 },
    { lat: 47.993841, lng: 17.823861 },
    { lat: 47.993904, lng: 17.823833 },
    { lat: 47.99397, lng: 17.823799 },
    { lat: 47.994119, lng: 17.823727 },
    { lat: 47.994404, lng: 17.823591 },
    { lat: 47.994499, lng: 17.823539 },
    { lat: 47.994596, lng: 17.823491 },
    { lat: 47.994685, lng: 17.823447 },
    { lat: 47.994765, lng: 17.823388 },
    { lat: 47.995079, lng: 17.823167 },
    { lat: 47.995132, lng: 17.823129 },
    { lat: 47.995186, lng: 17.823094 },
    { lat: 47.995246, lng: 17.823048 },
    { lat: 47.995307, lng: 17.823007 },
    { lat: 47.995381, lng: 17.822949 },
    { lat: 47.995493, lng: 17.822823 },
    { lat: 47.995588, lng: 17.822723 },
    { lat: 47.995628, lng: 17.82267 },
    { lat: 47.995667, lng: 17.822615 },
    { lat: 47.995748, lng: 17.822507 },
    { lat: 47.995778, lng: 17.822467 },
    { lat: 47.995814, lng: 17.822422 },
    { lat: 47.995836, lng: 17.822379 },
    { lat: 47.995899, lng: 17.822247 },
    { lat: 47.995957, lng: 17.822115 },
    { lat: 47.996014, lng: 17.821987 },
    { lat: 47.99607, lng: 17.821866 },
    { lat: 47.996092, lng: 17.821811 },
    { lat: 47.99612, lng: 17.821768 },
    { lat: 47.996185, lng: 17.821691 },
    { lat: 47.996236, lng: 17.821637 },
    { lat: 47.996325, lng: 17.821552 },
    { lat: 47.996408, lng: 17.821471 },
    { lat: 47.996521, lng: 17.821369 },
    { lat: 47.99664, lng: 17.821271 },
    { lat: 47.996903, lng: 17.821077 },
    { lat: 47.997009, lng: 17.820996 },
    { lat: 47.997108, lng: 17.820913 },
    { lat: 47.997208, lng: 17.820824 },
    { lat: 47.997282, lng: 17.820759 },
    { lat: 47.997367, lng: 17.82069 },
    { lat: 47.997513, lng: 17.820593 },
    { lat: 47.997558, lng: 17.82056 },
    { lat: 47.997703, lng: 17.820458 },
    { lat: 47.997751, lng: 17.82043 },
    { lat: 47.997821, lng: 17.82038 },
    { lat: 47.997898, lng: 17.820326 },
    { lat: 47.998047, lng: 17.820226 },
    { lat: 47.998169, lng: 17.820137 },
    { lat: 47.998225, lng: 17.820082 },
    { lat: 47.998281, lng: 17.820016 },
    { lat: 47.998391, lng: 17.819898 },
    { lat: 47.99851, lng: 17.81977 },
    { lat: 47.998683, lng: 17.819467 },
    { lat: 47.998721, lng: 17.819397 },
    { lat: 47.998744, lng: 17.819324 },
    { lat: 47.998779, lng: 17.819156 },
    { lat: 47.998859, lng: 17.8188 },
    { lat: 47.998904, lng: 17.818545 },
    { lat: 47.998954, lng: 17.818282 },
    { lat: 47.998982, lng: 17.818133 },
    { lat: 47.999013, lng: 17.818011 },
    { lat: 47.999046, lng: 17.817924 },
    { lat: 47.999124, lng: 17.817732 },
    { lat: 47.999174, lng: 17.817622 },
    { lat: 47.999203, lng: 17.817555 },
    { lat: 47.999228, lng: 17.817517 },
    { lat: 47.999287, lng: 17.817434 },
    { lat: 47.999373, lng: 17.817343 },
    { lat: 47.999448, lng: 17.817282 },
    { lat: 47.999597, lng: 17.817185 },
    { lat: 47.9969516, lng: 17.8141508 },
    { lat: 47.9939882, lng: 17.8096892 },
    { lat: 47.9940233, lng: 17.8088671 },
    { lat: 47.9939029, lng: 17.8079457 },
    { lat: 47.9936522, lng: 17.8078321 },
    { lat: 47.9929073, lng: 17.8070863 },
    { lat: 47.9920655, lng: 17.8055423 },
    { lat: 47.9910222, lng: 17.8025799 },
    { lat: 47.9904205, lng: 17.8006665 },
    { lat: 47.9900311, lng: 17.8013585 },
    { lat: 47.9877165, lng: 17.8014123 },
    { lat: 47.9870928, lng: 17.8002329 },
    { lat: 47.9860246, lng: 17.7951403 },
    { lat: 47.985836, lng: 17.794559 },
    { lat: 47.9860138, lng: 17.7938932 },
    { lat: 47.9865403, lng: 17.7932416 },
    { lat: 47.9862952, lng: 17.7926959 },
    { lat: 47.9848637, lng: 17.7903813 },
    { lat: 47.9845431, lng: 17.7894884 },
    { lat: 47.9836582, lng: 17.7873788 },
    { lat: 47.9832767, lng: 17.7860348 },
    { lat: 47.9830226, lng: 17.7847414 },
    { lat: 47.982958, lng: 17.7845885 },
    { lat: 47.9826695, lng: 17.7840895 },
    { lat: 47.9817353, lng: 17.7828963 },
    { lat: 47.9816848, lng: 17.7826478 },
    { lat: 47.9817172, lng: 17.782297 },
    { lat: 47.9823771, lng: 17.7825768 },
    { lat: 47.9829374, lng: 17.7826737 },
    { lat: 47.9835129, lng: 17.7827062 },
    { lat: 47.9842606, lng: 17.7826015 },
    { lat: 47.9847502, lng: 17.7823981 },
    { lat: 47.9851325, lng: 17.7820428 },
    { lat: 47.9848165, lng: 17.7812815 },
    { lat: 47.9838327, lng: 17.7783819 },
    { lat: 47.9837251, lng: 17.7782839 },
    { lat: 47.9835576, lng: 17.7778958 },
    { lat: 47.9833319, lng: 17.7775326 },
    { lat: 47.9826477, lng: 17.7760623 },
    { lat: 47.9824878, lng: 17.7753038 },
    { lat: 47.9812006, lng: 17.7715037 },
    { lat: 47.9800603, lng: 17.7687215 },
    { lat: 47.9774389, lng: 17.763805 },
    { lat: 47.9753356, lng: 17.7617195 },
    { lat: 47.9739424, lng: 17.7597447 },
    { lat: 47.9649283, lng: 17.7466078 },
    { lat: 47.9603436, lng: 17.7393578 },
    { lat: 47.9602937, lng: 17.7392787 },
    { lat: 47.9590112, lng: 17.7378896 },
    { lat: 47.9589652, lng: 17.7378424 },
    { lat: 47.958367, lng: 17.738927 },
    { lat: 47.9582494, lng: 17.7392677 },
    { lat: 47.9580551, lng: 17.7395358 },
    { lat: 47.957488, lng: 17.7413574 },
    { lat: 47.9571808, lng: 17.7428384 },
    { lat: 47.9570927, lng: 17.7435448 },
    { lat: 47.9569556, lng: 17.7465382 },
    { lat: 47.9570483, lng: 17.7475186 },
    { lat: 47.957285, lng: 17.7484875 },
    { lat: 47.957436, lng: 17.7494451 },
    { lat: 47.958064, lng: 17.7518946 },
    { lat: 47.9586507, lng: 17.7536052 },
    { lat: 47.9606406, lng: 17.7553549 },
    { lat: 47.9600649, lng: 17.7566598 },
    { lat: 47.9600146, lng: 17.7569422 },
    { lat: 47.9598671, lng: 17.7572366 },
    { lat: 47.9593308, lng: 17.7579436 },
    { lat: 47.9591141, lng: 17.7585973 },
    { lat: 47.9590829, lng: 17.7592578 },
    { lat: 47.9589186, lng: 17.7595612 },
    { lat: 47.9588034, lng: 17.7597737 },
    { lat: 47.9578616, lng: 17.7600241 },
    { lat: 47.9567841, lng: 17.7594188 },
    { lat: 47.9565325, lng: 17.75922 },
    { lat: 47.9560312, lng: 17.7591479 },
    { lat: 47.9556282, lng: 17.7592399 },
    { lat: 47.9552344, lng: 17.7594781 },
    { lat: 47.9548781, lng: 17.7598122 },
    { lat: 47.9543739, lng: 17.7605245 },
    { lat: 47.9541258, lng: 17.7615639 },
    { lat: 47.9541805, lng: 17.7626773 },
    { lat: 47.9542575, lng: 17.7632795 },
    { lat: 47.9544617, lng: 17.7638236 },
    { lat: 47.9557408, lng: 17.765511 },
    { lat: 47.9559162, lng: 17.7660404 },
    { lat: 47.9560378, lng: 17.7669608 },
    { lat: 47.9560095, lng: 17.7677033 },
    { lat: 47.9559231, lng: 17.768128 },
    { lat: 47.9554436, lng: 17.7689168 },
    { lat: 47.9548542, lng: 17.7694777 },
    { lat: 47.9541999, lng: 17.7697957 },
    { lat: 47.9535373, lng: 17.7699969 },
    { lat: 47.9530634, lng: 17.770001 },
    { lat: 47.9527182, lng: 17.7699316 },
    { lat: 47.9524107, lng: 17.76978 },
    { lat: 47.9521145, lng: 17.7696662 },
    { lat: 47.9519185, lng: 17.7699982 },
    { lat: 47.9506792, lng: 17.7698943 },
    { lat: 47.9499912, lng: 17.7702849 },
    { lat: 47.9489232, lng: 17.7714293 },
    { lat: 47.9487233, lng: 17.7717562 },
    { lat: 47.9484729, lng: 17.7731595 },
    { lat: 47.9478861, lng: 17.7755971 },
    { lat: 47.9478715, lng: 17.7767407 },
    { lat: 47.9485601, lng: 17.7784299 },
    { lat: 47.9489225, lng: 17.7797016 },
    { lat: 47.9483087, lng: 17.7804717 },
    { lat: 47.9484533, lng: 17.7808542 },
    { lat: 47.9480727, lng: 17.7815975 },
    { lat: 47.9482522, lng: 17.7817601 },
    { lat: 47.948082, lng: 17.7823843 },
    { lat: 47.9489695, lng: 17.783987 },
    { lat: 47.9466611, lng: 17.7895422 },
    { lat: 47.9462356, lng: 17.7916187 },
    { lat: 47.9455539, lng: 17.7940785 },
    { lat: 47.9446885, lng: 17.7962723 },
    { lat: 47.9436461, lng: 17.7979973 },
    { lat: 47.9423174, lng: 17.7982536 },
    { lat: 47.9417841, lng: 17.7982455 },
    { lat: 47.9410208, lng: 17.7979915 },
    { lat: 47.9403324, lng: 17.796083 },
    { lat: 47.9388971, lng: 17.7966919 },
    { lat: 47.9381688, lng: 17.7964919 },
    { lat: 47.9366182, lng: 17.7958637 },
    { lat: 47.9360276, lng: 17.7955484 },
    { lat: 47.9345557, lng: 17.7940603 },
    { lat: 47.9331958, lng: 17.7915036 },
    { lat: 47.9311723, lng: 17.7887486 },
    { lat: 47.931027, lng: 17.7885521 },
    { lat: 47.9315808, lng: 17.7922017 },
    { lat: 47.9318286, lng: 17.7933026 },
    { lat: 47.9323817, lng: 17.7952669 },
    { lat: 47.934202, lng: 17.8003929 },
    { lat: 47.9357062, lng: 17.8041196 },
    { lat: 47.9358191, lng: 17.804102 },
    { lat: 47.9360549, lng: 17.8053053 },
    { lat: 47.9365482, lng: 17.8087356 },
    { lat: 47.9374421, lng: 17.812693 },
    { lat: 47.9394234, lng: 17.824956 },
    { lat: 47.9394995, lng: 17.8251808 },
    { lat: 47.941429, lng: 17.8292861 },
    { lat: 47.9436862, lng: 17.8338171 },
    { lat: 47.9476992, lng: 17.8415492 },
    { lat: 47.9510065, lng: 17.8477427 },
    { lat: 47.9516874, lng: 17.8487559 },
    { lat: 47.9588593, lng: 17.8582057 },
    { lat: 47.959289, lng: 17.8588589 },
    { lat: 47.9597925, lng: 17.8594116 },
    { lat: 47.9606061, lng: 17.8605459 },
    { lat: 47.9608184, lng: 17.860934 },
    { lat: 47.960937, lng: 17.8615064 },
    { lat: 47.9603676, lng: 17.8617747 },
    { lat: 47.9597814, lng: 17.8623024 },
    { lat: 47.9592912, lng: 17.8628488 },
    { lat: 47.9588494, lng: 17.8635255 },
    { lat: 47.958585, lng: 17.864089 },
    { lat: 47.9579781, lng: 17.8658654 },
    { lat: 47.957717, lng: 17.8673849 },
    { lat: 47.9575773, lng: 17.8685868 },
    { lat: 47.9576008, lng: 17.8697274 },
    { lat: 47.9577036, lng: 17.8710717 },
    { lat: 47.9579241, lng: 17.8722574 },
    { lat: 47.958493, lng: 17.8747981 },
    { lat: 47.9588482, lng: 17.8761371 },
    { lat: 47.959083, lng: 17.877922 },
    { lat: 47.9593226, lng: 17.8778488 },
    { lat: 47.959417, lng: 17.87782 },
    { lat: 47.959654, lng: 17.877747 },
    { lat: 47.959689, lng: 17.877653 },
    { lat: 47.959899, lng: 17.877088 },
    { lat: 47.959908, lng: 17.877071 },
    { lat: 47.959984, lng: 17.876919 },
    { lat: 47.960197, lng: 17.876502 },
    { lat: 47.960239, lng: 17.876417 },
    { lat: 47.960248, lng: 17.876397 },
    { lat: 47.96032, lng: 17.876217 },
    { lat: 47.960568, lng: 17.875601 },
    { lat: 47.960461, lng: 17.875495 },
    { lat: 47.960371, lng: 17.874883 },
    { lat: 47.960303, lng: 17.873448 },
    { lat: 47.960244, lng: 17.87101 },
    { lat: 47.960239, lng: 17.869915 },
    { lat: 47.960389, lng: 17.867523 },
    { lat: 47.960493, lng: 17.867533 },
    { lat: 47.960587, lng: 17.867558 },
    { lat: 47.960809, lng: 17.867622 },
    { lat: 47.961349, lng: 17.867617 },
    { lat: 47.961673, lng: 17.867667 },
    { lat: 47.962009, lng: 17.86769 },
    { lat: 47.962193, lng: 17.867717 },
    { lat: 47.962414, lng: 17.867771 },
    { lat: 47.963418, lng: 17.867098 },
    { lat: 47.964081, lng: 17.866537 },
    { lat: 47.964577, lng: 17.865619 },
    { lat: 47.965006, lng: 17.865141 },
    { lat: 47.965387, lng: 17.864372 },
    { lat: 47.966208, lng: 17.863672 },
    { lat: 47.966435, lng: 17.863182 },
    { lat: 47.966993, lng: 17.862945 },
    { lat: 47.967342, lng: 17.862805 },
    { lat: 47.967769, lng: 17.86249 },
    { lat: 47.968201, lng: 17.862172 },
    { lat: 47.968671, lng: 17.861822 },
    { lat: 47.96903, lng: 17.861514 },
    { lat: 47.969289, lng: 17.861295 },
    { lat: 47.96947, lng: 17.861118 },
    { lat: 47.969722, lng: 17.860858 },
    { lat: 47.969984, lng: 17.860595 },
    { lat: 47.970256, lng: 17.86031 },
    { lat: 47.970625, lng: 17.859942 },
    { lat: 47.970918, lng: 17.859649 },
    { lat: 47.971219, lng: 17.859237 },
    { lat: 47.971575, lng: 17.858741 },
    { lat: 47.97194, lng: 17.858234 },
    { lat: 47.972303, lng: 17.857343 },
    { lat: 47.972637, lng: 17.856492 },
    { lat: 47.97278, lng: 17.855809 },
    { lat: 47.972845, lng: 17.855797 },
    { lat: 47.973003, lng: 17.855764 },
    { lat: 47.973148, lng: 17.855734 },
    { lat: 47.973256, lng: 17.855711 },
    { lat: 47.973291, lng: 17.855704 },
    { lat: 47.973329, lng: 17.855696 },
    { lat: 47.973377, lng: 17.855681 },
    { lat: 47.973487, lng: 17.85565 },
    { lat: 47.973713, lng: 17.855584 },
    { lat: 47.973788, lng: 17.855562 },
    { lat: 47.973809, lng: 17.855557 },
    { lat: 47.974083, lng: 17.855486 },
    { lat: 47.974166, lng: 17.855504 },
    { lat: 47.974284, lng: 17.855524 },
    { lat: 47.974521, lng: 17.855562 },
    { lat: 47.974998, lng: 17.855647 },
    { lat: 47.975239, lng: 17.855598 },
    { lat: 47.975504, lng: 17.855544 },
    { lat: 47.976132, lng: 17.85514 },
    { lat: 47.976292, lng: 17.854989 },
    { lat: 47.976461, lng: 17.854824 },
    { lat: 47.97688, lng: 17.854159 },
    { lat: 47.976889, lng: 17.854021 },
    { lat: 47.976911, lng: 17.853628 },
    { lat: 47.976905, lng: 17.853504 },
    { lat: 47.976819, lng: 17.852973 },
    { lat: 47.976572, lng: 17.852268 },
    { lat: 47.975977, lng: 17.850565 },
    { lat: 47.975889, lng: 17.85023 },
    { lat: 47.975717, lng: 17.849566 },
    { lat: 47.975543, lng: 17.848736 },
    { lat: 47.975421, lng: 17.847896 },
    { lat: 47.975437, lng: 17.847666 },
    { lat: 47.975476, lng: 17.847139 },
    { lat: 47.975575, lng: 17.846612 },
    { lat: 47.97576, lng: 17.846166 },
    { lat: 47.975995, lng: 17.845806 },
    { lat: 47.9764, lng: 17.845553 },
    { lat: 47.977254, lng: 17.845176 },
    { lat: 47.977903, lng: 17.844955 },
    { lat: 47.978585, lng: 17.844631 },
    { lat: 47.979378, lng: 17.844108 },
    { lat: 47.980205, lng: 17.843468 },
    { lat: 47.98062, lng: 17.843047 },
    { lat: 47.980813, lng: 17.842831 },
    { lat: 47.980863, lng: 17.842773 },
    { lat: 47.980993, lng: 17.842472 },
    { lat: 47.981063, lng: 17.84214 },
    { lat: 47.980931, lng: 17.84153 },
    { lat: 47.980395, lng: 17.839996 },
    { lat: 47.980078, lng: 17.839354 },
    { lat: 47.979777, lng: 17.838646 },
    { lat: 47.979727, lng: 17.838369 },
    { lat: 47.979747, lng: 17.838074 },
    { lat: 47.97984, lng: 17.837836 },
    { lat: 47.98006, lng: 17.837449 },
    { lat: 47.980391, lng: 17.837141 },
    { lat: 47.980641, lng: 17.836961 },
    { lat: 47.980912, lng: 17.836854 },
    { lat: 47.981367, lng: 17.836768 },
    { lat: 47.981733, lng: 17.836515 },
    { lat: 47.982286, lng: 17.835659 },
    { lat: 47.982818, lng: 17.834585 },
    { lat: 47.983603, lng: 17.833156 },
    { lat: 47.983852, lng: 17.832851 },
    { lat: 47.983866, lng: 17.832833 },
    { lat: 47.983886, lng: 17.83281 },
    { lat: 47.983924, lng: 17.832764 },
    { lat: 47.983941, lng: 17.832742 },
    { lat: 47.98417, lng: 17.832496 },
    { lat: 47.984316, lng: 17.832352 },
    { lat: 47.984359, lng: 17.832312 },
    { lat: 47.984453, lng: 17.832193 },
    { lat: 47.984584, lng: 17.832034 },
    { lat: 47.984698, lng: 17.831894 },
    { lat: 47.984846, lng: 17.831547 },
    { lat: 47.985057, lng: 17.831384 },
    { lat: 47.985455, lng: 17.831083 },
    { lat: 47.985541, lng: 17.83102 },
    { lat: 47.986005, lng: 17.83078 },
    { lat: 47.986236, lng: 17.830733 },
    { lat: 47.986273, lng: 17.830722 },
    { lat: 47.986442, lng: 17.830691 },
    { lat: 47.98646, lng: 17.830688 },
    { lat: 47.986547, lng: 17.830672 },
    { lat: 47.986641, lng: 17.830655 },
    { lat: 47.986656, lng: 17.830654 },
    { lat: 47.986846, lng: 17.830633 },
    { lat: 47.987052, lng: 17.830618 },
    { lat: 47.987386, lng: 17.830583 },
    { lat: 47.987785, lng: 17.830515 },
    { lat: 47.988025, lng: 17.830482 },
    { lat: 47.988066, lng: 17.830477 },
    { lat: 47.988123, lng: 17.830468 },
    { lat: 47.98816, lng: 17.830459 },
    { lat: 47.988195, lng: 17.830452 },
  ],
  DolnýŠtál: [
    { lat: 47.8979553, lng: 17.7090304 },
    { lat: 47.8979648, lng: 17.7093633 },
    { lat: 47.8980306, lng: 17.7121554 },
    { lat: 47.8983621, lng: 17.7146101 },
    { lat: 47.898614, lng: 17.7157674 },
    { lat: 47.8988246, lng: 17.7172869 },
    { lat: 47.8990135, lng: 17.7179598 },
    { lat: 47.8996977, lng: 17.7191294 },
    { lat: 47.9000613, lng: 17.7195441 },
    { lat: 47.9003872, lng: 17.7202743 },
    { lat: 47.9006419, lng: 17.7211861 },
    { lat: 47.9012355, lng: 17.7221037 },
    { lat: 47.9021924, lng: 17.7224186 },
    { lat: 47.9030872, lng: 17.7228224 },
    { lat: 47.9039961, lng: 17.7230936 },
    { lat: 47.9041495, lng: 17.7231632 },
    { lat: 47.9044982, lng: 17.7235395 },
    { lat: 47.9045584, lng: 17.7237796 },
    { lat: 47.9043234, lng: 17.7257037 },
    { lat: 47.9043732, lng: 17.727005 },
    { lat: 47.9044444, lng: 17.727493 },
    { lat: 47.9048157, lng: 17.7288057 },
    { lat: 47.9052024, lng: 17.7298085 },
    { lat: 47.9056961, lng: 17.7309297 },
    { lat: 47.9062086, lng: 17.732046 },
    { lat: 47.9063661, lng: 17.7325822 },
    { lat: 47.9063737, lng: 17.7330432 },
    { lat: 47.9062451, lng: 17.7335698 },
    { lat: 47.9058043, lng: 17.7346811 },
    { lat: 47.9054634, lng: 17.7353662 },
    { lat: 47.9045545, lng: 17.736721 },
    { lat: 47.9038591, lng: 17.7376433 },
    { lat: 47.9035497, lng: 17.7379464 },
    { lat: 47.9042349, lng: 17.7396422 },
    { lat: 47.9033795, lng: 17.7403358 },
    { lat: 47.9024023, lng: 17.7408308 },
    { lat: 47.9017228, lng: 17.7411246 },
    { lat: 47.9003053, lng: 17.7414936 },
    { lat: 47.9002768, lng: 17.7437816 },
    { lat: 47.9003227, lng: 17.7453686 },
    { lat: 47.9002448, lng: 17.7478601 },
    { lat: 47.8993355, lng: 17.7502027 },
    { lat: 47.8999597, lng: 17.7509971 },
    { lat: 47.9005045, lng: 17.752039 },
    { lat: 47.900856, lng: 17.7532918 },
    { lat: 47.9010742, lng: 17.7549272 },
    { lat: 47.9010397, lng: 17.7566007 },
    { lat: 47.9028529, lng: 17.7575236 },
    { lat: 47.9036713, lng: 17.7571426 },
    { lat: 47.9044852, lng: 17.7568854 },
    { lat: 47.904765, lng: 17.7568644 },
    { lat: 47.9053248, lng: 17.7569912 },
    { lat: 47.9063576, lng: 17.7568034 },
    { lat: 47.907118, lng: 17.7564508 },
    { lat: 47.9072639, lng: 17.7562955 },
    { lat: 47.9076725, lng: 17.7557293 },
    { lat: 47.9079544, lng: 17.7552347 },
    { lat: 47.908118, lng: 17.7547822 },
    { lat: 47.908194, lng: 17.7540285 },
    { lat: 47.9078281, lng: 17.7520347 },
    { lat: 47.9078164, lng: 17.7512875 },
    { lat: 47.9079208, lng: 17.7505711 },
    { lat: 47.9082298, lng: 17.7495721 },
    { lat: 47.9084531, lng: 17.7492795 },
    { lat: 47.9095351, lng: 17.7485807 },
    { lat: 47.9101765, lng: 17.7475688 },
    { lat: 47.9109496, lng: 17.7466432 },
    { lat: 47.9113489, lng: 17.7463663 },
    { lat: 47.9117386, lng: 17.7463142 },
    { lat: 47.9120853, lng: 17.7464789 },
    { lat: 47.9130829, lng: 17.747629 },
    { lat: 47.9135506, lng: 17.7479962 },
    { lat: 47.914342, lng: 17.7480319 },
    { lat: 47.9158377, lng: 17.7475398 },
    { lat: 47.9165129, lng: 17.7477059 },
    { lat: 47.9168287, lng: 17.7478456 },
    { lat: 47.9174064, lng: 17.7485802 },
    { lat: 47.9176148, lng: 17.7487545 },
    { lat: 47.9180788, lng: 17.7485729 },
    { lat: 47.9186257, lng: 17.7485075 },
    { lat: 47.9197012, lng: 17.748788 },
    { lat: 47.9210554, lng: 17.7495952 },
    { lat: 47.921861, lng: 17.7502895 },
    { lat: 47.9271123, lng: 17.7541155 },
    { lat: 47.9272629, lng: 17.7543232 },
    { lat: 47.9273432, lng: 17.7544339 },
    { lat: 47.9276125, lng: 17.7550954 },
    { lat: 47.9280535, lng: 17.7564282 },
    { lat: 47.92871, lng: 17.7573756 },
    { lat: 47.9290152, lng: 17.7581424 },
    { lat: 47.929054, lng: 17.7588372 },
    { lat: 47.9289538, lng: 17.7599289 },
    { lat: 47.9289292, lng: 17.760768 },
    { lat: 47.9293221, lng: 17.7622366 },
    { lat: 47.9297854, lng: 17.7631847 },
    { lat: 47.9303068, lng: 17.7640545 },
    { lat: 47.930781, lng: 17.7655229 },
    { lat: 47.9311494, lng: 17.7662187 },
    { lat: 47.9318367, lng: 17.7673026 },
    { lat: 47.9322155, lng: 17.7677474 },
    { lat: 47.9326953, lng: 17.7680175 },
    { lat: 47.9329365, lng: 17.7687545 },
    { lat: 47.9341793, lng: 17.7715123 },
    { lat: 47.9332661, lng: 17.7737253 },
    { lat: 47.9354643, lng: 17.7757454 },
    { lat: 47.9347664, lng: 17.7788849 },
    { lat: 47.933873, lng: 17.7824215 },
    { lat: 47.9330558, lng: 17.7853318 },
    { lat: 47.9317858, lng: 17.7874069 },
    { lat: 47.931027, lng: 17.7885521 },
    { lat: 47.9311723, lng: 17.7887486 },
    { lat: 47.9331958, lng: 17.7915036 },
    { lat: 47.9345557, lng: 17.7940603 },
    { lat: 47.9360276, lng: 17.7955484 },
    { lat: 47.9366182, lng: 17.7958637 },
    { lat: 47.9381688, lng: 17.7964919 },
    { lat: 47.9388971, lng: 17.7966919 },
    { lat: 47.9403324, lng: 17.796083 },
    { lat: 47.9410208, lng: 17.7979915 },
    { lat: 47.9417841, lng: 17.7982455 },
    { lat: 47.9423174, lng: 17.7982536 },
    { lat: 47.9436461, lng: 17.7979973 },
    { lat: 47.9446885, lng: 17.7962723 },
    { lat: 47.9455539, lng: 17.7940785 },
    { lat: 47.9462356, lng: 17.7916187 },
    { lat: 47.9466611, lng: 17.7895422 },
    { lat: 47.9489695, lng: 17.783987 },
    { lat: 47.948082, lng: 17.7823843 },
    { lat: 47.9482522, lng: 17.7817601 },
    { lat: 47.9480727, lng: 17.7815975 },
    { lat: 47.9484533, lng: 17.7808542 },
    { lat: 47.9483087, lng: 17.7804717 },
    { lat: 47.9489225, lng: 17.7797016 },
    { lat: 47.9485601, lng: 17.7784299 },
    { lat: 47.9478715, lng: 17.7767407 },
    { lat: 47.9478861, lng: 17.7755971 },
    { lat: 47.9484729, lng: 17.7731595 },
    { lat: 47.9487233, lng: 17.7717562 },
    { lat: 47.9489232, lng: 17.7714293 },
    { lat: 47.9499912, lng: 17.7702849 },
    { lat: 47.9506792, lng: 17.7698943 },
    { lat: 47.9519185, lng: 17.7699982 },
    { lat: 47.9521145, lng: 17.7696662 },
    { lat: 47.9524107, lng: 17.76978 },
    { lat: 47.9527182, lng: 17.7699316 },
    { lat: 47.9530634, lng: 17.770001 },
    { lat: 47.9535373, lng: 17.7699969 },
    { lat: 47.9541999, lng: 17.7697957 },
    { lat: 47.9548542, lng: 17.7694777 },
    { lat: 47.9554436, lng: 17.7689168 },
    { lat: 47.9559231, lng: 17.768128 },
    { lat: 47.9560095, lng: 17.7677033 },
    { lat: 47.9560378, lng: 17.7669608 },
    { lat: 47.9559162, lng: 17.7660404 },
    { lat: 47.9557408, lng: 17.765511 },
    { lat: 47.9544617, lng: 17.7638236 },
    { lat: 47.9542575, lng: 17.7632795 },
    { lat: 47.9541805, lng: 17.7626773 },
    { lat: 47.9541258, lng: 17.7615639 },
    { lat: 47.9543739, lng: 17.7605245 },
    { lat: 47.9548781, lng: 17.7598122 },
    { lat: 47.9552344, lng: 17.7594781 },
    { lat: 47.9556282, lng: 17.7592399 },
    { lat: 47.9560312, lng: 17.7591479 },
    { lat: 47.9565325, lng: 17.75922 },
    { lat: 47.9567841, lng: 17.7594188 },
    { lat: 47.9578616, lng: 17.7600241 },
    { lat: 47.9588034, lng: 17.7597737 },
    { lat: 47.9589186, lng: 17.7595612 },
    { lat: 47.9590829, lng: 17.7592578 },
    { lat: 47.9591141, lng: 17.7585973 },
    { lat: 47.9593308, lng: 17.7579436 },
    { lat: 47.9598671, lng: 17.7572366 },
    { lat: 47.9600146, lng: 17.7569422 },
    { lat: 47.9600649, lng: 17.7566598 },
    { lat: 47.9606406, lng: 17.7553549 },
    { lat: 47.9586507, lng: 17.7536052 },
    { lat: 47.958064, lng: 17.7518946 },
    { lat: 47.957436, lng: 17.7494451 },
    { lat: 47.957285, lng: 17.7484875 },
    { lat: 47.9570483, lng: 17.7475186 },
    { lat: 47.9569556, lng: 17.7465382 },
    { lat: 47.9570927, lng: 17.7435448 },
    { lat: 47.9571808, lng: 17.7428384 },
    { lat: 47.957488, lng: 17.7413574 },
    { lat: 47.9580551, lng: 17.7395358 },
    { lat: 47.9582494, lng: 17.7392677 },
    { lat: 47.958367, lng: 17.738927 },
    { lat: 47.9589652, lng: 17.7378424 },
    { lat: 47.9615927, lng: 17.7306721 },
    { lat: 47.9626278, lng: 17.7286638 },
    { lat: 47.9630184, lng: 17.7270942 },
    { lat: 47.9629672, lng: 17.7265102 },
    { lat: 47.962821, lng: 17.7261976 },
    { lat: 47.9625348, lng: 17.7259154 },
    { lat: 47.9626889, lng: 17.7257453 },
    { lat: 47.9633493, lng: 17.7240122 },
    { lat: 47.9636333, lng: 17.7237491 },
    { lat: 47.9640486, lng: 17.7215476 },
    { lat: 47.9644602, lng: 17.7199362 },
    { lat: 47.9643595, lng: 17.7197156 },
    { lat: 47.9641692, lng: 17.717633 },
    { lat: 47.9636772, lng: 17.7156546 },
    { lat: 47.9626038, lng: 17.7125612 },
    { lat: 47.9622966, lng: 17.7118951 },
    { lat: 47.9611056, lng: 17.7104385 },
    { lat: 47.9593959, lng: 17.7084867 },
    { lat: 47.9590722, lng: 17.7089797 },
    { lat: 47.9586269, lng: 17.7101234 },
    { lat: 47.9580168, lng: 17.7119204 },
    { lat: 47.9571533, lng: 17.7137582 },
    { lat: 47.9569432, lng: 17.7139737 },
    { lat: 47.9551943, lng: 17.7089138 },
    { lat: 47.9523536, lng: 17.7086103 },
    { lat: 47.9505034, lng: 17.7075342 },
    { lat: 47.9500252, lng: 17.7072552 },
    { lat: 47.9494128, lng: 17.7071924 },
    { lat: 47.9485711, lng: 17.7056187 },
    { lat: 47.9477973, lng: 17.7039056 },
    { lat: 47.9461661, lng: 17.7015185 },
    { lat: 47.9452279, lng: 17.6999912 },
    { lat: 47.9441583, lng: 17.698441 },
    { lat: 47.9438833, lng: 17.6974566 },
    { lat: 47.9438913, lng: 17.6964558 },
    { lat: 47.9441345, lng: 17.6952574 },
    { lat: 47.9444411, lng: 17.694497 },
    { lat: 47.9450322, lng: 17.6937164 },
    { lat: 47.9449426, lng: 17.6933676 },
    { lat: 47.944929, lng: 17.6926134 },
    { lat: 47.9459517, lng: 17.6912616 },
    { lat: 47.9462472, lng: 17.6902147 },
    { lat: 47.9453281, lng: 17.689337 },
    { lat: 47.9444601, lng: 17.6890361 },
    { lat: 47.9459842, lng: 17.6860357 },
    { lat: 47.9454547, lng: 17.6849611 },
    { lat: 47.9446544, lng: 17.6839078 },
    { lat: 47.9441346, lng: 17.6835948 },
    { lat: 47.9431578, lng: 17.683237 },
    { lat: 47.9428056, lng: 17.6828241 },
    { lat: 47.9419395, lng: 17.6822333 },
    { lat: 47.9418736, lng: 17.6825273 },
    { lat: 47.9417855, lng: 17.6829956 },
    { lat: 47.941528, lng: 17.6837643 },
    { lat: 47.9401878, lng: 17.6865809 },
    { lat: 47.9402955, lng: 17.6868039 },
    { lat: 47.9401821, lng: 17.6875665 },
    { lat: 47.940396, lng: 17.6890811 },
    { lat: 47.940425, lng: 17.689975 },
    { lat: 47.9404083, lng: 17.6902287 },
    { lat: 47.9401886, lng: 17.6911136 },
    { lat: 47.9401171, lng: 17.6920084 },
    { lat: 47.9403374, lng: 17.6943073 },
    { lat: 47.9404397, lng: 17.6967962 },
    { lat: 47.940423, lng: 17.697463 },
    { lat: 47.9406335, lng: 17.6980453 },
    { lat: 47.9408097, lng: 17.6988021 },
    { lat: 47.9412013, lng: 17.7005086 },
    { lat: 47.9412604, lng: 17.7010676 },
    { lat: 47.93927, lng: 17.7013403 },
    { lat: 47.9389517, lng: 17.701629 },
    { lat: 47.9384287, lng: 17.7023471 },
    { lat: 47.9379893, lng: 17.7032769 },
    { lat: 47.9371308, lng: 17.702589 },
    { lat: 47.9368315, lng: 17.7023507 },
    { lat: 47.9364827, lng: 17.7029301 },
    { lat: 47.9350302, lng: 17.7016454 },
    { lat: 47.9332448, lng: 17.7028792 },
    { lat: 47.9326796, lng: 17.7018252 },
    { lat: 47.9326353, lng: 17.7007069 },
    { lat: 47.931631, lng: 17.7010941 },
    { lat: 47.9314386, lng: 17.6998996 },
    { lat: 47.9316054, lng: 17.6991589 },
    { lat: 47.9283412, lng: 17.7005766 },
    { lat: 47.928308, lng: 17.7005904 },
    { lat: 47.9278247, lng: 17.6958633 },
    { lat: 47.9225647, lng: 17.6978982 },
    { lat: 47.922732, lng: 17.6980453 },
    { lat: 47.9225536, lng: 17.6979702 },
    { lat: 47.9203874, lng: 17.7016981 },
    { lat: 47.920337, lng: 17.7016875 },
    { lat: 47.9203442, lng: 17.7011356 },
    { lat: 47.920137, lng: 17.7007664 },
    { lat: 47.9194403, lng: 17.7001738 },
    { lat: 47.9194619, lng: 17.6988463 },
    { lat: 47.9194223, lng: 17.6981824 },
    { lat: 47.9194547, lng: 17.6979973 },
    { lat: 47.9193653, lng: 17.6977419 },
    { lat: 47.9189413, lng: 17.6968879 },
    { lat: 47.9186493, lng: 17.6973417 },
    { lat: 47.9182518, lng: 17.696356 },
    { lat: 47.9182427, lng: 17.6962302 },
    { lat: 47.9170256, lng: 17.6942734 },
    { lat: 47.9153814, lng: 17.6957056 },
    { lat: 47.9142397, lng: 17.701197 },
    { lat: 47.9144294, lng: 17.7012156 },
    { lat: 47.9149345, lng: 17.7015276 },
    { lat: 47.9155014, lng: 17.7016712 },
    { lat: 47.9163422, lng: 17.7023328 },
    { lat: 47.9174848, lng: 17.7037865 },
    { lat: 47.9179327, lng: 17.7044806 },
    { lat: 47.9181013, lng: 17.7048813 },
    { lat: 47.9181483, lng: 17.7052721 },
    { lat: 47.9182712, lng: 17.7054321 },
    { lat: 47.9186406, lng: 17.706602 },
    { lat: 47.9189636, lng: 17.7081318 },
    { lat: 47.9190193, lng: 17.7084799 },
    { lat: 47.9190398, lng: 17.7092373 },
    { lat: 47.9189041, lng: 17.7103698 },
    { lat: 47.9186954, lng: 17.7110361 },
    { lat: 47.9181724, lng: 17.7120939 },
    { lat: 47.9177627, lng: 17.7126896 },
    { lat: 47.9168174, lng: 17.7137472 },
    { lat: 47.9164568, lng: 17.714045 },
    { lat: 47.9161305, lng: 17.7144071 },
    { lat: 47.9114341, lng: 17.7178868 },
    { lat: 47.9108035, lng: 17.7181658 },
    { lat: 47.9095691, lng: 17.7185562 },
    { lat: 47.9092531, lng: 17.7186141 },
    { lat: 47.9085401, lng: 17.7175243 },
    { lat: 47.9063102, lng: 17.7180814 },
    { lat: 47.9056339, lng: 17.718133 },
    { lat: 47.9045281, lng: 17.7179113 },
    { lat: 47.9041029, lng: 17.7177215 },
    { lat: 47.9032694, lng: 17.717069 },
    { lat: 47.9027943, lng: 17.7164759 },
    { lat: 47.9022922, lng: 17.715566 },
    { lat: 47.9018434, lng: 17.7143673 },
    { lat: 47.9014326, lng: 17.7127671 },
    { lat: 47.9012184, lng: 17.7111563 },
    { lat: 47.9009636, lng: 17.70983 },
    { lat: 47.8979553, lng: 17.7090304 },
  ],
  MichalnaOstrove: [
    { lat: 48.0848188, lng: 17.6121557 },
    { lat: 48.0850351, lng: 17.6120023 },
    { lat: 48.088022, lng: 17.6099404 },
    { lat: 48.0854925, lng: 17.6044102 },
    { lat: 48.0838519, lng: 17.6010007 },
    { lat: 48.0823277, lng: 17.5968439 },
    { lat: 48.0820155, lng: 17.596251 },
    { lat: 48.0810664, lng: 17.5948031 },
    { lat: 48.0794139, lng: 17.591833 },
    { lat: 48.0774496, lng: 17.5891067 },
    { lat: 48.0764144, lng: 17.588138 },
    { lat: 48.0748535, lng: 17.5862708 },
    { lat: 48.07472, lng: 17.5859462 },
    { lat: 48.0716499, lng: 17.581518 },
    { lat: 48.07028, lng: 17.5791453 },
    { lat: 48.069311, lng: 17.5773202 },
    { lat: 48.0688022, lng: 17.5761768 },
    { lat: 48.0679886, lng: 17.5739531 },
    { lat: 48.0647438, lng: 17.5658092 },
    { lat: 48.0637414, lng: 17.564241 },
    { lat: 48.0627388, lng: 17.5629725 },
    { lat: 48.0609913, lng: 17.5601552 },
    { lat: 48.0601102, lng: 17.5582067 },
    { lat: 48.0594033, lng: 17.556938 },
    { lat: 48.0581534, lng: 17.5562533 },
    { lat: 48.0575936, lng: 17.5556743 },
    { lat: 48.0572977, lng: 17.5549847 },
    { lat: 48.0553153, lng: 17.5478344 },
    { lat: 48.0520616, lng: 17.5429131 },
    { lat: 48.0495003, lng: 17.5388631 },
    { lat: 48.0469436, lng: 17.5351117 },
    { lat: 48.0451898, lng: 17.5326842 },
    { lat: 48.0395786, lng: 17.5216344 },
    { lat: 48.0358977, lng: 17.5185009 },
    { lat: 48.0359665, lng: 17.5183429 },
    { lat: 48.0341282, lng: 17.515624 },
    { lat: 48.0337307, lng: 17.5147469 },
    { lat: 48.0330981, lng: 17.5129222 },
    { lat: 48.0325996, lng: 17.5122308 },
    { lat: 48.0319566, lng: 17.5117152 },
    { lat: 48.0315428, lng: 17.5111983 },
    { lat: 48.0305703, lng: 17.5093745 },
    { lat: 48.0301885, lng: 17.5087415 },
    { lat: 48.0297981, lng: 17.5082544 },
    { lat: 48.0294789, lng: 17.5078775 },
    { lat: 48.027906, lng: 17.506578 },
    { lat: 48.0277329, lng: 17.5065078 },
    { lat: 48.0271549, lng: 17.5063186 },
    { lat: 48.0264478, lng: 17.5057577 },
    { lat: 48.0260534, lng: 17.503387 },
    { lat: 48.0245672, lng: 17.5030024 },
    { lat: 48.0247981, lng: 17.5007521 },
    { lat: 48.0231914, lng: 17.5015288 },
    { lat: 48.0221203, lng: 17.499455 },
    { lat: 48.0204096, lng: 17.4964676 },
    { lat: 48.0195496, lng: 17.4958964 },
    { lat: 48.0180886, lng: 17.4945564 },
    { lat: 48.0172908, lng: 17.4944789 },
    { lat: 48.0134586, lng: 17.4924796 },
    { lat: 48.0134127, lng: 17.4925056 },
    { lat: 48.0107383, lng: 17.4939263 },
    { lat: 48.0107788, lng: 17.4942078 },
    { lat: 48.0107842, lng: 17.4942621 },
    { lat: 48.0106202, lng: 17.4955521 },
    { lat: 48.0102973, lng: 17.4976178 },
    { lat: 48.0104463, lng: 17.4979686 },
    { lat: 48.0106905, lng: 17.4988481 },
    { lat: 48.0111043, lng: 17.500658 },
    { lat: 48.0111889, lng: 17.5013562 },
    { lat: 48.0114468, lng: 17.5023053 },
    { lat: 48.0115597, lng: 17.503198 },
    { lat: 48.0114678, lng: 17.5035589 },
    { lat: 48.0126018, lng: 17.5060329 },
    { lat: 48.0140506, lng: 17.5104786 },
    { lat: 48.0152261, lng: 17.5142642 },
    { lat: 48.0159738, lng: 17.5163536 },
    { lat: 48.0165606, lng: 17.5176978 },
    { lat: 48.0162107, lng: 17.5192984 },
    { lat: 48.0172699, lng: 17.5215412 },
    { lat: 48.0171295, lng: 17.5217278 },
    { lat: 48.0182939, lng: 17.5239451 },
    { lat: 48.0186906, lng: 17.523033 },
    { lat: 48.0191363, lng: 17.5235571 },
    { lat: 48.0199154, lng: 17.5244866 },
    { lat: 48.0200737, lng: 17.5246629 },
    { lat: 48.0201131, lng: 17.5245913 },
    { lat: 48.0216392, lng: 17.5263346 },
    { lat: 48.0217802, lng: 17.5260181 },
    { lat: 48.0231593, lng: 17.5274961 },
    { lat: 48.0232172, lng: 17.5273961 },
    { lat: 48.0246966, lng: 17.5287867 },
    { lat: 48.025874, lng: 17.5303937 },
    { lat: 48.026443, lng: 17.5313236 },
    { lat: 48.0262345, lng: 17.5327708 },
    { lat: 48.0278862, lng: 17.5337684 },
    { lat: 48.0288988, lng: 17.5316169 },
    { lat: 48.0308265, lng: 17.5341821 },
    { lat: 48.0326324, lng: 17.5356458 },
    { lat: 48.0335254, lng: 17.5362241 },
    { lat: 48.0453061, lng: 17.5460833 },
    { lat: 48.0511165, lng: 17.5519416 },
    { lat: 48.0536306, lng: 17.5555116 },
    { lat: 48.0573095, lng: 17.5632956 },
    { lat: 48.0583406, lng: 17.5665814 },
    { lat: 48.0602547, lng: 17.5715506 },
    { lat: 48.0602164, lng: 17.5721922 },
    { lat: 48.0605679, lng: 17.5729019 },
    { lat: 48.0613892, lng: 17.5733504 },
    { lat: 48.0616823, lng: 17.5736455 },
    { lat: 48.0620074, lng: 17.5742598 },
    { lat: 48.0621497, lng: 17.5748285 },
    { lat: 48.0622941, lng: 17.5762945 },
    { lat: 48.0623153, lng: 17.5785758 },
    { lat: 48.0624164, lng: 17.5794146 },
    { lat: 48.062528, lng: 17.5798739 },
    { lat: 48.062723, lng: 17.5803306 },
    { lat: 48.0629374, lng: 17.5805748 },
    { lat: 48.0630859, lng: 17.5806547 },
    { lat: 48.0636755, lng: 17.5807278 },
    { lat: 48.0638094, lng: 17.5808235 },
    { lat: 48.063892, lng: 17.5809876 },
    { lat: 48.064212, lng: 17.5830127 },
    { lat: 48.0648177, lng: 17.5853071 },
    { lat: 48.0666903, lng: 17.5880954 },
    { lat: 48.0708245, lng: 17.5945024 },
    { lat: 48.0724875, lng: 17.5967863 },
    { lat: 48.074131, lng: 17.5988723 },
    { lat: 48.0774553, lng: 17.6026926 },
    { lat: 48.0807139, lng: 17.6066155 },
    { lat: 48.0824464, lng: 17.6088619 },
    { lat: 48.0841923, lng: 17.6113031 },
    { lat: 48.0848188, lng: 17.6121557 },
  ],
  VojkanadDunajom: [
    { lat: 47.9832418, lng: 17.3903233 },
    { lat: 47.9820557, lng: 17.387911 },
    { lat: 47.977247, lng: 17.3820471 },
    { lat: 47.9773624, lng: 17.3815873 },
    { lat: 47.9775947, lng: 17.3811851 },
    { lat: 47.9776824, lng: 17.3805928 },
    { lat: 47.9780773, lng: 17.3800887 },
    { lat: 47.9756087, lng: 17.3774293 },
    { lat: 47.9755409, lng: 17.3774773 },
    { lat: 47.9754776, lng: 17.3774107 },
    { lat: 47.9743819, lng: 17.3784046 },
    { lat: 47.9741116, lng: 17.3783512 },
    { lat: 47.9740257, lng: 17.3784336 },
    { lat: 47.9738344, lng: 17.3784219 },
    { lat: 47.9735741, lng: 17.3782125 },
    { lat: 47.973489, lng: 17.3782773 },
    { lat: 47.9709203, lng: 17.3775175 },
    { lat: 47.9694009, lng: 17.3775991 },
    { lat: 47.9622587, lng: 17.3727578 },
    { lat: 47.960257, lng: 17.374359 },
    { lat: 47.959406, lng: 17.375299 },
    { lat: 47.958646, lng: 17.376398 },
    { lat: 47.957386, lng: 17.378679 },
    { lat: 47.95663, lng: 17.380296 },
    { lat: 47.955946, lng: 17.381981 },
    { lat: 47.952857, lng: 17.390881 },
    { lat: 47.952306, lng: 17.392811 },
    { lat: 47.951845, lng: 17.394794 },
    { lat: 47.951518, lng: 17.396443 },
    { lat: 47.951179, lng: 17.397684 },
    { lat: 47.950738, lng: 17.398851 },
    { lat: 47.950482, lng: 17.399401 },
    { lat: 47.949902, lng: 17.400426 },
    { lat: 47.949581, lng: 17.400896 },
    { lat: 47.947849, lng: 17.402993 },
    { lat: 47.946824, lng: 17.403998 },
    { lat: 47.946286, lng: 17.404439 },
    { lat: 47.945457, lng: 17.404971 },
    { lat: 47.945026, lng: 17.405171 },
    { lat: 47.944203, lng: 17.405432 },
    { lat: 47.942223, lng: 17.405846 },
    { lat: 47.94142, lng: 17.406162 },
    { lat: 47.940645, lng: 17.406605 },
    { lat: 47.939201, lng: 17.407672 },
    { lat: 47.937795, lng: 17.408847 },
    { lat: 47.936432, lng: 17.410128 },
    { lat: 47.935115, lng: 17.411511 },
    { lat: 47.934648, lng: 17.412054 },
    { lat: 47.9340297, lng: 17.4129033 },
    { lat: 47.9389934, lng: 17.4181921 },
    { lat: 47.9429014, lng: 17.4222215 },
    { lat: 47.9435835, lng: 17.4229112 },
    { lat: 47.944051, lng: 17.4232377 },
    { lat: 47.9455529, lng: 17.4245856 },
    { lat: 47.946735, lng: 17.4268295 },
    { lat: 47.9472249, lng: 17.4254911 },
    { lat: 47.9479741, lng: 17.4234738 },
    { lat: 47.9484737, lng: 17.4223711 },
    { lat: 47.9489729, lng: 17.4214735 },
    { lat: 47.9505061, lng: 17.4192854 },
    { lat: 47.9509091, lng: 17.4183823 },
    { lat: 47.9510601, lng: 17.4183241 },
    { lat: 47.9512915, lng: 17.4180292 },
    { lat: 47.9518641, lng: 17.416592 },
    { lat: 47.9526564, lng: 17.4166035 },
    { lat: 47.9578602, lng: 17.4166789 },
    { lat: 47.959063, lng: 17.4152401 },
    { lat: 47.959238, lng: 17.4151006 },
    { lat: 47.9593941, lng: 17.4150562 },
    { lat: 47.9596329, lng: 17.4151139 },
    { lat: 47.959746, lng: 17.4150489 },
    { lat: 47.9601054, lng: 17.4146015 },
    { lat: 47.961062, lng: 17.4140316 },
    { lat: 47.9616445, lng: 17.4140758 },
    { lat: 47.9635382, lng: 17.4127221 },
    { lat: 47.9651861, lng: 17.4116347 },
    { lat: 47.9652474, lng: 17.4113315 },
    { lat: 47.9653695, lng: 17.4098281 },
    { lat: 47.9656712, lng: 17.4081368 },
    { lat: 47.9663436, lng: 17.4055026 },
    { lat: 47.966738, lng: 17.4033986 },
    { lat: 47.9666218, lng: 17.4024836 },
    { lat: 47.9691266, lng: 17.398287 },
    { lat: 47.9699469, lng: 17.3969445 },
    { lat: 47.9704987, lng: 17.3960159 },
    { lat: 47.9712474, lng: 17.3947633 },
    { lat: 47.9720501, lng: 17.3933868 },
    { lat: 47.9729079, lng: 17.3920301 },
    { lat: 47.9733385, lng: 17.3913242 },
    { lat: 47.9747806, lng: 17.3889412 },
    { lat: 47.9746983, lng: 17.388791 },
    { lat: 47.974841, lng: 17.3886053 },
    { lat: 47.9749381, lng: 17.3886754 },
    { lat: 47.9757729, lng: 17.387315 },
    { lat: 47.9771149, lng: 17.3880585 },
    { lat: 47.9781373, lng: 17.3888552 },
    { lat: 47.9786395, lng: 17.3895179 },
    { lat: 47.9793058, lng: 17.3906569 },
    { lat: 47.981533, lng: 17.3928061 },
    { lat: 47.9828044, lng: 17.390854 },
    { lat: 47.9832418, lng: 17.3903233 },
  ],
  Hviezdoslavov: [
    { lat: 48.085072, lng: 17.34971 },
    { lat: 48.084472, lng: 17.345245 },
    { lat: 48.084527, lng: 17.341331 },
    { lat: 48.0835933, lng: 17.3389046 },
    { lat: 48.0834937, lng: 17.3386651 },
    { lat: 48.081328, lng: 17.333831 },
    { lat: 48.080869, lng: 17.335654 },
    { lat: 48.069178, lng: 17.331638 },
    { lat: 48.069148, lng: 17.330098 },
    { lat: 48.069679, lng: 17.327558 },
    { lat: 48.069886, lng: 17.326824 },
    { lat: 48.070828, lng: 17.324001 },
    { lat: 48.06272, lng: 17.318914 },
    { lat: 48.0624759, lng: 17.3201356 },
    { lat: 48.0639764, lng: 17.3216795 },
    { lat: 48.063796, lng: 17.3224028 },
    { lat: 48.0644564, lng: 17.3230801 },
    { lat: 48.0642777, lng: 17.3256786 },
    { lat: 48.0639844, lng: 17.3270025 },
    { lat: 48.0639995, lng: 17.3273675 },
    { lat: 48.0652096, lng: 17.3281757 },
    { lat: 48.0644282, lng: 17.331142 },
    { lat: 48.0638183, lng: 17.3324632 },
    { lat: 48.0633597, lng: 17.3331828 },
    { lat: 48.0622969, lng: 17.3359084 },
    { lat: 48.0622909, lng: 17.3362447 },
    { lat: 48.0623401, lng: 17.3363049 },
    { lat: 48.0622901, lng: 17.3363857 },
    { lat: 48.0621502, lng: 17.3362855 },
    { lat: 48.0605011, lng: 17.3405386 },
    { lat: 48.060529, lng: 17.3406196 },
    { lat: 48.0602576, lng: 17.3412823 },
    { lat: 48.060227, lng: 17.3417769 },
    { lat: 48.0603061, lng: 17.3418465 },
    { lat: 48.0602551, lng: 17.3419994 },
    { lat: 48.0600534, lng: 17.3418369 },
    { lat: 48.0593678, lng: 17.3434483 },
    { lat: 48.0590866, lng: 17.3442896 },
    { lat: 48.0588848, lng: 17.3451711 },
    { lat: 48.0589609, lng: 17.3452634 },
    { lat: 48.0588526, lng: 17.3457613 },
    { lat: 48.0588032, lng: 17.3456117 },
    { lat: 48.0579688, lng: 17.3505266 },
    { lat: 48.057758, lng: 17.3535039 },
    { lat: 48.0576451, lng: 17.3541979 },
    { lat: 48.0575819, lng: 17.3545861 },
    { lat: 48.0572614, lng: 17.3557592 },
    { lat: 48.0572899, lng: 17.3560382 },
    { lat: 48.0571088, lng: 17.357388 },
    { lat: 48.0566677, lng: 17.3570179 },
    { lat: 48.056593, lng: 17.3571213 },
    { lat: 48.0577774, lng: 17.358211 },
    { lat: 48.0601293, lng: 17.3606903 },
    { lat: 48.0590022, lng: 17.3633757 },
    { lat: 48.0578584, lng: 17.3623499 },
    { lat: 48.0565611, lng: 17.3655824 },
    { lat: 48.0560802, lng: 17.3667808 },
    { lat: 48.0537892, lng: 17.3646968 },
    { lat: 48.0541369, lng: 17.3638146 },
    { lat: 48.0540338, lng: 17.3637251 },
    { lat: 48.053722, lng: 17.3645308 },
    { lat: 48.053108, lng: 17.3658293 },
    { lat: 48.053036, lng: 17.366142 },
    { lat: 48.0523851, lng: 17.3676196 },
    { lat: 48.0518738, lng: 17.3688448 },
    { lat: 48.0687125, lng: 17.3843098 },
    { lat: 48.0687644, lng: 17.3841734 },
    { lat: 48.0672011, lng: 17.3770361 },
    { lat: 48.0695544, lng: 17.379354 },
    { lat: 48.0700164, lng: 17.3774563 },
    { lat: 48.0703548, lng: 17.3776403 },
    { lat: 48.0715273, lng: 17.37287 },
    { lat: 48.07509, lng: 17.3765919 },
    { lat: 48.0754641, lng: 17.3770776 },
    { lat: 48.0804224, lng: 17.382401 },
    { lat: 48.0804382, lng: 17.3823683 },
    { lat: 48.0804522, lng: 17.3823402 },
    { lat: 48.0810903, lng: 17.3808225 },
    { lat: 48.0817154, lng: 17.3795357 },
    { lat: 48.0823603, lng: 17.3787941 },
    { lat: 48.0825568, lng: 17.3783617 },
    { lat: 48.0828951, lng: 17.3773187 },
    { lat: 48.0837586, lng: 17.3757412 },
    { lat: 48.0845942, lng: 17.3740126 },
    { lat: 48.0851866, lng: 17.3723509 },
    { lat: 48.0855953, lng: 17.370574 },
    { lat: 48.0867593, lng: 17.3552558 },
    { lat: 48.0866944, lng: 17.3553656 },
    { lat: 48.0861824, lng: 17.3551025 },
    { lat: 48.0858156, lng: 17.3551258 },
    { lat: 48.085072, lng: 17.34971 },
  ],
  Kútniky: [
    { lat: 47.97779, lng: 17.644725 },
    { lat: 47.97558, lng: 17.6487708 },
    { lat: 47.9753024, lng: 17.6485616 },
    { lat: 47.9751937, lng: 17.6483433 },
    { lat: 47.974945, lng: 17.6481586 },
    { lat: 47.9747985, lng: 17.647948 },
    { lat: 47.9745834, lng: 17.6481354 },
    { lat: 47.9747341, lng: 17.648734 },
    { lat: 47.9737778, lng: 17.6504859 },
    { lat: 47.9722615, lng: 17.6485756 },
    { lat: 47.9696913, lng: 17.6514285 },
    { lat: 47.9691532, lng: 17.6520606 },
    { lat: 47.9689725, lng: 17.6523643 },
    { lat: 47.9657059, lng: 17.6582946 },
    { lat: 47.9654911, lng: 17.6586854 },
    { lat: 47.9652247, lng: 17.6591691 },
    { lat: 47.9633645, lng: 17.6625565 },
    { lat: 47.9632643, lng: 17.662456 },
    { lat: 47.9621482, lng: 17.6649341 },
    { lat: 47.960358, lng: 17.6631707 },
    { lat: 47.9601785, lng: 17.663262 },
    { lat: 47.9585778, lng: 17.6641081 },
    { lat: 47.9575281, lng: 17.6642761 },
    { lat: 47.9566335, lng: 17.6652879 },
    { lat: 47.9553259, lng: 17.6669157 },
    { lat: 47.9541996, lng: 17.6680326 },
    { lat: 47.9529824, lng: 17.6693778 },
    { lat: 47.9512035, lng: 17.6723578 },
    { lat: 47.9504517, lng: 17.6765856 },
    { lat: 47.9505261, lng: 17.6782499 },
    { lat: 47.9507756, lng: 17.6811513 },
    { lat: 47.951409, lng: 17.6834843 },
    { lat: 47.9523693, lng: 17.6850511 },
    { lat: 47.953305, lng: 17.6847987 },
    { lat: 47.9547752, lng: 17.6842794 },
    { lat: 47.9553139, lng: 17.6846396 },
    { lat: 47.9553699, lng: 17.6841837 },
    { lat: 47.9558189, lng: 17.6833716 },
    { lat: 47.9561022, lng: 17.6825452 },
    { lat: 47.9559436, lng: 17.6821228 },
    { lat: 47.95576, lng: 17.6818272 },
    { lat: 47.9567508, lng: 17.6786819 },
    { lat: 47.9571246, lng: 17.6778396 },
    { lat: 47.9584665, lng: 17.6762538 },
    { lat: 47.9590027, lng: 17.6756945 },
    { lat: 47.9595931, lng: 17.6748699 },
    { lat: 47.9603232, lng: 17.6743064 },
    { lat: 47.960957, lng: 17.6739564 },
    { lat: 47.9612912, lng: 17.6736124 },
    { lat: 47.9643001, lng: 17.6682591 },
    { lat: 47.9649738, lng: 17.6695966 },
    { lat: 47.9653684, lng: 17.670462 },
    { lat: 47.9658506, lng: 17.6715008 },
    { lat: 47.9663695, lng: 17.6719966 },
    { lat: 47.9674161, lng: 17.6726024 },
    { lat: 47.9680537, lng: 17.674408 },
    { lat: 47.9692082, lng: 17.6784309 },
    { lat: 47.9693368, lng: 17.6787339 },
    { lat: 47.9694466, lng: 17.6789708 },
    { lat: 47.9700465, lng: 17.6795656 },
    { lat: 47.9721359, lng: 17.6830192 },
    { lat: 47.9715154, lng: 17.6841751 },
    { lat: 47.9720169, lng: 17.6866744 },
    { lat: 47.9720307, lng: 17.6867313 },
    { lat: 47.9720841, lng: 17.6869514 },
    { lat: 47.9717246, lng: 17.6868712 },
    { lat: 47.9712975, lng: 17.6869579 },
    { lat: 47.9710084, lng: 17.6871015 },
    { lat: 47.9703382, lng: 17.687628 },
    { lat: 47.9691685, lng: 17.6892438 },
    { lat: 47.968924, lng: 17.6901513 },
    { lat: 47.9687091, lng: 17.6914009 },
    { lat: 47.9691182, lng: 17.6934021 },
    { lat: 47.9712203, lng: 17.6960176 },
    { lat: 47.9718068, lng: 17.6968734 },
    { lat: 47.9730083, lng: 17.6988169 },
    { lat: 47.9758205, lng: 17.7019421 },
    { lat: 47.9773893, lng: 17.7021482 },
    { lat: 47.9782665, lng: 17.7024148 },
    { lat: 47.9785831, lng: 17.7011437 },
    { lat: 47.9786985, lng: 17.7007175 },
    { lat: 47.978571, lng: 17.7003064 },
    { lat: 47.9786444, lng: 17.6999093 },
    { lat: 47.9780049, lng: 17.6987894 },
    { lat: 47.9773068, lng: 17.6973069 },
    { lat: 47.9775132, lng: 17.6963186 },
    { lat: 47.978282, lng: 17.6957922 },
    { lat: 47.979195, lng: 17.6943675 },
    { lat: 47.9812061, lng: 17.6909205 },
    { lat: 47.9868537, lng: 17.6802751 },
    { lat: 47.9870809, lng: 17.6799955 },
    { lat: 47.987498, lng: 17.6796412 },
    { lat: 47.9885413, lng: 17.6789435 },
    { lat: 47.9888128, lng: 17.6784685 },
    { lat: 47.9890319, lng: 17.6772131 },
    { lat: 47.9894641, lng: 17.67728 },
    { lat: 47.9901157, lng: 17.6771931 },
    { lat: 47.9904946, lng: 17.6769509 },
    { lat: 47.990815, lng: 17.6765882 },
    { lat: 47.9910602, lng: 17.6762325 },
    { lat: 47.9914412, lng: 17.6748613 },
    { lat: 47.9913848, lng: 17.674485 },
    { lat: 47.9913954, lng: 17.6741867 },
    { lat: 47.991581, lng: 17.6733709 },
    { lat: 47.9918191, lng: 17.6725791 },
    { lat: 47.9923226, lng: 17.6695506 },
    { lat: 47.9923669, lng: 17.6690372 },
    { lat: 47.9923422, lng: 17.6683216 },
    { lat: 47.9924661, lng: 17.6670074 },
    { lat: 47.9924513, lng: 17.6663074 },
    { lat: 47.9925606, lng: 17.6652127 },
    { lat: 47.9931755, lng: 17.6613941 },
    { lat: 47.9932406, lng: 17.6605471 },
    { lat: 47.9932711, lng: 17.6589013 },
    { lat: 47.9934028, lng: 17.6576068 },
    { lat: 47.9935594, lng: 17.6546697 },
    { lat: 47.9935638, lng: 17.6529186 },
    { lat: 47.9934414, lng: 17.6513379 },
    { lat: 47.9934485, lng: 17.6502526 },
    { lat: 47.9935865, lng: 17.6490961 },
    { lat: 47.9935584, lng: 17.6484542 },
    { lat: 47.9930088, lng: 17.6466917 },
    { lat: 47.9928217, lng: 17.6464465 },
    { lat: 47.9923392, lng: 17.6460666 },
    { lat: 47.9922346, lng: 17.6459847 },
    { lat: 47.9917495, lng: 17.6455618 },
    { lat: 47.99166, lng: 17.6452916 },
    { lat: 47.9914336, lng: 17.6434436 },
    { lat: 47.9912133, lng: 17.6430667 },
    { lat: 47.9901529, lng: 17.6418152 },
    { lat: 47.9897299, lng: 17.641286 },
    { lat: 47.9891935, lng: 17.6406092 },
    { lat: 47.9888117, lng: 17.6402282 },
    { lat: 47.9871615, lng: 17.6387587 },
    { lat: 47.9871543, lng: 17.6387537 },
    { lat: 47.9869619, lng: 17.6386228 },
    { lat: 47.9855958, lng: 17.6373757 },
    { lat: 47.9825898, lng: 17.6358397 },
    { lat: 47.9825014, lng: 17.6360135 },
    { lat: 47.97779, lng: 17.644725 },
  ],
  ŠtvrtoknaOstrove: [
    { lat: 48.1213272, lng: 17.3102796 },
    { lat: 48.121688, lng: 17.308833 },
    { lat: 48.12239, lng: 17.30651 },
    { lat: 48.1231805, lng: 17.3043633 },
    { lat: 48.121434, lng: 17.303309 },
    { lat: 48.120743, lng: 17.30337 },
    { lat: 48.120495, lng: 17.303488 },
    { lat: 48.120175, lng: 17.303723 },
    { lat: 48.1198, lng: 17.304111 },
    { lat: 48.119492, lng: 17.304567 },
    { lat: 48.118759, lng: 17.306549 },
    { lat: 48.118502, lng: 17.307114 },
    { lat: 48.116171, lng: 17.311851 },
    { lat: 48.115333, lng: 17.313432 },
    { lat: 48.099408, lng: 17.307123 },
    { lat: 48.097463, lng: 17.312482 },
    { lat: 48.098064, lng: 17.314628 },
    { lat: 48.098624, lng: 17.315269 },
    { lat: 48.097554, lng: 17.316259 },
    { lat: 48.093704, lng: 17.320492 },
    { lat: 48.090314, lng: 17.324541 },
    { lat: 48.08884, lng: 17.326551 },
    { lat: 48.096056, lng: 17.343434 },
    { lat: 48.091478, lng: 17.348268 },
    { lat: 48.092794, lng: 17.351338 },
    { lat: 48.092173, lng: 17.353677 },
    { lat: 48.085072, lng: 17.34971 },
    { lat: 48.0858156, lng: 17.3551258 },
    { lat: 48.0861824, lng: 17.3551025 },
    { lat: 48.0866944, lng: 17.3553656 },
    { lat: 48.0867593, lng: 17.3552558 },
    { lat: 48.0855953, lng: 17.370574 },
    { lat: 48.0851866, lng: 17.3723509 },
    { lat: 48.0845942, lng: 17.3740126 },
    { lat: 48.0837586, lng: 17.3757412 },
    { lat: 48.0828951, lng: 17.3773187 },
    { lat: 48.0825568, lng: 17.3783617 },
    { lat: 48.0823603, lng: 17.3787941 },
    { lat: 48.0817154, lng: 17.3795357 },
    { lat: 48.0810903, lng: 17.3808225 },
    { lat: 48.0804522, lng: 17.3823402 },
    { lat: 48.080807, lng: 17.3826872 },
    { lat: 48.082776, lng: 17.3847766 },
    { lat: 48.083167, lng: 17.3843126 },
    { lat: 48.0847738, lng: 17.3823421 },
    { lat: 48.0860799, lng: 17.3810699 },
    { lat: 48.0874435, lng: 17.3822459 },
    { lat: 48.0883537, lng: 17.3829348 },
    { lat: 48.0898838, lng: 17.3839128 },
    { lat: 48.0906848, lng: 17.3842934 },
    { lat: 48.0936532, lng: 17.3861424 },
    { lat: 48.0945622, lng: 17.3864989 },
    { lat: 48.095752, lng: 17.386866 },
    { lat: 48.0977866, lng: 17.3871855 },
    { lat: 48.0983648, lng: 17.3870952 },
    { lat: 48.0992847, lng: 17.3866727 },
    { lat: 48.0996438, lng: 17.3863592 },
    { lat: 48.1002255, lng: 17.3856521 },
    { lat: 48.1005122, lng: 17.3852156 },
    { lat: 48.0997121, lng: 17.3842776 },
    { lat: 48.0992527, lng: 17.383558 },
    { lat: 48.0989467, lng: 17.3829038 },
    { lat: 48.098581, lng: 17.3818921 },
    { lat: 48.098394, lng: 17.3812778 },
    { lat: 48.0982743, lng: 17.380555 },
    { lat: 48.0981865, lng: 17.3781778 },
    { lat: 48.0983294, lng: 17.3775153 },
    { lat: 48.0985248, lng: 17.3769977 },
    { lat: 48.0991015, lng: 17.3760241 },
    { lat: 48.0999926, lng: 17.3750085 },
    { lat: 48.1004146, lng: 17.3742235 },
    { lat: 48.100614, lng: 17.3735975 },
    { lat: 48.1007584, lng: 17.37268 },
    { lat: 48.1007521, lng: 17.3723413 },
    { lat: 48.1005878, lng: 17.3717134 },
    { lat: 48.100845, lng: 17.3700545 },
    { lat: 48.1008501, lng: 17.3693389 },
    { lat: 48.1009245, lng: 17.3691305 },
    { lat: 48.1013163, lng: 17.3684923 },
    { lat: 48.1014571, lng: 17.3680883 },
    { lat: 48.101578, lng: 17.3674194 },
    { lat: 48.1016257, lng: 17.3666141 },
    { lat: 48.1021192, lng: 17.3644238 },
    { lat: 48.1022008, lng: 17.363568 },
    { lat: 48.1024003, lng: 17.3623406 },
    { lat: 48.1024244, lng: 17.3618649 },
    { lat: 48.1023919, lng: 17.3611099 },
    { lat: 48.102026, lng: 17.3595817 },
    { lat: 48.1019414, lng: 17.3590326 },
    { lat: 48.1021332, lng: 17.3579278 },
    { lat: 48.1022125, lng: 17.3577441 },
    { lat: 48.1025606, lng: 17.3573313 },
    { lat: 48.1034542, lng: 17.356853 },
    { lat: 48.1038888, lng: 17.3564717 },
    { lat: 48.1040501, lng: 17.3562279 },
    { lat: 48.1042036, lng: 17.35588 },
    { lat: 48.1045864, lng: 17.3546248 },
    { lat: 48.1046725, lng: 17.3541305 },
    { lat: 48.1046797, lng: 17.3536379 },
    { lat: 48.1053065, lng: 17.3534803 },
    { lat: 48.1056304, lng: 17.3532003 },
    { lat: 48.11012, lng: 17.3486437 },
    { lat: 48.1138958, lng: 17.3449966 },
    { lat: 48.1149188, lng: 17.3440788 },
    { lat: 48.1178342, lng: 17.3411242 },
    { lat: 48.1181318, lng: 17.3408773 },
    { lat: 48.118445, lng: 17.340467 },
    { lat: 48.1207147, lng: 17.3342012 },
    { lat: 48.12073, lng: 17.334159 },
    { lat: 48.119164, lng: 17.333081 },
    { lat: 48.119725, lng: 17.330618 },
    { lat: 48.118627, lng: 17.330188 },
    { lat: 48.118664, lng: 17.329942 },
    { lat: 48.118236, lng: 17.329764 },
    { lat: 48.118361, lng: 17.327411 },
    { lat: 48.118562, lng: 17.326091 },
    { lat: 48.119281, lng: 17.322825 },
    { lat: 48.119446, lng: 17.321911 },
    { lat: 48.119508, lng: 17.321474 },
    { lat: 48.119315, lng: 17.321298 },
    { lat: 48.119766, lng: 17.320361 },
    { lat: 48.11996, lng: 17.319769 },
    { lat: 48.120011, lng: 17.319227 },
    { lat: 48.119825, lng: 17.317894 },
    { lat: 48.11993, lng: 17.317413 },
    { lat: 48.120129, lng: 17.317159 },
    { lat: 48.120433, lng: 17.31694 },
    { lat: 48.121401, lng: 17.316432 },
    { lat: 48.121629, lng: 17.316164 },
    { lat: 48.121754, lng: 17.31587 },
    { lat: 48.121762, lng: 17.315358 },
    { lat: 48.121474, lng: 17.314637 },
    { lat: 48.121145, lng: 17.313181 },
    { lat: 48.121032, lng: 17.312318 },
    { lat: 48.12104, lng: 17.311846 },
    { lat: 48.121166, lng: 17.310926 },
    { lat: 48.1213272, lng: 17.3102796 },
  ],
  Blahová: [
    { lat: 48.1021229, lng: 17.5356327 },
    { lat: 48.1018251, lng: 17.5354799 },
    { lat: 48.0950223, lng: 17.5321513 },
    { lat: 48.0938535, lng: 17.5294256 },
    { lat: 48.0930344, lng: 17.5269023 },
    { lat: 48.090803, lng: 17.5295078 },
    { lat: 48.0877967, lng: 17.522753 },
    { lat: 48.085981, lng: 17.5253959 },
    { lat: 48.0832239, lng: 17.5192342 },
    { lat: 48.080154, lng: 17.5236745 },
    { lat: 48.0800925, lng: 17.5234725 },
    { lat: 48.0771271, lng: 17.521631 },
    { lat: 48.0753141, lng: 17.5205895 },
    { lat: 48.0752515, lng: 17.5207559 },
    { lat: 48.0748513, lng: 17.5218051 },
    { lat: 48.0737015, lng: 17.5241931 },
    { lat: 48.0761044, lng: 17.5364153 },
    { lat: 48.0770924, lng: 17.5390028 },
    { lat: 48.0786614, lng: 17.5437316 },
    { lat: 48.0791948, lng: 17.5462062 },
    { lat: 48.080864, lng: 17.5489964 },
    { lat: 48.083189, lng: 17.55259 },
    { lat: 48.0847947, lng: 17.5559251 },
    { lat: 48.0849762, lng: 17.5563107 },
    { lat: 48.0865878, lng: 17.5596878 },
    { lat: 48.0873433, lng: 17.5609422 },
    { lat: 48.0875643, lng: 17.5617492 },
    { lat: 48.0875804, lng: 17.5619687 },
    { lat: 48.08723, lng: 17.5630767 },
    { lat: 48.0867209, lng: 17.5643351 },
    { lat: 48.0873163, lng: 17.5653474 },
    { lat: 48.0868067, lng: 17.5656777 },
    { lat: 48.0866185, lng: 17.5658775 },
    { lat: 48.0858566, lng: 17.5671909 },
    { lat: 48.0884895, lng: 17.5731849 },
    { lat: 48.0893219, lng: 17.5748863 },
    { lat: 48.0901901, lng: 17.5764046 },
    { lat: 48.0913863, lng: 17.5779714 },
    { lat: 48.0939562, lng: 17.5822538 },
    { lat: 48.0945678, lng: 17.5853849 },
    { lat: 48.0947695, lng: 17.5859295 },
    { lat: 48.0962816, lng: 17.5893028 },
    { lat: 48.097136, lng: 17.590793 },
    { lat: 48.09721, lng: 17.590771 },
    { lat: 48.097527, lng: 17.590602 },
    { lat: 48.097934, lng: 17.590315 },
    { lat: 48.098279, lng: 17.590042 },
    { lat: 48.098568, lng: 17.58973 },
    { lat: 48.098772, lng: 17.589451 },
    { lat: 48.099035, lng: 17.589124 },
    { lat: 48.099216, lng: 17.5889 },
    { lat: 48.09939, lng: 17.5887 },
    { lat: 48.099772, lng: 17.588304 },
    { lat: 48.099899, lng: 17.588212 },
    { lat: 48.100092, lng: 17.588036 },
    { lat: 48.100278, lng: 17.587908 },
    { lat: 48.100431, lng: 17.587802 },
    { lat: 48.100568, lng: 17.587653 },
    { lat: 48.100685, lng: 17.58759 },
    { lat: 48.100974, lng: 17.58746 },
    { lat: 48.101206, lng: 17.587351 },
    { lat: 48.101343, lng: 17.587293 },
    { lat: 48.101616, lng: 17.587206 },
    { lat: 48.101834, lng: 17.587074 },
    { lat: 48.102149, lng: 17.586988 },
    { lat: 48.102238, lng: 17.586966 },
    { lat: 48.102355, lng: 17.586983 },
    { lat: 48.10263, lng: 17.587037 },
    { lat: 48.102808, lng: 17.587045 },
    { lat: 48.102903, lng: 17.58704 },
    { lat: 48.103218, lng: 17.587 },
    { lat: 48.103552, lng: 17.586956 },
    { lat: 48.103879, lng: 17.586865 },
    { lat: 48.104023, lng: 17.586859 },
    { lat: 48.104252, lng: 17.586755 },
    { lat: 48.10465, lng: 17.586566 },
    { lat: 48.105098, lng: 17.586288 },
    { lat: 48.105497, lng: 17.585932 },
    { lat: 48.105691, lng: 17.585759 },
    { lat: 48.105761, lng: 17.585627 },
    { lat: 48.106079, lng: 17.585252 },
    { lat: 48.106271, lng: 17.584959 },
    { lat: 48.106455, lng: 17.584597 },
    { lat: 48.10658, lng: 17.584368 },
    { lat: 48.106805, lng: 17.583951 },
    { lat: 48.106976, lng: 17.583513 },
    { lat: 48.107017, lng: 17.583371 },
    { lat: 48.107163, lng: 17.582772 },
    { lat: 48.107282, lng: 17.582392 },
    { lat: 48.107538, lng: 17.581724 },
    { lat: 48.107734, lng: 17.581206 },
    { lat: 48.107909, lng: 17.580672 },
    { lat: 48.10796, lng: 17.580503 },
    { lat: 48.108007, lng: 17.580075 },
    { lat: 48.1080052, lng: 17.5798211 },
    { lat: 48.108003, lng: 17.57952 },
    { lat: 48.107988, lng: 17.579113 },
    { lat: 48.107914, lng: 17.578498 },
    { lat: 48.107751, lng: 17.577863 },
    { lat: 48.107577, lng: 17.577213 },
    { lat: 48.107318, lng: 17.57669 },
    { lat: 48.107142, lng: 17.576357 },
    { lat: 48.106864, lng: 17.575942 },
    { lat: 48.106743, lng: 17.575761 },
    { lat: 48.106425, lng: 17.575421 },
    { lat: 48.106319, lng: 17.575331 },
    { lat: 48.105871, lng: 17.574866 },
    { lat: 48.105437, lng: 17.574571 },
    { lat: 48.105174, lng: 17.574451 },
    { lat: 48.104743, lng: 17.574306 },
    { lat: 48.104443, lng: 17.574207 },
    { lat: 48.10426, lng: 17.574149 },
    { lat: 48.10401, lng: 17.574112 },
    { lat: 48.10381, lng: 17.574081 },
    { lat: 48.103749, lng: 17.574048 },
    { lat: 48.103609, lng: 17.573975 },
    { lat: 48.10338, lng: 17.573882 },
    { lat: 48.103145, lng: 17.573695 },
    { lat: 48.102987, lng: 17.573518 },
    { lat: 48.102702, lng: 17.573156 },
    { lat: 48.10255, lng: 17.572935 },
    { lat: 48.102487, lng: 17.572804 },
    { lat: 48.102364, lng: 17.572542 },
    { lat: 48.102229, lng: 17.572068 },
    { lat: 48.102157, lng: 17.571764 },
    { lat: 48.102132, lng: 17.571662 },
    { lat: 48.10206, lng: 17.571115 },
    { lat: 48.102042, lng: 17.570691 },
    { lat: 48.102058, lng: 17.570507 },
    { lat: 48.102085, lng: 17.570309 },
    { lat: 48.10219, lng: 17.569662 },
    { lat: 48.10225, lng: 17.569484 },
    { lat: 48.102443, lng: 17.568979 },
    { lat: 48.102566, lng: 17.568728 },
    { lat: 48.102729, lng: 17.568412 },
    { lat: 48.102814, lng: 17.568236 },
    { lat: 48.102947, lng: 17.567927 },
    { lat: 48.103242, lng: 17.567328 },
    { lat: 48.10349, lng: 17.566959 },
    { lat: 48.103795, lng: 17.56664 },
    { lat: 48.104259, lng: 17.566422 },
    { lat: 48.104672, lng: 17.566284 },
    { lat: 48.104979, lng: 17.566367 },
    { lat: 48.105266, lng: 17.566436 },
    { lat: 48.105487, lng: 17.566471 },
    { lat: 48.105645, lng: 17.566553 },
    { lat: 48.105956, lng: 17.566726 },
    { lat: 48.106124, lng: 17.566837 },
    { lat: 48.106453, lng: 17.566925 },
    { lat: 48.106811, lng: 17.56721 },
    { lat: 48.10698, lng: 17.567355 },
    { lat: 48.107127, lng: 17.567539 },
    { lat: 48.107352, lng: 17.56816 },
    { lat: 48.107491, lng: 17.568568 },
    { lat: 48.107643, lng: 17.568801 },
    { lat: 48.107967, lng: 17.569376 },
    { lat: 48.108195, lng: 17.569703 },
    { lat: 48.108344, lng: 17.569968 },
    { lat: 48.108571, lng: 17.570326 },
    { lat: 48.108763, lng: 17.570719 },
    { lat: 48.108918, lng: 17.570912 },
    { lat: 48.109131, lng: 17.571207 },
    { lat: 48.109455, lng: 17.571603 },
    { lat: 48.109926, lng: 17.571975 },
    { lat: 48.110131, lng: 17.572125 },
    { lat: 48.110428, lng: 17.572347 },
    { lat: 48.110793, lng: 17.572504 },
    { lat: 48.110841, lng: 17.57251 },
    { lat: 48.110947, lng: 17.572519 },
    { lat: 48.111185, lng: 17.572509 },
    { lat: 48.111689, lng: 17.572388 },
    { lat: 48.112185, lng: 17.572092 },
    { lat: 48.112366, lng: 17.571953 },
    { lat: 48.112587, lng: 17.57171 },
    { lat: 48.112691, lng: 17.571595 },
    { lat: 48.112983, lng: 17.571301 },
    { lat: 48.113277, lng: 17.570831 },
    { lat: 48.113588, lng: 17.570181 },
    { lat: 48.113812, lng: 17.569748 },
    { lat: 48.113888, lng: 17.569514 },
    { lat: 48.11406, lng: 17.568994 },
    { lat: 48.114121, lng: 17.568647 },
    { lat: 48.114142, lng: 17.568288 },
    { lat: 48.114135, lng: 17.567976 },
    { lat: 48.114052, lng: 17.567484 },
    { lat: 48.113894, lng: 17.566867 },
    { lat: 48.113732, lng: 17.566431 },
    { lat: 48.113485, lng: 17.566045 },
    { lat: 48.113261, lng: 17.565641 },
    { lat: 48.112737, lng: 17.565321 },
    { lat: 48.1121, lng: 17.56496 },
    { lat: 48.112035, lng: 17.564929 },
    { lat: 48.111827, lng: 17.564842 },
    { lat: 48.111572, lng: 17.564726 },
    { lat: 48.111192, lng: 17.564574 },
    { lat: 48.110827, lng: 17.564346 },
    { lat: 48.110439, lng: 17.563919 },
    { lat: 48.110007, lng: 17.563183 },
    { lat: 48.109856, lng: 17.562862 },
    { lat: 48.109595, lng: 17.562478 },
    { lat: 48.109352, lng: 17.56198 },
    { lat: 48.10915, lng: 17.561496 },
    { lat: 48.109003, lng: 17.561109 },
    { lat: 48.108457, lng: 17.559808 },
    { lat: 48.108215, lng: 17.559121 },
    { lat: 48.108133, lng: 17.558522 },
    { lat: 48.108017, lng: 17.557966 },
    { lat: 48.107967, lng: 17.557616 },
    { lat: 48.107923, lng: 17.557139 },
    { lat: 48.107795, lng: 17.555813 },
    { lat: 48.107669, lng: 17.554845 },
    { lat: 48.107591, lng: 17.554292 },
    { lat: 48.107479, lng: 17.553359 },
    { lat: 48.107365, lng: 17.552209 },
    { lat: 48.10728, lng: 17.551202 },
    { lat: 48.107249, lng: 17.550861 },
    { lat: 48.107208, lng: 17.55006 },
    { lat: 48.107085, lng: 17.549218 },
    { lat: 48.106878, lng: 17.54838 },
    { lat: 48.106652, lng: 17.547452 },
    { lat: 48.106371, lng: 17.546855 },
    { lat: 48.106151, lng: 17.546324 },
    { lat: 48.106025, lng: 17.54589 },
    { lat: 48.105892, lng: 17.545411 },
    { lat: 48.105869, lng: 17.544936 },
    { lat: 48.105772, lng: 17.544096 },
    { lat: 48.105725, lng: 17.543732 },
    { lat: 48.10556, lng: 17.543291 },
    { lat: 48.105561, lng: 17.542743 },
    { lat: 48.105649, lng: 17.541828 },
    { lat: 48.105759, lng: 17.541304 },
    { lat: 48.105875, lng: 17.540961 },
    { lat: 48.106154, lng: 17.539969 },
    { lat: 48.106361, lng: 17.539228 },
    { lat: 48.106818, lng: 17.538237 },
    { lat: 48.106696, lng: 17.538052 },
    { lat: 48.1064838, lng: 17.5377928 },
    { lat: 48.1021229, lng: 17.5356327 },
  ],
  Jahodná: [
    { lat: 48.0284492, lng: 17.7101273 },
    { lat: 48.0284832, lng: 17.7101832 },
    { lat: 48.029495, lng: 17.7119904 },
    { lat: 48.0307501, lng: 17.7139671 },
    { lat: 48.0313699, lng: 17.7152653 },
    { lat: 48.0315242, lng: 17.7160333 },
    { lat: 48.0315266, lng: 17.7167747 },
    { lat: 48.0317757, lng: 17.7171835 },
    { lat: 48.032974, lng: 17.7185258 },
    { lat: 48.0333217, lng: 17.7195717 },
    { lat: 48.0339635, lng: 17.7205919 },
    { lat: 48.0346778, lng: 17.7214697 },
    { lat: 48.034486, lng: 17.7219585 },
    { lat: 48.0343652, lng: 17.7224458 },
    { lat: 48.0342804, lng: 17.7230407 },
    { lat: 48.0342217, lng: 17.7237981 },
    { lat: 48.0344111, lng: 17.7243376 },
    { lat: 48.0354102, lng: 17.72551 },
    { lat: 48.037634, lng: 17.7255764 },
    { lat: 48.0379054, lng: 17.7256717 },
    { lat: 48.0381738, lng: 17.7255409 },
    { lat: 48.0384815, lng: 17.7255503 },
    { lat: 48.0388921, lng: 17.7252576 },
    { lat: 48.0392859, lng: 17.7253613 },
    { lat: 48.0400105, lng: 17.7257531 },
    { lat: 48.0403277, lng: 17.7257912 },
    { lat: 48.0411359, lng: 17.7255142 },
    { lat: 48.0409432, lng: 17.7263147 },
    { lat: 48.0409392, lng: 17.7264867 },
    { lat: 48.0407277, lng: 17.7268427 },
    { lat: 48.0400042, lng: 17.7284701 },
    { lat: 48.0396934, lng: 17.7288661 },
    { lat: 48.0394929, lng: 17.728852 },
    { lat: 48.0394477, lng: 17.7291443 },
    { lat: 48.0392516, lng: 17.7293307 },
    { lat: 48.0392915, lng: 17.7296426 },
    { lat: 48.0392522, lng: 17.7299907 },
    { lat: 48.0391657, lng: 17.7302323 },
    { lat: 48.0390532, lng: 17.7303464 },
    { lat: 48.0389526, lng: 17.7307787 },
    { lat: 48.0383975, lng: 17.7319064 },
    { lat: 48.0377446, lng: 17.7334381 },
    { lat: 48.0365371, lng: 17.7330864 },
    { lat: 48.0355147, lng: 17.7326611 },
    { lat: 48.0340953, lng: 17.7311014 },
    { lat: 48.0334937, lng: 17.7306699 },
    { lat: 48.0331644, lng: 17.7306027 },
    { lat: 48.0328561, lng: 17.7307118 },
    { lat: 48.0324638, lng: 17.7311166 },
    { lat: 48.0322609, lng: 17.7315588 },
    { lat: 48.0320111, lng: 17.7325286 },
    { lat: 48.0319208, lng: 17.7336512 },
    { lat: 48.0320468, lng: 17.7351092 },
    { lat: 48.0322933, lng: 17.7359124 },
    { lat: 48.0326292, lng: 17.736609 },
    { lat: 48.0326816, lng: 17.7369418 },
    { lat: 48.0329155, lng: 17.737649 },
    { lat: 48.0335995, lng: 17.738624 },
    { lat: 48.0338119, lng: 17.7388083 },
    { lat: 48.034297, lng: 17.7390381 },
    { lat: 48.035006, lng: 17.7392152 },
    { lat: 48.0355448, lng: 17.7392407 },
    { lat: 48.0362505, lng: 17.7391326 },
    { lat: 48.0371357, lng: 17.738897 },
    { lat: 48.0375739, lng: 17.7388824 },
    { lat: 48.0384746, lng: 17.7389124 },
    { lat: 48.0391844, lng: 17.73916 },
    { lat: 48.0397257, lng: 17.7396558 },
    { lat: 48.0403766, lng: 17.7404807 },
    { lat: 48.0407947, lng: 17.7416278 },
    { lat: 48.0409447, lng: 17.7426753 },
    { lat: 48.0409434, lng: 17.7436714 },
    { lat: 48.0408951, lng: 17.7442563 },
    { lat: 48.0406422, lng: 17.7456863 },
    { lat: 48.0401517, lng: 17.7471826 },
    { lat: 48.0390262, lng: 17.7499007 },
    { lat: 48.0386193, lng: 17.7512013 },
    { lat: 48.0382263, lng: 17.7529592 },
    { lat: 48.037994, lng: 17.7536521 },
    { lat: 48.037553, lng: 17.7544388 },
    { lat: 48.0370613, lng: 17.7549918 },
    { lat: 48.0368476, lng: 17.7551703 },
    { lat: 48.0363664, lng: 17.7553261 },
    { lat: 48.0357392, lng: 17.755428 },
    { lat: 48.0348319, lng: 17.7552537 },
    { lat: 48.0343376, lng: 17.7550395 },
    { lat: 48.0336592, lng: 17.7543877 },
    { lat: 48.0326686, lng: 17.75311 },
    { lat: 48.0319911, lng: 17.752347 },
    { lat: 48.0314242, lng: 17.7518706 },
    { lat: 48.0310149, lng: 17.75168 },
    { lat: 48.0305697, lng: 17.7517314 },
    { lat: 48.0302038, lng: 17.7519266 },
    { lat: 48.0299051, lng: 17.7522805 },
    { lat: 48.0295796, lng: 17.7528168 },
    { lat: 48.0293883, lng: 17.7534267 },
    { lat: 48.0293241, lng: 17.7542281 },
    { lat: 48.0294927, lng: 17.7552484 },
    { lat: 48.0296873, lng: 17.7559999 },
    { lat: 48.0303342, lng: 17.7573975 },
    { lat: 48.031121, lng: 17.7583923 },
    { lat: 48.0323801, lng: 17.7601765 },
    { lat: 48.0327212, lng: 17.7608331 },
    { lat: 48.0332108, lng: 17.7622627 },
    { lat: 48.033444, lng: 17.762766 },
    { lat: 48.033721, lng: 17.762622 },
    { lat: 48.033788, lng: 17.762583 },
    { lat: 48.033826, lng: 17.762517 },
    { lat: 48.033999, lng: 17.762204 },
    { lat: 48.034168, lng: 17.761926 },
    { lat: 48.034419, lng: 17.761511 },
    { lat: 48.034447, lng: 17.761485 },
    { lat: 48.034676, lng: 17.761279 },
    { lat: 48.035164, lng: 17.760932 },
    { lat: 48.03528, lng: 17.760985 },
    { lat: 48.035545, lng: 17.761105 },
    { lat: 48.03586, lng: 17.761421 },
    { lat: 48.036486, lng: 17.762302 },
    { lat: 48.036733, lng: 17.762599 },
    { lat: 48.037099, lng: 17.763042 },
    { lat: 48.037427, lng: 17.76358 },
    { lat: 48.03745, lng: 17.76362 },
    { lat: 48.037767, lng: 17.764143 },
    { lat: 48.038118, lng: 17.764851 },
    { lat: 48.038347, lng: 17.765311 },
    { lat: 48.03859, lng: 17.765794 },
    { lat: 48.038816, lng: 17.766235 },
    { lat: 48.03904, lng: 17.766675 },
    { lat: 48.039169, lng: 17.766772 },
    { lat: 48.039292, lng: 17.766831 },
    { lat: 48.03939, lng: 17.766902 },
    { lat: 48.039496, lng: 17.766982 },
    { lat: 48.039598, lng: 17.767073 },
    { lat: 48.039668, lng: 17.76717 },
    { lat: 48.039873, lng: 17.767488 },
    { lat: 48.039926, lng: 17.767569 },
    { lat: 48.040184, lng: 17.767963 },
    { lat: 48.040649, lng: 17.768634 },
    { lat: 48.040703, lng: 17.768704 },
    { lat: 48.040793, lng: 17.768817 },
    { lat: 48.040803, lng: 17.768788 },
    { lat: 48.040935, lng: 17.768529 },
    { lat: 48.041015, lng: 17.768439 },
    { lat: 48.041161, lng: 17.76827 },
    { lat: 48.041268, lng: 17.768193 },
    { lat: 48.041375, lng: 17.768111 },
    { lat: 48.041565, lng: 17.768067 },
    { lat: 48.04163, lng: 17.76804 },
    { lat: 48.041856, lng: 17.767941 },
    { lat: 48.042143, lng: 17.767661 },
    { lat: 48.042516, lng: 17.767345 },
    { lat: 48.042718, lng: 17.767224 },
    { lat: 48.04284, lng: 17.767106 },
    { lat: 48.042907, lng: 17.767007 },
    { lat: 48.042973, lng: 17.766899 },
    { lat: 48.0432, lng: 17.766716 },
    { lat: 48.04328, lng: 17.766631 },
    { lat: 48.043417, lng: 17.766315 },
    { lat: 48.043518, lng: 17.766012 },
    { lat: 48.043574, lng: 17.765768 },
    { lat: 48.043614, lng: 17.765511 },
    { lat: 48.043686, lng: 17.765358 },
    { lat: 48.043677, lng: 17.765141 },
    { lat: 48.043718, lng: 17.764884 },
    { lat: 48.043715, lng: 17.76459 },
    { lat: 48.043672, lng: 17.764413 },
    { lat: 48.043611, lng: 17.764251 },
    { lat: 48.04353, lng: 17.764132 },
    { lat: 48.043486, lng: 17.764 },
    { lat: 48.04348, lng: 17.76386 },
    { lat: 48.043494, lng: 17.763681 },
    { lat: 48.043565, lng: 17.763509 },
    { lat: 48.043712, lng: 17.763215 },
    { lat: 48.043796, lng: 17.762929 },
    { lat: 48.043925, lng: 17.76266 },
    { lat: 48.044227, lng: 17.762152 },
    { lat: 48.044344, lng: 17.761941 },
    { lat: 48.044425, lng: 17.761795 },
    { lat: 48.044486, lng: 17.761714 },
    { lat: 48.044601, lng: 17.761592 },
    { lat: 48.04479, lng: 17.761406 },
    { lat: 48.044832, lng: 17.761333 },
    { lat: 48.044876, lng: 17.761286 },
    { lat: 48.044959, lng: 17.761235 },
    { lat: 48.045035, lng: 17.761193 },
    { lat: 48.045233, lng: 17.761232 },
    { lat: 48.045296, lng: 17.761264 },
    { lat: 48.045505, lng: 17.761454 },
    { lat: 48.045674, lng: 17.761666 },
    { lat: 48.04581, lng: 17.761838 },
    { lat: 48.045916, lng: 17.761933 },
    { lat: 48.046143, lng: 17.762028 },
    { lat: 48.04633, lng: 17.762129 },
    { lat: 48.046665, lng: 17.762169 },
    { lat: 48.04686, lng: 17.762087 },
    { lat: 48.047001, lng: 17.76203 },
    { lat: 48.047162, lng: 17.762029 },
    { lat: 48.047284, lng: 17.762021 },
    { lat: 48.047406, lng: 17.762019 },
    { lat: 48.047545, lng: 17.762024 },
    { lat: 48.047679, lng: 17.76203 },
    { lat: 48.04789, lng: 17.762007 },
    { lat: 48.048209, lng: 17.761929 },
    { lat: 48.048381, lng: 17.761841 },
    { lat: 48.048616, lng: 17.761716 },
    { lat: 48.048838, lng: 17.761357 },
    { lat: 48.049085, lng: 17.761057 },
    { lat: 48.049194, lng: 17.761011 },
    { lat: 48.049354, lng: 17.761007 },
    { lat: 48.049654, lng: 17.760965 },
    { lat: 48.049857, lng: 17.760931 },
    { lat: 48.049957, lng: 17.760915 },
    { lat: 48.050276, lng: 17.760837 },
    { lat: 48.05035, lng: 17.760821 },
    { lat: 48.050566, lng: 17.760718 },
    { lat: 48.05071, lng: 17.760647 },
    { lat: 48.050864, lng: 17.760527 },
    { lat: 48.051061, lng: 17.760228 },
    { lat: 48.0512, lng: 17.759867 },
    { lat: 48.051233, lng: 17.759761 },
    { lat: 48.051416, lng: 17.75953 },
    { lat: 48.051533, lng: 17.75946 },
    { lat: 48.051727, lng: 17.759446 },
    { lat: 48.051912, lng: 17.759414 },
    { lat: 48.051986, lng: 17.759379 },
    { lat: 48.052145, lng: 17.759441 },
    { lat: 48.052318, lng: 17.759483 },
    { lat: 48.052351, lng: 17.759489 },
    { lat: 48.052409, lng: 17.759498 },
    { lat: 48.05252, lng: 17.759513 },
    { lat: 48.052627, lng: 17.759503 },
    { lat: 48.052726, lng: 17.75938 },
    { lat: 48.052754, lng: 17.759372 },
    { lat: 48.052882, lng: 17.759213 },
    { lat: 48.05308, lng: 17.758878 },
    { lat: 48.053322, lng: 17.758529 },
    { lat: 48.053433, lng: 17.758399 },
    { lat: 48.053574, lng: 17.758134 },
    { lat: 48.053712, lng: 17.757874 },
    { lat: 48.053765, lng: 17.757733 },
    { lat: 48.05382, lng: 17.757475 },
    { lat: 48.05389, lng: 17.757313 },
    { lat: 48.053863, lng: 17.75726 },
    { lat: 48.053946, lng: 17.756892 },
    { lat: 48.053985, lng: 17.756723 },
    { lat: 48.054129, lng: 17.756447 },
    { lat: 48.05426, lng: 17.756162 },
    { lat: 48.05434, lng: 17.755827 },
    { lat: 48.05435, lng: 17.755631 },
    { lat: 48.054299, lng: 17.755425 },
    { lat: 48.05428, lng: 17.755354 },
    { lat: 48.054237, lng: 17.755184 },
    { lat: 48.054184, lng: 17.755013 },
    { lat: 48.053952, lng: 17.754612 },
    { lat: 48.053781, lng: 17.753908 },
    { lat: 48.053744, lng: 17.753643 },
    { lat: 48.053731, lng: 17.753396 },
    { lat: 48.053722, lng: 17.753196 },
    { lat: 48.05371, lng: 17.753034 },
    { lat: 48.053685, lng: 17.752722 },
    { lat: 48.053685, lng: 17.752566 },
    { lat: 48.053684, lng: 17.752503 },
    { lat: 48.053693, lng: 17.752357 },
    { lat: 48.053703, lng: 17.752018 },
    { lat: 48.05373, lng: 17.751682 },
    { lat: 48.053747, lng: 17.751581 },
    { lat: 48.053777, lng: 17.751397 },
    { lat: 48.053842, lng: 17.75115 },
    { lat: 48.053883, lng: 17.751054 },
    { lat: 48.053923, lng: 17.750976 },
    { lat: 48.053994, lng: 17.750881 },
    { lat: 48.054111, lng: 17.750771 },
    { lat: 48.054206, lng: 17.750676 },
    { lat: 48.054365, lng: 17.750525 },
    { lat: 48.054526, lng: 17.750409 },
    { lat: 48.054628, lng: 17.750348 },
    { lat: 48.054767, lng: 17.750298 },
    { lat: 48.054833, lng: 17.750194 },
    { lat: 48.054895, lng: 17.750036 },
    { lat: 48.054762, lng: 17.749658 },
    { lat: 48.054588, lng: 17.749429 },
    { lat: 48.054403, lng: 17.749248 },
    { lat: 48.054242, lng: 17.749067 },
    { lat: 48.054063, lng: 17.748808 },
    { lat: 48.053985, lng: 17.748602 },
    { lat: 48.053794, lng: 17.748371 },
    { lat: 48.053658, lng: 17.748243 },
    { lat: 48.053092, lng: 17.747703 },
    { lat: 48.053005, lng: 17.747427 },
    { lat: 48.053034, lng: 17.74735 },
    { lat: 48.053115, lng: 17.747136 },
    { lat: 48.05315, lng: 17.747061 },
    { lat: 48.053159, lng: 17.747042 },
    { lat: 48.053233, lng: 17.746987 },
    { lat: 48.053341, lng: 17.74687 },
    { lat: 48.053407, lng: 17.74669 },
    { lat: 48.05347, lng: 17.7465 },
    { lat: 48.053498, lng: 17.746362 },
    { lat: 48.053548, lng: 17.746146 },
    { lat: 48.053624, lng: 17.745828 },
    { lat: 48.053695, lng: 17.745612 },
    { lat: 48.053813, lng: 17.745372 },
    { lat: 48.053932, lng: 17.745271 },
    { lat: 48.053977, lng: 17.745224 },
    { lat: 48.054062, lng: 17.745181 },
    { lat: 48.054209, lng: 17.745117 },
    { lat: 48.054339, lng: 17.745065 },
    { lat: 48.054508, lng: 17.744976 },
    { lat: 48.054727, lng: 17.744833 },
    { lat: 48.054887, lng: 17.74472 },
    { lat: 48.055083, lng: 17.744616 },
    { lat: 48.055267, lng: 17.744531 },
    { lat: 48.055378, lng: 17.744489 },
    { lat: 48.05566, lng: 17.744318 },
    { lat: 48.055731, lng: 17.744249 },
    { lat: 48.055782, lng: 17.744157 },
    { lat: 48.055837, lng: 17.743888 },
    { lat: 48.055789, lng: 17.743427 },
    { lat: 48.055744, lng: 17.743275 },
    { lat: 48.055587, lng: 17.742947 },
    { lat: 48.055543, lng: 17.742773 },
    { lat: 48.055538, lng: 17.742684 },
    { lat: 48.055561, lng: 17.742351 },
    { lat: 48.055609, lng: 17.742072 },
    { lat: 48.055653, lng: 17.741948 },
    { lat: 48.055681, lng: 17.741812 },
    { lat: 48.055732, lng: 17.741562 },
    { lat: 48.055766, lng: 17.741209 },
    { lat: 48.055785, lng: 17.740906 },
    { lat: 48.055827, lng: 17.740623 },
    { lat: 48.055874, lng: 17.740352 },
    { lat: 48.055904, lng: 17.740088 },
    { lat: 48.055909, lng: 17.739745 },
    { lat: 48.055904, lng: 17.739484 },
    { lat: 48.055909, lng: 17.739403 },
    { lat: 48.055928, lng: 17.739269 },
    { lat: 48.055953, lng: 17.739115 },
    { lat: 48.056006, lng: 17.738874 },
    { lat: 48.056065, lng: 17.738644 },
    { lat: 48.056103, lng: 17.738532 },
    { lat: 48.056159, lng: 17.7384 },
    { lat: 48.056231, lng: 17.73821 },
    { lat: 48.05633, lng: 17.737984 },
    { lat: 48.056422, lng: 17.737759 },
    { lat: 48.056527, lng: 17.737246 },
    { lat: 48.056552, lng: 17.737106 },
    { lat: 48.05659, lng: 17.736893 },
    { lat: 48.056614, lng: 17.736703 },
    { lat: 48.056637, lng: 17.736542 },
    { lat: 48.056724, lng: 17.736277 },
    { lat: 48.056759, lng: 17.736172 },
    { lat: 48.056819, lng: 17.735951 },
    { lat: 48.056898, lng: 17.735708 },
    { lat: 48.056968, lng: 17.735492 },
    { lat: 48.057061, lng: 17.735164 },
    { lat: 48.057103, lng: 17.734997 },
    { lat: 48.057156, lng: 17.734761 },
    { lat: 48.057189, lng: 17.734595 },
    { lat: 48.057236, lng: 17.73447 },
    { lat: 48.057344, lng: 17.734121 },
    { lat: 48.057381, lng: 17.733999 },
    { lat: 48.057467, lng: 17.733773 },
    { lat: 48.057545, lng: 17.733527 },
    { lat: 48.057734, lng: 17.73308 },
    { lat: 48.057832, lng: 17.732873 },
    { lat: 48.057929, lng: 17.732638 },
    { lat: 48.058087, lng: 17.732299 },
    { lat: 48.058189, lng: 17.73205 },
    { lat: 48.058216, lng: 17.731937 },
    { lat: 48.058285, lng: 17.731675 },
    { lat: 48.058326, lng: 17.731532 },
    { lat: 48.058361, lng: 17.731363 },
    { lat: 48.058379, lng: 17.731267 },
    { lat: 48.058422, lng: 17.731043 },
    { lat: 48.058466, lng: 17.730818 },
    { lat: 48.058512, lng: 17.730508 },
    { lat: 48.058538, lng: 17.730257 },
    { lat: 48.058547, lng: 17.73019 },
    { lat: 48.058597, lng: 17.729897 },
    { lat: 48.058624, lng: 17.729776 },
    { lat: 48.058721, lng: 17.72951 },
    { lat: 48.058782, lng: 17.729381 },
    { lat: 48.058902, lng: 17.729174 },
    { lat: 48.059009, lng: 17.728954 },
    { lat: 48.059114, lng: 17.728728 },
    { lat: 48.059157, lng: 17.728629 },
    { lat: 48.059179, lng: 17.728534 },
    { lat: 48.059209, lng: 17.728288 },
    { lat: 48.059205, lng: 17.728179 },
    { lat: 48.05921, lng: 17.728093 },
    { lat: 48.05919, lng: 17.727946 },
    { lat: 48.05914, lng: 17.727658 },
    { lat: 48.059098, lng: 17.72745 },
    { lat: 48.059029, lng: 17.727122 },
    { lat: 48.058987, lng: 17.726949 },
    { lat: 48.058944, lng: 17.726658 },
    { lat: 48.058933, lng: 17.726451 },
    { lat: 48.058955, lng: 17.726336 },
    { lat: 48.059001, lng: 17.726097 },
    { lat: 48.059047, lng: 17.72597 },
    { lat: 48.059165, lng: 17.725773 },
    { lat: 48.059254, lng: 17.725615 },
    { lat: 48.059573, lng: 17.725112 },
    { lat: 48.05966, lng: 17.724904 },
    { lat: 48.059744, lng: 17.724672 },
    { lat: 48.059803, lng: 17.7245 },
    { lat: 48.059598, lng: 17.723889 },
    { lat: 48.059523, lng: 17.723716 },
    { lat: 48.059439, lng: 17.723567 },
    { lat: 48.0593, lng: 17.723426 },
    { lat: 48.059268, lng: 17.723362 },
    { lat: 48.059238, lng: 17.723302 },
    { lat: 48.059217, lng: 17.723263 },
    { lat: 48.059186, lng: 17.723149 },
    { lat: 48.059201, lng: 17.723035 },
    { lat: 48.059209, lng: 17.7229 },
    { lat: 48.059224, lng: 17.722645 },
    { lat: 48.059228, lng: 17.722629 },
    { lat: 48.059297, lng: 17.722271 },
    { lat: 48.059363, lng: 17.722172 },
    { lat: 48.059466, lng: 17.721974 },
    { lat: 48.059535, lng: 17.721862 },
    { lat: 48.059563, lng: 17.721814 },
    { lat: 48.059694, lng: 17.721581 },
    { lat: 48.059763, lng: 17.721454 },
    { lat: 48.05986, lng: 17.721281 },
    { lat: 48.059896, lng: 17.721175 },
    { lat: 48.059945, lng: 17.721065 },
    { lat: 48.059994, lng: 17.720798 },
    { lat: 48.059986, lng: 17.720747 },
    { lat: 48.059983, lng: 17.72072 },
    { lat: 48.059976, lng: 17.720671 },
    { lat: 48.059969, lng: 17.720618 },
    { lat: 48.059951, lng: 17.720494 },
    { lat: 48.059898, lng: 17.720227 },
    { lat: 48.059862, lng: 17.720074 },
    { lat: 48.059825, lng: 17.719917 },
    { lat: 48.059807, lng: 17.719789 },
    { lat: 48.059773, lng: 17.719557 },
    { lat: 48.059763, lng: 17.719488 },
    { lat: 48.059737, lng: 17.719232 },
    { lat: 48.059717, lng: 17.718904 },
    { lat: 48.059717, lng: 17.718718 },
    { lat: 48.059719, lng: 17.718641 },
    { lat: 48.059799, lng: 17.71844 },
    { lat: 48.059848, lng: 17.718346 },
    { lat: 48.05995, lng: 17.718192 },
    { lat: 48.060106, lng: 17.717964 },
    { lat: 48.06021, lng: 17.717806 },
    { lat: 48.06035, lng: 17.717656 },
    { lat: 48.060408, lng: 17.717592 },
    { lat: 48.060447, lng: 17.717551 },
    { lat: 48.060574, lng: 17.717429 },
    { lat: 48.06065, lng: 17.717358 },
    { lat: 48.060794, lng: 17.71723 },
    { lat: 48.060992, lng: 17.717055 },
    { lat: 48.061137, lng: 17.716889 },
    { lat: 48.061258, lng: 17.716716 },
    { lat: 48.061368, lng: 17.716547 },
    { lat: 48.061553, lng: 17.716089 },
    { lat: 48.061554, lng: 17.715766 },
    { lat: 48.061538, lng: 17.715679 },
    { lat: 48.061428, lng: 17.715402 },
    { lat: 48.061319, lng: 17.715177 },
    { lat: 48.061218, lng: 17.714925 },
    { lat: 48.061135, lng: 17.714701 },
    { lat: 48.061052, lng: 17.714426 },
    { lat: 48.061036, lng: 17.714347 },
    { lat: 48.061015, lng: 17.714251 },
    { lat: 48.060957, lng: 17.713971 },
    { lat: 48.060854, lng: 17.713695 },
    { lat: 48.060852, lng: 17.713683 },
    { lat: 48.060824, lng: 17.713516 },
    { lat: 48.06083, lng: 17.713406 },
    { lat: 48.06092, lng: 17.713038 },
    { lat: 48.060964, lng: 17.712849 },
    { lat: 48.061007, lng: 17.712603 },
    { lat: 48.061027, lng: 17.712474 },
    { lat: 48.060973, lng: 17.712266 },
    { lat: 48.060921, lng: 17.712146 },
    { lat: 48.060761, lng: 17.711898 },
    { lat: 48.060575, lng: 17.711642 },
    { lat: 48.060476, lng: 17.71151 },
    { lat: 48.060359, lng: 17.711352 },
    { lat: 48.060239, lng: 17.711189 },
    { lat: 48.060122, lng: 17.711012 },
    { lat: 48.059993, lng: 17.710792 },
    { lat: 48.059925, lng: 17.710661 },
    { lat: 48.059861, lng: 17.710529 },
    { lat: 48.05976, lng: 17.71032 },
    { lat: 48.05972, lng: 17.710183 },
    { lat: 48.059691, lng: 17.709965 },
    { lat: 48.059704, lng: 17.709818 },
    { lat: 48.059722, lng: 17.709624 },
    { lat: 48.059792, lng: 17.709047 },
    { lat: 48.059782, lng: 17.708943 },
    { lat: 48.059747, lng: 17.708594 },
    { lat: 48.059745, lng: 17.708561 },
    { lat: 48.059744, lng: 17.708529 },
    { lat: 48.059743, lng: 17.708461 },
    { lat: 48.059742, lng: 17.708423 },
    { lat: 48.059517, lng: 17.708401 },
    { lat: 48.05935, lng: 17.70813 },
    { lat: 48.059319, lng: 17.70802 },
    { lat: 48.059481, lng: 17.707452 },
    { lat: 48.059513, lng: 17.707253 },
    { lat: 48.059569, lng: 17.706953 },
    { lat: 48.059657, lng: 17.706622 },
    { lat: 48.059668, lng: 17.706136 },
    { lat: 48.059647, lng: 17.705588 },
    { lat: 48.059603, lng: 17.70518 },
    { lat: 48.059593, lng: 17.705076 },
    { lat: 48.059666, lng: 17.704741 },
    { lat: 48.059695, lng: 17.704435 },
    { lat: 48.059779, lng: 17.704006 },
    { lat: 48.059847, lng: 17.703647 },
    { lat: 48.059953, lng: 17.703035 },
    { lat: 48.059965, lng: 17.70266 },
    { lat: 48.059939, lng: 17.702152 },
    { lat: 48.05991, lng: 17.701797 },
    { lat: 48.059819, lng: 17.701326 },
    { lat: 48.059799, lng: 17.701164 },
    { lat: 48.059767, lng: 17.700803 },
    { lat: 48.059809, lng: 17.700573 },
    { lat: 48.059907, lng: 17.700091 },
    { lat: 48.060215, lng: 17.698934 },
    { lat: 48.060316, lng: 17.698521 },
    { lat: 48.060457, lng: 17.697906 },
    { lat: 48.060477, lng: 17.697707 },
    { lat: 48.060846, lng: 17.697454 },
    { lat: 48.061075, lng: 17.697294 },
    { lat: 48.061242, lng: 17.697184 },
    { lat: 48.061608, lng: 17.696949 },
    { lat: 48.061898, lng: 17.696765 },
    { lat: 48.062134, lng: 17.696615 },
    { lat: 48.062241, lng: 17.6965 },
    { lat: 48.062456, lng: 17.696255 },
    { lat: 48.062514, lng: 17.695963 },
    { lat: 48.062419, lng: 17.695717 },
    { lat: 48.062256, lng: 17.695294 },
    { lat: 48.062482, lng: 17.695165 },
    { lat: 48.062616, lng: 17.695038 },
    { lat: 48.062741, lng: 17.694947 },
    { lat: 48.063089, lng: 17.694713 },
    { lat: 48.063218, lng: 17.694649 },
    { lat: 48.063288, lng: 17.694603 },
    { lat: 48.06365, lng: 17.694347 },
    { lat: 48.063839, lng: 17.694229 },
    { lat: 48.063918, lng: 17.694174 },
    { lat: 48.064099, lng: 17.694001 },
    { lat: 48.064162, lng: 17.693918 },
    { lat: 48.064358, lng: 17.69372 },
    { lat: 48.064498, lng: 17.693593 },
    { lat: 48.064653, lng: 17.69342 },
    { lat: 48.064776, lng: 17.693289 },
    { lat: 48.064912, lng: 17.693129 },
    { lat: 48.065101, lng: 17.692885 },
    { lat: 48.065283, lng: 17.692638 },
    { lat: 48.065378, lng: 17.692515 },
    { lat: 48.065495, lng: 17.692367 },
    { lat: 48.065593, lng: 17.692226 },
    { lat: 48.065647, lng: 17.692147 },
    { lat: 48.0657, lng: 17.692064 },
    { lat: 48.065753, lng: 17.69197 },
    { lat: 48.065858, lng: 17.691785 },
    { lat: 48.065939, lng: 17.691615 },
    { lat: 48.066085, lng: 17.691287 },
    { lat: 48.066119, lng: 17.691194 },
    { lat: 48.066159, lng: 17.691067 },
    { lat: 48.06629, lng: 17.690735 },
    { lat: 48.066364, lng: 17.690497 },
    { lat: 48.066389, lng: 17.69039 },
    { lat: 48.066447, lng: 17.690223 },
    { lat: 48.06648, lng: 17.690059 },
    { lat: 48.066508, lng: 17.689947 },
    { lat: 48.06652, lng: 17.689881 },
    { lat: 48.066528, lng: 17.689811 },
    { lat: 48.066557, lng: 17.689686 },
    { lat: 48.066586, lng: 17.689585 },
    { lat: 48.066597, lng: 17.689504 },
    { lat: 48.066615, lng: 17.689365 },
    { lat: 48.066633, lng: 17.689216 },
    { lat: 48.066678, lng: 17.689005 },
    { lat: 48.066704, lng: 17.688807 },
    { lat: 48.066719, lng: 17.688599 },
    { lat: 48.066717, lng: 17.68842 },
    { lat: 48.066697, lng: 17.688313 },
    { lat: 48.06667, lng: 17.688076 },
    { lat: 48.066673, lng: 17.68788 },
    { lat: 48.0661869, lng: 17.6879016 },
    { lat: 48.0648848, lng: 17.687558 },
    { lat: 48.0642567, lng: 17.6876762 },
    { lat: 48.0636924, lng: 17.6875545 },
    { lat: 48.0636036, lng: 17.6874709 },
    { lat: 48.0634233, lng: 17.6870641 },
    { lat: 48.0633245, lng: 17.686534 },
    { lat: 48.0632694, lng: 17.6856042 },
    { lat: 48.063301, lng: 17.6850986 },
    { lat: 48.0632195, lng: 17.6846476 },
    { lat: 48.0626822, lng: 17.6834806 },
    { lat: 48.0624314, lng: 17.6830297 },
    { lat: 48.0623211, lng: 17.6829802 },
    { lat: 48.0622862, lng: 17.6826582 },
    { lat: 48.0621592, lng: 17.6825422 },
    { lat: 48.062057, lng: 17.682158 },
    { lat: 48.0620475, lng: 17.6817358 },
    { lat: 48.0621052, lng: 17.6816638 },
    { lat: 48.0619937, lng: 17.6813988 },
    { lat: 48.0614796, lng: 17.6805336 },
    { lat: 48.060885, lng: 17.6797856 },
    { lat: 48.0607972, lng: 17.6798254 },
    { lat: 48.0605082, lng: 17.6795189 },
    { lat: 48.0604103, lng: 17.6793473 },
    { lat: 48.0603707, lng: 17.6790228 },
    { lat: 48.0589677, lng: 17.6789209 },
    { lat: 48.0581972, lng: 17.6787545 },
    { lat: 48.0576037, lng: 17.6787793 },
    { lat: 48.0568639, lng: 17.6791859 },
    { lat: 48.0563541, lng: 17.6800228 },
    { lat: 48.0558053, lng: 17.6803445 },
    { lat: 48.0555977, lng: 17.68058 },
    { lat: 48.055545, lng: 17.6808181 },
    { lat: 48.0554647, lng: 17.6819837 },
    { lat: 48.0554758, lng: 17.6826273 },
    { lat: 48.0557619, lng: 17.6834781 },
    { lat: 48.0564793, lng: 17.6848339 },
    { lat: 48.0566033, lng: 17.6852796 },
    { lat: 48.0567015, lng: 17.6859262 },
    { lat: 48.0566353, lng: 17.6864733 },
    { lat: 48.0560874, lng: 17.6878543 },
    { lat: 48.0556308, lng: 17.6883476 },
    { lat: 48.0552002, lng: 17.6885736 },
    { lat: 48.0550885, lng: 17.6885589 },
    { lat: 48.0547727, lng: 17.6881527 },
    { lat: 48.0542974, lng: 17.6872715 },
    { lat: 48.0541108, lng: 17.6867083 },
    { lat: 48.0538601, lng: 17.6862574 },
    { lat: 48.0530492, lng: 17.6842791 },
    { lat: 48.0528714, lng: 17.6837121 },
    { lat: 48.0525832, lng: 17.6831521 },
    { lat: 48.0519028, lng: 17.6823135 },
    { lat: 48.0517339, lng: 17.6819641 },
    { lat: 48.0516603, lng: 17.6816173 },
    { lat: 48.0516501, lng: 17.6810788 },
    { lat: 48.0518792, lng: 17.6804137 },
    { lat: 48.0519214, lng: 17.6798082 },
    { lat: 48.051845, lng: 17.6791699 },
    { lat: 48.0515749, lng: 17.6782115 },
    { lat: 48.0514702, lng: 17.6783793 },
    { lat: 48.0510626, lng: 17.6781477 },
    { lat: 48.0507775, lng: 17.6780644 },
    { lat: 48.0503057, lng: 17.678177 },
    { lat: 48.0497797, lng: 17.6784482 },
    { lat: 48.0495349, lng: 17.6787909 },
    { lat: 48.0494014, lng: 17.6792902 },
    { lat: 48.0494529, lng: 17.6809189 },
    { lat: 48.0496323, lng: 17.681831 },
    { lat: 48.0498029, lng: 17.6824057 },
    { lat: 48.050351, lng: 17.6831685 },
    { lat: 48.0516812, lng: 17.6854538 },
    { lat: 48.0525372, lng: 17.6867302 },
    { lat: 48.052777, lng: 17.6872257 },
    { lat: 48.0531903, lng: 17.6877834 },
    { lat: 48.0532986, lng: 17.6893986 },
    { lat: 48.0532374, lng: 17.690149 },
    { lat: 48.0531364, lng: 17.6907196 },
    { lat: 48.0528102, lng: 17.6918362 },
    { lat: 48.0521332, lng: 17.6932442 },
    { lat: 48.0518096, lng: 17.6936112 },
    { lat: 48.0506483, lng: 17.6940351 },
    { lat: 48.050632, lng: 17.6941341 },
    { lat: 48.0502171, lng: 17.6942344 },
    { lat: 48.0499335, lng: 17.6942133 },
    { lat: 48.0498421, lng: 17.6941247 },
    { lat: 48.049376, lng: 17.6940377 },
    { lat: 48.0489267, lng: 17.6938047 },
    { lat: 48.0484819, lng: 17.6934466 },
    { lat: 48.0479202, lng: 17.6926806 },
    { lat: 48.0475503, lng: 17.6917372 },
    { lat: 48.0473256, lng: 17.6910149 },
    { lat: 48.0470271, lng: 17.6896437 },
    { lat: 48.0468065, lng: 17.6874611 },
    { lat: 48.046859, lng: 17.6845575 },
    { lat: 48.0468272, lng: 17.6835662 },
    { lat: 48.0468603, lng: 17.6827635 },
    { lat: 48.0470384, lng: 17.6812693 },
    { lat: 48.0472981, lng: 17.6803152 },
    { lat: 48.047642, lng: 17.6797349 },
    { lat: 48.0479246, lng: 17.6794159 },
    { lat: 48.0486315, lng: 17.6783573 },
    { lat: 48.0491649, lng: 17.6773436 },
    { lat: 48.0492543, lng: 17.6766998 },
    { lat: 48.0492669, lng: 17.6759856 },
    { lat: 48.0488606, lng: 17.6752182 },
    { lat: 48.0485939, lng: 17.6752858 },
    { lat: 48.0480973, lng: 17.6751433 },
    { lat: 48.0478244, lng: 17.6747923 },
    { lat: 48.0476944, lng: 17.6744107 },
    { lat: 48.0475854, lng: 17.674276 },
    { lat: 48.0471549, lng: 17.6742184 },
    { lat: 48.0469496, lng: 17.6740611 },
    { lat: 48.0468806, lng: 17.6739311 },
    { lat: 48.0468078, lng: 17.6735482 },
    { lat: 48.0464369, lng: 17.6731435 },
    { lat: 48.0461571, lng: 17.6731827 },
    { lat: 48.0457246, lng: 17.673022 },
    { lat: 48.045388, lng: 17.6726775 },
    { lat: 48.0448517, lng: 17.6719611 },
    { lat: 48.0446324, lng: 17.6715254 },
    { lat: 48.0440669, lng: 17.6700605 },
    { lat: 48.0436615, lng: 17.6695736 },
    { lat: 48.0433282, lng: 17.6693906 },
    { lat: 48.0427751, lng: 17.6695379 },
    { lat: 48.0423489, lng: 17.6698534 },
    { lat: 48.041976, lng: 17.6707097 },
    { lat: 48.0417041, lng: 17.6720737 },
    { lat: 48.0416203, lng: 17.6731277 },
    { lat: 48.041544, lng: 17.675359 },
    { lat: 48.0416455, lng: 17.6770817 },
    { lat: 48.0416201, lng: 17.6775912 },
    { lat: 48.041459, lng: 17.6782284 },
    { lat: 48.0412299, lng: 17.6799345 },
    { lat: 48.0407601, lng: 17.6821944 },
    { lat: 48.0404714, lng: 17.6827911 },
    { lat: 48.0397854, lng: 17.6834106 },
    { lat: 48.0397067, lng: 17.6835595 },
    { lat: 48.0396371, lng: 17.6838716 },
    { lat: 48.039648, lng: 17.6842783 },
    { lat: 48.0399895, lng: 17.6851154 },
    { lat: 48.0406413, lng: 17.6860514 },
    { lat: 48.0411682, lng: 17.6874871 },
    { lat: 48.0414335, lng: 17.6878071 },
    { lat: 48.0419319, lng: 17.6882836 },
    { lat: 48.0428214, lng: 17.6889793 },
    { lat: 48.0445675, lng: 17.689096 },
    { lat: 48.0449565, lng: 17.6895752 },
    { lat: 48.0453255, lng: 17.6904315 },
    { lat: 48.0454553, lng: 17.6910075 },
    { lat: 48.0454586, lng: 17.6916552 },
    { lat: 48.0452686, lng: 17.6929778 },
    { lat: 48.0449822, lng: 17.693703 },
    { lat: 48.043858, lng: 17.6956018 },
    { lat: 48.0433969, lng: 17.6958807 },
    { lat: 48.0426034, lng: 17.6961724 },
    { lat: 48.0422366, lng: 17.6963901 },
    { lat: 48.0413985, lng: 17.6971435 },
    { lat: 48.0404802, lng: 17.6974963 },
    { lat: 48.038906, lng: 17.6974383 },
    { lat: 48.0385831, lng: 17.6972579 },
    { lat: 48.0379679, lng: 17.6965221 },
    { lat: 48.0371764, lng: 17.6957477 },
    { lat: 48.0364846, lng: 17.6953408 },
    { lat: 48.0358358, lng: 17.695401 },
    { lat: 48.035547, lng: 17.6938027 },
    { lat: 48.0354203, lng: 17.693439 },
    { lat: 48.0350219, lng: 17.6930113 },
    { lat: 48.0345538, lng: 17.6928731 },
    { lat: 48.0341576, lng: 17.6929309 },
    { lat: 48.0335481, lng: 17.6932206 },
    { lat: 48.0332167, lng: 17.693483 },
    { lat: 48.0326781, lng: 17.6941096 },
    { lat: 48.0323157, lng: 17.6948847 },
    { lat: 48.0320949, lng: 17.6958482 },
    { lat: 48.0319737, lng: 17.6966842 },
    { lat: 48.0314798, lng: 17.6988924 },
    { lat: 48.0313528, lng: 17.699926 },
    { lat: 48.0312243, lng: 17.7005591 },
    { lat: 48.0317169, lng: 17.7017363 },
    { lat: 48.0308858, lng: 17.7024176 },
    { lat: 48.0305763, lng: 17.702598 },
    { lat: 48.0301813, lng: 17.7027095 },
    { lat: 48.030164, lng: 17.7032597 },
    { lat: 48.0300582, lng: 17.7034808 },
    { lat: 48.0300159, lng: 17.7038662 },
    { lat: 48.0297755, lng: 17.7044097 },
    { lat: 48.0296513, lng: 17.7048782 },
    { lat: 48.0296058, lng: 17.7053794 },
    { lat: 48.0294271, lng: 17.706345 },
    { lat: 48.0294924, lng: 17.7071338 },
    { lat: 48.0295934, lng: 17.7076661 },
    { lat: 48.0295871, lng: 17.7086112 },
    { lat: 48.0288578, lng: 17.7096588 },
    { lat: 48.0287706, lng: 17.7097771 },
    { lat: 48.0286179, lng: 17.7099204 },
    { lat: 48.0284492, lng: 17.7101273 },
  ],
  Bodíky: [
    { lat: 47.9208615, lng: 17.4325057 },
    { lat: 47.920133, lng: 17.433014 },
    { lat: 47.916511, lng: 17.435268 },
    { lat: 47.915638, lng: 17.435721 },
    { lat: 47.915188, lng: 17.435889 },
    { lat: 47.914275, lng: 17.436099 },
    { lat: 47.913349, lng: 17.436146 },
    { lat: 47.911981, lng: 17.436069 },
    { lat: 47.911078, lng: 17.436193 },
    { lat: 47.910192, lng: 17.436474 },
    { lat: 47.909333, lng: 17.43691 },
    { lat: 47.906081, lng: 17.439002 },
    { lat: 47.90291, lng: 17.441428 },
    { lat: 47.901422, lng: 17.442499 },
    { lat: 47.900493, lng: 17.443437 },
    { lat: 47.899667, lng: 17.444632 },
    { lat: 47.899058, lng: 17.445416 },
    { lat: 47.898393, lng: 17.446095 },
    { lat: 47.897681, lng: 17.446659 },
    { lat: 47.893136, lng: 17.449572 },
    { lat: 47.892286, lng: 17.450014 },
    { lat: 47.89097, lng: 17.450457 },
    { lat: 47.889608, lng: 17.45067 },
    { lat: 47.888689, lng: 17.450955 },
    { lat: 47.888194, lng: 17.451182 },
    { lat: 47.886903, lng: 17.452102 },
    { lat: 47.886571, lng: 17.45241 },
    { lat: 47.885887, lng: 17.453112 },
    { lat: 47.885258, lng: 17.45392 },
    { lat: 47.884023, lng: 17.456005 },
    { lat: 47.883582, lng: 17.456866 },
    { lat: 47.882768, lng: 17.458659 },
    { lat: 47.881622, lng: 17.461701 },
    { lat: 47.88096, lng: 17.463899 },
    { lat: 47.880675, lng: 17.465025 },
    { lat: 47.880191, lng: 17.467323 },
    { lat: 47.879871, lng: 17.469303 },
    { lat: 47.879693, lng: 17.470946 },
    { lat: 47.879571, lng: 17.472643 },
    { lat: 47.8794667, lng: 17.4734974 },
    { lat: 47.8794016, lng: 17.4738581 },
    { lat: 47.8805299, lng: 17.4779779 },
    { lat: 47.8830455, lng: 17.4883319 },
    { lat: 47.8883797, lng: 17.4923172 },
    { lat: 47.8901965, lng: 17.4937053 },
    { lat: 47.8902658, lng: 17.4938329 },
    { lat: 47.8910836, lng: 17.4937335 },
    { lat: 47.892235, lng: 17.4937612 },
    { lat: 47.892688, lng: 17.4937105 },
    { lat: 47.8936935, lng: 17.4933836 },
    { lat: 47.8943029, lng: 17.4936374 },
    { lat: 47.8947967, lng: 17.4957378 },
    { lat: 47.8963057, lng: 17.4981654 },
    { lat: 47.8979789, lng: 17.4963777 },
    { lat: 47.8987958, lng: 17.4988797 },
    { lat: 47.8990252, lng: 17.4998611 },
    { lat: 47.8992333, lng: 17.5023296 },
    { lat: 47.901688, lng: 17.5028638 },
    { lat: 47.9031985, lng: 17.5032733 },
    { lat: 47.9040906, lng: 17.5036564 },
    { lat: 47.9045533, lng: 17.5039507 },
    { lat: 47.9049236, lng: 17.5033192 },
    { lat: 47.905288, lng: 17.50314 },
    { lat: 47.9058529, lng: 17.5030374 },
    { lat: 47.9060904, lng: 17.5028676 },
    { lat: 47.9065179, lng: 17.5019868 },
    { lat: 47.9073243, lng: 17.5009571 },
    { lat: 47.9086535, lng: 17.4998205 },
    { lat: 47.9101458, lng: 17.4979239 },
    { lat: 47.9118416, lng: 17.4954355 },
    { lat: 47.9153372, lng: 17.4910108 },
    { lat: 47.9166798, lng: 17.4894544 },
    { lat: 47.9179139, lng: 17.4878275 },
    { lat: 47.920031, lng: 17.4848192 },
    { lat: 47.9219385, lng: 17.4850281 },
    { lat: 47.9222182, lng: 17.4853021 },
    { lat: 47.9222603, lng: 17.4853425 },
    { lat: 47.9280626, lng: 17.4759778 },
    { lat: 47.9281214, lng: 17.4760489 },
    { lat: 47.9279326, lng: 17.4763785 },
    { lat: 47.9279874, lng: 17.4771724 },
    { lat: 47.9280483, lng: 17.4771517 },
    { lat: 47.9279919, lng: 17.4765626 },
    { lat: 47.9281627, lng: 17.4758866 },
    { lat: 47.9281662, lng: 17.4755756 },
    { lat: 47.9280838, lng: 17.4748589 },
    { lat: 47.928128, lng: 17.4741659 },
    { lat: 47.927937, lng: 17.4730165 },
    { lat: 47.9279284, lng: 17.4721346 },
    { lat: 47.9278161, lng: 17.4701091 },
    { lat: 47.9274166, lng: 17.467677 },
    { lat: 47.9270349, lng: 17.4662488 },
    { lat: 47.9271438, lng: 17.4661863 },
    { lat: 47.9276244, lng: 17.4654316 },
    { lat: 47.9302489, lng: 17.4611857 },
    { lat: 47.9324146, lng: 17.4575659 },
    { lat: 47.9324604, lng: 17.4571568 },
    { lat: 47.9350158, lng: 17.4531556 },
    { lat: 47.9364818, lng: 17.4507443 },
    { lat: 47.9365905, lng: 17.4505377 },
    { lat: 47.9386219, lng: 17.4469575 },
    { lat: 47.9387816, lng: 17.4465816 },
    { lat: 47.938826, lng: 17.4464315 },
    { lat: 47.9386433, lng: 17.4459281 },
    { lat: 47.9388866, lng: 17.4456782 },
    { lat: 47.9390418, lng: 17.4454593 },
    { lat: 47.9389591, lng: 17.4453205 },
    { lat: 47.9408026, lng: 17.4423594 },
    { lat: 47.9412489, lng: 17.4417523 },
    { lat: 47.9419952, lng: 17.4403283 },
    { lat: 47.9423185, lng: 17.4401397 },
    { lat: 47.9449313, lng: 17.4360249 },
    { lat: 47.9450866, lng: 17.4357393 },
    { lat: 47.9452613, lng: 17.4352308 },
    { lat: 47.9453272, lng: 17.4346802 },
    { lat: 47.9456457, lng: 17.4347343 },
    { lat: 47.9456473, lng: 17.4354667 },
    { lat: 47.946069, lng: 17.4347115 },
    { lat: 47.9484543, lng: 17.4314713 },
    { lat: 47.9484913, lng: 17.4312947 },
    { lat: 47.9484361, lng: 17.4311234 },
    { lat: 47.9485775, lng: 17.4309183 },
    { lat: 47.948711, lng: 17.4310266 },
    { lat: 47.9488432, lng: 17.430942 },
    { lat: 47.9494694, lng: 17.4300673 },
    { lat: 47.9497121, lng: 17.4297539 },
    { lat: 47.9490105, lng: 17.4285878 },
    { lat: 47.9488926, lng: 17.4284089 },
    { lat: 47.9481567, lng: 17.4282447 },
    { lat: 47.947623, lng: 17.4280454 },
    { lat: 47.9472638, lng: 17.4276795 },
    { lat: 47.946735, lng: 17.4268295 },
    { lat: 47.9455529, lng: 17.4245856 },
    { lat: 47.944051, lng: 17.4232377 },
    { lat: 47.9435835, lng: 17.4229112 },
    { lat: 47.9429014, lng: 17.4222215 },
    { lat: 47.9389934, lng: 17.4181921 },
    { lat: 47.9340297, lng: 17.4129033 },
    { lat: 47.933778, lng: 17.413249 },
    { lat: 47.933376, lng: 17.413898 },
    { lat: 47.932649, lng: 17.415289 },
    { lat: 47.932035, lng: 17.416796 },
    { lat: 47.93115, lng: 17.419688 },
    { lat: 47.930633, lng: 17.420875 },
    { lat: 47.930004, lng: 17.421937 },
    { lat: 47.929365, lng: 17.422793 },
    { lat: 47.928669, lng: 17.423542 },
    { lat: 47.927923, lng: 17.424178 },
    { lat: 47.926284, lng: 17.4253 },
    { lat: 47.925493, lng: 17.426069 },
    { lat: 47.924777, lng: 17.426986 },
    { lat: 47.923474, lng: 17.429208 },
    { lat: 47.922747, lng: 17.430311 },
    { lat: 47.921973, lng: 17.431338 },
    { lat: 47.921093, lng: 17.432273 },
    { lat: 47.9208615, lng: 17.4325057 },
  ],
  Hubice: [
    { lat: 48.1134372, lng: 17.4017906 },
    { lat: 48.111228, lng: 17.4002722 },
    { lat: 48.1100505, lng: 17.3990505 },
    { lat: 48.1092951, lng: 17.3974478 },
    { lat: 48.1088511, lng: 17.3962197 },
    { lat: 48.1085115, lng: 17.3956511 },
    { lat: 48.1078961, lng: 17.3951732 },
    { lat: 48.1067632, lng: 17.3945152 },
    { lat: 48.1060482, lng: 17.3934768 },
    { lat: 48.1056135, lng: 17.3929786 },
    { lat: 48.1049389, lng: 17.3926115 },
    { lat: 48.1040946, lng: 17.3923112 },
    { lat: 48.1016484, lng: 17.390033 },
    { lat: 48.1015563, lng: 17.3892291 },
    { lat: 48.1015425, lng: 17.388588 },
    { lat: 48.101393, lng: 17.3872684 },
    { lat: 48.101049, lng: 17.3862942 },
    { lat: 48.1005122, lng: 17.3852156 },
    { lat: 48.1002255, lng: 17.3856521 },
    { lat: 48.0996438, lng: 17.3863592 },
    { lat: 48.0992847, lng: 17.3866727 },
    { lat: 48.0983648, lng: 17.3870952 },
    { lat: 48.0977866, lng: 17.3871855 },
    { lat: 48.095752, lng: 17.386866 },
    { lat: 48.0945622, lng: 17.3864989 },
    { lat: 48.0936532, lng: 17.3861424 },
    { lat: 48.0906848, lng: 17.3842934 },
    { lat: 48.0898838, lng: 17.3839128 },
    { lat: 48.0883537, lng: 17.3829348 },
    { lat: 48.0874435, lng: 17.3822459 },
    { lat: 48.0860799, lng: 17.3810699 },
    { lat: 48.0847738, lng: 17.3823421 },
    { lat: 48.083167, lng: 17.3843126 },
    { lat: 48.082776, lng: 17.3847766 },
    { lat: 48.0822119, lng: 17.3854766 },
    { lat: 48.0813031, lng: 17.38678 },
    { lat: 48.0804329, lng: 17.3881516 },
    { lat: 48.0786292, lng: 17.3913153 },
    { lat: 48.0776568, lng: 17.3925759 },
    { lat: 48.077588, lng: 17.3926643 },
    { lat: 48.0799338, lng: 17.3951476 },
    { lat: 48.081231, lng: 17.3963992 },
    { lat: 48.0839244, lng: 17.3995927 },
    { lat: 48.0853821, lng: 17.4010376 },
    { lat: 48.0877632, lng: 17.4030976 },
    { lat: 48.0879894, lng: 17.4033791 },
    { lat: 48.0879632, lng: 17.4034451 },
    { lat: 48.0891785, lng: 17.404811 },
    { lat: 48.0908512, lng: 17.4069043 },
    { lat: 48.0934364, lng: 17.4098406 },
    { lat: 48.0951195, lng: 17.4115585 },
    { lat: 48.0967028, lng: 17.4129587 },
    { lat: 48.0971906, lng: 17.4135135 },
    { lat: 48.0990164, lng: 17.4151661 },
    { lat: 48.1002456, lng: 17.4164359 },
    { lat: 48.1017887, lng: 17.4177252 },
    { lat: 48.1019885, lng: 17.4178774 },
    { lat: 48.1021885, lng: 17.4176385 },
    { lat: 48.1025259, lng: 17.4169026 },
    { lat: 48.1027341, lng: 17.4163208 },
    { lat: 48.1030813, lng: 17.4157028 },
    { lat: 48.1035796, lng: 17.4152209 },
    { lat: 48.1044319, lng: 17.4141578 },
    { lat: 48.1047368, lng: 17.4138612 },
    { lat: 48.1048974, lng: 17.4136912 },
    { lat: 48.1056169, lng: 17.4131906 },
    { lat: 48.1060398, lng: 17.4128153 },
    { lat: 48.107328, lng: 17.4111961 },
    { lat: 48.1078674, lng: 17.4104265 },
    { lat: 48.1107693, lng: 17.4058749 },
    { lat: 48.110704, lng: 17.4057742 },
    { lat: 48.1134372, lng: 17.4017906 },
  ],
  BlatnánaOstrove: [
    { lat: 48.0246146, lng: 17.4478508 },
    { lat: 48.024523, lng: 17.4468934 },
    { lat: 48.0243824, lng: 17.4460338 },
    { lat: 48.0241604, lng: 17.4453134 },
    { lat: 48.023766, lng: 17.4445614 },
    { lat: 48.0231188, lng: 17.4439006 },
    { lat: 48.0225184, lng: 17.4436127 },
    { lat: 48.0220667, lng: 17.4436293 },
    { lat: 48.020723, lng: 17.4443991 },
    { lat: 48.0192656, lng: 17.4413529 },
    { lat: 48.0178698, lng: 17.4397047 },
    { lat: 48.0172786, lng: 17.4389012 },
    { lat: 48.0184573, lng: 17.4357359 },
    { lat: 48.0183064, lng: 17.435672 },
    { lat: 48.01758, lng: 17.4347325 },
    { lat: 48.017157, lng: 17.4340487 },
    { lat: 48.0169903, lng: 17.4336907 },
    { lat: 48.0166334, lng: 17.4317828 },
    { lat: 48.0164354, lng: 17.4311003 },
    { lat: 48.0162427, lng: 17.4309328 },
    { lat: 48.0159689, lng: 17.4308066 },
    { lat: 48.0156766, lng: 17.4304097 },
    { lat: 48.0130321, lng: 17.4280796 },
    { lat: 48.0146115, lng: 17.4250004 },
    { lat: 48.0149246, lng: 17.4242605 },
    { lat: 48.0149402, lng: 17.424223 },
    { lat: 48.0022183, lng: 17.4093628 },
    { lat: 48.0021854, lng: 17.409328 },
    { lat: 48.0021325, lng: 17.4092782 },
    { lat: 48.0020592, lng: 17.4092099 },
    { lat: 48.0008692, lng: 17.4144709 },
    { lat: 47.9999901, lng: 17.417581 },
    { lat: 47.9995386, lng: 17.4197461 },
    { lat: 47.9989989, lng: 17.4215011 },
    { lat: 47.9977971, lng: 17.4222961 },
    { lat: 47.996496, lng: 17.42245 },
    { lat: 47.9943952, lng: 17.4220179 },
    { lat: 47.993363, lng: 17.4220891 },
    { lat: 47.991104, lng: 17.4228841 },
    { lat: 47.9907971, lng: 17.4242712 },
    { lat: 47.9898456, lng: 17.4271741 },
    { lat: 47.9888106, lng: 17.4314139 },
    { lat: 47.9869092, lng: 17.4317337 },
    { lat: 47.9845218, lng: 17.4357437 },
    { lat: 47.9823856, lng: 17.4383706 },
    { lat: 47.9810217, lng: 17.4419706 },
    { lat: 47.9781517, lng: 17.4461689 },
    { lat: 47.9781176, lng: 17.4461981 },
    { lat: 47.9772518, lng: 17.4455638 },
    { lat: 47.9767774, lng: 17.4453034 },
    { lat: 47.9761081, lng: 17.4451871 },
    { lat: 47.9752057, lng: 17.4448422 },
    { lat: 47.9738551, lng: 17.4441154 },
    { lat: 47.9735873, lng: 17.444128 },
    { lat: 47.9729218, lng: 17.4446805 },
    { lat: 47.9722928, lng: 17.4448202 },
    { lat: 47.9720443, lng: 17.4450125 },
    { lat: 47.9714492, lng: 17.4466262 },
    { lat: 47.9716753, lng: 17.4469947 },
    { lat: 47.9723693, lng: 17.4485648 },
    { lat: 47.9726943, lng: 17.4491696 },
    { lat: 47.9744589, lng: 17.4521143 },
    { lat: 47.9749707, lng: 17.4528628 },
    { lat: 47.9756703, lng: 17.4530961 },
    { lat: 47.9759734, lng: 17.4531539 },
    { lat: 47.9761029, lng: 17.4531144 },
    { lat: 47.9769122, lng: 17.4523947 },
    { lat: 47.9772464, lng: 17.4519764 },
    { lat: 47.9775815, lng: 17.451362 },
    { lat: 47.9779558, lng: 17.4502753 },
    { lat: 47.9782586, lng: 17.4498062 },
    { lat: 47.9815483, lng: 17.4485376 },
    { lat: 47.9838265, lng: 17.4485378 },
    { lat: 47.9849441, lng: 17.4492086 },
    { lat: 47.9857045, lng: 17.4498381 },
    { lat: 47.987482, lng: 17.4510716 },
    { lat: 47.987734, lng: 17.4513321 },
    { lat: 47.9883185, lng: 17.4524341 },
    { lat: 47.9888483, lng: 17.4531693 },
    { lat: 47.9893791, lng: 17.4517411 },
    { lat: 47.9900143, lng: 17.4507251 },
    { lat: 47.9903671, lng: 17.4501584 },
    { lat: 47.9908195, lng: 17.4498188 },
    { lat: 47.9922381, lng: 17.4503941 },
    { lat: 47.9949936, lng: 17.4483729 },
    { lat: 47.9956618, lng: 17.4494122 },
    { lat: 47.9966, lng: 17.4510924 },
    { lat: 47.9973139, lng: 17.4501717 },
    { lat: 47.997952, lng: 17.4507637 },
    { lat: 47.9989248, lng: 17.4507496 },
    { lat: 47.9996106, lng: 17.451295 },
    { lat: 47.9998632, lng: 17.4532537 },
    { lat: 48.0000105, lng: 17.455521 },
    { lat: 48.0000318, lng: 17.4580583 },
    { lat: 47.9992087, lng: 17.4583063 },
    { lat: 47.9991325, lng: 17.4593748 },
    { lat: 47.9987528, lng: 17.4611316 },
    { lat: 47.9992026, lng: 17.4614649 },
    { lat: 48.0001387, lng: 17.46167 },
    { lat: 48.000784, lng: 17.4620263 },
    { lat: 48.0009075, lng: 17.4620667 },
    { lat: 48.0023199, lng: 17.4624789 },
    { lat: 48.0029683, lng: 17.4617663 },
    { lat: 48.003609, lng: 17.4609867 },
    { lat: 48.004776, lng: 17.4591667 },
    { lat: 48.0055334, lng: 17.4578878 },
    { lat: 48.005559, lng: 17.4573424 },
    { lat: 48.005872, lng: 17.4575371 },
    { lat: 48.0060507, lng: 17.4578538 },
    { lat: 48.00621, lng: 17.4580124 },
    { lat: 48.0098948, lng: 17.4610671 },
    { lat: 48.0140507, lng: 17.4571287 },
    { lat: 48.0147117, lng: 17.4585628 },
    { lat: 48.0154019, lng: 17.4580062 },
    { lat: 48.0176716, lng: 17.4559141 },
    { lat: 48.0186275, lng: 17.4546676 },
    { lat: 48.0189597, lng: 17.4543561 },
    { lat: 48.0193508, lng: 17.4545876 },
    { lat: 48.0193851, lng: 17.45454 },
    { lat: 48.0203022, lng: 17.4530463 },
    { lat: 48.0204899, lng: 17.452579 },
    { lat: 48.0207699, lng: 17.4513593 },
    { lat: 48.0212593, lng: 17.4515917 },
    { lat: 48.022437, lng: 17.4519908 },
    { lat: 48.0229993, lng: 17.4518803 },
    { lat: 48.0234928, lng: 17.45148 },
    { lat: 48.0238799, lng: 17.4508339 },
    { lat: 48.024172, lng: 17.4500812 },
    { lat: 48.0245827, lng: 17.4485952 },
    { lat: 48.024631, lng: 17.448312 },
    { lat: 48.0246219, lng: 17.4479827 },
    { lat: 48.0246146, lng: 17.4478508 },
  ],
  BlatnánaOstroveExt: [
    { lat: 47.965804, lng: 17.4601573 },
    { lat: 47.9659854, lng: 17.4601833 },
    { lat: 47.9663432, lng: 17.4602343 },
    { lat: 47.9664496, lng: 17.4595262 },
    { lat: 47.9667831, lng: 17.4579992 },
    { lat: 47.9672253, lng: 17.4564783 },
    { lat: 47.9685221, lng: 17.4534754 },
    { lat: 47.9701651, lng: 17.4490071 },
    { lat: 47.9711765, lng: 17.4464975 },
    { lat: 47.9708581, lng: 17.4463069 },
    { lat: 47.9704062, lng: 17.4460443 },
    { lat: 47.9677914, lng: 17.4451215 },
    { lat: 47.9676869, lng: 17.445858 },
    { lat: 47.967563, lng: 17.4467519 },
    { lat: 47.9667814, lng: 17.4495986 },
    { lat: 47.9654737, lng: 17.4536631 },
    { lat: 47.9647473, lng: 17.456634 },
    { lat: 47.964235, lng: 17.4583249 },
    { lat: 47.9654622, lng: 17.4591715 },
    { lat: 47.9652654, lng: 17.4601069 },
    { lat: 47.965804, lng: 17.4601573 },
  ],
};

export default UnitsDunajskáStreda;
