export const categoryList = [
  {
    categoryName: "Byty",
    children: [
      {
        code: "101",
        name: "Garsónka",
        min: "15",
        max: "40",
      },
      {
        code: "102",
        name: "Dvojgarsónka",
        min: "30",
        max: "60",
      },
      {
        code: "103",
        name: "1 izbový byt",
        min: "15",
        max: "50",
      },
      {
        code: "104",
        name: "2 izbový byt",
        min: "30",
        max: "75",
      },
      {
        code: "105",
        name: "3 izbový byt",
        min: "50",
        max: "90",
      },
      {
        code: "106",
        name: "4 izbový byt",
        min: "65",
        max: "100",
      },
      {
        code: "107",
        name: "5 a viac izbový byt",
        min: "85",
        max: "215",
      },
      {
        code: "108",
        name: "Mezonet",
        min: "45",
        max: "250",
      },
      {
        code: "109",
        name: "Apartmán",
        min: "15",
        max: "100",
      },
      {
        code: "110",
        name: "Loft",
        min: "10",
        max: "300",
      },
      {
        code: "111",
        name: "Iný byt",
        min: "40",
        max: "300",
      },
    ],
  },
  {
    categoryName: "Domy",
    children: [
      {
        code: "201",
        name: "Rodinný dom",
      },
      {
        code: "202",
        name: "Rodinná vila",
      },
      {
        code: "203",
        name: "Zrubový dom",
      },
      {
        code: "204",
        name: "Bungalov",
      },
      {
        code: "205",
        name: "Vidiecky dom",
      },
      {
        code: "206",
        name: "Bývalá poľnohosp. usadlosť",
      },
      {
        code: "207",
        name: "Mobilný dom",
      },
      {
        code: "208",
        name: "Hausbót",
      },
      {
        code: "209",
        name: "Iný objekt na bývanie",
      },
    ],
  },
  {
    categoryName: "Rekreačné domy",
    children: [
      {
        code: "301",
        name: "Chata, drevenica, zrub",
      },
      {
        code: "302",
        name: "Chalupa, rekreačný domček",
      },
      {
        code: "303",
        name: "Záhradná chatka",
      },
      {
        code: "304",
        name: "Iný objekt na rekreáciu",
      },
    ],
  },
  /*{
        "categoryName": "Objekty",
        "children": [
            {
                "code": "401",
                "name": "Garáž",
            },
            {
                "code": "402",
                "name": "Hotel, penzión",
            },
            {
                "code": "403",
                "name": "Objekt pre obchod",
            },
            {
                "code": "404",
                "name": "Reštaurácia",
            },
            {
                "code": "405",
                "name": "Skladový objekt",
            },
            {
                "code": "406",
                "name": "Polyfunkčný objekt",
            },
            {
                "code": "407",
                "name": "Administratívny objekt",
            },
            {
                "code": "408",
                "name": "Výrobný objekt",
            },
            {
                "code": "409",
                "name": "Historický objekt",
            },
            {
                "code": "410",
                "name": "Nájomný dom",
            },
            {
                "code": "411",
                "name": "Prevádzkový areál",
            },
            {
                "code": "412",
                "name": "Poľnohosp. objekt",
            },
            {
                "code": "413",
                "name": "Bytový dom",
            },
            {
                "code": "414",
                "name": "Apartmánový dom",
            },
            {
                "code": "415",
                "name": "Spevnené plochy",
            },
            {
                "code": "416",
                "name": "Objekt pre šport",
            },
            {
                "code": "417",
                "name": "Opravárenský objekt",
            },
            {
                "code": "418",
                "name": "Kúpeľný objekt",
            },
            {
                "code": "419",
                "name": "Čerpacia stanica PHM",
            },
            {
                "code": "420",
                "name": "Malá elektráreň",
            },
            {
                "code": "421",
                "name": "Mobilné bunky a stánky",
            },
            {
                "code": "422",
                "name": "Iný komerčný objekt",
            },
            {
                "code": "423",
                "name": "Iný prevádzkový objekt",
            },
            {
                "code": "424",
                "name": "Iný objekt",
            },
        ]
    },*/
  /*{
        "categoryName": "Priestory",
        "children": [
            {
                "code": "501",
                "name": "Kancelárie, admin. priestory",
            },
            {
                "code": "502",
                "name": "Obchodné priestory",
            },
            {
                "code": "503",
                "name": "Reštauračné priestory",
            },
            {
                "code": "504",
                "name": "Skladové priestory",
            },
            {
                "code": "505",
                "name": "Výrobné priestory",
            },
            {
                "code": "506",
                "name": "Športové priestory",
            },
            {
                "code": "507",
                "name": "Opravárenské priestory",
            },
            {
                "code": "508",
                "name": "Plocha pre reklamu",
            },
            {
                "code": "509",
                "name": "Iné komerčné priestory",
            },
            {
                "code": "510",
                "name": "Iné prevádzkové priestory",
            },
        ]
    },*/
  {
    categoryName: "Pozemky",
    children: [
      {
        code: "601",
        name: "Pozemok pre rod. domy",
      },
      {
        code: "602",
        name: "Rekreačný pozemok",
      },
      {
        code: "603",
        name: "Pozemok pre bytovú výstavbu",
      },
      {
        code: "604",
        name: "Orná pôda",
      },
      {
        code: "605",
        name: "Záhrada",
      },
      {
        code: "606",
        name: "Priemyselná zóna",
      },
      {
        code: "607",
        name: "Komerčná zóna",
      },
      {
        code: "608",
        name: "Pozemok pre obč. vybavenosť",
      },
      {
        code: "609",
        name: "Zmiešaná zóna",
      },
      {
        code: "610",
        name: "Lesy",
      },
      {
        code: "611",
        name: "Rybník, vodná plocha",
      },
      {
        code: "612",
        name: "Lúka, pasienok",
      },
      {
        code: "613",
        name: "Chmelnica, vinica",
      },
      {
        code: "614",
        name: "Sad",
      },
      {
        code: "615",
        name: "Pozemok pre reklamu",
      },
      {
        code: "616",
        name: "Iný stavebný pozemok",
      },
      {
        code: "617",
        name: "Iný poľnohosp. pozemok",
      },
    ],
  },
];
