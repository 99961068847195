export const categoryService = {
  getAllCategories: async () => {
    return await fetch(process.env.REACT_APP_SERVICE + `listAll`);
  },

  getAllStatuses: async () => {
    return await fetch(process.env.REACT_APP_SERVICE + `listOfStatuses`);
  },

  getAllMunicipalities: async () => {
    return await fetch(process.env.REACT_APP_SERVICE + `listOfMunicipalities`);
  },

  getHistory: async (location, number, psc, lvNum, flat, entrance, street) => {
    try {
      const serviceUrl = process.env.REACT_APP_HISTORY_SERVICE;
      console.log("Service URL:", serviceUrl);

      if (!serviceUrl) {
        throw new Error("REACT_APP_HISTORY_SERVICE is not set");
      }

      try {
        console.log("Attempting to fetch history data...");
        const response = await fetch(serviceUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ location, number, psc, lvNum, flat, entrance, street }),
        });

        console.log("Response status:", response.status);
        console.log("Response ok:", response.ok);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
      } catch (fetchError) {
        console.error("Fetch error in getHistory:", fetchError);
        throw fetchError;
      }
    } catch (error) {
      console.error("Error in getHistory:", error);
      throw error;
    }
  },
};
