const UnitsTopoľčany = {
  Ludanice: [
    { lat: 48.4855395, lng: 18.0690229 },
    { lat: 48.4855158, lng: 18.069057 },
    { lat: 48.4850836, lng: 18.071051 },
    { lat: 48.4844349, lng: 18.0723037 },
    { lat: 48.484391, lng: 18.07257 },
    { lat: 48.4843612, lng: 18.0729468 },
    { lat: 48.4846423, lng: 18.0723159 },
    { lat: 48.4849379, lng: 18.0725838 },
    { lat: 48.4833532, lng: 18.0762035 },
    { lat: 48.4826658, lng: 18.0774106 },
    { lat: 48.4812792, lng: 18.0803127 },
    { lat: 48.4802594, lng: 18.0828165 },
    { lat: 48.4801813, lng: 18.0828479 },
    { lat: 48.479946, lng: 18.0834544 },
    { lat: 48.4793949, lng: 18.085328 },
    { lat: 48.479111, lng: 18.0866822 },
    { lat: 48.4785641, lng: 18.0930846 },
    { lat: 48.4778294, lng: 18.0967961 },
    { lat: 48.4768795, lng: 18.1007832 },
    { lat: 48.4768762, lng: 18.1009768 },
    { lat: 48.4760859, lng: 18.1049376 },
    { lat: 48.4758965, lng: 18.10623 },
    { lat: 48.4753937, lng: 18.1084521 },
    { lat: 48.4755741, lng: 18.1087788 },
    { lat: 48.4753226, lng: 18.1090508 },
    { lat: 48.4752847, lng: 18.1093094 },
    { lat: 48.4753049, lng: 18.1095205 },
    { lat: 48.4755243, lng: 18.1097582 },
    { lat: 48.4755242, lng: 18.1105268 },
    { lat: 48.4752864, lng: 18.1104765 },
    { lat: 48.4754087, lng: 18.1116771 },
    { lat: 48.4752643, lng: 18.1115614 },
    { lat: 48.4745724, lng: 18.1134687 },
    { lat: 48.4742705, lng: 18.1146739 },
    { lat: 48.4733757, lng: 18.1164892 },
    { lat: 48.4733416, lng: 18.1166776 },
    { lat: 48.4734553, lng: 18.1169225 },
    { lat: 48.4734358, lng: 18.1176321 },
    { lat: 48.4733494, lng: 18.1182781 },
    { lat: 48.4725871, lng: 18.1202738 },
    { lat: 48.4728621, lng: 18.1216199 },
    { lat: 48.4731087, lng: 18.1225731 },
    { lat: 48.4731046, lng: 18.1226964 },
    { lat: 48.4728336, lng: 18.1238895 },
    { lat: 48.4728154, lng: 18.1245822 },
    { lat: 48.4722477, lng: 18.1270525 },
    { lat: 48.4724375, lng: 18.1268029 },
    { lat: 48.4727215, lng: 18.1267015 },
    { lat: 48.4731365, lng: 18.1264862 },
    { lat: 48.4735779, lng: 18.1265051 },
    { lat: 48.4738904, lng: 18.1268358 },
    { lat: 48.4741199, lng: 18.127537 },
    { lat: 48.4743399, lng: 18.1278629 },
    { lat: 48.4744305, lng: 18.127985 },
    { lat: 48.4745681, lng: 18.1275183 },
    { lat: 48.4761384, lng: 18.1226077 },
    { lat: 48.4763639, lng: 18.1224833 },
    { lat: 48.4764827, lng: 18.12249 },
    { lat: 48.4766984, lng: 18.1225916 },
    { lat: 48.4771647, lng: 18.1230762 },
    { lat: 48.477337, lng: 18.123014 },
    { lat: 48.4774921, lng: 18.122827 },
    { lat: 48.4776643, lng: 18.1224919 },
    { lat: 48.4779072, lng: 18.1224 },
    { lat: 48.4780639, lng: 18.1224345 },
    { lat: 48.4782612, lng: 18.1226819 },
    { lat: 48.4784167, lng: 18.1230081 },
    { lat: 48.4785729, lng: 18.1231674 },
    { lat: 48.478985, lng: 18.123385 },
    { lat: 48.4790779, lng: 18.1233311 },
    { lat: 48.4786286, lng: 18.1249904 },
    { lat: 48.4787785, lng: 18.1250837 },
    { lat: 48.4818276, lng: 18.119523 },
    { lat: 48.4854157, lng: 18.110447 },
    { lat: 48.4856865, lng: 18.1091955 },
    { lat: 48.4869529, lng: 18.1100632 },
    { lat: 48.4870783, lng: 18.1096047 },
    { lat: 48.4855363, lng: 18.1085164 },
    { lat: 48.4859975, lng: 18.1068942 },
    { lat: 48.4863297, lng: 18.1055125 },
    { lat: 48.489438, lng: 18.091321 },
    { lat: 48.4900854, lng: 18.0885865 },
    { lat: 48.4925504, lng: 18.0861309 },
    { lat: 48.4929388, lng: 18.0850584 },
    { lat: 48.4931405, lng: 18.0790455 },
    { lat: 48.4931771, lng: 18.0790726 },
    { lat: 48.493953, lng: 18.0781996 },
    { lat: 48.4943098, lng: 18.0777045 },
    { lat: 48.4941563, lng: 18.0774541 },
    { lat: 48.4940273, lng: 18.0772561 },
    { lat: 48.4929052, lng: 18.0760837 },
    { lat: 48.492598, lng: 18.0755056 },
    { lat: 48.4921919, lng: 18.0749893 },
    { lat: 48.4912616, lng: 18.0745818 },
    { lat: 48.4909647, lng: 18.0745399 },
    { lat: 48.490338, lng: 18.0742861 },
    { lat: 48.4892863, lng: 18.073655 },
    { lat: 48.489029, lng: 18.0729852 },
    { lat: 48.4888075, lng: 18.0726731 },
    { lat: 48.4880849, lng: 18.0720163 },
    { lat: 48.4864285, lng: 18.0701822 },
    { lat: 48.486168, lng: 18.0695542 },
    { lat: 48.4859784, lng: 18.0692298 },
    { lat: 48.4857932, lng: 18.0690929 },
    { lat: 48.4855395, lng: 18.0690229 },
  ],
  LudaniceExt: [
    { lat: 48.5000837, lng: 18.1291945 },
    { lat: 48.500731, lng: 18.1265379 },
    { lat: 48.5009921, lng: 18.1258507 },
    { lat: 48.501102, lng: 18.1251667 },
    { lat: 48.5012972, lng: 18.1242154 },
    { lat: 48.5014726, lng: 18.1236446 },
    { lat: 48.502117, lng: 18.1239155 },
    { lat: 48.5025883, lng: 18.1243161 },
    { lat: 48.5031057, lng: 18.1245547 },
    { lat: 48.5032759, lng: 18.1244347 },
    { lat: 48.5036452, lng: 18.124498 },
    { lat: 48.5057249, lng: 18.1255406 },
    { lat: 48.5060348, lng: 18.1264775 },
    { lat: 48.5062807, lng: 18.1274255 },
    { lat: 48.5064536, lng: 18.1289924 },
    { lat: 48.5066861, lng: 18.1299001 },
    { lat: 48.5066832, lng: 18.1302556 },
    { lat: 48.5076638, lng: 18.1315716 },
    { lat: 48.5080713, lng: 18.1318804 },
    { lat: 48.5083541, lng: 18.1320096 },
    { lat: 48.5089541, lng: 18.132099 },
    { lat: 48.5091515, lng: 18.1315963 },
    { lat: 48.5097764, lng: 18.1288832 },
    { lat: 48.5100059, lng: 18.1285816 },
    { lat: 48.5109317, lng: 18.1281844 },
    { lat: 48.5112149, lng: 18.1278348 },
    { lat: 48.5113983, lng: 18.1277066 },
    { lat: 48.5117499, lng: 18.127621 },
    { lat: 48.5124436, lng: 18.1277933 },
    { lat: 48.5129224, lng: 18.1275844 },
    { lat: 48.5141318, lng: 18.128079 },
    { lat: 48.5148637, lng: 18.1280918 },
    { lat: 48.5152423, lng: 18.1282175 },
    { lat: 48.5161237, lng: 18.1288136 },
    { lat: 48.5171025, lng: 18.1301539 },
    { lat: 48.5171226, lng: 18.1303441 },
    { lat: 48.5173424, lng: 18.1309992 },
    { lat: 48.5177195, lng: 18.1303246 },
    { lat: 48.5186521, lng: 18.1274909 },
    { lat: 48.5166781, lng: 18.1261531 },
    { lat: 48.5133055, lng: 18.124325 },
    { lat: 48.514202, lng: 18.1201795 },
    { lat: 48.5142749, lng: 18.1198449 },
    { lat: 48.5144158, lng: 18.1161963 },
    { lat: 48.5149904, lng: 18.1162757 },
    { lat: 48.5149967, lng: 18.1160933 },
    { lat: 48.5157475, lng: 18.1157692 },
    { lat: 48.5156009, lng: 18.1129549 },
    { lat: 48.5161078, lng: 18.1128435 },
    { lat: 48.5177652, lng: 18.1119492 },
    { lat: 48.5184584, lng: 18.1113767 },
    { lat: 48.5200364, lng: 18.1048842 },
    { lat: 48.5203071, lng: 18.101298 },
    { lat: 48.5202292, lng: 18.0986978 },
    { lat: 48.5206875, lng: 18.0982948 },
    { lat: 48.5208573, lng: 18.0978233 },
    { lat: 48.5208659, lng: 18.0974334 },
    { lat: 48.5189484, lng: 18.0985861 },
    { lat: 48.5163638, lng: 18.098984 },
    { lat: 48.5142868, lng: 18.0988242 },
    { lat: 48.5115021, lng: 18.0976787 },
    { lat: 48.5105664, lng: 18.0974656 },
    { lat: 48.509635, lng: 18.0974399 },
    { lat: 48.5091593, lng: 18.0976234 },
    { lat: 48.5088949, lng: 18.0978795 },
    { lat: 48.5066669, lng: 18.1000376 },
    { lat: 48.5053041, lng: 18.0983897 },
    { lat: 48.5052604, lng: 18.0982418 },
    { lat: 48.5050998, lng: 18.0980439 },
    { lat: 48.5047394, lng: 18.0976935 },
    { lat: 48.5043534, lng: 18.0970761 },
    { lat: 48.5042871, lng: 18.0965226 },
    { lat: 48.5042906, lng: 18.0960453 },
    { lat: 48.5050371, lng: 18.0914384 },
    { lat: 48.5053422, lng: 18.0905205 },
    { lat: 48.5055552, lng: 18.0901125 },
    { lat: 48.5057705, lng: 18.0898963 },
    { lat: 48.5061392, lng: 18.0891991 },
    { lat: 48.5064458, lng: 18.0881334 },
    { lat: 48.506606, lng: 18.0872693 },
    { lat: 48.5068446, lng: 18.0867225 },
    { lat: 48.5042533, lng: 18.0833206 },
    { lat: 48.503695, lng: 18.0831156 },
    { lat: 48.5032413, lng: 18.0833838 },
    { lat: 48.5027115, lng: 18.0839138 },
    { lat: 48.5024493, lng: 18.0843452 },
    { lat: 48.5022427, lng: 18.084826 },
    { lat: 48.5022569, lng: 18.0848384 },
    { lat: 48.5020108, lng: 18.0855487 },
    { lat: 48.5018845, lng: 18.0864129 },
    { lat: 48.5011721, lng: 18.0897496 },
    { lat: 48.5007916, lng: 18.0909414 },
    { lat: 48.500332, lng: 18.0918381 },
    { lat: 48.4990524, lng: 18.0936473 },
    { lat: 48.498508, lng: 18.0947569 },
    { lat: 48.4980479, lng: 18.0959951 },
    { lat: 48.497724, lng: 18.0965897 },
    { lat: 48.4967921, lng: 18.0979408 },
    { lat: 48.4961539, lng: 18.0994353 },
    { lat: 48.4960151, lng: 18.0999505 },
    { lat: 48.4959134, lng: 18.1009909 },
    { lat: 48.4957871, lng: 18.1016855 },
    { lat: 48.4954622, lng: 18.1027676 },
    { lat: 48.4953569, lng: 18.1030285 },
    { lat: 48.4950912, lng: 18.1034452 },
    { lat: 48.4950225, lng: 18.1036575 },
    { lat: 48.4949413, lng: 18.1042159 },
    { lat: 48.4944408, lng: 18.1063261 },
    { lat: 48.4939645, lng: 18.108021 },
    { lat: 48.4935505, lng: 18.1100289 },
    { lat: 48.4932567, lng: 18.1111025 },
    { lat: 48.4931768, lng: 18.1111632 },
    { lat: 48.4931721, lng: 18.1113332 },
    { lat: 48.4918931, lng: 18.1154132 },
    { lat: 48.490882, lng: 18.1201605 },
    { lat: 48.4915452, lng: 18.1201343 },
    { lat: 48.4923058, lng: 18.120309 },
    { lat: 48.4923798, lng: 18.1203778 },
    { lat: 48.4917523, lng: 18.1242567 },
    { lat: 48.4909162, lng: 18.1240116 },
    { lat: 48.4906086, lng: 18.125137 },
    { lat: 48.4906304, lng: 18.1252793 },
    { lat: 48.4912467, lng: 18.1265394 },
    { lat: 48.4911876, lng: 18.1268596 },
    { lat: 48.4908968, lng: 18.1271567 },
    { lat: 48.4901741, lng: 18.1321537 },
    { lat: 48.4902374, lng: 18.1321934 },
    { lat: 48.4901582, lng: 18.1324404 },
    { lat: 48.4904842, lng: 18.1326905 },
    { lat: 48.4906932, lng: 18.1329447 },
    { lat: 48.491375, lng: 18.1340312 },
    { lat: 48.4915263, lng: 18.1341674 },
    { lat: 48.4916616, lng: 18.1342043 },
    { lat: 48.4918106, lng: 18.1342059 },
    { lat: 48.4920295, lng: 18.1340497 },
    { lat: 48.4922717, lng: 18.1337352 },
    { lat: 48.4926615, lng: 18.1329294 },
    { lat: 48.4927556, lng: 18.1328191 },
    { lat: 48.4928948, lng: 18.1328264 },
    { lat: 48.4930219, lng: 18.1329174 },
    { lat: 48.4934432, lng: 18.1334826 },
    { lat: 48.4940241, lng: 18.1326661 },
    { lat: 48.4940731, lng: 18.1323839 },
    { lat: 48.494196, lng: 18.1321101 },
    { lat: 48.4945559, lng: 18.1323614 },
    { lat: 48.4946653, lng: 18.1323604 },
    { lat: 48.4948523, lng: 18.1321408 },
    { lat: 48.4950062, lng: 18.1317223 },
    { lat: 48.4952184, lng: 18.132149 },
    { lat: 48.4955643, lng: 18.1326619 },
    { lat: 48.495831, lng: 18.1328643 },
    { lat: 48.4960728, lng: 18.1328854 },
    { lat: 48.4962595, lng: 18.1327017 },
    { lat: 48.4963535, lng: 18.1323395 },
    { lat: 48.4964827, lng: 18.1321182 },
    { lat: 48.4965975, lng: 18.132035 },
    { lat: 48.4967169, lng: 18.1320306 },
    { lat: 48.4971927, lng: 18.1322574 },
    { lat: 48.4977222, lng: 18.1320874 },
    { lat: 48.49794, lng: 18.1315811 },
    { lat: 48.4981212, lng: 18.1313786 },
    { lat: 48.4985013, lng: 18.1311458 },
    { lat: 48.4985831, lng: 18.1310396 },
    { lat: 48.4991113, lng: 18.1299361 },
    { lat: 48.4993834, lng: 18.1296101 },
    { lat: 48.4994717, lng: 18.1294213 },
    { lat: 48.4995846, lng: 18.1293263 },
    { lat: 48.5000887, lng: 18.1292027 },
    { lat: 48.5000837, lng: 18.1291945 },
  ],
  Jacovce: [
    { lat: 48.574542, lng: 18.1216451 },
    { lat: 48.5764129, lng: 18.1282743 },
    { lat: 48.576491, lng: 18.1285511 },
    { lat: 48.5790794, lng: 18.1375719 },
    { lat: 48.5787307, lng: 18.1378821 },
    { lat: 48.5790696, lng: 18.1388954 },
    { lat: 48.5797136, lng: 18.1399558 },
    { lat: 48.5797941, lng: 18.1401939 },
    { lat: 48.5800986, lng: 18.1416637 },
    { lat: 48.5823325, lng: 18.1495146 },
    { lat: 48.5858735, lng: 18.16018 },
    { lat: 48.5870784, lng: 18.1590862 },
    { lat: 48.5893491, lng: 18.1569515 },
    { lat: 48.5924578, lng: 18.1542835 },
    { lat: 48.5927051, lng: 18.1538972 },
    { lat: 48.5929502, lng: 18.1536663 },
    { lat: 48.5964304, lng: 18.150808 },
    { lat: 48.5982268, lng: 18.149369 },
    { lat: 48.5984644, lng: 18.1492431 },
    { lat: 48.5992963, lng: 18.1484894 },
    { lat: 48.5993555, lng: 18.1482876 },
    { lat: 48.5970154, lng: 18.1410531 },
    { lat: 48.5967787, lng: 18.1405168 },
    { lat: 48.6104134, lng: 18.1399747 },
    { lat: 48.6148882, lng: 18.1381159 },
    { lat: 48.6147901, lng: 18.1381153 },
    { lat: 48.6147731, lng: 18.1378643 },
    { lat: 48.6145526, lng: 18.1369837 },
    { lat: 48.614014, lng: 18.1350596 },
    { lat: 48.6142842, lng: 18.1348344 },
    { lat: 48.6140776, lng: 18.1343853 },
    { lat: 48.6139876, lng: 18.1340049 },
    { lat: 48.6139074, lng: 18.1333258 },
    { lat: 48.6139233, lng: 18.1324535 },
    { lat: 48.61406, lng: 18.1321262 },
    { lat: 48.6141955, lng: 18.1315283 },
    { lat: 48.6141182, lng: 18.1307821 },
    { lat: 48.6139423, lng: 18.130202 },
    { lat: 48.6133354, lng: 18.1288523 },
    { lat: 48.6124546, lng: 18.1254904 },
    { lat: 48.6115915, lng: 18.1216426 },
    { lat: 48.6115571, lng: 18.1211294 },
    { lat: 48.6115878, lng: 18.1209791 },
    { lat: 48.6114607, lng: 18.1206981 },
    { lat: 48.6100181, lng: 18.1158184 },
    { lat: 48.6071466, lng: 18.1065788 },
    { lat: 48.6069875, lng: 18.1060936 },
    { lat: 48.6068362, lng: 18.1056294 },
    { lat: 48.6057759, lng: 18.1065859 },
    { lat: 48.6050771, lng: 18.1070233 },
    { lat: 48.6034743, lng: 18.1075794 },
    { lat: 48.602016, lng: 18.1075578 },
    { lat: 48.6006487, lng: 18.1071822 },
    { lat: 48.6002471, lng: 18.1071818 },
    { lat: 48.5999733, lng: 18.1074053 },
    { lat: 48.5989641, lng: 18.1082676 },
    { lat: 48.5988129, lng: 18.1083433 },
    { lat: 48.598802, lng: 18.1084649 },
    { lat: 48.5985502, lng: 18.1086559 },
    { lat: 48.5947457, lng: 18.1110285 },
    { lat: 48.5890774, lng: 18.1144531 },
    { lat: 48.5884214, lng: 18.1149298 },
    { lat: 48.5882847, lng: 18.115006 },
    { lat: 48.5878693, lng: 18.1150444 },
    { lat: 48.5825249, lng: 18.1175411 },
    { lat: 48.5814387, lng: 18.1181118 },
    { lat: 48.5811626, lng: 18.1181781 },
    { lat: 48.5787831, lng: 18.1193283 },
    { lat: 48.5764531, lng: 18.1205889 },
    { lat: 48.574542, lng: 18.1216451 },
  ],
  Chrabrany: [
    { lat: 48.5142749, lng: 18.1198449 },
    { lat: 48.514202, lng: 18.1201795 },
    { lat: 48.5133055, lng: 18.124325 },
    { lat: 48.5166781, lng: 18.1261531 },
    { lat: 48.5186521, lng: 18.1274909 },
    { lat: 48.5177195, lng: 18.1303246 },
    { lat: 48.5173424, lng: 18.1309992 },
    { lat: 48.5171226, lng: 18.1303441 },
    { lat: 48.5171025, lng: 18.1301539 },
    { lat: 48.5161237, lng: 18.1288136 },
    { lat: 48.5152423, lng: 18.1282175 },
    { lat: 48.5148637, lng: 18.1280918 },
    { lat: 48.5141318, lng: 18.128079 },
    { lat: 48.5129224, lng: 18.1275844 },
    { lat: 48.5124436, lng: 18.1277933 },
    { lat: 48.5117499, lng: 18.127621 },
    { lat: 48.5113983, lng: 18.1277066 },
    { lat: 48.5112149, lng: 18.1278348 },
    { lat: 48.5109317, lng: 18.1281844 },
    { lat: 48.5100059, lng: 18.1285816 },
    { lat: 48.5097764, lng: 18.1288832 },
    { lat: 48.5091515, lng: 18.1315963 },
    { lat: 48.5089541, lng: 18.132099 },
    { lat: 48.5083541, lng: 18.1320096 },
    { lat: 48.5080713, lng: 18.1318804 },
    { lat: 48.5076638, lng: 18.1315716 },
    { lat: 48.5066832, lng: 18.1302556 },
    { lat: 48.5066861, lng: 18.1299001 },
    { lat: 48.5064536, lng: 18.1289924 },
    { lat: 48.5062807, lng: 18.1274255 },
    { lat: 48.5060348, lng: 18.1264775 },
    { lat: 48.5057249, lng: 18.1255406 },
    { lat: 48.5036452, lng: 18.124498 },
    { lat: 48.5032759, lng: 18.1244347 },
    { lat: 48.5031057, lng: 18.1245547 },
    { lat: 48.5025883, lng: 18.1243161 },
    { lat: 48.502117, lng: 18.1239155 },
    { lat: 48.5014726, lng: 18.1236446 },
    { lat: 48.5012972, lng: 18.1242154 },
    { lat: 48.501102, lng: 18.1251667 },
    { lat: 48.5009921, lng: 18.1258507 },
    { lat: 48.500731, lng: 18.1265379 },
    { lat: 48.5000837, lng: 18.1291945 },
    { lat: 48.5094901, lng: 18.1384074 },
    { lat: 48.5100408, lng: 18.1428419 },
    { lat: 48.5102707, lng: 18.143692 },
    { lat: 48.5103472, lng: 18.1441517 },
    { lat: 48.5105663, lng: 18.1444627 },
    { lat: 48.5105807, lng: 18.1447505 },
    { lat: 48.5107141, lng: 18.145023 },
    { lat: 48.5108214, lng: 18.145395 },
    { lat: 48.5108311, lng: 18.1455998 },
    { lat: 48.5107147, lng: 18.1459276 },
    { lat: 48.5108031, lng: 18.1461854 },
    { lat: 48.5107602, lng: 18.1464929 },
    { lat: 48.5109423, lng: 18.1466393 },
    { lat: 48.5111374, lng: 18.1465958 },
    { lat: 48.5113089, lng: 18.1468284 },
    { lat: 48.5116519, lng: 18.1470198 },
    { lat: 48.5117948, lng: 18.1470341 },
    { lat: 48.5120009, lng: 18.1466777 },
    { lat: 48.5129386, lng: 18.1477438 },
    { lat: 48.5129059, lng: 18.1479738 },
    { lat: 48.5135946, lng: 18.1481951 },
    { lat: 48.5137254, lng: 18.1483089 },
    { lat: 48.513838, lng: 18.1485955 },
    { lat: 48.513857, lng: 18.1488066 },
    { lat: 48.5138214, lng: 18.1489888 },
    { lat: 48.5136972, lng: 18.1493576 },
    { lat: 48.513411, lng: 18.1498477 },
    { lat: 48.5133133, lng: 18.1503436 },
    { lat: 48.5132543, lng: 18.1509757 },
    { lat: 48.5133826, lng: 18.1518429 },
    { lat: 48.5137236, lng: 18.1522165 },
    { lat: 48.5142055, lng: 18.1524691 },
    { lat: 48.5149328, lng: 18.1526701 },
    { lat: 48.5154985, lng: 18.1531227 },
    { lat: 48.5158099, lng: 18.1536657 },
    { lat: 48.5166142, lng: 18.1553673 },
    { lat: 48.5170385, lng: 18.1559499 },
    { lat: 48.517544, lng: 18.1562005 },
    { lat: 48.5178399, lng: 18.1561298 },
    { lat: 48.5189778, lng: 18.1549323 },
    { lat: 48.5197404, lng: 18.1544396 },
    { lat: 48.5207182, lng: 18.1541584 },
    { lat: 48.5216391, lng: 18.1540822 },
    { lat: 48.5222252, lng: 18.1541655 },
    { lat: 48.5226713, lng: 18.1544442 },
    { lat: 48.5227514, lng: 18.1548142 },
    { lat: 48.5228709, lng: 18.1550071 },
    { lat: 48.522801, lng: 18.1557653 },
    { lat: 48.5224876, lng: 18.1561779 },
    { lat: 48.5221931, lng: 18.1564285 },
    { lat: 48.5218883, lng: 18.1566986 },
    { lat: 48.5220064, lng: 18.156944 },
    { lat: 48.5244488, lng: 18.161769 },
    { lat: 48.5248979, lng: 18.1627996 },
    { lat: 48.5250417, lng: 18.1627671 },
    { lat: 48.525481, lng: 18.1618015 },
    { lat: 48.525555, lng: 18.1615401 },
    { lat: 48.5257102, lng: 18.1615129 },
    { lat: 48.5262303, lng: 18.1620724 },
    { lat: 48.5273343, lng: 18.161456 },
    { lat: 48.5276856, lng: 18.1615031 },
    { lat: 48.5277707, lng: 18.1610426 },
    { lat: 48.528215, lng: 18.1584363 },
    { lat: 48.5280707, lng: 18.1583987 },
    { lat: 48.5280878, lng: 18.1582672 },
    { lat: 48.5331082, lng: 18.159701 },
    { lat: 48.5361519, lng: 18.1515673 },
    { lat: 48.5360935, lng: 18.1515308 },
    { lat: 48.5363434, lng: 18.1515004 },
    { lat: 48.5366763, lng: 18.1511331 },
    { lat: 48.5372501, lng: 18.1508887 },
    { lat: 48.5381832, lng: 18.150229 },
    { lat: 48.5387979, lng: 18.1491155 },
    { lat: 48.5393025, lng: 18.1484977 },
    { lat: 48.5394532, lng: 18.1484294 },
    { lat: 48.5400009, lng: 18.1476616 },
    { lat: 48.5402135, lng: 18.1472999 },
    { lat: 48.5409382, lng: 18.1455746 },
    { lat: 48.5410291, lng: 18.1450445 },
    { lat: 48.5417457, lng: 18.1427376 },
    { lat: 48.5417057, lng: 18.1424569 },
    { lat: 48.5414042, lng: 18.1417179 },
    { lat: 48.5407785, lng: 18.1403707 },
    { lat: 48.5382835, lng: 18.135494 },
    { lat: 48.5378906, lng: 18.134969 },
    { lat: 48.5378585, lng: 18.1347461 },
    { lat: 48.537558, lng: 18.1340056 },
    { lat: 48.5364376, lng: 18.1350165 },
    { lat: 48.5360786, lng: 18.1354582 },
    { lat: 48.5359017, lng: 18.1355798 },
    { lat: 48.5357515, lng: 18.1358308 },
    { lat: 48.5348523, lng: 18.1339535 },
    { lat: 48.5345942, lng: 18.133263 },
    { lat: 48.5342884, lng: 18.1321023 },
    { lat: 48.5342042, lng: 18.1315715 },
    { lat: 48.5340789, lng: 18.1296339 },
    { lat: 48.534804, lng: 18.1259109 },
    { lat: 48.5328198, lng: 18.126489 },
    { lat: 48.5324813, lng: 18.1288081 },
    { lat: 48.5322889, lng: 18.1297661 },
    { lat: 48.5287892, lng: 18.1300168 },
    { lat: 48.5285738, lng: 18.1288383 },
    { lat: 48.5284127, lng: 18.1272002 },
    { lat: 48.5283602, lng: 18.1254105 },
    { lat: 48.5284132, lng: 18.1246943 },
    { lat: 48.5283799, lng: 18.1239125 },
    { lat: 48.5280061, lng: 18.122112 },
    { lat: 48.527754, lng: 18.1202677 },
    { lat: 48.5261075, lng: 18.1208885 },
    { lat: 48.5258121, lng: 18.1207581 },
    { lat: 48.5254146, lng: 18.120464 },
    { lat: 48.525357, lng: 18.1197283 },
    { lat: 48.5251737, lng: 18.1188504 },
    { lat: 48.5251799, lng: 18.1185309 },
    { lat: 48.5250454, lng: 18.1181439 },
    { lat: 48.5248805, lng: 18.1169048 },
    { lat: 48.5249071, lng: 18.115584 },
    { lat: 48.5243194, lng: 18.1156644 },
    { lat: 48.5232367, lng: 18.115987 },
    { lat: 48.5219049, lng: 18.1162749 },
    { lat: 48.519147, lng: 18.1173029 },
    { lat: 48.5182893, lng: 18.1173788 },
    { lat: 48.5172131, lng: 18.1179831 },
    { lat: 48.5164628, lng: 18.118489 },
    { lat: 48.5163654, lng: 18.118635 },
    { lat: 48.5162296, lng: 18.1191129 },
    { lat: 48.5161511, lng: 18.1199708 },
    { lat: 48.5160436, lng: 18.1202556 },
    { lat: 48.5150028, lng: 18.1203468 },
    { lat: 48.5148445, lng: 18.1201475 },
    { lat: 48.5142749, lng: 18.1198449 },
  ],
  Preseľany: [
    { lat: 48.4588065, lng: 18.1197714 },
    { lat: 48.4588463, lng: 18.119562 },
    { lat: 48.4598793, lng: 18.1067955 },
    { lat: 48.4585025, lng: 18.1060813 },
    { lat: 48.4586606, lng: 18.1024678 },
    { lat: 48.4585969, lng: 18.0988421 },
    { lat: 48.4586587, lng: 18.0988361 },
    { lat: 48.4590356, lng: 18.0946449 },
    { lat: 48.4589869, lng: 18.0944319 },
    { lat: 48.4599185, lng: 18.0838023 },
    { lat: 48.4602153, lng: 18.0798927 },
    { lat: 48.4626587, lng: 18.068585 },
    { lat: 48.4628359, lng: 18.0687178 },
    { lat: 48.4627961, lng: 18.0689454 },
    { lat: 48.4629489, lng: 18.069143 },
    { lat: 48.4642173, lng: 18.0654462 },
    { lat: 48.4647323, lng: 18.0637886 },
    { lat: 48.4665031, lng: 18.0649169 },
    { lat: 48.4669785, lng: 18.0651141 },
    { lat: 48.4683818, lng: 18.065944 },
    { lat: 48.4683305, lng: 18.0657837 },
    { lat: 48.4681567, lng: 18.0652112 },
    { lat: 48.4680433, lng: 18.0645112 },
    { lat: 48.4680195, lng: 18.0636864 },
    { lat: 48.4680566, lng: 18.0626871 },
    { lat: 48.4676849, lng: 18.0617075 },
    { lat: 48.4675362, lng: 18.0608504 },
    { lat: 48.4677095, lng: 18.0599438 },
    { lat: 48.4677465, lng: 18.059329 },
    { lat: 48.4676504, lng: 18.0584463 },
    { lat: 48.4675253, lng: 18.0579331 },
    { lat: 48.467423, lng: 18.0572295 },
    { lat: 48.4674035, lng: 18.0557551 },
    { lat: 48.4672205, lng: 18.0533452 },
    { lat: 48.4665979, lng: 18.0503735 },
    { lat: 48.4656733, lng: 18.047752 },
    { lat: 48.4648984, lng: 18.0449935 },
    { lat: 48.4647559, lng: 18.0438056 },
    { lat: 48.4648062, lng: 18.0429407 },
    { lat: 48.463051, lng: 18.0428417 },
    { lat: 48.4623159, lng: 18.0427015 },
    { lat: 48.4618327, lng: 18.0425098 },
    { lat: 48.4613019, lng: 18.0421964 },
    { lat: 48.4603638, lng: 18.0418512 },
    { lat: 48.4597835, lng: 18.0413122 },
    { lat: 48.4586428, lng: 18.0409358 },
    { lat: 48.456122, lng: 18.0398044 },
    { lat: 48.4544245, lng: 18.0416316 },
    { lat: 48.454064, lng: 18.042121 },
    { lat: 48.453972, lng: 18.042181 },
    { lat: 48.4539, lng: 18.042228 },
    { lat: 48.453755, lng: 18.042322 },
    { lat: 48.453674, lng: 18.042375 },
    { lat: 48.453588, lng: 18.042432 },
    { lat: 48.453478, lng: 18.042502 },
    { lat: 48.453475, lng: 18.042503 },
    { lat: 48.453436, lng: 18.042529 },
    { lat: 48.453433, lng: 18.042531 },
    { lat: 48.453395, lng: 18.042562 },
    { lat: 48.453367, lng: 18.042585 },
    { lat: 48.453257, lng: 18.042677 },
    { lat: 48.453155, lng: 18.042765 },
    { lat: 48.453128, lng: 18.042786 },
    { lat: 48.453099, lng: 18.042821 },
    { lat: 48.453097, lng: 18.042824 },
    { lat: 48.453094, lng: 18.042826 },
    { lat: 48.453046, lng: 18.04289 },
    { lat: 48.452975, lng: 18.042985 },
    { lat: 48.452884, lng: 18.043102 },
    { lat: 48.452808, lng: 18.04321 },
    { lat: 48.452804, lng: 18.043215 },
    { lat: 48.452802, lng: 18.043218 },
    { lat: 48.452743, lng: 18.043294 },
    { lat: 48.45266, lng: 18.043404 },
    { lat: 48.452651, lng: 18.043417 },
    { lat: 48.452576, lng: 18.043513 },
    { lat: 48.452509, lng: 18.043601 },
    { lat: 48.452498, lng: 18.043629 },
    { lat: 48.452489, lng: 18.043649 },
    { lat: 48.452427, lng: 18.043793 },
    { lat: 48.45235, lng: 18.043964 },
    { lat: 48.452281, lng: 18.044122 },
    { lat: 48.452279, lng: 18.044124 },
    { lat: 48.452201, lng: 18.044303 },
    { lat: 48.452105, lng: 18.044522 },
    { lat: 48.452057, lng: 18.044643 },
    { lat: 48.452057, lng: 18.044644 },
    { lat: 48.451998, lng: 18.044804 },
    { lat: 48.45192, lng: 18.045012 },
    { lat: 48.451851, lng: 18.0452 },
    { lat: 48.45185, lng: 18.045204 },
    { lat: 48.45179, lng: 18.045365 },
    { lat: 48.451737, lng: 18.045509 },
    { lat: 48.45171, lng: 18.045587 },
    { lat: 48.451676, lng: 18.045641 },
    { lat: 48.451589, lng: 18.045803 },
    { lat: 48.451528, lng: 18.045916 },
    { lat: 48.451478, lng: 18.046007 },
    { lat: 48.451476, lng: 18.046066 },
    { lat: 48.451473, lng: 18.046128 },
    { lat: 48.451465, lng: 18.046271 },
    { lat: 48.451459, lng: 18.046396 },
    { lat: 48.451454, lng: 18.046474 },
    { lat: 48.451442, lng: 18.046684 },
    { lat: 48.451438, lng: 18.046768 },
    { lat: 48.4514, lng: 18.046822 },
    { lat: 48.451383, lng: 18.046845 },
    { lat: 48.451278, lng: 18.046983 },
    { lat: 48.451265, lng: 18.047003 },
    { lat: 48.451249, lng: 18.047074 },
    { lat: 48.451239, lng: 18.047132 },
    { lat: 48.451221, lng: 18.04723 },
    { lat: 48.451203, lng: 18.047327 },
    { lat: 48.451196, lng: 18.047438 },
    { lat: 48.451191, lng: 18.047502 },
    { lat: 48.451191, lng: 18.047504 },
    { lat: 48.451173, lng: 18.0477 },
    { lat: 48.451156, lng: 18.047869 },
    { lat: 48.451156, lng: 18.04787 },
    { lat: 48.451115, lng: 18.048239 },
    { lat: 48.451113, lng: 18.04824 },
    { lat: 48.451087, lng: 18.048479 },
    { lat: 48.451064, lng: 18.048603 },
    { lat: 48.451064, lng: 18.048604 },
    { lat: 48.450991, lng: 18.048954 },
    { lat: 48.450938, lng: 18.049226 },
    { lat: 48.450918, lng: 18.049318 },
    { lat: 48.450917, lng: 18.04932 },
    { lat: 48.450901, lng: 18.049412 },
    { lat: 48.450808, lng: 18.049391 },
    { lat: 48.45072, lng: 18.049367 },
    { lat: 48.450713, lng: 18.049576 },
    { lat: 48.450709, lng: 18.049717 },
    { lat: 48.450708, lng: 18.049721 },
    { lat: 48.450701, lng: 18.049953 },
    { lat: 48.450695, lng: 18.050128 },
    { lat: 48.450694, lng: 18.050131 },
    { lat: 48.450685, lng: 18.050377 },
    { lat: 48.450677, lng: 18.050596 },
    { lat: 48.450665, lng: 18.050969 },
    { lat: 48.450651, lng: 18.051082 },
    { lat: 48.450625, lng: 18.05126 },
    { lat: 48.450612, lng: 18.051352 },
    { lat: 48.450578, lng: 18.051557 },
    { lat: 48.450533, lng: 18.051837 },
    { lat: 48.450504, lng: 18.052008 },
    { lat: 48.450458, lng: 18.052308 },
    { lat: 48.450437, lng: 18.052438 },
    { lat: 48.450407, lng: 18.052661 },
    { lat: 48.450398, lng: 18.052739 },
    { lat: 48.450398, lng: 18.052741 },
    { lat: 48.450389, lng: 18.052857 },
    { lat: 48.450389, lng: 18.052859 },
    { lat: 48.450385, lng: 18.052907 },
    { lat: 48.45038, lng: 18.052968 },
    { lat: 48.45038, lng: 18.05302 },
    { lat: 48.450382, lng: 18.053076 },
    { lat: 48.450384, lng: 18.053115 },
    { lat: 48.450386, lng: 18.053177 },
    { lat: 48.450387, lng: 18.053206 },
    { lat: 48.450421, lng: 18.053423 },
    { lat: 48.450424, lng: 18.053434 },
    { lat: 48.450459, lng: 18.053647 },
    { lat: 48.450514, lng: 18.053989 },
    { lat: 48.450544, lng: 18.054161 },
    { lat: 48.450544, lng: 18.054166 },
    { lat: 48.450554, lng: 18.05422 },
    { lat: 48.450564, lng: 18.054281 },
    { lat: 48.450567, lng: 18.054447 },
    { lat: 48.450571, lng: 18.054605 },
    { lat: 48.450572, lng: 18.054706 },
    { lat: 48.450572, lng: 18.054707 },
    { lat: 48.450583, lng: 18.054898 },
    { lat: 48.450595, lng: 18.055148 },
    { lat: 48.45059, lng: 18.055207 },
    { lat: 48.450557, lng: 18.055446 },
    { lat: 48.450531, lng: 18.055645 },
    { lat: 48.450507, lng: 18.055864 },
    { lat: 48.450485, lng: 18.056054 },
    { lat: 48.45044, lng: 18.056269 },
    { lat: 48.450403, lng: 18.056444 },
    { lat: 48.450379, lng: 18.056563 },
    { lat: 48.450349, lng: 18.056712 },
    { lat: 48.450312, lng: 18.056859 },
    { lat: 48.450261, lng: 18.057068 },
    { lat: 48.450231, lng: 18.057218 },
    { lat: 48.450213, lng: 18.05731 },
    { lat: 48.450186, lng: 18.057445 },
    { lat: 48.450154, lng: 18.057606 },
    { lat: 48.450148, lng: 18.057647 },
    { lat: 48.450133, lng: 18.057826 },
    { lat: 48.450123, lng: 18.057936 },
    { lat: 48.450109, lng: 18.058103 },
    { lat: 48.450106, lng: 18.058138 },
    { lat: 48.450095, lng: 18.058271 },
    { lat: 48.450078, lng: 18.058454 },
    { lat: 48.450075, lng: 18.058493 },
    { lat: 48.450057, lng: 18.058717 },
    { lat: 48.450056, lng: 18.058718 },
    { lat: 48.450026, lng: 18.059043 },
    { lat: 48.450017, lng: 18.05915 },
    { lat: 48.450015, lng: 18.059195 },
    { lat: 48.449876, lng: 18.059193 },
    { lat: 48.449722, lng: 18.059187 },
    { lat: 48.449644, lng: 18.059184 },
    { lat: 48.449584, lng: 18.059181 },
    { lat: 48.449445, lng: 18.059176 },
    { lat: 48.449426, lng: 18.059176 },
    { lat: 48.449335, lng: 18.059142 },
    { lat: 48.449253, lng: 18.059112 },
    { lat: 48.449133, lng: 18.059066 },
    { lat: 48.449088, lng: 18.059057 },
    { lat: 48.449001, lng: 18.059037 },
    { lat: 48.448928, lng: 18.05902 },
    { lat: 48.448845, lng: 18.059 },
    { lat: 48.448769, lng: 18.058985 },
    { lat: 48.448722, lng: 18.058971 },
    { lat: 48.44867, lng: 18.058956 },
    { lat: 48.448556, lng: 18.058922 },
    { lat: 48.448492, lng: 18.058902 },
    { lat: 48.448372, lng: 18.058877 },
    { lat: 48.448269, lng: 18.058853 },
    { lat: 48.448169, lng: 18.058822 },
    { lat: 48.448079, lng: 18.058793 },
    { lat: 48.447993, lng: 18.058766 },
    { lat: 48.447891, lng: 18.058731 },
    { lat: 48.447772, lng: 18.058689 },
    { lat: 48.447667, lng: 18.05869 },
    { lat: 48.447627, lng: 18.058688 },
    { lat: 48.447546, lng: 18.058661 },
    { lat: 48.447445, lng: 18.05863 },
    { lat: 48.447355, lng: 18.058596 },
    { lat: 48.447248, lng: 18.058556 },
    { lat: 48.447152, lng: 18.058516 },
    { lat: 48.447066, lng: 18.058477 },
    { lat: 48.446976, lng: 18.058437 },
    { lat: 48.446877, lng: 18.058392 },
    { lat: 48.44682, lng: 18.058368 },
    { lat: 48.446723, lng: 18.058377 },
    { lat: 48.446654, lng: 18.058384 },
    { lat: 48.446574, lng: 18.058431 },
    { lat: 48.446453, lng: 18.0585 },
    { lat: 48.446446, lng: 18.058504 },
    { lat: 48.446394, lng: 18.058501 },
    { lat: 48.446256, lng: 18.058492 },
    { lat: 48.446157, lng: 18.058488 },
    { lat: 48.446111, lng: 18.05847 },
    { lat: 48.445988, lng: 18.058426 },
    { lat: 48.445891, lng: 18.058392 },
    { lat: 48.44577, lng: 18.058338 },
    { lat: 48.445668, lng: 18.058294 },
    { lat: 48.445518, lng: 18.058224 },
    { lat: 48.445427, lng: 18.05818 },
    { lat: 48.445377, lng: 18.058159 },
    { lat: 48.445275, lng: 18.058101 },
    { lat: 48.445178, lng: 18.058047 },
    { lat: 48.445018, lng: 18.057957 },
    { lat: 48.444921, lng: 18.057899 },
    { lat: 48.444876, lng: 18.05787 },
    { lat: 48.444786, lng: 18.05779 },
    { lat: 48.444675, lng: 18.057697 },
    { lat: 48.444641, lng: 18.057673 },
    { lat: 48.444565, lng: 18.057619 },
    { lat: 48.444525, lng: 18.05759 },
    { lat: 48.444429, lng: 18.057521 },
    { lat: 48.444297, lng: 18.057429 },
    { lat: 48.444197, lng: 18.057366 },
    { lat: 48.444057, lng: 18.057286 },
    { lat: 48.443945, lng: 18.057223 },
    { lat: 48.443907, lng: 18.057204 },
    { lat: 48.443769, lng: 18.057151 },
    { lat: 48.443567, lng: 18.057067 },
    { lat: 48.44314, lng: 18.056893 },
    { lat: 48.443113, lng: 18.056869 },
    { lat: 48.4431109, lng: 18.056916 },
    { lat: 48.4432596, lng: 18.0578837 },
    { lat: 48.4438176, lng: 18.0594227 },
    { lat: 48.4436654, lng: 18.0595564 },
    { lat: 48.4438586, lng: 18.0608824 },
    { lat: 48.4440173, lng: 18.0626884 },
    { lat: 48.4439885, lng: 18.0642495 },
    { lat: 48.4451466, lng: 18.0643247 },
    { lat: 48.4456629, lng: 18.0644884 },
    { lat: 48.4459136, lng: 18.064033 },
    { lat: 48.446339, lng: 18.0635912 },
    { lat: 48.4464256, lng: 18.0632786 },
    { lat: 48.4479526, lng: 18.0638144 },
    { lat: 48.4480226, lng: 18.0646767 },
    { lat: 48.4480211, lng: 18.0651273 },
    { lat: 48.4479013, lng: 18.0661202 },
    { lat: 48.4477405, lng: 18.0665526 },
    { lat: 48.4475629, lng: 18.0668137 },
    { lat: 48.4472801, lng: 18.067412 },
    { lat: 48.4470522, lng: 18.0681427 },
    { lat: 48.4471538, lng: 18.0682184 },
    { lat: 48.4471821, lng: 18.0684322 },
    { lat: 48.4471529, lng: 18.0689682 },
    { lat: 48.4470898, lng: 18.0692582 },
    { lat: 48.4467069, lng: 18.0701169 },
    { lat: 48.4465861, lng: 18.0702663 },
    { lat: 48.4465152, lng: 18.0701545 },
    { lat: 48.446366, lng: 18.0704595 },
    { lat: 48.4462809, lng: 18.0709852 },
    { lat: 48.4460795, lng: 18.0717258 },
    { lat: 48.445749, lng: 18.0724943 },
    { lat: 48.4456251, lng: 18.0731286 },
    { lat: 48.4455542, lng: 18.0737826 },
    { lat: 48.4455486, lng: 18.0753534 },
    { lat: 48.4456115, lng: 18.0755989 },
    { lat: 48.4450906, lng: 18.0794035 },
    { lat: 48.4474428, lng: 18.0801911 },
    { lat: 48.4459027, lng: 18.090426 },
    { lat: 48.4455106, lng: 18.0929695 },
    { lat: 48.4454017, lng: 18.0934016 },
    { lat: 48.4448504, lng: 18.0970803 },
    { lat: 48.4451769, lng: 18.0972395 },
    { lat: 48.4451961, lng: 18.0975303 },
    { lat: 48.4444531, lng: 18.10287 },
    { lat: 48.4437472, lng: 18.1041284 },
    { lat: 48.4433302, lng: 18.1045064 },
    { lat: 48.4431501, lng: 18.1045527 },
    { lat: 48.4429663, lng: 18.1045142 },
    { lat: 48.4428488, lng: 18.1044149 },
    { lat: 48.4426281, lng: 18.1040589 },
    { lat: 48.4425608, lng: 18.1036619 },
    { lat: 48.442426, lng: 18.1037093 },
    { lat: 48.4413596, lng: 18.1089109 },
    { lat: 48.4400905, lng: 18.1144053 },
    { lat: 48.4396515, lng: 18.1140405 },
    { lat: 48.4391202, lng: 18.114602 },
    { lat: 48.4388102, lng: 18.1147037 },
    { lat: 48.4385047, lng: 18.1145761 },
    { lat: 48.4383494, lng: 18.114678 },
    { lat: 48.4382397, lng: 18.1148325 },
    { lat: 48.4379779, lng: 18.114928 },
    { lat: 48.4378854, lng: 18.1154491 },
    { lat: 48.4375269, lng: 18.1156266 },
    { lat: 48.4374081, lng: 18.1157589 },
    { lat: 48.4373299, lng: 18.1159459 },
    { lat: 48.4371994, lng: 18.1166036 },
    { lat: 48.4369746, lng: 18.1167961 },
    { lat: 48.4368464, lng: 18.1172068 },
    { lat: 48.4367754, lng: 18.1172674 },
    { lat: 48.4357801, lng: 18.1171904 },
    { lat: 48.4355076, lng: 18.1173363 },
    { lat: 48.4353565, lng: 18.1176264 },
    { lat: 48.4352186, lng: 18.1177395 },
    { lat: 48.4348012, lng: 18.1175125 },
    { lat: 48.4343358, lng: 18.1175048 },
    { lat: 48.4340816, lng: 18.1176901 },
    { lat: 48.4337048, lng: 18.1178462 },
    { lat: 48.433383, lng: 18.118421 },
    { lat: 48.433401, lng: 18.118448 },
    { lat: 48.437448, lng: 18.124347 },
    { lat: 48.437565, lng: 18.124518 },
    { lat: 48.440702, lng: 18.12908 },
    { lat: 48.440772, lng: 18.129182 },
    { lat: 48.444389, lng: 18.130759 },
    { lat: 48.447102, lng: 18.131243 },
    { lat: 48.447349, lng: 18.131418 },
    { lat: 48.44768, lng: 18.13138 },
    { lat: 48.4530605, lng: 18.1274204 },
    { lat: 48.4575064, lng: 18.117013 },
    { lat: 48.4578012, lng: 18.1174607 },
    { lat: 48.4578484, lng: 18.1176127 },
    { lat: 48.457863, lng: 18.1179592 },
    { lat: 48.4578217, lng: 18.1182708 },
    { lat: 48.4576965, lng: 18.1186781 },
    { lat: 48.4573775, lng: 18.1195338 },
    { lat: 48.4573722, lng: 18.1198687 },
    { lat: 48.4574397, lng: 18.1200965 },
    { lat: 48.4576757, lng: 18.120428 },
    { lat: 48.4580889, lng: 18.1204686 },
    { lat: 48.4586396, lng: 18.1199268 },
    { lat: 48.4588065, lng: 18.1197714 },
  ],
  Kamanová: [
    { lat: 48.4722477, lng: 18.1270525 },
    { lat: 48.4728154, lng: 18.1245822 },
    { lat: 48.4728336, lng: 18.1238895 },
    { lat: 48.4731046, lng: 18.1226964 },
    { lat: 48.4731087, lng: 18.1225731 },
    { lat: 48.4728621, lng: 18.1216199 },
    { lat: 48.4725871, lng: 18.1202738 },
    { lat: 48.4733494, lng: 18.1182781 },
    { lat: 48.4734358, lng: 18.1176321 },
    { lat: 48.4734553, lng: 18.1169225 },
    { lat: 48.4733416, lng: 18.1166776 },
    { lat: 48.4733757, lng: 18.1164892 },
    { lat: 48.4742705, lng: 18.1146739 },
    { lat: 48.4745724, lng: 18.1134687 },
    { lat: 48.4752643, lng: 18.1115614 },
    { lat: 48.4754087, lng: 18.1116771 },
    { lat: 48.4752864, lng: 18.1104765 },
    { lat: 48.4755242, lng: 18.1105268 },
    { lat: 48.4755243, lng: 18.1097582 },
    { lat: 48.4753049, lng: 18.1095205 },
    { lat: 48.4752847, lng: 18.1093094 },
    { lat: 48.4753226, lng: 18.1090508 },
    { lat: 48.4755741, lng: 18.1087788 },
    { lat: 48.4753937, lng: 18.1084521 },
    { lat: 48.4758965, lng: 18.10623 },
    { lat: 48.4760859, lng: 18.1049376 },
    { lat: 48.4768762, lng: 18.1009768 },
    { lat: 48.4768795, lng: 18.1007832 },
    { lat: 48.4778294, lng: 18.0967961 },
    { lat: 48.4785641, lng: 18.0930846 },
    { lat: 48.479111, lng: 18.0866822 },
    { lat: 48.4793949, lng: 18.085328 },
    { lat: 48.479946, lng: 18.0834544 },
    { lat: 48.4801813, lng: 18.0828479 },
    { lat: 48.4802594, lng: 18.0828165 },
    { lat: 48.4812792, lng: 18.0803127 },
    { lat: 48.4826658, lng: 18.0774106 },
    { lat: 48.4833532, lng: 18.0762035 },
    { lat: 48.4849379, lng: 18.0725838 },
    { lat: 48.4846423, lng: 18.0723159 },
    { lat: 48.4843612, lng: 18.0729468 },
    { lat: 48.484391, lng: 18.07257 },
    { lat: 48.4844349, lng: 18.0723037 },
    { lat: 48.4850836, lng: 18.071051 },
    { lat: 48.4855158, lng: 18.069057 },
    { lat: 48.4855395, lng: 18.0690229 },
    { lat: 48.48381, lng: 18.0688583 },
    { lat: 48.4809971, lng: 18.0678739 },
    { lat: 48.4806075, lng: 18.0678893 },
    { lat: 48.4795129, lng: 18.0675863 },
    { lat: 48.4785694, lng: 18.0675674 },
    { lat: 48.4783511, lng: 18.0675962 },
    { lat: 48.477441, lng: 18.0677229 },
    { lat: 48.4767209, lng: 18.0680694 },
    { lat: 48.4753628, lng: 18.068444 },
    { lat: 48.4749972, lng: 18.0686375 },
    { lat: 48.4731658, lng: 18.0688497 },
    { lat: 48.4723126, lng: 18.0690805 },
    { lat: 48.4719221, lng: 18.0690632 },
    { lat: 48.4713948, lng: 18.0688842 },
    { lat: 48.4708804, lng: 18.0689688 },
    { lat: 48.4698739, lng: 18.0688559 },
    { lat: 48.4695888, lng: 18.0687666 },
    { lat: 48.4691711, lng: 18.0681921 },
    { lat: 48.4687332, lng: 18.0673301 },
    { lat: 48.4685307, lng: 18.0662681 },
    { lat: 48.4685078, lng: 18.0661477 },
    { lat: 48.4683818, lng: 18.065944 },
    { lat: 48.4669785, lng: 18.0651141 },
    { lat: 48.4665031, lng: 18.0649169 },
    { lat: 48.4665145, lng: 18.0649588 },
    { lat: 48.4664974, lng: 18.0671552 },
    { lat: 48.4659404, lng: 18.0672228 },
    { lat: 48.4658735, lng: 18.0690557 },
    { lat: 48.4658736, lng: 18.0710112 },
    { lat: 48.4657126, lng: 18.0720117 },
    { lat: 48.4656689, lng: 18.0726114 },
    { lat: 48.4657707, lng: 18.072633 },
    { lat: 48.4657672, lng: 18.0727096 },
    { lat: 48.4654334, lng: 18.0751456 },
    { lat: 48.465338, lng: 18.0786458 },
    { lat: 48.4652953, lng: 18.0788567 },
    { lat: 48.4652525, lng: 18.0801468 },
    { lat: 48.4652728, lng: 18.0807974 },
    { lat: 48.4653902, lng: 18.0820054 },
    { lat: 48.4655895, lng: 18.0832068 },
    { lat: 48.4655556, lng: 18.0844147 },
    { lat: 48.4650665, lng: 18.0886709 },
    { lat: 48.4644103, lng: 18.0932725 },
    { lat: 48.4642285, lng: 18.0951895 },
    { lat: 48.4640856, lng: 18.0973879 },
    { lat: 48.4640049, lng: 18.0991286 },
    { lat: 48.4641953, lng: 18.1019506 },
    { lat: 48.4642853, lng: 18.1021019 },
    { lat: 48.4643024, lng: 18.1024299 },
    { lat: 48.464267, lng: 18.102999 },
    { lat: 48.4641945, lng: 18.1033226 },
    { lat: 48.464179, lng: 18.104753 },
    { lat: 48.4641573, lng: 18.1049995 },
    { lat: 48.4639946, lng: 18.1052654 },
    { lat: 48.4638006, lng: 18.1061118 },
    { lat: 48.4636401, lng: 18.1065889 },
    { lat: 48.4636244, lng: 18.1088636 },
    { lat: 48.4633899, lng: 18.111622 },
    { lat: 48.4625746, lng: 18.1164332 },
    { lat: 48.4623682, lng: 18.116377 },
    { lat: 48.4622054, lng: 18.1193982 },
    { lat: 48.4617212, lng: 18.1213091 },
    { lat: 48.4619658, lng: 18.1217866 },
    { lat: 48.4621316, lng: 18.1226885 },
    { lat: 48.4622637, lng: 18.1237352 },
    { lat: 48.462259, lng: 18.1243862 },
    { lat: 48.4621983, lng: 18.1246797 },
    { lat: 48.4622271, lng: 18.125002 },
    { lat: 48.4622902, lng: 18.1252123 },
    { lat: 48.4624783, lng: 18.1253798 },
    { lat: 48.4625986, lng: 18.1253494 },
    { lat: 48.4626767, lng: 18.125237 },
    { lat: 48.4627985, lng: 18.1248898 },
    { lat: 48.4627119, lng: 18.1238897 },
    { lat: 48.4629372, lng: 18.1225535 },
    { lat: 48.4630806, lng: 18.1222639 },
    { lat: 48.4632741, lng: 18.1221199 },
    { lat: 48.4635235, lng: 18.1220987 },
    { lat: 48.4637232, lng: 18.1222131 },
    { lat: 48.4643865, lng: 18.1228671 },
    { lat: 48.4646358, lng: 18.1229049 },
    { lat: 48.4648344, lng: 18.1228079 },
    { lat: 48.4651714, lng: 18.1225681 },
    { lat: 48.4652837, lng: 18.1224021 },
    { lat: 48.4656573, lng: 18.1217311 },
    { lat: 48.4659505, lng: 18.1210306 },
    { lat: 48.4661901, lng: 18.120671 },
    { lat: 48.4664079, lng: 18.1206106 },
    { lat: 48.4666236, lng: 18.1207691 },
    { lat: 48.4668374, lng: 18.1217432 },
    { lat: 48.4672543, lng: 18.1226771 },
    { lat: 48.4675162, lng: 18.12295 },
    { lat: 48.4681797, lng: 18.1233128 },
    { lat: 48.4685823, lng: 18.123772 },
    { lat: 48.4687478, lng: 18.1241517 },
    { lat: 48.4689888, lng: 18.1251874 },
    { lat: 48.4692518, lng: 18.1255719 },
    { lat: 48.4696399, lng: 18.125937 },
    { lat: 48.4703823, lng: 18.1264922 },
    { lat: 48.4711654, lng: 18.1272635 },
    { lat: 48.4716256, lng: 18.1274168 },
    { lat: 48.4719055, lng: 18.1272614 },
    { lat: 48.4722477, lng: 18.1270525 },
  ],
  Čeľadince: [
    { lat: 48.5218883, lng: 18.1566986 },
    { lat: 48.5221931, lng: 18.1564285 },
    { lat: 48.5224876, lng: 18.1561779 },
    { lat: 48.522801, lng: 18.1557653 },
    { lat: 48.5228709, lng: 18.1550071 },
    { lat: 48.5227514, lng: 18.1548142 },
    { lat: 48.5226713, lng: 18.1544442 },
    { lat: 48.5222252, lng: 18.1541655 },
    { lat: 48.5216391, lng: 18.1540822 },
    { lat: 48.5207182, lng: 18.1541584 },
    { lat: 48.5197404, lng: 18.1544396 },
    { lat: 48.5189778, lng: 18.1549323 },
    { lat: 48.5178399, lng: 18.1561298 },
    { lat: 48.517544, lng: 18.1562005 },
    { lat: 48.5170385, lng: 18.1559499 },
    { lat: 48.5166142, lng: 18.1553673 },
    { lat: 48.5158099, lng: 18.1536657 },
    { lat: 48.5154985, lng: 18.1531227 },
    { lat: 48.5149328, lng: 18.1526701 },
    { lat: 48.5142055, lng: 18.1524691 },
    { lat: 48.5137236, lng: 18.1522165 },
    { lat: 48.5133826, lng: 18.1518429 },
    { lat: 48.5132543, lng: 18.1509757 },
    { lat: 48.5133133, lng: 18.1503436 },
    { lat: 48.513411, lng: 18.1498477 },
    { lat: 48.5136972, lng: 18.1493576 },
    { lat: 48.5138214, lng: 18.1489888 },
    { lat: 48.513857, lng: 18.1488066 },
    { lat: 48.513838, lng: 18.1485955 },
    { lat: 48.5137254, lng: 18.1483089 },
    { lat: 48.5135946, lng: 18.1481951 },
    { lat: 48.5129059, lng: 18.1479738 },
    { lat: 48.5127673, lng: 18.1479782 },
    { lat: 48.5122143, lng: 18.1483665 },
    { lat: 48.5115635, lng: 18.1495354 },
    { lat: 48.5113644, lng: 18.1500524 },
    { lat: 48.5113295, lng: 18.1505578 },
    { lat: 48.5114044, lng: 18.1509924 },
    { lat: 48.5117397, lng: 18.1520203 },
    { lat: 48.5118178, lng: 18.152725 },
    { lat: 48.5117992, lng: 18.1530834 },
    { lat: 48.5118133, lng: 18.1534201 },
    { lat: 48.5116786, lng: 18.1533769 },
    { lat: 48.5113233, lng: 18.1535945 },
    { lat: 48.5110395, lng: 18.1535654 },
    { lat: 48.5106492, lng: 18.1534129 },
    { lat: 48.5099213, lng: 18.1554865 },
    { lat: 48.509046, lng: 18.1596868 },
    { lat: 48.5088166, lng: 18.160691 },
    { lat: 48.5083338, lng: 18.162424 },
    { lat: 48.5083535, lng: 18.1625709 },
    { lat: 48.5082914, lng: 18.1629763 },
    { lat: 48.5078793, lng: 18.1647031 },
    { lat: 48.5068233, lng: 18.1680335 },
    { lat: 48.5050601, lng: 18.1739174 },
    { lat: 48.5038583, lng: 18.1777522 },
    { lat: 48.5024999, lng: 18.1823528 },
    { lat: 48.50213, lng: 18.183741 },
    { lat: 48.501948, lng: 18.1851803 },
    { lat: 48.5016736, lng: 18.1867552 },
    { lat: 48.5012919, lng: 18.188073 },
    { lat: 48.5009662, lng: 18.1896421 },
    { lat: 48.501282, lng: 18.1893456 },
    { lat: 48.5021189, lng: 18.1887161 },
    { lat: 48.5025314, lng: 18.1889425 },
    { lat: 48.5023738, lng: 18.189435 },
    { lat: 48.5023525, lng: 18.1896922 },
    { lat: 48.5031938, lng: 18.1901299 },
    { lat: 48.5038825, lng: 18.1894522 },
    { lat: 48.5076866, lng: 18.1905499 },
    { lat: 48.5080089, lng: 18.1907955 },
    { lat: 48.5094064, lng: 18.1923039 },
    { lat: 48.5102729, lng: 18.1896107 },
    { lat: 48.5098912, lng: 18.1893884 },
    { lat: 48.5154518, lng: 18.1736012 },
    { lat: 48.5164641, lng: 18.1711849 },
    { lat: 48.5172076, lng: 18.1724715 },
    { lat: 48.5178988, lng: 18.1706315 },
    { lat: 48.5184575, lng: 18.1689388 },
    { lat: 48.5190029, lng: 18.1670207 },
    { lat: 48.5197676, lng: 18.1636716 },
    { lat: 48.5191431, lng: 18.1631547 },
    { lat: 48.5197947, lng: 18.1619587 },
    { lat: 48.5196275, lng: 18.1617796 },
    { lat: 48.5210845, lng: 18.1591223 },
    { lat: 48.521055, lng: 18.1590026 },
    { lat: 48.52115, lng: 18.1582283 },
    { lat: 48.5214501, lng: 18.1573943 },
    { lat: 48.5217713, lng: 18.1567852 },
    { lat: 48.5218883, lng: 18.1566986 },
  ],
  VeľkéRipňany: [
    { lat: 48.5130448, lng: 17.9381528 },
    { lat: 48.5129918, lng: 17.9383601 },
    { lat: 48.5126103, lng: 17.9390339 },
    { lat: 48.512024, lng: 17.9396662 },
    { lat: 48.5117597, lng: 17.9398136 },
    { lat: 48.5111652, lng: 17.9399699 },
    { lat: 48.5105873, lng: 17.9402721 },
    { lat: 48.5100337, lng: 17.9408461 },
    { lat: 48.5100044, lng: 17.9408109 },
    { lat: 48.5091717, lng: 17.9399142 },
    { lat: 48.5065912, lng: 17.9366774 },
    { lat: 48.503936, lng: 17.9329717 },
    { lat: 48.5027599, lng: 17.9312278 },
    { lat: 48.5001806, lng: 17.9276781 },
    { lat: 48.4981989, lng: 17.9255677 },
    { lat: 48.4967806, lng: 17.9242609 },
    { lat: 48.4968774, lng: 17.9236768 },
    { lat: 48.4966378, lng: 17.9233347 },
    { lat: 48.4952051, lng: 17.9207619 },
    { lat: 48.4950558, lng: 17.9193872 },
    { lat: 48.4948401, lng: 17.918245 },
    { lat: 48.4944973, lng: 17.917875 },
    { lat: 48.4941555, lng: 17.9177095 },
    { lat: 48.4939441, lng: 17.9174992 },
    { lat: 48.4936018, lng: 17.9169687 },
    { lat: 48.4934719, lng: 17.9166697 },
    { lat: 48.4934235, lng: 17.9159403 },
    { lat: 48.493257, lng: 17.915405 },
    { lat: 48.493213, lng: 17.915496 },
    { lat: 48.49318, lng: 17.915556 },
    { lat: 48.492955, lng: 17.915833 },
    { lat: 48.492736, lng: 17.916034 },
    { lat: 48.492626, lng: 17.916101 },
    { lat: 48.492487, lng: 17.91622 },
    { lat: 48.492416, lng: 17.916281 },
    { lat: 48.492184, lng: 17.916476 },
    { lat: 48.492041, lng: 17.916589 },
    { lat: 48.491988, lng: 17.916629 },
    { lat: 48.491881, lng: 17.916704 },
    { lat: 48.491585, lng: 17.916883 },
    { lat: 48.491287, lng: 17.917063 },
    { lat: 48.491014, lng: 17.917226 },
    { lat: 48.490972, lng: 17.917257 },
    { lat: 48.490709, lng: 17.917443 },
    { lat: 48.490682, lng: 17.917462 },
    { lat: 48.490445, lng: 17.917665 },
    { lat: 48.490316, lng: 17.917775 },
    { lat: 48.490196, lng: 17.917878 },
    { lat: 48.490127, lng: 17.917937 },
    { lat: 48.489937, lng: 17.918061 },
    { lat: 48.489686, lng: 17.918224 },
    { lat: 48.48967, lng: 17.918235 },
    { lat: 48.489422, lng: 17.918441 },
    { lat: 48.489365, lng: 17.918488 },
    { lat: 48.489075, lng: 17.918788 },
    { lat: 48.488963, lng: 17.918928 },
    { lat: 48.488733, lng: 17.919214 },
    { lat: 48.488615, lng: 17.919371 },
    { lat: 48.488131, lng: 17.919997 },
    { lat: 48.488115, lng: 17.920016 },
    { lat: 48.488059, lng: 17.920136 },
    { lat: 48.487982, lng: 17.920316 },
    { lat: 48.487917, lng: 17.920493 },
    { lat: 48.487807, lng: 17.920794 },
    { lat: 48.487645, lng: 17.921258 },
    { lat: 48.487459, lng: 17.921774 },
    { lat: 48.487174, lng: 17.922529 },
    { lat: 48.487015, lng: 17.92291 },
    { lat: 48.486839, lng: 17.923304 },
    { lat: 48.486706, lng: 17.923511 },
    { lat: 48.486535, lng: 17.923775 },
    { lat: 48.486421, lng: 17.923936 },
    { lat: 48.486293, lng: 17.924137 },
    { lat: 48.485784, lng: 17.924803 },
    { lat: 48.48549, lng: 17.92522 },
    { lat: 48.485181, lng: 17.925737 },
    { lat: 48.484589, lng: 17.926441 },
    { lat: 48.484455, lng: 17.926612 },
    { lat: 48.483962, lng: 17.927264 },
    { lat: 48.483589, lng: 17.927805 },
    { lat: 48.482837, lng: 17.929141 },
    { lat: 48.482758, lng: 17.929273 },
    { lat: 48.482448, lng: 17.929787 },
    { lat: 48.482181, lng: 17.930184 },
    { lat: 48.482028, lng: 17.930419 },
    { lat: 48.481999, lng: 17.930459 },
    { lat: 48.481794, lng: 17.930748 },
    { lat: 48.481673, lng: 17.930917 },
    { lat: 48.481611, lng: 17.931001 },
    { lat: 48.481486, lng: 17.931168 },
    { lat: 48.481268, lng: 17.931505 },
    { lat: 48.480987, lng: 17.931945 },
    { lat: 48.480803, lng: 17.9322 },
    { lat: 48.480496, lng: 17.932594 },
    { lat: 48.4804859, lng: 17.9326057 },
    { lat: 48.480154, lng: 17.932992 },
    { lat: 48.479969, lng: 17.933168 },
    { lat: 48.479722, lng: 17.933312 },
    { lat: 48.47945, lng: 17.933428 },
    { lat: 48.479275, lng: 17.933485 },
    { lat: 48.478868, lng: 17.933682 },
    { lat: 48.478903, lng: 17.933731 },
    { lat: 48.47954, lng: 17.934731 },
    { lat: 48.479267, lng: 17.935055 },
    { lat: 48.479, lng: 17.935373 },
    { lat: 48.478848, lng: 17.93558 },
    { lat: 48.47848, lng: 17.936112 },
    { lat: 48.477971, lng: 17.936847 },
    { lat: 48.47795, lng: 17.936876 },
    { lat: 48.477555, lng: 17.937453 },
    { lat: 48.477326, lng: 17.937792 },
    { lat: 48.477121, lng: 17.938097 },
    { lat: 48.476989, lng: 17.938141 },
    { lat: 48.476461, lng: 17.938307 },
    { lat: 48.476311, lng: 17.938356 },
    { lat: 48.475812, lng: 17.93947 },
    { lat: 48.47554, lng: 17.940076 },
    { lat: 48.475172, lng: 17.940903 },
    { lat: 48.475083, lng: 17.941164 },
    { lat: 48.475236, lng: 17.941353 },
    { lat: 48.475294, lng: 17.941416 },
    { lat: 48.475636, lng: 17.941758 },
    { lat: 48.4761128, lng: 17.9420222 },
    { lat: 48.4855663, lng: 17.9473083 },
    { lat: 48.4847785, lng: 17.9487005 },
    { lat: 48.4846683, lng: 17.9493078 },
    { lat: 48.4849659, lng: 17.9489465 },
    { lat: 48.4854662, lng: 17.9495144 },
    { lat: 48.4862461, lng: 17.9496071 },
    { lat: 48.4861605, lng: 17.950215 },
    { lat: 48.4864675, lng: 17.9502492 },
    { lat: 48.4864272, lng: 17.9505752 },
    { lat: 48.4865141, lng: 17.9506332 },
    { lat: 48.4870159, lng: 17.9507575 },
    { lat: 48.4842387, lng: 17.9681218 },
    { lat: 48.4845335, lng: 17.9682955 },
    { lat: 48.4848024, lng: 17.9684315 },
    { lat: 48.4839973, lng: 17.9827089 },
    { lat: 48.4838866, lng: 17.9838976 },
    { lat: 48.4835895, lng: 17.9838371 },
    { lat: 48.4820507, lng: 17.9838492 },
    { lat: 48.4812836, lng: 17.9841208 },
    { lat: 48.4816527, lng: 17.984516 },
    { lat: 48.4805752, lng: 17.9861895 },
    { lat: 48.4807286, lng: 17.9887805 },
    { lat: 48.4796709, lng: 17.9887969 },
    { lat: 48.4797242, lng: 17.991734 },
    { lat: 48.48016, lng: 17.9919662 },
    { lat: 48.4803976, lng: 17.9919164 },
    { lat: 48.4808586, lng: 17.9916599 },
    { lat: 48.4811195, lng: 17.9918137 },
    { lat: 48.4814664, lng: 17.9918416 },
    { lat: 48.4827935, lng: 17.9914003 },
    { lat: 48.4831703, lng: 17.9915679 },
    { lat: 48.4839138, lng: 17.9920938 },
    { lat: 48.4840337, lng: 17.9920413 },
    { lat: 48.4840966, lng: 17.9920937 },
    { lat: 48.4842943, lng: 17.9920766 },
    { lat: 48.4843984, lng: 17.9921561 },
    { lat: 48.4845162, lng: 17.9921253 },
    { lat: 48.4845209, lng: 17.9920339 },
    { lat: 48.4851236, lng: 17.9919594 },
    { lat: 48.4852155, lng: 17.9921751 },
    { lat: 48.4854734, lng: 17.9925423 },
    { lat: 48.4858692, lng: 17.9927622 },
    { lat: 48.4861013, lng: 17.9929657 },
    { lat: 48.4863695, lng: 17.9939617 },
    { lat: 48.4863367, lng: 17.9940544 },
    { lat: 48.486657, lng: 17.9943929 },
    { lat: 48.4868792, lng: 17.994427 },
    { lat: 48.4869463, lng: 17.9945877 },
    { lat: 48.4869108, lng: 17.9947693 },
    { lat: 48.4869443, lng: 17.9948555 },
    { lat: 48.4870234, lng: 17.9949523 },
    { lat: 48.4870977, lng: 17.9948935 },
    { lat: 48.4871673, lng: 17.9949233 },
    { lat: 48.4871546, lng: 17.9950513 },
    { lat: 48.4872736, lng: 17.995079 },
    { lat: 48.4872887, lng: 17.9952179 },
    { lat: 48.4874595, lng: 17.9954176 },
    { lat: 48.4873839, lng: 17.9956043 },
    { lat: 48.488397, lng: 17.996544 },
    { lat: 48.4889779, lng: 17.996877 },
    { lat: 48.4897203, lng: 17.9971196 },
    { lat: 48.4901349, lng: 17.9975088 },
    { lat: 48.490418, lng: 17.9978813 },
    { lat: 48.4906331, lng: 17.9980259 },
    { lat: 48.4918369, lng: 17.9991938 },
    { lat: 48.4921647, lng: 17.9978735 },
    { lat: 48.4926698, lng: 17.9954024 },
    { lat: 48.4947147, lng: 17.9897506 },
    { lat: 48.496511, lng: 17.990027 },
    { lat: 48.4964661, lng: 17.9914886 },
    { lat: 48.4964018, lng: 17.9922279 },
    { lat: 48.4977535, lng: 17.9935252 },
    { lat: 48.4982605, lng: 17.9925625 },
    { lat: 48.4991951, lng: 17.9909733 },
    { lat: 48.4996554, lng: 17.9903282 },
    { lat: 48.500923, lng: 17.9904795 },
    { lat: 48.5014321, lng: 17.9904823 },
    { lat: 48.5017894, lng: 17.9904181 },
    { lat: 48.5027613, lng: 17.9900776 },
    { lat: 48.5025352, lng: 17.9887247 },
    { lat: 48.5045636, lng: 17.9888042 },
    { lat: 48.5049544, lng: 17.989075 },
    { lat: 48.5055385, lng: 17.9892923 },
    { lat: 48.5055873, lng: 17.9899962 },
    { lat: 48.5067383, lng: 17.9897838 },
    { lat: 48.5076669, lng: 17.9948169 },
    { lat: 48.5078515, lng: 17.9952448 },
    { lat: 48.5080649, lng: 17.9955121 },
    { lat: 48.5091192, lng: 17.9966133 },
    { lat: 48.5092989, lng: 17.9968243 },
    { lat: 48.5094717, lng: 17.9965995 },
    { lat: 48.5098218, lng: 17.9962835 },
    { lat: 48.5111186, lng: 17.9953686 },
    { lat: 48.5126009, lng: 17.9942191 },
    { lat: 48.5127212, lng: 17.9944001 },
    { lat: 48.5187988, lng: 17.9930306 },
    { lat: 48.5202444, lng: 17.992782 },
    { lat: 48.520239, lng: 17.9928023 },
    { lat: 48.5203126, lng: 17.9967316 },
    { lat: 48.5255408, lng: 17.9954999 },
    { lat: 48.5288767, lng: 17.9909606 },
    { lat: 48.5290039, lng: 17.990806 },
    { lat: 48.5299712, lng: 17.9894788 },
    { lat: 48.5352427, lng: 17.9821773 },
    { lat: 48.5347526, lng: 17.9814155 },
    { lat: 48.5352529, lng: 17.9806637 },
    { lat: 48.5333493, lng: 17.9773085 },
    { lat: 48.5331057, lng: 17.9775933 },
    { lat: 48.5330465, lng: 17.9774887 },
    { lat: 48.5327479, lng: 17.9768368 },
    { lat: 48.5321138, lng: 17.9751185 },
    { lat: 48.531322, lng: 17.9731833 },
    { lat: 48.5310685, lng: 17.9727753 },
    { lat: 48.5306711, lng: 17.9718284 },
    { lat: 48.530181, lng: 17.970877 },
    { lat: 48.5296555, lng: 17.9696203 },
    { lat: 48.5293345, lng: 17.96899 },
    { lat: 48.5284799, lng: 17.9678729 },
    { lat: 48.5282374, lng: 17.9674809 },
    { lat: 48.5278761, lng: 17.9668725 },
    { lat: 48.5275613, lng: 17.9662165 },
    { lat: 48.5273397, lng: 17.9653421 },
    { lat: 48.5273868, lng: 17.9652239 },
    { lat: 48.5277211, lng: 17.9651501 },
    { lat: 48.5276533, lng: 17.9647334 },
    { lat: 48.5277072, lng: 17.9645384 },
    { lat: 48.5278048, lng: 17.9645494 },
    { lat: 48.5277104, lng: 17.9632912 },
    { lat: 48.527674, lng: 17.9620972 },
    { lat: 48.5276079, lng: 17.9619922 },
    { lat: 48.5273761, lng: 17.9624588 },
    { lat: 48.5266227, lng: 17.9612249 },
    { lat: 48.5253892, lng: 17.9588123 },
    { lat: 48.5264975, lng: 17.9571136 },
    { lat: 48.5261519, lng: 17.9565231 },
    { lat: 48.527275, lng: 17.9548655 },
    { lat: 48.5274062, lng: 17.9544581 },
    { lat: 48.527465, lng: 17.9536278 },
    { lat: 48.5275386, lng: 17.953427 },
    { lat: 48.5288096, lng: 17.9516914 },
    { lat: 48.5286102, lng: 17.9513991 },
    { lat: 48.5289623, lng: 17.9509075 },
    { lat: 48.529654, lng: 17.950148 },
    { lat: 48.5297282, lng: 17.9502768 },
    { lat: 48.5301704, lng: 17.9497078 },
    { lat: 48.5304993, lng: 17.9502222 },
    { lat: 48.5326136, lng: 17.9476254 },
    { lat: 48.5331981, lng: 17.9466428 },
    { lat: 48.5319969, lng: 17.9447013 },
    { lat: 48.5306815, lng: 17.9423619 },
    { lat: 48.5305766, lng: 17.9423235 },
    { lat: 48.5303147, lng: 17.9419478 },
    { lat: 48.5285887, lng: 17.939011 },
    { lat: 48.528148, lng: 17.9383284 },
    { lat: 48.5277791, lng: 17.9378619 },
    { lat: 48.5277358, lng: 17.937798 },
    { lat: 48.5268643, lng: 17.9389974 },
    { lat: 48.5264375, lng: 17.9396751 },
    { lat: 48.5268711, lng: 17.9402421 },
    { lat: 48.5253808, lng: 17.9426598 },
    { lat: 48.5237425, lng: 17.9450443 },
    { lat: 48.5231522, lng: 17.9443273 },
    { lat: 48.5227632, lng: 17.9439586 },
    { lat: 48.5222616, lng: 17.943633 },
    { lat: 48.5207865, lng: 17.9429206 },
    { lat: 48.5206767, lng: 17.9427468 },
    { lat: 48.5203585, lng: 17.9425056 },
    { lat: 48.5194704, lng: 17.9424519 },
    { lat: 48.5191846, lng: 17.9423516 },
    { lat: 48.5174084, lng: 17.9421178 },
    { lat: 48.5170726, lng: 17.9419772 },
    { lat: 48.5168803, lng: 17.9418343 },
    { lat: 48.5165785, lng: 17.9411889 },
    { lat: 48.5162671, lng: 17.9407962 },
    { lat: 48.5156147, lng: 17.9401575 },
    { lat: 48.5151414, lng: 17.9398499 },
    { lat: 48.5143452, lng: 17.939637 },
    { lat: 48.5137185, lng: 17.9392559 },
    { lat: 48.513418, lng: 17.9388219 },
    { lat: 48.5131402, lng: 17.9382758 },
    { lat: 48.5130448, lng: 17.9381528 },
  ],
  Práznovce: [
    { lat: 48.493841, lng: 18.292836 },
    { lat: 48.4945188, lng: 18.292154 },
    { lat: 48.4951199, lng: 18.291079 },
    { lat: 48.4951849, lng: 18.2906555 },
    { lat: 48.4951595, lng: 18.2903678 },
    { lat: 48.4953184, lng: 18.2900488 },
    { lat: 48.4955998, lng: 18.2891652 },
    { lat: 48.4958908, lng: 18.2887865 },
    { lat: 48.4963622, lng: 18.2876538 },
    { lat: 48.497066, lng: 18.2871951 },
    { lat: 48.4973514, lng: 18.2871337 },
    { lat: 48.4978705, lng: 18.2871934 },
    { lat: 48.4981005, lng: 18.2873092 },
    { lat: 48.4982293, lng: 18.2872908 },
    { lat: 48.4985722, lng: 18.2870936 },
    { lat: 48.4987615, lng: 18.2867099 },
    { lat: 48.4991154, lng: 18.2864891 },
    { lat: 48.4995172, lng: 18.2863914 },
    { lat: 48.5000411, lng: 18.284753 },
    { lat: 48.5002776, lng: 18.2843514 },
    { lat: 48.5004712, lng: 18.2841439 },
    { lat: 48.5009384, lng: 18.2838281 },
    { lat: 48.501516, lng: 18.2830765 },
    { lat: 48.5016843, lng: 18.2829399 },
    { lat: 48.5019977, lng: 18.2824194 },
    { lat: 48.5022584, lng: 18.2821648 },
    { lat: 48.5026796, lng: 18.2814202 },
    { lat: 48.5034114, lng: 18.2809143 },
    { lat: 48.5037458, lng: 18.2805054 },
    { lat: 48.5046057, lng: 18.2790458 },
    { lat: 48.504874, lng: 18.2787844 },
    { lat: 48.5054459, lng: 18.278386 },
    { lat: 48.5060433, lng: 18.2777633 },
    { lat: 48.5065959, lng: 18.2773407 },
    { lat: 48.5067254, lng: 18.2766639 },
    { lat: 48.5068029, lng: 18.2764806 },
    { lat: 48.5076539, lng: 18.2750448 },
    { lat: 48.5083735, lng: 18.2743468 },
    { lat: 48.5091698, lng: 18.2740428 },
    { lat: 48.5093212, lng: 18.2738712 },
    { lat: 48.5094959, lng: 18.2733935 },
    { lat: 48.509674, lng: 18.2730922 },
    { lat: 48.5101254, lng: 18.2726018 },
    { lat: 48.5106139, lng: 18.2711981 },
    { lat: 48.510695, lng: 18.2707211 },
    { lat: 48.5111671, lng: 18.2704305 },
    { lat: 48.5115018, lng: 18.2700369 },
    { lat: 48.5117507, lng: 18.2697443 },
    { lat: 48.5123352, lng: 18.2695295 },
    { lat: 48.5130021, lng: 18.2691727 },
    { lat: 48.5134486, lng: 18.2683583 },
    { lat: 48.5137559, lng: 18.2680093 },
    { lat: 48.5138339, lng: 18.2678145 },
    { lat: 48.5151251, lng: 18.2658654 },
    { lat: 48.5153457, lng: 18.2653816 },
    { lat: 48.5160592, lng: 18.2648184 },
    { lat: 48.5173697, lng: 18.2632278 },
    { lat: 48.5175256, lng: 18.2629151 },
    { lat: 48.5180045, lng: 18.2623708 },
    { lat: 48.5185497, lng: 18.2619029 },
    { lat: 48.5186971, lng: 18.2617137 },
    { lat: 48.5199823, lng: 18.2607118 },
    { lat: 48.520953, lng: 18.2602417 },
    { lat: 48.5210578, lng: 18.2601232 },
    { lat: 48.5228221, lng: 18.2591096 },
    { lat: 48.5233873, lng: 18.2587522 },
    { lat: 48.5236127, lng: 18.2585094 },
    { lat: 48.524793, lng: 18.2568353 },
    { lat: 48.525102, lng: 18.2561033 },
    { lat: 48.5255248, lng: 18.2553062 },
    { lat: 48.5269476, lng: 18.2527063 },
    { lat: 48.5275451, lng: 18.251731 },
    { lat: 48.5275618, lng: 18.2512917 },
    { lat: 48.5276691, lng: 18.2509773 },
    { lat: 48.5280848, lng: 18.2501202 },
    { lat: 48.5283006, lng: 18.2498501 },
    { lat: 48.5287045, lng: 18.2491576 },
    { lat: 48.5294649, lng: 18.2483686 },
    { lat: 48.5304583, lng: 18.2470384 },
    { lat: 48.5308116, lng: 18.2468237 },
    { lat: 48.5311237, lng: 18.2464809 },
    { lat: 48.5313052, lng: 18.2466357 },
    { lat: 48.5314951, lng: 18.2465426 },
    { lat: 48.5316493, lng: 18.2462627 },
    { lat: 48.5317387, lng: 18.2462664 },
    { lat: 48.5319699, lng: 18.24599 },
    { lat: 48.5319583, lng: 18.2459431 },
    { lat: 48.5332583, lng: 18.2445459 },
    { lat: 48.5334104, lng: 18.2444229 },
    { lat: 48.5335127, lng: 18.2444174 },
    { lat: 48.5342452, lng: 18.2438682 },
    { lat: 48.5345292, lng: 18.2437658 },
    { lat: 48.535511, lng: 18.2427441 },
    { lat: 48.5356736, lng: 18.2424328 },
    { lat: 48.5358287, lng: 18.2418889 },
    { lat: 48.5360702, lng: 18.2413007 },
    { lat: 48.5362874, lng: 18.2409559 },
    { lat: 48.5369349, lng: 18.2401047 },
    { lat: 48.5378917, lng: 18.2392991 },
    { lat: 48.5382308, lng: 18.2391101 },
    { lat: 48.5386464, lng: 18.2386059 },
    { lat: 48.5395336, lng: 18.237099 },
    { lat: 48.5395873, lng: 18.2371479 },
    { lat: 48.5397711, lng: 18.2369107 },
    { lat: 48.5400309, lng: 18.2361473 },
    { lat: 48.5402807, lng: 18.2357245 },
    { lat: 48.5403112, lng: 18.2355685 },
    { lat: 48.5404963, lng: 18.2352335 },
    { lat: 48.5406157, lng: 18.2352091 },
    { lat: 48.5407633, lng: 18.2350706 },
    { lat: 48.5407148, lng: 18.2349708 },
    { lat: 48.5408856, lng: 18.2346833 },
    { lat: 48.5410853, lng: 18.2339736 },
    { lat: 48.5413278, lng: 18.2335232 },
    { lat: 48.5416839, lng: 18.2325884 },
    { lat: 48.5431754, lng: 18.2339414 },
    { lat: 48.5443553, lng: 18.2316945 },
    { lat: 48.5446863, lng: 18.2320766 },
    { lat: 48.5454481, lng: 18.2309179 },
    { lat: 48.545545, lng: 18.2310321 },
    { lat: 48.5458296, lng: 18.2306026 },
    { lat: 48.5464472, lng: 18.231318 },
    { lat: 48.547368, lng: 18.2302361 },
    { lat: 48.5474825, lng: 18.2303651 },
    { lat: 48.5488289, lng: 18.2285845 },
    { lat: 48.549599, lng: 18.230752 },
    { lat: 48.551352, lng: 18.231384 },
    { lat: 48.551376, lng: 18.231416 },
    { lat: 48.55142, lng: 18.231472 },
    { lat: 48.551444, lng: 18.231499 },
    { lat: 48.551629, lng: 18.231154 },
    { lat: 48.551831, lng: 18.230852 },
    { lat: 48.552035, lng: 18.230589 },
    { lat: 48.552574, lng: 18.229966 },
    { lat: 48.552813, lng: 18.229745 },
    { lat: 48.553092, lng: 18.229514 },
    { lat: 48.553317, lng: 18.229345 },
    { lat: 48.553504, lng: 18.229182 },
    { lat: 48.553615, lng: 18.229062 },
    { lat: 48.554736, lng: 18.227687 },
    { lat: 48.554816, lng: 18.227225 },
    { lat: 48.554898, lng: 18.226768 },
    { lat: 48.554969, lng: 18.226803 },
    { lat: 48.555783, lng: 18.227249 },
    { lat: 48.555738, lng: 18.227541 },
    { lat: 48.555758, lng: 18.227545 },
    { lat: 48.555999, lng: 18.227602 },
    { lat: 48.55617, lng: 18.227678 },
    { lat: 48.556397, lng: 18.22782 },
    { lat: 48.556662, lng: 18.228025 },
    { lat: 48.556754, lng: 18.228091 },
    { lat: 48.556897, lng: 18.228195 },
    { lat: 48.55696, lng: 18.228253 },
    { lat: 48.561765, lng: 18.233084 },
    { lat: 48.561801, lng: 18.233111 },
    { lat: 48.561852, lng: 18.233151 },
    { lat: 48.56189, lng: 18.233176 },
    { lat: 48.561932, lng: 18.232993 },
    { lat: 48.561972, lng: 18.232779 },
    { lat: 48.56212, lng: 18.231827 },
    { lat: 48.562599, lng: 18.232111 },
    { lat: 48.562685, lng: 18.232162 },
    { lat: 48.565577, lng: 18.233877 },
    { lat: 48.565695, lng: 18.233986 },
    { lat: 48.567352, lng: 18.235213 },
    { lat: 48.568923, lng: 18.232075 },
    { lat: 48.567488, lng: 18.230241 },
    { lat: 48.567349, lng: 18.230106 },
    { lat: 48.567667, lng: 18.229429 },
    { lat: 48.56644, lng: 18.227814 },
    { lat: 48.566422, lng: 18.227777 },
    { lat: 48.566592, lng: 18.227607 },
    { lat: 48.566624, lng: 18.227658 },
    { lat: 48.566753, lng: 18.227475 },
    { lat: 48.566786, lng: 18.227241 },
    { lat: 48.566714, lng: 18.22711 },
    { lat: 48.56665, lng: 18.226603 },
    { lat: 48.566823, lng: 18.226421 },
    { lat: 48.566741, lng: 18.226362 },
    { lat: 48.566657, lng: 18.226061 },
    { lat: 48.566772, lng: 18.225853 },
    { lat: 48.566718, lng: 18.225641 },
    { lat: 48.56646, lng: 18.225111 },
    { lat: 48.56667, lng: 18.224907 },
    { lat: 48.567119, lng: 18.225123 },
    { lat: 48.567189, lng: 18.224749 },
    { lat: 48.56675, lng: 18.223989 },
    { lat: 48.566822, lng: 18.223715 },
    { lat: 48.566935, lng: 18.223516 },
    { lat: 48.567006, lng: 18.223485 },
    { lat: 48.567235, lng: 18.223536 },
    { lat: 48.567334, lng: 18.223585 },
    { lat: 48.567504, lng: 18.223598 },
    { lat: 48.56784, lng: 18.223512 },
    { lat: 48.568162, lng: 18.223515 },
    { lat: 48.568384, lng: 18.222906 },
    { lat: 48.568472, lng: 18.222588 },
    { lat: 48.5692, lng: 18.221012 },
    { lat: 48.569711, lng: 18.21995 },
    { lat: 48.569998, lng: 18.219769 },
    { lat: 48.570044, lng: 18.219629 },
    { lat: 48.570111, lng: 18.219525 },
    { lat: 48.570166, lng: 18.219516 },
    { lat: 48.570219, lng: 18.219652 },
    { lat: 48.570312, lng: 18.21967 },
    { lat: 48.570368, lng: 18.219608 },
    { lat: 48.570419, lng: 18.219512 },
    { lat: 48.570468, lng: 18.219402 },
    { lat: 48.570674, lng: 18.219375 },
    { lat: 48.570729, lng: 18.219244 },
    { lat: 48.570759, lng: 18.219053 },
    { lat: 48.570829, lng: 18.218834 },
    { lat: 48.570904, lng: 18.218766 },
    { lat: 48.570983, lng: 18.218769 },
    { lat: 48.571043, lng: 18.218621 },
    { lat: 48.571025, lng: 18.218225 },
    { lat: 48.571169, lng: 18.218117 },
    { lat: 48.571215, lng: 18.217801 },
    { lat: 48.572193, lng: 18.218995 },
    { lat: 48.572351, lng: 18.218913 },
    { lat: 48.572518, lng: 18.218499 },
    { lat: 48.572635, lng: 18.218126 },
    { lat: 48.572725, lng: 18.217787 },
    { lat: 48.573013, lng: 18.217962 },
    { lat: 48.573147, lng: 18.218044 },
    { lat: 48.573363, lng: 18.217652 },
    { lat: 48.573654, lng: 18.217627 },
    { lat: 48.573686, lng: 18.217374 },
    { lat: 48.5737, lng: 18.217277 },
    { lat: 48.573721, lng: 18.216994 },
    { lat: 48.573762, lng: 18.216802 },
    { lat: 48.573721, lng: 18.216772 },
    { lat: 48.573718, lng: 18.216708 },
    { lat: 48.573837, lng: 18.216683 },
    { lat: 48.573797, lng: 18.216467 },
    { lat: 48.573796, lng: 18.21631 },
    { lat: 48.573794, lng: 18.216251 },
    { lat: 48.573792, lng: 18.215898 },
    { lat: 48.573716, lng: 18.215416 },
    { lat: 48.573794, lng: 18.215015 },
    { lat: 48.57383, lng: 18.21501 },
    { lat: 48.57404, lng: 18.21498 },
    { lat: 48.57413, lng: 18.214967 },
    { lat: 48.574156, lng: 18.214964 },
    { lat: 48.573877, lng: 18.2125853 },
    { lat: 48.573612, lng: 18.211453 },
    { lat: 48.5734784, lng: 18.2101111 },
    { lat: 48.573287, lng: 18.2092674 },
    { lat: 48.5729942, lng: 18.207977 },
    { lat: 48.5727843, lng: 18.2074664 },
    { lat: 48.5725204, lng: 18.2068015 },
    { lat: 48.5723628, lng: 18.2064576 },
    { lat: 48.5720721, lng: 18.2060139 },
    { lat: 48.5717689, lng: 18.205295 },
    { lat: 48.5711064, lng: 18.2045284 },
    { lat: 48.5706231, lng: 18.2035847 },
    { lat: 48.5699182, lng: 18.2025154 },
    { lat: 48.5698897, lng: 18.2022747 },
    { lat: 48.5699274, lng: 18.2019114 },
    { lat: 48.5698973, lng: 18.2019346 },
    { lat: 48.5695655, lng: 18.2021305 },
    { lat: 48.5688758, lng: 18.2023042 },
    { lat: 48.5679492, lng: 18.2029097 },
    { lat: 48.5674748, lng: 18.2022357 },
    { lat: 48.5670827, lng: 18.2018531 },
    { lat: 48.5623589, lng: 18.1983291 },
    { lat: 48.5619861, lng: 18.197969 },
    { lat: 48.561251, lng: 18.1970443 },
    { lat: 48.5606734, lng: 18.1960876 },
    { lat: 48.56039, lng: 18.1974745 },
    { lat: 48.5608785, lng: 18.1984076 },
    { lat: 48.5617154, lng: 18.1999889 },
    { lat: 48.5636838, lng: 18.203223 },
    { lat: 48.5642642, lng: 18.2045991 },
    { lat: 48.5636658, lng: 18.2063757 },
    { lat: 48.5633078, lng: 18.2077228 },
    { lat: 48.5630479, lng: 18.2082014 },
    { lat: 48.5623176, lng: 18.2102349 },
    { lat: 48.5619987, lng: 18.2114804 },
    { lat: 48.5618145, lng: 18.2128656 },
    { lat: 48.5616609, lng: 18.2128259 },
    { lat: 48.5615585, lng: 18.2137704 },
    { lat: 48.5614017, lng: 18.2137318 },
    { lat: 48.5613288, lng: 18.2144043 },
    { lat: 48.5608829, lng: 18.2147967 },
    { lat: 48.5604448, lng: 18.2149444 },
    { lat: 48.557705, lng: 18.2142391 },
    { lat: 48.5576021, lng: 18.2142753 },
    { lat: 48.5575776, lng: 18.213989 },
    { lat: 48.5575289, lng: 18.2141214 },
    { lat: 48.5556648, lng: 18.2156719 },
    { lat: 48.5552186, lng: 18.2157786 },
    { lat: 48.5511788, lng: 18.2156246 },
    { lat: 48.5496481, lng: 18.2150228 },
    { lat: 48.5491642, lng: 18.2149101 },
    { lat: 48.5466471, lng: 18.2156393 },
    { lat: 48.5465317, lng: 18.2157409 },
    { lat: 48.5465049, lng: 18.2157629 },
    { lat: 48.5461548, lng: 18.2160892 },
    { lat: 48.5437483, lng: 18.2204477 },
    { lat: 48.5436147, lng: 18.2209698 },
    { lat: 48.5435985, lng: 18.2218262 },
    { lat: 48.5435484, lng: 18.2220321 },
    { lat: 48.5433334, lng: 18.2224957 },
    { lat: 48.5423716, lng: 18.2235082 },
    { lat: 48.5422218, lng: 18.2239419 },
    { lat: 48.5421644, lng: 18.2239643 },
    { lat: 48.542054, lng: 18.2238502 },
    { lat: 48.5417993, lng: 18.224169 },
    { lat: 48.5416892, lng: 18.2242574 },
    { lat: 48.5416552, lng: 18.2242173 },
    { lat: 48.5415457, lng: 18.2243527 },
    { lat: 48.5415392, lng: 18.2244953 },
    { lat: 48.5413988, lng: 18.2248572 },
    { lat: 48.5410506, lng: 18.224966 },
    { lat: 48.5403823, lng: 18.2256058 },
    { lat: 48.5401841, lng: 18.2255761 },
    { lat: 48.5401198, lng: 18.2256546 },
    { lat: 48.5401397, lng: 18.225981 },
    { lat: 48.5399615, lng: 18.2260235 },
    { lat: 48.5397969, lng: 18.2263604 },
    { lat: 48.5396732, lng: 18.2264834 },
    { lat: 48.5395222, lng: 18.2264269 },
    { lat: 48.5394283, lng: 18.2266193 },
    { lat: 48.5393198, lng: 18.2266787 },
    { lat: 48.5392213, lng: 18.22777 },
    { lat: 48.5391872, lng: 18.2278849 },
    { lat: 48.5389001, lng: 18.2282506 },
    { lat: 48.5388964, lng: 18.2284405 },
    { lat: 48.53878, lng: 18.2286333 },
    { lat: 48.5387415, lng: 18.2291034 },
    { lat: 48.5386425, lng: 18.2294983 },
    { lat: 48.5385865, lng: 18.2296025 },
    { lat: 48.5383449, lng: 18.229781 },
    { lat: 48.5381659, lng: 18.2302957 },
    { lat: 48.5380509, lng: 18.2303751 },
    { lat: 48.5379487, lng: 18.2305437 },
    { lat: 48.5378556, lng: 18.2310433 },
    { lat: 48.537319, lng: 18.2318185 },
    { lat: 48.536841, lng: 18.2326317 },
    { lat: 48.5366973, lng: 18.2327814 },
    { lat: 48.5362486, lng: 18.2336346 },
    { lat: 48.5358597, lng: 18.2332651 },
    { lat: 48.534641, lng: 18.2362546 },
    { lat: 48.5332671, lng: 18.2387216 },
    { lat: 48.5323536, lng: 18.2419968 },
    { lat: 48.5322481, lng: 18.2423748 },
    { lat: 48.5317509, lng: 18.2434064 },
    { lat: 48.5313767, lng: 18.2435907 },
    { lat: 48.5312202, lng: 18.2434213 },
    { lat: 48.5310095, lng: 18.2433875 },
    { lat: 48.5305621, lng: 18.2435727 },
    { lat: 48.5303598, lng: 18.2437268 },
    { lat: 48.5302708, lng: 18.2438858 },
    { lat: 48.5299708, lng: 18.2438878 },
    { lat: 48.5259002, lng: 18.2459063 },
    { lat: 48.5194251, lng: 18.2503474 },
    { lat: 48.5108259, lng: 18.2478477 },
    { lat: 48.5024859, lng: 18.2574332 },
    { lat: 48.4978994, lng: 18.262704 },
    { lat: 48.4939005, lng: 18.2674392 },
    { lat: 48.487703, lng: 18.274596 },
    { lat: 48.487714, lng: 18.274632 },
    { lat: 48.487828, lng: 18.278987 },
    { lat: 48.487642, lng: 18.281048 },
    { lat: 48.487683, lng: 18.281822 },
    { lat: 48.487781, lng: 18.28255 },
    { lat: 48.487941, lng: 18.282849 },
    { lat: 48.488288, lng: 18.283347 },
    { lat: 48.488672, lng: 18.284139 },
    { lat: 48.488972, lng: 18.284754 },
    { lat: 48.489109, lng: 18.284941 },
    { lat: 48.489655, lng: 18.285439 },
    { lat: 48.490296, lng: 18.286241 },
    { lat: 48.490495, lng: 18.286587 },
    { lat: 48.490683, lng: 18.287455 },
    { lat: 48.490796, lng: 18.287549 },
    { lat: 48.490995, lng: 18.287983 },
    { lat: 48.491367, lng: 18.288739 },
    { lat: 48.49171, lng: 18.289401 },
    { lat: 48.491874, lng: 18.289541 },
    { lat: 48.492296, lng: 18.289816 },
    { lat: 48.493166, lng: 18.290817 },
    { lat: 48.493385, lng: 18.291439 },
    { lat: 48.49353, lng: 18.291932 },
    { lat: 48.493841, lng: 18.292836 },
  ],
  Hrušovany: [
    { lat: 48.431657, lng: 18.116087 },
    { lat: 48.431709, lng: 18.11613 },
    { lat: 48.431776, lng: 18.116172 },
    { lat: 48.431837, lng: 18.116209 },
    { lat: 48.431912, lng: 18.116257 },
    { lat: 48.431932, lng: 18.116267 },
    { lat: 48.431988, lng: 18.116298 },
    { lat: 48.432063, lng: 18.116334 },
    { lat: 48.432138, lng: 18.11637 },
    { lat: 48.432205, lng: 18.116406 },
    { lat: 48.43228, lng: 18.116436 },
    { lat: 48.432348, lng: 18.116478 },
    { lat: 48.432385, lng: 18.116528 },
    { lat: 48.432462, lng: 18.116635 },
    { lat: 48.432572, lng: 18.116773 },
    { lat: 48.432671, lng: 18.116919 },
    { lat: 48.432769, lng: 18.117059 },
    { lat: 48.432846, lng: 18.117159 },
    { lat: 48.432932, lng: 18.117288 },
    { lat: 48.433046, lng: 18.117421 },
    { lat: 48.433123, lng: 18.117521 },
    { lat: 48.433116, lng: 18.117568 },
    { lat: 48.433112, lng: 18.117645 },
    { lat: 48.433106, lng: 18.117745 },
    { lat: 48.433113, lng: 18.117845 },
    { lat: 48.433142, lng: 18.117983 },
    { lat: 48.433173, lng: 18.118116 },
    { lat: 48.433208, lng: 18.118219 },
    { lat: 48.433245, lng: 18.118293 },
    { lat: 48.433306, lng: 18.118383 },
    { lat: 48.433364, lng: 18.118455 },
    { lat: 48.433383, lng: 18.118421 },
    { lat: 48.4337048, lng: 18.1178462 },
    { lat: 48.4340816, lng: 18.1176901 },
    { lat: 48.4343358, lng: 18.1175048 },
    { lat: 48.4348012, lng: 18.1175125 },
    { lat: 48.4352186, lng: 18.1177395 },
    { lat: 48.4353565, lng: 18.1176264 },
    { lat: 48.4355076, lng: 18.1173363 },
    { lat: 48.4357801, lng: 18.1171904 },
    { lat: 48.4367754, lng: 18.1172674 },
    { lat: 48.4368464, lng: 18.1172068 },
    { lat: 48.4369746, lng: 18.1167961 },
    { lat: 48.4371994, lng: 18.1166036 },
    { lat: 48.4373299, lng: 18.1159459 },
    { lat: 48.4374081, lng: 18.1157589 },
    { lat: 48.4375269, lng: 18.1156266 },
    { lat: 48.4378854, lng: 18.1154491 },
    { lat: 48.4379779, lng: 18.114928 },
    { lat: 48.4382397, lng: 18.1148325 },
    { lat: 48.4383494, lng: 18.114678 },
    { lat: 48.4385047, lng: 18.1145761 },
    { lat: 48.4388102, lng: 18.1147037 },
    { lat: 48.4391202, lng: 18.114602 },
    { lat: 48.4396515, lng: 18.1140405 },
    { lat: 48.4400905, lng: 18.1144053 },
    { lat: 48.4413596, lng: 18.1089109 },
    { lat: 48.442426, lng: 18.1037093 },
    { lat: 48.4425608, lng: 18.1036619 },
    { lat: 48.4426281, lng: 18.1040589 },
    { lat: 48.4428488, lng: 18.1044149 },
    { lat: 48.4429663, lng: 18.1045142 },
    { lat: 48.4431501, lng: 18.1045527 },
    { lat: 48.4433302, lng: 18.1045064 },
    { lat: 48.4437472, lng: 18.1041284 },
    { lat: 48.4444531, lng: 18.10287 },
    { lat: 48.4451961, lng: 18.0975303 },
    { lat: 48.4451769, lng: 18.0972395 },
    { lat: 48.4448504, lng: 18.0970803 },
    { lat: 48.4454017, lng: 18.0934016 },
    { lat: 48.4455106, lng: 18.0929695 },
    { lat: 48.4459027, lng: 18.090426 },
    { lat: 48.4474428, lng: 18.0801911 },
    { lat: 48.4450906, lng: 18.0794035 },
    { lat: 48.4456115, lng: 18.0755989 },
    { lat: 48.4455486, lng: 18.0753534 },
    { lat: 48.4455542, lng: 18.0737826 },
    { lat: 48.4456251, lng: 18.0731286 },
    { lat: 48.445749, lng: 18.0724943 },
    { lat: 48.4460795, lng: 18.0717258 },
    { lat: 48.4462809, lng: 18.0709852 },
    { lat: 48.446366, lng: 18.0704595 },
    { lat: 48.4465152, lng: 18.0701545 },
    { lat: 48.4465861, lng: 18.0702663 },
    { lat: 48.4467069, lng: 18.0701169 },
    { lat: 48.4470898, lng: 18.0692582 },
    { lat: 48.4471529, lng: 18.0689682 },
    { lat: 48.4471821, lng: 18.0684322 },
    { lat: 48.4471538, lng: 18.0682184 },
    { lat: 48.4470522, lng: 18.0681427 },
    { lat: 48.4472801, lng: 18.067412 },
    { lat: 48.4475629, lng: 18.0668137 },
    { lat: 48.4477405, lng: 18.0665526 },
    { lat: 48.4479013, lng: 18.0661202 },
    { lat: 48.4480211, lng: 18.0651273 },
    { lat: 48.4480226, lng: 18.0646767 },
    { lat: 48.4479526, lng: 18.0638144 },
    { lat: 48.4464256, lng: 18.0632786 },
    { lat: 48.446339, lng: 18.0635912 },
    { lat: 48.4459136, lng: 18.064033 },
    { lat: 48.4456629, lng: 18.0644884 },
    { lat: 48.4451466, lng: 18.0643247 },
    { lat: 48.4439885, lng: 18.0642495 },
    { lat: 48.4440173, lng: 18.0626884 },
    { lat: 48.4438586, lng: 18.0608824 },
    { lat: 48.4436654, lng: 18.0595564 },
    { lat: 48.4438176, lng: 18.0594227 },
    { lat: 48.4432596, lng: 18.0578837 },
    { lat: 48.4431109, lng: 18.056916 },
    { lat: 48.443113, lng: 18.056869 },
    { lat: 48.443092, lng: 18.056867 },
    { lat: 48.443054, lng: 18.056485 },
    { lat: 48.442888, lng: 18.056356 },
    { lat: 48.442576, lng: 18.056108 },
    { lat: 48.442334, lng: 18.055924 },
    { lat: 48.442154, lng: 18.055777 },
    { lat: 48.441896, lng: 18.055609 },
    { lat: 48.44186, lng: 18.055567 },
    { lat: 48.441581, lng: 18.055293 },
    { lat: 48.441492, lng: 18.055247 },
    { lat: 48.441329, lng: 18.055142 },
    { lat: 48.441055, lng: 18.054905 },
    { lat: 48.44095, lng: 18.054806 },
    { lat: 48.440809, lng: 18.054629 },
    { lat: 48.440602, lng: 18.054436 },
    { lat: 48.440396, lng: 18.054207 },
    { lat: 48.440327, lng: 18.054129 },
    { lat: 48.440122, lng: 18.053835 },
    { lat: 48.439969, lng: 18.053623 },
    { lat: 48.439875, lng: 18.053488 },
    { lat: 48.439677, lng: 18.053282 },
    { lat: 48.439497, lng: 18.053079 },
    { lat: 48.439469, lng: 18.053041 },
    { lat: 48.439384, lng: 18.052985 },
    { lat: 48.43921, lng: 18.052867 },
    { lat: 48.439085, lng: 18.052809 },
    { lat: 48.439043, lng: 18.052788 },
    { lat: 48.438793, lng: 18.05271 },
    { lat: 48.438626, lng: 18.05266 },
    { lat: 48.43845, lng: 18.052612 },
    { lat: 48.438256, lng: 18.052587 },
    { lat: 48.438249, lng: 18.052586 },
    { lat: 48.4381685, lng: 18.0536457 },
    { lat: 48.4378346, lng: 18.0555961 },
    { lat: 48.4377784, lng: 18.056442 },
    { lat: 48.4375828, lng: 18.0576773 },
    { lat: 48.4373433, lng: 18.0596417 },
    { lat: 48.4370601, lng: 18.0643455 },
    { lat: 48.4369424, lng: 18.0675137 },
    { lat: 48.4370267, lng: 18.0681977 },
    { lat: 48.4370976, lng: 18.0683841 },
    { lat: 48.4370848, lng: 18.0701 },
    { lat: 48.437017, lng: 18.0705586 },
    { lat: 48.4367037, lng: 18.0714466 },
    { lat: 48.4360421, lng: 18.0730498 },
    { lat: 48.4359319, lng: 18.0731942 },
    { lat: 48.4358231, lng: 18.0732417 },
    { lat: 48.4356645, lng: 18.0739775 },
    { lat: 48.4353859, lng: 18.0753975 },
    { lat: 48.4349194, lng: 18.0782463 },
    { lat: 48.4346768, lng: 18.0802378 },
    { lat: 48.4340897, lng: 18.0840329 },
    { lat: 48.4330679, lng: 18.0890314 },
    { lat: 48.4322424, lng: 18.0935349 },
    { lat: 48.4322621, lng: 18.0937924 },
    { lat: 48.4320955, lng: 18.0957396 },
    { lat: 48.4320073, lng: 18.0975231 },
    { lat: 48.4318291, lng: 18.0988904 },
    { lat: 48.4319058, lng: 18.0989069 },
    { lat: 48.4319507, lng: 18.098348 },
    { lat: 48.432196, lng: 18.0984885 },
    { lat: 48.4325958, lng: 18.0997031 },
    { lat: 48.4326444, lng: 18.100362 },
    { lat: 48.4324868, lng: 18.1008643 },
    { lat: 48.4322122, lng: 18.1012067 },
    { lat: 48.4320645, lng: 18.1013045 },
    { lat: 48.4313656, lng: 18.102298 },
    { lat: 48.431091, lng: 18.102544 },
    { lat: 48.4295994, lng: 18.1076662 },
    { lat: 48.4283637, lng: 18.1127868 },
    { lat: 48.428357, lng: 18.112832 },
    { lat: 48.428393, lng: 18.112838 },
    { lat: 48.428456, lng: 18.112851 },
    { lat: 48.42857, lng: 18.112884 },
    { lat: 48.428678, lng: 18.11294 },
    { lat: 48.42878, lng: 18.112998 },
    { lat: 48.428859, lng: 18.113039 },
    { lat: 48.42888, lng: 18.113073 },
    { lat: 48.428913, lng: 18.113123 },
    { lat: 48.42893, lng: 18.113193 },
    { lat: 48.428941, lng: 18.113251 },
    { lat: 48.428953, lng: 18.113379 },
    { lat: 48.428975, lng: 18.113489 },
    { lat: 48.429002, lng: 18.113611 },
    { lat: 48.429026, lng: 18.113726 },
    { lat: 48.429055, lng: 18.113782 },
    { lat: 48.429115, lng: 18.113837 },
    { lat: 48.429179, lng: 18.113897 },
    { lat: 48.429272, lng: 18.113984 },
    { lat: 48.429356, lng: 18.114061 },
    { lat: 48.429445, lng: 18.114137 },
    { lat: 48.429541, lng: 18.114212 },
    { lat: 48.429621, lng: 18.11427 },
    { lat: 48.429681, lng: 18.114308 },
    { lat: 48.429744, lng: 18.114344 },
    { lat: 48.429812, lng: 18.114398 },
    { lat: 48.429879, lng: 18.11444 },
    { lat: 48.429948, lng: 18.1145 },
    { lat: 48.430011, lng: 18.114531 },
    { lat: 48.430072, lng: 18.114562 },
    { lat: 48.430107, lng: 18.114583 },
    { lat: 48.430126, lng: 18.114581 },
    { lat: 48.430149, lng: 18.11458 },
    { lat: 48.430196, lng: 18.114576 },
    { lat: 48.430212, lng: 18.114575 },
    { lat: 48.43024, lng: 18.114585 },
    { lat: 48.43029, lng: 18.114599 },
    { lat: 48.430318, lng: 18.114609 },
    { lat: 48.430346, lng: 18.114624 },
    { lat: 48.430382, lng: 18.114645 },
    { lat: 48.43043, lng: 18.114677 },
    { lat: 48.430489, lng: 18.11472 },
    { lat: 48.430514, lng: 18.114736 },
    { lat: 48.430539, lng: 18.114769 },
    { lat: 48.430587, lng: 18.114825 },
    { lat: 48.430632, lng: 18.114897 },
    { lat: 48.430652, lng: 18.114914 },
    { lat: 48.430683, lng: 18.114911 },
    { lat: 48.430812, lng: 18.114914 },
    { lat: 48.430953, lng: 18.114704 },
    { lat: 48.431026, lng: 18.11464 },
    { lat: 48.431062, lng: 18.114644 },
    { lat: 48.431133, lng: 18.11468 },
    { lat: 48.431189, lng: 18.114717 },
    { lat: 48.431277, lng: 18.114799 },
    { lat: 48.431365, lng: 18.114874 },
    { lat: 48.431454, lng: 18.115086 },
    { lat: 48.431471, lng: 18.115125 },
    { lat: 48.431563, lng: 18.115324 },
    { lat: 48.431588, lng: 18.115511 },
    { lat: 48.431623, lng: 18.115778 },
    { lat: 48.43166, lng: 18.116034 },
    { lat: 48.431657, lng: 18.116087 },
  ],
  Vozokany: [
    { lat: 48.5491366, lng: 18.0086317 },
    { lat: 48.5494048, lng: 18.0084912 },
    { lat: 48.5495254, lng: 18.0083763 },
    { lat: 48.5502189, lng: 18.0080976 },
    { lat: 48.5508009, lng: 18.0080334 },
    { lat: 48.5509936, lng: 18.0077819 },
    { lat: 48.5512385, lng: 18.0076114 },
    { lat: 48.5515931, lng: 18.007481 },
    { lat: 48.5516544, lng: 18.0073911 },
    { lat: 48.5530936, lng: 18.0079086 },
    { lat: 48.554249, lng: 18.0090397 },
    { lat: 48.5543545, lng: 18.0090679 },
    { lat: 48.5545129, lng: 18.0089051 },
    { lat: 48.554755, lng: 18.008859 },
    { lat: 48.5549278, lng: 18.0088973 },
    { lat: 48.5549944, lng: 18.0089909 },
    { lat: 48.555331, lng: 18.0089609 },
    { lat: 48.5554833, lng: 18.0090351 },
    { lat: 48.555674, lng: 18.0093746 },
    { lat: 48.5559838, lng: 18.0094989 },
    { lat: 48.5561732, lng: 18.0096847 },
    { lat: 48.5563217, lng: 18.0096757 },
    { lat: 48.5565646, lng: 18.0097955 },
    { lat: 48.5566218, lng: 18.0095996 },
    { lat: 48.5567088, lng: 18.0096745 },
    { lat: 48.5569474, lng: 18.0096466 },
    { lat: 48.557014, lng: 18.0097403 },
    { lat: 48.5573004, lng: 18.0098527 },
    { lat: 48.5574949, lng: 18.0101803 },
    { lat: 48.5576048, lng: 18.0101669 },
    { lat: 48.5576349, lng: 18.0100478 },
    { lat: 48.5577404, lng: 18.0100574 },
    { lat: 48.5578123, lng: 18.0103164 },
    { lat: 48.5582547, lng: 18.0107517 },
    { lat: 48.5584686, lng: 18.0111797 },
    { lat: 48.5586255, lng: 18.0112772 },
    { lat: 48.5590606, lng: 18.0113044 },
    { lat: 48.5600319, lng: 18.0111272 },
    { lat: 48.5604413, lng: 18.0111793 },
    { lat: 48.5606369, lng: 18.0110714 },
    { lat: 48.5607938, lng: 18.0111643 },
    { lat: 48.5609645, lng: 18.0110921 },
    { lat: 48.5610431, lng: 18.0111446 },
    { lat: 48.561159, lng: 18.0107947 },
    { lat: 48.5612686, lng: 18.0106642 },
    { lat: 48.5617755, lng: 18.0105709 },
    { lat: 48.5618818, lng: 18.0106884 },
    { lat: 48.562034, lng: 18.0106102 },
    { lat: 48.562259, lng: 18.010313 },
    { lat: 48.5625641, lng: 18.0103756 },
    { lat: 48.5627534, lng: 18.0106572 },
    { lat: 48.5629098, lng: 18.0106857 },
    { lat: 48.5630686, lng: 18.0105481 },
    { lat: 48.5630924, lng: 18.0104715 },
    { lat: 48.5630412, lng: 18.0102942 },
    { lat: 48.5631307, lng: 18.0101873 },
    { lat: 48.5634076, lng: 18.0102498 },
    { lat: 48.5635407, lng: 18.0101898 },
    { lat: 48.5634894, lng: 18.0100307 },
    { lat: 48.5637257, lng: 18.009656 },
    { lat: 48.5640423, lng: 18.0093992 },
    { lat: 48.5640933, lng: 18.0095174 },
    { lat: 48.5641673, lng: 18.0094337 },
    { lat: 48.5646079, lng: 18.0092706 },
    { lat: 48.5648664, lng: 18.0093452 },
    { lat: 48.5649014, lng: 18.0093094 },
    { lat: 48.5648569, lng: 18.008979 },
    { lat: 48.5649426, lng: 18.0088599 },
    { lat: 48.5649535, lng: 18.0086058 },
    { lat: 48.5651214, lng: 18.008545 },
    { lat: 48.5651712, lng: 18.0083972 },
    { lat: 48.566095, lng: 18.0077039 },
    { lat: 48.5661222, lng: 18.007934 },
    { lat: 48.5665098, lng: 18.0078066 },
    { lat: 48.5667079, lng: 18.0075686 },
    { lat: 48.5671675, lng: 18.0072867 },
    { lat: 48.5672258, lng: 18.0070736 },
    { lat: 48.5671968, lng: 18.0067725 },
    { lat: 48.5677683, lng: 18.0060582 },
    { lat: 48.5677176, lng: 18.0058694 },
    { lat: 48.5677711, lng: 18.0056505 },
    { lat: 48.5679278, lng: 18.0056846 },
    { lat: 48.5680175, lng: 18.0056187 },
    { lat: 48.5685298, lng: 18.0057859 },
    { lat: 48.5686547, lng: 18.0057017 },
    { lat: 48.5687429, lng: 18.0054592 },
    { lat: 48.5688445, lng: 18.0053457 },
    { lat: 48.5691018, lng: 18.0052904 },
    { lat: 48.5694675, lng: 18.0049089 },
    { lat: 48.5694513, lng: 18.0046905 },
    { lat: 48.5695493, lng: 18.0046128 },
    { lat: 48.5698817, lng: 18.0046984 },
    { lat: 48.5702543, lng: 18.0044724 },
    { lat: 48.570318, lng: 18.0046128 },
    { lat: 48.570587, lng: 18.004584 },
    { lat: 48.5707913, lng: 18.0043747 },
    { lat: 48.5709222, lng: 18.0039743 },
    { lat: 48.5712501, lng: 18.0038887 },
    { lat: 48.5713201, lng: 18.0039651 },
    { lat: 48.5714846, lng: 18.003887 },
    { lat: 48.5716081, lng: 18.003608 },
    { lat: 48.5718194, lng: 18.0035941 },
    { lat: 48.572401, lng: 18.0033115 },
    { lat: 48.5727821, lng: 18.0028117 },
    { lat: 48.5731367, lng: 18.0027022 },
    { lat: 48.5733037, lng: 18.002494 },
    { lat: 48.5736432, lng: 18.0022974 },
    { lat: 48.5740382, lng: 18.0022412 },
    { lat: 48.5742084, lng: 18.0020454 },
    { lat: 48.5741841, lng: 18.0017322 },
    { lat: 48.5744457, lng: 18.0016534 },
    { lat: 48.5745158, lng: 18.0016945 },
    { lat: 48.5751836, lng: 18.0016483 },
    { lat: 48.5755394, lng: 18.0017699 },
    { lat: 48.5759499, lng: 18.00182 },
    { lat: 48.5760359, lng: 18.0019731 },
    { lat: 48.5761642, lng: 18.0019308 },
    { lat: 48.5763082, lng: 18.0016754 },
    { lat: 48.5763316, lng: 18.0015158 },
    { lat: 48.5765531, lng: 18.0012959 },
    { lat: 48.5767369, lng: 18.0012355 },
    { lat: 48.5770518, lng: 18.000967 },
    { lat: 48.5769884, lng: 18.0007196 },
    { lat: 48.5773005, lng: 18.0005756 },
    { lat: 48.5774055, lng: 18.0003678 },
    { lat: 48.5775063, lng: 18.0003728 },
    { lat: 48.5776316, lng: 18.00026 },
    { lat: 48.5779372, lng: 18.0006358 },
    { lat: 48.5780933, lng: 18.0005284 },
    { lat: 48.5781044, lng: 18.0003922 },
    { lat: 48.5782524, lng: 18.000184 },
    { lat: 48.5784281, lng: 18.0003663 },
    { lat: 48.5787006, lng: 17.9999977 },
    { lat: 48.5788252, lng: 17.9999082 },
    { lat: 48.5788165, lng: 17.9997432 },
    { lat: 48.5788941, lng: 17.9995121 },
    { lat: 48.5791388, lng: 17.9992798 },
    { lat: 48.5794822, lng: 17.9992477 },
    { lat: 48.5796188, lng: 17.9989986 },
    { lat: 48.5797382, lng: 17.9986371 },
    { lat: 48.5799837, lng: 17.9985883 },
    { lat: 48.5800819, lng: 17.9986645 },
    { lat: 48.5801761, lng: 17.9986343 },
    { lat: 48.5805484, lng: 17.9980818 },
    { lat: 48.5806146, lng: 17.9979217 },
    { lat: 48.5806136, lng: 17.9977743 },
    { lat: 48.5808509, lng: 17.9973409 },
    { lat: 48.5808848, lng: 17.9970572 },
    { lat: 48.5809775, lng: 17.9968497 },
    { lat: 48.5809613, lng: 17.9966729 },
    { lat: 48.5811294, lng: 17.9964998 },
    { lat: 48.5811831, lng: 17.9962451 },
    { lat: 48.5813419, lng: 17.9959724 },
    { lat: 48.5814079, lng: 17.9957535 },
    { lat: 48.5815938, lng: 17.9955038 },
    { lat: 48.5816717, lng: 17.995314 },
    { lat: 48.5816942, lng: 17.9949887 },
    { lat: 48.5817797, lng: 17.9949468 },
    { lat: 48.581822, lng: 17.9947868 },
    { lat: 48.582188, lng: 17.9943352 },
    { lat: 48.5823918, lng: 17.9936069 },
    { lat: 48.5827374, lng: 17.9933449 },
    { lat: 48.5827594, lng: 17.9932028 },
    { lat: 48.5831041, lng: 17.9928301 },
    { lat: 48.5834125, lng: 17.9928536 },
    { lat: 48.583609, lng: 17.9924546 },
    { lat: 48.5839344, lng: 17.9921593 },
    { lat: 48.5839578, lng: 17.9922121 },
    { lat: 48.5841263, lng: 17.9922324 },
    { lat: 48.5842497, lng: 17.9920528 },
    { lat: 48.5845274, lng: 17.9920891 },
    { lat: 48.5847129, lng: 17.9918316 },
    { lat: 48.584838, lng: 17.9918471 },
    { lat: 48.5851917, lng: 17.9915865 },
    { lat: 48.5859952, lng: 17.9915477 },
    { lat: 48.5862258, lng: 17.9911544 },
    { lat: 48.586541, lng: 17.9909709 },
    { lat: 48.586702, lng: 17.9910208 },
    { lat: 48.5871299, lng: 17.9903576 },
    { lat: 48.5874155, lng: 17.9895254 },
    { lat: 48.5876079, lng: 17.9892491 },
    { lat: 48.5882993, lng: 17.9889196 },
    { lat: 48.5885866, lng: 17.9887 },
    { lat: 48.5890841, lng: 17.9884872 },
    { lat: 48.5892179, lng: 17.9882713 },
    { lat: 48.5897391, lng: 17.9877387 },
    { lat: 48.589819, lng: 17.9875418 },
    { lat: 48.5910543, lng: 17.9862238 },
    { lat: 48.5912402, lng: 17.986143 },
    { lat: 48.5913588, lng: 17.9858799 },
    { lat: 48.5917335, lng: 17.9854634 },
    { lat: 48.5918317, lng: 17.9852133 },
    { lat: 48.5920982, lng: 17.9848756 },
    { lat: 48.5925937, lng: 17.9838534 },
    { lat: 48.5931085, lng: 17.9830375 },
    { lat: 48.593587, lng: 17.9828486 },
    { lat: 48.5945204, lng: 17.9817859 },
    { lat: 48.5946158, lng: 17.981589 },
    { lat: 48.5948175, lng: 17.9807095 },
    { lat: 48.5949085, lng: 17.9804946 },
    { lat: 48.5948862, lng: 17.9802352 },
    { lat: 48.5950095, lng: 17.9797478 },
    { lat: 48.595081, lng: 17.9790903 },
    { lat: 48.5952463, lng: 17.9782292 },
    { lat: 48.5957269, lng: 17.9774674 },
    { lat: 48.5963088, lng: 17.976833 },
    { lat: 48.596276, lng: 17.976556 },
    { lat: 48.596031, lng: 17.976524 },
    { lat: 48.595843, lng: 17.976486 },
    { lat: 48.595741, lng: 17.976435 },
    { lat: 48.595597, lng: 17.976348 },
    { lat: 48.595416, lng: 17.976251 },
    { lat: 48.595206, lng: 17.976231 },
    { lat: 48.594929, lng: 17.976341 },
    { lat: 48.594638, lng: 17.976516 },
    { lat: 48.594487, lng: 17.976583 },
    { lat: 48.594448, lng: 17.97659 },
    { lat: 48.594076, lng: 17.976419 },
    { lat: 48.593963, lng: 17.976361 },
    { lat: 48.593857, lng: 17.976269 },
    { lat: 48.593767, lng: 17.976188 },
    { lat: 48.593583, lng: 17.976037 },
    { lat: 48.593308, lng: 17.975758 },
    { lat: 48.593151, lng: 17.975655 },
    { lat: 48.592999, lng: 17.975616 },
    { lat: 48.592828, lng: 17.975601 },
    { lat: 48.592682, lng: 17.975609 },
    { lat: 48.592586, lng: 17.975599 },
    { lat: 48.59248, lng: 17.975541 },
    { lat: 48.59219, lng: 17.975375 },
    { lat: 48.591998, lng: 17.975236 },
    { lat: 48.591853, lng: 17.975127 },
    { lat: 48.591723, lng: 17.975046 },
    { lat: 48.591496, lng: 17.974808 },
    { lat: 48.591225, lng: 17.974506 },
    { lat: 48.591158, lng: 17.97446 },
    { lat: 48.591039, lng: 17.974385 },
    { lat: 48.590909, lng: 17.9743539 },
    { lat: 48.5904081, lng: 17.9740172 },
    { lat: 48.5897599, lng: 17.9739924 },
    { lat: 48.5895405, lng: 17.9738699 },
    { lat: 48.5888639, lng: 17.9751135 },
    { lat: 48.5888148, lng: 17.9755394 },
    { lat: 48.588209, lng: 17.9773228 },
    { lat: 48.5880528, lng: 17.977564 },
    { lat: 48.5876487, lng: 17.9779374 },
    { lat: 48.587308, lng: 17.9781141 },
    { lat: 48.5869561, lng: 17.9785917 },
    { lat: 48.5865249, lng: 17.9795364 },
    { lat: 48.5863153, lng: 17.9797341 },
    { lat: 48.5857531, lng: 17.9799197 },
    { lat: 48.5854365, lng: 17.9798545 },
    { lat: 48.5849393, lng: 17.9795044 },
    { lat: 48.5842975, lng: 17.9795503 },
    { lat: 48.5838574, lng: 17.9794163 },
    { lat: 48.5831754, lng: 17.9789219 },
    { lat: 48.5820194, lng: 17.978424 },
    { lat: 48.5814519, lng: 17.977975 },
    { lat: 48.5809644, lng: 17.9773306 },
    { lat: 48.5802078, lng: 17.976173 },
    { lat: 48.5795187, lng: 17.9749499 },
    { lat: 48.5793835, lng: 17.9745754 },
    { lat: 48.5790086, lng: 17.9739885 },
    { lat: 48.5788176, lng: 17.9734152 },
    { lat: 48.5785849, lng: 17.972449 },
    { lat: 48.5784314, lng: 17.9721636 },
    { lat: 48.5782882, lng: 17.9717485 },
    { lat: 48.5770453, lng: 17.971634 },
    { lat: 48.5762672, lng: 17.9713064 },
    { lat: 48.575576, lng: 17.9714885 },
    { lat: 48.5752683, lng: 17.971476 },
    { lat: 48.5743457, lng: 17.9710329 },
    { lat: 48.57381, lng: 17.9711362 },
    { lat: 48.5736542, lng: 17.9712097 },
    { lat: 48.5735159, lng: 17.9713885 },
    { lat: 48.573313, lng: 17.9714328 },
    { lat: 48.5732455, lng: 17.9712452 },
    { lat: 48.5727151, lng: 17.9713064 },
    { lat: 48.5722888, lng: 17.9712187 },
    { lat: 48.5714646, lng: 17.9715728 },
    { lat: 48.5710566, lng: 17.9718858 },
    { lat: 48.5699118, lng: 17.9731639 },
    { lat: 48.5687582, lng: 17.9740359 },
    { lat: 48.5674653, lng: 17.9748981 },
    { lat: 48.5672167, lng: 17.9749846 },
    { lat: 48.5663159, lng: 17.9756639 },
    { lat: 48.5652588, lng: 17.9761863 },
    { lat: 48.5643827, lng: 17.9764828 },
    { lat: 48.5633227, lng: 17.9770874 },
    { lat: 48.562147, lng: 17.9775477 },
    { lat: 48.5616148, lng: 17.9778974 },
    { lat: 48.5598647, lng: 17.978625 },
    { lat: 48.5592374, lng: 17.9789885 },
    { lat: 48.559101, lng: 17.9791256 },
    { lat: 48.5584006, lng: 17.9793826 },
    { lat: 48.5572978, lng: 17.9802327 },
    { lat: 48.5572564, lng: 17.9804456 },
    { lat: 48.5566419, lng: 17.9808541 },
    { lat: 48.5552804, lng: 17.9822501 },
    { lat: 48.5549399, lng: 17.9826905 },
    { lat: 48.5526436, lng: 17.9849938 },
    { lat: 48.5522673, lng: 17.9854227 },
    { lat: 48.5513763, lng: 17.986624 },
    { lat: 48.5511569, lng: 17.9870153 },
    { lat: 48.5510956, lng: 17.9879591 },
    { lat: 48.5512482, lng: 17.9887582 },
    { lat: 48.5516221, lng: 17.9899022 },
    { lat: 48.5510919, lng: 17.9906454 },
    { lat: 48.5500354, lng: 17.9915719 },
    { lat: 48.5502435, lng: 17.9923942 },
    { lat: 48.5501016, lng: 17.9927785 },
    { lat: 48.5501645, lng: 17.9930607 },
    { lat: 48.5491553, lng: 17.9938154 },
    { lat: 48.5485205, lng: 17.9943764 },
    { lat: 48.5484434, lng: 17.9945596 },
    { lat: 48.5488246, lng: 17.9953691 },
    { lat: 48.5488184, lng: 17.995475 },
    { lat: 48.5480056, lng: 17.9966111 },
    { lat: 48.5476219, lng: 17.997428 },
    { lat: 48.5470887, lng: 17.9987468 },
    { lat: 48.5469613, lng: 17.999377 },
    { lat: 48.5466465, lng: 18.0004975 },
    { lat: 48.5462284, lng: 18.00176 },
    { lat: 48.5457557, lng: 18.0029797 },
    { lat: 48.5463344, lng: 18.0042873 },
    { lat: 48.5473281, lng: 18.007219 },
    { lat: 48.5471778, lng: 18.0073294 },
    { lat: 48.5477765, lng: 18.0093736 },
    { lat: 48.5482166, lng: 18.009066 },
    { lat: 48.5490008, lng: 18.0087017 },
    { lat: 48.5491366, lng: 18.0086317 },
  ],
  Velušovce: [
    { lat: 48.6124546, lng: 18.1254904 },
    { lat: 48.6140425, lng: 18.1244881 },
    { lat: 48.6152035, lng: 18.1234963 },
    { lat: 48.6180547, lng: 18.1216861 },
    { lat: 48.6188936, lng: 18.1210544 },
    { lat: 48.6193462, lng: 18.1206851 },
    { lat: 48.6197542, lng: 18.1198637 },
    { lat: 48.6200157, lng: 18.1196279 },
    { lat: 48.6205797, lng: 18.1195263 },
    { lat: 48.6219886, lng: 18.1186462 },
    { lat: 48.6227516, lng: 18.1178929 },
    { lat: 48.6250868, lng: 18.1162551 },
    { lat: 48.6262659, lng: 18.1150508 },
    { lat: 48.6272039, lng: 18.1142633 },
    { lat: 48.627774, lng: 18.1139736 },
    { lat: 48.6291757, lng: 18.1134527 },
    { lat: 48.6306543, lng: 18.1124129 },
    { lat: 48.6309362, lng: 18.112446 },
    { lat: 48.6305428, lng: 18.1109289 },
    { lat: 48.6301964, lng: 18.1100388 },
    { lat: 48.6307272, lng: 18.1094666 },
    { lat: 48.6317419, lng: 18.1086799 },
    { lat: 48.6318571, lng: 18.1089514 },
    { lat: 48.6321142, lng: 18.108965 },
    { lat: 48.6325865, lng: 18.1084748 },
    { lat: 48.6330899, lng: 18.1082434 },
    { lat: 48.6333316, lng: 18.1079568 },
    { lat: 48.6338429, lng: 18.1075314 },
    { lat: 48.6342573, lng: 18.1069578 },
    { lat: 48.6345194, lng: 18.1064777 },
    { lat: 48.6355936, lng: 18.1053382 },
    { lat: 48.6359213, lng: 18.1050937 },
    { lat: 48.6357048, lng: 18.1048043 },
    { lat: 48.6380161, lng: 18.1026623 },
    { lat: 48.6384724, lng: 18.1020895 },
    { lat: 48.6388015, lng: 18.1014095 },
    { lat: 48.6389463, lng: 18.1011222 },
    { lat: 48.6386788, lng: 18.1008909 },
    { lat: 48.6376365, lng: 18.0990308 },
    { lat: 48.6362948, lng: 18.0960809 },
    { lat: 48.6360455, lng: 18.0963314 },
    { lat: 48.6353522, lng: 18.0950525 },
    { lat: 48.6357358, lng: 18.0946482 },
    { lat: 48.6350969, lng: 18.0933129 },
    { lat: 48.6349156, lng: 18.0934559 },
    { lat: 48.6343757, lng: 18.0923489 },
    { lat: 48.6334367, lng: 18.0902106 },
    { lat: 48.6339016, lng: 18.0889754 },
    { lat: 48.632982, lng: 18.0876131 },
    { lat: 48.6324079, lng: 18.0866543 },
    { lat: 48.632172, lng: 18.0869626 },
    { lat: 48.6317547, lng: 18.0860606 },
    { lat: 48.6318892, lng: 18.0858533 },
    { lat: 48.6314907, lng: 18.0848689 },
    { lat: 48.6304393, lng: 18.0863218 },
    { lat: 48.6303165, lng: 18.0859585 },
    { lat: 48.630619, lng: 18.0850544 },
    { lat: 48.6303582, lng: 18.0836271 },
    { lat: 48.6293688, lng: 18.0816128 },
    { lat: 48.6289149, lng: 18.0819945 },
    { lat: 48.6280497, lng: 18.0823119 },
    { lat: 48.6274233, lng: 18.082773 },
    { lat: 48.6255452, lng: 18.0851384 },
    { lat: 48.6250049, lng: 18.0856993 },
    { lat: 48.6242461, lng: 18.0863074 },
    { lat: 48.6236348, lng: 18.0870097 },
    { lat: 48.6227841, lng: 18.0881301 },
    { lat: 48.6220141, lng: 18.0888738 },
    { lat: 48.6218494, lng: 18.0889158 },
    { lat: 48.6203796, lng: 18.0902557 },
    { lat: 48.6201572, lng: 18.0903916 },
    { lat: 48.6195986, lng: 18.0893696 },
    { lat: 48.6195411, lng: 18.0894461 },
    { lat: 48.6193291, lng: 18.0890645 },
    { lat: 48.619134, lng: 18.0886646 },
    { lat: 48.6185861, lng: 18.089384 },
    { lat: 48.6182673, lng: 18.089897 },
    { lat: 48.6176067, lng: 18.0912759 },
    { lat: 48.6169082, lng: 18.0924718 },
    { lat: 48.6165897, lng: 18.0937616 },
    { lat: 48.615657, lng: 18.0951759 },
    { lat: 48.6147075, lng: 18.0970614 },
    { lat: 48.6149849, lng: 18.0973084 },
    { lat: 48.6130449, lng: 18.1003136 },
    { lat: 48.6116126, lng: 18.1023352 },
    { lat: 48.6116517, lng: 18.1024056 },
    { lat: 48.6114263, lng: 18.1026649 },
    { lat: 48.607429, lng: 18.1063494 },
    { lat: 48.6071466, lng: 18.1065788 },
    { lat: 48.6100181, lng: 18.1158184 },
    { lat: 48.6114607, lng: 18.1206981 },
    { lat: 48.6115878, lng: 18.1209791 },
    { lat: 48.6115571, lng: 18.1211294 },
    { lat: 48.6115915, lng: 18.1216426 },
    { lat: 48.6124546, lng: 18.1254904 },
  ],
  Prašice: [
    { lat: 48.691206, lng: 18.096487 },
    { lat: 48.691215, lng: 18.096463 },
    { lat: 48.6913, lng: 18.096234 },
    { lat: 48.691401, lng: 18.095862 },
    { lat: 48.691525, lng: 18.09546 },
    { lat: 48.691568, lng: 18.095059 },
    { lat: 48.691472, lng: 18.094663 },
    { lat: 48.691487, lng: 18.09431 },
    { lat: 48.691549, lng: 18.093919 },
    { lat: 48.691604, lng: 18.093736 },
    { lat: 48.691657, lng: 18.093346 },
    { lat: 48.691802, lng: 18.093198 },
    { lat: 48.69191, lng: 18.09302 },
    { lat: 48.69194, lng: 18.092922 },
    { lat: 48.692073, lng: 18.092489 },
    { lat: 48.69198, lng: 18.09239 },
    { lat: 48.691892, lng: 18.092069 },
    { lat: 48.691968, lng: 18.091915 },
    { lat: 48.691909, lng: 18.091754 },
    { lat: 48.691956, lng: 18.091589 },
    { lat: 48.691935, lng: 18.091483 },
    { lat: 48.692041, lng: 18.091116 },
    { lat: 48.691945, lng: 18.090979 },
    { lat: 48.691949, lng: 18.090969 },
    { lat: 48.692001, lng: 18.090897 },
    { lat: 48.692081, lng: 18.090783 },
    { lat: 48.692077, lng: 18.090755 },
    { lat: 48.692076, lng: 18.090728 },
    { lat: 48.692073, lng: 18.090707 },
    { lat: 48.691945, lng: 18.090668 },
    { lat: 48.69193, lng: 18.090417 },
    { lat: 48.691989, lng: 18.089923 },
    { lat: 48.69211, lng: 18.089748 },
    { lat: 48.692139, lng: 18.089631 },
    { lat: 48.692148, lng: 18.08951 },
    { lat: 48.692077, lng: 18.089408 },
    { lat: 48.692181, lng: 18.089049 },
    { lat: 48.692043, lng: 18.088668 },
    { lat: 48.692176, lng: 18.088301 },
    { lat: 48.692118, lng: 18.088013 },
    { lat: 48.692249, lng: 18.08788 },
    { lat: 48.692507, lng: 18.087494 },
    { lat: 48.692545, lng: 18.087274 },
    { lat: 48.692632, lng: 18.087225 },
    { lat: 48.692724, lng: 18.087026 },
    { lat: 48.692754, lng: 18.08687 },
    { lat: 48.692874, lng: 18.086675 },
    { lat: 48.692951, lng: 18.086605 },
    { lat: 48.693005, lng: 18.086614 },
    { lat: 48.693131, lng: 18.086566 },
    { lat: 48.693335, lng: 18.08641 },
    { lat: 48.693409, lng: 18.086424 },
    { lat: 48.693527, lng: 18.086318 },
    { lat: 48.693621, lng: 18.086071 },
    { lat: 48.693751, lng: 18.085915 },
    { lat: 48.69385, lng: 18.085714 },
    { lat: 48.694004, lng: 18.085528 },
    { lat: 48.694242, lng: 18.085256 },
    { lat: 48.694685, lng: 18.085041 },
    { lat: 48.694792, lng: 18.084885 },
    { lat: 48.695052, lng: 18.084754 },
    { lat: 48.695154, lng: 18.084291 },
    { lat: 48.695243, lng: 18.084349 },
    { lat: 48.695328, lng: 18.084038 },
    { lat: 48.695468, lng: 18.083973 },
    { lat: 48.695535, lng: 18.083738 },
    { lat: 48.695634, lng: 18.083125 },
    { lat: 48.695683, lng: 18.082275 },
    { lat: 48.696042, lng: 18.081372 },
    { lat: 48.696321, lng: 18.080702 },
    { lat: 48.696337, lng: 18.080628 },
    { lat: 48.696352, lng: 18.080556 },
    { lat: 48.696373, lng: 18.080449 },
    { lat: 48.696509, lng: 18.080187 },
    { lat: 48.696588, lng: 18.079539 },
    { lat: 48.696628, lng: 18.079261 },
    { lat: 48.696477, lng: 18.078808 },
    { lat: 48.696614, lng: 18.078244 },
    { lat: 48.696784, lng: 18.07786 },
    { lat: 48.696877, lng: 18.077728 },
    { lat: 48.696891, lng: 18.077613 },
    { lat: 48.696907, lng: 18.077541 },
    { lat: 48.696957, lng: 18.077149 },
    { lat: 48.697055, lng: 18.076591 },
    { lat: 48.69709, lng: 18.076265 },
    { lat: 48.696898, lng: 18.075729 },
    { lat: 48.696859, lng: 18.075627 },
    { lat: 48.696787, lng: 18.075443 },
    { lat: 48.696697, lng: 18.074961 },
    { lat: 48.696444, lng: 18.074251 },
    { lat: 48.696313, lng: 18.073591 },
    { lat: 48.696143, lng: 18.073097 },
    { lat: 48.69613, lng: 18.072971 },
    { lat: 48.696256, lng: 18.072403 },
    { lat: 48.696349, lng: 18.072372 },
    { lat: 48.696402, lng: 18.072017 },
    { lat: 48.696392, lng: 18.07176 },
    { lat: 48.696411, lng: 18.071564 },
    { lat: 48.696329, lng: 18.071444 },
    { lat: 48.69634, lng: 18.071111 },
    { lat: 48.696344, lng: 18.070718 },
    { lat: 48.696315, lng: 18.070447 },
    { lat: 48.696361, lng: 18.070277 },
    { lat: 48.696419, lng: 18.070012 },
    { lat: 48.696491, lng: 18.069794 },
    { lat: 48.696571, lng: 18.069368 },
    { lat: 48.696583, lng: 18.069061 },
    { lat: 48.696789, lng: 18.068451 },
    { lat: 48.696944, lng: 18.068083 },
    { lat: 48.697023, lng: 18.068005 },
    { lat: 48.697098, lng: 18.067892 },
    { lat: 48.697188, lng: 18.067731 },
    { lat: 48.697406, lng: 18.066944 },
    { lat: 48.697617, lng: 18.066367 },
    { lat: 48.697789, lng: 18.064519 },
    { lat: 48.6978, lng: 18.064416 },
    { lat: 48.697876, lng: 18.063557 },
    { lat: 48.697994, lng: 18.063288 },
    { lat: 48.698125, lng: 18.062982 },
    { lat: 48.698415, lng: 18.062284 },
    { lat: 48.698568, lng: 18.061935 },
    { lat: 48.699173, lng: 18.060478 },
    { lat: 48.699224, lng: 18.06037 },
    { lat: 48.699111, lng: 18.060295 },
    { lat: 48.698891, lng: 18.059985 },
    { lat: 48.698799, lng: 18.059875 },
    { lat: 48.698648, lng: 18.059716 },
    { lat: 48.698561, lng: 18.05958 },
    { lat: 48.698352, lng: 18.059366 },
    { lat: 48.698023, lng: 18.059185 },
    { lat: 48.697811, lng: 18.059104 },
    { lat: 48.697638, lng: 18.059006 },
    { lat: 48.697435, lng: 18.058839 },
    { lat: 48.697267, lng: 18.058601 },
    { lat: 48.69808, lng: 18.057054 },
    { lat: 48.698431, lng: 18.056363 },
    { lat: 48.699151, lng: 18.053637 },
    { lat: 48.699227, lng: 18.053319 },
    { lat: 48.699239, lng: 18.053264 },
    { lat: 48.699427, lng: 18.052412 },
    { lat: 48.699698, lng: 18.051248 },
    { lat: 48.699947, lng: 18.048873 },
    { lat: 48.700561, lng: 18.04689 },
    { lat: 48.6996668, lng: 18.0478672 },
    { lat: 48.6984296, lng: 18.0483028 },
    { lat: 48.6976174, lng: 18.0475627 },
    { lat: 48.6967349, lng: 18.0470675 },
    { lat: 48.696043, lng: 18.0464338 },
    { lat: 48.6951732, lng: 18.0458244 },
    { lat: 48.694148, lng: 18.0456288 },
    { lat: 48.6934541, lng: 18.0453841 },
    { lat: 48.692838, lng: 18.0452872 },
    { lat: 48.6920094, lng: 18.0458222 },
    { lat: 48.6916871, lng: 18.0462485 },
    { lat: 48.6916172, lng: 18.0464433 },
    { lat: 48.6915585, lng: 18.0464437 },
    { lat: 48.6912937, lng: 18.0463156 },
    { lat: 48.6911605, lng: 18.0460503 },
    { lat: 48.6904412, lng: 18.0452578 },
    { lat: 48.6901065, lng: 18.0451885 },
    { lat: 48.6893804, lng: 18.0446259 },
    { lat: 48.6883708, lng: 18.0443651 },
    { lat: 48.6882419, lng: 18.0441885 },
    { lat: 48.6878799, lng: 18.0445032 },
    { lat: 48.687419, lng: 18.0446484 },
    { lat: 48.6869989, lng: 18.0450281 },
    { lat: 48.6863843, lng: 18.0454386 },
    { lat: 48.6856764, lng: 18.0456426 },
    { lat: 48.685392, lng: 18.045833 },
    { lat: 48.6845996, lng: 18.0471003 },
    { lat: 48.6841186, lng: 18.0477635 },
    { lat: 48.6840564, lng: 18.047764 },
    { lat: 48.6839324, lng: 18.0479946 },
    { lat: 48.6839404, lng: 18.0480539 },
    { lat: 48.6834898, lng: 18.0487766 },
    { lat: 48.6832306, lng: 18.0493382 },
    { lat: 48.6830641, lng: 18.0492349 },
    { lat: 48.6830845, lng: 18.04956 },
    { lat: 48.6827507, lng: 18.0502184 },
    { lat: 48.6826309, lng: 18.0507335 },
    { lat: 48.6826165, lng: 18.0514458 },
    { lat: 48.6823504, lng: 18.0513919 },
    { lat: 48.682173, lng: 18.0511589 },
    { lat: 48.6821521, lng: 18.051017 },
    { lat: 48.6820424, lng: 18.0492099 },
    { lat: 48.6821302, lng: 18.0489774 },
    { lat: 48.6825645, lng: 18.0482651 },
    { lat: 48.6825859, lng: 18.047993 },
    { lat: 48.6824901, lng: 18.0473619 },
    { lat: 48.6823942, lng: 18.0471151 },
    { lat: 48.6822841, lng: 18.0470285 },
    { lat: 48.6819833, lng: 18.0469046 },
    { lat: 48.6817796, lng: 18.0468966 },
    { lat: 48.6818682, lng: 18.0467289 },
    { lat: 48.6817907, lng: 18.0463936 },
    { lat: 48.6815441, lng: 18.0458485 },
    { lat: 48.6813315, lng: 18.0455924 },
    { lat: 48.6812782, lng: 18.0452207 },
    { lat: 48.6810388, lng: 18.0446457 },
    { lat: 48.6808138, lng: 18.0435087 },
    { lat: 48.6808586, lng: 18.0430052 },
    { lat: 48.6808124, lng: 18.0424143 },
    { lat: 48.6807566, lng: 18.0422139 },
    { lat: 48.680754, lng: 18.0417285 },
    { lat: 48.6806245, lng: 18.0408906 },
    { lat: 48.6805094, lng: 18.0406435 },
    { lat: 48.6803856, lng: 18.0400355 },
    { lat: 48.6800687, lng: 18.0378742 },
    { lat: 48.679574, lng: 18.0365496 },
    { lat: 48.6791493, lng: 18.0360289 },
    { lat: 48.6785286, lng: 18.0354932 },
    { lat: 48.6780997, lng: 18.0354155 },
    { lat: 48.677791, lng: 18.0355028 },
    { lat: 48.6760229, lng: 18.0377684 },
    { lat: 48.6751003, lng: 18.0382003 },
    { lat: 48.674434, lng: 18.0391498 },
    { lat: 48.6738794, lng: 18.0397725 },
    { lat: 48.6732488, lng: 18.0401237 },
    { lat: 48.6726177, lng: 18.0411316 },
    { lat: 48.672086, lng: 18.042375 },
    { lat: 48.6717555, lng: 18.0426869 },
    { lat: 48.6712407, lng: 18.0434699 },
    { lat: 48.671125, lng: 18.0436897 },
    { lat: 48.6710032, lng: 18.0443238 },
    { lat: 48.6709177, lng: 18.044461 },
    { lat: 48.6706539, lng: 18.0446771 },
    { lat: 48.6702256, lng: 18.0454149 },
    { lat: 48.6698533, lng: 18.0457802 },
    { lat: 48.6698423, lng: 18.0459577 },
    { lat: 48.6697463, lng: 18.0460713 },
    { lat: 48.6696685, lng: 18.046131 },
    { lat: 48.6695524, lng: 18.0460191 },
    { lat: 48.6693409, lng: 18.0465474 },
    { lat: 48.6692151, lng: 18.0470746 },
    { lat: 48.6687533, lng: 18.0482199 },
    { lat: 48.6687829, lng: 18.0500441 },
    { lat: 48.6685878, lng: 18.0513689 },
    { lat: 48.6681515, lng: 18.0523547 },
    { lat: 48.6681528, lng: 18.0524724 },
    { lat: 48.6679701, lng: 18.0526876 },
    { lat: 48.6675156, lng: 18.0529824 },
    { lat: 48.6670254, lng: 18.0531478 },
    { lat: 48.6670028, lng: 18.0532423 },
    { lat: 48.6666826, lng: 18.0532466 },
    { lat: 48.6665917, lng: 18.0537908 },
    { lat: 48.6667811, lng: 18.0562623 },
    { lat: 48.6668564, lng: 18.0566217 },
    { lat: 48.6668477, lng: 18.0571652 },
    { lat: 48.666726, lng: 18.0576096 },
    { lat: 48.6665724, lng: 18.0587981 },
    { lat: 48.6666098, lng: 18.0591637 },
    { lat: 48.66653, lng: 18.0596489 },
    { lat: 48.6664282, lng: 18.0610672 },
    { lat: 48.6666167, lng: 18.0612004 },
    { lat: 48.6666384, lng: 18.0610708 },
    { lat: 48.6672057, lng: 18.0612583 },
    { lat: 48.6671982, lng: 18.0614771 },
    { lat: 48.666567, lng: 18.0615084 },
    { lat: 48.666409, lng: 18.0617581 },
    { lat: 48.6662629, lng: 18.0623268 },
    { lat: 48.6661175, lng: 18.062653 },
    { lat: 48.6659121, lng: 18.0629804 },
    { lat: 48.6658476, lng: 18.0632472 },
    { lat: 48.6655142, lng: 18.0636228 },
    { lat: 48.6649983, lng: 18.0650819 },
    { lat: 48.6647778, lng: 18.0653617 },
    { lat: 48.6641521, lng: 18.0672356 },
    { lat: 48.6638732, lng: 18.0676164 },
    { lat: 48.6637441, lng: 18.0681078 },
    { lat: 48.6633285, lng: 18.0687593 },
    { lat: 48.6627372, lng: 18.0705103 },
    { lat: 48.6623577, lng: 18.0714457 },
    { lat: 48.6617118, lng: 18.0726142 },
    { lat: 48.6616401, lng: 18.0728509 },
    { lat: 48.6615242, lng: 18.0729349 },
    { lat: 48.6614434, lng: 18.0731539 },
    { lat: 48.661161, lng: 18.0733994 },
    { lat: 48.6611048, lng: 18.0737122 },
    { lat: 48.6609967, lng: 18.0738316 },
    { lat: 48.6606895, lng: 18.0739241 },
    { lat: 48.6602303, lng: 18.0744663 },
    { lat: 48.659979, lng: 18.0745878 },
    { lat: 48.6598696, lng: 18.0745303 },
    { lat: 48.6596867, lng: 18.0746447 },
    { lat: 48.659388, lng: 18.0746489 },
    { lat: 48.6587505, lng: 18.0748645 },
    { lat: 48.6581909, lng: 18.0748665 },
    { lat: 48.6574876, lng: 18.0747906 },
    { lat: 48.6572492, lng: 18.0748799 },
    { lat: 48.6569486, lng: 18.0747788 },
    { lat: 48.6563963, lng: 18.0747687 },
    { lat: 48.6555766, lng: 18.0748623 },
    { lat: 48.6550482, lng: 18.0752999 },
    { lat: 48.6549198, lng: 18.0753195 },
    { lat: 48.6544333, lng: 18.0760688 },
    { lat: 48.6541519, lng: 18.0766976 },
    { lat: 48.6539621, lng: 18.0768297 },
    { lat: 48.6542845, lng: 18.0794391 },
    { lat: 48.654459, lng: 18.0800675 },
    { lat: 48.6546635, lng: 18.0804015 },
    { lat: 48.6547682, lng: 18.0809722 },
    { lat: 48.6546504, lng: 18.0813513 },
    { lat: 48.654985, lng: 18.0819188 },
    { lat: 48.6549872, lng: 18.0823197 },
    { lat: 48.6538, lng: 18.0835148 },
    { lat: 48.6520028, lng: 18.0854898 },
    { lat: 48.6501703, lng: 18.0877313 },
    { lat: 48.6495278, lng: 18.0884945 },
    { lat: 48.6492486, lng: 18.0888722 },
    { lat: 48.6491254, lng: 18.0890817 },
    { lat: 48.6488498, lng: 18.0899251 },
    { lat: 48.6485373, lng: 18.0905814 },
    { lat: 48.6482955, lng: 18.0913303 },
    { lat: 48.6481384, lng: 18.0912281 },
    { lat: 48.6475515, lng: 18.090382 },
    { lat: 48.6470805, lng: 18.0907585 },
    { lat: 48.646742, lng: 18.0911855 },
    { lat: 48.6457317, lng: 18.0933381 },
    { lat: 48.646052, lng: 18.0937608 },
    { lat: 48.6455063, lng: 18.0949059 },
    { lat: 48.6454241, lng: 18.0952556 },
    { lat: 48.6452936, lng: 18.0954775 },
    { lat: 48.6451988, lng: 18.0953613 },
    { lat: 48.6438619, lng: 18.0971635 },
    { lat: 48.6430805, lng: 18.0980406 },
    { lat: 48.6428901, lng: 18.0982012 },
    { lat: 48.6427881, lng: 18.0979254 },
    { lat: 48.6418812, lng: 18.0983948 },
    { lat: 48.6406372, lng: 18.0991621 },
    { lat: 48.6394518, lng: 18.1003694 },
    { lat: 48.6389463, lng: 18.1011222 },
    { lat: 48.6388015, lng: 18.1014095 },
    { lat: 48.6384724, lng: 18.1020895 },
    { lat: 48.6380161, lng: 18.1026623 },
    { lat: 48.6357048, lng: 18.1048043 },
    { lat: 48.6359213, lng: 18.1050937 },
    { lat: 48.6355936, lng: 18.1053382 },
    { lat: 48.6345194, lng: 18.1064777 },
    { lat: 48.6342573, lng: 18.1069578 },
    { lat: 48.6338429, lng: 18.1075314 },
    { lat: 48.6333316, lng: 18.1079568 },
    { lat: 48.6330899, lng: 18.1082434 },
    { lat: 48.6325865, lng: 18.1084748 },
    { lat: 48.6321142, lng: 18.108965 },
    { lat: 48.6318571, lng: 18.1089514 },
    { lat: 48.6317419, lng: 18.1086799 },
    { lat: 48.6307272, lng: 18.1094666 },
    { lat: 48.6301964, lng: 18.1100388 },
    { lat: 48.6305428, lng: 18.1109289 },
    { lat: 48.6309362, lng: 18.112446 },
    { lat: 48.6306543, lng: 18.1124129 },
    { lat: 48.6291757, lng: 18.1134527 },
    { lat: 48.627774, lng: 18.1139736 },
    { lat: 48.6272039, lng: 18.1142633 },
    { lat: 48.6262659, lng: 18.1150508 },
    { lat: 48.6250868, lng: 18.1162551 },
    { lat: 48.6227516, lng: 18.1178929 },
    { lat: 48.6219886, lng: 18.1186462 },
    { lat: 48.6205797, lng: 18.1195263 },
    { lat: 48.6200157, lng: 18.1196279 },
    { lat: 48.6197542, lng: 18.1198637 },
    { lat: 48.6193462, lng: 18.1206851 },
    { lat: 48.6188936, lng: 18.1210544 },
    { lat: 48.6180547, lng: 18.1216861 },
    { lat: 48.6152035, lng: 18.1234963 },
    { lat: 48.6140425, lng: 18.1244881 },
    { lat: 48.6124546, lng: 18.1254904 },
    { lat: 48.6133354, lng: 18.1288523 },
    { lat: 48.6139423, lng: 18.130202 },
    { lat: 48.6141182, lng: 18.1307821 },
    { lat: 48.6141955, lng: 18.1315283 },
    { lat: 48.61406, lng: 18.1321262 },
    { lat: 48.6139233, lng: 18.1324535 },
    { lat: 48.6139074, lng: 18.1333258 },
    { lat: 48.6139876, lng: 18.1340049 },
    { lat: 48.6140776, lng: 18.1343853 },
    { lat: 48.6142842, lng: 18.1348344 },
    { lat: 48.614014, lng: 18.1350596 },
    { lat: 48.6145526, lng: 18.1369837 },
    { lat: 48.6147731, lng: 18.1378643 },
    { lat: 48.6150457, lng: 18.1377202 },
    { lat: 48.6163754, lng: 18.1371794 },
    { lat: 48.6176716, lng: 18.136823 },
    { lat: 48.6178197, lng: 18.1368281 },
    { lat: 48.6179192, lng: 18.1370271 },
    { lat: 48.6188721, lng: 18.1407199 },
    { lat: 48.6192513, lng: 18.1429724 },
    { lat: 48.6193381, lng: 18.1450097 },
    { lat: 48.6197078, lng: 18.1467296 },
    { lat: 48.6189082, lng: 18.1471263 },
    { lat: 48.6191769, lng: 18.1475955 },
    { lat: 48.6191949, lng: 18.1480607 },
    { lat: 48.6193664, lng: 18.1485667 },
    { lat: 48.6194754, lng: 18.1486553 },
    { lat: 48.6198141, lng: 18.1494555 },
    { lat: 48.6198479, lng: 18.1497243 },
    { lat: 48.6197953, lng: 18.1501004 },
    { lat: 48.61967, lng: 18.1505489 },
    { lat: 48.6196876, lng: 18.1509296 },
    { lat: 48.6198792, lng: 18.1517618 },
    { lat: 48.6202539, lng: 18.1529646 },
    { lat: 48.6207495, lng: 18.1527001 },
    { lat: 48.6209907, lng: 18.1540358 },
    { lat: 48.6214203, lng: 18.1545723 },
    { lat: 48.6217294, lng: 18.1551553 },
    { lat: 48.6221213, lng: 18.1567137 },
    { lat: 48.6229645, lng: 18.1594642 },
    { lat: 48.6241433, lng: 18.159085 },
    { lat: 48.6249959, lng: 18.158822 },
    { lat: 48.6282534, lng: 18.156403 },
    { lat: 48.6292986, lng: 18.1556892 },
    { lat: 48.630999, lng: 18.1540902 },
    { lat: 48.6319827, lng: 18.1533424 },
    { lat: 48.6332801, lng: 18.1529498 },
    { lat: 48.6338072, lng: 18.1523093 },
    { lat: 48.6341296, lng: 18.1520502 },
    { lat: 48.6354626, lng: 18.1516272 },
    { lat: 48.6358789, lng: 18.1513724 },
    { lat: 48.6362175, lng: 18.1510611 },
    { lat: 48.6360265, lng: 18.1503182 },
    { lat: 48.6357572, lng: 18.1489259 },
    { lat: 48.6355717, lng: 18.1474979 },
    { lat: 48.6355159, lng: 18.1467321 },
    { lat: 48.6358547, lng: 18.1422306 },
    { lat: 48.6360615, lng: 18.1413413 },
    { lat: 48.6358784, lng: 18.1378268 },
    { lat: 48.6355206, lng: 18.1352246 },
    { lat: 48.6353588, lng: 18.1333943 },
    { lat: 48.6352529, lng: 18.1310313 },
    { lat: 48.6351365, lng: 18.1298269 },
    { lat: 48.6349716, lng: 18.1289129 },
    { lat: 48.6347141, lng: 18.126741 },
    { lat: 48.6347326, lng: 18.1266522 },
    { lat: 48.6349159, lng: 18.1264666 },
    { lat: 48.6363356, lng: 18.126136 },
    { lat: 48.6367932, lng: 18.1261067 },
    { lat: 48.6376743, lng: 18.1258717 },
    { lat: 48.6376669, lng: 18.1259191 },
    { lat: 48.6402671, lng: 18.1249301 },
    { lat: 48.6427255, lng: 18.1243973 },
    { lat: 48.6435877, lng: 18.123914 },
    { lat: 48.6439189, lng: 18.1240013 },
    { lat: 48.6441022, lng: 18.1239124 },
    { lat: 48.6448278, lng: 18.1239104 },
    { lat: 48.6477362, lng: 18.122947 },
    { lat: 48.648734, lng: 18.1228145 },
    { lat: 48.6492258, lng: 18.122654 },
    { lat: 48.6522072, lng: 18.1221087 },
    { lat: 48.6533346, lng: 18.1217031 },
    { lat: 48.6535887, lng: 18.1217465 },
    { lat: 48.6549049, lng: 18.1216153 },
    { lat: 48.6560551, lng: 18.121156 },
    { lat: 48.6561085, lng: 18.1209072 },
    { lat: 48.6568502, lng: 18.1204111 },
    { lat: 48.6568422, lng: 18.1203697 },
    { lat: 48.6576267, lng: 18.1199657 },
    { lat: 48.6577869, lng: 18.1198098 },
    { lat: 48.6579987, lng: 18.119938 },
    { lat: 48.658216, lng: 18.1197757 },
    { lat: 48.659356, lng: 18.1194083 },
    { lat: 48.6606014, lng: 18.1185293 },
    { lat: 48.6604694, lng: 18.1181081 },
    { lat: 48.6603933, lng: 18.1178651 },
    { lat: 48.6598983, lng: 18.117001 },
    { lat: 48.6604893, lng: 18.1158172 },
    { lat: 48.6604715, lng: 18.1157225 },
    { lat: 48.6606187, lng: 18.1153838 },
    { lat: 48.6606443, lng: 18.1151248 },
    { lat: 48.6608726, lng: 18.1144169 },
    { lat: 48.661167, lng: 18.1137927 },
    { lat: 48.6610257, lng: 18.1130357 },
    { lat: 48.661357, lng: 18.113007 },
    { lat: 48.6617288, lng: 18.1128794 },
    { lat: 48.6630165, lng: 18.1123405 },
    { lat: 48.6625772, lng: 18.1109231 },
    { lat: 48.6627225, lng: 18.1108206 },
    { lat: 48.6630897, lng: 18.1103448 },
    { lat: 48.6635104, lng: 18.1094699 },
    { lat: 48.6631816, lng: 18.1087678 },
    { lat: 48.6630126, lng: 18.1080399 },
    { lat: 48.663882, lng: 18.1076393 },
    { lat: 48.6645596, lng: 18.1072411 },
    { lat: 48.6649726, lng: 18.1067959 },
    { lat: 48.6656895, lng: 18.1065102 },
    { lat: 48.6670745, lng: 18.1050887 },
    { lat: 48.667429, lng: 18.1046424 },
    { lat: 48.6679037, lng: 18.1044046 },
    { lat: 48.6684336, lng: 18.1039502 },
    { lat: 48.6686155, lng: 18.1037243 },
    { lat: 48.6689086, lng: 18.1031761 },
    { lat: 48.6692586, lng: 18.1028121 },
    { lat: 48.6698064, lng: 18.102647 },
    { lat: 48.6705665, lng: 18.1022509 },
    { lat: 48.6716007, lng: 18.1014297 },
    { lat: 48.6724385, lng: 18.1013392 },
    { lat: 48.6733233, lng: 18.1009942 },
    { lat: 48.6738609, lng: 18.1009008 },
    { lat: 48.6744503, lng: 18.1009829 },
    { lat: 48.6749967, lng: 18.1004477 },
    { lat: 48.676136, lng: 18.0995467 },
    { lat: 48.677704, lng: 18.0986675 },
    { lat: 48.6784885, lng: 18.0979194 },
    { lat: 48.6791335, lng: 18.0976991 },
    { lat: 48.679569, lng: 18.0973083 },
    { lat: 48.6800605, lng: 18.0967395 },
    { lat: 48.6808626, lng: 18.0955008 },
    { lat: 48.6820077, lng: 18.0944696 },
    { lat: 48.6824632, lng: 18.0944617 },
    { lat: 48.6831297, lng: 18.0946653 },
    { lat: 48.6835926, lng: 18.0945332 },
    { lat: 48.6841433, lng: 18.0945322 },
    { lat: 48.6848774, lng: 18.0940997 },
    { lat: 48.6851462, lng: 18.0938724 },
    { lat: 48.6853408, lng: 18.0938596 },
    { lat: 48.6857173, lng: 18.0939294 },
    { lat: 48.6864158, lng: 18.0942184 },
    { lat: 48.6873469, lng: 18.0941628 },
    { lat: 48.6877482, lng: 18.0944207 },
    { lat: 48.688047, lng: 18.0950945 },
    { lat: 48.6882941, lng: 18.0959238 },
    { lat: 48.6886659, lng: 18.0963003 },
    { lat: 48.688795, lng: 18.0965378 },
    { lat: 48.6891246, lng: 18.0969457 },
    { lat: 48.6894911, lng: 18.0970543 },
    { lat: 48.6899602, lng: 18.0970289 },
    { lat: 48.6903095, lng: 18.0968873 },
    { lat: 48.6905932, lng: 18.0965648 },
    { lat: 48.6908435, lng: 18.0963853 },
    { lat: 48.691206, lng: 18.096487 },
  ],
  Čermany: [
    { lat: 48.4783511, lng: 18.0675962 },
    { lat: 48.4792927, lng: 18.064368 },
    { lat: 48.4818754, lng: 18.0550808 },
    { lat: 48.4819491, lng: 18.0547031 },
    { lat: 48.4821214, lng: 18.0528434 },
    { lat: 48.4821216, lng: 18.0521973 },
    { lat: 48.4823087, lng: 18.0514295 },
    { lat: 48.4831752, lng: 18.0503573 },
    { lat: 48.4850743, lng: 18.0436483 },
    { lat: 48.4855056, lng: 18.041991 },
    { lat: 48.4856922, lng: 18.0399569 },
    { lat: 48.4849857, lng: 18.0401067 },
    { lat: 48.4842651, lng: 18.040099 },
    { lat: 48.4837305, lng: 18.0393068 },
    { lat: 48.4839189, lng: 18.0385985 },
    { lat: 48.4830814, lng: 18.0382177 },
    { lat: 48.4826484, lng: 18.038186 },
    { lat: 48.4814506, lng: 18.0387108 },
    { lat: 48.4802927, lng: 18.0381253 },
    { lat: 48.4800459, lng: 18.0376148 },
    { lat: 48.4795159, lng: 18.0360014 },
    { lat: 48.4790562, lng: 18.034797 },
    { lat: 48.4792767, lng: 18.0342872 },
    { lat: 48.4795144, lng: 18.03352 },
    { lat: 48.4804835, lng: 18.0315516 },
    { lat: 48.4805963, lng: 18.031455 },
    { lat: 48.4813731, lng: 18.0300477 },
    { lat: 48.4821463, lng: 18.0287757 },
    { lat: 48.4842929, lng: 18.0249278 },
    { lat: 48.4848419, lng: 18.0241354 },
    { lat: 48.4852622, lng: 18.0237449 },
    { lat: 48.485571, lng: 18.0230339 },
    { lat: 48.4857667, lng: 18.0224138 },
    { lat: 48.4859284, lng: 18.0221697 },
    { lat: 48.4861061, lng: 18.0220836 },
    { lat: 48.486433, lng: 18.0217187 },
    { lat: 48.4871894, lng: 18.0204839 },
    { lat: 48.4874476, lng: 18.0202119 },
    { lat: 48.4880439, lng: 18.0198013 },
    { lat: 48.4894318, lng: 18.0183876 },
    { lat: 48.4898976, lng: 18.0181748 },
    { lat: 48.4901261, lng: 18.0180687 },
    { lat: 48.489896, lng: 18.0171763 },
    { lat: 48.4897542, lng: 18.0158713 },
    { lat: 48.4897846, lng: 18.0149784 },
    { lat: 48.4896877, lng: 18.0138952 },
    { lat: 48.4897444, lng: 18.0125732 },
    { lat: 48.4896593, lng: 18.0107799 },
    { lat: 48.4890996, lng: 18.0090149 },
    { lat: 48.4889522, lng: 18.0079033 },
    { lat: 48.488542, lng: 18.0074205 },
    { lat: 48.4882035, lng: 18.0071174 },
    { lat: 48.4878815, lng: 18.0066943 },
    { lat: 48.4875956, lng: 18.0064051 },
    { lat: 48.4868035, lng: 18.005935 },
    { lat: 48.4858811, lng: 18.0051519 },
    { lat: 48.485582, lng: 18.0047923 },
    { lat: 48.4854088, lng: 18.0044811 },
    { lat: 48.4848904, lng: 18.0040358 },
    { lat: 48.4835843, lng: 18.0036734 },
    { lat: 48.4821981, lng: 18.0031204 },
    { lat: 48.4819241, lng: 18.0031072 },
    { lat: 48.4812224, lng: 18.0033575 },
    { lat: 48.4805323, lng: 18.0038784 },
    { lat: 48.4800978, lng: 18.0040895 },
    { lat: 48.4786051, lng: 18.0044581 },
    { lat: 48.4773853, lng: 18.0045405 },
    { lat: 48.4770886, lng: 18.0044858 },
    { lat: 48.4765712, lng: 18.0040631 },
    { lat: 48.4761446, lng: 18.0038335 },
    { lat: 48.4744108, lng: 18.0039816 },
    { lat: 48.4737007, lng: 18.0039797 },
    { lat: 48.4738995, lng: 18.0027702 },
    { lat: 48.4739086, lng: 18.0024112 },
    { lat: 48.4737956, lng: 18.0017242 },
    { lat: 48.4737286, lng: 18.0015838 },
    { lat: 48.4735041, lng: 18.0013811 },
    { lat: 48.4727655, lng: 18.0011266 },
    { lat: 48.4725786, lng: 18.0010942 },
    { lat: 48.4716528, lng: 18.0013363 },
    { lat: 48.4712546, lng: 18.0013419 },
    { lat: 48.4709729, lng: 18.0012343 },
    { lat: 48.4705846, lng: 18.0008925 },
    { lat: 48.4696193, lng: 18.0004705 },
    { lat: 48.4688419, lng: 18.0003049 },
    { lat: 48.4680359, lng: 18.0003811 },
    { lat: 48.4673061, lng: 18.0009738 },
    { lat: 48.4670386, lng: 18.0011247 },
    { lat: 48.4655952, lng: 18.0010744 },
    { lat: 48.4654711, lng: 18.0011306 },
    { lat: 48.46507, lng: 18.0015602 },
    { lat: 48.4640323, lng: 18.0020275 },
    { lat: 48.4634425, lng: 18.0024533 },
    { lat: 48.4632341, lng: 18.0027032 },
    { lat: 48.463225, lng: 18.002718 },
    { lat: 48.46327, lng: 18.002865 },
    { lat: 48.463369, lng: 18.003168 },
    { lat: 48.463409, lng: 18.003297 },
    { lat: 48.46343, lng: 18.003366 },
    { lat: 48.463466, lng: 18.003509 },
    { lat: 48.463498, lng: 18.003636 },
    { lat: 48.463539, lng: 18.003805 },
    { lat: 48.463537, lng: 18.003888 },
    { lat: 48.463524, lng: 18.004131 },
    { lat: 48.463512, lng: 18.004371 },
    { lat: 48.463518, lng: 18.004671 },
    { lat: 48.463525, lng: 18.005043 },
    { lat: 48.463543, lng: 18.005502 },
    { lat: 48.463554, lng: 18.005807 },
    { lat: 48.463573, lng: 18.006227 },
    { lat: 48.463579, lng: 18.006344 },
    { lat: 48.463588, lng: 18.006607 },
    { lat: 48.463593, lng: 18.006758 },
    { lat: 48.463601, lng: 18.006979 },
    { lat: 48.463605, lng: 18.007084 },
    { lat: 48.46361, lng: 18.007133 },
    { lat: 48.463651, lng: 18.0074 },
    { lat: 48.463694, lng: 18.007685 },
    { lat: 48.463665, lng: 18.007858 },
    { lat: 48.463627, lng: 18.008084 },
    { lat: 48.463587, lng: 18.008237 },
    { lat: 48.463539, lng: 18.008431 },
    { lat: 48.463472, lng: 18.008684 },
    { lat: 48.463467, lng: 18.008796 },
    { lat: 48.463454, lng: 18.008994 },
    { lat: 48.463448, lng: 18.00925 },
    { lat: 48.463445, lng: 18.00936 },
    { lat: 48.463439, lng: 18.00948 },
    { lat: 48.463428, lng: 18.00964 },
    { lat: 48.463427, lng: 18.009648 },
    { lat: 48.463452, lng: 18.009861 },
    { lat: 48.463459, lng: 18.009919 },
    { lat: 48.463487, lng: 18.010066 },
    { lat: 48.463501, lng: 18.010137 },
    { lat: 48.463551, lng: 18.010331 },
    { lat: 48.463566, lng: 18.010392 },
    { lat: 48.463637, lng: 18.010612 },
    { lat: 48.463654, lng: 18.010662 },
    { lat: 48.463687, lng: 18.010776 },
    { lat: 48.463709, lng: 18.01085 },
    { lat: 48.463729, lng: 18.01094 },
    { lat: 48.463761, lng: 18.01107 },
    { lat: 48.463773, lng: 18.011117 },
    { lat: 48.463836, lng: 18.011291 },
    { lat: 48.463871, lng: 18.011381 },
    { lat: 48.463944, lng: 18.011554 },
    { lat: 48.464013, lng: 18.011708 },
    { lat: 48.464047, lng: 18.011776 },
    { lat: 48.464102, lng: 18.011876 },
    { lat: 48.46416, lng: 18.011979 },
    { lat: 48.464215, lng: 18.012087 },
    { lat: 48.464278, lng: 18.012207 },
    { lat: 48.464343, lng: 18.012346 },
    { lat: 48.464398, lng: 18.012462 },
    { lat: 48.464459, lng: 18.012592 },
    { lat: 48.464545, lng: 18.01277 },
    { lat: 48.464611, lng: 18.012938 },
    { lat: 48.464649, lng: 18.013034 },
    { lat: 48.464689, lng: 18.013165 },
    { lat: 48.464703, lng: 18.013211 },
    { lat: 48.464712, lng: 18.013293 },
    { lat: 48.464704, lng: 18.013376 },
    { lat: 48.464698, lng: 18.013509 },
    { lat: 48.464692, lng: 18.01358 },
    { lat: 48.464677, lng: 18.013704 },
    { lat: 48.464663, lng: 18.013806 },
    { lat: 48.464663, lng: 18.013807 },
    { lat: 48.464642, lng: 18.013842 },
    { lat: 48.464641, lng: 18.013844 },
    { lat: 48.46462, lng: 18.013882 },
    { lat: 48.464535, lng: 18.01402 },
    { lat: 48.464525, lng: 18.014035 },
    { lat: 48.464407, lng: 18.014224 },
    { lat: 48.464244, lng: 18.014484 },
    { lat: 48.464117, lng: 18.014719 },
    { lat: 48.464007, lng: 18.014922 },
    { lat: 48.463835, lng: 18.015236 },
    { lat: 48.463745, lng: 18.015496 },
    { lat: 48.463643, lng: 18.015777 },
    { lat: 48.463624, lng: 18.015833 },
    { lat: 48.463575, lng: 18.01598 },
    { lat: 48.463564, lng: 18.016013 },
    { lat: 48.46355, lng: 18.016052 },
    { lat: 48.463534, lng: 18.016098 },
    { lat: 48.463471, lng: 18.016278 },
    { lat: 48.463423, lng: 18.016417 },
    { lat: 48.463389, lng: 18.016634 },
    { lat: 48.463447, lng: 18.016677 },
    { lat: 48.463585, lng: 18.01677 },
    { lat: 48.463739, lng: 18.016873 },
    { lat: 48.463768, lng: 18.016893 },
    { lat: 48.463948, lng: 18.017013 },
    { lat: 48.464151, lng: 18.01715 },
    { lat: 48.464368, lng: 18.017296 },
    { lat: 48.464496, lng: 18.017381 },
    { lat: 48.464497, lng: 18.017382 },
    { lat: 48.464575, lng: 18.017435 },
    { lat: 48.46467, lng: 18.017499 },
    { lat: 48.464703, lng: 18.017523 },
    { lat: 48.464726, lng: 18.017544 },
    { lat: 48.464688, lng: 18.017634 },
    { lat: 48.464645, lng: 18.017738 },
    { lat: 48.464616, lng: 18.017805 },
    { lat: 48.464609, lng: 18.017819 },
    { lat: 48.464511, lng: 18.01805 },
    { lat: 48.464491, lng: 18.018094 },
    { lat: 48.464442, lng: 18.018203 },
    { lat: 48.46444, lng: 18.01821 },
    { lat: 48.464439, lng: 18.018256 },
    { lat: 48.464435, lng: 18.018292 },
    { lat: 48.464414, lng: 18.01842 },
    { lat: 48.464378, lng: 18.018639 },
    { lat: 48.46433, lng: 18.01893 },
    { lat: 48.464315, lng: 18.019013 },
    { lat: 48.464314, lng: 18.019014 },
    { lat: 48.464317, lng: 18.01921 },
    { lat: 48.464317, lng: 18.019233 },
    { lat: 48.464321, lng: 18.019375 },
    { lat: 48.464323, lng: 18.019439 },
    { lat: 48.464283, lng: 18.019579 },
    { lat: 48.464234, lng: 18.019761 },
    { lat: 48.464223, lng: 18.019808 },
    { lat: 48.464179, lng: 18.019972 },
    { lat: 48.464121, lng: 18.020197 },
    { lat: 48.464053, lng: 18.020454 },
    { lat: 48.464039, lng: 18.020511 },
    { lat: 48.463977, lng: 18.020774 },
    { lat: 48.463934, lng: 18.020992 },
    { lat: 48.463885, lng: 18.021221 },
    { lat: 48.463849, lng: 18.021381 },
    { lat: 48.463846, lng: 18.021392 },
    { lat: 48.463846, lng: 18.021393 },
    { lat: 48.46382, lng: 18.021524 },
    { lat: 48.463837, lng: 18.021538 },
    { lat: 48.463798, lng: 18.02165 },
    { lat: 48.463772, lng: 18.021724 },
    { lat: 48.463666, lng: 18.021742 },
    { lat: 48.463602, lng: 18.021797 },
    { lat: 48.463529, lng: 18.021857 },
    { lat: 48.463459, lng: 18.021896 },
    { lat: 48.463405, lng: 18.021926 },
    { lat: 48.463316, lng: 18.022 },
    { lat: 48.463264, lng: 18.022042 },
    { lat: 48.463182, lng: 18.022141 },
    { lat: 48.463145, lng: 18.022198 },
    { lat: 48.463112, lng: 18.022295 },
    { lat: 48.463111, lng: 18.022298 },
    { lat: 48.463073, lng: 18.022435 },
    { lat: 48.463028, lng: 18.022597 },
    { lat: 48.462961, lng: 18.022841 },
    { lat: 48.462901, lng: 18.023072 },
    { lat: 48.462834, lng: 18.023298 },
    { lat: 48.462771, lng: 18.023513 },
    { lat: 48.462701, lng: 18.023738 },
    { lat: 48.462625, lng: 18.023978 },
    { lat: 48.462579, lng: 18.024132 },
    { lat: 48.462557, lng: 18.024223 },
    { lat: 48.462534, lng: 18.024343 },
    { lat: 48.462498, lng: 18.024519 },
    { lat: 48.462476, lng: 18.02463 },
    { lat: 48.462458, lng: 18.024788 },
    { lat: 48.462432, lng: 18.024984 },
    { lat: 48.462418, lng: 18.025062 },
    { lat: 48.46238, lng: 18.025172 },
    { lat: 48.462333, lng: 18.0253 },
    { lat: 48.46227, lng: 18.02549 },
    { lat: 48.462209, lng: 18.025675 },
    { lat: 48.46214, lng: 18.025894 },
    { lat: 48.462071, lng: 18.026116 },
    { lat: 48.46204, lng: 18.026206 },
    { lat: 48.462033, lng: 18.026299 },
    { lat: 48.462027, lng: 18.026366 },
    { lat: 48.462024, lng: 18.026447 },
    { lat: 48.462019, lng: 18.026592 },
    { lat: 48.462013, lng: 18.026813 },
    { lat: 48.462004, lng: 18.027053 },
    { lat: 48.461995, lng: 18.027367 },
    { lat: 48.461994, lng: 18.027423 },
    { lat: 48.461993, lng: 18.027425 },
    { lat: 48.461991, lng: 18.027457 },
    { lat: 48.461983, lng: 18.027449 },
    { lat: 48.461915, lng: 18.027385 },
    { lat: 48.461778, lng: 18.027257 },
    { lat: 48.46164, lng: 18.02717 },
    { lat: 48.461603, lng: 18.027178 },
    { lat: 48.46153, lng: 18.027198 },
    { lat: 48.461394, lng: 18.027264 },
    { lat: 48.461292, lng: 18.027308 },
    { lat: 48.461202, lng: 18.027342 },
    { lat: 48.46111, lng: 18.027392 },
    { lat: 48.460967, lng: 18.027466 },
    { lat: 48.460865, lng: 18.027521 },
    { lat: 48.460676, lng: 18.027621 },
    { lat: 48.460572, lng: 18.027696 },
    { lat: 48.46044, lng: 18.027791 },
    { lat: 48.460325, lng: 18.027804 },
    { lat: 48.46025, lng: 18.027814 },
    { lat: 48.460135, lng: 18.027844 },
    { lat: 48.460127, lng: 18.027847 },
    { lat: 48.460079, lng: 18.027861 },
    { lat: 48.459984, lng: 18.027896 },
    { lat: 48.459849, lng: 18.027948 },
    { lat: 48.459824, lng: 18.027955 },
    { lat: 48.459821, lng: 18.028011 },
    { lat: 48.459821, lng: 18.028012 },
    { lat: 48.459813, lng: 18.028117 },
    { lat: 48.459796, lng: 18.028275 },
    { lat: 48.459782, lng: 18.028437 },
    { lat: 48.459762, lng: 18.028639 },
    { lat: 48.459748, lng: 18.028778 },
    { lat: 48.459732, lng: 18.028965 },
    { lat: 48.459714, lng: 18.029137 },
    { lat: 48.459708, lng: 18.029198 },
    { lat: 48.459682, lng: 18.029455 },
    { lat: 48.459683, lng: 18.029459 },
    { lat: 48.459677, lng: 18.029506 },
    { lat: 48.459664, lng: 18.029642 },
    { lat: 48.459646, lng: 18.029815 },
    { lat: 48.459626, lng: 18.030043 },
    { lat: 48.459603, lng: 18.03028 },
    { lat: 48.459577, lng: 18.03055 },
    { lat: 48.459547, lng: 18.03086 },
    { lat: 48.459525, lng: 18.031091 },
    { lat: 48.459489, lng: 18.031457 },
    { lat: 48.459463, lng: 18.031731 },
    { lat: 48.459447, lng: 18.031875 },
    { lat: 48.459446, lng: 18.03188 },
    { lat: 48.459446, lng: 18.031882 },
    { lat: 48.459355, lng: 18.031927 },
    { lat: 48.459341, lng: 18.03207 },
    { lat: 48.459315, lng: 18.032313 },
    { lat: 48.45929, lng: 18.032564 },
    { lat: 48.459255, lng: 18.032924 },
    { lat: 48.459236, lng: 18.03311 },
    { lat: 48.459236, lng: 18.033123 },
    { lat: 48.45923, lng: 18.033224 },
    { lat: 48.459223, lng: 18.033329 },
    { lat: 48.459223, lng: 18.033334 },
    { lat: 48.459205, lng: 18.033595 },
    { lat: 48.459196, lng: 18.033727 },
    { lat: 48.459188, lng: 18.033859 },
    { lat: 48.45918, lng: 18.034019 },
    { lat: 48.459171, lng: 18.034251 },
    { lat: 48.45916, lng: 18.034515 },
    { lat: 48.459258, lng: 18.034533 },
    { lat: 48.459302, lng: 18.034542 },
    { lat: 48.45942, lng: 18.034571 },
    { lat: 48.459578, lng: 18.034603 },
    { lat: 48.459755, lng: 18.034639 },
    { lat: 48.459898, lng: 18.034668 },
    { lat: 48.460053, lng: 18.034702 },
    { lat: 48.460108, lng: 18.034713 },
    { lat: 48.460236, lng: 18.034739 },
    { lat: 48.460235, lng: 18.034871 },
    { lat: 48.460229, lng: 18.035118 },
    { lat: 48.460222, lng: 18.03545 },
    { lat: 48.460217, lng: 18.035747 },
    { lat: 48.460212, lng: 18.035941 },
    { lat: 48.460207, lng: 18.036237 },
    { lat: 48.460201, lng: 18.036504 },
    { lat: 48.460199, lng: 18.036601 },
    { lat: 48.460193, lng: 18.0369 },
    { lat: 48.460187, lng: 18.037196 },
    { lat: 48.460179, lng: 18.037517 },
    { lat: 48.460176, lng: 18.037651 },
    { lat: 48.460173, lng: 18.037759 },
    { lat: 48.460169, lng: 18.037979 },
    { lat: 48.460166, lng: 18.038174 },
    { lat: 48.46016, lng: 18.038467 },
    { lat: 48.460148, lng: 18.038586 },
    { lat: 48.460125, lng: 18.038807 },
    { lat: 48.460097, lng: 18.039076 },
    { lat: 48.460081, lng: 18.039236 },
    { lat: 48.460056, lng: 18.039495 },
    { lat: 48.460049, lng: 18.039564 },
    { lat: 48.459972, lng: 18.039539 },
    { lat: 48.459815, lng: 18.039488 },
    { lat: 48.459735, lng: 18.039462 },
    { lat: 48.459622, lng: 18.039425 },
    { lat: 48.459488, lng: 18.03938 },
    { lat: 48.459378, lng: 18.039345 },
    { lat: 48.459256, lng: 18.039303 },
    { lat: 48.459146, lng: 18.039272 },
    { lat: 48.459103, lng: 18.039259 },
    { lat: 48.459095, lng: 18.039257 },
    { lat: 48.459079, lng: 18.039252 },
    { lat: 48.459057, lng: 18.039246 },
    { lat: 48.459038, lng: 18.03924 },
    { lat: 48.458988, lng: 18.039225 },
    { lat: 48.458963, lng: 18.039217 },
    { lat: 48.458871, lng: 18.039185 },
    { lat: 48.45876, lng: 18.039145 },
    { lat: 48.458686, lng: 18.039117 },
    { lat: 48.458685, lng: 18.039117 },
    { lat: 48.458659, lng: 18.039107 },
    { lat: 48.45853, lng: 18.039066 },
    { lat: 48.458433, lng: 18.039033 },
    { lat: 48.458336, lng: 18.039001 },
    { lat: 48.458227, lng: 18.038966 },
    { lat: 48.458106, lng: 18.038925 },
    { lat: 48.45795, lng: 18.038871 },
    { lat: 48.457949, lng: 18.038871 },
    { lat: 48.457915, lng: 18.038858 },
    { lat: 48.457796, lng: 18.038824 },
    { lat: 48.457776, lng: 18.038819 },
    { lat: 48.45768, lng: 18.038793 },
    { lat: 48.457607, lng: 18.038772 },
    { lat: 48.457604, lng: 18.03877 },
    { lat: 48.457593, lng: 18.038768 },
    { lat: 48.457484, lng: 18.038736 },
    { lat: 48.457444, lng: 18.038723 },
    { lat: 48.457442, lng: 18.038723 },
    { lat: 48.45744, lng: 18.038722 },
    { lat: 48.457358, lng: 18.038706 },
    { lat: 48.45735, lng: 18.038704 },
    { lat: 48.457267, lng: 18.038684 },
    { lat: 48.457138, lng: 18.038659 },
    { lat: 48.457081, lng: 18.038649 },
    { lat: 48.457041, lng: 18.038642 },
    { lat: 48.456939, lng: 18.038621 },
    { lat: 48.456816, lng: 18.038599 },
    { lat: 48.456705, lng: 18.038578 },
    { lat: 48.456657, lng: 18.038568 },
    { lat: 48.456656, lng: 18.038568 },
    { lat: 48.456555, lng: 18.038565 },
    { lat: 48.456512, lng: 18.038564 },
    { lat: 48.456442, lng: 18.038563 },
    { lat: 48.456407, lng: 18.038563 },
    { lat: 48.45637, lng: 18.038563 },
    { lat: 48.45629, lng: 18.038573 },
    { lat: 48.456216, lng: 18.038586 },
    { lat: 48.456205, lng: 18.038588 },
    { lat: 48.456083, lng: 18.038604 },
    { lat: 48.456007, lng: 18.038639 },
    { lat: 48.455949, lng: 18.038666 },
    { lat: 48.455925, lng: 18.038676 },
    { lat: 48.455802, lng: 18.038732 },
    { lat: 48.455784, lng: 18.03874 },
    { lat: 48.455782, lng: 18.038676 },
    { lat: 48.455773, lng: 18.038682 },
    { lat: 48.455756, lng: 18.038694 },
    { lat: 48.455708, lng: 18.038725 },
    { lat: 48.455627, lng: 18.03878 },
    { lat: 48.4555, lng: 18.038879 },
    { lat: 48.455398, lng: 18.038958 },
    { lat: 48.45529, lng: 18.039045 },
    { lat: 48.455197, lng: 18.039131 },
    { lat: 48.455081, lng: 18.039239 },
    { lat: 48.454995, lng: 18.039318 },
    { lat: 48.454898, lng: 18.039406 },
    { lat: 48.454849, lng: 18.039447 },
    { lat: 48.454807, lng: 18.039484 },
    { lat: 48.454671, lng: 18.039597 },
    { lat: 48.454583, lng: 18.039673 },
    { lat: 48.45445, lng: 18.039784 },
    { lat: 48.454352, lng: 18.039865 },
    { lat: 48.454239, lng: 18.039964 },
    { lat: 48.454158, lng: 18.04003 },
    { lat: 48.454078, lng: 18.040097 },
    { lat: 48.453972, lng: 18.040189 },
    { lat: 48.453871, lng: 18.040274 },
    { lat: 48.453767, lng: 18.040373 },
    { lat: 48.453664, lng: 18.040471 },
    { lat: 48.453615, lng: 18.040517 },
    { lat: 48.453595, lng: 18.040535 },
    { lat: 48.453613, lng: 18.040612 },
    { lat: 48.453653, lng: 18.04075 },
    { lat: 48.453672, lng: 18.040808 },
    { lat: 48.453683, lng: 18.04084 },
    { lat: 48.453701, lng: 18.040897 },
    { lat: 48.453715, lng: 18.040938 },
    { lat: 48.453716, lng: 18.040939 },
    { lat: 48.453729, lng: 18.040977 },
    { lat: 48.453741, lng: 18.041013 },
    { lat: 48.453767, lng: 18.041085 },
    { lat: 48.453775, lng: 18.04111 },
    { lat: 48.453781, lng: 18.041128 },
    { lat: 48.453831, lng: 18.041292 },
    { lat: 48.453878, lng: 18.041444 },
    { lat: 48.453913, lng: 18.041561 },
    { lat: 48.45394, lng: 18.041654 },
    { lat: 48.453984, lng: 18.041798 },
    { lat: 48.453997, lng: 18.041841 },
    { lat: 48.454012, lng: 18.041891 },
    { lat: 48.454013, lng: 18.041896 },
    { lat: 48.45403, lng: 18.041972 },
    { lat: 48.454049, lng: 18.042053 },
    { lat: 48.454064, lng: 18.042121 },
    { lat: 48.4544245, lng: 18.0416316 },
    { lat: 48.456122, lng: 18.0398044 },
    { lat: 48.4586428, lng: 18.0409358 },
    { lat: 48.4597835, lng: 18.0413122 },
    { lat: 48.4603638, lng: 18.0418512 },
    { lat: 48.4613019, lng: 18.0421964 },
    { lat: 48.4618327, lng: 18.0425098 },
    { lat: 48.4623159, lng: 18.0427015 },
    { lat: 48.463051, lng: 18.0428417 },
    { lat: 48.4648062, lng: 18.0429407 },
    { lat: 48.4647559, lng: 18.0438056 },
    { lat: 48.4648984, lng: 18.0449935 },
    { lat: 48.4656733, lng: 18.047752 },
    { lat: 48.4665979, lng: 18.0503735 },
    { lat: 48.4672205, lng: 18.0533452 },
    { lat: 48.4674035, lng: 18.0557551 },
    { lat: 48.467423, lng: 18.0572295 },
    { lat: 48.4675253, lng: 18.0579331 },
    { lat: 48.4676504, lng: 18.0584463 },
    { lat: 48.4677465, lng: 18.059329 },
    { lat: 48.4677095, lng: 18.0599438 },
    { lat: 48.4675362, lng: 18.0608504 },
    { lat: 48.4676849, lng: 18.0617075 },
    { lat: 48.4680566, lng: 18.0626871 },
    { lat: 48.4680195, lng: 18.0636864 },
    { lat: 48.4680433, lng: 18.0645112 },
    { lat: 48.4681567, lng: 18.0652112 },
    { lat: 48.4683305, lng: 18.0657837 },
    { lat: 48.4683818, lng: 18.065944 },
    { lat: 48.4685078, lng: 18.0661477 },
    { lat: 48.4685307, lng: 18.0662681 },
    { lat: 48.4687332, lng: 18.0673301 },
    { lat: 48.4691711, lng: 18.0681921 },
    { lat: 48.4695888, lng: 18.0687666 },
    { lat: 48.4698739, lng: 18.0688559 },
    { lat: 48.4708804, lng: 18.0689688 },
    { lat: 48.4713948, lng: 18.0688842 },
    { lat: 48.4719221, lng: 18.0690632 },
    { lat: 48.4723126, lng: 18.0690805 },
    { lat: 48.4731658, lng: 18.0688497 },
    { lat: 48.4749972, lng: 18.0686375 },
    { lat: 48.4753628, lng: 18.068444 },
    { lat: 48.4767209, lng: 18.0680694 },
    { lat: 48.477441, lng: 18.0677229 },
    { lat: 48.4783511, lng: 18.0675962 },
  ],
  Podhradie: [
    { lat: 48.6495278, lng: 18.0884945 },
    { lat: 48.6501703, lng: 18.0877313 },
    { lat: 48.6520028, lng: 18.0854898 },
    { lat: 48.6538, lng: 18.0835148 },
    { lat: 48.6549872, lng: 18.0823197 },
    { lat: 48.654985, lng: 18.0819188 },
    { lat: 48.6546504, lng: 18.0813513 },
    { lat: 48.6547682, lng: 18.0809722 },
    { lat: 48.6546635, lng: 18.0804015 },
    { lat: 48.654459, lng: 18.0800675 },
    { lat: 48.6542845, lng: 18.0794391 },
    { lat: 48.6539621, lng: 18.0768297 },
    { lat: 48.6541519, lng: 18.0766976 },
    { lat: 48.6544333, lng: 18.0760688 },
    { lat: 48.6549198, lng: 18.0753195 },
    { lat: 48.6550482, lng: 18.0752999 },
    { lat: 48.6555766, lng: 18.0748623 },
    { lat: 48.6563963, lng: 18.0747687 },
    { lat: 48.6569486, lng: 18.0747788 },
    { lat: 48.6572492, lng: 18.0748799 },
    { lat: 48.6574876, lng: 18.0747906 },
    { lat: 48.6581909, lng: 18.0748665 },
    { lat: 48.6587505, lng: 18.0748645 },
    { lat: 48.659388, lng: 18.0746489 },
    { lat: 48.6596867, lng: 18.0746447 },
    { lat: 48.6598696, lng: 18.0745303 },
    { lat: 48.659979, lng: 18.0745878 },
    { lat: 48.6602303, lng: 18.0744663 },
    { lat: 48.6606895, lng: 18.0739241 },
    { lat: 48.6609967, lng: 18.0738316 },
    { lat: 48.6611048, lng: 18.0737122 },
    { lat: 48.661161, lng: 18.0733994 },
    { lat: 48.6614434, lng: 18.0731539 },
    { lat: 48.6615242, lng: 18.0729349 },
    { lat: 48.6616401, lng: 18.0728509 },
    { lat: 48.6617118, lng: 18.0726142 },
    { lat: 48.6623577, lng: 18.0714457 },
    { lat: 48.6627372, lng: 18.0705103 },
    { lat: 48.6633285, lng: 18.0687593 },
    { lat: 48.6637441, lng: 18.0681078 },
    { lat: 48.6638732, lng: 18.0676164 },
    { lat: 48.6641521, lng: 18.0672356 },
    { lat: 48.6647778, lng: 18.0653617 },
    { lat: 48.6649983, lng: 18.0650819 },
    { lat: 48.6655142, lng: 18.0636228 },
    { lat: 48.6658476, lng: 18.0632472 },
    { lat: 48.6659121, lng: 18.0629804 },
    { lat: 48.6661175, lng: 18.062653 },
    { lat: 48.6662629, lng: 18.0623268 },
    { lat: 48.666409, lng: 18.0617581 },
    { lat: 48.666567, lng: 18.0615084 },
    { lat: 48.6671982, lng: 18.0614771 },
    { lat: 48.6672057, lng: 18.0612583 },
    { lat: 48.6666384, lng: 18.0610708 },
    { lat: 48.6666167, lng: 18.0612004 },
    { lat: 48.6664282, lng: 18.0610672 },
    { lat: 48.66653, lng: 18.0596489 },
    { lat: 48.6666098, lng: 18.0591637 },
    { lat: 48.6665724, lng: 18.0587981 },
    { lat: 48.666726, lng: 18.0576096 },
    { lat: 48.6668477, lng: 18.0571652 },
    { lat: 48.6668564, lng: 18.0566217 },
    { lat: 48.6667811, lng: 18.0562623 },
    { lat: 48.6665917, lng: 18.0537908 },
    { lat: 48.6666826, lng: 18.0532466 },
    { lat: 48.6670028, lng: 18.0532423 },
    { lat: 48.6670254, lng: 18.0531478 },
    { lat: 48.6675156, lng: 18.0529824 },
    { lat: 48.6679701, lng: 18.0526876 },
    { lat: 48.6681528, lng: 18.0524724 },
    { lat: 48.6681515, lng: 18.0523547 },
    { lat: 48.6685878, lng: 18.0513689 },
    { lat: 48.6687829, lng: 18.0500441 },
    { lat: 48.6687533, lng: 18.0482199 },
    { lat: 48.6692151, lng: 18.0470746 },
    { lat: 48.6693409, lng: 18.0465474 },
    { lat: 48.6695524, lng: 18.0460191 },
    { lat: 48.6696685, lng: 18.046131 },
    { lat: 48.6697463, lng: 18.0460713 },
    { lat: 48.6698423, lng: 18.0459577 },
    { lat: 48.6698533, lng: 18.0457802 },
    { lat: 48.6702256, lng: 18.0454149 },
    { lat: 48.6706539, lng: 18.0446771 },
    { lat: 48.6709177, lng: 18.044461 },
    { lat: 48.6710032, lng: 18.0443238 },
    { lat: 48.671125, lng: 18.0436897 },
    { lat: 48.6712407, lng: 18.0434699 },
    { lat: 48.6717555, lng: 18.0426869 },
    { lat: 48.672086, lng: 18.042375 },
    { lat: 48.6726177, lng: 18.0411316 },
    { lat: 48.6732488, lng: 18.0401237 },
    { lat: 48.6738794, lng: 18.0397725 },
    { lat: 48.674434, lng: 18.0391498 },
    { lat: 48.6751003, lng: 18.0382003 },
    { lat: 48.6760229, lng: 18.0377684 },
    { lat: 48.677791, lng: 18.0355028 },
    { lat: 48.6780997, lng: 18.0354155 },
    { lat: 48.6785286, lng: 18.0354932 },
    { lat: 48.6791493, lng: 18.0360289 },
    { lat: 48.679574, lng: 18.0365496 },
    { lat: 48.6800687, lng: 18.0378742 },
    { lat: 48.6803856, lng: 18.0400355 },
    { lat: 48.6805094, lng: 18.0406435 },
    { lat: 48.6806245, lng: 18.0408906 },
    { lat: 48.680754, lng: 18.0417285 },
    { lat: 48.6807566, lng: 18.0422139 },
    { lat: 48.6808124, lng: 18.0424143 },
    { lat: 48.6808586, lng: 18.0430052 },
    { lat: 48.6808138, lng: 18.0435087 },
    { lat: 48.6810388, lng: 18.0446457 },
    { lat: 48.6812782, lng: 18.0452207 },
    { lat: 48.6813315, lng: 18.0455924 },
    { lat: 48.6815441, lng: 18.0458485 },
    { lat: 48.6817907, lng: 18.0463936 },
    { lat: 48.6818682, lng: 18.0467289 },
    { lat: 48.6817796, lng: 18.0468966 },
    { lat: 48.6819833, lng: 18.0469046 },
    { lat: 48.6822841, lng: 18.0470285 },
    { lat: 48.6823942, lng: 18.0471151 },
    { lat: 48.6824901, lng: 18.0473619 },
    { lat: 48.6825859, lng: 18.047993 },
    { lat: 48.6825645, lng: 18.0482651 },
    { lat: 48.6821302, lng: 18.0489774 },
    { lat: 48.6820424, lng: 18.0492099 },
    { lat: 48.6821521, lng: 18.051017 },
    { lat: 48.682173, lng: 18.0511589 },
    { lat: 48.6823504, lng: 18.0513919 },
    { lat: 48.6826165, lng: 18.0514458 },
    { lat: 48.6826309, lng: 18.0507335 },
    { lat: 48.6827507, lng: 18.0502184 },
    { lat: 48.6830845, lng: 18.04956 },
    { lat: 48.6830641, lng: 18.0492349 },
    { lat: 48.6832306, lng: 18.0493382 },
    { lat: 48.6834898, lng: 18.0487766 },
    { lat: 48.6839404, lng: 18.0480539 },
    { lat: 48.6839324, lng: 18.0479946 },
    { lat: 48.6840564, lng: 18.047764 },
    { lat: 48.6841186, lng: 18.0477635 },
    { lat: 48.6845996, lng: 18.0471003 },
    { lat: 48.685392, lng: 18.045833 },
    { lat: 48.6856764, lng: 18.0456426 },
    { lat: 48.6863843, lng: 18.0454386 },
    { lat: 48.6869989, lng: 18.0450281 },
    { lat: 48.687419, lng: 18.0446484 },
    { lat: 48.6878799, lng: 18.0445032 },
    { lat: 48.6882419, lng: 18.0441885 },
    { lat: 48.6883708, lng: 18.0443651 },
    { lat: 48.6893804, lng: 18.0446259 },
    { lat: 48.6901065, lng: 18.0451885 },
    { lat: 48.6904412, lng: 18.0452578 },
    { lat: 48.6911605, lng: 18.0460503 },
    { lat: 48.6912937, lng: 18.0463156 },
    { lat: 48.6915585, lng: 18.0464437 },
    { lat: 48.6916172, lng: 18.0464433 },
    { lat: 48.6916871, lng: 18.0462485 },
    { lat: 48.6920094, lng: 18.0458222 },
    { lat: 48.692838, lng: 18.0452872 },
    { lat: 48.6934541, lng: 18.0453841 },
    { lat: 48.694148, lng: 18.0456288 },
    { lat: 48.6951732, lng: 18.0458244 },
    { lat: 48.696043, lng: 18.0464338 },
    { lat: 48.6967349, lng: 18.0470675 },
    { lat: 48.6976174, lng: 18.0475627 },
    { lat: 48.6984296, lng: 18.0483028 },
    { lat: 48.6996668, lng: 18.0478672 },
    { lat: 48.700561, lng: 18.04689 },
    { lat: 48.701022, lng: 18.046636 },
    { lat: 48.701532, lng: 18.046468 },
    { lat: 48.702054, lng: 18.046338 },
    { lat: 48.702787, lng: 18.046187 },
    { lat: 48.704137, lng: 18.045764 },
    { lat: 48.704035, lng: 18.045491 },
    { lat: 48.704014, lng: 18.045429 },
    { lat: 48.70395, lng: 18.045225 },
    { lat: 48.703948, lng: 18.045074 },
    { lat: 48.703961, lng: 18.044786 },
    { lat: 48.704012, lng: 18.044565 },
    { lat: 48.704169, lng: 18.044281 },
    { lat: 48.704295, lng: 18.044102 },
    { lat: 48.704535, lng: 18.043799 },
    { lat: 48.704682, lng: 18.043697 },
    { lat: 48.704821, lng: 18.043536 },
    { lat: 48.705014, lng: 18.04324 },
    { lat: 48.705055, lng: 18.043125 },
    { lat: 48.705104, lng: 18.042904 },
    { lat: 48.705204, lng: 18.042579 },
    { lat: 48.705294, lng: 18.042381 },
    { lat: 48.705448, lng: 18.042166 },
    { lat: 48.705504, lng: 18.042042 },
    { lat: 48.705533, lng: 18.041903 },
    { lat: 48.705521, lng: 18.041483 },
    { lat: 48.705504, lng: 18.041365 },
    { lat: 48.705486, lng: 18.041179 },
    { lat: 48.705409, lng: 18.040643 },
    { lat: 48.705405, lng: 18.040495 },
    { lat: 48.705488, lng: 18.039668 },
    { lat: 48.705497, lng: 18.039417 },
    { lat: 48.705379, lng: 18.038832 },
    { lat: 48.70532, lng: 18.038145 },
    { lat: 48.70527, lng: 18.037501 },
    { lat: 48.705253, lng: 18.03738 },
    { lat: 48.705182, lng: 18.036612 },
    { lat: 48.705125, lng: 18.036326 },
    { lat: 48.705074, lng: 18.035724 },
    { lat: 48.704956, lng: 18.034768 },
    { lat: 48.704916, lng: 18.034614 },
    { lat: 48.704884, lng: 18.034375 },
    { lat: 48.704825, lng: 18.03414 },
    { lat: 48.704789, lng: 18.033853 },
    { lat: 48.704485, lng: 18.033335 },
    { lat: 48.704223, lng: 18.032794 },
    { lat: 48.703862, lng: 18.031983 },
    { lat: 48.70338, lng: 18.031339 },
    { lat: 48.702881, lng: 18.030151 },
    { lat: 48.70266, lng: 18.028748 },
    { lat: 48.702616, lng: 18.028566 },
    { lat: 48.702508, lng: 18.028133 },
    { lat: 48.702881, lng: 18.027089 },
    { lat: 48.703464, lng: 18.026018 },
    { lat: 48.703479, lng: 18.025988 },
    { lat: 48.703535, lng: 18.025896 },
    { lat: 48.703956, lng: 18.02536 },
    { lat: 48.703983, lng: 18.025321 },
    { lat: 48.704494, lng: 18.024652 },
    { lat: 48.70544, lng: 18.02325 },
    { lat: 48.706396, lng: 18.021771 },
    { lat: 48.706713, lng: 18.02128 },
    { lat: 48.707514, lng: 18.021533 },
    { lat: 48.708958, lng: 18.022044 },
    { lat: 48.710682, lng: 18.022866 },
    { lat: 48.711144, lng: 18.023744 },
    { lat: 48.710964, lng: 18.024129 },
    { lat: 48.710919, lng: 18.024542 },
    { lat: 48.711034, lng: 18.024735 },
    { lat: 48.711104, lng: 18.02505 },
    { lat: 48.711167, lng: 18.025136 },
    { lat: 48.711146, lng: 18.025238 },
    { lat: 48.711305, lng: 18.025487 },
    { lat: 48.711389, lng: 18.025549 },
    { lat: 48.711576, lng: 18.025574 },
    { lat: 48.71146, lng: 18.025241 },
    { lat: 48.711587, lng: 18.025081 },
    { lat: 48.711747, lng: 18.02497 },
    { lat: 48.711895, lng: 18.024965 },
    { lat: 48.712034, lng: 18.025013 },
    { lat: 48.712109, lng: 18.024976 },
    { lat: 48.712479, lng: 18.025547 },
    { lat: 48.712629, lng: 18.025575 },
    { lat: 48.712735, lng: 18.025754 },
    { lat: 48.71282, lng: 18.025854 },
    { lat: 48.712946, lng: 18.025885 },
    { lat: 48.712961, lng: 18.02572 },
    { lat: 48.713045, lng: 18.025538 },
    { lat: 48.712867, lng: 18.02543 },
    { lat: 48.712862, lng: 18.025252 },
    { lat: 48.71288, lng: 18.025125 },
    { lat: 48.712973, lng: 18.025107 },
    { lat: 48.713015, lng: 18.024989 },
    { lat: 48.712972, lng: 18.024778 },
    { lat: 48.713135, lng: 18.024765 },
    { lat: 48.713227, lng: 18.02495 },
    { lat: 48.713374, lng: 18.025093 },
    { lat: 48.713567, lng: 18.024753 },
    { lat: 48.713588, lng: 18.02462 },
    { lat: 48.713606, lng: 18.024603 },
    { lat: 48.71366, lng: 18.024563 },
    { lat: 48.71368, lng: 18.024539 },
    { lat: 48.713671, lng: 18.024393 },
    { lat: 48.71362, lng: 18.02424 },
    { lat: 48.71355, lng: 18.02422 },
    { lat: 48.713515, lng: 18.023976 },
    { lat: 48.713433, lng: 18.023833 },
    { lat: 48.713456, lng: 18.023712 },
    { lat: 48.713504, lng: 18.023552 },
    { lat: 48.713503, lng: 18.023341 },
    { lat: 48.713593, lng: 18.022944 },
    { lat: 48.713568, lng: 18.022763 },
    { lat: 48.713595, lng: 18.022638 },
    { lat: 48.713672, lng: 18.022627 },
    { lat: 48.713766, lng: 18.022651 },
    { lat: 48.71406, lng: 18.022476 },
    { lat: 48.714348, lng: 18.022059 },
    { lat: 48.714431, lng: 18.021794 },
    { lat: 48.714406, lng: 18.021423 },
    { lat: 48.714299, lng: 18.02115 },
    { lat: 48.714179, lng: 18.020912 },
    { lat: 48.713822, lng: 18.020467 },
    { lat: 48.713746, lng: 18.02023 },
    { lat: 48.713399, lng: 18.019818 },
    { lat: 48.713495, lng: 18.019526 },
    { lat: 48.71361, lng: 18.019395 },
    { lat: 48.713774, lng: 18.019029 },
    { lat: 48.713928, lng: 18.018646 },
    { lat: 48.713831, lng: 18.018548 },
    { lat: 48.713879, lng: 18.018363 },
    { lat: 48.713841, lng: 18.018159 },
    { lat: 48.713897, lng: 18.018018 },
    { lat: 48.713924, lng: 18.017805 },
    { lat: 48.714023, lng: 18.017478 },
    { lat: 48.713859, lng: 18.017302 },
    { lat: 48.713744, lng: 18.017284 },
    { lat: 48.713845, lng: 18.017128 },
    { lat: 48.713894, lng: 18.016936 },
    { lat: 48.713863, lng: 18.01677 },
    { lat: 48.713881, lng: 18.016667 },
    { lat: 48.713759, lng: 18.016464 },
    { lat: 48.713116, lng: 18.014468 },
    { lat: 48.712217, lng: 18.012449 },
    { lat: 48.712691, lng: 18.011623 },
    { lat: 48.713062, lng: 18.010064 },
    { lat: 48.713902, lng: 18.006722 },
    { lat: 48.713475, lng: 18.005524 },
    { lat: 48.713175, lng: 18.004349 },
    { lat: 48.712983, lng: 18.0039984 },
    { lat: 48.712937, lng: 18.003875 },
    { lat: 48.712729, lng: 18.003687 },
    { lat: 48.712192, lng: 18.002996 },
    { lat: 48.711976, lng: 18.002598 },
    { lat: 48.711873, lng: 18.002346 },
    { lat: 48.711613, lng: 18.001385 },
    { lat: 48.711415, lng: 18.000611 },
    { lat: 48.711247, lng: 18.000267 },
    { lat: 48.710942, lng: 17.999555 },
    { lat: 48.710775, lng: 17.999279 },
    { lat: 48.710632, lng: 17.999006 },
    { lat: 48.71025, lng: 17.998431 },
    { lat: 48.710228, lng: 17.998397 },
    { lat: 48.71014, lng: 17.998266 },
    { lat: 48.709634, lng: 17.997834 },
    { lat: 48.709322, lng: 17.997372 },
    { lat: 48.70915, lng: 17.997157 },
    { lat: 48.708979, lng: 17.99682 },
    { lat: 48.708798, lng: 17.996689 },
    { lat: 48.708567, lng: 17.996431 },
    { lat: 48.708482, lng: 17.996285 },
    { lat: 48.708423, lng: 17.995887 },
    { lat: 48.708251, lng: 17.995567 },
    { lat: 48.708163, lng: 17.995499 },
    { lat: 48.70801, lng: 17.995335 },
    { lat: 48.707886, lng: 17.99516 },
    { lat: 48.70765, lng: 17.994724 },
    { lat: 48.707464, lng: 17.994356 },
    { lat: 48.707293, lng: 17.993581 },
    { lat: 48.706926, lng: 17.992567 },
    { lat: 48.706672, lng: 17.991942 },
    { lat: 48.706391, lng: 17.991364 },
    { lat: 48.705701, lng: 17.990918 },
    { lat: 48.705535, lng: 17.990834 },
    { lat: 48.705264, lng: 17.990845 },
    { lat: 48.70502, lng: 17.990951 },
    { lat: 48.704661, lng: 17.990957 },
    { lat: 48.70446, lng: 17.991052 },
    { lat: 48.704225, lng: 17.991109 },
    { lat: 48.703971, lng: 17.991083 },
    { lat: 48.703432, lng: 17.990873 },
    { lat: 48.703096, lng: 17.990908 },
    { lat: 48.702647, lng: 17.991389 },
    { lat: 48.702433, lng: 17.991431 },
    { lat: 48.702301, lng: 17.991563 },
    { lat: 48.701524, lng: 17.991534 },
    { lat: 48.701368, lng: 17.991494 },
    { lat: 48.701143, lng: 17.991419 },
    { lat: 48.70096, lng: 17.991394 },
    { lat: 48.700803, lng: 17.991314 },
    { lat: 48.700443, lng: 17.991618 },
    { lat: 48.700318, lng: 17.99168 },
    { lat: 48.699832, lng: 17.991738 },
    { lat: 48.699437, lng: 17.991921 },
    { lat: 48.699368, lng: 17.991967 },
    { lat: 48.698932, lng: 17.992721 },
    { lat: 48.698665, lng: 17.993332 },
    { lat: 48.698286, lng: 17.993567 },
    { lat: 48.698094, lng: 17.993589 },
    { lat: 48.697676, lng: 17.993491 },
    { lat: 48.697397, lng: 17.993585 },
    { lat: 48.697244, lng: 17.993576 },
    { lat: 48.696683, lng: 17.993613 },
    { lat: 48.696412, lng: 17.993404 },
    { lat: 48.696255, lng: 17.993339 },
    { lat: 48.696063, lng: 17.993392 },
    { lat: 48.695719, lng: 17.993052 },
    { lat: 48.695442, lng: 17.993204 },
    { lat: 48.695174, lng: 17.993293 },
    { lat: 48.695046, lng: 17.993508 },
    { lat: 48.695014, lng: 17.993767 },
    { lat: 48.695032, lng: 17.994383 },
    { lat: 48.694888, lng: 17.994466 },
    { lat: 48.694728, lng: 17.994455 },
    { lat: 48.694501, lng: 17.994253 },
    { lat: 48.694168, lng: 17.994319 },
    { lat: 48.693985, lng: 17.994441 },
    { lat: 48.693807, lng: 17.994356 },
    { lat: 48.693519, lng: 17.994094 },
    { lat: 48.69331, lng: 17.994104 },
    { lat: 48.693111, lng: 17.994399 },
    { lat: 48.692732, lng: 17.994764 },
    { lat: 48.692399, lng: 17.994967 },
    { lat: 48.69222, lng: 17.995078 },
    { lat: 48.692179, lng: 17.995104 },
    { lat: 48.691737, lng: 17.995238 },
    { lat: 48.691158, lng: 17.995839 },
    { lat: 48.690945, lng: 17.995946 },
    { lat: 48.690821, lng: 17.996095 },
    { lat: 48.690584, lng: 17.996266 },
    { lat: 48.689503, lng: 17.996562 },
    { lat: 48.689401, lng: 17.996666 },
    { lat: 48.689165, lng: 17.996944 },
    { lat: 48.688937, lng: 17.997176 },
    { lat: 48.688634, lng: 17.99761 },
    { lat: 48.688582, lng: 17.99764 },
    { lat: 48.688378, lng: 17.997542 },
    { lat: 48.687988, lng: 17.997252 },
    { lat: 48.68769, lng: 17.997067 },
    { lat: 48.687313, lng: 17.996791 },
    { lat: 48.687237, lng: 17.996743 },
    { lat: 48.686888, lng: 17.996369 },
    { lat: 48.686628, lng: 17.996035 },
    { lat: 48.686488, lng: 17.995892 },
    { lat: 48.686331, lng: 17.995842 },
    { lat: 48.686075, lng: 17.996086 },
    { lat: 48.68594, lng: 17.996171 },
    { lat: 48.68548, lng: 17.996191 },
    { lat: 48.685227, lng: 17.996148 },
    { lat: 48.685069, lng: 17.995991 },
    { lat: 48.684952, lng: 17.995803 },
    { lat: 48.684761, lng: 17.995388 },
    { lat: 48.684601, lng: 17.995224 },
    { lat: 48.684549, lng: 17.995251 },
    { lat: 48.684456, lng: 17.995261 },
    { lat: 48.684272, lng: 17.995226 },
    { lat: 48.682952, lng: 17.994754 },
    { lat: 48.682808, lng: 17.994695 },
    { lat: 48.682505, lng: 17.994539 },
    { lat: 48.682351, lng: 17.99452 },
    { lat: 48.682018, lng: 17.994161 },
    { lat: 48.681978, lng: 17.994119 },
    { lat: 48.681895, lng: 17.994029 },
    { lat: 48.681782, lng: 17.994205 },
    { lat: 48.681758, lng: 17.994243 },
    { lat: 48.681711, lng: 17.994315 },
    { lat: 48.681627, lng: 17.994417 },
    { lat: 48.681289, lng: 17.995092 },
    { lat: 48.681196, lng: 17.995329 },
    { lat: 48.680811, lng: 17.99584 },
    { lat: 48.680656, lng: 17.996167 },
    { lat: 48.680332, lng: 17.996521 },
    { lat: 48.679937, lng: 17.997062 },
    { lat: 48.679469, lng: 17.998173 },
    { lat: 48.679259, lng: 17.998579 },
    { lat: 48.678897, lng: 17.998945 },
    { lat: 48.678739, lng: 17.999255 },
    { lat: 48.678424, lng: 17.999578 },
    { lat: 48.678203, lng: 18.000246 },
    { lat: 48.67796, lng: 18.000948 },
    { lat: 48.677872, lng: 18.001138 },
    { lat: 48.677766, lng: 18.001379 },
    { lat: 48.677658, lng: 18.00154 },
    { lat: 48.677528, lng: 18.001686 },
    { lat: 48.677423, lng: 18.001781 },
    { lat: 48.677009, lng: 18.002376 },
    { lat: 48.67582, lng: 18.001003 },
    { lat: 48.675785, lng: 18.000956 },
    { lat: 48.675389, lng: 18.000437 },
    { lat: 48.674848, lng: 17.999835 },
    { lat: 48.674705, lng: 17.999737 },
    { lat: 48.674551, lng: 17.999685 },
    { lat: 48.674289, lng: 17.999516 },
    { lat: 48.673859, lng: 17.999365 },
    { lat: 48.673691, lng: 17.999358 },
    { lat: 48.673426, lng: 17.999184 },
    { lat: 48.672787, lng: 17.999039 },
    { lat: 48.672455, lng: 17.998896 },
    { lat: 48.672287, lng: 17.998805 },
    { lat: 48.6722, lng: 17.998812 },
    { lat: 48.672099, lng: 17.998949 },
    { lat: 48.671598, lng: 17.999594 },
    { lat: 48.67119, lng: 18.00081 },
    { lat: 48.670278, lng: 18.002327 },
    { lat: 48.669995, lng: 18.002777 },
    { lat: 48.668955, lng: 18.002477 },
    { lat: 48.667702, lng: 18.002521 },
    { lat: 48.666133, lng: 18.003251 },
    { lat: 48.664176, lng: 18.004331 },
    { lat: 48.662977, lng: 18.004746 },
    { lat: 48.66161, lng: 18.005083 },
    { lat: 48.661211, lng: 18.005022 },
    { lat: 48.659492, lng: 18.006355 },
    { lat: 48.658944, lng: 18.006905 },
    { lat: 48.658971, lng: 18.007259 },
    { lat: 48.658977, lng: 18.007342 },
    { lat: 48.659063, lng: 18.008516 },
    { lat: 48.659165, lng: 18.009874 },
    { lat: 48.659263, lng: 18.010662 },
    { lat: 48.659003, lng: 18.012548 },
    { lat: 48.658713, lng: 18.012944 },
    { lat: 48.658414, lng: 18.012314 },
    { lat: 48.658588, lng: 18.010869 },
    { lat: 48.658323, lng: 18.009385 },
    { lat: 48.658111, lng: 18.007781 },
    { lat: 48.657745, lng: 18.00828 },
    { lat: 48.657193, lng: 18.009993 },
    { lat: 48.655588, lng: 18.014176 },
    { lat: 48.655319, lng: 18.014805 },
    { lat: 48.655505, lng: 18.015227 },
    { lat: 48.655197, lng: 18.015958 },
    { lat: 48.654824, lng: 18.017235 },
    { lat: 48.654753, lng: 18.017386 },
    { lat: 48.654682, lng: 18.017539 },
    { lat: 48.654467, lng: 18.018001 },
    { lat: 48.654359, lng: 18.018029 },
    { lat: 48.654265, lng: 18.018097 },
    { lat: 48.653659, lng: 18.018312 },
    { lat: 48.653407, lng: 18.018411 },
    { lat: 48.652928, lng: 18.018511 },
    { lat: 48.652822, lng: 18.018474 },
    { lat: 48.65231, lng: 18.018291 },
    { lat: 48.652252, lng: 18.018271 },
    { lat: 48.65172, lng: 18.018082 },
    { lat: 48.651573, lng: 18.016993 },
    { lat: 48.651626, lng: 18.01689 },
    { lat: 48.65165, lng: 18.016843 },
    { lat: 48.651779, lng: 18.016592 },
    { lat: 48.651122, lng: 18.015922 },
    { lat: 48.65109, lng: 18.015891 },
    { lat: 48.650823, lng: 18.015617 },
    { lat: 48.650296, lng: 18.015756 },
    { lat: 48.650102, lng: 18.015758 },
    { lat: 48.649894, lng: 18.015795 },
    { lat: 48.649728, lng: 18.015884 },
    { lat: 48.649499, lng: 18.016076 },
    { lat: 48.649187, lng: 18.016305 },
    { lat: 48.648967, lng: 18.01682 },
    { lat: 48.648908, lng: 18.016882 },
    { lat: 48.648484, lng: 18.016959 },
    { lat: 48.648243, lng: 18.016889 },
    { lat: 48.648073, lng: 18.016896 },
    { lat: 48.647744, lng: 18.017011 },
    { lat: 48.647535, lng: 18.017166 },
    { lat: 48.647351, lng: 18.017285 },
    { lat: 48.64699, lng: 18.017607 },
    { lat: 48.646829, lng: 18.017689 },
    { lat: 48.646702, lng: 18.017793 },
    { lat: 48.646555, lng: 18.017979 },
    { lat: 48.646421, lng: 18.018067 },
    { lat: 48.646325, lng: 18.018084 },
    { lat: 48.646259, lng: 18.018096 },
    { lat: 48.646141, lng: 18.018117 },
    { lat: 48.645986, lng: 18.018175 },
    { lat: 48.645659, lng: 18.018233 },
    { lat: 48.644902, lng: 18.018478 },
    { lat: 48.644875, lng: 18.018487 },
    { lat: 48.644301, lng: 18.018592 },
    { lat: 48.644172, lng: 18.01858 },
    { lat: 48.643839, lng: 18.018416 },
    { lat: 48.643414, lng: 18.018302 },
    { lat: 48.643271, lng: 18.018239 },
    { lat: 48.64312, lng: 18.018244 },
    { lat: 48.643041, lng: 18.018288 },
    { lat: 48.642949, lng: 18.018292 },
    { lat: 48.642528, lng: 18.0183 },
    { lat: 48.642368, lng: 18.018328 },
    { lat: 48.642099, lng: 18.018452 },
    { lat: 48.641897, lng: 18.018711 },
    { lat: 48.641629, lng: 18.018882 },
    { lat: 48.64145, lng: 18.018926 },
    { lat: 48.641002, lng: 18.018951 },
    { lat: 48.640671, lng: 18.019075 },
    { lat: 48.640375, lng: 18.019234 },
    { lat: 48.639972, lng: 18.019347 },
    { lat: 48.639683, lng: 18.019353 },
    { lat: 48.639329, lng: 18.019441 },
    { lat: 48.638998, lng: 18.01961 },
    { lat: 48.638685, lng: 18.01976 },
    { lat: 48.638334, lng: 18.020178 },
    { lat: 48.63824, lng: 18.020392 },
    { lat: 48.638224, lng: 18.020677 },
    { lat: 48.638252, lng: 18.02103 },
    { lat: 48.638306, lng: 18.021144 },
    { lat: 48.638429, lng: 18.022471 },
    { lat: 48.638354, lng: 18.023258 },
    { lat: 48.638236, lng: 18.023355 },
    { lat: 48.638062, lng: 18.023167 },
    { lat: 48.63786, lng: 18.022726 },
    { lat: 48.637741, lng: 18.0225 },
    { lat: 48.637556, lng: 18.022113 },
    { lat: 48.637528, lng: 18.0221562 },
    { lat: 48.6373136, lng: 18.0222329 },
    { lat: 48.6370333, lng: 18.0225686 },
    { lat: 48.6367295, lng: 18.0226977 },
    { lat: 48.6359152, lng: 18.0224027 },
    { lat: 48.6357053, lng: 18.0223853 },
    { lat: 48.6355999, lng: 18.0224734 },
    { lat: 48.6353702, lng: 18.0228444 },
    { lat: 48.6349554, lng: 18.0239804 },
    { lat: 48.6347452, lng: 18.0246813 },
    { lat: 48.6346602, lng: 18.0255523 },
    { lat: 48.6344545, lng: 18.0260642 },
    { lat: 48.6341139, lng: 18.026488 },
    { lat: 48.6341376, lng: 18.0265048 },
    { lat: 48.6345596, lng: 18.0276701 },
    { lat: 48.6348378, lng: 18.0286154 },
    { lat: 48.6349895, lng: 18.0303209 },
    { lat: 48.6353971, lng: 18.0321759 },
    { lat: 48.6358317, lng: 18.0333146 },
    { lat: 48.6362718, lng: 18.0344676 },
    { lat: 48.6388998, lng: 18.0394881 },
    { lat: 48.6391164, lng: 18.0396392 },
    { lat: 48.6402346, lng: 18.041107 },
    { lat: 48.6423444, lng: 18.0424527 },
    { lat: 48.6435464, lng: 18.0453822 },
    { lat: 48.6427174, lng: 18.0496019 },
    { lat: 48.6427491, lng: 18.052882 },
    { lat: 48.6424586, lng: 18.0573008 },
    { lat: 48.6424019, lng: 18.0578912 },
    { lat: 48.6419843, lng: 18.0582748 },
    { lat: 48.6415534, lng: 18.0610608 },
    { lat: 48.6414794, lng: 18.062835 },
    { lat: 48.641669, lng: 18.063296 },
    { lat: 48.6428396, lng: 18.065909 },
    { lat: 48.6457046, lng: 18.0677247 },
    { lat: 48.6473156, lng: 18.0676979 },
    { lat: 48.6473772, lng: 18.0680737 },
    { lat: 48.6476676, lng: 18.0678481 },
    { lat: 48.6477528, lng: 18.0682236 },
    { lat: 48.6481681, lng: 18.0691617 },
    { lat: 48.6486788, lng: 18.0692318 },
    { lat: 48.6490248, lng: 18.0694725 },
    { lat: 48.6496069, lng: 18.0696798 },
    { lat: 48.6503244, lng: 18.0680612 },
    { lat: 48.6503857, lng: 18.0679891 },
    { lat: 48.6506173, lng: 18.0681073 },
    { lat: 48.6508429, lng: 18.0681074 },
    { lat: 48.6511315, lng: 18.0682537 },
    { lat: 48.6508313, lng: 18.0697933 },
    { lat: 48.6512168, lng: 18.069745 },
    { lat: 48.6516612, lng: 18.069855 },
    { lat: 48.6517254, lng: 18.0699892 },
    { lat: 48.651961, lng: 18.0702322 },
    { lat: 48.651947, lng: 18.0704157 },
    { lat: 48.6516313, lng: 18.0709809 },
    { lat: 48.6510718, lng: 18.0717335 },
    { lat: 48.6505683, lng: 18.0725968 },
    { lat: 48.6498731, lng: 18.073328 },
    { lat: 48.6495452, lng: 18.0738172 },
    { lat: 48.6492084, lng: 18.0741182 },
    { lat: 48.6485789, lng: 18.0737758 },
    { lat: 48.6483308, lng: 18.0734503 },
    { lat: 48.6481623, lng: 18.0738301 },
    { lat: 48.6478527, lng: 18.0735357 },
    { lat: 48.6471571, lng: 18.0742607 },
    { lat: 48.6467804, lng: 18.0744385 },
    { lat: 48.6468493, lng: 18.0763815 },
    { lat: 48.6460183, lng: 18.0765847 },
    { lat: 48.6460406, lng: 18.076985 },
    { lat: 48.6457685, lng: 18.0770784 },
    { lat: 48.6460706, lng: 18.0788814 },
    { lat: 48.6455088, lng: 18.0794047 },
    { lat: 48.6458749, lng: 18.0798926 },
    { lat: 48.6459077, lng: 18.0800274 },
    { lat: 48.6460927, lng: 18.0802186 },
    { lat: 48.6462823, lng: 18.0806454 },
    { lat: 48.6466195, lng: 18.0809459 },
    { lat: 48.6467931, lng: 18.0812139 },
    { lat: 48.6470157, lng: 18.0808148 },
    { lat: 48.6477192, lng: 18.0821695 },
    { lat: 48.6484174, lng: 18.0842602 },
    { lat: 48.6486484, lng: 18.0843682 },
    { lat: 48.6490869, lng: 18.0852854 },
    { lat: 48.6492084, lng: 18.0852596 },
    { lat: 48.6493212, lng: 18.0867778 },
    { lat: 48.6492312, lng: 18.0873038 },
    { lat: 48.6492375, lng: 18.0876042 },
    { lat: 48.6492902, lng: 18.0879273 },
    { lat: 48.6495278, lng: 18.0884945 },
  ],
  Závada: [
    { lat: 48.619134, lng: 18.0886646 },
    { lat: 48.6193291, lng: 18.0890645 },
    { lat: 48.6195411, lng: 18.0894461 },
    { lat: 48.6195986, lng: 18.0893696 },
    { lat: 48.6201572, lng: 18.0903916 },
    { lat: 48.6203796, lng: 18.0902557 },
    { lat: 48.6218494, lng: 18.0889158 },
    { lat: 48.6220141, lng: 18.0888738 },
    { lat: 48.6227841, lng: 18.0881301 },
    { lat: 48.6236348, lng: 18.0870097 },
    { lat: 48.6242461, lng: 18.0863074 },
    { lat: 48.6250049, lng: 18.0856993 },
    { lat: 48.6255452, lng: 18.0851384 },
    { lat: 48.6274233, lng: 18.082773 },
    { lat: 48.6280497, lng: 18.0823119 },
    { lat: 48.6289149, lng: 18.0819945 },
    { lat: 48.6293688, lng: 18.0816128 },
    { lat: 48.6303582, lng: 18.0836271 },
    { lat: 48.630619, lng: 18.0850544 },
    { lat: 48.6303165, lng: 18.0859585 },
    { lat: 48.6304393, lng: 18.0863218 },
    { lat: 48.6314907, lng: 18.0848689 },
    { lat: 48.6318892, lng: 18.0858533 },
    { lat: 48.6317547, lng: 18.0860606 },
    { lat: 48.632172, lng: 18.0869626 },
    { lat: 48.6324079, lng: 18.0866543 },
    { lat: 48.632982, lng: 18.0876131 },
    { lat: 48.6339016, lng: 18.0889754 },
    { lat: 48.6334367, lng: 18.0902106 },
    { lat: 48.6343757, lng: 18.0923489 },
    { lat: 48.6349156, lng: 18.0934559 },
    { lat: 48.6350969, lng: 18.0933129 },
    { lat: 48.6357358, lng: 18.0946482 },
    { lat: 48.6353522, lng: 18.0950525 },
    { lat: 48.6360455, lng: 18.0963314 },
    { lat: 48.6362948, lng: 18.0960809 },
    { lat: 48.6376365, lng: 18.0990308 },
    { lat: 48.6386788, lng: 18.1008909 },
    { lat: 48.6389463, lng: 18.1011222 },
    { lat: 48.6394518, lng: 18.1003694 },
    { lat: 48.6406372, lng: 18.0991621 },
    { lat: 48.6418812, lng: 18.0983948 },
    { lat: 48.6427881, lng: 18.0979254 },
    { lat: 48.6428901, lng: 18.0982012 },
    { lat: 48.6430805, lng: 18.0980406 },
    { lat: 48.6438619, lng: 18.0971635 },
    { lat: 48.6451988, lng: 18.0953613 },
    { lat: 48.6452936, lng: 18.0954775 },
    { lat: 48.6454241, lng: 18.0952556 },
    { lat: 48.6455063, lng: 18.0949059 },
    { lat: 48.646052, lng: 18.0937608 },
    { lat: 48.6457317, lng: 18.0933381 },
    { lat: 48.646742, lng: 18.0911855 },
    { lat: 48.6470805, lng: 18.0907585 },
    { lat: 48.6475515, lng: 18.090382 },
    { lat: 48.6481384, lng: 18.0912281 },
    { lat: 48.6482955, lng: 18.0913303 },
    { lat: 48.6485373, lng: 18.0905814 },
    { lat: 48.6488498, lng: 18.0899251 },
    { lat: 48.6491254, lng: 18.0890817 },
    { lat: 48.6492486, lng: 18.0888722 },
    { lat: 48.6495278, lng: 18.0884945 },
    { lat: 48.6492902, lng: 18.0879273 },
    { lat: 48.6492375, lng: 18.0876042 },
    { lat: 48.6492312, lng: 18.0873038 },
    { lat: 48.6493212, lng: 18.0867778 },
    { lat: 48.6492084, lng: 18.0852596 },
    { lat: 48.6490869, lng: 18.0852854 },
    { lat: 48.6486484, lng: 18.0843682 },
    { lat: 48.6484174, lng: 18.0842602 },
    { lat: 48.6477192, lng: 18.0821695 },
    { lat: 48.6470157, lng: 18.0808148 },
    { lat: 48.6467931, lng: 18.0812139 },
    { lat: 48.6466195, lng: 18.0809459 },
    { lat: 48.6462823, lng: 18.0806454 },
    { lat: 48.6460927, lng: 18.0802186 },
    { lat: 48.6459077, lng: 18.0800274 },
    { lat: 48.6458749, lng: 18.0798926 },
    { lat: 48.6455088, lng: 18.0794047 },
    { lat: 48.6460706, lng: 18.0788814 },
    { lat: 48.6457685, lng: 18.0770784 },
    { lat: 48.6460406, lng: 18.076985 },
    { lat: 48.6460183, lng: 18.0765847 },
    { lat: 48.6468493, lng: 18.0763815 },
    { lat: 48.6467804, lng: 18.0744385 },
    { lat: 48.6471571, lng: 18.0742607 },
    { lat: 48.6478527, lng: 18.0735357 },
    { lat: 48.6481623, lng: 18.0738301 },
    { lat: 48.6483308, lng: 18.0734503 },
    { lat: 48.6485789, lng: 18.0737758 },
    { lat: 48.6492084, lng: 18.0741182 },
    { lat: 48.6495452, lng: 18.0738172 },
    { lat: 48.6498731, lng: 18.073328 },
    { lat: 48.6505683, lng: 18.0725968 },
    { lat: 48.6510718, lng: 18.0717335 },
    { lat: 48.6516313, lng: 18.0709809 },
    { lat: 48.651947, lng: 18.0704157 },
    { lat: 48.651961, lng: 18.0702322 },
    { lat: 48.6517254, lng: 18.0699892 },
    { lat: 48.6516612, lng: 18.069855 },
    { lat: 48.6512168, lng: 18.069745 },
    { lat: 48.6508313, lng: 18.0697933 },
    { lat: 48.6511315, lng: 18.0682537 },
    { lat: 48.6508429, lng: 18.0681074 },
    { lat: 48.6506173, lng: 18.0681073 },
    { lat: 48.6503857, lng: 18.0679891 },
    { lat: 48.6503244, lng: 18.0680612 },
    { lat: 48.6496069, lng: 18.0696798 },
    { lat: 48.6490248, lng: 18.0694725 },
    { lat: 48.6486788, lng: 18.0692318 },
    { lat: 48.6481681, lng: 18.0691617 },
    { lat: 48.6477528, lng: 18.0682236 },
    { lat: 48.6476676, lng: 18.0678481 },
    { lat: 48.6473772, lng: 18.0680737 },
    { lat: 48.6473156, lng: 18.0676979 },
    { lat: 48.6457046, lng: 18.0677247 },
    { lat: 48.6428396, lng: 18.065909 },
    { lat: 48.641669, lng: 18.063296 },
    { lat: 48.6414794, lng: 18.062835 },
    { lat: 48.6415534, lng: 18.0610608 },
    { lat: 48.6419843, lng: 18.0582748 },
    { lat: 48.6424019, lng: 18.0578912 },
    { lat: 48.6424586, lng: 18.0573008 },
    { lat: 48.6427491, lng: 18.052882 },
    { lat: 48.6427174, lng: 18.0496019 },
    { lat: 48.6435464, lng: 18.0453822 },
    { lat: 48.6423444, lng: 18.0424527 },
    { lat: 48.6402346, lng: 18.041107 },
    { lat: 48.6391164, lng: 18.0396392 },
    { lat: 48.6388998, lng: 18.0394881 },
    { lat: 48.6389454, lng: 18.0400312 },
    { lat: 48.6388886, lng: 18.0404638 },
    { lat: 48.6388158, lng: 18.0406252 },
    { lat: 48.6385331, lng: 18.0407514 },
    { lat: 48.638228, lng: 18.0407834 },
    { lat: 48.6381134, lng: 18.0413063 },
    { lat: 48.6379995, lng: 18.0415574 },
    { lat: 48.6380152, lng: 18.0416338 },
    { lat: 48.637908, lng: 18.0418259 },
    { lat: 48.6376352, lng: 18.0421167 },
    { lat: 48.6371497, lng: 18.042402 },
    { lat: 48.6368851, lng: 18.0424508 },
    { lat: 48.6367123, lng: 18.0423313 },
    { lat: 48.6358652, lng: 18.0423612 },
    { lat: 48.6356197, lng: 18.0427342 },
    { lat: 48.6354299, lng: 18.0431529 },
    { lat: 48.6350295, lng: 18.0436898 },
    { lat: 48.6348134, lng: 18.0442209 },
    { lat: 48.6338836, lng: 18.0441819 },
    { lat: 48.6336603, lng: 18.0440528 },
    { lat: 48.6328533, lng: 18.0441283 },
    { lat: 48.6322397, lng: 18.0444404 },
    { lat: 48.631378, lng: 18.0452148 },
    { lat: 48.6306348, lng: 18.0450527 },
    { lat: 48.6303384, lng: 18.044695 },
    { lat: 48.6300462, lng: 18.0440124 },
    { lat: 48.6298717, lng: 18.0441532 },
    { lat: 48.62946, lng: 18.0449791 },
    { lat: 48.6292519, lng: 18.0448965 },
    { lat: 48.6289446, lng: 18.0446216 },
    { lat: 48.6287217, lng: 18.0445748 },
    { lat: 48.6287208, lng: 18.0451897 },
    { lat: 48.6285784, lng: 18.0459163 },
    { lat: 48.6280797, lng: 18.0472972 },
    { lat: 48.628424, lng: 18.0478493 },
    { lat: 48.6277988, lng: 18.0489621 },
    { lat: 48.6276824, lng: 18.0490937 },
    { lat: 48.627699, lng: 18.0491639 },
    { lat: 48.6266103, lng: 18.0507438 },
    { lat: 48.6258043, lng: 18.0525775 },
    { lat: 48.6252124, lng: 18.0536069 },
    { lat: 48.6247081, lng: 18.0547579 },
    { lat: 48.6249612, lng: 18.0571606 },
    { lat: 48.6250979, lng: 18.0576581 },
    { lat: 48.6250366, lng: 18.0578065 },
    { lat: 48.6259202, lng: 18.0600085 },
    { lat: 48.625076, lng: 18.0610837 },
    { lat: 48.6248308, lng: 18.0616356 },
    { lat: 48.6234033, lng: 18.0640749 },
    { lat: 48.6221981, lng: 18.065804 },
    { lat: 48.6213116, lng: 18.0668679 },
    { lat: 48.6209931, lng: 18.0674802 },
    { lat: 48.6207294, lng: 18.0685028 },
    { lat: 48.6207324, lng: 18.0694388 },
    { lat: 48.6206537, lng: 18.0697697 },
    { lat: 48.6204043, lng: 18.070423 },
    { lat: 48.620375, lng: 18.0706534 },
    { lat: 48.6200021, lng: 18.0708602 },
    { lat: 48.6200141, lng: 18.0710808 },
    { lat: 48.619918, lng: 18.0711557 },
    { lat: 48.6199724, lng: 18.0715255 },
    { lat: 48.6209053, lng: 18.0725352 },
    { lat: 48.6214294, lng: 18.0740407 },
    { lat: 48.6212065, lng: 18.0744337 },
    { lat: 48.6213209, lng: 18.0748832 },
    { lat: 48.6198797, lng: 18.0764619 },
    { lat: 48.6202575, lng: 18.0776492 },
    { lat: 48.6200472, lng: 18.0778528 },
    { lat: 48.6199013, lng: 18.0786142 },
    { lat: 48.6197056, lng: 18.0787823 },
    { lat: 48.6198193, lng: 18.0802403 },
    { lat: 48.6190346, lng: 18.0808288 },
    { lat: 48.6192322, lng: 18.0817937 },
    { lat: 48.6193573, lng: 18.0819828 },
    { lat: 48.6194569, lng: 18.0823151 },
    { lat: 48.6194922, lng: 18.0827028 },
    { lat: 48.6190355, lng: 18.0829407 },
    { lat: 48.6183742, lng: 18.0867335 },
    { lat: 48.619134, lng: 18.0886646 },
  ],
  Orešany: [
    { lat: 48.504679, lng: 17.8851 },
    { lat: 48.504816, lng: 17.88606 },
    { lat: 48.504884, lng: 17.886598 },
    { lat: 48.504925, lng: 17.886715 },
    { lat: 48.504975, lng: 17.886878 },
    { lat: 48.505042, lng: 17.886976 },
    { lat: 48.505211, lng: 17.887406 },
    { lat: 48.505193, lng: 17.887559 },
    { lat: 48.505214, lng: 17.887682 },
    { lat: 48.50521, lng: 17.887935 },
    { lat: 48.505225, lng: 17.888175 },
    { lat: 48.505244, lng: 17.888416 },
    { lat: 48.505244, lng: 17.88871 },
    { lat: 48.505091, lng: 17.889308 },
    { lat: 48.504983, lng: 17.88964 },
    { lat: 48.504766, lng: 17.890105 },
    { lat: 48.504703, lng: 17.890295 },
    { lat: 48.504555, lng: 17.890669 },
    { lat: 48.504435, lng: 17.890955 },
    { lat: 48.50441, lng: 17.890967 },
    { lat: 48.504385, lng: 17.891044 },
    { lat: 48.504321, lng: 17.891249 },
    { lat: 48.504199, lng: 17.891439 },
    { lat: 48.504118, lng: 17.891623 },
    { lat: 48.504057, lng: 17.891741 },
    { lat: 48.503981, lng: 17.892072 },
    { lat: 48.503778, lng: 17.892527 },
    { lat: 48.503681, lng: 17.892776 },
    { lat: 48.503548, lng: 17.893049 },
    { lat: 48.503493, lng: 17.893102 },
    { lat: 48.503407, lng: 17.893216 },
    { lat: 48.503339, lng: 17.89331 },
    { lat: 48.503231, lng: 17.893483 },
    { lat: 48.50306, lng: 17.893785 },
    { lat: 48.502968, lng: 17.893916 },
    { lat: 48.502637, lng: 17.894491 },
    { lat: 48.502279, lng: 17.895173 },
    { lat: 48.502199, lng: 17.895392 },
    { lat: 48.502043, lng: 17.896047 },
    { lat: 48.501975, lng: 17.896313 },
    { lat: 48.501938, lng: 17.896542 },
    { lat: 48.501892, lng: 17.896666 },
    { lat: 48.501845, lng: 17.89665 },
    { lat: 48.501787, lng: 17.89678 },
    { lat: 48.501738, lng: 17.896963 },
    { lat: 48.501528, lng: 17.897554 },
    { lat: 48.501439, lng: 17.897761 },
    { lat: 48.501229, lng: 17.898435 },
    { lat: 48.500829, lng: 17.899152 },
    { lat: 48.500578, lng: 17.899497 },
    { lat: 48.499538, lng: 17.900801 },
    { lat: 48.499098, lng: 17.901449 },
    { lat: 48.498418, lng: 17.90287 },
    { lat: 48.498061, lng: 17.90371 },
    { lat: 48.497923, lng: 17.904065 },
    { lat: 48.497517, lng: 17.905018 },
    { lat: 48.497158, lng: 17.906134 },
    { lat: 48.496925, lng: 17.907372 },
    { lat: 48.496541, lng: 17.908806 },
    { lat: 48.496296, lng: 17.909421 },
    { lat: 48.495976, lng: 17.910073 },
    { lat: 48.495911, lng: 17.910179 },
    { lat: 48.495563, lng: 17.910626 },
    { lat: 48.49541, lng: 17.910998 },
    { lat: 48.494708, lng: 17.912185 },
    { lat: 48.494554, lng: 17.912446 },
    { lat: 48.494403, lng: 17.912742 },
    { lat: 48.493898, lng: 17.913919 },
    { lat: 48.493841, lng: 17.914173 },
    { lat: 48.493706, lng: 17.914487 },
    { lat: 48.493595, lng: 17.914735 },
    { lat: 48.493325, lng: 17.915203 },
    { lat: 48.493257, lng: 17.915405 },
    { lat: 48.4934235, lng: 17.9159403 },
    { lat: 48.4934719, lng: 17.9166697 },
    { lat: 48.4936018, lng: 17.9169687 },
    { lat: 48.4939441, lng: 17.9174992 },
    { lat: 48.4941555, lng: 17.9177095 },
    { lat: 48.4944973, lng: 17.917875 },
    { lat: 48.4948401, lng: 17.918245 },
    { lat: 48.4950558, lng: 17.9193872 },
    { lat: 48.4952051, lng: 17.9207619 },
    { lat: 48.4966378, lng: 17.9233347 },
    { lat: 48.4968774, lng: 17.9236768 },
    { lat: 48.4967806, lng: 17.9242609 },
    { lat: 48.4981989, lng: 17.9255677 },
    { lat: 48.5001806, lng: 17.9276781 },
    { lat: 48.5027599, lng: 17.9312278 },
    { lat: 48.503936, lng: 17.9329717 },
    { lat: 48.5065912, lng: 17.9366774 },
    { lat: 48.5091717, lng: 17.9399142 },
    { lat: 48.5100044, lng: 17.9408109 },
    { lat: 48.5100337, lng: 17.9408461 },
    { lat: 48.5105873, lng: 17.9402721 },
    { lat: 48.5111652, lng: 17.9399699 },
    { lat: 48.5117597, lng: 17.9398136 },
    { lat: 48.512024, lng: 17.9396662 },
    { lat: 48.5126103, lng: 17.9390339 },
    { lat: 48.5129918, lng: 17.9383601 },
    { lat: 48.5130448, lng: 17.9381528 },
    { lat: 48.5131559, lng: 17.9376507 },
    { lat: 48.5132421, lng: 17.936625 },
    { lat: 48.5133843, lng: 17.9360627 },
    { lat: 48.513413, lng: 17.9355444 },
    { lat: 48.5137285, lng: 17.9334494 },
    { lat: 48.5139665, lng: 17.9323554 },
    { lat: 48.5141591, lng: 17.9324623 },
    { lat: 48.5144775, lng: 17.9302671 },
    { lat: 48.5143961, lng: 17.9289695 },
    { lat: 48.5141788, lng: 17.9280222 },
    { lat: 48.5141354, lng: 17.9269828 },
    { lat: 48.5140243, lng: 17.9260915 },
    { lat: 48.5139382, lng: 17.9253353 },
    { lat: 48.5129755, lng: 17.9247385 },
    { lat: 48.5118592, lng: 17.9240651 },
    { lat: 48.5104049, lng: 17.9233431 },
    { lat: 48.5105103, lng: 17.9227289 },
    { lat: 48.5092338, lng: 17.9216264 },
    { lat: 48.5096648, lng: 17.920704 },
    { lat: 48.5100276, lng: 17.9197364 },
    { lat: 48.510443, lng: 17.9180336 },
    { lat: 48.5105793, lng: 17.9169036 },
    { lat: 48.5106679, lng: 17.9165202 },
    { lat: 48.5110382, lng: 17.9157131 },
    { lat: 48.512239, lng: 17.9136307 },
    { lat: 48.5128769, lng: 17.9124182 },
    { lat: 48.5132423, lng: 17.9116054 },
    { lat: 48.5132861, lng: 17.911358 },
    { lat: 48.5137837, lng: 17.9101369 },
    { lat: 48.5141913, lng: 17.9093175 },
    { lat: 48.5145274, lng: 17.9084705 },
    { lat: 48.5150996, lng: 17.9067251 },
    { lat: 48.5154034, lng: 17.9060373 },
    { lat: 48.5157847, lng: 17.9049124 },
    { lat: 48.515871, lng: 17.9046407 },
    { lat: 48.5160499, lng: 17.9034506 },
    { lat: 48.5161587, lng: 17.9031021 },
    { lat: 48.5158718, lng: 17.902874 },
    { lat: 48.5160098, lng: 17.9019436 },
    { lat: 48.5162426, lng: 17.9011103 },
    { lat: 48.5167161, lng: 17.8998076 },
    { lat: 48.5167701, lng: 17.8994484 },
    { lat: 48.516906, lng: 17.8990285 },
    { lat: 48.5180966, lng: 17.8964996 },
    { lat: 48.5177703, lng: 17.8959931 },
    { lat: 48.5176, lng: 17.8956258 },
    { lat: 48.5176046, lng: 17.8952909 },
    { lat: 48.5177466, lng: 17.8948185 },
    { lat: 48.5177131, lng: 17.8946191 },
    { lat: 48.5179511, lng: 17.893963 },
    { lat: 48.5182544, lng: 17.8934711 },
    { lat: 48.5187221, lng: 17.8929157 },
    { lat: 48.5185177, lng: 17.8928208 },
    { lat: 48.5182068, lng: 17.8925348 },
    { lat: 48.5173527, lng: 17.8921088 },
    { lat: 48.5169584, lng: 17.8920852 },
    { lat: 48.5168232, lng: 17.8922005 },
    { lat: 48.5170296, lng: 17.8915655 },
    { lat: 48.5167544, lng: 17.891344 },
    { lat: 48.5167764, lng: 17.8912787 },
    { lat: 48.5151385, lng: 17.8900088 },
    { lat: 48.5147234, lng: 17.8896032 },
    { lat: 48.514428, lng: 17.8896116 },
    { lat: 48.5136562, lng: 17.8890573 },
    { lat: 48.5133286, lng: 17.8887319 },
    { lat: 48.5122465, lng: 17.8873614 },
    { lat: 48.5125245, lng: 17.8868815 },
    { lat: 48.51201, lng: 17.8855208 },
    { lat: 48.5119313, lng: 17.8854518 },
    { lat: 48.5113768, lng: 17.8834733 },
    { lat: 48.5109399, lng: 17.8822042 },
    { lat: 48.5103928, lng: 17.8809033 },
    { lat: 48.5104009, lng: 17.8805917 },
    { lat: 48.5102647, lng: 17.879761 },
    { lat: 48.5099321, lng: 17.8787418 },
    { lat: 48.5096277, lng: 17.8775162 },
    { lat: 48.5091223, lng: 17.8761437 },
    { lat: 48.5090421, lng: 17.8756403 },
    { lat: 48.5089814, lng: 17.8745838 },
    { lat: 48.5084959, lng: 17.8737986 },
    { lat: 48.5080163, lng: 17.8732069 },
    { lat: 48.5079264, lng: 17.8732506 },
    { lat: 48.5074844, lng: 17.8728111 },
    { lat: 48.5071948, lng: 17.8721315 },
    { lat: 48.507204, lng: 17.872014 },
    { lat: 48.50718, lng: 17.872032 },
    { lat: 48.507121, lng: 17.872633 },
    { lat: 48.506932, lng: 17.873056 },
    { lat: 48.50646, lng: 17.873881 },
    { lat: 48.506121, lng: 17.874843 },
    { lat: 48.505821, lng: 17.875527 },
    { lat: 48.505698, lng: 17.875931 },
    { lat: 48.505587, lng: 17.876369 },
    { lat: 48.505409, lng: 17.877326 },
    { lat: 48.505395, lng: 17.87745 },
    { lat: 48.505414, lng: 17.87779 },
    { lat: 48.505492, lng: 17.878358 },
    { lat: 48.505505, lng: 17.878716 },
    { lat: 48.505452, lng: 17.879188 },
    { lat: 48.50532, lng: 17.879809 },
    { lat: 48.505097, lng: 17.880668 },
    { lat: 48.50487, lng: 17.881467 },
    { lat: 48.504741, lng: 17.881742 },
    { lat: 48.504662, lng: 17.881973 },
    { lat: 48.504638, lng: 17.882291 },
    { lat: 48.504671, lng: 17.883272 },
    { lat: 48.504724, lng: 17.883764 },
    { lat: 48.504711, lng: 17.884352 },
    { lat: 48.504681, lng: 17.884712 },
    { lat: 48.504679, lng: 17.8851 },
  ],
  DvoranynadNitrou: [
    { lat: 48.5022427, lng: 18.084826 },
    { lat: 48.4986995, lng: 18.0818794 },
    { lat: 48.4977627, lng: 18.0809747 },
    { lat: 48.4960199, lng: 18.0796098 },
    { lat: 48.4944437, lng: 18.0778496 },
    { lat: 48.4943098, lng: 18.0777045 },
    { lat: 48.493953, lng: 18.0781996 },
    { lat: 48.4931771, lng: 18.0790726 },
    { lat: 48.4931405, lng: 18.0790455 },
    { lat: 48.4929388, lng: 18.0850584 },
    { lat: 48.4925504, lng: 18.0861309 },
    { lat: 48.4900854, lng: 18.0885865 },
    { lat: 48.489438, lng: 18.091321 },
    { lat: 48.4863297, lng: 18.1055125 },
    { lat: 48.4859975, lng: 18.1068942 },
    { lat: 48.4855363, lng: 18.1085164 },
    { lat: 48.4870783, lng: 18.1096047 },
    { lat: 48.4869529, lng: 18.1100632 },
    { lat: 48.4856865, lng: 18.1091955 },
    { lat: 48.4854157, lng: 18.110447 },
    { lat: 48.4818276, lng: 18.119523 },
    { lat: 48.4787785, lng: 18.1250837 },
    { lat: 48.4786286, lng: 18.1249904 },
    { lat: 48.4790779, lng: 18.1233311 },
    { lat: 48.478985, lng: 18.123385 },
    { lat: 48.4785729, lng: 18.1231674 },
    { lat: 48.4784167, lng: 18.1230081 },
    { lat: 48.4782612, lng: 18.1226819 },
    { lat: 48.4780639, lng: 18.1224345 },
    { lat: 48.4779072, lng: 18.1224 },
    { lat: 48.4776643, lng: 18.1224919 },
    { lat: 48.4774921, lng: 18.122827 },
    { lat: 48.477337, lng: 18.123014 },
    { lat: 48.4771647, lng: 18.1230762 },
    { lat: 48.4766984, lng: 18.1225916 },
    { lat: 48.4764827, lng: 18.12249 },
    { lat: 48.4763639, lng: 18.1224833 },
    { lat: 48.4761384, lng: 18.1226077 },
    { lat: 48.4745681, lng: 18.1275183 },
    { lat: 48.4744305, lng: 18.127985 },
    { lat: 48.4746063, lng: 18.1282118 },
    { lat: 48.4750947, lng: 18.1284766 },
    { lat: 48.4758856, lng: 18.128515 },
    { lat: 48.4762056, lng: 18.1286406 },
    { lat: 48.4763191, lng: 18.1287508 },
    { lat: 48.476564, lng: 18.1294049 },
    { lat: 48.4766937, lng: 18.1304882 },
    { lat: 48.4767696, lng: 18.1316116 },
    { lat: 48.4767863, lng: 18.1318596 },
    { lat: 48.4769173, lng: 18.1323445 },
    { lat: 48.4770595, lng: 18.1326127 },
    { lat: 48.4773994, lng: 18.1328495 },
    { lat: 48.4778557, lng: 18.1328561 },
    { lat: 48.4782441, lng: 18.1326882 },
    { lat: 48.4788801, lng: 18.1321951 },
    { lat: 48.4791099, lng: 18.132169 },
    { lat: 48.4792467, lng: 18.1322321 },
    { lat: 48.4794774, lng: 18.1324527 },
    { lat: 48.4795652, lng: 18.1326979 },
    { lat: 48.4796094, lng: 18.133958 },
    { lat: 48.479685, lng: 18.1341507 },
    { lat: 48.4798236, lng: 18.1343053 },
    { lat: 48.4802102, lng: 18.134393 },
    { lat: 48.4804821, lng: 18.1342184 },
    { lat: 48.4814061, lng: 18.1331708 },
    { lat: 48.4816151, lng: 18.1330148 },
    { lat: 48.481911, lng: 18.1329632 },
    { lat: 48.4821528, lng: 18.1330473 },
    { lat: 48.4823098, lng: 18.1332037 },
    { lat: 48.4825034, lng: 18.1336 },
    { lat: 48.4825147, lng: 18.1340047 },
    { lat: 48.4823943, lng: 18.1342063 },
    { lat: 48.4821702, lng: 18.1343856 },
    { lat: 48.4815559, lng: 18.1345539 },
    { lat: 48.4813465, lng: 18.1346982 },
    { lat: 48.4812207, lng: 18.1351346 },
    { lat: 48.4812305, lng: 18.135903 },
    { lat: 48.4815036, lng: 18.1366563 },
    { lat: 48.482095, lng: 18.1376044 },
    { lat: 48.4824441, lng: 18.1379215 },
    { lat: 48.4827874, lng: 18.1380221 },
    { lat: 48.4851749, lng: 18.1329476 },
    { lat: 48.4882566, lng: 18.1335216 },
    { lat: 48.4884928, lng: 18.1331596 },
    { lat: 48.488848, lng: 18.1328081 },
    { lat: 48.4890923, lng: 18.1326457 },
    { lat: 48.4898341, lng: 18.1323402 },
    { lat: 48.4901267, lng: 18.1323827 },
    { lat: 48.4901582, lng: 18.1324404 },
    { lat: 48.4902374, lng: 18.1321934 },
    { lat: 48.4901741, lng: 18.1321537 },
    { lat: 48.4908968, lng: 18.1271567 },
    { lat: 48.4911876, lng: 18.1268596 },
    { lat: 48.4912467, lng: 18.1265394 },
    { lat: 48.4906304, lng: 18.1252793 },
    { lat: 48.4906086, lng: 18.125137 },
    { lat: 48.4909162, lng: 18.1240116 },
    { lat: 48.4917523, lng: 18.1242567 },
    { lat: 48.4923798, lng: 18.1203778 },
    { lat: 48.4923058, lng: 18.120309 },
    { lat: 48.4915452, lng: 18.1201343 },
    { lat: 48.490882, lng: 18.1201605 },
    { lat: 48.4918931, lng: 18.1154132 },
    { lat: 48.4931721, lng: 18.1113332 },
    { lat: 48.4931768, lng: 18.1111632 },
    { lat: 48.4932567, lng: 18.1111025 },
    { lat: 48.4935505, lng: 18.1100289 },
    { lat: 48.4939645, lng: 18.108021 },
    { lat: 48.4944408, lng: 18.1063261 },
    { lat: 48.4949413, lng: 18.1042159 },
    { lat: 48.4950225, lng: 18.1036575 },
    { lat: 48.4950912, lng: 18.1034452 },
    { lat: 48.4953569, lng: 18.1030285 },
    { lat: 48.4954622, lng: 18.1027676 },
    { lat: 48.4957871, lng: 18.1016855 },
    { lat: 48.4959134, lng: 18.1009909 },
    { lat: 48.4960151, lng: 18.0999505 },
    { lat: 48.4961539, lng: 18.0994353 },
    { lat: 48.4967921, lng: 18.0979408 },
    { lat: 48.497724, lng: 18.0965897 },
    { lat: 48.4980479, lng: 18.0959951 },
    { lat: 48.498508, lng: 18.0947569 },
    { lat: 48.4990524, lng: 18.0936473 },
    { lat: 48.500332, lng: 18.0918381 },
    { lat: 48.5007916, lng: 18.0909414 },
    { lat: 48.5011721, lng: 18.0897496 },
    { lat: 48.5018845, lng: 18.0864129 },
    { lat: 48.5020108, lng: 18.0855487 },
    { lat: 48.5022569, lng: 18.0848384 },
    { lat: 48.5022427, lng: 18.084826 },
  ],
  Solčianky: [
    { lat: 48.632719, lng: 18.199209 },
    { lat: 48.6321484, lng: 18.1993378 },
    { lat: 48.6312442, lng: 18.1991539 },
    { lat: 48.6306611, lng: 18.1991786 },
    { lat: 48.6302285, lng: 18.1988408 },
    { lat: 48.6297627, lng: 18.1986689 },
    { lat: 48.6294376, lng: 18.1985874 },
    { lat: 48.6290837, lng: 18.1986125 },
    { lat: 48.6282647, lng: 18.1975816 },
    { lat: 48.6281128, lng: 18.19708 },
    { lat: 48.6278747, lng: 18.1970385 },
    { lat: 48.6282413, lng: 18.1954326 },
    { lat: 48.6282747, lng: 18.1939985 },
    { lat: 48.628505, lng: 18.1935676 },
    { lat: 48.6281846, lng: 18.193101 },
    { lat: 48.6275717, lng: 18.191903 },
    { lat: 48.6270834, lng: 18.1907757 },
    { lat: 48.6266216, lng: 18.1894416 },
    { lat: 48.6264348, lng: 18.1891173 },
    { lat: 48.6267115, lng: 18.188869 },
    { lat: 48.6265509, lng: 18.1884913 },
    { lat: 48.6261643, lng: 18.1878719 },
    { lat: 48.6262112, lng: 18.1875707 },
    { lat: 48.6256604, lng: 18.1868034 },
    { lat: 48.6252315, lng: 18.1864731 },
    { lat: 48.6245433, lng: 18.185222 },
    { lat: 48.6234334, lng: 18.1828496 },
    { lat: 48.6232072, lng: 18.1825273 },
    { lat: 48.6234462, lng: 18.1814087 },
    { lat: 48.6229735, lng: 18.1808085 },
    { lat: 48.6227675, lng: 18.1804639 },
    { lat: 48.6223404, lng: 18.1790316 },
    { lat: 48.6222342, lng: 18.1788501 },
    { lat: 48.6219842, lng: 18.1788423 },
    { lat: 48.6219393, lng: 18.178671 },
    { lat: 48.6216408, lng: 18.17864 },
    { lat: 48.6210248, lng: 18.1787025 },
    { lat: 48.6208566, lng: 18.1787781 },
    { lat: 48.6198505, lng: 18.179684 },
    { lat: 48.618606, lng: 18.1803912 },
    { lat: 48.6179665, lng: 18.1809415 },
    { lat: 48.6171889, lng: 18.1814678 },
    { lat: 48.6159611, lng: 18.1825026 },
    { lat: 48.6157265, lng: 18.1827599 },
    { lat: 48.614591, lng: 18.1844744 },
    { lat: 48.6143802, lng: 18.1849065 },
    { lat: 48.6141925, lng: 18.1851736 },
    { lat: 48.6142371, lng: 18.1852822 },
    { lat: 48.6154551, lng: 18.1878069 },
    { lat: 48.6162715, lng: 18.1891451 },
    { lat: 48.6161177, lng: 18.189824 },
    { lat: 48.6159406, lng: 18.191134 },
    { lat: 48.6156766, lng: 18.1917229 },
    { lat: 48.6158168, lng: 18.1923322 },
    { lat: 48.6155987, lng: 18.1961853 },
    { lat: 48.6162825, lng: 18.1986284 },
    { lat: 48.6166641, lng: 18.1997263 },
    { lat: 48.6179366, lng: 18.1998017 },
    { lat: 48.6186252, lng: 18.2010367 },
    { lat: 48.6186916, lng: 18.201385 },
    { lat: 48.6200849, lng: 18.2034193 },
    { lat: 48.621618, lng: 18.2044511 },
    { lat: 48.6229889, lng: 18.2059367 },
    { lat: 48.6230749, lng: 18.2060371 },
    { lat: 48.62338, lng: 18.2063007 },
    { lat: 48.624298, lng: 18.2067836 },
    { lat: 48.6245125, lng: 18.2069606 },
    { lat: 48.6247623, lng: 18.2073202 },
    { lat: 48.6270622, lng: 18.2099308 },
    { lat: 48.6277263, lng: 18.2108034 },
    { lat: 48.6284136, lng: 18.2115342 },
    { lat: 48.6290689, lng: 18.2119995 },
    { lat: 48.629852, lng: 18.212984 },
    { lat: 48.629957, lng: 18.2128 },
    { lat: 48.630038, lng: 18.212621 },
    { lat: 48.630185, lng: 18.2123 },
    { lat: 48.630312, lng: 18.211831 },
    { lat: 48.630384, lng: 18.211505 },
    { lat: 48.630406, lng: 18.211304 },
    { lat: 48.630537, lng: 18.210225 },
    { lat: 48.630633, lng: 18.209574 },
    { lat: 48.630788, lng: 18.208592 },
    { lat: 48.630823, lng: 18.208438 },
    { lat: 48.630829, lng: 18.208249 },
    { lat: 48.630811, lng: 18.208041 },
    { lat: 48.630753, lng: 18.207539 },
    { lat: 48.630783, lng: 18.206708 },
    { lat: 48.630835, lng: 18.206399 },
    { lat: 48.630848, lng: 18.206403 },
    { lat: 48.630917, lng: 18.205928 },
    { lat: 48.630946, lng: 18.205741 },
    { lat: 48.631064, lng: 18.204999 },
    { lat: 48.631307, lng: 18.203873 },
    { lat: 48.631648, lng: 18.202247 },
    { lat: 48.631945, lng: 18.201049 },
    { lat: 48.631982, lng: 18.2009 },
    { lat: 48.632005, lng: 18.200807 },
    { lat: 48.632281, lng: 18.200184 },
    { lat: 48.632385, lng: 18.199951 },
    { lat: 48.632437, lng: 18.199831 },
    { lat: 48.632483, lng: 18.199728 },
    { lat: 48.632719, lng: 18.199209 },
  ],
  Krtovce: [
    { lat: 48.5470887, lng: 17.9987468 },
    { lat: 48.5470261, lng: 17.9987951 },
    { lat: 48.5458112, lng: 18.0005357 },
    { lat: 48.5451888, lng: 18.0012651 },
    { lat: 48.5448606, lng: 18.0015569 },
    { lat: 48.5432777, lng: 18.0025719 },
    { lat: 48.5430962, lng: 18.0021005 },
    { lat: 48.5423134, lng: 18.0025281 },
    { lat: 48.5407658, lng: 18.0029597 },
    { lat: 48.5401801, lng: 18.0032645 },
    { lat: 48.5395507, lng: 18.0037465 },
    { lat: 48.5393332, lng: 18.0038467 },
    { lat: 48.5391863, lng: 18.0036154 },
    { lat: 48.5389387, lng: 18.0041813 },
    { lat: 48.5385439, lng: 18.0044101 },
    { lat: 48.5374173, lng: 18.0019585 },
    { lat: 48.5361905, lng: 17.9990746 },
    { lat: 48.5350248, lng: 17.9969183 },
    { lat: 48.5347682, lng: 17.997308 },
    { lat: 48.5341742, lng: 17.9978896 },
    { lat: 48.5319706, lng: 17.99377 },
    { lat: 48.5309592, lng: 17.994488 },
    { lat: 48.5290039, lng: 17.990806 },
    { lat: 48.5288767, lng: 17.9909606 },
    { lat: 48.5255408, lng: 17.9954999 },
    { lat: 48.5255454, lng: 17.9956172 },
    { lat: 48.5259726, lng: 18.0009915 },
    { lat: 48.5256139, lng: 18.0015451 },
    { lat: 48.5265859, lng: 18.0053477 },
    { lat: 48.5272204, lng: 18.008104 },
    { lat: 48.5266351, lng: 18.0082648 },
    { lat: 48.5264092, lng: 18.008224 },
    { lat: 48.5260039, lng: 18.0086193 },
    { lat: 48.5257112, lng: 18.0089963 },
    { lat: 48.5253571, lng: 18.010197 },
    { lat: 48.5258304, lng: 18.0106958 },
    { lat: 48.5258922, lng: 18.0108837 },
    { lat: 48.5256228, lng: 18.0109431 },
    { lat: 48.524484, lng: 18.010964 },
    { lat: 48.5264383, lng: 18.0150225 },
    { lat: 48.5266256, lng: 18.0148517 },
    { lat: 48.5276568, lng: 18.016995 },
    { lat: 48.5278521, lng: 18.0169947 },
    { lat: 48.529296, lng: 18.0171146 },
    { lat: 48.5302607, lng: 18.0174887 },
    { lat: 48.5303858, lng: 18.0174886 },
    { lat: 48.5320826, lng: 18.016849 },
    { lat: 48.5352327, lng: 18.0228992 },
    { lat: 48.5359646, lng: 18.0217075 },
    { lat: 48.5380582, lng: 18.0244707 },
    { lat: 48.5392467, lng: 18.0243192 },
    { lat: 48.5395118, lng: 18.0238964 },
    { lat: 48.5402455, lng: 18.0230794 },
    { lat: 48.5406307, lng: 18.0238628 },
    { lat: 48.5412388, lng: 18.0240521 },
    { lat: 48.5419793, lng: 18.0237884 },
    { lat: 48.5438858, lng: 18.0234096 },
    { lat: 48.5445885, lng: 18.0230985 },
    { lat: 48.5448222, lng: 18.0228872 },
    { lat: 48.5452128, lng: 18.0223585 },
    { lat: 48.5452478, lng: 18.022435 },
    { lat: 48.5465341, lng: 18.0214192 },
    { lat: 48.5513664, lng: 18.0174655 },
    { lat: 48.5514887, lng: 18.0173596 },
    { lat: 48.55313, lng: 18.0160336 },
    { lat: 48.5526471, lng: 18.014722 },
    { lat: 48.5514441, lng: 18.0123653 },
    { lat: 48.5507626, lng: 18.0127503 },
    { lat: 48.5503358, lng: 18.0120261 },
    { lat: 48.5499285, lng: 18.0112488 },
    { lat: 48.5497026, lng: 18.0106548 },
    { lat: 48.5491366, lng: 18.0086317 },
    { lat: 48.5490008, lng: 18.0087017 },
    { lat: 48.5482166, lng: 18.009066 },
    { lat: 48.5477765, lng: 18.0093736 },
    { lat: 48.5471778, lng: 18.0073294 },
    { lat: 48.5473281, lng: 18.007219 },
    { lat: 48.5463344, lng: 18.0042873 },
    { lat: 48.5457557, lng: 18.0029797 },
    { lat: 48.5462284, lng: 18.00176 },
    { lat: 48.5466465, lng: 18.0004975 },
    { lat: 48.5469613, lng: 17.999377 },
    { lat: 48.5470887, lng: 17.9987468 },
  ],
  Bojná: [
    { lat: 48.5581315, lng: 18.0493457 },
    { lat: 48.558098, lng: 18.0494318 },
    { lat: 48.5580985, lng: 18.0503734 },
    { lat: 48.5579631, lng: 18.0524437 },
    { lat: 48.5579416, lng: 18.0541039 },
    { lat: 48.5579825, lng: 18.0546849 },
    { lat: 48.5581335, lng: 18.0555023 },
    { lat: 48.5589999, lng: 18.0580634 },
    { lat: 48.5593895, lng: 18.0596374 },
    { lat: 48.5596074, lng: 18.060329 },
    { lat: 48.560434, lng: 18.0623501 },
    { lat: 48.5605252, lng: 18.062882 },
    { lat: 48.5612853, lng: 18.0642872 },
    { lat: 48.5627134, lng: 18.0677644 },
    { lat: 48.5630219, lng: 18.068289 },
    { lat: 48.5634732, lng: 18.0692677 },
    { lat: 48.563559, lng: 18.0695563 },
    { lat: 48.5631645, lng: 18.0698327 },
    { lat: 48.5630084, lng: 18.0700738 },
    { lat: 48.5627551, lng: 18.070315 },
    { lat: 48.5621146, lng: 18.0713985 },
    { lat: 48.5610088, lng: 18.0718829 },
    { lat: 48.5612903, lng: 18.0731579 },
    { lat: 48.5615825, lng: 18.0740969 },
    { lat: 48.5621088, lng: 18.0739511 },
    { lat: 48.5622523, lng: 18.0737609 },
    { lat: 48.5623748, lng: 18.0733658 },
    { lat: 48.5625582, lng: 18.0731871 },
    { lat: 48.5626346, lng: 18.0729866 },
    { lat: 48.5629925, lng: 18.0727418 },
    { lat: 48.5641528, lng: 18.0761226 },
    { lat: 48.5649518, lng: 18.0780997 },
    { lat: 48.5659309, lng: 18.0800692 },
    { lat: 48.5666942, lng: 18.0817459 },
    { lat: 48.5670671, lng: 18.0823493 },
    { lat: 48.567158, lng: 18.0828432 },
    { lat: 48.5676237, lng: 18.0842171 },
    { lat: 48.5676924, lng: 18.0848875 },
    { lat: 48.5677548, lng: 18.0866241 },
    { lat: 48.5677713, lng: 18.0871956 },
    { lat: 48.568349, lng: 18.0867709 },
    { lat: 48.5702806, lng: 18.0851063 },
    { lat: 48.571914, lng: 18.0835739 },
    { lat: 48.5720578, lng: 18.0833734 },
    { lat: 48.5722916, lng: 18.082875 },
    { lat: 48.572499, lng: 18.0825259 },
    { lat: 48.5727956, lng: 18.0828848 },
    { lat: 48.5729334, lng: 18.0827142 },
    { lat: 48.5732298, lng: 18.0830742 },
    { lat: 48.5734684, lng: 18.0826949 },
    { lat: 48.5743206, lng: 18.0836804 },
    { lat: 48.5746941, lng: 18.0841972 },
    { lat: 48.5748781, lng: 18.0845077 },
    { lat: 48.5751568, lng: 18.0852746 },
    { lat: 48.5753657, lng: 18.0850468 },
    { lat: 48.5760591, lng: 18.0861308 },
    { lat: 48.577827, lng: 18.0882364 },
    { lat: 48.5790707, lng: 18.0857932 },
    { lat: 48.5803777, lng: 18.0877551 },
    { lat: 48.5816126, lng: 18.0863398 },
    { lat: 48.5816264, lng: 18.0863248 },
    { lat: 48.5821304, lng: 18.0855839 },
    { lat: 48.5823149, lng: 18.0852104 },
    { lat: 48.5824286, lng: 18.0847204 },
    { lat: 48.5825967, lng: 18.0829354 },
    { lat: 48.5826842, lng: 18.0824633 },
    { lat: 48.583084, lng: 18.0809514 },
    { lat: 48.5837972, lng: 18.0790462 },
    { lat: 48.5841206, lng: 18.0784234 },
    { lat: 48.5847657, lng: 18.0773959 },
    { lat: 48.5857942, lng: 18.0753862 },
    { lat: 48.586991, lng: 18.0735146 },
    { lat: 48.5884532, lng: 18.0721211 },
    { lat: 48.5892945, lng: 18.0711118 },
    { lat: 48.5896915, lng: 18.0707255 },
    { lat: 48.5899434, lng: 18.070613 },
    { lat: 48.5903755, lng: 18.0705724 },
    { lat: 48.5908521, lng: 18.0703483 },
    { lat: 48.5916174, lng: 18.0698699 },
    { lat: 48.5921926, lng: 18.0694201 },
    { lat: 48.592932, lng: 18.0686952 },
    { lat: 48.5942263, lng: 18.0670216 },
    { lat: 48.5946998, lng: 18.0665508 },
    { lat: 48.5954104, lng: 18.0660679 },
    { lat: 48.5972194, lng: 18.0651802 },
    { lat: 48.5975262, lng: 18.0650839 },
    { lat: 48.5978019, lng: 18.0650118 },
    { lat: 48.5981352, lng: 18.0655559 },
    { lat: 48.5987835, lng: 18.0653155 },
    { lat: 48.5995688, lng: 18.0645248 },
    { lat: 48.6002145, lng: 18.0640847 },
    { lat: 48.6010356, lng: 18.0633284 },
    { lat: 48.6017611, lng: 18.0628843 },
    { lat: 48.6021274, lng: 18.0625101 },
    { lat: 48.6024918, lng: 18.0622774 },
    { lat: 48.6032401, lng: 18.0616053 },
    { lat: 48.6039309, lng: 18.0612086 },
    { lat: 48.6048171, lng: 18.0605416 },
    { lat: 48.6060155, lng: 18.059061 },
    { lat: 48.60694, lng: 18.0582578 },
    { lat: 48.6078181, lng: 18.0571082 },
    { lat: 48.6083584, lng: 18.0569495 },
    { lat: 48.6091733, lng: 18.0565668 },
    { lat: 48.609511, lng: 18.0563415 },
    { lat: 48.6101172, lng: 18.0557688 },
    { lat: 48.6106426, lng: 18.0555868 },
    { lat: 48.610803, lng: 18.0552828 },
    { lat: 48.6111729, lng: 18.0547803 },
    { lat: 48.6116698, lng: 18.0538093 },
    { lat: 48.61195, lng: 18.0529136 },
    { lat: 48.6120472, lng: 18.0515977 },
    { lat: 48.6124843, lng: 18.0503746 },
    { lat: 48.6128533, lng: 18.0481923 },
    { lat: 48.6129127, lng: 18.0478968 },
    { lat: 48.6130043, lng: 18.0477294 },
    { lat: 48.6134025, lng: 18.0473026 },
    { lat: 48.6138633, lng: 18.0470396 },
    { lat: 48.6149711, lng: 18.0462497 },
    { lat: 48.6152397, lng: 18.0458088 },
    { lat: 48.6153204, lng: 18.045549 },
    { lat: 48.6156976, lng: 18.0450739 },
    { lat: 48.616022, lng: 18.0444928 },
    { lat: 48.6164304, lng: 18.0439344 },
    { lat: 48.6172268, lng: 18.0431779 },
    { lat: 48.6181062, lng: 18.0417486 },
    { lat: 48.6183767, lng: 18.0411862 },
    { lat: 48.618859, lng: 18.0406388 },
    { lat: 48.6192265, lng: 18.0399049 },
    { lat: 48.6193422, lng: 18.039762 },
    { lat: 48.6200734, lng: 18.0391473 },
    { lat: 48.6207247, lng: 18.0389392 },
    { lat: 48.6213186, lng: 18.0388673 },
    { lat: 48.6217355, lng: 18.0387028 },
    { lat: 48.6233032, lng: 18.0375187 },
    { lat: 48.6238295, lng: 18.0372354 },
    { lat: 48.6245512, lng: 18.0369971 },
    { lat: 48.6246514, lng: 18.0368962 },
    { lat: 48.6248155, lng: 18.0368467 },
    { lat: 48.6262622, lng: 18.0356576 },
    { lat: 48.6265709, lng: 18.0356308 },
    { lat: 48.6270072, lng: 18.0354897 },
    { lat: 48.6282574, lng: 18.034662 },
    { lat: 48.6291112, lng: 18.0348055 },
    { lat: 48.6300416, lng: 18.0343133 },
    { lat: 48.6307887, lng: 18.0341208 },
    { lat: 48.6312532, lng: 18.033789 },
    { lat: 48.6314027, lng: 18.0335134 },
    { lat: 48.6314432, lng: 18.0333174 },
    { lat: 48.6314456, lng: 18.0327858 },
    { lat: 48.6313657, lng: 18.0322745 },
    { lat: 48.6313693, lng: 18.0315897 },
    { lat: 48.631481, lng: 18.0310905 },
    { lat: 48.6315842, lng: 18.030261 },
    { lat: 48.631837, lng: 18.0294391 },
    { lat: 48.6324034, lng: 18.028443 },
    { lat: 48.6328348, lng: 18.0279704 },
    { lat: 48.6331284, lng: 18.0277439 },
    { lat: 48.633549, lng: 18.0272416 },
    { lat: 48.6338226, lng: 18.0270217 },
    { lat: 48.6341139, lng: 18.026488 },
    { lat: 48.6344545, lng: 18.0260642 },
    { lat: 48.6346602, lng: 18.0255523 },
    { lat: 48.6347452, lng: 18.0246813 },
    { lat: 48.6349554, lng: 18.0239804 },
    { lat: 48.6353702, lng: 18.0228444 },
    { lat: 48.6355999, lng: 18.0224734 },
    { lat: 48.6357053, lng: 18.0223853 },
    { lat: 48.6359152, lng: 18.0224027 },
    { lat: 48.6367295, lng: 18.0226977 },
    { lat: 48.6370333, lng: 18.0225686 },
    { lat: 48.6373136, lng: 18.0222329 },
    { lat: 48.637528, lng: 18.0221562 },
    { lat: 48.637556, lng: 18.022113 },
    { lat: 48.637398, lng: 18.021733 },
    { lat: 48.636778, lng: 18.02125 },
    { lat: 48.636574, lng: 18.02094 },
    { lat: 48.636127, lng: 18.020394 },
    { lat: 48.63585, lng: 18.019648 },
    { lat: 48.635708, lng: 18.019402 },
    { lat: 48.635423, lng: 18.018967 },
    { lat: 48.635101, lng: 18.018785 },
    { lat: 48.634552, lng: 18.018512 },
    { lat: 48.634069, lng: 18.018322 },
    { lat: 48.633968, lng: 18.017654 },
    { lat: 48.633783, lng: 18.016948 },
    { lat: 48.633415, lng: 18.015784 },
    { lat: 48.633013, lng: 18.015244 },
    { lat: 48.632465, lng: 18.01476 },
    { lat: 48.632181, lng: 18.014956 },
    { lat: 48.631742, lng: 18.014783 },
    { lat: 48.631417, lng: 18.014522 },
    { lat: 48.631009, lng: 18.014368 },
    { lat: 48.630699, lng: 18.014341 },
    { lat: 48.630365, lng: 18.014173 },
    { lat: 48.630075, lng: 18.014157 },
    { lat: 48.629847, lng: 18.014116 },
    { lat: 48.629675, lng: 18.014186 },
    { lat: 48.629571, lng: 18.014227 },
    { lat: 48.629413, lng: 18.014362 },
    { lat: 48.629098, lng: 18.014682 },
    { lat: 48.628814, lng: 18.015047 },
    { lat: 48.628664, lng: 18.01525 },
    { lat: 48.628535, lng: 18.015493 },
    { lat: 48.62801, lng: 18.016193 },
    { lat: 48.62746, lng: 18.016693 },
    { lat: 48.6269, lng: 18.01724 },
    { lat: 48.626558, lng: 18.017502 },
    { lat: 48.626311, lng: 18.017541 },
    { lat: 48.626003, lng: 18.017456 },
    { lat: 48.625744, lng: 18.017248 },
    { lat: 48.625606, lng: 18.017077 },
    { lat: 48.625449, lng: 18.016849 },
    { lat: 48.625335, lng: 18.016587 },
    { lat: 48.625201, lng: 18.016519 },
    { lat: 48.624961, lng: 18.016043 },
    { lat: 48.624925, lng: 18.015998 },
    { lat: 48.624518, lng: 18.015554 },
    { lat: 48.624174, lng: 18.015437 },
    { lat: 48.624033, lng: 18.015428 },
    { lat: 48.623652, lng: 18.015596 },
    { lat: 48.623301, lng: 18.01578 },
    { lat: 48.623185, lng: 18.015841 },
    { lat: 48.623129, lng: 18.015845 },
    { lat: 48.623204, lng: 18.015326 },
    { lat: 48.623258, lng: 18.014722 },
    { lat: 48.623326, lng: 18.014392 },
    { lat: 48.62374, lng: 18.013597 },
    { lat: 48.623944, lng: 18.013272 },
    { lat: 48.624292, lng: 18.012659 },
    { lat: 48.624988, lng: 18.01136 },
    { lat: 48.625315, lng: 18.010723 },
    { lat: 48.625405, lng: 18.010581 },
    { lat: 48.625583, lng: 18.010288 },
    { lat: 48.625928, lng: 18.009611 },
    { lat: 48.625999, lng: 18.009423 },
    { lat: 48.626248, lng: 18.008637 },
    { lat: 48.626358, lng: 18.008533 },
    { lat: 48.626727, lng: 18.008379 },
    { lat: 48.626785, lng: 18.008283 },
    { lat: 48.626787, lng: 18.00793 },
    { lat: 48.626831, lng: 18.007776 },
    { lat: 48.627071, lng: 18.007459 },
    { lat: 48.62731, lng: 18.006902 },
    { lat: 48.627419, lng: 18.006634 },
    { lat: 48.627501, lng: 18.006453 },
    { lat: 48.627562, lng: 18.006331 },
    { lat: 48.627873, lng: 18.005961 },
    { lat: 48.628079, lng: 18.005607 },
    { lat: 48.628451, lng: 18.005088 },
    { lat: 48.628472, lng: 18.005015 },
    { lat: 48.628699, lng: 18.00467 },
    { lat: 48.628783, lng: 18.004531 },
    { lat: 48.62913, lng: 18.003876 },
    { lat: 48.62922, lng: 18.003716 },
    { lat: 48.628955, lng: 18.003166 },
    { lat: 48.628925, lng: 18.003129 },
    { lat: 48.628852, lng: 18.003033 },
    { lat: 48.62855, lng: 18.002315 },
    { lat: 48.628495, lng: 18.002171 },
    { lat: 48.628422, lng: 18.002097 },
    { lat: 48.6284, lng: 18.002065 },
    { lat: 48.628353, lng: 18.002024 },
    { lat: 48.628325, lng: 18.002097 },
    { lat: 48.628315, lng: 18.002137 },
    { lat: 48.628191, lng: 18.002473 },
    { lat: 48.628046, lng: 18.00268 },
    { lat: 48.62753, lng: 18.00308 },
    { lat: 48.627343, lng: 18.003295 },
    { lat: 48.626929, lng: 18.004091 },
    { lat: 48.626628, lng: 18.004769 },
    { lat: 48.626352, lng: 18.005258 },
    { lat: 48.625979, lng: 18.005935 },
    { lat: 48.625698, lng: 18.00636 },
    { lat: 48.625638, lng: 18.006759 },
    { lat: 48.625472, lng: 18.007188 },
    { lat: 48.625024, lng: 18.006536 },
    { lat: 48.624668, lng: 18.005926 },
    { lat: 48.622391, lng: 18.002069 },
    { lat: 48.62184, lng: 18.001441 },
    { lat: 48.621229, lng: 18.000639 },
    { lat: 48.620424, lng: 17.999621 },
    { lat: 48.620398, lng: 17.99959 },
    { lat: 48.619635, lng: 17.998627 },
    { lat: 48.617489, lng: 17.995918 },
    { lat: 48.617464, lng: 17.995894 },
    { lat: 48.617513, lng: 17.995771 },
    { lat: 48.617736, lng: 17.995413 },
    { lat: 48.618133, lng: 17.994773 },
    { lat: 48.618641, lng: 17.993918 },
    { lat: 48.618802, lng: 17.993682 },
    { lat: 48.619085, lng: 17.99257 },
    { lat: 48.619275, lng: 17.992115 },
    { lat: 48.619415, lng: 17.991816 },
    { lat: 48.619683, lng: 17.991349 },
    { lat: 48.620032, lng: 17.990611 },
    { lat: 48.62012, lng: 17.990294 },
    { lat: 48.620153, lng: 17.990244 },
    { lat: 48.62019, lng: 17.990188 },
    { lat: 48.620623, lng: 17.989886 },
    { lat: 48.619999, lng: 17.989063 },
    { lat: 48.619939, lng: 17.988984 },
    { lat: 48.618906, lng: 17.987712 },
    { lat: 48.618432, lng: 17.986922 },
    { lat: 48.617904, lng: 17.986433 },
    { lat: 48.61649, lng: 17.984659 },
    { lat: 48.615852, lng: 17.983884 },
    { lat: 48.614954, lng: 17.98273 },
    { lat: 48.614917, lng: 17.982682 },
    { lat: 48.613578, lng: 17.980963 },
    { lat: 48.6133888, lng: 17.9806274 },
    { lat: 48.613076, lng: 17.9800729 },
    { lat: 48.612924, lng: 17.9798034 },
    { lat: 48.6127087, lng: 17.9794217 },
    { lat: 48.612529, lng: 17.979103 },
    { lat: 48.612007, lng: 17.979199 },
    { lat: 48.611323, lng: 17.979302 },
    { lat: 48.610985, lng: 17.979342 },
    { lat: 48.61065, lng: 17.979388 },
    { lat: 48.610285, lng: 17.979446 },
    { lat: 48.60997, lng: 17.979491 },
    { lat: 48.609702, lng: 17.979528 },
    { lat: 48.609287, lng: 17.9796 },
    { lat: 48.608941, lng: 17.979652 },
    { lat: 48.608607, lng: 17.979703 },
    { lat: 48.60826, lng: 17.979761 },
    { lat: 48.607577, lng: 17.97987 },
    { lat: 48.607238, lng: 17.979928 },
    { lat: 48.607169, lng: 17.979942 },
    { lat: 48.606882, lng: 17.979985 },
    { lat: 48.606555, lng: 17.980049 },
    { lat: 48.606139, lng: 17.98012 },
    { lat: 48.60586, lng: 17.980164 },
    { lat: 48.605653, lng: 17.980205 },
    { lat: 48.605421, lng: 17.980248 },
    { lat: 48.605333, lng: 17.980415 },
    { lat: 48.60525, lng: 17.980576 },
    { lat: 48.605155, lng: 17.980761 },
    { lat: 48.605063, lng: 17.980935 },
    { lat: 48.604969, lng: 17.981114 },
    { lat: 48.604897, lng: 17.981263 },
    { lat: 48.604814, lng: 17.981436 },
    { lat: 48.604734, lng: 17.981592 },
    { lat: 48.604677, lng: 17.981734 },
    { lat: 48.604621, lng: 17.981854 },
    { lat: 48.604583, lng: 17.981943 },
    { lat: 48.604491, lng: 17.982075 },
    { lat: 48.60445, lng: 17.982147 },
    { lat: 48.604392, lng: 17.982225 },
    { lat: 48.604315, lng: 17.982339 },
    { lat: 48.604266, lng: 17.982399 },
    { lat: 48.604216, lng: 17.982454 },
    { lat: 48.604162, lng: 17.98252 },
    { lat: 48.60407, lng: 17.982623 },
    { lat: 48.603989, lng: 17.982702 },
    { lat: 48.603913, lng: 17.982781 },
    { lat: 48.603819, lng: 17.982866 },
    { lat: 48.603751, lng: 17.98295 },
    { lat: 48.603685, lng: 17.982999 },
    { lat: 48.603562, lng: 17.983114 },
    { lat: 48.603463, lng: 17.983229 },
    { lat: 48.603348, lng: 17.983356 },
    { lat: 48.603224, lng: 17.983483 },
    { lat: 48.603148, lng: 17.98355 },
    { lat: 48.603113, lng: 17.983592 },
    { lat: 48.603032, lng: 17.983666 },
    { lat: 48.602982, lng: 17.983702 },
    { lat: 48.602902, lng: 17.983769 },
    { lat: 48.602831, lng: 17.983825 },
    { lat: 48.60274, lng: 17.983898 },
    { lat: 48.602624, lng: 17.984001 },
    { lat: 48.602543, lng: 17.984068 },
    { lat: 48.602532, lng: 17.984086 },
    { lat: 48.602486, lng: 17.984152 },
    { lat: 48.602405, lng: 17.984266 },
    { lat: 48.602345, lng: 17.984356 },
    { lat: 48.602234, lng: 17.984513 },
    { lat: 48.602134, lng: 17.984663 },
    { lat: 48.602051, lng: 17.984777 },
    { lat: 48.601997, lng: 17.984855 },
    { lat: 48.601935, lng: 17.984945 },
    { lat: 48.601874, lng: 17.985041 },
    { lat: 48.601832, lng: 17.98509 },
    { lat: 48.601768, lng: 17.985179 },
    { lat: 48.601718, lng: 17.985234 },
    { lat: 48.601617, lng: 17.985325 },
    { lat: 48.601525, lng: 17.98541 },
    { lat: 48.601483, lng: 17.985441 },
    { lat: 48.601389, lng: 17.985455 },
    { lat: 48.601297, lng: 17.985476 },
    { lat: 48.601195, lng: 17.985462 },
    { lat: 48.601066, lng: 17.985436 },
    { lat: 48.601024, lng: 17.98542 },
    { lat: 48.600929, lng: 17.985382 },
    { lat: 48.600878, lng: 17.98533 },
    { lat: 48.600786, lng: 17.98528 },
    { lat: 48.600669, lng: 17.985215 },
    { lat: 48.600548, lng: 17.985139 },
    { lat: 48.600439, lng: 17.985103 },
    { lat: 48.600342, lng: 17.985026 },
    { lat: 48.60026, lng: 17.984956 },
    { lat: 48.600174, lng: 17.984861 },
    { lat: 48.6001, lng: 17.98479 },
    { lat: 48.600011, lng: 17.984713 },
    { lat: 48.599959, lng: 17.984649 },
    { lat: 48.599914, lng: 17.984578 },
    { lat: 48.599863, lng: 17.984501 },
    { lat: 48.599827, lng: 17.98446 },
    { lat: 48.5997755, lng: 17.9844959 },
    { lat: 48.599654, lng: 17.9848429 },
    { lat: 48.5995609, lng: 17.9850783 },
    { lat: 48.5988871, lng: 17.9858945 },
    { lat: 48.5981548, lng: 17.986107 },
    { lat: 48.5975583, lng: 17.9865175 },
    { lat: 48.5972745, lng: 17.9866012 },
    { lat: 48.5971344, lng: 17.9867343 },
    { lat: 48.5970413, lng: 17.9870853 },
    { lat: 48.5968364, lng: 17.9873384 },
    { lat: 48.5965507, lng: 17.9882845 },
    { lat: 48.5964996, lng: 17.9886227 },
    { lat: 48.5963948, lng: 17.9887378 },
    { lat: 48.5963165, lng: 17.9890703 },
    { lat: 48.5960976, lng: 17.989454 },
    { lat: 48.5960128, lng: 17.9894676 },
    { lat: 48.5959791, lng: 17.9896045 },
    { lat: 48.5958316, lng: 17.9897617 },
    { lat: 48.5956403, lng: 17.9900971 },
    { lat: 48.5954474, lng: 17.9910649 },
    { lat: 48.5954358, lng: 17.991402 },
    { lat: 48.5953469, lng: 17.9914218 },
    { lat: 48.5950083, lng: 17.9919732 },
    { lat: 48.5949185, lng: 17.9919458 },
    { lat: 48.5946715, lng: 17.9925721 },
    { lat: 48.5945853, lng: 17.9925866 },
    { lat: 48.5944354, lng: 17.9927791 },
    { lat: 48.5941637, lng: 17.9933172 },
    { lat: 48.5940959, lng: 17.9937118 },
    { lat: 48.5941273, lng: 17.9938059 },
    { lat: 48.5940175, lng: 17.9941035 },
    { lat: 48.5939642, lng: 17.9945718 },
    { lat: 48.5939597, lng: 17.9952751 },
    { lat: 48.5938225, lng: 17.9956449 },
    { lat: 48.5938593, lng: 17.9962646 },
    { lat: 48.5938016, lng: 17.9963366 },
    { lat: 48.5938313, lng: 17.9965725 },
    { lat: 48.5937503, lng: 17.9970883 },
    { lat: 48.5936744, lng: 17.9971966 },
    { lat: 48.5936667, lng: 17.9972972 },
    { lat: 48.5937354, lng: 17.9975025 },
    { lat: 48.5934963, lng: 17.998152 },
    { lat: 48.5931623, lng: 17.9987387 },
    { lat: 48.5930147, lng: 17.9988424 },
    { lat: 48.5929158, lng: 17.9990932 },
    { lat: 48.5925872, lng: 17.9993779 },
    { lat: 48.5921504, lng: 17.9998726 },
    { lat: 48.5920051, lng: 18.0001418 },
    { lat: 48.5917269, lng: 18.0004553 },
    { lat: 48.5915754, lng: 18.0004883 },
    { lat: 48.5912299, lng: 18.0007321 },
    { lat: 48.5909608, lng: 18.0006493 },
    { lat: 48.5908496, lng: 18.0008886 },
    { lat: 48.5906898, lng: 18.0009337 },
    { lat: 48.5905897, lng: 18.001036 },
    { lat: 48.5905517, lng: 18.001173 },
    { lat: 48.5901035, lng: 18.0016261 },
    { lat: 48.5889458, lng: 18.0033425 },
    { lat: 48.5887793, lng: 18.0034466 },
    { lat: 48.5884766, lng: 18.0040735 },
    { lat: 48.5880218, lng: 18.0046037 },
    { lat: 48.5879381, lng: 18.004523 },
    { lat: 48.5877739, lng: 18.0048579 },
    { lat: 48.5874619, lng: 18.0052308 },
    { lat: 48.5872422, lng: 18.0053143 },
    { lat: 48.5870985, lng: 18.0054805 },
    { lat: 48.5870201, lng: 18.0054697 },
    { lat: 48.5868221, lng: 18.0056893 },
    { lat: 48.5865178, lng: 18.005869 },
    { lat: 48.5864949, lng: 18.0059577 },
    { lat: 48.586129, lng: 18.0062206 },
    { lat: 48.5858215, lng: 18.0066303 },
    { lat: 48.5856223, lng: 18.0066967 },
    { lat: 48.5855144, lng: 18.0070464 },
    { lat: 48.5853436, lng: 18.0072897 },
    { lat: 48.5853242, lng: 18.0074731 },
    { lat: 48.5850688, lng: 18.0079831 },
    { lat: 48.585007, lng: 18.0081964 },
    { lat: 48.5849926, lng: 18.0085039 },
    { lat: 48.5848174, lng: 18.0087292 },
    { lat: 48.5846218, lng: 18.008725 },
    { lat: 48.5843157, lng: 18.0093655 },
    { lat: 48.5841204, lng: 18.0095383 },
    { lat: 48.5840093, lng: 18.0101005 },
    { lat: 48.5836666, lng: 18.0102208 },
    { lat: 48.5833977, lng: 18.010595 },
    { lat: 48.5832079, lng: 18.0110872 },
    { lat: 48.5830991, lng: 18.0110994 },
    { lat: 48.5830492, lng: 18.0115906 },
    { lat: 48.5829326, lng: 18.0116976 },
    { lat: 48.5828868, lng: 18.0119344 },
    { lat: 48.5827516, lng: 18.0122424 },
    { lat: 48.5826772, lng: 18.0122253 },
    { lat: 48.5824063, lng: 18.0131965 },
    { lat: 48.5820098, lng: 18.0137604 },
    { lat: 48.5818674, lng: 18.0144055 },
    { lat: 48.5819311, lng: 18.0146236 },
    { lat: 48.5817529, lng: 18.0151392 },
    { lat: 48.581543, lng: 18.0154245 },
    { lat: 48.581385, lng: 18.0161466 },
    { lat: 48.5809758, lng: 18.0163859 },
    { lat: 48.5809691, lng: 18.0164799 },
    { lat: 48.5808126, lng: 18.0165049 },
    { lat: 48.580724, lng: 18.0167653 },
    { lat: 48.5805673, lng: 18.01692 },
    { lat: 48.5805329, lng: 18.0170619 },
    { lat: 48.5805883, lng: 18.0172744 },
    { lat: 48.5805271, lng: 18.0175941 },
    { lat: 48.5805624, lng: 18.0177712 },
    { lat: 48.5805014, lng: 18.0180549 },
    { lat: 48.5804354, lng: 18.0181975 },
    { lat: 48.5801463, lng: 18.0183473 },
    { lat: 48.5800808, lng: 18.0184598 },
    { lat: 48.5801197, lng: 18.0184892 },
    { lat: 48.5793163, lng: 18.0206661 },
    { lat: 48.5791043, lng: 18.0202494 },
    { lat: 48.578667, lng: 18.0206401 },
    { lat: 48.5786161, lng: 18.0208872 },
    { lat: 48.5784521, lng: 18.0211289 },
    { lat: 48.5783037, lng: 18.0211945 },
    { lat: 48.5782111, lng: 18.0214243 },
    { lat: 48.5781761, lng: 18.0217067 },
    { lat: 48.5780897, lng: 18.0217543 },
    { lat: 48.5780046, lng: 18.0216902 },
    { lat: 48.5778497, lng: 18.0217995 },
    { lat: 48.5777158, lng: 18.0221497 },
    { lat: 48.5776196, lng: 18.0222578 },
    { lat: 48.5775931, lng: 18.0232002 },
    { lat: 48.577653, lng: 18.0238526 },
    { lat: 48.5775517, lng: 18.0242194 },
    { lat: 48.5776435, lng: 18.0244355 },
    { lat: 48.5775969, lng: 18.0250018 },
    { lat: 48.5775061, lng: 18.0252977 },
    { lat: 48.5777398, lng: 18.0256227 },
    { lat: 48.577741, lng: 18.0258346 },
    { lat: 48.5774951, lng: 18.0262344 },
    { lat: 48.5776577, lng: 18.0264485 },
    { lat: 48.5775765, lng: 18.0269624 },
    { lat: 48.577655, lng: 18.0271257 },
    { lat: 48.5777252, lng: 18.0275483 },
    { lat: 48.5779379, lng: 18.0282265 },
    { lat: 48.5779135, lng: 18.0284803 },
    { lat: 48.5779636, lng: 18.0287912 },
    { lat: 48.5780821, lng: 18.0290597 },
    { lat: 48.5782763, lng: 18.0289851 },
    { lat: 48.5783446, lng: 18.0291481 },
    { lat: 48.5783422, lng: 18.0293191 },
    { lat: 48.5781041, lng: 18.0293362 },
    { lat: 48.5780119, lng: 18.0294968 },
    { lat: 48.5777551, lng: 18.0295253 },
    { lat: 48.5763855, lng: 18.030296 },
    { lat: 48.5751694, lng: 18.031281 },
    { lat: 48.5748818, lng: 18.0313396 },
    { lat: 48.5741208, lng: 18.031202 },
    { lat: 48.5738421, lng: 18.031316 },
    { lat: 48.5733679, lng: 18.0316962 },
    { lat: 48.572331, lng: 18.0328466 },
    { lat: 48.5711447, lng: 18.0335099 },
    { lat: 48.5696555, lng: 18.034764 },
    { lat: 48.5692323, lng: 18.0352617 },
    { lat: 48.5683526, lng: 18.0365868 },
    { lat: 48.5671252, lng: 18.0389452 },
    { lat: 48.5667788, lng: 18.0393306 },
    { lat: 48.5661743, lng: 18.0402355 },
    { lat: 48.5650564, lng: 18.0427868 },
    { lat: 48.5645701, lng: 18.0441266 },
    { lat: 48.5642795, lng: 18.0447646 },
    { lat: 48.5639688, lng: 18.0449142 },
    { lat: 48.5634133, lng: 18.045407 },
    { lat: 48.5623514, lng: 18.0451798 },
    { lat: 48.5621067, lng: 18.045317 },
    { lat: 48.5612515, lng: 18.0460299 },
    { lat: 48.5600884, lng: 18.0471471 },
    { lat: 48.5600414, lng: 18.0470771 },
    { lat: 48.5592674, lng: 18.0475575 },
    { lat: 48.5594987, lng: 18.048916 },
    { lat: 48.5590234, lng: 18.0489697 },
    { lat: 48.5583728, lng: 18.0492311 },
    { lat: 48.5581315, lng: 18.0493457 },
  ],
  Biskupová: [
    { lat: 48.4654711, lng: 18.0011306 },
    { lat: 48.4654555, lng: 18.0010601 },
    { lat: 48.4654892, lng: 18.0003144 },
    { lat: 48.4657472, lng: 17.998925 },
    { lat: 48.4658138, lng: 17.9974454 },
    { lat: 48.4651361, lng: 17.9918334 },
    { lat: 48.4650325, lng: 17.9907989 },
    { lat: 48.4649437, lng: 17.9888548 },
    { lat: 48.4650398, lng: 17.9865479 },
    { lat: 48.4650164, lng: 17.9862907 },
    { lat: 48.4653604, lng: 17.9837884 },
    { lat: 48.4655286, lng: 17.9819191 },
    { lat: 48.4665732, lng: 17.9821746 },
    { lat: 48.4675426, lng: 17.982585 },
    { lat: 48.4678758, lng: 17.9804931 },
    { lat: 48.4682925, lng: 17.9807623 },
    { lat: 48.4686508, lng: 17.9800694 },
    { lat: 48.4687937, lng: 17.9792893 },
    { lat: 48.4687704, lng: 17.9792551 },
    { lat: 48.4697256, lng: 17.9775641 },
    { lat: 48.4686274, lng: 17.9768468 },
    { lat: 48.4676235, lng: 17.9764206 },
    { lat: 48.4678958, lng: 17.9733281 },
    { lat: 48.4680005, lng: 17.972662 },
    { lat: 48.4681367, lng: 17.9721481 },
    { lat: 48.4681801, lng: 17.9713081 },
    { lat: 48.4683571, lng: 17.9706639 },
    { lat: 48.469014, lng: 17.9689854 },
    { lat: 48.4665765, lng: 17.9660854 },
    { lat: 48.4662553, lng: 17.9655139 },
    { lat: 48.4656705, lng: 17.9646968 },
    { lat: 48.464798, lng: 17.964665 },
    { lat: 48.464515, lng: 17.964594 },
    { lat: 48.464398, lng: 17.964625 },
    { lat: 48.464348, lng: 17.964635 },
    { lat: 48.46427, lng: 17.964656 },
    { lat: 48.464174, lng: 17.964668 },
    { lat: 48.463898, lng: 17.964707 },
    { lat: 48.463672, lng: 17.964675 },
    { lat: 48.463444, lng: 17.964707 },
    { lat: 48.463204, lng: 17.964665 },
    { lat: 48.462448, lng: 17.964486 },
    { lat: 48.462242, lng: 17.964438 },
    { lat: 48.462105, lng: 17.964418 },
    { lat: 48.461728, lng: 17.964235 },
    { lat: 48.461687, lng: 17.964192 },
    { lat: 48.461447, lng: 17.964055 },
    { lat: 48.46128, lng: 17.963991 },
    { lat: 48.461196, lng: 17.963929 },
    { lat: 48.46112, lng: 17.963916 },
    { lat: 48.461007, lng: 17.963885 },
    { lat: 48.460939, lng: 17.963823 },
    { lat: 48.460835, lng: 17.963681 },
    { lat: 48.460676, lng: 17.963476 },
    { lat: 48.460559, lng: 17.963375 },
    { lat: 48.460478, lng: 17.963238 },
    { lat: 48.460255, lng: 17.96299 },
    { lat: 48.460164, lng: 17.962919 },
    { lat: 48.46009, lng: 17.962796 },
    { lat: 48.459988, lng: 17.96247 },
    { lat: 48.459892, lng: 17.962263 },
    { lat: 48.45989, lng: 17.962137 },
    { lat: 48.459851, lng: 17.962141 },
    { lat: 48.459681, lng: 17.96212 },
    { lat: 48.459533, lng: 17.962103 },
    { lat: 48.45936, lng: 17.962088 },
    { lat: 48.459325, lng: 17.962061 },
    { lat: 48.459318, lng: 17.962114 },
    { lat: 48.459297, lng: 17.962256 },
    { lat: 48.459267, lng: 17.962451 },
    { lat: 48.459232, lng: 17.962634 },
    { lat: 48.459207, lng: 17.962794 },
    { lat: 48.45918, lng: 17.963001 },
    { lat: 48.459152, lng: 17.963178 },
    { lat: 48.459128, lng: 17.96332 },
    { lat: 48.459117, lng: 17.963532 },
    { lat: 48.459092, lng: 17.963615 },
    { lat: 48.459059, lng: 17.963723 },
    { lat: 48.459036, lng: 17.963782 },
    { lat: 48.459011, lng: 17.963825 },
    { lat: 48.458923, lng: 17.96394 },
    { lat: 48.458843, lng: 17.964056 },
    { lat: 48.458771, lng: 17.964159 },
    { lat: 48.458694, lng: 17.964227 },
    { lat: 48.45861, lng: 17.964296 },
    { lat: 48.458518, lng: 17.964376 },
    { lat: 48.458437, lng: 17.964445 },
    { lat: 48.458356, lng: 17.964513 },
    { lat: 48.458283, lng: 17.964581 },
    { lat: 48.458218, lng: 17.964637 },
    { lat: 48.458137, lng: 17.964706 },
    { lat: 48.458076, lng: 17.964762 },
    { lat: 48.458049, lng: 17.96481 },
    { lat: 48.457993, lng: 17.96493 },
    { lat: 48.45794, lng: 17.96502 },
    { lat: 48.457887, lng: 17.965093 },
    { lat: 48.457857, lng: 17.965147 },
    { lat: 48.457808, lng: 17.965232 },
    { lat: 48.457752, lng: 17.965352 },
    { lat: 48.457688, lng: 17.965472 },
    { lat: 48.457647, lng: 17.965556 },
    { lat: 48.457598, lng: 17.965664 },
    { lat: 48.45755, lng: 17.965761 },
    { lat: 48.457515, lng: 17.965827 },
    { lat: 48.457478, lng: 17.965881 },
    { lat: 48.457428, lng: 17.965942 },
    { lat: 48.457378, lng: 17.96601 },
    { lat: 48.457352, lng: 17.966058 },
    { lat: 48.457391, lng: 17.966061 },
    { lat: 48.457426, lng: 17.966248 },
    { lat: 48.45741, lng: 17.96656 },
    { lat: 48.457401, lng: 17.966695 },
    { lat: 48.457376, lng: 17.967007 },
    { lat: 48.457352, lng: 17.967325 },
    { lat: 48.457331, lng: 17.967626 },
    { lat: 48.45731, lng: 17.967879 },
    { lat: 48.457287, lng: 17.968126 },
    { lat: 48.457267, lng: 17.968292 },
    { lat: 48.457252, lng: 17.968545 },
    { lat: 48.457234, lng: 17.968769 },
    { lat: 48.457211, lng: 17.968969 },
    { lat: 48.457197, lng: 17.969058 },
    { lat: 48.457191, lng: 17.969123 },
    { lat: 48.457176, lng: 17.969299 },
    { lat: 48.457146, lng: 17.969553 },
    { lat: 48.457123, lng: 17.969777 },
    { lat: 48.457092, lng: 17.969996 },
    { lat: 48.457066, lng: 17.970226 },
    { lat: 48.457037, lng: 17.970503 },
    { lat: 48.457044, lng: 17.970645 },
    { lat: 48.456999, lng: 17.971029 },
    { lat: 48.45698, lng: 17.971189 },
    { lat: 48.45696, lng: 17.971324 },
    { lat: 48.456944, lng: 17.971408 },
    { lat: 48.456924, lng: 17.971562 },
    { lat: 48.456904, lng: 17.971821 },
    { lat: 48.456879, lng: 17.972045 },
    { lat: 48.456851, lng: 17.972311 },
    { lat: 48.456807, lng: 17.972689 },
    { lat: 48.456757, lng: 17.973026 },
    { lat: 48.456724, lng: 17.973357 },
    { lat: 48.456682, lng: 17.97367 },
    { lat: 48.456644, lng: 17.97389 },
    { lat: 48.456633, lng: 17.974037 },
    { lat: 48.456542, lng: 17.974453 },
    { lat: 48.456499, lng: 17.974702 },
    { lat: 48.456448, lng: 17.974963 },
    { lat: 48.456393, lng: 17.975389 },
    { lat: 48.456353, lng: 17.975667 },
    { lat: 48.456315, lng: 17.97598 },
    { lat: 48.456281, lng: 17.976288 },
    { lat: 48.456255, lng: 17.976494 },
    { lat: 48.456248, lng: 17.97667 },
    { lat: 48.456234, lng: 17.976889 },
    { lat: 48.456219, lng: 17.977165 },
    { lat: 48.4562, lng: 17.97746 },
    { lat: 48.456188, lng: 17.977712 },
    { lat: 48.456171, lng: 17.978001 },
    { lat: 48.456161, lng: 17.978201 },
    { lat: 48.456175, lng: 17.97827 },
    { lat: 48.456188, lng: 17.978299 },
    { lat: 48.456263, lng: 17.978811 },
    { lat: 48.456268, lng: 17.979109 },
    { lat: 48.456261, lng: 17.979409 },
    { lat: 48.456263, lng: 17.979649 },
    { lat: 48.456298, lng: 17.979759 },
    { lat: 48.456345, lng: 17.979926 },
    { lat: 48.456358, lng: 17.980124 },
    { lat: 48.45637, lng: 17.980288 },
    { lat: 48.456389, lng: 17.980533 },
    { lat: 48.456405, lng: 17.980702 },
    { lat: 48.456411, lng: 17.98079 },
    { lat: 48.456418, lng: 17.980895 },
    { lat: 48.456452, lng: 17.981292 },
    { lat: 48.45649, lng: 17.981682 },
    { lat: 48.456515, lng: 17.98191 },
    { lat: 48.456551, lng: 17.982207 },
    { lat: 48.456578, lng: 17.982616 },
    { lat: 48.456595, lng: 17.98299 },
    { lat: 48.456616, lng: 17.983317 },
    { lat: 48.456617, lng: 17.983458 },
    { lat: 48.456623, lng: 17.98371 },
    { lat: 48.456626, lng: 17.984109 },
    { lat: 48.456621, lng: 17.984367 },
    { lat: 48.456614, lng: 17.984526 },
    { lat: 48.456605, lng: 17.984731 },
    { lat: 48.456601, lng: 17.984902 },
    { lat: 48.456585, lng: 17.985184 },
    { lat: 48.456519, lng: 17.98547 },
    { lat: 48.456453, lng: 17.985761 },
    { lat: 48.456381, lng: 17.986118 },
    { lat: 48.456296, lng: 17.986457 },
    { lat: 48.456241, lng: 17.98673 },
    { lat: 48.456154, lng: 17.987111 },
    { lat: 48.456133, lng: 17.987317 },
    { lat: 48.456095, lng: 17.987678 },
    { lat: 48.456055, lng: 17.988085 },
    { lat: 48.456021, lng: 17.988386 },
    { lat: 48.455998, lng: 17.988692 },
    { lat: 48.455961, lng: 17.98904 },
    { lat: 48.455943, lng: 17.989224 },
    { lat: 48.455931, lng: 17.989517 },
    { lat: 48.455913, lng: 17.989865 },
    { lat: 48.455888, lng: 17.99023 },
    { lat: 48.455881, lng: 17.990547 },
    { lat: 48.455875, lng: 17.99077 },
    { lat: 48.455861, lng: 17.990953 },
    { lat: 48.455851, lng: 17.991059 },
    { lat: 48.455842, lng: 17.991283 },
    { lat: 48.455831, lng: 17.991477 },
    { lat: 48.455808, lng: 17.991883 },
    { lat: 48.455793, lng: 17.992095 },
    { lat: 48.455769, lng: 17.99246 },
    { lat: 48.455756, lng: 17.992713 },
    { lat: 48.455749, lng: 17.992907 },
    { lat: 48.455726, lng: 17.993184 },
    { lat: 48.455728, lng: 17.993448 },
    { lat: 48.455731, lng: 17.993676 },
    { lat: 48.45573, lng: 17.993935 },
    { lat: 48.455732, lng: 17.994257 },
    { lat: 48.455731, lng: 17.994527 },
    { lat: 48.45573, lng: 17.994779 },
    { lat: 48.45573, lng: 17.994955 },
    { lat: 48.455729, lng: 17.995172 },
    { lat: 48.455732, lng: 17.995289 },
    { lat: 48.455743, lng: 17.995553 },
    { lat: 48.455751, lng: 17.995723 },
    { lat: 48.455764, lng: 17.995904 },
    { lat: 48.455768, lng: 17.996068 },
    { lat: 48.455772, lng: 17.996178 },
    { lat: 48.455806, lng: 17.996684 },
    { lat: 48.455838, lng: 17.997235 },
    { lat: 48.455857, lng: 17.997528 },
    { lat: 48.455887, lng: 17.99802 },
    { lat: 48.455918, lng: 17.998548 },
    { lat: 48.455933, lng: 17.998882 },
    { lat: 48.455947, lng: 17.999193 },
    { lat: 48.455956, lng: 17.999339 },
    { lat: 48.455974, lng: 17.999691 },
    { lat: 48.455992, lng: 18.000031 },
    { lat: 48.456007, lng: 18.000289 },
    { lat: 48.456011, lng: 18.000401 },
    { lat: 48.456028, lng: 18.000635 },
    { lat: 48.456033, lng: 18.000717 },
    { lat: 48.456046, lng: 18.000958 },
    { lat: 48.456068, lng: 18.001221 },
    { lat: 48.456095, lng: 18.001673 },
    { lat: 48.456109, lng: 18.001948 },
    { lat: 48.456118, lng: 18.002083 },
    { lat: 48.456114, lng: 18.002171 },
    { lat: 48.456096, lng: 18.002306 },
    { lat: 48.456084, lng: 18.002353 },
    { lat: 48.456061, lng: 18.002448 },
    { lat: 48.456027, lng: 18.002589 },
    { lat: 48.455978, lng: 18.002789 },
    { lat: 48.455922, lng: 18.002983 },
    { lat: 48.455868, lng: 18.003154 },
    { lat: 48.455842, lng: 18.003231 },
    { lat: 48.455811, lng: 18.003302 },
    { lat: 48.455762, lng: 18.003438 },
    { lat: 48.455711, lng: 18.003579 },
    { lat: 48.455651, lng: 18.00372 },
    { lat: 48.455631, lng: 18.003788 },
    { lat: 48.455643, lng: 18.003801 },
    { lat: 48.455721, lng: 18.003882 },
    { lat: 48.455924, lng: 18.004095 },
    { lat: 48.45608, lng: 18.004257 },
    { lat: 48.456197, lng: 18.004381 },
    { lat: 48.456307, lng: 18.004494 },
    { lat: 48.456402, lng: 18.004594 },
    { lat: 48.456521, lng: 18.00472 },
    { lat: 48.456629, lng: 18.004835 },
    { lat: 48.456733, lng: 18.004945 },
    { lat: 48.456778, lng: 18.004978 },
    { lat: 48.456921, lng: 18.005086 },
    { lat: 48.456995, lng: 18.005141 },
    { lat: 48.457054, lng: 18.005186 },
    { lat: 48.457129, lng: 18.005241 },
    { lat: 48.457178, lng: 18.005278 },
    { lat: 48.457223, lng: 18.005307 },
    { lat: 48.457341, lng: 18.005373 },
    { lat: 48.457425, lng: 18.005417 },
    { lat: 48.457483, lng: 18.005424 },
    { lat: 48.457591, lng: 18.00544 },
    { lat: 48.45766, lng: 18.005434 },
    { lat: 48.457896, lng: 18.005408 },
    { lat: 48.457885, lng: 18.005295 },
    { lat: 48.457958, lng: 18.005254 },
    { lat: 48.458023, lng: 18.005214 },
    { lat: 48.458194, lng: 18.005112 },
    { lat: 48.458339, lng: 18.005046 },
    { lat: 48.45853, lng: 18.004953 },
    { lat: 48.458619, lng: 18.004924 },
    { lat: 48.458673, lng: 18.004906 },
    { lat: 48.458784, lng: 18.004869 },
    { lat: 48.458901, lng: 18.004831 },
    { lat: 48.458971, lng: 18.004808 },
    { lat: 48.459046, lng: 18.004785 },
    { lat: 48.459149, lng: 18.004761 },
    { lat: 48.459268, lng: 18.004734 },
    { lat: 48.459286, lng: 18.004731 },
    { lat: 48.4593, lng: 18.004727 },
    { lat: 48.45937, lng: 18.004708 },
    { lat: 48.459422, lng: 18.004651 },
    { lat: 48.459574, lng: 18.004493 },
    { lat: 48.45973, lng: 18.00433 },
    { lat: 48.46, lng: 18.004052 },
    { lat: 48.46031, lng: 18.00374 },
    { lat: 48.460313, lng: 18.003737 },
    { lat: 48.460397, lng: 18.003649 },
    { lat: 48.460601, lng: 18.003484 },
    { lat: 48.460816, lng: 18.003363 },
    { lat: 48.460817, lng: 18.003362 },
    { lat: 48.460942, lng: 18.00329 },
    { lat: 48.461067, lng: 18.003244 },
    { lat: 48.461276, lng: 18.003166 },
    { lat: 48.461373, lng: 18.003129 },
    { lat: 48.461638, lng: 18.003034 },
    { lat: 48.461643, lng: 18.003033 },
    { lat: 48.461876, lng: 18.002952 },
    { lat: 48.461905, lng: 18.00294 },
    { lat: 48.462011, lng: 18.002946 },
    { lat: 48.462136, lng: 18.00295 },
    { lat: 48.462194, lng: 18.002952 },
    { lat: 48.462475, lng: 18.002958 },
    { lat: 48.462533, lng: 18.00296 },
    { lat: 48.462613, lng: 18.002962 },
    { lat: 48.462648, lng: 18.00295 },
    { lat: 48.46288, lng: 18.002861 },
    { lat: 48.462992, lng: 18.002818 },
    { lat: 48.463111, lng: 18.002767 },
    { lat: 48.463225, lng: 18.002718 },
    { lat: 48.4632341, lng: 18.0027032 },
    { lat: 48.4634425, lng: 18.0024533 },
    { lat: 48.4640323, lng: 18.0020275 },
    { lat: 48.46507, lng: 18.0015602 },
    { lat: 48.4654711, lng: 18.0011306 },
  ],
  Blesovce: [
    { lat: 48.5366422, lng: 18.0567991 },
    { lat: 48.5368332, lng: 18.0579524 },
    { lat: 48.5371332, lng: 18.0592292 },
    { lat: 48.537167, lng: 18.0592293 },
    { lat: 48.5396597, lng: 18.05913 },
    { lat: 48.5422922, lng: 18.0583919 },
    { lat: 48.5426281, lng: 18.0594564 },
    { lat: 48.5461052, lng: 18.0577319 },
    { lat: 48.5465161, lng: 18.0574657 },
    { lat: 48.5525174, lng: 18.0534325 },
    { lat: 48.5545282, lng: 18.0521483 },
    { lat: 48.5573656, lng: 18.0498723 },
    { lat: 48.5580886, lng: 18.0493679 },
    { lat: 48.5581315, lng: 18.0493457 },
    { lat: 48.5583728, lng: 18.0492311 },
    { lat: 48.5590234, lng: 18.0489697 },
    { lat: 48.5594987, lng: 18.048916 },
    { lat: 48.5592674, lng: 18.0475575 },
    { lat: 48.5600414, lng: 18.0470771 },
    { lat: 48.5600884, lng: 18.0471471 },
    { lat: 48.5612515, lng: 18.0460299 },
    { lat: 48.5621067, lng: 18.045317 },
    { lat: 48.5623514, lng: 18.0451798 },
    { lat: 48.5634133, lng: 18.045407 },
    { lat: 48.5639688, lng: 18.0449142 },
    { lat: 48.5642795, lng: 18.0447646 },
    { lat: 48.5645701, lng: 18.0441266 },
    { lat: 48.5650564, lng: 18.0427868 },
    { lat: 48.5661743, lng: 18.0402355 },
    { lat: 48.5667788, lng: 18.0393306 },
    { lat: 48.5671252, lng: 18.0389452 },
    { lat: 48.5683526, lng: 18.0365868 },
    { lat: 48.5692323, lng: 18.0352617 },
    { lat: 48.5696555, lng: 18.034764 },
    { lat: 48.5711447, lng: 18.0335099 },
    { lat: 48.572331, lng: 18.0328466 },
    { lat: 48.5733679, lng: 18.0316962 },
    { lat: 48.5738421, lng: 18.031316 },
    { lat: 48.5741208, lng: 18.031202 },
    { lat: 48.5748818, lng: 18.0313396 },
    { lat: 48.5751694, lng: 18.031281 },
    { lat: 48.5763855, lng: 18.030296 },
    { lat: 48.5777551, lng: 18.0295253 },
    { lat: 48.5780119, lng: 18.0294968 },
    { lat: 48.5781041, lng: 18.0293362 },
    { lat: 48.5783422, lng: 18.0293191 },
    { lat: 48.5783446, lng: 18.0291481 },
    { lat: 48.5782763, lng: 18.0289851 },
    { lat: 48.5780821, lng: 18.0290597 },
    { lat: 48.5779636, lng: 18.0287912 },
    { lat: 48.5779135, lng: 18.0284803 },
    { lat: 48.5779379, lng: 18.0282265 },
    { lat: 48.5777252, lng: 18.0275483 },
    { lat: 48.577655, lng: 18.0271257 },
    { lat: 48.5775765, lng: 18.0269624 },
    { lat: 48.5776577, lng: 18.0264485 },
    { lat: 48.5774951, lng: 18.0262344 },
    { lat: 48.577741, lng: 18.0258346 },
    { lat: 48.5777398, lng: 18.0256227 },
    { lat: 48.5775061, lng: 18.0252977 },
    { lat: 48.5775969, lng: 18.0250018 },
    { lat: 48.5776435, lng: 18.0244355 },
    { lat: 48.5775517, lng: 18.0242194 },
    { lat: 48.577653, lng: 18.0238526 },
    { lat: 48.5775931, lng: 18.0232002 },
    { lat: 48.5776196, lng: 18.0222578 },
    { lat: 48.5777158, lng: 18.0221497 },
    { lat: 48.5778497, lng: 18.0217995 },
    { lat: 48.5780046, lng: 18.0216902 },
    { lat: 48.5779961, lng: 18.0216789 },
    { lat: 48.5773351, lng: 18.0205977 },
    { lat: 48.5765922, lng: 18.0220435 },
    { lat: 48.5757067, lng: 18.0233281 },
    { lat: 48.5725428, lng: 18.0255539 },
    { lat: 48.5724979, lng: 18.0252363 },
    { lat: 48.5719026, lng: 18.0258322 },
    { lat: 48.5713323, lng: 18.0266119 },
    { lat: 48.5711522, lng: 18.0270377 },
    { lat: 48.5710439, lng: 18.0271332 },
    { lat: 48.5709499, lng: 18.0271287 },
    { lat: 48.5708197, lng: 18.0268536 },
    { lat: 48.5707194, lng: 18.0269904 },
    { lat: 48.5656303, lng: 18.0279817 },
    { lat: 48.564807, lng: 18.0251736 },
    { lat: 48.5643326, lng: 18.0252976 },
    { lat: 48.5638565, lng: 18.0252451 },
    { lat: 48.5632836, lng: 18.0232519 },
    { lat: 48.5628095, lng: 18.023517 },
    { lat: 48.5618842, lng: 18.0238826 },
    { lat: 48.5617619, lng: 18.0235307 },
    { lat: 48.5612458, lng: 18.0226726 },
    { lat: 48.5610597, lng: 18.0228048 },
    { lat: 48.5603554, lng: 18.0230731 },
    { lat: 48.5587835, lng: 18.0233262 },
    { lat: 48.5590654, lng: 18.0247869 },
    { lat: 48.55846, lng: 18.0250835 },
    { lat: 48.5571722, lng: 18.0261537 },
    { lat: 48.5564623, lng: 18.0266104 },
    { lat: 48.5560958, lng: 18.0258331 },
    { lat: 48.5549881, lng: 18.0268541 },
    { lat: 48.5548182, lng: 18.0270722 },
    { lat: 48.5547173, lng: 18.0271969 },
    { lat: 48.5544427, lng: 18.0275356 },
    { lat: 48.5538506, lng: 18.0284965 },
    { lat: 48.5541725, lng: 18.0297405 },
    { lat: 48.5553625, lng: 18.0322643 },
    { lat: 48.5560347, lng: 18.0334755 },
    { lat: 48.5553776, lng: 18.0347904 },
    { lat: 48.5553483, lng: 18.0350024 },
    { lat: 48.5545629, lng: 18.0362065 },
    { lat: 48.554295, lng: 18.0365036 },
    { lat: 48.5541121, lng: 18.0366532 },
    { lat: 48.5527317, lng: 18.0373223 },
    { lat: 48.5507921, lng: 18.0384389 },
    { lat: 48.5492349, lng: 18.039569 },
    { lat: 48.547411, lng: 18.0411907 },
    { lat: 48.5462527, lng: 18.041932 },
    { lat: 48.5455056, lng: 18.0422762 },
    { lat: 48.5443755, lng: 18.0422706 },
    { lat: 48.5428812, lng: 18.0418697 },
    { lat: 48.538339, lng: 18.0430032 },
    { lat: 48.5382944, lng: 18.0450392 },
    { lat: 48.538222, lng: 18.0454396 },
    { lat: 48.5381414, lng: 18.0455231 },
    { lat: 48.5380822, lng: 18.0469281 },
    { lat: 48.5390273, lng: 18.0502657 },
    { lat: 48.5380951, lng: 18.0502623 },
    { lat: 48.5381143, lng: 18.0507797 },
    { lat: 48.5372463, lng: 18.0515808 },
    { lat: 48.5372687, lng: 18.0519802 },
    { lat: 48.5382806, lng: 18.055399 },
    { lat: 48.5380368, lng: 18.0555673 },
    { lat: 48.5373486, lng: 18.0564892 },
    { lat: 48.5371857, lng: 18.0566385 },
    { lat: 48.5366422, lng: 18.0567991 },
  ],
  Kovarce: [
    { lat: 48.5129059, lng: 18.1479738 },
    { lat: 48.5129386, lng: 18.1477438 },
    { lat: 48.5120009, lng: 18.1466777 },
    { lat: 48.5117948, lng: 18.1470341 },
    { lat: 48.5116519, lng: 18.1470198 },
    { lat: 48.5113089, lng: 18.1468284 },
    { lat: 48.5111374, lng: 18.1465958 },
    { lat: 48.5109423, lng: 18.1466393 },
    { lat: 48.5107602, lng: 18.1464929 },
    { lat: 48.5108031, lng: 18.1461854 },
    { lat: 48.5107147, lng: 18.1459276 },
    { lat: 48.5108311, lng: 18.1455998 },
    { lat: 48.5108214, lng: 18.145395 },
    { lat: 48.5107141, lng: 18.145023 },
    { lat: 48.5105807, lng: 18.1447505 },
    { lat: 48.5105663, lng: 18.1444627 },
    { lat: 48.5103472, lng: 18.1441517 },
    { lat: 48.5102707, lng: 18.143692 },
    { lat: 48.5100408, lng: 18.1428419 },
    { lat: 48.5094901, lng: 18.1384074 },
    { lat: 48.5000837, lng: 18.1291945 },
    { lat: 48.5000887, lng: 18.1292027 },
    { lat: 48.4995846, lng: 18.1293263 },
    { lat: 48.4994717, lng: 18.1294213 },
    { lat: 48.4993834, lng: 18.1296101 },
    { lat: 48.4991113, lng: 18.1299361 },
    { lat: 48.4985831, lng: 18.1310396 },
    { lat: 48.4985013, lng: 18.1311458 },
    { lat: 48.4981212, lng: 18.1313786 },
    { lat: 48.49794, lng: 18.1315811 },
    { lat: 48.4977222, lng: 18.1320874 },
    { lat: 48.4971927, lng: 18.1322574 },
    { lat: 48.4967169, lng: 18.1320306 },
    { lat: 48.4965975, lng: 18.132035 },
    { lat: 48.4964827, lng: 18.1321182 },
    { lat: 48.4963535, lng: 18.1323395 },
    { lat: 48.4962595, lng: 18.1327017 },
    { lat: 48.4960728, lng: 18.1328854 },
    { lat: 48.495831, lng: 18.1328643 },
    { lat: 48.4955643, lng: 18.1326619 },
    { lat: 48.4952184, lng: 18.132149 },
    { lat: 48.4950062, lng: 18.1317223 },
    { lat: 48.4948523, lng: 18.1321408 },
    { lat: 48.4946653, lng: 18.1323604 },
    { lat: 48.4945559, lng: 18.1323614 },
    { lat: 48.494196, lng: 18.1321101 },
    { lat: 48.4940731, lng: 18.1323839 },
    { lat: 48.4940241, lng: 18.1326661 },
    { lat: 48.4934432, lng: 18.1334826 },
    { lat: 48.4930219, lng: 18.1329174 },
    { lat: 48.4928948, lng: 18.1328264 },
    { lat: 48.4927556, lng: 18.1328191 },
    { lat: 48.4926615, lng: 18.1329294 },
    { lat: 48.4922717, lng: 18.1337352 },
    { lat: 48.4920295, lng: 18.1340497 },
    { lat: 48.4918106, lng: 18.1342059 },
    { lat: 48.4916616, lng: 18.1342043 },
    { lat: 48.4915263, lng: 18.1341674 },
    { lat: 48.491375, lng: 18.1340312 },
    { lat: 48.4906932, lng: 18.1329447 },
    { lat: 48.4904842, lng: 18.1326905 },
    { lat: 48.4901582, lng: 18.1324404 },
    { lat: 48.4901105, lng: 18.1326278 },
    { lat: 48.4898561, lng: 18.1337632 },
    { lat: 48.488937, lng: 18.1372914 },
    { lat: 48.4884972, lng: 18.1398152 },
    { lat: 48.4881022, lng: 18.1410548 },
    { lat: 48.4873808, lng: 18.1429832 },
    { lat: 48.4871429, lng: 18.1438196 },
    { lat: 48.4871734, lng: 18.1439781 },
    { lat: 48.486931, lng: 18.1442335 },
    { lat: 48.486753, lng: 18.1447009 },
    { lat: 48.4868648, lng: 18.1448787 },
    { lat: 48.4872436, lng: 18.1449465 },
    { lat: 48.4873049, lng: 18.1450704 },
    { lat: 48.487293, lng: 18.1451587 },
    { lat: 48.4870585, lng: 18.1452971 },
    { lat: 48.4869305, lng: 18.1452311 },
    { lat: 48.4868789, lng: 18.1452948 },
    { lat: 48.4869074, lng: 18.1460167 },
    { lat: 48.4868711, lng: 18.1461688 },
    { lat: 48.4866365, lng: 18.1464598 },
    { lat: 48.4866812, lng: 18.1467655 },
    { lat: 48.4869783, lng: 18.147408 },
    { lat: 48.4870152, lng: 18.1479246 },
    { lat: 48.4869023, lng: 18.1480405 },
    { lat: 48.4868323, lng: 18.1479989 },
    { lat: 48.4865939, lng: 18.1480548 },
    { lat: 48.486398, lng: 18.1482169 },
    { lat: 48.4863434, lng: 18.1484041 },
    { lat: 48.4864421, lng: 18.1488393 },
    { lat: 48.4863397, lng: 18.1490378 },
    { lat: 48.4862272, lng: 18.1490836 },
    { lat: 48.4859526, lng: 18.1493681 },
    { lat: 48.4860009, lng: 18.1496523 },
    { lat: 48.4859787, lng: 18.1499295 },
    { lat: 48.4851287, lng: 18.151362 },
    { lat: 48.4847615, lng: 18.1514886 },
    { lat: 48.4845598, lng: 18.1517852 },
    { lat: 48.4844999, lng: 18.1520926 },
    { lat: 48.4842772, lng: 18.1526715 },
    { lat: 48.4839801, lng: 18.15286 },
    { lat: 48.4835644, lng: 18.1528888 },
    { lat: 48.4834295, lng: 18.1530182 },
    { lat: 48.4833877, lng: 18.153266 },
    { lat: 48.4834114, lng: 18.1536936 },
    { lat: 48.483238, lng: 18.1541647 },
    { lat: 48.4830673, lng: 18.1544127 },
    { lat: 48.4826638, lng: 18.1545174 },
    { lat: 48.482588, lng: 18.1546323 },
    { lat: 48.4825003, lng: 18.1549469 },
    { lat: 48.4822457, lng: 18.1546642 },
    { lat: 48.4821651, lng: 18.1549846 },
    { lat: 48.4820801, lng: 18.1558331 },
    { lat: 48.481957, lng: 18.1562783 },
    { lat: 48.4820028, lng: 18.1564234 },
    { lat: 48.4820009, lng: 18.1567403 },
    { lat: 48.4819507, lng: 18.1569831 },
    { lat: 48.4818427, lng: 18.1572402 },
    { lat: 48.4815343, lng: 18.1572353 },
    { lat: 48.4813112, lng: 18.1573969 },
    { lat: 48.4812179, lng: 18.157618 },
    { lat: 48.4812562, lng: 18.1577926 },
    { lat: 48.4809419, lng: 18.1580524 },
    { lat: 48.4808787, lng: 18.1581898 },
    { lat: 48.4806166, lng: 18.1595627 },
    { lat: 48.4806775, lng: 18.1597358 },
    { lat: 48.4808217, lng: 18.159906 },
    { lat: 48.480575, lng: 18.1600516 },
    { lat: 48.4804919, lng: 18.1604068 },
    { lat: 48.48031, lng: 18.1606552 },
    { lat: 48.4802186, lng: 18.1605591 },
    { lat: 48.4799728, lng: 18.1605814 },
    { lat: 48.4798675, lng: 18.1607674 },
    { lat: 48.4798857, lng: 18.1608899 },
    { lat: 48.4801714, lng: 18.1611422 },
    { lat: 48.4802448, lng: 18.1613385 },
    { lat: 48.4802767, lng: 18.1619653 },
    { lat: 48.4803167, lng: 18.1620105 },
    { lat: 48.4833455, lng: 18.163469 },
    { lat: 48.4829891, lng: 18.1639772 },
    { lat: 48.4825609, lng: 18.1650569 },
    { lat: 48.4817264, lng: 18.1678624 },
    { lat: 48.4807481, lng: 18.170761 },
    { lat: 48.4798347, lng: 18.1739622 },
    { lat: 48.4788113, lng: 18.1766491 },
    { lat: 48.4783403, lng: 18.1776129 },
    { lat: 48.4773956, lng: 18.1792643 },
    { lat: 48.4768821, lng: 18.1804335 },
    { lat: 48.4768206, lng: 18.1804042 },
    { lat: 48.4764465, lng: 18.1808807 },
    { lat: 48.4758006, lng: 18.1813403 },
    { lat: 48.4754338, lng: 18.1816697 },
    { lat: 48.4752634, lng: 18.181905 },
    { lat: 48.4750531, lng: 18.1820343 },
    { lat: 48.4745037, lng: 18.1829339 },
    { lat: 48.474205, lng: 18.1835218 },
    { lat: 48.4736837, lng: 18.1842622 },
    { lat: 48.4734152, lng: 18.184791 },
    { lat: 48.473194, lng: 18.1855483 },
    { lat: 48.4726615, lng: 18.1867407 },
    { lat: 48.4717911, lng: 18.189131 },
    { lat: 48.4715655, lng: 18.1895425 },
    { lat: 48.4711115, lng: 18.1906289 },
    { lat: 48.4709047, lng: 18.1910109 },
    { lat: 48.4703168, lng: 18.1917339 },
    { lat: 48.4700058, lng: 18.1922103 },
    { lat: 48.4699055, lng: 18.1925036 },
    { lat: 48.4698506, lng: 18.1930964 },
    { lat: 48.4694891, lng: 18.1940654 },
    { lat: 48.4683106, lng: 18.1954642 },
    { lat: 48.4676914, lng: 18.1965628 },
    { lat: 48.4650739, lng: 18.2016237 },
    { lat: 48.4607194, lng: 18.2097959 },
    { lat: 48.4598499, lng: 18.2115615 },
    { lat: 48.455747, lng: 18.2194058 },
    { lat: 48.453709, lng: 18.222694 },
    { lat: 48.453725, lng: 18.222715 },
    { lat: 48.453738, lng: 18.22273 },
    { lat: 48.453979, lng: 18.222884 },
    { lat: 48.454235, lng: 18.223147 },
    { lat: 48.454424, lng: 18.223299 },
    { lat: 48.454796, lng: 18.223492 },
    { lat: 48.45526, lng: 18.223705 },
    { lat: 48.455543, lng: 18.223671 },
    { lat: 48.455815, lng: 18.223597 },
    { lat: 48.456048, lng: 18.223589 },
    { lat: 48.456163, lng: 18.223608 },
    { lat: 48.456543, lng: 18.223507 },
    { lat: 48.45677, lng: 18.223485 },
    { lat: 48.456796, lng: 18.223492 },
    { lat: 48.457033, lng: 18.223548 },
    { lat: 48.457161, lng: 18.2236 },
    { lat: 48.457468, lng: 18.223662 },
    { lat: 48.457676, lng: 18.223791 },
    { lat: 48.457798, lng: 18.223844 },
    { lat: 48.457852, lng: 18.223861 },
    { lat: 48.457999, lng: 18.223897 },
    { lat: 48.458195, lng: 18.223862 },
    { lat: 48.458449, lng: 18.223857 },
    { lat: 48.458613, lng: 18.223897 },
    { lat: 48.458755, lng: 18.223898 },
    { lat: 48.459327, lng: 18.224001 },
    { lat: 48.459624, lng: 18.224109 },
    { lat: 48.45987, lng: 18.224275 },
    { lat: 48.459884, lng: 18.224303 },
    { lat: 48.460994, lng: 18.226853 },
    { lat: 48.461376, lng: 18.22771 },
    { lat: 48.461396, lng: 18.227758 },
    { lat: 48.461683, lng: 18.228414 },
    { lat: 48.462446, lng: 18.229339 },
    { lat: 48.463878, lng: 18.233432 },
    { lat: 48.464555, lng: 18.236322 },
    { lat: 48.464695, lng: 18.237319 },
    { lat: 48.465136, lng: 18.238695 },
    { lat: 48.46515, lng: 18.238739 },
    { lat: 48.465168, lng: 18.238745 },
    { lat: 48.465235, lng: 18.238786 },
    { lat: 48.465427, lng: 18.238888 },
    { lat: 48.465528, lng: 18.238945 },
    { lat: 48.465728, lng: 18.239059 },
    { lat: 48.46592, lng: 18.239167 },
    { lat: 48.465947, lng: 18.239184 },
    { lat: 48.466061, lng: 18.2393 },
    { lat: 48.466223, lng: 18.239434 },
    { lat: 48.466429, lng: 18.239633 },
    { lat: 48.466641, lng: 18.239839 },
    { lat: 48.466803, lng: 18.239992 },
    { lat: 48.467065, lng: 18.240107 },
    { lat: 48.467312, lng: 18.240194 },
    { lat: 48.467528, lng: 18.240288 },
    { lat: 48.467818, lng: 18.240145 },
    { lat: 48.468074, lng: 18.240031 },
    { lat: 48.468144, lng: 18.24 },
    { lat: 48.468349, lng: 18.239882 },
    { lat: 48.468565, lng: 18.239776 },
    { lat: 48.468748, lng: 18.239677 },
    { lat: 48.468771, lng: 18.2397 },
    { lat: 48.468937, lng: 18.239871 },
    { lat: 48.469115, lng: 18.240054 },
    { lat: 48.46917, lng: 18.240105 },
    { lat: 48.469474, lng: 18.240431 },
    { lat: 48.469648, lng: 18.240607 },
    { lat: 48.469927, lng: 18.240898 },
    { lat: 48.470093, lng: 18.24107 },
    { lat: 48.470267, lng: 18.241252 },
    { lat: 48.47048, lng: 18.241463 },
    { lat: 48.470634, lng: 18.241623 },
    { lat: 48.470662, lng: 18.241681 },
    { lat: 48.470873, lng: 18.242015 },
    { lat: 48.471055, lng: 18.242327 },
    { lat: 48.47121, lng: 18.242574 },
    { lat: 48.47131, lng: 18.242748 },
    { lat: 48.47152, lng: 18.243076 },
    { lat: 48.471699, lng: 18.243382 },
    { lat: 48.471745, lng: 18.243551 },
    { lat: 48.471833, lng: 18.243853 },
    { lat: 48.471984, lng: 18.244359 },
    { lat: 48.472102, lng: 18.24476 },
    { lat: 48.472147, lng: 18.244918 },
    { lat: 48.472208, lng: 18.245115 },
    { lat: 48.472362, lng: 18.245633 },
    { lat: 48.472465, lng: 18.246006 },
    { lat: 48.472568, lng: 18.246214 },
    { lat: 48.472664, lng: 18.246393 },
    { lat: 48.4728, lng: 18.246648 },
    { lat: 48.472927, lng: 18.246884 },
    { lat: 48.473027, lng: 18.247069 },
    { lat: 48.473137, lng: 18.247206 },
    { lat: 48.473308, lng: 18.247407 },
    { lat: 48.473521, lng: 18.247659 },
    { lat: 48.473656, lng: 18.247825 },
    { lat: 48.473813, lng: 18.248014 },
    { lat: 48.473885, lng: 18.2481 },
    { lat: 48.474078, lng: 18.248318 },
    { lat: 48.474159, lng: 18.248574 },
    { lat: 48.474296, lng: 18.248934 },
    { lat: 48.47435, lng: 18.249184 },
    { lat: 48.474403, lng: 18.249388 },
    { lat: 48.474461, lng: 18.249645 },
    { lat: 48.474502, lng: 18.249819 },
    { lat: 48.47453, lng: 18.249883 },
    { lat: 48.474627, lng: 18.250174 },
    { lat: 48.47466, lng: 18.250255 },
    { lat: 48.474748, lng: 18.250493 },
    { lat: 48.4748621, lng: 18.2503608 },
    { lat: 48.4755724, lng: 18.249524 },
    { lat: 48.4770335, lng: 18.2485781 },
    { lat: 48.4775076, lng: 18.2481709 },
    { lat: 48.4780782, lng: 18.2464524 },
    { lat: 48.4785577, lng: 18.2460982 },
    { lat: 48.479488, lng: 18.2450616 },
    { lat: 48.4803966, lng: 18.243042 },
    { lat: 48.4805124, lng: 18.2426247 },
    { lat: 48.4812952, lng: 18.2410872 },
    { lat: 48.4825831, lng: 18.2388136 },
    { lat: 48.4835924, lng: 18.2371517 },
    { lat: 48.4859786, lng: 18.2334559 },
    { lat: 48.4856099, lng: 18.2328124 },
    { lat: 48.4854421, lng: 18.2326785 },
    { lat: 48.4853479, lng: 18.2324972 },
    { lat: 48.4853593, lng: 18.2322624 },
    { lat: 48.4856937, lng: 18.2319137 },
    { lat: 48.4859424, lng: 18.2317658 },
    { lat: 48.486335, lng: 18.2312526 },
    { lat: 48.4868466, lng: 18.2300631 },
    { lat: 48.4873422, lng: 18.2292029 },
    { lat: 48.487842, lng: 18.2278495 },
    { lat: 48.4879102, lng: 18.2273501 },
    { lat: 48.4877921, lng: 18.2269045 },
    { lat: 48.4874947, lng: 18.2264253 },
    { lat: 48.4872302, lng: 18.2261396 },
    { lat: 48.4872137, lng: 18.2259518 },
    { lat: 48.4877198, lng: 18.2241401 },
    { lat: 48.4881382, lng: 18.2230746 },
    { lat: 48.4883599, lng: 18.2226331 },
    { lat: 48.4890326, lng: 18.222247 },
    { lat: 48.4900957, lng: 18.2209367 },
    { lat: 48.4905277, lng: 18.2205229 },
    { lat: 48.4908644, lng: 18.2198807 },
    { lat: 48.4915131, lng: 18.2189957 },
    { lat: 48.4915706, lng: 18.2187018 },
    { lat: 48.491672, lng: 18.2185246 },
    { lat: 48.4920876, lng: 18.2180584 },
    { lat: 48.4926113, lng: 18.2171798 },
    { lat: 48.492903, lng: 18.2171133 },
    { lat: 48.4930352, lng: 18.2169779 },
    { lat: 48.4936499, lng: 18.2161094 },
    { lat: 48.4939793, lng: 18.2157576 },
    { lat: 48.4943582, lng: 18.2148529 },
    { lat: 48.4945696, lng: 18.2144675 },
    { lat: 48.4953072, lng: 18.213463 },
    { lat: 48.4957111, lng: 18.2126396 },
    { lat: 48.4960082, lng: 18.2121946 },
    { lat: 48.4962669, lng: 18.2118669 },
    { lat: 48.4972618, lng: 18.2109992 },
    { lat: 48.4990155, lng: 18.2089736 },
    { lat: 48.4990983, lng: 18.2088779 },
    { lat: 48.4992778, lng: 18.2082521 },
    { lat: 48.4993206, lng: 18.2072357 },
    { lat: 48.4992259, lng: 18.2065326 },
    { lat: 48.4988656, lng: 18.2048825 },
    { lat: 48.4987487, lng: 18.2038564 },
    { lat: 48.4986773, lng: 18.2036935 },
    { lat: 48.4985887, lng: 18.2027438 },
    { lat: 48.4983198, lng: 18.201703 },
    { lat: 48.4982717, lng: 18.2010461 },
    { lat: 48.4984139, lng: 18.1995627 },
    { lat: 48.4984598, lng: 18.1981303 },
    { lat: 48.4986144, lng: 18.1967616 },
    { lat: 48.4988481, lng: 18.1956917 },
    { lat: 48.4988932, lng: 18.1952044 },
    { lat: 48.4992179, lng: 18.194398 },
    { lat: 48.4994338, lng: 18.1939526 },
    { lat: 48.4998288, lng: 18.1931375 },
    { lat: 48.500049, lng: 18.1924956 },
    { lat: 48.5004637, lng: 18.1918823 },
    { lat: 48.5006105, lng: 18.191529 },
    { lat: 48.5009147, lng: 18.1904879 },
    { lat: 48.5008488, lng: 18.1904414 },
    { lat: 48.5009662, lng: 18.1896421 },
    { lat: 48.5012919, lng: 18.188073 },
    { lat: 48.5016736, lng: 18.1867552 },
    { lat: 48.501948, lng: 18.1851803 },
    { lat: 48.50213, lng: 18.183741 },
    { lat: 48.5024999, lng: 18.1823528 },
    { lat: 48.5038583, lng: 18.1777522 },
    { lat: 48.5050601, lng: 18.1739174 },
    { lat: 48.5068233, lng: 18.1680335 },
    { lat: 48.5078793, lng: 18.1647031 },
    { lat: 48.5082914, lng: 18.1629763 },
    { lat: 48.5083535, lng: 18.1625709 },
    { lat: 48.5083338, lng: 18.162424 },
    { lat: 48.5088166, lng: 18.160691 },
    { lat: 48.509046, lng: 18.1596868 },
    { lat: 48.5099213, lng: 18.1554865 },
    { lat: 48.5106492, lng: 18.1534129 },
    { lat: 48.5110395, lng: 18.1535654 },
    { lat: 48.5113233, lng: 18.1535945 },
    { lat: 48.5116786, lng: 18.1533769 },
    { lat: 48.5118133, lng: 18.1534201 },
    { lat: 48.5117992, lng: 18.1530834 },
    { lat: 48.5118178, lng: 18.152725 },
    { lat: 48.5117397, lng: 18.1520203 },
    { lat: 48.5114044, lng: 18.1509924 },
    { lat: 48.5113295, lng: 18.1505578 },
    { lat: 48.5113644, lng: 18.1500524 },
    { lat: 48.5115635, lng: 18.1495354 },
    { lat: 48.5122143, lng: 18.1483665 },
    { lat: 48.5127673, lng: 18.1479782 },
    { lat: 48.5129059, lng: 18.1479738 },
  ],
  Solčany: [
    { lat: 48.537728, lng: 18.180557 },
    { lat: 48.5377026, lng: 18.1806454 },
    { lat: 48.5374781, lng: 18.1809587 },
    { lat: 48.5372009, lng: 18.1810841 },
    { lat: 48.536963, lng: 18.1810267 },
    { lat: 48.5367802, lng: 18.1808513 },
    { lat: 48.5366231, lng: 18.180576 },
    { lat: 48.5363683, lng: 18.1803659 },
    { lat: 48.5361773, lng: 18.1803792 },
    { lat: 48.5359711, lng: 18.1804807 },
    { lat: 48.5358508, lng: 18.1806457 },
    { lat: 48.5354993, lng: 18.1813182 },
    { lat: 48.5352307, lng: 18.1816322 },
    { lat: 48.5351857, lng: 18.1820437 },
    { lat: 48.535799, lng: 18.1823809 },
    { lat: 48.5355615, lng: 18.1826296 },
    { lat: 48.5333695, lng: 18.1857756 },
    { lat: 48.5312508, lng: 18.1890845 },
    { lat: 48.5312062, lng: 18.1893851 },
    { lat: 48.5312506, lng: 18.1895313 },
    { lat: 48.5311931, lng: 18.1896086 },
    { lat: 48.5314995, lng: 18.1902816 },
    { lat: 48.5315125, lng: 18.1903874 },
    { lat: 48.5314613, lng: 18.1905202 },
    { lat: 48.5312215, lng: 18.190725 },
    { lat: 48.5310221, lng: 18.19067 },
    { lat: 48.5307984, lng: 18.190392 },
    { lat: 48.5307516, lng: 18.1904224 },
    { lat: 48.5306887, lng: 18.190782 },
    { lat: 48.5304534, lng: 18.1910806 },
    { lat: 48.5300155, lng: 18.1914125 },
    { lat: 48.5298848, lng: 18.1916503 },
    { lat: 48.5297916, lng: 18.1920869 },
    { lat: 48.5297026, lng: 18.1921883 },
    { lat: 48.5295394, lng: 18.1922154 },
    { lat: 48.5293699, lng: 18.1925242 },
    { lat: 48.5293903, lng: 18.1930527 },
    { lat: 48.529198, lng: 18.1933385 },
    { lat: 48.5286662, lng: 18.1936253 },
    { lat: 48.5283231, lng: 18.1940138 },
    { lat: 48.5280818, lng: 18.1940771 },
    { lat: 48.5279555, lng: 18.1942734 },
    { lat: 48.5279961, lng: 18.1945255 },
    { lat: 48.5279672, lng: 18.194708 },
    { lat: 48.5278937, lng: 18.1948034 },
    { lat: 48.527586, lng: 18.1948687 },
    { lat: 48.5275132, lng: 18.194958 },
    { lat: 48.5273881, lng: 18.1949017 },
    { lat: 48.5273922, lng: 18.1945021 },
    { lat: 48.5272049, lng: 18.194506 },
    { lat: 48.5270698, lng: 18.1945849 },
    { lat: 48.5269735, lng: 18.1947161 },
    { lat: 48.5269055, lng: 18.1949468 },
    { lat: 48.5269833, lng: 18.1949629 },
    { lat: 48.5269923, lng: 18.1950621 },
    { lat: 48.5268993, lng: 18.195211 },
    { lat: 48.526825, lng: 18.1951538 },
    { lat: 48.5266842, lng: 18.1955325 },
    { lat: 48.5267055, lng: 18.1957259 },
    { lat: 48.5265947, lng: 18.1959515 },
    { lat: 48.5265124, lng: 18.1959356 },
    { lat: 48.5264312, lng: 18.196037 },
    { lat: 48.5263709, lng: 18.1962613 },
    { lat: 48.5264027, lng: 18.1964017 },
    { lat: 48.526545, lng: 18.1966341 },
    { lat: 48.5265035, lng: 18.1968171 },
    { lat: 48.5262788, lng: 18.1969918 },
    { lat: 48.5262193, lng: 18.1972103 },
    { lat: 48.526138, lng: 18.1973061 },
    { lat: 48.5260669, lng: 18.1977365 },
    { lat: 48.5259898, lng: 18.197826 },
    { lat: 48.5259158, lng: 18.1977979 },
    { lat: 48.5258764, lng: 18.197681 },
    { lat: 48.5256309, lng: 18.1976508 },
    { lat: 48.5254364, lng: 18.1977016 },
    { lat: 48.5252854, lng: 18.1978634 },
    { lat: 48.5252946, lng: 18.1980804 },
    { lat: 48.5252307, lng: 18.1982639 },
    { lat: 48.525044, lng: 18.1982734 },
    { lat: 48.5248749, lng: 18.1981592 },
    { lat: 48.5245088, lng: 18.1981135 },
    { lat: 48.5239741, lng: 18.1984882 },
    { lat: 48.5237164, lng: 18.1988694 },
    { lat: 48.5233894, lng: 18.198952 },
    { lat: 48.5232116, lng: 18.1990789 },
    { lat: 48.5232247, lng: 18.1992078 },
    { lat: 48.522952, lng: 18.1996831 },
    { lat: 48.5224998, lng: 18.1997095 },
    { lat: 48.5220701, lng: 18.2000648 },
    { lat: 48.521982, lng: 18.2001603 },
    { lat: 48.521829, lng: 18.2005982 },
    { lat: 48.5216697, lng: 18.2005956 },
    { lat: 48.5215584, lng: 18.2007621 },
    { lat: 48.5216017, lng: 18.2008846 },
    { lat: 48.5213935, lng: 18.2011649 },
    { lat: 48.5208062, lng: 18.2016874 },
    { lat: 48.5206216, lng: 18.2021216 },
    { lat: 48.5201053, lng: 18.2026042 },
    { lat: 48.5196424, lng: 18.2034379 },
    { lat: 48.5196871, lng: 18.2037249 },
    { lat: 48.5195641, lng: 18.2040966 },
    { lat: 48.5192246, lng: 18.2041189 },
    { lat: 48.5189247, lng: 18.2047273 },
    { lat: 48.5186674, lng: 18.2046894 },
    { lat: 48.5184918, lng: 18.2048152 },
    { lat: 48.5183496, lng: 18.2052278 },
    { lat: 48.5180257, lng: 18.2057315 },
    { lat: 48.517647, lng: 18.2055656 },
    { lat: 48.5175073, lng: 18.2057433 },
    { lat: 48.5173552, lng: 18.2064505 },
    { lat: 48.5171962, lng: 18.2065517 },
    { lat: 48.5169262, lng: 18.2064497 },
    { lat: 48.5168074, lng: 18.2067976 },
    { lat: 48.5167474, lng: 18.2072037 },
    { lat: 48.5165701, lng: 18.2075938 },
    { lat: 48.5163367, lng: 18.2077137 },
    { lat: 48.5162284, lng: 18.2078681 },
    { lat: 48.5162111, lng: 18.2082732 },
    { lat: 48.5160633, lng: 18.2089211 },
    { lat: 48.5159626, lng: 18.2091104 },
    { lat: 48.5158694, lng: 18.2091589 },
    { lat: 48.5157098, lng: 18.2090901 },
    { lat: 48.5155432, lng: 18.2092919 },
    { lat: 48.5153993, lng: 18.2101275 },
    { lat: 48.515318, lng: 18.210299 },
    { lat: 48.5151438, lng: 18.210489 },
    { lat: 48.5149783, lng: 18.2108669 },
    { lat: 48.5146448, lng: 18.2110706 },
    { lat: 48.5143845, lng: 18.2121545 },
    { lat: 48.5140736, lng: 18.212282 },
    { lat: 48.5139657, lng: 18.2123886 },
    { lat: 48.5138996, lng: 18.2125538 },
    { lat: 48.5138894, lng: 18.2128655 },
    { lat: 48.5138079, lng: 18.2129192 },
    { lat: 48.5137381, lng: 18.2128614 },
    { lat: 48.5136605, lng: 18.2128858 },
    { lat: 48.5134157, lng: 18.2130651 },
    { lat: 48.5132793, lng: 18.2130082 },
    { lat: 48.5131039, lng: 18.213157 },
    { lat: 48.5129172, lng: 18.2132238 },
    { lat: 48.5128447, lng: 18.2135948 },
    { lat: 48.5128693, lng: 18.2137475 },
    { lat: 48.5127698, lng: 18.2139772 },
    { lat: 48.5126571, lng: 18.21402 },
    { lat: 48.5125298, lng: 18.2144328 },
    { lat: 48.5124375, lng: 18.2144982 },
    { lat: 48.5124297, lng: 18.2146747 },
    { lat: 48.5123034, lng: 18.2149698 },
    { lat: 48.5118699, lng: 18.2156332 },
    { lat: 48.5117475, lng: 18.2159751 },
    { lat: 48.5115713, lng: 18.215954 },
    { lat: 48.5114008, lng: 18.2161086 },
    { lat: 48.5110991, lng: 18.2165114 },
    { lat: 48.5108585, lng: 18.2167025 },
    { lat: 48.5108363, lng: 18.216867 },
    { lat: 48.5105571, lng: 18.2170935 },
    { lat: 48.5107031, lng: 18.217573 },
    { lat: 48.5106144, lng: 18.2177155 },
    { lat: 48.5104246, lng: 18.2178115 },
    { lat: 48.510312, lng: 18.2180892 },
    { lat: 48.5100682, lng: 18.2182448 },
    { lat: 48.5099185, lng: 18.2186223 },
    { lat: 48.5097477, lng: 18.2187948 },
    { lat: 48.509776, lng: 18.2190879 },
    { lat: 48.509671, lng: 18.2191656 },
    { lat: 48.5096717, lng: 18.2192773 },
    { lat: 48.5095679, lng: 18.2194901 },
    { lat: 48.5095885, lng: 18.2197127 },
    { lat: 48.509415, lng: 18.2201086 },
    { lat: 48.5092406, lng: 18.2202748 },
    { lat: 48.5092378, lng: 18.2204805 },
    { lat: 48.5091757, lng: 18.2206167 },
    { lat: 48.5091019, lng: 18.2206704 },
    { lat: 48.5090008, lng: 18.2206185 },
    { lat: 48.5088897, lng: 18.2208607 },
    { lat: 48.5084828, lng: 18.2213999 },
    { lat: 48.5082887, lng: 18.2215782 },
    { lat: 48.508259, lng: 18.2217842 },
    { lat: 48.5080832, lng: 18.2218274 },
    { lat: 48.5081275, lng: 18.2220092 },
    { lat: 48.5079418, lng: 18.2223693 },
    { lat: 48.5076972, lng: 18.2224549 },
    { lat: 48.5074903, lng: 18.2223517 },
    { lat: 48.5072969, lng: 18.2226768 },
    { lat: 48.5069865, lng: 18.2228924 },
    { lat: 48.506898, lng: 18.2230758 },
    { lat: 48.5068179, lng: 18.2234702 },
    { lat: 48.5067363, lng: 18.2235246 },
    { lat: 48.5066544, lng: 18.223473 },
    { lat: 48.5063165, lng: 18.2236775 },
    { lat: 48.5061368, lng: 18.2236743 },
    { lat: 48.5057566, lng: 18.2233623 },
    { lat: 48.5055527, lng: 18.223718 },
    { lat: 48.5054631, lng: 18.2237895 },
    { lat: 48.505217, lng: 18.2242987 },
    { lat: 48.5050777, lng: 18.2244474 },
    { lat: 48.5049525, lng: 18.2244082 },
    { lat: 48.5049418, lng: 18.2245377 },
    { lat: 48.5047684, lng: 18.2248162 },
    { lat: 48.5045631, lng: 18.225013 },
    { lat: 48.5044073, lng: 18.225033 },
    { lat: 48.5042813, lng: 18.2249234 },
    { lat: 48.5041572, lng: 18.2249602 },
    { lat: 48.5036204, lng: 18.2252031 },
    { lat: 48.5034629, lng: 18.2254286 },
    { lat: 48.5030898, lng: 18.2256099 },
    { lat: 48.5028902, lng: 18.2255719 },
    { lat: 48.5026619, lng: 18.2257632 },
    { lat: 48.5020083, lng: 18.2260547 },
    { lat: 48.5018443, lng: 18.2259864 },
    { lat: 48.5015451, lng: 18.2264167 },
    { lat: 48.5014288, lng: 18.2267877 },
    { lat: 48.5012803, lng: 18.2276581 },
    { lat: 48.5009957, lng: 18.2278895 },
    { lat: 48.500911, lng: 18.2282011 },
    { lat: 48.5008781, lng: 18.2287473 },
    { lat: 48.5007307, lng: 18.2290305 },
    { lat: 48.5007316, lng: 18.229436 },
    { lat: 48.5006041, lng: 18.2300069 },
    { lat: 48.5005746, lng: 18.2306708 },
    { lat: 48.500548, lng: 18.2308002 },
    { lat: 48.5003304, lng: 18.2310957 },
    { lat: 48.5004129, lng: 18.2314119 },
    { lat: 48.5004179, lng: 18.2316177 },
    { lat: 48.5003012, lng: 18.2317065 },
    { lat: 48.5002744, lng: 18.2318477 },
    { lat: 48.5002912, lng: 18.2323589 },
    { lat: 48.5000856, lng: 18.2326479 },
    { lat: 48.5002344, lng: 18.2329583 },
    { lat: 48.5001575, lng: 18.2335052 },
    { lat: 48.5000107, lng: 18.2339175 },
    { lat: 48.4998706, lng: 18.23403 },
    { lat: 48.4998524, lng: 18.2341651 },
    { lat: 48.4998802, lng: 18.2343529 },
    { lat: 48.499939, lng: 18.2344349 },
    { lat: 48.4999623, lng: 18.234746 },
    { lat: 48.4999247, lng: 18.2349928 },
    { lat: 48.4998815, lng: 18.2351461 },
    { lat: 48.4997426, lng: 18.2353172 },
    { lat: 48.4997733, lng: 18.2353818 },
    { lat: 48.4997497, lng: 18.235523 },
    { lat: 48.4998238, lng: 18.2355165 },
    { lat: 48.4997395, lng: 18.2356577 },
    { lat: 48.4995837, lng: 18.2357527 },
    { lat: 48.4994262, lng: 18.236547 },
    { lat: 48.4993595, lng: 18.2366653 },
    { lat: 48.4992037, lng: 18.2366834 },
    { lat: 48.4991109, lng: 18.2365784 },
    { lat: 48.4990282, lng: 18.2365909 },
    { lat: 48.4988924, lng: 18.2367623 },
    { lat: 48.4988226, lng: 18.2367451 },
    { lat: 48.49871, lng: 18.2368689 },
    { lat: 48.4986314, lng: 18.2368165 },
    { lat: 48.4984876, lng: 18.2369231 },
    { lat: 48.4984761, lng: 18.2369997 },
    { lat: 48.4983137, lng: 18.2371766 },
    { lat: 48.498177, lng: 18.2372129 },
    { lat: 48.4979978, lng: 18.2370202 },
    { lat: 48.4976398, lng: 18.2372927 },
    { lat: 48.4975739, lng: 18.2372639 },
    { lat: 48.4974843, lng: 18.237335 },
    { lat: 48.4974216, lng: 18.2372058 },
    { lat: 48.4972892, lng: 18.2372597 },
    { lat: 48.4971376, lng: 18.2374484 },
    { lat: 48.4969394, lng: 18.2374553 },
    { lat: 48.4968879, lng: 18.237362 },
    { lat: 48.4968531, lng: 18.2374267 },
    { lat: 48.4964786, lng: 18.2373295 },
    { lat: 48.4961482, lng: 18.2376076 },
    { lat: 48.4959883, lng: 18.2375733 },
    { lat: 48.4958515, lng: 18.2373331 },
    { lat: 48.4957462, lng: 18.2373689 },
    { lat: 48.4955412, lng: 18.2375702 },
    { lat: 48.4954004, lng: 18.2375771 },
    { lat: 48.4951522, lng: 18.2378133 },
    { lat: 48.4950042, lng: 18.2380435 },
    { lat: 48.4945068, lng: 18.2383991 },
    { lat: 48.4943167, lng: 18.2386469 },
    { lat: 48.4941139, lng: 18.2387306 },
    { lat: 48.4939895, lng: 18.2388659 },
    { lat: 48.4938421, lng: 18.238873 },
    { lat: 48.4933027, lng: 18.2391846 },
    { lat: 48.4927363, lng: 18.2397556 },
    { lat: 48.4922512, lng: 18.2400901 },
    { lat: 48.4914963, lng: 18.2404052 },
    { lat: 48.4910466, lng: 18.2408457 },
    { lat: 48.4900526, lng: 18.2421857 },
    { lat: 48.489716, lng: 18.2438878 },
    { lat: 48.4893958, lng: 18.2445437 },
    { lat: 48.4888426, lng: 18.2454436 },
    { lat: 48.4877816, lng: 18.2466371 },
    { lat: 48.487277, lng: 18.2470078 },
    { lat: 48.4870187, lng: 18.2475222 },
    { lat: 48.4863067, lng: 18.248506 },
    { lat: 48.4853757, lng: 18.2494282 },
    { lat: 48.4851753, lng: 18.2496889 },
    { lat: 48.4844103, lng: 18.2509495 },
    { lat: 48.4837553, lng: 18.2523188 },
    { lat: 48.4829734, lng: 18.2530696 },
    { lat: 48.4826441, lng: 18.2539176 },
    { lat: 48.4823642, lng: 18.2543718 },
    { lat: 48.4821736, lng: 18.2548254 },
    { lat: 48.4818739, lng: 18.2552444 },
    { lat: 48.4815278, lng: 18.2555048 },
    { lat: 48.48137, lng: 18.255764 },
    { lat: 48.482546, lng: 18.257727 },
    { lat: 48.483995, lng: 18.259418 },
    { lat: 48.485283, lng: 18.26141 },
    { lat: 48.486232, lng: 18.262016 },
    { lat: 48.486275, lng: 18.262045 },
    { lat: 48.48628, lng: 18.263744 },
    { lat: 48.486499, lng: 18.266447 },
    { lat: 48.486994, lng: 18.269361 },
    { lat: 48.487579, lng: 18.272056 },
    { lat: 48.487703, lng: 18.274572 },
    { lat: 48.487703, lng: 18.274596 },
    { lat: 48.4939005, lng: 18.2674392 },
    { lat: 48.4978994, lng: 18.262704 },
    { lat: 48.5024859, lng: 18.2574332 },
    { lat: 48.5108259, lng: 18.2478477 },
    { lat: 48.5194251, lng: 18.2503474 },
    { lat: 48.5259002, lng: 18.2459063 },
    { lat: 48.5299708, lng: 18.2438878 },
    { lat: 48.5302708, lng: 18.2438858 },
    { lat: 48.5303598, lng: 18.2437268 },
    { lat: 48.5305621, lng: 18.2435727 },
    { lat: 48.5310095, lng: 18.2433875 },
    { lat: 48.5312202, lng: 18.2434213 },
    { lat: 48.5313767, lng: 18.2435907 },
    { lat: 48.5317509, lng: 18.2434064 },
    { lat: 48.5322481, lng: 18.2423748 },
    { lat: 48.5323536, lng: 18.2419968 },
    { lat: 48.5332671, lng: 18.2387216 },
    { lat: 48.534641, lng: 18.2362546 },
    { lat: 48.5358597, lng: 18.2332651 },
    { lat: 48.5362486, lng: 18.2336346 },
    { lat: 48.5366973, lng: 18.2327814 },
    { lat: 48.536841, lng: 18.2326317 },
    { lat: 48.537319, lng: 18.2318185 },
    { lat: 48.5378556, lng: 18.2310433 },
    { lat: 48.5379487, lng: 18.2305437 },
    { lat: 48.5380509, lng: 18.2303751 },
    { lat: 48.5381659, lng: 18.2302957 },
    { lat: 48.5383449, lng: 18.229781 },
    { lat: 48.5385865, lng: 18.2296025 },
    { lat: 48.5386425, lng: 18.2294983 },
    { lat: 48.5387415, lng: 18.2291034 },
    { lat: 48.53878, lng: 18.2286333 },
    { lat: 48.5388964, lng: 18.2284405 },
    { lat: 48.5389001, lng: 18.2282506 },
    { lat: 48.5391872, lng: 18.2278849 },
    { lat: 48.5392213, lng: 18.22777 },
    { lat: 48.5393198, lng: 18.2266787 },
    { lat: 48.5394283, lng: 18.2266193 },
    { lat: 48.5395222, lng: 18.2264269 },
    { lat: 48.5396732, lng: 18.2264834 },
    { lat: 48.5397969, lng: 18.2263604 },
    { lat: 48.5399615, lng: 18.2260235 },
    { lat: 48.5401397, lng: 18.225981 },
    { lat: 48.5401198, lng: 18.2256546 },
    { lat: 48.5401841, lng: 18.2255761 },
    { lat: 48.5403823, lng: 18.2256058 },
    { lat: 48.5410506, lng: 18.224966 },
    { lat: 48.5413988, lng: 18.2248572 },
    { lat: 48.5415392, lng: 18.2244953 },
    { lat: 48.5415457, lng: 18.2243527 },
    { lat: 48.5416552, lng: 18.2242173 },
    { lat: 48.5416892, lng: 18.2242574 },
    { lat: 48.5417993, lng: 18.224169 },
    { lat: 48.542054, lng: 18.2238502 },
    { lat: 48.5421644, lng: 18.2239643 },
    { lat: 48.5422218, lng: 18.2239419 },
    { lat: 48.5423716, lng: 18.2235082 },
    { lat: 48.5433334, lng: 18.2224957 },
    { lat: 48.5435484, lng: 18.2220321 },
    { lat: 48.5435985, lng: 18.2218262 },
    { lat: 48.5436147, lng: 18.2209698 },
    { lat: 48.5437483, lng: 18.2204477 },
    { lat: 48.5461548, lng: 18.2160892 },
    { lat: 48.5465049, lng: 18.2157629 },
    { lat: 48.5460116, lng: 18.2149907 },
    { lat: 48.5451958, lng: 18.2138971 },
    { lat: 48.5446068, lng: 18.2132294 },
    { lat: 48.5453637, lng: 18.2117178 },
    { lat: 48.545728, lng: 18.211536 },
    { lat: 48.5459766, lng: 18.2107211 },
    { lat: 48.5467663, lng: 18.209443 },
    { lat: 48.546999, lng: 18.2089179 },
    { lat: 48.5474193, lng: 18.2085595 },
    { lat: 48.5475884, lng: 18.2084939 },
    { lat: 48.5477266, lng: 18.2082899 },
    { lat: 48.5481202, lng: 18.208247 },
    { lat: 48.5484111, lng: 18.2081053 },
    { lat: 48.5491387, lng: 18.208023 },
    { lat: 48.550255, lng: 18.2083589 },
    { lat: 48.5506188, lng: 18.2081528 },
    { lat: 48.5509479, lng: 18.2077732 },
    { lat: 48.5516535, lng: 18.2077048 },
    { lat: 48.5520366, lng: 18.2077341 },
    { lat: 48.5523778, lng: 18.2076393 },
    { lat: 48.5525278, lng: 18.2075217 },
    { lat: 48.552846, lng: 18.207109 },
    { lat: 48.5534806, lng: 18.2065508 },
    { lat: 48.5536767, lng: 18.206174 },
    { lat: 48.5537402, lng: 18.2059267 },
    { lat: 48.553689, lng: 18.2057026 },
    { lat: 48.5537844, lng: 18.2054019 },
    { lat: 48.5537312, lng: 18.2051792 },
    { lat: 48.5535024, lng: 18.2047557 },
    { lat: 48.5528756, lng: 18.2039861 },
    { lat: 48.5528349, lng: 18.2038461 },
    { lat: 48.552909, lng: 18.2035632 },
    { lat: 48.5532916, lng: 18.2030191 },
    { lat: 48.5539017, lng: 18.2028552 },
    { lat: 48.5548492, lng: 18.2023016 },
    { lat: 48.5553818, lng: 18.2011836 },
    { lat: 48.5548741, lng: 18.2006965 },
    { lat: 48.5544689, lng: 18.2005387 },
    { lat: 48.5538071, lng: 18.20051 },
    { lat: 48.5535268, lng: 18.2002514 },
    { lat: 48.5533364, lng: 18.1998802 },
    { lat: 48.553226, lng: 18.1998284 },
    { lat: 48.5529441, lng: 18.1998515 },
    { lat: 48.552909, lng: 18.1993018 },
    { lat: 48.5525896, lng: 18.1980069 },
    { lat: 48.5518666, lng: 18.1983089 },
    { lat: 48.551783, lng: 18.1980324 },
    { lat: 48.5517864, lng: 18.1978977 },
    { lat: 48.5518758, lng: 18.1977261 },
    { lat: 48.5519285, lng: 18.1974638 },
    { lat: 48.5517527, lng: 18.1962167 },
    { lat: 48.5516214, lng: 18.1959575 },
    { lat: 48.5513044, lng: 18.1956073 },
    { lat: 48.5505112, lng: 18.1949625 },
    { lat: 48.550426, lng: 18.194719 },
    { lat: 48.5502827, lng: 18.193742 },
    { lat: 48.5502097, lng: 18.1934969 },
    { lat: 48.5500023, lng: 18.1933606 },
    { lat: 48.5497207, lng: 18.1935467 },
    { lat: 48.5494224, lng: 18.1926594 },
    { lat: 48.5492666, lng: 18.192484 },
    { lat: 48.5491759, lng: 18.1922818 },
    { lat: 48.5491086, lng: 18.1917432 },
    { lat: 48.548955, lng: 18.1912697 },
    { lat: 48.5492124, lng: 18.1905479 },
    { lat: 48.5492464, lng: 18.1901053 },
    { lat: 48.5490621, lng: 18.1897847 },
    { lat: 48.5486294, lng: 18.189797 },
    { lat: 48.5485336, lng: 18.1897184 },
    { lat: 48.5484481, lng: 18.1890415 },
    { lat: 48.5482964, lng: 18.1888185 },
    { lat: 48.5480844, lng: 18.1887213 },
    { lat: 48.5471652, lng: 18.1892208 },
    { lat: 48.5466678, lng: 18.1892425 },
    { lat: 48.5466578, lng: 18.1895573 },
    { lat: 48.5464445, lng: 18.1898712 },
    { lat: 48.5461448, lng: 18.190012 },
    { lat: 48.5457508, lng: 18.1899711 },
    { lat: 48.5451748, lng: 18.1901599 },
    { lat: 48.5446782, lng: 18.1899625 },
    { lat: 48.5436853, lng: 18.1893783 },
    { lat: 48.5444439, lng: 18.1882899 },
    { lat: 48.5444817, lng: 18.1881421 },
    { lat: 48.5442357, lng: 18.1873229 },
    { lat: 48.5439867, lng: 18.1869232 },
    { lat: 48.5434201, lng: 18.1864726 },
    { lat: 48.5429574, lng: 18.1862943 },
    { lat: 48.5427649, lng: 18.1860489 },
    { lat: 48.542757, lng: 18.1854247 },
    { lat: 48.542813, lng: 18.1848112 },
    { lat: 48.5428828, lng: 18.1845552 },
    { lat: 48.5431867, lng: 18.1843868 },
    { lat: 48.5432289, lng: 18.1838866 },
    { lat: 48.5431708, lng: 18.1836624 },
    { lat: 48.5429366, lng: 18.1832808 },
    { lat: 48.5420467, lng: 18.1821195 },
    { lat: 48.5424426, lng: 18.181413 },
    { lat: 48.5424906, lng: 18.1812496 },
    { lat: 48.5423668, lng: 18.1807488 },
    { lat: 48.5421524, lng: 18.1805138 },
    { lat: 48.5417865, lng: 18.1803328 },
    { lat: 48.5414249, lng: 18.1803447 },
    { lat: 48.5408228, lng: 18.1807852 },
    { lat: 48.5397861, lng: 18.1811795 },
    { lat: 48.5391857, lng: 18.1810012 },
    { lat: 48.5389805, lng: 18.1810456 },
    { lat: 48.5387996, lng: 18.1809205 },
    { lat: 48.5386131, lng: 18.1809539 },
    { lat: 48.5384123, lng: 18.1810921 },
    { lat: 48.5379022, lng: 18.1806413 },
    { lat: 48.537728, lng: 18.180557 },
  ],
  Rajčany: [
    { lat: 48.609355, lng: 18.256094 },
    { lat: 48.609446, lng: 18.255922 },
    { lat: 48.610006, lng: 18.254946 },
    { lat: 48.610767, lng: 18.253569 },
    { lat: 48.611381, lng: 18.252444 },
    { lat: 48.612029, lng: 18.251201 },
    { lat: 48.612726, lng: 18.250227 },
    { lat: 48.612851, lng: 18.250098 },
    { lat: 48.613627, lng: 18.249177 },
    { lat: 48.613975, lng: 18.248773 },
    { lat: 48.614128, lng: 18.248579 },
    { lat: 48.614218, lng: 18.248444 },
    { lat: 48.614288, lng: 18.248333 },
    { lat: 48.614403, lng: 18.248145 },
    { lat: 48.614476, lng: 18.247998 },
    { lat: 48.614535, lng: 18.247845 },
    { lat: 48.614619, lng: 18.247645 },
    { lat: 48.61476, lng: 18.24728 },
    { lat: 48.615577, lng: 18.245806 },
    { lat: 48.615658, lng: 18.245582 },
    { lat: 48.615733, lng: 18.245536 },
    { lat: 48.615803, lng: 18.245453 },
    { lat: 48.615936, lng: 18.245313 },
    { lat: 48.615979, lng: 18.245178 },
    { lat: 48.61598, lng: 18.245048 },
    { lat: 48.616047, lng: 18.244936 },
    { lat: 48.616524, lng: 18.244144 },
    { lat: 48.617755, lng: 18.24207 },
    { lat: 48.617811, lng: 18.241687 },
    { lat: 48.618073, lng: 18.241818 },
    { lat: 48.618472, lng: 18.241212 },
    { lat: 48.618885, lng: 18.240664 },
    { lat: 48.619009, lng: 18.240502 },
    { lat: 48.619078, lng: 18.240396 },
    { lat: 48.619117, lng: 18.240348 },
    { lat: 48.619574, lng: 18.239835 },
    { lat: 48.619803, lng: 18.239566 },
    { lat: 48.620062, lng: 18.239274 },
    { lat: 48.620368, lng: 18.238863 },
    { lat: 48.620484, lng: 18.23869 },
    { lat: 48.620689, lng: 18.23844 },
    { lat: 48.620848, lng: 18.238179 },
    { lat: 48.621154, lng: 18.237762 },
    { lat: 48.621605, lng: 18.237178 },
    { lat: 48.621967, lng: 18.236783 },
    { lat: 48.622199, lng: 18.236568 },
    { lat: 48.622718, lng: 18.235948 },
    { lat: 48.623496, lng: 18.235082 },
    { lat: 48.62431, lng: 18.234169 },
    { lat: 48.624883, lng: 18.233536 },
    { lat: 48.625146, lng: 18.233261 },
    { lat: 48.625406, lng: 18.232945 },
    { lat: 48.625743, lng: 18.232486 },
    { lat: 48.625906, lng: 18.232213 },
    { lat: 48.626419, lng: 18.231475 },
    { lat: 48.627331, lng: 18.230118 },
    { lat: 48.62841, lng: 18.228499 },
    { lat: 48.628908, lng: 18.227744 },
    { lat: 48.629305, lng: 18.227136 },
    { lat: 48.62929, lng: 18.227107 },
    { lat: 48.629025, lng: 18.226551 },
    { lat: 48.628788, lng: 18.225942 },
    { lat: 48.628605, lng: 18.226127 },
    { lat: 48.628423, lng: 18.225694 },
    { lat: 48.628276, lng: 18.225342 },
    { lat: 48.628205, lng: 18.22509 },
    { lat: 48.628184, lng: 18.224978 },
    { lat: 48.628144, lng: 18.224855 },
    { lat: 48.628088, lng: 18.224543 },
    { lat: 48.628058, lng: 18.224163 },
    { lat: 48.628037, lng: 18.223975 },
    { lat: 48.628028, lng: 18.223816 },
    { lat: 48.628015, lng: 18.223645 },
    { lat: 48.628015, lng: 18.223055 },
    { lat: 48.627998, lng: 18.222884 },
    { lat: 48.627961, lng: 18.222667 },
    { lat: 48.627929, lng: 18.22252 },
    { lat: 48.627904, lng: 18.222261 },
    { lat: 48.627928, lng: 18.221771 },
    { lat: 48.627955, lng: 18.221369 },
    { lat: 48.627969, lng: 18.220526 },
    { lat: 48.627965, lng: 18.220072 },
    { lat: 48.627984, lng: 18.219889 },
    { lat: 48.627997, lng: 18.219624 },
    { lat: 48.627998, lng: 18.219169 },
    { lat: 48.627984, lng: 18.218851 },
    { lat: 48.62798, lng: 18.218302 },
    { lat: 48.627975, lng: 18.217606 },
    { lat: 48.627993, lng: 18.217406 },
    { lat: 48.628022, lng: 18.217216 },
    { lat: 48.628095, lng: 18.216968 },
    { lat: 48.628229, lng: 18.21657 },
    { lat: 48.628371, lng: 18.216167 },
    { lat: 48.628619, lng: 18.215562 },
    { lat: 48.628738, lng: 18.215283 },
    { lat: 48.62892, lng: 18.214944 },
    { lat: 48.629163, lng: 18.214497 },
    { lat: 48.629625, lng: 18.213658 },
    { lat: 48.629854, lng: 18.213017 },
    { lat: 48.629858, lng: 18.213012 },
    { lat: 48.629852, lng: 18.212984 },
    { lat: 48.6290689, lng: 18.2119995 },
    { lat: 48.6284136, lng: 18.2115342 },
    { lat: 48.6277263, lng: 18.2108034 },
    { lat: 48.6270622, lng: 18.2099308 },
    { lat: 48.6247623, lng: 18.2073202 },
    { lat: 48.6245125, lng: 18.2069606 },
    { lat: 48.624298, lng: 18.2067836 },
    { lat: 48.62338, lng: 18.2063007 },
    { lat: 48.6230749, lng: 18.2060371 },
    { lat: 48.6228744, lng: 18.2066911 },
    { lat: 48.6227948, lng: 18.2072512 },
    { lat: 48.622787, lng: 18.2073696 },
    { lat: 48.6229399, lng: 18.2074583 },
    { lat: 48.622845, lng: 18.207806 },
    { lat: 48.6228836, lng: 18.2082425 },
    { lat: 48.6228476, lng: 18.208579 },
    { lat: 48.6227887, lng: 18.2088499 },
    { lat: 48.6225778, lng: 18.2088137 },
    { lat: 48.6224673, lng: 18.2093624 },
    { lat: 48.6222749, lng: 18.2098452 },
    { lat: 48.6223765, lng: 18.2100406 },
    { lat: 48.6222936, lng: 18.2103054 },
    { lat: 48.6220511, lng: 18.2102868 },
    { lat: 48.6219294, lng: 18.2106048 },
    { lat: 48.6193832, lng: 18.2092804 },
    { lat: 48.6110845, lng: 18.2223059 },
    { lat: 48.6035038, lng: 18.2343359 },
    { lat: 48.6043982, lng: 18.235019 },
    { lat: 48.6043988, lng: 18.235066 },
    { lat: 48.6043396, lng: 18.2350598 },
    { lat: 48.6043238, lng: 18.2352954 },
    { lat: 48.6041454, lng: 18.2358253 },
    { lat: 48.6037197, lng: 18.2364673 },
    { lat: 48.6035059, lng: 18.2369707 },
    { lat: 48.6030874, lng: 18.2377012 },
    { lat: 48.6031491, lng: 18.2377959 },
    { lat: 48.6028871, lng: 18.238163 },
    { lat: 48.6021806, lng: 18.2383977 },
    { lat: 48.6020508, lng: 18.2385968 },
    { lat: 48.5990944, lng: 18.2438124 },
    { lat: 48.59897, lng: 18.24403 },
    { lat: 48.599054, lng: 18.244125 },
    { lat: 48.600352, lng: 18.24702 },
    { lat: 48.601635, lng: 18.249621 },
    { lat: 48.602171, lng: 18.250735 },
    { lat: 48.60366, lng: 18.252737 },
    { lat: 48.603933, lng: 18.253071 },
    { lat: 48.604226, lng: 18.253395 },
    { lat: 48.604263, lng: 18.253444 },
    { lat: 48.604339, lng: 18.253538 },
    { lat: 48.60448, lng: 18.25372 },
    { lat: 48.604732, lng: 18.254176 },
    { lat: 48.606638, lng: 18.253275 },
    { lat: 48.606855, lng: 18.25361 },
    { lat: 48.60792, lng: 18.253013 },
    { lat: 48.60839, lng: 18.252526 },
    { lat: 48.608437, lng: 18.252476 },
    { lat: 48.608622, lng: 18.252571 },
    { lat: 48.608626, lng: 18.252573 },
    { lat: 48.608943, lng: 18.25363 },
    { lat: 48.609442, lng: 18.253949 },
    { lat: 48.609608, lng: 18.254382 },
    { lat: 48.609453, lng: 18.254588 },
    { lat: 48.609273, lng: 18.255322 },
    { lat: 48.609156, lng: 18.255464 },
    { lat: 48.609355, lng: 18.256094 },
  ],
  Koniarovce: [
    { lat: 48.436127, lng: 18.05062 },
    { lat: 48.436115, lng: 18.050635 },
    { lat: 48.435886, lng: 18.05138 },
    { lat: 48.43583, lng: 18.051666 },
    { lat: 48.435761, lng: 18.052539 },
    { lat: 48.435838, lng: 18.053816 },
    { lat: 48.435591, lng: 18.054623 },
    { lat: 48.435531, lng: 18.054678 },
    { lat: 48.434291, lng: 18.055838 },
    { lat: 48.433347, lng: 18.058169 },
    { lat: 48.433086, lng: 18.058815 },
    { lat: 48.432907, lng: 18.059087 },
    { lat: 48.432746, lng: 18.05943 },
    { lat: 48.432605, lng: 18.059807 },
    { lat: 48.432458, lng: 18.060094 },
    { lat: 48.432326, lng: 18.060414 },
    { lat: 48.432207, lng: 18.060995 },
    { lat: 48.43203, lng: 18.061383 },
    { lat: 48.431882, lng: 18.061731 },
    { lat: 48.431868, lng: 18.061741 },
    { lat: 48.431823, lng: 18.061817 },
    { lat: 48.431767, lng: 18.061912 },
    { lat: 48.431705, lng: 18.062009 },
    { lat: 48.431661, lng: 18.062076 },
    { lat: 48.431628, lng: 18.062136 },
    { lat: 48.431609, lng: 18.062161 },
    { lat: 48.431547, lng: 18.062285 },
    { lat: 48.431426, lng: 18.062514 },
    { lat: 48.431315, lng: 18.062715 },
    { lat: 48.431266, lng: 18.062783 },
    { lat: 48.431224, lng: 18.062838 },
    { lat: 48.431203, lng: 18.062865 },
    { lat: 48.431151, lng: 18.062935 },
    { lat: 48.431088, lng: 18.063002 },
    { lat: 48.431042, lng: 18.063055 },
    { lat: 48.430998, lng: 18.063104 },
    { lat: 48.430915, lng: 18.063194 },
    { lat: 48.430899, lng: 18.063214 },
    { lat: 48.430879, lng: 18.063237 },
    { lat: 48.430855, lng: 18.063262 },
    { lat: 48.430822, lng: 18.063295 },
    { lat: 48.430795, lng: 18.063331 },
    { lat: 48.430763, lng: 18.063364 },
    { lat: 48.430729, lng: 18.063401 },
    { lat: 48.430638, lng: 18.063684 },
    { lat: 48.430615, lng: 18.063749 },
    { lat: 48.430602, lng: 18.063803 },
    { lat: 48.430595, lng: 18.063853 },
    { lat: 48.430569, lng: 18.064047 },
    { lat: 48.430553, lng: 18.06455 },
    { lat: 48.430553, lng: 18.064673 },
    { lat: 48.430536, lng: 18.064761 },
    { lat: 48.430498, lng: 18.064959 },
    { lat: 48.430482, lng: 18.065058 },
    { lat: 48.430456, lng: 18.065182 },
    { lat: 48.430448, lng: 18.065212 },
    { lat: 48.430442, lng: 18.065244 },
    { lat: 48.430404, lng: 18.065401 },
    { lat: 48.430358, lng: 18.06559 },
    { lat: 48.43035, lng: 18.065651 },
    { lat: 48.430336, lng: 18.065733 },
    { lat: 48.430326, lng: 18.065783 },
    { lat: 48.430308, lng: 18.06586 },
    { lat: 48.430283, lng: 18.065919 },
    { lat: 48.43018, lng: 18.066178 },
    { lat: 48.430135, lng: 18.066285 },
    { lat: 48.430128, lng: 18.066322 },
    { lat: 48.430119, lng: 18.066348 },
    { lat: 48.430077, lng: 18.066476 },
    { lat: 48.43005, lng: 18.066598 },
    { lat: 48.430011, lng: 18.066786 },
    { lat: 48.430006, lng: 18.066812 },
    { lat: 48.429976, lng: 18.066962 },
    { lat: 48.429938, lng: 18.067151 },
    { lat: 48.42993, lng: 18.067193 },
    { lat: 48.429885, lng: 18.067582 },
    { lat: 48.429839, lng: 18.06786 },
    { lat: 48.429808, lng: 18.068056 },
    { lat: 48.429799, lng: 18.06813 },
    { lat: 48.429795, lng: 18.06817 },
    { lat: 48.429792, lng: 18.068236 },
    { lat: 48.42979, lng: 18.068304 },
    { lat: 48.429786, lng: 18.068375 },
    { lat: 48.429785, lng: 18.068386 },
    { lat: 48.429796, lng: 18.068507 },
    { lat: 48.429807, lng: 18.068628 },
    { lat: 48.429815, lng: 18.068747 },
    { lat: 48.429823, lng: 18.068866 },
    { lat: 48.429813, lng: 18.069015 },
    { lat: 48.429804, lng: 18.069163 },
    { lat: 48.429794, lng: 18.069265 },
    { lat: 48.429778, lng: 18.069382 },
    { lat: 48.429763, lng: 18.069479 },
    { lat: 48.429739, lng: 18.069644 },
    { lat: 48.42974, lng: 18.069709 },
    { lat: 48.429749, lng: 18.069974 },
    { lat: 48.429719, lng: 18.070658 },
    { lat: 48.429712, lng: 18.070878 },
    { lat: 48.429699, lng: 18.071138 },
    { lat: 48.42857, lng: 18.075313 },
    { lat: 48.427364, lng: 18.080811 },
    { lat: 48.426803, lng: 18.083333 },
    { lat: 48.426698, lng: 18.083846 },
    { lat: 48.425643, lng: 18.088192 },
    { lat: 48.425633, lng: 18.088241 },
    { lat: 48.42563, lng: 18.088249 },
    { lat: 48.425602, lng: 18.08823 },
    { lat: 48.425622, lng: 18.088327 },
    { lat: 48.425584, lng: 18.088427 },
    { lat: 48.425564, lng: 18.08848 },
    { lat: 48.42556, lng: 18.088493 },
    { lat: 48.425322, lng: 18.089392 },
    { lat: 48.425276, lng: 18.089566 },
    { lat: 48.425262, lng: 18.089619 },
    { lat: 48.425164, lng: 18.089969 },
    { lat: 48.425073, lng: 18.09032 },
    { lat: 48.425032, lng: 18.09048 },
    { lat: 48.42498, lng: 18.090671 },
    { lat: 48.424935, lng: 18.090843 },
    { lat: 48.42489, lng: 18.091018 },
    { lat: 48.424848, lng: 18.091176 },
    { lat: 48.4248, lng: 18.091335 },
    { lat: 48.424755, lng: 18.091379 },
    { lat: 48.424713, lng: 18.091424 },
    { lat: 48.424695, lng: 18.091438 },
    { lat: 48.424659, lng: 18.091495 },
    { lat: 48.424537, lng: 18.09219 },
    { lat: 48.424512, lng: 18.092312 },
    { lat: 48.424473, lng: 18.092504 },
    { lat: 48.424416, lng: 18.092771 },
    { lat: 48.424368, lng: 18.092987 },
    { lat: 48.424318, lng: 18.093234 },
    { lat: 48.42419, lng: 18.093841 },
    { lat: 48.424075, lng: 18.094078 },
    { lat: 48.424055, lng: 18.09412 },
    { lat: 48.424036, lng: 18.094159 },
    { lat: 48.423901, lng: 18.094439 },
    { lat: 48.423494, lng: 18.096069 },
    { lat: 48.42348, lng: 18.096131 },
    { lat: 48.423465, lng: 18.096188 },
    { lat: 48.423466, lng: 18.096207 },
    { lat: 48.423471, lng: 18.096275 },
    { lat: 48.423471, lng: 18.096302 },
    { lat: 48.423477, lng: 18.09638 },
    { lat: 48.423479, lng: 18.096407 },
    { lat: 48.423486, lng: 18.096497 },
    { lat: 48.423424, lng: 18.09667 },
    { lat: 48.422863, lng: 18.096485 },
    { lat: 48.422709, lng: 18.096444 },
    { lat: 48.422561, lng: 18.097079 },
    { lat: 48.422558, lng: 18.097105 },
    { lat: 48.422553, lng: 18.097159 },
    { lat: 48.422527, lng: 18.097419 },
    { lat: 48.422514, lng: 18.097772 },
    { lat: 48.42253, lng: 18.098037 },
    { lat: 48.422579, lng: 18.098429 },
    { lat: 48.422594, lng: 18.098565 },
    { lat: 48.422614, lng: 18.098754 },
    { lat: 48.42263, lng: 18.098881 },
    { lat: 48.422646, lng: 18.098896 },
    { lat: 48.422228, lng: 18.100873 },
    { lat: 48.422142, lng: 18.101201 },
    { lat: 48.421351, lng: 18.103724 },
    { lat: 48.421291, lng: 18.104027 },
    { lat: 48.421192, lng: 18.104668 },
    { lat: 48.420959, lng: 18.105955 },
    { lat: 48.420978, lng: 18.106349 },
    { lat: 48.421006, lng: 18.106688 },
    { lat: 48.421034, lng: 18.106959 },
    { lat: 48.421042, lng: 18.107198 },
    { lat: 48.421042, lng: 18.107209 },
    { lat: 48.421049, lng: 18.107403 },
    { lat: 48.421046, lng: 18.10744 },
    { lat: 48.421038, lng: 18.107533 },
    { lat: 48.420983, lng: 18.10787 },
    { lat: 48.420942, lng: 18.108102 },
    { lat: 48.420941, lng: 18.108114 },
    { lat: 48.420913, lng: 18.108286 },
    { lat: 48.420921, lng: 18.108363 },
    { lat: 48.420948, lng: 18.108606 },
    { lat: 48.421064, lng: 18.108711 },
    { lat: 48.421149, lng: 18.108879 },
    { lat: 48.421199, lng: 18.109025 },
    { lat: 48.421207, lng: 18.109179 },
    { lat: 48.421284, lng: 18.109367 },
    { lat: 48.42139, lng: 18.109472 },
    { lat: 48.421491, lng: 18.109518 },
    { lat: 48.421586, lng: 18.109552 },
    { lat: 48.421772, lng: 18.109602 },
    { lat: 48.421612, lng: 18.110289 },
    { lat: 48.421375, lng: 18.111195 },
    { lat: 48.421145, lng: 18.111606 },
    { lat: 48.420979, lng: 18.111746 },
    { lat: 48.420785, lng: 18.111943 },
    { lat: 48.420514, lng: 18.112097 },
    { lat: 48.420692, lng: 18.112493 },
    { lat: 48.420794, lng: 18.112345 },
    { lat: 48.420926, lng: 18.112432 },
    { lat: 48.421, lng: 18.112549 },
    { lat: 48.42104, lng: 18.112637 },
    { lat: 48.421191, lng: 18.113005 },
    { lat: 48.421323, lng: 18.113183 },
    { lat: 48.421487, lng: 18.113323 },
    { lat: 48.421641, lng: 18.113569 },
    { lat: 48.421716, lng: 18.11364 },
    { lat: 48.421788, lng: 18.113644 },
    { lat: 48.421916, lng: 18.113628 },
    { lat: 48.422031, lng: 18.113651 },
    { lat: 48.42212, lng: 18.11369 },
    { lat: 48.422255, lng: 18.113728 },
    { lat: 48.42234, lng: 18.113737 },
    { lat: 48.422459, lng: 18.11372 },
    { lat: 48.422726, lng: 18.11371 },
    { lat: 48.422828, lng: 18.113778 },
    { lat: 48.422881, lng: 18.113813 },
    { lat: 48.423172, lng: 18.113801 },
    { lat: 48.42328, lng: 18.11377 },
    { lat: 48.423289, lng: 18.113769 },
    { lat: 48.423539, lng: 18.113718 },
    { lat: 48.423799, lng: 18.113685 },
    { lat: 48.424099, lng: 18.113614 },
    { lat: 48.424247, lng: 18.113593 },
    { lat: 48.424629, lng: 18.113529 },
    { lat: 48.425256, lng: 18.11341 },
    { lat: 48.425408, lng: 18.113373 },
    { lat: 48.425533, lng: 18.113315 },
    { lat: 48.426328, lng: 18.112942 },
    { lat: 48.426617, lng: 18.112861 },
    { lat: 48.4268503, lng: 18.1128155 },
    { lat: 48.426928, lng: 18.112801 },
    { lat: 48.427077, lng: 18.112794 },
    { lat: 48.427528, lng: 18.112774 },
    { lat: 48.427653, lng: 18.112729 },
    { lat: 48.427739, lng: 18.112698 },
    { lat: 48.428003, lng: 18.112789 },
    { lat: 48.428357, lng: 18.112832 },
    { lat: 48.4283637, lng: 18.1127868 },
    { lat: 48.4295994, lng: 18.1076662 },
    { lat: 48.431091, lng: 18.102544 },
    { lat: 48.4313656, lng: 18.102298 },
    { lat: 48.4320645, lng: 18.1013045 },
    { lat: 48.4322122, lng: 18.1012067 },
    { lat: 48.4324868, lng: 18.1008643 },
    { lat: 48.4326444, lng: 18.100362 },
    { lat: 48.4325958, lng: 18.0997031 },
    { lat: 48.432196, lng: 18.0984885 },
    { lat: 48.4319507, lng: 18.098348 },
    { lat: 48.4319058, lng: 18.0989069 },
    { lat: 48.4318291, lng: 18.0988904 },
    { lat: 48.4320073, lng: 18.0975231 },
    { lat: 48.4320955, lng: 18.0957396 },
    { lat: 48.4322621, lng: 18.0937924 },
    { lat: 48.4322424, lng: 18.0935349 },
    { lat: 48.4330679, lng: 18.0890314 },
    { lat: 48.4340897, lng: 18.0840329 },
    { lat: 48.4346768, lng: 18.0802378 },
    { lat: 48.4349194, lng: 18.0782463 },
    { lat: 48.4353859, lng: 18.0753975 },
    { lat: 48.4356645, lng: 18.0739775 },
    { lat: 48.4358231, lng: 18.0732417 },
    { lat: 48.4359319, lng: 18.0731942 },
    { lat: 48.4360421, lng: 18.0730498 },
    { lat: 48.4367037, lng: 18.0714466 },
    { lat: 48.437017, lng: 18.0705586 },
    { lat: 48.4370848, lng: 18.0701 },
    { lat: 48.4370976, lng: 18.0683841 },
    { lat: 48.4370267, lng: 18.0681977 },
    { lat: 48.4369424, lng: 18.0675137 },
    { lat: 48.4370601, lng: 18.0643455 },
    { lat: 48.4373433, lng: 18.0596417 },
    { lat: 48.4375828, lng: 18.0576773 },
    { lat: 48.4377784, lng: 18.056442 },
    { lat: 48.4378346, lng: 18.0555961 },
    { lat: 48.4381685, lng: 18.0536457 },
    { lat: 48.438249, lng: 18.052586 },
    { lat: 48.437934, lng: 18.052541 },
    { lat: 48.437559, lng: 18.052399 },
    { lat: 48.437441, lng: 18.052338 },
    { lat: 48.437274, lng: 18.052237 },
    { lat: 48.436989, lng: 18.051973 },
    { lat: 48.436885, lng: 18.051854 },
    { lat: 48.436681, lng: 18.051545 },
    { lat: 48.436323, lng: 18.050912 },
    { lat: 48.436127, lng: 18.05062 },
  ],
  Radošina: [
    { lat: 48.5130448, lng: 17.9381528 },
    { lat: 48.5131402, lng: 17.9382758 },
    { lat: 48.513418, lng: 17.9388219 },
    { lat: 48.5137185, lng: 17.9392559 },
    { lat: 48.5143452, lng: 17.939637 },
    { lat: 48.5151414, lng: 17.9398499 },
    { lat: 48.5156147, lng: 17.9401575 },
    { lat: 48.5162671, lng: 17.9407962 },
    { lat: 48.5165785, lng: 17.9411889 },
    { lat: 48.5168803, lng: 17.9418343 },
    { lat: 48.5170726, lng: 17.9419772 },
    { lat: 48.5174084, lng: 17.9421178 },
    { lat: 48.5191846, lng: 17.9423516 },
    { lat: 48.5194704, lng: 17.9424519 },
    { lat: 48.5203585, lng: 17.9425056 },
    { lat: 48.5206767, lng: 17.9427468 },
    { lat: 48.5207865, lng: 17.9429206 },
    { lat: 48.5222616, lng: 17.943633 },
    { lat: 48.5227632, lng: 17.9439586 },
    { lat: 48.5231522, lng: 17.9443273 },
    { lat: 48.5237425, lng: 17.9450443 },
    { lat: 48.5253808, lng: 17.9426598 },
    { lat: 48.5268711, lng: 17.9402421 },
    { lat: 48.5264375, lng: 17.9396751 },
    { lat: 48.5268643, lng: 17.9389974 },
    { lat: 48.5277358, lng: 17.937798 },
    { lat: 48.5277791, lng: 17.9378619 },
    { lat: 48.528148, lng: 17.9383284 },
    { lat: 48.5285887, lng: 17.939011 },
    { lat: 48.5303147, lng: 17.9419478 },
    { lat: 48.5305766, lng: 17.9423235 },
    { lat: 48.5306815, lng: 17.9423619 },
    { lat: 48.5319969, lng: 17.9447013 },
    { lat: 48.5331981, lng: 17.9466428 },
    { lat: 48.5326136, lng: 17.9476254 },
    { lat: 48.5304993, lng: 17.9502222 },
    { lat: 48.5301704, lng: 17.9497078 },
    { lat: 48.5297282, lng: 17.9502768 },
    { lat: 48.529654, lng: 17.950148 },
    { lat: 48.5289623, lng: 17.9509075 },
    { lat: 48.5286102, lng: 17.9513991 },
    { lat: 48.5288096, lng: 17.9516914 },
    { lat: 48.5275386, lng: 17.953427 },
    { lat: 48.527465, lng: 17.9536278 },
    { lat: 48.5274062, lng: 17.9544581 },
    { lat: 48.527275, lng: 17.9548655 },
    { lat: 48.5261519, lng: 17.9565231 },
    { lat: 48.5264975, lng: 17.9571136 },
    { lat: 48.5253892, lng: 17.9588123 },
    { lat: 48.5266227, lng: 17.9612249 },
    { lat: 48.5273761, lng: 17.9624588 },
    { lat: 48.5276079, lng: 17.9619922 },
    { lat: 48.527674, lng: 17.9620972 },
    { lat: 48.5277104, lng: 17.9632912 },
    { lat: 48.5278048, lng: 17.9645494 },
    { lat: 48.5277072, lng: 17.9645384 },
    { lat: 48.5276533, lng: 17.9647334 },
    { lat: 48.5277211, lng: 17.9651501 },
    { lat: 48.5273868, lng: 17.9652239 },
    { lat: 48.5273397, lng: 17.9653421 },
    { lat: 48.5275613, lng: 17.9662165 },
    { lat: 48.5278761, lng: 17.9668725 },
    { lat: 48.5282374, lng: 17.9674809 },
    { lat: 48.5284799, lng: 17.9678729 },
    { lat: 48.5293345, lng: 17.96899 },
    { lat: 48.5296555, lng: 17.9696203 },
    { lat: 48.530181, lng: 17.970877 },
    { lat: 48.5306711, lng: 17.9718284 },
    { lat: 48.5310685, lng: 17.9727753 },
    { lat: 48.531322, lng: 17.9731833 },
    { lat: 48.5321138, lng: 17.9751185 },
    { lat: 48.5327479, lng: 17.9768368 },
    { lat: 48.5330465, lng: 17.9774887 },
    { lat: 48.5331057, lng: 17.9775933 },
    { lat: 48.5333493, lng: 17.9773085 },
    { lat: 48.5334306, lng: 17.9772516 },
    { lat: 48.5341603, lng: 17.976578 },
    { lat: 48.5347867, lng: 17.9772579 },
    { lat: 48.5350925, lng: 17.9774629 },
    { lat: 48.535186, lng: 17.977461 },
    { lat: 48.5357476, lng: 17.9771143 },
    { lat: 48.5368495, lng: 17.9761738 },
    { lat: 48.5382969, lng: 17.9751209 },
    { lat: 48.5387586, lng: 17.974876 },
    { lat: 48.5391746, lng: 17.9743969 },
    { lat: 48.5394302, lng: 17.9742327 },
    { lat: 48.5398225, lng: 17.9736371 },
    { lat: 48.5404239, lng: 17.9729544 },
    { lat: 48.5411398, lng: 17.972451 },
    { lat: 48.5425598, lng: 17.9713162 },
    { lat: 48.5430616, lng: 17.970835 },
    { lat: 48.544033, lng: 17.9700914 },
    { lat: 48.5444307, lng: 17.9697013 },
    { lat: 48.5460473, lng: 17.9677046 },
    { lat: 48.5465471, lng: 17.9673016 },
    { lat: 48.5478916, lng: 17.965585 },
    { lat: 48.5486441, lng: 17.9648428 },
    { lat: 48.549188, lng: 17.9641809 },
    { lat: 48.5499994, lng: 17.9630088 },
    { lat: 48.5509623, lng: 17.9622569 },
    { lat: 48.5502873, lng: 17.9603669 },
    { lat: 48.5502137, lng: 17.9599864 },
    { lat: 48.5500178, lng: 17.959468 },
    { lat: 48.5498323, lng: 17.9587785 },
    { lat: 48.5528204, lng: 17.9573885 },
    { lat: 48.5530783, lng: 17.9580414 },
    { lat: 48.5534112, lng: 17.9586048 },
    { lat: 48.553682, lng: 17.9592456 },
    { lat: 48.5539311, lng: 17.9596928 },
    { lat: 48.554296, lng: 17.9608312 },
    { lat: 48.5544082, lng: 17.9615923 },
    { lat: 48.5546149, lng: 17.9621051 },
    { lat: 48.5562291, lng: 17.9613876 },
    { lat: 48.5566772, lng: 17.9613143 },
    { lat: 48.5566703, lng: 17.9613847 },
    { lat: 48.5567825, lng: 17.9613948 },
    { lat: 48.5572411, lng: 17.9611922 },
    { lat: 48.5576772, lng: 17.9608198 },
    { lat: 48.5579709, lng: 17.9604678 },
    { lat: 48.5581861, lng: 17.9601022 },
    { lat: 48.5582554, lng: 17.9599603 },
    { lat: 48.5583038, lng: 17.9596533 },
    { lat: 48.5582624, lng: 17.9596223 },
    { lat: 48.5578153, lng: 17.9579217 },
    { lat: 48.5574434, lng: 17.956895 },
    { lat: 48.557192, lng: 17.9557665 },
    { lat: 48.5566684, lng: 17.9538266 },
    { lat: 48.5564816, lng: 17.9534604 },
    { lat: 48.5562691, lng: 17.9536643 },
    { lat: 48.5559397, lng: 17.9538351 },
    { lat: 48.5555666, lng: 17.9534661 },
    { lat: 48.5554263, lng: 17.9535571 },
    { lat: 48.5552451, lng: 17.9532433 },
    { lat: 48.5551595, lng: 17.9533041 },
    { lat: 48.554915, lng: 17.9529325 },
    { lat: 48.5544759, lng: 17.9530464 },
    { lat: 48.554476, lng: 17.9526886 },
    { lat: 48.5545407, lng: 17.95247 },
    { lat: 48.5550249, lng: 17.9513567 },
    { lat: 48.5556103, lng: 17.9505588 },
    { lat: 48.5557981, lng: 17.9502258 },
    { lat: 48.5561761, lng: 17.9498666 },
    { lat: 48.5563105, lng: 17.9496584 },
    { lat: 48.5570527, lng: 17.948992 },
    { lat: 48.5576359, lng: 17.9483818 },
    { lat: 48.557895, lng: 17.9490703 },
    { lat: 48.5589002, lng: 17.948252 },
    { lat: 48.559264, lng: 17.9477314 },
    { lat: 48.5593533, lng: 17.9476947 },
    { lat: 48.5594815, lng: 17.9474068 },
    { lat: 48.5601342, lng: 17.9464101 },
    { lat: 48.560831, lng: 17.9476409 },
    { lat: 48.5617944, lng: 17.9465588 },
    { lat: 48.5626828, lng: 17.945695 },
    { lat: 48.5632289, lng: 17.944991 },
    { lat: 48.5642562, lng: 17.944054 },
    { lat: 48.5653917, lng: 17.9433379 },
    { lat: 48.5653832, lng: 17.9432909 },
    { lat: 48.5661564, lng: 17.9427463 },
    { lat: 48.5677832, lng: 17.9417504 },
    { lat: 48.5698035, lng: 17.9406001 },
    { lat: 48.5698268, lng: 17.9406703 },
    { lat: 48.5705564, lng: 17.9398482 },
    { lat: 48.5716766, lng: 17.9392711 },
    { lat: 48.5725811, lng: 17.939015 },
    { lat: 48.5734584, lng: 17.9384511 },
    { lat: 48.5745852, lng: 17.9384031 },
    { lat: 48.5749285, lng: 17.9385346 },
    { lat: 48.5752826, lng: 17.9391899 },
    { lat: 48.5753471, lng: 17.9395483 },
    { lat: 48.5760057, lng: 17.9401412 },
    { lat: 48.578763, lng: 17.9429989 },
    { lat: 48.580091, lng: 17.9443855 },
    { lat: 48.5815392, lng: 17.946004 },
    { lat: 48.582458, lng: 17.946765 },
    { lat: 48.582296, lng: 17.942156 },
    { lat: 48.582285, lng: 17.94165 },
    { lat: 48.582256, lng: 17.94062 },
    { lat: 48.582221, lng: 17.939785 },
    { lat: 48.582107, lng: 17.937531 },
    { lat: 48.582058, lng: 17.936496 },
    { lat: 48.58198, lng: 17.934437 },
    { lat: 48.581943, lng: 17.933412 },
    { lat: 48.581917, lng: 17.932383 },
    { lat: 48.581909, lng: 17.932235 },
    { lat: 48.581797, lng: 17.930335 },
    { lat: 48.581679, lng: 17.928106 },
    { lat: 48.581614, lng: 17.925504 },
    { lat: 48.581594, lng: 17.924174 },
    { lat: 48.581719, lng: 17.923454 },
    { lat: 48.581951, lng: 17.921067 },
    { lat: 48.582102, lng: 17.919546 },
    { lat: 48.582113, lng: 17.919399 },
    { lat: 48.582112, lng: 17.919369 },
    { lat: 48.582147, lng: 17.919374 },
    { lat: 48.582242, lng: 17.919438 },
    { lat: 48.582617, lng: 17.919639 },
    { lat: 48.582778, lng: 17.919767 },
    { lat: 48.583022, lng: 17.920081 },
    { lat: 48.583131, lng: 17.920174 },
    { lat: 48.583666, lng: 17.920185 },
    { lat: 48.584208, lng: 17.920243 },
    { lat: 48.584387, lng: 17.920105 },
    { lat: 48.584568, lng: 17.919838 },
    { lat: 48.584665, lng: 17.919601 },
    { lat: 48.584787, lng: 17.919353 },
    { lat: 48.584935, lng: 17.919203 },
    { lat: 48.58504, lng: 17.919001 },
    { lat: 48.58519, lng: 17.918794 },
    { lat: 48.585875, lng: 17.917872 },
    { lat: 48.586022, lng: 17.917735 },
    { lat: 48.586438, lng: 17.917341 },
    { lat: 48.586511, lng: 17.917251 },
    { lat: 48.586742, lng: 17.916999 },
    { lat: 48.586926, lng: 17.916694 },
    { lat: 48.587105, lng: 17.916337 },
    { lat: 48.58719, lng: 17.916147 },
    { lat: 48.58737, lng: 17.915849 },
    { lat: 48.587574, lng: 17.915355 },
    { lat: 48.587743, lng: 17.915045 },
    { lat: 48.587809, lng: 17.914914 },
    { lat: 48.587985, lng: 17.914675 },
    { lat: 48.588132, lng: 17.91453 },
    { lat: 48.588264, lng: 17.914109 },
    { lat: 48.588406, lng: 17.913859 },
    { lat: 48.588551, lng: 17.913556 },
    { lat: 48.588669, lng: 17.913347 },
    { lat: 48.588702, lng: 17.913064 },
    { lat: 48.588714, lng: 17.912758 },
    { lat: 48.588742, lng: 17.912433 },
    { lat: 48.588754, lng: 17.91205 },
    { lat: 48.588828, lng: 17.911666 },
    { lat: 48.58882, lng: 17.911266 },
    { lat: 48.588856, lng: 17.91103 },
    { lat: 48.588979, lng: 17.910462 },
    { lat: 48.589161, lng: 17.909899 },
    { lat: 48.589305, lng: 17.909578 },
    { lat: 48.589411, lng: 17.909234 },
    { lat: 48.589656, lng: 17.908823 },
    { lat: 48.589775, lng: 17.908584 },
    { lat: 48.589912, lng: 17.908311 },
    { lat: 48.590044, lng: 17.907949 },
    { lat: 48.590112, lng: 17.907706 },
    { lat: 48.590601, lng: 17.905988 },
    { lat: 48.590776, lng: 17.905495 },
    { lat: 48.590898, lng: 17.905251 },
    { lat: 48.591166, lng: 17.904792 },
    { lat: 48.591265, lng: 17.904607 },
    { lat: 48.591534, lng: 17.904236 },
    { lat: 48.59163, lng: 17.904022 },
    { lat: 48.591697, lng: 17.903797 },
    { lat: 48.591807, lng: 17.903541 },
    { lat: 48.591948, lng: 17.90312 },
    { lat: 48.592143, lng: 17.902486 },
    { lat: 48.592212, lng: 17.902331 },
    { lat: 48.592537, lng: 17.901777 },
    { lat: 48.592678, lng: 17.90139 },
    { lat: 48.592771, lng: 17.900206 },
    { lat: 48.592876, lng: 17.899768 },
    { lat: 48.592897, lng: 17.899579 },
    { lat: 48.592864, lng: 17.899362 },
    { lat: 48.592751, lng: 17.899047 },
    { lat: 48.592598, lng: 17.898544 },
    { lat: 48.592461, lng: 17.898194 },
    { lat: 48.592433, lng: 17.898107 },
    { lat: 48.592437, lng: 17.897995 },
    { lat: 48.592488, lng: 17.897764 },
    { lat: 48.592602, lng: 17.897438 },
    { lat: 48.59262, lng: 17.89729 },
    { lat: 48.592668, lng: 17.897001 },
    { lat: 48.592738, lng: 17.896611 },
    { lat: 48.592729, lng: 17.89647 },
    { lat: 48.592653, lng: 17.89626 },
    { lat: 48.592367, lng: 17.895784 },
    { lat: 48.592237, lng: 17.895687 },
    { lat: 48.591994, lng: 17.895569 },
    { lat: 48.591943, lng: 17.895553 },
    { lat: 48.591744, lng: 17.89551 },
    { lat: 48.591467, lng: 17.895469 },
    { lat: 48.591319, lng: 17.89542 },
    { lat: 48.591083, lng: 17.895278 },
    { lat: 48.590937, lng: 17.895076 },
    { lat: 48.590695, lng: 17.894616 },
    { lat: 48.590564, lng: 17.894414 },
    { lat: 48.590076, lng: 17.894019 },
    { lat: 48.58983, lng: 17.893613 },
    { lat: 48.589566, lng: 17.893289 },
    { lat: 48.589366, lng: 17.893117 },
    { lat: 48.589236, lng: 17.893003 },
    { lat: 48.589098, lng: 17.892871 },
    { lat: 48.588995, lng: 17.892779 },
    { lat: 48.588681, lng: 17.892563 },
    { lat: 48.588556, lng: 17.892477 },
    { lat: 48.58841, lng: 17.892304 },
    { lat: 48.588323, lng: 17.892248 },
    { lat: 48.588006, lng: 17.892179 },
    { lat: 48.587906, lng: 17.892157 },
    { lat: 48.587572, lng: 17.891971 },
    { lat: 48.58749, lng: 17.891908 },
    { lat: 48.587407, lng: 17.891845 },
    { lat: 48.587273, lng: 17.891725 },
    { lat: 48.587191, lng: 17.891644 },
    { lat: 48.586971, lng: 17.891484 },
    { lat: 48.58685, lng: 17.891452 },
    { lat: 48.586787, lng: 17.891459 },
    { lat: 48.586467, lng: 17.89175 },
    { lat: 48.586253, lng: 17.891814 },
    { lat: 48.585897, lng: 17.891874 },
    { lat: 48.585511, lng: 17.891875 },
    { lat: 48.585247, lng: 17.891938 },
    { lat: 48.584887, lng: 17.892009 },
    { lat: 48.584721, lng: 17.892053 },
    { lat: 48.584571, lng: 17.89199 },
    { lat: 48.584016, lng: 17.891398 },
    { lat: 48.583951, lng: 17.891383 },
    { lat: 48.583532, lng: 17.891577 },
    { lat: 48.583297, lng: 17.891537 },
    { lat: 48.583142, lng: 17.891344 },
    { lat: 48.582342, lng: 17.891095 },
    { lat: 48.582182, lng: 17.891088 },
    { lat: 48.58174, lng: 17.890867 },
    { lat: 48.580987, lng: 17.890091 },
    { lat: 48.580824, lng: 17.889898 },
    { lat: 48.580743, lng: 17.889826 },
    { lat: 48.580647, lng: 17.889755 },
    { lat: 48.580423, lng: 17.889692 },
    { lat: 48.580268, lng: 17.889624 },
    { lat: 48.580182, lng: 17.889585 },
    { lat: 48.57999, lng: 17.889596 },
    { lat: 48.57948, lng: 17.889306 },
    { lat: 48.579053, lng: 17.889145 },
    { lat: 48.578812, lng: 17.888998 },
    { lat: 48.578665, lng: 17.888932 },
    { lat: 48.578065, lng: 17.88835 },
    { lat: 48.577842, lng: 17.888207 },
    { lat: 48.577689, lng: 17.888048 },
    { lat: 48.577528, lng: 17.887944 },
    { lat: 48.57739, lng: 17.887918 },
    { lat: 48.577121, lng: 17.88791 },
    { lat: 48.576684, lng: 17.88761 },
    { lat: 48.576411, lng: 17.887672 },
    { lat: 48.576081, lng: 17.887701 },
    { lat: 48.575408, lng: 17.888077 },
    { lat: 48.575319, lng: 17.888108 },
    { lat: 48.575101, lng: 17.88808 },
    { lat: 48.574937, lng: 17.888065 },
    { lat: 48.57476, lng: 17.888113 },
    { lat: 48.574663, lng: 17.888094 },
    { lat: 48.574594, lng: 17.888065 },
    { lat: 48.574503, lng: 17.888009 },
    { lat: 48.574359, lng: 17.887889 },
    { lat: 48.574238, lng: 17.887763 },
    { lat: 48.574017, lng: 17.887588 },
    { lat: 48.5738, lng: 17.88756 },
    { lat: 48.573629, lng: 17.887494 },
    { lat: 48.573521, lng: 17.887414 },
    { lat: 48.573334, lng: 17.887177 },
    { lat: 48.57299, lng: 17.886229 },
    { lat: 48.572913, lng: 17.886095 },
    { lat: 48.572849, lng: 17.88602 },
    { lat: 48.572768, lng: 17.885988 },
    { lat: 48.572552, lng: 17.886152 },
    { lat: 48.572309, lng: 17.886335 },
    { lat: 48.572123, lng: 17.886427 },
    { lat: 48.571889, lng: 17.886481 },
    { lat: 48.571822, lng: 17.886473 },
    { lat: 48.571701, lng: 17.886397 },
    { lat: 48.57152, lng: 17.886094 },
    { lat: 48.571458, lng: 17.885831 },
    { lat: 48.57137, lng: 17.885138 },
    { lat: 48.57129, lng: 17.884716 },
    { lat: 48.571239, lng: 17.884552 },
    { lat: 48.571142, lng: 17.884285 },
    { lat: 48.571013, lng: 17.884149 },
    { lat: 48.570528, lng: 17.884038 },
    { lat: 48.570027, lng: 17.884003 },
    { lat: 48.569905, lng: 17.88387 },
    { lat: 48.569867, lng: 17.883543 },
    { lat: 48.569812, lng: 17.883414 },
    { lat: 48.569697, lng: 17.883029 },
    { lat: 48.569704, lng: 17.882883 },
    { lat: 48.569979, lng: 17.882034 },
    { lat: 48.570025, lng: 17.881862 },
    { lat: 48.570025, lng: 17.881651 },
    { lat: 48.56998, lng: 17.881306 },
    { lat: 48.5699242, lng: 17.8814655 },
    { lat: 48.5690985, lng: 17.8820529 },
    { lat: 48.5684936, lng: 17.8823185 },
    { lat: 48.5679003, lng: 17.8824548 },
    { lat: 48.5670697, lng: 17.8825361 },
    { lat: 48.5666865, lng: 17.8827509 },
    { lat: 48.5664996, lng: 17.8827195 },
    { lat: 48.5661161, lng: 17.8828748 },
    { lat: 48.5655585, lng: 17.8828583 },
    { lat: 48.5651769, lng: 17.882967 },
    { lat: 48.5646539, lng: 17.8829979 },
    { lat: 48.563605, lng: 17.8840956 },
    { lat: 48.5633905, lng: 17.8862066 },
    { lat: 48.5633027, lng: 17.8866592 },
    { lat: 48.5631571, lng: 17.8869582 },
    { lat: 48.5631621, lng: 17.8876938 },
    { lat: 48.5629371, lng: 17.8886216 },
    { lat: 48.5630906, lng: 17.8890707 },
    { lat: 48.5630553, lng: 17.8892352 },
    { lat: 48.5631514, lng: 17.8896657 },
    { lat: 48.562962, lng: 17.8900819 },
    { lat: 48.5630624, lng: 17.8904712 },
    { lat: 48.5631117, lng: 17.8909309 },
    { lat: 48.5630601, lng: 17.891201 },
    { lat: 48.5631688, lng: 17.8915795 },
    { lat: 48.5631075, lng: 17.8917034 },
    { lat: 48.563124, lng: 17.8919036 },
    { lat: 48.5630865, lng: 17.892104 },
    { lat: 48.5632163, lng: 17.8924789 },
    { lat: 48.5631787, lng: 17.8925849 },
    { lat: 48.5632568, lng: 17.8928134 },
    { lat: 48.5630455, lng: 17.8932746 },
    { lat: 48.5629626, lng: 17.893734 },
    { lat: 48.562987, lng: 17.8939512 },
    { lat: 48.56295, lng: 17.8942931 },
    { lat: 48.5628305, lng: 17.8946295 },
    { lat: 48.562166, lng: 17.8957775 },
    { lat: 48.5615659, lng: 17.8966074 },
    { lat: 48.560919, lng: 17.8979725 },
    { lat: 48.5603497, lng: 17.8986196 },
    { lat: 48.5601088, lng: 17.8987634 },
    { lat: 48.5597481, lng: 17.8991435 },
    { lat: 48.5594683, lng: 17.8993525 },
    { lat: 48.5592349, lng: 17.8994315 },
    { lat: 48.5583873, lng: 17.9000896 },
    { lat: 48.5574834, lng: 17.9008804 },
    { lat: 48.5570611, lng: 17.9013252 },
    { lat: 48.5568667, lng: 17.9018083 },
    { lat: 48.5565998, lng: 17.9019528 },
    { lat: 48.5564629, lng: 17.9021693 },
    { lat: 48.5562276, lng: 17.9023661 },
    { lat: 48.5561522, lng: 17.9025044 },
    { lat: 48.5561696, lng: 17.9026035 },
    { lat: 48.556053, lng: 17.9026548 },
    { lat: 48.5559886, lng: 17.902804 },
    { lat: 48.5559274, lng: 17.9028355 },
    { lat: 48.5558677, lng: 17.9030258 },
    { lat: 48.5555908, lng: 17.9035062 },
    { lat: 48.5545371, lng: 17.9047315 },
    { lat: 48.5542918, lng: 17.9049514 },
    { lat: 48.5542195, lng: 17.9048191 },
    { lat: 48.5541134, lng: 17.9048404 },
    { lat: 48.5540246, lng: 17.9051258 },
    { lat: 48.5539189, lng: 17.9050591 },
    { lat: 48.5537927, lng: 17.9052695 },
    { lat: 48.5536635, lng: 17.9052212 },
    { lat: 48.5536026, lng: 17.9053176 },
    { lat: 48.5536236, lng: 17.9053635 },
    { lat: 48.5535654, lng: 17.9054302 },
    { lat: 48.5535335, lng: 17.9053491 },
    { lat: 48.5534508, lng: 17.9055696 },
    { lat: 48.5533385, lng: 17.905597 },
    { lat: 48.5532823, lng: 17.9057519 },
    { lat: 48.5531837, lng: 17.9056905 },
    { lat: 48.5530556, lng: 17.905754 },
    { lat: 48.5530537, lng: 17.9058363 },
    { lat: 48.5529715, lng: 17.9058396 },
    { lat: 48.5529257, lng: 17.9059236 },
    { lat: 48.5528311, lng: 17.9058328 },
    { lat: 48.5527246, lng: 17.9060127 },
    { lat: 48.5526195, lng: 17.9060464 },
    { lat: 48.5524317, lng: 17.9062466 },
    { lat: 48.552281, lng: 17.9062933 },
    { lat: 48.5521868, lng: 17.9062495 },
    { lat: 48.5521724, lng: 17.9063441 },
    { lat: 48.5517371, lng: 17.9064653 },
    { lat: 48.5513155, lng: 17.906886 },
    { lat: 48.5512054, lng: 17.9068779 },
    { lat: 48.5510983, lng: 17.9069528 },
    { lat: 48.5510098, lng: 17.9070379 },
    { lat: 48.5509381, lng: 17.9072348 },
    { lat: 48.5508181, lng: 17.9072683 },
    { lat: 48.5506997, lng: 17.9074195 },
    { lat: 48.5506101, lng: 17.9077107 },
    { lat: 48.5505215, lng: 17.9077196 },
    { lat: 48.5504188, lng: 17.9079467 },
    { lat: 48.5503295, lng: 17.907944 },
    { lat: 48.5500735, lng: 17.9082647 },
    { lat: 48.5497779, lng: 17.9083458 },
    { lat: 48.5496507, lng: 17.9084444 },
    { lat: 48.5494854, lng: 17.9085856 },
    { lat: 48.549452, lng: 17.9086868 },
    { lat: 48.549032, lng: 17.9090427 },
    { lat: 48.5483206, lng: 17.9098793 },
    { lat: 48.5479317, lng: 17.9104337 },
    { lat: 48.5478752, lng: 17.9106181 },
    { lat: 48.5472808, lng: 17.9097278 },
    { lat: 48.5465609, lng: 17.9093361 },
    { lat: 48.5463797, lng: 17.9091603 },
    { lat: 48.5466609, lng: 17.9084681 },
    { lat: 48.5465555, lng: 17.9082192 },
    { lat: 48.5463478, lng: 17.9079209 },
    { lat: 48.5463819, lng: 17.9078668 },
    { lat: 48.5462756, lng: 17.9075471 },
    { lat: 48.5461333, lng: 17.9074113 },
    { lat: 48.5459072, lng: 17.9076306 },
    { lat: 48.5456473, lng: 17.9077225 },
    { lat: 48.5454327, lng: 17.9077019 },
    { lat: 48.545234, lng: 17.907867 },
    { lat: 48.544739, lng: 17.9077266 },
    { lat: 48.5444392, lng: 17.9077915 },
    { lat: 48.5430875, lng: 17.9101247 },
    { lat: 48.5427138, lng: 17.9104597 },
    { lat: 48.5425304, lng: 17.9107124 },
    { lat: 48.5410314, lng: 17.9129805 },
    { lat: 48.5397493, lng: 17.9147265 },
    { lat: 48.5391177, lng: 17.9160655 },
    { lat: 48.5391332, lng: 17.9162885 },
    { lat: 48.5391776, lng: 17.9163799 },
    { lat: 48.539223, lng: 17.9164825 },
    { lat: 48.5386229, lng: 17.9176736 },
    { lat: 48.5360639, lng: 17.923299 },
    { lat: 48.534543, lng: 17.9262917 },
    { lat: 48.5344209, lng: 17.9261217 },
    { lat: 48.5328179, lng: 17.9243725 },
    { lat: 48.5315826, lng: 17.9226947 },
    { lat: 48.5310849, lng: 17.9229817 },
    { lat: 48.5307669, lng: 17.9232669 },
    { lat: 48.5300622, lng: 17.92101 },
    { lat: 48.5299883, lng: 17.9207213 },
    { lat: 48.5300707, lng: 17.920663 },
    { lat: 48.5296375, lng: 17.9191851 },
    { lat: 48.529391, lng: 17.9192899 },
    { lat: 48.5293405, lng: 17.9191661 },
    { lat: 48.5280809, lng: 17.9190902 },
    { lat: 48.5278206, lng: 17.9191948 },
    { lat: 48.525384, lng: 17.9200625 },
    { lat: 48.5249089, lng: 17.9205358 },
    { lat: 48.5244586, lng: 17.9208554 },
    { lat: 48.5229454, lng: 17.9221941 },
    { lat: 48.5228645, lng: 17.9223309 },
    { lat: 48.5193316, lng: 17.926051 },
    { lat: 48.5139382, lng: 17.9253353 },
    { lat: 48.5140243, lng: 17.9260915 },
    { lat: 48.5141354, lng: 17.9269828 },
    { lat: 48.5141788, lng: 17.9280222 },
    { lat: 48.5143961, lng: 17.9289695 },
    { lat: 48.5144775, lng: 17.9302671 },
    { lat: 48.5141591, lng: 17.9324623 },
    { lat: 48.5139665, lng: 17.9323554 },
    { lat: 48.5137285, lng: 17.9334494 },
    { lat: 48.513413, lng: 17.9355444 },
    { lat: 48.5133843, lng: 17.9360627 },
    { lat: 48.5132421, lng: 17.936625 },
    { lat: 48.5131559, lng: 17.9376507 },
    { lat: 48.5130448, lng: 17.9381528 },
  ],
  Krnča: [
    { lat: 48.496886, lng: 18.311372 },
    { lat: 48.497038, lng: 18.311369 },
    { lat: 48.497294, lng: 18.311311 },
    { lat: 48.497468, lng: 18.311208 },
    { lat: 48.497554, lng: 18.311183 },
    { lat: 48.497907, lng: 18.311012 },
    { lat: 48.498107, lng: 18.310955 },
    { lat: 48.498157, lng: 18.310937 },
    { lat: 48.49832, lng: 18.310863 },
    { lat: 48.498571, lng: 18.311041 },
    { lat: 48.498871, lng: 18.3111 },
    { lat: 48.498981, lng: 18.310998 },
    { lat: 48.499093, lng: 18.310949 },
    { lat: 48.499273, lng: 18.310981 },
    { lat: 48.49934, lng: 18.311021 },
    { lat: 48.499418, lng: 18.311066 },
    { lat: 48.499532, lng: 18.311123 },
    { lat: 48.499649, lng: 18.311132 },
    { lat: 48.499749, lng: 18.311095 },
    { lat: 48.49992, lng: 18.311003 },
    { lat: 48.500006, lng: 18.310961 },
    { lat: 48.500206, lng: 18.311034 },
    { lat: 48.500432, lng: 18.311041 },
    { lat: 48.500575, lng: 18.311368 },
    { lat: 48.500732, lng: 18.3116 },
    { lat: 48.500831, lng: 18.31171 },
    { lat: 48.500917, lng: 18.311726 },
    { lat: 48.501136, lng: 18.311728 },
    { lat: 48.501223, lng: 18.311867 },
    { lat: 48.501375, lng: 18.312017 },
    { lat: 48.501542, lng: 18.312226 },
    { lat: 48.501678, lng: 18.312286 },
    { lat: 48.501694, lng: 18.312287 },
    { lat: 48.501873, lng: 18.312302 },
    { lat: 48.501995, lng: 18.312335 },
    { lat: 48.502061, lng: 18.312369 },
    { lat: 48.502171, lng: 18.312484 },
    { lat: 48.50225, lng: 18.312581 },
    { lat: 48.502443, lng: 18.312844 },
    { lat: 48.502542, lng: 18.312912 },
    { lat: 48.502639, lng: 18.312917 },
    { lat: 48.502736, lng: 18.312856 },
    { lat: 48.502809, lng: 18.312772 },
    { lat: 48.502875, lng: 18.312659 },
    { lat: 48.503022, lng: 18.31244 },
    { lat: 48.503129, lng: 18.312202 },
    { lat: 48.503159, lng: 18.312072 },
    { lat: 48.503216, lng: 18.311877 },
    { lat: 48.503356, lng: 18.311369 },
    { lat: 48.503413, lng: 18.311186 },
    { lat: 48.503486, lng: 18.311015 },
    { lat: 48.503621, lng: 18.310853 },
    { lat: 48.503725, lng: 18.310751 },
    { lat: 48.503876, lng: 18.310677 },
    { lat: 48.504067, lng: 18.310481 },
    { lat: 48.504125, lng: 18.31042 },
    { lat: 48.504443, lng: 18.310156 },
    { lat: 48.504559, lng: 18.3101 },
    { lat: 48.504679, lng: 18.310069 },
    { lat: 48.504784, lng: 18.309996 },
    { lat: 48.504884, lng: 18.309836 },
    { lat: 48.505039, lng: 18.309692 },
    { lat: 48.505308, lng: 18.309664 },
    { lat: 48.505444, lng: 18.309737 },
    { lat: 48.505531, lng: 18.30973 },
    { lat: 48.505596, lng: 18.309711 },
    { lat: 48.505687, lng: 18.30965 },
    { lat: 48.505837, lng: 18.309495 },
    { lat: 48.506306, lng: 18.309239 },
    { lat: 48.50656, lng: 18.309235 },
    { lat: 48.506771, lng: 18.309148 },
    { lat: 48.507097, lng: 18.309084 },
    { lat: 48.507288, lng: 18.309062 },
    { lat: 48.507463, lng: 18.30907 },
    { lat: 48.507628, lng: 18.309085 },
    { lat: 48.507736, lng: 18.309089 },
    { lat: 48.507809, lng: 18.309114 },
    { lat: 48.507829, lng: 18.309123 },
    { lat: 48.50825, lng: 18.309258 },
    { lat: 48.508387, lng: 18.309258 },
    { lat: 48.5085, lng: 18.309205 },
    { lat: 48.50856, lng: 18.309168 },
    { lat: 48.508718, lng: 18.308993 },
    { lat: 48.508827, lng: 18.308975 },
    { lat: 48.508886, lng: 18.308946 },
    { lat: 48.508945, lng: 18.308887 },
    { lat: 48.508986, lng: 18.308769 },
    { lat: 48.509104, lng: 18.308639 },
    { lat: 48.509346, lng: 18.308445 },
    { lat: 48.509401, lng: 18.308374 },
    { lat: 48.509575, lng: 18.308127 },
    { lat: 48.509665, lng: 18.308027 },
    { lat: 48.509895, lng: 18.307892 },
    { lat: 48.510071, lng: 18.307839 },
    { lat: 48.510196, lng: 18.307809 },
    { lat: 48.510301, lng: 18.307815 },
    { lat: 48.510454, lng: 18.307744 },
    { lat: 48.510559, lng: 18.307685 },
    { lat: 48.510723, lng: 18.307673 },
    { lat: 48.510789, lng: 18.30765 },
    { lat: 48.51089, lng: 18.307585 },
    { lat: 48.510956, lng: 18.307467 },
    { lat: 48.511073, lng: 18.307443 },
    { lat: 48.511257, lng: 18.30742 },
    { lat: 48.511424, lng: 18.307349 },
    { lat: 48.511514, lng: 18.30729 },
    { lat: 48.511674, lng: 18.307154 },
    { lat: 48.511744, lng: 18.307119 },
    { lat: 48.511815, lng: 18.307113 },
    { lat: 48.51199, lng: 18.307143 },
    { lat: 48.512083, lng: 18.307154 },
    { lat: 48.512259, lng: 18.307195 },
    { lat: 48.512321, lng: 18.307213 },
    { lat: 48.512338, lng: 18.307214 },
    { lat: 48.512525, lng: 18.307219 },
    { lat: 48.51259, lng: 18.307201 },
    { lat: 48.512832, lng: 18.307248 },
    { lat: 48.512923, lng: 18.307285 },
    { lat: 48.513028, lng: 18.307325 },
    { lat: 48.513117, lng: 18.307324 },
    { lat: 48.513184, lng: 18.307342 },
    { lat: 48.513278, lng: 18.307389 },
    { lat: 48.513364, lng: 18.307448 },
    { lat: 48.513377, lng: 18.307456 },
    { lat: 48.51347, lng: 18.307514 },
    { lat: 48.51354, lng: 18.307565 },
    { lat: 48.513621, lng: 18.307595 },
    { lat: 48.513711, lng: 18.307601 },
    { lat: 48.513886, lng: 18.307606 },
    { lat: 48.513941, lng: 18.307601 },
    { lat: 48.513958, lng: 18.307602 },
    { lat: 48.514062, lng: 18.307612 },
    { lat: 48.514117, lng: 18.307583 },
    { lat: 48.514202, lng: 18.307488 },
    { lat: 48.514468, lng: 18.307265 },
    { lat: 48.514608, lng: 18.307123 },
    { lat: 48.514697, lng: 18.306912 },
    { lat: 48.514747, lng: 18.306741 },
    { lat: 48.514821, lng: 18.306635 },
    { lat: 48.514892, lng: 18.306582 },
    { lat: 48.514971, lng: 18.306499 },
    { lat: 48.515063, lng: 18.306364 },
    { lat: 48.515314, lng: 18.306122 },
    { lat: 48.515345, lng: 18.306075 },
    { lat: 48.515434, lng: 18.305904 },
    { lat: 48.515512, lng: 18.305816 },
    { lat: 48.51566, lng: 18.305698 },
    { lat: 48.515816, lng: 18.305527 },
    { lat: 48.51605, lng: 18.305239 },
    { lat: 48.51626, lng: 18.304944 },
    { lat: 48.516451, lng: 18.304744 },
    { lat: 48.516576, lng: 18.304655 },
    { lat: 48.516601, lng: 18.304639 },
    { lat: 48.516798, lng: 18.304496 },
    { lat: 48.516982, lng: 18.304261 },
    { lat: 48.51709, lng: 18.304226 },
    { lat: 48.517492, lng: 18.304089 },
    { lat: 48.517657, lng: 18.304007 },
    { lat: 48.517773, lng: 18.303848 },
    { lat: 48.517878, lng: 18.303783 },
    { lat: 48.518036, lng: 18.303738 },
    { lat: 48.518093, lng: 18.303725 },
    { lat: 48.518358, lng: 18.303647 },
    { lat: 48.518585, lng: 18.303523 },
    { lat: 48.518882, lng: 18.3034 },
    { lat: 48.519159, lng: 18.303298 },
    { lat: 48.519446, lng: 18.303188 },
    { lat: 48.519547, lng: 18.30317 },
    { lat: 48.519747, lng: 18.303187 },
    { lat: 48.519832, lng: 18.303223 },
    { lat: 48.519934, lng: 18.30333 },
    { lat: 48.520012, lng: 18.303411 },
    { lat: 48.520071, lng: 18.303464 },
    { lat: 48.52018, lng: 18.303529 },
    { lat: 48.520258, lng: 18.303452 },
    { lat: 48.520329, lng: 18.303275 },
    { lat: 48.520351, lng: 18.303116 },
    { lat: 48.520453, lng: 18.302869 },
    { lat: 48.520589, lng: 18.302551 },
    { lat: 48.520643, lng: 18.30201 },
    { lat: 48.520673, lng: 18.301352 },
    { lat: 48.520769, lng: 18.300747 },
    { lat: 48.520862, lng: 18.300465 },
    { lat: 48.521015, lng: 18.300232 },
    { lat: 48.521026, lng: 18.300217 },
    { lat: 48.521181, lng: 18.300011 },
    { lat: 48.521235, lng: 18.299958 },
    { lat: 48.521419, lng: 18.299765 },
    { lat: 48.521663, lng: 18.299538 },
    { lat: 48.521878, lng: 18.299135 },
    { lat: 48.522052, lng: 18.299003 },
    { lat: 48.522271, lng: 18.298852 },
    { lat: 48.522503, lng: 18.298755 },
    { lat: 48.522589, lng: 18.298713 },
    { lat: 48.522947, lng: 18.298648 },
    { lat: 48.523207, lng: 18.298562 },
    { lat: 48.523569, lng: 18.298539 },
    { lat: 48.523686, lng: 18.298443 },
    { lat: 48.523847, lng: 18.298082 },
    { lat: 48.524006, lng: 18.297862 },
    { lat: 48.524136, lng: 18.297549 },
    { lat: 48.524287, lng: 18.297382 },
    { lat: 48.524531, lng: 18.297214 },
    { lat: 48.524609, lng: 18.297154 },
    { lat: 48.524941, lng: 18.296832 },
    { lat: 48.525275, lng: 18.296421 },
    { lat: 48.525511, lng: 18.296148 },
    { lat: 48.525754, lng: 18.295862 },
    { lat: 48.526055, lng: 18.295451 },
    { lat: 48.526464, lng: 18.294881 },
    { lat: 48.526611, lng: 18.29475 },
    { lat: 48.526794, lng: 18.294612 },
    { lat: 48.527038, lng: 18.294355 },
    { lat: 48.52718, lng: 18.2942 },
    { lat: 48.527402, lng: 18.294068 },
    { lat: 48.527507, lng: 18.294037 },
    { lat: 48.52764, lng: 18.294046 },
    { lat: 48.527722, lng: 18.29411 },
    { lat: 48.527906, lng: 18.294183 },
    { lat: 48.528018, lng: 18.294158 },
    { lat: 48.528355, lng: 18.293788 },
    { lat: 48.528672, lng: 18.293454 },
    { lat: 48.528905, lng: 18.29328 },
    { lat: 48.528994, lng: 18.293191 },
    { lat: 48.529083, lng: 18.29309 },
    { lat: 48.529164, lng: 18.292936 },
    { lat: 48.529202, lng: 18.29287 },
    { lat: 48.529793, lng: 18.292497 },
    { lat: 48.530305, lng: 18.292225 },
    { lat: 48.530679, lng: 18.292107 },
    { lat: 48.531237, lng: 18.291734 },
    { lat: 48.531568, lng: 18.291659 },
    { lat: 48.531995, lng: 18.291476 },
    { lat: 48.532208, lng: 18.291308 },
    { lat: 48.532329, lng: 18.291242 },
    { lat: 48.53243, lng: 18.291205 },
    { lat: 48.532534, lng: 18.291192 },
    { lat: 48.532601, lng: 18.291179 },
    { lat: 48.532846, lng: 18.291134 },
    { lat: 48.533305, lng: 18.290933 },
    { lat: 48.533429, lng: 18.290855 },
    { lat: 48.5336, lng: 18.290735 },
    { lat: 48.53386, lng: 18.290572 },
    { lat: 48.534128, lng: 18.290462 },
    { lat: 48.534573, lng: 18.289955 },
    { lat: 48.53498, lng: 18.289147 },
    { lat: 48.535341, lng: 18.288796 },
    { lat: 48.53545, lng: 18.288759 },
    { lat: 48.535789, lng: 18.288544 },
    { lat: 48.535854, lng: 18.288402 },
    { lat: 48.535927, lng: 18.288113 },
    { lat: 48.536169, lng: 18.287999 },
    { lat: 48.536283, lng: 18.288262 },
    { lat: 48.536548, lng: 18.288507 },
    { lat: 48.536705, lng: 18.288546 },
    { lat: 48.537025, lng: 18.288701 },
    { lat: 48.537352, lng: 18.288474 },
    { lat: 48.537674, lng: 18.288083 },
    { lat: 48.537981, lng: 18.287892 },
    { lat: 48.538268, lng: 18.287717 },
    { lat: 48.538378, lng: 18.28764 },
    { lat: 48.538459, lng: 18.287593 },
    { lat: 48.53849, lng: 18.287574 },
    { lat: 48.538673, lng: 18.287473 },
    { lat: 48.538914, lng: 18.287264 },
    { lat: 48.539185, lng: 18.286997 },
    { lat: 48.539395, lng: 18.286783 },
    { lat: 48.539598, lng: 18.286592 },
    { lat: 48.539857, lng: 18.286278 },
    { lat: 48.540187, lng: 18.286086 },
    { lat: 48.540479, lng: 18.285942 },
    { lat: 48.540648, lng: 18.285814 },
    { lat: 48.540876, lng: 18.285661 },
    { lat: 48.541042, lng: 18.285272 },
    { lat: 48.541356, lng: 18.28498 },
    { lat: 48.541586, lng: 18.284689 },
    { lat: 48.541613, lng: 18.284666 },
    { lat: 48.541775, lng: 18.284511 },
    { lat: 48.541884, lng: 18.284274 },
    { lat: 48.54196, lng: 18.28395 },
    { lat: 48.542145, lng: 18.283413 },
    { lat: 48.542287, lng: 18.282923 },
    { lat: 48.542387, lng: 18.282593 },
    { lat: 48.542439, lng: 18.282145 },
    { lat: 48.542461, lng: 18.281733 },
    { lat: 48.542561, lng: 18.281303 },
    { lat: 48.542638, lng: 18.281079 },
    { lat: 48.542643, lng: 18.280573 },
    { lat: 48.542697, lng: 18.280314 },
    { lat: 48.542677, lng: 18.280155 },
    { lat: 48.542706, lng: 18.279773 },
    { lat: 48.542737, lng: 18.27959 },
    { lat: 48.542786, lng: 18.279337 },
    { lat: 48.542794, lng: 18.279266 },
    { lat: 48.542809, lng: 18.279207 },
    { lat: 48.542975, lng: 18.278999 },
    { lat: 48.543049, lng: 18.278907 },
    { lat: 48.543228, lng: 18.278685 },
    { lat: 48.543255, lng: 18.278658 },
    { lat: 48.543487, lng: 18.278416 },
    { lat: 48.543651, lng: 18.278245 },
    { lat: 48.54381, lng: 18.278032 },
    { lat: 48.543891, lng: 18.27796 },
    { lat: 48.54407, lng: 18.277805 },
    { lat: 48.544334, lng: 18.277514 },
    { lat: 48.544473, lng: 18.27733 },
    { lat: 48.54473, lng: 18.277157 },
    { lat: 48.544883, lng: 18.276854 },
    { lat: 48.544907, lng: 18.27681 },
    { lat: 48.544985, lng: 18.27668 },
    { lat: 48.545096, lng: 18.276455 },
    { lat: 48.545281, lng: 18.276082 },
    { lat: 48.545439, lng: 18.275746 },
    { lat: 48.545759, lng: 18.275195 },
    { lat: 48.545928, lng: 18.27487 },
    { lat: 48.546261, lng: 18.274308 },
    { lat: 48.546542, lng: 18.273817 },
    { lat: 48.546637, lng: 18.273451 },
    { lat: 48.546823, lng: 18.272532 },
    { lat: 48.546883, lng: 18.27222 },
    { lat: 48.546886, lng: 18.271833 },
    { lat: 48.546926, lng: 18.271473 },
    { lat: 48.546999, lng: 18.271179 },
    { lat: 48.547067, lng: 18.270931 },
    { lat: 48.547128, lng: 18.270572 },
    { lat: 48.547188, lng: 18.270313 },
    { lat: 48.547266, lng: 18.270347 },
    { lat: 48.547352, lng: 18.270323 },
    { lat: 48.547537, lng: 18.270038 },
    { lat: 48.547657, lng: 18.269907 },
    { lat: 48.547971, lng: 18.26951 },
    { lat: 48.54819, lng: 18.269149 },
    { lat: 48.548271, lng: 18.268866 },
    { lat: 48.548305, lng: 18.268659 },
    { lat: 48.5483, lng: 18.268477 },
    { lat: 48.548509, lng: 18.268345 },
    { lat: 48.548964, lng: 18.268058 },
    { lat: 48.548947, lng: 18.267805 },
    { lat: 48.549166, lng: 18.26788 },
    { lat: 48.549528, lng: 18.267635 },
    { lat: 48.54965, lng: 18.267692 },
    { lat: 48.549832, lng: 18.267643 },
    { lat: 48.550267, lng: 18.267809 },
    { lat: 48.552017, lng: 18.266038 },
    { lat: 48.552452, lng: 18.264679 },
    { lat: 48.5520831, lng: 18.2642541 },
    { lat: 48.550949, lng: 18.262948 },
    { lat: 48.551018, lng: 18.262694 },
    { lat: 48.551076, lng: 18.262553 },
    { lat: 48.551157, lng: 18.262439 },
    { lat: 48.551196, lng: 18.262387 },
    { lat: 48.551437, lng: 18.262142 },
    { lat: 48.551487, lng: 18.262089 },
    { lat: 48.551569, lng: 18.262018 },
    { lat: 48.551798, lng: 18.261809 },
    { lat: 48.551907, lng: 18.261713 },
    { lat: 48.552066, lng: 18.261571 },
    { lat: 48.552279, lng: 18.261363 },
    { lat: 48.552427, lng: 18.261215 },
    { lat: 48.552532, lng: 18.261119 },
    { lat: 48.552544, lng: 18.261095 },
    { lat: 48.552652, lng: 18.260935 },
    { lat: 48.552737, lng: 18.260817 },
    { lat: 48.552827, lng: 18.260675 },
    { lat: 48.552935, lng: 18.260509 },
    { lat: 48.552946, lng: 18.260491 },
    { lat: 48.553387, lng: 18.259757 },
    { lat: 48.553762, lng: 18.259117 },
    { lat: 48.553425, lng: 18.258603 },
    { lat: 48.553486, lng: 18.258455 },
    { lat: 48.553584, lng: 18.258325 },
    { lat: 48.553691, lng: 18.258194 },
    { lat: 48.553719, lng: 18.258177 },
    { lat: 48.553828, lng: 18.258081 },
    { lat: 48.553972, lng: 18.258015 },
    { lat: 48.554268, lng: 18.257912 },
    { lat: 48.554571, lng: 18.257697 },
    { lat: 48.555066, lng: 18.257479 },
    { lat: 48.555142, lng: 18.257119 },
    { lat: 48.555036, lng: 18.256968 },
    { lat: 48.55524, lng: 18.256548 },
    { lat: 48.555274, lng: 18.256336 },
    { lat: 48.555445, lng: 18.256004 },
    { lat: 48.555669, lng: 18.255523 },
    { lat: 48.555973, lng: 18.254886 },
    { lat: 48.555953, lng: 18.254657 },
    { lat: 48.555975, lng: 18.254445 },
    { lat: 48.556056, lng: 18.254209 },
    { lat: 48.555558, lng: 18.25352 },
    { lat: 48.555828, lng: 18.25307 },
    { lat: 48.556029, lng: 18.252744 },
    { lat: 48.556037, lng: 18.252715 },
    { lat: 48.555346, lng: 18.25151 },
    { lat: 48.555294, lng: 18.251422 },
    { lat: 48.555669, lng: 18.250693 },
    { lat: 48.555704, lng: 18.25057 },
    { lat: 48.555654, lng: 18.250465 },
    { lat: 48.555582, lng: 18.250423 },
    { lat: 48.555564, lng: 18.250373 },
    { lat: 48.555603, lng: 18.250209 },
    { lat: 48.555799, lng: 18.250031 },
    { lat: 48.556037, lng: 18.249905 },
    { lat: 48.556144, lng: 18.249887 },
    { lat: 48.556315, lng: 18.249937 },
    { lat: 48.556397, lng: 18.249866 },
    { lat: 48.556474, lng: 18.249538 },
    { lat: 48.556524, lng: 18.249422 },
    { lat: 48.556484, lng: 18.249378 },
    { lat: 48.555913, lng: 18.248749 },
    { lat: 48.555891, lng: 18.248725 },
    { lat: 48.555867, lng: 18.248699 },
    { lat: 48.556158, lng: 18.247294 },
    { lat: 48.556378, lng: 18.246417 },
    { lat: 48.556638, lng: 18.245655 },
    { lat: 48.555734, lng: 18.244717 },
    { lat: 48.55615, lng: 18.24373 },
    { lat: 48.555876, lng: 18.243458 },
    { lat: 48.555777, lng: 18.243579 },
    { lat: 48.555609, lng: 18.2434 },
    { lat: 48.555529, lng: 18.243728 },
    { lat: 48.555456, lng: 18.243916 },
    { lat: 48.555326, lng: 18.244331 },
    { lat: 48.555183, lng: 18.244611 },
    { lat: 48.555077, lng: 18.244708 },
    { lat: 48.555045, lng: 18.244662 },
    { lat: 48.554285, lng: 18.243535 },
    { lat: 48.553743, lng: 18.242839 },
    { lat: 48.553464, lng: 18.242513 },
    { lat: 48.553041, lng: 18.242123 },
    { lat: 48.553009, lng: 18.242077 },
    { lat: 48.553159, lng: 18.241811 },
    { lat: 48.553709, lng: 18.240728 },
    { lat: 48.553953, lng: 18.24013 },
    { lat: 48.554161, lng: 18.239731 },
    { lat: 48.554436, lng: 18.239214 },
    { lat: 48.554675, lng: 18.238891 },
    { lat: 48.555266, lng: 18.238307 },
    { lat: 48.555483, lng: 18.238103 },
    { lat: 48.555989, lng: 18.237723 },
    { lat: 48.555962, lng: 18.237665 },
    { lat: 48.555596, lng: 18.23712 },
    { lat: 48.55487, lng: 18.236105 },
    { lat: 48.554516, lng: 18.235623 },
    { lat: 48.553847, lng: 18.234821 },
    { lat: 48.553354, lng: 18.234265 },
    { lat: 48.552875, lng: 18.233766 },
    { lat: 48.552484, lng: 18.233389 },
    { lat: 48.552233, lng: 18.233182 },
    { lat: 48.552167, lng: 18.233125 },
    { lat: 48.551135, lng: 18.232206 },
    { lat: 48.55111, lng: 18.232184 },
    { lat: 48.551061, lng: 18.23214 },
    { lat: 48.5510345, lng: 18.2321158 },
    { lat: 48.551339, lng: 18.231439 },
    { lat: 48.551352, lng: 18.231384 },
    { lat: 48.549599, lng: 18.230752 },
    { lat: 48.5488289, lng: 18.2285845 },
    { lat: 48.5474825, lng: 18.2303651 },
    { lat: 48.547368, lng: 18.2302361 },
    { lat: 48.5464472, lng: 18.231318 },
    { lat: 48.5458296, lng: 18.2306026 },
    { lat: 48.545545, lng: 18.2310321 },
    { lat: 48.5454481, lng: 18.2309179 },
    { lat: 48.5446863, lng: 18.2320766 },
    { lat: 48.5443553, lng: 18.2316945 },
    { lat: 48.5431754, lng: 18.2339414 },
    { lat: 48.5416839, lng: 18.2325884 },
    { lat: 48.5413278, lng: 18.2335232 },
    { lat: 48.5410853, lng: 18.2339736 },
    { lat: 48.5408856, lng: 18.2346833 },
    { lat: 48.5407148, lng: 18.2349708 },
    { lat: 48.5407633, lng: 18.2350706 },
    { lat: 48.5406157, lng: 18.2352091 },
    { lat: 48.5404963, lng: 18.2352335 },
    { lat: 48.5403112, lng: 18.2355685 },
    { lat: 48.5402807, lng: 18.2357245 },
    { lat: 48.5400309, lng: 18.2361473 },
    { lat: 48.5397711, lng: 18.2369107 },
    { lat: 48.5395873, lng: 18.2371479 },
    { lat: 48.5395336, lng: 18.237099 },
    { lat: 48.5386464, lng: 18.2386059 },
    { lat: 48.5382308, lng: 18.2391101 },
    { lat: 48.5378917, lng: 18.2392991 },
    { lat: 48.5369349, lng: 18.2401047 },
    { lat: 48.5362874, lng: 18.2409559 },
    { lat: 48.5360702, lng: 18.2413007 },
    { lat: 48.5358287, lng: 18.2418889 },
    { lat: 48.5356736, lng: 18.2424328 },
    { lat: 48.535511, lng: 18.2427441 },
    { lat: 48.5345292, lng: 18.2437658 },
    { lat: 48.5342452, lng: 18.2438682 },
    { lat: 48.5335127, lng: 18.2444174 },
    { lat: 48.5334104, lng: 18.2444229 },
    { lat: 48.5332583, lng: 18.2445459 },
    { lat: 48.5319583, lng: 18.2459431 },
    { lat: 48.5319699, lng: 18.24599 },
    { lat: 48.5317387, lng: 18.2462664 },
    { lat: 48.5316493, lng: 18.2462627 },
    { lat: 48.5314951, lng: 18.2465426 },
    { lat: 48.5313052, lng: 18.2466357 },
    { lat: 48.5311237, lng: 18.2464809 },
    { lat: 48.5308116, lng: 18.2468237 },
    { lat: 48.5304583, lng: 18.2470384 },
    { lat: 48.5294649, lng: 18.2483686 },
    { lat: 48.5287045, lng: 18.2491576 },
    { lat: 48.5283006, lng: 18.2498501 },
    { lat: 48.5280848, lng: 18.2501202 },
    { lat: 48.5276691, lng: 18.2509773 },
    { lat: 48.5275618, lng: 18.2512917 },
    { lat: 48.5275451, lng: 18.251731 },
    { lat: 48.5269476, lng: 18.2527063 },
    { lat: 48.5255248, lng: 18.2553062 },
    { lat: 48.525102, lng: 18.2561033 },
    { lat: 48.524793, lng: 18.2568353 },
    { lat: 48.5236127, lng: 18.2585094 },
    { lat: 48.5233873, lng: 18.2587522 },
    { lat: 48.5228221, lng: 18.2591096 },
    { lat: 48.5210578, lng: 18.2601232 },
    { lat: 48.520953, lng: 18.2602417 },
    { lat: 48.5199823, lng: 18.2607118 },
    { lat: 48.5186971, lng: 18.2617137 },
    { lat: 48.5185497, lng: 18.2619029 },
    { lat: 48.5180045, lng: 18.2623708 },
    { lat: 48.5175256, lng: 18.2629151 },
    { lat: 48.5173697, lng: 18.2632278 },
    { lat: 48.5160592, lng: 18.2648184 },
    { lat: 48.5153457, lng: 18.2653816 },
    { lat: 48.5151251, lng: 18.2658654 },
    { lat: 48.5138339, lng: 18.2678145 },
    { lat: 48.5137559, lng: 18.2680093 },
    { lat: 48.5134486, lng: 18.2683583 },
    { lat: 48.5130021, lng: 18.2691727 },
    { lat: 48.5123352, lng: 18.2695295 },
    { lat: 48.5117507, lng: 18.2697443 },
    { lat: 48.5115018, lng: 18.2700369 },
    { lat: 48.5111671, lng: 18.2704305 },
    { lat: 48.510695, lng: 18.2707211 },
    { lat: 48.5106139, lng: 18.2711981 },
    { lat: 48.5101254, lng: 18.2726018 },
    { lat: 48.509674, lng: 18.2730922 },
    { lat: 48.5094959, lng: 18.2733935 },
    { lat: 48.5093212, lng: 18.2738712 },
    { lat: 48.5091698, lng: 18.2740428 },
    { lat: 48.5083735, lng: 18.2743468 },
    { lat: 48.5076539, lng: 18.2750448 },
    { lat: 48.5068029, lng: 18.2764806 },
    { lat: 48.5067254, lng: 18.2766639 },
    { lat: 48.5065959, lng: 18.2773407 },
    { lat: 48.5060433, lng: 18.2777633 },
    { lat: 48.5054459, lng: 18.278386 },
    { lat: 48.504874, lng: 18.2787844 },
    { lat: 48.5046057, lng: 18.2790458 },
    { lat: 48.5037458, lng: 18.2805054 },
    { lat: 48.5034114, lng: 18.2809143 },
    { lat: 48.5026796, lng: 18.2814202 },
    { lat: 48.5022584, lng: 18.2821648 },
    { lat: 48.5019977, lng: 18.2824194 },
    { lat: 48.5016843, lng: 18.2829399 },
    { lat: 48.501516, lng: 18.2830765 },
    { lat: 48.5009384, lng: 18.2838281 },
    { lat: 48.5004712, lng: 18.2841439 },
    { lat: 48.5002776, lng: 18.2843514 },
    { lat: 48.5000411, lng: 18.284753 },
    { lat: 48.4995172, lng: 18.2863914 },
    { lat: 48.4991154, lng: 18.2864891 },
    { lat: 48.4987615, lng: 18.2867099 },
    { lat: 48.4985722, lng: 18.2870936 },
    { lat: 48.4982293, lng: 18.2872908 },
    { lat: 48.4981005, lng: 18.2873092 },
    { lat: 48.4978705, lng: 18.2871934 },
    { lat: 48.4973514, lng: 18.2871337 },
    { lat: 48.497066, lng: 18.2871951 },
    { lat: 48.4963622, lng: 18.2876538 },
    { lat: 48.4958908, lng: 18.2887865 },
    { lat: 48.4955998, lng: 18.2891652 },
    { lat: 48.4953184, lng: 18.2900488 },
    { lat: 48.4951595, lng: 18.2903678 },
    { lat: 48.4951849, lng: 18.2906555 },
    { lat: 48.4951199, lng: 18.291079 },
    { lat: 48.4945188, lng: 18.292154 },
    { lat: 48.493841, lng: 18.292836 },
    { lat: 48.494304, lng: 18.293678 },
    { lat: 48.494804, lng: 18.295824 },
    { lat: 48.494859, lng: 18.296882 },
    { lat: 48.494836, lng: 18.297781 },
    { lat: 48.495061, lng: 18.298654 },
    { lat: 48.49545, lng: 18.299486 },
    { lat: 48.495381, lng: 18.301608 },
    { lat: 48.494687, lng: 18.302807 },
    { lat: 48.494732, lng: 18.303319 },
    { lat: 48.494919, lng: 18.305055 },
    { lat: 48.49493, lng: 18.306448 },
    { lat: 48.495296, lng: 18.308286 },
    { lat: 48.495598, lng: 18.309492 },
    { lat: 48.496545, lng: 18.310391 },
    { lat: 48.496886, lng: 18.311372 },
  ],
  Lužany: [
    { lat: 48.5180102, lng: 18.031237 },
    { lat: 48.5180454, lng: 18.0311765 },
    { lat: 48.5178821, lng: 18.0309993 },
    { lat: 48.5177444, lng: 18.0305379 },
    { lat: 48.5201745, lng: 18.028198 },
    { lat: 48.5201464, lng: 18.0280871 },
    { lat: 48.5208726, lng: 18.0268564 },
    { lat: 48.520673, lng: 18.0264965 },
    { lat: 48.5205047, lng: 18.026036 },
    { lat: 48.5206269, lng: 18.0251854 },
    { lat: 48.5207469, lng: 18.0247353 },
    { lat: 48.5213897, lng: 18.023677 },
    { lat: 48.5213136, lng: 18.0235384 },
    { lat: 48.5226419, lng: 18.0210794 },
    { lat: 48.5232287, lng: 18.0196885 },
    { lat: 48.5232246, lng: 18.0191842 },
    { lat: 48.5234228, lng: 18.0183393 },
    { lat: 48.5234306, lng: 18.0177294 },
    { lat: 48.5235902, lng: 18.0172013 },
    { lat: 48.5237118, lng: 18.0170425 },
    { lat: 48.5237387, lng: 18.0168317 },
    { lat: 48.5239568, lng: 18.0166848 },
    { lat: 48.5245258, lng: 18.0160625 },
    { lat: 48.5275552, lng: 18.0169539 },
    { lat: 48.5276568, lng: 18.016995 },
    { lat: 48.5266256, lng: 18.0148517 },
    { lat: 48.5264383, lng: 18.0150225 },
    { lat: 48.524484, lng: 18.010964 },
    { lat: 48.5256228, lng: 18.0109431 },
    { lat: 48.5258922, lng: 18.0108837 },
    { lat: 48.5258304, lng: 18.0106958 },
    { lat: 48.5253571, lng: 18.010197 },
    { lat: 48.5257112, lng: 18.0089963 },
    { lat: 48.5260039, lng: 18.0086193 },
    { lat: 48.5264092, lng: 18.008224 },
    { lat: 48.5266351, lng: 18.0082648 },
    { lat: 48.5272204, lng: 18.008104 },
    { lat: 48.5265859, lng: 18.0053477 },
    { lat: 48.5256139, lng: 18.0015451 },
    { lat: 48.5259726, lng: 18.0009915 },
    { lat: 48.5255454, lng: 17.9956172 },
    { lat: 48.5255408, lng: 17.9954999 },
    { lat: 48.5203126, lng: 17.9967316 },
    { lat: 48.520239, lng: 17.9928023 },
    { lat: 48.5202444, lng: 17.992782 },
    { lat: 48.5187988, lng: 17.9930306 },
    { lat: 48.5127212, lng: 17.9944001 },
    { lat: 48.5126009, lng: 17.9942191 },
    { lat: 48.5111186, lng: 17.9953686 },
    { lat: 48.5098218, lng: 17.9962835 },
    { lat: 48.5094717, lng: 17.9965995 },
    { lat: 48.5092989, lng: 17.9968243 },
    { lat: 48.509705, lng: 17.9977132 },
    { lat: 48.5103971, lng: 17.999709 },
    { lat: 48.5105407, lng: 18.0008875 },
    { lat: 48.5106841, lng: 18.0030353 },
    { lat: 48.5111433, lng: 18.0067517 },
    { lat: 48.5108833, lng: 18.006839 },
    { lat: 48.511088, lng: 18.0088978 },
    { lat: 48.5112223, lng: 18.0107756 },
    { lat: 48.5111829, lng: 18.0132851 },
    { lat: 48.5113173, lng: 18.016139 },
    { lat: 48.5114332, lng: 18.0207951 },
    { lat: 48.5115775, lng: 18.027225 },
    { lat: 48.51167, lng: 18.0301002 },
    { lat: 48.5177172, lng: 18.0315487 },
    { lat: 48.5179773, lng: 18.031288 },
    { lat: 48.5180102, lng: 18.031237 },
  ],
  HornéChlebany: [
    { lat: 48.598936, lng: 18.243997 },
    { lat: 48.59897, lng: 18.24403 },
    { lat: 48.5990944, lng: 18.2438124 },
    { lat: 48.6020508, lng: 18.2385968 },
    { lat: 48.6021806, lng: 18.2383977 },
    { lat: 48.6028871, lng: 18.238163 },
    { lat: 48.6031491, lng: 18.2377959 },
    { lat: 48.6030874, lng: 18.2377012 },
    { lat: 48.6035059, lng: 18.2369707 },
    { lat: 48.6037197, lng: 18.2364673 },
    { lat: 48.6041454, lng: 18.2358253 },
    { lat: 48.6043238, lng: 18.2352954 },
    { lat: 48.6043396, lng: 18.2350598 },
    { lat: 48.6043988, lng: 18.235066 },
    { lat: 48.6043982, lng: 18.235019 },
    { lat: 48.6035038, lng: 18.2343359 },
    { lat: 48.6110845, lng: 18.2223059 },
    { lat: 48.6193832, lng: 18.2092804 },
    { lat: 48.6219294, lng: 18.2106048 },
    { lat: 48.6220511, lng: 18.2102868 },
    { lat: 48.6222936, lng: 18.2103054 },
    { lat: 48.6223765, lng: 18.2100406 },
    { lat: 48.6222749, lng: 18.2098452 },
    { lat: 48.6224673, lng: 18.2093624 },
    { lat: 48.6225778, lng: 18.2088137 },
    { lat: 48.6227887, lng: 18.2088499 },
    { lat: 48.6228476, lng: 18.208579 },
    { lat: 48.6228836, lng: 18.2082425 },
    { lat: 48.622845, lng: 18.207806 },
    { lat: 48.6229399, lng: 18.2074583 },
    { lat: 48.622787, lng: 18.2073696 },
    { lat: 48.6227948, lng: 18.2072512 },
    { lat: 48.6228744, lng: 18.2066911 },
    { lat: 48.6230749, lng: 18.2060371 },
    { lat: 48.6229889, lng: 18.2059367 },
    { lat: 48.621618, lng: 18.2044511 },
    { lat: 48.6200849, lng: 18.2034193 },
    { lat: 48.6186916, lng: 18.201385 },
    { lat: 48.6186252, lng: 18.2010367 },
    { lat: 48.6179366, lng: 18.1998017 },
    { lat: 48.6166641, lng: 18.1997263 },
    { lat: 48.6162825, lng: 18.1986284 },
    { lat: 48.6155987, lng: 18.1961853 },
    { lat: 48.6158168, lng: 18.1923322 },
    { lat: 48.6154049, lng: 18.1928819 },
    { lat: 48.6139871, lng: 18.1946542 },
    { lat: 48.6137508, lng: 18.194529 },
    { lat: 48.6136526, lng: 18.1944085 },
    { lat: 48.6132199, lng: 18.1944585 },
    { lat: 48.6128179, lng: 18.1941996 },
    { lat: 48.6125744, lng: 18.1947921 },
    { lat: 48.6124372, lng: 18.1948191 },
    { lat: 48.6121111, lng: 18.1953119 },
    { lat: 48.6117609, lng: 18.1955568 },
    { lat: 48.6114206, lng: 18.1964829 },
    { lat: 48.6112542, lng: 18.1966734 },
    { lat: 48.6112667, lng: 18.1972431 },
    { lat: 48.6113452, lng: 18.1974674 },
    { lat: 48.6116778, lng: 18.1979901 },
    { lat: 48.6117163, lng: 18.1985021 },
    { lat: 48.6119118, lng: 18.1990349 },
    { lat: 48.6118076, lng: 18.1996726 },
    { lat: 48.6118154, lng: 18.2000046 },
    { lat: 48.6111334, lng: 18.2001144 },
    { lat: 48.610612, lng: 18.1998978 },
    { lat: 48.6090012, lng: 18.2022605 },
    { lat: 48.6091217, lng: 18.2025083 },
    { lat: 48.6097477, lng: 18.2032863 },
    { lat: 48.6102808, lng: 18.2037674 },
    { lat: 48.6105266, lng: 18.203901 },
    { lat: 48.61096, lng: 18.2044886 },
    { lat: 48.6108937, lng: 18.2050404 },
    { lat: 48.6107824, lng: 18.2054033 },
    { lat: 48.6102153, lng: 18.2065263 },
    { lat: 48.6094819, lng: 18.2070955 },
    { lat: 48.6092619, lng: 18.2071176 },
    { lat: 48.6091039, lng: 18.2072741 },
    { lat: 48.6088862, lng: 18.2076685 },
    { lat: 48.6092812, lng: 18.2082953 },
    { lat: 48.6093171, lng: 18.2083687 },
    { lat: 48.6087344, lng: 18.2092262 },
    { lat: 48.6041529, lng: 18.2167955 },
    { lat: 48.590603, lng: 18.239564 },
    { lat: 48.591932, lng: 18.240671 },
    { lat: 48.591973, lng: 18.240706 },
    { lat: 48.592251, lng: 18.240941 },
    { lat: 48.592282, lng: 18.240967 },
    { lat: 48.592369, lng: 18.24104 },
    { lat: 48.592423, lng: 18.241085 },
    { lat: 48.592541, lng: 18.241186 },
    { lat: 48.592779, lng: 18.241398 },
    { lat: 48.592938, lng: 18.24152 },
    { lat: 48.593026, lng: 18.241595 },
    { lat: 48.593271, lng: 18.241799 },
    { lat: 48.593434, lng: 18.241939 },
    { lat: 48.595194, lng: 18.243434 },
    { lat: 48.597811, lng: 18.243569 },
    { lat: 48.598942, lng: 18.243991 },
    { lat: 48.598936, lng: 18.243997 },
  ],
  Topoľčany: [
    { lat: 48.6229645, lng: 18.1594642 },
    { lat: 48.6221213, lng: 18.1567137 },
    { lat: 48.6217294, lng: 18.1551553 },
    { lat: 48.6214203, lng: 18.1545723 },
    { lat: 48.6209907, lng: 18.1540358 },
    { lat: 48.6207495, lng: 18.1527001 },
    { lat: 48.6202539, lng: 18.1529646 },
    { lat: 48.6198792, lng: 18.1517618 },
    { lat: 48.6196876, lng: 18.1509296 },
    { lat: 48.61967, lng: 18.1505489 },
    { lat: 48.6197953, lng: 18.1501004 },
    { lat: 48.6198479, lng: 18.1497243 },
    { lat: 48.6198141, lng: 18.1494555 },
    { lat: 48.6194754, lng: 18.1486553 },
    { lat: 48.6193664, lng: 18.1485667 },
    { lat: 48.6191949, lng: 18.1480607 },
    { lat: 48.6191769, lng: 18.1475955 },
    { lat: 48.6189082, lng: 18.1471263 },
    { lat: 48.6197078, lng: 18.1467296 },
    { lat: 48.6193381, lng: 18.1450097 },
    { lat: 48.6192513, lng: 18.1429724 },
    { lat: 48.6188721, lng: 18.1407199 },
    { lat: 48.6179192, lng: 18.1370271 },
    { lat: 48.6178197, lng: 18.1368281 },
    { lat: 48.6176716, lng: 18.136823 },
    { lat: 48.6163754, lng: 18.1371794 },
    { lat: 48.6150457, lng: 18.1377202 },
    { lat: 48.6147731, lng: 18.1378643 },
    { lat: 48.6147901, lng: 18.1381153 },
    { lat: 48.6148882, lng: 18.1381159 },
    { lat: 48.6104134, lng: 18.1399747 },
    { lat: 48.5967787, lng: 18.1405168 },
    { lat: 48.5970154, lng: 18.1410531 },
    { lat: 48.5993555, lng: 18.1482876 },
    { lat: 48.5992963, lng: 18.1484894 },
    { lat: 48.5984644, lng: 18.1492431 },
    { lat: 48.5982268, lng: 18.149369 },
    { lat: 48.5964304, lng: 18.150808 },
    { lat: 48.5929502, lng: 18.1536663 },
    { lat: 48.5927051, lng: 18.1538972 },
    { lat: 48.5924578, lng: 18.1542835 },
    { lat: 48.5893491, lng: 18.1569515 },
    { lat: 48.5870784, lng: 18.1590862 },
    { lat: 48.5858735, lng: 18.16018 },
    { lat: 48.5857366, lng: 18.1603118 },
    { lat: 48.5871244, lng: 18.1643555 },
    { lat: 48.5832907, lng: 18.1672877 },
    { lat: 48.5832553, lng: 18.1673205 },
    { lat: 48.5828168, lng: 18.1658314 },
    { lat: 48.5826134, lng: 18.1652028 },
    { lat: 48.5808815, lng: 18.1664912 },
    { lat: 48.5799714, lng: 18.1672422 },
    { lat: 48.5791798, lng: 18.1677185 },
    { lat: 48.5776071, lng: 18.1695751 },
    { lat: 48.5765298, lng: 18.167851 },
    { lat: 48.573432, lng: 18.163817 },
    { lat: 48.5736625, lng: 18.1633525 },
    { lat: 48.5741432, lng: 18.1623838 },
    { lat: 48.5699661, lng: 18.157705 },
    { lat: 48.5692148, lng: 18.1566628 },
    { lat: 48.5671055, lng: 18.1525394 },
    { lat: 48.5663146, lng: 18.1512221 },
    { lat: 48.566377, lng: 18.1506841 },
    { lat: 48.5663737, lng: 18.1502983 },
    { lat: 48.5661788, lng: 18.1494685 },
    { lat: 48.5630952, lng: 18.1426312 },
    { lat: 48.5625608, lng: 18.1413384 },
    { lat: 48.5622928, lng: 18.1401842 },
    { lat: 48.5624245, lng: 18.140103 },
    { lat: 48.5621199, lng: 18.1389619 },
    { lat: 48.5617727, lng: 18.1384812 },
    { lat: 48.5609294, lng: 18.1375477 },
    { lat: 48.5607949, lng: 18.1376827 },
    { lat: 48.5603632, lng: 18.1372613 },
    { lat: 48.5602737, lng: 18.1372767 },
    { lat: 48.5516086, lng: 18.1399228 },
    { lat: 48.5515313, lng: 18.1395861 },
    { lat: 48.5507506, lng: 18.1398606 },
    { lat: 48.5507911, lng: 18.1402064 },
    { lat: 48.5474705, lng: 18.1412432 },
    { lat: 48.5461326, lng: 18.1464286 },
    { lat: 48.5464473, lng: 18.1466011 },
    { lat: 48.5463694, lng: 18.1469098 },
    { lat: 48.5460582, lng: 18.1467027 },
    { lat: 48.5455007, lng: 18.1491272 },
    { lat: 48.5452655, lng: 18.1504946 },
    { lat: 48.5446244, lng: 18.1497739 },
    { lat: 48.5429901, lng: 18.1531099 },
    { lat: 48.541733, lng: 18.1516168 },
    { lat: 48.5406651, lng: 18.1536504 },
    { lat: 48.5407121, lng: 18.1536827 },
    { lat: 48.5400014, lng: 18.1550837 },
    { lat: 48.5387844, lng: 18.1573651 },
    { lat: 48.5389462, lng: 18.1578017 },
    { lat: 48.539131, lng: 18.1589041 },
    { lat: 48.5394359, lng: 18.1598418 },
    { lat: 48.5393522, lng: 18.1618776 },
    { lat: 48.5392723, lng: 18.1622126 },
    { lat: 48.5361578, lng: 18.1669412 },
    { lat: 48.5364266, lng: 18.1671191 },
    { lat: 48.5367893, lng: 18.1676323 },
    { lat: 48.5374496, lng: 18.1681785 },
    { lat: 48.5378409, lng: 18.168679 },
    { lat: 48.5383962, lng: 18.1692277 },
    { lat: 48.5387792, lng: 18.1699721 },
    { lat: 48.538856, lng: 18.1706155 },
    { lat: 48.5388193, lng: 18.170725 },
    { lat: 48.5395242, lng: 18.1715931 },
    { lat: 48.5398725, lng: 18.1718571 },
    { lat: 48.5405045, lng: 18.172212 },
    { lat: 48.5410574, lng: 18.172384 },
    { lat: 48.5410226, lng: 18.1726813 },
    { lat: 48.5416129, lng: 18.1728362 },
    { lat: 48.5420168, lng: 18.1733027 },
    { lat: 48.5422003, lng: 18.1732936 },
    { lat: 48.5423777, lng: 18.1743514 },
    { lat: 48.5424241, lng: 18.1749151 },
    { lat: 48.5423645, lng: 18.1752912 },
    { lat: 48.5420698, lng: 18.1758737 },
    { lat: 48.5417636, lng: 18.1761757 },
    { lat: 48.5410346, lng: 18.1764908 },
    { lat: 48.5403987, lng: 18.1765777 },
    { lat: 48.5400886, lng: 18.1765428 },
    { lat: 48.5399941, lng: 18.1765499 },
    { lat: 48.5394275, lng: 18.1764093 },
    { lat: 48.5391599, lng: 18.1765242 },
    { lat: 48.5385148, lng: 18.1780105 },
    { lat: 48.5381445, lng: 18.1795661 },
    { lat: 48.5380092, lng: 18.1799781 },
    { lat: 48.537728, lng: 18.180557 },
    { lat: 48.5379022, lng: 18.1806413 },
    { lat: 48.5384123, lng: 18.1810921 },
    { lat: 48.5386131, lng: 18.1809539 },
    { lat: 48.5387996, lng: 18.1809205 },
    { lat: 48.5389805, lng: 18.1810456 },
    { lat: 48.5391857, lng: 18.1810012 },
    { lat: 48.5397861, lng: 18.1811795 },
    { lat: 48.5408228, lng: 18.1807852 },
    { lat: 48.5414249, lng: 18.1803447 },
    { lat: 48.5417865, lng: 18.1803328 },
    { lat: 48.5421524, lng: 18.1805138 },
    { lat: 48.5423668, lng: 18.1807488 },
    { lat: 48.5424906, lng: 18.1812496 },
    { lat: 48.5424426, lng: 18.181413 },
    { lat: 48.5420467, lng: 18.1821195 },
    { lat: 48.5429366, lng: 18.1832808 },
    { lat: 48.5431708, lng: 18.1836624 },
    { lat: 48.5432289, lng: 18.1838866 },
    { lat: 48.5431867, lng: 18.1843868 },
    { lat: 48.5428828, lng: 18.1845552 },
    { lat: 48.542813, lng: 18.1848112 },
    { lat: 48.542757, lng: 18.1854247 },
    { lat: 48.5427649, lng: 18.1860489 },
    { lat: 48.5429574, lng: 18.1862943 },
    { lat: 48.5434201, lng: 18.1864726 },
    { lat: 48.5439867, lng: 18.1869232 },
    { lat: 48.5442357, lng: 18.1873229 },
    { lat: 48.5444817, lng: 18.1881421 },
    { lat: 48.5444439, lng: 18.1882899 },
    { lat: 48.5436853, lng: 18.1893783 },
    { lat: 48.5446782, lng: 18.1899625 },
    { lat: 48.5451748, lng: 18.1901599 },
    { lat: 48.5457508, lng: 18.1899711 },
    { lat: 48.5461448, lng: 18.190012 },
    { lat: 48.5464445, lng: 18.1898712 },
    { lat: 48.5466578, lng: 18.1895573 },
    { lat: 48.5466678, lng: 18.1892425 },
    { lat: 48.5471652, lng: 18.1892208 },
    { lat: 48.5480844, lng: 18.1887213 },
    { lat: 48.5482964, lng: 18.1888185 },
    { lat: 48.5484481, lng: 18.1890415 },
    { lat: 48.5485336, lng: 18.1897184 },
    { lat: 48.5486294, lng: 18.189797 },
    { lat: 48.5490621, lng: 18.1897847 },
    { lat: 48.5492464, lng: 18.1901053 },
    { lat: 48.5492124, lng: 18.1905479 },
    { lat: 48.548955, lng: 18.1912697 },
    { lat: 48.5491086, lng: 18.1917432 },
    { lat: 48.5491759, lng: 18.1922818 },
    { lat: 48.5492666, lng: 18.192484 },
    { lat: 48.5494224, lng: 18.1926594 },
    { lat: 48.5497207, lng: 18.1935467 },
    { lat: 48.5500023, lng: 18.1933606 },
    { lat: 48.5502097, lng: 18.1934969 },
    { lat: 48.5502827, lng: 18.193742 },
    { lat: 48.550426, lng: 18.194719 },
    { lat: 48.5505112, lng: 18.1949625 },
    { lat: 48.5513044, lng: 18.1956073 },
    { lat: 48.5516214, lng: 18.1959575 },
    { lat: 48.5517527, lng: 18.1962167 },
    { lat: 48.5519285, lng: 18.1974638 },
    { lat: 48.5518758, lng: 18.1977261 },
    { lat: 48.5517864, lng: 18.1978977 },
    { lat: 48.551783, lng: 18.1980324 },
    { lat: 48.5518666, lng: 18.1983089 },
    { lat: 48.5525896, lng: 18.1980069 },
    { lat: 48.552909, lng: 18.1993018 },
    { lat: 48.5529441, lng: 18.1998515 },
    { lat: 48.553226, lng: 18.1998284 },
    { lat: 48.5533364, lng: 18.1998802 },
    { lat: 48.5535268, lng: 18.2002514 },
    { lat: 48.5538071, lng: 18.20051 },
    { lat: 48.5544689, lng: 18.2005387 },
    { lat: 48.5548741, lng: 18.2006965 },
    { lat: 48.5553818, lng: 18.2011836 },
    { lat: 48.5548492, lng: 18.2023016 },
    { lat: 48.5539017, lng: 18.2028552 },
    { lat: 48.5532916, lng: 18.2030191 },
    { lat: 48.552909, lng: 18.2035632 },
    { lat: 48.5528349, lng: 18.2038461 },
    { lat: 48.5528756, lng: 18.2039861 },
    { lat: 48.5535024, lng: 18.2047557 },
    { lat: 48.5537312, lng: 18.2051792 },
    { lat: 48.5537844, lng: 18.2054019 },
    { lat: 48.553689, lng: 18.2057026 },
    { lat: 48.5537402, lng: 18.2059267 },
    { lat: 48.5536767, lng: 18.206174 },
    { lat: 48.5534806, lng: 18.2065508 },
    { lat: 48.552846, lng: 18.207109 },
    { lat: 48.5525278, lng: 18.2075217 },
    { lat: 48.5523778, lng: 18.2076393 },
    { lat: 48.5520366, lng: 18.2077341 },
    { lat: 48.5516535, lng: 18.2077048 },
    { lat: 48.5509479, lng: 18.2077732 },
    { lat: 48.5506188, lng: 18.2081528 },
    { lat: 48.550255, lng: 18.2083589 },
    { lat: 48.5491387, lng: 18.208023 },
    { lat: 48.5484111, lng: 18.2081053 },
    { lat: 48.5481202, lng: 18.208247 },
    { lat: 48.5477266, lng: 18.2082899 },
    { lat: 48.5475884, lng: 18.2084939 },
    { lat: 48.5474193, lng: 18.2085595 },
    { lat: 48.546999, lng: 18.2089179 },
    { lat: 48.5467663, lng: 18.209443 },
    { lat: 48.5459766, lng: 18.2107211 },
    { lat: 48.545728, lng: 18.211536 },
    { lat: 48.5453637, lng: 18.2117178 },
    { lat: 48.5446068, lng: 18.2132294 },
    { lat: 48.5451958, lng: 18.2138971 },
    { lat: 48.5460116, lng: 18.2149907 },
    { lat: 48.5465049, lng: 18.2157629 },
    { lat: 48.5465317, lng: 18.2157409 },
    { lat: 48.5466471, lng: 18.2156393 },
    { lat: 48.5491642, lng: 18.2149101 },
    { lat: 48.5496481, lng: 18.2150228 },
    { lat: 48.5511788, lng: 18.2156246 },
    { lat: 48.5552186, lng: 18.2157786 },
    { lat: 48.5556648, lng: 18.2156719 },
    { lat: 48.5575289, lng: 18.2141214 },
    { lat: 48.5575776, lng: 18.213989 },
    { lat: 48.5576021, lng: 18.2142753 },
    { lat: 48.557705, lng: 18.2142391 },
    { lat: 48.5604448, lng: 18.2149444 },
    { lat: 48.5608829, lng: 18.2147967 },
    { lat: 48.5613288, lng: 18.2144043 },
    { lat: 48.5614017, lng: 18.2137318 },
    { lat: 48.5615585, lng: 18.2137704 },
    { lat: 48.5616609, lng: 18.2128259 },
    { lat: 48.5618145, lng: 18.2128656 },
    { lat: 48.5619987, lng: 18.2114804 },
    { lat: 48.5623176, lng: 18.2102349 },
    { lat: 48.5630479, lng: 18.2082014 },
    { lat: 48.5633078, lng: 18.2077228 },
    { lat: 48.5636658, lng: 18.2063757 },
    { lat: 48.5642642, lng: 18.2045991 },
    { lat: 48.5636838, lng: 18.203223 },
    { lat: 48.5617154, lng: 18.1999889 },
    { lat: 48.5608785, lng: 18.1984076 },
    { lat: 48.56039, lng: 18.1974745 },
    { lat: 48.5606734, lng: 18.1960876 },
    { lat: 48.561251, lng: 18.1970443 },
    { lat: 48.5619861, lng: 18.197969 },
    { lat: 48.5623589, lng: 18.1983291 },
    { lat: 48.5670827, lng: 18.2018531 },
    { lat: 48.5674748, lng: 18.2022357 },
    { lat: 48.5679492, lng: 18.2029097 },
    { lat: 48.5688758, lng: 18.2023042 },
    { lat: 48.5695655, lng: 18.2021305 },
    { lat: 48.5698973, lng: 18.2019346 },
    { lat: 48.5699274, lng: 18.2019114 },
    { lat: 48.5701815, lng: 18.2017167 },
    { lat: 48.5705879, lng: 18.2008789 },
    { lat: 48.5720853, lng: 18.1956398 },
    { lat: 48.5723452, lng: 18.1952141 },
    { lat: 48.5722992, lng: 18.1952009 },
    { lat: 48.5723046, lng: 18.1947855 },
    { lat: 48.5722613, lng: 18.1948175 },
    { lat: 48.5720221, lng: 18.1951111 },
    { lat: 48.5718151, lng: 18.1956296 },
    { lat: 48.5710684, lng: 18.1950694 },
    { lat: 48.5710358, lng: 18.1949913 },
    { lat: 48.5725101, lng: 18.1923829 },
    { lat: 48.572776, lng: 18.1922614 },
    { lat: 48.5733479, lng: 18.1895578 },
    { lat: 48.5737701, lng: 18.1896414 },
    { lat: 48.5757716, lng: 18.1918411 },
    { lat: 48.5758925, lng: 18.1916286 },
    { lat: 48.5740581, lng: 18.1896816 },
    { lat: 48.574207, lng: 18.1892345 },
    { lat: 48.5740469, lng: 18.1890794 },
    { lat: 48.5814294, lng: 18.1817041 },
    { lat: 48.5833695, lng: 18.1796986 },
    { lat: 48.5834077, lng: 18.1797741 },
    { lat: 48.5845487, lng: 18.1828579 },
    { lat: 48.5845869, lng: 18.1832656 },
    { lat: 48.5848313, lng: 18.1845066 },
    { lat: 48.5847394, lng: 18.1845782 },
    { lat: 48.5849333, lng: 18.1851387 },
    { lat: 48.585111, lng: 18.1851695 },
    { lat: 48.5857368, lng: 18.1862354 },
    { lat: 48.5870673, lng: 18.1841677 },
    { lat: 48.5881553, lng: 18.1828008 },
    { lat: 48.5884689, lng: 18.1826423 },
    { lat: 48.5888564, lng: 18.1825921 },
    { lat: 48.5897491, lng: 18.1826128 },
    { lat: 48.5895832, lng: 18.1821651 },
    { lat: 48.5916337, lng: 18.1827145 },
    { lat: 48.5916256, lng: 18.1827665 },
    { lat: 48.591681, lng: 18.1827885 },
    { lat: 48.5916918, lng: 18.1827211 },
    { lat: 48.5923044, lng: 18.1828752 },
    { lat: 48.5927273, lng: 18.1828328 },
    { lat: 48.5975698, lng: 18.1811791 },
    { lat: 48.6092541, lng: 18.1778862 },
    { lat: 48.6115906, lng: 18.1764516 },
    { lat: 48.6116132, lng: 18.1766084 },
    { lat: 48.6116355, lng: 18.1770523 },
    { lat: 48.6119656, lng: 18.1771993 },
    { lat: 48.6125321, lng: 18.1782724 },
    { lat: 48.613299, lng: 18.1805673 },
    { lat: 48.6138998, lng: 18.1825463 },
    { lat: 48.614591, lng: 18.1844744 },
    { lat: 48.6157265, lng: 18.1827599 },
    { lat: 48.6159611, lng: 18.1825026 },
    { lat: 48.6171889, lng: 18.1814678 },
    { lat: 48.6179665, lng: 18.1809415 },
    { lat: 48.618606, lng: 18.1803912 },
    { lat: 48.6198505, lng: 18.179684 },
    { lat: 48.6208566, lng: 18.1787781 },
    { lat: 48.6210248, lng: 18.1787025 },
    { lat: 48.6216408, lng: 18.17864 },
    { lat: 48.6219393, lng: 18.178671 },
    { lat: 48.6218135, lng: 18.178389 },
    { lat: 48.6214697, lng: 18.1775953 },
    { lat: 48.6213358, lng: 18.1771888 },
    { lat: 48.621247, lng: 18.1766887 },
    { lat: 48.6211885, lng: 18.1759716 },
    { lat: 48.6211807, lng: 18.1743523 },
    { lat: 48.6210797, lng: 18.1736041 },
    { lat: 48.6208135, lng: 18.1725512 },
    { lat: 48.6204533, lng: 18.1723547 },
    { lat: 48.6198617, lng: 18.171608 },
    { lat: 48.6189322, lng: 18.17095 },
    { lat: 48.6204291, lng: 18.1697349 },
    { lat: 48.6227509, lng: 18.1684542 },
    { lat: 48.6246166, lng: 18.1670413 },
    { lat: 48.6245912, lng: 18.1669326 },
    { lat: 48.6246275, lng: 18.1664863 },
    { lat: 48.6243831, lng: 18.1655982 },
    { lat: 48.6246025, lng: 18.1655268 },
    { lat: 48.625341, lng: 18.1655194 },
    { lat: 48.6229645, lng: 18.1594642 },
  ],
  HajnáNováVes: [
    { lat: 48.5366422, lng: 18.0567991 },
    { lat: 48.5371857, lng: 18.0566385 },
    { lat: 48.5373486, lng: 18.0564892 },
    { lat: 48.5380368, lng: 18.0555673 },
    { lat: 48.5382806, lng: 18.055399 },
    { lat: 48.5372687, lng: 18.0519802 },
    { lat: 48.5372463, lng: 18.0515808 },
    { lat: 48.5381143, lng: 18.0507797 },
    { lat: 48.5380951, lng: 18.0502623 },
    { lat: 48.5390273, lng: 18.0502657 },
    { lat: 48.5380822, lng: 18.0469281 },
    { lat: 48.5381414, lng: 18.0455231 },
    { lat: 48.538222, lng: 18.0454396 },
    { lat: 48.5382944, lng: 18.0450392 },
    { lat: 48.538339, lng: 18.0430032 },
    { lat: 48.5428812, lng: 18.0418697 },
    { lat: 48.5443755, lng: 18.0422706 },
    { lat: 48.5455056, lng: 18.0422762 },
    { lat: 48.5462527, lng: 18.041932 },
    { lat: 48.547411, lng: 18.0411907 },
    { lat: 48.5492349, lng: 18.039569 },
    { lat: 48.5507921, lng: 18.0384389 },
    { lat: 48.5527317, lng: 18.0373223 },
    { lat: 48.5541121, lng: 18.0366532 },
    { lat: 48.554295, lng: 18.0365036 },
    { lat: 48.5545629, lng: 18.0362065 },
    { lat: 48.5553483, lng: 18.0350024 },
    { lat: 48.5553776, lng: 18.0347904 },
    { lat: 48.5560347, lng: 18.0334755 },
    { lat: 48.5553625, lng: 18.0322643 },
    { lat: 48.5541725, lng: 18.0297405 },
    { lat: 48.5538506, lng: 18.0284965 },
    { lat: 48.5544427, lng: 18.0275356 },
    { lat: 48.5547173, lng: 18.0271969 },
    { lat: 48.5548182, lng: 18.0270722 },
    { lat: 48.5515418, lng: 18.0179905 },
    { lat: 48.5513664, lng: 18.0174655 },
    { lat: 48.5465341, lng: 18.0214192 },
    { lat: 48.5452478, lng: 18.022435 },
    { lat: 48.5452128, lng: 18.0223585 },
    { lat: 48.5448222, lng: 18.0228872 },
    { lat: 48.5445885, lng: 18.0230985 },
    { lat: 48.5438858, lng: 18.0234096 },
    { lat: 48.5419793, lng: 18.0237884 },
    { lat: 48.5412388, lng: 18.0240521 },
    { lat: 48.5406307, lng: 18.0238628 },
    { lat: 48.5402455, lng: 18.0230794 },
    { lat: 48.5395118, lng: 18.0238964 },
    { lat: 48.5392467, lng: 18.0243192 },
    { lat: 48.5380582, lng: 18.0244707 },
    { lat: 48.5359646, lng: 18.0217075 },
    { lat: 48.5352327, lng: 18.0228992 },
    { lat: 48.5320826, lng: 18.016849 },
    { lat: 48.5303858, lng: 18.0174886 },
    { lat: 48.5302607, lng: 18.0174887 },
    { lat: 48.529296, lng: 18.0171146 },
    { lat: 48.5278521, lng: 18.0169947 },
    { lat: 48.5276568, lng: 18.016995 },
    { lat: 48.5275552, lng: 18.0169539 },
    { lat: 48.5245258, lng: 18.0160625 },
    { lat: 48.5239568, lng: 18.0166848 },
    { lat: 48.5237387, lng: 18.0168317 },
    { lat: 48.5237118, lng: 18.0170425 },
    { lat: 48.5235902, lng: 18.0172013 },
    { lat: 48.5234306, lng: 18.0177294 },
    { lat: 48.5234228, lng: 18.0183393 },
    { lat: 48.5232246, lng: 18.0191842 },
    { lat: 48.5232287, lng: 18.0196885 },
    { lat: 48.5226419, lng: 18.0210794 },
    { lat: 48.5213136, lng: 18.0235384 },
    { lat: 48.5213897, lng: 18.023677 },
    { lat: 48.5207469, lng: 18.0247353 },
    { lat: 48.5206269, lng: 18.0251854 },
    { lat: 48.5205047, lng: 18.026036 },
    { lat: 48.520673, lng: 18.0264965 },
    { lat: 48.5208726, lng: 18.0268564 },
    { lat: 48.5201464, lng: 18.0280871 },
    { lat: 48.5201745, lng: 18.028198 },
    { lat: 48.5177444, lng: 18.0305379 },
    { lat: 48.5178821, lng: 18.0309993 },
    { lat: 48.5180454, lng: 18.0311765 },
    { lat: 48.5180102, lng: 18.031237 },
    { lat: 48.5185216, lng: 18.031885 },
    { lat: 48.5189568, lng: 18.0325251 },
    { lat: 48.5190483, lng: 18.0327353 },
    { lat: 48.5190696, lng: 18.0327923 },
    { lat: 48.5222928, lng: 18.0322152 },
    { lat: 48.5284644, lng: 18.045331 },
    { lat: 48.5295052, lng: 18.0476072 },
    { lat: 48.5294781, lng: 18.0476251 },
    { lat: 48.5343814, lng: 18.0531416 },
    { lat: 48.5365941, lng: 18.0567689 },
    { lat: 48.5366422, lng: 18.0567991 },
  ],
  Tesáre: [
    { lat: 48.6388998, lng: 18.0394881 },
    { lat: 48.6362718, lng: 18.0344676 },
    { lat: 48.6358317, lng: 18.0333146 },
    { lat: 48.6353971, lng: 18.0321759 },
    { lat: 48.6349895, lng: 18.0303209 },
    { lat: 48.6348378, lng: 18.0286154 },
    { lat: 48.6345596, lng: 18.0276701 },
    { lat: 48.6341376, lng: 18.0265048 },
    { lat: 48.6341139, lng: 18.026488 },
    { lat: 48.6338226, lng: 18.0270217 },
    { lat: 48.633549, lng: 18.0272416 },
    { lat: 48.6331284, lng: 18.0277439 },
    { lat: 48.6328348, lng: 18.0279704 },
    { lat: 48.6324034, lng: 18.028443 },
    { lat: 48.631837, lng: 18.0294391 },
    { lat: 48.6315842, lng: 18.030261 },
    { lat: 48.631481, lng: 18.0310905 },
    { lat: 48.6313693, lng: 18.0315897 },
    { lat: 48.6313657, lng: 18.0322745 },
    { lat: 48.6314456, lng: 18.0327858 },
    { lat: 48.6314432, lng: 18.0333174 },
    { lat: 48.6314027, lng: 18.0335134 },
    { lat: 48.6312532, lng: 18.033789 },
    { lat: 48.6307887, lng: 18.0341208 },
    { lat: 48.6300416, lng: 18.0343133 },
    { lat: 48.6291112, lng: 18.0348055 },
    { lat: 48.6282574, lng: 18.034662 },
    { lat: 48.6270072, lng: 18.0354897 },
    { lat: 48.6265709, lng: 18.0356308 },
    { lat: 48.6262622, lng: 18.0356576 },
    { lat: 48.6248155, lng: 18.0368467 },
    { lat: 48.6246514, lng: 18.0368962 },
    { lat: 48.6245512, lng: 18.0369971 },
    { lat: 48.6238295, lng: 18.0372354 },
    { lat: 48.6233032, lng: 18.0375187 },
    { lat: 48.6217355, lng: 18.0387028 },
    { lat: 48.6213186, lng: 18.0388673 },
    { lat: 48.6207247, lng: 18.0389392 },
    { lat: 48.6200734, lng: 18.0391473 },
    { lat: 48.6193422, lng: 18.039762 },
    { lat: 48.6192265, lng: 18.0399049 },
    { lat: 48.618859, lng: 18.0406388 },
    { lat: 48.6183767, lng: 18.0411862 },
    { lat: 48.6181062, lng: 18.0417486 },
    { lat: 48.6172268, lng: 18.0431779 },
    { lat: 48.6164304, lng: 18.0439344 },
    { lat: 48.616022, lng: 18.0444928 },
    { lat: 48.6156976, lng: 18.0450739 },
    { lat: 48.6153204, lng: 18.045549 },
    { lat: 48.6152397, lng: 18.0458088 },
    { lat: 48.6149711, lng: 18.0462497 },
    { lat: 48.6138633, lng: 18.0470396 },
    { lat: 48.6134025, lng: 18.0473026 },
    { lat: 48.6130043, lng: 18.0477294 },
    { lat: 48.6129127, lng: 18.0478968 },
    { lat: 48.6128533, lng: 18.0481923 },
    { lat: 48.6124843, lng: 18.0503746 },
    { lat: 48.6120472, lng: 18.0515977 },
    { lat: 48.61195, lng: 18.0529136 },
    { lat: 48.6116698, lng: 18.0538093 },
    { lat: 48.6111729, lng: 18.0547803 },
    { lat: 48.610803, lng: 18.0552828 },
    { lat: 48.6106426, lng: 18.0555868 },
    { lat: 48.6101172, lng: 18.0557688 },
    { lat: 48.609511, lng: 18.0563415 },
    { lat: 48.6091733, lng: 18.0565668 },
    { lat: 48.6083584, lng: 18.0569495 },
    { lat: 48.6078181, lng: 18.0571082 },
    { lat: 48.60694, lng: 18.0582578 },
    { lat: 48.6060155, lng: 18.059061 },
    { lat: 48.6048171, lng: 18.0605416 },
    { lat: 48.6039309, lng: 18.0612086 },
    { lat: 48.6032401, lng: 18.0616053 },
    { lat: 48.6024918, lng: 18.0622774 },
    { lat: 48.6021274, lng: 18.0625101 },
    { lat: 48.6017611, lng: 18.0628843 },
    { lat: 48.6010356, lng: 18.0633284 },
    { lat: 48.6002145, lng: 18.0640847 },
    { lat: 48.5995688, lng: 18.0645248 },
    { lat: 48.5987835, lng: 18.0653155 },
    { lat: 48.5981352, lng: 18.0655559 },
    { lat: 48.5978019, lng: 18.0650118 },
    { lat: 48.5975262, lng: 18.0650839 },
    { lat: 48.5972194, lng: 18.0651802 },
    { lat: 48.5954104, lng: 18.0660679 },
    { lat: 48.5946998, lng: 18.0665508 },
    { lat: 48.5942263, lng: 18.0670216 },
    { lat: 48.592932, lng: 18.0686952 },
    { lat: 48.5921926, lng: 18.0694201 },
    { lat: 48.5916174, lng: 18.0698699 },
    { lat: 48.5908521, lng: 18.0703483 },
    { lat: 48.5903755, lng: 18.0705724 },
    { lat: 48.5899434, lng: 18.070613 },
    { lat: 48.5896915, lng: 18.0707255 },
    { lat: 48.5892945, lng: 18.0711118 },
    { lat: 48.5884532, lng: 18.0721211 },
    { lat: 48.586991, lng: 18.0735146 },
    { lat: 48.5857942, lng: 18.0753862 },
    { lat: 48.5847657, lng: 18.0773959 },
    { lat: 48.5841206, lng: 18.0784234 },
    { lat: 48.5837972, lng: 18.0790462 },
    { lat: 48.583084, lng: 18.0809514 },
    { lat: 48.5826842, lng: 18.0824633 },
    { lat: 48.5825967, lng: 18.0829354 },
    { lat: 48.5824286, lng: 18.0847204 },
    { lat: 48.5823149, lng: 18.0852104 },
    { lat: 48.5821304, lng: 18.0855839 },
    { lat: 48.5816264, lng: 18.0863248 },
    { lat: 48.5819252, lng: 18.0868745 },
    { lat: 48.5823874, lng: 18.0880978 },
    { lat: 48.5832279, lng: 18.0908858 },
    { lat: 48.5839874, lng: 18.0925776 },
    { lat: 48.5856362, lng: 18.0917703 },
    { lat: 48.5862743, lng: 18.0913156 },
    { lat: 48.5876043, lng: 18.0909403 },
    { lat: 48.5876383, lng: 18.0908823 },
    { lat: 48.5883597, lng: 18.0924659 },
    { lat: 48.5887593, lng: 18.0931994 },
    { lat: 48.5884062, lng: 18.0936264 },
    { lat: 48.5889998, lng: 18.0950108 },
    { lat: 48.5909609, lng: 18.0935251 },
    { lat: 48.5917635, lng: 18.0927002 },
    { lat: 48.5920104, lng: 18.0923693 },
    { lat: 48.5926031, lng: 18.093315 },
    { lat: 48.5936026, lng: 18.09242 },
    { lat: 48.5942812, lng: 18.0916401 },
    { lat: 48.5949564, lng: 18.0915676 },
    { lat: 48.5963732, lng: 18.0906672 },
    { lat: 48.5964198, lng: 18.090789 },
    { lat: 48.5977319, lng: 18.0900516 },
    { lat: 48.5982872, lng: 18.0918431 },
    { lat: 48.5984173, lng: 18.0927153 },
    { lat: 48.5988593, lng: 18.0942285 },
    { lat: 48.598905, lng: 18.0960707 },
    { lat: 48.599336, lng: 18.0989763 },
    { lat: 48.5995055, lng: 18.1009886 },
    { lat: 48.599676, lng: 18.1023005 },
    { lat: 48.5997052, lng: 18.1031648 },
    { lat: 48.5994329, lng: 18.1058631 },
    { lat: 48.5995091, lng: 18.1060669 },
    { lat: 48.5999733, lng: 18.1074053 },
    { lat: 48.6002471, lng: 18.1071818 },
    { lat: 48.6006487, lng: 18.1071822 },
    { lat: 48.602016, lng: 18.1075578 },
    { lat: 48.6034743, lng: 18.1075794 },
    { lat: 48.6050771, lng: 18.1070233 },
    { lat: 48.6057759, lng: 18.1065859 },
    { lat: 48.6068362, lng: 18.1056294 },
    { lat: 48.6069875, lng: 18.1060936 },
    { lat: 48.6071466, lng: 18.1065788 },
    { lat: 48.607429, lng: 18.1063494 },
    { lat: 48.6114263, lng: 18.1026649 },
    { lat: 48.6116517, lng: 18.1024056 },
    { lat: 48.6116126, lng: 18.1023352 },
    { lat: 48.6130449, lng: 18.1003136 },
    { lat: 48.6149849, lng: 18.0973084 },
    { lat: 48.6147075, lng: 18.0970614 },
    { lat: 48.615657, lng: 18.0951759 },
    { lat: 48.6165897, lng: 18.0937616 },
    { lat: 48.6169082, lng: 18.0924718 },
    { lat: 48.6176067, lng: 18.0912759 },
    { lat: 48.6182673, lng: 18.089897 },
    { lat: 48.6185861, lng: 18.089384 },
    { lat: 48.619134, lng: 18.0886646 },
    { lat: 48.6183742, lng: 18.0867335 },
    { lat: 48.6190355, lng: 18.0829407 },
    { lat: 48.6194922, lng: 18.0827028 },
    { lat: 48.6194569, lng: 18.0823151 },
    { lat: 48.6193573, lng: 18.0819828 },
    { lat: 48.6192322, lng: 18.0817937 },
    { lat: 48.6190346, lng: 18.0808288 },
    { lat: 48.6198193, lng: 18.0802403 },
    { lat: 48.6197056, lng: 18.0787823 },
    { lat: 48.6199013, lng: 18.0786142 },
    { lat: 48.6200472, lng: 18.0778528 },
    { lat: 48.6202575, lng: 18.0776492 },
    { lat: 48.6198797, lng: 18.0764619 },
    { lat: 48.6213209, lng: 18.0748832 },
    { lat: 48.6212065, lng: 18.0744337 },
    { lat: 48.6214294, lng: 18.0740407 },
    { lat: 48.6209053, lng: 18.0725352 },
    { lat: 48.6199724, lng: 18.0715255 },
    { lat: 48.619918, lng: 18.0711557 },
    { lat: 48.6200141, lng: 18.0710808 },
    { lat: 48.6200021, lng: 18.0708602 },
    { lat: 48.620375, lng: 18.0706534 },
    { lat: 48.6204043, lng: 18.070423 },
    { lat: 48.6206537, lng: 18.0697697 },
    { lat: 48.6207324, lng: 18.0694388 },
    { lat: 48.6207294, lng: 18.0685028 },
    { lat: 48.6209931, lng: 18.0674802 },
    { lat: 48.6213116, lng: 18.0668679 },
    { lat: 48.6221981, lng: 18.065804 },
    { lat: 48.6234033, lng: 18.0640749 },
    { lat: 48.6248308, lng: 18.0616356 },
    { lat: 48.625076, lng: 18.0610837 },
    { lat: 48.6259202, lng: 18.0600085 },
    { lat: 48.6250366, lng: 18.0578065 },
    { lat: 48.6250979, lng: 18.0576581 },
    { lat: 48.6249612, lng: 18.0571606 },
    { lat: 48.6247081, lng: 18.0547579 },
    { lat: 48.6252124, lng: 18.0536069 },
    { lat: 48.6258043, lng: 18.0525775 },
    { lat: 48.6266103, lng: 18.0507438 },
    { lat: 48.627699, lng: 18.0491639 },
    { lat: 48.6276824, lng: 18.0490937 },
    { lat: 48.6277988, lng: 18.0489621 },
    { lat: 48.628424, lng: 18.0478493 },
    { lat: 48.6280797, lng: 18.0472972 },
    { lat: 48.6285784, lng: 18.0459163 },
    { lat: 48.6287208, lng: 18.0451897 },
    { lat: 48.6287217, lng: 18.0445748 },
    { lat: 48.6289446, lng: 18.0446216 },
    { lat: 48.6292519, lng: 18.0448965 },
    { lat: 48.62946, lng: 18.0449791 },
    { lat: 48.6298717, lng: 18.0441532 },
    { lat: 48.6300462, lng: 18.0440124 },
    { lat: 48.6303384, lng: 18.044695 },
    { lat: 48.6306348, lng: 18.0450527 },
    { lat: 48.631378, lng: 18.0452148 },
    { lat: 48.6322397, lng: 18.0444404 },
    { lat: 48.6328533, lng: 18.0441283 },
    { lat: 48.6336603, lng: 18.0440528 },
    { lat: 48.6338836, lng: 18.0441819 },
    { lat: 48.6348134, lng: 18.0442209 },
    { lat: 48.6350295, lng: 18.0436898 },
    { lat: 48.6354299, lng: 18.0431529 },
    { lat: 48.6356197, lng: 18.0427342 },
    { lat: 48.6358652, lng: 18.0423612 },
    { lat: 48.6367123, lng: 18.0423313 },
    { lat: 48.6368851, lng: 18.0424508 },
    { lat: 48.6371497, lng: 18.042402 },
    { lat: 48.6376352, lng: 18.0421167 },
    { lat: 48.637908, lng: 18.0418259 },
    { lat: 48.6380152, lng: 18.0416338 },
    { lat: 48.6379995, lng: 18.0415574 },
    { lat: 48.6381134, lng: 18.0413063 },
    { lat: 48.638228, lng: 18.0407834 },
    { lat: 48.6385331, lng: 18.0407514 },
    { lat: 48.6388158, lng: 18.0406252 },
    { lat: 48.6388886, lng: 18.0404638 },
    { lat: 48.6389454, lng: 18.0400312 },
    { lat: 48.6388998, lng: 18.0394881 },
  ],
  MaléRipňany: [
    { lat: 48.4918369, lng: 17.9991938 },
    { lat: 48.4906331, lng: 17.9980259 },
    { lat: 48.490418, lng: 17.9978813 },
    { lat: 48.4901349, lng: 17.9975088 },
    { lat: 48.4897203, lng: 17.9971196 },
    { lat: 48.4889779, lng: 17.996877 },
    { lat: 48.488397, lng: 17.996544 },
    { lat: 48.4873839, lng: 17.9956043 },
    { lat: 48.4874595, lng: 17.9954176 },
    { lat: 48.4872887, lng: 17.9952179 },
    { lat: 48.4872736, lng: 17.995079 },
    { lat: 48.4871546, lng: 17.9950513 },
    { lat: 48.4871673, lng: 17.9949233 },
    { lat: 48.4870977, lng: 17.9948935 },
    { lat: 48.4870234, lng: 17.9949523 },
    { lat: 48.4869443, lng: 17.9948555 },
    { lat: 48.4869108, lng: 17.9947693 },
    { lat: 48.4869463, lng: 17.9945877 },
    { lat: 48.4868792, lng: 17.994427 },
    { lat: 48.486657, lng: 17.9943929 },
    { lat: 48.4863367, lng: 17.9940544 },
    { lat: 48.4863695, lng: 17.9939617 },
    { lat: 48.4861013, lng: 17.9929657 },
    { lat: 48.4858692, lng: 17.9927622 },
    { lat: 48.4854734, lng: 17.9925423 },
    { lat: 48.4852155, lng: 17.9921751 },
    { lat: 48.4851236, lng: 17.9919594 },
    { lat: 48.4845209, lng: 17.9920339 },
    { lat: 48.4845162, lng: 17.9921253 },
    { lat: 48.4843984, lng: 17.9921561 },
    { lat: 48.4842943, lng: 17.9920766 },
    { lat: 48.4840966, lng: 17.9920937 },
    { lat: 48.4840337, lng: 17.9920413 },
    { lat: 48.4839138, lng: 17.9920938 },
    { lat: 48.4831703, lng: 17.9915679 },
    { lat: 48.4827935, lng: 17.9914003 },
    { lat: 48.4814664, lng: 17.9918416 },
    { lat: 48.4811195, lng: 17.9918137 },
    { lat: 48.4808586, lng: 17.9916599 },
    { lat: 48.4803976, lng: 17.9919164 },
    { lat: 48.48016, lng: 17.9919662 },
    { lat: 48.4797242, lng: 17.991734 },
    { lat: 48.4796709, lng: 17.9887969 },
    { lat: 48.4807286, lng: 17.9887805 },
    { lat: 48.4805752, lng: 17.9861895 },
    { lat: 48.4816527, lng: 17.984516 },
    { lat: 48.4812836, lng: 17.9841208 },
    { lat: 48.4820507, lng: 17.9838492 },
    { lat: 48.4835895, lng: 17.9838371 },
    { lat: 48.4838866, lng: 17.9838976 },
    { lat: 48.4839973, lng: 17.9827089 },
    { lat: 48.4848024, lng: 17.9684315 },
    { lat: 48.4845335, lng: 17.9682955 },
    { lat: 48.4842387, lng: 17.9681218 },
    { lat: 48.4870159, lng: 17.9507575 },
    { lat: 48.4865141, lng: 17.9506332 },
    { lat: 48.4864272, lng: 17.9505752 },
    { lat: 48.4864675, lng: 17.9502492 },
    { lat: 48.4861605, lng: 17.950215 },
    { lat: 48.4862461, lng: 17.9496071 },
    { lat: 48.4854662, lng: 17.9495144 },
    { lat: 48.4849659, lng: 17.9489465 },
    { lat: 48.4846683, lng: 17.9493078 },
    { lat: 48.4847785, lng: 17.9487005 },
    { lat: 48.4855663, lng: 17.9473083 },
    { lat: 48.4761128, lng: 17.9420222 },
    { lat: 48.475636, lng: 17.941758 },
    { lat: 48.475444, lng: 17.942236 },
    { lat: 48.475355, lng: 17.942489 },
    { lat: 48.475228, lng: 17.942837 },
    { lat: 48.475084, lng: 17.94326 },
    { lat: 48.474603, lng: 17.944431 },
    { lat: 48.474463, lng: 17.94484 },
    { lat: 48.474533, lng: 17.944938 },
    { lat: 48.47435, lng: 17.945394 },
    { lat: 48.47402, lng: 17.946161 },
    { lat: 48.473907, lng: 17.94643 },
    { lat: 48.473852, lng: 17.946556 },
    { lat: 48.473678, lng: 17.946941 },
    { lat: 48.473608, lng: 17.947086 },
    { lat: 48.47355, lng: 17.947191 },
    { lat: 48.473497, lng: 17.9473 },
    { lat: 48.473304, lng: 17.947668 },
    { lat: 48.473208, lng: 17.947861 },
    { lat: 48.473112, lng: 17.948056 },
    { lat: 48.473227, lng: 17.948183 },
    { lat: 48.47351, lng: 17.948487 },
    { lat: 48.473515, lng: 17.948495 },
    { lat: 48.473425, lng: 17.94863 },
    { lat: 48.473375, lng: 17.948704 },
    { lat: 48.473294, lng: 17.948844 },
    { lat: 48.473168, lng: 17.949065 },
    { lat: 48.473018, lng: 17.949307 },
    { lat: 48.472815, lng: 17.949635 },
    { lat: 48.472606, lng: 17.949977 },
    { lat: 48.472484, lng: 17.950153 },
    { lat: 48.472391, lng: 17.950288 },
    { lat: 48.472289, lng: 17.950433 },
    { lat: 48.471964, lng: 17.95083 },
    { lat: 48.47176, lng: 17.951082 },
    { lat: 48.471437, lng: 17.951486 },
    { lat: 48.4712, lng: 17.95173 },
    { lat: 48.471097, lng: 17.951838 },
    { lat: 48.47092, lng: 17.952093 },
    { lat: 48.470509, lng: 17.95279 },
    { lat: 48.47037, lng: 17.953029 },
    { lat: 48.470278, lng: 17.953177 },
    { lat: 48.47022, lng: 17.953244 },
    { lat: 48.470174, lng: 17.953316 },
    { lat: 48.469924, lng: 17.953672 },
    { lat: 48.469743, lng: 17.953945 },
    { lat: 48.469522, lng: 17.954292 },
    { lat: 48.469289, lng: 17.954651 },
    { lat: 48.469082, lng: 17.954969 },
    { lat: 48.468884, lng: 17.955303 },
    { lat: 48.46876, lng: 17.955509 },
    { lat: 48.468511, lng: 17.955919 },
    { lat: 48.468369, lng: 17.95616 },
    { lat: 48.468126, lng: 17.956585 },
    { lat: 48.467641, lng: 17.957442 },
    { lat: 48.467531, lng: 17.957645 },
    { lat: 48.467363, lng: 17.957954 },
    { lat: 48.46728, lng: 17.958122 },
    { lat: 48.467228, lng: 17.958173 },
    { lat: 48.467053, lng: 17.958401 },
    { lat: 48.466907, lng: 17.95868 },
    { lat: 48.466845, lng: 17.958807 },
    { lat: 48.466674, lng: 17.959137 },
    { lat: 48.466487, lng: 17.95948 },
    { lat: 48.466322, lng: 17.959747 },
    { lat: 48.466178, lng: 17.960061 },
    { lat: 48.466003, lng: 17.960351 },
    { lat: 48.465882, lng: 17.9606 },
    { lat: 48.46578, lng: 17.960822 },
    { lat: 48.465699, lng: 17.960775 },
    { lat: 48.465638, lng: 17.96079 },
    { lat: 48.465575, lng: 17.960846 },
    { lat: 48.465488, lng: 17.961071 },
    { lat: 48.465409, lng: 17.961276 },
    { lat: 48.465253, lng: 17.961687 },
    { lat: 48.46505, lng: 17.962361 },
    { lat: 48.465076, lng: 17.9626 },
    { lat: 48.465027, lng: 17.962977 },
    { lat: 48.464983, lng: 17.963308 },
    { lat: 48.46495, lng: 17.963589 },
    { lat: 48.464902, lng: 17.963917 },
    { lat: 48.464798, lng: 17.964665 },
    { lat: 48.4656705, lng: 17.9646968 },
    { lat: 48.4662553, lng: 17.9655139 },
    { lat: 48.4665765, lng: 17.9660854 },
    { lat: 48.469014, lng: 17.9689854 },
    { lat: 48.4683571, lng: 17.9706639 },
    { lat: 48.4681801, lng: 17.9713081 },
    { lat: 48.4681367, lng: 17.9721481 },
    { lat: 48.4680005, lng: 17.972662 },
    { lat: 48.4678958, lng: 17.9733281 },
    { lat: 48.4676235, lng: 17.9764206 },
    { lat: 48.4686274, lng: 17.9768468 },
    { lat: 48.4697256, lng: 17.9775641 },
    { lat: 48.4687704, lng: 17.9792551 },
    { lat: 48.4687937, lng: 17.9792893 },
    { lat: 48.4686508, lng: 17.9800694 },
    { lat: 48.4682925, lng: 17.9807623 },
    { lat: 48.4678758, lng: 17.9804931 },
    { lat: 48.4675426, lng: 17.982585 },
    { lat: 48.4665732, lng: 17.9821746 },
    { lat: 48.4655286, lng: 17.9819191 },
    { lat: 48.4653604, lng: 17.9837884 },
    { lat: 48.4650164, lng: 17.9862907 },
    { lat: 48.4650398, lng: 17.9865479 },
    { lat: 48.4649437, lng: 17.9888548 },
    { lat: 48.4650325, lng: 17.9907989 },
    { lat: 48.4651361, lng: 17.9918334 },
    { lat: 48.4658138, lng: 17.9974454 },
    { lat: 48.4657472, lng: 17.998925 },
    { lat: 48.4654892, lng: 18.0003144 },
    { lat: 48.4654555, lng: 18.0010601 },
    { lat: 48.4654711, lng: 18.0011306 },
    { lat: 48.4655952, lng: 18.0010744 },
    { lat: 48.4670386, lng: 18.0011247 },
    { lat: 48.4673061, lng: 18.0009738 },
    { lat: 48.4680359, lng: 18.0003811 },
    { lat: 48.4688419, lng: 18.0003049 },
    { lat: 48.4696193, lng: 18.0004705 },
    { lat: 48.4705846, lng: 18.0008925 },
    { lat: 48.4709729, lng: 18.0012343 },
    { lat: 48.4712546, lng: 18.0013419 },
    { lat: 48.4716528, lng: 18.0013363 },
    { lat: 48.4725786, lng: 18.0010942 },
    { lat: 48.4727655, lng: 18.0011266 },
    { lat: 48.4735041, lng: 18.0013811 },
    { lat: 48.4737286, lng: 18.0015838 },
    { lat: 48.4737956, lng: 18.0017242 },
    { lat: 48.4739086, lng: 18.0024112 },
    { lat: 48.4738995, lng: 18.0027702 },
    { lat: 48.4737007, lng: 18.0039797 },
    { lat: 48.4744108, lng: 18.0039816 },
    { lat: 48.4761446, lng: 18.0038335 },
    { lat: 48.4765712, lng: 18.0040631 },
    { lat: 48.4770886, lng: 18.0044858 },
    { lat: 48.4773853, lng: 18.0045405 },
    { lat: 48.4786051, lng: 18.0044581 },
    { lat: 48.4800978, lng: 18.0040895 },
    { lat: 48.4805323, lng: 18.0038784 },
    { lat: 48.4812224, lng: 18.0033575 },
    { lat: 48.4819241, lng: 18.0031072 },
    { lat: 48.4821981, lng: 18.0031204 },
    { lat: 48.4835843, lng: 18.0036734 },
    { lat: 48.4848904, lng: 18.0040358 },
    { lat: 48.4854088, lng: 18.0044811 },
    { lat: 48.485582, lng: 18.0047923 },
    { lat: 48.4858811, lng: 18.0051519 },
    { lat: 48.4868035, lng: 18.005935 },
    { lat: 48.4875956, lng: 18.0064051 },
    { lat: 48.4878815, lng: 18.0066943 },
    { lat: 48.4917888, lng: 17.9992561 },
    { lat: 48.4918369, lng: 17.9991938 },
  ],
  Krušovce: [
    { lat: 48.5833695, lng: 18.1796986 },
    { lat: 48.5814294, lng: 18.1817041 },
    { lat: 48.5740469, lng: 18.1890794 },
    { lat: 48.574207, lng: 18.1892345 },
    { lat: 48.5740581, lng: 18.1896816 },
    { lat: 48.5758925, lng: 18.1916286 },
    { lat: 48.5757716, lng: 18.1918411 },
    { lat: 48.5737701, lng: 18.1896414 },
    { lat: 48.5733479, lng: 18.1895578 },
    { lat: 48.572776, lng: 18.1922614 },
    { lat: 48.5725101, lng: 18.1923829 },
    { lat: 48.5710358, lng: 18.1949913 },
    { lat: 48.5710684, lng: 18.1950694 },
    { lat: 48.5718151, lng: 18.1956296 },
    { lat: 48.5720221, lng: 18.1951111 },
    { lat: 48.5722613, lng: 18.1948175 },
    { lat: 48.5723046, lng: 18.1947855 },
    { lat: 48.5722992, lng: 18.1952009 },
    { lat: 48.5723452, lng: 18.1952141 },
    { lat: 48.5720853, lng: 18.1956398 },
    { lat: 48.5705879, lng: 18.2008789 },
    { lat: 48.5701815, lng: 18.2017167 },
    { lat: 48.5699274, lng: 18.2019114 },
    { lat: 48.5698897, lng: 18.2022747 },
    { lat: 48.5699182, lng: 18.2025154 },
    { lat: 48.5706231, lng: 18.2035847 },
    { lat: 48.5711064, lng: 18.2045284 },
    { lat: 48.5717689, lng: 18.205295 },
    { lat: 48.5720721, lng: 18.2060139 },
    { lat: 48.5723628, lng: 18.2064576 },
    { lat: 48.5725204, lng: 18.2068015 },
    { lat: 48.5727843, lng: 18.2074664 },
    { lat: 48.5729942, lng: 18.207977 },
    { lat: 48.573287, lng: 18.2092674 },
    { lat: 48.5734784, lng: 18.2101111 },
    { lat: 48.573612, lng: 18.211453 },
    { lat: 48.573877, lng: 18.2125853 },
    { lat: 48.574156, lng: 18.214964 },
    { lat: 48.57474, lng: 18.214838 },
    { lat: 48.57487, lng: 18.214811 },
    { lat: 48.575391, lng: 18.214699 },
    { lat: 48.576334, lng: 18.214473 },
    { lat: 48.576791, lng: 18.214327 },
    { lat: 48.579143, lng: 18.217861 },
    { lat: 48.581871, lng: 18.221991 },
    { lat: 48.582259, lng: 18.222634 },
    { lat: 48.582297, lng: 18.222695 },
    { lat: 48.582435, lng: 18.222916 },
    { lat: 48.582447, lng: 18.222933 },
    { lat: 48.582793, lng: 18.223487 },
    { lat: 48.583672, lng: 18.22489 },
    { lat: 48.584373, lng: 18.22595 },
    { lat: 48.585005, lng: 18.226911 },
    { lat: 48.585701, lng: 18.227986 },
    { lat: 48.586333, lng: 18.228953 },
    { lat: 48.58733, lng: 18.230494 },
    { lat: 48.589453, lng: 18.233772 },
    { lat: 48.589371, lng: 18.233899 },
    { lat: 48.589333, lng: 18.23395 },
    { lat: 48.589328, lng: 18.233955 },
    { lat: 48.589322, lng: 18.233964 },
    { lat: 48.589304, lng: 18.233988 },
    { lat: 48.5893, lng: 18.233993 },
    { lat: 48.58929, lng: 18.234006 },
    { lat: 48.588957, lng: 18.234532 },
    { lat: 48.587835, lng: 18.237215 },
    { lat: 48.588522, lng: 18.237802 },
    { lat: 48.58904, lng: 18.238243 },
    { lat: 48.589184, lng: 18.238365 },
    { lat: 48.589266, lng: 18.238435 },
    { lat: 48.58933, lng: 18.23849 },
    { lat: 48.589395, lng: 18.238546 },
    { lat: 48.589443, lng: 18.238586 },
    { lat: 48.590542, lng: 18.239508 },
    { lat: 48.590603, lng: 18.239564 },
    { lat: 48.6041529, lng: 18.2167955 },
    { lat: 48.6087344, lng: 18.2092262 },
    { lat: 48.6093171, lng: 18.2083687 },
    { lat: 48.6092812, lng: 18.2082953 },
    { lat: 48.6088862, lng: 18.2076685 },
    { lat: 48.6091039, lng: 18.2072741 },
    { lat: 48.6092619, lng: 18.2071176 },
    { lat: 48.6094819, lng: 18.2070955 },
    { lat: 48.6102153, lng: 18.2065263 },
    { lat: 48.6107824, lng: 18.2054033 },
    { lat: 48.6108937, lng: 18.2050404 },
    { lat: 48.61096, lng: 18.2044886 },
    { lat: 48.6105266, lng: 18.203901 },
    { lat: 48.6102808, lng: 18.2037674 },
    { lat: 48.6097477, lng: 18.2032863 },
    { lat: 48.6091217, lng: 18.2025083 },
    { lat: 48.6090012, lng: 18.2022605 },
    { lat: 48.610612, lng: 18.1998978 },
    { lat: 48.6111334, lng: 18.2001144 },
    { lat: 48.6118154, lng: 18.2000046 },
    { lat: 48.6118076, lng: 18.1996726 },
    { lat: 48.6119118, lng: 18.1990349 },
    { lat: 48.6117163, lng: 18.1985021 },
    { lat: 48.6116778, lng: 18.1979901 },
    { lat: 48.6113452, lng: 18.1974674 },
    { lat: 48.6112667, lng: 18.1972431 },
    { lat: 48.6112542, lng: 18.1966734 },
    { lat: 48.6114206, lng: 18.1964829 },
    { lat: 48.6117609, lng: 18.1955568 },
    { lat: 48.6121111, lng: 18.1953119 },
    { lat: 48.6124372, lng: 18.1948191 },
    { lat: 48.6125744, lng: 18.1947921 },
    { lat: 48.6128179, lng: 18.1941996 },
    { lat: 48.6132199, lng: 18.1944585 },
    { lat: 48.6136526, lng: 18.1944085 },
    { lat: 48.6137508, lng: 18.194529 },
    { lat: 48.6139871, lng: 18.1946542 },
    { lat: 48.6154049, lng: 18.1928819 },
    { lat: 48.6158168, lng: 18.1923322 },
    { lat: 48.6156766, lng: 18.1917229 },
    { lat: 48.6159406, lng: 18.191134 },
    { lat: 48.6161177, lng: 18.189824 },
    { lat: 48.6162715, lng: 18.1891451 },
    { lat: 48.6154551, lng: 18.1878069 },
    { lat: 48.6142371, lng: 18.1852822 },
    { lat: 48.6141925, lng: 18.1851736 },
    { lat: 48.6143802, lng: 18.1849065 },
    { lat: 48.614591, lng: 18.1844744 },
    { lat: 48.6138998, lng: 18.1825463 },
    { lat: 48.613299, lng: 18.1805673 },
    { lat: 48.6125321, lng: 18.1782724 },
    { lat: 48.6119656, lng: 18.1771993 },
    { lat: 48.6116355, lng: 18.1770523 },
    { lat: 48.6116132, lng: 18.1766084 },
    { lat: 48.6115906, lng: 18.1764516 },
    { lat: 48.6092541, lng: 18.1778862 },
    { lat: 48.5975698, lng: 18.1811791 },
    { lat: 48.5927273, lng: 18.1828328 },
    { lat: 48.5923044, lng: 18.1828752 },
    { lat: 48.5916918, lng: 18.1827211 },
    { lat: 48.591681, lng: 18.1827885 },
    { lat: 48.5916256, lng: 18.1827665 },
    { lat: 48.5916337, lng: 18.1827145 },
    { lat: 48.5895832, lng: 18.1821651 },
    { lat: 48.5897491, lng: 18.1826128 },
    { lat: 48.5888564, lng: 18.1825921 },
    { lat: 48.5884689, lng: 18.1826423 },
    { lat: 48.5881553, lng: 18.1828008 },
    { lat: 48.5870673, lng: 18.1841677 },
    { lat: 48.5857368, lng: 18.1862354 },
    { lat: 48.585111, lng: 18.1851695 },
    { lat: 48.5849333, lng: 18.1851387 },
    { lat: 48.5847394, lng: 18.1845782 },
    { lat: 48.5848313, lng: 18.1845066 },
    { lat: 48.5845869, lng: 18.1832656 },
    { lat: 48.5845487, lng: 18.1828579 },
    { lat: 48.5834077, lng: 18.1797741 },
    { lat: 48.5833695, lng: 18.1796986 },
  ],
  Svrbice: [
    { lat: 48.521565, lng: 17.845534 },
    { lat: 48.521522, lng: 17.845564 },
    { lat: 48.521464, lng: 17.845595 },
    { lat: 48.521321, lng: 17.845757 },
    { lat: 48.52126, lng: 17.845846 },
    { lat: 48.521179, lng: 17.845947 },
    { lat: 48.520633, lng: 17.846658 },
    { lat: 48.520476, lng: 17.846797 },
    { lat: 48.520309, lng: 17.846977 },
    { lat: 48.520287, lng: 17.846995 },
    { lat: 48.520212, lng: 17.846891 },
    { lat: 48.520084, lng: 17.846612 },
    { lat: 48.519985, lng: 17.846468 },
    { lat: 48.519962, lng: 17.846439 },
    { lat: 48.519796, lng: 17.846338 },
    { lat: 48.519679, lng: 17.846281 },
    { lat: 48.519538, lng: 17.846138 },
    { lat: 48.519443, lng: 17.84604 },
    { lat: 48.519325, lng: 17.845908 },
    { lat: 48.519246, lng: 17.845833 },
    { lat: 48.519175, lng: 17.845782 },
    { lat: 48.519058, lng: 17.845703 },
    { lat: 48.51896, lng: 17.845664 },
    { lat: 48.518741, lng: 17.845616 },
    { lat: 48.51859, lng: 17.845614 },
    { lat: 48.518445, lng: 17.845553 },
    { lat: 48.518287, lng: 17.845415 },
    { lat: 48.518227, lng: 17.845264 },
    { lat: 48.51809, lng: 17.845132 },
    { lat: 48.517918, lng: 17.84509 },
    { lat: 48.517802, lng: 17.844869 },
    { lat: 48.517554, lng: 17.844676 },
    { lat: 48.51753, lng: 17.844653 },
    { lat: 48.517498, lng: 17.844659 },
    { lat: 48.517334, lng: 17.844615 },
    { lat: 48.517253, lng: 17.844594 },
    { lat: 48.517003, lng: 17.84515 },
    { lat: 48.516837, lng: 17.845153 },
    { lat: 48.516659, lng: 17.844939 },
    { lat: 48.51635, lng: 17.844822 },
    { lat: 48.516327, lng: 17.844858 },
    { lat: 48.516116, lng: 17.84522 },
    { lat: 48.516066, lng: 17.845326 },
    { lat: 48.516023, lng: 17.845351 },
    { lat: 48.515871, lng: 17.845353 },
    { lat: 48.515774, lng: 17.845355 },
    { lat: 48.515183, lng: 17.845554 },
    { lat: 48.515105, lng: 17.84562 },
    { lat: 48.514699, lng: 17.845986 },
    { lat: 48.514594, lng: 17.846059 },
    { lat: 48.514416, lng: 17.846173 },
    { lat: 48.514319, lng: 17.846228 },
    { lat: 48.514126, lng: 17.846349 },
    { lat: 48.514024, lng: 17.846468 },
    { lat: 48.514056, lng: 17.846509 },
    { lat: 48.514076, lng: 17.846502 },
    { lat: 48.514155, lng: 17.846613 },
    { lat: 48.514242, lng: 17.84674 },
    { lat: 48.514302, lng: 17.846886 },
    { lat: 48.514345, lng: 17.847009 },
    { lat: 48.514251, lng: 17.847386 },
    { lat: 48.514257, lng: 17.847621 },
    { lat: 48.514246, lng: 17.847703 },
    { lat: 48.514232, lng: 17.847921 },
    { lat: 48.514275, lng: 17.848413 },
    { lat: 48.514309, lng: 17.848695 },
    { lat: 48.514267, lng: 17.848854 },
    { lat: 48.514273, lng: 17.849007 },
    { lat: 48.514297, lng: 17.849182 },
    { lat: 48.514402, lng: 17.849574 },
    { lat: 48.514569, lng: 17.849947 },
    { lat: 48.51462, lng: 17.850028 },
    { lat: 48.514601, lng: 17.850046 },
    { lat: 48.514857, lng: 17.85091 },
    { lat: 48.514877, lng: 17.850904 },
    { lat: 48.514907, lng: 17.85135 },
    { lat: 48.514873, lng: 17.85182 },
    { lat: 48.514881, lng: 17.852266 },
    { lat: 48.514874, lng: 17.85249 },
    { lat: 48.514833, lng: 17.852737 },
    { lat: 48.514804, lng: 17.852937 },
    { lat: 48.514716, lng: 17.853109 },
    { lat: 48.514624, lng: 17.853346 },
    { lat: 48.514412, lng: 17.853655 },
    { lat: 48.514181, lng: 17.854006 },
    { lat: 48.513984, lng: 17.85425 },
    { lat: 48.513833, lng: 17.854406 },
    { lat: 48.513776, lng: 17.854542 },
    { lat: 48.513726, lng: 17.854607 },
    { lat: 48.513548, lng: 17.854752 },
    { lat: 48.513039, lng: 17.855431 },
    { lat: 48.512888, lng: 17.855527 },
    { lat: 48.51266, lng: 17.855872 },
    { lat: 48.512394, lng: 17.856112 },
    { lat: 48.511996, lng: 17.856566 },
    { lat: 48.511576, lng: 17.857243 },
    { lat: 48.511422, lng: 17.857339 },
    { lat: 48.511124, lng: 17.857756 },
    { lat: 48.510947, lng: 17.857971 },
    { lat: 48.510723, lng: 17.85821 },
    { lat: 48.510447, lng: 17.858321 },
    { lat: 48.510377, lng: 17.858375 },
    { lat: 48.510285, lng: 17.858535 },
    { lat: 48.510218, lng: 17.85883 },
    { lat: 48.509911, lng: 17.859546 },
    { lat: 48.509729, lng: 17.860054 },
    { lat: 48.509687, lng: 17.860249 },
    { lat: 48.509607, lng: 17.860374 },
    { lat: 48.509519, lng: 17.860598 },
    { lat: 48.50933, lng: 17.860843 },
    { lat: 48.509288, lng: 17.860955 },
    { lat: 48.509298, lng: 17.861131 },
    { lat: 48.509397, lng: 17.861458 },
    { lat: 48.509489, lng: 17.862161 },
    { lat: 48.509465, lng: 17.862684 },
    { lat: 48.509421, lng: 17.862867 },
    { lat: 48.509387, lng: 17.863085 },
    { lat: 48.50935, lng: 17.863344 },
    { lat: 48.509285, lng: 17.863469 },
    { lat: 48.509266, lng: 17.863539 },
    { lat: 48.509264, lng: 17.863704 },
    { lat: 48.509229, lng: 17.863839 },
    { lat: 48.50913, lng: 17.864053 },
    { lat: 48.509038, lng: 17.864178 },
    { lat: 48.508972, lng: 17.864291 },
    { lat: 48.50874, lng: 17.864518 },
    { lat: 48.508659, lng: 17.864642 },
    { lat: 48.508561, lng: 17.864985 },
    { lat: 48.508287, lng: 17.86543 },
    { lat: 48.508254, lng: 17.86556 },
    { lat: 48.50816, lng: 17.865644 },
    { lat: 48.508069, lng: 17.865851 },
    { lat: 48.507977, lng: 17.866046 },
    { lat: 48.507886, lng: 17.86633 },
    { lat: 48.50779, lng: 17.866537 },
    { lat: 48.507728, lng: 17.866768 },
    { lat: 48.507667, lng: 17.867421 },
    { lat: 48.507667, lng: 17.867555 },
    { lat: 48.507653, lng: 17.867791 },
    { lat: 48.507687, lng: 17.868313 },
    { lat: 48.507693, lng: 17.868583 },
    { lat: 48.507628, lng: 17.868953 },
    { lat: 48.50763, lng: 17.869012 },
    { lat: 48.507648, lng: 17.870028 },
    { lat: 48.507207, lng: 17.871819 },
    { lat: 48.507204, lng: 17.872014 },
    { lat: 48.5071948, lng: 17.8721315 },
    { lat: 48.5074844, lng: 17.8728111 },
    { lat: 48.5079264, lng: 17.8732506 },
    { lat: 48.5080163, lng: 17.8732069 },
    { lat: 48.5084959, lng: 17.8737986 },
    { lat: 48.5089814, lng: 17.8745838 },
    { lat: 48.5090421, lng: 17.8756403 },
    { lat: 48.5091223, lng: 17.8761437 },
    { lat: 48.5096277, lng: 17.8775162 },
    { lat: 48.5099321, lng: 17.8787418 },
    { lat: 48.5102647, lng: 17.879761 },
    { lat: 48.5104009, lng: 17.8805917 },
    { lat: 48.5103928, lng: 17.8809033 },
    { lat: 48.5109399, lng: 17.8822042 },
    { lat: 48.5113768, lng: 17.8834733 },
    { lat: 48.5119313, lng: 17.8854518 },
    { lat: 48.51201, lng: 17.8855208 },
    { lat: 48.5125245, lng: 17.8868815 },
    { lat: 48.5122465, lng: 17.8873614 },
    { lat: 48.5133286, lng: 17.8887319 },
    { lat: 48.5136562, lng: 17.8890573 },
    { lat: 48.514428, lng: 17.8896116 },
    { lat: 48.5147234, lng: 17.8896032 },
    { lat: 48.5151385, lng: 17.8900088 },
    { lat: 48.5167764, lng: 17.8912787 },
    { lat: 48.5167544, lng: 17.891344 },
    { lat: 48.5170296, lng: 17.8915655 },
    { lat: 48.5168232, lng: 17.8922005 },
    { lat: 48.5169584, lng: 17.8920852 },
    { lat: 48.5173527, lng: 17.8921088 },
    { lat: 48.5182068, lng: 17.8925348 },
    { lat: 48.5185177, lng: 17.8928208 },
    { lat: 48.5187221, lng: 17.8929157 },
    { lat: 48.5189396, lng: 17.8928549 },
    { lat: 48.5200747, lng: 17.8918958 },
    { lat: 48.5204633, lng: 17.8913198 },
    { lat: 48.5212847, lng: 17.8906956 },
    { lat: 48.5227669, lng: 17.8903241 },
    { lat: 48.5236114, lng: 17.889635 },
    { lat: 48.524391, lng: 17.8891813 },
    { lat: 48.5248745, lng: 17.8887743 },
    { lat: 48.5260606, lng: 17.8879497 },
    { lat: 48.5277077, lng: 17.8872235 },
    { lat: 48.5281256, lng: 17.8868934 },
    { lat: 48.5293911, lng: 17.886185 },
    { lat: 48.5302187, lng: 17.8854019 },
    { lat: 48.5306801, lng: 17.8845601 },
    { lat: 48.5308669, lng: 17.8840462 },
    { lat: 48.5308845, lng: 17.8837938 },
    { lat: 48.5308093, lng: 17.8830957 },
    { lat: 48.5309315, lng: 17.8822423 },
    { lat: 48.5314425, lng: 17.8800901 },
    { lat: 48.5322959, lng: 17.877498 },
    { lat: 48.532417, lng: 17.8767954 },
    { lat: 48.53255, lng: 17.8763273 },
    { lat: 48.5330424, lng: 17.8754013 },
    { lat: 48.5333148, lng: 17.8751452 },
    { lat: 48.5336235, lng: 17.8749649 },
    { lat: 48.5346534, lng: 17.8737976 },
    { lat: 48.5349468, lng: 17.8725844 },
    { lat: 48.5356326, lng: 17.8710878 },
    { lat: 48.5363007, lng: 17.8697269 },
    { lat: 48.5366208, lng: 17.8700159 },
    { lat: 48.53675, lng: 17.8698179 },
    { lat: 48.5378437, lng: 17.8674331 },
    { lat: 48.538516, lng: 17.865726 },
    { lat: 48.53752, lng: 17.865492 },
    { lat: 48.537414, lng: 17.865469 },
    { lat: 48.537403, lng: 17.865463 },
    { lat: 48.537348, lng: 17.865523 },
    { lat: 48.537026, lng: 17.865604 },
    { lat: 48.53688, lng: 17.865542 },
    { lat: 48.536783, lng: 17.865567 },
    { lat: 48.53676, lng: 17.865562 },
    { lat: 48.536736, lng: 17.86541 },
    { lat: 48.536641, lng: 17.865359 },
    { lat: 48.536559, lng: 17.865284 },
    { lat: 48.536511, lng: 17.865191 },
    { lat: 48.536425, lng: 17.865163 },
    { lat: 48.535966, lng: 17.865277 },
    { lat: 48.535902, lng: 17.865273 },
    { lat: 48.535895, lng: 17.865272 },
    { lat: 48.535427, lng: 17.86525 },
    { lat: 48.534947, lng: 17.865228 },
    { lat: 48.534475, lng: 17.865207 },
    { lat: 48.534025, lng: 17.865186 },
    { lat: 48.533784, lng: 17.865174 },
    { lat: 48.533627, lng: 17.865167 },
    { lat: 48.533161, lng: 17.865143 },
    { lat: 48.532757, lng: 17.865122 },
    { lat: 48.533119, lng: 17.864504 },
    { lat: 48.533538, lng: 17.863786 },
    { lat: 48.533796, lng: 17.863345 },
    { lat: 48.533575, lng: 17.862992 },
    { lat: 48.533448, lng: 17.862836 },
    { lat: 48.533251, lng: 17.862529 },
    { lat: 48.533096, lng: 17.862268 },
    { lat: 48.532943, lng: 17.862107 },
    { lat: 48.532875, lng: 17.862014 },
    { lat: 48.532729, lng: 17.861806 },
    { lat: 48.532335, lng: 17.862146 },
    { lat: 48.532214, lng: 17.862242 },
    { lat: 48.53207, lng: 17.862614 },
    { lat: 48.531976, lng: 17.862662 },
    { lat: 48.531809, lng: 17.862864 },
    { lat: 48.531625, lng: 17.863132 },
    { lat: 48.531573, lng: 17.863252 },
    { lat: 48.531517, lng: 17.863386 },
    { lat: 48.531408, lng: 17.863581 },
    { lat: 48.531283, lng: 17.863806 },
    { lat: 48.53112, lng: 17.863608 },
    { lat: 48.530803, lng: 17.863223 },
    { lat: 48.530621, lng: 17.862964 },
    { lat: 48.530409, lng: 17.862763 },
    { lat: 48.530152, lng: 17.862428 },
    { lat: 48.529791, lng: 17.862143 },
    { lat: 48.529629, lng: 17.861982 },
    { lat: 48.529562, lng: 17.861837 },
    { lat: 48.529389, lng: 17.861653 },
    { lat: 48.529237, lng: 17.861598 },
    { lat: 48.529098, lng: 17.861496 },
    { lat: 48.529141, lng: 17.861412 },
    { lat: 48.528916, lng: 17.86113 },
    { lat: 48.528844, lng: 17.861049 },
    { lat: 48.528214, lng: 17.860283 },
    { lat: 48.527997, lng: 17.860047 },
    { lat: 48.52756, lng: 17.859582 },
    { lat: 48.527493, lng: 17.859523 },
    { lat: 48.527183, lng: 17.85925 },
    { lat: 48.527085, lng: 17.859051 },
    { lat: 48.526697, lng: 17.858258 },
    { lat: 48.526506, lng: 17.857763 },
    { lat: 48.526404, lng: 17.857632 },
    { lat: 48.52611, lng: 17.857255 },
    { lat: 48.525576, lng: 17.856565 },
    { lat: 48.525503, lng: 17.856471 },
    { lat: 48.524968, lng: 17.85599 },
    { lat: 48.524945, lng: 17.85597 },
    { lat: 48.524915, lng: 17.855943 },
    { lat: 48.524787, lng: 17.85583 },
    { lat: 48.52451, lng: 17.855696 },
    { lat: 48.524496, lng: 17.8557 },
    { lat: 48.524435, lng: 17.85572 },
    { lat: 48.524387, lng: 17.855211 },
    { lat: 48.524493, lng: 17.854868 },
    { lat: 48.524523, lng: 17.854457 },
    { lat: 48.524608, lng: 17.853921 },
    { lat: 48.524619, lng: 17.853422 },
    { lat: 48.524314, lng: 17.852636 },
    { lat: 48.524007, lng: 17.851904 },
    { lat: 48.523954, lng: 17.851746 },
    { lat: 48.523882, lng: 17.851525 },
    { lat: 48.523874, lng: 17.851503 },
    { lat: 48.523625, lng: 17.850821 },
    { lat: 48.523308, lng: 17.849889 },
    { lat: 48.523223, lng: 17.849723 },
    { lat: 48.523158, lng: 17.849593 },
    { lat: 48.523065, lng: 17.849414 },
    { lat: 48.52301, lng: 17.849289 },
    { lat: 48.522872, lng: 17.848981 },
    { lat: 48.522791, lng: 17.848798 },
    { lat: 48.522735, lng: 17.848699 },
    { lat: 48.52255, lng: 17.848366 },
    { lat: 48.522501, lng: 17.848276 },
    { lat: 48.522425, lng: 17.848052 },
    { lat: 48.522319, lng: 17.847737 },
    { lat: 48.522215, lng: 17.847431 },
    { lat: 48.52211, lng: 17.847121 },
    { lat: 48.522002, lng: 17.846803 },
    { lat: 48.521902, lng: 17.846505 },
    { lat: 48.521803, lng: 17.846212 },
    { lat: 48.521611, lng: 17.845647 },
    { lat: 48.5216, lng: 17.845615 },
    { lat: 48.521565, lng: 17.845534 },
  ],
  Kuzmice: [
    { lat: 48.5620415, lng: 18.0955444 },
    { lat: 48.5623366, lng: 18.0963641 },
    { lat: 48.5623416, lng: 18.0964493 },
    { lat: 48.5622578, lng: 18.0965973 },
    { lat: 48.5610791, lng: 18.0972051 },
    { lat: 48.5610239, lng: 18.0973617 },
    { lat: 48.5630675, lng: 18.1007872 },
    { lat: 48.5654434, lng: 18.1046099 },
    { lat: 48.5673126, lng: 18.1089346 },
    { lat: 48.5682123, lng: 18.1111978 },
    { lat: 48.5685491, lng: 18.1109557 },
    { lat: 48.5686978, lng: 18.111396 },
    { lat: 48.5682243, lng: 18.111828 },
    { lat: 48.568277, lng: 18.1120181 },
    { lat: 48.567431, lng: 18.11289 },
    { lat: 48.5668983, lng: 18.1137603 },
    { lat: 48.5663925, lng: 18.1142596 },
    { lat: 48.5657299, lng: 18.1142756 },
    { lat: 48.5641004, lng: 18.1151641 },
    { lat: 48.5626971, lng: 18.1158327 },
    { lat: 48.5639094, lng: 18.1199919 },
    { lat: 48.5651151, lng: 18.1233801 },
    { lat: 48.5651406, lng: 18.1234473 },
    { lat: 48.5664497, lng: 18.1268915 },
    { lat: 48.5667678, lng: 18.1266983 },
    { lat: 48.5678971, lng: 18.1260135 },
    { lat: 48.5704975, lng: 18.1241425 },
    { lat: 48.5732777, lng: 18.1223334 },
    { lat: 48.574542, lng: 18.1216451 },
    { lat: 48.5764531, lng: 18.1205889 },
    { lat: 48.5787831, lng: 18.1193283 },
    { lat: 48.5811626, lng: 18.1181781 },
    { lat: 48.5814387, lng: 18.1181118 },
    { lat: 48.5825249, lng: 18.1175411 },
    { lat: 48.5878693, lng: 18.1150444 },
    { lat: 48.5882847, lng: 18.115006 },
    { lat: 48.5884214, lng: 18.1149298 },
    { lat: 48.5890774, lng: 18.1144531 },
    { lat: 48.5947457, lng: 18.1110285 },
    { lat: 48.5985502, lng: 18.1086559 },
    { lat: 48.598802, lng: 18.1084649 },
    { lat: 48.5988129, lng: 18.1083433 },
    { lat: 48.5989641, lng: 18.1082676 },
    { lat: 48.5999733, lng: 18.1074053 },
    { lat: 48.5995091, lng: 18.1060669 },
    { lat: 48.5994329, lng: 18.1058631 },
    { lat: 48.5997052, lng: 18.1031648 },
    { lat: 48.599676, lng: 18.1023005 },
    { lat: 48.5995055, lng: 18.1009886 },
    { lat: 48.599336, lng: 18.0989763 },
    { lat: 48.598905, lng: 18.0960707 },
    { lat: 48.5988593, lng: 18.0942285 },
    { lat: 48.5984173, lng: 18.0927153 },
    { lat: 48.5982872, lng: 18.0918431 },
    { lat: 48.5977319, lng: 18.0900516 },
    { lat: 48.5964198, lng: 18.090789 },
    { lat: 48.5963732, lng: 18.0906672 },
    { lat: 48.5949564, lng: 18.0915676 },
    { lat: 48.5942812, lng: 18.0916401 },
    { lat: 48.5936026, lng: 18.09242 },
    { lat: 48.5926031, lng: 18.093315 },
    { lat: 48.5920104, lng: 18.0923693 },
    { lat: 48.5917635, lng: 18.0927002 },
    { lat: 48.5909609, lng: 18.0935251 },
    { lat: 48.5889998, lng: 18.0950108 },
    { lat: 48.5884062, lng: 18.0936264 },
    { lat: 48.5887593, lng: 18.0931994 },
    { lat: 48.5883597, lng: 18.0924659 },
    { lat: 48.5876383, lng: 18.0908823 },
    { lat: 48.5876043, lng: 18.0909403 },
    { lat: 48.5862743, lng: 18.0913156 },
    { lat: 48.5856362, lng: 18.0917703 },
    { lat: 48.5839874, lng: 18.0925776 },
    { lat: 48.5832279, lng: 18.0908858 },
    { lat: 48.5823874, lng: 18.0880978 },
    { lat: 48.5819252, lng: 18.0868745 },
    { lat: 48.5816264, lng: 18.0863248 },
    { lat: 48.5816126, lng: 18.0863398 },
    { lat: 48.5803777, lng: 18.0877551 },
    { lat: 48.5790707, lng: 18.0857932 },
    { lat: 48.577827, lng: 18.0882364 },
    { lat: 48.5760591, lng: 18.0861308 },
    { lat: 48.5753657, lng: 18.0850468 },
    { lat: 48.5751568, lng: 18.0852746 },
    { lat: 48.5748781, lng: 18.0845077 },
    { lat: 48.5746941, lng: 18.0841972 },
    { lat: 48.5743206, lng: 18.0836804 },
    { lat: 48.5734684, lng: 18.0826949 },
    { lat: 48.5732298, lng: 18.0830742 },
    { lat: 48.5729334, lng: 18.0827142 },
    { lat: 48.5727956, lng: 18.0828848 },
    { lat: 48.572499, lng: 18.0825259 },
    { lat: 48.5722916, lng: 18.082875 },
    { lat: 48.5720578, lng: 18.0833734 },
    { lat: 48.571914, lng: 18.0835739 },
    { lat: 48.5702806, lng: 18.0851063 },
    { lat: 48.568349, lng: 18.0867709 },
    { lat: 48.5677713, lng: 18.0871956 },
    { lat: 48.5676511, lng: 18.0873791 },
    { lat: 48.5669282, lng: 18.088388 },
    { lat: 48.5665561, lng: 18.0888142 },
    { lat: 48.5653238, lng: 18.0905479 },
    { lat: 48.5650009, lng: 18.0898334 },
    { lat: 48.5620415, lng: 18.0955444 },
  ],
  HornéŠtitáre: [
    { lat: 48.5190696, lng: 18.0327923 },
    { lat: 48.5191149, lng: 18.0328695 },
    { lat: 48.519869, lng: 18.0348385 },
    { lat: 48.5201973, lng: 18.0359206 },
    { lat: 48.5207663, lng: 18.0381388 },
    { lat: 48.5133798, lng: 18.0426301 },
    { lat: 48.5098075, lng: 18.0491579 },
    { lat: 48.5145143, lng: 18.0500255 },
    { lat: 48.516774, lng: 18.0472581 },
    { lat: 48.5208458, lng: 18.0501573 },
    { lat: 48.5177902, lng: 18.052347 },
    { lat: 48.5179587, lng: 18.0531187 },
    { lat: 48.5170782, lng: 18.0535198 },
    { lat: 48.5156677, lng: 18.0583409 },
    { lat: 48.5162276, lng: 18.0628795 },
    { lat: 48.5145174, lng: 18.0637153 },
    { lat: 48.5146065, lng: 18.0642127 },
    { lat: 48.5147212, lng: 18.0645691 },
    { lat: 48.511578, lng: 18.0665421 },
    { lat: 48.5125185, lng: 18.0668736 },
    { lat: 48.5140537, lng: 18.0693491 },
    { lat: 48.5143293, lng: 18.0698729 },
    { lat: 48.5149511, lng: 18.0722885 },
    { lat: 48.515121, lng: 18.0730859 },
    { lat: 48.5153693, lng: 18.0746561 },
    { lat: 48.5154582, lng: 18.0748817 },
    { lat: 48.5157621, lng: 18.0753103 },
    { lat: 48.5189884, lng: 18.0744992 },
    { lat: 48.5219149, lng: 18.0762831 },
    { lat: 48.5219485, lng: 18.0762999 },
    { lat: 48.5220915, lng: 18.0761217 },
    { lat: 48.5232815, lng: 18.0758381 },
    { lat: 48.5242345, lng: 18.0756932 },
    { lat: 48.524127, lng: 18.0769163 },
    { lat: 48.5239056, lng: 18.0786049 },
    { lat: 48.5249932, lng: 18.0788102 },
    { lat: 48.5250511, lng: 18.0787569 },
    { lat: 48.5250933, lng: 18.0785327 },
    { lat: 48.5253674, lng: 18.0783406 },
    { lat: 48.5256364, lng: 18.0783065 },
    { lat: 48.5262676, lng: 18.0783904 },
    { lat: 48.5261139, lng: 18.0771598 },
    { lat: 48.5260892, lng: 18.0758357 },
    { lat: 48.526016, lng: 18.0750533 },
    { lat: 48.5369938, lng: 18.0720412 },
    { lat: 48.536911, lng: 18.0593284 },
    { lat: 48.5371705, lng: 18.0592531 },
    { lat: 48.537167, lng: 18.0592293 },
    { lat: 48.5371332, lng: 18.0592292 },
    { lat: 48.5368332, lng: 18.0579524 },
    { lat: 48.5366422, lng: 18.0567991 },
    { lat: 48.5365941, lng: 18.0567689 },
    { lat: 48.5343814, lng: 18.0531416 },
    { lat: 48.5294781, lng: 18.0476251 },
    { lat: 48.5295052, lng: 18.0476072 },
    { lat: 48.5284644, lng: 18.045331 },
    { lat: 48.5222928, lng: 18.0322152 },
    { lat: 48.5190696, lng: 18.0327923 },
  ],
  Šalgovce: [
    { lat: 48.538516, lng: 17.865726 },
    { lat: 48.5378437, lng: 17.8674331 },
    { lat: 48.53675, lng: 17.8698179 },
    { lat: 48.5366208, lng: 17.8700159 },
    { lat: 48.5363007, lng: 17.8697269 },
    { lat: 48.5356326, lng: 17.8710878 },
    { lat: 48.5349468, lng: 17.8725844 },
    { lat: 48.5346534, lng: 17.8737976 },
    { lat: 48.5336235, lng: 17.8749649 },
    { lat: 48.5333148, lng: 17.8751452 },
    { lat: 48.5330424, lng: 17.8754013 },
    { lat: 48.53255, lng: 17.8763273 },
    { lat: 48.532417, lng: 17.8767954 },
    { lat: 48.5322959, lng: 17.877498 },
    { lat: 48.5314425, lng: 17.8800901 },
    { lat: 48.5309315, lng: 17.8822423 },
    { lat: 48.5308093, lng: 17.8830957 },
    { lat: 48.5308845, lng: 17.8837938 },
    { lat: 48.5308669, lng: 17.8840462 },
    { lat: 48.5306801, lng: 17.8845601 },
    { lat: 48.5302187, lng: 17.8854019 },
    { lat: 48.5293911, lng: 17.886185 },
    { lat: 48.5281256, lng: 17.8868934 },
    { lat: 48.5277077, lng: 17.8872235 },
    { lat: 48.5260606, lng: 17.8879497 },
    { lat: 48.5248745, lng: 17.8887743 },
    { lat: 48.524391, lng: 17.8891813 },
    { lat: 48.5236114, lng: 17.889635 },
    { lat: 48.5227669, lng: 17.8903241 },
    { lat: 48.5212847, lng: 17.8906956 },
    { lat: 48.5204633, lng: 17.8913198 },
    { lat: 48.5200747, lng: 17.8918958 },
    { lat: 48.5189396, lng: 17.8928549 },
    { lat: 48.5187221, lng: 17.8929157 },
    { lat: 48.5182544, lng: 17.8934711 },
    { lat: 48.5179511, lng: 17.893963 },
    { lat: 48.5177131, lng: 17.8946191 },
    { lat: 48.5177466, lng: 17.8948185 },
    { lat: 48.5176046, lng: 17.8952909 },
    { lat: 48.5176, lng: 17.8956258 },
    { lat: 48.5177703, lng: 17.8959931 },
    { lat: 48.5180966, lng: 17.8964996 },
    { lat: 48.516906, lng: 17.8990285 },
    { lat: 48.5167701, lng: 17.8994484 },
    { lat: 48.5167161, lng: 17.8998076 },
    { lat: 48.5162426, lng: 17.9011103 },
    { lat: 48.5160098, lng: 17.9019436 },
    { lat: 48.5158718, lng: 17.902874 },
    { lat: 48.5161587, lng: 17.9031021 },
    { lat: 48.5160499, lng: 17.9034506 },
    { lat: 48.515871, lng: 17.9046407 },
    { lat: 48.5157847, lng: 17.9049124 },
    { lat: 48.5154034, lng: 17.9060373 },
    { lat: 48.5150996, lng: 17.9067251 },
    { lat: 48.5145274, lng: 17.9084705 },
    { lat: 48.5141913, lng: 17.9093175 },
    { lat: 48.5137837, lng: 17.9101369 },
    { lat: 48.5132861, lng: 17.911358 },
    { lat: 48.5132423, lng: 17.9116054 },
    { lat: 48.5128769, lng: 17.9124182 },
    { lat: 48.512239, lng: 17.9136307 },
    { lat: 48.5110382, lng: 17.9157131 },
    { lat: 48.5106679, lng: 17.9165202 },
    { lat: 48.5105793, lng: 17.9169036 },
    { lat: 48.510443, lng: 17.9180336 },
    { lat: 48.5100276, lng: 17.9197364 },
    { lat: 48.5096648, lng: 17.920704 },
    { lat: 48.5092338, lng: 17.9216264 },
    { lat: 48.5105103, lng: 17.9227289 },
    { lat: 48.5104049, lng: 17.9233431 },
    { lat: 48.5118592, lng: 17.9240651 },
    { lat: 48.5129755, lng: 17.9247385 },
    { lat: 48.5139382, lng: 17.9253353 },
    { lat: 48.5193316, lng: 17.926051 },
    { lat: 48.5228645, lng: 17.9223309 },
    { lat: 48.5229454, lng: 17.9221941 },
    { lat: 48.5244586, lng: 17.9208554 },
    { lat: 48.5249089, lng: 17.9205358 },
    { lat: 48.525384, lng: 17.9200625 },
    { lat: 48.5278206, lng: 17.9191948 },
    { lat: 48.5280809, lng: 17.9190902 },
    { lat: 48.5281685, lng: 17.9181851 },
    { lat: 48.5281539, lng: 17.917644 },
    { lat: 48.5282953, lng: 17.9166916 },
    { lat: 48.5284489, lng: 17.9159983 },
    { lat: 48.5286785, lng: 17.9155371 },
    { lat: 48.5288256, lng: 17.9154319 },
    { lat: 48.5289497, lng: 17.9151511 },
    { lat: 48.5290798, lng: 17.9150055 },
    { lat: 48.5291312, lng: 17.9150148 },
    { lat: 48.5292064, lng: 17.9148771 },
    { lat: 48.5291895, lng: 17.9147661 },
    { lat: 48.529351, lng: 17.9146543 },
    { lat: 48.5294727, lng: 17.9144792 },
    { lat: 48.5294627, lng: 17.9142975 },
    { lat: 48.5295532, lng: 17.9141117 },
    { lat: 48.5294994, lng: 17.9136319 },
    { lat: 48.5295087, lng: 17.9132788 },
    { lat: 48.5295986, lng: 17.9127697 },
    { lat: 48.5297356, lng: 17.9123062 },
    { lat: 48.5301554, lng: 17.9114621 },
    { lat: 48.5309622, lng: 17.9102215 },
    { lat: 48.5298177, lng: 17.9078646 },
    { lat: 48.5301644, lng: 17.9068172 },
    { lat: 48.5306182, lng: 17.9057127 },
    { lat: 48.5317441, lng: 17.9034378 },
    { lat: 48.5327078, lng: 17.9018429 },
    { lat: 48.5333703, lng: 17.9008505 },
    { lat: 48.5342003, lng: 17.8992869 },
    { lat: 48.5345402, lng: 17.8988201 },
    { lat: 48.5348918, lng: 17.8984535 },
    { lat: 48.5352206, lng: 17.8983683 },
    { lat: 48.5357962, lng: 17.8974496 },
    { lat: 48.5387027, lng: 17.8940082 },
    { lat: 48.5409827, lng: 17.8923593 },
    { lat: 48.5410132, lng: 17.8924719 },
    { lat: 48.5418479, lng: 17.8912657 },
    { lat: 48.5422342, lng: 17.8908394 },
    { lat: 48.5426163, lng: 17.8904964 },
    { lat: 48.5437709, lng: 17.8892608 },
    { lat: 48.5444831, lng: 17.8887161 },
    { lat: 48.5448223, lng: 17.8882976 },
    { lat: 48.5451198, lng: 17.8876156 },
    { lat: 48.5452392, lng: 17.8874428 },
    { lat: 48.5472227, lng: 17.8857304 },
    { lat: 48.5474457, lng: 17.8853845 },
    { lat: 48.5479085, lng: 17.8848628 },
    { lat: 48.5487593, lng: 17.884092 },
    { lat: 48.5490708, lng: 17.8836213 },
    { lat: 48.5492527, lng: 17.8835112 },
    { lat: 48.5496739, lng: 17.8834602 },
    { lat: 48.5499197, lng: 17.8835312 },
    { lat: 48.5503281, lng: 17.883416 },
    { lat: 48.5506768, lng: 17.8834665 },
    { lat: 48.550923, lng: 17.8836023 },
    { lat: 48.5511195, lng: 17.8833387 },
    { lat: 48.55186, lng: 17.8828174 },
    { lat: 48.5521647, lng: 17.8825165 },
    { lat: 48.5528724, lng: 17.8811555 },
    { lat: 48.5533038, lng: 17.88013 },
    { lat: 48.5537641, lng: 17.8809472 },
    { lat: 48.5543467, lng: 17.8802587 },
    { lat: 48.5551868, lng: 17.8788652 },
    { lat: 48.5552742, lng: 17.8786283 },
    { lat: 48.5561298, lng: 17.877957 },
    { lat: 48.5571611, lng: 17.8773928 },
    { lat: 48.5575052, lng: 17.877091 },
    { lat: 48.5583715, lng: 17.875104 },
    { lat: 48.5592398, lng: 17.8738215 },
    { lat: 48.5585615, lng: 17.8730977 },
    { lat: 48.5582798, lng: 17.8725816 },
    { lat: 48.5585085, lng: 17.8721474 },
    { lat: 48.558564, lng: 17.872053 },
    { lat: 48.558455, lng: 17.87182 },
    { lat: 48.558341, lng: 17.871675 },
    { lat: 48.557945, lng: 17.871377 },
    { lat: 48.5568, lng: 17.870869 },
    { lat: 48.555817, lng: 17.869301 },
    { lat: 48.554972, lng: 17.869817 },
    { lat: 48.554355, lng: 17.869599 },
    { lat: 48.553324, lng: 17.869725 },
    { lat: 48.553172, lng: 17.869675 },
    { lat: 48.552803, lng: 17.869441 },
    { lat: 48.552005, lng: 17.869468 },
    { lat: 48.551908, lng: 17.869487 },
    { lat: 48.551204, lng: 17.869172 },
    { lat: 48.551044, lng: 17.869045 },
    { lat: 48.55049, lng: 17.868533 },
    { lat: 48.549636, lng: 17.867497 },
    { lat: 48.549397, lng: 17.867349 },
    { lat: 48.548571, lng: 17.866771 },
    { lat: 48.548382, lng: 17.866657 },
    { lat: 48.547824, lng: 17.866015 },
    { lat: 48.547133, lng: 17.865806 },
    { lat: 48.546839, lng: 17.865664 },
    { lat: 48.546188, lng: 17.865048 },
    { lat: 48.545775, lng: 17.864686 },
    { lat: 48.545525, lng: 17.864555 },
    { lat: 48.545102, lng: 17.864264 },
    { lat: 48.545043, lng: 17.864218 },
    { lat: 48.544557, lng: 17.863761 },
    { lat: 48.544193, lng: 17.863937 },
    { lat: 48.543785, lng: 17.863791 },
    { lat: 48.54327, lng: 17.864118 },
    { lat: 48.543093, lng: 17.863988 },
    { lat: 48.542877, lng: 17.863882 },
    { lat: 48.542543, lng: 17.863922 },
    { lat: 48.542368, lng: 17.863733 },
    { lat: 48.542207, lng: 17.863579 },
    { lat: 48.541805, lng: 17.863368 },
    { lat: 48.541497, lng: 17.863329 },
    { lat: 48.541218, lng: 17.862843 },
    { lat: 48.541039, lng: 17.862878 },
    { lat: 48.540269, lng: 17.863343 },
    { lat: 48.53984, lng: 17.863608 },
    { lat: 48.539186, lng: 17.863175 },
    { lat: 48.539216, lng: 17.863292 },
    { lat: 48.539204, lng: 17.863601 },
    { lat: 48.539187, lng: 17.864075 },
    { lat: 48.539159, lng: 17.864312 },
    { lat: 48.539103, lng: 17.864478 },
    { lat: 48.538981, lng: 17.864724 },
    { lat: 48.538672, lng: 17.86535 },
    { lat: 48.538628, lng: 17.865441 },
    { lat: 48.538535, lng: 17.865678 },
    { lat: 48.538516, lng: 17.865726 },
  ],
  Belince: [
    { lat: 48.4665031, lng: 18.0649169 },
    { lat: 48.4647323, lng: 18.0637886 },
    { lat: 48.4642173, lng: 18.0654462 },
    { lat: 48.4629489, lng: 18.069143 },
    { lat: 48.4627961, lng: 18.0689454 },
    { lat: 48.4628359, lng: 18.0687178 },
    { lat: 48.4626587, lng: 18.068585 },
    { lat: 48.4602153, lng: 18.0798927 },
    { lat: 48.4599185, lng: 18.0838023 },
    { lat: 48.4589869, lng: 18.0944319 },
    { lat: 48.4590356, lng: 18.0946449 },
    { lat: 48.4586587, lng: 18.0988361 },
    { lat: 48.4585969, lng: 18.0988421 },
    { lat: 48.4586606, lng: 18.1024678 },
    { lat: 48.4585025, lng: 18.1060813 },
    { lat: 48.4598793, lng: 18.1067955 },
    { lat: 48.4588463, lng: 18.119562 },
    { lat: 48.4588065, lng: 18.1197714 },
    { lat: 48.4590901, lng: 18.1195618 },
    { lat: 48.4593006, lng: 18.119535 },
    { lat: 48.4595463, lng: 18.1197254 },
    { lat: 48.4596515, lng: 18.1203053 },
    { lat: 48.4593775, lng: 18.121331 },
    { lat: 48.4593612, lng: 18.1218246 },
    { lat: 48.4593938, lng: 18.1219767 },
    { lat: 48.4596214, lng: 18.1222609 },
    { lat: 48.4599305, lng: 18.122315 },
    { lat: 48.4600818, lng: 18.1222605 },
    { lat: 48.4603177, lng: 18.1219928 },
    { lat: 48.4607075, lng: 18.1213998 },
    { lat: 48.4610213, lng: 18.121055 },
    { lat: 48.4612743, lng: 18.120998 },
    { lat: 48.4614427, lng: 18.1210426 },
    { lat: 48.461646, lng: 18.1212394 },
    { lat: 48.4617212, lng: 18.1213091 },
    { lat: 48.4622054, lng: 18.1193982 },
    { lat: 48.4623682, lng: 18.116377 },
    { lat: 48.4625746, lng: 18.1164332 },
    { lat: 48.4633899, lng: 18.111622 },
    { lat: 48.4636244, lng: 18.1088636 },
    { lat: 48.4636401, lng: 18.1065889 },
    { lat: 48.4638006, lng: 18.1061118 },
    { lat: 48.4639946, lng: 18.1052654 },
    { lat: 48.4641573, lng: 18.1049995 },
    { lat: 48.464179, lng: 18.104753 },
    { lat: 48.4641945, lng: 18.1033226 },
    { lat: 48.464267, lng: 18.102999 },
    { lat: 48.4643024, lng: 18.1024299 },
    { lat: 48.4642853, lng: 18.1021019 },
    { lat: 48.4641953, lng: 18.1019506 },
    { lat: 48.4640049, lng: 18.0991286 },
    { lat: 48.4640856, lng: 18.0973879 },
    { lat: 48.4642285, lng: 18.0951895 },
    { lat: 48.4644103, lng: 18.0932725 },
    { lat: 48.4650665, lng: 18.0886709 },
    { lat: 48.4655556, lng: 18.0844147 },
    { lat: 48.4655895, lng: 18.0832068 },
    { lat: 48.4653902, lng: 18.0820054 },
    { lat: 48.4652728, lng: 18.0807974 },
    { lat: 48.4652525, lng: 18.0801468 },
    { lat: 48.4652953, lng: 18.0788567 },
    { lat: 48.465338, lng: 18.0786458 },
    { lat: 48.4654334, lng: 18.0751456 },
    { lat: 48.4657672, lng: 18.0727096 },
    { lat: 48.4657707, lng: 18.072633 },
    { lat: 48.4656689, lng: 18.0726114 },
    { lat: 48.4657126, lng: 18.0720117 },
    { lat: 48.4658736, lng: 18.0710112 },
    { lat: 48.4658735, lng: 18.0690557 },
    { lat: 48.4659404, lng: 18.0672228 },
    { lat: 48.4664974, lng: 18.0671552 },
    { lat: 48.4665145, lng: 18.0649588 },
    { lat: 48.4665031, lng: 18.0649169 },
  ],
  HornéObdokovce: [
    { lat: 48.4943098, lng: 18.0777045 },
    { lat: 48.4944437, lng: 18.0778496 },
    { lat: 48.4960199, lng: 18.0796098 },
    { lat: 48.4977627, lng: 18.0809747 },
    { lat: 48.4986995, lng: 18.0818794 },
    { lat: 48.5022427, lng: 18.084826 },
    { lat: 48.5024493, lng: 18.0843452 },
    { lat: 48.5027115, lng: 18.0839138 },
    { lat: 48.5032413, lng: 18.0833838 },
    { lat: 48.503695, lng: 18.0831156 },
    { lat: 48.5042533, lng: 18.0833206 },
    { lat: 48.5068446, lng: 18.0867225 },
    { lat: 48.506606, lng: 18.0872693 },
    { lat: 48.5064458, lng: 18.0881334 },
    { lat: 48.5061392, lng: 18.0891991 },
    { lat: 48.5057705, lng: 18.0898963 },
    { lat: 48.5055552, lng: 18.0901125 },
    { lat: 48.5053422, lng: 18.0905205 },
    { lat: 48.5050371, lng: 18.0914384 },
    { lat: 48.5042906, lng: 18.0960453 },
    { lat: 48.5042871, lng: 18.0965226 },
    { lat: 48.5043534, lng: 18.0970761 },
    { lat: 48.5047394, lng: 18.0976935 },
    { lat: 48.5050998, lng: 18.0980439 },
    { lat: 48.5052604, lng: 18.0982418 },
    { lat: 48.5053041, lng: 18.0983897 },
    { lat: 48.5066669, lng: 18.1000376 },
    { lat: 48.5088949, lng: 18.0978795 },
    { lat: 48.5091593, lng: 18.0976234 },
    { lat: 48.509635, lng: 18.0974399 },
    { lat: 48.5105664, lng: 18.0974656 },
    { lat: 48.5115021, lng: 18.0976787 },
    { lat: 48.5142868, lng: 18.0988242 },
    { lat: 48.5163638, lng: 18.098984 },
    { lat: 48.5189484, lng: 18.0985861 },
    { lat: 48.5208659, lng: 18.0974334 },
    { lat: 48.5220025, lng: 18.0972233 },
    { lat: 48.52257, lng: 18.0970247 },
    { lat: 48.5227563, lng: 18.0967019 },
    { lat: 48.5229557, lng: 18.0960819 },
    { lat: 48.5232682, lng: 18.0955181 },
    { lat: 48.523439, lng: 18.0945441 },
    { lat: 48.5232144, lng: 18.0940453 },
    { lat: 48.5224116, lng: 18.093152 },
    { lat: 48.5200934, lng: 18.0938416 },
    { lat: 48.5190141, lng: 18.0901843 },
    { lat: 48.5187288, lng: 18.0889382 },
    { lat: 48.5187641, lng: 18.0884789 },
    { lat: 48.5187222, lng: 18.0861723 },
    { lat: 48.5187897, lng: 18.0857821 },
    { lat: 48.5192096, lng: 18.0847504 },
    { lat: 48.5194969, lng: 18.0842223 },
    { lat: 48.5197159, lng: 18.0840084 },
    { lat: 48.5200068, lng: 18.083582 },
    { lat: 48.5200777, lng: 18.0829865 },
    { lat: 48.5200843, lng: 18.0816588 },
    { lat: 48.5202692, lng: 18.0811953 },
    { lat: 48.5205408, lng: 18.0807821 },
    { lat: 48.5208865, lng: 18.080061 },
    { lat: 48.5213406, lng: 18.0789553 },
    { lat: 48.5217537, lng: 18.0777272 },
    { lat: 48.5218935, lng: 18.0764774 },
    { lat: 48.5219149, lng: 18.0762831 },
    { lat: 48.5189884, lng: 18.0744992 },
    { lat: 48.5157621, lng: 18.0753103 },
    { lat: 48.5154582, lng: 18.0748817 },
    { lat: 48.5153693, lng: 18.0746561 },
    { lat: 48.515121, lng: 18.0730859 },
    { lat: 48.5149511, lng: 18.0722885 },
    { lat: 48.5143293, lng: 18.0698729 },
    { lat: 48.5140537, lng: 18.0693491 },
    { lat: 48.5125185, lng: 18.0668736 },
    { lat: 48.511578, lng: 18.0665421 },
    { lat: 48.5147212, lng: 18.0645691 },
    { lat: 48.5146065, lng: 18.0642127 },
    { lat: 48.5145174, lng: 18.0637153 },
    { lat: 48.5162276, lng: 18.0628795 },
    { lat: 48.5156677, lng: 18.0583409 },
    { lat: 48.5170782, lng: 18.0535198 },
    { lat: 48.5179587, lng: 18.0531187 },
    { lat: 48.5177902, lng: 18.052347 },
    { lat: 48.5208458, lng: 18.0501573 },
    { lat: 48.516774, lng: 18.0472581 },
    { lat: 48.5145143, lng: 18.0500255 },
    { lat: 48.5098075, lng: 18.0491579 },
    { lat: 48.5133798, lng: 18.0426301 },
    { lat: 48.5207663, lng: 18.0381388 },
    { lat: 48.5201973, lng: 18.0359206 },
    { lat: 48.519869, lng: 18.0348385 },
    { lat: 48.5191149, lng: 18.0328695 },
    { lat: 48.5190696, lng: 18.0327923 },
    { lat: 48.5190483, lng: 18.0327353 },
    { lat: 48.5189568, lng: 18.0325251 },
    { lat: 48.5185216, lng: 18.031885 },
    { lat: 48.5180102, lng: 18.031237 },
    { lat: 48.5179773, lng: 18.031288 },
    { lat: 48.5177172, lng: 18.0315487 },
    { lat: 48.51167, lng: 18.0301002 },
    { lat: 48.5115775, lng: 18.027225 },
    { lat: 48.5114332, lng: 18.0207951 },
    { lat: 48.5113173, lng: 18.016139 },
    { lat: 48.5111829, lng: 18.0132851 },
    { lat: 48.5112223, lng: 18.0107756 },
    { lat: 48.511088, lng: 18.0088978 },
    { lat: 48.5108833, lng: 18.006839 },
    { lat: 48.5111433, lng: 18.0067517 },
    { lat: 48.5106841, lng: 18.0030353 },
    { lat: 48.5105407, lng: 18.0008875 },
    { lat: 48.5103971, lng: 17.999709 },
    { lat: 48.509705, lng: 17.9977132 },
    { lat: 48.5092989, lng: 17.9968243 },
    { lat: 48.5091192, lng: 17.9966133 },
    { lat: 48.5080649, lng: 17.9955121 },
    { lat: 48.5078515, lng: 17.9952448 },
    { lat: 48.5076669, lng: 17.9948169 },
    { lat: 48.5067383, lng: 17.9897838 },
    { lat: 48.5055873, lng: 17.9899962 },
    { lat: 48.5055385, lng: 17.9892923 },
    { lat: 48.5049544, lng: 17.989075 },
    { lat: 48.5045636, lng: 17.9888042 },
    { lat: 48.5025352, lng: 17.9887247 },
    { lat: 48.5027613, lng: 17.9900776 },
    { lat: 48.5017894, lng: 17.9904181 },
    { lat: 48.5014321, lng: 17.9904823 },
    { lat: 48.500923, lng: 17.9904795 },
    { lat: 48.4996554, lng: 17.9903282 },
    { lat: 48.4991951, lng: 17.9909733 },
    { lat: 48.4982605, lng: 17.9925625 },
    { lat: 48.4977535, lng: 17.9935252 },
    { lat: 48.4964018, lng: 17.9922279 },
    { lat: 48.4964661, lng: 17.9914886 },
    { lat: 48.496511, lng: 17.990027 },
    { lat: 48.4947147, lng: 17.9897506 },
    { lat: 48.4926698, lng: 17.9954024 },
    { lat: 48.4921647, lng: 17.9978735 },
    { lat: 48.4918369, lng: 17.9991938 },
    { lat: 48.4917888, lng: 17.9992561 },
    { lat: 48.4878815, lng: 18.0066943 },
    { lat: 48.4882035, lng: 18.0071174 },
    { lat: 48.488542, lng: 18.0074205 },
    { lat: 48.4889522, lng: 18.0079033 },
    { lat: 48.4890996, lng: 18.0090149 },
    { lat: 48.4896593, lng: 18.0107799 },
    { lat: 48.4897444, lng: 18.0125732 },
    { lat: 48.4896877, lng: 18.0138952 },
    { lat: 48.4897846, lng: 18.0149784 },
    { lat: 48.4897542, lng: 18.0158713 },
    { lat: 48.489896, lng: 18.0171763 },
    { lat: 48.4901261, lng: 18.0180687 },
    { lat: 48.4898976, lng: 18.0181748 },
    { lat: 48.4894318, lng: 18.0183876 },
    { lat: 48.4880439, lng: 18.0198013 },
    { lat: 48.4874476, lng: 18.0202119 },
    { lat: 48.4871894, lng: 18.0204839 },
    { lat: 48.486433, lng: 18.0217187 },
    { lat: 48.4861061, lng: 18.0220836 },
    { lat: 48.4859284, lng: 18.0221697 },
    { lat: 48.4857667, lng: 18.0224138 },
    { lat: 48.485571, lng: 18.0230339 },
    { lat: 48.4852622, lng: 18.0237449 },
    { lat: 48.4848419, lng: 18.0241354 },
    { lat: 48.4842929, lng: 18.0249278 },
    { lat: 48.4821463, lng: 18.0287757 },
    { lat: 48.4813731, lng: 18.0300477 },
    { lat: 48.4805963, lng: 18.031455 },
    { lat: 48.4804835, lng: 18.0315516 },
    { lat: 48.4795144, lng: 18.03352 },
    { lat: 48.4792767, lng: 18.0342872 },
    { lat: 48.4790562, lng: 18.034797 },
    { lat: 48.4795159, lng: 18.0360014 },
    { lat: 48.4800459, lng: 18.0376148 },
    { lat: 48.4802927, lng: 18.0381253 },
    { lat: 48.4814506, lng: 18.0387108 },
    { lat: 48.4826484, lng: 18.038186 },
    { lat: 48.4830814, lng: 18.0382177 },
    { lat: 48.4839189, lng: 18.0385985 },
    { lat: 48.4837305, lng: 18.0393068 },
    { lat: 48.4842651, lng: 18.040099 },
    { lat: 48.4849857, lng: 18.0401067 },
    { lat: 48.4856922, lng: 18.0399569 },
    { lat: 48.4855056, lng: 18.041991 },
    { lat: 48.4850743, lng: 18.0436483 },
    { lat: 48.4831752, lng: 18.0503573 },
    { lat: 48.4823087, lng: 18.0514295 },
    { lat: 48.4821216, lng: 18.0521973 },
    { lat: 48.4821214, lng: 18.0528434 },
    { lat: 48.4819491, lng: 18.0547031 },
    { lat: 48.4818754, lng: 18.0550808 },
    { lat: 48.4792927, lng: 18.064368 },
    { lat: 48.4783511, lng: 18.0675962 },
    { lat: 48.4785694, lng: 18.0675674 },
    { lat: 48.4795129, lng: 18.0675863 },
    { lat: 48.4806075, lng: 18.0678893 },
    { lat: 48.4809971, lng: 18.0678739 },
    { lat: 48.48381, lng: 18.0688583 },
    { lat: 48.4855395, lng: 18.0690229 },
    { lat: 48.4857932, lng: 18.0690929 },
    { lat: 48.4859784, lng: 18.0692298 },
    { lat: 48.486168, lng: 18.0695542 },
    { lat: 48.4864285, lng: 18.0701822 },
    { lat: 48.4880849, lng: 18.0720163 },
    { lat: 48.4888075, lng: 18.0726731 },
    { lat: 48.489029, lng: 18.0729852 },
    { lat: 48.4892863, lng: 18.073655 },
    { lat: 48.490338, lng: 18.0742861 },
    { lat: 48.4909647, lng: 18.0745399 },
    { lat: 48.4912616, lng: 18.0745818 },
    { lat: 48.4921919, lng: 18.0749893 },
    { lat: 48.492598, lng: 18.0755056 },
    { lat: 48.4929052, lng: 18.0760837 },
    { lat: 48.4940273, lng: 18.0772561 },
    { lat: 48.4941563, lng: 18.0774541 },
    { lat: 48.4943098, lng: 18.0777045 },
  ],
  Lipovník: [
    { lat: 48.599827, lng: 17.98446 },
    { lat: 48.599636, lng: 17.98431 },
    { lat: 48.599261, lng: 17.98429 },
    { lat: 48.599171, lng: 17.984245 },
    { lat: 48.599003, lng: 17.984184 },
    { lat: 48.598672, lng: 17.983967 },
    { lat: 48.598233, lng: 17.983741 },
    { lat: 48.597425, lng: 17.983548 },
    { lat: 48.597121, lng: 17.983431 },
    { lat: 48.596693, lng: 17.983258 },
    { lat: 48.596421, lng: 17.983016 },
    { lat: 48.596317, lng: 17.982765 },
    { lat: 48.596256, lng: 17.982542 },
    { lat: 48.596231, lng: 17.982383 },
    { lat: 48.596209, lng: 17.982224 },
    { lat: 48.596171, lng: 17.981965 },
    { lat: 48.596115, lng: 17.981505 },
    { lat: 48.596111, lng: 17.981098 },
    { lat: 48.596089, lng: 17.980862 },
    { lat: 48.596051, lng: 17.980579 },
    { lat: 48.596045, lng: 17.980378 },
    { lat: 48.596088, lng: 17.980052 },
    { lat: 48.596111, lng: 17.979638 },
    { lat: 48.596179, lng: 17.979152 },
    { lat: 48.596211, lng: 17.978797 },
    { lat: 48.596208, lng: 17.978537 },
    { lat: 48.596257, lng: 17.978015 },
    { lat: 48.5963, lng: 17.977335 },
    { lat: 48.596311, lng: 17.977234 },
    { lat: 48.596366, lng: 17.976861 },
    { lat: 48.596348, lng: 17.976702 },
    { lat: 48.59632, lng: 17.976596 },
    { lat: 48.596276, lng: 17.976556 },
    { lat: 48.5963088, lng: 17.976833 },
    { lat: 48.5957269, lng: 17.9774674 },
    { lat: 48.5952463, lng: 17.9782292 },
    { lat: 48.595081, lng: 17.9790903 },
    { lat: 48.5950095, lng: 17.9797478 },
    { lat: 48.5948862, lng: 17.9802352 },
    { lat: 48.5949085, lng: 17.9804946 },
    { lat: 48.5948175, lng: 17.9807095 },
    { lat: 48.5946158, lng: 17.981589 },
    { lat: 48.5945204, lng: 17.9817859 },
    { lat: 48.593587, lng: 17.9828486 },
    { lat: 48.5931085, lng: 17.9830375 },
    { lat: 48.5925937, lng: 17.9838534 },
    { lat: 48.5920982, lng: 17.9848756 },
    { lat: 48.5918317, lng: 17.9852133 },
    { lat: 48.5917335, lng: 17.9854634 },
    { lat: 48.5913588, lng: 17.9858799 },
    { lat: 48.5912402, lng: 17.986143 },
    { lat: 48.5910543, lng: 17.9862238 },
    { lat: 48.589819, lng: 17.9875418 },
    { lat: 48.5897391, lng: 17.9877387 },
    { lat: 48.5892179, lng: 17.9882713 },
    { lat: 48.5890841, lng: 17.9884872 },
    { lat: 48.5885866, lng: 17.9887 },
    { lat: 48.5882993, lng: 17.9889196 },
    { lat: 48.5876079, lng: 17.9892491 },
    { lat: 48.5874155, lng: 17.9895254 },
    { lat: 48.5871299, lng: 17.9903576 },
    { lat: 48.586702, lng: 17.9910208 },
    { lat: 48.586541, lng: 17.9909709 },
    { lat: 48.5862258, lng: 17.9911544 },
    { lat: 48.5859952, lng: 17.9915477 },
    { lat: 48.5851917, lng: 17.9915865 },
    { lat: 48.584838, lng: 17.9918471 },
    { lat: 48.5847129, lng: 17.9918316 },
    { lat: 48.5845274, lng: 17.9920891 },
    { lat: 48.5842497, lng: 17.9920528 },
    { lat: 48.5841263, lng: 17.9922324 },
    { lat: 48.5839578, lng: 17.9922121 },
    { lat: 48.5839344, lng: 17.9921593 },
    { lat: 48.583609, lng: 17.9924546 },
    { lat: 48.5834125, lng: 17.9928536 },
    { lat: 48.5831041, lng: 17.9928301 },
    { lat: 48.5827594, lng: 17.9932028 },
    { lat: 48.5827374, lng: 17.9933449 },
    { lat: 48.5823918, lng: 17.9936069 },
    { lat: 48.582188, lng: 17.9943352 },
    { lat: 48.581822, lng: 17.9947868 },
    { lat: 48.5817797, lng: 17.9949468 },
    { lat: 48.5816942, lng: 17.9949887 },
    { lat: 48.5816717, lng: 17.995314 },
    { lat: 48.5815938, lng: 17.9955038 },
    { lat: 48.5814079, lng: 17.9957535 },
    { lat: 48.5813419, lng: 17.9959724 },
    { lat: 48.5811831, lng: 17.9962451 },
    { lat: 48.5811294, lng: 17.9964998 },
    { lat: 48.5809613, lng: 17.9966729 },
    { lat: 48.5809775, lng: 17.9968497 },
    { lat: 48.5808848, lng: 17.9970572 },
    { lat: 48.5808509, lng: 17.9973409 },
    { lat: 48.5806136, lng: 17.9977743 },
    { lat: 48.5806146, lng: 17.9979217 },
    { lat: 48.5805484, lng: 17.9980818 },
    { lat: 48.5801761, lng: 17.9986343 },
    { lat: 48.5800819, lng: 17.9986645 },
    { lat: 48.5799837, lng: 17.9985883 },
    { lat: 48.5797382, lng: 17.9986371 },
    { lat: 48.5796188, lng: 17.9989986 },
    { lat: 48.5794822, lng: 17.9992477 },
    { lat: 48.5791388, lng: 17.9992798 },
    { lat: 48.5788941, lng: 17.9995121 },
    { lat: 48.5788165, lng: 17.9997432 },
    { lat: 48.5788252, lng: 17.9999082 },
    { lat: 48.5787006, lng: 17.9999977 },
    { lat: 48.5784281, lng: 18.0003663 },
    { lat: 48.5782524, lng: 18.000184 },
    { lat: 48.5781044, lng: 18.0003922 },
    { lat: 48.5780933, lng: 18.0005284 },
    { lat: 48.5779372, lng: 18.0006358 },
    { lat: 48.5776316, lng: 18.00026 },
    { lat: 48.5775063, lng: 18.0003728 },
    { lat: 48.5774055, lng: 18.0003678 },
    { lat: 48.5773005, lng: 18.0005756 },
    { lat: 48.5769884, lng: 18.0007196 },
    { lat: 48.5770518, lng: 18.000967 },
    { lat: 48.5767369, lng: 18.0012355 },
    { lat: 48.5765531, lng: 18.0012959 },
    { lat: 48.5763316, lng: 18.0015158 },
    { lat: 48.5763082, lng: 18.0016754 },
    { lat: 48.5761642, lng: 18.0019308 },
    { lat: 48.5760359, lng: 18.0019731 },
    { lat: 48.5759499, lng: 18.00182 },
    { lat: 48.5755394, lng: 18.0017699 },
    { lat: 48.5751836, lng: 18.0016483 },
    { lat: 48.5745158, lng: 18.0016945 },
    { lat: 48.5744457, lng: 18.0016534 },
    { lat: 48.5741841, lng: 18.0017322 },
    { lat: 48.5742084, lng: 18.0020454 },
    { lat: 48.5740382, lng: 18.0022412 },
    { lat: 48.5736432, lng: 18.0022974 },
    { lat: 48.5733037, lng: 18.002494 },
    { lat: 48.5731367, lng: 18.0027022 },
    { lat: 48.5727821, lng: 18.0028117 },
    { lat: 48.572401, lng: 18.0033115 },
    { lat: 48.5718194, lng: 18.0035941 },
    { lat: 48.5716081, lng: 18.003608 },
    { lat: 48.5714846, lng: 18.003887 },
    { lat: 48.5713201, lng: 18.0039651 },
    { lat: 48.5712501, lng: 18.0038887 },
    { lat: 48.5709222, lng: 18.0039743 },
    { lat: 48.5707913, lng: 18.0043747 },
    { lat: 48.570587, lng: 18.004584 },
    { lat: 48.570318, lng: 18.0046128 },
    { lat: 48.5702543, lng: 18.0044724 },
    { lat: 48.5698817, lng: 18.0046984 },
    { lat: 48.5695493, lng: 18.0046128 },
    { lat: 48.5694513, lng: 18.0046905 },
    { lat: 48.5694675, lng: 18.0049089 },
    { lat: 48.5691018, lng: 18.0052904 },
    { lat: 48.5688445, lng: 18.0053457 },
    { lat: 48.5687429, lng: 18.0054592 },
    { lat: 48.5686547, lng: 18.0057017 },
    { lat: 48.5685298, lng: 18.0057859 },
    { lat: 48.5680175, lng: 18.0056187 },
    { lat: 48.5679278, lng: 18.0056846 },
    { lat: 48.5677711, lng: 18.0056505 },
    { lat: 48.5677176, lng: 18.0058694 },
    { lat: 48.5677683, lng: 18.0060582 },
    { lat: 48.5671968, lng: 18.0067725 },
    { lat: 48.5672258, lng: 18.0070736 },
    { lat: 48.5671675, lng: 18.0072867 },
    { lat: 48.5667079, lng: 18.0075686 },
    { lat: 48.5665098, lng: 18.0078066 },
    { lat: 48.5661222, lng: 18.007934 },
    { lat: 48.566095, lng: 18.0077039 },
    { lat: 48.5651712, lng: 18.0083972 },
    { lat: 48.5651214, lng: 18.008545 },
    { lat: 48.5649535, lng: 18.0086058 },
    { lat: 48.5649426, lng: 18.0088599 },
    { lat: 48.5648569, lng: 18.008979 },
    { lat: 48.5649014, lng: 18.0093094 },
    { lat: 48.5648664, lng: 18.0093452 },
    { lat: 48.5646079, lng: 18.0092706 },
    { lat: 48.5641673, lng: 18.0094337 },
    { lat: 48.5640933, lng: 18.0095174 },
    { lat: 48.5640423, lng: 18.0093992 },
    { lat: 48.5637257, lng: 18.009656 },
    { lat: 48.5634894, lng: 18.0100307 },
    { lat: 48.5635407, lng: 18.0101898 },
    { lat: 48.5634076, lng: 18.0102498 },
    { lat: 48.5631307, lng: 18.0101873 },
    { lat: 48.5630412, lng: 18.0102942 },
    { lat: 48.5630924, lng: 18.0104715 },
    { lat: 48.5630686, lng: 18.0105481 },
    { lat: 48.5629098, lng: 18.0106857 },
    { lat: 48.5627534, lng: 18.0106572 },
    { lat: 48.5625641, lng: 18.0103756 },
    { lat: 48.562259, lng: 18.010313 },
    { lat: 48.562034, lng: 18.0106102 },
    { lat: 48.5618818, lng: 18.0106884 },
    { lat: 48.5617755, lng: 18.0105709 },
    { lat: 48.5612686, lng: 18.0106642 },
    { lat: 48.561159, lng: 18.0107947 },
    { lat: 48.5610431, lng: 18.0111446 },
    { lat: 48.5609645, lng: 18.0110921 },
    { lat: 48.5607938, lng: 18.0111643 },
    { lat: 48.5606369, lng: 18.0110714 },
    { lat: 48.5604413, lng: 18.0111793 },
    { lat: 48.5600319, lng: 18.0111272 },
    { lat: 48.5590606, lng: 18.0113044 },
    { lat: 48.5586255, lng: 18.0112772 },
    { lat: 48.5584686, lng: 18.0111797 },
    { lat: 48.5582547, lng: 18.0107517 },
    { lat: 48.5578123, lng: 18.0103164 },
    { lat: 48.5577404, lng: 18.0100574 },
    { lat: 48.5576349, lng: 18.0100478 },
    { lat: 48.5576048, lng: 18.0101669 },
    { lat: 48.5574949, lng: 18.0101803 },
    { lat: 48.5573004, lng: 18.0098527 },
    { lat: 48.557014, lng: 18.0097403 },
    { lat: 48.5569474, lng: 18.0096466 },
    { lat: 48.5567088, lng: 18.0096745 },
    { lat: 48.5566218, lng: 18.0095996 },
    { lat: 48.5565646, lng: 18.0097955 },
    { lat: 48.5563217, lng: 18.0096757 },
    { lat: 48.5561732, lng: 18.0096847 },
    { lat: 48.5559838, lng: 18.0094989 },
    { lat: 48.555674, lng: 18.0093746 },
    { lat: 48.5554833, lng: 18.0090351 },
    { lat: 48.555331, lng: 18.0089609 },
    { lat: 48.5549944, lng: 18.0089909 },
    { lat: 48.5549278, lng: 18.0088973 },
    { lat: 48.554755, lng: 18.008859 },
    { lat: 48.5545129, lng: 18.0089051 },
    { lat: 48.5543545, lng: 18.0090679 },
    { lat: 48.554249, lng: 18.0090397 },
    { lat: 48.5530936, lng: 18.0079086 },
    { lat: 48.5516544, lng: 18.0073911 },
    { lat: 48.5515931, lng: 18.007481 },
    { lat: 48.5512385, lng: 18.0076114 },
    { lat: 48.5509936, lng: 18.0077819 },
    { lat: 48.5508009, lng: 18.0080334 },
    { lat: 48.5502189, lng: 18.0080976 },
    { lat: 48.5495254, lng: 18.0083763 },
    { lat: 48.5494048, lng: 18.0084912 },
    { lat: 48.5491366, lng: 18.0086317 },
    { lat: 48.5497026, lng: 18.0106548 },
    { lat: 48.5499285, lng: 18.0112488 },
    { lat: 48.5503358, lng: 18.0120261 },
    { lat: 48.5507626, lng: 18.0127503 },
    { lat: 48.5514441, lng: 18.0123653 },
    { lat: 48.5526471, lng: 18.014722 },
    { lat: 48.55313, lng: 18.0160336 },
    { lat: 48.5514887, lng: 18.0173596 },
    { lat: 48.5513664, lng: 18.0174655 },
    { lat: 48.5515418, lng: 18.0179905 },
    { lat: 48.5548182, lng: 18.0270722 },
    { lat: 48.5549881, lng: 18.0268541 },
    { lat: 48.5560958, lng: 18.0258331 },
    { lat: 48.5564623, lng: 18.0266104 },
    { lat: 48.5571722, lng: 18.0261537 },
    { lat: 48.55846, lng: 18.0250835 },
    { lat: 48.5590654, lng: 18.0247869 },
    { lat: 48.5587835, lng: 18.0233262 },
    { lat: 48.5603554, lng: 18.0230731 },
    { lat: 48.5610597, lng: 18.0228048 },
    { lat: 48.5612458, lng: 18.0226726 },
    { lat: 48.5617619, lng: 18.0235307 },
    { lat: 48.5618842, lng: 18.0238826 },
    { lat: 48.5628095, lng: 18.023517 },
    { lat: 48.5632836, lng: 18.0232519 },
    { lat: 48.5638565, lng: 18.0252451 },
    { lat: 48.5643326, lng: 18.0252976 },
    { lat: 48.564807, lng: 18.0251736 },
    { lat: 48.5656303, lng: 18.0279817 },
    { lat: 48.5707194, lng: 18.0269904 },
    { lat: 48.5708197, lng: 18.0268536 },
    { lat: 48.5709499, lng: 18.0271287 },
    { lat: 48.5710439, lng: 18.0271332 },
    { lat: 48.5711522, lng: 18.0270377 },
    { lat: 48.5713323, lng: 18.0266119 },
    { lat: 48.5719026, lng: 18.0258322 },
    { lat: 48.5724979, lng: 18.0252363 },
    { lat: 48.5725428, lng: 18.0255539 },
    { lat: 48.5757067, lng: 18.0233281 },
    { lat: 48.5765922, lng: 18.0220435 },
    { lat: 48.5773351, lng: 18.0205977 },
    { lat: 48.5779961, lng: 18.0216789 },
    { lat: 48.5780046, lng: 18.0216902 },
    { lat: 48.5780897, lng: 18.0217543 },
    { lat: 48.5781761, lng: 18.0217067 },
    { lat: 48.5782111, lng: 18.0214243 },
    { lat: 48.5783037, lng: 18.0211945 },
    { lat: 48.5784521, lng: 18.0211289 },
    { lat: 48.5786161, lng: 18.0208872 },
    { lat: 48.578667, lng: 18.0206401 },
    { lat: 48.5791043, lng: 18.0202494 },
    { lat: 48.5793163, lng: 18.0206661 },
    { lat: 48.5801197, lng: 18.0184892 },
    { lat: 48.5800808, lng: 18.0184598 },
    { lat: 48.5801463, lng: 18.0183473 },
    { lat: 48.5804354, lng: 18.0181975 },
    { lat: 48.5805014, lng: 18.0180549 },
    { lat: 48.5805624, lng: 18.0177712 },
    { lat: 48.5805271, lng: 18.0175941 },
    { lat: 48.5805883, lng: 18.0172744 },
    { lat: 48.5805329, lng: 18.0170619 },
    { lat: 48.5805673, lng: 18.01692 },
    { lat: 48.580724, lng: 18.0167653 },
    { lat: 48.5808126, lng: 18.0165049 },
    { lat: 48.5809691, lng: 18.0164799 },
    { lat: 48.5809758, lng: 18.0163859 },
    { lat: 48.581385, lng: 18.0161466 },
    { lat: 48.581543, lng: 18.0154245 },
    { lat: 48.5817529, lng: 18.0151392 },
    { lat: 48.5819311, lng: 18.0146236 },
    { lat: 48.5818674, lng: 18.0144055 },
    { lat: 48.5820098, lng: 18.0137604 },
    { lat: 48.5824063, lng: 18.0131965 },
    { lat: 48.5826772, lng: 18.0122253 },
    { lat: 48.5827516, lng: 18.0122424 },
    { lat: 48.5828868, lng: 18.0119344 },
    { lat: 48.5829326, lng: 18.0116976 },
    { lat: 48.5830492, lng: 18.0115906 },
    { lat: 48.5830991, lng: 18.0110994 },
    { lat: 48.5832079, lng: 18.0110872 },
    { lat: 48.5833977, lng: 18.010595 },
    { lat: 48.5836666, lng: 18.0102208 },
    { lat: 48.5840093, lng: 18.0101005 },
    { lat: 48.5841204, lng: 18.0095383 },
    { lat: 48.5843157, lng: 18.0093655 },
    { lat: 48.5846218, lng: 18.008725 },
    { lat: 48.5848174, lng: 18.0087292 },
    { lat: 48.5849926, lng: 18.0085039 },
    { lat: 48.585007, lng: 18.0081964 },
    { lat: 48.5850688, lng: 18.0079831 },
    { lat: 48.5853242, lng: 18.0074731 },
    { lat: 48.5853436, lng: 18.0072897 },
    { lat: 48.5855144, lng: 18.0070464 },
    { lat: 48.5856223, lng: 18.0066967 },
    { lat: 48.5858215, lng: 18.0066303 },
    { lat: 48.586129, lng: 18.0062206 },
    { lat: 48.5864949, lng: 18.0059577 },
    { lat: 48.5865178, lng: 18.005869 },
    { lat: 48.5868221, lng: 18.0056893 },
    { lat: 48.5870201, lng: 18.0054697 },
    { lat: 48.5870985, lng: 18.0054805 },
    { lat: 48.5872422, lng: 18.0053143 },
    { lat: 48.5874619, lng: 18.0052308 },
    { lat: 48.5877739, lng: 18.0048579 },
    { lat: 48.5879381, lng: 18.004523 },
    { lat: 48.5880218, lng: 18.0046037 },
    { lat: 48.5884766, lng: 18.0040735 },
    { lat: 48.5887793, lng: 18.0034466 },
    { lat: 48.5889458, lng: 18.0033425 },
    { lat: 48.5901035, lng: 18.0016261 },
    { lat: 48.5905517, lng: 18.001173 },
    { lat: 48.5905897, lng: 18.001036 },
    { lat: 48.5906898, lng: 18.0009337 },
    { lat: 48.5908496, lng: 18.0008886 },
    { lat: 48.5909608, lng: 18.0006493 },
    { lat: 48.5912299, lng: 18.0007321 },
    { lat: 48.5915754, lng: 18.0004883 },
    { lat: 48.5917269, lng: 18.0004553 },
    { lat: 48.5920051, lng: 18.0001418 },
    { lat: 48.5921504, lng: 17.9998726 },
    { lat: 48.5925872, lng: 17.9993779 },
    { lat: 48.5929158, lng: 17.9990932 },
    { lat: 48.5930147, lng: 17.9988424 },
    { lat: 48.5931623, lng: 17.9987387 },
    { lat: 48.5934963, lng: 17.998152 },
    { lat: 48.5937354, lng: 17.9975025 },
    { lat: 48.5936667, lng: 17.9972972 },
    { lat: 48.5936744, lng: 17.9971966 },
    { lat: 48.5937503, lng: 17.9970883 },
    { lat: 48.5938313, lng: 17.9965725 },
    { lat: 48.5938016, lng: 17.9963366 },
    { lat: 48.5938593, lng: 17.9962646 },
    { lat: 48.5938225, lng: 17.9956449 },
    { lat: 48.5939597, lng: 17.9952751 },
    { lat: 48.5939642, lng: 17.9945718 },
    { lat: 48.5940175, lng: 17.9941035 },
    { lat: 48.5941273, lng: 17.9938059 },
    { lat: 48.5940959, lng: 17.9937118 },
    { lat: 48.5941637, lng: 17.9933172 },
    { lat: 48.5944354, lng: 17.9927791 },
    { lat: 48.5945853, lng: 17.9925866 },
    { lat: 48.5946715, lng: 17.9925721 },
    { lat: 48.5949185, lng: 17.9919458 },
    { lat: 48.5950083, lng: 17.9919732 },
    { lat: 48.5953469, lng: 17.9914218 },
    { lat: 48.5954358, lng: 17.991402 },
    { lat: 48.5954474, lng: 17.9910649 },
    { lat: 48.5956403, lng: 17.9900971 },
    { lat: 48.5958316, lng: 17.9897617 },
    { lat: 48.5959791, lng: 17.9896045 },
    { lat: 48.5960128, lng: 17.9894676 },
    { lat: 48.5960976, lng: 17.989454 },
    { lat: 48.5963165, lng: 17.9890703 },
    { lat: 48.5963948, lng: 17.9887378 },
    { lat: 48.5964996, lng: 17.9886227 },
    { lat: 48.5965507, lng: 17.9882845 },
    { lat: 48.5968364, lng: 17.9873384 },
    { lat: 48.5970413, lng: 17.9870853 },
    { lat: 48.5971344, lng: 17.9867343 },
    { lat: 48.5972745, lng: 17.9866012 },
    { lat: 48.5975583, lng: 17.9865175 },
    { lat: 48.5981548, lng: 17.986107 },
    { lat: 48.5988871, lng: 17.9858945 },
    { lat: 48.5995609, lng: 17.9850783 },
    { lat: 48.599654, lng: 17.9848429 },
    { lat: 48.5997755, lng: 17.9844959 },
    { lat: 48.599827, lng: 17.98446 },
  ],
  Ardanovce: [
    { lat: 48.534543, lng: 17.9262917 },
    { lat: 48.5360639, lng: 17.923299 },
    { lat: 48.5386229, lng: 17.9176736 },
    { lat: 48.539223, lng: 17.9164825 },
    { lat: 48.5391776, lng: 17.9163799 },
    { lat: 48.5391332, lng: 17.9162885 },
    { lat: 48.5391177, lng: 17.9160655 },
    { lat: 48.5397493, lng: 17.9147265 },
    { lat: 48.5410314, lng: 17.9129805 },
    { lat: 48.5425304, lng: 17.9107124 },
    { lat: 48.5427138, lng: 17.9104597 },
    { lat: 48.5430875, lng: 17.9101247 },
    { lat: 48.5444392, lng: 17.9077915 },
    { lat: 48.544739, lng: 17.9077266 },
    { lat: 48.545234, lng: 17.907867 },
    { lat: 48.5454327, lng: 17.9077019 },
    { lat: 48.5456473, lng: 17.9077225 },
    { lat: 48.5459072, lng: 17.9076306 },
    { lat: 48.5461333, lng: 17.9074113 },
    { lat: 48.5462756, lng: 17.9075471 },
    { lat: 48.5463819, lng: 17.9078668 },
    { lat: 48.5463478, lng: 17.9079209 },
    { lat: 48.5465555, lng: 17.9082192 },
    { lat: 48.5466609, lng: 17.9084681 },
    { lat: 48.5463797, lng: 17.9091603 },
    { lat: 48.5465609, lng: 17.9093361 },
    { lat: 48.5472808, lng: 17.9097278 },
    { lat: 48.5478752, lng: 17.9106181 },
    { lat: 48.5479317, lng: 17.9104337 },
    { lat: 48.5483206, lng: 17.9098793 },
    { lat: 48.549032, lng: 17.9090427 },
    { lat: 48.549452, lng: 17.9086868 },
    { lat: 48.5494854, lng: 17.9085856 },
    { lat: 48.5496507, lng: 17.9084444 },
    { lat: 48.5497779, lng: 17.9083458 },
    { lat: 48.5500735, lng: 17.9082647 },
    { lat: 48.5503295, lng: 17.907944 },
    { lat: 48.5504188, lng: 17.9079467 },
    { lat: 48.5505215, lng: 17.9077196 },
    { lat: 48.5506101, lng: 17.9077107 },
    { lat: 48.5506997, lng: 17.9074195 },
    { lat: 48.5508181, lng: 17.9072683 },
    { lat: 48.5509381, lng: 17.9072348 },
    { lat: 48.5510098, lng: 17.9070379 },
    { lat: 48.5510983, lng: 17.9069528 },
    { lat: 48.5512054, lng: 17.9068779 },
    { lat: 48.5513155, lng: 17.906886 },
    { lat: 48.5517371, lng: 17.9064653 },
    { lat: 48.5521724, lng: 17.9063441 },
    { lat: 48.5521868, lng: 17.9062495 },
    { lat: 48.552281, lng: 17.9062933 },
    { lat: 48.5524317, lng: 17.9062466 },
    { lat: 48.5526195, lng: 17.9060464 },
    { lat: 48.5527246, lng: 17.9060127 },
    { lat: 48.5528311, lng: 17.9058328 },
    { lat: 48.5529257, lng: 17.9059236 },
    { lat: 48.5529715, lng: 17.9058396 },
    { lat: 48.5530537, lng: 17.9058363 },
    { lat: 48.5530556, lng: 17.905754 },
    { lat: 48.5531837, lng: 17.9056905 },
    { lat: 48.5532823, lng: 17.9057519 },
    { lat: 48.5533385, lng: 17.905597 },
    { lat: 48.5534508, lng: 17.9055696 },
    { lat: 48.5535335, lng: 17.9053491 },
    { lat: 48.5535654, lng: 17.9054302 },
    { lat: 48.5536236, lng: 17.9053635 },
    { lat: 48.5536026, lng: 17.9053176 },
    { lat: 48.5536635, lng: 17.9052212 },
    { lat: 48.5537927, lng: 17.9052695 },
    { lat: 48.5539189, lng: 17.9050591 },
    { lat: 48.5540246, lng: 17.9051258 },
    { lat: 48.5541134, lng: 17.9048404 },
    { lat: 48.5542195, lng: 17.9048191 },
    { lat: 48.5542918, lng: 17.9049514 },
    { lat: 48.5545371, lng: 17.9047315 },
    { lat: 48.5555908, lng: 17.9035062 },
    { lat: 48.5558677, lng: 17.9030258 },
    { lat: 48.5559274, lng: 17.9028355 },
    { lat: 48.5559886, lng: 17.902804 },
    { lat: 48.556053, lng: 17.9026548 },
    { lat: 48.5561696, lng: 17.9026035 },
    { lat: 48.5561522, lng: 17.9025044 },
    { lat: 48.5562276, lng: 17.9023661 },
    { lat: 48.5564629, lng: 17.9021693 },
    { lat: 48.5565998, lng: 17.9019528 },
    { lat: 48.5568667, lng: 17.9018083 },
    { lat: 48.5570611, lng: 17.9013252 },
    { lat: 48.5574834, lng: 17.9008804 },
    { lat: 48.5583873, lng: 17.9000896 },
    { lat: 48.5592349, lng: 17.8994315 },
    { lat: 48.5594683, lng: 17.8993525 },
    { lat: 48.5597481, lng: 17.8991435 },
    { lat: 48.5601088, lng: 17.8987634 },
    { lat: 48.5603497, lng: 17.8986196 },
    { lat: 48.560919, lng: 17.8979725 },
    { lat: 48.5615659, lng: 17.8966074 },
    { lat: 48.562166, lng: 17.8957775 },
    { lat: 48.5628305, lng: 17.8946295 },
    { lat: 48.56295, lng: 17.8942931 },
    { lat: 48.562987, lng: 17.8939512 },
    { lat: 48.5629626, lng: 17.893734 },
    { lat: 48.5630455, lng: 17.8932746 },
    { lat: 48.5632568, lng: 17.8928134 },
    { lat: 48.5631787, lng: 17.8925849 },
    { lat: 48.5632163, lng: 17.8924789 },
    { lat: 48.5630865, lng: 17.892104 },
    { lat: 48.563124, lng: 17.8919036 },
    { lat: 48.5631075, lng: 17.8917034 },
    { lat: 48.5631688, lng: 17.8915795 },
    { lat: 48.5630601, lng: 17.891201 },
    { lat: 48.5631117, lng: 17.8909309 },
    { lat: 48.5630624, lng: 17.8904712 },
    { lat: 48.562962, lng: 17.8900819 },
    { lat: 48.5631514, lng: 17.8896657 },
    { lat: 48.5630553, lng: 17.8892352 },
    { lat: 48.5630906, lng: 17.8890707 },
    { lat: 48.5629371, lng: 17.8886216 },
    { lat: 48.5631621, lng: 17.8876938 },
    { lat: 48.5631571, lng: 17.8869582 },
    { lat: 48.5633027, lng: 17.8866592 },
    { lat: 48.5633905, lng: 17.8862066 },
    { lat: 48.563605, lng: 17.8840956 },
    { lat: 48.5646539, lng: 17.8829979 },
    { lat: 48.5651769, lng: 17.882967 },
    { lat: 48.5655585, lng: 17.8828583 },
    { lat: 48.5661161, lng: 17.8828748 },
    { lat: 48.5664996, lng: 17.8827195 },
    { lat: 48.5666865, lng: 17.8827509 },
    { lat: 48.5670697, lng: 17.8825361 },
    { lat: 48.5679003, lng: 17.8824548 },
    { lat: 48.5684936, lng: 17.8823185 },
    { lat: 48.5690985, lng: 17.8820529 },
    { lat: 48.5699242, lng: 17.8814655 },
    { lat: 48.56998, lng: 17.881306 },
    { lat: 48.569798, lng: 17.880831 },
    { lat: 48.569584, lng: 17.880557 },
    { lat: 48.569345, lng: 17.880311 },
    { lat: 48.569217, lng: 17.880151 },
    { lat: 48.56899, lng: 17.87976 },
    { lat: 48.568904, lng: 17.879489 },
    { lat: 48.568728, lng: 17.87925 },
    { lat: 48.568457, lng: 17.879138 },
    { lat: 48.568289, lng: 17.878996 },
    { lat: 48.568007, lng: 17.878646 },
    { lat: 48.567405, lng: 17.87825 },
    { lat: 48.566789, lng: 17.877895 },
    { lat: 48.566554, lng: 17.877807 },
    { lat: 48.566269, lng: 17.877619 },
    { lat: 48.566081, lng: 17.877437 },
    { lat: 48.565885, lng: 17.877299 },
    { lat: 48.565608, lng: 17.877005 },
    { lat: 48.565536, lng: 17.876928 },
    { lat: 48.565514, lng: 17.876904 },
    { lat: 48.565117, lng: 17.876311 },
    { lat: 48.564996, lng: 17.876059 },
    { lat: 48.564826, lng: 17.875807 },
    { lat: 48.564609, lng: 17.875611 },
    { lat: 48.564495, lng: 17.87545 },
    { lat: 48.564418, lng: 17.875385 },
    { lat: 48.564325, lng: 17.875302 },
    { lat: 48.564254, lng: 17.875242 },
    { lat: 48.564173, lng: 17.875159 },
    { lat: 48.564127, lng: 17.875106 },
    { lat: 48.564057, lng: 17.875017 },
    { lat: 48.564005, lng: 17.874952 },
    { lat: 48.563971, lng: 17.874922 },
    { lat: 48.563909, lng: 17.874863 },
    { lat: 48.563847, lng: 17.874809 },
    { lat: 48.563792, lng: 17.874761 },
    { lat: 48.563746, lng: 17.874702 },
    { lat: 48.563699, lng: 17.874613 },
    { lat: 48.563653, lng: 17.87453 },
    { lat: 48.563602, lng: 17.874448 },
    { lat: 48.563556, lng: 17.874365 },
    { lat: 48.563513, lng: 17.874282 },
    { lat: 48.563486, lng: 17.874205 },
    { lat: 48.563444, lng: 17.874117 },
    { lat: 48.563381, lng: 17.873981 },
    { lat: 48.563351, lng: 17.87391 },
    { lat: 48.5633, lng: 17.873816 },
    { lat: 48.563243, lng: 17.873727 },
    { lat: 48.563184, lng: 17.873614 },
    { lat: 48.563153, lng: 17.873532 },
    { lat: 48.563115, lng: 17.873425 },
    { lat: 48.563075, lng: 17.873331 },
    { lat: 48.563045, lng: 17.873278 },
    { lat: 48.56299, lng: 17.873195 },
    { lat: 48.562941, lng: 17.8731 },
    { lat: 48.562917, lng: 17.873071 },
    { lat: 48.562866, lng: 17.873006 },
    { lat: 48.56282, lng: 17.872935 },
    { lat: 48.562766, lng: 17.872863 },
    { lat: 48.562712, lng: 17.872804 },
    { lat: 48.562664, lng: 17.87275 },
    { lat: 48.562638, lng: 17.872703 },
    { lat: 48.562603, lng: 17.87265 },
    { lat: 48.562559, lng: 17.872585 },
    { lat: 48.562514, lng: 17.872502 },
    { lat: 48.562467, lng: 17.872431 },
    { lat: 48.562413, lng: 17.872366 },
    { lat: 48.562366, lng: 17.872301 },
    { lat: 48.562319, lng: 17.872247 },
    { lat: 48.562281, lng: 17.8722 },
    { lat: 48.562238, lng: 17.872158 },
    { lat: 48.562199, lng: 17.872129 },
    { lat: 48.562172, lng: 17.872093 },
    { lat: 48.562133, lng: 17.872051 },
    { lat: 48.56209, lng: 17.87201 },
    { lat: 48.562021, lng: 17.871927 },
    { lat: 48.561951, lng: 17.87185 },
    { lat: 48.561903, lng: 17.871797 },
    { lat: 48.561838, lng: 17.871737 },
    { lat: 48.561806, lng: 17.871707 },
    { lat: 48.56176, lng: 17.871672 },
    { lat: 48.561721, lng: 17.871642 },
    { lat: 48.561663, lng: 17.871594 },
    { lat: 48.561589, lng: 17.871547 },
    { lat: 48.561534, lng: 17.871511 },
    { lat: 48.561499, lng: 17.871476 },
    { lat: 48.561445, lng: 17.871457 },
    { lat: 48.561394, lng: 17.871427 },
    { lat: 48.561328, lng: 17.871397 },
    { lat: 48.561296, lng: 17.871391 },
    { lat: 48.561238, lng: 17.871385 },
    { lat: 48.561168, lng: 17.871367 },
    { lat: 48.561117, lng: 17.871361 },
    { lat: 48.561036, lng: 17.871342 },
    { lat: 48.560966, lng: 17.871324 },
    { lat: 48.560915, lng: 17.871318 },
    { lat: 48.560845, lng: 17.871305 },
    { lat: 48.560814, lng: 17.871311 },
    { lat: 48.56077, lng: 17.871316 },
    { lat: 48.560739, lng: 17.871322 },
    { lat: 48.560684, lng: 17.871333 },
    { lat: 48.560607, lng: 17.871338 },
    { lat: 48.560544, lng: 17.871349 },
    { lat: 48.560509, lng: 17.871367 },
    { lat: 48.560435, lng: 17.871407 },
    { lat: 48.560396, lng: 17.871425 },
    { lat: 48.560329, lng: 17.871466 },
    { lat: 48.560239, lng: 17.871512 },
    { lat: 48.560212, lng: 17.871512 },
    { lat: 48.560173, lng: 17.871517 },
    { lat: 48.56013, lng: 17.871517 },
    { lat: 48.56008, lng: 17.871528 },
    { lat: 48.560033, lng: 17.871522 },
    { lat: 48.559986, lng: 17.87151 },
    { lat: 48.559923, lng: 17.871497 },
    { lat: 48.559877, lng: 17.871492 },
    { lat: 48.559819, lng: 17.871485 },
    { lat: 48.559749, lng: 17.8714714 },
    { lat: 48.559686, lng: 17.871443 },
    { lat: 48.559631, lng: 17.871424 },
    { lat: 48.559565, lng: 17.871412 },
    { lat: 48.559503, lng: 17.871394 },
    { lat: 48.559472, lng: 17.871394 },
    { lat: 48.559421, lng: 17.871393 },
    { lat: 48.559367, lng: 17.871387 },
    { lat: 48.559331, lng: 17.871393 },
    { lat: 48.559218, lng: 17.871386 },
    { lat: 48.559203, lng: 17.871397 },
    { lat: 48.559159, lng: 17.871426 },
    { lat: 48.559116, lng: 17.871444 },
    { lat: 48.559093, lng: 17.871473 },
    { lat: 48.559043, lng: 17.871514 },
    { lat: 48.558988, lng: 17.87156 },
    { lat: 48.558952, lng: 17.871589 },
    { lat: 48.55891, lng: 17.87163 },
    { lat: 48.558871, lng: 17.871659 },
    { lat: 48.558855, lng: 17.871677 },
    { lat: 48.558832, lng: 17.8717 },
    { lat: 48.558764, lng: 17.871766 },
    { lat: 48.558757, lng: 17.871772 },
    { lat: 48.558679, lng: 17.87185 },
    { lat: 48.558618, lng: 17.871899 },
    { lat: 48.558582, lng: 17.87197 },
    { lat: 48.558564, lng: 17.872053 },
    { lat: 48.5585085, lng: 17.8721474 },
    { lat: 48.5582798, lng: 17.8725816 },
    { lat: 48.5585615, lng: 17.8730977 },
    { lat: 48.5592398, lng: 17.8738215 },
    { lat: 48.5583715, lng: 17.875104 },
    { lat: 48.5575052, lng: 17.877091 },
    { lat: 48.5571611, lng: 17.8773928 },
    { lat: 48.5561298, lng: 17.877957 },
    { lat: 48.5552742, lng: 17.8786283 },
    { lat: 48.5551868, lng: 17.8788652 },
    { lat: 48.5543467, lng: 17.8802587 },
    { lat: 48.5537641, lng: 17.8809472 },
    { lat: 48.5533038, lng: 17.88013 },
    { lat: 48.5528724, lng: 17.8811555 },
    { lat: 48.5521647, lng: 17.8825165 },
    { lat: 48.55186, lng: 17.8828174 },
    { lat: 48.5511195, lng: 17.8833387 },
    { lat: 48.550923, lng: 17.8836023 },
    { lat: 48.5506768, lng: 17.8834665 },
    { lat: 48.5503281, lng: 17.883416 },
    { lat: 48.5499197, lng: 17.8835312 },
    { lat: 48.5496739, lng: 17.8834602 },
    { lat: 48.5492527, lng: 17.8835112 },
    { lat: 48.5490708, lng: 17.8836213 },
    { lat: 48.5487593, lng: 17.884092 },
    { lat: 48.5479085, lng: 17.8848628 },
    { lat: 48.5474457, lng: 17.8853845 },
    { lat: 48.5472227, lng: 17.8857304 },
    { lat: 48.5452392, lng: 17.8874428 },
    { lat: 48.5451198, lng: 17.8876156 },
    { lat: 48.5448223, lng: 17.8882976 },
    { lat: 48.5444831, lng: 17.8887161 },
    { lat: 48.5437709, lng: 17.8892608 },
    { lat: 48.5426163, lng: 17.8904964 },
    { lat: 48.5422342, lng: 17.8908394 },
    { lat: 48.5418479, lng: 17.8912657 },
    { lat: 48.5410132, lng: 17.8924719 },
    { lat: 48.5409827, lng: 17.8923593 },
    { lat: 48.5387027, lng: 17.8940082 },
    { lat: 48.5357962, lng: 17.8974496 },
    { lat: 48.5352206, lng: 17.8983683 },
    { lat: 48.5348918, lng: 17.8984535 },
    { lat: 48.5345402, lng: 17.8988201 },
    { lat: 48.5342003, lng: 17.8992869 },
    { lat: 48.5333703, lng: 17.9008505 },
    { lat: 48.5327078, lng: 17.9018429 },
    { lat: 48.5317441, lng: 17.9034378 },
    { lat: 48.5306182, lng: 17.9057127 },
    { lat: 48.5301644, lng: 17.9068172 },
    { lat: 48.5298177, lng: 17.9078646 },
    { lat: 48.5309622, lng: 17.9102215 },
    { lat: 48.5301554, lng: 17.9114621 },
    { lat: 48.5297356, lng: 17.9123062 },
    { lat: 48.5295986, lng: 17.9127697 },
    { lat: 48.5295087, lng: 17.9132788 },
    { lat: 48.5294994, lng: 17.9136319 },
    { lat: 48.5295532, lng: 17.9141117 },
    { lat: 48.5294627, lng: 17.9142975 },
    { lat: 48.5294727, lng: 17.9144792 },
    { lat: 48.529351, lng: 17.9146543 },
    { lat: 48.5291895, lng: 17.9147661 },
    { lat: 48.5292064, lng: 17.9148771 },
    { lat: 48.5291312, lng: 17.9150148 },
    { lat: 48.5290798, lng: 17.9150055 },
    { lat: 48.5289497, lng: 17.9151511 },
    { lat: 48.5288256, lng: 17.9154319 },
    { lat: 48.5286785, lng: 17.9155371 },
    { lat: 48.5284489, lng: 17.9159983 },
    { lat: 48.5282953, lng: 17.9166916 },
    { lat: 48.5281539, lng: 17.917644 },
    { lat: 48.5281685, lng: 17.9181851 },
    { lat: 48.5280809, lng: 17.9190902 },
    { lat: 48.5293405, lng: 17.9191661 },
    { lat: 48.529391, lng: 17.9192899 },
    { lat: 48.5296375, lng: 17.9191851 },
    { lat: 48.5300707, lng: 17.920663 },
    { lat: 48.5299883, lng: 17.9207213 },
    { lat: 48.5300622, lng: 17.92101 },
    { lat: 48.5307669, lng: 17.9232669 },
    { lat: 48.5310849, lng: 17.9229817 },
    { lat: 48.5315826, lng: 17.9226947 },
    { lat: 48.5328179, lng: 17.9243725 },
    { lat: 48.5344209, lng: 17.9261217 },
    { lat: 48.534543, lng: 17.9262917 },
  ],
  Súlovce: [
    { lat: 48.439368, lng: 18.1945 },
    { lat: 48.439565, lng: 18.195058 },
    { lat: 48.439575, lng: 18.195089 },
    { lat: 48.439584, lng: 18.195138 },
    { lat: 48.439922, lng: 18.196603 },
    { lat: 48.441294, lng: 18.197792 },
    { lat: 48.442228, lng: 18.198603 },
    { lat: 48.44226, lng: 18.198629 },
    { lat: 48.442269, lng: 18.198638 },
    { lat: 48.442406, lng: 18.19876 },
    { lat: 48.442939, lng: 18.199241 },
    { lat: 48.443768, lng: 18.200772 },
    { lat: 48.444193, lng: 18.201317 },
    { lat: 48.444251, lng: 18.201465 },
    { lat: 48.444394, lng: 18.201822 },
    { lat: 48.444521, lng: 18.202171 },
    { lat: 48.444558, lng: 18.202273 },
    { lat: 48.444668, lng: 18.203026 },
    { lat: 48.444733, lng: 18.203682 },
    { lat: 48.44475, lng: 18.204333 },
    { lat: 48.444988, lng: 18.205042 },
    { lat: 48.445342, lng: 18.205834 },
    { lat: 48.445469, lng: 18.206177 },
    { lat: 48.445536, lng: 18.206331 },
    { lat: 48.445581, lng: 18.206654 },
    { lat: 48.445661, lng: 18.206828 },
    { lat: 48.445884, lng: 18.207201 },
    { lat: 48.446048, lng: 18.207495 },
    { lat: 48.446281, lng: 18.207793 },
    { lat: 48.446509, lng: 18.208271 },
    { lat: 48.446886, lng: 18.208931 },
    { lat: 48.447701, lng: 18.209684 },
    { lat: 48.447941, lng: 18.209945 },
    { lat: 48.448835, lng: 18.210654 },
    { lat: 48.448966, lng: 18.2108 },
    { lat: 48.44907, lng: 18.210968 },
    { lat: 48.449298, lng: 18.211417 },
    { lat: 48.449826, lng: 18.212389 },
    { lat: 48.450014, lng: 18.212817 },
    { lat: 48.450275, lng: 18.21357 },
    { lat: 48.450287, lng: 18.213602 },
    { lat: 48.450381, lng: 18.213777 },
    { lat: 48.450579, lng: 18.213957 },
    { lat: 48.450762, lng: 18.214157 },
    { lat: 48.451122, lng: 18.214778 },
    { lat: 48.451248, lng: 18.21505 },
    { lat: 48.451329, lng: 18.215357 },
    { lat: 48.451685, lng: 18.216012 },
    { lat: 48.451759, lng: 18.216262 },
    { lat: 48.451953, lng: 18.216565 },
    { lat: 48.452026, lng: 18.216797 },
    { lat: 48.452118, lng: 18.217337 },
    { lat: 48.4521226, lng: 18.2173475 },
    { lat: 48.452156, lng: 18.217423 },
    { lat: 48.452182, lng: 18.217486 },
    { lat: 48.452262, lng: 18.217759 },
    { lat: 48.452289, lng: 18.218176 },
    { lat: 48.452392, lng: 18.218589 },
    { lat: 48.452573, lng: 18.219162 },
    { lat: 48.452651, lng: 18.21941 },
    { lat: 48.452859, lng: 18.220529 },
    { lat: 48.452876, lng: 18.22084 },
    { lat: 48.452863, lng: 18.221154 },
    { lat: 48.453167, lng: 18.222194 },
    { lat: 48.453428, lng: 18.222486 },
    { lat: 48.453709, lng: 18.222694 },
    { lat: 48.455747, lng: 18.2194058 },
    { lat: 48.4598499, lng: 18.2115615 },
    { lat: 48.4607194, lng: 18.2097959 },
    { lat: 48.4650739, lng: 18.2016237 },
    { lat: 48.4676914, lng: 18.1965628 },
    { lat: 48.4683106, lng: 18.1954642 },
    { lat: 48.4694891, lng: 18.1940654 },
    { lat: 48.4698506, lng: 18.1930964 },
    { lat: 48.4699055, lng: 18.1925036 },
    { lat: 48.4700058, lng: 18.1922103 },
    { lat: 48.4703168, lng: 18.1917339 },
    { lat: 48.4709047, lng: 18.1910109 },
    { lat: 48.4711115, lng: 18.1906289 },
    { lat: 48.4715655, lng: 18.1895425 },
    { lat: 48.4717911, lng: 18.189131 },
    { lat: 48.4726615, lng: 18.1867407 },
    { lat: 48.473194, lng: 18.1855483 },
    { lat: 48.4734152, lng: 18.184791 },
    { lat: 48.4736837, lng: 18.1842622 },
    { lat: 48.474205, lng: 18.1835218 },
    { lat: 48.4745037, lng: 18.1829339 },
    { lat: 48.4750531, lng: 18.1820343 },
    { lat: 48.4752634, lng: 18.181905 },
    { lat: 48.4754338, lng: 18.1816697 },
    { lat: 48.4758006, lng: 18.1813403 },
    { lat: 48.4764465, lng: 18.1808807 },
    { lat: 48.4768206, lng: 18.1804042 },
    { lat: 48.4768821, lng: 18.1804335 },
    { lat: 48.4773956, lng: 18.1792643 },
    { lat: 48.4783403, lng: 18.1776129 },
    { lat: 48.4788113, lng: 18.1766491 },
    { lat: 48.4798347, lng: 18.1739622 },
    { lat: 48.4807481, lng: 18.170761 },
    { lat: 48.4817264, lng: 18.1678624 },
    { lat: 48.4825609, lng: 18.1650569 },
    { lat: 48.4829891, lng: 18.1639772 },
    { lat: 48.4833455, lng: 18.163469 },
    { lat: 48.4803167, lng: 18.1620105 },
    { lat: 48.4802767, lng: 18.1619653 },
    { lat: 48.4802448, lng: 18.1613385 },
    { lat: 48.4801714, lng: 18.1611422 },
    { lat: 48.4798857, lng: 18.1608899 },
    { lat: 48.4798675, lng: 18.1607674 },
    { lat: 48.4799728, lng: 18.1605814 },
    { lat: 48.4802186, lng: 18.1605591 },
    { lat: 48.48031, lng: 18.1606552 },
    { lat: 48.4804919, lng: 18.1604068 },
    { lat: 48.480575, lng: 18.1600516 },
    { lat: 48.4808217, lng: 18.159906 },
    { lat: 48.4806775, lng: 18.1597358 },
    { lat: 48.4806166, lng: 18.1595627 },
    { lat: 48.4808787, lng: 18.1581898 },
    { lat: 48.4809419, lng: 18.1580524 },
    { lat: 48.4812562, lng: 18.1577926 },
    { lat: 48.4812179, lng: 18.157618 },
    { lat: 48.4813112, lng: 18.1573969 },
    { lat: 48.4815343, lng: 18.1572353 },
    { lat: 48.4818427, lng: 18.1572402 },
    { lat: 48.4819507, lng: 18.1569831 },
    { lat: 48.4820009, lng: 18.1567403 },
    { lat: 48.4820028, lng: 18.1564234 },
    { lat: 48.481957, lng: 18.1562783 },
    { lat: 48.4820801, lng: 18.1558331 },
    { lat: 48.4821651, lng: 18.1549846 },
    { lat: 48.4822457, lng: 18.1546642 },
    { lat: 48.4825003, lng: 18.1549469 },
    { lat: 48.482588, lng: 18.1546323 },
    { lat: 48.4826638, lng: 18.1545174 },
    { lat: 48.4830673, lng: 18.1544127 },
    { lat: 48.483238, lng: 18.1541647 },
    { lat: 48.4834114, lng: 18.1536936 },
    { lat: 48.4833877, lng: 18.153266 },
    { lat: 48.4834295, lng: 18.1530182 },
    { lat: 48.4835644, lng: 18.1528888 },
    { lat: 48.4839801, lng: 18.15286 },
    { lat: 48.4842772, lng: 18.1526715 },
    { lat: 48.4844999, lng: 18.1520926 },
    { lat: 48.4845598, lng: 18.1517852 },
    { lat: 48.4847615, lng: 18.1514886 },
    { lat: 48.4851287, lng: 18.151362 },
    { lat: 48.4859787, lng: 18.1499295 },
    { lat: 48.4860009, lng: 18.1496523 },
    { lat: 48.4859526, lng: 18.1493681 },
    { lat: 48.4862272, lng: 18.1490836 },
    { lat: 48.4863397, lng: 18.1490378 },
    { lat: 48.4864421, lng: 18.1488393 },
    { lat: 48.4863434, lng: 18.1484041 },
    { lat: 48.486398, lng: 18.1482169 },
    { lat: 48.4865939, lng: 18.1480548 },
    { lat: 48.4868323, lng: 18.1479989 },
    { lat: 48.4869023, lng: 18.1480405 },
    { lat: 48.4870152, lng: 18.1479246 },
    { lat: 48.4869783, lng: 18.147408 },
    { lat: 48.4866812, lng: 18.1467655 },
    { lat: 48.4866365, lng: 18.1464598 },
    { lat: 48.4868711, lng: 18.1461688 },
    { lat: 48.4869074, lng: 18.1460167 },
    { lat: 48.4868789, lng: 18.1452948 },
    { lat: 48.4869305, lng: 18.1452311 },
    { lat: 48.4870585, lng: 18.1452971 },
    { lat: 48.487293, lng: 18.1451587 },
    { lat: 48.4873049, lng: 18.1450704 },
    { lat: 48.4872436, lng: 18.1449465 },
    { lat: 48.4868648, lng: 18.1448787 },
    { lat: 48.486753, lng: 18.1447009 },
    { lat: 48.486931, lng: 18.1442335 },
    { lat: 48.4871734, lng: 18.1439781 },
    { lat: 48.4871429, lng: 18.1438196 },
    { lat: 48.4873808, lng: 18.1429832 },
    { lat: 48.4881022, lng: 18.1410548 },
    { lat: 48.4884972, lng: 18.1398152 },
    { lat: 48.488937, lng: 18.1372914 },
    { lat: 48.4898561, lng: 18.1337632 },
    { lat: 48.4901105, lng: 18.1326278 },
    { lat: 48.4901582, lng: 18.1324404 },
    { lat: 48.4901267, lng: 18.1323827 },
    { lat: 48.4898341, lng: 18.1323402 },
    { lat: 48.4890923, lng: 18.1326457 },
    { lat: 48.488848, lng: 18.1328081 },
    { lat: 48.4884928, lng: 18.1331596 },
    { lat: 48.4882566, lng: 18.1335216 },
    { lat: 48.4851749, lng: 18.1329476 },
    { lat: 48.4827874, lng: 18.1380221 },
    { lat: 48.4824441, lng: 18.1379215 },
    { lat: 48.482095, lng: 18.1376044 },
    { lat: 48.4815036, lng: 18.1366563 },
    { lat: 48.4812305, lng: 18.135903 },
    { lat: 48.4812207, lng: 18.1351346 },
    { lat: 48.4813465, lng: 18.1346982 },
    { lat: 48.4815559, lng: 18.1345539 },
    { lat: 48.4821702, lng: 18.1343856 },
    { lat: 48.4823943, lng: 18.1342063 },
    { lat: 48.4825147, lng: 18.1340047 },
    { lat: 48.4825034, lng: 18.1336 },
    { lat: 48.4823098, lng: 18.1332037 },
    { lat: 48.4821528, lng: 18.1330473 },
    { lat: 48.481911, lng: 18.1329632 },
    { lat: 48.4816151, lng: 18.1330148 },
    { lat: 48.4814061, lng: 18.1331708 },
    { lat: 48.4804821, lng: 18.1342184 },
    { lat: 48.4802102, lng: 18.134393 },
    { lat: 48.4798236, lng: 18.1343053 },
    { lat: 48.4791372, lng: 18.1378977 },
    { lat: 48.4790858, lng: 18.1385252 },
    { lat: 48.4783811, lng: 18.1434697 },
    { lat: 48.4775107, lng: 18.1480165 },
    { lat: 48.4768116, lng: 18.1477188 },
    { lat: 48.4761506, lng: 18.1473154 },
    { lat: 48.4758333, lng: 18.1469841 },
    { lat: 48.4752131, lng: 18.1457884 },
    { lat: 48.4733769, lng: 18.1541429 },
    { lat: 48.4724904, lng: 18.1570414 },
    { lat: 48.470841, lng: 18.1619369 },
    { lat: 48.4676769, lng: 18.1601339 },
    { lat: 48.4676334, lng: 18.1600639 },
    { lat: 48.4675012, lng: 18.1603167 },
    { lat: 48.4658598, lng: 18.1592375 },
    { lat: 48.4657136, lng: 18.1590331 },
    { lat: 48.4642585, lng: 18.1583654 },
    { lat: 48.4624083, lng: 18.1571896 },
    { lat: 48.4622593, lng: 18.1579666 },
    { lat: 48.4618754, lng: 18.1589892 },
    { lat: 48.4615355, lng: 18.1600927 },
    { lat: 48.4613592, lng: 18.1604366 },
    { lat: 48.461037, lng: 18.1614347 },
    { lat: 48.4606038, lng: 18.1630503 },
    { lat: 48.4597839, lng: 18.1649614 },
    { lat: 48.4589995, lng: 18.1673292 },
    { lat: 48.457385, lng: 18.1697255 },
    { lat: 48.4506551, lng: 18.179138 },
    { lat: 48.4456783, lng: 18.1859669 },
    { lat: 48.4404605, lng: 18.1930015 },
    { lat: 48.4394755, lng: 18.1944054 },
    { lat: 48.439368, lng: 18.1945 },
  ],
  NitrianskaStreda: [
    { lat: 48.5218883, lng: 18.1566986 },
    { lat: 48.5217713, lng: 18.1567852 },
    { lat: 48.5214501, lng: 18.1573943 },
    { lat: 48.52115, lng: 18.1582283 },
    { lat: 48.521055, lng: 18.1590026 },
    { lat: 48.5210845, lng: 18.1591223 },
    { lat: 48.5196275, lng: 18.1617796 },
    { lat: 48.5197947, lng: 18.1619587 },
    { lat: 48.5191431, lng: 18.1631547 },
    { lat: 48.5197676, lng: 18.1636716 },
    { lat: 48.5190029, lng: 18.1670207 },
    { lat: 48.5184575, lng: 18.1689388 },
    { lat: 48.5178988, lng: 18.1706315 },
    { lat: 48.5172076, lng: 18.1724715 },
    { lat: 48.5164641, lng: 18.1711849 },
    { lat: 48.5154518, lng: 18.1736012 },
    { lat: 48.5098912, lng: 18.1893884 },
    { lat: 48.5102729, lng: 18.1896107 },
    { lat: 48.5094064, lng: 18.1923039 },
    { lat: 48.5080089, lng: 18.1907955 },
    { lat: 48.5076866, lng: 18.1905499 },
    { lat: 48.5038825, lng: 18.1894522 },
    { lat: 48.5031938, lng: 18.1901299 },
    { lat: 48.5023525, lng: 18.1896922 },
    { lat: 48.5023738, lng: 18.189435 },
    { lat: 48.5025314, lng: 18.1889425 },
    { lat: 48.5021189, lng: 18.1887161 },
    { lat: 48.501282, lng: 18.1893456 },
    { lat: 48.5009662, lng: 18.1896421 },
    { lat: 48.5008488, lng: 18.1904414 },
    { lat: 48.5009147, lng: 18.1904879 },
    { lat: 48.5006105, lng: 18.191529 },
    { lat: 48.5004637, lng: 18.1918823 },
    { lat: 48.500049, lng: 18.1924956 },
    { lat: 48.4998288, lng: 18.1931375 },
    { lat: 48.4994338, lng: 18.1939526 },
    { lat: 48.4992179, lng: 18.194398 },
    { lat: 48.4988932, lng: 18.1952044 },
    { lat: 48.4988481, lng: 18.1956917 },
    { lat: 48.4986144, lng: 18.1967616 },
    { lat: 48.4984598, lng: 18.1981303 },
    { lat: 48.4984139, lng: 18.1995627 },
    { lat: 48.4982717, lng: 18.2010461 },
    { lat: 48.4983198, lng: 18.201703 },
    { lat: 48.4985887, lng: 18.2027438 },
    { lat: 48.4986773, lng: 18.2036935 },
    { lat: 48.4987487, lng: 18.2038564 },
    { lat: 48.4988656, lng: 18.2048825 },
    { lat: 48.4992259, lng: 18.2065326 },
    { lat: 48.4993206, lng: 18.2072357 },
    { lat: 48.4992778, lng: 18.2082521 },
    { lat: 48.4990983, lng: 18.2088779 },
    { lat: 48.4990155, lng: 18.2089736 },
    { lat: 48.4972618, lng: 18.2109992 },
    { lat: 48.4962669, lng: 18.2118669 },
    { lat: 48.4960082, lng: 18.2121946 },
    { lat: 48.4957111, lng: 18.2126396 },
    { lat: 48.4953072, lng: 18.213463 },
    { lat: 48.4945696, lng: 18.2144675 },
    { lat: 48.4943582, lng: 18.2148529 },
    { lat: 48.4939793, lng: 18.2157576 },
    { lat: 48.4936499, lng: 18.2161094 },
    { lat: 48.4930352, lng: 18.2169779 },
    { lat: 48.492903, lng: 18.2171133 },
    { lat: 48.4926113, lng: 18.2171798 },
    { lat: 48.4920876, lng: 18.2180584 },
    { lat: 48.491672, lng: 18.2185246 },
    { lat: 48.4915706, lng: 18.2187018 },
    { lat: 48.4915131, lng: 18.2189957 },
    { lat: 48.4908644, lng: 18.2198807 },
    { lat: 48.4905277, lng: 18.2205229 },
    { lat: 48.4900957, lng: 18.2209367 },
    { lat: 48.4890326, lng: 18.222247 },
    { lat: 48.4883599, lng: 18.2226331 },
    { lat: 48.4881382, lng: 18.2230746 },
    { lat: 48.4877198, lng: 18.2241401 },
    { lat: 48.4872137, lng: 18.2259518 },
    { lat: 48.4872302, lng: 18.2261396 },
    { lat: 48.4874947, lng: 18.2264253 },
    { lat: 48.4877921, lng: 18.2269045 },
    { lat: 48.4879102, lng: 18.2273501 },
    { lat: 48.487842, lng: 18.2278495 },
    { lat: 48.4873422, lng: 18.2292029 },
    { lat: 48.4868466, lng: 18.2300631 },
    { lat: 48.486335, lng: 18.2312526 },
    { lat: 48.4859424, lng: 18.2317658 },
    { lat: 48.4856937, lng: 18.2319137 },
    { lat: 48.4853593, lng: 18.2322624 },
    { lat: 48.4853479, lng: 18.2324972 },
    { lat: 48.4854421, lng: 18.2326785 },
    { lat: 48.4856099, lng: 18.2328124 },
    { lat: 48.4859786, lng: 18.2334559 },
    { lat: 48.4835924, lng: 18.2371517 },
    { lat: 48.4825831, lng: 18.2388136 },
    { lat: 48.4812952, lng: 18.2410872 },
    { lat: 48.4805124, lng: 18.2426247 },
    { lat: 48.4803966, lng: 18.243042 },
    { lat: 48.479488, lng: 18.2450616 },
    { lat: 48.4785577, lng: 18.2460982 },
    { lat: 48.4780782, lng: 18.2464524 },
    { lat: 48.4775076, lng: 18.2481709 },
    { lat: 48.4770335, lng: 18.2485781 },
    { lat: 48.4755724, lng: 18.249524 },
    { lat: 48.4748621, lng: 18.2503608 },
    { lat: 48.474748, lng: 18.250493 },
    { lat: 48.475184, lng: 18.251143 },
    { lat: 48.475899, lng: 18.252254 },
    { lat: 48.476065, lng: 18.252506 },
    { lat: 48.477108, lng: 18.253095 },
    { lat: 48.478514, lng: 18.253387 },
    { lat: 48.480986, lng: 18.255372 },
    { lat: 48.48137, lng: 18.255764 },
    { lat: 48.4815278, lng: 18.2555048 },
    { lat: 48.4818739, lng: 18.2552444 },
    { lat: 48.4821736, lng: 18.2548254 },
    { lat: 48.4823642, lng: 18.2543718 },
    { lat: 48.4826441, lng: 18.2539176 },
    { lat: 48.4829734, lng: 18.2530696 },
    { lat: 48.4837553, lng: 18.2523188 },
    { lat: 48.4844103, lng: 18.2509495 },
    { lat: 48.4851753, lng: 18.2496889 },
    { lat: 48.4853757, lng: 18.2494282 },
    { lat: 48.4863067, lng: 18.248506 },
    { lat: 48.4870187, lng: 18.2475222 },
    { lat: 48.487277, lng: 18.2470078 },
    { lat: 48.4877816, lng: 18.2466371 },
    { lat: 48.4888426, lng: 18.2454436 },
    { lat: 48.4893958, lng: 18.2445437 },
    { lat: 48.489716, lng: 18.2438878 },
    { lat: 48.4900526, lng: 18.2421857 },
    { lat: 48.4910466, lng: 18.2408457 },
    { lat: 48.4914963, lng: 18.2404052 },
    { lat: 48.4922512, lng: 18.2400901 },
    { lat: 48.4927363, lng: 18.2397556 },
    { lat: 48.4933027, lng: 18.2391846 },
    { lat: 48.4938421, lng: 18.238873 },
    { lat: 48.4939895, lng: 18.2388659 },
    { lat: 48.4941139, lng: 18.2387306 },
    { lat: 48.4943167, lng: 18.2386469 },
    { lat: 48.4945068, lng: 18.2383991 },
    { lat: 48.4950042, lng: 18.2380435 },
    { lat: 48.4951522, lng: 18.2378133 },
    { lat: 48.4954004, lng: 18.2375771 },
    { lat: 48.4955412, lng: 18.2375702 },
    { lat: 48.4957462, lng: 18.2373689 },
    { lat: 48.4958515, lng: 18.2373331 },
    { lat: 48.4959883, lng: 18.2375733 },
    { lat: 48.4961482, lng: 18.2376076 },
    { lat: 48.4964786, lng: 18.2373295 },
    { lat: 48.4968531, lng: 18.2374267 },
    { lat: 48.4968879, lng: 18.237362 },
    { lat: 48.4969394, lng: 18.2374553 },
    { lat: 48.4971376, lng: 18.2374484 },
    { lat: 48.4972892, lng: 18.2372597 },
    { lat: 48.4974216, lng: 18.2372058 },
    { lat: 48.4974843, lng: 18.237335 },
    { lat: 48.4975739, lng: 18.2372639 },
    { lat: 48.4976398, lng: 18.2372927 },
    { lat: 48.4979978, lng: 18.2370202 },
    { lat: 48.498177, lng: 18.2372129 },
    { lat: 48.4983137, lng: 18.2371766 },
    { lat: 48.4984761, lng: 18.2369997 },
    { lat: 48.4984876, lng: 18.2369231 },
    { lat: 48.4986314, lng: 18.2368165 },
    { lat: 48.49871, lng: 18.2368689 },
    { lat: 48.4988226, lng: 18.2367451 },
    { lat: 48.4988924, lng: 18.2367623 },
    { lat: 48.4990282, lng: 18.2365909 },
    { lat: 48.4991109, lng: 18.2365784 },
    { lat: 48.4992037, lng: 18.2366834 },
    { lat: 48.4993595, lng: 18.2366653 },
    { lat: 48.4994262, lng: 18.236547 },
    { lat: 48.4995837, lng: 18.2357527 },
    { lat: 48.4997395, lng: 18.2356577 },
    { lat: 48.4998238, lng: 18.2355165 },
    { lat: 48.4997497, lng: 18.235523 },
    { lat: 48.4997733, lng: 18.2353818 },
    { lat: 48.4997426, lng: 18.2353172 },
    { lat: 48.4998815, lng: 18.2351461 },
    { lat: 48.4999247, lng: 18.2349928 },
    { lat: 48.4999623, lng: 18.234746 },
    { lat: 48.499939, lng: 18.2344349 },
    { lat: 48.4998802, lng: 18.2343529 },
    { lat: 48.4998524, lng: 18.2341651 },
    { lat: 48.4998706, lng: 18.23403 },
    { lat: 48.5000107, lng: 18.2339175 },
    { lat: 48.5001575, lng: 18.2335052 },
    { lat: 48.5002344, lng: 18.2329583 },
    { lat: 48.5000856, lng: 18.2326479 },
    { lat: 48.5002912, lng: 18.2323589 },
    { lat: 48.5002744, lng: 18.2318477 },
    { lat: 48.5003012, lng: 18.2317065 },
    { lat: 48.5004179, lng: 18.2316177 },
    { lat: 48.5004129, lng: 18.2314119 },
    { lat: 48.5003304, lng: 18.2310957 },
    { lat: 48.500548, lng: 18.2308002 },
    { lat: 48.5005746, lng: 18.2306708 },
    { lat: 48.5006041, lng: 18.2300069 },
    { lat: 48.5007316, lng: 18.229436 },
    { lat: 48.5007307, lng: 18.2290305 },
    { lat: 48.5008781, lng: 18.2287473 },
    { lat: 48.500911, lng: 18.2282011 },
    { lat: 48.5009957, lng: 18.2278895 },
    { lat: 48.5012803, lng: 18.2276581 },
    { lat: 48.5014288, lng: 18.2267877 },
    { lat: 48.5015451, lng: 18.2264167 },
    { lat: 48.5018443, lng: 18.2259864 },
    { lat: 48.5020083, lng: 18.2260547 },
    { lat: 48.5026619, lng: 18.2257632 },
    { lat: 48.5028902, lng: 18.2255719 },
    { lat: 48.5030898, lng: 18.2256099 },
    { lat: 48.5034629, lng: 18.2254286 },
    { lat: 48.5036204, lng: 18.2252031 },
    { lat: 48.5041572, lng: 18.2249602 },
    { lat: 48.5042813, lng: 18.2249234 },
    { lat: 48.5044073, lng: 18.225033 },
    { lat: 48.5045631, lng: 18.225013 },
    { lat: 48.5047684, lng: 18.2248162 },
    { lat: 48.5049418, lng: 18.2245377 },
    { lat: 48.5049525, lng: 18.2244082 },
    { lat: 48.5050777, lng: 18.2244474 },
    { lat: 48.505217, lng: 18.2242987 },
    { lat: 48.5054631, lng: 18.2237895 },
    { lat: 48.5055527, lng: 18.223718 },
    { lat: 48.5057566, lng: 18.2233623 },
    { lat: 48.5061368, lng: 18.2236743 },
    { lat: 48.5063165, lng: 18.2236775 },
    { lat: 48.5066544, lng: 18.223473 },
    { lat: 48.5067363, lng: 18.2235246 },
    { lat: 48.5068179, lng: 18.2234702 },
    { lat: 48.506898, lng: 18.2230758 },
    { lat: 48.5069865, lng: 18.2228924 },
    { lat: 48.5072969, lng: 18.2226768 },
    { lat: 48.5074903, lng: 18.2223517 },
    { lat: 48.5076972, lng: 18.2224549 },
    { lat: 48.5079418, lng: 18.2223693 },
    { lat: 48.5081275, lng: 18.2220092 },
    { lat: 48.5080832, lng: 18.2218274 },
    { lat: 48.508259, lng: 18.2217842 },
    { lat: 48.5082887, lng: 18.2215782 },
    { lat: 48.5084828, lng: 18.2213999 },
    { lat: 48.5088897, lng: 18.2208607 },
    { lat: 48.5090008, lng: 18.2206185 },
    { lat: 48.5091019, lng: 18.2206704 },
    { lat: 48.5091757, lng: 18.2206167 },
    { lat: 48.5092378, lng: 18.2204805 },
    { lat: 48.5092406, lng: 18.2202748 },
    { lat: 48.509415, lng: 18.2201086 },
    { lat: 48.5095885, lng: 18.2197127 },
    { lat: 48.5095679, lng: 18.2194901 },
    { lat: 48.5096717, lng: 18.2192773 },
    { lat: 48.509671, lng: 18.2191656 },
    { lat: 48.509776, lng: 18.2190879 },
    { lat: 48.5097477, lng: 18.2187948 },
    { lat: 48.5099185, lng: 18.2186223 },
    { lat: 48.5100682, lng: 18.2182448 },
    { lat: 48.510312, lng: 18.2180892 },
    { lat: 48.5104246, lng: 18.2178115 },
    { lat: 48.5106144, lng: 18.2177155 },
    { lat: 48.5107031, lng: 18.217573 },
    { lat: 48.5105571, lng: 18.2170935 },
    { lat: 48.5108363, lng: 18.216867 },
    { lat: 48.5108585, lng: 18.2167025 },
    { lat: 48.5110991, lng: 18.2165114 },
    { lat: 48.5114008, lng: 18.2161086 },
    { lat: 48.5115713, lng: 18.215954 },
    { lat: 48.5117475, lng: 18.2159751 },
    { lat: 48.5118699, lng: 18.2156332 },
    { lat: 48.5123034, lng: 18.2149698 },
    { lat: 48.5124297, lng: 18.2146747 },
    { lat: 48.5124375, lng: 18.2144982 },
    { lat: 48.5125298, lng: 18.2144328 },
    { lat: 48.5126571, lng: 18.21402 },
    { lat: 48.5127698, lng: 18.2139772 },
    { lat: 48.5128693, lng: 18.2137475 },
    { lat: 48.5128447, lng: 18.2135948 },
    { lat: 48.5129172, lng: 18.2132238 },
    { lat: 48.5131039, lng: 18.213157 },
    { lat: 48.5132793, lng: 18.2130082 },
    { lat: 48.5134157, lng: 18.2130651 },
    { lat: 48.5136605, lng: 18.2128858 },
    { lat: 48.5137381, lng: 18.2128614 },
    { lat: 48.5138079, lng: 18.2129192 },
    { lat: 48.5138894, lng: 18.2128655 },
    { lat: 48.5138996, lng: 18.2125538 },
    { lat: 48.5139657, lng: 18.2123886 },
    { lat: 48.5140736, lng: 18.212282 },
    { lat: 48.5143845, lng: 18.2121545 },
    { lat: 48.5146448, lng: 18.2110706 },
    { lat: 48.5149783, lng: 18.2108669 },
    { lat: 48.5151438, lng: 18.210489 },
    { lat: 48.515318, lng: 18.210299 },
    { lat: 48.5153993, lng: 18.2101275 },
    { lat: 48.5155432, lng: 18.2092919 },
    { lat: 48.5157098, lng: 18.2090901 },
    { lat: 48.5158694, lng: 18.2091589 },
    { lat: 48.5159626, lng: 18.2091104 },
    { lat: 48.5160633, lng: 18.2089211 },
    { lat: 48.5162111, lng: 18.2082732 },
    { lat: 48.5162284, lng: 18.2078681 },
    { lat: 48.5163367, lng: 18.2077137 },
    { lat: 48.5165701, lng: 18.2075938 },
    { lat: 48.5167474, lng: 18.2072037 },
    { lat: 48.5168074, lng: 18.2067976 },
    { lat: 48.5169262, lng: 18.2064497 },
    { lat: 48.5171962, lng: 18.2065517 },
    { lat: 48.5173552, lng: 18.2064505 },
    { lat: 48.5175073, lng: 18.2057433 },
    { lat: 48.517647, lng: 18.2055656 },
    { lat: 48.5180257, lng: 18.2057315 },
    { lat: 48.5183496, lng: 18.2052278 },
    { lat: 48.5184918, lng: 18.2048152 },
    { lat: 48.5186674, lng: 18.2046894 },
    { lat: 48.5189247, lng: 18.2047273 },
    { lat: 48.5192246, lng: 18.2041189 },
    { lat: 48.5195641, lng: 18.2040966 },
    { lat: 48.5196871, lng: 18.2037249 },
    { lat: 48.5196424, lng: 18.2034379 },
    { lat: 48.5201053, lng: 18.2026042 },
    { lat: 48.5206216, lng: 18.2021216 },
    { lat: 48.5208062, lng: 18.2016874 },
    { lat: 48.5213935, lng: 18.2011649 },
    { lat: 48.5216017, lng: 18.2008846 },
    { lat: 48.5215584, lng: 18.2007621 },
    { lat: 48.5216697, lng: 18.2005956 },
    { lat: 48.521829, lng: 18.2005982 },
    { lat: 48.521982, lng: 18.2001603 },
    { lat: 48.5220701, lng: 18.2000648 },
    { lat: 48.5224998, lng: 18.1997095 },
    { lat: 48.522952, lng: 18.1996831 },
    { lat: 48.5232247, lng: 18.1992078 },
    { lat: 48.5232116, lng: 18.1990789 },
    { lat: 48.5233894, lng: 18.198952 },
    { lat: 48.5237164, lng: 18.1988694 },
    { lat: 48.5239741, lng: 18.1984882 },
    { lat: 48.5245088, lng: 18.1981135 },
    { lat: 48.5248749, lng: 18.1981592 },
    { lat: 48.525044, lng: 18.1982734 },
    { lat: 48.5252307, lng: 18.1982639 },
    { lat: 48.5252946, lng: 18.1980804 },
    { lat: 48.5252854, lng: 18.1978634 },
    { lat: 48.5254364, lng: 18.1977016 },
    { lat: 48.5256309, lng: 18.1976508 },
    { lat: 48.5258764, lng: 18.197681 },
    { lat: 48.5259158, lng: 18.1977979 },
    { lat: 48.5259898, lng: 18.197826 },
    { lat: 48.5260669, lng: 18.1977365 },
    { lat: 48.526138, lng: 18.1973061 },
    { lat: 48.5262193, lng: 18.1972103 },
    { lat: 48.5262788, lng: 18.1969918 },
    { lat: 48.5265035, lng: 18.1968171 },
    { lat: 48.526545, lng: 18.1966341 },
    { lat: 48.5264027, lng: 18.1964017 },
    { lat: 48.5263709, lng: 18.1962613 },
    { lat: 48.5264312, lng: 18.196037 },
    { lat: 48.5265124, lng: 18.1959356 },
    { lat: 48.5265947, lng: 18.1959515 },
    { lat: 48.5267055, lng: 18.1957259 },
    { lat: 48.5266842, lng: 18.1955325 },
    { lat: 48.526825, lng: 18.1951538 },
    { lat: 48.5268993, lng: 18.195211 },
    { lat: 48.5269923, lng: 18.1950621 },
    { lat: 48.5269833, lng: 18.1949629 },
    { lat: 48.5269055, lng: 18.1949468 },
    { lat: 48.5269735, lng: 18.1947161 },
    { lat: 48.5270698, lng: 18.1945849 },
    { lat: 48.5272049, lng: 18.194506 },
    { lat: 48.5273922, lng: 18.1945021 },
    { lat: 48.5273881, lng: 18.1949017 },
    { lat: 48.5275132, lng: 18.194958 },
    { lat: 48.527586, lng: 18.1948687 },
    { lat: 48.5278937, lng: 18.1948034 },
    { lat: 48.5279672, lng: 18.194708 },
    { lat: 48.5279961, lng: 18.1945255 },
    { lat: 48.5279555, lng: 18.1942734 },
    { lat: 48.5280818, lng: 18.1940771 },
    { lat: 48.5283231, lng: 18.1940138 },
    { lat: 48.5286662, lng: 18.1936253 },
    { lat: 48.529198, lng: 18.1933385 },
    { lat: 48.5293903, lng: 18.1930527 },
    { lat: 48.5293699, lng: 18.1925242 },
    { lat: 48.5295394, lng: 18.1922154 },
    { lat: 48.5297026, lng: 18.1921883 },
    { lat: 48.5297916, lng: 18.1920869 },
    { lat: 48.5298848, lng: 18.1916503 },
    { lat: 48.5300155, lng: 18.1914125 },
    { lat: 48.5304534, lng: 18.1910806 },
    { lat: 48.5306887, lng: 18.190782 },
    { lat: 48.5307516, lng: 18.1904224 },
    { lat: 48.5307984, lng: 18.190392 },
    { lat: 48.5310221, lng: 18.19067 },
    { lat: 48.5312215, lng: 18.190725 },
    { lat: 48.5314613, lng: 18.1905202 },
    { lat: 48.5315125, lng: 18.1903874 },
    { lat: 48.5314995, lng: 18.1902816 },
    { lat: 48.5311931, lng: 18.1896086 },
    { lat: 48.5312506, lng: 18.1895313 },
    { lat: 48.5312062, lng: 18.1893851 },
    { lat: 48.5312508, lng: 18.1890845 },
    { lat: 48.5333695, lng: 18.1857756 },
    { lat: 48.5355615, lng: 18.1826296 },
    { lat: 48.535799, lng: 18.1823809 },
    { lat: 48.5351857, lng: 18.1820437 },
    { lat: 48.5352307, lng: 18.1816322 },
    { lat: 48.5354993, lng: 18.1813182 },
    { lat: 48.5358508, lng: 18.1806457 },
    { lat: 48.5359711, lng: 18.1804807 },
    { lat: 48.5361773, lng: 18.1803792 },
    { lat: 48.5363683, lng: 18.1803659 },
    { lat: 48.5366231, lng: 18.180576 },
    { lat: 48.5367802, lng: 18.1808513 },
    { lat: 48.536963, lng: 18.1810267 },
    { lat: 48.5372009, lng: 18.1810841 },
    { lat: 48.5374781, lng: 18.1809587 },
    { lat: 48.5377026, lng: 18.1806454 },
    { lat: 48.537728, lng: 18.180557 },
    { lat: 48.5380092, lng: 18.1799781 },
    { lat: 48.5381445, lng: 18.1795661 },
    { lat: 48.5385148, lng: 18.1780105 },
    { lat: 48.5391599, lng: 18.1765242 },
    { lat: 48.5394275, lng: 18.1764093 },
    { lat: 48.5399941, lng: 18.1765499 },
    { lat: 48.5400886, lng: 18.1765428 },
    { lat: 48.5403987, lng: 18.1765777 },
    { lat: 48.5410346, lng: 18.1764908 },
    { lat: 48.5417636, lng: 18.1761757 },
    { lat: 48.5420698, lng: 18.1758737 },
    { lat: 48.5423645, lng: 18.1752912 },
    { lat: 48.5424241, lng: 18.1749151 },
    { lat: 48.5423777, lng: 18.1743514 },
    { lat: 48.5422003, lng: 18.1732936 },
    { lat: 48.5420168, lng: 18.1733027 },
    { lat: 48.5416129, lng: 18.1728362 },
    { lat: 48.5410226, lng: 18.1726813 },
    { lat: 48.5410574, lng: 18.172384 },
    { lat: 48.5405045, lng: 18.172212 },
    { lat: 48.5398725, lng: 18.1718571 },
    { lat: 48.5395242, lng: 18.1715931 },
    { lat: 48.5388193, lng: 18.170725 },
    { lat: 48.538856, lng: 18.1706155 },
    { lat: 48.5387792, lng: 18.1699721 },
    { lat: 48.5383962, lng: 18.1692277 },
    { lat: 48.5378409, lng: 18.168679 },
    { lat: 48.5374496, lng: 18.1681785 },
    { lat: 48.5367893, lng: 18.1676323 },
    { lat: 48.5364266, lng: 18.1671191 },
    { lat: 48.5361578, lng: 18.1669412 },
    { lat: 48.5358778, lng: 18.166957 },
    { lat: 48.5357874, lng: 18.1668942 },
    { lat: 48.5356764, lng: 18.1667546 },
    { lat: 48.5354616, lng: 18.1662028 },
    { lat: 48.5351144, lng: 18.1657603 },
    { lat: 48.535221, lng: 18.1652068 },
    { lat: 48.5347072, lng: 18.1651007 },
    { lat: 48.533962, lng: 18.1646102 },
    { lat: 48.533567, lng: 18.1644912 },
    { lat: 48.5330533, lng: 18.1641561 },
    { lat: 48.5328106, lng: 18.1642511 },
    { lat: 48.5326827, lng: 18.164207 },
    { lat: 48.5325866, lng: 18.1640463 },
    { lat: 48.5324022, lng: 18.1634781 },
    { lat: 48.5321677, lng: 18.1632469 },
    { lat: 48.5320664, lng: 18.1632531 },
    { lat: 48.5318655, lng: 18.1635238 },
    { lat: 48.5315073, lng: 18.1634972 },
    { lat: 48.5307539, lng: 18.1636391 },
    { lat: 48.5305767, lng: 18.1632509 },
    { lat: 48.5305186, lng: 18.1627964 },
    { lat: 48.5299587, lng: 18.1621484 },
    { lat: 48.5295489, lng: 18.1614856 },
    { lat: 48.5292298, lng: 18.1611574 },
    { lat: 48.5285743, lng: 18.1610439 },
    { lat: 48.5284409, lng: 18.1615653 },
    { lat: 48.5281776, lng: 18.1615996 },
    { lat: 48.5280039, lng: 18.1615402 },
    { lat: 48.5276856, lng: 18.1615031 },
    { lat: 48.5273343, lng: 18.161456 },
    { lat: 48.5262303, lng: 18.1620724 },
    { lat: 48.5257102, lng: 18.1615129 },
    { lat: 48.525555, lng: 18.1615401 },
    { lat: 48.525481, lng: 18.1618015 },
    { lat: 48.5250417, lng: 18.1627671 },
    { lat: 48.5248979, lng: 18.1627996 },
    { lat: 48.5244488, lng: 18.161769 },
    { lat: 48.5220064, lng: 18.156944 },
    { lat: 48.5218883, lng: 18.1566986 },
  ],
  Norovce: [
    { lat: 48.632719, lng: 18.199209 },
    { lat: 48.633185, lng: 18.19922 },
    { lat: 48.633266, lng: 18.199217 },
    { lat: 48.635983, lng: 18.199102 },
    { lat: 48.636067, lng: 18.199099 },
    { lat: 48.63607, lng: 18.19911 },
    { lat: 48.638094, lng: 18.19863 },
    { lat: 48.638094, lng: 18.198398 },
    { lat: 48.637975, lng: 18.197914 },
    { lat: 48.637948, lng: 18.197508 },
    { lat: 48.637896, lng: 18.19727 },
    { lat: 48.637868, lng: 18.196705 },
    { lat: 48.637875, lng: 18.19616 },
    { lat: 48.637886, lng: 18.195741 },
    { lat: 48.637913, lng: 18.195548 },
    { lat: 48.637945, lng: 18.195308 },
    { lat: 48.637961, lng: 18.19508 },
    { lat: 48.638012, lng: 18.194769 },
    { lat: 48.638015, lng: 18.194698 },
    { lat: 48.638018, lng: 18.194588 },
    { lat: 48.638022, lng: 18.194478 },
    { lat: 48.638009, lng: 18.194401 },
    { lat: 48.638005, lng: 18.19434 },
    { lat: 48.638167, lng: 18.194037 },
    { lat: 48.638216, lng: 18.193821 },
    { lat: 48.638362, lng: 18.193549 },
    { lat: 48.638461, lng: 18.193386 },
    { lat: 48.638582, lng: 18.193065 },
    { lat: 48.638641, lng: 18.192768 },
    { lat: 48.638798, lng: 18.192642 },
    { lat: 48.638873, lng: 18.192493 },
    { lat: 48.639001, lng: 18.192167 },
    { lat: 48.639113, lng: 18.191924 },
    { lat: 48.639132, lng: 18.191637 },
    { lat: 48.639122, lng: 18.191627 },
    { lat: 48.639764, lng: 18.190227 },
    { lat: 48.639801, lng: 18.190146 },
    { lat: 48.639949, lng: 18.189802 },
    { lat: 48.640008, lng: 18.189693 },
    { lat: 48.641162, lng: 18.18866 },
    { lat: 48.641593, lng: 18.188281 },
    { lat: 48.641761, lng: 18.18817 },
    { lat: 48.641874, lng: 18.188066 },
    { lat: 48.642119, lng: 18.187792 },
    { lat: 48.642311, lng: 18.187537 },
    { lat: 48.642562, lng: 18.18711 },
    { lat: 48.642675, lng: 18.186832 },
    { lat: 48.642826, lng: 18.186502 },
    { lat: 48.642939, lng: 18.186301 },
    { lat: 48.643062, lng: 18.186065 },
    { lat: 48.64314, lng: 18.185977 },
    { lat: 48.643258, lng: 18.185863 },
    { lat: 48.64354, lng: 18.185656 },
    { lat: 48.643933, lng: 18.185482 },
    { lat: 48.644496, lng: 18.185286 },
    { lat: 48.645519, lng: 18.184908 },
    { lat: 48.645952, lng: 18.184729 },
    { lat: 48.646372, lng: 18.184497 },
    { lat: 48.646354, lng: 18.184473 },
    { lat: 48.64627, lng: 18.184215 },
    { lat: 48.646222, lng: 18.183767 },
    { lat: 48.646183, lng: 18.183208 },
    { lat: 48.646182, lng: 18.182924 },
    { lat: 48.646211, lng: 18.182771 },
    { lat: 48.646299, lng: 18.182494 },
    { lat: 48.646384, lng: 18.182051 },
    { lat: 48.646462, lng: 18.181564 },
    { lat: 48.646462, lng: 18.181321 },
    { lat: 48.646501, lng: 18.181248 },
    { lat: 48.646516, lng: 18.181221 },
    { lat: 48.646439, lng: 18.180813 },
    { lat: 48.646238, lng: 18.180117 },
    { lat: 48.646086, lng: 18.179288 },
    { lat: 48.646079, lng: 18.179165 },
    { lat: 48.646101, lng: 18.178513 },
    { lat: 48.646092, lng: 18.178214 },
    { lat: 48.645306, lng: 18.175218 },
    { lat: 48.645275, lng: 18.175072 },
    { lat: 48.645267, lng: 18.174955 },
    { lat: 48.645531, lng: 18.173457 },
    { lat: 48.64557, lng: 18.173156 },
    { lat: 48.645591, lng: 18.172927 },
    { lat: 48.645578, lng: 18.172347 },
    { lat: 48.645514, lng: 18.172209 },
    { lat: 48.644999, lng: 18.170707 },
    { lat: 48.644358, lng: 18.16918 },
    { lat: 48.644297, lng: 18.169 },
    { lat: 48.644242, lng: 18.168743 },
    { lat: 48.644168, lng: 18.168097 },
    { lat: 48.64413, lng: 18.167856 },
    { lat: 48.643898, lng: 18.167117 },
    { lat: 48.643743, lng: 18.166572 },
    { lat: 48.643641, lng: 18.166175 },
    { lat: 48.643554, lng: 18.165918 },
    { lat: 48.643455, lng: 18.165651 },
    { lat: 48.643275, lng: 18.165302 },
    { lat: 48.64304, lng: 18.164912 },
    { lat: 48.642927, lng: 18.164693 },
    { lat: 48.6429, lng: 18.164702 },
    { lat: 48.642879, lng: 18.164657 },
    { lat: 48.6406424, lng: 18.1669106 },
    { lat: 48.6404775, lng: 18.1664966 },
    { lat: 48.6399573, lng: 18.1669452 },
    { lat: 48.6397923, lng: 18.1663376 },
    { lat: 48.6390531, lng: 18.1641746 },
    { lat: 48.6376843, lng: 18.1595205 },
    { lat: 48.6376858, lng: 18.1591382 },
    { lat: 48.6376326, lng: 18.1589018 },
    { lat: 48.6370252, lng: 18.15697 },
    { lat: 48.6368533, lng: 18.1555641 },
    { lat: 48.6367266, lng: 18.1550514 },
    { lat: 48.6369287, lng: 18.1548013 },
    { lat: 48.6366803, lng: 18.1539999 },
    { lat: 48.6358789, lng: 18.1513724 },
    { lat: 48.6354626, lng: 18.1516272 },
    { lat: 48.6341296, lng: 18.1520502 },
    { lat: 48.6338072, lng: 18.1523093 },
    { lat: 48.6332801, lng: 18.1529498 },
    { lat: 48.6319827, lng: 18.1533424 },
    { lat: 48.630999, lng: 18.1540902 },
    { lat: 48.6292986, lng: 18.1556892 },
    { lat: 48.6282534, lng: 18.156403 },
    { lat: 48.6249959, lng: 18.158822 },
    { lat: 48.6241433, lng: 18.159085 },
    { lat: 48.6229645, lng: 18.1594642 },
    { lat: 48.625341, lng: 18.1655194 },
    { lat: 48.6246025, lng: 18.1655268 },
    { lat: 48.6243831, lng: 18.1655982 },
    { lat: 48.6246275, lng: 18.1664863 },
    { lat: 48.6245912, lng: 18.1669326 },
    { lat: 48.6246166, lng: 18.1670413 },
    { lat: 48.6227509, lng: 18.1684542 },
    { lat: 48.6204291, lng: 18.1697349 },
    { lat: 48.6189322, lng: 18.17095 },
    { lat: 48.6198617, lng: 18.171608 },
    { lat: 48.6204533, lng: 18.1723547 },
    { lat: 48.6208135, lng: 18.1725512 },
    { lat: 48.6210797, lng: 18.1736041 },
    { lat: 48.6211807, lng: 18.1743523 },
    { lat: 48.6211885, lng: 18.1759716 },
    { lat: 48.621247, lng: 18.1766887 },
    { lat: 48.6213358, lng: 18.1771888 },
    { lat: 48.6214697, lng: 18.1775953 },
    { lat: 48.6218135, lng: 18.178389 },
    { lat: 48.6219393, lng: 18.178671 },
    { lat: 48.6219842, lng: 18.1788423 },
    { lat: 48.6222342, lng: 18.1788501 },
    { lat: 48.6223404, lng: 18.1790316 },
    { lat: 48.6227675, lng: 18.1804639 },
    { lat: 48.6229735, lng: 18.1808085 },
    { lat: 48.6234462, lng: 18.1814087 },
    { lat: 48.6232072, lng: 18.1825273 },
    { lat: 48.6234334, lng: 18.1828496 },
    { lat: 48.6245433, lng: 18.185222 },
    { lat: 48.6252315, lng: 18.1864731 },
    { lat: 48.6256604, lng: 18.1868034 },
    { lat: 48.6262112, lng: 18.1875707 },
    { lat: 48.6261643, lng: 18.1878719 },
    { lat: 48.6265509, lng: 18.1884913 },
    { lat: 48.6267115, lng: 18.188869 },
    { lat: 48.6264348, lng: 18.1891173 },
    { lat: 48.6266216, lng: 18.1894416 },
    { lat: 48.6270834, lng: 18.1907757 },
    { lat: 48.6275717, lng: 18.191903 },
    { lat: 48.6281846, lng: 18.193101 },
    { lat: 48.628505, lng: 18.1935676 },
    { lat: 48.6282747, lng: 18.1939985 },
    { lat: 48.6282413, lng: 18.1954326 },
    { lat: 48.6278747, lng: 18.1970385 },
    { lat: 48.6281128, lng: 18.19708 },
    { lat: 48.6282647, lng: 18.1975816 },
    { lat: 48.6290837, lng: 18.1986125 },
    { lat: 48.6294376, lng: 18.1985874 },
    { lat: 48.6297627, lng: 18.1986689 },
    { lat: 48.6302285, lng: 18.1988408 },
    { lat: 48.6306611, lng: 18.1991786 },
    { lat: 48.6312442, lng: 18.1991539 },
    { lat: 48.6321484, lng: 18.1993378 },
    { lat: 48.632719, lng: 18.199209 },
  ],
  Oponice: [
    { lat: 48.43811, lng: 18.1921 },
    { lat: 48.438313, lng: 18.192362 },
    { lat: 48.438493, lng: 18.192637 },
    { lat: 48.438521, lng: 18.192677 },
    { lat: 48.438801, lng: 18.193083 },
    { lat: 48.438964, lng: 18.193324 },
    { lat: 48.439144, lng: 18.193851 },
    { lat: 48.439172, lng: 18.193944 },
    { lat: 48.439368, lng: 18.1945 },
    { lat: 48.4394755, lng: 18.1944054 },
    { lat: 48.4404605, lng: 18.1930015 },
    { lat: 48.4456783, lng: 18.1859669 },
    { lat: 48.4506551, lng: 18.179138 },
    { lat: 48.457385, lng: 18.1697255 },
    { lat: 48.4589995, lng: 18.1673292 },
    { lat: 48.4597839, lng: 18.1649614 },
    { lat: 48.4606038, lng: 18.1630503 },
    { lat: 48.461037, lng: 18.1614347 },
    { lat: 48.4613592, lng: 18.1604366 },
    { lat: 48.4615355, lng: 18.1600927 },
    { lat: 48.4618754, lng: 18.1589892 },
    { lat: 48.4622593, lng: 18.1579666 },
    { lat: 48.4624083, lng: 18.1571896 },
    { lat: 48.4642585, lng: 18.1583654 },
    { lat: 48.4657136, lng: 18.1590331 },
    { lat: 48.4658598, lng: 18.1592375 },
    { lat: 48.4675012, lng: 18.1603167 },
    { lat: 48.4676334, lng: 18.1600639 },
    { lat: 48.4676769, lng: 18.1601339 },
    { lat: 48.470841, lng: 18.1619369 },
    { lat: 48.4724904, lng: 18.1570414 },
    { lat: 48.4733769, lng: 18.1541429 },
    { lat: 48.4752131, lng: 18.1457884 },
    { lat: 48.4758333, lng: 18.1469841 },
    { lat: 48.4761506, lng: 18.1473154 },
    { lat: 48.4768116, lng: 18.1477188 },
    { lat: 48.4775107, lng: 18.1480165 },
    { lat: 48.4783811, lng: 18.1434697 },
    { lat: 48.4790858, lng: 18.1385252 },
    { lat: 48.4791372, lng: 18.1378977 },
    { lat: 48.4798236, lng: 18.1343053 },
    { lat: 48.479685, lng: 18.1341507 },
    { lat: 48.4796094, lng: 18.133958 },
    { lat: 48.4795652, lng: 18.1326979 },
    { lat: 48.4794774, lng: 18.1324527 },
    { lat: 48.4792467, lng: 18.1322321 },
    { lat: 48.4791099, lng: 18.132169 },
    { lat: 48.4788801, lng: 18.1321951 },
    { lat: 48.4782441, lng: 18.1326882 },
    { lat: 48.4778557, lng: 18.1328561 },
    { lat: 48.4773994, lng: 18.1328495 },
    { lat: 48.4770595, lng: 18.1326127 },
    { lat: 48.4769173, lng: 18.1323445 },
    { lat: 48.4767863, lng: 18.1318596 },
    { lat: 48.4767696, lng: 18.1316116 },
    { lat: 48.4766937, lng: 18.1304882 },
    { lat: 48.476564, lng: 18.1294049 },
    { lat: 48.4763191, lng: 18.1287508 },
    { lat: 48.4762056, lng: 18.1286406 },
    { lat: 48.4758856, lng: 18.128515 },
    { lat: 48.4750947, lng: 18.1284766 },
    { lat: 48.4746063, lng: 18.1282118 },
    { lat: 48.4744305, lng: 18.127985 },
    { lat: 48.4743399, lng: 18.1278629 },
    { lat: 48.4741199, lng: 18.127537 },
    { lat: 48.4738904, lng: 18.1268358 },
    { lat: 48.4735779, lng: 18.1265051 },
    { lat: 48.4731365, lng: 18.1264862 },
    { lat: 48.4727215, lng: 18.1267015 },
    { lat: 48.4724375, lng: 18.1268029 },
    { lat: 48.4722477, lng: 18.1270525 },
    { lat: 48.4719055, lng: 18.1272614 },
    { lat: 48.4716256, lng: 18.1274168 },
    { lat: 48.4711654, lng: 18.1272635 },
    { lat: 48.4703823, lng: 18.1264922 },
    { lat: 48.4696399, lng: 18.125937 },
    { lat: 48.4692518, lng: 18.1255719 },
    { lat: 48.4689888, lng: 18.1251874 },
    { lat: 48.4687478, lng: 18.1241517 },
    { lat: 48.4685823, lng: 18.123772 },
    { lat: 48.4681797, lng: 18.1233128 },
    { lat: 48.4675162, lng: 18.12295 },
    { lat: 48.4672543, lng: 18.1226771 },
    { lat: 48.4668374, lng: 18.1217432 },
    { lat: 48.4666236, lng: 18.1207691 },
    { lat: 48.4664079, lng: 18.1206106 },
    { lat: 48.4661901, lng: 18.120671 },
    { lat: 48.4659505, lng: 18.1210306 },
    { lat: 48.4656573, lng: 18.1217311 },
    { lat: 48.4652837, lng: 18.1224021 },
    { lat: 48.4651714, lng: 18.1225681 },
    { lat: 48.4648344, lng: 18.1228079 },
    { lat: 48.4646358, lng: 18.1229049 },
    { lat: 48.4643865, lng: 18.1228671 },
    { lat: 48.4637232, lng: 18.1222131 },
    { lat: 48.4635235, lng: 18.1220987 },
    { lat: 48.4632741, lng: 18.1221199 },
    { lat: 48.4630806, lng: 18.1222639 },
    { lat: 48.4629372, lng: 18.1225535 },
    { lat: 48.4627119, lng: 18.1238897 },
    { lat: 48.4627985, lng: 18.1248898 },
    { lat: 48.4626767, lng: 18.125237 },
    { lat: 48.4625986, lng: 18.1253494 },
    { lat: 48.4624783, lng: 18.1253798 },
    { lat: 48.4622902, lng: 18.1252123 },
    { lat: 48.4622271, lng: 18.125002 },
    { lat: 48.4621983, lng: 18.1246797 },
    { lat: 48.462259, lng: 18.1243862 },
    { lat: 48.4622637, lng: 18.1237352 },
    { lat: 48.4621316, lng: 18.1226885 },
    { lat: 48.4619658, lng: 18.1217866 },
    { lat: 48.4617212, lng: 18.1213091 },
    { lat: 48.461646, lng: 18.1212394 },
    { lat: 48.4614427, lng: 18.1210426 },
    { lat: 48.4612743, lng: 18.120998 },
    { lat: 48.4610213, lng: 18.121055 },
    { lat: 48.4607075, lng: 18.1213998 },
    { lat: 48.4603177, lng: 18.1219928 },
    { lat: 48.4600818, lng: 18.1222605 },
    { lat: 48.4599305, lng: 18.122315 },
    { lat: 48.4596214, lng: 18.1222609 },
    { lat: 48.4593938, lng: 18.1219767 },
    { lat: 48.4593612, lng: 18.1218246 },
    { lat: 48.4593775, lng: 18.121331 },
    { lat: 48.4596515, lng: 18.1203053 },
    { lat: 48.4595463, lng: 18.1197254 },
    { lat: 48.4593006, lng: 18.119535 },
    { lat: 48.4590901, lng: 18.1195618 },
    { lat: 48.4588065, lng: 18.1197714 },
    { lat: 48.4586396, lng: 18.1199268 },
    { lat: 48.4580889, lng: 18.1204686 },
    { lat: 48.4576757, lng: 18.120428 },
    { lat: 48.4574397, lng: 18.1200965 },
    { lat: 48.4573722, lng: 18.1198687 },
    { lat: 48.4573775, lng: 18.1195338 },
    { lat: 48.4576965, lng: 18.1186781 },
    { lat: 48.4578217, lng: 18.1182708 },
    { lat: 48.457863, lng: 18.1179592 },
    { lat: 48.4578484, lng: 18.1176127 },
    { lat: 48.4578012, lng: 18.1174607 },
    { lat: 48.4575064, lng: 18.117013 },
    { lat: 48.4530605, lng: 18.1274204 },
    { lat: 48.44768, lng: 18.13138 },
    { lat: 48.447646, lng: 18.13148 },
    { lat: 48.447635, lng: 18.131574 },
    { lat: 48.447608, lng: 18.131704 },
    { lat: 48.447586, lng: 18.131845 },
    { lat: 48.447564, lng: 18.132003 },
    { lat: 48.447519, lng: 18.132262 },
    { lat: 48.44746, lng: 18.132615 },
    { lat: 48.447429, lng: 18.132745 },
    { lat: 48.447387, lng: 18.13298 },
    { lat: 48.447324, lng: 18.133338 },
    { lat: 48.447249, lng: 18.133762 },
    { lat: 48.447224, lng: 18.133926 },
    { lat: 48.447171, lng: 18.134179 },
    { lat: 48.447156, lng: 18.134314 },
    { lat: 48.447129, lng: 18.13445 },
    { lat: 48.447111, lng: 18.134643 },
    { lat: 48.447232, lng: 18.134677 },
    { lat: 48.447211, lng: 18.134906 },
    { lat: 48.447193, lng: 18.135082 },
    { lat: 48.447164, lng: 18.135352 },
    { lat: 48.446967, lng: 18.135256 },
    { lat: 48.446819, lng: 18.135194 },
    { lat: 48.446678, lng: 18.135132 },
    { lat: 48.446487, lng: 18.135047 },
    { lat: 48.446287, lng: 18.134956 },
    { lat: 48.446256, lng: 18.134939 },
    { lat: 48.446194, lng: 18.134905 },
    { lat: 48.446006, lng: 18.134826 },
    { lat: 48.445806, lng: 18.134742 },
    { lat: 48.445775, lng: 18.134731 },
    { lat: 48.445768, lng: 18.13476 },
    { lat: 48.44572, lng: 18.135118 },
    { lat: 48.445664, lng: 18.135465 },
    { lat: 48.445634, lng: 18.135665 },
    { lat: 48.445601, lng: 18.135853 },
    { lat: 48.445537, lng: 18.136235 },
    { lat: 48.445499, lng: 18.13647 },
    { lat: 48.445432, lng: 18.136858 },
    { lat: 48.445376, lng: 18.137287 },
    { lat: 48.445312, lng: 18.137588 },
    { lat: 48.445257, lng: 18.137887 },
    { lat: 48.445185, lng: 18.138264 },
    { lat: 48.44511, lng: 18.138676 },
    { lat: 48.445065, lng: 18.138928 },
    { lat: 48.444997, lng: 18.139304 },
    { lat: 48.444938, lng: 18.139675 },
    { lat: 48.444881, lng: 18.139958 },
    { lat: 48.444792, lng: 18.140504 },
    { lat: 48.444788, lng: 18.140581 },
    { lat: 48.444781, lng: 18.140692 },
    { lat: 48.444777, lng: 18.140733 },
    { lat: 48.444733, lng: 18.14098 },
    { lat: 48.444707, lng: 18.141133 },
    { lat: 48.444647, lng: 18.141567 },
    { lat: 48.44461, lng: 18.141832 },
    { lat: 48.444581, lng: 18.142108 },
    { lat: 48.444555, lng: 18.142366 },
    { lat: 48.444534, lng: 18.142619 },
    { lat: 48.444527, lng: 18.142754 },
    { lat: 48.444522, lng: 18.143047 },
    { lat: 48.444499, lng: 18.143282 },
    { lat: 48.444458, lng: 18.143529 },
    { lat: 48.444394, lng: 18.143794 },
    { lat: 48.444311, lng: 18.144158 },
    { lat: 48.444224, lng: 18.144541 },
    { lat: 48.444217, lng: 18.144635 },
    { lat: 48.444199, lng: 18.144811 },
    { lat: 48.444172, lng: 18.145028 },
    { lat: 48.44412, lng: 18.145228 },
    { lat: 48.444094, lng: 18.145346 },
    { lat: 48.444079, lng: 18.145417 },
    { lat: 48.444067, lng: 18.145505 },
    { lat: 48.444053, lng: 18.145611 },
    { lat: 48.444034, lng: 18.145757 },
    { lat: 48.444015, lng: 18.14594 },
    { lat: 48.443998, lng: 18.146175 },
    { lat: 48.443983, lng: 18.146298 },
    { lat: 48.44398, lng: 18.14645 },
    { lat: 48.443959, lng: 18.146785 },
    { lat: 48.443971, lng: 18.14682 },
    { lat: 48.443952, lng: 18.146985 },
    { lat: 48.443941, lng: 18.147066 },
    { lat: 48.443915, lng: 18.147196 },
    { lat: 48.44389, lng: 18.147343 },
    { lat: 48.443886, lng: 18.147442 },
    { lat: 48.443872, lng: 18.147736 },
    { lat: 48.443865, lng: 18.147847 },
    { lat: 48.443851, lng: 18.147953 },
    { lat: 48.443851, lng: 18.148018 },
    { lat: 48.443818, lng: 18.148428 },
    { lat: 48.443827, lng: 18.148575 },
    { lat: 48.443828, lng: 18.148645 },
    { lat: 48.443824, lng: 18.148698 },
    { lat: 48.443817, lng: 18.148763 },
    { lat: 48.443799, lng: 18.148857 },
    { lat: 48.443787, lng: 18.148904 },
    { lat: 48.443788, lng: 18.148986 },
    { lat: 48.443776, lng: 18.149098 },
    { lat: 48.443778, lng: 18.149215 },
    { lat: 48.443778, lng: 18.149344 },
    { lat: 48.443764, lng: 18.14959 },
    { lat: 48.443758, lng: 18.149713 },
    { lat: 48.44374, lng: 18.149878 },
    { lat: 48.443737, lng: 18.150024 },
    { lat: 48.443725, lng: 18.150142 },
    { lat: 48.443711, lng: 18.150224 },
    { lat: 48.443688, lng: 18.150413 },
    { lat: 48.443643, lng: 18.150677 },
    { lat: 48.443615, lng: 18.150842 },
    { lat: 48.4436, lng: 18.150959 },
    { lat: 48.443573, lng: 18.15124 },
    { lat: 48.443546, lng: 18.151557 },
    { lat: 48.443547, lng: 18.151762 },
    { lat: 48.443551, lng: 18.151997 },
    { lat: 48.443509, lng: 18.152338 },
    { lat: 48.443502, lng: 18.152484 },
    { lat: 48.443491, lng: 18.152795 },
    { lat: 48.443475, lng: 18.153095 },
    { lat: 48.443425, lng: 18.153476 },
    { lat: 48.443399, lng: 18.153816 },
    { lat: 48.443368, lng: 18.154408 },
    { lat: 48.443323, lng: 18.155054 },
    { lat: 48.443261, lng: 18.155389 },
    { lat: 48.443301, lng: 18.155693 },
    { lat: 48.443211, lng: 18.155952 },
    { lat: 48.443118, lng: 18.156392 },
    { lat: 48.443072, lng: 18.156597 },
    { lat: 48.442945, lng: 18.15712 },
    { lat: 48.442852, lng: 18.157519 },
    { lat: 48.442824, lng: 18.157672 },
    { lat: 48.442789, lng: 18.157942 },
    { lat: 48.442743, lng: 18.158229 },
    { lat: 48.4427, lng: 18.15847 },
    { lat: 48.442678, lng: 18.158764 },
    { lat: 48.442655, lng: 18.159027 },
    { lat: 48.442683, lng: 18.159467 },
    { lat: 48.442711, lng: 18.159872 },
    { lat: 48.4427, lng: 18.160182 },
    { lat: 48.442654, lng: 18.160534 },
    { lat: 48.442603, lng: 18.160769 },
    { lat: 48.442521, lng: 18.161057 },
    { lat: 48.4423, lng: 18.161369 },
    { lat: 48.442238, lng: 18.161457 },
    { lat: 48.44209, lng: 18.161757 },
    { lat: 48.44188, lng: 18.162004 },
    { lat: 48.441749, lng: 18.162145 },
    { lat: 48.441604, lng: 18.162322 },
    { lat: 48.441495, lng: 18.162439 },
    { lat: 48.441351, lng: 18.162604 },
    { lat: 48.441328, lng: 18.162874 },
    { lat: 48.441309, lng: 18.163203 },
    { lat: 48.441162, lng: 18.16369 },
    { lat: 48.441123, lng: 18.163896 },
    { lat: 48.441088, lng: 18.164048 },
    { lat: 48.441089, lng: 18.164429 },
    { lat: 48.441028, lng: 18.164846 },
    { lat: 48.440993, lng: 18.165163 },
    { lat: 48.440985, lng: 18.165497 },
    { lat: 48.441034, lng: 18.166154 },
    { lat: 48.441069, lng: 18.166787 },
    { lat: 48.441152, lng: 18.167186 },
    { lat: 48.441294, lng: 18.167889 },
    { lat: 48.441349, lng: 18.168217 },
    { lat: 48.441396, lng: 18.168522 },
    { lat: 48.441447, lng: 18.168826 },
    { lat: 48.441455, lng: 18.169231 },
    { lat: 48.44146, lng: 18.169518 },
    { lat: 48.441499, lng: 18.169953 },
    { lat: 48.4415, lng: 18.170257 },
    { lat: 48.441544, lng: 18.170445 },
    { lat: 48.441485, lng: 18.170645 },
    { lat: 48.441399, lng: 18.170915 },
    { lat: 48.441272, lng: 18.17132 },
    { lat: 48.441214, lng: 18.171789 },
    { lat: 48.441156, lng: 18.172306 },
    { lat: 48.441134, lng: 18.172522 },
    { lat: 48.441029, lng: 18.172986 },
    { lat: 48.44103, lng: 18.173409 },
    { lat: 48.441031, lng: 18.173854 },
    { lat: 48.441031, lng: 18.174447 },
    { lat: 48.441008, lng: 18.174881 },
    { lat: 48.440979, lng: 18.175543 },
    { lat: 48.440858, lng: 18.175908 },
    { lat: 48.44075, lng: 18.176242 },
    { lat: 48.440741, lng: 18.176239 },
    { lat: 48.440542, lng: 18.176433 },
    { lat: 48.440207, lng: 18.177173 },
    { lat: 48.440048, lng: 18.177472 },
    { lat: 48.439855, lng: 18.177837 },
    { lat: 48.439674, lng: 18.178471 },
    { lat: 48.439475, lng: 18.179182 },
    { lat: 48.439464, lng: 18.179217 },
    { lat: 48.439442, lng: 18.179576 },
    { lat: 48.439419, lng: 18.180052 },
    { lat: 48.439407, lng: 18.180246 },
    { lat: 48.439354, lng: 18.181087 },
    { lat: 48.439255, lng: 18.18153 },
    { lat: 48.439124, lng: 18.181846 },
    { lat: 48.439093, lng: 18.182093 },
    { lat: 48.439083, lng: 18.182216 },
    { lat: 48.439052, lng: 18.182482 },
    { lat: 48.438854, lng: 18.183263 },
    { lat: 48.438913, lng: 18.183543 },
    { lat: 48.43909, lng: 18.184134 },
    { lat: 48.439217, lng: 18.184552 },
    { lat: 48.439297, lng: 18.184596 },
    { lat: 48.4394, lng: 18.184985 },
    { lat: 48.439537, lng: 18.18552 },
    { lat: 48.439368, lng: 18.186183 },
    { lat: 48.439239, lng: 18.186645 },
    { lat: 48.439225, lng: 18.187191 },
    { lat: 48.439219, lng: 18.187567 },
    { lat: 48.43904, lng: 18.188095 },
    { lat: 48.438895, lng: 18.188775 },
    { lat: 48.438763, lng: 18.189296 },
    { lat: 48.438643, lng: 18.18981 },
    { lat: 48.438524, lng: 18.190407 },
    { lat: 48.43831, lng: 18.191441 },
    { lat: 48.438292, lng: 18.191524 },
    { lat: 48.43818, lng: 18.191827 },
    { lat: 48.43811, lng: 18.1921 },
  ],
  Urmince: [
    { lat: 48.5142749, lng: 18.1198449 },
    { lat: 48.5148445, lng: 18.1201475 },
    { lat: 48.5150028, lng: 18.1203468 },
    { lat: 48.5160436, lng: 18.1202556 },
    { lat: 48.5161511, lng: 18.1199708 },
    { lat: 48.5162296, lng: 18.1191129 },
    { lat: 48.5163654, lng: 18.118635 },
    { lat: 48.5164628, lng: 18.118489 },
    { lat: 48.5172131, lng: 18.1179831 },
    { lat: 48.5182893, lng: 18.1173788 },
    { lat: 48.519147, lng: 18.1173029 },
    { lat: 48.5219049, lng: 18.1162749 },
    { lat: 48.5232367, lng: 18.115987 },
    { lat: 48.5243194, lng: 18.1156644 },
    { lat: 48.5249071, lng: 18.115584 },
    { lat: 48.5248805, lng: 18.1169048 },
    { lat: 48.5250454, lng: 18.1181439 },
    { lat: 48.5251799, lng: 18.1185309 },
    { lat: 48.5251737, lng: 18.1188504 },
    { lat: 48.525357, lng: 18.1197283 },
    { lat: 48.5254146, lng: 18.120464 },
    { lat: 48.5258121, lng: 18.1207581 },
    { lat: 48.5261075, lng: 18.1208885 },
    { lat: 48.527754, lng: 18.1202677 },
    { lat: 48.5280061, lng: 18.122112 },
    { lat: 48.5283799, lng: 18.1239125 },
    { lat: 48.5284132, lng: 18.1246943 },
    { lat: 48.5283602, lng: 18.1254105 },
    { lat: 48.5284127, lng: 18.1272002 },
    { lat: 48.5285738, lng: 18.1288383 },
    { lat: 48.5287892, lng: 18.1300168 },
    { lat: 48.5322889, lng: 18.1297661 },
    { lat: 48.5324813, lng: 18.1288081 },
    { lat: 48.5328198, lng: 18.126489 },
    { lat: 48.534804, lng: 18.1259109 },
    { lat: 48.5340789, lng: 18.1296339 },
    { lat: 48.5342262, lng: 18.1295283 },
    { lat: 48.5347325, lng: 18.1292686 },
    { lat: 48.5360672, lng: 18.12905 },
    { lat: 48.5365706, lng: 18.1288983 },
    { lat: 48.5371413, lng: 18.1286205 },
    { lat: 48.5380238, lng: 18.1280045 },
    { lat: 48.5390928, lng: 18.1270621 },
    { lat: 48.5380455, lng: 18.1245673 },
    { lat: 48.5366345, lng: 18.1208107 },
    { lat: 48.5379805, lng: 18.1198788 },
    { lat: 48.5403001, lng: 18.1184162 },
    { lat: 48.5415127, lng: 18.1174602 },
    { lat: 48.5426053, lng: 18.116384 },
    { lat: 48.5431365, lng: 18.1159329 },
    { lat: 48.5455599, lng: 18.1141662 },
    { lat: 48.5475962, lng: 18.1127824 },
    { lat: 48.5478971, lng: 18.1126797 },
    { lat: 48.5490513, lng: 18.1120753 },
    { lat: 48.5496378, lng: 18.1114525 },
    { lat: 48.5507208, lng: 18.1105366 },
    { lat: 48.5513042, lng: 18.1099384 },
    { lat: 48.5516995, lng: 18.1094029 },
    { lat: 48.5524963, lng: 18.1081102 },
    { lat: 48.5540429, lng: 18.1060781 },
    { lat: 48.5547276, lng: 18.1049722 },
    { lat: 48.5550851, lng: 18.1054117 },
    { lat: 48.5557593, lng: 18.1044166 },
    { lat: 48.5563167, lng: 18.1037786 },
    { lat: 48.5564179, lng: 18.1038873 },
    { lat: 48.5580041, lng: 18.1024147 },
    { lat: 48.5582416, lng: 18.102614 },
    { lat: 48.5585541, lng: 18.1005881 },
    { lat: 48.5594554, lng: 18.0990015 },
    { lat: 48.5590978, lng: 18.0990255 },
    { lat: 48.5593495, lng: 18.0980626 },
    { lat: 48.5597182, lng: 18.0970733 },
    { lat: 48.5594036, lng: 18.0965772 },
    { lat: 48.5578804, lng: 18.0967175 },
    { lat: 48.5555968, lng: 18.0964587 },
    { lat: 48.5529853, lng: 18.0963433 },
    { lat: 48.5529421, lng: 18.096167 },
    { lat: 48.5517789, lng: 18.0966933 },
    { lat: 48.551657, lng: 18.0963647 },
    { lat: 48.5513484, lng: 18.0960089 },
    { lat: 48.5508871, lng: 18.0958419 },
    { lat: 48.5506027, lng: 18.09565 },
    { lat: 48.5510949, lng: 18.0949002 },
    { lat: 48.5503354, lng: 18.0938012 },
    { lat: 48.549893, lng: 18.0930522 },
    { lat: 48.548786, lng: 18.0908223 },
    { lat: 48.5476093, lng: 18.0882515 },
    { lat: 48.5467294, lng: 18.0886804 },
    { lat: 48.5461712, lng: 18.0875554 },
    { lat: 48.5457224, lng: 18.0867707 },
    { lat: 48.5455697, lng: 18.0867073 },
    { lat: 48.5448388, lng: 18.0870444 },
    { lat: 48.5443827, lng: 18.0871375 },
    { lat: 48.5441022, lng: 18.0871051 },
    { lat: 48.5438986, lng: 18.0870008 },
    { lat: 48.5436985, lng: 18.0868796 },
    { lat: 48.5434329, lng: 18.0866055 },
    { lat: 48.5433961, lng: 18.0864412 },
    { lat: 48.5438565, lng: 18.0855945 },
    { lat: 48.5440992, lng: 18.0848264 },
    { lat: 48.5430864, lng: 18.0834707 },
    { lat: 48.5423891, lng: 18.0837072 },
    { lat: 48.5422366, lng: 18.0837029 },
    { lat: 48.5421487, lng: 18.0838981 },
    { lat: 48.5420162, lng: 18.0839289 },
    { lat: 48.541946, lng: 18.0840593 },
    { lat: 48.5416541, lng: 18.0841623 },
    { lat: 48.5411375, lng: 18.0846681 },
    { lat: 48.540947, lng: 18.08467 },
    { lat: 48.5408607, lng: 18.084606 },
    { lat: 48.5407405, lng: 18.0846893 },
    { lat: 48.5403782, lng: 18.0840574 },
    { lat: 48.5394438, lng: 18.082748 },
    { lat: 48.5368264, lng: 18.0818443 },
    { lat: 48.534885, lng: 18.0754475 },
    { lat: 48.5369979, lng: 18.0735709 },
    { lat: 48.5369938, lng: 18.0720412 },
    { lat: 48.526016, lng: 18.0750533 },
    { lat: 48.5260892, lng: 18.0758357 },
    { lat: 48.5261139, lng: 18.0771598 },
    { lat: 48.5262676, lng: 18.0783904 },
    { lat: 48.5256364, lng: 18.0783065 },
    { lat: 48.5253674, lng: 18.0783406 },
    { lat: 48.5250933, lng: 18.0785327 },
    { lat: 48.5250511, lng: 18.0787569 },
    { lat: 48.5249932, lng: 18.0788102 },
    { lat: 48.5239056, lng: 18.0786049 },
    { lat: 48.524127, lng: 18.0769163 },
    { lat: 48.5242345, lng: 18.0756932 },
    { lat: 48.5232815, lng: 18.0758381 },
    { lat: 48.5220915, lng: 18.0761217 },
    { lat: 48.5219485, lng: 18.0762999 },
    { lat: 48.5219149, lng: 18.0762831 },
    { lat: 48.5218935, lng: 18.0764774 },
    { lat: 48.5217537, lng: 18.0777272 },
    { lat: 48.5213406, lng: 18.0789553 },
    { lat: 48.5208865, lng: 18.080061 },
    { lat: 48.5205408, lng: 18.0807821 },
    { lat: 48.5202692, lng: 18.0811953 },
    { lat: 48.5200843, lng: 18.0816588 },
    { lat: 48.5200777, lng: 18.0829865 },
    { lat: 48.5200068, lng: 18.083582 },
    { lat: 48.5197159, lng: 18.0840084 },
    { lat: 48.5194969, lng: 18.0842223 },
    { lat: 48.5192096, lng: 18.0847504 },
    { lat: 48.5187897, lng: 18.0857821 },
    { lat: 48.5187222, lng: 18.0861723 },
    { lat: 48.5187641, lng: 18.0884789 },
    { lat: 48.5187288, lng: 18.0889382 },
    { lat: 48.5190141, lng: 18.0901843 },
    { lat: 48.5200934, lng: 18.0938416 },
    { lat: 48.5224116, lng: 18.093152 },
    { lat: 48.5232144, lng: 18.0940453 },
    { lat: 48.523439, lng: 18.0945441 },
    { lat: 48.5232682, lng: 18.0955181 },
    { lat: 48.5229557, lng: 18.0960819 },
    { lat: 48.5227563, lng: 18.0967019 },
    { lat: 48.52257, lng: 18.0970247 },
    { lat: 48.5220025, lng: 18.0972233 },
    { lat: 48.5208659, lng: 18.0974334 },
    { lat: 48.5208573, lng: 18.0978233 },
    { lat: 48.5206875, lng: 18.0982948 },
    { lat: 48.5202292, lng: 18.0986978 },
    { lat: 48.5203071, lng: 18.101298 },
    { lat: 48.5200364, lng: 18.1048842 },
    { lat: 48.5184584, lng: 18.1113767 },
    { lat: 48.5177652, lng: 18.1119492 },
    { lat: 48.5161078, lng: 18.1128435 },
    { lat: 48.5156009, lng: 18.1129549 },
    { lat: 48.5157475, lng: 18.1157692 },
    { lat: 48.5149967, lng: 18.1160933 },
    { lat: 48.5149904, lng: 18.1162757 },
    { lat: 48.5144158, lng: 18.1161963 },
    { lat: 48.5142749, lng: 18.1198449 },
  ],
  VeľkéDvorany: [
    { lat: 48.5594554, lng: 18.0990015 },
    { lat: 48.5596716, lng: 18.0986272 },
    { lat: 48.5603063, lng: 18.0976136 },
    { lat: 48.5610613, lng: 18.0971715 },
    { lat: 48.5617779, lng: 18.0960544 },
    { lat: 48.5620415, lng: 18.0955444 },
    { lat: 48.5650009, lng: 18.0898334 },
    { lat: 48.5653238, lng: 18.0905479 },
    { lat: 48.5665561, lng: 18.0888142 },
    { lat: 48.5669282, lng: 18.088388 },
    { lat: 48.5676511, lng: 18.0873791 },
    { lat: 48.5677713, lng: 18.0871956 },
    { lat: 48.5677548, lng: 18.0866241 },
    { lat: 48.5676924, lng: 18.0848875 },
    { lat: 48.5676237, lng: 18.0842171 },
    { lat: 48.567158, lng: 18.0828432 },
    { lat: 48.5670671, lng: 18.0823493 },
    { lat: 48.5666942, lng: 18.0817459 },
    { lat: 48.5659309, lng: 18.0800692 },
    { lat: 48.5649518, lng: 18.0780997 },
    { lat: 48.5641528, lng: 18.0761226 },
    { lat: 48.5629925, lng: 18.0727418 },
    { lat: 48.5626346, lng: 18.0729866 },
    { lat: 48.5625582, lng: 18.0731871 },
    { lat: 48.5623748, lng: 18.0733658 },
    { lat: 48.5622523, lng: 18.0737609 },
    { lat: 48.5621088, lng: 18.0739511 },
    { lat: 48.5615825, lng: 18.0740969 },
    { lat: 48.5612903, lng: 18.0731579 },
    { lat: 48.5610088, lng: 18.0718829 },
    { lat: 48.5621146, lng: 18.0713985 },
    { lat: 48.5627551, lng: 18.070315 },
    { lat: 48.5630084, lng: 18.0700738 },
    { lat: 48.5631645, lng: 18.0698327 },
    { lat: 48.563559, lng: 18.0695563 },
    { lat: 48.5634732, lng: 18.0692677 },
    { lat: 48.5630219, lng: 18.068289 },
    { lat: 48.5627134, lng: 18.0677644 },
    { lat: 48.5612853, lng: 18.0642872 },
    { lat: 48.5605252, lng: 18.062882 },
    { lat: 48.560434, lng: 18.0623501 },
    { lat: 48.5596074, lng: 18.060329 },
    { lat: 48.5593895, lng: 18.0596374 },
    { lat: 48.5589999, lng: 18.0580634 },
    { lat: 48.5581335, lng: 18.0555023 },
    { lat: 48.5579825, lng: 18.0546849 },
    { lat: 48.5579416, lng: 18.0541039 },
    { lat: 48.5579631, lng: 18.0524437 },
    { lat: 48.5580985, lng: 18.0503734 },
    { lat: 48.558098, lng: 18.0494318 },
    { lat: 48.5581315, lng: 18.0493457 },
    { lat: 48.5580886, lng: 18.0493679 },
    { lat: 48.5573656, lng: 18.0498723 },
    { lat: 48.5545282, lng: 18.0521483 },
    { lat: 48.5525174, lng: 18.0534325 },
    { lat: 48.5465161, lng: 18.0574657 },
    { lat: 48.5461052, lng: 18.0577319 },
    { lat: 48.5426281, lng: 18.0594564 },
    { lat: 48.5422922, lng: 18.0583919 },
    { lat: 48.5396597, lng: 18.05913 },
    { lat: 48.537167, lng: 18.0592293 },
    { lat: 48.5371705, lng: 18.0592531 },
    { lat: 48.536911, lng: 18.0593284 },
    { lat: 48.5369938, lng: 18.0720412 },
    { lat: 48.5369979, lng: 18.0735709 },
    { lat: 48.534885, lng: 18.0754475 },
    { lat: 48.5368264, lng: 18.0818443 },
    { lat: 48.5394438, lng: 18.082748 },
    { lat: 48.5403782, lng: 18.0840574 },
    { lat: 48.5407405, lng: 18.0846893 },
    { lat: 48.5408607, lng: 18.084606 },
    { lat: 48.540947, lng: 18.08467 },
    { lat: 48.5411375, lng: 18.0846681 },
    { lat: 48.5416541, lng: 18.0841623 },
    { lat: 48.541946, lng: 18.0840593 },
    { lat: 48.5420162, lng: 18.0839289 },
    { lat: 48.5421487, lng: 18.0838981 },
    { lat: 48.5422366, lng: 18.0837029 },
    { lat: 48.5423891, lng: 18.0837072 },
    { lat: 48.5430864, lng: 18.0834707 },
    { lat: 48.5440992, lng: 18.0848264 },
    { lat: 48.5438565, lng: 18.0855945 },
    { lat: 48.5433961, lng: 18.0864412 },
    { lat: 48.5434329, lng: 18.0866055 },
    { lat: 48.5436985, lng: 18.0868796 },
    { lat: 48.5438986, lng: 18.0870008 },
    { lat: 48.5441022, lng: 18.0871051 },
    { lat: 48.5443827, lng: 18.0871375 },
    { lat: 48.5448388, lng: 18.0870444 },
    { lat: 48.5455697, lng: 18.0867073 },
    { lat: 48.5457224, lng: 18.0867707 },
    { lat: 48.5461712, lng: 18.0875554 },
    { lat: 48.5467294, lng: 18.0886804 },
    { lat: 48.5476093, lng: 18.0882515 },
    { lat: 48.548786, lng: 18.0908223 },
    { lat: 48.549893, lng: 18.0930522 },
    { lat: 48.5503354, lng: 18.0938012 },
    { lat: 48.5510949, lng: 18.0949002 },
    { lat: 48.5506027, lng: 18.09565 },
    { lat: 48.5508871, lng: 18.0958419 },
    { lat: 48.5513484, lng: 18.0960089 },
    { lat: 48.551657, lng: 18.0963647 },
    { lat: 48.5517789, lng: 18.0966933 },
    { lat: 48.5529421, lng: 18.096167 },
    { lat: 48.5529853, lng: 18.0963433 },
    { lat: 48.5555968, lng: 18.0964587 },
    { lat: 48.5578804, lng: 18.0967175 },
    { lat: 48.5594036, lng: 18.0965772 },
    { lat: 48.5597182, lng: 18.0970733 },
    { lat: 48.5593495, lng: 18.0980626 },
    { lat: 48.5590978, lng: 18.0990255 },
    { lat: 48.5594554, lng: 18.0990015 },
  ],
  Tvrdomestice: [
    { lat: 48.668761, lng: 18.14219 },
    { lat: 48.668862, lng: 18.14185 },
    { lat: 48.668921, lng: 18.141673 },
    { lat: 48.668985, lng: 18.141527 },
    { lat: 48.669073, lng: 18.141268 },
    { lat: 48.669132, lng: 18.141139 },
    { lat: 48.669339, lng: 18.140377 },
    { lat: 48.669357, lng: 18.140237 },
    { lat: 48.669418, lng: 18.140033 },
    { lat: 48.669552, lng: 18.13971 },
    { lat: 48.669653, lng: 18.139426 },
    { lat: 48.669633, lng: 18.139021 },
    { lat: 48.669763, lng: 18.138473 },
    { lat: 48.669803, lng: 18.138163 },
    { lat: 48.669829, lng: 18.137804 },
    { lat: 48.669746, lng: 18.137149 },
    { lat: 48.669767, lng: 18.136384 },
    { lat: 48.669789, lng: 18.136157 },
    { lat: 48.669885, lng: 18.135818 },
    { lat: 48.67012, lng: 18.134771 },
    { lat: 48.670149, lng: 18.134616 },
    { lat: 48.670193, lng: 18.134236 },
    { lat: 48.670216, lng: 18.133781 },
    { lat: 48.670455, lng: 18.132732 },
    { lat: 48.670578, lng: 18.132261 },
    { lat: 48.670887, lng: 18.131132 },
    { lat: 48.6709, lng: 18.131109 },
    { lat: 48.6707338, lng: 18.1305913 },
    { lat: 48.6705517, lng: 18.130217 },
    { lat: 48.6702751, lng: 18.1298859 },
    { lat: 48.6699994, lng: 18.1297026 },
    { lat: 48.6699034, lng: 18.1294918 },
    { lat: 48.6698812, lng: 18.1289724 },
    { lat: 48.6696045, lng: 18.1280383 },
    { lat: 48.6694385, lng: 18.1272616 },
    { lat: 48.669411, lng: 18.1265948 },
    { lat: 48.669122, lng: 18.1255252 },
    { lat: 48.6687316, lng: 18.1249294 },
    { lat: 48.6685728, lng: 18.1240993 },
    { lat: 48.6684508, lng: 18.1238648 },
    { lat: 48.6681133, lng: 18.1236522 },
    { lat: 48.6678457, lng: 18.1222161 },
    { lat: 48.667706, lng: 18.1218526 },
    { lat: 48.6672007, lng: 18.1197436 },
    { lat: 48.6670488, lng: 18.1196767 },
    { lat: 48.6657255, lng: 18.1184237 },
    { lat: 48.6655089, lng: 18.1184246 },
    { lat: 48.6654857, lng: 18.1183592 },
    { lat: 48.6653764, lng: 18.1183216 },
    { lat: 48.6651307, lng: 18.1183874 },
    { lat: 48.6647253, lng: 18.117955 },
    { lat: 48.6646042, lng: 18.1179875 },
    { lat: 48.6645156, lng: 18.1178797 },
    { lat: 48.6642164, lng: 18.118074 },
    { lat: 48.6641735, lng: 18.1181497 },
    { lat: 48.6641431, lng: 18.11852 },
    { lat: 48.6638864, lng: 18.1184495 },
    { lat: 48.6638032, lng: 18.1184952 },
    { lat: 48.6637867, lng: 18.1186777 },
    { lat: 48.6635958, lng: 18.1189858 },
    { lat: 48.6626335, lng: 18.1198201 },
    { lat: 48.6620057, lng: 18.1198542 },
    { lat: 48.6614503, lng: 18.1200255 },
    { lat: 48.6611106, lng: 18.1200067 },
    { lat: 48.6608841, lng: 18.1196069 },
    { lat: 48.6606014, lng: 18.1185293 },
    { lat: 48.659356, lng: 18.1194083 },
    { lat: 48.658216, lng: 18.1197757 },
    { lat: 48.6579987, lng: 18.119938 },
    { lat: 48.6577869, lng: 18.1198098 },
    { lat: 48.6576267, lng: 18.1199657 },
    { lat: 48.6568422, lng: 18.1203697 },
    { lat: 48.6568502, lng: 18.1204111 },
    { lat: 48.6561085, lng: 18.1209072 },
    { lat: 48.6560551, lng: 18.121156 },
    { lat: 48.6549049, lng: 18.1216153 },
    { lat: 48.6535887, lng: 18.1217465 },
    { lat: 48.6533346, lng: 18.1217031 },
    { lat: 48.6522072, lng: 18.1221087 },
    { lat: 48.6492258, lng: 18.122654 },
    { lat: 48.648734, lng: 18.1228145 },
    { lat: 48.6477362, lng: 18.122947 },
    { lat: 48.6448278, lng: 18.1239104 },
    { lat: 48.6441022, lng: 18.1239124 },
    { lat: 48.6439189, lng: 18.1240013 },
    { lat: 48.6435877, lng: 18.123914 },
    { lat: 48.6427255, lng: 18.1243973 },
    { lat: 48.6402671, lng: 18.1249301 },
    { lat: 48.6376669, lng: 18.1259191 },
    { lat: 48.6376743, lng: 18.1258717 },
    { lat: 48.6367932, lng: 18.1261067 },
    { lat: 48.6363356, lng: 18.126136 },
    { lat: 48.6349159, lng: 18.1264666 },
    { lat: 48.6347326, lng: 18.1266522 },
    { lat: 48.6347141, lng: 18.126741 },
    { lat: 48.6349716, lng: 18.1289129 },
    { lat: 48.6351365, lng: 18.1298269 },
    { lat: 48.6352529, lng: 18.1310313 },
    { lat: 48.6353588, lng: 18.1333943 },
    { lat: 48.6355206, lng: 18.1352246 },
    { lat: 48.6358784, lng: 18.1378268 },
    { lat: 48.6360615, lng: 18.1413413 },
    { lat: 48.6358547, lng: 18.1422306 },
    { lat: 48.6355159, lng: 18.1467321 },
    { lat: 48.6355717, lng: 18.1474979 },
    { lat: 48.6357572, lng: 18.1489259 },
    { lat: 48.6360265, lng: 18.1503182 },
    { lat: 48.6362175, lng: 18.1510611 },
    { lat: 48.6358789, lng: 18.1513724 },
    { lat: 48.6366803, lng: 18.1539999 },
    { lat: 48.6369287, lng: 18.1548013 },
    { lat: 48.6367266, lng: 18.1550514 },
    { lat: 48.6368533, lng: 18.1555641 },
    { lat: 48.6370252, lng: 18.15697 },
    { lat: 48.6376326, lng: 18.1589018 },
    { lat: 48.6376858, lng: 18.1591382 },
    { lat: 48.6376843, lng: 18.1595205 },
    { lat: 48.6390531, lng: 18.1641746 },
    { lat: 48.6397923, lng: 18.1663376 },
    { lat: 48.6399573, lng: 18.1669452 },
    { lat: 48.6404775, lng: 18.1664966 },
    { lat: 48.6406424, lng: 18.1669106 },
    { lat: 48.642879, lng: 18.164657 },
    { lat: 48.643214, lng: 18.164573 },
    { lat: 48.643898, lng: 18.164229 },
    { lat: 48.644108, lng: 18.164146 },
    { lat: 48.644187, lng: 18.164106 },
    { lat: 48.644401, lng: 18.16398 },
    { lat: 48.644743, lng: 18.163748 },
    { lat: 48.645005, lng: 18.16357 },
    { lat: 48.645282, lng: 18.163471 },
    { lat: 48.645558, lng: 18.163335 },
    { lat: 48.645705, lng: 18.163279 },
    { lat: 48.646131, lng: 18.163156 },
    { lat: 48.646234, lng: 18.163088 },
    { lat: 48.646573, lng: 18.162696 },
    { lat: 48.646916, lng: 18.162374 },
    { lat: 48.647917, lng: 18.161627 },
    { lat: 48.648064, lng: 18.161559 },
    { lat: 48.648318, lng: 18.161396 },
    { lat: 48.64839, lng: 18.161349 },
    { lat: 48.648546, lng: 18.161229 },
    { lat: 48.648899, lng: 18.160985 },
    { lat: 48.648987, lng: 18.160935 },
    { lat: 48.649158, lng: 18.160874 },
    { lat: 48.649204, lng: 18.160841 },
    { lat: 48.649308, lng: 18.160715 },
    { lat: 48.649585, lng: 18.160514 },
    { lat: 48.649944, lng: 18.160213 },
    { lat: 48.65013, lng: 18.160116 },
    { lat: 48.650418, lng: 18.159878 },
    { lat: 48.650577, lng: 18.15977 },
    { lat: 48.650782, lng: 18.159564 },
    { lat: 48.650907, lng: 18.159334 },
    { lat: 48.650979, lng: 18.159093 },
    { lat: 48.651032, lng: 18.158991 },
    { lat: 48.651082, lng: 18.158858 },
    { lat: 48.651233, lng: 18.15859 },
    { lat: 48.651424, lng: 18.158128 },
    { lat: 48.651559, lng: 18.15785 },
    { lat: 48.651988, lng: 18.156829 },
    { lat: 48.652049, lng: 18.156587 },
    { lat: 48.652133, lng: 18.156325 },
    { lat: 48.652432, lng: 18.155656 },
    { lat: 48.6525, lng: 18.155502 },
    { lat: 48.652607, lng: 18.15507 },
    { lat: 48.652842, lng: 18.154215 },
    { lat: 48.652947, lng: 18.153998 },
    { lat: 48.652982, lng: 18.153919 },
    { lat: 48.65303, lng: 18.153789 },
    { lat: 48.653174, lng: 18.153462 },
    { lat: 48.653369, lng: 18.153067 },
    { lat: 48.653483, lng: 18.152804 },
    { lat: 48.653599, lng: 18.152351 },
    { lat: 48.654045, lng: 18.150978 },
    { lat: 48.65411, lng: 18.150637 },
    { lat: 48.654181, lng: 18.150267 },
    { lat: 48.654395, lng: 18.149185 },
    { lat: 48.654502, lng: 18.148864 },
    { lat: 48.6547, lng: 18.148429 },
    { lat: 48.655023, lng: 18.147696 },
    { lat: 48.655188, lng: 18.147449 },
    { lat: 48.655668, lng: 18.146959 },
    { lat: 48.655905, lng: 18.146627 },
    { lat: 48.656059, lng: 18.146447 },
    { lat: 48.656085, lng: 18.146478 },
    { lat: 48.657108, lng: 18.147727 },
    { lat: 48.65726, lng: 18.147915 },
    { lat: 48.657554, lng: 18.148283 },
    { lat: 48.658907, lng: 18.146913 },
    { lat: 48.660134, lng: 18.145823 },
    { lat: 48.660681, lng: 18.145462 },
    { lat: 48.660906, lng: 18.145314 },
    { lat: 48.660911, lng: 18.145279 },
    { lat: 48.661024, lng: 18.145209 },
    { lat: 48.661101, lng: 18.145047 },
    { lat: 48.66119, lng: 18.144968 },
    { lat: 48.661268, lng: 18.144923 },
    { lat: 48.661289, lng: 18.144953 },
    { lat: 48.661426, lng: 18.144898 },
    { lat: 48.661436, lng: 18.144854 },
    { lat: 48.661499, lng: 18.144856 },
    { lat: 48.661548, lng: 18.144747 },
    { lat: 48.661614, lng: 18.144755 },
    { lat: 48.661645, lng: 18.14465 },
    { lat: 48.661812, lng: 18.144478 },
    { lat: 48.661886, lng: 18.144389 },
    { lat: 48.66195, lng: 18.144289 },
    { lat: 48.661968, lng: 18.14422 },
    { lat: 48.662042, lng: 18.144117 },
    { lat: 48.662075, lng: 18.144122 },
    { lat: 48.662077, lng: 18.144062 },
    { lat: 48.662136, lng: 18.14407 },
    { lat: 48.662187, lng: 18.143991 },
    { lat: 48.662321, lng: 18.14396 },
    { lat: 48.662532, lng: 18.143769 },
    { lat: 48.662706, lng: 18.143642 },
    { lat: 48.662799, lng: 18.143551 },
    { lat: 48.662884, lng: 18.143534 },
    { lat: 48.662963, lng: 18.143498 },
    { lat: 48.663015, lng: 18.143407 },
    { lat: 48.663089, lng: 18.143361 },
    { lat: 48.663194, lng: 18.143312 },
    { lat: 48.663231, lng: 18.143269 },
    { lat: 48.66327, lng: 18.143272 },
    { lat: 48.663341, lng: 18.143194 },
    { lat: 48.663382, lng: 18.143214 },
    { lat: 48.663406, lng: 18.14316 },
    { lat: 48.663616, lng: 18.143045 },
    { lat: 48.663859, lng: 18.142955 },
    { lat: 48.664023, lng: 18.14292 },
    { lat: 48.664142, lng: 18.142899 },
    { lat: 48.664617, lng: 18.142772 },
    { lat: 48.664999, lng: 18.142682 },
    { lat: 48.665282, lng: 18.142639 },
    { lat: 48.666231, lng: 18.142482 },
    { lat: 48.666235, lng: 18.142504 },
    { lat: 48.666458, lng: 18.143608 },
    { lat: 48.666561, lng: 18.143535 },
    { lat: 48.666946, lng: 18.143325 },
    { lat: 48.667558, lng: 18.142996 },
    { lat: 48.667863, lng: 18.142833 },
    { lat: 48.668556, lng: 18.142032 },
    { lat: 48.668761, lng: 18.14219 },
  ],
  Nemčice: [
    { lat: 48.5620415, lng: 18.0955444 },
    { lat: 48.5617779, lng: 18.0960544 },
    { lat: 48.5610613, lng: 18.0971715 },
    { lat: 48.5603063, lng: 18.0976136 },
    { lat: 48.5596716, lng: 18.0986272 },
    { lat: 48.5594554, lng: 18.0990015 },
    { lat: 48.5585541, lng: 18.1005881 },
    { lat: 48.5582416, lng: 18.102614 },
    { lat: 48.5580041, lng: 18.1024147 },
    { lat: 48.5564179, lng: 18.1038873 },
    { lat: 48.5563167, lng: 18.1037786 },
    { lat: 48.5557593, lng: 18.1044166 },
    { lat: 48.5550851, lng: 18.1054117 },
    { lat: 48.5547276, lng: 18.1049722 },
    { lat: 48.5540429, lng: 18.1060781 },
    { lat: 48.5524963, lng: 18.1081102 },
    { lat: 48.5516995, lng: 18.1094029 },
    { lat: 48.5513042, lng: 18.1099384 },
    { lat: 48.5507208, lng: 18.1105366 },
    { lat: 48.5496378, lng: 18.1114525 },
    { lat: 48.5490513, lng: 18.1120753 },
    { lat: 48.5478971, lng: 18.1126797 },
    { lat: 48.5475962, lng: 18.1127824 },
    { lat: 48.5455599, lng: 18.1141662 },
    { lat: 48.5431365, lng: 18.1159329 },
    { lat: 48.5426053, lng: 18.116384 },
    { lat: 48.5415127, lng: 18.1174602 },
    { lat: 48.5403001, lng: 18.1184162 },
    { lat: 48.5379805, lng: 18.1198788 },
    { lat: 48.5366345, lng: 18.1208107 },
    { lat: 48.5380455, lng: 18.1245673 },
    { lat: 48.5390928, lng: 18.1270621 },
    { lat: 48.5380238, lng: 18.1280045 },
    { lat: 48.5371413, lng: 18.1286205 },
    { lat: 48.5365706, lng: 18.1288983 },
    { lat: 48.5360672, lng: 18.12905 },
    { lat: 48.5347325, lng: 18.1292686 },
    { lat: 48.5342262, lng: 18.1295283 },
    { lat: 48.5340789, lng: 18.1296339 },
    { lat: 48.5342042, lng: 18.1315715 },
    { lat: 48.5342884, lng: 18.1321023 },
    { lat: 48.5345942, lng: 18.133263 },
    { lat: 48.5348523, lng: 18.1339535 },
    { lat: 48.5357515, lng: 18.1358308 },
    { lat: 48.5359017, lng: 18.1355798 },
    { lat: 48.5360786, lng: 18.1354582 },
    { lat: 48.5364376, lng: 18.1350165 },
    { lat: 48.537558, lng: 18.1340056 },
    { lat: 48.5378585, lng: 18.1347461 },
    { lat: 48.5378906, lng: 18.134969 },
    { lat: 48.5382835, lng: 18.135494 },
    { lat: 48.5407785, lng: 18.1403707 },
    { lat: 48.5414042, lng: 18.1417179 },
    { lat: 48.5417057, lng: 18.1424569 },
    { lat: 48.5417457, lng: 18.1427376 },
    { lat: 48.5410291, lng: 18.1450445 },
    { lat: 48.5409382, lng: 18.1455746 },
    { lat: 48.5402135, lng: 18.1472999 },
    { lat: 48.5400009, lng: 18.1476616 },
    { lat: 48.5394532, lng: 18.1484294 },
    { lat: 48.5393025, lng: 18.1484977 },
    { lat: 48.5387979, lng: 18.1491155 },
    { lat: 48.5381832, lng: 18.150229 },
    { lat: 48.5372501, lng: 18.1508887 },
    { lat: 48.5366763, lng: 18.1511331 },
    { lat: 48.5363434, lng: 18.1515004 },
    { lat: 48.5360935, lng: 18.1515308 },
    { lat: 48.5361519, lng: 18.1515673 },
    { lat: 48.5331082, lng: 18.159701 },
    { lat: 48.5280878, lng: 18.1582672 },
    { lat: 48.5280707, lng: 18.1583987 },
    { lat: 48.528215, lng: 18.1584363 },
    { lat: 48.5277707, lng: 18.1610426 },
    { lat: 48.5276856, lng: 18.1615031 },
    { lat: 48.5280039, lng: 18.1615402 },
    { lat: 48.5281776, lng: 18.1615996 },
    { lat: 48.5284409, lng: 18.1615653 },
    { lat: 48.5285743, lng: 18.1610439 },
    { lat: 48.5292298, lng: 18.1611574 },
    { lat: 48.5295489, lng: 18.1614856 },
    { lat: 48.5299587, lng: 18.1621484 },
    { lat: 48.5305186, lng: 18.1627964 },
    { lat: 48.5305767, lng: 18.1632509 },
    { lat: 48.5307539, lng: 18.1636391 },
    { lat: 48.5315073, lng: 18.1634972 },
    { lat: 48.5318655, lng: 18.1635238 },
    { lat: 48.5320664, lng: 18.1632531 },
    { lat: 48.5321677, lng: 18.1632469 },
    { lat: 48.5324022, lng: 18.1634781 },
    { lat: 48.5325866, lng: 18.1640463 },
    { lat: 48.5326827, lng: 18.164207 },
    { lat: 48.5328106, lng: 18.1642511 },
    { lat: 48.5330533, lng: 18.1641561 },
    { lat: 48.533567, lng: 18.1644912 },
    { lat: 48.533962, lng: 18.1646102 },
    { lat: 48.5347072, lng: 18.1651007 },
    { lat: 48.535221, lng: 18.1652068 },
    { lat: 48.5351144, lng: 18.1657603 },
    { lat: 48.5354616, lng: 18.1662028 },
    { lat: 48.5356764, lng: 18.1667546 },
    { lat: 48.5357874, lng: 18.1668942 },
    { lat: 48.5358778, lng: 18.166957 },
    { lat: 48.5361578, lng: 18.1669412 },
    { lat: 48.5392723, lng: 18.1622126 },
    { lat: 48.5393522, lng: 18.1618776 },
    { lat: 48.5394359, lng: 18.1598418 },
    { lat: 48.539131, lng: 18.1589041 },
    { lat: 48.5389462, lng: 18.1578017 },
    { lat: 48.5387844, lng: 18.1573651 },
    { lat: 48.5400014, lng: 18.1550837 },
    { lat: 48.5407121, lng: 18.1536827 },
    { lat: 48.5406651, lng: 18.1536504 },
    { lat: 48.541733, lng: 18.1516168 },
    { lat: 48.5429901, lng: 18.1531099 },
    { lat: 48.5446244, lng: 18.1497739 },
    { lat: 48.5452655, lng: 18.1504946 },
    { lat: 48.5455007, lng: 18.1491272 },
    { lat: 48.5460582, lng: 18.1467027 },
    { lat: 48.5463694, lng: 18.1469098 },
    { lat: 48.5464473, lng: 18.1466011 },
    { lat: 48.5461326, lng: 18.1464286 },
    { lat: 48.5474705, lng: 18.1412432 },
    { lat: 48.5507911, lng: 18.1402064 },
    { lat: 48.5507506, lng: 18.1398606 },
    { lat: 48.5515313, lng: 18.1395861 },
    { lat: 48.5516086, lng: 18.1399228 },
    { lat: 48.5602737, lng: 18.1372767 },
    { lat: 48.5603632, lng: 18.1372613 },
    { lat: 48.5607949, lng: 18.1376827 },
    { lat: 48.5609294, lng: 18.1375477 },
    { lat: 48.5605966, lng: 18.1371799 },
    { lat: 48.5593801, lng: 18.1358633 },
    { lat: 48.558812, lng: 18.1348316 },
    { lat: 48.5583111, lng: 18.133496 },
    { lat: 48.5582642, lng: 18.1333319 },
    { lat: 48.559329, lng: 18.1324707 },
    { lat: 48.5588065, lng: 18.1310777 },
    { lat: 48.5615503, lng: 18.1289354 },
    { lat: 48.5621053, lng: 18.1302493 },
    { lat: 48.5664497, lng: 18.1268915 },
    { lat: 48.5651406, lng: 18.1234473 },
    { lat: 48.5651151, lng: 18.1233801 },
    { lat: 48.5639094, lng: 18.1199919 },
    { lat: 48.5626971, lng: 18.1158327 },
    { lat: 48.5641004, lng: 18.1151641 },
    { lat: 48.5657299, lng: 18.1142756 },
    { lat: 48.5663925, lng: 18.1142596 },
    { lat: 48.5668983, lng: 18.1137603 },
    { lat: 48.567431, lng: 18.11289 },
    { lat: 48.568277, lng: 18.1120181 },
    { lat: 48.5682243, lng: 18.111828 },
    { lat: 48.5686978, lng: 18.111396 },
    { lat: 48.5685491, lng: 18.1109557 },
    { lat: 48.5682123, lng: 18.1111978 },
    { lat: 48.5673126, lng: 18.1089346 },
    { lat: 48.5654434, lng: 18.1046099 },
    { lat: 48.5630675, lng: 18.1007872 },
    { lat: 48.5610239, lng: 18.0973617 },
    { lat: 48.5610791, lng: 18.0972051 },
    { lat: 48.5622578, lng: 18.0965973 },
    { lat: 48.5623416, lng: 18.0964493 },
    { lat: 48.5623366, lng: 18.0963641 },
    { lat: 48.5620415, lng: 18.0955444 },
  ],
  Nemečky: [
    { lat: 48.684069, lng: 18.120233 },
    { lat: 48.684072, lng: 18.120201 },
    { lat: 48.684073, lng: 18.120169 },
    { lat: 48.684389, lng: 18.120115 },
    { lat: 48.68457, lng: 18.120188 },
    { lat: 48.684689, lng: 18.120283 },
    { lat: 48.684868, lng: 18.120254 },
    { lat: 48.685113, lng: 18.120121 },
    { lat: 48.685292, lng: 18.120114 },
    { lat: 48.68536, lng: 18.120101 },
    { lat: 48.685413, lng: 18.120087 },
    { lat: 48.685476, lng: 18.120053 },
    { lat: 48.685718, lng: 18.119945 },
    { lat: 48.685872, lng: 18.119933 },
    { lat: 48.685993, lng: 18.119977 },
    { lat: 48.686196, lng: 18.119939 },
    { lat: 48.686258, lng: 18.119928 },
    { lat: 48.686319, lng: 18.119874 },
    { lat: 48.686553, lng: 18.119693 },
    { lat: 48.686896, lng: 18.119692 },
    { lat: 48.687091, lng: 18.119643 },
    { lat: 48.687234, lng: 18.119611 },
    { lat: 48.687471, lng: 18.119588 },
    { lat: 48.687966, lng: 18.119622 },
    { lat: 48.688242, lng: 18.119707 },
    { lat: 48.688417, lng: 18.119453 },
    { lat: 48.688394, lng: 18.119049 },
    { lat: 48.688406, lng: 18.118987 },
    { lat: 48.688395, lng: 18.118867 },
    { lat: 48.68843, lng: 18.118779 },
    { lat: 48.688576, lng: 18.117933 },
    { lat: 48.688604, lng: 18.117808 },
    { lat: 48.688932, lng: 18.117043 },
    { lat: 48.689031, lng: 18.116767 },
    { lat: 48.689252, lng: 18.116368 },
    { lat: 48.689429, lng: 18.116059 },
    { lat: 48.689541, lng: 18.115885 },
    { lat: 48.689676, lng: 18.115705 },
    { lat: 48.689821, lng: 18.115538 },
    { lat: 48.690022, lng: 18.11532 },
    { lat: 48.690093, lng: 18.115242 },
    { lat: 48.690182, lng: 18.115113 },
    { lat: 48.690376, lng: 18.114721 },
    { lat: 48.690553, lng: 18.114479 },
    { lat: 48.690893, lng: 18.113871 },
    { lat: 48.691065, lng: 18.113581 },
    { lat: 48.691258, lng: 18.113071 },
    { lat: 48.691554, lng: 18.112563 },
    { lat: 48.691658, lng: 18.112257 },
    { lat: 48.691824, lng: 18.111904 },
    { lat: 48.691949, lng: 18.111547 },
    { lat: 48.692134, lng: 18.111273 },
    { lat: 48.692295, lng: 18.111043 },
    { lat: 48.69243, lng: 18.110885 },
    { lat: 48.69261, lng: 18.110632 },
    { lat: 48.692948, lng: 18.110109 },
    { lat: 48.693012, lng: 18.109965 },
    { lat: 48.693323, lng: 18.109592 },
    { lat: 48.693401, lng: 18.109486 },
    { lat: 48.69343, lng: 18.109441 },
    { lat: 48.693558, lng: 18.109183 },
    { lat: 48.693574, lng: 18.109145 },
    { lat: 48.693806, lng: 18.108864 },
    { lat: 48.694019, lng: 18.108565 },
    { lat: 48.694114, lng: 18.108456 },
    { lat: 48.694213, lng: 18.108335 },
    { lat: 48.694325, lng: 18.108139 },
    { lat: 48.694433, lng: 18.107991 },
    { lat: 48.694602, lng: 18.107778 },
    { lat: 48.694776, lng: 18.107533 },
    { lat: 48.694888, lng: 18.107357 },
    { lat: 48.695016, lng: 18.107138 },
    { lat: 48.695099, lng: 18.106952 },
    { lat: 48.695425, lng: 18.106644 },
    { lat: 48.695603, lng: 18.106406 },
    { lat: 48.695855, lng: 18.106133 },
    { lat: 48.695719, lng: 18.106047 },
    { lat: 48.695974, lng: 18.105894 },
    { lat: 48.696336, lng: 18.10573 },
    { lat: 48.696458, lng: 18.105639 },
    { lat: 48.696612, lng: 18.105552 },
    { lat: 48.696904, lng: 18.105268 },
    { lat: 48.696902, lng: 18.104928 },
    { lat: 48.696873, lng: 18.104574 },
    { lat: 48.696784, lng: 18.104471 },
    { lat: 48.696586, lng: 18.103392 },
    { lat: 48.696512, lng: 18.103221 },
    { lat: 48.696477, lng: 18.10314 },
    { lat: 48.696308, lng: 18.103141 },
    { lat: 48.696263, lng: 18.103151 },
    { lat: 48.69613, lng: 18.103178 },
    { lat: 48.695645, lng: 18.103514 },
    { lat: 48.695579, lng: 18.103475 },
    { lat: 48.695486, lng: 18.103428 },
    { lat: 48.6946, lng: 18.102665 },
    { lat: 48.694426, lng: 18.102418 },
    { lat: 48.694374, lng: 18.102192 },
    { lat: 48.694223, lng: 18.101695 },
    { lat: 48.694134, lng: 18.101511 },
    { lat: 48.693856, lng: 18.101035 },
    { lat: 48.693733, lng: 18.100811 },
    { lat: 48.693659, lng: 18.100649 },
    { lat: 48.693399, lng: 18.100403 },
    { lat: 48.693059, lng: 18.100322 },
    { lat: 48.692944, lng: 18.100152 },
    { lat: 48.692943, lng: 18.100147 },
    { lat: 48.692906, lng: 18.099962 },
    { lat: 48.69282, lng: 18.099465 },
    { lat: 48.692764, lng: 18.099078 },
    { lat: 48.692653, lng: 18.09877 },
    { lat: 48.692498, lng: 18.098118 },
    { lat: 48.692349, lng: 18.097293 },
    { lat: 48.692122, lng: 18.096936 },
    { lat: 48.691944, lng: 18.096772 },
    { lat: 48.691797, lng: 18.096762 },
    { lat: 48.691663, lng: 18.097069 },
    { lat: 48.691206, lng: 18.096487 },
    { lat: 48.6908435, lng: 18.0963853 },
    { lat: 48.6905932, lng: 18.0965648 },
    { lat: 48.6903095, lng: 18.0968873 },
    { lat: 48.6899602, lng: 18.0970289 },
    { lat: 48.6894911, lng: 18.0970543 },
    { lat: 48.6891246, lng: 18.0969457 },
    { lat: 48.688795, lng: 18.0965378 },
    { lat: 48.6886659, lng: 18.0963003 },
    { lat: 48.6882941, lng: 18.0959238 },
    { lat: 48.688047, lng: 18.0950945 },
    { lat: 48.6877482, lng: 18.0944207 },
    { lat: 48.6873469, lng: 18.0941628 },
    { lat: 48.6864158, lng: 18.0942184 },
    { lat: 48.6857173, lng: 18.0939294 },
    { lat: 48.6853408, lng: 18.0938596 },
    { lat: 48.6851462, lng: 18.0938724 },
    { lat: 48.6848774, lng: 18.0940997 },
    { lat: 48.6841433, lng: 18.0945322 },
    { lat: 48.6835926, lng: 18.0945332 },
    { lat: 48.6831297, lng: 18.0946653 },
    { lat: 48.6824632, lng: 18.0944617 },
    { lat: 48.6820077, lng: 18.0944696 },
    { lat: 48.6808626, lng: 18.0955008 },
    { lat: 48.6800605, lng: 18.0967395 },
    { lat: 48.679569, lng: 18.0973083 },
    { lat: 48.6791335, lng: 18.0976991 },
    { lat: 48.6784885, lng: 18.0979194 },
    { lat: 48.677704, lng: 18.0986675 },
    { lat: 48.676136, lng: 18.0995467 },
    { lat: 48.6749967, lng: 18.1004477 },
    { lat: 48.6744503, lng: 18.1009829 },
    { lat: 48.6738609, lng: 18.1009008 },
    { lat: 48.6733233, lng: 18.1009942 },
    { lat: 48.6724385, lng: 18.1013392 },
    { lat: 48.6716007, lng: 18.1014297 },
    { lat: 48.6705665, lng: 18.1022509 },
    { lat: 48.6698064, lng: 18.102647 },
    { lat: 48.6692586, lng: 18.1028121 },
    { lat: 48.6689086, lng: 18.1031761 },
    { lat: 48.6686155, lng: 18.1037243 },
    { lat: 48.6684336, lng: 18.1039502 },
    { lat: 48.6679037, lng: 18.1044046 },
    { lat: 48.667429, lng: 18.1046424 },
    { lat: 48.6670745, lng: 18.1050887 },
    { lat: 48.6656895, lng: 18.1065102 },
    { lat: 48.6649726, lng: 18.1067959 },
    { lat: 48.6645596, lng: 18.1072411 },
    { lat: 48.663882, lng: 18.1076393 },
    { lat: 48.6630126, lng: 18.1080399 },
    { lat: 48.6631816, lng: 18.1087678 },
    { lat: 48.6635104, lng: 18.1094699 },
    { lat: 48.6630897, lng: 18.1103448 },
    { lat: 48.6627225, lng: 18.1108206 },
    { lat: 48.6625772, lng: 18.1109231 },
    { lat: 48.6630165, lng: 18.1123405 },
    { lat: 48.6617288, lng: 18.1128794 },
    { lat: 48.661357, lng: 18.113007 },
    { lat: 48.6610257, lng: 18.1130357 },
    { lat: 48.661167, lng: 18.1137927 },
    { lat: 48.6608726, lng: 18.1144169 },
    { lat: 48.6606443, lng: 18.1151248 },
    { lat: 48.6606187, lng: 18.1153838 },
    { lat: 48.6604715, lng: 18.1157225 },
    { lat: 48.6604893, lng: 18.1158172 },
    { lat: 48.6598983, lng: 18.117001 },
    { lat: 48.6603933, lng: 18.1178651 },
    { lat: 48.6604694, lng: 18.1181081 },
    { lat: 48.6606014, lng: 18.1185293 },
    { lat: 48.6608841, lng: 18.1196069 },
    { lat: 48.6611106, lng: 18.1200067 },
    { lat: 48.6614503, lng: 18.1200255 },
    { lat: 48.6620057, lng: 18.1198542 },
    { lat: 48.6626335, lng: 18.1198201 },
    { lat: 48.6635958, lng: 18.1189858 },
    { lat: 48.6637867, lng: 18.1186777 },
    { lat: 48.6638032, lng: 18.1184952 },
    { lat: 48.6638864, lng: 18.1184495 },
    { lat: 48.6641431, lng: 18.11852 },
    { lat: 48.6641735, lng: 18.1181497 },
    { lat: 48.6642164, lng: 18.118074 },
    { lat: 48.6645156, lng: 18.1178797 },
    { lat: 48.6646042, lng: 18.1179875 },
    { lat: 48.6647253, lng: 18.117955 },
    { lat: 48.6651307, lng: 18.1183874 },
    { lat: 48.6653764, lng: 18.1183216 },
    { lat: 48.6654857, lng: 18.1183592 },
    { lat: 48.6655089, lng: 18.1184246 },
    { lat: 48.6657255, lng: 18.1184237 },
    { lat: 48.6670488, lng: 18.1196767 },
    { lat: 48.6672007, lng: 18.1197436 },
    { lat: 48.667706, lng: 18.1218526 },
    { lat: 48.6678457, lng: 18.1222161 },
    { lat: 48.6681133, lng: 18.1236522 },
    { lat: 48.6684508, lng: 18.1238648 },
    { lat: 48.6685728, lng: 18.1240993 },
    { lat: 48.6687316, lng: 18.1249294 },
    { lat: 48.669122, lng: 18.1255252 },
    { lat: 48.669411, lng: 18.1265948 },
    { lat: 48.6694385, lng: 18.1272616 },
    { lat: 48.6696045, lng: 18.1280383 },
    { lat: 48.6698812, lng: 18.1289724 },
    { lat: 48.6699034, lng: 18.1294918 },
    { lat: 48.6699994, lng: 18.1297026 },
    { lat: 48.6702751, lng: 18.1298859 },
    { lat: 48.6705517, lng: 18.130217 },
    { lat: 48.6707338, lng: 18.1305913 },
    { lat: 48.6709, lng: 18.131109 },
    { lat: 48.670988, lng: 18.131286 },
    { lat: 48.671193, lng: 18.132418 },
    { lat: 48.671331, lng: 18.132849 },
    { lat: 48.671619, lng: 18.133127 },
    { lat: 48.671795, lng: 18.1334 },
    { lat: 48.672059, lng: 18.133676 },
    { lat: 48.672128, lng: 18.133722 },
    { lat: 48.672194, lng: 18.133735 },
    { lat: 48.67244, lng: 18.13375 },
    { lat: 48.672575, lng: 18.133824 },
    { lat: 48.672745, lng: 18.133952 },
    { lat: 48.673009, lng: 18.134224 },
    { lat: 48.673166, lng: 18.134347 },
    { lat: 48.673277, lng: 18.134381 },
    { lat: 48.673334, lng: 18.134297 },
    { lat: 48.673735, lng: 18.133917 },
    { lat: 48.674013, lng: 18.133416 },
    { lat: 48.674121, lng: 18.133283 },
    { lat: 48.674228, lng: 18.133181 },
    { lat: 48.674602, lng: 18.132967 },
    { lat: 48.674846, lng: 18.132942 },
    { lat: 48.675001, lng: 18.133019 },
    { lat: 48.675082, lng: 18.133109 },
    { lat: 48.675228, lng: 18.133368 },
    { lat: 48.675518, lng: 18.133788 },
    { lat: 48.675669, lng: 18.133895 },
    { lat: 48.675841, lng: 18.133906 },
    { lat: 48.675856, lng: 18.133886 },
    { lat: 48.675919, lng: 18.133833 },
    { lat: 48.675936, lng: 18.133825 },
    { lat: 48.675975, lng: 18.133764 },
    { lat: 48.676009, lng: 18.133704 },
    { lat: 48.676045, lng: 18.133654 },
    { lat: 48.676088, lng: 18.13359 },
    { lat: 48.676111, lng: 18.133527 },
    { lat: 48.676131, lng: 18.133474 },
    { lat: 48.676137, lng: 18.133444 },
    { lat: 48.676153, lng: 18.133404 },
    { lat: 48.67625, lng: 18.133267 },
    { lat: 48.676379, lng: 18.133068 },
    { lat: 48.676407, lng: 18.13302 },
    { lat: 48.676439, lng: 18.132975 },
    { lat: 48.67648, lng: 18.132919 },
    { lat: 48.676507, lng: 18.132888 },
    { lat: 48.676564, lng: 18.132747 },
    { lat: 48.676561, lng: 18.132672 },
    { lat: 48.676631, lng: 18.132498 },
    { lat: 48.676627, lng: 18.132347 },
    { lat: 48.676702, lng: 18.132224 },
    { lat: 48.676771, lng: 18.132095 },
    { lat: 48.676826, lng: 18.131972 },
    { lat: 48.676849, lng: 18.131926 },
    { lat: 48.676866, lng: 18.131876 },
    { lat: 48.676886, lng: 18.131828 },
    { lat: 48.67691, lng: 18.13177 },
    { lat: 48.676924, lng: 18.131742 },
    { lat: 48.676944, lng: 18.131712 },
    { lat: 48.676965, lng: 18.131674 },
    { lat: 48.677008, lng: 18.131594 },
    { lat: 48.677028, lng: 18.131551 },
    { lat: 48.677048, lng: 18.131513 },
    { lat: 48.677181, lng: 18.131311 },
    { lat: 48.677225, lng: 18.131247 },
    { lat: 48.677257, lng: 18.131154 },
    { lat: 48.677292, lng: 18.131061 },
    { lat: 48.677311, lng: 18.131003 },
    { lat: 48.677352, lng: 18.130968 },
    { lat: 48.677398, lng: 18.130927 },
    { lat: 48.677438, lng: 18.130882 },
    { lat: 48.677561, lng: 18.130637 },
    { lat: 48.677614, lng: 18.130541 },
    { lat: 48.677923, lng: 18.129968 },
    { lat: 48.677934, lng: 18.129898 },
    { lat: 48.677946, lng: 18.129822 },
    { lat: 48.67796, lng: 18.129724 },
    { lat: 48.677971, lng: 18.129656 },
    { lat: 48.678032, lng: 18.129568 },
    { lat: 48.678104, lng: 18.129475 },
    { lat: 48.678123, lng: 18.129442 },
    { lat: 48.67814, lng: 18.129359 },
    { lat: 48.678062, lng: 18.129143 },
    { lat: 48.678215, lng: 18.128815 },
    { lat: 48.678268, lng: 18.12876 },
    { lat: 48.678324, lng: 18.128701 },
    { lat: 48.678395, lng: 18.128502 },
    { lat: 48.678436, lng: 18.128356 },
    { lat: 48.678406, lng: 18.128183 },
    { lat: 48.678404, lng: 18.128158 },
    { lat: 48.678519, lng: 18.128079 },
    { lat: 48.678513, lng: 18.127926 },
    { lat: 48.678503, lng: 18.127785 },
    { lat: 48.678554, lng: 18.12773 },
    { lat: 48.678657, lng: 18.127616 },
    { lat: 48.678721, lng: 18.127409 },
    { lat: 48.678773, lng: 18.127258 },
    { lat: 48.678877, lng: 18.126986 },
    { lat: 48.678895, lng: 18.126971 },
    { lat: 48.678949, lng: 18.126882 },
    { lat: 48.679018, lng: 18.126849 },
    { lat: 48.679025, lng: 18.126774 },
    { lat: 48.679008, lng: 18.126718 },
    { lat: 48.679074, lng: 18.126675 },
    { lat: 48.679116, lng: 18.126625 },
    { lat: 48.679184, lng: 18.126549 },
    { lat: 48.679216, lng: 18.126421 },
    { lat: 48.679242, lng: 18.126351 },
    { lat: 48.679324, lng: 18.12623 },
    { lat: 48.67932, lng: 18.126151 },
    { lat: 48.67941, lng: 18.12608 },
    { lat: 48.679429, lng: 18.125969 },
    { lat: 48.679537, lng: 18.125898 },
    { lat: 48.679607, lng: 18.125917 },
    { lat: 48.67969, lng: 18.12582 },
    { lat: 48.679761, lng: 18.125812 },
    { lat: 48.679887, lng: 18.125721 },
    { lat: 48.679924, lng: 18.125592 },
    { lat: 48.679998, lng: 18.125551 },
    { lat: 48.680027, lng: 18.125592 },
    { lat: 48.680271, lng: 18.125396 },
    { lat: 48.680359, lng: 18.125354 },
    { lat: 48.680352, lng: 18.125302 },
    { lat: 48.680436, lng: 18.125341 },
    { lat: 48.680625, lng: 18.125165 },
    { lat: 48.680614, lng: 18.125125 },
    { lat: 48.680641, lng: 18.125049 },
    { lat: 48.680791, lng: 18.125021 },
    { lat: 48.680819, lng: 18.125074 },
    { lat: 48.680844, lng: 18.125076 },
    { lat: 48.680861, lng: 18.125048 },
    { lat: 48.680996, lng: 18.124964 },
    { lat: 48.681442, lng: 18.124579 },
    { lat: 48.681528, lng: 18.124544 },
    { lat: 48.681528, lng: 18.124445 },
    { lat: 48.681644, lng: 18.124299 },
    { lat: 48.681741, lng: 18.124259 },
    { lat: 48.681781, lng: 18.124198 },
    { lat: 48.682158, lng: 18.123911 },
    { lat: 48.682508, lng: 18.123508 },
    { lat: 48.682748, lng: 18.12324 },
    { lat: 48.682826, lng: 18.123131 },
    { lat: 48.683126, lng: 18.12287 },
    { lat: 48.683392, lng: 18.122549 },
    { lat: 48.683388, lng: 18.122465 },
    { lat: 48.683395, lng: 18.122349 },
    { lat: 48.683436, lng: 18.122189 },
    { lat: 48.683736, lng: 18.121372 },
    { lat: 48.684058, lng: 18.120276 },
    { lat: 48.684069, lng: 18.120233 },
  ],
  NitrianskaBlatnica: [
    { lat: 48.582458, lng: 17.946765 },
    { lat: 48.5815392, lng: 17.946004 },
    { lat: 48.580091, lng: 17.9443855 },
    { lat: 48.578763, lng: 17.9429989 },
    { lat: 48.5760057, lng: 17.9401412 },
    { lat: 48.5753471, lng: 17.9395483 },
    { lat: 48.5752826, lng: 17.9391899 },
    { lat: 48.5749285, lng: 17.9385346 },
    { lat: 48.5745852, lng: 17.9384031 },
    { lat: 48.5734584, lng: 17.9384511 },
    { lat: 48.5725811, lng: 17.939015 },
    { lat: 48.5716766, lng: 17.9392711 },
    { lat: 48.5705564, lng: 17.9398482 },
    { lat: 48.5698268, lng: 17.9406703 },
    { lat: 48.5698035, lng: 17.9406001 },
    { lat: 48.5677832, lng: 17.9417504 },
    { lat: 48.5661564, lng: 17.9427463 },
    { lat: 48.5653832, lng: 17.9432909 },
    { lat: 48.5653917, lng: 17.9433379 },
    { lat: 48.5642562, lng: 17.944054 },
    { lat: 48.5632289, lng: 17.944991 },
    { lat: 48.5626828, lng: 17.945695 },
    { lat: 48.5617944, lng: 17.9465588 },
    { lat: 48.560831, lng: 17.9476409 },
    { lat: 48.5601342, lng: 17.9464101 },
    { lat: 48.5594815, lng: 17.9474068 },
    { lat: 48.5593533, lng: 17.9476947 },
    { lat: 48.559264, lng: 17.9477314 },
    { lat: 48.5589002, lng: 17.948252 },
    { lat: 48.557895, lng: 17.9490703 },
    { lat: 48.5576359, lng: 17.9483818 },
    { lat: 48.5570527, lng: 17.948992 },
    { lat: 48.5563105, lng: 17.9496584 },
    { lat: 48.5561761, lng: 17.9498666 },
    { lat: 48.5557981, lng: 17.9502258 },
    { lat: 48.5556103, lng: 17.9505588 },
    { lat: 48.5550249, lng: 17.9513567 },
    { lat: 48.5545407, lng: 17.95247 },
    { lat: 48.554476, lng: 17.9526886 },
    { lat: 48.5544759, lng: 17.9530464 },
    { lat: 48.554915, lng: 17.9529325 },
    { lat: 48.5551595, lng: 17.9533041 },
    { lat: 48.5552451, lng: 17.9532433 },
    { lat: 48.5554263, lng: 17.9535571 },
    { lat: 48.5555666, lng: 17.9534661 },
    { lat: 48.5559397, lng: 17.9538351 },
    { lat: 48.5562691, lng: 17.9536643 },
    { lat: 48.5564816, lng: 17.9534604 },
    { lat: 48.5566684, lng: 17.9538266 },
    { lat: 48.557192, lng: 17.9557665 },
    { lat: 48.5574434, lng: 17.956895 },
    { lat: 48.5578153, lng: 17.9579217 },
    { lat: 48.5582624, lng: 17.9596223 },
    { lat: 48.5583038, lng: 17.9596533 },
    { lat: 48.5582554, lng: 17.9599603 },
    { lat: 48.5581861, lng: 17.9601022 },
    { lat: 48.5579709, lng: 17.9604678 },
    { lat: 48.5576772, lng: 17.9608198 },
    { lat: 48.5572411, lng: 17.9611922 },
    { lat: 48.5567825, lng: 17.9613948 },
    { lat: 48.5566703, lng: 17.9613847 },
    { lat: 48.5566772, lng: 17.9613143 },
    { lat: 48.5562291, lng: 17.9613876 },
    { lat: 48.5546149, lng: 17.9621051 },
    { lat: 48.5544082, lng: 17.9615923 },
    { lat: 48.554296, lng: 17.9608312 },
    { lat: 48.5539311, lng: 17.9596928 },
    { lat: 48.553682, lng: 17.9592456 },
    { lat: 48.5534112, lng: 17.9586048 },
    { lat: 48.5530783, lng: 17.9580414 },
    { lat: 48.5528204, lng: 17.9573885 },
    { lat: 48.5498323, lng: 17.9587785 },
    { lat: 48.5500178, lng: 17.959468 },
    { lat: 48.5502137, lng: 17.9599864 },
    { lat: 48.5502873, lng: 17.9603669 },
    { lat: 48.5509623, lng: 17.9622569 },
    { lat: 48.5499994, lng: 17.9630088 },
    { lat: 48.549188, lng: 17.9641809 },
    { lat: 48.5486441, lng: 17.9648428 },
    { lat: 48.5478916, lng: 17.965585 },
    { lat: 48.5465471, lng: 17.9673016 },
    { lat: 48.5460473, lng: 17.9677046 },
    { lat: 48.5444307, lng: 17.9697013 },
    { lat: 48.544033, lng: 17.9700914 },
    { lat: 48.5430616, lng: 17.970835 },
    { lat: 48.5425598, lng: 17.9713162 },
    { lat: 48.5411398, lng: 17.972451 },
    { lat: 48.5404239, lng: 17.9729544 },
    { lat: 48.5398225, lng: 17.9736371 },
    { lat: 48.5394302, lng: 17.9742327 },
    { lat: 48.5391746, lng: 17.9743969 },
    { lat: 48.5387586, lng: 17.974876 },
    { lat: 48.5382969, lng: 17.9751209 },
    { lat: 48.5368495, lng: 17.9761738 },
    { lat: 48.5357476, lng: 17.9771143 },
    { lat: 48.535186, lng: 17.977461 },
    { lat: 48.5350925, lng: 17.9774629 },
    { lat: 48.5347867, lng: 17.9772579 },
    { lat: 48.5341603, lng: 17.976578 },
    { lat: 48.5334306, lng: 17.9772516 },
    { lat: 48.5333493, lng: 17.9773085 },
    { lat: 48.5352529, lng: 17.9806637 },
    { lat: 48.5347526, lng: 17.9814155 },
    { lat: 48.5352427, lng: 17.9821773 },
    { lat: 48.5299712, lng: 17.9894788 },
    { lat: 48.5290039, lng: 17.990806 },
    { lat: 48.5309592, lng: 17.994488 },
    { lat: 48.5319706, lng: 17.99377 },
    { lat: 48.5341742, lng: 17.9978896 },
    { lat: 48.5347682, lng: 17.997308 },
    { lat: 48.5350248, lng: 17.9969183 },
    { lat: 48.5361905, lng: 17.9990746 },
    { lat: 48.5374173, lng: 18.0019585 },
    { lat: 48.5385439, lng: 18.0044101 },
    { lat: 48.5389387, lng: 18.0041813 },
    { lat: 48.5391863, lng: 18.0036154 },
    { lat: 48.5393332, lng: 18.0038467 },
    { lat: 48.5395507, lng: 18.0037465 },
    { lat: 48.5401801, lng: 18.0032645 },
    { lat: 48.5407658, lng: 18.0029597 },
    { lat: 48.5423134, lng: 18.0025281 },
    { lat: 48.5430962, lng: 18.0021005 },
    { lat: 48.5432777, lng: 18.0025719 },
    { lat: 48.5448606, lng: 18.0015569 },
    { lat: 48.5451888, lng: 18.0012651 },
    { lat: 48.5458112, lng: 18.0005357 },
    { lat: 48.5470261, lng: 17.9987951 },
    { lat: 48.5470887, lng: 17.9987468 },
    { lat: 48.5476219, lng: 17.997428 },
    { lat: 48.5480056, lng: 17.9966111 },
    { lat: 48.5488184, lng: 17.995475 },
    { lat: 48.5488246, lng: 17.9953691 },
    { lat: 48.5484434, lng: 17.9945596 },
    { lat: 48.5485205, lng: 17.9943764 },
    { lat: 48.5491553, lng: 17.9938154 },
    { lat: 48.5501645, lng: 17.9930607 },
    { lat: 48.5501016, lng: 17.9927785 },
    { lat: 48.5502435, lng: 17.9923942 },
    { lat: 48.5500354, lng: 17.9915719 },
    { lat: 48.5510919, lng: 17.9906454 },
    { lat: 48.5516221, lng: 17.9899022 },
    { lat: 48.5512482, lng: 17.9887582 },
    { lat: 48.5510956, lng: 17.9879591 },
    { lat: 48.5511569, lng: 17.9870153 },
    { lat: 48.5513763, lng: 17.986624 },
    { lat: 48.5522673, lng: 17.9854227 },
    { lat: 48.5526436, lng: 17.9849938 },
    { lat: 48.5549399, lng: 17.9826905 },
    { lat: 48.5552804, lng: 17.9822501 },
    { lat: 48.5566419, lng: 17.9808541 },
    { lat: 48.5572564, lng: 17.9804456 },
    { lat: 48.5572978, lng: 17.9802327 },
    { lat: 48.5584006, lng: 17.9793826 },
    { lat: 48.559101, lng: 17.9791256 },
    { lat: 48.5592374, lng: 17.9789885 },
    { lat: 48.5598647, lng: 17.978625 },
    { lat: 48.5616148, lng: 17.9778974 },
    { lat: 48.562147, lng: 17.9775477 },
    { lat: 48.5633227, lng: 17.9770874 },
    { lat: 48.5643827, lng: 17.9764828 },
    { lat: 48.5652588, lng: 17.9761863 },
    { lat: 48.5663159, lng: 17.9756639 },
    { lat: 48.5672167, lng: 17.9749846 },
    { lat: 48.5674653, lng: 17.9748981 },
    { lat: 48.5687582, lng: 17.9740359 },
    { lat: 48.5699118, lng: 17.9731639 },
    { lat: 48.5710566, lng: 17.9718858 },
    { lat: 48.5714646, lng: 17.9715728 },
    { lat: 48.5722888, lng: 17.9712187 },
    { lat: 48.5727151, lng: 17.9713064 },
    { lat: 48.5732455, lng: 17.9712452 },
    { lat: 48.573313, lng: 17.9714328 },
    { lat: 48.5735159, lng: 17.9713885 },
    { lat: 48.5736542, lng: 17.9712097 },
    { lat: 48.57381, lng: 17.9711362 },
    { lat: 48.5743457, lng: 17.9710329 },
    { lat: 48.5752683, lng: 17.971476 },
    { lat: 48.575576, lng: 17.9714885 },
    { lat: 48.5762672, lng: 17.9713064 },
    { lat: 48.5770453, lng: 17.971634 },
    { lat: 48.5782882, lng: 17.9717485 },
    { lat: 48.5784314, lng: 17.9721636 },
    { lat: 48.5785849, lng: 17.972449 },
    { lat: 48.5788176, lng: 17.9734152 },
    { lat: 48.5790086, lng: 17.9739885 },
    { lat: 48.5793835, lng: 17.9745754 },
    { lat: 48.5795187, lng: 17.9749499 },
    { lat: 48.5802078, lng: 17.976173 },
    { lat: 48.5809644, lng: 17.9773306 },
    { lat: 48.5814519, lng: 17.977975 },
    { lat: 48.5820194, lng: 17.978424 },
    { lat: 48.5831754, lng: 17.9789219 },
    { lat: 48.5838574, lng: 17.9794163 },
    { lat: 48.5842975, lng: 17.9795503 },
    { lat: 48.5849393, lng: 17.9795044 },
    { lat: 48.5854365, lng: 17.9798545 },
    { lat: 48.5857531, lng: 17.9799197 },
    { lat: 48.5863153, lng: 17.9797341 },
    { lat: 48.5865249, lng: 17.9795364 },
    { lat: 48.5869561, lng: 17.9785917 },
    { lat: 48.587308, lng: 17.9781141 },
    { lat: 48.5876487, lng: 17.9779374 },
    { lat: 48.5880528, lng: 17.977564 },
    { lat: 48.588209, lng: 17.9773228 },
    { lat: 48.5888148, lng: 17.9755394 },
    { lat: 48.5888639, lng: 17.9751135 },
    { lat: 48.5895405, lng: 17.9738699 },
    { lat: 48.5897599, lng: 17.9739924 },
    { lat: 48.5904081, lng: 17.9740172 },
    { lat: 48.590909, lng: 17.9743539 },
    { lat: 48.591039, lng: 17.974385 },
    { lat: 48.591039, lng: 17.974355 },
    { lat: 48.591071, lng: 17.971713 },
    { lat: 48.591004, lng: 17.969616 },
    { lat: 48.591082, lng: 17.967602 },
    { lat: 48.590933, lng: 17.964946 },
    { lat: 48.590999, lng: 17.963596 },
    { lat: 48.590873, lng: 17.960634 },
    { lat: 48.590832, lng: 17.958366 },
    { lat: 48.590901, lng: 17.956144 },
    { lat: 48.590793, lng: 17.953612 },
    { lat: 48.590747, lng: 17.952563 },
    { lat: 48.590557, lng: 17.949832 },
    { lat: 48.590221, lng: 17.949259 },
    { lat: 48.589874, lng: 17.949175 },
    { lat: 48.589179, lng: 17.949073 },
    { lat: 48.589036, lng: 17.949316 },
    { lat: 48.588725, lng: 17.949598 },
    { lat: 48.588524, lng: 17.949877 },
    { lat: 48.588177, lng: 17.950407 },
    { lat: 48.588096, lng: 17.950555 },
    { lat: 48.587915, lng: 17.950817 },
    { lat: 48.58788, lng: 17.950917 },
    { lat: 48.587787, lng: 17.951007 },
    { lat: 48.587502, lng: 17.951076 },
    { lat: 48.587412, lng: 17.951082 },
    { lat: 48.587183, lng: 17.951133 },
    { lat: 48.587093, lng: 17.951181 },
    { lat: 48.586833, lng: 17.951291 },
    { lat: 48.586732, lng: 17.951331 },
    { lat: 48.586543, lng: 17.951447 },
    { lat: 48.586248, lng: 17.951683 },
    { lat: 48.58585, lng: 17.952051 },
    { lat: 48.585788, lng: 17.952176 },
    { lat: 48.585708, lng: 17.952308 },
    { lat: 48.585502, lng: 17.952442 },
    { lat: 48.585437, lng: 17.952508 },
    { lat: 48.585321, lng: 17.952693 },
    { lat: 48.585205, lng: 17.952814 },
    { lat: 48.585111, lng: 17.952827 },
    { lat: 48.584914, lng: 17.952608 },
    { lat: 48.584805, lng: 17.952557 },
    { lat: 48.584681, lng: 17.952571 },
    { lat: 48.584587, lng: 17.952674 },
    { lat: 48.584548, lng: 17.95268 },
    { lat: 48.584395, lng: 17.952595 },
    { lat: 48.584271, lng: 17.952598 },
    { lat: 48.584184, lng: 17.952553 },
    { lat: 48.583963, lng: 17.952569 },
    { lat: 48.583792, lng: 17.952679 },
    { lat: 48.583543, lng: 17.952785 },
    { lat: 48.583025, lng: 17.952855 },
    { lat: 48.583024, lng: 17.952826 },
    { lat: 48.58306, lng: 17.949153 },
    { lat: 48.582615, lng: 17.947136 },
    { lat: 48.582458, lng: 17.946765 },
  ],
  Tovarníky: [
    { lat: 48.5664497, lng: 18.1268915 },
    { lat: 48.5621053, lng: 18.1302493 },
    { lat: 48.5615503, lng: 18.1289354 },
    { lat: 48.5588065, lng: 18.1310777 },
    { lat: 48.559329, lng: 18.1324707 },
    { lat: 48.5582642, lng: 18.1333319 },
    { lat: 48.5583111, lng: 18.133496 },
    { lat: 48.558812, lng: 18.1348316 },
    { lat: 48.5593801, lng: 18.1358633 },
    { lat: 48.5605966, lng: 18.1371799 },
    { lat: 48.5609294, lng: 18.1375477 },
    { lat: 48.5617727, lng: 18.1384812 },
    { lat: 48.5621199, lng: 18.1389619 },
    { lat: 48.5624245, lng: 18.140103 },
    { lat: 48.5622928, lng: 18.1401842 },
    { lat: 48.5625608, lng: 18.1413384 },
    { lat: 48.5630952, lng: 18.1426312 },
    { lat: 48.5661788, lng: 18.1494685 },
    { lat: 48.5663737, lng: 18.1502983 },
    { lat: 48.566377, lng: 18.1506841 },
    { lat: 48.5663146, lng: 18.1512221 },
    { lat: 48.5671055, lng: 18.1525394 },
    { lat: 48.5692148, lng: 18.1566628 },
    { lat: 48.5699661, lng: 18.157705 },
    { lat: 48.5741432, lng: 18.1623838 },
    { lat: 48.5736625, lng: 18.1633525 },
    { lat: 48.573432, lng: 18.163817 },
    { lat: 48.5765298, lng: 18.167851 },
    { lat: 48.5776071, lng: 18.1695751 },
    { lat: 48.5791798, lng: 18.1677185 },
    { lat: 48.5799714, lng: 18.1672422 },
    { lat: 48.5808815, lng: 18.1664912 },
    { lat: 48.5826134, lng: 18.1652028 },
    { lat: 48.5828168, lng: 18.1658314 },
    { lat: 48.5832553, lng: 18.1673205 },
    { lat: 48.5832907, lng: 18.1672877 },
    { lat: 48.5871244, lng: 18.1643555 },
    { lat: 48.5857366, lng: 18.1603118 },
    { lat: 48.5858735, lng: 18.16018 },
    { lat: 48.5823325, lng: 18.1495146 },
    { lat: 48.5800986, lng: 18.1416637 },
    { lat: 48.5797941, lng: 18.1401939 },
    { lat: 48.5797136, lng: 18.1399558 },
    { lat: 48.5790696, lng: 18.1388954 },
    { lat: 48.5787307, lng: 18.1378821 },
    { lat: 48.5790794, lng: 18.1375719 },
    { lat: 48.576491, lng: 18.1285511 },
    { lat: 48.5764129, lng: 18.1282743 },
    { lat: 48.574542, lng: 18.1216451 },
    { lat: 48.5732777, lng: 18.1223334 },
    { lat: 48.5704975, lng: 18.1241425 },
    { lat: 48.5678971, lng: 18.1260135 },
    { lat: 48.5667678, lng: 18.1266983 },
    { lat: 48.5664497, lng: 18.1268915 },
  ],
};

export default UnitsTopoľčany;
