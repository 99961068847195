const UnitsNovéMestonadVáhom = {
  ZemianskePodhradie: [
    { lat: 48.8683668, lng: 17.8148543 },
    { lat: 48.868851, lng: 17.8145361 },
    { lat: 48.8690272, lng: 17.8143724 },
    { lat: 48.8693194, lng: 17.8138461 },
    { lat: 48.8697347, lng: 17.8133616 },
    { lat: 48.8700129, lng: 17.8128873 },
    { lat: 48.8703375, lng: 17.8122092 },
    { lat: 48.8707567, lng: 17.8117139 },
    { lat: 48.8713314, lng: 17.8110822 },
    { lat: 48.8716396, lng: 17.8105635 },
    { lat: 48.8722728, lng: 17.8098 },
    { lat: 48.8723987, lng: 17.8095497 },
    { lat: 48.872369, lng: 17.809227 },
    { lat: 48.8725299, lng: 17.8087724 },
    { lat: 48.8724703, lng: 17.8083674 },
    { lat: 48.8724006, lng: 17.808001 },
    { lat: 48.8723354, lng: 17.8077077 },
    { lat: 48.8722826, lng: 17.8064119 },
    { lat: 48.8722969, lng: 17.8058309 },
    { lat: 48.8722688, lng: 17.8053113 },
    { lat: 48.8722736, lng: 17.8049452 },
    { lat: 48.8722299, lng: 17.8046719 },
    { lat: 48.8722289, lng: 17.8040449 },
    { lat: 48.8722552, lng: 17.8036958 },
    { lat: 48.8722129, lng: 17.8028714 },
    { lat: 48.8721892, lng: 17.8028537 },
    { lat: 48.872005, lng: 17.8029989 },
    { lat: 48.8719573, lng: 17.8030307 },
    { lat: 48.8716895, lng: 17.8032307 },
    { lat: 48.871223, lng: 17.8035787 },
    { lat: 48.8710903, lng: 17.8036799 },
    { lat: 48.8710611, lng: 17.8037006 },
    { lat: 48.8708963, lng: 17.8038119 },
    { lat: 48.8706074, lng: 17.8040181 },
    { lat: 48.8703497, lng: 17.8032524 },
    { lat: 48.870299, lng: 17.803105 },
    { lat: 48.8703251, lng: 17.8030707 },
    { lat: 48.8701691, lng: 17.8027882 },
    { lat: 48.8700913, lng: 17.8026596 },
    { lat: 48.8698614, lng: 17.8022763 },
    { lat: 48.870033, lng: 17.8020726 },
    { lat: 48.8699733, lng: 17.8019275 },
    { lat: 48.8697794, lng: 17.8021163 },
    { lat: 48.8697073, lng: 17.8020462 },
    { lat: 48.8693336, lng: 17.8024908 },
    { lat: 48.8691644, lng: 17.8026582 },
    { lat: 48.869292, lng: 17.8023447 },
    { lat: 48.8696956, lng: 17.8019441 },
    { lat: 48.8699857, lng: 17.8015543 },
    { lat: 48.8701224, lng: 17.8015387 },
    { lat: 48.8703564, lng: 17.8014872 },
    { lat: 48.8706409, lng: 17.8014311 },
    { lat: 48.8708463, lng: 17.8013934 },
    { lat: 48.8709325, lng: 17.8013817 },
    { lat: 48.8708113, lng: 17.80119 },
    { lat: 48.8707026, lng: 17.8010182 },
    { lat: 48.8706075, lng: 17.8008647 },
    { lat: 48.8704776, lng: 17.8006598 },
    { lat: 48.8704052, lng: 17.800546 },
    { lat: 48.8703751, lng: 17.8004957 },
    { lat: 48.8702301, lng: 17.800265 },
    { lat: 48.8700484, lng: 17.7999685 },
    { lat: 48.8693865, lng: 17.7988872 },
    { lat: 48.8691971, lng: 17.7990387 },
    { lat: 48.8690155, lng: 17.7991906 },
    { lat: 48.8689135, lng: 17.7992765 },
    { lat: 48.8685126, lng: 17.7996091 },
    { lat: 48.8683473, lng: 17.7997501 },
    { lat: 48.868069, lng: 17.7999069 },
    { lat: 48.8680166, lng: 17.7999361 },
    { lat: 48.8677712, lng: 17.7999289 },
    { lat: 48.8672104, lng: 17.8000254 },
    { lat: 48.8670606, lng: 17.7999393 },
    { lat: 48.8669816, lng: 17.7997527 },
    { lat: 48.866151, lng: 17.8004069 },
    { lat: 48.866174, lng: 17.8006176 },
    { lat: 48.8658011, lng: 17.8007968 },
    { lat: 48.8654319, lng: 17.8010376 },
    { lat: 48.865384, lng: 17.8010304 },
    { lat: 48.8651193, lng: 17.8009862 },
    { lat: 48.864996, lng: 17.8010372 },
    { lat: 48.8649087, lng: 17.8010687 },
    { lat: 48.8647384, lng: 17.8011074 },
    { lat: 48.8647275, lng: 17.8011129 },
    { lat: 48.8643301, lng: 17.8013545 },
    { lat: 48.8640183, lng: 17.8017583 },
    { lat: 48.8640383, lng: 17.8020869 },
    { lat: 48.8637768, lng: 17.8022612 },
    { lat: 48.8631053, lng: 17.8026169 },
    { lat: 48.8625235, lng: 17.8026652 },
    { lat: 48.8622557, lng: 17.8025696 },
    { lat: 48.8621679, lng: 17.8025563 },
    { lat: 48.8619231, lng: 17.8025207 },
    { lat: 48.8616689, lng: 17.8024066 },
    { lat: 48.8613392, lng: 17.8022554 },
    { lat: 48.8612705, lng: 17.8022209 },
    { lat: 48.8609744, lng: 17.802087 },
    { lat: 48.8608413, lng: 17.801997 },
    { lat: 48.8607848, lng: 17.8019581 },
    { lat: 48.8606272, lng: 17.8018535 },
    { lat: 48.8602727, lng: 17.8015565 },
    { lat: 48.8598459, lng: 17.8022044 },
    { lat: 48.8594126, lng: 17.803025 },
    { lat: 48.85903, lng: 17.803564 },
    { lat: 48.858907, lng: 17.8032865 },
    { lat: 48.8588406, lng: 17.8031418 },
    { lat: 48.8585456, lng: 17.8027103 },
    { lat: 48.8583429, lng: 17.8022339 },
    { lat: 48.8582807, lng: 17.8020848 },
    { lat: 48.8581129, lng: 17.8018682 },
    { lat: 48.8580702, lng: 17.8019093 },
    { lat: 48.8580459, lng: 17.8019362 },
    { lat: 48.8573349, lng: 17.8026446 },
    { lat: 48.8569367, lng: 17.802983 },
    { lat: 48.8567582, lng: 17.8030742 },
    { lat: 48.8558356, lng: 17.8027365 },
    { lat: 48.8558092, lng: 17.8027646 },
    { lat: 48.8554076, lng: 17.803067 },
    { lat: 48.8550085, lng: 17.8031882 },
    { lat: 48.8549631, lng: 17.8031078 },
    { lat: 48.8543794, lng: 17.8034229 },
    { lat: 48.8538531, lng: 17.8037186 },
    { lat: 48.8536518, lng: 17.8022076 },
    { lat: 48.85345, lng: 17.8009318 },
    { lat: 48.8525252, lng: 17.8013378 },
    { lat: 48.8519529, lng: 17.8016157 },
    { lat: 48.8512742, lng: 17.8017431 },
    { lat: 48.8507188, lng: 17.8016868 },
    { lat: 48.8502892, lng: 17.8014923 },
    { lat: 48.8502288, lng: 17.801464 },
    { lat: 48.8501639, lng: 17.8014346 },
    { lat: 48.8501279, lng: 17.8014183 },
    { lat: 48.850091, lng: 17.8017023 },
    { lat: 48.8495956, lng: 17.8017629 },
    { lat: 48.8486797, lng: 17.801869 },
    { lat: 48.848538, lng: 17.8019868 },
    { lat: 48.8478874, lng: 17.8020383 },
    { lat: 48.8475696, lng: 17.802066 },
    { lat: 48.8468322, lng: 17.8023597 },
    { lat: 48.8467528, lng: 17.8023696 },
    { lat: 48.84629, lng: 17.8024274 },
    { lat: 48.8459861, lng: 17.8027765 },
    { lat: 48.8458699, lng: 17.8031481 },
    { lat: 48.8454629, lng: 17.8041229 },
    { lat: 48.8447063, lng: 17.805641 },
    { lat: 48.844691, lng: 17.8056666 },
    { lat: 48.8443945, lng: 17.8056559 },
    { lat: 48.8441232, lng: 17.8055945 },
    { lat: 48.8437442, lng: 17.8055623 },
    { lat: 48.84344, lng: 17.8054427 },
    { lat: 48.8429119, lng: 17.8052774 },
    { lat: 48.8428674, lng: 17.8052709 },
    { lat: 48.8421871, lng: 17.8052218 },
    { lat: 48.8418664, lng: 17.8050156 },
    { lat: 48.8414496, lng: 17.8047159 },
    { lat: 48.8409282, lng: 17.8049973 },
    { lat: 48.8405857, lng: 17.805657 },
    { lat: 48.8404486, lng: 17.8060566 },
    { lat: 48.8400659, lng: 17.8065403 },
    { lat: 48.8400189, lng: 17.8066024 },
    { lat: 48.8397637, lng: 17.8069921 },
    { lat: 48.8393902, lng: 17.8076248 },
    { lat: 48.8391001, lng: 17.8079043 },
    { lat: 48.8388048, lng: 17.8086356 },
    { lat: 48.8384269, lng: 17.8086947 },
    { lat: 48.8381699, lng: 17.8088512 },
    { lat: 48.8381228, lng: 17.808968 },
    { lat: 48.8380388, lng: 17.8091451 },
    { lat: 48.8380177, lng: 17.8091892 },
    { lat: 48.8379378, lng: 17.809768 },
    { lat: 48.8379797, lng: 17.8103822 },
    { lat: 48.8382448, lng: 17.8109745 },
    { lat: 48.8382865, lng: 17.8110645 },
    { lat: 48.8382584, lng: 17.8111983 },
    { lat: 48.8382099, lng: 17.8114018 },
    { lat: 48.8381542, lng: 17.8114711 },
    { lat: 48.837461, lng: 17.8123438 },
    { lat: 48.8373582, lng: 17.8124714 },
    { lat: 48.8374009, lng: 17.8127091 },
    { lat: 48.8374188, lng: 17.8128007 },
    { lat: 48.8374087, lng: 17.8129137 },
    { lat: 48.8374001, lng: 17.8130133 },
    { lat: 48.8373913, lng: 17.8131102 },
    { lat: 48.8373705, lng: 17.8133243 },
    { lat: 48.8373732, lng: 17.8133693 },
    { lat: 48.837388, lng: 17.813502 },
    { lat: 48.8374156, lng: 17.8139192 },
    { lat: 48.8374171, lng: 17.8139425 },
    { lat: 48.8370984, lng: 17.8140292 },
    { lat: 48.8370277, lng: 17.8148736 },
    { lat: 48.8369113, lng: 17.8154657 },
    { lat: 48.8370147, lng: 17.8158853 },
    { lat: 48.8371859, lng: 17.816586 },
    { lat: 48.8374724, lng: 17.817659 },
    { lat: 48.8375228, lng: 17.8182843 },
    { lat: 48.8375312, lng: 17.8187013 },
    { lat: 48.8374836, lng: 17.81922 },
    { lat: 48.8374574, lng: 17.8196799 },
    { lat: 48.8374809, lng: 17.8203107 },
    { lat: 48.8374242, lng: 17.8211783 },
    { lat: 48.8373827, lng: 17.8213704 },
    { lat: 48.8373767, lng: 17.8214017 },
    { lat: 48.837319, lng: 17.8218635 },
    { lat: 48.8372719, lng: 17.822185 },
    { lat: 48.837265, lng: 17.822277 },
    { lat: 48.8373633, lng: 17.8225383 },
    { lat: 48.8373847, lng: 17.8225934 },
    { lat: 48.8373565, lng: 17.8230779 },
    { lat: 48.8373256, lng: 17.8236475 },
    { lat: 48.8372596, lng: 17.8238613 },
    { lat: 48.8372115, lng: 17.8240366 },
    { lat: 48.8371838, lng: 17.8241195 },
    { lat: 48.837245, lng: 17.8249601 },
    { lat: 48.8374606, lng: 17.8251275 },
    { lat: 48.8374559, lng: 17.8251803 },
    { lat: 48.8374437, lng: 17.8252779 },
    { lat: 48.837612, lng: 17.8254288 },
    { lat: 48.8375273, lng: 17.8256701 },
    { lat: 48.8374129, lng: 17.8259987 },
    { lat: 48.8370547, lng: 17.826998 },
    { lat: 48.8374095, lng: 17.8276189 },
    { lat: 48.8372227, lng: 17.8279264 },
    { lat: 48.8368849, lng: 17.8284796 },
    { lat: 48.8369771, lng: 17.8287142 },
    { lat: 48.8369819, lng: 17.8287576 },
    { lat: 48.836987, lng: 17.8288031 },
    { lat: 48.837019, lng: 17.829147 },
    { lat: 48.8370288, lng: 17.8292517 },
    { lat: 48.8370331, lng: 17.8293036 },
    { lat: 48.8370485, lng: 17.8294657 },
    { lat: 48.8368399, lng: 17.8298606 },
    { lat: 48.8368067, lng: 17.8299068 },
    { lat: 48.8365658, lng: 17.8302725 },
    { lat: 48.8366367, lng: 17.8305119 },
    { lat: 48.8366893, lng: 17.83069 },
    { lat: 48.8367048, lng: 17.8307414 },
    { lat: 48.8367203, lng: 17.830793 },
    { lat: 48.8367963, lng: 17.8310399 },
    { lat: 48.8368184, lng: 17.8310308 },
    { lat: 48.8368448, lng: 17.8311335 },
    { lat: 48.8369123, lng: 17.83139 },
    { lat: 48.8369679, lng: 17.8315991 },
    { lat: 48.8370276, lng: 17.8318212 },
    { lat: 48.8370844, lng: 17.8320305 },
    { lat: 48.837144, lng: 17.8322501 },
    { lat: 48.837201, lng: 17.8324658 },
    { lat: 48.8372578, lng: 17.8326768 },
    { lat: 48.8373148, lng: 17.8328886 },
    { lat: 48.8373726, lng: 17.8330986 },
    { lat: 48.837412, lng: 17.8332454 },
    { lat: 48.8374409, lng: 17.83335 },
    { lat: 48.8374673, lng: 17.8334493 },
    { lat: 48.8375054, lng: 17.833589 },
    { lat: 48.8375935, lng: 17.8339161 },
    { lat: 48.8376762, lng: 17.8342256 },
    { lat: 48.837715, lng: 17.8343794 },
    { lat: 48.8379022, lng: 17.8350597 },
    { lat: 48.8379093, lng: 17.8350825 },
    { lat: 48.8379365, lng: 17.8351759 },
    { lat: 48.8379457, lng: 17.835216 },
    { lat: 48.8379522, lng: 17.8352502 },
    { lat: 48.837962, lng: 17.8352997 },
    { lat: 48.8379747, lng: 17.8353603 },
    { lat: 48.8380418, lng: 17.8356857 },
    { lat: 48.83806, lng: 17.8357815 },
    { lat: 48.8381728, lng: 17.835854 },
    { lat: 48.838316, lng: 17.8358705 },
    { lat: 48.8384066, lng: 17.8359489 },
    { lat: 48.8385474, lng: 17.8361857 },
    { lat: 48.8386717, lng: 17.8363387 },
    { lat: 48.8387424, lng: 17.836537 },
    { lat: 48.8388475, lng: 17.836743 },
    { lat: 48.838935, lng: 17.8369202 },
    { lat: 48.838944, lng: 17.8369375 },
    { lat: 48.8389606, lng: 17.8369703 },
    { lat: 48.8389652, lng: 17.8369888 },
    { lat: 48.8389891, lng: 17.8370872 },
    { lat: 48.8390157, lng: 17.8372079 },
    { lat: 48.839048, lng: 17.8373505 },
    { lat: 48.839094, lng: 17.8375511 },
    { lat: 48.8391639, lng: 17.8378856 },
    { lat: 48.8391765, lng: 17.8379428 },
    { lat: 48.8391933, lng: 17.8380153 },
    { lat: 48.839217, lng: 17.8381111 },
    { lat: 48.8392555, lng: 17.8382622 },
    { lat: 48.8392648, lng: 17.8383019 },
    { lat: 48.8392763, lng: 17.8383432 },
    { lat: 48.8392878, lng: 17.8383856 },
    { lat: 48.8393203, lng: 17.8385116 },
    { lat: 48.8394593, lng: 17.8390732 },
    { lat: 48.8395609, lng: 17.8394628 },
    { lat: 48.8399627, lng: 17.8392966 },
    { lat: 48.8400078, lng: 17.8392754 },
    { lat: 48.8404009, lng: 17.8390746 },
    { lat: 48.8404297, lng: 17.8390666 },
    { lat: 48.8405539, lng: 17.8398502 },
    { lat: 48.8405581, lng: 17.8403104 },
    { lat: 48.8406078, lng: 17.8406469 },
    { lat: 48.8407273, lng: 17.8409044 },
    { lat: 48.8407446, lng: 17.8408913 },
    { lat: 48.8418703, lng: 17.8405421 },
    { lat: 48.8421697, lng: 17.8404718 },
    { lat: 48.8425455, lng: 17.8403017 },
    { lat: 48.8428234, lng: 17.8401934 },
    { lat: 48.8430969, lng: 17.840161 },
    { lat: 48.84349, lng: 17.8401675 },
    { lat: 48.8437977, lng: 17.8401201 },
    { lat: 48.8442108, lng: 17.8399528 },
    { lat: 48.8444074, lng: 17.8398429 },
    { lat: 48.8444963, lng: 17.8398558 },
    { lat: 48.844597, lng: 17.8400099 },
    { lat: 48.8446637, lng: 17.8401044 },
    { lat: 48.8447488, lng: 17.840149 },
    { lat: 48.844899, lng: 17.8402244 },
    { lat: 48.8450243, lng: 17.8404478 },
    { lat: 48.8453089, lng: 17.8405053 },
    { lat: 48.8455529, lng: 17.8404534 },
    { lat: 48.8457895, lng: 17.840651 },
    { lat: 48.8460983, lng: 17.8409031 },
    { lat: 48.8461256, lng: 17.8409255 },
    { lat: 48.8462219, lng: 17.8405021 },
    { lat: 48.8463068, lng: 17.8401695 },
    { lat: 48.8463522, lng: 17.8399879 },
    { lat: 48.8464684, lng: 17.8396336 },
    { lat: 48.8466752, lng: 17.8390959 },
    { lat: 48.846897, lng: 17.8386011 },
    { lat: 48.8470125, lng: 17.8384 },
    { lat: 48.8468862, lng: 17.8382537 },
    { lat: 48.8475254, lng: 17.8372326 },
    { lat: 48.84777, lng: 17.837115 },
    { lat: 48.8481243, lng: 17.8370383 },
    { lat: 48.8482622, lng: 17.8370969 },
    { lat: 48.8487559, lng: 17.8377181 },
    { lat: 48.8489069, lng: 17.837863 },
    { lat: 48.8491036, lng: 17.8380542 },
    { lat: 48.8495847, lng: 17.8382443 },
    { lat: 48.8496299, lng: 17.8382651 },
    { lat: 48.8497119, lng: 17.8382947 },
    { lat: 48.8499785, lng: 17.8384839 },
    { lat: 48.8503181, lng: 17.8385555 },
    { lat: 48.8505505, lng: 17.8386651 },
    { lat: 48.8508133, lng: 17.838606 },
    { lat: 48.8510862, lng: 17.8388986 },
    { lat: 48.8514328, lng: 17.8392739 },
    { lat: 48.8515598, lng: 17.8394687 },
    { lat: 48.8516369, lng: 17.8396621 },
    { lat: 48.8517636, lng: 17.8402616 },
    { lat: 48.851807, lng: 17.8408118 },
    { lat: 48.8520863, lng: 17.8411168 },
    { lat: 48.8521044, lng: 17.8411361 },
    { lat: 48.8524961, lng: 17.8414923 },
    { lat: 48.8527265, lng: 17.841831 },
    { lat: 48.8530047, lng: 17.8420814 },
    { lat: 48.8529445, lng: 17.8423554 },
    { lat: 48.8528779, lng: 17.8425602 },
    { lat: 48.8530819, lng: 17.8426849 },
    { lat: 48.8532018, lng: 17.8429299 },
    { lat: 48.8535533, lng: 17.841948 },
    { lat: 48.8538101, lng: 17.8413799 },
    { lat: 48.8538982, lng: 17.841437 },
    { lat: 48.8540374, lng: 17.8415159 },
    { lat: 48.8540819, lng: 17.8415445 },
    { lat: 48.8540975, lng: 17.8415213 },
    { lat: 48.8542583, lng: 17.8412877 },
    { lat: 48.8543068, lng: 17.8412501 },
    { lat: 48.8545063, lng: 17.8410761 },
    { lat: 48.8549903, lng: 17.8405133 },
    { lat: 48.855308, lng: 17.8403155 },
    { lat: 48.8554296, lng: 17.8400857 },
    { lat: 48.8555744, lng: 17.8396614 },
    { lat: 48.8556316, lng: 17.8394887 },
    { lat: 48.8557792, lng: 17.839147 },
    { lat: 48.8559264, lng: 17.8388932 },
    { lat: 48.8560292, lng: 17.838559 },
    { lat: 48.8561752, lng: 17.8382107 },
    { lat: 48.856271, lng: 17.8378722 },
    { lat: 48.8563476, lng: 17.8376079 },
    { lat: 48.8563891, lng: 17.8374555 },
    { lat: 48.8567122, lng: 17.8371219 },
    { lat: 48.8568432, lng: 17.8373291 },
    { lat: 48.8570998, lng: 17.8368495 },
    { lat: 48.8572922, lng: 17.8364852 },
    { lat: 48.8578259, lng: 17.8360147 },
    { lat: 48.8578432, lng: 17.8360019 },
    { lat: 48.8583525, lng: 17.8358203 },
    { lat: 48.8585285, lng: 17.8358562 },
    { lat: 48.8586552, lng: 17.8354305 },
    { lat: 48.8585365, lng: 17.8349997 },
    { lat: 48.8583367, lng: 17.8342725 },
    { lat: 48.8585142, lng: 17.8339592 },
    { lat: 48.8585285, lng: 17.8339329 },
    { lat: 48.8590953, lng: 17.8328188 },
    { lat: 48.8591758, lng: 17.8326555 },
    { lat: 48.8590102, lng: 17.8323261 },
    { lat: 48.8590551, lng: 17.8319126 },
    { lat: 48.8589977, lng: 17.8315416 },
    { lat: 48.8587435, lng: 17.8309978 },
    { lat: 48.8591229, lng: 17.8306466 },
    { lat: 48.8592704, lng: 17.8305097 },
    { lat: 48.8597243, lng: 17.8301201 },
    { lat: 48.8598693, lng: 17.8299042 },
    { lat: 48.8599861, lng: 17.829727 },
    { lat: 48.8602713, lng: 17.8291981 },
    { lat: 48.8606569, lng: 17.8287445 },
    { lat: 48.8610433, lng: 17.8281717 },
    { lat: 48.8613323, lng: 17.8278176 },
    { lat: 48.8617654, lng: 17.8272713 },
    { lat: 48.862113, lng: 17.8268406 },
    { lat: 48.8621803, lng: 17.8267414 },
    { lat: 48.862359, lng: 17.826506 },
    { lat: 48.8623814, lng: 17.8264834 },
    { lat: 48.8625644, lng: 17.8262799 },
    { lat: 48.8625876, lng: 17.8262539 },
    { lat: 48.8626224, lng: 17.8262132 },
    { lat: 48.8628588, lng: 17.8257547 },
    { lat: 48.8631453, lng: 17.8249495 },
    { lat: 48.8634359, lng: 17.8241758 },
    { lat: 48.8637577, lng: 17.8234316 },
    { lat: 48.8640248, lng: 17.8227558 },
    { lat: 48.8642246, lng: 17.8224392 },
    { lat: 48.8642502, lng: 17.8223967 },
    { lat: 48.8642699, lng: 17.8223662 },
    { lat: 48.864668, lng: 17.821729 },
    { lat: 48.8651454, lng: 17.8209446 },
    { lat: 48.8655751, lng: 17.8202851 },
    { lat: 48.8656095, lng: 17.8200911 },
    { lat: 48.8655858, lng: 17.8200722 },
    { lat: 48.8658623, lng: 17.8196059 },
    { lat: 48.8659195, lng: 17.8195056 },
    { lat: 48.8662163, lng: 17.8192451 },
    { lat: 48.8664507, lng: 17.8188094 },
    { lat: 48.8667039, lng: 17.8184435 },
    { lat: 48.8667617, lng: 17.8181667 },
    { lat: 48.8667779, lng: 17.818099 },
    { lat: 48.8667774, lng: 17.8172543 },
    { lat: 48.8668413, lng: 17.816801 },
    { lat: 48.8669507, lng: 17.8163907 },
    { lat: 48.8672953, lng: 17.8156698 },
    { lat: 48.8674581, lng: 17.8154511 },
    { lat: 48.8679344, lng: 17.8150763 },
    { lat: 48.8682054, lng: 17.8149491 },
    { lat: 48.8683668, lng: 17.8148543 },
  ],
  Považany: [
    { lat: 48.7224746, lng: 17.837923 },
    { lat: 48.7224405, lng: 17.837674 },
    { lat: 48.7222784, lng: 17.836268 },
    { lat: 48.7219655, lng: 17.8334758 },
    { lat: 48.7218338, lng: 17.8324776 },
    { lat: 48.7217662, lng: 17.8319235 },
    { lat: 48.7217527, lng: 17.8317565 },
    { lat: 48.7216331, lng: 17.8308653 },
    { lat: 48.7215813, lng: 17.8304022 },
    { lat: 48.72143, lng: 17.8292693 },
    { lat: 48.7213956, lng: 17.8292974 },
    { lat: 48.7204225, lng: 17.8296061 },
    { lat: 48.7197053, lng: 17.8297961 },
    { lat: 48.7195158, lng: 17.8298381 },
    { lat: 48.7188651, lng: 17.8299524 },
    { lat: 48.7183532, lng: 17.8300268 },
    { lat: 48.7166478, lng: 17.8302096 },
    { lat: 48.7161452, lng: 17.8302674 },
    { lat: 48.7160993, lng: 17.830275 },
    { lat: 48.7157068, lng: 17.8303424 },
    { lat: 48.7154974, lng: 17.8303753 },
    { lat: 48.7140078, lng: 17.8305004 },
    { lat: 48.7138985, lng: 17.8305228 },
    { lat: 48.7133838, lng: 17.830514 },
    { lat: 48.7131903, lng: 17.8304905 },
    { lat: 48.7130933, lng: 17.8304194 },
    { lat: 48.7130308, lng: 17.8303201 },
    { lat: 48.7129319, lng: 17.8300696 },
    { lat: 48.7128745, lng: 17.8298317 },
    { lat: 48.7127617, lng: 17.8286983 },
    { lat: 48.712672, lng: 17.8279863 },
    { lat: 48.7125648, lng: 17.8273383 },
    { lat: 48.7124097, lng: 17.8267753 },
    { lat: 48.7119169, lng: 17.8255633 },
    { lat: 48.7118452, lng: 17.8252578 },
    { lat: 48.7118282, lng: 17.824998 },
    { lat: 48.7118216, lng: 17.8245555 },
    { lat: 48.7118449, lng: 17.8241578 },
    { lat: 48.7120202, lng: 17.8227862 },
    { lat: 48.7119835, lng: 17.8227768 },
    { lat: 48.7082776, lng: 17.8233035 },
    { lat: 48.7081417, lng: 17.8233181 },
    { lat: 48.7072424, lng: 17.8234135 },
    { lat: 48.7070965, lng: 17.8234291 },
    { lat: 48.7070347, lng: 17.8242883 },
    { lat: 48.7067989, lng: 17.8246476 },
    { lat: 48.7066905, lng: 17.8249841 },
    { lat: 48.7065996, lng: 17.8253839 },
    { lat: 48.7063248, lng: 17.8259379 },
    { lat: 48.7060328, lng: 17.8265804 },
    { lat: 48.7057863, lng: 17.8273405 },
    { lat: 48.7057083, lng: 17.8275075 },
    { lat: 48.7055638, lng: 17.8277679 },
    { lat: 48.7054497, lng: 17.8280107 },
    { lat: 48.7053104, lng: 17.8283392 },
    { lat: 48.7052817, lng: 17.8284826 },
    { lat: 48.705237, lng: 17.8290655 },
    { lat: 48.7052161, lng: 17.8294828 },
    { lat: 48.7051575, lng: 17.8309749 },
    { lat: 48.70434, lng: 17.8308903 },
    { lat: 48.7039488, lng: 17.8307205 },
    { lat: 48.7035841, lng: 17.8306299 },
    { lat: 48.7035416, lng: 17.830619 },
    { lat: 48.7035232, lng: 17.8306147 },
    { lat: 48.7030151, lng: 17.8306012 },
    { lat: 48.7026404, lng: 17.8307197 },
    { lat: 48.7024972, lng: 17.8308741 },
    { lat: 48.7022324, lng: 17.8312389 },
    { lat: 48.7020057, lng: 17.8314326 },
    { lat: 48.701832, lng: 17.8314767 },
    { lat: 48.7014569, lng: 17.8315121 },
    { lat: 48.701293, lng: 17.8316237 },
    { lat: 48.7012359, lng: 17.8317804 },
    { lat: 48.7011452, lng: 17.8324844 },
    { lat: 48.701143, lng: 17.8326512 },
    { lat: 48.7011689, lng: 17.8328004 },
    { lat: 48.7014747, lng: 17.833256 },
    { lat: 48.7015961, lng: 17.8334132 },
    { lat: 48.7017731, lng: 17.8337062 },
    { lat: 48.7018302, lng: 17.8339002 },
    { lat: 48.7018492, lng: 17.8340282 },
    { lat: 48.7018309, lng: 17.8341916 },
    { lat: 48.7016172, lng: 17.8344324 },
    { lat: 48.7013702, lng: 17.8346062 },
    { lat: 48.7009634, lng: 17.8349401 },
    { lat: 48.7007991, lng: 17.8351003 },
    { lat: 48.7006485, lng: 17.8352061 },
    { lat: 48.700473, lng: 17.8352384 },
    { lat: 48.7003495, lng: 17.8351587 },
    { lat: 48.7002779, lng: 17.8350961 },
    { lat: 48.700125, lng: 17.8349974 },
    { lat: 48.6997246, lng: 17.8346364 },
    { lat: 48.6996359, lng: 17.8344587 },
    { lat: 48.69946, lng: 17.8340385 },
    { lat: 48.6993333, lng: 17.8337577 },
    { lat: 48.6991904, lng: 17.8335988 },
    { lat: 48.6986306, lng: 17.8332194 },
    { lat: 48.698538, lng: 17.8331268 },
    { lat: 48.698183, lng: 17.8325653 },
    { lat: 48.6981058, lng: 17.8323562 },
    { lat: 48.6979734, lng: 17.8316473 },
    { lat: 48.6979, lng: 17.8314042 },
    { lat: 48.6977951, lng: 17.8311839 },
    { lat: 48.6976232, lng: 17.8310337 },
    { lat: 48.6971606, lng: 17.831491 },
    { lat: 48.6968952, lng: 17.8317919 },
    { lat: 48.6967753, lng: 17.8319757 },
    { lat: 48.6966909, lng: 17.8322226 },
    { lat: 48.6966555, lng: 17.832438 },
    { lat: 48.6961828, lng: 17.8322648 },
    { lat: 48.6962098, lng: 17.8341412 },
    { lat: 48.6958699, lng: 17.8376982 },
    { lat: 48.6957608, lng: 17.8416152 },
    { lat: 48.6957086, lng: 17.8434756 },
    { lat: 48.6956152, lng: 17.8441325 },
    { lat: 48.6956125, lng: 17.8441823 },
    { lat: 48.6956083, lng: 17.8442606 },
    { lat: 48.6956005, lng: 17.8446174 },
    { lat: 48.6955164, lng: 17.8463048 },
    { lat: 48.6954276, lng: 17.8481012 },
    { lat: 48.6954208, lng: 17.8484545 },
    { lat: 48.6954194, lng: 17.8485435 },
    { lat: 48.6954174, lng: 17.8486403 },
    { lat: 48.6954159, lng: 17.8487103 },
    { lat: 48.695407, lng: 17.8491414 },
    { lat: 48.695303, lng: 17.852141 },
    { lat: 48.6950321, lng: 17.8558199 },
    { lat: 48.6950115, lng: 17.8561145 },
    { lat: 48.695007, lng: 17.8561698 },
    { lat: 48.6946276, lng: 17.861513 },
    { lat: 48.6945636, lng: 17.8622859 },
    { lat: 48.6945579, lng: 17.8623417 },
    { lat: 48.6945534, lng: 17.8623974 },
    { lat: 48.6944698, lng: 17.8634062 },
    { lat: 48.694463, lng: 17.86348 },
    { lat: 48.6942593, lng: 17.8659215 },
    { lat: 48.6968645, lng: 17.8662717 },
    { lat: 48.6990289, lng: 17.8665626 },
    { lat: 48.70017, lng: 17.8667103 },
    { lat: 48.7006739, lng: 17.8668393 },
    { lat: 48.700908, lng: 17.866899 },
    { lat: 48.7014142, lng: 17.8668439 },
    { lat: 48.7048348, lng: 17.8709806 },
    { lat: 48.7048958, lng: 17.8710542 },
    { lat: 48.7054043, lng: 17.8707957 },
    { lat: 48.7055476, lng: 17.8707354 },
    { lat: 48.7058719, lng: 17.8705498 },
    { lat: 48.7059958, lng: 17.8704675 },
    { lat: 48.7061368, lng: 17.8703131 },
    { lat: 48.7063413, lng: 17.8700344 },
    { lat: 48.7064425, lng: 17.8700063 },
    { lat: 48.7067302, lng: 17.8697931 },
    { lat: 48.7070868, lng: 17.8696742 },
    { lat: 48.7076835, lng: 17.8699936 },
    { lat: 48.7082603, lng: 17.87045 },
    { lat: 48.7084556, lng: 17.8706727 },
    { lat: 48.7086121, lng: 17.8709825 },
    { lat: 48.7087828, lng: 17.8713396 },
    { lat: 48.7088386, lng: 17.8718884 },
    { lat: 48.7088948, lng: 17.8725188 },
    { lat: 48.711167, lng: 17.8728379 },
    { lat: 48.7123661, lng: 17.8730104 },
    { lat: 48.7138145, lng: 17.8708196 },
    { lat: 48.714106, lng: 17.870333 },
    { lat: 48.7153443, lng: 17.8690967 },
    { lat: 48.7156938, lng: 17.868748 },
    { lat: 48.7157502, lng: 17.8686916 },
    { lat: 48.7161787, lng: 17.8682645 },
    { lat: 48.7170578, lng: 17.8673867 },
    { lat: 48.7181469, lng: 17.8662989 },
    { lat: 48.7182638, lng: 17.8661957 },
    { lat: 48.7193449, lng: 17.8652409 },
    { lat: 48.719446, lng: 17.8651565 },
    { lat: 48.7205006, lng: 17.8642646 },
    { lat: 48.721165, lng: 17.8637038 },
    { lat: 48.7212188, lng: 17.8636583 },
    { lat: 48.7212305, lng: 17.8636477 },
    { lat: 48.7212198, lng: 17.8630784 },
    { lat: 48.721216, lng: 17.8628471 },
    { lat: 48.7212051, lng: 17.8623315 },
    { lat: 48.7211865, lng: 17.8613509 },
    { lat: 48.7211665, lng: 17.8603287 },
    { lat: 48.7211507, lng: 17.8594707 },
    { lat: 48.7211206, lng: 17.8579444 },
    { lat: 48.7211086, lng: 17.8571302 },
    { lat: 48.7211081, lng: 17.8571024 },
    { lat: 48.7211037, lng: 17.856732 },
    { lat: 48.7210547, lng: 17.8545035 },
    { lat: 48.721054, lng: 17.8544925 },
    { lat: 48.7210468, lng: 17.8540931 },
    { lat: 48.721025, lng: 17.8537895 },
    { lat: 48.7210175, lng: 17.8537593 },
    { lat: 48.7214899, lng: 17.8532953 },
    { lat: 48.7214911, lng: 17.8532388 },
    { lat: 48.7214919, lng: 17.8532151 },
    { lat: 48.7214945, lng: 17.8530896 },
    { lat: 48.7215043, lng: 17.8527106 },
    { lat: 48.7215487, lng: 17.8524747 },
    { lat: 48.7216434, lng: 17.8522852 },
    { lat: 48.7218614, lng: 17.8520591 },
    { lat: 48.7223534, lng: 17.851991 },
    { lat: 48.7225943, lng: 17.8520328 },
    { lat: 48.722956, lng: 17.8521848 },
    { lat: 48.7238189, lng: 17.8522341 },
    { lat: 48.7253769, lng: 17.8523247 },
    { lat: 48.7253623, lng: 17.8520269 },
    { lat: 48.7254019, lng: 17.8519426 },
    { lat: 48.7255235, lng: 17.8517155 },
    { lat: 48.7255979, lng: 17.8514329 },
    { lat: 48.7256137, lng: 17.851302 },
    { lat: 48.7256172, lng: 17.8510058 },
    { lat: 48.7257975, lng: 17.8506278 },
    { lat: 48.7257621, lng: 17.8504343 },
    { lat: 48.7257078, lng: 17.8500054 },
    { lat: 48.7256783, lng: 17.8499458 },
    { lat: 48.7257224, lng: 17.8491839 },
    { lat: 48.7257678, lng: 17.8483125 },
    { lat: 48.7257461, lng: 17.8478037 },
    { lat: 48.7257095, lng: 17.8467731 },
    { lat: 48.725704, lng: 17.8459165 },
    { lat: 48.723496, lng: 17.8432193 },
    { lat: 48.7230648, lng: 17.8424007 },
    { lat: 48.7230017, lng: 17.8421971 },
    { lat: 48.7228702, lng: 17.8412768 },
    { lat: 48.72282, lng: 17.8408953 },
    { lat: 48.7227718, lng: 17.8404374 },
    { lat: 48.7227013, lng: 17.839832 },
    { lat: 48.722625, lng: 17.839152 },
    { lat: 48.722535, lng: 17.8384308 },
    { lat: 48.7224891, lng: 17.8380285 },
    { lat: 48.7224746, lng: 17.837923 },
  ],
  Bošáca: [
    { lat: 48.8164486, lng: 17.8201435 },
    { lat: 48.8163114, lng: 17.8202667 },
    { lat: 48.816254, lng: 17.8203644 },
    { lat: 48.8162094, lng: 17.8205234 },
    { lat: 48.8161925, lng: 17.8208385 },
    { lat: 48.8162038, lng: 17.8212492 },
    { lat: 48.8162051, lng: 17.82129 },
    { lat: 48.8162177, lng: 17.8217566 },
    { lat: 48.816273, lng: 17.8221109 },
    { lat: 48.8164362, lng: 17.8228243 },
    { lat: 48.8164005, lng: 17.8228506 },
    { lat: 48.8156715, lng: 17.8233319 },
    { lat: 48.8149443, lng: 17.8237498 },
    { lat: 48.8137631, lng: 17.82445 },
    { lat: 48.8124692, lng: 17.8259569 },
    { lat: 48.8111566, lng: 17.8274726 },
    { lat: 48.8111165, lng: 17.8275736 },
    { lat: 48.8110764, lng: 17.827677 },
    { lat: 48.8112525, lng: 17.8278792 },
    { lat: 48.8112898, lng: 17.8280653 },
    { lat: 48.8112607, lng: 17.8282002 },
    { lat: 48.8110502, lng: 17.8283441 },
    { lat: 48.8105888, lng: 17.8286178 },
    { lat: 48.810245, lng: 17.8287611 },
    { lat: 48.8095638, lng: 17.8289314 },
    { lat: 48.8092237, lng: 17.8291176 },
    { lat: 48.8088879, lng: 17.8293013 },
    { lat: 48.808095, lng: 17.8299199 },
    { lat: 48.8079096, lng: 17.8300673 },
    { lat: 48.8075517, lng: 17.8303527 },
    { lat: 48.806973, lng: 17.8311306 },
    { lat: 48.8066253, lng: 17.8319016 },
    { lat: 48.806394, lng: 17.8326894 },
    { lat: 48.8062717, lng: 17.8332085 },
    { lat: 48.8062427, lng: 17.8334355 },
    { lat: 48.8062111, lng: 17.8344439 },
    { lat: 48.8061173, lng: 17.834723 },
    { lat: 48.8055269, lng: 17.8354797 },
    { lat: 48.8048169, lng: 17.8362366 },
    { lat: 48.8040027, lng: 17.8373259 },
    { lat: 48.8034234, lng: 17.8380217 },
    { lat: 48.8035231, lng: 17.8389227 },
    { lat: 48.8037535, lng: 17.8403177 },
    { lat: 48.8042797, lng: 17.8404053 },
    { lat: 48.8048443, lng: 17.8397937 },
    { lat: 48.8048676, lng: 17.8402147 },
    { lat: 48.8049069, lng: 17.8404176 },
    { lat: 48.8049411, lng: 17.8405885 },
    { lat: 48.8049504, lng: 17.8406116 },
    { lat: 48.80497, lng: 17.8406751 },
    { lat: 48.805004, lng: 17.84079 },
    { lat: 48.8050768, lng: 17.841041 },
    { lat: 48.8051125, lng: 17.841163 },
    { lat: 48.8051294, lng: 17.8412195 },
    { lat: 48.8051456, lng: 17.8412818 },
    { lat: 48.8051606, lng: 17.8413246 },
    { lat: 48.8053302, lng: 17.8419632 },
    { lat: 48.8054902, lng: 17.8423131 },
    { lat: 48.8055487, lng: 17.8424212 },
    { lat: 48.8055986, lng: 17.8424993 },
    { lat: 48.8056321, lng: 17.8425486 },
    { lat: 48.8056472, lng: 17.8425759 },
    { lat: 48.8056966, lng: 17.8426648 },
    { lat: 48.8057628, lng: 17.8427493 },
    { lat: 48.8058068, lng: 17.8428234 },
    { lat: 48.8058588, lng: 17.8428994 },
    { lat: 48.8059632, lng: 17.8430585 },
    { lat: 48.8059759, lng: 17.843081 },
    { lat: 48.8060234, lng: 17.8431561 },
    { lat: 48.8061228, lng: 17.8433072 },
    { lat: 48.8061427, lng: 17.8433378 },
    { lat: 48.8062337, lng: 17.8434798 },
    { lat: 48.8063193, lng: 17.8436068 },
    { lat: 48.806358, lng: 17.8436682 },
    { lat: 48.8063744, lng: 17.8436974 },
    { lat: 48.8063953, lng: 17.8437266 },
    { lat: 48.806413, lng: 17.8437558 },
    { lat: 48.8064374, lng: 17.8437861 },
    { lat: 48.8064723, lng: 17.8438416 },
    { lat: 48.8065109, lng: 17.8438998 },
    { lat: 48.8065504, lng: 17.8439563 },
    { lat: 48.8065866, lng: 17.8440144 },
    { lat: 48.8066077, lng: 17.8440454 },
    { lat: 48.8066252, lng: 17.8440732 },
    { lat: 48.806644, lng: 17.8441039 },
    { lat: 48.8066802, lng: 17.8441608 },
    { lat: 48.8066976, lng: 17.8441867 },
    { lat: 48.8067473, lng: 17.8442627 },
    { lat: 48.8067875, lng: 17.8443297 },
    { lat: 48.8068321, lng: 17.8443962 },
    { lat: 48.8068772, lng: 17.8444694 },
    { lat: 48.8069228, lng: 17.844551 },
    { lat: 48.8069655, lng: 17.8446228 },
    { lat: 48.8070113, lng: 17.8446892 },
    { lat: 48.8070579, lng: 17.8447326 },
    { lat: 48.8070744, lng: 17.8447796 },
    { lat: 48.8072651, lng: 17.8453385 },
    { lat: 48.8075704, lng: 17.8462407 },
    { lat: 48.8078538, lng: 17.8470817 },
    { lat: 48.8080253, lng: 17.8475851 },
    { lat: 48.8084637, lng: 17.8488797 },
    { lat: 48.8084825, lng: 17.8489279 },
    { lat: 48.8084973, lng: 17.8490992 },
    { lat: 48.8085453, lng: 17.8495386 },
    { lat: 48.8085487, lng: 17.8495557 },
    { lat: 48.8085665, lng: 17.8497012 },
    { lat: 48.808573, lng: 17.8497545 },
    { lat: 48.8086178, lng: 17.8501396 },
    { lat: 48.8086295, lng: 17.8502393 },
    { lat: 48.8086336, lng: 17.8502699 },
    { lat: 48.8086713, lng: 17.8503701 },
    { lat: 48.8088864, lng: 17.8509228 },
    { lat: 48.8091748, lng: 17.8518083 },
    { lat: 48.8093842, lng: 17.8525645 },
    { lat: 48.8096169, lng: 17.8535202 },
    { lat: 48.8098717, lng: 17.8544676 },
    { lat: 48.8098725, lng: 17.8544992 },
    { lat: 48.8098729, lng: 17.8545248 },
    { lat: 48.8098786, lng: 17.8546196 },
    { lat: 48.8098821, lng: 17.8546401 },
    { lat: 48.8098833, lng: 17.8546593 },
    { lat: 48.8101133, lng: 17.8555887 },
    { lat: 48.8103922, lng: 17.8567853 },
    { lat: 48.8106831, lng: 17.8581076 },
    { lat: 48.8107756, lng: 17.8587032 },
    { lat: 48.8109811, lng: 17.8595836 },
    { lat: 48.8111018, lng: 17.8600302 },
    { lat: 48.8110927, lng: 17.8602719 },
    { lat: 48.81078, lng: 17.860851 },
    { lat: 48.8106884, lng: 17.8613158 },
    { lat: 48.8106344, lng: 17.8618057 },
    { lat: 48.8106332, lng: 17.8622523 },
    { lat: 48.8105615, lng: 17.8626725 },
    { lat: 48.8105391, lng: 17.8632361 },
    { lat: 48.8104596, lng: 17.8632117 },
    { lat: 48.8101166, lng: 17.8642791 },
    { lat: 48.8100894, lng: 17.8643704 },
    { lat: 48.8098231, lng: 17.8649345 },
    { lat: 48.8096456, lng: 17.8652658 },
    { lat: 48.8095343, lng: 17.8654799 },
    { lat: 48.8100024, lng: 17.8661655 },
    { lat: 48.8103616, lng: 17.8666568 },
    { lat: 48.8106177, lng: 17.8668409 },
    { lat: 48.810649, lng: 17.866852 },
    { lat: 48.810804, lng: 17.866843 },
    { lat: 48.810952, lng: 17.86684 },
    { lat: 48.811384, lng: 17.86662 },
    { lat: 48.811725, lng: 17.866563 },
    { lat: 48.812016, lng: 17.866519 },
    { lat: 48.812916, lng: 17.866368 },
    { lat: 48.813456, lng: 17.866436 },
    { lat: 48.8138587, lng: 17.8664863 },
    { lat: 48.814323, lng: 17.8659132 },
    { lat: 48.8144675, lng: 17.8657273 },
    { lat: 48.8149408, lng: 17.8651525 },
    { lat: 48.8156583, lng: 17.8643584 },
    { lat: 48.8167721, lng: 17.8633984 },
    { lat: 48.8175017, lng: 17.862674 },
    { lat: 48.818225, lng: 17.8616986 },
    { lat: 48.8187135, lng: 17.8611865 },
    { lat: 48.8192134, lng: 17.8608844 },
    { lat: 48.8192516, lng: 17.8608261 },
    { lat: 48.8192868, lng: 17.8607734 },
    { lat: 48.8198124, lng: 17.8599798 },
    { lat: 48.8198279, lng: 17.8599575 },
    { lat: 48.8201364, lng: 17.8610665 },
    { lat: 48.8201538, lng: 17.8610555 },
    { lat: 48.8202499, lng: 17.8610012 },
    { lat: 48.8203087, lng: 17.8609656 },
    { lat: 48.8204275, lng: 17.8608937 },
    { lat: 48.8204916, lng: 17.8608536 },
    { lat: 48.8205406, lng: 17.8608237 },
    { lat: 48.8205909, lng: 17.8607972 },
    { lat: 48.8206406, lng: 17.8607612 },
    { lat: 48.8206921, lng: 17.8607345 },
    { lat: 48.8207455, lng: 17.8607013 },
    { lat: 48.8207899, lng: 17.8606708 },
    { lat: 48.8208396, lng: 17.8606337 },
    { lat: 48.8210732, lng: 17.8604645 },
    { lat: 48.8211082, lng: 17.8604457 },
    { lat: 48.8211383, lng: 17.8604214 },
    { lat: 48.8211774, lng: 17.8603958 },
    { lat: 48.8212163, lng: 17.8603673 },
    { lat: 48.8212509, lng: 17.8603412 },
    { lat: 48.821287, lng: 17.8603221 },
    { lat: 48.8213515, lng: 17.86027 },
    { lat: 48.8213964, lng: 17.8602451 },
    { lat: 48.8214178, lng: 17.8602262 },
    { lat: 48.8214663, lng: 17.8601892 },
    { lat: 48.8215148, lng: 17.8601496 },
    { lat: 48.821593, lng: 17.8600823 },
    { lat: 48.821633, lng: 17.8600503 },
    { lat: 48.8216738, lng: 17.8600177 },
    { lat: 48.821719, lng: 17.8599803 },
    { lat: 48.8217623, lng: 17.8599494 },
    { lat: 48.8218051, lng: 17.8599098 },
    { lat: 48.8218478, lng: 17.8598715 },
    { lat: 48.8218876, lng: 17.8598377 },
    { lat: 48.8219307, lng: 17.8598039 },
    { lat: 48.8219671, lng: 17.8597706 },
    { lat: 48.822007, lng: 17.8597391 },
    { lat: 48.8220488, lng: 17.8597038 },
    { lat: 48.8220811, lng: 17.8596776 },
    { lat: 48.8221756, lng: 17.8595983 },
    { lat: 48.8222693, lng: 17.8598018 },
    { lat: 48.8223069, lng: 17.8597707 },
    { lat: 48.8223853, lng: 17.8597046 },
    { lat: 48.8224637, lng: 17.8596386 },
    { lat: 48.8225041, lng: 17.8595963 },
    { lat: 48.8226609, lng: 17.8594648 },
    { lat: 48.8227806, lng: 17.8593712 },
    { lat: 48.8228132, lng: 17.8594808 },
    { lat: 48.8231134, lng: 17.8593479 },
    { lat: 48.8235244, lng: 17.8591652 },
    { lat: 48.823546, lng: 17.8591665 },
    { lat: 48.8235716, lng: 17.8598148 },
    { lat: 48.8235916, lng: 17.8598099 },
    { lat: 48.8236714, lng: 17.8597852 },
    { lat: 48.8237512, lng: 17.8597604 },
    { lat: 48.8238828, lng: 17.8597332 },
    { lat: 48.8240409, lng: 17.8602571 },
    { lat: 48.8240976, lng: 17.8602245 },
    { lat: 48.8241641, lng: 17.8601857 },
    { lat: 48.8242322, lng: 17.8601541 },
    { lat: 48.8242692, lng: 17.8601332 },
    { lat: 48.8244035, lng: 17.8600564 },
    { lat: 48.8245171, lng: 17.8599929 },
    { lat: 48.8246299, lng: 17.8599353 },
    { lat: 48.8247221, lng: 17.8601363 },
    { lat: 48.8249381, lng: 17.8599752 },
    { lat: 48.8248036, lng: 17.8595783 },
    { lat: 48.8248514, lng: 17.8591183 },
    { lat: 48.8248877, lng: 17.8591197 },
    { lat: 48.8249861, lng: 17.8591249 },
    { lat: 48.825035, lng: 17.8591292 },
    { lat: 48.8250552, lng: 17.859128 },
    { lat: 48.8250834, lng: 17.8591275 },
    { lat: 48.8251309, lng: 17.8591283 },
    { lat: 48.8251792, lng: 17.8591263 },
    { lat: 48.8253177, lng: 17.8591195 },
    { lat: 48.8254249, lng: 17.8591191 },
    { lat: 48.8256255, lng: 17.8591123 },
    { lat: 48.8258513, lng: 17.8591136 },
    { lat: 48.8259912, lng: 17.8591131 },
    { lat: 48.8260409, lng: 17.859113 },
    { lat: 48.8261166, lng: 17.8591132 },
    { lat: 48.8261554, lng: 17.859009 },
    { lat: 48.8261632, lng: 17.8589882 },
    { lat: 48.8261951, lng: 17.8588983 },
    { lat: 48.8265601, lng: 17.8585135 },
    { lat: 48.827433, lng: 17.8584431 },
    { lat: 48.8279004, lng: 17.8587627 },
    { lat: 48.8283515, lng: 17.8594092 },
    { lat: 48.8285181, lng: 17.8596448 },
    { lat: 48.8286154, lng: 17.8597797 },
    { lat: 48.8290899, lng: 17.8601213 },
    { lat: 48.8295295, lng: 17.8604298 },
    { lat: 48.8304931, lng: 17.8606104 },
    { lat: 48.8311046, lng: 17.8607536 },
    { lat: 48.8322529, lng: 17.8611503 },
    { lat: 48.8323092, lng: 17.8611657 },
    { lat: 48.832383, lng: 17.861735 },
    { lat: 48.8337157, lng: 17.8606622 },
    { lat: 48.8344687, lng: 17.8603071 },
    { lat: 48.8360506, lng: 17.8600341 },
    { lat: 48.8360607, lng: 17.8599945 },
    { lat: 48.8360695, lng: 17.8599913 },
    { lat: 48.8362984, lng: 17.8599122 },
    { lat: 48.8364866, lng: 17.8598513 },
    { lat: 48.8365311, lng: 17.8598405 },
    { lat: 48.8365185, lng: 17.8597627 },
    { lat: 48.8366412, lng: 17.8597 },
    { lat: 48.8368072, lng: 17.859607 },
    { lat: 48.8369731, lng: 17.8595138 },
    { lat: 48.8372148, lng: 17.8593855 },
    { lat: 48.8373296, lng: 17.859323 },
    { lat: 48.8374072, lng: 17.8592802 },
    { lat: 48.8374837, lng: 17.8592391 },
    { lat: 48.8375601, lng: 17.8591961 },
    { lat: 48.8377197, lng: 17.8591099 },
    { lat: 48.8379252, lng: 17.8589972 },
    { lat: 48.8380572, lng: 17.8589234 },
    { lat: 48.8380439, lng: 17.8588691 },
    { lat: 48.8381725, lng: 17.858794 },
    { lat: 48.8390334, lng: 17.8583024 },
    { lat: 48.8393127, lng: 17.8578597 },
    { lat: 48.8392228, lng: 17.8576426 },
    { lat: 48.8394506, lng: 17.8573964 },
    { lat: 48.8399029, lng: 17.8583817 },
    { lat: 48.8400948, lng: 17.8581751 },
    { lat: 48.8404638, lng: 17.8589037 },
    { lat: 48.8406463, lng: 17.8588413 },
    { lat: 48.8408051, lng: 17.8587231 },
    { lat: 48.8410137, lng: 17.8585697 },
    { lat: 48.841027, lng: 17.8585637 },
    { lat: 48.8411203, lng: 17.8584463 },
    { lat: 48.8411812, lng: 17.8583691 },
    { lat: 48.8413977, lng: 17.8581044 },
    { lat: 48.8414842, lng: 17.8580035 },
    { lat: 48.8415706, lng: 17.85792 },
    { lat: 48.8416615, lng: 17.8578361 },
    { lat: 48.8418687, lng: 17.8576433 },
    { lat: 48.8420651, lng: 17.8574724 },
    { lat: 48.8423674, lng: 17.8573 },
    { lat: 48.8429468, lng: 17.8569689 },
    { lat: 48.8429653, lng: 17.857033 },
    { lat: 48.843314, lng: 17.8567875 },
    { lat: 48.8437215, lng: 17.8563578 },
    { lat: 48.8439117, lng: 17.8561797 },
    { lat: 48.8442753, lng: 17.8560877 },
    { lat: 48.8445692, lng: 17.8558516 },
    { lat: 48.8449316, lng: 17.8556478 },
    { lat: 48.8453382, lng: 17.8555377 },
    { lat: 48.8456507, lng: 17.8555354 },
    { lat: 48.8459561, lng: 17.85549 },
    { lat: 48.8459668, lng: 17.8555361 },
    { lat: 48.8459719, lng: 17.8555653 },
    { lat: 48.8462481, lng: 17.8555399 },
    { lat: 48.8466825, lng: 17.8551811 },
    { lat: 48.8466933, lng: 17.8551719 },
    { lat: 48.8475313, lng: 17.8543758 },
    { lat: 48.8476305, lng: 17.8546524 },
    { lat: 48.8476709, lng: 17.8546495 },
    { lat: 48.8483239, lng: 17.8546016 },
    { lat: 48.8488925, lng: 17.8546169 },
    { lat: 48.8494865, lng: 17.8546178 },
    { lat: 48.8499185, lng: 17.8545943 },
    { lat: 48.8500946, lng: 17.8545767 },
    { lat: 48.8502409, lng: 17.854234 },
    { lat: 48.850423, lng: 17.8538077 },
    { lat: 48.8505625, lng: 17.8534838 },
    { lat: 48.8509571, lng: 17.8527467 },
    { lat: 48.8514693, lng: 17.8519197 },
    { lat: 48.8518323, lng: 17.8513329 },
    { lat: 48.851854, lng: 17.8512983 },
    { lat: 48.8520218, lng: 17.8510292 },
    { lat: 48.8521575, lng: 17.850753 },
    { lat: 48.8523008, lng: 17.8504734 },
    { lat: 48.8523372, lng: 17.8504958 },
    { lat: 48.8524382, lng: 17.8505407 },
    { lat: 48.8525436, lng: 17.8501383 },
    { lat: 48.8525999, lng: 17.8502109 },
    { lat: 48.8527379, lng: 17.8503816 },
    { lat: 48.8528927, lng: 17.8505718 },
    { lat: 48.8529628, lng: 17.8506296 },
    { lat: 48.853035, lng: 17.8506855 },
    { lat: 48.8531001, lng: 17.8507358 },
    { lat: 48.853113, lng: 17.8507435 },
    { lat: 48.8532197, lng: 17.8507911 },
    { lat: 48.8537031, lng: 17.8510008 },
    { lat: 48.8538051, lng: 17.8506325 },
    { lat: 48.8538289, lng: 17.8505419 },
    { lat: 48.8538645, lng: 17.8504203 },
    { lat: 48.8538976, lng: 17.8502972 },
    { lat: 48.8539652, lng: 17.8500878 },
    { lat: 48.85399, lng: 17.8500125 },
    { lat: 48.8540147, lng: 17.8499363 },
    { lat: 48.8539688, lng: 17.8498853 },
    { lat: 48.8541278, lng: 17.8496596 },
    { lat: 48.8541702, lng: 17.8495957 },
    { lat: 48.8543763, lng: 17.849307 },
    { lat: 48.8544136, lng: 17.8492698 },
    { lat: 48.8545079, lng: 17.849169 },
    { lat: 48.8547194, lng: 17.8489502 },
    { lat: 48.8548448, lng: 17.8488221 },
    { lat: 48.8548631, lng: 17.8488068 },
    { lat: 48.8550334, lng: 17.8487489 },
    { lat: 48.8553614, lng: 17.8486473 },
    { lat: 48.8556963, lng: 17.848586 },
    { lat: 48.8557232, lng: 17.8486745 },
    { lat: 48.8557388, lng: 17.8487267 },
    { lat: 48.8557444, lng: 17.8487447 },
    { lat: 48.8560848, lng: 17.8486996 },
    { lat: 48.8564485, lng: 17.8485735 },
    { lat: 48.8567391, lng: 17.8484134 },
    { lat: 48.856913, lng: 17.8483196 },
    { lat: 48.8569312, lng: 17.8483414 },
    { lat: 48.8569785, lng: 17.848396 },
    { lat: 48.8571642, lng: 17.8486084 },
    { lat: 48.857479, lng: 17.8489625 },
    { lat: 48.8577612, lng: 17.849242 },
    { lat: 48.8578023, lng: 17.8491951 },
    { lat: 48.8578521, lng: 17.8491394 },
    { lat: 48.8579237, lng: 17.8490538 },
    { lat: 48.8579596, lng: 17.8490146 },
    { lat: 48.8579945, lng: 17.8489748 },
    { lat: 48.8580452, lng: 17.8489176 },
    { lat: 48.8580939, lng: 17.8488651 },
    { lat: 48.8581646, lng: 17.8487813 },
    { lat: 48.8584942, lng: 17.8491548 },
    { lat: 48.8586356, lng: 17.8494028 },
    { lat: 48.8586911, lng: 17.8494994 },
    { lat: 48.858655, lng: 17.8497238 },
    { lat: 48.8586237, lng: 17.8499345 },
    { lat: 48.8586744, lng: 17.8500457 },
    { lat: 48.8586859, lng: 17.8500666 },
    { lat: 48.8587004, lng: 17.850102 },
    { lat: 48.8587216, lng: 17.8502663 },
    { lat: 48.8587316, lng: 17.8503569 },
    { lat: 48.8587708, lng: 17.8504637 },
    { lat: 48.8588308, lng: 17.8506349 },
    { lat: 48.8588851, lng: 17.8509742 },
    { lat: 48.8590163, lng: 17.8511638 },
    { lat: 48.8592191, lng: 17.8514954 },
    { lat: 48.8593061, lng: 17.8516253 },
    { lat: 48.8593224, lng: 17.8516514 },
    { lat: 48.8595253, lng: 17.851855 },
    { lat: 48.860171, lng: 17.850542 },
    { lat: 48.860185, lng: 17.850513 },
    { lat: 48.860478, lng: 17.850346 },
    { lat: 48.860562, lng: 17.850277 },
    { lat: 48.860602, lng: 17.850245 },
    { lat: 48.860626, lng: 17.850228 },
    { lat: 48.860734, lng: 17.850146 },
    { lat: 48.861033, lng: 17.849917 },
    { lat: 48.860947, lng: 17.849614 },
    { lat: 48.860946, lng: 17.849498 },
    { lat: 48.860945, lng: 17.849409 },
    { lat: 48.860945, lng: 17.849305 },
    { lat: 48.860853, lng: 17.849046 },
    { lat: 48.861078, lng: 17.848818 },
    { lat: 48.86161, lng: 17.848286 },
    { lat: 48.861697, lng: 17.848538 },
    { lat: 48.861716, lng: 17.848588 },
    { lat: 48.861878, lng: 17.849035 },
    { lat: 48.862061, lng: 17.848786 },
    { lat: 48.862429, lng: 17.848153 },
    { lat: 48.862505, lng: 17.848077 },
    { lat: 48.863032, lng: 17.847528 },
    { lat: 48.863205, lng: 17.847358 },
    { lat: 48.863637, lng: 17.84773 },
    { lat: 48.863844, lng: 17.847255 },
    { lat: 48.864027, lng: 17.84686 },
    { lat: 48.864157, lng: 17.846754 },
    { lat: 48.864303, lng: 17.846594 },
    { lat: 48.864835, lng: 17.845983 },
    { lat: 48.864765, lng: 17.845819 },
    { lat: 48.865142, lng: 17.845276 },
    { lat: 48.865987, lng: 17.844088 },
    { lat: 48.866448, lng: 17.843437 },
    { lat: 48.866344, lng: 17.843115 },
    { lat: 48.866566, lng: 17.842953 },
    { lat: 48.866652, lng: 17.84289 },
    { lat: 48.8666, lng: 17.84266 },
    { lat: 48.866636, lng: 17.842628 },
    { lat: 48.866788, lng: 17.842492 },
    { lat: 48.866896, lng: 17.842396 },
    { lat: 48.867109, lng: 17.842207 },
    { lat: 48.867223, lng: 17.842094 },
    { lat: 48.867345, lng: 17.84198 },
    { lat: 48.867399, lng: 17.84194 },
    { lat: 48.867443, lng: 17.841907 },
    { lat: 48.867669, lng: 17.841755 },
    { lat: 48.867934, lng: 17.841538 },
    { lat: 48.868009, lng: 17.841516 },
    { lat: 48.868197, lng: 17.84136 },
    { lat: 48.86834, lng: 17.841241 },
    { lat: 48.868556, lng: 17.841024 },
    { lat: 48.868653, lng: 17.840912 },
    { lat: 48.868994, lng: 17.840734 },
    { lat: 48.869045, lng: 17.840707 },
    { lat: 48.869074, lng: 17.840688 },
    { lat: 48.86952, lng: 17.840423 },
    { lat: 48.869824, lng: 17.840276 },
    { lat: 48.870143, lng: 17.840716 },
    { lat: 48.87037, lng: 17.840437 },
    { lat: 48.870672, lng: 17.839959 },
    { lat: 48.871193, lng: 17.83981 },
    { lat: 48.871758, lng: 17.839642 },
    { lat: 48.872225, lng: 17.839492 },
    { lat: 48.872789, lng: 17.839383 },
    { lat: 48.873208, lng: 17.839122 },
    { lat: 48.874127, lng: 17.838656 },
    { lat: 48.874501, lng: 17.838474 },
    { lat: 48.87477, lng: 17.838342 },
    { lat: 48.874886, lng: 17.838297 },
    { lat: 48.875337, lng: 17.838075 },
    { lat: 48.875992, lng: 17.837765 },
    { lat: 48.876032, lng: 17.837746 },
    { lat: 48.876621, lng: 17.837523 },
    { lat: 48.877172, lng: 17.837267 },
    { lat: 48.877428, lng: 17.837292 },
    { lat: 48.87796, lng: 17.837165 },
    { lat: 48.87812, lng: 17.837158 },
    { lat: 48.878802, lng: 17.837265 },
    { lat: 48.878951, lng: 17.837251 },
    { lat: 48.879203, lng: 17.837237 },
    { lat: 48.879527, lng: 17.837139 },
    { lat: 48.880025, lng: 17.837104 },
    { lat: 48.880167, lng: 17.836914 },
    { lat: 48.880639, lng: 17.836184 },
    { lat: 48.880748, lng: 17.835889 },
    { lat: 48.881527, lng: 17.835228 },
    { lat: 48.881586, lng: 17.835168 },
    { lat: 48.882294, lng: 17.834875 },
    { lat: 48.882788, lng: 17.834629 },
    { lat: 48.883286, lng: 17.834396 },
    { lat: 48.8841, lng: 17.833838 },
    { lat: 48.884754, lng: 17.8336 },
    { lat: 48.884771, lng: 17.833584 },
    { lat: 48.88545, lng: 17.83346 },
    { lat: 48.886038, lng: 17.833416 },
    { lat: 48.88655, lng: 17.833135 },
    { lat: 48.88706, lng: 17.832778 },
    { lat: 48.887667, lng: 17.832357 },
    { lat: 48.888126, lng: 17.831827 },
    { lat: 48.888451, lng: 17.83139 },
    { lat: 48.888649, lng: 17.831154 },
    { lat: 48.888765, lng: 17.831005 },
    { lat: 48.889248, lng: 17.83091 },
    { lat: 48.889641, lng: 17.83086 },
    { lat: 48.889803, lng: 17.830846 },
    { lat: 48.890096, lng: 17.830789 },
    { lat: 48.890341, lng: 17.830734 },
    { lat: 48.89037, lng: 17.830719 },
    { lat: 48.890434, lng: 17.830779 },
    { lat: 48.890647, lng: 17.830877 },
    { lat: 48.890816, lng: 17.830925 },
    { lat: 48.891135, lng: 17.830936 },
    { lat: 48.891636, lng: 17.831091 },
    { lat: 48.892287, lng: 17.831237 },
    { lat: 48.892527, lng: 17.831245 },
    { lat: 48.892819, lng: 17.83138 },
    { lat: 48.893107, lng: 17.831389 },
    { lat: 48.89343, lng: 17.831656 },
    { lat: 48.893689, lng: 17.831722 },
    { lat: 48.893914, lng: 17.831778 },
    { lat: 48.894384, lng: 17.831897 },
    { lat: 48.894771, lng: 17.832115 },
    { lat: 48.89476, lng: 17.832151 },
    { lat: 48.894976, lng: 17.832282 },
    { lat: 48.895506, lng: 17.832517 },
    { lat: 48.895895, lng: 17.832712 },
    { lat: 48.895851, lng: 17.833009 },
    { lat: 48.896652, lng: 17.833553 },
    { lat: 48.896686, lng: 17.833578 },
    { lat: 48.896721, lng: 17.833547 },
    { lat: 48.8967598, lng: 17.8335078 },
    { lat: 48.896685, lng: 17.8333367 },
    { lat: 48.896742, lng: 17.8329343 },
    { lat: 48.8968039, lng: 17.8324277 },
    { lat: 48.8968636, lng: 17.8319413 },
    { lat: 48.8969049, lng: 17.8315782 },
    { lat: 48.8969479, lng: 17.8310769 },
    { lat: 48.8969717, lng: 17.8309654 },
    { lat: 48.8969785, lng: 17.8309105 },
    { lat: 48.8973232, lng: 17.8285874 },
    { lat: 48.8973586, lng: 17.8283145 },
    { lat: 48.8975516, lng: 17.8270096 },
    { lat: 48.8976835, lng: 17.8261112 },
    { lat: 48.8977019, lng: 17.825987 },
    { lat: 48.8978985, lng: 17.8246848 },
    { lat: 48.8979697, lng: 17.8242007 },
    { lat: 48.8980556, lng: 17.8239254 },
    { lat: 48.8981691, lng: 17.8234494 },
    { lat: 48.8981866, lng: 17.8230869 },
    { lat: 48.8981933, lng: 17.8229918 },
    { lat: 48.8982159, lng: 17.8224901 },
    { lat: 48.8981766, lng: 17.8223638 },
    { lat: 48.8980511, lng: 17.8218384 },
    { lat: 48.8978074, lng: 17.8209848 },
    { lat: 48.8975924, lng: 17.8206029 },
    { lat: 48.8975152, lng: 17.8202618 },
    { lat: 48.8975019, lng: 17.8202285 },
    { lat: 48.8968936, lng: 17.8193943 },
    { lat: 48.8967672, lng: 17.8186497 },
    { lat: 48.8967676, lng: 17.8181723 },
    { lat: 48.8965873, lng: 17.8178611 },
    { lat: 48.8964921, lng: 17.8175944 },
    { lat: 48.8964682, lng: 17.8175353 },
    { lat: 48.8964152, lng: 17.8175547 },
    { lat: 48.8962474, lng: 17.8179706 },
    { lat: 48.8961291, lng: 17.8184216 },
    { lat: 48.8960021, lng: 17.8188386 },
    { lat: 48.8959594, lng: 17.8190663 },
    { lat: 48.8959475, lng: 17.8192987 },
    { lat: 48.896134, lng: 17.8199348 },
    { lat: 48.8961976, lng: 17.8201451 },
    { lat: 48.8961899, lng: 17.8203173 },
    { lat: 48.8961666, lng: 17.8206584 },
    { lat: 48.8959604, lng: 17.8202561 },
    { lat: 48.8957581, lng: 17.8199915 },
    { lat: 48.8955938, lng: 17.8198878 },
    { lat: 48.8951939, lng: 17.8201631 },
    { lat: 48.8950837, lng: 17.8199083 },
    { lat: 48.8951384, lng: 17.8192117 },
    { lat: 48.8950945, lng: 17.8187101 },
    { lat: 48.8950833, lng: 17.8186179 },
    { lat: 48.8950809, lng: 17.818206 },
    { lat: 48.8950815, lng: 17.817918 },
    { lat: 48.8950819, lng: 17.8178501 },
    { lat: 48.895083, lng: 17.8177002 },
    { lat: 48.8950779, lng: 17.8175057 },
    { lat: 48.8951162, lng: 17.8170026 },
    { lat: 48.8951292, lng: 17.8168241 },
    { lat: 48.8951669, lng: 17.8165898 },
    { lat: 48.8951622, lng: 17.8162334 },
    { lat: 48.8951181, lng: 17.8158788 },
    { lat: 48.8950262, lng: 17.8151268 },
    { lat: 48.8949629, lng: 17.8150696 },
    { lat: 48.8947976, lng: 17.8148026 },
    { lat: 48.8948761, lng: 17.8147376 },
    { lat: 48.8948244, lng: 17.814592 },
    { lat: 48.8945475, lng: 17.8142904 },
    { lat: 48.8944178, lng: 17.8141426 },
    { lat: 48.8943807, lng: 17.814145 },
    { lat: 48.894342, lng: 17.8141399 },
    { lat: 48.8940564, lng: 17.8141223 },
    { lat: 48.8940159, lng: 17.8142546 },
    { lat: 48.8938184, lng: 17.81407 },
    { lat: 48.8936005, lng: 17.8139938 },
    { lat: 48.8934535, lng: 17.8140657 },
    { lat: 48.8932414, lng: 17.8139737 },
    { lat: 48.8931433, lng: 17.8140871 },
    { lat: 48.8931122, lng: 17.8140957 },
    { lat: 48.8928566, lng: 17.8140684 },
    { lat: 48.8926188, lng: 17.814058 },
    { lat: 48.8925348, lng: 17.81405 },
    { lat: 48.8922606, lng: 17.8140139 },
    { lat: 48.8921112, lng: 17.8139137 },
    { lat: 48.8920342, lng: 17.8138562 },
    { lat: 48.8918558, lng: 17.8137242 },
    { lat: 48.8916192, lng: 17.8136198 },
    { lat: 48.8915844, lng: 17.8136053 },
    { lat: 48.8915617, lng: 17.8136022 },
    { lat: 48.8912727, lng: 17.8137155 },
    { lat: 48.891003, lng: 17.8147578 },
    { lat: 48.8909956, lng: 17.8147861 },
    { lat: 48.8909613, lng: 17.8149079 },
    { lat: 48.890848, lng: 17.8153662 },
    { lat: 48.8908806, lng: 17.8154011 },
    { lat: 48.8909856, lng: 17.815493 },
    { lat: 48.890855, lng: 17.8159464 },
    { lat: 48.890802, lng: 17.8161708 },
    { lat: 48.890724, lng: 17.8165785 },
    { lat: 48.8906553, lng: 17.8168987 },
    { lat: 48.8906444, lng: 17.8170141 },
    { lat: 48.8906269, lng: 17.8173041 },
    { lat: 48.8904488, lng: 17.8177729 },
    { lat: 48.8902961, lng: 17.8181219 },
    { lat: 48.8903021, lng: 17.8182769 },
    { lat: 48.8897842, lng: 17.8186898 },
    { lat: 48.889661, lng: 17.8182602 },
    { lat: 48.8896715, lng: 17.8181355 },
    { lat: 48.8895967, lng: 17.8178511 },
    { lat: 48.8895626, lng: 17.8177154 },
    { lat: 48.8894979, lng: 17.8174317 },
    { lat: 48.8895133, lng: 17.8170337 },
    { lat: 48.889423, lng: 17.8166063 },
    { lat: 48.8895613, lng: 17.8165233 },
    { lat: 48.8896116, lng: 17.8164027 },
    { lat: 48.889556, lng: 17.8157099 },
    { lat: 48.8895332, lng: 17.8156507 },
    { lat: 48.8892149, lng: 17.8150556 },
    { lat: 48.8892058, lng: 17.8150359 },
    { lat: 48.8889796, lng: 17.8147312 },
    { lat: 48.8890514, lng: 17.8143719 },
    { lat: 48.8887173, lng: 17.8143522 },
    { lat: 48.88846, lng: 17.8145042 },
    { lat: 48.8884501, lng: 17.814509 },
    { lat: 48.8884668, lng: 17.8146354 },
    { lat: 48.8885286, lng: 17.8151304 },
    { lat: 48.8885546, lng: 17.815375 },
    { lat: 48.8882558, lng: 17.8155649 },
    { lat: 48.8880121, lng: 17.8157358 },
    { lat: 48.8878759, lng: 17.8158538 },
    { lat: 48.8877419, lng: 17.8159707 },
    { lat: 48.8875947, lng: 17.816112 },
    { lat: 48.887534, lng: 17.8161727 },
    { lat: 48.8870127, lng: 17.8162883 },
    { lat: 48.8866145, lng: 17.816387 },
    { lat: 48.8864259, lng: 17.816422 },
    { lat: 48.8861973, lng: 17.8164499 },
    { lat: 48.8861343, lng: 17.8164531 },
    { lat: 48.8858875, lng: 17.8166092 },
    { lat: 48.8857206, lng: 17.816593 },
    { lat: 48.8856226, lng: 17.8165794 },
    { lat: 48.8855737, lng: 17.8164783 },
    { lat: 48.8855243, lng: 17.8163548 },
    { lat: 48.885393, lng: 17.8161805 },
    { lat: 48.8851981, lng: 17.8159264 },
    { lat: 48.8851865, lng: 17.8159033 },
    { lat: 48.8849466, lng: 17.8157065 },
    { lat: 48.884989, lng: 17.8156244 },
    { lat: 48.8850921, lng: 17.8154071 },
    { lat: 48.8848637, lng: 17.8152901 },
    { lat: 48.8845376, lng: 17.8149574 },
    { lat: 48.8841904, lng: 17.8147978 },
    { lat: 48.8840536, lng: 17.814832 },
    { lat: 48.8840358, lng: 17.8151528 },
    { lat: 48.8836663, lng: 17.8153519 },
    { lat: 48.8836351, lng: 17.8153974 },
    { lat: 48.8836035, lng: 17.8154703 },
    { lat: 48.8835801, lng: 17.815494 },
    { lat: 48.8831569, lng: 17.8153285 },
    { lat: 48.8827972, lng: 17.8152058 },
    { lat: 48.8823878, lng: 17.8153973 },
    { lat: 48.8819381, lng: 17.8156709 },
    { lat: 48.8817271, lng: 17.8158216 },
    { lat: 48.8815748, lng: 17.8161007 },
    { lat: 48.8813319, lng: 17.8165263 },
    { lat: 48.8812046, lng: 17.8170151 },
    { lat: 48.8810693, lng: 17.8170729 },
    { lat: 48.8810299, lng: 17.8174082 },
    { lat: 48.8808994, lng: 17.817418 },
    { lat: 48.8807697, lng: 17.8174185 },
    { lat: 48.8807408, lng: 17.8174251 },
    { lat: 48.8807313, lng: 17.816654 },
    { lat: 48.8806061, lng: 17.8166578 },
    { lat: 48.8801363, lng: 17.8166904 },
    { lat: 48.8800059, lng: 17.8167001 },
    { lat: 48.8794845, lng: 17.8168507 },
    { lat: 48.8793956, lng: 17.8168751 },
    { lat: 48.8791211, lng: 17.8170768 },
    { lat: 48.8789895, lng: 17.8172334 },
    { lat: 48.8785705, lng: 17.8175832 },
    { lat: 48.8784331, lng: 17.8177 },
    { lat: 48.8782096, lng: 17.8179053 },
    { lat: 48.878353, lng: 17.8182591 },
    { lat: 48.8785634, lng: 17.8185452 },
    { lat: 48.8785353, lng: 17.8186038 },
    { lat: 48.8785076, lng: 17.8186694 },
    { lat: 48.8785023, lng: 17.8186936 },
    { lat: 48.8783399, lng: 17.8185473 },
    { lat: 48.8781315, lng: 17.8184039 },
    { lat: 48.8780644, lng: 17.8187683 },
    { lat: 48.8782355, lng: 17.8191322 },
    { lat: 48.8780804, lng: 17.8195158 },
    { lat: 48.8779261, lng: 17.8196157 },
    { lat: 48.8777463, lng: 17.8195359 },
    { lat: 48.8775325, lng: 17.8195661 },
    { lat: 48.8773716, lng: 17.8198166 },
    { lat: 48.8772147, lng: 17.8201505 },
    { lat: 48.8767662, lng: 17.820052 },
    { lat: 48.8766008, lng: 17.8199858 },
    { lat: 48.8765929, lng: 17.8200428 },
    { lat: 48.8765114, lng: 17.8208215 },
    { lat: 48.8765237, lng: 17.8213399 },
    { lat: 48.876632, lng: 17.8213564 },
    { lat: 48.8766252, lng: 17.8214131 },
    { lat: 48.8765919, lng: 17.8216459 },
    { lat: 48.8765805, lng: 17.8217359 },
    { lat: 48.8763704, lng: 17.8223303 },
    { lat: 48.8762301, lng: 17.8223449 },
    { lat: 48.8760006, lng: 17.8223769 },
    { lat: 48.8758344, lng: 17.8222781 },
    { lat: 48.875861, lng: 17.8220472 },
    { lat: 48.8753436, lng: 17.821519 },
    { lat: 48.8752347, lng: 17.8213986 },
    { lat: 48.8749862, lng: 17.8211379 },
    { lat: 48.8749161, lng: 17.8210596 },
    { lat: 48.8745475, lng: 17.820716 },
    { lat: 48.8745428, lng: 17.8206187 },
    { lat: 48.8744775, lng: 17.8205097 },
    { lat: 48.8744359, lng: 17.820421 },
    { lat: 48.8743163, lng: 17.8202721 },
    { lat: 48.8741548, lng: 17.8200465 },
    { lat: 48.8738594, lng: 17.8195707 },
    { lat: 48.8734946, lng: 17.8192696 },
    { lat: 48.8731621, lng: 17.8190352 },
    { lat: 48.8731055, lng: 17.818997 },
    { lat: 48.8730962, lng: 17.8186192 },
    { lat: 48.8729118, lng: 17.8185567 },
    { lat: 48.8728251, lng: 17.8184135 },
    { lat: 48.8725538, lng: 17.8182764 },
    { lat: 48.8724039, lng: 17.8182797 },
    { lat: 48.8723025, lng: 17.8183941 },
    { lat: 48.8718309, lng: 17.818437 },
    { lat: 48.8711058, lng: 17.8183924 },
    { lat: 48.8711021, lng: 17.8182948 },
    { lat: 48.870819, lng: 17.8183553 },
    { lat: 48.8705504, lng: 17.8185044 },
    { lat: 48.8704585, lng: 17.8187948 },
    { lat: 48.8703151, lng: 17.8188508 },
    { lat: 48.8702772, lng: 17.8183752 },
    { lat: 48.8700383, lng: 17.8183064 },
    { lat: 48.8700367, lng: 17.8179835 },
    { lat: 48.8699971, lng: 17.8178696 },
    { lat: 48.8699408, lng: 17.8177234 },
    { lat: 48.8698806, lng: 17.8175124 },
    { lat: 48.8697129, lng: 17.8175406 },
    { lat: 48.8696894, lng: 17.8175627 },
    { lat: 48.8696681, lng: 17.8175831 },
    { lat: 48.8695357, lng: 17.8177082 },
    { lat: 48.8694829, lng: 17.8177873 },
    { lat: 48.8693407, lng: 17.818028 },
    { lat: 48.8692422, lng: 17.818173 },
    { lat: 48.8690485, lng: 17.818312 },
    { lat: 48.8690206, lng: 17.8182989 },
    { lat: 48.8689892, lng: 17.8182818 },
    { lat: 48.8687705, lng: 17.8181758 },
    { lat: 48.8686535, lng: 17.8179029 },
    { lat: 48.8685187, lng: 17.8175603 },
    { lat: 48.8684335, lng: 17.8173117 },
    { lat: 48.8683648, lng: 17.817109 },
    { lat: 48.8682754, lng: 17.8168464 },
    { lat: 48.8682601, lng: 17.8164627 },
    { lat: 48.8682979, lng: 17.8164164 },
    { lat: 48.868339, lng: 17.8163678 },
    { lat: 48.8685256, lng: 17.8161515 },
    { lat: 48.8685794, lng: 17.8160148 },
    { lat: 48.8685982, lng: 17.8159698 },
    { lat: 48.868581, lng: 17.8157617 },
    { lat: 48.8685308, lng: 17.8155466 },
    { lat: 48.8684178, lng: 17.8150623 },
    { lat: 48.8683982, lng: 17.8149813 },
    { lat: 48.868373, lng: 17.8148816 },
    { lat: 48.8683668, lng: 17.8148543 },
    { lat: 48.8682054, lng: 17.8149491 },
    { lat: 48.8679344, lng: 17.8150763 },
    { lat: 48.8674581, lng: 17.8154511 },
    { lat: 48.8672953, lng: 17.8156698 },
    { lat: 48.8669507, lng: 17.8163907 },
    { lat: 48.8668413, lng: 17.816801 },
    { lat: 48.8667774, lng: 17.8172543 },
    { lat: 48.8667779, lng: 17.818099 },
    { lat: 48.8667617, lng: 17.8181667 },
    { lat: 48.8667039, lng: 17.8184435 },
    { lat: 48.8664507, lng: 17.8188094 },
    { lat: 48.8662163, lng: 17.8192451 },
    { lat: 48.8659195, lng: 17.8195056 },
    { lat: 48.8658623, lng: 17.8196059 },
    { lat: 48.8655858, lng: 17.8200722 },
    { lat: 48.8656095, lng: 17.8200911 },
    { lat: 48.8655751, lng: 17.8202851 },
    { lat: 48.8651454, lng: 17.8209446 },
    { lat: 48.864668, lng: 17.821729 },
    { lat: 48.8642699, lng: 17.8223662 },
    { lat: 48.8642502, lng: 17.8223967 },
    { lat: 48.8642246, lng: 17.8224392 },
    { lat: 48.8640248, lng: 17.8227558 },
    { lat: 48.8637577, lng: 17.8234316 },
    { lat: 48.8634359, lng: 17.8241758 },
    { lat: 48.8631453, lng: 17.8249495 },
    { lat: 48.8628588, lng: 17.8257547 },
    { lat: 48.8626224, lng: 17.8262132 },
    { lat: 48.8625876, lng: 17.8262539 },
    { lat: 48.8625644, lng: 17.8262799 },
    { lat: 48.8623814, lng: 17.8264834 },
    { lat: 48.862359, lng: 17.826506 },
    { lat: 48.8621803, lng: 17.8267414 },
    { lat: 48.862113, lng: 17.8268406 },
    { lat: 48.8617654, lng: 17.8272713 },
    { lat: 48.8613323, lng: 17.8278176 },
    { lat: 48.8610433, lng: 17.8281717 },
    { lat: 48.8606569, lng: 17.8287445 },
    { lat: 48.8602713, lng: 17.8291981 },
    { lat: 48.8599861, lng: 17.829727 },
    { lat: 48.8598693, lng: 17.8299042 },
    { lat: 48.8597243, lng: 17.8301201 },
    { lat: 48.8592704, lng: 17.8305097 },
    { lat: 48.8591229, lng: 17.8306466 },
    { lat: 48.8587435, lng: 17.8309978 },
    { lat: 48.8589977, lng: 17.8315416 },
    { lat: 48.8590551, lng: 17.8319126 },
    { lat: 48.8590102, lng: 17.8323261 },
    { lat: 48.8591758, lng: 17.8326555 },
    { lat: 48.8590953, lng: 17.8328188 },
    { lat: 48.8585285, lng: 17.8339329 },
    { lat: 48.8585142, lng: 17.8339592 },
    { lat: 48.8583367, lng: 17.8342725 },
    { lat: 48.8585365, lng: 17.8349997 },
    { lat: 48.8586552, lng: 17.8354305 },
    { lat: 48.8585285, lng: 17.8358562 },
    { lat: 48.8583525, lng: 17.8358203 },
    { lat: 48.8578432, lng: 17.8360019 },
    { lat: 48.8578259, lng: 17.8360147 },
    { lat: 48.8572922, lng: 17.8364852 },
    { lat: 48.8570998, lng: 17.8368495 },
    { lat: 48.8568432, lng: 17.8373291 },
    { lat: 48.8567122, lng: 17.8371219 },
    { lat: 48.8563891, lng: 17.8374555 },
    { lat: 48.8563476, lng: 17.8376079 },
    { lat: 48.856271, lng: 17.8378722 },
    { lat: 48.8561752, lng: 17.8382107 },
    { lat: 48.8560292, lng: 17.838559 },
    { lat: 48.8559264, lng: 17.8388932 },
    { lat: 48.8557792, lng: 17.839147 },
    { lat: 48.8556316, lng: 17.8394887 },
    { lat: 48.8555744, lng: 17.8396614 },
    { lat: 48.8554296, lng: 17.8400857 },
    { lat: 48.855308, lng: 17.8403155 },
    { lat: 48.8549903, lng: 17.8405133 },
    { lat: 48.8545063, lng: 17.8410761 },
    { lat: 48.8543068, lng: 17.8412501 },
    { lat: 48.8542583, lng: 17.8412877 },
    { lat: 48.8540975, lng: 17.8415213 },
    { lat: 48.8540819, lng: 17.8415445 },
    { lat: 48.8540374, lng: 17.8415159 },
    { lat: 48.8538982, lng: 17.841437 },
    { lat: 48.8538101, lng: 17.8413799 },
    { lat: 48.8535533, lng: 17.841948 },
    { lat: 48.8532018, lng: 17.8429299 },
    { lat: 48.8530819, lng: 17.8426849 },
    { lat: 48.8528779, lng: 17.8425602 },
    { lat: 48.8529445, lng: 17.8423554 },
    { lat: 48.8530047, lng: 17.8420814 },
    { lat: 48.8527265, lng: 17.841831 },
    { lat: 48.8524961, lng: 17.8414923 },
    { lat: 48.8521044, lng: 17.8411361 },
    { lat: 48.8520863, lng: 17.8411168 },
    { lat: 48.851807, lng: 17.8408118 },
    { lat: 48.8517636, lng: 17.8402616 },
    { lat: 48.8516369, lng: 17.8396621 },
    { lat: 48.8515598, lng: 17.8394687 },
    { lat: 48.8514328, lng: 17.8392739 },
    { lat: 48.8510862, lng: 17.8388986 },
    { lat: 48.8508133, lng: 17.838606 },
    { lat: 48.8505505, lng: 17.8386651 },
    { lat: 48.8503181, lng: 17.8385555 },
    { lat: 48.8499785, lng: 17.8384839 },
    { lat: 48.8497119, lng: 17.8382947 },
    { lat: 48.8496299, lng: 17.8382651 },
    { lat: 48.8495847, lng: 17.8382443 },
    { lat: 48.8491036, lng: 17.8380542 },
    { lat: 48.8489069, lng: 17.837863 },
    { lat: 48.8487559, lng: 17.8377181 },
    { lat: 48.8482622, lng: 17.8370969 },
    { lat: 48.8481243, lng: 17.8370383 },
    { lat: 48.84777, lng: 17.837115 },
    { lat: 48.8475254, lng: 17.8372326 },
    { lat: 48.8468862, lng: 17.8382537 },
    { lat: 48.8470125, lng: 17.8384 },
    { lat: 48.846897, lng: 17.8386011 },
    { lat: 48.8466752, lng: 17.8390959 },
    { lat: 48.8464684, lng: 17.8396336 },
    { lat: 48.8463522, lng: 17.8399879 },
    { lat: 48.8463068, lng: 17.8401695 },
    { lat: 48.8462219, lng: 17.8405021 },
    { lat: 48.8461256, lng: 17.8409255 },
    { lat: 48.8460983, lng: 17.8409031 },
    { lat: 48.8457895, lng: 17.840651 },
    { lat: 48.8455529, lng: 17.8404534 },
    { lat: 48.8453089, lng: 17.8405053 },
    { lat: 48.8450243, lng: 17.8404478 },
    { lat: 48.844899, lng: 17.8402244 },
    { lat: 48.8447488, lng: 17.840149 },
    { lat: 48.8446637, lng: 17.8401044 },
    { lat: 48.844597, lng: 17.8400099 },
    { lat: 48.8444963, lng: 17.8398558 },
    { lat: 48.8444074, lng: 17.8398429 },
    { lat: 48.8442108, lng: 17.8399528 },
    { lat: 48.8437977, lng: 17.8401201 },
    { lat: 48.84349, lng: 17.8401675 },
    { lat: 48.8430969, lng: 17.840161 },
    { lat: 48.8428234, lng: 17.8401934 },
    { lat: 48.8425455, lng: 17.8403017 },
    { lat: 48.8421697, lng: 17.8404718 },
    { lat: 48.8418703, lng: 17.8405421 },
    { lat: 48.8407446, lng: 17.8408913 },
    { lat: 48.8407273, lng: 17.8409044 },
    { lat: 48.8406078, lng: 17.8406469 },
    { lat: 48.8405581, lng: 17.8403104 },
    { lat: 48.8405539, lng: 17.8398502 },
    { lat: 48.8404297, lng: 17.8390666 },
    { lat: 48.8404009, lng: 17.8390746 },
    { lat: 48.8400078, lng: 17.8392754 },
    { lat: 48.8399627, lng: 17.8392966 },
    { lat: 48.8395609, lng: 17.8394628 },
    { lat: 48.8394593, lng: 17.8390732 },
    { lat: 48.8393203, lng: 17.8385116 },
    { lat: 48.8392878, lng: 17.8383856 },
    { lat: 48.8392763, lng: 17.8383432 },
    { lat: 48.8392648, lng: 17.8383019 },
    { lat: 48.8392555, lng: 17.8382622 },
    { lat: 48.839217, lng: 17.8381111 },
    { lat: 48.8391933, lng: 17.8380153 },
    { lat: 48.8391765, lng: 17.8379428 },
    { lat: 48.8391639, lng: 17.8378856 },
    { lat: 48.839094, lng: 17.8375511 },
    { lat: 48.839048, lng: 17.8373505 },
    { lat: 48.8390157, lng: 17.8372079 },
    { lat: 48.8389891, lng: 17.8370872 },
    { lat: 48.8389652, lng: 17.8369888 },
    { lat: 48.8389606, lng: 17.8369703 },
    { lat: 48.838944, lng: 17.8369375 },
    { lat: 48.838935, lng: 17.8369202 },
    { lat: 48.8388475, lng: 17.836743 },
    { lat: 48.8387424, lng: 17.836537 },
    { lat: 48.8386717, lng: 17.8363387 },
    { lat: 48.8385474, lng: 17.8361857 },
    { lat: 48.8384066, lng: 17.8359489 },
    { lat: 48.838316, lng: 17.8358705 },
    { lat: 48.8381728, lng: 17.835854 },
    { lat: 48.83806, lng: 17.8357815 },
    { lat: 48.8380418, lng: 17.8356857 },
    { lat: 48.8379747, lng: 17.8353603 },
    { lat: 48.837962, lng: 17.8352997 },
    { lat: 48.8379522, lng: 17.8352502 },
    { lat: 48.8379457, lng: 17.835216 },
    { lat: 48.8379365, lng: 17.8351759 },
    { lat: 48.8379093, lng: 17.8350825 },
    { lat: 48.8379022, lng: 17.8350597 },
    { lat: 48.837715, lng: 17.8343794 },
    { lat: 48.8376762, lng: 17.8342256 },
    { lat: 48.8375935, lng: 17.8339161 },
    { lat: 48.8375054, lng: 17.833589 },
    { lat: 48.8374673, lng: 17.8334493 },
    { lat: 48.8374409, lng: 17.83335 },
    { lat: 48.837412, lng: 17.8332454 },
    { lat: 48.8373726, lng: 17.8330986 },
    { lat: 48.8373148, lng: 17.8328886 },
    { lat: 48.8372578, lng: 17.8326768 },
    { lat: 48.837201, lng: 17.8324658 },
    { lat: 48.837144, lng: 17.8322501 },
    { lat: 48.8370844, lng: 17.8320305 },
    { lat: 48.8370276, lng: 17.8318212 },
    { lat: 48.8369679, lng: 17.8315991 },
    { lat: 48.8369123, lng: 17.83139 },
    { lat: 48.8368448, lng: 17.8311335 },
    { lat: 48.8368184, lng: 17.8310308 },
    { lat: 48.8367963, lng: 17.8310399 },
    { lat: 48.8367203, lng: 17.830793 },
    { lat: 48.8367048, lng: 17.8307414 },
    { lat: 48.8366893, lng: 17.83069 },
    { lat: 48.8366367, lng: 17.8305119 },
    { lat: 48.8365658, lng: 17.8302725 },
    { lat: 48.8368067, lng: 17.8299068 },
    { lat: 48.8368399, lng: 17.8298606 },
    { lat: 48.8370485, lng: 17.8294657 },
    { lat: 48.8370331, lng: 17.8293036 },
    { lat: 48.8370288, lng: 17.8292517 },
    { lat: 48.837019, lng: 17.829147 },
    { lat: 48.836987, lng: 17.8288031 },
    { lat: 48.8369819, lng: 17.8287576 },
    { lat: 48.8369771, lng: 17.8287142 },
    { lat: 48.8368849, lng: 17.8284796 },
    { lat: 48.8372227, lng: 17.8279264 },
    { lat: 48.8374095, lng: 17.8276189 },
    { lat: 48.8370547, lng: 17.826998 },
    { lat: 48.8374129, lng: 17.8259987 },
    { lat: 48.8375273, lng: 17.8256701 },
    { lat: 48.837612, lng: 17.8254288 },
    { lat: 48.8374437, lng: 17.8252779 },
    { lat: 48.8374559, lng: 17.8251803 },
    { lat: 48.8374606, lng: 17.8251275 },
    { lat: 48.837245, lng: 17.8249601 },
    { lat: 48.8371838, lng: 17.8241195 },
    { lat: 48.8372115, lng: 17.8240366 },
    { lat: 48.8372596, lng: 17.8238613 },
    { lat: 48.8373256, lng: 17.8236475 },
    { lat: 48.8373565, lng: 17.8230779 },
    { lat: 48.8373847, lng: 17.8225934 },
    { lat: 48.8373633, lng: 17.8225383 },
    { lat: 48.837265, lng: 17.822277 },
    { lat: 48.8372719, lng: 17.822185 },
    { lat: 48.837319, lng: 17.8218635 },
    { lat: 48.8373767, lng: 17.8214017 },
    { lat: 48.8373827, lng: 17.8213704 },
    { lat: 48.8374242, lng: 17.8211783 },
    { lat: 48.8374809, lng: 17.8203107 },
    { lat: 48.8374574, lng: 17.8196799 },
    { lat: 48.8374836, lng: 17.81922 },
    { lat: 48.8375312, lng: 17.8187013 },
    { lat: 48.8375228, lng: 17.8182843 },
    { lat: 48.8374724, lng: 17.817659 },
    { lat: 48.8371859, lng: 17.816586 },
    { lat: 48.8370147, lng: 17.8158853 },
    { lat: 48.8369113, lng: 17.8154657 },
    { lat: 48.8370277, lng: 17.8148736 },
    { lat: 48.8370984, lng: 17.8140292 },
    { lat: 48.8374171, lng: 17.8139425 },
    { lat: 48.8374156, lng: 17.8139192 },
    { lat: 48.837388, lng: 17.813502 },
    { lat: 48.8373732, lng: 17.8133693 },
    { lat: 48.8373705, lng: 17.8133243 },
    { lat: 48.8373913, lng: 17.8131102 },
    { lat: 48.8374001, lng: 17.8130133 },
    { lat: 48.8374087, lng: 17.8129137 },
    { lat: 48.8374188, lng: 17.8128007 },
    { lat: 48.8374009, lng: 17.8127091 },
    { lat: 48.8373582, lng: 17.8124714 },
    { lat: 48.837461, lng: 17.8123438 },
    { lat: 48.8381542, lng: 17.8114711 },
    { lat: 48.8382099, lng: 17.8114018 },
    { lat: 48.8382584, lng: 17.8111983 },
    { lat: 48.8382865, lng: 17.8110645 },
    { lat: 48.8382448, lng: 17.8109745 },
    { lat: 48.8379797, lng: 17.8103822 },
    { lat: 48.8379378, lng: 17.809768 },
    { lat: 48.8380177, lng: 17.8091892 },
    { lat: 48.8380388, lng: 17.8091451 },
    { lat: 48.8381228, lng: 17.808968 },
    { lat: 48.8381699, lng: 17.8088512 },
    { lat: 48.8384269, lng: 17.8086947 },
    { lat: 48.8388048, lng: 17.8086356 },
    { lat: 48.8391001, lng: 17.8079043 },
    { lat: 48.8393902, lng: 17.8076248 },
    { lat: 48.8397637, lng: 17.8069921 },
    { lat: 48.8400189, lng: 17.8066024 },
    { lat: 48.8400659, lng: 17.8065403 },
    { lat: 48.8404486, lng: 17.8060566 },
    { lat: 48.8405857, lng: 17.805657 },
    { lat: 48.8409282, lng: 17.8049973 },
    { lat: 48.8414496, lng: 17.8047159 },
    { lat: 48.8418664, lng: 17.8050156 },
    { lat: 48.8421871, lng: 17.8052218 },
    { lat: 48.8428674, lng: 17.8052709 },
    { lat: 48.8429119, lng: 17.8052774 },
    { lat: 48.84344, lng: 17.8054427 },
    { lat: 48.8437442, lng: 17.8055623 },
    { lat: 48.8441232, lng: 17.8055945 },
    { lat: 48.8443945, lng: 17.8056559 },
    { lat: 48.844691, lng: 17.8056666 },
    { lat: 48.8447063, lng: 17.805641 },
    { lat: 48.8454629, lng: 17.8041229 },
    { lat: 48.8458699, lng: 17.8031481 },
    { lat: 48.8459861, lng: 17.8027765 },
    { lat: 48.84629, lng: 17.8024274 },
    { lat: 48.8467528, lng: 17.8023696 },
    { lat: 48.8468322, lng: 17.8023597 },
    { lat: 48.8475696, lng: 17.802066 },
    { lat: 48.8478874, lng: 17.8020383 },
    { lat: 48.848538, lng: 17.8019868 },
    { lat: 48.8486797, lng: 17.801869 },
    { lat: 48.8495956, lng: 17.8017629 },
    { lat: 48.850091, lng: 17.8017023 },
    { lat: 48.8501279, lng: 17.8014183 },
    { lat: 48.8501639, lng: 17.8014346 },
    { lat: 48.8501781, lng: 17.8012799 },
    { lat: 48.850239, lng: 17.8011855 },
    { lat: 48.8504482, lng: 17.8008765 },
    { lat: 48.8506186, lng: 17.800505 },
    { lat: 48.8507827, lng: 17.8001593 },
    { lat: 48.8508237, lng: 17.8000709 },
    { lat: 48.8508576, lng: 17.7999986 },
    { lat: 48.8508985, lng: 17.7999095 },
    { lat: 48.850899, lng: 17.7998064 },
    { lat: 48.8509016, lng: 17.7997017 },
    { lat: 48.8508917, lng: 17.799613 },
    { lat: 48.8508822, lng: 17.7995295 },
    { lat: 48.8507284, lng: 17.7989694 },
    { lat: 48.8506058, lng: 17.7984379 },
    { lat: 48.8505296, lng: 17.7981898 },
    { lat: 48.850376, lng: 17.7975766 },
    { lat: 48.8503472, lng: 17.7973442 },
    { lat: 48.8503264, lng: 17.7972071 },
    { lat: 48.8502997, lng: 17.7970288 },
    { lat: 48.8502735, lng: 17.7968566 },
    { lat: 48.8502599, lng: 17.7966888 },
    { lat: 48.8502542, lng: 17.7968565 },
    { lat: 48.8502614, lng: 17.7973437 },
    { lat: 48.8502253, lng: 17.7974733 },
    { lat: 48.8501576, lng: 17.7976225 },
    { lat: 48.8500812, lng: 17.7977415 },
    { lat: 48.8499303, lng: 17.7978947 },
    { lat: 48.8497172, lng: 17.7978988 },
    { lat: 48.8493787, lng: 17.7980313 },
    { lat: 48.8487577, lng: 17.798476 },
    { lat: 48.8483924, lng: 17.7988717 },
    { lat: 48.8482217, lng: 17.7990542 },
    { lat: 48.8480861, lng: 17.7991823 },
    { lat: 48.8480188, lng: 17.7992445 },
    { lat: 48.8475107, lng: 17.7995949 },
    { lat: 48.8472855, lng: 17.7996422 },
    { lat: 48.8471128, lng: 17.799624 },
    { lat: 48.8467721, lng: 17.7998127 },
    { lat: 48.8466063, lng: 17.7999638 },
    { lat: 48.8457868, lng: 17.8007256 },
    { lat: 48.8450434, lng: 17.8012345 },
    { lat: 48.8442133, lng: 17.8018611 },
    { lat: 48.8438403, lng: 17.802002 },
    { lat: 48.8437013, lng: 17.8019992 },
    { lat: 48.8434662, lng: 17.8019007 },
    { lat: 48.8432179, lng: 17.8020329 },
    { lat: 48.8422505, lng: 17.8030737 },
    { lat: 48.8419378, lng: 17.8034817 },
    { lat: 48.8416534, lng: 17.8036309 },
    { lat: 48.841337, lng: 17.8037374 },
    { lat: 48.841305, lng: 17.8037484 },
    { lat: 48.8411499, lng: 17.8037987 },
    { lat: 48.840964, lng: 17.803932 },
    { lat: 48.8405952, lng: 17.8043096 },
    { lat: 48.8395013, lng: 17.8043111 },
    { lat: 48.8387506, lng: 17.8045894 },
    { lat: 48.8382093, lng: 17.8049699 },
    { lat: 48.8378909, lng: 17.8051545 },
    { lat: 48.8371697, lng: 17.8056041 },
    { lat: 48.8367619, lng: 17.8055983 },
    { lat: 48.8366412, lng: 17.8055813 },
    { lat: 48.8364735, lng: 17.8057013 },
    { lat: 48.836087, lng: 17.8060845 },
    { lat: 48.8352247, lng: 17.8067179 },
    { lat: 48.8349291, lng: 17.8070365 },
    { lat: 48.8345544, lng: 17.8075749 },
    { lat: 48.8343119, lng: 17.807876 },
    { lat: 48.8335886, lng: 17.8086609 },
    { lat: 48.8327251, lng: 17.8087754 },
    { lat: 48.8323495, lng: 17.8089104 },
    { lat: 48.8314574, lng: 17.8094819 },
    { lat: 48.8310521, lng: 17.8096479 },
    { lat: 48.8305151, lng: 17.8099322 },
    { lat: 48.829463, lng: 17.8106215 },
    { lat: 48.8292004, lng: 17.8107561 },
    { lat: 48.8291027, lng: 17.810803 },
    { lat: 48.8288697, lng: 17.8110539 },
    { lat: 48.8284045, lng: 17.8119998 },
    { lat: 48.8280836, lng: 17.8132966 },
    { lat: 48.8280389, lng: 17.8134516 },
    { lat: 48.827562, lng: 17.8143541 },
    { lat: 48.827082, lng: 17.8153913 },
    { lat: 48.826846, lng: 17.8158734 },
    { lat: 48.8262763, lng: 17.8165868 },
    { lat: 48.8259679, lng: 17.8173264 },
    { lat: 48.8259768, lng: 17.8173422 },
    { lat: 48.8258342, lng: 17.817596 },
    { lat: 48.8253025, lng: 17.8184898 },
    { lat: 48.8251935, lng: 17.8186465 },
    { lat: 48.8248906, lng: 17.8190671 },
    { lat: 48.8247378, lng: 17.81934 },
    { lat: 48.8247138, lng: 17.8193703 },
    { lat: 48.8243191, lng: 17.8197711 },
    { lat: 48.8236287, lng: 17.820019 },
    { lat: 48.8218342, lng: 17.8202304 },
    { lat: 48.8219682, lng: 17.8194625 },
    { lat: 48.8215614, lng: 17.8197183 },
    { lat: 48.8207527, lng: 17.820139 },
    { lat: 48.8207812, lng: 17.8202355 },
    { lat: 48.8207461, lng: 17.8202524 },
    { lat: 48.8197005, lng: 17.8207878 },
    { lat: 48.8188168, lng: 17.8212185 },
    { lat: 48.818216, lng: 17.8215121 },
    { lat: 48.8181701, lng: 17.821389 },
    { lat: 48.8181174, lng: 17.8212459 },
    { lat: 48.8180687, lng: 17.8211129 },
    { lat: 48.8180511, lng: 17.8209721 },
    { lat: 48.8180321, lng: 17.8208279 },
    { lat: 48.8180121, lng: 17.8206648 },
    { lat: 48.8178788, lng: 17.820664 },
    { lat: 48.817594, lng: 17.8206012 },
    { lat: 48.8173025, lng: 17.8204674 },
    { lat: 48.816606, lng: 17.8200781 },
    { lat: 48.8165078, lng: 17.8200601 },
    { lat: 48.8164486, lng: 17.8201435 },
  ],
  NováBošáca: [
    { lat: 48.8502599, lng: 17.7966888 },
    { lat: 48.8502735, lng: 17.7968566 },
    { lat: 48.8502997, lng: 17.7970288 },
    { lat: 48.8503264, lng: 17.7972071 },
    { lat: 48.8503472, lng: 17.7973442 },
    { lat: 48.850376, lng: 17.7975766 },
    { lat: 48.8505296, lng: 17.7981898 },
    { lat: 48.8506058, lng: 17.7984379 },
    { lat: 48.8507284, lng: 17.7989694 },
    { lat: 48.8508822, lng: 17.7995295 },
    { lat: 48.8508917, lng: 17.799613 },
    { lat: 48.8509016, lng: 17.7997017 },
    { lat: 48.850899, lng: 17.7998064 },
    { lat: 48.8508985, lng: 17.7999095 },
    { lat: 48.8508576, lng: 17.7999986 },
    { lat: 48.8508237, lng: 17.8000709 },
    { lat: 48.8507827, lng: 17.8001593 },
    { lat: 48.8506186, lng: 17.800505 },
    { lat: 48.8504482, lng: 17.8008765 },
    { lat: 48.850239, lng: 17.8011855 },
    { lat: 48.8501781, lng: 17.8012799 },
    { lat: 48.8501639, lng: 17.8014346 },
    { lat: 48.8502288, lng: 17.801464 },
    { lat: 48.8502892, lng: 17.8014923 },
    { lat: 48.8507188, lng: 17.8016868 },
    { lat: 48.8512742, lng: 17.8017431 },
    { lat: 48.8519529, lng: 17.8016157 },
    { lat: 48.8525252, lng: 17.8013378 },
    { lat: 48.85345, lng: 17.8009318 },
    { lat: 48.8536518, lng: 17.8022076 },
    { lat: 48.8538531, lng: 17.8037186 },
    { lat: 48.8543794, lng: 17.8034229 },
    { lat: 48.8549631, lng: 17.8031078 },
    { lat: 48.8550085, lng: 17.8031882 },
    { lat: 48.8554076, lng: 17.803067 },
    { lat: 48.8558092, lng: 17.8027646 },
    { lat: 48.8558356, lng: 17.8027365 },
    { lat: 48.8567582, lng: 17.8030742 },
    { lat: 48.8569367, lng: 17.802983 },
    { lat: 48.8573349, lng: 17.8026446 },
    { lat: 48.8580459, lng: 17.8019362 },
    { lat: 48.8580702, lng: 17.8019093 },
    { lat: 48.8581129, lng: 17.8018682 },
    { lat: 48.8582807, lng: 17.8020848 },
    { lat: 48.8583429, lng: 17.8022339 },
    { lat: 48.8585456, lng: 17.8027103 },
    { lat: 48.8588406, lng: 17.8031418 },
    { lat: 48.858907, lng: 17.8032865 },
    { lat: 48.85903, lng: 17.803564 },
    { lat: 48.8594126, lng: 17.803025 },
    { lat: 48.8598459, lng: 17.8022044 },
    { lat: 48.8602727, lng: 17.8015565 },
    { lat: 48.8606272, lng: 17.8018535 },
    { lat: 48.8607848, lng: 17.8019581 },
    { lat: 48.8608413, lng: 17.801997 },
    { lat: 48.8609744, lng: 17.802087 },
    { lat: 48.8612705, lng: 17.8022209 },
    { lat: 48.8613392, lng: 17.8022554 },
    { lat: 48.8616689, lng: 17.8024066 },
    { lat: 48.8619231, lng: 17.8025207 },
    { lat: 48.8621679, lng: 17.8025563 },
    { lat: 48.8622557, lng: 17.8025696 },
    { lat: 48.8625235, lng: 17.8026652 },
    { lat: 48.8631053, lng: 17.8026169 },
    { lat: 48.8637768, lng: 17.8022612 },
    { lat: 48.8640383, lng: 17.8020869 },
    { lat: 48.8640183, lng: 17.8017583 },
    { lat: 48.8643301, lng: 17.8013545 },
    { lat: 48.8647275, lng: 17.8011129 },
    { lat: 48.8647384, lng: 17.8011074 },
    { lat: 48.8649087, lng: 17.8010687 },
    { lat: 48.864996, lng: 17.8010372 },
    { lat: 48.8651193, lng: 17.8009862 },
    { lat: 48.865384, lng: 17.8010304 },
    { lat: 48.8654319, lng: 17.8010376 },
    { lat: 48.8658011, lng: 17.8007968 },
    { lat: 48.866174, lng: 17.8006176 },
    { lat: 48.866151, lng: 17.8004069 },
    { lat: 48.8669816, lng: 17.7997527 },
    { lat: 48.8670606, lng: 17.7999393 },
    { lat: 48.8672104, lng: 17.8000254 },
    { lat: 48.8677712, lng: 17.7999289 },
    { lat: 48.8680166, lng: 17.7999361 },
    { lat: 48.868069, lng: 17.7999069 },
    { lat: 48.8683473, lng: 17.7997501 },
    { lat: 48.8685126, lng: 17.7996091 },
    { lat: 48.8689135, lng: 17.7992765 },
    { lat: 48.8690155, lng: 17.7991906 },
    { lat: 48.8691971, lng: 17.7990387 },
    { lat: 48.8693865, lng: 17.7988872 },
    { lat: 48.8700484, lng: 17.7999685 },
    { lat: 48.8702301, lng: 17.800265 },
    { lat: 48.8703751, lng: 17.8004957 },
    { lat: 48.8704052, lng: 17.800546 },
    { lat: 48.8704776, lng: 17.8006598 },
    { lat: 48.8706075, lng: 17.8008647 },
    { lat: 48.8707026, lng: 17.8010182 },
    { lat: 48.8708113, lng: 17.80119 },
    { lat: 48.8709325, lng: 17.8013817 },
    { lat: 48.8708463, lng: 17.8013934 },
    { lat: 48.8706409, lng: 17.8014311 },
    { lat: 48.8703564, lng: 17.8014872 },
    { lat: 48.8701224, lng: 17.8015387 },
    { lat: 48.8699857, lng: 17.8015543 },
    { lat: 48.8696956, lng: 17.8019441 },
    { lat: 48.869292, lng: 17.8023447 },
    { lat: 48.8691644, lng: 17.8026582 },
    { lat: 48.8693336, lng: 17.8024908 },
    { lat: 48.8697073, lng: 17.8020462 },
    { lat: 48.8697794, lng: 17.8021163 },
    { lat: 48.8699733, lng: 17.8019275 },
    { lat: 48.870033, lng: 17.8020726 },
    { lat: 48.8698614, lng: 17.8022763 },
    { lat: 48.8700913, lng: 17.8026596 },
    { lat: 48.8701691, lng: 17.8027882 },
    { lat: 48.8703251, lng: 17.8030707 },
    { lat: 48.870299, lng: 17.803105 },
    { lat: 48.8703497, lng: 17.8032524 },
    { lat: 48.8706074, lng: 17.8040181 },
    { lat: 48.8708963, lng: 17.8038119 },
    { lat: 48.8710611, lng: 17.8037006 },
    { lat: 48.8710903, lng: 17.8036799 },
    { lat: 48.871223, lng: 17.8035787 },
    { lat: 48.8716895, lng: 17.8032307 },
    { lat: 48.8719573, lng: 17.8030307 },
    { lat: 48.872005, lng: 17.8029989 },
    { lat: 48.8721892, lng: 17.8028537 },
    { lat: 48.8722129, lng: 17.8028714 },
    { lat: 48.8722552, lng: 17.8036958 },
    { lat: 48.8722289, lng: 17.8040449 },
    { lat: 48.8722299, lng: 17.8046719 },
    { lat: 48.8722736, lng: 17.8049452 },
    { lat: 48.8722688, lng: 17.8053113 },
    { lat: 48.8722969, lng: 17.8058309 },
    { lat: 48.8722826, lng: 17.8064119 },
    { lat: 48.8723354, lng: 17.8077077 },
    { lat: 48.8724006, lng: 17.808001 },
    { lat: 48.8724703, lng: 17.8083674 },
    { lat: 48.8725299, lng: 17.8087724 },
    { lat: 48.872369, lng: 17.809227 },
    { lat: 48.8723987, lng: 17.8095497 },
    { lat: 48.8722728, lng: 17.8098 },
    { lat: 48.8716396, lng: 17.8105635 },
    { lat: 48.8713314, lng: 17.8110822 },
    { lat: 48.8707567, lng: 17.8117139 },
    { lat: 48.8703375, lng: 17.8122092 },
    { lat: 48.8700129, lng: 17.8128873 },
    { lat: 48.8697347, lng: 17.8133616 },
    { lat: 48.8693194, lng: 17.8138461 },
    { lat: 48.8690272, lng: 17.8143724 },
    { lat: 48.868851, lng: 17.8145361 },
    { lat: 48.8683668, lng: 17.8148543 },
    { lat: 48.868373, lng: 17.8148816 },
    { lat: 48.8683982, lng: 17.8149813 },
    { lat: 48.8684178, lng: 17.8150623 },
    { lat: 48.8685308, lng: 17.8155466 },
    { lat: 48.868581, lng: 17.8157617 },
    { lat: 48.8685982, lng: 17.8159698 },
    { lat: 48.8685794, lng: 17.8160148 },
    { lat: 48.8685256, lng: 17.8161515 },
    { lat: 48.868339, lng: 17.8163678 },
    { lat: 48.8682979, lng: 17.8164164 },
    { lat: 48.8682601, lng: 17.8164627 },
    { lat: 48.8682754, lng: 17.8168464 },
    { lat: 48.8683648, lng: 17.817109 },
    { lat: 48.8684335, lng: 17.8173117 },
    { lat: 48.8685187, lng: 17.8175603 },
    { lat: 48.8686535, lng: 17.8179029 },
    { lat: 48.8687705, lng: 17.8181758 },
    { lat: 48.8689892, lng: 17.8182818 },
    { lat: 48.8690206, lng: 17.8182989 },
    { lat: 48.8690485, lng: 17.818312 },
    { lat: 48.8692422, lng: 17.818173 },
    { lat: 48.8693407, lng: 17.818028 },
    { lat: 48.8694829, lng: 17.8177873 },
    { lat: 48.8695357, lng: 17.8177082 },
    { lat: 48.8696681, lng: 17.8175831 },
    { lat: 48.8696894, lng: 17.8175627 },
    { lat: 48.8697129, lng: 17.8175406 },
    { lat: 48.8698806, lng: 17.8175124 },
    { lat: 48.8699408, lng: 17.8177234 },
    { lat: 48.8699971, lng: 17.8178696 },
    { lat: 48.8700367, lng: 17.8179835 },
    { lat: 48.8700383, lng: 17.8183064 },
    { lat: 48.8702772, lng: 17.8183752 },
    { lat: 48.8703151, lng: 17.8188508 },
    { lat: 48.8704585, lng: 17.8187948 },
    { lat: 48.8705504, lng: 17.8185044 },
    { lat: 48.870819, lng: 17.8183553 },
    { lat: 48.8711021, lng: 17.8182948 },
    { lat: 48.8711058, lng: 17.8183924 },
    { lat: 48.8718309, lng: 17.818437 },
    { lat: 48.8723025, lng: 17.8183941 },
    { lat: 48.8724039, lng: 17.8182797 },
    { lat: 48.8725538, lng: 17.8182764 },
    { lat: 48.8728251, lng: 17.8184135 },
    { lat: 48.8729118, lng: 17.8185567 },
    { lat: 48.8730962, lng: 17.8186192 },
    { lat: 48.8731055, lng: 17.818997 },
    { lat: 48.8731621, lng: 17.8190352 },
    { lat: 48.8734946, lng: 17.8192696 },
    { lat: 48.8738594, lng: 17.8195707 },
    { lat: 48.8741548, lng: 17.8200465 },
    { lat: 48.8743163, lng: 17.8202721 },
    { lat: 48.8744359, lng: 17.820421 },
    { lat: 48.8744775, lng: 17.8205097 },
    { lat: 48.8745428, lng: 17.8206187 },
    { lat: 48.8745475, lng: 17.820716 },
    { lat: 48.8749161, lng: 17.8210596 },
    { lat: 48.8749862, lng: 17.8211379 },
    { lat: 48.8752347, lng: 17.8213986 },
    { lat: 48.8753436, lng: 17.821519 },
    { lat: 48.875861, lng: 17.8220472 },
    { lat: 48.8758344, lng: 17.8222781 },
    { lat: 48.8760006, lng: 17.8223769 },
    { lat: 48.8762301, lng: 17.8223449 },
    { lat: 48.8763704, lng: 17.8223303 },
    { lat: 48.8765805, lng: 17.8217359 },
    { lat: 48.8765919, lng: 17.8216459 },
    { lat: 48.8766252, lng: 17.8214131 },
    { lat: 48.876632, lng: 17.8213564 },
    { lat: 48.8765237, lng: 17.8213399 },
    { lat: 48.8765114, lng: 17.8208215 },
    { lat: 48.8765929, lng: 17.8200428 },
    { lat: 48.8766008, lng: 17.8199858 },
    { lat: 48.8767662, lng: 17.820052 },
    { lat: 48.8772147, lng: 17.8201505 },
    { lat: 48.8773716, lng: 17.8198166 },
    { lat: 48.8775325, lng: 17.8195661 },
    { lat: 48.8777463, lng: 17.8195359 },
    { lat: 48.8779261, lng: 17.8196157 },
    { lat: 48.8780804, lng: 17.8195158 },
    { lat: 48.8782355, lng: 17.8191322 },
    { lat: 48.8780644, lng: 17.8187683 },
    { lat: 48.8781315, lng: 17.8184039 },
    { lat: 48.8783399, lng: 17.8185473 },
    { lat: 48.8785023, lng: 17.8186936 },
    { lat: 48.8785076, lng: 17.8186694 },
    { lat: 48.8785353, lng: 17.8186038 },
    { lat: 48.8785634, lng: 17.8185452 },
    { lat: 48.878353, lng: 17.8182591 },
    { lat: 48.8782096, lng: 17.8179053 },
    { lat: 48.8784331, lng: 17.8177 },
    { lat: 48.8785705, lng: 17.8175832 },
    { lat: 48.8789895, lng: 17.8172334 },
    { lat: 48.8791211, lng: 17.8170768 },
    { lat: 48.8793956, lng: 17.8168751 },
    { lat: 48.8794845, lng: 17.8168507 },
    { lat: 48.8800059, lng: 17.8167001 },
    { lat: 48.8801363, lng: 17.8166904 },
    { lat: 48.8806061, lng: 17.8166578 },
    { lat: 48.8807313, lng: 17.816654 },
    { lat: 48.8807408, lng: 17.8174251 },
    { lat: 48.8807697, lng: 17.8174185 },
    { lat: 48.8808994, lng: 17.817418 },
    { lat: 48.8810299, lng: 17.8174082 },
    { lat: 48.8810693, lng: 17.8170729 },
    { lat: 48.8812046, lng: 17.8170151 },
    { lat: 48.8813319, lng: 17.8165263 },
    { lat: 48.8815748, lng: 17.8161007 },
    { lat: 48.8817271, lng: 17.8158216 },
    { lat: 48.8819381, lng: 17.8156709 },
    { lat: 48.8823878, lng: 17.8153973 },
    { lat: 48.8827972, lng: 17.8152058 },
    { lat: 48.8831569, lng: 17.8153285 },
    { lat: 48.8835801, lng: 17.815494 },
    { lat: 48.8836035, lng: 17.8154703 },
    { lat: 48.8836351, lng: 17.8153974 },
    { lat: 48.8836663, lng: 17.8153519 },
    { lat: 48.8840358, lng: 17.8151528 },
    { lat: 48.8840536, lng: 17.814832 },
    { lat: 48.8841904, lng: 17.8147978 },
    { lat: 48.8845376, lng: 17.8149574 },
    { lat: 48.8848637, lng: 17.8152901 },
    { lat: 48.8850921, lng: 17.8154071 },
    { lat: 48.884989, lng: 17.8156244 },
    { lat: 48.8849466, lng: 17.8157065 },
    { lat: 48.8851865, lng: 17.8159033 },
    { lat: 48.8851981, lng: 17.8159264 },
    { lat: 48.885393, lng: 17.8161805 },
    { lat: 48.8855243, lng: 17.8163548 },
    { lat: 48.8855737, lng: 17.8164783 },
    { lat: 48.8856226, lng: 17.8165794 },
    { lat: 48.8857206, lng: 17.816593 },
    { lat: 48.8858875, lng: 17.8166092 },
    { lat: 48.8861343, lng: 17.8164531 },
    { lat: 48.8861973, lng: 17.8164499 },
    { lat: 48.8864259, lng: 17.816422 },
    { lat: 48.8866145, lng: 17.816387 },
    { lat: 48.8870127, lng: 17.8162883 },
    { lat: 48.887534, lng: 17.8161727 },
    { lat: 48.8875947, lng: 17.816112 },
    { lat: 48.8877419, lng: 17.8159707 },
    { lat: 48.8878759, lng: 17.8158538 },
    { lat: 48.8880121, lng: 17.8157358 },
    { lat: 48.8882558, lng: 17.8155649 },
    { lat: 48.8885546, lng: 17.815375 },
    { lat: 48.8885286, lng: 17.8151304 },
    { lat: 48.8884668, lng: 17.8146354 },
    { lat: 48.8884501, lng: 17.814509 },
    { lat: 48.88846, lng: 17.8145042 },
    { lat: 48.8887173, lng: 17.8143522 },
    { lat: 48.8890514, lng: 17.8143719 },
    { lat: 48.8889796, lng: 17.8147312 },
    { lat: 48.8892058, lng: 17.8150359 },
    { lat: 48.8892149, lng: 17.8150556 },
    { lat: 48.8895332, lng: 17.8156507 },
    { lat: 48.889556, lng: 17.8157099 },
    { lat: 48.8896116, lng: 17.8164027 },
    { lat: 48.8895613, lng: 17.8165233 },
    { lat: 48.889423, lng: 17.8166063 },
    { lat: 48.8895133, lng: 17.8170337 },
    { lat: 48.8894979, lng: 17.8174317 },
    { lat: 48.8895626, lng: 17.8177154 },
    { lat: 48.8895967, lng: 17.8178511 },
    { lat: 48.8896715, lng: 17.8181355 },
    { lat: 48.889661, lng: 17.8182602 },
    { lat: 48.8897842, lng: 17.8186898 },
    { lat: 48.8903021, lng: 17.8182769 },
    { lat: 48.8902961, lng: 17.8181219 },
    { lat: 48.8904488, lng: 17.8177729 },
    { lat: 48.8906269, lng: 17.8173041 },
    { lat: 48.8906444, lng: 17.8170141 },
    { lat: 48.8906553, lng: 17.8168987 },
    { lat: 48.890724, lng: 17.8165785 },
    { lat: 48.890802, lng: 17.8161708 },
    { lat: 48.890855, lng: 17.8159464 },
    { lat: 48.8909856, lng: 17.815493 },
    { lat: 48.8908806, lng: 17.8154011 },
    { lat: 48.890848, lng: 17.8153662 },
    { lat: 48.8909613, lng: 17.8149079 },
    { lat: 48.8909956, lng: 17.8147861 },
    { lat: 48.891003, lng: 17.8147578 },
    { lat: 48.8912727, lng: 17.8137155 },
    { lat: 48.8915617, lng: 17.8136022 },
    { lat: 48.8915844, lng: 17.8136053 },
    { lat: 48.8916192, lng: 17.8136198 },
    { lat: 48.8918558, lng: 17.8137242 },
    { lat: 48.8920342, lng: 17.8138562 },
    { lat: 48.8921112, lng: 17.8139137 },
    { lat: 48.8922606, lng: 17.8140139 },
    { lat: 48.8925348, lng: 17.81405 },
    { lat: 48.8926188, lng: 17.814058 },
    { lat: 48.8928566, lng: 17.8140684 },
    { lat: 48.8931122, lng: 17.8140957 },
    { lat: 48.8931433, lng: 17.8140871 },
    { lat: 48.8932414, lng: 17.8139737 },
    { lat: 48.8934535, lng: 17.8140657 },
    { lat: 48.8936005, lng: 17.8139938 },
    { lat: 48.8938184, lng: 17.81407 },
    { lat: 48.8940159, lng: 17.8142546 },
    { lat: 48.8940564, lng: 17.8141223 },
    { lat: 48.894342, lng: 17.8141399 },
    { lat: 48.8943807, lng: 17.814145 },
    { lat: 48.8944178, lng: 17.8141426 },
    { lat: 48.8945475, lng: 17.8142904 },
    { lat: 48.8948244, lng: 17.814592 },
    { lat: 48.8948761, lng: 17.8147376 },
    { lat: 48.8947976, lng: 17.8148026 },
    { lat: 48.8949629, lng: 17.8150696 },
    { lat: 48.8950262, lng: 17.8151268 },
    { lat: 48.8951181, lng: 17.8158788 },
    { lat: 48.8951622, lng: 17.8162334 },
    { lat: 48.8951669, lng: 17.8165898 },
    { lat: 48.8951292, lng: 17.8168241 },
    { lat: 48.8951162, lng: 17.8170026 },
    { lat: 48.8950779, lng: 17.8175057 },
    { lat: 48.895083, lng: 17.8177002 },
    { lat: 48.8950819, lng: 17.8178501 },
    { lat: 48.8950815, lng: 17.817918 },
    { lat: 48.8950809, lng: 17.818206 },
    { lat: 48.8950833, lng: 17.8186179 },
    { lat: 48.8950945, lng: 17.8187101 },
    { lat: 48.8951384, lng: 17.8192117 },
    { lat: 48.8950837, lng: 17.8199083 },
    { lat: 48.8951939, lng: 17.8201631 },
    { lat: 48.8955938, lng: 17.8198878 },
    { lat: 48.8957581, lng: 17.8199915 },
    { lat: 48.8959604, lng: 17.8202561 },
    { lat: 48.8961666, lng: 17.8206584 },
    { lat: 48.8961899, lng: 17.8203173 },
    { lat: 48.8961976, lng: 17.8201451 },
    { lat: 48.896134, lng: 17.8199348 },
    { lat: 48.8959475, lng: 17.8192987 },
    { lat: 48.8959594, lng: 17.8190663 },
    { lat: 48.8960021, lng: 17.8188386 },
    { lat: 48.8961291, lng: 17.8184216 },
    { lat: 48.8962474, lng: 17.8179706 },
    { lat: 48.8964152, lng: 17.8175547 },
    { lat: 48.8964682, lng: 17.8175353 },
    { lat: 48.8964921, lng: 17.8175944 },
    { lat: 48.8965873, lng: 17.8178611 },
    { lat: 48.8967676, lng: 17.8181723 },
    { lat: 48.8967672, lng: 17.8186497 },
    { lat: 48.8968936, lng: 17.8193943 },
    { lat: 48.8975019, lng: 17.8202285 },
    { lat: 48.8975152, lng: 17.8202618 },
    { lat: 48.8975924, lng: 17.8206029 },
    { lat: 48.8978074, lng: 17.8209848 },
    { lat: 48.8980511, lng: 17.8218384 },
    { lat: 48.8981766, lng: 17.8223638 },
    { lat: 48.8982159, lng: 17.8224901 },
    { lat: 48.8981933, lng: 17.8229918 },
    { lat: 48.8981866, lng: 17.8230869 },
    { lat: 48.8981691, lng: 17.8234494 },
    { lat: 48.8980556, lng: 17.8239254 },
    { lat: 48.8979697, lng: 17.8242007 },
    { lat: 48.8978985, lng: 17.8246848 },
    { lat: 48.8977019, lng: 17.825987 },
    { lat: 48.8976835, lng: 17.8261112 },
    { lat: 48.8975516, lng: 17.8270096 },
    { lat: 48.8973586, lng: 17.8283145 },
    { lat: 48.8973232, lng: 17.8285874 },
    { lat: 48.8969785, lng: 17.8309105 },
    { lat: 48.8969717, lng: 17.8309654 },
    { lat: 48.8969479, lng: 17.8310769 },
    { lat: 48.8969049, lng: 17.8315782 },
    { lat: 48.8968636, lng: 17.8319413 },
    { lat: 48.8968039, lng: 17.8324277 },
    { lat: 48.896742, lng: 17.8329343 },
    { lat: 48.896685, lng: 17.8333367 },
    { lat: 48.8967598, lng: 17.8335078 },
    { lat: 48.896721, lng: 17.833547 },
    { lat: 48.896828, lng: 17.833672 },
    { lat: 48.897091, lng: 17.834004 },
    { lat: 48.89726, lng: 17.834106 },
    { lat: 48.897425, lng: 17.834187 },
    { lat: 48.897529, lng: 17.834246 },
    { lat: 48.897746, lng: 17.834332 },
    { lat: 48.898303, lng: 17.834472 },
    { lat: 48.898792, lng: 17.834777 },
    { lat: 48.899064, lng: 17.834995 },
    { lat: 48.899283, lng: 17.835096 },
    { lat: 48.899717, lng: 17.835145 },
    { lat: 48.90007, lng: 17.835331 },
    { lat: 48.900226, lng: 17.835544 },
    { lat: 48.90031, lng: 17.8358 },
    { lat: 48.900417, lng: 17.835953 },
    { lat: 48.900585, lng: 17.836111 },
    { lat: 48.900781, lng: 17.836273 },
    { lat: 48.900921, lng: 17.836334 },
    { lat: 48.901012, lng: 17.836401 },
    { lat: 48.901077, lng: 17.83651 },
    { lat: 48.901257, lng: 17.836729 },
    { lat: 48.901457, lng: 17.836906 },
    { lat: 48.901599, lng: 17.837202 },
    { lat: 48.901645, lng: 17.837274 },
    { lat: 48.901686, lng: 17.837326 },
    { lat: 48.901886, lng: 17.83744 },
    { lat: 48.901954, lng: 17.83757 },
    { lat: 48.902059, lng: 17.837822 },
    { lat: 48.902337, lng: 17.83803 },
    { lat: 48.90249, lng: 17.838322 },
    { lat: 48.902661, lng: 17.838988 },
    { lat: 48.902689, lng: 17.839296 },
    { lat: 48.90277, lng: 17.839701 },
    { lat: 48.902806, lng: 17.839959 },
    { lat: 48.902825, lng: 17.840284 },
    { lat: 48.902926, lng: 17.840672 },
    { lat: 48.903085, lng: 17.841365 },
    { lat: 48.903074, lng: 17.841806 },
    { lat: 48.903062, lng: 17.841806 },
    { lat: 48.903091, lng: 17.842374 },
    { lat: 48.903264, lng: 17.842956 },
    { lat: 48.903293, lng: 17.842961 },
    { lat: 48.903314, lng: 17.843232 },
    { lat: 48.903257, lng: 17.843601 },
    { lat: 48.903324, lng: 17.844097 },
    { lat: 48.903295, lng: 17.844494 },
    { lat: 48.903246, lng: 17.844918 },
    { lat: 48.903047, lng: 17.845478 },
    { lat: 48.903024, lng: 17.845523 },
    { lat: 48.903045, lng: 17.845562 },
    { lat: 48.903066, lng: 17.845571 },
    { lat: 48.903568, lng: 17.845738 },
    { lat: 48.904226, lng: 17.84594 },
    { lat: 48.905294, lng: 17.845913 },
    { lat: 48.905863, lng: 17.845783 },
    { lat: 48.906301, lng: 17.845647 },
    { lat: 48.907065, lng: 17.845427 },
    { lat: 48.907437, lng: 17.845351 },
    { lat: 48.907966, lng: 17.845512 },
    { lat: 48.908487, lng: 17.845727 },
    { lat: 48.909724, lng: 17.845469 },
    { lat: 48.909987, lng: 17.845352 },
    { lat: 48.910221, lng: 17.845011 },
    { lat: 48.91082, lng: 17.844775 },
    { lat: 48.91113, lng: 17.844527 },
    { lat: 48.911712, lng: 17.843979 },
    { lat: 48.912341, lng: 17.843544 },
    { lat: 48.912661, lng: 17.843315 },
    { lat: 48.913105, lng: 17.843062 },
    { lat: 48.913671, lng: 17.843071 },
    { lat: 48.914487, lng: 17.842897 },
    { lat: 48.914814, lng: 17.842592 },
    { lat: 48.91533, lng: 17.842376 },
    { lat: 48.915696, lng: 17.842266 },
    { lat: 48.916115, lng: 17.842372 },
    { lat: 48.916502, lng: 17.84254 },
    { lat: 48.916562, lng: 17.842566 },
    { lat: 48.917131, lng: 17.843186 },
    { lat: 48.917979, lng: 17.843726 },
    { lat: 48.918291, lng: 17.843826 },
    { lat: 48.918549, lng: 17.843994 },
    { lat: 48.919171, lng: 17.844282 },
    { lat: 48.91975, lng: 17.844596 },
    { lat: 48.920512, lng: 17.844859 },
    { lat: 48.921413, lng: 17.845661 },
    { lat: 48.921629, lng: 17.84573 },
    { lat: 48.921794, lng: 17.845809 },
    { lat: 48.922476, lng: 17.846136 },
    { lat: 48.9226838, lng: 17.8461405 },
    { lat: 48.923126, lng: 17.84615 },
    { lat: 48.923452, lng: 17.846009 },
    { lat: 48.923847, lng: 17.845834 },
    { lat: 48.923859, lng: 17.845829 },
    { lat: 48.923899, lng: 17.845813 },
    { lat: 48.924065, lng: 17.845734 },
    { lat: 48.924527, lng: 17.84554 },
    { lat: 48.925167, lng: 17.845293 },
    { lat: 48.925366, lng: 17.845206 },
    { lat: 48.925678, lng: 17.844747 },
    { lat: 48.92574, lng: 17.844712 },
    { lat: 48.925802, lng: 17.844673 },
    { lat: 48.92582, lng: 17.844662 },
    { lat: 48.92608, lng: 17.844346 },
    { lat: 48.926355, lng: 17.844248 },
    { lat: 48.926401, lng: 17.844223 },
    { lat: 48.926419, lng: 17.843824 },
    { lat: 48.92649, lng: 17.842317 },
    { lat: 48.926524, lng: 17.841615 },
    { lat: 48.926579, lng: 17.840507 },
    { lat: 48.926596, lng: 17.84009 },
    { lat: 48.926598, lng: 17.840035 },
    { lat: 48.926607, lng: 17.839795 },
    { lat: 48.926628, lng: 17.839303 },
    { lat: 48.926688, lng: 17.837901 },
    { lat: 48.926715, lng: 17.837252 },
    { lat: 48.926811, lng: 17.835436 },
    { lat: 48.926851, lng: 17.834513 },
    { lat: 48.926876, lng: 17.833963 },
    { lat: 48.926929, lng: 17.832602 },
    { lat: 48.926966, lng: 17.831898 },
    { lat: 48.927028, lng: 17.830769 },
    { lat: 48.927038, lng: 17.830465 },
    { lat: 48.927041, lng: 17.830352 },
    { lat: 48.927079, lng: 17.829241 },
    { lat: 48.927169, lng: 17.827552 },
    { lat: 48.927233, lng: 17.825806 },
    { lat: 48.927274, lng: 17.824459 },
    { lat: 48.927408, lng: 17.822722 },
    { lat: 48.927466, lng: 17.821881 },
    { lat: 48.927505, lng: 17.821632 },
    { lat: 48.92749, lng: 17.820731 },
    { lat: 48.927491, lng: 17.820544 },
    { lat: 48.927492, lng: 17.819175 },
    { lat: 48.927501, lng: 17.819071 },
    { lat: 48.927593, lng: 17.818213 },
    { lat: 48.927645, lng: 17.817714 },
    { lat: 48.927672, lng: 17.817419 },
    { lat: 48.927677, lng: 17.817358 },
    { lat: 48.9276939, lng: 17.8171749 },
    { lat: 48.927613, lng: 17.817191 },
    { lat: 48.927586, lng: 17.81719 },
    { lat: 48.927489, lng: 17.817224 },
    { lat: 48.927336, lng: 17.817131 },
    { lat: 48.927281, lng: 17.817054 },
    { lat: 48.927186, lng: 17.817052 },
    { lat: 48.926897, lng: 17.816785 },
    { lat: 48.926744, lng: 17.816456 },
    { lat: 48.926609, lng: 17.816385 },
    { lat: 48.926373, lng: 17.816047 },
    { lat: 48.926078, lng: 17.816042 },
    { lat: 48.92597, lng: 17.816061 },
    { lat: 48.92591, lng: 17.815989 },
    { lat: 48.925735, lng: 17.816021 },
    { lat: 48.925673, lng: 17.815946 },
    { lat: 48.925645, lng: 17.81591 },
    { lat: 48.925652, lng: 17.815863 },
    { lat: 48.92569, lng: 17.815622 },
    { lat: 48.925702, lng: 17.815462 },
    { lat: 48.925648, lng: 17.814419 },
    { lat: 48.925602, lng: 17.813396 },
    { lat: 48.925535, lng: 17.812204 },
    { lat: 48.92551, lng: 17.811508 },
    { lat: 48.925506, lng: 17.811353 },
    { lat: 48.925499, lng: 17.81112 },
    { lat: 48.925492, lng: 17.810954 },
    { lat: 48.925446, lng: 17.810125 },
    { lat: 48.925381, lng: 17.808712 },
    { lat: 48.925359, lng: 17.808508 },
    { lat: 48.925351, lng: 17.808224 },
    { lat: 48.92534, lng: 17.807838 },
    { lat: 48.925248, lng: 17.807328 },
    { lat: 48.925226, lng: 17.8072 },
    { lat: 48.925229, lng: 17.806677 },
    { lat: 48.925233, lng: 17.80607 },
    { lat: 48.925279, lng: 17.804937 },
    { lat: 48.925278, lng: 17.80403 },
    { lat: 48.92533, lng: 17.802962 },
    { lat: 48.92527, lng: 17.802226 },
    { lat: 48.925271, lng: 17.802097 },
    { lat: 48.925278, lng: 17.801616 },
    { lat: 48.925286, lng: 17.801297 },
    { lat: 48.925286, lng: 17.800896 },
    { lat: 48.925283, lng: 17.800679 },
    { lat: 48.925282, lng: 17.800387 },
    { lat: 48.925255, lng: 17.799846 },
    { lat: 48.925254, lng: 17.799726 },
    { lat: 48.92524, lng: 17.799295 },
    { lat: 48.925236, lng: 17.798899 },
    { lat: 48.925238, lng: 17.798559 },
    { lat: 48.925255, lng: 17.798157 },
    { lat: 48.925273, lng: 17.797483 },
    { lat: 48.925271, lng: 17.796675 },
    { lat: 48.925267, lng: 17.796491 },
    { lat: 48.925236, lng: 17.79563 },
    { lat: 48.925278, lng: 17.795014 },
    { lat: 48.925326, lng: 17.794021 },
    { lat: 48.925313, lng: 17.7938 },
    { lat: 48.925299, lng: 17.793494 },
    { lat: 48.925292, lng: 17.792978 },
    { lat: 48.925268, lng: 17.792281 },
    { lat: 48.925261, lng: 17.792027 },
    { lat: 48.925225, lng: 17.791214 },
    { lat: 48.925228, lng: 17.790818 },
    { lat: 48.925234, lng: 17.790004 },
    { lat: 48.925257, lng: 17.788567 },
    { lat: 48.925278, lng: 17.78738 },
    { lat: 48.925281, lng: 17.787098 },
    { lat: 48.925287, lng: 17.786428 },
    { lat: 48.9252821, lng: 17.7857552 },
    { lat: 48.92528, lng: 17.785503 },
    { lat: 48.925274, lng: 17.784757 },
    { lat: 48.925272, lng: 17.784055 },
    { lat: 48.925267, lng: 17.7835 },
    { lat: 48.92527, lng: 17.782885 },
    { lat: 48.925272, lng: 17.782563 },
    { lat: 48.925273, lng: 17.782239 },
    { lat: 48.925272, lng: 17.782016 },
    { lat: 48.925275, lng: 17.781318 },
    { lat: 48.924307, lng: 17.781971 },
    { lat: 48.923721, lng: 17.78238 },
    { lat: 48.922912, lng: 17.782948 },
    { lat: 48.921908, lng: 17.783317 },
    { lat: 48.921208, lng: 17.783431 },
    { lat: 48.919924, lng: 17.783474 },
    { lat: 48.919465, lng: 17.783289 },
    { lat: 48.919299, lng: 17.783222 },
    { lat: 48.918815, lng: 17.783028 },
    { lat: 48.918128, lng: 17.782876 },
    { lat: 48.91739, lng: 17.782716 },
    { lat: 48.916979, lng: 17.782685 },
    { lat: 48.9167088, lng: 17.782663 },
    { lat: 48.916502, lng: 17.782371 },
    { lat: 48.91629, lng: 17.782055 },
    { lat: 48.915913, lng: 17.781516 },
    { lat: 48.915449, lng: 17.780877 },
    { lat: 48.914945, lng: 17.7801 },
    { lat: 48.914511, lng: 17.779139 },
    { lat: 48.914029, lng: 17.778158 },
    { lat: 48.913798, lng: 17.777688 },
    { lat: 48.9137107, lng: 17.7775181 },
    { lat: 48.913328, lng: 17.776887 },
    { lat: 48.913022, lng: 17.775702 },
    { lat: 48.9130158, lng: 17.7756476 },
    { lat: 48.912957, lng: 17.775129 },
    { lat: 48.912905, lng: 17.774685 },
    { lat: 48.91286, lng: 17.77391 },
    { lat: 48.912813, lng: 17.77307 },
    { lat: 48.912727, lng: 17.772529 },
    { lat: 48.912648, lng: 17.772037 },
    { lat: 48.912446, lng: 17.771398 },
    { lat: 48.912272, lng: 17.770851 },
    { lat: 48.911986, lng: 17.770317 },
    { lat: 48.911484, lng: 17.769774 },
    { lat: 48.91091, lng: 17.769045 },
    { lat: 48.910794, lng: 17.768848 },
    { lat: 48.910384, lng: 17.767983 },
    { lat: 48.910126, lng: 17.767446 },
    { lat: 48.909992, lng: 17.76717 },
    { lat: 48.909748, lng: 17.766781 },
    { lat: 48.909318, lng: 17.766099 },
    { lat: 48.908896, lng: 17.765497 },
    { lat: 48.908581, lng: 17.765034 },
    { lat: 48.908521, lng: 17.76492 },
    { lat: 48.908358, lng: 17.764615 },
    { lat: 48.908141, lng: 17.764149 },
    { lat: 48.907914, lng: 17.763659 },
    { lat: 48.907528, lng: 17.762976 },
    { lat: 48.907439, lng: 17.763016 },
    { lat: 48.907425, lng: 17.76298 },
    { lat: 48.907101, lng: 17.762248 },
    { lat: 48.907021, lng: 17.762068 },
    { lat: 48.906717, lng: 17.761887 },
    { lat: 48.906536, lng: 17.7617 },
    { lat: 48.906453, lng: 17.761614 },
    { lat: 48.906311, lng: 17.761277 },
    { lat: 48.906298, lng: 17.761242 },
    { lat: 48.906234, lng: 17.761115 },
    { lat: 48.905992, lng: 17.760804 },
    { lat: 48.905753, lng: 17.760543 },
    { lat: 48.905599, lng: 17.760365 },
    { lat: 48.90544, lng: 17.760173 },
    { lat: 48.905292, lng: 17.760016 },
    { lat: 48.90514, lng: 17.75977 },
    { lat: 48.904944, lng: 17.759467 },
    { lat: 48.904774, lng: 17.759174 },
    { lat: 48.904632, lng: 17.758941 },
    { lat: 48.904587, lng: 17.758868 },
    { lat: 48.904425, lng: 17.758751 },
    { lat: 48.904356, lng: 17.758618 },
    { lat: 48.90422, lng: 17.758543 },
    { lat: 48.904191, lng: 17.758537 },
    { lat: 48.904115, lng: 17.758554 },
    { lat: 48.904071, lng: 17.758593 },
    { lat: 48.903967, lng: 17.758353 },
    { lat: 48.903843, lng: 17.758178 },
    { lat: 48.903733, lng: 17.758048 },
    { lat: 48.903612, lng: 17.757918 },
    { lat: 48.903586, lng: 17.757829 },
    { lat: 48.903567, lng: 17.75771 },
    { lat: 48.903577, lng: 17.757656 },
    { lat: 48.903413, lng: 17.757372 },
    { lat: 48.903353, lng: 17.75736 },
    { lat: 48.903244, lng: 17.757156 },
    { lat: 48.903109, lng: 17.757045 },
    { lat: 48.903035, lng: 17.757006 },
    { lat: 48.902947, lng: 17.756858 },
    { lat: 48.902917, lng: 17.756743 },
    { lat: 48.902926, lng: 17.756702 },
    { lat: 48.902946, lng: 17.756502 },
    { lat: 48.902891, lng: 17.756302 },
    { lat: 48.90291, lng: 17.756182 },
    { lat: 48.902877, lng: 17.756026 },
    { lat: 48.902858, lng: 17.755889 },
    { lat: 48.90279, lng: 17.755744 },
    { lat: 48.902642, lng: 17.755567 },
    { lat: 48.902557, lng: 17.755436 },
    { lat: 48.902501, lng: 17.755334 },
    { lat: 48.902471, lng: 17.755181 },
    { lat: 48.902418, lng: 17.755088 },
    { lat: 48.90239, lng: 17.755078 },
    { lat: 48.902228, lng: 17.754952 },
    { lat: 48.90217, lng: 17.75493 },
    { lat: 48.902042, lng: 17.754834 },
    { lat: 48.901837, lng: 17.7546344 },
    { lat: 48.90179, lng: 17.7546102 },
    { lat: 48.9017406, lng: 17.7545831 },
    { lat: 48.901692, lng: 17.754559 },
    { lat: 48.901548, lng: 17.754508 },
    { lat: 48.90143, lng: 17.754478 },
    { lat: 48.901286, lng: 17.754402 },
    { lat: 48.901152, lng: 17.754361 },
    { lat: 48.901085, lng: 17.754325 },
    { lat: 48.900989, lng: 17.75421 },
    { lat: 48.900927, lng: 17.754097 },
    { lat: 48.900911, lng: 17.754038 },
    { lat: 48.900876, lng: 17.754001 },
    { lat: 48.900819, lng: 17.753945 },
    { lat: 48.900855, lng: 17.753581 },
    { lat: 48.900615, lng: 17.753146 },
    { lat: 48.90059, lng: 17.7531 },
    { lat: 48.900457, lng: 17.753022 },
    { lat: 48.900309, lng: 17.752968 },
    { lat: 48.90023, lng: 17.752946 },
    { lat: 48.900077, lng: 17.752839 },
    { lat: 48.899887, lng: 17.752644 },
    { lat: 48.899782, lng: 17.752519 },
    { lat: 48.899595, lng: 17.752302 },
    { lat: 48.899456, lng: 17.752098 },
    { lat: 48.899303, lng: 17.751647 },
    { lat: 48.899403, lng: 17.751352 },
    { lat: 48.89941, lng: 17.751262 },
    { lat: 48.899388, lng: 17.751218 },
    { lat: 48.899224, lng: 17.751026 },
    { lat: 48.899112, lng: 17.75074 },
    { lat: 48.898925, lng: 17.750614 },
    { lat: 48.89874, lng: 17.750329 },
    { lat: 48.898713, lng: 17.750325 },
    { lat: 48.898576, lng: 17.750375 },
    { lat: 48.898445, lng: 17.750372 },
    { lat: 48.898371, lng: 17.750356 },
    { lat: 48.898284, lng: 17.750352 },
    { lat: 48.898071, lng: 17.750273 },
    { lat: 48.897926, lng: 17.750213 },
    { lat: 48.897859, lng: 17.75005 },
    { lat: 48.897772, lng: 17.750018 },
    { lat: 48.897759, lng: 17.749909 },
    { lat: 48.897715, lng: 17.7499 },
    { lat: 48.897631, lng: 17.749861 },
    { lat: 48.897565, lng: 17.749756 },
    { lat: 48.897418, lng: 17.749684 },
    { lat: 48.897334, lng: 17.749631 },
    { lat: 48.897163, lng: 17.749628 },
    { lat: 48.896987, lng: 17.749599 },
    { lat: 48.896774, lng: 17.749409 },
    { lat: 48.896671, lng: 17.749353 },
    { lat: 48.896633, lng: 17.749419 },
    { lat: 48.896513, lng: 17.749629 },
    { lat: 48.896359, lng: 17.749811 },
    { lat: 48.896214, lng: 17.749857 },
    { lat: 48.896115, lng: 17.749888 },
    { lat: 48.896075, lng: 17.749874 },
    { lat: 48.895829, lng: 17.749789 },
    { lat: 48.89559, lng: 17.749759 },
    { lat: 48.895501, lng: 17.749664 },
    { lat: 48.895438, lng: 17.749596 },
    { lat: 48.895162, lng: 17.749384 },
    { lat: 48.895036, lng: 17.749329 },
    { lat: 48.894948, lng: 17.74929 },
    { lat: 48.89487, lng: 17.749257 },
    { lat: 48.894815, lng: 17.749222 },
    { lat: 48.894702, lng: 17.749152 },
    { lat: 48.894332, lng: 17.748921 },
    { lat: 48.893849, lng: 17.748399 },
    { lat: 48.893405, lng: 17.748264 },
    { lat: 48.89303, lng: 17.748197 },
    { lat: 48.892599, lng: 17.747916 },
    { lat: 48.892311, lng: 17.74779 },
    { lat: 48.891687, lng: 17.747257 },
    { lat: 48.8911706, lng: 17.7468976 },
    { lat: 48.890833, lng: 17.746458 },
    { lat: 48.8907103, lng: 17.746245 },
    { lat: 48.8906482, lng: 17.7460381 },
    { lat: 48.8904591, lng: 17.746788 },
    { lat: 48.8899748, lng: 17.7475256 },
    { lat: 48.889799, lng: 17.7478628 },
    { lat: 48.8896576, lng: 17.7480982 },
    { lat: 48.8895679, lng: 17.7482558 },
    { lat: 48.8894756, lng: 17.7484067 },
    { lat: 48.8893171, lng: 17.7486947 },
    { lat: 48.8890008, lng: 17.7492252 },
    { lat: 48.8887314, lng: 17.7496752 },
    { lat: 48.8886952, lng: 17.7497111 },
    { lat: 48.8882598, lng: 17.7500674 },
    { lat: 48.8879787, lng: 17.7503082 },
    { lat: 48.8878524, lng: 17.7504564 },
    { lat: 48.8874796, lng: 17.7508227 },
    { lat: 48.8872954, lng: 17.7510572 },
    { lat: 48.8870953, lng: 17.7512569 },
    { lat: 48.8869333, lng: 17.7514504 },
    { lat: 48.8868277, lng: 17.7515335 },
    { lat: 48.8866567, lng: 17.7516894 },
    { lat: 48.8864423, lng: 17.7519126 },
    { lat: 48.8860505, lng: 17.752225 },
    { lat: 48.8857528, lng: 17.752379 },
    { lat: 48.8854564, lng: 17.7525897 },
    { lat: 48.8853393, lng: 17.7526695 },
    { lat: 48.8849122, lng: 17.7531239 },
    { lat: 48.884771, lng: 17.7535667 },
    { lat: 48.884636, lng: 17.753813 },
    { lat: 48.8845062, lng: 17.7539424 },
    { lat: 48.8843414, lng: 17.7543495 },
    { lat: 48.8841434, lng: 17.7546367 },
    { lat: 48.8840231, lng: 17.7547376 },
    { lat: 48.8838284, lng: 17.7547842 },
    { lat: 48.8833858, lng: 17.7549112 },
    { lat: 48.8831903, lng: 17.754945 },
    { lat: 48.8830399, lng: 17.7551084 },
    { lat: 48.8829201, lng: 17.7553078 },
    { lat: 48.8826052, lng: 17.7555494 },
    { lat: 48.8824327, lng: 17.7556274 },
    { lat: 48.8822746, lng: 17.7557565 },
    { lat: 48.8822004, lng: 17.7557605 },
    { lat: 48.8821097, lng: 17.7557164 },
    { lat: 48.8819743, lng: 17.755738 },
    { lat: 48.8819386, lng: 17.7557439 },
    { lat: 48.8816779, lng: 17.7557639 },
    { lat: 48.8815521, lng: 17.7559583 },
    { lat: 48.8814283, lng: 17.7561115 },
    { lat: 48.8812609, lng: 17.756218 },
    { lat: 48.8811335, lng: 17.7562758 },
    { lat: 48.8810998, lng: 17.7562976 },
    { lat: 48.8809941, lng: 17.7563973 },
    { lat: 48.8807582, lng: 17.7565809 },
    { lat: 48.8805752, lng: 17.7565986 },
    { lat: 48.8804992, lng: 17.7566303 },
    { lat: 48.8804587, lng: 17.7567978 },
    { lat: 48.8804052, lng: 17.7569186 },
    { lat: 48.8803399, lng: 17.7570164 },
    { lat: 48.8801113, lng: 17.7570238 },
    { lat: 48.8799313, lng: 17.7570906 },
    { lat: 48.8798857, lng: 17.7571205 },
    { lat: 48.8796854, lng: 17.757186 },
    { lat: 48.8794317, lng: 17.7574686 },
    { lat: 48.8793554, lng: 17.7576788 },
    { lat: 48.8792834, lng: 17.7577929 },
    { lat: 48.8791077, lng: 17.7579301 },
    { lat: 48.878843, lng: 17.7583825 },
    { lat: 48.8786339, lng: 17.7586002 },
    { lat: 48.8785285, lng: 17.758668 },
    { lat: 48.8783646, lng: 17.7586812 },
    { lat: 48.8782741, lng: 17.7586602 },
    { lat: 48.8781988, lng: 17.758648 },
    { lat: 48.8780844, lng: 17.7586415 },
    { lat: 48.8780197, lng: 17.7586908 },
    { lat: 48.8778065, lng: 17.7589887 },
    { lat: 48.8777139, lng: 17.759119 },
    { lat: 48.8776736, lng: 17.759309 },
    { lat: 48.8775987, lng: 17.7595792 },
    { lat: 48.8773819, lng: 17.7600557 },
    { lat: 48.8772684, lng: 17.7606373 },
    { lat: 48.8772047, lng: 17.76085 },
    { lat: 48.8771835, lng: 17.7610228 },
    { lat: 48.8771384, lng: 17.761188 },
    { lat: 48.8769863, lng: 17.7614506 },
    { lat: 48.8768677, lng: 17.761746 },
    { lat: 48.876836, lng: 17.7620953 },
    { lat: 48.8767865, lng: 17.7623024 },
    { lat: 48.8767442, lng: 17.762663 },
    { lat: 48.8766712, lng: 17.7629819 },
    { lat: 48.8766516, lng: 17.7633826 },
    { lat: 48.8766155, lng: 17.7634557 },
    { lat: 48.876351, lng: 17.7636906 },
    { lat: 48.8762132, lng: 17.7638931 },
    { lat: 48.8761323, lng: 17.7640641 },
    { lat: 48.87599, lng: 17.7642483 },
    { lat: 48.8759191, lng: 17.7644003 },
    { lat: 48.8759024, lng: 17.7645141 },
    { lat: 48.8759187, lng: 17.7649298 },
    { lat: 48.875825, lng: 17.765263 },
    { lat: 48.8757567, lng: 17.7654552 },
    { lat: 48.875733, lng: 17.7655103 },
    { lat: 48.8756852, lng: 17.7655775 },
    { lat: 48.8753657, lng: 17.7660399 },
    { lat: 48.8753037, lng: 17.7661886 },
    { lat: 48.8752014, lng: 17.7664729 },
    { lat: 48.8751336, lng: 17.7666942 },
    { lat: 48.8745163, lng: 17.768157 },
    { lat: 48.8744146, lng: 17.7685988 },
    { lat: 48.8741905, lng: 17.7691625 },
    { lat: 48.8740496, lng: 17.7697373 },
    { lat: 48.8737815, lng: 17.7701738 },
    { lat: 48.8736157, lng: 17.7705076 },
    { lat: 48.87344, lng: 17.7709765 },
    { lat: 48.8732727, lng: 17.7713247 },
    { lat: 48.8730149, lng: 17.7716326 },
    { lat: 48.8728117, lng: 17.7718352 },
    { lat: 48.8725713, lng: 17.7720961 },
    { lat: 48.8723049, lng: 17.7724292 },
    { lat: 48.8721833, lng: 17.7727655 },
    { lat: 48.8720533, lng: 17.7729489 },
    { lat: 48.8718388, lng: 17.7731698 },
    { lat: 48.8716772, lng: 17.7735005 },
    { lat: 48.8715451, lng: 17.7736478 },
    { lat: 48.8710136, lng: 17.7739847 },
    { lat: 48.8707823, lng: 17.774098 },
    { lat: 48.8707125, lng: 17.774118 },
    { lat: 48.8706379, lng: 17.7741366 },
    { lat: 48.869989, lng: 17.774398 },
    { lat: 48.8698739, lng: 17.7743447 },
    { lat: 48.8697499, lng: 17.7742732 },
    { lat: 48.8696325, lng: 17.7742545 },
    { lat: 48.8695038, lng: 17.7742923 },
    { lat: 48.8693728, lng: 17.7743471 },
    { lat: 48.8692079, lng: 17.774402 },
    { lat: 48.8691098, lng: 17.7744046 },
    { lat: 48.8690009, lng: 17.7743385 },
    { lat: 48.8688765, lng: 17.7742991 },
    { lat: 48.8687484, lng: 17.7743461 },
    { lat: 48.868553, lng: 17.7744544 },
    { lat: 48.8683493, lng: 17.7749099 },
    { lat: 48.8682675, lng: 17.775067 },
    { lat: 48.8680954, lng: 17.7753542 },
    { lat: 48.8680975, lng: 17.7753705 },
    { lat: 48.868013, lng: 17.7754642 },
    { lat: 48.8678969, lng: 17.7755608 },
    { lat: 48.8678294, lng: 17.7756576 },
    { lat: 48.8677278, lng: 17.7758798 },
    { lat: 48.8676032, lng: 17.7761121 },
    { lat: 48.8674735, lng: 17.7764664 },
    { lat: 48.8674151, lng: 17.7765456 },
    { lat: 48.8673653, lng: 17.7765809 },
    { lat: 48.8672901, lng: 17.7766603 },
    { lat: 48.8672356, lng: 17.7767488 },
    { lat: 48.8671398, lng: 17.7769907 },
    { lat: 48.8670737, lng: 17.7771117 },
    { lat: 48.8668916, lng: 17.7773083 },
    { lat: 48.8666363, lng: 17.7775277 },
    { lat: 48.8665408, lng: 17.7775547 },
    { lat: 48.8664653, lng: 17.7776115 },
    { lat: 48.8661496, lng: 17.7784888 },
    { lat: 48.8659873, lng: 17.778715 },
    { lat: 48.8656605, lng: 17.7787614 },
    { lat: 48.8654355, lng: 17.7789025 },
    { lat: 48.8652232, lng: 17.7789956 },
    { lat: 48.8645565, lng: 17.7791505 },
    { lat: 48.8641399, lng: 17.7793357 },
    { lat: 48.8639355, lng: 17.7802416 },
    { lat: 48.8635812, lng: 17.7805779 },
    { lat: 48.8634885, lng: 17.7805934 },
    { lat: 48.8626871, lng: 17.7808542 },
    { lat: 48.8624241, lng: 17.7808364 },
    { lat: 48.8614433, lng: 17.7808977 },
    { lat: 48.8613032, lng: 17.7809682 },
    { lat: 48.8610274, lng: 17.7812588 },
    { lat: 48.8606457, lng: 17.7814976 },
    { lat: 48.8602243, lng: 17.7818252 },
    { lat: 48.8598965, lng: 17.781911 },
    { lat: 48.8595751, lng: 17.7821591 },
    { lat: 48.8585574, lng: 17.7822415 },
    { lat: 48.8579939, lng: 17.7823871 },
    { lat: 48.8570148, lng: 17.7829375 },
    { lat: 48.8568014, lng: 17.7831569 },
    { lat: 48.8563625, lng: 17.7838667 },
    { lat: 48.8562972, lng: 17.7840724 },
    { lat: 48.8556066, lng: 17.7847802 },
    { lat: 48.8554628, lng: 17.7850335 },
    { lat: 48.8551998, lng: 17.7854996 },
    { lat: 48.8550187, lng: 17.7858206 },
    { lat: 48.8548038, lng: 17.7862775 },
    { lat: 48.8542792, lng: 17.7872099 },
    { lat: 48.8541251, lng: 17.7887176 },
    { lat: 48.8540137, lng: 17.7892978 },
    { lat: 48.8538528, lng: 17.789789 },
    { lat: 48.8538241, lng: 17.7899291 },
    { lat: 48.8537565, lng: 17.7904675 },
    { lat: 48.8535832, lng: 17.7910157 },
    { lat: 48.8535824, lng: 17.7912601 },
    { lat: 48.8534926, lng: 17.7917509 },
    { lat: 48.8534522, lng: 17.7920887 },
    { lat: 48.8533926, lng: 17.7927403 },
    { lat: 48.8529752, lng: 17.7941912 },
    { lat: 48.8529153, lng: 17.7945041 },
    { lat: 48.8528957, lng: 17.7947566 },
    { lat: 48.8528913, lng: 17.7950015 },
    { lat: 48.8529425, lng: 17.7953775 },
    { lat: 48.8528865, lng: 17.7954225 },
    { lat: 48.852788, lng: 17.7954192 },
    { lat: 48.8525447, lng: 17.7953163 },
    { lat: 48.8522935, lng: 17.7953066 },
    { lat: 48.851458, lng: 17.7955833 },
    { lat: 48.8514196, lng: 17.7955819 },
    { lat: 48.8512217, lng: 17.795539 },
    { lat: 48.8510791, lng: 17.7955517 },
    { lat: 48.851045, lng: 17.7955655 },
    { lat: 48.8508072, lng: 17.7956471 },
    { lat: 48.850617, lng: 17.7958053 },
    { lat: 48.8504305, lng: 17.7961176 },
    { lat: 48.8503289, lng: 17.7963019 },
    { lat: 48.8503041, lng: 17.7963765 },
    { lat: 48.8502668, lng: 17.7964876 },
    { lat: 48.8502599, lng: 17.7966888 },
  ],
  TrenčianskeBohuslavice: [
    { lat: 48.7843962, lng: 17.8562766 },
    { lat: 48.7844976, lng: 17.8569369 },
    { lat: 48.7845069, lng: 17.8570536 },
    { lat: 48.7843156, lng: 17.8571202 },
    { lat: 48.7839022, lng: 17.8575092 },
    { lat: 48.7836193, lng: 17.8576837 },
    { lat: 48.7833699, lng: 17.8579086 },
    { lat: 48.7831777, lng: 17.8580732 },
    { lat: 48.7828974, lng: 17.8581798 },
    { lat: 48.7825766, lng: 17.8582146 },
    { lat: 48.7823353, lng: 17.8584803 },
    { lat: 48.7820988, lng: 17.8588622 },
    { lat: 48.7818039, lng: 17.8595854 },
    { lat: 48.7818236, lng: 17.8598005 },
    { lat: 48.7816876, lng: 17.8602592 },
    { lat: 48.7813931, lng: 17.8607646 },
    { lat: 48.781253, lng: 17.8610051 },
    { lat: 48.7809821, lng: 17.8610811 },
    { lat: 48.7808715, lng: 17.8611549 },
    { lat: 48.7805738, lng: 17.8614783 },
    { lat: 48.7791715, lng: 17.8630071 },
    { lat: 48.7771281, lng: 17.8643122 },
    { lat: 48.7774689, lng: 17.8651144 },
    { lat: 48.7776825, lng: 17.8656422 },
    { lat: 48.7777586, lng: 17.8659296 },
    { lat: 48.7777658, lng: 17.8662946 },
    { lat: 48.7777685, lng: 17.8663762 },
    { lat: 48.7777835, lng: 17.8666415 },
    { lat: 48.7777854, lng: 17.8666741 },
    { lat: 48.7777997, lng: 17.8669109 },
    { lat: 48.7778025, lng: 17.8669561 },
    { lat: 48.7778092, lng: 17.8670674 },
    { lat: 48.7778118, lng: 17.8671111 },
    { lat: 48.7778177, lng: 17.8672081 },
    { lat: 48.7778198, lng: 17.8672621 },
    { lat: 48.7778205, lng: 17.867274 },
    { lat: 48.7778225, lng: 17.8673082 },
    { lat: 48.7778324, lng: 17.8675271 },
    { lat: 48.7778484, lng: 17.8678653 },
    { lat: 48.7778644, lng: 17.8682081 },
    { lat: 48.7781237, lng: 17.8685195 },
    { lat: 48.7781298, lng: 17.8685262 },
    { lat: 48.7784163, lng: 17.8688611 },
    { lat: 48.7787522, lng: 17.8692075 },
    { lat: 48.7792185, lng: 17.8697315 },
    { lat: 48.7792486, lng: 17.8697646 },
    { lat: 48.7792889, lng: 17.8698145 },
    { lat: 48.779543, lng: 17.8701334 },
    { lat: 48.7798842, lng: 17.8706623 },
    { lat: 48.7803799, lng: 17.8713765 },
    { lat: 48.7805184, lng: 17.8715757 },
    { lat: 48.7807211, lng: 17.8718675 },
    { lat: 48.780835, lng: 17.8719969 },
    { lat: 48.781018, lng: 17.872203 },
    { lat: 48.7812608, lng: 17.872431 },
    { lat: 48.7815221, lng: 17.8726272 },
    { lat: 48.7817911, lng: 17.8727635 },
    { lat: 48.7818551, lng: 17.872796 },
    { lat: 48.7820164, lng: 17.8728505 },
    { lat: 48.7821571, lng: 17.8728978 },
    { lat: 48.7824857, lng: 17.8729578 },
    { lat: 48.7828252, lng: 17.8729331 },
    { lat: 48.7830756, lng: 17.8728542 },
    { lat: 48.7831043, lng: 17.872845 },
    { lat: 48.7831321, lng: 17.8728362 },
    { lat: 48.7831519, lng: 17.87283 },
    { lat: 48.7831853, lng: 17.8728199 },
    { lat: 48.7832041, lng: 17.8728139 },
    { lat: 48.7832118, lng: 17.872811 },
    { lat: 48.783234, lng: 17.8728046 },
    { lat: 48.7832616, lng: 17.8727956 },
    { lat: 48.7832794, lng: 17.8727902 },
    { lat: 48.7832838, lng: 17.8727887 },
    { lat: 48.7834223, lng: 17.8727453 },
    { lat: 48.7834323, lng: 17.8727421 },
    { lat: 48.7837136, lng: 17.8726533 },
    { lat: 48.7838976, lng: 17.8725955 },
    { lat: 48.7839098, lng: 17.8725917 },
    { lat: 48.7844057, lng: 17.8724296 },
    { lat: 48.7845781, lng: 17.8723691 },
    { lat: 48.784588, lng: 17.8723656 },
    { lat: 48.7852315, lng: 17.8721409 },
    { lat: 48.785289, lng: 17.8721211 },
    { lat: 48.7859501, lng: 17.87189 },
    { lat: 48.7859944, lng: 17.8718749 },
    { lat: 48.7872908, lng: 17.8715276 },
    { lat: 48.7878596, lng: 17.8713751 },
    { lat: 48.7879496, lng: 17.8713512 },
    { lat: 48.7879795, lng: 17.8713432 },
    { lat: 48.7881351, lng: 17.8713016 },
    { lat: 48.7881694, lng: 17.8712922 },
    { lat: 48.7881973, lng: 17.871285 },
    { lat: 48.7885946, lng: 17.8709094 },
    { lat: 48.7886191, lng: 17.8708863 },
    { lat: 48.7887569, lng: 17.8707566 },
    { lat: 48.7887761, lng: 17.8707386 },
    { lat: 48.7888071, lng: 17.8707092 },
    { lat: 48.7888318, lng: 17.8706895 },
    { lat: 48.7893088, lng: 17.8703075 },
    { lat: 48.7893143, lng: 17.8703037 },
    { lat: 48.7893361, lng: 17.8702905 },
    { lat: 48.7893688, lng: 17.8702715 },
    { lat: 48.7896693, lng: 17.8701621 },
    { lat: 48.7903464, lng: 17.8699161 },
    { lat: 48.7903388, lng: 17.8698654 },
    { lat: 48.7903336, lng: 17.869835 },
    { lat: 48.7906695, lng: 17.8696224 },
    { lat: 48.7907783, lng: 17.8695535 },
    { lat: 48.790899, lng: 17.8694771 },
    { lat: 48.793035, lng: 17.8706786 },
    { lat: 48.7931741, lng: 17.8707571 },
    { lat: 48.7931974, lng: 17.8707702 },
    { lat: 48.7932196, lng: 17.8708192 },
    { lat: 48.7932453, lng: 17.8708708 },
    { lat: 48.793271, lng: 17.8709227 },
    { lat: 48.7933017, lng: 17.8709835 },
    { lat: 48.7933391, lng: 17.8710597 },
    { lat: 48.7933866, lng: 17.8711545 },
    { lat: 48.7934096, lng: 17.8711991 },
    { lat: 48.7934313, lng: 17.8713358 },
    { lat: 48.7934483, lng: 17.8714492 },
    { lat: 48.7934867, lng: 17.8716913 },
    { lat: 48.7935247, lng: 17.8719295 },
    { lat: 48.7935647, lng: 17.872181 },
    { lat: 48.793601, lng: 17.8724075 },
    { lat: 48.7940097, lng: 17.8721855 },
    { lat: 48.7940146, lng: 17.8721925 },
    { lat: 48.794421, lng: 17.8728091 },
    { lat: 48.7947502, lng: 17.8733076 },
    { lat: 48.7949888, lng: 17.8736703 },
    { lat: 48.7951044, lng: 17.8738457 },
    { lat: 48.794991, lng: 17.8742684 },
    { lat: 48.7951102, lng: 17.8744122 },
    { lat: 48.7953209, lng: 17.8746654 },
    { lat: 48.7954072, lng: 17.8747688 },
    { lat: 48.7957078, lng: 17.87513 },
    { lat: 48.7966107, lng: 17.8762147 },
    { lat: 48.7966254, lng: 17.8762323 },
    { lat: 48.7975077, lng: 17.8772924 },
    { lat: 48.79764, lng: 17.8772976 },
    { lat: 48.797803, lng: 17.8773038 },
    { lat: 48.7979625, lng: 17.8773104 },
    { lat: 48.7980208, lng: 17.8773023 },
    { lat: 48.7981965, lng: 17.8772786 },
    { lat: 48.7987161, lng: 17.8772081 },
    { lat: 48.7987553, lng: 17.8772027 },
    { lat: 48.798809, lng: 17.8771957 },
    { lat: 48.7988258, lng: 17.8771934 },
    { lat: 48.799183, lng: 17.8771453 },
    { lat: 48.7996711, lng: 17.8770784 },
    { lat: 48.7998089, lng: 17.8770598 },
    { lat: 48.8008623, lng: 17.8769143 },
    { lat: 48.8008675, lng: 17.8769063 },
    { lat: 48.8008819, lng: 17.8768848 },
    { lat: 48.8008985, lng: 17.8768594 },
    { lat: 48.800914, lng: 17.8768363 },
    { lat: 48.8009549, lng: 17.8768969 },
    { lat: 48.8013155, lng: 17.8773169 },
    { lat: 48.8016913, lng: 17.8778397 },
    { lat: 48.80193, lng: 17.8781652 },
    { lat: 48.802291, lng: 17.8787258 },
    { lat: 48.8023425, lng: 17.8786622 },
    { lat: 48.8023382, lng: 17.8786082 },
    { lat: 48.8023736, lng: 17.8785981 },
    { lat: 48.8024069, lng: 17.8785889 },
    { lat: 48.8024147, lng: 17.8785866 },
    { lat: 48.8027876, lng: 17.8784803 },
    { lat: 48.8034275, lng: 17.8783843 },
    { lat: 48.8040232, lng: 17.8780979 },
    { lat: 48.8045802, lng: 17.8778438 },
    { lat: 48.8047, lng: 17.8776956 },
    { lat: 48.8048828, lng: 17.8774695 },
    { lat: 48.8049196, lng: 17.8774243 },
    { lat: 48.804967, lng: 17.877222 },
    { lat: 48.804996, lng: 17.876956 },
    { lat: 48.805031, lng: 17.876697 },
    { lat: 48.805066, lng: 17.876428 },
    { lat: 48.803926, lng: 17.875041 },
    { lat: 48.802852, lng: 17.873735 },
    { lat: 48.802927, lng: 17.873653 },
    { lat: 48.802991, lng: 17.873588 },
    { lat: 48.803144, lng: 17.873431 },
    { lat: 48.803307, lng: 17.873264 },
    { lat: 48.803475, lng: 17.873092 },
    { lat: 48.803656, lng: 17.872908 },
    { lat: 48.803663, lng: 17.872901 },
    { lat: 48.803859, lng: 17.872702 },
    { lat: 48.804075, lng: 17.872482 },
    { lat: 48.804315, lng: 17.872238 },
    { lat: 48.804448, lng: 17.872103 },
    { lat: 48.804451, lng: 17.8721 },
    { lat: 48.804558, lng: 17.871911 },
    { lat: 48.804676, lng: 17.871703 },
    { lat: 48.80483, lng: 17.87146 },
    { lat: 48.804833, lng: 17.871455 },
    { lat: 48.805035, lng: 17.87117 },
    { lat: 48.805044, lng: 17.871156 },
    { lat: 48.80507, lng: 17.871111 },
    { lat: 48.805074, lng: 17.871105 },
    { lat: 48.805084, lng: 17.871093 },
    { lat: 48.805212, lng: 17.870645 },
    { lat: 48.805239, lng: 17.870552 },
    { lat: 48.805386, lng: 17.87054 },
    { lat: 48.805579, lng: 17.870518 },
    { lat: 48.805727, lng: 17.870545 },
    { lat: 48.805795, lng: 17.870557 },
    { lat: 48.806012, lng: 17.870597 },
    { lat: 48.806015, lng: 17.870598 },
    { lat: 48.806038, lng: 17.870605 },
    { lat: 48.806039, lng: 17.870605 },
    { lat: 48.806809, lng: 17.870755 },
    { lat: 48.806814, lng: 17.870757 },
    { lat: 48.806847, lng: 17.870775 },
    { lat: 48.807036, lng: 17.870876 },
    { lat: 48.807068, lng: 17.870897 },
    { lat: 48.807312, lng: 17.871031 },
    { lat: 48.807337, lng: 17.871043 },
    { lat: 48.807347, lng: 17.87105 },
    { lat: 48.807651, lng: 17.871214 },
    { lat: 48.807674, lng: 17.871156 },
    { lat: 48.807851, lng: 17.870497 },
    { lat: 48.80787, lng: 17.870426 },
    { lat: 48.807892, lng: 17.870346 },
    { lat: 48.807908, lng: 17.870282 },
    { lat: 48.808046, lng: 17.869786 },
    { lat: 48.808209, lng: 17.869436 },
    { lat: 48.808355, lng: 17.869116 },
    { lat: 48.808443, lng: 17.868899 },
    { lat: 48.80845, lng: 17.86888 },
    { lat: 48.80861, lng: 17.868405 },
    { lat: 48.808778, lng: 17.868197 },
    { lat: 48.808891, lng: 17.867988 },
    { lat: 48.809008, lng: 17.867838 },
    { lat: 48.809172, lng: 17.867766 },
    { lat: 48.809343, lng: 17.867579 },
    { lat: 48.809446, lng: 17.867372 },
    { lat: 48.809673, lng: 17.867032 },
    { lat: 48.809808, lng: 17.866965 },
    { lat: 48.81005, lng: 17.866868 },
    { lat: 48.8106177, lng: 17.8668409 },
    { lat: 48.8103616, lng: 17.8666568 },
    { lat: 48.8100024, lng: 17.8661655 },
    { lat: 48.8095343, lng: 17.8654799 },
    { lat: 48.8096456, lng: 17.8652658 },
    { lat: 48.8098231, lng: 17.8649345 },
    { lat: 48.8100894, lng: 17.8643704 },
    { lat: 48.8101166, lng: 17.8642791 },
    { lat: 48.8104596, lng: 17.8632117 },
    { lat: 48.8105391, lng: 17.8632361 },
    { lat: 48.8105615, lng: 17.8626725 },
    { lat: 48.8106332, lng: 17.8622523 },
    { lat: 48.8106344, lng: 17.8618057 },
    { lat: 48.8106884, lng: 17.8613158 },
    { lat: 48.81078, lng: 17.860851 },
    { lat: 48.8110927, lng: 17.8602719 },
    { lat: 48.8111018, lng: 17.8600302 },
    { lat: 48.8109811, lng: 17.8595836 },
    { lat: 48.8107756, lng: 17.8587032 },
    { lat: 48.8106831, lng: 17.8581076 },
    { lat: 48.8103922, lng: 17.8567853 },
    { lat: 48.8101133, lng: 17.8555887 },
    { lat: 48.8098833, lng: 17.8546593 },
    { lat: 48.8098821, lng: 17.8546401 },
    { lat: 48.8098786, lng: 17.8546196 },
    { lat: 48.8098729, lng: 17.8545248 },
    { lat: 48.8098725, lng: 17.8544992 },
    { lat: 48.8098717, lng: 17.8544676 },
    { lat: 48.8096169, lng: 17.8535202 },
    { lat: 48.8093842, lng: 17.8525645 },
    { lat: 48.8091748, lng: 17.8518083 },
    { lat: 48.8088864, lng: 17.8509228 },
    { lat: 48.8086713, lng: 17.8503701 },
    { lat: 48.8086336, lng: 17.8502699 },
    { lat: 48.8086295, lng: 17.8502393 },
    { lat: 48.8086178, lng: 17.8501396 },
    { lat: 48.808573, lng: 17.8497545 },
    { lat: 48.8085665, lng: 17.8497012 },
    { lat: 48.8085487, lng: 17.8495557 },
    { lat: 48.8085453, lng: 17.8495386 },
    { lat: 48.8084973, lng: 17.8490992 },
    { lat: 48.8084825, lng: 17.8489279 },
    { lat: 48.8084637, lng: 17.8488797 },
    { lat: 48.8080253, lng: 17.8475851 },
    { lat: 48.8078538, lng: 17.8470817 },
    { lat: 48.8075704, lng: 17.8462407 },
    { lat: 48.8072651, lng: 17.8453385 },
    { lat: 48.8070744, lng: 17.8447796 },
    { lat: 48.8070579, lng: 17.8447326 },
    { lat: 48.8070113, lng: 17.8446892 },
    { lat: 48.8069655, lng: 17.8446228 },
    { lat: 48.8069228, lng: 17.844551 },
    { lat: 48.8068772, lng: 17.8444694 },
    { lat: 48.8068321, lng: 17.8443962 },
    { lat: 48.8067875, lng: 17.8443297 },
    { lat: 48.8067473, lng: 17.8442627 },
    { lat: 48.8066976, lng: 17.8441867 },
    { lat: 48.8066802, lng: 17.8441608 },
    { lat: 48.806644, lng: 17.8441039 },
    { lat: 48.8066252, lng: 17.8440732 },
    { lat: 48.8066077, lng: 17.8440454 },
    { lat: 48.8065866, lng: 17.8440144 },
    { lat: 48.8065504, lng: 17.8439563 },
    { lat: 48.8065109, lng: 17.8438998 },
    { lat: 48.8064723, lng: 17.8438416 },
    { lat: 48.8064374, lng: 17.8437861 },
    { lat: 48.806413, lng: 17.8437558 },
    { lat: 48.8063953, lng: 17.8437266 },
    { lat: 48.8063744, lng: 17.8436974 },
    { lat: 48.806358, lng: 17.8436682 },
    { lat: 48.8063193, lng: 17.8436068 },
    { lat: 48.8062337, lng: 17.8434798 },
    { lat: 48.8061427, lng: 17.8433378 },
    { lat: 48.8061228, lng: 17.8433072 },
    { lat: 48.8060234, lng: 17.8431561 },
    { lat: 48.8059759, lng: 17.843081 },
    { lat: 48.8059632, lng: 17.8430585 },
    { lat: 48.8058588, lng: 17.8428994 },
    { lat: 48.8058068, lng: 17.8428234 },
    { lat: 48.8057628, lng: 17.8427493 },
    { lat: 48.8056966, lng: 17.8426648 },
    { lat: 48.8056472, lng: 17.8425759 },
    { lat: 48.8056321, lng: 17.8425486 },
    { lat: 48.8055986, lng: 17.8424993 },
    { lat: 48.8055487, lng: 17.8424212 },
    { lat: 48.8054902, lng: 17.8423131 },
    { lat: 48.8053302, lng: 17.8419632 },
    { lat: 48.8051606, lng: 17.8413246 },
    { lat: 48.8051456, lng: 17.8412818 },
    { lat: 48.8051294, lng: 17.8412195 },
    { lat: 48.8051125, lng: 17.841163 },
    { lat: 48.8050768, lng: 17.841041 },
    { lat: 48.805004, lng: 17.84079 },
    { lat: 48.80497, lng: 17.8406751 },
    { lat: 48.8049504, lng: 17.8406116 },
    { lat: 48.8049411, lng: 17.8405885 },
    { lat: 48.8049069, lng: 17.8404176 },
    { lat: 48.8048676, lng: 17.8402147 },
    { lat: 48.8048443, lng: 17.8397937 },
    { lat: 48.8042797, lng: 17.8404053 },
    { lat: 48.8037535, lng: 17.8403177 },
    { lat: 48.8036608, lng: 17.840392 },
    { lat: 48.8030013, lng: 17.8409197 },
    { lat: 48.8027426, lng: 17.8411635 },
    { lat: 48.8027201, lng: 17.8411842 },
    { lat: 48.8022414, lng: 17.8414148 },
    { lat: 48.8021681, lng: 17.8414348 },
    { lat: 48.801949, lng: 17.8414944 },
    { lat: 48.8015886, lng: 17.8415143 },
    { lat: 48.8013912, lng: 17.8414628 },
    { lat: 48.8011367, lng: 17.8412526 },
    { lat: 48.8009962, lng: 17.8417317 },
    { lat: 48.8007757, lng: 17.8417285 },
    { lat: 48.8006523, lng: 17.8418749 },
    { lat: 48.800595, lng: 17.8418794 },
    { lat: 48.800427, lng: 17.841868 },
    { lat: 48.800208, lng: 17.8418535 },
    { lat: 48.7999628, lng: 17.84174 },
    { lat: 48.7998908, lng: 17.8417073 },
    { lat: 48.7997293, lng: 17.8415971 },
    { lat: 48.7996634, lng: 17.8415523 },
    { lat: 48.7995808, lng: 17.8414963 },
    { lat: 48.7995019, lng: 17.8414423 },
    { lat: 48.7993736, lng: 17.8413763 },
    { lat: 48.7991122, lng: 17.8412407 },
    { lat: 48.7986885, lng: 17.8410177 },
    { lat: 48.7979417, lng: 17.8411102 },
    { lat: 48.7978747, lng: 17.8411233 },
    { lat: 48.7976, lng: 17.8411777 },
    { lat: 48.7967991, lng: 17.8413688 },
    { lat: 48.7959543, lng: 17.8422317 },
    { lat: 48.7958687, lng: 17.8420878 },
    { lat: 48.7954759, lng: 17.841425 },
    { lat: 48.7954067, lng: 17.8413071 },
    { lat: 48.7949381, lng: 17.8406961 },
    { lat: 48.7947944, lng: 17.8404694 },
    { lat: 48.7947869, lng: 17.8404577 },
    { lat: 48.7947169, lng: 17.8403459 },
    { lat: 48.7946379, lng: 17.8401988 },
    { lat: 48.7946327, lng: 17.8401882 },
    { lat: 48.7945105, lng: 17.8399612 },
    { lat: 48.7942964, lng: 17.8399148 },
    { lat: 48.7941814, lng: 17.8399 },
    { lat: 48.7940858, lng: 17.839888 },
    { lat: 48.7939741, lng: 17.8398736 },
    { lat: 48.7939718, lng: 17.8398734 },
    { lat: 48.79394, lng: 17.8398692 },
    { lat: 48.7938352, lng: 17.839856 },
    { lat: 48.7938261, lng: 17.8398548 },
    { lat: 48.793825, lng: 17.8398549 },
    { lat: 48.7937293, lng: 17.8398426 },
    { lat: 48.793702, lng: 17.839839 },
    { lat: 48.7936803, lng: 17.8398365 },
    { lat: 48.793661, lng: 17.8398336 },
    { lat: 48.7935973, lng: 17.839884 },
    { lat: 48.7935661, lng: 17.8399088 },
    { lat: 48.7935392, lng: 17.83993 },
    { lat: 48.7935036, lng: 17.8399572 },
    { lat: 48.7934722, lng: 17.8399821 },
    { lat: 48.7934442, lng: 17.8400044 },
    { lat: 48.7934334, lng: 17.8400127 },
    { lat: 48.7934173, lng: 17.8400261 },
    { lat: 48.7933774, lng: 17.8400572 },
    { lat: 48.7932707, lng: 17.8401798 },
    { lat: 48.7932045, lng: 17.8402622 },
    { lat: 48.7931761, lng: 17.8402973 },
    { lat: 48.7931477, lng: 17.8403324 },
    { lat: 48.7931162, lng: 17.8403709 },
    { lat: 48.7930868, lng: 17.840408 },
    { lat: 48.7930604, lng: 17.8404402 },
    { lat: 48.7930584, lng: 17.8404436 },
    { lat: 48.793051, lng: 17.8404527 },
    { lat: 48.7929543, lng: 17.8405717 },
    { lat: 48.7928943, lng: 17.8410931 },
    { lat: 48.7928824, lng: 17.8411948 },
    { lat: 48.7928795, lng: 17.8412213 },
    { lat: 48.792876, lng: 17.8413124 },
    { lat: 48.7928723, lng: 17.8414001 },
    { lat: 48.7928717, lng: 17.84141 },
    { lat: 48.792856, lng: 17.8418214 },
    { lat: 48.7929326, lng: 17.8423769 },
    { lat: 48.7930013, lng: 17.842877 },
    { lat: 48.7931765, lng: 17.8431403 },
    { lat: 48.7933088, lng: 17.843608 },
    { lat: 48.7939156, lng: 17.8436642 },
    { lat: 48.7942708, lng: 17.8440643 },
    { lat: 48.7943594, lng: 17.844388 },
    { lat: 48.7943619, lng: 17.8445792 },
    { lat: 48.7944081, lng: 17.8448567 },
    { lat: 48.7938036, lng: 17.8452664 },
    { lat: 48.793329, lng: 17.8454344 },
    { lat: 48.7931357, lng: 17.8454696 },
    { lat: 48.7928643, lng: 17.845672 },
    { lat: 48.7926124, lng: 17.8457658 },
    { lat: 48.7923304, lng: 17.8459413 },
    { lat: 48.7921082, lng: 17.8460419 },
    { lat: 48.7920719, lng: 17.846058 },
    { lat: 48.7920696, lng: 17.8460591 },
    { lat: 48.7920328, lng: 17.8460848 },
    { lat: 48.7919981, lng: 17.8461084 },
    { lat: 48.7919557, lng: 17.8461373 },
    { lat: 48.7919178, lng: 17.8461632 },
    { lat: 48.7918441, lng: 17.846214 },
    { lat: 48.7918027, lng: 17.8462423 },
    { lat: 48.7917648, lng: 17.8462685 },
    { lat: 48.7915479, lng: 17.8463059 },
    { lat: 48.791526, lng: 17.8462268 },
    { lat: 48.7911535, lng: 17.8465645 },
    { lat: 48.7903918, lng: 17.8472947 },
    { lat: 48.7896522, lng: 17.8480742 },
    { lat: 48.7889976, lng: 17.8485446 },
    { lat: 48.7880981, lng: 17.8495739 },
    { lat: 48.7878244, lng: 17.8500704 },
    { lat: 48.7875468, lng: 17.8505556 },
    { lat: 48.7877212, lng: 17.8509952 },
    { lat: 48.7877785, lng: 17.8512701 },
    { lat: 48.7877367, lng: 17.8519221 },
    { lat: 48.7879277, lng: 17.8524863 },
    { lat: 48.7881922, lng: 17.8528848 },
    { lat: 48.7881745, lng: 17.8529257 },
    { lat: 48.7876451, lng: 17.8542901 },
    { lat: 48.7873563, lng: 17.8553756 },
    { lat: 48.7868642, lng: 17.8552478 },
    { lat: 48.786618, lng: 17.8550594 },
    { lat: 48.7862498, lng: 17.8552222 },
    { lat: 48.7856681, lng: 17.8552183 },
    { lat: 48.7847192, lng: 17.8551886 },
    { lat: 48.7844022, lng: 17.8550429 },
    { lat: 48.7844016, lng: 17.8552974 },
    { lat: 48.7844009, lng: 17.8555996 },
    { lat: 48.7842944, lng: 17.8556137 },
    { lat: 48.7843962, lng: 17.8562766 },
  ],
  Beckov: [
    { lat: 48.7568341, lng: 17.8506178 },
    { lat: 48.7587326, lng: 17.850763 },
    { lat: 48.7587859, lng: 17.850767 },
    { lat: 48.7588494, lng: 17.850772 },
    { lat: 48.7589118, lng: 17.8507766 },
    { lat: 48.7590218, lng: 17.8507857 },
    { lat: 48.7593814, lng: 17.8508145 },
    { lat: 48.7594235, lng: 17.8508183 },
    { lat: 48.7596219, lng: 17.8508336 },
    { lat: 48.7597365, lng: 17.8508429 },
    { lat: 48.7598646, lng: 17.8508529 },
    { lat: 48.7600541, lng: 17.8508685 },
    { lat: 48.7603911, lng: 17.8508961 },
    { lat: 48.7603864, lng: 17.850913 },
    { lat: 48.7603727, lng: 17.8509652 },
    { lat: 48.7600668, lng: 17.8521421 },
    { lat: 48.7599606, lng: 17.8523828 },
    { lat: 48.7598494, lng: 17.852635 },
    { lat: 48.7597501, lng: 17.8528575 },
    { lat: 48.7595754, lng: 17.8532538 },
    { lat: 48.7595723, lng: 17.8532603 },
    { lat: 48.7595345, lng: 17.8533454 },
    { lat: 48.7594502, lng: 17.8535363 },
    { lat: 48.7599011, lng: 17.8552616 },
    { lat: 48.7599419, lng: 17.8554314 },
    { lat: 48.7601368, lng: 17.8562274 },
    { lat: 48.7601651, lng: 17.8563417 },
    { lat: 48.7604908, lng: 17.8574142 },
    { lat: 48.760502, lng: 17.8574509 },
    { lat: 48.7605795, lng: 17.8577062 },
    { lat: 48.7605964, lng: 17.8577606 },
    { lat: 48.760643, lng: 17.8579143 },
    { lat: 48.7606556, lng: 17.8579565 },
    { lat: 48.7606698, lng: 17.8580045 },
    { lat: 48.7606867, lng: 17.8580615 },
    { lat: 48.760691, lng: 17.8580745 },
    { lat: 48.7607459, lng: 17.8582557 },
    { lat: 48.7608207, lng: 17.8585031 },
    { lat: 48.76087, lng: 17.8586641 },
    { lat: 48.7609053, lng: 17.8587803 },
    { lat: 48.7610182, lng: 17.8591554 },
    { lat: 48.7611394, lng: 17.8595551 },
    { lat: 48.7611508, lng: 17.859592 },
    { lat: 48.7612166, lng: 17.8597302 },
    { lat: 48.7612323, lng: 17.859764 },
    { lat: 48.7619838, lng: 17.8613302 },
    { lat: 48.7622057, lng: 17.8617876 },
    { lat: 48.7621062, lng: 17.8629427 },
    { lat: 48.7620493, lng: 17.86359 },
    { lat: 48.7620461, lng: 17.8636289 },
    { lat: 48.7620419, lng: 17.863673 },
    { lat: 48.7620063, lng: 17.8640745 },
    { lat: 48.7620326, lng: 17.8640789 },
    { lat: 48.762116, lng: 17.8640948 },
    { lat: 48.762173, lng: 17.8641053 },
    { lat: 48.7622084, lng: 17.8641115 },
    { lat: 48.7623042, lng: 17.8641294 },
    { lat: 48.7622909, lng: 17.8642439 },
    { lat: 48.7622888, lng: 17.8642652 },
    { lat: 48.7622756, lng: 17.8643831 },
    { lat: 48.7622741, lng: 17.864396 },
    { lat: 48.7622329, lng: 17.8643856 },
    { lat: 48.7621469, lng: 17.8643633 },
    { lat: 48.7620139, lng: 17.8643291 },
    { lat: 48.761914, lng: 17.8643032 },
    { lat: 48.7619017, lng: 17.8648278 },
    { lat: 48.7618438, lng: 17.8648222 },
    { lat: 48.7617563, lng: 17.8648134 },
    { lat: 48.7613088, lng: 17.8647668 },
    { lat: 48.7614485, lng: 17.8654348 },
    { lat: 48.761463, lng: 17.8655062 },
    { lat: 48.7614741, lng: 17.8655592 },
    { lat: 48.7614884, lng: 17.8656302 },
    { lat: 48.7615826, lng: 17.8660843 },
    { lat: 48.7617192, lng: 17.8667415 },
    { lat: 48.7617367, lng: 17.8668258 },
    { lat: 48.7617588, lng: 17.866929 },
    { lat: 48.7619131, lng: 17.8676709 },
    { lat: 48.7619561, lng: 17.8678787 },
    { lat: 48.761958, lng: 17.8678905 },
    { lat: 48.7619691, lng: 17.8679428 },
    { lat: 48.7619709, lng: 17.8679554 },
    { lat: 48.761985, lng: 17.86802 },
    { lat: 48.7620123, lng: 17.8681561 },
    { lat: 48.7620251, lng: 17.8682163 },
    { lat: 48.7620495, lng: 17.8683401 },
    { lat: 48.762059, lng: 17.8683867 },
    { lat: 48.7620718, lng: 17.8684481 },
    { lat: 48.7621673, lng: 17.8689092 },
    { lat: 48.7624143, lng: 17.8701011 },
    { lat: 48.7625036, lng: 17.8705344 },
    { lat: 48.762566, lng: 17.8708387 },
    { lat: 48.7625723, lng: 17.8708671 },
    { lat: 48.7623774, lng: 17.8708924 },
    { lat: 48.7621845, lng: 17.8709151 },
    { lat: 48.7622032, lng: 17.8709818 },
    { lat: 48.762324, lng: 17.8714115 },
    { lat: 48.7624719, lng: 17.8718034 },
    { lat: 48.7625146, lng: 17.8718936 },
    { lat: 48.7625629, lng: 17.8719663 },
    { lat: 48.7629365, lng: 17.8724706 },
    { lat: 48.7631004, lng: 17.8726972 },
    { lat: 48.7633048, lng: 17.8730361 },
    { lat: 48.7634575, lng: 17.8733421 },
    { lat: 48.763475, lng: 17.8733862 },
    { lat: 48.7636366, lng: 17.8738202 },
    { lat: 48.7637269, lng: 17.8740643 },
    { lat: 48.7638324, lng: 17.874541 },
    { lat: 48.7639857, lng: 17.8752303 },
    { lat: 48.7639981, lng: 17.8752849 },
    { lat: 48.7640412, lng: 17.8752692 },
    { lat: 48.7640776, lng: 17.8752555 },
    { lat: 48.7641097, lng: 17.8752438 },
    { lat: 48.764124, lng: 17.8752381 },
    { lat: 48.7641372, lng: 17.8752332 },
    { lat: 48.7641559, lng: 17.8752263 },
    { lat: 48.7641846, lng: 17.8752152 },
    { lat: 48.764295, lng: 17.8751738 },
    { lat: 48.7643183, lng: 17.8751665 },
    { lat: 48.7643228, lng: 17.875165 },
    { lat: 48.7643472, lng: 17.8751573 },
    { lat: 48.7646255, lng: 17.8772674 },
    { lat: 48.7646273, lng: 17.8772774 },
    { lat: 48.7646373, lng: 17.8773486 },
    { lat: 48.764638, lng: 17.8773609 },
    { lat: 48.7646522, lng: 17.8775416 },
    { lat: 48.7646564, lng: 17.8775909 },
    { lat: 48.7646626, lng: 17.8776759 },
    { lat: 48.7646632, lng: 17.8776857 },
    { lat: 48.7646746, lng: 17.8778357 },
    { lat: 48.7646883, lng: 17.8780087 },
    { lat: 48.7646885, lng: 17.8780109 },
    { lat: 48.7646821, lng: 17.8790662 },
    { lat: 48.764678, lng: 17.8796908 },
    { lat: 48.7646776, lng: 17.8799658 },
    { lat: 48.764697, lng: 17.8801757 },
    { lat: 48.7647324, lng: 17.8805567 },
    { lat: 48.7647099, lng: 17.8810456 },
    { lat: 48.7646991, lng: 17.8811834 },
    { lat: 48.7646631, lng: 17.8816723 },
    { lat: 48.7646316, lng: 17.8823683 },
    { lat: 48.7647429, lng: 17.8835972 },
    { lat: 48.7647535, lng: 17.8841486 },
    { lat: 48.7647444, lng: 17.8845584 },
    { lat: 48.7647283, lng: 17.8846664 },
    { lat: 48.7646394, lng: 17.8850458 },
    { lat: 48.7645353, lng: 17.8853982 },
    { lat: 48.7643146, lng: 17.8860255 },
    { lat: 48.7641443, lng: 17.88644 },
    { lat: 48.7640595, lng: 17.8867027 },
    { lat: 48.7639114, lng: 17.8870736 },
    { lat: 48.7638313, lng: 17.887373 },
    { lat: 48.7637998, lng: 17.8875435 },
    { lat: 48.763737, lng: 17.8877754 },
    { lat: 48.7637404, lng: 17.8878693 },
    { lat: 48.7637426, lng: 17.8879249 },
    { lat: 48.7637443, lng: 17.8879331 },
    { lat: 48.7637724, lng: 17.8880259 },
    { lat: 48.7637738, lng: 17.8880312 },
    { lat: 48.763781, lng: 17.8880567 },
    { lat: 48.7638168, lng: 17.8882749 },
    { lat: 48.7638218, lng: 17.8883037 },
    { lat: 48.7639138, lng: 17.8888755 },
    { lat: 48.7638973, lng: 17.8890885 },
    { lat: 48.7638069, lng: 17.8898191 },
    { lat: 48.7636549, lng: 17.8909873 },
    { lat: 48.7635313, lng: 17.8919734 },
    { lat: 48.7635225, lng: 17.8922213 },
    { lat: 48.7635507, lng: 17.8922405 },
    { lat: 48.7634995, lng: 17.8926655 },
    { lat: 48.763643, lng: 17.8926473 },
    { lat: 48.7637542, lng: 17.892676 },
    { lat: 48.7637869, lng: 17.8932397 },
    { lat: 48.7638168, lng: 17.8936415 },
    { lat: 48.7638206, lng: 17.8936859 },
    { lat: 48.7638335, lng: 17.8938625 },
    { lat: 48.7636034, lng: 17.8939215 },
    { lat: 48.7634466, lng: 17.8939619 },
    { lat: 48.7634545, lng: 17.8941306 },
    { lat: 48.7634237, lng: 17.8944627 },
    { lat: 48.7634243, lng: 17.8944725 },
    { lat: 48.7634261, lng: 17.8948039 },
    { lat: 48.7634239, lng: 17.8948779 },
    { lat: 48.7634174, lng: 17.8952788 },
    { lat: 48.7634144, lng: 17.8954175 },
    { lat: 48.763396, lng: 17.8956353 },
    { lat: 48.7633859, lng: 17.8957115 },
    { lat: 48.7633324, lng: 17.8961365 },
    { lat: 48.7634406, lng: 17.8962635 },
    { lat: 48.7634829, lng: 17.896348 },
    { lat: 48.7635053, lng: 17.8964206 },
    { lat: 48.7635845, lng: 17.8966684 },
    { lat: 48.763582, lng: 17.8967588 },
    { lat: 48.7635778, lng: 17.8969323 },
    { lat: 48.7635851, lng: 17.8970533 },
    { lat: 48.763626, lng: 17.8972066 },
    { lat: 48.76362, lng: 17.8972961 },
    { lat: 48.7635876, lng: 17.8974893 },
    { lat: 48.7635745, lng: 17.8976094 },
    { lat: 48.7633495, lng: 17.897735 },
    { lat: 48.7632582, lng: 17.8978679 },
    { lat: 48.7632084, lng: 17.8980365 },
    { lat: 48.7629992, lng: 17.8987427 },
    { lat: 48.7629701, lng: 17.898784 },
    { lat: 48.7629565, lng: 17.8988016 },
    { lat: 48.7629406, lng: 17.8988007 },
    { lat: 48.7628796, lng: 17.8987998 },
    { lat: 48.7628581, lng: 17.8987994 },
    { lat: 48.7628227, lng: 17.8984545 },
    { lat: 48.7627703, lng: 17.8979393 },
    { lat: 48.7627498, lng: 17.8975597 },
    { lat: 48.7627547, lng: 17.8973797 },
    { lat: 48.7627604, lng: 17.897192 },
    { lat: 48.7627737, lng: 17.8968132 },
    { lat: 48.7627813, lng: 17.8966206 },
    { lat: 48.7627798, lng: 17.8962208 },
    { lat: 48.7620255, lng: 17.8960274 },
    { lat: 48.7610132, lng: 17.8957701 },
    { lat: 48.7609715, lng: 17.8957699 },
    { lat: 48.7599636, lng: 17.8955082 },
    { lat: 48.7590737, lng: 17.8952759 },
    { lat: 48.7590483, lng: 17.8955497 },
    { lat: 48.7590463, lng: 17.8955716 },
    { lat: 48.7590415, lng: 17.8956231 },
    { lat: 48.7589947, lng: 17.8959902 },
    { lat: 48.758946, lng: 17.8963448 },
    { lat: 48.7589151, lng: 17.8965073 },
    { lat: 48.7588787, lng: 17.8966909 },
    { lat: 48.7587725, lng: 17.8971954 },
    { lat: 48.7587353, lng: 17.8973644 },
    { lat: 48.7586956, lng: 17.8975509 },
    { lat: 48.7586659, lng: 17.8976945 },
    { lat: 48.7586318, lng: 17.8978584 },
    { lat: 48.7585605, lng: 17.8981752 },
    { lat: 48.7585261, lng: 17.8983343 },
    { lat: 48.7584891, lng: 17.898488 },
    { lat: 48.758487, lng: 17.8984933 },
    { lat: 48.758405, lng: 17.8987992 },
    { lat: 48.7583223, lng: 17.8990943 },
    { lat: 48.7582452, lng: 17.89939 },
    { lat: 48.7581723, lng: 17.8996796 },
    { lat: 48.7580915, lng: 17.8999515 },
    { lat: 48.7580617, lng: 17.900056 },
    { lat: 48.7579979, lng: 17.9002341 },
    { lat: 48.7579483, lng: 17.9003665 },
    { lat: 48.7579317, lng: 17.9004101 },
    { lat: 48.7578541, lng: 17.9006201 },
    { lat: 48.757752, lng: 17.9008749 },
    { lat: 48.757704, lng: 17.9009972 },
    { lat: 48.7576044, lng: 17.9012379 },
    { lat: 48.7575134, lng: 17.9014693 },
    { lat: 48.7574562, lng: 17.9016071 },
    { lat: 48.7574308, lng: 17.9016725 },
    { lat: 48.7574201, lng: 17.9017008 },
    { lat: 48.7573862, lng: 17.9018135 },
    { lat: 48.7573365, lng: 17.901926 },
    { lat: 48.7572935, lng: 17.9020362 },
    { lat: 48.7572354, lng: 17.9021785 },
    { lat: 48.7572166, lng: 17.9022221 },
    { lat: 48.7571161, lng: 17.9024664 },
    { lat: 48.7570315, lng: 17.9026721 },
    { lat: 48.756988, lng: 17.9027742 },
    { lat: 48.7569573, lng: 17.9028464 },
    { lat: 48.7569207, lng: 17.9029324 },
    { lat: 48.7568683, lng: 17.9030766 },
    { lat: 48.756835, lng: 17.9031798 },
    { lat: 48.756798, lng: 17.9032788 },
    { lat: 48.7567378, lng: 17.9034765 },
    { lat: 48.7566681, lng: 17.9036717 },
    { lat: 48.7566076, lng: 17.9038653 },
    { lat: 48.7565781, lng: 17.9039572 },
    { lat: 48.7565563, lng: 17.9040273 },
    { lat: 48.7565412, lng: 17.904075 },
    { lat: 48.7565307, lng: 17.9041081 },
    { lat: 48.7565239, lng: 17.9041257 },
    { lat: 48.7565171, lng: 17.9041445 },
    { lat: 48.7564939, lng: 17.9042083 },
    { lat: 48.756188, lng: 17.905037 },
    { lat: 48.7561679, lng: 17.9051152 },
    { lat: 48.7561666, lng: 17.9051324 },
    { lat: 48.7561629, lng: 17.9051825 },
    { lat: 48.7561499, lng: 17.9053413 },
    { lat: 48.756115, lng: 17.9056824 },
    { lat: 48.7561123, lng: 17.9057123 },
    { lat: 48.7561001, lng: 17.9058288 },
    { lat: 48.7560984, lng: 17.9059313 },
    { lat: 48.7560987, lng: 17.9059361 },
    { lat: 48.7560983, lng: 17.9059861 },
    { lat: 48.7561041, lng: 17.9060266 },
    { lat: 48.7561287, lng: 17.9061727 },
    { lat: 48.7561537, lng: 17.9063446 },
    { lat: 48.7561246, lng: 17.9065752 },
    { lat: 48.7560991, lng: 17.9067703 },
    { lat: 48.7560604, lng: 17.9070665 },
    { lat: 48.7560398, lng: 17.9071362 },
    { lat: 48.7560294, lng: 17.9071705 },
    { lat: 48.7560216, lng: 17.9071904 },
    { lat: 48.7560177, lng: 17.9072015 },
    { lat: 48.7559829, lng: 17.9074871 },
    { lat: 48.7559745, lng: 17.9075519 },
    { lat: 48.7559623, lng: 17.9076505 },
    { lat: 48.755951, lng: 17.9077442 },
    { lat: 48.7559409, lng: 17.9078203 },
    { lat: 48.7559176, lng: 17.9080148 },
    { lat: 48.7559103, lng: 17.9081005 },
    { lat: 48.7558921, lng: 17.9083053 },
    { lat: 48.7558924, lng: 17.9083468 },
    { lat: 48.7558936, lng: 17.9086312 },
    { lat: 48.7558954, lng: 17.9086421 },
    { lat: 48.7559488, lng: 17.9090227 },
    { lat: 48.7559531, lng: 17.9090387 },
    { lat: 48.7559936, lng: 17.9091886 },
    { lat: 48.7560318, lng: 17.9093345 },
    { lat: 48.7560447, lng: 17.9093796 },
    { lat: 48.7560448, lng: 17.9093808 },
    { lat: 48.756056, lng: 17.909475 },
    { lat: 48.7560724, lng: 17.9096157 },
    { lat: 48.75609, lng: 17.9097605 },
    { lat: 48.7560946, lng: 17.9097992 },
    { lat: 48.7560999, lng: 17.9099247 },
    { lat: 48.7561042, lng: 17.9100138 },
    { lat: 48.7561473, lng: 17.9104509 },
    { lat: 48.7560574, lng: 17.9108716 },
    { lat: 48.7560515, lng: 17.9109032 },
    { lat: 48.7560392, lng: 17.910963 },
    { lat: 48.7560004, lng: 17.9111611 },
    { lat: 48.7560054, lng: 17.9113778 },
    { lat: 48.756017, lng: 17.9118729 },
    { lat: 48.7560384, lng: 17.9118718 },
    { lat: 48.7560556, lng: 17.9121592 },
    { lat: 48.7560594, lng: 17.9122221 },
    { lat: 48.7560778, lng: 17.9125466 },
    { lat: 48.7560787, lng: 17.912561 },
    { lat: 48.7561189, lng: 17.9125354 },
    { lat: 48.7563245, lng: 17.9125959 },
    { lat: 48.7565145, lng: 17.9126206 },
    { lat: 48.7567434, lng: 17.9125978 },
    { lat: 48.7568106, lng: 17.9125889 },
    { lat: 48.756851, lng: 17.9125839 },
    { lat: 48.7569193, lng: 17.9125749 },
    { lat: 48.7573478, lng: 17.9124739 },
    { lat: 48.7576965, lng: 17.9124329 },
    { lat: 48.7580749, lng: 17.9124761 },
    { lat: 48.7581385, lng: 17.9124831 },
    { lat: 48.7583147, lng: 17.9125035 },
    { lat: 48.7588639, lng: 17.9126068 },
    { lat: 48.7589004, lng: 17.9126138 },
    { lat: 48.759018, lng: 17.9126361 },
    { lat: 48.7592513, lng: 17.9127236 },
    { lat: 48.7595359, lng: 17.9128019 },
    { lat: 48.7596498, lng: 17.9128004 },
    { lat: 48.759687, lng: 17.9127995 },
    { lat: 48.759819, lng: 17.9127975 },
    { lat: 48.7600386, lng: 17.9126771 },
    { lat: 48.7601586, lng: 17.9125146 },
    { lat: 48.7602918, lng: 17.9120989 },
    { lat: 48.7603007, lng: 17.9120968 },
    { lat: 48.7603095, lng: 17.9120943 },
    { lat: 48.7605807, lng: 17.9120238 },
    { lat: 48.7606441, lng: 17.9120074 },
    { lat: 48.7607237, lng: 17.9120358 },
    { lat: 48.7611428, lng: 17.911854 },
    { lat: 48.761593, lng: 17.9116616 },
    { lat: 48.7617096, lng: 17.911612 },
    { lat: 48.7617415, lng: 17.9115981 },
    { lat: 48.7618079, lng: 17.9115754 },
    { lat: 48.7618422, lng: 17.9115634 },
    { lat: 48.7619782, lng: 17.9115161 },
    { lat: 48.761986, lng: 17.9115134 },
    { lat: 48.7619926, lng: 17.9115112 },
    { lat: 48.7620014, lng: 17.9115082 },
    { lat: 48.7620374, lng: 17.9114867 },
    { lat: 48.7620494, lng: 17.9114798 },
    { lat: 48.7620592, lng: 17.9114738 },
    { lat: 48.7620701, lng: 17.911468 },
    { lat: 48.7627559, lng: 17.9110644 },
    { lat: 48.7633532, lng: 17.9107098 },
    { lat: 48.7639825, lng: 17.9103432 },
    { lat: 48.7640087, lng: 17.9103282 },
    { lat: 48.7648537, lng: 17.9097934 },
    { lat: 48.7648795, lng: 17.9098843 },
    { lat: 48.764904, lng: 17.909974 },
    { lat: 48.764931, lng: 17.9100651 },
    { lat: 48.7649566, lng: 17.9101546 },
    { lat: 48.7650602, lng: 17.910527 },
    { lat: 48.7651358, lng: 17.910807 },
    { lat: 48.7651403, lng: 17.9108248 },
    { lat: 48.7652923, lng: 17.9107624 },
    { lat: 48.7653123, lng: 17.910833 },
    { lat: 48.7653342, lng: 17.9109171 },
    { lat: 48.7653442, lng: 17.9109508 },
    { lat: 48.7653546, lng: 17.9109921 },
    { lat: 48.7654477, lng: 17.9109264 },
    { lat: 48.7656587, lng: 17.9108488 },
    { lat: 48.7662305, lng: 17.9107288 },
    { lat: 48.7662689, lng: 17.910785 },
    { lat: 48.766289, lng: 17.9110835 },
    { lat: 48.766333, lng: 17.9112709 },
    { lat: 48.7664073, lng: 17.9114566 },
    { lat: 48.766544, lng: 17.9115899 },
    { lat: 48.7666127, lng: 17.9117748 },
    { lat: 48.7668509, lng: 17.9120571 },
    { lat: 48.767558, lng: 17.9123288 },
    { lat: 48.7678964, lng: 17.9125133 },
    { lat: 48.767953, lng: 17.9125725 },
    { lat: 48.7680239, lng: 17.9127191 },
    { lat: 48.7680609, lng: 17.9127901 },
    { lat: 48.7681145, lng: 17.9128564 },
    { lat: 48.7682291, lng: 17.9129414 },
    { lat: 48.7683756, lng: 17.9130306 },
    { lat: 48.7687165, lng: 17.9132397 },
    { lat: 48.7688753, lng: 17.9133827 },
    { lat: 48.7690201, lng: 17.9135394 },
    { lat: 48.7694668, lng: 17.9141562 },
    { lat: 48.7696139, lng: 17.9142943 },
    { lat: 48.7697929, lng: 17.914417 },
    { lat: 48.7699211, lng: 17.9144463 },
    { lat: 48.7701071, lng: 17.9144238 },
    { lat: 48.7707062, lng: 17.9142123 },
    { lat: 48.7708657, lng: 17.9141435 },
    { lat: 48.7710597, lng: 17.9141024 },
    { lat: 48.7718917, lng: 17.9140659 },
    { lat: 48.7718912, lng: 17.9140949 },
    { lat: 48.7718502, lng: 17.9144646 },
    { lat: 48.7718232, lng: 17.9147113 },
    { lat: 48.7717138, lng: 17.915278 },
    { lat: 48.7717843, lng: 17.9153053 },
    { lat: 48.7718421, lng: 17.9153282 },
    { lat: 48.7719011, lng: 17.9153512 },
    { lat: 48.7719588, lng: 17.9153737 },
    { lat: 48.7720825, lng: 17.915422 },
    { lat: 48.772146, lng: 17.9154466 },
    { lat: 48.7723136, lng: 17.915512 },
    { lat: 48.7728238, lng: 17.9152285 },
    { lat: 48.7729606, lng: 17.9151371 },
    { lat: 48.7741796, lng: 17.9141206 },
    { lat: 48.7743565, lng: 17.9141912 },
    { lat: 48.774438, lng: 17.9145139 },
    { lat: 48.7743409, lng: 17.914701 },
    { lat: 48.7743548, lng: 17.9149531 },
    { lat: 48.7743715, lng: 17.9152312 },
    { lat: 48.7741528, lng: 17.9156324 },
    { lat: 48.7741603, lng: 17.9158124 },
    { lat: 48.7745282, lng: 17.9163007 },
    { lat: 48.7747608, lng: 17.9166598 },
    { lat: 48.774909, lng: 17.917081 },
    { lat: 48.7749102, lng: 17.9170997 },
    { lat: 48.774912, lng: 17.91711 },
    { lat: 48.7750016, lng: 17.9184567 },
    { lat: 48.7750159, lng: 17.9186771 },
    { lat: 48.7750318, lng: 17.9189026 },
    { lat: 48.7750463, lng: 17.9191269 },
    { lat: 48.7750483, lng: 17.9191603 },
    { lat: 48.7754729, lng: 17.91939 },
    { lat: 48.7754928, lng: 17.9194008 },
    { lat: 48.7754326, lng: 17.9200738 },
    { lat: 48.775274, lng: 17.9208555 },
    { lat: 48.7749035, lng: 17.921434 },
    { lat: 48.7754886, lng: 17.9218564 },
    { lat: 48.7755417, lng: 17.9218948 },
    { lat: 48.7756042, lng: 17.9214498 },
    { lat: 48.7756477, lng: 17.9214597 },
    { lat: 48.7759027, lng: 17.9215153 },
    { lat: 48.7759422, lng: 17.9215333 },
    { lat: 48.7760943, lng: 17.9216034 },
    { lat: 48.7762352, lng: 17.9216379 },
    { lat: 48.7764459, lng: 17.9216892 },
    { lat: 48.7764361, lng: 17.9219395 },
    { lat: 48.7764262, lng: 17.9222082 },
    { lat: 48.776366, lng: 17.9226535 },
    { lat: 48.7763434, lng: 17.9228229 },
    { lat: 48.776321, lng: 17.9229958 },
    { lat: 48.7763004, lng: 17.9231612 },
    { lat: 48.7762812, lng: 17.9233113 },
    { lat: 48.7762687, lng: 17.9234043 },
    { lat: 48.7765891, lng: 17.9234006 },
    { lat: 48.7765062, lng: 17.9248451 },
    { lat: 48.7767581, lng: 17.9249045 },
    { lat: 48.7768645, lng: 17.9249293 },
    { lat: 48.7769487, lng: 17.9254865 },
    { lat: 48.7770045, lng: 17.9258533 },
    { lat: 48.7770549, lng: 17.9261859 },
    { lat: 48.7770558, lng: 17.9262015 },
    { lat: 48.7770953, lng: 17.926559 },
    { lat: 48.7771115, lng: 17.9267169 },
    { lat: 48.7771313, lng: 17.9268969 },
    { lat: 48.777145, lng: 17.9270309 },
    { lat: 48.7771927, lng: 17.927471 },
    { lat: 48.7779693, lng: 17.9271323 },
    { lat: 48.7781171, lng: 17.9274315 },
    { lat: 48.7782147, lng: 17.9276276 },
    { lat: 48.7783071, lng: 17.9278143 },
    { lat: 48.7783162, lng: 17.9278349 },
    { lat: 48.7780199, lng: 17.9282235 },
    { lat: 48.7779802, lng: 17.9282755 },
    { lat: 48.7787331, lng: 17.9287658 },
    { lat: 48.7791885, lng: 17.9288866 },
    { lat: 48.7793811, lng: 17.9289376 },
    { lat: 48.7793433, lng: 17.9293432 },
    { lat: 48.7792668, lng: 17.9298188 },
    { lat: 48.7792175, lng: 17.9302233 },
    { lat: 48.779192, lng: 17.9304375 },
    { lat: 48.7791766, lng: 17.9308592 },
    { lat: 48.7792126, lng: 17.9311211 },
    { lat: 48.7794009, lng: 17.9309852 },
    { lat: 48.7795621, lng: 17.9308683 },
    { lat: 48.7800449, lng: 17.9306011 },
    { lat: 48.780131, lng: 17.9307568 },
    { lat: 48.7802159, lng: 17.9309103 },
    { lat: 48.7797676, lng: 17.9315871 },
    { lat: 48.7796225, lng: 17.9318989 },
    { lat: 48.7798667, lng: 17.9323949 },
    { lat: 48.7798562, lng: 17.9324848 },
    { lat: 48.7798365, lng: 17.9326646 },
    { lat: 48.7797204, lng: 17.9329509 },
    { lat: 48.7794767, lng: 17.933179 },
    { lat: 48.7792318, lng: 17.933499 },
    { lat: 48.7791721, lng: 17.9337296 },
    { lat: 48.7788053, lng: 17.9340142 },
    { lat: 48.7784085, lng: 17.9341732 },
    { lat: 48.7779213, lng: 17.9343299 },
    { lat: 48.7779125, lng: 17.9341834 },
    { lat: 48.7774588, lng: 17.934298 },
    { lat: 48.7770849, lng: 17.934388 },
    { lat: 48.7770693, lng: 17.9344865 },
    { lat: 48.776879, lng: 17.9345877 },
    { lat: 48.776357, lng: 17.93492 },
    { lat: 48.776375, lng: 17.9350307 },
    { lat: 48.7761313, lng: 17.9351662 },
    { lat: 48.7759161, lng: 17.9352862 },
    { lat: 48.7754951, lng: 17.9355111 },
    { lat: 48.7753708, lng: 17.9355102 },
    { lat: 48.7751527, lng: 17.9356007 },
    { lat: 48.77503, lng: 17.9364935 },
    { lat: 48.7750984, lng: 17.9367882 },
    { lat: 48.7754419, lng: 17.9367369 },
    { lat: 48.7758078, lng: 17.9366086 },
    { lat: 48.7761283, lng: 17.9363227 },
    { lat: 48.7763676, lng: 17.9361166 },
    { lat: 48.7767262, lng: 17.9359033 },
    { lat: 48.7772249, lng: 17.93556 },
    { lat: 48.77833, lng: 17.9352393 },
    { lat: 48.7783807, lng: 17.9355201 },
    { lat: 48.7785209, lng: 17.9356572 },
    { lat: 48.7786177, lng: 17.9358601 },
    { lat: 48.7788608, lng: 17.9369062 },
    { lat: 48.7786442, lng: 17.9369264 },
    { lat: 48.7778007, lng: 17.937395 },
    { lat: 48.7774965, lng: 17.9375928 },
    { lat: 48.7770689, lng: 17.9379177 },
    { lat: 48.7766576, lng: 17.9382105 },
    { lat: 48.776384, lng: 17.9383746 },
    { lat: 48.7761009, lng: 17.9384536 },
    { lat: 48.7760389, lng: 17.938436 },
    { lat: 48.7757662, lng: 17.9382927 },
    { lat: 48.7755642, lng: 17.9383681 },
    { lat: 48.7752657, lng: 17.9383791 },
    { lat: 48.7751382, lng: 17.9394203 },
    { lat: 48.7753377, lng: 17.9395459 },
    { lat: 48.7755875, lng: 17.939703 },
    { lat: 48.7758637, lng: 17.9400179 },
    { lat: 48.7759786, lng: 17.9401491 },
    { lat: 48.7765578, lng: 17.9408311 },
    { lat: 48.7767082, lng: 17.9408909 },
    { lat: 48.7766685, lng: 17.9413991 },
    { lat: 48.7766616, lng: 17.9414912 },
    { lat: 48.77665, lng: 17.9416373 },
    { lat: 48.7766417, lng: 17.9417444 },
    { lat: 48.7769416, lng: 17.9426838 },
    { lat: 48.7772519, lng: 17.9435323 },
    { lat: 48.7773734, lng: 17.9438662 },
    { lat: 48.776891, lng: 17.9437657 },
    { lat: 48.7768661, lng: 17.9437648 },
    { lat: 48.7768403, lng: 17.9439187 },
    { lat: 48.7768183, lng: 17.9442491 },
    { lat: 48.7768025, lng: 17.9443427 },
    { lat: 48.7768027, lng: 17.9444417 },
    { lat: 48.7767595, lng: 17.9447759 },
    { lat: 48.7766926, lng: 17.9449234 },
    { lat: 48.7766015, lng: 17.9451745 },
    { lat: 48.7765977, lng: 17.9452232 },
    { lat: 48.7765223, lng: 17.9454172 },
    { lat: 48.7764757, lng: 17.9455056 },
    { lat: 48.7763694, lng: 17.9456929 },
    { lat: 48.7763789, lng: 17.945699 },
    { lat: 48.7763867, lng: 17.9457167 },
    { lat: 48.7762875, lng: 17.9458698 },
    { lat: 48.7761935, lng: 17.9460546 },
    { lat: 48.7760775, lng: 17.9463412 },
    { lat: 48.7759093, lng: 17.9466272 },
    { lat: 48.7757746, lng: 17.9468852 },
    { lat: 48.7756224, lng: 17.9471139 },
    { lat: 48.7755136, lng: 17.9473909 },
    { lat: 48.7754702, lng: 17.9474949 },
    { lat: 48.7753868, lng: 17.9478752 },
    { lat: 48.7753644, lng: 17.9478791 },
    { lat: 48.7751546, lng: 17.947921 },
    { lat: 48.7750744, lng: 17.9480503 },
    { lat: 48.775059, lng: 17.9480967 },
    { lat: 48.7750532, lng: 17.9481492 },
    { lat: 48.775019, lng: 17.9482581 },
    { lat: 48.7749561, lng: 17.9483951 },
    { lat: 48.7748853, lng: 17.9485145 },
    { lat: 48.7748542, lng: 17.948579 },
    { lat: 48.774758, lng: 17.9486698 },
    { lat: 48.7746937, lng: 17.9488012 },
    { lat: 48.774583, lng: 17.9489152 },
    { lat: 48.7745338, lng: 17.9489983 },
    { lat: 48.7744445, lng: 17.9490909 },
    { lat: 48.7743556, lng: 17.9491717 },
    { lat: 48.7743092, lng: 17.949263 },
    { lat: 48.7742564, lng: 17.9495139 },
    { lat: 48.7741708, lng: 17.9495924 },
    { lat: 48.7741121, lng: 17.9496687 },
    { lat: 48.7741002, lng: 17.9497527 },
    { lat: 48.7740283, lng: 17.9498525 },
    { lat: 48.7740048, lng: 17.9498557 },
    { lat: 48.7738804, lng: 17.9499077 },
    { lat: 48.7735158, lng: 17.9500613 },
    { lat: 48.7734399, lng: 17.9500933 },
    { lat: 48.773375, lng: 17.9501206 },
    { lat: 48.7726936, lng: 17.9498618 },
    { lat: 48.7723737, lng: 17.9486055 },
    { lat: 48.7719916, lng: 17.9478017 },
    { lat: 48.7719064, lng: 17.9476033 },
    { lat: 48.771648, lng: 17.9471494 },
    { lat: 48.7714148, lng: 17.9467415 },
    { lat: 48.7710237, lng: 17.9459181 },
    { lat: 48.7709713, lng: 17.9459488 },
    { lat: 48.770236, lng: 17.9448808 },
    { lat: 48.7699127, lng: 17.944551 },
    { lat: 48.7694881, lng: 17.9441323 },
    { lat: 48.7693205, lng: 17.9444074 },
    { lat: 48.7693787, lng: 17.9448541 },
    { lat: 48.7694462, lng: 17.9453776 },
    { lat: 48.7697304, lng: 17.9468683 },
    { lat: 48.7696489, lng: 17.9467317 },
    { lat: 48.7696239, lng: 17.9466913 },
    { lat: 48.7695411, lng: 17.9465517 },
    { lat: 48.7694471, lng: 17.9463955 },
    { lat: 48.769341, lng: 17.9460737 },
    { lat: 48.7692761, lng: 17.9458771 },
    { lat: 48.7692363, lng: 17.9456053 },
    { lat: 48.769143, lng: 17.9449704 },
    { lat: 48.7687804, lng: 17.9441695 },
    { lat: 48.768595, lng: 17.9438256 },
    { lat: 48.7685264, lng: 17.9434892 },
    { lat: 48.7683894, lng: 17.9433311 },
    { lat: 48.7679231, lng: 17.9431968 },
    { lat: 48.7689718, lng: 17.9460921 },
    { lat: 48.769631, lng: 17.9480024 },
    { lat: 48.7696695, lng: 17.9481172 },
    { lat: 48.7696885, lng: 17.9481713 },
    { lat: 48.7697613, lng: 17.9483898 },
    { lat: 48.7697969, lng: 17.9484943 },
    { lat: 48.7701222, lng: 17.9494629 },
    { lat: 48.7710332, lng: 17.9519242 },
    { lat: 48.7716938, lng: 17.9537479 },
    { lat: 48.7723542, lng: 17.9555482 },
    { lat: 48.7729368, lng: 17.9571619 },
    { lat: 48.7734818, lng: 17.9587114 },
    { lat: 48.7739186, lng: 17.9599809 },
    { lat: 48.7744337, lng: 17.9615432 },
    { lat: 48.7751227, lng: 17.9635216 },
    { lat: 48.7756673, lng: 17.9648214 },
    { lat: 48.7761549, lng: 17.9663951 },
    { lat: 48.77629, lng: 17.966316 },
    { lat: 48.776548, lng: 17.96615 },
    { lat: 48.776784, lng: 17.966003 },
    { lat: 48.776992, lng: 17.965869 },
    { lat: 48.777137, lng: 17.965777 },
    { lat: 48.777288, lng: 17.965751 },
    { lat: 48.77743, lng: 17.965734 },
    { lat: 48.777545, lng: 17.96571 },
    { lat: 48.77764, lng: 17.965757 },
    { lat: 48.777885, lng: 17.965882 },
    { lat: 48.777993, lng: 17.965927 },
    { lat: 48.778404, lng: 17.966073 },
    { lat: 48.778557, lng: 17.966129 },
    { lat: 48.778989, lng: 17.966286 },
    { lat: 48.779234, lng: 17.966376 },
    { lat: 48.779333, lng: 17.966413 },
    { lat: 48.77963, lng: 17.966513 },
    { lat: 48.779937, lng: 17.966617 },
    { lat: 48.780139, lng: 17.966672 },
    { lat: 48.78035, lng: 17.96673 },
    { lat: 48.780524, lng: 17.966781 },
    { lat: 48.780579, lng: 17.966808 },
    { lat: 48.780723, lng: 17.966881 },
    { lat: 48.780851, lng: 17.966927 },
    { lat: 48.781038, lng: 17.966998 },
    { lat: 48.781233, lng: 17.967069 },
    { lat: 48.781594, lng: 17.96716 },
    { lat: 48.781729, lng: 17.967214 },
    { lat: 48.781969, lng: 17.967319 },
    { lat: 48.782279, lng: 17.967452 },
    { lat: 48.782529, lng: 17.967533 },
    { lat: 48.782803, lng: 17.96763 },
    { lat: 48.782928, lng: 17.967611 },
    { lat: 48.783068, lng: 17.967592 },
    { lat: 48.783141, lng: 17.967622 },
    { lat: 48.78328, lng: 17.967624 },
    { lat: 48.783537, lng: 17.96763 },
    { lat: 48.783684, lng: 17.967583 },
    { lat: 48.783843, lng: 17.967531 },
    { lat: 48.784107, lng: 17.967368 },
    { lat: 48.784305, lng: 17.967358 },
    { lat: 48.784436, lng: 17.967385 },
    { lat: 48.784485, lng: 17.967411 },
    { lat: 48.784704, lng: 17.967369 },
    { lat: 48.784899, lng: 17.967296 },
    { lat: 48.785174, lng: 17.967194 },
    { lat: 48.785408, lng: 17.96728 },
    { lat: 48.785755, lng: 17.967408 },
    { lat: 48.785972, lng: 17.967574 },
    { lat: 48.786231, lng: 17.967767 },
    { lat: 48.786365, lng: 17.967867 },
    { lat: 48.786614, lng: 17.968055 },
    { lat: 48.786812, lng: 17.968208 },
    { lat: 48.786924, lng: 17.9683 },
    { lat: 48.787215, lng: 17.968547 },
    { lat: 48.787382, lng: 17.968681 },
    { lat: 48.787644, lng: 17.968894 },
    { lat: 48.787999, lng: 17.968951 },
    { lat: 48.788261, lng: 17.968994 },
    { lat: 48.788361, lng: 17.969009 },
    { lat: 48.78858, lng: 17.969012 },
    { lat: 48.788809, lng: 17.969015 },
    { lat: 48.78905, lng: 17.969019 },
    { lat: 48.789296, lng: 17.969022 },
    { lat: 48.789545, lng: 17.969028 },
    { lat: 48.789762, lng: 17.968997 },
    { lat: 48.790082, lng: 17.968958 },
    { lat: 48.790289, lng: 17.968855 },
    { lat: 48.790514, lng: 17.968749 },
    { lat: 48.790718, lng: 17.968654 },
    { lat: 48.790826, lng: 17.968602 },
    { lat: 48.79102, lng: 17.968511 },
    { lat: 48.791338, lng: 17.96836 },
    { lat: 48.791433, lng: 17.968312 },
    { lat: 48.791486, lng: 17.968296 },
    { lat: 48.791708, lng: 17.96822 },
    { lat: 48.791939, lng: 17.968139 },
    { lat: 48.79208, lng: 17.968088 },
    { lat: 48.792256, lng: 17.968028 },
    { lat: 48.792383, lng: 17.967981 },
    { lat: 48.792601, lng: 17.967906 },
    { lat: 48.79278, lng: 17.967842 },
    { lat: 48.792946, lng: 17.967795 },
    { lat: 48.793208, lng: 17.96772 },
    { lat: 48.79348, lng: 17.96763 },
    { lat: 48.793656, lng: 17.967568 },
    { lat: 48.793804, lng: 17.967517 },
    { lat: 48.793883, lng: 17.967491 },
    { lat: 48.793935, lng: 17.967471 },
    { lat: 48.793999, lng: 17.967422 },
    { lat: 48.794258, lng: 17.967229 },
    { lat: 48.794448, lng: 17.967087 },
    { lat: 48.794623, lng: 17.966947 },
    { lat: 48.794965, lng: 17.966666 },
    { lat: 48.795101, lng: 17.96656 },
    { lat: 48.795394, lng: 17.966523 },
    { lat: 48.795551, lng: 17.966502 },
    { lat: 48.795534, lng: 17.965617 },
    { lat: 48.795543, lng: 17.964891 },
    { lat: 48.795549, lng: 17.964247 },
    { lat: 48.795541, lng: 17.963788 },
    { lat: 48.795519, lng: 17.962429 },
    { lat: 48.795388, lng: 17.961066 },
    { lat: 48.794994, lng: 17.95962 },
    { lat: 48.795862, lng: 17.958132 },
    { lat: 48.795481, lng: 17.957712 },
    { lat: 48.795454, lng: 17.957685 },
    { lat: 48.795111, lng: 17.957599 },
    { lat: 48.794988, lng: 17.957572 },
    { lat: 48.79485, lng: 17.957484 },
    { lat: 48.794856, lng: 17.957465 },
    { lat: 48.794671, lng: 17.956723 },
    { lat: 48.794418, lng: 17.955623 },
    { lat: 48.794132, lng: 17.953865 },
    { lat: 48.794182, lng: 17.951827 },
    { lat: 48.794165, lng: 17.951416 },
    { lat: 48.794151, lng: 17.950714 },
    { lat: 48.794167, lng: 17.949983 },
    { lat: 48.794168, lng: 17.949968 },
    { lat: 48.794249, lng: 17.9492 },
    { lat: 48.794345, lng: 17.948445 },
    { lat: 48.794396, lng: 17.94792 },
    { lat: 48.794366, lng: 17.947581 },
    { lat: 48.794358, lng: 17.947323 },
    { lat: 48.794411, lng: 17.946922 },
    { lat: 48.794445, lng: 17.946711 },
    { lat: 48.79444, lng: 17.946573 },
    { lat: 48.794366, lng: 17.946278 },
    { lat: 48.794296, lng: 17.945376 },
    { lat: 48.794332, lng: 17.945129 },
    { lat: 48.794424, lng: 17.944648 },
    { lat: 48.794516, lng: 17.944121 },
    { lat: 48.794535, lng: 17.943412 },
    { lat: 48.794508, lng: 17.943121 },
    { lat: 48.794439, lng: 17.942895 },
    { lat: 48.79429, lng: 17.942543 },
    { lat: 48.794021, lng: 17.941918 },
    { lat: 48.793883, lng: 17.941387 },
    { lat: 48.793809, lng: 17.940977 },
    { lat: 48.79376, lng: 17.940622 },
    { lat: 48.793743, lng: 17.940327 },
    { lat: 48.793709, lng: 17.940148 },
    { lat: 48.793725, lng: 17.939626 },
    { lat: 48.793798, lng: 17.939308 },
    { lat: 48.794019, lng: 17.938892 },
    { lat: 48.79419, lng: 17.938548 },
    { lat: 48.794351, lng: 17.938314 },
    { lat: 48.794582, lng: 17.938117 },
    { lat: 48.794808, lng: 17.937891 },
    { lat: 48.795047, lng: 17.937568 },
    { lat: 48.795197, lng: 17.937409 },
    { lat: 48.795298, lng: 17.937376 },
    { lat: 48.795684, lng: 17.937253 },
    { lat: 48.796107, lng: 17.937328 },
    { lat: 48.796185, lng: 17.937307 },
    { lat: 48.796188, lng: 17.937306 },
    { lat: 48.796279, lng: 17.937259 },
    { lat: 48.79642, lng: 17.937128 },
    { lat: 48.796582, lng: 17.93692 },
    { lat: 48.796647, lng: 17.936689 },
    { lat: 48.796608, lng: 17.936536 },
    { lat: 48.796513, lng: 17.936376 },
    { lat: 48.796295, lng: 17.936147 },
    { lat: 48.796184, lng: 17.935905 },
    { lat: 48.796102, lng: 17.935425 },
    { lat: 48.796202, lng: 17.934829 },
    { lat: 48.796213, lng: 17.934622 },
    { lat: 48.796221, lng: 17.934592 },
    { lat: 48.796463, lng: 17.933776 },
    { lat: 48.7958, lng: 17.932643 },
    { lat: 48.796061, lng: 17.932257 },
    { lat: 48.796239, lng: 17.932026 },
    { lat: 48.796358, lng: 17.93195 },
    { lat: 48.796543, lng: 17.931849 },
    { lat: 48.796678, lng: 17.931761 },
    { lat: 48.79673, lng: 17.931663 },
    { lat: 48.796964, lng: 17.931266 },
    { lat: 48.797114, lng: 17.930963 },
    { lat: 48.797153, lng: 17.930827 },
    { lat: 48.797279, lng: 17.930645 },
    { lat: 48.797325, lng: 17.930612 },
    { lat: 48.797375, lng: 17.930537 },
    { lat: 48.797416, lng: 17.93052 },
    { lat: 48.797432, lng: 17.930484 },
    { lat: 48.79746, lng: 17.930448 },
    { lat: 48.797544, lng: 17.930409 },
    { lat: 48.79774, lng: 17.930164 },
    { lat: 48.797917, lng: 17.930119 },
    { lat: 48.797971, lng: 17.930097 },
    { lat: 48.798088, lng: 17.930092 },
    { lat: 48.798175, lng: 17.930016 },
    { lat: 48.798304, lng: 17.929985 },
    { lat: 48.798356, lng: 17.929811 },
    { lat: 48.798993, lng: 17.930144 },
    { lat: 48.799152, lng: 17.930292 },
    { lat: 48.799288, lng: 17.930394 },
    { lat: 48.799514, lng: 17.93048 },
    { lat: 48.799734, lng: 17.930517 },
    { lat: 48.800021, lng: 17.930485 },
    { lat: 48.800338, lng: 17.93055 },
    { lat: 48.800347, lng: 17.930261 },
    { lat: 48.800351, lng: 17.930106 },
    { lat: 48.800441, lng: 17.930064 },
    { lat: 48.80065, lng: 17.929945 },
    { lat: 48.801274, lng: 17.929823 },
    { lat: 48.801284, lng: 17.929754 },
    { lat: 48.801289, lng: 17.929713 },
    { lat: 48.8013, lng: 17.929632 },
    { lat: 48.801775, lng: 17.92976 },
    { lat: 48.802413, lng: 17.930104 },
    { lat: 48.802576, lng: 17.930294 },
    { lat: 48.802673, lng: 17.930466 },
    { lat: 48.80294, lng: 17.930139 },
    { lat: 48.803086, lng: 17.930392 },
    { lat: 48.803361, lng: 17.930153 },
    { lat: 48.803543, lng: 17.930469 },
    { lat: 48.803721, lng: 17.930776 },
    { lat: 48.804086, lng: 17.931392 },
    { lat: 48.80432, lng: 17.931525 },
    { lat: 48.804504, lng: 17.931636 },
    { lat: 48.805485, lng: 17.932177 },
    { lat: 48.805741, lng: 17.932264 },
    { lat: 48.805841, lng: 17.932298 },
    { lat: 48.805849, lng: 17.9323 },
    { lat: 48.80588, lng: 17.932311 },
    { lat: 48.805922, lng: 17.932325 },
    { lat: 48.806074, lng: 17.932423 },
    { lat: 48.806198, lng: 17.932501 },
    { lat: 48.806324, lng: 17.932581 },
    { lat: 48.806408, lng: 17.932634 },
    { lat: 48.806446, lng: 17.932659 },
    { lat: 48.806486, lng: 17.932684 },
    { lat: 48.806519, lng: 17.932705 },
    { lat: 48.806548, lng: 17.932724 },
    { lat: 48.806603, lng: 17.932758 },
    { lat: 48.806649, lng: 17.932787 },
    { lat: 48.806674, lng: 17.932803 },
    { lat: 48.806818, lng: 17.932895 },
    { lat: 48.806918, lng: 17.932783 },
    { lat: 48.807239, lng: 17.932228 },
    { lat: 48.807322, lng: 17.932025 },
    { lat: 48.807401, lng: 17.931771 },
    { lat: 48.807559, lng: 17.931135 },
    { lat: 48.807638, lng: 17.930956 },
    { lat: 48.80781, lng: 17.930745 },
    { lat: 48.808056, lng: 17.930508 },
    { lat: 48.808106, lng: 17.93046 },
    { lat: 48.808134, lng: 17.930434 },
    { lat: 48.808213, lng: 17.930348 },
    { lat: 48.808245, lng: 17.930177 },
    { lat: 48.808337, lng: 17.930083 },
    { lat: 48.808777, lng: 17.929661 },
    { lat: 48.808877, lng: 17.929583 },
    { lat: 48.809132, lng: 17.929092 },
    { lat: 48.80915, lng: 17.929052 },
    { lat: 48.809188, lng: 17.92897 },
    { lat: 48.809195, lng: 17.92893 },
    { lat: 48.809236, lng: 17.92899 },
    { lat: 48.809267, lng: 17.929008 },
    { lat: 48.809322, lng: 17.929046 },
    { lat: 48.809473, lng: 17.929214 },
    { lat: 48.809519, lng: 17.929541 },
    { lat: 48.809609, lng: 17.929822 },
    { lat: 48.80991, lng: 17.93036 },
    { lat: 48.81008, lng: 17.930515 },
    { lat: 48.810118, lng: 17.930465 },
    { lat: 48.810317, lng: 17.930205 },
    { lat: 48.810338, lng: 17.930177 },
    { lat: 48.81035, lng: 17.93016 },
    { lat: 48.810391, lng: 17.930107 },
    { lat: 48.81081, lng: 17.929559 },
    { lat: 48.81084, lng: 17.929518 },
    { lat: 48.810809, lng: 17.929415 },
    { lat: 48.811355, lng: 17.928912 },
    { lat: 48.811385, lng: 17.928394 },
    { lat: 48.811355, lng: 17.927471 },
    { lat: 48.811398, lng: 17.926461 },
    { lat: 48.811447, lng: 17.924802 },
    { lat: 48.811453, lng: 17.924757 },
    { lat: 48.811505, lng: 17.924312 },
    { lat: 48.811483, lng: 17.924016 },
    { lat: 48.811501, lng: 17.923798 },
    { lat: 48.81143, lng: 17.923614 },
    { lat: 48.81134, lng: 17.923408 },
    { lat: 48.811333, lng: 17.923192 },
    { lat: 48.811382, lng: 17.922856 },
    { lat: 48.811541, lng: 17.922274 },
    { lat: 48.811536, lng: 17.921843 },
    { lat: 48.811525, lng: 17.920969 },
    { lat: 48.811524, lng: 17.920932 },
    { lat: 48.811523, lng: 17.920858 },
    { lat: 48.811523, lng: 17.920774 },
    { lat: 48.811522, lng: 17.920725 },
    { lat: 48.811505, lng: 17.920686 },
    { lat: 48.811508, lng: 17.920639 },
    { lat: 48.811505, lng: 17.920513 },
    { lat: 48.811503, lng: 17.920294 },
    { lat: 48.811503, lng: 17.920277 },
    { lat: 48.811492, lng: 17.919506 },
    { lat: 48.811485, lng: 17.918917 },
    { lat: 48.811463, lng: 17.917393 },
    { lat: 48.811462, lng: 17.917015 },
    { lat: 48.811444, lng: 17.915729 },
    { lat: 48.811429, lng: 17.914613 },
    { lat: 48.811407, lng: 17.912904 },
    { lat: 48.811395, lng: 17.911991 },
    { lat: 48.81139, lng: 17.91128 },
    { lat: 48.811381, lng: 17.910642 },
    { lat: 48.811372, lng: 17.909894 },
    { lat: 48.811365, lng: 17.909179 },
    { lat: 48.811354, lng: 17.908653 },
    { lat: 48.81138, lng: 17.908474 },
    { lat: 48.811399, lng: 17.908329 },
    { lat: 48.811427, lng: 17.908117 },
    { lat: 48.811434, lng: 17.908059 },
    { lat: 48.811442, lng: 17.907884 },
    { lat: 48.811447, lng: 17.907794 },
    { lat: 48.811481, lng: 17.907095 },
    { lat: 48.811563, lng: 17.906594 },
    { lat: 48.811598, lng: 17.906376 },
    { lat: 48.811616, lng: 17.906273 },
    { lat: 48.811616, lng: 17.906272 },
    { lat: 48.811619, lng: 17.906252 },
    { lat: 48.81162, lng: 17.906247 },
    { lat: 48.811635, lng: 17.90615 },
    { lat: 48.811659, lng: 17.905973 },
    { lat: 48.811729, lng: 17.905429 },
    { lat: 48.81174, lng: 17.905146 },
    { lat: 48.811751, lng: 17.904839 },
    { lat: 48.811755, lng: 17.90475 },
    { lat: 48.811756, lng: 17.904726 },
    { lat: 48.811767, lng: 17.904395 },
    { lat: 48.81178, lng: 17.903702 },
    { lat: 48.811671, lng: 17.902283 },
    { lat: 48.811619, lng: 17.901649 },
    { lat: 48.811617, lng: 17.901605 },
    { lat: 48.811874, lng: 17.901202 },
    { lat: 48.812089, lng: 17.900957 },
    { lat: 48.812234, lng: 17.900793 },
    { lat: 48.81267, lng: 17.900297 },
    { lat: 48.81283, lng: 17.9001 },
    { lat: 48.812834, lng: 17.900095 },
    { lat: 48.812571, lng: 17.899024 },
    { lat: 48.812419, lng: 17.898397 },
    { lat: 48.812318, lng: 17.897117 },
    { lat: 48.812375, lng: 17.896211 },
    { lat: 48.812563, lng: 17.894087 },
    { lat: 48.812576, lng: 17.893932 },
    { lat: 48.812586, lng: 17.893783 },
    { lat: 48.812724, lng: 17.893778 },
    { lat: 48.812948, lng: 17.893543 },
    { lat: 48.812972, lng: 17.89352 },
    { lat: 48.812987, lng: 17.893502 },
    { lat: 48.813546, lng: 17.892829 },
    { lat: 48.813797, lng: 17.89246 },
    { lat: 48.814058, lng: 17.892021 },
    { lat: 48.814184, lng: 17.891719 },
    { lat: 48.814242, lng: 17.891499 },
    { lat: 48.814245, lng: 17.891449 },
    { lat: 48.814252, lng: 17.891391 },
    { lat: 48.814254, lng: 17.891375 },
    { lat: 48.814264, lng: 17.891293 },
    { lat: 48.814013, lng: 17.890916 },
    { lat: 48.813973, lng: 17.890849 },
    { lat: 48.813698, lng: 17.890445 },
    { lat: 48.813545, lng: 17.890211 },
    { lat: 48.813479, lng: 17.890126 },
    { lat: 48.813418, lng: 17.89003 },
    { lat: 48.813374, lng: 17.889968 },
    { lat: 48.81331, lng: 17.889874 },
    { lat: 48.813233, lng: 17.889761 },
    { lat: 48.81304, lng: 17.889475 },
    { lat: 48.812927, lng: 17.88931 },
    { lat: 48.812812, lng: 17.889144 },
    { lat: 48.812747, lng: 17.889042 },
    { lat: 48.812689, lng: 17.888956 },
    { lat: 48.812626, lng: 17.888866 },
    { lat: 48.812584, lng: 17.888803 },
    { lat: 48.812531, lng: 17.888734 },
    { lat: 48.812428, lng: 17.888582 },
    { lat: 48.812128, lng: 17.888151 },
    { lat: 48.812032, lng: 17.888297 },
    { lat: 48.812028, lng: 17.888304 },
    { lat: 48.811999, lng: 17.888345 },
    { lat: 48.81193, lng: 17.888448 },
    { lat: 48.811924, lng: 17.888458 },
    { lat: 48.811906, lng: 17.888482 },
    { lat: 48.81177, lng: 17.888694 },
    { lat: 48.811462, lng: 17.888232 },
    { lat: 48.81142, lng: 17.888165 },
    { lat: 48.811172, lng: 17.887783 },
    { lat: 48.810743, lng: 17.887115 },
    { lat: 48.810727, lng: 17.887089 },
    { lat: 48.810716, lng: 17.887071 },
    { lat: 48.810654, lng: 17.886964 },
    { lat: 48.810647, lng: 17.886952 },
    { lat: 48.810619, lng: 17.886901 },
    { lat: 48.809323, lng: 17.884459 },
    { lat: 48.808656, lng: 17.883202 },
    { lat: 48.808429, lng: 17.882148 },
    { lat: 48.808426, lng: 17.882128 },
    { lat: 48.808415, lng: 17.882082 },
    { lat: 48.807659, lng: 17.880498 },
    { lat: 48.807092, lng: 17.879311 },
    { lat: 48.804967, lng: 17.877222 },
    { lat: 48.8049196, lng: 17.8774243 },
    { lat: 48.8048828, lng: 17.8774695 },
    { lat: 48.8047, lng: 17.8776956 },
    { lat: 48.8045802, lng: 17.8778438 },
    { lat: 48.8040232, lng: 17.8780979 },
    { lat: 48.8034275, lng: 17.8783843 },
    { lat: 48.8027876, lng: 17.8784803 },
    { lat: 48.8024147, lng: 17.8785866 },
    { lat: 48.8024069, lng: 17.8785889 },
    { lat: 48.8023736, lng: 17.8785981 },
    { lat: 48.8023382, lng: 17.8786082 },
    { lat: 48.8023425, lng: 17.8786622 },
    { lat: 48.802291, lng: 17.8787258 },
    { lat: 48.80193, lng: 17.8781652 },
    { lat: 48.8016913, lng: 17.8778397 },
    { lat: 48.8013155, lng: 17.8773169 },
    { lat: 48.8009549, lng: 17.8768969 },
    { lat: 48.800914, lng: 17.8768363 },
    { lat: 48.8008985, lng: 17.8768594 },
    { lat: 48.8008819, lng: 17.8768848 },
    { lat: 48.8008675, lng: 17.8769063 },
    { lat: 48.8008623, lng: 17.8769143 },
    { lat: 48.7998089, lng: 17.8770598 },
    { lat: 48.7996711, lng: 17.8770784 },
    { lat: 48.799183, lng: 17.8771453 },
    { lat: 48.7988258, lng: 17.8771934 },
    { lat: 48.798809, lng: 17.8771957 },
    { lat: 48.7987553, lng: 17.8772027 },
    { lat: 48.7987161, lng: 17.8772081 },
    { lat: 48.7981965, lng: 17.8772786 },
    { lat: 48.7980208, lng: 17.8773023 },
    { lat: 48.7979625, lng: 17.8773104 },
    { lat: 48.797803, lng: 17.8773038 },
    { lat: 48.79764, lng: 17.8772976 },
    { lat: 48.7975077, lng: 17.8772924 },
    { lat: 48.7966254, lng: 17.8762323 },
    { lat: 48.7966107, lng: 17.8762147 },
    { lat: 48.7957078, lng: 17.87513 },
    { lat: 48.7954072, lng: 17.8747688 },
    { lat: 48.7953209, lng: 17.8746654 },
    { lat: 48.7951102, lng: 17.8744122 },
    { lat: 48.794991, lng: 17.8742684 },
    { lat: 48.7951044, lng: 17.8738457 },
    { lat: 48.7949888, lng: 17.8736703 },
    { lat: 48.7947502, lng: 17.8733076 },
    { lat: 48.794421, lng: 17.8728091 },
    { lat: 48.7940146, lng: 17.8721925 },
    { lat: 48.7940097, lng: 17.8721855 },
    { lat: 48.793601, lng: 17.8724075 },
    { lat: 48.7935647, lng: 17.872181 },
    { lat: 48.7935247, lng: 17.8719295 },
    { lat: 48.7934867, lng: 17.8716913 },
    { lat: 48.7934483, lng: 17.8714492 },
    { lat: 48.7934313, lng: 17.8713358 },
    { lat: 48.7934096, lng: 17.8711991 },
    { lat: 48.7933866, lng: 17.8711545 },
    { lat: 48.7933391, lng: 17.8710597 },
    { lat: 48.7933017, lng: 17.8709835 },
    { lat: 48.793271, lng: 17.8709227 },
    { lat: 48.7932453, lng: 17.8708708 },
    { lat: 48.7932196, lng: 17.8708192 },
    { lat: 48.7931974, lng: 17.8707702 },
    { lat: 48.7931741, lng: 17.8707571 },
    { lat: 48.793035, lng: 17.8706786 },
    { lat: 48.790899, lng: 17.8694771 },
    { lat: 48.7907783, lng: 17.8695535 },
    { lat: 48.7906695, lng: 17.8696224 },
    { lat: 48.7903336, lng: 17.869835 },
    { lat: 48.7903388, lng: 17.8698654 },
    { lat: 48.7903464, lng: 17.8699161 },
    { lat: 48.7896693, lng: 17.8701621 },
    { lat: 48.7893688, lng: 17.8702715 },
    { lat: 48.7893361, lng: 17.8702905 },
    { lat: 48.7893143, lng: 17.8703037 },
    { lat: 48.7893088, lng: 17.8703075 },
    { lat: 48.7888318, lng: 17.8706895 },
    { lat: 48.7888071, lng: 17.8707092 },
    { lat: 48.7887761, lng: 17.8707386 },
    { lat: 48.7887569, lng: 17.8707566 },
    { lat: 48.7886191, lng: 17.8708863 },
    { lat: 48.7885946, lng: 17.8709094 },
    { lat: 48.7881973, lng: 17.871285 },
    { lat: 48.7881694, lng: 17.8712922 },
    { lat: 48.7881351, lng: 17.8713016 },
    { lat: 48.7879795, lng: 17.8713432 },
    { lat: 48.7879496, lng: 17.8713512 },
    { lat: 48.7878596, lng: 17.8713751 },
    { lat: 48.7872908, lng: 17.8715276 },
    { lat: 48.7859944, lng: 17.8718749 },
    { lat: 48.7859501, lng: 17.87189 },
    { lat: 48.785289, lng: 17.8721211 },
    { lat: 48.7852315, lng: 17.8721409 },
    { lat: 48.784588, lng: 17.8723656 },
    { lat: 48.7845781, lng: 17.8723691 },
    { lat: 48.7844057, lng: 17.8724296 },
    { lat: 48.7839098, lng: 17.8725917 },
    { lat: 48.7838976, lng: 17.8725955 },
    { lat: 48.7837136, lng: 17.8726533 },
    { lat: 48.7834323, lng: 17.8727421 },
    { lat: 48.7834223, lng: 17.8727453 },
    { lat: 48.7832838, lng: 17.8727887 },
    { lat: 48.7832794, lng: 17.8727902 },
    { lat: 48.7832616, lng: 17.8727956 },
    { lat: 48.783234, lng: 17.8728046 },
    { lat: 48.7832118, lng: 17.872811 },
    { lat: 48.7832041, lng: 17.8728139 },
    { lat: 48.7831853, lng: 17.8728199 },
    { lat: 48.7831519, lng: 17.87283 },
    { lat: 48.7831321, lng: 17.8728362 },
    { lat: 48.7831043, lng: 17.872845 },
    { lat: 48.7830756, lng: 17.8728542 },
    { lat: 48.7828252, lng: 17.8729331 },
    { lat: 48.7824857, lng: 17.8729578 },
    { lat: 48.7821571, lng: 17.8728978 },
    { lat: 48.7820164, lng: 17.8728505 },
    { lat: 48.7818551, lng: 17.872796 },
    { lat: 48.7817911, lng: 17.8727635 },
    { lat: 48.7815221, lng: 17.8726272 },
    { lat: 48.7812608, lng: 17.872431 },
    { lat: 48.781018, lng: 17.872203 },
    { lat: 48.780835, lng: 17.8719969 },
    { lat: 48.7807211, lng: 17.8718675 },
    { lat: 48.7805184, lng: 17.8715757 },
    { lat: 48.7803799, lng: 17.8713765 },
    { lat: 48.7798842, lng: 17.8706623 },
    { lat: 48.779543, lng: 17.8701334 },
    { lat: 48.7792889, lng: 17.8698145 },
    { lat: 48.7792486, lng: 17.8697646 },
    { lat: 48.7792185, lng: 17.8697315 },
    { lat: 48.7787522, lng: 17.8692075 },
    { lat: 48.7784163, lng: 17.8688611 },
    { lat: 48.7781298, lng: 17.8685262 },
    { lat: 48.7781237, lng: 17.8685195 },
    { lat: 48.7778644, lng: 17.8682081 },
    { lat: 48.7778484, lng: 17.8678653 },
    { lat: 48.7778324, lng: 17.8675271 },
    { lat: 48.7778225, lng: 17.8673082 },
    { lat: 48.7778205, lng: 17.867274 },
    { lat: 48.7778198, lng: 17.8672621 },
    { lat: 48.7778177, lng: 17.8672081 },
    { lat: 48.7778118, lng: 17.8671111 },
    { lat: 48.7778092, lng: 17.8670674 },
    { lat: 48.7778025, lng: 17.8669561 },
    { lat: 48.7777997, lng: 17.8669109 },
    { lat: 48.7777854, lng: 17.8666741 },
    { lat: 48.7777835, lng: 17.8666415 },
    { lat: 48.7777685, lng: 17.8663762 },
    { lat: 48.7777658, lng: 17.8662946 },
    { lat: 48.7777586, lng: 17.8659296 },
    { lat: 48.7776825, lng: 17.8656422 },
    { lat: 48.7774689, lng: 17.8651144 },
    { lat: 48.7771281, lng: 17.8643122 },
    { lat: 48.7757555, lng: 17.8640908 },
    { lat: 48.7751287, lng: 17.8638791 },
    { lat: 48.7747458, lng: 17.8637436 },
    { lat: 48.7730041, lng: 17.8632604 },
    { lat: 48.7726413, lng: 17.8634766 },
    { lat: 48.7727165, lng: 17.8647612 },
    { lat: 48.7727513, lng: 17.8653746 },
    { lat: 48.7728586, lng: 17.8672064 },
    { lat: 48.7728835, lng: 17.8676192 },
    { lat: 48.7729567, lng: 17.868889 },
    { lat: 48.7724979, lng: 17.8695352 },
    { lat: 48.7714238, lng: 17.86889 },
    { lat: 48.7700346, lng: 17.8678496 },
    { lat: 48.7662158, lng: 17.863943 },
    { lat: 48.7628311, lng: 17.8604798 },
    { lat: 48.7629038, lng: 17.8596659 },
    { lat: 48.7629774, lng: 17.8589966 },
    { lat: 48.763051, lng: 17.8583474 },
    { lat: 48.7631202, lng: 17.8575127 },
    { lat: 48.7631455, lng: 17.8570716 },
    { lat: 48.763266, lng: 17.855799 },
    { lat: 48.7632857, lng: 17.8556759 },
    { lat: 48.7632969, lng: 17.8555253 },
    { lat: 48.7634219, lng: 17.8542701 },
    { lat: 48.7634071, lng: 17.8542675 },
    { lat: 48.7635369, lng: 17.8528493 },
    { lat: 48.7635367, lng: 17.8528464 },
    { lat: 48.7635748, lng: 17.8523941 },
    { lat: 48.7637017, lng: 17.8524398 },
    { lat: 48.7639928, lng: 17.8524187 },
    { lat: 48.7644325, lng: 17.8524841 },
    { lat: 48.7648453, lng: 17.8525529 },
    { lat: 48.765338, lng: 17.8526722 },
    { lat: 48.7657824, lng: 17.8528338 },
    { lat: 48.7661358, lng: 17.8529835 },
    { lat: 48.765521, lng: 17.851592 },
    { lat: 48.7649215, lng: 17.8501372 },
    { lat: 48.7648624, lng: 17.8500358 },
    { lat: 48.7647131, lng: 17.8494069 },
    { lat: 48.7640596, lng: 17.8484841 },
    { lat: 48.76397, lng: 17.8483481 },
    { lat: 48.7633384, lng: 17.8473888 },
    { lat: 48.7631683, lng: 17.8472263 },
    { lat: 48.762061, lng: 17.8461463 },
    { lat: 48.7618309, lng: 17.8459265 },
    { lat: 48.761755, lng: 17.8458481 },
    { lat: 48.7612305, lng: 17.8452587 },
    { lat: 48.7609379, lng: 17.8460558 },
    { lat: 48.7609287, lng: 17.8460719 },
    { lat: 48.7609248, lng: 17.8460819 },
    { lat: 48.7603802, lng: 17.8471549 },
    { lat: 48.7601158, lng: 17.8476375 },
    { lat: 48.7598656, lng: 17.8481277 },
    { lat: 48.7591204, lng: 17.8480683 },
    { lat: 48.7579588, lng: 17.8479948 },
    { lat: 48.7576226, lng: 17.8478869 },
    { lat: 48.7573262, lng: 17.8478025 },
    { lat: 48.7569551, lng: 17.8478616 },
    { lat: 48.7568676, lng: 17.8478545 },
    { lat: 48.7568341, lng: 17.8506178 },
  ],
  NovéMestonadVáhom: [
    { lat: 48.7772905, lng: 17.796376 },
    { lat: 48.7772025, lng: 17.7962854 },
    { lat: 48.7765592, lng: 17.7956398 },
    { lat: 48.7765939, lng: 17.7955052 },
    { lat: 48.7766814, lng: 17.7954589 },
    { lat: 48.7763145, lng: 17.7950326 },
    { lat: 48.7760587, lng: 17.7946712 },
    { lat: 48.7756961, lng: 17.7943149 },
    { lat: 48.7751797, lng: 17.7937162 },
    { lat: 48.7750079, lng: 17.793472 },
    { lat: 48.7745367, lng: 17.7929302 },
    { lat: 48.7742599, lng: 17.7924254 },
    { lat: 48.7740045, lng: 17.7920883 },
    { lat: 48.7739342, lng: 17.7919358 },
    { lat: 48.7738593, lng: 17.7917997 },
    { lat: 48.7736706, lng: 17.7915567 },
    { lat: 48.7734211, lng: 17.7911859 },
    { lat: 48.7732819, lng: 17.7909413 },
    { lat: 48.7730162, lng: 17.7905443 },
    { lat: 48.7727871, lng: 17.7901207 },
    { lat: 48.7726993, lng: 17.7900156 },
    { lat: 48.7724064, lng: 17.7897119 },
    { lat: 48.7723297, lng: 17.7895642 },
    { lat: 48.7722999, lng: 17.7894445 },
    { lat: 48.7722859, lng: 17.7889209 },
    { lat: 48.7722284, lng: 17.7880907 },
    { lat: 48.7722076, lng: 17.7876384 },
    { lat: 48.7721392, lng: 17.7874797 },
    { lat: 48.7708757, lng: 17.7846299 },
    { lat: 48.7708483, lng: 17.7845697 },
    { lat: 48.7694015, lng: 17.7813075 },
    { lat: 48.7691915, lng: 17.7816037 },
    { lat: 48.7689264, lng: 17.7819029 },
    { lat: 48.7685572, lng: 17.78218 },
    { lat: 48.768236, lng: 17.7820949 },
    { lat: 48.7681847, lng: 17.782014 },
    { lat: 48.7680099, lng: 17.7818881 },
    { lat: 48.7678955, lng: 17.7818252 },
    { lat: 48.7675511, lng: 17.7815835 },
    { lat: 48.7673483, lng: 17.7814804 },
    { lat: 48.7668829, lng: 17.781218 },
    { lat: 48.7666341, lng: 17.7812097 },
    { lat: 48.7664602, lng: 17.7812464 },
    { lat: 48.7662881, lng: 17.781203 },
    { lat: 48.7659252, lng: 17.7810449 },
    { lat: 48.7657962, lng: 17.7810796 },
    { lat: 48.7656695, lng: 17.7810358 },
    { lat: 48.7654696, lng: 17.7809238 },
    { lat: 48.7650947, lng: 17.7807764 },
    { lat: 48.7647941, lng: 17.7805683 },
    { lat: 48.7645532, lng: 17.7804129 },
    { lat: 48.7642297, lng: 17.7802562 },
    { lat: 48.7638628, lng: 17.7803118 },
    { lat: 48.7636726, lng: 17.7803013 },
    { lat: 48.7635557, lng: 17.780274 },
    { lat: 48.7634788, lng: 17.7801418 },
    { lat: 48.7633259, lng: 17.7798558 },
    { lat: 48.7632318, lng: 17.7797225 },
    { lat: 48.7630104, lng: 17.7794786 },
    { lat: 48.762891, lng: 17.7794107 },
    { lat: 48.7627387, lng: 17.7793932 },
    { lat: 48.7624592, lng: 17.7792727 },
    { lat: 48.7622156, lng: 17.7791116 },
    { lat: 48.7621255, lng: 17.7790589 },
    { lat: 48.7618164, lng: 17.7789135 },
    { lat: 48.7612546, lng: 17.7787565 },
    { lat: 48.761176, lng: 17.7787077 },
    { lat: 48.7611477, lng: 17.7784111 },
    { lat: 48.761153, lng: 17.7777956 },
    { lat: 48.7611856, lng: 17.7776288 },
    { lat: 48.7612059, lng: 17.77748 },
    { lat: 48.761211, lng: 17.7772858 },
    { lat: 48.7611266, lng: 17.7767543 },
    { lat: 48.7610733, lng: 17.7762533 },
    { lat: 48.7609549, lng: 17.7759412 },
    { lat: 48.7608765, lng: 17.7758053 },
    { lat: 48.76084, lng: 17.7757691 },
    { lat: 48.7608128, lng: 17.7757421 },
    { lat: 48.7606175, lng: 17.775722 },
    { lat: 48.7602673, lng: 17.7755724 },
    { lat: 48.7600148, lng: 17.7755797 },
    { lat: 48.759615, lng: 17.7754473 },
    { lat: 48.7590935, lng: 17.7750422 },
    { lat: 48.7588175, lng: 17.7749604 },
    { lat: 48.7585721, lng: 17.7748805 },
    { lat: 48.7583401, lng: 17.774667 },
    { lat: 48.7578636, lng: 17.774519 },
    { lat: 48.7576007, lng: 17.7743735 },
    { lat: 48.7575296, lng: 17.7743553 },
    { lat: 48.7572881, lng: 17.7743759 },
    { lat: 48.7570422, lng: 17.7743044 },
    { lat: 48.7570003, lng: 17.7743026 },
    { lat: 48.7564938, lng: 17.7743642 },
    { lat: 48.7560141, lng: 17.7742922 },
    { lat: 48.7559369, lng: 17.774267 },
    { lat: 48.7557864, lng: 17.7742804 },
    { lat: 48.7550067, lng: 17.7745262 },
    { lat: 48.7547633, lng: 17.774609 },
    { lat: 48.7544895, lng: 17.7746366 },
    { lat: 48.7540463, lng: 17.7746252 },
    { lat: 48.753809, lng: 17.77464 },
    { lat: 48.7536707, lng: 17.7746662 },
    { lat: 48.7533647, lng: 17.7748137 },
    { lat: 48.7531809, lng: 17.7749106 },
    { lat: 48.7529851, lng: 17.7749761 },
    { lat: 48.7527422, lng: 17.7751573 },
    { lat: 48.7523175, lng: 17.7756889 },
    { lat: 48.7520975, lng: 17.7758946 },
    { lat: 48.7520416, lng: 17.7759956 },
    { lat: 48.751611, lng: 17.7765065 },
    { lat: 48.7515079, lng: 17.7765923 },
    { lat: 48.7514133, lng: 17.7766869 },
    { lat: 48.7513201, lng: 17.7768237 },
    { lat: 48.7512223, lng: 17.7769433 },
    { lat: 48.751115, lng: 17.7769959 },
    { lat: 48.750974, lng: 17.7770344 },
    { lat: 48.7506084, lng: 17.7770575 },
    { lat: 48.7503902, lng: 17.7771084 },
    { lat: 48.7502413, lng: 17.7772577 },
    { lat: 48.7501957, lng: 17.7773977 },
    { lat: 48.7501549, lng: 17.7775989 },
    { lat: 48.750131, lng: 17.7776312 },
    { lat: 48.7498707, lng: 17.7789511 },
    { lat: 48.7497403, lng: 17.7793099 },
    { lat: 48.7495562, lng: 17.7796408 },
    { lat: 48.7492691, lng: 17.7801705 },
    { lat: 48.7490593, lng: 17.7805056 },
    { lat: 48.7488441, lng: 17.7809009 },
    { lat: 48.7487432, lng: 17.7810973 },
    { lat: 48.748509, lng: 17.7813638 },
    { lat: 48.7483312, lng: 17.7816337 },
    { lat: 48.7482372, lng: 17.781702 },
    { lat: 48.7479143, lng: 17.7818679 },
    { lat: 48.7473131, lng: 17.7819503 },
    { lat: 48.7471322, lng: 17.7821688 },
    { lat: 48.7470693, lng: 17.7823219 },
    { lat: 48.7469405, lng: 17.7827999 },
    { lat: 48.7468489, lng: 17.7830569 },
    { lat: 48.7467293, lng: 17.7832781 },
    { lat: 48.7462151, lng: 17.7838774 },
    { lat: 48.7460945, lng: 17.7842323 },
    { lat: 48.7460892, lng: 17.784443 },
    { lat: 48.7459942, lng: 17.7848276 },
    { lat: 48.7459557, lng: 17.7849171 },
    { lat: 48.7458516, lng: 17.7852469 },
    { lat: 48.7457755, lng: 17.7856271 },
    { lat: 48.745664, lng: 17.7863508 },
    { lat: 48.7455732, lng: 17.7867677 },
    { lat: 48.7455307, lng: 17.7868858 },
    { lat: 48.7454309, lng: 17.7870986 },
    { lat: 48.7450594, lng: 17.7875431 },
    { lat: 48.7449254, lng: 17.7876596 },
    { lat: 48.7448167, lng: 17.7877839 },
    { lat: 48.744648, lng: 17.7879232 },
    { lat: 48.7442571, lng: 17.7880295 },
    { lat: 48.7441487, lng: 17.7881026 },
    { lat: 48.7438839, lng: 17.7882036 },
    { lat: 48.7437073, lng: 17.7883435 },
    { lat: 48.7436015, lng: 17.7883852 },
    { lat: 48.7434794, lng: 17.7884014 },
    { lat: 48.7433341, lng: 17.7884811 },
    { lat: 48.7432024, lng: 17.7886163 },
    { lat: 48.743198, lng: 17.7886732 },
    { lat: 48.7430573, lng: 17.7888095 },
    { lat: 48.7424112, lng: 17.789119 },
    { lat: 48.7424465, lng: 17.7900102 },
    { lat: 48.742445, lng: 17.7902444 },
    { lat: 48.7421899, lng: 17.7904673 },
    { lat: 48.7417892, lng: 17.7909115 },
    { lat: 48.7418266, lng: 17.7910438 },
    { lat: 48.7414379, lng: 17.7914817 },
    { lat: 48.7417991, lng: 17.7917761 },
    { lat: 48.7418996, lng: 17.7918316 },
    { lat: 48.7419119, lng: 17.7920522 },
    { lat: 48.7418572, lng: 17.7923025 },
    { lat: 48.7417571, lng: 17.7925688 },
    { lat: 48.7416908, lng: 17.7926831 },
    { lat: 48.7416108, lng: 17.7928904 },
    { lat: 48.7415857, lng: 17.7930527 },
    { lat: 48.7414446, lng: 17.793311 },
    { lat: 48.7411394, lng: 17.7930276 },
    { lat: 48.7407601, lng: 17.7927878 },
    { lat: 48.7405823, lng: 17.7932205 },
    { lat: 48.7402324, lng: 17.7929464 },
    { lat: 48.7398403, lng: 17.7937341 },
    { lat: 48.7397424, lng: 17.7945923 },
    { lat: 48.7395558, lng: 17.7957516 },
    { lat: 48.739072, lng: 17.7969234 },
    { lat: 48.7383533, lng: 17.7978563 },
    { lat: 48.7378443, lng: 17.799098 },
    { lat: 48.7373291, lng: 17.8007196 },
    { lat: 48.7362555, lng: 17.8015162 },
    { lat: 48.7356501, lng: 17.8019926 },
    { lat: 48.734535, lng: 17.8027941 },
    { lat: 48.734236, lng: 17.8029638 },
    { lat: 48.7336948, lng: 17.8033827 },
    { lat: 48.733076, lng: 17.8036029 },
    { lat: 48.7327813, lng: 17.8036934 },
    { lat: 48.7326345, lng: 17.8038035 },
    { lat: 48.7324714, lng: 17.803777 },
    { lat: 48.7324046, lng: 17.8037919 },
    { lat: 48.7322487, lng: 17.8039379 },
    { lat: 48.7321891, lng: 17.8040358 },
    { lat: 48.7320872, lng: 17.8041771 },
    { lat: 48.7319402, lng: 17.8044706 },
    { lat: 48.7318198, lng: 17.8045881 },
    { lat: 48.7316341, lng: 17.8047457 },
    { lat: 48.7313397, lng: 17.8048793 },
    { lat: 48.7312435, lng: 17.80495 },
    { lat: 48.7310305, lng: 17.8049929 },
    { lat: 48.7309532, lng: 17.8050398 },
    { lat: 48.7308294, lng: 17.8050656 },
    { lat: 48.730637, lng: 17.8051291 },
    { lat: 48.7303755, lng: 17.8051741 },
    { lat: 48.7302264, lng: 17.8052644 },
    { lat: 48.730139, lng: 17.8052766 },
    { lat: 48.7300146, lng: 17.8052742 },
    { lat: 48.7299111, lng: 17.8053335 },
    { lat: 48.7298449, lng: 17.8053418 },
    { lat: 48.7296417, lng: 17.8054154 },
    { lat: 48.7293887, lng: 17.8055254 },
    { lat: 48.7294791, lng: 17.8057652 },
    { lat: 48.7293526, lng: 17.8058942 },
    { lat: 48.7293475, lng: 17.8059606 },
    { lat: 48.729542, lng: 17.8060393 },
    { lat: 48.729245, lng: 17.8060927 },
    { lat: 48.7291497, lng: 17.8061413 },
    { lat: 48.7290521, lng: 17.8062427 },
    { lat: 48.7289106, lng: 17.8065523 },
    { lat: 48.7286414, lng: 17.8070069 },
    { lat: 48.7276922, lng: 17.8087155 },
    { lat: 48.7273988, lng: 17.808403 },
    { lat: 48.7272943, lng: 17.8082052 },
    { lat: 48.7271594, lng: 17.8081236 },
    { lat: 48.7271132, lng: 17.8081437 },
    { lat: 48.727084, lng: 17.8082012 },
    { lat: 48.727013, lng: 17.8081834 },
    { lat: 48.7266171, lng: 17.8078938 },
    { lat: 48.7264226, lng: 17.8076658 },
    { lat: 48.7261688, lng: 17.8070403 },
    { lat: 48.7260305, lng: 17.8070304 },
    { lat: 48.725835, lng: 17.8066581 },
    { lat: 48.7255897, lng: 17.8071878 },
    { lat: 48.725564, lng: 17.8073233 },
    { lat: 48.7255022, lng: 17.8074022 },
    { lat: 48.7253057, lng: 17.8079573 },
    { lat: 48.7251287, lng: 17.808534 },
    { lat: 48.7247136, lng: 17.8100584 },
    { lat: 48.7245826, lng: 17.8099098 },
    { lat: 48.724459, lng: 17.8097529 },
    { lat: 48.7242182, lng: 17.8094149 },
    { lat: 48.7244697, lng: 17.8083172 },
    { lat: 48.724345, lng: 17.8081034 },
    { lat: 48.7242465, lng: 17.80799 },
    { lat: 48.72409, lng: 17.8078844 },
    { lat: 48.7236849, lng: 17.8077023 },
    { lat: 48.7235079, lng: 17.8076152 },
    { lat: 48.7232785, lng: 17.8075173 },
    { lat: 48.7228099, lng: 17.8072012 },
    { lat: 48.7219801, lng: 17.806849 },
    { lat: 48.721652, lng: 17.8066885 },
    { lat: 48.7212825, lng: 17.8067024 },
    { lat: 48.7212322, lng: 17.8068029 },
    { lat: 48.7206519, lng: 17.8072654 },
    { lat: 48.7205634, lng: 17.8074066 },
    { lat: 48.7205302, lng: 17.8073996 },
    { lat: 48.7204271, lng: 17.8076337 },
    { lat: 48.7193969, lng: 17.8102894 },
    { lat: 48.7194212, lng: 17.8103373 },
    { lat: 48.7197383, lng: 17.8103917 },
    { lat: 48.7197552, lng: 17.8122997 },
    { lat: 48.7206264, lng: 17.8125334 },
    { lat: 48.7206595, lng: 17.818732 },
    { lat: 48.721292, lng: 17.8253442 },
    { lat: 48.7213366, lng: 17.8258008 },
    { lat: 48.7216592, lng: 17.8291777 },
    { lat: 48.72143, lng: 17.8292693 },
    { lat: 48.7215813, lng: 17.8304022 },
    { lat: 48.7216331, lng: 17.8308653 },
    { lat: 48.7217527, lng: 17.8317565 },
    { lat: 48.7217662, lng: 17.8319235 },
    { lat: 48.7218338, lng: 17.8324776 },
    { lat: 48.7219655, lng: 17.8334758 },
    { lat: 48.7222784, lng: 17.836268 },
    { lat: 48.7224405, lng: 17.837674 },
    { lat: 48.7224746, lng: 17.837923 },
    { lat: 48.7224891, lng: 17.8380285 },
    { lat: 48.722535, lng: 17.8384308 },
    { lat: 48.722625, lng: 17.839152 },
    { lat: 48.7227013, lng: 17.839832 },
    { lat: 48.7227718, lng: 17.8404374 },
    { lat: 48.72282, lng: 17.8408953 },
    { lat: 48.7228702, lng: 17.8412768 },
    { lat: 48.7230017, lng: 17.8421971 },
    { lat: 48.7230648, lng: 17.8424007 },
    { lat: 48.723496, lng: 17.8432193 },
    { lat: 48.725704, lng: 17.8459165 },
    { lat: 48.7257095, lng: 17.8467731 },
    { lat: 48.7257461, lng: 17.8478037 },
    { lat: 48.7257678, lng: 17.8483125 },
    { lat: 48.7257224, lng: 17.8491839 },
    { lat: 48.7256783, lng: 17.8499458 },
    { lat: 48.7257078, lng: 17.8500054 },
    { lat: 48.7257621, lng: 17.8504343 },
    { lat: 48.7257975, lng: 17.8506278 },
    { lat: 48.7256172, lng: 17.8510058 },
    { lat: 48.7256137, lng: 17.851302 },
    { lat: 48.7255979, lng: 17.8514329 },
    { lat: 48.7255235, lng: 17.8517155 },
    { lat: 48.7254019, lng: 17.8519426 },
    { lat: 48.7285893, lng: 17.8518611 },
    { lat: 48.729466, lng: 17.8520576 },
    { lat: 48.7350702, lng: 17.8535571 },
    { lat: 48.7377139, lng: 17.8533508 },
    { lat: 48.7385578, lng: 17.8524861 },
    { lat: 48.7424017, lng: 17.8485516 },
    { lat: 48.7430936, lng: 17.8478426 },
    { lat: 48.7438384, lng: 17.8476896 },
    { lat: 48.7441294, lng: 17.8474227 },
    { lat: 48.7446395, lng: 17.8471787 },
    { lat: 48.7452252, lng: 17.8473072 },
    { lat: 48.7457348, lng: 17.8497006 },
    { lat: 48.7460847, lng: 17.8513399 },
    { lat: 48.7470253, lng: 17.8513265 },
    { lat: 48.7473041, lng: 17.8503931 },
    { lat: 48.7474223, lng: 17.8500135 },
    { lat: 48.7475408, lng: 17.8500876 },
    { lat: 48.748026, lng: 17.8502343 },
    { lat: 48.7485876, lng: 17.8500745 },
    { lat: 48.7490955, lng: 17.8500358 },
    { lat: 48.7496957, lng: 17.8497696 },
    { lat: 48.7500121, lng: 17.8496827 },
    { lat: 48.7502249, lng: 17.8496725 },
    { lat: 48.7502829, lng: 17.8496994 },
    { lat: 48.7504359, lng: 17.849747 },
    { lat: 48.7507255, lng: 17.8497009 },
    { lat: 48.7508708, lng: 17.8494883 },
    { lat: 48.7514094, lng: 17.8495101 },
    { lat: 48.7515833, lng: 17.8493252 },
    { lat: 48.7516406, lng: 17.8490408 },
    { lat: 48.7516395, lng: 17.8485945 },
    { lat: 48.751646, lng: 17.8482922 },
    { lat: 48.7516211, lng: 17.8480665 },
    { lat: 48.7516303, lng: 17.8478275 },
    { lat: 48.7516892, lng: 17.8475509 },
    { lat: 48.7517886, lng: 17.8471239 },
    { lat: 48.7518212, lng: 17.8468967 },
    { lat: 48.7518958, lng: 17.8465641 },
    { lat: 48.752025, lng: 17.8461791 },
    { lat: 48.7521947, lng: 17.8458877 },
    { lat: 48.7522482, lng: 17.8457736 },
    { lat: 48.7523077, lng: 17.8456466 },
    { lat: 48.7524412, lng: 17.8454843 },
    { lat: 48.752776, lng: 17.8451963 },
    { lat: 48.7529586, lng: 17.8450989 },
    { lat: 48.7533766, lng: 17.8449565 },
    { lat: 48.7538768, lng: 17.8448278 },
    { lat: 48.754501, lng: 17.8448639 },
    { lat: 48.7547248, lng: 17.844867 },
    { lat: 48.7549312, lng: 17.8450513 },
    { lat: 48.7550127, lng: 17.8451659 },
    { lat: 48.7552574, lng: 17.8453105 },
    { lat: 48.755306, lng: 17.8455172 },
    { lat: 48.7553693, lng: 17.8457414 },
    { lat: 48.7554989, lng: 17.8460359 },
    { lat: 48.7555982, lng: 17.8464656 },
    { lat: 48.7556178, lng: 17.8468995 },
    { lat: 48.755603, lng: 17.8471765 },
    { lat: 48.7556115, lng: 17.847317 },
    { lat: 48.7557103, lng: 17.84755 },
    { lat: 48.7558548, lng: 17.8476999 },
    { lat: 48.7560782, lng: 17.8478106 },
    { lat: 48.7561428, lng: 17.8477956 },
    { lat: 48.7568676, lng: 17.8478545 },
    { lat: 48.7569551, lng: 17.8478616 },
    { lat: 48.7573262, lng: 17.8478025 },
    { lat: 48.7576226, lng: 17.8478869 },
    { lat: 48.7579588, lng: 17.8479948 },
    { lat: 48.7591204, lng: 17.8480683 },
    { lat: 48.7598656, lng: 17.8481277 },
    { lat: 48.7601158, lng: 17.8476375 },
    { lat: 48.7603802, lng: 17.8471549 },
    { lat: 48.7609248, lng: 17.8460819 },
    { lat: 48.7609287, lng: 17.8460719 },
    { lat: 48.7609379, lng: 17.8460558 },
    { lat: 48.7612305, lng: 17.8452587 },
    { lat: 48.761755, lng: 17.8458481 },
    { lat: 48.7618309, lng: 17.8459265 },
    { lat: 48.762061, lng: 17.8461463 },
    { lat: 48.7631683, lng: 17.8472263 },
    { lat: 48.7633384, lng: 17.8473888 },
    { lat: 48.76397, lng: 17.8483481 },
    { lat: 48.7640596, lng: 17.8484841 },
    { lat: 48.7647131, lng: 17.8494069 },
    { lat: 48.7648624, lng: 17.8500358 },
    { lat: 48.7649215, lng: 17.8501372 },
    { lat: 48.765521, lng: 17.851592 },
    { lat: 48.7661358, lng: 17.8529835 },
    { lat: 48.7657824, lng: 17.8528338 },
    { lat: 48.765338, lng: 17.8526722 },
    { lat: 48.7648453, lng: 17.8525529 },
    { lat: 48.7644325, lng: 17.8524841 },
    { lat: 48.7639928, lng: 17.8524187 },
    { lat: 48.7637017, lng: 17.8524398 },
    { lat: 48.7635748, lng: 17.8523941 },
    { lat: 48.7635367, lng: 17.8528464 },
    { lat: 48.7635369, lng: 17.8528493 },
    { lat: 48.7634071, lng: 17.8542675 },
    { lat: 48.7634219, lng: 17.8542701 },
    { lat: 48.7632969, lng: 17.8555253 },
    { lat: 48.7632857, lng: 17.8556759 },
    { lat: 48.763266, lng: 17.855799 },
    { lat: 48.7631455, lng: 17.8570716 },
    { lat: 48.7631202, lng: 17.8575127 },
    { lat: 48.763051, lng: 17.8583474 },
    { lat: 48.7629774, lng: 17.8589966 },
    { lat: 48.7629038, lng: 17.8596659 },
    { lat: 48.7628311, lng: 17.8604798 },
    { lat: 48.7662158, lng: 17.863943 },
    { lat: 48.7700346, lng: 17.8678496 },
    { lat: 48.7714238, lng: 17.86889 },
    { lat: 48.7724979, lng: 17.8695352 },
    { lat: 48.7729567, lng: 17.868889 },
    { lat: 48.7728835, lng: 17.8676192 },
    { lat: 48.7728586, lng: 17.8672064 },
    { lat: 48.7727513, lng: 17.8653746 },
    { lat: 48.7727165, lng: 17.8647612 },
    { lat: 48.7726413, lng: 17.8634766 },
    { lat: 48.7730041, lng: 17.8632604 },
    { lat: 48.7747458, lng: 17.8637436 },
    { lat: 48.7751287, lng: 17.8638791 },
    { lat: 48.7757555, lng: 17.8640908 },
    { lat: 48.7771281, lng: 17.8643122 },
    { lat: 48.7791715, lng: 17.8630071 },
    { lat: 48.7805738, lng: 17.8614783 },
    { lat: 48.7808715, lng: 17.8611549 },
    { lat: 48.7809821, lng: 17.8610811 },
    { lat: 48.781253, lng: 17.8610051 },
    { lat: 48.7813931, lng: 17.8607646 },
    { lat: 48.7816876, lng: 17.8602592 },
    { lat: 48.7818236, lng: 17.8598005 },
    { lat: 48.7818039, lng: 17.8595854 },
    { lat: 48.7820988, lng: 17.8588622 },
    { lat: 48.7823353, lng: 17.8584803 },
    { lat: 48.7825766, lng: 17.8582146 },
    { lat: 48.7828974, lng: 17.8581798 },
    { lat: 48.7831777, lng: 17.8580732 },
    { lat: 48.7833699, lng: 17.8579086 },
    { lat: 48.7836193, lng: 17.8576837 },
    { lat: 48.7839022, lng: 17.8575092 },
    { lat: 48.7843156, lng: 17.8571202 },
    { lat: 48.7845069, lng: 17.8570536 },
    { lat: 48.7844976, lng: 17.8569369 },
    { lat: 48.7843962, lng: 17.8562766 },
    { lat: 48.7842944, lng: 17.8556137 },
    { lat: 48.7844009, lng: 17.8555996 },
    { lat: 48.7844016, lng: 17.8552974 },
    { lat: 48.7844022, lng: 17.8550429 },
    { lat: 48.7847192, lng: 17.8551886 },
    { lat: 48.7856681, lng: 17.8552183 },
    { lat: 48.7862498, lng: 17.8552222 },
    { lat: 48.786618, lng: 17.8550594 },
    { lat: 48.7868642, lng: 17.8552478 },
    { lat: 48.7873563, lng: 17.8553756 },
    { lat: 48.7876451, lng: 17.8542901 },
    { lat: 48.7881745, lng: 17.8529257 },
    { lat: 48.7881922, lng: 17.8528848 },
    { lat: 48.7879277, lng: 17.8524863 },
    { lat: 48.7877367, lng: 17.8519221 },
    { lat: 48.7877785, lng: 17.8512701 },
    { lat: 48.7877212, lng: 17.8509952 },
    { lat: 48.7875468, lng: 17.8505556 },
    { lat: 48.7878244, lng: 17.8500704 },
    { lat: 48.7880981, lng: 17.8495739 },
    { lat: 48.7889976, lng: 17.8485446 },
    { lat: 48.7896522, lng: 17.8480742 },
    { lat: 48.7903918, lng: 17.8472947 },
    { lat: 48.7911535, lng: 17.8465645 },
    { lat: 48.791526, lng: 17.8462268 },
    { lat: 48.7913596, lng: 17.8454558 },
    { lat: 48.7910989, lng: 17.8448966 },
    { lat: 48.791648, lng: 17.844737 },
    { lat: 48.7911609, lng: 17.8436847 },
    { lat: 48.7909824, lng: 17.843197 },
    { lat: 48.7907858, lng: 17.8433232 },
    { lat: 48.7906864, lng: 17.8430069 },
    { lat: 48.7904631, lng: 17.8418919 },
    { lat: 48.7904243, lng: 17.841773 },
    { lat: 48.7903947, lng: 17.8417315 },
    { lat: 48.7898879, lng: 17.841454 },
    { lat: 48.789592, lng: 17.8412645 },
    { lat: 48.7889129, lng: 17.8411797 },
    { lat: 48.7887638, lng: 17.841253 },
    { lat: 48.7887464, lng: 17.8406472 },
    { lat: 48.7887785, lng: 17.8405441 },
    { lat: 48.7887071, lng: 17.8403178 },
    { lat: 48.7885627, lng: 17.8399812 },
    { lat: 48.7885471, lng: 17.8396331 },
    { lat: 48.7881654, lng: 17.8401144 },
    { lat: 48.787894, lng: 17.8395478 },
    { lat: 48.7874845, lng: 17.8387325 },
    { lat: 48.7877915, lng: 17.8383217 },
    { lat: 48.7875384, lng: 17.8377042 },
    { lat: 48.7870954, lng: 17.8368018 },
    { lat: 48.7868349, lng: 17.8362652 },
    { lat: 48.7869405, lng: 17.8362389 },
    { lat: 48.7870277, lng: 17.8360945 },
    { lat: 48.7870456, lng: 17.8359229 },
    { lat: 48.7870244, lng: 17.8356871 },
    { lat: 48.7868759, lng: 17.8351918 },
    { lat: 48.7865636, lng: 17.834603 },
    { lat: 48.7865259, lng: 17.8344648 },
    { lat: 48.7865096, lng: 17.8339545 },
    { lat: 48.7864677, lng: 17.8336152 },
    { lat: 48.786355, lng: 17.8331162 },
    { lat: 48.7862527, lng: 17.8327896 },
    { lat: 48.7860502, lng: 17.8323911 },
    { lat: 48.7859547, lng: 17.8322506 },
    { lat: 48.7860884, lng: 17.8320544 },
    { lat: 48.7856813, lng: 17.8315028 },
    { lat: 48.7861005, lng: 17.8308752 },
    { lat: 48.7855411, lng: 17.8301222 },
    { lat: 48.785034, lng: 17.8293006 },
    { lat: 48.7846969, lng: 17.8286927 },
    { lat: 48.7844688, lng: 17.8283016 },
    { lat: 48.7839692, lng: 17.82684 },
    { lat: 48.7839274, lng: 17.8266185 },
    { lat: 48.7840768, lng: 17.8258707 },
    { lat: 48.7843344, lng: 17.8245816 },
    { lat: 48.7841496, lng: 17.8240111 },
    { lat: 48.7846557, lng: 17.8231439 },
    { lat: 48.7839968, lng: 17.8214222 },
    { lat: 48.7832596, lng: 17.8194897 },
    { lat: 48.7833031, lng: 17.8194423 },
    { lat: 48.7829583, lng: 17.8186562 },
    { lat: 48.7830661, lng: 17.818554 },
    { lat: 48.7827168, lng: 17.8177283 },
    { lat: 48.7825999, lng: 17.8178663 },
    { lat: 48.7821549, lng: 17.816859 },
    { lat: 48.7824507, lng: 17.8165261 },
    { lat: 48.781877, lng: 17.8154635 },
    { lat: 48.7819764, lng: 17.8153362 },
    { lat: 48.7814586, lng: 17.8137828 },
    { lat: 48.7816362, lng: 17.813583 },
    { lat: 48.781401, lng: 17.8127988 },
    { lat: 48.7819398, lng: 17.8122276 },
    { lat: 48.781384, lng: 17.8109034 },
    { lat: 48.7810185, lng: 17.8100369 },
    { lat: 48.7815648, lng: 17.8095163 },
    { lat: 48.781395, lng: 17.8085257 },
    { lat: 48.7813198, lng: 17.8085704 },
    { lat: 48.7812772, lng: 17.8084267 },
    { lat: 48.7802721, lng: 17.8092923 },
    { lat: 48.7795953, lng: 17.8097835 },
    { lat: 48.7794779, lng: 17.8089864 },
    { lat: 48.7793492, lng: 17.8079482 },
    { lat: 48.7791716, lng: 17.807375 },
    { lat: 48.7788331, lng: 17.8076114 },
    { lat: 48.778494, lng: 17.8067153 },
    { lat: 48.7781571, lng: 17.8060952 },
    { lat: 48.7776479, lng: 17.8040555 },
    { lat: 48.7775995, lng: 17.8035203 },
    { lat: 48.7776174, lng: 17.8032219 },
    { lat: 48.7778073, lng: 17.8022802 },
    { lat: 48.7781791, lng: 17.8012671 },
    { lat: 48.7786126, lng: 17.7996911 },
    { lat: 48.7785887, lng: 17.7996701 },
    { lat: 48.7774763, lng: 17.799072 },
    { lat: 48.7772553, lng: 17.798891 },
    { lat: 48.7772936, lng: 17.7984826 },
    { lat: 48.7774989, lng: 17.7979607 },
    { lat: 48.7770638, lng: 17.7973984 },
    { lat: 48.7770528, lng: 17.797014 },
    { lat: 48.7772905, lng: 17.796376 },
  ],
  Lubina: [
    { lat: 48.860115, lng: 17.7029848 },
    { lat: 48.860192, lng: 17.70269 },
    { lat: 48.8602661, lng: 17.7024859 },
    { lat: 48.8603402, lng: 17.7022815 },
    { lat: 48.860364, lng: 17.702216 },
    { lat: 48.86053, lng: 17.702333 },
    { lat: 48.860572, lng: 17.702208 },
    { lat: 48.8605724, lng: 17.7022069 },
    { lat: 48.8606297, lng: 17.7020238 },
    { lat: 48.86063, lng: 17.702023 },
    { lat: 48.860667, lng: 17.701881 },
    { lat: 48.860707, lng: 17.701679 },
    { lat: 48.860733, lng: 17.701514 },
    { lat: 48.860767, lng: 17.701218 },
    { lat: 48.860783, lng: 17.701048 },
    { lat: 48.860806, lng: 17.700887 },
    { lat: 48.86084, lng: 17.700745 },
    { lat: 48.860893, lng: 17.700554 },
    { lat: 48.860971, lng: 17.700225 },
    { lat: 48.861021, lng: 17.700066 },
    { lat: 48.861082, lng: 17.699906 },
    { lat: 48.861125, lng: 17.699782 },
    { lat: 48.861143, lng: 17.699695 },
    { lat: 48.861157, lng: 17.699526 },
    { lat: 48.861163, lng: 17.6993684 },
    { lat: 48.861163, lng: 17.699368 },
    { lat: 48.86118, lng: 17.699219 },
    { lat: 48.861223, lng: 17.699023 },
    { lat: 48.8612578, lng: 17.6988649 },
    { lat: 48.861258, lng: 17.698864 },
    { lat: 48.8612868, lng: 17.6987181 },
    { lat: 48.861287, lng: 17.698717 },
    { lat: 48.861312, lng: 17.69856 },
    { lat: 48.8613479, lng: 17.6983575 },
    { lat: 48.861348, lng: 17.698357 },
    { lat: 48.861406, lng: 17.698064 },
    { lat: 48.86144, lng: 17.69791 },
    { lat: 48.861466, lng: 17.697767 },
    { lat: 48.861482, lng: 17.697626 },
    { lat: 48.861499, lng: 17.697373 },
    { lat: 48.861516, lng: 17.6971806 },
    { lat: 48.861541, lng: 17.697026 },
    { lat: 48.861615, lng: 17.696647 },
    { lat: 48.861656, lng: 17.696447 },
    { lat: 48.861682, lng: 17.696307 },
    { lat: 48.861712, lng: 17.696128 },
    { lat: 48.861717, lng: 17.695998 },
    { lat: 48.861724, lng: 17.695827 },
    { lat: 48.861743, lng: 17.69565 },
    { lat: 48.8617664, lng: 17.6954893 },
    { lat: 48.861772, lng: 17.695451 },
    { lat: 48.861808, lng: 17.695211 },
    { lat: 48.861846, lng: 17.694937 },
    { lat: 48.861898, lng: 17.694627 },
    { lat: 48.861925, lng: 17.694418 },
    { lat: 48.861955, lng: 17.694095 },
    { lat: 48.861972, lng: 17.693878 },
    { lat: 48.862001, lng: 17.693557 },
    { lat: 48.862018, lng: 17.693353 },
    { lat: 48.862031, lng: 17.693206 },
    { lat: 48.862046, lng: 17.693111 },
    { lat: 48.862083, lng: 17.692912 },
    { lat: 48.862117, lng: 17.692761 },
    { lat: 48.862148, lng: 17.692634 },
    { lat: 48.862222, lng: 17.692305 },
    { lat: 48.8622222, lng: 17.692304 },
    { lat: 48.862261, lng: 17.692089 },
    { lat: 48.862278, lng: 17.691968 },
    { lat: 48.862289, lng: 17.691801 },
    { lat: 48.862294, lng: 17.691584 },
    { lat: 48.862295, lng: 17.691484 },
    { lat: 48.862291, lng: 17.691285 },
    { lat: 48.862284, lng: 17.69105 },
    { lat: 48.862264, lng: 17.690765 },
    { lat: 48.862246, lng: 17.690617 },
    { lat: 48.862215, lng: 17.690448 },
    { lat: 48.862171, lng: 17.690238 },
    { lat: 48.862114, lng: 17.690037 },
    { lat: 48.862028, lng: 17.689775 },
    { lat: 48.861871, lng: 17.689298 },
    { lat: 48.861788, lng: 17.689051 },
    { lat: 48.861706, lng: 17.688817 },
    { lat: 48.861594, lng: 17.6885113 },
    { lat: 48.861548, lng: 17.688379 },
    { lat: 48.861506, lng: 17.688234 },
    { lat: 48.861439, lng: 17.688008 },
    { lat: 48.861407, lng: 17.687875 },
    { lat: 48.861363, lng: 17.687681 },
    { lat: 48.861335, lng: 17.687533 },
    { lat: 48.861293, lng: 17.687248 },
    { lat: 48.861221, lng: 17.686809 },
    { lat: 48.861173, lng: 17.686539 },
    { lat: 48.861129, lng: 17.686259 },
    { lat: 48.8611, lng: 17.686019 },
    { lat: 48.861081, lng: 17.685824 },
    { lat: 48.861074, lng: 17.685707 },
    { lat: 48.861072, lng: 17.685628 },
    { lat: 48.861066, lng: 17.685423 },
    { lat: 48.86106, lng: 17.685183 },
    { lat: 48.861055, lng: 17.68447 },
    { lat: 48.861047, lng: 17.684243 },
    { lat: 48.861039, lng: 17.6841 },
    { lat: 48.861022, lng: 17.68393 },
    { lat: 48.860987, lng: 17.683591 },
    { lat: 48.860933, lng: 17.68315 },
    { lat: 48.860905, lng: 17.682897 },
    { lat: 48.860885, lng: 17.682746 },
    { lat: 48.860881, lng: 17.682704 },
    { lat: 48.860866, lng: 17.682671 },
    { lat: 48.8608653, lng: 17.6826692 },
    { lat: 48.860832, lng: 17.682584 },
    { lat: 48.860774, lng: 17.682485 },
    { lat: 48.860623, lng: 17.682262 },
    { lat: 48.86019, lng: 17.681643 },
    { lat: 48.860135, lng: 17.681563 },
    { lat: 48.860087, lng: 17.681516 },
    { lat: 48.859968, lng: 17.681371 },
    { lat: 48.859344, lng: 17.680481 },
    { lat: 48.858801, lng: 17.679576 },
    { lat: 48.858187, lng: 17.678552 },
    { lat: 48.858027, lng: 17.677605 },
    { lat: 48.857942, lng: 17.6771 },
    { lat: 48.857759, lng: 17.676124 },
    { lat: 48.857936, lng: 17.675936 },
    { lat: 48.857977, lng: 17.675686 },
    { lat: 48.858074, lng: 17.675095 },
    { lat: 48.857983, lng: 17.674951 },
    { lat: 48.857771, lng: 17.674795 },
    { lat: 48.857568, lng: 17.674645 },
    { lat: 48.857195, lng: 17.674499 },
    { lat: 48.856846, lng: 17.67385 },
    { lat: 48.856331, lng: 17.672938 },
    { lat: 48.855785, lng: 17.671671 },
    { lat: 48.855211, lng: 17.670248 },
    { lat: 48.854838, lng: 17.669317 },
    { lat: 48.854756, lng: 17.669136 },
    { lat: 48.854227, lng: 17.668497 },
    { lat: 48.8541096, lng: 17.6683781 },
    { lat: 48.8540346, lng: 17.6682817 },
    { lat: 48.8539615, lng: 17.6681937 },
    { lat: 48.8538981, lng: 17.668119 },
    { lat: 48.8538259, lng: 17.6680284 },
    { lat: 48.8537248, lng: 17.6679092 },
    { lat: 48.8536247, lng: 17.6678432 },
    { lat: 48.8535266, lng: 17.6677715 },
    { lat: 48.8534371, lng: 17.6677141 },
    { lat: 48.8533405, lng: 17.6676474 },
    { lat: 48.8532285, lng: 17.667571 },
    { lat: 48.8531295, lng: 17.6675048 },
    { lat: 48.8530481, lng: 17.667447 },
    { lat: 48.8529467, lng: 17.6673785 },
    { lat: 48.8528488, lng: 17.6673112 },
    { lat: 48.852752, lng: 17.6672445 },
    { lat: 48.8526401, lng: 17.667169 },
    { lat: 48.8525376, lng: 17.6670997 },
    { lat: 48.8524502, lng: 17.6670378 },
    { lat: 48.8523538, lng: 17.6669762 },
    { lat: 48.8522063, lng: 17.6668748 },
    { lat: 48.8520912, lng: 17.6668741 },
    { lat: 48.8519768, lng: 17.6668698 },
    { lat: 48.8518852, lng: 17.6668667 },
    { lat: 48.8516919, lng: 17.6668634 },
    { lat: 48.8516149, lng: 17.6668608 },
    { lat: 48.8516172, lng: 17.6668416 },
    { lat: 48.8515196, lng: 17.666834 },
    { lat: 48.8514295, lng: 17.6668734 },
    { lat: 48.8513498, lng: 17.666901 },
    { lat: 48.8512491, lng: 17.6669129 },
    { lat: 48.851174, lng: 17.6669236 },
    { lat: 48.8511049, lng: 17.6669915 },
    { lat: 48.8510394, lng: 17.6670283 },
    { lat: 48.8509683, lng: 17.6670674 },
    { lat: 48.8508804, lng: 17.6670877 },
    { lat: 48.8507958, lng: 17.6671065 },
    { lat: 48.8506622, lng: 17.6670999 },
    { lat: 48.8505539, lng: 17.6669931 },
    { lat: 48.8504403, lng: 17.6669451 },
    { lat: 48.850351, lng: 17.6669064 },
    { lat: 48.8502384, lng: 17.6669301 },
    { lat: 48.8501672, lng: 17.6669479 },
    { lat: 48.8501017, lng: 17.6669659 },
    { lat: 48.8500262, lng: 17.666968 },
    { lat: 48.8499258, lng: 17.6670055 },
    { lat: 48.8498243, lng: 17.667043 },
    { lat: 48.8497092, lng: 17.6670996 },
    { lat: 48.8496102, lng: 17.6671248 },
    { lat: 48.8495037, lng: 17.6671538 },
    { lat: 48.8494198, lng: 17.6671476 },
    { lat: 48.8493504, lng: 17.6671944 },
    { lat: 48.8492517, lng: 17.6672058 },
    { lat: 48.8491925, lng: 17.667216 },
    { lat: 48.8489767, lng: 17.6672869 },
    { lat: 48.8489095, lng: 17.667276 },
    { lat: 48.848832, lng: 17.6672476 },
    { lat: 48.8487557, lng: 17.6672184 },
    { lat: 48.8486286, lng: 17.667263 },
    { lat: 48.8485484, lng: 17.6673547 },
    { lat: 48.8484573, lng: 17.6674491 },
    { lat: 48.8483665, lng: 17.6675339 },
    { lat: 48.8482815, lng: 17.6676015 },
    { lat: 48.8481871, lng: 17.6676817 },
    { lat: 48.8481162, lng: 17.6677773 },
    { lat: 48.8480576, lng: 17.6678498 },
    { lat: 48.8480193, lng: 17.6678877 },
    { lat: 48.8479216, lng: 17.6679324 },
    { lat: 48.8478108, lng: 17.6680577 },
    { lat: 48.8476971, lng: 17.6681739 },
    { lat: 48.8476573, lng: 17.6682224 },
    { lat: 48.8475311, lng: 17.6683191 },
    { lat: 48.8474429, lng: 17.6683872 },
    { lat: 48.8473277, lng: 17.6684438 },
    { lat: 48.8472282, lng: 17.6684958 },
    { lat: 48.8471119, lng: 17.668531 },
    { lat: 48.8469934, lng: 17.6685694 },
    { lat: 48.8468595, lng: 17.6686123 },
    { lat: 48.8467711, lng: 17.6686446 },
    { lat: 48.8466623, lng: 17.6686558 },
    { lat: 48.8465689, lng: 17.6686606 },
    { lat: 48.8464637, lng: 17.6686763 },
    { lat: 48.8463806, lng: 17.668702 },
    { lat: 48.846323, lng: 17.6687195 },
    { lat: 48.8461409, lng: 17.6687874 },
    { lat: 48.8460626, lng: 17.6688173 },
    { lat: 48.8460177, lng: 17.6688391 },
    { lat: 48.8458383, lng: 17.6688595 },
    { lat: 48.8456749, lng: 17.6688648 },
    { lat: 48.8455443, lng: 17.6688698 },
    { lat: 48.8454475, lng: 17.6689126 },
    { lat: 48.8453649, lng: 17.6689466 },
    { lat: 48.8453388, lng: 17.6689614 },
    { lat: 48.8452814, lng: 17.6689653 },
    { lat: 48.8452136, lng: 17.6689099 },
    { lat: 48.8451509, lng: 17.6688616 },
    { lat: 48.8450614, lng: 17.6688229 },
    { lat: 48.8449621, lng: 17.6687491 },
    { lat: 48.8448557, lng: 17.6686923 },
    { lat: 48.8447667, lng: 17.6687127 },
    { lat: 48.8446313, lng: 17.6687508 },
    { lat: 48.8445039, lng: 17.6687887 },
    { lat: 48.8443447, lng: 17.66879 },
    { lat: 48.8441779, lng: 17.668758 },
    { lat: 48.8441152, lng: 17.6687474 },
    { lat: 48.8439992, lng: 17.6686433 },
    { lat: 48.843942, lng: 17.668559 },
    { lat: 48.8439038, lng: 17.6684884 },
    { lat: 48.8438385, lng: 17.6682914 },
    { lat: 48.8437527, lng: 17.668219 },
    { lat: 48.8436699, lng: 17.6681213 },
    { lat: 48.8435149, lng: 17.6680631 },
    { lat: 48.8434279, lng: 17.6679891 },
    { lat: 48.8433615, lng: 17.6678838 },
    { lat: 48.8433211, lng: 17.6677784 },
    { lat: 48.8432798, lng: 17.6676209 },
    { lat: 48.8432465, lng: 17.6675034 },
    { lat: 48.8431966, lng: 17.6673001 },
    { lat: 48.8431587, lng: 17.6671447 },
    { lat: 48.8431257, lng: 17.6670133 },
    { lat: 48.8430751, lng: 17.6668718 },
    { lat: 48.8429955, lng: 17.6666983 },
    { lat: 48.8429368, lng: 17.6665698 },
    { lat: 48.8429018, lng: 17.6664979 },
    { lat: 48.8428243, lng: 17.6664309 },
    { lat: 48.842788, lng: 17.6664105 },
    { lat: 48.8427407, lng: 17.6664122 },
    { lat: 48.8426589, lng: 17.66644 },
    { lat: 48.8425969, lng: 17.6664608 },
    { lat: 48.8425594, lng: 17.6664568 },
    { lat: 48.8424932, lng: 17.6664277 },
    { lat: 48.8424619, lng: 17.6664125 },
    { lat: 48.8423916, lng: 17.6663549 },
    { lat: 48.842336, lng: 17.6663141 },
    { lat: 48.8422874, lng: 17.6662398 },
    { lat: 48.8422454, lng: 17.6661816 },
    { lat: 48.8421877, lng: 17.6660529 },
    { lat: 48.8421436, lng: 17.6659592 },
    { lat: 48.8420963, lng: 17.6658539 },
    { lat: 48.8420583, lng: 17.6657131 },
    { lat: 48.842014, lng: 17.6656201 },
    { lat: 48.8419574, lng: 17.6654887 },
    { lat: 48.8418851, lng: 17.6653965 },
    { lat: 48.8418223, lng: 17.6653335 },
    { lat: 48.8417476, lng: 17.665239 },
    { lat: 48.841685, lng: 17.6651556 },
    { lat: 48.8416311, lng: 17.665088 },
    { lat: 48.8415769, lng: 17.665035 },
    { lat: 48.8415014, lng: 17.6649636 },
    { lat: 48.8413835, lng: 17.6649009 },
    { lat: 48.8412716, lng: 17.6648637 },
    { lat: 48.8412212, lng: 17.6648154 },
    { lat: 48.8411745, lng: 17.6647363 },
    { lat: 48.8411331, lng: 17.6646141 },
    { lat: 48.8410933, lng: 17.6645017 },
    { lat: 48.8410525, lng: 17.6644084 },
    { lat: 48.8410186, lng: 17.6643352 },
    { lat: 48.8410139, lng: 17.6642411 },
    { lat: 48.840976, lng: 17.6642678 },
    { lat: 48.8409131, lng: 17.6642725 },
    { lat: 48.840866, lng: 17.6643134 },
    { lat: 48.8407862, lng: 17.6643017 },
    { lat: 48.8406899, lng: 17.6643514 },
    { lat: 48.8406003, lng: 17.6643991 },
    { lat: 48.8405104, lng: 17.6644419 },
    { lat: 48.84046, lng: 17.6644669 },
    { lat: 48.8403627, lng: 17.6645545 },
    { lat: 48.8402913, lng: 17.6646415 },
    { lat: 48.8402234, lng: 17.664712 },
    { lat: 48.8401433, lng: 17.6648038 },
    { lat: 48.8400564, lng: 17.6648965 },
    { lat: 48.8399798, lng: 17.6649528 },
    { lat: 48.8399094, lng: 17.6650022 },
    { lat: 48.8398321, lng: 17.6650663 },
    { lat: 48.8397295, lng: 17.6651416 },
    { lat: 48.8396509, lng: 17.6652007 },
    { lat: 48.8395764, lng: 17.6652574 },
    { lat: 48.8395172, lng: 17.6653043 },
    { lat: 48.8394251, lng: 17.6653653 },
    { lat: 48.8393609, lng: 17.6654053 },
    { lat: 48.8392743, lng: 17.6654836 },
    { lat: 48.8391784, lng: 17.6655383 },
    { lat: 48.8390075, lng: 17.6655873 },
    { lat: 48.8389108, lng: 17.6656113 },
    { lat: 48.8388391, lng: 17.6656208 },
    { lat: 48.838759, lng: 17.6656579 },
    { lat: 48.838649, lng: 17.665706 },
    { lat: 48.8385717, lng: 17.6657342 },
    { lat: 48.8384629, lng: 17.6657633 },
    { lat: 48.8382897, lng: 17.6658102 },
    { lat: 48.838191, lng: 17.6658388 },
    { lat: 48.8380859, lng: 17.6658939 },
    { lat: 48.838004, lng: 17.6659364 },
    { lat: 48.8379427, lng: 17.6659691 },
    { lat: 48.8378679, lng: 17.6660371 },
    { lat: 48.8377544, lng: 17.666137 },
    { lat: 48.837682, lng: 17.666208 },
    { lat: 48.8376415, lng: 17.6662446 },
    { lat: 48.8375693, lng: 17.6663386 },
    { lat: 48.8374541, lng: 17.6665005 },
    { lat: 48.8373935, lng: 17.6666159 },
    { lat: 48.837342, lng: 17.666714 },
    { lat: 48.8372684, lng: 17.6668391 },
    { lat: 48.837195, lng: 17.6669478 },
    { lat: 48.837099, lng: 17.6670753 },
    { lat: 48.8370009, lng: 17.6671679 },
    { lat: 48.836896, lng: 17.6672606 },
    { lat: 48.8367957, lng: 17.6673536 },
    { lat: 48.8366888, lng: 17.6674511 },
    { lat: 48.8365574, lng: 17.6675706 },
    { lat: 48.8364431, lng: 17.6676776 },
    { lat: 48.8363326, lng: 17.6677872 },
    { lat: 48.8362044, lng: 17.6679064 },
    { lat: 48.8360885, lng: 17.6680213 },
    { lat: 48.83598, lng: 17.6681283 },
    { lat: 48.8358495, lng: 17.6682264 },
    { lat: 48.8357537, lng: 17.6683024 },
    { lat: 48.8356135, lng: 17.6684079 },
    { lat: 48.8355102, lng: 17.668491 },
    { lat: 48.8353753, lng: 17.6685916 },
    { lat: 48.835275, lng: 17.6686657 },
    { lat: 48.8351786, lng: 17.6687341 },
    { lat: 48.8350411, lng: 17.6688281 },
    { lat: 48.8349252, lng: 17.6689257 },
    { lat: 48.834819, lng: 17.6690161 },
    { lat: 48.8347283, lng: 17.6691009 },
    { lat: 48.8346293, lng: 17.6691791 },
    { lat: 48.8345234, lng: 17.6692182 },
    { lat: 48.8344312, lng: 17.6692989 },
    { lat: 48.8343445, lng: 17.6693745 },
    { lat: 48.8342468, lng: 17.6694578 },
    { lat: 48.8341117, lng: 17.6695718 },
    { lat: 48.8340276, lng: 17.6696735 },
    { lat: 48.8339562, lng: 17.669759 },
    { lat: 48.8338911, lng: 17.6698385 },
    { lat: 48.8338061, lng: 17.669907 },
    { lat: 48.8337215, lng: 17.6699805 },
    { lat: 48.8336159, lng: 17.6700615 },
    { lat: 48.8335207, lng: 17.6701467 },
    { lat: 48.8332874, lng: 17.6703374 },
    { lat: 48.8331886, lng: 17.6704182 },
    { lat: 48.8331008, lng: 17.6704939 },
    { lat: 48.8330236, lng: 17.6705596 },
    { lat: 48.832914, lng: 17.6706504 },
    { lat: 48.8328057, lng: 17.6707438 },
    { lat: 48.8327198, lng: 17.670814 },
    { lat: 48.8326286, lng: 17.6708921 },
    { lat: 48.8325215, lng: 17.6709851 },
    { lat: 48.8324261, lng: 17.6710678 },
    { lat: 48.8322468, lng: 17.6712148 },
    { lat: 48.8321771, lng: 17.6712762 },
    { lat: 48.8320766, lng: 17.6713665 },
    { lat: 48.831984, lng: 17.6714395 },
    { lat: 48.8318704, lng: 17.6715377 },
    { lat: 48.8317724, lng: 17.6716133 },
    { lat: 48.8316831, lng: 17.6716843 },
    { lat: 48.8315979, lng: 17.6717485 },
    { lat: 48.831496, lng: 17.6718365 },
    { lat: 48.8314082, lng: 17.671914 },
    { lat: 48.8313452, lng: 17.6719724 },
    { lat: 48.8312574, lng: 17.6720484 },
    { lat: 48.8311825, lng: 17.6721146 },
    { lat: 48.831099, lng: 17.6721897 },
    { lat: 48.830975, lng: 17.6723023 },
    { lat: 48.8308855, lng: 17.6723876 },
    { lat: 48.8307723, lng: 17.6724919 },
    { lat: 48.8306527, lng: 17.6726021 },
    { lat: 48.8305224, lng: 17.672724 },
    { lat: 48.8304193, lng: 17.6728259 },
    { lat: 48.8303142, lng: 17.6729161 },
    { lat: 48.8302198, lng: 17.6729971 },
    { lat: 48.8301245, lng: 17.67308 },
    { lat: 48.8300209, lng: 17.673175 },
    { lat: 48.8299065, lng: 17.6732777 },
    { lat: 48.8298115, lng: 17.6733485 },
    { lat: 48.82972, lng: 17.6734198 },
    { lat: 48.8296075, lng: 17.6735006 },
    { lat: 48.8294961, lng: 17.6735788 },
    { lat: 48.8294366, lng: 17.6736215 },
    { lat: 48.8293505, lng: 17.6736895 },
    { lat: 48.8290437, lng: 17.6738571 },
    { lat: 48.8288348, lng: 17.6739662 },
    { lat: 48.8287517, lng: 17.6740122 },
    { lat: 48.8286211, lng: 17.6740736 },
    { lat: 48.8285334, lng: 17.6741143 },
    { lat: 48.8284359, lng: 17.6741632 },
    { lat: 48.8283405, lng: 17.6742092 },
    { lat: 48.8282388, lng: 17.6742631 },
    { lat: 48.8281242, lng: 17.6743257 },
    { lat: 48.8280129, lng: 17.6743868 },
    { lat: 48.8279098, lng: 17.6744355 },
    { lat: 48.8278325, lng: 17.6744834 },
    { lat: 48.8277401, lng: 17.6745598 },
    { lat: 48.8276427, lng: 17.6746437 },
    { lat: 48.8275436, lng: 17.6747236 },
    { lat: 48.8274113, lng: 17.6748271 },
    { lat: 48.8273248, lng: 17.6749446 },
    { lat: 48.8272447, lng: 17.6750535 },
    { lat: 48.8271623, lng: 17.6751652 },
    { lat: 48.8271059, lng: 17.6752382 },
    { lat: 48.8270389, lng: 17.6753239 },
    { lat: 48.8269587, lng: 17.675414 },
    { lat: 48.8268813, lng: 17.675512 },
    { lat: 48.8268058, lng: 17.6756066 },
    { lat: 48.8267178, lng: 17.6757166 },
    { lat: 48.8266423, lng: 17.6758094 },
    { lat: 48.8265611, lng: 17.6759023 },
    { lat: 48.8264757, lng: 17.6759999 },
    { lat: 48.8263742, lng: 17.6761127 },
    { lat: 48.8261989, lng: 17.6763076 },
    { lat: 48.8261061, lng: 17.6763945 },
    { lat: 48.8260106, lng: 17.6764756 },
    { lat: 48.8259099, lng: 17.6765625 },
    { lat: 48.8258176, lng: 17.6766415 },
    { lat: 48.8257285, lng: 17.6767157 },
    { lat: 48.8256435, lng: 17.6767826 },
    { lat: 48.8255451, lng: 17.6768546 },
    { lat: 48.8254357, lng: 17.6769291 },
    { lat: 48.8252113, lng: 17.6770963 },
    { lat: 48.8250126, lng: 17.6772433 },
    { lat: 48.8248904, lng: 17.6773282 },
    { lat: 48.8247533, lng: 17.6774323 },
    { lat: 48.8246341, lng: 17.6774938 },
    { lat: 48.8245353, lng: 17.6775564 },
    { lat: 48.8244232, lng: 17.6776238 },
    { lat: 48.8242698, lng: 17.6777011 },
    { lat: 48.8241579, lng: 17.6777522 },
    { lat: 48.8240716, lng: 17.6778002 },
    { lat: 48.8239765, lng: 17.6778317 },
    { lat: 48.8237436, lng: 17.6779187 },
    { lat: 48.8236119, lng: 17.6779614 },
    { lat: 48.8235006, lng: 17.6780054 },
    { lat: 48.8234253, lng: 17.6780299 },
    { lat: 48.8232985, lng: 17.6780785 },
    { lat: 48.8231901, lng: 17.6781162 },
    { lat: 48.8230858, lng: 17.6781455 },
    { lat: 48.8229619, lng: 17.6781665 },
    { lat: 48.8228687, lng: 17.6781936 },
    { lat: 48.8226195, lng: 17.6782546 },
    { lat: 48.8225093, lng: 17.6782805 },
    { lat: 48.822387, lng: 17.6783082 },
    { lat: 48.8222696, lng: 17.678329 },
    { lat: 48.8221682, lng: 17.6783496 },
    { lat: 48.8220956, lng: 17.6783642 },
    { lat: 48.8220474, lng: 17.6783871 },
    { lat: 48.8219236, lng: 17.6784287 },
    { lat: 48.8218095, lng: 17.6784663 },
    { lat: 48.8217248, lng: 17.6785005 },
    { lat: 48.8216286, lng: 17.6785348 },
    { lat: 48.8215324, lng: 17.6785664 },
    { lat: 48.8214219, lng: 17.6786061 },
    { lat: 48.8213213, lng: 17.6786401 },
    { lat: 48.8212264, lng: 17.6786765 },
    { lat: 48.8211294, lng: 17.6786955 },
    { lat: 48.8209851, lng: 17.6787357 },
    { lat: 48.8208896, lng: 17.6787613 },
    { lat: 48.8207651, lng: 17.6787942 },
    { lat: 48.8206231, lng: 17.6787965 },
    { lat: 48.820516, lng: 17.6788006 },
    { lat: 48.820401, lng: 17.6788024 },
    { lat: 48.8201991, lng: 17.6788064 },
    { lat: 48.8200998, lng: 17.6788076 },
    { lat: 48.8200143, lng: 17.6788111 },
    { lat: 48.8198623, lng: 17.6788191 },
    { lat: 48.8197643, lng: 17.6788228 },
    { lat: 48.8196752, lng: 17.6788244 },
    { lat: 48.8195895, lng: 17.6788255 },
    { lat: 48.819391, lng: 17.6788288 },
    { lat: 48.8192888, lng: 17.6788195 },
    { lat: 48.8192059, lng: 17.6788131 },
    { lat: 48.819088, lng: 17.6788034 },
    { lat: 48.8189892, lng: 17.6787961 },
    { lat: 48.8189143, lng: 17.6787897 },
    { lat: 48.8187474, lng: 17.6787928 },
    { lat: 48.8185794, lng: 17.6787954 },
    { lat: 48.8184124, lng: 17.6787985 },
    { lat: 48.8182737, lng: 17.6788012 },
    { lat: 48.8181915, lng: 17.6788231 },
    { lat: 48.8180892, lng: 17.6788487 },
    { lat: 48.817988, lng: 17.6788742 },
    { lat: 48.8178836, lng: 17.678902 },
    { lat: 48.8177594, lng: 17.6789375 },
    { lat: 48.8176816, lng: 17.6789572 },
    { lat: 48.8175892, lng: 17.678978 },
    { lat: 48.8175024, lng: 17.6790006 },
    { lat: 48.8174041, lng: 17.6790351 },
    { lat: 48.817337, lng: 17.6790626 },
    { lat: 48.8172553, lng: 17.6790946 },
    { lat: 48.8171627, lng: 17.6791292 },
    { lat: 48.8170838, lng: 17.6791498 },
    { lat: 48.817011, lng: 17.6791789 },
    { lat: 48.8168861, lng: 17.6792214 },
    { lat: 48.8167881, lng: 17.6792611 },
    { lat: 48.8167017, lng: 17.6792879 },
    { lat: 48.8166159, lng: 17.6793248 },
    { lat: 48.8163349, lng: 17.6794554 },
    { lat: 48.8162176, lng: 17.6795114 },
    { lat: 48.8161015, lng: 17.6795697 },
    { lat: 48.8159928, lng: 17.6796193 },
    { lat: 48.8158658, lng: 17.6796827 },
    { lat: 48.8157806, lng: 17.679729 },
    { lat: 48.8156748, lng: 17.6797894 },
    { lat: 48.8155683, lng: 17.6798575 },
    { lat: 48.8154536, lng: 17.67992 },
    { lat: 48.8153646, lng: 17.6799753 },
    { lat: 48.8152849, lng: 17.68002 },
    { lat: 48.8151991, lng: 17.6800749 },
    { lat: 48.815104, lng: 17.6801252 },
    { lat: 48.8150069, lng: 17.6801809 },
    { lat: 48.814896, lng: 17.6802488 },
    { lat: 48.8148054, lng: 17.6803181 },
    { lat: 48.8147352, lng: 17.68037 },
    { lat: 48.8146275, lng: 17.6804537 },
    { lat: 48.8145423, lng: 17.6805179 },
    { lat: 48.8144488, lng: 17.6805944 },
    { lat: 48.8143575, lng: 17.6806688 },
    { lat: 48.8141863, lng: 17.6808044 },
    { lat: 48.8141099, lng: 17.6808656 },
    { lat: 48.8140284, lng: 17.6809345 },
    { lat: 48.8139183, lng: 17.6810167 },
    { lat: 48.8138442, lng: 17.6810778 },
    { lat: 48.8137647, lng: 17.6811421 },
    { lat: 48.8136733, lng: 17.6812183 },
    { lat: 48.8135253, lng: 17.6813445 },
    { lat: 48.8133429, lng: 17.6814968 },
    { lat: 48.8132395, lng: 17.6815781 },
    { lat: 48.813057, lng: 17.6817311 },
    { lat: 48.8129678, lng: 17.6818023 },
    { lat: 48.8128549, lng: 17.6818961 },
    { lat: 48.8127633, lng: 17.6819651 },
    { lat: 48.8126535, lng: 17.6820517 },
    { lat: 48.8125825, lng: 17.6821087 },
    { lat: 48.8124288, lng: 17.682234 },
    { lat: 48.8123342, lng: 17.6823107 },
    { lat: 48.8122373, lng: 17.6823873 },
    { lat: 48.8121274, lng: 17.6824712 },
    { lat: 48.8119626, lng: 17.6826019 },
    { lat: 48.8118831, lng: 17.6826662 },
    { lat: 48.8117992, lng: 17.6827329 },
    { lat: 48.8115528, lng: 17.6829302 },
    { lat: 48.8114872, lng: 17.6829819 },
    { lat: 48.8113766, lng: 17.6830566 },
    { lat: 48.8112422, lng: 17.6831466 },
    { lat: 48.8111429, lng: 17.6832205 },
    { lat: 48.8110388, lng: 17.6832904 },
    { lat: 48.8109481, lng: 17.683357 },
    { lat: 48.8108419, lng: 17.6834291 },
    { lat: 48.8107272, lng: 17.6835086 },
    { lat: 48.8106459, lng: 17.6835633 },
    { lat: 48.8105399, lng: 17.6836758 },
    { lat: 48.8104383, lng: 17.6837855 },
    { lat: 48.8103291, lng: 17.6839002 },
    { lat: 48.810241, lng: 17.6839905 },
    { lat: 48.8101553, lng: 17.6840834 },
    { lat: 48.8100584, lng: 17.6841788 },
    { lat: 48.8099706, lng: 17.6842737 },
    { lat: 48.8098398, lng: 17.6844409 },
    { lat: 48.80969, lng: 17.6846281 },
    { lat: 48.8095529, lng: 17.6848032 },
    { lat: 48.8094917, lng: 17.6848899 },
    { lat: 48.8093973, lng: 17.6850069 },
    { lat: 48.8093201, lng: 17.6851088 },
    { lat: 48.8092544, lng: 17.685198 },
    { lat: 48.8091716, lng: 17.6853004 },
    { lat: 48.8090945, lng: 17.6854045 },
    { lat: 48.8090131, lng: 17.6855113 },
    { lat: 48.8089013, lng: 17.6856588 },
    { lat: 48.8088222, lng: 17.6857659 },
    { lat: 48.8087231, lng: 17.6858256 },
    { lat: 48.8085399, lng: 17.6858935 },
    { lat: 48.8083538, lng: 17.6859328 },
    { lat: 48.8081867, lng: 17.6859692 },
    { lat: 48.8080865, lng: 17.6859915 },
    { lat: 48.807928, lng: 17.6860226 },
    { lat: 48.8078137, lng: 17.6861637 },
    { lat: 48.8077313, lng: 17.6862561 },
    { lat: 48.8075953, lng: 17.6864284 },
    { lat: 48.8074949, lng: 17.686505 },
    { lat: 48.8074009, lng: 17.6865722 },
    { lat: 48.8072886, lng: 17.6866729 },
    { lat: 48.8072054, lng: 17.6867701 },
    { lat: 48.8071294, lng: 17.6868552 },
    { lat: 48.8070445, lng: 17.6869434 },
    { lat: 48.8069588, lng: 17.6870358 },
    { lat: 48.8068682, lng: 17.6871405 },
    { lat: 48.8067867, lng: 17.6872661 },
    { lat: 48.8067308, lng: 17.6873484 },
    { lat: 48.8066981, lng: 17.6874582 },
    { lat: 48.806664, lng: 17.6875823 },
    { lat: 48.8065715, lng: 17.6876916 },
    { lat: 48.8064051, lng: 17.6878861 },
    { lat: 48.8062662, lng: 17.6880494 },
    { lat: 48.8062018, lng: 17.6881766 },
    { lat: 48.8061453, lng: 17.688287 },
    { lat: 48.806132, lng: 17.688309 },
    { lat: 48.8061027, lng: 17.6883454 },
    { lat: 48.8059591, lng: 17.6884873 },
    { lat: 48.8058663, lng: 17.6886296 },
    { lat: 48.8057927, lng: 17.6887008 },
    { lat: 48.8057344, lng: 17.6886697 },
    { lat: 48.8056388, lng: 17.6887507 },
    { lat: 48.8054929, lng: 17.688874 },
    { lat: 48.8053749, lng: 17.6889182 },
    { lat: 48.8053348, lng: 17.6890376 },
    { lat: 48.8052545, lng: 17.6890707 },
    { lat: 48.8051508, lng: 17.689164 },
    { lat: 48.8050598, lng: 17.6892265 },
    { lat: 48.8050121, lng: 17.6892397 },
    { lat: 48.8049677, lng: 17.6893262 },
    { lat: 48.8048722, lng: 17.689424 },
    { lat: 48.8048061, lng: 17.6894709 },
    { lat: 48.8047285, lng: 17.6895307 },
    { lat: 48.8046013, lng: 17.689627 },
    { lat: 48.8045676, lng: 17.6896848 },
    { lat: 48.8044912, lng: 17.6896709 },
    { lat: 48.8044178, lng: 17.6896542 },
    { lat: 48.804314, lng: 17.6896554 },
    { lat: 48.8041947, lng: 17.6896427 },
    { lat: 48.8041785, lng: 17.6896552 },
    { lat: 48.8040935, lng: 17.6897242 },
    { lat: 48.8040635, lng: 17.6897656 },
    { lat: 48.8039758, lng: 17.6898819 },
    { lat: 48.8039626, lng: 17.6899248 },
    { lat: 48.8039119, lng: 17.6900543 },
    { lat: 48.8038379, lng: 17.6901181 },
    { lat: 48.8037257, lng: 17.6902019 },
    { lat: 48.8035518, lng: 17.690201 },
    { lat: 48.8035097, lng: 17.6901955 },
    { lat: 48.8034558, lng: 17.6902373 },
    { lat: 48.8033906, lng: 17.6903336 },
    { lat: 48.8033403, lng: 17.6903968 },
    { lat: 48.8032541, lng: 17.6905372 },
    { lat: 48.8031939, lng: 17.6906405 },
    { lat: 48.8031281, lng: 17.6907423 },
    { lat: 48.8030267, lng: 17.6908211 },
    { lat: 48.8029791, lng: 17.6908911 },
    { lat: 48.8029414, lng: 17.6910105 },
    { lat: 48.8028906, lng: 17.6911207 },
    { lat: 48.802882, lng: 17.6911283 },
    { lat: 48.802781, lng: 17.691212 },
    { lat: 48.8027565, lng: 17.6912342 },
    { lat: 48.8026238, lng: 17.6913166 },
    { lat: 48.8025487, lng: 17.6914158 },
    { lat: 48.8025075, lng: 17.6915165 },
    { lat: 48.8024713, lng: 17.6916076 },
    { lat: 48.8024599, lng: 17.6916411 },
    { lat: 48.8024028, lng: 17.6917962 },
    { lat: 48.8023683, lng: 17.6918942 },
    { lat: 48.8023197, lng: 17.6920571 },
    { lat: 48.8022265, lng: 17.6922121 },
    { lat: 48.8022132, lng: 17.6922343 },
    { lat: 48.8021232, lng: 17.6923679 },
    { lat: 48.8020571, lng: 17.6924666 },
    { lat: 48.8019766, lng: 17.69259 },
    { lat: 48.8019506, lng: 17.6926248 },
    { lat: 48.8018881, lng: 17.6927114 },
    { lat: 48.8017992, lng: 17.6927881 },
    { lat: 48.8017076, lng: 17.6928226 },
    { lat: 48.8016171, lng: 17.6928567 },
    { lat: 48.8015245, lng: 17.6928933 },
    { lat: 48.8014484, lng: 17.692922 },
    { lat: 48.8013099, lng: 17.6930562 },
    { lat: 48.8012549, lng: 17.693152 },
    { lat: 48.8011896, lng: 17.6932647 },
    { lat: 48.8011457, lng: 17.6933764 },
    { lat: 48.8011047, lng: 17.6934809 },
    { lat: 48.801042, lng: 17.6936171 },
    { lat: 48.8009908, lng: 17.6937202 },
    { lat: 48.8009503, lng: 17.693758 },
    { lat: 48.8009492, lng: 17.6937769 },
    { lat: 48.8009456, lng: 17.6938296 },
    { lat: 48.8008831, lng: 17.6939682 },
    { lat: 48.8008676, lng: 17.6940829 },
    { lat: 48.8008576, lng: 17.6941944 },
    { lat: 48.8007959, lng: 17.6942007 },
    { lat: 48.8007456, lng: 17.6942633 },
    { lat: 48.8006872, lng: 17.6942883 },
    { lat: 48.8006674, lng: 17.6943133 },
    { lat: 48.8006071, lng: 17.6943976 },
    { lat: 48.8005516, lng: 17.6944695 },
    { lat: 48.8005382, lng: 17.694506 },
    { lat: 48.8004972, lng: 17.6946105 },
    { lat: 48.8004578, lng: 17.6947049 },
    { lat: 48.8004486, lng: 17.69472 },
    { lat: 48.8003993, lng: 17.6947988 },
    { lat: 48.80035, lng: 17.6948043 },
    { lat: 48.8002505, lng: 17.6948202 },
    { lat: 48.8001557, lng: 17.6948405 },
    { lat: 48.8001063, lng: 17.6948813 },
    { lat: 48.8000764, lng: 17.6949257 },
    { lat: 48.800045, lng: 17.6949681 },
    { lat: 48.8000745, lng: 17.6950228 },
    { lat: 48.8000832, lng: 17.6950366 },
    { lat: 48.8001563, lng: 17.6951595 },
    { lat: 48.8002257, lng: 17.6952784 },
    { lat: 48.8003025, lng: 17.6954059 },
    { lat: 48.8003691, lng: 17.6955152 },
    { lat: 48.8004346, lng: 17.6956247 },
    { lat: 48.800716, lng: 17.6960869 },
    { lat: 48.8007889, lng: 17.6961864 },
    { lat: 48.8008721, lng: 17.6963087 },
    { lat: 48.8009264, lng: 17.6963836 },
    { lat: 48.8010652, lng: 17.6965831 },
    { lat: 48.8011245, lng: 17.6966665 },
    { lat: 48.8011914, lng: 17.6967618 },
    { lat: 48.8012399, lng: 17.6968343 },
    { lat: 48.8011815, lng: 17.6969495 },
    { lat: 48.8011129, lng: 17.6970981 },
    { lat: 48.8010599, lng: 17.6972102 },
    { lat: 48.8009842, lng: 17.6973547 },
    { lat: 48.8009235, lng: 17.6974697 },
    { lat: 48.800867, lng: 17.6975773 },
    { lat: 48.8007811, lng: 17.6977415 },
    { lat: 48.8007395, lng: 17.6978176 },
    { lat: 48.8006878, lng: 17.6978206 },
    { lat: 48.8005938, lng: 17.6977436 },
    { lat: 48.8005024, lng: 17.6976717 },
    { lat: 48.800436, lng: 17.6976192 },
    { lat: 48.8004843, lng: 17.6974884 },
    { lat: 48.8005473, lng: 17.6973208 },
    { lat: 48.8004354, lng: 17.6972265 },
    { lat: 48.8003378, lng: 17.6971451 },
    { lat: 48.800151, lng: 17.6969912 },
    { lat: 48.800039, lng: 17.6968968 },
    { lat: 48.7999722, lng: 17.6968396 },
    { lat: 48.7998781, lng: 17.6967577 },
    { lat: 48.7997831, lng: 17.6966648 },
    { lat: 48.7996975, lng: 17.6965777 },
    { lat: 48.7996143, lng: 17.696493 },
    { lat: 48.7995466, lng: 17.6964193 },
    { lat: 48.7994552, lng: 17.6963306 },
    { lat: 48.799368, lng: 17.6962344 },
    { lat: 48.7993103, lng: 17.6961767 },
    { lat: 48.7992126, lng: 17.6960764 },
    { lat: 48.7991102, lng: 17.6959717 },
    { lat: 48.799016, lng: 17.6958737 },
    { lat: 48.7988813, lng: 17.6957402 },
    { lat: 48.7988227, lng: 17.6958339 },
    { lat: 48.7987595, lng: 17.6959421 },
    { lat: 48.7986917, lng: 17.6960503 },
    { lat: 48.7986325, lng: 17.6961534 },
    { lat: 48.798568, lng: 17.6962593 },
    { lat: 48.7985556, lng: 17.6962789 },
    { lat: 48.7984785, lng: 17.696419 },
    { lat: 48.7984033, lng: 17.6965539 },
    { lat: 48.7983335, lng: 17.6966693 },
    { lat: 48.7982839, lng: 17.6967601 },
    { lat: 48.7982199, lng: 17.6968752 },
    { lat: 48.7981514, lng: 17.6968442 },
    { lat: 48.7981001, lng: 17.6968003 },
    { lat: 48.7980879, lng: 17.6968955 },
    { lat: 48.7980563, lng: 17.6970611 },
    { lat: 48.7980336, lng: 17.6972044 },
    { lat: 48.7980076, lng: 17.6973504 },
    { lat: 48.7979768, lng: 17.6975274 },
    { lat: 48.797963, lng: 17.6975968 },
    { lat: 48.797946, lng: 17.6977044 },
    { lat: 48.7978683, lng: 17.6977261 },
    { lat: 48.7977621, lng: 17.69778 },
    { lat: 48.7976635, lng: 17.6978285 },
    { lat: 48.7975792, lng: 17.6978908 },
    { lat: 48.7975533, lng: 17.6979091 },
    { lat: 48.797523, lng: 17.6979301 },
    { lat: 48.7974609, lng: 17.697967 },
    { lat: 48.7974012, lng: 17.6980062 },
    { lat: 48.7973567, lng: 17.6980351 },
    { lat: 48.7973044, lng: 17.6980644 },
    { lat: 48.7972723, lng: 17.6980762 },
    { lat: 48.7972141, lng: 17.6981033 },
    { lat: 48.797158, lng: 17.6981257 },
    { lat: 48.7970513, lng: 17.6981708 },
    { lat: 48.7969731, lng: 17.698204 },
    { lat: 48.7969293, lng: 17.6982256 },
    { lat: 48.7969196, lng: 17.698198 },
    { lat: 48.7968357, lng: 17.6982269 },
    { lat: 48.7967098, lng: 17.6982725 },
    { lat: 48.796629, lng: 17.698299 },
    { lat: 48.7965241, lng: 17.6983364 },
    { lat: 48.796342, lng: 17.698405 },
    { lat: 48.7962731, lng: 17.6984237 },
    { lat: 48.7961763, lng: 17.6984652 },
    { lat: 48.7961064, lng: 17.6985028 },
    { lat: 48.7959734, lng: 17.6985798 },
    { lat: 48.7958817, lng: 17.6986474 },
    { lat: 48.7957799, lng: 17.6987199 },
    { lat: 48.7956913, lng: 17.6987824 },
    { lat: 48.7956728, lng: 17.6987932 },
    { lat: 48.7957437, lng: 17.6989186 },
    { lat: 48.7958158, lng: 17.6990631 },
    { lat: 48.7958642, lng: 17.699169 },
    { lat: 48.7959134, lng: 17.6993078 },
    { lat: 48.7959366, lng: 17.6993912 },
    { lat: 48.7959732, lng: 17.699545 },
    { lat: 48.7959937, lng: 17.6996194 },
    { lat: 48.7960089, lng: 17.6997557 },
    { lat: 48.7960077, lng: 17.6998835 },
    { lat: 48.795895, lng: 17.6999426 },
    { lat: 48.7957724, lng: 17.7000049 },
    { lat: 48.7957257, lng: 17.7000341 },
    { lat: 48.7956676, lng: 17.7000615 },
    { lat: 48.7956229, lng: 17.7000881 },
    { lat: 48.7955608, lng: 17.7001438 },
    { lat: 48.7954883, lng: 17.7001934 },
    { lat: 48.7954546, lng: 17.7002143 },
    { lat: 48.7954161, lng: 17.7002309 },
    { lat: 48.7953436, lng: 17.7002809 },
    { lat: 48.7952581, lng: 17.7003429 },
    { lat: 48.795185, lng: 17.700402 },
    { lat: 48.7952409, lng: 17.7005756 },
    { lat: 48.7952911, lng: 17.7007288 },
    { lat: 48.7953423, lng: 17.7008818 },
    { lat: 48.7954018, lng: 17.7010601 },
    { lat: 48.7954865, lng: 17.7013147 },
    { lat: 48.7955615, lng: 17.7015421 },
    { lat: 48.7956281, lng: 17.7017436 },
    { lat: 48.7956723, lng: 17.701892 },
    { lat: 48.7957391, lng: 17.7020962 },
    { lat: 48.7957701, lng: 17.702196 },
    { lat: 48.795785, lng: 17.7022354 },
    { lat: 48.7958301, lng: 17.7023811 },
    { lat: 48.7958854, lng: 17.7025459 },
    { lat: 48.7959559, lng: 17.7027731 },
    { lat: 48.795887, lng: 17.7028251 },
    { lat: 48.7958093, lng: 17.7028843 },
    { lat: 48.7957358, lng: 17.7029387 },
    { lat: 48.7956595, lng: 17.703 },
    { lat: 48.7955742, lng: 17.7030624 },
    { lat: 48.7955229, lng: 17.7031295 },
    { lat: 48.7955399, lng: 17.7031831 },
    { lat: 48.7954568, lng: 17.7033016 },
    { lat: 48.7953095, lng: 17.7035123 },
    { lat: 48.7952299, lng: 17.7036308 },
    { lat: 48.7951669, lng: 17.7037247 },
    { lat: 48.795085, lng: 17.7038434 },
    { lat: 48.794999, lng: 17.7039698 },
    { lat: 48.7949295, lng: 17.7040688 },
    { lat: 48.7947855, lng: 17.704279 },
    { lat: 48.7947141, lng: 17.7043826 },
    { lat: 48.7946437, lng: 17.7044865 },
    { lat: 48.7945714, lng: 17.7045954 },
    { lat: 48.7944876, lng: 17.7047188 },
    { lat: 48.794414, lng: 17.7048254 },
    { lat: 48.794321, lng: 17.7049658 },
    { lat: 48.7942377, lng: 17.7050775 },
    { lat: 48.7941664, lng: 17.7051863 },
    { lat: 48.7941076, lng: 17.7052753 },
    { lat: 48.7940381, lng: 17.7053767 },
    { lat: 48.7939395, lng: 17.7055346 },
    { lat: 48.7938698, lng: 17.7054279 },
    { lat: 48.7938053, lng: 17.7053369 },
    { lat: 48.7937377, lng: 17.7052278 },
    { lat: 48.7937066, lng: 17.7051826 },
    { lat: 48.7936452, lng: 17.7050824 },
    { lat: 48.793586, lng: 17.7050011 },
    { lat: 48.7935177, lng: 17.7049013 },
    { lat: 48.7933946, lng: 17.7047175 },
    { lat: 48.7934257, lng: 17.704673 },
    { lat: 48.7934791, lng: 17.7046057 },
    { lat: 48.7934257, lng: 17.7045097 },
    { lat: 48.7933793, lng: 17.7044343 },
    { lat: 48.7933326, lng: 17.7045179 },
    { lat: 48.7932546, lng: 17.7046267 },
    { lat: 48.7931266, lng: 17.7047841 },
    { lat: 48.7930481, lng: 17.7048836 },
    { lat: 48.7929707, lng: 17.704983 },
    { lat: 48.7928923, lng: 17.7050851 },
    { lat: 48.7928051, lng: 17.7051923 },
    { lat: 48.7927549, lng: 17.7052571 },
    { lat: 48.7927221, lng: 17.7053095 },
    { lat: 48.7926483, lng: 17.7054143 },
    { lat: 48.7925588, lng: 17.7055552 },
    { lat: 48.7924586, lng: 17.7057052 },
    { lat: 48.7923616, lng: 17.7058531 },
    { lat: 48.7922845, lng: 17.7059768 },
    { lat: 48.7922028, lng: 17.7061147 },
    { lat: 48.7921149, lng: 17.7062648 },
    { lat: 48.7920309, lng: 17.7064219 },
    { lat: 48.7919803, lng: 17.7064984 },
    { lat: 48.7919452, lng: 17.7065694 },
    { lat: 48.791881, lng: 17.7067002 },
    { lat: 48.7918253, lng: 17.7068403 },
    { lat: 48.791751, lng: 17.7069904 },
    { lat: 48.7917133, lng: 17.7070756 },
    { lat: 48.7916403, lng: 17.7072089 },
    { lat: 48.791585, lng: 17.7073184 },
    { lat: 48.7915395, lng: 17.7074065 },
    { lat: 48.7914576, lng: 17.7075608 },
    { lat: 48.7913577, lng: 17.7077346 },
    { lat: 48.7912553, lng: 17.7079038 },
    { lat: 48.79115, lng: 17.7080635 },
    { lat: 48.7911049, lng: 17.7081374 },
    { lat: 48.7910348, lng: 17.7082635 },
    { lat: 48.7909501, lng: 17.7084087 },
    { lat: 48.7908637, lng: 17.7085654 },
    { lat: 48.7908222, lng: 17.7086418 },
    { lat: 48.7907841, lng: 17.7087202 },
    { lat: 48.7907592, lng: 17.7087558 },
    { lat: 48.7907432, lng: 17.708735 },
    { lat: 48.7907296, lng: 17.7087163 },
    { lat: 48.7906536, lng: 17.7085994 },
    { lat: 48.7905341, lng: 17.7084194 },
    { lat: 48.7904742, lng: 17.7083279 },
    { lat: 48.7904243, lng: 17.7082507 },
    { lat: 48.7903611, lng: 17.7081597 },
    { lat: 48.7902614, lng: 17.7080098 },
    { lat: 48.7902142, lng: 17.7079373 },
    { lat: 48.7901486, lng: 17.7078276 },
    { lat: 48.790074, lng: 17.7076988 },
    { lat: 48.7900053, lng: 17.7075911 },
    { lat: 48.7899574, lng: 17.7075089 },
    { lat: 48.7898872, lng: 17.7073967 },
    { lat: 48.7898173, lng: 17.7072892 },
    { lat: 48.7897544, lng: 17.707184 },
    { lat: 48.7896897, lng: 17.7070693 },
    { lat: 48.7896463, lng: 17.7070058 },
    { lat: 48.7895182, lng: 17.7067974 },
    { lat: 48.7894821, lng: 17.706744 },
    { lat: 48.7894435, lng: 17.706685 },
    { lat: 48.7894102, lng: 17.7066385 },
    { lat: 48.7893764, lng: 17.7065847 },
    { lat: 48.789334, lng: 17.7065193 },
    { lat: 48.7892678, lng: 17.7064164 },
    { lat: 48.7892317, lng: 17.7063624 },
    { lat: 48.7891836, lng: 17.7062971 },
    { lat: 48.789138, lng: 17.7062339 },
    { lat: 48.7890749, lng: 17.7061451 },
    { lat: 48.7890477, lng: 17.7061079 },
    { lat: 48.7889998, lng: 17.7060445 },
    { lat: 48.7889481, lng: 17.7059747 },
    { lat: 48.7888866, lng: 17.7058928 },
    { lat: 48.788881, lng: 17.7058773 },
    { lat: 48.7888169, lng: 17.7057893 },
    { lat: 48.7887251, lng: 17.705655 },
    { lat: 48.7886558, lng: 17.7055572 },
    { lat: 48.788522, lng: 17.7053647 },
    { lat: 48.7884523, lng: 17.7052599 },
    { lat: 48.7883839, lng: 17.7051583 },
    { lat: 48.7883728, lng: 17.7051444 },
    { lat: 48.7882766, lng: 17.7053048 },
    { lat: 48.7881792, lng: 17.7054823 },
    { lat: 48.7881033, lng: 17.7056051 },
    { lat: 48.7879964, lng: 17.7057564 },
    { lat: 48.787898, lng: 17.7059198 },
    { lat: 48.7877943, lng: 17.7060877 },
    { lat: 48.7876947, lng: 17.7062651 },
    { lat: 48.7876247, lng: 17.7064113 },
    { lat: 48.7875521, lng: 17.706607 },
    { lat: 48.7875041, lng: 17.7067808 },
    { lat: 48.7874616, lng: 17.7069517 },
    { lat: 48.7874209, lng: 17.7070971 },
    { lat: 48.7873415, lng: 17.7073847 },
    { lat: 48.7872937, lng: 17.707561 },
    { lat: 48.7872505, lng: 17.70772 },
    { lat: 48.787224, lng: 17.7078579 },
    { lat: 48.787187, lng: 17.7080455 },
    { lat: 48.7871803, lng: 17.7081025 },
    { lat: 48.7871396, lng: 17.7082287 },
    { lat: 48.7871059, lng: 17.7083218 },
    { lat: 48.7870315, lng: 17.7084891 },
    { lat: 48.7869844, lng: 17.7085872 },
    { lat: 48.7869047, lng: 17.7087215 },
    { lat: 48.7868223, lng: 17.7088511 },
    { lat: 48.7867595, lng: 17.7089663 },
    { lat: 48.7866969, lng: 17.7090695 },
    { lat: 48.7866217, lng: 17.7092037 },
    { lat: 48.7865424, lng: 17.7093451 },
    { lat: 48.7864629, lng: 17.7094845 },
    { lat: 48.7863794, lng: 17.7096306 },
    { lat: 48.7862967, lng: 17.709772 },
    { lat: 48.7862387, lng: 17.7098751 },
    { lat: 48.7861779, lng: 17.7100067 },
    { lat: 48.786133, lng: 17.7101022 },
    { lat: 48.7860819, lng: 17.7102432 },
    { lat: 48.7860185, lng: 17.7104055 },
    { lat: 48.7859739, lng: 17.7105601 },
    { lat: 48.7859485, lng: 17.7106435 },
    { lat: 48.7859235, lng: 17.7107859 },
    { lat: 48.7858886, lng: 17.7109167 },
    { lat: 48.785873, lng: 17.7110493 },
    { lat: 48.7858633, lng: 17.7112386 },
    { lat: 48.7858532, lng: 17.7115698 },
    { lat: 48.7858473, lng: 17.7117115 },
    { lat: 48.7858424, lng: 17.7118511 },
    { lat: 48.7858429, lng: 17.712007 },
    { lat: 48.7858334, lng: 17.7121442 },
    { lat: 48.7858281, lng: 17.712279 },
    { lat: 48.7858242, lng: 17.7124704 },
    { lat: 48.7858102, lng: 17.712464 },
    { lat: 48.7856892, lng: 17.7125331 },
    { lat: 48.7856085, lng: 17.71258 },
    { lat: 48.7855113, lng: 17.7126159 },
    { lat: 48.7854023, lng: 17.7126612 },
    { lat: 48.7853135, lng: 17.7127037 },
    { lat: 48.7851792, lng: 17.7127944 },
    { lat: 48.7850905, lng: 17.7128394 },
    { lat: 48.7849928, lng: 17.7128865 },
    { lat: 48.7849143, lng: 17.7129314 },
    { lat: 48.7847669, lng: 17.7129941 },
    { lat: 48.7846228, lng: 17.7130546 },
    { lat: 48.7844948, lng: 17.7131217 },
    { lat: 48.7844358, lng: 17.713154 },
    { lat: 48.7843794, lng: 17.7131907 },
    { lat: 48.784271, lng: 17.7132457 },
    { lat: 48.7841422, lng: 17.7132985 },
    { lat: 48.7840531, lng: 17.7133175 },
    { lat: 48.783969, lng: 17.7133476 },
    { lat: 48.7838403, lng: 17.7134197 },
    { lat: 48.7837212, lng: 17.713484 },
    { lat: 48.7836523, lng: 17.7135214 },
    { lat: 48.7835442, lng: 17.7135808 },
    { lat: 48.7833072, lng: 17.713691 },
    { lat: 48.7831762, lng: 17.7137462 },
    { lat: 48.7830477, lng: 17.7138041 },
    { lat: 48.7827922, lng: 17.7139262 },
    { lat: 48.7827287, lng: 17.7139774 },
    { lat: 48.7826682, lng: 17.7141871 },
    { lat: 48.7826218, lng: 17.7143487 },
    { lat: 48.7825806, lng: 17.7145058 },
    { lat: 48.7825207, lng: 17.714706 },
    { lat: 48.7824078, lng: 17.715108 },
    { lat: 48.782354, lng: 17.7152796 },
    { lat: 48.7822747, lng: 17.7153477 },
    { lat: 48.7822532, lng: 17.7153647 },
    { lat: 48.7822252, lng: 17.7154956 },
    { lat: 48.7821903, lng: 17.7156829 },
    { lat: 48.7821176, lng: 17.7160417 },
    { lat: 48.7820972, lng: 17.7161672 },
    { lat: 48.7819981, lng: 17.7162644 },
    { lat: 48.7819035, lng: 17.7163591 },
    { lat: 48.7818251, lng: 17.716441 },
    { lat: 48.7816166, lng: 17.7166498 },
    { lat: 48.7815071, lng: 17.7167587 },
    { lat: 48.7814016, lng: 17.7168608 },
    { lat: 48.7812901, lng: 17.7169748 },
    { lat: 48.7811826, lng: 17.7170813 },
    { lat: 48.7810892, lng: 17.7171782 },
    { lat: 48.780975, lng: 17.7173041 },
    { lat: 48.7809136, lng: 17.7173697 },
    { lat: 48.7808717, lng: 17.7172406 },
    { lat: 48.7808084, lng: 17.7170556 },
    { lat: 48.7807438, lng: 17.7168683 },
    { lat: 48.7806936, lng: 17.7167134 },
    { lat: 48.780637, lng: 17.7165451 },
    { lat: 48.7806148, lng: 17.7164795 },
    { lat: 48.7805768, lng: 17.7163221 },
    { lat: 48.7805549, lng: 17.7162214 },
    { lat: 48.7805119, lng: 17.7160734 },
    { lat: 48.780469, lng: 17.7159282 },
    { lat: 48.7803667, lng: 17.715697 },
    { lat: 48.7802454, lng: 17.7157074 },
    { lat: 48.7801073, lng: 17.7157201 },
    { lat: 48.7799852, lng: 17.7157352 },
    { lat: 48.7798785, lng: 17.715745 },
    { lat: 48.779759, lng: 17.7157644 },
    { lat: 48.7796472, lng: 17.7157842 },
    { lat: 48.7795456, lng: 17.715801 },
    { lat: 48.7794306, lng: 17.7158228 },
    { lat: 48.7793451, lng: 17.715846 },
    { lat: 48.779219, lng: 17.7158706 },
    { lat: 48.7791211, lng: 17.7158943 },
    { lat: 48.7789933, lng: 17.7159261 },
    { lat: 48.7788942, lng: 17.7159498 },
    { lat: 48.7787706, lng: 17.7159959 },
    { lat: 48.7786038, lng: 17.7160733 },
    { lat: 48.7784949, lng: 17.7161399 },
    { lat: 48.7784704, lng: 17.7161618 },
    { lat: 48.7783798, lng: 17.7162513 },
    { lat: 48.7783056, lng: 17.7163456 },
    { lat: 48.7782153, lng: 17.7164566 },
    { lat: 48.7781569, lng: 17.7165336 },
    { lat: 48.7780245, lng: 17.7167495 },
    { lat: 48.7779746, lng: 17.7168194 },
    { lat: 48.7778722, lng: 17.7169355 },
    { lat: 48.7777283, lng: 17.7171092 },
    { lat: 48.7777199, lng: 17.7170834 },
    { lat: 48.7776401, lng: 17.716906 },
    { lat: 48.7775442, lng: 17.7167051 },
    { lat: 48.7774714, lng: 17.7165514 },
    { lat: 48.7773884, lng: 17.7163765 },
    { lat: 48.7773298, lng: 17.7162502 },
    { lat: 48.7771998, lng: 17.7159727 },
    { lat: 48.7771337, lng: 17.715835 },
    { lat: 48.7770499, lng: 17.7156666 },
    { lat: 48.7769382, lng: 17.7157785 },
    { lat: 48.7768641, lng: 17.7158581 },
    { lat: 48.7767887, lng: 17.7159334 },
    { lat: 48.7767234, lng: 17.7160108 },
    { lat: 48.7766369, lng: 17.716091 },
    { lat: 48.7766078, lng: 17.7161672 },
    { lat: 48.776525, lng: 17.7162707 },
    { lat: 48.7763679, lng: 17.716471 },
    { lat: 48.7762844, lng: 17.716582 },
    { lat: 48.7761981, lng: 17.7163905 },
    { lat: 48.7761302, lng: 17.7162411 },
    { lat: 48.776038, lng: 17.7160474 },
    { lat: 48.7759472, lng: 17.7161299 },
    { lat: 48.7758553, lng: 17.716215 },
    { lat: 48.7757638, lng: 17.7163048 },
    { lat: 48.7756742, lng: 17.7163896 },
    { lat: 48.7755696, lng: 17.7164867 },
    { lat: 48.7753716, lng: 17.7166835 },
    { lat: 48.7752982, lng: 17.7167749 },
    { lat: 48.7752515, lng: 17.7168225 },
    { lat: 48.7751259, lng: 17.7168895 },
    { lat: 48.7749875, lng: 17.7169731 },
    { lat: 48.7749881, lng: 17.7169989 },
    { lat: 48.7750471, lng: 17.7172059 },
    { lat: 48.7750829, lng: 17.7173482 },
    { lat: 48.7751525, lng: 17.7175592 },
    { lat: 48.7752132, lng: 17.717721 },
    { lat: 48.7752045, lng: 17.7178729 },
    { lat: 48.7751922, lng: 17.7179473 },
    { lat: 48.7751653, lng: 17.7179866 },
    { lat: 48.7751393, lng: 17.7180047 },
    { lat: 48.7750259, lng: 17.7180515 },
    { lat: 48.7749564, lng: 17.7180771 },
    { lat: 48.7748908, lng: 17.7181137 },
    { lat: 48.7747955, lng: 17.7181596 },
    { lat: 48.7747242, lng: 17.7181949 },
    { lat: 48.7746278, lng: 17.7183327 },
    { lat: 48.7746278, lng: 17.7183894 },
    { lat: 48.7746013, lng: 17.7184718 },
    { lat: 48.7745818, lng: 17.7185413 },
    { lat: 48.7745162, lng: 17.7186846 },
    { lat: 48.7744563, lng: 17.7188113 },
    { lat: 48.7743583, lng: 17.718999 },
    { lat: 48.7743069, lng: 17.7190989 },
    { lat: 48.7742599, lng: 17.7191965 },
    { lat: 48.7742018, lng: 17.7192969 },
    { lat: 48.7741581, lng: 17.7193775 },
    { lat: 48.774111, lng: 17.7194557 },
    { lat: 48.7739999, lng: 17.7195761 },
    { lat: 48.7739042, lng: 17.7196361 },
    { lat: 48.773832, lng: 17.7197279 },
    { lat: 48.773744, lng: 17.7197826 },
    { lat: 48.7736974, lng: 17.7198515 },
    { lat: 48.7736369, lng: 17.7198976 },
    { lat: 48.7735748, lng: 17.7199346 },
    { lat: 48.7735073, lng: 17.7199928 },
    { lat: 48.7734431, lng: 17.7200509 },
    { lat: 48.773413, lng: 17.7201287 },
    { lat: 48.7733513, lng: 17.7202271 },
    { lat: 48.7732417, lng: 17.7203159 },
    { lat: 48.773162, lng: 17.7202708 },
    { lat: 48.7730832, lng: 17.7202379 },
    { lat: 48.7729801, lng: 17.7199933 },
    { lat: 48.7729452, lng: 17.7198488 },
    { lat: 48.7728742, lng: 17.719685 },
    { lat: 48.772801, lng: 17.719505 },
    { lat: 48.7727167, lng: 17.7193468 },
    { lat: 48.7726546, lng: 17.7192181 },
    { lat: 48.7725817, lng: 17.7190806 },
    { lat: 48.772519, lng: 17.7189425 },
    { lat: 48.7723994, lng: 17.718687 },
    { lat: 48.7723473, lng: 17.7185743 },
    { lat: 48.772282, lng: 17.7184315 },
    { lat: 48.7722289, lng: 17.7183023 },
    { lat: 48.7721564, lng: 17.7181341 },
    { lat: 48.7720949, lng: 17.7179959 },
    { lat: 48.7720514, lng: 17.7178945 },
    { lat: 48.7719993, lng: 17.7177651 },
    { lat: 48.7719434, lng: 17.7176287 },
    { lat: 48.7718769, lng: 17.7174647 },
    { lat: 48.7718058, lng: 17.7172634 },
    { lat: 48.7717851, lng: 17.7171485 },
    { lat: 48.7717136, lng: 17.7169779 },
    { lat: 48.7716854, lng: 17.7169038 },
    { lat: 48.7716438, lng: 17.7169254 },
    { lat: 48.7715277, lng: 17.7169841 },
    { lat: 48.7714215, lng: 17.7170379 },
    { lat: 48.7713174, lng: 17.7170889 },
    { lat: 48.7712321, lng: 17.7171343 },
    { lat: 48.771108, lng: 17.717189 },
    { lat: 48.7710539, lng: 17.7173742 },
    { lat: 48.7710126, lng: 17.7175112 },
    { lat: 48.7709557, lng: 17.7177037 },
    { lat: 48.7709014, lng: 17.7178488 },
    { lat: 48.7708429, lng: 17.7179988 },
    { lat: 48.770771, lng: 17.7181876 },
    { lat: 48.770704, lng: 17.7183644 },
    { lat: 48.7706452, lng: 17.7185098 },
    { lat: 48.7705903, lng: 17.7186618 },
    { lat: 48.7705234, lng: 17.718824 },
    { lat: 48.7704758, lng: 17.7189474 },
    { lat: 48.7703909, lng: 17.719 },
    { lat: 48.770279, lng: 17.7190704 },
    { lat: 48.7702303, lng: 17.7191038 },
    { lat: 48.770209, lng: 17.7190889 },
    { lat: 48.770133, lng: 17.7192115 },
    { lat: 48.7700953, lng: 17.7192774 },
    { lat: 48.770031, lng: 17.7194064 },
    { lat: 48.7699703, lng: 17.7195567 },
    { lat: 48.7699034, lng: 17.7196977 },
    { lat: 48.7697891, lng: 17.719915 },
    { lat: 48.7697125, lng: 17.7200262 },
    { lat: 48.7696439, lng: 17.7201055 },
    { lat: 48.7695644, lng: 17.7202067 },
    { lat: 48.7695308, lng: 17.7204865 },
    { lat: 48.7692189, lng: 17.7209579 },
    { lat: 48.7686137, lng: 17.7217441 },
    { lat: 48.7679097, lng: 17.7226687 },
    { lat: 48.7680928, lng: 17.7230391 },
    { lat: 48.7683442, lng: 17.7235267 },
    { lat: 48.7686008, lng: 17.7241913 },
    { lat: 48.7688732, lng: 17.7250559 },
    { lat: 48.7685201, lng: 17.7253363 },
    { lat: 48.7684105, lng: 17.7254448 },
    { lat: 48.768721, lng: 17.7261983 },
    { lat: 48.7685629, lng: 17.726399 },
    { lat: 48.7685255, lng: 17.7264329 },
    { lat: 48.7686004, lng: 17.7266213 },
    { lat: 48.7689529, lng: 17.7276327 },
    { lat: 48.7690325, lng: 17.7280284 },
    { lat: 48.7690752, lng: 17.7281886 },
    { lat: 48.7691327, lng: 17.7284089 },
    { lat: 48.7690647, lng: 17.7284594 },
    { lat: 48.7689399, lng: 17.728542 },
    { lat: 48.7688148, lng: 17.7286894 },
    { lat: 48.7687072, lng: 17.7287772 },
    { lat: 48.7684772, lng: 17.7289646 },
    { lat: 48.7684031, lng: 17.7289908 },
    { lat: 48.7682926, lng: 17.7291212 },
    { lat: 48.7679288, lng: 17.7294658 },
    { lat: 48.7677026, lng: 17.7297718 },
    { lat: 48.7677172, lng: 17.7297869 },
    { lat: 48.7677135, lng: 17.7298562 },
    { lat: 48.7676962, lng: 17.7302364 },
    { lat: 48.767689, lng: 17.7303741 },
    { lat: 48.76766, lng: 17.7310042 },
    { lat: 48.7677302, lng: 17.7317222 },
    { lat: 48.7678062, lng: 17.7324259 },
    { lat: 48.7679943, lng: 17.7340509 },
    { lat: 48.7683249, lng: 17.7346897 },
    { lat: 48.7683295, lng: 17.7347646 },
    { lat: 48.7683618, lng: 17.7350689 },
    { lat: 48.7686986, lng: 17.7353323 },
    { lat: 48.7690599, lng: 17.7356619 },
    { lat: 48.7694096, lng: 17.7358773 },
    { lat: 48.7697614, lng: 17.7361275 },
    { lat: 48.7700495, lng: 17.7363134 },
    { lat: 48.7702523, lng: 17.7364897 },
    { lat: 48.7705817, lng: 17.7367059 },
    { lat: 48.7708285, lng: 17.736936 },
    { lat: 48.7711681, lng: 17.7372828 },
    { lat: 48.7712799, lng: 17.7374283 },
    { lat: 48.7715985, lng: 17.7370829 },
    { lat: 48.7716199, lng: 17.7371746 },
    { lat: 48.7716543, lng: 17.7372936 },
    { lat: 48.7717297, lng: 17.7374915 },
    { lat: 48.7729024, lng: 17.7366964 },
    { lat: 48.7730819, lng: 17.737193 },
    { lat: 48.7733867, lng: 17.7369711 },
    { lat: 48.7736657, lng: 17.7372851 },
    { lat: 48.7737621, lng: 17.7375849 },
    { lat: 48.7739548, lng: 17.7377448 },
    { lat: 48.7739918, lng: 17.7379063 },
    { lat: 48.7739717, lng: 17.7380204 },
    { lat: 48.7741366, lng: 17.7383145 },
    { lat: 48.7744561, lng: 17.7391583 },
    { lat: 48.7745417, lng: 17.739358 },
    { lat: 48.7746081, lng: 17.7396296 },
    { lat: 48.7746226, lng: 17.7396993 },
    { lat: 48.7746888, lng: 17.7398398 },
    { lat: 48.7746775, lng: 17.7400068 },
    { lat: 48.7746904, lng: 17.7403812 },
    { lat: 48.7747607, lng: 17.7404588 },
    { lat: 48.7747832, lng: 17.7406063 },
    { lat: 48.7748007, lng: 17.7407973 },
    { lat: 48.7748369, lng: 17.7409089 },
    { lat: 48.7750113, lng: 17.7406243 },
    { lat: 48.7751378, lng: 17.740349 },
    { lat: 48.7752463, lng: 17.7401501 },
    { lat: 48.7753503, lng: 17.7399667 },
    { lat: 48.7758387, lng: 17.7392823 },
    { lat: 48.7758667, lng: 17.7393508 },
    { lat: 48.7759121, lng: 17.7394661 },
    { lat: 48.7761594, lng: 17.7390803 },
    { lat: 48.7762243, lng: 17.738979 },
    { lat: 48.7766162, lng: 17.7384473 },
    { lat: 48.7768601, lng: 17.7381924 },
    { lat: 48.777171, lng: 17.7387303 },
    { lat: 48.7772505, lng: 17.7388115 },
    { lat: 48.77749, lng: 17.7392927 },
    { lat: 48.7776149, lng: 17.7394858 },
    { lat: 48.7776976, lng: 17.7396748 },
    { lat: 48.7778682, lng: 17.7399518 },
    { lat: 48.7780538, lng: 17.7402892 },
    { lat: 48.7780862, lng: 17.7403586 },
    { lat: 48.7781306, lng: 17.7404556 },
    { lat: 48.77815, lng: 17.7404951 },
    { lat: 48.7781513, lng: 17.7405356 },
    { lat: 48.7784792, lng: 17.7412055 },
    { lat: 48.778505, lng: 17.7412579 },
    { lat: 48.7785994, lng: 17.7412502 },
    { lat: 48.7786132, lng: 17.7412726 },
    { lat: 48.7786471, lng: 17.7413272 },
    { lat: 48.7790361, lng: 17.7419254 },
    { lat: 48.7790094, lng: 17.7419689 },
    { lat: 48.7789918, lng: 17.742012 },
    { lat: 48.779199, lng: 17.742316 },
    { lat: 48.7793317, lng: 17.7425463 },
    { lat: 48.7793892, lng: 17.7426556 },
    { lat: 48.7796805, lng: 17.7431881 },
    { lat: 48.7797288, lng: 17.7431119 },
    { lat: 48.7797475, lng: 17.7431391 },
    { lat: 48.7797757, lng: 17.7431769 },
    { lat: 48.7799396, lng: 17.7434191 },
    { lat: 48.7801329, lng: 17.7436605 },
    { lat: 48.7804485, lng: 17.7440949 },
    { lat: 48.7803416, lng: 17.7443008 },
    { lat: 48.7803074, lng: 17.7443693 },
    { lat: 48.7802913, lng: 17.7444024 },
    { lat: 48.7802724, lng: 17.744443 },
    { lat: 48.7802033, lng: 17.7445676 },
    { lat: 48.7801767, lng: 17.7446311 },
    { lat: 48.7801485, lng: 17.7446872 },
    { lat: 48.7800976, lng: 17.7448139 },
    { lat: 48.7800737, lng: 17.7448647 },
    { lat: 48.7800442, lng: 17.7449381 },
    { lat: 48.7800136, lng: 17.745009 },
    { lat: 48.779988, lng: 17.7450697 },
    { lat: 48.7799301, lng: 17.7452121 },
    { lat: 48.7798973, lng: 17.7452854 },
    { lat: 48.7798616, lng: 17.7453644 },
    { lat: 48.7798331, lng: 17.7454328 },
    { lat: 48.7797969, lng: 17.7455063 },
    { lat: 48.7797291, lng: 17.7456336 },
    { lat: 48.7796643, lng: 17.7457555 },
    { lat: 48.7797227, lng: 17.7458246 },
    { lat: 48.7798144, lng: 17.7459207 },
    { lat: 48.7799093, lng: 17.7460307 },
    { lat: 48.7802381, lng: 17.7464571 },
    { lat: 48.7803389, lng: 17.7465905 },
    { lat: 48.7803894, lng: 17.7468075 },
    { lat: 48.7804272, lng: 17.7469999 },
    { lat: 48.7804695, lng: 17.7471921 },
    { lat: 48.7804906, lng: 17.7472797 },
    { lat: 48.7805062, lng: 17.7473672 },
    { lat: 48.7805238, lng: 17.7474519 },
    { lat: 48.7805445, lng: 17.7475498 },
    { lat: 48.7805674, lng: 17.7476469 },
    { lat: 48.7805882, lng: 17.7477469 },
    { lat: 48.7807554, lng: 17.7479511 },
    { lat: 48.7810958, lng: 17.7483268 },
    { lat: 48.7811814, lng: 17.7484527 },
    { lat: 48.7814934, lng: 17.7490124 },
    { lat: 48.7817489, lng: 17.749403 },
    { lat: 48.7822357, lng: 17.7500313 },
    { lat: 48.782481, lng: 17.7503687 },
    { lat: 48.7826018, lng: 17.7505317 },
    { lat: 48.7826404, lng: 17.7505912 },
    { lat: 48.7826862, lng: 17.7506397 },
    { lat: 48.7830037, lng: 17.7511017 },
    { lat: 48.7831879, lng: 17.7515102 },
    { lat: 48.7833244, lng: 17.7512873 },
    { lat: 48.7833675, lng: 17.7512915 },
    { lat: 48.783417, lng: 17.7513055 },
    { lat: 48.7835136, lng: 17.7497346 },
    { lat: 48.7835756, lng: 17.7497336 },
    { lat: 48.7836387, lng: 17.7497124 },
    { lat: 48.7845558, lng: 17.7487801 },
    { lat: 48.7845818, lng: 17.748855 },
    { lat: 48.7848116, lng: 17.7486809 },
    { lat: 48.7849814, lng: 17.7485779 },
    { lat: 48.7852038, lng: 17.7483938 },
    { lat: 48.7853629, lng: 17.7481908 },
    { lat: 48.7861359, lng: 17.7478523 },
    { lat: 48.7861598, lng: 17.7480227 },
    { lat: 48.7861983, lng: 17.748298 },
    { lat: 48.7862599, lng: 17.748678 },
    { lat: 48.7862906, lng: 17.7488829 },
    { lat: 48.786543, lng: 17.7487087 },
    { lat: 48.7865879, lng: 17.7486678 },
    { lat: 48.7868667, lng: 17.748538 },
    { lat: 48.7871144, lng: 17.7483636 },
    { lat: 48.7872777, lng: 17.7483009 },
    { lat: 48.7876278, lng: 17.7480995 },
    { lat: 48.7879179, lng: 17.7479148 },
    { lat: 48.7879563, lng: 17.7480995 },
    { lat: 48.7879828, lng: 17.7482196 },
    { lat: 48.788031, lng: 17.7484341 },
    { lat: 48.7880415, lng: 17.7486043 },
    { lat: 48.7880456, lng: 17.7487446 },
    { lat: 48.7880501, lng: 17.7488552 },
    { lat: 48.7880565, lng: 17.7489769 },
    { lat: 48.7880592, lng: 17.7490209 },
    { lat: 48.7880608, lng: 17.7490655 },
    { lat: 48.7880626, lng: 17.7491139 },
    { lat: 48.7881566, lng: 17.7490636 },
    { lat: 48.7882236, lng: 17.7490148 },
    { lat: 48.7882695, lng: 17.7489532 },
    { lat: 48.7883335, lng: 17.7488366 },
    { lat: 48.7883783, lng: 17.7487381 },
    { lat: 48.7884056, lng: 17.7486872 },
    { lat: 48.7884905, lng: 17.7486173 },
    { lat: 48.7886739, lng: 17.7485145 },
    { lat: 48.7886822, lng: 17.7484477 },
    { lat: 48.7886853, lng: 17.748425 },
    { lat: 48.7886867, lng: 17.7482821 },
    { lat: 48.788697, lng: 17.748138 },
    { lat: 48.7887886, lng: 17.7476792 },
    { lat: 48.7889627, lng: 17.7471687 },
    { lat: 48.7890009, lng: 17.7467791 },
    { lat: 48.7890034, lng: 17.7465999 },
    { lat: 48.789001, lng: 17.7465251 },
    { lat: 48.7891803, lng: 17.7466305 },
    { lat: 48.7892494, lng: 17.7466704 },
    { lat: 48.7893278, lng: 17.7467165 },
    { lat: 48.7896542, lng: 17.7469193 },
    { lat: 48.7897477, lng: 17.7469732 },
    { lat: 48.7900667, lng: 17.7461731 },
    { lat: 48.7902682, lng: 17.7462933 },
    { lat: 48.7907288, lng: 17.7465493 },
    { lat: 48.7913129, lng: 17.7467748 },
    { lat: 48.7912198, lng: 17.7472825 },
    { lat: 48.7917414, lng: 17.7480359 },
    { lat: 48.7920347, lng: 17.7484376 },
    { lat: 48.7920902, lng: 17.7485131 },
    { lat: 48.7920801, lng: 17.7485345 },
    { lat: 48.7920672, lng: 17.7485634 },
    { lat: 48.7920577, lng: 17.7485731 },
    { lat: 48.7921133, lng: 17.7486503 },
    { lat: 48.7921293, lng: 17.7486724 },
    { lat: 48.792143, lng: 17.748694 },
    { lat: 48.7922546, lng: 17.7486736 },
    { lat: 48.7924625, lng: 17.7487491 },
    { lat: 48.792538, lng: 17.7487287 },
    { lat: 48.792584, lng: 17.7487789 },
    { lat: 48.7927629, lng: 17.7488238 },
    { lat: 48.7927961, lng: 17.7489598 },
    { lat: 48.7930293, lng: 17.7489897 },
    { lat: 48.7931526, lng: 17.7490852 },
    { lat: 48.7932595, lng: 17.7490048 },
    { lat: 48.7935432, lng: 17.7487535 },
    { lat: 48.7938853, lng: 17.7484015 },
    { lat: 48.7942481, lng: 17.7479495 },
    { lat: 48.7943238, lng: 17.7478389 },
    { lat: 48.7943232, lng: 17.7477937 },
    { lat: 48.7945111, lng: 17.7475626 },
    { lat: 48.7946562, lng: 17.7474067 },
    { lat: 48.7949066, lng: 17.74709 },
    { lat: 48.7955712, lng: 17.7463117 },
    { lat: 48.7956474, lng: 17.7464317 },
    { lat: 48.7956833, lng: 17.7464821 },
    { lat: 48.7963188, lng: 17.7455426 },
    { lat: 48.7974389, lng: 17.7438547 },
    { lat: 48.7977848, lng: 17.7433822 },
    { lat: 48.7981376, lng: 17.7428951 },
    { lat: 48.7984241, lng: 17.7425232 },
    { lat: 48.7989309, lng: 17.7419152 },
    { lat: 48.7991723, lng: 17.741654 },
    { lat: 48.7993518, lng: 17.7414339 },
    { lat: 48.7993621, lng: 17.7414174 },
    { lat: 48.7995585, lng: 17.7417106 },
    { lat: 48.79972, lng: 17.741932 },
    { lat: 48.79982, lng: 17.7419978 },
    { lat: 48.7999353, lng: 17.7420721 },
    { lat: 48.8004174, lng: 17.7411924 },
    { lat: 48.8005687, lng: 17.7409161 },
    { lat: 48.8007081, lng: 17.7407029 },
    { lat: 48.8008234, lng: 17.7408722 },
    { lat: 48.8009423, lng: 17.7409883 },
    { lat: 48.8013287, lng: 17.740569 },
    { lat: 48.8014117, lng: 17.7405983 },
    { lat: 48.8015146, lng: 17.7407111 },
    { lat: 48.8016271, lng: 17.7405751 },
    { lat: 48.801886, lng: 17.7408382 },
    { lat: 48.8019809, lng: 17.7408945 },
    { lat: 48.8019906, lng: 17.7409231 },
    { lat: 48.8021067, lng: 17.7410683 },
    { lat: 48.8023347, lng: 17.7414147 },
    { lat: 48.8024162, lng: 17.7416212 },
    { lat: 48.8024498, lng: 17.7416911 },
    { lat: 48.8025583, lng: 17.7415811 },
    { lat: 48.8027695, lng: 17.7414001 },
    { lat: 48.8028978, lng: 17.7412094 },
    { lat: 48.8031149, lng: 17.7411417 },
    { lat: 48.8031742, lng: 17.7410397 },
    { lat: 48.803256, lng: 17.7410483 },
    { lat: 48.8033731, lng: 17.7409156 },
    { lat: 48.8035151, lng: 17.7408175 },
    { lat: 48.8036219, lng: 17.7406999 },
    { lat: 48.8037997, lng: 17.7406009 },
    { lat: 48.8039661, lng: 17.7404424 },
    { lat: 48.8040386, lng: 17.7404458 },
    { lat: 48.8044965, lng: 17.7401263 },
    { lat: 48.8046313, lng: 17.7400232 },
    { lat: 48.8048635, lng: 17.7398682 },
    { lat: 48.8051523, lng: 17.7397374 },
    { lat: 48.8053004, lng: 17.73974 },
    { lat: 48.805689, lng: 17.7396525 },
    { lat: 48.8057407, lng: 17.7396114 },
    { lat: 48.8057701, lng: 17.7396107 },
    { lat: 48.8058289, lng: 17.7395597 },
    { lat: 48.8059046, lng: 17.7395584 },
    { lat: 48.8061738, lng: 17.7394025 },
    { lat: 48.8062526, lng: 17.7393962 },
    { lat: 48.8064984, lng: 17.7392461 },
    { lat: 48.8090879, lng: 17.7379332 },
    { lat: 48.8092192, lng: 17.7378655 },
    { lat: 48.8092378, lng: 17.7378002 },
    { lat: 48.809748, lng: 17.7358514 },
    { lat: 48.8097676, lng: 17.7357854 },
    { lat: 48.8097833, lng: 17.7357101 },
    { lat: 48.8097704, lng: 17.7356849 },
    { lat: 48.8097666, lng: 17.7356598 },
    { lat: 48.8097628, lng: 17.7356347 },
    { lat: 48.8099746, lng: 17.7353699 },
    { lat: 48.8102103, lng: 17.7351998 },
    { lat: 48.8103642, lng: 17.7351321 },
    { lat: 48.8105216, lng: 17.7350487 },
    { lat: 48.8105994, lng: 17.7349369 },
    { lat: 48.8110589, lng: 17.7344414 },
    { lat: 48.8112874, lng: 17.7342263 },
    { lat: 48.812001, lng: 17.7334757 },
    { lat: 48.8123439, lng: 17.733158 },
    { lat: 48.8124357, lng: 17.7331263 },
    { lat: 48.8124488, lng: 17.733101 },
    { lat: 48.8125734, lng: 17.7326668 },
    { lat: 48.8126913, lng: 17.7321976 },
    { lat: 48.8127769, lng: 17.7317539 },
    { lat: 48.8128283, lng: 17.731306 },
    { lat: 48.8129383, lng: 17.7309824 },
    { lat: 48.8131167, lng: 17.7307636 },
    { lat: 48.8133748, lng: 17.7303878 },
    { lat: 48.8134335, lng: 17.7301304 },
    { lat: 48.81347, lng: 17.7298234 },
    { lat: 48.8135325, lng: 17.7296113 },
    { lat: 48.8136452, lng: 17.7293873 },
    { lat: 48.8138304, lng: 17.7291867 },
    { lat: 48.8141188, lng: 17.7288281 },
    { lat: 48.8147015, lng: 17.7283164 },
    { lat: 48.8147018, lng: 17.7283013 },
    { lat: 48.814815, lng: 17.7281225 },
    { lat: 48.8148368, lng: 17.7280365 },
    { lat: 48.8148816, lng: 17.7279401 },
    { lat: 48.814942, lng: 17.7275273 },
    { lat: 48.8153085, lng: 17.726549 },
    { lat: 48.815477, lng: 17.7262786 },
    { lat: 48.8156751, lng: 17.7257715 },
    { lat: 48.8157845, lng: 17.725568 },
    { lat: 48.8158391, lng: 17.7255013 },
    { lat: 48.816352, lng: 17.7257092 },
    { lat: 48.8164043, lng: 17.7257518 },
    { lat: 48.8170149, lng: 17.7252703 },
    { lat: 48.8171296, lng: 17.7250995 },
    { lat: 48.8171371, lng: 17.7250745 },
    { lat: 48.8173054, lng: 17.7249841 },
    { lat: 48.8174202, lng: 17.7249038 },
    { lat: 48.81749, lng: 17.7249192 },
    { lat: 48.8179311, lng: 17.7248949 },
    { lat: 48.8186795, lng: 17.7244541 },
    { lat: 48.8189956, lng: 17.7244702 },
    { lat: 48.8191538, lng: 17.7243448 },
    { lat: 48.8192787, lng: 17.72432 },
    { lat: 48.8192694, lng: 17.7241687 },
    { lat: 48.8192538, lng: 17.7240982 },
    { lat: 48.8192371, lng: 17.7240481 },
    { lat: 48.8192234, lng: 17.7240076 },
    { lat: 48.8191847, lng: 17.7239473 },
    { lat: 48.8191449, lng: 17.7239067 },
    { lat: 48.8194279, lng: 17.723827 },
    { lat: 48.8195998, lng: 17.7238125 },
    { lat: 48.8198501, lng: 17.7238435 },
    { lat: 48.8199518, lng: 17.7238637 },
    { lat: 48.8201196, lng: 17.723819 },
    { lat: 48.8201919, lng: 17.7240406 },
    { lat: 48.8205144, lng: 17.7242178 },
    { lat: 48.821031, lng: 17.7248881 },
    { lat: 48.8212313, lng: 17.7252613 },
    { lat: 48.821405, lng: 17.7254628 },
    { lat: 48.8214974, lng: 17.7254029 },
    { lat: 48.8218973, lng: 17.72473 },
    { lat: 48.8220557, lng: 17.7246798 },
    { lat: 48.8221415, lng: 17.7246048 },
    { lat: 48.8224427, lng: 17.7243995 },
    { lat: 48.822864, lng: 17.7240535 },
    { lat: 48.8229434, lng: 17.724006 },
    { lat: 48.8230205, lng: 17.7238455 },
    { lat: 48.8230545, lng: 17.7235915 },
    { lat: 48.8229936, lng: 17.7235377 },
    { lat: 48.8230131, lng: 17.7235042 },
    { lat: 48.8229596, lng: 17.7229098 },
    { lat: 48.8229434, lng: 17.7224834 },
    { lat: 48.8230493, lng: 17.7224249 },
    { lat: 48.8231517, lng: 17.7223642 },
    { lat: 48.8233583, lng: 17.7222542 },
    { lat: 48.823556, lng: 17.7221443 },
    { lat: 48.8236522, lng: 17.7220943 },
    { lat: 48.8237738, lng: 17.7220345 },
    { lat: 48.8237509, lng: 17.7216819 },
    { lat: 48.8238828, lng: 17.7215116 },
    { lat: 48.8240047, lng: 17.721346 },
    { lat: 48.8240778, lng: 17.7212506 },
    { lat: 48.8241568, lng: 17.7211404 },
    { lat: 48.8241091, lng: 17.7210074 },
    { lat: 48.8240459, lng: 17.7208611 },
    { lat: 48.8239865, lng: 17.7207395 },
    { lat: 48.8239595, lng: 17.7206673 },
    { lat: 48.8238959, lng: 17.7204984 },
    { lat: 48.8239038, lng: 17.7204609 },
    { lat: 48.8239331, lng: 17.7204226 },
    { lat: 48.8240726, lng: 17.7201943 },
    { lat: 48.8242059, lng: 17.7199215 },
    { lat: 48.8242099, lng: 17.719729 },
    { lat: 48.8244746, lng: 17.7194433 },
    { lat: 48.8246316, lng: 17.7193169 },
    { lat: 48.8247088, lng: 17.719269 },
    { lat: 48.8248015, lng: 17.7192337 },
    { lat: 48.8250725, lng: 17.7191245 },
    { lat: 48.8253813, lng: 17.7189519 },
    { lat: 48.8255836, lng: 17.7187889 },
    { lat: 48.825666, lng: 17.7187149 },
    { lat: 48.8256842, lng: 17.7186987 },
    { lat: 48.8256991, lng: 17.7186837 },
    { lat: 48.826068, lng: 17.7191186 },
    { lat: 48.8261288, lng: 17.7191899 },
    { lat: 48.8261875, lng: 17.7192636 },
    { lat: 48.8263236, lng: 17.719456 },
    { lat: 48.8266063, lng: 17.7197744 },
    { lat: 48.8267454, lng: 17.7198534 },
    { lat: 48.827178, lng: 17.7200403 },
    { lat: 48.8272254, lng: 17.7200558 },
    { lat: 48.8272933, lng: 17.7200777 },
    { lat: 48.8275133, lng: 17.7201117 },
    { lat: 48.8276775, lng: 17.7204129 },
    { lat: 48.827769, lng: 17.7205976 },
    { lat: 48.8278796, lng: 17.720706 },
    { lat: 48.8281104, lng: 17.7208425 },
    { lat: 48.8282932, lng: 17.720915 },
    { lat: 48.8283615, lng: 17.7209421 },
    { lat: 48.8286647, lng: 17.7210623 },
    { lat: 48.8292107, lng: 17.7212006 },
    { lat: 48.8295392, lng: 17.7210913 },
    { lat: 48.8297673, lng: 17.7210723 },
    { lat: 48.8300069, lng: 17.7209666 },
    { lat: 48.8301007, lng: 17.7208944 },
    { lat: 48.8301676, lng: 17.7208806 },
    { lat: 48.830543, lng: 17.7204882 },
    { lat: 48.8308065, lng: 17.7203295 },
    { lat: 48.8308648, lng: 17.7201951 },
    { lat: 48.8309914, lng: 17.7199764 },
    { lat: 48.8310586, lng: 17.7198571 },
    { lat: 48.8311886, lng: 17.7196402 },
    { lat: 48.8312577, lng: 17.7195159 },
    { lat: 48.8313307, lng: 17.7194013 },
    { lat: 48.831385, lng: 17.7193474 },
    { lat: 48.8314084, lng: 17.719324 },
    { lat: 48.8314061, lng: 17.7191584 },
    { lat: 48.8314065, lng: 17.7191281 },
    { lat: 48.8314651, lng: 17.7191445 },
    { lat: 48.8315433, lng: 17.7192032 },
    { lat: 48.8315839, lng: 17.7193128 },
    { lat: 48.8316629, lng: 17.7194219 },
    { lat: 48.8317289, lng: 17.7193929 },
    { lat: 48.8317901, lng: 17.7193616 },
    { lat: 48.8318312, lng: 17.7192955 },
    { lat: 48.8318704, lng: 17.7192541 },
    { lat: 48.8319791, lng: 17.7191138 },
    { lat: 48.8320455, lng: 17.7189616 },
    { lat: 48.8321112, lng: 17.7188194 },
    { lat: 48.8321678, lng: 17.7186926 },
    { lat: 48.8321942, lng: 17.7185362 },
    { lat: 48.8322057, lng: 17.7183927 },
    { lat: 48.8322608, lng: 17.7184241 },
    { lat: 48.8323016, lng: 17.7184286 },
    { lat: 48.8323548, lng: 17.7184297 },
    { lat: 48.8323861, lng: 17.7184446 },
    { lat: 48.8324145, lng: 17.7184459 },
    { lat: 48.8324799, lng: 17.7184274 },
    { lat: 48.8325864, lng: 17.7183973 },
    { lat: 48.8326954, lng: 17.7183874 },
    { lat: 48.8328202, lng: 17.718342 },
    { lat: 48.8328939, lng: 17.7183108 },
    { lat: 48.8329151, lng: 17.7183051 },
    { lat: 48.8329939, lng: 17.7181376 },
    { lat: 48.8330395, lng: 17.7180537 },
    { lat: 48.8331033, lng: 17.7179719 },
    { lat: 48.8332482, lng: 17.7178687 },
    { lat: 48.8333641, lng: 17.7178431 },
    { lat: 48.8335763, lng: 17.7176404 },
    { lat: 48.8337036, lng: 17.7175622 },
    { lat: 48.833754, lng: 17.7175008 },
    { lat: 48.833958, lng: 17.7174411 },
    { lat: 48.8339957, lng: 17.7174281 },
    { lat: 48.8340448, lng: 17.7174015 },
    { lat: 48.8341344, lng: 17.717299 },
    { lat: 48.834147, lng: 17.7173191 },
    { lat: 48.8341762, lng: 17.7173538 },
    { lat: 48.8345641, lng: 17.7178583 },
    { lat: 48.8349484, lng: 17.7174255 },
    { lat: 48.8353224, lng: 17.7171008 },
    { lat: 48.8354558, lng: 17.7170301 },
    { lat: 48.8357524, lng: 17.71675 },
    { lat: 48.8359606, lng: 17.7165192 },
    { lat: 48.8360855, lng: 17.7162553 },
    { lat: 48.8362479, lng: 17.7158496 },
    { lat: 48.8363366, lng: 17.7155489 },
    { lat: 48.8365196, lng: 17.7150548 },
    { lat: 48.8366565, lng: 17.7147127 },
    { lat: 48.8366697, lng: 17.7146321 },
    { lat: 48.8367227, lng: 17.7144121 },
    { lat: 48.8367406, lng: 17.7142257 },
    { lat: 48.8370458, lng: 17.7136464 },
    { lat: 48.8371589, lng: 17.7134302 },
    { lat: 48.8371867, lng: 17.7134225 },
    { lat: 48.8372946, lng: 17.7132867 },
    { lat: 48.837419, lng: 17.7130352 },
    { lat: 48.8374942, lng: 17.7128429 },
    { lat: 48.8376009, lng: 17.7125968 },
    { lat: 48.8376304, lng: 17.7125254 },
    { lat: 48.8378279, lng: 17.7121572 },
    { lat: 48.8379946, lng: 17.7116936 },
    { lat: 48.8381179, lng: 17.7114599 },
    { lat: 48.8381953, lng: 17.7113048 },
    { lat: 48.8382833, lng: 17.7110845 },
    { lat: 48.8383552, lng: 17.7109494 },
    { lat: 48.8383916, lng: 17.710881 },
    { lat: 48.8384263, lng: 17.7107845 },
    { lat: 48.8384938, lng: 17.7104519 },
    { lat: 48.8386287, lng: 17.7101851 },
    { lat: 48.8387201, lng: 17.7099469 },
    { lat: 48.8387862, lng: 17.7099204 },
    { lat: 48.838879, lng: 17.7098684 },
    { lat: 48.8390276, lng: 17.7096964 },
    { lat: 48.8390724, lng: 17.7095976 },
    { lat: 48.8391241, lng: 17.7093401 },
    { lat: 48.8392521, lng: 17.7091263 },
    { lat: 48.8392808, lng: 17.7090776 },
    { lat: 48.8393896, lng: 17.7088117 },
    { lat: 48.8394049, lng: 17.7086932 },
    { lat: 48.8395689, lng: 17.708479 },
    { lat: 48.8397494, lng: 17.7082387 },
    { lat: 48.8398025, lng: 17.7081097 },
    { lat: 48.8399668, lng: 17.7077717 },
    { lat: 48.8400427, lng: 17.7076293 },
    { lat: 48.8401261, lng: 17.7074793 },
    { lat: 48.8402271, lng: 17.7073791 },
    { lat: 48.8403026, lng: 17.707267 },
    { lat: 48.8403548, lng: 17.7071982 },
    { lat: 48.8404662, lng: 17.706972 },
    { lat: 48.8405287, lng: 17.7068151 },
    { lat: 48.8406449, lng: 17.7066666 },
    { lat: 48.8406808, lng: 17.7065529 },
    { lat: 48.8407492, lng: 17.7062147 },
    { lat: 48.8408941, lng: 17.7059128 },
    { lat: 48.8410071, lng: 17.7056188 },
    { lat: 48.8410439, lng: 17.7055222 },
    { lat: 48.8413083, lng: 17.7055595 },
    { lat: 48.8414176, lng: 17.7056125 },
    { lat: 48.8415057, lng: 17.705686 },
    { lat: 48.8417372, lng: 17.7058341 },
    { lat: 48.8420358, lng: 17.7059705 },
    { lat: 48.8422893, lng: 17.7060154 },
    { lat: 48.8425249, lng: 17.7060829 },
    { lat: 48.8427027, lng: 17.7060565 },
    { lat: 48.8427913, lng: 17.7060269 },
    { lat: 48.8430315, lng: 17.7058936 },
    { lat: 48.8432954, lng: 17.7057446 },
    { lat: 48.8433844, lng: 17.705685 },
    { lat: 48.8435949, lng: 17.7055108 },
    { lat: 48.8437206, lng: 17.7054249 },
    { lat: 48.8438586, lng: 17.705301 },
    { lat: 48.8440637, lng: 17.7050931 },
    { lat: 48.8442838, lng: 17.7047267 },
    { lat: 48.844581, lng: 17.7042552 },
    { lat: 48.8446061, lng: 17.7042047 },
    { lat: 48.8447236, lng: 17.7039862 },
    { lat: 48.8448437, lng: 17.7037378 },
    { lat: 48.8448837, lng: 17.7035811 },
    { lat: 48.8449491, lng: 17.703341 },
    { lat: 48.8450051, lng: 17.7030965 },
    { lat: 48.8450204, lng: 17.7030708 },
    { lat: 48.8451465, lng: 17.702716 },
    { lat: 48.8451865, lng: 17.7025949 },
    { lat: 48.845163, lng: 17.7025801 },
    { lat: 48.8452155, lng: 17.7023709 },
    { lat: 48.8452414, lng: 17.7023523 },
    { lat: 48.8452573, lng: 17.702297 },
    { lat: 48.845254, lng: 17.7021166 },
    { lat: 48.8453952, lng: 17.7016236 },
    { lat: 48.845496, lng: 17.7014274 },
    { lat: 48.8455644, lng: 17.7012548 },
    { lat: 48.8455501, lng: 17.7010952 },
    { lat: 48.8456117, lng: 17.7008325 },
    { lat: 48.8456516, lng: 17.700711 },
    { lat: 48.8457254, lng: 17.700571 },
    { lat: 48.84578, lng: 17.7004315 },
    { lat: 48.8457802, lng: 17.7001782 },
    { lat: 48.8458308, lng: 17.699994 },
    { lat: 48.8459709, lng: 17.6997741 },
    { lat: 48.8459879, lng: 17.6996656 },
    { lat: 48.8461714, lng: 17.6995121 },
    { lat: 48.8462449, lng: 17.6992567 },
    { lat: 48.846258, lng: 17.699286 },
    { lat: 48.8463954, lng: 17.6992088 },
    { lat: 48.8464307, lng: 17.6991755 },
    { lat: 48.8464747, lng: 17.6990838 },
    { lat: 48.8466039, lng: 17.698964 },
    { lat: 48.8466763, lng: 17.6989491 },
    { lat: 48.8467298, lng: 17.6989551 },
    { lat: 48.8468347, lng: 17.6988065 },
    { lat: 48.8468774, lng: 17.6988048 },
    { lat: 48.8470104, lng: 17.6986178 },
    { lat: 48.8471849, lng: 17.6984441 },
    { lat: 48.8473618, lng: 17.6984384 },
    { lat: 48.847418, lng: 17.6983992 },
    { lat: 48.8475081, lng: 17.6984312 },
    { lat: 48.8476335, lng: 17.6984323 },
    { lat: 48.8478275, lng: 17.698303 },
    { lat: 48.8480581, lng: 17.6981228 },
    { lat: 48.8481484, lng: 17.6981047 },
    { lat: 48.8482172, lng: 17.6980677 },
    { lat: 48.8482785, lng: 17.6979979 },
    { lat: 48.8483632, lng: 17.6980178 },
    { lat: 48.8483939, lng: 17.6980569 },
    { lat: 48.8485549, lng: 17.6979767 },
    { lat: 48.848645, lng: 17.6979913 },
    { lat: 48.8487472, lng: 17.6979827 },
    { lat: 48.8488081, lng: 17.6979634 },
    { lat: 48.8488837, lng: 17.6979082 },
    { lat: 48.8491066, lng: 17.6977886 },
    { lat: 48.8491599, lng: 17.6976993 },
    { lat: 48.8492632, lng: 17.6975977 },
    { lat: 48.8494188, lng: 17.6974127 },
    { lat: 48.8496331, lng: 17.6974081 },
    { lat: 48.8496758, lng: 17.697387 },
    { lat: 48.8498703, lng: 17.6971375 },
    { lat: 48.8499542, lng: 17.6970874 },
    { lat: 48.8499953, lng: 17.6970936 },
    { lat: 48.8501163, lng: 17.6970243 },
    { lat: 48.8501377, lng: 17.6969512 },
    { lat: 48.8502502, lng: 17.6967624 },
    { lat: 48.8503862, lng: 17.6967327 },
    { lat: 48.8504281, lng: 17.6966438 },
    { lat: 48.8506565, lng: 17.6963382 },
    { lat: 48.8507375, lng: 17.696278 },
    { lat: 48.8508092, lng: 17.6962882 },
    { lat: 48.8509138, lng: 17.6963551 },
    { lat: 48.8509604, lng: 17.6963587 },
    { lat: 48.8511582, lng: 17.696072 },
    { lat: 48.8511586, lng: 17.6960967 },
    { lat: 48.8511318, lng: 17.6961378 },
    { lat: 48.8511637, lng: 17.6961619 },
    { lat: 48.8578545, lng: 17.7012447 },
    { lat: 48.8578806, lng: 17.701264 },
    { lat: 48.8592922, lng: 17.7023301 },
    { lat: 48.8598966, lng: 17.7028108 },
    { lat: 48.860115, lng: 17.7029848 },
  ],
  BzincepodJavorinou: [
    { lat: 48.8126548, lng: 17.7673588 },
    { lat: 48.812698, lng: 17.7674916 },
    { lat: 48.8127419, lng: 17.7676001 },
    { lat: 48.8127754, lng: 17.7676867 },
    { lat: 48.812806, lng: 17.7677807 },
    { lat: 48.8128493, lng: 17.7678991 },
    { lat: 48.8128944, lng: 17.7680073 },
    { lat: 48.8129389, lng: 17.7681253 },
    { lat: 48.8129908, lng: 17.7682552 },
    { lat: 48.8130235, lng: 17.7683456 },
    { lat: 48.8130709, lng: 17.7684582 },
    { lat: 48.8131181, lng: 17.7685811 },
    { lat: 48.8131593, lng: 17.7686845 },
    { lat: 48.8132022, lng: 17.7687948 },
    { lat: 48.8132503, lng: 17.7689155 },
    { lat: 48.8132979, lng: 17.7690301 },
    { lat: 48.8133426, lng: 17.7691333 },
    { lat: 48.8133919, lng: 17.7692392 },
    { lat: 48.8134555, lng: 17.7693725 },
    { lat: 48.8135138, lng: 17.7694968 },
    { lat: 48.8135778, lng: 17.7696383 },
    { lat: 48.8136481, lng: 17.7697918 },
    { lat: 48.8137044, lng: 17.7699163 },
    { lat: 48.8137577, lng: 17.7700315 },
    { lat: 48.8138031, lng: 17.7703118 },
    { lat: 48.8137886, lng: 17.7704452 },
    { lat: 48.8137817, lng: 17.7705346 },
    { lat: 48.8137968, lng: 17.7705982 },
    { lat: 48.8138419, lng: 17.770706 },
    { lat: 48.8138866, lng: 17.7708113 },
    { lat: 48.8139277, lng: 17.7709103 },
    { lat: 48.8139658, lng: 17.7709981 },
    { lat: 48.8139926, lng: 17.7710662 },
    { lat: 48.8140621, lng: 17.7710034 },
    { lat: 48.8141132, lng: 17.7709534 },
    { lat: 48.8141856, lng: 17.7709556 },
    { lat: 48.8142626, lng: 17.770959 },
    { lat: 48.8143409, lng: 17.7709656 },
    { lat: 48.814444, lng: 17.7709715 },
    { lat: 48.8145414, lng: 17.7709772 },
    { lat: 48.8146196, lng: 17.7709802 },
    { lat: 48.8146807, lng: 17.7709828 },
    { lat: 48.8147158, lng: 17.7709843 },
    { lat: 48.8147686, lng: 17.7710731 },
    { lat: 48.8148512, lng: 17.7712055 },
    { lat: 48.8149001, lng: 17.7712849 },
    { lat: 48.8149626, lng: 17.771384 },
    { lat: 48.8150325, lng: 17.7714921 },
    { lat: 48.8151048, lng: 17.7716043 },
    { lat: 48.81518, lng: 17.7717261 },
    { lat: 48.8152564, lng: 17.7718492 },
    { lat: 48.8152767, lng: 17.7718855 },
    { lat: 48.815339, lng: 17.7719452 },
    { lat: 48.8153983, lng: 17.7720105 },
    { lat: 48.8154441, lng: 17.7720591 },
    { lat: 48.8154734, lng: 17.7720928 },
    { lat: 48.8155338, lng: 17.7720838 },
    { lat: 48.8156029, lng: 17.7720691 },
    { lat: 48.8156632, lng: 17.7720585 },
    { lat: 48.815748, lng: 17.7720426 },
    { lat: 48.8158283, lng: 17.7720258 },
    { lat: 48.8158574, lng: 17.7721509 },
    { lat: 48.815884, lng: 17.7722731 },
    { lat: 48.8159058, lng: 17.7723898 },
    { lat: 48.8159371, lng: 17.7725328 },
    { lat: 48.8159794, lng: 17.7727252 },
    { lat: 48.8160087, lng: 17.7728715 },
    { lat: 48.816037, lng: 17.7730035 },
    { lat: 48.8160615, lng: 17.7731247 },
    { lat: 48.8160838, lng: 17.7732495 },
    { lat: 48.8161121, lng: 17.7733994 },
    { lat: 48.8161748, lng: 17.773723 },
    { lat: 48.8161852, lng: 17.7737823 },
    { lat: 48.8162484, lng: 17.7736909 },
    { lat: 48.8163173, lng: 17.7735988 },
    { lat: 48.8163662, lng: 17.7735291 },
    { lat: 48.8164389, lng: 17.7734267 },
    { lat: 48.8165034, lng: 17.7733381 },
    { lat: 48.8165536, lng: 17.7732703 },
    { lat: 48.8166078, lng: 17.7731983 },
    { lat: 48.8166518, lng: 17.7731246 },
    { lat: 48.8167655, lng: 17.7729326 },
    { lat: 48.816817, lng: 17.7728526 },
    { lat: 48.8168734, lng: 17.7727593 },
    { lat: 48.8169266, lng: 17.7726692 },
    { lat: 48.8169796, lng: 17.7725776 },
    { lat: 48.8170467, lng: 17.7724726 },
    { lat: 48.8170966, lng: 17.7723835 },
    { lat: 48.8171502, lng: 17.7722641 },
    { lat: 48.8172166, lng: 17.7721133 },
    { lat: 48.8172655, lng: 17.7720077 },
    { lat: 48.8173577, lng: 17.771799 },
    { lat: 48.8174008, lng: 17.7717104 },
    { lat: 48.8174412, lng: 17.7716143 },
    { lat: 48.8174807, lng: 17.7715219 },
    { lat: 48.8175108, lng: 17.7714612 },
    { lat: 48.8175756, lng: 17.7714124 },
    { lat: 48.8176452, lng: 17.771368 },
    { lat: 48.817765, lng: 17.7712966 },
    { lat: 48.8178005, lng: 17.771268 },
    { lat: 48.817835, lng: 17.7712415 },
    { lat: 48.8179386, lng: 17.7711632 },
    { lat: 48.8179938, lng: 17.7711252 },
    { lat: 48.8180479, lng: 17.7710871 },
    { lat: 48.818083, lng: 17.7710519 },
    { lat: 48.818146, lng: 17.7709931 },
    { lat: 48.8182591, lng: 17.7708841 },
    { lat: 48.8183171, lng: 17.7708341 },
    { lat: 48.818396, lng: 17.7707592 },
    { lat: 48.8184844, lng: 17.7706734 },
    { lat: 48.8185497, lng: 17.7706143 },
    { lat: 48.8185978, lng: 17.770569 },
    { lat: 48.8186287, lng: 17.7705398 },
    { lat: 48.8187204, lng: 17.7704698 },
    { lat: 48.8187808, lng: 17.7704246 },
    { lat: 48.8188456, lng: 17.770377 },
    { lat: 48.818894, lng: 17.7703561 },
    { lat: 48.8189403, lng: 17.7703376 },
    { lat: 48.8190088, lng: 17.7703125 },
    { lat: 48.8191467, lng: 17.7702599 },
    { lat: 48.8192183, lng: 17.770231 },
    { lat: 48.8192855, lng: 17.7702041 },
    { lat: 48.8193638, lng: 17.7701727 },
    { lat: 48.819431, lng: 17.7701457 },
    { lat: 48.8194949, lng: 17.7701213 },
    { lat: 48.81957, lng: 17.7700916 },
    { lat: 48.819644, lng: 17.7700492 },
    { lat: 48.8197231, lng: 17.7700107 },
    { lat: 48.8197956, lng: 17.7699783 },
    { lat: 48.8199475, lng: 17.7699142 },
    { lat: 48.8200289, lng: 17.7698802 },
    { lat: 48.820118, lng: 17.7698421 },
    { lat: 48.8201494, lng: 17.7698205 },
    { lat: 48.8202311, lng: 17.7697701 },
    { lat: 48.8202995, lng: 17.7697269 },
    { lat: 48.8203605, lng: 17.7696913 },
    { lat: 48.8204268, lng: 17.7696483 },
    { lat: 48.8204987, lng: 17.7696044 },
    { lat: 48.8205601, lng: 17.7695574 },
    { lat: 48.8206346, lng: 17.7695016 },
    { lat: 48.8207056, lng: 17.7694446 },
    { lat: 48.8207853, lng: 17.7693818 },
    { lat: 48.8208574, lng: 17.769323 },
    { lat: 48.8209394, lng: 17.7692627 },
    { lat: 48.8210202, lng: 17.7691978 },
    { lat: 48.8210924, lng: 17.7691408 },
    { lat: 48.8211603, lng: 17.7690893 },
    { lat: 48.821236, lng: 17.7690346 },
    { lat: 48.821304, lng: 17.7690393 },
    { lat: 48.8214086, lng: 17.7690135 },
    { lat: 48.8214654, lng: 17.769003 },
    { lat: 48.8215067, lng: 17.7689933 },
    { lat: 48.8215547, lng: 17.7689844 },
    { lat: 48.8215947, lng: 17.7689732 },
    { lat: 48.8216582, lng: 17.76896 },
    { lat: 48.8217241, lng: 17.7689486 },
    { lat: 48.8218028, lng: 17.7689265 },
    { lat: 48.8218662, lng: 17.7689086 },
    { lat: 48.8219325, lng: 17.768887 },
    { lat: 48.8220955, lng: 17.7688376 },
    { lat: 48.8221546, lng: 17.768825 },
    { lat: 48.8222054, lng: 17.7688076 },
    { lat: 48.8222688, lng: 17.7687911 },
    { lat: 48.8223664, lng: 17.7687632 },
    { lat: 48.8224561, lng: 17.7687345 },
    { lat: 48.8225417, lng: 17.768713 },
    { lat: 48.822605, lng: 17.7686952 },
    { lat: 48.8226954, lng: 17.7686979 },
    { lat: 48.8227692, lng: 17.7687029 },
    { lat: 48.8228507, lng: 17.7687069 },
    { lat: 48.8229326, lng: 17.7686616 },
    { lat: 48.823009, lng: 17.7686188 },
    { lat: 48.8230736, lng: 17.7685859 },
    { lat: 48.823139, lng: 17.7685475 },
    { lat: 48.8232263, lng: 17.7684998 },
    { lat: 48.8232829, lng: 17.7684632 },
    { lat: 48.8233624, lng: 17.7684166 },
    { lat: 48.8234052, lng: 17.7683952 },
    { lat: 48.8234789, lng: 17.7683644 },
    { lat: 48.8235567, lng: 17.7683261 },
    { lat: 48.8236204, lng: 17.7682967 },
    { lat: 48.8236842, lng: 17.7682684 },
    { lat: 48.8237567, lng: 17.768236 },
    { lat: 48.8238412, lng: 17.7681969 },
    { lat: 48.823916, lng: 17.7681642 },
    { lat: 48.8239506, lng: 17.7681391 },
    { lat: 48.8240089, lng: 17.7680973 },
    { lat: 48.8240738, lng: 17.7680497 },
    { lat: 48.8241484, lng: 17.7679957 },
    { lat: 48.8242188, lng: 17.7679469 },
    { lat: 48.8242934, lng: 17.7678927 },
    { lat: 48.8243617, lng: 17.7678461 },
    { lat: 48.8244401, lng: 17.7677799 },
    { lat: 48.8244846, lng: 17.7677154 },
    { lat: 48.8245363, lng: 17.7676553 },
    { lat: 48.8246, lng: 17.7675715 },
    { lat: 48.8246471, lng: 17.7675115 },
    { lat: 48.8246775, lng: 17.7674166 },
    { lat: 48.8247105, lng: 17.7673115 },
    { lat: 48.8247087, lng: 17.7671883 },
    { lat: 48.8247091, lng: 17.767085 },
    { lat: 48.8247111, lng: 17.7669878 },
    { lat: 48.8247096, lng: 17.766873 },
    { lat: 48.8247098, lng: 17.7667644 },
    { lat: 48.8247078, lng: 17.7666775 },
    { lat: 48.8247079, lng: 17.7665689 },
    { lat: 48.8247091, lng: 17.7664392 },
    { lat: 48.8247385, lng: 17.7662936 },
    { lat: 48.8247578, lng: 17.7662033 },
    { lat: 48.8247828, lng: 17.766093 },
    { lat: 48.8248054, lng: 17.7659662 },
    { lat: 48.8248223, lng: 17.7659092 },
    { lat: 48.8248526, lng: 17.7658125 },
    { lat: 48.8248873, lng: 17.7656975 },
    { lat: 48.8249184, lng: 17.7655972 },
    { lat: 48.8249605, lng: 17.7655118 },
    { lat: 48.8250157, lng: 17.7654361 },
    { lat: 48.8250692, lng: 17.7653688 },
    { lat: 48.8250936, lng: 17.7653256 },
    { lat: 48.8251668, lng: 17.7651568 },
    { lat: 48.8252089, lng: 17.7650137 },
    { lat: 48.8252512, lng: 17.76493 },
    { lat: 48.8252698, lng: 17.7648466 },
    { lat: 48.8253121, lng: 17.7647632 },
    { lat: 48.8253249, lng: 17.7647331 },
    { lat: 48.8253496, lng: 17.7646935 },
    { lat: 48.8253843, lng: 17.7646322 },
    { lat: 48.8254103, lng: 17.7645771 },
    { lat: 48.8254295, lng: 17.7645053 },
    { lat: 48.8254501, lng: 17.7644527 },
    { lat: 48.825481, lng: 17.7643855 },
    { lat: 48.825511, lng: 17.7643052 },
    { lat: 48.8255495, lng: 17.7642335 },
    { lat: 48.8255748, lng: 17.7641312 },
    { lat: 48.8256045, lng: 17.7640249 },
    { lat: 48.825636, lng: 17.7639311 },
    { lat: 48.8256594, lng: 17.7638338 },
    { lat: 48.8256846, lng: 17.7637299 },
    { lat: 48.8257065, lng: 17.7636246 },
    { lat: 48.8257323, lng: 17.7635305 },
    { lat: 48.8257554, lng: 17.7634283 },
    { lat: 48.8257704, lng: 17.7633408 },
    { lat: 48.8257907, lng: 17.7632485 },
    { lat: 48.8258132, lng: 17.763175 },
    { lat: 48.8258468, lng: 17.7630757 },
    { lat: 48.8258613, lng: 17.7630193 },
    { lat: 48.8258908, lng: 17.7629097 },
    { lat: 48.8259106, lng: 17.7628098 },
    { lat: 48.8259373, lng: 17.7626744 },
    { lat: 48.8259638, lng: 17.7625352 },
    { lat: 48.8259848, lng: 17.7624369 },
    { lat: 48.8260032, lng: 17.7623305 },
    { lat: 48.8260394, lng: 17.7622396 },
    { lat: 48.826082, lng: 17.7621445 },
    { lat: 48.8261207, lng: 17.7620562 },
    { lat: 48.8261701, lng: 17.7619403 },
    { lat: 48.8262194, lng: 17.7618415 },
    { lat: 48.8262583, lng: 17.7617407 },
    { lat: 48.8262917, lng: 17.7616778 },
    { lat: 48.8263376, lng: 17.7615966 },
    { lat: 48.8263654, lng: 17.7615345 },
    { lat: 48.8264234, lng: 17.7614678 },
    { lat: 48.8264524, lng: 17.7614261 },
    { lat: 48.8265117, lng: 17.7613431 },
    { lat: 48.826536, lng: 17.7612977 },
    { lat: 48.8266031, lng: 17.761213 },
    { lat: 48.8266581, lng: 17.7611344 },
    { lat: 48.826713, lng: 17.7610724 },
    { lat: 48.826767, lng: 17.7609939 },
    { lat: 48.8268408, lng: 17.7610394 },
    { lat: 48.8268942, lng: 17.7609896 },
    { lat: 48.82694, lng: 17.7609455 },
    { lat: 48.8270012, lng: 17.7608947 },
    { lat: 48.8270469, lng: 17.7608463 },
    { lat: 48.8271, lng: 17.7607928 },
    { lat: 48.8271281, lng: 17.7607717 },
    { lat: 48.8271534, lng: 17.7607429 },
    { lat: 48.8271993, lng: 17.7606988 },
    { lat: 48.8272636, lng: 17.7606432 },
    { lat: 48.8273272, lng: 17.760592 },
    { lat: 48.8273865, lng: 17.7605494 },
    { lat: 48.8274357, lng: 17.7605045 },
    { lat: 48.8274897, lng: 17.7604631 },
    { lat: 48.8275456, lng: 17.7604172 },
    { lat: 48.8276122, lng: 17.7603613 },
    { lat: 48.8276436, lng: 17.7603397 },
    { lat: 48.8276767, lng: 17.76031 },
    { lat: 48.8277384, lng: 17.7602671 },
    { lat: 48.8277772, lng: 17.7602364 },
    { lat: 48.8278328, lng: 17.7601863 },
    { lat: 48.8279108, lng: 17.7601328 },
    { lat: 48.827958, lng: 17.7601088 },
    { lat: 48.8279916, lng: 17.7600869 },
    { lat: 48.8280594, lng: 17.7600514 },
    { lat: 48.8280942, lng: 17.7600293 },
    { lat: 48.8281286, lng: 17.7600198 },
    { lat: 48.8281435, lng: 17.7600054 },
    { lat: 48.8281699, lng: 17.7599927 },
    { lat: 48.8282221, lng: 17.7599598 },
    { lat: 48.8282816, lng: 17.7599172 },
    { lat: 48.828342, lng: 17.7598911 },
    { lat: 48.8283931, lng: 17.7598583 },
    { lat: 48.8284356, lng: 17.7598352 },
    { lat: 48.8284947, lng: 17.7597846 },
    { lat: 48.8285507, lng: 17.7597425 },
    { lat: 48.8286045, lng: 17.759697 },
    { lat: 48.8286605, lng: 17.7596348 },
    { lat: 48.8287103, lng: 17.7595816 },
    { lat: 48.8287612, lng: 17.7595282 },
    { lat: 48.8288293, lng: 17.7594597 },
    { lat: 48.8288651, lng: 17.759417 },
    { lat: 48.8289053, lng: 17.7593738 },
    { lat: 48.8289453, lng: 17.7593262 },
    { lat: 48.8290205, lng: 17.7592444 },
    { lat: 48.8290515, lng: 17.7592185 },
    { lat: 48.8290884, lng: 17.7591757 },
    { lat: 48.8291083, lng: 17.7591482 },
    { lat: 48.8291336, lng: 17.7591194 },
    { lat: 48.8291862, lng: 17.7590573 },
    { lat: 48.8292339, lng: 17.7590049 },
    { lat: 48.8292587, lng: 17.7589676 },
    { lat: 48.8292928, lng: 17.7589171 },
    { lat: 48.8293508, lng: 17.7588136 },
    { lat: 48.8294073, lng: 17.7587222 },
    { lat: 48.8294622, lng: 17.7586231 },
    { lat: 48.829506, lng: 17.7585091 },
    { lat: 48.8295491, lng: 17.7584203 },
    { lat: 48.8296152, lng: 17.7583191 },
    { lat: 48.8296886, lng: 17.7582459 },
    { lat: 48.8297561, lng: 17.7581692 },
    { lat: 48.8298195, lng: 17.7581343 },
    { lat: 48.8298742, lng: 17.7581051 },
    { lat: 48.8299337, lng: 17.7580627 },
    { lat: 48.8300027, lng: 17.7580106 },
    { lat: 48.8300697, lng: 17.7579628 },
    { lat: 48.8301663, lng: 17.7578978 },
    { lat: 48.8302331, lng: 17.7578461 },
    { lat: 48.8303019, lng: 17.7577899 },
    { lat: 48.8303628, lng: 17.7577351 },
    { lat: 48.8304095, lng: 17.7577029 },
    { lat: 48.8304663, lng: 17.7576527 },
    { lat: 48.8305379, lng: 17.7575878 },
    { lat: 48.8305986, lng: 17.7575289 },
    { lat: 48.8306726, lng: 17.7574637 },
    { lat: 48.8307257, lng: 17.7574102 },
    { lat: 48.8307665, lng: 17.7573745 },
    { lat: 48.8307973, lng: 17.7573449 },
    { lat: 48.8308676, lng: 17.7572761 },
    { lat: 48.8309322, lng: 17.7572247 },
    { lat: 48.8310062, lng: 17.7571595 },
    { lat: 48.8310793, lng: 17.7571189 },
    { lat: 48.8311292, lng: 17.7570867 },
    { lat: 48.8311688, lng: 17.7570678 },
    { lat: 48.8312358, lng: 17.7570572 },
    { lat: 48.8313086, lng: 17.7570657 },
    { lat: 48.8313529, lng: 17.757071 },
    { lat: 48.8313883, lng: 17.7570776 },
    { lat: 48.8314254, lng: 17.7570185 },
    { lat: 48.8314231, lng: 17.7569077 },
    { lat: 48.8314211, lng: 17.7568014 },
    { lat: 48.8314282, lng: 17.756751 },
    { lat: 48.8314399, lng: 17.7566461 },
    { lat: 48.8314593, lng: 17.7565406 },
    { lat: 48.8314776, lng: 17.7564511 },
    { lat: 48.8314965, lng: 17.7563741 },
    { lat: 48.8315163, lng: 17.7562726 },
    { lat: 48.8315399, lng: 17.7561782 },
    { lat: 48.8315508, lng: 17.7561357 },
    { lat: 48.8315683, lng: 17.7560339 },
    { lat: 48.8315882, lng: 17.7559734 },
    { lat: 48.83161, lng: 17.7558668 },
    { lat: 48.8316445, lng: 17.7558039 },
    { lat: 48.8316857, lng: 17.7557028 },
    { lat: 48.8317301, lng: 17.755634 },
    { lat: 48.8317698, lng: 17.7555828 },
    { lat: 48.8318464, lng: 17.7555251 },
    { lat: 48.8318713, lng: 17.7554513 },
    { lat: 48.8319137, lng: 17.75535 },
    { lat: 48.8319604, lng: 17.7552482 },
    { lat: 48.8320105, lng: 17.7551454 },
    { lat: 48.8320528, lng: 17.7550608 },
    { lat: 48.8320845, lng: 17.7550062 },
    { lat: 48.8321166, lng: 17.7549599 },
    { lat: 48.8321604, lng: 17.7548832 },
    { lat: 48.8322014, lng: 17.7548151 },
    { lat: 48.8322481, lng: 17.754746 },
    { lat: 48.832305, lng: 17.7546627 },
    { lat: 48.8323597, lng: 17.7545964 },
    { lat: 48.8323808, lng: 17.7545558 },
    { lat: 48.8324477, lng: 17.7545042 },
    { lat: 48.8324865, lng: 17.7544734 },
    { lat: 48.8325515, lng: 17.7544301 },
    { lat: 48.8326007, lng: 17.7544018 },
    { lat: 48.8326718, lng: 17.7543659 },
    { lat: 48.8326906, lng: 17.7542846 },
    { lat: 48.8327252, lng: 17.7542048 },
    { lat: 48.8327638, lng: 17.7541001 },
    { lat: 48.832789, lng: 17.7540304 },
    { lat: 48.832793, lng: 17.753931 },
    { lat: 48.8329237, lng: 17.7543722 },
    { lat: 48.832821, lng: 17.7537049 },
    { lat: 48.8328306, lng: 17.7536048 },
    { lat: 48.8328338, lng: 17.7535465 },
    { lat: 48.8328457, lng: 17.7534461 },
    { lat: 48.8328567, lng: 17.7534031 },
    { lat: 48.8328663, lng: 17.753303 },
    { lat: 48.8329038, lng: 17.7531779 },
    { lat: 48.8329322, lng: 17.7530872 },
    { lat: 48.8329455, lng: 17.7529737 },
    { lat: 48.8329818, lng: 17.7528656 },
    { lat: 48.8330172, lng: 17.7527448 },
    { lat: 48.8330418, lng: 17.7526297 },
    { lat: 48.8330762, lng: 17.7525095 },
    { lat: 48.8331005, lng: 17.7523902 },
    { lat: 48.8331319, lng: 17.752258 },
    { lat: 48.8331628, lng: 17.7521171 },
    { lat: 48.833199, lng: 17.7519719 },
    { lat: 48.833214, lng: 17.7519202 },
    { lat: 48.8332473, lng: 17.7517834 },
    { lat: 48.8332709, lng: 17.7516522 },
    { lat: 48.8333039, lng: 17.7514906 },
    { lat: 48.833335, lng: 17.7513174 },
    { lat: 48.8333763, lng: 17.7512163 },
    { lat: 48.8334074, lng: 17.7511166 },
    { lat: 48.8334269, lng: 17.7510479 },
    { lat: 48.8334773, lng: 17.7509862 },
    { lat: 48.8335298, lng: 17.7509202 },
    { lat: 48.8335588, lng: 17.7508785 },
    { lat: 48.8335871, lng: 17.7508616 },
    { lat: 48.8336767, lng: 17.7508144 },
    { lat: 48.8337205, lng: 17.7507912 },
    { lat: 48.8337804, lng: 17.7507566 },
    { lat: 48.8338458, lng: 17.7507175 },
    { lat: 48.8339476, lng: 17.7506643 },
    { lat: 48.8339734, lng: 17.7506436 },
    { lat: 48.8340733, lng: 17.750562 },
    { lat: 48.8341658, lng: 17.7504858 },
    { lat: 48.8342124, lng: 17.7504534 },
    { lat: 48.8342643, lng: 17.7503424 },
    { lat: 48.8343329, lng: 17.7502288 },
    { lat: 48.834369, lng: 17.7501737 },
    { lat: 48.8344361, lng: 17.750089 },
    { lat: 48.8345063, lng: 17.7499996 },
    { lat: 48.8345648, lng: 17.7499408 },
    { lat: 48.8346024, lng: 17.7500579 },
    { lat: 48.8346445, lng: 17.7501541 },
    { lat: 48.8346656, lng: 17.7503316 },
    { lat: 48.8346723, lng: 17.7504414 },
    { lat: 48.8346976, lng: 17.7505973 },
    { lat: 48.834689, lng: 17.7507139 },
    { lat: 48.834717, lng: 17.7508408 },
    { lat: 48.8347264, lng: 17.7509378 },
    { lat: 48.8348022, lng: 17.7509422 },
    { lat: 48.8348706, lng: 17.7509888 },
    { lat: 48.8349089, lng: 17.7510238 },
    { lat: 48.8349598, lng: 17.751061 },
    { lat: 48.8350137, lng: 17.7512043 },
    { lat: 48.8350308, lng: 17.751263 },
    { lat: 48.8350574, lng: 17.7513286 },
    { lat: 48.8350809, lng: 17.7513988 },
    { lat: 48.8351241, lng: 17.7515522 },
    { lat: 48.8351524, lng: 17.7516423 },
    { lat: 48.8351746, lng: 17.7516922 },
    { lat: 48.8352133, lng: 17.7517722 },
    { lat: 48.835255, lng: 17.7519011 },
    { lat: 48.8352705, lng: 17.7519686 },
    { lat: 48.835292, lng: 17.752043 },
    { lat: 48.8353165, lng: 17.7521297 },
    { lat: 48.8353467, lng: 17.7520878 },
    { lat: 48.835446, lng: 17.7519939 },
    { lat: 48.8355112, lng: 17.7519177 },
    { lat: 48.8355886, lng: 17.7518354 },
    { lat: 48.8356579, lng: 17.7517667 },
    { lat: 48.8357003, lng: 17.7517231 },
    { lat: 48.8357661, lng: 17.7516549 },
    { lat: 48.8357988, lng: 17.7516167 },
    { lat: 48.8358848, lng: 17.751529 },
    { lat: 48.835978, lng: 17.7514647 },
    { lat: 48.8360558, lng: 17.7514442 },
    { lat: 48.836098, lng: 17.7514333 },
    { lat: 48.8361355, lng: 17.751419 },
    { lat: 48.8362094, lng: 17.7513905 },
    { lat: 48.8362772, lng: 17.751355 },
    { lat: 48.8363588, lng: 17.7513217 },
    { lat: 48.8364508, lng: 17.7512779 },
    { lat: 48.8364926, lng: 17.7512588 },
    { lat: 48.8365377, lng: 17.7512397 },
    { lat: 48.8366006, lng: 17.7512171 },
    { lat: 48.8366885, lng: 17.7512318 },
    { lat: 48.8367516, lng: 17.7511189 },
    { lat: 48.8368211, lng: 17.7510009 },
    { lat: 48.8369088, lng: 17.750847 },
    { lat: 48.8370069, lng: 17.7506999 },
    { lat: 48.8370999, lng: 17.7505576 },
    { lat: 48.8371664, lng: 17.7504646 },
    { lat: 48.8372484, lng: 17.75055 },
    { lat: 48.837292, lng: 17.7505965 },
    { lat: 48.8373336, lng: 17.7506514 },
    { lat: 48.837393, lng: 17.7507162 },
    { lat: 48.8374279, lng: 17.7507514 },
    { lat: 48.8375187, lng: 17.7509259 },
    { lat: 48.8375683, lng: 17.7510164 },
    { lat: 48.8376096, lng: 17.7511004 },
    { lat: 48.8376589, lng: 17.7511871 },
    { lat: 48.8377171, lng: 17.7511241 },
    { lat: 48.8377744, lng: 17.7510451 },
    { lat: 48.8378688, lng: 17.7509275 },
    { lat: 48.8379536, lng: 17.7508194 },
    { lat: 48.8380154, lng: 17.7507393 },
    { lat: 48.8381207, lng: 17.7506159 },
    { lat: 48.8381979, lng: 17.7505131 },
    { lat: 48.8382448, lng: 17.750448 },
    { lat: 48.838348, lng: 17.7503286 },
    { lat: 48.8384424, lng: 17.7502105 },
    { lat: 48.8385326, lng: 17.7500974 },
    { lat: 48.8386381, lng: 17.7499778 },
    { lat: 48.8387103, lng: 17.7498843 },
    { lat: 48.8387925, lng: 17.7497888 },
    { lat: 48.8388468, lng: 17.7497185 },
    { lat: 48.8389685, lng: 17.7495842 },
    { lat: 48.8390193, lng: 17.7495471 },
    { lat: 48.8390786, lng: 17.7495006 },
    { lat: 48.8391319, lng: 17.7494508 },
    { lat: 48.8391576, lng: 17.7495375 },
    { lat: 48.8391927, lng: 17.7496137 },
    { lat: 48.8392067, lng: 17.7496566 },
    { lat: 48.839239, lng: 17.749742 },
    { lat: 48.8393177, lng: 17.7496637 },
    { lat: 48.8393636, lng: 17.7496192 },
    { lat: 48.8394008, lng: 17.7495638 },
    { lat: 48.8394571, lng: 17.7494892 },
    { lat: 48.8395197, lng: 17.7493681 },
    { lat: 48.8395607, lng: 17.7492998 },
    { lat: 48.8396279, lng: 17.7491616 },
    { lat: 48.8396501, lng: 17.7491008 },
    { lat: 48.8396805, lng: 17.7490259 },
    { lat: 48.8397139, lng: 17.7489259 },
    { lat: 48.8397686, lng: 17.7487322 },
    { lat: 48.8397916, lng: 17.7486465 },
    { lat: 48.8398479, lng: 17.7486253 },
    { lat: 48.8398855, lng: 17.7485944 },
    { lat: 48.8398774, lng: 17.7485176 },
    { lat: 48.839989, lng: 17.7484053 },
    { lat: 48.8401083, lng: 17.7482876 },
    { lat: 48.8401782, lng: 17.7482312 },
    { lat: 48.840228, lng: 17.7481779 },
    { lat: 48.8403601, lng: 17.7480498 },
    { lat: 48.8405174, lng: 17.7479628 },
    { lat: 48.8405976, lng: 17.747946 },
    { lat: 48.8406464, lng: 17.7480246 },
    { lat: 48.8406773, lng: 17.7481591 },
    { lat: 48.8407442, lng: 17.7481814 },
    { lat: 48.8408051, lng: 17.7482001 },
    { lat: 48.8409107, lng: 17.7483191 },
    { lat: 48.8409461, lng: 17.7483625 },
    { lat: 48.8410291, lng: 17.7484646 },
    { lat: 48.8410764, lng: 17.7485187 },
    { lat: 48.8411663, lng: 17.7486397 },
    { lat: 48.8412136, lng: 17.7486938 },
    { lat: 48.8412516, lng: 17.7487779 },
    { lat: 48.8412694, lng: 17.7488655 },
    { lat: 48.841284, lng: 17.7489576 },
    { lat: 48.8412688, lng: 17.7490217 },
    { lat: 48.8412296, lng: 17.7491928 },
    { lat: 48.841204, lng: 17.7492174 },
    { lat: 48.8412533, lng: 17.749304 },
    { lat: 48.8413131, lng: 17.7493397 },
    { lat: 48.841368, lng: 17.7493884 },
    { lat: 48.8414204, lng: 17.7493964 },
    { lat: 48.8415715, lng: 17.7494295 },
    { lat: 48.8416591, lng: 17.7494402 },
    { lat: 48.8417325, lng: 17.7494778 },
    { lat: 48.8417862, lng: 17.7495061 },
    { lat: 48.8418701, lng: 17.7495503 },
    { lat: 48.8418673, lng: 17.7496328 },
    { lat: 48.8418578, lng: 17.7497168 },
    { lat: 48.8418496, lng: 17.7498043 },
    { lat: 48.8418436, lng: 17.7499082 },
    { lat: 48.8418675, lng: 17.7499863 },
    { lat: 48.8419008, lng: 17.7500673 },
    { lat: 48.84194, lng: 17.7501554 },
    { lat: 48.8419761, lng: 17.7502483 },
    { lat: 48.842029, lng: 17.7503918 },
    { lat: 48.8420923, lng: 17.7503572 },
    { lat: 48.8421404, lng: 17.7503123 },
    { lat: 48.8422513, lng: 17.7502245 },
    { lat: 48.8423094, lng: 17.7501783 },
    { lat: 48.8423763, lng: 17.7501264 },
    { lat: 48.8424485, lng: 17.7500696 },
    { lat: 48.8425596, lng: 17.7499861 },
    { lat: 48.8426337, lng: 17.7499248 },
    { lat: 48.8427318, lng: 17.7498477 },
    { lat: 48.8428133, lng: 17.7499251 },
    { lat: 48.8428995, lng: 17.7500058 },
    { lat: 48.8429284, lng: 17.7499435 },
    { lat: 48.842894, lng: 17.7498792 },
    { lat: 48.8428405, lng: 17.749781 },
    { lat: 48.84301, lng: 17.7496716 },
    { lat: 48.8431002, lng: 17.7496157 },
    { lat: 48.843197, lng: 17.7495551 },
    { lat: 48.8433019, lng: 17.7494809 },
    { lat: 48.8434046, lng: 17.7494232 },
    { lat: 48.8435137, lng: 17.7493434 },
    { lat: 48.843549, lng: 17.7492934 },
    { lat: 48.8435809, lng: 17.7492436 },
    { lat: 48.8436194, lng: 17.7491891 },
    { lat: 48.8436649, lng: 17.749121 },
    { lat: 48.8437062, lng: 17.7490575 },
    { lat: 48.8436804, lng: 17.7489873 },
    { lat: 48.8436512, lng: 17.7489177 },
    { lat: 48.8436082, lng: 17.7488044 },
    { lat: 48.8435627, lng: 17.7486877 },
    { lat: 48.8434971, lng: 17.7485204 },
    { lat: 48.8434571, lng: 17.7484196 },
    { lat: 48.8433522, lng: 17.7482931 },
    { lat: 48.8433019, lng: 17.7482271 },
    { lat: 48.8432343, lng: 17.7481558 },
    { lat: 48.8431635, lng: 17.7480688 },
    { lat: 48.8430771, lng: 17.7479675 },
    { lat: 48.8430072, lng: 17.7478965 },
    { lat: 48.8429493, lng: 17.7478359 },
    { lat: 48.8428549, lng: 17.7477316 },
    { lat: 48.8428111, lng: 17.7476813 },
    { lat: 48.8427557, lng: 17.7476246 },
    { lat: 48.8427116, lng: 17.7475864 },
    { lat: 48.8426555, lng: 17.7475172 },
    { lat: 48.8425962, lng: 17.747453 },
    { lat: 48.8425439, lng: 17.7473913 },
    { lat: 48.8424562, lng: 17.7473067 },
    { lat: 48.8423864, lng: 17.7472357 },
    { lat: 48.8423334, lng: 17.7471824 },
    { lat: 48.8422718, lng: 17.7471146 },
    { lat: 48.8422196, lng: 17.7470569 },
    { lat: 48.8421487, lng: 17.746986 },
    { lat: 48.8420612, lng: 17.7468851 },
    { lat: 48.8419478, lng: 17.7466197 },
    { lat: 48.8419078, lng: 17.7465192 },
    { lat: 48.841837, lng: 17.746321 },
    { lat: 48.8418089, lng: 17.7462309 },
    { lat: 48.8418036, lng: 17.7461085 },
    { lat: 48.8417884, lng: 17.7458972 },
    { lat: 48.8417742, lng: 17.745649 },
    { lat: 48.8417627, lng: 17.7454617 },
    { lat: 48.841879, lng: 17.7454059 },
    { lat: 48.8419739, lng: 17.7452963 },
    { lat: 48.8420595, lng: 17.7453113 },
    { lat: 48.8421628, lng: 17.7455045 },
    { lat: 48.8422148, lng: 17.7455986 },
    { lat: 48.8422589, lng: 17.7456902 },
    { lat: 48.8423272, lng: 17.7458107 },
    { lat: 48.8423783, lng: 17.7456876 },
    { lat: 48.8424171, lng: 17.7455825 },
    { lat: 48.8424563, lng: 17.7454859 },
    { lat: 48.8425024, lng: 17.7453717 },
    { lat: 48.8425472, lng: 17.7452742 },
    { lat: 48.8425664, lng: 17.7452014 },
    { lat: 48.8426024, lng: 17.7450886 },
    { lat: 48.8426143, lng: 17.7449882 },
    { lat: 48.8426396, lng: 17.7448854 },
    { lat: 48.8426707, lng: 17.7447489 },
    { lat: 48.8425123, lng: 17.7445769 },
    { lat: 48.8424358, lng: 17.7444908 },
    { lat: 48.8424267, lng: 17.7442496 },
    { lat: 48.8424185, lng: 17.7441357 },
    { lat: 48.8424139, lng: 17.7439845 },
    { lat: 48.8423301, lng: 17.7438705 },
    { lat: 48.8422871, lng: 17.7438321 },
    { lat: 48.8422317, lng: 17.7437754 },
    { lat: 48.8421931, lng: 17.7436991 },
    { lat: 48.8421276, lng: 17.7436071 },
    { lat: 48.8421157, lng: 17.7435228 },
    { lat: 48.842038, lng: 17.7432681 },
    { lat: 48.8420288, lng: 17.7431379 },
    { lat: 48.8420357, lng: 17.7429192 },
    { lat: 48.8419715, lng: 17.742847 },
    { lat: 48.8420403, lng: 17.7427538 },
    { lat: 48.8421208, lng: 17.7426301 },
    { lat: 48.8421982, lng: 17.7424943 },
    { lat: 48.8422395, lng: 17.7424136 },
    { lat: 48.8423002, lng: 17.7423179 },
    { lat: 48.8423688, lng: 17.7422201 },
    { lat: 48.8424184, lng: 17.7421464 },
    { lat: 48.8425554, lng: 17.7419312 },
    { lat: 48.8426476, lng: 17.741814 },
    { lat: 48.8427209, lng: 17.7417036 },
    { lat: 48.8428012, lng: 17.7415755 },
    { lat: 48.8428745, lng: 17.7414651 },
    { lat: 48.8429374, lng: 17.741422 },
    { lat: 48.8430244, lng: 17.7413678 },
    { lat: 48.8430992, lng: 17.7412996 },
    { lat: 48.8431454, lng: 17.7412614 },
    { lat: 48.8431999, lng: 17.7412302 },
    { lat: 48.8432649, lng: 17.7412216 },
    { lat: 48.843314, lng: 17.741212 },
    { lat: 48.8433842, lng: 17.7412351 },
    { lat: 48.8434958, lng: 17.7412672 },
    { lat: 48.843536, lng: 17.7412794 },
    { lat: 48.8435879, lng: 17.7412983 },
    { lat: 48.8436616, lng: 17.741358 },
    { lat: 48.8436878, lng: 17.7413984 },
    { lat: 48.8437089, lng: 17.7414476 },
    { lat: 48.8438046, lng: 17.7416266 },
    { lat: 48.8438387, lng: 17.741723 },
    { lat: 48.8438615, lng: 17.7418006 },
    { lat: 48.8439291, lng: 17.7419441 },
    { lat: 48.8439521, lng: 17.7419893 },
    { lat: 48.8440143, lng: 17.7421008 },
    { lat: 48.8440374, lng: 17.7421454 },
    { lat: 48.8440726, lng: 17.7422046 },
    { lat: 48.8441077, lng: 17.7422799 },
    { lat: 48.8441344, lng: 17.7423283 },
    { lat: 48.8441632, lng: 17.7423927 },
    { lat: 48.8441956, lng: 17.7424604 },
    { lat: 48.8442282, lng: 17.7425325 },
    { lat: 48.8443019, lng: 17.7426662 },
    { lat: 48.8443396, lng: 17.7427288 },
    { lat: 48.8443872, lng: 17.742806 },
    { lat: 48.8444318, lng: 17.7428882 },
    { lat: 48.8444759, lng: 17.743036 },
    { lat: 48.8445361, lng: 17.743263 },
    { lat: 48.8445592, lng: 17.7433821 },
    { lat: 48.8445881, lng: 17.743467 },
    { lat: 48.844606, lng: 17.743225 },
    { lat: 48.8446178, lng: 17.7430864 },
    { lat: 48.8446424, lng: 17.7428055 },
    { lat: 48.8446601, lng: 17.74267 },
    { lat: 48.8446643, lng: 17.7424633 },
    { lat: 48.8446651, lng: 17.7421087 },
    { lat: 48.8446677, lng: 17.741894 },
    { lat: 48.8446851, lng: 17.7418826 },
    { lat: 48.8446707, lng: 17.7417782 },
    { lat: 48.8446549, lng: 17.7416496 },
    { lat: 48.8446395, lng: 17.7414713 },
    { lat: 48.8446097, lng: 17.7412792 },
    { lat: 48.8445705, lng: 17.7410111 },
    { lat: 48.8445571, lng: 17.7409189 },
    { lat: 48.8445149, lng: 17.7407089 },
    { lat: 48.8444818, lng: 17.7406121 },
    { lat: 48.8444376, lng: 17.7405714 },
    { lat: 48.8443804, lng: 17.7405251 },
    { lat: 48.8442871, lng: 17.7403658 },
    { lat: 48.8442329, lng: 17.7402739 },
    { lat: 48.8441834, lng: 17.7401846 },
    { lat: 48.8441273, lng: 17.7400801 },
    { lat: 48.844058, lng: 17.7399988 },
    { lat: 48.8440006, lng: 17.7399277 },
    { lat: 48.8439393, lng: 17.7398491 },
    { lat: 48.8439008, lng: 17.7397742 },
    { lat: 48.8438346, lng: 17.7396513 },
    { lat: 48.8437669, lng: 17.7395244 },
    { lat: 48.8436991, lng: 17.7393939 },
    { lat: 48.8436583, lng: 17.7392614 },
    { lat: 48.8436816, lng: 17.7390178 },
    { lat: 48.8437078, lng: 17.7387451 },
    { lat: 48.8437359, lng: 17.7384675 },
    { lat: 48.8437571, lng: 17.7381874 },
    { lat: 48.843768, lng: 17.7380699 },
    { lat: 48.843794, lng: 17.7378136 },
    { lat: 48.8438081, lng: 17.7376582 },
    { lat: 48.8438203, lng: 17.7375075 },
    { lat: 48.8438331, lng: 17.7374224 },
    { lat: 48.8438792, lng: 17.7371417 },
    { lat: 48.8438995, lng: 17.7370141 },
    { lat: 48.843921, lng: 17.7368861 },
    { lat: 48.8439799, lng: 17.7366687 },
    { lat: 48.844032, lng: 17.7365595 },
    { lat: 48.8441587, lng: 17.736271 },
    { lat: 48.8442167, lng: 17.7361114 },
    { lat: 48.8442708, lng: 17.735981 },
    { lat: 48.8443074, lng: 17.7359165 },
    { lat: 48.8460795, lng: 17.7355247 },
    { lat: 48.8461282, lng: 17.7354724 },
    { lat: 48.8462063, lng: 17.7354001 },
    { lat: 48.8462897, lng: 17.7353239 },
    { lat: 48.8463793, lng: 17.735239 },
    { lat: 48.8464666, lng: 17.7351539 },
    { lat: 48.846537, lng: 17.7350859 },
    { lat: 48.8466645, lng: 17.7348091 },
    { lat: 48.8467273, lng: 17.7346746 },
    { lat: 48.8467783, lng: 17.7345665 },
    { lat: 48.8468475, lng: 17.7344989 },
    { lat: 48.8469269, lng: 17.7344314 },
    { lat: 48.8470167, lng: 17.7343494 },
    { lat: 48.8470991, lng: 17.7342748 },
    { lat: 48.8471878, lng: 17.7341947 },
    { lat: 48.8472618, lng: 17.7341309 },
    { lat: 48.8473576, lng: 17.734019 },
    { lat: 48.8474315, lng: 17.7339342 },
    { lat: 48.8475339, lng: 17.7338192 },
    { lat: 48.8476236, lng: 17.7337176 },
    { lat: 48.8477038, lng: 17.7336255 },
    { lat: 48.8477949, lng: 17.7335648 },
    { lat: 48.8478807, lng: 17.7335077 },
    { lat: 48.8479738, lng: 17.7334449 },
    { lat: 48.8480846, lng: 17.7333737 },
    { lat: 48.8481669, lng: 17.7333173 },
    { lat: 48.8482515, lng: 17.7332587 },
    { lat: 48.8483274, lng: 17.7332082 },
    { lat: 48.8483968, lng: 17.7331617 },
    { lat: 48.8484821, lng: 17.7330982 },
    { lat: 48.8485349, lng: 17.7330028 },
    { lat: 48.848587, lng: 17.7328961 },
    { lat: 48.8486396, lng: 17.7327976 },
    { lat: 48.8486863, lng: 17.7327097 },
    { lat: 48.8487275, lng: 17.7326273 },
    { lat: 48.8487637, lng: 17.7325573 },
    { lat: 48.8488128, lng: 17.7324724 },
    { lat: 48.8488785, lng: 17.7324214 },
    { lat: 48.8489596, lng: 17.7323619 },
    { lat: 48.8490513, lng: 17.7322943 },
    { lat: 48.8491636, lng: 17.7322113 },
    { lat: 48.8492315, lng: 17.7321581 },
    { lat: 48.8492742, lng: 17.7320808 },
    { lat: 48.8493251, lng: 17.7319906 },
    { lat: 48.8493799, lng: 17.7318916 },
    { lat: 48.8494318, lng: 17.7317984 },
    { lat: 48.849492, lng: 17.7316941 },
    { lat: 48.8495485, lng: 17.7316391 },
    { lat: 48.8496332, lng: 17.7315661 },
    { lat: 48.8497089, lng: 17.7314959 },
    { lat: 48.8498201, lng: 17.7313929 },
    { lat: 48.8499002, lng: 17.7313187 },
    { lat: 48.8499394, lng: 17.73124 },
    { lat: 48.8499955, lng: 17.7311231 },
    { lat: 48.8500484, lng: 17.7310113 },
    { lat: 48.8501154, lng: 17.7308701 },
    { lat: 48.8501681, lng: 17.7307549 },
    { lat: 48.8502091, lng: 17.7305579 },
    { lat: 48.8502367, lng: 17.7304201 },
    { lat: 48.8502621, lng: 17.7302989 },
    { lat: 48.8502864, lng: 17.7301808 },
    { lat: 48.8503129, lng: 17.7300613 },
    { lat: 48.8503559, lng: 17.7299346 },
    { lat: 48.8503933, lng: 17.7298262 },
    { lat: 48.8504338, lng: 17.7297131 },
    { lat: 48.850474, lng: 17.7295963 },
    { lat: 48.8505088, lng: 17.7295016 },
    { lat: 48.8505714, lng: 17.729325 },
    { lat: 48.8506031, lng: 17.7292354 },
    { lat: 48.8506364, lng: 17.7291344 },
    { lat: 48.8507214, lng: 17.7290289 },
    { lat: 48.8507748, lng: 17.7289611 },
    { lat: 48.8508367, lng: 17.7288842 },
    { lat: 48.8508932, lng: 17.7288133 },
    { lat: 48.850953, lng: 17.7287388 },
    { lat: 48.8510182, lng: 17.7286598 },
    { lat: 48.8510778, lng: 17.7285833 },
    { lat: 48.8511587, lng: 17.7284842 },
    { lat: 48.8512446, lng: 17.7283767 },
    { lat: 48.8512578, lng: 17.7283509 },
    { lat: 48.8512919, lng: 17.7283025 },
    { lat: 48.8513534, lng: 17.7282185 },
    { lat: 48.8514109, lng: 17.7281446 },
    { lat: 48.8514693, lng: 17.7280652 },
    { lat: 48.8515308, lng: 17.7279814 },
    { lat: 48.8515974, lng: 17.7278899 },
    { lat: 48.8516703, lng: 17.7277893 },
    { lat: 48.8517214, lng: 17.7277209 },
    { lat: 48.8517789, lng: 17.7276453 },
    { lat: 48.851831, lng: 17.727575 },
    { lat: 48.8518946, lng: 17.7274907 },
    { lat: 48.8519271, lng: 17.7274686 },
    { lat: 48.8520019, lng: 17.7274175 },
    { lat: 48.8520679, lng: 17.7273718 },
    { lat: 48.8521708, lng: 17.7273002 },
    { lat: 48.8522715, lng: 17.7272313 },
    { lat: 48.8523114, lng: 17.7271988 },
    { lat: 48.8523986, lng: 17.7271308 },
    { lat: 48.8525224, lng: 17.7270326 },
    { lat: 48.8526343, lng: 17.7269422 },
    { lat: 48.8527095, lng: 17.7268807 },
    { lat: 48.8527827, lng: 17.7268229 },
    { lat: 48.8528564, lng: 17.72668 },
    { lat: 48.852909, lng: 17.7265819 },
    { lat: 48.8530163, lng: 17.7263615 },
    { lat: 48.8530656, lng: 17.7262633 },
    { lat: 48.853126, lng: 17.7261992 },
    { lat: 48.8532055, lng: 17.7261157 },
    { lat: 48.8533104, lng: 17.7260034 },
    { lat: 48.8533848, lng: 17.7259274 },
    { lat: 48.8534699, lng: 17.7258435 },
    { lat: 48.8535443, lng: 17.7257694 },
    { lat: 48.8535857, lng: 17.7257071 },
    { lat: 48.8536714, lng: 17.7255772 },
    { lat: 48.8537265, lng: 17.7255002 },
    { lat: 48.8538082, lng: 17.7253791 },
    { lat: 48.8538758, lng: 17.7252841 },
    { lat: 48.8539347, lng: 17.7251965 },
    { lat: 48.8540478, lng: 17.7250327 },
    { lat: 48.8541183, lng: 17.7249308 },
    { lat: 48.8542144, lng: 17.7248786 },
    { lat: 48.8542974, lng: 17.7248339 },
    { lat: 48.8543783, lng: 17.7247883 },
    { lat: 48.8544951, lng: 17.7247239 },
    { lat: 48.8546195, lng: 17.724655 },
    { lat: 48.8547113, lng: 17.7246066 },
    { lat: 48.8548047, lng: 17.724453 },
    { lat: 48.8548623, lng: 17.724364 },
    { lat: 48.8550065, lng: 17.724301 },
    { lat: 48.8551065, lng: 17.7242582 },
    { lat: 48.8552524, lng: 17.7242996 },
    { lat: 48.8553662, lng: 17.7243323 },
    { lat: 48.8554569, lng: 17.7243575 },
    { lat: 48.8555613, lng: 17.724273 },
    { lat: 48.8556334, lng: 17.7242153 },
    { lat: 48.855713, lng: 17.7241515 },
    { lat: 48.8557894, lng: 17.7240886 },
    { lat: 48.8558045, lng: 17.7240226 },
    { lat: 48.8558307, lng: 17.7239164 },
    { lat: 48.8558569, lng: 17.7238116 },
    { lat: 48.8558859, lng: 17.7236951 },
    { lat: 48.8559178, lng: 17.7235896 },
    { lat: 48.8560159, lng: 17.7235702 },
    { lat: 48.8561272, lng: 17.7235421 },
    { lat: 48.856199, lng: 17.7235006 },
    { lat: 48.8562951, lng: 17.7234465 },
    { lat: 48.8563726, lng: 17.7234029 },
    { lat: 48.8564611, lng: 17.7233549 },
    { lat: 48.8565257, lng: 17.7233227 },
    { lat: 48.8566265, lng: 17.7232729 },
    { lat: 48.856746, lng: 17.7232143 },
    { lat: 48.8568545, lng: 17.7231608 },
    { lat: 48.8569716, lng: 17.7231006 },
    { lat: 48.857068, lng: 17.7230531 },
    { lat: 48.8571329, lng: 17.7229717 },
    { lat: 48.8572124, lng: 17.7228673 },
    { lat: 48.857296, lng: 17.7227592 },
    { lat: 48.8573712, lng: 17.72266 },
    { lat: 48.8575009, lng: 17.7225117 },
    { lat: 48.8575707, lng: 17.7224346 },
    { lat: 48.8576362, lng: 17.7223617 },
    { lat: 48.8577092, lng: 17.7222807 },
    { lat: 48.857799, lng: 17.7221816 },
    { lat: 48.8579004, lng: 17.722068 },
    { lat: 48.8579808, lng: 17.7219797 },
    { lat: 48.8580327, lng: 17.7219048 },
    { lat: 48.8581155, lng: 17.7217848 },
    { lat: 48.8581848, lng: 17.7216802 },
    { lat: 48.8583289, lng: 17.7214728 },
    { lat: 48.8584056, lng: 17.7213605 },
    { lat: 48.8584896, lng: 17.7212393 },
    { lat: 48.8585673, lng: 17.7211251 },
    { lat: 48.8586334, lng: 17.7210268 },
    { lat: 48.8586622, lng: 17.7209073 },
    { lat: 48.8586932, lng: 17.720769 },
    { lat: 48.8587226, lng: 17.7206411 },
    { lat: 48.8587626, lng: 17.7204657 },
    { lat: 48.8587942, lng: 17.720318 },
    { lat: 48.858872, lng: 17.7199875 },
    { lat: 48.8589107, lng: 17.7198271 },
    { lat: 48.8589607, lng: 17.7197032 },
    { lat: 48.8590123, lng: 17.7195706 },
    { lat: 48.8590805, lng: 17.7193954 },
    { lat: 48.8591464, lng: 17.7192188 },
    { lat: 48.8591999, lng: 17.7190783 },
    { lat: 48.8592635, lng: 17.7189183 },
    { lat: 48.8593139, lng: 17.7187844 },
    { lat: 48.8593698, lng: 17.7186466 },
    { lat: 48.8594184, lng: 17.7185194 },
    { lat: 48.8594711, lng: 17.7183838 },
    { lat: 48.8595203, lng: 17.71825 },
    { lat: 48.8595896, lng: 17.718073 },
    { lat: 48.8596531, lng: 17.7179306 },
    { lat: 48.8596861, lng: 17.7178426 },
    { lat: 48.8597346, lng: 17.7177124 },
    { lat: 48.8597903, lng: 17.717572 },
    { lat: 48.8598048, lng: 17.7175323 },
    { lat: 48.8598868, lng: 17.7173257 },
    { lat: 48.8599472, lng: 17.7171696 },
    { lat: 48.8599926, lng: 17.717045 },
    { lat: 48.8600442, lng: 17.7169099 },
    { lat: 48.8600966, lng: 17.7167714 },
    { lat: 48.8601498, lng: 17.716628 },
    { lat: 48.860182, lng: 17.7165445 },
    { lat: 48.8602316, lng: 17.7164144 },
    { lat: 48.8602725, lng: 17.7163089 },
    { lat: 48.860318, lng: 17.7161874 },
    { lat: 48.8603552, lng: 17.7160932 },
    { lat: 48.8603884, lng: 17.7160094 },
    { lat: 48.8604223, lng: 17.7159177 },
    { lat: 48.8604581, lng: 17.7158206 },
    { lat: 48.8604909, lng: 17.7157317 },
    { lat: 48.8605159, lng: 17.7156611 },
    { lat: 48.8605452, lng: 17.715586 },
    { lat: 48.8605771, lng: 17.7154976 },
    { lat: 48.8606264, lng: 17.7153656 },
    { lat: 48.8606665, lng: 17.715263 },
    { lat: 48.860707, lng: 17.7151502 },
    { lat: 48.8607524, lng: 17.7150271 },
    { lat: 48.8608133, lng: 17.7148605 },
    { lat: 48.8608644, lng: 17.7147202 },
    { lat: 48.8609033, lng: 17.7146177 },
    { lat: 48.8609468, lng: 17.7144818 },
    { lat: 48.8609976, lng: 17.7143347 },
    { lat: 48.8610462, lng: 17.714188 },
    { lat: 48.8610908, lng: 17.7140517 },
    { lat: 48.8611357, lng: 17.7139186 },
    { lat: 48.8611663, lng: 17.7138288 },
    { lat: 48.8612465, lng: 17.7137371 },
    { lat: 48.8613189, lng: 17.7136494 },
    { lat: 48.8614283, lng: 17.7135183 },
    { lat: 48.8613781, lng: 17.7133812 },
    { lat: 48.8613246, lng: 17.7132459 },
    { lat: 48.8612726, lng: 17.7130989 },
    { lat: 48.8612045, lng: 17.7129293 },
    { lat: 48.86114, lng: 17.7127614 },
    { lat: 48.8611003, lng: 17.7126652 },
    { lat: 48.8611168, lng: 17.7124935 },
    { lat: 48.8611329, lng: 17.7123153 },
    { lat: 48.8611534, lng: 17.7120806 },
    { lat: 48.8611744, lng: 17.7118526 },
    { lat: 48.8611923, lng: 17.7116495 },
    { lat: 48.8612077, lng: 17.7114581 },
    { lat: 48.8612385, lng: 17.7112994 },
    { lat: 48.861278, lng: 17.7110982 },
    { lat: 48.8613132, lng: 17.710917 },
    { lat: 48.8613393, lng: 17.7107722 },
    { lat: 48.8613631, lng: 17.7106469 },
    { lat: 48.8614248, lng: 17.7105299 },
    { lat: 48.8614993, lng: 17.7103841 },
    { lat: 48.8615638, lng: 17.7102565 },
    { lat: 48.8616202, lng: 17.7101467 },
    { lat: 48.8616712, lng: 17.7100407 },
    { lat: 48.8617296, lng: 17.7099238 },
    { lat: 48.8617623, lng: 17.7097778 },
    { lat: 48.8617923, lng: 17.709642 },
    { lat: 48.8618223, lng: 17.7095047 },
    { lat: 48.8618583, lng: 17.7093386 },
    { lat: 48.861898, lng: 17.7091585 },
    { lat: 48.8619128, lng: 17.7090691 },
    { lat: 48.8619281, lng: 17.7088963 },
    { lat: 48.8619422, lng: 17.7087215 },
    { lat: 48.8619625, lng: 17.7084457 },
    { lat: 48.8619765, lng: 17.7082153 },
    { lat: 48.8620321, lng: 17.7080744 },
    { lat: 48.8620842, lng: 17.7079304 },
    { lat: 48.8621453, lng: 17.7077686 },
    { lat: 48.8622064, lng: 17.7076056 },
    { lat: 48.8622791, lng: 17.7074108 },
    { lat: 48.8623441, lng: 17.7072374 },
    { lat: 48.8623924, lng: 17.7071056 },
    { lat: 48.8624378, lng: 17.7069808 },
    { lat: 48.8624636, lng: 17.7068504 },
    { lat: 48.8624882, lng: 17.7067173 },
    { lat: 48.8625236, lng: 17.7065609 },
    { lat: 48.8625607, lng: 17.7063567 },
    { lat: 48.8625961, lng: 17.7061788 },
    { lat: 48.8626387, lng: 17.7059908 },
    { lat: 48.862633, lng: 17.705983 },
    { lat: 48.8625287, lng: 17.7058414 },
    { lat: 48.8624472, lng: 17.7057307 },
    { lat: 48.8623636, lng: 17.7056171 },
    { lat: 48.8623517, lng: 17.7056009 },
    { lat: 48.862351, lng: 17.7056 },
    { lat: 48.8622232, lng: 17.7054219 },
    { lat: 48.8621328, lng: 17.705296 },
    { lat: 48.8620779, lng: 17.7052194 },
    { lat: 48.8620138, lng: 17.7051327 },
    { lat: 48.8619613, lng: 17.7050616 },
    { lat: 48.8619069, lng: 17.7049878 },
    { lat: 48.861907, lng: 17.704988 },
    { lat: 48.8618694, lng: 17.7049383 },
    { lat: 48.8616776, lng: 17.7046848 },
    { lat: 48.861677, lng: 17.704684 },
    { lat: 48.8613508, lng: 17.704249 },
    { lat: 48.861351, lng: 17.704249 },
    { lat: 48.8612222, lng: 17.7041019 },
    { lat: 48.8611292, lng: 17.7039957 },
    { lat: 48.8610493, lng: 17.7039044 },
    { lat: 48.860999, lng: 17.703847 },
    { lat: 48.86085, lng: 17.703827 },
    { lat: 48.8607452, lng: 17.7038129 },
    { lat: 48.8606757, lng: 17.7038035 },
    { lat: 48.860605, lng: 17.703794 },
    { lat: 48.8605203, lng: 17.703721 },
    { lat: 48.8604379, lng: 17.7036498 },
    { lat: 48.8603414, lng: 17.7035666 },
    { lat: 48.8603274, lng: 17.7035545 },
    { lat: 48.8601895, lng: 17.703454 },
    { lat: 48.8601214, lng: 17.7034043 },
    { lat: 48.860121, lng: 17.703404 },
    { lat: 48.860022, lng: 17.703336 },
    { lat: 48.8600644, lng: 17.703176 },
    { lat: 48.860115, lng: 17.7029848 },
    { lat: 48.8598966, lng: 17.7028108 },
    { lat: 48.8592922, lng: 17.7023301 },
    { lat: 48.8578806, lng: 17.701264 },
    { lat: 48.8578545, lng: 17.7012447 },
    { lat: 48.8511637, lng: 17.6961619 },
    { lat: 48.8511318, lng: 17.6961378 },
    { lat: 48.8511586, lng: 17.6960967 },
    { lat: 48.8511582, lng: 17.696072 },
    { lat: 48.8509604, lng: 17.6963587 },
    { lat: 48.8509138, lng: 17.6963551 },
    { lat: 48.8508092, lng: 17.6962882 },
    { lat: 48.8507375, lng: 17.696278 },
    { lat: 48.8506565, lng: 17.6963382 },
    { lat: 48.8504281, lng: 17.6966438 },
    { lat: 48.8503862, lng: 17.6967327 },
    { lat: 48.8502502, lng: 17.6967624 },
    { lat: 48.8501377, lng: 17.6969512 },
    { lat: 48.8501163, lng: 17.6970243 },
    { lat: 48.8499953, lng: 17.6970936 },
    { lat: 48.8499542, lng: 17.6970874 },
    { lat: 48.8498703, lng: 17.6971375 },
    { lat: 48.8496758, lng: 17.697387 },
    { lat: 48.8496331, lng: 17.6974081 },
    { lat: 48.8494188, lng: 17.6974127 },
    { lat: 48.8492632, lng: 17.6975977 },
    { lat: 48.8491599, lng: 17.6976993 },
    { lat: 48.8491066, lng: 17.6977886 },
    { lat: 48.8488837, lng: 17.6979082 },
    { lat: 48.8488081, lng: 17.6979634 },
    { lat: 48.8487472, lng: 17.6979827 },
    { lat: 48.848645, lng: 17.6979913 },
    { lat: 48.8485549, lng: 17.6979767 },
    { lat: 48.8483939, lng: 17.6980569 },
    { lat: 48.8483632, lng: 17.6980178 },
    { lat: 48.8482785, lng: 17.6979979 },
    { lat: 48.8482172, lng: 17.6980677 },
    { lat: 48.8481484, lng: 17.6981047 },
    { lat: 48.8480581, lng: 17.6981228 },
    { lat: 48.8478275, lng: 17.698303 },
    { lat: 48.8476335, lng: 17.6984323 },
    { lat: 48.8475081, lng: 17.6984312 },
    { lat: 48.847418, lng: 17.6983992 },
    { lat: 48.8473618, lng: 17.6984384 },
    { lat: 48.8471849, lng: 17.6984441 },
    { lat: 48.8470104, lng: 17.6986178 },
    { lat: 48.8468774, lng: 17.6988048 },
    { lat: 48.8468347, lng: 17.6988065 },
    { lat: 48.8467298, lng: 17.6989551 },
    { lat: 48.8466763, lng: 17.6989491 },
    { lat: 48.8466039, lng: 17.698964 },
    { lat: 48.8464747, lng: 17.6990838 },
    { lat: 48.8464307, lng: 17.6991755 },
    { lat: 48.8463954, lng: 17.6992088 },
    { lat: 48.846258, lng: 17.699286 },
    { lat: 48.8462449, lng: 17.6992567 },
    { lat: 48.8461714, lng: 17.6995121 },
    { lat: 48.8459879, lng: 17.6996656 },
    { lat: 48.8459709, lng: 17.6997741 },
    { lat: 48.8458308, lng: 17.699994 },
    { lat: 48.8457802, lng: 17.7001782 },
    { lat: 48.84578, lng: 17.7004315 },
    { lat: 48.8457254, lng: 17.700571 },
    { lat: 48.8456516, lng: 17.700711 },
    { lat: 48.8456117, lng: 17.7008325 },
    { lat: 48.8455501, lng: 17.7010952 },
    { lat: 48.8455644, lng: 17.7012548 },
    { lat: 48.845496, lng: 17.7014274 },
    { lat: 48.8453952, lng: 17.7016236 },
    { lat: 48.845254, lng: 17.7021166 },
    { lat: 48.8452573, lng: 17.702297 },
    { lat: 48.8452414, lng: 17.7023523 },
    { lat: 48.8452155, lng: 17.7023709 },
    { lat: 48.845163, lng: 17.7025801 },
    { lat: 48.8451865, lng: 17.7025949 },
    { lat: 48.8451465, lng: 17.702716 },
    { lat: 48.8450204, lng: 17.7030708 },
    { lat: 48.8450051, lng: 17.7030965 },
    { lat: 48.8449491, lng: 17.703341 },
    { lat: 48.8448837, lng: 17.7035811 },
    { lat: 48.8448437, lng: 17.7037378 },
    { lat: 48.8447236, lng: 17.7039862 },
    { lat: 48.8446061, lng: 17.7042047 },
    { lat: 48.844581, lng: 17.7042552 },
    { lat: 48.8442838, lng: 17.7047267 },
    { lat: 48.8440637, lng: 17.7050931 },
    { lat: 48.8438586, lng: 17.705301 },
    { lat: 48.8437206, lng: 17.7054249 },
    { lat: 48.8435949, lng: 17.7055108 },
    { lat: 48.8433844, lng: 17.705685 },
    { lat: 48.8432954, lng: 17.7057446 },
    { lat: 48.8430315, lng: 17.7058936 },
    { lat: 48.8427913, lng: 17.7060269 },
    { lat: 48.8427027, lng: 17.7060565 },
    { lat: 48.8425249, lng: 17.7060829 },
    { lat: 48.8422893, lng: 17.7060154 },
    { lat: 48.8420358, lng: 17.7059705 },
    { lat: 48.8417372, lng: 17.7058341 },
    { lat: 48.8415057, lng: 17.705686 },
    { lat: 48.8414176, lng: 17.7056125 },
    { lat: 48.8413083, lng: 17.7055595 },
    { lat: 48.8410439, lng: 17.7055222 },
    { lat: 48.8410071, lng: 17.7056188 },
    { lat: 48.8408941, lng: 17.7059128 },
    { lat: 48.8407492, lng: 17.7062147 },
    { lat: 48.8406808, lng: 17.7065529 },
    { lat: 48.8406449, lng: 17.7066666 },
    { lat: 48.8405287, lng: 17.7068151 },
    { lat: 48.8404662, lng: 17.706972 },
    { lat: 48.8403548, lng: 17.7071982 },
    { lat: 48.8403026, lng: 17.707267 },
    { lat: 48.8402271, lng: 17.7073791 },
    { lat: 48.8401261, lng: 17.7074793 },
    { lat: 48.8400427, lng: 17.7076293 },
    { lat: 48.8399668, lng: 17.7077717 },
    { lat: 48.8398025, lng: 17.7081097 },
    { lat: 48.8397494, lng: 17.7082387 },
    { lat: 48.8395689, lng: 17.708479 },
    { lat: 48.8394049, lng: 17.7086932 },
    { lat: 48.8393896, lng: 17.7088117 },
    { lat: 48.8392808, lng: 17.7090776 },
    { lat: 48.8392521, lng: 17.7091263 },
    { lat: 48.8391241, lng: 17.7093401 },
    { lat: 48.8390724, lng: 17.7095976 },
    { lat: 48.8390276, lng: 17.7096964 },
    { lat: 48.838879, lng: 17.7098684 },
    { lat: 48.8387862, lng: 17.7099204 },
    { lat: 48.8387201, lng: 17.7099469 },
    { lat: 48.8386287, lng: 17.7101851 },
    { lat: 48.8384938, lng: 17.7104519 },
    { lat: 48.8384263, lng: 17.7107845 },
    { lat: 48.8383916, lng: 17.710881 },
    { lat: 48.8383552, lng: 17.7109494 },
    { lat: 48.8382833, lng: 17.7110845 },
    { lat: 48.8381953, lng: 17.7113048 },
    { lat: 48.8381179, lng: 17.7114599 },
    { lat: 48.8379946, lng: 17.7116936 },
    { lat: 48.8378279, lng: 17.7121572 },
    { lat: 48.8376304, lng: 17.7125254 },
    { lat: 48.8376009, lng: 17.7125968 },
    { lat: 48.8374942, lng: 17.7128429 },
    { lat: 48.837419, lng: 17.7130352 },
    { lat: 48.8372946, lng: 17.7132867 },
    { lat: 48.8371867, lng: 17.7134225 },
    { lat: 48.8371589, lng: 17.7134302 },
    { lat: 48.8370458, lng: 17.7136464 },
    { lat: 48.8367406, lng: 17.7142257 },
    { lat: 48.8367227, lng: 17.7144121 },
    { lat: 48.8366697, lng: 17.7146321 },
    { lat: 48.8366565, lng: 17.7147127 },
    { lat: 48.8365196, lng: 17.7150548 },
    { lat: 48.8363366, lng: 17.7155489 },
    { lat: 48.8362479, lng: 17.7158496 },
    { lat: 48.8360855, lng: 17.7162553 },
    { lat: 48.8359606, lng: 17.7165192 },
    { lat: 48.8357524, lng: 17.71675 },
    { lat: 48.8354558, lng: 17.7170301 },
    { lat: 48.8353224, lng: 17.7171008 },
    { lat: 48.8349484, lng: 17.7174255 },
    { lat: 48.8345641, lng: 17.7178583 },
    { lat: 48.8341762, lng: 17.7173538 },
    { lat: 48.834147, lng: 17.7173191 },
    { lat: 48.8341344, lng: 17.717299 },
    { lat: 48.8340448, lng: 17.7174015 },
    { lat: 48.8339957, lng: 17.7174281 },
    { lat: 48.833958, lng: 17.7174411 },
    { lat: 48.833754, lng: 17.7175008 },
    { lat: 48.8337036, lng: 17.7175622 },
    { lat: 48.8335763, lng: 17.7176404 },
    { lat: 48.8333641, lng: 17.7178431 },
    { lat: 48.8332482, lng: 17.7178687 },
    { lat: 48.8331033, lng: 17.7179719 },
    { lat: 48.8330395, lng: 17.7180537 },
    { lat: 48.8329939, lng: 17.7181376 },
    { lat: 48.8329151, lng: 17.7183051 },
    { lat: 48.8328939, lng: 17.7183108 },
    { lat: 48.8328202, lng: 17.718342 },
    { lat: 48.8326954, lng: 17.7183874 },
    { lat: 48.8325864, lng: 17.7183973 },
    { lat: 48.8324799, lng: 17.7184274 },
    { lat: 48.8324145, lng: 17.7184459 },
    { lat: 48.8323861, lng: 17.7184446 },
    { lat: 48.8323548, lng: 17.7184297 },
    { lat: 48.8323016, lng: 17.7184286 },
    { lat: 48.8322608, lng: 17.7184241 },
    { lat: 48.8322057, lng: 17.7183927 },
    { lat: 48.8321942, lng: 17.7185362 },
    { lat: 48.8321678, lng: 17.7186926 },
    { lat: 48.8321112, lng: 17.7188194 },
    { lat: 48.8320455, lng: 17.7189616 },
    { lat: 48.8319791, lng: 17.7191138 },
    { lat: 48.8318704, lng: 17.7192541 },
    { lat: 48.8318312, lng: 17.7192955 },
    { lat: 48.8317901, lng: 17.7193616 },
    { lat: 48.8317289, lng: 17.7193929 },
    { lat: 48.8316629, lng: 17.7194219 },
    { lat: 48.8315839, lng: 17.7193128 },
    { lat: 48.8315433, lng: 17.7192032 },
    { lat: 48.8314651, lng: 17.7191445 },
    { lat: 48.8314065, lng: 17.7191281 },
    { lat: 48.8314061, lng: 17.7191584 },
    { lat: 48.8314084, lng: 17.719324 },
    { lat: 48.831385, lng: 17.7193474 },
    { lat: 48.8313307, lng: 17.7194013 },
    { lat: 48.8312577, lng: 17.7195159 },
    { lat: 48.8311886, lng: 17.7196402 },
    { lat: 48.8310586, lng: 17.7198571 },
    { lat: 48.8309914, lng: 17.7199764 },
    { lat: 48.8308648, lng: 17.7201951 },
    { lat: 48.8308065, lng: 17.7203295 },
    { lat: 48.830543, lng: 17.7204882 },
    { lat: 48.8301676, lng: 17.7208806 },
    { lat: 48.8301007, lng: 17.7208944 },
    { lat: 48.8300069, lng: 17.7209666 },
    { lat: 48.8297673, lng: 17.7210723 },
    { lat: 48.8295392, lng: 17.7210913 },
    { lat: 48.8292107, lng: 17.7212006 },
    { lat: 48.8286647, lng: 17.7210623 },
    { lat: 48.8283615, lng: 17.7209421 },
    { lat: 48.8282932, lng: 17.720915 },
    { lat: 48.8281104, lng: 17.7208425 },
    { lat: 48.8278796, lng: 17.720706 },
    { lat: 48.827769, lng: 17.7205976 },
    { lat: 48.8276775, lng: 17.7204129 },
    { lat: 48.8275133, lng: 17.7201117 },
    { lat: 48.8272933, lng: 17.7200777 },
    { lat: 48.8272254, lng: 17.7200558 },
    { lat: 48.827178, lng: 17.7200403 },
    { lat: 48.8267454, lng: 17.7198534 },
    { lat: 48.8266063, lng: 17.7197744 },
    { lat: 48.8263236, lng: 17.719456 },
    { lat: 48.8261875, lng: 17.7192636 },
    { lat: 48.8261288, lng: 17.7191899 },
    { lat: 48.826068, lng: 17.7191186 },
    { lat: 48.8256991, lng: 17.7186837 },
    { lat: 48.8256842, lng: 17.7186987 },
    { lat: 48.825666, lng: 17.7187149 },
    { lat: 48.8255836, lng: 17.7187889 },
    { lat: 48.8253813, lng: 17.7189519 },
    { lat: 48.8250725, lng: 17.7191245 },
    { lat: 48.8248015, lng: 17.7192337 },
    { lat: 48.8247088, lng: 17.719269 },
    { lat: 48.8246316, lng: 17.7193169 },
    { lat: 48.8244746, lng: 17.7194433 },
    { lat: 48.8242099, lng: 17.719729 },
    { lat: 48.8242059, lng: 17.7199215 },
    { lat: 48.8240726, lng: 17.7201943 },
    { lat: 48.8239331, lng: 17.7204226 },
    { lat: 48.8239038, lng: 17.7204609 },
    { lat: 48.8238959, lng: 17.7204984 },
    { lat: 48.8239595, lng: 17.7206673 },
    { lat: 48.8239865, lng: 17.7207395 },
    { lat: 48.8240459, lng: 17.7208611 },
    { lat: 48.8241091, lng: 17.7210074 },
    { lat: 48.8241568, lng: 17.7211404 },
    { lat: 48.8240778, lng: 17.7212506 },
    { lat: 48.8240047, lng: 17.721346 },
    { lat: 48.8238828, lng: 17.7215116 },
    { lat: 48.8237509, lng: 17.7216819 },
    { lat: 48.8237738, lng: 17.7220345 },
    { lat: 48.8236522, lng: 17.7220943 },
    { lat: 48.823556, lng: 17.7221443 },
    { lat: 48.8233583, lng: 17.7222542 },
    { lat: 48.8231517, lng: 17.7223642 },
    { lat: 48.8230493, lng: 17.7224249 },
    { lat: 48.8229434, lng: 17.7224834 },
    { lat: 48.8229596, lng: 17.7229098 },
    { lat: 48.8230131, lng: 17.7235042 },
    { lat: 48.8229936, lng: 17.7235377 },
    { lat: 48.8230545, lng: 17.7235915 },
    { lat: 48.8230205, lng: 17.7238455 },
    { lat: 48.8229434, lng: 17.724006 },
    { lat: 48.822864, lng: 17.7240535 },
    { lat: 48.8224427, lng: 17.7243995 },
    { lat: 48.8221415, lng: 17.7246048 },
    { lat: 48.8220557, lng: 17.7246798 },
    { lat: 48.8218973, lng: 17.72473 },
    { lat: 48.8214974, lng: 17.7254029 },
    { lat: 48.821405, lng: 17.7254628 },
    { lat: 48.8212313, lng: 17.7252613 },
    { lat: 48.821031, lng: 17.7248881 },
    { lat: 48.8205144, lng: 17.7242178 },
    { lat: 48.8201919, lng: 17.7240406 },
    { lat: 48.8201196, lng: 17.723819 },
    { lat: 48.8199518, lng: 17.7238637 },
    { lat: 48.8198501, lng: 17.7238435 },
    { lat: 48.8195998, lng: 17.7238125 },
    { lat: 48.8194279, lng: 17.723827 },
    { lat: 48.8191449, lng: 17.7239067 },
    { lat: 48.8191847, lng: 17.7239473 },
    { lat: 48.8192234, lng: 17.7240076 },
    { lat: 48.8192371, lng: 17.7240481 },
    { lat: 48.8192538, lng: 17.7240982 },
    { lat: 48.8192694, lng: 17.7241687 },
    { lat: 48.8192787, lng: 17.72432 },
    { lat: 48.8191538, lng: 17.7243448 },
    { lat: 48.8189956, lng: 17.7244702 },
    { lat: 48.8186795, lng: 17.7244541 },
    { lat: 48.8179311, lng: 17.7248949 },
    { lat: 48.81749, lng: 17.7249192 },
    { lat: 48.8174202, lng: 17.7249038 },
    { lat: 48.8173054, lng: 17.7249841 },
    { lat: 48.8171371, lng: 17.7250745 },
    { lat: 48.8171296, lng: 17.7250995 },
    { lat: 48.8170149, lng: 17.7252703 },
    { lat: 48.8164043, lng: 17.7257518 },
    { lat: 48.816352, lng: 17.7257092 },
    { lat: 48.8158391, lng: 17.7255013 },
    { lat: 48.8157845, lng: 17.725568 },
    { lat: 48.8156751, lng: 17.7257715 },
    { lat: 48.815477, lng: 17.7262786 },
    { lat: 48.8153085, lng: 17.726549 },
    { lat: 48.814942, lng: 17.7275273 },
    { lat: 48.8148816, lng: 17.7279401 },
    { lat: 48.8148368, lng: 17.7280365 },
    { lat: 48.814815, lng: 17.7281225 },
    { lat: 48.8147018, lng: 17.7283013 },
    { lat: 48.8147015, lng: 17.7283164 },
    { lat: 48.8141188, lng: 17.7288281 },
    { lat: 48.8138304, lng: 17.7291867 },
    { lat: 48.8136452, lng: 17.7293873 },
    { lat: 48.8135325, lng: 17.7296113 },
    { lat: 48.81347, lng: 17.7298234 },
    { lat: 48.8134335, lng: 17.7301304 },
    { lat: 48.8133748, lng: 17.7303878 },
    { lat: 48.8131167, lng: 17.7307636 },
    { lat: 48.8129383, lng: 17.7309824 },
    { lat: 48.8128283, lng: 17.731306 },
    { lat: 48.8127769, lng: 17.7317539 },
    { lat: 48.8126913, lng: 17.7321976 },
    { lat: 48.8125734, lng: 17.7326668 },
    { lat: 48.8124488, lng: 17.733101 },
    { lat: 48.8124357, lng: 17.7331263 },
    { lat: 48.8123439, lng: 17.733158 },
    { lat: 48.812001, lng: 17.7334757 },
    { lat: 48.8112874, lng: 17.7342263 },
    { lat: 48.8110589, lng: 17.7344414 },
    { lat: 48.8105994, lng: 17.7349369 },
    { lat: 48.8105216, lng: 17.7350487 },
    { lat: 48.8103642, lng: 17.7351321 },
    { lat: 48.8102103, lng: 17.7351998 },
    { lat: 48.8099746, lng: 17.7353699 },
    { lat: 48.8097628, lng: 17.7356347 },
    { lat: 48.8097666, lng: 17.7356598 },
    { lat: 48.8097704, lng: 17.7356849 },
    { lat: 48.8097833, lng: 17.7357101 },
    { lat: 48.8097676, lng: 17.7357854 },
    { lat: 48.809748, lng: 17.7358514 },
    { lat: 48.8092378, lng: 17.7378002 },
    { lat: 48.8092192, lng: 17.7378655 },
    { lat: 48.8090879, lng: 17.7379332 },
    { lat: 48.8064984, lng: 17.7392461 },
    { lat: 48.8062526, lng: 17.7393962 },
    { lat: 48.8061738, lng: 17.7394025 },
    { lat: 48.8059046, lng: 17.7395584 },
    { lat: 48.8058289, lng: 17.7395597 },
    { lat: 48.8057701, lng: 17.7396107 },
    { lat: 48.8057407, lng: 17.7396114 },
    { lat: 48.805689, lng: 17.7396525 },
    { lat: 48.8053004, lng: 17.73974 },
    { lat: 48.8051523, lng: 17.7397374 },
    { lat: 48.8048635, lng: 17.7398682 },
    { lat: 48.8046313, lng: 17.7400232 },
    { lat: 48.8044965, lng: 17.7401263 },
    { lat: 48.8040386, lng: 17.7404458 },
    { lat: 48.8039661, lng: 17.7404424 },
    { lat: 48.8037997, lng: 17.7406009 },
    { lat: 48.8036219, lng: 17.7406999 },
    { lat: 48.8035151, lng: 17.7408175 },
    { lat: 48.8033731, lng: 17.7409156 },
    { lat: 48.803256, lng: 17.7410483 },
    { lat: 48.8031742, lng: 17.7410397 },
    { lat: 48.8031149, lng: 17.7411417 },
    { lat: 48.8028978, lng: 17.7412094 },
    { lat: 48.8027695, lng: 17.7414001 },
    { lat: 48.8025583, lng: 17.7415811 },
    { lat: 48.8024498, lng: 17.7416911 },
    { lat: 48.8024162, lng: 17.7416212 },
    { lat: 48.8023347, lng: 17.7414147 },
    { lat: 48.8021067, lng: 17.7410683 },
    { lat: 48.8019906, lng: 17.7409231 },
    { lat: 48.8019809, lng: 17.7408945 },
    { lat: 48.801886, lng: 17.7408382 },
    { lat: 48.8016271, lng: 17.7405751 },
    { lat: 48.8015146, lng: 17.7407111 },
    { lat: 48.8014117, lng: 17.7405983 },
    { lat: 48.8013287, lng: 17.740569 },
    { lat: 48.8009423, lng: 17.7409883 },
    { lat: 48.8008234, lng: 17.7408722 },
    { lat: 48.8007081, lng: 17.7407029 },
    { lat: 48.8005687, lng: 17.7409161 },
    { lat: 48.8004174, lng: 17.7411924 },
    { lat: 48.7999353, lng: 17.7420721 },
    { lat: 48.79982, lng: 17.7419978 },
    { lat: 48.79972, lng: 17.741932 },
    { lat: 48.7995585, lng: 17.7417106 },
    { lat: 48.7993621, lng: 17.7414174 },
    { lat: 48.7993518, lng: 17.7414339 },
    { lat: 48.7991723, lng: 17.741654 },
    { lat: 48.7989309, lng: 17.7419152 },
    { lat: 48.7984241, lng: 17.7425232 },
    { lat: 48.7981376, lng: 17.7428951 },
    { lat: 48.7977848, lng: 17.7433822 },
    { lat: 48.7974389, lng: 17.7438547 },
    { lat: 48.7963188, lng: 17.7455426 },
    { lat: 48.7956833, lng: 17.7464821 },
    { lat: 48.7956474, lng: 17.7464317 },
    { lat: 48.7955712, lng: 17.7463117 },
    { lat: 48.7949066, lng: 17.74709 },
    { lat: 48.7946562, lng: 17.7474067 },
    { lat: 48.7945111, lng: 17.7475626 },
    { lat: 48.7943232, lng: 17.7477937 },
    { lat: 48.7943238, lng: 17.7478389 },
    { lat: 48.7942481, lng: 17.7479495 },
    { lat: 48.7938853, lng: 17.7484015 },
    { lat: 48.7935432, lng: 17.7487535 },
    { lat: 48.7932595, lng: 17.7490048 },
    { lat: 48.7931526, lng: 17.7490852 },
    { lat: 48.7930293, lng: 17.7489897 },
    { lat: 48.7927961, lng: 17.7489598 },
    { lat: 48.7927629, lng: 17.7488238 },
    { lat: 48.792584, lng: 17.7487789 },
    { lat: 48.792538, lng: 17.7487287 },
    { lat: 48.7924625, lng: 17.7487491 },
    { lat: 48.7922546, lng: 17.7486736 },
    { lat: 48.792143, lng: 17.748694 },
    { lat: 48.7921293, lng: 17.7486724 },
    { lat: 48.7921133, lng: 17.7486503 },
    { lat: 48.7920577, lng: 17.7485731 },
    { lat: 48.7920672, lng: 17.7485634 },
    { lat: 48.7920801, lng: 17.7485345 },
    { lat: 48.7920902, lng: 17.7485131 },
    { lat: 48.7920347, lng: 17.7484376 },
    { lat: 48.7917414, lng: 17.7480359 },
    { lat: 48.7912198, lng: 17.7472825 },
    { lat: 48.7913129, lng: 17.7467748 },
    { lat: 48.7907288, lng: 17.7465493 },
    { lat: 48.7902682, lng: 17.7462933 },
    { lat: 48.7900667, lng: 17.7461731 },
    { lat: 48.7897477, lng: 17.7469732 },
    { lat: 48.7896542, lng: 17.7469193 },
    { lat: 48.7893278, lng: 17.7467165 },
    { lat: 48.7892494, lng: 17.7466704 },
    { lat: 48.7891803, lng: 17.7466305 },
    { lat: 48.789001, lng: 17.7465251 },
    { lat: 48.7890034, lng: 17.7465999 },
    { lat: 48.7890009, lng: 17.7467791 },
    { lat: 48.7889627, lng: 17.7471687 },
    { lat: 48.7887886, lng: 17.7476792 },
    { lat: 48.788697, lng: 17.748138 },
    { lat: 48.7886867, lng: 17.7482821 },
    { lat: 48.7886853, lng: 17.748425 },
    { lat: 48.7886822, lng: 17.7484477 },
    { lat: 48.7886739, lng: 17.7485145 },
    { lat: 48.7884905, lng: 17.7486173 },
    { lat: 48.7884056, lng: 17.7486872 },
    { lat: 48.7883783, lng: 17.7487381 },
    { lat: 48.7883335, lng: 17.7488366 },
    { lat: 48.7882695, lng: 17.7489532 },
    { lat: 48.7882236, lng: 17.7490148 },
    { lat: 48.7881566, lng: 17.7490636 },
    { lat: 48.7880626, lng: 17.7491139 },
    { lat: 48.7880608, lng: 17.7490655 },
    { lat: 48.7880592, lng: 17.7490209 },
    { lat: 48.7880565, lng: 17.7489769 },
    { lat: 48.7880501, lng: 17.7488552 },
    { lat: 48.7880456, lng: 17.7487446 },
    { lat: 48.7880415, lng: 17.7486043 },
    { lat: 48.788031, lng: 17.7484341 },
    { lat: 48.7879828, lng: 17.7482196 },
    { lat: 48.7879563, lng: 17.7480995 },
    { lat: 48.7879179, lng: 17.7479148 },
    { lat: 48.7876278, lng: 17.7480995 },
    { lat: 48.7872777, lng: 17.7483009 },
    { lat: 48.7871144, lng: 17.7483636 },
    { lat: 48.7868667, lng: 17.748538 },
    { lat: 48.7865879, lng: 17.7486678 },
    { lat: 48.786543, lng: 17.7487087 },
    { lat: 48.7862906, lng: 17.7488829 },
    { lat: 48.7862599, lng: 17.748678 },
    { lat: 48.7861983, lng: 17.748298 },
    { lat: 48.7861598, lng: 17.7480227 },
    { lat: 48.7861359, lng: 17.7478523 },
    { lat: 48.7853629, lng: 17.7481908 },
    { lat: 48.7852038, lng: 17.7483938 },
    { lat: 48.7849814, lng: 17.7485779 },
    { lat: 48.7848116, lng: 17.7486809 },
    { lat: 48.7845818, lng: 17.748855 },
    { lat: 48.7845558, lng: 17.7487801 },
    { lat: 48.7836387, lng: 17.7497124 },
    { lat: 48.7835756, lng: 17.7497336 },
    { lat: 48.7835136, lng: 17.7497346 },
    { lat: 48.783417, lng: 17.7513055 },
    { lat: 48.7833675, lng: 17.7512915 },
    { lat: 48.7833244, lng: 17.7512873 },
    { lat: 48.7831879, lng: 17.7515102 },
    { lat: 48.7830037, lng: 17.7511017 },
    { lat: 48.7826862, lng: 17.7506397 },
    { lat: 48.7826404, lng: 17.7505912 },
    { lat: 48.7826018, lng: 17.7505317 },
    { lat: 48.782481, lng: 17.7503687 },
    { lat: 48.7822357, lng: 17.7500313 },
    { lat: 48.7817489, lng: 17.749403 },
    { lat: 48.7814934, lng: 17.7490124 },
    { lat: 48.7811814, lng: 17.7484527 },
    { lat: 48.7810958, lng: 17.7483268 },
    { lat: 48.7807554, lng: 17.7479511 },
    { lat: 48.7805882, lng: 17.7477469 },
    { lat: 48.7805674, lng: 17.7476469 },
    { lat: 48.7805445, lng: 17.7475498 },
    { lat: 48.7805238, lng: 17.7474519 },
    { lat: 48.7805062, lng: 17.7473672 },
    { lat: 48.7804906, lng: 17.7472797 },
    { lat: 48.7804695, lng: 17.7471921 },
    { lat: 48.7804272, lng: 17.7469999 },
    { lat: 48.7803894, lng: 17.7468075 },
    { lat: 48.7803389, lng: 17.7465905 },
    { lat: 48.7802381, lng: 17.7464571 },
    { lat: 48.7799093, lng: 17.7460307 },
    { lat: 48.7798144, lng: 17.7459207 },
    { lat: 48.7797227, lng: 17.7458246 },
    { lat: 48.7796643, lng: 17.7457555 },
    { lat: 48.7797291, lng: 17.7456336 },
    { lat: 48.7797969, lng: 17.7455063 },
    { lat: 48.7798331, lng: 17.7454328 },
    { lat: 48.7798616, lng: 17.7453644 },
    { lat: 48.7798973, lng: 17.7452854 },
    { lat: 48.7799301, lng: 17.7452121 },
    { lat: 48.779988, lng: 17.7450697 },
    { lat: 48.7800136, lng: 17.745009 },
    { lat: 48.7800442, lng: 17.7449381 },
    { lat: 48.7800737, lng: 17.7448647 },
    { lat: 48.7800976, lng: 17.7448139 },
    { lat: 48.7801485, lng: 17.7446872 },
    { lat: 48.7801767, lng: 17.7446311 },
    { lat: 48.7802033, lng: 17.7445676 },
    { lat: 48.7802724, lng: 17.744443 },
    { lat: 48.7802913, lng: 17.7444024 },
    { lat: 48.7803074, lng: 17.7443693 },
    { lat: 48.7803416, lng: 17.7443008 },
    { lat: 48.7804485, lng: 17.7440949 },
    { lat: 48.7801329, lng: 17.7436605 },
    { lat: 48.7799396, lng: 17.7434191 },
    { lat: 48.7797757, lng: 17.7431769 },
    { lat: 48.7797475, lng: 17.7431391 },
    { lat: 48.7797288, lng: 17.7431119 },
    { lat: 48.7796805, lng: 17.7431881 },
    { lat: 48.7793892, lng: 17.7426556 },
    { lat: 48.7793317, lng: 17.7425463 },
    { lat: 48.779199, lng: 17.742316 },
    { lat: 48.7789918, lng: 17.742012 },
    { lat: 48.7790094, lng: 17.7419689 },
    { lat: 48.7790361, lng: 17.7419254 },
    { lat: 48.7786471, lng: 17.7413272 },
    { lat: 48.7786132, lng: 17.7412726 },
    { lat: 48.7785994, lng: 17.7412502 },
    { lat: 48.778505, lng: 17.7412579 },
    { lat: 48.7784792, lng: 17.7412055 },
    { lat: 48.7781513, lng: 17.7405356 },
    { lat: 48.77815, lng: 17.7404951 },
    { lat: 48.7781306, lng: 17.7404556 },
    { lat: 48.7780862, lng: 17.7403586 },
    { lat: 48.7780538, lng: 17.7402892 },
    { lat: 48.7778682, lng: 17.7399518 },
    { lat: 48.7776976, lng: 17.7396748 },
    { lat: 48.7776149, lng: 17.7394858 },
    { lat: 48.77749, lng: 17.7392927 },
    { lat: 48.7772505, lng: 17.7388115 },
    { lat: 48.777171, lng: 17.7387303 },
    { lat: 48.7768601, lng: 17.7381924 },
    { lat: 48.7766162, lng: 17.7384473 },
    { lat: 48.7762243, lng: 17.738979 },
    { lat: 48.7761594, lng: 17.7390803 },
    { lat: 48.7759121, lng: 17.7394661 },
    { lat: 48.7758667, lng: 17.7393508 },
    { lat: 48.7758387, lng: 17.7392823 },
    { lat: 48.7753503, lng: 17.7399667 },
    { lat: 48.7752463, lng: 17.7401501 },
    { lat: 48.7751378, lng: 17.740349 },
    { lat: 48.7750113, lng: 17.7406243 },
    { lat: 48.7748369, lng: 17.7409089 },
    { lat: 48.7748007, lng: 17.7407973 },
    { lat: 48.7747832, lng: 17.7406063 },
    { lat: 48.7747607, lng: 17.7404588 },
    { lat: 48.7746904, lng: 17.7403812 },
    { lat: 48.7746775, lng: 17.7400068 },
    { lat: 48.7746888, lng: 17.7398398 },
    { lat: 48.7746226, lng: 17.7396993 },
    { lat: 48.7746081, lng: 17.7396296 },
    { lat: 48.7745417, lng: 17.739358 },
    { lat: 48.7744561, lng: 17.7391583 },
    { lat: 48.7741366, lng: 17.7383145 },
    { lat: 48.7739717, lng: 17.7380204 },
    { lat: 48.7739918, lng: 17.7379063 },
    { lat: 48.7739548, lng: 17.7377448 },
    { lat: 48.7737621, lng: 17.7375849 },
    { lat: 48.7736657, lng: 17.7372851 },
    { lat: 48.7733867, lng: 17.7369711 },
    { lat: 48.7730819, lng: 17.737193 },
    { lat: 48.7729024, lng: 17.7366964 },
    { lat: 48.7717297, lng: 17.7374915 },
    { lat: 48.7716543, lng: 17.7372936 },
    { lat: 48.7716199, lng: 17.7371746 },
    { lat: 48.7715985, lng: 17.7370829 },
    { lat: 48.7712799, lng: 17.7374283 },
    { lat: 48.7711681, lng: 17.7372828 },
    { lat: 48.7708285, lng: 17.736936 },
    { lat: 48.7705817, lng: 17.7367059 },
    { lat: 48.7702523, lng: 17.7364897 },
    { lat: 48.7700495, lng: 17.7363134 },
    { lat: 48.7697614, lng: 17.7361275 },
    { lat: 48.7694096, lng: 17.7358773 },
    { lat: 48.7690599, lng: 17.7356619 },
    { lat: 48.7686986, lng: 17.7353323 },
    { lat: 48.7683618, lng: 17.7350689 },
    { lat: 48.7683295, lng: 17.7347646 },
    { lat: 48.7683249, lng: 17.7346897 },
    { lat: 48.7679943, lng: 17.7340509 },
    { lat: 48.7678062, lng: 17.7324259 },
    { lat: 48.7677302, lng: 17.7317222 },
    { lat: 48.76766, lng: 17.7310042 },
    { lat: 48.767689, lng: 17.7303741 },
    { lat: 48.7676962, lng: 17.7302364 },
    { lat: 48.7677135, lng: 17.7298562 },
    { lat: 48.7677172, lng: 17.7297869 },
    { lat: 48.7677026, lng: 17.7297718 },
    { lat: 48.7675538, lng: 17.7298125 },
    { lat: 48.7671355, lng: 17.7299491 },
    { lat: 48.7669353, lng: 17.7300533 },
    { lat: 48.7668145, lng: 17.730145 },
    { lat: 48.7666639, lng: 17.7303208 },
    { lat: 48.7664571, lng: 17.7304457 },
    { lat: 48.7658507, lng: 17.7307373 },
    { lat: 48.7656495, lng: 17.730863 },
    { lat: 48.7653025, lng: 17.7310384 },
    { lat: 48.7647746, lng: 17.7312314 },
    { lat: 48.7645141, lng: 17.7313463 },
    { lat: 48.7642805, lng: 17.7313838 },
    { lat: 48.7641109, lng: 17.7313612 },
    { lat: 48.7639147, lng: 17.7313103 },
    { lat: 48.7636772, lng: 17.7313591 },
    { lat: 48.7633547, lng: 17.7314749 },
    { lat: 48.7633327, lng: 17.731522 },
    { lat: 48.7632113, lng: 17.7315672 },
    { lat: 48.7628433, lng: 17.7318432 },
    { lat: 48.762669, lng: 17.7319839 },
    { lat: 48.7625683, lng: 17.7320353 },
    { lat: 48.7625803, lng: 17.7320644 },
    { lat: 48.7624888, lng: 17.7323568 },
    { lat: 48.7624732, lng: 17.7328194 },
    { lat: 48.7624909, lng: 17.7331425 },
    { lat: 48.7623521, lng: 17.7335298 },
    { lat: 48.7620618, lng: 17.7341874 },
    { lat: 48.7614817, lng: 17.7348694 },
    { lat: 48.7613842, lng: 17.7350663 },
    { lat: 48.7613522, lng: 17.7351315 },
    { lat: 48.7611802, lng: 17.7355857 },
    { lat: 48.7611832, lng: 17.7357615 },
    { lat: 48.7611459, lng: 17.7358893 },
    { lat: 48.761067, lng: 17.7360563 },
    { lat: 48.7608255, lng: 17.7372509 },
    { lat: 48.7608141, lng: 17.7373048 },
    { lat: 48.7608029, lng: 17.7373613 },
    { lat: 48.760654, lng: 17.738079 },
    { lat: 48.7606163, lng: 17.7382564 },
    { lat: 48.7604908, lng: 17.7388411 },
    { lat: 48.7604401, lng: 17.7390795 },
    { lat: 48.7602868, lng: 17.7397987 },
    { lat: 48.7602549, lng: 17.7399988 },
    { lat: 48.7599671, lng: 17.7420013 },
    { lat: 48.7594963, lng: 17.7439648 },
    { lat: 48.7593076, lng: 17.7450841 },
    { lat: 48.7590345, lng: 17.7460036 },
    { lat: 48.7587138, lng: 17.7469222 },
    { lat: 48.7587188, lng: 17.7474078 },
    { lat: 48.7585877, lng: 17.7479554 },
    { lat: 48.7585632, lng: 17.7481642 },
    { lat: 48.7585326, lng: 17.7483816 },
    { lat: 48.758545, lng: 17.7484375 },
    { lat: 48.7584419, lng: 17.7486892 },
    { lat: 48.7584342, lng: 17.748839 },
    { lat: 48.7584515, lng: 17.7490103 },
    { lat: 48.7583876, lng: 17.7494952 },
    { lat: 48.7582826, lng: 17.7496065 },
    { lat: 48.758265, lng: 17.749962 },
    { lat: 48.7582936, lng: 17.7500245 },
    { lat: 48.7581469, lng: 17.7502453 },
    { lat: 48.758216, lng: 17.7503056 },
    { lat: 48.758185, lng: 17.7508658 },
    { lat: 48.75829, lng: 17.7509958 },
    { lat: 48.7583022, lng: 17.7511946 },
    { lat: 48.7582022, lng: 17.7514402 },
    { lat: 48.7582235, lng: 17.7515478 },
    { lat: 48.7582285, lng: 17.7516669 },
    { lat: 48.7580822, lng: 17.7519689 },
    { lat: 48.7579559, lng: 17.7520813 },
    { lat: 48.757902, lng: 17.752179 },
    { lat: 48.7578109, lng: 17.7524044 },
    { lat: 48.7576486, lng: 17.7526125 },
    { lat: 48.7574698, lng: 17.7528801 },
    { lat: 48.7564698, lng: 17.7531793 },
    { lat: 48.755398, lng: 17.7535594 },
    { lat: 48.7545095, lng: 17.7540934 },
    { lat: 48.7533755, lng: 17.7550795 },
    { lat: 48.7533097, lng: 17.7552569 },
    { lat: 48.7524137, lng: 17.7568268 },
    { lat: 48.7514728, lng: 17.7584014 },
    { lat: 48.7513764, lng: 17.7584875 },
    { lat: 48.7512957, lng: 17.7588105 },
    { lat: 48.7524161, lng: 17.7590934 },
    { lat: 48.7532024, lng: 17.7592861 },
    { lat: 48.7552728, lng: 17.7601307 },
    { lat: 48.7554245, lng: 17.7601747 },
    { lat: 48.7554556, lng: 17.7601836 },
    { lat: 48.7555452, lng: 17.7602099 },
    { lat: 48.7556051, lng: 17.7602271 },
    { lat: 48.7556924, lng: 17.760252 },
    { lat: 48.7557728, lng: 17.7602756 },
    { lat: 48.7567475, lng: 17.7605599 },
    { lat: 48.757611, lng: 17.7613377 },
    { lat: 48.7578509, lng: 17.7614336 },
    { lat: 48.7578791, lng: 17.7614152 },
    { lat: 48.758124, lng: 17.7614479 },
    { lat: 48.7586415, lng: 17.7619799 },
    { lat: 48.7588805, lng: 17.7622664 },
    { lat: 48.7593463, lng: 17.7624723 },
    { lat: 48.7596047, lng: 17.7625023 },
    { lat: 48.7598495, lng: 17.7627902 },
    { lat: 48.7600919, lng: 17.7630396 },
    { lat: 48.7603674, lng: 17.7637726 },
    { lat: 48.7604108, lng: 17.7639657 },
    { lat: 48.7605937, lng: 17.7644233 },
    { lat: 48.7607645, lng: 17.7647215 },
    { lat: 48.7609436, lng: 17.7653758 },
    { lat: 48.7615484, lng: 17.7657308 },
    { lat: 48.7620961, lng: 17.7662031 },
    { lat: 48.7622773, lng: 17.766432 },
    { lat: 48.7627632, lng: 17.7670214 },
    { lat: 48.7629787, lng: 17.7672186 },
    { lat: 48.7630464, lng: 17.76731 },
    { lat: 48.7632553, lng: 17.7675474 },
    { lat: 48.7633239, lng: 17.7676506 },
    { lat: 48.7634055, lng: 17.7677843 },
    { lat: 48.7636285, lng: 17.7680313 },
    { lat: 48.7638174, lng: 17.7684954 },
    { lat: 48.7639259, lng: 17.7687551 },
    { lat: 48.7648167, lng: 17.7698301 },
    { lat: 48.7652227, lng: 17.7703326 },
    { lat: 48.7655614, lng: 17.770716 },
    { lat: 48.7660458, lng: 17.7710037 },
    { lat: 48.7661965, lng: 17.7710484 },
    { lat: 48.7663032, lng: 17.7710936 },
    { lat: 48.7667595, lng: 17.7714037 },
    { lat: 48.7668914, lng: 17.7715638 },
    { lat: 48.7670626, lng: 17.7716112 },
    { lat: 48.7671552, lng: 17.7715905 },
    { lat: 48.7675459, lng: 17.7715308 },
    { lat: 48.7676107, lng: 17.7715187 },
    { lat: 48.7679333, lng: 17.7717666 },
    { lat: 48.7681483, lng: 17.7717916 },
    { lat: 48.7683434, lng: 17.7719138 },
    { lat: 48.7685636, lng: 17.7720596 },
    { lat: 48.7688176, lng: 17.7720736 },
    { lat: 48.7690983, lng: 17.7722843 },
    { lat: 48.7694383, lng: 17.7731122 },
    { lat: 48.770111, lng: 17.7747071 },
    { lat: 48.7705287, lng: 17.7761213 },
    { lat: 48.7706289, lng: 17.7775185 },
    { lat: 48.7706705, lng: 17.7775166 },
    { lat: 48.770611, lng: 17.7779659 },
    { lat: 48.7704118, lng: 17.778534 },
    { lat: 48.7703059, lng: 17.7790189 },
    { lat: 48.7702875, lng: 17.7791797 },
    { lat: 48.7702804, lng: 17.7793969 },
    { lat: 48.7702613, lng: 17.7796214 },
    { lat: 48.7701834, lng: 17.7799177 },
    { lat: 48.7700402, lng: 17.7803105 },
    { lat: 48.7699507, lng: 17.7804551 },
    { lat: 48.7697278, lng: 17.7807828 },
    { lat: 48.7694015, lng: 17.7813075 },
    { lat: 48.7708483, lng: 17.7845697 },
    { lat: 48.7708757, lng: 17.7846299 },
    { lat: 48.7721392, lng: 17.7874797 },
    { lat: 48.7722076, lng: 17.7876384 },
    { lat: 48.7722284, lng: 17.7880907 },
    { lat: 48.7722859, lng: 17.7889209 },
    { lat: 48.7722999, lng: 17.7894445 },
    { lat: 48.7723297, lng: 17.7895642 },
    { lat: 48.7724064, lng: 17.7897119 },
    { lat: 48.7726993, lng: 17.7900156 },
    { lat: 48.7727871, lng: 17.7901207 },
    { lat: 48.7730162, lng: 17.7905443 },
    { lat: 48.7732819, lng: 17.7909413 },
    { lat: 48.7734211, lng: 17.7911859 },
    { lat: 48.7736706, lng: 17.7915567 },
    { lat: 48.7738593, lng: 17.7917997 },
    { lat: 48.7739342, lng: 17.7919358 },
    { lat: 48.7740045, lng: 17.7920883 },
    { lat: 48.7742599, lng: 17.7924254 },
    { lat: 48.7745367, lng: 17.7929302 },
    { lat: 48.7750079, lng: 17.793472 },
    { lat: 48.7751797, lng: 17.7937162 },
    { lat: 48.7756961, lng: 17.7943149 },
    { lat: 48.7760587, lng: 17.7946712 },
    { lat: 48.7763145, lng: 17.7950326 },
    { lat: 48.7766814, lng: 17.7954589 },
    { lat: 48.7765939, lng: 17.7955052 },
    { lat: 48.7765592, lng: 17.7956398 },
    { lat: 48.7772025, lng: 17.7962854 },
    { lat: 48.7772905, lng: 17.796376 },
    { lat: 48.7770528, lng: 17.797014 },
    { lat: 48.7770638, lng: 17.7973984 },
    { lat: 48.7774989, lng: 17.7979607 },
    { lat: 48.7772936, lng: 17.7984826 },
    { lat: 48.7772553, lng: 17.798891 },
    { lat: 48.7774763, lng: 17.799072 },
    { lat: 48.7785887, lng: 17.7996701 },
    { lat: 48.7786126, lng: 17.7996911 },
    { lat: 48.7781791, lng: 17.8012671 },
    { lat: 48.7778073, lng: 17.8022802 },
    { lat: 48.7776174, lng: 17.8032219 },
    { lat: 48.7775995, lng: 17.8035203 },
    { lat: 48.7776479, lng: 17.8040555 },
    { lat: 48.7781571, lng: 17.8060952 },
    { lat: 48.778494, lng: 17.8067153 },
    { lat: 48.7788331, lng: 17.8076114 },
    { lat: 48.7791716, lng: 17.807375 },
    { lat: 48.7793606, lng: 17.8072243 },
    { lat: 48.7794074, lng: 17.8071589 },
    { lat: 48.77956, lng: 17.806843 },
    { lat: 48.7796694, lng: 17.8066175 },
    { lat: 48.7801563, lng: 17.8056887 },
    { lat: 48.7802707, lng: 17.8054521 },
    { lat: 48.7804373, lng: 17.8051079 },
    { lat: 48.7805698, lng: 17.8048342 },
    { lat: 48.7805421, lng: 17.804733 },
    { lat: 48.7805032, lng: 17.8045947 },
    { lat: 48.7805016, lng: 17.8045856 },
    { lat: 48.7807478, lng: 17.8041554 },
    { lat: 48.7811481, lng: 17.8034576 },
    { lat: 48.7813289, lng: 17.8032895 },
    { lat: 48.7814028, lng: 17.8033331 },
    { lat: 48.7815266, lng: 17.8031782 },
    { lat: 48.7816365, lng: 17.8033316 },
    { lat: 48.7816698, lng: 17.8033762 },
    { lat: 48.7817525, lng: 17.8034909 },
    { lat: 48.7817823, lng: 17.8034597 },
    { lat: 48.7822764, lng: 17.8029467 },
    { lat: 48.7830319, lng: 17.8021978 },
    { lat: 48.7830617, lng: 17.8021681 },
    { lat: 48.7829984, lng: 17.801962 },
    { lat: 48.7829293, lng: 17.8017382 },
    { lat: 48.7829069, lng: 17.8016656 },
    { lat: 48.7828634, lng: 17.8015258 },
    { lat: 48.7827985, lng: 17.8013519 },
    { lat: 48.7827606, lng: 17.8012509 },
    { lat: 48.7827524, lng: 17.8012263 },
    { lat: 48.7827484, lng: 17.801215 },
    { lat: 48.7826179, lng: 17.8008921 },
    { lat: 48.7825835, lng: 17.8008095 },
    { lat: 48.7825807, lng: 17.8008006 },
    { lat: 48.7823111, lng: 17.8001026 },
    { lat: 48.7820367, lng: 17.8002173 },
    { lat: 48.782029, lng: 17.8001842 },
    { lat: 48.7819684, lng: 17.8002102 },
    { lat: 48.7818803, lng: 17.8002481 },
    { lat: 48.7817733, lng: 17.8000862 },
    { lat: 48.7815678, lng: 17.7997741 },
    { lat: 48.7814954, lng: 17.7996632 },
    { lat: 48.7826206, lng: 17.7979694 },
    { lat: 48.7826373, lng: 17.7979466 },
    { lat: 48.7826655, lng: 17.797911 },
    { lat: 48.7832224, lng: 17.7971843 },
    { lat: 48.7832411, lng: 17.7971929 },
    { lat: 48.7844326, lng: 17.7956268 },
    { lat: 48.7844609, lng: 17.7955905 },
    { lat: 48.7844736, lng: 17.7956134 },
    { lat: 48.7845584, lng: 17.7955203 },
    { lat: 48.7847023, lng: 17.795361 },
    { lat: 48.7848526, lng: 17.795195 },
    { lat: 48.7848579, lng: 17.7951896 },
    { lat: 48.7848927, lng: 17.7951865 },
    { lat: 48.7849077, lng: 17.7951712 },
    { lat: 48.7850598, lng: 17.7950164 },
    { lat: 48.7850971, lng: 17.7949797 },
    { lat: 48.7852929, lng: 17.7948351 },
    { lat: 48.7854302, lng: 17.7947343 },
    { lat: 48.7854723, lng: 17.7947037 },
    { lat: 48.7857142, lng: 17.7944082 },
    { lat: 48.7857899, lng: 17.794316 },
    { lat: 48.786014, lng: 17.7940431 },
    { lat: 48.7862529, lng: 17.7937529 },
    { lat: 48.7862843, lng: 17.7937143 },
    { lat: 48.7865045, lng: 17.7934698 },
    { lat: 48.7865711, lng: 17.7933955 },
    { lat: 48.7868305, lng: 17.7931083 },
    { lat: 48.7868982, lng: 17.7930335 },
    { lat: 48.7869172, lng: 17.7930115 },
    { lat: 48.7870855, lng: 17.7928418 },
    { lat: 48.7872504, lng: 17.7926752 },
    { lat: 48.7878677, lng: 17.7920507 },
    { lat: 48.7880344, lng: 17.7918743 },
    { lat: 48.7881103, lng: 17.7917846 },
    { lat: 48.7881862, lng: 17.7916949 },
    { lat: 48.7883275, lng: 17.7915289 },
    { lat: 48.7887945, lng: 17.7909788 },
    { lat: 48.7889568, lng: 17.7907877 },
    { lat: 48.789022, lng: 17.7907081 },
    { lat: 48.7890683, lng: 17.790652 },
    { lat: 48.7891208, lng: 17.7905874 },
    { lat: 48.7891672, lng: 17.7905313 },
    { lat: 48.7892197, lng: 17.7904667 },
    { lat: 48.7892735, lng: 17.7904022 },
    { lat: 48.7895133, lng: 17.7901101 },
    { lat: 48.7900995, lng: 17.7907497 },
    { lat: 48.7901016, lng: 17.7907469 },
    { lat: 48.7901099, lng: 17.7907359 },
    { lat: 48.790258, lng: 17.7905351 },
    { lat: 48.7904179, lng: 17.7903243 },
    { lat: 48.7904647, lng: 17.7902606 },
    { lat: 48.7905752, lng: 17.7901093 },
    { lat: 48.7907405, lng: 17.7898948 },
    { lat: 48.7908114, lng: 17.7897986 },
    { lat: 48.7908625, lng: 17.7897296 },
    { lat: 48.7909129, lng: 17.7896679 },
    { lat: 48.7909934, lng: 17.7895625 },
    { lat: 48.7910761, lng: 17.7894557 },
    { lat: 48.7911586, lng: 17.7893464 },
    { lat: 48.7912422, lng: 17.7892365 },
    { lat: 48.7913249, lng: 17.7891309 },
    { lat: 48.7914514, lng: 17.7889659 },
    { lat: 48.7915079, lng: 17.7888923 },
    { lat: 48.7915204, lng: 17.7890971 },
    { lat: 48.7915728, lng: 17.7893073 },
    { lat: 48.7918025, lng: 17.7898712 },
    { lat: 48.7920452, lng: 17.7903877 },
    { lat: 48.792454, lng: 17.7910573 },
    { lat: 48.7925184, lng: 17.7910384 },
    { lat: 48.7926496, lng: 17.7909692 },
    { lat: 48.7928953, lng: 17.7906483 },
    { lat: 48.7931341, lng: 17.7903258 },
    { lat: 48.7936664, lng: 17.7897225 },
    { lat: 48.794482, lng: 17.7888263 },
    { lat: 48.7947745, lng: 17.7885679 },
    { lat: 48.7953943, lng: 17.7879021 },
    { lat: 48.796036, lng: 17.7872809 },
    { lat: 48.796058, lng: 17.787253 },
    { lat: 48.7972989, lng: 17.7860709 },
    { lat: 48.7973956, lng: 17.7859539 },
    { lat: 48.7976119, lng: 17.7856502 },
    { lat: 48.7983879, lng: 17.7844558 },
    { lat: 48.7987295, lng: 17.7839675 },
    { lat: 48.799551, lng: 17.7828151 },
    { lat: 48.8003411, lng: 17.7818159 },
    { lat: 48.8009587, lng: 17.7810211 },
    { lat: 48.8010338, lng: 17.7809388 },
    { lat: 48.8016694, lng: 17.7800709 },
    { lat: 48.8025125, lng: 17.7790121 },
    { lat: 48.8030431, lng: 17.778329 },
    { lat: 48.8032132, lng: 17.7781399 },
    { lat: 48.8041784, lng: 17.7769365 },
    { lat: 48.8044385, lng: 17.7766301 },
    { lat: 48.8050038, lng: 17.7760894 },
    { lat: 48.8053691, lng: 17.7756937 },
    { lat: 48.8062198, lng: 17.7748537 },
    { lat: 48.8064234, lng: 17.7746026 },
    { lat: 48.8065582, lng: 17.7744068 },
    { lat: 48.8067058, lng: 17.7742165 },
    { lat: 48.809115, lng: 17.7714641 },
    { lat: 48.8091113, lng: 17.7714433 },
    { lat: 48.8090954, lng: 17.771366 },
    { lat: 48.80908, lng: 17.7712981 },
    { lat: 48.8093055, lng: 17.7709798 },
    { lat: 48.8096517, lng: 17.7706244 },
    { lat: 48.8101149, lng: 17.7702044 },
    { lat: 48.8104839, lng: 17.7698688 },
    { lat: 48.8108497, lng: 17.7695368 },
    { lat: 48.8111146, lng: 17.7692539 },
    { lat: 48.8111344, lng: 17.7692268 },
    { lat: 48.8116063, lng: 17.768599 },
    { lat: 48.8118071, lng: 17.7683564 },
    { lat: 48.8126548, lng: 17.7673588 },
  ],
  StaráTurá: [
    { lat: 48.7695644, lng: 17.7202067 },
    { lat: 48.7696439, lng: 17.7201055 },
    { lat: 48.7697125, lng: 17.7200262 },
    { lat: 48.7697891, lng: 17.719915 },
    { lat: 48.7699034, lng: 17.7196977 },
    { lat: 48.7699703, lng: 17.7195567 },
    { lat: 48.770031, lng: 17.7194064 },
    { lat: 48.7700953, lng: 17.7192774 },
    { lat: 48.770133, lng: 17.7192115 },
    { lat: 48.770209, lng: 17.7190889 },
    { lat: 48.7702303, lng: 17.7191038 },
    { lat: 48.770279, lng: 17.7190704 },
    { lat: 48.7703909, lng: 17.719 },
    { lat: 48.7704758, lng: 17.7189474 },
    { lat: 48.7705234, lng: 17.718824 },
    { lat: 48.7705903, lng: 17.7186618 },
    { lat: 48.7706452, lng: 17.7185098 },
    { lat: 48.770704, lng: 17.7183644 },
    { lat: 48.770771, lng: 17.7181876 },
    { lat: 48.7708429, lng: 17.7179988 },
    { lat: 48.7709014, lng: 17.7178488 },
    { lat: 48.7709557, lng: 17.7177037 },
    { lat: 48.7710126, lng: 17.7175112 },
    { lat: 48.7710539, lng: 17.7173742 },
    { lat: 48.771108, lng: 17.717189 },
    { lat: 48.7712321, lng: 17.7171343 },
    { lat: 48.7713174, lng: 17.7170889 },
    { lat: 48.7714215, lng: 17.7170379 },
    { lat: 48.7715277, lng: 17.7169841 },
    { lat: 48.7716438, lng: 17.7169254 },
    { lat: 48.7716854, lng: 17.7169038 },
    { lat: 48.7717136, lng: 17.7169779 },
    { lat: 48.7717851, lng: 17.7171485 },
    { lat: 48.7718058, lng: 17.7172634 },
    { lat: 48.7718769, lng: 17.7174647 },
    { lat: 48.7719434, lng: 17.7176287 },
    { lat: 48.7719993, lng: 17.7177651 },
    { lat: 48.7720514, lng: 17.7178945 },
    { lat: 48.7720949, lng: 17.7179959 },
    { lat: 48.7721564, lng: 17.7181341 },
    { lat: 48.7722289, lng: 17.7183023 },
    { lat: 48.772282, lng: 17.7184315 },
    { lat: 48.7723473, lng: 17.7185743 },
    { lat: 48.7723994, lng: 17.718687 },
    { lat: 48.772519, lng: 17.7189425 },
    { lat: 48.7725817, lng: 17.7190806 },
    { lat: 48.7726546, lng: 17.7192181 },
    { lat: 48.7727167, lng: 17.7193468 },
    { lat: 48.772801, lng: 17.719505 },
    { lat: 48.7728742, lng: 17.719685 },
    { lat: 48.7729452, lng: 17.7198488 },
    { lat: 48.7729801, lng: 17.7199933 },
    { lat: 48.7730832, lng: 17.7202379 },
    { lat: 48.773162, lng: 17.7202708 },
    { lat: 48.7732417, lng: 17.7203159 },
    { lat: 48.7733513, lng: 17.7202271 },
    { lat: 48.773413, lng: 17.7201287 },
    { lat: 48.7734431, lng: 17.7200509 },
    { lat: 48.7735073, lng: 17.7199928 },
    { lat: 48.7735748, lng: 17.7199346 },
    { lat: 48.7736369, lng: 17.7198976 },
    { lat: 48.7736974, lng: 17.7198515 },
    { lat: 48.773744, lng: 17.7197826 },
    { lat: 48.773832, lng: 17.7197279 },
    { lat: 48.7739042, lng: 17.7196361 },
    { lat: 48.7739999, lng: 17.7195761 },
    { lat: 48.774111, lng: 17.7194557 },
    { lat: 48.7741581, lng: 17.7193775 },
    { lat: 48.7742018, lng: 17.7192969 },
    { lat: 48.7742599, lng: 17.7191965 },
    { lat: 48.7743069, lng: 17.7190989 },
    { lat: 48.7743583, lng: 17.718999 },
    { lat: 48.7744563, lng: 17.7188113 },
    { lat: 48.7745162, lng: 17.7186846 },
    { lat: 48.7745818, lng: 17.7185413 },
    { lat: 48.7746013, lng: 17.7184718 },
    { lat: 48.7746278, lng: 17.7183894 },
    { lat: 48.7746278, lng: 17.7183327 },
    { lat: 48.7747242, lng: 17.7181949 },
    { lat: 48.7747955, lng: 17.7181596 },
    { lat: 48.7748908, lng: 17.7181137 },
    { lat: 48.7749564, lng: 17.7180771 },
    { lat: 48.7750259, lng: 17.7180515 },
    { lat: 48.7751393, lng: 17.7180047 },
    { lat: 48.7751653, lng: 17.7179866 },
    { lat: 48.7751922, lng: 17.7179473 },
    { lat: 48.7752045, lng: 17.7178729 },
    { lat: 48.7752132, lng: 17.717721 },
    { lat: 48.7751525, lng: 17.7175592 },
    { lat: 48.7750829, lng: 17.7173482 },
    { lat: 48.7750471, lng: 17.7172059 },
    { lat: 48.7749881, lng: 17.7169989 },
    { lat: 48.7749875, lng: 17.7169731 },
    { lat: 48.7751259, lng: 17.7168895 },
    { lat: 48.7752515, lng: 17.7168225 },
    { lat: 48.7752982, lng: 17.7167749 },
    { lat: 48.7753716, lng: 17.7166835 },
    { lat: 48.7755696, lng: 17.7164867 },
    { lat: 48.7756742, lng: 17.7163896 },
    { lat: 48.7757638, lng: 17.7163048 },
    { lat: 48.7758553, lng: 17.716215 },
    { lat: 48.7759472, lng: 17.7161299 },
    { lat: 48.776038, lng: 17.7160474 },
    { lat: 48.7761302, lng: 17.7162411 },
    { lat: 48.7761981, lng: 17.7163905 },
    { lat: 48.7762844, lng: 17.716582 },
    { lat: 48.7763679, lng: 17.716471 },
    { lat: 48.776525, lng: 17.7162707 },
    { lat: 48.7766078, lng: 17.7161672 },
    { lat: 48.7766369, lng: 17.716091 },
    { lat: 48.7767234, lng: 17.7160108 },
    { lat: 48.7767887, lng: 17.7159334 },
    { lat: 48.7768641, lng: 17.7158581 },
    { lat: 48.7769382, lng: 17.7157785 },
    { lat: 48.7770499, lng: 17.7156666 },
    { lat: 48.7771337, lng: 17.715835 },
    { lat: 48.7771998, lng: 17.7159727 },
    { lat: 48.7773298, lng: 17.7162502 },
    { lat: 48.7773884, lng: 17.7163765 },
    { lat: 48.7774714, lng: 17.7165514 },
    { lat: 48.7775442, lng: 17.7167051 },
    { lat: 48.7776401, lng: 17.716906 },
    { lat: 48.7777199, lng: 17.7170834 },
    { lat: 48.7777283, lng: 17.7171092 },
    { lat: 48.7778722, lng: 17.7169355 },
    { lat: 48.7779746, lng: 17.7168194 },
    { lat: 48.7780245, lng: 17.7167495 },
    { lat: 48.7781569, lng: 17.7165336 },
    { lat: 48.7782153, lng: 17.7164566 },
    { lat: 48.7783056, lng: 17.7163456 },
    { lat: 48.7783798, lng: 17.7162513 },
    { lat: 48.7784704, lng: 17.7161618 },
    { lat: 48.7784949, lng: 17.7161399 },
    { lat: 48.7786038, lng: 17.7160733 },
    { lat: 48.7787706, lng: 17.7159959 },
    { lat: 48.7788942, lng: 17.7159498 },
    { lat: 48.7789933, lng: 17.7159261 },
    { lat: 48.7791211, lng: 17.7158943 },
    { lat: 48.779219, lng: 17.7158706 },
    { lat: 48.7793451, lng: 17.715846 },
    { lat: 48.7794306, lng: 17.7158228 },
    { lat: 48.7795456, lng: 17.715801 },
    { lat: 48.7796472, lng: 17.7157842 },
    { lat: 48.779759, lng: 17.7157644 },
    { lat: 48.7798785, lng: 17.715745 },
    { lat: 48.7799852, lng: 17.7157352 },
    { lat: 48.7801073, lng: 17.7157201 },
    { lat: 48.7802454, lng: 17.7157074 },
    { lat: 48.7803667, lng: 17.715697 },
    { lat: 48.780469, lng: 17.7159282 },
    { lat: 48.7805119, lng: 17.7160734 },
    { lat: 48.7805549, lng: 17.7162214 },
    { lat: 48.7805768, lng: 17.7163221 },
    { lat: 48.7806148, lng: 17.7164795 },
    { lat: 48.780637, lng: 17.7165451 },
    { lat: 48.7806936, lng: 17.7167134 },
    { lat: 48.7807438, lng: 17.7168683 },
    { lat: 48.7808084, lng: 17.7170556 },
    { lat: 48.7808717, lng: 17.7172406 },
    { lat: 48.7809136, lng: 17.7173697 },
    { lat: 48.780975, lng: 17.7173041 },
    { lat: 48.7810892, lng: 17.7171782 },
    { lat: 48.7811826, lng: 17.7170813 },
    { lat: 48.7812901, lng: 17.7169748 },
    { lat: 48.7814016, lng: 17.7168608 },
    { lat: 48.7815071, lng: 17.7167587 },
    { lat: 48.7816166, lng: 17.7166498 },
    { lat: 48.7818251, lng: 17.716441 },
    { lat: 48.7819035, lng: 17.7163591 },
    { lat: 48.7819981, lng: 17.7162644 },
    { lat: 48.7820972, lng: 17.7161672 },
    { lat: 48.7821176, lng: 17.7160417 },
    { lat: 48.7821903, lng: 17.7156829 },
    { lat: 48.7822252, lng: 17.7154956 },
    { lat: 48.7822532, lng: 17.7153647 },
    { lat: 48.7822747, lng: 17.7153477 },
    { lat: 48.782354, lng: 17.7152796 },
    { lat: 48.7824078, lng: 17.715108 },
    { lat: 48.7825207, lng: 17.714706 },
    { lat: 48.7825806, lng: 17.7145058 },
    { lat: 48.7826218, lng: 17.7143487 },
    { lat: 48.7826682, lng: 17.7141871 },
    { lat: 48.7827287, lng: 17.7139774 },
    { lat: 48.7827922, lng: 17.7139262 },
    { lat: 48.7830477, lng: 17.7138041 },
    { lat: 48.7831762, lng: 17.7137462 },
    { lat: 48.7833072, lng: 17.713691 },
    { lat: 48.7835442, lng: 17.7135808 },
    { lat: 48.7836523, lng: 17.7135214 },
    { lat: 48.7837212, lng: 17.713484 },
    { lat: 48.7838403, lng: 17.7134197 },
    { lat: 48.783969, lng: 17.7133476 },
    { lat: 48.7840531, lng: 17.7133175 },
    { lat: 48.7841422, lng: 17.7132985 },
    { lat: 48.784271, lng: 17.7132457 },
    { lat: 48.7843794, lng: 17.7131907 },
    { lat: 48.7844358, lng: 17.713154 },
    { lat: 48.7844948, lng: 17.7131217 },
    { lat: 48.7846228, lng: 17.7130546 },
    { lat: 48.7847669, lng: 17.7129941 },
    { lat: 48.7849143, lng: 17.7129314 },
    { lat: 48.7849928, lng: 17.7128865 },
    { lat: 48.7850905, lng: 17.7128394 },
    { lat: 48.7851792, lng: 17.7127944 },
    { lat: 48.7853135, lng: 17.7127037 },
    { lat: 48.7854023, lng: 17.7126612 },
    { lat: 48.7855113, lng: 17.7126159 },
    { lat: 48.7856085, lng: 17.71258 },
    { lat: 48.7856892, lng: 17.7125331 },
    { lat: 48.7858102, lng: 17.712464 },
    { lat: 48.7858242, lng: 17.7124704 },
    { lat: 48.7858281, lng: 17.712279 },
    { lat: 48.7858334, lng: 17.7121442 },
    { lat: 48.7858429, lng: 17.712007 },
    { lat: 48.7858424, lng: 17.7118511 },
    { lat: 48.7858473, lng: 17.7117115 },
    { lat: 48.7858532, lng: 17.7115698 },
    { lat: 48.7858633, lng: 17.7112386 },
    { lat: 48.785873, lng: 17.7110493 },
    { lat: 48.7858886, lng: 17.7109167 },
    { lat: 48.7859235, lng: 17.7107859 },
    { lat: 48.7859485, lng: 17.7106435 },
    { lat: 48.7859739, lng: 17.7105601 },
    { lat: 48.7860185, lng: 17.7104055 },
    { lat: 48.7860819, lng: 17.7102432 },
    { lat: 48.786133, lng: 17.7101022 },
    { lat: 48.7861779, lng: 17.7100067 },
    { lat: 48.7862387, lng: 17.7098751 },
    { lat: 48.7862967, lng: 17.709772 },
    { lat: 48.7863794, lng: 17.7096306 },
    { lat: 48.7864629, lng: 17.7094845 },
    { lat: 48.7865424, lng: 17.7093451 },
    { lat: 48.7866217, lng: 17.7092037 },
    { lat: 48.7866969, lng: 17.7090695 },
    { lat: 48.7867595, lng: 17.7089663 },
    { lat: 48.7868223, lng: 17.7088511 },
    { lat: 48.7869047, lng: 17.7087215 },
    { lat: 48.7869844, lng: 17.7085872 },
    { lat: 48.7870315, lng: 17.7084891 },
    { lat: 48.7871059, lng: 17.7083218 },
    { lat: 48.7871396, lng: 17.7082287 },
    { lat: 48.7871803, lng: 17.7081025 },
    { lat: 48.787187, lng: 17.7080455 },
    { lat: 48.787224, lng: 17.7078579 },
    { lat: 48.7872505, lng: 17.70772 },
    { lat: 48.7872937, lng: 17.707561 },
    { lat: 48.7873415, lng: 17.7073847 },
    { lat: 48.7874209, lng: 17.7070971 },
    { lat: 48.7874616, lng: 17.7069517 },
    { lat: 48.7875041, lng: 17.7067808 },
    { lat: 48.7875521, lng: 17.706607 },
    { lat: 48.7876247, lng: 17.7064113 },
    { lat: 48.7876947, lng: 17.7062651 },
    { lat: 48.7877943, lng: 17.7060877 },
    { lat: 48.787898, lng: 17.7059198 },
    { lat: 48.7879964, lng: 17.7057564 },
    { lat: 48.7881033, lng: 17.7056051 },
    { lat: 48.7881792, lng: 17.7054823 },
    { lat: 48.7882766, lng: 17.7053048 },
    { lat: 48.7883728, lng: 17.7051444 },
    { lat: 48.7883839, lng: 17.7051583 },
    { lat: 48.7884523, lng: 17.7052599 },
    { lat: 48.788522, lng: 17.7053647 },
    { lat: 48.7886558, lng: 17.7055572 },
    { lat: 48.7887251, lng: 17.705655 },
    { lat: 48.7888169, lng: 17.7057893 },
    { lat: 48.788881, lng: 17.7058773 },
    { lat: 48.7888866, lng: 17.7058928 },
    { lat: 48.7889481, lng: 17.7059747 },
    { lat: 48.7889998, lng: 17.7060445 },
    { lat: 48.7890477, lng: 17.7061079 },
    { lat: 48.7890749, lng: 17.7061451 },
    { lat: 48.789138, lng: 17.7062339 },
    { lat: 48.7891836, lng: 17.7062971 },
    { lat: 48.7892317, lng: 17.7063624 },
    { lat: 48.7892678, lng: 17.7064164 },
    { lat: 48.789334, lng: 17.7065193 },
    { lat: 48.7893764, lng: 17.7065847 },
    { lat: 48.7894102, lng: 17.7066385 },
    { lat: 48.7894435, lng: 17.706685 },
    { lat: 48.7894821, lng: 17.706744 },
    { lat: 48.7895182, lng: 17.7067974 },
    { lat: 48.7896463, lng: 17.7070058 },
    { lat: 48.7896897, lng: 17.7070693 },
    { lat: 48.7897544, lng: 17.707184 },
    { lat: 48.7898173, lng: 17.7072892 },
    { lat: 48.7898872, lng: 17.7073967 },
    { lat: 48.7899574, lng: 17.7075089 },
    { lat: 48.7900053, lng: 17.7075911 },
    { lat: 48.790074, lng: 17.7076988 },
    { lat: 48.7901486, lng: 17.7078276 },
    { lat: 48.7902142, lng: 17.7079373 },
    { lat: 48.7902614, lng: 17.7080098 },
    { lat: 48.7903611, lng: 17.7081597 },
    { lat: 48.7904243, lng: 17.7082507 },
    { lat: 48.7904742, lng: 17.7083279 },
    { lat: 48.7905341, lng: 17.7084194 },
    { lat: 48.7906536, lng: 17.7085994 },
    { lat: 48.7907296, lng: 17.7087163 },
    { lat: 48.7907432, lng: 17.708735 },
    { lat: 48.7907592, lng: 17.7087558 },
    { lat: 48.7907841, lng: 17.7087202 },
    { lat: 48.7908222, lng: 17.7086418 },
    { lat: 48.7908637, lng: 17.7085654 },
    { lat: 48.7909501, lng: 17.7084087 },
    { lat: 48.7910348, lng: 17.7082635 },
    { lat: 48.7911049, lng: 17.7081374 },
    { lat: 48.79115, lng: 17.7080635 },
    { lat: 48.7912553, lng: 17.7079038 },
    { lat: 48.7913577, lng: 17.7077346 },
    { lat: 48.7914576, lng: 17.7075608 },
    { lat: 48.7915395, lng: 17.7074065 },
    { lat: 48.791585, lng: 17.7073184 },
    { lat: 48.7916403, lng: 17.7072089 },
    { lat: 48.7917133, lng: 17.7070756 },
    { lat: 48.791751, lng: 17.7069904 },
    { lat: 48.7918253, lng: 17.7068403 },
    { lat: 48.791881, lng: 17.7067002 },
    { lat: 48.7919452, lng: 17.7065694 },
    { lat: 48.7919803, lng: 17.7064984 },
    { lat: 48.7920309, lng: 17.7064219 },
    { lat: 48.7921149, lng: 17.7062648 },
    { lat: 48.7922028, lng: 17.7061147 },
    { lat: 48.7922845, lng: 17.7059768 },
    { lat: 48.7923616, lng: 17.7058531 },
    { lat: 48.7924586, lng: 17.7057052 },
    { lat: 48.7925588, lng: 17.7055552 },
    { lat: 48.7926483, lng: 17.7054143 },
    { lat: 48.7927221, lng: 17.7053095 },
    { lat: 48.7927549, lng: 17.7052571 },
    { lat: 48.7928051, lng: 17.7051923 },
    { lat: 48.7928923, lng: 17.7050851 },
    { lat: 48.7929707, lng: 17.704983 },
    { lat: 48.7930481, lng: 17.7048836 },
    { lat: 48.7931266, lng: 17.7047841 },
    { lat: 48.7932546, lng: 17.7046267 },
    { lat: 48.7933326, lng: 17.7045179 },
    { lat: 48.7933793, lng: 17.7044343 },
    { lat: 48.7934257, lng: 17.7045097 },
    { lat: 48.7934791, lng: 17.7046057 },
    { lat: 48.7934257, lng: 17.704673 },
    { lat: 48.7933946, lng: 17.7047175 },
    { lat: 48.7935177, lng: 17.7049013 },
    { lat: 48.793586, lng: 17.7050011 },
    { lat: 48.7936452, lng: 17.7050824 },
    { lat: 48.7937066, lng: 17.7051826 },
    { lat: 48.7937377, lng: 17.7052278 },
    { lat: 48.7938053, lng: 17.7053369 },
    { lat: 48.7938698, lng: 17.7054279 },
    { lat: 48.7939395, lng: 17.7055346 },
    { lat: 48.7940381, lng: 17.7053767 },
    { lat: 48.7941076, lng: 17.7052753 },
    { lat: 48.7941664, lng: 17.7051863 },
    { lat: 48.7942377, lng: 17.7050775 },
    { lat: 48.794321, lng: 17.7049658 },
    { lat: 48.794414, lng: 17.7048254 },
    { lat: 48.7944876, lng: 17.7047188 },
    { lat: 48.7945714, lng: 17.7045954 },
    { lat: 48.7946437, lng: 17.7044865 },
    { lat: 48.7947141, lng: 17.7043826 },
    { lat: 48.7947855, lng: 17.704279 },
    { lat: 48.7949295, lng: 17.7040688 },
    { lat: 48.794999, lng: 17.7039698 },
    { lat: 48.795085, lng: 17.7038434 },
    { lat: 48.7951669, lng: 17.7037247 },
    { lat: 48.7952299, lng: 17.7036308 },
    { lat: 48.7953095, lng: 17.7035123 },
    { lat: 48.7954568, lng: 17.7033016 },
    { lat: 48.7955399, lng: 17.7031831 },
    { lat: 48.7955229, lng: 17.7031295 },
    { lat: 48.7955742, lng: 17.7030624 },
    { lat: 48.7956595, lng: 17.703 },
    { lat: 48.7957358, lng: 17.7029387 },
    { lat: 48.7958093, lng: 17.7028843 },
    { lat: 48.795887, lng: 17.7028251 },
    { lat: 48.7959559, lng: 17.7027731 },
    { lat: 48.7958854, lng: 17.7025459 },
    { lat: 48.7958301, lng: 17.7023811 },
    { lat: 48.795785, lng: 17.7022354 },
    { lat: 48.7957701, lng: 17.702196 },
    { lat: 48.7957391, lng: 17.7020962 },
    { lat: 48.7956723, lng: 17.701892 },
    { lat: 48.7956281, lng: 17.7017436 },
    { lat: 48.7955615, lng: 17.7015421 },
    { lat: 48.7954865, lng: 17.7013147 },
    { lat: 48.7954018, lng: 17.7010601 },
    { lat: 48.7953423, lng: 17.7008818 },
    { lat: 48.7952911, lng: 17.7007288 },
    { lat: 48.7952409, lng: 17.7005756 },
    { lat: 48.795185, lng: 17.700402 },
    { lat: 48.7952581, lng: 17.7003429 },
    { lat: 48.7953436, lng: 17.7002809 },
    { lat: 48.7954161, lng: 17.7002309 },
    { lat: 48.7954546, lng: 17.7002143 },
    { lat: 48.7954883, lng: 17.7001934 },
    { lat: 48.7955608, lng: 17.7001438 },
    { lat: 48.7956229, lng: 17.7000881 },
    { lat: 48.7956676, lng: 17.7000615 },
    { lat: 48.7957257, lng: 17.7000341 },
    { lat: 48.7957724, lng: 17.7000049 },
    { lat: 48.795895, lng: 17.6999426 },
    { lat: 48.7960077, lng: 17.6998835 },
    { lat: 48.7960089, lng: 17.6997557 },
    { lat: 48.7959937, lng: 17.6996194 },
    { lat: 48.7959732, lng: 17.699545 },
    { lat: 48.7959366, lng: 17.6993912 },
    { lat: 48.7959134, lng: 17.6993078 },
    { lat: 48.7958642, lng: 17.699169 },
    { lat: 48.7958158, lng: 17.6990631 },
    { lat: 48.7957437, lng: 17.6989186 },
    { lat: 48.7956728, lng: 17.6987932 },
    { lat: 48.7956913, lng: 17.6987824 },
    { lat: 48.7957799, lng: 17.6987199 },
    { lat: 48.7958817, lng: 17.6986474 },
    { lat: 48.7959734, lng: 17.6985798 },
    { lat: 48.7961064, lng: 17.6985028 },
    { lat: 48.7961763, lng: 17.6984652 },
    { lat: 48.7962731, lng: 17.6984237 },
    { lat: 48.796342, lng: 17.698405 },
    { lat: 48.7965241, lng: 17.6983364 },
    { lat: 48.796629, lng: 17.698299 },
    { lat: 48.7967098, lng: 17.6982725 },
    { lat: 48.7968357, lng: 17.6982269 },
    { lat: 48.7969196, lng: 17.698198 },
    { lat: 48.7969293, lng: 17.6982256 },
    { lat: 48.7969731, lng: 17.698204 },
    { lat: 48.7970513, lng: 17.6981708 },
    { lat: 48.797158, lng: 17.6981257 },
    { lat: 48.7972141, lng: 17.6981033 },
    { lat: 48.7972723, lng: 17.6980762 },
    { lat: 48.7973044, lng: 17.6980644 },
    { lat: 48.7973567, lng: 17.6980351 },
    { lat: 48.7974012, lng: 17.6980062 },
    { lat: 48.7974609, lng: 17.697967 },
    { lat: 48.797523, lng: 17.6979301 },
    { lat: 48.7975533, lng: 17.6979091 },
    { lat: 48.7975792, lng: 17.6978908 },
    { lat: 48.7976635, lng: 17.6978285 },
    { lat: 48.7977621, lng: 17.69778 },
    { lat: 48.7978683, lng: 17.6977261 },
    { lat: 48.797946, lng: 17.6977044 },
    { lat: 48.797963, lng: 17.6975968 },
    { lat: 48.7979768, lng: 17.6975274 },
    { lat: 48.7980076, lng: 17.6973504 },
    { lat: 48.7980336, lng: 17.6972044 },
    { lat: 48.7980563, lng: 17.6970611 },
    { lat: 48.7980879, lng: 17.6968955 },
    { lat: 48.7981001, lng: 17.6968003 },
    { lat: 48.7981514, lng: 17.6968442 },
    { lat: 48.7982199, lng: 17.6968752 },
    { lat: 48.7982839, lng: 17.6967601 },
    { lat: 48.7983335, lng: 17.6966693 },
    { lat: 48.7984033, lng: 17.6965539 },
    { lat: 48.7984785, lng: 17.696419 },
    { lat: 48.7985556, lng: 17.6962789 },
    { lat: 48.798568, lng: 17.6962593 },
    { lat: 48.7986325, lng: 17.6961534 },
    { lat: 48.7986917, lng: 17.6960503 },
    { lat: 48.7987595, lng: 17.6959421 },
    { lat: 48.7988227, lng: 17.6958339 },
    { lat: 48.7988813, lng: 17.6957402 },
    { lat: 48.799016, lng: 17.6958737 },
    { lat: 48.7991102, lng: 17.6959717 },
    { lat: 48.7992126, lng: 17.6960764 },
    { lat: 48.7993103, lng: 17.6961767 },
    { lat: 48.799368, lng: 17.6962344 },
    { lat: 48.7994552, lng: 17.6963306 },
    { lat: 48.7995466, lng: 17.6964193 },
    { lat: 48.7996143, lng: 17.696493 },
    { lat: 48.7996975, lng: 17.6965777 },
    { lat: 48.7997831, lng: 17.6966648 },
    { lat: 48.7998781, lng: 17.6967577 },
    { lat: 48.7999722, lng: 17.6968396 },
    { lat: 48.800039, lng: 17.6968968 },
    { lat: 48.800151, lng: 17.6969912 },
    { lat: 48.8003378, lng: 17.6971451 },
    { lat: 48.8004354, lng: 17.6972265 },
    { lat: 48.8005473, lng: 17.6973208 },
    { lat: 48.8004843, lng: 17.6974884 },
    { lat: 48.800436, lng: 17.6976192 },
    { lat: 48.8005024, lng: 17.6976717 },
    { lat: 48.8005938, lng: 17.6977436 },
    { lat: 48.8006878, lng: 17.6978206 },
    { lat: 48.8007395, lng: 17.6978176 },
    { lat: 48.8007811, lng: 17.6977415 },
    { lat: 48.800867, lng: 17.6975773 },
    { lat: 48.8009235, lng: 17.6974697 },
    { lat: 48.8009842, lng: 17.6973547 },
    { lat: 48.8010599, lng: 17.6972102 },
    { lat: 48.8011129, lng: 17.6970981 },
    { lat: 48.8011815, lng: 17.6969495 },
    { lat: 48.8012399, lng: 17.6968343 },
    { lat: 48.8011914, lng: 17.6967618 },
    { lat: 48.8011245, lng: 17.6966665 },
    { lat: 48.8010652, lng: 17.6965831 },
    { lat: 48.8009264, lng: 17.6963836 },
    { lat: 48.8008721, lng: 17.6963087 },
    { lat: 48.8007889, lng: 17.6961864 },
    { lat: 48.800716, lng: 17.6960869 },
    { lat: 48.8004346, lng: 17.6956247 },
    { lat: 48.8003691, lng: 17.6955152 },
    { lat: 48.8003025, lng: 17.6954059 },
    { lat: 48.8002257, lng: 17.6952784 },
    { lat: 48.8001563, lng: 17.6951595 },
    { lat: 48.8000832, lng: 17.6950366 },
    { lat: 48.8000745, lng: 17.6950228 },
    { lat: 48.800045, lng: 17.6949681 },
    { lat: 48.8000764, lng: 17.6949257 },
    { lat: 48.8001063, lng: 17.6948813 },
    { lat: 48.8001557, lng: 17.6948405 },
    { lat: 48.8002505, lng: 17.6948202 },
    { lat: 48.80035, lng: 17.6948043 },
    { lat: 48.8003993, lng: 17.6947988 },
    { lat: 48.8004486, lng: 17.69472 },
    { lat: 48.8004578, lng: 17.6947049 },
    { lat: 48.8004972, lng: 17.6946105 },
    { lat: 48.8005382, lng: 17.694506 },
    { lat: 48.8005516, lng: 17.6944695 },
    { lat: 48.8006071, lng: 17.6943976 },
    { lat: 48.8006674, lng: 17.6943133 },
    { lat: 48.8006872, lng: 17.6942883 },
    { lat: 48.8007456, lng: 17.6942633 },
    { lat: 48.8007959, lng: 17.6942007 },
    { lat: 48.8008576, lng: 17.6941944 },
    { lat: 48.8008676, lng: 17.6940829 },
    { lat: 48.8008831, lng: 17.6939682 },
    { lat: 48.8009456, lng: 17.6938296 },
    { lat: 48.8009492, lng: 17.6937769 },
    { lat: 48.8009503, lng: 17.693758 },
    { lat: 48.8009908, lng: 17.6937202 },
    { lat: 48.801042, lng: 17.6936171 },
    { lat: 48.8011047, lng: 17.6934809 },
    { lat: 48.8011457, lng: 17.6933764 },
    { lat: 48.8011896, lng: 17.6932647 },
    { lat: 48.8012549, lng: 17.693152 },
    { lat: 48.8013099, lng: 17.6930562 },
    { lat: 48.8014484, lng: 17.692922 },
    { lat: 48.8015245, lng: 17.6928933 },
    { lat: 48.8016171, lng: 17.6928567 },
    { lat: 48.8017076, lng: 17.6928226 },
    { lat: 48.8017992, lng: 17.6927881 },
    { lat: 48.8018881, lng: 17.6927114 },
    { lat: 48.8019506, lng: 17.6926248 },
    { lat: 48.8019766, lng: 17.69259 },
    { lat: 48.8020571, lng: 17.6924666 },
    { lat: 48.8021232, lng: 17.6923679 },
    { lat: 48.8022132, lng: 17.6922343 },
    { lat: 48.8022265, lng: 17.6922121 },
    { lat: 48.8023197, lng: 17.6920571 },
    { lat: 48.8023683, lng: 17.6918942 },
    { lat: 48.8024028, lng: 17.6917962 },
    { lat: 48.8024599, lng: 17.6916411 },
    { lat: 48.8024713, lng: 17.6916076 },
    { lat: 48.8025075, lng: 17.6915165 },
    { lat: 48.8025487, lng: 17.6914158 },
    { lat: 48.8026238, lng: 17.6913166 },
    { lat: 48.8027565, lng: 17.6912342 },
    { lat: 48.802781, lng: 17.691212 },
    { lat: 48.802882, lng: 17.6911283 },
    { lat: 48.8028906, lng: 17.6911207 },
    { lat: 48.8029414, lng: 17.6910105 },
    { lat: 48.8029791, lng: 17.6908911 },
    { lat: 48.8030267, lng: 17.6908211 },
    { lat: 48.8031281, lng: 17.6907423 },
    { lat: 48.8031939, lng: 17.6906405 },
    { lat: 48.8032541, lng: 17.6905372 },
    { lat: 48.8033403, lng: 17.6903968 },
    { lat: 48.8033906, lng: 17.6903336 },
    { lat: 48.8034558, lng: 17.6902373 },
    { lat: 48.8035097, lng: 17.6901955 },
    { lat: 48.8035518, lng: 17.690201 },
    { lat: 48.8037257, lng: 17.6902019 },
    { lat: 48.8038379, lng: 17.6901181 },
    { lat: 48.8039119, lng: 17.6900543 },
    { lat: 48.8039626, lng: 17.6899248 },
    { lat: 48.8039758, lng: 17.6898819 },
    { lat: 48.8040635, lng: 17.6897656 },
    { lat: 48.8040935, lng: 17.6897242 },
    { lat: 48.8041785, lng: 17.6896552 },
    { lat: 48.8041947, lng: 17.6896427 },
    { lat: 48.804314, lng: 17.6896554 },
    { lat: 48.8044178, lng: 17.6896542 },
    { lat: 48.8044912, lng: 17.6896709 },
    { lat: 48.8045676, lng: 17.6896848 },
    { lat: 48.8046013, lng: 17.689627 },
    { lat: 48.8047285, lng: 17.6895307 },
    { lat: 48.8048061, lng: 17.6894709 },
    { lat: 48.8048722, lng: 17.689424 },
    { lat: 48.8049677, lng: 17.6893262 },
    { lat: 48.8050121, lng: 17.6892397 },
    { lat: 48.8050598, lng: 17.6892265 },
    { lat: 48.8051508, lng: 17.689164 },
    { lat: 48.8052545, lng: 17.6890707 },
    { lat: 48.8053348, lng: 17.6890376 },
    { lat: 48.8053749, lng: 17.6889182 },
    { lat: 48.8054929, lng: 17.688874 },
    { lat: 48.8056388, lng: 17.6887507 },
    { lat: 48.8057344, lng: 17.6886697 },
    { lat: 48.8057927, lng: 17.6887008 },
    { lat: 48.8058663, lng: 17.6886296 },
    { lat: 48.8059591, lng: 17.6884873 },
    { lat: 48.8061027, lng: 17.6883454 },
    { lat: 48.806132, lng: 17.688309 },
    { lat: 48.8061453, lng: 17.688287 },
    { lat: 48.8062018, lng: 17.6881766 },
    { lat: 48.8062662, lng: 17.6880494 },
    { lat: 48.8064051, lng: 17.6878861 },
    { lat: 48.8065715, lng: 17.6876916 },
    { lat: 48.806664, lng: 17.6875823 },
    { lat: 48.8066981, lng: 17.6874582 },
    { lat: 48.8067308, lng: 17.6873484 },
    { lat: 48.8067867, lng: 17.6872661 },
    { lat: 48.8068682, lng: 17.6871405 },
    { lat: 48.8069588, lng: 17.6870358 },
    { lat: 48.8070445, lng: 17.6869434 },
    { lat: 48.8071294, lng: 17.6868552 },
    { lat: 48.8072054, lng: 17.6867701 },
    { lat: 48.8072886, lng: 17.6866729 },
    { lat: 48.8074009, lng: 17.6865722 },
    { lat: 48.8074949, lng: 17.686505 },
    { lat: 48.8075953, lng: 17.6864284 },
    { lat: 48.8077313, lng: 17.6862561 },
    { lat: 48.8078137, lng: 17.6861637 },
    { lat: 48.807928, lng: 17.6860226 },
    { lat: 48.8080865, lng: 17.6859915 },
    { lat: 48.8081867, lng: 17.6859692 },
    { lat: 48.8083538, lng: 17.6859328 },
    { lat: 48.8085399, lng: 17.6858935 },
    { lat: 48.8087231, lng: 17.6858256 },
    { lat: 48.8088222, lng: 17.6857659 },
    { lat: 48.8089013, lng: 17.6856588 },
    { lat: 48.8090131, lng: 17.6855113 },
    { lat: 48.8090945, lng: 17.6854045 },
    { lat: 48.8091716, lng: 17.6853004 },
    { lat: 48.8092544, lng: 17.685198 },
    { lat: 48.8093201, lng: 17.6851088 },
    { lat: 48.8093973, lng: 17.6850069 },
    { lat: 48.8094917, lng: 17.6848899 },
    { lat: 48.8095529, lng: 17.6848032 },
    { lat: 48.80969, lng: 17.6846281 },
    { lat: 48.8098398, lng: 17.6844409 },
    { lat: 48.8099706, lng: 17.6842737 },
    { lat: 48.8100584, lng: 17.6841788 },
    { lat: 48.8101553, lng: 17.6840834 },
    { lat: 48.810241, lng: 17.6839905 },
    { lat: 48.8103291, lng: 17.6839002 },
    { lat: 48.8104383, lng: 17.6837855 },
    { lat: 48.8105399, lng: 17.6836758 },
    { lat: 48.8106459, lng: 17.6835633 },
    { lat: 48.8107272, lng: 17.6835086 },
    { lat: 48.8108419, lng: 17.6834291 },
    { lat: 48.8109481, lng: 17.683357 },
    { lat: 48.8110388, lng: 17.6832904 },
    { lat: 48.8111429, lng: 17.6832205 },
    { lat: 48.8112422, lng: 17.6831466 },
    { lat: 48.8113766, lng: 17.6830566 },
    { lat: 48.8114872, lng: 17.6829819 },
    { lat: 48.8115528, lng: 17.6829302 },
    { lat: 48.8117992, lng: 17.6827329 },
    { lat: 48.8118831, lng: 17.6826662 },
    { lat: 48.8119626, lng: 17.6826019 },
    { lat: 48.8121274, lng: 17.6824712 },
    { lat: 48.8122373, lng: 17.6823873 },
    { lat: 48.8123342, lng: 17.6823107 },
    { lat: 48.8124288, lng: 17.682234 },
    { lat: 48.8125825, lng: 17.6821087 },
    { lat: 48.8126535, lng: 17.6820517 },
    { lat: 48.8127633, lng: 17.6819651 },
    { lat: 48.8128549, lng: 17.6818961 },
    { lat: 48.8129678, lng: 17.6818023 },
    { lat: 48.813057, lng: 17.6817311 },
    { lat: 48.8132395, lng: 17.6815781 },
    { lat: 48.8133429, lng: 17.6814968 },
    { lat: 48.8135253, lng: 17.6813445 },
    { lat: 48.8136733, lng: 17.6812183 },
    { lat: 48.8137647, lng: 17.6811421 },
    { lat: 48.8138442, lng: 17.6810778 },
    { lat: 48.8139183, lng: 17.6810167 },
    { lat: 48.8140284, lng: 17.6809345 },
    { lat: 48.8141099, lng: 17.6808656 },
    { lat: 48.8141863, lng: 17.6808044 },
    { lat: 48.8143575, lng: 17.6806688 },
    { lat: 48.8144488, lng: 17.6805944 },
    { lat: 48.8145423, lng: 17.6805179 },
    { lat: 48.8146275, lng: 17.6804537 },
    { lat: 48.8147352, lng: 17.68037 },
    { lat: 48.8148054, lng: 17.6803181 },
    { lat: 48.814896, lng: 17.6802488 },
    { lat: 48.8150069, lng: 17.6801809 },
    { lat: 48.815104, lng: 17.6801252 },
    { lat: 48.8151991, lng: 17.6800749 },
    { lat: 48.8152849, lng: 17.68002 },
    { lat: 48.8153646, lng: 17.6799753 },
    { lat: 48.8154536, lng: 17.67992 },
    { lat: 48.8155683, lng: 17.6798575 },
    { lat: 48.8156748, lng: 17.6797894 },
    { lat: 48.8157806, lng: 17.679729 },
    { lat: 48.8158658, lng: 17.6796827 },
    { lat: 48.8159928, lng: 17.6796193 },
    { lat: 48.8161015, lng: 17.6795697 },
    { lat: 48.8162176, lng: 17.6795114 },
    { lat: 48.8163349, lng: 17.6794554 },
    { lat: 48.8166159, lng: 17.6793248 },
    { lat: 48.8167017, lng: 17.6792879 },
    { lat: 48.8167881, lng: 17.6792611 },
    { lat: 48.8168861, lng: 17.6792214 },
    { lat: 48.817011, lng: 17.6791789 },
    { lat: 48.8170838, lng: 17.6791498 },
    { lat: 48.8171627, lng: 17.6791292 },
    { lat: 48.8172553, lng: 17.6790946 },
    { lat: 48.817337, lng: 17.6790626 },
    { lat: 48.8174041, lng: 17.6790351 },
    { lat: 48.8175024, lng: 17.6790006 },
    { lat: 48.8175892, lng: 17.678978 },
    { lat: 48.8176816, lng: 17.6789572 },
    { lat: 48.8177594, lng: 17.6789375 },
    { lat: 48.8178836, lng: 17.678902 },
    { lat: 48.817988, lng: 17.6788742 },
    { lat: 48.8180892, lng: 17.6788487 },
    { lat: 48.8181915, lng: 17.6788231 },
    { lat: 48.8182737, lng: 17.6788012 },
    { lat: 48.8184124, lng: 17.6787985 },
    { lat: 48.8185794, lng: 17.6787954 },
    { lat: 48.8187474, lng: 17.6787928 },
    { lat: 48.8189143, lng: 17.6787897 },
    { lat: 48.8189892, lng: 17.6787961 },
    { lat: 48.819088, lng: 17.6788034 },
    { lat: 48.8192059, lng: 17.6788131 },
    { lat: 48.8192888, lng: 17.6788195 },
    { lat: 48.819391, lng: 17.6788288 },
    { lat: 48.8195895, lng: 17.6788255 },
    { lat: 48.8196752, lng: 17.6788244 },
    { lat: 48.8197643, lng: 17.6788228 },
    { lat: 48.8198623, lng: 17.6788191 },
    { lat: 48.8200143, lng: 17.6788111 },
    { lat: 48.8200998, lng: 17.6788076 },
    { lat: 48.8201991, lng: 17.6788064 },
    { lat: 48.820401, lng: 17.6788024 },
    { lat: 48.820516, lng: 17.6788006 },
    { lat: 48.8206231, lng: 17.6787965 },
    { lat: 48.8207651, lng: 17.6787942 },
    { lat: 48.8208896, lng: 17.6787613 },
    { lat: 48.8209851, lng: 17.6787357 },
    { lat: 48.8211294, lng: 17.6786955 },
    { lat: 48.8212264, lng: 17.6786765 },
    { lat: 48.8213213, lng: 17.6786401 },
    { lat: 48.8214219, lng: 17.6786061 },
    { lat: 48.8215324, lng: 17.6785664 },
    { lat: 48.8216286, lng: 17.6785348 },
    { lat: 48.8217248, lng: 17.6785005 },
    { lat: 48.8218095, lng: 17.6784663 },
    { lat: 48.8219236, lng: 17.6784287 },
    { lat: 48.8220474, lng: 17.6783871 },
    { lat: 48.8220956, lng: 17.6783642 },
    { lat: 48.8221682, lng: 17.6783496 },
    { lat: 48.8222696, lng: 17.678329 },
    { lat: 48.822387, lng: 17.6783082 },
    { lat: 48.8225093, lng: 17.6782805 },
    { lat: 48.8226195, lng: 17.6782546 },
    { lat: 48.8228687, lng: 17.6781936 },
    { lat: 48.8229619, lng: 17.6781665 },
    { lat: 48.8230858, lng: 17.6781455 },
    { lat: 48.8231901, lng: 17.6781162 },
    { lat: 48.8232985, lng: 17.6780785 },
    { lat: 48.8234253, lng: 17.6780299 },
    { lat: 48.8235006, lng: 17.6780054 },
    { lat: 48.8236119, lng: 17.6779614 },
    { lat: 48.8237436, lng: 17.6779187 },
    { lat: 48.8239765, lng: 17.6778317 },
    { lat: 48.8240716, lng: 17.6778002 },
    { lat: 48.8241579, lng: 17.6777522 },
    { lat: 48.8242698, lng: 17.6777011 },
    { lat: 48.8244232, lng: 17.6776238 },
    { lat: 48.8245353, lng: 17.6775564 },
    { lat: 48.8246341, lng: 17.6774938 },
    { lat: 48.8247533, lng: 17.6774323 },
    { lat: 48.8248904, lng: 17.6773282 },
    { lat: 48.8250126, lng: 17.6772433 },
    { lat: 48.8252113, lng: 17.6770963 },
    { lat: 48.8254357, lng: 17.6769291 },
    { lat: 48.8255451, lng: 17.6768546 },
    { lat: 48.8256435, lng: 17.6767826 },
    { lat: 48.8257285, lng: 17.6767157 },
    { lat: 48.8258176, lng: 17.6766415 },
    { lat: 48.8259099, lng: 17.6765625 },
    { lat: 48.8260106, lng: 17.6764756 },
    { lat: 48.8261061, lng: 17.6763945 },
    { lat: 48.8261989, lng: 17.6763076 },
    { lat: 48.8263742, lng: 17.6761127 },
    { lat: 48.8264757, lng: 17.6759999 },
    { lat: 48.8265611, lng: 17.6759023 },
    { lat: 48.8266423, lng: 17.6758094 },
    { lat: 48.8267178, lng: 17.6757166 },
    { lat: 48.8268058, lng: 17.6756066 },
    { lat: 48.8268813, lng: 17.675512 },
    { lat: 48.8269587, lng: 17.675414 },
    { lat: 48.8270389, lng: 17.6753239 },
    { lat: 48.8271059, lng: 17.6752382 },
    { lat: 48.8271623, lng: 17.6751652 },
    { lat: 48.8272447, lng: 17.6750535 },
    { lat: 48.8273248, lng: 17.6749446 },
    { lat: 48.8274113, lng: 17.6748271 },
    { lat: 48.8275436, lng: 17.6747236 },
    { lat: 48.8276427, lng: 17.6746437 },
    { lat: 48.8277401, lng: 17.6745598 },
    { lat: 48.8278325, lng: 17.6744834 },
    { lat: 48.8279098, lng: 17.6744355 },
    { lat: 48.8280129, lng: 17.6743868 },
    { lat: 48.8281242, lng: 17.6743257 },
    { lat: 48.8282388, lng: 17.6742631 },
    { lat: 48.8283405, lng: 17.6742092 },
    { lat: 48.8284359, lng: 17.6741632 },
    { lat: 48.8285334, lng: 17.6741143 },
    { lat: 48.8286211, lng: 17.6740736 },
    { lat: 48.8287517, lng: 17.6740122 },
    { lat: 48.8288348, lng: 17.6739662 },
    { lat: 48.8290437, lng: 17.6738571 },
    { lat: 48.8293505, lng: 17.6736895 },
    { lat: 48.8294366, lng: 17.6736215 },
    { lat: 48.8294961, lng: 17.6735788 },
    { lat: 48.8296075, lng: 17.6735006 },
    { lat: 48.82972, lng: 17.6734198 },
    { lat: 48.8298115, lng: 17.6733485 },
    { lat: 48.8299065, lng: 17.6732777 },
    { lat: 48.8300209, lng: 17.673175 },
    { lat: 48.8301245, lng: 17.67308 },
    { lat: 48.8302198, lng: 17.6729971 },
    { lat: 48.8303142, lng: 17.6729161 },
    { lat: 48.8304193, lng: 17.6728259 },
    { lat: 48.8305224, lng: 17.672724 },
    { lat: 48.8306527, lng: 17.6726021 },
    { lat: 48.8307723, lng: 17.6724919 },
    { lat: 48.8308855, lng: 17.6723876 },
    { lat: 48.830975, lng: 17.6723023 },
    { lat: 48.831099, lng: 17.6721897 },
    { lat: 48.8311825, lng: 17.6721146 },
    { lat: 48.8312574, lng: 17.6720484 },
    { lat: 48.8313452, lng: 17.6719724 },
    { lat: 48.8314082, lng: 17.671914 },
    { lat: 48.831496, lng: 17.6718365 },
    { lat: 48.8315979, lng: 17.6717485 },
    { lat: 48.8316831, lng: 17.6716843 },
    { lat: 48.8317724, lng: 17.6716133 },
    { lat: 48.8318704, lng: 17.6715377 },
    { lat: 48.831984, lng: 17.6714395 },
    { lat: 48.8320766, lng: 17.6713665 },
    { lat: 48.8321771, lng: 17.6712762 },
    { lat: 48.8322468, lng: 17.6712148 },
    { lat: 48.8324261, lng: 17.6710678 },
    { lat: 48.8325215, lng: 17.6709851 },
    { lat: 48.8326286, lng: 17.6708921 },
    { lat: 48.8327198, lng: 17.670814 },
    { lat: 48.8328057, lng: 17.6707438 },
    { lat: 48.832914, lng: 17.6706504 },
    { lat: 48.8330236, lng: 17.6705596 },
    { lat: 48.8331008, lng: 17.6704939 },
    { lat: 48.8331886, lng: 17.6704182 },
    { lat: 48.8332874, lng: 17.6703374 },
    { lat: 48.8335207, lng: 17.6701467 },
    { lat: 48.8336159, lng: 17.6700615 },
    { lat: 48.8337215, lng: 17.6699805 },
    { lat: 48.8338061, lng: 17.669907 },
    { lat: 48.8338911, lng: 17.6698385 },
    { lat: 48.8339562, lng: 17.669759 },
    { lat: 48.8340276, lng: 17.6696735 },
    { lat: 48.8341117, lng: 17.6695718 },
    { lat: 48.8342468, lng: 17.6694578 },
    { lat: 48.8343445, lng: 17.6693745 },
    { lat: 48.8344312, lng: 17.6692989 },
    { lat: 48.8345234, lng: 17.6692182 },
    { lat: 48.8346293, lng: 17.6691791 },
    { lat: 48.8347283, lng: 17.6691009 },
    { lat: 48.834819, lng: 17.6690161 },
    { lat: 48.8349252, lng: 17.6689257 },
    { lat: 48.8350411, lng: 17.6688281 },
    { lat: 48.8351786, lng: 17.6687341 },
    { lat: 48.835275, lng: 17.6686657 },
    { lat: 48.8353753, lng: 17.6685916 },
    { lat: 48.8355102, lng: 17.668491 },
    { lat: 48.8356135, lng: 17.6684079 },
    { lat: 48.8357537, lng: 17.6683024 },
    { lat: 48.8358495, lng: 17.6682264 },
    { lat: 48.83598, lng: 17.6681283 },
    { lat: 48.8360885, lng: 17.6680213 },
    { lat: 48.8362044, lng: 17.6679064 },
    { lat: 48.8363326, lng: 17.6677872 },
    { lat: 48.8364431, lng: 17.6676776 },
    { lat: 48.8365574, lng: 17.6675706 },
    { lat: 48.8366888, lng: 17.6674511 },
    { lat: 48.8367957, lng: 17.6673536 },
    { lat: 48.836896, lng: 17.6672606 },
    { lat: 48.8370009, lng: 17.6671679 },
    { lat: 48.837099, lng: 17.6670753 },
    { lat: 48.837195, lng: 17.6669478 },
    { lat: 48.8372684, lng: 17.6668391 },
    { lat: 48.837342, lng: 17.666714 },
    { lat: 48.8373935, lng: 17.6666159 },
    { lat: 48.8374541, lng: 17.6665005 },
    { lat: 48.8375693, lng: 17.6663386 },
    { lat: 48.8376415, lng: 17.6662446 },
    { lat: 48.837682, lng: 17.666208 },
    { lat: 48.8377544, lng: 17.666137 },
    { lat: 48.8378679, lng: 17.6660371 },
    { lat: 48.8379427, lng: 17.6659691 },
    { lat: 48.838004, lng: 17.6659364 },
    { lat: 48.8380859, lng: 17.6658939 },
    { lat: 48.838191, lng: 17.6658388 },
    { lat: 48.8382897, lng: 17.6658102 },
    { lat: 48.8384629, lng: 17.6657633 },
    { lat: 48.8385717, lng: 17.6657342 },
    { lat: 48.838649, lng: 17.665706 },
    { lat: 48.838759, lng: 17.6656579 },
    { lat: 48.8388391, lng: 17.6656208 },
    { lat: 48.8389108, lng: 17.6656113 },
    { lat: 48.8390075, lng: 17.6655873 },
    { lat: 48.8391784, lng: 17.6655383 },
    { lat: 48.8392743, lng: 17.6654836 },
    { lat: 48.8393609, lng: 17.6654053 },
    { lat: 48.8394251, lng: 17.6653653 },
    { lat: 48.8395172, lng: 17.6653043 },
    { lat: 48.8395764, lng: 17.6652574 },
    { lat: 48.8396509, lng: 17.6652007 },
    { lat: 48.8397295, lng: 17.6651416 },
    { lat: 48.8398321, lng: 17.6650663 },
    { lat: 48.8399094, lng: 17.6650022 },
    { lat: 48.8399798, lng: 17.6649528 },
    { lat: 48.8400564, lng: 17.6648965 },
    { lat: 48.8401433, lng: 17.6648038 },
    { lat: 48.8402234, lng: 17.664712 },
    { lat: 48.8402913, lng: 17.6646415 },
    { lat: 48.8403627, lng: 17.6645545 },
    { lat: 48.84046, lng: 17.6644669 },
    { lat: 48.8405104, lng: 17.6644419 },
    { lat: 48.8406003, lng: 17.6643991 },
    { lat: 48.8406899, lng: 17.6643514 },
    { lat: 48.8407862, lng: 17.6643017 },
    { lat: 48.840866, lng: 17.6643134 },
    { lat: 48.8409131, lng: 17.6642725 },
    { lat: 48.840976, lng: 17.6642678 },
    { lat: 48.8410139, lng: 17.6642411 },
    { lat: 48.8410186, lng: 17.6643352 },
    { lat: 48.8410525, lng: 17.6644084 },
    { lat: 48.8410933, lng: 17.6645017 },
    { lat: 48.8411331, lng: 17.6646141 },
    { lat: 48.8411745, lng: 17.6647363 },
    { lat: 48.8412212, lng: 17.6648154 },
    { lat: 48.8412716, lng: 17.6648637 },
    { lat: 48.8413835, lng: 17.6649009 },
    { lat: 48.8415014, lng: 17.6649636 },
    { lat: 48.8415769, lng: 17.665035 },
    { lat: 48.8416311, lng: 17.665088 },
    { lat: 48.841685, lng: 17.6651556 },
    { lat: 48.8417476, lng: 17.665239 },
    { lat: 48.8418223, lng: 17.6653335 },
    { lat: 48.8418851, lng: 17.6653965 },
    { lat: 48.8419574, lng: 17.6654887 },
    { lat: 48.842014, lng: 17.6656201 },
    { lat: 48.8420583, lng: 17.6657131 },
    { lat: 48.8420963, lng: 17.6658539 },
    { lat: 48.8421436, lng: 17.6659592 },
    { lat: 48.8421877, lng: 17.6660529 },
    { lat: 48.8422454, lng: 17.6661816 },
    { lat: 48.8422874, lng: 17.6662398 },
    { lat: 48.842336, lng: 17.6663141 },
    { lat: 48.8423916, lng: 17.6663549 },
    { lat: 48.8424619, lng: 17.6664125 },
    { lat: 48.8424932, lng: 17.6664277 },
    { lat: 48.8425594, lng: 17.6664568 },
    { lat: 48.8425969, lng: 17.6664608 },
    { lat: 48.8426589, lng: 17.66644 },
    { lat: 48.8427407, lng: 17.6664122 },
    { lat: 48.842788, lng: 17.6664105 },
    { lat: 48.8428243, lng: 17.6664309 },
    { lat: 48.8429018, lng: 17.6664979 },
    { lat: 48.8429368, lng: 17.6665698 },
    { lat: 48.8429955, lng: 17.6666983 },
    { lat: 48.8430751, lng: 17.6668718 },
    { lat: 48.8431257, lng: 17.6670133 },
    { lat: 48.8431587, lng: 17.6671447 },
    { lat: 48.8431966, lng: 17.6673001 },
    { lat: 48.8432465, lng: 17.6675034 },
    { lat: 48.8432798, lng: 17.6676209 },
    { lat: 48.8433211, lng: 17.6677784 },
    { lat: 48.8433615, lng: 17.6678838 },
    { lat: 48.8434279, lng: 17.6679891 },
    { lat: 48.8435149, lng: 17.6680631 },
    { lat: 48.8436699, lng: 17.6681213 },
    { lat: 48.8437527, lng: 17.668219 },
    { lat: 48.8438385, lng: 17.6682914 },
    { lat: 48.8439038, lng: 17.6684884 },
    { lat: 48.843942, lng: 17.668559 },
    { lat: 48.8439992, lng: 17.6686433 },
    { lat: 48.8441152, lng: 17.6687474 },
    { lat: 48.8441779, lng: 17.668758 },
    { lat: 48.8443447, lng: 17.66879 },
    { lat: 48.8445039, lng: 17.6687887 },
    { lat: 48.8446313, lng: 17.6687508 },
    { lat: 48.8447667, lng: 17.6687127 },
    { lat: 48.8448557, lng: 17.6686923 },
    { lat: 48.8449621, lng: 17.6687491 },
    { lat: 48.8450614, lng: 17.6688229 },
    { lat: 48.8451509, lng: 17.6688616 },
    { lat: 48.8452136, lng: 17.6689099 },
    { lat: 48.8452814, lng: 17.6689653 },
    { lat: 48.8453388, lng: 17.6689614 },
    { lat: 48.8453649, lng: 17.6689466 },
    { lat: 48.8454475, lng: 17.6689126 },
    { lat: 48.8455443, lng: 17.6688698 },
    { lat: 48.8456749, lng: 17.6688648 },
    { lat: 48.8458383, lng: 17.6688595 },
    { lat: 48.8460177, lng: 17.6688391 },
    { lat: 48.8460626, lng: 17.6688173 },
    { lat: 48.8461409, lng: 17.6687874 },
    { lat: 48.846323, lng: 17.6687195 },
    { lat: 48.8463806, lng: 17.668702 },
    { lat: 48.8464637, lng: 17.6686763 },
    { lat: 48.8465689, lng: 17.6686606 },
    { lat: 48.8466623, lng: 17.6686558 },
    { lat: 48.8467711, lng: 17.6686446 },
    { lat: 48.8468595, lng: 17.6686123 },
    { lat: 48.8469934, lng: 17.6685694 },
    { lat: 48.8471119, lng: 17.668531 },
    { lat: 48.8472282, lng: 17.6684958 },
    { lat: 48.8473277, lng: 17.6684438 },
    { lat: 48.8474429, lng: 17.6683872 },
    { lat: 48.8475311, lng: 17.6683191 },
    { lat: 48.8476573, lng: 17.6682224 },
    { lat: 48.8476971, lng: 17.6681739 },
    { lat: 48.8478108, lng: 17.6680577 },
    { lat: 48.8479216, lng: 17.6679324 },
    { lat: 48.8480193, lng: 17.6678877 },
    { lat: 48.8480576, lng: 17.6678498 },
    { lat: 48.8481162, lng: 17.6677773 },
    { lat: 48.8481871, lng: 17.6676817 },
    { lat: 48.8482815, lng: 17.6676015 },
    { lat: 48.8483665, lng: 17.6675339 },
    { lat: 48.8484573, lng: 17.6674491 },
    { lat: 48.8485484, lng: 17.6673547 },
    { lat: 48.8486286, lng: 17.667263 },
    { lat: 48.8487557, lng: 17.6672184 },
    { lat: 48.848832, lng: 17.6672476 },
    { lat: 48.8489095, lng: 17.667276 },
    { lat: 48.8489767, lng: 17.6672869 },
    { lat: 48.8491925, lng: 17.667216 },
    { lat: 48.8492517, lng: 17.6672058 },
    { lat: 48.8493504, lng: 17.6671944 },
    { lat: 48.8494198, lng: 17.6671476 },
    { lat: 48.8495037, lng: 17.6671538 },
    { lat: 48.8496102, lng: 17.6671248 },
    { lat: 48.8497092, lng: 17.6670996 },
    { lat: 48.8498243, lng: 17.667043 },
    { lat: 48.8499258, lng: 17.6670055 },
    { lat: 48.8500262, lng: 17.666968 },
    { lat: 48.8501017, lng: 17.6669659 },
    { lat: 48.8501672, lng: 17.6669479 },
    { lat: 48.8502384, lng: 17.6669301 },
    { lat: 48.850351, lng: 17.6669064 },
    { lat: 48.8504403, lng: 17.6669451 },
    { lat: 48.8505539, lng: 17.6669931 },
    { lat: 48.8506622, lng: 17.6670999 },
    { lat: 48.8507958, lng: 17.6671065 },
    { lat: 48.8508804, lng: 17.6670877 },
    { lat: 48.8509683, lng: 17.6670674 },
    { lat: 48.8510394, lng: 17.6670283 },
    { lat: 48.8511049, lng: 17.6669915 },
    { lat: 48.851174, lng: 17.6669236 },
    { lat: 48.8512491, lng: 17.6669129 },
    { lat: 48.8513498, lng: 17.666901 },
    { lat: 48.8514295, lng: 17.6668734 },
    { lat: 48.8515196, lng: 17.666834 },
    { lat: 48.8516172, lng: 17.6668416 },
    { lat: 48.8516149, lng: 17.6668608 },
    { lat: 48.8516919, lng: 17.6668634 },
    { lat: 48.8518852, lng: 17.6668667 },
    { lat: 48.8519768, lng: 17.6668698 },
    { lat: 48.8520912, lng: 17.6668741 },
    { lat: 48.8522063, lng: 17.6668748 },
    { lat: 48.8523538, lng: 17.6669762 },
    { lat: 48.8524502, lng: 17.6670378 },
    { lat: 48.8525376, lng: 17.6670997 },
    { lat: 48.8526401, lng: 17.667169 },
    { lat: 48.852752, lng: 17.6672445 },
    { lat: 48.8528488, lng: 17.6673112 },
    { lat: 48.8529467, lng: 17.6673785 },
    { lat: 48.8530481, lng: 17.667447 },
    { lat: 48.8531295, lng: 17.6675048 },
    { lat: 48.8532285, lng: 17.667571 },
    { lat: 48.8533405, lng: 17.6676474 },
    { lat: 48.8534371, lng: 17.6677141 },
    { lat: 48.8535266, lng: 17.6677715 },
    { lat: 48.8536247, lng: 17.6678432 },
    { lat: 48.8537248, lng: 17.6679092 },
    { lat: 48.8538259, lng: 17.6680284 },
    { lat: 48.8538981, lng: 17.668119 },
    { lat: 48.8539615, lng: 17.6681937 },
    { lat: 48.8540346, lng: 17.6682817 },
    { lat: 48.8541096, lng: 17.6683781 },
    { lat: 48.854227, lng: 17.668497 },
    { lat: 48.854169, lng: 17.667759 },
    { lat: 48.854133, lng: 17.667086 },
    { lat: 48.854199, lng: 17.666721 },
    { lat: 48.854259, lng: 17.66639 },
    { lat: 48.8542769, lng: 17.6661414 },
    { lat: 48.85431, lng: 17.66568 },
    { lat: 48.854367, lng: 17.665163 },
    { lat: 48.8543671, lng: 17.6651619 },
    { lat: 48.854427, lng: 17.664621 },
    { lat: 48.854683, lng: 17.664147 },
    { lat: 48.854832, lng: 17.663627 },
    { lat: 48.854868, lng: 17.663133 },
    { lat: 48.854876, lng: 17.663025 },
    { lat: 48.854904, lng: 17.662669 },
    { lat: 48.855113, lng: 17.662024 },
    { lat: 48.855173, lng: 17.661825 },
    { lat: 48.855247, lng: 17.661461 },
    { lat: 48.855318, lng: 17.661182 },
    { lat: 48.855344, lng: 17.661036 },
    { lat: 48.855394, lng: 17.660862 },
    { lat: 48.855479, lng: 17.660577 },
    { lat: 48.855557, lng: 17.660261 },
    { lat: 48.8556, lng: 17.660104 },
    { lat: 48.855676, lng: 17.659944 },
    { lat: 48.855704, lng: 17.659846 },
    { lat: 48.855731, lng: 17.659714 },
    { lat: 48.855738, lng: 17.659652 },
    { lat: 48.855758, lng: 17.659264 },
    { lat: 48.855756, lng: 17.659243 },
    { lat: 48.85576, lng: 17.65898 },
    { lat: 48.855779, lng: 17.658713 },
    { lat: 48.855796, lng: 17.658432 },
    { lat: 48.855794, lng: 17.658294 },
    { lat: 48.855774, lng: 17.657989 },
    { lat: 48.855764, lng: 17.657723 },
    { lat: 48.855766, lng: 17.657446 },
    { lat: 48.855752, lng: 17.657042 },
    { lat: 48.855755, lng: 17.656743 },
    { lat: 48.855777, lng: 17.656421 },
    { lat: 48.855787, lng: 17.656316 },
    { lat: 48.855789, lng: 17.656119 },
    { lat: 48.855765, lng: 17.655694 },
    { lat: 48.855777, lng: 17.65538 },
    { lat: 48.855759, lng: 17.654952 },
    { lat: 48.855761, lng: 17.654749 },
    { lat: 48.855751, lng: 17.65463 },
    { lat: 48.855719, lng: 17.654515 },
    { lat: 48.855642, lng: 17.654238 },
    { lat: 48.855562, lng: 17.653961 },
    { lat: 48.85552, lng: 17.653792 },
    { lat: 48.855488, lng: 17.653595 },
    { lat: 48.855452, lng: 17.653337 },
    { lat: 48.855435, lng: 17.653135 },
    { lat: 48.855398, lng: 17.652817 },
    { lat: 48.85537, lng: 17.652698 },
    { lat: 48.855322, lng: 17.652513 },
    { lat: 48.855297, lng: 17.652343 },
    { lat: 48.855278, lng: 17.652105 },
    { lat: 48.855263, lng: 17.651985 },
    { lat: 48.85525, lng: 17.651942 },
    { lat: 48.855205, lng: 17.651794 },
    { lat: 48.855114, lng: 17.651621 },
    { lat: 48.855054, lng: 17.651479 },
    { lat: 48.855015, lng: 17.651349 },
    { lat: 48.854968, lng: 17.651169 },
    { lat: 48.854954, lng: 17.651124 },
    { lat: 48.854908, lng: 17.650913 },
    { lat: 48.854865, lng: 17.650682 },
    { lat: 48.854824, lng: 17.650448 },
    { lat: 48.854794, lng: 17.650158 },
    { lat: 48.854751, lng: 17.649963 },
    { lat: 48.854726, lng: 17.649879 },
    { lat: 48.854683, lng: 17.64976 },
    { lat: 48.854629, lng: 17.649628 },
    { lat: 48.8546, lng: 17.649571 },
    { lat: 48.854531, lng: 17.649444 },
    { lat: 48.854484, lng: 17.64934 },
    { lat: 48.854434, lng: 17.649191 },
    { lat: 48.854381, lng: 17.649025 },
    { lat: 48.854319, lng: 17.648817 },
    { lat: 48.8543, lng: 17.648751 },
    { lat: 48.854253, lng: 17.648516 },
    { lat: 48.854228, lng: 17.64838 },
    { lat: 48.854192, lng: 17.648142 },
    { lat: 48.854195, lng: 17.648097 },
    { lat: 48.854204, lng: 17.648003 },
    { lat: 48.854219, lng: 17.647815 },
    { lat: 48.854225, lng: 17.647605 },
    { lat: 48.8542216, lng: 17.6472481 },
    { lat: 48.8542623, lng: 17.6469364 },
    { lat: 48.854288, lng: 17.646642 },
    { lat: 48.854416, lng: 17.646148 },
    { lat: 48.854478, lng: 17.645949 },
    { lat: 48.854535, lng: 17.645765 },
    { lat: 48.854588, lng: 17.645531 },
    { lat: 48.854618, lng: 17.645443 },
    { lat: 48.854648, lng: 17.645332 },
    { lat: 48.8546628, lng: 17.6452676 },
    { lat: 48.854682, lng: 17.645055 },
    { lat: 48.854694, lng: 17.6449 },
    { lat: 48.85472, lng: 17.644599 },
    { lat: 48.854728, lng: 17.644358 },
    { lat: 48.854722, lng: 17.644022 },
    { lat: 48.854712, lng: 17.643835 },
    { lat: 48.854696, lng: 17.643624 },
    { lat: 48.854675, lng: 17.643155 },
    { lat: 48.854651, lng: 17.641841 },
    { lat: 48.854653, lng: 17.641708 },
    { lat: 48.854666, lng: 17.641356 },
    { lat: 48.854674, lng: 17.641049 },
    { lat: 48.854688, lng: 17.640798 },
    { lat: 48.854711, lng: 17.64052 },
    { lat: 48.854762, lng: 17.640266 },
    { lat: 48.8548, lng: 17.640055 },
    { lat: 48.854852, lng: 17.639768 },
    { lat: 48.854904, lng: 17.639461 },
    { lat: 48.854938, lng: 17.63929 },
    { lat: 48.854984, lng: 17.639059 },
    { lat: 48.855033, lng: 17.638821 },
    { lat: 48.855091, lng: 17.638529 },
    { lat: 48.855176, lng: 17.638062 },
    { lat: 48.85518, lng: 17.637962 },
    { lat: 48.855181, lng: 17.637781 },
    { lat: 48.855159, lng: 17.637451 },
    { lat: 48.855154, lng: 17.637161 },
    { lat: 48.855135, lng: 17.636814 },
    { lat: 48.855109, lng: 17.636534 },
    { lat: 48.85508, lng: 17.636286 },
    { lat: 48.855053, lng: 17.63591 },
    { lat: 48.855034, lng: 17.635515 },
    { lat: 48.855009, lng: 17.635049 },
    { lat: 48.854985, lng: 17.634644 },
    { lat: 48.854969, lng: 17.634294 },
    { lat: 48.854958, lng: 17.633904 },
    { lat: 48.854936, lng: 17.633447 },
    { lat: 48.854924, lng: 17.633182 },
    { lat: 48.854909, lng: 17.633074 },
    { lat: 48.85489, lng: 17.632981 },
    { lat: 48.854846, lng: 17.632845 },
    { lat: 48.854813, lng: 17.632797 },
    { lat: 48.854691, lng: 17.632567 },
    { lat: 48.854344, lng: 17.631941 },
    { lat: 48.854251, lng: 17.631784 },
    { lat: 48.854061, lng: 17.631432 },
    { lat: 48.85388, lng: 17.631077 },
    { lat: 48.853723, lng: 17.630795 },
    { lat: 48.853543, lng: 17.63049 },
    { lat: 48.853526, lng: 17.630467 },
    { lat: 48.853373, lng: 17.630207 },
    { lat: 48.85332, lng: 17.630105 },
    { lat: 48.853194, lng: 17.629892 },
    { lat: 48.853022, lng: 17.629585 },
    { lat: 48.852912, lng: 17.629366 },
    { lat: 48.852757, lng: 17.629098 },
    { lat: 48.852598, lng: 17.628804 },
    { lat: 48.852453, lng: 17.628567 },
    { lat: 48.852325, lng: 17.628361 },
    { lat: 48.852162, lng: 17.628088 },
    { lat: 48.852008, lng: 17.627848 },
    { lat: 48.851829, lng: 17.62758 },
    { lat: 48.85168, lng: 17.627337 },
    { lat: 48.851562, lng: 17.627148 },
    { lat: 48.851397, lng: 17.626873 },
    { lat: 48.851302, lng: 17.626743 },
    { lat: 48.85124, lng: 17.626655 },
    { lat: 48.851146, lng: 17.626529 },
    { lat: 48.850881, lng: 17.626241 },
    { lat: 48.850828, lng: 17.626169 },
    { lat: 48.850586, lng: 17.625858 },
    { lat: 48.85029, lng: 17.625474 },
    { lat: 48.850127, lng: 17.625279 },
    { lat: 48.850014, lng: 17.62514 },
    { lat: 48.849827, lng: 17.624802 },
    { lat: 48.849644, lng: 17.624442 },
    { lat: 48.849493, lng: 17.624151 },
    { lat: 48.849319, lng: 17.623798 },
    { lat: 48.84925, lng: 17.623664 },
    { lat: 48.849108, lng: 17.623474 },
    { lat: 48.848957, lng: 17.623284 },
    { lat: 48.848798, lng: 17.623084 },
    { lat: 48.848652, lng: 17.622877 },
    { lat: 48.848495, lng: 17.622657 },
    { lat: 48.848175, lng: 17.622324 },
    { lat: 48.848001, lng: 17.622118 },
    { lat: 48.847811, lng: 17.621883 },
    { lat: 48.847572, lng: 17.621591 },
    { lat: 48.847375, lng: 17.621318 },
    { lat: 48.84698, lng: 17.620857 },
    { lat: 48.846732, lng: 17.620536 },
    { lat: 48.845701, lng: 17.62069 },
    { lat: 48.844744, lng: 17.62078 },
    { lat: 48.843851, lng: 17.620869 },
    { lat: 48.84287, lng: 17.621015 },
    { lat: 48.842452, lng: 17.620957 },
    { lat: 48.842023, lng: 17.620949 },
    { lat: 48.841901, lng: 17.620965 },
    { lat: 48.84173, lng: 17.620985 },
    { lat: 48.841506, lng: 17.62101 },
    { lat: 48.841244, lng: 17.62095 },
    { lat: 48.841183, lng: 17.620944 },
    { lat: 48.841103, lng: 17.620885 },
    { lat: 48.840916, lng: 17.620793 },
    { lat: 48.840845, lng: 17.620778 },
    { lat: 48.840639, lng: 17.620733 },
    { lat: 48.840572, lng: 17.620675 },
    { lat: 48.840534, lng: 17.620663 },
    { lat: 48.840526, lng: 17.62065 },
    { lat: 48.8404, lng: 17.620396 },
    { lat: 48.840357, lng: 17.620307 },
    { lat: 48.840196, lng: 17.620186 },
    { lat: 48.839966, lng: 17.620072 },
    { lat: 48.83981, lng: 17.619967 },
    { lat: 48.839575, lng: 17.619834 },
    { lat: 48.83951, lng: 17.619797 },
    { lat: 48.839436, lng: 17.619778 },
    { lat: 48.839237, lng: 17.619618 },
    { lat: 48.839119, lng: 17.619523 },
    { lat: 48.839021, lng: 17.619484 },
    { lat: 48.838856, lng: 17.619265 },
    { lat: 48.838599, lng: 17.618934 },
    { lat: 48.838554, lng: 17.618721 },
    { lat: 48.838548, lng: 17.618688 },
    { lat: 48.838432, lng: 17.618583 },
    { lat: 48.83829, lng: 17.618588 },
    { lat: 48.83818, lng: 17.618551 },
    { lat: 48.83801, lng: 17.618431 },
    { lat: 48.83795, lng: 17.61834 },
    { lat: 48.837877, lng: 17.618261 },
    { lat: 48.837722, lng: 17.618152 },
    { lat: 48.837599, lng: 17.618069 },
    { lat: 48.837497, lng: 17.618016 },
    { lat: 48.837414, lng: 17.617949 },
    { lat: 48.837258, lng: 17.617757 },
    { lat: 48.837095, lng: 17.61757 },
    { lat: 48.836895, lng: 17.617353 },
    { lat: 48.836733, lng: 17.617224 },
    { lat: 48.836612, lng: 17.617068 },
    { lat: 48.836548, lng: 17.617023 },
    { lat: 48.836483, lng: 17.616946 },
    { lat: 48.836408, lng: 17.616881 },
    { lat: 48.836292, lng: 17.61682 },
    { lat: 48.83615, lng: 17.61679 },
    { lat: 48.836107, lng: 17.61678 },
    { lat: 48.83604, lng: 17.616749 },
    { lat: 48.835917, lng: 17.616709 },
    { lat: 48.835805, lng: 17.616688 },
    { lat: 48.835669, lng: 17.616587 },
    { lat: 48.835528, lng: 17.616498 },
    { lat: 48.835407, lng: 17.616396 },
    { lat: 48.835356, lng: 17.616383 },
    { lat: 48.83525, lng: 17.61628 },
    { lat: 48.835168, lng: 17.61625 },
    { lat: 48.834942, lng: 17.616173 },
    { lat: 48.834824, lng: 17.616116 },
    { lat: 48.834699, lng: 17.616069 },
    { lat: 48.834575, lng: 17.616065 },
    { lat: 48.83444, lng: 17.616059 },
    { lat: 48.834388, lng: 17.616033 },
    { lat: 48.834276, lng: 17.615945 },
    { lat: 48.834002, lng: 17.615727 },
    { lat: 48.833938, lng: 17.615643 },
    { lat: 48.833848, lng: 17.615503 },
    { lat: 48.833654, lng: 17.615364 },
    { lat: 48.833472, lng: 17.61526 },
    { lat: 48.833307, lng: 17.615162 },
    { lat: 48.833241, lng: 17.615071 },
    { lat: 48.833043, lng: 17.615014 },
    { lat: 48.83291, lng: 17.61498 },
    { lat: 48.832689, lng: 17.614908 },
    { lat: 48.83254, lng: 17.614891 },
    { lat: 48.832485, lng: 17.614889 },
    { lat: 48.832353, lng: 17.614933 },
    { lat: 48.832179, lng: 17.61496 },
    { lat: 48.83206, lng: 17.614924 },
    { lat: 48.831816, lng: 17.614992 },
    { lat: 48.831704, lng: 17.614951 },
    { lat: 48.831545, lng: 17.614871 },
    { lat: 48.831397, lng: 17.614794 },
    { lat: 48.831325, lng: 17.61476 },
    { lat: 48.831189, lng: 17.614733 },
    { lat: 48.831119, lng: 17.614714 },
    { lat: 48.831037, lng: 17.614728 },
    { lat: 48.830857, lng: 17.614713 },
    { lat: 48.830672, lng: 17.614653 },
    { lat: 48.830432, lng: 17.614594 },
    { lat: 48.830225, lng: 17.614547 },
    { lat: 48.830069, lng: 17.614521 },
    { lat: 48.829851, lng: 17.614594 },
    { lat: 48.829679, lng: 17.614572 },
    { lat: 48.82955, lng: 17.614525 },
    { lat: 48.829341, lng: 17.614483 },
    { lat: 48.829124, lng: 17.614496 },
    { lat: 48.829065, lng: 17.614483 },
    { lat: 48.828874, lng: 17.614464 },
    { lat: 48.828781, lng: 17.614457 },
    { lat: 48.828728, lng: 17.614456 },
    { lat: 48.828548, lng: 17.61447 },
    { lat: 48.828388, lng: 17.614468 },
    { lat: 48.828266, lng: 17.614485 },
    { lat: 48.828015, lng: 17.614401 },
    { lat: 48.827896, lng: 17.614385 },
    { lat: 48.82776, lng: 17.614367 },
    { lat: 48.82761, lng: 17.614374 },
    { lat: 48.827346, lng: 17.61431 },
    { lat: 48.827162, lng: 17.614237 },
    { lat: 48.827016, lng: 17.614197 },
    { lat: 48.826906, lng: 17.614151 },
    { lat: 48.826721, lng: 17.614131 },
    { lat: 48.826541, lng: 17.614108 },
    { lat: 48.826475, lng: 17.614104 },
    { lat: 48.826402, lng: 17.614109 },
    { lat: 48.826328, lng: 17.614094 },
    { lat: 48.826196, lng: 17.614064 },
    { lat: 48.826175, lng: 17.614038 },
    { lat: 48.826165, lng: 17.614028 },
    { lat: 48.826092, lng: 17.614487 },
    { lat: 48.82604, lng: 17.614625 },
    { lat: 48.825965, lng: 17.614888 },
    { lat: 48.825895, lng: 17.615157 },
    { lat: 48.825809, lng: 17.615471 },
    { lat: 48.8257862, lng: 17.6155619 },
    { lat: 48.825734, lng: 17.61577 },
    { lat: 48.825651, lng: 17.616093 },
    { lat: 48.825572, lng: 17.616402 },
    { lat: 48.825608, lng: 17.616616 },
    { lat: 48.82557, lng: 17.616888 },
    { lat: 48.825531, lng: 17.616968 },
    { lat: 48.825306, lng: 17.616992 },
    { lat: 48.825174, lng: 17.617005 },
    { lat: 48.824853, lng: 17.617118 },
    { lat: 48.824716, lng: 17.617226 },
    { lat: 48.824403, lng: 17.617335 },
    { lat: 48.824302, lng: 17.61737 },
    { lat: 48.824176, lng: 17.617302 },
    { lat: 48.823976, lng: 17.617228 },
    { lat: 48.823794, lng: 17.617332 },
    { lat: 48.823743, lng: 17.617373 },
    { lat: 48.823669, lng: 17.617537 },
    { lat: 48.823488, lng: 17.617824 },
    { lat: 48.823416, lng: 17.618014 },
    { lat: 48.823213, lng: 17.618267 },
    { lat: 48.82309, lng: 17.61841 },
    { lat: 48.822871, lng: 17.6185 },
    { lat: 48.822708, lng: 17.618476 },
    { lat: 48.8225, lng: 17.618394 },
    { lat: 48.822301, lng: 17.618437 },
    { lat: 48.82209, lng: 17.618494 },
    { lat: 48.821983, lng: 17.618513 },
    { lat: 48.821915, lng: 17.618573 },
    { lat: 48.82182, lng: 17.618565 },
    { lat: 48.821532, lng: 17.618632 },
    { lat: 48.821336, lng: 17.618783 },
    { lat: 48.821217, lng: 17.618975 },
    { lat: 48.821032, lng: 17.619125 },
    { lat: 48.820808, lng: 17.61932 },
    { lat: 48.820608, lng: 17.619442 },
    { lat: 48.820428, lng: 17.619557 },
    { lat: 48.820337, lng: 17.619615 },
    { lat: 48.820177, lng: 17.619733 },
    { lat: 48.820093, lng: 17.619798 },
    { lat: 48.819929, lng: 17.619779 },
    { lat: 48.819842, lng: 17.619767 },
    { lat: 48.819706, lng: 17.619524 },
    { lat: 48.819534, lng: 17.619475 },
    { lat: 48.819376, lng: 17.619425 },
    { lat: 48.819204, lng: 17.619416 },
    { lat: 48.819035, lng: 17.619363 },
    { lat: 48.818882, lng: 17.619356 },
    { lat: 48.818699, lng: 17.619348 },
    { lat: 48.81858, lng: 17.619282 },
    { lat: 48.818412, lng: 17.619213 },
    { lat: 48.818197, lng: 17.619173 },
    { lat: 48.817961, lng: 17.619134 },
    { lat: 48.81785, lng: 17.619116 },
    { lat: 48.817655, lng: 17.619053 },
    { lat: 48.817564, lng: 17.619023 },
    { lat: 48.817408, lng: 17.61894 },
    { lat: 48.817247, lng: 17.618853 },
    { lat: 48.816961, lng: 17.618901 },
    { lat: 48.81675, lng: 17.619059 },
    { lat: 48.816546, lng: 17.619207 },
    { lat: 48.816379, lng: 17.619248 },
    { lat: 48.816217, lng: 17.619329 },
    { lat: 48.816018, lng: 17.619368 },
    { lat: 48.815806, lng: 17.619406 },
    { lat: 48.815713, lng: 17.619415 },
    { lat: 48.815573, lng: 17.619411 },
    { lat: 48.815528, lng: 17.619416 },
    { lat: 48.815448, lng: 17.619442 },
    { lat: 48.815374, lng: 17.619479 },
    { lat: 48.815135, lng: 17.619561 },
    { lat: 48.814963, lng: 17.619574 },
    { lat: 48.814822, lng: 17.619583 },
    { lat: 48.814575, lng: 17.619645 },
    { lat: 48.814382, lng: 17.619684 },
    { lat: 48.814059, lng: 17.619854 },
    { lat: 48.813983, lng: 17.619929 },
    { lat: 48.813902, lng: 17.6201 },
    { lat: 48.813787, lng: 17.620181 },
    { lat: 48.813446, lng: 17.620294 },
    { lat: 48.813162, lng: 17.620381 },
    { lat: 48.812944, lng: 17.620548 },
    { lat: 48.812733, lng: 17.620712 },
    { lat: 48.812703, lng: 17.620734 },
    { lat: 48.812488, lng: 17.620867 },
    { lat: 48.8124, lng: 17.620921 },
    { lat: 48.812257, lng: 17.621082 },
    { lat: 48.81206, lng: 17.621266 },
    { lat: 48.811965, lng: 17.621349 },
    { lat: 48.811811, lng: 17.621518 },
    { lat: 48.811654, lng: 17.621702 },
    { lat: 48.81148, lng: 17.621781 },
    { lat: 48.811211, lng: 17.621995 },
    { lat: 48.811088, lng: 17.622129 },
    { lat: 48.810852, lng: 17.62223 },
    { lat: 48.8106, lng: 17.622436 },
    { lat: 48.810499, lng: 17.622548 },
    { lat: 48.810375, lng: 17.622678 },
    { lat: 48.81023, lng: 17.622792 },
    { lat: 48.809978, lng: 17.622889 },
    { lat: 48.809823, lng: 17.62291 },
    { lat: 48.809612, lng: 17.622847 },
    { lat: 48.809363, lng: 17.622828 },
    { lat: 48.809025, lng: 17.622799 },
    { lat: 48.808921, lng: 17.62273 },
    { lat: 48.80863, lng: 17.622315 },
    { lat: 48.808513, lng: 17.62203 },
    { lat: 48.808341, lng: 17.621785 },
    { lat: 48.808197, lng: 17.621576 },
    { lat: 48.80798, lng: 17.621387 },
    { lat: 48.807735, lng: 17.621276 },
    { lat: 48.807544, lng: 17.621193 },
    { lat: 48.807357, lng: 17.621173 },
    { lat: 48.807224, lng: 17.621153 },
    { lat: 48.806961, lng: 17.621078 },
    { lat: 48.806768, lng: 17.621012 },
    { lat: 48.806537, lng: 17.620949 },
    { lat: 48.806357, lng: 17.620888 },
    { lat: 48.806221, lng: 17.620846 },
    { lat: 48.80593, lng: 17.62071 },
    { lat: 48.805759, lng: 17.620604 },
    { lat: 48.805595, lng: 17.620503 },
    { lat: 48.80533, lng: 17.620343 },
    { lat: 48.805128, lng: 17.620253 },
    { lat: 48.804999, lng: 17.620195 },
    { lat: 48.804794, lng: 17.620155 },
    { lat: 48.804579, lng: 17.620117 },
    { lat: 48.804371, lng: 17.620229 },
    { lat: 48.804258, lng: 17.620268 },
    { lat: 48.803985, lng: 17.620301 },
    { lat: 48.803827, lng: 17.620416 },
    { lat: 48.803652, lng: 17.620426 },
    { lat: 48.803455, lng: 17.620441 },
    { lat: 48.803303, lng: 17.620464 },
    { lat: 48.80314, lng: 17.620564 },
    { lat: 48.802998, lng: 17.620656 },
    { lat: 48.802784, lng: 17.620711 },
    { lat: 48.802659, lng: 17.62075 },
    { lat: 48.802537, lng: 17.62082 },
    { lat: 48.802412, lng: 17.620892 },
    { lat: 48.802309, lng: 17.620948 },
    { lat: 48.802226, lng: 17.620989 },
    { lat: 48.802099, lng: 17.620982 },
    { lat: 48.801813, lng: 17.620973 },
    { lat: 48.801519, lng: 17.621033 },
    { lat: 48.801393, lng: 17.621073 },
    { lat: 48.801283, lng: 17.621095 },
    { lat: 48.801087, lng: 17.621219 },
    { lat: 48.801018, lng: 17.621258 },
    { lat: 48.800587, lng: 17.621357 },
    { lat: 48.800508, lng: 17.621375 },
    { lat: 48.800265, lng: 17.621487 },
    { lat: 48.799967, lng: 17.621768 },
    { lat: 48.799664, lng: 17.621988 },
    { lat: 48.799451, lng: 17.622084 },
    { lat: 48.799147, lng: 17.622222 },
    { lat: 48.798864, lng: 17.622323 },
    { lat: 48.79878, lng: 17.622352 },
    { lat: 48.798537, lng: 17.62241 },
    { lat: 48.798363, lng: 17.622454 },
    { lat: 48.798151, lng: 17.622575 },
    { lat: 48.79791, lng: 17.622708 },
    { lat: 48.797724, lng: 17.622736 },
    { lat: 48.797576, lng: 17.622761 },
    { lat: 48.797364, lng: 17.622884 },
    { lat: 48.797246, lng: 17.622954 },
    { lat: 48.797142, lng: 17.623007 },
    { lat: 48.796843, lng: 17.623134 },
    { lat: 48.796576, lng: 17.623091 },
    { lat: 48.796479, lng: 17.623069 },
    { lat: 48.796065, lng: 17.62302 },
    { lat: 48.795801, lng: 17.623097 },
    { lat: 48.795574, lng: 17.623144 },
    { lat: 48.795493, lng: 17.623217 },
    { lat: 48.795435, lng: 17.623292 },
    { lat: 48.795032, lng: 17.623776 },
    { lat: 48.794783, lng: 17.624128 },
    { lat: 48.794318, lng: 17.624864 },
    { lat: 48.794039, lng: 17.625234 },
    { lat: 48.793821, lng: 17.62544 },
    { lat: 48.793687, lng: 17.62565 },
    { lat: 48.793574, lng: 17.625804 },
    { lat: 48.79341, lng: 17.625979 },
    { lat: 48.793208, lng: 17.62626 },
    { lat: 48.792966, lng: 17.626614 },
    { lat: 48.792756, lng: 17.626901 },
    { lat: 48.792772, lng: 17.626914 },
    { lat: 48.792253, lng: 17.627829 },
    { lat: 48.791981, lng: 17.628307 },
    { lat: 48.791778, lng: 17.628681 },
    { lat: 48.791595, lng: 17.629016 },
    { lat: 48.791191, lng: 17.62955 },
    { lat: 48.790373, lng: 17.63062 },
    { lat: 48.790185, lng: 17.630809 },
    { lat: 48.789007, lng: 17.631989 },
    { lat: 48.788841, lng: 17.632061 },
    { lat: 48.788236, lng: 17.632306 },
    { lat: 48.788011, lng: 17.632404 },
    { lat: 48.787599, lng: 17.632552 },
    { lat: 48.787403, lng: 17.632559 },
    { lat: 48.787371, lng: 17.632559 },
    { lat: 48.787269, lng: 17.632565 },
    { lat: 48.786889, lng: 17.632586 },
    { lat: 48.78673, lng: 17.632586 },
    { lat: 48.78666, lng: 17.632571 },
    { lat: 48.786564, lng: 17.63261 },
    { lat: 48.786496, lng: 17.632582 },
    { lat: 48.786467, lng: 17.632571 },
    { lat: 48.786444, lng: 17.632551 },
    { lat: 48.786404, lng: 17.632514 },
    { lat: 48.786198, lng: 17.63291 },
    { lat: 48.786027, lng: 17.633119 },
    { lat: 48.785895, lng: 17.633344 },
    { lat: 48.785846, lng: 17.63323 },
    { lat: 48.785818, lng: 17.633155 },
    { lat: 48.785702, lng: 17.632755 },
    { lat: 48.785593, lng: 17.632712 },
    { lat: 48.785525, lng: 17.63266 },
    { lat: 48.785507, lng: 17.632648 },
    { lat: 48.785442, lng: 17.632612 },
    { lat: 48.78542, lng: 17.632597 },
    { lat: 48.78555, lng: 17.632285 },
    { lat: 48.785678, lng: 17.631966 },
    { lat: 48.785831, lng: 17.631538 },
    { lat: 48.786033, lng: 17.631137 },
    { lat: 48.786294, lng: 17.630392 },
    { lat: 48.786742, lng: 17.628989 },
    { lat: 48.786823, lng: 17.628654 },
    { lat: 48.786952, lng: 17.628273 },
    { lat: 48.787055, lng: 17.627853 },
    { lat: 48.787099, lng: 17.627633 },
    { lat: 48.78717, lng: 17.62713 },
    { lat: 48.787154, lng: 17.627114 },
    { lat: 48.787121, lng: 17.627084 },
    { lat: 48.786682, lng: 17.626716 },
    { lat: 48.786558, lng: 17.62699 },
    { lat: 48.786488, lng: 17.627214 },
    { lat: 48.786476, lng: 17.627289 },
    { lat: 48.786449, lng: 17.627632 },
    { lat: 48.78638, lng: 17.627941 },
    { lat: 48.786248, lng: 17.628189 },
    { lat: 48.786121, lng: 17.628291 },
    { lat: 48.786045, lng: 17.628364 },
    { lat: 48.785911, lng: 17.62873 },
    { lat: 48.785836, lng: 17.628988 },
    { lat: 48.785565, lng: 17.629644 },
    { lat: 48.785463, lng: 17.629568 },
    { lat: 48.785338, lng: 17.629459 },
    { lat: 48.785271, lng: 17.629352 },
    { lat: 48.785103, lng: 17.629195 },
    { lat: 48.78502, lng: 17.62915 },
    { lat: 48.784828, lng: 17.629021 },
    { lat: 48.784656, lng: 17.628869 },
    { lat: 48.784513, lng: 17.628731 },
    { lat: 48.784382, lng: 17.628601 },
    { lat: 48.784248, lng: 17.628464 },
    { lat: 48.783893, lng: 17.628073 },
    { lat: 48.783736, lng: 17.6279 },
    { lat: 48.783612, lng: 17.62776 },
    { lat: 48.783487, lng: 17.627611 },
    { lat: 48.78337, lng: 17.627477 },
    { lat: 48.78334, lng: 17.627436 },
    { lat: 48.783296, lng: 17.627372 },
    { lat: 48.783227, lng: 17.627278 },
    { lat: 48.783192, lng: 17.627233 },
    { lat: 48.783145, lng: 17.627179 },
    { lat: 48.783089, lng: 17.627115 },
    { lat: 48.783029, lng: 17.627081 },
    { lat: 48.782869, lng: 17.627392 },
    { lat: 48.782718, lng: 17.627667 },
    { lat: 48.782651, lng: 17.627785 },
    { lat: 48.78264, lng: 17.627827 },
    { lat: 48.782715, lng: 17.62808 },
    { lat: 48.782748, lng: 17.628212 },
    { lat: 48.78277, lng: 17.628517 },
    { lat: 48.782759, lng: 17.628761 },
    { lat: 48.782705, lng: 17.629074 },
    { lat: 48.782689, lng: 17.629074 },
    { lat: 48.782569, lng: 17.629087 },
    { lat: 48.782455, lng: 17.629099 },
    { lat: 48.782393, lng: 17.629106 },
    { lat: 48.782325, lng: 17.629115 },
    { lat: 48.782233, lng: 17.628764 },
    { lat: 48.782134, lng: 17.628439 },
    { lat: 48.782026, lng: 17.628472 },
    { lat: 48.781783, lng: 17.628535 },
    { lat: 48.781584, lng: 17.628582 },
    { lat: 48.781478, lng: 17.628602 },
    { lat: 48.781373, lng: 17.628639 },
    { lat: 48.781307, lng: 17.628507 },
    { lat: 48.781265, lng: 17.627737 },
    { lat: 48.781274, lng: 17.627149 },
    { lat: 48.781146, lng: 17.627111 },
    { lat: 48.781081, lng: 17.627094 },
    { lat: 48.781014, lng: 17.627075 },
    { lat: 48.781013, lng: 17.626695 },
    { lat: 48.781012, lng: 17.625969 },
    { lat: 48.780965, lng: 17.6254 },
    { lat: 48.780927, lng: 17.624779 },
    { lat: 48.780898, lng: 17.624471 },
    { lat: 48.780685, lng: 17.624467 },
    { lat: 48.780607, lng: 17.62446 },
    { lat: 48.780571, lng: 17.624461 },
    { lat: 48.780511, lng: 17.62445 },
    { lat: 48.780497, lng: 17.624453 },
    { lat: 48.780519, lng: 17.624503 },
    { lat: 48.780523, lng: 17.62458 },
    { lat: 48.780471, lng: 17.624707 },
    { lat: 48.78041, lng: 17.624716 },
    { lat: 48.780426, lng: 17.624793 },
    { lat: 48.780439, lng: 17.624859 },
    { lat: 48.78042, lng: 17.624917 },
    { lat: 48.780356, lng: 17.624938 },
    { lat: 48.780339, lng: 17.624998 },
    { lat: 48.780337, lng: 17.62508 },
    { lat: 48.780311, lng: 17.625096 },
    { lat: 48.780279, lng: 17.625114 },
    { lat: 48.780138, lng: 17.62539 },
    { lat: 48.780123, lng: 17.625441 },
    { lat: 48.780129, lng: 17.625513 },
    { lat: 48.780159, lng: 17.625615 },
    { lat: 48.780146, lng: 17.625675 },
    { lat: 48.780107, lng: 17.625774 },
    { lat: 48.780092, lng: 17.625767 },
    { lat: 48.779975, lng: 17.625724 },
    { lat: 48.779846, lng: 17.626144 },
    { lat: 48.779828, lng: 17.626417 },
    { lat: 48.77977, lng: 17.626717 },
    { lat: 48.779635, lng: 17.62702 },
    { lat: 48.779609, lng: 17.627073 },
    { lat: 48.77959, lng: 17.627389 },
    { lat: 48.779614, lng: 17.627532 },
    { lat: 48.779564, lng: 17.627758 },
    { lat: 48.779492, lng: 17.628056 },
    { lat: 48.779297, lng: 17.627969 },
    { lat: 48.779157, lng: 17.628386 },
    { lat: 48.779074, lng: 17.628807 },
    { lat: 48.778967, lng: 17.629273 },
    { lat: 48.778905, lng: 17.629525 },
    { lat: 48.778806, lng: 17.629851 },
    { lat: 48.778888, lng: 17.629913 },
    { lat: 48.778935, lng: 17.629952 },
    { lat: 48.778961, lng: 17.629972 },
    { lat: 48.778757, lng: 17.630704 },
    { lat: 48.778554, lng: 17.631415 },
    { lat: 48.778518, lng: 17.631382 },
    { lat: 48.778466, lng: 17.63133 },
    { lat: 48.778376, lng: 17.631268 },
    { lat: 48.778068, lng: 17.631009 },
    { lat: 48.777983, lng: 17.630894 },
    { lat: 48.777814, lng: 17.630666 },
    { lat: 48.777708, lng: 17.630522 },
    { lat: 48.777611, lng: 17.630364 },
    { lat: 48.777586, lng: 17.630324 },
    { lat: 48.777561, lng: 17.630289 },
    { lat: 48.777386, lng: 17.629929 },
    { lat: 48.77719, lng: 17.629527 },
    { lat: 48.777063, lng: 17.629228 },
    { lat: 48.777051, lng: 17.629206 },
    { lat: 48.77699, lng: 17.629258 },
    { lat: 48.776825, lng: 17.629403 },
    { lat: 48.776755, lng: 17.629462 },
    { lat: 48.776687, lng: 17.629525 },
    { lat: 48.776512, lng: 17.629702 },
    { lat: 48.7763503, lng: 17.6294663 },
    { lat: 48.77609, lng: 17.629087 },
    { lat: 48.775487, lng: 17.628207 },
    { lat: 48.775317, lng: 17.627984 },
    { lat: 48.775297, lng: 17.627954 },
    { lat: 48.774905, lng: 17.628663 },
    { lat: 48.774798, lng: 17.628791 },
    { lat: 48.77459, lng: 17.629 },
    { lat: 48.774387, lng: 17.629266 },
    { lat: 48.774314, lng: 17.629377 },
    { lat: 48.774181, lng: 17.629646 },
    { lat: 48.774181, lng: 17.629664 },
    { lat: 48.774131, lng: 17.629846 },
    { lat: 48.774095, lng: 17.63002 },
    { lat: 48.774083, lng: 17.630191 },
    { lat: 48.774066, lng: 17.630415 },
    { lat: 48.774047, lng: 17.630675 },
    { lat: 48.774034, lng: 17.630876 },
    { lat: 48.774028, lng: 17.631067 },
    { lat: 48.774025, lng: 17.631485 },
    { lat: 48.774016, lng: 17.631675 },
    { lat: 48.773997, lng: 17.632035 },
    { lat: 48.773983, lng: 17.632234 },
    { lat: 48.773985, lng: 17.632443 },
    { lat: 48.773986, lng: 17.6326 },
    { lat: 48.773982, lng: 17.63283 },
    { lat: 48.773975, lng: 17.633037 },
    { lat: 48.773971, lng: 17.633206 },
    { lat: 48.773944, lng: 17.633397 },
    { lat: 48.773911, lng: 17.63364 },
    { lat: 48.773882, lng: 17.633885 },
    { lat: 48.773856, lng: 17.634024 },
    { lat: 48.773822, lng: 17.634227 },
    { lat: 48.773775, lng: 17.634356 },
    { lat: 48.773699, lng: 17.634569 },
    { lat: 48.773582, lng: 17.634796 },
    { lat: 48.773538, lng: 17.63488 },
    { lat: 48.77344, lng: 17.635025 },
    { lat: 48.773339, lng: 17.635165 },
    { lat: 48.773226, lng: 17.635313 },
    { lat: 48.773139, lng: 17.635431 },
    { lat: 48.773036, lng: 17.635565 },
    { lat: 48.772946, lng: 17.635761 },
    { lat: 48.772884, lng: 17.635922 },
    { lat: 48.772822, lng: 17.63609 },
    { lat: 48.772789, lng: 17.636217 },
    { lat: 48.772753, lng: 17.636363 },
    { lat: 48.772716, lng: 17.636606 },
    { lat: 48.772694, lng: 17.636766 },
    { lat: 48.772721, lng: 17.637071 },
    { lat: 48.772754, lng: 17.637246 },
    { lat: 48.772787, lng: 17.637227 },
    { lat: 48.772801, lng: 17.637321 },
    { lat: 48.772864, lng: 17.637422 },
    { lat: 48.772941, lng: 17.637557 },
    { lat: 48.773027, lng: 17.637698 },
    { lat: 48.773116, lng: 17.637845 },
    { lat: 48.773182, lng: 17.637955 },
    { lat: 48.773283, lng: 17.637986 },
    { lat: 48.773371, lng: 17.638108 },
    { lat: 48.773434, lng: 17.638181 },
    { lat: 48.773534, lng: 17.638282 },
    { lat: 48.773614, lng: 17.638363 },
    { lat: 48.773695, lng: 17.638451 },
    { lat: 48.773753, lng: 17.638544 },
    { lat: 48.773827, lng: 17.638657 },
    { lat: 48.773891, lng: 17.638754 },
    { lat: 48.773946, lng: 17.638846 },
    { lat: 48.773971, lng: 17.638919 },
    { lat: 48.774083, lng: 17.639133 },
    { lat: 48.774111, lng: 17.639215 },
    { lat: 48.774167, lng: 17.639334 },
    { lat: 48.774228, lng: 17.639452 },
    { lat: 48.774291, lng: 17.639568 },
    { lat: 48.774359, lng: 17.639698 },
    { lat: 48.774372, lng: 17.639731 },
    { lat: 48.774401, lng: 17.639782 },
    { lat: 48.774421, lng: 17.639834 },
    { lat: 48.774314, lng: 17.639996 },
    { lat: 48.774206, lng: 17.640162 },
    { lat: 48.774056, lng: 17.640349 },
    { lat: 48.773945, lng: 17.640469 },
    { lat: 48.773805, lng: 17.640455 },
    { lat: 48.773674, lng: 17.640611 },
    { lat: 48.773566, lng: 17.640751 },
    { lat: 48.773458, lng: 17.640569 },
    { lat: 48.773372, lng: 17.640447 },
    { lat: 48.773177, lng: 17.640647 },
    { lat: 48.773027, lng: 17.640812 },
    { lat: 48.772881, lng: 17.64097 },
    { lat: 48.772766, lng: 17.641097 },
    { lat: 48.772765, lng: 17.641115 },
    { lat: 48.772668, lng: 17.641122 },
    { lat: 48.772553, lng: 17.641294 },
    { lat: 48.772473, lng: 17.641424 },
    { lat: 48.772401, lng: 17.641474 },
    { lat: 48.772312, lng: 17.641538 },
    { lat: 48.772165, lng: 17.641736 },
    { lat: 48.772067, lng: 17.641872 },
    { lat: 48.771973, lng: 17.642005 },
    { lat: 48.771828, lng: 17.642132 },
    { lat: 48.771728, lng: 17.642223 },
    { lat: 48.771531, lng: 17.642572 },
    { lat: 48.771464, lng: 17.64273 },
    { lat: 48.771367, lng: 17.642861 },
    { lat: 48.771281, lng: 17.642982 },
    { lat: 48.771195, lng: 17.64311 },
    { lat: 48.771274, lng: 17.643416 },
    { lat: 48.771337, lng: 17.643579 },
    { lat: 48.771367, lng: 17.6436 },
    { lat: 48.771483, lng: 17.64372 },
    { lat: 48.771574, lng: 17.64382 },
    { lat: 48.771599, lng: 17.643846 },
    { lat: 48.771647, lng: 17.64393 },
    { lat: 48.771703, lng: 17.644052 },
    { lat: 48.771717, lng: 17.644077 },
    { lat: 48.771774, lng: 17.644176 },
    { lat: 48.771976, lng: 17.64453 },
    { lat: 48.771903, lng: 17.644612 },
    { lat: 48.771782, lng: 17.644739 },
    { lat: 48.77167, lng: 17.644856 },
    { lat: 48.771555, lng: 17.644986 },
    { lat: 48.771426, lng: 17.645135 },
    { lat: 48.771301, lng: 17.645278 },
    { lat: 48.771247, lng: 17.645389 },
    { lat: 48.771164, lng: 17.645545 },
    { lat: 48.771167, lng: 17.645631 },
    { lat: 48.771229, lng: 17.645743 },
    { lat: 48.771155, lng: 17.645897 },
    { lat: 48.771072, lng: 17.646077 },
    { lat: 48.7709, lng: 17.646445 },
    { lat: 48.770831, lng: 17.646599 },
    { lat: 48.770773, lng: 17.646731 },
    { lat: 48.770832, lng: 17.646812 },
    { lat: 48.77092, lng: 17.646934 },
    { lat: 48.770951, lng: 17.646969 },
    { lat: 48.770837, lng: 17.647151 },
    { lat: 48.770729, lng: 17.647293 },
    { lat: 48.770644, lng: 17.647409 },
    { lat: 48.770584, lng: 17.647488 },
    { lat: 48.770477, lng: 17.647638 },
    { lat: 48.770374, lng: 17.647797 },
    { lat: 48.77021, lng: 17.648082 },
    { lat: 48.770136, lng: 17.648086 },
    { lat: 48.770042, lng: 17.648098 },
    { lat: 48.769918, lng: 17.648103 },
    { lat: 48.769812, lng: 17.648104 },
    { lat: 48.769621, lng: 17.648099 },
    { lat: 48.769499, lng: 17.648088 },
    { lat: 48.769408, lng: 17.648079 },
    { lat: 48.769377, lng: 17.647963 },
    { lat: 48.76933, lng: 17.647781 },
    { lat: 48.769253, lng: 17.647723 },
    { lat: 48.769133, lng: 17.647646 },
    { lat: 48.769118, lng: 17.647634 },
    { lat: 48.769063, lng: 17.647612 },
    { lat: 48.769078, lng: 17.647709 },
    { lat: 48.769046, lng: 17.647796 },
    { lat: 48.769069, lng: 17.647963 },
    { lat: 48.769008, lng: 17.648124 },
    { lat: 48.769002, lng: 17.648205 },
    { lat: 48.768901, lng: 17.648378 },
    { lat: 48.768822, lng: 17.648511 },
    { lat: 48.768798, lng: 17.648493 },
    { lat: 48.768785, lng: 17.648507 },
    { lat: 48.768717, lng: 17.648621 },
    { lat: 48.76859, lng: 17.648482 },
    { lat: 48.768465, lng: 17.64851 },
    { lat: 48.768329, lng: 17.648485 },
    { lat: 48.76832, lng: 17.648428 },
    { lat: 48.768179, lng: 17.648408 },
    { lat: 48.768078, lng: 17.648337 },
    { lat: 48.767935, lng: 17.648222 },
    { lat: 48.767793, lng: 17.648081 },
    { lat: 48.76768, lng: 17.647977 },
    { lat: 48.767552, lng: 17.647858 },
    { lat: 48.76744, lng: 17.647755 },
    { lat: 48.767321, lng: 17.647643 },
    { lat: 48.767241, lng: 17.647838 },
    { lat: 48.767172, lng: 17.648004 },
    { lat: 48.7671, lng: 17.648162 },
    { lat: 48.767038, lng: 17.648308 },
    { lat: 48.766971, lng: 17.64846 },
    { lat: 48.766933, lng: 17.648518 },
    { lat: 48.766964, lng: 17.648711 },
    { lat: 48.766989, lng: 17.648903 },
    { lat: 48.767007, lng: 17.649108 },
    { lat: 48.767024, lng: 17.649311 },
    { lat: 48.767061, lng: 17.649483 },
    { lat: 48.767104, lng: 17.649682 },
    { lat: 48.767139, lng: 17.649866 },
    { lat: 48.767181, lng: 17.650055 },
    { lat: 48.767235, lng: 17.65027 },
    { lat: 48.767096, lng: 17.650352 },
    { lat: 48.766966, lng: 17.650412 },
    { lat: 48.76695, lng: 17.650508 },
    { lat: 48.766878, lng: 17.650552 },
    { lat: 48.766757, lng: 17.650619 },
    { lat: 48.76667, lng: 17.65069 },
    { lat: 48.766558, lng: 17.650774 },
    { lat: 48.76649, lng: 17.650859 },
    { lat: 48.766402, lng: 17.650975 },
    { lat: 48.766457, lng: 17.651028 },
    { lat: 48.766581, lng: 17.651105 },
    { lat: 48.766699, lng: 17.651179 },
    { lat: 48.766779, lng: 17.651296 },
    { lat: 48.766796, lng: 17.651528 },
    { lat: 48.766822, lng: 17.651957 },
    { lat: 48.766827, lng: 17.65215 },
    { lat: 48.766826, lng: 17.652285 },
    { lat: 48.766804, lng: 17.652608 },
    { lat: 48.766777, lng: 17.65286 },
    { lat: 48.766772, lng: 17.652978 },
    { lat: 48.766763, lng: 17.653198 },
    { lat: 48.76675, lng: 17.653413 },
    { lat: 48.766735, lng: 17.653645 },
    { lat: 48.76688, lng: 17.653592 },
    { lat: 48.767013, lng: 17.653541 },
    { lat: 48.76713, lng: 17.653486 },
    { lat: 48.767263, lng: 17.653433 },
    { lat: 48.767292, lng: 17.653428 },
    { lat: 48.767396, lng: 17.653408 },
    { lat: 48.767455, lng: 17.653387 },
    { lat: 48.767444, lng: 17.653524 },
    { lat: 48.76742, lng: 17.653757 },
    { lat: 48.767449, lng: 17.653892 },
    { lat: 48.767497, lng: 17.654013 },
    { lat: 48.76761, lng: 17.654239 },
    { lat: 48.767609, lng: 17.654322 },
    { lat: 48.767604, lng: 17.654369 },
    { lat: 48.767535, lng: 17.654476 },
    { lat: 48.76746, lng: 17.65453 },
    { lat: 48.767389, lng: 17.654585 },
    { lat: 48.76728, lng: 17.654635 },
    { lat: 48.767247, lng: 17.654649 },
    { lat: 48.767165, lng: 17.654684 },
    { lat: 48.767066, lng: 17.654722 },
    { lat: 48.767045, lng: 17.654844 },
    { lat: 48.767016, lng: 17.655052 },
    { lat: 48.766984, lng: 17.655267 },
    { lat: 48.766855, lng: 17.655387 },
    { lat: 48.766795, lng: 17.655488 },
    { lat: 48.766754, lng: 17.655614 },
    { lat: 48.766724, lng: 17.655712 },
    { lat: 48.766676, lng: 17.655915 },
    { lat: 48.76666, lng: 17.655911 },
    { lat: 48.766624, lng: 17.655898 },
    { lat: 48.766595, lng: 17.655884 },
    { lat: 48.766553, lng: 17.655866 },
    { lat: 48.766494, lng: 17.655845 },
    { lat: 48.766372, lng: 17.655786 },
    { lat: 48.76634, lng: 17.655678 },
    { lat: 48.766297, lng: 17.65551 },
    { lat: 48.766249, lng: 17.655331 },
    { lat: 48.766243, lng: 17.655252 },
    { lat: 48.766211, lng: 17.655089 },
    { lat: 48.766173, lng: 17.654969 },
    { lat: 48.766123, lng: 17.654811 },
    { lat: 48.766086, lng: 17.654664 },
    { lat: 48.766091, lng: 17.654545 },
    { lat: 48.766113, lng: 17.654366 },
    { lat: 48.766151, lng: 17.654238 },
    { lat: 48.766186, lng: 17.65416 },
    { lat: 48.766225, lng: 17.654055 },
    { lat: 48.766308, lng: 17.653865 },
    { lat: 48.766322, lng: 17.653827 },
    { lat: 48.766414, lng: 17.653611 },
    { lat: 48.766347, lng: 17.653596 },
    { lat: 48.766233, lng: 17.653579 },
    { lat: 48.766105, lng: 17.653554 },
    { lat: 48.766065, lng: 17.653546 },
    { lat: 48.765988, lng: 17.653543 },
    { lat: 48.765879, lng: 17.653534 },
    { lat: 48.765834, lng: 17.653533 },
    { lat: 48.765809, lng: 17.653369 },
    { lat: 48.765767, lng: 17.653102 },
    { lat: 48.765728, lng: 17.652851 },
    { lat: 48.765679, lng: 17.652532 },
    { lat: 48.76564, lng: 17.652279 },
    { lat: 48.765559, lng: 17.651732 },
    { lat: 48.765526, lng: 17.65151 },
    { lat: 48.765463, lng: 17.651582 },
    { lat: 48.765364, lng: 17.651699 },
    { lat: 48.765253, lng: 17.651827 },
    { lat: 48.765124, lng: 17.651918 },
    { lat: 48.765045, lng: 17.65178 },
    { lat: 48.765011, lng: 17.65163 },
    { lat: 48.764989, lng: 17.651516 },
    { lat: 48.765051, lng: 17.651336 },
    { lat: 48.765123, lng: 17.651142 },
    { lat: 48.765191, lng: 17.650952 },
    { lat: 48.765257, lng: 17.650771 },
    { lat: 48.765326, lng: 17.650593 },
    { lat: 48.765389, lng: 17.65043 },
    { lat: 48.765464, lng: 17.650236 },
    { lat: 48.765545, lng: 17.65003 },
    { lat: 48.765559, lng: 17.649986 },
    { lat: 48.765583, lng: 17.649902 },
    { lat: 48.765602, lng: 17.649821 },
    { lat: 48.765605, lng: 17.649803 },
    { lat: 48.765545, lng: 17.64955 },
    { lat: 48.765497, lng: 17.64933 },
    { lat: 48.765441, lng: 17.649149 },
    { lat: 48.765374, lng: 17.648944 },
    { lat: 48.765319, lng: 17.648745 },
    { lat: 48.76524, lng: 17.648519 },
    { lat: 48.765163, lng: 17.648296 },
    { lat: 48.765082, lng: 17.648062 },
    { lat: 48.765112, lng: 17.648055 },
    { lat: 48.765218, lng: 17.648045 },
    { lat: 48.765355, lng: 17.648028 },
    { lat: 48.765467, lng: 17.648015 },
    { lat: 48.765606, lng: 17.648005 },
    { lat: 48.765707, lng: 17.647608 },
    { lat: 48.765747, lng: 17.647421 },
    { lat: 48.765785, lng: 17.647238 },
    { lat: 48.76583, lng: 17.647018 },
    { lat: 48.765874, lng: 17.646832 },
    { lat: 48.765898, lng: 17.646748 },
    { lat: 48.765958, lng: 17.646554 },
    { lat: 48.766024, lng: 17.646367 },
    { lat: 48.76595, lng: 17.646284 },
    { lat: 48.765819, lng: 17.646142 },
    { lat: 48.765699, lng: 17.646012 },
    { lat: 48.765551, lng: 17.645852 },
    { lat: 48.765623, lng: 17.645715 },
    { lat: 48.7657, lng: 17.645539 },
    { lat: 48.765786, lng: 17.645359 },
    { lat: 48.765874, lng: 17.64516 },
    { lat: 48.76579, lng: 17.644995 },
    { lat: 48.765691, lng: 17.644803 },
    { lat: 48.765588, lng: 17.644599 },
    { lat: 48.765521, lng: 17.644468 },
    { lat: 48.765406, lng: 17.644264 },
    { lat: 48.765319, lng: 17.644135 },
    { lat: 48.765236, lng: 17.643998 },
    { lat: 48.765133, lng: 17.643827 },
    { lat: 48.765034, lng: 17.643663 },
    { lat: 48.764932, lng: 17.643497 },
    { lat: 48.765004, lng: 17.643367 },
    { lat: 48.76512, lng: 17.643162 },
    { lat: 48.765198, lng: 17.643013 },
    { lat: 48.765268, lng: 17.643061 },
    { lat: 48.76537, lng: 17.643151 },
    { lat: 48.765532, lng: 17.643278 },
    { lat: 48.765709, lng: 17.643428 },
    { lat: 48.765838, lng: 17.643529 },
    { lat: 48.765955, lng: 17.643624 },
    { lat: 48.766021, lng: 17.64347 },
    { lat: 48.766153, lng: 17.643687 },
    { lat: 48.7662, lng: 17.643494 },
    { lat: 48.766254, lng: 17.643255 },
    { lat: 48.766294, lng: 17.643014 },
    { lat: 48.766337, lng: 17.64279 },
    { lat: 48.766359, lng: 17.642614 },
    { lat: 48.766384, lng: 17.642447 },
    { lat: 48.766453, lng: 17.641979 },
    { lat: 48.76648, lng: 17.641742 },
    { lat: 48.766506, lng: 17.641487 },
    { lat: 48.766557, lng: 17.641 },
    { lat: 48.766586, lng: 17.640727 },
    { lat: 48.766616, lng: 17.640493 },
    { lat: 48.766647, lng: 17.640232 },
    { lat: 48.766681, lng: 17.639957 },
    { lat: 48.76671, lng: 17.639709 },
    { lat: 48.766736, lng: 17.639464 },
    { lat: 48.766531, lng: 17.639408 },
    { lat: 48.766379, lng: 17.639369 },
    { lat: 48.766256, lng: 17.639341 },
    { lat: 48.766111, lng: 17.639306 },
    { lat: 48.765975, lng: 17.63927 },
    { lat: 48.765816, lng: 17.639238 },
    { lat: 48.765821, lng: 17.639081 },
    { lat: 48.765826, lng: 17.638836 },
    { lat: 48.765826, lng: 17.638587 },
    { lat: 48.765828, lng: 17.638559 },
    { lat: 48.765831, lng: 17.638387 },
    { lat: 48.765835, lng: 17.638178 },
    { lat: 48.7658, lng: 17.637948 },
    { lat: 48.765764, lng: 17.637776 },
    { lat: 48.765708, lng: 17.637499 },
    { lat: 48.765671, lng: 17.637315 },
    { lat: 48.765581, lng: 17.637258 },
    { lat: 48.765465, lng: 17.63719 },
    { lat: 48.765324, lng: 17.637108 },
    { lat: 48.765209, lng: 17.637035 },
    { lat: 48.764931, lng: 17.636831 },
    { lat: 48.764802, lng: 17.636739 },
    { lat: 48.764623, lng: 17.636786 },
    { lat: 48.7645, lng: 17.636827 },
    { lat: 48.764385, lng: 17.636854 },
    { lat: 48.764303, lng: 17.636813 },
    { lat: 48.76428, lng: 17.636809 },
    { lat: 48.764243, lng: 17.636781 },
    { lat: 48.76409, lng: 17.636883 },
    { lat: 48.763946, lng: 17.636974 },
    { lat: 48.763838, lng: 17.637043 },
    { lat: 48.763708, lng: 17.637125 },
    { lat: 48.763591, lng: 17.637197 },
    { lat: 48.763466, lng: 17.637278 },
    { lat: 48.763363, lng: 17.63734 },
    { lat: 48.763239, lng: 17.637417 },
    { lat: 48.763123, lng: 17.637489 },
    { lat: 48.763, lng: 17.637589 },
    { lat: 48.762893, lng: 17.637682 },
    { lat: 48.762798, lng: 17.637753 },
    { lat: 48.762689, lng: 17.637844 },
    { lat: 48.762566, lng: 17.637949 },
    { lat: 48.762489, lng: 17.638013 },
    { lat: 48.762386, lng: 17.638104 },
    { lat: 48.76228, lng: 17.638199 },
    { lat: 48.762173, lng: 17.638296 },
    { lat: 48.762077, lng: 17.638396 },
    { lat: 48.761999, lng: 17.638479 },
    { lat: 48.761881, lng: 17.638608 },
    { lat: 48.761792, lng: 17.638695 },
    { lat: 48.761711, lng: 17.638809 },
    { lat: 48.761622, lng: 17.638923 },
    { lat: 48.761532, lng: 17.639084 },
    { lat: 48.761424, lng: 17.639272 },
    { lat: 48.761322, lng: 17.639441 },
    { lat: 48.761227, lng: 17.6396 },
    { lat: 48.761136, lng: 17.639748 },
    { lat: 48.761092, lng: 17.639819 },
    { lat: 48.761011, lng: 17.639939 },
    { lat: 48.760922, lng: 17.640062 },
    { lat: 48.760878, lng: 17.640141 },
    { lat: 48.760836, lng: 17.640263 },
    { lat: 48.760772, lng: 17.640429 },
    { lat: 48.760672, lng: 17.640578 },
    { lat: 48.760582, lng: 17.640712 },
    { lat: 48.760486, lng: 17.640858 },
    { lat: 48.760423, lng: 17.640931 },
    { lat: 48.760327, lng: 17.641023 },
    { lat: 48.760226, lng: 17.641161 },
    { lat: 48.760186, lng: 17.641258 },
    { lat: 48.760073, lng: 17.641433 },
    { lat: 48.760015, lng: 17.6416 },
    { lat: 48.759895, lng: 17.641753 },
    { lat: 48.759775, lng: 17.641902 },
    { lat: 48.759661, lng: 17.642065 },
    { lat: 48.759536, lng: 17.642251 },
    { lat: 48.759448, lng: 17.642372 },
    { lat: 48.759353, lng: 17.642568 },
    { lat: 48.759276, lng: 17.642731 },
    { lat: 48.75922, lng: 17.642809 },
    { lat: 48.759108, lng: 17.642985 },
    { lat: 48.759009, lng: 17.643137 },
    { lat: 48.758916, lng: 17.643347 },
    { lat: 48.758964, lng: 17.643394 },
    { lat: 48.759044, lng: 17.643527 },
    { lat: 48.759198, lng: 17.643775 },
    { lat: 48.759301, lng: 17.643932 },
    { lat: 48.759583, lng: 17.644367 },
    { lat: 48.759684, lng: 17.644505 },
    { lat: 48.759882, lng: 17.644778 },
    { lat: 48.760084, lng: 17.645057 },
    { lat: 48.760112, lng: 17.645089 },
    { lat: 48.760428, lng: 17.645457 },
    { lat: 48.760437, lng: 17.645467 },
    { lat: 48.760503, lng: 17.645358 },
    { lat: 48.760582, lng: 17.645488 },
    { lat: 48.760658, lng: 17.645614 },
    { lat: 48.760737, lng: 17.645742 },
    { lat: 48.760807, lng: 17.645856 },
    { lat: 48.760891, lng: 17.645974 },
    { lat: 48.761015, lng: 17.646149 },
    { lat: 48.761111, lng: 17.646283 },
    { lat: 48.761173, lng: 17.646371 },
    { lat: 48.761225, lng: 17.646445 },
    { lat: 48.761319, lng: 17.646575 },
    { lat: 48.761372, lng: 17.646646 },
    { lat: 48.76142, lng: 17.646714 },
    { lat: 48.761496, lng: 17.646805 },
    { lat: 48.761566, lng: 17.646893 },
    { lat: 48.761713, lng: 17.647074 },
    { lat: 48.761402, lng: 17.647583 },
    { lat: 48.761264, lng: 17.647834 },
    { lat: 48.76101, lng: 17.648417 },
    { lat: 48.76085, lng: 17.648786 },
    { lat: 48.760654, lng: 17.649152 },
    { lat: 48.760575, lng: 17.649311 },
    { lat: 48.760545, lng: 17.649373 },
    { lat: 48.76051, lng: 17.649438 },
    { lat: 48.759964, lng: 17.650102 },
    { lat: 48.759878, lng: 17.650367 },
    { lat: 48.760022, lng: 17.65061 },
    { lat: 48.760181, lng: 17.650858 },
    { lat: 48.760196, lng: 17.650883 },
    { lat: 48.760212, lng: 17.650908 },
    { lat: 48.760288, lng: 17.65103 },
    { lat: 48.760472, lng: 17.651212 },
    { lat: 48.760539, lng: 17.651296 },
    { lat: 48.760615, lng: 17.651366 },
    { lat: 48.760659, lng: 17.651402 },
    { lat: 48.760693, lng: 17.651432 },
    { lat: 48.760784, lng: 17.651511 },
    { lat: 48.760793, lng: 17.651731 },
    { lat: 48.760877, lng: 17.652564 },
    { lat: 48.760883, lng: 17.652741 },
    { lat: 48.760888, lng: 17.652874 },
    { lat: 48.76089, lng: 17.652977 },
    { lat: 48.760891, lng: 17.653059 },
    { lat: 48.760889, lng: 17.653129 },
    { lat: 48.76089, lng: 17.653174 },
    { lat: 48.760891, lng: 17.653227 },
    { lat: 48.760888, lng: 17.653419 },
    { lat: 48.760885, lng: 17.653591 },
    { lat: 48.760428, lng: 17.65356 },
    { lat: 48.760404, lng: 17.653558 },
    { lat: 48.760387, lng: 17.653558 },
    { lat: 48.760335, lng: 17.653585 },
    { lat: 48.759954, lng: 17.65388 },
    { lat: 48.759944, lng: 17.653891 },
    { lat: 48.759922, lng: 17.653884 },
    { lat: 48.75989, lng: 17.654278 },
    { lat: 48.759919, lng: 17.654405 },
    { lat: 48.759911, lng: 17.654485 },
    { lat: 48.760017, lng: 17.654565 },
    { lat: 48.760021, lng: 17.654595 },
    { lat: 48.759969, lng: 17.65471 },
    { lat: 48.759895, lng: 17.65473 },
    { lat: 48.759868, lng: 17.654746 },
    { lat: 48.759881, lng: 17.654853 },
    { lat: 48.759913, lng: 17.654932 },
    { lat: 48.759927, lng: 17.655067 },
    { lat: 48.75982, lng: 17.655382 },
    { lat: 48.759909, lng: 17.655647 },
    { lat: 48.759799, lng: 17.655721 },
    { lat: 48.759798, lng: 17.655743 },
    { lat: 48.759881, lng: 17.655909 },
    { lat: 48.759862, lng: 17.656056 },
    { lat: 48.759809, lng: 17.65617 },
    { lat: 48.759776, lng: 17.656361 },
    { lat: 48.759734, lng: 17.656765 },
    { lat: 48.759791, lng: 17.656833 },
    { lat: 48.759708, lng: 17.656898 },
    { lat: 48.75968, lng: 17.657151 },
    { lat: 48.759666, lng: 17.657351 },
    { lat: 48.759677, lng: 17.657353 },
    { lat: 48.759913, lng: 17.657405 },
    { lat: 48.759896, lng: 17.657606 },
    { lat: 48.759888, lng: 17.657705 },
    { lat: 48.759878, lng: 17.65779 },
    { lat: 48.759874, lng: 17.657826 },
    { lat: 48.759845, lng: 17.657997 },
    { lat: 48.759913, lng: 17.658105 },
    { lat: 48.760041, lng: 17.658274 },
    { lat: 48.760266, lng: 17.658617 },
    { lat: 48.760396, lng: 17.658657 },
    { lat: 48.760557, lng: 17.658733 },
    { lat: 48.76076, lng: 17.658833 },
    { lat: 48.76067, lng: 17.65916 },
    { lat: 48.760417, lng: 17.659934 },
    { lat: 48.760245, lng: 17.659815 },
    { lat: 48.760212, lng: 17.659793 },
    { lat: 48.759735, lng: 17.660898 },
    { lat: 48.75976, lng: 17.660922 },
    { lat: 48.759838, lng: 17.661022 },
    { lat: 48.759886, lng: 17.661081 },
    { lat: 48.759979, lng: 17.661193 },
    { lat: 48.760022, lng: 17.661254 },
    { lat: 48.759939, lng: 17.66138 },
    { lat: 48.759699, lng: 17.661694 },
    { lat: 48.75957, lng: 17.661891 },
    { lat: 48.759451, lng: 17.662094 },
    { lat: 48.759844, lng: 17.662517 },
    { lat: 48.759857, lng: 17.662491 },
    { lat: 48.759966, lng: 17.662683 },
    { lat: 48.760076, lng: 17.662975 },
    { lat: 48.760146, lng: 17.663209 },
    { lat: 48.760161, lng: 17.66328 },
    { lat: 48.760189, lng: 17.663515 },
    { lat: 48.760196, lng: 17.66367 },
    { lat: 48.760191, lng: 17.663881 },
    { lat: 48.760172, lng: 17.664176 },
    { lat: 48.760127, lng: 17.664536 },
    { lat: 48.759997, lng: 17.665079 },
    { lat: 48.759904, lng: 17.665496 },
    { lat: 48.759938, lng: 17.66562 },
    { lat: 48.760107, lng: 17.665912 },
    { lat: 48.76009, lng: 17.665931 },
    { lat: 48.760263, lng: 17.666319 },
    { lat: 48.760471, lng: 17.666713 },
    { lat: 48.760465, lng: 17.666735 },
    { lat: 48.760459, lng: 17.666758 },
    { lat: 48.760389, lng: 17.666963 },
    { lat: 48.76026, lng: 17.667374 },
    { lat: 48.760233, lng: 17.667461 },
    { lat: 48.760209, lng: 17.667531 },
    { lat: 48.760169, lng: 17.667658 },
    { lat: 48.760078, lng: 17.667952 },
    { lat: 48.760034, lng: 17.668204 },
    { lat: 48.760029, lng: 17.668235 },
    { lat: 48.760023, lng: 17.668262 },
    { lat: 48.760084, lng: 17.668318 },
    { lat: 48.760122, lng: 17.668354 },
    { lat: 48.76032, lng: 17.668413 },
    { lat: 48.760376, lng: 17.668406 },
    { lat: 48.760453, lng: 17.66841 },
    { lat: 48.760708, lng: 17.66849 },
    { lat: 48.760967, lng: 17.668578 },
    { lat: 48.76124, lng: 17.668698 },
    { lat: 48.76135, lng: 17.668648 },
    { lat: 48.761357, lng: 17.668673 },
    { lat: 48.761357, lng: 17.668699 },
    { lat: 48.761409, lng: 17.66874 },
    { lat: 48.761423, lng: 17.668752 },
    { lat: 48.76144, lng: 17.668737 },
    { lat: 48.761486, lng: 17.668839 },
    { lat: 48.761579, lng: 17.668933 },
    { lat: 48.761696, lng: 17.669038 },
    { lat: 48.761722, lng: 17.669216 },
    { lat: 48.761748, lng: 17.669317 },
    { lat: 48.761709, lng: 17.669339 },
    { lat: 48.761483, lng: 17.669482 },
    { lat: 48.761322, lng: 17.669587 },
    { lat: 48.761275, lng: 17.669691 },
    { lat: 48.761171, lng: 17.669931 },
    { lat: 48.761152, lng: 17.669977 },
    { lat: 48.761134, lng: 17.670022 },
    { lat: 48.761052, lng: 17.670211 },
    { lat: 48.761038, lng: 17.670244 },
    { lat: 48.761063, lng: 17.670304 },
    { lat: 48.761124, lng: 17.67045 },
    { lat: 48.761179, lng: 17.670578 },
    { lat: 48.761235, lng: 17.670714 },
    { lat: 48.761339, lng: 17.670957 },
    { lat: 48.761377, lng: 17.671048 },
    { lat: 48.76138, lng: 17.671057 },
    { lat: 48.76143, lng: 17.671174 },
    { lat: 48.761483, lng: 17.671295 },
    { lat: 48.761536, lng: 17.67142 },
    { lat: 48.761565, lng: 17.671488 },
    { lat: 48.761592, lng: 17.671549 },
    { lat: 48.761616, lng: 17.671604 },
    { lat: 48.761642, lng: 17.671666 },
    { lat: 48.761694, lng: 17.671786 },
    { lat: 48.761712, lng: 17.671829 },
    { lat: 48.761727, lng: 17.671843 },
    { lat: 48.761764, lng: 17.671878 },
    { lat: 48.761801, lng: 17.671914 },
    { lat: 48.761857, lng: 17.671967 },
    { lat: 48.761896, lng: 17.672004 },
    { lat: 48.761946, lng: 17.672052 },
    { lat: 48.762035, lng: 17.672137 },
    { lat: 48.76212, lng: 17.672216 },
    { lat: 48.76225, lng: 17.672582 },
    { lat: 48.76254, lng: 17.672926 },
    { lat: 48.762859, lng: 17.673303 },
    { lat: 48.76308, lng: 17.673472 },
    { lat: 48.7633, lng: 17.673653 },
    { lat: 48.763376, lng: 17.673716 },
    { lat: 48.763666, lng: 17.673997 },
    { lat: 48.763827, lng: 17.673967 },
    { lat: 48.763853, lng: 17.673962 },
    { lat: 48.763937, lng: 17.673946 },
    { lat: 48.764212, lng: 17.674203 },
    { lat: 48.764544, lng: 17.67446 },
    { lat: 48.764592, lng: 17.674498 },
    { lat: 48.764614, lng: 17.674515 },
    { lat: 48.764585, lng: 17.674556 },
    { lat: 48.764454, lng: 17.674788 },
    { lat: 48.76437, lng: 17.674932 },
    { lat: 48.764306, lng: 17.67504 },
    { lat: 48.764241, lng: 17.675152 },
    { lat: 48.764205, lng: 17.675213 },
    { lat: 48.76403, lng: 17.6755 },
    { lat: 48.764002, lng: 17.675554 },
    { lat: 48.7639, lng: 17.675633 },
    { lat: 48.763809, lng: 17.675704 },
    { lat: 48.763681, lng: 17.675885 },
    { lat: 48.76346, lng: 17.676202 },
    { lat: 48.763389, lng: 17.676305 },
    { lat: 48.763384, lng: 17.676315 },
    { lat: 48.763306, lng: 17.676429 },
    { lat: 48.763231, lng: 17.676458 },
    { lat: 48.763047, lng: 17.676524 },
    { lat: 48.76291, lng: 17.676572 },
    { lat: 48.762897, lng: 17.676578 },
    { lat: 48.762957, lng: 17.67673 },
    { lat: 48.762987, lng: 17.676807 },
    { lat: 48.763094, lng: 17.676813 },
    { lat: 48.763111, lng: 17.676814 },
    { lat: 48.763305, lng: 17.676823 },
    { lat: 48.763321, lng: 17.676823 },
    { lat: 48.763351, lng: 17.676825 },
    { lat: 48.763365, lng: 17.676827 },
    { lat: 48.763429, lng: 17.676843 },
    { lat: 48.763617, lng: 17.676889 },
    { lat: 48.763858, lng: 17.67694 },
    { lat: 48.763862, lng: 17.676941 },
    { lat: 48.764185, lng: 17.677061 },
    { lat: 48.764273, lng: 17.67734 },
    { lat: 48.764435, lng: 17.677847 },
    { lat: 48.76446, lng: 17.677948 },
    { lat: 48.764492, lng: 17.678084 },
    { lat: 48.764544, lng: 17.678734 },
    { lat: 48.764528, lng: 17.678823 },
    { lat: 48.764518, lng: 17.678874 },
    { lat: 48.764507, lng: 17.678945 },
    { lat: 48.7645, lng: 17.678981 },
    { lat: 48.764485, lng: 17.679067 },
    { lat: 48.764446, lng: 17.679112 },
    { lat: 48.76441, lng: 17.679148 },
    { lat: 48.764367, lng: 17.679189 },
    { lat: 48.764327, lng: 17.679229 },
    { lat: 48.764277, lng: 17.679277 },
    { lat: 48.764257, lng: 17.679298 },
    { lat: 48.764233, lng: 17.67933 },
    { lat: 48.763993, lng: 17.67966 },
    { lat: 48.763964, lng: 17.679712 },
    { lat: 48.763939, lng: 17.679759 },
    { lat: 48.763896, lng: 17.679837 },
    { lat: 48.763873, lng: 17.679873 },
    { lat: 48.763835, lng: 17.679941 },
    { lat: 48.763702, lng: 17.680181 },
    { lat: 48.763671, lng: 17.680241 },
    { lat: 48.763658, lng: 17.680265 },
    { lat: 48.763599, lng: 17.680383 },
    { lat: 48.763484, lng: 17.680609 },
    { lat: 48.763421, lng: 17.680735 },
    { lat: 48.763393, lng: 17.68079 },
    { lat: 48.763379, lng: 17.680815 },
    { lat: 48.763339, lng: 17.680878 },
    { lat: 48.763283, lng: 17.680964 },
    { lat: 48.763273, lng: 17.68098 },
    { lat: 48.763232, lng: 17.681044 },
    { lat: 48.763206, lng: 17.681086 },
    { lat: 48.763076, lng: 17.681288 },
    { lat: 48.762688, lng: 17.681896 },
    { lat: 48.762622, lng: 17.682 },
    { lat: 48.762119, lng: 17.682901 },
    { lat: 48.761897, lng: 17.683298 },
    { lat: 48.761552, lng: 17.683904 },
    { lat: 48.761532, lng: 17.683942 },
    { lat: 48.761479, lng: 17.684034 },
    { lat: 48.761471, lng: 17.684047 },
    { lat: 48.761466, lng: 17.684057 },
    { lat: 48.761415, lng: 17.684144 },
    { lat: 48.761359, lng: 17.684242 },
    { lat: 48.761327, lng: 17.684298 },
    { lat: 48.761235, lng: 17.684462 },
    { lat: 48.761021, lng: 17.68484 },
    { lat: 48.760996, lng: 17.684886 },
    { lat: 48.760922, lng: 17.685019 },
    { lat: 48.760804, lng: 17.68545 },
    { lat: 48.760715, lng: 17.685626 },
    { lat: 48.760581, lng: 17.685893 },
    { lat: 48.760304, lng: 17.68644 },
    { lat: 48.760146, lng: 17.686753 },
    { lat: 48.76002, lng: 17.686901 },
    { lat: 48.759883, lng: 17.68706 },
    { lat: 48.759591, lng: 17.68753 },
    { lat: 48.759576, lng: 17.687547 },
    { lat: 48.759085, lng: 17.688092 },
    { lat: 48.758888, lng: 17.688029 },
    { lat: 48.758397, lng: 17.688087 },
    { lat: 48.758334, lng: 17.688098 },
    { lat: 48.758227, lng: 17.688113 },
    { lat: 48.756413, lng: 17.69105 },
    { lat: 48.756116, lng: 17.691868 },
    { lat: 48.755992, lng: 17.691932 },
    { lat: 48.755945, lng: 17.691951 },
    { lat: 48.755897, lng: 17.691956 },
    { lat: 48.755641, lng: 17.69198 },
    { lat: 48.755611, lng: 17.692008 },
    { lat: 48.755518, lng: 17.692093 },
    { lat: 48.755484, lng: 17.692125 },
    { lat: 48.755445, lng: 17.69216 },
    { lat: 48.755346, lng: 17.692252 },
    { lat: 48.755241, lng: 17.692349 },
    { lat: 48.75513, lng: 17.692451 },
    { lat: 48.755089, lng: 17.69249 },
    { lat: 48.755044, lng: 17.692532 },
    { lat: 48.754998, lng: 17.692574 },
    { lat: 48.754971, lng: 17.692611 },
    { lat: 48.754834, lng: 17.692797 },
    { lat: 48.754725, lng: 17.692947 },
    { lat: 48.754455, lng: 17.693393 },
    { lat: 48.75442, lng: 17.693414 },
    { lat: 48.754192, lng: 17.693551 },
    { lat: 48.754013, lng: 17.693659 },
    { lat: 48.753903, lng: 17.69384 },
    { lat: 48.753823, lng: 17.693967 },
    { lat: 48.753832, lng: 17.694037 },
    { lat: 48.753869, lng: 17.694365 },
    { lat: 48.7539, lng: 17.694629 },
    { lat: 48.753535, lng: 17.694973 },
    { lat: 48.753534, lng: 17.69499 },
    { lat: 48.753437, lng: 17.698127 },
    { lat: 48.753433, lng: 17.698214 },
    { lat: 48.753442, lng: 17.698305 },
    { lat: 48.753461, lng: 17.698528 },
    { lat: 48.753487, lng: 17.69878 },
    { lat: 48.753497, lng: 17.69888 },
    { lat: 48.75351, lng: 17.699037 },
    { lat: 48.753521, lng: 17.699164 },
    { lat: 48.753529, lng: 17.699258 },
    { lat: 48.753545, lng: 17.699431 },
    { lat: 48.753564, lng: 17.699639 },
    { lat: 48.753567, lng: 17.699671 },
    { lat: 48.753582, lng: 17.699832 },
    { lat: 48.753599, lng: 17.700022 },
    { lat: 48.753612, lng: 17.700159 },
    { lat: 48.753628, lng: 17.700348 },
    { lat: 48.75363, lng: 17.700363 },
    { lat: 48.75364, lng: 17.700453 },
    { lat: 48.753649, lng: 17.700553 },
    { lat: 48.75365, lng: 17.700571 },
    { lat: 48.75366, lng: 17.700673 },
    { lat: 48.753664, lng: 17.700723 },
    { lat: 48.7536594, lng: 17.7007751 },
    { lat: 48.7536645, lng: 17.7008385 },
    { lat: 48.7536674, lng: 17.7008846 },
    { lat: 48.7537061, lng: 17.701475 },
    { lat: 48.7537749, lng: 17.7025149 },
    { lat: 48.7537775, lng: 17.7025568 },
    { lat: 48.7538929, lng: 17.7042941 },
    { lat: 48.7541027, lng: 17.7074708 },
    { lat: 48.7603516, lng: 17.7013251 },
    { lat: 48.7603529, lng: 17.7013462 },
    { lat: 48.7604671, lng: 17.701605 },
    { lat: 48.7606775, lng: 17.7018857 },
    { lat: 48.7608716, lng: 17.7022113 },
    { lat: 48.7609359, lng: 17.7026492 },
    { lat: 48.760966, lng: 17.7027351 },
    { lat: 48.7610297, lng: 17.702779 },
    { lat: 48.7611406, lng: 17.7028367 },
    { lat: 48.7612156, lng: 17.7029 },
    { lat: 48.7613633, lng: 17.7030597 },
    { lat: 48.7614485, lng: 17.7031428 },
    { lat: 48.7621918, lng: 17.7039506 },
    { lat: 48.7620739, lng: 17.7041084 },
    { lat: 48.761982, lng: 17.7043392 },
    { lat: 48.7623504, lng: 17.704709 },
    { lat: 48.7626508, lng: 17.7050021 },
    { lat: 48.7627439, lng: 17.7051208 },
    { lat: 48.7628194, lng: 17.705284 },
    { lat: 48.7627533, lng: 17.7056196 },
    { lat: 48.7626066, lng: 17.7058776 },
    { lat: 48.762554, lng: 17.7059944 },
    { lat: 48.7626326, lng: 17.7062787 },
    { lat: 48.7626171, lng: 17.7064666 },
    { lat: 48.7626969, lng: 17.7065911 },
    { lat: 48.7627904, lng: 17.7067504 },
    { lat: 48.7628377, lng: 17.7068031 },
    { lat: 48.7629402, lng: 17.7067083 },
    { lat: 48.7629721, lng: 17.7066573 },
    { lat: 48.7631037, lng: 17.7065404 },
    { lat: 48.7631677, lng: 17.7065523 },
    { lat: 48.7634737, lng: 17.7069356 },
    { lat: 48.7635349, lng: 17.7071226 },
    { lat: 48.7636219, lng: 17.7072339 },
    { lat: 48.7637488, lng: 17.7073305 },
    { lat: 48.7637947, lng: 17.7074543 },
    { lat: 48.7638877, lng: 17.7075714 },
    { lat: 48.7639627, lng: 17.7075953 },
    { lat: 48.7640246, lng: 17.7076655 },
    { lat: 48.7641203, lng: 17.7077344 },
    { lat: 48.7642505, lng: 17.7078692 },
    { lat: 48.7644444, lng: 17.7080288 },
    { lat: 48.7643721, lng: 17.708046 },
    { lat: 48.7644252, lng: 17.7081008 },
    { lat: 48.7644669, lng: 17.708155 },
    { lat: 48.7644965, lng: 17.7081769 },
    { lat: 48.7640233, lng: 17.7086874 },
    { lat: 48.7636788, lng: 17.70905 },
    { lat: 48.7634178, lng: 17.7094134 },
    { lat: 48.7631269, lng: 17.7099677 },
    { lat: 48.7628059, lng: 17.710785 },
    { lat: 48.7626492, lng: 17.7111722 },
    { lat: 48.7624233, lng: 17.7115551 },
    { lat: 48.7621351, lng: 17.7119711 },
    { lat: 48.7618492, lng: 17.7122788 },
    { lat: 48.7616287, lng: 17.7124381 },
    { lat: 48.7615588, lng: 17.7124936 },
    { lat: 48.7617462, lng: 17.7128414 },
    { lat: 48.7618614, lng: 17.7130046 },
    { lat: 48.7618961, lng: 17.7130552 },
    { lat: 48.7619497, lng: 17.7131185 },
    { lat: 48.7620093, lng: 17.7131344 },
    { lat: 48.7620325, lng: 17.713181 },
    { lat: 48.7620672, lng: 17.7132475 },
    { lat: 48.7621245, lng: 17.7133713 },
    { lat: 48.7623159, lng: 17.7136188 },
    { lat: 48.7624026, lng: 17.713762 },
    { lat: 48.7624704, lng: 17.7138548 },
    { lat: 48.7625783, lng: 17.7140843 },
    { lat: 48.7626792, lng: 17.7142911 },
    { lat: 48.7628662, lng: 17.7148523 },
    { lat: 48.763047, lng: 17.7151093 },
    { lat: 48.7632536, lng: 17.7154947 },
    { lat: 48.7635005, lng: 17.7159083 },
    { lat: 48.763763, lng: 17.7164855 },
    { lat: 48.7637876, lng: 17.7167753 },
    { lat: 48.7638637, lng: 17.717332 },
    { lat: 48.7639749, lng: 17.7176523 },
    { lat: 48.7640986, lng: 17.7182654 },
    { lat: 48.7641805, lng: 17.718678 },
    { lat: 48.7643666, lng: 17.719351 },
    { lat: 48.7643613, lng: 17.7197971 },
    { lat: 48.7643892, lng: 17.7200306 },
    { lat: 48.7645025, lng: 17.7204756 },
    { lat: 48.7645221, lng: 17.7204632 },
    { lat: 48.764838, lng: 17.7202024 },
    { lat: 48.7649034, lng: 17.7201834 },
    { lat: 48.7663272, lng: 17.7197459 },
    { lat: 48.7674852, lng: 17.7186949 },
    { lat: 48.767653, lng: 17.7185427 },
    { lat: 48.7678273, lng: 17.7188422 },
    { lat: 48.7679457, lng: 17.7190051 },
    { lat: 48.7685891, lng: 17.7199358 },
    { lat: 48.7688852, lng: 17.7202881 },
    { lat: 48.7691127, lng: 17.7203349 },
    { lat: 48.7693322, lng: 17.720249 },
    { lat: 48.7694911, lng: 17.7201185 },
    { lat: 48.7695644, lng: 17.7202067 },
  ],
  Čachtice: [
    { lat: 48.72143, lng: 17.8292693 },
    { lat: 48.7216592, lng: 17.8291777 },
    { lat: 48.7213366, lng: 17.8258008 },
    { lat: 48.721292, lng: 17.8253442 },
    { lat: 48.7206595, lng: 17.818732 },
    { lat: 48.7206264, lng: 17.8125334 },
    { lat: 48.7197552, lng: 17.8122997 },
    { lat: 48.7197383, lng: 17.8103917 },
    { lat: 48.7194212, lng: 17.8103373 },
    { lat: 48.7193969, lng: 17.8102894 },
    { lat: 48.7204271, lng: 17.8076337 },
    { lat: 48.7205302, lng: 17.8073996 },
    { lat: 48.7205634, lng: 17.8074066 },
    { lat: 48.7206519, lng: 17.8072654 },
    { lat: 48.7212322, lng: 17.8068029 },
    { lat: 48.7212825, lng: 17.8067024 },
    { lat: 48.721652, lng: 17.8066885 },
    { lat: 48.7219801, lng: 17.806849 },
    { lat: 48.7228099, lng: 17.8072012 },
    { lat: 48.7232785, lng: 17.8075173 },
    { lat: 48.7235079, lng: 17.8076152 },
    { lat: 48.7236849, lng: 17.8077023 },
    { lat: 48.72409, lng: 17.8078844 },
    { lat: 48.7242465, lng: 17.80799 },
    { lat: 48.724345, lng: 17.8081034 },
    { lat: 48.7244697, lng: 17.8083172 },
    { lat: 48.7242182, lng: 17.8094149 },
    { lat: 48.724459, lng: 17.8097529 },
    { lat: 48.7245826, lng: 17.8099098 },
    { lat: 48.7247136, lng: 17.8100584 },
    { lat: 48.7251287, lng: 17.808534 },
    { lat: 48.7253057, lng: 17.8079573 },
    { lat: 48.7255022, lng: 17.8074022 },
    { lat: 48.725564, lng: 17.8073233 },
    { lat: 48.7255897, lng: 17.8071878 },
    { lat: 48.725835, lng: 17.8066581 },
    { lat: 48.7260305, lng: 17.8070304 },
    { lat: 48.7261688, lng: 17.8070403 },
    { lat: 48.7264226, lng: 17.8076658 },
    { lat: 48.7266171, lng: 17.8078938 },
    { lat: 48.727013, lng: 17.8081834 },
    { lat: 48.727084, lng: 17.8082012 },
    { lat: 48.7271132, lng: 17.8081437 },
    { lat: 48.7271594, lng: 17.8081236 },
    { lat: 48.7272943, lng: 17.8082052 },
    { lat: 48.7273988, lng: 17.808403 },
    { lat: 48.7276922, lng: 17.8087155 },
    { lat: 48.7286414, lng: 17.8070069 },
    { lat: 48.7289106, lng: 17.8065523 },
    { lat: 48.7290521, lng: 17.8062427 },
    { lat: 48.7291497, lng: 17.8061413 },
    { lat: 48.729245, lng: 17.8060927 },
    { lat: 48.729542, lng: 17.8060393 },
    { lat: 48.7293475, lng: 17.8059606 },
    { lat: 48.7293526, lng: 17.8058942 },
    { lat: 48.7294791, lng: 17.8057652 },
    { lat: 48.7293887, lng: 17.8055254 },
    { lat: 48.7296417, lng: 17.8054154 },
    { lat: 48.7298449, lng: 17.8053418 },
    { lat: 48.7299111, lng: 17.8053335 },
    { lat: 48.7300146, lng: 17.8052742 },
    { lat: 48.730139, lng: 17.8052766 },
    { lat: 48.7302264, lng: 17.8052644 },
    { lat: 48.7303755, lng: 17.8051741 },
    { lat: 48.730637, lng: 17.8051291 },
    { lat: 48.7308294, lng: 17.8050656 },
    { lat: 48.7309532, lng: 17.8050398 },
    { lat: 48.7310305, lng: 17.8049929 },
    { lat: 48.7312435, lng: 17.80495 },
    { lat: 48.7313397, lng: 17.8048793 },
    { lat: 48.7316341, lng: 17.8047457 },
    { lat: 48.7318198, lng: 17.8045881 },
    { lat: 48.7319402, lng: 17.8044706 },
    { lat: 48.7320872, lng: 17.8041771 },
    { lat: 48.7321891, lng: 17.8040358 },
    { lat: 48.7322487, lng: 17.8039379 },
    { lat: 48.7324046, lng: 17.8037919 },
    { lat: 48.7324714, lng: 17.803777 },
    { lat: 48.7326345, lng: 17.8038035 },
    { lat: 48.7327813, lng: 17.8036934 },
    { lat: 48.733076, lng: 17.8036029 },
    { lat: 48.7336948, lng: 17.8033827 },
    { lat: 48.734236, lng: 17.8029638 },
    { lat: 48.734535, lng: 17.8027941 },
    { lat: 48.7356501, lng: 17.8019926 },
    { lat: 48.7362555, lng: 17.8015162 },
    { lat: 48.7373291, lng: 17.8007196 },
    { lat: 48.7378443, lng: 17.799098 },
    { lat: 48.7383533, lng: 17.7978563 },
    { lat: 48.739072, lng: 17.7969234 },
    { lat: 48.7395558, lng: 17.7957516 },
    { lat: 48.7397424, lng: 17.7945923 },
    { lat: 48.7398403, lng: 17.7937341 },
    { lat: 48.7402324, lng: 17.7929464 },
    { lat: 48.7405823, lng: 17.7932205 },
    { lat: 48.7407601, lng: 17.7927878 },
    { lat: 48.7411394, lng: 17.7930276 },
    { lat: 48.7414446, lng: 17.793311 },
    { lat: 48.7415857, lng: 17.7930527 },
    { lat: 48.7416108, lng: 17.7928904 },
    { lat: 48.7416908, lng: 17.7926831 },
    { lat: 48.7417571, lng: 17.7925688 },
    { lat: 48.7418572, lng: 17.7923025 },
    { lat: 48.7419119, lng: 17.7920522 },
    { lat: 48.7418996, lng: 17.7918316 },
    { lat: 48.7417991, lng: 17.7917761 },
    { lat: 48.7414379, lng: 17.7914817 },
    { lat: 48.7418266, lng: 17.7910438 },
    { lat: 48.7417892, lng: 17.7909115 },
    { lat: 48.7421899, lng: 17.7904673 },
    { lat: 48.742445, lng: 17.7902444 },
    { lat: 48.7424465, lng: 17.7900102 },
    { lat: 48.7424112, lng: 17.789119 },
    { lat: 48.7430573, lng: 17.7888095 },
    { lat: 48.743198, lng: 17.7886732 },
    { lat: 48.7432024, lng: 17.7886163 },
    { lat: 48.7433341, lng: 17.7884811 },
    { lat: 48.7434794, lng: 17.7884014 },
    { lat: 48.7436015, lng: 17.7883852 },
    { lat: 48.7437073, lng: 17.7883435 },
    { lat: 48.7438839, lng: 17.7882036 },
    { lat: 48.7441487, lng: 17.7881026 },
    { lat: 48.7442571, lng: 17.7880295 },
    { lat: 48.744648, lng: 17.7879232 },
    { lat: 48.7448167, lng: 17.7877839 },
    { lat: 48.7449254, lng: 17.7876596 },
    { lat: 48.7450594, lng: 17.7875431 },
    { lat: 48.7454309, lng: 17.7870986 },
    { lat: 48.7455307, lng: 17.7868858 },
    { lat: 48.7455732, lng: 17.7867677 },
    { lat: 48.745664, lng: 17.7863508 },
    { lat: 48.7457755, lng: 17.7856271 },
    { lat: 48.7458516, lng: 17.7852469 },
    { lat: 48.7459557, lng: 17.7849171 },
    { lat: 48.7459942, lng: 17.7848276 },
    { lat: 48.7460892, lng: 17.784443 },
    { lat: 48.7460945, lng: 17.7842323 },
    { lat: 48.7462151, lng: 17.7838774 },
    { lat: 48.7467293, lng: 17.7832781 },
    { lat: 48.7468489, lng: 17.7830569 },
    { lat: 48.7469405, lng: 17.7827999 },
    { lat: 48.7470693, lng: 17.7823219 },
    { lat: 48.7471322, lng: 17.7821688 },
    { lat: 48.7473131, lng: 17.7819503 },
    { lat: 48.7479143, lng: 17.7818679 },
    { lat: 48.7482372, lng: 17.781702 },
    { lat: 48.7483312, lng: 17.7816337 },
    { lat: 48.748509, lng: 17.7813638 },
    { lat: 48.7487432, lng: 17.7810973 },
    { lat: 48.7488441, lng: 17.7809009 },
    { lat: 48.7490593, lng: 17.7805056 },
    { lat: 48.7492691, lng: 17.7801705 },
    { lat: 48.7495562, lng: 17.7796408 },
    { lat: 48.7497403, lng: 17.7793099 },
    { lat: 48.7498707, lng: 17.7789511 },
    { lat: 48.750131, lng: 17.7776312 },
    { lat: 48.7501549, lng: 17.7775989 },
    { lat: 48.7501957, lng: 17.7773977 },
    { lat: 48.7502413, lng: 17.7772577 },
    { lat: 48.7503902, lng: 17.7771084 },
    { lat: 48.7506084, lng: 17.7770575 },
    { lat: 48.750974, lng: 17.7770344 },
    { lat: 48.751115, lng: 17.7769959 },
    { lat: 48.7512223, lng: 17.7769433 },
    { lat: 48.7513201, lng: 17.7768237 },
    { lat: 48.7514133, lng: 17.7766869 },
    { lat: 48.7515079, lng: 17.7765923 },
    { lat: 48.751611, lng: 17.7765065 },
    { lat: 48.7520416, lng: 17.7759956 },
    { lat: 48.7520975, lng: 17.7758946 },
    { lat: 48.7523175, lng: 17.7756889 },
    { lat: 48.7527422, lng: 17.7751573 },
    { lat: 48.7529851, lng: 17.7749761 },
    { lat: 48.7531809, lng: 17.7749106 },
    { lat: 48.7533647, lng: 17.7748137 },
    { lat: 48.7536707, lng: 17.7746662 },
    { lat: 48.753809, lng: 17.77464 },
    { lat: 48.7540463, lng: 17.7746252 },
    { lat: 48.7544895, lng: 17.7746366 },
    { lat: 48.7547633, lng: 17.774609 },
    { lat: 48.7550067, lng: 17.7745262 },
    { lat: 48.7557864, lng: 17.7742804 },
    { lat: 48.7559369, lng: 17.774267 },
    { lat: 48.7560141, lng: 17.7742922 },
    { lat: 48.7564938, lng: 17.7743642 },
    { lat: 48.7570003, lng: 17.7743026 },
    { lat: 48.7570422, lng: 17.7743044 },
    { lat: 48.7572881, lng: 17.7743759 },
    { lat: 48.7575296, lng: 17.7743553 },
    { lat: 48.7576007, lng: 17.7743735 },
    { lat: 48.7578636, lng: 17.774519 },
    { lat: 48.7583401, lng: 17.774667 },
    { lat: 48.7585721, lng: 17.7748805 },
    { lat: 48.7588175, lng: 17.7749604 },
    { lat: 48.7590935, lng: 17.7750422 },
    { lat: 48.759615, lng: 17.7754473 },
    { lat: 48.7600148, lng: 17.7755797 },
    { lat: 48.7602673, lng: 17.7755724 },
    { lat: 48.7606175, lng: 17.775722 },
    { lat: 48.7608128, lng: 17.7757421 },
    { lat: 48.76084, lng: 17.7757691 },
    { lat: 48.7608765, lng: 17.7758053 },
    { lat: 48.7609549, lng: 17.7759412 },
    { lat: 48.7610733, lng: 17.7762533 },
    { lat: 48.7611266, lng: 17.7767543 },
    { lat: 48.761211, lng: 17.7772858 },
    { lat: 48.7612059, lng: 17.77748 },
    { lat: 48.7611856, lng: 17.7776288 },
    { lat: 48.761153, lng: 17.7777956 },
    { lat: 48.7611477, lng: 17.7784111 },
    { lat: 48.761176, lng: 17.7787077 },
    { lat: 48.7612546, lng: 17.7787565 },
    { lat: 48.7618164, lng: 17.7789135 },
    { lat: 48.7621255, lng: 17.7790589 },
    { lat: 48.7622156, lng: 17.7791116 },
    { lat: 48.7624592, lng: 17.7792727 },
    { lat: 48.7627387, lng: 17.7793932 },
    { lat: 48.762891, lng: 17.7794107 },
    { lat: 48.7630104, lng: 17.7794786 },
    { lat: 48.7632318, lng: 17.7797225 },
    { lat: 48.7633259, lng: 17.7798558 },
    { lat: 48.7634788, lng: 17.7801418 },
    { lat: 48.7635557, lng: 17.780274 },
    { lat: 48.7636726, lng: 17.7803013 },
    { lat: 48.7638628, lng: 17.7803118 },
    { lat: 48.7642297, lng: 17.7802562 },
    { lat: 48.7645532, lng: 17.7804129 },
    { lat: 48.7647941, lng: 17.7805683 },
    { lat: 48.7650947, lng: 17.7807764 },
    { lat: 48.7654696, lng: 17.7809238 },
    { lat: 48.7656695, lng: 17.7810358 },
    { lat: 48.7657962, lng: 17.7810796 },
    { lat: 48.7659252, lng: 17.7810449 },
    { lat: 48.7662881, lng: 17.781203 },
    { lat: 48.7664602, lng: 17.7812464 },
    { lat: 48.7666341, lng: 17.7812097 },
    { lat: 48.7668829, lng: 17.781218 },
    { lat: 48.7673483, lng: 17.7814804 },
    { lat: 48.7675511, lng: 17.7815835 },
    { lat: 48.7678955, lng: 17.7818252 },
    { lat: 48.7680099, lng: 17.7818881 },
    { lat: 48.7681847, lng: 17.782014 },
    { lat: 48.768236, lng: 17.7820949 },
    { lat: 48.7685572, lng: 17.78218 },
    { lat: 48.7689264, lng: 17.7819029 },
    { lat: 48.7691915, lng: 17.7816037 },
    { lat: 48.7694015, lng: 17.7813075 },
    { lat: 48.7697278, lng: 17.7807828 },
    { lat: 48.7699507, lng: 17.7804551 },
    { lat: 48.7700402, lng: 17.7803105 },
    { lat: 48.7701834, lng: 17.7799177 },
    { lat: 48.7702613, lng: 17.7796214 },
    { lat: 48.7702804, lng: 17.7793969 },
    { lat: 48.7702875, lng: 17.7791797 },
    { lat: 48.7703059, lng: 17.7790189 },
    { lat: 48.7704118, lng: 17.778534 },
    { lat: 48.770611, lng: 17.7779659 },
    { lat: 48.7706705, lng: 17.7775166 },
    { lat: 48.7706289, lng: 17.7775185 },
    { lat: 48.7705287, lng: 17.7761213 },
    { lat: 48.770111, lng: 17.7747071 },
    { lat: 48.7694383, lng: 17.7731122 },
    { lat: 48.7690983, lng: 17.7722843 },
    { lat: 48.7688176, lng: 17.7720736 },
    { lat: 48.7685636, lng: 17.7720596 },
    { lat: 48.7683434, lng: 17.7719138 },
    { lat: 48.7681483, lng: 17.7717916 },
    { lat: 48.7679333, lng: 17.7717666 },
    { lat: 48.7676107, lng: 17.7715187 },
    { lat: 48.7675459, lng: 17.7715308 },
    { lat: 48.7671552, lng: 17.7715905 },
    { lat: 48.7670626, lng: 17.7716112 },
    { lat: 48.7668914, lng: 17.7715638 },
    { lat: 48.7667595, lng: 17.7714037 },
    { lat: 48.7663032, lng: 17.7710936 },
    { lat: 48.7661965, lng: 17.7710484 },
    { lat: 48.7660458, lng: 17.7710037 },
    { lat: 48.7655614, lng: 17.770716 },
    { lat: 48.7652227, lng: 17.7703326 },
    { lat: 48.7648167, lng: 17.7698301 },
    { lat: 48.7639259, lng: 17.7687551 },
    { lat: 48.7638174, lng: 17.7684954 },
    { lat: 48.7636285, lng: 17.7680313 },
    { lat: 48.7634055, lng: 17.7677843 },
    { lat: 48.7633239, lng: 17.7676506 },
    { lat: 48.7632553, lng: 17.7675474 },
    { lat: 48.7630464, lng: 17.76731 },
    { lat: 48.7629787, lng: 17.7672186 },
    { lat: 48.7627632, lng: 17.7670214 },
    { lat: 48.7622773, lng: 17.766432 },
    { lat: 48.7620961, lng: 17.7662031 },
    { lat: 48.7615484, lng: 17.7657308 },
    { lat: 48.7609436, lng: 17.7653758 },
    { lat: 48.7607645, lng: 17.7647215 },
    { lat: 48.7605937, lng: 17.7644233 },
    { lat: 48.7604108, lng: 17.7639657 },
    { lat: 48.7603674, lng: 17.7637726 },
    { lat: 48.7600919, lng: 17.7630396 },
    { lat: 48.7598495, lng: 17.7627902 },
    { lat: 48.7596047, lng: 17.7625023 },
    { lat: 48.7593463, lng: 17.7624723 },
    { lat: 48.7588805, lng: 17.7622664 },
    { lat: 48.7586415, lng: 17.7619799 },
    { lat: 48.758124, lng: 17.7614479 },
    { lat: 48.7578791, lng: 17.7614152 },
    { lat: 48.7578509, lng: 17.7614336 },
    { lat: 48.757611, lng: 17.7613377 },
    { lat: 48.7567475, lng: 17.7605599 },
    { lat: 48.7563085, lng: 17.7608658 },
    { lat: 48.7556491, lng: 17.7613707 },
    { lat: 48.7556084, lng: 17.761408 },
    { lat: 48.7555549, lng: 17.7614574 },
    { lat: 48.7547744, lng: 17.7620343 },
    { lat: 48.7530369, lng: 17.7634613 },
    { lat: 48.7526303, lng: 17.7637786 },
    { lat: 48.7514231, lng: 17.7646407 },
    { lat: 48.7500117, lng: 17.7656905 },
    { lat: 48.7485814, lng: 17.7667474 },
    { lat: 48.7482659, lng: 17.7661346 },
    { lat: 48.7482127, lng: 17.7658947 },
    { lat: 48.7473118, lng: 17.7658347 },
    { lat: 48.7467301, lng: 17.765968 },
    { lat: 48.7459922, lng: 17.7657655 },
    { lat: 48.7454583, lng: 17.7652406 },
    { lat: 48.7451698, lng: 17.7649568 },
    { lat: 48.7441771, lng: 17.7647464 },
    { lat: 48.7425014, lng: 17.7643431 },
    { lat: 48.7414831, lng: 17.7645452 },
    { lat: 48.7411599, lng: 17.7642498 },
    { lat: 48.740037, lng: 17.7636276 },
    { lat: 48.7384904, lng: 17.7621978 },
    { lat: 48.7383285, lng: 17.7623226 },
    { lat: 48.7382065, lng: 17.7624161 },
    { lat: 48.7380759, lng: 17.7625161 },
    { lat: 48.7379789, lng: 17.762591 },
    { lat: 48.7378418, lng: 17.7626966 },
    { lat: 48.7376302, lng: 17.7626921 },
    { lat: 48.7374904, lng: 17.7626973 },
    { lat: 48.73731, lng: 17.7627036 },
    { lat: 48.7372288, lng: 17.7627066 },
    { lat: 48.7361441, lng: 17.7624498 },
    { lat: 48.7352957, lng: 17.7622331 },
    { lat: 48.7346599, lng: 17.7624247 },
    { lat: 48.7334145, lng: 17.7627555 },
    { lat: 48.7323472, lng: 17.7627467 },
    { lat: 48.7310562, lng: 17.7634752 },
    { lat: 48.7304923, lng: 17.7638809 },
    { lat: 48.7291223, lng: 17.7651079 },
    { lat: 48.7283766, lng: 17.7656997 },
    { lat: 48.7278787, lng: 17.7660945 },
    { lat: 48.7270901, lng: 17.7665584 },
    { lat: 48.7256893, lng: 17.7669912 },
    { lat: 48.7250424, lng: 17.7669623 },
    { lat: 48.7250417, lng: 17.7669326 },
    { lat: 48.7247582, lng: 17.766806 },
    { lat: 48.7245033, lng: 17.7667402 },
    { lat: 48.7241078, lng: 17.7668694 },
    { lat: 48.7236658, lng: 17.7668494 },
    { lat: 48.7233161, lng: 17.7667293 },
    { lat: 48.723037, lng: 17.7667496 },
    { lat: 48.7226732, lng: 17.7668236 },
    { lat: 48.7224423, lng: 17.7668175 },
    { lat: 48.7218748, lng: 17.7667582 },
    { lat: 48.7215147, lng: 17.7666725 },
    { lat: 48.7215279, lng: 17.7666104 },
    { lat: 48.7215805, lng: 17.766141 },
    { lat: 48.7215886, lng: 17.7650386 },
    { lat: 48.7215209, lng: 17.7648207 },
    { lat: 48.7215217, lng: 17.7645742 },
    { lat: 48.7213801, lng: 17.7645317 },
    { lat: 48.7211978, lng: 17.7642503 },
    { lat: 48.7218353, lng: 17.763736 },
    { lat: 48.7224331, lng: 17.7633329 },
    { lat: 48.7224083, lng: 17.7638116 },
    { lat: 48.7231407, lng: 17.7636121 },
    { lat: 48.7242281, lng: 17.762935 },
    { lat: 48.7250212, lng: 17.762527 },
    { lat: 48.7260722, lng: 17.7620125 },
    { lat: 48.7260594, lng: 17.7617649 },
    { lat: 48.7255609, lng: 17.7604559 },
    { lat: 48.7252752, lng: 17.7603667 },
    { lat: 48.7241582, lng: 17.7608742 },
    { lat: 48.7238579, lng: 17.7611917 },
    { lat: 48.7225562, lng: 17.7615052 },
    { lat: 48.7206302, lng: 17.7620711 },
    { lat: 48.7201177, lng: 17.7619887 },
    { lat: 48.7199134, lng: 17.7617897 },
    { lat: 48.7196551, lng: 17.7615392 },
    { lat: 48.7196204, lng: 17.7612861 },
    { lat: 48.7195017, lng: 17.7606052 },
    { lat: 48.719444, lng: 17.7602523 },
    { lat: 48.7194143, lng: 17.7600639 },
    { lat: 48.7191735, lng: 17.7574501 },
    { lat: 48.7189536, lng: 17.7551021 },
    { lat: 48.7183845, lng: 17.7541892 },
    { lat: 48.7183607, lng: 17.7541135 },
    { lat: 48.7178639, lng: 17.7524647 },
    { lat: 48.718314, lng: 17.7511634 },
    { lat: 48.7173754, lng: 17.7494081 },
    { lat: 48.7168124, lng: 17.7475291 },
    { lat: 48.7149409, lng: 17.7470108 },
    { lat: 48.7146042, lng: 17.7475643 },
    { lat: 48.7137774, lng: 17.746156 },
    { lat: 48.7139875, lng: 17.7449233 },
    { lat: 48.7133799, lng: 17.7433502 },
    { lat: 48.7138013, lng: 17.7415862 },
    { lat: 48.7133131, lng: 17.7396381 },
    { lat: 48.7127871, lng: 17.7375933 },
    { lat: 48.7098409, lng: 17.7366193 },
    { lat: 48.7079616, lng: 17.7369868 },
    { lat: 48.7057976, lng: 17.7347677 },
    { lat: 48.7046456, lng: 17.7351831 },
    { lat: 48.7035862, lng: 17.7363848 },
    { lat: 48.7031016, lng: 17.7365541 },
    { lat: 48.702637, lng: 17.7369741 },
    { lat: 48.7017683, lng: 17.736905 },
    { lat: 48.7014511, lng: 17.7364891 },
    { lat: 48.7004358, lng: 17.7384082 },
    { lat: 48.6996482, lng: 17.7398953 },
    { lat: 48.699459, lng: 17.7404946 },
    { lat: 48.6991186, lng: 17.741609 },
    { lat: 48.700437, lng: 17.7426488 },
    { lat: 48.7004619, lng: 17.7426678 },
    { lat: 48.699893, lng: 17.7436505 },
    { lat: 48.6994974, lng: 17.7442749 },
    { lat: 48.6992182, lng: 17.7445126 },
    { lat: 48.6988368, lng: 17.7453108 },
    { lat: 48.6986574, lng: 17.7455183 },
    { lat: 48.6985527, lng: 17.7456713 },
    { lat: 48.6984067, lng: 17.7461638 },
    { lat: 48.6981593, lng: 17.7464219 },
    { lat: 48.6980521, lng: 17.7467194 },
    { lat: 48.6978253, lng: 17.7471273 },
    { lat: 48.6977427, lng: 17.7473458 },
    { lat: 48.6976595, lng: 17.7474645 },
    { lat: 48.6973854, lng: 17.747766 },
    { lat: 48.6971871, lng: 17.7482919 },
    { lat: 48.6971262, lng: 17.7486217 },
    { lat: 48.6970375, lng: 17.7488715 },
    { lat: 48.6969317, lng: 17.7495955 },
    { lat: 48.6968862, lng: 17.7497181 },
    { lat: 48.6967892, lng: 17.750127 },
    { lat: 48.6966696, lng: 17.7504048 },
    { lat: 48.6965457, lng: 17.7509635 },
    { lat: 48.6963151, lng: 17.7513845 },
    { lat: 48.6961775, lng: 17.7517563 },
    { lat: 48.6959749, lng: 17.7521557 },
    { lat: 48.6957726, lng: 17.7524131 },
    { lat: 48.6956235, lng: 17.7527076 },
    { lat: 48.6955154, lng: 17.7532835 },
    { lat: 48.6953239, lng: 17.7535344 },
    { lat: 48.6950343, lng: 17.7537859 },
    { lat: 48.6947751, lng: 17.7542213 },
    { lat: 48.694464, lng: 17.7548038 },
    { lat: 48.6939904, lng: 17.7551868 },
    { lat: 48.6936101, lng: 17.755748 },
    { lat: 48.693331, lng: 17.7557304 },
    { lat: 48.6931305, lng: 17.7556855 },
    { lat: 48.692644, lng: 17.7558972 },
    { lat: 48.6927354, lng: 17.7560784 },
    { lat: 48.692766, lng: 17.7561352 },
    { lat: 48.6929216, lng: 17.7564974 },
    { lat: 48.6930931, lng: 17.7568228 },
    { lat: 48.6936926, lng: 17.757845 },
    { lat: 48.6937046, lng: 17.7578565 },
    { lat: 48.6936406, lng: 17.7579363 },
    { lat: 48.6934471, lng: 17.7576196 },
    { lat: 48.6933218, lng: 17.7577685 },
    { lat: 48.6932359, lng: 17.7576222 },
    { lat: 48.6931312, lng: 17.7578127 },
    { lat: 48.6929212, lng: 17.7579996 },
    { lat: 48.6926729, lng: 17.7583168 },
    { lat: 48.6926488, lng: 17.7582755 },
    { lat: 48.6924847, lng: 17.7584726 },
    { lat: 48.6934045, lng: 17.7597954 },
    { lat: 48.6935439, lng: 17.760005 },
    { lat: 48.6932578, lng: 17.7605175 },
    { lat: 48.6930849, lng: 17.760296 },
    { lat: 48.6929007, lng: 17.7606281 },
    { lat: 48.6932672, lng: 17.7611119 },
    { lat: 48.6929603, lng: 17.7616913 },
    { lat: 48.693143, lng: 17.7619426 },
    { lat: 48.6929586, lng: 17.7623437 },
    { lat: 48.692799, lng: 17.762634 },
    { lat: 48.6925235, lng: 17.7623256 },
    { lat: 48.6922885, lng: 17.7628608 },
    { lat: 48.6935418, lng: 17.7643355 },
    { lat: 48.6934611, lng: 17.7645294 },
    { lat: 48.6937391, lng: 17.7648791 },
    { lat: 48.6940621, lng: 17.7654459 },
    { lat: 48.6938339, lng: 17.7659814 },
    { lat: 48.6936451, lng: 17.7661645 },
    { lat: 48.6935094, lng: 17.7660166 },
    { lat: 48.6931884, lng: 17.7666415 },
    { lat: 48.692917, lng: 17.7670438 },
    { lat: 48.6931795, lng: 17.7673426 },
    { lat: 48.6936923, lng: 17.7679302 },
    { lat: 48.6940152, lng: 17.7682204 },
    { lat: 48.6944857, lng: 17.768556 },
    { lat: 48.6946763, lng: 17.7686975 },
    { lat: 48.6942873, lng: 17.7695426 },
    { lat: 48.6941177, lng: 17.7698536 },
    { lat: 48.6944444, lng: 17.7703345 },
    { lat: 48.6939013, lng: 17.7713553 },
    { lat: 48.6940571, lng: 17.7716124 },
    { lat: 48.6947989, lng: 17.7702384 },
    { lat: 48.69502, lng: 17.7697896 },
    { lat: 48.6952771, lng: 17.7700366 },
    { lat: 48.6946513, lng: 17.7712572 },
    { lat: 48.694864, lng: 17.7714842 },
    { lat: 48.6945113, lng: 17.772219 },
    { lat: 48.6944051, lng: 17.7720912 },
    { lat: 48.6940881, lng: 17.7727263 },
    { lat: 48.6935915, lng: 17.7739525 },
    { lat: 48.6940794, lng: 17.774317 },
    { lat: 48.6943489, lng: 17.7733295 },
    { lat: 48.6947153, lng: 17.7736293 },
    { lat: 48.6949245, lng: 17.773042 },
    { lat: 48.6951581, lng: 17.7725566 },
    { lat: 48.6953417, lng: 17.772032 },
    { lat: 48.6954567, lng: 17.7720828 },
    { lat: 48.6949076, lng: 17.773393 },
    { lat: 48.6944536, lng: 17.7746889 },
    { lat: 48.6946931, lng: 17.7748714 },
    { lat: 48.6947739, lng: 17.7746223 },
    { lat: 48.6951438, lng: 17.775035 },
    { lat: 48.6951729, lng: 17.7750671 },
    { lat: 48.6943698, lng: 17.7774888 },
    { lat: 48.694473, lng: 17.7775872 },
    { lat: 48.6947673, lng: 17.7781685 },
    { lat: 48.6948026, lng: 17.7782456 },
    { lat: 48.6943434, lng: 17.7796592 },
    { lat: 48.6938376, lng: 17.7813295 },
    { lat: 48.6934174, lng: 17.7829382 },
    { lat: 48.6939353, lng: 17.7832942 },
    { lat: 48.6939241, lng: 17.7833316 },
    { lat: 48.693899, lng: 17.7834193 },
    { lat: 48.693885, lng: 17.783469 },
    { lat: 48.6935848, lng: 17.784879 },
    { lat: 48.693507, lng: 17.7852145 },
    { lat: 48.6934669, lng: 17.7853171 },
    { lat: 48.6935416, lng: 17.7853169 },
    { lat: 48.6937073, lng: 17.7854022 },
    { lat: 48.6938542, lng: 17.785478 },
    { lat: 48.6941709, lng: 17.7855926 },
    { lat: 48.694546, lng: 17.7856298 },
    { lat: 48.6942635, lng: 17.7864232 },
    { lat: 48.6943834, lng: 17.7865171 },
    { lat: 48.6958361, lng: 17.7878015 },
    { lat: 48.6958005, lng: 17.787958 },
    { lat: 48.6957558, lng: 17.7881529 },
    { lat: 48.6957131, lng: 17.7883424 },
    { lat: 48.6956684, lng: 17.7885342 },
    { lat: 48.6956249, lng: 17.7887272 },
    { lat: 48.6955831, lng: 17.7889117 },
    { lat: 48.6955366, lng: 17.7891137 },
    { lat: 48.6955145, lng: 17.7892136 },
    { lat: 48.6954913, lng: 17.7893151 },
    { lat: 48.6954474, lng: 17.7895039 },
    { lat: 48.6954057, lng: 17.7896889 },
    { lat: 48.6953602, lng: 17.789888 },
    { lat: 48.6953157, lng: 17.7900838 },
    { lat: 48.6952721, lng: 17.7902759 },
    { lat: 48.6952283, lng: 17.7904655 },
    { lat: 48.6951839, lng: 17.7906638 },
    { lat: 48.6951384, lng: 17.7908629 },
    { lat: 48.6950957, lng: 17.7910503 },
    { lat: 48.6950725, lng: 17.7911516 },
    { lat: 48.6950492, lng: 17.7912527 },
    { lat: 48.6950066, lng: 17.7914417 },
    { lat: 48.6949845, lng: 17.7916525 },
    { lat: 48.6946975, lng: 17.7917281 },
    { lat: 48.6942151, lng: 17.791765 },
    { lat: 48.6939007, lng: 17.7917985 },
    { lat: 48.6935503, lng: 17.7918538 },
    { lat: 48.6933462, lng: 17.7918055 },
    { lat: 48.6932069, lng: 17.7916521 },
    { lat: 48.6927753, lng: 17.7912267 },
    { lat: 48.6922045, lng: 17.7907447 },
    { lat: 48.6918497, lng: 17.7905784 },
    { lat: 48.6913898, lng: 17.7903763 },
    { lat: 48.6911007, lng: 17.7904699 },
    { lat: 48.69093, lng: 17.7907842 },
    { lat: 48.6905951, lng: 17.7912206 },
    { lat: 48.6901649, lng: 17.7917991 },
    { lat: 48.6900515, lng: 17.7919966 },
    { lat: 48.6898669, lng: 17.7923235 },
    { lat: 48.6896285, lng: 17.7928045 },
    { lat: 48.6893597, lng: 17.7932311 },
    { lat: 48.6891987, lng: 17.7934814 },
    { lat: 48.6890699, lng: 17.7936658 },
    { lat: 48.6888701, lng: 17.79391 },
    { lat: 48.6887323, lng: 17.7940414 },
    { lat: 48.6885675, lng: 17.7941911 },
    { lat: 48.6881676, lng: 17.7944887 },
    { lat: 48.6879755, lng: 17.7946 },
    { lat: 48.6875623, lng: 17.7947728 },
    { lat: 48.6873114, lng: 17.7948653 },
    { lat: 48.6871095, lng: 17.7948879 },
    { lat: 48.686719, lng: 17.7947675 },
    { lat: 48.686636, lng: 17.7947219 },
    { lat: 48.6866376, lng: 17.7949703 },
    { lat: 48.6865617, lng: 17.7949848 },
    { lat: 48.6860341, lng: 17.7958378 },
    { lat: 48.6856729, lng: 17.796381 },
    { lat: 48.6853762, lng: 17.7967955 },
    { lat: 48.6851009, lng: 17.797154 },
    { lat: 48.6845362, lng: 17.7978065 },
    { lat: 48.6843593, lng: 17.7979446 },
    { lat: 48.6846927, lng: 17.8022389 },
    { lat: 48.6808181, lng: 17.8059244 },
    { lat: 48.6778549, lng: 17.806105 },
    { lat: 48.6765975, lng: 17.8061466 },
    { lat: 48.6764216, lng: 17.8067076 },
    { lat: 48.6762723, lng: 17.8074264 },
    { lat: 48.676228, lng: 17.8078142 },
    { lat: 48.6762375, lng: 17.8089313 },
    { lat: 48.676251, lng: 17.8092093 },
    { lat: 48.6762183, lng: 17.8095807 },
    { lat: 48.6761996, lng: 17.8099779 },
    { lat: 48.6762012, lng: 17.8103006 },
    { lat: 48.6761874, lng: 17.8106306 },
    { lat: 48.6761164, lng: 17.8114299 },
    { lat: 48.6760862, lng: 17.8120095 },
    { lat: 48.6761311, lng: 17.8123748 },
    { lat: 48.6761428, lng: 17.8129747 },
    { lat: 48.6757237, lng: 17.8190026 },
    { lat: 48.6756896, lng: 17.8190006 },
    { lat: 48.6750641, lng: 17.8189351 },
    { lat: 48.6736189, lng: 17.8188232 },
    { lat: 48.67327, lng: 17.818884 },
    { lat: 48.6726908, lng: 17.8190962 },
    { lat: 48.6724169, lng: 17.8192925 },
    { lat: 48.6721405, lng: 17.8195411 },
    { lat: 48.6718993, lng: 17.819811 },
    { lat: 48.6716636, lng: 17.8201341 },
    { lat: 48.671418, lng: 17.8205163 },
    { lat: 48.6713656, lng: 17.820622 },
    { lat: 48.6715412, lng: 17.820741 },
    { lat: 48.6716764, lng: 17.8207699 },
    { lat: 48.6717291, lng: 17.820746 },
    { lat: 48.6717973, lng: 17.8207153 },
    { lat: 48.6719524, lng: 17.8206443 },
    { lat: 48.6722473, lng: 17.8205512 },
    { lat: 48.6727398, lng: 17.8207362 },
    { lat: 48.6731122, lng: 17.8207097 },
    { lat: 48.6734841, lng: 17.8207493 },
    { lat: 48.6736811, lng: 17.8209401 },
    { lat: 48.6739108, lng: 17.8213538 },
    { lat: 48.6740563, lng: 17.8215904 },
    { lat: 48.6739005, lng: 17.8217788 },
    { lat: 48.6740398, lng: 17.8221349 },
    { lat: 48.6740567, lng: 17.8224497 },
    { lat: 48.674206, lng: 17.8225626 },
    { lat: 48.6743111, lng: 17.8224542 },
    { lat: 48.6744493, lng: 17.8222363 },
    { lat: 48.6744681, lng: 17.8223035 },
    { lat: 48.6747349, lng: 17.822028 },
    { lat: 48.6750008, lng: 17.8218474 },
    { lat: 48.6749663, lng: 17.8215955 },
    { lat: 48.6756493, lng: 17.8212887 },
    { lat: 48.6766232, lng: 17.821565 },
    { lat: 48.6767256, lng: 17.8215944 },
    { lat: 48.6772282, lng: 17.821665 },
    { lat: 48.6784663, lng: 17.8219576 },
    { lat: 48.6795136, lng: 17.8226039 },
    { lat: 48.6798719, lng: 17.8227922 },
    { lat: 48.680135, lng: 17.8229365 },
    { lat: 48.6801654, lng: 17.8229533 },
    { lat: 48.6810573, lng: 17.8234426 },
    { lat: 48.680995, lng: 17.8238108 },
    { lat: 48.6813941, lng: 17.8241124 },
    { lat: 48.6821725, lng: 17.8245551 },
    { lat: 48.6824758, lng: 17.8246909 },
    { lat: 48.682812, lng: 17.824832 },
    { lat: 48.6830467, lng: 17.8249009 },
    { lat: 48.683883, lng: 17.8251453 },
    { lat: 48.6840856, lng: 17.8252044 },
    { lat: 48.6844775, lng: 17.8253147 },
    { lat: 48.6846094, lng: 17.8248632 },
    { lat: 48.6856953, lng: 17.8252173 },
    { lat: 48.6864426, lng: 17.8254084 },
    { lat: 48.6879897, lng: 17.8258009 },
    { lat: 48.6880471, lng: 17.8258134 },
    { lat: 48.6884087, lng: 17.8258924 },
    { lat: 48.68845, lng: 17.8259017 },
    { lat: 48.6888767, lng: 17.826005 },
    { lat: 48.6889609, lng: 17.8260321 },
    { lat: 48.6889977, lng: 17.8260445 },
    { lat: 48.6898104, lng: 17.8263085 },
    { lat: 48.6906992, lng: 17.826617 },
    { lat: 48.6923198, lng: 17.8272711 },
    { lat: 48.6936981, lng: 17.8277502 },
    { lat: 48.6941161, lng: 17.8279152 },
    { lat: 48.694237, lng: 17.8279711 },
    { lat: 48.694822, lng: 17.8282453 },
    { lat: 48.6963694, lng: 17.8287927 },
    { lat: 48.6962424, lng: 17.831145 },
    { lat: 48.6962405, lng: 17.8311875 },
    { lat: 48.6961828, lng: 17.8322648 },
    { lat: 48.6966555, lng: 17.832438 },
    { lat: 48.6966909, lng: 17.8322226 },
    { lat: 48.6967753, lng: 17.8319757 },
    { lat: 48.6968952, lng: 17.8317919 },
    { lat: 48.6971606, lng: 17.831491 },
    { lat: 48.6976232, lng: 17.8310337 },
    { lat: 48.6977951, lng: 17.8311839 },
    { lat: 48.6979, lng: 17.8314042 },
    { lat: 48.6979734, lng: 17.8316473 },
    { lat: 48.6981058, lng: 17.8323562 },
    { lat: 48.698183, lng: 17.8325653 },
    { lat: 48.698538, lng: 17.8331268 },
    { lat: 48.6986306, lng: 17.8332194 },
    { lat: 48.6991904, lng: 17.8335988 },
    { lat: 48.6993333, lng: 17.8337577 },
    { lat: 48.69946, lng: 17.8340385 },
    { lat: 48.6996359, lng: 17.8344587 },
    { lat: 48.6997246, lng: 17.8346364 },
    { lat: 48.700125, lng: 17.8349974 },
    { lat: 48.7002779, lng: 17.8350961 },
    { lat: 48.7003495, lng: 17.8351587 },
    { lat: 48.700473, lng: 17.8352384 },
    { lat: 48.7006485, lng: 17.8352061 },
    { lat: 48.7007991, lng: 17.8351003 },
    { lat: 48.7009634, lng: 17.8349401 },
    { lat: 48.7013702, lng: 17.8346062 },
    { lat: 48.7016172, lng: 17.8344324 },
    { lat: 48.7018309, lng: 17.8341916 },
    { lat: 48.7018492, lng: 17.8340282 },
    { lat: 48.7018302, lng: 17.8339002 },
    { lat: 48.7017731, lng: 17.8337062 },
    { lat: 48.7015961, lng: 17.8334132 },
    { lat: 48.7014747, lng: 17.833256 },
    { lat: 48.7011689, lng: 17.8328004 },
    { lat: 48.701143, lng: 17.8326512 },
    { lat: 48.7011452, lng: 17.8324844 },
    { lat: 48.7012359, lng: 17.8317804 },
    { lat: 48.701293, lng: 17.8316237 },
    { lat: 48.7014569, lng: 17.8315121 },
    { lat: 48.701832, lng: 17.8314767 },
    { lat: 48.7020057, lng: 17.8314326 },
    { lat: 48.7022324, lng: 17.8312389 },
    { lat: 48.7024972, lng: 17.8308741 },
    { lat: 48.7026404, lng: 17.8307197 },
    { lat: 48.7030151, lng: 17.8306012 },
    { lat: 48.7035232, lng: 17.8306147 },
    { lat: 48.7035416, lng: 17.830619 },
    { lat: 48.7035841, lng: 17.8306299 },
    { lat: 48.7039488, lng: 17.8307205 },
    { lat: 48.70434, lng: 17.8308903 },
    { lat: 48.7051575, lng: 17.8309749 },
    { lat: 48.7052161, lng: 17.8294828 },
    { lat: 48.705237, lng: 17.8290655 },
    { lat: 48.7052817, lng: 17.8284826 },
    { lat: 48.7053104, lng: 17.8283392 },
    { lat: 48.7054497, lng: 17.8280107 },
    { lat: 48.7055638, lng: 17.8277679 },
    { lat: 48.7057083, lng: 17.8275075 },
    { lat: 48.7057863, lng: 17.8273405 },
    { lat: 48.7060328, lng: 17.8265804 },
    { lat: 48.7063248, lng: 17.8259379 },
    { lat: 48.7065996, lng: 17.8253839 },
    { lat: 48.7066905, lng: 17.8249841 },
    { lat: 48.7067989, lng: 17.8246476 },
    { lat: 48.7070347, lng: 17.8242883 },
    { lat: 48.7070965, lng: 17.8234291 },
    { lat: 48.7072424, lng: 17.8234135 },
    { lat: 48.7081417, lng: 17.8233181 },
    { lat: 48.7082776, lng: 17.8233035 },
    { lat: 48.7119835, lng: 17.8227768 },
    { lat: 48.7120202, lng: 17.8227862 },
    { lat: 48.7118449, lng: 17.8241578 },
    { lat: 48.7118216, lng: 17.8245555 },
    { lat: 48.7118282, lng: 17.824998 },
    { lat: 48.7118452, lng: 17.8252578 },
    { lat: 48.7119169, lng: 17.8255633 },
    { lat: 48.7124097, lng: 17.8267753 },
    { lat: 48.7125648, lng: 17.8273383 },
    { lat: 48.712672, lng: 17.8279863 },
    { lat: 48.7127617, lng: 17.8286983 },
    { lat: 48.7128745, lng: 17.8298317 },
    { lat: 48.7129319, lng: 17.8300696 },
    { lat: 48.7130308, lng: 17.8303201 },
    { lat: 48.7130933, lng: 17.8304194 },
    { lat: 48.7131903, lng: 17.8304905 },
    { lat: 48.7133838, lng: 17.830514 },
    { lat: 48.7138985, lng: 17.8305228 },
    { lat: 48.7140078, lng: 17.8305004 },
    { lat: 48.7154974, lng: 17.8303753 },
    { lat: 48.7157068, lng: 17.8303424 },
    { lat: 48.7160993, lng: 17.830275 },
    { lat: 48.7161452, lng: 17.8302674 },
    { lat: 48.7166478, lng: 17.8302096 },
    { lat: 48.7183532, lng: 17.8300268 },
    { lat: 48.7188651, lng: 17.8299524 },
    { lat: 48.7195158, lng: 17.8298381 },
    { lat: 48.7197053, lng: 17.8297961 },
    { lat: 48.7204225, lng: 17.8296061 },
    { lat: 48.7213956, lng: 17.8292974 },
    { lat: 48.72143, lng: 17.8292693 },
  ],
  MoravskéLieskové: [
    { lat: 48.8502599, lng: 17.7966888 },
    { lat: 48.8502668, lng: 17.7964876 },
    { lat: 48.8503041, lng: 17.7963765 },
    { lat: 48.8503289, lng: 17.7963019 },
    { lat: 48.8504305, lng: 17.7961176 },
    { lat: 48.850617, lng: 17.7958053 },
    { lat: 48.8508072, lng: 17.7956471 },
    { lat: 48.851045, lng: 17.7955655 },
    { lat: 48.8510791, lng: 17.7955517 },
    { lat: 48.8512217, lng: 17.795539 },
    { lat: 48.8514196, lng: 17.7955819 },
    { lat: 48.851458, lng: 17.7955833 },
    { lat: 48.8522935, lng: 17.7953066 },
    { lat: 48.8525447, lng: 17.7953163 },
    { lat: 48.852788, lng: 17.7954192 },
    { lat: 48.8528865, lng: 17.7954225 },
    { lat: 48.8529425, lng: 17.7953775 },
    { lat: 48.8528913, lng: 17.7950015 },
    { lat: 48.8528957, lng: 17.7947566 },
    { lat: 48.8529153, lng: 17.7945041 },
    { lat: 48.8529752, lng: 17.7941912 },
    { lat: 48.8533926, lng: 17.7927403 },
    { lat: 48.8534522, lng: 17.7920887 },
    { lat: 48.8534926, lng: 17.7917509 },
    { lat: 48.8535824, lng: 17.7912601 },
    { lat: 48.8535832, lng: 17.7910157 },
    { lat: 48.8537565, lng: 17.7904675 },
    { lat: 48.8538241, lng: 17.7899291 },
    { lat: 48.8538528, lng: 17.789789 },
    { lat: 48.8540137, lng: 17.7892978 },
    { lat: 48.8541251, lng: 17.7887176 },
    { lat: 48.8542792, lng: 17.7872099 },
    { lat: 48.8548038, lng: 17.7862775 },
    { lat: 48.8550187, lng: 17.7858206 },
    { lat: 48.8551998, lng: 17.7854996 },
    { lat: 48.8554628, lng: 17.7850335 },
    { lat: 48.8556066, lng: 17.7847802 },
    { lat: 48.8562972, lng: 17.7840724 },
    { lat: 48.8563625, lng: 17.7838667 },
    { lat: 48.8568014, lng: 17.7831569 },
    { lat: 48.8570148, lng: 17.7829375 },
    { lat: 48.8579939, lng: 17.7823871 },
    { lat: 48.8585574, lng: 17.7822415 },
    { lat: 48.8595751, lng: 17.7821591 },
    { lat: 48.8598965, lng: 17.781911 },
    { lat: 48.8602243, lng: 17.7818252 },
    { lat: 48.8606457, lng: 17.7814976 },
    { lat: 48.8610274, lng: 17.7812588 },
    { lat: 48.8613032, lng: 17.7809682 },
    { lat: 48.8614433, lng: 17.7808977 },
    { lat: 48.8624241, lng: 17.7808364 },
    { lat: 48.8626871, lng: 17.7808542 },
    { lat: 48.8634885, lng: 17.7805934 },
    { lat: 48.8635812, lng: 17.7805779 },
    { lat: 48.8639355, lng: 17.7802416 },
    { lat: 48.8641399, lng: 17.7793357 },
    { lat: 48.8645565, lng: 17.7791505 },
    { lat: 48.8652232, lng: 17.7789956 },
    { lat: 48.8654355, lng: 17.7789025 },
    { lat: 48.8656605, lng: 17.7787614 },
    { lat: 48.8659873, lng: 17.778715 },
    { lat: 48.8661496, lng: 17.7784888 },
    { lat: 48.8664653, lng: 17.7776115 },
    { lat: 48.8665408, lng: 17.7775547 },
    { lat: 48.8666363, lng: 17.7775277 },
    { lat: 48.8668916, lng: 17.7773083 },
    { lat: 48.8670737, lng: 17.7771117 },
    { lat: 48.8671398, lng: 17.7769907 },
    { lat: 48.8672356, lng: 17.7767488 },
    { lat: 48.8672901, lng: 17.7766603 },
    { lat: 48.8673653, lng: 17.7765809 },
    { lat: 48.8674151, lng: 17.7765456 },
    { lat: 48.8674735, lng: 17.7764664 },
    { lat: 48.8676032, lng: 17.7761121 },
    { lat: 48.8677278, lng: 17.7758798 },
    { lat: 48.8678294, lng: 17.7756576 },
    { lat: 48.8678969, lng: 17.7755608 },
    { lat: 48.868013, lng: 17.7754642 },
    { lat: 48.8680975, lng: 17.7753705 },
    { lat: 48.8680954, lng: 17.7753542 },
    { lat: 48.8682675, lng: 17.775067 },
    { lat: 48.8683493, lng: 17.7749099 },
    { lat: 48.868553, lng: 17.7744544 },
    { lat: 48.8687484, lng: 17.7743461 },
    { lat: 48.8688765, lng: 17.7742991 },
    { lat: 48.8690009, lng: 17.7743385 },
    { lat: 48.8691098, lng: 17.7744046 },
    { lat: 48.8692079, lng: 17.774402 },
    { lat: 48.8693728, lng: 17.7743471 },
    { lat: 48.8695038, lng: 17.7742923 },
    { lat: 48.8696325, lng: 17.7742545 },
    { lat: 48.8697499, lng: 17.7742732 },
    { lat: 48.8698739, lng: 17.7743447 },
    { lat: 48.869989, lng: 17.774398 },
    { lat: 48.8706379, lng: 17.7741366 },
    { lat: 48.8707125, lng: 17.774118 },
    { lat: 48.8707823, lng: 17.774098 },
    { lat: 48.8710136, lng: 17.7739847 },
    { lat: 48.8715451, lng: 17.7736478 },
    { lat: 48.8716772, lng: 17.7735005 },
    { lat: 48.8718388, lng: 17.7731698 },
    { lat: 48.8720533, lng: 17.7729489 },
    { lat: 48.8721833, lng: 17.7727655 },
    { lat: 48.8723049, lng: 17.7724292 },
    { lat: 48.8725713, lng: 17.7720961 },
    { lat: 48.8728117, lng: 17.7718352 },
    { lat: 48.8730149, lng: 17.7716326 },
    { lat: 48.8732727, lng: 17.7713247 },
    { lat: 48.87344, lng: 17.7709765 },
    { lat: 48.8736157, lng: 17.7705076 },
    { lat: 48.8737815, lng: 17.7701738 },
    { lat: 48.8740496, lng: 17.7697373 },
    { lat: 48.8741905, lng: 17.7691625 },
    { lat: 48.8744146, lng: 17.7685988 },
    { lat: 48.8745163, lng: 17.768157 },
    { lat: 48.8751336, lng: 17.7666942 },
    { lat: 48.8752014, lng: 17.7664729 },
    { lat: 48.8753037, lng: 17.7661886 },
    { lat: 48.8753657, lng: 17.7660399 },
    { lat: 48.8756852, lng: 17.7655775 },
    { lat: 48.875733, lng: 17.7655103 },
    { lat: 48.8757567, lng: 17.7654552 },
    { lat: 48.875825, lng: 17.765263 },
    { lat: 48.8759187, lng: 17.7649298 },
    { lat: 48.8759024, lng: 17.7645141 },
    { lat: 48.8759191, lng: 17.7644003 },
    { lat: 48.87599, lng: 17.7642483 },
    { lat: 48.8761323, lng: 17.7640641 },
    { lat: 48.8762132, lng: 17.7638931 },
    { lat: 48.876351, lng: 17.7636906 },
    { lat: 48.8766155, lng: 17.7634557 },
    { lat: 48.8766516, lng: 17.7633826 },
    { lat: 48.8766712, lng: 17.7629819 },
    { lat: 48.8767442, lng: 17.762663 },
    { lat: 48.8767865, lng: 17.7623024 },
    { lat: 48.876836, lng: 17.7620953 },
    { lat: 48.8768677, lng: 17.761746 },
    { lat: 48.8769863, lng: 17.7614506 },
    { lat: 48.8771384, lng: 17.761188 },
    { lat: 48.8771835, lng: 17.7610228 },
    { lat: 48.8772047, lng: 17.76085 },
    { lat: 48.8772684, lng: 17.7606373 },
    { lat: 48.8773819, lng: 17.7600557 },
    { lat: 48.8775987, lng: 17.7595792 },
    { lat: 48.8776736, lng: 17.759309 },
    { lat: 48.8777139, lng: 17.759119 },
    { lat: 48.8778065, lng: 17.7589887 },
    { lat: 48.8780197, lng: 17.7586908 },
    { lat: 48.8780844, lng: 17.7586415 },
    { lat: 48.8781988, lng: 17.758648 },
    { lat: 48.8782741, lng: 17.7586602 },
    { lat: 48.8783646, lng: 17.7586812 },
    { lat: 48.8785285, lng: 17.758668 },
    { lat: 48.8786339, lng: 17.7586002 },
    { lat: 48.878843, lng: 17.7583825 },
    { lat: 48.8791077, lng: 17.7579301 },
    { lat: 48.8792834, lng: 17.7577929 },
    { lat: 48.8793554, lng: 17.7576788 },
    { lat: 48.8794317, lng: 17.7574686 },
    { lat: 48.8796854, lng: 17.757186 },
    { lat: 48.8798857, lng: 17.7571205 },
    { lat: 48.8799313, lng: 17.7570906 },
    { lat: 48.8801113, lng: 17.7570238 },
    { lat: 48.8803399, lng: 17.7570164 },
    { lat: 48.8804052, lng: 17.7569186 },
    { lat: 48.8804587, lng: 17.7567978 },
    { lat: 48.8804992, lng: 17.7566303 },
    { lat: 48.8805752, lng: 17.7565986 },
    { lat: 48.8807582, lng: 17.7565809 },
    { lat: 48.8809941, lng: 17.7563973 },
    { lat: 48.8810998, lng: 17.7562976 },
    { lat: 48.8811335, lng: 17.7562758 },
    { lat: 48.8812609, lng: 17.756218 },
    { lat: 48.8814283, lng: 17.7561115 },
    { lat: 48.8815521, lng: 17.7559583 },
    { lat: 48.8816779, lng: 17.7557639 },
    { lat: 48.8819386, lng: 17.7557439 },
    { lat: 48.8819743, lng: 17.755738 },
    { lat: 48.8821097, lng: 17.7557164 },
    { lat: 48.8822004, lng: 17.7557605 },
    { lat: 48.8822746, lng: 17.7557565 },
    { lat: 48.8824327, lng: 17.7556274 },
    { lat: 48.8826052, lng: 17.7555494 },
    { lat: 48.8829201, lng: 17.7553078 },
    { lat: 48.8830399, lng: 17.7551084 },
    { lat: 48.8831903, lng: 17.754945 },
    { lat: 48.8833858, lng: 17.7549112 },
    { lat: 48.8838284, lng: 17.7547842 },
    { lat: 48.8840231, lng: 17.7547376 },
    { lat: 48.8841434, lng: 17.7546367 },
    { lat: 48.8843414, lng: 17.7543495 },
    { lat: 48.8845062, lng: 17.7539424 },
    { lat: 48.884636, lng: 17.753813 },
    { lat: 48.884771, lng: 17.7535667 },
    { lat: 48.8849122, lng: 17.7531239 },
    { lat: 48.8853393, lng: 17.7526695 },
    { lat: 48.8854564, lng: 17.7525897 },
    { lat: 48.8857528, lng: 17.752379 },
    { lat: 48.8860505, lng: 17.752225 },
    { lat: 48.8864423, lng: 17.7519126 },
    { lat: 48.8866567, lng: 17.7516894 },
    { lat: 48.8868277, lng: 17.7515335 },
    { lat: 48.8869333, lng: 17.7514504 },
    { lat: 48.8870953, lng: 17.7512569 },
    { lat: 48.8872954, lng: 17.7510572 },
    { lat: 48.8874796, lng: 17.7508227 },
    { lat: 48.8878524, lng: 17.7504564 },
    { lat: 48.8879787, lng: 17.7503082 },
    { lat: 48.8882598, lng: 17.7500674 },
    { lat: 48.8886952, lng: 17.7497111 },
    { lat: 48.8887314, lng: 17.7496752 },
    { lat: 48.8890008, lng: 17.7492252 },
    { lat: 48.8893171, lng: 17.7486947 },
    { lat: 48.8894756, lng: 17.7484067 },
    { lat: 48.8895679, lng: 17.7482558 },
    { lat: 48.8896576, lng: 17.7480982 },
    { lat: 48.889799, lng: 17.7478628 },
    { lat: 48.8899748, lng: 17.7475256 },
    { lat: 48.8904591, lng: 17.746788 },
    { lat: 48.8906482, lng: 17.7460381 },
    { lat: 48.890582, lng: 17.745788 },
    { lat: 48.890439, lng: 17.74527 },
    { lat: 48.890198, lng: 17.744385 },
    { lat: 48.890079, lng: 17.74412 },
    { lat: 48.889662, lng: 17.743361 },
    { lat: 48.889341, lng: 17.742767 },
    { lat: 48.889139, lng: 17.742391 },
    { lat: 48.888886, lng: 17.741972 },
    { lat: 48.888548, lng: 17.741104 },
    { lat: 48.888272, lng: 17.740497 },
    { lat: 48.888019, lng: 17.74036 },
    { lat: 48.887833, lng: 17.740162 },
    { lat: 48.887757, lng: 17.740082 },
    { lat: 48.887598, lng: 17.740254 },
    { lat: 48.887438, lng: 17.740115 },
    { lat: 48.887322, lng: 17.740155 },
    { lat: 48.887131, lng: 17.740179 },
    { lat: 48.886828, lng: 17.740368 },
    { lat: 48.886658, lng: 17.740299 },
    { lat: 48.886561, lng: 17.740335 },
    { lat: 48.8864, lng: 17.740632 },
    { lat: 48.886194, lng: 17.740717 },
    { lat: 48.88595, lng: 17.74103 },
    { lat: 48.885895, lng: 17.741016 },
    { lat: 48.885755, lng: 17.740935 },
    { lat: 48.885675, lng: 17.740991 },
    { lat: 48.885648, lng: 17.740929 },
    { lat: 48.885506, lng: 17.74088 },
    { lat: 48.885353, lng: 17.74078 },
    { lat: 48.885207, lng: 17.740732 },
    { lat: 48.885257, lng: 17.740617 },
    { lat: 48.885081, lng: 17.74059 },
    { lat: 48.884985, lng: 17.740412 },
    { lat: 48.884762, lng: 17.740242 },
    { lat: 48.884691, lng: 17.740341 },
    { lat: 48.88446, lng: 17.74011 },
    { lat: 48.884306, lng: 17.740111 },
    { lat: 48.884076, lng: 17.739906 },
    { lat: 48.883959, lng: 17.73976 },
    { lat: 48.883866, lng: 17.739511 },
    { lat: 48.883467, lng: 17.739212 },
    { lat: 48.88339, lng: 17.738992 },
    { lat: 48.883306, lng: 17.738999 },
    { lat: 48.883035, lng: 17.738826 },
    { lat: 48.882692, lng: 17.738524 },
    { lat: 48.882556, lng: 17.738193 },
    { lat: 48.882396, lng: 17.738096 },
    { lat: 48.882278, lng: 17.737902 },
    { lat: 48.881992, lng: 17.737594 },
    { lat: 48.881795, lng: 17.737165 },
    { lat: 48.881732, lng: 17.737035 },
    { lat: 48.881641, lng: 17.737035 },
    { lat: 48.881581, lng: 17.736867 },
    { lat: 48.881554, lng: 17.736764 },
    { lat: 48.881534, lng: 17.736718 },
    { lat: 48.881448, lng: 17.736681 },
    { lat: 48.881228, lng: 17.736699 },
    { lat: 48.881059, lng: 17.73663 },
    { lat: 48.880922, lng: 17.736725 },
    { lat: 48.880837, lng: 17.736708 },
    { lat: 48.88076, lng: 17.736469 },
    { lat: 48.880684, lng: 17.736222 },
    { lat: 48.880683, lng: 17.736068 },
    { lat: 48.880615, lng: 17.735805 },
    { lat: 48.880549, lng: 17.735669 },
    { lat: 48.880499, lng: 17.735572 },
    { lat: 48.880355, lng: 17.735415 },
    { lat: 48.880215, lng: 17.735296 },
    { lat: 48.880102, lng: 17.735119 },
    { lat: 48.8799, lng: 17.734887 },
    { lat: 48.879771, lng: 17.734644 },
    { lat: 48.879728, lng: 17.734543 },
    { lat: 48.87971, lng: 17.734437 },
    { lat: 48.879741, lng: 17.734184 },
    { lat: 48.879677, lng: 17.733988 },
    { lat: 48.879626, lng: 17.733865 },
    { lat: 48.879633, lng: 17.733823 },
    { lat: 48.879642, lng: 17.733766 },
    { lat: 48.879645, lng: 17.73371 },
    { lat: 48.879574, lng: 17.733649 },
    { lat: 48.87955, lng: 17.733642 },
    { lat: 48.879548, lng: 17.733611 },
    { lat: 48.879542, lng: 17.733592 },
    { lat: 48.879512, lng: 17.733428 },
    { lat: 48.879433, lng: 17.733254 },
    { lat: 48.879379, lng: 17.733144 },
    { lat: 48.879369, lng: 17.73312 },
    { lat: 48.879304, lng: 17.732932 },
    { lat: 48.879254, lng: 17.732751 },
    { lat: 48.879246, lng: 17.732726 },
    { lat: 48.879233, lng: 17.732678 },
    { lat: 48.879207, lng: 17.732619 },
    { lat: 48.879137, lng: 17.732481 },
    { lat: 48.879123, lng: 17.732459 },
    { lat: 48.879062, lng: 17.732321 },
    { lat: 48.879013, lng: 17.732201 },
    { lat: 48.87897, lng: 17.732084 },
    { lat: 48.878914, lng: 17.731899 },
    { lat: 48.878887, lng: 17.73181 },
    { lat: 48.878851, lng: 17.731707 },
    { lat: 48.878802, lng: 17.731598 },
    { lat: 48.878725, lng: 17.73144 },
    { lat: 48.87868, lng: 17.731351 },
    { lat: 48.8786, lng: 17.731226 },
    { lat: 48.8785184, lng: 17.7311359 },
    { lat: 48.87833, lng: 17.730926 },
    { lat: 48.878449, lng: 17.730569 },
    { lat: 48.878398, lng: 17.730481 },
    { lat: 48.878483, lng: 17.730375 },
    { lat: 48.87851, lng: 17.73034 },
    { lat: 48.878639, lng: 17.730225 },
    { lat: 48.878681, lng: 17.730186 },
    { lat: 48.878703, lng: 17.730163 },
    { lat: 48.878746, lng: 17.730089 },
    { lat: 48.878767, lng: 17.730021 },
    { lat: 48.878774, lng: 17.729956 },
    { lat: 48.878774, lng: 17.729906 },
    { lat: 48.878754, lng: 17.729808 },
    { lat: 48.878708, lng: 17.729615 },
    { lat: 48.878646, lng: 17.729336 },
    { lat: 48.878642, lng: 17.729289 },
    { lat: 48.878584, lng: 17.729294 },
    { lat: 48.8785, lng: 17.729278 },
    { lat: 48.878432, lng: 17.729273 },
    { lat: 48.878412, lng: 17.729265 },
    { lat: 48.878386, lng: 17.729221 },
    { lat: 48.878379, lng: 17.729161 },
    { lat: 48.878366, lng: 17.729082 },
    { lat: 48.878333, lng: 17.728965 },
    { lat: 48.878316, lng: 17.728946 },
    { lat: 48.878257, lng: 17.728916 },
    { lat: 48.878195, lng: 17.728868 },
    { lat: 48.878125, lng: 17.7288 },
    { lat: 48.878047, lng: 17.728647 },
    { lat: 48.878013, lng: 17.728591 },
    { lat: 48.877996, lng: 17.728575 },
    { lat: 48.877936, lng: 17.728472 },
    { lat: 48.877915, lng: 17.728429 },
    { lat: 48.877858, lng: 17.7283 },
    { lat: 48.877799, lng: 17.728161 },
    { lat: 48.877757, lng: 17.728087 },
    { lat: 48.877752, lng: 17.727863 },
    { lat: 48.877745, lng: 17.727734 },
    { lat: 48.877704, lng: 17.727565 },
    { lat: 48.877644, lng: 17.727496 },
    { lat: 48.877623, lng: 17.727426 },
    { lat: 48.8776, lng: 17.727278 },
    { lat: 48.877576, lng: 17.727166 },
    { lat: 48.877548, lng: 17.727068 },
    { lat: 48.877536, lng: 17.726925 },
    { lat: 48.877507, lng: 17.726851 },
    { lat: 48.87746, lng: 17.726694 },
    { lat: 48.877395, lng: 17.726625 },
    { lat: 48.877303, lng: 17.726523 },
    { lat: 48.877218, lng: 17.726446 },
    { lat: 48.877175, lng: 17.726398 },
    { lat: 48.877106, lng: 17.726286 },
    { lat: 48.877067, lng: 17.726135 },
    { lat: 48.877051, lng: 17.726116 },
    { lat: 48.876952, lng: 17.726044 },
    { lat: 48.876913, lng: 17.725946 },
    { lat: 48.876876, lng: 17.725862 },
    { lat: 48.876858, lng: 17.725816 },
    { lat: 48.87681, lng: 17.725667 },
    { lat: 48.876808, lng: 17.725649 },
    { lat: 48.876828, lng: 17.725554 },
    { lat: 48.876794, lng: 17.725355 },
    { lat: 48.876759, lng: 17.725251 },
    { lat: 48.876702, lng: 17.725146 },
    { lat: 48.876659, lng: 17.725055 },
    { lat: 48.876561, lng: 17.725064 },
    { lat: 48.876544, lng: 17.725015 },
    { lat: 48.876541, lng: 17.724974 },
    { lat: 48.876508, lng: 17.72488 },
    { lat: 48.87649, lng: 17.724861 },
    { lat: 48.876334, lng: 17.724831 },
    { lat: 48.876322, lng: 17.724813 },
    { lat: 48.876293, lng: 17.724725 },
    { lat: 48.876329, lng: 17.724615 },
    { lat: 48.876332, lng: 17.724597 },
    { lat: 48.876323, lng: 17.724575 },
    { lat: 48.876293, lng: 17.724447 },
    { lat: 48.876266, lng: 17.724408 },
    { lat: 48.87613, lng: 17.724265 },
    { lat: 48.876109, lng: 17.724214 },
    { lat: 48.876098, lng: 17.724177 },
    { lat: 48.876105, lng: 17.724094 },
    { lat: 48.876128, lng: 17.72398 },
    { lat: 48.876118, lng: 17.723873 },
    { lat: 48.876071, lng: 17.723721 },
    { lat: 48.876052, lng: 17.723697 },
    { lat: 48.875964, lng: 17.723643 },
    { lat: 48.875899, lng: 17.723506 },
    { lat: 48.875814, lng: 17.723326 },
    { lat: 48.875813, lng: 17.723296 },
    { lat: 48.875833, lng: 17.723198 },
    { lat: 48.875827, lng: 17.72317 },
    { lat: 48.875733, lng: 17.722977 },
    { lat: 48.875607, lng: 17.722887 },
    { lat: 48.875529, lng: 17.722681 },
    { lat: 48.875527, lng: 17.722664 },
    { lat: 48.875447, lng: 17.722666 },
    { lat: 48.875346, lng: 17.722652 },
    { lat: 48.875329, lng: 17.722645 },
    { lat: 48.875302, lng: 17.722467 },
    { lat: 48.875233, lng: 17.722299 },
    { lat: 48.875155, lng: 17.722076 },
    { lat: 48.875152, lng: 17.722062 },
    { lat: 48.875066, lng: 17.721962 },
    { lat: 48.875026, lng: 17.721759 },
    { lat: 48.874924, lng: 17.721579 },
    { lat: 48.8749, lng: 17.721392 },
    { lat: 48.874857, lng: 17.721356 },
    { lat: 48.874851, lng: 17.721247 },
    { lat: 48.874813, lng: 17.72109 },
    { lat: 48.874784, lng: 17.721043 },
    { lat: 48.874766, lng: 17.721046 },
    { lat: 48.874696, lng: 17.721108 },
    { lat: 48.874686, lng: 17.7211 },
    { lat: 48.874638, lng: 17.721023 },
    { lat: 48.874653, lng: 17.72096 },
    { lat: 48.874643, lng: 17.720826 },
    { lat: 48.874582, lng: 17.720744 },
    { lat: 48.87455, lng: 17.720577 },
    { lat: 48.874462, lng: 17.72049 },
    { lat: 48.874372, lng: 17.720419 },
    { lat: 48.874325, lng: 17.720348 },
    { lat: 48.874313, lng: 17.720311 },
    { lat: 48.874333, lng: 17.720236 },
    { lat: 48.874336, lng: 17.72021 },
    { lat: 48.874298, lng: 17.720148 },
    { lat: 48.874183, lng: 17.720105 },
    { lat: 48.874133, lng: 17.720056 },
    { lat: 48.874082, lng: 17.719965 },
    { lat: 48.874037, lng: 17.719826 },
    { lat: 48.873955, lng: 17.71974 },
    { lat: 48.873895, lng: 17.71973 },
    { lat: 48.873839, lng: 17.719719 },
    { lat: 48.873778, lng: 17.719638 },
    { lat: 48.873701, lng: 17.719517 },
    { lat: 48.873594, lng: 17.719365 },
    { lat: 48.873543, lng: 17.719265 },
    { lat: 48.873489, lng: 17.719096 },
    { lat: 48.873345, lng: 17.718889 },
    { lat: 48.873315, lng: 17.718757 },
    { lat: 48.873225, lng: 17.718701 },
    { lat: 48.873116, lng: 17.718691 },
    { lat: 48.872986, lng: 17.71862 },
    { lat: 48.872906, lng: 17.718535 },
    { lat: 48.872786, lng: 17.718471 },
    { lat: 48.872731, lng: 17.718431 },
    { lat: 48.872593, lng: 17.718284 },
    { lat: 48.872497, lng: 17.718157 },
    { lat: 48.872394, lng: 17.718095 },
    { lat: 48.872302, lng: 17.718089 },
    { lat: 48.872195, lng: 17.718067 },
    { lat: 48.872132, lng: 17.718026 },
    { lat: 48.872074, lng: 17.717993 },
    { lat: 48.871954, lng: 17.717952 },
    { lat: 48.871858, lng: 17.717897 },
    { lat: 48.871791, lng: 17.717827 },
    { lat: 48.871729, lng: 17.717667 },
    { lat: 48.871662, lng: 17.717604 },
    { lat: 48.87159, lng: 17.717465 },
    { lat: 48.871556, lng: 17.717432 },
    { lat: 48.8715, lng: 17.717455 },
    { lat: 48.87146, lng: 17.717473 },
    { lat: 48.871426, lng: 17.717457 },
    { lat: 48.871386, lng: 17.717424 },
    { lat: 48.871324, lng: 17.717307 },
    { lat: 48.871225, lng: 17.717231 },
    { lat: 48.871014, lng: 17.717124 },
    { lat: 48.870965, lng: 17.717068 },
    { lat: 48.870861, lng: 17.717018 },
    { lat: 48.8702998, lng: 17.7164499 },
    { lat: 48.870192, lng: 17.716598 },
    { lat: 48.870094, lng: 17.716517 },
    { lat: 48.870028, lng: 17.71641 },
    { lat: 48.869721, lng: 17.716227 },
    { lat: 48.869598, lng: 17.716138 },
    { lat: 48.869464, lng: 17.716055 },
    { lat: 48.869318, lng: 17.715979 },
    { lat: 48.869244, lng: 17.715917 },
    { lat: 48.869147, lng: 17.715831 },
    { lat: 48.869079, lng: 17.715786 },
    { lat: 48.868946, lng: 17.715682 },
    { lat: 48.868737, lng: 17.715546 },
    { lat: 48.868536, lng: 17.715386 },
    { lat: 48.868391, lng: 17.715304 },
    { lat: 48.868266, lng: 17.715114 },
    { lat: 48.868111, lng: 17.71491 },
    { lat: 48.86797, lng: 17.71473 },
    { lat: 48.86781, lng: 17.71455 },
    { lat: 48.867618, lng: 17.714357 },
    { lat: 48.867466, lng: 17.714162 },
    { lat: 48.867312, lng: 17.71399 },
    { lat: 48.867145, lng: 17.713741 },
    { lat: 48.867025, lng: 17.713567 },
    { lat: 48.866907, lng: 17.713381 },
    { lat: 48.866798, lng: 17.713221 },
    { lat: 48.866712, lng: 17.713081 },
    { lat: 48.866653, lng: 17.712961 },
    { lat: 48.866507, lng: 17.712799 },
    { lat: 48.866418, lng: 17.712644 },
    { lat: 48.866373, lng: 17.712481 },
    { lat: 48.866315, lng: 17.712343 },
    { lat: 48.866289, lng: 17.712232 },
    { lat: 48.866214, lng: 17.712093 },
    { lat: 48.86614, lng: 17.711966 },
    { lat: 48.866032, lng: 17.711787 },
    { lat: 48.86584, lng: 17.71158 },
    { lat: 48.865734, lng: 17.711458 },
    { lat: 48.865627, lng: 17.711363 },
    { lat: 48.8655812, lng: 17.7112896 },
    { lat: 48.865544, lng: 17.71123 },
    { lat: 48.865515, lng: 17.71113 },
    { lat: 48.865483, lng: 17.711027 },
    { lat: 48.865429, lng: 17.710908 },
    { lat: 48.8653054, lng: 17.7107052 },
    { lat: 48.8652681, lng: 17.710644 },
    { lat: 48.86522, lng: 17.710565 },
    { lat: 48.865137, lng: 17.710428 },
    { lat: 48.8650932, lng: 17.7103644 },
    { lat: 48.864966, lng: 17.71018 },
    { lat: 48.8648484, lng: 17.7099806 },
    { lat: 48.864848, lng: 17.70998 },
    { lat: 48.864724, lng: 17.709784 },
    { lat: 48.864692, lng: 17.709693 },
    { lat: 48.864624, lng: 17.7096 },
    { lat: 48.8645644, lng: 17.7094715 },
    { lat: 48.8643655, lng: 17.7090425 },
    { lat: 48.8643439, lng: 17.7089959 },
    { lat: 48.8641137, lng: 17.7084995 },
    { lat: 48.864113, lng: 17.708498 },
    { lat: 48.8637785, lng: 17.7078002 },
    { lat: 48.863777, lng: 17.707797 },
    { lat: 48.863646, lng: 17.7075471 },
    { lat: 48.863524, lng: 17.7073142 },
    { lat: 48.8634555, lng: 17.7071835 },
    { lat: 48.86345, lng: 17.707173 },
    { lat: 48.8634442, lng: 17.7071639 },
    { lat: 48.8633053, lng: 17.706958 },
    { lat: 48.8629984, lng: 17.7065021 },
    { lat: 48.862997, lng: 17.7065 },
    { lat: 48.8629581, lng: 17.7064451 },
    { lat: 48.8629225, lng: 17.7063949 },
    { lat: 48.862863, lng: 17.7063111 },
    { lat: 48.8627881, lng: 17.7062057 },
    { lat: 48.862787, lng: 17.706204 },
    { lat: 48.8627375, lng: 17.7061328 },
    { lat: 48.8626761, lng: 17.7060446 },
    { lat: 48.86264, lng: 17.7059927 },
    { lat: 48.8626387, lng: 17.7059908 },
    { lat: 48.8625961, lng: 17.7061788 },
    { lat: 48.8625607, lng: 17.7063567 },
    { lat: 48.8625236, lng: 17.7065609 },
    { lat: 48.8624882, lng: 17.7067173 },
    { lat: 48.8624636, lng: 17.7068504 },
    { lat: 48.8624378, lng: 17.7069808 },
    { lat: 48.8623924, lng: 17.7071056 },
    { lat: 48.8623441, lng: 17.7072374 },
    { lat: 48.8622791, lng: 17.7074108 },
    { lat: 48.8622064, lng: 17.7076056 },
    { lat: 48.8621453, lng: 17.7077686 },
    { lat: 48.8620842, lng: 17.7079304 },
    { lat: 48.8620321, lng: 17.7080744 },
    { lat: 48.8619765, lng: 17.7082153 },
    { lat: 48.8619625, lng: 17.7084457 },
    { lat: 48.8619422, lng: 17.7087215 },
    { lat: 48.8619281, lng: 17.7088963 },
    { lat: 48.8619128, lng: 17.7090691 },
    { lat: 48.861898, lng: 17.7091585 },
    { lat: 48.8618583, lng: 17.7093386 },
    { lat: 48.8618223, lng: 17.7095047 },
    { lat: 48.8617923, lng: 17.709642 },
    { lat: 48.8617623, lng: 17.7097778 },
    { lat: 48.8617296, lng: 17.7099238 },
    { lat: 48.8616712, lng: 17.7100407 },
    { lat: 48.8616202, lng: 17.7101467 },
    { lat: 48.8615638, lng: 17.7102565 },
    { lat: 48.8614993, lng: 17.7103841 },
    { lat: 48.8614248, lng: 17.7105299 },
    { lat: 48.8613631, lng: 17.7106469 },
    { lat: 48.8613393, lng: 17.7107722 },
    { lat: 48.8613132, lng: 17.710917 },
    { lat: 48.861278, lng: 17.7110982 },
    { lat: 48.8612385, lng: 17.7112994 },
    { lat: 48.8612077, lng: 17.7114581 },
    { lat: 48.8611923, lng: 17.7116495 },
    { lat: 48.8611744, lng: 17.7118526 },
    { lat: 48.8611534, lng: 17.7120806 },
    { lat: 48.8611329, lng: 17.7123153 },
    { lat: 48.8611168, lng: 17.7124935 },
    { lat: 48.8611003, lng: 17.7126652 },
    { lat: 48.86114, lng: 17.7127614 },
    { lat: 48.8612045, lng: 17.7129293 },
    { lat: 48.8612726, lng: 17.7130989 },
    { lat: 48.8613246, lng: 17.7132459 },
    { lat: 48.8613781, lng: 17.7133812 },
    { lat: 48.8614283, lng: 17.7135183 },
    { lat: 48.8613189, lng: 17.7136494 },
    { lat: 48.8612465, lng: 17.7137371 },
    { lat: 48.8611663, lng: 17.7138288 },
    { lat: 48.8611357, lng: 17.7139186 },
    { lat: 48.8610908, lng: 17.7140517 },
    { lat: 48.8610462, lng: 17.714188 },
    { lat: 48.8609976, lng: 17.7143347 },
    { lat: 48.8609468, lng: 17.7144818 },
    { lat: 48.8609033, lng: 17.7146177 },
    { lat: 48.8608644, lng: 17.7147202 },
    { lat: 48.8608133, lng: 17.7148605 },
    { lat: 48.8607524, lng: 17.7150271 },
    { lat: 48.860707, lng: 17.7151502 },
    { lat: 48.8606665, lng: 17.715263 },
    { lat: 48.8606264, lng: 17.7153656 },
    { lat: 48.8605771, lng: 17.7154976 },
    { lat: 48.8605452, lng: 17.715586 },
    { lat: 48.8605159, lng: 17.7156611 },
    { lat: 48.8604909, lng: 17.7157317 },
    { lat: 48.8604581, lng: 17.7158206 },
    { lat: 48.8604223, lng: 17.7159177 },
    { lat: 48.8603884, lng: 17.7160094 },
    { lat: 48.8603552, lng: 17.7160932 },
    { lat: 48.860318, lng: 17.7161874 },
    { lat: 48.8602725, lng: 17.7163089 },
    { lat: 48.8602316, lng: 17.7164144 },
    { lat: 48.860182, lng: 17.7165445 },
    { lat: 48.8601498, lng: 17.716628 },
    { lat: 48.8600966, lng: 17.7167714 },
    { lat: 48.8600442, lng: 17.7169099 },
    { lat: 48.8599926, lng: 17.717045 },
    { lat: 48.8599472, lng: 17.7171696 },
    { lat: 48.8598868, lng: 17.7173257 },
    { lat: 48.8598048, lng: 17.7175323 },
    { lat: 48.8597903, lng: 17.717572 },
    { lat: 48.8597346, lng: 17.7177124 },
    { lat: 48.8596861, lng: 17.7178426 },
    { lat: 48.8596531, lng: 17.7179306 },
    { lat: 48.8595896, lng: 17.718073 },
    { lat: 48.8595203, lng: 17.71825 },
    { lat: 48.8594711, lng: 17.7183838 },
    { lat: 48.8594184, lng: 17.7185194 },
    { lat: 48.8593698, lng: 17.7186466 },
    { lat: 48.8593139, lng: 17.7187844 },
    { lat: 48.8592635, lng: 17.7189183 },
    { lat: 48.8591999, lng: 17.7190783 },
    { lat: 48.8591464, lng: 17.7192188 },
    { lat: 48.8590805, lng: 17.7193954 },
    { lat: 48.8590123, lng: 17.7195706 },
    { lat: 48.8589607, lng: 17.7197032 },
    { lat: 48.8589107, lng: 17.7198271 },
    { lat: 48.858872, lng: 17.7199875 },
    { lat: 48.8587942, lng: 17.720318 },
    { lat: 48.8587626, lng: 17.7204657 },
    { lat: 48.8587226, lng: 17.7206411 },
    { lat: 48.8586932, lng: 17.720769 },
    { lat: 48.8586622, lng: 17.7209073 },
    { lat: 48.8586334, lng: 17.7210268 },
    { lat: 48.8585673, lng: 17.7211251 },
    { lat: 48.8584896, lng: 17.7212393 },
    { lat: 48.8584056, lng: 17.7213605 },
    { lat: 48.8583289, lng: 17.7214728 },
    { lat: 48.8581848, lng: 17.7216802 },
    { lat: 48.8581155, lng: 17.7217848 },
    { lat: 48.8580327, lng: 17.7219048 },
    { lat: 48.8579808, lng: 17.7219797 },
    { lat: 48.8579004, lng: 17.722068 },
    { lat: 48.857799, lng: 17.7221816 },
    { lat: 48.8577092, lng: 17.7222807 },
    { lat: 48.8576362, lng: 17.7223617 },
    { lat: 48.8575707, lng: 17.7224346 },
    { lat: 48.8575009, lng: 17.7225117 },
    { lat: 48.8573712, lng: 17.72266 },
    { lat: 48.857296, lng: 17.7227592 },
    { lat: 48.8572124, lng: 17.7228673 },
    { lat: 48.8571329, lng: 17.7229717 },
    { lat: 48.857068, lng: 17.7230531 },
    { lat: 48.8569716, lng: 17.7231006 },
    { lat: 48.8568545, lng: 17.7231608 },
    { lat: 48.856746, lng: 17.7232143 },
    { lat: 48.8566265, lng: 17.7232729 },
    { lat: 48.8565257, lng: 17.7233227 },
    { lat: 48.8564611, lng: 17.7233549 },
    { lat: 48.8563726, lng: 17.7234029 },
    { lat: 48.8562951, lng: 17.7234465 },
    { lat: 48.856199, lng: 17.7235006 },
    { lat: 48.8561272, lng: 17.7235421 },
    { lat: 48.8560159, lng: 17.7235702 },
    { lat: 48.8559178, lng: 17.7235896 },
    { lat: 48.8558859, lng: 17.7236951 },
    { lat: 48.8558569, lng: 17.7238116 },
    { lat: 48.8558307, lng: 17.7239164 },
    { lat: 48.8558045, lng: 17.7240226 },
    { lat: 48.8557894, lng: 17.7240886 },
    { lat: 48.855713, lng: 17.7241515 },
    { lat: 48.8556334, lng: 17.7242153 },
    { lat: 48.8555613, lng: 17.724273 },
    { lat: 48.8554569, lng: 17.7243575 },
    { lat: 48.8553662, lng: 17.7243323 },
    { lat: 48.8552524, lng: 17.7242996 },
    { lat: 48.8551065, lng: 17.7242582 },
    { lat: 48.8550065, lng: 17.724301 },
    { lat: 48.8548623, lng: 17.724364 },
    { lat: 48.8548047, lng: 17.724453 },
    { lat: 48.8547113, lng: 17.7246066 },
    { lat: 48.8546195, lng: 17.724655 },
    { lat: 48.8544951, lng: 17.7247239 },
    { lat: 48.8543783, lng: 17.7247883 },
    { lat: 48.8542974, lng: 17.7248339 },
    { lat: 48.8542144, lng: 17.7248786 },
    { lat: 48.8541183, lng: 17.7249308 },
    { lat: 48.8540478, lng: 17.7250327 },
    { lat: 48.8539347, lng: 17.7251965 },
    { lat: 48.8538758, lng: 17.7252841 },
    { lat: 48.8538082, lng: 17.7253791 },
    { lat: 48.8537265, lng: 17.7255002 },
    { lat: 48.8536714, lng: 17.7255772 },
    { lat: 48.8535857, lng: 17.7257071 },
    { lat: 48.8535443, lng: 17.7257694 },
    { lat: 48.8534699, lng: 17.7258435 },
    { lat: 48.8533848, lng: 17.7259274 },
    { lat: 48.8533104, lng: 17.7260034 },
    { lat: 48.8532055, lng: 17.7261157 },
    { lat: 48.853126, lng: 17.7261992 },
    { lat: 48.8530656, lng: 17.7262633 },
    { lat: 48.8530163, lng: 17.7263615 },
    { lat: 48.852909, lng: 17.7265819 },
    { lat: 48.8528564, lng: 17.72668 },
    { lat: 48.8527827, lng: 17.7268229 },
    { lat: 48.8527095, lng: 17.7268807 },
    { lat: 48.8526343, lng: 17.7269422 },
    { lat: 48.8525224, lng: 17.7270326 },
    { lat: 48.8523986, lng: 17.7271308 },
    { lat: 48.8523114, lng: 17.7271988 },
    { lat: 48.8522715, lng: 17.7272313 },
    { lat: 48.8521708, lng: 17.7273002 },
    { lat: 48.8520679, lng: 17.7273718 },
    { lat: 48.8520019, lng: 17.7274175 },
    { lat: 48.8519271, lng: 17.7274686 },
    { lat: 48.8518946, lng: 17.7274907 },
    { lat: 48.851831, lng: 17.727575 },
    { lat: 48.8517789, lng: 17.7276453 },
    { lat: 48.8517214, lng: 17.7277209 },
    { lat: 48.8516703, lng: 17.7277893 },
    { lat: 48.8515974, lng: 17.7278899 },
    { lat: 48.8515308, lng: 17.7279814 },
    { lat: 48.8514693, lng: 17.7280652 },
    { lat: 48.8514109, lng: 17.7281446 },
    { lat: 48.8513534, lng: 17.7282185 },
    { lat: 48.8512919, lng: 17.7283025 },
    { lat: 48.8512578, lng: 17.7283509 },
    { lat: 48.8512446, lng: 17.7283767 },
    { lat: 48.8511587, lng: 17.7284842 },
    { lat: 48.8510778, lng: 17.7285833 },
    { lat: 48.8510182, lng: 17.7286598 },
    { lat: 48.850953, lng: 17.7287388 },
    { lat: 48.8508932, lng: 17.7288133 },
    { lat: 48.8508367, lng: 17.7288842 },
    { lat: 48.8507748, lng: 17.7289611 },
    { lat: 48.8507214, lng: 17.7290289 },
    { lat: 48.8506364, lng: 17.7291344 },
    { lat: 48.8506031, lng: 17.7292354 },
    { lat: 48.8505714, lng: 17.729325 },
    { lat: 48.8505088, lng: 17.7295016 },
    { lat: 48.850474, lng: 17.7295963 },
    { lat: 48.8504338, lng: 17.7297131 },
    { lat: 48.8503933, lng: 17.7298262 },
    { lat: 48.8503559, lng: 17.7299346 },
    { lat: 48.8503129, lng: 17.7300613 },
    { lat: 48.8502864, lng: 17.7301808 },
    { lat: 48.8502621, lng: 17.7302989 },
    { lat: 48.8502367, lng: 17.7304201 },
    { lat: 48.8502091, lng: 17.7305579 },
    { lat: 48.8501681, lng: 17.7307549 },
    { lat: 48.8501154, lng: 17.7308701 },
    { lat: 48.8500484, lng: 17.7310113 },
    { lat: 48.8499955, lng: 17.7311231 },
    { lat: 48.8499394, lng: 17.73124 },
    { lat: 48.8499002, lng: 17.7313187 },
    { lat: 48.8498201, lng: 17.7313929 },
    { lat: 48.8497089, lng: 17.7314959 },
    { lat: 48.8496332, lng: 17.7315661 },
    { lat: 48.8495485, lng: 17.7316391 },
    { lat: 48.849492, lng: 17.7316941 },
    { lat: 48.8494318, lng: 17.7317984 },
    { lat: 48.8493799, lng: 17.7318916 },
    { lat: 48.8493251, lng: 17.7319906 },
    { lat: 48.8492742, lng: 17.7320808 },
    { lat: 48.8492315, lng: 17.7321581 },
    { lat: 48.8491636, lng: 17.7322113 },
    { lat: 48.8490513, lng: 17.7322943 },
    { lat: 48.8489596, lng: 17.7323619 },
    { lat: 48.8488785, lng: 17.7324214 },
    { lat: 48.8488128, lng: 17.7324724 },
    { lat: 48.8487637, lng: 17.7325573 },
    { lat: 48.8487275, lng: 17.7326273 },
    { lat: 48.8486863, lng: 17.7327097 },
    { lat: 48.8486396, lng: 17.7327976 },
    { lat: 48.848587, lng: 17.7328961 },
    { lat: 48.8485349, lng: 17.7330028 },
    { lat: 48.8484821, lng: 17.7330982 },
    { lat: 48.8483968, lng: 17.7331617 },
    { lat: 48.8483274, lng: 17.7332082 },
    { lat: 48.8482515, lng: 17.7332587 },
    { lat: 48.8481669, lng: 17.7333173 },
    { lat: 48.8480846, lng: 17.7333737 },
    { lat: 48.8479738, lng: 17.7334449 },
    { lat: 48.8478807, lng: 17.7335077 },
    { lat: 48.8477949, lng: 17.7335648 },
    { lat: 48.8477038, lng: 17.7336255 },
    { lat: 48.8476236, lng: 17.7337176 },
    { lat: 48.8475339, lng: 17.7338192 },
    { lat: 48.8474315, lng: 17.7339342 },
    { lat: 48.8473576, lng: 17.734019 },
    { lat: 48.8472618, lng: 17.7341309 },
    { lat: 48.8471878, lng: 17.7341947 },
    { lat: 48.8470991, lng: 17.7342748 },
    { lat: 48.8470167, lng: 17.7343494 },
    { lat: 48.8469269, lng: 17.7344314 },
    { lat: 48.8468475, lng: 17.7344989 },
    { lat: 48.8467783, lng: 17.7345665 },
    { lat: 48.8467273, lng: 17.7346746 },
    { lat: 48.8466645, lng: 17.7348091 },
    { lat: 48.846537, lng: 17.7350859 },
    { lat: 48.8464666, lng: 17.7351539 },
    { lat: 48.8463793, lng: 17.735239 },
    { lat: 48.8462897, lng: 17.7353239 },
    { lat: 48.8462063, lng: 17.7354001 },
    { lat: 48.8461282, lng: 17.7354724 },
    { lat: 48.8460795, lng: 17.7355247 },
    { lat: 48.8443074, lng: 17.7359165 },
    { lat: 48.8442708, lng: 17.735981 },
    { lat: 48.8442167, lng: 17.7361114 },
    { lat: 48.8441587, lng: 17.736271 },
    { lat: 48.844032, lng: 17.7365595 },
    { lat: 48.8439799, lng: 17.7366687 },
    { lat: 48.843921, lng: 17.7368861 },
    { lat: 48.8438995, lng: 17.7370141 },
    { lat: 48.8438792, lng: 17.7371417 },
    { lat: 48.8438331, lng: 17.7374224 },
    { lat: 48.8438203, lng: 17.7375075 },
    { lat: 48.8438081, lng: 17.7376582 },
    { lat: 48.843794, lng: 17.7378136 },
    { lat: 48.843768, lng: 17.7380699 },
    { lat: 48.8437571, lng: 17.7381874 },
    { lat: 48.8437359, lng: 17.7384675 },
    { lat: 48.8437078, lng: 17.7387451 },
    { lat: 48.8436816, lng: 17.7390178 },
    { lat: 48.8436583, lng: 17.7392614 },
    { lat: 48.8436991, lng: 17.7393939 },
    { lat: 48.8437669, lng: 17.7395244 },
    { lat: 48.8438346, lng: 17.7396513 },
    { lat: 48.8439008, lng: 17.7397742 },
    { lat: 48.8439393, lng: 17.7398491 },
    { lat: 48.8440006, lng: 17.7399277 },
    { lat: 48.844058, lng: 17.7399988 },
    { lat: 48.8441273, lng: 17.7400801 },
    { lat: 48.8441834, lng: 17.7401846 },
    { lat: 48.8442329, lng: 17.7402739 },
    { lat: 48.8442871, lng: 17.7403658 },
    { lat: 48.8443804, lng: 17.7405251 },
    { lat: 48.8444376, lng: 17.7405714 },
    { lat: 48.8444818, lng: 17.7406121 },
    { lat: 48.8445149, lng: 17.7407089 },
    { lat: 48.8445571, lng: 17.7409189 },
    { lat: 48.8445705, lng: 17.7410111 },
    { lat: 48.8446097, lng: 17.7412792 },
    { lat: 48.8446395, lng: 17.7414713 },
    { lat: 48.8446549, lng: 17.7416496 },
    { lat: 48.8446707, lng: 17.7417782 },
    { lat: 48.8446851, lng: 17.7418826 },
    { lat: 48.8446677, lng: 17.741894 },
    { lat: 48.8446651, lng: 17.7421087 },
    { lat: 48.8446643, lng: 17.7424633 },
    { lat: 48.8446601, lng: 17.74267 },
    { lat: 48.8446424, lng: 17.7428055 },
    { lat: 48.8446178, lng: 17.7430864 },
    { lat: 48.844606, lng: 17.743225 },
    { lat: 48.8445881, lng: 17.743467 },
    { lat: 48.8445592, lng: 17.7433821 },
    { lat: 48.8445361, lng: 17.743263 },
    { lat: 48.8444759, lng: 17.743036 },
    { lat: 48.8444318, lng: 17.7428882 },
    { lat: 48.8443872, lng: 17.742806 },
    { lat: 48.8443396, lng: 17.7427288 },
    { lat: 48.8443019, lng: 17.7426662 },
    { lat: 48.8442282, lng: 17.7425325 },
    { lat: 48.8441956, lng: 17.7424604 },
    { lat: 48.8441632, lng: 17.7423927 },
    { lat: 48.8441344, lng: 17.7423283 },
    { lat: 48.8441077, lng: 17.7422799 },
    { lat: 48.8440726, lng: 17.7422046 },
    { lat: 48.8440374, lng: 17.7421454 },
    { lat: 48.8440143, lng: 17.7421008 },
    { lat: 48.8439521, lng: 17.7419893 },
    { lat: 48.8439291, lng: 17.7419441 },
    { lat: 48.8438615, lng: 17.7418006 },
    { lat: 48.8438387, lng: 17.741723 },
    { lat: 48.8438046, lng: 17.7416266 },
    { lat: 48.8437089, lng: 17.7414476 },
    { lat: 48.8436878, lng: 17.7413984 },
    { lat: 48.8436616, lng: 17.741358 },
    { lat: 48.8435879, lng: 17.7412983 },
    { lat: 48.843536, lng: 17.7412794 },
    { lat: 48.8434958, lng: 17.7412672 },
    { lat: 48.8433842, lng: 17.7412351 },
    { lat: 48.843314, lng: 17.741212 },
    { lat: 48.8432649, lng: 17.7412216 },
    { lat: 48.8431999, lng: 17.7412302 },
    { lat: 48.8431454, lng: 17.7412614 },
    { lat: 48.8430992, lng: 17.7412996 },
    { lat: 48.8430244, lng: 17.7413678 },
    { lat: 48.8429374, lng: 17.741422 },
    { lat: 48.8428745, lng: 17.7414651 },
    { lat: 48.8428012, lng: 17.7415755 },
    { lat: 48.8427209, lng: 17.7417036 },
    { lat: 48.8426476, lng: 17.741814 },
    { lat: 48.8425554, lng: 17.7419312 },
    { lat: 48.8424184, lng: 17.7421464 },
    { lat: 48.8423688, lng: 17.7422201 },
    { lat: 48.8423002, lng: 17.7423179 },
    { lat: 48.8422395, lng: 17.7424136 },
    { lat: 48.8421982, lng: 17.7424943 },
    { lat: 48.8421208, lng: 17.7426301 },
    { lat: 48.8420403, lng: 17.7427538 },
    { lat: 48.8419715, lng: 17.742847 },
    { lat: 48.8420357, lng: 17.7429192 },
    { lat: 48.8420288, lng: 17.7431379 },
    { lat: 48.842038, lng: 17.7432681 },
    { lat: 48.8421157, lng: 17.7435228 },
    { lat: 48.8421276, lng: 17.7436071 },
    { lat: 48.8421931, lng: 17.7436991 },
    { lat: 48.8422317, lng: 17.7437754 },
    { lat: 48.8422871, lng: 17.7438321 },
    { lat: 48.8423301, lng: 17.7438705 },
    { lat: 48.8424139, lng: 17.7439845 },
    { lat: 48.8424185, lng: 17.7441357 },
    { lat: 48.8424267, lng: 17.7442496 },
    { lat: 48.8424358, lng: 17.7444908 },
    { lat: 48.8425123, lng: 17.7445769 },
    { lat: 48.8426707, lng: 17.7447489 },
    { lat: 48.8426396, lng: 17.7448854 },
    { lat: 48.8426143, lng: 17.7449882 },
    { lat: 48.8426024, lng: 17.7450886 },
    { lat: 48.8425664, lng: 17.7452014 },
    { lat: 48.8425472, lng: 17.7452742 },
    { lat: 48.8425024, lng: 17.7453717 },
    { lat: 48.8424563, lng: 17.7454859 },
    { lat: 48.8424171, lng: 17.7455825 },
    { lat: 48.8423783, lng: 17.7456876 },
    { lat: 48.8423272, lng: 17.7458107 },
    { lat: 48.8422589, lng: 17.7456902 },
    { lat: 48.8422148, lng: 17.7455986 },
    { lat: 48.8421628, lng: 17.7455045 },
    { lat: 48.8420595, lng: 17.7453113 },
    { lat: 48.8419739, lng: 17.7452963 },
    { lat: 48.841879, lng: 17.7454059 },
    { lat: 48.8417627, lng: 17.7454617 },
    { lat: 48.8417742, lng: 17.745649 },
    { lat: 48.8417884, lng: 17.7458972 },
    { lat: 48.8418036, lng: 17.7461085 },
    { lat: 48.8418089, lng: 17.7462309 },
    { lat: 48.841837, lng: 17.746321 },
    { lat: 48.8419078, lng: 17.7465192 },
    { lat: 48.8419478, lng: 17.7466197 },
    { lat: 48.8420612, lng: 17.7468851 },
    { lat: 48.8421487, lng: 17.746986 },
    { lat: 48.8422196, lng: 17.7470569 },
    { lat: 48.8422718, lng: 17.7471146 },
    { lat: 48.8423334, lng: 17.7471824 },
    { lat: 48.8423864, lng: 17.7472357 },
    { lat: 48.8424562, lng: 17.7473067 },
    { lat: 48.8425439, lng: 17.7473913 },
    { lat: 48.8425962, lng: 17.747453 },
    { lat: 48.8426555, lng: 17.7475172 },
    { lat: 48.8427116, lng: 17.7475864 },
    { lat: 48.8427557, lng: 17.7476246 },
    { lat: 48.8428111, lng: 17.7476813 },
    { lat: 48.8428549, lng: 17.7477316 },
    { lat: 48.8429493, lng: 17.7478359 },
    { lat: 48.8430072, lng: 17.7478965 },
    { lat: 48.8430771, lng: 17.7479675 },
    { lat: 48.8431635, lng: 17.7480688 },
    { lat: 48.8432343, lng: 17.7481558 },
    { lat: 48.8433019, lng: 17.7482271 },
    { lat: 48.8433522, lng: 17.7482931 },
    { lat: 48.8434571, lng: 17.7484196 },
    { lat: 48.8434971, lng: 17.7485204 },
    { lat: 48.8435627, lng: 17.7486877 },
    { lat: 48.8436082, lng: 17.7488044 },
    { lat: 48.8436512, lng: 17.7489177 },
    { lat: 48.8436804, lng: 17.7489873 },
    { lat: 48.8437062, lng: 17.7490575 },
    { lat: 48.8436649, lng: 17.749121 },
    { lat: 48.8436194, lng: 17.7491891 },
    { lat: 48.8435809, lng: 17.7492436 },
    { lat: 48.843549, lng: 17.7492934 },
    { lat: 48.8435137, lng: 17.7493434 },
    { lat: 48.8434046, lng: 17.7494232 },
    { lat: 48.8433019, lng: 17.7494809 },
    { lat: 48.843197, lng: 17.7495551 },
    { lat: 48.8431002, lng: 17.7496157 },
    { lat: 48.84301, lng: 17.7496716 },
    { lat: 48.8428405, lng: 17.749781 },
    { lat: 48.842894, lng: 17.7498792 },
    { lat: 48.8429284, lng: 17.7499435 },
    { lat: 48.8428995, lng: 17.7500058 },
    { lat: 48.8428133, lng: 17.7499251 },
    { lat: 48.8427318, lng: 17.7498477 },
    { lat: 48.8426337, lng: 17.7499248 },
    { lat: 48.8425596, lng: 17.7499861 },
    { lat: 48.8424485, lng: 17.7500696 },
    { lat: 48.8423763, lng: 17.7501264 },
    { lat: 48.8423094, lng: 17.7501783 },
    { lat: 48.8422513, lng: 17.7502245 },
    { lat: 48.8421404, lng: 17.7503123 },
    { lat: 48.8420923, lng: 17.7503572 },
    { lat: 48.842029, lng: 17.7503918 },
    { lat: 48.8419761, lng: 17.7502483 },
    { lat: 48.84194, lng: 17.7501554 },
    { lat: 48.8419008, lng: 17.7500673 },
    { lat: 48.8418675, lng: 17.7499863 },
    { lat: 48.8418436, lng: 17.7499082 },
    { lat: 48.8418496, lng: 17.7498043 },
    { lat: 48.8418578, lng: 17.7497168 },
    { lat: 48.8418673, lng: 17.7496328 },
    { lat: 48.8418701, lng: 17.7495503 },
    { lat: 48.8417862, lng: 17.7495061 },
    { lat: 48.8417325, lng: 17.7494778 },
    { lat: 48.8416591, lng: 17.7494402 },
    { lat: 48.8415715, lng: 17.7494295 },
    { lat: 48.8414204, lng: 17.7493964 },
    { lat: 48.841368, lng: 17.7493884 },
    { lat: 48.8413131, lng: 17.7493397 },
    { lat: 48.8412533, lng: 17.749304 },
    { lat: 48.841204, lng: 17.7492174 },
    { lat: 48.8412296, lng: 17.7491928 },
    { lat: 48.8412688, lng: 17.7490217 },
    { lat: 48.841284, lng: 17.7489576 },
    { lat: 48.8412694, lng: 17.7488655 },
    { lat: 48.8412516, lng: 17.7487779 },
    { lat: 48.8412136, lng: 17.7486938 },
    { lat: 48.8411663, lng: 17.7486397 },
    { lat: 48.8410764, lng: 17.7485187 },
    { lat: 48.8410291, lng: 17.7484646 },
    { lat: 48.8409461, lng: 17.7483625 },
    { lat: 48.8409107, lng: 17.7483191 },
    { lat: 48.8408051, lng: 17.7482001 },
    { lat: 48.8407442, lng: 17.7481814 },
    { lat: 48.8406773, lng: 17.7481591 },
    { lat: 48.8406464, lng: 17.7480246 },
    { lat: 48.8405976, lng: 17.747946 },
    { lat: 48.8405174, lng: 17.7479628 },
    { lat: 48.8403601, lng: 17.7480498 },
    { lat: 48.840228, lng: 17.7481779 },
    { lat: 48.8401782, lng: 17.7482312 },
    { lat: 48.8401083, lng: 17.7482876 },
    { lat: 48.839989, lng: 17.7484053 },
    { lat: 48.8398774, lng: 17.7485176 },
    { lat: 48.8398855, lng: 17.7485944 },
    { lat: 48.8398479, lng: 17.7486253 },
    { lat: 48.8397916, lng: 17.7486465 },
    { lat: 48.8397686, lng: 17.7487322 },
    { lat: 48.8397139, lng: 17.7489259 },
    { lat: 48.8396805, lng: 17.7490259 },
    { lat: 48.8396501, lng: 17.7491008 },
    { lat: 48.8396279, lng: 17.7491616 },
    { lat: 48.8395607, lng: 17.7492998 },
    { lat: 48.8395197, lng: 17.7493681 },
    { lat: 48.8394571, lng: 17.7494892 },
    { lat: 48.8394008, lng: 17.7495638 },
    { lat: 48.8393636, lng: 17.7496192 },
    { lat: 48.8393177, lng: 17.7496637 },
    { lat: 48.839239, lng: 17.749742 },
    { lat: 48.8392067, lng: 17.7496566 },
    { lat: 48.8391927, lng: 17.7496137 },
    { lat: 48.8391576, lng: 17.7495375 },
    { lat: 48.8391319, lng: 17.7494508 },
    { lat: 48.8390786, lng: 17.7495006 },
    { lat: 48.8390193, lng: 17.7495471 },
    { lat: 48.8389685, lng: 17.7495842 },
    { lat: 48.8388468, lng: 17.7497185 },
    { lat: 48.8387925, lng: 17.7497888 },
    { lat: 48.8387103, lng: 17.7498843 },
    { lat: 48.8386381, lng: 17.7499778 },
    { lat: 48.8385326, lng: 17.7500974 },
    { lat: 48.8384424, lng: 17.7502105 },
    { lat: 48.838348, lng: 17.7503286 },
    { lat: 48.8382448, lng: 17.750448 },
    { lat: 48.8381979, lng: 17.7505131 },
    { lat: 48.8381207, lng: 17.7506159 },
    { lat: 48.8380154, lng: 17.7507393 },
    { lat: 48.8379536, lng: 17.7508194 },
    { lat: 48.8378688, lng: 17.7509275 },
    { lat: 48.8377744, lng: 17.7510451 },
    { lat: 48.8377171, lng: 17.7511241 },
    { lat: 48.8376589, lng: 17.7511871 },
    { lat: 48.8376096, lng: 17.7511004 },
    { lat: 48.8375683, lng: 17.7510164 },
    { lat: 48.8375187, lng: 17.7509259 },
    { lat: 48.8374279, lng: 17.7507514 },
    { lat: 48.837393, lng: 17.7507162 },
    { lat: 48.8373336, lng: 17.7506514 },
    { lat: 48.837292, lng: 17.7505965 },
    { lat: 48.8372484, lng: 17.75055 },
    { lat: 48.8371664, lng: 17.7504646 },
    { lat: 48.8370999, lng: 17.7505576 },
    { lat: 48.8370069, lng: 17.7506999 },
    { lat: 48.8369088, lng: 17.750847 },
    { lat: 48.8368211, lng: 17.7510009 },
    { lat: 48.8367516, lng: 17.7511189 },
    { lat: 48.8366885, lng: 17.7512318 },
    { lat: 48.8366006, lng: 17.7512171 },
    { lat: 48.8365377, lng: 17.7512397 },
    { lat: 48.8364926, lng: 17.7512588 },
    { lat: 48.8364508, lng: 17.7512779 },
    { lat: 48.8363588, lng: 17.7513217 },
    { lat: 48.8362772, lng: 17.751355 },
    { lat: 48.8362094, lng: 17.7513905 },
    { lat: 48.8361355, lng: 17.751419 },
    { lat: 48.836098, lng: 17.7514333 },
    { lat: 48.8360558, lng: 17.7514442 },
    { lat: 48.835978, lng: 17.7514647 },
    { lat: 48.8358848, lng: 17.751529 },
    { lat: 48.8357988, lng: 17.7516167 },
    { lat: 48.8357661, lng: 17.7516549 },
    { lat: 48.8357003, lng: 17.7517231 },
    { lat: 48.8356579, lng: 17.7517667 },
    { lat: 48.8355886, lng: 17.7518354 },
    { lat: 48.8355112, lng: 17.7519177 },
    { lat: 48.835446, lng: 17.7519939 },
    { lat: 48.8353467, lng: 17.7520878 },
    { lat: 48.8353165, lng: 17.7521297 },
    { lat: 48.835292, lng: 17.752043 },
    { lat: 48.8352705, lng: 17.7519686 },
    { lat: 48.835255, lng: 17.7519011 },
    { lat: 48.8352133, lng: 17.7517722 },
    { lat: 48.8351746, lng: 17.7516922 },
    { lat: 48.8351524, lng: 17.7516423 },
    { lat: 48.8351241, lng: 17.7515522 },
    { lat: 48.8350809, lng: 17.7513988 },
    { lat: 48.8350574, lng: 17.7513286 },
    { lat: 48.8350308, lng: 17.751263 },
    { lat: 48.8350137, lng: 17.7512043 },
    { lat: 48.8349598, lng: 17.751061 },
    { lat: 48.8349089, lng: 17.7510238 },
    { lat: 48.8348706, lng: 17.7509888 },
    { lat: 48.8348022, lng: 17.7509422 },
    { lat: 48.8347264, lng: 17.7509378 },
    { lat: 48.834717, lng: 17.7508408 },
    { lat: 48.834689, lng: 17.7507139 },
    { lat: 48.8346976, lng: 17.7505973 },
    { lat: 48.8346723, lng: 17.7504414 },
    { lat: 48.8346656, lng: 17.7503316 },
    { lat: 48.8346445, lng: 17.7501541 },
    { lat: 48.8346024, lng: 17.7500579 },
    { lat: 48.8345648, lng: 17.7499408 },
    { lat: 48.8345063, lng: 17.7499996 },
    { lat: 48.8344361, lng: 17.750089 },
    { lat: 48.834369, lng: 17.7501737 },
    { lat: 48.8343329, lng: 17.7502288 },
    { lat: 48.8342643, lng: 17.7503424 },
    { lat: 48.8342124, lng: 17.7504534 },
    { lat: 48.8341658, lng: 17.7504858 },
    { lat: 48.8340733, lng: 17.750562 },
    { lat: 48.8339734, lng: 17.7506436 },
    { lat: 48.8339476, lng: 17.7506643 },
    { lat: 48.8338458, lng: 17.7507175 },
    { lat: 48.8337804, lng: 17.7507566 },
    { lat: 48.8337205, lng: 17.7507912 },
    { lat: 48.8336767, lng: 17.7508144 },
    { lat: 48.8335871, lng: 17.7508616 },
    { lat: 48.8335588, lng: 17.7508785 },
    { lat: 48.8335298, lng: 17.7509202 },
    { lat: 48.8334773, lng: 17.7509862 },
    { lat: 48.8334269, lng: 17.7510479 },
    { lat: 48.8334074, lng: 17.7511166 },
    { lat: 48.8333763, lng: 17.7512163 },
    { lat: 48.833335, lng: 17.7513174 },
    { lat: 48.8333039, lng: 17.7514906 },
    { lat: 48.8332709, lng: 17.7516522 },
    { lat: 48.8332473, lng: 17.7517834 },
    { lat: 48.833214, lng: 17.7519202 },
    { lat: 48.833199, lng: 17.7519719 },
    { lat: 48.8331628, lng: 17.7521171 },
    { lat: 48.8331319, lng: 17.752258 },
    { lat: 48.8331005, lng: 17.7523902 },
    { lat: 48.8330762, lng: 17.7525095 },
    { lat: 48.8330418, lng: 17.7526297 },
    { lat: 48.8330172, lng: 17.7527448 },
    { lat: 48.8329818, lng: 17.7528656 },
    { lat: 48.8329455, lng: 17.7529737 },
    { lat: 48.8329322, lng: 17.7530872 },
    { lat: 48.8329038, lng: 17.7531779 },
    { lat: 48.8328663, lng: 17.753303 },
    { lat: 48.8328567, lng: 17.7534031 },
    { lat: 48.8328457, lng: 17.7534461 },
    { lat: 48.8328338, lng: 17.7535465 },
    { lat: 48.8328306, lng: 17.7536048 },
    { lat: 48.832821, lng: 17.7537049 },
    { lat: 48.8329237, lng: 17.7543722 },
    { lat: 48.832793, lng: 17.753931 },
    { lat: 48.832789, lng: 17.7540304 },
    { lat: 48.8327638, lng: 17.7541001 },
    { lat: 48.8327252, lng: 17.7542048 },
    { lat: 48.8326906, lng: 17.7542846 },
    { lat: 48.8326718, lng: 17.7543659 },
    { lat: 48.8326007, lng: 17.7544018 },
    { lat: 48.8325515, lng: 17.7544301 },
    { lat: 48.8324865, lng: 17.7544734 },
    { lat: 48.8324477, lng: 17.7545042 },
    { lat: 48.8323808, lng: 17.7545558 },
    { lat: 48.8323597, lng: 17.7545964 },
    { lat: 48.832305, lng: 17.7546627 },
    { lat: 48.8322481, lng: 17.754746 },
    { lat: 48.8322014, lng: 17.7548151 },
    { lat: 48.8321604, lng: 17.7548832 },
    { lat: 48.8321166, lng: 17.7549599 },
    { lat: 48.8320845, lng: 17.7550062 },
    { lat: 48.8320528, lng: 17.7550608 },
    { lat: 48.8320105, lng: 17.7551454 },
    { lat: 48.8319604, lng: 17.7552482 },
    { lat: 48.8319137, lng: 17.75535 },
    { lat: 48.8318713, lng: 17.7554513 },
    { lat: 48.8318464, lng: 17.7555251 },
    { lat: 48.8317698, lng: 17.7555828 },
    { lat: 48.8317301, lng: 17.755634 },
    { lat: 48.8316857, lng: 17.7557028 },
    { lat: 48.8316445, lng: 17.7558039 },
    { lat: 48.83161, lng: 17.7558668 },
    { lat: 48.8315882, lng: 17.7559734 },
    { lat: 48.8315683, lng: 17.7560339 },
    { lat: 48.8315508, lng: 17.7561357 },
    { lat: 48.8315399, lng: 17.7561782 },
    { lat: 48.8315163, lng: 17.7562726 },
    { lat: 48.8314965, lng: 17.7563741 },
    { lat: 48.8314776, lng: 17.7564511 },
    { lat: 48.8314593, lng: 17.7565406 },
    { lat: 48.8314399, lng: 17.7566461 },
    { lat: 48.8314282, lng: 17.756751 },
    { lat: 48.8314211, lng: 17.7568014 },
    { lat: 48.8314231, lng: 17.7569077 },
    { lat: 48.8314254, lng: 17.7570185 },
    { lat: 48.8313883, lng: 17.7570776 },
    { lat: 48.8313529, lng: 17.757071 },
    { lat: 48.8313086, lng: 17.7570657 },
    { lat: 48.8312358, lng: 17.7570572 },
    { lat: 48.8311688, lng: 17.7570678 },
    { lat: 48.8311292, lng: 17.7570867 },
    { lat: 48.8310793, lng: 17.7571189 },
    { lat: 48.8310062, lng: 17.7571595 },
    { lat: 48.8309322, lng: 17.7572247 },
    { lat: 48.8308676, lng: 17.7572761 },
    { lat: 48.8307973, lng: 17.7573449 },
    { lat: 48.8307665, lng: 17.7573745 },
    { lat: 48.8307257, lng: 17.7574102 },
    { lat: 48.8306726, lng: 17.7574637 },
    { lat: 48.8305986, lng: 17.7575289 },
    { lat: 48.8305379, lng: 17.7575878 },
    { lat: 48.8304663, lng: 17.7576527 },
    { lat: 48.8304095, lng: 17.7577029 },
    { lat: 48.8303628, lng: 17.7577351 },
    { lat: 48.8303019, lng: 17.7577899 },
    { lat: 48.8302331, lng: 17.7578461 },
    { lat: 48.8301663, lng: 17.7578978 },
    { lat: 48.8300697, lng: 17.7579628 },
    { lat: 48.8300027, lng: 17.7580106 },
    { lat: 48.8299337, lng: 17.7580627 },
    { lat: 48.8298742, lng: 17.7581051 },
    { lat: 48.8298195, lng: 17.7581343 },
    { lat: 48.8297561, lng: 17.7581692 },
    { lat: 48.8296886, lng: 17.7582459 },
    { lat: 48.8296152, lng: 17.7583191 },
    { lat: 48.8295491, lng: 17.7584203 },
    { lat: 48.829506, lng: 17.7585091 },
    { lat: 48.8294622, lng: 17.7586231 },
    { lat: 48.8294073, lng: 17.7587222 },
    { lat: 48.8293508, lng: 17.7588136 },
    { lat: 48.8292928, lng: 17.7589171 },
    { lat: 48.8292587, lng: 17.7589676 },
    { lat: 48.8292339, lng: 17.7590049 },
    { lat: 48.8291862, lng: 17.7590573 },
    { lat: 48.8291336, lng: 17.7591194 },
    { lat: 48.8291083, lng: 17.7591482 },
    { lat: 48.8290884, lng: 17.7591757 },
    { lat: 48.8290515, lng: 17.7592185 },
    { lat: 48.8290205, lng: 17.7592444 },
    { lat: 48.8289453, lng: 17.7593262 },
    { lat: 48.8289053, lng: 17.7593738 },
    { lat: 48.8288651, lng: 17.759417 },
    { lat: 48.8288293, lng: 17.7594597 },
    { lat: 48.8287612, lng: 17.7595282 },
    { lat: 48.8287103, lng: 17.7595816 },
    { lat: 48.8286605, lng: 17.7596348 },
    { lat: 48.8286045, lng: 17.759697 },
    { lat: 48.8285507, lng: 17.7597425 },
    { lat: 48.8284947, lng: 17.7597846 },
    { lat: 48.8284356, lng: 17.7598352 },
    { lat: 48.8283931, lng: 17.7598583 },
    { lat: 48.828342, lng: 17.7598911 },
    { lat: 48.8282816, lng: 17.7599172 },
    { lat: 48.8282221, lng: 17.7599598 },
    { lat: 48.8281699, lng: 17.7599927 },
    { lat: 48.8281435, lng: 17.7600054 },
    { lat: 48.8281286, lng: 17.7600198 },
    { lat: 48.8280942, lng: 17.7600293 },
    { lat: 48.8280594, lng: 17.7600514 },
    { lat: 48.8279916, lng: 17.7600869 },
    { lat: 48.827958, lng: 17.7601088 },
    { lat: 48.8279108, lng: 17.7601328 },
    { lat: 48.8278328, lng: 17.7601863 },
    { lat: 48.8277772, lng: 17.7602364 },
    { lat: 48.8277384, lng: 17.7602671 },
    { lat: 48.8276767, lng: 17.76031 },
    { lat: 48.8276436, lng: 17.7603397 },
    { lat: 48.8276122, lng: 17.7603613 },
    { lat: 48.8275456, lng: 17.7604172 },
    { lat: 48.8274897, lng: 17.7604631 },
    { lat: 48.8274357, lng: 17.7605045 },
    { lat: 48.8273865, lng: 17.7605494 },
    { lat: 48.8273272, lng: 17.760592 },
    { lat: 48.8272636, lng: 17.7606432 },
    { lat: 48.8271993, lng: 17.7606988 },
    { lat: 48.8271534, lng: 17.7607429 },
    { lat: 48.8271281, lng: 17.7607717 },
    { lat: 48.8271, lng: 17.7607928 },
    { lat: 48.8270469, lng: 17.7608463 },
    { lat: 48.8270012, lng: 17.7608947 },
    { lat: 48.82694, lng: 17.7609455 },
    { lat: 48.8268942, lng: 17.7609896 },
    { lat: 48.8268408, lng: 17.7610394 },
    { lat: 48.826767, lng: 17.7609939 },
    { lat: 48.826713, lng: 17.7610724 },
    { lat: 48.8266581, lng: 17.7611344 },
    { lat: 48.8266031, lng: 17.761213 },
    { lat: 48.826536, lng: 17.7612977 },
    { lat: 48.8265117, lng: 17.7613431 },
    { lat: 48.8264524, lng: 17.7614261 },
    { lat: 48.8264234, lng: 17.7614678 },
    { lat: 48.8263654, lng: 17.7615345 },
    { lat: 48.8263376, lng: 17.7615966 },
    { lat: 48.8262917, lng: 17.7616778 },
    { lat: 48.8262583, lng: 17.7617407 },
    { lat: 48.8262194, lng: 17.7618415 },
    { lat: 48.8261701, lng: 17.7619403 },
    { lat: 48.8261207, lng: 17.7620562 },
    { lat: 48.826082, lng: 17.7621445 },
    { lat: 48.8260394, lng: 17.7622396 },
    { lat: 48.8260032, lng: 17.7623305 },
    { lat: 48.8259848, lng: 17.7624369 },
    { lat: 48.8259638, lng: 17.7625352 },
    { lat: 48.8259373, lng: 17.7626744 },
    { lat: 48.8259106, lng: 17.7628098 },
    { lat: 48.8258908, lng: 17.7629097 },
    { lat: 48.8258613, lng: 17.7630193 },
    { lat: 48.8258468, lng: 17.7630757 },
    { lat: 48.8258132, lng: 17.763175 },
    { lat: 48.8257907, lng: 17.7632485 },
    { lat: 48.8257704, lng: 17.7633408 },
    { lat: 48.8257554, lng: 17.7634283 },
    { lat: 48.8257323, lng: 17.7635305 },
    { lat: 48.8257065, lng: 17.7636246 },
    { lat: 48.8256846, lng: 17.7637299 },
    { lat: 48.8256594, lng: 17.7638338 },
    { lat: 48.825636, lng: 17.7639311 },
    { lat: 48.8256045, lng: 17.7640249 },
    { lat: 48.8255748, lng: 17.7641312 },
    { lat: 48.8255495, lng: 17.7642335 },
    { lat: 48.825511, lng: 17.7643052 },
    { lat: 48.825481, lng: 17.7643855 },
    { lat: 48.8254501, lng: 17.7644527 },
    { lat: 48.8254295, lng: 17.7645053 },
    { lat: 48.8254103, lng: 17.7645771 },
    { lat: 48.8253843, lng: 17.7646322 },
    { lat: 48.8253496, lng: 17.7646935 },
    { lat: 48.8253249, lng: 17.7647331 },
    { lat: 48.8253121, lng: 17.7647632 },
    { lat: 48.8252698, lng: 17.7648466 },
    { lat: 48.8252512, lng: 17.76493 },
    { lat: 48.8252089, lng: 17.7650137 },
    { lat: 48.8251668, lng: 17.7651568 },
    { lat: 48.8250936, lng: 17.7653256 },
    { lat: 48.8250692, lng: 17.7653688 },
    { lat: 48.8250157, lng: 17.7654361 },
    { lat: 48.8249605, lng: 17.7655118 },
    { lat: 48.8249184, lng: 17.7655972 },
    { lat: 48.8248873, lng: 17.7656975 },
    { lat: 48.8248526, lng: 17.7658125 },
    { lat: 48.8248223, lng: 17.7659092 },
    { lat: 48.8248054, lng: 17.7659662 },
    { lat: 48.8247828, lng: 17.766093 },
    { lat: 48.8247578, lng: 17.7662033 },
    { lat: 48.8247385, lng: 17.7662936 },
    { lat: 48.8247091, lng: 17.7664392 },
    { lat: 48.8247079, lng: 17.7665689 },
    { lat: 48.8247078, lng: 17.7666775 },
    { lat: 48.8247098, lng: 17.7667644 },
    { lat: 48.8247096, lng: 17.766873 },
    { lat: 48.8247111, lng: 17.7669878 },
    { lat: 48.8247091, lng: 17.767085 },
    { lat: 48.8247087, lng: 17.7671883 },
    { lat: 48.8247105, lng: 17.7673115 },
    { lat: 48.8246775, lng: 17.7674166 },
    { lat: 48.8246471, lng: 17.7675115 },
    { lat: 48.8246, lng: 17.7675715 },
    { lat: 48.8245363, lng: 17.7676553 },
    { lat: 48.8244846, lng: 17.7677154 },
    { lat: 48.8244401, lng: 17.7677799 },
    { lat: 48.8243617, lng: 17.7678461 },
    { lat: 48.8242934, lng: 17.7678927 },
    { lat: 48.8242188, lng: 17.7679469 },
    { lat: 48.8241484, lng: 17.7679957 },
    { lat: 48.8240738, lng: 17.7680497 },
    { lat: 48.8240089, lng: 17.7680973 },
    { lat: 48.8239506, lng: 17.7681391 },
    { lat: 48.823916, lng: 17.7681642 },
    { lat: 48.8238412, lng: 17.7681969 },
    { lat: 48.8237567, lng: 17.768236 },
    { lat: 48.8236842, lng: 17.7682684 },
    { lat: 48.8236204, lng: 17.7682967 },
    { lat: 48.8235567, lng: 17.7683261 },
    { lat: 48.8234789, lng: 17.7683644 },
    { lat: 48.8234052, lng: 17.7683952 },
    { lat: 48.8233624, lng: 17.7684166 },
    { lat: 48.8232829, lng: 17.7684632 },
    { lat: 48.8232263, lng: 17.7684998 },
    { lat: 48.823139, lng: 17.7685475 },
    { lat: 48.8230736, lng: 17.7685859 },
    { lat: 48.823009, lng: 17.7686188 },
    { lat: 48.8229326, lng: 17.7686616 },
    { lat: 48.8228507, lng: 17.7687069 },
    { lat: 48.8227692, lng: 17.7687029 },
    { lat: 48.8226954, lng: 17.7686979 },
    { lat: 48.822605, lng: 17.7686952 },
    { lat: 48.8225417, lng: 17.768713 },
    { lat: 48.8224561, lng: 17.7687345 },
    { lat: 48.8223664, lng: 17.7687632 },
    { lat: 48.8222688, lng: 17.7687911 },
    { lat: 48.8222054, lng: 17.7688076 },
    { lat: 48.8221546, lng: 17.768825 },
    { lat: 48.8220955, lng: 17.7688376 },
    { lat: 48.8219325, lng: 17.768887 },
    { lat: 48.8218662, lng: 17.7689086 },
    { lat: 48.8218028, lng: 17.7689265 },
    { lat: 48.8217241, lng: 17.7689486 },
    { lat: 48.8216582, lng: 17.76896 },
    { lat: 48.8215947, lng: 17.7689732 },
    { lat: 48.8215547, lng: 17.7689844 },
    { lat: 48.8215067, lng: 17.7689933 },
    { lat: 48.8214654, lng: 17.769003 },
    { lat: 48.8214086, lng: 17.7690135 },
    { lat: 48.821304, lng: 17.7690393 },
    { lat: 48.821236, lng: 17.7690346 },
    { lat: 48.8211603, lng: 17.7690893 },
    { lat: 48.8210924, lng: 17.7691408 },
    { lat: 48.8210202, lng: 17.7691978 },
    { lat: 48.8209394, lng: 17.7692627 },
    { lat: 48.8208574, lng: 17.769323 },
    { lat: 48.8207853, lng: 17.7693818 },
    { lat: 48.8207056, lng: 17.7694446 },
    { lat: 48.8206346, lng: 17.7695016 },
    { lat: 48.8205601, lng: 17.7695574 },
    { lat: 48.8204987, lng: 17.7696044 },
    { lat: 48.8204268, lng: 17.7696483 },
    { lat: 48.8203605, lng: 17.7696913 },
    { lat: 48.8202995, lng: 17.7697269 },
    { lat: 48.8202311, lng: 17.7697701 },
    { lat: 48.8201494, lng: 17.7698205 },
    { lat: 48.820118, lng: 17.7698421 },
    { lat: 48.8200289, lng: 17.7698802 },
    { lat: 48.8199475, lng: 17.7699142 },
    { lat: 48.8197956, lng: 17.7699783 },
    { lat: 48.8197231, lng: 17.7700107 },
    { lat: 48.819644, lng: 17.7700492 },
    { lat: 48.81957, lng: 17.7700916 },
    { lat: 48.8194949, lng: 17.7701213 },
    { lat: 48.819431, lng: 17.7701457 },
    { lat: 48.8193638, lng: 17.7701727 },
    { lat: 48.8192855, lng: 17.7702041 },
    { lat: 48.8192183, lng: 17.770231 },
    { lat: 48.8191467, lng: 17.7702599 },
    { lat: 48.8190088, lng: 17.7703125 },
    { lat: 48.8189403, lng: 17.7703376 },
    { lat: 48.818894, lng: 17.7703561 },
    { lat: 48.8188456, lng: 17.770377 },
    { lat: 48.8187808, lng: 17.7704246 },
    { lat: 48.8187204, lng: 17.7704698 },
    { lat: 48.8186287, lng: 17.7705398 },
    { lat: 48.8185978, lng: 17.770569 },
    { lat: 48.8185497, lng: 17.7706143 },
    { lat: 48.8184844, lng: 17.7706734 },
    { lat: 48.818396, lng: 17.7707592 },
    { lat: 48.8183171, lng: 17.7708341 },
    { lat: 48.8182591, lng: 17.7708841 },
    { lat: 48.818146, lng: 17.7709931 },
    { lat: 48.818083, lng: 17.7710519 },
    { lat: 48.8180479, lng: 17.7710871 },
    { lat: 48.8179938, lng: 17.7711252 },
    { lat: 48.8179386, lng: 17.7711632 },
    { lat: 48.817835, lng: 17.7712415 },
    { lat: 48.8178005, lng: 17.771268 },
    { lat: 48.817765, lng: 17.7712966 },
    { lat: 48.8176452, lng: 17.771368 },
    { lat: 48.8175756, lng: 17.7714124 },
    { lat: 48.8175108, lng: 17.7714612 },
    { lat: 48.8174807, lng: 17.7715219 },
    { lat: 48.8174412, lng: 17.7716143 },
    { lat: 48.8174008, lng: 17.7717104 },
    { lat: 48.8173577, lng: 17.771799 },
    { lat: 48.8172655, lng: 17.7720077 },
    { lat: 48.8172166, lng: 17.7721133 },
    { lat: 48.8171502, lng: 17.7722641 },
    { lat: 48.8170966, lng: 17.7723835 },
    { lat: 48.8170467, lng: 17.7724726 },
    { lat: 48.8169796, lng: 17.7725776 },
    { lat: 48.8169266, lng: 17.7726692 },
    { lat: 48.8168734, lng: 17.7727593 },
    { lat: 48.816817, lng: 17.7728526 },
    { lat: 48.8167655, lng: 17.7729326 },
    { lat: 48.8166518, lng: 17.7731246 },
    { lat: 48.8166078, lng: 17.7731983 },
    { lat: 48.8165536, lng: 17.7732703 },
    { lat: 48.8165034, lng: 17.7733381 },
    { lat: 48.8164389, lng: 17.7734267 },
    { lat: 48.8163662, lng: 17.7735291 },
    { lat: 48.8163173, lng: 17.7735988 },
    { lat: 48.8162484, lng: 17.7736909 },
    { lat: 48.8161852, lng: 17.7737823 },
    { lat: 48.8161748, lng: 17.773723 },
    { lat: 48.8161121, lng: 17.7733994 },
    { lat: 48.8160838, lng: 17.7732495 },
    { lat: 48.8160615, lng: 17.7731247 },
    { lat: 48.816037, lng: 17.7730035 },
    { lat: 48.8160087, lng: 17.7728715 },
    { lat: 48.8159794, lng: 17.7727252 },
    { lat: 48.8159371, lng: 17.7725328 },
    { lat: 48.8159058, lng: 17.7723898 },
    { lat: 48.815884, lng: 17.7722731 },
    { lat: 48.8158574, lng: 17.7721509 },
    { lat: 48.8158283, lng: 17.7720258 },
    { lat: 48.815748, lng: 17.7720426 },
    { lat: 48.8156632, lng: 17.7720585 },
    { lat: 48.8156029, lng: 17.7720691 },
    { lat: 48.8155338, lng: 17.7720838 },
    { lat: 48.8154734, lng: 17.7720928 },
    { lat: 48.8154441, lng: 17.7720591 },
    { lat: 48.8153983, lng: 17.7720105 },
    { lat: 48.815339, lng: 17.7719452 },
    { lat: 48.8152767, lng: 17.7718855 },
    { lat: 48.8152564, lng: 17.7718492 },
    { lat: 48.81518, lng: 17.7717261 },
    { lat: 48.8151048, lng: 17.7716043 },
    { lat: 48.8150325, lng: 17.7714921 },
    { lat: 48.8149626, lng: 17.771384 },
    { lat: 48.8149001, lng: 17.7712849 },
    { lat: 48.8148512, lng: 17.7712055 },
    { lat: 48.8147686, lng: 17.7710731 },
    { lat: 48.8147158, lng: 17.7709843 },
    { lat: 48.8146807, lng: 17.7709828 },
    { lat: 48.8146196, lng: 17.7709802 },
    { lat: 48.8145414, lng: 17.7709772 },
    { lat: 48.814444, lng: 17.7709715 },
    { lat: 48.8143409, lng: 17.7709656 },
    { lat: 48.8142626, lng: 17.770959 },
    { lat: 48.8141856, lng: 17.7709556 },
    { lat: 48.8141132, lng: 17.7709534 },
    { lat: 48.8140621, lng: 17.7710034 },
    { lat: 48.8139926, lng: 17.7710662 },
    { lat: 48.8139658, lng: 17.7709981 },
    { lat: 48.8139277, lng: 17.7709103 },
    { lat: 48.8138866, lng: 17.7708113 },
    { lat: 48.8138419, lng: 17.770706 },
    { lat: 48.8137968, lng: 17.7705982 },
    { lat: 48.8137817, lng: 17.7705346 },
    { lat: 48.8137886, lng: 17.7704452 },
    { lat: 48.8138031, lng: 17.7703118 },
    { lat: 48.8137577, lng: 17.7700315 },
    { lat: 48.8137044, lng: 17.7699163 },
    { lat: 48.8136481, lng: 17.7697918 },
    { lat: 48.8135778, lng: 17.7696383 },
    { lat: 48.8135138, lng: 17.7694968 },
    { lat: 48.8134555, lng: 17.7693725 },
    { lat: 48.8133919, lng: 17.7692392 },
    { lat: 48.8133426, lng: 17.7691333 },
    { lat: 48.8132979, lng: 17.7690301 },
    { lat: 48.8132503, lng: 17.7689155 },
    { lat: 48.8132022, lng: 17.7687948 },
    { lat: 48.8131593, lng: 17.7686845 },
    { lat: 48.8131181, lng: 17.7685811 },
    { lat: 48.8130709, lng: 17.7684582 },
    { lat: 48.8130235, lng: 17.7683456 },
    { lat: 48.8129908, lng: 17.7682552 },
    { lat: 48.8129389, lng: 17.7681253 },
    { lat: 48.8128944, lng: 17.7680073 },
    { lat: 48.8128493, lng: 17.7678991 },
    { lat: 48.812806, lng: 17.7677807 },
    { lat: 48.8127754, lng: 17.7676867 },
    { lat: 48.8127419, lng: 17.7676001 },
    { lat: 48.812698, lng: 17.7674916 },
    { lat: 48.8126548, lng: 17.7673588 },
    { lat: 48.8118071, lng: 17.7683564 },
    { lat: 48.8116063, lng: 17.768599 },
    { lat: 48.8111344, lng: 17.7692268 },
    { lat: 48.8111146, lng: 17.7692539 },
    { lat: 48.8108497, lng: 17.7695368 },
    { lat: 48.8104839, lng: 17.7698688 },
    { lat: 48.8101149, lng: 17.7702044 },
    { lat: 48.8096517, lng: 17.7706244 },
    { lat: 48.8093055, lng: 17.7709798 },
    { lat: 48.80908, lng: 17.7712981 },
    { lat: 48.8090954, lng: 17.771366 },
    { lat: 48.8091113, lng: 17.7714433 },
    { lat: 48.809115, lng: 17.7714641 },
    { lat: 48.8067058, lng: 17.7742165 },
    { lat: 48.8065582, lng: 17.7744068 },
    { lat: 48.8064234, lng: 17.7746026 },
    { lat: 48.8062198, lng: 17.7748537 },
    { lat: 48.8053691, lng: 17.7756937 },
    { lat: 48.8050038, lng: 17.7760894 },
    { lat: 48.8044385, lng: 17.7766301 },
    { lat: 48.8041784, lng: 17.7769365 },
    { lat: 48.8032132, lng: 17.7781399 },
    { lat: 48.8030431, lng: 17.778329 },
    { lat: 48.8025125, lng: 17.7790121 },
    { lat: 48.8016694, lng: 17.7800709 },
    { lat: 48.8010338, lng: 17.7809388 },
    { lat: 48.8009587, lng: 17.7810211 },
    { lat: 48.8003411, lng: 17.7818159 },
    { lat: 48.799551, lng: 17.7828151 },
    { lat: 48.7987295, lng: 17.7839675 },
    { lat: 48.7983879, lng: 17.7844558 },
    { lat: 48.7976119, lng: 17.7856502 },
    { lat: 48.7973956, lng: 17.7859539 },
    { lat: 48.7972989, lng: 17.7860709 },
    { lat: 48.796058, lng: 17.787253 },
    { lat: 48.796036, lng: 17.7872809 },
    { lat: 48.7953943, lng: 17.7879021 },
    { lat: 48.7947745, lng: 17.7885679 },
    { lat: 48.794482, lng: 17.7888263 },
    { lat: 48.7936664, lng: 17.7897225 },
    { lat: 48.7931341, lng: 17.7903258 },
    { lat: 48.7928953, lng: 17.7906483 },
    { lat: 48.7926496, lng: 17.7909692 },
    { lat: 48.7925184, lng: 17.7910384 },
    { lat: 48.792454, lng: 17.7910573 },
    { lat: 48.7920452, lng: 17.7903877 },
    { lat: 48.7918025, lng: 17.7898712 },
    { lat: 48.7915728, lng: 17.7893073 },
    { lat: 48.7915204, lng: 17.7890971 },
    { lat: 48.7915079, lng: 17.7888923 },
    { lat: 48.7914514, lng: 17.7889659 },
    { lat: 48.7913249, lng: 17.7891309 },
    { lat: 48.7912422, lng: 17.7892365 },
    { lat: 48.7911586, lng: 17.7893464 },
    { lat: 48.7910761, lng: 17.7894557 },
    { lat: 48.7909934, lng: 17.7895625 },
    { lat: 48.7909129, lng: 17.7896679 },
    { lat: 48.7908625, lng: 17.7897296 },
    { lat: 48.7908114, lng: 17.7897986 },
    { lat: 48.7907405, lng: 17.7898948 },
    { lat: 48.7905752, lng: 17.7901093 },
    { lat: 48.7904647, lng: 17.7902606 },
    { lat: 48.7904179, lng: 17.7903243 },
    { lat: 48.790258, lng: 17.7905351 },
    { lat: 48.7901099, lng: 17.7907359 },
    { lat: 48.7901016, lng: 17.7907469 },
    { lat: 48.7900995, lng: 17.7907497 },
    { lat: 48.7901222, lng: 17.7907745 },
    { lat: 48.7906361, lng: 17.7915308 },
    { lat: 48.7909897, lng: 17.7920859 },
    { lat: 48.7910148, lng: 17.7921252 },
    { lat: 48.7913764, lng: 17.7917537 },
    { lat: 48.7916975, lng: 17.791424 },
    { lat: 48.7920301, lng: 17.7919864 },
    { lat: 48.7922272, lng: 17.7916808 },
    { lat: 48.7922505, lng: 17.7916362 },
    { lat: 48.7925064, lng: 17.7911441 },
    { lat: 48.7925874, lng: 17.7911013 },
    { lat: 48.7926915, lng: 17.7910468 },
    { lat: 48.7931322, lng: 17.791141 },
    { lat: 48.7933134, lng: 17.7914979 },
    { lat: 48.7934616, lng: 17.7916854 },
    { lat: 48.7936883, lng: 17.7923091 },
    { lat: 48.7941596, lng: 17.7930154 },
    { lat: 48.7942773, lng: 17.7932591 },
    { lat: 48.7945852, lng: 17.7937693 },
    { lat: 48.7947468, lng: 17.7941196 },
    { lat: 48.7950016, lng: 17.7943517 },
    { lat: 48.795162, lng: 17.7947389 },
    { lat: 48.7952925, lng: 17.7951374 },
    { lat: 48.7952953, lng: 17.7951469 },
    { lat: 48.7955757, lng: 17.7957055 },
    { lat: 48.7958001, lng: 17.7961039 },
    { lat: 48.7960672, lng: 17.7965756 },
    { lat: 48.7961344, lng: 17.7966965 },
    { lat: 48.7962652, lng: 17.7969327 },
    { lat: 48.7963248, lng: 17.7970402 },
    { lat: 48.7969984, lng: 17.7981725 },
    { lat: 48.7977816, lng: 17.7995281 },
    { lat: 48.7978992, lng: 17.7997315 },
    { lat: 48.7979092, lng: 17.7997489 },
    { lat: 48.7984687, lng: 17.8006965 },
    { lat: 48.7991163, lng: 17.8017193 },
    { lat: 48.7997644, lng: 17.8024157 },
    { lat: 48.8000828, lng: 17.8028193 },
    { lat: 48.8002022, lng: 17.8029981 },
    { lat: 48.8003367, lng: 17.803241 },
    { lat: 48.8005737, lng: 17.803737 },
    { lat: 48.8012404, lng: 17.805295 },
    { lat: 48.8014429, lng: 17.805764 },
    { lat: 48.8015829, lng: 17.8060028 },
    { lat: 48.8017148, lng: 17.8060919 },
    { lat: 48.8018761, lng: 17.8061784 },
    { lat: 48.8019419, lng: 17.8062385 },
    { lat: 48.8020522, lng: 17.8064172 },
    { lat: 48.8022222, lng: 17.8067425 },
    { lat: 48.8029649, lng: 17.8082875 },
    { lat: 48.8037939, lng: 17.8100825 },
    { lat: 48.8040837, lng: 17.8107422 },
    { lat: 48.8041519, lng: 17.8108974 },
    { lat: 48.8042847, lng: 17.8112967 },
    { lat: 48.804262, lng: 17.8113883 },
    { lat: 48.804275, lng: 17.8114896 },
    { lat: 48.8047464, lng: 17.8125345 },
    { lat: 48.8050262, lng: 17.8131969 },
    { lat: 48.8052995, lng: 17.8139187 },
    { lat: 48.8057076, lng: 17.8132901 },
    { lat: 48.8062327, lng: 17.8124856 },
    { lat: 48.8062389, lng: 17.8124949 },
    { lat: 48.8063572, lng: 17.8126728 },
    { lat: 48.8064778, lng: 17.8128551 },
    { lat: 48.8064943, lng: 17.8128284 },
    { lat: 48.8065723, lng: 17.8127001 },
    { lat: 48.8069138, lng: 17.8131498 },
    { lat: 48.806971, lng: 17.8132172 },
    { lat: 48.8071278, lng: 17.8133993 },
    { lat: 48.8073796, lng: 17.8136926 },
    { lat: 48.8073954, lng: 17.8137114 },
    { lat: 48.8074548, lng: 17.8137779 },
    { lat: 48.8074695, lng: 17.8137951 },
    { lat: 48.8075198, lng: 17.8135443 },
    { lat: 48.8075913, lng: 17.8133086 },
    { lat: 48.8075949, lng: 17.8132948 },
    { lat: 48.8076108, lng: 17.8133126 },
    { lat: 48.8080826, lng: 17.8138427 },
    { lat: 48.807948, lng: 17.814399 },
    { lat: 48.8080749, lng: 17.8143853 },
    { lat: 48.8081456, lng: 17.8143776 },
    { lat: 48.8082758, lng: 17.8143632 },
    { lat: 48.8085002, lng: 17.8143384 },
    { lat: 48.8085921, lng: 17.8143252 },
    { lat: 48.8086783, lng: 17.8143126 },
    { lat: 48.8087702, lng: 17.8142995 },
    { lat: 48.808806, lng: 17.814294 },
    { lat: 48.808863, lng: 17.8142831 },
    { lat: 48.8090451, lng: 17.8142479 },
    { lat: 48.8092651, lng: 17.8142053 },
    { lat: 48.80935, lng: 17.8141892 },
    { lat: 48.809351, lng: 17.814189 },
    { lat: 48.8095481, lng: 17.8141406 },
    { lat: 48.8096895, lng: 17.8141059 },
    { lat: 48.809735, lng: 17.8142788 },
    { lat: 48.8097588, lng: 17.814373 },
    { lat: 48.8098075, lng: 17.8143546 },
    { lat: 48.8101279, lng: 17.8142357 },
    { lat: 48.8101887, lng: 17.8142125 },
    { lat: 48.8101953, lng: 17.8142103 },
    { lat: 48.8106016, lng: 17.814055 },
    { lat: 48.8108211, lng: 17.8148382 },
    { lat: 48.8104908, lng: 17.8150379 },
    { lat: 48.8104926, lng: 17.8150475 },
    { lat: 48.8105013, lng: 17.8151002 },
    { lat: 48.810071, lng: 17.8153247 },
    { lat: 48.8093735, lng: 17.8156371 },
    { lat: 48.8094134, lng: 17.8156609 },
    { lat: 48.8094422, lng: 17.8156702 },
    { lat: 48.809531, lng: 17.8156985 },
    { lat: 48.8096035, lng: 17.8157218 },
    { lat: 48.8096174, lng: 17.8157258 },
    { lat: 48.809647, lng: 17.8157315 },
    { lat: 48.8098289, lng: 17.8157673 },
    { lat: 48.8098404, lng: 17.8157696 },
    { lat: 48.8099201, lng: 17.8157992 },
    { lat: 48.8099455, lng: 17.8158083 },
    { lat: 48.809969, lng: 17.8158235 },
    { lat: 48.8100656, lng: 17.8158867 },
    { lat: 48.8100991, lng: 17.8159178 },
    { lat: 48.8101771, lng: 17.8159935 },
    { lat: 48.8104974, lng: 17.8162991 },
    { lat: 48.8105868, lng: 17.8163752 },
    { lat: 48.8107986, lng: 17.8165527 },
    { lat: 48.8109666, lng: 17.8166935 },
    { lat: 48.8110933, lng: 17.8167149 },
    { lat: 48.8112455, lng: 17.8167089 },
    { lat: 48.8113402, lng: 17.8167052 },
    { lat: 48.8114095, lng: 17.8166925 },
    { lat: 48.8115514, lng: 17.8166668 },
    { lat: 48.8116017, lng: 17.8166577 },
    { lat: 48.8117145, lng: 17.8166373 },
    { lat: 48.811728, lng: 17.8166345 },
    { lat: 48.8117626, lng: 17.8166279 },
    { lat: 48.8119727, lng: 17.8165893 },
    { lat: 48.812184, lng: 17.8165719 },
    { lat: 48.8124674, lng: 17.8165478 },
    { lat: 48.8125156, lng: 17.8165436 },
    { lat: 48.8125835, lng: 17.8165431 },
    { lat: 48.812841, lng: 17.8165411 },
    { lat: 48.8129234, lng: 17.8165407 },
    { lat: 48.8129813, lng: 17.8165434 },
    { lat: 48.8130344, lng: 17.8165462 },
    { lat: 48.8131262, lng: 17.8165504 },
    { lat: 48.8131965, lng: 17.8165541 },
    { lat: 48.8134765, lng: 17.8165675 },
    { lat: 48.8134769, lng: 17.8166121 },
    { lat: 48.8134996, lng: 17.8175079 },
    { lat: 48.8135445, lng: 17.8190645 },
    { lat: 48.8135618, lng: 17.8190502 },
    { lat: 48.8139806, lng: 17.8187095 },
    { lat: 48.8143209, lng: 17.8183958 },
    { lat: 48.8144593, lng: 17.8183306 },
    { lat: 48.8146598, lng: 17.8182639 },
    { lat: 48.8146698, lng: 17.8182607 },
    { lat: 48.815075, lng: 17.818162 },
    { lat: 48.8151038, lng: 17.8182631 },
    { lat: 48.8152169, lng: 17.8186731 },
    { lat: 48.8153336, lng: 17.8192204 },
    { lat: 48.8153933, lng: 17.8196626 },
    { lat: 48.8154224, lng: 17.8196773 },
    { lat: 48.815547, lng: 17.8197388 },
    { lat: 48.8156974, lng: 17.8198127 },
    { lat: 48.8157358, lng: 17.8198318 },
    { lat: 48.8158282, lng: 17.8198651 },
    { lat: 48.8160155, lng: 17.8199339 },
    { lat: 48.8164152, lng: 17.8200798 },
    { lat: 48.8164486, lng: 17.8201435 },
    { lat: 48.8165078, lng: 17.8200601 },
    { lat: 48.816606, lng: 17.8200781 },
    { lat: 48.8173025, lng: 17.8204674 },
    { lat: 48.817594, lng: 17.8206012 },
    { lat: 48.8178788, lng: 17.820664 },
    { lat: 48.8180121, lng: 17.8206648 },
    { lat: 48.8180321, lng: 17.8208279 },
    { lat: 48.8180511, lng: 17.8209721 },
    { lat: 48.8180687, lng: 17.8211129 },
    { lat: 48.8181174, lng: 17.8212459 },
    { lat: 48.8181701, lng: 17.821389 },
    { lat: 48.818216, lng: 17.8215121 },
    { lat: 48.8188168, lng: 17.8212185 },
    { lat: 48.8197005, lng: 17.8207878 },
    { lat: 48.8207461, lng: 17.8202524 },
    { lat: 48.8207812, lng: 17.8202355 },
    { lat: 48.8207527, lng: 17.820139 },
    { lat: 48.8215614, lng: 17.8197183 },
    { lat: 48.8219682, lng: 17.8194625 },
    { lat: 48.8218342, lng: 17.8202304 },
    { lat: 48.8236287, lng: 17.820019 },
    { lat: 48.8243191, lng: 17.8197711 },
    { lat: 48.8247138, lng: 17.8193703 },
    { lat: 48.8247378, lng: 17.81934 },
    { lat: 48.8248906, lng: 17.8190671 },
    { lat: 48.8251935, lng: 17.8186465 },
    { lat: 48.8253025, lng: 17.8184898 },
    { lat: 48.8258342, lng: 17.817596 },
    { lat: 48.8259768, lng: 17.8173422 },
    { lat: 48.8259679, lng: 17.8173264 },
    { lat: 48.8262763, lng: 17.8165868 },
    { lat: 48.826846, lng: 17.8158734 },
    { lat: 48.827082, lng: 17.8153913 },
    { lat: 48.827562, lng: 17.8143541 },
    { lat: 48.8280389, lng: 17.8134516 },
    { lat: 48.8280836, lng: 17.8132966 },
    { lat: 48.8284045, lng: 17.8119998 },
    { lat: 48.8288697, lng: 17.8110539 },
    { lat: 48.8291027, lng: 17.810803 },
    { lat: 48.8292004, lng: 17.8107561 },
    { lat: 48.829463, lng: 17.8106215 },
    { lat: 48.8305151, lng: 17.8099322 },
    { lat: 48.8310521, lng: 17.8096479 },
    { lat: 48.8314574, lng: 17.8094819 },
    { lat: 48.8323495, lng: 17.8089104 },
    { lat: 48.8327251, lng: 17.8087754 },
    { lat: 48.8335886, lng: 17.8086609 },
    { lat: 48.8343119, lng: 17.807876 },
    { lat: 48.8345544, lng: 17.8075749 },
    { lat: 48.8349291, lng: 17.8070365 },
    { lat: 48.8352247, lng: 17.8067179 },
    { lat: 48.836087, lng: 17.8060845 },
    { lat: 48.8364735, lng: 17.8057013 },
    { lat: 48.8366412, lng: 17.8055813 },
    { lat: 48.8367619, lng: 17.8055983 },
    { lat: 48.8371697, lng: 17.8056041 },
    { lat: 48.8378909, lng: 17.8051545 },
    { lat: 48.8382093, lng: 17.8049699 },
    { lat: 48.8387506, lng: 17.8045894 },
    { lat: 48.8395013, lng: 17.8043111 },
    { lat: 48.8405952, lng: 17.8043096 },
    { lat: 48.840964, lng: 17.803932 },
    { lat: 48.8411499, lng: 17.8037987 },
    { lat: 48.841305, lng: 17.8037484 },
    { lat: 48.841337, lng: 17.8037374 },
    { lat: 48.8416534, lng: 17.8036309 },
    { lat: 48.8419378, lng: 17.8034817 },
    { lat: 48.8422505, lng: 17.8030737 },
    { lat: 48.8432179, lng: 17.8020329 },
    { lat: 48.8434662, lng: 17.8019007 },
    { lat: 48.8437013, lng: 17.8019992 },
    { lat: 48.8438403, lng: 17.802002 },
    { lat: 48.8442133, lng: 17.8018611 },
    { lat: 48.8450434, lng: 17.8012345 },
    { lat: 48.8457868, lng: 17.8007256 },
    { lat: 48.8466063, lng: 17.7999638 },
    { lat: 48.8467721, lng: 17.7998127 },
    { lat: 48.8471128, lng: 17.799624 },
    { lat: 48.8472855, lng: 17.7996422 },
    { lat: 48.8475107, lng: 17.7995949 },
    { lat: 48.8480188, lng: 17.7992445 },
    { lat: 48.8480861, lng: 17.7991823 },
    { lat: 48.8482217, lng: 17.7990542 },
    { lat: 48.8483924, lng: 17.7988717 },
    { lat: 48.8487577, lng: 17.798476 },
    { lat: 48.8493787, lng: 17.7980313 },
    { lat: 48.8497172, lng: 17.7978988 },
    { lat: 48.8499303, lng: 17.7978947 },
    { lat: 48.8500812, lng: 17.7977415 },
    { lat: 48.8501576, lng: 17.7976225 },
    { lat: 48.8502253, lng: 17.7974733 },
    { lat: 48.8502614, lng: 17.7973437 },
    { lat: 48.8502542, lng: 17.7968565 },
    { lat: 48.8502599, lng: 17.7966888 },
  ],
  DolnéSrnie: [
    { lat: 48.7840768, lng: 17.8258707 },
    { lat: 48.7839274, lng: 17.8266185 },
    { lat: 48.7839692, lng: 17.82684 },
    { lat: 48.7844688, lng: 17.8283016 },
    { lat: 48.7846969, lng: 17.8286927 },
    { lat: 48.785034, lng: 17.8293006 },
    { lat: 48.7855411, lng: 17.8301222 },
    { lat: 48.7861005, lng: 17.8308752 },
    { lat: 48.7856813, lng: 17.8315028 },
    { lat: 48.7860884, lng: 17.8320544 },
    { lat: 48.7859547, lng: 17.8322506 },
    { lat: 48.7860502, lng: 17.8323911 },
    { lat: 48.7862527, lng: 17.8327896 },
    { lat: 48.786355, lng: 17.8331162 },
    { lat: 48.7864677, lng: 17.8336152 },
    { lat: 48.7865096, lng: 17.8339545 },
    { lat: 48.7865259, lng: 17.8344648 },
    { lat: 48.7865636, lng: 17.834603 },
    { lat: 48.7868759, lng: 17.8351918 },
    { lat: 48.7870244, lng: 17.8356871 },
    { lat: 48.7870456, lng: 17.8359229 },
    { lat: 48.7870277, lng: 17.8360945 },
    { lat: 48.7869405, lng: 17.8362389 },
    { lat: 48.7868349, lng: 17.8362652 },
    { lat: 48.7870954, lng: 17.8368018 },
    { lat: 48.7875384, lng: 17.8377042 },
    { lat: 48.7877915, lng: 17.8383217 },
    { lat: 48.7874845, lng: 17.8387325 },
    { lat: 48.787894, lng: 17.8395478 },
    { lat: 48.7881654, lng: 17.8401144 },
    { lat: 48.7885471, lng: 17.8396331 },
    { lat: 48.7885627, lng: 17.8399812 },
    { lat: 48.7887071, lng: 17.8403178 },
    { lat: 48.7887785, lng: 17.8405441 },
    { lat: 48.7887464, lng: 17.8406472 },
    { lat: 48.7887638, lng: 17.841253 },
    { lat: 48.7889129, lng: 17.8411797 },
    { lat: 48.789592, lng: 17.8412645 },
    { lat: 48.7898879, lng: 17.841454 },
    { lat: 48.7903947, lng: 17.8417315 },
    { lat: 48.7904243, lng: 17.841773 },
    { lat: 48.7904631, lng: 17.8418919 },
    { lat: 48.7906864, lng: 17.8430069 },
    { lat: 48.7907858, lng: 17.8433232 },
    { lat: 48.7909824, lng: 17.843197 },
    { lat: 48.7911609, lng: 17.8436847 },
    { lat: 48.791648, lng: 17.844737 },
    { lat: 48.7910989, lng: 17.8448966 },
    { lat: 48.7913596, lng: 17.8454558 },
    { lat: 48.791526, lng: 17.8462268 },
    { lat: 48.7915479, lng: 17.8463059 },
    { lat: 48.7917648, lng: 17.8462685 },
    { lat: 48.7918027, lng: 17.8462423 },
    { lat: 48.7918441, lng: 17.846214 },
    { lat: 48.7919178, lng: 17.8461632 },
    { lat: 48.7919557, lng: 17.8461373 },
    { lat: 48.7919981, lng: 17.8461084 },
    { lat: 48.7920328, lng: 17.8460848 },
    { lat: 48.7920696, lng: 17.8460591 },
    { lat: 48.7920719, lng: 17.846058 },
    { lat: 48.7921082, lng: 17.8460419 },
    { lat: 48.7923304, lng: 17.8459413 },
    { lat: 48.7926124, lng: 17.8457658 },
    { lat: 48.7928643, lng: 17.845672 },
    { lat: 48.7931357, lng: 17.8454696 },
    { lat: 48.793329, lng: 17.8454344 },
    { lat: 48.7938036, lng: 17.8452664 },
    { lat: 48.7944081, lng: 17.8448567 },
    { lat: 48.7943619, lng: 17.8445792 },
    { lat: 48.7943594, lng: 17.844388 },
    { lat: 48.7942708, lng: 17.8440643 },
    { lat: 48.7939156, lng: 17.8436642 },
    { lat: 48.7933088, lng: 17.843608 },
    { lat: 48.7931765, lng: 17.8431403 },
    { lat: 48.7930013, lng: 17.842877 },
    { lat: 48.7929326, lng: 17.8423769 },
    { lat: 48.792856, lng: 17.8418214 },
    { lat: 48.7928717, lng: 17.84141 },
    { lat: 48.7928723, lng: 17.8414001 },
    { lat: 48.792876, lng: 17.8413124 },
    { lat: 48.7928795, lng: 17.8412213 },
    { lat: 48.7928824, lng: 17.8411948 },
    { lat: 48.7928943, lng: 17.8410931 },
    { lat: 48.7929543, lng: 17.8405717 },
    { lat: 48.793051, lng: 17.8404527 },
    { lat: 48.7930584, lng: 17.8404436 },
    { lat: 48.7930604, lng: 17.8404402 },
    { lat: 48.7930868, lng: 17.840408 },
    { lat: 48.7931162, lng: 17.8403709 },
    { lat: 48.7931477, lng: 17.8403324 },
    { lat: 48.7931761, lng: 17.8402973 },
    { lat: 48.7932045, lng: 17.8402622 },
    { lat: 48.7932707, lng: 17.8401798 },
    { lat: 48.7933774, lng: 17.8400572 },
    { lat: 48.7934173, lng: 17.8400261 },
    { lat: 48.7934334, lng: 17.8400127 },
    { lat: 48.7934442, lng: 17.8400044 },
    { lat: 48.7934722, lng: 17.8399821 },
    { lat: 48.7935036, lng: 17.8399572 },
    { lat: 48.7935392, lng: 17.83993 },
    { lat: 48.7935661, lng: 17.8399088 },
    { lat: 48.7935973, lng: 17.839884 },
    { lat: 48.793661, lng: 17.8398336 },
    { lat: 48.7936803, lng: 17.8398365 },
    { lat: 48.793702, lng: 17.839839 },
    { lat: 48.7937293, lng: 17.8398426 },
    { lat: 48.793825, lng: 17.8398549 },
    { lat: 48.7938261, lng: 17.8398548 },
    { lat: 48.7938352, lng: 17.839856 },
    { lat: 48.79394, lng: 17.8398692 },
    { lat: 48.7939718, lng: 17.8398734 },
    { lat: 48.7939741, lng: 17.8398736 },
    { lat: 48.7940858, lng: 17.839888 },
    { lat: 48.7941814, lng: 17.8399 },
    { lat: 48.7942964, lng: 17.8399148 },
    { lat: 48.7945105, lng: 17.8399612 },
    { lat: 48.7946327, lng: 17.8401882 },
    { lat: 48.7946379, lng: 17.8401988 },
    { lat: 48.7947169, lng: 17.8403459 },
    { lat: 48.7947869, lng: 17.8404577 },
    { lat: 48.7947944, lng: 17.8404694 },
    { lat: 48.7949381, lng: 17.8406961 },
    { lat: 48.7954067, lng: 17.8413071 },
    { lat: 48.7954759, lng: 17.841425 },
    { lat: 48.7958687, lng: 17.8420878 },
    { lat: 48.7959543, lng: 17.8422317 },
    { lat: 48.7967991, lng: 17.8413688 },
    { lat: 48.7976, lng: 17.8411777 },
    { lat: 48.7978747, lng: 17.8411233 },
    { lat: 48.7979417, lng: 17.8411102 },
    { lat: 48.7986885, lng: 17.8410177 },
    { lat: 48.7991122, lng: 17.8412407 },
    { lat: 48.7993736, lng: 17.8413763 },
    { lat: 48.7995019, lng: 17.8414423 },
    { lat: 48.7995808, lng: 17.8414963 },
    { lat: 48.7996634, lng: 17.8415523 },
    { lat: 48.7997293, lng: 17.8415971 },
    { lat: 48.7998908, lng: 17.8417073 },
    { lat: 48.7999628, lng: 17.84174 },
    { lat: 48.800208, lng: 17.8418535 },
    { lat: 48.800427, lng: 17.841868 },
    { lat: 48.800595, lng: 17.8418794 },
    { lat: 48.8006523, lng: 17.8418749 },
    { lat: 48.8007757, lng: 17.8417285 },
    { lat: 48.8009962, lng: 17.8417317 },
    { lat: 48.8011367, lng: 17.8412526 },
    { lat: 48.8013912, lng: 17.8414628 },
    { lat: 48.8015886, lng: 17.8415143 },
    { lat: 48.801949, lng: 17.8414944 },
    { lat: 48.8021681, lng: 17.8414348 },
    { lat: 48.8022414, lng: 17.8414148 },
    { lat: 48.8027201, lng: 17.8411842 },
    { lat: 48.8027426, lng: 17.8411635 },
    { lat: 48.8030013, lng: 17.8409197 },
    { lat: 48.8036608, lng: 17.840392 },
    { lat: 48.8037535, lng: 17.8403177 },
    { lat: 48.8035231, lng: 17.8389227 },
    { lat: 48.8034234, lng: 17.8380217 },
    { lat: 48.8040027, lng: 17.8373259 },
    { lat: 48.8048169, lng: 17.8362366 },
    { lat: 48.8055269, lng: 17.8354797 },
    { lat: 48.8061173, lng: 17.834723 },
    { lat: 48.8062111, lng: 17.8344439 },
    { lat: 48.8062427, lng: 17.8334355 },
    { lat: 48.8062717, lng: 17.8332085 },
    { lat: 48.806394, lng: 17.8326894 },
    { lat: 48.8066253, lng: 17.8319016 },
    { lat: 48.806973, lng: 17.8311306 },
    { lat: 48.8075517, lng: 17.8303527 },
    { lat: 48.8079096, lng: 17.8300673 },
    { lat: 48.808095, lng: 17.8299199 },
    { lat: 48.8088879, lng: 17.8293013 },
    { lat: 48.8092237, lng: 17.8291176 },
    { lat: 48.8095638, lng: 17.8289314 },
    { lat: 48.810245, lng: 17.8287611 },
    { lat: 48.8105888, lng: 17.8286178 },
    { lat: 48.8110502, lng: 17.8283441 },
    { lat: 48.8112607, lng: 17.8282002 },
    { lat: 48.8112898, lng: 17.8280653 },
    { lat: 48.8112525, lng: 17.8278792 },
    { lat: 48.8110764, lng: 17.827677 },
    { lat: 48.8111165, lng: 17.8275736 },
    { lat: 48.8111566, lng: 17.8274726 },
    { lat: 48.8124692, lng: 17.8259569 },
    { lat: 48.8137631, lng: 17.82445 },
    { lat: 48.8149443, lng: 17.8237498 },
    { lat: 48.8156715, lng: 17.8233319 },
    { lat: 48.8164005, lng: 17.8228506 },
    { lat: 48.8164362, lng: 17.8228243 },
    { lat: 48.816273, lng: 17.8221109 },
    { lat: 48.8162177, lng: 17.8217566 },
    { lat: 48.8162051, lng: 17.82129 },
    { lat: 48.8162038, lng: 17.8212492 },
    { lat: 48.8161925, lng: 17.8208385 },
    { lat: 48.8162094, lng: 17.8205234 },
    { lat: 48.816254, lng: 17.8203644 },
    { lat: 48.8163114, lng: 17.8202667 },
    { lat: 48.8164486, lng: 17.8201435 },
    { lat: 48.8164152, lng: 17.8200798 },
    { lat: 48.8160155, lng: 17.8199339 },
    { lat: 48.8158282, lng: 17.8198651 },
    { lat: 48.8157358, lng: 17.8198318 },
    { lat: 48.8156974, lng: 17.8198127 },
    { lat: 48.815547, lng: 17.8197388 },
    { lat: 48.8154224, lng: 17.8196773 },
    { lat: 48.8153933, lng: 17.8196626 },
    { lat: 48.8153336, lng: 17.8192204 },
    { lat: 48.8152169, lng: 17.8186731 },
    { lat: 48.8151038, lng: 17.8182631 },
    { lat: 48.815075, lng: 17.818162 },
    { lat: 48.8146698, lng: 17.8182607 },
    { lat: 48.8146598, lng: 17.8182639 },
    { lat: 48.8144593, lng: 17.8183306 },
    { lat: 48.8143209, lng: 17.8183958 },
    { lat: 48.8139806, lng: 17.8187095 },
    { lat: 48.8135618, lng: 17.8190502 },
    { lat: 48.8135445, lng: 17.8190645 },
    { lat: 48.8134996, lng: 17.8175079 },
    { lat: 48.8134769, lng: 17.8166121 },
    { lat: 48.8134765, lng: 17.8165675 },
    { lat: 48.8131965, lng: 17.8165541 },
    { lat: 48.8131262, lng: 17.8165504 },
    { lat: 48.8130344, lng: 17.8165462 },
    { lat: 48.8129813, lng: 17.8165434 },
    { lat: 48.8129234, lng: 17.8165407 },
    { lat: 48.812841, lng: 17.8165411 },
    { lat: 48.8125835, lng: 17.8165431 },
    { lat: 48.8125156, lng: 17.8165436 },
    { lat: 48.8124674, lng: 17.8165478 },
    { lat: 48.812184, lng: 17.8165719 },
    { lat: 48.8119727, lng: 17.8165893 },
    { lat: 48.8117626, lng: 17.8166279 },
    { lat: 48.811728, lng: 17.8166345 },
    { lat: 48.8117145, lng: 17.8166373 },
    { lat: 48.8116017, lng: 17.8166577 },
    { lat: 48.8115514, lng: 17.8166668 },
    { lat: 48.8114095, lng: 17.8166925 },
    { lat: 48.8113402, lng: 17.8167052 },
    { lat: 48.8112455, lng: 17.8167089 },
    { lat: 48.8110933, lng: 17.8167149 },
    { lat: 48.8109666, lng: 17.8166935 },
    { lat: 48.8107986, lng: 17.8165527 },
    { lat: 48.8105868, lng: 17.8163752 },
    { lat: 48.8104974, lng: 17.8162991 },
    { lat: 48.8101771, lng: 17.8159935 },
    { lat: 48.8100991, lng: 17.8159178 },
    { lat: 48.8100656, lng: 17.8158867 },
    { lat: 48.809969, lng: 17.8158235 },
    { lat: 48.8099455, lng: 17.8158083 },
    { lat: 48.8099201, lng: 17.8157992 },
    { lat: 48.8098404, lng: 17.8157696 },
    { lat: 48.8098289, lng: 17.8157673 },
    { lat: 48.809647, lng: 17.8157315 },
    { lat: 48.8096174, lng: 17.8157258 },
    { lat: 48.8096035, lng: 17.8157218 },
    { lat: 48.809531, lng: 17.8156985 },
    { lat: 48.8094422, lng: 17.8156702 },
    { lat: 48.8094134, lng: 17.8156609 },
    { lat: 48.8093735, lng: 17.8156371 },
    { lat: 48.810071, lng: 17.8153247 },
    { lat: 48.8105013, lng: 17.8151002 },
    { lat: 48.8104926, lng: 17.8150475 },
    { lat: 48.8104908, lng: 17.8150379 },
    { lat: 48.8108211, lng: 17.8148382 },
    { lat: 48.8106016, lng: 17.814055 },
    { lat: 48.8101953, lng: 17.8142103 },
    { lat: 48.8101887, lng: 17.8142125 },
    { lat: 48.8101279, lng: 17.8142357 },
    { lat: 48.8098075, lng: 17.8143546 },
    { lat: 48.8097588, lng: 17.814373 },
    { lat: 48.809735, lng: 17.8142788 },
    { lat: 48.8096895, lng: 17.8141059 },
    { lat: 48.8095481, lng: 17.8141406 },
    { lat: 48.809351, lng: 17.814189 },
    { lat: 48.80935, lng: 17.8141892 },
    { lat: 48.8092651, lng: 17.8142053 },
    { lat: 48.8090451, lng: 17.8142479 },
    { lat: 48.808863, lng: 17.8142831 },
    { lat: 48.808806, lng: 17.814294 },
    { lat: 48.8087702, lng: 17.8142995 },
    { lat: 48.8086783, lng: 17.8143126 },
    { lat: 48.8085921, lng: 17.8143252 },
    { lat: 48.8085002, lng: 17.8143384 },
    { lat: 48.8082758, lng: 17.8143632 },
    { lat: 48.8081456, lng: 17.8143776 },
    { lat: 48.8080749, lng: 17.8143853 },
    { lat: 48.807948, lng: 17.814399 },
    { lat: 48.8080826, lng: 17.8138427 },
    { lat: 48.8076108, lng: 17.8133126 },
    { lat: 48.8075949, lng: 17.8132948 },
    { lat: 48.8075913, lng: 17.8133086 },
    { lat: 48.8075198, lng: 17.8135443 },
    { lat: 48.8074695, lng: 17.8137951 },
    { lat: 48.8074548, lng: 17.8137779 },
    { lat: 48.8073954, lng: 17.8137114 },
    { lat: 48.8073796, lng: 17.8136926 },
    { lat: 48.8071278, lng: 17.8133993 },
    { lat: 48.806971, lng: 17.8132172 },
    { lat: 48.8069138, lng: 17.8131498 },
    { lat: 48.8065723, lng: 17.8127001 },
    { lat: 48.8064943, lng: 17.8128284 },
    { lat: 48.8064778, lng: 17.8128551 },
    { lat: 48.8063572, lng: 17.8126728 },
    { lat: 48.8062389, lng: 17.8124949 },
    { lat: 48.8062327, lng: 17.8124856 },
    { lat: 48.8057076, lng: 17.8132901 },
    { lat: 48.8052995, lng: 17.8139187 },
    { lat: 48.8050262, lng: 17.8131969 },
    { lat: 48.8047464, lng: 17.8125345 },
    { lat: 48.804275, lng: 17.8114896 },
    { lat: 48.804262, lng: 17.8113883 },
    { lat: 48.8042847, lng: 17.8112967 },
    { lat: 48.8041519, lng: 17.8108974 },
    { lat: 48.8040837, lng: 17.8107422 },
    { lat: 48.8037939, lng: 17.8100825 },
    { lat: 48.8029649, lng: 17.8082875 },
    { lat: 48.8022222, lng: 17.8067425 },
    { lat: 48.8020522, lng: 17.8064172 },
    { lat: 48.8019419, lng: 17.8062385 },
    { lat: 48.8018761, lng: 17.8061784 },
    { lat: 48.8017148, lng: 17.8060919 },
    { lat: 48.8015829, lng: 17.8060028 },
    { lat: 48.8014429, lng: 17.805764 },
    { lat: 48.8012404, lng: 17.805295 },
    { lat: 48.8005737, lng: 17.803737 },
    { lat: 48.8003367, lng: 17.803241 },
    { lat: 48.8002022, lng: 17.8029981 },
    { lat: 48.8000828, lng: 17.8028193 },
    { lat: 48.7997644, lng: 17.8024157 },
    { lat: 48.7991163, lng: 17.8017193 },
    { lat: 48.7984687, lng: 17.8006965 },
    { lat: 48.7979092, lng: 17.7997489 },
    { lat: 48.7978992, lng: 17.7997315 },
    { lat: 48.7977816, lng: 17.7995281 },
    { lat: 48.7969984, lng: 17.7981725 },
    { lat: 48.7963248, lng: 17.7970402 },
    { lat: 48.7962652, lng: 17.7969327 },
    { lat: 48.7961344, lng: 17.7966965 },
    { lat: 48.7960672, lng: 17.7965756 },
    { lat: 48.7958001, lng: 17.7961039 },
    { lat: 48.7955757, lng: 17.7957055 },
    { lat: 48.7952953, lng: 17.7951469 },
    { lat: 48.7952925, lng: 17.7951374 },
    { lat: 48.795162, lng: 17.7947389 },
    { lat: 48.7950016, lng: 17.7943517 },
    { lat: 48.7947468, lng: 17.7941196 },
    { lat: 48.7945852, lng: 17.7937693 },
    { lat: 48.7942773, lng: 17.7932591 },
    { lat: 48.7941596, lng: 17.7930154 },
    { lat: 48.7936883, lng: 17.7923091 },
    { lat: 48.7934616, lng: 17.7916854 },
    { lat: 48.7933134, lng: 17.7914979 },
    { lat: 48.7931322, lng: 17.791141 },
    { lat: 48.7926915, lng: 17.7910468 },
    { lat: 48.7925874, lng: 17.7911013 },
    { lat: 48.7925064, lng: 17.7911441 },
    { lat: 48.7922505, lng: 17.7916362 },
    { lat: 48.7922272, lng: 17.7916808 },
    { lat: 48.7920301, lng: 17.7919864 },
    { lat: 48.7916975, lng: 17.791424 },
    { lat: 48.7913764, lng: 17.7917537 },
    { lat: 48.7910148, lng: 17.7921252 },
    { lat: 48.7909897, lng: 17.7920859 },
    { lat: 48.7906361, lng: 17.7915308 },
    { lat: 48.7901222, lng: 17.7907745 },
    { lat: 48.7900995, lng: 17.7907497 },
    { lat: 48.7895133, lng: 17.7901101 },
    { lat: 48.7892735, lng: 17.7904022 },
    { lat: 48.7892197, lng: 17.7904667 },
    { lat: 48.7891672, lng: 17.7905313 },
    { lat: 48.7891208, lng: 17.7905874 },
    { lat: 48.7890683, lng: 17.790652 },
    { lat: 48.789022, lng: 17.7907081 },
    { lat: 48.7889568, lng: 17.7907877 },
    { lat: 48.7887945, lng: 17.7909788 },
    { lat: 48.7883275, lng: 17.7915289 },
    { lat: 48.7881862, lng: 17.7916949 },
    { lat: 48.7881103, lng: 17.7917846 },
    { lat: 48.7880344, lng: 17.7918743 },
    { lat: 48.7878677, lng: 17.7920507 },
    { lat: 48.7872504, lng: 17.7926752 },
    { lat: 48.7870855, lng: 17.7928418 },
    { lat: 48.7869172, lng: 17.7930115 },
    { lat: 48.7868982, lng: 17.7930335 },
    { lat: 48.7868305, lng: 17.7931083 },
    { lat: 48.7865711, lng: 17.7933955 },
    { lat: 48.7865045, lng: 17.7934698 },
    { lat: 48.7862843, lng: 17.7937143 },
    { lat: 48.7862529, lng: 17.7937529 },
    { lat: 48.786014, lng: 17.7940431 },
    { lat: 48.7857899, lng: 17.794316 },
    { lat: 48.7857142, lng: 17.7944082 },
    { lat: 48.7854723, lng: 17.7947037 },
    { lat: 48.7854302, lng: 17.7947343 },
    { lat: 48.7852929, lng: 17.7948351 },
    { lat: 48.7850971, lng: 17.7949797 },
    { lat: 48.7850598, lng: 17.7950164 },
    { lat: 48.7849077, lng: 17.7951712 },
    { lat: 48.7848927, lng: 17.7951865 },
    { lat: 48.7848579, lng: 17.7951896 },
    { lat: 48.7848526, lng: 17.795195 },
    { lat: 48.7847023, lng: 17.795361 },
    { lat: 48.7845584, lng: 17.7955203 },
    { lat: 48.7844736, lng: 17.7956134 },
    { lat: 48.7844609, lng: 17.7955905 },
    { lat: 48.7844326, lng: 17.7956268 },
    { lat: 48.7832411, lng: 17.7971929 },
    { lat: 48.7832224, lng: 17.7971843 },
    { lat: 48.7826655, lng: 17.797911 },
    { lat: 48.7826373, lng: 17.7979466 },
    { lat: 48.7826206, lng: 17.7979694 },
    { lat: 48.7814954, lng: 17.7996632 },
    { lat: 48.7815678, lng: 17.7997741 },
    { lat: 48.7817733, lng: 17.8000862 },
    { lat: 48.7818803, lng: 17.8002481 },
    { lat: 48.7819684, lng: 17.8002102 },
    { lat: 48.782029, lng: 17.8001842 },
    { lat: 48.7820367, lng: 17.8002173 },
    { lat: 48.7823111, lng: 17.8001026 },
    { lat: 48.7825807, lng: 17.8008006 },
    { lat: 48.7825835, lng: 17.8008095 },
    { lat: 48.7826179, lng: 17.8008921 },
    { lat: 48.7827484, lng: 17.801215 },
    { lat: 48.7827524, lng: 17.8012263 },
    { lat: 48.7827606, lng: 17.8012509 },
    { lat: 48.7827985, lng: 17.8013519 },
    { lat: 48.7828634, lng: 17.8015258 },
    { lat: 48.7829069, lng: 17.8016656 },
    { lat: 48.7829293, lng: 17.8017382 },
    { lat: 48.7829984, lng: 17.801962 },
    { lat: 48.7830617, lng: 17.8021681 },
    { lat: 48.7830319, lng: 17.8021978 },
    { lat: 48.7822764, lng: 17.8029467 },
    { lat: 48.7817823, lng: 17.8034597 },
    { lat: 48.7817525, lng: 17.8034909 },
    { lat: 48.7816698, lng: 17.8033762 },
    { lat: 48.7816365, lng: 17.8033316 },
    { lat: 48.7815266, lng: 17.8031782 },
    { lat: 48.7814028, lng: 17.8033331 },
    { lat: 48.7813289, lng: 17.8032895 },
    { lat: 48.7811481, lng: 17.8034576 },
    { lat: 48.7807478, lng: 17.8041554 },
    { lat: 48.7805016, lng: 17.8045856 },
    { lat: 48.7805032, lng: 17.8045947 },
    { lat: 48.7805421, lng: 17.804733 },
    { lat: 48.7805698, lng: 17.8048342 },
    { lat: 48.7804373, lng: 17.8051079 },
    { lat: 48.7802707, lng: 17.8054521 },
    { lat: 48.7801563, lng: 17.8056887 },
    { lat: 48.7796694, lng: 17.8066175 },
    { lat: 48.77956, lng: 17.806843 },
    { lat: 48.7794074, lng: 17.8071589 },
    { lat: 48.7793606, lng: 17.8072243 },
    { lat: 48.7791716, lng: 17.807375 },
    { lat: 48.7793492, lng: 17.8079482 },
    { lat: 48.7794779, lng: 17.8089864 },
    { lat: 48.7795953, lng: 17.8097835 },
    { lat: 48.7802721, lng: 17.8092923 },
    { lat: 48.7812772, lng: 17.8084267 },
    { lat: 48.7813198, lng: 17.8085704 },
    { lat: 48.781395, lng: 17.8085257 },
    { lat: 48.7815648, lng: 17.8095163 },
    { lat: 48.7810185, lng: 17.8100369 },
    { lat: 48.781384, lng: 17.8109034 },
    { lat: 48.7819398, lng: 17.8122276 },
    { lat: 48.781401, lng: 17.8127988 },
    { lat: 48.7816362, lng: 17.813583 },
    { lat: 48.7814586, lng: 17.8137828 },
    { lat: 48.7819764, lng: 17.8153362 },
    { lat: 48.781877, lng: 17.8154635 },
    { lat: 48.7824507, lng: 17.8165261 },
    { lat: 48.7821549, lng: 17.816859 },
    { lat: 48.7825999, lng: 17.8178663 },
    { lat: 48.7827168, lng: 17.8177283 },
    { lat: 48.7830661, lng: 17.818554 },
    { lat: 48.7829583, lng: 17.8186562 },
    { lat: 48.7833031, lng: 17.8194423 },
    { lat: 48.7832596, lng: 17.8194897 },
    { lat: 48.7839968, lng: 17.8214222 },
    { lat: 48.7846557, lng: 17.8231439 },
    { lat: 48.7841496, lng: 17.8240111 },
    { lat: 48.7843344, lng: 17.8245816 },
    { lat: 48.7840768, lng: 17.8258707 },
  ],
  Haluzice: [
    { lat: 48.830316, lng: 17.877329 },
    { lat: 48.830328, lng: 17.877319 },
    { lat: 48.830328, lng: 17.877263 },
    { lat: 48.830464, lng: 17.87719 },
    { lat: 48.830586, lng: 17.877128 },
    { lat: 48.830694, lng: 17.87707 },
    { lat: 48.831003, lng: 17.876903 },
    { lat: 48.831172, lng: 17.876815 },
    { lat: 48.83142, lng: 17.876675 },
    { lat: 48.832046, lng: 17.876332 },
    { lat: 48.832307, lng: 17.876353 },
    { lat: 48.832448, lng: 17.876362 },
    { lat: 48.833184, lng: 17.876605 },
    { lat: 48.833537, lng: 17.87671 },
    { lat: 48.834505, lng: 17.876567 },
    { lat: 48.834938, lng: 17.876535 },
    { lat: 48.835394, lng: 17.876445 },
    { lat: 48.835577, lng: 17.876397 },
    { lat: 48.835716, lng: 17.875907 },
    { lat: 48.835529, lng: 17.875184 },
    { lat: 48.834966, lng: 17.874118 },
    { lat: 48.834897, lng: 17.873909 },
    { lat: 48.834518, lng: 17.873219 },
    { lat: 48.834063, lng: 17.872223 },
    { lat: 48.834513, lng: 17.871359 },
    { lat: 48.834542, lng: 17.870866 },
    { lat: 48.834591, lng: 17.870685 },
    { lat: 48.834741, lng: 17.870513 },
    { lat: 48.835284, lng: 17.870341 },
    { lat: 48.835764, lng: 17.870339 },
    { lat: 48.835954, lng: 17.870561 },
    { lat: 48.838086, lng: 17.86954 },
    { lat: 48.83904, lng: 17.869244 },
    { lat: 48.838969, lng: 17.868791 },
    { lat: 48.838952, lng: 17.868722 },
    { lat: 48.838862, lng: 17.868052 },
    { lat: 48.839319, lng: 17.867923 },
    { lat: 48.839275, lng: 17.867431 },
    { lat: 48.840607, lng: 17.866903 },
    { lat: 48.84068, lng: 17.867259 },
    { lat: 48.841061, lng: 17.868854 },
    { lat: 48.841083, lng: 17.868914 },
    { lat: 48.841333, lng: 17.868745 },
    { lat: 48.841717, lng: 17.868229 },
    { lat: 48.841967, lng: 17.867934 },
    { lat: 48.842509, lng: 17.867208 },
    { lat: 48.842794, lng: 17.866775 },
    { lat: 48.842864, lng: 17.867261 },
    { lat: 48.843737, lng: 17.866265 },
    { lat: 48.844591, lng: 17.86575 },
    { lat: 48.845267, lng: 17.865533 },
    { lat: 48.845771, lng: 17.865485 },
    { lat: 48.84604, lng: 17.864912 },
    { lat: 48.846283, lng: 17.863647 },
    { lat: 48.846788, lng: 17.862947 },
    { lat: 48.846832, lng: 17.862852 },
    { lat: 48.84716, lng: 17.862379 },
    { lat: 48.847378, lng: 17.862667 },
    { lat: 48.847518, lng: 17.863017 },
    { lat: 48.847662, lng: 17.863337 },
    { lat: 48.847759, lng: 17.863519 },
    { lat: 48.848011, lng: 17.86391 },
    { lat: 48.848238, lng: 17.864103 },
    { lat: 48.848544, lng: 17.863301 },
    { lat: 48.848608, lng: 17.863077 },
    { lat: 48.848707, lng: 17.862775 },
    { lat: 48.848813, lng: 17.862453 },
    { lat: 48.848928, lng: 17.862195 },
    { lat: 48.849304, lng: 17.861361 },
    { lat: 48.84942, lng: 17.861247 },
    { lat: 48.849525, lng: 17.861145 },
    { lat: 48.849635, lng: 17.86102 },
    { lat: 48.849856, lng: 17.860775 },
    { lat: 48.850029, lng: 17.860958 },
    { lat: 48.850325, lng: 17.861408 },
    { lat: 48.85043, lng: 17.86157 },
    { lat: 48.850543, lng: 17.861762 },
    { lat: 48.851047, lng: 17.862331 },
    { lat: 48.851207, lng: 17.861903 },
    { lat: 48.851268, lng: 17.861897 },
    { lat: 48.851486, lng: 17.861846 },
    { lat: 48.851682, lng: 17.861724 },
    { lat: 48.851842, lng: 17.861621 },
    { lat: 48.852154, lng: 17.861112 },
    { lat: 48.852175, lng: 17.861083 },
    { lat: 48.852184, lng: 17.86087 },
    { lat: 48.85243, lng: 17.860523 },
    { lat: 48.852775, lng: 17.860036 },
    { lat: 48.852892, lng: 17.859727 },
    { lat: 48.852938, lng: 17.859599 },
    { lat: 48.853032, lng: 17.859224 },
    { lat: 48.85311, lng: 17.859022 },
    { lat: 48.853385, lng: 17.858368 },
    { lat: 48.854295, lng: 17.856765 },
    { lat: 48.85466, lng: 17.856027 },
    { lat: 48.854937, lng: 17.855577 },
    { lat: 48.855155, lng: 17.855183 },
    { lat: 48.855365, lng: 17.854747 },
    { lat: 48.855671, lng: 17.854122 },
    { lat: 48.855758, lng: 17.853935 },
    { lat: 48.855932, lng: 17.854285 },
    { lat: 48.855988, lng: 17.854393 },
    { lat: 48.856494, lng: 17.854049 },
    { lat: 48.856636, lng: 17.853936 },
    { lat: 48.856708, lng: 17.853878 },
    { lat: 48.856973, lng: 17.853669 },
    { lat: 48.857198, lng: 17.853491 },
    { lat: 48.85736, lng: 17.853849 },
    { lat: 48.857392, lng: 17.854456 },
    { lat: 48.857403, lng: 17.854545 },
    { lat: 48.857459, lng: 17.855049 },
    { lat: 48.857892, lng: 17.854569 },
    { lat: 48.858058, lng: 17.854363 },
    { lat: 48.858164, lng: 17.854232 },
    { lat: 48.858251, lng: 17.854124 },
    { lat: 48.858371, lng: 17.853976 },
    { lat: 48.858587, lng: 17.853739 },
    { lat: 48.858737, lng: 17.853529 },
    { lat: 48.858765, lng: 17.853242 },
    { lat: 48.858922, lng: 17.853093 },
    { lat: 48.859198, lng: 17.853032 },
    { lat: 48.859272, lng: 17.852935 },
    { lat: 48.859462, lng: 17.852742 },
    { lat: 48.859533, lng: 17.85267 },
    { lat: 48.86003, lng: 17.852243 },
    { lat: 48.859978, lng: 17.85117 },
    { lat: 48.860023, lng: 17.851017 },
    { lat: 48.860171, lng: 17.850542 },
    { lat: 48.8595253, lng: 17.851855 },
    { lat: 48.8593224, lng: 17.8516514 },
    { lat: 48.8593061, lng: 17.8516253 },
    { lat: 48.8592191, lng: 17.8514954 },
    { lat: 48.8590163, lng: 17.8511638 },
    { lat: 48.8588851, lng: 17.8509742 },
    { lat: 48.8588308, lng: 17.8506349 },
    { lat: 48.8587708, lng: 17.8504637 },
    { lat: 48.8587316, lng: 17.8503569 },
    { lat: 48.8587216, lng: 17.8502663 },
    { lat: 48.8587004, lng: 17.850102 },
    { lat: 48.8586859, lng: 17.8500666 },
    { lat: 48.8586744, lng: 17.8500457 },
    { lat: 48.8586237, lng: 17.8499345 },
    { lat: 48.858655, lng: 17.8497238 },
    { lat: 48.8586911, lng: 17.8494994 },
    { lat: 48.8586356, lng: 17.8494028 },
    { lat: 48.8584942, lng: 17.8491548 },
    { lat: 48.8581646, lng: 17.8487813 },
    { lat: 48.8580939, lng: 17.8488651 },
    { lat: 48.8580452, lng: 17.8489176 },
    { lat: 48.8579945, lng: 17.8489748 },
    { lat: 48.8579596, lng: 17.8490146 },
    { lat: 48.8579237, lng: 17.8490538 },
    { lat: 48.8578521, lng: 17.8491394 },
    { lat: 48.8578023, lng: 17.8491951 },
    { lat: 48.8577612, lng: 17.849242 },
    { lat: 48.857479, lng: 17.8489625 },
    { lat: 48.8571642, lng: 17.8486084 },
    { lat: 48.8569785, lng: 17.848396 },
    { lat: 48.8569312, lng: 17.8483414 },
    { lat: 48.856913, lng: 17.8483196 },
    { lat: 48.8567391, lng: 17.8484134 },
    { lat: 48.8564485, lng: 17.8485735 },
    { lat: 48.8560848, lng: 17.8486996 },
    { lat: 48.8557444, lng: 17.8487447 },
    { lat: 48.8557388, lng: 17.8487267 },
    { lat: 48.8557232, lng: 17.8486745 },
    { lat: 48.8556963, lng: 17.848586 },
    { lat: 48.8553614, lng: 17.8486473 },
    { lat: 48.8550334, lng: 17.8487489 },
    { lat: 48.8548631, lng: 17.8488068 },
    { lat: 48.8548448, lng: 17.8488221 },
    { lat: 48.8547194, lng: 17.8489502 },
    { lat: 48.8545079, lng: 17.849169 },
    { lat: 48.8544136, lng: 17.8492698 },
    { lat: 48.8543763, lng: 17.849307 },
    { lat: 48.8541702, lng: 17.8495957 },
    { lat: 48.8541278, lng: 17.8496596 },
    { lat: 48.8539688, lng: 17.8498853 },
    { lat: 48.8540147, lng: 17.8499363 },
    { lat: 48.85399, lng: 17.8500125 },
    { lat: 48.8539652, lng: 17.8500878 },
    { lat: 48.8538976, lng: 17.8502972 },
    { lat: 48.8538645, lng: 17.8504203 },
    { lat: 48.8538289, lng: 17.8505419 },
    { lat: 48.8538051, lng: 17.8506325 },
    { lat: 48.8537031, lng: 17.8510008 },
    { lat: 48.8532197, lng: 17.8507911 },
    { lat: 48.853113, lng: 17.8507435 },
    { lat: 48.8531001, lng: 17.8507358 },
    { lat: 48.853035, lng: 17.8506855 },
    { lat: 48.8529628, lng: 17.8506296 },
    { lat: 48.8528927, lng: 17.8505718 },
    { lat: 48.8527379, lng: 17.8503816 },
    { lat: 48.8525999, lng: 17.8502109 },
    { lat: 48.8525436, lng: 17.8501383 },
    { lat: 48.8524382, lng: 17.8505407 },
    { lat: 48.8523372, lng: 17.8504958 },
    { lat: 48.8523008, lng: 17.8504734 },
    { lat: 48.8521575, lng: 17.850753 },
    { lat: 48.8520218, lng: 17.8510292 },
    { lat: 48.851854, lng: 17.8512983 },
    { lat: 48.8518323, lng: 17.8513329 },
    { lat: 48.8514693, lng: 17.8519197 },
    { lat: 48.8509571, lng: 17.8527467 },
    { lat: 48.8505625, lng: 17.8534838 },
    { lat: 48.850423, lng: 17.8538077 },
    { lat: 48.8502409, lng: 17.854234 },
    { lat: 48.8500946, lng: 17.8545767 },
    { lat: 48.8499185, lng: 17.8545943 },
    { lat: 48.8494865, lng: 17.8546178 },
    { lat: 48.8488925, lng: 17.8546169 },
    { lat: 48.8483239, lng: 17.8546016 },
    { lat: 48.8476709, lng: 17.8546495 },
    { lat: 48.8476305, lng: 17.8546524 },
    { lat: 48.8475313, lng: 17.8543758 },
    { lat: 48.8466933, lng: 17.8551719 },
    { lat: 48.8466825, lng: 17.8551811 },
    { lat: 48.8462481, lng: 17.8555399 },
    { lat: 48.8459719, lng: 17.8555653 },
    { lat: 48.8459668, lng: 17.8555361 },
    { lat: 48.8459561, lng: 17.85549 },
    { lat: 48.8456507, lng: 17.8555354 },
    { lat: 48.8453382, lng: 17.8555377 },
    { lat: 48.8449316, lng: 17.8556478 },
    { lat: 48.8445692, lng: 17.8558516 },
    { lat: 48.8442753, lng: 17.8560877 },
    { lat: 48.8439117, lng: 17.8561797 },
    { lat: 48.8437215, lng: 17.8563578 },
    { lat: 48.843314, lng: 17.8567875 },
    { lat: 48.8429653, lng: 17.857033 },
    { lat: 48.8429468, lng: 17.8569689 },
    { lat: 48.8423674, lng: 17.8573 },
    { lat: 48.8420651, lng: 17.8574724 },
    { lat: 48.8418687, lng: 17.8576433 },
    { lat: 48.8416615, lng: 17.8578361 },
    { lat: 48.8415706, lng: 17.85792 },
    { lat: 48.8414842, lng: 17.8580035 },
    { lat: 48.8413977, lng: 17.8581044 },
    { lat: 48.8411812, lng: 17.8583691 },
    { lat: 48.8411203, lng: 17.8584463 },
    { lat: 48.841027, lng: 17.8585637 },
    { lat: 48.8410137, lng: 17.8585697 },
    { lat: 48.8408051, lng: 17.8587231 },
    { lat: 48.8406463, lng: 17.8588413 },
    { lat: 48.8404638, lng: 17.8589037 },
    { lat: 48.8400948, lng: 17.8581751 },
    { lat: 48.8399029, lng: 17.8583817 },
    { lat: 48.8394506, lng: 17.8573964 },
    { lat: 48.8392228, lng: 17.8576426 },
    { lat: 48.8393127, lng: 17.8578597 },
    { lat: 48.8390334, lng: 17.8583024 },
    { lat: 48.8381725, lng: 17.858794 },
    { lat: 48.8380439, lng: 17.8588691 },
    { lat: 48.8380572, lng: 17.8589234 },
    { lat: 48.8379252, lng: 17.8589972 },
    { lat: 48.8377197, lng: 17.8591099 },
    { lat: 48.8375601, lng: 17.8591961 },
    { lat: 48.8374837, lng: 17.8592391 },
    { lat: 48.8374072, lng: 17.8592802 },
    { lat: 48.8373296, lng: 17.859323 },
    { lat: 48.8372148, lng: 17.8593855 },
    { lat: 48.8369731, lng: 17.8595138 },
    { lat: 48.8368072, lng: 17.859607 },
    { lat: 48.8366412, lng: 17.8597 },
    { lat: 48.8365185, lng: 17.8597627 },
    { lat: 48.8365311, lng: 17.8598405 },
    { lat: 48.8364866, lng: 17.8598513 },
    { lat: 48.8362984, lng: 17.8599122 },
    { lat: 48.8360695, lng: 17.8599913 },
    { lat: 48.8360607, lng: 17.8599945 },
    { lat: 48.8360506, lng: 17.8600341 },
    { lat: 48.8344687, lng: 17.8603071 },
    { lat: 48.8337157, lng: 17.8606622 },
    { lat: 48.832383, lng: 17.861735 },
    { lat: 48.8323092, lng: 17.8611657 },
    { lat: 48.8322529, lng: 17.8611503 },
    { lat: 48.8311046, lng: 17.8607536 },
    { lat: 48.8304931, lng: 17.8606104 },
    { lat: 48.8295295, lng: 17.8604298 },
    { lat: 48.8290899, lng: 17.8601213 },
    { lat: 48.8286154, lng: 17.8597797 },
    { lat: 48.8285181, lng: 17.8596448 },
    { lat: 48.8283515, lng: 17.8594092 },
    { lat: 48.8279004, lng: 17.8587627 },
    { lat: 48.827433, lng: 17.8584431 },
    { lat: 48.8265601, lng: 17.8585135 },
    { lat: 48.8261951, lng: 17.8588983 },
    { lat: 48.8261632, lng: 17.8589882 },
    { lat: 48.8261554, lng: 17.859009 },
    { lat: 48.8261166, lng: 17.8591132 },
    { lat: 48.8260409, lng: 17.859113 },
    { lat: 48.8259912, lng: 17.8591131 },
    { lat: 48.8258513, lng: 17.8591136 },
    { lat: 48.8256255, lng: 17.8591123 },
    { lat: 48.8254249, lng: 17.8591191 },
    { lat: 48.8253177, lng: 17.8591195 },
    { lat: 48.8251792, lng: 17.8591263 },
    { lat: 48.8251309, lng: 17.8591283 },
    { lat: 48.8250834, lng: 17.8591275 },
    { lat: 48.8250552, lng: 17.859128 },
    { lat: 48.825035, lng: 17.8591292 },
    { lat: 48.8249861, lng: 17.8591249 },
    { lat: 48.8248877, lng: 17.8591197 },
    { lat: 48.8248514, lng: 17.8591183 },
    { lat: 48.8248036, lng: 17.8595783 },
    { lat: 48.8249381, lng: 17.8599752 },
    { lat: 48.8247221, lng: 17.8601363 },
    { lat: 48.8246299, lng: 17.8599353 },
    { lat: 48.8245171, lng: 17.8599929 },
    { lat: 48.8244035, lng: 17.8600564 },
    { lat: 48.8242692, lng: 17.8601332 },
    { lat: 48.8242322, lng: 17.8601541 },
    { lat: 48.8241641, lng: 17.8601857 },
    { lat: 48.8240976, lng: 17.8602245 },
    { lat: 48.8240409, lng: 17.8602571 },
    { lat: 48.8238828, lng: 17.8597332 },
    { lat: 48.8237512, lng: 17.8597604 },
    { lat: 48.8236714, lng: 17.8597852 },
    { lat: 48.8235916, lng: 17.8598099 },
    { lat: 48.8235716, lng: 17.8598148 },
    { lat: 48.823546, lng: 17.8591665 },
    { lat: 48.8235244, lng: 17.8591652 },
    { lat: 48.8231134, lng: 17.8593479 },
    { lat: 48.8228132, lng: 17.8594808 },
    { lat: 48.8227806, lng: 17.8593712 },
    { lat: 48.8226609, lng: 17.8594648 },
    { lat: 48.8225041, lng: 17.8595963 },
    { lat: 48.8224637, lng: 17.8596386 },
    { lat: 48.8223853, lng: 17.8597046 },
    { lat: 48.8223069, lng: 17.8597707 },
    { lat: 48.8222693, lng: 17.8598018 },
    { lat: 48.8221756, lng: 17.8595983 },
    { lat: 48.8220811, lng: 17.8596776 },
    { lat: 48.8220488, lng: 17.8597038 },
    { lat: 48.822007, lng: 17.8597391 },
    { lat: 48.8219671, lng: 17.8597706 },
    { lat: 48.8219307, lng: 17.8598039 },
    { lat: 48.8218876, lng: 17.8598377 },
    { lat: 48.8218478, lng: 17.8598715 },
    { lat: 48.8218051, lng: 17.8599098 },
    { lat: 48.8217623, lng: 17.8599494 },
    { lat: 48.821719, lng: 17.8599803 },
    { lat: 48.8216738, lng: 17.8600177 },
    { lat: 48.821633, lng: 17.8600503 },
    { lat: 48.821593, lng: 17.8600823 },
    { lat: 48.8215148, lng: 17.8601496 },
    { lat: 48.8214663, lng: 17.8601892 },
    { lat: 48.8214178, lng: 17.8602262 },
    { lat: 48.8213964, lng: 17.8602451 },
    { lat: 48.8213515, lng: 17.86027 },
    { lat: 48.821287, lng: 17.8603221 },
    { lat: 48.8212509, lng: 17.8603412 },
    { lat: 48.8212163, lng: 17.8603673 },
    { lat: 48.8211774, lng: 17.8603958 },
    { lat: 48.8211383, lng: 17.8604214 },
    { lat: 48.8211082, lng: 17.8604457 },
    { lat: 48.8210732, lng: 17.8604645 },
    { lat: 48.8208396, lng: 17.8606337 },
    { lat: 48.8207899, lng: 17.8606708 },
    { lat: 48.8207455, lng: 17.8607013 },
    { lat: 48.8206921, lng: 17.8607345 },
    { lat: 48.8206406, lng: 17.8607612 },
    { lat: 48.8205909, lng: 17.8607972 },
    { lat: 48.8205406, lng: 17.8608237 },
    { lat: 48.8204916, lng: 17.8608536 },
    { lat: 48.8204275, lng: 17.8608937 },
    { lat: 48.8203087, lng: 17.8609656 },
    { lat: 48.8202499, lng: 17.8610012 },
    { lat: 48.8201538, lng: 17.8610555 },
    { lat: 48.8201364, lng: 17.8610665 },
    { lat: 48.8198279, lng: 17.8599575 },
    { lat: 48.8198124, lng: 17.8599798 },
    { lat: 48.8192868, lng: 17.8607734 },
    { lat: 48.8192516, lng: 17.8608261 },
    { lat: 48.8192134, lng: 17.8608844 },
    { lat: 48.8187135, lng: 17.8611865 },
    { lat: 48.818225, lng: 17.8616986 },
    { lat: 48.8175017, lng: 17.862674 },
    { lat: 48.8167721, lng: 17.8633984 },
    { lat: 48.8156583, lng: 17.8643584 },
    { lat: 48.8149408, lng: 17.8651525 },
    { lat: 48.8144675, lng: 17.8657273 },
    { lat: 48.814323, lng: 17.8659132 },
    { lat: 48.8138587, lng: 17.8664863 },
    { lat: 48.813824, lng: 17.866751 },
    { lat: 48.814546, lng: 17.866995 },
    { lat: 48.815023, lng: 17.867158 },
    { lat: 48.815763, lng: 17.867417 },
    { lat: 48.816396, lng: 17.868578 },
    { lat: 48.816625, lng: 17.868948 },
    { lat: 48.817043, lng: 17.869511 },
    { lat: 48.817654, lng: 17.870168 },
    { lat: 48.817918, lng: 17.869901 },
    { lat: 48.818956, lng: 17.869263 },
    { lat: 48.819147, lng: 17.869704 },
    { lat: 48.819177, lng: 17.869776 },
    { lat: 48.819204, lng: 17.869835 },
    { lat: 48.818983, lng: 17.870301 },
    { lat: 48.818802, lng: 17.870684 },
    { lat: 48.818875, lng: 17.871574 },
    { lat: 48.819236, lng: 17.870718 },
    { lat: 48.819312, lng: 17.870831 },
    { lat: 48.819467, lng: 17.871059 },
    { lat: 48.819512, lng: 17.871127 },
    { lat: 48.819561, lng: 17.871194 },
    { lat: 48.819291, lng: 17.871682 },
    { lat: 48.8193374, lng: 17.8717325 },
    { lat: 48.820039, lng: 17.872497 },
    { lat: 48.820265, lng: 17.872616 },
    { lat: 48.820342, lng: 17.872653 },
    { lat: 48.820646, lng: 17.873052 },
    { lat: 48.820967, lng: 17.873701 },
    { lat: 48.821126, lng: 17.874097 },
    { lat: 48.821147, lng: 17.874151 },
    { lat: 48.82123, lng: 17.874389 },
    { lat: 48.821299, lng: 17.874577 },
    { lat: 48.821364, lng: 17.874508 },
    { lat: 48.821505, lng: 17.874344 },
    { lat: 48.821577, lng: 17.874261 },
    { lat: 48.821639, lng: 17.874155 },
    { lat: 48.82169, lng: 17.874063 },
    { lat: 48.821733, lng: 17.873934 },
    { lat: 48.821822, lng: 17.873653 },
    { lat: 48.821883, lng: 17.873743 },
    { lat: 48.821903, lng: 17.873774 },
    { lat: 48.821926, lng: 17.873811 },
    { lat: 48.822062, lng: 17.873978 },
    { lat: 48.822166, lng: 17.874111 },
    { lat: 48.822346, lng: 17.873802 },
    { lat: 48.822716, lng: 17.873191 },
    { lat: 48.822864, lng: 17.872878 },
    { lat: 48.823311, lng: 17.872783 },
    { lat: 48.823565, lng: 17.872859 },
    { lat: 48.823707, lng: 17.873036 },
    { lat: 48.823919, lng: 17.873461 },
    { lat: 48.823969, lng: 17.873576 },
    { lat: 48.824109, lng: 17.873896 },
    { lat: 48.824098, lng: 17.874223 },
    { lat: 48.82401, lng: 17.87428 },
    { lat: 48.82385, lng: 17.874386 },
    { lat: 48.824016, lng: 17.874605 },
    { lat: 48.824033, lng: 17.874622 },
    { lat: 48.824126, lng: 17.874738 },
    { lat: 48.824079, lng: 17.874844 },
    { lat: 48.824091, lng: 17.874858 },
    { lat: 48.824195, lng: 17.874986 },
    { lat: 48.824288, lng: 17.875079 },
    { lat: 48.82438, lng: 17.875165 },
    { lat: 48.824585, lng: 17.875369 },
    { lat: 48.824835, lng: 17.875213 },
    { lat: 48.824938, lng: 17.875145 },
    { lat: 48.825017, lng: 17.875142 },
    { lat: 48.825111, lng: 17.875127 },
    { lat: 48.8252, lng: 17.875123 },
    { lat: 48.825219, lng: 17.87512 },
    { lat: 48.825233, lng: 17.875118 },
    { lat: 48.825291, lng: 17.875118 },
    { lat: 48.825339, lng: 17.875114 },
    { lat: 48.825373, lng: 17.875113 },
    { lat: 48.825408, lng: 17.875111 },
    { lat: 48.825482, lng: 17.875128 },
    { lat: 48.825523, lng: 17.875137 },
    { lat: 48.825475, lng: 17.875073 },
    { lat: 48.825423, lng: 17.875005 },
    { lat: 48.826024, lng: 17.874562 },
    { lat: 48.826607, lng: 17.874129 },
    { lat: 48.826619, lng: 17.874218 },
    { lat: 48.826637, lng: 17.87435 },
    { lat: 48.826644, lng: 17.874391 },
    { lat: 48.826653, lng: 17.874458 },
    { lat: 48.826656, lng: 17.874485 },
    { lat: 48.826663, lng: 17.874526 },
    { lat: 48.826671, lng: 17.874573 },
    { lat: 48.826676, lng: 17.874617 },
    { lat: 48.826685, lng: 17.874672 },
    { lat: 48.826718, lng: 17.874878 },
    { lat: 48.826764, lng: 17.875058 },
    { lat: 48.82682, lng: 17.875272 },
    { lat: 48.826847, lng: 17.875371 },
    { lat: 48.826854, lng: 17.8754 },
    { lat: 48.826917, lng: 17.87549 },
    { lat: 48.826953, lng: 17.875546 },
    { lat: 48.826969, lng: 17.875574 },
    { lat: 48.826988, lng: 17.875599 },
    { lat: 48.827023, lng: 17.875651 },
    { lat: 48.827074, lng: 17.875726 },
    { lat: 48.827125, lng: 17.875748 },
    { lat: 48.827173, lng: 17.875763 },
    { lat: 48.827181, lng: 17.875779 },
    { lat: 48.827199, lng: 17.875813 },
    { lat: 48.827211, lng: 17.875838 },
    { lat: 48.827244, lng: 17.875899 },
    { lat: 48.827246, lng: 17.875906 },
    { lat: 48.827249, lng: 17.875909 },
    { lat: 48.827251, lng: 17.875916 },
    { lat: 48.827256, lng: 17.87592 },
    { lat: 48.82726, lng: 17.87593 },
    { lat: 48.827295, lng: 17.875996 },
    { lat: 48.827381, lng: 17.876173 },
    { lat: 48.827415, lng: 17.876134 },
    { lat: 48.827537, lng: 17.876004 },
    { lat: 48.827571, lng: 17.875962 },
    { lat: 48.827898, lng: 17.875082 },
    { lat: 48.827935, lng: 17.875085 },
    { lat: 48.827964, lng: 17.875088 },
    { lat: 48.828015, lng: 17.875094 },
    { lat: 48.828062, lng: 17.875097 },
    { lat: 48.828109, lng: 17.8751 },
    { lat: 48.828186, lng: 17.87511 },
    { lat: 48.828217, lng: 17.875114 },
    { lat: 48.828251, lng: 17.875118 },
    { lat: 48.828308, lng: 17.875123 },
    { lat: 48.828349, lng: 17.875136 },
    { lat: 48.828498, lng: 17.875185 },
    { lat: 48.828576, lng: 17.875209 },
    { lat: 48.828696, lng: 17.87525 },
    { lat: 48.828734, lng: 17.875264 },
    { lat: 48.828916, lng: 17.875532 },
    { lat: 48.829255, lng: 17.875801 },
    { lat: 48.829697, lng: 17.875944 },
    { lat: 48.829953, lng: 17.876233 },
    { lat: 48.830154, lng: 17.87654 },
    { lat: 48.830316, lng: 17.877329 },
  ],
  Kálnica: [
    { lat: 48.7374358, lng: 17.9367772 },
    { lat: 48.7372184, lng: 17.9369341 },
    { lat: 48.7371853, lng: 17.9369493 },
    { lat: 48.737127, lng: 17.9370124 },
    { lat: 48.7370936, lng: 17.937057 },
    { lat: 48.7368387, lng: 17.937343 },
    { lat: 48.7366173, lng: 17.9375859 },
    { lat: 48.7361911, lng: 17.9377439 },
    { lat: 48.7358757, lng: 17.9378117 },
    { lat: 48.7358463, lng: 17.937831 },
    { lat: 48.7356987, lng: 17.9379472 },
    { lat: 48.7355349, lng: 17.9382103 },
    { lat: 48.7354416, lng: 17.9384064 },
    { lat: 48.7353277, lng: 17.9387288 },
    { lat: 48.7351635, lng: 17.939173 },
    { lat: 48.7351152, lng: 17.9392906 },
    { lat: 48.734937, lng: 17.9396132 },
    { lat: 48.7348588, lng: 17.9397775 },
    { lat: 48.7347468, lng: 17.9400563 },
    { lat: 48.7345757, lng: 17.9401975 },
    { lat: 48.7342651, lng: 17.9406481 },
    { lat: 48.7342151, lng: 17.9407549 },
    { lat: 48.7341364, lng: 17.940931 },
    { lat: 48.734088, lng: 17.9410447 },
    { lat: 48.733943, lng: 17.9414348 },
    { lat: 48.7337818, lng: 17.941834 },
    { lat: 48.7335836, lng: 17.9422573 },
    { lat: 48.7333932, lng: 17.9424897 },
    { lat: 48.7333233, lng: 17.9427932 },
    { lat: 48.7331829, lng: 17.9432765 },
    { lat: 48.7330028, lng: 17.943871 },
    { lat: 48.7329751, lng: 17.9439755 },
    { lat: 48.7326827, lng: 17.9445585 },
    { lat: 48.7324265, lng: 17.944901 },
    { lat: 48.73223, lng: 17.945351 },
    { lat: 48.7320521, lng: 17.9457933 },
    { lat: 48.7319548, lng: 17.9459789 },
    { lat: 48.7318867, lng: 17.9461811 },
    { lat: 48.7318116, lng: 17.9464707 },
    { lat: 48.7317747, lng: 17.9470452 },
    { lat: 48.7317462, lng: 17.9473254 },
    { lat: 48.7316082, lng: 17.9478114 },
    { lat: 48.7315872, lng: 17.9478368 },
    { lat: 48.7313043, lng: 17.9482791 },
    { lat: 48.7311927, lng: 17.9484325 },
    { lat: 48.7310605, lng: 17.9485431 },
    { lat: 48.7308051, lng: 17.9487076 },
    { lat: 48.7306621, lng: 17.9489422 },
    { lat: 48.730509, lng: 17.9490444 },
    { lat: 48.7303314, lng: 17.9492645 },
    { lat: 48.7298329, lng: 17.9499893 },
    { lat: 48.7296505, lng: 17.9502041 },
    { lat: 48.7293827, lng: 17.9504074 },
    { lat: 48.7290811, lng: 17.9505738 },
    { lat: 48.7288231, lng: 17.9508854 },
    { lat: 48.7287382, lng: 17.9509742 },
    { lat: 48.7283642, lng: 17.9510999 },
    { lat: 48.7279379, lng: 17.951202 },
    { lat: 48.7276237, lng: 17.9514209 },
    { lat: 48.7273836, lng: 17.9516349 },
    { lat: 48.7270365, lng: 17.9519472 },
    { lat: 48.7269716, lng: 17.9520132 },
    { lat: 48.7269331, lng: 17.9520471 },
    { lat: 48.7265682, lng: 17.9522508 },
    { lat: 48.7265196, lng: 17.9522685 },
    { lat: 48.7263994, lng: 17.9523177 },
    { lat: 48.7263259, lng: 17.9523349 },
    { lat: 48.7259777, lng: 17.9527408 },
    { lat: 48.7258937, lng: 17.9529228 },
    { lat: 48.7257967, lng: 17.9532819 },
    { lat: 48.7257722, lng: 17.9533637 },
    { lat: 48.725733, lng: 17.9535013 },
    { lat: 48.7256711, lng: 17.9536753 },
    { lat: 48.7256418, lng: 17.9537508 },
    { lat: 48.7255304, lng: 17.9539272 },
    { lat: 48.7253166, lng: 17.9544311 },
    { lat: 48.7250276, lng: 17.9549413 },
    { lat: 48.7245969, lng: 17.9555911 },
    { lat: 48.7244434, lng: 17.9557239 },
    { lat: 48.724428, lng: 17.9557301 },
    { lat: 48.7244175, lng: 17.9557069 },
    { lat: 48.7244098, lng: 17.955748 },
    { lat: 48.7241337, lng: 17.956001 },
    { lat: 48.7239772, lng: 17.9561021 },
    { lat: 48.7238119, lng: 17.956207 },
    { lat: 48.7235975, lng: 17.956246 },
    { lat: 48.7234588, lng: 17.9563234 },
    { lat: 48.7232863, lng: 17.9563839 },
    { lat: 48.7231758, lng: 17.956481 },
    { lat: 48.722954, lng: 17.9568301 },
    { lat: 48.7228927, lng: 17.9570147 },
    { lat: 48.7227224, lng: 17.9574127 },
    { lat: 48.7223929, lng: 17.9577761 },
    { lat: 48.7219642, lng: 17.9584406 },
    { lat: 48.721858, lng: 17.9585904 },
    { lat: 48.7216498, lng: 17.9589234 },
    { lat: 48.7215655, lng: 17.9590053 },
    { lat: 48.7213494, lng: 17.9591846 },
    { lat: 48.7210451, lng: 17.9594959 },
    { lat: 48.7209769, lng: 17.9596215 },
    { lat: 48.7209175, lng: 17.9597969 },
    { lat: 48.7208934, lng: 17.9600186 },
    { lat: 48.7209801, lng: 17.9604678 },
    { lat: 48.7211938, lng: 17.9611952 },
    { lat: 48.7212638, lng: 17.9614808 },
    { lat: 48.7213678, lng: 17.9616901 },
    { lat: 48.721691, lng: 17.9619814 },
    { lat: 48.7219369, lng: 17.9623181 },
    { lat: 48.7222696, lng: 17.9626741 },
    { lat: 48.7225902, lng: 17.963036 },
    { lat: 48.7227411, lng: 17.9633132 },
    { lat: 48.7228419, lng: 17.963754 },
    { lat: 48.7228494, lng: 17.9640892 },
    { lat: 48.7227882, lng: 17.9645964 },
    { lat: 48.722827, lng: 17.9649268 },
    { lat: 48.7229145, lng: 17.9651826 },
    { lat: 48.7226797, lng: 17.9656557 },
    { lat: 48.722574, lng: 17.9658696 },
    { lat: 48.722433, lng: 17.9661549 },
    { lat: 48.7223224, lng: 17.9664976 },
    { lat: 48.7222615, lng: 17.9667822 },
    { lat: 48.7222735, lng: 17.9670024 },
    { lat: 48.7223038, lng: 17.9672272 },
    { lat: 48.7223863, lng: 17.9675699 },
    { lat: 48.7223971, lng: 17.9677139 },
    { lat: 48.7223638, lng: 17.967951 },
    { lat: 48.7223454, lng: 17.9682473 },
    { lat: 48.7223106, lng: 17.9685552 },
    { lat: 48.7222308, lng: 17.9690919 },
    { lat: 48.7221977, lng: 17.9692567 },
    { lat: 48.7221528, lng: 17.9697517 },
    { lat: 48.7221243, lng: 17.969938 },
    { lat: 48.7220601, lng: 17.9700342 },
    { lat: 48.7213917, lng: 17.9706185 },
    { lat: 48.7213367, lng: 17.9706435 },
    { lat: 48.721252, lng: 17.9706818 },
    { lat: 48.721177, lng: 17.9706915 },
    { lat: 48.721038, lng: 17.9706891 },
    { lat: 48.7204986, lng: 17.970672 },
    { lat: 48.7203149, lng: 17.9706378 },
    { lat: 48.7199237, lng: 17.970703 },
    { lat: 48.7193585, lng: 17.9706513 },
    { lat: 48.7192477, lng: 17.9706857 },
    { lat: 48.719203, lng: 17.9710349 },
    { lat: 48.7193987, lng: 17.9718374 },
    { lat: 48.7196914, lng: 17.9731151 },
    { lat: 48.7199059, lng: 17.9737992 },
    { lat: 48.7198897, lng: 17.9745499 },
    { lat: 48.7199077, lng: 17.974834 },
    { lat: 48.7197688, lng: 17.9758961 },
    { lat: 48.7197196, lng: 17.9760357 },
    { lat: 48.7195564, lng: 17.9763104 },
    { lat: 48.7193979, lng: 17.9765295 },
    { lat: 48.7193481, lng: 17.9766974 },
    { lat: 48.7193614, lng: 17.9771505 },
    { lat: 48.7194462, lng: 17.9775706 },
    { lat: 48.7196205, lng: 17.9778826 },
    { lat: 48.7197586, lng: 17.9783253 },
    { lat: 48.7198022, lng: 17.9786403 },
    { lat: 48.7198151, lng: 17.9789526 },
    { lat: 48.7198678, lng: 17.9792886 },
    { lat: 48.7200727, lng: 17.9798694 },
    { lat: 48.7202641, lng: 17.9802972 },
    { lat: 48.7204239, lng: 17.9806508 },
    { lat: 48.7205831, lng: 17.9812018 },
    { lat: 48.7206314, lng: 17.9815958 },
    { lat: 48.7206224, lng: 17.9819002 },
    { lat: 48.7205891, lng: 17.9824028 },
    { lat: 48.7206359, lng: 17.9826413 },
    { lat: 48.7207382, lng: 17.9828056 },
    { lat: 48.7209574, lng: 17.9829994 },
    { lat: 48.7210529, lng: 17.9831616 },
    { lat: 48.7212241, lng: 17.9833654 },
    { lat: 48.7212881, lng: 17.9835306 },
    { lat: 48.7213057, lng: 17.9838085 },
    { lat: 48.7213075, lng: 17.9841805 },
    { lat: 48.721338, lng: 17.9843891 },
    { lat: 48.7214019, lng: 17.9846104 },
    { lat: 48.7215129, lng: 17.9850542 },
    { lat: 48.7215977, lng: 17.9853224 },
    { lat: 48.7216505, lng: 17.9856026 },
    { lat: 48.7216827, lng: 17.9858419 },
    { lat: 48.7217696, lng: 17.9862033 },
    { lat: 48.7218815, lng: 17.9867936 },
    { lat: 48.7217982, lng: 17.9872366 },
    { lat: 48.7217785, lng: 17.9874358 },
    { lat: 48.7217836, lng: 17.9877109 },
    { lat: 48.7217791, lng: 17.9878063 },
    { lat: 48.721757, lng: 17.9881369 },
    { lat: 48.721724, lng: 17.9884949 },
    { lat: 48.7217115, lng: 17.9887037 },
    { lat: 48.7217304, lng: 17.9892685 },
    { lat: 48.7218046, lng: 17.989663 },
    { lat: 48.7217794, lng: 17.9904554 },
    { lat: 48.7218365, lng: 17.9908648 },
    { lat: 48.7219194, lng: 17.9914067 },
    { lat: 48.7219222, lng: 17.9914728 },
    { lat: 48.7219808, lng: 17.9918517 },
    { lat: 48.7221282, lng: 17.9924705 },
    { lat: 48.7222016, lng: 17.9928906 },
    { lat: 48.7222246, lng: 17.993278 },
    { lat: 48.7221722, lng: 17.9937274 },
    { lat: 48.7220912, lng: 17.9939981 },
    { lat: 48.7220628, lng: 17.9941848 },
    { lat: 48.7220663, lng: 17.9944163 },
    { lat: 48.7220341, lng: 17.9948446 },
    { lat: 48.721985, lng: 17.9953281 },
    { lat: 48.7220289, lng: 17.9955551 },
    { lat: 48.7220975, lng: 17.9958348 },
    { lat: 48.7222805, lng: 17.9964484 },
    { lat: 48.7223341, lng: 17.9967039 },
    { lat: 48.7223821, lng: 17.9970765 },
    { lat: 48.7224509, lng: 17.9972456 },
    { lat: 48.7225179, lng: 17.997578 },
    { lat: 48.7226584, lng: 17.9979476 },
    { lat: 48.722806, lng: 17.998215 },
    { lat: 48.723645, lng: 17.998441 },
    { lat: 48.724352, lng: 17.998409 },
    { lat: 48.724721, lng: 17.998304 },
    { lat: 48.725569, lng: 17.998163 },
    { lat: 48.726629, lng: 17.997391 },
    { lat: 48.727432, lng: 17.997234 },
    { lat: 48.72845, lng: 17.997159 },
    { lat: 48.729173, lng: 17.997495 },
    { lat: 48.729587, lng: 17.997665 },
    { lat: 48.730528, lng: 17.997816 },
    { lat: 48.730991, lng: 17.998134 },
    { lat: 48.731669, lng: 17.998508 },
    { lat: 48.73255, lng: 17.999074 },
    { lat: 48.733163, lng: 17.998968 },
    { lat: 48.73383, lng: 17.998448 },
    { lat: 48.735382, lng: 17.997874 },
    { lat: 48.736033, lng: 17.998338 },
    { lat: 48.736744, lng: 17.998942 },
    { lat: 48.737436, lng: 17.999852 },
    { lat: 48.738064, lng: 18.000225 },
    { lat: 48.739099, lng: 18.00011 },
    { lat: 48.740298, lng: 18.00007 },
    { lat: 48.74094, lng: 17.999987 },
    { lat: 48.74105, lng: 17.999404 },
    { lat: 48.741524, lng: 17.999373 },
    { lat: 48.742224, lng: 17.999396 },
    { lat: 48.742925, lng: 18.000061 },
    { lat: 48.743703, lng: 18.000804 },
    { lat: 48.744285, lng: 18.001574 },
    { lat: 48.744798, lng: 18.002522 },
    { lat: 48.74559, lng: 18.0029 },
    { lat: 48.746393, lng: 18.002908 },
    { lat: 48.747213, lng: 18.003117 },
    { lat: 48.747977, lng: 18.003482 },
    { lat: 48.748081, lng: 18.003323 },
    { lat: 48.748139, lng: 18.003229 },
    { lat: 48.74824, lng: 18.003147 },
    { lat: 48.748388, lng: 18.003014 },
    { lat: 48.748456, lng: 18.002957 },
    { lat: 48.748613, lng: 18.002819 },
    { lat: 48.748749, lng: 18.002699 },
    { lat: 48.748901, lng: 18.002564 },
    { lat: 48.748932, lng: 18.002547 },
    { lat: 48.749038, lng: 18.002493 },
    { lat: 48.749169, lng: 18.002422 },
    { lat: 48.749331, lng: 18.002336 },
    { lat: 48.749398, lng: 18.002302 },
    { lat: 48.749413, lng: 18.002283 },
    { lat: 48.749527, lng: 18.002124 },
    { lat: 48.74966, lng: 18.001942 },
    { lat: 48.749781, lng: 18.001775 },
    { lat: 48.7498, lng: 18.00174 },
    { lat: 48.749828, lng: 18.001681 },
    { lat: 48.749881, lng: 18.001559 },
    { lat: 48.749942, lng: 18.001427 },
    { lat: 48.749999, lng: 18.0013 },
    { lat: 48.750035, lng: 18.001219 },
    { lat: 48.750073, lng: 18.001113 },
    { lat: 48.750117, lng: 18.000987 },
    { lat: 48.750222, lng: 18.000702 },
    { lat: 48.750284, lng: 18.000501 },
    { lat: 48.750327, lng: 18.000378 },
    { lat: 48.750418, lng: 18.000107 },
    { lat: 48.750456, lng: 17.999997 },
    { lat: 48.750565, lng: 17.999744 },
    { lat: 48.75068, lng: 17.999478 },
    { lat: 48.750771, lng: 17.999266 },
    { lat: 48.750828, lng: 17.999135 },
    { lat: 48.750914, lng: 17.998992 },
    { lat: 48.751069, lng: 17.998745 },
    { lat: 48.751228, lng: 17.998492 },
    { lat: 48.751357, lng: 17.998286 },
    { lat: 48.751395, lng: 17.998222 },
    { lat: 48.751497, lng: 17.998005 },
    { lat: 48.751581, lng: 17.997824 },
    { lat: 48.75165, lng: 17.997664 },
    { lat: 48.751808, lng: 17.997485 },
    { lat: 48.752038, lng: 17.997223 },
    { lat: 48.752205, lng: 17.997037 },
    { lat: 48.752295, lng: 17.996688 },
    { lat: 48.752428, lng: 17.996177 },
    { lat: 48.75252, lng: 17.995912 },
    { lat: 48.752647, lng: 17.995542 },
    { lat: 48.752768, lng: 17.995189 },
    { lat: 48.752784, lng: 17.995098 },
    { lat: 48.752836, lng: 17.994784 },
    { lat: 48.752888, lng: 17.99446 },
    { lat: 48.752967, lng: 17.994264 },
    { lat: 48.753078, lng: 17.993988 },
    { lat: 48.753201, lng: 17.993928 },
    { lat: 48.753354, lng: 17.99386 },
    { lat: 48.753528, lng: 17.993781 },
    { lat: 48.753668, lng: 17.993715 },
    { lat: 48.753837, lng: 17.993661 },
    { lat: 48.754, lng: 17.993609 },
    { lat: 48.754092, lng: 17.993583 },
    { lat: 48.754077, lng: 17.993551 },
    { lat: 48.753936, lng: 17.993242 },
    { lat: 48.753802, lng: 17.992947 },
    { lat: 48.7537, lng: 17.992711 },
    { lat: 48.753584, lng: 17.99244 },
    { lat: 48.753519, lng: 17.99229 },
    { lat: 48.753413, lng: 17.992 },
    { lat: 48.753311, lng: 17.991722 },
    { lat: 48.753235, lng: 17.991511 },
    { lat: 48.753116, lng: 17.991176 },
    { lat: 48.753075, lng: 17.991057 },
    { lat: 48.753027, lng: 17.990859 },
    { lat: 48.752947, lng: 17.990544 },
    { lat: 48.752884, lng: 17.990279 },
    { lat: 48.752785, lng: 17.990051 },
    { lat: 48.752703, lng: 17.98986 },
    { lat: 48.752606, lng: 17.98964 },
    { lat: 48.752564, lng: 17.989539 },
    { lat: 48.752489, lng: 17.989363 },
    { lat: 48.752428, lng: 17.989217 },
    { lat: 48.7523, lng: 17.988878 },
    { lat: 48.752183, lng: 17.988553 },
    { lat: 48.752086, lng: 17.988292 },
    { lat: 48.752012, lng: 17.988087 },
    { lat: 48.751876, lng: 17.987784 },
    { lat: 48.751824, lng: 17.987658 },
    { lat: 48.751753, lng: 17.987492 },
    { lat: 48.751683, lng: 17.987331 },
    { lat: 48.751664, lng: 17.987351 },
    { lat: 48.751567, lng: 17.987068 },
    { lat: 48.75144, lng: 17.986693 },
    { lat: 48.751361, lng: 17.98646 },
    { lat: 48.751229, lng: 17.986075 },
    { lat: 48.751113, lng: 17.985742 },
    { lat: 48.751004, lng: 17.985448 },
    { lat: 48.750915, lng: 17.9852 },
    { lat: 48.750747, lng: 17.984746 },
    { lat: 48.750602, lng: 17.984359 },
    { lat: 48.750446, lng: 17.983873 },
    { lat: 48.75037, lng: 17.983649 },
    { lat: 48.750403, lng: 17.983628 },
    { lat: 48.750919, lng: 17.9833 },
    { lat: 48.751239, lng: 17.9831 },
    { lat: 48.751746, lng: 17.982792 },
    { lat: 48.75223, lng: 17.982499 },
    { lat: 48.752768, lng: 17.982176 },
    { lat: 48.753133, lng: 17.98196 },
    { lat: 48.753536, lng: 17.981722 },
    { lat: 48.753772, lng: 17.981576 },
    { lat: 48.7541, lng: 17.981362 },
    { lat: 48.754388, lng: 17.981175 },
    { lat: 48.754789, lng: 17.98092 },
    { lat: 48.755242, lng: 17.980634 },
    { lat: 48.75575, lng: 17.980307 },
    { lat: 48.756276, lng: 17.979982 },
    { lat: 48.756757, lng: 17.979698 },
    { lat: 48.757244, lng: 17.979411 },
    { lat: 48.75768, lng: 17.979152 },
    { lat: 48.758035, lng: 17.978952 },
    { lat: 48.758255, lng: 17.978824 },
    { lat: 48.758525, lng: 17.978669 },
    { lat: 48.758795, lng: 17.978479 },
    { lat: 48.759112, lng: 17.978257 },
    { lat: 48.759493, lng: 17.977992 },
    { lat: 48.759869, lng: 17.97773 },
    { lat: 48.760133, lng: 17.977543 },
    { lat: 48.760324, lng: 17.977408 },
    { lat: 48.76058, lng: 17.977224 },
    { lat: 48.760741, lng: 17.977115 },
    { lat: 48.761182, lng: 17.976806 },
    { lat: 48.761422, lng: 17.976626 },
    { lat: 48.761822, lng: 17.976355 },
    { lat: 48.762337, lng: 17.976012 },
    { lat: 48.762676, lng: 17.975783 },
    { lat: 48.762943, lng: 17.975611 },
    { lat: 48.763435, lng: 17.97528 },
    { lat: 48.763823, lng: 17.975014 },
    { lat: 48.764176, lng: 17.974761 },
    { lat: 48.764514, lng: 17.974511 },
    { lat: 48.764708, lng: 17.974367 },
    { lat: 48.765321, lng: 17.973908 },
    { lat: 48.765778, lng: 17.973626 },
    { lat: 48.766153, lng: 17.9734 },
    { lat: 48.766431, lng: 17.973204 },
    { lat: 48.766712, lng: 17.973014 },
    { lat: 48.766869, lng: 17.972891 },
    { lat: 48.767039, lng: 17.972758 },
    { lat: 48.767118, lng: 17.972696 },
    { lat: 48.767277, lng: 17.972584 },
    { lat: 48.767459, lng: 17.972453 },
    { lat: 48.767575, lng: 17.972368 },
    { lat: 48.767755, lng: 17.972239 },
    { lat: 48.767917, lng: 17.972122 },
    { lat: 48.768099, lng: 17.971993 },
    { lat: 48.768226, lng: 17.9719 },
    { lat: 48.768395, lng: 17.971775 },
    { lat: 48.768519, lng: 17.97169 },
    { lat: 48.768579, lng: 17.971649 },
    { lat: 48.768731, lng: 17.971552 },
    { lat: 48.768882, lng: 17.971455 },
    { lat: 48.769058, lng: 17.971338 },
    { lat: 48.769151, lng: 17.971271 },
    { lat: 48.769292, lng: 17.971171 },
    { lat: 48.769438, lng: 17.971069 },
    { lat: 48.769491, lng: 17.971032 },
    { lat: 48.769597, lng: 17.97096 },
    { lat: 48.769724, lng: 17.97087 },
    { lat: 48.769859, lng: 17.970775 },
    { lat: 48.769977, lng: 17.970689 },
    { lat: 48.770112, lng: 17.970595 },
    { lat: 48.770203, lng: 17.970532 },
    { lat: 48.770264, lng: 17.970492 },
    { lat: 48.770331, lng: 17.970443 },
    { lat: 48.770367, lng: 17.970415 },
    { lat: 48.770412, lng: 17.970384 },
    { lat: 48.770484, lng: 17.970335 },
    { lat: 48.770591, lng: 17.97026 },
    { lat: 48.770682, lng: 17.970198 },
    { lat: 48.770782, lng: 17.970132 },
    { lat: 48.770815, lng: 17.970106 },
    { lat: 48.770893, lng: 17.970051 },
    { lat: 48.770992, lng: 17.969985 },
    { lat: 48.771031, lng: 17.969956 },
    { lat: 48.771145, lng: 17.969877 },
    { lat: 48.771191, lng: 17.969846 },
    { lat: 48.771243, lng: 17.96981 },
    { lat: 48.771335, lng: 17.969747 },
    { lat: 48.771466, lng: 17.969656 },
    { lat: 48.77161, lng: 17.969557 },
    { lat: 48.771746, lng: 17.969464 },
    { lat: 48.771893, lng: 17.969365 },
    { lat: 48.772081, lng: 17.969234 },
    { lat: 48.772269, lng: 17.969103 },
    { lat: 48.772361, lng: 17.96904 },
    { lat: 48.772485, lng: 17.968953 },
    { lat: 48.772628, lng: 17.968854 },
    { lat: 48.772777, lng: 17.968751 },
    { lat: 48.772857, lng: 17.968695 },
    { lat: 48.773021, lng: 17.968582 },
    { lat: 48.773073, lng: 17.968544 },
    { lat: 48.773194, lng: 17.968461 },
    { lat: 48.773287, lng: 17.968395 },
    { lat: 48.773422, lng: 17.968302 },
    { lat: 48.773536, lng: 17.968222 },
    { lat: 48.773672, lng: 17.96813 },
    { lat: 48.773725, lng: 17.968095 },
    { lat: 48.773853, lng: 17.968006 },
    { lat: 48.774008, lng: 17.967899 },
    { lat: 48.774353, lng: 17.967656 },
    { lat: 48.774451, lng: 17.967587 },
    { lat: 48.774701, lng: 17.967416 },
    { lat: 48.774939, lng: 17.967252 },
    { lat: 48.775149, lng: 17.967085 },
    { lat: 48.775379, lng: 17.966914 },
    { lat: 48.775463, lng: 17.966856 },
    { lat: 48.775653, lng: 17.966729 },
    { lat: 48.775813, lng: 17.966625 },
    { lat: 48.776013, lng: 17.966485 },
    { lat: 48.7761549, lng: 17.9663951 },
    { lat: 48.7756673, lng: 17.9648214 },
    { lat: 48.7751227, lng: 17.9635216 },
    { lat: 48.7744337, lng: 17.9615432 },
    { lat: 48.7739186, lng: 17.9599809 },
    { lat: 48.7734818, lng: 17.9587114 },
    { lat: 48.7729368, lng: 17.9571619 },
    { lat: 48.7723542, lng: 17.9555482 },
    { lat: 48.7716938, lng: 17.9537479 },
    { lat: 48.7710332, lng: 17.9519242 },
    { lat: 48.7701222, lng: 17.9494629 },
    { lat: 48.7697969, lng: 17.9484943 },
    { lat: 48.7697613, lng: 17.9483898 },
    { lat: 48.7696885, lng: 17.9481713 },
    { lat: 48.7696695, lng: 17.9481172 },
    { lat: 48.769631, lng: 17.9480024 },
    { lat: 48.7689718, lng: 17.9460921 },
    { lat: 48.7679231, lng: 17.9431968 },
    { lat: 48.7683894, lng: 17.9433311 },
    { lat: 48.7685264, lng: 17.9434892 },
    { lat: 48.768595, lng: 17.9438256 },
    { lat: 48.7687804, lng: 17.9441695 },
    { lat: 48.769143, lng: 17.9449704 },
    { lat: 48.7692363, lng: 17.9456053 },
    { lat: 48.7692761, lng: 17.9458771 },
    { lat: 48.769341, lng: 17.9460737 },
    { lat: 48.7694471, lng: 17.9463955 },
    { lat: 48.7695411, lng: 17.9465517 },
    { lat: 48.7696239, lng: 17.9466913 },
    { lat: 48.7696489, lng: 17.9467317 },
    { lat: 48.7697304, lng: 17.9468683 },
    { lat: 48.7694462, lng: 17.9453776 },
    { lat: 48.7693787, lng: 17.9448541 },
    { lat: 48.7693205, lng: 17.9444074 },
    { lat: 48.7694881, lng: 17.9441323 },
    { lat: 48.7699127, lng: 17.944551 },
    { lat: 48.770236, lng: 17.9448808 },
    { lat: 48.7709713, lng: 17.9459488 },
    { lat: 48.7710237, lng: 17.9459181 },
    { lat: 48.7714148, lng: 17.9467415 },
    { lat: 48.771648, lng: 17.9471494 },
    { lat: 48.7719064, lng: 17.9476033 },
    { lat: 48.7719916, lng: 17.9478017 },
    { lat: 48.7723737, lng: 17.9486055 },
    { lat: 48.7726936, lng: 17.9498618 },
    { lat: 48.773375, lng: 17.9501206 },
    { lat: 48.7734399, lng: 17.9500933 },
    { lat: 48.7735158, lng: 17.9500613 },
    { lat: 48.7738804, lng: 17.9499077 },
    { lat: 48.7740048, lng: 17.9498557 },
    { lat: 48.7740283, lng: 17.9498525 },
    { lat: 48.7741002, lng: 17.9497527 },
    { lat: 48.7741121, lng: 17.9496687 },
    { lat: 48.7741708, lng: 17.9495924 },
    { lat: 48.7742564, lng: 17.9495139 },
    { lat: 48.7743092, lng: 17.949263 },
    { lat: 48.7743556, lng: 17.9491717 },
    { lat: 48.7744445, lng: 17.9490909 },
    { lat: 48.7745338, lng: 17.9489983 },
    { lat: 48.774583, lng: 17.9489152 },
    { lat: 48.7746937, lng: 17.9488012 },
    { lat: 48.774758, lng: 17.9486698 },
    { lat: 48.7748542, lng: 17.948579 },
    { lat: 48.7748853, lng: 17.9485145 },
    { lat: 48.7749561, lng: 17.9483951 },
    { lat: 48.775019, lng: 17.9482581 },
    { lat: 48.7750532, lng: 17.9481492 },
    { lat: 48.775059, lng: 17.9480967 },
    { lat: 48.7750744, lng: 17.9480503 },
    { lat: 48.7751546, lng: 17.947921 },
    { lat: 48.7753644, lng: 17.9478791 },
    { lat: 48.7753868, lng: 17.9478752 },
    { lat: 48.7754702, lng: 17.9474949 },
    { lat: 48.7755136, lng: 17.9473909 },
    { lat: 48.7756224, lng: 17.9471139 },
    { lat: 48.7757746, lng: 17.9468852 },
    { lat: 48.7759093, lng: 17.9466272 },
    { lat: 48.7760775, lng: 17.9463412 },
    { lat: 48.7761935, lng: 17.9460546 },
    { lat: 48.7762875, lng: 17.9458698 },
    { lat: 48.7763867, lng: 17.9457167 },
    { lat: 48.7763789, lng: 17.945699 },
    { lat: 48.7763694, lng: 17.9456929 },
    { lat: 48.7764757, lng: 17.9455056 },
    { lat: 48.7765223, lng: 17.9454172 },
    { lat: 48.7765977, lng: 17.9452232 },
    { lat: 48.7766015, lng: 17.9451745 },
    { lat: 48.7766926, lng: 17.9449234 },
    { lat: 48.7767595, lng: 17.9447759 },
    { lat: 48.7768027, lng: 17.9444417 },
    { lat: 48.7768025, lng: 17.9443427 },
    { lat: 48.7768183, lng: 17.9442491 },
    { lat: 48.7768403, lng: 17.9439187 },
    { lat: 48.7768661, lng: 17.9437648 },
    { lat: 48.776891, lng: 17.9437657 },
    { lat: 48.7773734, lng: 17.9438662 },
    { lat: 48.7772519, lng: 17.9435323 },
    { lat: 48.7769416, lng: 17.9426838 },
    { lat: 48.7766417, lng: 17.9417444 },
    { lat: 48.77665, lng: 17.9416373 },
    { lat: 48.7766616, lng: 17.9414912 },
    { lat: 48.7766685, lng: 17.9413991 },
    { lat: 48.7767082, lng: 17.9408909 },
    { lat: 48.7765578, lng: 17.9408311 },
    { lat: 48.7759786, lng: 17.9401491 },
    { lat: 48.7758637, lng: 17.9400179 },
    { lat: 48.7755875, lng: 17.939703 },
    { lat: 48.7753377, lng: 17.9395459 },
    { lat: 48.7751382, lng: 17.9394203 },
    { lat: 48.7752657, lng: 17.9383791 },
    { lat: 48.7755642, lng: 17.9383681 },
    { lat: 48.7757662, lng: 17.9382927 },
    { lat: 48.7760389, lng: 17.938436 },
    { lat: 48.7761009, lng: 17.9384536 },
    { lat: 48.776384, lng: 17.9383746 },
    { lat: 48.7766576, lng: 17.9382105 },
    { lat: 48.7770689, lng: 17.9379177 },
    { lat: 48.7774965, lng: 17.9375928 },
    { lat: 48.7778007, lng: 17.937395 },
    { lat: 48.7786442, lng: 17.9369264 },
    { lat: 48.7788608, lng: 17.9369062 },
    { lat: 48.7786177, lng: 17.9358601 },
    { lat: 48.7785209, lng: 17.9356572 },
    { lat: 48.7783807, lng: 17.9355201 },
    { lat: 48.77833, lng: 17.9352393 },
    { lat: 48.7772249, lng: 17.93556 },
    { lat: 48.7767262, lng: 17.9359033 },
    { lat: 48.7763676, lng: 17.9361166 },
    { lat: 48.7761283, lng: 17.9363227 },
    { lat: 48.7758078, lng: 17.9366086 },
    { lat: 48.7754419, lng: 17.9367369 },
    { lat: 48.7750984, lng: 17.9367882 },
    { lat: 48.77503, lng: 17.9364935 },
    { lat: 48.7751527, lng: 17.9356007 },
    { lat: 48.7753708, lng: 17.9355102 },
    { lat: 48.7754951, lng: 17.9355111 },
    { lat: 48.7759161, lng: 17.9352862 },
    { lat: 48.7761313, lng: 17.9351662 },
    { lat: 48.776375, lng: 17.9350307 },
    { lat: 48.776357, lng: 17.93492 },
    { lat: 48.776879, lng: 17.9345877 },
    { lat: 48.7770693, lng: 17.9344865 },
    { lat: 48.7770849, lng: 17.934388 },
    { lat: 48.7774588, lng: 17.934298 },
    { lat: 48.7779125, lng: 17.9341834 },
    { lat: 48.7779213, lng: 17.9343299 },
    { lat: 48.7784085, lng: 17.9341732 },
    { lat: 48.7788053, lng: 17.9340142 },
    { lat: 48.7791721, lng: 17.9337296 },
    { lat: 48.7792318, lng: 17.933499 },
    { lat: 48.7794767, lng: 17.933179 },
    { lat: 48.7797204, lng: 17.9329509 },
    { lat: 48.7798365, lng: 17.9326646 },
    { lat: 48.7798562, lng: 17.9324848 },
    { lat: 48.7798667, lng: 17.9323949 },
    { lat: 48.7796225, lng: 17.9318989 },
    { lat: 48.7797676, lng: 17.9315871 },
    { lat: 48.7802159, lng: 17.9309103 },
    { lat: 48.780131, lng: 17.9307568 },
    { lat: 48.7800449, lng: 17.9306011 },
    { lat: 48.7795621, lng: 17.9308683 },
    { lat: 48.7794009, lng: 17.9309852 },
    { lat: 48.7792126, lng: 17.9311211 },
    { lat: 48.7791766, lng: 17.9308592 },
    { lat: 48.779192, lng: 17.9304375 },
    { lat: 48.7792175, lng: 17.9302233 },
    { lat: 48.7792668, lng: 17.9298188 },
    { lat: 48.7793433, lng: 17.9293432 },
    { lat: 48.7793811, lng: 17.9289376 },
    { lat: 48.7791885, lng: 17.9288866 },
    { lat: 48.7787331, lng: 17.9287658 },
    { lat: 48.7779802, lng: 17.9282755 },
    { lat: 48.7780199, lng: 17.9282235 },
    { lat: 48.7783162, lng: 17.9278349 },
    { lat: 48.7783071, lng: 17.9278143 },
    { lat: 48.7782147, lng: 17.9276276 },
    { lat: 48.7781171, lng: 17.9274315 },
    { lat: 48.7779693, lng: 17.9271323 },
    { lat: 48.7771927, lng: 17.927471 },
    { lat: 48.777145, lng: 17.9270309 },
    { lat: 48.7771313, lng: 17.9268969 },
    { lat: 48.7771115, lng: 17.9267169 },
    { lat: 48.7770953, lng: 17.926559 },
    { lat: 48.7770558, lng: 17.9262015 },
    { lat: 48.7770549, lng: 17.9261859 },
    { lat: 48.7770045, lng: 17.9258533 },
    { lat: 48.7769487, lng: 17.9254865 },
    { lat: 48.7768645, lng: 17.9249293 },
    { lat: 48.7767581, lng: 17.9249045 },
    { lat: 48.7765062, lng: 17.9248451 },
    { lat: 48.7765891, lng: 17.9234006 },
    { lat: 48.7762687, lng: 17.9234043 },
    { lat: 48.7762812, lng: 17.9233113 },
    { lat: 48.7763004, lng: 17.9231612 },
    { lat: 48.776321, lng: 17.9229958 },
    { lat: 48.7763434, lng: 17.9228229 },
    { lat: 48.776366, lng: 17.9226535 },
    { lat: 48.7764262, lng: 17.9222082 },
    { lat: 48.7764361, lng: 17.9219395 },
    { lat: 48.7764459, lng: 17.9216892 },
    { lat: 48.7762352, lng: 17.9216379 },
    { lat: 48.7760943, lng: 17.9216034 },
    { lat: 48.7759422, lng: 17.9215333 },
    { lat: 48.7759027, lng: 17.9215153 },
    { lat: 48.7756477, lng: 17.9214597 },
    { lat: 48.7756042, lng: 17.9214498 },
    { lat: 48.7755417, lng: 17.9218948 },
    { lat: 48.7754886, lng: 17.9218564 },
    { lat: 48.7749035, lng: 17.921434 },
    { lat: 48.775274, lng: 17.9208555 },
    { lat: 48.7754326, lng: 17.9200738 },
    { lat: 48.7754928, lng: 17.9194008 },
    { lat: 48.7754729, lng: 17.91939 },
    { lat: 48.7750483, lng: 17.9191603 },
    { lat: 48.7750463, lng: 17.9191269 },
    { lat: 48.7750318, lng: 17.9189026 },
    { lat: 48.7750159, lng: 17.9186771 },
    { lat: 48.7750016, lng: 17.9184567 },
    { lat: 48.774912, lng: 17.91711 },
    { lat: 48.7749102, lng: 17.9170997 },
    { lat: 48.774909, lng: 17.917081 },
    { lat: 48.7747608, lng: 17.9166598 },
    { lat: 48.7745282, lng: 17.9163007 },
    { lat: 48.7741603, lng: 17.9158124 },
    { lat: 48.7741528, lng: 17.9156324 },
    { lat: 48.7743715, lng: 17.9152312 },
    { lat: 48.7743548, lng: 17.9149531 },
    { lat: 48.7743409, lng: 17.914701 },
    { lat: 48.774438, lng: 17.9145139 },
    { lat: 48.7743565, lng: 17.9141912 },
    { lat: 48.7741796, lng: 17.9141206 },
    { lat: 48.7729606, lng: 17.9151371 },
    { lat: 48.7728238, lng: 17.9152285 },
    { lat: 48.7723136, lng: 17.915512 },
    { lat: 48.772146, lng: 17.9154466 },
    { lat: 48.7720825, lng: 17.915422 },
    { lat: 48.7719588, lng: 17.9153737 },
    { lat: 48.7719011, lng: 17.9153512 },
    { lat: 48.7718421, lng: 17.9153282 },
    { lat: 48.7717843, lng: 17.9153053 },
    { lat: 48.7717138, lng: 17.915278 },
    { lat: 48.7718232, lng: 17.9147113 },
    { lat: 48.7718502, lng: 17.9144646 },
    { lat: 48.7718912, lng: 17.9140949 },
    { lat: 48.7718917, lng: 17.9140659 },
    { lat: 48.7710597, lng: 17.9141024 },
    { lat: 48.7708657, lng: 17.9141435 },
    { lat: 48.7707062, lng: 17.9142123 },
    { lat: 48.7701071, lng: 17.9144238 },
    { lat: 48.7699211, lng: 17.9144463 },
    { lat: 48.7697929, lng: 17.914417 },
    { lat: 48.7696139, lng: 17.9142943 },
    { lat: 48.7694668, lng: 17.9141562 },
    { lat: 48.7690201, lng: 17.9135394 },
    { lat: 48.7688753, lng: 17.9133827 },
    { lat: 48.7687165, lng: 17.9132397 },
    { lat: 48.7683756, lng: 17.9130306 },
    { lat: 48.7682291, lng: 17.9129414 },
    { lat: 48.7681145, lng: 17.9128564 },
    { lat: 48.7680609, lng: 17.9127901 },
    { lat: 48.7680239, lng: 17.9127191 },
    { lat: 48.767953, lng: 17.9125725 },
    { lat: 48.7678964, lng: 17.9125133 },
    { lat: 48.767558, lng: 17.9123288 },
    { lat: 48.7668509, lng: 17.9120571 },
    { lat: 48.7666127, lng: 17.9117748 },
    { lat: 48.766544, lng: 17.9115899 },
    { lat: 48.7664073, lng: 17.9114566 },
    { lat: 48.766333, lng: 17.9112709 },
    { lat: 48.766289, lng: 17.9110835 },
    { lat: 48.7662689, lng: 17.910785 },
    { lat: 48.7662305, lng: 17.9107288 },
    { lat: 48.7656587, lng: 17.9108488 },
    { lat: 48.7654477, lng: 17.9109264 },
    { lat: 48.7653546, lng: 17.9109921 },
    { lat: 48.7653442, lng: 17.9109508 },
    { lat: 48.7653342, lng: 17.9109171 },
    { lat: 48.7653123, lng: 17.910833 },
    { lat: 48.7652923, lng: 17.9107624 },
    { lat: 48.7651403, lng: 17.9108248 },
    { lat: 48.7651358, lng: 17.910807 },
    { lat: 48.7650602, lng: 17.910527 },
    { lat: 48.7649566, lng: 17.9101546 },
    { lat: 48.764931, lng: 17.9100651 },
    { lat: 48.764904, lng: 17.909974 },
    { lat: 48.7648795, lng: 17.9098843 },
    { lat: 48.7648537, lng: 17.9097934 },
    { lat: 48.7640087, lng: 17.9103282 },
    { lat: 48.7639825, lng: 17.9103432 },
    { lat: 48.7633532, lng: 17.9107098 },
    { lat: 48.7627559, lng: 17.9110644 },
    { lat: 48.7620701, lng: 17.911468 },
    { lat: 48.7620592, lng: 17.9114738 },
    { lat: 48.7620494, lng: 17.9114798 },
    { lat: 48.7620374, lng: 17.9114867 },
    { lat: 48.7620014, lng: 17.9115082 },
    { lat: 48.7619926, lng: 17.9115112 },
    { lat: 48.761986, lng: 17.9115134 },
    { lat: 48.7619782, lng: 17.9115161 },
    { lat: 48.7618422, lng: 17.9115634 },
    { lat: 48.7618079, lng: 17.9115754 },
    { lat: 48.7617415, lng: 17.9115981 },
    { lat: 48.7617096, lng: 17.911612 },
    { lat: 48.761593, lng: 17.9116616 },
    { lat: 48.7611428, lng: 17.911854 },
    { lat: 48.7607237, lng: 17.9120358 },
    { lat: 48.7606441, lng: 17.9120074 },
    { lat: 48.7605807, lng: 17.9120238 },
    { lat: 48.7603095, lng: 17.9120943 },
    { lat: 48.7603007, lng: 17.9120968 },
    { lat: 48.7602918, lng: 17.9120989 },
    { lat: 48.7601586, lng: 17.9125146 },
    { lat: 48.7600386, lng: 17.9126771 },
    { lat: 48.759819, lng: 17.9127975 },
    { lat: 48.759687, lng: 17.9127995 },
    { lat: 48.7596498, lng: 17.9128004 },
    { lat: 48.7595359, lng: 17.9128019 },
    { lat: 48.7592513, lng: 17.9127236 },
    { lat: 48.759018, lng: 17.9126361 },
    { lat: 48.7589004, lng: 17.9126138 },
    { lat: 48.7588639, lng: 17.9126068 },
    { lat: 48.7583147, lng: 17.9125035 },
    { lat: 48.7581385, lng: 17.9124831 },
    { lat: 48.7580749, lng: 17.9124761 },
    { lat: 48.7576965, lng: 17.9124329 },
    { lat: 48.7573478, lng: 17.9124739 },
    { lat: 48.7569193, lng: 17.9125749 },
    { lat: 48.756851, lng: 17.9125839 },
    { lat: 48.7568106, lng: 17.9125889 },
    { lat: 48.7567434, lng: 17.9125978 },
    { lat: 48.7565145, lng: 17.9126206 },
    { lat: 48.7563245, lng: 17.9125959 },
    { lat: 48.7561189, lng: 17.9125354 },
    { lat: 48.7560787, lng: 17.912561 },
    { lat: 48.7560786, lng: 17.9125794 },
    { lat: 48.7560485, lng: 17.9134319 },
    { lat: 48.7560482, lng: 17.9134648 },
    { lat: 48.7562964, lng: 17.9147798 },
    { lat: 48.7563154, lng: 17.9150414 },
    { lat: 48.7562902, lng: 17.9152792 },
    { lat: 48.7562093, lng: 17.9156249 },
    { lat: 48.7562063, lng: 17.916082 },
    { lat: 48.7561183, lng: 17.9167219 },
    { lat: 48.7560399, lng: 17.9175812 },
    { lat: 48.7560034, lng: 17.9177432 },
    { lat: 48.7558867, lng: 17.9181697 },
    { lat: 48.755807, lng: 17.9183484 },
    { lat: 48.755671, lng: 17.9185633 },
    { lat: 48.7555814, lng: 17.9185756 },
    { lat: 48.7553668, lng: 17.9183852 },
    { lat: 48.7550754, lng: 17.9180436 },
    { lat: 48.7549263, lng: 17.917948 },
    { lat: 48.7549174, lng: 17.9179685 },
    { lat: 48.7549144, lng: 17.9179748 },
    { lat: 48.7548767, lng: 17.9180415 },
    { lat: 48.7547747, lng: 17.9182249 },
    { lat: 48.754354, lng: 17.9187374 },
    { lat: 48.7540465, lng: 17.918993 },
    { lat: 48.7538203, lng: 17.9191184 },
    { lat: 48.7536283, lng: 17.9190401 },
    { lat: 48.7524949, lng: 17.9196215 },
    { lat: 48.7525384, lng: 17.9198404 },
    { lat: 48.7525282, lng: 17.9201598 },
    { lat: 48.7523996, lng: 17.9205383 },
    { lat: 48.7518051, lng: 17.9209211 },
    { lat: 48.7518125, lng: 17.9209876 },
    { lat: 48.7514552, lng: 17.9214876 },
    { lat: 48.750919, lng: 17.9216544 },
    { lat: 48.7503067, lng: 17.9217034 },
    { lat: 48.750308, lng: 17.9223655 },
    { lat: 48.7503634, lng: 17.9227621 },
    { lat: 48.7504019, lng: 17.9230282 },
    { lat: 48.7501939, lng: 17.9233618 },
    { lat: 48.7499881, lng: 17.9236584 },
    { lat: 48.7497918, lng: 17.9240365 },
    { lat: 48.7494486, lng: 17.9246963 },
    { lat: 48.7494606, lng: 17.9247088 },
    { lat: 48.7491004, lng: 17.9254591 },
    { lat: 48.7489414, lng: 17.9255185 },
    { lat: 48.7485383, lng: 17.9256475 },
    { lat: 48.748234, lng: 17.9258253 },
    { lat: 48.7481389, lng: 17.9255384 },
    { lat: 48.7473038, lng: 17.9257676 },
    { lat: 48.7468998, lng: 17.9261641 },
    { lat: 48.7468536, lng: 17.9265038 },
    { lat: 48.7465708, lng: 17.9271164 },
    { lat: 48.7464656, lng: 17.9272456 },
    { lat: 48.745883, lng: 17.9279407 },
    { lat: 48.7459126, lng: 17.9280207 },
    { lat: 48.7459709, lng: 17.9281817 },
    { lat: 48.746084, lng: 17.9280539 },
    { lat: 48.7464631, lng: 17.9278638 },
    { lat: 48.746546, lng: 17.9278533 },
    { lat: 48.7466484, lng: 17.9278095 },
    { lat: 48.7467838, lng: 17.9277355 },
    { lat: 48.746869, lng: 17.9276478 },
    { lat: 48.7469966, lng: 17.927461 },
    { lat: 48.7471793, lng: 17.9272507 },
    { lat: 48.747323, lng: 17.9271615 },
    { lat: 48.7474905, lng: 17.9271126 },
    { lat: 48.7476212, lng: 17.9270516 },
    { lat: 48.7477351, lng: 17.9269749 },
    { lat: 48.7478661, lng: 17.926882 },
    { lat: 48.7484225, lng: 17.9265411 },
    { lat: 48.7484738, lng: 17.926532 },
    { lat: 48.7486862, lng: 17.9264603 },
    { lat: 48.74885, lng: 17.9263107 },
    { lat: 48.7490296, lng: 17.9262178 },
    { lat: 48.7490777, lng: 17.9262679 },
    { lat: 48.7493911, lng: 17.9262604 },
    { lat: 48.7500355, lng: 17.9261266 },
    { lat: 48.7501892, lng: 17.9260168 },
    { lat: 48.7503146, lng: 17.9259628 },
    { lat: 48.7504623, lng: 17.9262606 },
    { lat: 48.7506149, lng: 17.9267748 },
    { lat: 48.7507955, lng: 17.9276595 },
    { lat: 48.7509594, lng: 17.9281162 },
    { lat: 48.750856, lng: 17.9284253 },
    { lat: 48.7506459, lng: 17.9287618 },
    { lat: 48.749792, lng: 17.930186 },
    { lat: 48.7495966, lng: 17.9302044 },
    { lat: 48.7494414, lng: 17.9298914 },
    { lat: 48.7492134, lng: 17.9300613 },
    { lat: 48.7490673, lng: 17.9303553 },
    { lat: 48.7485765, lng: 17.9311325 },
    { lat: 48.7481252, lng: 17.9317388 },
    { lat: 48.7480943, lng: 17.9321672 },
    { lat: 48.748136, lng: 17.9325424 },
    { lat: 48.7482966, lng: 17.9328679 },
    { lat: 48.7483071, lng: 17.932893 },
    { lat: 48.7482364, lng: 17.9329563 },
    { lat: 48.7480177, lng: 17.9330375 },
    { lat: 48.7476769, lng: 17.9331126 },
    { lat: 48.7474498, lng: 17.9331851 },
    { lat: 48.7472389, lng: 17.9332079 },
    { lat: 48.7470766, lng: 17.9332873 },
    { lat: 48.7467843, lng: 17.9334959 },
    { lat: 48.7464829, lng: 17.9338734 },
    { lat: 48.7463841, lng: 17.9339564 },
    { lat: 48.7461606, lng: 17.9340698 },
    { lat: 48.7459785, lng: 17.9341582 },
    { lat: 48.7458552, lng: 17.9342301 },
    { lat: 48.7455421, lng: 17.9344863 },
    { lat: 48.7454338, lng: 17.9346014 },
    { lat: 48.745324, lng: 17.9346706 },
    { lat: 48.7450995, lng: 17.9347678 },
    { lat: 48.7450302, lng: 17.9347598 },
    { lat: 48.7449504, lng: 17.9347636 },
    { lat: 48.7447966, lng: 17.9347794 },
    { lat: 48.7445234, lng: 17.934742 },
    { lat: 48.7444909, lng: 17.9347452 },
    { lat: 48.7443905, lng: 17.9347825 },
    { lat: 48.744269, lng: 17.9348285 },
    { lat: 48.7438936, lng: 17.9349682 },
    { lat: 48.7436724, lng: 17.9350061 },
    { lat: 48.7435548, lng: 17.9349846 },
    { lat: 48.7433288, lng: 17.9348862 },
    { lat: 48.7432532, lng: 17.9349046 },
    { lat: 48.7430158, lng: 17.9351061 },
    { lat: 48.7427844, lng: 17.935351 },
    { lat: 48.7426743, lng: 17.9353968 },
    { lat: 48.7425504, lng: 17.9354399 },
    { lat: 48.7424789, lng: 17.9354522 },
    { lat: 48.742372, lng: 17.9354591 },
    { lat: 48.7421964, lng: 17.9353529 },
    { lat: 48.7420232, lng: 17.93527 },
    { lat: 48.741836, lng: 17.9351402 },
    { lat: 48.7417542, lng: 17.9350929 },
    { lat: 48.7415132, lng: 17.9350458 },
    { lat: 48.7413389, lng: 17.9350185 },
    { lat: 48.7412789, lng: 17.9350163 },
    { lat: 48.7410941, lng: 17.9350207 },
    { lat: 48.7410319, lng: 17.935019 },
    { lat: 48.7407795, lng: 17.9350091 },
    { lat: 48.7407059, lng: 17.9349859 },
    { lat: 48.7406575, lng: 17.9349677 },
    { lat: 48.7404646, lng: 17.9348952 },
    { lat: 48.7403633, lng: 17.9348626 },
    { lat: 48.7402072, lng: 17.934819 },
    { lat: 48.7401283, lng: 17.9348033 },
    { lat: 48.739868, lng: 17.9347899 },
    { lat: 48.7397957, lng: 17.9347894 },
    { lat: 48.7396881, lng: 17.9348017 },
    { lat: 48.7396057, lng: 17.9348202 },
    { lat: 48.7395292, lng: 17.9348443 },
    { lat: 48.7394752, lng: 17.9348667 },
    { lat: 48.7392258, lng: 17.9349805 },
    { lat: 48.7391938, lng: 17.9349926 },
    { lat: 48.7390771, lng: 17.9350222 },
    { lat: 48.7389709, lng: 17.935058 },
    { lat: 48.7388539, lng: 17.935124 },
    { lat: 48.7385601, lng: 17.9353246 },
    { lat: 48.7385236, lng: 17.9353576 },
    { lat: 48.7384492, lng: 17.9354509 },
    { lat: 48.7383738, lng: 17.9355489 },
    { lat: 48.7380156, lng: 17.9360543 },
    { lat: 48.7378773, lng: 17.9362511 },
    { lat: 48.7377768, lng: 17.9364009 },
    { lat: 48.7374781, lng: 17.9367484 },
    { lat: 48.7374358, lng: 17.9367772 },
  ],
  HôrkanadVáhom: [
    { lat: 48.705701, lng: 17.990918 },
    { lat: 48.706391, lng: 17.991364 },
    { lat: 48.706672, lng: 17.991942 },
    { lat: 48.706926, lng: 17.992567 },
    { lat: 48.707293, lng: 17.993581 },
    { lat: 48.707464, lng: 17.994356 },
    { lat: 48.70765, lng: 17.994724 },
    { lat: 48.707886, lng: 17.99516 },
    { lat: 48.70801, lng: 17.995335 },
    { lat: 48.708163, lng: 17.995499 },
    { lat: 48.708251, lng: 17.995567 },
    { lat: 48.708423, lng: 17.995887 },
    { lat: 48.708482, lng: 17.996285 },
    { lat: 48.708567, lng: 17.996431 },
    { lat: 48.708798, lng: 17.996689 },
    { lat: 48.708979, lng: 17.99682 },
    { lat: 48.70915, lng: 17.997157 },
    { lat: 48.709322, lng: 17.997372 },
    { lat: 48.709634, lng: 17.997834 },
    { lat: 48.71014, lng: 17.998266 },
    { lat: 48.710228, lng: 17.998397 },
    { lat: 48.71025, lng: 17.998431 },
    { lat: 48.710632, lng: 17.999006 },
    { lat: 48.710775, lng: 17.999279 },
    { lat: 48.710942, lng: 17.999555 },
    { lat: 48.711247, lng: 18.000267 },
    { lat: 48.711415, lng: 18.000611 },
    { lat: 48.711613, lng: 18.001385 },
    { lat: 48.711873, lng: 18.002346 },
    { lat: 48.711976, lng: 18.002598 },
    { lat: 48.712192, lng: 18.002996 },
    { lat: 48.712729, lng: 18.003687 },
    { lat: 48.712937, lng: 18.003875 },
    { lat: 48.712983, lng: 18.0039984 },
    { lat: 48.713175, lng: 18.004349 },
    { lat: 48.713475, lng: 18.005524 },
    { lat: 48.714938, lng: 18.005928 },
    { lat: 48.714946, lng: 18.005929 },
    { lat: 48.715179, lng: 18.005964 },
    { lat: 48.715304, lng: 18.006045 },
    { lat: 48.715762, lng: 18.005935 },
    { lat: 48.716071, lng: 18.005645 },
    { lat: 48.716151, lng: 18.005591 },
    { lat: 48.716382, lng: 18.005491 },
    { lat: 48.716521, lng: 18.005462 },
    { lat: 48.716711, lng: 18.005397 },
    { lat: 48.716965, lng: 18.005338 },
    { lat: 48.717111, lng: 18.005238 },
    { lat: 48.717318, lng: 18.004865 },
    { lat: 48.717463, lng: 18.00477 },
    { lat: 48.717677, lng: 18.004579 },
    { lat: 48.717949, lng: 18.004117 },
    { lat: 48.717961, lng: 18.004147 },
    { lat: 48.718364, lng: 18.003747 },
    { lat: 48.718503, lng: 18.003533 },
    { lat: 48.718745, lng: 18.003229 },
    { lat: 48.719036, lng: 18.002761 },
    { lat: 48.719324, lng: 18.002301 },
    { lat: 48.719416, lng: 18.002076 },
    { lat: 48.719628, lng: 18.001674 },
    { lat: 48.719831, lng: 18.001369 },
    { lat: 48.71996, lng: 18.001128 },
    { lat: 48.72029, lng: 18.000684 },
    { lat: 48.720589, lng: 18.000144 },
    { lat: 48.720817, lng: 17.999868 },
    { lat: 48.721046, lng: 17.999504 },
    { lat: 48.721256, lng: 17.999309 },
    { lat: 48.72168, lng: 17.999099 },
    { lat: 48.721823, lng: 17.998965 },
    { lat: 48.721868, lng: 17.998878 },
    { lat: 48.722003, lng: 17.998621 },
    { lat: 48.722541, lng: 17.998359 },
    { lat: 48.722774, lng: 17.998237 },
    { lat: 48.722806, lng: 17.998215 },
    { lat: 48.7226584, lng: 17.9979476 },
    { lat: 48.7225179, lng: 17.997578 },
    { lat: 48.7224509, lng: 17.9972456 },
    { lat: 48.7223821, lng: 17.9970765 },
    { lat: 48.7223341, lng: 17.9967039 },
    { lat: 48.7222805, lng: 17.9964484 },
    { lat: 48.7220975, lng: 17.9958348 },
    { lat: 48.7220289, lng: 17.9955551 },
    { lat: 48.721985, lng: 17.9953281 },
    { lat: 48.7220341, lng: 17.9948446 },
    { lat: 48.7220663, lng: 17.9944163 },
    { lat: 48.7220628, lng: 17.9941848 },
    { lat: 48.7220912, lng: 17.9939981 },
    { lat: 48.7221722, lng: 17.9937274 },
    { lat: 48.7222246, lng: 17.993278 },
    { lat: 48.7222016, lng: 17.9928906 },
    { lat: 48.7221282, lng: 17.9924705 },
    { lat: 48.7219808, lng: 17.9918517 },
    { lat: 48.7219222, lng: 17.9914728 },
    { lat: 48.7219194, lng: 17.9914067 },
    { lat: 48.7218365, lng: 17.9908648 },
    { lat: 48.7217794, lng: 17.9904554 },
    { lat: 48.7218046, lng: 17.989663 },
    { lat: 48.7217304, lng: 17.9892685 },
    { lat: 48.7217115, lng: 17.9887037 },
    { lat: 48.721724, lng: 17.9884949 },
    { lat: 48.721757, lng: 17.9881369 },
    { lat: 48.7217791, lng: 17.9878063 },
    { lat: 48.7217836, lng: 17.9877109 },
    { lat: 48.7217785, lng: 17.9874358 },
    { lat: 48.7217982, lng: 17.9872366 },
    { lat: 48.7218815, lng: 17.9867936 },
    { lat: 48.7217696, lng: 17.9862033 },
    { lat: 48.7216827, lng: 17.9858419 },
    { lat: 48.7216505, lng: 17.9856026 },
    { lat: 48.7215977, lng: 17.9853224 },
    { lat: 48.7215129, lng: 17.9850542 },
    { lat: 48.7214019, lng: 17.9846104 },
    { lat: 48.721338, lng: 17.9843891 },
    { lat: 48.7213075, lng: 17.9841805 },
    { lat: 48.7213057, lng: 17.9838085 },
    { lat: 48.7212881, lng: 17.9835306 },
    { lat: 48.7212241, lng: 17.9833654 },
    { lat: 48.7210529, lng: 17.9831616 },
    { lat: 48.7209574, lng: 17.9829994 },
    { lat: 48.7207382, lng: 17.9828056 },
    { lat: 48.7206359, lng: 17.9826413 },
    { lat: 48.7205891, lng: 17.9824028 },
    { lat: 48.7206224, lng: 17.9819002 },
    { lat: 48.7206314, lng: 17.9815958 },
    { lat: 48.7205831, lng: 17.9812018 },
    { lat: 48.7204239, lng: 17.9806508 },
    { lat: 48.7202641, lng: 17.9802972 },
    { lat: 48.7200727, lng: 17.9798694 },
    { lat: 48.7198678, lng: 17.9792886 },
    { lat: 48.7198151, lng: 17.9789526 },
    { lat: 48.7198022, lng: 17.9786403 },
    { lat: 48.7197586, lng: 17.9783253 },
    { lat: 48.7196205, lng: 17.9778826 },
    { lat: 48.7194462, lng: 17.9775706 },
    { lat: 48.7193614, lng: 17.9771505 },
    { lat: 48.7193481, lng: 17.9766974 },
    { lat: 48.7193979, lng: 17.9765295 },
    { lat: 48.7195564, lng: 17.9763104 },
    { lat: 48.7197196, lng: 17.9760357 },
    { lat: 48.7197688, lng: 17.9758961 },
    { lat: 48.7199077, lng: 17.974834 },
    { lat: 48.7198897, lng: 17.9745499 },
    { lat: 48.7199059, lng: 17.9737992 },
    { lat: 48.7196914, lng: 17.9731151 },
    { lat: 48.7193987, lng: 17.9718374 },
    { lat: 48.719203, lng: 17.9710349 },
    { lat: 48.7192477, lng: 17.9706857 },
    { lat: 48.7193585, lng: 17.9706513 },
    { lat: 48.7199237, lng: 17.970703 },
    { lat: 48.7203149, lng: 17.9706378 },
    { lat: 48.7204986, lng: 17.970672 },
    { lat: 48.721038, lng: 17.9706891 },
    { lat: 48.721177, lng: 17.9706915 },
    { lat: 48.721252, lng: 17.9706818 },
    { lat: 48.7213367, lng: 17.9706435 },
    { lat: 48.7213917, lng: 17.9706185 },
    { lat: 48.7220601, lng: 17.9700342 },
    { lat: 48.7221243, lng: 17.969938 },
    { lat: 48.7221528, lng: 17.9697517 },
    { lat: 48.7221977, lng: 17.9692567 },
    { lat: 48.7222308, lng: 17.9690919 },
    { lat: 48.7223106, lng: 17.9685552 },
    { lat: 48.7223454, lng: 17.9682473 },
    { lat: 48.7223638, lng: 17.967951 },
    { lat: 48.7223971, lng: 17.9677139 },
    { lat: 48.7223863, lng: 17.9675699 },
    { lat: 48.7223038, lng: 17.9672272 },
    { lat: 48.7222735, lng: 17.9670024 },
    { lat: 48.7222615, lng: 17.9667822 },
    { lat: 48.7223224, lng: 17.9664976 },
    { lat: 48.722433, lng: 17.9661549 },
    { lat: 48.722574, lng: 17.9658696 },
    { lat: 48.7226797, lng: 17.9656557 },
    { lat: 48.7229145, lng: 17.9651826 },
    { lat: 48.722827, lng: 17.9649268 },
    { lat: 48.7227882, lng: 17.9645964 },
    { lat: 48.7228494, lng: 17.9640892 },
    { lat: 48.7228419, lng: 17.963754 },
    { lat: 48.7227411, lng: 17.9633132 },
    { lat: 48.7225902, lng: 17.963036 },
    { lat: 48.7222696, lng: 17.9626741 },
    { lat: 48.7219369, lng: 17.9623181 },
    { lat: 48.721691, lng: 17.9619814 },
    { lat: 48.7213678, lng: 17.9616901 },
    { lat: 48.7212638, lng: 17.9614808 },
    { lat: 48.7211938, lng: 17.9611952 },
    { lat: 48.7209801, lng: 17.9604678 },
    { lat: 48.7208934, lng: 17.9600186 },
    { lat: 48.7209175, lng: 17.9597969 },
    { lat: 48.7209769, lng: 17.9596215 },
    { lat: 48.7210451, lng: 17.9594959 },
    { lat: 48.7213494, lng: 17.9591846 },
    { lat: 48.7215655, lng: 17.9590053 },
    { lat: 48.7216498, lng: 17.9589234 },
    { lat: 48.721858, lng: 17.9585904 },
    { lat: 48.7219642, lng: 17.9584406 },
    { lat: 48.7223929, lng: 17.9577761 },
    { lat: 48.7227224, lng: 17.9574127 },
    { lat: 48.7228927, lng: 17.9570147 },
    { lat: 48.722954, lng: 17.9568301 },
    { lat: 48.7231758, lng: 17.956481 },
    { lat: 48.7232863, lng: 17.9563839 },
    { lat: 48.7234588, lng: 17.9563234 },
    { lat: 48.7235975, lng: 17.956246 },
    { lat: 48.7238119, lng: 17.956207 },
    { lat: 48.7239772, lng: 17.9561021 },
    { lat: 48.7241337, lng: 17.956001 },
    { lat: 48.7244098, lng: 17.955748 },
    { lat: 48.7244175, lng: 17.9557069 },
    { lat: 48.7244758, lng: 17.9555672 },
    { lat: 48.7245117, lng: 17.9552415 },
    { lat: 48.724557, lng: 17.9550546 },
    { lat: 48.7245873, lng: 17.9548052 },
    { lat: 48.7244651, lng: 17.9538729 },
    { lat: 48.724287, lng: 17.9530074 },
    { lat: 48.7241316, lng: 17.9524845 },
    { lat: 48.724001, lng: 17.9520933 },
    { lat: 48.723922, lng: 17.9518097 },
    { lat: 48.7237855, lng: 17.9514517 },
    { lat: 48.7236708, lng: 17.9511364 },
    { lat: 48.7236224, lng: 17.951044 },
    { lat: 48.7234725, lng: 17.9507642 },
    { lat: 48.7233974, lng: 17.9507365 },
    { lat: 48.7230915, lng: 17.9505096 },
    { lat: 48.7229916, lng: 17.9503857 },
    { lat: 48.7226772, lng: 17.9499403 },
    { lat: 48.722489, lng: 17.9497173 },
    { lat: 48.7223585, lng: 17.9496115 },
    { lat: 48.7222167, lng: 17.9494299 },
    { lat: 48.7220086, lng: 17.9491219 },
    { lat: 48.721938, lng: 17.9489417 },
    { lat: 48.7218851, lng: 17.948812 },
    { lat: 48.7217206, lng: 17.9485701 },
    { lat: 48.7215037, lng: 17.9482452 },
    { lat: 48.7212182, lng: 17.9477941 },
    { lat: 48.7210341, lng: 17.947545 },
    { lat: 48.7209996, lng: 17.9475156 },
    { lat: 48.720844, lng: 17.9474998 },
    { lat: 48.7207668, lng: 17.9474181 },
    { lat: 48.7207247, lng: 17.947374 },
    { lat: 48.7206272, lng: 17.9472722 },
    { lat: 48.7204458, lng: 17.9469557 },
    { lat: 48.7202542, lng: 17.9466006 },
    { lat: 48.7201874, lng: 17.9464101 },
    { lat: 48.7199722, lng: 17.9460586 },
    { lat: 48.7198331, lng: 17.9455419 },
    { lat: 48.7197423, lng: 17.9453083 },
    { lat: 48.7196767, lng: 17.9451906 },
    { lat: 48.7196046, lng: 17.9450622 },
    { lat: 48.7195615, lng: 17.9449629 },
    { lat: 48.7195085, lng: 17.9448707 },
    { lat: 48.7192753, lng: 17.9445962 },
    { lat: 48.7189971, lng: 17.9443024 },
    { lat: 48.718803, lng: 17.9440757 },
    { lat: 48.7187439, lng: 17.9439745 },
    { lat: 48.7186936, lng: 17.9438146 },
    { lat: 48.718637, lng: 17.9434705 },
    { lat: 48.7186627, lng: 17.9429946 },
    { lat: 48.7187046, lng: 17.9424859 },
    { lat: 48.7187194, lng: 17.9422235 },
    { lat: 48.7187392, lng: 17.9418569 },
    { lat: 48.7187971, lng: 17.9415616 },
    { lat: 48.7188708, lng: 17.9413771 },
    { lat: 48.7189402, lng: 17.9411787 },
    { lat: 48.7189851, lng: 17.9410242 },
    { lat: 48.7190218, lng: 17.9408072 },
    { lat: 48.7190361, lng: 17.9405929 },
    { lat: 48.7190055, lng: 17.9404026 },
    { lat: 48.7189909, lng: 17.9399687 },
    { lat: 48.7189631, lng: 17.9397682 },
    { lat: 48.7189403, lng: 17.9395197 },
    { lat: 48.7189204, lng: 17.9392999 },
    { lat: 48.7189171, lng: 17.9391314 },
    { lat: 48.7188946, lng: 17.9386428 },
    { lat: 48.7188593, lng: 17.9383354 },
    { lat: 48.718819, lng: 17.9380194 },
    { lat: 48.7187536, lng: 17.937569 },
    { lat: 48.7187244, lng: 17.9373435 },
    { lat: 48.7187139, lng: 17.9372042 },
    { lat: 48.7187084, lng: 17.9369798 },
    { lat: 48.7186727, lng: 17.9365739 },
    { lat: 48.7185819, lng: 17.936206 },
    { lat: 48.7184021, lng: 17.9355589 },
    { lat: 48.7183652, lng: 17.9353772 },
    { lat: 48.7183398, lng: 17.9350462 },
    { lat: 48.718384, lng: 17.9346718 },
    { lat: 48.7184151, lng: 17.9343059 },
    { lat: 48.7184674, lng: 17.9337645 },
    { lat: 48.7185378, lng: 17.9333189 },
    { lat: 48.7185449, lng: 17.9331346 },
    { lat: 48.7185443, lng: 17.93288 },
    { lat: 48.7185096, lng: 17.9324899 },
    { lat: 48.718526, lng: 17.9320667 },
    { lat: 48.7185143, lng: 17.9318709 },
    { lat: 48.7184693, lng: 17.9315717 },
    { lat: 48.7184423, lng: 17.9311016 },
    { lat: 48.7184112, lng: 17.9309595 },
    { lat: 48.7183368, lng: 17.9307915 },
    { lat: 48.7182193, lng: 17.9305457 },
    { lat: 48.7182164, lng: 17.9304766 },
    { lat: 48.7182281, lng: 17.930315 },
    { lat: 48.7182593, lng: 17.9300439 },
    { lat: 48.7182475, lng: 17.9297899 },
    { lat: 48.7182182, lng: 17.9292062 },
    { lat: 48.7182207, lng: 17.9289653 },
    { lat: 48.7182495, lng: 17.9285235 },
    { lat: 48.7182863, lng: 17.928232 },
    { lat: 48.7182946, lng: 17.9280503 },
    { lat: 48.7182866, lng: 17.9276911 },
    { lat: 48.7183102, lng: 17.9273497 },
    { lat: 48.7184131, lng: 17.9267126 },
    { lat: 48.7184292, lng: 17.9265282 },
    { lat: 48.7184419, lng: 17.9263832 },
    { lat: 48.7184329, lng: 17.9260825 },
    { lat: 48.718439, lng: 17.9258638 },
    { lat: 48.7184572, lng: 17.925714 },
    { lat: 48.7184862, lng: 17.9252765 },
    { lat: 48.7185287, lng: 17.9250429 },
    { lat: 48.7185936, lng: 17.9247517 },
    { lat: 48.718621, lng: 17.9246051 },
    { lat: 48.7186567, lng: 17.924372 },
    { lat: 48.7186915, lng: 17.9242764 },
    { lat: 48.7187706, lng: 17.9241087 },
    { lat: 48.7190038, lng: 17.9237236 },
    { lat: 48.7191107, lng: 17.9235674 },
    { lat: 48.719224, lng: 17.9234418 },
    { lat: 48.7192801, lng: 17.9233429 },
    { lat: 48.7193411, lng: 17.9232305 },
    { lat: 48.7194034, lng: 17.9230833 },
    { lat: 48.7194418, lng: 17.9230082 },
    { lat: 48.7195311, lng: 17.9229178 },
    { lat: 48.7195739, lng: 17.9228569 },
    { lat: 48.7196731, lng: 17.9226473 },
    { lat: 48.7197472, lng: 17.9224738 },
    { lat: 48.7199096, lng: 17.9221303 },
    { lat: 48.7199991, lng: 17.9219492 },
    { lat: 48.7200374, lng: 17.9218707 },
    { lat: 48.7201233, lng: 17.9215552 },
    { lat: 48.7201909, lng: 17.9213813 },
    { lat: 48.7203716, lng: 17.9208942 },
    { lat: 48.7204022, lng: 17.9207259 },
    { lat: 48.720464, lng: 17.9205898 },
    { lat: 48.7205271, lng: 17.920438 },
    { lat: 48.7206624, lng: 17.9201346 },
    { lat: 48.7207072, lng: 17.9200528 },
    { lat: 48.7207702, lng: 17.9199748 },
    { lat: 48.7208371, lng: 17.9198291 },
    { lat: 48.7209327, lng: 17.9196148 },
    { lat: 48.7210537, lng: 17.9194314 },
    { lat: 48.7211279, lng: 17.9192951 },
    { lat: 48.7211836, lng: 17.9191309 },
    { lat: 48.7212168, lng: 17.9189506 },
    { lat: 48.7212229, lng: 17.9187902 },
    { lat: 48.7211921, lng: 17.9185581 },
    { lat: 48.7211693, lng: 17.9184035 },
    { lat: 48.7211527, lng: 17.9182791 },
    { lat: 48.7211273, lng: 17.918263 },
    { lat: 48.7210954, lng: 17.9181309 },
    { lat: 48.7210351, lng: 17.9179349 },
    { lat: 48.721013, lng: 17.9178684 },
    { lat: 48.7209955, lng: 17.9177077 },
    { lat: 48.720968, lng: 17.9175115 },
    { lat: 48.7208753, lng: 17.917117 },
    { lat: 48.7208623, lng: 17.9170321 },
    { lat: 48.7208445, lng: 17.9169028 },
    { lat: 48.7208203, lng: 17.9165939 },
    { lat: 48.7208376, lng: 17.916244 },
    { lat: 48.7208449, lng: 17.9158932 },
    { lat: 48.7208241, lng: 17.9156791 },
    { lat: 48.7208364, lng: 17.9155085 },
    { lat: 48.7208454, lng: 17.9153562 },
    { lat: 48.7208228, lng: 17.9150374 },
    { lat: 48.7208312, lng: 17.9148757 },
    { lat: 48.7208703, lng: 17.914699 },
    { lat: 48.7208663, lng: 17.9145776 },
    { lat: 48.7208381, lng: 17.9144437 },
    { lat: 48.7207951, lng: 17.9143314 },
    { lat: 48.7207868, lng: 17.9141922 },
    { lat: 48.7208141, lng: 17.9140814 },
    { lat: 48.7208419, lng: 17.9139822 },
    { lat: 48.720861, lng: 17.9137731 },
    { lat: 48.7209159, lng: 17.9135421 },
    { lat: 48.7209672, lng: 17.9133994 },
    { lat: 48.720985, lng: 17.9132626 },
    { lat: 48.7209892, lng: 17.9130888 },
    { lat: 48.7210104, lng: 17.9129896 },
    { lat: 48.7211134, lng: 17.9127123 },
    { lat: 48.7211463, lng: 17.9125466 },
    { lat: 48.7211737, lng: 17.9121936 },
    { lat: 48.7212183, lng: 17.9119588 },
    { lat: 48.7213023, lng: 17.9116483 },
    { lat: 48.7213728, lng: 17.911393 },
    { lat: 48.7214167, lng: 17.9110141 },
    { lat: 48.7214238, lng: 17.9109467 },
    { lat: 48.7214756, lng: 17.9103992 },
    { lat: 48.72146, lng: 17.9098 },
    { lat: 48.7215112, lng: 17.9094688 },
    { lat: 48.7216691, lng: 17.9086572 },
    { lat: 48.7217472, lng: 17.9084354 },
    { lat: 48.7218455, lng: 17.9082309 },
    { lat: 48.7218704, lng: 17.9081379 },
    { lat: 48.7220716, lng: 17.9073896 },
    { lat: 48.7222789, lng: 17.9069328 },
    { lat: 48.7223215, lng: 17.9066088 },
    { lat: 48.7224312, lng: 17.9057681 },
    { lat: 48.7225542, lng: 17.9048265 },
    { lat: 48.7226902, lng: 17.9034281 },
    { lat: 48.7228318, lng: 17.9018199 },
    { lat: 48.7228504, lng: 17.9016048 },
    { lat: 48.723058, lng: 17.8997453 },
    { lat: 48.7231218, lng: 17.8991913 },
    { lat: 48.7232359, lng: 17.8978983 },
    { lat: 48.723298, lng: 17.8970158 },
    { lat: 48.7233054, lng: 17.8969145 },
    { lat: 48.7233401, lng: 17.8964595 },
    { lat: 48.7234045, lng: 17.895148 },
    { lat: 48.7234223, lng: 17.8946558 },
    { lat: 48.7234232, lng: 17.8946335 },
    { lat: 48.723428, lng: 17.8945612 },
    { lat: 48.7234322, lng: 17.8944821 },
    { lat: 48.7234344, lng: 17.8944439 },
    { lat: 48.7234351, lng: 17.8944358 },
    { lat: 48.7233756, lng: 17.8938424 },
    { lat: 48.7232672, lng: 17.8927535 },
    { lat: 48.723255, lng: 17.8926272 },
    { lat: 48.723175, lng: 17.8919166 },
    { lat: 48.7231299, lng: 17.8915036 },
    { lat: 48.7231206, lng: 17.8914264 },
    { lat: 48.7230886, lng: 17.8906323 },
    { lat: 48.7230666, lng: 17.8900975 },
    { lat: 48.7230435, lng: 17.8895279 },
    { lat: 48.7230424, lng: 17.8895093 },
    { lat: 48.7230403, lng: 17.8894549 },
    { lat: 48.7230841, lng: 17.8889818 },
    { lat: 48.7230928, lng: 17.8888821 },
    { lat: 48.7231781, lng: 17.8879367 },
    { lat: 48.7231965, lng: 17.8865765 },
    { lat: 48.7231801, lng: 17.8855913 },
    { lat: 48.7231412, lng: 17.8843841 },
    { lat: 48.7231398, lng: 17.8843429 },
    { lat: 48.7230804, lng: 17.882497 },
    { lat: 48.7230901, lng: 17.8820008 },
    { lat: 48.7231285, lng: 17.8812902 },
    { lat: 48.7230901, lng: 17.8803366 },
    { lat: 48.7230844, lng: 17.880278 },
    { lat: 48.7229335, lng: 17.878731 },
    { lat: 48.7229249, lng: 17.8786438 },
    { lat: 48.7228957, lng: 17.8783481 },
    { lat: 48.7227615, lng: 17.8764412 },
    { lat: 48.722658, lng: 17.875175 },
    { lat: 48.7226367, lng: 17.8749161 },
    { lat: 48.7225863, lng: 17.8739709 },
    { lat: 48.7225158, lng: 17.8728765 },
    { lat: 48.7225139, lng: 17.8728456 },
    { lat: 48.7225112, lng: 17.872819 },
    { lat: 48.7225002, lng: 17.8727131 },
    { lat: 48.7224705, lng: 17.8724076 },
    { lat: 48.7224652, lng: 17.8723558 },
    { lat: 48.7224556, lng: 17.872254 },
    { lat: 48.7224332, lng: 17.8720315 },
    { lat: 48.7224039, lng: 17.8717355 },
    { lat: 48.7222416, lng: 17.870094 },
    { lat: 48.7221781, lng: 17.8694536 },
    { lat: 48.7221715, lng: 17.8693808 },
    { lat: 48.7220856, lng: 17.86852 },
    { lat: 48.7220697, lng: 17.8683506 },
    { lat: 48.721931, lng: 17.8669504 },
    { lat: 48.7215322, lng: 17.8649671 },
    { lat: 48.7212305, lng: 17.8636477 },
    { lat: 48.7212188, lng: 17.8636583 },
    { lat: 48.721165, lng: 17.8637038 },
    { lat: 48.7205006, lng: 17.8642646 },
    { lat: 48.719446, lng: 17.8651565 },
    { lat: 48.7193449, lng: 17.8652409 },
    { lat: 48.7182638, lng: 17.8661957 },
    { lat: 48.7181469, lng: 17.8662989 },
    { lat: 48.7170578, lng: 17.8673867 },
    { lat: 48.7161787, lng: 17.8682645 },
    { lat: 48.7157502, lng: 17.8686916 },
    { lat: 48.7156938, lng: 17.868748 },
    { lat: 48.7153443, lng: 17.8690967 },
    { lat: 48.714106, lng: 17.870333 },
    { lat: 48.7138145, lng: 17.8708196 },
    { lat: 48.7123661, lng: 17.8730104 },
    { lat: 48.711167, lng: 17.8728379 },
    { lat: 48.7088948, lng: 17.8725188 },
    { lat: 48.7089044, lng: 17.8735905 },
    { lat: 48.7089565, lng: 17.874341 },
    { lat: 48.7090701, lng: 17.8757725 },
    { lat: 48.7091245, lng: 17.8761142 },
    { lat: 48.7091245, lng: 17.8764307 },
    { lat: 48.7091935, lng: 17.8768083 },
    { lat: 48.7093171, lng: 17.8772105 },
    { lat: 48.7094786, lng: 17.877622 },
    { lat: 48.7096299, lng: 17.8783346 },
    { lat: 48.7096762, lng: 17.8787464 },
    { lat: 48.7095846, lng: 17.8794354 },
    { lat: 48.7098464, lng: 17.8800892 },
    { lat: 48.7101431, lng: 17.8804969 },
    { lat: 48.7106295, lng: 17.8805328 },
    { lat: 48.7106638, lng: 17.8807472 },
    { lat: 48.7115456, lng: 17.8825634 },
    { lat: 48.7116395, lng: 17.882756 },
    { lat: 48.7128422, lng: 17.8852347 },
    { lat: 48.7134118, lng: 17.8864066 },
    { lat: 48.7134879, lng: 17.8865642 },
    { lat: 48.7136646, lng: 17.8868921 },
    { lat: 48.7136717, lng: 17.88658 },
    { lat: 48.7137526, lng: 17.8860111 },
    { lat: 48.7138572, lng: 17.8857632 },
    { lat: 48.7140027, lng: 17.8855161 },
    { lat: 48.7141671, lng: 17.8853412 },
    { lat: 48.7143156, lng: 17.8852389 },
    { lat: 48.7144622, lng: 17.8851436 },
    { lat: 48.7145445, lng: 17.8851775 },
    { lat: 48.7145315, lng: 17.8944464 },
    { lat: 48.7143089, lng: 17.8947603 },
    { lat: 48.714278, lng: 17.8951833 },
    { lat: 48.7142926, lng: 17.8953135 },
    { lat: 48.7143404, lng: 17.8954722 },
    { lat: 48.7143465, lng: 17.8960037 },
    { lat: 48.7143108, lng: 17.8961233 },
    { lat: 48.71424, lng: 17.8962222 },
    { lat: 48.711723, lng: 17.8978012 },
    { lat: 48.7098305, lng: 17.898318 },
    { lat: 48.7089316, lng: 17.899438 },
    { lat: 48.7087612, lng: 17.8999998 },
    { lat: 48.7081369, lng: 17.9032282 },
    { lat: 48.7065757, lng: 17.9074713 },
    { lat: 48.7050843, lng: 17.909325 },
    { lat: 48.7038674, lng: 17.9109407 },
    { lat: 48.7037356, lng: 17.9114521 },
    { lat: 48.7035796, lng: 17.9120503 },
    { lat: 48.7037469, lng: 17.9122973 },
    { lat: 48.7038823, lng: 17.9122219 },
    { lat: 48.7047465, lng: 17.9126088 },
    { lat: 48.7047254, lng: 17.9127652 },
    { lat: 48.7045648, lng: 17.9130608 },
    { lat: 48.7035928, lng: 17.9138301 },
    { lat: 48.7033843, lng: 17.9140988 },
    { lat: 48.7032432, lng: 17.9143254 },
    { lat: 48.7030734, lng: 17.9146542 },
    { lat: 48.7028218, lng: 17.9150716 },
    { lat: 48.7026997, lng: 17.9154993 },
    { lat: 48.7025898, lng: 17.9157949 },
    { lat: 48.7023767, lng: 17.9162876 },
    { lat: 48.7021323, lng: 17.9169553 },
    { lat: 48.7019119, lng: 17.9173638 },
    { lat: 48.7018549, lng: 17.9176552 },
    { lat: 48.701742, lng: 17.917937 },
    { lat: 48.7017146, lng: 17.9181757 },
    { lat: 48.7017173, lng: 17.9185215 },
    { lat: 48.7017109, lng: 17.9190366 },
    { lat: 48.70171, lng: 17.9190401 },
    { lat: 48.7016742, lng: 17.9192529 },
    { lat: 48.701661, lng: 17.9193337 },
    { lat: 48.7016719, lng: 17.9195913 },
    { lat: 48.7017111, lng: 17.9199796 },
    { lat: 48.7017274, lng: 17.9202705 },
    { lat: 48.7017325, lng: 17.9206954 },
    { lat: 48.7017027, lng: 17.9208576 },
    { lat: 48.7016139, lng: 17.9210876 },
    { lat: 48.7013874, lng: 17.9215283 },
    { lat: 48.7013147, lng: 17.9217462 },
    { lat: 48.7012365, lng: 17.9219667 },
    { lat: 48.7012048, lng: 17.9221897 },
    { lat: 48.7012523, lng: 17.9224557 },
    { lat: 48.7012931, lng: 17.9226292 },
    { lat: 48.7013347, lng: 17.9230221 },
    { lat: 48.7013625, lng: 17.923391 },
    { lat: 48.7014433, lng: 17.9241545 },
    { lat: 48.7014307, lng: 17.9243404 },
    { lat: 48.7013759, lng: 17.9246697 },
    { lat: 48.7013402, lng: 17.924939 },
    { lat: 48.7013365, lng: 17.9251035 },
    { lat: 48.7013623, lng: 17.9253635 },
    { lat: 48.7013722, lng: 17.925774 },
    { lat: 48.7014236, lng: 17.9262935 },
    { lat: 48.7014337, lng: 17.926669 },
    { lat: 48.7014283, lng: 17.927012 },
    { lat: 48.7013475, lng: 17.9272457 },
    { lat: 48.7012908, lng: 17.9273535 },
    { lat: 48.7010784, lng: 17.9277833 },
    { lat: 48.7009989, lng: 17.9283025 },
    { lat: 48.7009927, lng: 17.9284636 },
    { lat: 48.7016192, lng: 17.9392102 },
    { lat: 48.700717, lng: 17.9424708 },
    { lat: 48.7006333, lng: 17.9437521 },
    { lat: 48.7006441, lng: 17.9438005 },
    { lat: 48.6993906, lng: 17.9542393 },
    { lat: 48.6993824, lng: 17.9543102 },
    { lat: 48.6992844, lng: 17.9551835 },
    { lat: 48.6989327, lng: 17.9557779 },
    { lat: 48.6989224, lng: 17.9558325 },
    { lat: 48.6988271, lng: 17.9559563 },
    { lat: 48.6985415, lng: 17.9617275 },
    { lat: 48.6986126, lng: 17.9622 },
    { lat: 48.6986206, lng: 17.9622593 },
    { lat: 48.6988277, lng: 17.9636494 },
    { lat: 48.6990792, lng: 17.9647815 },
    { lat: 48.6990939, lng: 17.9655409 },
    { lat: 48.6992528, lng: 17.9661049 },
    { lat: 48.6993035, lng: 17.9666535 },
    { lat: 48.6998524, lng: 17.9685339 },
    { lat: 48.6998087, lng: 17.9688246 },
    { lat: 48.6999201, lng: 17.9691773 },
    { lat: 48.6998165, lng: 17.9695989 },
    { lat: 48.7000039, lng: 17.9699963 },
    { lat: 48.6997485, lng: 17.9703528 },
    { lat: 48.6996268, lng: 17.9704874 },
    { lat: 48.6994265, lng: 17.9710288 },
    { lat: 48.6996593, lng: 17.971414 },
    { lat: 48.6997807, lng: 17.9719163 },
    { lat: 48.7003301, lng: 17.9725388 },
    { lat: 48.7005872, lng: 17.9732162 },
    { lat: 48.7007186, lng: 17.9734518 },
    { lat: 48.7007951, lng: 17.9738456 },
    { lat: 48.7009701, lng: 17.9740355 },
    { lat: 48.7010991, lng: 17.9741373 },
    { lat: 48.7012337, lng: 17.9742162 },
    { lat: 48.7012951, lng: 17.9749261 },
    { lat: 48.701381, lng: 17.9751561 },
    { lat: 48.7013917, lng: 17.9755065 },
    { lat: 48.7015085, lng: 17.9761987 },
    { lat: 48.7015669, lng: 17.9764223 },
    { lat: 48.7015736, lng: 17.9769311 },
    { lat: 48.7016794, lng: 17.9773827 },
    { lat: 48.7016901, lng: 17.9774282 },
    { lat: 48.701722, lng: 17.9775672 },
    { lat: 48.7017341, lng: 17.9776191 },
    { lat: 48.7020043, lng: 17.9787847 },
    { lat: 48.7020164, lng: 17.9788347 },
    { lat: 48.702309, lng: 17.9800926 },
    { lat: 48.7031371, lng: 17.9819855 },
    { lat: 48.7031607, lng: 17.9820399 },
    { lat: 48.7036081, lng: 17.984289 },
    { lat: 48.7037398, lng: 17.9863161 },
    { lat: 48.7044198, lng: 17.9869329 },
    { lat: 48.7052565, lng: 17.9893335 },
    { lat: 48.705797, lng: 17.9904135 },
    { lat: 48.705701, lng: 17.990918 },
  ],
  NováVesnadVáhom: [
    { lat: 48.7254019, lng: 17.8519426 },
    { lat: 48.7253623, lng: 17.8520269 },
    { lat: 48.7253769, lng: 17.8523247 },
    { lat: 48.7238189, lng: 17.8522341 },
    { lat: 48.722956, lng: 17.8521848 },
    { lat: 48.7225943, lng: 17.8520328 },
    { lat: 48.7223534, lng: 17.851991 },
    { lat: 48.7218614, lng: 17.8520591 },
    { lat: 48.7216434, lng: 17.8522852 },
    { lat: 48.7215487, lng: 17.8524747 },
    { lat: 48.7215043, lng: 17.8527106 },
    { lat: 48.7214945, lng: 17.8530896 },
    { lat: 48.7214919, lng: 17.8532151 },
    { lat: 48.7214911, lng: 17.8532388 },
    { lat: 48.7214899, lng: 17.8532953 },
    { lat: 48.7210175, lng: 17.8537593 },
    { lat: 48.721025, lng: 17.8537895 },
    { lat: 48.7210468, lng: 17.8540931 },
    { lat: 48.721054, lng: 17.8544925 },
    { lat: 48.7210547, lng: 17.8545035 },
    { lat: 48.7211037, lng: 17.856732 },
    { lat: 48.7211081, lng: 17.8571024 },
    { lat: 48.7211086, lng: 17.8571302 },
    { lat: 48.7211206, lng: 17.8579444 },
    { lat: 48.7211507, lng: 17.8594707 },
    { lat: 48.7211665, lng: 17.8603287 },
    { lat: 48.7211865, lng: 17.8613509 },
    { lat: 48.7212051, lng: 17.8623315 },
    { lat: 48.721216, lng: 17.8628471 },
    { lat: 48.7212198, lng: 17.8630784 },
    { lat: 48.7212305, lng: 17.8636477 },
    { lat: 48.7215322, lng: 17.8649671 },
    { lat: 48.721931, lng: 17.8669504 },
    { lat: 48.7220697, lng: 17.8683506 },
    { lat: 48.7220856, lng: 17.86852 },
    { lat: 48.7221715, lng: 17.8693808 },
    { lat: 48.7221781, lng: 17.8694536 },
    { lat: 48.7222416, lng: 17.870094 },
    { lat: 48.7224039, lng: 17.8717355 },
    { lat: 48.7224332, lng: 17.8720315 },
    { lat: 48.7224556, lng: 17.872254 },
    { lat: 48.7224652, lng: 17.8723558 },
    { lat: 48.7224705, lng: 17.8724076 },
    { lat: 48.7225002, lng: 17.8727131 },
    { lat: 48.7225112, lng: 17.872819 },
    { lat: 48.7225139, lng: 17.8728456 },
    { lat: 48.7225158, lng: 17.8728765 },
    { lat: 48.7225863, lng: 17.8739709 },
    { lat: 48.7226367, lng: 17.8749161 },
    { lat: 48.722658, lng: 17.875175 },
    { lat: 48.7227615, lng: 17.8764412 },
    { lat: 48.7228957, lng: 17.8783481 },
    { lat: 48.7229249, lng: 17.8786438 },
    { lat: 48.7229335, lng: 17.878731 },
    { lat: 48.7230844, lng: 17.880278 },
    { lat: 48.7230901, lng: 17.8803366 },
    { lat: 48.7231285, lng: 17.8812902 },
    { lat: 48.7230901, lng: 17.8820008 },
    { lat: 48.7230804, lng: 17.882497 },
    { lat: 48.7231398, lng: 17.8843429 },
    { lat: 48.7231412, lng: 17.8843841 },
    { lat: 48.7231801, lng: 17.8855913 },
    { lat: 48.7231965, lng: 17.8865765 },
    { lat: 48.7231781, lng: 17.8879367 },
    { lat: 48.7230928, lng: 17.8888821 },
    { lat: 48.7230841, lng: 17.8889818 },
    { lat: 48.7230403, lng: 17.8894549 },
    { lat: 48.7230424, lng: 17.8895093 },
    { lat: 48.7230435, lng: 17.8895279 },
    { lat: 48.7230666, lng: 17.8900975 },
    { lat: 48.7230886, lng: 17.8906323 },
    { lat: 48.7231206, lng: 17.8914264 },
    { lat: 48.7231299, lng: 17.8915036 },
    { lat: 48.723175, lng: 17.8919166 },
    { lat: 48.723255, lng: 17.8926272 },
    { lat: 48.7232672, lng: 17.8927535 },
    { lat: 48.7233756, lng: 17.8938424 },
    { lat: 48.7234351, lng: 17.8944358 },
    { lat: 48.7234344, lng: 17.8944439 },
    { lat: 48.7234322, lng: 17.8944821 },
    { lat: 48.723428, lng: 17.8945612 },
    { lat: 48.7234232, lng: 17.8946335 },
    { lat: 48.7234223, lng: 17.8946558 },
    { lat: 48.7234045, lng: 17.895148 },
    { lat: 48.7233401, lng: 17.8964595 },
    { lat: 48.7233054, lng: 17.8969145 },
    { lat: 48.723298, lng: 17.8970158 },
    { lat: 48.7232359, lng: 17.8978983 },
    { lat: 48.7231218, lng: 17.8991913 },
    { lat: 48.723058, lng: 17.8997453 },
    { lat: 48.7228504, lng: 17.9016048 },
    { lat: 48.7228318, lng: 17.9018199 },
    { lat: 48.7226902, lng: 17.9034281 },
    { lat: 48.7225542, lng: 17.9048265 },
    { lat: 48.7224312, lng: 17.9057681 },
    { lat: 48.7223215, lng: 17.9066088 },
    { lat: 48.7222789, lng: 17.9069328 },
    { lat: 48.7220716, lng: 17.9073896 },
    { lat: 48.7218704, lng: 17.9081379 },
    { lat: 48.7218455, lng: 17.9082309 },
    { lat: 48.7217472, lng: 17.9084354 },
    { lat: 48.7216691, lng: 17.9086572 },
    { lat: 48.7215112, lng: 17.9094688 },
    { lat: 48.72146, lng: 17.9098 },
    { lat: 48.7214756, lng: 17.9103992 },
    { lat: 48.7214238, lng: 17.9109467 },
    { lat: 48.7214167, lng: 17.9110141 },
    { lat: 48.7213728, lng: 17.911393 },
    { lat: 48.7213023, lng: 17.9116483 },
    { lat: 48.7212183, lng: 17.9119588 },
    { lat: 48.7211737, lng: 17.9121936 },
    { lat: 48.7211463, lng: 17.9125466 },
    { lat: 48.7211134, lng: 17.9127123 },
    { lat: 48.7210104, lng: 17.9129896 },
    { lat: 48.7209892, lng: 17.9130888 },
    { lat: 48.720985, lng: 17.9132626 },
    { lat: 48.7209672, lng: 17.9133994 },
    { lat: 48.7209159, lng: 17.9135421 },
    { lat: 48.720861, lng: 17.9137731 },
    { lat: 48.7208419, lng: 17.9139822 },
    { lat: 48.7208141, lng: 17.9140814 },
    { lat: 48.7207868, lng: 17.9141922 },
    { lat: 48.7207951, lng: 17.9143314 },
    { lat: 48.7208381, lng: 17.9144437 },
    { lat: 48.7208663, lng: 17.9145776 },
    { lat: 48.7208703, lng: 17.914699 },
    { lat: 48.7208312, lng: 17.9148757 },
    { lat: 48.7208228, lng: 17.9150374 },
    { lat: 48.7208454, lng: 17.9153562 },
    { lat: 48.7208364, lng: 17.9155085 },
    { lat: 48.7208241, lng: 17.9156791 },
    { lat: 48.7208449, lng: 17.9158932 },
    { lat: 48.7208376, lng: 17.916244 },
    { lat: 48.7208203, lng: 17.9165939 },
    { lat: 48.7208445, lng: 17.9169028 },
    { lat: 48.7208623, lng: 17.9170321 },
    { lat: 48.7208753, lng: 17.917117 },
    { lat: 48.720968, lng: 17.9175115 },
    { lat: 48.7209955, lng: 17.9177077 },
    { lat: 48.721013, lng: 17.9178684 },
    { lat: 48.7210351, lng: 17.9179349 },
    { lat: 48.7210954, lng: 17.9181309 },
    { lat: 48.7211273, lng: 17.918263 },
    { lat: 48.7211527, lng: 17.9182791 },
    { lat: 48.7211693, lng: 17.9184035 },
    { lat: 48.7211921, lng: 17.9185581 },
    { lat: 48.7212229, lng: 17.9187902 },
    { lat: 48.7212168, lng: 17.9189506 },
    { lat: 48.7211836, lng: 17.9191309 },
    { lat: 48.7211279, lng: 17.9192951 },
    { lat: 48.7210537, lng: 17.9194314 },
    { lat: 48.7209327, lng: 17.9196148 },
    { lat: 48.7208371, lng: 17.9198291 },
    { lat: 48.7207702, lng: 17.9199748 },
    { lat: 48.7207072, lng: 17.9200528 },
    { lat: 48.7206624, lng: 17.9201346 },
    { lat: 48.7205271, lng: 17.920438 },
    { lat: 48.720464, lng: 17.9205898 },
    { lat: 48.7204022, lng: 17.9207259 },
    { lat: 48.7203716, lng: 17.9208942 },
    { lat: 48.7201909, lng: 17.9213813 },
    { lat: 48.7201233, lng: 17.9215552 },
    { lat: 48.7200374, lng: 17.9218707 },
    { lat: 48.7199991, lng: 17.9219492 },
    { lat: 48.7199096, lng: 17.9221303 },
    { lat: 48.7197472, lng: 17.9224738 },
    { lat: 48.7196731, lng: 17.9226473 },
    { lat: 48.7195739, lng: 17.9228569 },
    { lat: 48.7195311, lng: 17.9229178 },
    { lat: 48.7194418, lng: 17.9230082 },
    { lat: 48.7194034, lng: 17.9230833 },
    { lat: 48.7193411, lng: 17.9232305 },
    { lat: 48.7192801, lng: 17.9233429 },
    { lat: 48.719224, lng: 17.9234418 },
    { lat: 48.7191107, lng: 17.9235674 },
    { lat: 48.7190038, lng: 17.9237236 },
    { lat: 48.7187706, lng: 17.9241087 },
    { lat: 48.7186915, lng: 17.9242764 },
    { lat: 48.7186567, lng: 17.924372 },
    { lat: 48.718621, lng: 17.9246051 },
    { lat: 48.7185936, lng: 17.9247517 },
    { lat: 48.7185287, lng: 17.9250429 },
    { lat: 48.7184862, lng: 17.9252765 },
    { lat: 48.7184572, lng: 17.925714 },
    { lat: 48.718439, lng: 17.9258638 },
    { lat: 48.7184329, lng: 17.9260825 },
    { lat: 48.7184419, lng: 17.9263832 },
    { lat: 48.7184292, lng: 17.9265282 },
    { lat: 48.7184131, lng: 17.9267126 },
    { lat: 48.7183102, lng: 17.9273497 },
    { lat: 48.7182866, lng: 17.9276911 },
    { lat: 48.7182946, lng: 17.9280503 },
    { lat: 48.7182863, lng: 17.928232 },
    { lat: 48.7182495, lng: 17.9285235 },
    { lat: 48.7182207, lng: 17.9289653 },
    { lat: 48.7182182, lng: 17.9292062 },
    { lat: 48.7182475, lng: 17.9297899 },
    { lat: 48.7182593, lng: 17.9300439 },
    { lat: 48.7182281, lng: 17.930315 },
    { lat: 48.7182164, lng: 17.9304766 },
    { lat: 48.7182193, lng: 17.9305457 },
    { lat: 48.7183368, lng: 17.9307915 },
    { lat: 48.7184112, lng: 17.9309595 },
    { lat: 48.7184423, lng: 17.9311016 },
    { lat: 48.7184693, lng: 17.9315717 },
    { lat: 48.7185143, lng: 17.9318709 },
    { lat: 48.718526, lng: 17.9320667 },
    { lat: 48.7185096, lng: 17.9324899 },
    { lat: 48.7185443, lng: 17.93288 },
    { lat: 48.7185449, lng: 17.9331346 },
    { lat: 48.7185378, lng: 17.9333189 },
    { lat: 48.7184674, lng: 17.9337645 },
    { lat: 48.7184151, lng: 17.9343059 },
    { lat: 48.718384, lng: 17.9346718 },
    { lat: 48.7183398, lng: 17.9350462 },
    { lat: 48.7183652, lng: 17.9353772 },
    { lat: 48.7184021, lng: 17.9355589 },
    { lat: 48.7185819, lng: 17.936206 },
    { lat: 48.7186727, lng: 17.9365739 },
    { lat: 48.7187084, lng: 17.9369798 },
    { lat: 48.7187139, lng: 17.9372042 },
    { lat: 48.7187244, lng: 17.9373435 },
    { lat: 48.7187536, lng: 17.937569 },
    { lat: 48.718819, lng: 17.9380194 },
    { lat: 48.7188593, lng: 17.9383354 },
    { lat: 48.7188946, lng: 17.9386428 },
    { lat: 48.7189171, lng: 17.9391314 },
    { lat: 48.7189204, lng: 17.9392999 },
    { lat: 48.7189403, lng: 17.9395197 },
    { lat: 48.7189631, lng: 17.9397682 },
    { lat: 48.7189909, lng: 17.9399687 },
    { lat: 48.7190055, lng: 17.9404026 },
    { lat: 48.7190361, lng: 17.9405929 },
    { lat: 48.7190218, lng: 17.9408072 },
    { lat: 48.7189851, lng: 17.9410242 },
    { lat: 48.7189402, lng: 17.9411787 },
    { lat: 48.7188708, lng: 17.9413771 },
    { lat: 48.7187971, lng: 17.9415616 },
    { lat: 48.7187392, lng: 17.9418569 },
    { lat: 48.7187194, lng: 17.9422235 },
    { lat: 48.7187046, lng: 17.9424859 },
    { lat: 48.7186627, lng: 17.9429946 },
    { lat: 48.718637, lng: 17.9434705 },
    { lat: 48.7186936, lng: 17.9438146 },
    { lat: 48.7187439, lng: 17.9439745 },
    { lat: 48.718803, lng: 17.9440757 },
    { lat: 48.7189971, lng: 17.9443024 },
    { lat: 48.7192753, lng: 17.9445962 },
    { lat: 48.7195085, lng: 17.9448707 },
    { lat: 48.7195615, lng: 17.9449629 },
    { lat: 48.7196046, lng: 17.9450622 },
    { lat: 48.7196767, lng: 17.9451906 },
    { lat: 48.7197423, lng: 17.9453083 },
    { lat: 48.7198331, lng: 17.9455419 },
    { lat: 48.7199722, lng: 17.9460586 },
    { lat: 48.7201874, lng: 17.9464101 },
    { lat: 48.7202542, lng: 17.9466006 },
    { lat: 48.7204458, lng: 17.9469557 },
    { lat: 48.7206272, lng: 17.9472722 },
    { lat: 48.7207247, lng: 17.947374 },
    { lat: 48.7207668, lng: 17.9474181 },
    { lat: 48.720844, lng: 17.9474998 },
    { lat: 48.7209996, lng: 17.9475156 },
    { lat: 48.7210341, lng: 17.947545 },
    { lat: 48.7212182, lng: 17.9477941 },
    { lat: 48.7215037, lng: 17.9482452 },
    { lat: 48.7217206, lng: 17.9485701 },
    { lat: 48.7218851, lng: 17.948812 },
    { lat: 48.721938, lng: 17.9489417 },
    { lat: 48.7220086, lng: 17.9491219 },
    { lat: 48.7222167, lng: 17.9494299 },
    { lat: 48.7223585, lng: 17.9496115 },
    { lat: 48.722489, lng: 17.9497173 },
    { lat: 48.7226772, lng: 17.9499403 },
    { lat: 48.7229916, lng: 17.9503857 },
    { lat: 48.7230915, lng: 17.9505096 },
    { lat: 48.7233974, lng: 17.9507365 },
    { lat: 48.7234725, lng: 17.9507642 },
    { lat: 48.7236224, lng: 17.951044 },
    { lat: 48.7236708, lng: 17.9511364 },
    { lat: 48.7237855, lng: 17.9514517 },
    { lat: 48.723922, lng: 17.9518097 },
    { lat: 48.724001, lng: 17.9520933 },
    { lat: 48.7241316, lng: 17.9524845 },
    { lat: 48.724287, lng: 17.9530074 },
    { lat: 48.7244651, lng: 17.9538729 },
    { lat: 48.7245873, lng: 17.9548052 },
    { lat: 48.724557, lng: 17.9550546 },
    { lat: 48.7245117, lng: 17.9552415 },
    { lat: 48.7244758, lng: 17.9555672 },
    { lat: 48.7244175, lng: 17.9557069 },
    { lat: 48.724428, lng: 17.9557301 },
    { lat: 48.7244434, lng: 17.9557239 },
    { lat: 48.7245969, lng: 17.9555911 },
    { lat: 48.7250276, lng: 17.9549413 },
    { lat: 48.7253166, lng: 17.9544311 },
    { lat: 48.7255304, lng: 17.9539272 },
    { lat: 48.7256418, lng: 17.9537508 },
    { lat: 48.7256711, lng: 17.9536753 },
    { lat: 48.725733, lng: 17.9535013 },
    { lat: 48.7257722, lng: 17.9533637 },
    { lat: 48.7257967, lng: 17.9532819 },
    { lat: 48.7258937, lng: 17.9529228 },
    { lat: 48.7259777, lng: 17.9527408 },
    { lat: 48.7263259, lng: 17.9523349 },
    { lat: 48.7263994, lng: 17.9523177 },
    { lat: 48.7265196, lng: 17.9522685 },
    { lat: 48.7265682, lng: 17.9522508 },
    { lat: 48.7269331, lng: 17.9520471 },
    { lat: 48.7269716, lng: 17.9520132 },
    { lat: 48.7270365, lng: 17.9519472 },
    { lat: 48.7273836, lng: 17.9516349 },
    { lat: 48.7276237, lng: 17.9514209 },
    { lat: 48.7279379, lng: 17.951202 },
    { lat: 48.7283642, lng: 17.9510999 },
    { lat: 48.7287382, lng: 17.9509742 },
    { lat: 48.7288231, lng: 17.9508854 },
    { lat: 48.7290811, lng: 17.9505738 },
    { lat: 48.7293827, lng: 17.9504074 },
    { lat: 48.7296505, lng: 17.9502041 },
    { lat: 48.7298329, lng: 17.9499893 },
    { lat: 48.7303314, lng: 17.9492645 },
    { lat: 48.730509, lng: 17.9490444 },
    { lat: 48.7306621, lng: 17.9489422 },
    { lat: 48.7308051, lng: 17.9487076 },
    { lat: 48.7310605, lng: 17.9485431 },
    { lat: 48.7311927, lng: 17.9484325 },
    { lat: 48.7313043, lng: 17.9482791 },
    { lat: 48.7315872, lng: 17.9478368 },
    { lat: 48.7316082, lng: 17.9478114 },
    { lat: 48.7317462, lng: 17.9473254 },
    { lat: 48.7317747, lng: 17.9470452 },
    { lat: 48.7318116, lng: 17.9464707 },
    { lat: 48.7318867, lng: 17.9461811 },
    { lat: 48.7319548, lng: 17.9459789 },
    { lat: 48.7320521, lng: 17.9457933 },
    { lat: 48.73223, lng: 17.945351 },
    { lat: 48.7324265, lng: 17.944901 },
    { lat: 48.7326827, lng: 17.9445585 },
    { lat: 48.7329751, lng: 17.9439755 },
    { lat: 48.7330028, lng: 17.943871 },
    { lat: 48.7331829, lng: 17.9432765 },
    { lat: 48.7333233, lng: 17.9427932 },
    { lat: 48.7333932, lng: 17.9424897 },
    { lat: 48.7335836, lng: 17.9422573 },
    { lat: 48.7337818, lng: 17.941834 },
    { lat: 48.733943, lng: 17.9414348 },
    { lat: 48.734088, lng: 17.9410447 },
    { lat: 48.7341364, lng: 17.940931 },
    { lat: 48.7342151, lng: 17.9407549 },
    { lat: 48.7342651, lng: 17.9406481 },
    { lat: 48.7345757, lng: 17.9401975 },
    { lat: 48.7347468, lng: 17.9400563 },
    { lat: 48.7348588, lng: 17.9397775 },
    { lat: 48.734937, lng: 17.9396132 },
    { lat: 48.7351152, lng: 17.9392906 },
    { lat: 48.7351635, lng: 17.939173 },
    { lat: 48.7353277, lng: 17.9387288 },
    { lat: 48.7354416, lng: 17.9384064 },
    { lat: 48.7355349, lng: 17.9382103 },
    { lat: 48.7356987, lng: 17.9379472 },
    { lat: 48.7358463, lng: 17.937831 },
    { lat: 48.7358757, lng: 17.9378117 },
    { lat: 48.7361911, lng: 17.9377439 },
    { lat: 48.7366173, lng: 17.9375859 },
    { lat: 48.7368387, lng: 17.937343 },
    { lat: 48.7370936, lng: 17.937057 },
    { lat: 48.737127, lng: 17.9370124 },
    { lat: 48.7371853, lng: 17.9369493 },
    { lat: 48.7371965, lng: 17.9369081 },
    { lat: 48.737275, lng: 17.936693 },
    { lat: 48.7373131, lng: 17.9364818 },
    { lat: 48.737316, lng: 17.9363017 },
    { lat: 48.7373171, lng: 17.9359796 },
    { lat: 48.7371991, lng: 17.9355925 },
    { lat: 48.7371669, lng: 17.935338 },
    { lat: 48.7370487, lng: 17.9349092 },
    { lat: 48.736974, lng: 17.9347359 },
    { lat: 48.7368035, lng: 17.9345475 },
    { lat: 48.7367297, lng: 17.9344074 },
    { lat: 48.7366789, lng: 17.9342383 },
    { lat: 48.7366092, lng: 17.9338455 },
    { lat: 48.7366072, lng: 17.9335876 },
    { lat: 48.7366387, lng: 17.9333201 },
    { lat: 48.7366004, lng: 17.9330588 },
    { lat: 48.7365599, lng: 17.932871 },
    { lat: 48.7363121, lng: 17.9322761 },
    { lat: 48.7360797, lng: 17.9318829 },
    { lat: 48.7359914, lng: 17.9317653 },
    { lat: 48.7358668, lng: 17.9314749 },
    { lat: 48.7357613, lng: 17.9313152 },
    { lat: 48.7356247, lng: 17.9311824 },
    { lat: 48.7354282, lng: 17.9308984 },
    { lat: 48.7353117, lng: 17.9307423 },
    { lat: 48.7351134, lng: 17.93024 },
    { lat: 48.7349492, lng: 17.9298931 },
    { lat: 48.7347271, lng: 17.9292917 },
    { lat: 48.7347175, lng: 17.9289819 },
    { lat: 48.7346918, lng: 17.9287794 },
    { lat: 48.734629, lng: 17.9285206 },
    { lat: 48.7345859, lng: 17.9284052 },
    { lat: 48.7344329, lng: 17.9281489 },
    { lat: 48.73422, lng: 17.9278364 },
    { lat: 48.7341324, lng: 17.9277305 },
    { lat: 48.7339884, lng: 17.9275126 },
    { lat: 48.7338305, lng: 17.9272135 },
    { lat: 48.7335695, lng: 17.9268512 },
    { lat: 48.7333989, lng: 17.9264906 },
    { lat: 48.7331465, lng: 17.9260256 },
    { lat: 48.7330187, lng: 17.9258162 },
    { lat: 48.7325798, lng: 17.9247628 },
    { lat: 48.7325224, lng: 17.9245016 },
    { lat: 48.7323721, lng: 17.923991 },
    { lat: 48.7320807, lng: 17.9230464 },
    { lat: 48.7320272, lng: 17.9228878 },
    { lat: 48.7319414, lng: 17.9225488 },
    { lat: 48.7319262, lng: 17.9223513 },
    { lat: 48.7319396, lng: 17.9220849 },
    { lat: 48.7319731, lng: 17.9218517 },
    { lat: 48.7320861, lng: 17.921309 },
    { lat: 48.7321245, lng: 17.9211575 },
    { lat: 48.7322348, lng: 17.9210417 },
    { lat: 48.7322468, lng: 17.921014 },
    { lat: 48.7322863, lng: 17.9207318 },
    { lat: 48.7323, lng: 17.920604 },
    { lat: 48.7323185, lng: 17.9204202 },
    { lat: 48.7324584, lng: 17.9199868 },
    { lat: 48.7326295, lng: 17.9195082 },
    { lat: 48.7327661, lng: 17.9190202 },
    { lat: 48.7328315, lng: 17.9188303 },
    { lat: 48.7328858, lng: 17.9187209 },
    { lat: 48.733009, lng: 17.9185911 },
    { lat: 48.7331196, lng: 17.9184018 },
    { lat: 48.7332357, lng: 17.9179857 },
    { lat: 48.7332358, lng: 17.9177435 },
    { lat: 48.7332208, lng: 17.9175104 },
    { lat: 48.7331757, lng: 17.9171928 },
    { lat: 48.7331513, lng: 17.9168225 },
    { lat: 48.7331656, lng: 17.916515 },
    { lat: 48.7331103, lng: 17.916308 },
    { lat: 48.7331092, lng: 17.9162152 },
    { lat: 48.7331003, lng: 17.9160108 },
    { lat: 48.733079, lng: 17.9159165 },
    { lat: 48.7330137, lng: 17.9157719 },
    { lat: 48.7329649, lng: 17.915633 },
    { lat: 48.7329116, lng: 17.9155359 },
    { lat: 48.73287, lng: 17.9154638 },
    { lat: 48.7328748, lng: 17.9152794 },
    { lat: 48.7328983, lng: 17.9151444 },
    { lat: 48.7329493, lng: 17.9150162 },
    { lat: 48.7330929, lng: 17.9147573 },
    { lat: 48.7331222, lng: 17.9146065 },
    { lat: 48.7331213, lng: 17.9144402 },
    { lat: 48.7330975, lng: 17.9143279 },
    { lat: 48.7330264, lng: 17.9141196 },
    { lat: 48.7330278, lng: 17.9140119 },
    { lat: 48.7330644, lng: 17.9138136 },
    { lat: 48.733005, lng: 17.9134238 },
    { lat: 48.7329938, lng: 17.9131437 },
    { lat: 48.7329488, lng: 17.9128633 },
    { lat: 48.7328846, lng: 17.9125854 },
    { lat: 48.732826, lng: 17.9120393 },
    { lat: 48.7328624, lng: 17.9120249 },
    { lat: 48.7331922, lng: 17.9119729 },
    { lat: 48.7336173, lng: 17.9117213 },
    { lat: 48.7338659, lng: 17.9115566 },
    { lat: 48.7342223, lng: 17.9114203 },
    { lat: 48.7346223, lng: 17.9111445 },
    { lat: 48.7350722, lng: 17.9110034 },
    { lat: 48.7350886, lng: 17.9112772 },
    { lat: 48.7353126, lng: 17.9111931 },
    { lat: 48.7355952, lng: 17.9109175 },
    { lat: 48.7361172, lng: 17.9108507 },
    { lat: 48.7366142, lng: 17.9107053 },
    { lat: 48.7366772, lng: 17.9107204 },
    { lat: 48.73681, lng: 17.910413 },
    { lat: 48.7368992, lng: 17.9102066 },
    { lat: 48.7372455, lng: 17.9095635 },
    { lat: 48.7375857, lng: 17.9089311 },
    { lat: 48.7377909, lng: 17.9082117 },
    { lat: 48.737831, lng: 17.9077531 },
    { lat: 48.7381425, lng: 17.9066997 },
    { lat: 48.7381416, lng: 17.9066841 },
    { lat: 48.7381237, lng: 17.9064237 },
    { lat: 48.7381172, lng: 17.9063354 },
    { lat: 48.7381129, lng: 17.9062805 },
    { lat: 48.7380329, lng: 17.9056272 },
    { lat: 48.7379959, lng: 17.9053289 },
    { lat: 48.7379826, lng: 17.9052208 },
    { lat: 48.7378752, lng: 17.9052559 },
    { lat: 48.7375413, lng: 17.9053334 },
    { lat: 48.7374284, lng: 17.9053357 },
    { lat: 48.7372681, lng: 17.9055039 },
    { lat: 48.7372879, lng: 17.905363 },
    { lat: 48.7372668, lng: 17.9051424 },
    { lat: 48.7372007, lng: 17.904965 },
    { lat: 48.7370363, lng: 17.9047832 },
    { lat: 48.7368869, lng: 17.9047018 },
    { lat: 48.7367304, lng: 17.9046904 },
    { lat: 48.7365995, lng: 17.9047271 },
    { lat: 48.7362096, lng: 17.9049271 },
    { lat: 48.7361407, lng: 17.9049625 },
    { lat: 48.7359658, lng: 17.9050028 },
    { lat: 48.7357972, lng: 17.9042435 },
    { lat: 48.7357661, lng: 17.9037067 },
    { lat: 48.7358031, lng: 17.9035724 },
    { lat: 48.7358061, lng: 17.9035652 },
    { lat: 48.7358348, lng: 17.90346 },
    { lat: 48.7358108, lng: 17.9033248 },
    { lat: 48.7357923, lng: 17.9032227 },
    { lat: 48.7357538, lng: 17.9030143 },
    { lat: 48.7357383, lng: 17.9027763 },
    { lat: 48.7357836, lng: 17.9027773 },
    { lat: 48.7357862, lng: 17.9027095 },
    { lat: 48.7357931, lng: 17.9025226 },
    { lat: 48.7358005, lng: 17.9023453 },
    { lat: 48.7358064, lng: 17.9021807 },
    { lat: 48.7358143, lng: 17.9019912 },
    { lat: 48.7358216, lng: 17.901776 },
    { lat: 48.7358317, lng: 17.9015117 },
    { lat: 48.7358458, lng: 17.9011467 },
    { lat: 48.7358561, lng: 17.9008846 },
    { lat: 48.7363863, lng: 17.9007867 },
    { lat: 48.736385, lng: 17.9007278 },
    { lat: 48.7366814, lng: 17.9006624 },
    { lat: 48.7368849, lng: 17.9005159 },
    { lat: 48.7370216, lng: 17.9002568 },
    { lat: 48.7376478, lng: 17.9001197 },
    { lat: 48.738647, lng: 17.9000485 },
    { lat: 48.7387368, lng: 17.897506 },
    { lat: 48.738756, lng: 17.8969433 },
    { lat: 48.7388711, lng: 17.8936592 },
    { lat: 48.7389357, lng: 17.891844 },
    { lat: 48.7390449, lng: 17.8887634 },
    { lat: 48.7390596, lng: 17.8883145 },
    { lat: 48.7390652, lng: 17.8881449 },
    { lat: 48.7390901, lng: 17.8874332 },
    { lat: 48.7390929, lng: 17.8873495 },
    { lat: 48.7390964, lng: 17.8872569 },
    { lat: 48.7390978, lng: 17.8872051 },
    { lat: 48.7391009, lng: 17.8871252 },
    { lat: 48.7391028, lng: 17.8870637 },
    { lat: 48.7391087, lng: 17.8868998 },
    { lat: 48.7391119, lng: 17.8868205 },
    { lat: 48.7391128, lng: 17.8867806 },
    { lat: 48.7391138, lng: 17.8867408 },
    { lat: 48.7391149, lng: 17.8866473 },
    { lat: 48.7391148, lng: 17.8866076 },
    { lat: 48.7391108, lng: 17.8865595 },
    { lat: 48.739103, lng: 17.8864662 },
    { lat: 48.7390975, lng: 17.8863961 },
    { lat: 48.7390897, lng: 17.886303 },
    { lat: 48.7390839, lng: 17.886224 },
    { lat: 48.7390534, lng: 17.8858493 },
    { lat: 48.7390311, lng: 17.8855747 },
    { lat: 48.73902, lng: 17.8854461 },
    { lat: 48.7390079, lng: 17.8852825 },
    { lat: 48.7389916, lng: 17.8850873 },
    { lat: 48.7389783, lng: 17.8849218 },
    { lat: 48.7379995, lng: 17.872847 },
    { lat: 48.7379901, lng: 17.8727476 },
    { lat: 48.7391026, lng: 17.8731698 },
    { lat: 48.7390859, lng: 17.8730415 },
    { lat: 48.7386827, lng: 17.870066 },
    { lat: 48.738426, lng: 17.868132 },
    { lat: 48.7382931, lng: 17.867129 },
    { lat: 48.7382849, lng: 17.8670677 },
    { lat: 48.7381323, lng: 17.8659245 },
    { lat: 48.7379712, lng: 17.8647149 },
    { lat: 48.7379295, lng: 17.8643999 },
    { lat: 48.7378998, lng: 17.8641701 },
    { lat: 48.7378031, lng: 17.8634296 },
    { lat: 48.7377871, lng: 17.8633329 },
    { lat: 48.7377554, lng: 17.8630692 },
    { lat: 48.7377368, lng: 17.8629293 },
    { lat: 48.7377324, lng: 17.8628927 },
    { lat: 48.7376638, lng: 17.8623749 },
    { lat: 48.7374484, lng: 17.8607919 },
    { lat: 48.7373434, lng: 17.8600089 },
    { lat: 48.737178, lng: 17.8587847 },
    { lat: 48.7368727, lng: 17.8564995 },
    { lat: 48.7368613, lng: 17.8564042 },
    { lat: 48.7368434, lng: 17.8562761 },
    { lat: 48.7366617, lng: 17.8548028 },
    { lat: 48.7365852, lng: 17.8541922 },
    { lat: 48.7376183, lng: 17.8537464 },
    { lat: 48.7377139, lng: 17.8533508 },
    { lat: 48.7350702, lng: 17.8535571 },
    { lat: 48.729466, lng: 17.8520576 },
    { lat: 48.7285893, lng: 17.8518611 },
    { lat: 48.7254019, lng: 17.8519426 },
  ],
  Kočovce: [
    { lat: 48.7371853, lng: 17.9369493 },
    { lat: 48.7372184, lng: 17.9369341 },
    { lat: 48.7374358, lng: 17.9367772 },
    { lat: 48.7374781, lng: 17.9367484 },
    { lat: 48.7377768, lng: 17.9364009 },
    { lat: 48.7378773, lng: 17.9362511 },
    { lat: 48.7380156, lng: 17.9360543 },
    { lat: 48.7383738, lng: 17.9355489 },
    { lat: 48.7384492, lng: 17.9354509 },
    { lat: 48.7385236, lng: 17.9353576 },
    { lat: 48.7385601, lng: 17.9353246 },
    { lat: 48.7388539, lng: 17.935124 },
    { lat: 48.7389709, lng: 17.935058 },
    { lat: 48.7390771, lng: 17.9350222 },
    { lat: 48.7391938, lng: 17.9349926 },
    { lat: 48.7392258, lng: 17.9349805 },
    { lat: 48.7394752, lng: 17.9348667 },
    { lat: 48.7395292, lng: 17.9348443 },
    { lat: 48.7396057, lng: 17.9348202 },
    { lat: 48.7396881, lng: 17.9348017 },
    { lat: 48.7397957, lng: 17.9347894 },
    { lat: 48.739868, lng: 17.9347899 },
    { lat: 48.7401283, lng: 17.9348033 },
    { lat: 48.7402072, lng: 17.934819 },
    { lat: 48.7403633, lng: 17.9348626 },
    { lat: 48.7404646, lng: 17.9348952 },
    { lat: 48.7406575, lng: 17.9349677 },
    { lat: 48.7407059, lng: 17.9349859 },
    { lat: 48.7407795, lng: 17.9350091 },
    { lat: 48.7410319, lng: 17.935019 },
    { lat: 48.7410941, lng: 17.9350207 },
    { lat: 48.7412789, lng: 17.9350163 },
    { lat: 48.7413389, lng: 17.9350185 },
    { lat: 48.7415132, lng: 17.9350458 },
    { lat: 48.7417542, lng: 17.9350929 },
    { lat: 48.741836, lng: 17.9351402 },
    { lat: 48.7420232, lng: 17.93527 },
    { lat: 48.7421964, lng: 17.9353529 },
    { lat: 48.742372, lng: 17.9354591 },
    { lat: 48.7424789, lng: 17.9354522 },
    { lat: 48.7425504, lng: 17.9354399 },
    { lat: 48.7426743, lng: 17.9353968 },
    { lat: 48.7427844, lng: 17.935351 },
    { lat: 48.7430158, lng: 17.9351061 },
    { lat: 48.7432532, lng: 17.9349046 },
    { lat: 48.7433288, lng: 17.9348862 },
    { lat: 48.7435548, lng: 17.9349846 },
    { lat: 48.7436724, lng: 17.9350061 },
    { lat: 48.7438936, lng: 17.9349682 },
    { lat: 48.744269, lng: 17.9348285 },
    { lat: 48.7443905, lng: 17.9347825 },
    { lat: 48.7444909, lng: 17.9347452 },
    { lat: 48.7445234, lng: 17.934742 },
    { lat: 48.7447966, lng: 17.9347794 },
    { lat: 48.7449504, lng: 17.9347636 },
    { lat: 48.7450302, lng: 17.9347598 },
    { lat: 48.7450995, lng: 17.9347678 },
    { lat: 48.745324, lng: 17.9346706 },
    { lat: 48.7454338, lng: 17.9346014 },
    { lat: 48.7455421, lng: 17.9344863 },
    { lat: 48.7458552, lng: 17.9342301 },
    { lat: 48.7459785, lng: 17.9341582 },
    { lat: 48.7461606, lng: 17.9340698 },
    { lat: 48.7463841, lng: 17.9339564 },
    { lat: 48.7464829, lng: 17.9338734 },
    { lat: 48.7467843, lng: 17.9334959 },
    { lat: 48.7470766, lng: 17.9332873 },
    { lat: 48.7472389, lng: 17.9332079 },
    { lat: 48.7474498, lng: 17.9331851 },
    { lat: 48.7476769, lng: 17.9331126 },
    { lat: 48.7480177, lng: 17.9330375 },
    { lat: 48.7482364, lng: 17.9329563 },
    { lat: 48.7483071, lng: 17.932893 },
    { lat: 48.7482966, lng: 17.9328679 },
    { lat: 48.748136, lng: 17.9325424 },
    { lat: 48.7480943, lng: 17.9321672 },
    { lat: 48.7481252, lng: 17.9317388 },
    { lat: 48.7485765, lng: 17.9311325 },
    { lat: 48.7490673, lng: 17.9303553 },
    { lat: 48.7492134, lng: 17.9300613 },
    { lat: 48.7494414, lng: 17.9298914 },
    { lat: 48.7495966, lng: 17.9302044 },
    { lat: 48.749792, lng: 17.930186 },
    { lat: 48.7506459, lng: 17.9287618 },
    { lat: 48.750856, lng: 17.9284253 },
    { lat: 48.7509594, lng: 17.9281162 },
    { lat: 48.7507955, lng: 17.9276595 },
    { lat: 48.7506149, lng: 17.9267748 },
    { lat: 48.7504623, lng: 17.9262606 },
    { lat: 48.7503146, lng: 17.9259628 },
    { lat: 48.7501892, lng: 17.9260168 },
    { lat: 48.7500355, lng: 17.9261266 },
    { lat: 48.7493911, lng: 17.9262604 },
    { lat: 48.7490777, lng: 17.9262679 },
    { lat: 48.7490296, lng: 17.9262178 },
    { lat: 48.74885, lng: 17.9263107 },
    { lat: 48.7486862, lng: 17.9264603 },
    { lat: 48.7484738, lng: 17.926532 },
    { lat: 48.7484225, lng: 17.9265411 },
    { lat: 48.7478661, lng: 17.926882 },
    { lat: 48.7477351, lng: 17.9269749 },
    { lat: 48.7476212, lng: 17.9270516 },
    { lat: 48.7474905, lng: 17.9271126 },
    { lat: 48.747323, lng: 17.9271615 },
    { lat: 48.7471793, lng: 17.9272507 },
    { lat: 48.7469966, lng: 17.927461 },
    { lat: 48.746869, lng: 17.9276478 },
    { lat: 48.7467838, lng: 17.9277355 },
    { lat: 48.7466484, lng: 17.9278095 },
    { lat: 48.746546, lng: 17.9278533 },
    { lat: 48.7464631, lng: 17.9278638 },
    { lat: 48.746084, lng: 17.9280539 },
    { lat: 48.7459709, lng: 17.9281817 },
    { lat: 48.7459126, lng: 17.9280207 },
    { lat: 48.745883, lng: 17.9279407 },
    { lat: 48.7464656, lng: 17.9272456 },
    { lat: 48.7465708, lng: 17.9271164 },
    { lat: 48.7468536, lng: 17.9265038 },
    { lat: 48.7468998, lng: 17.9261641 },
    { lat: 48.7473038, lng: 17.9257676 },
    { lat: 48.7481389, lng: 17.9255384 },
    { lat: 48.748234, lng: 17.9258253 },
    { lat: 48.7485383, lng: 17.9256475 },
    { lat: 48.7489414, lng: 17.9255185 },
    { lat: 48.7491004, lng: 17.9254591 },
    { lat: 48.7494606, lng: 17.9247088 },
    { lat: 48.7494486, lng: 17.9246963 },
    { lat: 48.7497918, lng: 17.9240365 },
    { lat: 48.7499881, lng: 17.9236584 },
    { lat: 48.7501939, lng: 17.9233618 },
    { lat: 48.7504019, lng: 17.9230282 },
    { lat: 48.7503634, lng: 17.9227621 },
    { lat: 48.750308, lng: 17.9223655 },
    { lat: 48.7503067, lng: 17.9217034 },
    { lat: 48.750919, lng: 17.9216544 },
    { lat: 48.7514552, lng: 17.9214876 },
    { lat: 48.7518125, lng: 17.9209876 },
    { lat: 48.7518051, lng: 17.9209211 },
    { lat: 48.7523996, lng: 17.9205383 },
    { lat: 48.7525282, lng: 17.9201598 },
    { lat: 48.7525384, lng: 17.9198404 },
    { lat: 48.7524949, lng: 17.9196215 },
    { lat: 48.7536283, lng: 17.9190401 },
    { lat: 48.7538203, lng: 17.9191184 },
    { lat: 48.7540465, lng: 17.918993 },
    { lat: 48.754354, lng: 17.9187374 },
    { lat: 48.7547747, lng: 17.9182249 },
    { lat: 48.7548767, lng: 17.9180415 },
    { lat: 48.7549144, lng: 17.9179748 },
    { lat: 48.7549174, lng: 17.9179685 },
    { lat: 48.7549263, lng: 17.917948 },
    { lat: 48.7550754, lng: 17.9180436 },
    { lat: 48.7553668, lng: 17.9183852 },
    { lat: 48.7555814, lng: 17.9185756 },
    { lat: 48.755671, lng: 17.9185633 },
    { lat: 48.755807, lng: 17.9183484 },
    { lat: 48.7558867, lng: 17.9181697 },
    { lat: 48.7560034, lng: 17.9177432 },
    { lat: 48.7560399, lng: 17.9175812 },
    { lat: 48.7561183, lng: 17.9167219 },
    { lat: 48.7562063, lng: 17.916082 },
    { lat: 48.7562093, lng: 17.9156249 },
    { lat: 48.7562902, lng: 17.9152792 },
    { lat: 48.7563154, lng: 17.9150414 },
    { lat: 48.7562964, lng: 17.9147798 },
    { lat: 48.7560482, lng: 17.9134648 },
    { lat: 48.7560485, lng: 17.9134319 },
    { lat: 48.7560786, lng: 17.9125794 },
    { lat: 48.7560787, lng: 17.912561 },
    { lat: 48.7560778, lng: 17.9125466 },
    { lat: 48.7560594, lng: 17.9122221 },
    { lat: 48.7560556, lng: 17.9121592 },
    { lat: 48.7560384, lng: 17.9118718 },
    { lat: 48.756017, lng: 17.9118729 },
    { lat: 48.7560054, lng: 17.9113778 },
    { lat: 48.7560004, lng: 17.9111611 },
    { lat: 48.7560392, lng: 17.910963 },
    { lat: 48.7560515, lng: 17.9109032 },
    { lat: 48.7560574, lng: 17.9108716 },
    { lat: 48.7561473, lng: 17.9104509 },
    { lat: 48.7561042, lng: 17.9100138 },
    { lat: 48.7560999, lng: 17.9099247 },
    { lat: 48.7560946, lng: 17.9097992 },
    { lat: 48.75609, lng: 17.9097605 },
    { lat: 48.7560724, lng: 17.9096157 },
    { lat: 48.756056, lng: 17.909475 },
    { lat: 48.7560448, lng: 17.9093808 },
    { lat: 48.7560447, lng: 17.9093796 },
    { lat: 48.7560318, lng: 17.9093345 },
    { lat: 48.7559936, lng: 17.9091886 },
    { lat: 48.7559531, lng: 17.9090387 },
    { lat: 48.7559488, lng: 17.9090227 },
    { lat: 48.7558954, lng: 17.9086421 },
    { lat: 48.7558936, lng: 17.9086312 },
    { lat: 48.7558924, lng: 17.9083468 },
    { lat: 48.7558921, lng: 17.9083053 },
    { lat: 48.7559103, lng: 17.9081005 },
    { lat: 48.7559176, lng: 17.9080148 },
    { lat: 48.7559409, lng: 17.9078203 },
    { lat: 48.755951, lng: 17.9077442 },
    { lat: 48.7559623, lng: 17.9076505 },
    { lat: 48.7559745, lng: 17.9075519 },
    { lat: 48.7559829, lng: 17.9074871 },
    { lat: 48.7560177, lng: 17.9072015 },
    { lat: 48.7560216, lng: 17.9071904 },
    { lat: 48.7560294, lng: 17.9071705 },
    { lat: 48.7560398, lng: 17.9071362 },
    { lat: 48.7560604, lng: 17.9070665 },
    { lat: 48.7560991, lng: 17.9067703 },
    { lat: 48.7561246, lng: 17.9065752 },
    { lat: 48.7561537, lng: 17.9063446 },
    { lat: 48.7561287, lng: 17.9061727 },
    { lat: 48.7561041, lng: 17.9060266 },
    { lat: 48.7560983, lng: 17.9059861 },
    { lat: 48.7560987, lng: 17.9059361 },
    { lat: 48.7560984, lng: 17.9059313 },
    { lat: 48.7561001, lng: 17.9058288 },
    { lat: 48.7561123, lng: 17.9057123 },
    { lat: 48.756115, lng: 17.9056824 },
    { lat: 48.7561499, lng: 17.9053413 },
    { lat: 48.7561629, lng: 17.9051825 },
    { lat: 48.7561666, lng: 17.9051324 },
    { lat: 48.7561679, lng: 17.9051152 },
    { lat: 48.756188, lng: 17.905037 },
    { lat: 48.7564939, lng: 17.9042083 },
    { lat: 48.7565171, lng: 17.9041445 },
    { lat: 48.7565239, lng: 17.9041257 },
    { lat: 48.7565307, lng: 17.9041081 },
    { lat: 48.7565412, lng: 17.904075 },
    { lat: 48.7565563, lng: 17.9040273 },
    { lat: 48.7565781, lng: 17.9039572 },
    { lat: 48.7566076, lng: 17.9038653 },
    { lat: 48.7566681, lng: 17.9036717 },
    { lat: 48.7567378, lng: 17.9034765 },
    { lat: 48.756798, lng: 17.9032788 },
    { lat: 48.756835, lng: 17.9031798 },
    { lat: 48.7568683, lng: 17.9030766 },
    { lat: 48.7569207, lng: 17.9029324 },
    { lat: 48.7569573, lng: 17.9028464 },
    { lat: 48.756988, lng: 17.9027742 },
    { lat: 48.7570315, lng: 17.9026721 },
    { lat: 48.7571161, lng: 17.9024664 },
    { lat: 48.7572166, lng: 17.9022221 },
    { lat: 48.7572354, lng: 17.9021785 },
    { lat: 48.7572935, lng: 17.9020362 },
    { lat: 48.7573365, lng: 17.901926 },
    { lat: 48.7573862, lng: 17.9018135 },
    { lat: 48.7574201, lng: 17.9017008 },
    { lat: 48.7574308, lng: 17.9016725 },
    { lat: 48.7574562, lng: 17.9016071 },
    { lat: 48.7575134, lng: 17.9014693 },
    { lat: 48.7576044, lng: 17.9012379 },
    { lat: 48.757704, lng: 17.9009972 },
    { lat: 48.757752, lng: 17.9008749 },
    { lat: 48.7578541, lng: 17.9006201 },
    { lat: 48.7579317, lng: 17.9004101 },
    { lat: 48.7579483, lng: 17.9003665 },
    { lat: 48.7579979, lng: 17.9002341 },
    { lat: 48.7580617, lng: 17.900056 },
    { lat: 48.7580915, lng: 17.8999515 },
    { lat: 48.7581723, lng: 17.8996796 },
    { lat: 48.7582452, lng: 17.89939 },
    { lat: 48.7583223, lng: 17.8990943 },
    { lat: 48.758405, lng: 17.8987992 },
    { lat: 48.758487, lng: 17.8984933 },
    { lat: 48.7584891, lng: 17.898488 },
    { lat: 48.7585261, lng: 17.8983343 },
    { lat: 48.7585605, lng: 17.8981752 },
    { lat: 48.7586318, lng: 17.8978584 },
    { lat: 48.7586659, lng: 17.8976945 },
    { lat: 48.7586956, lng: 17.8975509 },
    { lat: 48.7587353, lng: 17.8973644 },
    { lat: 48.7587725, lng: 17.8971954 },
    { lat: 48.7588787, lng: 17.8966909 },
    { lat: 48.7589151, lng: 17.8965073 },
    { lat: 48.758946, lng: 17.8963448 },
    { lat: 48.7589947, lng: 17.8959902 },
    { lat: 48.7590415, lng: 17.8956231 },
    { lat: 48.7590463, lng: 17.8955716 },
    { lat: 48.7590483, lng: 17.8955497 },
    { lat: 48.7590737, lng: 17.8952759 },
    { lat: 48.7599636, lng: 17.8955082 },
    { lat: 48.7609715, lng: 17.8957699 },
    { lat: 48.7610132, lng: 17.8957701 },
    { lat: 48.7620255, lng: 17.8960274 },
    { lat: 48.7627798, lng: 17.8962208 },
    { lat: 48.7627813, lng: 17.8966206 },
    { lat: 48.7627737, lng: 17.8968132 },
    { lat: 48.7627604, lng: 17.897192 },
    { lat: 48.7627547, lng: 17.8973797 },
    { lat: 48.7627498, lng: 17.8975597 },
    { lat: 48.7627703, lng: 17.8979393 },
    { lat: 48.7628227, lng: 17.8984545 },
    { lat: 48.7628581, lng: 17.8987994 },
    { lat: 48.7628796, lng: 17.8987998 },
    { lat: 48.7629406, lng: 17.8988007 },
    { lat: 48.7629565, lng: 17.8988016 },
    { lat: 48.7629701, lng: 17.898784 },
    { lat: 48.7629992, lng: 17.8987427 },
    { lat: 48.7632084, lng: 17.8980365 },
    { lat: 48.7632582, lng: 17.8978679 },
    { lat: 48.7633495, lng: 17.897735 },
    { lat: 48.7635745, lng: 17.8976094 },
    { lat: 48.7635876, lng: 17.8974893 },
    { lat: 48.76362, lng: 17.8972961 },
    { lat: 48.763626, lng: 17.8972066 },
    { lat: 48.7635851, lng: 17.8970533 },
    { lat: 48.7635778, lng: 17.8969323 },
    { lat: 48.763582, lng: 17.8967588 },
    { lat: 48.7635845, lng: 17.8966684 },
    { lat: 48.7635053, lng: 17.8964206 },
    { lat: 48.7634829, lng: 17.896348 },
    { lat: 48.7634406, lng: 17.8962635 },
    { lat: 48.7633324, lng: 17.8961365 },
    { lat: 48.7633859, lng: 17.8957115 },
    { lat: 48.763396, lng: 17.8956353 },
    { lat: 48.7634144, lng: 17.8954175 },
    { lat: 48.7634174, lng: 17.8952788 },
    { lat: 48.7634239, lng: 17.8948779 },
    { lat: 48.7634261, lng: 17.8948039 },
    { lat: 48.7634243, lng: 17.8944725 },
    { lat: 48.7634237, lng: 17.8944627 },
    { lat: 48.7634545, lng: 17.8941306 },
    { lat: 48.7634466, lng: 17.8939619 },
    { lat: 48.7636034, lng: 17.8939215 },
    { lat: 48.7638335, lng: 17.8938625 },
    { lat: 48.7638206, lng: 17.8936859 },
    { lat: 48.7638168, lng: 17.8936415 },
    { lat: 48.7637869, lng: 17.8932397 },
    { lat: 48.7637542, lng: 17.892676 },
    { lat: 48.763643, lng: 17.8926473 },
    { lat: 48.7634995, lng: 17.8926655 },
    { lat: 48.7635507, lng: 17.8922405 },
    { lat: 48.7635225, lng: 17.8922213 },
    { lat: 48.7635313, lng: 17.8919734 },
    { lat: 48.7636549, lng: 17.8909873 },
    { lat: 48.7638069, lng: 17.8898191 },
    { lat: 48.7638973, lng: 17.8890885 },
    { lat: 48.7639138, lng: 17.8888755 },
    { lat: 48.7638218, lng: 17.8883037 },
    { lat: 48.7638168, lng: 17.8882749 },
    { lat: 48.763781, lng: 17.8880567 },
    { lat: 48.7637738, lng: 17.8880312 },
    { lat: 48.7637724, lng: 17.8880259 },
    { lat: 48.7637443, lng: 17.8879331 },
    { lat: 48.7637426, lng: 17.8879249 },
    { lat: 48.7637404, lng: 17.8878693 },
    { lat: 48.763737, lng: 17.8877754 },
    { lat: 48.7637998, lng: 17.8875435 },
    { lat: 48.7638313, lng: 17.887373 },
    { lat: 48.7639114, lng: 17.8870736 },
    { lat: 48.7640595, lng: 17.8867027 },
    { lat: 48.7641443, lng: 17.88644 },
    { lat: 48.7643146, lng: 17.8860255 },
    { lat: 48.7645353, lng: 17.8853982 },
    { lat: 48.7646394, lng: 17.8850458 },
    { lat: 48.7647283, lng: 17.8846664 },
    { lat: 48.7647444, lng: 17.8845584 },
    { lat: 48.7647535, lng: 17.8841486 },
    { lat: 48.7647429, lng: 17.8835972 },
    { lat: 48.7646316, lng: 17.8823683 },
    { lat: 48.7646631, lng: 17.8816723 },
    { lat: 48.7646991, lng: 17.8811834 },
    { lat: 48.7647099, lng: 17.8810456 },
    { lat: 48.7647324, lng: 17.8805567 },
    { lat: 48.764697, lng: 17.8801757 },
    { lat: 48.7646776, lng: 17.8799658 },
    { lat: 48.764678, lng: 17.8796908 },
    { lat: 48.7646821, lng: 17.8790662 },
    { lat: 48.7646885, lng: 17.8780109 },
    { lat: 48.7646883, lng: 17.8780087 },
    { lat: 48.7646746, lng: 17.8778357 },
    { lat: 48.7646632, lng: 17.8776857 },
    { lat: 48.7646626, lng: 17.8776759 },
    { lat: 48.7646564, lng: 17.8775909 },
    { lat: 48.7646522, lng: 17.8775416 },
    { lat: 48.764638, lng: 17.8773609 },
    { lat: 48.7646373, lng: 17.8773486 },
    { lat: 48.7646273, lng: 17.8772774 },
    { lat: 48.7646255, lng: 17.8772674 },
    { lat: 48.7643472, lng: 17.8751573 },
    { lat: 48.7643228, lng: 17.875165 },
    { lat: 48.7643183, lng: 17.8751665 },
    { lat: 48.764295, lng: 17.8751738 },
    { lat: 48.7641846, lng: 17.8752152 },
    { lat: 48.7641559, lng: 17.8752263 },
    { lat: 48.7641372, lng: 17.8752332 },
    { lat: 48.764124, lng: 17.8752381 },
    { lat: 48.7641097, lng: 17.8752438 },
    { lat: 48.7640776, lng: 17.8752555 },
    { lat: 48.7640412, lng: 17.8752692 },
    { lat: 48.7639981, lng: 17.8752849 },
    { lat: 48.7639857, lng: 17.8752303 },
    { lat: 48.7638324, lng: 17.874541 },
    { lat: 48.7637269, lng: 17.8740643 },
    { lat: 48.7636366, lng: 17.8738202 },
    { lat: 48.763475, lng: 17.8733862 },
    { lat: 48.7634575, lng: 17.8733421 },
    { lat: 48.7633048, lng: 17.8730361 },
    { lat: 48.7631004, lng: 17.8726972 },
    { lat: 48.7629365, lng: 17.8724706 },
    { lat: 48.7625629, lng: 17.8719663 },
    { lat: 48.7625146, lng: 17.8718936 },
    { lat: 48.7624719, lng: 17.8718034 },
    { lat: 48.762324, lng: 17.8714115 },
    { lat: 48.7622032, lng: 17.8709818 },
    { lat: 48.7621845, lng: 17.8709151 },
    { lat: 48.7623774, lng: 17.8708924 },
    { lat: 48.7625723, lng: 17.8708671 },
    { lat: 48.762566, lng: 17.8708387 },
    { lat: 48.7625036, lng: 17.8705344 },
    { lat: 48.7624143, lng: 17.8701011 },
    { lat: 48.7621673, lng: 17.8689092 },
    { lat: 48.7620718, lng: 17.8684481 },
    { lat: 48.762059, lng: 17.8683867 },
    { lat: 48.7620495, lng: 17.8683401 },
    { lat: 48.7620251, lng: 17.8682163 },
    { lat: 48.7620123, lng: 17.8681561 },
    { lat: 48.761985, lng: 17.86802 },
    { lat: 48.7619709, lng: 17.8679554 },
    { lat: 48.7619691, lng: 17.8679428 },
    { lat: 48.761958, lng: 17.8678905 },
    { lat: 48.7619561, lng: 17.8678787 },
    { lat: 48.7619131, lng: 17.8676709 },
    { lat: 48.7617588, lng: 17.866929 },
    { lat: 48.7617367, lng: 17.8668258 },
    { lat: 48.7617192, lng: 17.8667415 },
    { lat: 48.7615826, lng: 17.8660843 },
    { lat: 48.7614884, lng: 17.8656302 },
    { lat: 48.7614741, lng: 17.8655592 },
    { lat: 48.761463, lng: 17.8655062 },
    { lat: 48.7614485, lng: 17.8654348 },
    { lat: 48.7613088, lng: 17.8647668 },
    { lat: 48.7617563, lng: 17.8648134 },
    { lat: 48.7618438, lng: 17.8648222 },
    { lat: 48.7619017, lng: 17.8648278 },
    { lat: 48.761914, lng: 17.8643032 },
    { lat: 48.7620139, lng: 17.8643291 },
    { lat: 48.7621469, lng: 17.8643633 },
    { lat: 48.7622329, lng: 17.8643856 },
    { lat: 48.7622741, lng: 17.864396 },
    { lat: 48.7622756, lng: 17.8643831 },
    { lat: 48.7622888, lng: 17.8642652 },
    { lat: 48.7622909, lng: 17.8642439 },
    { lat: 48.7623042, lng: 17.8641294 },
    { lat: 48.7622084, lng: 17.8641115 },
    { lat: 48.762173, lng: 17.8641053 },
    { lat: 48.762116, lng: 17.8640948 },
    { lat: 48.7620326, lng: 17.8640789 },
    { lat: 48.7620063, lng: 17.8640745 },
    { lat: 48.7620419, lng: 17.863673 },
    { lat: 48.7620461, lng: 17.8636289 },
    { lat: 48.7620493, lng: 17.86359 },
    { lat: 48.7621062, lng: 17.8629427 },
    { lat: 48.7622057, lng: 17.8617876 },
    { lat: 48.7619838, lng: 17.8613302 },
    { lat: 48.7612323, lng: 17.859764 },
    { lat: 48.7612166, lng: 17.8597302 },
    { lat: 48.7611508, lng: 17.859592 },
    { lat: 48.7611394, lng: 17.8595551 },
    { lat: 48.7610182, lng: 17.8591554 },
    { lat: 48.7609053, lng: 17.8587803 },
    { lat: 48.76087, lng: 17.8586641 },
    { lat: 48.7608207, lng: 17.8585031 },
    { lat: 48.7607459, lng: 17.8582557 },
    { lat: 48.760691, lng: 17.8580745 },
    { lat: 48.7606867, lng: 17.8580615 },
    { lat: 48.7606698, lng: 17.8580045 },
    { lat: 48.7606556, lng: 17.8579565 },
    { lat: 48.760643, lng: 17.8579143 },
    { lat: 48.7605964, lng: 17.8577606 },
    { lat: 48.7605795, lng: 17.8577062 },
    { lat: 48.760502, lng: 17.8574509 },
    { lat: 48.7604908, lng: 17.8574142 },
    { lat: 48.7601651, lng: 17.8563417 },
    { lat: 48.7601368, lng: 17.8562274 },
    { lat: 48.7599419, lng: 17.8554314 },
    { lat: 48.7599011, lng: 17.8552616 },
    { lat: 48.7594502, lng: 17.8535363 },
    { lat: 48.7595345, lng: 17.8533454 },
    { lat: 48.7595723, lng: 17.8532603 },
    { lat: 48.7595754, lng: 17.8532538 },
    { lat: 48.7597501, lng: 17.8528575 },
    { lat: 48.7598494, lng: 17.852635 },
    { lat: 48.7599606, lng: 17.8523828 },
    { lat: 48.7600668, lng: 17.8521421 },
    { lat: 48.7603727, lng: 17.8509652 },
    { lat: 48.7603864, lng: 17.850913 },
    { lat: 48.7603911, lng: 17.8508961 },
    { lat: 48.7600541, lng: 17.8508685 },
    { lat: 48.7598646, lng: 17.8508529 },
    { lat: 48.7597365, lng: 17.8508429 },
    { lat: 48.7596219, lng: 17.8508336 },
    { lat: 48.7594235, lng: 17.8508183 },
    { lat: 48.7593814, lng: 17.8508145 },
    { lat: 48.7590218, lng: 17.8507857 },
    { lat: 48.7589118, lng: 17.8507766 },
    { lat: 48.7588494, lng: 17.850772 },
    { lat: 48.7587859, lng: 17.850767 },
    { lat: 48.7587326, lng: 17.850763 },
    { lat: 48.7568341, lng: 17.8506178 },
    { lat: 48.7568676, lng: 17.8478545 },
    { lat: 48.7561428, lng: 17.8477956 },
    { lat: 48.7560782, lng: 17.8478106 },
    { lat: 48.7558548, lng: 17.8476999 },
    { lat: 48.7557103, lng: 17.84755 },
    { lat: 48.7556115, lng: 17.847317 },
    { lat: 48.755603, lng: 17.8471765 },
    { lat: 48.7556178, lng: 17.8468995 },
    { lat: 48.7555982, lng: 17.8464656 },
    { lat: 48.7554989, lng: 17.8460359 },
    { lat: 48.7553693, lng: 17.8457414 },
    { lat: 48.755306, lng: 17.8455172 },
    { lat: 48.7552574, lng: 17.8453105 },
    { lat: 48.7550127, lng: 17.8451659 },
    { lat: 48.7549312, lng: 17.8450513 },
    { lat: 48.7547248, lng: 17.844867 },
    { lat: 48.754501, lng: 17.8448639 },
    { lat: 48.7538768, lng: 17.8448278 },
    { lat: 48.7533766, lng: 17.8449565 },
    { lat: 48.7529586, lng: 17.8450989 },
    { lat: 48.752776, lng: 17.8451963 },
    { lat: 48.7524412, lng: 17.8454843 },
    { lat: 48.7523077, lng: 17.8456466 },
    { lat: 48.7522482, lng: 17.8457736 },
    { lat: 48.7521947, lng: 17.8458877 },
    { lat: 48.752025, lng: 17.8461791 },
    { lat: 48.7518958, lng: 17.8465641 },
    { lat: 48.7518212, lng: 17.8468967 },
    { lat: 48.7517886, lng: 17.8471239 },
    { lat: 48.7516892, lng: 17.8475509 },
    { lat: 48.7516303, lng: 17.8478275 },
    { lat: 48.7516211, lng: 17.8480665 },
    { lat: 48.751646, lng: 17.8482922 },
    { lat: 48.7516395, lng: 17.8485945 },
    { lat: 48.7516406, lng: 17.8490408 },
    { lat: 48.7515833, lng: 17.8493252 },
    { lat: 48.7514094, lng: 17.8495101 },
    { lat: 48.7508708, lng: 17.8494883 },
    { lat: 48.7507255, lng: 17.8497009 },
    { lat: 48.7504359, lng: 17.849747 },
    { lat: 48.7502829, lng: 17.8496994 },
    { lat: 48.7502249, lng: 17.8496725 },
    { lat: 48.7500121, lng: 17.8496827 },
    { lat: 48.7496957, lng: 17.8497696 },
    { lat: 48.7490955, lng: 17.8500358 },
    { lat: 48.7485876, lng: 17.8500745 },
    { lat: 48.748026, lng: 17.8502343 },
    { lat: 48.7475408, lng: 17.8500876 },
    { lat: 48.7474223, lng: 17.8500135 },
    { lat: 48.7473041, lng: 17.8503931 },
    { lat: 48.7470253, lng: 17.8513265 },
    { lat: 48.7460847, lng: 17.8513399 },
    { lat: 48.7457348, lng: 17.8497006 },
    { lat: 48.7452252, lng: 17.8473072 },
    { lat: 48.7446395, lng: 17.8471787 },
    { lat: 48.7441294, lng: 17.8474227 },
    { lat: 48.7438384, lng: 17.8476896 },
    { lat: 48.7430936, lng: 17.8478426 },
    { lat: 48.7424017, lng: 17.8485516 },
    { lat: 48.7385578, lng: 17.8524861 },
    { lat: 48.7377139, lng: 17.8533508 },
    { lat: 48.7376183, lng: 17.8537464 },
    { lat: 48.7365852, lng: 17.8541922 },
    { lat: 48.7366617, lng: 17.8548028 },
    { lat: 48.7368434, lng: 17.8562761 },
    { lat: 48.7368613, lng: 17.8564042 },
    { lat: 48.7368727, lng: 17.8564995 },
    { lat: 48.737178, lng: 17.8587847 },
    { lat: 48.7373434, lng: 17.8600089 },
    { lat: 48.7374484, lng: 17.8607919 },
    { lat: 48.7376638, lng: 17.8623749 },
    { lat: 48.7377324, lng: 17.8628927 },
    { lat: 48.7377368, lng: 17.8629293 },
    { lat: 48.7377554, lng: 17.8630692 },
    { lat: 48.7377871, lng: 17.8633329 },
    { lat: 48.7378031, lng: 17.8634296 },
    { lat: 48.7378998, lng: 17.8641701 },
    { lat: 48.7379295, lng: 17.8643999 },
    { lat: 48.7379712, lng: 17.8647149 },
    { lat: 48.7381323, lng: 17.8659245 },
    { lat: 48.7382849, lng: 17.8670677 },
    { lat: 48.7382931, lng: 17.867129 },
    { lat: 48.738426, lng: 17.868132 },
    { lat: 48.7386827, lng: 17.870066 },
    { lat: 48.7390859, lng: 17.8730415 },
    { lat: 48.7391026, lng: 17.8731698 },
    { lat: 48.7379901, lng: 17.8727476 },
    { lat: 48.7379995, lng: 17.872847 },
    { lat: 48.7389783, lng: 17.8849218 },
    { lat: 48.7389916, lng: 17.8850873 },
    { lat: 48.7390079, lng: 17.8852825 },
    { lat: 48.73902, lng: 17.8854461 },
    { lat: 48.7390311, lng: 17.8855747 },
    { lat: 48.7390534, lng: 17.8858493 },
    { lat: 48.7390839, lng: 17.886224 },
    { lat: 48.7390897, lng: 17.886303 },
    { lat: 48.7390975, lng: 17.8863961 },
    { lat: 48.739103, lng: 17.8864662 },
    { lat: 48.7391108, lng: 17.8865595 },
    { lat: 48.7391148, lng: 17.8866076 },
    { lat: 48.7391149, lng: 17.8866473 },
    { lat: 48.7391138, lng: 17.8867408 },
    { lat: 48.7391128, lng: 17.8867806 },
    { lat: 48.7391119, lng: 17.8868205 },
    { lat: 48.7391087, lng: 17.8868998 },
    { lat: 48.7391028, lng: 17.8870637 },
    { lat: 48.7391009, lng: 17.8871252 },
    { lat: 48.7390978, lng: 17.8872051 },
    { lat: 48.7390964, lng: 17.8872569 },
    { lat: 48.7390929, lng: 17.8873495 },
    { lat: 48.7390901, lng: 17.8874332 },
    { lat: 48.7390652, lng: 17.8881449 },
    { lat: 48.7390596, lng: 17.8883145 },
    { lat: 48.7390449, lng: 17.8887634 },
    { lat: 48.7389357, lng: 17.891844 },
    { lat: 48.7388711, lng: 17.8936592 },
    { lat: 48.738756, lng: 17.8969433 },
    { lat: 48.7387368, lng: 17.897506 },
    { lat: 48.738647, lng: 17.9000485 },
    { lat: 48.7376478, lng: 17.9001197 },
    { lat: 48.7370216, lng: 17.9002568 },
    { lat: 48.7368849, lng: 17.9005159 },
    { lat: 48.7366814, lng: 17.9006624 },
    { lat: 48.736385, lng: 17.9007278 },
    { lat: 48.7363863, lng: 17.9007867 },
    { lat: 48.7358561, lng: 17.9008846 },
    { lat: 48.7358458, lng: 17.9011467 },
    { lat: 48.7358317, lng: 17.9015117 },
    { lat: 48.7358216, lng: 17.901776 },
    { lat: 48.7358143, lng: 17.9019912 },
    { lat: 48.7358064, lng: 17.9021807 },
    { lat: 48.7358005, lng: 17.9023453 },
    { lat: 48.7357931, lng: 17.9025226 },
    { lat: 48.7357862, lng: 17.9027095 },
    { lat: 48.7357836, lng: 17.9027773 },
    { lat: 48.7357383, lng: 17.9027763 },
    { lat: 48.7357538, lng: 17.9030143 },
    { lat: 48.7357923, lng: 17.9032227 },
    { lat: 48.7358108, lng: 17.9033248 },
    { lat: 48.7358348, lng: 17.90346 },
    { lat: 48.7358061, lng: 17.9035652 },
    { lat: 48.7358031, lng: 17.9035724 },
    { lat: 48.7357661, lng: 17.9037067 },
    { lat: 48.7357972, lng: 17.9042435 },
    { lat: 48.7359658, lng: 17.9050028 },
    { lat: 48.7361407, lng: 17.9049625 },
    { lat: 48.7362096, lng: 17.9049271 },
    { lat: 48.7365995, lng: 17.9047271 },
    { lat: 48.7367304, lng: 17.9046904 },
    { lat: 48.7368869, lng: 17.9047018 },
    { lat: 48.7370363, lng: 17.9047832 },
    { lat: 48.7372007, lng: 17.904965 },
    { lat: 48.7372668, lng: 17.9051424 },
    { lat: 48.7372879, lng: 17.905363 },
    { lat: 48.7372681, lng: 17.9055039 },
    { lat: 48.7374284, lng: 17.9053357 },
    { lat: 48.7375413, lng: 17.9053334 },
    { lat: 48.7378752, lng: 17.9052559 },
    { lat: 48.7379826, lng: 17.9052208 },
    { lat: 48.7379959, lng: 17.9053289 },
    { lat: 48.7380329, lng: 17.9056272 },
    { lat: 48.7381129, lng: 17.9062805 },
    { lat: 48.7381172, lng: 17.9063354 },
    { lat: 48.7381237, lng: 17.9064237 },
    { lat: 48.7381416, lng: 17.9066841 },
    { lat: 48.7381425, lng: 17.9066997 },
    { lat: 48.737831, lng: 17.9077531 },
    { lat: 48.7377909, lng: 17.9082117 },
    { lat: 48.7375857, lng: 17.9089311 },
    { lat: 48.7372455, lng: 17.9095635 },
    { lat: 48.7368992, lng: 17.9102066 },
    { lat: 48.73681, lng: 17.910413 },
    { lat: 48.7366772, lng: 17.9107204 },
    { lat: 48.7366142, lng: 17.9107053 },
    { lat: 48.7361172, lng: 17.9108507 },
    { lat: 48.7355952, lng: 17.9109175 },
    { lat: 48.7353126, lng: 17.9111931 },
    { lat: 48.7350886, lng: 17.9112772 },
    { lat: 48.7350722, lng: 17.9110034 },
    { lat: 48.7346223, lng: 17.9111445 },
    { lat: 48.7342223, lng: 17.9114203 },
    { lat: 48.7338659, lng: 17.9115566 },
    { lat: 48.7336173, lng: 17.9117213 },
    { lat: 48.7331922, lng: 17.9119729 },
    { lat: 48.7328624, lng: 17.9120249 },
    { lat: 48.732826, lng: 17.9120393 },
    { lat: 48.7328846, lng: 17.9125854 },
    { lat: 48.7329488, lng: 17.9128633 },
    { lat: 48.7329938, lng: 17.9131437 },
    { lat: 48.733005, lng: 17.9134238 },
    { lat: 48.7330644, lng: 17.9138136 },
    { lat: 48.7330278, lng: 17.9140119 },
    { lat: 48.7330264, lng: 17.9141196 },
    { lat: 48.7330975, lng: 17.9143279 },
    { lat: 48.7331213, lng: 17.9144402 },
    { lat: 48.7331222, lng: 17.9146065 },
    { lat: 48.7330929, lng: 17.9147573 },
    { lat: 48.7329493, lng: 17.9150162 },
    { lat: 48.7328983, lng: 17.9151444 },
    { lat: 48.7328748, lng: 17.9152794 },
    { lat: 48.73287, lng: 17.9154638 },
    { lat: 48.7329116, lng: 17.9155359 },
    { lat: 48.7329649, lng: 17.915633 },
    { lat: 48.7330137, lng: 17.9157719 },
    { lat: 48.733079, lng: 17.9159165 },
    { lat: 48.7331003, lng: 17.9160108 },
    { lat: 48.7331092, lng: 17.9162152 },
    { lat: 48.7331103, lng: 17.916308 },
    { lat: 48.7331656, lng: 17.916515 },
    { lat: 48.7331513, lng: 17.9168225 },
    { lat: 48.7331757, lng: 17.9171928 },
    { lat: 48.7332208, lng: 17.9175104 },
    { lat: 48.7332358, lng: 17.9177435 },
    { lat: 48.7332357, lng: 17.9179857 },
    { lat: 48.7331196, lng: 17.9184018 },
    { lat: 48.733009, lng: 17.9185911 },
    { lat: 48.7328858, lng: 17.9187209 },
    { lat: 48.7328315, lng: 17.9188303 },
    { lat: 48.7327661, lng: 17.9190202 },
    { lat: 48.7326295, lng: 17.9195082 },
    { lat: 48.7324584, lng: 17.9199868 },
    { lat: 48.7323185, lng: 17.9204202 },
    { lat: 48.7323, lng: 17.920604 },
    { lat: 48.7322863, lng: 17.9207318 },
    { lat: 48.7322468, lng: 17.921014 },
    { lat: 48.7322348, lng: 17.9210417 },
    { lat: 48.7321245, lng: 17.9211575 },
    { lat: 48.7320861, lng: 17.921309 },
    { lat: 48.7319731, lng: 17.9218517 },
    { lat: 48.7319396, lng: 17.9220849 },
    { lat: 48.7319262, lng: 17.9223513 },
    { lat: 48.7319414, lng: 17.9225488 },
    { lat: 48.7320272, lng: 17.9228878 },
    { lat: 48.7320807, lng: 17.9230464 },
    { lat: 48.7323721, lng: 17.923991 },
    { lat: 48.7325224, lng: 17.9245016 },
    { lat: 48.7325798, lng: 17.9247628 },
    { lat: 48.7330187, lng: 17.9258162 },
    { lat: 48.7331465, lng: 17.9260256 },
    { lat: 48.7333989, lng: 17.9264906 },
    { lat: 48.7335695, lng: 17.9268512 },
    { lat: 48.7338305, lng: 17.9272135 },
    { lat: 48.7339884, lng: 17.9275126 },
    { lat: 48.7341324, lng: 17.9277305 },
    { lat: 48.73422, lng: 17.9278364 },
    { lat: 48.7344329, lng: 17.9281489 },
    { lat: 48.7345859, lng: 17.9284052 },
    { lat: 48.734629, lng: 17.9285206 },
    { lat: 48.7346918, lng: 17.9287794 },
    { lat: 48.7347175, lng: 17.9289819 },
    { lat: 48.7347271, lng: 17.9292917 },
    { lat: 48.7349492, lng: 17.9298931 },
    { lat: 48.7351134, lng: 17.93024 },
    { lat: 48.7353117, lng: 17.9307423 },
    { lat: 48.7354282, lng: 17.9308984 },
    { lat: 48.7356247, lng: 17.9311824 },
    { lat: 48.7357613, lng: 17.9313152 },
    { lat: 48.7358668, lng: 17.9314749 },
    { lat: 48.7359914, lng: 17.9317653 },
    { lat: 48.7360797, lng: 17.9318829 },
    { lat: 48.7363121, lng: 17.9322761 },
    { lat: 48.7365599, lng: 17.932871 },
    { lat: 48.7366004, lng: 17.9330588 },
    { lat: 48.7366387, lng: 17.9333201 },
    { lat: 48.7366072, lng: 17.9335876 },
    { lat: 48.7366092, lng: 17.9338455 },
    { lat: 48.7366789, lng: 17.9342383 },
    { lat: 48.7367297, lng: 17.9344074 },
    { lat: 48.7368035, lng: 17.9345475 },
    { lat: 48.736974, lng: 17.9347359 },
    { lat: 48.7370487, lng: 17.9349092 },
    { lat: 48.7371669, lng: 17.935338 },
    { lat: 48.7371991, lng: 17.9355925 },
    { lat: 48.7373171, lng: 17.9359796 },
    { lat: 48.737316, lng: 17.9363017 },
    { lat: 48.7373131, lng: 17.9364818 },
    { lat: 48.737275, lng: 17.936693 },
    { lat: 48.7371965, lng: 17.9369081 },
    { lat: 48.7371853, lng: 17.9369493 },
  ],
  Vaďovce: [
    { lat: 48.7536594, lng: 17.7007751 },
    { lat: 48.753463, lng: 17.700635 },
    { lat: 48.753345, lng: 17.700645 },
    { lat: 48.753169, lng: 17.700615 },
    { lat: 48.752825, lng: 17.700553 },
    { lat: 48.752666, lng: 17.700706 },
    { lat: 48.752307, lng: 17.700886 },
    { lat: 48.752179, lng: 17.700861 },
    { lat: 48.752139, lng: 17.700629 },
    { lat: 48.751954, lng: 17.700687 },
    { lat: 48.751912, lng: 17.700666 },
    { lat: 48.751855, lng: 17.700434 },
    { lat: 48.751523, lng: 17.700427 },
    { lat: 48.751385, lng: 17.700434 },
    { lat: 48.751296, lng: 17.700467 },
    { lat: 48.751224, lng: 17.700514 },
    { lat: 48.751331, lng: 17.700923 },
    { lat: 48.751394, lng: 17.701096 },
    { lat: 48.751354, lng: 17.701197 },
    { lat: 48.75123, lng: 17.701426 },
    { lat: 48.751162, lng: 17.701407 },
    { lat: 48.751152, lng: 17.701472 },
    { lat: 48.751125, lng: 17.70164 },
    { lat: 48.751015, lng: 17.702131 },
    { lat: 48.751005, lng: 17.702177 },
    { lat: 48.750392, lng: 17.704906 },
    { lat: 48.750337, lng: 17.705127 },
    { lat: 48.748471, lng: 17.706771 },
    { lat: 48.747111, lng: 17.707718 },
    { lat: 48.746996, lng: 17.707798 },
    { lat: 48.745913, lng: 17.708306 },
    { lat: 48.745538, lng: 17.708456 },
    { lat: 48.745202, lng: 17.708668 },
    { lat: 48.745193, lng: 17.708673 },
    { lat: 48.745261, lng: 17.708726 },
    { lat: 48.744802, lng: 17.708854 },
    { lat: 48.744368, lng: 17.709107 },
    { lat: 48.743583, lng: 17.710427 },
    { lat: 48.743466, lng: 17.710556 },
    { lat: 48.743301, lng: 17.71075 },
    { lat: 48.743188, lng: 17.710885 },
    { lat: 48.742449, lng: 17.71128 },
    { lat: 48.741843, lng: 17.711616 },
    { lat: 48.741736, lng: 17.711675 },
    { lat: 48.739849, lng: 17.71272 },
    { lat: 48.739799, lng: 17.712748 },
    { lat: 48.739628, lng: 17.712984 },
    { lat: 48.739478, lng: 17.713162 },
    { lat: 48.738963, lng: 17.713835 },
    { lat: 48.738416, lng: 17.714523 },
    { lat: 48.738312, lng: 17.714309 },
    { lat: 48.738071, lng: 17.713899 },
    { lat: 48.738027, lng: 17.713801 },
    { lat: 48.737938, lng: 17.713601 },
    { lat: 48.73789, lng: 17.71346 },
    { lat: 48.737559, lng: 17.713937 },
    { lat: 48.73736, lng: 17.714159 },
    { lat: 48.73721, lng: 17.714326 },
    { lat: 48.737095, lng: 17.714515 },
    { lat: 48.737065, lng: 17.714504 },
    { lat: 48.737007, lng: 17.714483 },
    { lat: 48.736853, lng: 17.714429 },
    { lat: 48.736819, lng: 17.714421 },
    { lat: 48.736801, lng: 17.71446 },
    { lat: 48.736769, lng: 17.714446 },
    { lat: 48.73666, lng: 17.71458 },
    { lat: 48.736625, lng: 17.714681 },
    { lat: 48.736584, lng: 17.714635 },
    { lat: 48.736478, lng: 17.71451 },
    { lat: 48.736488, lng: 17.714308 },
    { lat: 48.736532, lng: 17.713397 },
    { lat: 48.736562, lng: 17.712829 },
    { lat: 48.736563, lng: 17.712811 },
    { lat: 48.736545, lng: 17.712809 },
    { lat: 48.736431, lng: 17.712794 },
    { lat: 48.736391, lng: 17.712789 },
    { lat: 48.736348, lng: 17.712784 },
    { lat: 48.736307, lng: 17.712779 },
    { lat: 48.736266, lng: 17.712774 },
    { lat: 48.736179, lng: 17.712763 },
    { lat: 48.736101, lng: 17.712753 },
    { lat: 48.736018, lng: 17.712743 },
    { lat: 48.73595, lng: 17.712734 },
    { lat: 48.735875, lng: 17.712725 },
    { lat: 48.735802, lng: 17.712716 },
    { lat: 48.73577, lng: 17.712712 },
    { lat: 48.735724, lng: 17.712707 },
    { lat: 48.735661, lng: 17.712711 },
    { lat: 48.735564, lng: 17.71272 },
    { lat: 48.734087, lng: 17.7134 },
    { lat: 48.733843, lng: 17.713496 },
    { lat: 48.732796, lng: 17.714016 },
    { lat: 48.732569, lng: 17.714103 },
    { lat: 48.732526, lng: 17.714089 },
    { lat: 48.73247, lng: 17.714125 },
    { lat: 48.732375, lng: 17.71413 },
    { lat: 48.732121, lng: 17.714095 },
    { lat: 48.731741, lng: 17.712926 },
    { lat: 48.731657, lng: 17.712668 },
    { lat: 48.731515, lng: 17.712771 },
    { lat: 48.730788, lng: 17.713292 },
    { lat: 48.730784, lng: 17.713279 },
    { lat: 48.730484, lng: 17.71235 },
    { lat: 48.730464, lng: 17.712293 },
    { lat: 48.730455, lng: 17.712267 },
    { lat: 48.730378, lng: 17.712373 },
    { lat: 48.730294, lng: 17.712488 },
    { lat: 48.730167, lng: 17.712598 },
    { lat: 48.730073, lng: 17.71259 },
    { lat: 48.7298, lng: 17.712716 },
    { lat: 48.72969, lng: 17.712735 },
    { lat: 48.729609, lng: 17.712495 },
    { lat: 48.72958, lng: 17.712615 },
    { lat: 48.729506, lng: 17.712607 },
    { lat: 48.729444, lng: 17.712727 },
    { lat: 48.72938, lng: 17.712813 },
    { lat: 48.729336, lng: 17.712895 },
    { lat: 48.729227, lng: 17.71298 },
    { lat: 48.729133, lng: 17.713005 },
    { lat: 48.729003, lng: 17.712997 },
    { lat: 48.728964, lng: 17.71304 },
    { lat: 48.728895, lng: 17.71326 },
    { lat: 48.728866, lng: 17.7133 },
    { lat: 48.728852, lng: 17.713319 },
    { lat: 48.72879, lng: 17.71334 },
    { lat: 48.728742, lng: 17.713388 },
    { lat: 48.728671, lng: 17.713399 },
    { lat: 48.728529, lng: 17.713492 },
    { lat: 48.728466, lng: 17.7135 },
    { lat: 48.728438, lng: 17.713793 },
    { lat: 48.728352, lng: 17.713809 },
    { lat: 48.728267, lng: 17.713898 },
    { lat: 48.728132, lng: 17.714277 },
    { lat: 48.727945, lng: 17.714383 },
    { lat: 48.727835, lng: 17.7146 },
    { lat: 48.727551, lng: 17.714828 },
    { lat: 48.727415, lng: 17.715017 },
    { lat: 48.727278, lng: 17.715086 },
    { lat: 48.727172, lng: 17.715186 },
    { lat: 48.72713, lng: 17.715284 },
    { lat: 48.727089, lng: 17.715487 },
    { lat: 48.727003, lng: 17.715723 },
    { lat: 48.726845, lng: 17.715808 },
    { lat: 48.726735, lng: 17.715839 },
    { lat: 48.726654, lng: 17.715911 },
    { lat: 48.726544, lng: 17.71589 },
    { lat: 48.7265434, lng: 17.7159542 },
    { lat: 48.7253427, lng: 17.7166772 },
    { lat: 48.725165, lng: 17.7162138 },
    { lat: 48.7246028, lng: 17.7166539 },
    { lat: 48.7244037, lng: 17.7165175 },
    { lat: 48.7246331, lng: 17.7162294 },
    { lat: 48.7245489, lng: 17.7161451 },
    { lat: 48.7244424, lng: 17.7161022 },
    { lat: 48.7242182, lng: 17.7163495 },
    { lat: 48.7241072, lng: 17.7165049 },
    { lat: 48.7239203, lng: 17.7168621 },
    { lat: 48.7236323, lng: 17.7171369 },
    { lat: 48.7233709, lng: 17.7175478 },
    { lat: 48.7231654, lng: 17.7178017 },
    { lat: 48.7233673, lng: 17.7178727 },
    { lat: 48.7235513, lng: 17.7179823 },
    { lat: 48.7238693, lng: 17.7182117 },
    { lat: 48.7251196, lng: 17.7189687 },
    { lat: 48.7266707, lng: 17.7197902 },
    { lat: 48.726639, lng: 17.7199499 },
    { lat: 48.7264606, lng: 17.7211427 },
    { lat: 48.7263122, lng: 17.7211336 },
    { lat: 48.7252065, lng: 17.7209976 },
    { lat: 48.7251962, lng: 17.7212337 },
    { lat: 48.7248906, lng: 17.7224507 },
    { lat: 48.724384, lng: 17.7226913 },
    { lat: 48.7242034, lng: 17.7233529 },
    { lat: 48.7243404, lng: 17.7237985 },
    { lat: 48.7248608, lng: 17.7236668 },
    { lat: 48.7249061, lng: 17.7241092 },
    { lat: 48.7256948, lng: 17.7238808 },
    { lat: 48.7257855, lng: 17.7242703 },
    { lat: 48.7264309, lng: 17.7243003 },
    { lat: 48.7275765, lng: 17.7246609 },
    { lat: 48.7274898, lng: 17.725196 },
    { lat: 48.7273706, lng: 17.725462 },
    { lat: 48.7272467, lng: 17.7261071 },
    { lat: 48.7270514, lng: 17.727353 },
    { lat: 48.727004, lng: 17.7278661 },
    { lat: 48.7274527, lng: 17.72809 },
    { lat: 48.7282692, lng: 17.7284789 },
    { lat: 48.728165, lng: 17.7292056 },
    { lat: 48.7285465, lng: 17.7296647 },
    { lat: 48.7288655, lng: 17.7299116 },
    { lat: 48.7294074, lng: 17.7309729 },
    { lat: 48.7294325, lng: 17.73118 },
    { lat: 48.7293896, lng: 17.7313252 },
    { lat: 48.7291705, lng: 17.7316354 },
    { lat: 48.7291852, lng: 17.7316708 },
    { lat: 48.729123, lng: 17.731764 },
    { lat: 48.7289888, lng: 17.731912 },
    { lat: 48.7286342, lng: 17.7324436 },
    { lat: 48.7281127, lng: 17.7332333 },
    { lat: 48.7280157, lng: 17.733345 },
    { lat: 48.7276602, lng: 17.7339146 },
    { lat: 48.7275157, lng: 17.7341542 },
    { lat: 48.7273531, lng: 17.7344098 },
    { lat: 48.7275951, lng: 17.7348164 },
    { lat: 48.7278651, lng: 17.7352057 },
    { lat: 48.7284258, lng: 17.7359898 },
    { lat: 48.7286273, lng: 17.7363069 },
    { lat: 48.7288609, lng: 17.7367815 },
    { lat: 48.7291543, lng: 17.7374576 },
    { lat: 48.7290904, lng: 17.7375953 },
    { lat: 48.7290461, lng: 17.7377375 },
    { lat: 48.7287767, lng: 17.7380189 },
    { lat: 48.7286782, lng: 17.7382166 },
    { lat: 48.7285578, lng: 17.7383715 },
    { lat: 48.7284214, lng: 17.738611 },
    { lat: 48.7281096, lng: 17.7390507 },
    { lat: 48.728009, lng: 17.7391765 },
    { lat: 48.7277652, lng: 17.7396159 },
    { lat: 48.7275739, lng: 17.7398835 },
    { lat: 48.7276271, lng: 17.7400323 },
    { lat: 48.7278056, lng: 17.7400723 },
    { lat: 48.7284111, lng: 17.7392705 },
    { lat: 48.7289822, lng: 17.7384785 },
    { lat: 48.7295857, lng: 17.7377553 },
    { lat: 48.7296936, lng: 17.7378746 },
    { lat: 48.7298181, lng: 17.7379529 },
    { lat: 48.7298882, lng: 17.7379538 },
    { lat: 48.7300037, lng: 17.7381621 },
    { lat: 48.7300672, lng: 17.7381296 },
    { lat: 48.7301701, lng: 17.7382059 },
    { lat: 48.7302334, lng: 17.7382807 },
    { lat: 48.7303867, lng: 17.7383875 },
    { lat: 48.7304618, lng: 17.738505 },
    { lat: 48.7306425, lng: 17.7385972 },
    { lat: 48.7307886, lng: 17.7385678 },
    { lat: 48.7309465, lng: 17.7389694 },
    { lat: 48.730985, lng: 17.7391571 },
    { lat: 48.7310354, lng: 17.7392215 },
    { lat: 48.7312732, lng: 17.7390872 },
    { lat: 48.7319684, lng: 17.7386597 },
    { lat: 48.7327637, lng: 17.7382274 },
    { lat: 48.7329828, lng: 17.7381386 },
    { lat: 48.7334804, lng: 17.7378204 },
    { lat: 48.7337242, lng: 17.7377457 },
    { lat: 48.7339547, lng: 17.7377126 },
    { lat: 48.7340581, lng: 17.7376852 },
    { lat: 48.7344004, lng: 17.7376152 },
    { lat: 48.7350586, lng: 17.7374504 },
    { lat: 48.7355503, lng: 17.7373669 },
    { lat: 48.7356925, lng: 17.7376248 },
    { lat: 48.7357607, lng: 17.7376685 },
    { lat: 48.7359775, lng: 17.7377034 },
    { lat: 48.7361725, lng: 17.7377164 },
    { lat: 48.7367603, lng: 17.7377113 },
    { lat: 48.7371685, lng: 17.7377201 },
    { lat: 48.7371645, lng: 17.7378391 },
    { lat: 48.7370965, lng: 17.7380765 },
    { lat: 48.7370734, lng: 17.7382496 },
    { lat: 48.7370152, lng: 17.7383114 },
    { lat: 48.7370071, lng: 17.7388763 },
    { lat: 48.7370686, lng: 17.7391606 },
    { lat: 48.7372474, lng: 17.7394824 },
    { lat: 48.7372538, lng: 17.7395484 },
    { lat: 48.7373074, lng: 17.7395576 },
    { lat: 48.7373677, lng: 17.7397308 },
    { lat: 48.7373812, lng: 17.7398388 },
    { lat: 48.7374671, lng: 17.7399301 },
    { lat: 48.7377207, lng: 17.7399992 },
    { lat: 48.7380689, lng: 17.7399334 },
    { lat: 48.7381377, lng: 17.7398948 },
    { lat: 48.7382858, lng: 17.7398786 },
    { lat: 48.7385488, lng: 17.7399156 },
    { lat: 48.7385707, lng: 17.7400864 },
    { lat: 48.7385686, lng: 17.7402187 },
    { lat: 48.7387057, lng: 17.7403006 },
    { lat: 48.7389031, lng: 17.7401673 },
    { lat: 48.7389371, lng: 17.7400789 },
    { lat: 48.7391051, lng: 17.7399472 },
    { lat: 48.7392733, lng: 17.7401128 },
    { lat: 48.7395679, lng: 17.740149 },
    { lat: 48.7396116, lng: 17.7401056 },
    { lat: 48.7396456, lng: 17.7399613 },
    { lat: 48.7397214, lng: 17.7399442 },
    { lat: 48.7397834, lng: 17.7399792 },
    { lat: 48.7399099, lng: 17.7398888 },
    { lat: 48.7400317, lng: 17.7397582 },
    { lat: 48.7401355, lng: 17.7397179 },
    { lat: 48.7402035, lng: 17.7397774 },
    { lat: 48.7403029, lng: 17.7397773 },
    { lat: 48.740491, lng: 17.7397362 },
    { lat: 48.7406075, lng: 17.7398676 },
    { lat: 48.7407786, lng: 17.7399891 },
    { lat: 48.7411314, lng: 17.7402539 },
    { lat: 48.7412659, lng: 17.7403294 },
    { lat: 48.7412709, lng: 17.7405036 },
    { lat: 48.7412983, lng: 17.7407094 },
    { lat: 48.7413098, lng: 17.7410615 },
    { lat: 48.7415456, lng: 17.7416994 },
    { lat: 48.7417381, lng: 17.7419131 },
    { lat: 48.7419114, lng: 17.742196 },
    { lat: 48.7421446, lng: 17.7424991 },
    { lat: 48.7422745, lng: 17.7426293 },
    { lat: 48.7429848, lng: 17.7431082 },
    { lat: 48.7431447, lng: 17.7426615 },
    { lat: 48.7433983, lng: 17.7427643 },
    { lat: 48.7435062, lng: 17.74283 },
    { lat: 48.7436922, lng: 17.7430274 },
    { lat: 48.7437566, lng: 17.7430477 },
    { lat: 48.7438449, lng: 17.7431068 },
    { lat: 48.7440072, lng: 17.7431952 },
    { lat: 48.7444659, lng: 17.7434169 },
    { lat: 48.7446026, lng: 17.7434582 },
    { lat: 48.7447166, lng: 17.743459 },
    { lat: 48.7448755, lng: 17.7433225 },
    { lat: 48.7450277, lng: 17.7435036 },
    { lat: 48.7452759, lng: 17.7433692 },
    { lat: 48.7453923, lng: 17.7434663 },
    { lat: 48.7454383, lng: 17.7435911 },
    { lat: 48.7455306, lng: 17.7436761 },
    { lat: 48.745758, lng: 17.7438112 },
    { lat: 48.7457873, lng: 17.7440293 },
    { lat: 48.7458675, lng: 17.7441801 },
    { lat: 48.7459335, lng: 17.7443725 },
    { lat: 48.7459725, lng: 17.7446379 },
    { lat: 48.7461222, lng: 17.7448712 },
    { lat: 48.7462238, lng: 17.7451456 },
    { lat: 48.7462033, lng: 17.7451978 },
    { lat: 48.7462117, lng: 17.7453537 },
    { lat: 48.746304, lng: 17.7457343 },
    { lat: 48.7462785, lng: 17.7458319 },
    { lat: 48.7463417, lng: 17.7459973 },
    { lat: 48.7463869, lng: 17.7461633 },
    { lat: 48.7463858, lng: 17.7462919 },
    { lat: 48.7464335, lng: 17.7464258 },
    { lat: 48.7465034, lng: 17.7465341 },
    { lat: 48.7465002, lng: 17.7468336 },
    { lat: 48.7465861, lng: 17.7470183 },
    { lat: 48.7465986, lng: 17.7471829 },
    { lat: 48.7467222, lng: 17.7477436 },
    { lat: 48.7469114, lng: 17.7478454 },
    { lat: 48.7469661, lng: 17.7478353 },
    { lat: 48.7472793, lng: 17.7490942 },
    { lat: 48.7471806, lng: 17.7491588 },
    { lat: 48.747304, lng: 17.7497536 },
    { lat: 48.7475645, lng: 17.749619 },
    { lat: 48.7478275, lng: 17.7495066 },
    { lat: 48.7477475, lng: 17.7498795 },
    { lat: 48.7477442, lng: 17.7501529 },
    { lat: 48.7477979, lng: 17.7502559 },
    { lat: 48.7477701, lng: 17.7506515 },
    { lat: 48.7478756, lng: 17.7513021 },
    { lat: 48.7477778, lng: 17.7518253 },
    { lat: 48.7479397, lng: 17.7535949 },
    { lat: 48.7482088, lng: 17.7544286 },
    { lat: 48.7484435, lng: 17.7550529 },
    { lat: 48.7485501, lng: 17.7554296 },
    { lat: 48.7486158, lng: 17.7555971 },
    { lat: 48.7486512, lng: 17.7557506 },
    { lat: 48.7486865, lng: 17.7558273 },
    { lat: 48.7487481, lng: 17.7559141 },
    { lat: 48.7488105, lng: 17.7559562 },
    { lat: 48.7489481, lng: 17.7561937 },
    { lat: 48.7490237, lng: 17.7564658 },
    { lat: 48.74906, lng: 17.7565447 },
    { lat: 48.749066, lng: 17.7566226 },
    { lat: 48.7494344, lng: 17.7576599 },
    { lat: 48.7512957, lng: 17.7588105 },
    { lat: 48.7513764, lng: 17.7584875 },
    { lat: 48.7514728, lng: 17.7584014 },
    { lat: 48.7524137, lng: 17.7568268 },
    { lat: 48.7533097, lng: 17.7552569 },
    { lat: 48.7533755, lng: 17.7550795 },
    { lat: 48.7545095, lng: 17.7540934 },
    { lat: 48.755398, lng: 17.7535594 },
    { lat: 48.7564698, lng: 17.7531793 },
    { lat: 48.7574698, lng: 17.7528801 },
    { lat: 48.7576486, lng: 17.7526125 },
    { lat: 48.7578109, lng: 17.7524044 },
    { lat: 48.757902, lng: 17.752179 },
    { lat: 48.7579559, lng: 17.7520813 },
    { lat: 48.7580822, lng: 17.7519689 },
    { lat: 48.7582285, lng: 17.7516669 },
    { lat: 48.7582235, lng: 17.7515478 },
    { lat: 48.7582022, lng: 17.7514402 },
    { lat: 48.7583022, lng: 17.7511946 },
    { lat: 48.75829, lng: 17.7509958 },
    { lat: 48.758185, lng: 17.7508658 },
    { lat: 48.758216, lng: 17.7503056 },
    { lat: 48.7581469, lng: 17.7502453 },
    { lat: 48.7582936, lng: 17.7500245 },
    { lat: 48.758265, lng: 17.749962 },
    { lat: 48.7582826, lng: 17.7496065 },
    { lat: 48.7583876, lng: 17.7494952 },
    { lat: 48.7584515, lng: 17.7490103 },
    { lat: 48.7584342, lng: 17.748839 },
    { lat: 48.7584419, lng: 17.7486892 },
    { lat: 48.758545, lng: 17.7484375 },
    { lat: 48.7585326, lng: 17.7483816 },
    { lat: 48.7585632, lng: 17.7481642 },
    { lat: 48.7585877, lng: 17.7479554 },
    { lat: 48.7587188, lng: 17.7474078 },
    { lat: 48.7587138, lng: 17.7469222 },
    { lat: 48.7590345, lng: 17.7460036 },
    { lat: 48.7593076, lng: 17.7450841 },
    { lat: 48.7594963, lng: 17.7439648 },
    { lat: 48.7599671, lng: 17.7420013 },
    { lat: 48.7602549, lng: 17.7399988 },
    { lat: 48.7602868, lng: 17.7397987 },
    { lat: 48.7604401, lng: 17.7390795 },
    { lat: 48.7604908, lng: 17.7388411 },
    { lat: 48.7606163, lng: 17.7382564 },
    { lat: 48.760654, lng: 17.738079 },
    { lat: 48.7608029, lng: 17.7373613 },
    { lat: 48.7608141, lng: 17.7373048 },
    { lat: 48.7608255, lng: 17.7372509 },
    { lat: 48.761067, lng: 17.7360563 },
    { lat: 48.7611459, lng: 17.7358893 },
    { lat: 48.7611832, lng: 17.7357615 },
    { lat: 48.7611802, lng: 17.7355857 },
    { lat: 48.7613522, lng: 17.7351315 },
    { lat: 48.7613842, lng: 17.7350663 },
    { lat: 48.7614817, lng: 17.7348694 },
    { lat: 48.7620618, lng: 17.7341874 },
    { lat: 48.7623521, lng: 17.7335298 },
    { lat: 48.7624909, lng: 17.7331425 },
    { lat: 48.7624732, lng: 17.7328194 },
    { lat: 48.7624888, lng: 17.7323568 },
    { lat: 48.7625803, lng: 17.7320644 },
    { lat: 48.7625683, lng: 17.7320353 },
    { lat: 48.762669, lng: 17.7319839 },
    { lat: 48.7628433, lng: 17.7318432 },
    { lat: 48.7632113, lng: 17.7315672 },
    { lat: 48.7633327, lng: 17.731522 },
    { lat: 48.7633547, lng: 17.7314749 },
    { lat: 48.7636772, lng: 17.7313591 },
    { lat: 48.7639147, lng: 17.7313103 },
    { lat: 48.7641109, lng: 17.7313612 },
    { lat: 48.7642805, lng: 17.7313838 },
    { lat: 48.7645141, lng: 17.7313463 },
    { lat: 48.7647746, lng: 17.7312314 },
    { lat: 48.7653025, lng: 17.7310384 },
    { lat: 48.7656495, lng: 17.730863 },
    { lat: 48.7658507, lng: 17.7307373 },
    { lat: 48.7664571, lng: 17.7304457 },
    { lat: 48.7666639, lng: 17.7303208 },
    { lat: 48.7668145, lng: 17.730145 },
    { lat: 48.7669353, lng: 17.7300533 },
    { lat: 48.7671355, lng: 17.7299491 },
    { lat: 48.7675538, lng: 17.7298125 },
    { lat: 48.7677026, lng: 17.7297718 },
    { lat: 48.7679288, lng: 17.7294658 },
    { lat: 48.7682926, lng: 17.7291212 },
    { lat: 48.7684031, lng: 17.7289908 },
    { lat: 48.7684772, lng: 17.7289646 },
    { lat: 48.7687072, lng: 17.7287772 },
    { lat: 48.7688148, lng: 17.7286894 },
    { lat: 48.7689399, lng: 17.728542 },
    { lat: 48.7690647, lng: 17.7284594 },
    { lat: 48.7691327, lng: 17.7284089 },
    { lat: 48.7690752, lng: 17.7281886 },
    { lat: 48.7690325, lng: 17.7280284 },
    { lat: 48.7689529, lng: 17.7276327 },
    { lat: 48.7686004, lng: 17.7266213 },
    { lat: 48.7685255, lng: 17.7264329 },
    { lat: 48.7685629, lng: 17.726399 },
    { lat: 48.768721, lng: 17.7261983 },
    { lat: 48.7684105, lng: 17.7254448 },
    { lat: 48.7685201, lng: 17.7253363 },
    { lat: 48.7688732, lng: 17.7250559 },
    { lat: 48.7686008, lng: 17.7241913 },
    { lat: 48.7683442, lng: 17.7235267 },
    { lat: 48.7680928, lng: 17.7230391 },
    { lat: 48.7679097, lng: 17.7226687 },
    { lat: 48.7686137, lng: 17.7217441 },
    { lat: 48.7692189, lng: 17.7209579 },
    { lat: 48.7695308, lng: 17.7204865 },
    { lat: 48.7695644, lng: 17.7202067 },
    { lat: 48.7694911, lng: 17.7201185 },
    { lat: 48.7693322, lng: 17.720249 },
    { lat: 48.7691127, lng: 17.7203349 },
    { lat: 48.7688852, lng: 17.7202881 },
    { lat: 48.7685891, lng: 17.7199358 },
    { lat: 48.7679457, lng: 17.7190051 },
    { lat: 48.7678273, lng: 17.7188422 },
    { lat: 48.767653, lng: 17.7185427 },
    { lat: 48.7674852, lng: 17.7186949 },
    { lat: 48.7663272, lng: 17.7197459 },
    { lat: 48.7649034, lng: 17.7201834 },
    { lat: 48.764838, lng: 17.7202024 },
    { lat: 48.7645221, lng: 17.7204632 },
    { lat: 48.7645025, lng: 17.7204756 },
    { lat: 48.7643892, lng: 17.7200306 },
    { lat: 48.7643613, lng: 17.7197971 },
    { lat: 48.7643666, lng: 17.719351 },
    { lat: 48.7641805, lng: 17.718678 },
    { lat: 48.7640986, lng: 17.7182654 },
    { lat: 48.7639749, lng: 17.7176523 },
    { lat: 48.7638637, lng: 17.717332 },
    { lat: 48.7637876, lng: 17.7167753 },
    { lat: 48.763763, lng: 17.7164855 },
    { lat: 48.7635005, lng: 17.7159083 },
    { lat: 48.7632536, lng: 17.7154947 },
    { lat: 48.763047, lng: 17.7151093 },
    { lat: 48.7628662, lng: 17.7148523 },
    { lat: 48.7626792, lng: 17.7142911 },
    { lat: 48.7625783, lng: 17.7140843 },
    { lat: 48.7624704, lng: 17.7138548 },
    { lat: 48.7624026, lng: 17.713762 },
    { lat: 48.7623159, lng: 17.7136188 },
    { lat: 48.7621245, lng: 17.7133713 },
    { lat: 48.7620672, lng: 17.7132475 },
    { lat: 48.7620325, lng: 17.713181 },
    { lat: 48.7620093, lng: 17.7131344 },
    { lat: 48.7619497, lng: 17.7131185 },
    { lat: 48.7618961, lng: 17.7130552 },
    { lat: 48.7618614, lng: 17.7130046 },
    { lat: 48.7617462, lng: 17.7128414 },
    { lat: 48.7615588, lng: 17.7124936 },
    { lat: 48.7616287, lng: 17.7124381 },
    { lat: 48.7618492, lng: 17.7122788 },
    { lat: 48.7621351, lng: 17.7119711 },
    { lat: 48.7624233, lng: 17.7115551 },
    { lat: 48.7626492, lng: 17.7111722 },
    { lat: 48.7628059, lng: 17.710785 },
    { lat: 48.7631269, lng: 17.7099677 },
    { lat: 48.7634178, lng: 17.7094134 },
    { lat: 48.7636788, lng: 17.70905 },
    { lat: 48.7640233, lng: 17.7086874 },
    { lat: 48.7644965, lng: 17.7081769 },
    { lat: 48.7644669, lng: 17.708155 },
    { lat: 48.7644252, lng: 17.7081008 },
    { lat: 48.7643721, lng: 17.708046 },
    { lat: 48.7644444, lng: 17.7080288 },
    { lat: 48.7642505, lng: 17.7078692 },
    { lat: 48.7641203, lng: 17.7077344 },
    { lat: 48.7640246, lng: 17.7076655 },
    { lat: 48.7639627, lng: 17.7075953 },
    { lat: 48.7638877, lng: 17.7075714 },
    { lat: 48.7637947, lng: 17.7074543 },
    { lat: 48.7637488, lng: 17.7073305 },
    { lat: 48.7636219, lng: 17.7072339 },
    { lat: 48.7635349, lng: 17.7071226 },
    { lat: 48.7634737, lng: 17.7069356 },
    { lat: 48.7631677, lng: 17.7065523 },
    { lat: 48.7631037, lng: 17.7065404 },
    { lat: 48.7629721, lng: 17.7066573 },
    { lat: 48.7629402, lng: 17.7067083 },
    { lat: 48.7628377, lng: 17.7068031 },
    { lat: 48.7627904, lng: 17.7067504 },
    { lat: 48.7626969, lng: 17.7065911 },
    { lat: 48.7626171, lng: 17.7064666 },
    { lat: 48.7626326, lng: 17.7062787 },
    { lat: 48.762554, lng: 17.7059944 },
    { lat: 48.7626066, lng: 17.7058776 },
    { lat: 48.7627533, lng: 17.7056196 },
    { lat: 48.7628194, lng: 17.705284 },
    { lat: 48.7627439, lng: 17.7051208 },
    { lat: 48.7626508, lng: 17.7050021 },
    { lat: 48.7623504, lng: 17.704709 },
    { lat: 48.761982, lng: 17.7043392 },
    { lat: 48.7620739, lng: 17.7041084 },
    { lat: 48.7621918, lng: 17.7039506 },
    { lat: 48.7614485, lng: 17.7031428 },
    { lat: 48.7613633, lng: 17.7030597 },
    { lat: 48.7612156, lng: 17.7029 },
    { lat: 48.7611406, lng: 17.7028367 },
    { lat: 48.7610297, lng: 17.702779 },
    { lat: 48.760966, lng: 17.7027351 },
    { lat: 48.7609359, lng: 17.7026492 },
    { lat: 48.7608716, lng: 17.7022113 },
    { lat: 48.7606775, lng: 17.7018857 },
    { lat: 48.7604671, lng: 17.701605 },
    { lat: 48.7603529, lng: 17.7013462 },
    { lat: 48.7603516, lng: 17.7013251 },
    { lat: 48.7541027, lng: 17.7074708 },
    { lat: 48.7538929, lng: 17.7042941 },
    { lat: 48.7537775, lng: 17.7025568 },
    { lat: 48.7537749, lng: 17.7025149 },
    { lat: 48.7537061, lng: 17.701475 },
    { lat: 48.7536674, lng: 17.7008846 },
    { lat: 48.7536645, lng: 17.7008385 },
    { lat: 48.7536594, lng: 17.7007751 },
  ],
  HornáStreda: [
    { lat: 48.632909, lng: 17.851204 },
    { lat: 48.632685, lng: 17.851624 },
    { lat: 48.63232, lng: 17.852308 },
    { lat: 48.63246, lng: 17.852807 },
    { lat: 48.632635, lng: 17.854327 },
    { lat: 48.632582, lng: 17.855481 },
    { lat: 48.632245, lng: 17.856403 },
    { lat: 48.632714, lng: 17.856459 },
    { lat: 48.63274, lng: 17.856462 },
    { lat: 48.632914, lng: 17.856482 },
    { lat: 48.63311, lng: 17.856667 },
    { lat: 48.633892, lng: 17.856461 },
    { lat: 48.634265, lng: 17.856406 },
    { lat: 48.634488, lng: 17.856487 },
    { lat: 48.635179, lng: 17.856793 },
    { lat: 48.635634, lng: 17.857259 },
    { lat: 48.635973, lng: 17.85749 },
    { lat: 48.636268, lng: 17.857701 },
    { lat: 48.636568, lng: 17.857793 },
    { lat: 48.637255, lng: 17.858072 },
    { lat: 48.6377356, lng: 17.8581977 },
    { lat: 48.6384214, lng: 17.858319 },
    { lat: 48.6391984, lng: 17.8588093 },
    { lat: 48.6398074, lng: 17.8592247 },
    { lat: 48.639716, lng: 17.8594503 },
    { lat: 48.6401502, lng: 17.8597606 },
    { lat: 48.6403404, lng: 17.8599171 },
    { lat: 48.6404796, lng: 17.8599604 },
    { lat: 48.6408658, lng: 17.8602804 },
    { lat: 48.6416136, lng: 17.8611632 },
    { lat: 48.6417346, lng: 17.8613374 },
    { lat: 48.6418212, lng: 17.8614779 },
    { lat: 48.6420189, lng: 17.8618365 },
    { lat: 48.6422122, lng: 17.8622857 },
    { lat: 48.6425036, lng: 17.8630154 },
    { lat: 48.6427057, lng: 17.8635016 },
    { lat: 48.6428452, lng: 17.8639421 },
    { lat: 48.6429655, lng: 17.8642489 },
    { lat: 48.6431131, lng: 17.8644509 },
    { lat: 48.643256, lng: 17.8646665 },
    { lat: 48.6434266, lng: 17.8649681 },
    { lat: 48.6435453, lng: 17.8652302 },
    { lat: 48.6435608, lng: 17.8653026 },
    { lat: 48.6440736, lng: 17.8650074 },
    { lat: 48.644094, lng: 17.8651578 },
    { lat: 48.6441331, lng: 17.8653187 },
    { lat: 48.6441667, lng: 17.8655761 },
    { lat: 48.6441683, lng: 17.86594 },
    { lat: 48.6441656, lng: 17.8661524 },
    { lat: 48.6441748, lng: 17.8662493 },
    { lat: 48.6442277, lng: 17.8663223 },
    { lat: 48.6442805, lng: 17.8668044 },
    { lat: 48.6443335, lng: 17.8671173 },
    { lat: 48.6443487, lng: 17.8672933 },
    { lat: 48.6444431, lng: 17.8674749 },
    { lat: 48.6445031, lng: 17.8676647 },
    { lat: 48.6445237, lng: 17.8679853 },
    { lat: 48.6445235, lng: 17.8681295 },
    { lat: 48.6445645, lng: 17.8684176 },
    { lat: 48.6445958, lng: 17.8686718 },
    { lat: 48.6447066, lng: 17.869163 },
    { lat: 48.6447842, lng: 17.8694758 },
    { lat: 48.644802, lng: 17.8696015 },
    { lat: 48.6449169, lng: 17.8698783 },
    { lat: 48.6449729, lng: 17.8700734 },
    { lat: 48.6451163, lng: 17.870281 },
    { lat: 48.6453571, lng: 17.8703235 },
    { lat: 48.645741, lng: 17.8702681 },
    { lat: 48.6459528, lng: 17.8701879 },
    { lat: 48.6463928, lng: 17.869922 },
    { lat: 48.6465085, lng: 17.8698361 },
    { lat: 48.6465971, lng: 17.8698071 },
    { lat: 48.6469346, lng: 17.8694897 },
    { lat: 48.6470886, lng: 17.8693492 },
    { lat: 48.6474307, lng: 17.8692957 },
    { lat: 48.6476715, lng: 17.8692832 },
    { lat: 48.6481945, lng: 17.8691776 },
    { lat: 48.6488624, lng: 17.8690188 },
    { lat: 48.6488668, lng: 17.8690364 },
    { lat: 48.6494428, lng: 17.8688378 },
    { lat: 48.6495173, lng: 17.8687982 },
    { lat: 48.6498734, lng: 17.8686392 },
    { lat: 48.6501172, lng: 17.8685085 },
    { lat: 48.6507086, lng: 17.8681139 },
    { lat: 48.6508147, lng: 17.8680222 },
    { lat: 48.6511741, lng: 17.8678045 },
    { lat: 48.6513412, lng: 17.8677283 },
    { lat: 48.651427, lng: 17.8676572 },
    { lat: 48.6521207, lng: 17.867257 },
    { lat: 48.6524091, lng: 17.8670963 },
    { lat: 48.6527698, lng: 17.8669005 },
    { lat: 48.6532341, lng: 17.8666269 },
    { lat: 48.6535828, lng: 17.86638 },
    { lat: 48.6540166, lng: 17.8660513 },
    { lat: 48.6545834, lng: 17.865794 },
    { lat: 48.6549013, lng: 17.865673 },
    { lat: 48.6550024, lng: 17.8656077 },
    { lat: 48.6560086, lng: 17.8647027 },
    { lat: 48.6562628, lng: 17.8643692 },
    { lat: 48.6564679, lng: 17.8639902 },
    { lat: 48.6565177, lng: 17.863375 },
    { lat: 48.6569534, lng: 17.8612893 },
    { lat: 48.6573072, lng: 17.8596005 },
    { lat: 48.6589674, lng: 17.8604323 },
    { lat: 48.6602076, lng: 17.8608349 },
    { lat: 48.6607793, lng: 17.8610295 },
    { lat: 48.6617384, lng: 17.8613393 },
    { lat: 48.6624886, lng: 17.8615761 },
    { lat: 48.6625394, lng: 17.8630285 },
    { lat: 48.6624518, lng: 17.8645671 },
    { lat: 48.6624306, lng: 17.8655574 },
    { lat: 48.6624452, lng: 17.8660993 },
    { lat: 48.6624492, lng: 17.8667047 },
    { lat: 48.662524, lng: 17.8675689 },
    { lat: 48.6626367, lng: 17.8686457 },
    { lat: 48.662716, lng: 17.8701071 },
    { lat: 48.6627758, lng: 17.8713376 },
    { lat: 48.6628792, lng: 17.8729738 },
    { lat: 48.6629829, lng: 17.8741686 },
    { lat: 48.6632331, lng: 17.8758032 },
    { lat: 48.6645899, lng: 17.8765516 },
    { lat: 48.665234, lng: 17.8762251 },
    { lat: 48.6659918, lng: 17.8755394 },
    { lat: 48.6668149, lng: 17.8747582 },
    { lat: 48.6675814, lng: 17.8735613 },
    { lat: 48.6678641, lng: 17.8730852 },
    { lat: 48.6682391, lng: 17.8719118 },
    { lat: 48.6685904, lng: 17.869936 },
    { lat: 48.668453, lng: 17.8690296 },
    { lat: 48.668548, lng: 17.866999 },
    { lat: 48.6686313, lng: 17.8648294 },
    { lat: 48.6687424, lng: 17.8634562 },
    { lat: 48.6688697, lng: 17.8618662 },
    { lat: 48.6690151, lng: 17.8602786 },
    { lat: 48.6692173, lng: 17.8579893 },
    { lat: 48.6691968, lng: 17.8566814 },
    { lat: 48.6699148, lng: 17.8571826 },
    { lat: 48.6699973, lng: 17.8572405 },
    { lat: 48.6703972, lng: 17.8562452 },
    { lat: 48.6704025, lng: 17.856232 },
    { lat: 48.6706019, lng: 17.8550337 },
    { lat: 48.6706556, lng: 17.8543938 },
    { lat: 48.6707726, lng: 17.8535848 },
    { lat: 48.6710371, lng: 17.8524728 },
    { lat: 48.6714772, lng: 17.8507223 },
    { lat: 48.6716777, lng: 17.8497256 },
    { lat: 48.6718733, lng: 17.8487456 },
    { lat: 48.6720464, lng: 17.8478952 },
    { lat: 48.6724337, lng: 17.8459426 },
    { lat: 48.6725173, lng: 17.8454999 },
    { lat: 48.672601, lng: 17.8452776 },
    { lat: 48.6728016, lng: 17.8444344 },
    { lat: 48.6730056, lng: 17.843684 },
    { lat: 48.6736722, lng: 17.8414433 },
    { lat: 48.6740034, lng: 17.8401691 },
    { lat: 48.6741464, lng: 17.8392717 },
    { lat: 48.6743867, lng: 17.8376893 },
    { lat: 48.6746879, lng: 17.8356773 },
    { lat: 48.6748146, lng: 17.8345671 },
    { lat: 48.6750856, lng: 17.8323975 },
    { lat: 48.6751927, lng: 17.8306299 },
    { lat: 48.6745206, lng: 17.830493 },
    { lat: 48.6742279, lng: 17.8302464 },
    { lat: 48.6741537, lng: 17.830046 },
    { lat: 48.6742005, lng: 17.8297419 },
    { lat: 48.6743194, lng: 17.8293004 },
    { lat: 48.6742372, lng: 17.8289685 },
    { lat: 48.6739654, lng: 17.8288976 },
    { lat: 48.6737427, lng: 17.8290057 },
    { lat: 48.6735639, lng: 17.8292394 },
    { lat: 48.6734492, lng: 17.8294886 },
    { lat: 48.6728423, lng: 17.8297367 },
    { lat: 48.6722679, lng: 17.8294095 },
    { lat: 48.6720201, lng: 17.8291924 },
    { lat: 48.6717924, lng: 17.8289218 },
    { lat: 48.6716448, lng: 17.8286272 },
    { lat: 48.6715768, lng: 17.8283649 },
    { lat: 48.6715531, lng: 17.8281781 },
    { lat: 48.671538, lng: 17.8280024 },
    { lat: 48.6715181, lng: 17.8278815 },
    { lat: 48.6714097, lng: 17.8277148 },
    { lat: 48.6711197, lng: 17.8275121 },
    { lat: 48.6710599, lng: 17.8274936 },
    { lat: 48.6710466, lng: 17.8274972 },
    { lat: 48.67093, lng: 17.8276806 },
    { lat: 48.6708336, lng: 17.8277629 },
    { lat: 48.6707241, lng: 17.8279702 },
    { lat: 48.6706389, lng: 17.8281838 },
    { lat: 48.6705911, lng: 17.8282687 },
    { lat: 48.670373, lng: 17.8286932 },
    { lat: 48.6703513, lng: 17.8287787 },
    { lat: 48.6702767, lng: 17.8295198 },
    { lat: 48.6702477, lng: 17.8296935 },
    { lat: 48.6700068, lng: 17.829948 },
    { lat: 48.669569, lng: 17.8302844 },
    { lat: 48.6694025, lng: 17.8305539 },
    { lat: 48.6693249, lng: 17.8308183 },
    { lat: 48.6692415, lng: 17.8309323 },
    { lat: 48.6691542, lng: 17.8309248 },
    { lat: 48.6690591, lng: 17.8308848 },
    { lat: 48.6689636, lng: 17.8307619 },
    { lat: 48.66887, lng: 17.830577 },
    { lat: 48.6686958, lng: 17.830069 },
    { lat: 48.6686636, lng: 17.8299338 },
    { lat: 48.6686753, lng: 17.8292344 },
    { lat: 48.6685934, lng: 17.828445 },
    { lat: 48.6684739, lng: 17.8283544 },
    { lat: 48.6682984, lng: 17.8282884 },
    { lat: 48.6682403, lng: 17.8282975 },
    { lat: 48.6681666, lng: 17.8282381 },
    { lat: 48.6680913, lng: 17.8282053 },
    { lat: 48.6679243, lng: 17.8281156 },
    { lat: 48.6678676, lng: 17.8280935 },
    { lat: 48.6677206, lng: 17.8280699 },
    { lat: 48.667642, lng: 17.8281337 },
    { lat: 48.6676074, lng: 17.8281949 },
    { lat: 48.6676102, lng: 17.8283345 },
    { lat: 48.6676971, lng: 17.8285732 },
    { lat: 48.668057, lng: 17.8293137 },
    { lat: 48.6681434, lng: 17.8298045 },
    { lat: 48.6681421, lng: 17.8300633 },
    { lat: 48.6681067, lng: 17.8304075 },
    { lat: 48.6680094, lng: 17.8307556 },
    { lat: 48.6676247, lng: 17.83128 },
    { lat: 48.66753, lng: 17.8313564 },
    { lat: 48.6673413, lng: 17.8314129 },
    { lat: 48.6671471, lng: 17.8314284 },
    { lat: 48.6670112, lng: 17.8314233 },
    { lat: 48.6668731, lng: 17.8313967 },
    { lat: 48.6667406, lng: 17.8313526 },
    { lat: 48.6667037, lng: 17.8313216 },
    { lat: 48.6666469, lng: 17.8312048 },
    { lat: 48.666563, lng: 17.8309579 },
    { lat: 48.6664893, lng: 17.8307106 },
    { lat: 48.6663745, lng: 17.8303619 },
    { lat: 48.6663721, lng: 17.8300822 },
    { lat: 48.6665146, lng: 17.8295657 },
    { lat: 48.6665197, lng: 17.8291872 },
    { lat: 48.6664493, lng: 17.8289601 },
    { lat: 48.6663402, lng: 17.8288349 },
    { lat: 48.6662327, lng: 17.8287741 },
    { lat: 48.6661288, lng: 17.8287381 },
    { lat: 48.6658727, lng: 17.8287004 },
    { lat: 48.6655781, lng: 17.8286495 },
    { lat: 48.6654737, lng: 17.8286008 },
    { lat: 48.6653701, lng: 17.8285116 },
    { lat: 48.6652721, lng: 17.8284069 },
    { lat: 48.6651422, lng: 17.8283132 },
    { lat: 48.6650244, lng: 17.8281763 },
    { lat: 48.6648161, lng: 17.8279613 },
    { lat: 48.6647737, lng: 17.8278972 },
    { lat: 48.6646603, lng: 17.8277568 },
    { lat: 48.6645671, lng: 17.8276558 },
    { lat: 48.6645304, lng: 17.8275891 },
    { lat: 48.6644062, lng: 17.8274606 },
    { lat: 48.6642827, lng: 17.8273785 },
    { lat: 48.6641856, lng: 17.8273416 },
    { lat: 48.6639489, lng: 17.827308 },
    { lat: 48.6638267, lng: 17.8273064 },
    { lat: 48.6637879, lng: 17.8273182 },
    { lat: 48.6637111, lng: 17.8274085 },
    { lat: 48.6636358, lng: 17.8275232 },
    { lat: 48.6636116, lng: 17.8276669 },
    { lat: 48.6635593, lng: 17.8278988 },
    { lat: 48.6634726, lng: 17.828014 },
    { lat: 48.6633647, lng: 17.8280797 },
    { lat: 48.6633075, lng: 17.8280655 },
    { lat: 48.663192, lng: 17.8279861 },
    { lat: 48.6630379, lng: 17.8278293 },
    { lat: 48.6629592, lng: 17.8277959 },
    { lat: 48.6628817, lng: 17.8277845 },
    { lat: 48.662825, lng: 17.8278174 },
    { lat: 48.662773, lng: 17.8279121 },
    { lat: 48.6626793, lng: 17.8282804 },
    { lat: 48.6626282, lng: 17.8287039 },
    { lat: 48.6626197, lng: 17.8287119 },
    { lat: 48.6624345, lng: 17.8287822 },
    { lat: 48.6622725, lng: 17.8288503 },
    { lat: 48.6622205, lng: 17.8288468 },
    { lat: 48.662151, lng: 17.8288193 },
    { lat: 48.6618995, lng: 17.8286756 },
    { lat: 48.6618524, lng: 17.828625 },
    { lat: 48.661857, lng: 17.828218 },
    { lat: 48.6617392, lng: 17.8281754 },
    { lat: 48.6615113, lng: 17.8281781 },
    { lat: 48.6613773, lng: 17.8281642 },
    { lat: 48.6611612, lng: 17.8280606 },
    { lat: 48.6610624, lng: 17.8279236 },
    { lat: 48.6609969, lng: 17.8277563 },
    { lat: 48.6609716, lng: 17.8272856 },
    { lat: 48.6609767, lng: 17.8271831 },
    { lat: 48.660913, lng: 17.8268791 },
    { lat: 48.6607302, lng: 17.8265073 },
    { lat: 48.6606509, lng: 17.8263191 },
    { lat: 48.6605446, lng: 17.8261114 },
    { lat: 48.6604492, lng: 17.8259707 },
    { lat: 48.6603354, lng: 17.8258661 },
    { lat: 48.6602478, lng: 17.8259072 },
    { lat: 48.6598991, lng: 17.8262645 },
    { lat: 48.6597804, lng: 17.8263355 },
    { lat: 48.6596628, lng: 17.8263865 },
    { lat: 48.659509, lng: 17.8264392 },
    { lat: 48.6593343, lng: 17.8264074 },
    { lat: 48.6589978, lng: 17.8263652 },
    { lat: 48.6588457, lng: 17.8263352 },
    { lat: 48.6588088, lng: 17.8263425 },
    { lat: 48.6586258, lng: 17.826341 },
    { lat: 48.6584772, lng: 17.8264027 },
    { lat: 48.6583265, lng: 17.826374 },
    { lat: 48.6582082, lng: 17.826305 },
    { lat: 48.6581271, lng: 17.826232 },
    { lat: 48.6580937, lng: 17.8256671 },
    { lat: 48.6581837, lng: 17.8252529 },
    { lat: 48.6582223, lng: 17.8242899 },
    { lat: 48.6581897, lng: 17.8242401 },
    { lat: 48.6581755, lng: 17.8242285 },
    { lat: 48.6579847, lng: 17.8238922 },
    { lat: 48.6579152, lng: 17.8237915 },
    { lat: 48.6578804, lng: 17.8237956 },
    { lat: 48.65774, lng: 17.8237501 },
    { lat: 48.657579, lng: 17.8237753 },
    { lat: 48.6574888, lng: 17.8237971 },
    { lat: 48.6573597, lng: 17.8238657 },
    { lat: 48.6571988, lng: 17.8239852 },
    { lat: 48.6571301, lng: 17.8240218 },
    { lat: 48.6568487, lng: 17.8242769 },
    { lat: 48.6566434, lng: 17.8244482 },
    { lat: 48.6564763, lng: 17.8245789 },
    { lat: 48.6563913, lng: 17.8246078 },
    { lat: 48.6563581, lng: 17.8246003 },
    { lat: 48.6563269, lng: 17.8246078 },
    { lat: 48.6562762, lng: 17.8245916 },
    { lat: 48.6562379, lng: 17.8245559 },
    { lat: 48.6559399, lng: 17.8241102 },
    { lat: 48.6558625, lng: 17.8239509 },
    { lat: 48.6557845, lng: 17.8236908 },
    { lat: 48.6556784, lng: 17.8234141 },
    { lat: 48.655672, lng: 17.8233822 },
    { lat: 48.6553688, lng: 17.8228312 },
    { lat: 48.6551874, lng: 17.8228017 },
    { lat: 48.6551396, lng: 17.8228133 },
    { lat: 48.655093, lng: 17.8228473 },
    { lat: 48.6550446, lng: 17.8229429 },
    { lat: 48.6549021, lng: 17.8233258 },
    { lat: 48.6547938, lng: 17.8234004 },
    { lat: 48.6543359, lng: 17.8235025 },
    { lat: 48.65423, lng: 17.8236175 },
    { lat: 48.6541103, lng: 17.8239123 },
    { lat: 48.6540659, lng: 17.8240384 },
    { lat: 48.6539772, lng: 17.8241932 },
    { lat: 48.6539396, lng: 17.8242445 },
    { lat: 48.6538253, lng: 17.8242939 },
    { lat: 48.6536892, lng: 17.8242264 },
    { lat: 48.653649, lng: 17.8241805 },
    { lat: 48.6536149, lng: 17.8241013 },
    { lat: 48.6535778, lng: 17.8236798 },
    { lat: 48.6535384, lng: 17.8235689 },
    { lat: 48.6534544, lng: 17.823452 },
    { lat: 48.653278, lng: 17.823337 },
    { lat: 48.653263, lng: 17.823396 },
    { lat: 48.653259, lng: 17.823878 },
    { lat: 48.653229, lng: 17.82416 },
    { lat: 48.653224, lng: 17.824207 },
    { lat: 48.653218, lng: 17.824263 },
    { lat: 48.653171, lng: 17.825053 },
    { lat: 48.653126, lng: 17.825734 },
    { lat: 48.652996, lng: 17.826707 },
    { lat: 48.652903, lng: 17.827296 },
    { lat: 48.652706, lng: 17.828439 },
    { lat: 48.652655, lng: 17.828779 },
    { lat: 48.652647, lng: 17.828842 },
    { lat: 48.652611, lng: 17.829089 },
    { lat: 48.652507, lng: 17.82977 },
    { lat: 48.652339, lng: 17.830794 },
    { lat: 48.652303, lng: 17.831075 },
    { lat: 48.652174, lng: 17.831919 },
    { lat: 48.652167, lng: 17.831968 },
    { lat: 48.651928, lng: 17.833529 },
    { lat: 48.651691, lng: 17.834519 },
    { lat: 48.651513, lng: 17.835334 },
    { lat: 48.651446, lng: 17.83565 },
    { lat: 48.651432, lng: 17.83574 },
    { lat: 48.651378, lng: 17.836118 },
    { lat: 48.651374, lng: 17.836155 },
    { lat: 48.651364, lng: 17.836221 },
    { lat: 48.651299, lng: 17.836679 },
    { lat: 48.651197, lng: 17.837615 },
    { lat: 48.651086, lng: 17.838643 },
    { lat: 48.651012, lng: 17.839328 },
    { lat: 48.651008, lng: 17.839365 },
    { lat: 48.650689, lng: 17.8392 },
    { lat: 48.650238, lng: 17.838965 },
    { lat: 48.650171, lng: 17.838931 },
    { lat: 48.649975, lng: 17.838846 },
    { lat: 48.649906, lng: 17.838815 },
    { lat: 48.649602, lng: 17.838733 },
    { lat: 48.649572, lng: 17.838726 },
    { lat: 48.649351, lng: 17.838703 },
    { lat: 48.649225, lng: 17.83869 },
    { lat: 48.649199, lng: 17.838688 },
    { lat: 48.649101, lng: 17.838678 },
    { lat: 48.648859, lng: 17.838669 },
    { lat: 48.648369, lng: 17.838757 },
    { lat: 48.647982, lng: 17.838695 },
    { lat: 48.647682, lng: 17.838593 },
    { lat: 48.647425, lng: 17.83853 },
    { lat: 48.647358, lng: 17.838553 },
    { lat: 48.647193, lng: 17.838615 },
    { lat: 48.647065, lng: 17.838692 },
    { lat: 48.646995, lng: 17.838733 },
    { lat: 48.646844, lng: 17.838825 },
    { lat: 48.646811, lng: 17.838832 },
    { lat: 48.646642, lng: 17.838868 },
    { lat: 48.646259, lng: 17.838785 },
    { lat: 48.646104, lng: 17.838736 },
    { lat: 48.645841, lng: 17.838653 },
    { lat: 48.645619, lng: 17.838571 },
    { lat: 48.64543, lng: 17.838494 },
    { lat: 48.6453978, lng: 17.8384839 },
    { lat: 48.645082, lng: 17.838385 },
    { lat: 48.644823, lng: 17.838262 },
    { lat: 48.644777, lng: 17.838252 },
    { lat: 48.644538, lng: 17.838273 },
    { lat: 48.644207, lng: 17.838269 },
    { lat: 48.644006, lng: 17.838262 },
    { lat: 48.643709, lng: 17.838323 },
    { lat: 48.643279, lng: 17.838605 },
    { lat: 48.643107, lng: 17.838724 },
    { lat: 48.64302, lng: 17.838803 },
    { lat: 48.642933, lng: 17.838884 },
    { lat: 48.642801, lng: 17.839005 },
    { lat: 48.642542, lng: 17.839277 },
    { lat: 48.642462, lng: 17.839421 },
    { lat: 48.642308, lng: 17.839623 },
    { lat: 48.642074, lng: 17.83988 },
    { lat: 48.641865, lng: 17.840183 },
    { lat: 48.641717, lng: 17.840415 },
    { lat: 48.641534, lng: 17.840839 },
    { lat: 48.641356, lng: 17.841292 },
    { lat: 48.641208, lng: 17.84207 },
    { lat: 48.640997, lng: 17.842546 },
    { lat: 48.640937, lng: 17.843145 },
    { lat: 48.640936, lng: 17.843167 },
    { lat: 48.640914, lng: 17.843395 },
    { lat: 48.640903, lng: 17.843507 },
    { lat: 48.640902, lng: 17.843514 },
    { lat: 48.64089, lng: 17.843636 },
    { lat: 48.640525, lng: 17.844535 },
    { lat: 48.640351, lng: 17.844476 },
    { lat: 48.639308, lng: 17.844121 },
    { lat: 48.638509, lng: 17.844338 },
    { lat: 48.638435, lng: 17.844358 },
    { lat: 48.638337, lng: 17.844385 },
    { lat: 48.638158, lng: 17.844433 },
    { lat: 48.637876, lng: 17.84451 },
    { lat: 48.637489, lng: 17.844615 },
    { lat: 48.637435, lng: 17.844771 },
    { lat: 48.637424, lng: 17.844801 },
    { lat: 48.637401, lng: 17.844868 },
    { lat: 48.637345, lng: 17.845028 },
    { lat: 48.637333, lng: 17.845065 },
    { lat: 48.637173, lng: 17.845529 },
    { lat: 48.637154, lng: 17.845581 },
    { lat: 48.637004, lng: 17.846019 },
    { lat: 48.636998, lng: 17.846034 },
    { lat: 48.636987, lng: 17.846064 },
    { lat: 48.636636, lng: 17.847081 },
    { lat: 48.636283, lng: 17.848037 },
    { lat: 48.636193, lng: 17.848296 },
    { lat: 48.635862, lng: 17.849342 },
    { lat: 48.635533, lng: 17.850414 },
    { lat: 48.632909, lng: 17.851204 },
  ],
  Lúka: [
    { lat: 48.6685904, lng: 17.869936 },
    { lat: 48.6682391, lng: 17.8719118 },
    { lat: 48.6678641, lng: 17.8730852 },
    { lat: 48.6675814, lng: 17.8735613 },
    { lat: 48.6668149, lng: 17.8747582 },
    { lat: 48.6659918, lng: 17.8755394 },
    { lat: 48.665234, lng: 17.8762251 },
    { lat: 48.6645899, lng: 17.8765516 },
    { lat: 48.6632331, lng: 17.8758032 },
    { lat: 48.6629829, lng: 17.8741686 },
    { lat: 48.6628792, lng: 17.8729738 },
    { lat: 48.6627758, lng: 17.8713376 },
    { lat: 48.662716, lng: 17.8701071 },
    { lat: 48.6626367, lng: 17.8686457 },
    { lat: 48.662524, lng: 17.8675689 },
    { lat: 48.6624492, lng: 17.8667047 },
    { lat: 48.6624452, lng: 17.8660993 },
    { lat: 48.6624306, lng: 17.8655574 },
    { lat: 48.6624518, lng: 17.8645671 },
    { lat: 48.6625394, lng: 17.8630285 },
    { lat: 48.6624886, lng: 17.8615761 },
    { lat: 48.6617384, lng: 17.8613393 },
    { lat: 48.6607793, lng: 17.8610295 },
    { lat: 48.6602076, lng: 17.8608349 },
    { lat: 48.6589674, lng: 17.8604323 },
    { lat: 48.6573072, lng: 17.8596005 },
    { lat: 48.6569534, lng: 17.8612893 },
    { lat: 48.6565177, lng: 17.863375 },
    { lat: 48.6564679, lng: 17.8639902 },
    { lat: 48.6562628, lng: 17.8643692 },
    { lat: 48.6560086, lng: 17.8647027 },
    { lat: 48.6550024, lng: 17.8656077 },
    { lat: 48.6549013, lng: 17.865673 },
    { lat: 48.6545834, lng: 17.865794 },
    { lat: 48.6540166, lng: 17.8660513 },
    { lat: 48.6535828, lng: 17.86638 },
    { lat: 48.6532341, lng: 17.8666269 },
    { lat: 48.6527698, lng: 17.8669005 },
    { lat: 48.6524091, lng: 17.8670963 },
    { lat: 48.6521207, lng: 17.867257 },
    { lat: 48.651427, lng: 17.8676572 },
    { lat: 48.6513412, lng: 17.8677283 },
    { lat: 48.6514975, lng: 17.8694004 },
    { lat: 48.6515714, lng: 17.8699304 },
    { lat: 48.6520912, lng: 17.869772 },
    { lat: 48.6522871, lng: 17.8697646 },
    { lat: 48.6527907, lng: 17.8699523 },
    { lat: 48.652893, lng: 17.87002 },
    { lat: 48.6527836, lng: 17.8706916 },
    { lat: 48.6526397, lng: 17.8713555 },
    { lat: 48.6527606, lng: 17.8716962 },
    { lat: 48.6528353, lng: 17.8726681 },
    { lat: 48.6528371, lng: 17.8730149 },
    { lat: 48.6529523, lng: 17.8736327 },
    { lat: 48.6526853, lng: 17.8737905 },
    { lat: 48.6522802, lng: 17.8739812 },
    { lat: 48.6519832, lng: 17.8741631 },
    { lat: 48.6519828, lng: 17.8742896 },
    { lat: 48.6521094, lng: 17.8746113 },
    { lat: 48.6523055, lng: 17.874825 },
    { lat: 48.652935, lng: 17.8753077 },
    { lat: 48.6531059, lng: 17.8754827 },
    { lat: 48.6534009, lng: 17.8759762 },
    { lat: 48.6534922, lng: 17.8766794 },
    { lat: 48.6535103, lng: 17.8770932 },
    { lat: 48.6535925, lng: 17.8779138 },
    { lat: 48.6537866, lng: 17.8790699 },
    { lat: 48.6538306, lng: 17.8796108 },
    { lat: 48.6538673, lng: 17.8797882 },
    { lat: 48.653862, lng: 17.8798682 },
    { lat: 48.6537141, lng: 17.8805415 },
    { lat: 48.6536759, lng: 17.880656 },
    { lat: 48.6537563, lng: 17.88066 },
    { lat: 48.653967, lng: 17.880691 },
    { lat: 48.6539547, lng: 17.8808237 },
    { lat: 48.6539408, lng: 17.8814357 },
    { lat: 48.6542485, lng: 17.881408 },
    { lat: 48.6544548, lng: 17.881741 },
    { lat: 48.6545782, lng: 17.8818018 },
    { lat: 48.6548227, lng: 17.882001 },
    { lat: 48.6547652, lng: 17.882714 },
    { lat: 48.6547507, lng: 17.8829388 },
    { lat: 48.6547862, lng: 17.883191 },
    { lat: 48.6550352, lng: 17.8838008 },
    { lat: 48.6552969, lng: 17.8845264 },
    { lat: 48.6553292, lng: 17.8848933 },
    { lat: 48.6555018, lng: 17.885398 },
    { lat: 48.6555675, lng: 17.8856638 },
    { lat: 48.6559968, lng: 17.886772 },
    { lat: 48.6561391, lng: 17.8872415 },
    { lat: 48.656194, lng: 17.8874768 },
    { lat: 48.6559529, lng: 17.8899931 },
    { lat: 48.6558165, lng: 17.8904449 },
    { lat: 48.6557226, lng: 17.8906646 },
    { lat: 48.6554461, lng: 17.8910967 },
    { lat: 48.654989, lng: 17.8919032 },
    { lat: 48.6547898, lng: 17.8927546 },
    { lat: 48.6544531, lng: 17.8926398 },
    { lat: 48.6543623, lng: 17.8928365 },
    { lat: 48.6542718, lng: 17.8929628 },
    { lat: 48.6541139, lng: 17.8933619 },
    { lat: 48.653786, lng: 17.894177 },
    { lat: 48.6537145, lng: 17.8944313 },
    { lat: 48.6533362, lng: 17.8954783 },
    { lat: 48.6529236, lng: 17.8964624 },
    { lat: 48.6526798, lng: 17.8971935 },
    { lat: 48.652465, lng: 17.8984025 },
    { lat: 48.6524595, lng: 17.8986885 },
    { lat: 48.6524238, lng: 17.8989201 },
    { lat: 48.6522587, lng: 17.8999446 },
    { lat: 48.6520839, lng: 17.9004905 },
    { lat: 48.6525173, lng: 17.9011093 },
    { lat: 48.6526907, lng: 17.9013251 },
    { lat: 48.6528445, lng: 17.9015771 },
    { lat: 48.6531367, lng: 17.9017984 },
    { lat: 48.6534888, lng: 17.902452 },
    { lat: 48.6538615, lng: 17.9029421 },
    { lat: 48.6539967, lng: 17.9031995 },
    { lat: 48.6540054, lng: 17.9034029 },
    { lat: 48.6539431, lng: 17.9036606 },
    { lat: 48.6538338, lng: 17.9040026 },
    { lat: 48.6538468, lng: 17.9046667 },
    { lat: 48.6538857, lng: 17.9052379 },
    { lat: 48.6537822, lng: 17.9059604 },
    { lat: 48.6537251, lng: 17.9064734 },
    { lat: 48.6536598, lng: 17.9066063 },
    { lat: 48.6534907, lng: 17.9067805 },
    { lat: 48.6530413, lng: 17.9071337 },
    { lat: 48.6527232, lng: 17.907398 },
    { lat: 48.6524224, lng: 17.9073521 },
    { lat: 48.6522855, lng: 17.9074055 },
    { lat: 48.6522091, lng: 17.907543 },
    { lat: 48.6520196, lng: 17.9084458 },
    { lat: 48.6518308, lng: 17.9089104 },
    { lat: 48.6517222, lng: 17.9092626 },
    { lat: 48.6515849, lng: 17.9096077 },
    { lat: 48.6513459, lng: 17.9100221 },
    { lat: 48.6508661, lng: 17.910493 },
    { lat: 48.6509777, lng: 17.9118975 },
    { lat: 48.6509477, lng: 17.9128662 },
    { lat: 48.6508984, lng: 17.9137526 },
    { lat: 48.6505891, lng: 17.9146909 },
    { lat: 48.6507065, lng: 17.9152557 },
    { lat: 48.6508129, lng: 17.9154082 },
    { lat: 48.6508494, lng: 17.9156217 },
    { lat: 48.6508401, lng: 17.9158261 },
    { lat: 48.6507839, lng: 17.9160562 },
    { lat: 48.6506243, lng: 17.9164036 },
    { lat: 48.6504462, lng: 17.9170098 },
    { lat: 48.6504724, lng: 17.91754 },
    { lat: 48.6505315, lng: 17.9180156 },
    { lat: 48.6505384, lng: 17.9182056 },
    { lat: 48.6505604, lng: 17.9184218 },
    { lat: 48.6507409, lng: 17.9189069 },
    { lat: 48.6508834, lng: 17.9191016 },
    { lat: 48.6509137, lng: 17.9191928 },
    { lat: 48.6509897, lng: 17.9193112 },
    { lat: 48.651056, lng: 17.9194942 },
    { lat: 48.6512317, lng: 17.9199036 },
    { lat: 48.6512367, lng: 17.9200621 },
    { lat: 48.6513622, lng: 17.9204216 },
    { lat: 48.6519341, lng: 17.9211469 },
    { lat: 48.6520019, lng: 17.9212623 },
    { lat: 48.6522805, lng: 17.9216515 },
    { lat: 48.6524883, lng: 17.9221425 },
    { lat: 48.6525541, lng: 17.9224316 },
    { lat: 48.6525316, lng: 17.9227503 },
    { lat: 48.6526024, lng: 17.9230272 },
    { lat: 48.6526362, lng: 17.9233454 },
    { lat: 48.6526774, lng: 17.9235628 },
    { lat: 48.6528567, lng: 17.9250463 },
    { lat: 48.6531707, lng: 17.9257095 },
    { lat: 48.652883, lng: 17.9261066 },
    { lat: 48.6529738, lng: 17.9267182 },
    { lat: 48.652951, lng: 17.9269366 },
    { lat: 48.652999, lng: 17.9271735 },
    { lat: 48.6530856, lng: 17.9273941 },
    { lat: 48.6530375, lng: 17.9281346 },
    { lat: 48.6531251, lng: 17.9284861 },
    { lat: 48.6531184, lng: 17.9286951 },
    { lat: 48.653069, lng: 17.9291488 },
    { lat: 48.6531687, lng: 17.9294779 },
    { lat: 48.6533622, lng: 17.9299002 },
    { lat: 48.6534508, lng: 17.9301348 },
    { lat: 48.6534818, lng: 17.930293 },
    { lat: 48.6534802, lng: 17.9303961 },
    { lat: 48.653541, lng: 17.9313167 },
    { lat: 48.6535906, lng: 17.9315605 },
    { lat: 48.6536927, lng: 17.9317204 },
    { lat: 48.653807, lng: 17.9318391 },
    { lat: 48.6538751, lng: 17.9319968 },
    { lat: 48.6539377, lng: 17.9322275 },
    { lat: 48.6539614, lng: 17.9323638 },
    { lat: 48.6539892, lng: 17.9326724 },
    { lat: 48.6540495, lng: 17.9326253 },
    { lat: 48.65479, lng: 17.9332867 },
    { lat: 48.6561106, lng: 17.9341521 },
    { lat: 48.657686, lng: 17.9341063 },
    { lat: 48.658813, lng: 17.9353417 },
    { lat: 48.6597393, lng: 17.936447 },
    { lat: 48.6610762, lng: 17.9370747 },
    { lat: 48.66232, lng: 17.937788 },
    { lat: 48.6634939, lng: 17.938433 },
    { lat: 48.6644827, lng: 17.939034 },
    { lat: 48.6655608, lng: 17.9396036 },
    { lat: 48.6668079, lng: 17.9403522 },
    { lat: 48.6675855, lng: 17.9408054 },
    { lat: 48.6689123, lng: 17.9415857 },
    { lat: 48.6708953, lng: 17.9427333 },
    { lat: 48.6732207, lng: 17.9440368 },
    { lat: 48.6739499, lng: 17.943926 },
    { lat: 48.6740921, lng: 17.9438862 },
    { lat: 48.6746876, lng: 17.9442722 },
    { lat: 48.6750123, lng: 17.9447786 },
    { lat: 48.6757246, lng: 17.9451751 },
    { lat: 48.6762318, lng: 17.9450106 },
    { lat: 48.6780743, lng: 17.9449826 },
    { lat: 48.6783005, lng: 17.9447821 },
    { lat: 48.6787555, lng: 17.9445196 },
    { lat: 48.6788799, lng: 17.9443123 },
    { lat: 48.6790539, lng: 17.9438457 },
    { lat: 48.6792256, lng: 17.942979 },
    { lat: 48.6794116, lng: 17.9426561 },
    { lat: 48.679482, lng: 17.9424361 },
    { lat: 48.6796584, lng: 17.9423091 },
    { lat: 48.679737, lng: 17.9423377 },
    { lat: 48.6798924, lng: 17.9423497 },
    { lat: 48.6804227, lng: 17.9424613 },
    { lat: 48.6805081, lng: 17.9424379 },
    { lat: 48.680537, lng: 17.9423904 },
    { lat: 48.6806051, lng: 17.9420578 },
    { lat: 48.6806972, lng: 17.9418824 },
    { lat: 48.6809824, lng: 17.9417617 },
    { lat: 48.6811172, lng: 17.94145 },
    { lat: 48.6814301, lng: 17.9411334 },
    { lat: 48.6814767, lng: 17.9409281 },
    { lat: 48.6816536, lng: 17.9405267 },
    { lat: 48.6817207, lng: 17.9402336 },
    { lat: 48.6817626, lng: 17.9391238 },
    { lat: 48.6811177, lng: 17.937024 },
    { lat: 48.6802666, lng: 17.9357972 },
    { lat: 48.6802353, lng: 17.9339169 },
    { lat: 48.6802039, lng: 17.932112 },
    { lat: 48.6804918, lng: 17.9315837 },
    { lat: 48.6806301, lng: 17.9314451 },
    { lat: 48.6812914, lng: 17.9305957 },
    { lat: 48.6821216, lng: 17.9281751 },
    { lat: 48.6829835, lng: 17.9264905 },
    { lat: 48.6833702, lng: 17.9253346 },
    { lat: 48.682448, lng: 17.9246006 },
    { lat: 48.6824455, lng: 17.9243894 },
    { lat: 48.6836117, lng: 17.924486 },
    { lat: 48.6841192, lng: 17.9239322 },
    { lat: 48.6856314, lng: 17.9227615 },
    { lat: 48.6878525, lng: 17.9218153 },
    { lat: 48.6884548, lng: 17.922013 },
    { lat: 48.6886349, lng: 17.9208758 },
    { lat: 48.6903888, lng: 17.9176908 },
    { lat: 48.6896792, lng: 17.9149181 },
    { lat: 48.6896032, lng: 17.9145137 },
    { lat: 48.6894728, lng: 17.9140371 },
    { lat: 48.6889528, lng: 17.9110542 },
    { lat: 48.6887392, lng: 17.9108258 },
    { lat: 48.6885457, lng: 17.9105344 },
    { lat: 48.6883902, lng: 17.9099384 },
    { lat: 48.6884232, lng: 17.9093783 },
    { lat: 48.6884254, lng: 17.9083077 },
    { lat: 48.688266, lng: 17.9077776 },
    { lat: 48.6882761, lng: 17.9077212 },
    { lat: 48.6877111, lng: 17.9078405 },
    { lat: 48.6873286, lng: 17.9080883 },
    { lat: 48.6870475, lng: 17.9082408 },
    { lat: 48.6868436, lng: 17.9084338 },
    { lat: 48.6866872, lng: 17.9086089 },
    { lat: 48.6866496, lng: 17.9086251 },
    { lat: 48.6866548, lng: 17.908504 },
    { lat: 48.6867454, lng: 17.908169 },
    { lat: 48.6868749, lng: 17.9078486 },
    { lat: 48.6870062, lng: 17.9073837 },
    { lat: 48.6871845, lng: 17.9071742 },
    { lat: 48.6874077, lng: 17.9070757 },
    { lat: 48.6875833, lng: 17.9069545 },
    { lat: 48.6877478, lng: 17.9067997 },
    { lat: 48.6878119, lng: 17.9066098 },
    { lat: 48.6878687, lng: 17.9064098 },
    { lat: 48.6878845, lng: 17.9061647 },
    { lat: 48.6878307, lng: 17.9059277 },
    { lat: 48.6877658, lng: 17.9058784 },
    { lat: 48.6875346, lng: 17.9058069 },
    { lat: 48.6871565, lng: 17.9059977 },
    { lat: 48.6870352, lng: 17.9059868 },
    { lat: 48.6868938, lng: 17.9058144 },
    { lat: 48.6867721, lng: 17.9055728 },
    { lat: 48.6866485, lng: 17.9049646 },
    { lat: 48.6865869, lng: 17.9042496 },
    { lat: 48.6865703, lng: 17.9040572 },
    { lat: 48.6864764, lng: 17.9034352 },
    { lat: 48.6862654, lng: 17.9030226 },
    { lat: 48.686062, lng: 17.9027962 },
    { lat: 48.6856338, lng: 17.902488 },
    { lat: 48.6852752, lng: 17.9020832 },
    { lat: 48.6847972, lng: 17.9014897 },
    { lat: 48.6846658, lng: 17.9014095 },
    { lat: 48.6835979, lng: 17.9013875 },
    { lat: 48.6830381, lng: 17.9014471 },
    { lat: 48.6829146, lng: 17.9014401 },
    { lat: 48.682809, lng: 17.9013164 },
    { lat: 48.6827155, lng: 17.9010399 },
    { lat: 48.6826658, lng: 17.9008109 },
    { lat: 48.6825001, lng: 17.9002714 },
    { lat: 48.6823189, lng: 17.8998301 },
    { lat: 48.682195, lng: 17.8992931 },
    { lat: 48.6820752, lng: 17.899102 },
    { lat: 48.6816912, lng: 17.8987975 },
    { lat: 48.68156, lng: 17.8986265 },
    { lat: 48.6813092, lng: 17.8981891 },
    { lat: 48.680777, lng: 17.8973374 },
    { lat: 48.6806469, lng: 17.8970709 },
    { lat: 48.6805994, lng: 17.896658 },
    { lat: 48.6806048, lng: 17.8959239 },
    { lat: 48.6806079, lng: 17.895841 },
    { lat: 48.6805802, lng: 17.8956084 },
    { lat: 48.6804304, lng: 17.8952749 },
    { lat: 48.6802935, lng: 17.894934 },
    { lat: 48.6802563, lng: 17.8946341 },
    { lat: 48.6802779, lng: 17.8941675 },
    { lat: 48.6802792, lng: 17.8937026 },
    { lat: 48.6802271, lng: 17.8933643 },
    { lat: 48.6797166, lng: 17.8915977 },
    { lat: 48.6796188, lng: 17.8910611 },
    { lat: 48.6795961, lng: 17.8908836 },
    { lat: 48.6795525, lng: 17.8905426 },
    { lat: 48.6795154, lng: 17.8900948 },
    { lat: 48.67984, lng: 17.8900698 },
    { lat: 48.6802569, lng: 17.89009 },
    { lat: 48.6811497, lng: 17.8898637 },
    { lat: 48.6814063, lng: 17.889852 },
    { lat: 48.6821325, lng: 17.8899536 },
    { lat: 48.6824878, lng: 17.8898719 },
    { lat: 48.6829094, lng: 17.8894335 },
    { lat: 48.6828531, lng: 17.8886485 },
    { lat: 48.6827739, lng: 17.8876528 },
    { lat: 48.6826761, lng: 17.8866317 },
    { lat: 48.6825584, lng: 17.885389 },
    { lat: 48.68199, lng: 17.8819754 },
    { lat: 48.6804843, lng: 17.8801822 },
    { lat: 48.6786256, lng: 17.8770696 },
    { lat: 48.6788326, lng: 17.876999 },
    { lat: 48.6797131, lng: 17.8770949 },
    { lat: 48.6797808, lng: 17.8764012 },
    { lat: 48.679237, lng: 17.8760531 },
    { lat: 48.6767202, lng: 17.8752007 },
    { lat: 48.676747, lng: 17.8750113 },
    { lat: 48.6764364, lng: 17.874764 },
    { lat: 48.6758229, lng: 17.8743824 },
    { lat: 48.6757061, lng: 17.8742428 },
    { lat: 48.6756064, lng: 17.8740479 },
    { lat: 48.6744749, lng: 17.8732755 },
    { lat: 48.67378, lng: 17.8727801 },
    { lat: 48.6726348, lng: 17.8719847 },
    { lat: 48.6699776, lng: 17.8701405 },
    { lat: 48.6685904, lng: 17.869936 },
  ],
  Podolie: [
    { lat: 48.677589, lng: 17.713741 },
    { lat: 48.67667, lng: 17.715754 },
    { lat: 48.676653, lng: 17.715732 },
    { lat: 48.676581, lng: 17.71584 },
    { lat: 48.676253, lng: 17.716339 },
    { lat: 48.675889, lng: 17.716946 },
    { lat: 48.675227, lng: 17.717996 },
    { lat: 48.674123, lng: 17.719667 },
    { lat: 48.671925, lng: 17.722995 },
    { lat: 48.670773, lng: 17.724789 },
    { lat: 48.670652, lng: 17.725008 },
    { lat: 48.670498, lng: 17.725226 },
    { lat: 48.670281, lng: 17.725608 },
    { lat: 48.669984, lng: 17.725995 },
    { lat: 48.669966, lng: 17.726022 },
    { lat: 48.669679, lng: 17.726522 },
    { lat: 48.669212, lng: 17.727233 },
    { lat: 48.669133, lng: 17.727345 },
    { lat: 48.668511, lng: 17.728264 },
    { lat: 48.668174, lng: 17.728716 },
    { lat: 48.6686699, lng: 17.7290689 },
    { lat: 48.6687775, lng: 17.7291099 },
    { lat: 48.6699249, lng: 17.7294457 },
    { lat: 48.6700335, lng: 17.7295387 },
    { lat: 48.670028, lng: 17.7295409 },
    { lat: 48.6698343, lng: 17.7297338 },
    { lat: 48.6697223, lng: 17.7298739 },
    { lat: 48.6695489, lng: 17.7300672 },
    { lat: 48.6694615, lng: 17.7301885 },
    { lat: 48.6691969, lng: 17.7308386 },
    { lat: 48.6690378, lng: 17.731374 },
    { lat: 48.6687148, lng: 17.7326349 },
    { lat: 48.668646, lng: 17.7329453 },
    { lat: 48.6685271, lng: 17.7335618 },
    { lat: 48.6682398, lng: 17.7346537 },
    { lat: 48.6680498, lng: 17.7351591 },
    { lat: 48.6679371, lng: 17.735511 },
    { lat: 48.6678621, lng: 17.7356691 },
    { lat: 48.6675564, lng: 17.7363657 },
    { lat: 48.6673982, lng: 17.7368058 },
    { lat: 48.6671571, lng: 17.7375115 },
    { lat: 48.6670395, lng: 17.7379638 },
    { lat: 48.6668977, lng: 17.7383917 },
    { lat: 48.6667675, lng: 17.7386975 },
    { lat: 48.666645, lng: 17.7390754 },
    { lat: 48.6664877, lng: 17.7396172 },
    { lat: 48.6663678, lng: 17.7399091 },
    { lat: 48.6662603, lng: 17.7403421 },
    { lat: 48.66619, lng: 17.7407069 },
    { lat: 48.6661407, lng: 17.7409853 },
    { lat: 48.6660392, lng: 17.7412996 },
    { lat: 48.6659465, lng: 17.7414812 },
    { lat: 48.665704, lng: 17.7420878 },
    { lat: 48.6656222, lng: 17.7423393 },
    { lat: 48.6655339, lng: 17.7425371 },
    { lat: 48.6653956, lng: 17.742984 },
    { lat: 48.6653215, lng: 17.7434673 },
    { lat: 48.6653214, lng: 17.7439088 },
    { lat: 48.6653006, lng: 17.7445224 },
    { lat: 48.6652145, lng: 17.7448463 },
    { lat: 48.6650078, lng: 17.7454321 },
    { lat: 48.6647224, lng: 17.7460983 },
    { lat: 48.6643573, lng: 17.7468249 },
    { lat: 48.6638578, lng: 17.7478078 },
    { lat: 48.6637142, lng: 17.7481534 },
    { lat: 48.6630673, lng: 17.7500973 },
    { lat: 48.6627325, lng: 17.751315 },
    { lat: 48.6624466, lng: 17.7519915 },
    { lat: 48.6623801, lng: 17.7522669 },
    { lat: 48.6622642, lng: 17.7528253 },
    { lat: 48.6620447, lng: 17.7534039 },
    { lat: 48.6618194, lng: 17.7538908 },
    { lat: 48.6616596, lng: 17.7542114 },
    { lat: 48.6614767, lng: 17.7545967 },
    { lat: 48.6614117, lng: 17.7547515 },
    { lat: 48.6613369, lng: 17.755058 },
    { lat: 48.661183, lng: 17.7555685 },
    { lat: 48.6610738, lng: 17.7560117 },
    { lat: 48.6609107, lng: 17.756738 },
    { lat: 48.6605957, lng: 17.7579325 },
    { lat: 48.6604553, lng: 17.7579082 },
    { lat: 48.6602591, lng: 17.7583532 },
    { lat: 48.6602763, lng: 17.7585218 },
    { lat: 48.6602456, lng: 17.7589422 },
    { lat: 48.6601935, lng: 17.7593805 },
    { lat: 48.6601555, lng: 17.7594212 },
    { lat: 48.660141, lng: 17.7594996 },
    { lat: 48.6601143, lng: 17.7595769 },
    { lat: 48.6599579, lng: 17.7600824 },
    { lat: 48.6596641, lng: 17.760776 },
    { lat: 48.6592539, lng: 17.7616148 },
    { lat: 48.659047, lng: 17.7619601 },
    { lat: 48.6587536, lng: 17.7623865 },
    { lat: 48.658235, lng: 17.7630219 },
    { lat: 48.6577788, lng: 17.7634985 },
    { lat: 48.6576856, lng: 17.7636187 },
    { lat: 48.6574897, lng: 17.7639764 },
    { lat: 48.657411, lng: 17.7641657 },
    { lat: 48.656423, lng: 17.7668667 },
    { lat: 48.6563413, lng: 17.7670996 },
    { lat: 48.6562411, lng: 17.767341 },
    { lat: 48.6561694, lng: 17.7675721 },
    { lat: 48.6560128, lng: 17.7682396 },
    { lat: 48.6558981, lng: 17.7685978 },
    { lat: 48.6557901, lng: 17.7688578 },
    { lat: 48.6556937, lng: 17.7691834 },
    { lat: 48.6553569, lng: 17.770115 },
    { lat: 48.654201, lng: 17.7714635 },
    { lat: 48.6537769, lng: 17.7711178 },
    { lat: 48.6535494, lng: 17.7715135 },
    { lat: 48.6533546, lng: 17.7718014 },
    { lat: 48.6531777, lng: 17.7720061 },
    { lat: 48.6531092, lng: 17.7722363 },
    { lat: 48.6529605, lng: 17.7721296 },
    { lat: 48.6528545, lng: 17.7719834 },
    { lat: 48.6526622, lng: 17.7717935 },
    { lat: 48.6525708, lng: 17.7716656 },
    { lat: 48.6524575, lng: 17.771624 },
    { lat: 48.6523973, lng: 17.771707 },
    { lat: 48.6523148, lng: 17.7718935 },
    { lat: 48.6521305, lng: 17.7721235 },
    { lat: 48.6520148, lng: 17.772375 },
    { lat: 48.6519585, lng: 17.7725978 },
    { lat: 48.6515544, lng: 17.7736129 },
    { lat: 48.6515549, lng: 17.7736936 },
    { lat: 48.6515081, lng: 17.7738868 },
    { lat: 48.6514101, lng: 17.7741673 },
    { lat: 48.6512531, lng: 17.7745519 },
    { lat: 48.6509366, lng: 17.7755202 },
    { lat: 48.6508821, lng: 17.7757195 },
    { lat: 48.6507389, lng: 17.7763126 },
    { lat: 48.6506141, lng: 17.7769081 },
    { lat: 48.6505037, lng: 17.7773534 },
    { lat: 48.6502031, lng: 17.7783076 },
    { lat: 48.6500671, lng: 17.7788343 },
    { lat: 48.6501778, lng: 17.7788917 },
    { lat: 48.6502567, lng: 17.7790553 },
    { lat: 48.6502626, lng: 17.7792255 },
    { lat: 48.6502854, lng: 17.779373 },
    { lat: 48.6502803, lng: 17.7795868 },
    { lat: 48.6500181, lng: 17.7799483 },
    { lat: 48.6498742, lng: 17.7801987 },
    { lat: 48.6494462, lng: 17.7800314 },
    { lat: 48.6490776, lng: 17.7799322 },
    { lat: 48.6490615, lng: 17.7799626 },
    { lat: 48.6488081, lng: 17.7803404 },
    { lat: 48.6486012, lng: 17.780632 },
    { lat: 48.648458, lng: 17.7808737 },
    { lat: 48.6483098, lng: 17.7811615 },
    { lat: 48.6481385, lng: 17.7814813 },
    { lat: 48.6479875, lng: 17.7817231 },
    { lat: 48.6478248, lng: 17.7819068 },
    { lat: 48.6476517, lng: 17.7819773 },
    { lat: 48.6475035, lng: 17.7820059 },
    { lat: 48.6471448, lng: 17.782971 },
    { lat: 48.6470143, lng: 17.7832191 },
    { lat: 48.6467552, lng: 17.7833188 },
    { lat: 48.6466434, lng: 17.7833944 },
    { lat: 48.6465264, lng: 17.7835117 },
    { lat: 48.6464399, lng: 17.7836284 },
    { lat: 48.6462912, lng: 17.7836488 },
    { lat: 48.6461335, lng: 17.7836012 },
    { lat: 48.6458687, lng: 17.7833525 },
    { lat: 48.6455232, lng: 17.7832961 },
    { lat: 48.6453296, lng: 17.7833424 },
    { lat: 48.6451953, lng: 17.7833629 },
    { lat: 48.6452001, lng: 17.7834408 },
    { lat: 48.6453343, lng: 17.7838433 },
    { lat: 48.6453734, lng: 17.7839453 },
    { lat: 48.6454749, lng: 17.7842768 },
    { lat: 48.6455242, lng: 17.7845291 },
    { lat: 48.6456019, lng: 17.784988 },
    { lat: 48.6456358, lng: 17.7852086 },
    { lat: 48.6457018, lng: 17.7856767 },
    { lat: 48.6457549, lng: 17.785972 },
    { lat: 48.6457516, lng: 17.7861414 },
    { lat: 48.6457374, lng: 17.7864243 },
    { lat: 48.6456913, lng: 17.7867606 },
    { lat: 48.6455249, lng: 17.7873971 },
    { lat: 48.6454715, lng: 17.7875602 },
    { lat: 48.6455768, lng: 17.7877108 },
    { lat: 48.6456524, lng: 17.7878745 },
    { lat: 48.6456933, lng: 17.788249 },
    { lat: 48.6458064, lng: 17.7884353 },
    { lat: 48.6458765, lng: 17.7885145 },
    { lat: 48.6460663, lng: 17.7884762 },
    { lat: 48.6461583, lng: 17.788523 },
    { lat: 48.6463285, lng: 17.7885939 },
    { lat: 48.6464107, lng: 17.7886995 },
    { lat: 48.6464543, lng: 17.7888791 },
    { lat: 48.6465922, lng: 17.7892112 },
    { lat: 48.6467072, lng: 17.7892458 },
    { lat: 48.6467837, lng: 17.7892437 },
    { lat: 48.6468492, lng: 17.7891307 },
    { lat: 48.6469186, lng: 17.788974 },
    { lat: 48.6470086, lng: 17.7889868 },
    { lat: 48.6470662, lng: 17.7889498 },
    { lat: 48.6472482, lng: 17.7887499 },
    { lat: 48.6475509, lng: 17.7887376 },
    { lat: 48.6476352, lng: 17.788878 },
    { lat: 48.6477992, lng: 17.7890676 },
    { lat: 48.6481318, lng: 17.789098 },
    { lat: 48.6481575, lng: 17.7891476 },
    { lat: 48.6481739, lng: 17.7891563 },
    { lat: 48.6482365, lng: 17.7892212 },
    { lat: 48.648099, lng: 17.7895013 },
    { lat: 48.6480671, lng: 17.7898121 },
    { lat: 48.6480668, lng: 17.7898803 },
    { lat: 48.6481095, lng: 17.7899319 },
    { lat: 48.6482304, lng: 17.790045 },
    { lat: 48.6483667, lng: 17.7901302 },
    { lat: 48.6484322, lng: 17.7902232 },
    { lat: 48.6485699, lng: 17.7902768 },
    { lat: 48.6486569, lng: 17.790299 },
    { lat: 48.6487906, lng: 17.7904157 },
    { lat: 48.6489099, lng: 17.7906682 },
    { lat: 48.6490022, lng: 17.7907571 },
    { lat: 48.6490619, lng: 17.7907745 },
    { lat: 48.6491869, lng: 17.7907881 },
    { lat: 48.6493925, lng: 17.7907688 },
    { lat: 48.649453, lng: 17.7907257 },
    { lat: 48.6494768, lng: 17.7907274 },
    { lat: 48.6495627, lng: 17.7907822 },
    { lat: 48.6497835, lng: 17.7909796 },
    { lat: 48.6499045, lng: 17.7909827 },
    { lat: 48.6499774, lng: 17.7909557 },
    { lat: 48.6500973, lng: 17.7900539 },
    { lat: 48.6502621, lng: 17.790057 },
    { lat: 48.6504143, lng: 17.7900697 },
    { lat: 48.6507215, lng: 17.7885602 },
    { lat: 48.6509081, lng: 17.7886559 },
    { lat: 48.651019, lng: 17.7886619 },
    { lat: 48.6510653, lng: 17.7886222 },
    { lat: 48.651188, lng: 17.788583 },
    { lat: 48.651218, lng: 17.7885931 },
    { lat: 48.6513561, lng: 17.7888216 },
    { lat: 48.6514496, lng: 17.7889643 },
    { lat: 48.6517807, lng: 17.7891334 },
    { lat: 48.6517861, lng: 17.7891676 },
    { lat: 48.6518345, lng: 17.7897579 },
    { lat: 48.6520608, lng: 17.7900023 },
    { lat: 48.6522871, lng: 17.7901604 },
    { lat: 48.6526472, lng: 17.790675 },
    { lat: 48.652692, lng: 17.790762 },
    { lat: 48.6528135, lng: 17.7908097 },
    { lat: 48.6528703, lng: 17.7908178 },
    { lat: 48.652915, lng: 17.7908445 },
    { lat: 48.6529926, lng: 17.7909532 },
    { lat: 48.6530702, lng: 17.7911317 },
    { lat: 48.6531376, lng: 17.7912544 },
    { lat: 48.6531892, lng: 17.7912854 },
    { lat: 48.6532515, lng: 17.7912917 },
    { lat: 48.6533548, lng: 17.7912441 },
    { lat: 48.6534569, lng: 17.7911792 },
    { lat: 48.6536114, lng: 17.7911576 },
    { lat: 48.6537414, lng: 17.7911585 },
    { lat: 48.6537925, lng: 17.7910896 },
    { lat: 48.6538281, lng: 17.7907129 },
    { lat: 48.6538711, lng: 17.7905841 },
    { lat: 48.6539234, lng: 17.7904993 },
    { lat: 48.6540021, lng: 17.7904405 },
    { lat: 48.6541897, lng: 17.7904047 },
    { lat: 48.6542897, lng: 17.7901565 },
    { lat: 48.6544004, lng: 17.7901211 },
    { lat: 48.6545238, lng: 17.7901467 },
    { lat: 48.6546555, lng: 17.7902112 },
    { lat: 48.6548536, lng: 17.7901672 },
    { lat: 48.6550561, lng: 17.7901743 },
    { lat: 48.6552095, lng: 17.7901711 },
    { lat: 48.6553036, lng: 17.7902141 },
    { lat: 48.6554332, lng: 17.790321 },
    { lat: 48.6556327, lng: 17.7904067 },
    { lat: 48.6557325, lng: 17.7903796 },
    { lat: 48.6558672, lng: 17.7900337 },
    { lat: 48.6559003, lng: 17.7900595 },
    { lat: 48.6559368, lng: 17.7900644 },
    { lat: 48.6560526, lng: 17.7901286 },
    { lat: 48.6561314, lng: 17.7901833 },
    { lat: 48.6563202, lng: 17.7903334 },
    { lat: 48.6563386, lng: 17.7903412 },
    { lat: 48.6564372, lng: 17.7904171 },
    { lat: 48.6564854, lng: 17.7904314 },
    { lat: 48.6564896, lng: 17.7904458 },
    { lat: 48.6565954, lng: 17.7900343 },
    { lat: 48.6566493, lng: 17.7898622 },
    { lat: 48.6567913, lng: 17.7897867 },
    { lat: 48.6568298, lng: 17.7897849 },
    { lat: 48.6569043, lng: 17.7898042 },
    { lat: 48.6569419, lng: 17.7898307 },
    { lat: 48.657043, lng: 17.7899869 },
    { lat: 48.657095, lng: 17.7900254 },
    { lat: 48.6571807, lng: 17.7900613 },
    { lat: 48.6573018, lng: 17.7901383 },
    { lat: 48.6573403, lng: 17.7901797 },
    { lat: 48.6574258, lng: 17.7903203 },
    { lat: 48.6575135, lng: 17.7904281 },
    { lat: 48.6576723, lng: 17.7904934 },
    { lat: 48.6579301, lng: 17.7905193 },
    { lat: 48.6580396, lng: 17.7905008 },
    { lat: 48.6581089, lng: 17.7903422 },
    { lat: 48.6581241, lng: 17.7901644 },
    { lat: 48.6581083, lng: 17.7899798 },
    { lat: 48.6581269, lng: 17.789858 },
    { lat: 48.6584092, lng: 17.7894383 },
    { lat: 48.658471, lng: 17.7893972 },
    { lat: 48.6585988, lng: 17.7894165 },
    { lat: 48.6586745, lng: 17.7895099 },
    { lat: 48.6587282, lng: 17.7896485 },
    { lat: 48.658737, lng: 17.7897572 },
    { lat: 48.6587842, lng: 17.7899377 },
    { lat: 48.6590623, lng: 17.7901293 },
    { lat: 48.6591185, lng: 17.7901425 },
    { lat: 48.6593203, lng: 17.7902126 },
    { lat: 48.6593999, lng: 17.7902947 },
    { lat: 48.6595318, lng: 17.7904749 },
    { lat: 48.6596519, lng: 17.7907417 },
    { lat: 48.6598377, lng: 17.7909361 },
    { lat: 48.6600573, lng: 17.7910026 },
    { lat: 48.6603298, lng: 17.7911753 },
    { lat: 48.6605562, lng: 17.7918684 },
    { lat: 48.6604564, lng: 17.7921197 },
    { lat: 48.6603158, lng: 17.7924586 },
    { lat: 48.6602928, lng: 17.7925436 },
    { lat: 48.6602827, lng: 17.7926378 },
    { lat: 48.660311, lng: 17.7930656 },
    { lat: 48.6603952, lng: 17.7936463 },
    { lat: 48.6603907, lng: 17.7940908 },
    { lat: 48.6603681, lng: 17.7946457 },
    { lat: 48.6603542, lng: 17.7948244 },
    { lat: 48.6604805, lng: 17.794875 },
    { lat: 48.6605957, lng: 17.7950243 },
    { lat: 48.66065, lng: 17.7951546 },
    { lat: 48.6606948, lng: 17.7953733 },
    { lat: 48.6608167, lng: 17.7958695 },
    { lat: 48.661037, lng: 17.7962031 },
    { lat: 48.6612528, lng: 17.7963198 },
    { lat: 48.6614313, lng: 17.7964496 },
    { lat: 48.6616371, lng: 17.7966584 },
    { lat: 48.6617691, lng: 17.7968982 },
    { lat: 48.6619532, lng: 17.7970458 },
    { lat: 48.6620686, lng: 17.7970679 },
    { lat: 48.6621986, lng: 17.7970698 },
    { lat: 48.662369, lng: 17.7970864 },
    { lat: 48.6624675, lng: 17.7971852 },
    { lat: 48.6625399, lng: 17.7971887 },
    { lat: 48.6625029, lng: 17.7976531 },
    { lat: 48.6625123, lng: 17.7977711 },
    { lat: 48.6625313, lng: 17.7977684 },
    { lat: 48.6626321, lng: 17.7978651 },
    { lat: 48.662827, lng: 17.797969 },
    { lat: 48.6629959, lng: 17.7980901 },
    { lat: 48.6630646, lng: 17.7981613 },
    { lat: 48.6631142, lng: 17.7982533 },
    { lat: 48.6631026, lng: 17.7982685 },
    { lat: 48.6632147, lng: 17.7983832 },
    { lat: 48.6633199, lng: 17.7983514 },
    { lat: 48.6634518, lng: 17.7982713 },
    { lat: 48.6640525, lng: 17.7990862 },
    { lat: 48.6643091, lng: 17.7993704 },
    { lat: 48.664528, lng: 17.7994803 },
    { lat: 48.6648691, lng: 17.7996317 },
    { lat: 48.6647645, lng: 17.8000795 },
    { lat: 48.6646708, lng: 17.8004475 },
    { lat: 48.6647023, lng: 17.8004674 },
    { lat: 48.6647699, lng: 17.8008151 },
    { lat: 48.6648143, lng: 17.8009886 },
    { lat: 48.6649251, lng: 17.8013218 },
    { lat: 48.6650793, lng: 17.801721 },
    { lat: 48.6651456, lng: 17.8018653 },
    { lat: 48.6654549, lng: 17.8023623 },
    { lat: 48.6654231, lng: 17.802396 },
    { lat: 48.665466, lng: 17.8024511 },
    { lat: 48.6660285, lng: 17.8026792 },
    { lat: 48.666194, lng: 17.8027633 },
    { lat: 48.666419, lng: 17.8029188 },
    { lat: 48.6665427, lng: 17.803038 },
    { lat: 48.6665691, lng: 17.8032294 },
    { lat: 48.6666342, lng: 17.8034831 },
    { lat: 48.6668111, lng: 17.8040128 },
    { lat: 48.6669917, lng: 17.8042548 },
    { lat: 48.667174, lng: 17.804447 },
    { lat: 48.6672566, lng: 17.8045641 },
    { lat: 48.667408, lng: 17.8047291 },
    { lat: 48.6677035, lng: 17.8049473 },
    { lat: 48.6678618, lng: 17.8050795 },
    { lat: 48.6680437, lng: 17.8052492 },
    { lat: 48.6682734, lng: 17.8055169 },
    { lat: 48.6687195, lng: 17.8062063 },
    { lat: 48.6689127, lng: 17.8064834 },
    { lat: 48.6688352, lng: 17.8070278 },
    { lat: 48.6694766, lng: 17.8070878 },
    { lat: 48.6698711, lng: 17.8061518 },
    { lat: 48.6705305, lng: 17.8060219 },
    { lat: 48.6706704, lng: 17.8060024 },
    { lat: 48.6724861, lng: 17.8063067 },
    { lat: 48.6725806, lng: 17.8061535 },
    { lat: 48.672708, lng: 17.8060193 },
    { lat: 48.6728837, lng: 17.8059213 },
    { lat: 48.6731753, lng: 17.8057218 },
    { lat: 48.6736708, lng: 17.8052742 },
    { lat: 48.6739096, lng: 17.805137 },
    { lat: 48.6741786, lng: 17.8048656 },
    { lat: 48.6745447, lng: 17.8045189 },
    { lat: 48.6750375, lng: 17.8041046 },
    { lat: 48.6759759, lng: 17.8033678 },
    { lat: 48.6761101, lng: 17.7986496 },
    { lat: 48.6765409, lng: 17.7986428 },
    { lat: 48.676748, lng: 17.7986708 },
    { lat: 48.6773137, lng: 17.798692 },
    { lat: 48.6776869, lng: 17.79874 },
    { lat: 48.6779638, lng: 17.7987261 },
    { lat: 48.6782457, lng: 17.7986633 },
    { lat: 48.6784652, lng: 17.7986898 },
    { lat: 48.6786983, lng: 17.7986992 },
    { lat: 48.6787023, lng: 17.7984893 },
    { lat: 48.6791953, lng: 17.7965408 },
    { lat: 48.6793888, lng: 17.7965292 },
    { lat: 48.6796464, lng: 17.7965712 },
    { lat: 48.6797439, lng: 17.7963547 },
    { lat: 48.6798004, lng: 17.7958199 },
    { lat: 48.67995, lng: 17.7952567 },
    { lat: 48.6801392, lng: 17.795085 },
    { lat: 48.6802949, lng: 17.7950279 },
    { lat: 48.6803782, lng: 17.7949463 },
    { lat: 48.6805099, lng: 17.7940948 },
    { lat: 48.6804731, lng: 17.7940803 },
    { lat: 48.6804201, lng: 17.7935118 },
    { lat: 48.6804145, lng: 17.793141 },
    { lat: 48.6806496, lng: 17.7915571 },
    { lat: 48.6804605, lng: 17.791437 },
    { lat: 48.6805915, lng: 17.7910679 },
    { lat: 48.6802585, lng: 17.7907756 },
    { lat: 48.6806062, lng: 17.7897615 },
    { lat: 48.6809218, lng: 17.7899883 },
    { lat: 48.6815312, lng: 17.7876608 },
    { lat: 48.681511, lng: 17.7875357 },
    { lat: 48.6814837, lng: 17.7875491 },
    { lat: 48.6813677, lng: 17.7876453 },
    { lat: 48.6811806, lng: 17.7876686 },
    { lat: 48.680954, lng: 17.787657 },
    { lat: 48.6806576, lng: 17.7872593 },
    { lat: 48.6805493, lng: 17.7871679 },
    { lat: 48.6802635, lng: 17.7876626 },
    { lat: 48.6801673, lng: 17.7876796 },
    { lat: 48.6801435, lng: 17.7872317 },
    { lat: 48.6800165, lng: 17.7870393 },
    { lat: 48.6799361, lng: 17.786723 },
    { lat: 48.6798339, lng: 17.7866036 },
    { lat: 48.6798444, lng: 17.7865889 },
    { lat: 48.6815554, lng: 17.7808248 },
    { lat: 48.6827664, lng: 17.7766367 },
    { lat: 48.6824295, lng: 17.7764097 },
    { lat: 48.6817119, lng: 17.7758798 },
    { lat: 48.6813864, lng: 17.7756709 },
    { lat: 48.6810034, lng: 17.7753557 },
    { lat: 48.6805323, lng: 17.7749443 },
    { lat: 48.6799441, lng: 17.7744263 },
    { lat: 48.6790044, lng: 17.7738703 },
    { lat: 48.6790194, lng: 17.7738185 },
    { lat: 48.6792687, lng: 17.773226 },
    { lat: 48.6793572, lng: 17.7730883 },
    { lat: 48.679697, lng: 17.7724209 },
    { lat: 48.6802034, lng: 17.7713659 },
    { lat: 48.6802608, lng: 17.7709949 },
    { lat: 48.6806247, lng: 17.7703016 },
    { lat: 48.6809126, lng: 17.7694178 },
    { lat: 48.6811337, lng: 17.7689732 },
    { lat: 48.6814546, lng: 17.7679447 },
    { lat: 48.6815451, lng: 17.7676916 },
    { lat: 48.6817859, lng: 17.7673103 },
    { lat: 48.6821803, lng: 17.7670208 },
    { lat: 48.6823799, lng: 17.7670196 },
    { lat: 48.6829618, lng: 17.7665295 },
    { lat: 48.683305, lng: 17.766107 },
    { lat: 48.683567, lng: 17.7658317 },
    { lat: 48.683756, lng: 17.7655615 },
    { lat: 48.6843062, lng: 17.764576 },
    { lat: 48.6847419, lng: 17.7639133 },
    { lat: 48.6851161, lng: 17.7635329 },
    { lat: 48.6853579, lng: 17.7630796 },
    { lat: 48.685661, lng: 17.7628292 },
    { lat: 48.6856656, lng: 17.7624612 },
    { lat: 48.685822, lng: 17.7621424 },
    { lat: 48.6860921, lng: 17.7616126 },
    { lat: 48.6865186, lng: 17.7607446 },
    { lat: 48.6868939, lng: 17.7601989 },
    { lat: 48.6869255, lng: 17.7597039 },
    { lat: 48.6871485, lng: 17.7589216 },
    { lat: 48.6873412, lng: 17.7584951 },
    { lat: 48.687532, lng: 17.758035 },
    { lat: 48.6876665, lng: 17.7574669 },
    { lat: 48.6877553, lng: 17.756394 },
    { lat: 48.6878371, lng: 17.7559998 },
    { lat: 48.6878103, lng: 17.7555072 },
    { lat: 48.6878939, lng: 17.7549199 },
    { lat: 48.6879202, lng: 17.7544303 },
    { lat: 48.6880279, lng: 17.7538513 },
    { lat: 48.6881688, lng: 17.7534224 },
    { lat: 48.6881496, lng: 17.75324 },
    { lat: 48.6881684, lng: 17.7525184 },
    { lat: 48.6883297, lng: 17.7523332 },
    { lat: 48.6883012, lng: 17.7521242 },
    { lat: 48.6882552, lng: 17.7519456 },
    { lat: 48.688167, lng: 17.7515221 },
    { lat: 48.6881284, lng: 17.7512402 },
    { lat: 48.688119, lng: 17.7510003 },
    { lat: 48.6881779, lng: 17.7503946 },
    { lat: 48.6880572, lng: 17.7502679 },
    { lat: 48.6882998, lng: 17.749054 },
    { lat: 48.6886065, lng: 17.7475977 },
    { lat: 48.6884146, lng: 17.7475435 },
    { lat: 48.6885548, lng: 17.7457111 },
    { lat: 48.6888408, lng: 17.7456799 },
    { lat: 48.6891969, lng: 17.7451396 },
    { lat: 48.6893583, lng: 17.7436873 },
    { lat: 48.6895011, lng: 17.7422136 },
    { lat: 48.6896435, lng: 17.7404926 },
    { lat: 48.6897706, lng: 17.7384101 },
    { lat: 48.6898662, lng: 17.7376898 },
    { lat: 48.6899831, lng: 17.7370602 },
    { lat: 48.6901452, lng: 17.7363188 },
    { lat: 48.6904209, lng: 17.7352423 },
    { lat: 48.6907152, lng: 17.7341171 },
    { lat: 48.691035, lng: 17.7328757 },
    { lat: 48.6911994, lng: 17.7323037 },
    { lat: 48.6910663, lng: 17.7321566 },
    { lat: 48.6908264, lng: 17.7320406 },
    { lat: 48.6906894, lng: 17.7319409 },
    { lat: 48.6902667, lng: 17.7312402 },
    { lat: 48.6901086, lng: 17.7309091 },
    { lat: 48.6897614, lng: 17.7302929 },
    { lat: 48.6896587, lng: 17.7298557 },
    { lat: 48.6896467, lng: 17.7297534 },
    { lat: 48.6896517, lng: 17.7291559 },
    { lat: 48.6896771, lng: 17.7289268 },
    { lat: 48.6898024, lng: 17.7282881 },
    { lat: 48.6898652, lng: 17.7276225 },
    { lat: 48.6897413, lng: 17.7274244 },
    { lat: 48.6896154, lng: 17.7272128 },
    { lat: 48.6895982, lng: 17.7272302 },
    { lat: 48.6891412, lng: 17.7267563 },
    { lat: 48.6890201, lng: 17.7265679 },
    { lat: 48.6889011, lng: 17.7264149 },
    { lat: 48.6887063, lng: 17.7262608 },
    { lat: 48.6885715, lng: 17.7261958 },
    { lat: 48.6883276, lng: 17.725994 },
    { lat: 48.6882386, lng: 17.7256853 },
    { lat: 48.6882308, lng: 17.7252679 },
    { lat: 48.6881931, lng: 17.7249484 },
    { lat: 48.6881227, lng: 17.7245059 },
    { lat: 48.6881186, lng: 17.7239455 },
    { lat: 48.688144, lng: 17.7237507 },
    { lat: 48.6885667, lng: 17.7228829 },
    { lat: 48.6886531, lng: 17.7228 },
    { lat: 48.6888714, lng: 17.7226796 },
    { lat: 48.6890595, lng: 17.7226716 },
    { lat: 48.6891622, lng: 17.7225964 },
    { lat: 48.6893209, lng: 17.7222768 },
    { lat: 48.6893672, lng: 17.7221495 },
    { lat: 48.6895379, lng: 17.7218059 },
    { lat: 48.6897604, lng: 17.7214973 },
    { lat: 48.6899042, lng: 17.7211565 },
    { lat: 48.6900537, lng: 17.7204515 },
    { lat: 48.69033, lng: 17.720041 },
    { lat: 48.690319, lng: 17.720032 },
    { lat: 48.689968, lng: 17.719737 },
    { lat: 48.689559, lng: 17.719163 },
    { lat: 48.689185, lng: 17.71851 },
    { lat: 48.688809, lng: 17.71779 },
    { lat: 48.6887532, lng: 17.7177117 },
    { lat: 48.688552, lng: 17.7174292 },
    { lat: 48.688445, lng: 17.717279 },
    { lat: 48.688365, lng: 17.717122 },
    { lat: 48.688272, lng: 17.716901 },
    { lat: 48.6877518, lng: 17.7163794 },
    { lat: 48.68755, lng: 17.716228 },
    { lat: 48.687216, lng: 17.71618 },
    { lat: 48.6870808, lng: 17.7161716 },
    { lat: 48.68694, lng: 17.716173 },
    { lat: 48.686507, lng: 17.716072 },
    { lat: 48.685724, lng: 17.7158963 },
    { lat: 48.685275, lng: 17.715775 },
    { lat: 48.684923, lng: 17.715713 },
    { lat: 48.684859, lng: 17.715702 },
    { lat: 48.684798, lng: 17.715691 },
    { lat: 48.68441, lng: 17.715516 },
    { lat: 48.684355, lng: 17.715492 },
    { lat: 48.684181, lng: 17.715402 },
    { lat: 48.6841254, lng: 17.7153682 },
    { lat: 48.684072, lng: 17.715336 },
    { lat: 48.6840316, lng: 17.7153143 },
    { lat: 48.6839852, lng: 17.715297 },
    { lat: 48.683947, lng: 17.715291 },
    { lat: 48.683899, lng: 17.715295 },
    { lat: 48.6838074, lng: 17.7153177 },
    { lat: 48.6837766, lng: 17.7153464 },
    { lat: 48.6836982, lng: 17.7154162 },
    { lat: 48.683485, lng: 17.715696 },
    { lat: 48.683421, lng: 17.715767 },
    { lat: 48.683312, lng: 17.715889 },
    { lat: 48.683215, lng: 17.715971 },
    { lat: 48.683146, lng: 17.71603 },
    { lat: 48.683088, lng: 17.71608 },
    { lat: 48.682752, lng: 17.716128 },
    { lat: 48.682159, lng: 17.716124 },
    { lat: 48.681995, lng: 17.716085 },
    { lat: 48.681296, lng: 17.716045 },
    { lat: 48.680802, lng: 17.715956 },
    { lat: 48.679993, lng: 17.715625 },
    { lat: 48.679937, lng: 17.715604 },
    { lat: 48.679916, lng: 17.715597 },
    { lat: 48.679754, lng: 17.715336 },
    { lat: 48.679675, lng: 17.715201 },
    { lat: 48.679555, lng: 17.715068 },
    { lat: 48.679284, lng: 17.714822 },
    { lat: 48.679034, lng: 17.714648 },
    { lat: 48.678808, lng: 17.71452 },
    { lat: 48.678477, lng: 17.714464 },
    { lat: 48.678055, lng: 17.714125 },
    { lat: 48.677902, lng: 17.713978 },
    { lat: 48.677589, lng: 17.713741 },
  ],
  NováLehota: [
    { lat: 48.637556, lng: 18.022113 },
    { lat: 48.637741, lng: 18.0225 },
    { lat: 48.63786, lng: 18.022726 },
    { lat: 48.638062, lng: 18.023167 },
    { lat: 48.638236, lng: 18.023355 },
    { lat: 48.638354, lng: 18.023258 },
    { lat: 48.638429, lng: 18.022471 },
    { lat: 48.638306, lng: 18.021144 },
    { lat: 48.638252, lng: 18.02103 },
    { lat: 48.638224, lng: 18.020677 },
    { lat: 48.63824, lng: 18.020392 },
    { lat: 48.638334, lng: 18.020178 },
    { lat: 48.638685, lng: 18.01976 },
    { lat: 48.638998, lng: 18.01961 },
    { lat: 48.639329, lng: 18.019441 },
    { lat: 48.639683, lng: 18.019353 },
    { lat: 48.639972, lng: 18.019347 },
    { lat: 48.640375, lng: 18.019234 },
    { lat: 48.640671, lng: 18.019075 },
    { lat: 48.641002, lng: 18.018951 },
    { lat: 48.64145, lng: 18.018926 },
    { lat: 48.641629, lng: 18.018882 },
    { lat: 48.641897, lng: 18.018711 },
    { lat: 48.642099, lng: 18.018452 },
    { lat: 48.642368, lng: 18.018328 },
    { lat: 48.642528, lng: 18.0183 },
    { lat: 48.642949, lng: 18.018292 },
    { lat: 48.643041, lng: 18.018288 },
    { lat: 48.64312, lng: 18.018244 },
    { lat: 48.643271, lng: 18.018239 },
    { lat: 48.643414, lng: 18.018302 },
    { lat: 48.643839, lng: 18.018416 },
    { lat: 48.644172, lng: 18.01858 },
    { lat: 48.644301, lng: 18.018592 },
    { lat: 48.644875, lng: 18.018487 },
    { lat: 48.644902, lng: 18.018478 },
    { lat: 48.645659, lng: 18.018233 },
    { lat: 48.645986, lng: 18.018175 },
    { lat: 48.646141, lng: 18.018117 },
    { lat: 48.646259, lng: 18.018096 },
    { lat: 48.646325, lng: 18.018084 },
    { lat: 48.646421, lng: 18.018067 },
    { lat: 48.646555, lng: 18.017979 },
    { lat: 48.646702, lng: 18.017793 },
    { lat: 48.646829, lng: 18.017689 },
    { lat: 48.64699, lng: 18.017607 },
    { lat: 48.647351, lng: 18.017285 },
    { lat: 48.647535, lng: 18.017166 },
    { lat: 48.647744, lng: 18.017011 },
    { lat: 48.648073, lng: 18.016896 },
    { lat: 48.648243, lng: 18.016889 },
    { lat: 48.648484, lng: 18.016959 },
    { lat: 48.648908, lng: 18.016882 },
    { lat: 48.648967, lng: 18.01682 },
    { lat: 48.649187, lng: 18.016305 },
    { lat: 48.649499, lng: 18.016076 },
    { lat: 48.649728, lng: 18.015884 },
    { lat: 48.649894, lng: 18.015795 },
    { lat: 48.650102, lng: 18.015758 },
    { lat: 48.650296, lng: 18.015756 },
    { lat: 48.650823, lng: 18.015617 },
    { lat: 48.65109, lng: 18.015891 },
    { lat: 48.651122, lng: 18.015922 },
    { lat: 48.651779, lng: 18.016592 },
    { lat: 48.65165, lng: 18.016843 },
    { lat: 48.651626, lng: 18.01689 },
    { lat: 48.651573, lng: 18.016993 },
    { lat: 48.65172, lng: 18.018082 },
    { lat: 48.652252, lng: 18.018271 },
    { lat: 48.65231, lng: 18.018291 },
    { lat: 48.652822, lng: 18.018474 },
    { lat: 48.652928, lng: 18.018511 },
    { lat: 48.653407, lng: 18.018411 },
    { lat: 48.653659, lng: 18.018312 },
    { lat: 48.654265, lng: 18.018097 },
    { lat: 48.654359, lng: 18.018029 },
    { lat: 48.654467, lng: 18.018001 },
    { lat: 48.654682, lng: 18.017539 },
    { lat: 48.654753, lng: 18.017386 },
    { lat: 48.654824, lng: 18.017235 },
    { lat: 48.655197, lng: 18.015958 },
    { lat: 48.655505, lng: 18.015227 },
    { lat: 48.655319, lng: 18.014805 },
    { lat: 48.655588, lng: 18.014176 },
    { lat: 48.657193, lng: 18.009993 },
    { lat: 48.657745, lng: 18.00828 },
    { lat: 48.658111, lng: 18.007781 },
    { lat: 48.658323, lng: 18.009385 },
    { lat: 48.658588, lng: 18.010869 },
    { lat: 48.658414, lng: 18.012314 },
    { lat: 48.658713, lng: 18.012944 },
    { lat: 48.659003, lng: 18.012548 },
    { lat: 48.659263, lng: 18.010662 },
    { lat: 48.659165, lng: 18.009874 },
    { lat: 48.659063, lng: 18.008516 },
    { lat: 48.658977, lng: 18.007342 },
    { lat: 48.658971, lng: 18.007259 },
    { lat: 48.658944, lng: 18.006905 },
    { lat: 48.659492, lng: 18.006355 },
    { lat: 48.661211, lng: 18.005022 },
    { lat: 48.66161, lng: 18.005083 },
    { lat: 48.662977, lng: 18.004746 },
    { lat: 48.664176, lng: 18.004331 },
    { lat: 48.666133, lng: 18.003251 },
    { lat: 48.667702, lng: 18.002521 },
    { lat: 48.668955, lng: 18.002477 },
    { lat: 48.669995, lng: 18.002777 },
    { lat: 48.670278, lng: 18.002327 },
    { lat: 48.67119, lng: 18.00081 },
    { lat: 48.671598, lng: 17.999594 },
    { lat: 48.672099, lng: 17.998949 },
    { lat: 48.6722, lng: 17.998812 },
    { lat: 48.672287, lng: 17.998805 },
    { lat: 48.672455, lng: 17.998896 },
    { lat: 48.672787, lng: 17.999039 },
    { lat: 48.673426, lng: 17.999184 },
    { lat: 48.673691, lng: 17.999358 },
    { lat: 48.673859, lng: 17.999365 },
    { lat: 48.674289, lng: 17.999516 },
    { lat: 48.674551, lng: 17.999685 },
    { lat: 48.674705, lng: 17.999737 },
    { lat: 48.674848, lng: 17.999835 },
    { lat: 48.675389, lng: 18.000437 },
    { lat: 48.675785, lng: 18.000956 },
    { lat: 48.67582, lng: 18.001003 },
    { lat: 48.677009, lng: 18.002376 },
    { lat: 48.677423, lng: 18.001781 },
    { lat: 48.677528, lng: 18.001686 },
    { lat: 48.677658, lng: 18.00154 },
    { lat: 48.677766, lng: 18.001379 },
    { lat: 48.677872, lng: 18.001138 },
    { lat: 48.67796, lng: 18.000948 },
    { lat: 48.678203, lng: 18.000246 },
    { lat: 48.678424, lng: 17.999578 },
    { lat: 48.678739, lng: 17.999255 },
    { lat: 48.678897, lng: 17.998945 },
    { lat: 48.679259, lng: 17.998579 },
    { lat: 48.679469, lng: 17.998173 },
    { lat: 48.679937, lng: 17.997062 },
    { lat: 48.680332, lng: 17.996521 },
    { lat: 48.680656, lng: 17.996167 },
    { lat: 48.680811, lng: 17.99584 },
    { lat: 48.681196, lng: 17.995329 },
    { lat: 48.681289, lng: 17.995092 },
    { lat: 48.681627, lng: 17.994417 },
    { lat: 48.681711, lng: 17.994315 },
    { lat: 48.681758, lng: 17.994243 },
    { lat: 48.681782, lng: 17.994205 },
    { lat: 48.681895, lng: 17.994029 },
    { lat: 48.681978, lng: 17.994119 },
    { lat: 48.682018, lng: 17.994161 },
    { lat: 48.682351, lng: 17.99452 },
    { lat: 48.682505, lng: 17.994539 },
    { lat: 48.682808, lng: 17.994695 },
    { lat: 48.682952, lng: 17.994754 },
    { lat: 48.684272, lng: 17.995226 },
    { lat: 48.684456, lng: 17.995261 },
    { lat: 48.684549, lng: 17.995251 },
    { lat: 48.684601, lng: 17.995224 },
    { lat: 48.684761, lng: 17.995388 },
    { lat: 48.684952, lng: 17.995803 },
    { lat: 48.685069, lng: 17.995991 },
    { lat: 48.685227, lng: 17.996148 },
    { lat: 48.68548, lng: 17.996191 },
    { lat: 48.68594, lng: 17.996171 },
    { lat: 48.686075, lng: 17.996086 },
    { lat: 48.686331, lng: 17.995842 },
    { lat: 48.686488, lng: 17.995892 },
    { lat: 48.686628, lng: 17.996035 },
    { lat: 48.686888, lng: 17.996369 },
    { lat: 48.687237, lng: 17.996743 },
    { lat: 48.687313, lng: 17.996791 },
    { lat: 48.68769, lng: 17.997067 },
    { lat: 48.687988, lng: 17.997252 },
    { lat: 48.688378, lng: 17.997542 },
    { lat: 48.688582, lng: 17.99764 },
    { lat: 48.688634, lng: 17.99761 },
    { lat: 48.688937, lng: 17.997176 },
    { lat: 48.689165, lng: 17.996944 },
    { lat: 48.689401, lng: 17.996666 },
    { lat: 48.6892743, lng: 17.9964632 },
    { lat: 48.6892238, lng: 17.9963339 },
    { lat: 48.6889532, lng: 17.9964531 },
    { lat: 48.6883959, lng: 17.9964385 },
    { lat: 48.6883326, lng: 17.9963763 },
    { lat: 48.6881823, lng: 17.9960527 },
    { lat: 48.6878662, lng: 17.9957468 },
    { lat: 48.6876738, lng: 17.9953192 },
    { lat: 48.6876859, lng: 17.9952197 },
    { lat: 48.6875647, lng: 17.9950789 },
    { lat: 48.6869435, lng: 17.994579 },
    { lat: 48.6867196, lng: 17.9944564 },
    { lat: 48.6867214, lng: 17.9943154 },
    { lat: 48.686553, lng: 17.9943303 },
    { lat: 48.6864274, lng: 17.9942116 },
    { lat: 48.6855808, lng: 17.9937708 },
    { lat: 48.684536, lng: 17.9928085 },
    { lat: 48.6841424, lng: 17.9926804 },
    { lat: 48.6835173, lng: 17.9927018 },
    { lat: 48.6827085, lng: 17.9925198 },
    { lat: 48.6821961, lng: 17.9925781 },
    { lat: 48.6820245, lng: 17.992595 },
    { lat: 48.681191, lng: 17.9934786 },
    { lat: 48.6808555, lng: 17.9936094 },
    { lat: 48.6809447, lng: 17.9934384 },
    { lat: 48.6808597, lng: 17.9932056 },
    { lat: 48.6813701, lng: 17.9924286 },
    { lat: 48.6813123, lng: 17.992256 },
    { lat: 48.6811304, lng: 17.9919849 },
    { lat: 48.6810268, lng: 17.99157 },
    { lat: 48.6808894, lng: 17.9913271 },
    { lat: 48.6809317, lng: 17.9911882 },
    { lat: 48.6810509, lng: 17.9909692 },
    { lat: 48.6809713, lng: 17.9908467 },
    { lat: 48.6809287, lng: 17.9905835 },
    { lat: 48.6810359, lng: 17.9900711 },
    { lat: 48.6810201, lng: 17.9899954 },
    { lat: 48.6810624, lng: 17.9897014 },
    { lat: 48.6812025, lng: 17.9893042 },
    { lat: 48.6814672, lng: 17.9889905 },
    { lat: 48.6819057, lng: 17.9886753 },
    { lat: 48.6823193, lng: 17.9882085 },
    { lat: 48.682641, lng: 17.9879817 },
    { lat: 48.6825807, lng: 17.9877838 },
    { lat: 48.6825173, lng: 17.9871544 },
    { lat: 48.6823927, lng: 17.9870716 },
    { lat: 48.6823084, lng: 17.9870777 },
    { lat: 48.682203, lng: 17.9870116 },
    { lat: 48.6823034, lng: 17.9867266 },
    { lat: 48.6826112, lng: 17.9866252 },
    { lat: 48.682616, lng: 17.9864235 },
    { lat: 48.6826838, lng: 17.9863868 },
    { lat: 48.682801, lng: 17.9858865 },
    { lat: 48.6828397, lng: 17.9856098 },
    { lat: 48.6828943, lng: 17.9854463 },
    { lat: 48.682937, lng: 17.9852541 },
    { lat: 48.683084, lng: 17.9849538 },
    { lat: 48.6831553, lng: 17.9846891 },
    { lat: 48.6832627, lng: 17.9843516 },
    { lat: 48.6833829, lng: 17.9841339 },
    { lat: 48.6833135, lng: 17.9839525 },
    { lat: 48.6834686, lng: 17.9837879 },
    { lat: 48.6836642, lng: 17.9837207 },
    { lat: 48.6840817, lng: 17.9837376 },
    { lat: 48.6841354, lng: 17.9835032 },
    { lat: 48.6843402, lng: 17.9833947 },
    { lat: 48.6843145, lng: 17.9828309 },
    { lat: 48.6837522, lng: 17.9830365 },
    { lat: 48.6836072, lng: 17.9829322 },
    { lat: 48.6834684, lng: 17.9827978 },
    { lat: 48.6833757, lng: 17.9825685 },
    { lat: 48.6832529, lng: 17.9824195 },
    { lat: 48.682994, lng: 17.9826603 },
    { lat: 48.6826496, lng: 17.9828299 },
    { lat: 48.6825542, lng: 17.983275 },
    { lat: 48.6824113, lng: 17.9835287 },
    { lat: 48.6823922, lng: 17.9836656 },
    { lat: 48.6823996, lng: 17.9843379 },
    { lat: 48.6823942, lng: 17.9847055 },
    { lat: 48.6822807, lng: 17.9853184 },
    { lat: 48.6818308, lng: 17.9859895 },
    { lat: 48.681619, lng: 17.9861703 },
    { lat: 48.6813936, lng: 17.9864423 },
    { lat: 48.6812534, lng: 17.9865703 },
    { lat: 48.681239, lng: 17.9866708 },
    { lat: 48.6808315, lng: 17.9872961 },
    { lat: 48.6806972, lng: 17.9872007 },
    { lat: 48.6802743, lng: 17.9879638 },
    { lat: 48.6800674, lng: 17.9882833 },
    { lat: 48.6798373, lng: 17.9883637 },
    { lat: 48.6796699, lng: 17.9883371 },
    { lat: 48.6794671, lng: 17.9882471 },
    { lat: 48.6792843, lng: 17.9883436 },
    { lat: 48.6791719, lng: 17.9882949 },
    { lat: 48.6790589, lng: 17.988367 },
    { lat: 48.6787192, lng: 17.9883302 },
    { lat: 48.6786432, lng: 17.9884019 },
    { lat: 48.6785324, lng: 17.9883994 },
    { lat: 48.6785101, lng: 17.9883064 },
    { lat: 48.6782313, lng: 17.9880806 },
    { lat: 48.6780142, lng: 17.9878618 },
    { lat: 48.6778701, lng: 17.9877384 },
    { lat: 48.6776467, lng: 17.9879298 },
    { lat: 48.6775858, lng: 17.9871523 },
    { lat: 48.677509, lng: 17.9871295 },
    { lat: 48.6774946, lng: 17.9869068 },
    { lat: 48.6773275, lng: 17.9867337 },
    { lat: 48.677238, lng: 17.9867287 },
    { lat: 48.6766355, lng: 17.9857647 },
    { lat: 48.6763886, lng: 17.9856181 },
    { lat: 48.6761731, lng: 17.9853738 },
    { lat: 48.6763261, lng: 17.9852698 },
    { lat: 48.6763438, lng: 17.9849035 },
    { lat: 48.6764995, lng: 17.9848813 },
    { lat: 48.6766858, lng: 17.985095 },
    { lat: 48.6769387, lng: 17.9853029 },
    { lat: 48.6771089, lng: 17.9853919 },
    { lat: 48.6772191, lng: 17.9854241 },
    { lat: 48.6775772, lng: 17.9857695 },
    { lat: 48.6777768, lng: 17.9859214 },
    { lat: 48.677913, lng: 17.9859126 },
    { lat: 48.6780149, lng: 17.9858791 },
    { lat: 48.678075, lng: 17.9859046 },
    { lat: 48.6781555, lng: 17.9858937 },
    { lat: 48.6783723, lng: 17.9859328 },
    { lat: 48.6785905, lng: 17.985994 },
    { lat: 48.6786975, lng: 17.9859513 },
    { lat: 48.6789658, lng: 17.9860999 },
    { lat: 48.679097, lng: 17.9861428 },
    { lat: 48.679219, lng: 17.9858956 },
    { lat: 48.6792345, lng: 17.9856831 },
    { lat: 48.6791493, lng: 17.985634 },
    { lat: 48.6788982, lng: 17.9853612 },
    { lat: 48.6785945, lng: 17.9851309 },
    { lat: 48.6784403, lng: 17.9848357 },
    { lat: 48.678224, lng: 17.984499 },
    { lat: 48.6779874, lng: 17.9844314 },
    { lat: 48.6777743, lng: 17.983906 },
    { lat: 48.6776182, lng: 17.9834593 },
    { lat: 48.6773555, lng: 17.9834288 },
    { lat: 48.6772482, lng: 17.9833909 },
    { lat: 48.6770556, lng: 17.9833977 },
    { lat: 48.6767071, lng: 17.9831728 },
    { lat: 48.6765336, lng: 17.9831781 },
    { lat: 48.6764419, lng: 17.9833077 },
    { lat: 48.6761987, lng: 17.983601 },
    { lat: 48.6760094, lng: 17.9836278 },
    { lat: 48.6759141, lng: 17.9835993 },
    { lat: 48.6756941, lng: 17.9836741 },
    { lat: 48.6754907, lng: 17.9837099 },
    { lat: 48.6753816, lng: 17.9836779 },
    { lat: 48.6753137, lng: 17.9839039 },
    { lat: 48.6752026, lng: 17.9840646 },
    { lat: 48.6752158, lng: 17.984135 },
    { lat: 48.6748103, lng: 17.9847578 },
    { lat: 48.6747266, lng: 17.9848302 },
    { lat: 48.6745809, lng: 17.9848853 },
    { lat: 48.6745205, lng: 17.9851981 },
    { lat: 48.6744916, lng: 17.9847521 },
    { lat: 48.6743489, lng: 17.982619 },
    { lat: 48.6742963, lng: 17.9817701 },
    { lat: 48.6740971, lng: 17.9814586 },
    { lat: 48.6736207, lng: 17.9806789 },
    { lat: 48.6737982, lng: 17.9807395 },
    { lat: 48.6738836, lng: 17.9807119 },
    { lat: 48.6738761, lng: 17.9806068 },
    { lat: 48.674009, lng: 17.980507 },
    { lat: 48.6740581, lng: 17.980291 },
    { lat: 48.6740008, lng: 17.9802567 },
    { lat: 48.6738176, lng: 17.9800051 },
    { lat: 48.6737116, lng: 17.979627 },
    { lat: 48.6736944, lng: 17.9794694 },
    { lat: 48.6737228, lng: 17.9792616 },
    { lat: 48.6737352, lng: 17.9788471 },
    { lat: 48.6737178, lng: 17.9785348 },
    { lat: 48.6736262, lng: 17.9784572 },
    { lat: 48.6735011, lng: 17.9783821 },
    { lat: 48.673353, lng: 17.9783975 },
    { lat: 48.6731731, lng: 17.9783719 },
    { lat: 48.6729786, lng: 17.9784615 },
    { lat: 48.6729806, lng: 17.978797 },
    { lat: 48.6728385, lng: 17.9789134 },
    { lat: 48.6727417, lng: 17.9788608 },
    { lat: 48.6727503, lng: 17.9776411 },
    { lat: 48.6727838, lng: 17.9755457 },
    { lat: 48.6728726, lng: 17.9755404 },
    { lat: 48.6730251, lng: 17.9757147 },
    { lat: 48.6730856, lng: 17.9757243 },
    { lat: 48.673139, lng: 17.9758267 },
    { lat: 48.6731358, lng: 17.9761918 },
    { lat: 48.6731816, lng: 17.9762973 },
    { lat: 48.6732317, lng: 17.9763045 },
    { lat: 48.6732401, lng: 17.9760833 },
    { lat: 48.6732585, lng: 17.9758808 },
    { lat: 48.6732485, lng: 17.9757519 },
    { lat: 48.6731131, lng: 17.9755231 },
    { lat: 48.6729581, lng: 17.9754038 },
    { lat: 48.6729959, lng: 17.975244 },
    { lat: 48.6731778, lng: 17.9748865 },
    { lat: 48.6731607, lng: 17.974806 },
    { lat: 48.6728823, lng: 17.9747938 },
    { lat: 48.6728766, lng: 17.9745467 },
    { lat: 48.672806, lng: 17.9744613 },
    { lat: 48.6728275, lng: 17.9741987 },
    { lat: 48.6728637, lng: 17.9741599 },
    { lat: 48.6730149, lng: 17.9741401 },
    { lat: 48.6732536, lng: 17.9738673 },
    { lat: 48.6732598, lng: 17.9736661 },
    { lat: 48.6733918, lng: 17.97342 },
    { lat: 48.6731986, lng: 17.9732056 },
    { lat: 48.6730961, lng: 17.9732677 },
    { lat: 48.6729545, lng: 17.9731458 },
    { lat: 48.6733206, lng: 17.9726769 },
    { lat: 48.6734397, lng: 17.9726878 },
    { lat: 48.6736754, lng: 17.9724741 },
    { lat: 48.6735442, lng: 17.9721494 },
    { lat: 48.6732248, lng: 17.9719417 },
    { lat: 48.6728319, lng: 17.9717319 },
    { lat: 48.6728537, lng: 17.9701633 },
    { lat: 48.6728938, lng: 17.9668602 },
    { lat: 48.6729042, lng: 17.9660895 },
    { lat: 48.6729086, lng: 17.9645529 },
    { lat: 48.6729252, lng: 17.9636018 },
    { lat: 48.6729214, lng: 17.9620995 },
    { lat: 48.6729109, lng: 17.9619228 },
    { lat: 48.6722485, lng: 17.9624164 },
    { lat: 48.6721172, lng: 17.9623721 },
    { lat: 48.6719334, lng: 17.9624714 },
    { lat: 48.6717834, lng: 17.962585 },
    { lat: 48.6715257, lng: 17.9625796 },
    { lat: 48.6713177, lng: 17.962634 },
    { lat: 48.6710883, lng: 17.9628163 },
    { lat: 48.6710635, lng: 17.9630464 },
    { lat: 48.6708758, lng: 17.9630412 },
    { lat: 48.6704758, lng: 17.9623396 },
    { lat: 48.6704688, lng: 17.9623273 },
    { lat: 48.6703552, lng: 17.9622785 },
    { lat: 48.6702707, lng: 17.9623575 },
    { lat: 48.670047, lng: 17.9629568 },
    { lat: 48.670039, lng: 17.9634853 },
    { lat: 48.6700164, lng: 17.9635827 },
    { lat: 48.6700329, lng: 17.9637004 },
    { lat: 48.670053, lng: 17.9638894 },
    { lat: 48.6701291, lng: 17.9642573 },
    { lat: 48.6701685, lng: 17.9645219 },
    { lat: 48.6699447, lng: 17.964604 },
    { lat: 48.6693963, lng: 17.964805 },
    { lat: 48.6684511, lng: 17.9655927 },
    { lat: 48.6683686, lng: 17.96566 },
    { lat: 48.6680444, lng: 17.9659247 },
    { lat: 48.6675735, lng: 17.9653738 },
    { lat: 48.667345, lng: 17.9653453 },
    { lat: 48.667298, lng: 17.9651081 },
    { lat: 48.6669996, lng: 17.9651169 },
    { lat: 48.6669751, lng: 17.9653531 },
    { lat: 48.6667476, lng: 17.9652833 },
    { lat: 48.6667614, lng: 17.9651729 },
    { lat: 48.6666635, lng: 17.9651215 },
    { lat: 48.6665301, lng: 17.9651172 },
    { lat: 48.6664759, lng: 17.965177 },
    { lat: 48.6662885, lng: 17.965175 },
    { lat: 48.6662463, lng: 17.9652454 },
    { lat: 48.6659063, lng: 17.9651077 },
    { lat: 48.6657223, lng: 17.9648035 },
    { lat: 48.6655088, lng: 17.9648203 },
    { lat: 48.6654417, lng: 17.9646431 },
    { lat: 48.6653961, lng: 17.9644068 },
    { lat: 48.6654284, lng: 17.9642103 },
    { lat: 48.6652561, lng: 17.9639345 },
    { lat: 48.6650988, lng: 17.9641527 },
    { lat: 48.6648415, lng: 17.9642687 },
    { lat: 48.6646578, lng: 17.9646893 },
    { lat: 48.6642977, lng: 17.9646129 },
    { lat: 48.664066, lng: 17.9640938 },
    { lat: 48.6637957, lng: 17.9648643 },
    { lat: 48.6634163, lng: 17.9646899 },
    { lat: 48.6628653, lng: 17.9647069 },
    { lat: 48.6627441, lng: 17.9648494 },
    { lat: 48.6627093, lng: 17.9649666 },
    { lat: 48.6625993, lng: 17.9650141 },
    { lat: 48.6625237, lng: 17.9650918 },
    { lat: 48.6623573, lng: 17.9654422 },
    { lat: 48.661865, lng: 17.9664049 },
    { lat: 48.6617425, lng: 17.9664919 },
    { lat: 48.6613157, lng: 17.9668677 },
    { lat: 48.6611405, lng: 17.9670315 },
    { lat: 48.6610249, lng: 17.967215 },
    { lat: 48.6609452, lng: 17.9673526 },
    { lat: 48.6608839, lng: 17.9675183 },
    { lat: 48.6606441, lng: 17.9683057 },
    { lat: 48.6605667, lng: 17.9684829 },
    { lat: 48.6605128, lng: 17.9685817 },
    { lat: 48.6604454, lng: 17.9686661 },
    { lat: 48.6603841, lng: 17.9687163 },
    { lat: 48.6602768, lng: 17.9687694 },
    { lat: 48.6597356, lng: 17.9688922 },
    { lat: 48.6595942, lng: 17.9689089 },
    { lat: 48.6593152, lng: 17.9689221 },
    { lat: 48.6578885, lng: 17.9689217 },
    { lat: 48.6578122, lng: 17.9688717 },
    { lat: 48.6577505, lng: 17.9687294 },
    { lat: 48.6577073, lng: 17.9685536 },
    { lat: 48.6575186, lng: 17.9681326 },
    { lat: 48.6571865, lng: 17.9683191 },
    { lat: 48.6571278, lng: 17.9680722 },
    { lat: 48.6569385, lng: 17.967452 },
    { lat: 48.6567978, lng: 17.9670252 },
    { lat: 48.656448, lng: 17.9664223 },
    { lat: 48.6562577, lng: 17.9662971 },
    { lat: 48.6556879, lng: 17.9665259 },
    { lat: 48.6554141, lng: 17.9665314 },
    { lat: 48.6545989, lng: 17.9668713 },
    { lat: 48.6543451, lng: 17.966874 },
    { lat: 48.6540607, lng: 17.9669476 },
    { lat: 48.6537816, lng: 17.9669845 },
    { lat: 48.653438, lng: 17.9670683 },
    { lat: 48.6533169, lng: 17.9671204 },
    { lat: 48.6532852, lng: 17.9672508 },
    { lat: 48.6532896, lng: 17.9674954 },
    { lat: 48.6532032, lng: 17.9674868 },
    { lat: 48.6531738, lng: 17.967615 },
    { lat: 48.6529398, lng: 17.9676854 },
    { lat: 48.652819, lng: 17.9677068 },
    { lat: 48.6527176, lng: 17.9676884 },
    { lat: 48.6522442, lng: 17.9676424 },
    { lat: 48.6520432, lng: 17.9676597 },
    { lat: 48.6519239, lng: 17.9677048 },
    { lat: 48.6518243, lng: 17.9678119 },
    { lat: 48.6516892, lng: 17.9679887 },
    { lat: 48.6516853, lng: 17.9681146 },
    { lat: 48.6514874, lng: 17.9683139 },
    { lat: 48.6508447, lng: 17.9688006 },
    { lat: 48.6507096, lng: 17.968633 },
    { lat: 48.6506022, lng: 17.9689538 },
    { lat: 48.6502917, lng: 17.9689707 },
    { lat: 48.65012, lng: 17.9688929 },
    { lat: 48.6500205, lng: 17.9690396 },
    { lat: 48.6499385, lng: 17.9689347 },
    { lat: 48.6498736, lng: 17.9690003 },
    { lat: 48.6496245, lng: 17.9696138 },
    { lat: 48.6495312, lng: 17.9696558 },
    { lat: 48.6494395, lng: 17.9695979 },
    { lat: 48.6494172, lng: 17.9700721 },
    { lat: 48.6493423, lng: 17.9704075 },
    { lat: 48.6491825, lng: 17.970266 },
    { lat: 48.6490089, lng: 17.9701557 },
    { lat: 48.6490368, lng: 17.9697893 },
    { lat: 48.6486435, lng: 17.9694218 },
    { lat: 48.6480974, lng: 17.9694253 },
    { lat: 48.6477432, lng: 17.9694085 },
    { lat: 48.6467092, lng: 17.9689171 },
    { lat: 48.6465098, lng: 17.9687707 },
    { lat: 48.6465665, lng: 17.9691895 },
    { lat: 48.6442536, lng: 17.9688104 },
    { lat: 48.6434904, lng: 17.968656 },
    { lat: 48.6432496, lng: 17.9682315 },
    { lat: 48.6431695, lng: 17.9680625 },
    { lat: 48.6430316, lng: 17.9677222 },
    { lat: 48.642754, lng: 17.9676075 },
    { lat: 48.6426434, lng: 17.9676443 },
    { lat: 48.6426689, lng: 17.9678664 },
    { lat: 48.642495, lng: 17.9677112 },
    { lat: 48.642404, lng: 17.967549 },
    { lat: 48.6423719, lng: 17.9673724 },
    { lat: 48.6423327, lng: 17.967357 },
    { lat: 48.6423258, lng: 17.9672966 },
    { lat: 48.6422904, lng: 17.9672143 },
    { lat: 48.6422256, lng: 17.967207 },
    { lat: 48.6421782, lng: 17.96713 },
    { lat: 48.6421156, lng: 17.9669522 },
    { lat: 48.6418952, lng: 17.9663986 },
    { lat: 48.641857, lng: 17.9665155 },
    { lat: 48.6417734, lng: 17.9666289 },
    { lat: 48.6413692, lng: 17.9670054 },
    { lat: 48.6408526, lng: 17.9677658 },
    { lat: 48.640527, lng: 17.967987 },
    { lat: 48.6402681, lng: 17.96811 },
    { lat: 48.6398899, lng: 17.9680906 },
    { lat: 48.6397105, lng: 17.9679776 },
    { lat: 48.6396324, lng: 17.9678779 },
    { lat: 48.6395755, lng: 17.9677415 },
    { lat: 48.6395605, lng: 17.9674148 },
    { lat: 48.6394137, lng: 17.9672406 },
    { lat: 48.6392111, lng: 17.9673067 },
    { lat: 48.6387757, lng: 17.9675003 },
    { lat: 48.6385801, lng: 17.9676086 },
    { lat: 48.6384847, lng: 17.9676384 },
    { lat: 48.6383669, lng: 17.9676297 },
    { lat: 48.6382386, lng: 17.9675961 },
    { lat: 48.6381409, lng: 17.9676993 },
    { lat: 48.6379335, lng: 17.9681247 },
    { lat: 48.6378525, lng: 17.9682021 },
    { lat: 48.6377255, lng: 17.9682696 },
    { lat: 48.6368982, lng: 17.9683666 },
    { lat: 48.6367112, lng: 17.9682967 },
    { lat: 48.6367148, lng: 17.9682812 },
    { lat: 48.6367169, lng: 17.9682403 },
    { lat: 48.6361857, lng: 17.968225 },
    { lat: 48.6356447, lng: 17.9681842 },
    { lat: 48.6351864, lng: 17.9683917 },
    { lat: 48.6347748, lng: 17.968585 },
    { lat: 48.6347305, lng: 17.9688661 },
    { lat: 48.6347624, lng: 17.9691548 },
    { lat: 48.6348556, lng: 17.9694494 },
    { lat: 48.6352163, lng: 17.9703882 },
    { lat: 48.6353602, lng: 17.9706645 },
    { lat: 48.6354981, lng: 17.9710651 },
    { lat: 48.6355708, lng: 17.9713921 },
    { lat: 48.6356477, lng: 17.9718291 },
    { lat: 48.6362782, lng: 17.9736967 },
    { lat: 48.6357283, lng: 17.9737681 },
    { lat: 48.6348045, lng: 17.9750318 },
    { lat: 48.6349815, lng: 17.9758628 },
    { lat: 48.6352184, lng: 17.9762178 },
    { lat: 48.6358512, lng: 17.9767251 },
    { lat: 48.6359748, lng: 17.9768461 },
    { lat: 48.63606, lng: 17.9770447 },
    { lat: 48.6361212, lng: 17.977334 },
    { lat: 48.6362679, lng: 17.9786781 },
    { lat: 48.636237, lng: 17.9786701 },
    { lat: 48.635007, lng: 17.9788283 },
    { lat: 48.6349591, lng: 17.9788569 },
    { lat: 48.6348428, lng: 17.9785922 },
    { lat: 48.6346541, lng: 17.977756 },
    { lat: 48.6345933, lng: 17.9775683 },
    { lat: 48.6344088, lng: 17.9772747 },
    { lat: 48.6343221, lng: 17.9770495 },
    { lat: 48.6342658, lng: 17.9768441 },
    { lat: 48.6342128, lng: 17.9768834 },
    { lat: 48.6337484, lng: 17.9772904 },
    { lat: 48.6334371, lng: 17.9778047 },
    { lat: 48.6329564, lng: 17.9790032 },
    { lat: 48.6331057, lng: 17.9797105 },
    { lat: 48.6320439, lng: 17.9804172 },
    { lat: 48.6309708, lng: 17.9807435 },
    { lat: 48.6311252, lng: 17.9822002 },
    { lat: 48.6301169, lng: 17.9832727 },
    { lat: 48.6297283, lng: 17.9815798 },
    { lat: 48.6301444, lng: 17.9807235 },
    { lat: 48.6300108, lng: 17.9804847 },
    { lat: 48.6293677, lng: 17.9802831 },
    { lat: 48.6272405, lng: 17.9782216 },
    { lat: 48.6272096, lng: 17.9784459 },
    { lat: 48.6271423, lng: 17.9785628 },
    { lat: 48.6269876, lng: 17.9786776 },
    { lat: 48.6263494, lng: 17.9788797 },
    { lat: 48.626132, lng: 17.9789819 },
    { lat: 48.6259069, lng: 17.9791823 },
    { lat: 48.625876, lng: 17.9794209 },
    { lat: 48.6259133, lng: 17.9800284 },
    { lat: 48.6260031, lng: 17.9802296 },
    { lat: 48.6261183, lng: 17.9805515 },
    { lat: 48.6263271, lng: 17.9810231 },
    { lat: 48.6264468, lng: 17.9811945 },
    { lat: 48.626763, lng: 17.9817104 },
    { lat: 48.6268668, lng: 17.9818401 },
    { lat: 48.6269999, lng: 17.9820697 },
    { lat: 48.6270443, lng: 17.9825689 },
    { lat: 48.6270132, lng: 17.9831629 },
    { lat: 48.6269772, lng: 17.9836395 },
    { lat: 48.6269846, lng: 17.9840705 },
    { lat: 48.6270705, lng: 17.9844689 },
    { lat: 48.6270662, lng: 17.9848134 },
    { lat: 48.6270174, lng: 17.9851904 },
    { lat: 48.6269125, lng: 17.9857005 },
    { lat: 48.6267894, lng: 17.9866127 },
    { lat: 48.6267272, lng: 17.9868195 },
    { lat: 48.6266356, lng: 17.986965 },
    { lat: 48.6265318, lng: 17.9870822 },
    { lat: 48.6265078, lng: 17.9873426 },
    { lat: 48.6264658, lng: 17.9876216 },
    { lat: 48.6263448, lng: 17.9878068 },
    { lat: 48.6262339, lng: 17.9882003 },
    { lat: 48.6260579, lng: 17.98847 },
    { lat: 48.6248223, lng: 17.9921022 },
    { lat: 48.6248038, lng: 17.9921493 },
    { lat: 48.6246552, lng: 17.9923267 },
    { lat: 48.6245055, lng: 17.9926563 },
    { lat: 48.6242952, lng: 17.993161 },
    { lat: 48.6241344, lng: 17.9934771 },
    { lat: 48.6238628, lng: 17.9937504 },
    { lat: 48.6235439, lng: 17.9939113 },
    { lat: 48.6232463, lng: 17.993919 },
    { lat: 48.6229903, lng: 17.9940917 },
    { lat: 48.6225936, lng: 17.994084 },
    { lat: 48.6222642, lng: 17.9941236 },
    { lat: 48.6221142, lng: 17.9945056 },
    { lat: 48.6219899, lng: 17.9950921 },
    { lat: 48.6219299, lng: 17.9956582 },
    { lat: 48.6216437, lng: 17.9961236 },
    { lat: 48.6215358, lng: 17.9966055 },
    { lat: 48.6214167, lng: 17.9970333 },
    { lat: 48.62133, lng: 17.9972266 },
    { lat: 48.6212013, lng: 17.9976425 },
    { lat: 48.6212478, lng: 17.9980478 },
    { lat: 48.6212529, lng: 17.9982255 },
    { lat: 48.6218168, lng: 17.9981069 },
    { lat: 48.6217854, lng: 17.9983387 },
    { lat: 48.6218149, lng: 17.9985128 },
    { lat: 48.6218749, lng: 17.9987219 },
    { lat: 48.6218467, lng: 17.998781 },
    { lat: 48.6214181, lng: 17.9998328 },
    { lat: 48.621229, lng: 18.000639 },
    { lat: 48.62184, lng: 18.001441 },
    { lat: 48.622391, lng: 18.002069 },
    { lat: 48.624668, lng: 18.005926 },
    { lat: 48.625024, lng: 18.006536 },
    { lat: 48.625472, lng: 18.007188 },
    { lat: 48.625638, lng: 18.006759 },
    { lat: 48.625698, lng: 18.00636 },
    { lat: 48.625979, lng: 18.005935 },
    { lat: 48.626352, lng: 18.005258 },
    { lat: 48.626628, lng: 18.004769 },
    { lat: 48.626929, lng: 18.004091 },
    { lat: 48.627343, lng: 18.003295 },
    { lat: 48.62753, lng: 18.00308 },
    { lat: 48.628046, lng: 18.00268 },
    { lat: 48.628191, lng: 18.002473 },
    { lat: 48.628315, lng: 18.002137 },
    { lat: 48.628325, lng: 18.002097 },
    { lat: 48.628353, lng: 18.002024 },
    { lat: 48.6284, lng: 18.002065 },
    { lat: 48.628422, lng: 18.002097 },
    { lat: 48.628495, lng: 18.002171 },
    { lat: 48.62855, lng: 18.002315 },
    { lat: 48.628852, lng: 18.003033 },
    { lat: 48.628925, lng: 18.003129 },
    { lat: 48.628955, lng: 18.003166 },
    { lat: 48.62922, lng: 18.003716 },
    { lat: 48.62913, lng: 18.003876 },
    { lat: 48.628783, lng: 18.004531 },
    { lat: 48.628699, lng: 18.00467 },
    { lat: 48.628472, lng: 18.005015 },
    { lat: 48.628451, lng: 18.005088 },
    { lat: 48.628079, lng: 18.005607 },
    { lat: 48.627873, lng: 18.005961 },
    { lat: 48.627562, lng: 18.006331 },
    { lat: 48.627501, lng: 18.006453 },
    { lat: 48.627419, lng: 18.006634 },
    { lat: 48.62731, lng: 18.006902 },
    { lat: 48.627071, lng: 18.007459 },
    { lat: 48.626831, lng: 18.007776 },
    { lat: 48.626787, lng: 18.00793 },
    { lat: 48.626785, lng: 18.008283 },
    { lat: 48.626727, lng: 18.008379 },
    { lat: 48.626358, lng: 18.008533 },
    { lat: 48.626248, lng: 18.008637 },
    { lat: 48.625999, lng: 18.009423 },
    { lat: 48.625928, lng: 18.009611 },
    { lat: 48.625583, lng: 18.010288 },
    { lat: 48.625405, lng: 18.010581 },
    { lat: 48.625315, lng: 18.010723 },
    { lat: 48.624988, lng: 18.01136 },
    { lat: 48.624292, lng: 18.012659 },
    { lat: 48.623944, lng: 18.013272 },
    { lat: 48.62374, lng: 18.013597 },
    { lat: 48.623326, lng: 18.014392 },
    { lat: 48.623258, lng: 18.014722 },
    { lat: 48.623204, lng: 18.015326 },
    { lat: 48.623129, lng: 18.015845 },
    { lat: 48.623185, lng: 18.015841 },
    { lat: 48.623301, lng: 18.01578 },
    { lat: 48.623652, lng: 18.015596 },
    { lat: 48.624033, lng: 18.015428 },
    { lat: 48.624174, lng: 18.015437 },
    { lat: 48.624518, lng: 18.015554 },
    { lat: 48.624925, lng: 18.015998 },
    { lat: 48.624961, lng: 18.016043 },
    { lat: 48.625201, lng: 18.016519 },
    { lat: 48.625335, lng: 18.016587 },
    { lat: 48.625449, lng: 18.016849 },
    { lat: 48.625606, lng: 18.017077 },
    { lat: 48.625744, lng: 18.017248 },
    { lat: 48.626003, lng: 18.017456 },
    { lat: 48.626311, lng: 18.017541 },
    { lat: 48.626558, lng: 18.017502 },
    { lat: 48.6269, lng: 18.01724 },
    { lat: 48.62746, lng: 18.016693 },
    { lat: 48.62801, lng: 18.016193 },
    { lat: 48.628535, lng: 18.015493 },
    { lat: 48.628664, lng: 18.01525 },
    { lat: 48.628814, lng: 18.015047 },
    { lat: 48.629098, lng: 18.014682 },
    { lat: 48.629413, lng: 18.014362 },
    { lat: 48.629571, lng: 18.014227 },
    { lat: 48.629675, lng: 18.014186 },
    { lat: 48.629847, lng: 18.014116 },
    { lat: 48.630075, lng: 18.014157 },
    { lat: 48.630365, lng: 18.014173 },
    { lat: 48.630699, lng: 18.014341 },
    { lat: 48.631009, lng: 18.014368 },
    { lat: 48.631417, lng: 18.014522 },
    { lat: 48.631742, lng: 18.014783 },
    { lat: 48.632181, lng: 18.014956 },
    { lat: 48.632465, lng: 18.01476 },
    { lat: 48.633013, lng: 18.015244 },
    { lat: 48.633415, lng: 18.015784 },
    { lat: 48.633783, lng: 18.016948 },
    { lat: 48.633968, lng: 18.017654 },
    { lat: 48.634069, lng: 18.018322 },
    { lat: 48.634552, lng: 18.018512 },
    { lat: 48.635101, lng: 18.018785 },
    { lat: 48.635423, lng: 18.018967 },
    { lat: 48.635708, lng: 18.019402 },
    { lat: 48.63585, lng: 18.019648 },
    { lat: 48.636127, lng: 18.020394 },
    { lat: 48.636574, lng: 18.02094 },
    { lat: 48.636778, lng: 18.02125 },
    { lat: 48.637398, lng: 18.021733 },
    { lat: 48.637556, lng: 18.022113 },
  ],
  Višňové: [
    { lat: 48.7183607, lng: 17.7541135 },
    { lat: 48.7183845, lng: 17.7541892 },
    { lat: 48.7189536, lng: 17.7551021 },
    { lat: 48.7191735, lng: 17.7574501 },
    { lat: 48.7194143, lng: 17.7600639 },
    { lat: 48.719444, lng: 17.7602523 },
    { lat: 48.7195017, lng: 17.7606052 },
    { lat: 48.7196204, lng: 17.7612861 },
    { lat: 48.7196551, lng: 17.7615392 },
    { lat: 48.7199134, lng: 17.7617897 },
    { lat: 48.7201177, lng: 17.7619887 },
    { lat: 48.7206302, lng: 17.7620711 },
    { lat: 48.7225562, lng: 17.7615052 },
    { lat: 48.7238579, lng: 17.7611917 },
    { lat: 48.7241582, lng: 17.7608742 },
    { lat: 48.7252752, lng: 17.7603667 },
    { lat: 48.7255609, lng: 17.7604559 },
    { lat: 48.7260594, lng: 17.7617649 },
    { lat: 48.7260722, lng: 17.7620125 },
    { lat: 48.7250212, lng: 17.762527 },
    { lat: 48.7242281, lng: 17.762935 },
    { lat: 48.7231407, lng: 17.7636121 },
    { lat: 48.7224083, lng: 17.7638116 },
    { lat: 48.7224331, lng: 17.7633329 },
    { lat: 48.7218353, lng: 17.763736 },
    { lat: 48.7211978, lng: 17.7642503 },
    { lat: 48.7213801, lng: 17.7645317 },
    { lat: 48.7215217, lng: 17.7645742 },
    { lat: 48.7215209, lng: 17.7648207 },
    { lat: 48.7215886, lng: 17.7650386 },
    { lat: 48.7215805, lng: 17.766141 },
    { lat: 48.7215279, lng: 17.7666104 },
    { lat: 48.7215147, lng: 17.7666725 },
    { lat: 48.7218748, lng: 17.7667582 },
    { lat: 48.7224423, lng: 17.7668175 },
    { lat: 48.7226732, lng: 17.7668236 },
    { lat: 48.723037, lng: 17.7667496 },
    { lat: 48.7233161, lng: 17.7667293 },
    { lat: 48.7236658, lng: 17.7668494 },
    { lat: 48.7241078, lng: 17.7668694 },
    { lat: 48.7245033, lng: 17.7667402 },
    { lat: 48.7247582, lng: 17.766806 },
    { lat: 48.7250417, lng: 17.7669326 },
    { lat: 48.7250424, lng: 17.7669623 },
    { lat: 48.7256893, lng: 17.7669912 },
    { lat: 48.7270901, lng: 17.7665584 },
    { lat: 48.7278787, lng: 17.7660945 },
    { lat: 48.7283766, lng: 17.7656997 },
    { lat: 48.7291223, lng: 17.7651079 },
    { lat: 48.7304923, lng: 17.7638809 },
    { lat: 48.7310562, lng: 17.7634752 },
    { lat: 48.7323472, lng: 17.7627467 },
    { lat: 48.7334145, lng: 17.7627555 },
    { lat: 48.7346599, lng: 17.7624247 },
    { lat: 48.7352957, lng: 17.7622331 },
    { lat: 48.7361441, lng: 17.7624498 },
    { lat: 48.7372288, lng: 17.7627066 },
    { lat: 48.73731, lng: 17.7627036 },
    { lat: 48.7374904, lng: 17.7626973 },
    { lat: 48.7376302, lng: 17.7626921 },
    { lat: 48.7378418, lng: 17.7626966 },
    { lat: 48.7379789, lng: 17.762591 },
    { lat: 48.7380759, lng: 17.7625161 },
    { lat: 48.7382065, lng: 17.7624161 },
    { lat: 48.7383285, lng: 17.7623226 },
    { lat: 48.7384904, lng: 17.7621978 },
    { lat: 48.740037, lng: 17.7636276 },
    { lat: 48.7411599, lng: 17.7642498 },
    { lat: 48.7414831, lng: 17.7645452 },
    { lat: 48.7425014, lng: 17.7643431 },
    { lat: 48.7441771, lng: 17.7647464 },
    { lat: 48.7451698, lng: 17.7649568 },
    { lat: 48.7454583, lng: 17.7652406 },
    { lat: 48.7459922, lng: 17.7657655 },
    { lat: 48.7467301, lng: 17.765968 },
    { lat: 48.7473118, lng: 17.7658347 },
    { lat: 48.7482127, lng: 17.7658947 },
    { lat: 48.7482659, lng: 17.7661346 },
    { lat: 48.7485814, lng: 17.7667474 },
    { lat: 48.7500117, lng: 17.7656905 },
    { lat: 48.7514231, lng: 17.7646407 },
    { lat: 48.7526303, lng: 17.7637786 },
    { lat: 48.7530369, lng: 17.7634613 },
    { lat: 48.7547744, lng: 17.7620343 },
    { lat: 48.7555549, lng: 17.7614574 },
    { lat: 48.7556084, lng: 17.761408 },
    { lat: 48.7556491, lng: 17.7613707 },
    { lat: 48.7563085, lng: 17.7608658 },
    { lat: 48.7567475, lng: 17.7605599 },
    { lat: 48.7557728, lng: 17.7602756 },
    { lat: 48.7556924, lng: 17.760252 },
    { lat: 48.7556051, lng: 17.7602271 },
    { lat: 48.7555452, lng: 17.7602099 },
    { lat: 48.7554556, lng: 17.7601836 },
    { lat: 48.7554245, lng: 17.7601747 },
    { lat: 48.7552728, lng: 17.7601307 },
    { lat: 48.7532024, lng: 17.7592861 },
    { lat: 48.7524161, lng: 17.7590934 },
    { lat: 48.7512957, lng: 17.7588105 },
    { lat: 48.7494344, lng: 17.7576599 },
    { lat: 48.749066, lng: 17.7566226 },
    { lat: 48.74906, lng: 17.7565447 },
    { lat: 48.7490237, lng: 17.7564658 },
    { lat: 48.7489481, lng: 17.7561937 },
    { lat: 48.7488105, lng: 17.7559562 },
    { lat: 48.7487481, lng: 17.7559141 },
    { lat: 48.7486865, lng: 17.7558273 },
    { lat: 48.7486512, lng: 17.7557506 },
    { lat: 48.7486158, lng: 17.7555971 },
    { lat: 48.7485501, lng: 17.7554296 },
    { lat: 48.7484435, lng: 17.7550529 },
    { lat: 48.7482088, lng: 17.7544286 },
    { lat: 48.7479397, lng: 17.7535949 },
    { lat: 48.7477778, lng: 17.7518253 },
    { lat: 48.7478756, lng: 17.7513021 },
    { lat: 48.7477701, lng: 17.7506515 },
    { lat: 48.7477979, lng: 17.7502559 },
    { lat: 48.7477442, lng: 17.7501529 },
    { lat: 48.7477475, lng: 17.7498795 },
    { lat: 48.7478275, lng: 17.7495066 },
    { lat: 48.7475645, lng: 17.749619 },
    { lat: 48.747304, lng: 17.7497536 },
    { lat: 48.7471806, lng: 17.7491588 },
    { lat: 48.7472793, lng: 17.7490942 },
    { lat: 48.7469661, lng: 17.7478353 },
    { lat: 48.7469114, lng: 17.7478454 },
    { lat: 48.7467222, lng: 17.7477436 },
    { lat: 48.7465986, lng: 17.7471829 },
    { lat: 48.7465861, lng: 17.7470183 },
    { lat: 48.7465002, lng: 17.7468336 },
    { lat: 48.7465034, lng: 17.7465341 },
    { lat: 48.7464335, lng: 17.7464258 },
    { lat: 48.7463858, lng: 17.7462919 },
    { lat: 48.7463869, lng: 17.7461633 },
    { lat: 48.7463417, lng: 17.7459973 },
    { lat: 48.7462785, lng: 17.7458319 },
    { lat: 48.746304, lng: 17.7457343 },
    { lat: 48.7462117, lng: 17.7453537 },
    { lat: 48.7462033, lng: 17.7451978 },
    { lat: 48.7462238, lng: 17.7451456 },
    { lat: 48.7461222, lng: 17.7448712 },
    { lat: 48.7459725, lng: 17.7446379 },
    { lat: 48.7459335, lng: 17.7443725 },
    { lat: 48.7458675, lng: 17.7441801 },
    { lat: 48.7457873, lng: 17.7440293 },
    { lat: 48.745758, lng: 17.7438112 },
    { lat: 48.7455306, lng: 17.7436761 },
    { lat: 48.7454383, lng: 17.7435911 },
    { lat: 48.7453923, lng: 17.7434663 },
    { lat: 48.7452759, lng: 17.7433692 },
    { lat: 48.7450277, lng: 17.7435036 },
    { lat: 48.7448755, lng: 17.7433225 },
    { lat: 48.7447166, lng: 17.743459 },
    { lat: 48.7446026, lng: 17.7434582 },
    { lat: 48.7444659, lng: 17.7434169 },
    { lat: 48.7440072, lng: 17.7431952 },
    { lat: 48.7438449, lng: 17.7431068 },
    { lat: 48.7437566, lng: 17.7430477 },
    { lat: 48.7436922, lng: 17.7430274 },
    { lat: 48.7435062, lng: 17.74283 },
    { lat: 48.7433983, lng: 17.7427643 },
    { lat: 48.7431447, lng: 17.7426615 },
    { lat: 48.7429848, lng: 17.7431082 },
    { lat: 48.7422745, lng: 17.7426293 },
    { lat: 48.7421446, lng: 17.7424991 },
    { lat: 48.7419114, lng: 17.742196 },
    { lat: 48.7417381, lng: 17.7419131 },
    { lat: 48.7415456, lng: 17.7416994 },
    { lat: 48.7413098, lng: 17.7410615 },
    { lat: 48.7412983, lng: 17.7407094 },
    { lat: 48.7412709, lng: 17.7405036 },
    { lat: 48.7412659, lng: 17.7403294 },
    { lat: 48.7411314, lng: 17.7402539 },
    { lat: 48.7407786, lng: 17.7399891 },
    { lat: 48.7406075, lng: 17.7398676 },
    { lat: 48.740491, lng: 17.7397362 },
    { lat: 48.7403029, lng: 17.7397773 },
    { lat: 48.7402035, lng: 17.7397774 },
    { lat: 48.7401355, lng: 17.7397179 },
    { lat: 48.7400317, lng: 17.7397582 },
    { lat: 48.7399099, lng: 17.7398888 },
    { lat: 48.7397834, lng: 17.7399792 },
    { lat: 48.7397214, lng: 17.7399442 },
    { lat: 48.7396456, lng: 17.7399613 },
    { lat: 48.7396116, lng: 17.7401056 },
    { lat: 48.7395679, lng: 17.740149 },
    { lat: 48.7392733, lng: 17.7401128 },
    { lat: 48.7391051, lng: 17.7399472 },
    { lat: 48.7389371, lng: 17.7400789 },
    { lat: 48.7389031, lng: 17.7401673 },
    { lat: 48.7387057, lng: 17.7403006 },
    { lat: 48.7385686, lng: 17.7402187 },
    { lat: 48.7385707, lng: 17.7400864 },
    { lat: 48.7385488, lng: 17.7399156 },
    { lat: 48.7382858, lng: 17.7398786 },
    { lat: 48.7381377, lng: 17.7398948 },
    { lat: 48.7380689, lng: 17.7399334 },
    { lat: 48.7377207, lng: 17.7399992 },
    { lat: 48.7374671, lng: 17.7399301 },
    { lat: 48.7373812, lng: 17.7398388 },
    { lat: 48.7373677, lng: 17.7397308 },
    { lat: 48.7373074, lng: 17.7395576 },
    { lat: 48.7372538, lng: 17.7395484 },
    { lat: 48.7372474, lng: 17.7394824 },
    { lat: 48.7370686, lng: 17.7391606 },
    { lat: 48.7370071, lng: 17.7388763 },
    { lat: 48.7370152, lng: 17.7383114 },
    { lat: 48.7370734, lng: 17.7382496 },
    { lat: 48.7370965, lng: 17.7380765 },
    { lat: 48.7371645, lng: 17.7378391 },
    { lat: 48.7371685, lng: 17.7377201 },
    { lat: 48.7367603, lng: 17.7377113 },
    { lat: 48.7361725, lng: 17.7377164 },
    { lat: 48.7359775, lng: 17.7377034 },
    { lat: 48.7357607, lng: 17.7376685 },
    { lat: 48.7356925, lng: 17.7376248 },
    { lat: 48.7355503, lng: 17.7373669 },
    { lat: 48.7350586, lng: 17.7374504 },
    { lat: 48.7344004, lng: 17.7376152 },
    { lat: 48.7340581, lng: 17.7376852 },
    { lat: 48.7339547, lng: 17.7377126 },
    { lat: 48.7337242, lng: 17.7377457 },
    { lat: 48.7334804, lng: 17.7378204 },
    { lat: 48.7329828, lng: 17.7381386 },
    { lat: 48.7327637, lng: 17.7382274 },
    { lat: 48.7319684, lng: 17.7386597 },
    { lat: 48.7312732, lng: 17.7390872 },
    { lat: 48.7310354, lng: 17.7392215 },
    { lat: 48.730985, lng: 17.7391571 },
    { lat: 48.7309465, lng: 17.7389694 },
    { lat: 48.7307886, lng: 17.7385678 },
    { lat: 48.7306425, lng: 17.7385972 },
    { lat: 48.7304618, lng: 17.738505 },
    { lat: 48.7303867, lng: 17.7383875 },
    { lat: 48.7302334, lng: 17.7382807 },
    { lat: 48.7301701, lng: 17.7382059 },
    { lat: 48.7300672, lng: 17.7381296 },
    { lat: 48.7300037, lng: 17.7381621 },
    { lat: 48.7298882, lng: 17.7379538 },
    { lat: 48.7298181, lng: 17.7379529 },
    { lat: 48.7296936, lng: 17.7378746 },
    { lat: 48.7295857, lng: 17.7377553 },
    { lat: 48.7289822, lng: 17.7384785 },
    { lat: 48.7284111, lng: 17.7392705 },
    { lat: 48.7278056, lng: 17.7400723 },
    { lat: 48.7276271, lng: 17.7400323 },
    { lat: 48.7275739, lng: 17.7398835 },
    { lat: 48.7273966, lng: 17.7399199 },
    { lat: 48.7271407, lng: 17.7401096 },
    { lat: 48.7266778, lng: 17.7404962 },
    { lat: 48.7265812, lng: 17.7406138 },
    { lat: 48.726432, lng: 17.7408687 },
    { lat: 48.7262303, lng: 17.7410393 },
    { lat: 48.7256206, lng: 17.7435156 },
    { lat: 48.7246455, lng: 17.7455217 },
    { lat: 48.7238716, lng: 17.7456977 },
    { lat: 48.7227989, lng: 17.7458058 },
    { lat: 48.7223781, lng: 17.7460144 },
    { lat: 48.7217089, lng: 17.7459439 },
    { lat: 48.7215867, lng: 17.7462882 },
    { lat: 48.7214935, lng: 17.7464075 },
    { lat: 48.7214115, lng: 17.7465778 },
    { lat: 48.721393, lng: 17.7467731 },
    { lat: 48.721246, lng: 17.7467873 },
    { lat: 48.7210637, lng: 17.7467807 },
    { lat: 48.721021, lng: 17.7468557 },
    { lat: 48.7209502, lng: 17.7469172 },
    { lat: 48.7207661, lng: 17.7470278 },
    { lat: 48.7207204, lng: 17.7470345 },
    { lat: 48.7207517, lng: 17.7473448 },
    { lat: 48.7207559, lng: 17.7475963 },
    { lat: 48.7207497, lng: 17.7481928 },
    { lat: 48.7207782, lng: 17.7485446 },
    { lat: 48.7207757, lng: 17.748688 },
    { lat: 48.7206616, lng: 17.7491649 },
    { lat: 48.7206577, lng: 17.7495788 },
    { lat: 48.7207158, lng: 17.7501009 },
    { lat: 48.7209216, lng: 17.7508436 },
    { lat: 48.7209847, lng: 17.7511694 },
    { lat: 48.7210961, lng: 17.7516426 },
    { lat: 48.721056, lng: 17.751798 },
    { lat: 48.7209577, lng: 17.7519613 },
    { lat: 48.7207617, lng: 17.7519519 },
    { lat: 48.7205799, lng: 17.7520236 },
    { lat: 48.7201835, lng: 17.7522605 },
    { lat: 48.7199048, lng: 17.7525767 },
    { lat: 48.7195712, lng: 17.7528613 },
    { lat: 48.7194817, lng: 17.7529092 },
    { lat: 48.7190489, lng: 17.7532009 },
    { lat: 48.7188495, lng: 17.7534256 },
    { lat: 48.7187651, lng: 17.7536527 },
    { lat: 48.7185562, lng: 17.7540002 },
    { lat: 48.7183607, lng: 17.7541135 },
  ],
  Modrová: [
    { lat: 48.634327, lng: 17.879181 },
    { lat: 48.63373, lng: 17.879473 },
    { lat: 48.632981, lng: 17.879805 },
    { lat: 48.632477, lng: 17.87987 },
    { lat: 48.632273, lng: 17.879895 },
    { lat: 48.630813, lng: 17.880106 },
    { lat: 48.630042, lng: 17.880402 },
    { lat: 48.629452, lng: 17.880622 },
    { lat: 48.629335, lng: 17.88064 },
    { lat: 48.628398, lng: 17.880475 },
    { lat: 48.628053, lng: 17.880466 },
    { lat: 48.627618, lng: 17.880466 },
    { lat: 48.627657, lng: 17.881609 },
    { lat: 48.627638, lng: 17.882185 },
    { lat: 48.627593, lng: 17.882448 },
    { lat: 48.627563, lng: 17.882759 },
    { lat: 48.627567, lng: 17.882989 },
    { lat: 48.62736, lng: 17.882925 },
    { lat: 48.627415, lng: 17.883735 },
    { lat: 48.629978, lng: 17.88514 },
    { lat: 48.629777, lng: 17.885945 },
    { lat: 48.62959, lng: 17.885887 },
    { lat: 48.629534, lng: 17.886203 },
    { lat: 48.629508, lng: 17.886366 },
    { lat: 48.629256, lng: 17.887088 },
    { lat: 48.629185, lng: 17.887391 },
    { lat: 48.629173, lng: 17.887595 },
    { lat: 48.629203, lng: 17.887736 },
    { lat: 48.629001, lng: 17.888248 },
    { lat: 48.62904, lng: 17.888485 },
    { lat: 48.629119, lng: 17.88875 },
    { lat: 48.629538, lng: 17.889422 },
    { lat: 48.62998, lng: 17.889969 },
    { lat: 48.630231, lng: 17.890994 },
    { lat: 48.630842, lng: 17.894146 },
    { lat: 48.632391, lng: 17.895812 },
    { lat: 48.632276, lng: 17.895966 },
    { lat: 48.63212, lng: 17.896099 },
    { lat: 48.632079, lng: 17.896277 },
    { lat: 48.632249, lng: 17.897091 },
    { lat: 48.632207, lng: 17.897198 },
    { lat: 48.631854, lng: 17.896758 },
    { lat: 48.631818, lng: 17.896746 },
    { lat: 48.631731, lng: 17.896566 },
    { lat: 48.631577, lng: 17.896339 },
    { lat: 48.631474, lng: 17.896214 },
    { lat: 48.631333, lng: 17.896086 },
    { lat: 48.630891, lng: 17.89642 },
    { lat: 48.630305, lng: 17.897413 },
    { lat: 48.630133, lng: 17.897979 },
    { lat: 48.629961, lng: 17.898294 },
    { lat: 48.629787, lng: 17.898551 },
    { lat: 48.629687, lng: 17.898654 },
    { lat: 48.629523, lng: 17.898868 },
    { lat: 48.629425, lng: 17.899045 },
    { lat: 48.628939, lng: 17.899825 },
    { lat: 48.627748, lng: 17.901736 },
    { lat: 48.62732, lng: 17.902468 },
    { lat: 48.62683, lng: 17.903244 },
    { lat: 48.626434, lng: 17.904209 },
    { lat: 48.625811, lng: 17.905777 },
    { lat: 48.625387, lng: 17.908189 },
    { lat: 48.625103, lng: 17.910096 },
    { lat: 48.625196, lng: 17.911133 },
    { lat: 48.625331, lng: 17.912645 },
    { lat: 48.625362, lng: 17.913176 },
    { lat: 48.625315, lng: 17.914756 },
    { lat: 48.625307, lng: 17.915024 },
    { lat: 48.62539, lng: 17.916412 },
    { lat: 48.625876, lng: 17.917535 },
    { lat: 48.625583, lng: 17.918744 },
    { lat: 48.625489, lng: 17.919077 },
    { lat: 48.625419, lng: 17.919266 },
    { lat: 48.625409, lng: 17.919372 },
    { lat: 48.625361, lng: 17.919618 },
    { lat: 48.62528, lng: 17.920027 },
    { lat: 48.625144, lng: 17.920541 },
    { lat: 48.625194, lng: 17.920983 },
    { lat: 48.62519, lng: 17.921241 },
    { lat: 48.625137, lng: 17.92157 },
    { lat: 48.625141, lng: 17.921875 },
    { lat: 48.625078, lng: 17.922015 },
    { lat: 48.624891, lng: 17.922357 },
    { lat: 48.624813, lng: 17.922655 },
    { lat: 48.624799, lng: 17.922719 },
    { lat: 48.624813, lng: 17.922732 },
    { lat: 48.6255117, lng: 17.9228747 },
    { lat: 48.625582, lng: 17.922889 },
    { lat: 48.6252813, lng: 17.9232271 },
    { lat: 48.625092, lng: 17.92344 },
    { lat: 48.625015, lng: 17.923543 },
    { lat: 48.624833, lng: 17.923954 },
    { lat: 48.624672, lng: 17.924233 },
    { lat: 48.624473, lng: 17.924529 },
    { lat: 48.624593, lng: 17.924808 },
    { lat: 48.624716, lng: 17.92497 },
    { lat: 48.624715, lng: 17.925079 },
    { lat: 48.624868, lng: 17.925266 },
    { lat: 48.624962, lng: 17.925299 },
    { lat: 48.625001, lng: 17.925954 },
    { lat: 48.624854, lng: 17.926036 },
    { lat: 48.624457, lng: 17.925829 },
    { lat: 48.624476, lng: 17.925444 },
    { lat: 48.624395, lng: 17.92528 },
    { lat: 48.624348, lng: 17.924948 },
    { lat: 48.624292, lng: 17.924756 },
    { lat: 48.624053, lng: 17.924676 },
    { lat: 48.624073, lng: 17.924958 },
    { lat: 48.624015, lng: 17.925158 },
    { lat: 48.624012, lng: 17.925468 },
    { lat: 48.623955, lng: 17.925676 },
    { lat: 48.623828, lng: 17.926038 },
    { lat: 48.623763, lng: 17.926421 },
    { lat: 48.623772, lng: 17.926539 },
    { lat: 48.623747, lng: 17.926686 },
    { lat: 48.623709, lng: 17.92698 },
    { lat: 48.623671, lng: 17.9273 },
    { lat: 48.623612, lng: 17.92756 },
    { lat: 48.623369, lng: 17.928035 },
    { lat: 48.623148, lng: 17.928751 },
    { lat: 48.623, lng: 17.929083 },
    { lat: 48.622866, lng: 17.929325 },
    { lat: 48.622835, lng: 17.929453 },
    { lat: 48.622814, lng: 17.929541 },
    { lat: 48.622775, lng: 17.929674 },
    { lat: 48.622705, lng: 17.929922 },
    { lat: 48.622685, lng: 17.930142 },
    { lat: 48.622609, lng: 17.930324 },
    { lat: 48.622529, lng: 17.930446 },
    { lat: 48.622307, lng: 17.930669 },
    { lat: 48.622045, lng: 17.93087 },
    { lat: 48.621785, lng: 17.931199 },
    { lat: 48.621714, lng: 17.931372 },
    { lat: 48.621698, lng: 17.931458 },
    { lat: 48.621585, lng: 17.931674 },
    { lat: 48.621737, lng: 17.931831 },
    { lat: 48.621862, lng: 17.933265 },
    { lat: 48.621756, lng: 17.934278 },
    { lat: 48.622199, lng: 17.934335 },
    { lat: 48.622295, lng: 17.935347 },
    { lat: 48.622303, lng: 17.935414 },
    { lat: 48.622466, lng: 17.937125 },
    { lat: 48.622605, lng: 17.938551 },
    { lat: 48.622569, lng: 17.939223 },
    { lat: 48.622478, lng: 17.93932 },
    { lat: 48.622198, lng: 17.939618 },
    { lat: 48.622056, lng: 17.939711 },
    { lat: 48.621808, lng: 17.939833 },
    { lat: 48.621508, lng: 17.939954 },
    { lat: 48.621379, lng: 17.939951 },
    { lat: 48.621205, lng: 17.939938 },
    { lat: 48.620945, lng: 17.940003 },
    { lat: 48.621051, lng: 17.940425 },
    { lat: 48.62133, lng: 17.942616 },
    { lat: 48.621607, lng: 17.945065 },
    { lat: 48.621526, lng: 17.948059 },
    { lat: 48.621586, lng: 17.94972 },
    { lat: 48.6216395, lng: 17.9502426 },
    { lat: 48.621608, lng: 17.950317 },
    { lat: 48.621681, lng: 17.952253 },
    { lat: 48.621728, lng: 17.954082 },
    { lat: 48.621834, lng: 17.95521 },
    { lat: 48.62184, lng: 17.955698 },
    { lat: 48.621947, lng: 17.956499 },
    { lat: 48.622005, lng: 17.95672 },
    { lat: 48.621946, lng: 17.956867 },
    { lat: 48.621886, lng: 17.956974 },
    { lat: 48.621412, lng: 17.957912 },
    { lat: 48.621363, lng: 17.958104 },
    { lat: 48.62113, lng: 17.958762 },
    { lat: 48.621415, lng: 17.9585428 },
    { lat: 48.6219063, lng: 17.9581703 },
    { lat: 48.62288, lng: 17.9574442 },
    { lat: 48.6235212, lng: 17.9569778 },
    { lat: 48.6242469, lng: 17.9563886 },
    { lat: 48.6253311, lng: 17.955869 },
    { lat: 48.626402, lng: 17.9550915 },
    { lat: 48.6273719, lng: 17.9543223 },
    { lat: 48.6283032, lng: 17.9539283 },
    { lat: 48.6291667, lng: 17.9537777 },
    { lat: 48.629256, lng: 17.9541002 },
    { lat: 48.6297343, lng: 17.9541518 },
    { lat: 48.6298604, lng: 17.954109 },
    { lat: 48.6299838, lng: 17.9538702 },
    { lat: 48.6305755, lng: 17.9535325 },
    { lat: 48.6313609, lng: 17.9522259 },
    { lat: 48.6308491, lng: 17.9517072 },
    { lat: 48.6307422, lng: 17.9510901 },
    { lat: 48.6312726, lng: 17.9507482 },
    { lat: 48.6313121, lng: 17.9505979 },
    { lat: 48.630998, lng: 17.9499894 },
    { lat: 48.6308061, lng: 17.9494425 },
    { lat: 48.630782, lng: 17.9492852 },
    { lat: 48.630731, lng: 17.9491123 },
    { lat: 48.630767, lng: 17.9488084 },
    { lat: 48.6306855, lng: 17.9485411 },
    { lat: 48.6306933, lng: 17.9484259 },
    { lat: 48.6308803, lng: 17.9480018 },
    { lat: 48.6308584, lng: 17.9474856 },
    { lat: 48.6307476, lng: 17.9468595 },
    { lat: 48.6306064, lng: 17.9464452 },
    { lat: 48.6303969, lng: 17.9460191 },
    { lat: 48.6302613, lng: 17.9454681 },
    { lat: 48.6301165, lng: 17.9449343 },
    { lat: 48.6300645, lng: 17.9445557 },
    { lat: 48.6301146, lng: 17.9443565 },
    { lat: 48.6302532, lng: 17.944389 },
    { lat: 48.6304272, lng: 17.9444859 },
    { lat: 48.6305299, lng: 17.9444509 },
    { lat: 48.6306532, lng: 17.944321 },
    { lat: 48.6309539, lng: 17.9441772 },
    { lat: 48.631182, lng: 17.9439335 },
    { lat: 48.6312421, lng: 17.9438618 },
    { lat: 48.6314709, lng: 17.9436897 },
    { lat: 48.6317071, lng: 17.9436928 },
    { lat: 48.6316503, lng: 17.944102 },
    { lat: 48.6315378, lng: 17.9442426 },
    { lat: 48.6316979, lng: 17.9444277 },
    { lat: 48.6316873, lng: 17.9445705 },
    { lat: 48.6316523, lng: 17.9445713 },
    { lat: 48.6315835, lng: 17.9447769 },
    { lat: 48.6317271, lng: 17.9449704 },
    { lat: 48.631774, lng: 17.9452252 },
    { lat: 48.6318788, lng: 17.9453558 },
    { lat: 48.6320968, lng: 17.9449779 },
    { lat: 48.6322545, lng: 17.944844 },
    { lat: 48.6323127, lng: 17.9445709 },
    { lat: 48.6324688, lng: 17.9445946 },
    { lat: 48.632857, lng: 17.9449317 },
    { lat: 48.6330692, lng: 17.9450459 },
    { lat: 48.6331523, lng: 17.9449251 },
    { lat: 48.6334666, lng: 17.9449165 },
    { lat: 48.6333763, lng: 17.943764 },
    { lat: 48.6338421, lng: 17.9440414 },
    { lat: 48.6340794, lng: 17.9439509 },
    { lat: 48.6341228, lng: 17.9437326 },
    { lat: 48.6344647, lng: 17.9436753 },
    { lat: 48.6352083, lng: 17.9434837 },
    { lat: 48.6354153, lng: 17.9427189 },
    { lat: 48.6350061, lng: 17.9413508 },
    { lat: 48.635038, lng: 17.940581 },
    { lat: 48.6353309, lng: 17.9400439 },
    { lat: 48.6357226, lng: 17.9396112 },
    { lat: 48.6356507, lng: 17.9393151 },
    { lat: 48.6356525, lng: 17.93863 },
    { lat: 48.6358065, lng: 17.9370541 },
    { lat: 48.6358577, lng: 17.9361411 },
    { lat: 48.636018, lng: 17.9346713 },
    { lat: 48.6360733, lng: 17.9345014 },
    { lat: 48.6360038, lng: 17.9339056 },
    { lat: 48.6359337, lng: 17.9335655 },
    { lat: 48.6358706, lng: 17.9333956 },
    { lat: 48.6358304, lng: 17.9331039 },
    { lat: 48.635799, lng: 17.9327663 },
    { lat: 48.635729, lng: 17.9323365 },
    { lat: 48.6357024, lng: 17.9320428 },
    { lat: 48.6356434, lng: 17.9314888 },
    { lat: 48.6356226, lng: 17.9311808 },
    { lat: 48.6356383, lng: 17.9310481 },
    { lat: 48.6366407, lng: 17.9303352 },
    { lat: 48.6378386, lng: 17.9294518 },
    { lat: 48.6379914, lng: 17.9295748 },
    { lat: 48.6380879, lng: 17.9292236 },
    { lat: 48.637963, lng: 17.9291018 },
    { lat: 48.637786, lng: 17.9288201 },
    { lat: 48.6377334, lng: 17.9286617 },
    { lat: 48.6376926, lng: 17.928506 },
    { lat: 48.6376005, lng: 17.9279472 },
    { lat: 48.6374274, lng: 17.9274359 },
    { lat: 48.6372815, lng: 17.9271685 },
    { lat: 48.6370664, lng: 17.9269702 },
    { lat: 48.6367726, lng: 17.9267366 },
    { lat: 48.6365098, lng: 17.9265169 },
    { lat: 48.6363444, lng: 17.926279 },
    { lat: 48.6362771, lng: 17.9261151 },
    { lat: 48.6361373, lng: 17.9255557 },
    { lat: 48.6360986, lng: 17.9252488 },
    { lat: 48.6360258, lng: 17.9241513 },
    { lat: 48.6360363, lng: 17.9240028 },
    { lat: 48.636068, lng: 17.9238183 },
    { lat: 48.6361808, lng: 17.9233286 },
    { lat: 48.6362292, lng: 17.9228541 },
    { lat: 48.6362279, lng: 17.9223646 },
    { lat: 48.6362166, lng: 17.9222152 },
    { lat: 48.6369342, lng: 17.9220207 },
    { lat: 48.6370533, lng: 17.9222364 },
    { lat: 48.6370565, lng: 17.9223484 },
    { lat: 48.637105, lng: 17.9225334 },
    { lat: 48.63714, lng: 17.9225882 },
    { lat: 48.6378532, lng: 17.9232281 },
    { lat: 48.6385269, lng: 17.9232595 },
    { lat: 48.6393324, lng: 17.923179 },
    { lat: 48.639748, lng: 17.9237272 },
    { lat: 48.6404605, lng: 17.9239763 },
    { lat: 48.6406159, lng: 17.9240079 },
    { lat: 48.6410955, lng: 17.9241915 },
    { lat: 48.6416611, lng: 17.9244012 },
    { lat: 48.6421694, lng: 17.924578 },
    { lat: 48.6424748, lng: 17.9246804 },
    { lat: 48.6435617, lng: 17.9250359 },
    { lat: 48.6436603, lng: 17.9254918 },
    { lat: 48.6436444, lng: 17.9266982 },
    { lat: 48.6438553, lng: 17.9275011 },
    { lat: 48.6443234, lng: 17.9279654 },
    { lat: 48.6445775, lng: 17.9287599 },
    { lat: 48.6448836, lng: 17.929872 },
    { lat: 48.6449589, lng: 17.9305639 },
    { lat: 48.645691, lng: 17.9302715 },
    { lat: 48.6472837, lng: 17.9317966 },
    { lat: 48.6484613, lng: 17.9329912 },
    { lat: 48.6492813, lng: 17.9336575 },
    { lat: 48.6503375, lng: 17.9334844 },
    { lat: 48.65058, lng: 17.9334227 },
    { lat: 48.6508169, lng: 17.9332701 },
    { lat: 48.6509985, lng: 17.9330611 },
    { lat: 48.6512653, lng: 17.9328426 },
    { lat: 48.6516809, lng: 17.9323389 },
    { lat: 48.6517405, lng: 17.9321994 },
    { lat: 48.651911, lng: 17.9319752 },
    { lat: 48.6522089, lng: 17.9317321 },
    { lat: 48.6522923, lng: 17.9317267 },
    { lat: 48.6525107, lng: 17.9318294 },
    { lat: 48.6527099, lng: 17.9318771 },
    { lat: 48.6527879, lng: 17.9319711 },
    { lat: 48.6530981, lng: 17.9325927 },
    { lat: 48.6534451, lng: 17.9326557 },
    { lat: 48.6539892, lng: 17.9326724 },
    { lat: 48.6539614, lng: 17.9323638 },
    { lat: 48.6539377, lng: 17.9322275 },
    { lat: 48.6538751, lng: 17.9319968 },
    { lat: 48.653807, lng: 17.9318391 },
    { lat: 48.6536927, lng: 17.9317204 },
    { lat: 48.6535906, lng: 17.9315605 },
    { lat: 48.653541, lng: 17.9313167 },
    { lat: 48.6534802, lng: 17.9303961 },
    { lat: 48.6534818, lng: 17.930293 },
    { lat: 48.6534508, lng: 17.9301348 },
    { lat: 48.6533622, lng: 17.9299002 },
    { lat: 48.6531687, lng: 17.9294779 },
    { lat: 48.653069, lng: 17.9291488 },
    { lat: 48.6531184, lng: 17.9286951 },
    { lat: 48.6531251, lng: 17.9284861 },
    { lat: 48.6530375, lng: 17.9281346 },
    { lat: 48.6530856, lng: 17.9273941 },
    { lat: 48.652999, lng: 17.9271735 },
    { lat: 48.652951, lng: 17.9269366 },
    { lat: 48.6529738, lng: 17.9267182 },
    { lat: 48.652883, lng: 17.9261066 },
    { lat: 48.6531707, lng: 17.9257095 },
    { lat: 48.6528567, lng: 17.9250463 },
    { lat: 48.6526774, lng: 17.9235628 },
    { lat: 48.6526362, lng: 17.9233454 },
    { lat: 48.6526024, lng: 17.9230272 },
    { lat: 48.6525316, lng: 17.9227503 },
    { lat: 48.6525541, lng: 17.9224316 },
    { lat: 48.6524883, lng: 17.9221425 },
    { lat: 48.6522805, lng: 17.9216515 },
    { lat: 48.6520019, lng: 17.9212623 },
    { lat: 48.6519341, lng: 17.9211469 },
    { lat: 48.6513622, lng: 17.9204216 },
    { lat: 48.6512367, lng: 17.9200621 },
    { lat: 48.6512317, lng: 17.9199036 },
    { lat: 48.651056, lng: 17.9194942 },
    { lat: 48.6509897, lng: 17.9193112 },
    { lat: 48.6509137, lng: 17.9191928 },
    { lat: 48.6508834, lng: 17.9191016 },
    { lat: 48.6507409, lng: 17.9189069 },
    { lat: 48.6505604, lng: 17.9184218 },
    { lat: 48.6505384, lng: 17.9182056 },
    { lat: 48.6505315, lng: 17.9180156 },
    { lat: 48.6504724, lng: 17.91754 },
    { lat: 48.6504462, lng: 17.9170098 },
    { lat: 48.6506243, lng: 17.9164036 },
    { lat: 48.6507839, lng: 17.9160562 },
    { lat: 48.6508401, lng: 17.9158261 },
    { lat: 48.6508494, lng: 17.9156217 },
    { lat: 48.6508129, lng: 17.9154082 },
    { lat: 48.6507065, lng: 17.9152557 },
    { lat: 48.6505891, lng: 17.9146909 },
    { lat: 48.6508984, lng: 17.9137526 },
    { lat: 48.6509477, lng: 17.9128662 },
    { lat: 48.6509777, lng: 17.9118975 },
    { lat: 48.6508661, lng: 17.910493 },
    { lat: 48.6513459, lng: 17.9100221 },
    { lat: 48.6515849, lng: 17.9096077 },
    { lat: 48.6517222, lng: 17.9092626 },
    { lat: 48.6518308, lng: 17.9089104 },
    { lat: 48.6520196, lng: 17.9084458 },
    { lat: 48.6522091, lng: 17.907543 },
    { lat: 48.6522855, lng: 17.9074055 },
    { lat: 48.6524224, lng: 17.9073521 },
    { lat: 48.6527232, lng: 17.907398 },
    { lat: 48.6530413, lng: 17.9071337 },
    { lat: 48.6534907, lng: 17.9067805 },
    { lat: 48.6536598, lng: 17.9066063 },
    { lat: 48.6537251, lng: 17.9064734 },
    { lat: 48.6537822, lng: 17.9059604 },
    { lat: 48.6538857, lng: 17.9052379 },
    { lat: 48.6538468, lng: 17.9046667 },
    { lat: 48.6538338, lng: 17.9040026 },
    { lat: 48.6539431, lng: 17.9036606 },
    { lat: 48.6540054, lng: 17.9034029 },
    { lat: 48.6539967, lng: 17.9031995 },
    { lat: 48.6538615, lng: 17.9029421 },
    { lat: 48.6534888, lng: 17.902452 },
    { lat: 48.6531367, lng: 17.9017984 },
    { lat: 48.6528445, lng: 17.9015771 },
    { lat: 48.6526907, lng: 17.9013251 },
    { lat: 48.6525173, lng: 17.9011093 },
    { lat: 48.6520839, lng: 17.9004905 },
    { lat: 48.6522587, lng: 17.8999446 },
    { lat: 48.6524238, lng: 17.8989201 },
    { lat: 48.6524595, lng: 17.8986885 },
    { lat: 48.652465, lng: 17.8984025 },
    { lat: 48.6526798, lng: 17.8971935 },
    { lat: 48.6529236, lng: 17.8964624 },
    { lat: 48.65254, lng: 17.896126 },
    { lat: 48.652262, lng: 17.8967407 },
    { lat: 48.6519568, lng: 17.8976158 },
    { lat: 48.6513465, lng: 17.8969284 },
    { lat: 48.6508732, lng: 17.896359 },
    { lat: 48.6505286, lng: 17.8959786 },
    { lat: 48.6502776, lng: 17.8958214 },
    { lat: 48.650305, lng: 17.8953575 },
    { lat: 48.6501014, lng: 17.8944904 },
    { lat: 48.6501728, lng: 17.8941768 },
    { lat: 48.6503066, lng: 17.8933624 },
    { lat: 48.6505308, lng: 17.8927193 },
    { lat: 48.650653, lng: 17.8917883 },
    { lat: 48.6508718, lng: 17.8909985 },
    { lat: 48.6495343, lng: 17.8892259 },
    { lat: 48.6490939, lng: 17.8888845 },
    { lat: 48.6493019, lng: 17.8881966 },
    { lat: 48.6485818, lng: 17.8876537 },
    { lat: 48.6483586, lng: 17.887362 },
    { lat: 48.6481943, lng: 17.887109 },
    { lat: 48.6481829, lng: 17.8868653 },
    { lat: 48.647247, lng: 17.8865805 },
    { lat: 48.6466486, lng: 17.8862601 },
    { lat: 48.6455706, lng: 17.8860941 },
    { lat: 48.6451727, lng: 17.8859918 },
    { lat: 48.6451023, lng: 17.8860411 },
    { lat: 48.644822, lng: 17.8860332 },
    { lat: 48.6437798, lng: 17.8858609 },
    { lat: 48.6427882, lng: 17.8856656 },
    { lat: 48.642823, lng: 17.8854373 },
    { lat: 48.6390532, lng: 17.8832954 },
    { lat: 48.6372282, lng: 17.8817325 },
    { lat: 48.6366329, lng: 17.8812215 },
    { lat: 48.6359465, lng: 17.8806028 },
    { lat: 48.6354991, lng: 17.8802229 },
    { lat: 48.634327, lng: 17.879181 },
  ],
  Hrachovište: [
    { lat: 48.7014511, lng: 17.7364891 },
    { lat: 48.7017683, lng: 17.736905 },
    { lat: 48.702637, lng: 17.7369741 },
    { lat: 48.7031016, lng: 17.7365541 },
    { lat: 48.7035862, lng: 17.7363848 },
    { lat: 48.7046456, lng: 17.7351831 },
    { lat: 48.7057976, lng: 17.7347677 },
    { lat: 48.7079616, lng: 17.7369868 },
    { lat: 48.7098409, lng: 17.7366193 },
    { lat: 48.7127871, lng: 17.7375933 },
    { lat: 48.7133131, lng: 17.7396381 },
    { lat: 48.7138013, lng: 17.7415862 },
    { lat: 48.7133799, lng: 17.7433502 },
    { lat: 48.7139875, lng: 17.7449233 },
    { lat: 48.7137774, lng: 17.746156 },
    { lat: 48.7146042, lng: 17.7475643 },
    { lat: 48.7149409, lng: 17.7470108 },
    { lat: 48.7168124, lng: 17.7475291 },
    { lat: 48.7173754, lng: 17.7494081 },
    { lat: 48.718314, lng: 17.7511634 },
    { lat: 48.7178639, lng: 17.7524647 },
    { lat: 48.7183607, lng: 17.7541135 },
    { lat: 48.7185562, lng: 17.7540002 },
    { lat: 48.7187651, lng: 17.7536527 },
    { lat: 48.7188495, lng: 17.7534256 },
    { lat: 48.7190489, lng: 17.7532009 },
    { lat: 48.7194817, lng: 17.7529092 },
    { lat: 48.7195712, lng: 17.7528613 },
    { lat: 48.7199048, lng: 17.7525767 },
    { lat: 48.7201835, lng: 17.7522605 },
    { lat: 48.7205799, lng: 17.7520236 },
    { lat: 48.7207617, lng: 17.7519519 },
    { lat: 48.7209577, lng: 17.7519613 },
    { lat: 48.721056, lng: 17.751798 },
    { lat: 48.7210961, lng: 17.7516426 },
    { lat: 48.7209847, lng: 17.7511694 },
    { lat: 48.7209216, lng: 17.7508436 },
    { lat: 48.7207158, lng: 17.7501009 },
    { lat: 48.7206577, lng: 17.7495788 },
    { lat: 48.7206616, lng: 17.7491649 },
    { lat: 48.7207757, lng: 17.748688 },
    { lat: 48.7207782, lng: 17.7485446 },
    { lat: 48.7207497, lng: 17.7481928 },
    { lat: 48.7207559, lng: 17.7475963 },
    { lat: 48.7207517, lng: 17.7473448 },
    { lat: 48.7207204, lng: 17.7470345 },
    { lat: 48.7207661, lng: 17.7470278 },
    { lat: 48.7209502, lng: 17.7469172 },
    { lat: 48.721021, lng: 17.7468557 },
    { lat: 48.7210637, lng: 17.7467807 },
    { lat: 48.721246, lng: 17.7467873 },
    { lat: 48.721393, lng: 17.7467731 },
    { lat: 48.7214115, lng: 17.7465778 },
    { lat: 48.7214935, lng: 17.7464075 },
    { lat: 48.7215867, lng: 17.7462882 },
    { lat: 48.7217089, lng: 17.7459439 },
    { lat: 48.7223781, lng: 17.7460144 },
    { lat: 48.7227989, lng: 17.7458058 },
    { lat: 48.7238716, lng: 17.7456977 },
    { lat: 48.7246455, lng: 17.7455217 },
    { lat: 48.7256206, lng: 17.7435156 },
    { lat: 48.7262303, lng: 17.7410393 },
    { lat: 48.726432, lng: 17.7408687 },
    { lat: 48.7265812, lng: 17.7406138 },
    { lat: 48.7266778, lng: 17.7404962 },
    { lat: 48.7271407, lng: 17.7401096 },
    { lat: 48.7273966, lng: 17.7399199 },
    { lat: 48.7275739, lng: 17.7398835 },
    { lat: 48.7277652, lng: 17.7396159 },
    { lat: 48.728009, lng: 17.7391765 },
    { lat: 48.7281096, lng: 17.7390507 },
    { lat: 48.7284214, lng: 17.738611 },
    { lat: 48.7285578, lng: 17.7383715 },
    { lat: 48.7286782, lng: 17.7382166 },
    { lat: 48.7287767, lng: 17.7380189 },
    { lat: 48.7290461, lng: 17.7377375 },
    { lat: 48.7290904, lng: 17.7375953 },
    { lat: 48.7291543, lng: 17.7374576 },
    { lat: 48.7288609, lng: 17.7367815 },
    { lat: 48.7286273, lng: 17.7363069 },
    { lat: 48.7284258, lng: 17.7359898 },
    { lat: 48.7278651, lng: 17.7352057 },
    { lat: 48.7275951, lng: 17.7348164 },
    { lat: 48.7273531, lng: 17.7344098 },
    { lat: 48.7275157, lng: 17.7341542 },
    { lat: 48.7276602, lng: 17.7339146 },
    { lat: 48.7280157, lng: 17.733345 },
    { lat: 48.7281127, lng: 17.7332333 },
    { lat: 48.7286342, lng: 17.7324436 },
    { lat: 48.7289888, lng: 17.731912 },
    { lat: 48.729123, lng: 17.731764 },
    { lat: 48.7291852, lng: 17.7316708 },
    { lat: 48.7291705, lng: 17.7316354 },
    { lat: 48.7293896, lng: 17.7313252 },
    { lat: 48.7294325, lng: 17.73118 },
    { lat: 48.7294074, lng: 17.7309729 },
    { lat: 48.7288655, lng: 17.7299116 },
    { lat: 48.7285465, lng: 17.7296647 },
    { lat: 48.728165, lng: 17.7292056 },
    { lat: 48.7282692, lng: 17.7284789 },
    { lat: 48.7274527, lng: 17.72809 },
    { lat: 48.727004, lng: 17.7278661 },
    { lat: 48.7270514, lng: 17.727353 },
    { lat: 48.7272467, lng: 17.7261071 },
    { lat: 48.7273706, lng: 17.725462 },
    { lat: 48.7274898, lng: 17.725196 },
    { lat: 48.7275765, lng: 17.7246609 },
    { lat: 48.7264309, lng: 17.7243003 },
    { lat: 48.7257855, lng: 17.7242703 },
    { lat: 48.7256948, lng: 17.7238808 },
    { lat: 48.7249061, lng: 17.7241092 },
    { lat: 48.7248608, lng: 17.7236668 },
    { lat: 48.7243404, lng: 17.7237985 },
    { lat: 48.7242034, lng: 17.7233529 },
    { lat: 48.724384, lng: 17.7226913 },
    { lat: 48.7248906, lng: 17.7224507 },
    { lat: 48.7251962, lng: 17.7212337 },
    { lat: 48.7252065, lng: 17.7209976 },
    { lat: 48.7263122, lng: 17.7211336 },
    { lat: 48.7264606, lng: 17.7211427 },
    { lat: 48.726639, lng: 17.7199499 },
    { lat: 48.7266707, lng: 17.7197902 },
    { lat: 48.7251196, lng: 17.7189687 },
    { lat: 48.7238693, lng: 17.7182117 },
    { lat: 48.7235513, lng: 17.7179823 },
    { lat: 48.7233673, lng: 17.7178727 },
    { lat: 48.7231654, lng: 17.7178017 },
    { lat: 48.7233709, lng: 17.7175478 },
    { lat: 48.7236323, lng: 17.7171369 },
    { lat: 48.7239203, lng: 17.7168621 },
    { lat: 48.7241072, lng: 17.7165049 },
    { lat: 48.7242182, lng: 17.7163495 },
    { lat: 48.7244424, lng: 17.7161022 },
    { lat: 48.7245489, lng: 17.7161451 },
    { lat: 48.7246331, lng: 17.7162294 },
    { lat: 48.7244037, lng: 17.7165175 },
    { lat: 48.7246028, lng: 17.7166539 },
    { lat: 48.725165, lng: 17.7162138 },
    { lat: 48.7253427, lng: 17.7166772 },
    { lat: 48.7265434, lng: 17.7159542 },
    { lat: 48.726544, lng: 17.71589 },
    { lat: 48.726518, lng: 17.7158 },
    { lat: 48.726247, lng: 17.714798 },
    { lat: 48.726446, lng: 17.714639 },
    { lat: 48.726527, lng: 17.714363 },
    { lat: 48.72667, lng: 17.714014 },
    { lat: 48.727209, lng: 17.713474 },
    { lat: 48.726939, lng: 17.712852 },
    { lat: 48.7269, lng: 17.712765 },
    { lat: 48.727138, lng: 17.712551 },
    { lat: 48.727355, lng: 17.712312 },
    { lat: 48.728229, lng: 17.711434 },
    { lat: 48.728667, lng: 17.710965 },
    { lat: 48.728587, lng: 17.710624 },
    { lat: 48.728326, lng: 17.709642 },
    { lat: 48.72822, lng: 17.709201 },
    { lat: 48.727537, lng: 17.709713 },
    { lat: 48.727389, lng: 17.709322 },
    { lat: 48.727361, lng: 17.709246 },
    { lat: 48.727236, lng: 17.708915 },
    { lat: 48.726704, lng: 17.7092 },
    { lat: 48.7266474, lng: 17.7090881 },
    { lat: 48.726607, lng: 17.709008 },
    { lat: 48.726567, lng: 17.70893 },
    { lat: 48.726515, lng: 17.70883 },
    { lat: 48.726201, lng: 17.709021 },
    { lat: 48.726121, lng: 17.708807 },
    { lat: 48.726125, lng: 17.708761 },
    { lat: 48.726145, lng: 17.708542 },
    { lat: 48.726116, lng: 17.708444 },
    { lat: 48.72605, lng: 17.708223 },
    { lat: 48.726052, lng: 17.7078 },
    { lat: 48.726135, lng: 17.707717 },
    { lat: 48.726558, lng: 17.707369 },
    { lat: 48.726386, lng: 17.706914 },
    { lat: 48.726122, lng: 17.7071 },
    { lat: 48.726023, lng: 17.706922 },
    { lat: 48.725903, lng: 17.706772 },
    { lat: 48.725749, lng: 17.706401 },
    { lat: 48.726124, lng: 17.70574 },
    { lat: 48.726446, lng: 17.705135 },
    { lat: 48.726456, lng: 17.705116 },
    { lat: 48.725976, lng: 17.704477 },
    { lat: 48.725695, lng: 17.704177 },
    { lat: 48.725684, lng: 17.704203 },
    { lat: 48.725457, lng: 17.704662 },
    { lat: 48.725049, lng: 17.705393 },
    { lat: 48.724679, lng: 17.70508 },
    { lat: 48.724494, lng: 17.704896 },
    { lat: 48.724309, lng: 17.704715 },
    { lat: 48.724215, lng: 17.704527 },
    { lat: 48.724061, lng: 17.704484 },
    { lat: 48.723747, lng: 17.70428 },
    { lat: 48.723592, lng: 17.704181 },
    { lat: 48.723447, lng: 17.704139 },
    { lat: 48.723338, lng: 17.704066 },
    { lat: 48.7231, lng: 17.703968 },
    { lat: 48.722923, lng: 17.703854 },
    { lat: 48.722669, lng: 17.703579 },
    { lat: 48.722576, lng: 17.703533 },
    { lat: 48.722242, lng: 17.703398 },
    { lat: 48.722094, lng: 17.703384 },
    { lat: 48.722014, lng: 17.703355 },
    { lat: 48.721756, lng: 17.703415 },
    { lat: 48.721661, lng: 17.703508 },
    { lat: 48.721483, lng: 17.703682 },
    { lat: 48.721266, lng: 17.703991 },
    { lat: 48.721053, lng: 17.704485 },
    { lat: 48.72096, lng: 17.704792 },
    { lat: 48.72088, lng: 17.705016 },
    { lat: 48.72071, lng: 17.704704 },
    { lat: 48.720597, lng: 17.704771 },
    { lat: 48.72047, lng: 17.7047 },
    { lat: 48.720337, lng: 17.704905 },
    { lat: 48.720074, lng: 17.704533 },
    { lat: 48.71978, lng: 17.70407 },
    { lat: 48.719659, lng: 17.703729 },
    { lat: 48.719357, lng: 17.702881 },
    { lat: 48.719226, lng: 17.702512 },
    { lat: 48.718902, lng: 17.701766 },
    { lat: 48.718747, lng: 17.70132 },
    { lat: 48.718734, lng: 17.701331 },
    { lat: 48.717713, lng: 17.702295 },
    { lat: 48.7173, lng: 17.701351 },
    { lat: 48.717026, lng: 17.701637 },
    { lat: 48.716807, lng: 17.701142 },
    { lat: 48.716692, lng: 17.701004 },
    { lat: 48.71661, lng: 17.701073 },
    { lat: 48.716529, lng: 17.701262 },
    { lat: 48.716188, lng: 17.701504 },
    { lat: 48.716031, lng: 17.701632 },
    { lat: 48.715778, lng: 17.701796 },
    { lat: 48.715622, lng: 17.701929 },
    { lat: 48.715441, lng: 17.702048 },
    { lat: 48.715228, lng: 17.701197 },
    { lat: 48.714318, lng: 17.70199 },
    { lat: 48.71438, lng: 17.702301 },
    { lat: 48.7144, lng: 17.702594 },
    { lat: 48.714447, lng: 17.70289 },
    { lat: 48.713374, lng: 17.703555 },
    { lat: 48.713043, lng: 17.70379 },
    { lat: 48.712651, lng: 17.704127 },
    { lat: 48.711909, lng: 17.704821 },
    { lat: 48.7115, lng: 17.705256 },
    { lat: 48.71159, lng: 17.705483 },
    { lat: 48.711018, lng: 17.706064 },
    { lat: 48.710506, lng: 17.70654 },
    { lat: 48.710222, lng: 17.706763 },
    { lat: 48.70989, lng: 17.707022 },
    { lat: 48.709809, lng: 17.70705 },
    { lat: 48.709754, lng: 17.706828 },
    { lat: 48.709597, lng: 17.706847 },
    { lat: 48.70935, lng: 17.706861 },
    { lat: 48.709078, lng: 17.706851 },
    { lat: 48.708802, lng: 17.706916 },
    { lat: 48.708665, lng: 17.706989 },
    { lat: 48.708787, lng: 17.707221 },
    { lat: 48.709097, lng: 17.707197 },
    { lat: 48.708903, lng: 17.70741 },
    { lat: 48.70892, lng: 17.707461 },
    { lat: 48.709132, lng: 17.707453 },
    { lat: 48.708891, lng: 17.707879 },
    { lat: 48.708632, lng: 17.708227 },
    { lat: 48.708473, lng: 17.708012 },
    { lat: 48.708388, lng: 17.707757 },
    { lat: 48.708337, lng: 17.707474 },
    { lat: 48.708297, lng: 17.707066 },
    { lat: 48.708178, lng: 17.70635 },
    { lat: 48.708081, lng: 17.706213 },
    { lat: 48.707859, lng: 17.70632 },
    { lat: 48.707726, lng: 17.706469 },
    { lat: 48.707655, lng: 17.706652 },
    { lat: 48.707645, lng: 17.706904 },
    { lat: 48.707775, lng: 17.70728 },
    { lat: 48.707587, lng: 17.707631 },
    { lat: 48.707533, lng: 17.707715 },
    { lat: 48.707353, lng: 17.707702 },
    { lat: 48.707231, lng: 17.707843 },
    { lat: 48.707145, lng: 17.708078 },
    { lat: 48.707111, lng: 17.708242 },
    { lat: 48.707011, lng: 17.708383 },
    { lat: 48.706584, lng: 17.708491 },
    { lat: 48.706511, lng: 17.708547 },
    { lat: 48.706283, lng: 17.708564 },
    { lat: 48.706221, lng: 17.70843 },
    { lat: 48.706229, lng: 17.708374 },
    { lat: 48.70618, lng: 17.708289 },
    { lat: 48.706079, lng: 17.708185 },
    { lat: 48.705993, lng: 17.708028 },
    { lat: 48.705799, lng: 17.707504 },
    { lat: 48.705781, lng: 17.707458 },
    { lat: 48.705712, lng: 17.707403 },
    { lat: 48.705635, lng: 17.707375 },
    { lat: 48.705545, lng: 17.707432 },
    { lat: 48.705188, lng: 17.707784 },
    { lat: 48.70507, lng: 17.708022 },
    { lat: 48.704973, lng: 17.708274 },
    { lat: 48.704805, lng: 17.708681 },
    { lat: 48.704651, lng: 17.708972 },
    { lat: 48.704439, lng: 17.709253 },
    { lat: 48.704244, lng: 17.709472 },
    { lat: 48.703947, lng: 17.70984 },
    { lat: 48.703796, lng: 17.709985 },
    { lat: 48.703567, lng: 17.710251 },
    { lat: 48.703333, lng: 17.710549 },
    { lat: 48.703358, lng: 17.710609 },
    { lat: 48.703363, lng: 17.710619 },
    { lat: 48.703274, lng: 17.710662 },
    { lat: 48.702874, lng: 17.710967 },
    { lat: 48.702777, lng: 17.711018 },
    { lat: 48.702754, lng: 17.71103 },
    { lat: 48.702672, lng: 17.710809 },
    { lat: 48.702335, lng: 17.710171 },
    { lat: 48.702227, lng: 17.709923 },
    { lat: 48.70211, lng: 17.709574 },
    { lat: 48.702083, lng: 17.709591 },
    { lat: 48.702039, lng: 17.70962 },
    { lat: 48.702005, lng: 17.709643 },
    { lat: 48.701991, lng: 17.709652 },
    { lat: 48.701971, lng: 17.709411 },
    { lat: 48.701928, lng: 17.709226 },
    { lat: 48.701865, lng: 17.70869 },
    { lat: 48.701782, lng: 17.708424 },
    { lat: 48.701774, lng: 17.708188 },
    { lat: 48.701559, lng: 17.707304 },
    { lat: 48.701539, lng: 17.707016 },
    { lat: 48.701244, lng: 17.706715 },
    { lat: 48.701086, lng: 17.706411 },
    { lat: 48.700879, lng: 17.706123 },
    { lat: 48.700805, lng: 17.706024 },
    { lat: 48.700723, lng: 17.705651 },
    { lat: 48.70066, lng: 17.705515 },
    { lat: 48.700596, lng: 17.705551 },
    { lat: 48.700108, lng: 17.705821 },
    { lat: 48.700156, lng: 17.706337 },
    { lat: 48.700234, lng: 17.706536 },
    { lat: 48.700107, lng: 17.706742 },
    { lat: 48.700164, lng: 17.706937 },
    { lat: 48.70037, lng: 17.706924 },
    { lat: 48.700475, lng: 17.706935 },
    { lat: 48.700601, lng: 17.707032 },
    { lat: 48.700461, lng: 17.707643 },
    { lat: 48.70057, lng: 17.707786 },
    { lat: 48.700775, lng: 17.7074 },
    { lat: 48.701161, lng: 17.707843 },
    { lat: 48.701274, lng: 17.708129 },
    { lat: 48.701323, lng: 17.708535 },
    { lat: 48.700907, lng: 17.708776 },
    { lat: 48.700933, lng: 17.709038 },
    { lat: 48.700901, lng: 17.709235 },
    { lat: 48.699829, lng: 17.709615 },
    { lat: 48.699148, lng: 17.709797 },
    { lat: 48.698938, lng: 17.709287 },
    { lat: 48.698796, lng: 17.708888 },
    { lat: 48.698682, lng: 17.708486 },
    { lat: 48.698597, lng: 17.708236 },
    { lat: 48.698537, lng: 17.7081 },
    { lat: 48.698461, lng: 17.707924 },
    { lat: 48.698338, lng: 17.707378 },
    { lat: 48.698258, lng: 17.707159 },
    { lat: 48.697757, lng: 17.706618 },
    { lat: 48.697485, lng: 17.706426 },
    { lat: 48.696957, lng: 17.70856 },
    { lat: 48.696908, lng: 17.708709 },
    { lat: 48.696541, lng: 17.708575 },
    { lat: 48.696323, lng: 17.708519 },
    { lat: 48.696282, lng: 17.708751 },
    { lat: 48.696228, lng: 17.708918 },
    { lat: 48.696172, lng: 17.709178 },
    { lat: 48.695983, lng: 17.709257 },
    { lat: 48.695899, lng: 17.709274 },
    { lat: 48.69579, lng: 17.709352 },
    { lat: 48.69568, lng: 17.70934 },
    { lat: 48.695668, lng: 17.709363 },
    { lat: 48.695607, lng: 17.709481 },
    { lat: 48.695138, lng: 17.710403 },
    { lat: 48.694748, lng: 17.710813 },
    { lat: 48.694356, lng: 17.711273 },
    { lat: 48.694345, lng: 17.711287 },
    { lat: 48.694241, lng: 17.711374 },
    { lat: 48.693986, lng: 17.711673 },
    { lat: 48.693835, lng: 17.711951 },
    { lat: 48.693702, lng: 17.712216 },
    { lat: 48.693359, lng: 17.712761 },
    { lat: 48.693146, lng: 17.713239 },
    { lat: 48.693055, lng: 17.713594 },
    { lat: 48.692993, lng: 17.713834 },
    { lat: 48.692774, lng: 17.714549 },
    { lat: 48.692451, lng: 17.715215 },
    { lat: 48.69244, lng: 17.715236 },
    { lat: 48.692189, lng: 17.715685 },
    { lat: 48.691777, lng: 17.716248 },
    { lat: 48.691226, lng: 17.717373 },
    { lat: 48.69101, lng: 17.717887 },
    { lat: 48.690745, lng: 17.718786 },
    { lat: 48.690364, lng: 17.720081 },
    { lat: 48.6904023, lng: 17.7201343 },
    { lat: 48.6903892, lng: 17.7201608 },
    { lat: 48.6906419, lng: 17.7204518 },
    { lat: 48.6908491, lng: 17.7207143 },
    { lat: 48.6910148, lng: 17.7208053 },
    { lat: 48.6913965, lng: 17.7211696 },
    { lat: 48.6916957, lng: 17.7214251 },
    { lat: 48.6919518, lng: 17.7215874 },
    { lat: 48.6924849, lng: 17.7218461 },
    { lat: 48.6927494, lng: 17.7220529 },
    { lat: 48.6929062, lng: 17.7223035 },
    { lat: 48.6932107, lng: 17.7225919 },
    { lat: 48.6933843, lng: 17.7227172 },
    { lat: 48.693655, lng: 17.7231708 },
    { lat: 48.6937639, lng: 17.7232702 },
    { lat: 48.6940978, lng: 17.7233371 },
    { lat: 48.6943669, lng: 17.7235258 },
    { lat: 48.694614, lng: 17.723724 },
    { lat: 48.6947865, lng: 17.7241218 },
    { lat: 48.6949682, lng: 17.7245254 },
    { lat: 48.6950636, lng: 17.7247911 },
    { lat: 48.6953346, lng: 17.7252483 },
    { lat: 48.6954383, lng: 17.7256293 },
    { lat: 48.6954368, lng: 17.7258622 },
    { lat: 48.6954844, lng: 17.7260463 },
    { lat: 48.6955847, lng: 17.7262262 },
    { lat: 48.6957438, lng: 17.7264079 },
    { lat: 48.6959422, lng: 17.7265326 },
    { lat: 48.6962411, lng: 17.7266921 },
    { lat: 48.6965247, lng: 17.7269867 },
    { lat: 48.697121, lng: 17.7277244 },
    { lat: 48.6974442, lng: 17.728607 },
    { lat: 48.6975268, lng: 17.7294886 },
    { lat: 48.6973312, lng: 17.7311476 },
    { lat: 48.6975198, lng: 17.7318087 },
    { lat: 48.6978911, lng: 17.7325771 },
    { lat: 48.6986047, lng: 17.7331622 },
    { lat: 48.6996457, lng: 17.7330726 },
    { lat: 48.7002174, lng: 17.7335733 },
    { lat: 48.7006303, lng: 17.7341354 },
    { lat: 48.7008064, lng: 17.7347936 },
    { lat: 48.7012485, lng: 17.7353169 },
    { lat: 48.7012506, lng: 17.7357934 },
    { lat: 48.7012812, lng: 17.7359058 },
    { lat: 48.7014511, lng: 17.7364891 },
  ],
  Pobedim: [
    { lat: 48.638546, lng: 17.782409 },
    { lat: 48.638543, lng: 17.782483 },
    { lat: 48.638628, lng: 17.783411 },
    { lat: 48.638608, lng: 17.784011 },
    { lat: 48.63854, lng: 17.78451 },
    { lat: 48.638498, lng: 17.78514 },
    { lat: 48.638684, lng: 17.785184 },
    { lat: 48.63858, lng: 17.785455 },
    { lat: 48.63852, lng: 17.785519 },
    { lat: 48.638384, lng: 17.785598 },
    { lat: 48.638129, lng: 17.785757 },
    { lat: 48.638114, lng: 17.786271 },
    { lat: 48.638112, lng: 17.786347 },
    { lat: 48.638014, lng: 17.786539 },
    { lat: 48.637799, lng: 17.786915 },
    { lat: 48.637636, lng: 17.787116 },
    { lat: 48.637533, lng: 17.787245 },
    { lat: 48.637527, lng: 17.787224 },
    { lat: 48.63735, lng: 17.787377 },
    { lat: 48.637287, lng: 17.787432 },
    { lat: 48.637227, lng: 17.787515 },
    { lat: 48.637189, lng: 17.787618 },
    { lat: 48.637182, lng: 17.787778 },
    { lat: 48.637137, lng: 17.787877 },
    { lat: 48.637003, lng: 17.787979 },
    { lat: 48.636912, lng: 17.788025 },
    { lat: 48.63682, lng: 17.788077 },
    { lat: 48.63675, lng: 17.788222 },
    { lat: 48.636732, lng: 17.788315 },
    { lat: 48.636732, lng: 17.788426 },
    { lat: 48.636763, lng: 17.788714 },
    { lat: 48.637002, lng: 17.789028 },
    { lat: 48.637098, lng: 17.789069 },
    { lat: 48.637211, lng: 17.789084 },
    { lat: 48.637411, lng: 17.789121 },
    { lat: 48.637424, lng: 17.789137 },
    { lat: 48.637545, lng: 17.789294 },
    { lat: 48.637607, lng: 17.789549 },
    { lat: 48.637783, lng: 17.789583 },
    { lat: 48.637712, lng: 17.789992 },
    { lat: 48.637528, lng: 17.790745 },
    { lat: 48.637567, lng: 17.790778 },
    { lat: 48.638034, lng: 17.791061 },
    { lat: 48.63816, lng: 17.791055 },
    { lat: 48.638352, lng: 17.790896 },
    { lat: 48.638548, lng: 17.790745 },
    { lat: 48.638812, lng: 17.791197 },
    { lat: 48.638965, lng: 17.791551 },
    { lat: 48.639126, lng: 17.791712 },
    { lat: 48.639203, lng: 17.791738 },
    { lat: 48.639328, lng: 17.79235 },
    { lat: 48.639414, lng: 17.792672 },
    { lat: 48.639415, lng: 17.792774 },
    { lat: 48.639398, lng: 17.792881 },
    { lat: 48.639399, lng: 17.7929 },
    { lat: 48.639339, lng: 17.793061 },
    { lat: 48.639182, lng: 17.793257 },
    { lat: 48.639052, lng: 17.793381 },
    { lat: 48.638982, lng: 17.793573 },
    { lat: 48.639002, lng: 17.79373 },
    { lat: 48.639182, lng: 17.793777 },
    { lat: 48.639287, lng: 17.793853 },
    { lat: 48.63932, lng: 17.793946 },
    { lat: 48.639316, lng: 17.794068 },
    { lat: 48.639301, lng: 17.794231 },
    { lat: 48.639318, lng: 17.794345 },
    { lat: 48.63936, lng: 17.79441 },
    { lat: 48.639458, lng: 17.794423 },
    { lat: 48.639535, lng: 17.794476 },
    { lat: 48.639549, lng: 17.794749 },
    { lat: 48.639592, lng: 17.794782 },
    { lat: 48.639649, lng: 17.794756 },
    { lat: 48.639679, lng: 17.794719 },
    { lat: 48.639739, lng: 17.794735 },
    { lat: 48.639803, lng: 17.794837 },
    { lat: 48.639799, lng: 17.794866 },
    { lat: 48.639837, lng: 17.795047 },
    { lat: 48.639915, lng: 17.79524 },
    { lat: 48.639978, lng: 17.795633 },
    { lat: 48.640076, lng: 17.796074 },
    { lat: 48.640126, lng: 17.79631 },
    { lat: 48.640159, lng: 17.796462 },
    { lat: 48.640244, lng: 17.796919 },
    { lat: 48.640248, lng: 17.796954 },
    { lat: 48.640256, lng: 17.797009 },
    { lat: 48.640266, lng: 17.79708 },
    { lat: 48.640272, lng: 17.797129 },
    { lat: 48.640364, lng: 17.797809 },
    { lat: 48.640411, lng: 17.798456 },
    { lat: 48.640395, lng: 17.79849 },
    { lat: 48.640402, lng: 17.798558 },
    { lat: 48.640091, lng: 17.798703 },
    { lat: 48.639729, lng: 17.798791 },
    { lat: 48.639302, lng: 17.798889 },
    { lat: 48.639051, lng: 17.798781 },
    { lat: 48.638931, lng: 17.798701 },
    { lat: 48.638806, lng: 17.798687 },
    { lat: 48.638639, lng: 17.798637 },
    { lat: 48.638448, lng: 17.798553 },
    { lat: 48.637931, lng: 17.798424 },
    { lat: 48.637769, lng: 17.798424 },
    { lat: 48.637752, lng: 17.79857 },
    { lat: 48.637696, lng: 17.799286 },
    { lat: 48.637681, lng: 17.799578 },
    { lat: 48.637651, lng: 17.799806 },
    { lat: 48.637592, lng: 17.80049 },
    { lat: 48.637487, lng: 17.801408 },
    { lat: 48.637484, lng: 17.801435 },
    { lat: 48.637479, lng: 17.801483 },
    { lat: 48.63745, lng: 17.801772 },
    { lat: 48.637414, lng: 17.802204 },
    { lat: 48.637399, lng: 17.802451 },
    { lat: 48.637362, lng: 17.802713 },
    { lat: 48.637351, lng: 17.802994 },
    { lat: 48.637342, lng: 17.803368 },
    { lat: 48.637266, lng: 17.803615 },
    { lat: 48.637236, lng: 17.803761 },
    { lat: 48.637211, lng: 17.803883 },
    { lat: 48.637106, lng: 17.804305 },
    { lat: 48.63709, lng: 17.804418 },
    { lat: 48.637141, lng: 17.804707 },
    { lat: 48.63719, lng: 17.804891 },
    { lat: 48.637219, lng: 17.805057 },
    { lat: 48.63723, lng: 17.805261 },
    { lat: 48.637245, lng: 17.805596 },
    { lat: 48.637641, lng: 17.805762 },
    { lat: 48.637636, lng: 17.805872 },
    { lat: 48.637601, lng: 17.806841 },
    { lat: 48.63779, lng: 17.806902 },
    { lat: 48.638371, lng: 17.80709 },
    { lat: 48.638457, lng: 17.807118 },
    { lat: 48.638506, lng: 17.80721 },
    { lat: 48.638574, lng: 17.807381 },
    { lat: 48.638605, lng: 17.807868 },
    { lat: 48.638634, lng: 17.80815 },
    { lat: 48.638683, lng: 17.808343 },
    { lat: 48.638736, lng: 17.808438 },
    { lat: 48.638736, lng: 17.808486 },
    { lat: 48.63896, lng: 17.80862 },
    { lat: 48.639185, lng: 17.808642 },
    { lat: 48.639578, lng: 17.808598 },
    { lat: 48.639751, lng: 17.808595 },
    { lat: 48.639972, lng: 17.808601 },
    { lat: 48.640055, lng: 17.808647 },
    { lat: 48.640056, lng: 17.808594 },
    { lat: 48.6401, lng: 17.80861 },
    { lat: 48.640038, lng: 17.809904 },
    { lat: 48.63998, lng: 17.810557 },
    { lat: 48.639897, lng: 17.811435 },
    { lat: 48.639842, lng: 17.811947 },
    { lat: 48.639748, lng: 17.812995 },
    { lat: 48.639687, lng: 17.81365 },
    { lat: 48.639659, lng: 17.814098 },
    { lat: 48.639639, lng: 17.814549 },
    { lat: 48.639636, lng: 17.814622 },
    { lat: 48.639632, lng: 17.814693 },
    { lat: 48.639603, lng: 17.815108 },
    { lat: 48.639599, lng: 17.815194 },
    { lat: 48.6395, lng: 17.81673 },
    { lat: 48.63943, lng: 17.818174 },
    { lat: 48.639358, lng: 17.8199 },
    { lat: 48.639368, lng: 17.820472 },
    { lat: 48.639815, lng: 17.820779 },
    { lat: 48.640282, lng: 17.821257 },
    { lat: 48.640527, lng: 17.821458 },
    { lat: 48.64066, lng: 17.821598 },
    { lat: 48.640823, lng: 17.821783 },
    { lat: 48.641142, lng: 17.822207 },
    { lat: 48.641336, lng: 17.822361 },
    { lat: 48.641631, lng: 17.822413 },
    { lat: 48.641748, lng: 17.822408 },
    { lat: 48.64194, lng: 17.822294 },
    { lat: 48.642543, lng: 17.822032 },
    { lat: 48.642715, lng: 17.822025 },
    { lat: 48.643311, lng: 17.82256 },
    { lat: 48.6437, lng: 17.822548 },
    { lat: 48.644023, lng: 17.822556 },
    { lat: 48.644273, lng: 17.822479 },
    { lat: 48.644719, lng: 17.82246 },
    { lat: 48.644782, lng: 17.822462 },
    { lat: 48.644853, lng: 17.822477 },
    { lat: 48.645017, lng: 17.822512 },
    { lat: 48.64521, lng: 17.822553 },
    { lat: 48.645247, lng: 17.822561 },
    { lat: 48.6455, lng: 17.822931 },
    { lat: 48.645787, lng: 17.823379 },
    { lat: 48.645904, lng: 17.823563 },
    { lat: 48.645926, lng: 17.823581 },
    { lat: 48.646216, lng: 17.823821 },
    { lat: 48.646271, lng: 17.823866 },
    { lat: 48.646423, lng: 17.82404 },
    { lat: 48.64656, lng: 17.824123 },
    { lat: 48.646634, lng: 17.824147 },
    { lat: 48.646709, lng: 17.824095 },
    { lat: 48.646914, lng: 17.823839 },
    { lat: 48.646927, lng: 17.823592 },
    { lat: 48.646874, lng: 17.823437 },
    { lat: 48.646807, lng: 17.823299 },
    { lat: 48.64676, lng: 17.823118 },
    { lat: 48.64678, lng: 17.822864 },
    { lat: 48.646823, lng: 17.822751 },
    { lat: 48.646967, lng: 17.822576 },
    { lat: 48.647101, lng: 17.822537 },
    { lat: 48.64722, lng: 17.82261 },
    { lat: 48.647526, lng: 17.823014 },
    { lat: 48.647685, lng: 17.823082 },
    { lat: 48.647907, lng: 17.823125 },
    { lat: 48.647981, lng: 17.823124 },
    { lat: 48.648093, lng: 17.823084 },
    { lat: 48.648288, lng: 17.82293 },
    { lat: 48.648294, lng: 17.822925 },
    { lat: 48.648554, lng: 17.822503 },
    { lat: 48.648609, lng: 17.822412 },
    { lat: 48.648714, lng: 17.822306 },
    { lat: 48.648894, lng: 17.82222 },
    { lat: 48.64893, lng: 17.822235 },
    { lat: 48.648985, lng: 17.822259 },
    { lat: 48.649071, lng: 17.822337 },
    { lat: 48.649112, lng: 17.82246 },
    { lat: 48.649111, lng: 17.822653 },
    { lat: 48.649089, lng: 17.822698 },
    { lat: 48.649054, lng: 17.822771 },
    { lat: 48.648966, lng: 17.822902 },
    { lat: 48.648941, lng: 17.823124 },
    { lat: 48.649048, lng: 17.823294 },
    { lat: 48.649107, lng: 17.82335 },
    { lat: 48.649165, lng: 17.823393 },
    { lat: 48.649274, lng: 17.823449 },
    { lat: 48.649337, lng: 17.823482 },
    { lat: 48.649438, lng: 17.823491 },
    { lat: 48.649549, lng: 17.82336 },
    { lat: 48.649566, lng: 17.822872 },
    { lat: 48.649573, lng: 17.822684 },
    { lat: 48.649625, lng: 17.822562 },
    { lat: 48.649691, lng: 17.822094 },
    { lat: 48.649898, lng: 17.82094 },
    { lat: 48.649949, lng: 17.820838 },
    { lat: 48.650041, lng: 17.820827 },
    { lat: 48.65027, lng: 17.820797 },
    { lat: 48.650482, lng: 17.82088 },
    { lat: 48.650721, lng: 17.821184 },
    { lat: 48.650771, lng: 17.821503 },
    { lat: 48.650758, lng: 17.821729 },
    { lat: 48.650699, lng: 17.821843 },
    { lat: 48.650668, lng: 17.821837 },
    { lat: 48.650549, lng: 17.822261 },
    { lat: 48.650505, lng: 17.822513 },
    { lat: 48.650508, lng: 17.82266 },
    { lat: 48.650524, lng: 17.822708 },
    { lat: 48.650554, lng: 17.822792 },
    { lat: 48.650605, lng: 17.822842 },
    { lat: 48.650845, lng: 17.822898 },
    { lat: 48.651492, lng: 17.823353 },
    { lat: 48.65169, lng: 17.823298 },
    { lat: 48.651761, lng: 17.823225 },
    { lat: 48.65182, lng: 17.823096 },
    { lat: 48.651835, lng: 17.822983 },
    { lat: 48.65185, lng: 17.822684 },
    { lat: 48.651762, lng: 17.822472 },
    { lat: 48.651683, lng: 17.822191 },
    { lat: 48.65166, lng: 17.821758 },
    { lat: 48.651668, lng: 17.82113 },
    { lat: 48.65156, lng: 17.820872 },
    { lat: 48.651576, lng: 17.820686 },
    { lat: 48.651668, lng: 17.820488 },
    { lat: 48.651769, lng: 17.820461 },
    { lat: 48.651858, lng: 17.820515 },
    { lat: 48.651928, lng: 17.820587 },
    { lat: 48.651979, lng: 17.820709 },
    { lat: 48.652021, lng: 17.820835 },
    { lat: 48.652089, lng: 17.821135 },
    { lat: 48.652168, lng: 17.821274 },
    { lat: 48.652192, lng: 17.821316 },
    { lat: 48.652172, lng: 17.821347 },
    { lat: 48.652214, lng: 17.821386 },
    { lat: 48.652276, lng: 17.821443 },
    { lat: 48.652335, lng: 17.821462 },
    { lat: 48.652737, lng: 17.821453 },
    { lat: 48.652868, lng: 17.821463 },
    { lat: 48.652949, lng: 17.821559 },
    { lat: 48.653042, lng: 17.821713 },
    { lat: 48.653059, lng: 17.821905 },
    { lat: 48.653018, lng: 17.822236 },
    { lat: 48.653104, lng: 17.823004 },
    { lat: 48.653208, lng: 17.823183 },
    { lat: 48.653278, lng: 17.823337 },
    { lat: 48.6534544, lng: 17.823452 },
    { lat: 48.6535384, lng: 17.8235689 },
    { lat: 48.6535778, lng: 17.8236798 },
    { lat: 48.6536149, lng: 17.8241013 },
    { lat: 48.653649, lng: 17.8241805 },
    { lat: 48.6536892, lng: 17.8242264 },
    { lat: 48.6538253, lng: 17.8242939 },
    { lat: 48.6539396, lng: 17.8242445 },
    { lat: 48.6539772, lng: 17.8241932 },
    { lat: 48.6540659, lng: 17.8240384 },
    { lat: 48.6541103, lng: 17.8239123 },
    { lat: 48.65423, lng: 17.8236175 },
    { lat: 48.6543359, lng: 17.8235025 },
    { lat: 48.6547938, lng: 17.8234004 },
    { lat: 48.6549021, lng: 17.8233258 },
    { lat: 48.6550446, lng: 17.8229429 },
    { lat: 48.655093, lng: 17.8228473 },
    { lat: 48.6551396, lng: 17.8228133 },
    { lat: 48.6551874, lng: 17.8228017 },
    { lat: 48.6553688, lng: 17.8228312 },
    { lat: 48.655672, lng: 17.8233822 },
    { lat: 48.6556784, lng: 17.8234141 },
    { lat: 48.6557845, lng: 17.8236908 },
    { lat: 48.6558625, lng: 17.8239509 },
    { lat: 48.6559399, lng: 17.8241102 },
    { lat: 48.6562379, lng: 17.8245559 },
    { lat: 48.6562762, lng: 17.8245916 },
    { lat: 48.6563269, lng: 17.8246078 },
    { lat: 48.6563581, lng: 17.8246003 },
    { lat: 48.6563913, lng: 17.8246078 },
    { lat: 48.6564763, lng: 17.8245789 },
    { lat: 48.6566434, lng: 17.8244482 },
    { lat: 48.6568487, lng: 17.8242769 },
    { lat: 48.6571301, lng: 17.8240218 },
    { lat: 48.6571988, lng: 17.8239852 },
    { lat: 48.6573597, lng: 17.8238657 },
    { lat: 48.6574888, lng: 17.8237971 },
    { lat: 48.657579, lng: 17.8237753 },
    { lat: 48.65774, lng: 17.8237501 },
    { lat: 48.6578804, lng: 17.8237956 },
    { lat: 48.6579152, lng: 17.8237915 },
    { lat: 48.6579847, lng: 17.8238922 },
    { lat: 48.6581755, lng: 17.8242285 },
    { lat: 48.6581897, lng: 17.8242401 },
    { lat: 48.6582223, lng: 17.8242899 },
    { lat: 48.6581837, lng: 17.8252529 },
    { lat: 48.6580937, lng: 17.8256671 },
    { lat: 48.6581271, lng: 17.826232 },
    { lat: 48.6582082, lng: 17.826305 },
    { lat: 48.6583265, lng: 17.826374 },
    { lat: 48.6584772, lng: 17.8264027 },
    { lat: 48.6586258, lng: 17.826341 },
    { lat: 48.6588088, lng: 17.8263425 },
    { lat: 48.6588457, lng: 17.8263352 },
    { lat: 48.6589978, lng: 17.8263652 },
    { lat: 48.6593343, lng: 17.8264074 },
    { lat: 48.659509, lng: 17.8264392 },
    { lat: 48.6596628, lng: 17.8263865 },
    { lat: 48.6597804, lng: 17.8263355 },
    { lat: 48.6598991, lng: 17.8262645 },
    { lat: 48.6602478, lng: 17.8259072 },
    { lat: 48.6603354, lng: 17.8258661 },
    { lat: 48.6604492, lng: 17.8259707 },
    { lat: 48.6605446, lng: 17.8261114 },
    { lat: 48.6606509, lng: 17.8263191 },
    { lat: 48.6607302, lng: 17.8265073 },
    { lat: 48.660913, lng: 17.8268791 },
    { lat: 48.6609767, lng: 17.8271831 },
    { lat: 48.6609716, lng: 17.8272856 },
    { lat: 48.6609969, lng: 17.8277563 },
    { lat: 48.6610624, lng: 17.8279236 },
    { lat: 48.6611612, lng: 17.8280606 },
    { lat: 48.6613773, lng: 17.8281642 },
    { lat: 48.6615113, lng: 17.8281781 },
    { lat: 48.6617392, lng: 17.8281754 },
    { lat: 48.661857, lng: 17.828218 },
    { lat: 48.6618524, lng: 17.828625 },
    { lat: 48.6618995, lng: 17.8286756 },
    { lat: 48.662151, lng: 17.8288193 },
    { lat: 48.6622205, lng: 17.8288468 },
    { lat: 48.6622725, lng: 17.8288503 },
    { lat: 48.6624345, lng: 17.8287822 },
    { lat: 48.6626197, lng: 17.8287119 },
    { lat: 48.6626282, lng: 17.8287039 },
    { lat: 48.6626793, lng: 17.8282804 },
    { lat: 48.662773, lng: 17.8279121 },
    { lat: 48.662825, lng: 17.8278174 },
    { lat: 48.6628817, lng: 17.8277845 },
    { lat: 48.6629592, lng: 17.8277959 },
    { lat: 48.6630379, lng: 17.8278293 },
    { lat: 48.663192, lng: 17.8279861 },
    { lat: 48.6633075, lng: 17.8280655 },
    { lat: 48.6633647, lng: 17.8280797 },
    { lat: 48.6634726, lng: 17.828014 },
    { lat: 48.6635593, lng: 17.8278988 },
    { lat: 48.6636116, lng: 17.8276669 },
    { lat: 48.6636358, lng: 17.8275232 },
    { lat: 48.6637111, lng: 17.8274085 },
    { lat: 48.6637879, lng: 17.8273182 },
    { lat: 48.6638267, lng: 17.8273064 },
    { lat: 48.6639489, lng: 17.827308 },
    { lat: 48.6641856, lng: 17.8273416 },
    { lat: 48.6642827, lng: 17.8273785 },
    { lat: 48.6644062, lng: 17.8274606 },
    { lat: 48.6645304, lng: 17.8275891 },
    { lat: 48.6645671, lng: 17.8276558 },
    { lat: 48.6646603, lng: 17.8277568 },
    { lat: 48.6647737, lng: 17.8278972 },
    { lat: 48.6648161, lng: 17.8279613 },
    { lat: 48.6650244, lng: 17.8281763 },
    { lat: 48.6651422, lng: 17.8283132 },
    { lat: 48.6652721, lng: 17.8284069 },
    { lat: 48.6653701, lng: 17.8285116 },
    { lat: 48.6654737, lng: 17.8286008 },
    { lat: 48.6655781, lng: 17.8286495 },
    { lat: 48.6658727, lng: 17.8287004 },
    { lat: 48.6661288, lng: 17.8287381 },
    { lat: 48.6662327, lng: 17.8287741 },
    { lat: 48.6663402, lng: 17.8288349 },
    { lat: 48.6664493, lng: 17.8289601 },
    { lat: 48.6665197, lng: 17.8291872 },
    { lat: 48.6665146, lng: 17.8295657 },
    { lat: 48.6663721, lng: 17.8300822 },
    { lat: 48.6663745, lng: 17.8303619 },
    { lat: 48.6664893, lng: 17.8307106 },
    { lat: 48.666563, lng: 17.8309579 },
    { lat: 48.6666469, lng: 17.8312048 },
    { lat: 48.6667037, lng: 17.8313216 },
    { lat: 48.6667406, lng: 17.8313526 },
    { lat: 48.6668731, lng: 17.8313967 },
    { lat: 48.6670112, lng: 17.8314233 },
    { lat: 48.6671471, lng: 17.8314284 },
    { lat: 48.6673413, lng: 17.8314129 },
    { lat: 48.66753, lng: 17.8313564 },
    { lat: 48.6676247, lng: 17.83128 },
    { lat: 48.6680094, lng: 17.8307556 },
    { lat: 48.6681067, lng: 17.8304075 },
    { lat: 48.6681421, lng: 17.8300633 },
    { lat: 48.6681434, lng: 17.8298045 },
    { lat: 48.668057, lng: 17.8293137 },
    { lat: 48.6676971, lng: 17.8285732 },
    { lat: 48.6676102, lng: 17.8283345 },
    { lat: 48.6676074, lng: 17.8281949 },
    { lat: 48.667642, lng: 17.8281337 },
    { lat: 48.6677206, lng: 17.8280699 },
    { lat: 48.6678676, lng: 17.8280935 },
    { lat: 48.6679243, lng: 17.8281156 },
    { lat: 48.6680913, lng: 17.8282053 },
    { lat: 48.6681666, lng: 17.8282381 },
    { lat: 48.6682403, lng: 17.8282975 },
    { lat: 48.6682984, lng: 17.8282884 },
    { lat: 48.6684739, lng: 17.8283544 },
    { lat: 48.6685934, lng: 17.828445 },
    { lat: 48.6686753, lng: 17.8292344 },
    { lat: 48.6686636, lng: 17.8299338 },
    { lat: 48.6686958, lng: 17.830069 },
    { lat: 48.66887, lng: 17.830577 },
    { lat: 48.6689636, lng: 17.8307619 },
    { lat: 48.6690591, lng: 17.8308848 },
    { lat: 48.6691542, lng: 17.8309248 },
    { lat: 48.6692415, lng: 17.8309323 },
    { lat: 48.6693249, lng: 17.8308183 },
    { lat: 48.6694025, lng: 17.8305539 },
    { lat: 48.669569, lng: 17.8302844 },
    { lat: 48.6700068, lng: 17.829948 },
    { lat: 48.6702477, lng: 17.8296935 },
    { lat: 48.6702767, lng: 17.8295198 },
    { lat: 48.6703513, lng: 17.8287787 },
    { lat: 48.670373, lng: 17.8286932 },
    { lat: 48.6705911, lng: 17.8282687 },
    { lat: 48.6706389, lng: 17.8281838 },
    { lat: 48.6707241, lng: 17.8279702 },
    { lat: 48.6708336, lng: 17.8277629 },
    { lat: 48.67093, lng: 17.8276806 },
    { lat: 48.6710466, lng: 17.8274972 },
    { lat: 48.6710599, lng: 17.8274936 },
    { lat: 48.6711197, lng: 17.8275121 },
    { lat: 48.6714097, lng: 17.8277148 },
    { lat: 48.6715181, lng: 17.8278815 },
    { lat: 48.671538, lng: 17.8280024 },
    { lat: 48.6715531, lng: 17.8281781 },
    { lat: 48.6715768, lng: 17.8283649 },
    { lat: 48.6716448, lng: 17.8286272 },
    { lat: 48.6717924, lng: 17.8289218 },
    { lat: 48.6720201, lng: 17.8291924 },
    { lat: 48.6722679, lng: 17.8294095 },
    { lat: 48.6728423, lng: 17.8297367 },
    { lat: 48.6734492, lng: 17.8294886 },
    { lat: 48.6735639, lng: 17.8292394 },
    { lat: 48.6737427, lng: 17.8290057 },
    { lat: 48.6739654, lng: 17.8288976 },
    { lat: 48.6742372, lng: 17.8289685 },
    { lat: 48.6743194, lng: 17.8293004 },
    { lat: 48.6743889, lng: 17.8290707 },
    { lat: 48.6748012, lng: 17.825901 },
    { lat: 48.6750353, lng: 17.8255184 },
    { lat: 48.6753275, lng: 17.8249196 },
    { lat: 48.6754244, lng: 17.8244514 },
    { lat: 48.6754283, lng: 17.8243137 },
    { lat: 48.675463, lng: 17.823881 },
    { lat: 48.6754212, lng: 17.8236771 },
    { lat: 48.6753117, lng: 17.8234362 },
    { lat: 48.6751939, lng: 17.8233189 },
    { lat: 48.6747384, lng: 17.8224595 },
    { lat: 48.674742, lng: 17.8224397 },
    { lat: 48.6747017, lng: 17.8223735 },
    { lat: 48.6746161, lng: 17.8222847 },
    { lat: 48.6744681, lng: 17.8223035 },
    { lat: 48.6744493, lng: 17.8222363 },
    { lat: 48.6743111, lng: 17.8224542 },
    { lat: 48.674206, lng: 17.8225626 },
    { lat: 48.6740567, lng: 17.8224497 },
    { lat: 48.6740398, lng: 17.8221349 },
    { lat: 48.6739005, lng: 17.8217788 },
    { lat: 48.6740563, lng: 17.8215904 },
    { lat: 48.6739108, lng: 17.8213538 },
    { lat: 48.6736811, lng: 17.8209401 },
    { lat: 48.6734841, lng: 17.8207493 },
    { lat: 48.6731122, lng: 17.8207097 },
    { lat: 48.6727398, lng: 17.8207362 },
    { lat: 48.6722473, lng: 17.8205512 },
    { lat: 48.6719524, lng: 17.8206443 },
    { lat: 48.6717973, lng: 17.8207153 },
    { lat: 48.6717291, lng: 17.820746 },
    { lat: 48.6716764, lng: 17.8207699 },
    { lat: 48.6715412, lng: 17.820741 },
    { lat: 48.6713656, lng: 17.820622 },
    { lat: 48.6713072, lng: 17.8204838 },
    { lat: 48.6712004, lng: 17.8203614 },
    { lat: 48.6711286, lng: 17.8202389 },
    { lat: 48.6710181, lng: 17.8201696 },
    { lat: 48.6707645, lng: 17.8202309 },
    { lat: 48.6706876, lng: 17.8204866 },
    { lat: 48.6706511, lng: 17.8205366 },
    { lat: 48.6705657, lng: 17.8205799 },
    { lat: 48.6703572, lng: 17.8205671 },
    { lat: 48.6702235, lng: 17.8206349 },
    { lat: 48.670153, lng: 17.8206429 },
    { lat: 48.6700561, lng: 17.8205749 },
    { lat: 48.6698965, lng: 17.8204007 },
    { lat: 48.6696145, lng: 17.8201798 },
    { lat: 48.669526, lng: 17.8200824 },
    { lat: 48.6694297, lng: 17.8200583 },
    { lat: 48.6693598, lng: 17.8200569 },
    { lat: 48.6692326, lng: 17.8201203 },
    { lat: 48.6690807, lng: 17.8202946 },
    { lat: 48.6690074, lng: 17.8203887 },
    { lat: 48.6688801, lng: 17.8204696 },
    { lat: 48.6685991, lng: 17.820524 },
    { lat: 48.6685389, lng: 17.8205581 },
    { lat: 48.6683952, lng: 17.8207001 },
    { lat: 48.6683159, lng: 17.8207294 },
    { lat: 48.6682039, lng: 17.8206899 },
    { lat: 48.6679522, lng: 17.8205424 },
    { lat: 48.667739, lng: 17.8204902 },
    { lat: 48.6676665, lng: 17.8204838 },
    { lat: 48.667621, lng: 17.820463 },
    { lat: 48.667529, lng: 17.8204532 },
    { lat: 48.6675112, lng: 17.8204007 },
    { lat: 48.6675821, lng: 17.8198248 },
    { lat: 48.6676445, lng: 17.8193658 },
    { lat: 48.6677439, lng: 17.8183645 },
    { lat: 48.66787, lng: 17.817413 },
    { lat: 48.667947, lng: 17.8164573 },
    { lat: 48.6680068, lng: 17.8158075 },
    { lat: 48.668049, lng: 17.8155342 },
    { lat: 48.6681231, lng: 17.8148974 },
    { lat: 48.6681754, lng: 17.8144977 },
    { lat: 48.668258, lng: 17.813909 },
    { lat: 48.6683474, lng: 17.8131723 },
    { lat: 48.6684461, lng: 17.8122725 },
    { lat: 48.6685342, lng: 17.8114938 },
    { lat: 48.6685685, lng: 17.8111136 },
    { lat: 48.6685935, lng: 17.8104876 },
    { lat: 48.6686157, lng: 17.8096848 },
    { lat: 48.6686458, lng: 17.8089567 },
    { lat: 48.6687534, lng: 17.8077974 },
    { lat: 48.6688352, lng: 17.8070278 },
    { lat: 48.6689127, lng: 17.8064834 },
    { lat: 48.6687195, lng: 17.8062063 },
    { lat: 48.6682734, lng: 17.8055169 },
    { lat: 48.6680437, lng: 17.8052492 },
    { lat: 48.6678618, lng: 17.8050795 },
    { lat: 48.6677035, lng: 17.8049473 },
    { lat: 48.667408, lng: 17.8047291 },
    { lat: 48.6672566, lng: 17.8045641 },
    { lat: 48.667174, lng: 17.804447 },
    { lat: 48.6669917, lng: 17.8042548 },
    { lat: 48.6668111, lng: 17.8040128 },
    { lat: 48.6666342, lng: 17.8034831 },
    { lat: 48.6665691, lng: 17.8032294 },
    { lat: 48.6665427, lng: 17.803038 },
    { lat: 48.666419, lng: 17.8029188 },
    { lat: 48.666194, lng: 17.8027633 },
    { lat: 48.6660285, lng: 17.8026792 },
    { lat: 48.665466, lng: 17.8024511 },
    { lat: 48.6654231, lng: 17.802396 },
    { lat: 48.6654549, lng: 17.8023623 },
    { lat: 48.6651456, lng: 17.8018653 },
    { lat: 48.6650793, lng: 17.801721 },
    { lat: 48.6649251, lng: 17.8013218 },
    { lat: 48.6648143, lng: 17.8009886 },
    { lat: 48.6647699, lng: 17.8008151 },
    { lat: 48.6647023, lng: 17.8004674 },
    { lat: 48.6646708, lng: 17.8004475 },
    { lat: 48.6647645, lng: 17.8000795 },
    { lat: 48.6648691, lng: 17.7996317 },
    { lat: 48.664528, lng: 17.7994803 },
    { lat: 48.6643091, lng: 17.7993704 },
    { lat: 48.6640525, lng: 17.7990862 },
    { lat: 48.6634518, lng: 17.7982713 },
    { lat: 48.6633199, lng: 17.7983514 },
    { lat: 48.6632147, lng: 17.7983832 },
    { lat: 48.6631026, lng: 17.7982685 },
    { lat: 48.6631142, lng: 17.7982533 },
    { lat: 48.6630646, lng: 17.7981613 },
    { lat: 48.6629959, lng: 17.7980901 },
    { lat: 48.662827, lng: 17.797969 },
    { lat: 48.6626321, lng: 17.7978651 },
    { lat: 48.6625313, lng: 17.7977684 },
    { lat: 48.6625123, lng: 17.7977711 },
    { lat: 48.6625029, lng: 17.7976531 },
    { lat: 48.6625399, lng: 17.7971887 },
    { lat: 48.6624675, lng: 17.7971852 },
    { lat: 48.662369, lng: 17.7970864 },
    { lat: 48.6621986, lng: 17.7970698 },
    { lat: 48.6620686, lng: 17.7970679 },
    { lat: 48.6619532, lng: 17.7970458 },
    { lat: 48.6617691, lng: 17.7968982 },
    { lat: 48.6616371, lng: 17.7966584 },
    { lat: 48.6614313, lng: 17.7964496 },
    { lat: 48.6612528, lng: 17.7963198 },
    { lat: 48.661037, lng: 17.7962031 },
    { lat: 48.6608167, lng: 17.7958695 },
    { lat: 48.6606948, lng: 17.7953733 },
    { lat: 48.66065, lng: 17.7951546 },
    { lat: 48.6605957, lng: 17.7950243 },
    { lat: 48.6604805, lng: 17.794875 },
    { lat: 48.6603542, lng: 17.7948244 },
    { lat: 48.6603681, lng: 17.7946457 },
    { lat: 48.6603907, lng: 17.7940908 },
    { lat: 48.6603952, lng: 17.7936463 },
    { lat: 48.660311, lng: 17.7930656 },
    { lat: 48.6602827, lng: 17.7926378 },
    { lat: 48.6602928, lng: 17.7925436 },
    { lat: 48.6603158, lng: 17.7924586 },
    { lat: 48.6604564, lng: 17.7921197 },
    { lat: 48.6605562, lng: 17.7918684 },
    { lat: 48.6603298, lng: 17.7911753 },
    { lat: 48.6600573, lng: 17.7910026 },
    { lat: 48.6598377, lng: 17.7909361 },
    { lat: 48.6596519, lng: 17.7907417 },
    { lat: 48.6595318, lng: 17.7904749 },
    { lat: 48.6593999, lng: 17.7902947 },
    { lat: 48.6593203, lng: 17.7902126 },
    { lat: 48.6591185, lng: 17.7901425 },
    { lat: 48.6590623, lng: 17.7901293 },
    { lat: 48.6587842, lng: 17.7899377 },
    { lat: 48.658737, lng: 17.7897572 },
    { lat: 48.6587282, lng: 17.7896485 },
    { lat: 48.6586745, lng: 17.7895099 },
    { lat: 48.6585988, lng: 17.7894165 },
    { lat: 48.658471, lng: 17.7893972 },
    { lat: 48.6584092, lng: 17.7894383 },
    { lat: 48.6581269, lng: 17.789858 },
    { lat: 48.6581083, lng: 17.7899798 },
    { lat: 48.6581241, lng: 17.7901644 },
    { lat: 48.6581089, lng: 17.7903422 },
    { lat: 48.6580396, lng: 17.7905008 },
    { lat: 48.6579301, lng: 17.7905193 },
    { lat: 48.6576723, lng: 17.7904934 },
    { lat: 48.6575135, lng: 17.7904281 },
    { lat: 48.6574258, lng: 17.7903203 },
    { lat: 48.6573403, lng: 17.7901797 },
    { lat: 48.6573018, lng: 17.7901383 },
    { lat: 48.6571807, lng: 17.7900613 },
    { lat: 48.657095, lng: 17.7900254 },
    { lat: 48.657043, lng: 17.7899869 },
    { lat: 48.6569419, lng: 17.7898307 },
    { lat: 48.6569043, lng: 17.7898042 },
    { lat: 48.6568298, lng: 17.7897849 },
    { lat: 48.6567913, lng: 17.7897867 },
    { lat: 48.6566493, lng: 17.7898622 },
    { lat: 48.6565954, lng: 17.7900343 },
    { lat: 48.6564896, lng: 17.7904458 },
    { lat: 48.6564854, lng: 17.7904314 },
    { lat: 48.6564372, lng: 17.7904171 },
    { lat: 48.6563386, lng: 17.7903412 },
    { lat: 48.6563202, lng: 17.7903334 },
    { lat: 48.6561314, lng: 17.7901833 },
    { lat: 48.6560526, lng: 17.7901286 },
    { lat: 48.6559368, lng: 17.7900644 },
    { lat: 48.6559003, lng: 17.7900595 },
    { lat: 48.6558672, lng: 17.7900337 },
    { lat: 48.6557325, lng: 17.7903796 },
    { lat: 48.6556327, lng: 17.7904067 },
    { lat: 48.6554332, lng: 17.790321 },
    { lat: 48.6553036, lng: 17.7902141 },
    { lat: 48.6552095, lng: 17.7901711 },
    { lat: 48.6550561, lng: 17.7901743 },
    { lat: 48.6548536, lng: 17.7901672 },
    { lat: 48.6546555, lng: 17.7902112 },
    { lat: 48.6545238, lng: 17.7901467 },
    { lat: 48.6544004, lng: 17.7901211 },
    { lat: 48.6542897, lng: 17.7901565 },
    { lat: 48.6541897, lng: 17.7904047 },
    { lat: 48.6540021, lng: 17.7904405 },
    { lat: 48.6539234, lng: 17.7904993 },
    { lat: 48.6538711, lng: 17.7905841 },
    { lat: 48.6538281, lng: 17.7907129 },
    { lat: 48.6537925, lng: 17.7910896 },
    { lat: 48.6537414, lng: 17.7911585 },
    { lat: 48.6536114, lng: 17.7911576 },
    { lat: 48.6534569, lng: 17.7911792 },
    { lat: 48.6533548, lng: 17.7912441 },
    { lat: 48.6532515, lng: 17.7912917 },
    { lat: 48.6531892, lng: 17.7912854 },
    { lat: 48.6531376, lng: 17.7912544 },
    { lat: 48.6530702, lng: 17.7911317 },
    { lat: 48.6529926, lng: 17.7909532 },
    { lat: 48.652915, lng: 17.7908445 },
    { lat: 48.6528703, lng: 17.7908178 },
    { lat: 48.6528135, lng: 17.7908097 },
    { lat: 48.652692, lng: 17.790762 },
    { lat: 48.6526472, lng: 17.790675 },
    { lat: 48.6522871, lng: 17.7901604 },
    { lat: 48.6520608, lng: 17.7900023 },
    { lat: 48.6518345, lng: 17.7897579 },
    { lat: 48.6517861, lng: 17.7891676 },
    { lat: 48.6517807, lng: 17.7891334 },
    { lat: 48.6514496, lng: 17.7889643 },
    { lat: 48.6513561, lng: 17.7888216 },
    { lat: 48.651218, lng: 17.7885931 },
    { lat: 48.651188, lng: 17.788583 },
    { lat: 48.6510653, lng: 17.7886222 },
    { lat: 48.651019, lng: 17.7886619 },
    { lat: 48.6509081, lng: 17.7886559 },
    { lat: 48.6507215, lng: 17.7885602 },
    { lat: 48.6504143, lng: 17.7900697 },
    { lat: 48.6502621, lng: 17.790057 },
    { lat: 48.6500973, lng: 17.7900539 },
    { lat: 48.6499774, lng: 17.7909557 },
    { lat: 48.6499045, lng: 17.7909827 },
    { lat: 48.6497835, lng: 17.7909796 },
    { lat: 48.6495627, lng: 17.7907822 },
    { lat: 48.6494768, lng: 17.7907274 },
    { lat: 48.649453, lng: 17.7907257 },
    { lat: 48.6493925, lng: 17.7907688 },
    { lat: 48.6491869, lng: 17.7907881 },
    { lat: 48.6490619, lng: 17.7907745 },
    { lat: 48.6490022, lng: 17.7907571 },
    { lat: 48.6489099, lng: 17.7906682 },
    { lat: 48.6487906, lng: 17.7904157 },
    { lat: 48.6486569, lng: 17.790299 },
    { lat: 48.6485699, lng: 17.7902768 },
    { lat: 48.6484322, lng: 17.7902232 },
    { lat: 48.6483667, lng: 17.7901302 },
    { lat: 48.6482304, lng: 17.790045 },
    { lat: 48.6481095, lng: 17.7899319 },
    { lat: 48.6480668, lng: 17.7898803 },
    { lat: 48.6480671, lng: 17.7898121 },
    { lat: 48.648099, lng: 17.7895013 },
    { lat: 48.6482365, lng: 17.7892212 },
    { lat: 48.6481739, lng: 17.7891563 },
    { lat: 48.6481575, lng: 17.7891476 },
    { lat: 48.6481318, lng: 17.789098 },
    { lat: 48.6477992, lng: 17.7890676 },
    { lat: 48.6476352, lng: 17.788878 },
    { lat: 48.6475509, lng: 17.7887376 },
    { lat: 48.6472482, lng: 17.7887499 },
    { lat: 48.6470662, lng: 17.7889498 },
    { lat: 48.6470086, lng: 17.7889868 },
    { lat: 48.6469186, lng: 17.788974 },
    { lat: 48.6468492, lng: 17.7891307 },
    { lat: 48.6467837, lng: 17.7892437 },
    { lat: 48.6467072, lng: 17.7892458 },
    { lat: 48.6465922, lng: 17.7892112 },
    { lat: 48.6464543, lng: 17.7888791 },
    { lat: 48.6464107, lng: 17.7886995 },
    { lat: 48.6463285, lng: 17.7885939 },
    { lat: 48.6461583, lng: 17.788523 },
    { lat: 48.6460663, lng: 17.7884762 },
    { lat: 48.6458765, lng: 17.7885145 },
    { lat: 48.6458064, lng: 17.7884353 },
    { lat: 48.6456933, lng: 17.788249 },
    { lat: 48.6456524, lng: 17.7878745 },
    { lat: 48.6455768, lng: 17.7877108 },
    { lat: 48.6454715, lng: 17.7875602 },
    { lat: 48.6455249, lng: 17.7873971 },
    { lat: 48.6456913, lng: 17.7867606 },
    { lat: 48.6457374, lng: 17.7864243 },
    { lat: 48.6457516, lng: 17.7861414 },
    { lat: 48.6457549, lng: 17.785972 },
    { lat: 48.6457018, lng: 17.7856767 },
    { lat: 48.6456358, lng: 17.7852086 },
    { lat: 48.6456019, lng: 17.784988 },
    { lat: 48.6455242, lng: 17.7845291 },
    { lat: 48.6454749, lng: 17.7842768 },
    { lat: 48.6453734, lng: 17.7839453 },
    { lat: 48.6453343, lng: 17.7838433 },
    { lat: 48.6452001, lng: 17.7834408 },
    { lat: 48.6451953, lng: 17.7833629 },
    { lat: 48.6451922, lng: 17.7832734 },
    { lat: 48.6450879, lng: 17.7829723 },
    { lat: 48.644909, lng: 17.7826321 },
    { lat: 48.6447373, lng: 17.7823155 },
    { lat: 48.6443938, lng: 17.7818872 },
    { lat: 48.6442776, lng: 17.7817588 },
    { lat: 48.6440003, lng: 17.7815847 },
    { lat: 48.6437645, lng: 17.7814016 },
    { lat: 48.6435955, lng: 17.7812417 },
    { lat: 48.6433841, lng: 17.7810538 },
    { lat: 48.6432224, lng: 17.7809197 },
    { lat: 48.6427553, lng: 17.7817184 },
    { lat: 48.6422898, lng: 17.7812668 },
    { lat: 48.6419506, lng: 17.7809472 },
    { lat: 48.6418476, lng: 17.7812557 },
    { lat: 48.6417374, lng: 17.7816117 },
    { lat: 48.6415889, lng: 17.7820647 },
    { lat: 48.6414251, lng: 17.7820233 },
    { lat: 48.641276, lng: 17.7818217 },
    { lat: 48.6412084, lng: 17.7817112 },
    { lat: 48.6408414, lng: 17.7815443 },
    { lat: 48.640598, lng: 17.781468 },
    { lat: 48.640414, lng: 17.781494 },
    { lat: 48.640248, lng: 17.781847 },
    { lat: 48.640025, lng: 17.782179 },
    { lat: 48.639847, lng: 17.782326 },
    { lat: 48.639679, lng: 17.782402 },
    { lat: 48.639572, lng: 17.782422 },
    { lat: 48.639425, lng: 17.782332 },
    { lat: 48.639254, lng: 17.782294 },
    { lat: 48.639138, lng: 17.78227 },
    { lat: 48.639033, lng: 17.782281 },
    { lat: 48.638825, lng: 17.782303 },
    { lat: 48.638711, lng: 17.782316 },
    { lat: 48.638546, lng: 17.782409 },
  ],
  Modrovka: [
    { lat: 48.6513412, lng: 17.8677283 },
    { lat: 48.6511741, lng: 17.8678045 },
    { lat: 48.6508147, lng: 17.8680222 },
    { lat: 48.6507086, lng: 17.8681139 },
    { lat: 48.6501172, lng: 17.8685085 },
    { lat: 48.6498734, lng: 17.8686392 },
    { lat: 48.6495173, lng: 17.8687982 },
    { lat: 48.6494428, lng: 17.8688378 },
    { lat: 48.6488668, lng: 17.8690364 },
    { lat: 48.6488624, lng: 17.8690188 },
    { lat: 48.6481945, lng: 17.8691776 },
    { lat: 48.6476715, lng: 17.8692832 },
    { lat: 48.6474307, lng: 17.8692957 },
    { lat: 48.6470886, lng: 17.8693492 },
    { lat: 48.6469346, lng: 17.8694897 },
    { lat: 48.6465971, lng: 17.8698071 },
    { lat: 48.6465085, lng: 17.8698361 },
    { lat: 48.6463928, lng: 17.869922 },
    { lat: 48.6459528, lng: 17.8701879 },
    { lat: 48.645741, lng: 17.8702681 },
    { lat: 48.6453571, lng: 17.8703235 },
    { lat: 48.6451163, lng: 17.870281 },
    { lat: 48.6449729, lng: 17.8700734 },
    { lat: 48.6449169, lng: 17.8698783 },
    { lat: 48.644802, lng: 17.8696015 },
    { lat: 48.6447842, lng: 17.8694758 },
    { lat: 48.6447066, lng: 17.869163 },
    { lat: 48.6445958, lng: 17.8686718 },
    { lat: 48.6445645, lng: 17.8684176 },
    { lat: 48.6445235, lng: 17.8681295 },
    { lat: 48.6445237, lng: 17.8679853 },
    { lat: 48.6445031, lng: 17.8676647 },
    { lat: 48.6444431, lng: 17.8674749 },
    { lat: 48.6443487, lng: 17.8672933 },
    { lat: 48.6443335, lng: 17.8671173 },
    { lat: 48.6442805, lng: 17.8668044 },
    { lat: 48.6442277, lng: 17.8663223 },
    { lat: 48.6441748, lng: 17.8662493 },
    { lat: 48.6441656, lng: 17.8661524 },
    { lat: 48.6441683, lng: 17.86594 },
    { lat: 48.6441667, lng: 17.8655761 },
    { lat: 48.6441331, lng: 17.8653187 },
    { lat: 48.644094, lng: 17.8651578 },
    { lat: 48.6440736, lng: 17.8650074 },
    { lat: 48.6435608, lng: 17.8653026 },
    { lat: 48.6435453, lng: 17.8652302 },
    { lat: 48.6434266, lng: 17.8649681 },
    { lat: 48.643256, lng: 17.8646665 },
    { lat: 48.6431131, lng: 17.8644509 },
    { lat: 48.6429655, lng: 17.8642489 },
    { lat: 48.6428452, lng: 17.8639421 },
    { lat: 48.6427057, lng: 17.8635016 },
    { lat: 48.6425036, lng: 17.8630154 },
    { lat: 48.6422122, lng: 17.8622857 },
    { lat: 48.6420189, lng: 17.8618365 },
    { lat: 48.6418212, lng: 17.8614779 },
    { lat: 48.6417346, lng: 17.8613374 },
    { lat: 48.6416136, lng: 17.8611632 },
    { lat: 48.6408658, lng: 17.8602804 },
    { lat: 48.6404796, lng: 17.8599604 },
    { lat: 48.6403404, lng: 17.8599171 },
    { lat: 48.6401502, lng: 17.8597606 },
    { lat: 48.639716, lng: 17.8594503 },
    { lat: 48.6398074, lng: 17.8592247 },
    { lat: 48.6391984, lng: 17.8588093 },
    { lat: 48.6384214, lng: 17.858319 },
    { lat: 48.6377356, lng: 17.8581977 },
    { lat: 48.637255, lng: 17.858072 },
    { lat: 48.637532, lng: 17.858475 },
    { lat: 48.638086, lng: 17.861415 },
    { lat: 48.638166, lng: 17.861836 },
    { lat: 48.638419, lng: 17.863179 },
    { lat: 48.638429, lng: 17.863225 },
    { lat: 48.638435, lng: 17.863262 },
    { lat: 48.638446, lng: 17.86331 },
    { lat: 48.638459, lng: 17.863372 },
    { lat: 48.638467, lng: 17.863413 },
    { lat: 48.63865, lng: 17.864345 },
    { lat: 48.638799, lng: 17.865167 },
    { lat: 48.638037, lng: 17.865564 },
    { lat: 48.63777, lng: 17.865752 },
    { lat: 48.637733, lng: 17.865779 },
    { lat: 48.637686, lng: 17.865807 },
    { lat: 48.637617, lng: 17.865895 },
    { lat: 48.637553, lng: 17.866077 },
    { lat: 48.637443, lng: 17.866543 },
    { lat: 48.637346, lng: 17.868207 },
    { lat: 48.637342, lng: 17.86836 },
    { lat: 48.637283, lng: 17.869349 },
    { lat: 48.63726, lng: 17.869809 },
    { lat: 48.637241, lng: 17.870221 },
    { lat: 48.637218, lng: 17.870621 },
    { lat: 48.637209, lng: 17.870842 },
    { lat: 48.637249, lng: 17.870981 },
    { lat: 48.637332, lng: 17.871092 },
    { lat: 48.637382, lng: 17.871129 },
    { lat: 48.637425, lng: 17.871171 },
    { lat: 48.63745, lng: 17.871251 },
    { lat: 48.63752, lng: 17.871475 },
    { lat: 48.637631, lng: 17.871646 },
    { lat: 48.637683, lng: 17.871768 },
    { lat: 48.637686, lng: 17.871927 },
    { lat: 48.637549, lng: 17.872242 },
    { lat: 48.637174, lng: 17.872865 },
    { lat: 48.636639, lng: 17.873655 },
    { lat: 48.636466, lng: 17.873853 },
    { lat: 48.636155, lng: 17.874327 },
    { lat: 48.636137, lng: 17.874358 },
    { lat: 48.63596, lng: 17.874609 },
    { lat: 48.635706, lng: 17.874905 },
    { lat: 48.634807, lng: 17.875814 },
    { lat: 48.634705, lng: 17.875929 },
    { lat: 48.634608, lng: 17.876188 },
    { lat: 48.634467, lng: 17.876933 },
    { lat: 48.634418, lng: 17.877435 },
    { lat: 48.634387, lng: 17.877806 },
    { lat: 48.634383, lng: 17.877849 },
    { lat: 48.634374, lng: 17.877925 },
    { lat: 48.634305, lng: 17.878525 },
    { lat: 48.634327, lng: 17.879181 },
    { lat: 48.6354991, lng: 17.8802229 },
    { lat: 48.6359465, lng: 17.8806028 },
    { lat: 48.6366329, lng: 17.8812215 },
    { lat: 48.6372282, lng: 17.8817325 },
    { lat: 48.6390532, lng: 17.8832954 },
    { lat: 48.642823, lng: 17.8854373 },
    { lat: 48.6427882, lng: 17.8856656 },
    { lat: 48.6437798, lng: 17.8858609 },
    { lat: 48.644822, lng: 17.8860332 },
    { lat: 48.6451023, lng: 17.8860411 },
    { lat: 48.6451727, lng: 17.8859918 },
    { lat: 48.6455706, lng: 17.8860941 },
    { lat: 48.6466486, lng: 17.8862601 },
    { lat: 48.647247, lng: 17.8865805 },
    { lat: 48.6481829, lng: 17.8868653 },
    { lat: 48.6481943, lng: 17.887109 },
    { lat: 48.6483586, lng: 17.887362 },
    { lat: 48.6485818, lng: 17.8876537 },
    { lat: 48.6493019, lng: 17.8881966 },
    { lat: 48.6490939, lng: 17.8888845 },
    { lat: 48.6495343, lng: 17.8892259 },
    { lat: 48.6508718, lng: 17.8909985 },
    { lat: 48.650653, lng: 17.8917883 },
    { lat: 48.6505308, lng: 17.8927193 },
    { lat: 48.6503066, lng: 17.8933624 },
    { lat: 48.6501728, lng: 17.8941768 },
    { lat: 48.6501014, lng: 17.8944904 },
    { lat: 48.650305, lng: 17.8953575 },
    { lat: 48.6502776, lng: 17.8958214 },
    { lat: 48.6505286, lng: 17.8959786 },
    { lat: 48.6508732, lng: 17.896359 },
    { lat: 48.6513465, lng: 17.8969284 },
    { lat: 48.6519568, lng: 17.8976158 },
    { lat: 48.652262, lng: 17.8967407 },
    { lat: 48.65254, lng: 17.896126 },
    { lat: 48.6529236, lng: 17.8964624 },
    { lat: 48.6533362, lng: 17.8954783 },
    { lat: 48.6537145, lng: 17.8944313 },
    { lat: 48.653786, lng: 17.894177 },
    { lat: 48.6541139, lng: 17.8933619 },
    { lat: 48.6542718, lng: 17.8929628 },
    { lat: 48.6543623, lng: 17.8928365 },
    { lat: 48.6544531, lng: 17.8926398 },
    { lat: 48.6547898, lng: 17.8927546 },
    { lat: 48.654989, lng: 17.8919032 },
    { lat: 48.6554461, lng: 17.8910967 },
    { lat: 48.6557226, lng: 17.8906646 },
    { lat: 48.6558165, lng: 17.8904449 },
    { lat: 48.6559529, lng: 17.8899931 },
    { lat: 48.656194, lng: 17.8874768 },
    { lat: 48.6561391, lng: 17.8872415 },
    { lat: 48.6559968, lng: 17.886772 },
    { lat: 48.6555675, lng: 17.8856638 },
    { lat: 48.6555018, lng: 17.885398 },
    { lat: 48.6553292, lng: 17.8848933 },
    { lat: 48.6552969, lng: 17.8845264 },
    { lat: 48.6550352, lng: 17.8838008 },
    { lat: 48.6547862, lng: 17.883191 },
    { lat: 48.6547507, lng: 17.8829388 },
    { lat: 48.6547652, lng: 17.882714 },
    { lat: 48.6548227, lng: 17.882001 },
    { lat: 48.6545782, lng: 17.8818018 },
    { lat: 48.6544548, lng: 17.881741 },
    { lat: 48.6542485, lng: 17.881408 },
    { lat: 48.6539408, lng: 17.8814357 },
    { lat: 48.6539547, lng: 17.8808237 },
    { lat: 48.653967, lng: 17.880691 },
    { lat: 48.6537563, lng: 17.88066 },
    { lat: 48.6536759, lng: 17.880656 },
    { lat: 48.6537141, lng: 17.8805415 },
    { lat: 48.653862, lng: 17.8798682 },
    { lat: 48.6538673, lng: 17.8797882 },
    { lat: 48.6538306, lng: 17.8796108 },
    { lat: 48.6537866, lng: 17.8790699 },
    { lat: 48.6535925, lng: 17.8779138 },
    { lat: 48.6535103, lng: 17.8770932 },
    { lat: 48.6534922, lng: 17.8766794 },
    { lat: 48.6534009, lng: 17.8759762 },
    { lat: 48.6531059, lng: 17.8754827 },
    { lat: 48.652935, lng: 17.8753077 },
    { lat: 48.6523055, lng: 17.874825 },
    { lat: 48.6521094, lng: 17.8746113 },
    { lat: 48.6519828, lng: 17.8742896 },
    { lat: 48.6519832, lng: 17.8741631 },
    { lat: 48.6522802, lng: 17.8739812 },
    { lat: 48.6526853, lng: 17.8737905 },
    { lat: 48.6529523, lng: 17.8736327 },
    { lat: 48.6528371, lng: 17.8730149 },
    { lat: 48.6528353, lng: 17.8726681 },
    { lat: 48.6527606, lng: 17.8716962 },
    { lat: 48.6526397, lng: 17.8713555 },
    { lat: 48.6527836, lng: 17.8706916 },
    { lat: 48.652893, lng: 17.87002 },
    { lat: 48.6527907, lng: 17.8699523 },
    { lat: 48.6522871, lng: 17.8697646 },
    { lat: 48.6520912, lng: 17.869772 },
    { lat: 48.6515714, lng: 17.8699304 },
    { lat: 48.6514975, lng: 17.8694004 },
    { lat: 48.6513412, lng: 17.8677283 },
  ],
  StaráLehota: [
    { lat: 48.612529, lng: 17.979103 },
    { lat: 48.6127087, lng: 17.9794217 },
    { lat: 48.612924, lng: 17.9798034 },
    { lat: 48.613076, lng: 17.9800729 },
    { lat: 48.6133888, lng: 17.9806274 },
    { lat: 48.613578, lng: 17.980963 },
    { lat: 48.614917, lng: 17.982682 },
    { lat: 48.614954, lng: 17.98273 },
    { lat: 48.615852, lng: 17.983884 },
    { lat: 48.61649, lng: 17.984659 },
    { lat: 48.617904, lng: 17.986433 },
    { lat: 48.618432, lng: 17.986922 },
    { lat: 48.618906, lng: 17.987712 },
    { lat: 48.619939, lng: 17.988984 },
    { lat: 48.619999, lng: 17.989063 },
    { lat: 48.620623, lng: 17.989886 },
    { lat: 48.62019, lng: 17.990188 },
    { lat: 48.620153, lng: 17.990244 },
    { lat: 48.62012, lng: 17.990294 },
    { lat: 48.620032, lng: 17.990611 },
    { lat: 48.619683, lng: 17.991349 },
    { lat: 48.619415, lng: 17.991816 },
    { lat: 48.619275, lng: 17.992115 },
    { lat: 48.619085, lng: 17.99257 },
    { lat: 48.618802, lng: 17.993682 },
    { lat: 48.618641, lng: 17.993918 },
    { lat: 48.618133, lng: 17.994773 },
    { lat: 48.617736, lng: 17.995413 },
    { lat: 48.617513, lng: 17.995771 },
    { lat: 48.617464, lng: 17.995894 },
    { lat: 48.617489, lng: 17.995918 },
    { lat: 48.619635, lng: 17.998627 },
    { lat: 48.620398, lng: 17.99959 },
    { lat: 48.620424, lng: 17.999621 },
    { lat: 48.621229, lng: 18.000639 },
    { lat: 48.6214181, lng: 17.9998328 },
    { lat: 48.6218467, lng: 17.998781 },
    { lat: 48.6218749, lng: 17.9987219 },
    { lat: 48.6218149, lng: 17.9985128 },
    { lat: 48.6217854, lng: 17.9983387 },
    { lat: 48.6218168, lng: 17.9981069 },
    { lat: 48.6212529, lng: 17.9982255 },
    { lat: 48.6212478, lng: 17.9980478 },
    { lat: 48.6212013, lng: 17.9976425 },
    { lat: 48.62133, lng: 17.9972266 },
    { lat: 48.6214167, lng: 17.9970333 },
    { lat: 48.6215358, lng: 17.9966055 },
    { lat: 48.6216437, lng: 17.9961236 },
    { lat: 48.6219299, lng: 17.9956582 },
    { lat: 48.6219899, lng: 17.9950921 },
    { lat: 48.6221142, lng: 17.9945056 },
    { lat: 48.6222642, lng: 17.9941236 },
    { lat: 48.6225936, lng: 17.994084 },
    { lat: 48.6229903, lng: 17.9940917 },
    { lat: 48.6232463, lng: 17.993919 },
    { lat: 48.6235439, lng: 17.9939113 },
    { lat: 48.6238628, lng: 17.9937504 },
    { lat: 48.6241344, lng: 17.9934771 },
    { lat: 48.6242952, lng: 17.993161 },
    { lat: 48.6245055, lng: 17.9926563 },
    { lat: 48.6246552, lng: 17.9923267 },
    { lat: 48.6248038, lng: 17.9921493 },
    { lat: 48.6248223, lng: 17.9921022 },
    { lat: 48.6260579, lng: 17.98847 },
    { lat: 48.6262339, lng: 17.9882003 },
    { lat: 48.6263448, lng: 17.9878068 },
    { lat: 48.6264658, lng: 17.9876216 },
    { lat: 48.6265078, lng: 17.9873426 },
    { lat: 48.6265318, lng: 17.9870822 },
    { lat: 48.6266356, lng: 17.986965 },
    { lat: 48.6267272, lng: 17.9868195 },
    { lat: 48.6267894, lng: 17.9866127 },
    { lat: 48.6269125, lng: 17.9857005 },
    { lat: 48.6270174, lng: 17.9851904 },
    { lat: 48.6270662, lng: 17.9848134 },
    { lat: 48.6270705, lng: 17.9844689 },
    { lat: 48.6269846, lng: 17.9840705 },
    { lat: 48.6269772, lng: 17.9836395 },
    { lat: 48.6270132, lng: 17.9831629 },
    { lat: 48.6270443, lng: 17.9825689 },
    { lat: 48.6269999, lng: 17.9820697 },
    { lat: 48.6268668, lng: 17.9818401 },
    { lat: 48.626763, lng: 17.9817104 },
    { lat: 48.6264468, lng: 17.9811945 },
    { lat: 48.6263271, lng: 17.9810231 },
    { lat: 48.6261183, lng: 17.9805515 },
    { lat: 48.6260031, lng: 17.9802296 },
    { lat: 48.6259133, lng: 17.9800284 },
    { lat: 48.625876, lng: 17.9794209 },
    { lat: 48.6259069, lng: 17.9791823 },
    { lat: 48.626132, lng: 17.9789819 },
    { lat: 48.6263494, lng: 17.9788797 },
    { lat: 48.6269876, lng: 17.9786776 },
    { lat: 48.6271423, lng: 17.9785628 },
    { lat: 48.6272096, lng: 17.9784459 },
    { lat: 48.6272405, lng: 17.9782216 },
    { lat: 48.6293677, lng: 17.9802831 },
    { lat: 48.6300108, lng: 17.9804847 },
    { lat: 48.6301444, lng: 17.9807235 },
    { lat: 48.6297283, lng: 17.9815798 },
    { lat: 48.6301169, lng: 17.9832727 },
    { lat: 48.6311252, lng: 17.9822002 },
    { lat: 48.6309708, lng: 17.9807435 },
    { lat: 48.6320439, lng: 17.9804172 },
    { lat: 48.6331057, lng: 17.9797105 },
    { lat: 48.6329564, lng: 17.9790032 },
    { lat: 48.6334371, lng: 17.9778047 },
    { lat: 48.6337484, lng: 17.9772904 },
    { lat: 48.6342128, lng: 17.9768834 },
    { lat: 48.6342658, lng: 17.9768441 },
    { lat: 48.6343221, lng: 17.9770495 },
    { lat: 48.6344088, lng: 17.9772747 },
    { lat: 48.6345933, lng: 17.9775683 },
    { lat: 48.6346541, lng: 17.977756 },
    { lat: 48.6348428, lng: 17.9785922 },
    { lat: 48.6349591, lng: 17.9788569 },
    { lat: 48.635007, lng: 17.9788283 },
    { lat: 48.636237, lng: 17.9786701 },
    { lat: 48.6362679, lng: 17.9786781 },
    { lat: 48.6361212, lng: 17.977334 },
    { lat: 48.63606, lng: 17.9770447 },
    { lat: 48.6359748, lng: 17.9768461 },
    { lat: 48.6358512, lng: 17.9767251 },
    { lat: 48.6352184, lng: 17.9762178 },
    { lat: 48.6349815, lng: 17.9758628 },
    { lat: 48.6348045, lng: 17.9750318 },
    { lat: 48.6357283, lng: 17.9737681 },
    { lat: 48.6362782, lng: 17.9736967 },
    { lat: 48.6356477, lng: 17.9718291 },
    { lat: 48.6355708, lng: 17.9713921 },
    { lat: 48.6354981, lng: 17.9710651 },
    { lat: 48.6353602, lng: 17.9706645 },
    { lat: 48.6352163, lng: 17.9703882 },
    { lat: 48.6348556, lng: 17.9694494 },
    { lat: 48.6347624, lng: 17.9691548 },
    { lat: 48.6347305, lng: 17.9688661 },
    { lat: 48.6347748, lng: 17.968585 },
    { lat: 48.6351864, lng: 17.9683917 },
    { lat: 48.6356447, lng: 17.9681842 },
    { lat: 48.6361857, lng: 17.968225 },
    { lat: 48.6367169, lng: 17.9682403 },
    { lat: 48.6367148, lng: 17.9682812 },
    { lat: 48.6367112, lng: 17.9682967 },
    { lat: 48.6368982, lng: 17.9683666 },
    { lat: 48.6377255, lng: 17.9682696 },
    { lat: 48.6378525, lng: 17.9682021 },
    { lat: 48.6379335, lng: 17.9681247 },
    { lat: 48.6381409, lng: 17.9676993 },
    { lat: 48.6382386, lng: 17.9675961 },
    { lat: 48.6383669, lng: 17.9676297 },
    { lat: 48.6384847, lng: 17.9676384 },
    { lat: 48.6385801, lng: 17.9676086 },
    { lat: 48.6387757, lng: 17.9675003 },
    { lat: 48.6392111, lng: 17.9673067 },
    { lat: 48.6394137, lng: 17.9672406 },
    { lat: 48.6395605, lng: 17.9674148 },
    { lat: 48.6395755, lng: 17.9677415 },
    { lat: 48.6396324, lng: 17.9678779 },
    { lat: 48.6397105, lng: 17.9679776 },
    { lat: 48.6398899, lng: 17.9680906 },
    { lat: 48.6402681, lng: 17.96811 },
    { lat: 48.640527, lng: 17.967987 },
    { lat: 48.6408526, lng: 17.9677658 },
    { lat: 48.6413692, lng: 17.9670054 },
    { lat: 48.6417734, lng: 17.9666289 },
    { lat: 48.641857, lng: 17.9665155 },
    { lat: 48.6418952, lng: 17.9663986 },
    { lat: 48.6421156, lng: 17.9669522 },
    { lat: 48.6421782, lng: 17.96713 },
    { lat: 48.6422256, lng: 17.967207 },
    { lat: 48.6422904, lng: 17.9672143 },
    { lat: 48.6423258, lng: 17.9672966 },
    { lat: 48.6423327, lng: 17.967357 },
    { lat: 48.6423719, lng: 17.9673724 },
    { lat: 48.642404, lng: 17.967549 },
    { lat: 48.642495, lng: 17.9677112 },
    { lat: 48.6426689, lng: 17.9678664 },
    { lat: 48.6426434, lng: 17.9676443 },
    { lat: 48.642754, lng: 17.9676075 },
    { lat: 48.6430316, lng: 17.9677222 },
    { lat: 48.6431695, lng: 17.9680625 },
    { lat: 48.6432496, lng: 17.9682315 },
    { lat: 48.6434904, lng: 17.968656 },
    { lat: 48.6442536, lng: 17.9688104 },
    { lat: 48.6465665, lng: 17.9691895 },
    { lat: 48.6465098, lng: 17.9687707 },
    { lat: 48.6467092, lng: 17.9689171 },
    { lat: 48.6477432, lng: 17.9694085 },
    { lat: 48.6480974, lng: 17.9694253 },
    { lat: 48.6486435, lng: 17.9694218 },
    { lat: 48.6490368, lng: 17.9697893 },
    { lat: 48.6490089, lng: 17.9701557 },
    { lat: 48.6491825, lng: 17.970266 },
    { lat: 48.6493423, lng: 17.9704075 },
    { lat: 48.6494172, lng: 17.9700721 },
    { lat: 48.6494395, lng: 17.9695979 },
    { lat: 48.6495312, lng: 17.9696558 },
    { lat: 48.6496245, lng: 17.9696138 },
    { lat: 48.6498736, lng: 17.9690003 },
    { lat: 48.6499385, lng: 17.9689347 },
    { lat: 48.6500205, lng: 17.9690396 },
    { lat: 48.65012, lng: 17.9688929 },
    { lat: 48.6502917, lng: 17.9689707 },
    { lat: 48.6506022, lng: 17.9689538 },
    { lat: 48.6507096, lng: 17.968633 },
    { lat: 48.6508447, lng: 17.9688006 },
    { lat: 48.6514874, lng: 17.9683139 },
    { lat: 48.6516853, lng: 17.9681146 },
    { lat: 48.6516892, lng: 17.9679887 },
    { lat: 48.6518243, lng: 17.9678119 },
    { lat: 48.6519239, lng: 17.9677048 },
    { lat: 48.6520432, lng: 17.9676597 },
    { lat: 48.6522442, lng: 17.9676424 },
    { lat: 48.6527176, lng: 17.9676884 },
    { lat: 48.652819, lng: 17.9677068 },
    { lat: 48.6529398, lng: 17.9676854 },
    { lat: 48.6531738, lng: 17.967615 },
    { lat: 48.6532032, lng: 17.9674868 },
    { lat: 48.6532896, lng: 17.9674954 },
    { lat: 48.6532852, lng: 17.9672508 },
    { lat: 48.6533169, lng: 17.9671204 },
    { lat: 48.653438, lng: 17.9670683 },
    { lat: 48.6537816, lng: 17.9669845 },
    { lat: 48.6540607, lng: 17.9669476 },
    { lat: 48.6543451, lng: 17.966874 },
    { lat: 48.6545989, lng: 17.9668713 },
    { lat: 48.6554141, lng: 17.9665314 },
    { lat: 48.6556879, lng: 17.9665259 },
    { lat: 48.6562577, lng: 17.9662971 },
    { lat: 48.656448, lng: 17.9664223 },
    { lat: 48.6567978, lng: 17.9670252 },
    { lat: 48.6569385, lng: 17.967452 },
    { lat: 48.6571278, lng: 17.9680722 },
    { lat: 48.6571865, lng: 17.9683191 },
    { lat: 48.6575186, lng: 17.9681326 },
    { lat: 48.6577073, lng: 17.9685536 },
    { lat: 48.6577505, lng: 17.9687294 },
    { lat: 48.6578122, lng: 17.9688717 },
    { lat: 48.6578885, lng: 17.9689217 },
    { lat: 48.6593152, lng: 17.9689221 },
    { lat: 48.6595942, lng: 17.9689089 },
    { lat: 48.6597356, lng: 17.9688922 },
    { lat: 48.6602768, lng: 17.9687694 },
    { lat: 48.6603841, lng: 17.9687163 },
    { lat: 48.6604454, lng: 17.9686661 },
    { lat: 48.6605128, lng: 17.9685817 },
    { lat: 48.6605667, lng: 17.9684829 },
    { lat: 48.6606441, lng: 17.9683057 },
    { lat: 48.6608839, lng: 17.9675183 },
    { lat: 48.6609452, lng: 17.9673526 },
    { lat: 48.6610249, lng: 17.967215 },
    { lat: 48.6611405, lng: 17.9670315 },
    { lat: 48.6613157, lng: 17.9668677 },
    { lat: 48.6617425, lng: 17.9664919 },
    { lat: 48.661865, lng: 17.9664049 },
    { lat: 48.6623573, lng: 17.9654422 },
    { lat: 48.6625237, lng: 17.9650918 },
    { lat: 48.6625993, lng: 17.9650141 },
    { lat: 48.6627093, lng: 17.9649666 },
    { lat: 48.6627441, lng: 17.9648494 },
    { lat: 48.6628653, lng: 17.9647069 },
    { lat: 48.6634163, lng: 17.9646899 },
    { lat: 48.6637957, lng: 17.9648643 },
    { lat: 48.664066, lng: 17.9640938 },
    { lat: 48.6642977, lng: 17.9646129 },
    { lat: 48.6646578, lng: 17.9646893 },
    { lat: 48.6648415, lng: 17.9642687 },
    { lat: 48.6650988, lng: 17.9641527 },
    { lat: 48.6652561, lng: 17.9639345 },
    { lat: 48.6654284, lng: 17.9642103 },
    { lat: 48.6653961, lng: 17.9644068 },
    { lat: 48.6654417, lng: 17.9646431 },
    { lat: 48.6655088, lng: 17.9648203 },
    { lat: 48.6657223, lng: 17.9648035 },
    { lat: 48.6659063, lng: 17.9651077 },
    { lat: 48.6662463, lng: 17.9652454 },
    { lat: 48.6662885, lng: 17.965175 },
    { lat: 48.6664759, lng: 17.965177 },
    { lat: 48.6665301, lng: 17.9651172 },
    { lat: 48.6666635, lng: 17.9651215 },
    { lat: 48.6667614, lng: 17.9651729 },
    { lat: 48.6667476, lng: 17.9652833 },
    { lat: 48.6669751, lng: 17.9653531 },
    { lat: 48.6669996, lng: 17.9651169 },
    { lat: 48.667298, lng: 17.9651081 },
    { lat: 48.667345, lng: 17.9653453 },
    { lat: 48.6675735, lng: 17.9653738 },
    { lat: 48.6680444, lng: 17.9659247 },
    { lat: 48.6683686, lng: 17.96566 },
    { lat: 48.6684511, lng: 17.9655927 },
    { lat: 48.6693963, lng: 17.964805 },
    { lat: 48.6699447, lng: 17.964604 },
    { lat: 48.6701685, lng: 17.9645219 },
    { lat: 48.6701291, lng: 17.9642573 },
    { lat: 48.670053, lng: 17.9638894 },
    { lat: 48.6700329, lng: 17.9637004 },
    { lat: 48.6700164, lng: 17.9635827 },
    { lat: 48.670039, lng: 17.9634853 },
    { lat: 48.670047, lng: 17.9629568 },
    { lat: 48.6702707, lng: 17.9623575 },
    { lat: 48.6703552, lng: 17.9622785 },
    { lat: 48.6704688, lng: 17.9623273 },
    { lat: 48.6704758, lng: 17.9623396 },
    { lat: 48.6708758, lng: 17.9630412 },
    { lat: 48.6710635, lng: 17.9630464 },
    { lat: 48.6710883, lng: 17.9628163 },
    { lat: 48.6713177, lng: 17.962634 },
    { lat: 48.6715257, lng: 17.9625796 },
    { lat: 48.6717834, lng: 17.962585 },
    { lat: 48.6719334, lng: 17.9624714 },
    { lat: 48.6721172, lng: 17.9623721 },
    { lat: 48.6722485, lng: 17.9624164 },
    { lat: 48.6729109, lng: 17.9619228 },
    { lat: 48.6729003, lng: 17.9617456 },
    { lat: 48.6729824, lng: 17.9577696 },
    { lat: 48.6730105, lng: 17.9564616 },
    { lat: 48.6730326, lng: 17.9532801 },
    { lat: 48.6730519, lng: 17.9484847 },
    { lat: 48.6737392, lng: 17.949405 },
    { lat: 48.6738609, lng: 17.9492324 },
    { lat: 48.6739489, lng: 17.9488729 },
    { lat: 48.6736577, lng: 17.9481754 },
    { lat: 48.6739075, lng: 17.9476142 },
    { lat: 48.6740038, lng: 17.9474129 },
    { lat: 48.6741281, lng: 17.947111 },
    { lat: 48.6737183, lng: 17.9469236 },
    { lat: 48.6735735, lng: 17.9467676 },
    { lat: 48.6732475, lng: 17.9466553 },
    { lat: 48.6731435, lng: 17.946352 },
    { lat: 48.6732207, lng: 17.9440368 },
    { lat: 48.6708953, lng: 17.9427333 },
    { lat: 48.6689123, lng: 17.9415857 },
    { lat: 48.6675855, lng: 17.9408054 },
    { lat: 48.6668079, lng: 17.9403522 },
    { lat: 48.6655608, lng: 17.9396036 },
    { lat: 48.6644827, lng: 17.939034 },
    { lat: 48.6634939, lng: 17.938433 },
    { lat: 48.66232, lng: 17.937788 },
    { lat: 48.6610762, lng: 17.9370747 },
    { lat: 48.6597393, lng: 17.936447 },
    { lat: 48.658813, lng: 17.9353417 },
    { lat: 48.657686, lng: 17.9341063 },
    { lat: 48.6561106, lng: 17.9341521 },
    { lat: 48.65479, lng: 17.9332867 },
    { lat: 48.6540495, lng: 17.9326253 },
    { lat: 48.6539892, lng: 17.9326724 },
    { lat: 48.6534451, lng: 17.9326557 },
    { lat: 48.6530981, lng: 17.9325927 },
    { lat: 48.6527879, lng: 17.9319711 },
    { lat: 48.6527099, lng: 17.9318771 },
    { lat: 48.6525107, lng: 17.9318294 },
    { lat: 48.6522923, lng: 17.9317267 },
    { lat: 48.6522089, lng: 17.9317321 },
    { lat: 48.651911, lng: 17.9319752 },
    { lat: 48.6517405, lng: 17.9321994 },
    { lat: 48.6516809, lng: 17.9323389 },
    { lat: 48.6512653, lng: 17.9328426 },
    { lat: 48.6509985, lng: 17.9330611 },
    { lat: 48.6508169, lng: 17.9332701 },
    { lat: 48.65058, lng: 17.9334227 },
    { lat: 48.6503375, lng: 17.9334844 },
    { lat: 48.6492813, lng: 17.9336575 },
    { lat: 48.6484613, lng: 17.9329912 },
    { lat: 48.6472837, lng: 17.9317966 },
    { lat: 48.645691, lng: 17.9302715 },
    { lat: 48.6449589, lng: 17.9305639 },
    { lat: 48.6448836, lng: 17.929872 },
    { lat: 48.6445775, lng: 17.9287599 },
    { lat: 48.6443234, lng: 17.9279654 },
    { lat: 48.6438553, lng: 17.9275011 },
    { lat: 48.6436444, lng: 17.9266982 },
    { lat: 48.6436603, lng: 17.9254918 },
    { lat: 48.6435617, lng: 17.9250359 },
    { lat: 48.6424748, lng: 17.9246804 },
    { lat: 48.6421694, lng: 17.924578 },
    { lat: 48.6416611, lng: 17.9244012 },
    { lat: 48.6410955, lng: 17.9241915 },
    { lat: 48.6406159, lng: 17.9240079 },
    { lat: 48.6404605, lng: 17.9239763 },
    { lat: 48.639748, lng: 17.9237272 },
    { lat: 48.6393324, lng: 17.923179 },
    { lat: 48.6385269, lng: 17.9232595 },
    { lat: 48.6378532, lng: 17.9232281 },
    { lat: 48.63714, lng: 17.9225882 },
    { lat: 48.637105, lng: 17.9225334 },
    { lat: 48.6370565, lng: 17.9223484 },
    { lat: 48.6370533, lng: 17.9222364 },
    { lat: 48.6369342, lng: 17.9220207 },
    { lat: 48.6362166, lng: 17.9222152 },
    { lat: 48.6362279, lng: 17.9223646 },
    { lat: 48.6362292, lng: 17.9228541 },
    { lat: 48.6361808, lng: 17.9233286 },
    { lat: 48.636068, lng: 17.9238183 },
    { lat: 48.6360363, lng: 17.9240028 },
    { lat: 48.6360258, lng: 17.9241513 },
    { lat: 48.6360986, lng: 17.9252488 },
    { lat: 48.6361373, lng: 17.9255557 },
    { lat: 48.6362771, lng: 17.9261151 },
    { lat: 48.6363444, lng: 17.926279 },
    { lat: 48.6365098, lng: 17.9265169 },
    { lat: 48.6367726, lng: 17.9267366 },
    { lat: 48.6370664, lng: 17.9269702 },
    { lat: 48.6372815, lng: 17.9271685 },
    { lat: 48.6374274, lng: 17.9274359 },
    { lat: 48.6376005, lng: 17.9279472 },
    { lat: 48.6376926, lng: 17.928506 },
    { lat: 48.6377334, lng: 17.9286617 },
    { lat: 48.637786, lng: 17.9288201 },
    { lat: 48.637963, lng: 17.9291018 },
    { lat: 48.6380879, lng: 17.9292236 },
    { lat: 48.6379914, lng: 17.9295748 },
    { lat: 48.6378386, lng: 17.9294518 },
    { lat: 48.6366407, lng: 17.9303352 },
    { lat: 48.6356383, lng: 17.9310481 },
    { lat: 48.6356226, lng: 17.9311808 },
    { lat: 48.6356434, lng: 17.9314888 },
    { lat: 48.6357024, lng: 17.9320428 },
    { lat: 48.635729, lng: 17.9323365 },
    { lat: 48.635799, lng: 17.9327663 },
    { lat: 48.6358304, lng: 17.9331039 },
    { lat: 48.6358706, lng: 17.9333956 },
    { lat: 48.6359337, lng: 17.9335655 },
    { lat: 48.6360038, lng: 17.9339056 },
    { lat: 48.6360733, lng: 17.9345014 },
    { lat: 48.636018, lng: 17.9346713 },
    { lat: 48.6358577, lng: 17.9361411 },
    { lat: 48.6358065, lng: 17.9370541 },
    { lat: 48.6356525, lng: 17.93863 },
    { lat: 48.6356507, lng: 17.9393151 },
    { lat: 48.6357226, lng: 17.9396112 },
    { lat: 48.6353309, lng: 17.9400439 },
    { lat: 48.635038, lng: 17.940581 },
    { lat: 48.6350061, lng: 17.9413508 },
    { lat: 48.6354153, lng: 17.9427189 },
    { lat: 48.6352083, lng: 17.9434837 },
    { lat: 48.6344647, lng: 17.9436753 },
    { lat: 48.6341228, lng: 17.9437326 },
    { lat: 48.6340794, lng: 17.9439509 },
    { lat: 48.6338421, lng: 17.9440414 },
    { lat: 48.6333763, lng: 17.943764 },
    { lat: 48.6334666, lng: 17.9449165 },
    { lat: 48.6331523, lng: 17.9449251 },
    { lat: 48.6330692, lng: 17.9450459 },
    { lat: 48.632857, lng: 17.9449317 },
    { lat: 48.6324688, lng: 17.9445946 },
    { lat: 48.6323127, lng: 17.9445709 },
    { lat: 48.6322545, lng: 17.944844 },
    { lat: 48.6320968, lng: 17.9449779 },
    { lat: 48.6318788, lng: 17.9453558 },
    { lat: 48.631774, lng: 17.9452252 },
    { lat: 48.6317271, lng: 17.9449704 },
    { lat: 48.6315835, lng: 17.9447769 },
    { lat: 48.6316523, lng: 17.9445713 },
    { lat: 48.6316873, lng: 17.9445705 },
    { lat: 48.6316979, lng: 17.9444277 },
    { lat: 48.6315378, lng: 17.9442426 },
    { lat: 48.6316503, lng: 17.944102 },
    { lat: 48.6317071, lng: 17.9436928 },
    { lat: 48.6314709, lng: 17.9436897 },
    { lat: 48.6312421, lng: 17.9438618 },
    { lat: 48.631182, lng: 17.9439335 },
    { lat: 48.6309539, lng: 17.9441772 },
    { lat: 48.6306532, lng: 17.944321 },
    { lat: 48.6305299, lng: 17.9444509 },
    { lat: 48.6304272, lng: 17.9444859 },
    { lat: 48.6302532, lng: 17.944389 },
    { lat: 48.6301146, lng: 17.9443565 },
    { lat: 48.6300645, lng: 17.9445557 },
    { lat: 48.6301165, lng: 17.9449343 },
    { lat: 48.6302613, lng: 17.9454681 },
    { lat: 48.6303969, lng: 17.9460191 },
    { lat: 48.6306064, lng: 17.9464452 },
    { lat: 48.6307476, lng: 17.9468595 },
    { lat: 48.6308584, lng: 17.9474856 },
    { lat: 48.6308803, lng: 17.9480018 },
    { lat: 48.6306933, lng: 17.9484259 },
    { lat: 48.6306855, lng: 17.9485411 },
    { lat: 48.630767, lng: 17.9488084 },
    { lat: 48.630731, lng: 17.9491123 },
    { lat: 48.630782, lng: 17.9492852 },
    { lat: 48.6308061, lng: 17.9494425 },
    { lat: 48.630998, lng: 17.9499894 },
    { lat: 48.6313121, lng: 17.9505979 },
    { lat: 48.6312726, lng: 17.9507482 },
    { lat: 48.6307422, lng: 17.9510901 },
    { lat: 48.6308491, lng: 17.9517072 },
    { lat: 48.6313609, lng: 17.9522259 },
    { lat: 48.6305755, lng: 17.9535325 },
    { lat: 48.6299838, lng: 17.9538702 },
    { lat: 48.6298604, lng: 17.954109 },
    { lat: 48.6297343, lng: 17.9541518 },
    { lat: 48.629256, lng: 17.9541002 },
    { lat: 48.6291667, lng: 17.9537777 },
    { lat: 48.6283032, lng: 17.9539283 },
    { lat: 48.6273719, lng: 17.9543223 },
    { lat: 48.626402, lng: 17.9550915 },
    { lat: 48.6253311, lng: 17.955869 },
    { lat: 48.6242469, lng: 17.9563886 },
    { lat: 48.6235212, lng: 17.9569778 },
    { lat: 48.62288, lng: 17.9574442 },
    { lat: 48.6219063, lng: 17.9581703 },
    { lat: 48.621415, lng: 17.9585428 },
    { lat: 48.62113, lng: 17.958762 },
    { lat: 48.621065, lng: 17.959343 },
    { lat: 48.621011, lng: 17.95972 },
    { lat: 48.620751, lng: 17.960343 },
    { lat: 48.620512, lng: 17.960872 },
    { lat: 48.620333, lng: 17.960782 },
    { lat: 48.620089, lng: 17.960413 },
    { lat: 48.619662, lng: 17.960015 },
    { lat: 48.618692, lng: 17.95947 },
    { lat: 48.617065, lng: 17.958379 },
    { lat: 48.61696, lng: 17.958278 },
    { lat: 48.616863, lng: 17.958318 },
    { lat: 48.616776, lng: 17.958534 },
    { lat: 48.616411, lng: 17.959017 },
    { lat: 48.616066, lng: 17.959682 },
    { lat: 48.615736, lng: 17.960326 },
    { lat: 48.615305, lng: 17.961154 },
    { lat: 48.614879, lng: 17.961732 },
    { lat: 48.614758, lng: 17.961861 },
    { lat: 48.614383, lng: 17.962197 },
    { lat: 48.613796, lng: 17.962517 },
    { lat: 48.613186, lng: 17.96294 },
    { lat: 48.612722, lng: 17.963076 },
    { lat: 48.6126633, lng: 17.9631057 },
    { lat: 48.612351, lng: 17.963264 },
    { lat: 48.611995, lng: 17.963382 },
    { lat: 48.611829, lng: 17.963473 },
    { lat: 48.611643, lng: 17.963546 },
    { lat: 48.611411, lng: 17.963727 },
    { lat: 48.6114095, lng: 17.9637707 },
    { lat: 48.611403, lng: 17.963963 },
    { lat: 48.611401, lng: 17.964044 },
    { lat: 48.611373, lng: 17.9648286 },
    { lat: 48.611333, lng: 17.965952 },
    { lat: 48.611172, lng: 17.968129 },
    { lat: 48.611125, lng: 17.968793 },
    { lat: 48.611093, lng: 17.969257 },
    { lat: 48.611091, lng: 17.969288 },
    { lat: 48.611106, lng: 17.969321 },
    { lat: 48.611437, lng: 17.970001 },
    { lat: 48.611621, lng: 17.97038 },
    { lat: 48.611606, lng: 17.970547 },
    { lat: 48.611576, lng: 17.970864 },
    { lat: 48.611603, lng: 17.971196 },
    { lat: 48.611572, lng: 17.971452 },
    { lat: 48.611596, lng: 17.971874 },
    { lat: 48.611667, lng: 17.972144 },
    { lat: 48.611693, lng: 17.97255 },
    { lat: 48.611602, lng: 17.973063 },
    { lat: 48.611627, lng: 17.973251 },
    { lat: 48.611704, lng: 17.973387 },
    { lat: 48.611815, lng: 17.973718 },
    { lat: 48.611805, lng: 17.973925 },
    { lat: 48.61176, lng: 17.974156 },
    { lat: 48.611729, lng: 17.974411 },
    { lat: 48.611711, lng: 17.974764 },
    { lat: 48.611731, lng: 17.9751 },
    { lat: 48.611746, lng: 17.975472 },
    { lat: 48.611793, lng: 17.975678 },
    { lat: 48.611946, lng: 17.976034 },
    { lat: 48.61209, lng: 17.97623 },
    { lat: 48.612156, lng: 17.976341 },
    { lat: 48.612179, lng: 17.976486 },
    { lat: 48.612059, lng: 17.9769 },
    { lat: 48.612013, lng: 17.97718 },
    { lat: 48.612017, lng: 17.977302 },
    { lat: 48.612192, lng: 17.97752 },
    { lat: 48.612242, lng: 17.977641 },
    { lat: 48.61225, lng: 17.977829 },
    { lat: 48.6123936, lng: 17.9784848 },
    { lat: 48.6125064, lng: 17.9789997 },
    { lat: 48.612529, lng: 17.979103 },
  ],
  Hrádok: [
    { lat: 48.7088948, lng: 17.8725188 },
    { lat: 48.7088386, lng: 17.8718884 },
    { lat: 48.7087828, lng: 17.8713396 },
    { lat: 48.7086121, lng: 17.8709825 },
    { lat: 48.7084556, lng: 17.8706727 },
    { lat: 48.7082603, lng: 17.87045 },
    { lat: 48.7076835, lng: 17.8699936 },
    { lat: 48.7070868, lng: 17.8696742 },
    { lat: 48.7067302, lng: 17.8697931 },
    { lat: 48.7064425, lng: 17.8700063 },
    { lat: 48.7063413, lng: 17.8700344 },
    { lat: 48.7061368, lng: 17.8703131 },
    { lat: 48.7059958, lng: 17.8704675 },
    { lat: 48.7058719, lng: 17.8705498 },
    { lat: 48.7055476, lng: 17.8707354 },
    { lat: 48.7054043, lng: 17.8707957 },
    { lat: 48.7048958, lng: 17.8710542 },
    { lat: 48.7048348, lng: 17.8709806 },
    { lat: 48.7014142, lng: 17.8668439 },
    { lat: 48.700908, lng: 17.866899 },
    { lat: 48.7006739, lng: 17.8668393 },
    { lat: 48.70017, lng: 17.8667103 },
    { lat: 48.6990289, lng: 17.8665626 },
    { lat: 48.6968645, lng: 17.8662717 },
    { lat: 48.6942593, lng: 17.8659215 },
    { lat: 48.6938907, lng: 17.8657817 },
    { lat: 48.6935924, lng: 17.8688378 },
    { lat: 48.6935361, lng: 17.8693262 },
    { lat: 48.6933663, lng: 17.8707752 },
    { lat: 48.6931262, lng: 17.8727407 },
    { lat: 48.6929047, lng: 17.8745874 },
    { lat: 48.6923507, lng: 17.8779023 },
    { lat: 48.6922798, lng: 17.8784673 },
    { lat: 48.6923127, lng: 17.8787489 },
    { lat: 48.6918112, lng: 17.8788738 },
    { lat: 48.6916756, lng: 17.8788545 },
    { lat: 48.6915607, lng: 17.8788196 },
    { lat: 48.6912828, lng: 17.8787614 },
    { lat: 48.6911123, lng: 17.8798636 },
    { lat: 48.6910935, lng: 17.8800384 },
    { lat: 48.6907071, lng: 17.8809501 },
    { lat: 48.6893585, lng: 17.8800947 },
    { lat: 48.6862794, lng: 17.8792793 },
    { lat: 48.6837088, lng: 17.8783043 },
    { lat: 48.6797808, lng: 17.8764012 },
    { lat: 48.6797131, lng: 17.8770949 },
    { lat: 48.6788326, lng: 17.876999 },
    { lat: 48.6786256, lng: 17.8770696 },
    { lat: 48.6804843, lng: 17.8801822 },
    { lat: 48.68199, lng: 17.8819754 },
    { lat: 48.6825584, lng: 17.885389 },
    { lat: 48.6826761, lng: 17.8866317 },
    { lat: 48.6827739, lng: 17.8876528 },
    { lat: 48.6828531, lng: 17.8886485 },
    { lat: 48.6829094, lng: 17.8894335 },
    { lat: 48.6824878, lng: 17.8898719 },
    { lat: 48.6821325, lng: 17.8899536 },
    { lat: 48.6814063, lng: 17.889852 },
    { lat: 48.6811497, lng: 17.8898637 },
    { lat: 48.6802569, lng: 17.89009 },
    { lat: 48.67984, lng: 17.8900698 },
    { lat: 48.6795154, lng: 17.8900948 },
    { lat: 48.6795525, lng: 17.8905426 },
    { lat: 48.6795961, lng: 17.8908836 },
    { lat: 48.6796188, lng: 17.8910611 },
    { lat: 48.6797166, lng: 17.8915977 },
    { lat: 48.6802271, lng: 17.8933643 },
    { lat: 48.6802792, lng: 17.8937026 },
    { lat: 48.6802779, lng: 17.8941675 },
    { lat: 48.6802563, lng: 17.8946341 },
    { lat: 48.6802935, lng: 17.894934 },
    { lat: 48.6804304, lng: 17.8952749 },
    { lat: 48.6805802, lng: 17.8956084 },
    { lat: 48.6806079, lng: 17.895841 },
    { lat: 48.6806048, lng: 17.8959239 },
    { lat: 48.6805994, lng: 17.896658 },
    { lat: 48.6806469, lng: 17.8970709 },
    { lat: 48.680777, lng: 17.8973374 },
    { lat: 48.6813092, lng: 17.8981891 },
    { lat: 48.68156, lng: 17.8986265 },
    { lat: 48.6816912, lng: 17.8987975 },
    { lat: 48.6820752, lng: 17.899102 },
    { lat: 48.682195, lng: 17.8992931 },
    { lat: 48.6823189, lng: 17.8998301 },
    { lat: 48.6825001, lng: 17.9002714 },
    { lat: 48.6826658, lng: 17.9008109 },
    { lat: 48.6827155, lng: 17.9010399 },
    { lat: 48.682809, lng: 17.9013164 },
    { lat: 48.6829146, lng: 17.9014401 },
    { lat: 48.6830381, lng: 17.9014471 },
    { lat: 48.6835979, lng: 17.9013875 },
    { lat: 48.6846658, lng: 17.9014095 },
    { lat: 48.6847972, lng: 17.9014897 },
    { lat: 48.6852752, lng: 17.9020832 },
    { lat: 48.6856338, lng: 17.902488 },
    { lat: 48.686062, lng: 17.9027962 },
    { lat: 48.6862654, lng: 17.9030226 },
    { lat: 48.6864764, lng: 17.9034352 },
    { lat: 48.6865703, lng: 17.9040572 },
    { lat: 48.6865869, lng: 17.9042496 },
    { lat: 48.6866485, lng: 17.9049646 },
    { lat: 48.6867721, lng: 17.9055728 },
    { lat: 48.6868938, lng: 17.9058144 },
    { lat: 48.6870352, lng: 17.9059868 },
    { lat: 48.6871565, lng: 17.9059977 },
    { lat: 48.6875346, lng: 17.9058069 },
    { lat: 48.6877658, lng: 17.9058784 },
    { lat: 48.6878307, lng: 17.9059277 },
    { lat: 48.6878845, lng: 17.9061647 },
    { lat: 48.6878687, lng: 17.9064098 },
    { lat: 48.6878119, lng: 17.9066098 },
    { lat: 48.6877478, lng: 17.9067997 },
    { lat: 48.6875833, lng: 17.9069545 },
    { lat: 48.6874077, lng: 17.9070757 },
    { lat: 48.6871845, lng: 17.9071742 },
    { lat: 48.6870062, lng: 17.9073837 },
    { lat: 48.6868749, lng: 17.9078486 },
    { lat: 48.6867454, lng: 17.908169 },
    { lat: 48.6866548, lng: 17.908504 },
    { lat: 48.6866496, lng: 17.9086251 },
    { lat: 48.6866872, lng: 17.9086089 },
    { lat: 48.6868436, lng: 17.9084338 },
    { lat: 48.6870475, lng: 17.9082408 },
    { lat: 48.6873286, lng: 17.9080883 },
    { lat: 48.6877111, lng: 17.9078405 },
    { lat: 48.6882761, lng: 17.9077212 },
    { lat: 48.688266, lng: 17.9077776 },
    { lat: 48.6884254, lng: 17.9083077 },
    { lat: 48.6884232, lng: 17.9093783 },
    { lat: 48.6883902, lng: 17.9099384 },
    { lat: 48.6885457, lng: 17.9105344 },
    { lat: 48.6887392, lng: 17.9108258 },
    { lat: 48.6889528, lng: 17.9110542 },
    { lat: 48.6894728, lng: 17.9140371 },
    { lat: 48.6896032, lng: 17.9145137 },
    { lat: 48.6896792, lng: 17.9149181 },
    { lat: 48.6903888, lng: 17.9176908 },
    { lat: 48.6886349, lng: 17.9208758 },
    { lat: 48.6884548, lng: 17.922013 },
    { lat: 48.6878525, lng: 17.9218153 },
    { lat: 48.6856314, lng: 17.9227615 },
    { lat: 48.6841192, lng: 17.9239322 },
    { lat: 48.6836117, lng: 17.924486 },
    { lat: 48.6824455, lng: 17.9243894 },
    { lat: 48.682448, lng: 17.9246006 },
    { lat: 48.6833702, lng: 17.9253346 },
    { lat: 48.6829835, lng: 17.9264905 },
    { lat: 48.6821216, lng: 17.9281751 },
    { lat: 48.6812914, lng: 17.9305957 },
    { lat: 48.6806301, lng: 17.9314451 },
    { lat: 48.6804918, lng: 17.9315837 },
    { lat: 48.6802039, lng: 17.932112 },
    { lat: 48.6802353, lng: 17.9339169 },
    { lat: 48.6802666, lng: 17.9357972 },
    { lat: 48.6811177, lng: 17.937024 },
    { lat: 48.6817626, lng: 17.9391238 },
    { lat: 48.6817207, lng: 17.9402336 },
    { lat: 48.6816536, lng: 17.9405267 },
    { lat: 48.6814767, lng: 17.9409281 },
    { lat: 48.6814301, lng: 17.9411334 },
    { lat: 48.6811172, lng: 17.94145 },
    { lat: 48.6809824, lng: 17.9417617 },
    { lat: 48.6806972, lng: 17.9418824 },
    { lat: 48.6806051, lng: 17.9420578 },
    { lat: 48.680537, lng: 17.9423904 },
    { lat: 48.6805081, lng: 17.9424379 },
    { lat: 48.6804227, lng: 17.9424613 },
    { lat: 48.6798924, lng: 17.9423497 },
    { lat: 48.679737, lng: 17.9423377 },
    { lat: 48.6796584, lng: 17.9423091 },
    { lat: 48.679482, lng: 17.9424361 },
    { lat: 48.6794116, lng: 17.9426561 },
    { lat: 48.6792256, lng: 17.942979 },
    { lat: 48.6790539, lng: 17.9438457 },
    { lat: 48.6788799, lng: 17.9443123 },
    { lat: 48.6787555, lng: 17.9445196 },
    { lat: 48.6783005, lng: 17.9447821 },
    { lat: 48.6780743, lng: 17.9449826 },
    { lat: 48.6762318, lng: 17.9450106 },
    { lat: 48.6757246, lng: 17.9451751 },
    { lat: 48.6750123, lng: 17.9447786 },
    { lat: 48.6746876, lng: 17.9442722 },
    { lat: 48.6740921, lng: 17.9438862 },
    { lat: 48.6739499, lng: 17.943926 },
    { lat: 48.6732207, lng: 17.9440368 },
    { lat: 48.6731435, lng: 17.946352 },
    { lat: 48.6732475, lng: 17.9466553 },
    { lat: 48.6735735, lng: 17.9467676 },
    { lat: 48.6737183, lng: 17.9469236 },
    { lat: 48.6741281, lng: 17.947111 },
    { lat: 48.6740038, lng: 17.9474129 },
    { lat: 48.6739075, lng: 17.9476142 },
    { lat: 48.6736577, lng: 17.9481754 },
    { lat: 48.6739489, lng: 17.9488729 },
    { lat: 48.6738609, lng: 17.9492324 },
    { lat: 48.6737392, lng: 17.949405 },
    { lat: 48.6730519, lng: 17.9484847 },
    { lat: 48.6730326, lng: 17.9532801 },
    { lat: 48.6730105, lng: 17.9564616 },
    { lat: 48.6729824, lng: 17.9577696 },
    { lat: 48.6729003, lng: 17.9617456 },
    { lat: 48.6729109, lng: 17.9619228 },
    { lat: 48.6729214, lng: 17.9620995 },
    { lat: 48.6729252, lng: 17.9636018 },
    { lat: 48.6729086, lng: 17.9645529 },
    { lat: 48.6729042, lng: 17.9660895 },
    { lat: 48.6728938, lng: 17.9668602 },
    { lat: 48.6728537, lng: 17.9701633 },
    { lat: 48.6728319, lng: 17.9717319 },
    { lat: 48.6732248, lng: 17.9719417 },
    { lat: 48.6735442, lng: 17.9721494 },
    { lat: 48.6736754, lng: 17.9724741 },
    { lat: 48.6734397, lng: 17.9726878 },
    { lat: 48.6733206, lng: 17.9726769 },
    { lat: 48.6729545, lng: 17.9731458 },
    { lat: 48.6730961, lng: 17.9732677 },
    { lat: 48.6731986, lng: 17.9732056 },
    { lat: 48.6733918, lng: 17.97342 },
    { lat: 48.6732598, lng: 17.9736661 },
    { lat: 48.6732536, lng: 17.9738673 },
    { lat: 48.6730149, lng: 17.9741401 },
    { lat: 48.6728637, lng: 17.9741599 },
    { lat: 48.6728275, lng: 17.9741987 },
    { lat: 48.672806, lng: 17.9744613 },
    { lat: 48.6728766, lng: 17.9745467 },
    { lat: 48.6728823, lng: 17.9747938 },
    { lat: 48.6731607, lng: 17.974806 },
    { lat: 48.6731778, lng: 17.9748865 },
    { lat: 48.6729959, lng: 17.975244 },
    { lat: 48.6729581, lng: 17.9754038 },
    { lat: 48.6731131, lng: 17.9755231 },
    { lat: 48.6732485, lng: 17.9757519 },
    { lat: 48.6732585, lng: 17.9758808 },
    { lat: 48.6732401, lng: 17.9760833 },
    { lat: 48.6732317, lng: 17.9763045 },
    { lat: 48.6731816, lng: 17.9762973 },
    { lat: 48.6731358, lng: 17.9761918 },
    { lat: 48.673139, lng: 17.9758267 },
    { lat: 48.6730856, lng: 17.9757243 },
    { lat: 48.6730251, lng: 17.9757147 },
    { lat: 48.6728726, lng: 17.9755404 },
    { lat: 48.6727838, lng: 17.9755457 },
    { lat: 48.6727503, lng: 17.9776411 },
    { lat: 48.6727417, lng: 17.9788608 },
    { lat: 48.6728385, lng: 17.9789134 },
    { lat: 48.6729806, lng: 17.978797 },
    { lat: 48.6729786, lng: 17.9784615 },
    { lat: 48.6731731, lng: 17.9783719 },
    { lat: 48.673353, lng: 17.9783975 },
    { lat: 48.6735011, lng: 17.9783821 },
    { lat: 48.6736262, lng: 17.9784572 },
    { lat: 48.6737178, lng: 17.9785348 },
    { lat: 48.6737352, lng: 17.9788471 },
    { lat: 48.6737228, lng: 17.9792616 },
    { lat: 48.6736944, lng: 17.9794694 },
    { lat: 48.6737116, lng: 17.979627 },
    { lat: 48.6738176, lng: 17.9800051 },
    { lat: 48.6740008, lng: 17.9802567 },
    { lat: 48.6740581, lng: 17.980291 },
    { lat: 48.674009, lng: 17.980507 },
    { lat: 48.6738761, lng: 17.9806068 },
    { lat: 48.6738836, lng: 17.9807119 },
    { lat: 48.6737982, lng: 17.9807395 },
    { lat: 48.6736207, lng: 17.9806789 },
    { lat: 48.6740971, lng: 17.9814586 },
    { lat: 48.6742963, lng: 17.9817701 },
    { lat: 48.6743489, lng: 17.982619 },
    { lat: 48.6744916, lng: 17.9847521 },
    { lat: 48.6745205, lng: 17.9851981 },
    { lat: 48.6745809, lng: 17.9848853 },
    { lat: 48.6747266, lng: 17.9848302 },
    { lat: 48.6748103, lng: 17.9847578 },
    { lat: 48.6752158, lng: 17.984135 },
    { lat: 48.6752026, lng: 17.9840646 },
    { lat: 48.6753137, lng: 17.9839039 },
    { lat: 48.6753816, lng: 17.9836779 },
    { lat: 48.6754907, lng: 17.9837099 },
    { lat: 48.6756941, lng: 17.9836741 },
    { lat: 48.6759141, lng: 17.9835993 },
    { lat: 48.6760094, lng: 17.9836278 },
    { lat: 48.6761987, lng: 17.983601 },
    { lat: 48.6764419, lng: 17.9833077 },
    { lat: 48.6765336, lng: 17.9831781 },
    { lat: 48.6767071, lng: 17.9831728 },
    { lat: 48.6770556, lng: 17.9833977 },
    { lat: 48.6772482, lng: 17.9833909 },
    { lat: 48.6773555, lng: 17.9834288 },
    { lat: 48.6776182, lng: 17.9834593 },
    { lat: 48.6777743, lng: 17.983906 },
    { lat: 48.6779874, lng: 17.9844314 },
    { lat: 48.678224, lng: 17.984499 },
    { lat: 48.6784403, lng: 17.9848357 },
    { lat: 48.6785945, lng: 17.9851309 },
    { lat: 48.6788982, lng: 17.9853612 },
    { lat: 48.6791493, lng: 17.985634 },
    { lat: 48.6792345, lng: 17.9856831 },
    { lat: 48.679219, lng: 17.9858956 },
    { lat: 48.679097, lng: 17.9861428 },
    { lat: 48.6789658, lng: 17.9860999 },
    { lat: 48.6786975, lng: 17.9859513 },
    { lat: 48.6785905, lng: 17.985994 },
    { lat: 48.6783723, lng: 17.9859328 },
    { lat: 48.6781555, lng: 17.9858937 },
    { lat: 48.678075, lng: 17.9859046 },
    { lat: 48.6780149, lng: 17.9858791 },
    { lat: 48.677913, lng: 17.9859126 },
    { lat: 48.6777768, lng: 17.9859214 },
    { lat: 48.6775772, lng: 17.9857695 },
    { lat: 48.6772191, lng: 17.9854241 },
    { lat: 48.6771089, lng: 17.9853919 },
    { lat: 48.6769387, lng: 17.9853029 },
    { lat: 48.6766858, lng: 17.985095 },
    { lat: 48.6764995, lng: 17.9848813 },
    { lat: 48.6763438, lng: 17.9849035 },
    { lat: 48.6763261, lng: 17.9852698 },
    { lat: 48.6761731, lng: 17.9853738 },
    { lat: 48.6763886, lng: 17.9856181 },
    { lat: 48.6766355, lng: 17.9857647 },
    { lat: 48.677238, lng: 17.9867287 },
    { lat: 48.6773275, lng: 17.9867337 },
    { lat: 48.6774946, lng: 17.9869068 },
    { lat: 48.677509, lng: 17.9871295 },
    { lat: 48.6775858, lng: 17.9871523 },
    { lat: 48.6776467, lng: 17.9879298 },
    { lat: 48.6778701, lng: 17.9877384 },
    { lat: 48.6780142, lng: 17.9878618 },
    { lat: 48.6782313, lng: 17.9880806 },
    { lat: 48.6785101, lng: 17.9883064 },
    { lat: 48.6785324, lng: 17.9883994 },
    { lat: 48.6786432, lng: 17.9884019 },
    { lat: 48.6787192, lng: 17.9883302 },
    { lat: 48.6790589, lng: 17.988367 },
    { lat: 48.6791719, lng: 17.9882949 },
    { lat: 48.6792843, lng: 17.9883436 },
    { lat: 48.6794671, lng: 17.9882471 },
    { lat: 48.6796699, lng: 17.9883371 },
    { lat: 48.6798373, lng: 17.9883637 },
    { lat: 48.6800674, lng: 17.9882833 },
    { lat: 48.6802743, lng: 17.9879638 },
    { lat: 48.6806972, lng: 17.9872007 },
    { lat: 48.6808315, lng: 17.9872961 },
    { lat: 48.681239, lng: 17.9866708 },
    { lat: 48.6812534, lng: 17.9865703 },
    { lat: 48.6813936, lng: 17.9864423 },
    { lat: 48.681619, lng: 17.9861703 },
    { lat: 48.6818308, lng: 17.9859895 },
    { lat: 48.6822807, lng: 17.9853184 },
    { lat: 48.6823942, lng: 17.9847055 },
    { lat: 48.6823996, lng: 17.9843379 },
    { lat: 48.6823922, lng: 17.9836656 },
    { lat: 48.6824113, lng: 17.9835287 },
    { lat: 48.6825542, lng: 17.983275 },
    { lat: 48.6826496, lng: 17.9828299 },
    { lat: 48.682994, lng: 17.9826603 },
    { lat: 48.6832529, lng: 17.9824195 },
    { lat: 48.6833757, lng: 17.9825685 },
    { lat: 48.6834684, lng: 17.9827978 },
    { lat: 48.6836072, lng: 17.9829322 },
    { lat: 48.6837522, lng: 17.9830365 },
    { lat: 48.6843145, lng: 17.9828309 },
    { lat: 48.6843402, lng: 17.9833947 },
    { lat: 48.6841354, lng: 17.9835032 },
    { lat: 48.6840817, lng: 17.9837376 },
    { lat: 48.6836642, lng: 17.9837207 },
    { lat: 48.6834686, lng: 17.9837879 },
    { lat: 48.6833135, lng: 17.9839525 },
    { lat: 48.6833829, lng: 17.9841339 },
    { lat: 48.6832627, lng: 17.9843516 },
    { lat: 48.6831553, lng: 17.9846891 },
    { lat: 48.683084, lng: 17.9849538 },
    { lat: 48.682937, lng: 17.9852541 },
    { lat: 48.6828943, lng: 17.9854463 },
    { lat: 48.6828397, lng: 17.9856098 },
    { lat: 48.682801, lng: 17.9858865 },
    { lat: 48.6826838, lng: 17.9863868 },
    { lat: 48.682616, lng: 17.9864235 },
    { lat: 48.6826112, lng: 17.9866252 },
    { lat: 48.6823034, lng: 17.9867266 },
    { lat: 48.682203, lng: 17.9870116 },
    { lat: 48.6823084, lng: 17.9870777 },
    { lat: 48.6823927, lng: 17.9870716 },
    { lat: 48.6825173, lng: 17.9871544 },
    { lat: 48.6825807, lng: 17.9877838 },
    { lat: 48.682641, lng: 17.9879817 },
    { lat: 48.6823193, lng: 17.9882085 },
    { lat: 48.6819057, lng: 17.9886753 },
    { lat: 48.6814672, lng: 17.9889905 },
    { lat: 48.6812025, lng: 17.9893042 },
    { lat: 48.6810624, lng: 17.9897014 },
    { lat: 48.6810201, lng: 17.9899954 },
    { lat: 48.6810359, lng: 17.9900711 },
    { lat: 48.6809287, lng: 17.9905835 },
    { lat: 48.6809713, lng: 17.9908467 },
    { lat: 48.6810509, lng: 17.9909692 },
    { lat: 48.6809317, lng: 17.9911882 },
    { lat: 48.6808894, lng: 17.9913271 },
    { lat: 48.6810268, lng: 17.99157 },
    { lat: 48.6811304, lng: 17.9919849 },
    { lat: 48.6813123, lng: 17.992256 },
    { lat: 48.6813701, lng: 17.9924286 },
    { lat: 48.6808597, lng: 17.9932056 },
    { lat: 48.6809447, lng: 17.9934384 },
    { lat: 48.6808555, lng: 17.9936094 },
    { lat: 48.681191, lng: 17.9934786 },
    { lat: 48.6820245, lng: 17.992595 },
    { lat: 48.6821961, lng: 17.9925781 },
    { lat: 48.6827085, lng: 17.9925198 },
    { lat: 48.6835173, lng: 17.9927018 },
    { lat: 48.6841424, lng: 17.9926804 },
    { lat: 48.684536, lng: 17.9928085 },
    { lat: 48.6855808, lng: 17.9937708 },
    { lat: 48.6864274, lng: 17.9942116 },
    { lat: 48.686553, lng: 17.9943303 },
    { lat: 48.6867214, lng: 17.9943154 },
    { lat: 48.6867196, lng: 17.9944564 },
    { lat: 48.6869435, lng: 17.994579 },
    { lat: 48.6875647, lng: 17.9950789 },
    { lat: 48.6876859, lng: 17.9952197 },
    { lat: 48.6876738, lng: 17.9953192 },
    { lat: 48.6878662, lng: 17.9957468 },
    { lat: 48.6881823, lng: 17.9960527 },
    { lat: 48.6883326, lng: 17.9963763 },
    { lat: 48.6883959, lng: 17.9964385 },
    { lat: 48.6889532, lng: 17.9964531 },
    { lat: 48.6892238, lng: 17.9963339 },
    { lat: 48.6892743, lng: 17.9964632 },
    { lat: 48.689401, lng: 17.996666 },
    { lat: 48.689503, lng: 17.996562 },
    { lat: 48.690584, lng: 17.996266 },
    { lat: 48.690821, lng: 17.996095 },
    { lat: 48.690945, lng: 17.995946 },
    { lat: 48.691158, lng: 17.995839 },
    { lat: 48.691737, lng: 17.995238 },
    { lat: 48.692179, lng: 17.995104 },
    { lat: 48.69222, lng: 17.995078 },
    { lat: 48.692399, lng: 17.994967 },
    { lat: 48.692732, lng: 17.994764 },
    { lat: 48.693111, lng: 17.994399 },
    { lat: 48.69331, lng: 17.994104 },
    { lat: 48.693519, lng: 17.994094 },
    { lat: 48.693807, lng: 17.994356 },
    { lat: 48.693985, lng: 17.994441 },
    { lat: 48.694168, lng: 17.994319 },
    { lat: 48.694501, lng: 17.994253 },
    { lat: 48.694728, lng: 17.994455 },
    { lat: 48.694888, lng: 17.994466 },
    { lat: 48.695032, lng: 17.994383 },
    { lat: 48.695014, lng: 17.993767 },
    { lat: 48.695046, lng: 17.993508 },
    { lat: 48.695174, lng: 17.993293 },
    { lat: 48.695442, lng: 17.993204 },
    { lat: 48.695719, lng: 17.993052 },
    { lat: 48.696063, lng: 17.993392 },
    { lat: 48.696255, lng: 17.993339 },
    { lat: 48.696412, lng: 17.993404 },
    { lat: 48.696683, lng: 17.993613 },
    { lat: 48.697244, lng: 17.993576 },
    { lat: 48.697397, lng: 17.993585 },
    { lat: 48.697676, lng: 17.993491 },
    { lat: 48.698094, lng: 17.993589 },
    { lat: 48.698286, lng: 17.993567 },
    { lat: 48.698665, lng: 17.993332 },
    { lat: 48.698932, lng: 17.992721 },
    { lat: 48.699368, lng: 17.991967 },
    { lat: 48.699437, lng: 17.991921 },
    { lat: 48.699832, lng: 17.991738 },
    { lat: 48.700318, lng: 17.99168 },
    { lat: 48.700443, lng: 17.991618 },
    { lat: 48.700803, lng: 17.991314 },
    { lat: 48.70096, lng: 17.991394 },
    { lat: 48.701143, lng: 17.991419 },
    { lat: 48.701368, lng: 17.991494 },
    { lat: 48.701524, lng: 17.991534 },
    { lat: 48.702301, lng: 17.991563 },
    { lat: 48.702433, lng: 17.991431 },
    { lat: 48.702647, lng: 17.991389 },
    { lat: 48.703096, lng: 17.990908 },
    { lat: 48.703432, lng: 17.990873 },
    { lat: 48.703971, lng: 17.991083 },
    { lat: 48.704225, lng: 17.991109 },
    { lat: 48.70446, lng: 17.991052 },
    { lat: 48.704661, lng: 17.990957 },
    { lat: 48.70502, lng: 17.990951 },
    { lat: 48.705264, lng: 17.990845 },
    { lat: 48.705535, lng: 17.990834 },
    { lat: 48.705701, lng: 17.990918 },
    { lat: 48.705797, lng: 17.9904135 },
    { lat: 48.7052565, lng: 17.9893335 },
    { lat: 48.7044198, lng: 17.9869329 },
    { lat: 48.7037398, lng: 17.9863161 },
    { lat: 48.7036081, lng: 17.984289 },
    { lat: 48.7031607, lng: 17.9820399 },
    { lat: 48.7031371, lng: 17.9819855 },
    { lat: 48.702309, lng: 17.9800926 },
    { lat: 48.7020164, lng: 17.9788347 },
    { lat: 48.7020043, lng: 17.9787847 },
    { lat: 48.7017341, lng: 17.9776191 },
    { lat: 48.701722, lng: 17.9775672 },
    { lat: 48.7016901, lng: 17.9774282 },
    { lat: 48.7016794, lng: 17.9773827 },
    { lat: 48.7015736, lng: 17.9769311 },
    { lat: 48.7015669, lng: 17.9764223 },
    { lat: 48.7015085, lng: 17.9761987 },
    { lat: 48.7013917, lng: 17.9755065 },
    { lat: 48.701381, lng: 17.9751561 },
    { lat: 48.7012951, lng: 17.9749261 },
    { lat: 48.7012337, lng: 17.9742162 },
    { lat: 48.7010991, lng: 17.9741373 },
    { lat: 48.7009701, lng: 17.9740355 },
    { lat: 48.7007951, lng: 17.9738456 },
    { lat: 48.7007186, lng: 17.9734518 },
    { lat: 48.7005872, lng: 17.9732162 },
    { lat: 48.7003301, lng: 17.9725388 },
    { lat: 48.6997807, lng: 17.9719163 },
    { lat: 48.6996593, lng: 17.971414 },
    { lat: 48.6994265, lng: 17.9710288 },
    { lat: 48.6996268, lng: 17.9704874 },
    { lat: 48.6997485, lng: 17.9703528 },
    { lat: 48.7000039, lng: 17.9699963 },
    { lat: 48.6998165, lng: 17.9695989 },
    { lat: 48.6999201, lng: 17.9691773 },
    { lat: 48.6998087, lng: 17.9688246 },
    { lat: 48.6998524, lng: 17.9685339 },
    { lat: 48.6993035, lng: 17.9666535 },
    { lat: 48.6992528, lng: 17.9661049 },
    { lat: 48.6990939, lng: 17.9655409 },
    { lat: 48.6990792, lng: 17.9647815 },
    { lat: 48.6988277, lng: 17.9636494 },
    { lat: 48.6986206, lng: 17.9622593 },
    { lat: 48.6986126, lng: 17.9622 },
    { lat: 48.6985415, lng: 17.9617275 },
    { lat: 48.6988271, lng: 17.9559563 },
    { lat: 48.6989224, lng: 17.9558325 },
    { lat: 48.6989327, lng: 17.9557779 },
    { lat: 48.6992844, lng: 17.9551835 },
    { lat: 48.6993824, lng: 17.9543102 },
    { lat: 48.6993906, lng: 17.9542393 },
    { lat: 48.7006441, lng: 17.9438005 },
    { lat: 48.7006333, lng: 17.9437521 },
    { lat: 48.700717, lng: 17.9424708 },
    { lat: 48.7016192, lng: 17.9392102 },
    { lat: 48.7009927, lng: 17.9284636 },
    { lat: 48.7009989, lng: 17.9283025 },
    { lat: 48.7010784, lng: 17.9277833 },
    { lat: 48.7012908, lng: 17.9273535 },
    { lat: 48.7013475, lng: 17.9272457 },
    { lat: 48.7014283, lng: 17.927012 },
    { lat: 48.7014337, lng: 17.926669 },
    { lat: 48.7014236, lng: 17.9262935 },
    { lat: 48.7013722, lng: 17.925774 },
    { lat: 48.7013623, lng: 17.9253635 },
    { lat: 48.7013365, lng: 17.9251035 },
    { lat: 48.7013402, lng: 17.924939 },
    { lat: 48.7013759, lng: 17.9246697 },
    { lat: 48.7014307, lng: 17.9243404 },
    { lat: 48.7014433, lng: 17.9241545 },
    { lat: 48.7013625, lng: 17.923391 },
    { lat: 48.7013347, lng: 17.9230221 },
    { lat: 48.7012931, lng: 17.9226292 },
    { lat: 48.7012523, lng: 17.9224557 },
    { lat: 48.7012048, lng: 17.9221897 },
    { lat: 48.7012365, lng: 17.9219667 },
    { lat: 48.7013147, lng: 17.9217462 },
    { lat: 48.7013874, lng: 17.9215283 },
    { lat: 48.7016139, lng: 17.9210876 },
    { lat: 48.7017027, lng: 17.9208576 },
    { lat: 48.7017325, lng: 17.9206954 },
    { lat: 48.7017274, lng: 17.9202705 },
    { lat: 48.7017111, lng: 17.9199796 },
    { lat: 48.7016719, lng: 17.9195913 },
    { lat: 48.701661, lng: 17.9193337 },
    { lat: 48.7016742, lng: 17.9192529 },
    { lat: 48.70171, lng: 17.9190401 },
    { lat: 48.7017109, lng: 17.9190366 },
    { lat: 48.7017173, lng: 17.9185215 },
    { lat: 48.7017146, lng: 17.9181757 },
    { lat: 48.701742, lng: 17.917937 },
    { lat: 48.7018549, lng: 17.9176552 },
    { lat: 48.7019119, lng: 17.9173638 },
    { lat: 48.7021323, lng: 17.9169553 },
    { lat: 48.7023767, lng: 17.9162876 },
    { lat: 48.7025898, lng: 17.9157949 },
    { lat: 48.7026997, lng: 17.9154993 },
    { lat: 48.7028218, lng: 17.9150716 },
    { lat: 48.7030734, lng: 17.9146542 },
    { lat: 48.7032432, lng: 17.9143254 },
    { lat: 48.7033843, lng: 17.9140988 },
    { lat: 48.7035928, lng: 17.9138301 },
    { lat: 48.7045648, lng: 17.9130608 },
    { lat: 48.7047254, lng: 17.9127652 },
    { lat: 48.7047465, lng: 17.9126088 },
    { lat: 48.7038823, lng: 17.9122219 },
    { lat: 48.7037469, lng: 17.9122973 },
    { lat: 48.7035796, lng: 17.9120503 },
    { lat: 48.7037356, lng: 17.9114521 },
    { lat: 48.7038674, lng: 17.9109407 },
    { lat: 48.7050843, lng: 17.909325 },
    { lat: 48.7065757, lng: 17.9074713 },
    { lat: 48.7081369, lng: 17.9032282 },
    { lat: 48.7087612, lng: 17.8999998 },
    { lat: 48.7089316, lng: 17.899438 },
    { lat: 48.7098305, lng: 17.898318 },
    { lat: 48.711723, lng: 17.8978012 },
    { lat: 48.71424, lng: 17.8962222 },
    { lat: 48.7143108, lng: 17.8961233 },
    { lat: 48.7143465, lng: 17.8960037 },
    { lat: 48.7143404, lng: 17.8954722 },
    { lat: 48.7142926, lng: 17.8953135 },
    { lat: 48.714278, lng: 17.8951833 },
    { lat: 48.7143089, lng: 17.8947603 },
    { lat: 48.7145315, lng: 17.8944464 },
    { lat: 48.7145445, lng: 17.8851775 },
    { lat: 48.7144622, lng: 17.8851436 },
    { lat: 48.7143156, lng: 17.8852389 },
    { lat: 48.7141671, lng: 17.8853412 },
    { lat: 48.7140027, lng: 17.8855161 },
    { lat: 48.7138572, lng: 17.8857632 },
    { lat: 48.7137526, lng: 17.8860111 },
    { lat: 48.7136717, lng: 17.88658 },
    { lat: 48.7136646, lng: 17.8868921 },
    { lat: 48.7134879, lng: 17.8865642 },
    { lat: 48.7134118, lng: 17.8864066 },
    { lat: 48.7128422, lng: 17.8852347 },
    { lat: 48.7116395, lng: 17.882756 },
    { lat: 48.7115456, lng: 17.8825634 },
    { lat: 48.7106638, lng: 17.8807472 },
    { lat: 48.7106295, lng: 17.8805328 },
    { lat: 48.7101431, lng: 17.8804969 },
    { lat: 48.7098464, lng: 17.8800892 },
    { lat: 48.7095846, lng: 17.8794354 },
    { lat: 48.7096762, lng: 17.8787464 },
    { lat: 48.7096299, lng: 17.8783346 },
    { lat: 48.7094786, lng: 17.877622 },
    { lat: 48.7093171, lng: 17.8772105 },
    { lat: 48.7091935, lng: 17.8768083 },
    { lat: 48.7091245, lng: 17.8764307 },
    { lat: 48.7091245, lng: 17.8761142 },
    { lat: 48.7090701, lng: 17.8757725 },
    { lat: 48.7089565, lng: 17.874341 },
    { lat: 48.7089044, lng: 17.8735905 },
    { lat: 48.7088948, lng: 17.8725188 },
  ],
  Potvorice: [
    { lat: 48.6961828, lng: 17.8322648 },
    { lat: 48.6962405, lng: 17.8311875 },
    { lat: 48.6962424, lng: 17.831145 },
    { lat: 48.6963694, lng: 17.8287927 },
    { lat: 48.694822, lng: 17.8282453 },
    { lat: 48.694237, lng: 17.8279711 },
    { lat: 48.6941161, lng: 17.8279152 },
    { lat: 48.6936981, lng: 17.8277502 },
    { lat: 48.6923198, lng: 17.8272711 },
    { lat: 48.6906992, lng: 17.826617 },
    { lat: 48.6898104, lng: 17.8263085 },
    { lat: 48.6889977, lng: 17.8260445 },
    { lat: 48.6889609, lng: 17.8260321 },
    { lat: 48.6888767, lng: 17.826005 },
    { lat: 48.68845, lng: 17.8259017 },
    { lat: 48.6884087, lng: 17.8258924 },
    { lat: 48.6880471, lng: 17.8258134 },
    { lat: 48.6877447, lng: 17.8283976 },
    { lat: 48.6873532, lng: 17.8304781 },
    { lat: 48.6873001, lng: 17.8310535 },
    { lat: 48.6872369, lng: 17.8314807 },
    { lat: 48.6872757, lng: 17.8323985 },
    { lat: 48.6872646, lng: 17.832773 },
    { lat: 48.6872326, lng: 17.8334377 },
    { lat: 48.6871907, lng: 17.8340838 },
    { lat: 48.6872056, lng: 17.8340874 },
    { lat: 48.6868812, lng: 17.8367019 },
    { lat: 48.6868122, lng: 17.8370702 },
    { lat: 48.6865492, lng: 17.8384453 },
    { lat: 48.6863879, lng: 17.839249 },
    { lat: 48.6862044, lng: 17.8406521 },
    { lat: 48.6861036, lng: 17.8417975 },
    { lat: 48.6859533, lng: 17.8431913 },
    { lat: 48.6856929, lng: 17.8454306 },
    { lat: 48.6856548, lng: 17.8461986 },
    { lat: 48.6856305, lng: 17.8467461 },
    { lat: 48.6856195, lng: 17.847122 },
    { lat: 48.6854196, lng: 17.8473251 },
    { lat: 48.6853367, lng: 17.8482315 },
    { lat: 48.6851753, lng: 17.8499233 },
    { lat: 48.6851734, lng: 17.8499318 },
    { lat: 48.684937, lng: 17.8523617 },
    { lat: 48.6847061, lng: 17.8573826 },
    { lat: 48.6847457, lng: 17.8577948 },
    { lat: 48.6845941, lng: 17.8600643 },
    { lat: 48.6843564, lng: 17.8606123 },
    { lat: 48.6845314, lng: 17.8625544 },
    { lat: 48.6845355, lng: 17.8626571 },
    { lat: 48.6848965, lng: 17.8638112 },
    { lat: 48.684962, lng: 17.8644824 },
    { lat: 48.6849711, lng: 17.8647665 },
    { lat: 48.685075, lng: 17.8659983 },
    { lat: 48.685056, lng: 17.8668383 },
    { lat: 48.6847854, lng: 17.869535 },
    { lat: 48.6844372, lng: 17.8729983 },
    { lat: 48.6842578, lng: 17.8747778 },
    { lat: 48.683858, lng: 17.8773157 },
    { lat: 48.6837499, lng: 17.8780279 },
    { lat: 48.6837487, lng: 17.878067 },
    { lat: 48.6837088, lng: 17.8783043 },
    { lat: 48.6862794, lng: 17.8792793 },
    { lat: 48.6893585, lng: 17.8800947 },
    { lat: 48.6907071, lng: 17.8809501 },
    { lat: 48.6910935, lng: 17.8800384 },
    { lat: 48.6911123, lng: 17.8798636 },
    { lat: 48.6912828, lng: 17.8787614 },
    { lat: 48.6915607, lng: 17.8788196 },
    { lat: 48.6916756, lng: 17.8788545 },
    { lat: 48.6918112, lng: 17.8788738 },
    { lat: 48.6923127, lng: 17.8787489 },
    { lat: 48.6922798, lng: 17.8784673 },
    { lat: 48.6923507, lng: 17.8779023 },
    { lat: 48.6929047, lng: 17.8745874 },
    { lat: 48.6931262, lng: 17.8727407 },
    { lat: 48.6933663, lng: 17.8707752 },
    { lat: 48.6935361, lng: 17.8693262 },
    { lat: 48.6935924, lng: 17.8688378 },
    { lat: 48.6938907, lng: 17.8657817 },
    { lat: 48.6942593, lng: 17.8659215 },
    { lat: 48.694463, lng: 17.86348 },
    { lat: 48.6944698, lng: 17.8634062 },
    { lat: 48.6945534, lng: 17.8623974 },
    { lat: 48.6945579, lng: 17.8623417 },
    { lat: 48.6945636, lng: 17.8622859 },
    { lat: 48.6946276, lng: 17.861513 },
    { lat: 48.695007, lng: 17.8561698 },
    { lat: 48.6950115, lng: 17.8561145 },
    { lat: 48.6950321, lng: 17.8558199 },
    { lat: 48.695303, lng: 17.852141 },
    { lat: 48.695407, lng: 17.8491414 },
    { lat: 48.6954159, lng: 17.8487103 },
    { lat: 48.6954174, lng: 17.8486403 },
    { lat: 48.6954194, lng: 17.8485435 },
    { lat: 48.6954208, lng: 17.8484545 },
    { lat: 48.6954276, lng: 17.8481012 },
    { lat: 48.6955164, lng: 17.8463048 },
    { lat: 48.6956005, lng: 17.8446174 },
    { lat: 48.6956083, lng: 17.8442606 },
    { lat: 48.6956125, lng: 17.8441823 },
    { lat: 48.6956152, lng: 17.8441325 },
    { lat: 48.6957086, lng: 17.8434756 },
    { lat: 48.6957608, lng: 17.8416152 },
    { lat: 48.6958699, lng: 17.8376982 },
    { lat: 48.6962098, lng: 17.8341412 },
    { lat: 48.6961828, lng: 17.8322648 },
  ],
  Očkov: [
    { lat: 48.651279, lng: 17.744627 },
    { lat: 48.651125, lng: 17.745296 },
    { lat: 48.651112, lng: 17.745729 },
    { lat: 48.651165, lng: 17.745916 },
    { lat: 48.651219, lng: 17.746363 },
    { lat: 48.651237, lng: 17.7469 },
    { lat: 48.651211, lng: 17.74712 },
    { lat: 48.65121, lng: 17.747315 },
    { lat: 48.65111, lng: 17.748022 },
    { lat: 48.650904, lng: 17.749103 },
    { lat: 48.650866, lng: 17.74932 },
    { lat: 48.650806, lng: 17.749593 },
    { lat: 48.650426, lng: 17.750776 },
    { lat: 48.650373, lng: 17.751023 },
    { lat: 48.65027, lng: 17.751381 },
    { lat: 48.650121, lng: 17.751809 },
    { lat: 48.649842, lng: 17.75264 },
    { lat: 48.649712, lng: 17.752922 },
    { lat: 48.649489, lng: 17.753406 },
    { lat: 48.649402, lng: 17.753599 },
    { lat: 48.64922, lng: 17.753825 },
    { lat: 48.64892, lng: 17.754237 },
    { lat: 48.648534, lng: 17.754815 },
    { lat: 48.648333, lng: 17.755094 },
    { lat: 48.647781, lng: 17.755807 },
    { lat: 48.647553, lng: 17.756143 },
    { lat: 48.647415, lng: 17.756378 },
    { lat: 48.64731, lng: 17.756607 },
    { lat: 48.647068, lng: 17.757248 },
    { lat: 48.646833, lng: 17.757814 },
    { lat: 48.646722, lng: 17.758024 },
    { lat: 48.64663, lng: 17.758189 },
    { lat: 48.646553, lng: 17.758351 },
    { lat: 48.646526, lng: 17.758536 },
    { lat: 48.646509, lng: 17.758829 },
    { lat: 48.646167, lng: 17.759771 },
    { lat: 48.645996, lng: 17.76042 },
    { lat: 48.645934, lng: 17.760655 },
    { lat: 48.645559, lng: 17.761759 },
    { lat: 48.645032, lng: 17.76356 },
    { lat: 48.644734, lng: 17.764819 },
    { lat: 48.644722, lng: 17.764866 },
    { lat: 48.644605, lng: 17.764867 },
    { lat: 48.644588, lng: 17.764935 },
    { lat: 48.644556, lng: 17.764995 },
    { lat: 48.643988, lng: 17.767337 },
    { lat: 48.643907, lng: 17.767665 },
    { lat: 48.643157, lng: 17.770759 },
    { lat: 48.641357, lng: 17.778097 },
    { lat: 48.641233, lng: 17.778603 },
    { lat: 48.641193, lng: 17.778747 },
    { lat: 48.641084, lng: 17.779137 },
    { lat: 48.640955, lng: 17.779445 },
    { lat: 48.640682, lng: 17.780021 },
    { lat: 48.640515, lng: 17.780518 },
    { lat: 48.640491, lng: 17.780651 },
    { lat: 48.640497, lng: 17.781073 },
    { lat: 48.640552, lng: 17.781285 },
    { lat: 48.640598, lng: 17.781468 },
    { lat: 48.6408414, lng: 17.7815443 },
    { lat: 48.6412084, lng: 17.7817112 },
    { lat: 48.641276, lng: 17.7818217 },
    { lat: 48.6414251, lng: 17.7820233 },
    { lat: 48.6415889, lng: 17.7820647 },
    { lat: 48.6417374, lng: 17.7816117 },
    { lat: 48.6418476, lng: 17.7812557 },
    { lat: 48.6419506, lng: 17.7809472 },
    { lat: 48.6422898, lng: 17.7812668 },
    { lat: 48.6427553, lng: 17.7817184 },
    { lat: 48.6432224, lng: 17.7809197 },
    { lat: 48.6433841, lng: 17.7810538 },
    { lat: 48.6435955, lng: 17.7812417 },
    { lat: 48.6437645, lng: 17.7814016 },
    { lat: 48.6440003, lng: 17.7815847 },
    { lat: 48.6442776, lng: 17.7817588 },
    { lat: 48.6443938, lng: 17.7818872 },
    { lat: 48.6447373, lng: 17.7823155 },
    { lat: 48.644909, lng: 17.7826321 },
    { lat: 48.6450879, lng: 17.7829723 },
    { lat: 48.6451922, lng: 17.7832734 },
    { lat: 48.6451953, lng: 17.7833629 },
    { lat: 48.6453296, lng: 17.7833424 },
    { lat: 48.6455232, lng: 17.7832961 },
    { lat: 48.6458687, lng: 17.7833525 },
    { lat: 48.6461335, lng: 17.7836012 },
    { lat: 48.6462912, lng: 17.7836488 },
    { lat: 48.6464399, lng: 17.7836284 },
    { lat: 48.6465264, lng: 17.7835117 },
    { lat: 48.6466434, lng: 17.7833944 },
    { lat: 48.6467552, lng: 17.7833188 },
    { lat: 48.6470143, lng: 17.7832191 },
    { lat: 48.6471448, lng: 17.782971 },
    { lat: 48.6475035, lng: 17.7820059 },
    { lat: 48.6476517, lng: 17.7819773 },
    { lat: 48.6478248, lng: 17.7819068 },
    { lat: 48.6479875, lng: 17.7817231 },
    { lat: 48.6481385, lng: 17.7814813 },
    { lat: 48.6483098, lng: 17.7811615 },
    { lat: 48.648458, lng: 17.7808737 },
    { lat: 48.6486012, lng: 17.780632 },
    { lat: 48.6488081, lng: 17.7803404 },
    { lat: 48.6490615, lng: 17.7799626 },
    { lat: 48.6490776, lng: 17.7799322 },
    { lat: 48.6494462, lng: 17.7800314 },
    { lat: 48.6498742, lng: 17.7801987 },
    { lat: 48.6500181, lng: 17.7799483 },
    { lat: 48.6502803, lng: 17.7795868 },
    { lat: 48.6502854, lng: 17.779373 },
    { lat: 48.6502626, lng: 17.7792255 },
    { lat: 48.6502567, lng: 17.7790553 },
    { lat: 48.6501778, lng: 17.7788917 },
    { lat: 48.6500671, lng: 17.7788343 },
    { lat: 48.6502031, lng: 17.7783076 },
    { lat: 48.6505037, lng: 17.7773534 },
    { lat: 48.6506141, lng: 17.7769081 },
    { lat: 48.6507389, lng: 17.7763126 },
    { lat: 48.6508821, lng: 17.7757195 },
    { lat: 48.6509366, lng: 17.7755202 },
    { lat: 48.6512531, lng: 17.7745519 },
    { lat: 48.6514101, lng: 17.7741673 },
    { lat: 48.6515081, lng: 17.7738868 },
    { lat: 48.6515549, lng: 17.7736936 },
    { lat: 48.6515544, lng: 17.7736129 },
    { lat: 48.6519585, lng: 17.7725978 },
    { lat: 48.6520148, lng: 17.772375 },
    { lat: 48.6521305, lng: 17.7721235 },
    { lat: 48.6523148, lng: 17.7718935 },
    { lat: 48.6523973, lng: 17.771707 },
    { lat: 48.6524575, lng: 17.771624 },
    { lat: 48.6525708, lng: 17.7716656 },
    { lat: 48.6526622, lng: 17.7717935 },
    { lat: 48.6528545, lng: 17.7719834 },
    { lat: 48.6529605, lng: 17.7721296 },
    { lat: 48.6531092, lng: 17.7722363 },
    { lat: 48.6531777, lng: 17.7720061 },
    { lat: 48.6533546, lng: 17.7718014 },
    { lat: 48.6535494, lng: 17.7715135 },
    { lat: 48.6537769, lng: 17.7711178 },
    { lat: 48.654201, lng: 17.7714635 },
    { lat: 48.6553569, lng: 17.770115 },
    { lat: 48.6556937, lng: 17.7691834 },
    { lat: 48.6557901, lng: 17.7688578 },
    { lat: 48.6558981, lng: 17.7685978 },
    { lat: 48.6560128, lng: 17.7682396 },
    { lat: 48.6561694, lng: 17.7675721 },
    { lat: 48.6562411, lng: 17.767341 },
    { lat: 48.6563413, lng: 17.7670996 },
    { lat: 48.656423, lng: 17.7668667 },
    { lat: 48.657411, lng: 17.7641657 },
    { lat: 48.6574897, lng: 17.7639764 },
    { lat: 48.6576856, lng: 17.7636187 },
    { lat: 48.6577788, lng: 17.7634985 },
    { lat: 48.658235, lng: 17.7630219 },
    { lat: 48.6587536, lng: 17.7623865 },
    { lat: 48.659047, lng: 17.7619601 },
    { lat: 48.6592539, lng: 17.7616148 },
    { lat: 48.6596641, lng: 17.760776 },
    { lat: 48.6599579, lng: 17.7600824 },
    { lat: 48.6601143, lng: 17.7595769 },
    { lat: 48.660141, lng: 17.7594996 },
    { lat: 48.6601555, lng: 17.7594212 },
    { lat: 48.6601935, lng: 17.7593805 },
    { lat: 48.6602456, lng: 17.7589422 },
    { lat: 48.6602763, lng: 17.7585218 },
    { lat: 48.6602591, lng: 17.7583532 },
    { lat: 48.6604553, lng: 17.7579082 },
    { lat: 48.6605957, lng: 17.7579325 },
    { lat: 48.6609107, lng: 17.756738 },
    { lat: 48.6610738, lng: 17.7560117 },
    { lat: 48.661183, lng: 17.7555685 },
    { lat: 48.6613369, lng: 17.755058 },
    { lat: 48.6614117, lng: 17.7547515 },
    { lat: 48.6614767, lng: 17.7545967 },
    { lat: 48.6616596, lng: 17.7542114 },
    { lat: 48.6618194, lng: 17.7538908 },
    { lat: 48.6620447, lng: 17.7534039 },
    { lat: 48.6622642, lng: 17.7528253 },
    { lat: 48.6623801, lng: 17.7522669 },
    { lat: 48.6624466, lng: 17.7519915 },
    { lat: 48.6627325, lng: 17.751315 },
    { lat: 48.6630673, lng: 17.7500973 },
    { lat: 48.6637142, lng: 17.7481534 },
    { lat: 48.6638578, lng: 17.7478078 },
    { lat: 48.6643573, lng: 17.7468249 },
    { lat: 48.6647224, lng: 17.7460983 },
    { lat: 48.6650078, lng: 17.7454321 },
    { lat: 48.6652145, lng: 17.7448463 },
    { lat: 48.6653006, lng: 17.7445224 },
    { lat: 48.6653214, lng: 17.7439088 },
    { lat: 48.6653215, lng: 17.7434673 },
    { lat: 48.6653956, lng: 17.742984 },
    { lat: 48.6655339, lng: 17.7425371 },
    { lat: 48.6656222, lng: 17.7423393 },
    { lat: 48.665704, lng: 17.7420878 },
    { lat: 48.6659465, lng: 17.7414812 },
    { lat: 48.6660392, lng: 17.7412996 },
    { lat: 48.6661407, lng: 17.7409853 },
    { lat: 48.66619, lng: 17.7407069 },
    { lat: 48.6662603, lng: 17.7403421 },
    { lat: 48.6663678, lng: 17.7399091 },
    { lat: 48.6664877, lng: 17.7396172 },
    { lat: 48.666645, lng: 17.7390754 },
    { lat: 48.6667675, lng: 17.7386975 },
    { lat: 48.6668977, lng: 17.7383917 },
    { lat: 48.6670395, lng: 17.7379638 },
    { lat: 48.6671571, lng: 17.7375115 },
    { lat: 48.6673982, lng: 17.7368058 },
    { lat: 48.6675564, lng: 17.7363657 },
    { lat: 48.6678621, lng: 17.7356691 },
    { lat: 48.6679371, lng: 17.735511 },
    { lat: 48.6680498, lng: 17.7351591 },
    { lat: 48.6682398, lng: 17.7346537 },
    { lat: 48.6685271, lng: 17.7335618 },
    { lat: 48.668646, lng: 17.7329453 },
    { lat: 48.6687148, lng: 17.7326349 },
    { lat: 48.6690378, lng: 17.731374 },
    { lat: 48.6691969, lng: 17.7308386 },
    { lat: 48.6694615, lng: 17.7301885 },
    { lat: 48.6695489, lng: 17.7300672 },
    { lat: 48.6697223, lng: 17.7298739 },
    { lat: 48.6698343, lng: 17.7297338 },
    { lat: 48.670028, lng: 17.7295409 },
    { lat: 48.6700335, lng: 17.7295387 },
    { lat: 48.6699249, lng: 17.7294457 },
    { lat: 48.6687775, lng: 17.7291099 },
    { lat: 48.6686699, lng: 17.7290689 },
    { lat: 48.668174, lng: 17.728716 },
    { lat: 48.667979, lng: 17.728703 },
    { lat: 48.667628, lng: 17.728744 },
    { lat: 48.667012, lng: 17.728857 },
    { lat: 48.666499, lng: 17.72893 },
    { lat: 48.665745, lng: 17.729064 },
    { lat: 48.664493, lng: 17.729437 },
    { lat: 48.664269, lng: 17.729402 },
    { lat: 48.664046, lng: 17.72941 },
    { lat: 48.663804, lng: 17.729457 },
    { lat: 48.663619, lng: 17.729622 },
    { lat: 48.663373, lng: 17.729705 },
    { lat: 48.66279, lng: 17.729588 },
    { lat: 48.662535, lng: 17.729655 },
    { lat: 48.662246, lng: 17.72981 },
    { lat: 48.661928, lng: 17.730066 },
    { lat: 48.661743, lng: 17.730156 },
    { lat: 48.661619, lng: 17.730263 },
    { lat: 48.661472, lng: 17.730455 },
    { lat: 48.661214, lng: 17.730791 },
    { lat: 48.661009, lng: 17.731206 },
    { lat: 48.66065, lng: 17.731672 },
    { lat: 48.660299, lng: 17.732009 },
    { lat: 48.660138, lng: 17.732222 },
    { lat: 48.659742, lng: 17.732871 },
    { lat: 48.659141, lng: 17.733493 },
    { lat: 48.659092, lng: 17.733571 },
    { lat: 48.658849, lng: 17.734164 },
    { lat: 48.658612, lng: 17.734551 },
    { lat: 48.658487, lng: 17.734736 },
    { lat: 48.657901, lng: 17.735473 },
    { lat: 48.657861, lng: 17.735549 },
    { lat: 48.65767, lng: 17.736108 },
    { lat: 48.657289, lng: 17.736828 },
    { lat: 48.657066, lng: 17.737197 },
    { lat: 48.656678, lng: 17.7377 },
    { lat: 48.656279, lng: 17.738102 },
    { lat: 48.656145, lng: 17.73827 },
    { lat: 48.655863, lng: 17.738766 },
    { lat: 48.655671, lng: 17.739046 },
    { lat: 48.655487, lng: 17.73933 },
    { lat: 48.655014, lng: 17.740158 },
    { lat: 48.654716, lng: 17.740664 },
    { lat: 48.654574, lng: 17.740917 },
    { lat: 48.654482, lng: 17.741101 },
    { lat: 48.654424, lng: 17.741144 },
    { lat: 48.654367, lng: 17.741178 },
    { lat: 48.654302, lng: 17.741148 },
    { lat: 48.654163, lng: 17.741009 },
    { lat: 48.653925, lng: 17.740687 },
    { lat: 48.653803, lng: 17.740652 },
    { lat: 48.653703, lng: 17.740715 },
    { lat: 48.653634, lng: 17.740775 },
    { lat: 48.652552, lng: 17.742292 },
    { lat: 48.652325, lng: 17.742619 },
    { lat: 48.651892, lng: 17.743346 },
    { lat: 48.651764, lng: 17.743582 },
    { lat: 48.651465, lng: 17.744158 },
    { lat: 48.651279, lng: 17.744627 },
  ],
  Brunovce: [
    { lat: 48.6880471, lng: 17.8258134 },
    { lat: 48.6879897, lng: 17.8258009 },
    { lat: 48.6864426, lng: 17.8254084 },
    { lat: 48.6856953, lng: 17.8252173 },
    { lat: 48.6846094, lng: 17.8248632 },
    { lat: 48.6844775, lng: 17.8253147 },
    { lat: 48.6840856, lng: 17.8252044 },
    { lat: 48.683883, lng: 17.8251453 },
    { lat: 48.6830467, lng: 17.8249009 },
    { lat: 48.682812, lng: 17.824832 },
    { lat: 48.6824758, lng: 17.8246909 },
    { lat: 48.6821725, lng: 17.8245551 },
    { lat: 48.6813941, lng: 17.8241124 },
    { lat: 48.680995, lng: 17.8238108 },
    { lat: 48.6810573, lng: 17.8234426 },
    { lat: 48.6801654, lng: 17.8229533 },
    { lat: 48.680135, lng: 17.8229365 },
    { lat: 48.6798719, lng: 17.8227922 },
    { lat: 48.6795136, lng: 17.8226039 },
    { lat: 48.6784663, lng: 17.8219576 },
    { lat: 48.6772282, lng: 17.821665 },
    { lat: 48.6767256, lng: 17.8215944 },
    { lat: 48.6766232, lng: 17.821565 },
    { lat: 48.6756493, lng: 17.8212887 },
    { lat: 48.6749663, lng: 17.8215955 },
    { lat: 48.6750008, lng: 17.8218474 },
    { lat: 48.6747349, lng: 17.822028 },
    { lat: 48.6744681, lng: 17.8223035 },
    { lat: 48.6746161, lng: 17.8222847 },
    { lat: 48.6747017, lng: 17.8223735 },
    { lat: 48.674742, lng: 17.8224397 },
    { lat: 48.6747384, lng: 17.8224595 },
    { lat: 48.6751939, lng: 17.8233189 },
    { lat: 48.6753117, lng: 17.8234362 },
    { lat: 48.6754212, lng: 17.8236771 },
    { lat: 48.675463, lng: 17.823881 },
    { lat: 48.6754283, lng: 17.8243137 },
    { lat: 48.6754244, lng: 17.8244514 },
    { lat: 48.6753275, lng: 17.8249196 },
    { lat: 48.6750353, lng: 17.8255184 },
    { lat: 48.6748012, lng: 17.825901 },
    { lat: 48.6743889, lng: 17.8290707 },
    { lat: 48.6743194, lng: 17.8293004 },
    { lat: 48.6742005, lng: 17.8297419 },
    { lat: 48.6741537, lng: 17.830046 },
    { lat: 48.6742279, lng: 17.8302464 },
    { lat: 48.6745206, lng: 17.830493 },
    { lat: 48.6751927, lng: 17.8306299 },
    { lat: 48.6750856, lng: 17.8323975 },
    { lat: 48.6748146, lng: 17.8345671 },
    { lat: 48.6746879, lng: 17.8356773 },
    { lat: 48.6743867, lng: 17.8376893 },
    { lat: 48.6741464, lng: 17.8392717 },
    { lat: 48.6740034, lng: 17.8401691 },
    { lat: 48.6736722, lng: 17.8414433 },
    { lat: 48.6730056, lng: 17.843684 },
    { lat: 48.6728016, lng: 17.8444344 },
    { lat: 48.672601, lng: 17.8452776 },
    { lat: 48.6725173, lng: 17.8454999 },
    { lat: 48.6724337, lng: 17.8459426 },
    { lat: 48.6720464, lng: 17.8478952 },
    { lat: 48.6718733, lng: 17.8487456 },
    { lat: 48.6716777, lng: 17.8497256 },
    { lat: 48.6714772, lng: 17.8507223 },
    { lat: 48.6710371, lng: 17.8524728 },
    { lat: 48.6707726, lng: 17.8535848 },
    { lat: 48.6706556, lng: 17.8543938 },
    { lat: 48.6706019, lng: 17.8550337 },
    { lat: 48.6704025, lng: 17.856232 },
    { lat: 48.6703972, lng: 17.8562452 },
    { lat: 48.6699973, lng: 17.8572405 },
    { lat: 48.6699148, lng: 17.8571826 },
    { lat: 48.6691968, lng: 17.8566814 },
    { lat: 48.6692173, lng: 17.8579893 },
    { lat: 48.6690151, lng: 17.8602786 },
    { lat: 48.6688697, lng: 17.8618662 },
    { lat: 48.6687424, lng: 17.8634562 },
    { lat: 48.6686313, lng: 17.8648294 },
    { lat: 48.668548, lng: 17.866999 },
    { lat: 48.668453, lng: 17.8690296 },
    { lat: 48.6685904, lng: 17.869936 },
    { lat: 48.6699776, lng: 17.8701405 },
    { lat: 48.6726348, lng: 17.8719847 },
    { lat: 48.67378, lng: 17.8727801 },
    { lat: 48.6744749, lng: 17.8732755 },
    { lat: 48.6756064, lng: 17.8740479 },
    { lat: 48.6757061, lng: 17.8742428 },
    { lat: 48.6758229, lng: 17.8743824 },
    { lat: 48.6764364, lng: 17.874764 },
    { lat: 48.676747, lng: 17.8750113 },
    { lat: 48.6767202, lng: 17.8752007 },
    { lat: 48.679237, lng: 17.8760531 },
    { lat: 48.6797808, lng: 17.8764012 },
    { lat: 48.6837088, lng: 17.8783043 },
    { lat: 48.6837487, lng: 17.878067 },
    { lat: 48.6837499, lng: 17.8780279 },
    { lat: 48.683858, lng: 17.8773157 },
    { lat: 48.6842578, lng: 17.8747778 },
    { lat: 48.6844372, lng: 17.8729983 },
    { lat: 48.6847854, lng: 17.869535 },
    { lat: 48.685056, lng: 17.8668383 },
    { lat: 48.685075, lng: 17.8659983 },
    { lat: 48.6849711, lng: 17.8647665 },
    { lat: 48.684962, lng: 17.8644824 },
    { lat: 48.6848965, lng: 17.8638112 },
    { lat: 48.6845355, lng: 17.8626571 },
    { lat: 48.6845314, lng: 17.8625544 },
    { lat: 48.6843564, lng: 17.8606123 },
    { lat: 48.6845941, lng: 17.8600643 },
    { lat: 48.6847457, lng: 17.8577948 },
    { lat: 48.6847061, lng: 17.8573826 },
    { lat: 48.684937, lng: 17.8523617 },
    { lat: 48.6851734, lng: 17.8499318 },
    { lat: 48.6851753, lng: 17.8499233 },
    { lat: 48.6853367, lng: 17.8482315 },
    { lat: 48.6854196, lng: 17.8473251 },
    { lat: 48.6856195, lng: 17.847122 },
    { lat: 48.6856305, lng: 17.8467461 },
    { lat: 48.6856548, lng: 17.8461986 },
    { lat: 48.6856929, lng: 17.8454306 },
    { lat: 48.6859533, lng: 17.8431913 },
    { lat: 48.6861036, lng: 17.8417975 },
    { lat: 48.6862044, lng: 17.8406521 },
    { lat: 48.6863879, lng: 17.839249 },
    { lat: 48.6865492, lng: 17.8384453 },
    { lat: 48.6868122, lng: 17.8370702 },
    { lat: 48.6868812, lng: 17.8367019 },
    { lat: 48.6872056, lng: 17.8340874 },
    { lat: 48.6871907, lng: 17.8340838 },
    { lat: 48.6872326, lng: 17.8334377 },
    { lat: 48.6872646, lng: 17.832773 },
    { lat: 48.6872757, lng: 17.8323985 },
    { lat: 48.6872369, lng: 17.8314807 },
    { lat: 48.6873001, lng: 17.8310535 },
    { lat: 48.6873532, lng: 17.8304781 },
    { lat: 48.6877447, lng: 17.8283976 },
    { lat: 48.6880471, lng: 17.8258134 },
  ],
  Častkovce: [
    { lat: 48.6713656, lng: 17.820622 },
    { lat: 48.671418, lng: 17.8205163 },
    { lat: 48.6716636, lng: 17.8201341 },
    { lat: 48.6718993, lng: 17.819811 },
    { lat: 48.6721405, lng: 17.8195411 },
    { lat: 48.6724169, lng: 17.8192925 },
    { lat: 48.6726908, lng: 17.8190962 },
    { lat: 48.67327, lng: 17.818884 },
    { lat: 48.6736189, lng: 17.8188232 },
    { lat: 48.6750641, lng: 17.8189351 },
    { lat: 48.6756896, lng: 17.8190006 },
    { lat: 48.6757237, lng: 17.8190026 },
    { lat: 48.6761428, lng: 17.8129747 },
    { lat: 48.6761311, lng: 17.8123748 },
    { lat: 48.6760862, lng: 17.8120095 },
    { lat: 48.6761164, lng: 17.8114299 },
    { lat: 48.6761874, lng: 17.8106306 },
    { lat: 48.6762012, lng: 17.8103006 },
    { lat: 48.6761996, lng: 17.8099779 },
    { lat: 48.6762183, lng: 17.8095807 },
    { lat: 48.676251, lng: 17.8092093 },
    { lat: 48.6762375, lng: 17.8089313 },
    { lat: 48.676228, lng: 17.8078142 },
    { lat: 48.6762723, lng: 17.8074264 },
    { lat: 48.6764216, lng: 17.8067076 },
    { lat: 48.6765975, lng: 17.8061466 },
    { lat: 48.6778549, lng: 17.806105 },
    { lat: 48.6808181, lng: 17.8059244 },
    { lat: 48.6846927, lng: 17.8022389 },
    { lat: 48.6843593, lng: 17.7979446 },
    { lat: 48.6845362, lng: 17.7978065 },
    { lat: 48.6851009, lng: 17.797154 },
    { lat: 48.6853762, lng: 17.7967955 },
    { lat: 48.6856729, lng: 17.796381 },
    { lat: 48.6860341, lng: 17.7958378 },
    { lat: 48.6865617, lng: 17.7949848 },
    { lat: 48.6866376, lng: 17.7949703 },
    { lat: 48.686636, lng: 17.7947219 },
    { lat: 48.686719, lng: 17.7947675 },
    { lat: 48.6871095, lng: 17.7948879 },
    { lat: 48.6873114, lng: 17.7948653 },
    { lat: 48.6875623, lng: 17.7947728 },
    { lat: 48.6879755, lng: 17.7946 },
    { lat: 48.6881676, lng: 17.7944887 },
    { lat: 48.6885675, lng: 17.7941911 },
    { lat: 48.6887323, lng: 17.7940414 },
    { lat: 48.6888701, lng: 17.79391 },
    { lat: 48.6890699, lng: 17.7936658 },
    { lat: 48.6891987, lng: 17.7934814 },
    { lat: 48.6893597, lng: 17.7932311 },
    { lat: 48.6896285, lng: 17.7928045 },
    { lat: 48.6898669, lng: 17.7923235 },
    { lat: 48.6900515, lng: 17.7919966 },
    { lat: 48.6901649, lng: 17.7917991 },
    { lat: 48.6905951, lng: 17.7912206 },
    { lat: 48.69093, lng: 17.7907842 },
    { lat: 48.6911007, lng: 17.7904699 },
    { lat: 48.6913898, lng: 17.7903763 },
    { lat: 48.6918497, lng: 17.7905784 },
    { lat: 48.6922045, lng: 17.7907447 },
    { lat: 48.6927753, lng: 17.7912267 },
    { lat: 48.6932069, lng: 17.7916521 },
    { lat: 48.6933462, lng: 17.7918055 },
    { lat: 48.6935503, lng: 17.7918538 },
    { lat: 48.6939007, lng: 17.7917985 },
    { lat: 48.6942151, lng: 17.791765 },
    { lat: 48.6946975, lng: 17.7917281 },
    { lat: 48.6949845, lng: 17.7916525 },
    { lat: 48.6950066, lng: 17.7914417 },
    { lat: 48.6950492, lng: 17.7912527 },
    { lat: 48.6950725, lng: 17.7911516 },
    { lat: 48.6950957, lng: 17.7910503 },
    { lat: 48.6951384, lng: 17.7908629 },
    { lat: 48.6951839, lng: 17.7906638 },
    { lat: 48.6952283, lng: 17.7904655 },
    { lat: 48.6952721, lng: 17.7902759 },
    { lat: 48.6953157, lng: 17.7900838 },
    { lat: 48.6953602, lng: 17.789888 },
    { lat: 48.6954057, lng: 17.7896889 },
    { lat: 48.6954474, lng: 17.7895039 },
    { lat: 48.6954913, lng: 17.7893151 },
    { lat: 48.6955145, lng: 17.7892136 },
    { lat: 48.6955366, lng: 17.7891137 },
    { lat: 48.6955831, lng: 17.7889117 },
    { lat: 48.6956249, lng: 17.7887272 },
    { lat: 48.6956684, lng: 17.7885342 },
    { lat: 48.6957131, lng: 17.7883424 },
    { lat: 48.6957558, lng: 17.7881529 },
    { lat: 48.6958005, lng: 17.787958 },
    { lat: 48.6958361, lng: 17.7878015 },
    { lat: 48.6943834, lng: 17.7865171 },
    { lat: 48.6942635, lng: 17.7864232 },
    { lat: 48.694546, lng: 17.7856298 },
    { lat: 48.6941709, lng: 17.7855926 },
    { lat: 48.6938542, lng: 17.785478 },
    { lat: 48.6937073, lng: 17.7854022 },
    { lat: 48.6935416, lng: 17.7853169 },
    { lat: 48.6934669, lng: 17.7853171 },
    { lat: 48.693507, lng: 17.7852145 },
    { lat: 48.6935848, lng: 17.784879 },
    { lat: 48.693885, lng: 17.783469 },
    { lat: 48.693899, lng: 17.7834193 },
    { lat: 48.6939241, lng: 17.7833316 },
    { lat: 48.6939353, lng: 17.7832942 },
    { lat: 48.6934174, lng: 17.7829382 },
    { lat: 48.6938376, lng: 17.7813295 },
    { lat: 48.6943434, lng: 17.7796592 },
    { lat: 48.6948026, lng: 17.7782456 },
    { lat: 48.6947673, lng: 17.7781685 },
    { lat: 48.694473, lng: 17.7775872 },
    { lat: 48.6943698, lng: 17.7774888 },
    { lat: 48.6951729, lng: 17.7750671 },
    { lat: 48.6951438, lng: 17.775035 },
    { lat: 48.6947739, lng: 17.7746223 },
    { lat: 48.6946931, lng: 17.7748714 },
    { lat: 48.6944536, lng: 17.7746889 },
    { lat: 48.6949076, lng: 17.773393 },
    { lat: 48.6954567, lng: 17.7720828 },
    { lat: 48.6953417, lng: 17.772032 },
    { lat: 48.6951581, lng: 17.7725566 },
    { lat: 48.6949245, lng: 17.773042 },
    { lat: 48.6947153, lng: 17.7736293 },
    { lat: 48.6943489, lng: 17.7733295 },
    { lat: 48.6940794, lng: 17.774317 },
    { lat: 48.6935915, lng: 17.7739525 },
    { lat: 48.6940881, lng: 17.7727263 },
    { lat: 48.6944051, lng: 17.7720912 },
    { lat: 48.6945113, lng: 17.772219 },
    { lat: 48.694864, lng: 17.7714842 },
    { lat: 48.6946513, lng: 17.7712572 },
    { lat: 48.6952771, lng: 17.7700366 },
    { lat: 48.69502, lng: 17.7697896 },
    { lat: 48.6947989, lng: 17.7702384 },
    { lat: 48.6940571, lng: 17.7716124 },
    { lat: 48.6939013, lng: 17.7713553 },
    { lat: 48.6944444, lng: 17.7703345 },
    { lat: 48.6941177, lng: 17.7698536 },
    { lat: 48.6942873, lng: 17.7695426 },
    { lat: 48.6946763, lng: 17.7686975 },
    { lat: 48.6944857, lng: 17.768556 },
    { lat: 48.6940152, lng: 17.7682204 },
    { lat: 48.6936923, lng: 17.7679302 },
    { lat: 48.6931795, lng: 17.7673426 },
    { lat: 48.692917, lng: 17.7670438 },
    { lat: 48.6931884, lng: 17.7666415 },
    { lat: 48.6935094, lng: 17.7660166 },
    { lat: 48.6936451, lng: 17.7661645 },
    { lat: 48.6938339, lng: 17.7659814 },
    { lat: 48.6940621, lng: 17.7654459 },
    { lat: 48.6937391, lng: 17.7648791 },
    { lat: 48.6934611, lng: 17.7645294 },
    { lat: 48.6935418, lng: 17.7643355 },
    { lat: 48.6922885, lng: 17.7628608 },
    { lat: 48.6925235, lng: 17.7623256 },
    { lat: 48.692799, lng: 17.762634 },
    { lat: 48.6929586, lng: 17.7623437 },
    { lat: 48.693143, lng: 17.7619426 },
    { lat: 48.6929603, lng: 17.7616913 },
    { lat: 48.6932672, lng: 17.7611119 },
    { lat: 48.6929007, lng: 17.7606281 },
    { lat: 48.6930849, lng: 17.760296 },
    { lat: 48.6932578, lng: 17.7605175 },
    { lat: 48.6935439, lng: 17.760005 },
    { lat: 48.6934045, lng: 17.7597954 },
    { lat: 48.6924847, lng: 17.7584726 },
    { lat: 48.6926488, lng: 17.7582755 },
    { lat: 48.6926729, lng: 17.7583168 },
    { lat: 48.6929212, lng: 17.7579996 },
    { lat: 48.6931312, lng: 17.7578127 },
    { lat: 48.6932359, lng: 17.7576222 },
    { lat: 48.6933218, lng: 17.7577685 },
    { lat: 48.6934471, lng: 17.7576196 },
    { lat: 48.6936406, lng: 17.7579363 },
    { lat: 48.6937046, lng: 17.7578565 },
    { lat: 48.6936926, lng: 17.757845 },
    { lat: 48.6930931, lng: 17.7568228 },
    { lat: 48.6929216, lng: 17.7564974 },
    { lat: 48.692766, lng: 17.7561352 },
    { lat: 48.6927354, lng: 17.7560784 },
    { lat: 48.692644, lng: 17.7558972 },
    { lat: 48.6931305, lng: 17.7556855 },
    { lat: 48.693331, lng: 17.7557304 },
    { lat: 48.6936101, lng: 17.755748 },
    { lat: 48.6939904, lng: 17.7551868 },
    { lat: 48.694464, lng: 17.7548038 },
    { lat: 48.6947751, lng: 17.7542213 },
    { lat: 48.6950343, lng: 17.7537859 },
    { lat: 48.6953239, lng: 17.7535344 },
    { lat: 48.6955154, lng: 17.7532835 },
    { lat: 48.6956235, lng: 17.7527076 },
    { lat: 48.6957726, lng: 17.7524131 },
    { lat: 48.6959749, lng: 17.7521557 },
    { lat: 48.6961775, lng: 17.7517563 },
    { lat: 48.6963151, lng: 17.7513845 },
    { lat: 48.6965457, lng: 17.7509635 },
    { lat: 48.6966696, lng: 17.7504048 },
    { lat: 48.6967892, lng: 17.750127 },
    { lat: 48.6968862, lng: 17.7497181 },
    { lat: 48.6969317, lng: 17.7495955 },
    { lat: 48.6970375, lng: 17.7488715 },
    { lat: 48.6971262, lng: 17.7486217 },
    { lat: 48.6971871, lng: 17.7482919 },
    { lat: 48.6973854, lng: 17.747766 },
    { lat: 48.6976595, lng: 17.7474645 },
    { lat: 48.6977427, lng: 17.7473458 },
    { lat: 48.6978253, lng: 17.7471273 },
    { lat: 48.6980521, lng: 17.7467194 },
    { lat: 48.6981593, lng: 17.7464219 },
    { lat: 48.6984067, lng: 17.7461638 },
    { lat: 48.6985527, lng: 17.7456713 },
    { lat: 48.6986574, lng: 17.7455183 },
    { lat: 48.6988368, lng: 17.7453108 },
    { lat: 48.6992182, lng: 17.7445126 },
    { lat: 48.6994974, lng: 17.7442749 },
    { lat: 48.699893, lng: 17.7436505 },
    { lat: 48.7004619, lng: 17.7426678 },
    { lat: 48.700437, lng: 17.7426488 },
    { lat: 48.6991186, lng: 17.741609 },
    { lat: 48.699459, lng: 17.7404946 },
    { lat: 48.6996482, lng: 17.7398953 },
    { lat: 48.7004358, lng: 17.7384082 },
    { lat: 48.7014511, lng: 17.7364891 },
    { lat: 48.7012812, lng: 17.7359058 },
    { lat: 48.7012506, lng: 17.7357934 },
    { lat: 48.7012485, lng: 17.7353169 },
    { lat: 48.7008064, lng: 17.7347936 },
    { lat: 48.7006303, lng: 17.7341354 },
    { lat: 48.7002174, lng: 17.7335733 },
    { lat: 48.6996457, lng: 17.7330726 },
    { lat: 48.6986047, lng: 17.7331622 },
    { lat: 48.6978911, lng: 17.7325771 },
    { lat: 48.6975198, lng: 17.7318087 },
    { lat: 48.6973312, lng: 17.7311476 },
    { lat: 48.6975268, lng: 17.7294886 },
    { lat: 48.6974442, lng: 17.728607 },
    { lat: 48.697121, lng: 17.7277244 },
    { lat: 48.6965247, lng: 17.7269867 },
    { lat: 48.6962411, lng: 17.7266921 },
    { lat: 48.6959422, lng: 17.7265326 },
    { lat: 48.6957438, lng: 17.7264079 },
    { lat: 48.6955847, lng: 17.7262262 },
    { lat: 48.6954844, lng: 17.7260463 },
    { lat: 48.6954368, lng: 17.7258622 },
    { lat: 48.6954383, lng: 17.7256293 },
    { lat: 48.6953346, lng: 17.7252483 },
    { lat: 48.6950636, lng: 17.7247911 },
    { lat: 48.6949682, lng: 17.7245254 },
    { lat: 48.6947865, lng: 17.7241218 },
    { lat: 48.694614, lng: 17.723724 },
    { lat: 48.6943669, lng: 17.7235258 },
    { lat: 48.6940978, lng: 17.7233371 },
    { lat: 48.6937639, lng: 17.7232702 },
    { lat: 48.693655, lng: 17.7231708 },
    { lat: 48.6933843, lng: 17.7227172 },
    { lat: 48.6932107, lng: 17.7225919 },
    { lat: 48.6929062, lng: 17.7223035 },
    { lat: 48.6927494, lng: 17.7220529 },
    { lat: 48.6924849, lng: 17.7218461 },
    { lat: 48.6919518, lng: 17.7215874 },
    { lat: 48.6916957, lng: 17.7214251 },
    { lat: 48.6913965, lng: 17.7211696 },
    { lat: 48.6910148, lng: 17.7208053 },
    { lat: 48.6908491, lng: 17.7207143 },
    { lat: 48.6906419, lng: 17.7204518 },
    { lat: 48.6903892, lng: 17.7201608 },
    { lat: 48.6904023, lng: 17.7201343 },
    { lat: 48.690364, lng: 17.720081 },
    { lat: 48.69033, lng: 17.720041 },
    { lat: 48.6900537, lng: 17.7204515 },
    { lat: 48.6899042, lng: 17.7211565 },
    { lat: 48.6897604, lng: 17.7214973 },
    { lat: 48.6895379, lng: 17.7218059 },
    { lat: 48.6893672, lng: 17.7221495 },
    { lat: 48.6893209, lng: 17.7222768 },
    { lat: 48.6891622, lng: 17.7225964 },
    { lat: 48.6890595, lng: 17.7226716 },
    { lat: 48.6888714, lng: 17.7226796 },
    { lat: 48.6886531, lng: 17.7228 },
    { lat: 48.6885667, lng: 17.7228829 },
    { lat: 48.688144, lng: 17.7237507 },
    { lat: 48.6881186, lng: 17.7239455 },
    { lat: 48.6881227, lng: 17.7245059 },
    { lat: 48.6881931, lng: 17.7249484 },
    { lat: 48.6882308, lng: 17.7252679 },
    { lat: 48.6882386, lng: 17.7256853 },
    { lat: 48.6883276, lng: 17.725994 },
    { lat: 48.6885715, lng: 17.7261958 },
    { lat: 48.6887063, lng: 17.7262608 },
    { lat: 48.6889011, lng: 17.7264149 },
    { lat: 48.6890201, lng: 17.7265679 },
    { lat: 48.6891412, lng: 17.7267563 },
    { lat: 48.6895982, lng: 17.7272302 },
    { lat: 48.6896154, lng: 17.7272128 },
    { lat: 48.6897413, lng: 17.7274244 },
    { lat: 48.6898652, lng: 17.7276225 },
    { lat: 48.6898024, lng: 17.7282881 },
    { lat: 48.6896771, lng: 17.7289268 },
    { lat: 48.6896517, lng: 17.7291559 },
    { lat: 48.6896467, lng: 17.7297534 },
    { lat: 48.6896587, lng: 17.7298557 },
    { lat: 48.6897614, lng: 17.7302929 },
    { lat: 48.6901086, lng: 17.7309091 },
    { lat: 48.6902667, lng: 17.7312402 },
    { lat: 48.6906894, lng: 17.7319409 },
    { lat: 48.6908264, lng: 17.7320406 },
    { lat: 48.6910663, lng: 17.7321566 },
    { lat: 48.6911994, lng: 17.7323037 },
    { lat: 48.691035, lng: 17.7328757 },
    { lat: 48.6907152, lng: 17.7341171 },
    { lat: 48.6904209, lng: 17.7352423 },
    { lat: 48.6901452, lng: 17.7363188 },
    { lat: 48.6899831, lng: 17.7370602 },
    { lat: 48.6898662, lng: 17.7376898 },
    { lat: 48.6897706, lng: 17.7384101 },
    { lat: 48.6896435, lng: 17.7404926 },
    { lat: 48.6895011, lng: 17.7422136 },
    { lat: 48.6893583, lng: 17.7436873 },
    { lat: 48.6891969, lng: 17.7451396 },
    { lat: 48.6888408, lng: 17.7456799 },
    { lat: 48.6885548, lng: 17.7457111 },
    { lat: 48.6884146, lng: 17.7475435 },
    { lat: 48.6886065, lng: 17.7475977 },
    { lat: 48.6882998, lng: 17.749054 },
    { lat: 48.6880572, lng: 17.7502679 },
    { lat: 48.6881779, lng: 17.7503946 },
    { lat: 48.688119, lng: 17.7510003 },
    { lat: 48.6881284, lng: 17.7512402 },
    { lat: 48.688167, lng: 17.7515221 },
    { lat: 48.6882552, lng: 17.7519456 },
    { lat: 48.6883012, lng: 17.7521242 },
    { lat: 48.6883297, lng: 17.7523332 },
    { lat: 48.6881684, lng: 17.7525184 },
    { lat: 48.6881496, lng: 17.75324 },
    { lat: 48.6881688, lng: 17.7534224 },
    { lat: 48.6880279, lng: 17.7538513 },
    { lat: 48.6879202, lng: 17.7544303 },
    { lat: 48.6878939, lng: 17.7549199 },
    { lat: 48.6878103, lng: 17.7555072 },
    { lat: 48.6878371, lng: 17.7559998 },
    { lat: 48.6877553, lng: 17.756394 },
    { lat: 48.6876665, lng: 17.7574669 },
    { lat: 48.687532, lng: 17.758035 },
    { lat: 48.6873412, lng: 17.7584951 },
    { lat: 48.6871485, lng: 17.7589216 },
    { lat: 48.6869255, lng: 17.7597039 },
    { lat: 48.6868939, lng: 17.7601989 },
    { lat: 48.6865186, lng: 17.7607446 },
    { lat: 48.6860921, lng: 17.7616126 },
    { lat: 48.685822, lng: 17.7621424 },
    { lat: 48.6856656, lng: 17.7624612 },
    { lat: 48.685661, lng: 17.7628292 },
    { lat: 48.6853579, lng: 17.7630796 },
    { lat: 48.6851161, lng: 17.7635329 },
    { lat: 48.6847419, lng: 17.7639133 },
    { lat: 48.6843062, lng: 17.764576 },
    { lat: 48.683756, lng: 17.7655615 },
    { lat: 48.683567, lng: 17.7658317 },
    { lat: 48.683305, lng: 17.766107 },
    { lat: 48.6829618, lng: 17.7665295 },
    { lat: 48.6823799, lng: 17.7670196 },
    { lat: 48.6821803, lng: 17.7670208 },
    { lat: 48.6817859, lng: 17.7673103 },
    { lat: 48.6815451, lng: 17.7676916 },
    { lat: 48.6814546, lng: 17.7679447 },
    { lat: 48.6811337, lng: 17.7689732 },
    { lat: 48.6809126, lng: 17.7694178 },
    { lat: 48.6806247, lng: 17.7703016 },
    { lat: 48.6802608, lng: 17.7709949 },
    { lat: 48.6802034, lng: 17.7713659 },
    { lat: 48.679697, lng: 17.7724209 },
    { lat: 48.6793572, lng: 17.7730883 },
    { lat: 48.6792687, lng: 17.773226 },
    { lat: 48.6790194, lng: 17.7738185 },
    { lat: 48.6790044, lng: 17.7738703 },
    { lat: 48.6799441, lng: 17.7744263 },
    { lat: 48.6805323, lng: 17.7749443 },
    { lat: 48.6810034, lng: 17.7753557 },
    { lat: 48.6813864, lng: 17.7756709 },
    { lat: 48.6817119, lng: 17.7758798 },
    { lat: 48.6824295, lng: 17.7764097 },
    { lat: 48.6827664, lng: 17.7766367 },
    { lat: 48.6815554, lng: 17.7808248 },
    { lat: 48.6798444, lng: 17.7865889 },
    { lat: 48.6798339, lng: 17.7866036 },
    { lat: 48.6799361, lng: 17.786723 },
    { lat: 48.6800165, lng: 17.7870393 },
    { lat: 48.6801435, lng: 17.7872317 },
    { lat: 48.6801673, lng: 17.7876796 },
    { lat: 48.6802635, lng: 17.7876626 },
    { lat: 48.6805493, lng: 17.7871679 },
    { lat: 48.6806576, lng: 17.7872593 },
    { lat: 48.680954, lng: 17.787657 },
    { lat: 48.6811806, lng: 17.7876686 },
    { lat: 48.6813677, lng: 17.7876453 },
    { lat: 48.6814837, lng: 17.7875491 },
    { lat: 48.681511, lng: 17.7875357 },
    { lat: 48.6815312, lng: 17.7876608 },
    { lat: 48.6809218, lng: 17.7899883 },
    { lat: 48.6806062, lng: 17.7897615 },
    { lat: 48.6802585, lng: 17.7907756 },
    { lat: 48.6805915, lng: 17.7910679 },
    { lat: 48.6804605, lng: 17.791437 },
    { lat: 48.6806496, lng: 17.7915571 },
    { lat: 48.6804145, lng: 17.793141 },
    { lat: 48.6804201, lng: 17.7935118 },
    { lat: 48.6804731, lng: 17.7940803 },
    { lat: 48.6805099, lng: 17.7940948 },
    { lat: 48.6803782, lng: 17.7949463 },
    { lat: 48.6802949, lng: 17.7950279 },
    { lat: 48.6801392, lng: 17.795085 },
    { lat: 48.67995, lng: 17.7952567 },
    { lat: 48.6798004, lng: 17.7958199 },
    { lat: 48.6797439, lng: 17.7963547 },
    { lat: 48.6796464, lng: 17.7965712 },
    { lat: 48.6793888, lng: 17.7965292 },
    { lat: 48.6791953, lng: 17.7965408 },
    { lat: 48.6787023, lng: 17.7984893 },
    { lat: 48.6786983, lng: 17.7986992 },
    { lat: 48.6784652, lng: 17.7986898 },
    { lat: 48.6782457, lng: 17.7986633 },
    { lat: 48.6779638, lng: 17.7987261 },
    { lat: 48.6776869, lng: 17.79874 },
    { lat: 48.6773137, lng: 17.798692 },
    { lat: 48.676748, lng: 17.7986708 },
    { lat: 48.6765409, lng: 17.7986428 },
    { lat: 48.6761101, lng: 17.7986496 },
    { lat: 48.6759759, lng: 17.8033678 },
    { lat: 48.6750375, lng: 17.8041046 },
    { lat: 48.6745447, lng: 17.8045189 },
    { lat: 48.6741786, lng: 17.8048656 },
    { lat: 48.6739096, lng: 17.805137 },
    { lat: 48.6736708, lng: 17.8052742 },
    { lat: 48.6731753, lng: 17.8057218 },
    { lat: 48.6728837, lng: 17.8059213 },
    { lat: 48.672708, lng: 17.8060193 },
    { lat: 48.6725806, lng: 17.8061535 },
    { lat: 48.6724861, lng: 17.8063067 },
    { lat: 48.6706704, lng: 17.8060024 },
    { lat: 48.6705305, lng: 17.8060219 },
    { lat: 48.6698711, lng: 17.8061518 },
    { lat: 48.6694766, lng: 17.8070878 },
    { lat: 48.6688352, lng: 17.8070278 },
    { lat: 48.6687534, lng: 17.8077974 },
    { lat: 48.6686458, lng: 17.8089567 },
    { lat: 48.6686157, lng: 17.8096848 },
    { lat: 48.6685935, lng: 17.8104876 },
    { lat: 48.6685685, lng: 17.8111136 },
    { lat: 48.6685342, lng: 17.8114938 },
    { lat: 48.6684461, lng: 17.8122725 },
    { lat: 48.6683474, lng: 17.8131723 },
    { lat: 48.668258, lng: 17.813909 },
    { lat: 48.6681754, lng: 17.8144977 },
    { lat: 48.6681231, lng: 17.8148974 },
    { lat: 48.668049, lng: 17.8155342 },
    { lat: 48.6680068, lng: 17.8158075 },
    { lat: 48.667947, lng: 17.8164573 },
    { lat: 48.66787, lng: 17.817413 },
    { lat: 48.6677439, lng: 17.8183645 },
    { lat: 48.6676445, lng: 17.8193658 },
    { lat: 48.6675821, lng: 17.8198248 },
    { lat: 48.6675112, lng: 17.8204007 },
    { lat: 48.667529, lng: 17.8204532 },
    { lat: 48.667621, lng: 17.820463 },
    { lat: 48.6676665, lng: 17.8204838 },
    { lat: 48.667739, lng: 17.8204902 },
    { lat: 48.6679522, lng: 17.8205424 },
    { lat: 48.6682039, lng: 17.8206899 },
    { lat: 48.6683159, lng: 17.8207294 },
    { lat: 48.6683952, lng: 17.8207001 },
    { lat: 48.6685389, lng: 17.8205581 },
    { lat: 48.6685991, lng: 17.820524 },
    { lat: 48.6688801, lng: 17.8204696 },
    { lat: 48.6690074, lng: 17.8203887 },
    { lat: 48.6690807, lng: 17.8202946 },
    { lat: 48.6692326, lng: 17.8201203 },
    { lat: 48.6693598, lng: 17.8200569 },
    { lat: 48.6694297, lng: 17.8200583 },
    { lat: 48.669526, lng: 17.8200824 },
    { lat: 48.6696145, lng: 17.8201798 },
    { lat: 48.6698965, lng: 17.8204007 },
    { lat: 48.6700561, lng: 17.8205749 },
    { lat: 48.670153, lng: 17.8206429 },
    { lat: 48.6702235, lng: 17.8206349 },
    { lat: 48.6703572, lng: 17.8205671 },
    { lat: 48.6705657, lng: 17.8205799 },
    { lat: 48.6706511, lng: 17.8205366 },
    { lat: 48.6706876, lng: 17.8204866 },
    { lat: 48.6707645, lng: 17.8202309 },
    { lat: 48.6710181, lng: 17.8201696 },
    { lat: 48.6711286, lng: 17.8202389 },
    { lat: 48.6712004, lng: 17.8203614 },
    { lat: 48.6713072, lng: 17.8204838 },
    { lat: 48.6713656, lng: 17.820622 },
  ],
};

export default UnitsNovéMestonadVáhom;
