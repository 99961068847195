import { categoryService } from "../../../services/categoryService";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { faMagnifyingGlass, faX } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

export default function Location({ filters, setFilters, filterForSale, isFilter }) {
  const [locations, setLocations] = useState();
  const [isOpen, setOpen] = useState(false);
  const [locToFind, setLocToFind] = useState("");
  const inputRef = useRef(null);
  const name = "name";
  const district = "externalDistrictName";

  useEffect(() => {
    getAllMunicipalities();
    // eslint-disable-next-line
  }, []);

  const getAllMunicipalities = async () => {
    const response = await categoryService.getAllMunicipalities();
    const data = await response.json();
    setLocations(data);
  };

  const selectOption = (option) => {
    setLocToFind(() => "");
    //  handleChange(option[name]);
    setOpen(!isOpen);
    setFilters({ ...filters, location: option[name] });
    //  changeIsOpen(false);
    //  resetAvgPrice([]);
  };

  const toggle = (e) => {
    setOpen(e && e.target === inputRef.current);
    setFilters({ ...filters, location: e.target.value });
  };

  const toggle1 = (e) => {
    setOpen(!isOpen);
    setFilters({ ...filters, location: e.target.value });
  };

  const filter = (locations) => {
    return locations?.filter(
      (option) =>
        option[name]
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .indexOf(
            locToFind
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
          ) > -1,
    );
  };

  return (
    <motion.div
      className="relative w-full h-auto pt-1"
      initial={{ height: 30 }}
      animate={{ height: isFilter.location ? 60 : 30 }}
      transition={{ duration: 0.5 }}
    >
      {/* <div className="w-full pl-2 flex items-center justify-start">Lokalita</div> */}
      <input
        className="w-full p-2.5 text-gray-500 bg-white border border-opacity-50 border-sky-500 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-600"
        ref={inputRef}
        type="text"
        value={filters.location ? filters.location : ""}
        name="searchTerm"
        placeholder="Lokalita..."
        onChange={(e) => {
          setLocToFind(e.target.value);
          //   handleChange(null);
        }}
        onClick={toggle1}
        onChangeCapture={toggle}
      />
      <button
        onClick={() => filterForSale()}
        className="absolute top-[4px] right-0 w-10 h-[45.2px] rounded-r-lg flex justify-center items-center bg-sky-100"
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
      <button
        onClick={() => filterForSale(1)}
        className="absolute top-[18px] right-12 w-5 h-5 rounded-full flex justify-center items-center bg-sky-100"
      >
        <FontAwesomeIcon icon={faX} />
      </button>
      {isOpen ? (
        <div
          className={clsx("", {
            " w-full h-40 overflow-scroll bg-white rounded-lg z-50": isOpen,
          })}
        >
          {filter(locations)?.map((option, index) => {
            return (
              <div
                onClick={() => selectOption(option)}
                className={`box-border text-black cursor-pointer block px-2.5 py-2  hover:bg-sky-300 `}
                //   key={`${code}-${index}`}
                key={index}
              >
                {option[name]}, (okres {option[district]})
              </div>
            );
          })}
        </div>
      ) : null}
    </motion.div>
  );
}
