// import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";

export default function PriceInput({ inputValues, setInputValues, size }) {
  // const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <div
      className={clsx("px-4 w-full", {
        // "felx flex-col w-1/2": !isSmallDevice && size.full,
        // "w-full": isSmallDevice || !size.full,
      })}
    >
      <div className="w-full text-start">Som ochotný dať</div>
      <input
        onClick={(event) => event.stopPropagation()}
        value={inputValues.determinePrice}
        onChange={(e) => {
          setInputValues({ ...inputValues, determinePrice: e.target.value });
        }}
        placeholder={"Som ochotný dať"}
        type="number"
        className={clsx("rounded-xl px-2 h-14 truncate border border-opacity-50 border-sky-500 py-2 w-full", {})}
      ></input>
    </div>
  );
}
