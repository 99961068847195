import { saveAddressService } from "../../../services/saveAddressService";

export default function SelectFlat({
  filteredArray,
  blockOfFlats,
  setBlockOfFlats,
  addressService,
  avgPrice,
  setHistoryItems,
}) {
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr?.map((item) => [item[key], item])).values()];
  };
  const arrFlat = getUniqueListBy(filteredArray?.List?.byt, "cislo_bytu");

  return filteredArray?.List?.hasOwnProperty("byt") ? (
    blockOfFlats.floor !== null && blockOfFlats.flat === null ? (
      <div className="w-full flex flex-col justify-center items-center">
        <div>Zvoľ byt</div>
        {arrFlat?.map((value, index) => {
          return (
            <div
              onClick={() => {
                setBlockOfFlats({ ...blockOfFlats, flat: Number(value?.cislo_bytu) });
                // console.log("avgPrice = ", avgPrice);
                const user = JSON.parse(localStorage.getItem("userData"));
                const charsToEncode = /[\u007f-\uffff]/g;
                saveAddressService.setSaveAddressService(
                  user?.m,
                  addressService.streetName,
                  addressService.regNumber,
                  addressService.addressCurr,
                  addressService.psc,
                  addressService.country,
                  addressService.latitude,
                  addressService.longitude,
                  JSON.stringify(avgPrice).replace(charsToEncode, function (c) {
                    return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
                  }),
                  JSON.stringify(filteredArray).replace(charsToEncode, function (c) {
                    return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
                  }),
                  value?.cislo_bytu,
                  blockOfFlats.entrance,
                  "0",
                  filteredArray?.List?.cislo_listu_vlastnictva,
                );
                const historyArray = JSON.parse(localStorage.getItem("history"));
                const history = historyArray[0];
                const changeHistory = () => {
                  return { ...history, flat: value?.cislo_bytu, entrance: blockOfFlats.entrance };
                };
                const newHistory = changeHistory();
                historyArray[0] = newHistory;
                localStorage.setItem("history", JSON.stringify(historyArray));
                setHistoryItems(JSON.parse(localStorage.getItem("history")));
              }}
              key={index}
              className="h-10 border-2 border-gray-300 w-[78%] rounded-lg mb-1 flex items-center justify-center cursor-pointer"
            >
              <div>{value?.cislo_bytu}</div>
            </div>
          );
        })}
      </div>
    ) : null
  ) : null;
}
