const UnitsKysuckéNovéMesto = {
  DolnýVadičov: [
    { lat: 49.262762, lng: 18.850194 },
    { lat: 49.2630615, lng: 18.850418 },
    { lat: 49.2631351, lng: 18.8504489 },
    { lat: 49.2633205, lng: 18.8505256 },
    { lat: 49.2635308, lng: 18.8507748 },
    { lat: 49.2636278, lng: 18.8508888 },
    { lat: 49.2638076, lng: 18.8511189 },
    { lat: 49.2639885, lng: 18.8513478 },
    { lat: 49.2640128, lng: 18.851402 },
    { lat: 49.2641955, lng: 18.851801 },
    { lat: 49.2642793, lng: 18.8519845 },
    { lat: 49.2642553, lng: 18.8519993 },
    { lat: 49.2645204, lng: 18.8527972 },
    { lat: 49.2645392, lng: 18.8528559 },
    { lat: 49.2645553, lng: 18.852902 },
    { lat: 49.2645886, lng: 18.8530038 },
    { lat: 49.2645061, lng: 18.8530664 },
    { lat: 49.2640827, lng: 18.8533849 },
    { lat: 49.263821, lng: 18.8535812 },
    { lat: 49.2638203, lng: 18.8539174 },
    { lat: 49.2638325, lng: 18.8540036 },
    { lat: 49.2638645, lng: 18.8542081 },
    { lat: 49.2639475, lng: 18.8547531 },
    { lat: 49.2639564, lng: 18.854817 },
    { lat: 49.2640037, lng: 18.8551214 },
    { lat: 49.2640122, lng: 18.8551785 },
    { lat: 49.2640125, lng: 18.8551828 },
    { lat: 49.2640156, lng: 18.8552027 },
    { lat: 49.2640683, lng: 18.8552162 },
    { lat: 49.2641346, lng: 18.8552329 },
    { lat: 49.2641458, lng: 18.8552358 },
    { lat: 49.2643237, lng: 18.8554287 },
    { lat: 49.2644963, lng: 18.8556705 },
    { lat: 49.2644773, lng: 18.8558486 },
    { lat: 49.2644843, lng: 18.8558986 },
    { lat: 49.2645606, lng: 18.8560913 },
    { lat: 49.2645638, lng: 18.8562602 },
    { lat: 49.2645874, lng: 18.8564585 },
    { lat: 49.2646486, lng: 18.8566201 },
    { lat: 49.2647534, lng: 18.8567527 },
    { lat: 49.2648672, lng: 18.8567745 },
    { lat: 49.2649617, lng: 18.8569221 },
    { lat: 49.2649689, lng: 18.8571505 },
    { lat: 49.2651298, lng: 18.8573428 },
    { lat: 49.2652924, lng: 18.8576761 },
    { lat: 49.2654375, lng: 18.8578663 },
    { lat: 49.2657324, lng: 18.8580581 },
    { lat: 49.2658952, lng: 18.8582661 },
    { lat: 49.2660351, lng: 18.85864 },
    { lat: 49.2667949, lng: 18.8592529 },
    { lat: 49.2671084, lng: 18.8597844 },
    { lat: 49.2672113, lng: 18.8598382 },
    { lat: 49.2673863, lng: 18.8599281 },
    { lat: 49.2676503, lng: 18.8600648 },
    { lat: 49.2679856, lng: 18.8602738 },
    { lat: 49.2680369, lng: 18.8603064 },
    { lat: 49.2682968, lng: 18.8604677 },
    { lat: 49.2684237, lng: 18.8605479 },
    { lat: 49.2686394, lng: 18.8607557 },
    { lat: 49.2690865, lng: 18.8611865 },
    { lat: 49.2695771, lng: 18.8613008 },
    { lat: 49.269684, lng: 18.8613628 },
    { lat: 49.2699085, lng: 18.8614923 },
    { lat: 49.2705268, lng: 18.862208 },
    { lat: 49.270605, lng: 18.8623702 },
    { lat: 49.2707609, lng: 18.8625783 },
    { lat: 49.2709608, lng: 18.8628465 },
    { lat: 49.2711942, lng: 18.8632014 },
    { lat: 49.2712456, lng: 18.8631487 },
    { lat: 49.2715028, lng: 18.8634805 },
    { lat: 49.2718138, lng: 18.8638102 },
    { lat: 49.2719058, lng: 18.8639082 },
    { lat: 49.2721231, lng: 18.864235 },
    { lat: 49.2721999, lng: 18.8643468 },
    { lat: 49.2722767, lng: 18.8644575 },
    { lat: 49.272389, lng: 18.8646961 },
    { lat: 49.2724395, lng: 18.8648013 },
    { lat: 49.2733897, lng: 18.8648186 },
    { lat: 49.2733089, lng: 18.8652846 },
    { lat: 49.2731517, lng: 18.8656767 },
    { lat: 49.273148, lng: 18.8657797 },
    { lat: 49.2732353, lng: 18.8657689 },
    { lat: 49.27335, lng: 18.8657159 },
    { lat: 49.2734759, lng: 18.8655994 },
    { lat: 49.2735179, lng: 18.8655612 },
    { lat: 49.2737729, lng: 18.8656737 },
    { lat: 49.2738258, lng: 18.8660252 },
    { lat: 49.2738614, lng: 18.8671652 },
    { lat: 49.2738754, lng: 18.8676138 },
    { lat: 49.273923, lng: 18.868014 },
    { lat: 49.2739004, lng: 18.8688121 },
    { lat: 49.2739432, lng: 18.8697368 },
    { lat: 49.2741204, lng: 18.8697182 },
    { lat: 49.2741982, lng: 18.8703581 },
    { lat: 49.2742215, lng: 18.870481 },
    { lat: 49.2746183, lng: 18.8705261 },
    { lat: 49.2747143, lng: 18.8705504 },
    { lat: 49.2747204, lng: 18.8705821 },
    { lat: 49.2747253, lng: 18.8707018 },
    { lat: 49.2746494, lng: 18.8709824 },
    { lat: 49.2746092, lng: 18.8712966 },
    { lat: 49.2747342, lng: 18.8714911 },
    { lat: 49.2748017, lng: 18.8717997 },
    { lat: 49.2748323, lng: 18.872025 },
    { lat: 49.2752947, lng: 18.8739696 },
    { lat: 49.2753844, lng: 18.8740271 },
    { lat: 49.2757491, lng: 18.8745913 },
    { lat: 49.2758842, lng: 18.874919 },
    { lat: 49.2761472, lng: 18.8749891 },
    { lat: 49.2763747, lng: 18.8750494 },
    { lat: 49.2764135, lng: 18.8750599 },
    { lat: 49.2765613, lng: 18.8751737 },
    { lat: 49.2766353, lng: 18.8752296 },
    { lat: 49.2769451, lng: 18.8754678 },
    { lat: 49.2771221, lng: 18.8756038 },
    { lat: 49.2771943, lng: 18.8756713 },
    { lat: 49.2771993, lng: 18.8756751 },
    { lat: 49.2772441, lng: 18.8757163 },
    { lat: 49.2774333, lng: 18.8758909 },
    { lat: 49.2776688, lng: 18.8761081 },
    { lat: 49.2778049, lng: 18.8762337 },
    { lat: 49.2786742, lng: 18.8766758 },
    { lat: 49.2798354, lng: 18.877251 },
    { lat: 49.2813957, lng: 18.8782019 },
    { lat: 49.2816465, lng: 18.8785847 },
    { lat: 49.2818014, lng: 18.8789249 },
    { lat: 49.2818902, lng: 18.879166 },
    { lat: 49.2828416, lng: 18.8800465 },
    { lat: 49.2840007, lng: 18.8792504 },
    { lat: 49.2854485, lng: 18.8779949 },
    { lat: 49.2857882, lng: 18.8776254 },
    { lat: 49.2860823, lng: 18.8771136 },
    { lat: 49.2863415, lng: 18.876624 },
    { lat: 49.2866435, lng: 18.8754944 },
    { lat: 49.2867736, lng: 18.8747067 },
    { lat: 49.2868263, lng: 18.8737531 },
    { lat: 49.2869383, lng: 18.8726321 },
    { lat: 49.2870331, lng: 18.8719952 },
    { lat: 49.2870885, lng: 18.8712471 },
    { lat: 49.2874429, lng: 18.8700643 },
    { lat: 49.287463, lng: 18.8694851 },
    { lat: 49.28772, lng: 18.8690864 },
    { lat: 49.2880046, lng: 18.8687429 },
    { lat: 49.288168, lng: 18.8679692 },
    { lat: 49.2882959, lng: 18.8666111 },
    { lat: 49.2882039, lng: 18.8649856 },
    { lat: 49.2880885, lng: 18.8642485 },
    { lat: 49.2879017, lng: 18.8632187 },
    { lat: 49.2878696, lng: 18.8624568 },
    { lat: 49.2876882, lng: 18.8618666 },
    { lat: 49.2871635, lng: 18.8605544 },
    { lat: 49.2867493, lng: 18.8595789 },
    { lat: 49.2864595, lng: 18.8584773 },
    { lat: 49.2863302, lng: 18.8576898 },
    { lat: 49.2860706, lng: 18.8567995 },
    { lat: 49.2859445, lng: 18.8559475 },
    { lat: 49.285829, lng: 18.8557818 },
    { lat: 49.2858265, lng: 18.8553819 },
    { lat: 49.2857479, lng: 18.854881 },
    { lat: 49.2857611, lng: 18.8538826 },
    { lat: 49.2859105, lng: 18.8530355 },
    { lat: 49.2860523, lng: 18.852216 },
    { lat: 49.2861705, lng: 18.8518612 },
    { lat: 49.2861108, lng: 18.8513566 },
    { lat: 49.2860892, lng: 18.8506892 },
    { lat: 49.2857523, lng: 18.8505183 },
    { lat: 49.2852085, lng: 18.85047 },
    { lat: 49.2843699, lng: 18.8499643 },
    { lat: 49.2838364, lng: 18.849632 },
    { lat: 49.28352, lng: 18.8494655 },
    { lat: 49.2834072, lng: 18.8494165 },
    { lat: 49.2827361, lng: 18.849105 },
    { lat: 49.28233, lng: 18.8488387 },
    { lat: 49.2819427, lng: 18.8486301 },
    { lat: 49.281414, lng: 18.8482348 },
    { lat: 49.2809754, lng: 18.8479247 },
    { lat: 49.2806785, lng: 18.8475656 },
    { lat: 49.2804322, lng: 18.847269 },
    { lat: 49.2801836, lng: 18.8469492 },
    { lat: 49.2799113, lng: 18.8465885 },
    { lat: 49.2798424, lng: 18.8464978 },
    { lat: 49.2797105, lng: 18.8463208 },
    { lat: 49.2795117, lng: 18.8462731 },
    { lat: 49.2785439, lng: 18.8460463 },
    { lat: 49.2781253, lng: 18.8458394 },
    { lat: 49.2779454, lng: 18.8457172 },
    { lat: 49.277776, lng: 18.8456028 },
    { lat: 49.2777153, lng: 18.8455617 },
    { lat: 49.2775893, lng: 18.845476 },
    { lat: 49.2774115, lng: 18.84522 },
    { lat: 49.2772949, lng: 18.8449472 },
    { lat: 49.2770846, lng: 18.8442777 },
    { lat: 49.2770171, lng: 18.8442554 },
    { lat: 49.2769967, lng: 18.8442124 },
    { lat: 49.2769752, lng: 18.8441673 },
    { lat: 49.27687, lng: 18.8439412 },
    { lat: 49.2767369, lng: 18.8435187 },
    { lat: 49.2762467, lng: 18.8431113 },
    { lat: 49.2761839, lng: 18.8430513 },
    { lat: 49.2756995, lng: 18.8425933 },
    { lat: 49.2756458, lng: 18.8425138 },
    { lat: 49.275608, lng: 18.8424588 },
    { lat: 49.2756006, lng: 18.8424488 },
    { lat: 49.2755799, lng: 18.8424181 },
    { lat: 49.2751359, lng: 18.8417636 },
    { lat: 49.2746902, lng: 18.8413606 },
    { lat: 49.27441, lng: 18.8411075 },
    { lat: 49.2743319, lng: 18.8407276 },
    { lat: 49.2741528, lng: 18.8405114 },
    { lat: 49.2741289, lng: 18.8404876 },
    { lat: 49.2740706, lng: 18.8404278 },
    { lat: 49.2740576, lng: 18.8404138 },
    { lat: 49.2739301, lng: 18.8402597 },
    { lat: 49.2738566, lng: 18.8401895 },
    { lat: 49.273812, lng: 18.8401349 },
    { lat: 49.2737458, lng: 18.8400515 },
    { lat: 49.2737137, lng: 18.8399972 },
    { lat: 49.2736745, lng: 18.8399614 },
    { lat: 49.2736572, lng: 18.8399512 },
    { lat: 49.2736305, lng: 18.8399375 },
    { lat: 49.2736224, lng: 18.8399309 },
    { lat: 49.2736091, lng: 18.8399162 },
    { lat: 49.2734876, lng: 18.8397907 },
    { lat: 49.273439, lng: 18.8397409 },
    { lat: 49.2733652, lng: 18.8396894 },
    { lat: 49.272819, lng: 18.8391971 },
    { lat: 49.2726572, lng: 18.8390514 },
    { lat: 49.272656, lng: 18.8390494 },
    { lat: 49.2725532, lng: 18.8389578 },
    { lat: 49.2727939, lng: 18.8383992 },
    { lat: 49.2728624, lng: 18.8384137 },
    { lat: 49.2733095, lng: 18.837725 },
    { lat: 49.2732474, lng: 18.8376328 },
    { lat: 49.2732424, lng: 18.837646 },
    { lat: 49.2731923, lng: 18.8375733 },
    { lat: 49.2731765, lng: 18.837551 },
    { lat: 49.273151, lng: 18.8375147 },
    { lat: 49.2731315, lng: 18.8374849 },
    { lat: 49.2731181, lng: 18.8374659 },
    { lat: 49.2731106, lng: 18.8374545 },
    { lat: 49.2730415, lng: 18.8373144 },
    { lat: 49.2729987, lng: 18.8372495 },
    { lat: 49.2727558, lng: 18.8368894 },
    { lat: 49.2725219, lng: 18.8364842 },
    { lat: 49.2722448, lng: 18.8362242 },
    { lat: 49.2719598, lng: 18.8356374 },
    { lat: 49.2718721, lng: 18.8354673 },
    { lat: 49.2715678, lng: 18.8348785 },
    { lat: 49.2713393, lng: 18.8345557 },
    { lat: 49.2710985, lng: 18.8342152 },
    { lat: 49.270941, lng: 18.8334953 },
    { lat: 49.2708879, lng: 18.8332526 },
    { lat: 49.2706214, lng: 18.8329351 },
    { lat: 49.2705187, lng: 18.8328261 },
    { lat: 49.2705108, lng: 18.832805 },
    { lat: 49.2702587, lng: 18.8321124 },
    { lat: 49.270188, lng: 18.8319165 },
    { lat: 49.2697482, lng: 18.8311068 },
    { lat: 49.269737, lng: 18.8310864 },
    { lat: 49.2696013, lng: 18.8308363 },
    { lat: 49.2692649, lng: 18.830217 },
    { lat: 49.2690219, lng: 18.8297003 },
    { lat: 49.2688234, lng: 18.8292815 },
    { lat: 49.2685898, lng: 18.8288828 },
    { lat: 49.2682228, lng: 18.8282141 },
    { lat: 49.2680754, lng: 18.8284409 },
    { lat: 49.2677013, lng: 18.8279619 },
    { lat: 49.267195, lng: 18.82729 },
    { lat: 49.266718, lng: 18.827951 },
    { lat: 49.266234, lng: 18.827371 },
    { lat: 49.265763, lng: 18.826949 },
    { lat: 49.265715, lng: 18.826907 },
    { lat: 49.264717, lng: 18.826298 },
    { lat: 49.263936, lng: 18.825829 },
    { lat: 49.263736, lng: 18.826802 },
    { lat: 49.263698, lng: 18.827001 },
    { lat: 49.263664, lng: 18.827175 },
    { lat: 49.263609, lng: 18.827203 },
    { lat: 49.263328, lng: 18.82735 },
    { lat: 49.263328, lng: 18.827364 },
    { lat: 49.263326, lng: 18.827698 },
    { lat: 49.263326, lng: 18.8278 },
    { lat: 49.263252, lng: 18.82863 },
    { lat: 49.263251, lng: 18.828635 },
    { lat: 49.26326, lng: 18.829182 },
    { lat: 49.263323, lng: 18.830042 },
    { lat: 49.263239, lng: 18.830535 },
    { lat: 49.262944, lng: 18.831309 },
    { lat: 49.262652, lng: 18.832312 },
    { lat: 49.262509, lng: 18.8328 },
    { lat: 49.26224, lng: 18.833708 },
    { lat: 49.261778, lng: 18.834552 },
    { lat: 49.261299, lng: 18.835428 },
    { lat: 49.260823, lng: 18.83684 },
    { lat: 49.260929, lng: 18.837639 },
    { lat: 49.26098, lng: 18.838019 },
    { lat: 49.260752, lng: 18.837897 },
    { lat: 49.260997, lng: 18.8388 },
    { lat: 49.2611287, lng: 18.8395043 },
    { lat: 49.261161, lng: 18.839677 },
    { lat: 49.261145, lng: 18.840119 },
    { lat: 49.261044, lng: 18.84064 },
    { lat: 49.260905, lng: 18.841066 },
    { lat: 49.261277, lng: 18.841847 },
    { lat: 49.26183, lng: 18.842913 },
    { lat: 49.26196, lng: 18.843167 },
    { lat: 49.26118, lng: 18.84386 },
    { lat: 49.261547, lng: 18.844418 },
    { lat: 49.261802, lng: 18.845555 },
    { lat: 49.262131, lng: 18.846521 },
    { lat: 49.262294, lng: 18.847011 },
    { lat: 49.262357, lng: 18.847263 },
    { lat: 49.262411, lng: 18.847664 },
    { lat: 49.262429, lng: 18.847988 },
    { lat: 49.262405, lng: 18.848397 },
    { lat: 49.262288, lng: 18.848879 },
    { lat: 49.262127, lng: 18.849682 },
    { lat: 49.262762, lng: 18.850194 },
  ],
  HornýVadičov: [
    { lat: 49.250787, lng: 18.863721 },
    { lat: 49.250415, lng: 18.864226 },
    { lat: 49.250271, lng: 18.864537 },
    { lat: 49.250189, lng: 18.864846 },
    { lat: 49.250029, lng: 18.864757 },
    { lat: 49.249888, lng: 18.864888 },
    { lat: 49.249774, lng: 18.864823 },
    { lat: 49.249505, lng: 18.864973 },
    { lat: 49.249468, lng: 18.865041 },
    { lat: 49.249445, lng: 18.865086 },
    { lat: 49.249472, lng: 18.865289 },
    { lat: 49.249098, lng: 18.865743 },
    { lat: 49.248767, lng: 18.866062 },
    { lat: 49.248651, lng: 18.866314 },
    { lat: 49.248566, lng: 18.866574 },
    { lat: 49.24822, lng: 18.866879 },
    { lat: 49.248153, lng: 18.867198 },
    { lat: 49.248148, lng: 18.867251 },
    { lat: 49.248103, lng: 18.86755 },
    { lat: 49.248031, lng: 18.867598 },
    { lat: 49.247971, lng: 18.867551 },
    { lat: 49.247927, lng: 18.867388 },
    { lat: 49.247786, lng: 18.867432 },
    { lat: 49.247763, lng: 18.867534 },
    { lat: 49.247912, lng: 18.867852 },
    { lat: 49.247901, lng: 18.86791 },
    { lat: 49.247821, lng: 18.86797 },
    { lat: 49.24779, lng: 18.867956 },
    { lat: 49.24769, lng: 18.868043 },
    { lat: 49.247673, lng: 18.868203 },
    { lat: 49.247808, lng: 18.868312 },
    { lat: 49.24779, lng: 18.868455 },
    { lat: 49.247631, lng: 18.868548 },
    { lat: 49.24773, lng: 18.868827 },
    { lat: 49.247715, lng: 18.868847 },
    { lat: 49.247597, lng: 18.869161 },
    { lat: 49.247648, lng: 18.869407 },
    { lat: 49.247635, lng: 18.869576 },
    { lat: 49.247503, lng: 18.86972 },
    { lat: 49.247515, lng: 18.871162 },
    { lat: 49.247587, lng: 18.871938 },
    { lat: 49.247905, lng: 18.872613 },
    { lat: 49.248023, lng: 18.872953 },
    { lat: 49.248056, lng: 18.873082 },
    { lat: 49.248039, lng: 18.873388 },
    { lat: 49.248073, lng: 18.87356 },
    { lat: 49.248074, lng: 18.873798 },
    { lat: 49.248113, lng: 18.874011 },
    { lat: 49.248118, lng: 18.874173 },
    { lat: 49.248165, lng: 18.874296 },
    { lat: 49.248289, lng: 18.874643 },
    { lat: 49.248296, lng: 18.874702 },
    { lat: 49.248342, lng: 18.874957 },
    { lat: 49.24844, lng: 18.875158 },
    { lat: 49.248784, lng: 18.87563 },
    { lat: 49.249046, lng: 18.87596 },
    { lat: 49.249547, lng: 18.876432 },
    { lat: 49.250416, lng: 18.876941 },
    { lat: 49.250775, lng: 18.877071 },
    { lat: 49.250958, lng: 18.877137 },
    { lat: 49.250996, lng: 18.87819 },
    { lat: 49.25111, lng: 18.87836 },
    { lat: 49.251785, lng: 18.878457 },
    { lat: 49.251875, lng: 18.878532 },
    { lat: 49.25245, lng: 18.879483 },
    { lat: 49.252761, lng: 18.880014 },
    { lat: 49.251877, lng: 18.880428 },
    { lat: 49.251015, lng: 18.881454 },
    { lat: 49.250402, lng: 18.881491 },
    { lat: 49.249806, lng: 18.882246 },
    { lat: 49.24919, lng: 18.88234 },
    { lat: 49.248997, lng: 18.882881 },
    { lat: 49.248881, lng: 18.883422 },
    { lat: 49.248859, lng: 18.883701 },
    { lat: 49.248842, lng: 18.884311 },
    { lat: 49.248819, lng: 18.885115 },
    { lat: 49.248819, lng: 18.885117 },
    { lat: 49.249215, lng: 18.8873 },
    { lat: 49.249344, lng: 18.888017 },
    { lat: 49.249399, lng: 18.888946 },
    { lat: 49.249399, lng: 18.888949 },
    { lat: 49.249426, lng: 18.890498 },
    { lat: 49.249479, lng: 18.891441 },
    { lat: 49.249479, lng: 18.891444 },
    { lat: 49.249527, lng: 18.892972 },
    { lat: 49.249527, lng: 18.892974 },
    { lat: 49.249629, lng: 18.894684 },
    { lat: 49.249629, lng: 18.894688 },
    { lat: 49.249615, lng: 18.89545 },
    { lat: 49.249615, lng: 18.895452 },
    { lat: 49.249773, lng: 18.896519 },
    { lat: 49.249775, lng: 18.896518 },
    { lat: 49.249933, lng: 18.896473 },
    { lat: 49.250115, lng: 18.896422 },
    { lat: 49.250119, lng: 18.896426 },
    { lat: 49.250454, lng: 18.896855 },
    { lat: 49.250615, lng: 18.897217 },
    { lat: 49.250668, lng: 18.897282 },
    { lat: 49.25085, lng: 18.89751 },
    { lat: 49.251263, lng: 18.898257 },
    { lat: 49.251691, lng: 18.899028 },
    { lat: 49.251702, lng: 18.900039 },
    { lat: 49.251708, lng: 18.900685 },
    { lat: 49.251708, lng: 18.900688 },
    { lat: 49.251907, lng: 18.901746 },
    { lat: 49.252207, lng: 18.902015 },
    { lat: 49.252555, lng: 18.902455 },
    { lat: 49.252965, lng: 18.903146 },
    { lat: 49.25331, lng: 18.903539 },
    { lat: 49.253808, lng: 18.903913 },
    { lat: 49.253812, lng: 18.903917 },
    { lat: 49.254284, lng: 18.904205 },
    { lat: 49.254407, lng: 18.904264 },
    { lat: 49.254737, lng: 18.904425 },
    { lat: 49.255358, lng: 18.9051 },
    { lat: 49.255416, lng: 18.905177 },
    { lat: 49.255668, lng: 18.905515 },
    { lat: 49.255668, lng: 18.905516 },
    { lat: 49.255751, lng: 18.90602 },
    { lat: 49.255882, lng: 18.906418 },
    { lat: 49.255944, lng: 18.906594 },
    { lat: 49.256128, lng: 18.907067 },
    { lat: 49.256332, lng: 18.907489 },
    { lat: 49.256353, lng: 18.907548 },
    { lat: 49.257186, lng: 18.909214 },
    { lat: 49.25729, lng: 18.909774 },
    { lat: 49.257428, lng: 18.910186 },
    { lat: 49.257461, lng: 18.910285 },
    { lat: 49.257489, lng: 18.910336 },
    { lat: 49.257496, lng: 18.910348 },
    { lat: 49.257522, lng: 18.910397 },
    { lat: 49.257633, lng: 18.910602 },
    { lat: 49.257634, lng: 18.910603 },
    { lat: 49.258018, lng: 18.910824 },
    { lat: 49.258373, lng: 18.911304 },
    { lat: 49.258433, lng: 18.911322 },
    { lat: 49.258542, lng: 18.911414 },
    { lat: 49.258654, lng: 18.911532 },
    { lat: 49.258876, lng: 18.911935 },
    { lat: 49.259029, lng: 18.912305 },
    { lat: 49.259379, lng: 18.913269 },
    { lat: 49.26034, lng: 18.914974 },
    { lat: 49.261272, lng: 18.916512 },
    { lat: 49.261273, lng: 18.916514 },
    { lat: 49.261202, lng: 18.916723 },
    { lat: 49.261057, lng: 18.917058 },
    { lat: 49.260976, lng: 18.91725 },
    { lat: 49.260921, lng: 18.917586 },
    { lat: 49.260911, lng: 18.917654 },
    { lat: 49.260909, lng: 18.917656 },
    { lat: 49.261115, lng: 18.91856 },
    { lat: 49.261308, lng: 18.919358 },
    { lat: 49.261469, lng: 18.920146 },
    { lat: 49.261547, lng: 18.920536 },
    { lat: 49.26157, lng: 18.920616 },
    { lat: 49.261906, lng: 18.921791 },
    { lat: 49.262249, lng: 18.923 },
    { lat: 49.26225, lng: 18.923001 },
    { lat: 49.262212, lng: 18.923583 },
    { lat: 49.262212, lng: 18.923584 },
    { lat: 49.26229, lng: 18.924376 },
    { lat: 49.262363, lng: 18.924671 },
    { lat: 49.262695, lng: 18.924947 },
    { lat: 49.262746, lng: 18.925182 },
    { lat: 49.262941, lng: 18.926079 },
    { lat: 49.263352, lng: 18.926834 },
    { lat: 49.263355, lng: 18.926841 },
    { lat: 49.264059, lng: 18.927626 },
    { lat: 49.264109, lng: 18.927682 },
    { lat: 49.264117, lng: 18.92769 },
    { lat: 49.264905, lng: 18.927856 },
    { lat: 49.265459, lng: 18.927772 },
    { lat: 49.266327, lng: 18.927708 },
    { lat: 49.266753, lng: 18.927671 },
    { lat: 49.267105, lng: 18.92764 },
    { lat: 49.267643, lng: 18.927824 },
    { lat: 49.268051, lng: 18.928083 },
    { lat: 49.268178, lng: 18.928121 },
    { lat: 49.268408, lng: 18.928191 },
    { lat: 49.268668, lng: 18.928276 },
    { lat: 49.269055, lng: 18.928405 },
    { lat: 49.269065, lng: 18.928408 },
    { lat: 49.269205, lng: 18.928197 },
    { lat: 49.26921, lng: 18.92819 },
    { lat: 49.269593, lng: 18.928487 },
    { lat: 49.269597, lng: 18.92849 },
    { lat: 49.269726, lng: 18.928494 },
    { lat: 49.269947, lng: 18.9285 },
    { lat: 49.270206, lng: 18.928457 },
    { lat: 49.270286, lng: 18.928444 },
    { lat: 49.270795, lng: 18.928331 },
    { lat: 49.270848, lng: 18.928319 },
    { lat: 49.270849, lng: 18.928319 },
    { lat: 49.271111, lng: 18.928369 },
    { lat: 49.271459, lng: 18.928909 },
    { lat: 49.271759, lng: 18.928857 },
    { lat: 49.272117, lng: 18.928791 },
    { lat: 49.272115, lng: 18.928787 },
    { lat: 49.272104, lng: 18.928755 },
    { lat: 49.271932, lng: 18.927916 },
    { lat: 49.271931, lng: 18.927915 },
    { lat: 49.271933, lng: 18.927914 },
    { lat: 49.271961, lng: 18.927873 },
    { lat: 49.272174, lng: 18.927499 },
    { lat: 49.272215, lng: 18.927429 },
    { lat: 49.272736, lng: 18.927629 },
    { lat: 49.273292, lng: 18.927776 },
    { lat: 49.273439, lng: 18.927818 },
    { lat: 49.273702, lng: 18.927739 },
    { lat: 49.273915, lng: 18.927826 },
    { lat: 49.274004, lng: 18.927862 },
    { lat: 49.274049, lng: 18.927728 },
    { lat: 49.27406, lng: 18.927695 },
    { lat: 49.274371, lng: 18.927659 },
    { lat: 49.274724, lng: 18.927593 },
    { lat: 49.274791, lng: 18.927603 },
    { lat: 49.274955, lng: 18.927626 },
    { lat: 49.275096, lng: 18.927642 },
    { lat: 49.275304, lng: 18.927642 },
    { lat: 49.275529, lng: 18.927641 },
    { lat: 49.275599, lng: 18.927869 },
    { lat: 49.275779, lng: 18.928353 },
    { lat: 49.27607, lng: 18.928463 },
    { lat: 49.276311, lng: 18.928895 },
    { lat: 49.276442, lng: 18.929083 },
    { lat: 49.276641, lng: 18.929529 },
    { lat: 49.276858, lng: 18.930844 },
    { lat: 49.276862, lng: 18.930841 },
    { lat: 49.277029, lng: 18.930745 },
    { lat: 49.277034, lng: 18.930747 },
    { lat: 49.277199, lng: 18.930783 },
    { lat: 49.277713, lng: 18.930897 },
    { lat: 49.277759, lng: 18.930911 },
    { lat: 49.277789, lng: 18.930885 },
    { lat: 49.277902, lng: 18.930785 },
    { lat: 49.277958, lng: 18.930645 },
    { lat: 49.278249, lng: 18.929915 },
    { lat: 49.278808, lng: 18.928542 },
    { lat: 49.278917, lng: 18.928427 },
    { lat: 49.279006, lng: 18.928337 },
    { lat: 49.279025, lng: 18.928318 },
    { lat: 49.279153, lng: 18.928189 },
    { lat: 49.27926, lng: 18.92808 },
    { lat: 49.279495, lng: 18.928307 },
    { lat: 49.280059, lng: 18.928542 },
    { lat: 49.280472, lng: 18.928773 },
    { lat: 49.281693, lng: 18.928973 },
    { lat: 49.282099, lng: 18.928682 },
    { lat: 49.282593, lng: 18.928881 },
    { lat: 49.283208, lng: 18.929144 },
    { lat: 49.283816, lng: 18.929466 },
    { lat: 49.284401, lng: 18.929763 },
    { lat: 49.284637, lng: 18.929965 },
    { lat: 49.284767, lng: 18.930077 },
    { lat: 49.285043, lng: 18.929786 },
    { lat: 49.285196, lng: 18.929626 },
    { lat: 49.286103, lng: 18.929913 },
    { lat: 49.286416, lng: 18.930139 },
    { lat: 49.286722, lng: 18.930303 },
    { lat: 49.28704, lng: 18.93052 },
    { lat: 49.287264, lng: 18.930463 },
    { lat: 49.287452, lng: 18.930414 },
    { lat: 49.287587, lng: 18.930119 },
    { lat: 49.288356, lng: 18.929672 },
    { lat: 49.288499, lng: 18.929608 },
    { lat: 49.28871, lng: 18.929515 },
    { lat: 49.288968, lng: 18.929072 },
    { lat: 49.289337, lng: 18.928618 },
    { lat: 49.289582, lng: 18.927675 },
    { lat: 49.289747, lng: 18.927368 },
    { lat: 49.290467, lng: 18.926674 },
    { lat: 49.290508, lng: 18.926635 },
    { lat: 49.290805, lng: 18.926085 },
    { lat: 49.291075, lng: 18.925586 },
    { lat: 49.291077, lng: 18.925581 },
    { lat: 49.291092, lng: 18.924691 },
    { lat: 49.291288, lng: 18.923119 },
    { lat: 49.291342, lng: 18.9227 },
    { lat: 49.291349, lng: 18.92264 },
    { lat: 49.29135, lng: 18.922605 },
    { lat: 49.291385, lng: 18.921979 },
    { lat: 49.29167, lng: 18.920763 },
    { lat: 49.292111, lng: 18.92059 },
    { lat: 49.292546, lng: 18.920457 },
    { lat: 49.292756, lng: 18.920401 },
    { lat: 49.292809, lng: 18.920401 },
    { lat: 49.293057, lng: 18.920405 },
    { lat: 49.293192, lng: 18.920407 },
    { lat: 49.293211, lng: 18.920408 },
    { lat: 49.293254, lng: 18.920386 },
    { lat: 49.293561, lng: 18.92023 },
    { lat: 49.293959, lng: 18.920145 },
    { lat: 49.294103, lng: 18.920114 },
    { lat: 49.294703, lng: 18.919627 },
    { lat: 49.295047, lng: 18.919348 },
    { lat: 49.295434, lng: 18.919254 },
    { lat: 49.295469, lng: 18.919247 },
    { lat: 49.295527, lng: 18.919232 },
    { lat: 49.295641, lng: 18.919258 },
    { lat: 49.295682, lng: 18.919267 },
    { lat: 49.295867, lng: 18.919221 },
    { lat: 49.296206, lng: 18.919137 },
    { lat: 49.296241, lng: 18.919129 },
    { lat: 49.296306, lng: 18.919075 },
    { lat: 49.296449, lng: 18.918957 },
    { lat: 49.296452, lng: 18.918955 },
    { lat: 49.296874, lng: 18.918249 },
    { lat: 49.297068, lng: 18.917768 },
    { lat: 49.297068, lng: 18.917766 },
    { lat: 49.297314, lng: 18.917673 },
    { lat: 49.297747, lng: 18.917475 },
    { lat: 49.297795, lng: 18.917411 },
    { lat: 49.297945, lng: 18.917213 },
    { lat: 49.297999, lng: 18.916893 },
    { lat: 49.298051, lng: 18.916581 },
    { lat: 49.298075, lng: 18.91627 },
    { lat: 49.298023, lng: 18.915782 },
    { lat: 49.297772, lng: 18.914218 },
    { lat: 49.297703, lng: 18.913785 },
    { lat: 49.297693, lng: 18.912914 },
    { lat: 49.297605, lng: 18.912403 },
    { lat: 49.298171, lng: 18.91065 },
    { lat: 49.298269, lng: 18.910386 },
    { lat: 49.298565, lng: 18.909589 },
    { lat: 49.298627, lng: 18.909082 },
    { lat: 49.298752, lng: 18.908478 },
    { lat: 49.298895, lng: 18.907825 },
    { lat: 49.298905, lng: 18.907781 },
    { lat: 49.299098, lng: 18.9077 },
    { lat: 49.299278, lng: 18.907207 },
    { lat: 49.299682, lng: 18.906584 },
    { lat: 49.300053, lng: 18.906061 },
    { lat: 49.300086, lng: 18.906065 },
    { lat: 49.300528, lng: 18.906122 },
    { lat: 49.300633, lng: 18.905977 },
    { lat: 49.300652, lng: 18.905331 },
    { lat: 49.300652, lng: 18.905304 },
    { lat: 49.300644, lng: 18.904594 },
    { lat: 49.300668, lng: 18.90393 },
    { lat: 49.300672, lng: 18.903791 },
    { lat: 49.300677, lng: 18.903653 },
    { lat: 49.300697, lng: 18.903051 },
    { lat: 49.300698, lng: 18.903017 },
    { lat: 49.300576, lng: 18.902716 },
    { lat: 49.300284, lng: 18.90221 },
    { lat: 49.300275, lng: 18.902194 },
    { lat: 49.300346, lng: 18.900672 },
    { lat: 49.300351, lng: 18.90056 },
    { lat: 49.3000536, lng: 18.9000936 },
    { lat: 49.2999034, lng: 18.9000002 },
    { lat: 49.2996679, lng: 18.9001141 },
    { lat: 49.299555, lng: 18.9000679 },
    { lat: 49.2994155, lng: 18.8996507 },
    { lat: 49.2991161, lng: 18.8993474 },
    { lat: 49.2989257, lng: 18.8989766 },
    { lat: 49.2987082, lng: 18.898777 },
    { lat: 49.2983744, lng: 18.8985732 },
    { lat: 49.2982174, lng: 18.8982812 },
    { lat: 49.2979437, lng: 18.8982432 },
    { lat: 49.2974976, lng: 18.8977157 },
    { lat: 49.297007, lng: 18.8973123 },
    { lat: 49.2966755, lng: 18.8964696 },
    { lat: 49.2964749, lng: 18.8959577 },
    { lat: 49.2958949, lng: 18.8954834 },
    { lat: 49.2954653, lng: 18.8949525 },
    { lat: 49.2953517, lng: 18.8946687 },
    { lat: 49.295212, lng: 18.8943147 },
    { lat: 49.2947623, lng: 18.8936764 },
    { lat: 49.2939129, lng: 18.8934003 },
    { lat: 49.2933608, lng: 18.8937802 },
    { lat: 49.2931548, lng: 18.8933676 },
    { lat: 49.2932814, lng: 18.8924882 },
    { lat: 49.2933325, lng: 18.8913205 },
    { lat: 49.2932096, lng: 18.8906791 },
    { lat: 49.2930191, lng: 18.8903879 },
    { lat: 49.2929018, lng: 18.8900981 },
    { lat: 49.2928526, lng: 18.8894657 },
    { lat: 49.2929486, lng: 18.8885166 },
    { lat: 49.2926386, lng: 18.8883609 },
    { lat: 49.2926003, lng: 18.8883424 },
    { lat: 49.2923388, lng: 18.8879429 },
    { lat: 49.2921972, lng: 18.8874642 },
    { lat: 49.2923265, lng: 18.8866636 },
    { lat: 49.2924135, lng: 18.8859771 },
    { lat: 49.2923875, lng: 18.885512 },
    { lat: 49.2920272, lng: 18.8847473 },
    { lat: 49.2915547, lng: 18.8829517 },
    { lat: 49.291122, lng: 18.8820692 },
    { lat: 49.290777, lng: 18.8816024 },
    { lat: 49.2904525, lng: 18.881165 },
    { lat: 49.2902917, lng: 18.8809306 },
    { lat: 49.2901261, lng: 18.8806885 },
    { lat: 49.289039, lng: 18.8798254 },
    { lat: 49.2882197, lng: 18.8791433 },
    { lat: 49.2878558, lng: 18.8788413 },
    { lat: 49.2869121, lng: 18.8780426 },
    { lat: 49.2860823, lng: 18.8771136 },
    { lat: 49.2857882, lng: 18.8776254 },
    { lat: 49.2854485, lng: 18.8779949 },
    { lat: 49.2840007, lng: 18.8792504 },
    { lat: 49.2828416, lng: 18.8800465 },
    { lat: 49.2818902, lng: 18.879166 },
    { lat: 49.2818014, lng: 18.8789249 },
    { lat: 49.2816465, lng: 18.8785847 },
    { lat: 49.2813957, lng: 18.8782019 },
    { lat: 49.2798354, lng: 18.877251 },
    { lat: 49.2786742, lng: 18.8766758 },
    { lat: 49.2778049, lng: 18.8762337 },
    { lat: 49.2776688, lng: 18.8761081 },
    { lat: 49.2774333, lng: 18.8758909 },
    { lat: 49.2772441, lng: 18.8757163 },
    { lat: 49.2771993, lng: 18.8756751 },
    { lat: 49.2771943, lng: 18.8756713 },
    { lat: 49.2771221, lng: 18.8756038 },
    { lat: 49.2769451, lng: 18.8754678 },
    { lat: 49.2766353, lng: 18.8752296 },
    { lat: 49.2765613, lng: 18.8751737 },
    { lat: 49.2764135, lng: 18.8750599 },
    { lat: 49.2763747, lng: 18.8750494 },
    { lat: 49.2761472, lng: 18.8749891 },
    { lat: 49.2758842, lng: 18.874919 },
    { lat: 49.2757491, lng: 18.8745913 },
    { lat: 49.2753844, lng: 18.8740271 },
    { lat: 49.2752947, lng: 18.8739696 },
    { lat: 49.2748323, lng: 18.872025 },
    { lat: 49.2748017, lng: 18.8717997 },
    { lat: 49.2747342, lng: 18.8714911 },
    { lat: 49.2746092, lng: 18.8712966 },
    { lat: 49.2746494, lng: 18.8709824 },
    { lat: 49.2747253, lng: 18.8707018 },
    { lat: 49.2747204, lng: 18.8705821 },
    { lat: 49.2747143, lng: 18.8705504 },
    { lat: 49.2746183, lng: 18.8705261 },
    { lat: 49.2742215, lng: 18.870481 },
    { lat: 49.2741982, lng: 18.8703581 },
    { lat: 49.2741204, lng: 18.8697182 },
    { lat: 49.2739432, lng: 18.8697368 },
    { lat: 49.2739004, lng: 18.8688121 },
    { lat: 49.273923, lng: 18.868014 },
    { lat: 49.2738754, lng: 18.8676138 },
    { lat: 49.2738614, lng: 18.8671652 },
    { lat: 49.2738258, lng: 18.8660252 },
    { lat: 49.2737729, lng: 18.8656737 },
    { lat: 49.2735179, lng: 18.8655612 },
    { lat: 49.2734759, lng: 18.8655994 },
    { lat: 49.27335, lng: 18.8657159 },
    { lat: 49.2732353, lng: 18.8657689 },
    { lat: 49.273148, lng: 18.8657797 },
    { lat: 49.2731517, lng: 18.8656767 },
    { lat: 49.2733089, lng: 18.8652846 },
    { lat: 49.2733897, lng: 18.8648186 },
    { lat: 49.2724395, lng: 18.8648013 },
    { lat: 49.272389, lng: 18.8646961 },
    { lat: 49.2722767, lng: 18.8644575 },
    { lat: 49.2721999, lng: 18.8643468 },
    { lat: 49.2721231, lng: 18.864235 },
    { lat: 49.2719058, lng: 18.8639082 },
    { lat: 49.2718138, lng: 18.8638102 },
    { lat: 49.2715028, lng: 18.8634805 },
    { lat: 49.2712456, lng: 18.8631487 },
    { lat: 49.2711942, lng: 18.8632014 },
    { lat: 49.2709608, lng: 18.8628465 },
    { lat: 49.2707609, lng: 18.8625783 },
    { lat: 49.270605, lng: 18.8623702 },
    { lat: 49.2705268, lng: 18.862208 },
    { lat: 49.2699085, lng: 18.8614923 },
    { lat: 49.269684, lng: 18.8613628 },
    { lat: 49.2695771, lng: 18.8613008 },
    { lat: 49.2690865, lng: 18.8611865 },
    { lat: 49.2686394, lng: 18.8607557 },
    { lat: 49.2684237, lng: 18.8605479 },
    { lat: 49.2682968, lng: 18.8604677 },
    { lat: 49.2680369, lng: 18.8603064 },
    { lat: 49.2679856, lng: 18.8602738 },
    { lat: 49.2676503, lng: 18.8600648 },
    { lat: 49.2673863, lng: 18.8599281 },
    { lat: 49.2672113, lng: 18.8598382 },
    { lat: 49.2671084, lng: 18.8597844 },
    { lat: 49.2667949, lng: 18.8592529 },
    { lat: 49.2660351, lng: 18.85864 },
    { lat: 49.2658952, lng: 18.8582661 },
    { lat: 49.2657324, lng: 18.8580581 },
    { lat: 49.2654375, lng: 18.8578663 },
    { lat: 49.2652924, lng: 18.8576761 },
    { lat: 49.2651298, lng: 18.8573428 },
    { lat: 49.2649689, lng: 18.8571505 },
    { lat: 49.2649617, lng: 18.8569221 },
    { lat: 49.2648672, lng: 18.8567745 },
    { lat: 49.2647534, lng: 18.8567527 },
    { lat: 49.2646486, lng: 18.8566201 },
    { lat: 49.2645874, lng: 18.8564585 },
    { lat: 49.2645638, lng: 18.8562602 },
    { lat: 49.2645606, lng: 18.8560913 },
    { lat: 49.2644843, lng: 18.8558986 },
    { lat: 49.2644773, lng: 18.8558486 },
    { lat: 49.2644963, lng: 18.8556705 },
    { lat: 49.2643237, lng: 18.8554287 },
    { lat: 49.2641458, lng: 18.8552358 },
    { lat: 49.2641346, lng: 18.8552329 },
    { lat: 49.2640683, lng: 18.8552162 },
    { lat: 49.2640156, lng: 18.8552027 },
    { lat: 49.2640125, lng: 18.8551828 },
    { lat: 49.2640122, lng: 18.8551785 },
    { lat: 49.2640037, lng: 18.8551214 },
    { lat: 49.2639564, lng: 18.854817 },
    { lat: 49.2639475, lng: 18.8547531 },
    { lat: 49.2638645, lng: 18.8542081 },
    { lat: 49.2638325, lng: 18.8540036 },
    { lat: 49.2638203, lng: 18.8539174 },
    { lat: 49.263821, lng: 18.8535812 },
    { lat: 49.2640827, lng: 18.8533849 },
    { lat: 49.2645061, lng: 18.8530664 },
    { lat: 49.2645886, lng: 18.8530038 },
    { lat: 49.2645553, lng: 18.852902 },
    { lat: 49.2645392, lng: 18.8528559 },
    { lat: 49.2645204, lng: 18.8527972 },
    { lat: 49.2642553, lng: 18.8519993 },
    { lat: 49.2642793, lng: 18.8519845 },
    { lat: 49.2641955, lng: 18.851801 },
    { lat: 49.2640128, lng: 18.851402 },
    { lat: 49.2639885, lng: 18.8513478 },
    { lat: 49.2638076, lng: 18.8511189 },
    { lat: 49.2636278, lng: 18.8508888 },
    { lat: 49.2635308, lng: 18.8507748 },
    { lat: 49.2633205, lng: 18.8505256 },
    { lat: 49.2631351, lng: 18.8504489 },
    { lat: 49.2630615, lng: 18.850418 },
    { lat: 49.262762, lng: 18.850194 },
    { lat: 49.262513, lng: 18.850588 },
    { lat: 49.262202, lng: 18.850922 },
    { lat: 49.261864, lng: 18.851146 },
    { lat: 49.261493, lng: 18.851277 },
    { lat: 49.261152, lng: 18.851469 },
    { lat: 49.260982, lng: 18.851632 },
    { lat: 49.260946, lng: 18.851662 },
    { lat: 49.260874, lng: 18.851735 },
    { lat: 49.260693, lng: 18.851867 },
    { lat: 49.26036, lng: 18.852006 },
    { lat: 49.260112, lng: 18.852102 },
    { lat: 49.260083, lng: 18.851958 },
    { lat: 49.259824, lng: 18.852083 },
    { lat: 49.259274, lng: 18.852352 },
    { lat: 49.259249, lng: 18.852366 },
    { lat: 49.259114, lng: 18.852426 },
    { lat: 49.258929, lng: 18.852641 },
    { lat: 49.25877, lng: 18.853084 },
    { lat: 49.258624, lng: 18.853514 },
    { lat: 49.258418, lng: 18.854038 },
    { lat: 49.258286, lng: 18.854437 },
    { lat: 49.257976, lng: 18.854019 },
    { lat: 49.258012, lng: 18.853834 },
    { lat: 49.257633, lng: 18.85361 },
    { lat: 49.257417, lng: 18.853485 },
    { lat: 49.257289, lng: 18.853396 },
    { lat: 49.257061, lng: 18.853226 },
    { lat: 49.256627, lng: 18.852901 },
    { lat: 49.256563, lng: 18.853024 },
    { lat: 49.256413, lng: 18.853303 },
    { lat: 49.256436, lng: 18.853554 },
    { lat: 49.256313, lng: 18.853692 },
    { lat: 49.256366, lng: 18.853844 },
    { lat: 49.256109, lng: 18.854076 },
    { lat: 49.255933, lng: 18.854218 },
    { lat: 49.256093, lng: 18.854731 },
    { lat: 49.256266, lng: 18.855302 },
    { lat: 49.256017, lng: 18.855309 },
    { lat: 49.255775, lng: 18.855329 },
    { lat: 49.255709, lng: 18.855844 },
    { lat: 49.255406, lng: 18.856018 },
    { lat: 49.255466, lng: 18.8562 },
    { lat: 49.255417, lng: 18.856218 },
    { lat: 49.255391, lng: 18.856227 },
    { lat: 49.2551, lng: 18.856327 },
    { lat: 49.254984, lng: 18.856368 },
    { lat: 49.254909, lng: 18.856381 },
    { lat: 49.254641, lng: 18.856415 },
    { lat: 49.254381, lng: 18.856395 },
    { lat: 49.254343, lng: 18.8564 },
    { lat: 49.25372, lng: 18.856434 },
    { lat: 49.25347, lng: 18.856401 },
    { lat: 49.253466, lng: 18.856912 },
    { lat: 49.253464, lng: 18.857011 },
    { lat: 49.253463, lng: 18.857145 },
    { lat: 49.253323, lng: 18.857108 },
    { lat: 49.253288, lng: 18.857347 },
    { lat: 49.253276, lng: 18.857403 },
    { lat: 49.253051, lng: 18.857443 },
    { lat: 49.252849, lng: 18.857468 },
    { lat: 49.25274, lng: 18.8573838 },
    { lat: 49.252739, lng: 18.857383 },
    { lat: 49.2527, lng: 18.857348 },
    { lat: 49.252454, lng: 18.857388 },
    { lat: 49.252134, lng: 18.857345 },
    { lat: 49.252021, lng: 18.857324 },
    { lat: 49.251766, lng: 18.85741 },
    { lat: 49.251367, lng: 18.856912 },
    { lat: 49.250838, lng: 18.85635 },
    { lat: 49.25055, lng: 18.85619 },
    { lat: 49.250282, lng: 18.856036 },
    { lat: 49.250276, lng: 18.856035 },
    { lat: 49.2499, lng: 18.855946 },
    { lat: 49.2499, lng: 18.855949 },
    { lat: 49.249833, lng: 18.856457 },
    { lat: 49.249754, lng: 18.857137 },
    { lat: 49.249696, lng: 18.857664 },
    { lat: 49.249683, lng: 18.857753 },
    { lat: 49.249596, lng: 18.858685 },
    { lat: 49.249581, lng: 18.858867 },
    { lat: 49.249048, lng: 18.858763 },
    { lat: 49.24904, lng: 18.85881 },
    { lat: 49.249032, lng: 18.85886 },
    { lat: 49.248935, lng: 18.85927 },
    { lat: 49.248879, lng: 18.859526 },
    { lat: 49.248752, lng: 18.860092 },
    { lat: 49.248729, lng: 18.860184 },
    { lat: 49.248669, lng: 18.860458 },
    { lat: 49.248598, lng: 18.860441 },
    { lat: 49.248428, lng: 18.860401 },
    { lat: 49.248427, lng: 18.860407 },
    { lat: 49.248287, lng: 18.861116 },
    { lat: 49.248348, lng: 18.861396 },
    { lat: 49.248511, lng: 18.86214 },
    { lat: 49.248488, lng: 18.862733 },
    { lat: 49.248463, lng: 18.862796 },
    { lat: 49.248453, lng: 18.862814 },
    { lat: 49.248584, lng: 18.862935 },
    { lat: 49.248595, lng: 18.862943 },
    { lat: 49.248597, lng: 18.862946 },
    { lat: 49.248645, lng: 18.862982 },
    { lat: 49.24874, lng: 18.863044 },
    { lat: 49.248763, lng: 18.863063 },
    { lat: 49.248777, lng: 18.863074 },
    { lat: 49.248778, lng: 18.863073 },
    { lat: 49.248963, lng: 18.863003 },
    { lat: 49.249067, lng: 18.862962 },
    { lat: 49.249141, lng: 18.862928 },
    { lat: 49.24917, lng: 18.862923 },
    { lat: 49.249174, lng: 18.862923 },
    { lat: 49.249193, lng: 18.862921 },
    { lat: 49.249225, lng: 18.862933 },
    { lat: 49.249286, lng: 18.862957 },
    { lat: 49.249345, lng: 18.862983 },
    { lat: 49.249407, lng: 18.863009 },
    { lat: 49.249477, lng: 18.862999 },
    { lat: 49.249551, lng: 18.862984 },
    { lat: 49.249628, lng: 18.86307 },
    { lat: 49.249687, lng: 18.863136 },
    { lat: 49.249734, lng: 18.863194 },
    { lat: 49.249741, lng: 18.863202 },
    { lat: 49.249773, lng: 18.863214 },
    { lat: 49.249815, lng: 18.863232 },
    { lat: 49.249841, lng: 18.863244 },
    { lat: 49.249909, lng: 18.863271 },
    { lat: 49.249927, lng: 18.863285 },
    { lat: 49.249947, lng: 18.8633 },
    { lat: 49.24999, lng: 18.863309 },
    { lat: 49.250066, lng: 18.863325 },
    { lat: 49.250102, lng: 18.86333 },
    { lat: 49.250152, lng: 18.863366 },
    { lat: 49.250219, lng: 18.86341 },
    { lat: 49.250258, lng: 18.863438 },
    { lat: 49.250279, lng: 18.863448 },
    { lat: 49.250399, lng: 18.863496 },
    { lat: 49.250408, lng: 18.863504 },
    { lat: 49.250446, lng: 18.863529 },
    { lat: 49.2505, lng: 18.863564 },
    { lat: 49.250556, lng: 18.8636 },
    { lat: 49.250562, lng: 18.863604 },
    { lat: 49.250594, lng: 18.863615 },
    { lat: 49.250658, lng: 18.8636 },
    { lat: 49.250671, lng: 18.863602 },
    { lat: 49.250699, lng: 18.863606 },
    { lat: 49.250716, lng: 18.863609 },
    { lat: 49.25075, lng: 18.863616 },
    { lat: 49.250774, lng: 18.863626 },
    { lat: 49.250792, lng: 18.863627 },
    { lat: 49.25077, lng: 18.863682 },
    { lat: 49.250768, lng: 18.863686 },
    { lat: 49.250787, lng: 18.863721 },
  ],
  KysuckéNovéMesto: [
    { lat: 49.2860892, lng: 18.8506892 },
    { lat: 49.2861108, lng: 18.8513566 },
    { lat: 49.2861705, lng: 18.8518612 },
    { lat: 49.2860523, lng: 18.852216 },
    { lat: 49.2859105, lng: 18.8530355 },
    { lat: 49.2857611, lng: 18.8538826 },
    { lat: 49.2857479, lng: 18.854881 },
    { lat: 49.2858265, lng: 18.8553819 },
    { lat: 49.285829, lng: 18.8557818 },
    { lat: 49.2859445, lng: 18.8559475 },
    { lat: 49.2860706, lng: 18.8567995 },
    { lat: 49.2863302, lng: 18.8576898 },
    { lat: 49.2864595, lng: 18.8584773 },
    { lat: 49.2867493, lng: 18.8595789 },
    { lat: 49.2871635, lng: 18.8605544 },
    { lat: 49.2876882, lng: 18.8618666 },
    { lat: 49.2878696, lng: 18.8624568 },
    { lat: 49.2879017, lng: 18.8632187 },
    { lat: 49.2880885, lng: 18.8642485 },
    { lat: 49.2882039, lng: 18.8649856 },
    { lat: 49.2882959, lng: 18.8666111 },
    { lat: 49.288168, lng: 18.8679692 },
    { lat: 49.2880046, lng: 18.8687429 },
    { lat: 49.28772, lng: 18.8690864 },
    { lat: 49.287463, lng: 18.8694851 },
    { lat: 49.2874429, lng: 18.8700643 },
    { lat: 49.2870885, lng: 18.8712471 },
    { lat: 49.2870331, lng: 18.8719952 },
    { lat: 49.2869383, lng: 18.8726321 },
    { lat: 49.2868263, lng: 18.8737531 },
    { lat: 49.2867736, lng: 18.8747067 },
    { lat: 49.2866435, lng: 18.8754944 },
    { lat: 49.2863415, lng: 18.876624 },
    { lat: 49.2860823, lng: 18.8771136 },
    { lat: 49.2869121, lng: 18.8780426 },
    { lat: 49.2878558, lng: 18.8788413 },
    { lat: 49.2882197, lng: 18.8791433 },
    { lat: 49.289039, lng: 18.8798254 },
    { lat: 49.2901261, lng: 18.8806885 },
    { lat: 49.2902842, lng: 18.880121 },
    { lat: 49.2904673, lng: 18.8794209 },
    { lat: 49.2905973, lng: 18.8783883 },
    { lat: 49.2908058, lng: 18.8774128 },
    { lat: 49.2909429, lng: 18.8768539 },
    { lat: 49.2910647, lng: 18.8763893 },
    { lat: 49.2910672, lng: 18.8755998 },
    { lat: 49.291129, lng: 18.875042 },
    { lat: 49.2914299, lng: 18.8744188 },
    { lat: 49.2918697, lng: 18.8737361 },
    { lat: 49.2921041, lng: 18.8733397 },
    { lat: 49.2923978, lng: 18.8727282 },
    { lat: 49.2927047, lng: 18.8723173 },
    { lat: 49.2929516, lng: 18.8720065 },
    { lat: 49.292983, lng: 18.8716271 },
    { lat: 49.2922103, lng: 18.8706707 },
    { lat: 49.2922526, lng: 18.8704616 },
    { lat: 49.2925323, lng: 18.8703498 },
    { lat: 49.2925883, lng: 18.8701665 },
    { lat: 49.2926839, lng: 18.8699889 },
    { lat: 49.2929843, lng: 18.8695973 },
    { lat: 49.2933495, lng: 18.8692214 },
    { lat: 49.2934255, lng: 18.8691422 },
    { lat: 49.2938582, lng: 18.8690505 },
    { lat: 49.2939362, lng: 18.8690343 },
    { lat: 49.2943663, lng: 18.8691737 },
    { lat: 49.2944705, lng: 18.8692075 },
    { lat: 49.2947124, lng: 18.8693499 },
    { lat: 49.2949182, lng: 18.8694709 },
    { lat: 49.2952334, lng: 18.8671026 },
    { lat: 49.2958014, lng: 18.8655313 },
    { lat: 49.2965337, lng: 18.8647662 },
    { lat: 49.2969647, lng: 18.8639124 },
    { lat: 49.297252, lng: 18.8631592 },
    { lat: 49.2975564, lng: 18.8628291 },
    { lat: 49.2975842, lng: 18.862799 },
    { lat: 49.2982059, lng: 18.8621262 },
    { lat: 49.2980938, lng: 18.8605717 },
    { lat: 49.2980796, lng: 18.8602295 },
    { lat: 49.298065, lng: 18.8598736 },
    { lat: 49.2966171, lng: 18.8575567 },
    { lat: 49.2961711, lng: 18.8568391 },
    { lat: 49.2957216, lng: 18.8561198 },
    { lat: 49.2955734, lng: 18.8554909 },
    { lat: 49.2957409, lng: 18.8542026 },
    { lat: 49.2957642, lng: 18.8539563 },
    { lat: 49.2957352, lng: 18.8525506 },
    { lat: 49.295769, lng: 18.8523327 },
    { lat: 49.2960184, lng: 18.8519619 },
    { lat: 49.2961742, lng: 18.8515903 },
    { lat: 49.2962657, lng: 18.8509554 },
    { lat: 49.2963691, lng: 18.8500042 },
    { lat: 49.2964345, lng: 18.8490799 },
    { lat: 49.2965431, lng: 18.847239 },
    { lat: 49.2964868, lng: 18.8465561 },
    { lat: 49.2964364, lng: 18.8461257 },
    { lat: 49.2963095, lng: 18.8459119 },
    { lat: 49.2962736, lng: 18.845456 },
    { lat: 49.2962866, lng: 18.8454248 },
    { lat: 49.2965194, lng: 18.8448417 },
    { lat: 49.2965405, lng: 18.8447918 },
    { lat: 49.2965756, lng: 18.8447013 },
    { lat: 49.2965855, lng: 18.8447022 },
    { lat: 49.2966026, lng: 18.8447038 },
    { lat: 49.2967454, lng: 18.8447181 },
    { lat: 49.2970911, lng: 18.8447522 },
    { lat: 49.2971079, lng: 18.8447534 },
    { lat: 49.2973043, lng: 18.8446432 },
    { lat: 49.2975153, lng: 18.8446486 },
    { lat: 49.297711, lng: 18.844572 },
    { lat: 49.297759, lng: 18.8442538 },
    { lat: 49.2978147, lng: 18.8438891 },
    { lat: 49.2977185, lng: 18.8437081 },
    { lat: 49.2976532, lng: 18.8436202 },
    { lat: 49.297677, lng: 18.8433609 },
    { lat: 49.2980643, lng: 18.8429983 },
    { lat: 49.2981243, lng: 18.8428044 },
    { lat: 49.2994093, lng: 18.8429104 },
    { lat: 49.2989931, lng: 18.8427956 },
    { lat: 49.2989069, lng: 18.8425667 },
    { lat: 49.2994231, lng: 18.8424084 },
    { lat: 49.299579, lng: 18.8421039 },
    { lat: 49.2998278, lng: 18.8415932 },
    { lat: 49.2997781, lng: 18.8415266 },
    { lat: 49.3002794, lng: 18.8413657 },
    { lat: 49.3002344, lng: 18.8404419 },
    { lat: 49.3000188, lng: 18.840131 },
    { lat: 49.2998594, lng: 18.8398156 },
    { lat: 49.299867, lng: 18.8397182 },
    { lat: 49.3002154, lng: 18.8397227 },
    { lat: 49.300471, lng: 18.839804 },
    { lat: 49.3005886, lng: 18.8398311 },
    { lat: 49.3007028, lng: 18.8397917 },
    { lat: 49.3009508, lng: 18.8394845 },
    { lat: 49.3013472, lng: 18.8396266 },
    { lat: 49.3016391, lng: 18.8395381 },
    { lat: 49.3022935, lng: 18.8395456 },
    { lat: 49.3030682, lng: 18.8378785 },
    { lat: 49.3035422, lng: 18.8369881 },
    { lat: 49.304342, lng: 18.8359664 },
    { lat: 49.3046631, lng: 18.8352345 },
    { lat: 49.3044031, lng: 18.8351544 },
    { lat: 49.3044447, lng: 18.8347144 },
    { lat: 49.3043512, lng: 18.8343658 },
    { lat: 49.3041939, lng: 18.8340255 },
    { lat: 49.3039325, lng: 18.8334575 },
    { lat: 49.3037081, lng: 18.8328414 },
    { lat: 49.3035856, lng: 18.8318188 },
    { lat: 49.3031677, lng: 18.8317151 },
    { lat: 49.303082, lng: 18.8313883 },
    { lat: 49.3030458, lng: 18.8312535 },
    { lat: 49.302802, lng: 18.8303261 },
    { lat: 49.3025251, lng: 18.8300505 },
    { lat: 49.3025256, lng: 18.8298629 },
    { lat: 49.3025681, lng: 18.8295713 },
    { lat: 49.3025715, lng: 18.8293483 },
    { lat: 49.3024887, lng: 18.8286393 },
    { lat: 49.3025858, lng: 18.8284863 },
    { lat: 49.3027539, lng: 18.8284011 },
    { lat: 49.3029876, lng: 18.8283202 },
    { lat: 49.3031852, lng: 18.8280877 },
    { lat: 49.3035013, lng: 18.8278285 },
    { lat: 49.3045408, lng: 18.8266471 },
    { lat: 49.3045348, lng: 18.8254089 },
    { lat: 49.3047575, lng: 18.8250714 },
    { lat: 49.3047035, lng: 18.8243715 },
    { lat: 49.3046949, lng: 18.8231979 },
    { lat: 49.3047198, lng: 18.822607 },
    { lat: 49.3048051, lng: 18.821877 },
    { lat: 49.3048847, lng: 18.8209681 },
    { lat: 49.3047784, lng: 18.8208946 },
    { lat: 49.3047656, lng: 18.8206703 },
    { lat: 49.3047647, lng: 18.8204124 },
    { lat: 49.3048265, lng: 18.8199013 },
    { lat: 49.3048645, lng: 18.8195889 },
    { lat: 49.3048899, lng: 18.8193839 },
    { lat: 49.3050187, lng: 18.8190601 },
    { lat: 49.3053806, lng: 18.818773 },
    { lat: 49.3056504, lng: 18.8184284 },
    { lat: 49.3059385, lng: 18.8181137 },
    { lat: 49.3064123, lng: 18.8179012 },
    { lat: 49.3066875, lng: 18.8178988 },
    { lat: 49.3067279, lng: 18.8178766 },
    { lat: 49.3069133, lng: 18.8177737 },
    { lat: 49.3069888, lng: 18.8174455 },
    { lat: 49.3069571, lng: 18.8171151 },
    { lat: 49.3068539, lng: 18.816974 },
    { lat: 49.3066707, lng: 18.8167196 },
    { lat: 49.3062885, lng: 18.8164148 },
    { lat: 49.3061396, lng: 18.8161065 },
    { lat: 49.3063992, lng: 18.8158927 },
    { lat: 49.306581, lng: 18.81441 },
    { lat: 49.3067152, lng: 18.8138182 },
    { lat: 49.3068457, lng: 18.8137685 },
    { lat: 49.3070256, lng: 18.8135882 },
    { lat: 49.3072469, lng: 18.8134622 },
    { lat: 49.3071476, lng: 18.8125394 },
    { lat: 49.3072681, lng: 18.8117682 },
    { lat: 49.3072376, lng: 18.8111598 },
    { lat: 49.3074717, lng: 18.8103832 },
    { lat: 49.3077374, lng: 18.8098955 },
    { lat: 49.3079714, lng: 18.8096462 },
    { lat: 49.3080773, lng: 18.8096012 },
    { lat: 49.3084061, lng: 18.8088248 },
    { lat: 49.3085243, lng: 18.8087115 },
    { lat: 49.3090967, lng: 18.8077427 },
    { lat: 49.3093303, lng: 18.8069589 },
    { lat: 49.3092743, lng: 18.806707 },
    { lat: 49.3092011, lng: 18.8064238 },
    { lat: 49.3087938, lng: 18.8058518 },
    { lat: 49.3088291, lng: 18.8053532 },
    { lat: 49.3093658, lng: 18.8055714 },
    { lat: 49.3096328, lng: 18.8042126 },
    { lat: 49.3102292, lng: 18.8045609 },
    { lat: 49.3106248, lng: 18.8034468 },
    { lat: 49.3106286, lng: 18.8030578 },
    { lat: 49.3107004, lng: 18.8019163 },
    { lat: 49.3106153, lng: 18.8017965 },
    { lat: 49.3105961, lng: 18.8015578 },
    { lat: 49.3105123, lng: 18.8015036 },
    { lat: 49.3105011, lng: 18.8014438 },
    { lat: 49.3105251, lng: 18.8013174 },
    { lat: 49.3105318, lng: 18.8012692 },
    { lat: 49.3105641, lng: 18.8011298 },
    { lat: 49.3105639, lng: 18.8011266 },
    { lat: 49.3105684, lng: 18.801108 },
    { lat: 49.3105703, lng: 18.8010994 },
    { lat: 49.3105749, lng: 18.8010783 },
    { lat: 49.3105784, lng: 18.8010594 },
    { lat: 49.3105912, lng: 18.8010001 },
    { lat: 49.3106544, lng: 18.8006968 },
    { lat: 49.3111228, lng: 18.8009283 },
    { lat: 49.3113047, lng: 18.8010176 },
    { lat: 49.3114345, lng: 18.8010883 },
    { lat: 49.3119147, lng: 18.8013479 },
    { lat: 49.312054, lng: 18.8014233 },
    { lat: 49.3120747, lng: 18.8014341 },
    { lat: 49.312091, lng: 18.8014434 },
    { lat: 49.3121027, lng: 18.8014506 },
    { lat: 49.3121978, lng: 18.8015035 },
    { lat: 49.312206, lng: 18.8015075 },
    { lat: 49.3122107, lng: 18.8015106 },
    { lat: 49.3122165, lng: 18.8015142 },
    { lat: 49.3122384, lng: 18.801526 },
    { lat: 49.3122941, lng: 18.8015572 },
    { lat: 49.3124197, lng: 18.8016281 },
    { lat: 49.3124255, lng: 18.8016303 },
    { lat: 49.3124658, lng: 18.8016471 },
    { lat: 49.3125233, lng: 18.8016719 },
    { lat: 49.3126434, lng: 18.8017225 },
    { lat: 49.3127931, lng: 18.8017864 },
    { lat: 49.3128023, lng: 18.80179 },
    { lat: 49.3128255, lng: 18.8017992 },
    { lat: 49.3128771, lng: 18.8018193 },
    { lat: 49.3129359, lng: 18.8018426 },
    { lat: 49.3129369, lng: 18.8018428 },
    { lat: 49.3133869, lng: 18.8020194 },
    { lat: 49.3134549, lng: 18.8020459 },
    { lat: 49.3134819, lng: 18.8019786 },
    { lat: 49.3135539, lng: 18.8018034 },
    { lat: 49.3136192, lng: 18.801669 },
    { lat: 49.3139242, lng: 18.8010463 },
    { lat: 49.3139669, lng: 18.8009577 },
    { lat: 49.3139812, lng: 18.8008859 },
    { lat: 49.314002, lng: 18.8007858 },
    { lat: 49.3146432, lng: 18.8001901 },
    { lat: 49.3147965, lng: 18.7998365 },
    { lat: 49.31487, lng: 18.7996462 },
    { lat: 49.3149525, lng: 18.7994314 },
    { lat: 49.3149654, lng: 18.7993976 },
    { lat: 49.3150139, lng: 18.7993994 },
    { lat: 49.3150909, lng: 18.7994024 },
    { lat: 49.3152739, lng: 18.7994092 },
    { lat: 49.3156308, lng: 18.799422 },
    { lat: 49.3157099, lng: 18.7994248 },
    { lat: 49.3158053, lng: 18.799286 },
    { lat: 49.3158775, lng: 18.7991797 },
    { lat: 49.3160773, lng: 18.7990327 },
    { lat: 49.3160819, lng: 18.7990293 },
    { lat: 49.3161384, lng: 18.7989881 },
    { lat: 49.316236, lng: 18.798916 },
    { lat: 49.316236, lng: 18.7989156 },
    { lat: 49.3166466, lng: 18.7986126 },
    { lat: 49.316674, lng: 18.7985996 },
    { lat: 49.3167269, lng: 18.7985755 },
    { lat: 49.3168095, lng: 18.7985369 },
    { lat: 49.3168547, lng: 18.7985159 },
    { lat: 49.3170695, lng: 18.7980451 },
    { lat: 49.31765, lng: 18.7968003 },
    { lat: 49.3177101, lng: 18.7967392 },
    { lat: 49.318021, lng: 18.7966474 },
    { lat: 49.3181168, lng: 18.7966496 },
    { lat: 49.31821, lng: 18.7967052 },
    { lat: 49.3188409, lng: 18.7967156 },
    { lat: 49.3192047, lng: 18.7967974 },
    { lat: 49.3196551, lng: 18.7969823 },
    { lat: 49.320018, lng: 18.7972196 },
    { lat: 49.3205238, lng: 18.7975155 },
    { lat: 49.3207644, lng: 18.7977181 },
    { lat: 49.3209364, lng: 18.7979753 },
    { lat: 49.3212784, lng: 18.7985481 },
    { lat: 49.3216147, lng: 18.7992285 },
    { lat: 49.3214999, lng: 18.7995854 },
    { lat: 49.3214719, lng: 18.7996741 },
    { lat: 49.3214018, lng: 18.7998907 },
    { lat: 49.3211125, lng: 18.8005987 },
    { lat: 49.3211966, lng: 18.8012203 },
    { lat: 49.3212115, lng: 18.8013349 },
    { lat: 49.3212258, lng: 18.8014376 },
    { lat: 49.3212203, lng: 18.8015287 },
    { lat: 49.3212034, lng: 18.8018094 },
    { lat: 49.3212025, lng: 18.8018164 },
    { lat: 49.3214451, lng: 18.8020625 },
    { lat: 49.3225304, lng: 18.8031629 },
    { lat: 49.324036, lng: 18.8040032 },
    { lat: 49.3253391, lng: 18.8049155 },
    { lat: 49.3254209, lng: 18.8047296 },
    { lat: 49.3255229, lng: 18.8044979 },
    { lat: 49.3255378, lng: 18.8044631 },
    { lat: 49.3255965, lng: 18.804331 },
    { lat: 49.3258605, lng: 18.8037421 },
    { lat: 49.3260706, lng: 18.8032725 },
    { lat: 49.3260849, lng: 18.8032415 },
    { lat: 49.3261049, lng: 18.8031953 },
    { lat: 49.3261305, lng: 18.8031405 },
    { lat: 49.3261495, lng: 18.802964 },
    { lat: 49.3261604, lng: 18.8028687 },
    { lat: 49.3261868, lng: 18.8027253 },
    { lat: 49.3262156, lng: 18.802566 },
    { lat: 49.326309, lng: 18.8021459 },
    { lat: 49.3263661, lng: 18.801938 },
    { lat: 49.3264353, lng: 18.8017091 },
    { lat: 49.3266706, lng: 18.8011764 },
    { lat: 49.3266639, lng: 18.8011335 },
    { lat: 49.3267912, lng: 18.8010458 },
    { lat: 49.3268638, lng: 18.8009879 },
    { lat: 49.3270125, lng: 18.8007657 },
    { lat: 49.3270971, lng: 18.8006611 },
    { lat: 49.327116, lng: 18.8005005 },
    { lat: 49.3272315, lng: 18.8002881 },
    { lat: 49.3272747, lng: 18.8002749 },
    { lat: 49.3273606, lng: 18.8002808 },
    { lat: 49.3276616, lng: 18.8001251 },
    { lat: 49.3278146, lng: 18.8000318 },
    { lat: 49.3279688, lng: 18.7999384 },
    { lat: 49.3279827, lng: 18.7999475 },
    { lat: 49.3279979, lng: 18.7999583 },
    { lat: 49.3280096, lng: 18.799966 },
    { lat: 49.3280214, lng: 18.7999736 },
    { lat: 49.3280319, lng: 18.7999799 },
    { lat: 49.3280446, lng: 18.7999885 },
    { lat: 49.3280551, lng: 18.7999948 },
    { lat: 49.3280649, lng: 18.7999886 },
    { lat: 49.3280756, lng: 18.7999985 },
    { lat: 49.3281017, lng: 18.8000239 },
    { lat: 49.3281408, lng: 18.8000629 },
    { lat: 49.3281789, lng: 18.8001002 },
    { lat: 49.3281934, lng: 18.8001386 },
    { lat: 49.3288685, lng: 18.8003556 },
    { lat: 49.3293225, lng: 18.8006091 },
    { lat: 49.3297021, lng: 18.8007957 },
    { lat: 49.3297423, lng: 18.8007912 },
    { lat: 49.329923, lng: 18.8007723 },
    { lat: 49.3304594, lng: 18.8009667 },
    { lat: 49.3304411, lng: 18.8017241 },
    { lat: 49.3304544, lng: 18.8019408 },
    { lat: 49.3304709, lng: 18.802215 },
    { lat: 49.3304851, lng: 18.8024674 },
    { lat: 49.3304633, lng: 18.8025478 },
    { lat: 49.3304612, lng: 18.8025542 },
    { lat: 49.3306176, lng: 18.8030472 },
    { lat: 49.3307782, lng: 18.8036698 },
    { lat: 49.3309623, lng: 18.8038074 },
    { lat: 49.3311898, lng: 18.8039769 },
    { lat: 49.33143, lng: 18.804043 },
    { lat: 49.3316537, lng: 18.8040937 },
    { lat: 49.3316183, lng: 18.8042824 },
    { lat: 49.3322924, lng: 18.8047169 },
    { lat: 49.3324988, lng: 18.8048492 },
    { lat: 49.3326476, lng: 18.8049173 },
    { lat: 49.3327413, lng: 18.8049598 },
    { lat: 49.3328671, lng: 18.8050173 },
    { lat: 49.3328972, lng: 18.8050325 },
    { lat: 49.333007, lng: 18.8051512 },
    { lat: 49.3331909, lng: 18.8053491 },
    { lat: 49.3332279, lng: 18.8053888 },
    { lat: 49.3335442, lng: 18.8048744 },
    { lat: 49.3340128, lng: 18.8043439 },
    { lat: 49.334556, lng: 18.8039008 },
    { lat: 49.3350455, lng: 18.8035977 },
    { lat: 49.3353779, lng: 18.803529 },
    { lat: 49.335458, lng: 18.8029831 },
    { lat: 49.3354656, lng: 18.802933 },
    { lat: 49.3354717, lng: 18.8028097 },
    { lat: 49.3354728, lng: 18.8027662 },
    { lat: 49.3355206, lng: 18.8027091 },
    { lat: 49.3355821, lng: 18.8026373 },
    { lat: 49.3356384, lng: 18.8025698 },
    { lat: 49.3357224, lng: 18.8024703 },
    { lat: 49.3364724, lng: 18.8024318 },
    { lat: 49.3371496, lng: 18.8026374 },
    { lat: 49.3379156, lng: 18.8029773 },
    { lat: 49.3385684, lng: 18.8031703 },
    { lat: 49.3388579, lng: 18.8028823 },
    { lat: 49.3389731, lng: 18.8024504 },
    { lat: 49.3391745, lng: 18.8016096 },
    { lat: 49.3389098, lng: 18.801485 },
    { lat: 49.3389408, lng: 18.8009494 },
    { lat: 49.3392761, lng: 18.8000162 },
    { lat: 49.3392889, lng: 18.7999796 },
    { lat: 49.3393224, lng: 18.7998869 },
    { lat: 49.3395505, lng: 18.7991948 },
    { lat: 49.3398359, lng: 18.7987813 },
    { lat: 49.3400738, lng: 18.7983704 },
    { lat: 49.3402269, lng: 18.7985164 },
    { lat: 49.3404674, lng: 18.7986533 },
    { lat: 49.34081, lng: 18.7984972 },
    { lat: 49.3408225, lng: 18.7980846 },
    { lat: 49.3408539, lng: 18.7975811 },
    { lat: 49.3408765, lng: 18.797122 },
    { lat: 49.3408032, lng: 18.796292 },
    { lat: 49.3407293, lng: 18.7955395 },
    { lat: 49.3407512, lng: 18.7953961 },
    { lat: 49.340769, lng: 18.7950411 },
    { lat: 49.3407471, lng: 18.7947255 },
    { lat: 49.3403952, lng: 18.794528 },
    { lat: 49.3403023, lng: 18.7937532 },
    { lat: 49.3398408, lng: 18.7936615 },
    { lat: 49.3396793, lng: 18.7928671 },
    { lat: 49.3395125, lng: 18.7919993 },
    { lat: 49.3394641, lng: 18.7909533 },
    { lat: 49.3397603, lng: 18.7910111 },
    { lat: 49.3397812, lng: 18.7907844 },
    { lat: 49.3397856, lng: 18.7907354 },
    { lat: 49.3398272, lng: 18.7902748 },
    { lat: 49.3402574, lng: 18.790334 },
    { lat: 49.3402989, lng: 18.7891079 },
    { lat: 49.3403269, lng: 18.7890597 },
    { lat: 49.340258, lng: 18.78786 },
    { lat: 49.3402074, lng: 18.7876441 },
    { lat: 49.340162, lng: 18.7874648 },
    { lat: 49.3400254, lng: 18.7869159 },
    { lat: 49.3399986, lng: 18.7866203 },
    { lat: 49.3399389, lng: 18.7859613 },
    { lat: 49.3399314, lng: 18.7855355 },
    { lat: 49.3399267, lng: 18.7852678 },
    { lat: 49.339884, lng: 18.7848774 },
    { lat: 49.3397757, lng: 18.7842216 },
    { lat: 49.3397177, lng: 18.7834075 },
    { lat: 49.3397419, lng: 18.7833539 },
    { lat: 49.3397462, lng: 18.7833454 },
    { lat: 49.3397299, lng: 18.7832716 },
    { lat: 49.3397123, lng: 18.7831923 },
    { lat: 49.3396649, lng: 18.7829749 },
    { lat: 49.3395116, lng: 18.7823007 },
    { lat: 49.3394506, lng: 18.7817523 },
    { lat: 49.3393374, lng: 18.7813311 },
    { lat: 49.3390178, lng: 18.7807993 },
    { lat: 49.3388572, lng: 18.7804637 },
    { lat: 49.3384795, lng: 18.7799628 },
    { lat: 49.3382368, lng: 18.779524 },
    { lat: 49.3380593, lng: 18.7791221 },
    { lat: 49.3375923, lng: 18.7780513 },
    { lat: 49.3372978, lng: 18.777156 },
    { lat: 49.3372555, lng: 18.7766868 },
    { lat: 49.33718, lng: 18.7760321 },
    { lat: 49.337204, lng: 18.7755823 },
    { lat: 49.3370916, lng: 18.7745676 },
    { lat: 49.3368874, lng: 18.7740186 },
    { lat: 49.3364872, lng: 18.7727392 },
    { lat: 49.3360005, lng: 18.7709854 },
    { lat: 49.3358716, lng: 18.7700682 },
    { lat: 49.3359127, lng: 18.7695755 },
    { lat: 49.3358124, lng: 18.7689025 },
    { lat: 49.3355113, lng: 18.7681184 },
    { lat: 49.3350553, lng: 18.7671527 },
    { lat: 49.3347031, lng: 18.7663217 },
    { lat: 49.334333, lng: 18.766816 },
    { lat: 49.3342234, lng: 18.7669631 },
    { lat: 49.3339814, lng: 18.7671889 },
    { lat: 49.3338283, lng: 18.7669349 },
    { lat: 49.3334056, lng: 18.7658466 },
    { lat: 49.3332281, lng: 18.7656853 },
    { lat: 49.3330719, lng: 18.7654812 },
    { lat: 49.3327325, lng: 18.7650459 },
    { lat: 49.33232, lng: 18.765634 },
    { lat: 49.3320932, lng: 18.7652426 },
    { lat: 49.3320003, lng: 18.7651707 },
    { lat: 49.331698, lng: 18.7651457 },
    { lat: 49.3312017, lng: 18.7648553 },
    { lat: 49.3310143, lng: 18.7648108 },
    { lat: 49.3300976, lng: 18.7644841 },
    { lat: 49.3297947, lng: 18.7644472 },
    { lat: 49.3296067, lng: 18.7643887 },
    { lat: 49.328755, lng: 18.7644911 },
    { lat: 49.3283902, lng: 18.7646324 },
    { lat: 49.3281545, lng: 18.7650214 },
    { lat: 49.3280531, lng: 18.7649561 },
    { lat: 49.3279158, lng: 18.7645697 },
    { lat: 49.3278174, lng: 18.7643723 },
    { lat: 49.3276148, lng: 18.764073 },
    { lat: 49.3274187, lng: 18.7637516 },
    { lat: 49.3271599, lng: 18.7633034 },
    { lat: 49.3271272, lng: 18.7632604 },
    { lat: 49.327097, lng: 18.76322 },
    { lat: 49.3267737, lng: 18.7626642 },
    { lat: 49.3265045, lng: 18.7622577 },
    { lat: 49.3265053, lng: 18.7622501 },
    { lat: 49.3262594, lng: 18.7618335 },
    { lat: 49.3259265, lng: 18.7612436 },
    { lat: 49.3259612, lng: 18.761197 },
    { lat: 49.325773, lng: 18.7611538 },
    { lat: 49.3256195, lng: 18.7611735 },
    { lat: 49.3254599, lng: 18.7610758 },
    { lat: 49.3252196, lng: 18.7609655 },
    { lat: 49.3250837, lng: 18.7609752 },
    { lat: 49.3249802, lng: 18.7609328 },
    { lat: 49.3248621, lng: 18.7609425 },
    { lat: 49.324644, lng: 18.7610377 },
    { lat: 49.3244262, lng: 18.7610342 },
    { lat: 49.3241982, lng: 18.7609668 },
    { lat: 49.324077, lng: 18.7609574 },
    { lat: 49.3239886, lng: 18.7609706 },
    { lat: 49.3236991, lng: 18.7610203 },
    { lat: 49.3235708, lng: 18.7610428 },
    { lat: 49.3235327, lng: 18.760982 },
    { lat: 49.323346, lng: 18.7608399 },
    { lat: 49.3233166, lng: 18.760752 },
    { lat: 49.3232616, lng: 18.7607355 },
    { lat: 49.3232042, lng: 18.7607573 },
    { lat: 49.3231418, lng: 18.7607534 },
    { lat: 49.3230554, lng: 18.7607415 },
    { lat: 49.3229865, lng: 18.7608015 },
    { lat: 49.3228866, lng: 18.7607887 },
    { lat: 49.3227446, lng: 18.7608758 },
    { lat: 49.3226329, lng: 18.7609635 },
    { lat: 49.3225087, lng: 18.7609836 },
    { lat: 49.3223897, lng: 18.7610126 },
    { lat: 49.3222727, lng: 18.7609288 },
    { lat: 49.3221211, lng: 18.7608573 },
    { lat: 49.3219899, lng: 18.7607839 },
    { lat: 49.3219331, lng: 18.7607554 },
    { lat: 49.3218565, lng: 18.7607318 },
    { lat: 49.3216503, lng: 18.7608234 },
    { lat: 49.3215448, lng: 18.7608305 },
    { lat: 49.3214177, lng: 18.7607722 },
    { lat: 49.3213022, lng: 18.7607407 },
    { lat: 49.3210813, lng: 18.7607011 },
    { lat: 49.3208976, lng: 18.7606789 },
    { lat: 49.3208351, lng: 18.7605824 },
    { lat: 49.3208241, lng: 18.7605661 },
    { lat: 49.3207502, lng: 18.7605115 },
    { lat: 49.3206503, lng: 18.7603266 },
    { lat: 49.3205845, lng: 18.760142 },
    { lat: 49.3205167, lng: 18.7600342 },
    { lat: 49.3204131, lng: 18.7599047 },
    { lat: 49.3202868, lng: 18.7597332 },
    { lat: 49.3199621, lng: 18.7594286 },
    { lat: 49.3199204, lng: 18.759386 },
    { lat: 49.3198103, lng: 18.7594171 },
    { lat: 49.3197222, lng: 18.7594545 },
    { lat: 49.319632, lng: 18.7594315 },
    { lat: 49.3195835, lng: 18.7593261 },
    { lat: 49.3195344, lng: 18.759142 },
    { lat: 49.3194387, lng: 18.7590549 },
    { lat: 49.3193722, lng: 18.7589943 },
    { lat: 49.3193368, lng: 18.7588761 },
    { lat: 49.3192806, lng: 18.7586639 },
    { lat: 49.3190221, lng: 18.7585671 },
    { lat: 49.3187078, lng: 18.7582934 },
    { lat: 49.3186079, lng: 18.7582351 },
    { lat: 49.3185268, lng: 18.7582572 },
    { lat: 49.318427, lng: 18.7582686 },
    { lat: 49.3182516, lng: 18.7581504 },
    { lat: 49.3181717, lng: 18.7583261 },
    { lat: 49.3181253, lng: 18.758301 },
    { lat: 49.3180906, lng: 18.758221 },
    { lat: 49.3180068, lng: 18.7580588 },
    { lat: 49.3179355, lng: 18.7580783 },
    { lat: 49.3179084, lng: 18.758143 },
    { lat: 49.3178889, lng: 18.7581588 },
    { lat: 49.3178105, lng: 18.7581448 },
    { lat: 49.3177215, lng: 18.7581011 },
    { lat: 49.3175556, lng: 18.7579684 },
    { lat: 49.317429, lng: 18.7578974 },
    { lat: 49.3174015, lng: 18.7577601 },
    { lat: 49.3173397, lng: 18.7576798 },
    { lat: 49.3172198, lng: 18.7576052 },
    { lat: 49.3171443, lng: 18.7576711 },
    { lat: 49.3170786, lng: 18.7577293 },
    { lat: 49.3169042, lng: 18.7574567 },
    { lat: 49.3168367, lng: 18.7572437 },
    { lat: 49.3168156, lng: 18.757161 },
    { lat: 49.316742, lng: 18.7571363 },
    { lat: 49.3166713, lng: 18.7572082 },
    { lat: 49.3165835, lng: 18.7572724 },
    { lat: 49.3165256, lng: 18.7572866 },
    { lat: 49.3164844, lng: 18.757253 },
    { lat: 49.316421, lng: 18.7572292 },
    { lat: 49.3163511, lng: 18.7571608 },
    { lat: 49.3162788, lng: 18.7570076 },
    { lat: 49.3162481, lng: 18.7568932 },
    { lat: 49.316193, lng: 18.7568746 },
    { lat: 49.3161531, lng: 18.7568018 },
    { lat: 49.3161124, lng: 18.756802 },
    { lat: 49.3160028, lng: 18.7567969 },
    { lat: 49.3158861, lng: 18.7568071 },
    { lat: 49.3158608, lng: 18.756756 },
    { lat: 49.3158468, lng: 18.7566782 },
    { lat: 49.3158462, lng: 18.7565803 },
    { lat: 49.3158041, lng: 18.7564434 },
    { lat: 49.3157535, lng: 18.7564481 },
    { lat: 49.3157503, lng: 18.7564485 },
    { lat: 49.3155216, lng: 18.7564308 },
    { lat: 49.3154478, lng: 18.756425 },
    { lat: 49.3153559, lng: 18.7563892 },
    { lat: 49.3152009, lng: 18.7563169 },
    { lat: 49.3150322, lng: 18.7563675 },
    { lat: 49.3148987, lng: 18.7563176 },
    { lat: 49.3148213, lng: 18.7562771 },
    { lat: 49.3147198, lng: 18.7561713 },
    { lat: 49.3146055, lng: 18.7561212 },
    { lat: 49.3145525, lng: 18.756098 },
    { lat: 49.3144083, lng: 18.7560151 },
    { lat: 49.314358, lng: 18.7559338 },
    { lat: 49.3142625, lng: 18.7559187 },
    { lat: 49.3141285, lng: 18.7558587 },
    { lat: 49.3140462, lng: 18.755795 },
    { lat: 49.3139585, lng: 18.7557348 },
    { lat: 49.3138903, lng: 18.7557457 },
    { lat: 49.3138457, lng: 18.7557558 },
    { lat: 49.3138251, lng: 18.7557915 },
    { lat: 49.313729, lng: 18.7557422 },
    { lat: 49.3137033, lng: 18.7556835 },
    { lat: 49.3137088, lng: 18.7556362 },
    { lat: 49.3137066, lng: 18.7555519 },
    { lat: 49.3136662, lng: 18.755426 },
    { lat: 49.3134484, lng: 18.7556177 },
    { lat: 49.3133828, lng: 18.7556546 },
    { lat: 49.3132326, lng: 18.7554788 },
    { lat: 49.3132052, lng: 18.7553838 },
    { lat: 49.3131153, lng: 18.7553275 },
    { lat: 49.313043, lng: 18.7553464 },
    { lat: 49.3129965, lng: 18.7554291 },
    { lat: 49.3129488, lng: 18.7554826 },
    { lat: 49.3129131, lng: 18.755492 },
    { lat: 49.312835, lng: 18.7554672 },
    { lat: 49.3127848, lng: 18.7554548 },
    { lat: 49.3127436, lng: 18.7554855 },
    { lat: 49.3126906, lng: 18.7555534 },
    { lat: 49.3126069, lng: 18.7555882 },
    { lat: 49.3124374, lng: 18.7556034 },
    { lat: 49.3123136, lng: 18.7555863 },
    { lat: 49.3122624, lng: 18.7555108 },
    { lat: 49.3121701, lng: 18.7554707 },
    { lat: 49.312031, lng: 18.7555498 },
    { lat: 49.3118442, lng: 18.7556235 },
    { lat: 49.3116364, lng: 18.755703 },
    { lat: 49.3114111, lng: 18.7557279 },
    { lat: 49.3112703, lng: 18.7556478 },
    { lat: 49.311074, lng: 18.7556218 },
    { lat: 49.3109251, lng: 18.7556848 },
    { lat: 49.310836, lng: 18.755666 },
    { lat: 49.3106675, lng: 18.7555242 },
    { lat: 49.3105542, lng: 18.7554719 },
    { lat: 49.3104912, lng: 18.755473 },
    { lat: 49.3103501, lng: 18.7555133 },
    { lat: 49.3102038, lng: 18.7554986 },
    { lat: 49.3101012, lng: 18.7553666 },
    { lat: 49.310088, lng: 18.7552847 },
    { lat: 49.3098673, lng: 18.7551854 },
    { lat: 49.3096576, lng: 18.7553598 },
    { lat: 49.3095682, lng: 18.7553317 },
    { lat: 49.3095321, lng: 18.7552631 },
    { lat: 49.3094952, lng: 18.7552484 },
    { lat: 49.3094493, lng: 18.7550805 },
    { lat: 49.3093375, lng: 18.7549077 },
    { lat: 49.3092923, lng: 18.7547972 },
    { lat: 49.3092532, lng: 18.7547358 },
    { lat: 49.3092056, lng: 18.7547333 },
    { lat: 49.3090921, lng: 18.7547406 },
    { lat: 49.3088852, lng: 18.7546194 },
    { lat: 49.3088086, lng: 18.7546013 },
    { lat: 49.3087273, lng: 18.7546643 },
    { lat: 49.3086538, lng: 18.7546816 },
    { lat: 49.308519, lng: 18.7546477 },
    { lat: 49.3082771, lng: 18.7546172 },
    { lat: 49.3081494, lng: 18.7546504 },
    { lat: 49.3080426, lng: 18.7546595 },
    { lat: 49.3078624, lng: 18.7546203 },
    { lat: 49.3077976, lng: 18.7546289 },
    { lat: 49.3076971, lng: 18.7546722 },
    { lat: 49.3075504, lng: 18.7546467 },
    { lat: 49.3074114, lng: 18.7546224 },
    { lat: 49.3073569, lng: 18.7546135 },
    { lat: 49.3070058, lng: 18.7548409 },
    { lat: 49.3069013, lng: 18.7547853 },
    { lat: 49.3068143, lng: 18.7547797 },
    { lat: 49.3065539, lng: 18.7547622 },
    { lat: 49.3062588, lng: 18.7547439 },
    { lat: 49.3060978, lng: 18.754684 },
    { lat: 49.3059681, lng: 18.7547522 },
    { lat: 49.305921, lng: 18.7547771 },
    { lat: 49.3057482, lng: 18.7545766 },
    { lat: 49.3056711, lng: 18.7547428 },
    { lat: 49.305512, lng: 18.755087 },
    { lat: 49.3054031, lng: 18.7553778 },
    { lat: 49.3053291, lng: 18.755535 },
    { lat: 49.3053494, lng: 18.7555809 },
    { lat: 49.305259, lng: 18.7555955 },
    { lat: 49.3051776, lng: 18.7555704 },
    { lat: 49.3050048, lng: 18.7555639 },
    { lat: 49.3049765, lng: 18.7555833 },
    { lat: 49.304959, lng: 18.7556617 },
    { lat: 49.3050378, lng: 18.7558317 },
    { lat: 49.3049778, lng: 18.7559137 },
    { lat: 49.3047747, lng: 18.7561686 },
    { lat: 49.3047232, lng: 18.7561568 },
    { lat: 49.3047212, lng: 18.7561629 },
    { lat: 49.3046784, lng: 18.7563126 },
    { lat: 49.3046049, lng: 18.756611 },
    { lat: 49.3044359, lng: 18.7567884 },
    { lat: 49.3043404, lng: 18.7568408 },
    { lat: 49.3042351, lng: 18.7570068 },
    { lat: 49.304202, lng: 18.7569335 },
    { lat: 49.3041956, lng: 18.7569191 },
    { lat: 49.3041501, lng: 18.7568213 },
    { lat: 49.3040965, lng: 18.7567032 },
    { lat: 49.3040266, lng: 18.7565509 },
    { lat: 49.3039593, lng: 18.7564062 },
    { lat: 49.3038881, lng: 18.7562498 },
    { lat: 49.303827, lng: 18.7561163 },
    { lat: 49.3037253, lng: 18.7558952 },
    { lat: 49.3036208, lng: 18.755668 },
    { lat: 49.303458, lng: 18.75529 },
    { lat: 49.3032398, lng: 18.7548667 },
    { lat: 49.3031786, lng: 18.7547748 },
    { lat: 49.3029424, lng: 18.7544176 },
    { lat: 49.3026936, lng: 18.7539229 },
    { lat: 49.3026607, lng: 18.7538583 },
    { lat: 49.3026483, lng: 18.753833 },
    { lat: 49.3026356, lng: 18.7538078 },
    { lat: 49.3026052, lng: 18.7537465 },
    { lat: 49.3026177, lng: 18.7536586 },
    { lat: 49.3025659, lng: 18.7535543 },
    { lat: 49.3024957, lng: 18.7534162 },
    { lat: 49.3024901, lng: 18.7533963 },
    { lat: 49.302457, lng: 18.7532778 },
    { lat: 49.3024147, lng: 18.753223 },
    { lat: 49.3023795, lng: 18.7531998 },
    { lat: 49.3023551, lng: 18.7531844 },
    { lat: 49.3022711, lng: 18.7531292 },
    { lat: 49.3022372, lng: 18.7531077 },
    { lat: 49.3021333, lng: 18.7527771 },
    { lat: 49.3020694, lng: 18.7525482 },
    { lat: 49.3018503, lng: 18.7525839 },
    { lat: 49.3017483, lng: 18.7525533 },
    { lat: 49.3016354, lng: 18.7524857 },
    { lat: 49.3015836, lng: 18.7524219 },
    { lat: 49.3010398, lng: 18.7518291 },
    { lat: 49.3010042, lng: 18.7514956 },
    { lat: 49.3009333, lng: 18.7512586 },
    { lat: 49.3007939, lng: 18.7511137 },
    { lat: 49.3007036, lng: 18.7510686 },
    { lat: 49.3005299, lng: 18.7510042 },
    { lat: 49.3003999, lng: 18.7508899 },
    { lat: 49.3001997, lng: 18.7506166 },
    { lat: 49.3001055, lng: 18.7501497 },
    { lat: 49.299982, lng: 18.7493164 },
    { lat: 49.2998996, lng: 18.7487505 },
    { lat: 49.299901, lng: 18.7487149 },
    { lat: 49.2999038, lng: 18.7486593 },
    { lat: 49.299906, lng: 18.7485925 },
    { lat: 49.2999081, lng: 18.7485247 },
    { lat: 49.2999106, lng: 18.7484453 },
    { lat: 49.2999136, lng: 18.7483705 },
    { lat: 49.2999205, lng: 18.7481995 },
    { lat: 49.299956, lng: 18.7478884 },
    { lat: 49.2999718, lng: 18.7477564 },
    { lat: 49.2999849, lng: 18.7476854 },
    { lat: 49.3000052, lng: 18.747576 },
    { lat: 49.3000059, lng: 18.7475676 },
    { lat: 49.3000228, lng: 18.7474828 },
    { lat: 49.3000341, lng: 18.7474196 },
    { lat: 49.3000468, lng: 18.7473588 },
    { lat: 49.3000627, lng: 18.7472738 },
    { lat: 49.300108, lng: 18.7470367 },
    { lat: 49.2999015, lng: 18.7469061 },
    { lat: 49.2997475, lng: 18.7467851 },
    { lat: 49.2996037, lng: 18.7468813 },
    { lat: 49.2995231, lng: 18.7469352 },
    { lat: 49.2994191, lng: 18.7468254 },
    { lat: 49.2993106, lng: 18.7467108 },
    { lat: 49.2993011, lng: 18.7466002 },
    { lat: 49.2991326, lng: 18.7464296 },
    { lat: 49.299154, lng: 18.7463001 },
    { lat: 49.2992964, lng: 18.7463393 },
    { lat: 49.2992943, lng: 18.7462897 },
    { lat: 49.2992933, lng: 18.7462475 },
    { lat: 49.2992964, lng: 18.7462211 },
    { lat: 49.2993043, lng: 18.7461562 },
    { lat: 49.2993121, lng: 18.7460927 },
    { lat: 49.2993138, lng: 18.7460788 },
    { lat: 49.2991455, lng: 18.7460101 },
    { lat: 49.2991329, lng: 18.7460033 },
    { lat: 49.2989925, lng: 18.7459308 },
    { lat: 49.2989316, lng: 18.7458855 },
    { lat: 49.2989081, lng: 18.745867 },
    { lat: 49.2988494, lng: 18.7458236 },
    { lat: 49.2987608, lng: 18.7457462 },
    { lat: 49.2980803, lng: 18.7451535 },
    { lat: 49.2979504, lng: 18.7450592 },
    { lat: 49.2977675, lng: 18.7449265 },
    { lat: 49.2970797, lng: 18.7442971 },
    { lat: 49.2967125, lng: 18.7439607 },
    { lat: 49.2965077, lng: 18.743796 },
    { lat: 49.2963401, lng: 18.7436946 },
    { lat: 49.2960408, lng: 18.743686 },
    { lat: 49.2958385, lng: 18.7437392 },
    { lat: 49.2956886, lng: 18.7438286 },
    { lat: 49.2955602, lng: 18.7439404 },
    { lat: 49.295395, lng: 18.744148 },
    { lat: 49.2952772, lng: 18.7443542 },
    { lat: 49.2951615, lng: 18.7445771 },
    { lat: 49.2945762, lng: 18.7457067 },
    { lat: 49.2944002, lng: 18.7460463 },
    { lat: 49.2937645, lng: 18.7472053 },
    { lat: 49.2935329, lng: 18.7475982 },
    { lat: 49.2929757, lng: 18.7486772 },
    { lat: 49.2929543, lng: 18.7487188 },
    { lat: 49.2926684, lng: 18.749272 },
    { lat: 49.2926938, lng: 18.7493029 },
    { lat: 49.2927916, lng: 18.7494286 },
    { lat: 49.2926198, lng: 18.7500279 },
    { lat: 49.2925027, lng: 18.750764 },
    { lat: 49.2923072, lng: 18.7510388 },
    { lat: 49.2922948, lng: 18.7513232 },
    { lat: 49.2922602, lng: 18.7521926 },
    { lat: 49.2921843, lng: 18.7521863 },
    { lat: 49.292089, lng: 18.7521762 },
    { lat: 49.2920153, lng: 18.7522127 },
    { lat: 49.2918489, lng: 18.7521971 },
    { lat: 49.2917888, lng: 18.7521691 },
    { lat: 49.2917569, lng: 18.7521863 },
    { lat: 49.2916593, lng: 18.7523272 },
    { lat: 49.2915882, lng: 18.7524319 },
    { lat: 49.29154, lng: 18.7525451 },
    { lat: 49.2915084, lng: 18.7526739 },
    { lat: 49.2914255, lng: 18.7527284 },
    { lat: 49.2912824, lng: 18.7527534 },
    { lat: 49.2912159, lng: 18.7528189 },
    { lat: 49.2911905, lng: 18.7528708 },
    { lat: 49.2911601, lng: 18.7528962 },
    { lat: 49.2910397, lng: 18.753029 },
    { lat: 49.2909718, lng: 18.7531326 },
    { lat: 49.2908687, lng: 18.7533391 },
    { lat: 49.2907927, lng: 18.7534405 },
    { lat: 49.2907119, lng: 18.7535764 },
    { lat: 49.2905523, lng: 18.7540188 },
    { lat: 49.2904775, lng: 18.7544668 },
    { lat: 49.2904089, lng: 18.7547501 },
    { lat: 49.2902645, lng: 18.7551632 },
    { lat: 49.2900915, lng: 18.7554339 },
    { lat: 49.2897785, lng: 18.7558993 },
    { lat: 49.2896611, lng: 18.7562636 },
    { lat: 49.2895001, lng: 18.7565057 },
    { lat: 49.289368, lng: 18.7566796 },
    { lat: 49.289224, lng: 18.756726 },
    { lat: 49.2891526, lng: 18.756894 },
    { lat: 49.2890685, lng: 18.757139 },
    { lat: 49.2890061, lng: 18.7572434 },
    { lat: 49.2888869, lng: 18.757337 },
    { lat: 49.288689, lng: 18.7576699 },
    { lat: 49.2885393, lng: 18.7579363 },
    { lat: 49.2882578, lng: 18.7582877 },
    { lat: 49.2881628, lng: 18.758547 },
    { lat: 49.2880199, lng: 18.7588736 },
    { lat: 49.2878402, lng: 18.759322 },
    { lat: 49.2877544, lng: 18.7595156 },
    { lat: 49.2876932, lng: 18.7596162 },
    { lat: 49.287462, lng: 18.7597679 },
    { lat: 49.2871531, lng: 18.7603761 },
    { lat: 49.2870909, lng: 18.7607203 },
    { lat: 49.287044, lng: 18.7608586 },
    { lat: 49.2869873, lng: 18.7610038 },
    { lat: 49.2869574, lng: 18.7610786 },
    { lat: 49.2866775, lng: 18.7608811 },
    { lat: 49.286456, lng: 18.760674 },
    { lat: 49.2862736, lng: 18.761506 },
    { lat: 49.2862416, lng: 18.7618855 },
    { lat: 49.2861577, lng: 18.7621167 },
    { lat: 49.2858968, lng: 18.7620433 },
    { lat: 49.285608, lng: 18.7620828 },
    { lat: 49.2854958, lng: 18.7619201 },
    { lat: 49.2854813, lng: 18.7616871 },
    { lat: 49.2855277, lng: 18.761407 },
    { lat: 49.2852373, lng: 18.7612667 },
    { lat: 49.2850365, lng: 18.7611983 },
    { lat: 49.2850021, lng: 18.7611858 },
    { lat: 49.2848895, lng: 18.7611479 },
    { lat: 49.2848311, lng: 18.761346 },
    { lat: 49.2847554, lng: 18.7614495 },
    { lat: 49.2845014, lng: 18.7616635 },
    { lat: 49.2844571, lng: 18.7615934 },
    { lat: 49.2842031, lng: 18.7618652 },
    { lat: 49.2839513, lng: 18.76199 },
    { lat: 49.2838269, lng: 18.762051 },
    { lat: 49.2837479, lng: 18.7620902 },
    { lat: 49.2836002, lng: 18.7621132 },
    { lat: 49.2835255, lng: 18.7621082 },
    { lat: 49.2834644, lng: 18.7621044 },
    { lat: 49.2833072, lng: 18.7620936 },
    { lat: 49.2832724, lng: 18.7621621 },
    { lat: 49.2832646, lng: 18.762189 },
    { lat: 49.2832571, lng: 18.762217 },
    { lat: 49.2832543, lng: 18.7622268 },
    { lat: 49.2832544, lng: 18.7622282 },
    { lat: 49.2832431, lng: 18.76227 },
    { lat: 49.2832373, lng: 18.7622902 },
    { lat: 49.2832326, lng: 18.7623089 },
    { lat: 49.2832241, lng: 18.7623417 },
    { lat: 49.2831929, lng: 18.7624542 },
    { lat: 49.283172, lng: 18.76253 },
    { lat: 49.2831693, lng: 18.7625412 },
    { lat: 49.2831653, lng: 18.7625558 },
    { lat: 49.2831609, lng: 18.7625744 },
    { lat: 49.2831505, lng: 18.7626161 },
    { lat: 49.2831496, lng: 18.762622 },
    { lat: 49.2831458, lng: 18.762634 },
    { lat: 49.2831311, lng: 18.7626968 },
    { lat: 49.283118, lng: 18.7627508 },
    { lat: 49.2831116, lng: 18.7627768 },
    { lat: 49.2830929, lng: 18.7628528 },
    { lat: 49.2830791, lng: 18.7629108 },
    { lat: 49.2830216, lng: 18.7631493 },
    { lat: 49.2829936, lng: 18.7632618 },
    { lat: 49.2829724, lng: 18.7634388 },
    { lat: 49.2828314, lng: 18.7646099 },
    { lat: 49.2828206, lng: 18.7653144 },
    { lat: 49.2828032, lng: 18.7654126 },
    { lat: 49.2827632, lng: 18.7656197 },
    { lat: 49.2826617, lng: 18.7661626 },
    { lat: 49.2826527, lng: 18.7662085 },
    { lat: 49.2817746, lng: 18.7653425 },
    { lat: 49.2816687, lng: 18.7652376 },
    { lat: 49.2814728, lng: 18.7650454 },
    { lat: 49.2814176, lng: 18.7648751 },
    { lat: 49.2812633, lng: 18.7644009 },
    { lat: 49.2809911, lng: 18.7633736 },
    { lat: 49.2809937, lng: 18.7633151 },
    { lat: 49.2802783, lng: 18.7630668 },
    { lat: 49.2802681, lng: 18.7629106 },
    { lat: 49.2802646, lng: 18.7628673 },
    { lat: 49.2793767, lng: 18.7622261 },
    { lat: 49.2792436, lng: 18.7622066 },
    { lat: 49.2790804, lng: 18.7620584 },
    { lat: 49.2790458, lng: 18.7620244 },
    { lat: 49.2789839, lng: 18.7619618 },
    { lat: 49.2788673, lng: 18.7618453 },
    { lat: 49.2788007, lng: 18.7617789 },
    { lat: 49.2783798, lng: 18.7613577 },
    { lat: 49.2782298, lng: 18.761251 },
    { lat: 49.2782253, lng: 18.7612493 },
    { lat: 49.2779644, lng: 18.7611348 },
    { lat: 49.2777811, lng: 18.7608591 },
    { lat: 49.2776734, lng: 18.760696 },
    { lat: 49.277643, lng: 18.7606978 },
    { lat: 49.27757, lng: 18.76069 },
    { lat: 49.277547, lng: 18.760733 },
    { lat: 49.27731, lng: 18.761425 },
    { lat: 49.277306, lng: 18.761457 },
    { lat: 49.277294, lng: 18.76151 },
    { lat: 49.277241, lng: 18.761784 },
    { lat: 49.2772, lng: 18.762003 },
    { lat: 49.277154, lng: 18.762247 },
    { lat: 49.277135, lng: 18.76235 },
    { lat: 49.277097, lng: 18.762554 },
    { lat: 49.277058, lng: 18.762646 },
    { lat: 49.277049, lng: 18.762658 },
    { lat: 49.27694, lng: 18.762818 },
    { lat: 49.276871, lng: 18.762918 },
    { lat: 49.276791, lng: 18.763018 },
    { lat: 49.276725, lng: 18.763081 },
    { lat: 49.27608, lng: 18.763491 },
    { lat: 49.275783, lng: 18.76368 },
    { lat: 49.275636, lng: 18.763773 },
    { lat: 49.275286, lng: 18.764006 },
    { lat: 49.275205, lng: 18.7641 },
    { lat: 49.275198, lng: 18.764115 },
    { lat: 49.275161, lng: 18.764158 },
    { lat: 49.275156, lng: 18.764163 },
    { lat: 49.275144, lng: 18.764175 },
    { lat: 49.274237, lng: 18.765463 },
    { lat: 49.273975, lng: 18.765709 },
    { lat: 49.273992, lng: 18.765761 },
    { lat: 49.27401, lng: 18.765799 },
    { lat: 49.274043, lng: 18.765869 },
    { lat: 49.274047, lng: 18.765883 },
    { lat: 49.274001, lng: 18.766053 },
    { lat: 49.2737, lng: 18.767154 },
    { lat: 49.273643, lng: 18.767641 },
    { lat: 49.273461, lng: 18.768717 },
    { lat: 49.273168, lng: 18.770045 },
    { lat: 49.272883, lng: 18.771623 },
    { lat: 49.272889, lng: 18.772002 },
    { lat: 49.272964, lng: 18.772845 },
    { lat: 49.273073, lng: 18.773508 },
    { lat: 49.273153, lng: 18.774051 },
    { lat: 49.273186, lng: 18.7746584 },
    { lat: 49.2732018, lng: 18.7752902 },
    { lat: 49.2736791, lng: 18.7764699 },
    { lat: 49.2737705, lng: 18.7794907 },
    { lat: 49.2726659, lng: 18.781067 },
    { lat: 49.2716515, lng: 18.7825515 },
    { lat: 49.271928, lng: 18.78345 },
    { lat: 49.2720103, lng: 18.7838622 },
    { lat: 49.2720864, lng: 18.7842408 },
    { lat: 49.2721184, lng: 18.7844034 },
    { lat: 49.2721506, lng: 18.7845685 },
    { lat: 49.2722114, lng: 18.7848713 },
    { lat: 49.2722621, lng: 18.7851284 },
    { lat: 49.2722799, lng: 18.7851891 },
    { lat: 49.2729835, lng: 18.7850613 },
    { lat: 49.2733217, lng: 18.7849489 },
    { lat: 49.2738018, lng: 18.7846846 },
    { lat: 49.2740282, lng: 18.7846175 },
    { lat: 49.2750026, lng: 18.7841833 },
    { lat: 49.2757511, lng: 18.784248 },
    { lat: 49.2762235, lng: 18.7840485 },
    { lat: 49.276158, lng: 18.783613 },
    { lat: 49.2766589, lng: 18.7833577 },
    { lat: 49.2772892, lng: 18.7833334 },
    { lat: 49.2778168, lng: 18.7830731 },
    { lat: 49.2782659, lng: 18.7827529 },
    { lat: 49.2786651, lng: 18.782342 },
    { lat: 49.2791349, lng: 18.7822245 },
    { lat: 49.2793421, lng: 18.7822832 },
    { lat: 49.2799065, lng: 18.7821639 },
    { lat: 49.2800179, lng: 18.7820287 },
    { lat: 49.2800571, lng: 18.7820236 },
    { lat: 49.2801598, lng: 18.7820917 },
    { lat: 49.280469, lng: 18.7827733 },
    { lat: 49.2808626, lng: 18.7832512 },
    { lat: 49.2812535, lng: 18.7833303 },
    { lat: 49.2816753, lng: 18.783355 },
    { lat: 49.2819313, lng: 18.7832056 },
    { lat: 49.282117, lng: 18.7832196 },
    { lat: 49.2822572, lng: 18.7832492 },
    { lat: 49.2824707, lng: 18.7831052 },
    { lat: 49.2828155, lng: 18.7832932 },
    { lat: 49.2830237, lng: 18.7833774 },
    { lat: 49.2833549, lng: 18.7835447 },
    { lat: 49.2835782, lng: 18.7834804 },
    { lat: 49.2837826, lng: 18.7833997 },
    { lat: 49.2840571, lng: 18.7836258 },
    { lat: 49.2841418, lng: 18.7835191 },
    { lat: 49.2844596, lng: 18.7834048 },
    { lat: 49.2847326, lng: 18.7831297 },
    { lat: 49.2851289, lng: 18.7828336 },
    { lat: 49.2853079, lng: 18.7825855 },
    { lat: 49.2855763, lng: 18.7824569 },
    { lat: 49.2859405, lng: 18.7823738 },
    { lat: 49.2860604, lng: 18.7820515 },
    { lat: 49.2862762, lng: 18.7814284 },
    { lat: 49.2865399, lng: 18.7810544 },
    { lat: 49.2869839, lng: 18.7805076 },
    { lat: 49.2872016, lng: 18.7802269 },
    { lat: 49.2873227, lng: 18.7798655 },
    { lat: 49.2873839, lng: 18.7795463 },
    { lat: 49.287386, lng: 18.7795407 },
    { lat: 49.2873895, lng: 18.7795222 },
    { lat: 49.2873965, lng: 18.779483 },
    { lat: 49.2874081, lng: 18.779426 },
    { lat: 49.2874089, lng: 18.7794183 },
    { lat: 49.2874226, lng: 18.7793777 },
    { lat: 49.2874481, lng: 18.7793034 },
    { lat: 49.2874572, lng: 18.7792835 },
    { lat: 49.2875127, lng: 18.7791591 },
    { lat: 49.287547, lng: 18.779014 },
    { lat: 49.287634, lng: 18.77876 },
    { lat: 49.2877011, lng: 18.7788335 },
    { lat: 49.288914, lng: 18.7801755 },
    { lat: 49.2892945, lng: 18.7805376 },
    { lat: 49.2899151, lng: 18.7809598 },
    { lat: 49.2917563, lng: 18.7819321 },
    { lat: 49.2920438, lng: 18.782088 },
    { lat: 49.2925405, lng: 18.7823573 },
    { lat: 49.2926691, lng: 18.7824266 },
    { lat: 49.2928711, lng: 18.7825358 },
    { lat: 49.2930252, lng: 18.7826194 },
    { lat: 49.293545, lng: 18.7829011 },
    { lat: 49.2936065, lng: 18.7829348 },
    { lat: 49.2945473, lng: 18.7834444 },
    { lat: 49.2951832, lng: 18.7835932 },
    { lat: 49.2954923, lng: 18.783835 },
    { lat: 49.2960655, lng: 18.7845416 },
    { lat: 49.2964248, lng: 18.7851668 },
    { lat: 49.2967075, lng: 18.7856843 },
    { lat: 49.2972501, lng: 18.7867533 },
    { lat: 49.2974456, lng: 18.7873955 },
    { lat: 49.2976916, lng: 18.7877704 },
    { lat: 49.2977052, lng: 18.7877915 },
    { lat: 49.2977517, lng: 18.7878632 },
    { lat: 49.2977662, lng: 18.7878842 },
    { lat: 49.297786, lng: 18.7879136 },
    { lat: 49.298869, lng: 18.7894968 },
    { lat: 49.2986838, lng: 18.7899278 },
    { lat: 49.2986641, lng: 18.7899584 },
    { lat: 49.2986358, lng: 18.7899997 },
    { lat: 49.2985558, lng: 18.7901348 },
    { lat: 49.2985476, lng: 18.7901491 },
    { lat: 49.2985382, lng: 18.7901644 },
    { lat: 49.298527, lng: 18.7901838 },
    { lat: 49.2984626, lng: 18.7902924 },
    { lat: 49.2984265, lng: 18.790356 },
    { lat: 49.2984554, lng: 18.7903937 },
    { lat: 49.2984169, lng: 18.7905006 },
    { lat: 49.2984138, lng: 18.7905038 },
    { lat: 49.2983978, lng: 18.7905228 },
    { lat: 49.2983862, lng: 18.7905361 },
    { lat: 49.298382, lng: 18.7905417 },
    { lat: 49.298364, lng: 18.7905633 },
    { lat: 49.298351, lng: 18.7905783 },
    { lat: 49.2982854, lng: 18.7906567 },
    { lat: 49.2982566, lng: 18.7906901 },
    { lat: 49.2979409, lng: 18.7906926 },
    { lat: 49.2978311, lng: 18.7906173 },
    { lat: 49.297679, lng: 18.7907502 },
    { lat: 49.2973487, lng: 18.791034 },
    { lat: 49.2972222, lng: 18.7911425 },
    { lat: 49.2969677, lng: 18.7912087 },
    { lat: 49.2969349, lng: 18.791165 },
    { lat: 49.2969054, lng: 18.7911205 },
    { lat: 49.2968749, lng: 18.7910733 },
    { lat: 49.2968466, lng: 18.7910297 },
    { lat: 49.2968197, lng: 18.7909889 },
    { lat: 49.2966919, lng: 18.7910658 },
    { lat: 49.2963489, lng: 18.7915206 },
    { lat: 49.2961672, lng: 18.7918442 },
    { lat: 49.2960964, lng: 18.7924405 },
    { lat: 49.2960861, lng: 18.7925234 },
    { lat: 49.2959929, lng: 18.7927272 },
    { lat: 49.2957337, lng: 18.7932943 },
    { lat: 49.2957338, lng: 18.7932965 },
    { lat: 49.2957044, lng: 18.7933799 },
    { lat: 49.2953822, lng: 18.7939759 },
    { lat: 49.2952742, lng: 18.7941761 },
    { lat: 49.2951986, lng: 18.7943503 },
    { lat: 49.2951564, lng: 18.7944298 },
    { lat: 49.294987, lng: 18.7946212 },
    { lat: 49.2949265, lng: 18.7947137 },
    { lat: 49.2948691, lng: 18.794759 },
    { lat: 49.2948008, lng: 18.7951194 },
    { lat: 49.2947992, lng: 18.7951294 },
    { lat: 49.2947932, lng: 18.7951695 },
    { lat: 49.2947802, lng: 18.7952653 },
    { lat: 49.2947686, lng: 18.795348 },
    { lat: 49.2947688, lng: 18.7953512 },
    { lat: 49.2947672, lng: 18.7955386 },
    { lat: 49.2947665, lng: 18.7956301 },
    { lat: 49.2947648, lng: 18.7957773 },
    { lat: 49.2947648, lng: 18.7957946 },
    { lat: 49.2947425, lng: 18.7958656 },
    { lat: 49.2947191, lng: 18.7959396 },
    { lat: 49.2947182, lng: 18.7959408 },
    { lat: 49.294677, lng: 18.7959981 },
    { lat: 49.2946424, lng: 18.7960468 },
    { lat: 49.2946045, lng: 18.796099 },
    { lat: 49.2945752, lng: 18.7961398 },
    { lat: 49.2945445, lng: 18.7961821 },
    { lat: 49.2945152, lng: 18.7962229 },
    { lat: 49.2944877, lng: 18.7962609 },
    { lat: 49.2944509, lng: 18.796313 },
    { lat: 49.2944037, lng: 18.796379 },
    { lat: 49.2943468, lng: 18.7964574 },
    { lat: 49.2942934, lng: 18.7965582 },
    { lat: 49.2943084, lng: 18.7965871 },
    { lat: 49.2943837, lng: 18.7967369 },
    { lat: 49.2945555, lng: 18.7969373 },
    { lat: 49.2951484, lng: 18.7980285 },
    { lat: 49.2952982, lng: 18.7983308 },
    { lat: 49.2956269, lng: 18.7987745 },
    { lat: 49.2956841, lng: 18.7988734 },
    { lat: 49.2958508, lng: 18.7991615 },
    { lat: 49.2958095, lng: 18.7995837 },
    { lat: 49.2958094, lng: 18.7999114 },
    { lat: 49.2958763, lng: 18.8002447 },
    { lat: 49.2958919, lng: 18.8003284 },
    { lat: 49.2959076, lng: 18.800458 },
    { lat: 49.2959406, lng: 18.8006357 },
    { lat: 49.296, lng: 18.8011538 },
    { lat: 49.2961743, lng: 18.8021906 },
    { lat: 49.2962091, lng: 18.8024754 },
    { lat: 49.2960332, lng: 18.8030862 },
    { lat: 49.2956986, lng: 18.803572 },
    { lat: 49.295031, lng: 18.8040947 },
    { lat: 49.2944425, lng: 18.804553 },
    { lat: 49.2942319, lng: 18.8046026 },
    { lat: 49.2938135, lng: 18.8046849 },
    { lat: 49.2935106, lng: 18.8061827 },
    { lat: 49.2934805, lng: 18.8072574 },
    { lat: 49.2934724, lng: 18.8075401 },
    { lat: 49.2941471, lng: 18.8101251 },
    { lat: 49.2940924, lng: 18.8104857 },
    { lat: 49.2940266, lng: 18.8106764 },
    { lat: 49.2940121, lng: 18.8109176 },
    { lat: 49.2939468, lng: 18.8110064 },
    { lat: 49.2937255, lng: 18.8113102 },
    { lat: 49.2936004, lng: 18.8114824 },
    { lat: 49.2937922, lng: 18.8123609 },
    { lat: 49.2938393, lng: 18.8126432 },
    { lat: 49.2939422, lng: 18.8129534 },
    { lat: 49.2939783, lng: 18.8133037 },
    { lat: 49.2942932, lng: 18.8136379 },
    { lat: 49.2941664, lng: 18.8146809 },
    { lat: 49.2940953, lng: 18.8149831 },
    { lat: 49.2942303, lng: 18.8152602 },
    { lat: 49.2942466, lng: 18.8153605 },
    { lat: 49.2942562, lng: 18.8154605 },
    { lat: 49.2942574, lng: 18.8155243 },
    { lat: 49.2942274, lng: 18.8158833 },
    { lat: 49.2941618, lng: 18.8167094 },
    { lat: 49.2940671, lng: 18.8170832 },
    { lat: 49.293937, lng: 18.8172491 },
    { lat: 49.2937228, lng: 18.8175588 },
    { lat: 49.2929543, lng: 18.8182022 },
    { lat: 49.2930434, lng: 18.8190791 },
    { lat: 49.2927412, lng: 18.8195135 },
    { lat: 49.2929307, lng: 18.8215118 },
    { lat: 49.2929614, lng: 18.8220203 },
    { lat: 49.2929694, lng: 18.8220425 },
    { lat: 49.2926762, lng: 18.8223909 },
    { lat: 49.2927639, lng: 18.8224985 },
    { lat: 49.2927498, lng: 18.8228809 },
    { lat: 49.292747, lng: 18.8230229 },
    { lat: 49.2927123, lng: 18.8230292 },
    { lat: 49.292699, lng: 18.8230319 },
    { lat: 49.292479, lng: 18.8230717 },
    { lat: 49.2914209, lng: 18.8210927 },
    { lat: 49.2913321, lng: 18.8208776 },
    { lat: 49.2911361, lng: 18.8211219 },
    { lat: 49.2909513, lng: 18.8214107 },
    { lat: 49.291003, lng: 18.8216018 },
    { lat: 49.2902592, lng: 18.8228594 },
    { lat: 49.2895386, lng: 18.8241492 },
    { lat: 49.2896639, lng: 18.8249649 },
    { lat: 49.2896815, lng: 18.8270791 },
    { lat: 49.2893825, lng: 18.82835 },
    { lat: 49.28928, lng: 18.8292047 },
    { lat: 49.2892414, lng: 18.8299259 },
    { lat: 49.2892833, lng: 18.8308268 },
    { lat: 49.2893928, lng: 18.8315324 },
    { lat: 49.2891687, lng: 18.8324139 },
    { lat: 49.2891379, lng: 18.8327795 },
    { lat: 49.2892714, lng: 18.8344351 },
    { lat: 49.2889163, lng: 18.8359727 },
    { lat: 49.2880804, lng: 18.8377205 },
    { lat: 49.2879509, lng: 18.8387336 },
    { lat: 49.2878851, lng: 18.8398489 },
    { lat: 49.2875225, lng: 18.8418988 },
    { lat: 49.2871747, lng: 18.8430319 },
    { lat: 49.2869737, lng: 18.8437524 },
    { lat: 49.2867785, lng: 18.8454838 },
    { lat: 49.2862015, lng: 18.8479753 },
    { lat: 49.2862146, lng: 18.8494625 },
    { lat: 49.2860892, lng: 18.8506892 },
  ],
  KysuckýLieskovec: [
    { lat: 49.354154, lng: 18.807492 },
    { lat: 49.3541215, lng: 18.8074905 },
    { lat: 49.3540606, lng: 18.8074863 },
    { lat: 49.3539575, lng: 18.8074799 },
    { lat: 49.3515544, lng: 18.8073195 },
    { lat: 49.3500561, lng: 18.8077329 },
    { lat: 49.3494535, lng: 18.808359 },
    { lat: 49.34931, lng: 18.8082421 },
    { lat: 49.3491204, lng: 18.8080886 },
    { lat: 49.3490769, lng: 18.8080526 },
    { lat: 49.3485784, lng: 18.8080524 },
    { lat: 49.3481467, lng: 18.808052 },
    { lat: 49.3479076, lng: 18.808052 },
    { lat: 49.3478467, lng: 18.8080521 },
    { lat: 49.3478162, lng: 18.8080518 },
    { lat: 49.3477611, lng: 18.8080519 },
    { lat: 49.3477576, lng: 18.8080516 },
    { lat: 49.3477352, lng: 18.8080522 },
    { lat: 49.3476628, lng: 18.8080519 },
    { lat: 49.3475749, lng: 18.8080516 },
    { lat: 49.3468477, lng: 18.8080508 },
    { lat: 49.3468427, lng: 18.807978 },
    { lat: 49.3468141, lng: 18.8075511 },
    { lat: 49.3468049, lng: 18.807417 },
    { lat: 49.3467968, lng: 18.8074115 },
    { lat: 49.3467166, lng: 18.8073235 },
    { lat: 49.3465925, lng: 18.8072343 },
    { lat: 49.3464186, lng: 18.8069465 },
    { lat: 49.3463964, lng: 18.8069087 },
    { lat: 49.3463526, lng: 18.8068883 },
    { lat: 49.3463092, lng: 18.8068769 },
    { lat: 49.3463012, lng: 18.8068764 },
    { lat: 49.3462787, lng: 18.8068763 },
    { lat: 49.346211, lng: 18.8068559 },
    { lat: 49.3460719, lng: 18.8068011 },
    { lat: 49.3458794, lng: 18.8067422 },
    { lat: 49.3458393, lng: 18.8067308 },
    { lat: 49.345505, lng: 18.8066081 },
    { lat: 49.3452201, lng: 18.8065065 },
    { lat: 49.3450377, lng: 18.8064475 },
    { lat: 49.344932, lng: 18.8064086 },
    { lat: 49.3446779, lng: 18.8063109 },
    { lat: 49.3445927, lng: 18.8062351 },
    { lat: 49.3445866, lng: 18.8061154 },
    { lat: 49.3445864, lng: 18.8061115 },
    { lat: 49.3445874, lng: 18.8061081 },
    { lat: 49.3445905, lng: 18.806081 },
    { lat: 49.3445966, lng: 18.8060267 },
    { lat: 49.344599, lng: 18.8060083 },
    { lat: 49.3446008, lng: 18.8059983 },
    { lat: 49.3445963, lng: 18.8058868 },
    { lat: 49.3445721, lng: 18.8058355 },
    { lat: 49.3445233, lng: 18.805786 },
    { lat: 49.3443555, lng: 18.8057206 },
    { lat: 49.3441166, lng: 18.8055734 },
    { lat: 49.3438809, lng: 18.8053744 },
    { lat: 49.3438031, lng: 18.8053545 },
    { lat: 49.3437239, lng: 18.8053253 },
    { lat: 49.3435402, lng: 18.8053504 },
    { lat: 49.3435228, lng: 18.8053847 },
    { lat: 49.3432797, lng: 18.8053038 },
    { lat: 49.3431593, lng: 18.8052641 },
    { lat: 49.3430228, lng: 18.8052194 },
    { lat: 49.3424332, lng: 18.8050249 },
    { lat: 49.3418907, lng: 18.8048456 },
    { lat: 49.3417047, lng: 18.8047842 },
    { lat: 49.3408356, lng: 18.8045435 },
    { lat: 49.3398772, lng: 18.8040189 },
    { lat: 49.3398104, lng: 18.803994 },
    { lat: 49.339436, lng: 18.8037927 },
    { lat: 49.3385684, lng: 18.8031703 },
    { lat: 49.3379156, lng: 18.8029773 },
    { lat: 49.3371496, lng: 18.8026374 },
    { lat: 49.3364724, lng: 18.8024318 },
    { lat: 49.3357224, lng: 18.8024703 },
    { lat: 49.3356384, lng: 18.8025698 },
    { lat: 49.3355821, lng: 18.8026373 },
    { lat: 49.3355206, lng: 18.8027091 },
    { lat: 49.3354728, lng: 18.8027662 },
    { lat: 49.3354717, lng: 18.8028097 },
    { lat: 49.3354656, lng: 18.802933 },
    { lat: 49.335458, lng: 18.8029831 },
    { lat: 49.3353779, lng: 18.803529 },
    { lat: 49.3350455, lng: 18.8035977 },
    { lat: 49.334556, lng: 18.8039008 },
    { lat: 49.3340128, lng: 18.8043439 },
    { lat: 49.3335442, lng: 18.8048744 },
    { lat: 49.3332279, lng: 18.8053888 },
    { lat: 49.3331909, lng: 18.8053491 },
    { lat: 49.333007, lng: 18.8051512 },
    { lat: 49.3328972, lng: 18.8050325 },
    { lat: 49.3328671, lng: 18.8050173 },
    { lat: 49.3327413, lng: 18.8049598 },
    { lat: 49.3326476, lng: 18.8049173 },
    { lat: 49.3324988, lng: 18.8048492 },
    { lat: 49.3322924, lng: 18.8047169 },
    { lat: 49.3316183, lng: 18.8042824 },
    { lat: 49.3316537, lng: 18.8040937 },
    { lat: 49.33143, lng: 18.804043 },
    { lat: 49.3311898, lng: 18.8039769 },
    { lat: 49.3309623, lng: 18.8038074 },
    { lat: 49.3307782, lng: 18.8036698 },
    { lat: 49.3306176, lng: 18.8030472 },
    { lat: 49.3304612, lng: 18.8025542 },
    { lat: 49.3304633, lng: 18.8025478 },
    { lat: 49.3304851, lng: 18.8024674 },
    { lat: 49.3304709, lng: 18.802215 },
    { lat: 49.3304544, lng: 18.8019408 },
    { lat: 49.3304411, lng: 18.8017241 },
    { lat: 49.3304594, lng: 18.8009667 },
    { lat: 49.329923, lng: 18.8007723 },
    { lat: 49.3297423, lng: 18.8007912 },
    { lat: 49.3297021, lng: 18.8007957 },
    { lat: 49.3293225, lng: 18.8006091 },
    { lat: 49.3288685, lng: 18.8003556 },
    { lat: 49.3281934, lng: 18.8001386 },
    { lat: 49.3281789, lng: 18.8001002 },
    { lat: 49.3281408, lng: 18.8000629 },
    { lat: 49.3281017, lng: 18.8000239 },
    { lat: 49.3280756, lng: 18.7999985 },
    { lat: 49.3280649, lng: 18.7999886 },
    { lat: 49.3280551, lng: 18.7999948 },
    { lat: 49.3280446, lng: 18.7999885 },
    { lat: 49.3280319, lng: 18.7999799 },
    { lat: 49.3280214, lng: 18.7999736 },
    { lat: 49.3280096, lng: 18.799966 },
    { lat: 49.3279979, lng: 18.7999583 },
    { lat: 49.3279827, lng: 18.7999475 },
    { lat: 49.3279688, lng: 18.7999384 },
    { lat: 49.3278146, lng: 18.8000318 },
    { lat: 49.3276616, lng: 18.8001251 },
    { lat: 49.3273606, lng: 18.8002808 },
    { lat: 49.3272747, lng: 18.8002749 },
    { lat: 49.3272315, lng: 18.8002881 },
    { lat: 49.327116, lng: 18.8005005 },
    { lat: 49.3270971, lng: 18.8006611 },
    { lat: 49.3270125, lng: 18.8007657 },
    { lat: 49.3268638, lng: 18.8009879 },
    { lat: 49.3267912, lng: 18.8010458 },
    { lat: 49.3266639, lng: 18.8011335 },
    { lat: 49.3266706, lng: 18.8011764 },
    { lat: 49.3264353, lng: 18.8017091 },
    { lat: 49.3263661, lng: 18.801938 },
    { lat: 49.326309, lng: 18.8021459 },
    { lat: 49.3262156, lng: 18.802566 },
    { lat: 49.3261868, lng: 18.8027253 },
    { lat: 49.3261604, lng: 18.8028687 },
    { lat: 49.3261495, lng: 18.802964 },
    { lat: 49.3261305, lng: 18.8031405 },
    { lat: 49.3261049, lng: 18.8031953 },
    { lat: 49.3260849, lng: 18.8032415 },
    { lat: 49.3260706, lng: 18.8032725 },
    { lat: 49.3258605, lng: 18.8037421 },
    { lat: 49.3255965, lng: 18.804331 },
    { lat: 49.3255378, lng: 18.8044631 },
    { lat: 49.3255229, lng: 18.8044979 },
    { lat: 49.3254209, lng: 18.8047296 },
    { lat: 49.3253391, lng: 18.8049155 },
    { lat: 49.3269672, lng: 18.8063134 },
    { lat: 49.3274342, lng: 18.806714 },
    { lat: 49.330219, lng: 18.8079352 },
    { lat: 49.3302336, lng: 18.8081742 },
    { lat: 49.3302356, lng: 18.808346 },
    { lat: 49.3302369, lng: 18.8083878 },
    { lat: 49.3302395, lng: 18.8085317 },
    { lat: 49.3302409, lng: 18.808623 },
    { lat: 49.3302433, lng: 18.808733 },
    { lat: 49.3301586, lng: 18.8094315 },
    { lat: 49.3300731, lng: 18.8098492 },
    { lat: 49.329553, lng: 18.8105865 },
    { lat: 49.3292923, lng: 18.8112166 },
    { lat: 49.3291508, lng: 18.811708 },
    { lat: 49.3290996, lng: 18.8122474 },
    { lat: 49.3290906, lng: 18.8133192 },
    { lat: 49.3290771, lng: 18.8136722 },
    { lat: 49.3290313, lng: 18.814426 },
    { lat: 49.3289512, lng: 18.8147317 },
    { lat: 49.3283437, lng: 18.8157416 },
    { lat: 49.3277158, lng: 18.8160504 },
    { lat: 49.3273914, lng: 18.816361 },
    { lat: 49.3269916, lng: 18.8165494 },
    { lat: 49.3264354, lng: 18.8168474 },
    { lat: 49.3257061, lng: 18.8172653 },
    { lat: 49.3251985, lng: 18.8174157 },
    { lat: 49.3247454, lng: 18.8175719 },
    { lat: 49.3243971, lng: 18.8176637 },
    { lat: 49.3237307, lng: 18.8181651 },
    { lat: 49.3235066, lng: 18.8182145 },
    { lat: 49.3235301, lng: 18.8194363 },
    { lat: 49.3235724, lng: 18.8211127 },
    { lat: 49.3235042, lng: 18.82299 },
    { lat: 49.323471, lng: 18.8240288 },
    { lat: 49.3235218, lng: 18.8249527 },
    { lat: 49.3234038, lng: 18.825902 },
    { lat: 49.3229108, lng: 18.8266448 },
    { lat: 49.3229479, lng: 18.8282429 },
    { lat: 49.3229617, lng: 18.8288388 },
    { lat: 49.3234449, lng: 18.8295571 },
    { lat: 49.3236538, lng: 18.8297832 },
    { lat: 49.324206, lng: 18.8314004 },
    { lat: 49.3239992, lng: 18.8324243 },
    { lat: 49.3233909, lng: 18.8338836 },
    { lat: 49.3232621, lng: 18.8339027 },
    { lat: 49.3228131, lng: 18.8342917 },
    { lat: 49.322516, lng: 18.8347857 },
    { lat: 49.3224615, lng: 18.8352838 },
    { lat: 49.3224891, lng: 18.8359509 },
    { lat: 49.3226162, lng: 18.8368497 },
    { lat: 49.3225596, lng: 18.837523 },
    { lat: 49.3224211, lng: 18.8382087 },
    { lat: 49.3223373, lng: 18.838882 },
    { lat: 49.3220263, lng: 18.8391712 },
    { lat: 49.322072, lng: 18.8403071 },
    { lat: 49.3222561, lng: 18.8403527 },
    { lat: 49.3225612, lng: 18.8403229 },
    { lat: 49.3229811, lng: 18.8401355 },
    { lat: 49.3232362, lng: 18.8404927 },
    { lat: 49.3233323, lng: 18.8409811 },
    { lat: 49.323405, lng: 18.8417632 },
    { lat: 49.3232047, lng: 18.8426955 },
    { lat: 49.322765, lng: 18.8438604 },
    { lat: 49.3226772, lng: 18.8439722 },
    { lat: 49.322809, lng: 18.8451155 },
    { lat: 49.3241609, lng: 18.8445688 },
    { lat: 49.3245121, lng: 18.8451285 },
    { lat: 49.3247371, lng: 18.8451207 },
    { lat: 49.325452, lng: 18.8448762 },
    { lat: 49.3259611, lng: 18.8448235 },
    { lat: 49.3259724, lng: 18.8447593 },
    { lat: 49.3263274, lng: 18.8447344 },
    { lat: 49.3265137, lng: 18.8448707 },
    { lat: 49.3266602, lng: 18.8449349 },
    { lat: 49.3270823, lng: 18.8451864 },
    { lat: 49.3273045, lng: 18.8452762 },
    { lat: 49.3275619, lng: 18.8453017 },
    { lat: 49.3279, lng: 18.8451917 },
    { lat: 49.3282351, lng: 18.8451104 },
    { lat: 49.3285218, lng: 18.8450716 },
    { lat: 49.328752, lng: 18.8449847 },
    { lat: 49.3290048, lng: 18.8451012 },
    { lat: 49.329083, lng: 18.8451757 },
    { lat: 49.3293225, lng: 18.8454922 },
    { lat: 49.3296378, lng: 18.8457475 },
    { lat: 49.3297899, lng: 18.8457691 },
    { lat: 49.3298816, lng: 18.8457338 },
    { lat: 49.3301397, lng: 18.8452702 },
    { lat: 49.3303843, lng: 18.8449386 },
    { lat: 49.3305757, lng: 18.8448665 },
    { lat: 49.3308473, lng: 18.8448639 },
    { lat: 49.3311546, lng: 18.8449865 },
    { lat: 49.3314727, lng: 18.8450127 },
    { lat: 49.3319227, lng: 18.8447527 },
    { lat: 49.3320237, lng: 18.8447413 },
    { lat: 49.3321211, lng: 18.8447929 },
    { lat: 49.3324281, lng: 18.8447777 },
    { lat: 49.3325673, lng: 18.8447693 },
    { lat: 49.3326514, lng: 18.8448001 },
    { lat: 49.3327145, lng: 18.844824 },
    { lat: 49.332944, lng: 18.8449219 },
    { lat: 49.3331148, lng: 18.8450243 },
    { lat: 49.3332495, lng: 18.8450573 },
    { lat: 49.333841, lng: 18.8450681 },
    { lat: 49.3340017, lng: 18.8450561 },
    { lat: 49.3341552, lng: 18.8450409 },
    { lat: 49.334329, lng: 18.8450851 },
    { lat: 49.3344911, lng: 18.8451698 },
    { lat: 49.334601, lng: 18.8452025 },
    { lat: 49.334681, lng: 18.8452685 },
    { lat: 49.3349104, lng: 18.8453198 },
    { lat: 49.3353898, lng: 18.8451958 },
    { lat: 49.3353904, lng: 18.8452084 },
    { lat: 49.3354153, lng: 18.8458008 },
    { lat: 49.335416, lng: 18.8458376 },
    { lat: 49.3354156, lng: 18.8458745 },
    { lat: 49.3354966, lng: 18.8458927 },
    { lat: 49.3355515, lng: 18.8459085 },
    { lat: 49.335555, lng: 18.8459124 },
    { lat: 49.3360847, lng: 18.8460177 },
    { lat: 49.3361426, lng: 18.8460227 },
    { lat: 49.3364418, lng: 18.8460985 },
    { lat: 49.3364641, lng: 18.8460919 },
    { lat: 49.3365251, lng: 18.8461164 },
    { lat: 49.3366033, lng: 18.846171 },
    { lat: 49.336859, lng: 18.8465424 },
    { lat: 49.3374577, lng: 18.8470038 },
    { lat: 49.3381232, lng: 18.8472513 },
    { lat: 49.3382106, lng: 18.8472842 },
    { lat: 49.3388377, lng: 18.8474201 },
    { lat: 49.3397448, lng: 18.8471667 },
    { lat: 49.3399306, lng: 18.8471415 },
    { lat: 49.340402, lng: 18.8470782 },
    { lat: 49.3406186, lng: 18.8470539 },
    { lat: 49.3408082, lng: 18.8470613 },
    { lat: 49.3409907, lng: 18.8471276 },
    { lat: 49.3416846, lng: 18.8473141 },
    { lat: 49.3417924, lng: 18.8472945 },
    { lat: 49.3420835, lng: 18.8472417 },
    { lat: 49.3424053, lng: 18.8473391 },
    { lat: 49.3428191, lng: 18.8475617 },
    { lat: 49.3431405, lng: 18.8477398 },
    { lat: 49.344714, lng: 18.8479474 },
    { lat: 49.3454061, lng: 18.8478803 },
    { lat: 49.3458435, lng: 18.8479888 },
    { lat: 49.3459327, lng: 18.8486749 },
    { lat: 49.3465116, lng: 18.8492806 },
    { lat: 49.3465663, lng: 18.8506814 },
    { lat: 49.346535, lng: 18.8522495 },
    { lat: 49.3465001, lng: 18.8531747 },
    { lat: 49.3464523, lng: 18.8536541 },
    { lat: 49.3464936, lng: 18.8546844 },
    { lat: 49.3465753, lng: 18.8560148 },
    { lat: 49.3465418, lng: 18.8570826 },
    { lat: 49.346575, lng: 18.8585692 },
    { lat: 49.3466314, lng: 18.8592285 },
    { lat: 49.3466476, lng: 18.8602123 },
    { lat: 49.3466264, lng: 18.8610086 },
    { lat: 49.3466985, lng: 18.8621571 },
    { lat: 49.3466868, lng: 18.8640247 },
    { lat: 49.3467175, lng: 18.8650892 },
    { lat: 49.3468639, lng: 18.8665449 },
    { lat: 49.3468707, lng: 18.8672999 },
    { lat: 49.346776, lng: 18.868373 },
    { lat: 49.347196, lng: 18.868741 },
    { lat: 49.348261, lng: 18.869222 },
    { lat: 49.349041, lng: 18.869684 },
    { lat: 49.349809, lng: 18.869894 },
    { lat: 49.3502, lng: 18.869928 },
    { lat: 49.35094, lng: 18.869869 },
    { lat: 49.351572, lng: 18.869717 },
    { lat: 49.351717, lng: 18.868005 },
    { lat: 49.351804, lng: 18.867535 },
    { lat: 49.351849, lng: 18.866467 },
    { lat: 49.351944, lng: 18.865984 },
    { lat: 49.352523, lng: 18.865019 },
    { lat: 49.352503, lng: 18.86413 },
    { lat: 49.352564, lng: 18.863611 },
    { lat: 49.352642, lng: 18.863359 },
    { lat: 49.353029, lng: 18.862816 },
    { lat: 49.35365, lng: 18.860743 },
    { lat: 49.353778, lng: 18.860184 },
    { lat: 49.354102, lng: 18.859607 },
    { lat: 49.354849, lng: 18.857276 },
    { lat: 49.355034, lng: 18.8569 },
    { lat: 49.355159, lng: 18.856426 },
    { lat: 49.355279, lng: 18.85553 },
    { lat: 49.355381, lng: 18.855135 },
    { lat: 49.355546, lng: 18.85468 },
    { lat: 49.355738, lng: 18.854362 },
    { lat: 49.355756, lng: 18.853877 },
    { lat: 49.355932, lng: 18.853305 },
    { lat: 49.355885, lng: 18.852918 },
    { lat: 49.355992, lng: 18.852014 },
    { lat: 49.356091, lng: 18.851793 },
    { lat: 49.356062, lng: 18.851333 },
    { lat: 49.356373, lng: 18.850514 },
    { lat: 49.356851, lng: 18.84973 },
    { lat: 49.357037, lng: 18.848595 },
    { lat: 49.357025, lng: 18.847899 },
    { lat: 49.356895, lng: 18.847191 },
    { lat: 49.356944, lng: 18.84695 },
    { lat: 49.35715, lng: 18.846726 },
    { lat: 49.357672, lng: 18.846444 },
    { lat: 49.357889, lng: 18.845941 },
    { lat: 49.358042, lng: 18.845875 },
    { lat: 49.358137, lng: 18.845759 },
    { lat: 49.3584965, lng: 18.8449417 },
    { lat: 49.35859, lng: 18.844729 },
    { lat: 49.358806, lng: 18.84459 },
    { lat: 49.358988, lng: 18.844329 },
    { lat: 49.3590806, lng: 18.8443428 },
    { lat: 49.359203, lng: 18.844361 },
    { lat: 49.359396, lng: 18.844215 },
    { lat: 49.3593385, lng: 18.8441192 },
    { lat: 49.358975, lng: 18.843514 },
    { lat: 49.358496, lng: 18.843248 },
    { lat: 49.358, lng: 18.842761 },
    { lat: 49.358033, lng: 18.842278 },
    { lat: 49.35829, lng: 18.840542 },
    { lat: 49.358269, lng: 18.839832 },
    { lat: 49.358342, lng: 18.83953 },
    { lat: 49.358391, lng: 18.838949 },
    { lat: 49.358393, lng: 18.838188 },
    { lat: 49.358311, lng: 18.837673 },
    { lat: 49.357124, lng: 18.835195 },
    { lat: 49.356691, lng: 18.834566 },
    { lat: 49.356255, lng: 18.833717 },
    { lat: 49.356273, lng: 18.832837 },
    { lat: 49.354866, lng: 18.832545 },
    { lat: 49.354744, lng: 18.830894 },
    { lat: 49.355806, lng: 18.830563 },
    { lat: 49.356846, lng: 18.829969 },
    { lat: 49.35724, lng: 18.829839 },
    { lat: 49.357577, lng: 18.829459 },
    { lat: 49.357881, lng: 18.8293 },
    { lat: 49.358078, lng: 18.829115 },
    { lat: 49.35848, lng: 18.828552 },
    { lat: 49.35913, lng: 18.827866 },
    { lat: 49.35884, lng: 18.827036 },
    { lat: 49.358474, lng: 18.825509 },
    { lat: 49.358078, lng: 18.820312 },
    { lat: 49.357852, lng: 18.818755 },
    { lat: 49.357866, lng: 18.818248 },
    { lat: 49.357053, lng: 18.8145 },
    { lat: 49.355929, lng: 18.810488 },
    { lat: 49.355314, lng: 18.808955 },
    { lat: 49.354885, lng: 18.807932 },
    { lat: 49.354154, lng: 18.807492 },
  ],
  Lodno: [
    { lat: 49.346776, lng: 18.868373 },
    { lat: 49.3468707, lng: 18.8672999 },
    { lat: 49.3468639, lng: 18.8665449 },
    { lat: 49.3467175, lng: 18.8650892 },
    { lat: 49.3466868, lng: 18.8640247 },
    { lat: 49.3466985, lng: 18.8621571 },
    { lat: 49.3466264, lng: 18.8610086 },
    { lat: 49.3466476, lng: 18.8602123 },
    { lat: 49.3466314, lng: 18.8592285 },
    { lat: 49.346575, lng: 18.8585692 },
    { lat: 49.3465418, lng: 18.8570826 },
    { lat: 49.3465753, lng: 18.8560148 },
    { lat: 49.3464936, lng: 18.8546844 },
    { lat: 49.3464523, lng: 18.8536541 },
    { lat: 49.3465001, lng: 18.8531747 },
    { lat: 49.346535, lng: 18.8522495 },
    { lat: 49.3465663, lng: 18.8506814 },
    { lat: 49.3465116, lng: 18.8492806 },
    { lat: 49.3459327, lng: 18.8486749 },
    { lat: 49.3458435, lng: 18.8479888 },
    { lat: 49.3454061, lng: 18.8478803 },
    { lat: 49.344714, lng: 18.8479474 },
    { lat: 49.3431405, lng: 18.8477398 },
    { lat: 49.3428191, lng: 18.8475617 },
    { lat: 49.3424053, lng: 18.8473391 },
    { lat: 49.3420835, lng: 18.8472417 },
    { lat: 49.3417924, lng: 18.8472945 },
    { lat: 49.3416846, lng: 18.8473141 },
    { lat: 49.3409907, lng: 18.8471276 },
    { lat: 49.3408082, lng: 18.8470613 },
    { lat: 49.3406186, lng: 18.8470539 },
    { lat: 49.340402, lng: 18.8470782 },
    { lat: 49.3399306, lng: 18.8471415 },
    { lat: 49.3397448, lng: 18.8471667 },
    { lat: 49.3388377, lng: 18.8474201 },
    { lat: 49.3382106, lng: 18.8472842 },
    { lat: 49.3381232, lng: 18.8472513 },
    { lat: 49.3374577, lng: 18.8470038 },
    { lat: 49.336859, lng: 18.8465424 },
    { lat: 49.3366033, lng: 18.846171 },
    { lat: 49.3365251, lng: 18.8461164 },
    { lat: 49.3364641, lng: 18.8460919 },
    { lat: 49.3364418, lng: 18.8460985 },
    { lat: 49.3361426, lng: 18.8460227 },
    { lat: 49.3360847, lng: 18.8460177 },
    { lat: 49.335555, lng: 18.8459124 },
    { lat: 49.3355515, lng: 18.8459085 },
    { lat: 49.3354966, lng: 18.8458927 },
    { lat: 49.3354156, lng: 18.8458745 },
    { lat: 49.335416, lng: 18.8458376 },
    { lat: 49.3354153, lng: 18.8458008 },
    { lat: 49.3353904, lng: 18.8452084 },
    { lat: 49.3353898, lng: 18.8451958 },
    { lat: 49.3349104, lng: 18.8453198 },
    { lat: 49.334681, lng: 18.8452685 },
    { lat: 49.334601, lng: 18.8452025 },
    { lat: 49.3344911, lng: 18.8451698 },
    { lat: 49.334329, lng: 18.8450851 },
    { lat: 49.3341552, lng: 18.8450409 },
    { lat: 49.3340017, lng: 18.8450561 },
    { lat: 49.333841, lng: 18.8450681 },
    { lat: 49.3332495, lng: 18.8450573 },
    { lat: 49.3331148, lng: 18.8450243 },
    { lat: 49.332944, lng: 18.8449219 },
    { lat: 49.3327145, lng: 18.844824 },
    { lat: 49.3326514, lng: 18.8448001 },
    { lat: 49.3325673, lng: 18.8447693 },
    { lat: 49.3324281, lng: 18.8447777 },
    { lat: 49.3321211, lng: 18.8447929 },
    { lat: 49.3320237, lng: 18.8447413 },
    { lat: 49.3319227, lng: 18.8447527 },
    { lat: 49.3314727, lng: 18.8450127 },
    { lat: 49.3311546, lng: 18.8449865 },
    { lat: 49.3308473, lng: 18.8448639 },
    { lat: 49.3305757, lng: 18.8448665 },
    { lat: 49.3303843, lng: 18.8449386 },
    { lat: 49.3301397, lng: 18.8452702 },
    { lat: 49.3298816, lng: 18.8457338 },
    { lat: 49.3297899, lng: 18.8457691 },
    { lat: 49.3296378, lng: 18.8457475 },
    { lat: 49.3293225, lng: 18.8454922 },
    { lat: 49.329083, lng: 18.8451757 },
    { lat: 49.3290048, lng: 18.8451012 },
    { lat: 49.328752, lng: 18.8449847 },
    { lat: 49.3285218, lng: 18.8450716 },
    { lat: 49.3282351, lng: 18.8451104 },
    { lat: 49.3279, lng: 18.8451917 },
    { lat: 49.3275619, lng: 18.8453017 },
    { lat: 49.3273045, lng: 18.8452762 },
    { lat: 49.3270823, lng: 18.8451864 },
    { lat: 49.3266602, lng: 18.8449349 },
    { lat: 49.3265137, lng: 18.8448707 },
    { lat: 49.3263274, lng: 18.8447344 },
    { lat: 49.3259724, lng: 18.8447593 },
    { lat: 49.3259611, lng: 18.8448235 },
    { lat: 49.325452, lng: 18.8448762 },
    { lat: 49.3247371, lng: 18.8451207 },
    { lat: 49.3245121, lng: 18.8451285 },
    { lat: 49.3241609, lng: 18.8445688 },
    { lat: 49.322809, lng: 18.8451155 },
    { lat: 49.3228985, lng: 18.8461811 },
    { lat: 49.3229166, lng: 18.8466448 },
    { lat: 49.3228264, lng: 18.8478115 },
    { lat: 49.3226881, lng: 18.8486554 },
    { lat: 49.3225282, lng: 18.8490519 },
    { lat: 49.3223229, lng: 18.8499761 },
    { lat: 49.3221919, lng: 18.8501082 },
    { lat: 49.3220146, lng: 18.8506962 },
    { lat: 49.3219733, lng: 18.8511435 },
    { lat: 49.3217927, lng: 18.8514706 },
    { lat: 49.3217367, lng: 18.8518954 },
    { lat: 49.321682, lng: 18.852146 },
    { lat: 49.3214856, lng: 18.852781 },
    { lat: 49.321634, lng: 18.8537431 },
    { lat: 49.3218425, lng: 18.8541157 },
    { lat: 49.3219445, lng: 18.8547645 },
    { lat: 49.3227735, lng: 18.8561175 },
    { lat: 49.323405, lng: 18.8570872 },
    { lat: 49.3234572, lng: 18.8573379 },
    { lat: 49.3235271, lng: 18.8576458 },
    { lat: 49.3238354, lng: 18.8584721 },
    { lat: 49.3240241, lng: 18.8594098 },
    { lat: 49.3241088, lng: 18.8596527 },
    { lat: 49.3240664, lng: 18.8603669 },
    { lat: 49.3239379, lng: 18.8612743 },
    { lat: 49.3232423, lng: 18.8620916 },
    { lat: 49.3229466, lng: 18.8623873 },
    { lat: 49.3228659, lng: 18.862468 },
    { lat: 49.3225292, lng: 18.863204 },
    { lat: 49.3224681, lng: 18.8637105 },
    { lat: 49.3222924, lng: 18.8640448 },
    { lat: 49.3222879, lng: 18.8641079 },
    { lat: 49.3219851, lng: 18.8646696 },
    { lat: 49.3217455, lng: 18.865146 },
    { lat: 49.3215183, lng: 18.8661686 },
    { lat: 49.3215226, lng: 18.8668099 },
    { lat: 49.3215997, lng: 18.8673884 },
    { lat: 49.3217056, lng: 18.867894 },
    { lat: 49.3216841, lng: 18.8694402 },
    { lat: 49.321646, lng: 18.869976 },
    { lat: 49.3216103, lng: 18.870736 },
    { lat: 49.3215056, lng: 18.871999 },
    { lat: 49.3213846, lng: 18.8726102 },
    { lat: 49.3212995, lng: 18.8731516 },
    { lat: 49.321237, lng: 18.8737193 },
    { lat: 49.3213643, lng: 18.8743568 },
    { lat: 49.3213905, lng: 18.8747588 },
    { lat: 49.3213114, lng: 18.8751539 },
    { lat: 49.3212147, lng: 18.8755337 },
    { lat: 49.32104, lng: 18.8760229 },
    { lat: 49.3209412, lng: 18.8762064 },
    { lat: 49.3207578, lng: 18.8764364 },
    { lat: 49.3206199, lng: 18.8767623 },
    { lat: 49.3206269, lng: 18.876966 },
    { lat: 49.3206426, lng: 18.8774495 },
    { lat: 49.320641, lng: 18.8777515 },
    { lat: 49.3204406, lng: 18.8787515 },
    { lat: 49.320261, lng: 18.8806063 },
    { lat: 49.3201474, lng: 18.8815624 },
    { lat: 49.3201461, lng: 18.8821119 },
    { lat: 49.3203083, lng: 18.8826857 },
    { lat: 49.3203749, lng: 18.8836821 },
    { lat: 49.3203814, lng: 18.8842747 },
    { lat: 49.320271, lng: 18.8852951 },
    { lat: 49.3202462, lng: 18.885848 },
    { lat: 49.3202287, lng: 18.8862376 },
    { lat: 49.3201517, lng: 18.8872085 },
    { lat: 49.3199974, lng: 18.8874939 },
    { lat: 49.3198671, lng: 18.8879283 },
    { lat: 49.3198302, lng: 18.8883555 },
    { lat: 49.3198071, lng: 18.8889188 },
    { lat: 49.3197867, lng: 18.8892938 },
    { lat: 49.3196416, lng: 18.8897433 },
    { lat: 49.319509, lng: 18.8901501 },
    { lat: 49.3194257, lng: 18.8903975 },
    { lat: 49.3193732, lng: 18.8911406 },
    { lat: 49.319273, lng: 18.891674 },
    { lat: 49.3190695, lng: 18.8919281 },
    { lat: 49.3190314, lng: 18.892113 },
    { lat: 49.3189913, lng: 18.8923006 },
    { lat: 49.3189877, lng: 18.8929598 },
    { lat: 49.3189192, lng: 18.8937631 },
    { lat: 49.318876, lng: 18.894141 },
    { lat: 49.319314, lng: 18.893663 },
    { lat: 49.319865, lng: 18.893266 },
    { lat: 49.3203064, lng: 18.8930773 },
    { lat: 49.320698, lng: 18.89291 },
    { lat: 49.32121, lng: 18.892528 },
    { lat: 49.321794, lng: 18.893062 },
    { lat: 49.322914, lng: 18.893241 },
    { lat: 49.323587, lng: 18.892761 },
    { lat: 49.323896, lng: 18.892645 },
    { lat: 49.324437, lng: 18.89276 },
    { lat: 49.324972, lng: 18.89332 },
    { lat: 49.325201, lng: 18.8937 },
    { lat: 49.32549, lng: 18.893435 },
    { lat: 49.326627, lng: 18.894398 },
    { lat: 49.327249, lng: 18.894554 },
    { lat: 49.327771, lng: 18.89481 },
    { lat: 49.328475, lng: 18.895531 },
    { lat: 49.32965, lng: 18.896468 },
    { lat: 49.330836, lng: 18.896025 },
    { lat: 49.331275, lng: 18.895994 },
    { lat: 49.332143, lng: 18.895666 },
    { lat: 49.332854, lng: 18.895149 },
    { lat: 49.334141, lng: 18.894782 },
    { lat: 49.334554, lng: 18.894738 },
    { lat: 49.335066, lng: 18.894526 },
    { lat: 49.335895, lng: 18.894364 },
    { lat: 49.336579, lng: 18.894459 },
    { lat: 49.336843, lng: 18.894424 },
    { lat: 49.337167, lng: 18.894558 },
    { lat: 49.337297, lng: 18.894528 },
    { lat: 49.337433, lng: 18.893805 },
    { lat: 49.337449, lng: 18.893234 },
    { lat: 49.33768, lng: 18.892547 },
    { lat: 49.338149, lng: 18.891604 },
    { lat: 49.338408, lng: 18.890033 },
    { lat: 49.338588, lng: 18.889255 },
    { lat: 49.339222, lng: 18.887759 },
    { lat: 49.339374, lng: 18.887556 },
    { lat: 49.339766, lng: 18.887239 },
    { lat: 49.339894, lng: 18.88705 },
    { lat: 49.340026, lng: 18.886855 },
    { lat: 49.340546, lng: 18.885832 },
    { lat: 49.340807, lng: 18.8855 },
    { lat: 49.34093, lng: 18.885122 },
    { lat: 49.341084, lng: 18.884238 },
    { lat: 49.341169, lng: 18.882762 },
    { lat: 49.341458, lng: 18.880889 },
    { lat: 49.343022, lng: 18.878401 },
    { lat: 49.343602, lng: 18.877208 },
    { lat: 49.343744, lng: 18.876758 },
    { lat: 49.344089, lng: 18.876186 },
    { lat: 49.344232, lng: 18.875708 },
    { lat: 49.344574, lng: 18.875183 },
    { lat: 49.345189, lng: 18.874619 },
    { lat: 49.345389, lng: 18.874263 },
    { lat: 49.345763, lng: 18.872377 },
    { lat: 49.345747, lng: 18.871196 },
    { lat: 49.345796, lng: 18.870638 },
    { lat: 49.345979, lng: 18.869981 },
    { lat: 49.346185, lng: 18.86952 },
    { lat: 49.346351, lng: 18.868837 },
    { lat: 49.346776, lng: 18.868373 },
  ],
  LopušnéPažite: [
    { lat: 49.267999, lng: 18.815081 },
    { lat: 49.268006, lng: 18.816515 },
    { lat: 49.268011, lng: 18.817247 },
    { lat: 49.268012, lng: 18.817796 },
    { lat: 49.268006, lng: 18.818708 },
    { lat: 49.267999, lng: 18.8197 },
    { lat: 49.267985, lng: 18.821541 },
    { lat: 49.267857, lng: 18.822501 },
    { lat: 49.26772, lng: 18.82347 },
    { lat: 49.267731, lng: 18.824122 },
    { lat: 49.267746, lng: 18.824905 },
    { lat: 49.267749, lng: 18.825838 },
    { lat: 49.267704, lng: 18.826542 },
    { lat: 49.267195, lng: 18.82729 },
    { lat: 49.2677013, lng: 18.8279619 },
    { lat: 49.2680754, lng: 18.8284409 },
    { lat: 49.2682228, lng: 18.8282141 },
    { lat: 49.2685898, lng: 18.8288828 },
    { lat: 49.2688234, lng: 18.8292815 },
    { lat: 49.2690219, lng: 18.8297003 },
    { lat: 49.2692649, lng: 18.830217 },
    { lat: 49.2696013, lng: 18.8308363 },
    { lat: 49.269737, lng: 18.8310864 },
    { lat: 49.2697482, lng: 18.8311068 },
    { lat: 49.270188, lng: 18.8319165 },
    { lat: 49.2702587, lng: 18.8321124 },
    { lat: 49.2705108, lng: 18.832805 },
    { lat: 49.2705187, lng: 18.8328261 },
    { lat: 49.2706214, lng: 18.8329351 },
    { lat: 49.2708879, lng: 18.8332526 },
    { lat: 49.270941, lng: 18.8334953 },
    { lat: 49.2710985, lng: 18.8342152 },
    { lat: 49.2713393, lng: 18.8345557 },
    { lat: 49.2715678, lng: 18.8348785 },
    { lat: 49.2718721, lng: 18.8354673 },
    { lat: 49.2719598, lng: 18.8356374 },
    { lat: 49.2722448, lng: 18.8362242 },
    { lat: 49.2725219, lng: 18.8364842 },
    { lat: 49.2727558, lng: 18.8368894 },
    { lat: 49.2729987, lng: 18.8372495 },
    { lat: 49.2730415, lng: 18.8373144 },
    { lat: 49.2731106, lng: 18.8374545 },
    { lat: 49.2731181, lng: 18.8374659 },
    { lat: 49.2731315, lng: 18.8374849 },
    { lat: 49.273151, lng: 18.8375147 },
    { lat: 49.2731765, lng: 18.837551 },
    { lat: 49.2731923, lng: 18.8375733 },
    { lat: 49.2732424, lng: 18.837646 },
    { lat: 49.2732474, lng: 18.8376328 },
    { lat: 49.2733095, lng: 18.837725 },
    { lat: 49.2728624, lng: 18.8384137 },
    { lat: 49.2727939, lng: 18.8383992 },
    { lat: 49.2725532, lng: 18.8389578 },
    { lat: 49.272656, lng: 18.8390494 },
    { lat: 49.2726572, lng: 18.8390514 },
    { lat: 49.272819, lng: 18.8391971 },
    { lat: 49.2733652, lng: 18.8396894 },
    { lat: 49.273439, lng: 18.8397409 },
    { lat: 49.2734876, lng: 18.8397907 },
    { lat: 49.2736091, lng: 18.8399162 },
    { lat: 49.2736224, lng: 18.8399309 },
    { lat: 49.2736305, lng: 18.8399375 },
    { lat: 49.2736572, lng: 18.8399512 },
    { lat: 49.2736745, lng: 18.8399614 },
    { lat: 49.2737137, lng: 18.8399972 },
    { lat: 49.2737458, lng: 18.8400515 },
    { lat: 49.273812, lng: 18.8401349 },
    { lat: 49.2738566, lng: 18.8401895 },
    { lat: 49.2739301, lng: 18.8402597 },
    { lat: 49.2740576, lng: 18.8404138 },
    { lat: 49.2740706, lng: 18.8404278 },
    { lat: 49.2741289, lng: 18.8404876 },
    { lat: 49.2741528, lng: 18.8405114 },
    { lat: 49.2743319, lng: 18.8407276 },
    { lat: 49.27441, lng: 18.8411075 },
    { lat: 49.2746902, lng: 18.8413606 },
    { lat: 49.2751359, lng: 18.8417636 },
    { lat: 49.2755799, lng: 18.8424181 },
    { lat: 49.2756006, lng: 18.8424488 },
    { lat: 49.275608, lng: 18.8424588 },
    { lat: 49.2756458, lng: 18.8425138 },
    { lat: 49.2756995, lng: 18.8425933 },
    { lat: 49.2761839, lng: 18.8430513 },
    { lat: 49.2762467, lng: 18.8431113 },
    { lat: 49.2767369, lng: 18.8435187 },
    { lat: 49.27687, lng: 18.8439412 },
    { lat: 49.2769752, lng: 18.8441673 },
    { lat: 49.2769967, lng: 18.8442124 },
    { lat: 49.2770171, lng: 18.8442554 },
    { lat: 49.2770846, lng: 18.8442777 },
    { lat: 49.2772949, lng: 18.8449472 },
    { lat: 49.2774115, lng: 18.84522 },
    { lat: 49.2775893, lng: 18.845476 },
    { lat: 49.2777153, lng: 18.8455617 },
    { lat: 49.277776, lng: 18.8456028 },
    { lat: 49.2779454, lng: 18.8457172 },
    { lat: 49.2781253, lng: 18.8458394 },
    { lat: 49.2785439, lng: 18.8460463 },
    { lat: 49.2795117, lng: 18.8462731 },
    { lat: 49.2797105, lng: 18.8463208 },
    { lat: 49.2798424, lng: 18.8464978 },
    { lat: 49.2799113, lng: 18.8465885 },
    { lat: 49.2801836, lng: 18.8469492 },
    { lat: 49.2804322, lng: 18.847269 },
    { lat: 49.2806785, lng: 18.8475656 },
    { lat: 49.2809754, lng: 18.8479247 },
    { lat: 49.281414, lng: 18.8482348 },
    { lat: 49.2819427, lng: 18.8486301 },
    { lat: 49.28233, lng: 18.8488387 },
    { lat: 49.2827361, lng: 18.849105 },
    { lat: 49.2834072, lng: 18.8494165 },
    { lat: 49.28352, lng: 18.8494655 },
    { lat: 49.2838364, lng: 18.849632 },
    { lat: 49.2843699, lng: 18.8499643 },
    { lat: 49.2852085, lng: 18.85047 },
    { lat: 49.2857523, lng: 18.8505183 },
    { lat: 49.2860892, lng: 18.8506892 },
    { lat: 49.2862146, lng: 18.8494625 },
    { lat: 49.2862015, lng: 18.8479753 },
    { lat: 49.2867785, lng: 18.8454838 },
    { lat: 49.2869737, lng: 18.8437524 },
    { lat: 49.2871747, lng: 18.8430319 },
    { lat: 49.2875225, lng: 18.8418988 },
    { lat: 49.2878851, lng: 18.8398489 },
    { lat: 49.2879509, lng: 18.8387336 },
    { lat: 49.2880804, lng: 18.8377205 },
    { lat: 49.2889163, lng: 18.8359727 },
    { lat: 49.2892714, lng: 18.8344351 },
    { lat: 49.2891379, lng: 18.8327795 },
    { lat: 49.2891687, lng: 18.8324139 },
    { lat: 49.2893928, lng: 18.8315324 },
    { lat: 49.2892833, lng: 18.8308268 },
    { lat: 49.2892414, lng: 18.8299259 },
    { lat: 49.28928, lng: 18.8292047 },
    { lat: 49.2893825, lng: 18.82835 },
    { lat: 49.2896815, lng: 18.8270791 },
    { lat: 49.2896639, lng: 18.8249649 },
    { lat: 49.2895386, lng: 18.8241492 },
    { lat: 49.2894191, lng: 18.8238208 },
    { lat: 49.2893784, lng: 18.8237087 },
    { lat: 49.2889708, lng: 18.8235013 },
    { lat: 49.2888083, lng: 18.8232998 },
    { lat: 49.288639, lng: 18.8227935 },
    { lat: 49.2880896, lng: 18.8220425 },
    { lat: 49.2877227, lng: 18.8220578 },
    { lat: 49.2863867, lng: 18.8203927 },
    { lat: 49.284589, lng: 18.8190502 },
    { lat: 49.2843522, lng: 18.8188728 },
    { lat: 49.2843153, lng: 18.8189231 },
    { lat: 49.2842924, lng: 18.8188696 },
    { lat: 49.2842681, lng: 18.8188143 },
    { lat: 49.2842104, lng: 18.8186811 },
    { lat: 49.2841861, lng: 18.8186259 },
    { lat: 49.2841441, lng: 18.8185306 },
    { lat: 49.2841567, lng: 18.8185146 },
    { lat: 49.284111, lng: 18.8184341 },
    { lat: 49.2840873, lng: 18.8183919 },
    { lat: 49.2841928, lng: 18.8182517 },
    { lat: 49.2843063, lng: 18.8181105 },
    { lat: 49.2843169, lng: 18.8180973 },
    { lat: 49.2843996, lng: 18.8179953 },
    { lat: 49.284376, lng: 18.8179558 },
    { lat: 49.2843864, lng: 18.8178488 },
    { lat: 49.2843473, lng: 18.8178798 },
    { lat: 49.284279, lng: 18.8179115 },
    { lat: 49.284158, lng: 18.8179474 },
    { lat: 49.2841319, lng: 18.8179451 },
    { lat: 49.2839273, lng: 18.8179308 },
    { lat: 49.2839066, lng: 18.8179235 },
    { lat: 49.2838446, lng: 18.8179039 },
    { lat: 49.2838263, lng: 18.8178981 },
    { lat: 49.2837299, lng: 18.817866 },
    { lat: 49.2836954, lng: 18.8178521 },
    { lat: 49.2835733, lng: 18.8178025 },
    { lat: 49.2834358, lng: 18.8177599 },
    { lat: 49.2835201, lng: 18.8171412 },
    { lat: 49.283541, lng: 18.8166518 },
    { lat: 49.2835207, lng: 18.8166069 },
    { lat: 49.2834706, lng: 18.8165964 },
    { lat: 49.2834112, lng: 18.8160776 },
    { lat: 49.2833449, lng: 18.8160842 },
    { lat: 49.2832777, lng: 18.8160912 },
    { lat: 49.2831783, lng: 18.8156936 },
    { lat: 49.2831693, lng: 18.8156972 },
    { lat: 49.2830378, lng: 18.8157441 },
    { lat: 49.2830342, lng: 18.8157449 },
    { lat: 49.2830111, lng: 18.8157535 },
    { lat: 49.2829528, lng: 18.8157179 },
    { lat: 49.2819488, lng: 18.8156625 },
    { lat: 49.2818031, lng: 18.8156808 },
    { lat: 49.2810034, lng: 18.8157832 },
    { lat: 49.2781922, lng: 18.816126 },
    { lat: 49.277869, lng: 18.8164199 },
    { lat: 49.2769653, lng: 18.8172404 },
    { lat: 49.2769073, lng: 18.8172936 },
    { lat: 49.2764034, lng: 18.8176247 },
    { lat: 49.2754195, lng: 18.8167801 },
    { lat: 49.2748618, lng: 18.8159112 },
    { lat: 49.2745075, lng: 18.8165172 },
    { lat: 49.2742556, lng: 18.815765 },
    { lat: 49.273215, lng: 18.8167089 },
    { lat: 49.2729634, lng: 18.8165297 },
    { lat: 49.2731546, lng: 18.8160406 },
    { lat: 49.2727088, lng: 18.8158115 },
    { lat: 49.2724801, lng: 18.8158177 },
    { lat: 49.2721046, lng: 18.8164533 },
    { lat: 49.2717646, lng: 18.8171402 },
    { lat: 49.2716136, lng: 18.8177534 },
    { lat: 49.2713429, lng: 18.8179488 },
    { lat: 49.2709711, lng: 18.818216 },
    { lat: 49.2703865, lng: 18.817353 },
    { lat: 49.2696057, lng: 18.8167717 },
    { lat: 49.2691663, lng: 18.8164509 },
    { lat: 49.267999, lng: 18.815081 },
  ],
  Nesluša: [
    { lat: 49.35619, lng: 18.676226 },
    { lat: 49.356159, lng: 18.6762815 },
    { lat: 49.3559907, lng: 18.6760997 },
    { lat: 49.3558142, lng: 18.6757897 },
    { lat: 49.3558052, lng: 18.6757507 },
    { lat: 49.3557348, lng: 18.6758672 },
    { lat: 49.3556499, lng: 18.6756277 },
    { lat: 49.3555392, lng: 18.6755582 },
    { lat: 49.3555214, lng: 18.6756063 },
    { lat: 49.3552326, lng: 18.6757306 },
    { lat: 49.3550549, lng: 18.6758473 },
    { lat: 49.3547346, lng: 18.6763607 },
    { lat: 49.3543971, lng: 18.6771059 },
    { lat: 49.3543432, lng: 18.6774497 },
    { lat: 49.3541897, lng: 18.6789463 },
    { lat: 49.3541754, lng: 18.6792772 },
    { lat: 49.3541358, lng: 18.6797451 },
    { lat: 49.3541152, lng: 18.6800591 },
    { lat: 49.3541192, lng: 18.6801353 },
    { lat: 49.3541426, lng: 18.6803466 },
    { lat: 49.3541641, lng: 18.6804756 },
    { lat: 49.3541777, lng: 18.6804964 },
    { lat: 49.3541975, lng: 18.6805543 },
    { lat: 49.3541695, lng: 18.6808114 },
    { lat: 49.3541432, lng: 18.6809133 },
    { lat: 49.3541465, lng: 18.6811922 },
    { lat: 49.3542358, lng: 18.6821582 },
    { lat: 49.3543772, lng: 18.6820373 },
    { lat: 49.3543385, lng: 18.682291 },
    { lat: 49.3542468, lng: 18.6824557 },
    { lat: 49.3543353, lng: 18.6827012 },
    { lat: 49.3542146, lng: 18.6828289 },
    { lat: 49.3542328, lng: 18.683043 },
    { lat: 49.3542396, lng: 18.6834965 },
    { lat: 49.354117, lng: 18.6835022 },
    { lat: 49.3540567, lng: 18.6834286 },
    { lat: 49.3538409, lng: 18.6834856 },
    { lat: 49.3534768, lng: 18.6836347 },
    { lat: 49.3532672, lng: 18.6837882 },
    { lat: 49.3531138, lng: 18.6839171 },
    { lat: 49.3529797, lng: 18.6840674 },
    { lat: 49.3528072, lng: 18.6843506 },
    { lat: 49.3523038, lng: 18.6848109 },
    { lat: 49.352251, lng: 18.6852236 },
    { lat: 49.3520815, lng: 18.6854738 },
    { lat: 49.3518653, lng: 18.6855623 },
    { lat: 49.3518238, lng: 18.6854838 },
    { lat: 49.3516175, lng: 18.6856764 },
    { lat: 49.3515061, lng: 18.6859809 },
    { lat: 49.3513895, lng: 18.6861895 },
    { lat: 49.3513341, lng: 18.686336 },
    { lat: 49.3512668, lng: 18.6868137 },
    { lat: 49.3512716, lng: 18.6872917 },
    { lat: 49.351415, lng: 18.6875099 },
    { lat: 49.3514564, lng: 18.6878035 },
    { lat: 49.3515228, lng: 18.6880142 },
    { lat: 49.3516267, lng: 18.6881258 },
    { lat: 49.3518296, lng: 18.6884036 },
    { lat: 49.3518697, lng: 18.6886724 },
    { lat: 49.3521937, lng: 18.6899235 },
    { lat: 49.3521748, lng: 18.6900575 },
    { lat: 49.3521529, lng: 18.690198 },
    { lat: 49.3521871, lng: 18.6903374 },
    { lat: 49.3521823, lng: 18.6904382 },
    { lat: 49.3521953, lng: 18.6904919 },
    { lat: 49.3518772, lng: 18.690789 },
    { lat: 49.3514321, lng: 18.6912677 },
    { lat: 49.3511906, lng: 18.6918237 },
    { lat: 49.3510924, lng: 18.6919931 },
    { lat: 49.3510693, lng: 18.6920481 },
    { lat: 49.3511142, lng: 18.6922541 },
    { lat: 49.3511148, lng: 18.6924595 },
    { lat: 49.3510969, lng: 18.6926766 },
    { lat: 49.3510834, lng: 18.6928739 },
    { lat: 49.3511077, lng: 18.6931664 },
    { lat: 49.3512142, lng: 18.6936243 },
    { lat: 49.3513794, lng: 18.6938944 },
    { lat: 49.3515128, lng: 18.6940711 },
    { lat: 49.351749, lng: 18.6943177 },
    { lat: 49.3519351, lng: 18.6947704 },
    { lat: 49.352028, lng: 18.6949283 },
    { lat: 49.3517918, lng: 18.6951548 },
    { lat: 49.3515199, lng: 18.6954534 },
    { lat: 49.35132, lng: 18.6956379 },
    { lat: 49.3509584, lng: 18.6960965 },
    { lat: 49.35086, lng: 18.6962652 },
    { lat: 49.3504036, lng: 18.6972133 },
    { lat: 49.3502245, lng: 18.6982083 },
    { lat: 49.3501594, lng: 18.6982962 },
    { lat: 49.3501045, lng: 18.698456 },
    { lat: 49.3500875, lng: 18.6986234 },
    { lat: 49.3499002, lng: 18.6984945 },
    { lat: 49.3498255, lng: 18.6987044 },
    { lat: 49.3497174, lng: 18.6992888 },
    { lat: 49.3497751, lng: 18.6996365 },
    { lat: 49.3497644, lng: 18.6997977 },
    { lat: 49.3497182, lng: 18.6999256 },
    { lat: 49.3497348, lng: 18.7000075 },
    { lat: 49.3497838, lng: 18.7002514 },
    { lat: 49.3498033, lng: 18.7004707 },
    { lat: 49.3497263, lng: 18.7007243 },
    { lat: 49.3497605, lng: 18.7009711 },
    { lat: 49.34967, lng: 18.701782 },
    { lat: 49.3495397, lng: 18.7018562 },
    { lat: 49.3494678, lng: 18.7019226 },
    { lat: 49.3494533, lng: 18.7025685 },
    { lat: 49.3494521, lng: 18.7026374 },
    { lat: 49.3490397, lng: 18.7028967 },
    { lat: 49.3488846, lng: 18.7029943 },
    { lat: 49.3487905, lng: 18.7031766 },
    { lat: 49.3485702, lng: 18.7034286 },
    { lat: 49.3482241, lng: 18.7039019 },
    { lat: 49.3481334, lng: 18.7040649 },
    { lat: 49.347888, lng: 18.704545 },
    { lat: 49.347826, lng: 18.7045251 },
    { lat: 49.3476709, lng: 18.7047084 },
    { lat: 49.3474779, lng: 18.7048124 },
    { lat: 49.3472456, lng: 18.7048535 },
    { lat: 49.3469114, lng: 18.7049098 },
    { lat: 49.3467959, lng: 18.7049873 },
    { lat: 49.3466296, lng: 18.7050203 },
    { lat: 49.346521, lng: 18.7050995 },
    { lat: 49.3463474, lng: 18.7051848 },
    { lat: 49.3461705, lng: 18.7052724 },
    { lat: 49.3459162, lng: 18.705393 },
    { lat: 49.3457005, lng: 18.7055153 },
    { lat: 49.3456328, lng: 18.7055985 },
    { lat: 49.3456062, lng: 18.7056093 },
    { lat: 49.3454841, lng: 18.7057097 },
    { lat: 49.3453908, lng: 18.7057385 },
    { lat: 49.3446578, lng: 18.7056737 },
    { lat: 49.3445981, lng: 18.7055686 },
    { lat: 49.344481, lng: 18.7055464 },
    { lat: 49.3442682, lng: 18.7053606 },
    { lat: 49.3441048, lng: 18.7052765 },
    { lat: 49.3440898, lng: 18.7053153 },
    { lat: 49.344002, lng: 18.7052718 },
    { lat: 49.3437921, lng: 18.7051844 },
    { lat: 49.3434524, lng: 18.7048753 },
    { lat: 49.3434186, lng: 18.7048126 },
    { lat: 49.3428452, lng: 18.7047365 },
    { lat: 49.3423541, lng: 18.7045954 },
    { lat: 49.341916, lng: 18.7046315 },
    { lat: 49.3416752, lng: 18.7048969 },
    { lat: 49.341226, lng: 18.7052337 },
    { lat: 49.3406536, lng: 18.7057372 },
    { lat: 49.3404923, lng: 18.7058015 },
    { lat: 49.3402943, lng: 18.7059144 },
    { lat: 49.340096, lng: 18.7059815 },
    { lat: 49.3399659, lng: 18.7059736 },
    { lat: 49.3397301, lng: 18.7058217 },
    { lat: 49.3395962, lng: 18.7058049 },
    { lat: 49.3392892, lng: 18.7058216 },
    { lat: 49.3389002, lng: 18.705716 },
    { lat: 49.3386275, lng: 18.7055673 },
    { lat: 49.3382617, lng: 18.7055583 },
    { lat: 49.3380367, lng: 18.7054167 },
    { lat: 49.3378984, lng: 18.7054076 },
    { lat: 49.3375189, lng: 18.7055233 },
    { lat: 49.337379, lng: 18.7056732 },
    { lat: 49.3370895, lng: 18.706087 },
    { lat: 49.3369707, lng: 18.7062491 },
    { lat: 49.3367048, lng: 18.7063208 },
    { lat: 49.3365232, lng: 18.7064269 },
    { lat: 49.3361372, lng: 18.7069335 },
    { lat: 49.336146, lng: 18.7071689 },
    { lat: 49.3359516, lng: 18.7077598 },
    { lat: 49.3359462, lng: 18.7081748 },
    { lat: 49.3360028, lng: 18.7084568 },
    { lat: 49.336098, lng: 18.7093957 },
    { lat: 49.3360602, lng: 18.7097243 },
    { lat: 49.3359614, lng: 18.7100358 },
    { lat: 49.3357507, lng: 18.710275 },
    { lat: 49.3356747, lng: 18.7105252 },
    { lat: 49.335517, lng: 18.7109784 },
    { lat: 49.335434, lng: 18.7113968 },
    { lat: 49.3353623, lng: 18.7117737 },
    { lat: 49.3352359, lng: 18.7119009 },
    { lat: 49.3350736, lng: 18.7120091 },
    { lat: 49.3347234, lng: 18.7120629 },
    { lat: 49.3344251, lng: 18.7122245 },
    { lat: 49.3340496, lng: 18.7123718 },
    { lat: 49.3338448, lng: 18.7125933 },
    { lat: 49.3336328, lng: 18.7127693 },
    { lat: 49.3329153, lng: 18.7128479 },
    { lat: 49.3327169, lng: 18.7128907 },
    { lat: 49.3325275, lng: 18.7128705 },
    { lat: 49.3321606, lng: 18.7129473 },
    { lat: 49.3319332, lng: 18.7132569 },
    { lat: 49.3315476, lng: 18.7133887 },
    { lat: 49.3314689, lng: 18.7134157 },
    { lat: 49.3313827, lng: 18.713533 },
    { lat: 49.3311868, lng: 18.7139265 },
    { lat: 49.3307373, lng: 18.7144932 },
    { lat: 49.3304992, lng: 18.7148156 },
    { lat: 49.330167, lng: 18.7153838 },
    { lat: 49.3301081, lng: 18.7155273 },
    { lat: 49.3299714, lng: 18.7158221 },
    { lat: 49.3298925, lng: 18.716108 },
    { lat: 49.3298202, lng: 18.7162752 },
    { lat: 49.3296209, lng: 18.7166875 },
    { lat: 49.3294096, lng: 18.716917 },
    { lat: 49.3293796, lng: 18.7169492 },
    { lat: 49.3293358, lng: 18.7169972 },
    { lat: 49.3292248, lng: 18.7171775 },
    { lat: 49.3291187, lng: 18.7175896 },
    { lat: 49.3290396, lng: 18.7179699 },
    { lat: 49.329047, lng: 18.7184994 },
    { lat: 49.3289983, lng: 18.7187338 },
    { lat: 49.3288516, lng: 18.7189051 },
    { lat: 49.3284754, lng: 18.7194287 },
    { lat: 49.3282743, lng: 18.7195741 },
    { lat: 49.3280034, lng: 18.7198694 },
    { lat: 49.3277244, lng: 18.7203992 },
    { lat: 49.3276414, lng: 18.7206013 },
    { lat: 49.3275104, lng: 18.7208972 },
    { lat: 49.3273231, lng: 18.7210072 },
    { lat: 49.3272291, lng: 18.7210625 },
    { lat: 49.3270485, lng: 18.7210376 },
    { lat: 49.3269142, lng: 18.7210768 },
    { lat: 49.3266787, lng: 18.7210836 },
    { lat: 49.3261373, lng: 18.7210989 },
    { lat: 49.3260659, lng: 18.721112 },
    { lat: 49.3259229, lng: 18.7211382 },
    { lat: 49.32592, lng: 18.7211281 },
    { lat: 49.3258309, lng: 18.7211455 },
    { lat: 49.3254469, lng: 18.7212218 },
    { lat: 49.3253479, lng: 18.7211873 },
    { lat: 49.3252654, lng: 18.7211594 },
    { lat: 49.3251922, lng: 18.7210957 },
    { lat: 49.324946, lng: 18.7210474 },
    { lat: 49.3247312, lng: 18.7210784 },
    { lat: 49.3245651, lng: 18.7211146 },
    { lat: 49.3244992, lng: 18.7211292 },
    { lat: 49.32439, lng: 18.7212821 },
    { lat: 49.3240422, lng: 18.7215532 },
    { lat: 49.3237583, lng: 18.7222061 },
    { lat: 49.323712, lng: 18.7223542 },
    { lat: 49.3236803, lng: 18.7224582 },
    { lat: 49.323526, lng: 18.7227829 },
    { lat: 49.3234503, lng: 18.7229353 },
    { lat: 49.323329, lng: 18.7231775 },
    { lat: 49.3232867, lng: 18.7234722 },
    { lat: 49.3232326, lng: 18.7238602 },
    { lat: 49.3231518, lng: 18.7240404 },
    { lat: 49.3231146, lng: 18.7241248 },
    { lat: 49.3230772, lng: 18.7242093 },
    { lat: 49.3228458, lng: 18.7244845 },
    { lat: 49.322664, lng: 18.7246535 },
    { lat: 49.3225071, lng: 18.7247989 },
    { lat: 49.3222737, lng: 18.7251423 },
    { lat: 49.3220713, lng: 18.7254801 },
    { lat: 49.3217837, lng: 18.725713 },
    { lat: 49.3217048, lng: 18.7259757 },
    { lat: 49.3216342, lng: 18.7260906 },
    { lat: 49.3215605, lng: 18.7261722 },
    { lat: 49.3213897, lng: 18.7262683 },
    { lat: 49.3212059, lng: 18.726332 },
    { lat: 49.3209924, lng: 18.7263881 },
    { lat: 49.32072, lng: 18.7265053 },
    { lat: 49.3200201, lng: 18.7268808 },
    { lat: 49.3197815, lng: 18.7270408 },
    { lat: 49.3194533, lng: 18.7272986 },
    { lat: 49.3190369, lng: 18.7277442 },
    { lat: 49.3186598, lng: 18.7280151 },
    { lat: 49.3182886, lng: 18.7282918 },
    { lat: 49.3180122, lng: 18.728442 },
    { lat: 49.3178289, lng: 18.7285335 },
    { lat: 49.3175467, lng: 18.7287216 },
    { lat: 49.3171341, lng: 18.7289426 },
    { lat: 49.3168824, lng: 18.7291537 },
    { lat: 49.3162257, lng: 18.7295568 },
    { lat: 49.3160692, lng: 18.7296222 },
    { lat: 49.3159031, lng: 18.7296584 },
    { lat: 49.3156278, lng: 18.7296136 },
    { lat: 49.3153874, lng: 18.7294384 },
    { lat: 49.3149312, lng: 18.7292741 },
    { lat: 49.3146886, lng: 18.7292932 },
    { lat: 49.3145651, lng: 18.7288692 },
    { lat: 49.3145207, lng: 18.7288641 },
    { lat: 49.3143169, lng: 18.7288901 },
    { lat: 49.3142172, lng: 18.7289034 },
    { lat: 49.3139319, lng: 18.7289477 },
    { lat: 49.3136959, lng: 18.7290474 },
    { lat: 49.3134532, lng: 18.7290647 },
    { lat: 49.3132449, lng: 18.7291567 },
    { lat: 49.3130709, lng: 18.7287631 },
    { lat: 49.3130174, lng: 18.7286023 },
    { lat: 49.3128323, lng: 18.7280987 },
    { lat: 49.3127331, lng: 18.7278871 },
    { lat: 49.3123685, lng: 18.7270141 },
    { lat: 49.3122422, lng: 18.7268401 },
    { lat: 49.3116533, lng: 18.7254788 },
    { lat: 49.3116424, lng: 18.7254859 },
    { lat: 49.3113039, lng: 18.724879 },
    { lat: 49.3111134, lng: 18.7246822 },
    { lat: 49.3109625, lng: 18.7244282 },
    { lat: 49.310941, lng: 18.7243827 },
    { lat: 49.3106251, lng: 18.7245099 },
    { lat: 49.3102275, lng: 18.7242806 },
    { lat: 49.3101793, lng: 18.7242034 },
    { lat: 49.3101126, lng: 18.7241349 },
    { lat: 49.3098338, lng: 18.7239547 },
    { lat: 49.3097602, lng: 18.7239923 },
    { lat: 49.309727, lng: 18.72394 },
    { lat: 49.3095765, lng: 18.7237998 },
    { lat: 49.3094799, lng: 18.7236573 },
    { lat: 49.3091858, lng: 18.7233145 },
    { lat: 49.3090539, lng: 18.7231424 },
    { lat: 49.308801, lng: 18.7229427 },
    { lat: 49.3087241, lng: 18.7227234 },
    { lat: 49.3087287, lng: 18.7225768 },
    { lat: 49.3087457, lng: 18.7224671 },
    { lat: 49.3087397, lng: 18.7223984 },
    { lat: 49.3087154, lng: 18.7223396 },
    { lat: 49.3086408, lng: 18.7222508 },
    { lat: 49.3086711, lng: 18.7221845 },
    { lat: 49.3086489, lng: 18.7221251 },
    { lat: 49.3086383, lng: 18.7220953 },
    { lat: 49.3084038, lng: 18.7222285 },
    { lat: 49.3078958, lng: 18.7224319 },
    { lat: 49.3076467, lng: 18.7225618 },
    { lat: 49.3074989, lng: 18.7226677 },
    { lat: 49.3074467, lng: 18.7227051 },
    { lat: 49.3072265, lng: 18.7229131 },
    { lat: 49.3071682, lng: 18.7230088 },
    { lat: 49.3071305, lng: 18.7230615 },
    { lat: 49.3070865, lng: 18.7231279 },
    { lat: 49.3070475, lng: 18.7231764 },
    { lat: 49.3069626, lng: 18.7233032 },
    { lat: 49.306922, lng: 18.7233435 },
    { lat: 49.3067912, lng: 18.7235182 },
    { lat: 49.306647, lng: 18.7235846 },
    { lat: 49.3066026, lng: 18.7235759 },
    { lat: 49.3062439, lng: 18.7232722 },
    { lat: 49.306003, lng: 18.7231925 },
    { lat: 49.3058647, lng: 18.7231791 },
    { lat: 49.3056815, lng: 18.7232131 },
    { lat: 49.3054468, lng: 18.7233206 },
    { lat: 49.3054086, lng: 18.7233447 },
    { lat: 49.3050829, lng: 18.7237983 },
    { lat: 49.3048905, lng: 18.7239563 },
    { lat: 49.3046021, lng: 18.7240707 },
    { lat: 49.3040754, lng: 18.7241557 },
    { lat: 49.3038213, lng: 18.7242309 },
    { lat: 49.3036342, lng: 18.7244088 },
    { lat: 49.3035558, lng: 18.7245037 },
    { lat: 49.3033891, lng: 18.7247228 },
    { lat: 49.3031736, lng: 18.7250675 },
    { lat: 49.3030009, lng: 18.7252794 },
    { lat: 49.3028786, lng: 18.725376 },
    { lat: 49.3028376, lng: 18.7253872 },
    { lat: 49.3027564, lng: 18.7253895 },
    { lat: 49.3025652, lng: 18.7253977 },
    { lat: 49.3020851, lng: 18.7254474 },
    { lat: 49.30137, lng: 18.7259391 },
    { lat: 49.3008663, lng: 18.7260752 },
    { lat: 49.3006972, lng: 18.7261403 },
    { lat: 49.300633, lng: 18.7261647 },
    { lat: 49.3003224, lng: 18.7262837 },
    { lat: 49.3003476, lng: 18.7264186 },
    { lat: 49.3003596, lng: 18.7265436 },
    { lat: 49.3001052, lng: 18.7269378 },
    { lat: 49.3000377, lng: 18.7269002 },
    { lat: 49.3001452, lng: 18.7275548 },
    { lat: 49.2998624, lng: 18.7280518 },
    { lat: 49.2994462, lng: 18.7287837 },
    { lat: 49.299285, lng: 18.7290668 },
    { lat: 49.298434, lng: 18.7296561 },
    { lat: 49.29829, lng: 18.7298362 },
    { lat: 49.2967328, lng: 18.7342804 },
    { lat: 49.2965628, lng: 18.7343922 },
    { lat: 49.2962923, lng: 18.7345693 },
    { lat: 49.2962616, lng: 18.7349308 },
    { lat: 49.2960505, lng: 18.735337 },
    { lat: 49.296077, lng: 18.7355407 },
    { lat: 49.295758, lng: 18.737876 },
    { lat: 49.2959433, lng: 18.7391995 },
    { lat: 49.2962377, lng: 18.740521 },
    { lat: 49.2961658, lng: 18.7410677 },
    { lat: 49.2961015, lng: 18.7415651 },
    { lat: 49.2962096, lng: 18.7421028 },
    { lat: 49.296301, lng: 18.7430303 },
    { lat: 49.2963401, lng: 18.7436946 },
    { lat: 49.2965077, lng: 18.743796 },
    { lat: 49.2967125, lng: 18.7439607 },
    { lat: 49.2970797, lng: 18.7442971 },
    { lat: 49.2977675, lng: 18.7449265 },
    { lat: 49.2979504, lng: 18.7450592 },
    { lat: 49.2980803, lng: 18.7451535 },
    { lat: 49.2987608, lng: 18.7457462 },
    { lat: 49.2988494, lng: 18.7458236 },
    { lat: 49.2989081, lng: 18.745867 },
    { lat: 49.2989316, lng: 18.7458855 },
    { lat: 49.2989925, lng: 18.7459308 },
    { lat: 49.2991329, lng: 18.7460033 },
    { lat: 49.2991455, lng: 18.7460101 },
    { lat: 49.2993138, lng: 18.7460788 },
    { lat: 49.2993121, lng: 18.7460927 },
    { lat: 49.2993043, lng: 18.7461562 },
    { lat: 49.2992964, lng: 18.7462211 },
    { lat: 49.2992933, lng: 18.7462475 },
    { lat: 49.2992943, lng: 18.7462897 },
    { lat: 49.2992964, lng: 18.7463393 },
    { lat: 49.299154, lng: 18.7463001 },
    { lat: 49.2991326, lng: 18.7464296 },
    { lat: 49.2993011, lng: 18.7466002 },
    { lat: 49.2993106, lng: 18.7467108 },
    { lat: 49.2994191, lng: 18.7468254 },
    { lat: 49.2995231, lng: 18.7469352 },
    { lat: 49.2996037, lng: 18.7468813 },
    { lat: 49.2997475, lng: 18.7467851 },
    { lat: 49.2999015, lng: 18.7469061 },
    { lat: 49.300108, lng: 18.7470367 },
    { lat: 49.3000627, lng: 18.7472738 },
    { lat: 49.3000468, lng: 18.7473588 },
    { lat: 49.3000341, lng: 18.7474196 },
    { lat: 49.3000228, lng: 18.7474828 },
    { lat: 49.3000059, lng: 18.7475676 },
    { lat: 49.3000052, lng: 18.747576 },
    { lat: 49.2999849, lng: 18.7476854 },
    { lat: 49.2999718, lng: 18.7477564 },
    { lat: 49.299956, lng: 18.7478884 },
    { lat: 49.2999205, lng: 18.7481995 },
    { lat: 49.2999136, lng: 18.7483705 },
    { lat: 49.2999106, lng: 18.7484453 },
    { lat: 49.2999081, lng: 18.7485247 },
    { lat: 49.299906, lng: 18.7485925 },
    { lat: 49.2999038, lng: 18.7486593 },
    { lat: 49.299901, lng: 18.7487149 },
    { lat: 49.2998996, lng: 18.7487505 },
    { lat: 49.299982, lng: 18.7493164 },
    { lat: 49.3001055, lng: 18.7501497 },
    { lat: 49.3001997, lng: 18.7506166 },
    { lat: 49.3003999, lng: 18.7508899 },
    { lat: 49.3005299, lng: 18.7510042 },
    { lat: 49.3007036, lng: 18.7510686 },
    { lat: 49.3007939, lng: 18.7511137 },
    { lat: 49.3009333, lng: 18.7512586 },
    { lat: 49.3010042, lng: 18.7514956 },
    { lat: 49.3010398, lng: 18.7518291 },
    { lat: 49.3015836, lng: 18.7524219 },
    { lat: 49.3016354, lng: 18.7524857 },
    { lat: 49.3017483, lng: 18.7525533 },
    { lat: 49.3018503, lng: 18.7525839 },
    { lat: 49.3020694, lng: 18.7525482 },
    { lat: 49.3021333, lng: 18.7527771 },
    { lat: 49.3022372, lng: 18.7531077 },
    { lat: 49.3022711, lng: 18.7531292 },
    { lat: 49.3023551, lng: 18.7531844 },
    { lat: 49.3023795, lng: 18.7531998 },
    { lat: 49.3024147, lng: 18.753223 },
    { lat: 49.302457, lng: 18.7532778 },
    { lat: 49.3024901, lng: 18.7533963 },
    { lat: 49.3024957, lng: 18.7534162 },
    { lat: 49.3025659, lng: 18.7535543 },
    { lat: 49.3026177, lng: 18.7536586 },
    { lat: 49.3026052, lng: 18.7537465 },
    { lat: 49.3026356, lng: 18.7538078 },
    { lat: 49.3026483, lng: 18.753833 },
    { lat: 49.3026607, lng: 18.7538583 },
    { lat: 49.3026936, lng: 18.7539229 },
    { lat: 49.3029424, lng: 18.7544176 },
    { lat: 49.3031786, lng: 18.7547748 },
    { lat: 49.3032398, lng: 18.7548667 },
    { lat: 49.303458, lng: 18.75529 },
    { lat: 49.3036208, lng: 18.755668 },
    { lat: 49.3037253, lng: 18.7558952 },
    { lat: 49.303827, lng: 18.7561163 },
    { lat: 49.3038881, lng: 18.7562498 },
    { lat: 49.3039593, lng: 18.7564062 },
    { lat: 49.3040266, lng: 18.7565509 },
    { lat: 49.3040965, lng: 18.7567032 },
    { lat: 49.3041501, lng: 18.7568213 },
    { lat: 49.3041956, lng: 18.7569191 },
    { lat: 49.304202, lng: 18.7569335 },
    { lat: 49.3042351, lng: 18.7570068 },
    { lat: 49.3043404, lng: 18.7568408 },
    { lat: 49.3044359, lng: 18.7567884 },
    { lat: 49.3046049, lng: 18.756611 },
    { lat: 49.3046784, lng: 18.7563126 },
    { lat: 49.3047212, lng: 18.7561629 },
    { lat: 49.3047232, lng: 18.7561568 },
    { lat: 49.3047747, lng: 18.7561686 },
    { lat: 49.3049778, lng: 18.7559137 },
    { lat: 49.3050378, lng: 18.7558317 },
    { lat: 49.304959, lng: 18.7556617 },
    { lat: 49.3049765, lng: 18.7555833 },
    { lat: 49.3050048, lng: 18.7555639 },
    { lat: 49.3051776, lng: 18.7555704 },
    { lat: 49.305259, lng: 18.7555955 },
    { lat: 49.3053494, lng: 18.7555809 },
    { lat: 49.3053291, lng: 18.755535 },
    { lat: 49.3054031, lng: 18.7553778 },
    { lat: 49.305512, lng: 18.755087 },
    { lat: 49.3056711, lng: 18.7547428 },
    { lat: 49.3057482, lng: 18.7545766 },
    { lat: 49.305921, lng: 18.7547771 },
    { lat: 49.3059681, lng: 18.7547522 },
    { lat: 49.3060978, lng: 18.754684 },
    { lat: 49.3062588, lng: 18.7547439 },
    { lat: 49.3065539, lng: 18.7547622 },
    { lat: 49.3068143, lng: 18.7547797 },
    { lat: 49.3069013, lng: 18.7547853 },
    { lat: 49.3070058, lng: 18.7548409 },
    { lat: 49.3073569, lng: 18.7546135 },
    { lat: 49.3074114, lng: 18.7546224 },
    { lat: 49.3075504, lng: 18.7546467 },
    { lat: 49.3076971, lng: 18.7546722 },
    { lat: 49.3077976, lng: 18.7546289 },
    { lat: 49.3078624, lng: 18.7546203 },
    { lat: 49.3080426, lng: 18.7546595 },
    { lat: 49.3081494, lng: 18.7546504 },
    { lat: 49.3082771, lng: 18.7546172 },
    { lat: 49.308519, lng: 18.7546477 },
    { lat: 49.3086538, lng: 18.7546816 },
    { lat: 49.3087273, lng: 18.7546643 },
    { lat: 49.3088086, lng: 18.7546013 },
    { lat: 49.3088852, lng: 18.7546194 },
    { lat: 49.3090921, lng: 18.7547406 },
    { lat: 49.3092056, lng: 18.7547333 },
    { lat: 49.3092532, lng: 18.7547358 },
    { lat: 49.3092923, lng: 18.7547972 },
    { lat: 49.3093375, lng: 18.7549077 },
    { lat: 49.3094493, lng: 18.7550805 },
    { lat: 49.3094952, lng: 18.7552484 },
    { lat: 49.3095321, lng: 18.7552631 },
    { lat: 49.3095682, lng: 18.7553317 },
    { lat: 49.3096576, lng: 18.7553598 },
    { lat: 49.3098673, lng: 18.7551854 },
    { lat: 49.310088, lng: 18.7552847 },
    { lat: 49.3101012, lng: 18.7553666 },
    { lat: 49.3102038, lng: 18.7554986 },
    { lat: 49.3103501, lng: 18.7555133 },
    { lat: 49.3104912, lng: 18.755473 },
    { lat: 49.3105542, lng: 18.7554719 },
    { lat: 49.3106675, lng: 18.7555242 },
    { lat: 49.310836, lng: 18.755666 },
    { lat: 49.3109251, lng: 18.7556848 },
    { lat: 49.311074, lng: 18.7556218 },
    { lat: 49.3112703, lng: 18.7556478 },
    { lat: 49.3114111, lng: 18.7557279 },
    { lat: 49.3116364, lng: 18.755703 },
    { lat: 49.3118442, lng: 18.7556235 },
    { lat: 49.312031, lng: 18.7555498 },
    { lat: 49.3121701, lng: 18.7554707 },
    { lat: 49.3122624, lng: 18.7555108 },
    { lat: 49.3123136, lng: 18.7555863 },
    { lat: 49.3124374, lng: 18.7556034 },
    { lat: 49.3126069, lng: 18.7555882 },
    { lat: 49.3126906, lng: 18.7555534 },
    { lat: 49.3127436, lng: 18.7554855 },
    { lat: 49.3127848, lng: 18.7554548 },
    { lat: 49.312835, lng: 18.7554672 },
    { lat: 49.3129131, lng: 18.755492 },
    { lat: 49.3129488, lng: 18.7554826 },
    { lat: 49.3129965, lng: 18.7554291 },
    { lat: 49.313043, lng: 18.7553464 },
    { lat: 49.3131153, lng: 18.7553275 },
    { lat: 49.3132052, lng: 18.7553838 },
    { lat: 49.3132326, lng: 18.7554788 },
    { lat: 49.3133828, lng: 18.7556546 },
    { lat: 49.3134484, lng: 18.7556177 },
    { lat: 49.3136662, lng: 18.755426 },
    { lat: 49.3137066, lng: 18.7555519 },
    { lat: 49.3137088, lng: 18.7556362 },
    { lat: 49.3137033, lng: 18.7556835 },
    { lat: 49.313729, lng: 18.7557422 },
    { lat: 49.3138251, lng: 18.7557915 },
    { lat: 49.3138457, lng: 18.7557558 },
    { lat: 49.3138903, lng: 18.7557457 },
    { lat: 49.3139585, lng: 18.7557348 },
    { lat: 49.3140462, lng: 18.755795 },
    { lat: 49.3141285, lng: 18.7558587 },
    { lat: 49.3142625, lng: 18.7559187 },
    { lat: 49.314358, lng: 18.7559338 },
    { lat: 49.3144083, lng: 18.7560151 },
    { lat: 49.3145525, lng: 18.756098 },
    { lat: 49.3146055, lng: 18.7561212 },
    { lat: 49.3147198, lng: 18.7561713 },
    { lat: 49.3148213, lng: 18.7562771 },
    { lat: 49.3148987, lng: 18.7563176 },
    { lat: 49.3150322, lng: 18.7563675 },
    { lat: 49.3152009, lng: 18.7563169 },
    { lat: 49.3153559, lng: 18.7563892 },
    { lat: 49.3154478, lng: 18.756425 },
    { lat: 49.3155216, lng: 18.7564308 },
    { lat: 49.3157503, lng: 18.7564485 },
    { lat: 49.3157535, lng: 18.7564481 },
    { lat: 49.3158041, lng: 18.7564434 },
    { lat: 49.3158462, lng: 18.7565803 },
    { lat: 49.3158468, lng: 18.7566782 },
    { lat: 49.3158608, lng: 18.756756 },
    { lat: 49.3158861, lng: 18.7568071 },
    { lat: 49.3160028, lng: 18.7567969 },
    { lat: 49.3161124, lng: 18.756802 },
    { lat: 49.3161531, lng: 18.7568018 },
    { lat: 49.316193, lng: 18.7568746 },
    { lat: 49.3162481, lng: 18.7568932 },
    { lat: 49.3162788, lng: 18.7570076 },
    { lat: 49.3163511, lng: 18.7571608 },
    { lat: 49.316421, lng: 18.7572292 },
    { lat: 49.3164844, lng: 18.757253 },
    { lat: 49.3165256, lng: 18.7572866 },
    { lat: 49.3165835, lng: 18.7572724 },
    { lat: 49.3166713, lng: 18.7572082 },
    { lat: 49.316742, lng: 18.7571363 },
    { lat: 49.3168156, lng: 18.757161 },
    { lat: 49.3168367, lng: 18.7572437 },
    { lat: 49.3169042, lng: 18.7574567 },
    { lat: 49.3170786, lng: 18.7577293 },
    { lat: 49.3171443, lng: 18.7576711 },
    { lat: 49.3172198, lng: 18.7576052 },
    { lat: 49.3173397, lng: 18.7576798 },
    { lat: 49.3174015, lng: 18.7577601 },
    { lat: 49.317429, lng: 18.7578974 },
    { lat: 49.3175556, lng: 18.7579684 },
    { lat: 49.3177215, lng: 18.7581011 },
    { lat: 49.3178105, lng: 18.7581448 },
    { lat: 49.3178889, lng: 18.7581588 },
    { lat: 49.3179084, lng: 18.758143 },
    { lat: 49.3179355, lng: 18.7580783 },
    { lat: 49.3180068, lng: 18.7580588 },
    { lat: 49.3180906, lng: 18.758221 },
    { lat: 49.3181253, lng: 18.758301 },
    { lat: 49.3181717, lng: 18.7583261 },
    { lat: 49.3182516, lng: 18.7581504 },
    { lat: 49.318427, lng: 18.7582686 },
    { lat: 49.3185268, lng: 18.7582572 },
    { lat: 49.3186079, lng: 18.7582351 },
    { lat: 49.3187078, lng: 18.7582934 },
    { lat: 49.3190221, lng: 18.7585671 },
    { lat: 49.3192806, lng: 18.7586639 },
    { lat: 49.3193368, lng: 18.7588761 },
    { lat: 49.3193722, lng: 18.7589943 },
    { lat: 49.3194387, lng: 18.7590549 },
    { lat: 49.3195344, lng: 18.759142 },
    { lat: 49.3195835, lng: 18.7593261 },
    { lat: 49.319632, lng: 18.7594315 },
    { lat: 49.3197222, lng: 18.7594545 },
    { lat: 49.3198103, lng: 18.7594171 },
    { lat: 49.3199204, lng: 18.759386 },
    { lat: 49.3199621, lng: 18.7594286 },
    { lat: 49.3202868, lng: 18.7597332 },
    { lat: 49.3204131, lng: 18.7599047 },
    { lat: 49.3205167, lng: 18.7600342 },
    { lat: 49.3205845, lng: 18.760142 },
    { lat: 49.3206503, lng: 18.7603266 },
    { lat: 49.3207502, lng: 18.7605115 },
    { lat: 49.3208241, lng: 18.7605661 },
    { lat: 49.3208351, lng: 18.7605824 },
    { lat: 49.3208976, lng: 18.7606789 },
    { lat: 49.3210813, lng: 18.7607011 },
    { lat: 49.3213022, lng: 18.7607407 },
    { lat: 49.3214177, lng: 18.7607722 },
    { lat: 49.3215448, lng: 18.7608305 },
    { lat: 49.3216503, lng: 18.7608234 },
    { lat: 49.3218565, lng: 18.7607318 },
    { lat: 49.3219331, lng: 18.7607554 },
    { lat: 49.3219899, lng: 18.7607839 },
    { lat: 49.3221211, lng: 18.7608573 },
    { lat: 49.3222727, lng: 18.7609288 },
    { lat: 49.3223897, lng: 18.7610126 },
    { lat: 49.3225087, lng: 18.7609836 },
    { lat: 49.3226329, lng: 18.7609635 },
    { lat: 49.3227446, lng: 18.7608758 },
    { lat: 49.3228866, lng: 18.7607887 },
    { lat: 49.3229865, lng: 18.7608015 },
    { lat: 49.3230554, lng: 18.7607415 },
    { lat: 49.3231418, lng: 18.7607534 },
    { lat: 49.3232042, lng: 18.7607573 },
    { lat: 49.3232616, lng: 18.7607355 },
    { lat: 49.3233166, lng: 18.760752 },
    { lat: 49.323346, lng: 18.7608399 },
    { lat: 49.3235327, lng: 18.760982 },
    { lat: 49.3235708, lng: 18.7610428 },
    { lat: 49.3236991, lng: 18.7610203 },
    { lat: 49.3239886, lng: 18.7609706 },
    { lat: 49.324077, lng: 18.7609574 },
    { lat: 49.3241982, lng: 18.7609668 },
    { lat: 49.3244262, lng: 18.7610342 },
    { lat: 49.324644, lng: 18.7610377 },
    { lat: 49.3248621, lng: 18.7609425 },
    { lat: 49.3249802, lng: 18.7609328 },
    { lat: 49.3250837, lng: 18.7609752 },
    { lat: 49.3252196, lng: 18.7609655 },
    { lat: 49.3254599, lng: 18.7610758 },
    { lat: 49.3256195, lng: 18.7611735 },
    { lat: 49.325773, lng: 18.7611538 },
    { lat: 49.3259612, lng: 18.761197 },
    { lat: 49.3259265, lng: 18.7612436 },
    { lat: 49.3262594, lng: 18.7618335 },
    { lat: 49.3265053, lng: 18.7622501 },
    { lat: 49.3265045, lng: 18.7622577 },
    { lat: 49.3267737, lng: 18.7626642 },
    { lat: 49.327097, lng: 18.76322 },
    { lat: 49.3271272, lng: 18.7632604 },
    { lat: 49.3271599, lng: 18.7633034 },
    { lat: 49.3274187, lng: 18.7637516 },
    { lat: 49.3276148, lng: 18.764073 },
    { lat: 49.3278174, lng: 18.7643723 },
    { lat: 49.3279158, lng: 18.7645697 },
    { lat: 49.3280531, lng: 18.7649561 },
    { lat: 49.3281545, lng: 18.7650214 },
    { lat: 49.3283902, lng: 18.7646324 },
    { lat: 49.328755, lng: 18.7644911 },
    { lat: 49.3296067, lng: 18.7643887 },
    { lat: 49.3297947, lng: 18.7644472 },
    { lat: 49.3300976, lng: 18.7644841 },
    { lat: 49.3310143, lng: 18.7648108 },
    { lat: 49.3312017, lng: 18.7648553 },
    { lat: 49.331698, lng: 18.7651457 },
    { lat: 49.3320003, lng: 18.7651707 },
    { lat: 49.3320932, lng: 18.7652426 },
    { lat: 49.33232, lng: 18.765634 },
    { lat: 49.3327325, lng: 18.7650459 },
    { lat: 49.3330719, lng: 18.7654812 },
    { lat: 49.3332281, lng: 18.7656853 },
    { lat: 49.3334056, lng: 18.7658466 },
    { lat: 49.3338283, lng: 18.7669349 },
    { lat: 49.3339814, lng: 18.7671889 },
    { lat: 49.3342234, lng: 18.7669631 },
    { lat: 49.334333, lng: 18.766816 },
    { lat: 49.3347031, lng: 18.7663217 },
    { lat: 49.3349804, lng: 18.7658194 },
    { lat: 49.3353236, lng: 18.7651981 },
    { lat: 49.3363087, lng: 18.7634119 },
    { lat: 49.3362814, lng: 18.7633819 },
    { lat: 49.3362902, lng: 18.7633602 },
    { lat: 49.3366454, lng: 18.7633391 },
    { lat: 49.336727, lng: 18.7633227 },
    { lat: 49.336967, lng: 18.7632747 },
    { lat: 49.3370396, lng: 18.7632865 },
    { lat: 49.3372193, lng: 18.7633156 },
    { lat: 49.3376395, lng: 18.7638036 },
    { lat: 49.3377487, lng: 18.7641049 },
    { lat: 49.3378357, lng: 18.7643471 },
    { lat: 49.3380212, lng: 18.7645982 },
    { lat: 49.3384374, lng: 18.7645375 },
    { lat: 49.3385916, lng: 18.7644895 },
    { lat: 49.3386836, lng: 18.764437 },
    { lat: 49.3393862, lng: 18.7628109 },
    { lat: 49.3395434, lng: 18.7624472 },
    { lat: 49.33956, lng: 18.7625775 },
    { lat: 49.3397319, lng: 18.7627565 },
    { lat: 49.3400545, lng: 18.7628681 },
    { lat: 49.3402097, lng: 18.7629899 },
    { lat: 49.3402616, lng: 18.763057 },
    { lat: 49.3404759, lng: 18.7630389 },
    { lat: 49.3408426, lng: 18.7629335 },
    { lat: 49.3409834, lng: 18.7629099 },
    { lat: 49.3410619, lng: 18.7629018 },
    { lat: 49.3412054, lng: 18.7630579 },
    { lat: 49.341572, lng: 18.7630838 },
    { lat: 49.3418836, lng: 18.7629147 },
    { lat: 49.3419282, lng: 18.7629266 },
    { lat: 49.342087, lng: 18.7629684 },
    { lat: 49.342538, lng: 18.7631862 },
    { lat: 49.3427168, lng: 18.7632397 },
    { lat: 49.3427945, lng: 18.763262 },
    { lat: 49.343341, lng: 18.76332 },
    { lat: 49.3433836, lng: 18.7628165 },
    { lat: 49.34366, lng: 18.7620383 },
    { lat: 49.344228, lng: 18.7605089 },
    { lat: 49.3445801, lng: 18.7599511 },
    { lat: 49.344694, lng: 18.7593467 },
    { lat: 49.3449848, lng: 18.7587349 },
    { lat: 49.3451572, lng: 18.7585154 },
    { lat: 49.3459128, lng: 18.7593195 },
    { lat: 49.3464533, lng: 18.7593091 },
    { lat: 49.3467108, lng: 18.7594039 },
    { lat: 49.3467568, lng: 18.7594211 },
    { lat: 49.3471577, lng: 18.7594335 },
    { lat: 49.3475916, lng: 18.7594479 },
    { lat: 49.3475916, lng: 18.7597531 },
    { lat: 49.3476715, lng: 18.7599456 },
    { lat: 49.34785, lng: 18.7600613 },
    { lat: 49.3479293, lng: 18.7602187 },
    { lat: 49.348053, lng: 18.7610796 },
    { lat: 49.3480576, lng: 18.7617785 },
    { lat: 49.3483345, lng: 18.7617918 },
    { lat: 49.348568, lng: 18.7614882 },
    { lat: 49.3486221, lng: 18.7614888 },
    { lat: 49.3487278, lng: 18.7615266 },
    { lat: 49.3491779, lng: 18.7618584 },
    { lat: 49.3493041, lng: 18.7619856 },
    { lat: 49.3493812, lng: 18.761969 },
    { lat: 49.3494151, lng: 18.7619938 },
    { lat: 49.3495728, lng: 18.7624914 },
    { lat: 49.349664, lng: 18.7625733 },
    { lat: 49.3502037, lng: 18.7625253 },
    { lat: 49.3503468, lng: 18.762501 },
    { lat: 49.3504613, lng: 18.7624904 },
    { lat: 49.350779, lng: 18.7625298 },
    { lat: 49.3511899, lng: 18.7627113 },
    { lat: 49.3512548, lng: 18.76266 },
    { lat: 49.3512799, lng: 18.7625148 },
    { lat: 49.3513494, lng: 18.762047 },
    { lat: 49.3513915, lng: 18.7618155 },
    { lat: 49.3516284, lng: 18.7611458 },
    { lat: 49.3517416, lng: 18.7603956 },
    { lat: 49.3518713, lng: 18.7594847 },
    { lat: 49.3519056, lng: 18.7591271 },
    { lat: 49.3518938, lng: 18.7587445 },
    { lat: 49.3519111, lng: 18.7586234 },
    { lat: 49.3519422, lng: 18.7585038 },
    { lat: 49.3521558, lng: 18.7576894 },
    { lat: 49.3522264, lng: 18.7574196 },
    { lat: 49.3523914, lng: 18.7573388 },
    { lat: 49.3523923, lng: 18.757338 },
    { lat: 49.3528948, lng: 18.7570919 },
    { lat: 49.3532565, lng: 18.7569141 },
    { lat: 49.3534643, lng: 18.7568124 },
    { lat: 49.3538392, lng: 18.7565834 },
    { lat: 49.3539507, lng: 18.7565148 },
    { lat: 49.3541408, lng: 18.7563984 },
    { lat: 49.3542433, lng: 18.7563302 },
    { lat: 49.3542685, lng: 18.7563137 },
    { lat: 49.3546216, lng: 18.7560794 },
    { lat: 49.3547239, lng: 18.7560112 },
    { lat: 49.3547059, lng: 18.7548386 },
    { lat: 49.3547021, lng: 18.7545519 },
    { lat: 49.3546415, lng: 18.7531906 },
    { lat: 49.35459, lng: 18.7528901 },
    { lat: 49.3544137, lng: 18.7499775 },
    { lat: 49.3545246, lng: 18.748881 },
    { lat: 49.3545923, lng: 18.7482571 },
    { lat: 49.3546678, lng: 18.7475187 },
    { lat: 49.3547598, lng: 18.7462722 },
    { lat: 49.3547815, lng: 18.7459777 },
    { lat: 49.3547236, lng: 18.7458582 },
    { lat: 49.3543621, lng: 18.7451101 },
    { lat: 49.3546717, lng: 18.7445164 },
    { lat: 49.3549923, lng: 18.7443529 },
    { lat: 49.3552302, lng: 18.7447203 },
    { lat: 49.3554453, lng: 18.7447345 },
    { lat: 49.3556955, lng: 18.7435874 },
    { lat: 49.3562252, lng: 18.743777 },
    { lat: 49.3562878, lng: 18.7435498 },
    { lat: 49.3561895, lng: 18.7428734 },
    { lat: 49.3563206, lng: 18.7411498 },
    { lat: 49.356147, lng: 18.7398496 },
    { lat: 49.3560825, lng: 18.7389604 },
    { lat: 49.3560766, lng: 18.7386938 },
    { lat: 49.3568733, lng: 18.7355699 },
    { lat: 49.3570332, lng: 18.7357587 },
    { lat: 49.3572742, lng: 18.7363608 },
    { lat: 49.3576959, lng: 18.7374202 },
    { lat: 49.3581914, lng: 18.7382285 },
    { lat: 49.3582792, lng: 18.7386609 },
    { lat: 49.3583425, lng: 18.7384485 },
    { lat: 49.3586105, lng: 18.7372704 },
    { lat: 49.3587475, lng: 18.7370435 },
    { lat: 49.3589925, lng: 18.7366388 },
    { lat: 49.3592518, lng: 18.7366176 },
    { lat: 49.3597289, lng: 18.7365785 },
    { lat: 49.3597908, lng: 18.7365735 },
    { lat: 49.3607733, lng: 18.7364925 },
    { lat: 49.3608114, lng: 18.7364896 },
    { lat: 49.3609808, lng: 18.7364758 },
    { lat: 49.3615142, lng: 18.7364319 },
    { lat: 49.3621446, lng: 18.7377524 },
    { lat: 49.3626155, lng: 18.7387389 },
    { lat: 49.3629229, lng: 18.7387759 },
    { lat: 49.363218, lng: 18.738704 },
    { lat: 49.3640272, lng: 18.7401026 },
    { lat: 49.3639653, lng: 18.7406705 },
    { lat: 49.3647946, lng: 18.7421292 },
    { lat: 49.3645696, lng: 18.7429414 },
    { lat: 49.3661483, lng: 18.7432646 },
    { lat: 49.3671612, lng: 18.7434228 },
    { lat: 49.3673556, lng: 18.7438028 },
    { lat: 49.3673882, lng: 18.7438672 },
    { lat: 49.3674148, lng: 18.74392 },
    { lat: 49.3674159, lng: 18.7439224 },
    { lat: 49.3676369, lng: 18.7443553 },
    { lat: 49.3678447, lng: 18.744098 },
    { lat: 49.3683386, lng: 18.7437555 },
    { lat: 49.3685605, lng: 18.7433843 },
    { lat: 49.368151, lng: 18.7426622 },
    { lat: 49.3679908, lng: 18.7424893 },
    { lat: 49.3679335, lng: 18.7424279 },
    { lat: 49.3679253, lng: 18.7424195 },
    { lat: 49.3678822, lng: 18.7423723 },
    { lat: 49.3678452, lng: 18.7423331 },
    { lat: 49.3678201, lng: 18.7423061 },
    { lat: 49.3674689, lng: 18.7419264 },
    { lat: 49.3670612, lng: 18.7414389 },
    { lat: 49.3667631, lng: 18.740544 },
    { lat: 49.3665576, lng: 18.7401495 },
    { lat: 49.3666048, lng: 18.7399313 },
    { lat: 49.3666613, lng: 18.7397149 },
    { lat: 49.3667153, lng: 18.7394301 },
    { lat: 49.3669192, lng: 18.7394077 },
    { lat: 49.3672956, lng: 18.7387322 },
    { lat: 49.3678393, lng: 18.7384556 },
    { lat: 49.3678579, lng: 18.7384461 },
    { lat: 49.3678821, lng: 18.7384337 },
    { lat: 49.3678751, lng: 18.7377789 },
    { lat: 49.3678276, lng: 18.7375608 },
    { lat: 49.3674682, lng: 18.736291 },
    { lat: 49.3674495, lng: 18.7362546 },
    { lat: 49.3673803, lng: 18.7361187 },
    { lat: 49.3673761, lng: 18.7357983 },
    { lat: 49.3674736, lng: 18.7353345 },
    { lat: 49.3676196, lng: 18.734868 },
    { lat: 49.3678936, lng: 18.734888 },
    { lat: 49.3684877, lng: 18.7343457 },
    { lat: 49.368626, lng: 18.7341884 },
    { lat: 49.3687144, lng: 18.7341317 },
    { lat: 49.3689565, lng: 18.7339751 },
    { lat: 49.3691362, lng: 18.733918 },
    { lat: 49.3691529, lng: 18.7339127 },
    { lat: 49.3691894, lng: 18.733901 },
    { lat: 49.3692206, lng: 18.7338914 },
    { lat: 49.369414, lng: 18.7338182 },
    { lat: 49.3697855, lng: 18.7335875 },
    { lat: 49.3700193, lng: 18.7335093 },
    { lat: 49.3701918, lng: 18.7334853 },
    { lat: 49.3703011, lng: 18.7334897 },
    { lat: 49.3705751, lng: 18.7336823 },
    { lat: 49.3706513, lng: 18.7336064 },
    { lat: 49.3707269, lng: 18.7332853 },
    { lat: 49.3706749, lng: 18.7329353 },
    { lat: 49.370673, lng: 18.7329232 },
    { lat: 49.3706965, lng: 18.7326573 },
    { lat: 49.3706978, lng: 18.7326408 },
    { lat: 49.3707541, lng: 18.7326158 },
    { lat: 49.3707749, lng: 18.7326068 },
    { lat: 49.3707633, lng: 18.7324888 },
    { lat: 49.3707735, lng: 18.7324474 },
    { lat: 49.3708288, lng: 18.7323169 },
    { lat: 49.370861, lng: 18.7322 },
    { lat: 49.370755, lng: 18.731976 },
    { lat: 49.369947, lng: 18.732057 },
    { lat: 49.369021, lng: 18.731905 },
    { lat: 49.3684, lng: 18.732099 },
    { lat: 49.367831, lng: 18.732103 },
    { lat: 49.367506, lng: 18.732011 },
    { lat: 49.366383, lng: 18.73193 },
    { lat: 49.366204, lng: 18.730857 },
    { lat: 49.366039, lng: 18.729477 },
    { lat: 49.365919, lng: 18.726175 },
    { lat: 49.365748, lng: 18.724241 },
    { lat: 49.365944, lng: 18.723489 },
    { lat: 49.366249, lng: 18.722721 },
    { lat: 49.366807, lng: 18.721653 },
    { lat: 49.367434, lng: 18.721258 },
    { lat: 49.367923, lng: 18.720817 },
    { lat: 49.368253, lng: 18.720518 },
    { lat: 49.368743, lng: 18.719906 },
    { lat: 49.369534, lng: 18.717801 },
    { lat: 49.369925, lng: 18.716455 },
    { lat: 49.370174, lng: 18.71583 },
    { lat: 49.370247, lng: 18.71464 },
    { lat: 49.370074, lng: 18.712999 },
    { lat: 49.370071, lng: 18.712119 },
    { lat: 49.37016, lng: 18.711697 },
    { lat: 49.370175, lng: 18.710855 },
    { lat: 49.370157, lng: 18.709974 },
    { lat: 49.370066, lng: 18.708821 },
    { lat: 49.369793, lng: 18.708139 },
    { lat: 49.369359, lng: 18.706709 },
    { lat: 49.368203, lng: 18.705356 },
    { lat: 49.368066, lng: 18.705014 },
    { lat: 49.367837, lng: 18.704731 },
    { lat: 49.367722, lng: 18.704466 },
    { lat: 49.367551, lng: 18.704246 },
    { lat: 49.366396, lng: 18.703199 },
    { lat: 49.366064, lng: 18.703003 },
    { lat: 49.364752, lng: 18.702879 },
    { lat: 49.363826, lng: 18.702075 },
    { lat: 49.362849, lng: 18.700977 },
    { lat: 49.362525, lng: 18.699858 },
    { lat: 49.362111, lng: 18.698759 },
    { lat: 49.362007, lng: 18.698371 },
    { lat: 49.361159, lng: 18.696865 },
    { lat: 49.3610737, lng: 18.6966616 },
    { lat: 49.360885, lng: 18.696212 },
    { lat: 49.360683, lng: 18.695492 },
    { lat: 49.360534, lng: 18.694374 },
    { lat: 49.360453, lng: 18.694117 },
    { lat: 49.36007, lng: 18.693481 },
    { lat: 49.359846, lng: 18.692913 },
    { lat: 49.360512, lng: 18.692085 },
    { lat: 49.36104, lng: 18.691255 },
    { lat: 49.361338, lng: 18.690212 },
    { lat: 49.361236, lng: 18.690181 },
    { lat: 49.361334, lng: 18.689428 },
    { lat: 49.361322, lng: 18.688761 },
    { lat: 49.36144, lng: 18.687557 },
    { lat: 49.361433, lng: 18.68728 },
    { lat: 49.36137, lng: 18.687032 },
    { lat: 49.361396, lng: 18.686669 },
    { lat: 49.361459, lng: 18.686439 },
    { lat: 49.361446, lng: 18.685284 },
    { lat: 49.361171, lng: 18.683248 },
    { lat: 49.361004, lng: 18.682436 },
    { lat: 49.360836, lng: 18.681986 },
    { lat: 49.36065, lng: 18.68092 },
    { lat: 49.360513, lng: 18.68043 },
    { lat: 49.360242, lng: 18.679863 },
    { lat: 49.359823, lng: 18.679237 },
    { lat: 49.35897, lng: 18.678307 },
    { lat: 49.358669, lng: 18.678297 },
    { lat: 49.358467, lng: 18.678111 },
    { lat: 49.358182, lng: 18.678005 },
    { lat: 49.357926, lng: 18.677996 },
    { lat: 49.357821, lng: 18.678082 },
    { lat: 49.357777, lng: 18.678198 },
    { lat: 49.357692, lng: 18.678139 },
    { lat: 49.357136, lng: 18.678093 },
    { lat: 49.356995, lng: 18.677981 },
    { lat: 49.356761, lng: 18.677635 },
    { lat: 49.356379, lng: 18.676887 },
    { lat: 49.35619, lng: 18.676226 },
  ],
  Ochodnica: [
    { lat: 49.380726, lng: 18.774706 },
    { lat: 49.380906, lng: 18.774548 },
    { lat: 49.380974, lng: 18.773804 },
    { lat: 49.380981, lng: 18.772896 },
    { lat: 49.380921, lng: 18.7708 },
    { lat: 49.381135, lng: 18.769771 },
    { lat: 49.382221, lng: 18.765559 },
    { lat: 49.382275, lng: 18.765164 },
    { lat: 49.383218, lng: 18.762871 },
    { lat: 49.383271, lng: 18.762632 },
    { lat: 49.38332, lng: 18.762635 },
    { lat: 49.383488, lng: 18.762375 },
    { lat: 49.383757, lng: 18.762189 },
    { lat: 49.383856, lng: 18.761948 },
    { lat: 49.384125, lng: 18.760821 },
    { lat: 49.384784, lng: 18.76011 },
    { lat: 49.385252, lng: 18.75985 },
    { lat: 49.385433, lng: 18.75965 },
    { lat: 49.38608, lng: 18.75942 },
    { lat: 49.386355, lng: 18.759244 },
    { lat: 49.387033, lng: 18.757736 },
    { lat: 49.387045, lng: 18.756861 },
    { lat: 49.387168, lng: 18.754796 },
    { lat: 49.387254, lng: 18.754352 },
    { lat: 49.387573, lng: 18.753449 },
    { lat: 49.387745, lng: 18.752807 },
    { lat: 49.388073, lng: 18.752322 },
    { lat: 49.388367, lng: 18.751365 },
    { lat: 49.3885244, lng: 18.7507939 },
    { lat: 49.388652, lng: 18.749621 },
    { lat: 49.388664, lng: 18.74888 },
    { lat: 49.3885, lng: 18.748504 },
    { lat: 49.38841, lng: 18.747968 },
    { lat: 49.3882824, lng: 18.7476795 },
    { lat: 49.388172, lng: 18.74743 },
    { lat: 49.387464, lng: 18.746193 },
    { lat: 49.386936, lng: 18.745389 },
    { lat: 49.3863358, lng: 18.7446342 },
    { lat: 49.3861002, lng: 18.7443086 },
    { lat: 49.3860475, lng: 18.7442669 },
    { lat: 49.384917, lng: 18.744053 },
    { lat: 49.3846465, lng: 18.7438614 },
    { lat: 49.384211, lng: 18.743553 },
    { lat: 49.383482, lng: 18.74334 },
    { lat: 49.383378, lng: 18.7433588 },
    { lat: 49.381892, lng: 18.743627 },
    { lat: 49.381226, lng: 18.743348 },
    { lat: 49.380891, lng: 18.742913 },
    { lat: 49.380518, lng: 18.742352 },
    { lat: 49.380551, lng: 18.741778 },
    { lat: 49.3805359, lng: 18.741735 },
    { lat: 49.380408, lng: 18.741371 },
    { lat: 49.379987, lng: 18.739653 },
    { lat: 49.379123, lng: 18.737818 },
    { lat: 49.378182, lng: 18.735356 },
    { lat: 49.377372, lng: 18.733478 },
    { lat: 49.376503, lng: 18.732111 },
    { lat: 49.37508, lng: 18.73278 },
    { lat: 49.374155, lng: 18.732859 },
    { lat: 49.372561, lng: 18.732308 },
    { lat: 49.370861, lng: 18.7322 },
    { lat: 49.3708288, lng: 18.7323169 },
    { lat: 49.3707735, lng: 18.7324474 },
    { lat: 49.3707633, lng: 18.7324888 },
    { lat: 49.3707749, lng: 18.7326068 },
    { lat: 49.3707541, lng: 18.7326158 },
    { lat: 49.3706978, lng: 18.7326408 },
    { lat: 49.3706965, lng: 18.7326573 },
    { lat: 49.370673, lng: 18.7329232 },
    { lat: 49.3706749, lng: 18.7329353 },
    { lat: 49.3707269, lng: 18.7332853 },
    { lat: 49.3706513, lng: 18.7336064 },
    { lat: 49.3705751, lng: 18.7336823 },
    { lat: 49.3703011, lng: 18.7334897 },
    { lat: 49.3701918, lng: 18.7334853 },
    { lat: 49.3700193, lng: 18.7335093 },
    { lat: 49.3697855, lng: 18.7335875 },
    { lat: 49.369414, lng: 18.7338182 },
    { lat: 49.3692206, lng: 18.7338914 },
    { lat: 49.3691894, lng: 18.733901 },
    { lat: 49.3691529, lng: 18.7339127 },
    { lat: 49.3691362, lng: 18.733918 },
    { lat: 49.3689565, lng: 18.7339751 },
    { lat: 49.3687144, lng: 18.7341317 },
    { lat: 49.368626, lng: 18.7341884 },
    { lat: 49.3684877, lng: 18.7343457 },
    { lat: 49.3678936, lng: 18.734888 },
    { lat: 49.3676196, lng: 18.734868 },
    { lat: 49.3674736, lng: 18.7353345 },
    { lat: 49.3673761, lng: 18.7357983 },
    { lat: 49.3673803, lng: 18.7361187 },
    { lat: 49.3674495, lng: 18.7362546 },
    { lat: 49.3674682, lng: 18.736291 },
    { lat: 49.3678276, lng: 18.7375608 },
    { lat: 49.3678751, lng: 18.7377789 },
    { lat: 49.3678821, lng: 18.7384337 },
    { lat: 49.3678579, lng: 18.7384461 },
    { lat: 49.3678393, lng: 18.7384556 },
    { lat: 49.3672956, lng: 18.7387322 },
    { lat: 49.3669192, lng: 18.7394077 },
    { lat: 49.3667153, lng: 18.7394301 },
    { lat: 49.3666613, lng: 18.7397149 },
    { lat: 49.3666048, lng: 18.7399313 },
    { lat: 49.3665576, lng: 18.7401495 },
    { lat: 49.3667631, lng: 18.740544 },
    { lat: 49.3670612, lng: 18.7414389 },
    { lat: 49.3674689, lng: 18.7419264 },
    { lat: 49.3678201, lng: 18.7423061 },
    { lat: 49.3678452, lng: 18.7423331 },
    { lat: 49.3678822, lng: 18.7423723 },
    { lat: 49.3679253, lng: 18.7424195 },
    { lat: 49.3679335, lng: 18.7424279 },
    { lat: 49.3679908, lng: 18.7424893 },
    { lat: 49.368151, lng: 18.7426622 },
    { lat: 49.3685605, lng: 18.7433843 },
    { lat: 49.3683386, lng: 18.7437555 },
    { lat: 49.3678447, lng: 18.744098 },
    { lat: 49.3676369, lng: 18.7443553 },
    { lat: 49.3674159, lng: 18.7439224 },
    { lat: 49.3674148, lng: 18.74392 },
    { lat: 49.3673882, lng: 18.7438672 },
    { lat: 49.3673556, lng: 18.7438028 },
    { lat: 49.3671612, lng: 18.7434228 },
    { lat: 49.3661483, lng: 18.7432646 },
    { lat: 49.3645696, lng: 18.7429414 },
    { lat: 49.3647946, lng: 18.7421292 },
    { lat: 49.3639653, lng: 18.7406705 },
    { lat: 49.3640272, lng: 18.7401026 },
    { lat: 49.363218, lng: 18.738704 },
    { lat: 49.3629229, lng: 18.7387759 },
    { lat: 49.3626155, lng: 18.7387389 },
    { lat: 49.3621446, lng: 18.7377524 },
    { lat: 49.3615142, lng: 18.7364319 },
    { lat: 49.3609808, lng: 18.7364758 },
    { lat: 49.3608114, lng: 18.7364896 },
    { lat: 49.3607733, lng: 18.7364925 },
    { lat: 49.3597908, lng: 18.7365735 },
    { lat: 49.3597289, lng: 18.7365785 },
    { lat: 49.3592518, lng: 18.7366176 },
    { lat: 49.3589925, lng: 18.7366388 },
    { lat: 49.3587475, lng: 18.7370435 },
    { lat: 49.3586105, lng: 18.7372704 },
    { lat: 49.3583425, lng: 18.7384485 },
    { lat: 49.3582792, lng: 18.7386609 },
    { lat: 49.3581914, lng: 18.7382285 },
    { lat: 49.3576959, lng: 18.7374202 },
    { lat: 49.3572742, lng: 18.7363608 },
    { lat: 49.3570332, lng: 18.7357587 },
    { lat: 49.3568733, lng: 18.7355699 },
    { lat: 49.3560766, lng: 18.7386938 },
    { lat: 49.3560825, lng: 18.7389604 },
    { lat: 49.356147, lng: 18.7398496 },
    { lat: 49.3563206, lng: 18.7411498 },
    { lat: 49.3561895, lng: 18.7428734 },
    { lat: 49.3562878, lng: 18.7435498 },
    { lat: 49.3562252, lng: 18.743777 },
    { lat: 49.3556955, lng: 18.7435874 },
    { lat: 49.3554453, lng: 18.7447345 },
    { lat: 49.3552302, lng: 18.7447203 },
    { lat: 49.3549923, lng: 18.7443529 },
    { lat: 49.3546717, lng: 18.7445164 },
    { lat: 49.3543621, lng: 18.7451101 },
    { lat: 49.3547236, lng: 18.7458582 },
    { lat: 49.3547815, lng: 18.7459777 },
    { lat: 49.3547598, lng: 18.7462722 },
    { lat: 49.3546678, lng: 18.7475187 },
    { lat: 49.3545923, lng: 18.7482571 },
    { lat: 49.3545246, lng: 18.748881 },
    { lat: 49.3544137, lng: 18.7499775 },
    { lat: 49.35459, lng: 18.7528901 },
    { lat: 49.3546415, lng: 18.7531906 },
    { lat: 49.3547021, lng: 18.7545519 },
    { lat: 49.3547059, lng: 18.7548386 },
    { lat: 49.3547239, lng: 18.7560112 },
    { lat: 49.3546216, lng: 18.7560794 },
    { lat: 49.3542685, lng: 18.7563137 },
    { lat: 49.3542433, lng: 18.7563302 },
    { lat: 49.3541408, lng: 18.7563984 },
    { lat: 49.3539507, lng: 18.7565148 },
    { lat: 49.3538392, lng: 18.7565834 },
    { lat: 49.3534643, lng: 18.7568124 },
    { lat: 49.3532565, lng: 18.7569141 },
    { lat: 49.3528948, lng: 18.7570919 },
    { lat: 49.3523923, lng: 18.757338 },
    { lat: 49.3523914, lng: 18.7573388 },
    { lat: 49.3522264, lng: 18.7574196 },
    { lat: 49.3521558, lng: 18.7576894 },
    { lat: 49.3519422, lng: 18.7585038 },
    { lat: 49.3519111, lng: 18.7586234 },
    { lat: 49.3518938, lng: 18.7587445 },
    { lat: 49.3519056, lng: 18.7591271 },
    { lat: 49.3518713, lng: 18.7594847 },
    { lat: 49.3517416, lng: 18.7603956 },
    { lat: 49.3516284, lng: 18.7611458 },
    { lat: 49.3513915, lng: 18.7618155 },
    { lat: 49.3513494, lng: 18.762047 },
    { lat: 49.3512799, lng: 18.7625148 },
    { lat: 49.3512548, lng: 18.76266 },
    { lat: 49.3511899, lng: 18.7627113 },
    { lat: 49.350779, lng: 18.7625298 },
    { lat: 49.3504613, lng: 18.7624904 },
    { lat: 49.3503468, lng: 18.762501 },
    { lat: 49.3502037, lng: 18.7625253 },
    { lat: 49.349664, lng: 18.7625733 },
    { lat: 49.3495728, lng: 18.7624914 },
    { lat: 49.3494151, lng: 18.7619938 },
    { lat: 49.3493812, lng: 18.761969 },
    { lat: 49.3493041, lng: 18.7619856 },
    { lat: 49.3491779, lng: 18.7618584 },
    { lat: 49.3487278, lng: 18.7615266 },
    { lat: 49.3486221, lng: 18.7614888 },
    { lat: 49.348568, lng: 18.7614882 },
    { lat: 49.3483345, lng: 18.7617918 },
    { lat: 49.3480576, lng: 18.7617785 },
    { lat: 49.348053, lng: 18.7610796 },
    { lat: 49.3479293, lng: 18.7602187 },
    { lat: 49.34785, lng: 18.7600613 },
    { lat: 49.3476715, lng: 18.7599456 },
    { lat: 49.3475916, lng: 18.7597531 },
    { lat: 49.3475916, lng: 18.7594479 },
    { lat: 49.3471577, lng: 18.7594335 },
    { lat: 49.3467568, lng: 18.7594211 },
    { lat: 49.3467108, lng: 18.7594039 },
    { lat: 49.3464533, lng: 18.7593091 },
    { lat: 49.3459128, lng: 18.7593195 },
    { lat: 49.3451572, lng: 18.7585154 },
    { lat: 49.3449848, lng: 18.7587349 },
    { lat: 49.344694, lng: 18.7593467 },
    { lat: 49.3445801, lng: 18.7599511 },
    { lat: 49.344228, lng: 18.7605089 },
    { lat: 49.34366, lng: 18.7620383 },
    { lat: 49.3433836, lng: 18.7628165 },
    { lat: 49.343341, lng: 18.76332 },
    { lat: 49.3427945, lng: 18.763262 },
    { lat: 49.3427168, lng: 18.7632397 },
    { lat: 49.342538, lng: 18.7631862 },
    { lat: 49.342087, lng: 18.7629684 },
    { lat: 49.3419282, lng: 18.7629266 },
    { lat: 49.3418836, lng: 18.7629147 },
    { lat: 49.341572, lng: 18.7630838 },
    { lat: 49.3412054, lng: 18.7630579 },
    { lat: 49.3410619, lng: 18.7629018 },
    { lat: 49.3409834, lng: 18.7629099 },
    { lat: 49.3408426, lng: 18.7629335 },
    { lat: 49.3404759, lng: 18.7630389 },
    { lat: 49.3402616, lng: 18.763057 },
    { lat: 49.3402097, lng: 18.7629899 },
    { lat: 49.3400545, lng: 18.7628681 },
    { lat: 49.3397319, lng: 18.7627565 },
    { lat: 49.33956, lng: 18.7625775 },
    { lat: 49.3395434, lng: 18.7624472 },
    { lat: 49.3393862, lng: 18.7628109 },
    { lat: 49.3386836, lng: 18.764437 },
    { lat: 49.3385916, lng: 18.7644895 },
    { lat: 49.3384374, lng: 18.7645375 },
    { lat: 49.3380212, lng: 18.7645982 },
    { lat: 49.3378357, lng: 18.7643471 },
    { lat: 49.3377487, lng: 18.7641049 },
    { lat: 49.3376395, lng: 18.7638036 },
    { lat: 49.3372193, lng: 18.7633156 },
    { lat: 49.3370396, lng: 18.7632865 },
    { lat: 49.336967, lng: 18.7632747 },
    { lat: 49.336727, lng: 18.7633227 },
    { lat: 49.3366454, lng: 18.7633391 },
    { lat: 49.3362902, lng: 18.7633602 },
    { lat: 49.3362814, lng: 18.7633819 },
    { lat: 49.3363087, lng: 18.7634119 },
    { lat: 49.3353236, lng: 18.7651981 },
    { lat: 49.3349804, lng: 18.7658194 },
    { lat: 49.3347031, lng: 18.7663217 },
    { lat: 49.3350553, lng: 18.7671527 },
    { lat: 49.3355113, lng: 18.7681184 },
    { lat: 49.3358124, lng: 18.7689025 },
    { lat: 49.3359127, lng: 18.7695755 },
    { lat: 49.3358716, lng: 18.7700682 },
    { lat: 49.3360005, lng: 18.7709854 },
    { lat: 49.3364872, lng: 18.7727392 },
    { lat: 49.3368874, lng: 18.7740186 },
    { lat: 49.3370916, lng: 18.7745676 },
    { lat: 49.337204, lng: 18.7755823 },
    { lat: 49.33718, lng: 18.7760321 },
    { lat: 49.3372555, lng: 18.7766868 },
    { lat: 49.3372978, lng: 18.777156 },
    { lat: 49.3375923, lng: 18.7780513 },
    { lat: 49.3380593, lng: 18.7791221 },
    { lat: 49.3382368, lng: 18.779524 },
    { lat: 49.3384795, lng: 18.7799628 },
    { lat: 49.3388572, lng: 18.7804637 },
    { lat: 49.3390178, lng: 18.7807993 },
    { lat: 49.3393374, lng: 18.7813311 },
    { lat: 49.3394506, lng: 18.7817523 },
    { lat: 49.3395116, lng: 18.7823007 },
    { lat: 49.3396649, lng: 18.7829749 },
    { lat: 49.3397123, lng: 18.7831923 },
    { lat: 49.3397299, lng: 18.7832716 },
    { lat: 49.3397462, lng: 18.7833454 },
    { lat: 49.3397419, lng: 18.7833539 },
    { lat: 49.3397177, lng: 18.7834075 },
    { lat: 49.3397757, lng: 18.7842216 },
    { lat: 49.339884, lng: 18.7848774 },
    { lat: 49.3399267, lng: 18.7852678 },
    { lat: 49.3399314, lng: 18.7855355 },
    { lat: 49.3399389, lng: 18.7859613 },
    { lat: 49.3399986, lng: 18.7866203 },
    { lat: 49.3400254, lng: 18.7869159 },
    { lat: 49.340162, lng: 18.7874648 },
    { lat: 49.3402074, lng: 18.7876441 },
    { lat: 49.340258, lng: 18.78786 },
    { lat: 49.3403269, lng: 18.7890597 },
    { lat: 49.3402989, lng: 18.7891079 },
    { lat: 49.3402574, lng: 18.790334 },
    { lat: 49.3398272, lng: 18.7902748 },
    { lat: 49.3397856, lng: 18.7907354 },
    { lat: 49.3397812, lng: 18.7907844 },
    { lat: 49.3397603, lng: 18.7910111 },
    { lat: 49.3394641, lng: 18.7909533 },
    { lat: 49.3395125, lng: 18.7919993 },
    { lat: 49.3396793, lng: 18.7928671 },
    { lat: 49.3398408, lng: 18.7936615 },
    { lat: 49.3403023, lng: 18.7937532 },
    { lat: 49.3403952, lng: 18.794528 },
    { lat: 49.3407471, lng: 18.7947255 },
    { lat: 49.340769, lng: 18.7950411 },
    { lat: 49.3407512, lng: 18.7953961 },
    { lat: 49.3407293, lng: 18.7955395 },
    { lat: 49.3408032, lng: 18.796292 },
    { lat: 49.3408765, lng: 18.797122 },
    { lat: 49.3408539, lng: 18.7975811 },
    { lat: 49.3408225, lng: 18.7980846 },
    { lat: 49.34081, lng: 18.7984972 },
    { lat: 49.3404674, lng: 18.7986533 },
    { lat: 49.3402269, lng: 18.7985164 },
    { lat: 49.3400738, lng: 18.7983704 },
    { lat: 49.3398359, lng: 18.7987813 },
    { lat: 49.3395505, lng: 18.7991948 },
    { lat: 49.3393224, lng: 18.7998869 },
    { lat: 49.3392889, lng: 18.7999796 },
    { lat: 49.3392761, lng: 18.8000162 },
    { lat: 49.3389408, lng: 18.8009494 },
    { lat: 49.3389098, lng: 18.801485 },
    { lat: 49.3391745, lng: 18.8016096 },
    { lat: 49.3389731, lng: 18.8024504 },
    { lat: 49.3388579, lng: 18.8028823 },
    { lat: 49.3385684, lng: 18.8031703 },
    { lat: 49.339436, lng: 18.8037927 },
    { lat: 49.3398104, lng: 18.803994 },
    { lat: 49.3398772, lng: 18.8040189 },
    { lat: 49.3408356, lng: 18.8045435 },
    { lat: 49.3417047, lng: 18.8047842 },
    { lat: 49.3418907, lng: 18.8048456 },
    { lat: 49.3424332, lng: 18.8050249 },
    { lat: 49.3430228, lng: 18.8052194 },
    { lat: 49.3431593, lng: 18.8052641 },
    { lat: 49.3432797, lng: 18.8053038 },
    { lat: 49.3435228, lng: 18.8053847 },
    { lat: 49.3435402, lng: 18.8053504 },
    { lat: 49.3437239, lng: 18.8053253 },
    { lat: 49.3438031, lng: 18.8053545 },
    { lat: 49.3438809, lng: 18.8053744 },
    { lat: 49.3441166, lng: 18.8055734 },
    { lat: 49.3443555, lng: 18.8057206 },
    { lat: 49.3445233, lng: 18.805786 },
    { lat: 49.3445721, lng: 18.8058355 },
    { lat: 49.3445963, lng: 18.8058868 },
    { lat: 49.3446008, lng: 18.8059983 },
    { lat: 49.344599, lng: 18.8060083 },
    { lat: 49.3445966, lng: 18.8060267 },
    { lat: 49.3445905, lng: 18.806081 },
    { lat: 49.3445874, lng: 18.8061081 },
    { lat: 49.3445864, lng: 18.8061115 },
    { lat: 49.3445866, lng: 18.8061154 },
    { lat: 49.3445927, lng: 18.8062351 },
    { lat: 49.3446779, lng: 18.8063109 },
    { lat: 49.344932, lng: 18.8064086 },
    { lat: 49.3450377, lng: 18.8064475 },
    { lat: 49.3452201, lng: 18.8065065 },
    { lat: 49.345505, lng: 18.8066081 },
    { lat: 49.3458393, lng: 18.8067308 },
    { lat: 49.3458794, lng: 18.8067422 },
    { lat: 49.3460719, lng: 18.8068011 },
    { lat: 49.346211, lng: 18.8068559 },
    { lat: 49.3462787, lng: 18.8068763 },
    { lat: 49.3463012, lng: 18.8068764 },
    { lat: 49.3463092, lng: 18.8068769 },
    { lat: 49.3463526, lng: 18.8068883 },
    { lat: 49.3463964, lng: 18.8069087 },
    { lat: 49.3464186, lng: 18.8069465 },
    { lat: 49.3465925, lng: 18.8072343 },
    { lat: 49.3467166, lng: 18.8073235 },
    { lat: 49.3467968, lng: 18.8074115 },
    { lat: 49.3468049, lng: 18.807417 },
    { lat: 49.3468141, lng: 18.8075511 },
    { lat: 49.3468427, lng: 18.807978 },
    { lat: 49.3468477, lng: 18.8080508 },
    { lat: 49.3475749, lng: 18.8080516 },
    { lat: 49.3476628, lng: 18.8080519 },
    { lat: 49.3477352, lng: 18.8080522 },
    { lat: 49.3477576, lng: 18.8080516 },
    { lat: 49.3477611, lng: 18.8080519 },
    { lat: 49.3478162, lng: 18.8080518 },
    { lat: 49.3478467, lng: 18.8080521 },
    { lat: 49.3479076, lng: 18.808052 },
    { lat: 49.3481467, lng: 18.808052 },
    { lat: 49.3485784, lng: 18.8080524 },
    { lat: 49.3490769, lng: 18.8080526 },
    { lat: 49.3491204, lng: 18.8080886 },
    { lat: 49.34931, lng: 18.8082421 },
    { lat: 49.3494535, lng: 18.808359 },
    { lat: 49.3500561, lng: 18.8077329 },
    { lat: 49.3515544, lng: 18.8073195 },
    { lat: 49.3539575, lng: 18.8074799 },
    { lat: 49.3540606, lng: 18.8074863 },
    { lat: 49.3541215, lng: 18.8074905 },
    { lat: 49.354154, lng: 18.807492 },
    { lat: 49.354291, lng: 18.806991 },
    { lat: 49.354382, lng: 18.806184 },
    { lat: 49.354731, lng: 18.804148 },
    { lat: 49.355808, lng: 18.803804 },
    { lat: 49.356192, lng: 18.802827 },
    { lat: 49.35648, lng: 18.80188 },
    { lat: 49.356585, lng: 18.80198 },
    { lat: 49.357213, lng: 18.800983 },
    { lat: 49.35723, lng: 18.801111 },
    { lat: 49.357811, lng: 18.800827 },
    { lat: 49.357727, lng: 18.800203 },
    { lat: 49.358123, lng: 18.800037 },
    { lat: 49.35853, lng: 18.799623 },
    { lat: 49.358835, lng: 18.799486 },
    { lat: 49.359421, lng: 18.79905 },
    { lat: 49.36146, lng: 18.797877 },
    { lat: 49.363053, lng: 18.798175 },
    { lat: 49.363582, lng: 18.798628 },
    { lat: 49.363789, lng: 18.798578 },
    { lat: 49.36379, lng: 18.797634 },
    { lat: 49.364159, lng: 18.797634 },
    { lat: 49.364176, lng: 18.797538 },
    { lat: 49.364612, lng: 18.797339 },
    { lat: 49.365218, lng: 18.797821 },
    { lat: 49.365505, lng: 18.797613 },
    { lat: 49.365601, lng: 18.797339 },
    { lat: 49.365738, lng: 18.797539 },
    { lat: 49.365965, lng: 18.797013 },
    { lat: 49.366207, lng: 18.796754 },
    { lat: 49.366377, lng: 18.796291 },
    { lat: 49.366244, lng: 18.79609 },
    { lat: 49.366393, lng: 18.795814 },
    { lat: 49.366726, lng: 18.795843 },
    { lat: 49.366691, lng: 18.795975 },
    { lat: 49.367203, lng: 18.796 },
    { lat: 49.367524, lng: 18.794454 },
    { lat: 49.368001, lng: 18.793975 },
    { lat: 49.367386, lng: 18.79334 },
    { lat: 49.366838, lng: 18.792952 },
    { lat: 49.366716, lng: 18.792749 },
    { lat: 49.366443, lng: 18.792535 },
    { lat: 49.3674, lng: 18.79173 },
    { lat: 49.367797, lng: 18.791478 },
    { lat: 49.368021, lng: 18.790877 },
    { lat: 49.368254, lng: 18.789971 },
    { lat: 49.368461, lng: 18.789359 },
    { lat: 49.368826, lng: 18.786922 },
    { lat: 49.36888, lng: 18.785643 },
    { lat: 49.369089, lng: 18.78501 },
    { lat: 49.370369, lng: 18.783252 },
    { lat: 49.37052, lng: 18.782119 },
    { lat: 49.371862, lng: 18.780661 },
    { lat: 49.372365, lng: 18.780297 },
    { lat: 49.373133, lng: 18.780465 },
    { lat: 49.373976, lng: 18.780508 },
    { lat: 49.374557, lng: 18.780382 },
    { lat: 49.374927, lng: 18.779929 },
    { lat: 49.375381, lng: 18.779537 },
    { lat: 49.375523, lng: 18.778246 },
    { lat: 49.375092, lng: 18.776092 },
    { lat: 49.37598, lng: 18.776224 },
    { lat: 49.376219, lng: 18.775876 },
    { lat: 49.376439, lng: 18.775771 },
    { lat: 49.376601, lng: 18.7756 },
    { lat: 49.377313, lng: 18.775654 },
    { lat: 49.377949, lng: 18.775076 },
    { lat: 49.378664, lng: 18.77504 },
    { lat: 49.380726, lng: 18.774706 },
  ],
  Povina: [
    { lat: 49.300351, lng: 18.90056 },
    { lat: 49.300503, lng: 18.899758 },
    { lat: 49.300973, lng: 18.8991 },
    { lat: 49.301313, lng: 18.898921 },
    { lat: 49.301816, lng: 18.897909 },
    { lat: 49.301818, lng: 18.897904 },
    { lat: 49.30229, lng: 18.89801 },
    { lat: 49.302894, lng: 18.898327 },
    { lat: 49.302911, lng: 18.898336 },
    { lat: 49.303528, lng: 18.898098 },
    { lat: 49.304242, lng: 18.898137 },
    { lat: 49.305023, lng: 18.898396 },
    { lat: 49.305095, lng: 18.898497 },
    { lat: 49.305305, lng: 18.898786 },
    { lat: 49.305664, lng: 18.899279 },
    { lat: 49.305701, lng: 18.899329 },
    { lat: 49.305731, lng: 18.899352 },
    { lat: 49.306311, lng: 18.899782 },
    { lat: 49.307008, lng: 18.900106 },
    { lat: 49.307549, lng: 18.900284 },
    { lat: 49.307978, lng: 18.900466 },
    { lat: 49.308342, lng: 18.900657 },
    { lat: 49.308763, lng: 18.90108 },
    { lat: 49.309119, lng: 18.902034 },
    { lat: 49.309475, lng: 18.902778 },
    { lat: 49.309972, lng: 18.902858 },
    { lat: 49.310613, lng: 18.902984 },
    { lat: 49.311376, lng: 18.902719 },
    { lat: 49.312473, lng: 18.901999 },
    { lat: 49.313799, lng: 18.902146 },
    { lat: 49.314446, lng: 18.90279 },
    { lat: 49.315461, lng: 18.90415 },
    { lat: 49.316025, lng: 18.903481 },
    { lat: 49.31721, lng: 18.901636 },
    { lat: 49.317713, lng: 18.900513 },
    { lat: 49.31814, lng: 18.899757 },
    { lat: 49.318425, lng: 18.898746 },
    { lat: 49.318423, lng: 18.898151 },
    { lat: 49.318592, lng: 18.897355 },
    { lat: 49.318632, lng: 18.896417 },
    { lat: 49.318778, lng: 18.894921 },
    { lat: 49.31886, lng: 18.894711 },
    { lat: 49.31892, lng: 18.894155 },
    { lat: 49.318876, lng: 18.894141 },
    { lat: 49.3189192, lng: 18.8937631 },
    { lat: 49.3189877, lng: 18.8929598 },
    { lat: 49.3189913, lng: 18.8923006 },
    { lat: 49.3190314, lng: 18.892113 },
    { lat: 49.3190695, lng: 18.8919281 },
    { lat: 49.319273, lng: 18.891674 },
    { lat: 49.3193732, lng: 18.8911406 },
    { lat: 49.3194257, lng: 18.8903975 },
    { lat: 49.319509, lng: 18.8901501 },
    { lat: 49.3196416, lng: 18.8897433 },
    { lat: 49.3197867, lng: 18.8892938 },
    { lat: 49.3198071, lng: 18.8889188 },
    { lat: 49.3198302, lng: 18.8883555 },
    { lat: 49.3198671, lng: 18.8879283 },
    { lat: 49.3199974, lng: 18.8874939 },
    { lat: 49.3201517, lng: 18.8872085 },
    { lat: 49.3202287, lng: 18.8862376 },
    { lat: 49.3202462, lng: 18.885848 },
    { lat: 49.320271, lng: 18.8852951 },
    { lat: 49.3203814, lng: 18.8842747 },
    { lat: 49.3203749, lng: 18.8836821 },
    { lat: 49.3203083, lng: 18.8826857 },
    { lat: 49.3201461, lng: 18.8821119 },
    { lat: 49.3201474, lng: 18.8815624 },
    { lat: 49.320261, lng: 18.8806063 },
    { lat: 49.3204406, lng: 18.8787515 },
    { lat: 49.320641, lng: 18.8777515 },
    { lat: 49.3206426, lng: 18.8774495 },
    { lat: 49.3206269, lng: 18.876966 },
    { lat: 49.3206199, lng: 18.8767623 },
    { lat: 49.3207578, lng: 18.8764364 },
    { lat: 49.3209412, lng: 18.8762064 },
    { lat: 49.32104, lng: 18.8760229 },
    { lat: 49.3212147, lng: 18.8755337 },
    { lat: 49.3213114, lng: 18.8751539 },
    { lat: 49.3213905, lng: 18.8747588 },
    { lat: 49.3213643, lng: 18.8743568 },
    { lat: 49.321237, lng: 18.8737193 },
    { lat: 49.3212995, lng: 18.8731516 },
    { lat: 49.3213846, lng: 18.8726102 },
    { lat: 49.3215056, lng: 18.871999 },
    { lat: 49.3216103, lng: 18.870736 },
    { lat: 49.321646, lng: 18.869976 },
    { lat: 49.3216841, lng: 18.8694402 },
    { lat: 49.3217056, lng: 18.867894 },
    { lat: 49.3215997, lng: 18.8673884 },
    { lat: 49.3215226, lng: 18.8668099 },
    { lat: 49.3215183, lng: 18.8661686 },
    { lat: 49.3217455, lng: 18.865146 },
    { lat: 49.3219851, lng: 18.8646696 },
    { lat: 49.3222879, lng: 18.8641079 },
    { lat: 49.3222924, lng: 18.8640448 },
    { lat: 49.3224681, lng: 18.8637105 },
    { lat: 49.3225292, lng: 18.863204 },
    { lat: 49.3228659, lng: 18.862468 },
    { lat: 49.3229466, lng: 18.8623873 },
    { lat: 49.3232423, lng: 18.8620916 },
    { lat: 49.3239379, lng: 18.8612743 },
    { lat: 49.3240664, lng: 18.8603669 },
    { lat: 49.3241088, lng: 18.8596527 },
    { lat: 49.3240241, lng: 18.8594098 },
    { lat: 49.3238354, lng: 18.8584721 },
    { lat: 49.3235271, lng: 18.8576458 },
    { lat: 49.3234572, lng: 18.8573379 },
    { lat: 49.323405, lng: 18.8570872 },
    { lat: 49.3227735, lng: 18.8561175 },
    { lat: 49.3219445, lng: 18.8547645 },
    { lat: 49.3218425, lng: 18.8541157 },
    { lat: 49.321634, lng: 18.8537431 },
    { lat: 49.3214856, lng: 18.852781 },
    { lat: 49.321682, lng: 18.852146 },
    { lat: 49.3217367, lng: 18.8518954 },
    { lat: 49.3217927, lng: 18.8514706 },
    { lat: 49.3219733, lng: 18.8511435 },
    { lat: 49.3220146, lng: 18.8506962 },
    { lat: 49.3221919, lng: 18.8501082 },
    { lat: 49.3223229, lng: 18.8499761 },
    { lat: 49.3225282, lng: 18.8490519 },
    { lat: 49.3226881, lng: 18.8486554 },
    { lat: 49.3228264, lng: 18.8478115 },
    { lat: 49.3229166, lng: 18.8466448 },
    { lat: 49.3228985, lng: 18.8461811 },
    { lat: 49.322809, lng: 18.8451155 },
    { lat: 49.3226772, lng: 18.8439722 },
    { lat: 49.322765, lng: 18.8438604 },
    { lat: 49.3232047, lng: 18.8426955 },
    { lat: 49.323405, lng: 18.8417632 },
    { lat: 49.3233323, lng: 18.8409811 },
    { lat: 49.3232362, lng: 18.8404927 },
    { lat: 49.3229811, lng: 18.8401355 },
    { lat: 49.3225612, lng: 18.8403229 },
    { lat: 49.3222561, lng: 18.8403527 },
    { lat: 49.322072, lng: 18.8403071 },
    { lat: 49.3220263, lng: 18.8391712 },
    { lat: 49.3223373, lng: 18.838882 },
    { lat: 49.3224211, lng: 18.8382087 },
    { lat: 49.3225596, lng: 18.837523 },
    { lat: 49.3226162, lng: 18.8368497 },
    { lat: 49.3224891, lng: 18.8359509 },
    { lat: 49.3224615, lng: 18.8352838 },
    { lat: 49.322516, lng: 18.8347857 },
    { lat: 49.3228131, lng: 18.8342917 },
    { lat: 49.3232621, lng: 18.8339027 },
    { lat: 49.3233909, lng: 18.8338836 },
    { lat: 49.3239992, lng: 18.8324243 },
    { lat: 49.324206, lng: 18.8314004 },
    { lat: 49.3236538, lng: 18.8297832 },
    { lat: 49.3234449, lng: 18.8295571 },
    { lat: 49.3229617, lng: 18.8288388 },
    { lat: 49.3229479, lng: 18.8282429 },
    { lat: 49.3229108, lng: 18.8266448 },
    { lat: 49.3234038, lng: 18.825902 },
    { lat: 49.3235218, lng: 18.8249527 },
    { lat: 49.323471, lng: 18.8240288 },
    { lat: 49.3235042, lng: 18.82299 },
    { lat: 49.3235724, lng: 18.8211127 },
    { lat: 49.3235301, lng: 18.8194363 },
    { lat: 49.3235066, lng: 18.8182145 },
    { lat: 49.3237307, lng: 18.8181651 },
    { lat: 49.3243971, lng: 18.8176637 },
    { lat: 49.3247454, lng: 18.8175719 },
    { lat: 49.3251985, lng: 18.8174157 },
    { lat: 49.3257061, lng: 18.8172653 },
    { lat: 49.3264354, lng: 18.8168474 },
    { lat: 49.3269916, lng: 18.8165494 },
    { lat: 49.3273914, lng: 18.816361 },
    { lat: 49.3277158, lng: 18.8160504 },
    { lat: 49.3283437, lng: 18.8157416 },
    { lat: 49.3289512, lng: 18.8147317 },
    { lat: 49.3290313, lng: 18.814426 },
    { lat: 49.3290771, lng: 18.8136722 },
    { lat: 49.3290906, lng: 18.8133192 },
    { lat: 49.3290996, lng: 18.8122474 },
    { lat: 49.3291508, lng: 18.811708 },
    { lat: 49.3292923, lng: 18.8112166 },
    { lat: 49.329553, lng: 18.8105865 },
    { lat: 49.3300731, lng: 18.8098492 },
    { lat: 49.3301586, lng: 18.8094315 },
    { lat: 49.3302433, lng: 18.808733 },
    { lat: 49.3302409, lng: 18.808623 },
    { lat: 49.3302395, lng: 18.8085317 },
    { lat: 49.3302369, lng: 18.8083878 },
    { lat: 49.3302356, lng: 18.808346 },
    { lat: 49.3302336, lng: 18.8081742 },
    { lat: 49.330219, lng: 18.8079352 },
    { lat: 49.3274342, lng: 18.806714 },
    { lat: 49.3269672, lng: 18.8063134 },
    { lat: 49.3253391, lng: 18.8049155 },
    { lat: 49.324036, lng: 18.8040032 },
    { lat: 49.3225304, lng: 18.8031629 },
    { lat: 49.3214451, lng: 18.8020625 },
    { lat: 49.3212025, lng: 18.8018164 },
    { lat: 49.3212034, lng: 18.8018094 },
    { lat: 49.3212203, lng: 18.8015287 },
    { lat: 49.3212258, lng: 18.8014376 },
    { lat: 49.3212115, lng: 18.8013349 },
    { lat: 49.3211966, lng: 18.8012203 },
    { lat: 49.3211125, lng: 18.8005987 },
    { lat: 49.3214018, lng: 18.7998907 },
    { lat: 49.3214719, lng: 18.7996741 },
    { lat: 49.3214999, lng: 18.7995854 },
    { lat: 49.3216147, lng: 18.7992285 },
    { lat: 49.3212784, lng: 18.7985481 },
    { lat: 49.3209364, lng: 18.7979753 },
    { lat: 49.3207644, lng: 18.7977181 },
    { lat: 49.3205238, lng: 18.7975155 },
    { lat: 49.320018, lng: 18.7972196 },
    { lat: 49.3196551, lng: 18.7969823 },
    { lat: 49.3192047, lng: 18.7967974 },
    { lat: 49.3188409, lng: 18.7967156 },
    { lat: 49.31821, lng: 18.7967052 },
    { lat: 49.3181168, lng: 18.7966496 },
    { lat: 49.318021, lng: 18.7966474 },
    { lat: 49.3177101, lng: 18.7967392 },
    { lat: 49.31765, lng: 18.7968003 },
    { lat: 49.3170695, lng: 18.7980451 },
    { lat: 49.3168547, lng: 18.7985159 },
    { lat: 49.3168095, lng: 18.7985369 },
    { lat: 49.3167269, lng: 18.7985755 },
    { lat: 49.316674, lng: 18.7985996 },
    { lat: 49.3166466, lng: 18.7986126 },
    { lat: 49.316236, lng: 18.7989156 },
    { lat: 49.316236, lng: 18.798916 },
    { lat: 49.3161384, lng: 18.7989881 },
    { lat: 49.3160819, lng: 18.7990293 },
    { lat: 49.3160773, lng: 18.7990327 },
    { lat: 49.3158775, lng: 18.7991797 },
    { lat: 49.3158053, lng: 18.799286 },
    { lat: 49.3157099, lng: 18.7994248 },
    { lat: 49.3156308, lng: 18.799422 },
    { lat: 49.3152739, lng: 18.7994092 },
    { lat: 49.3150909, lng: 18.7994024 },
    { lat: 49.3150139, lng: 18.7993994 },
    { lat: 49.3149654, lng: 18.7993976 },
    { lat: 49.3149525, lng: 18.7994314 },
    { lat: 49.31487, lng: 18.7996462 },
    { lat: 49.3147965, lng: 18.7998365 },
    { lat: 49.3146432, lng: 18.8001901 },
    { lat: 49.314002, lng: 18.8007858 },
    { lat: 49.3139812, lng: 18.8008859 },
    { lat: 49.3139669, lng: 18.8009577 },
    { lat: 49.3139242, lng: 18.8010463 },
    { lat: 49.3136192, lng: 18.801669 },
    { lat: 49.3135539, lng: 18.8018034 },
    { lat: 49.3134819, lng: 18.8019786 },
    { lat: 49.3134549, lng: 18.8020459 },
    { lat: 49.3133869, lng: 18.8020194 },
    { lat: 49.3129369, lng: 18.8018428 },
    { lat: 49.3129359, lng: 18.8018426 },
    { lat: 49.3128771, lng: 18.8018193 },
    { lat: 49.3128255, lng: 18.8017992 },
    { lat: 49.3128023, lng: 18.80179 },
    { lat: 49.3127931, lng: 18.8017864 },
    { lat: 49.3126434, lng: 18.8017225 },
    { lat: 49.3125233, lng: 18.8016719 },
    { lat: 49.3124658, lng: 18.8016471 },
    { lat: 49.3124255, lng: 18.8016303 },
    { lat: 49.3124197, lng: 18.8016281 },
    { lat: 49.3122941, lng: 18.8015572 },
    { lat: 49.3122384, lng: 18.801526 },
    { lat: 49.3122165, lng: 18.8015142 },
    { lat: 49.3122107, lng: 18.8015106 },
    { lat: 49.312206, lng: 18.8015075 },
    { lat: 49.3121978, lng: 18.8015035 },
    { lat: 49.3121027, lng: 18.8014506 },
    { lat: 49.312091, lng: 18.8014434 },
    { lat: 49.3120747, lng: 18.8014341 },
    { lat: 49.312054, lng: 18.8014233 },
    { lat: 49.3119147, lng: 18.8013479 },
    { lat: 49.3114345, lng: 18.8010883 },
    { lat: 49.3113047, lng: 18.8010176 },
    { lat: 49.3111228, lng: 18.8009283 },
    { lat: 49.3106544, lng: 18.8006968 },
    { lat: 49.3105912, lng: 18.8010001 },
    { lat: 49.3105784, lng: 18.8010594 },
    { lat: 49.3105749, lng: 18.8010783 },
    { lat: 49.3105703, lng: 18.8010994 },
    { lat: 49.3105684, lng: 18.801108 },
    { lat: 49.3105639, lng: 18.8011266 },
    { lat: 49.3105641, lng: 18.8011298 },
    { lat: 49.3105318, lng: 18.8012692 },
    { lat: 49.3105251, lng: 18.8013174 },
    { lat: 49.3105011, lng: 18.8014438 },
    { lat: 49.3105123, lng: 18.8015036 },
    { lat: 49.3105961, lng: 18.8015578 },
    { lat: 49.3106153, lng: 18.8017965 },
    { lat: 49.3107004, lng: 18.8019163 },
    { lat: 49.3106286, lng: 18.8030578 },
    { lat: 49.3106248, lng: 18.8034468 },
    { lat: 49.3102292, lng: 18.8045609 },
    { lat: 49.3096328, lng: 18.8042126 },
    { lat: 49.3093658, lng: 18.8055714 },
    { lat: 49.3088291, lng: 18.8053532 },
    { lat: 49.3087938, lng: 18.8058518 },
    { lat: 49.3092011, lng: 18.8064238 },
    { lat: 49.3092743, lng: 18.806707 },
    { lat: 49.3093303, lng: 18.8069589 },
    { lat: 49.3090967, lng: 18.8077427 },
    { lat: 49.3085243, lng: 18.8087115 },
    { lat: 49.3084061, lng: 18.8088248 },
    { lat: 49.3080773, lng: 18.8096012 },
    { lat: 49.3079714, lng: 18.8096462 },
    { lat: 49.3077374, lng: 18.8098955 },
    { lat: 49.3074717, lng: 18.8103832 },
    { lat: 49.3072376, lng: 18.8111598 },
    { lat: 49.3072681, lng: 18.8117682 },
    { lat: 49.3071476, lng: 18.8125394 },
    { lat: 49.3072469, lng: 18.8134622 },
    { lat: 49.3070256, lng: 18.8135882 },
    { lat: 49.3068457, lng: 18.8137685 },
    { lat: 49.3067152, lng: 18.8138182 },
    { lat: 49.306581, lng: 18.81441 },
    { lat: 49.3063992, lng: 18.8158927 },
    { lat: 49.3061396, lng: 18.8161065 },
    { lat: 49.3062885, lng: 18.8164148 },
    { lat: 49.3066707, lng: 18.8167196 },
    { lat: 49.3068539, lng: 18.816974 },
    { lat: 49.3069571, lng: 18.8171151 },
    { lat: 49.3069888, lng: 18.8174455 },
    { lat: 49.3069133, lng: 18.8177737 },
    { lat: 49.3067279, lng: 18.8178766 },
    { lat: 49.3066875, lng: 18.8178988 },
    { lat: 49.3064123, lng: 18.8179012 },
    { lat: 49.3059385, lng: 18.8181137 },
    { lat: 49.3056504, lng: 18.8184284 },
    { lat: 49.3053806, lng: 18.818773 },
    { lat: 49.3050187, lng: 18.8190601 },
    { lat: 49.3048899, lng: 18.8193839 },
    { lat: 49.3048645, lng: 18.8195889 },
    { lat: 49.3048265, lng: 18.8199013 },
    { lat: 49.3047647, lng: 18.8204124 },
    { lat: 49.3047656, lng: 18.8206703 },
    { lat: 49.3047784, lng: 18.8208946 },
    { lat: 49.3048847, lng: 18.8209681 },
    { lat: 49.3048051, lng: 18.821877 },
    { lat: 49.3047198, lng: 18.822607 },
    { lat: 49.3046949, lng: 18.8231979 },
    { lat: 49.3047035, lng: 18.8243715 },
    { lat: 49.3047575, lng: 18.8250714 },
    { lat: 49.3045348, lng: 18.8254089 },
    { lat: 49.3045408, lng: 18.8266471 },
    { lat: 49.3035013, lng: 18.8278285 },
    { lat: 49.3031852, lng: 18.8280877 },
    { lat: 49.3029876, lng: 18.8283202 },
    { lat: 49.3027539, lng: 18.8284011 },
    { lat: 49.3025858, lng: 18.8284863 },
    { lat: 49.3024887, lng: 18.8286393 },
    { lat: 49.3025715, lng: 18.8293483 },
    { lat: 49.3025681, lng: 18.8295713 },
    { lat: 49.3025256, lng: 18.8298629 },
    { lat: 49.3025251, lng: 18.8300505 },
    { lat: 49.302802, lng: 18.8303261 },
    { lat: 49.3030458, lng: 18.8312535 },
    { lat: 49.303082, lng: 18.8313883 },
    { lat: 49.3031677, lng: 18.8317151 },
    { lat: 49.3035856, lng: 18.8318188 },
    { lat: 49.3037081, lng: 18.8328414 },
    { lat: 49.3039325, lng: 18.8334575 },
    { lat: 49.3041939, lng: 18.8340255 },
    { lat: 49.3043512, lng: 18.8343658 },
    { lat: 49.3044447, lng: 18.8347144 },
    { lat: 49.3044031, lng: 18.8351544 },
    { lat: 49.3046631, lng: 18.8352345 },
    { lat: 49.304342, lng: 18.8359664 },
    { lat: 49.3035422, lng: 18.8369881 },
    { lat: 49.3030682, lng: 18.8378785 },
    { lat: 49.3022935, lng: 18.8395456 },
    { lat: 49.3016391, lng: 18.8395381 },
    { lat: 49.3013472, lng: 18.8396266 },
    { lat: 49.3009508, lng: 18.8394845 },
    { lat: 49.3007028, lng: 18.8397917 },
    { lat: 49.3005886, lng: 18.8398311 },
    { lat: 49.300471, lng: 18.839804 },
    { lat: 49.3002154, lng: 18.8397227 },
    { lat: 49.299867, lng: 18.8397182 },
    { lat: 49.2998594, lng: 18.8398156 },
    { lat: 49.3000188, lng: 18.840131 },
    { lat: 49.3002344, lng: 18.8404419 },
    { lat: 49.3002794, lng: 18.8413657 },
    { lat: 49.2997781, lng: 18.8415266 },
    { lat: 49.2998278, lng: 18.8415932 },
    { lat: 49.299579, lng: 18.8421039 },
    { lat: 49.2994231, lng: 18.8424084 },
    { lat: 49.2989069, lng: 18.8425667 },
    { lat: 49.2989931, lng: 18.8427956 },
    { lat: 49.2994093, lng: 18.8429104 },
    { lat: 49.2981243, lng: 18.8428044 },
    { lat: 49.2980643, lng: 18.8429983 },
    { lat: 49.297677, lng: 18.8433609 },
    { lat: 49.2976532, lng: 18.8436202 },
    { lat: 49.2977185, lng: 18.8437081 },
    { lat: 49.2978147, lng: 18.8438891 },
    { lat: 49.297759, lng: 18.8442538 },
    { lat: 49.297711, lng: 18.844572 },
    { lat: 49.2975153, lng: 18.8446486 },
    { lat: 49.2973043, lng: 18.8446432 },
    { lat: 49.2971079, lng: 18.8447534 },
    { lat: 49.2970911, lng: 18.8447522 },
    { lat: 49.2967454, lng: 18.8447181 },
    { lat: 49.2966026, lng: 18.8447038 },
    { lat: 49.2965855, lng: 18.8447022 },
    { lat: 49.2965756, lng: 18.8447013 },
    { lat: 49.2965405, lng: 18.8447918 },
    { lat: 49.2965194, lng: 18.8448417 },
    { lat: 49.2962866, lng: 18.8454248 },
    { lat: 49.2962736, lng: 18.845456 },
    { lat: 49.2963095, lng: 18.8459119 },
    { lat: 49.2964364, lng: 18.8461257 },
    { lat: 49.2964868, lng: 18.8465561 },
    { lat: 49.2965431, lng: 18.847239 },
    { lat: 49.2964345, lng: 18.8490799 },
    { lat: 49.2963691, lng: 18.8500042 },
    { lat: 49.2962657, lng: 18.8509554 },
    { lat: 49.2961742, lng: 18.8515903 },
    { lat: 49.2960184, lng: 18.8519619 },
    { lat: 49.295769, lng: 18.8523327 },
    { lat: 49.2957352, lng: 18.8525506 },
    { lat: 49.2957642, lng: 18.8539563 },
    { lat: 49.2957409, lng: 18.8542026 },
    { lat: 49.2955734, lng: 18.8554909 },
    { lat: 49.2957216, lng: 18.8561198 },
    { lat: 49.2961711, lng: 18.8568391 },
    { lat: 49.2966171, lng: 18.8575567 },
    { lat: 49.298065, lng: 18.8598736 },
    { lat: 49.2980796, lng: 18.8602295 },
    { lat: 49.2980938, lng: 18.8605717 },
    { lat: 49.2982059, lng: 18.8621262 },
    { lat: 49.2975842, lng: 18.862799 },
    { lat: 49.2975564, lng: 18.8628291 },
    { lat: 49.297252, lng: 18.8631592 },
    { lat: 49.2969647, lng: 18.8639124 },
    { lat: 49.2965337, lng: 18.8647662 },
    { lat: 49.2958014, lng: 18.8655313 },
    { lat: 49.2952334, lng: 18.8671026 },
    { lat: 49.2949182, lng: 18.8694709 },
    { lat: 49.2947124, lng: 18.8693499 },
    { lat: 49.2944705, lng: 18.8692075 },
    { lat: 49.2943663, lng: 18.8691737 },
    { lat: 49.2939362, lng: 18.8690343 },
    { lat: 49.2938582, lng: 18.8690505 },
    { lat: 49.2934255, lng: 18.8691422 },
    { lat: 49.2933495, lng: 18.8692214 },
    { lat: 49.2929843, lng: 18.8695973 },
    { lat: 49.2926839, lng: 18.8699889 },
    { lat: 49.2925883, lng: 18.8701665 },
    { lat: 49.2925323, lng: 18.8703498 },
    { lat: 49.2922526, lng: 18.8704616 },
    { lat: 49.2922103, lng: 18.8706707 },
    { lat: 49.292983, lng: 18.8716271 },
    { lat: 49.2929516, lng: 18.8720065 },
    { lat: 49.2927047, lng: 18.8723173 },
    { lat: 49.2923978, lng: 18.8727282 },
    { lat: 49.2921041, lng: 18.8733397 },
    { lat: 49.2918697, lng: 18.8737361 },
    { lat: 49.2914299, lng: 18.8744188 },
    { lat: 49.291129, lng: 18.875042 },
    { lat: 49.2910672, lng: 18.8755998 },
    { lat: 49.2910647, lng: 18.8763893 },
    { lat: 49.2909429, lng: 18.8768539 },
    { lat: 49.2908058, lng: 18.8774128 },
    { lat: 49.2905973, lng: 18.8783883 },
    { lat: 49.2904673, lng: 18.8794209 },
    { lat: 49.2902842, lng: 18.880121 },
    { lat: 49.2901261, lng: 18.8806885 },
    { lat: 49.2902917, lng: 18.8809306 },
    { lat: 49.2904525, lng: 18.881165 },
    { lat: 49.290777, lng: 18.8816024 },
    { lat: 49.291122, lng: 18.8820692 },
    { lat: 49.2915547, lng: 18.8829517 },
    { lat: 49.2920272, lng: 18.8847473 },
    { lat: 49.2923875, lng: 18.885512 },
    { lat: 49.2924135, lng: 18.8859771 },
    { lat: 49.2923265, lng: 18.8866636 },
    { lat: 49.2921972, lng: 18.8874642 },
    { lat: 49.2923388, lng: 18.8879429 },
    { lat: 49.2926003, lng: 18.8883424 },
    { lat: 49.2926386, lng: 18.8883609 },
    { lat: 49.2929486, lng: 18.8885166 },
    { lat: 49.2928526, lng: 18.8894657 },
    { lat: 49.2929018, lng: 18.8900981 },
    { lat: 49.2930191, lng: 18.8903879 },
    { lat: 49.2932096, lng: 18.8906791 },
    { lat: 49.2933325, lng: 18.8913205 },
    { lat: 49.2932814, lng: 18.8924882 },
    { lat: 49.2931548, lng: 18.8933676 },
    { lat: 49.2933608, lng: 18.8937802 },
    { lat: 49.2939129, lng: 18.8934003 },
    { lat: 49.2947623, lng: 18.8936764 },
    { lat: 49.295212, lng: 18.8943147 },
    { lat: 49.2953517, lng: 18.8946687 },
    { lat: 49.2954653, lng: 18.8949525 },
    { lat: 49.2958949, lng: 18.8954834 },
    { lat: 49.2964749, lng: 18.8959577 },
    { lat: 49.2966755, lng: 18.8964696 },
    { lat: 49.297007, lng: 18.8973123 },
    { lat: 49.2974976, lng: 18.8977157 },
    { lat: 49.2979437, lng: 18.8982432 },
    { lat: 49.2982174, lng: 18.8982812 },
    { lat: 49.2983744, lng: 18.8985732 },
    { lat: 49.2987082, lng: 18.898777 },
    { lat: 49.2989257, lng: 18.8989766 },
    { lat: 49.2991161, lng: 18.8993474 },
    { lat: 49.2994155, lng: 18.8996507 },
    { lat: 49.299555, lng: 18.9000679 },
    { lat: 49.2996679, lng: 18.9001141 },
    { lat: 49.2999034, lng: 18.9000002 },
    { lat: 49.3000536, lng: 18.9000936 },
    { lat: 49.300351, lng: 18.90056 },
  ],
  Radoľa: [
    { lat: 49.267999, lng: 18.815081 },
    { lat: 49.2691663, lng: 18.8164509 },
    { lat: 49.2696057, lng: 18.8167717 },
    { lat: 49.2703865, lng: 18.817353 },
    { lat: 49.2709711, lng: 18.818216 },
    { lat: 49.2713429, lng: 18.8179488 },
    { lat: 49.2716136, lng: 18.8177534 },
    { lat: 49.2717646, lng: 18.8171402 },
    { lat: 49.2721046, lng: 18.8164533 },
    { lat: 49.2724801, lng: 18.8158177 },
    { lat: 49.2727088, lng: 18.8158115 },
    { lat: 49.2731546, lng: 18.8160406 },
    { lat: 49.2729634, lng: 18.8165297 },
    { lat: 49.273215, lng: 18.8167089 },
    { lat: 49.2742556, lng: 18.815765 },
    { lat: 49.2745075, lng: 18.8165172 },
    { lat: 49.2748618, lng: 18.8159112 },
    { lat: 49.2754195, lng: 18.8167801 },
    { lat: 49.2764034, lng: 18.8176247 },
    { lat: 49.2769073, lng: 18.8172936 },
    { lat: 49.2769653, lng: 18.8172404 },
    { lat: 49.277869, lng: 18.8164199 },
    { lat: 49.2781922, lng: 18.816126 },
    { lat: 49.2810034, lng: 18.8157832 },
    { lat: 49.2818031, lng: 18.8156808 },
    { lat: 49.2819488, lng: 18.8156625 },
    { lat: 49.2829528, lng: 18.8157179 },
    { lat: 49.2830111, lng: 18.8157535 },
    { lat: 49.2830342, lng: 18.8157449 },
    { lat: 49.2830378, lng: 18.8157441 },
    { lat: 49.2831693, lng: 18.8156972 },
    { lat: 49.2831783, lng: 18.8156936 },
    { lat: 49.2832777, lng: 18.8160912 },
    { lat: 49.2833449, lng: 18.8160842 },
    { lat: 49.2834112, lng: 18.8160776 },
    { lat: 49.2834706, lng: 18.8165964 },
    { lat: 49.2835207, lng: 18.8166069 },
    { lat: 49.283541, lng: 18.8166518 },
    { lat: 49.2835201, lng: 18.8171412 },
    { lat: 49.2834358, lng: 18.8177599 },
    { lat: 49.2835733, lng: 18.8178025 },
    { lat: 49.2836954, lng: 18.8178521 },
    { lat: 49.2837299, lng: 18.817866 },
    { lat: 49.2838263, lng: 18.8178981 },
    { lat: 49.2838446, lng: 18.8179039 },
    { lat: 49.2839066, lng: 18.8179235 },
    { lat: 49.2839273, lng: 18.8179308 },
    { lat: 49.2841319, lng: 18.8179451 },
    { lat: 49.284158, lng: 18.8179474 },
    { lat: 49.284279, lng: 18.8179115 },
    { lat: 49.2843473, lng: 18.8178798 },
    { lat: 49.2843864, lng: 18.8178488 },
    { lat: 49.284376, lng: 18.8179558 },
    { lat: 49.2843996, lng: 18.8179953 },
    { lat: 49.2843169, lng: 18.8180973 },
    { lat: 49.2843063, lng: 18.8181105 },
    { lat: 49.2841928, lng: 18.8182517 },
    { lat: 49.2840873, lng: 18.8183919 },
    { lat: 49.284111, lng: 18.8184341 },
    { lat: 49.2841567, lng: 18.8185146 },
    { lat: 49.2841441, lng: 18.8185306 },
    { lat: 49.2841861, lng: 18.8186259 },
    { lat: 49.2842104, lng: 18.8186811 },
    { lat: 49.2842681, lng: 18.8188143 },
    { lat: 49.2842924, lng: 18.8188696 },
    { lat: 49.2843153, lng: 18.8189231 },
    { lat: 49.2843522, lng: 18.8188728 },
    { lat: 49.284589, lng: 18.8190502 },
    { lat: 49.2863867, lng: 18.8203927 },
    { lat: 49.2877227, lng: 18.8220578 },
    { lat: 49.2880896, lng: 18.8220425 },
    { lat: 49.288639, lng: 18.8227935 },
    { lat: 49.2888083, lng: 18.8232998 },
    { lat: 49.2889708, lng: 18.8235013 },
    { lat: 49.2893784, lng: 18.8237087 },
    { lat: 49.2894191, lng: 18.8238208 },
    { lat: 49.2895386, lng: 18.8241492 },
    { lat: 49.2902592, lng: 18.8228594 },
    { lat: 49.291003, lng: 18.8216018 },
    { lat: 49.2909513, lng: 18.8214107 },
    { lat: 49.2911361, lng: 18.8211219 },
    { lat: 49.2913321, lng: 18.8208776 },
    { lat: 49.2914209, lng: 18.8210927 },
    { lat: 49.292479, lng: 18.8230717 },
    { lat: 49.292699, lng: 18.8230319 },
    { lat: 49.2927123, lng: 18.8230292 },
    { lat: 49.292747, lng: 18.8230229 },
    { lat: 49.2927498, lng: 18.8228809 },
    { lat: 49.2927639, lng: 18.8224985 },
    { lat: 49.2926762, lng: 18.8223909 },
    { lat: 49.2929694, lng: 18.8220425 },
    { lat: 49.2929614, lng: 18.8220203 },
    { lat: 49.2929307, lng: 18.8215118 },
    { lat: 49.2927412, lng: 18.8195135 },
    { lat: 49.2930434, lng: 18.8190791 },
    { lat: 49.2929543, lng: 18.8182022 },
    { lat: 49.2937228, lng: 18.8175588 },
    { lat: 49.293937, lng: 18.8172491 },
    { lat: 49.2940671, lng: 18.8170832 },
    { lat: 49.2941618, lng: 18.8167094 },
    { lat: 49.2942274, lng: 18.8158833 },
    { lat: 49.2942574, lng: 18.8155243 },
    { lat: 49.2942562, lng: 18.8154605 },
    { lat: 49.2942466, lng: 18.8153605 },
    { lat: 49.2942303, lng: 18.8152602 },
    { lat: 49.2940953, lng: 18.8149831 },
    { lat: 49.2941664, lng: 18.8146809 },
    { lat: 49.2942932, lng: 18.8136379 },
    { lat: 49.2939783, lng: 18.8133037 },
    { lat: 49.2939422, lng: 18.8129534 },
    { lat: 49.2938393, lng: 18.8126432 },
    { lat: 49.2937922, lng: 18.8123609 },
    { lat: 49.2936004, lng: 18.8114824 },
    { lat: 49.2937255, lng: 18.8113102 },
    { lat: 49.2939468, lng: 18.8110064 },
    { lat: 49.2940121, lng: 18.8109176 },
    { lat: 49.2940266, lng: 18.8106764 },
    { lat: 49.2940924, lng: 18.8104857 },
    { lat: 49.2941471, lng: 18.8101251 },
    { lat: 49.2934724, lng: 18.8075401 },
    { lat: 49.2934805, lng: 18.8072574 },
    { lat: 49.2935106, lng: 18.8061827 },
    { lat: 49.2938135, lng: 18.8046849 },
    { lat: 49.2942319, lng: 18.8046026 },
    { lat: 49.2944425, lng: 18.804553 },
    { lat: 49.295031, lng: 18.8040947 },
    { lat: 49.2956986, lng: 18.803572 },
    { lat: 49.2960332, lng: 18.8030862 },
    { lat: 49.2962091, lng: 18.8024754 },
    { lat: 49.2961743, lng: 18.8021906 },
    { lat: 49.296, lng: 18.8011538 },
    { lat: 49.2959406, lng: 18.8006357 },
    { lat: 49.2959076, lng: 18.800458 },
    { lat: 49.2958919, lng: 18.8003284 },
    { lat: 49.2958763, lng: 18.8002447 },
    { lat: 49.2958094, lng: 18.7999114 },
    { lat: 49.2958095, lng: 18.7995837 },
    { lat: 49.2958508, lng: 18.7991615 },
    { lat: 49.2956841, lng: 18.7988734 },
    { lat: 49.2956269, lng: 18.7987745 },
    { lat: 49.2952982, lng: 18.7983308 },
    { lat: 49.2951484, lng: 18.7980285 },
    { lat: 49.2945555, lng: 18.7969373 },
    { lat: 49.2943837, lng: 18.7967369 },
    { lat: 49.2943084, lng: 18.7965871 },
    { lat: 49.2942934, lng: 18.7965582 },
    { lat: 49.2943468, lng: 18.7964574 },
    { lat: 49.2944037, lng: 18.796379 },
    { lat: 49.2944509, lng: 18.796313 },
    { lat: 49.2944877, lng: 18.7962609 },
    { lat: 49.2945152, lng: 18.7962229 },
    { lat: 49.2945445, lng: 18.7961821 },
    { lat: 49.2945752, lng: 18.7961398 },
    { lat: 49.2946045, lng: 18.796099 },
    { lat: 49.2946424, lng: 18.7960468 },
    { lat: 49.294677, lng: 18.7959981 },
    { lat: 49.2947182, lng: 18.7959408 },
    { lat: 49.2947191, lng: 18.7959396 },
    { lat: 49.2947425, lng: 18.7958656 },
    { lat: 49.2947648, lng: 18.7957946 },
    { lat: 49.2947648, lng: 18.7957773 },
    { lat: 49.2947665, lng: 18.7956301 },
    { lat: 49.2947672, lng: 18.7955386 },
    { lat: 49.2947688, lng: 18.7953512 },
    { lat: 49.2947686, lng: 18.795348 },
    { lat: 49.2947802, lng: 18.7952653 },
    { lat: 49.2947932, lng: 18.7951695 },
    { lat: 49.2947992, lng: 18.7951294 },
    { lat: 49.2948008, lng: 18.7951194 },
    { lat: 49.2948691, lng: 18.794759 },
    { lat: 49.2949265, lng: 18.7947137 },
    { lat: 49.294987, lng: 18.7946212 },
    { lat: 49.2951564, lng: 18.7944298 },
    { lat: 49.2951986, lng: 18.7943503 },
    { lat: 49.2952742, lng: 18.7941761 },
    { lat: 49.2953822, lng: 18.7939759 },
    { lat: 49.2957044, lng: 18.7933799 },
    { lat: 49.2957338, lng: 18.7932965 },
    { lat: 49.2957337, lng: 18.7932943 },
    { lat: 49.2959929, lng: 18.7927272 },
    { lat: 49.2960861, lng: 18.7925234 },
    { lat: 49.2960964, lng: 18.7924405 },
    { lat: 49.2961672, lng: 18.7918442 },
    { lat: 49.2963489, lng: 18.7915206 },
    { lat: 49.2966919, lng: 18.7910658 },
    { lat: 49.2968197, lng: 18.7909889 },
    { lat: 49.2968466, lng: 18.7910297 },
    { lat: 49.2968749, lng: 18.7910733 },
    { lat: 49.2969054, lng: 18.7911205 },
    { lat: 49.2969349, lng: 18.791165 },
    { lat: 49.2969677, lng: 18.7912087 },
    { lat: 49.2972222, lng: 18.7911425 },
    { lat: 49.2973487, lng: 18.791034 },
    { lat: 49.297679, lng: 18.7907502 },
    { lat: 49.2978311, lng: 18.7906173 },
    { lat: 49.2979409, lng: 18.7906926 },
    { lat: 49.2982566, lng: 18.7906901 },
    { lat: 49.2982854, lng: 18.7906567 },
    { lat: 49.298351, lng: 18.7905783 },
    { lat: 49.298364, lng: 18.7905633 },
    { lat: 49.298382, lng: 18.7905417 },
    { lat: 49.2983862, lng: 18.7905361 },
    { lat: 49.2983978, lng: 18.7905228 },
    { lat: 49.2984138, lng: 18.7905038 },
    { lat: 49.2984169, lng: 18.7905006 },
    { lat: 49.2984554, lng: 18.7903937 },
    { lat: 49.2984265, lng: 18.790356 },
    { lat: 49.2984626, lng: 18.7902924 },
    { lat: 49.298527, lng: 18.7901838 },
    { lat: 49.2985382, lng: 18.7901644 },
    { lat: 49.2985476, lng: 18.7901491 },
    { lat: 49.2985558, lng: 18.7901348 },
    { lat: 49.2986358, lng: 18.7899997 },
    { lat: 49.2986641, lng: 18.7899584 },
    { lat: 49.2986838, lng: 18.7899278 },
    { lat: 49.298869, lng: 18.7894968 },
    { lat: 49.297786, lng: 18.7879136 },
    { lat: 49.2977662, lng: 18.7878842 },
    { lat: 49.2977517, lng: 18.7878632 },
    { lat: 49.2977052, lng: 18.7877915 },
    { lat: 49.2976916, lng: 18.7877704 },
    { lat: 49.2974456, lng: 18.7873955 },
    { lat: 49.2972501, lng: 18.7867533 },
    { lat: 49.2967075, lng: 18.7856843 },
    { lat: 49.2964248, lng: 18.7851668 },
    { lat: 49.2960655, lng: 18.7845416 },
    { lat: 49.2954923, lng: 18.783835 },
    { lat: 49.2951832, lng: 18.7835932 },
    { lat: 49.2945473, lng: 18.7834444 },
    { lat: 49.2936065, lng: 18.7829348 },
    { lat: 49.293545, lng: 18.7829011 },
    { lat: 49.2930252, lng: 18.7826194 },
    { lat: 49.2928711, lng: 18.7825358 },
    { lat: 49.2926691, lng: 18.7824266 },
    { lat: 49.2925405, lng: 18.7823573 },
    { lat: 49.2920438, lng: 18.782088 },
    { lat: 49.2917563, lng: 18.7819321 },
    { lat: 49.2899151, lng: 18.7809598 },
    { lat: 49.2892945, lng: 18.7805376 },
    { lat: 49.288914, lng: 18.7801755 },
    { lat: 49.2877011, lng: 18.7788335 },
    { lat: 49.287634, lng: 18.77876 },
    { lat: 49.287547, lng: 18.779014 },
    { lat: 49.2875127, lng: 18.7791591 },
    { lat: 49.2874572, lng: 18.7792835 },
    { lat: 49.2874481, lng: 18.7793034 },
    { lat: 49.2874226, lng: 18.7793777 },
    { lat: 49.2874089, lng: 18.7794183 },
    { lat: 49.2874081, lng: 18.779426 },
    { lat: 49.2873965, lng: 18.779483 },
    { lat: 49.2873895, lng: 18.7795222 },
    { lat: 49.287386, lng: 18.7795407 },
    { lat: 49.2873839, lng: 18.7795463 },
    { lat: 49.2873227, lng: 18.7798655 },
    { lat: 49.2872016, lng: 18.7802269 },
    { lat: 49.2869839, lng: 18.7805076 },
    { lat: 49.2865399, lng: 18.7810544 },
    { lat: 49.2862762, lng: 18.7814284 },
    { lat: 49.2860604, lng: 18.7820515 },
    { lat: 49.2859405, lng: 18.7823738 },
    { lat: 49.2855763, lng: 18.7824569 },
    { lat: 49.2853079, lng: 18.7825855 },
    { lat: 49.2851289, lng: 18.7828336 },
    { lat: 49.2847326, lng: 18.7831297 },
    { lat: 49.2844596, lng: 18.7834048 },
    { lat: 49.2841418, lng: 18.7835191 },
    { lat: 49.2840571, lng: 18.7836258 },
    { lat: 49.2837826, lng: 18.7833997 },
    { lat: 49.2835782, lng: 18.7834804 },
    { lat: 49.2833549, lng: 18.7835447 },
    { lat: 49.2830237, lng: 18.7833774 },
    { lat: 49.2828155, lng: 18.7832932 },
    { lat: 49.2824707, lng: 18.7831052 },
    { lat: 49.2822572, lng: 18.7832492 },
    { lat: 49.282117, lng: 18.7832196 },
    { lat: 49.2819313, lng: 18.7832056 },
    { lat: 49.2816753, lng: 18.783355 },
    { lat: 49.2812535, lng: 18.7833303 },
    { lat: 49.2808626, lng: 18.7832512 },
    { lat: 49.280469, lng: 18.7827733 },
    { lat: 49.2801598, lng: 18.7820917 },
    { lat: 49.2800571, lng: 18.7820236 },
    { lat: 49.2800179, lng: 18.7820287 },
    { lat: 49.2799065, lng: 18.7821639 },
    { lat: 49.2793421, lng: 18.7822832 },
    { lat: 49.2791349, lng: 18.7822245 },
    { lat: 49.2786651, lng: 18.782342 },
    { lat: 49.2782659, lng: 18.7827529 },
    { lat: 49.2778168, lng: 18.7830731 },
    { lat: 49.2772892, lng: 18.7833334 },
    { lat: 49.2766589, lng: 18.7833577 },
    { lat: 49.276158, lng: 18.783613 },
    { lat: 49.2762235, lng: 18.7840485 },
    { lat: 49.2757511, lng: 18.784248 },
    { lat: 49.2750026, lng: 18.7841833 },
    { lat: 49.2740282, lng: 18.7846175 },
    { lat: 49.2738018, lng: 18.7846846 },
    { lat: 49.2733217, lng: 18.7849489 },
    { lat: 49.2729835, lng: 18.7850613 },
    { lat: 49.2722799, lng: 18.7851891 },
    { lat: 49.2723121, lng: 18.7853318 },
    { lat: 49.2724157, lng: 18.7859194 },
    { lat: 49.2726526, lng: 18.7870116 },
    { lat: 49.272904, lng: 18.7883808 },
    { lat: 49.2732014, lng: 18.7901182 },
    { lat: 49.2738576, lng: 18.7934758 },
    { lat: 49.2737926, lng: 18.7935898 },
    { lat: 49.2737373, lng: 18.7947639 },
    { lat: 49.2738579, lng: 18.7958752 },
    { lat: 49.274913, lng: 18.7990347 },
    { lat: 49.2756541, lng: 18.8009818 },
    { lat: 49.2741736, lng: 18.8032491 },
    { lat: 49.2733631, lng: 18.8031649 },
    { lat: 49.2726113, lng: 18.8036095 },
    { lat: 49.2717021, lng: 18.8041481 },
    { lat: 49.2706917, lng: 18.8062616 },
    { lat: 49.2697644, lng: 18.8086095 },
    { lat: 49.2692076, lng: 18.8092478 },
    { lat: 49.2697855, lng: 18.8095682 },
    { lat: 49.2698698, lng: 18.8096042 },
    { lat: 49.2699989, lng: 18.8096605 },
    { lat: 49.270083, lng: 18.8096977 },
    { lat: 49.2701108, lng: 18.8097099 },
    { lat: 49.2700993, lng: 18.8099669 },
    { lat: 49.2700204, lng: 18.8117185 },
    { lat: 49.2700179, lng: 18.8117798 },
    { lat: 49.2700156, lng: 18.811822 },
    { lat: 49.268021, lng: 18.813117 },
    { lat: 49.268023, lng: 18.814005 },
    { lat: 49.267999, lng: 18.815081 },
  ],
  Rudina: [
    { lat: 49.2832724, lng: 18.7621621 },
    { lat: 49.2833072, lng: 18.7620936 },
    { lat: 49.2834644, lng: 18.7621044 },
    { lat: 49.2835255, lng: 18.7621082 },
    { lat: 49.2836002, lng: 18.7621132 },
    { lat: 49.2837479, lng: 18.7620902 },
    { lat: 49.2838269, lng: 18.762051 },
    { lat: 49.2839513, lng: 18.76199 },
    { lat: 49.2842031, lng: 18.7618652 },
    { lat: 49.2844571, lng: 18.7615934 },
    { lat: 49.2845014, lng: 18.7616635 },
    { lat: 49.2847554, lng: 18.7614495 },
    { lat: 49.2848311, lng: 18.761346 },
    { lat: 49.2848895, lng: 18.7611479 },
    { lat: 49.2850021, lng: 18.7611858 },
    { lat: 49.2850365, lng: 18.7611983 },
    { lat: 49.2852373, lng: 18.7612667 },
    { lat: 49.2855277, lng: 18.761407 },
    { lat: 49.2854813, lng: 18.7616871 },
    { lat: 49.2854958, lng: 18.7619201 },
    { lat: 49.285608, lng: 18.7620828 },
    { lat: 49.2858968, lng: 18.7620433 },
    { lat: 49.2861577, lng: 18.7621167 },
    { lat: 49.2862416, lng: 18.7618855 },
    { lat: 49.2862736, lng: 18.761506 },
    { lat: 49.286456, lng: 18.760674 },
    { lat: 49.2866775, lng: 18.7608811 },
    { lat: 49.2869574, lng: 18.7610786 },
    { lat: 49.2869873, lng: 18.7610038 },
    { lat: 49.287044, lng: 18.7608586 },
    { lat: 49.2870909, lng: 18.7607203 },
    { lat: 49.2871531, lng: 18.7603761 },
    { lat: 49.287462, lng: 18.7597679 },
    { lat: 49.2876932, lng: 18.7596162 },
    { lat: 49.2877544, lng: 18.7595156 },
    { lat: 49.2878402, lng: 18.759322 },
    { lat: 49.2880199, lng: 18.7588736 },
    { lat: 49.2881628, lng: 18.758547 },
    { lat: 49.2882578, lng: 18.7582877 },
    { lat: 49.2885393, lng: 18.7579363 },
    { lat: 49.288689, lng: 18.7576699 },
    { lat: 49.2888869, lng: 18.757337 },
    { lat: 49.2890061, lng: 18.7572434 },
    { lat: 49.2890685, lng: 18.757139 },
    { lat: 49.2891526, lng: 18.756894 },
    { lat: 49.289224, lng: 18.756726 },
    { lat: 49.289368, lng: 18.7566796 },
    { lat: 49.2895001, lng: 18.7565057 },
    { lat: 49.2896611, lng: 18.7562636 },
    { lat: 49.2897785, lng: 18.7558993 },
    { lat: 49.2900915, lng: 18.7554339 },
    { lat: 49.2902645, lng: 18.7551632 },
    { lat: 49.2904089, lng: 18.7547501 },
    { lat: 49.2904775, lng: 18.7544668 },
    { lat: 49.2905523, lng: 18.7540188 },
    { lat: 49.2907119, lng: 18.7535764 },
    { lat: 49.2907927, lng: 18.7534405 },
    { lat: 49.2908687, lng: 18.7533391 },
    { lat: 49.2909718, lng: 18.7531326 },
    { lat: 49.2910397, lng: 18.753029 },
    { lat: 49.2911601, lng: 18.7528962 },
    { lat: 49.2911905, lng: 18.7528708 },
    { lat: 49.2912159, lng: 18.7528189 },
    { lat: 49.2912824, lng: 18.7527534 },
    { lat: 49.2914255, lng: 18.7527284 },
    { lat: 49.2915084, lng: 18.7526739 },
    { lat: 49.29154, lng: 18.7525451 },
    { lat: 49.2915882, lng: 18.7524319 },
    { lat: 49.2916593, lng: 18.7523272 },
    { lat: 49.2917569, lng: 18.7521863 },
    { lat: 49.2917888, lng: 18.7521691 },
    { lat: 49.2918489, lng: 18.7521971 },
    { lat: 49.2920153, lng: 18.7522127 },
    { lat: 49.292089, lng: 18.7521762 },
    { lat: 49.2921843, lng: 18.7521863 },
    { lat: 49.2922602, lng: 18.7521926 },
    { lat: 49.2922948, lng: 18.7513232 },
    { lat: 49.2923072, lng: 18.7510388 },
    { lat: 49.2925027, lng: 18.750764 },
    { lat: 49.2926198, lng: 18.7500279 },
    { lat: 49.2927916, lng: 18.7494286 },
    { lat: 49.2926938, lng: 18.7493029 },
    { lat: 49.2926684, lng: 18.749272 },
    { lat: 49.2929543, lng: 18.7487188 },
    { lat: 49.2929757, lng: 18.7486772 },
    { lat: 49.2935329, lng: 18.7475982 },
    { lat: 49.2937645, lng: 18.7472053 },
    { lat: 49.2944002, lng: 18.7460463 },
    { lat: 49.2945762, lng: 18.7457067 },
    { lat: 49.2951615, lng: 18.7445771 },
    { lat: 49.2952772, lng: 18.7443542 },
    { lat: 49.295395, lng: 18.744148 },
    { lat: 49.2955602, lng: 18.7439404 },
    { lat: 49.2956886, lng: 18.7438286 },
    { lat: 49.2958385, lng: 18.7437392 },
    { lat: 49.2960408, lng: 18.743686 },
    { lat: 49.2963401, lng: 18.7436946 },
    { lat: 49.296301, lng: 18.7430303 },
    { lat: 49.2962096, lng: 18.7421028 },
    { lat: 49.2961015, lng: 18.7415651 },
    { lat: 49.2961658, lng: 18.7410677 },
    { lat: 49.2962377, lng: 18.740521 },
    { lat: 49.2959433, lng: 18.7391995 },
    { lat: 49.295758, lng: 18.737876 },
    { lat: 49.296077, lng: 18.7355407 },
    { lat: 49.2960505, lng: 18.735337 },
    { lat: 49.2962616, lng: 18.7349308 },
    { lat: 49.2962923, lng: 18.7345693 },
    { lat: 49.2965628, lng: 18.7343922 },
    { lat: 49.2967328, lng: 18.7342804 },
    { lat: 49.29829, lng: 18.7298362 },
    { lat: 49.298434, lng: 18.7296561 },
    { lat: 49.299285, lng: 18.7290668 },
    { lat: 49.2994462, lng: 18.7287837 },
    { lat: 49.2998624, lng: 18.7280518 },
    { lat: 49.3001452, lng: 18.7275548 },
    { lat: 49.3000377, lng: 18.7269002 },
    { lat: 49.3001052, lng: 18.7269378 },
    { lat: 49.3003596, lng: 18.7265436 },
    { lat: 49.3003476, lng: 18.7264186 },
    { lat: 49.3003224, lng: 18.7262837 },
    { lat: 49.300633, lng: 18.7261647 },
    { lat: 49.3006972, lng: 18.7261403 },
    { lat: 49.3008663, lng: 18.7260752 },
    { lat: 49.30137, lng: 18.7259391 },
    { lat: 49.3020851, lng: 18.7254474 },
    { lat: 49.3025652, lng: 18.7253977 },
    { lat: 49.3024694, lng: 18.7251608 },
    { lat: 49.3024639, lng: 18.7251456 },
    { lat: 49.3024418, lng: 18.7250673 },
    { lat: 49.3024246, lng: 18.7250413 },
    { lat: 49.3027278, lng: 18.7248878 },
    { lat: 49.3026496, lng: 18.7244443 },
    { lat: 49.3025361, lng: 18.723806 },
    { lat: 49.3024594, lng: 18.7233756 },
    { lat: 49.3023637, lng: 18.7229685 },
    { lat: 49.3023154, lng: 18.7227356 },
    { lat: 49.3022197, lng: 18.7225648 },
    { lat: 49.3021021, lng: 18.7224079 },
    { lat: 49.3025518, lng: 18.7221007 },
    { lat: 49.3025615, lng: 18.7221837 },
    { lat: 49.3027769, lng: 18.7221176 },
    { lat: 49.3028827, lng: 18.722113 },
    { lat: 49.3029873, lng: 18.7221742 },
    { lat: 49.3030706, lng: 18.7221727 },
    { lat: 49.3032029, lng: 18.7221536 },
    { lat: 49.3032779, lng: 18.7221231 },
    { lat: 49.3031789, lng: 18.7214599 },
    { lat: 49.3029574, lng: 18.7210613 },
    { lat: 49.3028267, lng: 18.7208074 },
    { lat: 49.3027365, lng: 18.7204611 },
    { lat: 49.3025817, lng: 18.7207124 },
    { lat: 49.3025403, lng: 18.720699 },
    { lat: 49.3024485, lng: 18.7204981 },
    { lat: 49.3023999, lng: 18.7203003 },
    { lat: 49.3023466, lng: 18.7201225 },
    { lat: 49.3023206, lng: 18.7199913 },
    { lat: 49.3022696, lng: 18.7198563 },
    { lat: 49.3021308, lng: 18.7189287 },
    { lat: 49.3020395, lng: 18.7186291 },
    { lat: 49.3019269, lng: 18.7181121 },
    { lat: 49.3018819, lng: 18.7177715 },
    { lat: 49.3018745, lng: 18.717608 },
    { lat: 49.3016646, lng: 18.7174346 },
    { lat: 49.3015159, lng: 18.7174156 },
    { lat: 49.3013875, lng: 18.7172871 },
    { lat: 49.3013839, lng: 18.7171336 },
    { lat: 49.3013215, lng: 18.7169295 },
    { lat: 49.3012073, lng: 18.7168597 },
    { lat: 49.3012093, lng: 18.716664 },
    { lat: 49.3013163, lng: 18.7164648 },
    { lat: 49.3014615, lng: 18.7164391 },
    { lat: 49.301564, lng: 18.7161147 },
    { lat: 49.3016698, lng: 18.7159199 },
    { lat: 49.3018501, lng: 18.7152916 },
    { lat: 49.301731, lng: 18.7151482 },
    { lat: 49.301502, lng: 18.7149754 },
    { lat: 49.3014545, lng: 18.7149946 },
    { lat: 49.3011249, lng: 18.7147741 },
    { lat: 49.3011154, lng: 18.7145064 },
    { lat: 49.3010862, lng: 18.7142285 },
    { lat: 49.3010471, lng: 18.713997 },
    { lat: 49.300933, lng: 18.7135412 },
    { lat: 49.3008929, lng: 18.7132278 },
    { lat: 49.3008533, lng: 18.712643 },
    { lat: 49.3008083, lng: 18.7123027 },
    { lat: 49.3007581, lng: 18.7121379 },
    { lat: 49.3007319, lng: 18.7115746 },
    { lat: 49.3006705, lng: 18.7113964 },
    { lat: 49.3005566, lng: 18.7110938 },
    { lat: 49.3004244, lng: 18.7110244 },
    { lat: 49.3003133, lng: 18.7109914 },
    { lat: 49.3001543, lng: 18.7110549 },
    { lat: 49.2997743, lng: 18.7111174 },
    { lat: 49.299637, lng: 18.711039 },
    { lat: 49.299136, lng: 18.711698 },
    { lat: 49.299077, lng: 18.711409 },
    { lat: 49.298764, lng: 18.710757 },
    { lat: 49.298613, lng: 18.710528 },
    { lat: 49.298426, lng: 18.710047 },
    { lat: 49.298415, lng: 18.709835 },
    { lat: 49.298229, lng: 18.709706 },
    { lat: 49.2976, lng: 18.709913 },
    { lat: 49.297402, lng: 18.709815 },
    { lat: 49.297327, lng: 18.709669 },
    { lat: 49.297212, lng: 18.709552 },
    { lat: 49.297002, lng: 18.709674 },
    { lat: 49.296503, lng: 18.70968 },
    { lat: 49.296296, lng: 18.709627 },
    { lat: 49.296135, lng: 18.709606 },
    { lat: 49.296296, lng: 18.710427 },
    { lat: 49.296334, lng: 18.710513 },
    { lat: 49.296435, lng: 18.711136 },
    { lat: 49.296544, lng: 18.711423 },
    { lat: 49.2962, lng: 18.712356 },
    { lat: 49.296171, lng: 18.712557 },
    { lat: 49.29607, lng: 18.712583 },
    { lat: 49.295712, lng: 18.713006 },
    { lat: 49.29568, lng: 18.71312 },
    { lat: 49.295543, lng: 18.713894 },
    { lat: 49.295277, lng: 18.714147 },
    { lat: 49.295066, lng: 18.713906 },
    { lat: 49.295008, lng: 18.713773 },
    { lat: 49.294976, lng: 18.7137 },
    { lat: 49.294912, lng: 18.713606 },
    { lat: 49.294837, lng: 18.713459 },
    { lat: 49.294675, lng: 18.713049 },
    { lat: 49.294492, lng: 18.712816 },
    { lat: 49.294477, lng: 18.712818 },
    { lat: 49.294175, lng: 18.712849 },
    { lat: 49.293826, lng: 18.712989 },
    { lat: 49.293969, lng: 18.7135 },
    { lat: 49.293869, lng: 18.713591 },
    { lat: 49.29347, lng: 18.713799 },
    { lat: 49.293491, lng: 18.714028 },
    { lat: 49.293191, lng: 18.714175 },
    { lat: 49.292875, lng: 18.714392 },
    { lat: 49.292586, lng: 18.714636 },
    { lat: 49.292373, lng: 18.714796 },
    { lat: 49.292666, lng: 18.715473 },
    { lat: 49.292423, lng: 18.715976 },
    { lat: 49.292209, lng: 18.716082 },
    { lat: 49.291957, lng: 18.716043 },
    { lat: 49.291742, lng: 18.71598 },
    { lat: 49.291485, lng: 18.715915 },
    { lat: 49.291441, lng: 18.715893 },
    { lat: 49.291376, lng: 18.715851 },
    { lat: 49.291245, lng: 18.715687 },
    { lat: 49.290854, lng: 18.715124 },
    { lat: 49.290664, lng: 18.715326 },
    { lat: 49.290296, lng: 18.715696 },
    { lat: 49.290017, lng: 18.716147 },
    { lat: 49.289773, lng: 18.716471 },
    { lat: 49.289574, lng: 18.716687 },
    { lat: 49.289386, lng: 18.716796 },
    { lat: 49.289285, lng: 18.717044 },
    { lat: 49.289029, lng: 18.716965 },
    { lat: 49.288711, lng: 18.71653 },
    { lat: 49.288219, lng: 18.716577 },
    { lat: 49.287991, lng: 18.716981 },
    { lat: 49.287958, lng: 18.717041 },
    { lat: 49.287941, lng: 18.717072 },
    { lat: 49.287425, lng: 18.717246 },
    { lat: 49.287385, lng: 18.717563 },
    { lat: 49.287371, lng: 18.717768 },
    { lat: 49.287273, lng: 18.71767 },
    { lat: 49.286925, lng: 18.717138 },
    { lat: 49.286663, lng: 18.717541 },
    { lat: 49.286505, lng: 18.718071 },
    { lat: 49.286585, lng: 18.718428 },
    { lat: 49.286618, lng: 18.718651 },
    { lat: 49.28672, lng: 18.718721 },
    { lat: 49.286683, lng: 18.719111 },
    { lat: 49.286668, lng: 18.719125 },
    { lat: 49.286644, lng: 18.719452 },
    { lat: 49.286677, lng: 18.719571 },
    { lat: 49.28669, lng: 18.719941 },
    { lat: 49.286656, lng: 18.720009 },
    { lat: 49.286646, lng: 18.720026 },
    { lat: 49.286631, lng: 18.719912 },
    { lat: 49.286507, lng: 18.719718 },
    { lat: 49.286317, lng: 18.719526 },
    { lat: 49.286222, lng: 18.719489 },
    { lat: 49.286209, lng: 18.719421 },
    { lat: 49.286194, lng: 18.719397 },
    { lat: 49.28617, lng: 18.719403 },
    { lat: 49.286111, lng: 18.71949 },
    { lat: 49.285851, lng: 18.719519 },
    { lat: 49.285777, lng: 18.719434 },
    { lat: 49.285665, lng: 18.719351 },
    { lat: 49.285671, lng: 18.719106 },
    { lat: 49.285601, lng: 18.718948 },
    { lat: 49.285229, lng: 18.718606 },
    { lat: 49.285164, lng: 18.71861 },
    { lat: 49.28515, lng: 18.718623 },
    { lat: 49.285112, lng: 18.718571 },
    { lat: 49.28511, lng: 18.718533 },
    { lat: 49.285076, lng: 18.718442 },
    { lat: 49.285062, lng: 18.718482 },
    { lat: 49.285043, lng: 18.718502 },
    { lat: 49.285026, lng: 18.718474 },
    { lat: 49.284993, lng: 18.718394 },
    { lat: 49.284896, lng: 18.718517 },
    { lat: 49.284696, lng: 18.718399 },
    { lat: 49.284564, lng: 18.718396 },
    { lat: 49.284457, lng: 18.718453 },
    { lat: 49.284088, lng: 18.718567 },
    { lat: 49.283822, lng: 18.718276 },
    { lat: 49.283676, lng: 18.718374 },
    { lat: 49.283511, lng: 18.718468 },
    { lat: 49.283292, lng: 18.718624 },
    { lat: 49.283162, lng: 18.719409 },
    { lat: 49.283154, lng: 18.719458 },
    { lat: 49.28316, lng: 18.7198 },
    { lat: 49.282984, lng: 18.720309 },
    { lat: 49.282998, lng: 18.720639 },
    { lat: 49.28279, lng: 18.720921 },
    { lat: 49.28266, lng: 18.721252 },
    { lat: 49.282502, lng: 18.721637 },
    { lat: 49.282359, lng: 18.721911 },
    { lat: 49.281785, lng: 18.72212 },
    { lat: 49.281786, lng: 18.722802 },
    { lat: 49.281699, lng: 18.723384 },
    { lat: 49.281502, lng: 18.723249 },
    { lat: 49.281399, lng: 18.723121 },
    { lat: 49.281319, lng: 18.7229 },
    { lat: 49.281319, lng: 18.722615 },
    { lat: 49.28122, lng: 18.722379 },
    { lat: 49.281181, lng: 18.72231 },
    { lat: 49.281139, lng: 18.722189 },
    { lat: 49.281013, lng: 18.722008 },
    { lat: 49.280833, lng: 18.721792 },
    { lat: 49.280783, lng: 18.721748 },
    { lat: 49.280717, lng: 18.721633 },
    { lat: 49.280694, lng: 18.721581 },
    { lat: 49.280299, lng: 18.720891 },
    { lat: 49.280155, lng: 18.720713 },
    { lat: 49.280028, lng: 18.721107 },
    { lat: 49.279883, lng: 18.721291 },
    { lat: 49.279874, lng: 18.721883 },
    { lat: 49.279831, lng: 18.721979 },
    { lat: 49.279729, lng: 18.722536 },
    { lat: 49.279678, lng: 18.72261 },
    { lat: 49.279365, lng: 18.722393 },
    { lat: 49.279346, lng: 18.722346 },
    { lat: 49.278897, lng: 18.722284 },
    { lat: 49.278584, lng: 18.722385 },
    { lat: 49.278222, lng: 18.722151 },
    { lat: 49.278143, lng: 18.722101 },
    { lat: 49.277874, lng: 18.721928 },
    { lat: 49.277767, lng: 18.722525 },
    { lat: 49.277777, lng: 18.723441 },
    { lat: 49.276968, lng: 18.723952 },
    { lat: 49.277032, lng: 18.724464 },
    { lat: 49.277039, lng: 18.724479 },
    { lat: 49.277047, lng: 18.7245 },
    { lat: 49.27706, lng: 18.724524 },
    { lat: 49.276962, lng: 18.724569 },
    { lat: 49.27666, lng: 18.724505 },
    { lat: 49.276524, lng: 18.724256 },
    { lat: 49.2765, lng: 18.724245 },
    { lat: 49.276482, lng: 18.724243 },
    { lat: 49.276392, lng: 18.724156 },
    { lat: 49.276335, lng: 18.724147 },
    { lat: 49.276212, lng: 18.724032 },
    { lat: 49.276055, lng: 18.723957 },
    { lat: 49.275921, lng: 18.724089 },
    { lat: 49.275866, lng: 18.72403 },
    { lat: 49.27581, lng: 18.724062 },
    { lat: 49.275736, lng: 18.724015 },
    { lat: 49.275719, lng: 18.723963 },
    { lat: 49.275601, lng: 18.723857 },
    { lat: 49.275544, lng: 18.723809 },
    { lat: 49.275453, lng: 18.723818 },
    { lat: 49.275378, lng: 18.723844 },
    { lat: 49.275344, lng: 18.723864 },
    { lat: 49.275327, lng: 18.723842 },
    { lat: 49.275273, lng: 18.723866 },
    { lat: 49.275182, lng: 18.723926 },
    { lat: 49.275106, lng: 18.723996 },
    { lat: 49.274934, lng: 18.724008 },
    { lat: 49.274896, lng: 18.724015 },
    { lat: 49.274852, lng: 18.724079 },
    { lat: 49.274853, lng: 18.724151 },
    { lat: 49.27475, lng: 18.724254 },
    { lat: 49.274664, lng: 18.724237 },
    { lat: 49.27456, lng: 18.724353 },
    { lat: 49.274384, lng: 18.724436 },
    { lat: 49.274374, lng: 18.724461 },
    { lat: 49.274375, lng: 18.724486 },
    { lat: 49.274377, lng: 18.724498 },
    { lat: 49.273591, lng: 18.724639 },
    { lat: 49.2737221, lng: 18.7248635 },
    { lat: 49.2748496, lng: 18.7265635 },
    { lat: 49.275254, lng: 18.7280843 },
    { lat: 49.2757087, lng: 18.7292556 },
    { lat: 49.2757171, lng: 18.7292845 },
    { lat: 49.2760455, lng: 18.7303452 },
    { lat: 49.276185, lng: 18.7308811 },
    { lat: 49.2763231, lng: 18.7317963 },
    { lat: 49.2763323, lng: 18.7318638 },
    { lat: 49.276443, lng: 18.73273 },
    { lat: 49.2769141, lng: 18.7340207 },
    { lat: 49.2772635, lng: 18.7359828 },
    { lat: 49.277272, lng: 18.7360153 },
    { lat: 49.2773028, lng: 18.736129 },
    { lat: 49.2774099, lng: 18.7365204 },
    { lat: 49.2774736, lng: 18.737803 },
    { lat: 49.2779641, lng: 18.7388389 },
    { lat: 49.278644, lng: 18.7399403 },
    { lat: 49.2786747, lng: 18.73999 },
    { lat: 49.2785418, lng: 18.740337 },
    { lat: 49.2787475, lng: 18.740521 },
    { lat: 49.279074, lng: 18.7408987 },
    { lat: 49.2791747, lng: 18.7410143 },
    { lat: 49.2804382, lng: 18.7424736 },
    { lat: 49.2811471, lng: 18.7439995 },
    { lat: 49.2811518, lng: 18.7440271 },
    { lat: 49.2811587, lng: 18.7440725 },
    { lat: 49.2811787, lng: 18.7441943 },
    { lat: 49.2813122, lng: 18.7450058 },
    { lat: 49.2800165, lng: 18.7521193 },
    { lat: 49.2800061, lng: 18.752178 },
    { lat: 49.2799552, lng: 18.7524576 },
    { lat: 49.2799419, lng: 18.75253 },
    { lat: 49.2799157, lng: 18.7526744 },
    { lat: 49.2799051, lng: 18.7527306 },
    { lat: 49.2799034, lng: 18.7527424 },
    { lat: 49.2798464, lng: 18.7530549 },
    { lat: 49.2797953, lng: 18.7533298 },
    { lat: 49.2797946, lng: 18.7533353 },
    { lat: 49.2797883, lng: 18.7533682 },
    { lat: 49.2797694, lng: 18.7534597 },
    { lat: 49.2799389, lng: 18.7537457 },
    { lat: 49.2800326, lng: 18.7539037 },
    { lat: 49.280066, lng: 18.7539599 },
    { lat: 49.2801922, lng: 18.7541725 },
    { lat: 49.2804849, lng: 18.7542716 },
    { lat: 49.2805261, lng: 18.7542601 },
    { lat: 49.2805505, lng: 18.7542528 },
    { lat: 49.2805971, lng: 18.7542392 },
    { lat: 49.280696, lng: 18.7542105 },
    { lat: 49.2807337, lng: 18.7541994 },
    { lat: 49.2807736, lng: 18.7541852 },
    { lat: 49.2808244, lng: 18.7541667 },
    { lat: 49.2809462, lng: 18.754123 },
    { lat: 49.2809506, lng: 18.7541214 },
    { lat: 49.2809606, lng: 18.754118 },
    { lat: 49.2809617, lng: 18.7541175 },
    { lat: 49.2809741, lng: 18.7541146 },
    { lat: 49.2810686, lng: 18.7540915 },
    { lat: 49.2810819, lng: 18.7540881 },
    { lat: 49.2811335, lng: 18.7540815 },
    { lat: 49.2812086, lng: 18.754072 },
    { lat: 49.2812208, lng: 18.7540705 },
    { lat: 49.2812523, lng: 18.7540659 },
    { lat: 49.2812858, lng: 18.7540618 },
    { lat: 49.2814011, lng: 18.7540467 },
    { lat: 49.2815232, lng: 18.7540517 },
    { lat: 49.2815414, lng: 18.7540553 },
    { lat: 49.2815677, lng: 18.7540608 },
    { lat: 49.2816145, lng: 18.7540706 },
    { lat: 49.2817055, lng: 18.7540895 },
    { lat: 49.2818538, lng: 18.7541199 },
    { lat: 49.2818808, lng: 18.7541386 },
    { lat: 49.2819251, lng: 18.7541694 },
    { lat: 49.2819614, lng: 18.7541949 },
    { lat: 49.2819637, lng: 18.7541968 },
    { lat: 49.2819884, lng: 18.7542137 },
    { lat: 49.2820591, lng: 18.7542325 },
    { lat: 49.2821061, lng: 18.7542452 },
    { lat: 49.282137, lng: 18.7542537 },
    { lat: 49.2821473, lng: 18.7542561 },
    { lat: 49.2823668, lng: 18.7543157 },
    { lat: 49.282368, lng: 18.7543159 },
    { lat: 49.2823759, lng: 18.7543182 },
    { lat: 49.2823817, lng: 18.7543197 },
    { lat: 49.2823862, lng: 18.7543213 },
    { lat: 49.2823465, lng: 18.7557629 },
    { lat: 49.2823356, lng: 18.756185 },
    { lat: 49.2822658, lng: 18.7565955 },
    { lat: 49.2821729, lng: 18.7569127 },
    { lat: 49.2819982, lng: 18.7573063 },
    { lat: 49.2819599, lng: 18.7573698 },
    { lat: 49.2819473, lng: 18.7573912 },
    { lat: 49.2818333, lng: 18.7575835 },
    { lat: 49.2818002, lng: 18.7576392 },
    { lat: 49.2817524, lng: 18.7577183 },
    { lat: 49.2816757, lng: 18.7578476 },
    { lat: 49.2815627, lng: 18.7580368 },
    { lat: 49.2815587, lng: 18.7580456 },
    { lat: 49.2813791, lng: 18.7583459 },
    { lat: 49.2813212, lng: 18.758445 },
    { lat: 49.2813532, lng: 18.7584744 },
    { lat: 49.2813746, lng: 18.7584945 },
    { lat: 49.2814016, lng: 18.7585198 },
    { lat: 49.2814446, lng: 18.7585648 },
    { lat: 49.2814602, lng: 18.7585824 },
    { lat: 49.2816647, lng: 18.7587847 },
    { lat: 49.281746, lng: 18.7588716 },
    { lat: 49.2819496, lng: 18.7591026 },
    { lat: 49.281994, lng: 18.7591329 },
    { lat: 49.2821673, lng: 18.7592993 },
    { lat: 49.2821969, lng: 18.7593275 },
    { lat: 49.282223, lng: 18.7593518 },
    { lat: 49.2827027, lng: 18.7598388 },
    { lat: 49.2827958, lng: 18.7599337 },
    { lat: 49.2831206, lng: 18.7602652 },
    { lat: 49.2831181, lng: 18.7602803 },
    { lat: 49.2830986, lng: 18.7603827 },
    { lat: 49.2830978, lng: 18.7603897 },
    { lat: 49.2830892, lng: 18.7604369 },
    { lat: 49.2830865, lng: 18.7604532 },
    { lat: 49.2830663, lng: 18.7605607 },
    { lat: 49.2830335, lng: 18.7607348 },
    { lat: 49.2829814, lng: 18.7610135 },
    { lat: 49.2829594, lng: 18.7611314 },
    { lat: 49.2829401, lng: 18.7612377 },
    { lat: 49.2829182, lng: 18.7613563 },
    { lat: 49.2829084, lng: 18.7614081 },
    { lat: 49.2829048, lng: 18.7614255 },
    { lat: 49.2828573, lng: 18.7616808 },
    { lat: 49.2828995, lng: 18.7617151 },
    { lat: 49.2832724, lng: 18.7621621 },
  ],
  Rudinka: [
    { lat: 49.265828, lng: 18.750369 },
    { lat: 49.2658152, lng: 18.7502611 },
    { lat: 49.266123, lng: 18.750496 },
    { lat: 49.266713, lng: 18.75075 },
    { lat: 49.267472, lng: 18.751076 },
    { lat: 49.267483, lng: 18.751081 },
    { lat: 49.268065, lng: 18.751323 },
    { lat: 49.268153, lng: 18.75136 },
    { lat: 49.268328, lng: 18.751564 },
    { lat: 49.270393, lng: 18.754674 },
    { lat: 49.270538, lng: 18.754893 },
    { lat: 49.27054, lng: 18.754892 },
    { lat: 49.271066, lng: 18.754509 },
    { lat: 49.27111, lng: 18.754476 },
    { lat: 49.271112, lng: 18.754477 },
    { lat: 49.271116, lng: 18.754479 },
    { lat: 49.271496, lng: 18.754652 },
    { lat: 49.271724, lng: 18.754799 },
    { lat: 49.272238, lng: 18.754974 },
    { lat: 49.272334, lng: 18.755012 },
    { lat: 49.273052, lng: 18.755296 },
    { lat: 49.273078, lng: 18.755309 },
    { lat: 49.27326, lng: 18.755404 },
    { lat: 49.273831, lng: 18.755702 },
    { lat: 49.27402, lng: 18.755908 },
    { lat: 49.274164, lng: 18.756065 },
    { lat: 49.274454, lng: 18.756171 },
    { lat: 49.274699, lng: 18.756261 },
    { lat: 49.275014, lng: 18.756464 },
    { lat: 49.275097, lng: 18.756516 },
    { lat: 49.275226, lng: 18.756596 },
    { lat: 49.275762, lng: 18.757085 },
    { lat: 49.275892, lng: 18.757252 },
    { lat: 49.276229, lng: 18.757685 },
    { lat: 49.276438, lng: 18.758076 },
    { lat: 49.277144, lng: 18.758582 },
    { lat: 49.277632, lng: 18.759369 },
    { lat: 49.277634, lng: 18.759373 },
    { lat: 49.27799, lng: 18.759971 },
    { lat: 49.277821, lng: 18.760261 },
    { lat: 49.277802, lng: 18.760293 },
    { lat: 49.27757, lng: 18.76069 },
    { lat: 49.277643, lng: 18.7606978 },
    { lat: 49.2776734, lng: 18.760696 },
    { lat: 49.2777811, lng: 18.7608591 },
    { lat: 49.2779644, lng: 18.7611348 },
    { lat: 49.2782253, lng: 18.7612493 },
    { lat: 49.2782298, lng: 18.761251 },
    { lat: 49.2783798, lng: 18.7613577 },
    { lat: 49.2788007, lng: 18.7617789 },
    { lat: 49.2788673, lng: 18.7618453 },
    { lat: 49.2789839, lng: 18.7619618 },
    { lat: 49.2790458, lng: 18.7620244 },
    { lat: 49.2790804, lng: 18.7620584 },
    { lat: 49.2792436, lng: 18.7622066 },
    { lat: 49.2793767, lng: 18.7622261 },
    { lat: 49.2802646, lng: 18.7628673 },
    { lat: 49.2802681, lng: 18.7629106 },
    { lat: 49.2802783, lng: 18.7630668 },
    { lat: 49.2809937, lng: 18.7633151 },
    { lat: 49.2809911, lng: 18.7633736 },
    { lat: 49.2812633, lng: 18.7644009 },
    { lat: 49.2814176, lng: 18.7648751 },
    { lat: 49.2814728, lng: 18.7650454 },
    { lat: 49.2816687, lng: 18.7652376 },
    { lat: 49.2817746, lng: 18.7653425 },
    { lat: 49.2826527, lng: 18.7662085 },
    { lat: 49.2826617, lng: 18.7661626 },
    { lat: 49.2827632, lng: 18.7656197 },
    { lat: 49.2828032, lng: 18.7654126 },
    { lat: 49.2828206, lng: 18.7653144 },
    { lat: 49.2828314, lng: 18.7646099 },
    { lat: 49.2829724, lng: 18.7634388 },
    { lat: 49.2829936, lng: 18.7632618 },
    { lat: 49.2830216, lng: 18.7631493 },
    { lat: 49.2830791, lng: 18.7629108 },
    { lat: 49.2830929, lng: 18.7628528 },
    { lat: 49.2831116, lng: 18.7627768 },
    { lat: 49.283118, lng: 18.7627508 },
    { lat: 49.2831311, lng: 18.7626968 },
    { lat: 49.2831458, lng: 18.762634 },
    { lat: 49.2831496, lng: 18.762622 },
    { lat: 49.2831505, lng: 18.7626161 },
    { lat: 49.2831609, lng: 18.7625744 },
    { lat: 49.2831653, lng: 18.7625558 },
    { lat: 49.2831693, lng: 18.7625412 },
    { lat: 49.283172, lng: 18.76253 },
    { lat: 49.2831929, lng: 18.7624542 },
    { lat: 49.2832241, lng: 18.7623417 },
    { lat: 49.2832326, lng: 18.7623089 },
    { lat: 49.2832373, lng: 18.7622902 },
    { lat: 49.2832431, lng: 18.76227 },
    { lat: 49.2832544, lng: 18.7622282 },
    { lat: 49.2832543, lng: 18.7622268 },
    { lat: 49.2832571, lng: 18.762217 },
    { lat: 49.2832646, lng: 18.762189 },
    { lat: 49.2832724, lng: 18.7621621 },
    { lat: 49.2828995, lng: 18.7617151 },
    { lat: 49.2828573, lng: 18.7616808 },
    { lat: 49.2829048, lng: 18.7614255 },
    { lat: 49.2829084, lng: 18.7614081 },
    { lat: 49.2829182, lng: 18.7613563 },
    { lat: 49.2829401, lng: 18.7612377 },
    { lat: 49.2829594, lng: 18.7611314 },
    { lat: 49.2829814, lng: 18.7610135 },
    { lat: 49.2830335, lng: 18.7607348 },
    { lat: 49.2830663, lng: 18.7605607 },
    { lat: 49.2830865, lng: 18.7604532 },
    { lat: 49.2830892, lng: 18.7604369 },
    { lat: 49.2830978, lng: 18.7603897 },
    { lat: 49.2830986, lng: 18.7603827 },
    { lat: 49.2831181, lng: 18.7602803 },
    { lat: 49.2831206, lng: 18.7602652 },
    { lat: 49.2827958, lng: 18.7599337 },
    { lat: 49.2827027, lng: 18.7598388 },
    { lat: 49.282223, lng: 18.7593518 },
    { lat: 49.2821969, lng: 18.7593275 },
    { lat: 49.2821673, lng: 18.7592993 },
    { lat: 49.281994, lng: 18.7591329 },
    { lat: 49.2819496, lng: 18.7591026 },
    { lat: 49.281746, lng: 18.7588716 },
    { lat: 49.2816647, lng: 18.7587847 },
    { lat: 49.2814602, lng: 18.7585824 },
    { lat: 49.2814446, lng: 18.7585648 },
    { lat: 49.2814016, lng: 18.7585198 },
    { lat: 49.2813746, lng: 18.7584945 },
    { lat: 49.2813532, lng: 18.7584744 },
    { lat: 49.2813212, lng: 18.758445 },
    { lat: 49.2813791, lng: 18.7583459 },
    { lat: 49.2815587, lng: 18.7580456 },
    { lat: 49.2815627, lng: 18.7580368 },
    { lat: 49.2816757, lng: 18.7578476 },
    { lat: 49.2817524, lng: 18.7577183 },
    { lat: 49.2818002, lng: 18.7576392 },
    { lat: 49.2818333, lng: 18.7575835 },
    { lat: 49.2819473, lng: 18.7573912 },
    { lat: 49.2819599, lng: 18.7573698 },
    { lat: 49.2819982, lng: 18.7573063 },
    { lat: 49.2821729, lng: 18.7569127 },
    { lat: 49.2822658, lng: 18.7565955 },
    { lat: 49.2823356, lng: 18.756185 },
    { lat: 49.2823465, lng: 18.7557629 },
    { lat: 49.2823862, lng: 18.7543213 },
    { lat: 49.2823817, lng: 18.7543197 },
    { lat: 49.2823759, lng: 18.7543182 },
    { lat: 49.282368, lng: 18.7543159 },
    { lat: 49.2823668, lng: 18.7543157 },
    { lat: 49.2821473, lng: 18.7542561 },
    { lat: 49.282137, lng: 18.7542537 },
    { lat: 49.2821061, lng: 18.7542452 },
    { lat: 49.2820591, lng: 18.7542325 },
    { lat: 49.2819884, lng: 18.7542137 },
    { lat: 49.2819637, lng: 18.7541968 },
    { lat: 49.2819614, lng: 18.7541949 },
    { lat: 49.2819251, lng: 18.7541694 },
    { lat: 49.2818808, lng: 18.7541386 },
    { lat: 49.2818538, lng: 18.7541199 },
    { lat: 49.2817055, lng: 18.7540895 },
    { lat: 49.2816145, lng: 18.7540706 },
    { lat: 49.2815677, lng: 18.7540608 },
    { lat: 49.2815414, lng: 18.7540553 },
    { lat: 49.2815232, lng: 18.7540517 },
    { lat: 49.2814011, lng: 18.7540467 },
    { lat: 49.2812858, lng: 18.7540618 },
    { lat: 49.2812523, lng: 18.7540659 },
    { lat: 49.2812208, lng: 18.7540705 },
    { lat: 49.2812086, lng: 18.754072 },
    { lat: 49.2811335, lng: 18.7540815 },
    { lat: 49.2810819, lng: 18.7540881 },
    { lat: 49.2810686, lng: 18.7540915 },
    { lat: 49.2809741, lng: 18.7541146 },
    { lat: 49.2809617, lng: 18.7541175 },
    { lat: 49.2809606, lng: 18.754118 },
    { lat: 49.2809506, lng: 18.7541214 },
    { lat: 49.2809462, lng: 18.754123 },
    { lat: 49.2808244, lng: 18.7541667 },
    { lat: 49.2807736, lng: 18.7541852 },
    { lat: 49.2807337, lng: 18.7541994 },
    { lat: 49.280696, lng: 18.7542105 },
    { lat: 49.2805971, lng: 18.7542392 },
    { lat: 49.2805505, lng: 18.7542528 },
    { lat: 49.2805261, lng: 18.7542601 },
    { lat: 49.2804849, lng: 18.7542716 },
    { lat: 49.2801922, lng: 18.7541725 },
    { lat: 49.280066, lng: 18.7539599 },
    { lat: 49.2800326, lng: 18.7539037 },
    { lat: 49.2799389, lng: 18.7537457 },
    { lat: 49.2797694, lng: 18.7534597 },
    { lat: 49.2797883, lng: 18.7533682 },
    { lat: 49.2797946, lng: 18.7533353 },
    { lat: 49.2797953, lng: 18.7533298 },
    { lat: 49.2798464, lng: 18.7530549 },
    { lat: 49.2799034, lng: 18.7527424 },
    { lat: 49.2799051, lng: 18.7527306 },
    { lat: 49.2799157, lng: 18.7526744 },
    { lat: 49.2799419, lng: 18.75253 },
    { lat: 49.2799552, lng: 18.7524576 },
    { lat: 49.2800061, lng: 18.752178 },
    { lat: 49.2800165, lng: 18.7521193 },
    { lat: 49.2813122, lng: 18.7450058 },
    { lat: 49.2811787, lng: 18.7441943 },
    { lat: 49.2811587, lng: 18.7440725 },
    { lat: 49.2811518, lng: 18.7440271 },
    { lat: 49.2811471, lng: 18.7439995 },
    { lat: 49.2804382, lng: 18.7424736 },
    { lat: 49.2791747, lng: 18.7410143 },
    { lat: 49.279074, lng: 18.7408987 },
    { lat: 49.2787475, lng: 18.740521 },
    { lat: 49.2785418, lng: 18.740337 },
    { lat: 49.2786747, lng: 18.73999 },
    { lat: 49.278644, lng: 18.7399403 },
    { lat: 49.2779641, lng: 18.7388389 },
    { lat: 49.2774736, lng: 18.737803 },
    { lat: 49.2774099, lng: 18.7365204 },
    { lat: 49.2773028, lng: 18.736129 },
    { lat: 49.277272, lng: 18.7360153 },
    { lat: 49.2772635, lng: 18.7359828 },
    { lat: 49.2769141, lng: 18.7340207 },
    { lat: 49.276443, lng: 18.73273 },
    { lat: 49.2763323, lng: 18.7318638 },
    { lat: 49.2763231, lng: 18.7317963 },
    { lat: 49.276185, lng: 18.7308811 },
    { lat: 49.2760455, lng: 18.7303452 },
    { lat: 49.2757171, lng: 18.7292845 },
    { lat: 49.2757087, lng: 18.7292556 },
    { lat: 49.275254, lng: 18.7280843 },
    { lat: 49.2748496, lng: 18.7265635 },
    { lat: 49.2737221, lng: 18.7248635 },
    { lat: 49.273591, lng: 18.724639 },
    { lat: 49.273706, lng: 18.724184 },
    { lat: 49.273697, lng: 18.724181 },
    { lat: 49.273509, lng: 18.724122 },
    { lat: 49.273483, lng: 18.724114 },
    { lat: 49.272681, lng: 18.723862 },
    { lat: 49.272663, lng: 18.723023 },
    { lat: 49.272612, lng: 18.722459 },
    { lat: 49.2726, lng: 18.722314 },
    { lat: 49.272024, lng: 18.722423 },
    { lat: 49.272087, lng: 18.726166 },
    { lat: 49.271734, lng: 18.726483 },
    { lat: 49.271676, lng: 18.726535 },
    { lat: 49.271635, lng: 18.726525 },
    { lat: 49.271592, lng: 18.726514 },
    { lat: 49.27137, lng: 18.726461 },
    { lat: 49.271452, lng: 18.727239 },
    { lat: 49.271435, lng: 18.727804 },
    { lat: 49.270324, lng: 18.728473 },
    { lat: 49.269778, lng: 18.728686 },
    { lat: 49.269512, lng: 18.72905 },
    { lat: 49.269335, lng: 18.7294 },
    { lat: 49.269489, lng: 18.729948 },
    { lat: 49.269451, lng: 18.729954 },
    { lat: 49.269104, lng: 18.730017 },
    { lat: 49.268258, lng: 18.728958 },
    { lat: 49.268, lng: 18.729163 },
    { lat: 49.267983, lng: 18.729177 },
    { lat: 49.267808, lng: 18.729315 },
    { lat: 49.267415, lng: 18.729193 },
    { lat: 49.265717, lng: 18.729085 },
    { lat: 49.265659, lng: 18.73013 },
    { lat: 49.265945, lng: 18.731704 },
    { lat: 49.265868, lng: 18.736956 },
    { lat: 49.266537, lng: 18.739837 },
    { lat: 49.266422, lng: 18.741444 },
    { lat: 49.266008, lng: 18.748274 },
    { lat: 49.265935, lng: 18.748662 },
    { lat: 49.265801, lng: 18.749371 },
    { lat: 49.265708, lng: 18.749866 },
    { lat: 49.26573, lng: 18.749955 },
    { lat: 49.265765, lng: 18.750102 },
    { lat: 49.265827, lng: 18.750361 },
    { lat: 49.265828, lng: 18.750369 },
  ],
  Rudinská: [
    { lat: 49.340186, lng: 18.67847 },
    { lat: 49.339976, lng: 18.678591 },
    { lat: 49.339047, lng: 18.679463 },
    { lat: 49.338034, lng: 18.680404 },
    { lat: 49.336411, lng: 18.680402 },
    { lat: 49.336164, lng: 18.681012 },
    { lat: 49.335671, lng: 18.681172 },
    { lat: 49.335036, lng: 18.681891 },
    { lat: 49.334545, lng: 18.682531 },
    { lat: 49.334338, lng: 18.68267 },
    { lat: 49.334237, lng: 18.68254 },
    { lat: 49.33418, lng: 18.68232 },
    { lat: 49.333891, lng: 18.683162 },
    { lat: 49.333731, lng: 18.6832 },
    { lat: 49.333495, lng: 18.683807 },
    { lat: 49.333406, lng: 18.684215 },
    { lat: 49.333547, lng: 18.68673 },
    { lat: 49.333646, lng: 18.686843 },
    { lat: 49.333463, lng: 18.687462 },
    { lat: 49.333361, lng: 18.688709 },
    { lat: 49.333306, lng: 18.688901 },
    { lat: 49.333256, lng: 18.688935 },
    { lat: 49.332934, lng: 18.688984 },
    { lat: 49.332842, lng: 18.689196 },
    { lat: 49.332708, lng: 18.689419 },
    { lat: 49.332706, lng: 18.689585 },
    { lat: 49.332587, lng: 18.689765 },
    { lat: 49.332575, lng: 18.689891 },
    { lat: 49.332301, lng: 18.690598 },
    { lat: 49.332061, lng: 18.690413 },
    { lat: 49.331864, lng: 18.690349 },
    { lat: 49.331636, lng: 18.690393 },
    { lat: 49.331193, lng: 18.690532 },
    { lat: 49.331084, lng: 18.69052 },
    { lat: 49.330841, lng: 18.690605 },
    { lat: 49.330581, lng: 18.690531 },
    { lat: 49.329884, lng: 18.690691 },
    { lat: 49.32927, lng: 18.691581 },
    { lat: 49.329058, lng: 18.690969 },
    { lat: 49.329047, lng: 18.690966 },
    { lat: 49.328889, lng: 18.69119 },
    { lat: 49.328617, lng: 18.691301 },
    { lat: 49.328616, lng: 18.691281 },
    { lat: 49.328185, lng: 18.691296 },
    { lat: 49.327826, lng: 18.691099 },
    { lat: 49.327673, lng: 18.691085 },
    { lat: 49.327573, lng: 18.690999 },
    { lat: 49.327488, lng: 18.690834 },
    { lat: 49.327215, lng: 18.690851 },
    { lat: 49.327054, lng: 18.690962 },
    { lat: 49.326853, lng: 18.691207 },
    { lat: 49.326604, lng: 18.691441 },
    { lat: 49.326469, lng: 18.691498 },
    { lat: 49.326389, lng: 18.691668 },
    { lat: 49.326127, lng: 18.691866 },
    { lat: 49.325919, lng: 18.692128 },
    { lat: 49.325431, lng: 18.692981 },
    { lat: 49.325213, lng: 18.693306 },
    { lat: 49.32523, lng: 18.693331 },
    { lat: 49.32507, lng: 18.694257 },
    { lat: 49.324972, lng: 18.694606 },
    { lat: 49.32479, lng: 18.695078 },
    { lat: 49.324809, lng: 18.695644 },
    { lat: 49.324084, lng: 18.696107 },
    { lat: 49.323975, lng: 18.696178 },
    { lat: 49.323917, lng: 18.696362 },
    { lat: 49.323754, lng: 18.696738 },
    { lat: 49.323553, lng: 18.696989 },
    { lat: 49.323457, lng: 18.696962 },
    { lat: 49.3234, lng: 18.696871 },
    { lat: 49.323336, lng: 18.697029 },
    { lat: 49.323252, lng: 18.69712 },
    { lat: 49.323071, lng: 18.697344 },
    { lat: 49.322901, lng: 18.697411 },
    { lat: 49.322773, lng: 18.697603 },
    { lat: 49.322489, lng: 18.697431 },
    { lat: 49.322454, lng: 18.697341 },
    { lat: 49.322312, lng: 18.697185 },
    { lat: 49.322175, lng: 18.697111 },
    { lat: 49.321906, lng: 18.697064 },
    { lat: 49.321666, lng: 18.697043 },
    { lat: 49.321529, lng: 18.697104 },
    { lat: 49.32124, lng: 18.697372 },
    { lat: 49.32092, lng: 18.697397 },
    { lat: 49.320835, lng: 18.697165 },
    { lat: 49.320695, lng: 18.697027 },
    { lat: 49.320444, lng: 18.696955 },
    { lat: 49.320327, lng: 18.696888 },
    { lat: 49.32001, lng: 18.696536 },
    { lat: 49.319705, lng: 18.696515 },
    { lat: 49.319462, lng: 18.696618 },
    { lat: 49.319288, lng: 18.696569 },
    { lat: 49.319249, lng: 18.696598 },
    { lat: 49.318826, lng: 18.696909 },
    { lat: 49.318667, lng: 18.696948 },
    { lat: 49.318341, lng: 18.697148 },
    { lat: 49.318202, lng: 18.697252 },
    { lat: 49.318189, lng: 18.69722 },
    { lat: 49.317338, lng: 18.697928 },
    { lat: 49.316697, lng: 18.698515 },
    { lat: 49.31633, lng: 18.698686 },
    { lat: 49.316132, lng: 18.698693 },
    { lat: 49.315941, lng: 18.698762 },
    { lat: 49.315801, lng: 18.698727 },
    { lat: 49.315797, lng: 18.69877 },
    { lat: 49.315595, lng: 18.698853 },
    { lat: 49.315432, lng: 18.6989 },
    { lat: 49.315257, lng: 18.698883 },
    { lat: 49.314691, lng: 18.699034 },
    { lat: 49.314337, lng: 18.699084 },
    { lat: 49.314249, lng: 18.69909 },
    { lat: 49.314145, lng: 18.699085 },
    { lat: 49.313571, lng: 18.698913 },
    { lat: 49.313393, lng: 18.698921 },
    { lat: 49.313116, lng: 18.698916 },
    { lat: 49.312788, lng: 18.699001 },
    { lat: 49.312612, lng: 18.6991 },
    { lat: 49.312184, lng: 18.699417 },
    { lat: 49.312009, lng: 18.699527 },
    { lat: 49.311857, lng: 18.699661 },
    { lat: 49.311759, lng: 18.699716 },
    { lat: 49.311498, lng: 18.699901 },
    { lat: 49.311169, lng: 18.700026 },
    { lat: 49.310145, lng: 18.700432 },
    { lat: 49.310042, lng: 18.700497 },
    { lat: 49.30953, lng: 18.700679 },
    { lat: 49.309114, lng: 18.700998 },
    { lat: 49.30895, lng: 18.701084 },
    { lat: 49.308831, lng: 18.70111 },
    { lat: 49.308824, lng: 18.701367 },
    { lat: 49.3086, lng: 18.701761 },
    { lat: 49.308488, lng: 18.701763 },
    { lat: 49.30813, lng: 18.702023 },
    { lat: 49.308027, lng: 18.702117 },
    { lat: 49.307939, lng: 18.702311 },
    { lat: 49.307855, lng: 18.702468 },
    { lat: 49.307775, lng: 18.702639 },
    { lat: 49.307646, lng: 18.702727 },
    { lat: 49.307536, lng: 18.702877 },
    { lat: 49.307293, lng: 18.702881 },
    { lat: 49.307074, lng: 18.702664 },
    { lat: 49.306964, lng: 18.702889 },
    { lat: 49.306827, lng: 18.702595 },
    { lat: 49.306746, lng: 18.70186 },
    { lat: 49.306722, lng: 18.701404 },
    { lat: 49.306736, lng: 18.7009 },
    { lat: 49.306589, lng: 18.700917 },
    { lat: 49.306074, lng: 18.701227 },
    { lat: 49.305673, lng: 18.701618 },
    { lat: 49.305554, lng: 18.701627 },
    { lat: 49.305354, lng: 18.701807 },
    { lat: 49.305242, lng: 18.702405 },
    { lat: 49.305134, lng: 18.703255 },
    { lat: 49.304951, lng: 18.703986 },
    { lat: 49.304728, lng: 18.704804 },
    { lat: 49.304551, lng: 18.705339 },
    { lat: 49.304484, lng: 18.705661 },
    { lat: 49.304423, lng: 18.705889 },
    { lat: 49.304263, lng: 18.706278 },
    { lat: 49.303256, lng: 18.705501 },
    { lat: 49.303051, lng: 18.705461 },
    { lat: 49.30291, lng: 18.705467 },
    { lat: 49.302771, lng: 18.705698 },
    { lat: 49.30245, lng: 18.705745 },
    { lat: 49.302299, lng: 18.705641 },
    { lat: 49.302236, lng: 18.705626 },
    { lat: 49.301815, lng: 18.705765 },
    { lat: 49.301675, lng: 18.705832 },
    { lat: 49.301638, lng: 18.706107 },
    { lat: 49.301405, lng: 18.706458 },
    { lat: 49.301436, lng: 18.706533 },
    { lat: 49.301435, lng: 18.706578 },
    { lat: 49.301415, lng: 18.706647 },
    { lat: 49.301353, lng: 18.707152 },
    { lat: 49.301351, lng: 18.707303 },
    { lat: 49.301367, lng: 18.707415 },
    { lat: 49.30105, lng: 18.708062 },
    { lat: 49.301028, lng: 18.708118 },
    { lat: 49.301003, lng: 18.708171 },
    { lat: 49.300893, lng: 18.708348 },
    { lat: 49.300841, lng: 18.708588 },
    { lat: 49.300729, lng: 18.708709 },
    { lat: 49.300645, lng: 18.708782 },
    { lat: 49.300595, lng: 18.708826 },
    { lat: 49.300489, lng: 18.708861 },
    { lat: 49.30028, lng: 18.709107 },
    { lat: 49.300219, lng: 18.709156 },
    { lat: 49.300147, lng: 18.709304 },
    { lat: 49.30007, lng: 18.709402 },
    { lat: 49.300073, lng: 18.709533 },
    { lat: 49.300013, lng: 18.709619 },
    { lat: 49.299999, lng: 18.709798 },
    { lat: 49.299913, lng: 18.710148 },
    { lat: 49.299927, lng: 18.710229 },
    { lat: 49.299906, lng: 18.710412 },
    { lat: 49.299752, lng: 18.710671 },
    { lat: 49.299637, lng: 18.711039 },
    { lat: 49.2997743, lng: 18.7111174 },
    { lat: 49.3001543, lng: 18.7110549 },
    { lat: 49.3003133, lng: 18.7109914 },
    { lat: 49.3004244, lng: 18.7110244 },
    { lat: 49.3005566, lng: 18.7110938 },
    { lat: 49.3006705, lng: 18.7113964 },
    { lat: 49.3007319, lng: 18.7115746 },
    { lat: 49.3007581, lng: 18.7121379 },
    { lat: 49.3008083, lng: 18.7123027 },
    { lat: 49.3008533, lng: 18.712643 },
    { lat: 49.3008929, lng: 18.7132278 },
    { lat: 49.300933, lng: 18.7135412 },
    { lat: 49.3010471, lng: 18.713997 },
    { lat: 49.3010862, lng: 18.7142285 },
    { lat: 49.3011154, lng: 18.7145064 },
    { lat: 49.3011249, lng: 18.7147741 },
    { lat: 49.3014545, lng: 18.7149946 },
    { lat: 49.301502, lng: 18.7149754 },
    { lat: 49.301731, lng: 18.7151482 },
    { lat: 49.3018501, lng: 18.7152916 },
    { lat: 49.3016698, lng: 18.7159199 },
    { lat: 49.301564, lng: 18.7161147 },
    { lat: 49.3014615, lng: 18.7164391 },
    { lat: 49.3013163, lng: 18.7164648 },
    { lat: 49.3012093, lng: 18.716664 },
    { lat: 49.3012073, lng: 18.7168597 },
    { lat: 49.3013215, lng: 18.7169295 },
    { lat: 49.3013839, lng: 18.7171336 },
    { lat: 49.3013875, lng: 18.7172871 },
    { lat: 49.3015159, lng: 18.7174156 },
    { lat: 49.3016646, lng: 18.7174346 },
    { lat: 49.3018745, lng: 18.717608 },
    { lat: 49.3018819, lng: 18.7177715 },
    { lat: 49.3019269, lng: 18.7181121 },
    { lat: 49.3020395, lng: 18.7186291 },
    { lat: 49.3021308, lng: 18.7189287 },
    { lat: 49.3022696, lng: 18.7198563 },
    { lat: 49.3023206, lng: 18.7199913 },
    { lat: 49.3023466, lng: 18.7201225 },
    { lat: 49.3023999, lng: 18.7203003 },
    { lat: 49.3024485, lng: 18.7204981 },
    { lat: 49.3025403, lng: 18.720699 },
    { lat: 49.3025817, lng: 18.7207124 },
    { lat: 49.3027365, lng: 18.7204611 },
    { lat: 49.3028267, lng: 18.7208074 },
    { lat: 49.3029574, lng: 18.7210613 },
    { lat: 49.3031789, lng: 18.7214599 },
    { lat: 49.3032779, lng: 18.7221231 },
    { lat: 49.3032029, lng: 18.7221536 },
    { lat: 49.3030706, lng: 18.7221727 },
    { lat: 49.3029873, lng: 18.7221742 },
    { lat: 49.3028827, lng: 18.722113 },
    { lat: 49.3027769, lng: 18.7221176 },
    { lat: 49.3025615, lng: 18.7221837 },
    { lat: 49.3025518, lng: 18.7221007 },
    { lat: 49.3021021, lng: 18.7224079 },
    { lat: 49.3022197, lng: 18.7225648 },
    { lat: 49.3023154, lng: 18.7227356 },
    { lat: 49.3023637, lng: 18.7229685 },
    { lat: 49.3024594, lng: 18.7233756 },
    { lat: 49.3025361, lng: 18.723806 },
    { lat: 49.3026496, lng: 18.7244443 },
    { lat: 49.3027278, lng: 18.7248878 },
    { lat: 49.3024246, lng: 18.7250413 },
    { lat: 49.3024418, lng: 18.7250673 },
    { lat: 49.3024639, lng: 18.7251456 },
    { lat: 49.3024694, lng: 18.7251608 },
    { lat: 49.3025652, lng: 18.7253977 },
    { lat: 49.3027564, lng: 18.7253895 },
    { lat: 49.3028376, lng: 18.7253872 },
    { lat: 49.3028786, lng: 18.725376 },
    { lat: 49.3030009, lng: 18.7252794 },
    { lat: 49.3031736, lng: 18.7250675 },
    { lat: 49.3033891, lng: 18.7247228 },
    { lat: 49.3035558, lng: 18.7245037 },
    { lat: 49.3036342, lng: 18.7244088 },
    { lat: 49.3038213, lng: 18.7242309 },
    { lat: 49.3040754, lng: 18.7241557 },
    { lat: 49.3046021, lng: 18.7240707 },
    { lat: 49.3048905, lng: 18.7239563 },
    { lat: 49.3050829, lng: 18.7237983 },
    { lat: 49.3054086, lng: 18.7233447 },
    { lat: 49.3054468, lng: 18.7233206 },
    { lat: 49.3056815, lng: 18.7232131 },
    { lat: 49.3058647, lng: 18.7231791 },
    { lat: 49.306003, lng: 18.7231925 },
    { lat: 49.3062439, lng: 18.7232722 },
    { lat: 49.3066026, lng: 18.7235759 },
    { lat: 49.306647, lng: 18.7235846 },
    { lat: 49.3067912, lng: 18.7235182 },
    { lat: 49.306922, lng: 18.7233435 },
    { lat: 49.3069626, lng: 18.7233032 },
    { lat: 49.3070475, lng: 18.7231764 },
    { lat: 49.3070865, lng: 18.7231279 },
    { lat: 49.3071305, lng: 18.7230615 },
    { lat: 49.3071682, lng: 18.7230088 },
    { lat: 49.3072265, lng: 18.7229131 },
    { lat: 49.3074467, lng: 18.7227051 },
    { lat: 49.3074989, lng: 18.7226677 },
    { lat: 49.3076467, lng: 18.7225618 },
    { lat: 49.3078958, lng: 18.7224319 },
    { lat: 49.3084038, lng: 18.7222285 },
    { lat: 49.3086383, lng: 18.7220953 },
    { lat: 49.3086489, lng: 18.7221251 },
    { lat: 49.3086711, lng: 18.7221845 },
    { lat: 49.3086408, lng: 18.7222508 },
    { lat: 49.3087154, lng: 18.7223396 },
    { lat: 49.3087397, lng: 18.7223984 },
    { lat: 49.3087457, lng: 18.7224671 },
    { lat: 49.3087287, lng: 18.7225768 },
    { lat: 49.3087241, lng: 18.7227234 },
    { lat: 49.308801, lng: 18.7229427 },
    { lat: 49.3090539, lng: 18.7231424 },
    { lat: 49.3091858, lng: 18.7233145 },
    { lat: 49.3094799, lng: 18.7236573 },
    { lat: 49.3095765, lng: 18.7237998 },
    { lat: 49.309727, lng: 18.72394 },
    { lat: 49.3097602, lng: 18.7239923 },
    { lat: 49.3098338, lng: 18.7239547 },
    { lat: 49.3101126, lng: 18.7241349 },
    { lat: 49.3101793, lng: 18.7242034 },
    { lat: 49.3102275, lng: 18.7242806 },
    { lat: 49.3106251, lng: 18.7245099 },
    { lat: 49.310941, lng: 18.7243827 },
    { lat: 49.3109625, lng: 18.7244282 },
    { lat: 49.3111134, lng: 18.7246822 },
    { lat: 49.3113039, lng: 18.724879 },
    { lat: 49.3116424, lng: 18.7254859 },
    { lat: 49.3116533, lng: 18.7254788 },
    { lat: 49.3122422, lng: 18.7268401 },
    { lat: 49.3123685, lng: 18.7270141 },
    { lat: 49.3127331, lng: 18.7278871 },
    { lat: 49.3128323, lng: 18.7280987 },
    { lat: 49.3130174, lng: 18.7286023 },
    { lat: 49.3130709, lng: 18.7287631 },
    { lat: 49.3132449, lng: 18.7291567 },
    { lat: 49.3134532, lng: 18.7290647 },
    { lat: 49.3136959, lng: 18.7290474 },
    { lat: 49.3139319, lng: 18.7289477 },
    { lat: 49.3142172, lng: 18.7289034 },
    { lat: 49.3143169, lng: 18.7288901 },
    { lat: 49.3145207, lng: 18.7288641 },
    { lat: 49.3145651, lng: 18.7288692 },
    { lat: 49.3146886, lng: 18.7292932 },
    { lat: 49.3149312, lng: 18.7292741 },
    { lat: 49.3153874, lng: 18.7294384 },
    { lat: 49.3156278, lng: 18.7296136 },
    { lat: 49.3159031, lng: 18.7296584 },
    { lat: 49.3160692, lng: 18.7296222 },
    { lat: 49.3162257, lng: 18.7295568 },
    { lat: 49.3168824, lng: 18.7291537 },
    { lat: 49.3171341, lng: 18.7289426 },
    { lat: 49.3175467, lng: 18.7287216 },
    { lat: 49.3178289, lng: 18.7285335 },
    { lat: 49.3180122, lng: 18.728442 },
    { lat: 49.3182886, lng: 18.7282918 },
    { lat: 49.3186598, lng: 18.7280151 },
    { lat: 49.3190369, lng: 18.7277442 },
    { lat: 49.3194533, lng: 18.7272986 },
    { lat: 49.3197815, lng: 18.7270408 },
    { lat: 49.3200201, lng: 18.7268808 },
    { lat: 49.32072, lng: 18.7265053 },
    { lat: 49.3209924, lng: 18.7263881 },
    { lat: 49.3212059, lng: 18.726332 },
    { lat: 49.3213897, lng: 18.7262683 },
    { lat: 49.3215605, lng: 18.7261722 },
    { lat: 49.3216342, lng: 18.7260906 },
    { lat: 49.3217048, lng: 18.7259757 },
    { lat: 49.3217837, lng: 18.725713 },
    { lat: 49.3220713, lng: 18.7254801 },
    { lat: 49.3222737, lng: 18.7251423 },
    { lat: 49.3225071, lng: 18.7247989 },
    { lat: 49.322664, lng: 18.7246535 },
    { lat: 49.3228458, lng: 18.7244845 },
    { lat: 49.3230772, lng: 18.7242093 },
    { lat: 49.3231146, lng: 18.7241248 },
    { lat: 49.3231518, lng: 18.7240404 },
    { lat: 49.3232326, lng: 18.7238602 },
    { lat: 49.3232867, lng: 18.7234722 },
    { lat: 49.323329, lng: 18.7231775 },
    { lat: 49.3234503, lng: 18.7229353 },
    { lat: 49.323526, lng: 18.7227829 },
    { lat: 49.3236803, lng: 18.7224582 },
    { lat: 49.323712, lng: 18.7223542 },
    { lat: 49.3237583, lng: 18.7222061 },
    { lat: 49.3240422, lng: 18.7215532 },
    { lat: 49.32439, lng: 18.7212821 },
    { lat: 49.3244992, lng: 18.7211292 },
    { lat: 49.3245651, lng: 18.7211146 },
    { lat: 49.3247312, lng: 18.7210784 },
    { lat: 49.324946, lng: 18.7210474 },
    { lat: 49.3251922, lng: 18.7210957 },
    { lat: 49.3252654, lng: 18.7211594 },
    { lat: 49.3253479, lng: 18.7211873 },
    { lat: 49.3254469, lng: 18.7212218 },
    { lat: 49.3258309, lng: 18.7211455 },
    { lat: 49.32592, lng: 18.7211281 },
    { lat: 49.3259229, lng: 18.7211382 },
    { lat: 49.3260659, lng: 18.721112 },
    { lat: 49.3261373, lng: 18.7210989 },
    { lat: 49.3266787, lng: 18.7210836 },
    { lat: 49.3269142, lng: 18.7210768 },
    { lat: 49.3270485, lng: 18.7210376 },
    { lat: 49.3272291, lng: 18.7210625 },
    { lat: 49.3273231, lng: 18.7210072 },
    { lat: 49.3275104, lng: 18.7208972 },
    { lat: 49.3276414, lng: 18.7206013 },
    { lat: 49.3277244, lng: 18.7203992 },
    { lat: 49.3280034, lng: 18.7198694 },
    { lat: 49.3282743, lng: 18.7195741 },
    { lat: 49.3284754, lng: 18.7194287 },
    { lat: 49.3288516, lng: 18.7189051 },
    { lat: 49.3289983, lng: 18.7187338 },
    { lat: 49.329047, lng: 18.7184994 },
    { lat: 49.3290396, lng: 18.7179699 },
    { lat: 49.3291187, lng: 18.7175896 },
    { lat: 49.3292248, lng: 18.7171775 },
    { lat: 49.3293358, lng: 18.7169972 },
    { lat: 49.3293796, lng: 18.7169492 },
    { lat: 49.3294096, lng: 18.716917 },
    { lat: 49.3296209, lng: 18.7166875 },
    { lat: 49.3298202, lng: 18.7162752 },
    { lat: 49.3298925, lng: 18.716108 },
    { lat: 49.3299714, lng: 18.7158221 },
    { lat: 49.3301081, lng: 18.7155273 },
    { lat: 49.330167, lng: 18.7153838 },
    { lat: 49.3304992, lng: 18.7148156 },
    { lat: 49.3307373, lng: 18.7144932 },
    { lat: 49.3311868, lng: 18.7139265 },
    { lat: 49.3313827, lng: 18.713533 },
    { lat: 49.3314689, lng: 18.7134157 },
    { lat: 49.3315476, lng: 18.7133887 },
    { lat: 49.3319332, lng: 18.7132569 },
    { lat: 49.3321606, lng: 18.7129473 },
    { lat: 49.3325275, lng: 18.7128705 },
    { lat: 49.3327169, lng: 18.7128907 },
    { lat: 49.3329153, lng: 18.7128479 },
    { lat: 49.3336328, lng: 18.7127693 },
    { lat: 49.3338448, lng: 18.7125933 },
    { lat: 49.3340496, lng: 18.7123718 },
    { lat: 49.3344251, lng: 18.7122245 },
    { lat: 49.3347234, lng: 18.7120629 },
    { lat: 49.3350736, lng: 18.7120091 },
    { lat: 49.3352359, lng: 18.7119009 },
    { lat: 49.3353623, lng: 18.7117737 },
    { lat: 49.335434, lng: 18.7113968 },
    { lat: 49.335517, lng: 18.7109784 },
    { lat: 49.3356747, lng: 18.7105252 },
    { lat: 49.3357507, lng: 18.710275 },
    { lat: 49.3359614, lng: 18.7100358 },
    { lat: 49.3360602, lng: 18.7097243 },
    { lat: 49.336098, lng: 18.7093957 },
    { lat: 49.3360028, lng: 18.7084568 },
    { lat: 49.3359462, lng: 18.7081748 },
    { lat: 49.3359516, lng: 18.7077598 },
    { lat: 49.336146, lng: 18.7071689 },
    { lat: 49.3361372, lng: 18.7069335 },
    { lat: 49.3365232, lng: 18.7064269 },
    { lat: 49.3367048, lng: 18.7063208 },
    { lat: 49.3369707, lng: 18.7062491 },
    { lat: 49.3370895, lng: 18.706087 },
    { lat: 49.337379, lng: 18.7056732 },
    { lat: 49.3375189, lng: 18.7055233 },
    { lat: 49.3378984, lng: 18.7054076 },
    { lat: 49.3380367, lng: 18.7054167 },
    { lat: 49.3382617, lng: 18.7055583 },
    { lat: 49.3386275, lng: 18.7055673 },
    { lat: 49.3389002, lng: 18.705716 },
    { lat: 49.3392892, lng: 18.7058216 },
    { lat: 49.3395962, lng: 18.7058049 },
    { lat: 49.3397301, lng: 18.7058217 },
    { lat: 49.3399659, lng: 18.7059736 },
    { lat: 49.340096, lng: 18.7059815 },
    { lat: 49.3402943, lng: 18.7059144 },
    { lat: 49.3404923, lng: 18.7058015 },
    { lat: 49.3406536, lng: 18.7057372 },
    { lat: 49.341226, lng: 18.7052337 },
    { lat: 49.3416752, lng: 18.7048969 },
    { lat: 49.341916, lng: 18.7046315 },
    { lat: 49.3423541, lng: 18.7045954 },
    { lat: 49.3428452, lng: 18.7047365 },
    { lat: 49.3434186, lng: 18.7048126 },
    { lat: 49.3434524, lng: 18.7048753 },
    { lat: 49.3437921, lng: 18.7051844 },
    { lat: 49.344002, lng: 18.7052718 },
    { lat: 49.3440898, lng: 18.7053153 },
    { lat: 49.3441048, lng: 18.7052765 },
    { lat: 49.3442682, lng: 18.7053606 },
    { lat: 49.344481, lng: 18.7055464 },
    { lat: 49.3445981, lng: 18.7055686 },
    { lat: 49.3446578, lng: 18.7056737 },
    { lat: 49.3453908, lng: 18.7057385 },
    { lat: 49.3454841, lng: 18.7057097 },
    { lat: 49.3456062, lng: 18.7056093 },
    { lat: 49.3456328, lng: 18.7055985 },
    { lat: 49.3457005, lng: 18.7055153 },
    { lat: 49.3459162, lng: 18.705393 },
    { lat: 49.3461705, lng: 18.7052724 },
    { lat: 49.3463474, lng: 18.7051848 },
    { lat: 49.346521, lng: 18.7050995 },
    { lat: 49.3466296, lng: 18.7050203 },
    { lat: 49.3467959, lng: 18.7049873 },
    { lat: 49.3469114, lng: 18.7049098 },
    { lat: 49.3472456, lng: 18.7048535 },
    { lat: 49.3474779, lng: 18.7048124 },
    { lat: 49.3476709, lng: 18.7047084 },
    { lat: 49.347826, lng: 18.7045251 },
    { lat: 49.347888, lng: 18.704545 },
    { lat: 49.3481334, lng: 18.7040649 },
    { lat: 49.3482241, lng: 18.7039019 },
    { lat: 49.3485702, lng: 18.7034286 },
    { lat: 49.3487905, lng: 18.7031766 },
    { lat: 49.3488846, lng: 18.7029943 },
    { lat: 49.3490397, lng: 18.7028967 },
    { lat: 49.3494521, lng: 18.7026374 },
    { lat: 49.3494533, lng: 18.7025685 },
    { lat: 49.3494678, lng: 18.7019226 },
    { lat: 49.3495397, lng: 18.7018562 },
    { lat: 49.34967, lng: 18.701782 },
    { lat: 49.3497605, lng: 18.7009711 },
    { lat: 49.3497263, lng: 18.7007243 },
    { lat: 49.3498033, lng: 18.7004707 },
    { lat: 49.3497838, lng: 18.7002514 },
    { lat: 49.3497348, lng: 18.7000075 },
    { lat: 49.3497182, lng: 18.6999256 },
    { lat: 49.3497644, lng: 18.6997977 },
    { lat: 49.3497751, lng: 18.6996365 },
    { lat: 49.3497174, lng: 18.6992888 },
    { lat: 49.3498255, lng: 18.6987044 },
    { lat: 49.3499002, lng: 18.6984945 },
    { lat: 49.3500875, lng: 18.6986234 },
    { lat: 49.3501045, lng: 18.698456 },
    { lat: 49.3501594, lng: 18.6982962 },
    { lat: 49.3502245, lng: 18.6982083 },
    { lat: 49.3504036, lng: 18.6972133 },
    { lat: 49.35086, lng: 18.6962652 },
    { lat: 49.3509584, lng: 18.6960965 },
    { lat: 49.35132, lng: 18.6956379 },
    { lat: 49.3515199, lng: 18.6954534 },
    { lat: 49.3517918, lng: 18.6951548 },
    { lat: 49.352028, lng: 18.6949283 },
    { lat: 49.3519351, lng: 18.6947704 },
    { lat: 49.351749, lng: 18.6943177 },
    { lat: 49.3515128, lng: 18.6940711 },
    { lat: 49.3513794, lng: 18.6938944 },
    { lat: 49.3512142, lng: 18.6936243 },
    { lat: 49.3511077, lng: 18.6931664 },
    { lat: 49.3510834, lng: 18.6928739 },
    { lat: 49.3510969, lng: 18.6926766 },
    { lat: 49.3511148, lng: 18.6924595 },
    { lat: 49.3511142, lng: 18.6922541 },
    { lat: 49.3510693, lng: 18.6920481 },
    { lat: 49.3510924, lng: 18.6919931 },
    { lat: 49.3511906, lng: 18.6918237 },
    { lat: 49.3514321, lng: 18.6912677 },
    { lat: 49.3518772, lng: 18.690789 },
    { lat: 49.3521953, lng: 18.6904919 },
    { lat: 49.3521823, lng: 18.6904382 },
    { lat: 49.3521871, lng: 18.6903374 },
    { lat: 49.3521529, lng: 18.690198 },
    { lat: 49.3521748, lng: 18.6900575 },
    { lat: 49.3521937, lng: 18.6899235 },
    { lat: 49.3518697, lng: 18.6886724 },
    { lat: 49.3518296, lng: 18.6884036 },
    { lat: 49.3516267, lng: 18.6881258 },
    { lat: 49.3515228, lng: 18.6880142 },
    { lat: 49.3514564, lng: 18.6878035 },
    { lat: 49.351415, lng: 18.6875099 },
    { lat: 49.3512716, lng: 18.6872917 },
    { lat: 49.3512668, lng: 18.6868137 },
    { lat: 49.3513341, lng: 18.686336 },
    { lat: 49.3513895, lng: 18.6861895 },
    { lat: 49.3515061, lng: 18.6859809 },
    { lat: 49.3516175, lng: 18.6856764 },
    { lat: 49.3518238, lng: 18.6854838 },
    { lat: 49.3518653, lng: 18.6855623 },
    { lat: 49.3520815, lng: 18.6854738 },
    { lat: 49.352251, lng: 18.6852236 },
    { lat: 49.3523038, lng: 18.6848109 },
    { lat: 49.3528072, lng: 18.6843506 },
    { lat: 49.3529797, lng: 18.6840674 },
    { lat: 49.3531138, lng: 18.6839171 },
    { lat: 49.3532672, lng: 18.6837882 },
    { lat: 49.3534768, lng: 18.6836347 },
    { lat: 49.3538409, lng: 18.6834856 },
    { lat: 49.3540567, lng: 18.6834286 },
    { lat: 49.354117, lng: 18.6835022 },
    { lat: 49.3542396, lng: 18.6834965 },
    { lat: 49.3542328, lng: 18.683043 },
    { lat: 49.3542146, lng: 18.6828289 },
    { lat: 49.3543353, lng: 18.6827012 },
    { lat: 49.3542468, lng: 18.6824557 },
    { lat: 49.3543385, lng: 18.682291 },
    { lat: 49.3543772, lng: 18.6820373 },
    { lat: 49.3542358, lng: 18.6821582 },
    { lat: 49.3541465, lng: 18.6811922 },
    { lat: 49.3541432, lng: 18.6809133 },
    { lat: 49.3541695, lng: 18.6808114 },
    { lat: 49.3541975, lng: 18.6805543 },
    { lat: 49.3541777, lng: 18.6804964 },
    { lat: 49.3541641, lng: 18.6804756 },
    { lat: 49.3541426, lng: 18.6803466 },
    { lat: 49.3541192, lng: 18.6801353 },
    { lat: 49.3541152, lng: 18.6800591 },
    { lat: 49.3541358, lng: 18.6797451 },
    { lat: 49.3541754, lng: 18.6792772 },
    { lat: 49.3541897, lng: 18.6789463 },
    { lat: 49.3543432, lng: 18.6774497 },
    { lat: 49.3543971, lng: 18.6771059 },
    { lat: 49.3547346, lng: 18.6763607 },
    { lat: 49.3550549, lng: 18.6758473 },
    { lat: 49.3552326, lng: 18.6757306 },
    { lat: 49.3555214, lng: 18.6756063 },
    { lat: 49.3555392, lng: 18.6755582 },
    { lat: 49.3556499, lng: 18.6756277 },
    { lat: 49.3557348, lng: 18.6758672 },
    { lat: 49.3558052, lng: 18.6757507 },
    { lat: 49.3558142, lng: 18.6757897 },
    { lat: 49.3559907, lng: 18.6760997 },
    { lat: 49.356159, lng: 18.6762815 },
    { lat: 49.35619, lng: 18.676226 },
    { lat: 49.356773, lng: 18.676924 },
    { lat: 49.35717, lng: 18.677135 },
    { lat: 49.357455, lng: 18.677094 },
    { lat: 49.357763, lng: 18.676829 },
    { lat: 49.357699, lng: 18.676611 },
    { lat: 49.357832, lng: 18.67652 },
    { lat: 49.357965, lng: 18.676157 },
    { lat: 49.357911, lng: 18.676082 },
    { lat: 49.358124, lng: 18.675805 },
    { lat: 49.357993, lng: 18.675025 },
    { lat: 49.35769, lng: 18.674386 },
    { lat: 49.358062, lng: 18.67332 },
    { lat: 49.357898, lng: 18.673119 },
    { lat: 49.357338, lng: 18.673215 },
    { lat: 49.356767, lng: 18.672987 },
    { lat: 49.356264, lng: 18.673213 },
    { lat: 49.355967, lng: 18.673106 },
    { lat: 49.355652, lng: 18.67289 },
    { lat: 49.3554, lng: 18.672957 },
    { lat: 49.355166, lng: 18.672871 },
    { lat: 49.355115, lng: 18.672923 },
    { lat: 49.354718, lng: 18.672886 },
    { lat: 49.354708, lng: 18.672968 },
    { lat: 49.354351, lng: 18.672848 },
    { lat: 49.35408, lng: 18.673017 },
    { lat: 49.353966, lng: 18.673232 },
    { lat: 49.353948, lng: 18.673198 },
    { lat: 49.353811, lng: 18.673514 },
    { lat: 49.353799, lng: 18.67365 },
    { lat: 49.353524, lng: 18.673994 },
    { lat: 49.352594, lng: 18.676446 },
    { lat: 49.35208, lng: 18.67646 },
    { lat: 49.351288, lng: 18.676302 },
    { lat: 49.351146, lng: 18.676542 },
    { lat: 49.350987, lng: 18.676562 },
    { lat: 49.350962, lng: 18.677074 },
    { lat: 49.350487, lng: 18.677122 },
    { lat: 49.350076, lng: 18.677466 },
    { lat: 49.349824, lng: 18.677878 },
    { lat: 49.348981, lng: 18.678323 },
    { lat: 49.348753, lng: 18.678154 },
    { lat: 49.347836, lng: 18.678347 },
    { lat: 49.347297, lng: 18.679227 },
    { lat: 49.346575, lng: 18.679686 },
    { lat: 49.346164, lng: 18.679751 },
    { lat: 49.345789, lng: 18.679637 },
    { lat: 49.345532, lng: 18.679411 },
    { lat: 49.345222, lng: 18.678876 },
    { lat: 49.344793, lng: 18.678402 },
    { lat: 49.344271, lng: 18.678475 },
    { lat: 49.344152, lng: 18.678618 },
    { lat: 49.343941, lng: 18.678708 },
    { lat: 49.343793, lng: 18.678592 },
    { lat: 49.343541, lng: 18.67876 },
    { lat: 49.343379, lng: 18.678756 },
    { lat: 49.343097, lng: 18.678571 },
    { lat: 49.342836, lng: 18.67851 },
    { lat: 49.342492, lng: 18.678274 },
    { lat: 49.341518, lng: 18.678191 },
    { lat: 49.340966, lng: 18.678018 },
    { lat: 49.340186, lng: 18.67847 },
  ],
  Snežnica: [
    { lat: 49.248714, lng: 18.801035 },
    { lat: 49.248769, lng: 18.800972 },
    { lat: 49.248877, lng: 18.800995 },
    { lat: 49.249085, lng: 18.80104 },
    { lat: 49.249281, lng: 18.801083 },
    { lat: 49.24943, lng: 18.801116 },
    { lat: 49.249541, lng: 18.801007 },
    { lat: 49.249797, lng: 18.800754 },
    { lat: 49.24981, lng: 18.800742 },
    { lat: 49.249936, lng: 18.801041 },
    { lat: 49.250022, lng: 18.801258 },
    { lat: 49.250026, lng: 18.801255 },
    { lat: 49.250173, lng: 18.801133 },
    { lat: 49.250191, lng: 18.801115 },
    { lat: 49.250354, lng: 18.800957 },
    { lat: 49.250465, lng: 18.80087 },
    { lat: 49.251119, lng: 18.800144 },
    { lat: 49.251809, lng: 18.801978 },
    { lat: 49.252186, lng: 18.802717 },
    { lat: 49.252222, lng: 18.802891 },
    { lat: 49.252418, lng: 18.80365 },
    { lat: 49.252455, lng: 18.803826 },
    { lat: 49.252927, lng: 18.805316 },
    { lat: 49.253194, lng: 18.806379 },
    { lat: 49.253207, lng: 18.806428 },
    { lat: 49.253644, lng: 18.806276 },
    { lat: 49.254036, lng: 18.806371 },
    { lat: 49.254513, lng: 18.806999 },
    { lat: 49.254821, lng: 18.8077 },
    { lat: 49.255568, lng: 18.808337 },
    { lat: 49.256277, lng: 18.808723 },
    { lat: 49.256456, lng: 18.809035 },
    { lat: 49.256534, lng: 18.809173 },
    { lat: 49.256583, lng: 18.809259 },
    { lat: 49.256616, lng: 18.809287 },
    { lat: 49.25817, lng: 18.810608 },
    { lat: 49.258601, lng: 18.810971 },
    { lat: 49.259148, lng: 18.810893 },
    { lat: 49.25919, lng: 18.811599 },
    { lat: 49.259382, lng: 18.811935 },
    { lat: 49.259738, lng: 18.81256 },
    { lat: 49.260492, lng: 18.811933 },
    { lat: 49.260493, lng: 18.811933 },
    { lat: 49.260377, lng: 18.811676 },
    { lat: 49.260409, lng: 18.811273 },
    { lat: 49.260808, lng: 18.811133 },
    { lat: 49.261104, lng: 18.811455 },
    { lat: 49.261429, lng: 18.811172 },
    { lat: 49.261915, lng: 18.810749 },
    { lat: 49.261685, lng: 18.809974 },
    { lat: 49.262171, lng: 18.809623 },
    { lat: 49.26233, lng: 18.809829 },
    { lat: 49.262616, lng: 18.810052 },
    { lat: 49.263398, lng: 18.809173 },
    { lat: 49.263612, lng: 18.808968 },
    { lat: 49.263799, lng: 18.808855 },
    { lat: 49.264172, lng: 18.809234 },
    { lat: 49.265017, lng: 18.810113 },
    { lat: 49.265393, lng: 18.810505 },
    { lat: 49.265601, lng: 18.810743 },
    { lat: 49.265758, lng: 18.810923 },
    { lat: 49.266109, lng: 18.81094 },
    { lat: 49.266152, lng: 18.813419 },
    { lat: 49.266426, lng: 18.813574 },
    { lat: 49.266546, lng: 18.81381 },
    { lat: 49.266795, lng: 18.813516 },
    { lat: 49.267484, lng: 18.81312 },
    { lat: 49.268021, lng: 18.813117 },
    { lat: 49.2700156, lng: 18.811822 },
    { lat: 49.2700179, lng: 18.8117798 },
    { lat: 49.2700204, lng: 18.8117185 },
    { lat: 49.2700993, lng: 18.8099669 },
    { lat: 49.2701108, lng: 18.8097099 },
    { lat: 49.270083, lng: 18.8096977 },
    { lat: 49.2699989, lng: 18.8096605 },
    { lat: 49.2698698, lng: 18.8096042 },
    { lat: 49.2697855, lng: 18.8095682 },
    { lat: 49.2692076, lng: 18.8092478 },
    { lat: 49.2697644, lng: 18.8086095 },
    { lat: 49.2706917, lng: 18.8062616 },
    { lat: 49.2717021, lng: 18.8041481 },
    { lat: 49.2726113, lng: 18.8036095 },
    { lat: 49.2733631, lng: 18.8031649 },
    { lat: 49.2741736, lng: 18.8032491 },
    { lat: 49.2756541, lng: 18.8009818 },
    { lat: 49.274913, lng: 18.7990347 },
    { lat: 49.2738579, lng: 18.7958752 },
    { lat: 49.2737373, lng: 18.7947639 },
    { lat: 49.2737926, lng: 18.7935898 },
    { lat: 49.2738576, lng: 18.7934758 },
    { lat: 49.2732014, lng: 18.7901182 },
    { lat: 49.272904, lng: 18.7883808 },
    { lat: 49.2726526, lng: 18.7870116 },
    { lat: 49.2724157, lng: 18.7859194 },
    { lat: 49.2723121, lng: 18.7853318 },
    { lat: 49.2722799, lng: 18.7851891 },
    { lat: 49.2722621, lng: 18.7851284 },
    { lat: 49.2722114, lng: 18.7848713 },
    { lat: 49.2721506, lng: 18.7845685 },
    { lat: 49.2721184, lng: 18.7844034 },
    { lat: 49.2720864, lng: 18.7842408 },
    { lat: 49.2720103, lng: 18.7838622 },
    { lat: 49.271928, lng: 18.78345 },
    { lat: 49.2716515, lng: 18.7825515 },
    { lat: 49.2726659, lng: 18.781067 },
    { lat: 49.2737705, lng: 18.7794907 },
    { lat: 49.2736791, lng: 18.7764699 },
    { lat: 49.2732018, lng: 18.7752902 },
    { lat: 49.273186, lng: 18.7746584 },
    { lat: 49.273153, lng: 18.774051 },
    { lat: 49.272834, lng: 18.773894 },
    { lat: 49.2723, lng: 18.773538 },
    { lat: 49.271867, lng: 18.773464 },
    { lat: 49.271454, lng: 18.773204 },
    { lat: 49.270755, lng: 18.772919 },
    { lat: 49.270308, lng: 18.772912 },
    { lat: 49.270261, lng: 18.772899 },
    { lat: 49.270242, lng: 18.772895 },
    { lat: 49.270182, lng: 18.772888 },
    { lat: 49.270129, lng: 18.772874 },
    { lat: 49.270062, lng: 18.774249 },
    { lat: 49.269986, lng: 18.775294 },
    { lat: 49.269979, lng: 18.77537 },
    { lat: 49.269976, lng: 18.775381 },
    { lat: 49.26997, lng: 18.775441 },
    { lat: 49.269892, lng: 18.776168 },
    { lat: 49.269766, lng: 18.777133 },
    { lat: 49.269763, lng: 18.777145 },
    { lat: 49.269697, lng: 18.77765 },
    { lat: 49.269572, lng: 18.778481 },
    { lat: 49.269493, lng: 18.778737 },
    { lat: 49.269374, lng: 18.778957 },
    { lat: 49.269242, lng: 18.779147 },
    { lat: 49.269208, lng: 18.779189 },
    { lat: 49.269201, lng: 18.779197 },
    { lat: 49.26893, lng: 18.7795 },
    { lat: 49.268879, lng: 18.77943 },
    { lat: 49.268584, lng: 18.778815 },
    { lat: 49.268479, lng: 18.778372 },
    { lat: 49.268355, lng: 18.778056 },
    { lat: 49.268195, lng: 18.77759 },
    { lat: 49.268008, lng: 18.777214 },
    { lat: 49.267796, lng: 18.77691 },
    { lat: 49.2676, lng: 18.776542 },
    { lat: 49.267492, lng: 18.776386 },
    { lat: 49.26717, lng: 18.775979 },
    { lat: 49.266952, lng: 18.775428 },
    { lat: 49.266749, lng: 18.774969 },
    { lat: 49.266517, lng: 18.77467 },
    { lat: 49.266115, lng: 18.774117 },
    { lat: 49.266067, lng: 18.774039 },
    { lat: 49.265924, lng: 18.773792 },
    { lat: 49.265804, lng: 18.773565 },
    { lat: 49.265551, lng: 18.772941 },
    { lat: 49.265374, lng: 18.772359 },
    { lat: 49.265142, lng: 18.772385 },
    { lat: 49.264926, lng: 18.772608 },
    { lat: 49.264435, lng: 18.773035 },
    { lat: 49.264236, lng: 18.773164 },
    { lat: 49.263746, lng: 18.773534 },
    { lat: 49.263357, lng: 18.773909 },
    { lat: 49.262791, lng: 18.774375 },
    { lat: 49.262487, lng: 18.774683 },
    { lat: 49.262439, lng: 18.774732 },
    { lat: 49.262013, lng: 18.775117 },
    { lat: 49.260836, lng: 18.776551 },
    { lat: 49.26069, lng: 18.776907 },
    { lat: 49.260549, lng: 18.777121 },
    { lat: 49.260307, lng: 18.777532 },
    { lat: 49.260154, lng: 18.777868 },
    { lat: 49.259975, lng: 18.778473 },
    { lat: 49.259809, lng: 18.778881 },
    { lat: 49.259707, lng: 18.779177 },
    { lat: 49.259627, lng: 18.779477 },
    { lat: 49.259571, lng: 18.779731 },
    { lat: 49.25944, lng: 18.780164 },
    { lat: 49.259301, lng: 18.780583 },
    { lat: 49.259074, lng: 18.781054 },
    { lat: 49.258887, lng: 18.781376 },
    { lat: 49.258575, lng: 18.78178 },
    { lat: 49.258182, lng: 18.782231 },
    { lat: 49.258007, lng: 18.782313 },
    { lat: 49.257772, lng: 18.782348 },
    { lat: 49.257574, lng: 18.782474 },
    { lat: 49.257161, lng: 18.782857 },
    { lat: 49.25691, lng: 18.783152 },
    { lat: 49.256748, lng: 18.783462 },
    { lat: 49.256633, lng: 18.783882 },
    { lat: 49.256533, lng: 18.784108 },
    { lat: 49.2564, lng: 18.78431 },
    { lat: 49.256188, lng: 18.784572 },
    { lat: 49.256022, lng: 18.784721 },
    { lat: 49.255848, lng: 18.784826 },
    { lat: 49.255727, lng: 18.784884 },
    { lat: 49.255623, lng: 18.784898 },
    { lat: 49.255384, lng: 18.784847 },
    { lat: 49.255187, lng: 18.78473 },
    { lat: 49.255173, lng: 18.784735 },
    { lat: 49.255031, lng: 18.784843 },
    { lat: 49.254976, lng: 18.785203 },
    { lat: 49.254807, lng: 18.785587 },
    { lat: 49.254553, lng: 18.78577 },
    { lat: 49.254223, lng: 18.786006 },
    { lat: 49.254016, lng: 18.786995 },
    { lat: 49.253767, lng: 18.787339 },
    { lat: 49.253641, lng: 18.78782 },
    { lat: 49.253365, lng: 18.789002 },
    { lat: 49.253263, lng: 18.789508 },
    { lat: 49.252836, lng: 18.7909 },
    { lat: 49.252606, lng: 18.791651 },
    { lat: 49.252282, lng: 18.792778 },
    { lat: 49.251818, lng: 18.794555 },
    { lat: 49.250916, lng: 18.794066 },
    { lat: 49.250654, lng: 18.79388 },
    { lat: 49.250195, lng: 18.793669 },
    { lat: 49.249892, lng: 18.793855 },
    { lat: 49.249607, lng: 18.793924 },
    { lat: 49.24944, lng: 18.794188 },
    { lat: 49.249069, lng: 18.794439 },
    { lat: 49.24862, lng: 18.794742 },
    { lat: 49.248459, lng: 18.794897 },
    { lat: 49.248359, lng: 18.795102 },
    { lat: 49.248289, lng: 18.795365 },
    { lat: 49.248271, lng: 18.79543 },
    { lat: 49.24812, lng: 18.795706 },
    { lat: 49.248398, lng: 18.796146 },
    { lat: 49.24866, lng: 18.796561 },
    { lat: 49.248844, lng: 18.797025 },
    { lat: 49.248235, lng: 18.798062 },
    { lat: 49.247933, lng: 18.798387 },
    { lat: 49.24758, lng: 18.798585 },
    { lat: 49.247527, lng: 18.798665 },
    { lat: 49.247819, lng: 18.799287 },
    { lat: 49.248241, lng: 18.7999 },
    { lat: 49.248482, lng: 18.800249 },
    { lat: 49.248712, lng: 18.801028 },
    { lat: 49.248714, lng: 18.801035 },
  ],
};

export default UnitsKysuckéNovéMesto;
