const UnitsKežmarok = {
  Havka: [
    { lat: 49.3582397, lng: 20.394759 },
    { lat: 49.358185, lng: 20.3947411 },
    { lat: 49.3559902, lng: 20.3940428 },
    { lat: 49.3560739, lng: 20.393101 },
    { lat: 49.3561588, lng: 20.3927273 },
    { lat: 49.356528, lng: 20.3917168 },
    { lat: 49.3563668, lng: 20.3905516 },
    { lat: 49.3563778, lng: 20.389803 },
    { lat: 49.3561947, lng: 20.3881229 },
    { lat: 49.356275, lng: 20.3870912 },
    { lat: 49.3563207, lng: 20.3868108 },
    { lat: 49.3564655, lng: 20.3864162 },
    { lat: 49.3564099, lng: 20.3862582 },
    { lat: 49.3563399, lng: 20.3856263 },
    { lat: 49.3562461, lng: 20.3853493 },
    { lat: 49.3561816, lng: 20.3848668 },
    { lat: 49.3560841, lng: 20.3845244 },
    { lat: 49.3558862, lng: 20.3841451 },
    { lat: 49.3554164, lng: 20.3835411 },
    { lat: 49.3550794, lng: 20.3837123 },
    { lat: 49.3531589, lng: 20.3862685 },
    { lat: 49.3524809, lng: 20.3869106 },
    { lat: 49.3522522, lng: 20.3872617 },
    { lat: 49.3521052, lng: 20.3877215 },
    { lat: 49.3513756, lng: 20.3894717 },
    { lat: 49.3507617, lng: 20.3919281 },
    { lat: 49.3503512, lng: 20.3924752 },
    { lat: 49.3501509, lng: 20.3942877 },
    { lat: 49.3493872, lng: 20.3962975 },
    { lat: 49.348702, lng: 20.3974434 },
    { lat: 49.3480365, lng: 20.3983286 },
    { lat: 49.3475292, lng: 20.3973282 },
    { lat: 49.3474844, lng: 20.3974286 },
    { lat: 49.3473365, lng: 20.3975346 },
    { lat: 49.3472775, lng: 20.3971917 },
    { lat: 49.3471047, lng: 20.397059 },
    { lat: 49.3471259, lng: 20.396939 },
    { lat: 49.3468741, lng: 20.3970068 },
    { lat: 49.3468001, lng: 20.396865 },
    { lat: 49.346576, lng: 20.3968646 },
    { lat: 49.3464691, lng: 20.3970149 },
    { lat: 49.3462482, lng: 20.3967431 },
    { lat: 49.3463324, lng: 20.3965873 },
    { lat: 49.3463167, lng: 20.396498 },
    { lat: 49.3462181, lng: 20.3965718 },
    { lat: 49.3460656, lng: 20.3963192 },
    { lat: 49.345805, lng: 20.3965163 },
    { lat: 49.3457019, lng: 20.3961973 },
    { lat: 49.3454016, lng: 20.3961185 },
    { lat: 49.3453113, lng: 20.3959022 },
    { lat: 49.3448037, lng: 20.396052 },
    { lat: 49.3447556, lng: 20.3958302 },
    { lat: 49.3446388, lng: 20.3959521 },
    { lat: 49.3445758, lng: 20.3958032 },
    { lat: 49.3444128, lng: 20.3957797 },
    { lat: 49.3442448, lng: 20.3958661 },
    { lat: 49.3440727, lng: 20.3957814 },
    { lat: 49.3436716, lng: 20.3951457 },
    { lat: 49.3432717, lng: 20.3951133 },
    { lat: 49.3432068, lng: 20.3952123 },
    { lat: 49.3429988, lng: 20.3948036 },
    { lat: 49.3428594, lng: 20.3948972 },
    { lat: 49.3426454, lng: 20.3946858 },
    { lat: 49.3423017, lng: 20.3945874 },
    { lat: 49.3422404, lng: 20.3944564 },
    { lat: 49.34212, lng: 20.3945786 },
    { lat: 49.3419277, lng: 20.3942852 },
    { lat: 49.3413458, lng: 20.3940519 },
    { lat: 49.341058, lng: 20.394097 },
    { lat: 49.3409768, lng: 20.3940031 },
    { lat: 49.3409287, lng: 20.3940398 },
    { lat: 49.3408573, lng: 20.3939678 },
    { lat: 49.3405442, lng: 20.3939032 },
    { lat: 49.3404761, lng: 20.3937995 },
    { lat: 49.3404401, lng: 20.3939211 },
    { lat: 49.3401616, lng: 20.3936133 },
    { lat: 49.3400118, lng: 20.3936392 },
    { lat: 49.3399681, lng: 20.3935561 },
    { lat: 49.3398374, lng: 20.3935554 },
    { lat: 49.3389652, lng: 20.393175 },
    { lat: 49.3383626, lng: 20.3933494 },
    { lat: 49.3381037, lng: 20.3932908 },
    { lat: 49.33754, lng: 20.393443 },
    { lat: 49.3373876, lng: 20.3936114 },
    { lat: 49.3374046, lng: 20.3937049 },
    { lat: 49.3364405, lng: 20.3947031 },
    { lat: 49.3352571, lng: 20.3955611 },
    { lat: 49.3345839, lng: 20.3958335 },
    { lat: 49.3337863, lng: 20.3960584 },
    { lat: 49.3336593, lng: 20.3962674 },
    { lat: 49.3333348, lng: 20.3962024 },
    { lat: 49.3321963, lng: 20.3962524 },
    { lat: 49.3314763, lng: 20.3961531 },
    { lat: 49.3302012, lng: 20.3961925 },
    { lat: 49.3298182, lng: 20.3963077 },
    { lat: 49.3289789, lng: 20.3967659 },
    { lat: 49.3287405, lng: 20.3967426 },
    { lat: 49.3282497, lng: 20.3968655 },
    { lat: 49.3274875, lng: 20.3973106 },
    { lat: 49.3274401, lng: 20.3974282 },
    { lat: 49.3273561, lng: 20.3974714 },
    { lat: 49.3272585, lng: 20.3973862 },
    { lat: 49.3270849, lng: 20.3974983 },
    { lat: 49.3269649, lng: 20.3974526 },
    { lat: 49.3266005, lng: 20.3975228 },
    { lat: 49.3261023, lng: 20.3974814 },
    { lat: 49.3251558, lng: 20.3971875 },
    { lat: 49.3241898, lng: 20.3973655 },
    { lat: 49.3239474, lng: 20.3976851 },
    { lat: 49.3231972, lng: 20.3984456 },
    { lat: 49.3220741, lng: 20.3992663 },
    { lat: 49.3219696, lng: 20.3994152 },
    { lat: 49.3214712, lng: 20.4009134 },
    { lat: 49.3213481, lng: 20.4014686 },
    { lat: 49.3213378, lng: 20.4019076 },
    { lat: 49.3211559, lng: 20.4033547 },
    { lat: 49.3211081, lng: 20.4041118 },
    { lat: 49.321063, lng: 20.40414 },
    { lat: 49.321096, lng: 20.404356 },
    { lat: 49.321155, lng: 20.404498 },
    { lat: 49.321164, lng: 20.404519 },
    { lat: 49.321225, lng: 20.404702 },
    { lat: 49.321239, lng: 20.404742 },
    { lat: 49.321302, lng: 20.405037 },
    { lat: 49.321433, lng: 20.405252 },
    { lat: 49.321574, lng: 20.405485 },
    { lat: 49.321745, lng: 20.405765 },
    { lat: 49.321857, lng: 20.405834 },
    { lat: 49.321937, lng: 20.405983 },
    { lat: 49.322139, lng: 20.40635 },
    { lat: 49.322232, lng: 20.406391 },
    { lat: 49.322283, lng: 20.406414 },
    { lat: 49.322412, lng: 20.406602 },
    { lat: 49.322737, lng: 20.406874 },
    { lat: 49.322876, lng: 20.407034 },
    { lat: 49.322952, lng: 20.407095 },
    { lat: 49.32298, lng: 20.40711 },
    { lat: 49.323178, lng: 20.407215 },
    { lat: 49.32332, lng: 20.407309 },
    { lat: 49.323389, lng: 20.407448 },
    { lat: 49.323593, lng: 20.407663 },
    { lat: 49.323669, lng: 20.407791 },
    { lat: 49.323738, lng: 20.4079 },
    { lat: 49.323929, lng: 20.408112 },
    { lat: 49.32393, lng: 20.408113 },
    { lat: 49.323953, lng: 20.408139 },
    { lat: 49.324047, lng: 20.408171 },
    { lat: 49.324251, lng: 20.40801 },
    { lat: 49.324293, lng: 20.408048 },
    { lat: 49.324423, lng: 20.407997 },
    { lat: 49.324552, lng: 20.408007 },
    { lat: 49.324749, lng: 20.408108 },
    { lat: 49.324958, lng: 20.408264 },
    { lat: 49.325049, lng: 20.408421 },
    { lat: 49.325105, lng: 20.408668 },
    { lat: 49.325241, lng: 20.408916 },
    { lat: 49.325325, lng: 20.409092 },
    { lat: 49.325374, lng: 20.409296 },
    { lat: 49.325472, lng: 20.409544 },
    { lat: 49.325594, lng: 20.409739 },
    { lat: 49.325671, lng: 20.409812 },
    { lat: 49.325872, lng: 20.409991 },
    { lat: 49.32612, lng: 20.410148 },
    { lat: 49.326157, lng: 20.410238 },
    { lat: 49.326189, lng: 20.41052 },
    { lat: 49.326398, lng: 20.410622 },
    { lat: 49.326698, lng: 20.410887 },
    { lat: 49.32677, lng: 20.410794 },
    { lat: 49.32699, lng: 20.410591 },
    { lat: 49.327078, lng: 20.410509 },
    { lat: 49.32716, lng: 20.410464 },
    { lat: 49.327539, lng: 20.410356 },
    { lat: 49.327774, lng: 20.410339 },
    { lat: 49.328081, lng: 20.410366 },
    { lat: 49.32828, lng: 20.41036 },
    { lat: 49.32837, lng: 20.410374 },
    { lat: 49.328431, lng: 20.410453 },
    { lat: 49.328565, lng: 20.410702 },
    { lat: 49.328516, lng: 20.410838 },
    { lat: 49.328433, lng: 20.411071 },
    { lat: 49.328298, lng: 20.411452 },
    { lat: 49.328407, lng: 20.411503 },
    { lat: 49.328536, lng: 20.411704 },
    { lat: 49.328618, lng: 20.411981 },
    { lat: 49.32871, lng: 20.412102 },
    { lat: 49.328833, lng: 20.412268 },
    { lat: 49.329141, lng: 20.412593 },
    { lat: 49.329258, lng: 20.412638 },
    { lat: 49.329296, lng: 20.412621 },
    { lat: 49.329368, lng: 20.412588 },
    { lat: 49.329429, lng: 20.412614 },
    { lat: 49.329575, lng: 20.412833 },
    { lat: 49.329708, lng: 20.412927 },
    { lat: 49.329888, lng: 20.413056 },
    { lat: 49.330106, lng: 20.413045 },
    { lat: 49.330197, lng: 20.412994 },
    { lat: 49.330378, lng: 20.413172 },
    { lat: 49.330504, lng: 20.413421 },
    { lat: 49.330661, lng: 20.41358 },
    { lat: 49.330715, lng: 20.413683 },
    { lat: 49.330761, lng: 20.413936 },
    { lat: 49.330872, lng: 20.413861 },
    { lat: 49.331367, lng: 20.413776 },
    { lat: 49.331705, lng: 20.413718 },
    { lat: 49.332039, lng: 20.413441 },
    { lat: 49.332297, lng: 20.413395 },
    { lat: 49.332479, lng: 20.413448 },
    { lat: 49.332644, lng: 20.413722 },
    { lat: 49.332792, lng: 20.413684 },
    { lat: 49.332926, lng: 20.413592 },
    { lat: 49.333017, lng: 20.413798 },
    { lat: 49.333122, lng: 20.414147 },
    { lat: 49.333193, lng: 20.414352 },
    { lat: 49.333168, lng: 20.414501 },
    { lat: 49.33329, lng: 20.415095 },
    { lat: 49.333375, lng: 20.415444 },
    { lat: 49.333446, lng: 20.415733 },
    { lat: 49.333461, lng: 20.415752 },
    { lat: 49.333568, lng: 20.415892 },
    { lat: 49.333607, lng: 20.416185 },
    { lat: 49.333713, lng: 20.416212 },
    { lat: 49.333889, lng: 20.416438 },
    { lat: 49.333958, lng: 20.416484 },
    { lat: 49.334032, lng: 20.416532 },
    { lat: 49.334144, lng: 20.416863 },
    { lat: 49.334265, lng: 20.41722 },
    { lat: 49.334326, lng: 20.417335 },
    { lat: 49.33447, lng: 20.417509 },
    { lat: 49.33449, lng: 20.417534 },
    { lat: 49.334753, lng: 20.417688 },
    { lat: 49.334777, lng: 20.417766 },
    { lat: 49.334784, lng: 20.41779 },
    { lat: 49.334928, lng: 20.417822 },
    { lat: 49.335433, lng: 20.417645 },
    { lat: 49.335562, lng: 20.417522 },
    { lat: 49.335688, lng: 20.417393 },
    { lat: 49.335916, lng: 20.417115 },
    { lat: 49.335979, lng: 20.41704 },
    { lat: 49.336314, lng: 20.416799 },
    { lat: 49.336385, lng: 20.416748 },
    { lat: 49.336485, lng: 20.416952 },
    { lat: 49.336602, lng: 20.417068 },
    { lat: 49.336764, lng: 20.417303 },
    { lat: 49.336934, lng: 20.417479 },
    { lat: 49.337039, lng: 20.417658 },
    { lat: 49.33705, lng: 20.417678 },
    { lat: 49.33707, lng: 20.417678 },
    { lat: 49.337136, lng: 20.417694 },
    { lat: 49.33723, lng: 20.417716 },
    { lat: 49.337342, lng: 20.417743 },
    { lat: 49.337352, lng: 20.417748 },
    { lat: 49.337774, lng: 20.417957 },
    { lat: 49.3378, lng: 20.417971 },
    { lat: 49.337886, lng: 20.418056 },
    { lat: 49.338188, lng: 20.418425 },
    { lat: 49.338342, lng: 20.418583 },
    { lat: 49.338422, lng: 20.418817 },
    { lat: 49.338437, lng: 20.41886 },
    { lat: 49.338454, lng: 20.418907 },
    { lat: 49.338492, lng: 20.418997 },
    { lat: 49.338541, lng: 20.41903 },
    { lat: 49.338632, lng: 20.419089 },
    { lat: 49.338945, lng: 20.419393 },
    { lat: 49.338933, lng: 20.419428 },
    { lat: 49.33896, lng: 20.419443 },
    { lat: 49.339477, lng: 20.419712 },
    { lat: 49.339522, lng: 20.419775 },
    { lat: 49.339573, lng: 20.419845 },
    { lat: 49.339838, lng: 20.419885 },
    { lat: 49.339982, lng: 20.419947 },
    { lat: 49.340013, lng: 20.420014 },
    { lat: 49.340274, lng: 20.420179 },
    { lat: 49.34059, lng: 20.420602 },
    { lat: 49.340617, lng: 20.420638 },
    { lat: 49.34126, lng: 20.420783 },
    { lat: 49.341275, lng: 20.420787 },
    { lat: 49.341407, lng: 20.420777 },
    { lat: 49.341648, lng: 20.420841 },
    { lat: 49.341864, lng: 20.420736 },
    { lat: 49.342036, lng: 20.420632 },
    { lat: 49.342189, lng: 20.420622 },
    { lat: 49.342462, lng: 20.42052 },
    { lat: 49.342697, lng: 20.420297 },
    { lat: 49.343061, lng: 20.420052 },
    { lat: 49.34328, lng: 20.419936 },
    { lat: 49.343546, lng: 20.419815 },
    { lat: 49.343875, lng: 20.419719 },
    { lat: 49.344137, lng: 20.419616 },
    { lat: 49.344601, lng: 20.41945 },
    { lat: 49.344828, lng: 20.419412 },
    { lat: 49.345116, lng: 20.419429 },
    { lat: 49.34535, lng: 20.419415 },
    { lat: 49.345608, lng: 20.419354 },
    { lat: 49.346086, lng: 20.419493 },
    { lat: 49.346412, lng: 20.419535 },
    { lat: 49.346456, lng: 20.419541 },
    { lat: 49.346457, lng: 20.419445 },
    { lat: 49.3464931, lng: 20.4189928 },
    { lat: 49.3469249, lng: 20.4189829 },
    { lat: 49.3474256, lng: 20.4192431 },
    { lat: 49.3480008, lng: 20.4186117 },
    { lat: 49.3483394, lng: 20.4185757 },
    { lat: 49.3483693, lng: 20.4181744 },
    { lat: 49.3487988, lng: 20.4182766 },
    { lat: 49.3490126, lng: 20.4184531 },
    { lat: 49.3494544, lng: 20.4183765 },
    { lat: 49.3495188, lng: 20.4182968 },
    { lat: 49.3495498, lng: 20.418072 },
    { lat: 49.3497011, lng: 20.4177567 },
    { lat: 49.3505725, lng: 20.4165275 },
    { lat: 49.3510902, lng: 20.4160474 },
    { lat: 49.3516001, lng: 20.4154487 },
    { lat: 49.3516481, lng: 20.4151079 },
    { lat: 49.3516089, lng: 20.4147579 },
    { lat: 49.3517376, lng: 20.4145533 },
    { lat: 49.3522066, lng: 20.4143049 },
    { lat: 49.3522308, lng: 20.4144393 },
    { lat: 49.3525749, lng: 20.4145172 },
    { lat: 49.3529857, lng: 20.4144578 },
    { lat: 49.3530762, lng: 20.4145556 },
    { lat: 49.3537114, lng: 20.414445 },
    { lat: 49.3539653, lng: 20.4143417 },
    { lat: 49.354189, lng: 20.4141236 },
    { lat: 49.355511, lng: 20.4136829 },
    { lat: 49.3558476, lng: 20.4134444 },
    { lat: 49.3559072, lng: 20.4134987 },
    { lat: 49.3561692, lng: 20.4133701 },
    { lat: 49.3559222, lng: 20.4127021 },
    { lat: 49.3556555, lng: 20.4117553 },
    { lat: 49.3562331, lng: 20.4111881 },
    { lat: 49.3562503, lng: 20.4112234 },
    { lat: 49.3569567, lng: 20.4102259 },
    { lat: 49.3571913, lng: 20.4099986 },
    { lat: 49.3580863, lng: 20.4096096 },
    { lat: 49.3587234, lng: 20.409157 },
    { lat: 49.358857, lng: 20.4090256 },
    { lat: 49.358833, lng: 20.4089595 },
    { lat: 49.3589335, lng: 20.4088224 },
    { lat: 49.359516, lng: 20.408293 },
    { lat: 49.3606313, lng: 20.4070028 },
    { lat: 49.3604759, lng: 20.4063162 },
    { lat: 49.3604468, lng: 20.4058452 },
    { lat: 49.3603466, lng: 20.4055167 },
    { lat: 49.359065, lng: 20.4065754 },
    { lat: 49.3583112, lng: 20.4070055 },
    { lat: 49.3577539, lng: 20.407485 },
    { lat: 49.3570735, lng: 20.4079515 },
    { lat: 49.3566475, lng: 20.4083854 },
    { lat: 49.355278, lng: 20.4052428 },
    { lat: 49.3553419, lng: 20.405148 },
    { lat: 49.3556083, lng: 20.4050749 },
    { lat: 49.3558155, lng: 20.4048084 },
    { lat: 49.3560465, lng: 20.4042481 },
    { lat: 49.3566037, lng: 20.4035898 },
    { lat: 49.3559738, lng: 20.4025361 },
    { lat: 49.356015, lng: 20.4023165 },
    { lat: 49.3560027, lng: 20.4017766 },
    { lat: 49.3568321, lng: 20.3998666 },
    { lat: 49.3572599, lng: 20.3991522 },
    { lat: 49.357498, lng: 20.3989288 },
    { lat: 49.3571011, lng: 20.3986704 },
    { lat: 49.357157, lng: 20.398279 },
    { lat: 49.3573845, lng: 20.3979818 },
    { lat: 49.3582411, lng: 20.3974706 },
    { lat: 49.359163, lng: 20.3966639 },
    { lat: 49.3590152, lng: 20.3965676 },
    { lat: 49.3585451, lng: 20.39533 },
    { lat: 49.3581682, lng: 20.3950391 },
    { lat: 49.3581917, lng: 20.3948875 },
    { lat: 49.3582397, lng: 20.394759 },
  ],
  Jurské: [
    { lat: 49.2094505, lng: 20.512318 },
    { lat: 49.2091035, lng: 20.513435 },
    { lat: 49.2084257, lng: 20.5141919 },
    { lat: 49.2064256, lng: 20.5145682 },
    { lat: 49.2054953, lng: 20.5152611 },
    { lat: 49.2040336, lng: 20.51683 },
    { lat: 49.2029931, lng: 20.517313 },
    { lat: 49.202861, lng: 20.5173327 },
    { lat: 49.2024568, lng: 20.5171489 },
    { lat: 49.201703, lng: 20.5174031 },
    { lat: 49.201246, lng: 20.5178356 },
    { lat: 49.2005458, lng: 20.5187184 },
    { lat: 49.1997517, lng: 20.5195355 },
    { lat: 49.1976869, lng: 20.5207196 },
    { lat: 49.1977724, lng: 20.5214513 },
    { lat: 49.1977466, lng: 20.522063 },
    { lat: 49.1978775, lng: 20.5234427 },
    { lat: 49.1975117, lng: 20.5249411 },
    { lat: 49.1975036, lng: 20.525701 },
    { lat: 49.1974446, lng: 20.5263573 },
    { lat: 49.1974863, lng: 20.5268746 },
    { lat: 49.1976784, lng: 20.5279658 },
    { lat: 49.1975882, lng: 20.5282064 },
    { lat: 49.1974949, lng: 20.5282987 },
    { lat: 49.197451, lng: 20.5284849 },
    { lat: 49.1971918, lng: 20.5288455 },
    { lat: 49.197119, lng: 20.5293709 },
    { lat: 49.1970161, lng: 20.5296389 },
    { lat: 49.196594, lng: 20.5304001 },
    { lat: 49.196325, lng: 20.5305589 },
    { lat: 49.1958453, lng: 20.5312271 },
    { lat: 49.1955329, lng: 20.5315458 },
    { lat: 49.1951491, lng: 20.5321302 },
    { lat: 49.1946668, lng: 20.5324858 },
    { lat: 49.1939086, lng: 20.5334165 },
    { lat: 49.1936435, lng: 20.5335594 },
    { lat: 49.193189, lng: 20.5341178 },
    { lat: 49.1927598, lng: 20.5343842 },
    { lat: 49.192423, lng: 20.5348356 },
    { lat: 49.1920525, lng: 20.5357813 },
    { lat: 49.1924687, lng: 20.5365087 },
    { lat: 49.192445, lng: 20.5366903 },
    { lat: 49.1922852, lng: 20.536785 },
    { lat: 49.1923756, lng: 20.5371574 },
    { lat: 49.1926714, lng: 20.5373941 },
    { lat: 49.1926782, lng: 20.5379777 },
    { lat: 49.1928143, lng: 20.5384066 },
    { lat: 49.1933733, lng: 20.5391283 },
    { lat: 49.1942365, lng: 20.5405864 },
    { lat: 49.1945945, lng: 20.5412958 },
    { lat: 49.1946017, lng: 20.5418269 },
    { lat: 49.1947649, lng: 20.5420674 },
    { lat: 49.1950369, lng: 20.5422418 },
    { lat: 49.1953096, lng: 20.5426475 },
    { lat: 49.1956169, lng: 20.5433185 },
    { lat: 49.1961617, lng: 20.5442398 },
    { lat: 49.1961973, lng: 20.5446576 },
    { lat: 49.1960713, lng: 20.5451835 },
    { lat: 49.196006, lng: 20.5452992 },
    { lat: 49.1960585, lng: 20.5456882 },
    { lat: 49.1962851, lng: 20.5464978 },
    { lat: 49.1966144, lng: 20.5472814 },
    { lat: 49.1966444, lng: 20.5481561 },
    { lat: 49.1965342, lng: 20.5483145 },
    { lat: 49.1965691, lng: 20.5488033 },
    { lat: 49.1965207, lng: 20.5490853 },
    { lat: 49.1965268, lng: 20.5494318 },
    { lat: 49.1965965, lng: 20.5497372 },
    { lat: 49.1965856, lng: 20.5499617 },
    { lat: 49.1967728, lng: 20.5504612 },
    { lat: 49.196798, lng: 20.5506592 },
    { lat: 49.196757, lng: 20.5507055 },
    { lat: 49.1967011, lng: 20.5512729 },
    { lat: 49.1965686, lng: 20.5518332 },
    { lat: 49.1958759, lng: 20.5531781 },
    { lat: 49.1955768, lng: 20.554228 },
    { lat: 49.1954215, lng: 20.5543547 },
    { lat: 49.1952538, lng: 20.5546641 },
    { lat: 49.1956066, lng: 20.5557819 },
    { lat: 49.1957616, lng: 20.5561431 },
    { lat: 49.1957745, lng: 20.5562446 },
    { lat: 49.1957155, lng: 20.5563839 },
    { lat: 49.195581, lng: 20.5565264 },
    { lat: 49.195645, lng: 20.556675 },
    { lat: 49.1955301, lng: 20.5567334 },
    { lat: 49.1956232, lng: 20.5570026 },
    { lat: 49.1961437, lng: 20.557964 },
    { lat: 49.1962709, lng: 20.5584304 },
    { lat: 49.1963019, lng: 20.5589371 },
    { lat: 49.1961896, lng: 20.5602951 },
    { lat: 49.1961779, lng: 20.5609318 },
    { lat: 49.1962525, lng: 20.5623571 },
    { lat: 49.1964082, lng: 20.5633197 },
    { lat: 49.1964286, lng: 20.5642517 },
    { lat: 49.1966172, lng: 20.5650938 },
    { lat: 49.1968698, lng: 20.565605 },
    { lat: 49.1974801, lng: 20.5661928 },
    { lat: 49.1982357, lng: 20.5665994 },
    { lat: 49.1993945, lng: 20.5674083 },
    { lat: 49.2012735, lng: 20.5701638 },
    { lat: 49.2016853, lng: 20.5716016 },
    { lat: 49.2019244, lng: 20.5731005 },
    { lat: 49.2024989, lng: 20.5761326 },
    { lat: 49.2027994, lng: 20.5789738 },
    { lat: 49.2021779, lng: 20.5830866 },
    { lat: 49.2021552, lng: 20.5832415 },
    { lat: 49.2022104, lng: 20.583117 },
    { lat: 49.2023872, lng: 20.5828363 },
    { lat: 49.2028229, lng: 20.5815052 },
    { lat: 49.2042783, lng: 20.5802071 },
    { lat: 49.2042011, lng: 20.5796931 },
    { lat: 49.2042006, lng: 20.5794 },
    { lat: 49.2040121, lng: 20.5784623 },
    { lat: 49.2036377, lng: 20.577418 },
    { lat: 49.2035127, lng: 20.5766481 },
    { lat: 49.2035097, lng: 20.5759435 },
    { lat: 49.2035557, lng: 20.5755101 },
    { lat: 49.2039101, lng: 20.5741782 },
    { lat: 49.2047478, lng: 20.5720841 },
    { lat: 49.204998, lng: 20.571808 },
    { lat: 49.2049331, lng: 20.5698744 },
    { lat: 49.2052373, lng: 20.5677845 },
    { lat: 49.2061922, lng: 20.56604 },
    { lat: 49.2069038, lng: 20.5651442 },
    { lat: 49.208415, lng: 20.5648485 },
    { lat: 49.2088701, lng: 20.5644868 },
    { lat: 49.2095976, lng: 20.5629263 },
    { lat: 49.2101544, lng: 20.561281 },
    { lat: 49.2102662, lng: 20.5604876 },
    { lat: 49.210452, lng: 20.5600595 },
    { lat: 49.2106239, lng: 20.5598302 },
    { lat: 49.21102, lng: 20.5597688 },
    { lat: 49.2116775, lng: 20.5584802 },
    { lat: 49.2117839, lng: 20.5577251 },
    { lat: 49.2112839, lng: 20.5549188 },
    { lat: 49.2115888, lng: 20.5535933 },
    { lat: 49.2119869, lng: 20.5523545 },
    { lat: 49.212173, lng: 20.5510718 },
    { lat: 49.2121851, lng: 20.549651 },
    { lat: 49.2120916, lng: 20.5488186 },
    { lat: 49.2121718, lng: 20.5475698 },
    { lat: 49.2120148, lng: 20.5466934 },
    { lat: 49.2122624, lng: 20.5446407 },
    { lat: 49.2128159, lng: 20.5432477 },
    { lat: 49.2128269, lng: 20.5429609 },
    { lat: 49.2126752, lng: 20.5415605 },
    { lat: 49.212789, lng: 20.5401561 },
    { lat: 49.2131525, lng: 20.5395726 },
    { lat: 49.2134024, lng: 20.5388941 },
    { lat: 49.2138513, lng: 20.5367437 },
    { lat: 49.2140789, lng: 20.5353425 },
    { lat: 49.2141638, lng: 20.5351055 },
    { lat: 49.2143893, lng: 20.5346299 },
    { lat: 49.2152716, lng: 20.5332118 },
    { lat: 49.2156503, lng: 20.5323946 },
    { lat: 49.2160129, lng: 20.5319714 },
    { lat: 49.2159041, lng: 20.5316181 },
    { lat: 49.2162086, lng: 20.5312329 },
    { lat: 49.2164656, lng: 20.5304798 },
    { lat: 49.2163983, lng: 20.5302717 },
    { lat: 49.2163186, lng: 20.5292086 },
    { lat: 49.2159594, lng: 20.528745 },
    { lat: 49.2160732, lng: 20.5282909 },
    { lat: 49.2163049, lng: 20.5277934 },
    { lat: 49.2166338, lng: 20.5272812 },
    { lat: 49.2164757, lng: 20.5268709 },
    { lat: 49.2168057, lng: 20.5255321 },
    { lat: 49.2166417, lng: 20.5251425 },
    { lat: 49.2166071, lng: 20.5249391 },
    { lat: 49.2164719, lng: 20.5246875 },
    { lat: 49.2165179, lng: 20.5244703 },
    { lat: 49.2164904, lng: 20.5237903 },
    { lat: 49.2165784, lng: 20.5232458 },
    { lat: 49.2165614, lng: 20.5231169 },
    { lat: 49.216398, lng: 20.5229012 },
    { lat: 49.2163003, lng: 20.5222945 },
    { lat: 49.2160895, lng: 20.5220731 },
    { lat: 49.2158422, lng: 20.5214768 },
    { lat: 49.2165867, lng: 20.5193788 },
    { lat: 49.2153548, lng: 20.517143 },
    { lat: 49.2152625, lng: 20.5172701 },
    { lat: 49.2148196, lng: 20.5167997 },
    { lat: 49.2144953, lng: 20.5161568 },
    { lat: 49.2144166, lng: 20.5161599 },
    { lat: 49.2140942, lng: 20.5154156 },
    { lat: 49.2130506, lng: 20.5152948 },
    { lat: 49.2130188, lng: 20.5154103 },
    { lat: 49.2120391, lng: 20.5152775 },
    { lat: 49.2120243, lng: 20.515547 },
    { lat: 49.2117906, lng: 20.515498 },
    { lat: 49.211337, lng: 20.5155327 },
    { lat: 49.2109286, lng: 20.5150847 },
    { lat: 49.2107327, lng: 20.5147803 },
    { lat: 49.2099441, lng: 20.5129377 },
    { lat: 49.2094843, lng: 20.512377 },
    { lat: 49.2094505, lng: 20.512318 },
  ],
  Ihľany: [
    { lat: 49.1976869, lng: 20.5207196 },
    { lat: 49.1972507, lng: 20.5194867 },
    { lat: 49.1955714, lng: 20.5204735 },
    { lat: 49.1953452, lng: 20.5194076 },
    { lat: 49.1951498, lng: 20.5189907 },
    { lat: 49.1936885, lng: 20.5197862 },
    { lat: 49.1922212, lng: 20.5175468 },
    { lat: 49.1920241, lng: 20.5173643 },
    { lat: 49.1916356, lng: 20.5167844 },
    { lat: 49.1913501, lng: 20.5164584 },
    { lat: 49.1907403, lng: 20.5159216 },
    { lat: 49.1905141, lng: 20.5154379 },
    { lat: 49.1902696, lng: 20.5150659 },
    { lat: 49.19009, lng: 20.5149309 },
    { lat: 49.1898098, lng: 20.5145361 },
    { lat: 49.1888799, lng: 20.5144412 },
    { lat: 49.1888445, lng: 20.5147667 },
    { lat: 49.1887723, lng: 20.5148233 },
    { lat: 49.1885901, lng: 20.5141897 },
    { lat: 49.1883805, lng: 20.5137276 },
    { lat: 49.1880378, lng: 20.5134112 },
    { lat: 49.187742, lng: 20.5134173 },
    { lat: 49.1875673, lng: 20.5135612 },
    { lat: 49.1870354, lng: 20.5137293 },
    { lat: 49.1867142, lng: 20.5136933 },
    { lat: 49.186482, lng: 20.5137741 },
    { lat: 49.1862251, lng: 20.5136785 },
    { lat: 49.1856967, lng: 20.5129347 },
    { lat: 49.1855837, lng: 20.5126231 },
    { lat: 49.1850022, lng: 20.5121499 },
    { lat: 49.1848077, lng: 20.512122 },
    { lat: 49.1844407, lng: 20.5118185 },
    { lat: 49.1840839, lng: 20.511643 },
    { lat: 49.1839392, lng: 20.5118711 },
    { lat: 49.1831499, lng: 20.5114075 },
    { lat: 49.1828418, lng: 20.5110806 },
    { lat: 49.182394, lng: 20.5111818 },
    { lat: 49.1814045, lng: 20.5106376 },
    { lat: 49.1791752, lng: 20.5110294 },
    { lat: 49.1786824, lng: 20.5113993 },
    { lat: 49.1785893, lng: 20.5115045 },
    { lat: 49.17843, lng: 20.5119111 },
    { lat: 49.1782293, lng: 20.5126625 },
    { lat: 49.1778446, lng: 20.5134304 },
    { lat: 49.1777766, lng: 20.5138757 },
    { lat: 49.1778855, lng: 20.5143578 },
    { lat: 49.1779841, lng: 20.5145235 },
    { lat: 49.1782707, lng: 20.5146946 },
    { lat: 49.1784355, lng: 20.5151968 },
    { lat: 49.1784428, lng: 20.5156352 },
    { lat: 49.1785237, lng: 20.5158136 },
    { lat: 49.1784832, lng: 20.5166965 },
    { lat: 49.1784209, lng: 20.5171427 },
    { lat: 49.1784448, lng: 20.5175473 },
    { lat: 49.1783794, lng: 20.5180624 },
    { lat: 49.1782372, lng: 20.5184212 },
    { lat: 49.1780052, lng: 20.5187502 },
    { lat: 49.177637, lng: 20.5195696 },
    { lat: 49.1775413, lng: 20.5198473 },
    { lat: 49.1774243, lng: 20.5205504 },
    { lat: 49.1773657, lng: 20.5206681 },
    { lat: 49.1772977, lng: 20.5210575 },
    { lat: 49.1774208, lng: 20.5223449 },
    { lat: 49.1773671, lng: 20.5225971 },
    { lat: 49.1768351, lng: 20.5213625 },
    { lat: 49.1760261, lng: 20.5196051 },
    { lat: 49.175031, lng: 20.5178102 },
    { lat: 49.1749105, lng: 20.5175684 },
    { lat: 49.1749146, lng: 20.5174629 },
    { lat: 49.1747109, lng: 20.5171912 },
    { lat: 49.1742634, lng: 20.5163812 },
    { lat: 49.1740892, lng: 20.5151732 },
    { lat: 49.1737988, lng: 20.5139877 },
    { lat: 49.1736543, lng: 20.5137051 },
    { lat: 49.1724778, lng: 20.5121865 },
    { lat: 49.1723174, lng: 20.5118607 },
    { lat: 49.172066, lng: 20.5116092 },
    { lat: 49.1717186, lng: 20.5125987 },
    { lat: 49.1714208, lng: 20.5132551 },
    { lat: 49.1710845, lng: 20.5138705 },
    { lat: 49.1701791, lng: 20.5152023 },
    { lat: 49.1692189, lng: 20.516025 },
    { lat: 49.1680474, lng: 20.5174432 },
    { lat: 49.1680182, lng: 20.5173806 },
    { lat: 49.1675806, lng: 20.517783 },
    { lat: 49.1665981, lng: 20.5182823 },
    { lat: 49.165349, lng: 20.5190236 },
    { lat: 49.1649683, lng: 20.5192934 },
    { lat: 49.1645675, lng: 20.519785 },
    { lat: 49.1640573, lng: 20.520144 },
    { lat: 49.1639276, lng: 20.5201983 },
    { lat: 49.1633019, lng: 20.5202024 },
    { lat: 49.1635232, lng: 20.5207083 },
    { lat: 49.1631743, lng: 20.5209246 },
    { lat: 49.1629358, lng: 20.5206559 },
    { lat: 49.1628382, lng: 20.5208193 },
    { lat: 49.1623497, lng: 20.5201483 },
    { lat: 49.1618518, lng: 20.519718 },
    { lat: 49.1616078, lng: 20.5200879 },
    { lat: 49.1614613, lng: 20.5200883 },
    { lat: 49.1609269, lng: 20.5207612 },
    { lat: 49.161059, lng: 20.5214121 },
    { lat: 49.161079, lng: 20.5215014 },
    { lat: 49.1611805, lng: 20.5218984 },
    { lat: 49.1617213, lng: 20.5239528 },
    { lat: 49.1619199, lng: 20.5244872 },
    { lat: 49.1614429, lng: 20.5244044 },
    { lat: 49.1613848, lng: 20.5246597 },
    { lat: 49.1614479, lng: 20.5250273 },
    { lat: 49.1622846, lng: 20.5264063 },
    { lat: 49.1623975, lng: 20.527542 },
    { lat: 49.1623666, lng: 20.5286278 },
    { lat: 49.162174, lng: 20.5301552 },
    { lat: 49.1618386, lng: 20.5318664 },
    { lat: 49.1616247, lng: 20.5324775 },
    { lat: 49.1615595, lng: 20.5329079 },
    { lat: 49.1616537, lng: 20.5347949 },
    { lat: 49.1618004, lng: 20.5353507 },
    { lat: 49.1621342, lng: 20.5362232 },
    { lat: 49.1617567, lng: 20.5381106 },
    { lat: 49.1612741, lng: 20.5394656 },
    { lat: 49.1612411, lng: 20.5399803 },
    { lat: 49.1613312, lng: 20.5415129 },
    { lat: 49.1614556, lng: 20.5414954 },
    { lat: 49.1616027, lng: 20.5434116 },
    { lat: 49.1615548, lng: 20.5446506 },
    { lat: 49.1612421, lng: 20.5447831 },
    { lat: 49.1613325, lng: 20.5460171 },
    { lat: 49.1614709, lng: 20.5467215 },
    { lat: 49.1616749, lng: 20.5473429 },
    { lat: 49.1619512, lng: 20.548033 },
    { lat: 49.1625744, lng: 20.5491817 },
    { lat: 49.1625681, lng: 20.5501139 },
    { lat: 49.1652309, lng: 20.5524851 },
    { lat: 49.1668565, lng: 20.5523627 },
    { lat: 49.1677066, lng: 20.557066 },
    { lat: 49.1647552, lng: 20.5562162 },
    { lat: 49.1636331, lng: 20.5563144 },
    { lat: 49.1635121, lng: 20.5565204 },
    { lat: 49.1632029, lng: 20.5582496 },
    { lat: 49.1631858, lng: 20.5588914 },
    { lat: 49.1634954, lng: 20.5594399 },
    { lat: 49.1642294, lng: 20.560031 },
    { lat: 49.16448, lng: 20.5603856 },
    { lat: 49.1649172, lng: 20.5611362 },
    { lat: 49.1652199, lng: 20.5621181 },
    { lat: 49.1655252, lng: 20.5624936 },
    { lat: 49.1660927, lng: 20.5635749 },
    { lat: 49.1663718, lng: 20.5649236 },
    { lat: 49.1668835, lng: 20.5658651 },
    { lat: 49.1669893, lng: 20.5668828 },
    { lat: 49.1669456, lng: 20.5671315 },
    { lat: 49.1660728, lng: 20.5694005 },
    { lat: 49.1648755, lng: 20.5706523 },
    { lat: 49.164635, lng: 20.5716814 },
    { lat: 49.1643576, lng: 20.5719398 },
    { lat: 49.1640506, lng: 20.5729713 },
    { lat: 49.1638878, lng: 20.573165 },
    { lat: 49.1627967, lng: 20.5744907 },
    { lat: 49.1626761, lng: 20.5754128 },
    { lat: 49.1626636, lng: 20.5765783 },
    { lat: 49.1622421, lng: 20.5777446 },
    { lat: 49.1614271, lng: 20.5792528 },
    { lat: 49.1614283, lng: 20.5795973 },
    { lat: 49.1616509, lng: 20.5809465 },
    { lat: 49.1614252, lng: 20.5822641 },
    { lat: 49.1608214, lng: 20.5847231 },
    { lat: 49.1607142, lng: 20.585895 },
    { lat: 49.1608402, lng: 20.5876581 },
    { lat: 49.1609094, lng: 20.5879785 },
    { lat: 49.1608629, lng: 20.5889302 },
    { lat: 49.1609344, lng: 20.5894126 },
    { lat: 49.1609666, lng: 20.5915055 },
    { lat: 49.1607113, lng: 20.5935249 },
    { lat: 49.1610779, lng: 20.5949403 },
    { lat: 49.1612274, lng: 20.5963956 },
    { lat: 49.1609249, lng: 20.5978603 },
    { lat: 49.1599754, lng: 20.5993946 },
    { lat: 49.1595169, lng: 20.6004134 },
    { lat: 49.1598928, lng: 20.6010022 },
    { lat: 49.1601129, lng: 20.6018224 },
    { lat: 49.1602196, lng: 20.6028126 },
    { lat: 49.1600819, lng: 20.6056611 },
    { lat: 49.1593816, lng: 20.6071218 },
    { lat: 49.1586984, lng: 20.6092064 },
    { lat: 49.1589257, lng: 20.6104494 },
    { lat: 49.1598654, lng: 20.6118415 },
    { lat: 49.1602669, lng: 20.6127372 },
    { lat: 49.1604154, lng: 20.6138818 },
    { lat: 49.160716, lng: 20.615445 },
    { lat: 49.1606864, lng: 20.6164931 },
    { lat: 49.1605709, lng: 20.6166315 },
    { lat: 49.1593862, lng: 20.616553 },
    { lat: 49.1589915, lng: 20.6169102 },
    { lat: 49.1587482, lng: 20.616999 },
    { lat: 49.158506, lng: 20.617243 },
    { lat: 49.1573591, lng: 20.6198386 },
    { lat: 49.156808, lng: 20.6205686 },
    { lat: 49.1571179, lng: 20.621358 },
    { lat: 49.1572235, lng: 20.6222234 },
    { lat: 49.1574404, lng: 20.6230264 },
    { lat: 49.157551, lng: 20.6237242 },
    { lat: 49.1575629, lng: 20.6243033 },
    { lat: 49.1576637, lng: 20.6248168 },
    { lat: 49.1575886, lng: 20.6265149 },
    { lat: 49.1576294, lng: 20.6275812 },
    { lat: 49.1579339, lng: 20.6284458 },
    { lat: 49.1581601, lng: 20.6293153 },
    { lat: 49.1583276, lng: 20.630213 },
    { lat: 49.1575902, lng: 20.6325611 },
    { lat: 49.1575812, lng: 20.6325917 },
    { lat: 49.158013, lng: 20.6324848 },
    { lat: 49.1584542, lng: 20.6321706 },
    { lat: 49.1586298, lng: 20.6320091 },
    { lat: 49.1587428, lng: 20.6318979 },
    { lat: 49.1588992, lng: 20.6316769 },
    { lat: 49.1590558, lng: 20.6309347 },
    { lat: 49.1591063, lng: 20.6307053 },
    { lat: 49.1591909, lng: 20.6304012 },
    { lat: 49.1592147, lng: 20.6301273 },
    { lat: 49.1592111, lng: 20.6298031 },
    { lat: 49.159203, lng: 20.6294877 },
    { lat: 49.1592053, lng: 20.6291451 },
    { lat: 49.1592297, lng: 20.6288197 },
    { lat: 49.1592371, lng: 20.6285263 },
    { lat: 49.1592841, lng: 20.6281425 },
    { lat: 49.1593441, lng: 20.6276948 },
    { lat: 49.1593746, lng: 20.627467 },
    { lat: 49.1593984, lng: 20.6272895 },
    { lat: 49.1594514, lng: 20.6270689 },
    { lat: 49.1595744, lng: 20.6267003 },
    { lat: 49.1597057, lng: 20.6262847 },
    { lat: 49.1597958, lng: 20.6260042 },
    { lat: 49.159826, lng: 20.6257473 },
    { lat: 49.1597923, lng: 20.6253437 },
    { lat: 49.1597892, lng: 20.6251641 },
    { lat: 49.1598007, lng: 20.6248902 },
    { lat: 49.1598091, lng: 20.6245801 },
    { lat: 49.1598137, lng: 20.6244114 },
    { lat: 49.1598101, lng: 20.623651 },
    { lat: 49.1599996, lng: 20.6223831 },
    { lat: 49.1601176, lng: 20.6221573 },
    { lat: 49.16039, lng: 20.6217572 },
    { lat: 49.16074, lng: 20.6214073 },
    { lat: 49.1608815, lng: 20.6212771 },
    { lat: 49.1611325, lng: 20.6210536 },
    { lat: 49.1612642, lng: 20.6209314 },
    { lat: 49.1614685, lng: 20.6205394 },
    { lat: 49.1615887, lng: 20.6201905 },
    { lat: 49.1618033, lng: 20.6197697 },
    { lat: 49.1618569, lng: 20.6195695 },
    { lat: 49.161932, lng: 20.6194071 },
    { lat: 49.1620369, lng: 20.6192889 },
    { lat: 49.1622392, lng: 20.6192151 },
    { lat: 49.1626026, lng: 20.6191188 },
    { lat: 49.1628621, lng: 20.619197 },
    { lat: 49.1630395, lng: 20.6192433 },
    { lat: 49.1632949, lng: 20.619242 },
    { lat: 49.1633389, lng: 20.6192448 },
    { lat: 49.163475, lng: 20.6193042 },
    { lat: 49.1638886, lng: 20.6194443 },
    { lat: 49.1640284, lng: 20.6194484 },
    { lat: 49.164313, lng: 20.6194432 },
    { lat: 49.1645361, lng: 20.6195723 },
    { lat: 49.164876, lng: 20.6196327 },
    { lat: 49.1650215, lng: 20.6196701 },
    { lat: 49.1651277, lng: 20.6197094 },
    { lat: 49.1652568, lng: 20.6197637 },
    { lat: 49.1653851, lng: 20.6198302 },
    { lat: 49.1654295, lng: 20.6198697 },
    { lat: 49.1655544, lng: 20.6199959 },
    { lat: 49.1658086, lng: 20.6202109 },
    { lat: 49.165995, lng: 20.6203492 },
    { lat: 49.1661173, lng: 20.620403 },
    { lat: 49.1661763, lng: 20.6204182 },
    { lat: 49.1664554, lng: 20.6204131 },
    { lat: 49.1670129, lng: 20.6203893 },
    { lat: 49.1675577, lng: 20.6202346 },
    { lat: 49.1678618, lng: 20.6200454 },
    { lat: 49.1679684, lng: 20.6199476 },
    { lat: 49.1681839, lng: 20.6198321 },
    { lat: 49.1686452, lng: 20.6196412 },
    { lat: 49.1688015, lng: 20.6196054 },
    { lat: 49.1690584, lng: 20.6195788 },
    { lat: 49.1691589, lng: 20.6195865 },
    { lat: 49.1693439, lng: 20.6196592 },
    { lat: 49.1696599, lng: 20.619592 },
    { lat: 49.1698345, lng: 20.6195021 },
    { lat: 49.1700336, lng: 20.6194286 },
    { lat: 49.1703649, lng: 20.6193799 },
    { lat: 49.1705391, lng: 20.6193383 },
    { lat: 49.1706768, lng: 20.6192904 },
    { lat: 49.1708032, lng: 20.6192956 },
    { lat: 49.1712977, lng: 20.6193041 },
    { lat: 49.1716636, lng: 20.6193054 },
    { lat: 49.1719347, lng: 20.6193327 },
    { lat: 49.1719585, lng: 20.6193055 },
    { lat: 49.1719585, lng: 20.6193051 },
    { lat: 49.1732178, lng: 20.6179546 },
    { lat: 49.1736524, lng: 20.6174882 },
    { lat: 49.1748303, lng: 20.6169391 },
    { lat: 49.1754305, lng: 20.6173158 },
    { lat: 49.1757438, lng: 20.6175133 },
    { lat: 49.1761942, lng: 20.6177964 },
    { lat: 49.1762758, lng: 20.6178464 },
    { lat: 49.1764737, lng: 20.6179643 },
    { lat: 49.1765905, lng: 20.6181406 },
    { lat: 49.176578, lng: 20.6181686 },
    { lat: 49.1767641, lng: 20.6182713 },
    { lat: 49.176972, lng: 20.6183266 },
    { lat: 49.1771413, lng: 20.6183365 },
    { lat: 49.1773259, lng: 20.6183343 },
    { lat: 49.1777869, lng: 20.6182581 },
    { lat: 49.1778635, lng: 20.618229 },
    { lat: 49.1780165, lng: 20.6181698 },
    { lat: 49.1782104, lng: 20.6182003 },
    { lat: 49.1785069, lng: 20.618247 },
    { lat: 49.1786717, lng: 20.6183231 },
    { lat: 49.1788416, lng: 20.6183491 },
    { lat: 49.1793447, lng: 20.6185962 },
    { lat: 49.1796207, lng: 20.618695 },
    { lat: 49.1797161, lng: 20.6188151 },
    { lat: 49.1797324, lng: 20.6187971 },
    { lat: 49.1798796, lng: 20.6188215 },
    { lat: 49.1799297, lng: 20.6188396 },
    { lat: 49.1800867, lng: 20.6189466 },
    { lat: 49.1806493, lng: 20.6192545 },
    { lat: 49.1808287, lng: 20.6192632 },
    { lat: 49.1810474, lng: 20.6193632 },
    { lat: 49.1815081, lng: 20.6194023 },
    { lat: 49.1815426, lng: 20.6194231 },
    { lat: 49.1815839, lng: 20.619443 },
    { lat: 49.1820178, lng: 20.6194257 },
    { lat: 49.1821649, lng: 20.6194824 },
    { lat: 49.1822731, lng: 20.6194546 },
    { lat: 49.1823752, lng: 20.6194122 },
    { lat: 49.1827297, lng: 20.6192828 },
    { lat: 49.1829756, lng: 20.6191405 },
    { lat: 49.183047, lng: 20.6190924 },
    { lat: 49.1832103, lng: 20.6188146 },
    { lat: 49.1832723, lng: 20.6186604 },
    { lat: 49.1833521, lng: 20.6185 },
    { lat: 49.1833794, lng: 20.6183538 },
    { lat: 49.1834664, lng: 20.6182346 },
    { lat: 49.183535, lng: 20.6181428 },
    { lat: 49.183733, lng: 20.6179808 },
    { lat: 49.1839388, lng: 20.6178534 },
    { lat: 49.1840748, lng: 20.6177862 },
    { lat: 49.1842014, lng: 20.6177381 },
    { lat: 49.184283, lng: 20.6176859 },
    { lat: 49.1843354, lng: 20.6176409 },
    { lat: 49.184459, lng: 20.6175405 },
    { lat: 49.1845239, lng: 20.6175031 },
    { lat: 49.1849854, lng: 20.6174085 },
    { lat: 49.1851878, lng: 20.6173372 },
    { lat: 49.1866407, lng: 20.6165454 },
    { lat: 49.1866782, lng: 20.6165268 },
    { lat: 49.1885177, lng: 20.6155265 },
    { lat: 49.1884566, lng: 20.6153566 },
    { lat: 49.1881836, lng: 20.6145972 },
    { lat: 49.1880589, lng: 20.6143911 },
    { lat: 49.1879977, lng: 20.6140694 },
    { lat: 49.1878041, lng: 20.6129287 },
    { lat: 49.1877146, lng: 20.6124018 },
    { lat: 49.1876541, lng: 20.6117216 },
    { lat: 49.1876537, lng: 20.6116273 },
    { lat: 49.1876506, lng: 20.6109118 },
    { lat: 49.1876983, lng: 20.610453 },
    { lat: 49.1877962, lng: 20.6095106 },
    { lat: 49.1878084, lng: 20.6093928 },
    { lat: 49.1877463, lng: 20.6088869 },
    { lat: 49.1877621, lng: 20.6086611 },
    { lat: 49.1878145, lng: 20.6079099 },
    { lat: 49.1878065, lng: 20.60755 },
    { lat: 49.1877835, lng: 20.6065237 },
    { lat: 49.1876669, lng: 20.6048874 },
    { lat: 49.1882136, lng: 20.6041611 },
    { lat: 49.1883233, lng: 20.6038355 },
    { lat: 49.1885863, lng: 20.6035103 },
    { lat: 49.1887397, lng: 20.6031137 },
    { lat: 49.1893388, lng: 20.6026569 },
    { lat: 49.1898736, lng: 20.6020957 },
    { lat: 49.1902381, lng: 20.601686 },
    { lat: 49.1903088, lng: 20.6015227 },
    { lat: 49.1909435, lng: 20.6006486 },
    { lat: 49.1911547, lng: 20.600448 },
    { lat: 49.1922666, lng: 20.6000986 },
    { lat: 49.1925842, lng: 20.5997961 },
    { lat: 49.1931474, lng: 20.5997724 },
    { lat: 49.1936779, lng: 20.5994076 },
    { lat: 49.1943403, lng: 20.5987377 },
    { lat: 49.194776, lng: 20.5981207 },
    { lat: 49.194968, lng: 20.5979504 },
    { lat: 49.1955808, lng: 20.5967177 },
    { lat: 49.1964311, lng: 20.5953641 },
    { lat: 49.1969585, lng: 20.5947242 },
    { lat: 49.1982005, lng: 20.593678 },
    { lat: 49.198805, lng: 20.5922521 },
    { lat: 49.1989234, lng: 20.5917306 },
    { lat: 49.1991787, lng: 20.5911673 },
    { lat: 49.1992595, lng: 20.5907213 },
    { lat: 49.1993474, lng: 20.5906019 },
    { lat: 49.1994053, lng: 20.5903972 },
    { lat: 49.1999212, lng: 20.5897546 },
    { lat: 49.2003436, lng: 20.5889611 },
    { lat: 49.2008336, lng: 20.5878839 },
    { lat: 49.2015702, lng: 20.5865307 },
    { lat: 49.2019191, lng: 20.5848574 },
    { lat: 49.2021552, lng: 20.5832415 },
    { lat: 49.2021779, lng: 20.5830866 },
    { lat: 49.2027994, lng: 20.5789738 },
    { lat: 49.2024989, lng: 20.5761326 },
    { lat: 49.2019244, lng: 20.5731005 },
    { lat: 49.2016853, lng: 20.5716016 },
    { lat: 49.2012735, lng: 20.5701638 },
    { lat: 49.1993945, lng: 20.5674083 },
    { lat: 49.1982357, lng: 20.5665994 },
    { lat: 49.1974801, lng: 20.5661928 },
    { lat: 49.1968698, lng: 20.565605 },
    { lat: 49.1966172, lng: 20.5650938 },
    { lat: 49.1964286, lng: 20.5642517 },
    { lat: 49.1964082, lng: 20.5633197 },
    { lat: 49.1962525, lng: 20.5623571 },
    { lat: 49.1961779, lng: 20.5609318 },
    { lat: 49.1961896, lng: 20.5602951 },
    { lat: 49.1963019, lng: 20.5589371 },
    { lat: 49.1962709, lng: 20.5584304 },
    { lat: 49.1961437, lng: 20.557964 },
    { lat: 49.1956232, lng: 20.5570026 },
    { lat: 49.1955301, lng: 20.5567334 },
    { lat: 49.195645, lng: 20.556675 },
    { lat: 49.195581, lng: 20.5565264 },
    { lat: 49.1957155, lng: 20.5563839 },
    { lat: 49.1957745, lng: 20.5562446 },
    { lat: 49.1957616, lng: 20.5561431 },
    { lat: 49.1956066, lng: 20.5557819 },
    { lat: 49.1952538, lng: 20.5546641 },
    { lat: 49.1954215, lng: 20.5543547 },
    { lat: 49.1955768, lng: 20.554228 },
    { lat: 49.1958759, lng: 20.5531781 },
    { lat: 49.1965686, lng: 20.5518332 },
    { lat: 49.1967011, lng: 20.5512729 },
    { lat: 49.196757, lng: 20.5507055 },
    { lat: 49.196798, lng: 20.5506592 },
    { lat: 49.1967728, lng: 20.5504612 },
    { lat: 49.1965856, lng: 20.5499617 },
    { lat: 49.1965965, lng: 20.5497372 },
    { lat: 49.1965268, lng: 20.5494318 },
    { lat: 49.1965207, lng: 20.5490853 },
    { lat: 49.1965691, lng: 20.5488033 },
    { lat: 49.1965342, lng: 20.5483145 },
    { lat: 49.1966444, lng: 20.5481561 },
    { lat: 49.1966144, lng: 20.5472814 },
    { lat: 49.1962851, lng: 20.5464978 },
    { lat: 49.1960585, lng: 20.5456882 },
    { lat: 49.196006, lng: 20.5452992 },
    { lat: 49.1960713, lng: 20.5451835 },
    { lat: 49.1961973, lng: 20.5446576 },
    { lat: 49.1961617, lng: 20.5442398 },
    { lat: 49.1956169, lng: 20.5433185 },
    { lat: 49.1953096, lng: 20.5426475 },
    { lat: 49.1950369, lng: 20.5422418 },
    { lat: 49.1947649, lng: 20.5420674 },
    { lat: 49.1946017, lng: 20.5418269 },
    { lat: 49.1945945, lng: 20.5412958 },
    { lat: 49.1942365, lng: 20.5405864 },
    { lat: 49.1933733, lng: 20.5391283 },
    { lat: 49.1928143, lng: 20.5384066 },
    { lat: 49.1926782, lng: 20.5379777 },
    { lat: 49.1926714, lng: 20.5373941 },
    { lat: 49.1923756, lng: 20.5371574 },
    { lat: 49.1922852, lng: 20.536785 },
    { lat: 49.192445, lng: 20.5366903 },
    { lat: 49.1924687, lng: 20.5365087 },
    { lat: 49.1920525, lng: 20.5357813 },
    { lat: 49.192423, lng: 20.5348356 },
    { lat: 49.1927598, lng: 20.5343842 },
    { lat: 49.193189, lng: 20.5341178 },
    { lat: 49.1936435, lng: 20.5335594 },
    { lat: 49.1939086, lng: 20.5334165 },
    { lat: 49.1946668, lng: 20.5324858 },
    { lat: 49.1951491, lng: 20.5321302 },
    { lat: 49.1955329, lng: 20.5315458 },
    { lat: 49.1958453, lng: 20.5312271 },
    { lat: 49.196325, lng: 20.5305589 },
    { lat: 49.196594, lng: 20.5304001 },
    { lat: 49.1970161, lng: 20.5296389 },
    { lat: 49.197119, lng: 20.5293709 },
    { lat: 49.1971918, lng: 20.5288455 },
    { lat: 49.197451, lng: 20.5284849 },
    { lat: 49.1974949, lng: 20.5282987 },
    { lat: 49.1975882, lng: 20.5282064 },
    { lat: 49.1976784, lng: 20.5279658 },
    { lat: 49.1974863, lng: 20.5268746 },
    { lat: 49.1974446, lng: 20.5263573 },
    { lat: 49.1975036, lng: 20.525701 },
    { lat: 49.1975117, lng: 20.5249411 },
    { lat: 49.1978775, lng: 20.5234427 },
    { lat: 49.1977466, lng: 20.522063 },
    { lat: 49.1977724, lng: 20.5214513 },
    { lat: 49.1976869, lng: 20.5207196 },
  ],
  ČervenýKláštor: [
    { lat: 49.3879857, lng: 20.3852401 },
    { lat: 49.3879022, lng: 20.3852038 },
    { lat: 49.3875989, lng: 20.3847461 },
    { lat: 49.3867956, lng: 20.3844967 },
    { lat: 49.3862655, lng: 20.3844091 },
    { lat: 49.3861864, lng: 20.384315 },
    { lat: 49.3858393, lng: 20.3842125 },
    { lat: 49.3857525, lng: 20.3840948 },
    { lat: 49.3855545, lng: 20.3840687 },
    { lat: 49.3851269, lng: 20.3837176 },
    { lat: 49.3848516, lng: 20.3833753 },
    { lat: 49.384693, lng: 20.3830248 },
    { lat: 49.3845186, lng: 20.3828745 },
    { lat: 49.3840022, lng: 20.3835091 },
    { lat: 49.3837659, lng: 20.3844632 },
    { lat: 49.3831637, lng: 20.3841677 },
    { lat: 49.3827244, lng: 20.3850813 },
    { lat: 49.3827602, lng: 20.3851974 },
    { lat: 49.3827649, lng: 20.3858235 },
    { lat: 49.3828118, lng: 20.3859354 },
    { lat: 49.3831632, lng: 20.3862073 },
    { lat: 49.3833082, lng: 20.3864018 },
    { lat: 49.3835756, lng: 20.3869522 },
    { lat: 49.3837626, lng: 20.3874914 },
    { lat: 49.3851686, lng: 20.3895385 },
    { lat: 49.3852855, lng: 20.3899787 },
    { lat: 49.3854929, lng: 20.3911294 },
    { lat: 49.3855777, lng: 20.3913643 },
    { lat: 49.3857778, lng: 20.3916193 },
    { lat: 49.3858916, lng: 20.3916862 },
    { lat: 49.3863519, lng: 20.3917134 },
    { lat: 49.3865454, lng: 20.3920307 },
    { lat: 49.3867091, lng: 20.3921907 },
    { lat: 49.3872282, lng: 20.3924922 },
    { lat: 49.3873128, lng: 20.392678 },
    { lat: 49.3874899, lng: 20.3939651 },
    { lat: 49.3871252, lng: 20.3943209 },
    { lat: 49.3872841, lng: 20.39546 },
    { lat: 49.3873681, lng: 20.398928 },
    { lat: 49.3873534, lng: 20.3994662 },
    { lat: 49.3873502, lng: 20.3995819 },
    { lat: 49.3879151, lng: 20.4012765 },
    { lat: 49.3884192, lng: 20.4031483 },
    { lat: 49.3888606, lng: 20.404279 },
    { lat: 49.389307, lng: 20.405668 },
    { lat: 49.3885318, lng: 20.4061306 },
    { lat: 49.3884689, lng: 20.4062211 },
    { lat: 49.3882675, lng: 20.4068782 },
    { lat: 49.3881333, lng: 20.4074983 },
    { lat: 49.3874264, lng: 20.4080083 },
    { lat: 49.3871037, lng: 20.4084706 },
    { lat: 49.3882466, lng: 20.412861 },
    { lat: 49.3894092, lng: 20.4160794 },
    { lat: 49.3902776, lng: 20.4183044 },
    { lat: 49.3903454, lng: 20.41825 },
    { lat: 49.390971, lng: 20.4198619 },
    { lat: 49.3906288, lng: 20.4201896 },
    { lat: 49.3904484, lng: 20.4204498 },
    { lat: 49.3899013, lng: 20.4204468 },
    { lat: 49.3898079, lng: 20.4203911 },
    { lat: 49.3892965, lng: 20.4204756 },
    { lat: 49.3885071, lng: 20.4204987 },
    { lat: 49.3886186, lng: 20.4218766 },
    { lat: 49.3888187, lng: 20.4231563 },
    { lat: 49.3893401, lng: 20.4235479 },
    { lat: 49.3896558, lng: 20.4246968 },
    { lat: 49.3899171, lng: 20.4253029 },
    { lat: 49.3900501, lng: 20.4253956 },
    { lat: 49.3900616, lng: 20.4255189 },
    { lat: 49.3909181, lng: 20.4258771 },
    { lat: 49.3916417, lng: 20.4259268 },
    { lat: 49.3911625, lng: 20.429625 },
    { lat: 49.3912655, lng: 20.4296994 },
    { lat: 49.3912881, lng: 20.4296103 },
    { lat: 49.3915513, lng: 20.4296944 },
    { lat: 49.3915712, lng: 20.4297794 },
    { lat: 49.3916006, lng: 20.4309214 },
    { lat: 49.3914869, lng: 20.4315169 },
    { lat: 49.3921406, lng: 20.4308757 },
    { lat: 49.3928676, lng: 20.430032 },
    { lat: 49.3938066, lng: 20.4329762 },
    { lat: 49.3939348, lng: 20.4331236 },
    { lat: 49.3941476, lng: 20.4332447 },
    { lat: 49.3943137, lng: 20.4331701 },
    { lat: 49.3944909, lng: 20.4331831 },
    { lat: 49.3952103, lng: 20.4326408 },
    { lat: 49.395476, lng: 20.4321006 },
    { lat: 49.395969, lng: 20.431821 },
    { lat: 49.395913, lng: 20.431626 },
    { lat: 49.395877, lng: 20.431404 },
    { lat: 49.395885, lng: 20.43127 },
    { lat: 49.395948, lng: 20.431134 },
    { lat: 49.396256, lng: 20.430756 },
    { lat: 49.396476, lng: 20.430551 },
    { lat: 49.396595, lng: 20.430474 },
    { lat: 49.396835, lng: 20.430329 },
    { lat: 49.397147, lng: 20.4302138 },
    { lat: 49.397198, lng: 20.430195 },
    { lat: 49.39754, lng: 20.430019 },
    { lat: 49.397689, lng: 20.429959 },
    { lat: 49.398022, lng: 20.42975 },
    { lat: 49.398392, lng: 20.429594 },
    { lat: 49.39866, lng: 20.429495 },
    { lat: 49.399031, lng: 20.429305 },
    { lat: 49.399375, lng: 20.429136 },
    { lat: 49.39975, lng: 20.42897 },
    { lat: 49.400065, lng: 20.428823 },
    { lat: 49.400327, lng: 20.428693 },
    { lat: 49.400632, lng: 20.428562 },
    { lat: 49.400981, lng: 20.428414 },
    { lat: 49.401114, lng: 20.428349 },
    { lat: 49.401478, lng: 20.428187 },
    { lat: 49.401767, lng: 20.42806 },
    { lat: 49.401985, lng: 20.427964 },
    { lat: 49.402022, lng: 20.427928 },
    { lat: 49.402208, lng: 20.427711 },
    { lat: 49.402484, lng: 20.427368 },
    { lat: 49.402593, lng: 20.427148 },
    { lat: 49.402655, lng: 20.426977 },
    { lat: 49.402723, lng: 20.426903 },
    { lat: 49.402793, lng: 20.426793 },
    { lat: 49.402825, lng: 20.426668 },
    { lat: 49.402893, lng: 20.426263 },
    { lat: 49.402954, lng: 20.425928 },
    { lat: 49.402983, lng: 20.425718 },
    { lat: 49.403006, lng: 20.425584 },
    { lat: 49.403057, lng: 20.425187 },
    { lat: 49.403091, lng: 20.425086 },
    { lat: 49.40326, lng: 20.424766 },
    { lat: 49.403495, lng: 20.42418 },
    { lat: 49.403699, lng: 20.423714 },
    { lat: 49.403873, lng: 20.423334 },
    { lat: 49.404112, lng: 20.4228 },
    { lat: 49.4043, lng: 20.422395 },
    { lat: 49.404492, lng: 20.421928 },
    { lat: 49.404559, lng: 20.421815 },
    { lat: 49.404797, lng: 20.42138 },
    { lat: 49.405032, lng: 20.421052 },
    { lat: 49.405187, lng: 20.42081 },
    { lat: 49.405297, lng: 20.420575 },
    { lat: 49.4054, lng: 20.420359 },
    { lat: 49.405516, lng: 20.420014 },
    { lat: 49.405581, lng: 20.41968 },
    { lat: 49.405764, lng: 20.418803 },
    { lat: 49.405858, lng: 20.418288 },
    { lat: 49.405851, lng: 20.41752 },
    { lat: 49.40579, lng: 20.416875 },
    { lat: 49.405732, lng: 20.416679 },
    { lat: 49.405527, lng: 20.416369 },
    { lat: 49.405394, lng: 20.416077 },
    { lat: 49.405253, lng: 20.415804 },
    { lat: 49.405061, lng: 20.415479 },
    { lat: 49.404988, lng: 20.415368 },
    { lat: 49.404881, lng: 20.415241 },
    { lat: 49.404535, lng: 20.414894 },
    { lat: 49.404147, lng: 20.414586 },
    { lat: 49.403847, lng: 20.414359 },
    { lat: 49.403515, lng: 20.414209 },
    { lat: 49.402913, lng: 20.414076 },
    { lat: 49.402576, lng: 20.414058 },
    { lat: 49.40222, lng: 20.414087 },
    { lat: 49.40189, lng: 20.414114 },
    { lat: 49.40156, lng: 20.414197 },
    { lat: 49.401393, lng: 20.414204 },
    { lat: 49.401058, lng: 20.414279 },
    { lat: 49.400765, lng: 20.414361 },
    { lat: 49.400505, lng: 20.414437 },
    { lat: 49.400209, lng: 20.414508 },
    { lat: 49.400123, lng: 20.414513 },
    { lat: 49.399817, lng: 20.414581 },
    { lat: 49.399561, lng: 20.414611 },
    { lat: 49.399213, lng: 20.414668 },
    { lat: 49.398867, lng: 20.414692 },
    { lat: 49.398575, lng: 20.414728 },
    { lat: 49.398112, lng: 20.414778 },
    { lat: 49.397714, lng: 20.414688 },
    { lat: 49.397409, lng: 20.414518 },
    { lat: 49.3971797, lng: 20.414361 },
    { lat: 49.397136, lng: 20.414331 },
    { lat: 49.39701, lng: 20.414266 },
    { lat: 49.396562, lng: 20.413914 },
    { lat: 49.396269, lng: 20.413662 },
    { lat: 49.396082, lng: 20.413466 },
    { lat: 49.395956, lng: 20.413317 },
    { lat: 49.395718, lng: 20.41304 },
    { lat: 49.39546, lng: 20.412672 },
    { lat: 49.395203, lng: 20.412285 },
    { lat: 49.394972, lng: 20.411733 },
    { lat: 49.394754, lng: 20.411341 },
    { lat: 49.394402, lng: 20.410695 },
    { lat: 49.393922, lng: 20.409779 },
    { lat: 49.393692, lng: 20.409337 },
    { lat: 49.393512, lng: 20.408976 },
    { lat: 49.39337, lng: 20.408642 },
    { lat: 49.393167, lng: 20.408057 },
    { lat: 49.393116, lng: 20.407868 },
    { lat: 49.39296, lng: 20.407343 },
    { lat: 49.392816, lng: 20.406826 },
    { lat: 49.392691, lng: 20.406313 },
    { lat: 49.392543, lng: 20.405747 },
    { lat: 49.392446, lng: 20.405263 },
    { lat: 49.39235, lng: 20.404785 },
    { lat: 49.392289, lng: 20.404355 },
    { lat: 49.392228, lng: 20.403838 },
    { lat: 49.3921393, lng: 20.4030106 },
    { lat: 49.392089, lng: 20.402581 },
    { lat: 49.392037, lng: 20.4022 },
    { lat: 49.392018, lng: 20.401684 },
    { lat: 49.391991, lng: 20.401169 },
    { lat: 49.391982, lng: 20.400668 },
    { lat: 49.391963, lng: 20.400112 },
    { lat: 49.391892, lng: 20.399067 },
    { lat: 49.391266, lng: 20.396063 },
    { lat: 49.3907626, lng: 20.3938544 },
    { lat: 49.3906292, lng: 20.3932695 },
    { lat: 49.390576, lng: 20.393036 },
    { lat: 49.39042, lng: 20.390427 },
    { lat: 49.390838, lng: 20.388011 },
    { lat: 49.39084, lng: 20.386532 },
    { lat: 49.388836, lng: 20.3855775 },
    { lat: 49.3886255, lng: 20.3854267 },
    { lat: 49.3883447, lng: 20.3854137 },
    { lat: 49.3882893, lng: 20.3852022 },
    { lat: 49.3880337, lng: 20.3852607 },
    { lat: 49.3879857, lng: 20.3852401 },
  ],
  Vrbov: [
    { lat: 49.0610056, lng: 20.4906124 },
    { lat: 49.0610242, lng: 20.4905979 },
    { lat: 49.0613086, lng: 20.4905963 },
    { lat: 49.0633835, lng: 20.4905849 },
    { lat: 49.0671076, lng: 20.4899416 },
    { lat: 49.0687727, lng: 20.4889777 },
    { lat: 49.0694713, lng: 20.4887496 },
    { lat: 49.0696541, lng: 20.48861 },
    { lat: 49.0718936, lng: 20.4877066 },
    { lat: 49.0726463, lng: 20.4872975 },
    { lat: 49.0735336, lng: 20.4865209 },
    { lat: 49.0735161, lng: 20.4851158 },
    { lat: 49.0733895, lng: 20.4839547 },
    { lat: 49.0733752, lng: 20.4827562 },
    { lat: 49.0738207, lng: 20.482593 },
    { lat: 49.0738018, lng: 20.4823594 },
    { lat: 49.0739545, lng: 20.4823469 },
    { lat: 49.074232, lng: 20.4819696 },
    { lat: 49.0743994, lng: 20.481568 },
    { lat: 49.0748879, lng: 20.4796647 },
    { lat: 49.0750626, lng: 20.4793074 },
    { lat: 49.0751863, lng: 20.4788876 },
    { lat: 49.0752823, lng: 20.4783812 },
    { lat: 49.0753322, lng: 20.4775463 },
    { lat: 49.0752539, lng: 20.4765687 },
    { lat: 49.0750685, lng: 20.4754956 },
    { lat: 49.0749346, lng: 20.4742003 },
    { lat: 49.0745913, lng: 20.4733585 },
    { lat: 49.0746525, lng: 20.4732494 },
    { lat: 49.0745072, lng: 20.4731299 },
    { lat: 49.0741809, lng: 20.4725944 },
    { lat: 49.0737725, lng: 20.4721784 },
    { lat: 49.0738228, lng: 20.4719578 },
    { lat: 49.0739297, lng: 20.4717439 },
    { lat: 49.0745291, lng: 20.471577 },
    { lat: 49.0748346, lng: 20.4712865 },
    { lat: 49.0753118, lng: 20.4705589 },
    { lat: 49.0754518, lng: 20.4700271 },
    { lat: 49.0754681, lng: 20.4694744 },
    { lat: 49.0757701, lng: 20.4688822 },
    { lat: 49.0763125, lng: 20.4681517 },
    { lat: 49.0769755, lng: 20.4667589 },
    { lat: 49.0770881, lng: 20.4665225 },
    { lat: 49.0774993, lng: 20.4658105 },
    { lat: 49.0775808, lng: 20.4654261 },
    { lat: 49.0776276, lng: 20.4652054 },
    { lat: 49.0777607, lng: 20.4649722 },
    { lat: 49.0779592, lng: 20.4648766 },
    { lat: 49.0781445, lng: 20.4647873 },
    { lat: 49.0782162, lng: 20.4648375 },
    { lat: 49.0786734, lng: 20.4647692 },
    { lat: 49.0790333, lng: 20.4647155 },
    { lat: 49.0794745, lng: 20.4645565 },
    { lat: 49.0794467, lng: 20.4643578 },
    { lat: 49.07917, lng: 20.4636732 },
    { lat: 49.0804247, lng: 20.4618241 },
    { lat: 49.0806583, lng: 20.4612718 },
    { lat: 49.0808737, lng: 20.4610729 },
    { lat: 49.0819169, lng: 20.4601097 },
    { lat: 49.082048, lng: 20.4599887 },
    { lat: 49.0825493, lng: 20.4591847 },
    { lat: 49.0830171, lng: 20.4580334 },
    { lat: 49.083189, lng: 20.4578094 },
    { lat: 49.0834007, lng: 20.4576603 },
    { lat: 49.0835264, lng: 20.4579166 },
    { lat: 49.0837374, lng: 20.4585613 },
    { lat: 49.0838661, lng: 20.459198 },
    { lat: 49.0840107, lng: 20.4594861 },
    { lat: 49.0842674, lng: 20.4592723 },
    { lat: 49.0846532, lng: 20.4587546 },
    { lat: 49.0847041, lng: 20.4588202 },
    { lat: 49.0849635, lng: 20.4583835 },
    { lat: 49.0852711, lng: 20.4580313 },
    { lat: 49.0853622, lng: 20.4580251 },
    { lat: 49.0854808, lng: 20.4582745 },
    { lat: 49.0861231, lng: 20.4573285 },
    { lat: 49.0863626, lng: 20.4568698 },
    { lat: 49.0865013, lng: 20.4564874 },
    { lat: 49.086609, lng: 20.4559356 },
    { lat: 49.0869579, lng: 20.4550072 },
    { lat: 49.087117, lng: 20.4542979 },
    { lat: 49.0875482, lng: 20.4544163 },
    { lat: 49.088107, lng: 20.4548762 },
    { lat: 49.0882745, lng: 20.4543552 },
    { lat: 49.0884701, lng: 20.4545572 },
    { lat: 49.088859, lng: 20.4547238 },
    { lat: 49.0889041, lng: 20.4546042 },
    { lat: 49.088828, lng: 20.4542236 },
    { lat: 49.0888483, lng: 20.4538989 },
    { lat: 49.0887255, lng: 20.4534739 },
    { lat: 49.088503, lng: 20.4532777 },
    { lat: 49.0885052, lng: 20.4531234 },
    { lat: 49.0885311, lng: 20.4528916 },
    { lat: 49.0886608, lng: 20.4528631 },
    { lat: 49.0887636, lng: 20.4529619 },
    { lat: 49.088797, lng: 20.4528347 },
    { lat: 49.0887983, lng: 20.4527491 },
    { lat: 49.0887111, lng: 20.4526442 },
    { lat: 49.0886669, lng: 20.4524545 },
    { lat: 49.088728, lng: 20.4522218 },
    { lat: 49.0886693, lng: 20.4521054 },
    { lat: 49.088655, lng: 20.4519005 },
    { lat: 49.0887508, lng: 20.4516928 },
    { lat: 49.0886824, lng: 20.4515806 },
    { lat: 49.0887094, lng: 20.4513393 },
    { lat: 49.0887782, lng: 20.4513182 },
    { lat: 49.0888681, lng: 20.4514879 },
    { lat: 49.0889669, lng: 20.451304 },
    { lat: 49.0888782, lng: 20.4512147 },
    { lat: 49.0888365, lng: 20.450978 },
    { lat: 49.0889017, lng: 20.4509425 },
    { lat: 49.0888759, lng: 20.4507659 },
    { lat: 49.0889643, lng: 20.450631 },
    { lat: 49.0889866, lng: 20.4504469 },
    { lat: 49.0889292, lng: 20.4502378 },
    { lat: 49.0891129, lng: 20.4498525 },
    { lat: 49.0889905, lng: 20.4497411 },
    { lat: 49.0889775, lng: 20.4496646 },
    { lat: 49.0891131, lng: 20.4495005 },
    { lat: 49.0891165, lng: 20.4494391 },
    { lat: 49.0890073, lng: 20.4493542 },
    { lat: 49.0890148, lng: 20.4492526 },
    { lat: 49.0891437, lng: 20.4490272 },
    { lat: 49.0891288, lng: 20.448721 },
    { lat: 49.0894367, lng: 20.4486123 },
    { lat: 49.0894428, lng: 20.4484813 },
    { lat: 49.0895829, lng: 20.4484034 },
    { lat: 49.0896717, lng: 20.4485436 },
    { lat: 49.0897204, lng: 20.4484036 },
    { lat: 49.0897291, lng: 20.4481589 },
    { lat: 49.0897727, lng: 20.4480617 },
    { lat: 49.089879, lng: 20.4480406 },
    { lat: 49.0899052, lng: 20.447872 },
    { lat: 49.0902357, lng: 20.4479459 },
    { lat: 49.0902727, lng: 20.4480644 },
    { lat: 49.0903894, lng: 20.4481739 },
    { lat: 49.090371, lng: 20.4483447 },
    { lat: 49.0901691, lng: 20.44839 },
    { lat: 49.0902027, lng: 20.4485038 },
    { lat: 49.0903245, lng: 20.4486013 },
    { lat: 49.0906038, lng: 20.4484235 },
    { lat: 49.0907452, lng: 20.4484712 },
    { lat: 49.0907532, lng: 20.4482621 },
    { lat: 49.090878, lng: 20.4482861 },
    { lat: 49.0912034, lng: 20.4479322 },
    { lat: 49.0911937, lng: 20.4477355 },
    { lat: 49.0914441, lng: 20.4474439 },
    { lat: 49.0915001, lng: 20.4473201 },
    { lat: 49.0915023, lng: 20.4471661 },
    { lat: 49.0915949, lng: 20.4470546 },
    { lat: 49.0917062, lng: 20.4470546 },
    { lat: 49.0917399, lng: 20.4469288 },
    { lat: 49.0919368, lng: 20.4468362 },
    { lat: 49.0920242, lng: 20.446853 },
    { lat: 49.092204, lng: 20.4465466 },
    { lat: 49.0923308, lng: 20.4464476 },
    { lat: 49.0925433, lng: 20.4464096 },
    { lat: 49.0925758, lng: 20.4462577 },
    { lat: 49.0928912, lng: 20.4460178 },
    { lat: 49.0931782, lng: 20.4459256 },
    { lat: 49.0935236, lng: 20.445501 },
    { lat: 49.0935529, lng: 20.4453824 },
    { lat: 49.0936821, lng: 20.4452849 },
    { lat: 49.0937446, lng: 20.4450028 },
    { lat: 49.0938459, lng: 20.4448815 },
    { lat: 49.0938526, lng: 20.4447886 },
    { lat: 49.0938025, lng: 20.4447438 },
    { lat: 49.0938704, lng: 20.4446028 },
    { lat: 49.0939761, lng: 20.4446909 },
    { lat: 49.0940173, lng: 20.4445572 },
    { lat: 49.0939814, lng: 20.4444443 },
    { lat: 49.0938858, lng: 20.4445058 },
    { lat: 49.0938556, lng: 20.4444485 },
    { lat: 49.0939596, lng: 20.4442522 },
    { lat: 49.0939586, lng: 20.4441388 },
    { lat: 49.0940352, lng: 20.4441048 },
    { lat: 49.0941435, lng: 20.4442933 },
    { lat: 49.0941981, lng: 20.4442149 },
    { lat: 49.0941088, lng: 20.4439665 },
    { lat: 49.094015, lng: 20.4439018 },
    { lat: 49.0940246, lng: 20.4437986 },
    { lat: 49.0940844, lng: 20.4437391 },
    { lat: 49.0940061, lng: 20.443451 },
    { lat: 49.0940884, lng: 20.4433687 },
    { lat: 49.0940187, lng: 20.4432473 },
    { lat: 49.0940947, lng: 20.4428799 },
    { lat: 49.0941573, lng: 20.4428608 },
    { lat: 49.0943211, lng: 20.4430013 },
    { lat: 49.0944944, lng: 20.4427595 },
    { lat: 49.0944019, lng: 20.442423 },
    { lat: 49.0944409, lng: 20.4423244 },
    { lat: 49.094403, lng: 20.4421258 },
    { lat: 49.0943146, lng: 20.4419888 },
    { lat: 49.0943996, lng: 20.4417029 },
    { lat: 49.0945199, lng: 20.4416073 },
    { lat: 49.0945428, lng: 20.4412032 },
    { lat: 49.0945993, lng: 20.4410295 },
    { lat: 49.0947163, lng: 20.4410846 },
    { lat: 49.0946763, lng: 20.4408941 },
    { lat: 49.094728, lng: 20.4408256 },
    { lat: 49.094649, lng: 20.4407014 },
    { lat: 49.0947602, lng: 20.4406105 },
    { lat: 49.0947108, lng: 20.4403137 },
    { lat: 49.0947592, lng: 20.4402506 },
    { lat: 49.0949638, lng: 20.4402144 },
    { lat: 49.095015, lng: 20.4401082 },
    { lat: 49.0951454, lng: 20.4402499 },
    { lat: 49.0952187, lng: 20.4401677 },
    { lat: 49.095156, lng: 20.4399386 },
    { lat: 49.0951975, lng: 20.4398746 },
    { lat: 49.0953084, lng: 20.4400384 },
    { lat: 49.0954564, lng: 20.4399895 },
    { lat: 49.0954492, lng: 20.4396216 },
    { lat: 49.0955533, lng: 20.4394878 },
    { lat: 49.0955113, lng: 20.4392669 },
    { lat: 49.0951812, lng: 20.4393224 },
    { lat: 49.095112, lng: 20.4392763 },
    { lat: 49.0953631, lng: 20.4388592 },
    { lat: 49.0955021, lng: 20.4388409 },
    { lat: 49.0955849, lng: 20.438741 },
    { lat: 49.0957202, lng: 20.4387751 },
    { lat: 49.0957496, lng: 20.4387158 },
    { lat: 49.0956639, lng: 20.4385081 },
    { lat: 49.0958922, lng: 20.4382618 },
    { lat: 49.0959779, lng: 20.4383258 },
    { lat: 49.0960468, lng: 20.4386284 },
    { lat: 49.0961769, lng: 20.4385541 },
    { lat: 49.0962048, lng: 20.4387008 },
    { lat: 49.0962594, lng: 20.4387161 },
    { lat: 49.0964025, lng: 20.438506 },
    { lat: 49.0963273, lng: 20.4384195 },
    { lat: 49.0963672, lng: 20.4382576 },
    { lat: 49.0964913, lng: 20.4385658 },
    { lat: 49.0965827, lng: 20.438537 },
    { lat: 49.096635, lng: 20.4384034 },
    { lat: 49.0965875, lng: 20.4381245 },
    { lat: 49.0966405, lng: 20.4380408 },
    { lat: 49.0966681, lng: 20.4380222 },
    { lat: 49.0967069, lng: 20.4381575 },
    { lat: 49.0970442, lng: 20.4377849 },
    { lat: 49.0974392, lng: 20.4377245 },
    { lat: 49.0974555, lng: 20.4374989 },
    { lat: 49.0978965, lng: 20.437489 },
    { lat: 49.0977995, lng: 20.4376631 },
    { lat: 49.0977051, lng: 20.4376692 },
    { lat: 49.0977296, lng: 20.4378112 },
    { lat: 49.0978199, lng: 20.4378482 },
    { lat: 49.0979213, lng: 20.4375756 },
    { lat: 49.098057, lng: 20.4376266 },
    { lat: 49.0980634, lng: 20.4373781 },
    { lat: 49.0981275, lng: 20.4374336 },
    { lat: 49.0983053, lng: 20.4373739 },
    { lat: 49.0986534, lng: 20.4374954 },
    { lat: 49.0987157, lng: 20.437564 },
    { lat: 49.0987014, lng: 20.4377222 },
    { lat: 49.0988147, lng: 20.4379261 },
    { lat: 49.0987891, lng: 20.4381087 },
    { lat: 49.098834, lng: 20.4381687 },
    { lat: 49.0992602, lng: 20.4379694 },
    { lat: 49.0993326, lng: 20.4378316 },
    { lat: 49.0995464, lng: 20.4378571 },
    { lat: 49.0996586, lng: 20.4380859 },
    { lat: 49.0997309, lng: 20.4380968 },
    { lat: 49.1000523, lng: 20.4380489 },
    { lat: 49.1002136, lng: 20.4379076 },
    { lat: 49.1002738, lng: 20.4379203 },
    { lat: 49.100394, lng: 20.4382134 },
    { lat: 49.100475, lng: 20.438239 },
    { lat: 49.1005487, lng: 20.4383738 },
    { lat: 49.1005894, lng: 20.438708 },
    { lat: 49.1006455, lng: 20.4387006 },
    { lat: 49.1007395, lng: 20.4385414 },
    { lat: 49.1009313, lng: 20.4385451 },
    { lat: 49.1010277, lng: 20.4383254 },
    { lat: 49.1012167, lng: 20.4382615 },
    { lat: 49.101292, lng: 20.4381137 },
    { lat: 49.1016123, lng: 20.4381866 },
    { lat: 49.1016195, lng: 20.4384336 },
    { lat: 49.1018019, lng: 20.4384349 },
    { lat: 49.1018994, lng: 20.4382729 },
    { lat: 49.1018502, lng: 20.4380138 },
    { lat: 49.1018907, lng: 20.4379855 },
    { lat: 49.102202, lng: 20.4381764 },
    { lat: 49.1024112, lng: 20.4381998 },
    { lat: 49.1024704, lng: 20.4383365 },
    { lat: 49.1024426, lng: 20.4385251 },
    { lat: 49.1022984, lng: 20.4387619 },
    { lat: 49.1024994, lng: 20.4389003 },
    { lat: 49.1026533, lng: 20.4389202 },
    { lat: 49.1026019, lng: 20.4391091 },
    { lat: 49.1027271, lng: 20.4394449 },
    { lat: 49.102899, lng: 20.4394608 },
    { lat: 49.1030361, lng: 20.4395508 },
    { lat: 49.1031327, lng: 20.43951 },
    { lat: 49.1032436, lng: 20.4394098 },
    { lat: 49.1031249, lng: 20.4392128 },
    { lat: 49.103306, lng: 20.439179 },
    { lat: 49.1034969, lng: 20.4392888 },
    { lat: 49.1036207, lng: 20.4392848 },
    { lat: 49.1037989, lng: 20.439445 },
    { lat: 49.1039487, lng: 20.4392935 },
    { lat: 49.1042074, lng: 20.4396787 },
    { lat: 49.1042797, lng: 20.4396243 },
    { lat: 49.1043261, lng: 20.4394804 },
    { lat: 49.1043914, lng: 20.4394787 },
    { lat: 49.1046051, lng: 20.4397708 },
    { lat: 49.1046369, lng: 20.4395992 },
    { lat: 49.1046928, lng: 20.4395612 },
    { lat: 49.1049898, lng: 20.439762 },
    { lat: 49.1055998, lng: 20.4394894 },
    { lat: 49.105813, lng: 20.439588 },
    { lat: 49.1060964, lng: 20.4392814 },
    { lat: 49.106182, lng: 20.4392808 },
    { lat: 49.1063326, lng: 20.4394516 },
    { lat: 49.1064459, lng: 20.4393213 },
    { lat: 49.1063916, lng: 20.4388381 },
    { lat: 49.1064316, lng: 20.4384975 },
    { lat: 49.1064861, lng: 20.4385103 },
    { lat: 49.1065626, lng: 20.4384185 },
    { lat: 49.1066809, lng: 20.4384218 },
    { lat: 49.1067444, lng: 20.4384899 },
    { lat: 49.1070284, lng: 20.4384087 },
    { lat: 49.1071007, lng: 20.4382981 },
    { lat: 49.1071442, lng: 20.438313 },
    { lat: 49.1071821, lng: 20.4386371 },
    { lat: 49.1072479, lng: 20.4386802 },
    { lat: 49.1073883, lng: 20.438637 },
    { lat: 49.10775, lng: 20.438913 },
    { lat: 49.1080929, lng: 20.4388391 },
    { lat: 49.1082894, lng: 20.4388888 },
    { lat: 49.1082764, lng: 20.4391699 },
    { lat: 49.1081624, lng: 20.4391946 },
    { lat: 49.1080354, lng: 20.4391108 },
    { lat: 49.1080349, lng: 20.4392719 },
    { lat: 49.1082196, lng: 20.4394843 },
    { lat: 49.1082974, lng: 20.4392769 },
    { lat: 49.1083485, lng: 20.4392286 },
    { lat: 49.1084231, lng: 20.4392674 },
    { lat: 49.1084599, lng: 20.4394054 },
    { lat: 49.1083714, lng: 20.4395371 },
    { lat: 49.1083845, lng: 20.4396157 },
    { lat: 49.1088024, lng: 20.4401312 },
    { lat: 49.1087263, lng: 20.4403215 },
    { lat: 49.108716, lng: 20.4404974 },
    { lat: 49.1089349, lng: 20.4404218 },
    { lat: 49.1089906, lng: 20.4401985 },
    { lat: 49.1091141, lng: 20.4400932 },
    { lat: 49.1092503, lng: 20.4397068 },
    { lat: 49.1094321, lng: 20.4386789 },
    { lat: 49.1097794, lng: 20.4377638 },
    { lat: 49.1097786, lng: 20.4374749 },
    { lat: 49.1099108, lng: 20.4372747 },
    { lat: 49.1101388, lng: 20.4364598 },
    { lat: 49.1105049, lng: 20.4355613 },
    { lat: 49.1107292, lng: 20.4351535 },
    { lat: 49.1112324, lng: 20.4337197 },
    { lat: 49.1113488, lng: 20.4333404 },
    { lat: 49.1113841, lng: 20.4328439 },
    { lat: 49.1114765, lng: 20.432461 },
    { lat: 49.1116463, lng: 20.4322168 },
    { lat: 49.1117365, lng: 20.4319243 },
    { lat: 49.1120436, lng: 20.4320045 },
    { lat: 49.112115, lng: 20.4293322 },
    { lat: 49.112036, lng: 20.4289086 },
    { lat: 49.1123567, lng: 20.4284302 },
    { lat: 49.1131105, lng: 20.4277862 },
    { lat: 49.1132368, lng: 20.4274067 },
    { lat: 49.113622, lng: 20.4266951 },
    { lat: 49.1137729, lng: 20.4263091 },
    { lat: 49.1138144, lng: 20.4261226 },
    { lat: 49.1135985, lng: 20.4256549 },
    { lat: 49.1138791, lng: 20.4251242 },
    { lat: 49.1137794, lng: 20.4249604 },
    { lat: 49.1143008, lng: 20.4238296 },
    { lat: 49.1145499, lng: 20.4231234 },
    { lat: 49.1149953, lng: 20.42127 },
    { lat: 49.1151553, lng: 20.4208211 },
    { lat: 49.1153738, lng: 20.4204985 },
    { lat: 49.1160402, lng: 20.4197779 },
    { lat: 49.1165424, lng: 20.4193949 },
    { lat: 49.1180756, lng: 20.4190621 },
    { lat: 49.1186785, lng: 20.418865 },
    { lat: 49.1188781, lng: 20.4187278 },
    { lat: 49.1193623, lng: 20.4186996 },
    { lat: 49.1197625, lng: 20.4188614 },
    { lat: 49.1198764, lng: 20.418804 },
    { lat: 49.1199592, lng: 20.4187604 },
    { lat: 49.1199597, lng: 20.4187168 },
    { lat: 49.1197019, lng: 20.4169926 },
    { lat: 49.1197106, lng: 20.4165996 },
    { lat: 49.1197681, lng: 20.4165978 },
    { lat: 49.1197711, lng: 20.4164103 },
    { lat: 49.1196901, lng: 20.4155795 },
    { lat: 49.1194617, lng: 20.4144256 },
    { lat: 49.1192723, lng: 20.4137363 },
    { lat: 49.1192015, lng: 20.4136793 },
    { lat: 49.1190866, lng: 20.4133206 },
    { lat: 49.1191782, lng: 20.4126803 },
    { lat: 49.1194868, lng: 20.4116711 },
    { lat: 49.1195864, lng: 20.411094 },
    { lat: 49.1196142, lng: 20.410519 },
    { lat: 49.1195752, lng: 20.4096414 },
    { lat: 49.119645, lng: 20.4086877 },
    { lat: 49.1196387, lng: 20.4076024 },
    { lat: 49.1197673, lng: 20.4067197 },
    { lat: 49.119809, lng: 20.4054446 },
    { lat: 49.119766, lng: 20.4053176 },
    { lat: 49.1198068, lng: 20.4051181 },
    { lat: 49.1197876, lng: 20.4049426 },
    { lat: 49.1195638, lng: 20.4047721 },
    { lat: 49.1195397, lng: 20.4050266 },
    { lat: 49.1195828, lng: 20.4053269 },
    { lat: 49.1193819, lng: 20.4054363 },
    { lat: 49.1180584, lng: 20.4053577 },
    { lat: 49.1173223, lng: 20.4051293 },
    { lat: 49.1168376, lng: 20.4051465 },
    { lat: 49.1162973, lng: 20.4050568 },
    { lat: 49.1160955, lng: 20.4051418 },
    { lat: 49.1159392, lng: 20.4049712 },
    { lat: 49.1157279, lng: 20.4048867 },
    { lat: 49.1155024, lng: 20.405 },
    { lat: 49.1153708, lng: 20.4051827 },
    { lat: 49.1147863, lng: 20.4048187 },
    { lat: 49.1146358, lng: 20.4048633 },
    { lat: 49.114705, lng: 20.4045179 },
    { lat: 49.1146333, lng: 20.4043819 },
    { lat: 49.1146218, lng: 20.4041669 },
    { lat: 49.1145065, lng: 20.4039761 },
    { lat: 49.1143165, lng: 20.4039559 },
    { lat: 49.114386, lng: 20.4035066 },
    { lat: 49.1143663, lng: 20.4034591 },
    { lat: 49.1142228, lng: 20.4034764 },
    { lat: 49.1142009, lng: 20.4034025 },
    { lat: 49.1143058, lng: 20.4033199 },
    { lat: 49.1141965, lng: 20.403106 },
    { lat: 49.1140414, lng: 20.4030226 },
    { lat: 49.1140798, lng: 20.4028553 },
    { lat: 49.1139255, lng: 20.4027313 },
    { lat: 49.1139916, lng: 20.402544 },
    { lat: 49.1140938, lng: 20.4024789 },
    { lat: 49.114041, lng: 20.4023365 },
    { lat: 49.113928, lng: 20.4024723 },
    { lat: 49.1138858, lng: 20.4024548 },
    { lat: 49.1139951, lng: 20.4020732 },
    { lat: 49.113914, lng: 20.4020415 },
    { lat: 49.1138799, lng: 20.4021516 },
    { lat: 49.1138447, lng: 20.4021443 },
    { lat: 49.1138699, lng: 20.4019196 },
    { lat: 49.1137415, lng: 20.4017696 },
    { lat: 49.1138106, lng: 20.4013095 },
    { lat: 49.1137868, lng: 20.4012149 },
    { lat: 49.1136359, lng: 20.4010942 },
    { lat: 49.1136136, lng: 20.4008924 },
    { lat: 49.1136804, lng: 20.4006949 },
    { lat: 49.113604, lng: 20.4006395 },
    { lat: 49.1136729, lng: 20.4004965 },
    { lat: 49.1135727, lng: 20.4003195 },
    { lat: 49.1137756, lng: 20.3993811 },
    { lat: 49.1138332, lng: 20.3985274 },
    { lat: 49.1136075, lng: 20.3975065 },
    { lat: 49.1135006, lng: 20.3972999 },
    { lat: 49.1132792, lng: 20.3971613 },
    { lat: 49.1122641, lng: 20.3969117 },
    { lat: 49.1115725, lng: 20.3966051 },
    { lat: 49.1101241, lng: 20.3955017 },
    { lat: 49.108438, lng: 20.3956305 },
    { lat: 49.1071448, lng: 20.3961759 },
    { lat: 49.1058887, lng: 20.3956871 },
    { lat: 49.1054386, lng: 20.3960797 },
    { lat: 49.1050933, lng: 20.3962367 },
    { lat: 49.1044969, lng: 20.3966897 },
    { lat: 49.1044722, lng: 20.3968403 },
    { lat: 49.1043741, lng: 20.3969219 },
    { lat: 49.1043911, lng: 20.3969858 },
    { lat: 49.1042578, lng: 20.3969731 },
    { lat: 49.1042013, lng: 20.3970891 },
    { lat: 49.1042512, lng: 20.3971537 },
    { lat: 49.1041141, lng: 20.3973089 },
    { lat: 49.1040021, lng: 20.3972961 },
    { lat: 49.1039196, lng: 20.3976146 },
    { lat: 49.1037171, lng: 20.397769 },
    { lat: 49.1033895, lng: 20.3977397 },
    { lat: 49.1033581, lng: 20.3978032 },
    { lat: 49.1031691, lng: 20.3978637 },
    { lat: 49.1030068, lng: 20.3977397 },
    { lat: 49.1029761, lng: 20.3977895 },
    { lat: 49.1028438, lng: 20.3977466 },
    { lat: 49.1025038, lng: 20.3978093 },
    { lat: 49.102471, lng: 20.3977173 },
    { lat: 49.1024034, lng: 20.3977448 },
    { lat: 49.1023428, lng: 20.3976891 },
    { lat: 49.1021813, lng: 20.3978226 },
    { lat: 49.1019779, lng: 20.3978043 },
    { lat: 49.1018067, lng: 20.3978611 },
    { lat: 49.1017699, lng: 20.3979297 },
    { lat: 49.1015195, lng: 20.3979763 },
    { lat: 49.1014524, lng: 20.3981112 },
    { lat: 49.1012933, lng: 20.3980408 },
    { lat: 49.1010028, lng: 20.3981897 },
    { lat: 49.1009236, lng: 20.3982969 },
    { lat: 49.100777, lng: 20.3985294 },
    { lat: 49.1005302, lng: 20.3988451 },
    { lat: 49.1001435, lng: 20.3991606 },
    { lat: 49.0998114, lng: 20.3992502 },
    { lat: 49.0990978, lng: 20.3995823 },
    { lat: 49.098263, lng: 20.3995999 },
    { lat: 49.0981401, lng: 20.3995339 },
    { lat: 49.0978769, lng: 20.3996038 },
    { lat: 49.0976607, lng: 20.3994206 },
    { lat: 49.097601, lng: 20.3992419 },
    { lat: 49.09738, lng: 20.3991202 },
    { lat: 49.0972899, lng: 20.3988536 },
    { lat: 49.096994, lng: 20.3987101 },
    { lat: 49.0969758, lng: 20.3985537 },
    { lat: 49.0966271, lng: 20.3984497 },
    { lat: 49.0965154, lng: 20.3983223 },
    { lat: 49.0964802, lng: 20.3981971 },
    { lat: 49.0963169, lng: 20.3981332 },
    { lat: 49.0960413, lng: 20.3982017 },
    { lat: 49.0959822, lng: 20.3980999 },
    { lat: 49.0957276, lng: 20.3981547 },
    { lat: 49.0954462, lng: 20.3984792 },
    { lat: 49.0951118, lng: 20.3993081 },
    { lat: 49.0949946, lng: 20.3993651 },
    { lat: 49.0949531, lng: 20.3995761 },
    { lat: 49.0948209, lng: 20.3998055 },
    { lat: 49.094771, lng: 20.4000632 },
    { lat: 49.0947542, lng: 20.4006578 },
    { lat: 49.0946555, lng: 20.4008106 },
    { lat: 49.0946867, lng: 20.4009217 },
    { lat: 49.094483, lng: 20.4011944 },
    { lat: 49.0943552, lng: 20.4012334 },
    { lat: 49.094323, lng: 20.4015157 },
    { lat: 49.0941641, lng: 20.4017482 },
    { lat: 49.093647, lng: 20.4021839 },
    { lat: 49.0936173, lng: 20.4023216 },
    { lat: 49.093288, lng: 20.4025152 },
    { lat: 49.0932185, lng: 20.4024635 },
    { lat: 49.0932234, lng: 20.4046036 },
    { lat: 49.091851, lng: 20.4057049 },
    { lat: 49.0896034, lng: 20.4080145 },
    { lat: 49.0892301, lng: 20.4083236 },
    { lat: 49.088809, lng: 20.4085281 },
    { lat: 49.0876082, lng: 20.4093723 },
    { lat: 49.0852599, lng: 20.4098861 },
    { lat: 49.0852895, lng: 20.4106662 },
    { lat: 49.083945, lng: 20.4105147 },
    { lat: 49.0824477, lng: 20.410228 },
    { lat: 49.0816134, lng: 20.4101947 },
    { lat: 49.0806741, lng: 20.4099775 },
    { lat: 49.0802514, lng: 20.410117 },
    { lat: 49.0798777, lng: 20.4105654 },
    { lat: 49.0787307, lng: 20.4114335 },
    { lat: 49.0782434, lng: 20.4119411 },
    { lat: 49.0779315, lng: 20.4121815 },
    { lat: 49.0772519, lng: 20.4125782 },
    { lat: 49.0769299, lng: 20.4128482 },
    { lat: 49.0767002, lng: 20.413142 },
    { lat: 49.0760373, lng: 20.4142465 },
    { lat: 49.0757951, lng: 20.4147221 },
    { lat: 49.0757791, lng: 20.4148585 },
    { lat: 49.0752972, lng: 20.4157534 },
    { lat: 49.0745522, lng: 20.4162962 },
    { lat: 49.0746051, lng: 20.4165659 },
    { lat: 49.0746683, lng: 20.4177275 },
    { lat: 49.0739925, lng: 20.4175143 },
    { lat: 49.0733155, lng: 20.4168767 },
    { lat: 49.0718483, lng: 20.4166691 },
    { lat: 49.0713639, lng: 20.4168416 },
    { lat: 49.0712932, lng: 20.4167875 },
    { lat: 49.071077, lng: 20.4169921 },
    { lat: 49.0700469, lng: 20.4171156 },
    { lat: 49.0694636, lng: 20.4170272 },
    { lat: 49.0694006, lng: 20.4170883 },
    { lat: 49.0692728, lng: 20.4170116 },
    { lat: 49.0692148, lng: 20.4171165 },
    { lat: 49.0690478, lng: 20.4171057 },
    { lat: 49.0689337, lng: 20.417246 },
    { lat: 49.0688226, lng: 20.417551 },
    { lat: 49.0688158, lng: 20.4177279 },
    { lat: 49.0686254, lng: 20.4178753 },
    { lat: 49.0686273, lng: 20.4179268 },
    { lat: 49.0687006, lng: 20.4194756 },
    { lat: 49.0686847, lng: 20.4201815 },
    { lat: 49.0687856, lng: 20.4216245 },
    { lat: 49.0689682, lng: 20.4224038 },
    { lat: 49.0691588, lng: 20.4235739 },
    { lat: 49.0692892, lng: 20.4240461 },
    { lat: 49.0694062, lng: 20.4252004 },
    { lat: 49.0693985, lng: 20.4260378 },
    { lat: 49.0698189, lng: 20.4275389 },
    { lat: 49.0698011, lng: 20.4279315 },
    { lat: 49.06987, lng: 20.4290092 },
    { lat: 49.0698295, lng: 20.4299356 },
    { lat: 49.0699465, lng: 20.4326459 },
    { lat: 49.0704728, lng: 20.432538 },
    { lat: 49.0717536, lng: 20.4324672 },
    { lat: 49.0721934, lng: 20.4325408 },
    { lat: 49.0729379, lng: 20.432852 },
    { lat: 49.0729128, lng: 20.4332542 },
    { lat: 49.0725622, lng: 20.4353914 },
    { lat: 49.0725796, lng: 20.4364209 },
    { lat: 49.0726644, lng: 20.4372678 },
    { lat: 49.0726745, lng: 20.4378936 },
    { lat: 49.0727973, lng: 20.4386715 },
    { lat: 49.0726898, lng: 20.4391613 },
    { lat: 49.0725568, lng: 20.4394581 },
    { lat: 49.0729261, lng: 20.4397278 },
    { lat: 49.073595, lng: 20.4403858 },
    { lat: 49.0741631, lng: 20.4411169 },
    { lat: 49.074454, lng: 20.4416999 },
    { lat: 49.0746055, lng: 20.4425525 },
    { lat: 49.0746622, lng: 20.4435193 },
    { lat: 49.0747551, lng: 20.4441045 },
    { lat: 49.0746669, lng: 20.4441513 },
    { lat: 49.0749041, lng: 20.4459956 },
    { lat: 49.0751555, lng: 20.4469328 },
    { lat: 49.0758173, lng: 20.4474964 },
    { lat: 49.075706, lng: 20.4479733 },
    { lat: 49.0759024, lng: 20.4481965 },
    { lat: 49.0757401, lng: 20.4486739 },
    { lat: 49.0757498, lng: 20.4488131 },
    { lat: 49.0762109, lng: 20.4494858 },
    { lat: 49.0761665, lng: 20.4495654 },
    { lat: 49.076476, lng: 20.4504 },
    { lat: 49.0766083, lng: 20.4511631 },
    { lat: 49.076908, lng: 20.4518862 },
    { lat: 49.076823, lng: 20.4526819 },
    { lat: 49.0766359, lng: 20.4528264 },
    { lat: 49.0765521, lng: 20.4530186 },
    { lat: 49.0768263, lng: 20.4533345 },
    { lat: 49.0765933, lng: 20.4535186 },
    { lat: 49.0765643, lng: 20.4536766 },
    { lat: 49.076495, lng: 20.4537473 },
    { lat: 49.0763754, lng: 20.453678 },
    { lat: 49.0761865, lng: 20.4540453 },
    { lat: 49.076206, lng: 20.4541179 },
    { lat: 49.0761539, lng: 20.4542891 },
    { lat: 49.0757901, lng: 20.4543132 },
    { lat: 49.0758699, lng: 20.4547602 },
    { lat: 49.0757863, lng: 20.4548163 },
    { lat: 49.0757883, lng: 20.4546872 },
    { lat: 49.0757223, lng: 20.4546075 },
    { lat: 49.0757697, lng: 20.4545499 },
    { lat: 49.0757085, lng: 20.4544532 },
    { lat: 49.0755295, lng: 20.4544225 },
    { lat: 49.0752072, lng: 20.4545622 },
    { lat: 49.0751108, lng: 20.4546949 },
    { lat: 49.075098, lng: 20.4548314 },
    { lat: 49.0752787, lng: 20.4548501 },
    { lat: 49.0753881, lng: 20.4550065 },
    { lat: 49.0751558, lng: 20.4555924 },
    { lat: 49.0749748, lng: 20.4558416 },
    { lat: 49.074819, lng: 20.4562168 },
    { lat: 49.0747842, lng: 20.4561875 },
    { lat: 49.0747202, lng: 20.4562548 },
    { lat: 49.0746875, lng: 20.456434 },
    { lat: 49.0748178, lng: 20.4567875 },
    { lat: 49.0746867, lng: 20.4570736 },
    { lat: 49.0746227, lng: 20.4571079 },
    { lat: 49.0746249, lng: 20.4572851 },
    { lat: 49.0747771, lng: 20.4575628 },
    { lat: 49.0747951, lng: 20.4577103 },
    { lat: 49.0747616, lng: 20.4578108 },
    { lat: 49.0746962, lng: 20.4578029 },
    { lat: 49.0743136, lng: 20.4574534 },
    { lat: 49.0742276, lng: 20.4574695 },
    { lat: 49.0741819, lng: 20.4576347 },
    { lat: 49.0742138, lng: 20.457912 },
    { lat: 49.074573, lng: 20.4582744 },
    { lat: 49.0743625, lng: 20.4583034 },
    { lat: 49.074263, lng: 20.4584116 },
    { lat: 49.0742798, lng: 20.4585329 },
    { lat: 49.0744154, lng: 20.4586321 },
    { lat: 49.0744268, lng: 20.4586968 },
    { lat: 49.0741779, lng: 20.4588736 },
    { lat: 49.074204, lng: 20.4590031 },
    { lat: 49.0741283, lng: 20.4590851 },
    { lat: 49.0738118, lng: 20.4590814 },
    { lat: 49.0739495, lng: 20.4593302 },
    { lat: 49.0739225, lng: 20.4596309 },
    { lat: 49.0737628, lng: 20.4595432 },
    { lat: 49.0735309, lng: 20.4596374 },
    { lat: 49.0731715, lng: 20.4595968 },
    { lat: 49.0729234, lng: 20.459469 },
    { lat: 49.0726703, lng: 20.4597467 },
    { lat: 49.0726075, lng: 20.4599579 },
    { lat: 49.0724588, lng: 20.4599888 },
    { lat: 49.0723873, lng: 20.4600945 },
    { lat: 49.072174, lng: 20.459987 },
    { lat: 49.07202, lng: 20.4600586 },
    { lat: 49.0717322, lng: 20.4599148 },
    { lat: 49.0715514, lng: 20.4600498 },
    { lat: 49.0712537, lng: 20.4597938 },
    { lat: 49.071116, lng: 20.4598786 },
    { lat: 49.0710757, lng: 20.4600362 },
    { lat: 49.0710158, lng: 20.4600575 },
    { lat: 49.0708311, lng: 20.4598754 },
    { lat: 49.0706467, lng: 20.4599166 },
    { lat: 49.0704325, lng: 20.4598142 },
    { lat: 49.0703761, lng: 20.4596948 },
    { lat: 49.0703051, lng: 20.4597204 },
    { lat: 49.070189, lng: 20.4598964 },
    { lat: 49.0703191, lng: 20.4601253 },
    { lat: 49.0700529, lng: 20.46023 },
    { lat: 49.0700285, lng: 20.4599692 },
    { lat: 49.0700689, lng: 20.459821 },
    { lat: 49.0700315, lng: 20.4597485 },
    { lat: 49.0699718, lng: 20.4598431 },
    { lat: 49.0699183, lng: 20.4597932 },
    { lat: 49.0697496, lng: 20.4598301 },
    { lat: 49.0697165, lng: 20.459935 },
    { lat: 49.0697384, lng: 20.4600426 },
    { lat: 49.0698144, lng: 20.4600863 },
    { lat: 49.0695654, lng: 20.4604419 },
    { lat: 49.0694498, lng: 20.4603614 },
    { lat: 49.0692361, lng: 20.4604558 },
    { lat: 49.0690513, lng: 20.4602172 },
    { lat: 49.0690174, lng: 20.4598794 },
    { lat: 49.0689068, lng: 20.4598743 },
    { lat: 49.0687853, lng: 20.4599668 },
    { lat: 49.0687787, lng: 20.4601505 },
    { lat: 49.0687003, lng: 20.4602485 },
    { lat: 49.0684309, lng: 20.460092 },
    { lat: 49.0684632, lng: 20.4607704 },
    { lat: 49.0684296, lng: 20.4608386 },
    { lat: 49.0681954, lng: 20.4608389 },
    { lat: 49.0681449, lng: 20.4612045 },
    { lat: 49.0680169, lng: 20.4613007 },
    { lat: 49.0678908, lng: 20.4611548 },
    { lat: 49.0678192, lng: 20.4611646 },
    { lat: 49.0677382, lng: 20.4614941 },
    { lat: 49.0678229, lng: 20.4618354 },
    { lat: 49.0677359, lng: 20.4619747 },
    { lat: 49.0676764, lng: 20.4619781 },
    { lat: 49.067538, lng: 20.4617431 },
    { lat: 49.0676311, lng: 20.4615206 },
    { lat: 49.0675041, lng: 20.4613812 },
    { lat: 49.067341, lng: 20.4616561 },
    { lat: 49.0674343, lng: 20.4616462 },
    { lat: 49.0673456, lng: 20.4618977 },
    { lat: 49.0672509, lng: 20.4619188 },
    { lat: 49.0671595, lng: 20.4620359 },
    { lat: 49.0671902, lng: 20.4621309 },
    { lat: 49.067122, lng: 20.4622704 },
    { lat: 49.0668692, lng: 20.4621276 },
    { lat: 49.066871, lng: 20.4620327 },
    { lat: 49.0667773, lng: 20.4620807 },
    { lat: 49.0667765, lng: 20.4626624 },
    { lat: 49.0666563, lng: 20.4629673 },
    { lat: 49.06647, lng: 20.4631034 },
    { lat: 49.0663271, lng: 20.4630476 },
    { lat: 49.0660977, lng: 20.4631164 },
    { lat: 49.0659582, lng: 20.4630886 },
    { lat: 49.0657632, lng: 20.463652 },
    { lat: 49.0657001, lng: 20.4636812 },
    { lat: 49.0655604, lng: 20.4635925 },
    { lat: 49.0654803, lng: 20.4638281 },
    { lat: 49.0652681, lng: 20.4639001 },
    { lat: 49.0651492, lng: 20.4642393 },
    { lat: 49.0644375, lng: 20.4642365 },
    { lat: 49.0643887, lng: 20.4644953 },
    { lat: 49.0641913, lng: 20.4642765 },
    { lat: 49.0641149, lng: 20.464462 },
    { lat: 49.0641303, lng: 20.4646944 },
    { lat: 49.0640572, lng: 20.4649359 },
    { lat: 49.0640083, lng: 20.4649215 },
    { lat: 49.0638061, lng: 20.4645118 },
    { lat: 49.0637651, lng: 20.4645645 },
    { lat: 49.0637692, lng: 20.4648238 },
    { lat: 49.0636431, lng: 20.4649729 },
    { lat: 49.0635518, lng: 20.4648204 },
    { lat: 49.0635341, lng: 20.4645577 },
    { lat: 49.0634318, lng: 20.4647095 },
    { lat: 49.0634474, lng: 20.4649752 },
    { lat: 49.0633719, lng: 20.4650934 },
    { lat: 49.0632489, lng: 20.4649095 },
    { lat: 49.0631865, lng: 20.4650148 },
    { lat: 49.0630372, lng: 20.4649976 },
    { lat: 49.0629415, lng: 20.4651498 },
    { lat: 49.0626525, lng: 20.4651236 },
    { lat: 49.0623159, lng: 20.4647051 },
    { lat: 49.0621443, lng: 20.4647287 },
    { lat: 49.0619892, lng: 20.4653051 },
    { lat: 49.0619056, lng: 20.4654157 },
    { lat: 49.0614886, lng: 20.465313 },
    { lat: 49.0613538, lng: 20.4653868 },
    { lat: 49.0611381, lng: 20.4657542 },
    { lat: 49.0610885, lng: 20.4660547 },
    { lat: 49.060985, lng: 20.4660884 },
    { lat: 49.0609009, lng: 20.4660354 },
    { lat: 49.0607662, lng: 20.4661674 },
    { lat: 49.0606712, lng: 20.466157 },
    { lat: 49.0604924, lng: 20.4658892 },
    { lat: 49.0604071, lng: 20.4658981 },
    { lat: 49.060059, lng: 20.4656806 },
    { lat: 49.0599597, lng: 20.4654993 },
    { lat: 49.0598219, lng: 20.4653917 },
    { lat: 49.0594155, lng: 20.4653379 },
    { lat: 49.0592768, lng: 20.4652128 },
    { lat: 49.0591568, lng: 20.4652211 },
    { lat: 49.0587408, lng: 20.4654464 },
    { lat: 49.0583718, lng: 20.4653331 },
    { lat: 49.0581607, lng: 20.4654092 },
    { lat: 49.0578606, lng: 20.4652457 },
    { lat: 49.0577738, lng: 20.4651218 },
    { lat: 49.057664, lng: 20.4651378 },
    { lat: 49.0574512, lng: 20.4647744 },
    { lat: 49.0569485, lng: 20.4650596 },
    { lat: 49.0567629, lng: 20.4654893 },
    { lat: 49.0564026, lng: 20.4659348 },
    { lat: 49.0563001, lng: 20.4659373 },
    { lat: 49.0562715, lng: 20.466013 },
    { lat: 49.0561696, lng: 20.4660567 },
    { lat: 49.0561287, lng: 20.4662002 },
    { lat: 49.0556045, lng: 20.4663882 },
    { lat: 49.0555723, lng: 20.4664621 },
    { lat: 49.0555241, lng: 20.4665912 },
    { lat: 49.0553264, lng: 20.4668679 },
    { lat: 49.0553073, lng: 20.4669636 },
    { lat: 49.0553611, lng: 20.4674041 },
    { lat: 49.0555806, lng: 20.4680994 },
    { lat: 49.0554869, lng: 20.4689619 },
    { lat: 49.0552539, lng: 20.4700461 },
    { lat: 49.05507, lng: 20.4702466 },
    { lat: 49.054865, lng: 20.4709069 },
    { lat: 49.0544517, lng: 20.4718923 },
    { lat: 49.0543069, lng: 20.4727197 },
    { lat: 49.0544859, lng: 20.4737129 },
    { lat: 49.0545099, lng: 20.4749543 },
    { lat: 49.0549382, lng: 20.4774038 },
    { lat: 49.0556099, lng: 20.4794111 },
    { lat: 49.0556621, lng: 20.4803525 },
    { lat: 49.0557256, lng: 20.4806059 },
    { lat: 49.0558211, lng: 20.4809009 },
    { lat: 49.0560127, lng: 20.4814817 },
    { lat: 49.056499, lng: 20.4822599 },
    { lat: 49.0566155, lng: 20.482943 },
    { lat: 49.0567408, lng: 20.4846033 },
    { lat: 49.05684, lng: 20.4850951 },
    { lat: 49.0572076, lng: 20.4858029 },
    { lat: 49.0575633, lng: 20.4855596 },
    { lat: 49.0582567, lng: 20.4854971 },
    { lat: 49.0583415, lng: 20.4858345 },
    { lat: 49.0585952, lng: 20.4863309 },
    { lat: 49.0589725, lng: 20.4876912 },
    { lat: 49.0592205, lng: 20.4880284 },
    { lat: 49.0601178, lng: 20.4885113 },
    { lat: 49.0603506, lng: 20.4887479 },
    { lat: 49.0608472, lng: 20.4899257 },
    { lat: 49.0610056, lng: 20.4906124 },
  ],
  VeľkáLomnica: [
    { lat: 49.088087, lng: 20.373778 },
    { lat: 49.0869762, lng: 20.3768003 },
    { lat: 49.0864328, lng: 20.3788102 },
    { lat: 49.0863188, lng: 20.3793958 },
    { lat: 49.0863511, lng: 20.3794457 },
    { lat: 49.0873889, lng: 20.3794051 },
    { lat: 49.0881564, lng: 20.3790404 },
    { lat: 49.088937, lng: 20.3784639 },
    { lat: 49.0893108, lng: 20.3780762 },
    { lat: 49.0906511, lng: 20.3769382 },
    { lat: 49.0906609, lng: 20.3769284 },
    { lat: 49.0911859, lng: 20.3764112 },
    { lat: 49.0918535, lng: 20.3761116 },
    { lat: 49.0927601, lng: 20.3760291 },
    { lat: 49.0935723, lng: 20.3761841 },
    { lat: 49.0948843, lng: 20.3761633 },
    { lat: 49.0958223, lng: 20.3758707 },
    { lat: 49.0969902, lng: 20.3759097 },
    { lat: 49.0975808, lng: 20.3760154 },
    { lat: 49.0994822, lng: 20.3758285 },
    { lat: 49.1001926, lng: 20.3760928 },
    { lat: 49.1016333, lng: 20.3763402 },
    { lat: 49.1026487, lng: 20.3763938 },
    { lat: 49.102927, lng: 20.3762854 },
    { lat: 49.1042406, lng: 20.376363 },
    { lat: 49.10542, lng: 20.3765516 },
    { lat: 49.1065894, lng: 20.3765111 },
    { lat: 49.1070767, lng: 20.3765635 },
    { lat: 49.1071008, lng: 20.3743652 },
    { lat: 49.1070381, lng: 20.3724934 },
    { lat: 49.1101327, lng: 20.3711793 },
    { lat: 49.1107338, lng: 20.3712941 },
    { lat: 49.1111126, lng: 20.3712785 },
    { lat: 49.1113703, lng: 20.3711873 },
    { lat: 49.1114684, lng: 20.3715675 },
    { lat: 49.1119203, lng: 20.3712006 },
    { lat: 49.1119683, lng: 20.3711594 },
    { lat: 49.1115156, lng: 20.3675997 },
    { lat: 49.1114592, lng: 20.3663917 },
    { lat: 49.1119227, lng: 20.3672645 },
    { lat: 49.1119928, lng: 20.3671835 },
    { lat: 49.1119515, lng: 20.3671042 },
    { lat: 49.1125443, lng: 20.3664096 },
    { lat: 49.1129218, lng: 20.366977 },
    { lat: 49.113537, lng: 20.3682866 },
    { lat: 49.1150878, lng: 20.3662036 },
    { lat: 49.1146634, lng: 20.3660317 },
    { lat: 49.1134422, lng: 20.3651886 },
    { lat: 49.1142617, lng: 20.3640392 },
    { lat: 49.1144812, lng: 20.3640664 },
    { lat: 49.1148141, lng: 20.3643236 },
    { lat: 49.1154141, lng: 20.36491 },
    { lat: 49.1158334, lng: 20.3654144 },
    { lat: 49.1159947, lng: 20.3659863 },
    { lat: 49.1165551, lng: 20.3670332 },
    { lat: 49.1170201, lng: 20.3680612 },
    { lat: 49.1171334, lng: 20.3679638 },
    { lat: 49.1171572, lng: 20.3680558 },
    { lat: 49.1174699, lng: 20.3678724 },
    { lat: 49.1177325, lng: 20.3683778 },
    { lat: 49.1178564, lng: 20.3683179 },
    { lat: 49.1179337, lng: 20.3686044 },
    { lat: 49.118066, lng: 20.3687062 },
    { lat: 49.1179259, lng: 20.3687819 },
    { lat: 49.1179615, lng: 20.3688546 },
    { lat: 49.1181229, lng: 20.3688366 },
    { lat: 49.119209, lng: 20.3680331 },
    { lat: 49.1205515, lng: 20.3667494 },
    { lat: 49.1210043, lng: 20.3674892 },
    { lat: 49.1213314, lng: 20.3684171 },
    { lat: 49.1217515, lng: 20.3692389 },
    { lat: 49.1237454, lng: 20.3678574 },
    { lat: 49.1254915, lng: 20.3667887 },
    { lat: 49.1256659, lng: 20.3665801 },
    { lat: 49.1257025, lng: 20.3664208 },
    { lat: 49.126945, lng: 20.3652339 },
    { lat: 49.1281704, lng: 20.3643333 },
    { lat: 49.129616, lng: 20.3635103 },
    { lat: 49.1297364, lng: 20.3634418 },
    { lat: 49.1310565, lng: 20.3624876 },
    { lat: 49.131063, lng: 20.3623598 },
    { lat: 49.1324223, lng: 20.3614024 },
    { lat: 49.1324957, lng: 20.3615261 },
    { lat: 49.1340367, lng: 20.3606589 },
    { lat: 49.1341225, lng: 20.3608102 },
    { lat: 49.1343096, lng: 20.3608471 },
    { lat: 49.1346495, lng: 20.3607514 },
    { lat: 49.1348, lng: 20.3606287 },
    { lat: 49.1351266, lng: 20.3605126 },
    { lat: 49.1357697, lng: 20.3600738 },
    { lat: 49.1367815, lng: 20.3596511 },
    { lat: 49.1369603, lng: 20.3595021 },
    { lat: 49.1373305, lng: 20.3590232 },
    { lat: 49.1380171, lng: 20.358582 },
    { lat: 49.1394695, lng: 20.3573108 },
    { lat: 49.139975, lng: 20.3571661 },
    { lat: 49.1403156, lng: 20.356888 },
    { lat: 49.1406343, lng: 20.3564201 },
    { lat: 49.1412705, lng: 20.3556898 },
    { lat: 49.1415353, lng: 20.3556378 },
    { lat: 49.1423573, lng: 20.3547582 },
    { lat: 49.1431829, lng: 20.3540272 },
    { lat: 49.1436121, lng: 20.3535656 },
    { lat: 49.1440988, lng: 20.3529263 },
    { lat: 49.1468918, lng: 20.3481097 },
    { lat: 49.1474038, lng: 20.3467512 },
    { lat: 49.1480041, lng: 20.3453545 },
    { lat: 49.1491172, lng: 20.3423545 },
    { lat: 49.1495044, lng: 20.34051 },
    { lat: 49.1496745, lng: 20.3387869 },
    { lat: 49.1497257, lng: 20.3370476 },
    { lat: 49.14891, lng: 20.3332357 },
    { lat: 49.1495192, lng: 20.3306726 },
    { lat: 49.149508, lng: 20.329031 },
    { lat: 49.149483, lng: 20.329255 },
    { lat: 49.14935, lng: 20.330478 },
    { lat: 49.148733, lng: 20.330813 },
    { lat: 49.148134, lng: 20.331094 },
    { lat: 49.147633, lng: 20.331452 },
    { lat: 49.147544, lng: 20.331305 },
    { lat: 49.147389, lng: 20.331048 },
    { lat: 49.147342, lng: 20.330971 },
    { lat: 49.147252, lng: 20.330821 },
    { lat: 49.147204, lng: 20.330742 },
    { lat: 49.146548, lng: 20.329658 },
    { lat: 49.146012, lng: 20.328772 },
    { lat: 49.145972, lng: 20.328728 },
    { lat: 49.14575, lng: 20.328477 },
    { lat: 49.145079, lng: 20.327721 },
    { lat: 49.144624, lng: 20.327246 },
    { lat: 49.144585, lng: 20.327206 },
    { lat: 49.144702, lng: 20.327026 },
    { lat: 49.144904, lng: 20.326643 },
    { lat: 49.145002, lng: 20.326119 },
    { lat: 49.144995, lng: 20.32531 },
    { lat: 49.144953, lng: 20.325085 },
    { lat: 49.144704, lng: 20.324754 },
    { lat: 49.144692, lng: 20.324702 },
    { lat: 49.144733, lng: 20.32435 },
    { lat: 49.144792, lng: 20.324073 },
    { lat: 49.144909, lng: 20.323772 },
    { lat: 49.144947, lng: 20.323666 },
    { lat: 49.145038, lng: 20.323418 },
    { lat: 49.145014, lng: 20.323399 },
    { lat: 49.144962, lng: 20.323361 },
    { lat: 49.144843, lng: 20.323275 },
    { lat: 49.144662, lng: 20.323056 },
    { lat: 49.144198, lng: 20.322403 },
    { lat: 49.14369, lng: 20.323464 },
    { lat: 49.143332, lng: 20.322814 },
    { lat: 49.143758, lng: 20.321782 },
    { lat: 49.143168, lng: 20.320934 },
    { lat: 49.142524, lng: 20.320082 },
    { lat: 49.14312, lng: 20.318985 },
    { lat: 49.14365, lng: 20.317866 },
    { lat: 49.144378, lng: 20.318628 },
    { lat: 49.144987, lng: 20.319234 },
    { lat: 49.145136, lng: 20.319014 },
    { lat: 49.145439, lng: 20.319308 },
    { lat: 49.145838, lng: 20.318813 },
    { lat: 49.1458663, lng: 20.3187479 },
    { lat: 49.145041, lng: 20.3178759 },
    { lat: 49.1429213, lng: 20.316087 },
    { lat: 49.14248, lng: 20.3155376 },
    { lat: 49.1417306, lng: 20.3160729 },
    { lat: 49.1412897, lng: 20.3164895 },
    { lat: 49.1412018, lng: 20.3166067 },
    { lat: 49.1410951, lng: 20.316959 },
    { lat: 49.1410559, lng: 20.3169352 },
    { lat: 49.140895, lng: 20.3170871 },
    { lat: 49.1402726, lng: 20.3181658 },
    { lat: 49.140028, lng: 20.318455 },
    { lat: 49.1389036, lng: 20.3193368 },
    { lat: 49.1387322, lng: 20.319536 },
    { lat: 49.1383801, lng: 20.3205882 },
    { lat: 49.1364427, lng: 20.3235424 },
    { lat: 49.1362718, lng: 20.3236382 },
    { lat: 49.136213, lng: 20.3236004 },
    { lat: 49.1358643, lng: 20.3239614 },
    { lat: 49.1356375, lng: 20.3240336 },
    { lat: 49.1355256, lng: 20.3243184 },
    { lat: 49.1349716, lng: 20.3248668 },
    { lat: 49.1348268, lng: 20.3251452 },
    { lat: 49.1347603, lng: 20.3253472 },
    { lat: 49.1347729, lng: 20.3255269 },
    { lat: 49.13473, lng: 20.3255832 },
    { lat: 49.134558, lng: 20.3254958 },
    { lat: 49.1344483, lng: 20.325546 },
    { lat: 49.1344265, lng: 20.325677 },
    { lat: 49.1339606, lng: 20.3265354 },
    { lat: 49.1339023, lng: 20.3266881 },
    { lat: 49.1338964, lng: 20.3269137 },
    { lat: 49.1335609, lng: 20.3272322 },
    { lat: 49.1335516, lng: 20.3273693 },
    { lat: 49.1333948, lng: 20.3274812 },
    { lat: 49.1333559, lng: 20.3276425 },
    { lat: 49.1330287, lng: 20.3280904 },
    { lat: 49.1328144, lng: 20.328105 },
    { lat: 49.1327446, lng: 20.32799 },
    { lat: 49.1322475, lng: 20.3283586 },
    { lat: 49.1317491, lng: 20.3280765 },
    { lat: 49.131573, lng: 20.3281232 },
    { lat: 49.1314883, lng: 20.3280563 },
    { lat: 49.1310124, lng: 20.3275119 },
    { lat: 49.1310126, lng: 20.3273695 },
    { lat: 49.1307876, lng: 20.3274098 },
    { lat: 49.1305493, lng: 20.3273003 },
    { lat: 49.1303929, lng: 20.3273934 },
    { lat: 49.1300925, lng: 20.3274272 },
    { lat: 49.1300082, lng: 20.3275178 },
    { lat: 49.1300875, lng: 20.3277318 },
    { lat: 49.1297625, lng: 20.3279792 },
    { lat: 49.1292626, lng: 20.3278594 },
    { lat: 49.1291401, lng: 20.3276094 },
    { lat: 49.1284108, lng: 20.3276457 },
    { lat: 49.1282802, lng: 20.3266219 },
    { lat: 49.1273399, lng: 20.327 },
    { lat: 49.1271152, lng: 20.3272722 },
    { lat: 49.1268927, lng: 20.3278117 },
    { lat: 49.1258492, lng: 20.3263349 },
    { lat: 49.1248414, lng: 20.3254852 },
    { lat: 49.1242104, lng: 20.3246294 },
    { lat: 49.1240876, lng: 20.3246314 },
    { lat: 49.123529, lng: 20.3251475 },
    { lat: 49.1230589, lng: 20.3251084 },
    { lat: 49.1227564, lng: 20.3255864 },
    { lat: 49.1226862, lng: 20.3258077 },
    { lat: 49.1223517, lng: 20.3264143 },
    { lat: 49.1223464, lng: 20.3265093 },
    { lat: 49.1217843, lng: 20.3260378 },
    { lat: 49.1208232, lng: 20.3240156 },
    { lat: 49.1199833, lng: 20.3227908 },
    { lat: 49.1194161, lng: 20.3221875 },
    { lat: 49.1189115, lng: 20.3214887 },
    { lat: 49.1183426, lng: 20.3205789 },
    { lat: 49.117679, lng: 20.3193147 },
    { lat: 49.1171182, lng: 20.3184161 },
    { lat: 49.1163634, lng: 20.3170066 },
    { lat: 49.1159827, lng: 20.3161332 },
    { lat: 49.115402, lng: 20.3150057 },
    { lat: 49.114052, lng: 20.31292 },
    { lat: 49.113863, lng: 20.313204 },
    { lat: 49.113395, lng: 20.313847 },
    { lat: 49.113043, lng: 20.314304 },
    { lat: 49.112578, lng: 20.314896 },
    { lat: 49.112277, lng: 20.315305 },
    { lat: 49.112092, lng: 20.315501 },
    { lat: 49.111729, lng: 20.315878 },
    { lat: 49.111538, lng: 20.316068 },
    { lat: 49.11128, lng: 20.316318 },
    { lat: 49.110998, lng: 20.316629 },
    { lat: 49.110584, lng: 20.317097 },
    { lat: 49.110429, lng: 20.317241 },
    { lat: 49.11001, lng: 20.317731 },
    { lat: 49.10947, lng: 20.318436 },
    { lat: 49.109331, lng: 20.318608 },
    { lat: 49.109134, lng: 20.318852 },
    { lat: 49.108812, lng: 20.319214 },
    { lat: 49.108386, lng: 20.319706 },
    { lat: 49.107921, lng: 20.320181 },
    { lat: 49.107521, lng: 20.320645 },
    { lat: 49.107473, lng: 20.320735 },
    { lat: 49.107465, lng: 20.320703 },
    { lat: 49.107257, lng: 20.321037 },
    { lat: 49.107154, lng: 20.321152 },
    { lat: 49.107067, lng: 20.321246 },
    { lat: 49.10688, lng: 20.321367 },
    { lat: 49.106547, lng: 20.321761 },
    { lat: 49.106174, lng: 20.322112 },
    { lat: 49.105878, lng: 20.322467 },
    { lat: 49.105495, lng: 20.322919 },
    { lat: 49.105184, lng: 20.323228 },
    { lat: 49.104922, lng: 20.323557 },
    { lat: 49.10443, lng: 20.324188 },
    { lat: 49.104193, lng: 20.324476 },
    { lat: 49.104069, lng: 20.324636 },
    { lat: 49.103827, lng: 20.324986 },
    { lat: 49.103528, lng: 20.325403 },
    { lat: 49.103459, lng: 20.325628 },
    { lat: 49.103197, lng: 20.326171 },
    { lat: 49.103035, lng: 20.326574 },
    { lat: 49.102993, lng: 20.326549 },
    { lat: 49.10301, lng: 20.326682 },
    { lat: 49.103009, lng: 20.326878 },
    { lat: 49.102979, lng: 20.327003 },
    { lat: 49.102875, lng: 20.327313 },
    { lat: 49.102574, lng: 20.328184 },
    { lat: 49.102295, lng: 20.328976 },
    { lat: 49.102192, lng: 20.329277 },
    { lat: 49.102027, lng: 20.329733 },
    { lat: 49.101701, lng: 20.33068 },
    { lat: 49.101434, lng: 20.331449 },
    { lat: 49.101106, lng: 20.332408 },
    { lat: 49.100926, lng: 20.332947 },
    { lat: 49.10076, lng: 20.3334 },
    { lat: 49.100498, lng: 20.334168 },
    { lat: 49.100195, lng: 20.335079 },
    { lat: 49.099878, lng: 20.336008 },
    { lat: 49.099589, lng: 20.336865 },
    { lat: 49.09949, lng: 20.337152 },
    { lat: 49.099466, lng: 20.337136 },
    { lat: 49.099442, lng: 20.33712 },
    { lat: 49.09882, lng: 20.336929 },
    { lat: 49.098133, lng: 20.336728 },
    { lat: 49.097227, lng: 20.33646 },
    { lat: 49.096448, lng: 20.336178 },
    { lat: 49.096131, lng: 20.336057 },
    { lat: 49.096063, lng: 20.336026 },
    { lat: 49.096054, lng: 20.335977 },
    { lat: 49.095978, lng: 20.336023 },
    { lat: 49.095902, lng: 20.336042 },
    { lat: 49.095861, lng: 20.336022 },
    { lat: 49.095831, lng: 20.336089 },
    { lat: 49.09562, lng: 20.336547 },
    { lat: 49.095595, lng: 20.336598 },
    { lat: 49.095557, lng: 20.336684 },
    { lat: 49.09552, lng: 20.336765 },
    { lat: 49.095479, lng: 20.336851 },
    { lat: 49.09543, lng: 20.336973 },
    { lat: 49.095394, lng: 20.337043 },
    { lat: 49.095297, lng: 20.337256 },
    { lat: 49.095273, lng: 20.3373 },
    { lat: 49.095182, lng: 20.337493 },
    { lat: 49.094744, lng: 20.338156 },
    { lat: 49.094421, lng: 20.33802 },
    { lat: 49.094394, lng: 20.338009 },
    { lat: 49.094277, lng: 20.337962 },
    { lat: 49.094255, lng: 20.337953 },
    { lat: 49.094209, lng: 20.337935 },
    { lat: 49.094189, lng: 20.337898 },
    { lat: 49.094183, lng: 20.337888 },
    { lat: 49.094025, lng: 20.338013 },
    { lat: 49.093992, lng: 20.338038 },
    { lat: 49.093699, lng: 20.338304 },
    { lat: 49.09359, lng: 20.338476 },
    { lat: 49.093529, lng: 20.338589 },
    { lat: 49.093515, lng: 20.338615 },
    { lat: 49.093448, lng: 20.33881 },
    { lat: 49.093411, lng: 20.338926 },
    { lat: 49.09338, lng: 20.33902 },
    { lat: 49.093367, lng: 20.339137 },
    { lat: 49.093421, lng: 20.339177 },
    { lat: 49.093502, lng: 20.339239 },
    { lat: 49.094004, lng: 20.339619 },
    { lat: 49.094098, lng: 20.339602 },
    { lat: 49.094231, lng: 20.339584 },
    { lat: 49.094241, lng: 20.339582 },
    { lat: 49.09425, lng: 20.339581 },
    { lat: 49.094302, lng: 20.339574 },
    { lat: 49.094336, lng: 20.339569 },
    { lat: 49.094347, lng: 20.339567 },
    { lat: 49.094377, lng: 20.339563 },
    { lat: 49.094467, lng: 20.339551 },
    { lat: 49.094481, lng: 20.339549 },
    { lat: 49.094517, lng: 20.339544 },
    { lat: 49.094559, lng: 20.339538 },
    { lat: 49.09459, lng: 20.339534 },
    { lat: 49.094618, lng: 20.33953 },
    { lat: 49.094739, lng: 20.339513 },
    { lat: 49.094911, lng: 20.339498 },
    { lat: 49.095221, lng: 20.33945 },
    { lat: 49.095613, lng: 20.340066 },
    { lat: 49.095661, lng: 20.340211 },
    { lat: 49.095689, lng: 20.340296 },
    { lat: 49.09583, lng: 20.340629 },
    { lat: 49.095937, lng: 20.340998 },
    { lat: 49.095874, lng: 20.341197 },
    { lat: 49.09575, lng: 20.341617 },
    { lat: 49.095685, lng: 20.341845 },
    { lat: 49.095677, lng: 20.341871 },
    { lat: 49.095665, lng: 20.341915 },
    { lat: 49.095577, lng: 20.342203 },
    { lat: 49.095516, lng: 20.342405 },
    { lat: 49.09537, lng: 20.342897 },
    { lat: 49.095313, lng: 20.343052 },
    { lat: 49.095291, lng: 20.343104 },
    { lat: 49.095004, lng: 20.34381 },
    { lat: 49.094934, lng: 20.343959 },
    { lat: 49.094918, lng: 20.343992 },
    { lat: 49.094784, lng: 20.344269 },
    { lat: 49.094782, lng: 20.344545 },
    { lat: 49.094511, lng: 20.345387 },
    { lat: 49.094473, lng: 20.345414 },
    { lat: 49.09438, lng: 20.34569 },
    { lat: 49.094359, lng: 20.345752 },
    { lat: 49.094296, lng: 20.345937 },
    { lat: 49.094275, lng: 20.345999 },
    { lat: 49.094224, lng: 20.346137 },
    { lat: 49.094033, lng: 20.346709 },
    { lat: 49.093578, lng: 20.348101 },
    { lat: 49.093543, lng: 20.348203 },
    { lat: 49.093317, lng: 20.349142 },
    { lat: 49.093078, lng: 20.350151 },
    { lat: 49.092715, lng: 20.351314 },
    { lat: 49.093122, lng: 20.351971 },
    { lat: 49.093473, lng: 20.352533 },
    { lat: 49.093839, lng: 20.353134 },
    { lat: 49.093912, lng: 20.353248 },
    { lat: 49.09416, lng: 20.35359 },
    { lat: 49.094536, lng: 20.354109 },
    { lat: 49.094578, lng: 20.354106 },
    { lat: 49.094421, lng: 20.354891 },
    { lat: 49.094245, lng: 20.355476 },
    { lat: 49.094171, lng: 20.355728 },
    { lat: 49.093921, lng: 20.356332 },
    { lat: 49.093755, lng: 20.35667 },
    { lat: 49.09363, lng: 20.356922 },
    { lat: 49.093563, lng: 20.357052 },
    { lat: 49.093334, lng: 20.357425 },
    { lat: 49.093039, lng: 20.357683 },
    { lat: 49.092797, lng: 20.357902 },
    { lat: 49.092689, lng: 20.358015 },
    { lat: 49.092547, lng: 20.358143 },
    { lat: 49.092263, lng: 20.358557 },
    { lat: 49.092016, lng: 20.358896 },
    { lat: 49.091728, lng: 20.359217 },
    { lat: 49.091529, lng: 20.359401 },
    { lat: 49.091402, lng: 20.359517 },
    { lat: 49.091037, lng: 20.359795 },
    { lat: 49.090719, lng: 20.360097 },
    { lat: 49.090561, lng: 20.360301 },
    { lat: 49.090409, lng: 20.360707 },
    { lat: 49.090175, lng: 20.361321 },
    { lat: 49.089909, lng: 20.362326 },
    { lat: 49.089704, lng: 20.363166 },
    { lat: 49.089502, lng: 20.363995 },
    { lat: 49.089473, lng: 20.364167 },
    { lat: 49.089439, lng: 20.364485 },
    { lat: 49.089356, lng: 20.365429 },
    { lat: 49.089232, lng: 20.366725 },
    { lat: 49.089221, lng: 20.366902 },
    { lat: 49.089211, lng: 20.367053 },
    { lat: 49.089258, lng: 20.367475 },
    { lat: 49.089275, lng: 20.367713 },
    { lat: 49.08929, lng: 20.3682 },
    { lat: 49.089285, lng: 20.368405 },
    { lat: 49.089128, lng: 20.36895 },
    { lat: 49.089049, lng: 20.369214 },
    { lat: 49.088886, lng: 20.36961 },
    { lat: 49.08881, lng: 20.369856 },
    { lat: 49.08873, lng: 20.370284 },
    { lat: 49.088687, lng: 20.370521 },
    { lat: 49.088643, lng: 20.370901 },
    { lat: 49.08859, lng: 20.371463 },
    { lat: 49.088576, lng: 20.371884 },
    { lat: 49.088553, lng: 20.372191 },
    { lat: 49.088499, lng: 20.372557 },
    { lat: 49.088422, lng: 20.372961 },
    { lat: 49.088363, lng: 20.373187 },
    { lat: 49.088195, lng: 20.373584 },
    { lat: 49.088097, lng: 20.373759 },
    { lat: 49.088087, lng: 20.373778 },
  ],
  Rakúsy: [
    { lat: 49.1815852, lng: 20.404676 },
    { lat: 49.1814114, lng: 20.4054363 },
    { lat: 49.181361, lng: 20.4060993 },
    { lat: 49.1815298, lng: 20.4063313 },
    { lat: 49.1815104, lng: 20.4063878 },
    { lat: 49.181446, lng: 20.4063812 },
    { lat: 49.1814802, lng: 20.4065106 },
    { lat: 49.1814457, lng: 20.4066972 },
    { lat: 49.1815025, lng: 20.4066814 },
    { lat: 49.1815316, lng: 20.4067667 },
    { lat: 49.1814088, lng: 20.4068239 },
    { lat: 49.1814155, lng: 20.407058 },
    { lat: 49.1813391, lng: 20.4070964 },
    { lat: 49.1813322, lng: 20.4071812 },
    { lat: 49.1812225, lng: 20.4072575 },
    { lat: 49.1811611, lng: 20.4071798 },
    { lat: 49.181117, lng: 20.4072934 },
    { lat: 49.1810119, lng: 20.4072836 },
    { lat: 49.181041, lng: 20.4074851 },
    { lat: 49.1808764, lng: 20.4076626 },
    { lat: 49.1807818, lng: 20.4076001 },
    { lat: 49.180747, lng: 20.4076586 },
    { lat: 49.1807005, lng: 20.4079823 },
    { lat: 49.1807743, lng: 20.408087 },
    { lat: 49.1807433, lng: 20.4081898 },
    { lat: 49.1807755, lng: 20.4082366 },
    { lat: 49.1807326, lng: 20.408317 },
    { lat: 49.1806687, lng: 20.4083 },
    { lat: 49.180653, lng: 20.4084223 },
    { lat: 49.1807633, lng: 20.4084755 },
    { lat: 49.180704, lng: 20.4086956 },
    { lat: 49.1807651, lng: 20.4089706 },
    { lat: 49.1806879, lng: 20.4090436 },
    { lat: 49.1806854, lng: 20.4091281 },
    { lat: 49.1805301, lng: 20.4090694 },
    { lat: 49.1806032, lng: 20.4092739 },
    { lat: 49.1805838, lng: 20.4093278 },
    { lat: 49.1805032, lng: 20.4093064 },
    { lat: 49.1805162, lng: 20.4094737 },
    { lat: 49.1805805, lng: 20.4093842 },
    { lat: 49.1806847, lng: 20.4093768 },
    { lat: 49.1807241, lng: 20.4095515 },
    { lat: 49.1806087, lng: 20.4099543 },
    { lat: 49.1806648, lng: 20.4099781 },
    { lat: 49.1807156, lng: 20.4101306 },
    { lat: 49.1806773, lng: 20.4102225 },
    { lat: 49.1806775, lng: 20.4106367 },
    { lat: 49.1807947, lng: 20.410793 },
    { lat: 49.180749, lng: 20.4108931 },
    { lat: 49.1807747, lng: 20.4110373 },
    { lat: 49.1807466, lng: 20.4111298 },
    { lat: 49.1808142, lng: 20.4112195 },
    { lat: 49.1808369, lng: 20.4113712 },
    { lat: 49.1807932, lng: 20.4114701 },
    { lat: 49.1805457, lng: 20.4115945 },
    { lat: 49.180682, lng: 20.4116533 },
    { lat: 49.1807013, lng: 20.4117467 },
    { lat: 49.1807688, lng: 20.4117724 },
    { lat: 49.1807756, lng: 20.4121011 },
    { lat: 49.1806852, lng: 20.4120615 },
    { lat: 49.1806522, lng: 20.4119622 },
    { lat: 49.1805578, lng: 20.4120558 },
    { lat: 49.1804976, lng: 20.4123095 },
    { lat: 49.1805514, lng: 20.4123346 },
    { lat: 49.1805722, lng: 20.4125555 },
    { lat: 49.180459, lng: 20.4126321 },
    { lat: 49.1804799, lng: 20.4127101 },
    { lat: 49.1805612, lng: 20.4127444 },
    { lat: 49.1804626, lng: 20.4130809 },
    { lat: 49.1803386, lng: 20.4130209 },
    { lat: 49.1803219, lng: 20.4131153 },
    { lat: 49.1802512, lng: 20.4131503 },
    { lat: 49.1802666, lng: 20.4135574 },
    { lat: 49.1801275, lng: 20.4134786 },
    { lat: 49.1800847, lng: 20.4133892 },
    { lat: 49.1800383, lng: 20.4134159 },
    { lat: 49.1801612, lng: 20.413689 },
    { lat: 49.1801455, lng: 20.4137487 },
    { lat: 49.1800646, lng: 20.4137558 },
    { lat: 49.180109, lng: 20.4138563 },
    { lat: 49.1801236, lng: 20.4141221 },
    { lat: 49.1802872, lng: 20.4141886 },
    { lat: 49.1801991, lng: 20.4143907 },
    { lat: 49.1800204, lng: 20.4143388 },
    { lat: 49.1800304, lng: 20.4144282 },
    { lat: 49.1801655, lng: 20.4146323 },
    { lat: 49.1801236, lng: 20.4147141 },
    { lat: 49.1800031, lng: 20.4147175 },
    { lat: 49.1801129, lng: 20.4150861 },
    { lat: 49.1801655, lng: 20.4150803 },
    { lat: 49.1801128, lng: 20.4152354 },
    { lat: 49.1801915, lng: 20.4152879 },
    { lat: 49.1801275, lng: 20.4153871 },
    { lat: 49.1801832, lng: 20.4154872 },
    { lat: 49.1800647, lng: 20.4156586 },
    { lat: 49.1800746, lng: 20.4157387 },
    { lat: 49.1800008, lng: 20.4158725 },
    { lat: 49.1800757, lng: 20.4160999 },
    { lat: 49.1800798, lng: 20.4162946 },
    { lat: 49.1802073, lng: 20.416242 },
    { lat: 49.1802903, lng: 20.4165344 },
    { lat: 49.1802535, lng: 20.4166331 },
    { lat: 49.1800955, lng: 20.4166225 },
    { lat: 49.1800912, lng: 20.4166891 },
    { lat: 49.1801414, lng: 20.4167315 },
    { lat: 49.1800909, lng: 20.4170055 },
    { lat: 49.1801298, lng: 20.417024 },
    { lat: 49.1801833, lng: 20.416916 },
    { lat: 49.1802601, lng: 20.4169261 },
    { lat: 49.1802724, lng: 20.4172515 },
    { lat: 49.1802372, lng: 20.4172996 },
    { lat: 49.1802715, lng: 20.4173782 },
    { lat: 49.1801792, lng: 20.4174648 },
    { lat: 49.1802973, lng: 20.4174918 },
    { lat: 49.180336, lng: 20.4176795 },
    { lat: 49.180271, lng: 20.4177814 },
    { lat: 49.1801636, lng: 20.4177379 },
    { lat: 49.1801134, lng: 20.4179586 },
    { lat: 49.1799472, lng: 20.4181172 },
    { lat: 49.1799302, lng: 20.4182029 },
    { lat: 49.1799995, lng: 20.4184573 },
    { lat: 49.1799298, lng: 20.4187615 },
    { lat: 49.1798498, lng: 20.4187282 },
    { lat: 49.1798348, lng: 20.4189883 },
    { lat: 49.1800011, lng: 20.4189812 },
    { lat: 49.1800042, lng: 20.4192368 },
    { lat: 49.1799371, lng: 20.4194043 },
    { lat: 49.1798326, lng: 20.4193786 },
    { lat: 49.1797381, lng: 20.419587 },
    { lat: 49.1798049, lng: 20.4195959 },
    { lat: 49.1798018, lng: 20.4196929 },
    { lat: 49.179671, lng: 20.4197797 },
    { lat: 49.1796598, lng: 20.4201056 },
    { lat: 49.1797627, lng: 20.4201509 },
    { lat: 49.1797731, lng: 20.4203008 },
    { lat: 49.1797267, lng: 20.4203855 },
    { lat: 49.1795847, lng: 20.420322 },
    { lat: 49.1795953, lng: 20.4204528 },
    { lat: 49.1795302, lng: 20.420641 },
    { lat: 49.1795486, lng: 20.4209165 },
    { lat: 49.179658, lng: 20.4210454 },
    { lat: 49.1796539, lng: 20.4211739 },
    { lat: 49.1797312, lng: 20.4211045 },
    { lat: 49.1798815, lng: 20.4211478 },
    { lat: 49.1798504, lng: 20.4213679 },
    { lat: 49.1797781, lng: 20.4213893 },
    { lat: 49.1798344, lng: 20.4214801 },
    { lat: 49.1797561, lng: 20.4215262 },
    { lat: 49.1798404, lng: 20.4216948 },
    { lat: 49.1798516, lng: 20.4221426 },
    { lat: 49.1799009, lng: 20.422167 },
    { lat: 49.1799207, lng: 20.4220041 },
    { lat: 49.1799948, lng: 20.4220292 },
    { lat: 49.1800331, lng: 20.4221176 },
    { lat: 49.1799976, lng: 20.4222849 },
    { lat: 49.1800963, lng: 20.4224493 },
    { lat: 49.1800451, lng: 20.4225564 },
    { lat: 49.1798896, lng: 20.4225243 },
    { lat: 49.1798948, lng: 20.4226916 },
    { lat: 49.1799849, lng: 20.4228974 },
    { lat: 49.1800683, lng: 20.4229621 },
    { lat: 49.1800848, lng: 20.4230995 },
    { lat: 49.1801449, lng: 20.4231122 },
    { lat: 49.1801393, lng: 20.4231998 },
    { lat: 49.1800655, lng: 20.4232441 },
    { lat: 49.1800806, lng: 20.4233209 },
    { lat: 49.1802316, lng: 20.4234084 },
    { lat: 49.1801678, lng: 20.4235421 },
    { lat: 49.180231, lng: 20.423755 },
    { lat: 49.1801757, lng: 20.4238397 },
    { lat: 49.1801966, lng: 20.4239732 },
    { lat: 49.1803429, lng: 20.4239136 },
    { lat: 49.1803531, lng: 20.423976 },
    { lat: 49.1802128, lng: 20.4241658 },
    { lat: 49.1802091, lng: 20.4243046 },
    { lat: 49.1801737, lng: 20.4243553 },
    { lat: 49.1813081, lng: 20.4252053 },
    { lat: 49.1818157, lng: 20.4233217 },
    { lat: 49.1821706, lng: 20.418948 },
    { lat: 49.182958, lng: 20.4155935 },
    { lat: 49.1856639, lng: 20.4092995 },
    { lat: 49.1908143, lng: 20.3975565 },
    { lat: 49.1934651, lng: 20.389806 },
    { lat: 49.1939389, lng: 20.3887413 },
    { lat: 49.1948033, lng: 20.3874313 },
    { lat: 49.19572, lng: 20.3857801 },
    { lat: 49.1966491, lng: 20.384392 },
    { lat: 49.1970612, lng: 20.3834183 },
    { lat: 49.1983468, lng: 20.3809349 },
    { lat: 49.1990872, lng: 20.3797967 },
    { lat: 49.200095, lng: 20.3772017 },
    { lat: 49.200276, lng: 20.3771359 },
    { lat: 49.200598, lng: 20.3761061 },
    { lat: 49.2008918, lng: 20.3724435 },
    { lat: 49.2007593, lng: 20.3724513 },
    { lat: 49.2010022, lng: 20.3710594 },
    { lat: 49.200941, lng: 20.367217 },
    { lat: 49.200769, lng: 20.36244 },
    { lat: 49.200874, lng: 20.361305 },
    { lat: 49.200937, lng: 20.360627 },
    { lat: 49.20089, lng: 20.360563 },
    { lat: 49.200997, lng: 20.358704 },
    { lat: 49.200898, lng: 20.3565 },
    { lat: 49.200873, lng: 20.355601 },
    { lat: 49.200798, lng: 20.354399 },
    { lat: 49.200803, lng: 20.353295 },
    { lat: 49.20117, lng: 20.351671 },
    { lat: 49.201778, lng: 20.349347 },
    { lat: 49.202343, lng: 20.347365 },
    { lat: 49.202414, lng: 20.345224 },
    { lat: 49.202371, lng: 20.34286 },
    { lat: 49.202294, lng: 20.340476 },
    { lat: 49.202195, lng: 20.340414 },
    { lat: 49.201544, lng: 20.340015 },
    { lat: 49.200833, lng: 20.339599 },
    { lat: 49.200789, lng: 20.339573 },
    { lat: 49.200471, lng: 20.339646 },
    { lat: 49.20042, lng: 20.339747 },
    { lat: 49.200284, lng: 20.339692 },
    { lat: 49.200196, lng: 20.339735 },
    { lat: 49.200106, lng: 20.339797 },
    { lat: 49.199955, lng: 20.339919 },
    { lat: 49.19982, lng: 20.340063 },
    { lat: 49.199752, lng: 20.340161 },
    { lat: 49.199644, lng: 20.340203 },
    { lat: 49.199531, lng: 20.340229 },
    { lat: 49.199159, lng: 20.340574 },
    { lat: 49.199103, lng: 20.340713 },
    { lat: 49.199086, lng: 20.340948 },
    { lat: 49.198961, lng: 20.341388 },
    { lat: 49.198863, lng: 20.341539 },
    { lat: 49.199041, lng: 20.342294 },
    { lat: 49.198943, lng: 20.342302 },
    { lat: 49.198915, lng: 20.342288 },
    { lat: 49.198921, lng: 20.342576 },
    { lat: 49.198824, lng: 20.342851 },
    { lat: 49.198736, lng: 20.342935 },
    { lat: 49.198787, lng: 20.34307 },
    { lat: 49.198781, lng: 20.343285 },
    { lat: 49.198732, lng: 20.343375 },
    { lat: 49.198812, lng: 20.343534 },
    { lat: 49.198717, lng: 20.343598 },
    { lat: 49.198741, lng: 20.343847 },
    { lat: 49.1986, lng: 20.344374 },
    { lat: 49.198606, lng: 20.34452 },
    { lat: 49.198655, lng: 20.344808 },
    { lat: 49.198588, lng: 20.344848 },
    { lat: 49.198535, lng: 20.344891 },
    { lat: 49.198502, lng: 20.344956 },
    { lat: 49.198656, lng: 20.345364 },
    { lat: 49.198614, lng: 20.345486 },
    { lat: 49.198403, lng: 20.345546 },
    { lat: 49.198333, lng: 20.345938 },
    { lat: 49.198355, lng: 20.346031 },
    { lat: 49.198437, lng: 20.346183 },
    { lat: 49.198506, lng: 20.34642 },
    { lat: 49.198389, lng: 20.346809 },
    { lat: 49.198344, lng: 20.346896 },
    { lat: 49.198276, lng: 20.347175 },
    { lat: 49.198032, lng: 20.347161 },
    { lat: 49.19791, lng: 20.347388 },
    { lat: 49.19779, lng: 20.347432 },
    { lat: 49.197469, lng: 20.347781 },
    { lat: 49.197402, lng: 20.347836 },
    { lat: 49.197341, lng: 20.348046 },
    { lat: 49.197327, lng: 20.348079 },
    { lat: 49.197322, lng: 20.348087 },
    { lat: 49.197213, lng: 20.348102 },
    { lat: 49.197158, lng: 20.348241 },
    { lat: 49.196962, lng: 20.348835 },
    { lat: 49.197056, lng: 20.349078 },
    { lat: 49.197079, lng: 20.349245 },
    { lat: 49.197164, lng: 20.349566 },
    { lat: 49.197213, lng: 20.349641 },
    { lat: 49.19725, lng: 20.349782 },
    { lat: 49.197179, lng: 20.349879 },
    { lat: 49.197086, lng: 20.349915 },
    { lat: 49.197035, lng: 20.350005 },
    { lat: 49.196943, lng: 20.350054 },
    { lat: 49.196928, lng: 20.350068 },
    { lat: 49.196915, lng: 20.350187 },
    { lat: 49.197025, lng: 20.350554 },
    { lat: 49.196991, lng: 20.35085 },
    { lat: 49.196967, lng: 20.351235 },
    { lat: 49.196807, lng: 20.351488 },
    { lat: 49.196648, lng: 20.351614 },
    { lat: 49.19658, lng: 20.351717 },
    { lat: 49.196415, lng: 20.351895 },
    { lat: 49.196387, lng: 20.352063 },
    { lat: 49.196333, lng: 20.352126 },
    { lat: 49.196302, lng: 20.352185 },
    { lat: 49.196352, lng: 20.352759 },
    { lat: 49.196307, lng: 20.353152 },
    { lat: 49.196246, lng: 20.353326 },
    { lat: 49.196195, lng: 20.353419 },
    { lat: 49.196134, lng: 20.353457 },
    { lat: 49.196068, lng: 20.353545 },
    { lat: 49.19598, lng: 20.353837 },
    { lat: 49.196111, lng: 20.354116 },
    { lat: 49.196047, lng: 20.354887 },
    { lat: 49.196127, lng: 20.355025 },
    { lat: 49.196287, lng: 20.355233 },
    { lat: 49.196261, lng: 20.355456 },
    { lat: 49.196196, lng: 20.355744 },
    { lat: 49.196051, lng: 20.355925 },
    { lat: 49.196105, lng: 20.355956 },
    { lat: 49.196025, lng: 20.356252 },
    { lat: 49.195727, lng: 20.35626 },
    { lat: 49.195691, lng: 20.356288 },
    { lat: 49.19583, lng: 20.356355 },
    { lat: 49.195702, lng: 20.357568 },
    { lat: 49.195722, lng: 20.357685 },
    { lat: 49.195599, lng: 20.357771 },
    { lat: 49.195452, lng: 20.357807 },
    { lat: 49.19542, lng: 20.357976 },
    { lat: 49.195533, lng: 20.358164 },
    { lat: 49.195701, lng: 20.358702 },
    { lat: 49.195717, lng: 20.359205 },
    { lat: 49.195705, lng: 20.35922 },
    { lat: 49.1957, lng: 20.359222 },
    { lat: 49.195684, lng: 20.359227 },
    { lat: 49.195617, lng: 20.359252 },
    { lat: 49.194164, lng: 20.358467 },
    { lat: 49.193732, lng: 20.358211 },
    { lat: 49.193663, lng: 20.358171 },
    { lat: 49.193545, lng: 20.358133 },
    { lat: 49.193403, lng: 20.358035 },
    { lat: 49.193035, lng: 20.357851 },
    { lat: 49.19237, lng: 20.357476 },
    { lat: 49.191326, lng: 20.35761 },
    { lat: 49.190319, lng: 20.357753 },
    { lat: 49.189785, lng: 20.357826 },
    { lat: 49.189317, lng: 20.357889 },
    { lat: 49.188751, lng: 20.357972 },
    { lat: 49.18826, lng: 20.358044 },
    { lat: 49.187253, lng: 20.358159 },
    { lat: 49.186243, lng: 20.358257 },
    { lat: 49.185705, lng: 20.358477 },
    { lat: 49.185724, lng: 20.357797 },
    { lat: 49.185678, lng: 20.357796 },
    { lat: 49.185704, lng: 20.357179 },
    { lat: 49.185682, lng: 20.355936 },
    { lat: 49.185434, lng: 20.356325 },
    { lat: 49.184827, lng: 20.357353 },
    { lat: 49.184359, lng: 20.358192 },
    { lat: 49.1844847, lng: 20.3635477 },
    { lat: 49.1838009, lng: 20.3667144 },
    { lat: 49.1836127, lng: 20.367243 },
    { lat: 49.1835495, lng: 20.3672674 },
    { lat: 49.1835018, lng: 20.3675189 },
    { lat: 49.1833443, lng: 20.3678798 },
    { lat: 49.1833571, lng: 20.3679734 },
    { lat: 49.1832354, lng: 20.368148 },
    { lat: 49.1831897, lng: 20.3687481 },
    { lat: 49.1831258, lng: 20.3689035 },
    { lat: 49.1831909, lng: 20.3689599 },
    { lat: 49.1831357, lng: 20.3691938 },
    { lat: 49.1831632, lng: 20.3694141 },
    { lat: 49.1831128, lng: 20.3696554 },
    { lat: 49.1828277, lng: 20.370388 },
    { lat: 49.1826168, lng: 20.3713458 },
    { lat: 49.1825433, lng: 20.3715164 },
    { lat: 49.182434, lng: 20.3715999 },
    { lat: 49.1821943, lng: 20.3721942 },
    { lat: 49.1820683, lng: 20.3723476 },
    { lat: 49.1819572, lng: 20.3726814 },
    { lat: 49.1819153, lng: 20.3727035 },
    { lat: 49.1818273, lng: 20.3735556 },
    { lat: 49.1818654, lng: 20.3742253 },
    { lat: 49.1817997, lng: 20.374482 },
    { lat: 49.1816198, lng: 20.3760798 },
    { lat: 49.1813251, lng: 20.3776819 },
    { lat: 49.1810739, lng: 20.3802743 },
    { lat: 49.1808495, lng: 20.3820059 },
    { lat: 49.1810517, lng: 20.3828845 },
    { lat: 49.181239, lng: 20.3834221 },
    { lat: 49.1820749, lng: 20.3848297 },
    { lat: 49.1840757, lng: 20.3891427 },
    { lat: 49.1836944, lng: 20.3900421 },
    { lat: 49.1836602, lng: 20.3902967 },
    { lat: 49.1837235, lng: 20.3904541 },
    { lat: 49.1838057, lng: 20.39048 },
    { lat: 49.1837815, lng: 20.3906135 },
    { lat: 49.1838901, lng: 20.3908382 },
    { lat: 49.183869, lng: 20.39111 },
    { lat: 49.1839584, lng: 20.3913015 },
    { lat: 49.183868, lng: 20.3914106 },
    { lat: 49.1839926, lng: 20.3915792 },
    { lat: 49.1841407, lng: 20.3923359 },
    { lat: 49.1842339, lng: 20.3924465 },
    { lat: 49.1842454, lng: 20.3926013 },
    { lat: 49.1843087, lng: 20.3927267 },
    { lat: 49.1842296, lng: 20.3929241 },
    { lat: 49.1842464, lng: 20.3930979 },
    { lat: 49.1841875, lng: 20.3933288 },
    { lat: 49.1840961, lng: 20.3934139 },
    { lat: 49.1839861, lng: 20.3938645 },
    { lat: 49.1839892, lng: 20.394953 },
    { lat: 49.1839038, lng: 20.3953741 },
    { lat: 49.1840863, lng: 20.3959673 },
    { lat: 49.1838664, lng: 20.3962221 },
    { lat: 49.1837553, lng: 20.3964407 },
    { lat: 49.1837143, lng: 20.3971688 },
    { lat: 49.1836001, lng: 20.3973362 },
    { lat: 49.1836102, lng: 20.3974836 },
    { lat: 49.1836762, lng: 20.397594 },
    { lat: 49.183345, lng: 20.3984763 },
    { lat: 49.1833273, lng: 20.3986326 },
    { lat: 49.1831469, lng: 20.3989833 },
    { lat: 49.1828158, lng: 20.4005231 },
    { lat: 49.1826234, lng: 20.4011472 },
    { lat: 49.1826243, lng: 20.4013192 },
    { lat: 49.182536, lng: 20.4015426 },
    { lat: 49.1825086, lng: 20.4017699 },
    { lat: 49.1824186, lng: 20.4019485 },
    { lat: 49.1824556, lng: 20.4020658 },
    { lat: 49.1824302, lng: 20.4021119 },
    { lat: 49.1824899, lng: 20.4021355 },
    { lat: 49.1824014, lng: 20.4025644 },
    { lat: 49.1823504, lng: 20.4026185 },
    { lat: 49.1823183, lng: 20.4025688 },
    { lat: 49.1823002, lng: 20.4026892 },
    { lat: 49.1823697, lng: 20.4027665 },
    { lat: 49.1824412, lng: 20.4027555 },
    { lat: 49.1824652, lng: 20.4028528 },
    { lat: 49.1824551, lng: 20.4029731 },
    { lat: 49.1825564, lng: 20.4034486 },
    { lat: 49.1825107, lng: 20.4036985 },
    { lat: 49.1817993, lng: 20.4038207 },
    { lat: 49.1817074, lng: 20.4040674 },
    { lat: 49.181808, lng: 20.4042557 },
    { lat: 49.1817046, lng: 20.4043789 },
    { lat: 49.1815852, lng: 20.404676 },
  ],
  Vlková: [
    { lat: 49.048252, lng: 20.402983 },
    { lat: 49.047492, lng: 20.403627 },
    { lat: 49.046644, lng: 20.404363 },
    { lat: 49.046842, lng: 20.405233 },
    { lat: 49.046899, lng: 20.405686 },
    { lat: 49.047028, lng: 20.406354 },
    { lat: 49.047046, lng: 20.406439 },
    { lat: 49.046888, lng: 20.406595 },
    { lat: 49.046792, lng: 20.406741 },
    { lat: 49.0467, lng: 20.406904 },
    { lat: 49.04669, lng: 20.406916 },
    { lat: 49.046437, lng: 20.407242 },
    { lat: 49.046397, lng: 20.407264 },
    { lat: 49.046352, lng: 20.407215 },
    { lat: 49.046313, lng: 20.407236 },
    { lat: 49.04626, lng: 20.407408 },
    { lat: 49.046245, lng: 20.407426 },
    { lat: 49.046158, lng: 20.407404 },
    { lat: 49.046074, lng: 20.40736 },
    { lat: 49.045976, lng: 20.407345 },
    { lat: 49.045911, lng: 20.407267 },
    { lat: 49.045818, lng: 20.407193 },
    { lat: 49.04574, lng: 20.407184 },
    { lat: 49.045627, lng: 20.407229 },
    { lat: 49.045539, lng: 20.407095 },
    { lat: 49.045418, lng: 20.407054 },
    { lat: 49.045414, lng: 20.407072 },
    { lat: 49.045409, lng: 20.407081 },
    { lat: 49.045368, lng: 20.407157 },
    { lat: 49.045326, lng: 20.407231 },
    { lat: 49.045235, lng: 20.407364 },
    { lat: 49.045169, lng: 20.407455 },
    { lat: 49.045087, lng: 20.407568 },
    { lat: 49.044673, lng: 20.4068 },
    { lat: 49.044442, lng: 20.406446 },
    { lat: 49.044236, lng: 20.406163 },
    { lat: 49.044186, lng: 20.406112 },
    { lat: 49.043994, lng: 20.405917 },
    { lat: 49.04387, lng: 20.405806 },
    { lat: 49.043732, lng: 20.405719 },
    { lat: 49.043587, lng: 20.405653 },
    { lat: 49.043335, lng: 20.405558 },
    { lat: 49.043048, lng: 20.40549 },
    { lat: 49.042885, lng: 20.405483 },
    { lat: 49.042705, lng: 20.405472 },
    { lat: 49.042385, lng: 20.405511 },
    { lat: 49.041819, lng: 20.405511 },
    { lat: 49.04147, lng: 20.405523 },
    { lat: 49.040991, lng: 20.405557 },
    { lat: 49.040804, lng: 20.40561 },
    { lat: 49.040612, lng: 20.405692 },
    { lat: 49.040392, lng: 20.405821 },
    { lat: 49.040346, lng: 20.405849 },
    { lat: 49.040332, lng: 20.405857 },
    { lat: 49.04048, lng: 20.40648 },
    { lat: 49.039365, lng: 20.407339 },
    { lat: 49.039336, lng: 20.407362 },
    { lat: 49.038552, lng: 20.407984 },
    { lat: 49.038054, lng: 20.407942 },
    { lat: 49.03764, lng: 20.407908 },
    { lat: 49.037634, lng: 20.407907 },
    { lat: 49.037635, lng: 20.407948 },
    { lat: 49.03768, lng: 20.409408 },
    { lat: 49.037675, lng: 20.409735 },
    { lat: 49.037692, lng: 20.409952 },
    { lat: 49.037707, lng: 20.410073 },
    { lat: 49.037703, lng: 20.410324 },
    { lat: 49.03775, lng: 20.411204 },
    { lat: 49.037774, lng: 20.41142 },
    { lat: 49.037785, lng: 20.411991 },
    { lat: 49.037794, lng: 20.412092 },
    { lat: 49.037891, lng: 20.414329 },
    { lat: 49.037914, lng: 20.414846 },
    { lat: 49.037927, lng: 20.415183 },
    { lat: 49.037949, lng: 20.415793 },
    { lat: 49.037949, lng: 20.415804 },
    { lat: 49.03795, lng: 20.415813 },
    { lat: 49.0445321, lng: 20.4173886 },
    { lat: 49.0436627, lng: 20.4217793 },
    { lat: 49.0426989, lng: 20.4263271 },
    { lat: 49.0417656, lng: 20.4317204 },
    { lat: 49.0416052, lng: 20.4332888 },
    { lat: 49.0414792, lng: 20.4340926 },
    { lat: 49.0411437, lng: 20.4377911 },
    { lat: 49.0414224, lng: 20.4378945 },
    { lat: 49.0413158, lng: 20.4389497 },
    { lat: 49.0424209, lng: 20.4382739 },
    { lat: 49.0422199, lng: 20.4380495 },
    { lat: 49.0422412, lng: 20.4371271 },
    { lat: 49.0426696, lng: 20.4372304 },
    { lat: 49.0432828, lng: 20.4371876 },
    { lat: 49.0435437, lng: 20.4373299 },
    { lat: 49.0436892, lng: 20.4372468 },
    { lat: 49.0437137, lng: 20.4371535 },
    { lat: 49.0439091, lng: 20.4369843 },
    { lat: 49.0439413, lng: 20.4368874 },
    { lat: 49.0440949, lng: 20.4367807 },
    { lat: 49.0442086, lng: 20.4366322 },
    { lat: 49.0442386, lng: 20.4365018 },
    { lat: 49.0444284, lng: 20.436397 },
    { lat: 49.0444877, lng: 20.4358491 },
    { lat: 49.0445662, lng: 20.4357848 },
    { lat: 49.0447395, lng: 20.4357826 },
    { lat: 49.0447278, lng: 20.4354968 },
    { lat: 49.0448368, lng: 20.4354963 },
    { lat: 49.0450211, lng: 20.4356912 },
    { lat: 49.0451097, lng: 20.4354441 },
    { lat: 49.0454436, lng: 20.4354339 },
    { lat: 49.0457687, lng: 20.4351902 },
    { lat: 49.0459572, lng: 20.4351719 },
    { lat: 49.0460909, lng: 20.4353432 },
    { lat: 49.0464907, lng: 20.4379413 },
    { lat: 49.0467734, lng: 20.4391366 },
    { lat: 49.0471046, lng: 20.4398227 },
    { lat: 49.0473557, lng: 20.4402125 },
    { lat: 49.047753, lng: 20.441106 },
    { lat: 49.0481561, lng: 20.4415855 },
    { lat: 49.0482084, lng: 20.4418088 },
    { lat: 49.0482963, lng: 20.4419293 },
    { lat: 49.04881, lng: 20.4432197 },
    { lat: 49.0488844, lng: 20.4438478 },
    { lat: 49.0488375, lng: 20.4442773 },
    { lat: 49.0488993, lng: 20.4452284 },
    { lat: 49.048782, lng: 20.4457028 },
    { lat: 49.0488539, lng: 20.4457579 },
    { lat: 49.0486791, lng: 20.4468535 },
    { lat: 49.0487012, lng: 20.4471466 },
    { lat: 49.0487689, lng: 20.4471784 },
    { lat: 49.0487586, lng: 20.4473233 },
    { lat: 49.0496723, lng: 20.4494909 },
    { lat: 49.0500965, lng: 20.4512196 },
    { lat: 49.0505193, lng: 20.4533017 },
    { lat: 49.0506241, lng: 20.4536314 },
    { lat: 49.050851, lng: 20.4555668 },
    { lat: 49.0508264, lng: 20.4556602 },
    { lat: 49.0517257, lng: 20.4561918 },
    { lat: 49.0521766, lng: 20.4579757 },
    { lat: 49.052371, lng: 20.4590992 },
    { lat: 49.0527389, lng: 20.4604478 },
    { lat: 49.053479, lng: 20.4627017 },
    { lat: 49.0535483, lng: 20.4632298 },
    { lat: 49.0537561, lng: 20.4640014 },
    { lat: 49.0529692, lng: 20.4643889 },
    { lat: 49.0529956, lng: 20.464854 },
    { lat: 49.0526317, lng: 20.4650898 },
    { lat: 49.053248, lng: 20.4662449 },
    { lat: 49.0538569, lng: 20.4663249 },
    { lat: 49.0539992, lng: 20.4664902 },
    { lat: 49.0545028, lng: 20.466435 },
    { lat: 49.0550761, lng: 20.4665292 },
    { lat: 49.0551515, lng: 20.4664717 },
    { lat: 49.0554635, lng: 20.4665053 },
    { lat: 49.0555723, lng: 20.4664621 },
    { lat: 49.0556045, lng: 20.4663882 },
    { lat: 49.0561287, lng: 20.4662002 },
    { lat: 49.0561696, lng: 20.4660567 },
    { lat: 49.0562715, lng: 20.466013 },
    { lat: 49.0563001, lng: 20.4659373 },
    { lat: 49.0564026, lng: 20.4659348 },
    { lat: 49.0567629, lng: 20.4654893 },
    { lat: 49.0569485, lng: 20.4650596 },
    { lat: 49.0574512, lng: 20.4647744 },
    { lat: 49.057664, lng: 20.4651378 },
    { lat: 49.0577738, lng: 20.4651218 },
    { lat: 49.0578606, lng: 20.4652457 },
    { lat: 49.0581607, lng: 20.4654092 },
    { lat: 49.0583718, lng: 20.4653331 },
    { lat: 49.0587408, lng: 20.4654464 },
    { lat: 49.0591568, lng: 20.4652211 },
    { lat: 49.0592768, lng: 20.4652128 },
    { lat: 49.0594155, lng: 20.4653379 },
    { lat: 49.0598219, lng: 20.4653917 },
    { lat: 49.0599597, lng: 20.4654993 },
    { lat: 49.060059, lng: 20.4656806 },
    { lat: 49.0604071, lng: 20.4658981 },
    { lat: 49.0604924, lng: 20.4658892 },
    { lat: 49.0606712, lng: 20.466157 },
    { lat: 49.0607662, lng: 20.4661674 },
    { lat: 49.0609009, lng: 20.4660354 },
    { lat: 49.060985, lng: 20.4660884 },
    { lat: 49.0610885, lng: 20.4660547 },
    { lat: 49.0611381, lng: 20.4657542 },
    { lat: 49.0613538, lng: 20.4653868 },
    { lat: 49.0614886, lng: 20.465313 },
    { lat: 49.0619056, lng: 20.4654157 },
    { lat: 49.0619892, lng: 20.4653051 },
    { lat: 49.0621443, lng: 20.4647287 },
    { lat: 49.0623159, lng: 20.4647051 },
    { lat: 49.0626525, lng: 20.4651236 },
    { lat: 49.0629415, lng: 20.4651498 },
    { lat: 49.0630372, lng: 20.4649976 },
    { lat: 49.0631865, lng: 20.4650148 },
    { lat: 49.0632489, lng: 20.4649095 },
    { lat: 49.0633719, lng: 20.4650934 },
    { lat: 49.0634474, lng: 20.4649752 },
    { lat: 49.0634318, lng: 20.4647095 },
    { lat: 49.0635341, lng: 20.4645577 },
    { lat: 49.0635518, lng: 20.4648204 },
    { lat: 49.0636431, lng: 20.4649729 },
    { lat: 49.0637692, lng: 20.4648238 },
    { lat: 49.0637651, lng: 20.4645645 },
    { lat: 49.0638061, lng: 20.4645118 },
    { lat: 49.0640083, lng: 20.4649215 },
    { lat: 49.0640572, lng: 20.4649359 },
    { lat: 49.0641303, lng: 20.4646944 },
    { lat: 49.0641149, lng: 20.464462 },
    { lat: 49.0641913, lng: 20.4642765 },
    { lat: 49.0643887, lng: 20.4644953 },
    { lat: 49.0644375, lng: 20.4642365 },
    { lat: 49.0651492, lng: 20.4642393 },
    { lat: 49.0652681, lng: 20.4639001 },
    { lat: 49.0654803, lng: 20.4638281 },
    { lat: 49.0655604, lng: 20.4635925 },
    { lat: 49.0657001, lng: 20.4636812 },
    { lat: 49.0657632, lng: 20.463652 },
    { lat: 49.0659582, lng: 20.4630886 },
    { lat: 49.0660977, lng: 20.4631164 },
    { lat: 49.0663271, lng: 20.4630476 },
    { lat: 49.06647, lng: 20.4631034 },
    { lat: 49.0666563, lng: 20.4629673 },
    { lat: 49.0667765, lng: 20.4626624 },
    { lat: 49.0667773, lng: 20.4620807 },
    { lat: 49.066871, lng: 20.4620327 },
    { lat: 49.0668692, lng: 20.4621276 },
    { lat: 49.067122, lng: 20.4622704 },
    { lat: 49.0671902, lng: 20.4621309 },
    { lat: 49.0671595, lng: 20.4620359 },
    { lat: 49.0672509, lng: 20.4619188 },
    { lat: 49.0673456, lng: 20.4618977 },
    { lat: 49.0674343, lng: 20.4616462 },
    { lat: 49.067341, lng: 20.4616561 },
    { lat: 49.0675041, lng: 20.4613812 },
    { lat: 49.0676311, lng: 20.4615206 },
    { lat: 49.067538, lng: 20.4617431 },
    { lat: 49.0676764, lng: 20.4619781 },
    { lat: 49.0677359, lng: 20.4619747 },
    { lat: 49.0678229, lng: 20.4618354 },
    { lat: 49.0677382, lng: 20.4614941 },
    { lat: 49.0678192, lng: 20.4611646 },
    { lat: 49.0678908, lng: 20.4611548 },
    { lat: 49.0680169, lng: 20.4613007 },
    { lat: 49.0681449, lng: 20.4612045 },
    { lat: 49.0681954, lng: 20.4608389 },
    { lat: 49.0684296, lng: 20.4608386 },
    { lat: 49.0684632, lng: 20.4607704 },
    { lat: 49.0684309, lng: 20.460092 },
    { lat: 49.0687003, lng: 20.4602485 },
    { lat: 49.0687787, lng: 20.4601505 },
    { lat: 49.0687853, lng: 20.4599668 },
    { lat: 49.0689068, lng: 20.4598743 },
    { lat: 49.0690174, lng: 20.4598794 },
    { lat: 49.0690513, lng: 20.4602172 },
    { lat: 49.0692361, lng: 20.4604558 },
    { lat: 49.0694498, lng: 20.4603614 },
    { lat: 49.0695654, lng: 20.4604419 },
    { lat: 49.0698144, lng: 20.4600863 },
    { lat: 49.0697384, lng: 20.4600426 },
    { lat: 49.0697165, lng: 20.459935 },
    { lat: 49.0697496, lng: 20.4598301 },
    { lat: 49.0699183, lng: 20.4597932 },
    { lat: 49.0699718, lng: 20.4598431 },
    { lat: 49.0700315, lng: 20.4597485 },
    { lat: 49.0700689, lng: 20.459821 },
    { lat: 49.0700285, lng: 20.4599692 },
    { lat: 49.0700529, lng: 20.46023 },
    { lat: 49.0703191, lng: 20.4601253 },
    { lat: 49.070189, lng: 20.4598964 },
    { lat: 49.0703051, lng: 20.4597204 },
    { lat: 49.0703761, lng: 20.4596948 },
    { lat: 49.0704325, lng: 20.4598142 },
    { lat: 49.0706467, lng: 20.4599166 },
    { lat: 49.0708311, lng: 20.4598754 },
    { lat: 49.0710158, lng: 20.4600575 },
    { lat: 49.0710757, lng: 20.4600362 },
    { lat: 49.071116, lng: 20.4598786 },
    { lat: 49.0712537, lng: 20.4597938 },
    { lat: 49.0715514, lng: 20.4600498 },
    { lat: 49.0717322, lng: 20.4599148 },
    { lat: 49.07202, lng: 20.4600586 },
    { lat: 49.072174, lng: 20.459987 },
    { lat: 49.0723873, lng: 20.4600945 },
    { lat: 49.0724588, lng: 20.4599888 },
    { lat: 49.0726075, lng: 20.4599579 },
    { lat: 49.0726703, lng: 20.4597467 },
    { lat: 49.0729234, lng: 20.459469 },
    { lat: 49.0731715, lng: 20.4595968 },
    { lat: 49.0735309, lng: 20.4596374 },
    { lat: 49.0737628, lng: 20.4595432 },
    { lat: 49.0739225, lng: 20.4596309 },
    { lat: 49.0739495, lng: 20.4593302 },
    { lat: 49.0738118, lng: 20.4590814 },
    { lat: 49.0741283, lng: 20.4590851 },
    { lat: 49.074204, lng: 20.4590031 },
    { lat: 49.0741779, lng: 20.4588736 },
    { lat: 49.0744268, lng: 20.4586968 },
    { lat: 49.0744154, lng: 20.4586321 },
    { lat: 49.0742798, lng: 20.4585329 },
    { lat: 49.074263, lng: 20.4584116 },
    { lat: 49.0743625, lng: 20.4583034 },
    { lat: 49.074573, lng: 20.4582744 },
    { lat: 49.0742138, lng: 20.457912 },
    { lat: 49.0741819, lng: 20.4576347 },
    { lat: 49.0742276, lng: 20.4574695 },
    { lat: 49.0743136, lng: 20.4574534 },
    { lat: 49.0746962, lng: 20.4578029 },
    { lat: 49.0747616, lng: 20.4578108 },
    { lat: 49.0747951, lng: 20.4577103 },
    { lat: 49.0747771, lng: 20.4575628 },
    { lat: 49.0746249, lng: 20.4572851 },
    { lat: 49.0746227, lng: 20.4571079 },
    { lat: 49.0746867, lng: 20.4570736 },
    { lat: 49.0748178, lng: 20.4567875 },
    { lat: 49.0746875, lng: 20.456434 },
    { lat: 49.0747202, lng: 20.4562548 },
    { lat: 49.0747842, lng: 20.4561875 },
    { lat: 49.074819, lng: 20.4562168 },
    { lat: 49.0749748, lng: 20.4558416 },
    { lat: 49.0751558, lng: 20.4555924 },
    { lat: 49.0753881, lng: 20.4550065 },
    { lat: 49.0752787, lng: 20.4548501 },
    { lat: 49.075098, lng: 20.4548314 },
    { lat: 49.0751108, lng: 20.4546949 },
    { lat: 49.0752072, lng: 20.4545622 },
    { lat: 49.0755295, lng: 20.4544225 },
    { lat: 49.0757085, lng: 20.4544532 },
    { lat: 49.0757697, lng: 20.4545499 },
    { lat: 49.0757223, lng: 20.4546075 },
    { lat: 49.0757883, lng: 20.4546872 },
    { lat: 49.0757863, lng: 20.4548163 },
    { lat: 49.0758699, lng: 20.4547602 },
    { lat: 49.0757901, lng: 20.4543132 },
    { lat: 49.0761539, lng: 20.4542891 },
    { lat: 49.076206, lng: 20.4541179 },
    { lat: 49.0761865, lng: 20.4540453 },
    { lat: 49.0763754, lng: 20.453678 },
    { lat: 49.076495, lng: 20.4537473 },
    { lat: 49.0765643, lng: 20.4536766 },
    { lat: 49.0765933, lng: 20.4535186 },
    { lat: 49.0768263, lng: 20.4533345 },
    { lat: 49.0765521, lng: 20.4530186 },
    { lat: 49.0766359, lng: 20.4528264 },
    { lat: 49.076823, lng: 20.4526819 },
    { lat: 49.076908, lng: 20.4518862 },
    { lat: 49.0766083, lng: 20.4511631 },
    { lat: 49.076476, lng: 20.4504 },
    { lat: 49.0761665, lng: 20.4495654 },
    { lat: 49.0762109, lng: 20.4494858 },
    { lat: 49.0757498, lng: 20.4488131 },
    { lat: 49.0757401, lng: 20.4486739 },
    { lat: 49.0759024, lng: 20.4481965 },
    { lat: 49.075706, lng: 20.4479733 },
    { lat: 49.0758173, lng: 20.4474964 },
    { lat: 49.0751555, lng: 20.4469328 },
    { lat: 49.0749041, lng: 20.4459956 },
    { lat: 49.0746669, lng: 20.4441513 },
    { lat: 49.0747551, lng: 20.4441045 },
    { lat: 49.0746622, lng: 20.4435193 },
    { lat: 49.0746055, lng: 20.4425525 },
    { lat: 49.074454, lng: 20.4416999 },
    { lat: 49.0741631, lng: 20.4411169 },
    { lat: 49.073595, lng: 20.4403858 },
    { lat: 49.0729261, lng: 20.4397278 },
    { lat: 49.0725568, lng: 20.4394581 },
    { lat: 49.0726898, lng: 20.4391613 },
    { lat: 49.0727973, lng: 20.4386715 },
    { lat: 49.0726745, lng: 20.4378936 },
    { lat: 49.0726644, lng: 20.4372678 },
    { lat: 49.0725796, lng: 20.4364209 },
    { lat: 49.0725622, lng: 20.4353914 },
    { lat: 49.0729128, lng: 20.4332542 },
    { lat: 49.0729379, lng: 20.432852 },
    { lat: 49.0721934, lng: 20.4325408 },
    { lat: 49.0717536, lng: 20.4324672 },
    { lat: 49.0704728, lng: 20.432538 },
    { lat: 49.0699465, lng: 20.4326459 },
    { lat: 49.0698295, lng: 20.4299356 },
    { lat: 49.06987, lng: 20.4290092 },
    { lat: 49.0698011, lng: 20.4279315 },
    { lat: 49.0698189, lng: 20.4275389 },
    { lat: 49.0693985, lng: 20.4260378 },
    { lat: 49.0694062, lng: 20.4252004 },
    { lat: 49.0692892, lng: 20.4240461 },
    { lat: 49.0691588, lng: 20.4235739 },
    { lat: 49.0689682, lng: 20.4224038 },
    { lat: 49.0687856, lng: 20.4216245 },
    { lat: 49.0686847, lng: 20.4201815 },
    { lat: 49.0687006, lng: 20.4194756 },
    { lat: 49.0686273, lng: 20.4179268 },
    { lat: 49.0686254, lng: 20.4178753 },
    { lat: 49.0684052, lng: 20.4180666 },
    { lat: 49.068275, lng: 20.4180188 },
    { lat: 49.0679132, lng: 20.4174743 },
    { lat: 49.0677481, lng: 20.416678 },
    { lat: 49.0675618, lng: 20.4165518 },
    { lat: 49.0673922, lng: 20.4167383 },
    { lat: 49.0672956, lng: 20.4166541 },
    { lat: 49.0671232, lng: 20.4166503 },
    { lat: 49.0670336, lng: 20.4165712 },
    { lat: 49.0669974, lng: 20.4163884 },
    { lat: 49.0669161, lng: 20.4163855 },
    { lat: 49.0668402, lng: 20.4165494 },
    { lat: 49.0661271, lng: 20.4166889 },
    { lat: 49.0655435, lng: 20.4164123 },
    { lat: 49.0651761, lng: 20.4167279 },
    { lat: 49.0650458, lng: 20.4167691 },
    { lat: 49.0649486, lng: 20.4167568 },
    { lat: 49.0649149, lng: 20.4166101 },
    { lat: 49.0646627, lng: 20.4165543 },
    { lat: 49.0645111, lng: 20.4162635 },
    { lat: 49.0639861, lng: 20.4156648 },
    { lat: 49.0638777, lng: 20.4154772 },
    { lat: 49.0637469, lng: 20.4153246 },
    { lat: 49.0636137, lng: 20.4152853 },
    { lat: 49.0630926, lng: 20.4143713 },
    { lat: 49.0625205, lng: 20.4138022 },
    { lat: 49.0622203, lng: 20.41334 },
    { lat: 49.0610005, lng: 20.4121134 },
    { lat: 49.0586821, lng: 20.409944 },
    { lat: 49.0583995, lng: 20.4097395 },
    { lat: 49.056946, lng: 20.4089683 },
    { lat: 49.0563276, lng: 20.4084279 },
    { lat: 49.0556612, lng: 20.4069514 },
    { lat: 49.0553823, lng: 20.4065495 },
    { lat: 49.0542567, lng: 20.4053709 },
    { lat: 49.0538183, lng: 20.4050921 },
    { lat: 49.0528517, lng: 20.4047256 },
    { lat: 49.0506591, lng: 20.4048339 },
    { lat: 49.0498249, lng: 20.4048036 },
    { lat: 49.0491527, lng: 20.4049532 },
    { lat: 49.0487003, lng: 20.4038947 },
    { lat: 49.048252, lng: 20.402983 },
  ],
  SpišskáStaráVes: [
    { lat: 49.3879857, lng: 20.3852401 },
    { lat: 49.389067, lng: 20.3812512 },
    { lat: 49.3892813, lng: 20.3799856 },
    { lat: 49.3899291, lng: 20.37896 },
    { lat: 49.3901272, lng: 20.3787801 },
    { lat: 49.3903607, lng: 20.3782558 },
    { lat: 49.3905187, lng: 20.3781167 },
    { lat: 49.3907331, lng: 20.3771539 },
    { lat: 49.3908576, lng: 20.376253 },
    { lat: 49.3917674, lng: 20.376465 },
    { lat: 49.3922121, lng: 20.3744249 },
    { lat: 49.3925202, lng: 20.374144 },
    { lat: 49.3928554, lng: 20.373988 },
    { lat: 49.3932078, lng: 20.3739319 },
    { lat: 49.3933746, lng: 20.3742597 },
    { lat: 49.393528, lng: 20.3743844 },
    { lat: 49.3936522, lng: 20.3741908 },
    { lat: 49.3937704, lng: 20.3734776 },
    { lat: 49.3938662, lng: 20.3732154 },
    { lat: 49.3942245, lng: 20.3726575 },
    { lat: 49.3942821, lng: 20.3725161 },
    { lat: 49.3942773, lng: 20.3723893 },
    { lat: 49.3947517, lng: 20.3723993 },
    { lat: 49.3961629, lng: 20.3714066 },
    { lat: 49.3964511, lng: 20.3711123 },
    { lat: 49.3966155, lng: 20.3708131 },
    { lat: 49.3968607, lng: 20.3706929 },
    { lat: 49.3970999, lng: 20.3707666 },
    { lat: 49.3973332, lng: 20.3706851 },
    { lat: 49.3981089, lng: 20.3702088 },
    { lat: 49.3985652, lng: 20.3702768 },
    { lat: 49.3987854, lng: 20.3704466 },
    { lat: 49.3988622, lng: 20.3705998 },
    { lat: 49.3995306, lng: 20.3703207 },
    { lat: 49.3999643, lng: 20.3706272 },
    { lat: 49.4004209, lng: 20.3713257 },
    { lat: 49.4008948, lng: 20.3717088 },
    { lat: 49.4014333, lng: 20.3720107 },
    { lat: 49.4020388, lng: 20.3720947 },
    { lat: 49.4022922, lng: 20.3720695 },
    { lat: 49.4025561, lng: 20.3719932 },
    { lat: 49.4028907, lng: 20.3716734 },
    { lat: 49.4034884, lng: 20.3712856 },
    { lat: 49.4038558, lng: 20.3709694 },
    { lat: 49.4040991, lng: 20.3708866 },
    { lat: 49.4042814, lng: 20.3706411 },
    { lat: 49.4044302, lng: 20.3702052 },
    { lat: 49.404664, lng: 20.369759 },
    { lat: 49.404517, lng: 20.369775 },
    { lat: 49.403711, lng: 20.369912 },
    { lat: 49.40329, lng: 20.369804 },
    { lat: 49.40316, lng: 20.36978 },
    { lat: 49.40288, lng: 20.369731 },
    { lat: 49.402697, lng: 20.36966 },
    { lat: 49.402379, lng: 20.369539 },
    { lat: 49.40206, lng: 20.36945 },
    { lat: 49.401916, lng: 20.369389 },
    { lat: 49.401513, lng: 20.369271 },
    { lat: 49.40134, lng: 20.369231 },
    { lat: 49.401334, lng: 20.36923 },
    { lat: 49.400991, lng: 20.368991 },
    { lat: 49.400762, lng: 20.368766 },
    { lat: 49.400616, lng: 20.368623 },
    { lat: 49.400562, lng: 20.368569 },
    { lat: 49.40037, lng: 20.368269 },
    { lat: 49.400216, lng: 20.368002 },
    { lat: 49.400033, lng: 20.367606 },
    { lat: 49.399784, lng: 20.367104 },
    { lat: 49.399752, lng: 20.367033 },
    { lat: 49.399598, lng: 20.366695 },
    { lat: 49.399437, lng: 20.366218 },
    { lat: 49.399243, lng: 20.365737 },
    { lat: 49.399092, lng: 20.365328 },
    { lat: 49.399009, lng: 20.364946 },
    { lat: 49.398881, lng: 20.364478 },
    { lat: 49.398735, lng: 20.36387 },
    { lat: 49.3985448, lng: 20.3634097 },
    { lat: 49.398461, lng: 20.363207 },
    { lat: 49.398262, lng: 20.362271 },
    { lat: 49.39822, lng: 20.361659 },
    { lat: 49.398318, lng: 20.360325 },
    { lat: 49.398408, lng: 20.35859 },
    { lat: 49.398422, lng: 20.357906 },
    { lat: 49.398444, lng: 20.357712 },
    { lat: 49.398523, lng: 20.357197 },
    { lat: 49.398702, lng: 20.356716 },
    { lat: 49.398801, lng: 20.356471 },
    { lat: 49.398913, lng: 20.356186 },
    { lat: 49.399121, lng: 20.355899 },
    { lat: 49.399228, lng: 20.355542 },
    { lat: 49.399248, lng: 20.355475 },
    { lat: 49.399388, lng: 20.35519 },
    { lat: 49.399631, lng: 20.354644 },
    { lat: 49.399764, lng: 20.354439 },
    { lat: 49.400141, lng: 20.353766 },
    { lat: 49.400417, lng: 20.353368 },
    { lat: 49.400542, lng: 20.353204 },
    { lat: 49.400779, lng: 20.35281 },
    { lat: 49.40105, lng: 20.352442 },
    { lat: 49.401245, lng: 20.352004 },
    { lat: 49.401427, lng: 20.351486 },
    { lat: 49.401495, lng: 20.350979 },
    { lat: 49.401532, lng: 20.350749 },
    { lat: 49.401546, lng: 20.350264 },
    { lat: 49.401544, lng: 20.349882 },
    { lat: 49.401448, lng: 20.349327 },
    { lat: 49.401349, lng: 20.348901 },
    { lat: 49.401258, lng: 20.348484 },
    { lat: 49.400973, lng: 20.347978 },
    { lat: 49.400925, lng: 20.347774 },
    { lat: 49.400834, lng: 20.347359 },
    { lat: 49.400741, lng: 20.347011 },
    { lat: 49.400695, lng: 20.346643 },
    { lat: 49.400647, lng: 20.345999 },
    { lat: 49.400614, lng: 20.345597 },
    { lat: 49.400617, lng: 20.344782 },
    { lat: 49.400617, lng: 20.344746 },
    { lat: 49.400638, lng: 20.344325 },
    { lat: 49.400682, lng: 20.343928 },
    { lat: 49.400745, lng: 20.343443 },
    { lat: 49.400801, lng: 20.343038 },
    { lat: 49.400841, lng: 20.342609 },
    { lat: 49.400921, lng: 20.342203 },
    { lat: 49.401005, lng: 20.341739 },
    { lat: 49.401068, lng: 20.34136 },
    { lat: 49.401164, lng: 20.340849 },
    { lat: 49.401199, lng: 20.340503 },
    { lat: 49.401277, lng: 20.339778 },
    { lat: 49.401309, lng: 20.33942 },
    { lat: 49.401332, lng: 20.339253 },
    { lat: 49.401362, lng: 20.339009 },
    { lat: 49.401567, lng: 20.33848 },
    { lat: 49.401705, lng: 20.33826 },
    { lat: 49.401889, lng: 20.337877 },
    { lat: 49.402243, lng: 20.337691 },
    { lat: 49.402479, lng: 20.337471 },
    { lat: 49.402066, lng: 20.336272 },
    { lat: 49.401967, lng: 20.335991 },
    { lat: 49.4019444, lng: 20.335947 },
    { lat: 49.401908, lng: 20.335877 },
    { lat: 49.401898, lng: 20.335859 },
    { lat: 49.401472, lng: 20.335061 },
    { lat: 49.401705, lng: 20.333999 },
    { lat: 49.401844, lng: 20.333359 },
    { lat: 49.402158, lng: 20.332238 },
    { lat: 49.402355, lng: 20.331365 },
    { lat: 49.402594, lng: 20.330273 },
    { lat: 49.402801, lng: 20.328939 },
    { lat: 49.402895, lng: 20.32818 },
    { lat: 49.402841, lng: 20.327219 },
    { lat: 49.402812, lng: 20.326699 },
    { lat: 49.402818, lng: 20.326651 },
    { lat: 49.40283, lng: 20.32657 },
    { lat: 49.403028, lng: 20.325081 },
    { lat: 49.402724, lng: 20.324696 },
    { lat: 49.402695, lng: 20.324661 },
    { lat: 49.402268, lng: 20.324121 },
    { lat: 49.401974, lng: 20.32326 },
    { lat: 49.401853, lng: 20.322431 },
    { lat: 49.401755, lng: 20.322156 },
    { lat: 49.401385, lng: 20.321892 },
    { lat: 49.400786, lng: 20.321616 },
    { lat: 49.400251, lng: 20.321235 },
    { lat: 49.399585, lng: 20.320659 },
    { lat: 49.398008, lng: 20.320118 },
    { lat: 49.396874, lng: 20.319799 },
    { lat: 49.396418, lng: 20.319691 },
    { lat: 49.396198, lng: 20.319638 },
    { lat: 49.396089, lng: 20.319612 },
    { lat: 49.395555, lng: 20.3193 },
    { lat: 49.394624, lng: 20.318775 },
    { lat: 49.394471, lng: 20.318689 },
    { lat: 49.394433, lng: 20.318667 },
    { lat: 49.394347, lng: 20.318615 },
    { lat: 49.393528, lng: 20.318114 },
    { lat: 49.393136, lng: 20.317884 },
    { lat: 49.392677, lng: 20.31787 },
    { lat: 49.392173, lng: 20.317842 },
    { lat: 49.391689, lng: 20.317708 },
    { lat: 49.390636, lng: 20.317399 },
    { lat: 49.390247, lng: 20.317302 },
    { lat: 49.389605, lng: 20.317411 },
    { lat: 49.388892, lng: 20.317462 },
    { lat: 49.388727, lng: 20.31751 },
    { lat: 49.388823, lng: 20.317777 },
    { lat: 49.388826, lng: 20.31779 },
    { lat: 49.388851, lng: 20.317926 },
    { lat: 49.388868, lng: 20.318019 },
    { lat: 49.38887, lng: 20.318029 },
    { lat: 49.388909, lng: 20.318101 },
    { lat: 49.388937, lng: 20.318152 },
    { lat: 49.388949, lng: 20.318173 },
    { lat: 49.388952, lng: 20.318181 },
    { lat: 49.388968, lng: 20.318404 },
    { lat: 49.388984, lng: 20.318443 },
    { lat: 49.389051, lng: 20.318609 },
    { lat: 49.389092, lng: 20.318681 },
    { lat: 49.389147, lng: 20.318779 },
    { lat: 49.389175, lng: 20.31884 },
    { lat: 49.389187, lng: 20.318868 },
    { lat: 49.389236, lng: 20.318979 },
    { lat: 49.389246, lng: 20.319001 },
    { lat: 49.389299, lng: 20.319106 },
    { lat: 49.389322, lng: 20.319152 },
    { lat: 49.389381, lng: 20.319268 },
    { lat: 49.389389, lng: 20.319285 },
    { lat: 49.389457, lng: 20.319372 },
    { lat: 49.3895, lng: 20.319427 },
    { lat: 49.38951, lng: 20.319439 },
    { lat: 49.389517, lng: 20.31948 },
    { lat: 49.389524, lng: 20.319516 },
    { lat: 49.389548, lng: 20.319646 },
    { lat: 49.389648, lng: 20.319866 },
    { lat: 49.389651, lng: 20.319885 },
    { lat: 49.389654, lng: 20.319904 },
    { lat: 49.389665, lng: 20.319974 },
    { lat: 49.38967, lng: 20.320004 },
    { lat: 49.389673, lng: 20.320025 },
    { lat: 49.389732, lng: 20.320098 },
    { lat: 49.389751, lng: 20.320122 },
    { lat: 49.389784, lng: 20.320165 },
    { lat: 49.389794, lng: 20.32024 },
    { lat: 49.389796, lng: 20.320265 },
    { lat: 49.389798, lng: 20.320282 },
    { lat: 49.390134, lng: 20.320835 },
    { lat: 49.390267, lng: 20.321102 },
    { lat: 49.390269, lng: 20.321527 },
    { lat: 49.390448, lng: 20.32182 },
    { lat: 49.390455, lng: 20.321832 },
    { lat: 49.390465, lng: 20.321846 },
    { lat: 49.390438, lng: 20.321961 },
    { lat: 49.390496, lng: 20.322317 },
    { lat: 49.390442, lng: 20.322392 },
    { lat: 49.390352, lng: 20.322516 },
    { lat: 49.390335, lng: 20.322539 },
    { lat: 49.39034, lng: 20.322559 },
    { lat: 49.390351, lng: 20.322598 },
    { lat: 49.390424, lng: 20.322867 },
    { lat: 49.390429, lng: 20.322929 },
    { lat: 49.390439, lng: 20.323053 },
    { lat: 49.390441, lng: 20.32307 },
    { lat: 49.390439, lng: 20.323285 },
    { lat: 49.390439, lng: 20.323303 },
    { lat: 49.390438, lng: 20.323426 },
    { lat: 49.390509, lng: 20.323615 },
    { lat: 49.390601, lng: 20.323732 },
    { lat: 49.390603, lng: 20.323902 },
    { lat: 49.390608, lng: 20.324203 },
    { lat: 49.390609, lng: 20.324272 },
    { lat: 49.390461, lng: 20.324319 },
    { lat: 49.390563, lng: 20.324335 },
    { lat: 49.390592, lng: 20.324455 },
    { lat: 49.390601, lng: 20.324593 },
    { lat: 49.39059, lng: 20.324661 },
    { lat: 49.390583, lng: 20.324705 },
    { lat: 49.390576, lng: 20.324742 },
    { lat: 49.39051, lng: 20.324869 },
    { lat: 49.390546, lng: 20.324929 },
    { lat: 49.390571, lng: 20.324971 },
    { lat: 49.390592, lng: 20.325004 },
    { lat: 49.390606, lng: 20.325114 },
    { lat: 49.390574, lng: 20.325375 },
    { lat: 49.390628, lng: 20.325477 },
    { lat: 49.390656, lng: 20.325528 },
    { lat: 49.390759, lng: 20.325719 },
    { lat: 49.390751, lng: 20.325736 },
    { lat: 49.390743, lng: 20.325749 },
    { lat: 49.390722, lng: 20.325787 },
    { lat: 49.390861, lng: 20.326185 },
    { lat: 49.39087, lng: 20.326212 },
    { lat: 49.390897, lng: 20.326288 },
    { lat: 49.390838, lng: 20.326575 },
    { lat: 49.390834, lng: 20.326587 },
    { lat: 49.390862, lng: 20.326686 },
    { lat: 49.390888, lng: 20.326787 },
    { lat: 49.390892, lng: 20.326952 },
    { lat: 49.390872, lng: 20.327023 },
    { lat: 49.390919, lng: 20.327274 },
    { lat: 49.390926, lng: 20.327491 },
    { lat: 49.390899, lng: 20.327657 },
    { lat: 49.390943, lng: 20.327881 },
    { lat: 49.390975, lng: 20.328096 },
    { lat: 49.390977, lng: 20.328102 },
    { lat: 49.391006, lng: 20.328274 },
    { lat: 49.391012, lng: 20.328349 },
    { lat: 49.391017, lng: 20.328405 },
    { lat: 49.391022, lng: 20.328455 },
    { lat: 49.391021, lng: 20.328508 },
    { lat: 49.391021, lng: 20.328529 },
    { lat: 49.391041, lng: 20.328567 },
    { lat: 49.391094, lng: 20.328671 },
    { lat: 49.391129, lng: 20.328829 },
    { lat: 49.391087, lng: 20.329076 },
    { lat: 49.391086, lng: 20.329091 },
    { lat: 49.391095, lng: 20.3293 },
    { lat: 49.391096, lng: 20.329322 },
    { lat: 49.391081, lng: 20.329462 },
    { lat: 49.391075, lng: 20.329505 },
    { lat: 49.391094, lng: 20.329532 },
    { lat: 49.391181, lng: 20.329676 },
    { lat: 49.39123, lng: 20.329755 },
    { lat: 49.39126, lng: 20.329902 },
    { lat: 49.391287, lng: 20.329962 },
    { lat: 49.391299, lng: 20.329991 },
    { lat: 49.391314, lng: 20.330023 },
    { lat: 49.391331, lng: 20.330031 },
    { lat: 49.391345, lng: 20.330037 },
    { lat: 49.391384, lng: 20.330054 },
    { lat: 49.391384, lng: 20.330063 },
    { lat: 49.391394, lng: 20.330096 },
    { lat: 49.391402, lng: 20.330119 },
    { lat: 49.391335, lng: 20.330431 },
    { lat: 49.391345, lng: 20.330579 },
    { lat: 49.391348, lng: 20.330605 },
    { lat: 49.391355, lng: 20.330704 },
    { lat: 49.391355, lng: 20.330706 },
    { lat: 49.391357, lng: 20.330723 },
    { lat: 49.391372, lng: 20.330895 },
    { lat: 49.391337, lng: 20.330982 },
    { lat: 49.391394, lng: 20.331045 },
    { lat: 49.391401, lng: 20.331088 },
    { lat: 49.39141, lng: 20.33115 },
    { lat: 49.391419, lng: 20.331204 },
    { lat: 49.391429, lng: 20.331276 },
    { lat: 49.391166, lng: 20.331213 },
    { lat: 49.391075, lng: 20.331113 },
    { lat: 49.391112, lng: 20.330933 },
    { lat: 49.391075, lng: 20.330899 },
    { lat: 49.391054, lng: 20.330881 },
    { lat: 49.390986, lng: 20.33082 },
    { lat: 49.390978, lng: 20.330812 },
    { lat: 49.390973, lng: 20.330821 },
    { lat: 49.390873, lng: 20.33101 },
    { lat: 49.390803, lng: 20.330987 },
    { lat: 49.39086, lng: 20.330696 },
    { lat: 49.390783, lng: 20.330509 },
    { lat: 49.390742, lng: 20.330527 },
    { lat: 49.390727, lng: 20.330534 },
    { lat: 49.390639, lng: 20.330573 },
    { lat: 49.390626, lng: 20.33058 },
    { lat: 49.390583, lng: 20.330599 },
    { lat: 49.390591, lng: 20.330701 },
    { lat: 49.390594, lng: 20.330728 },
    { lat: 49.390597, lng: 20.33077 },
    { lat: 49.390585, lng: 20.330768 },
    { lat: 49.390576, lng: 20.330767 },
    { lat: 49.390446, lng: 20.330752 },
    { lat: 49.390307, lng: 20.330748 },
    { lat: 49.390284, lng: 20.330703 },
    { lat: 49.390274, lng: 20.330684 },
    { lat: 49.390163, lng: 20.330468 },
    { lat: 49.390075, lng: 20.330328 },
    { lat: 49.390059, lng: 20.330304 },
    { lat: 49.39005, lng: 20.33029 },
    { lat: 49.389893, lng: 20.330106 },
    { lat: 49.389748, lng: 20.330009 },
    { lat: 49.389524, lng: 20.32988 },
    { lat: 49.389443, lng: 20.329711 },
    { lat: 49.389355, lng: 20.329665 },
    { lat: 49.389196, lng: 20.329477 },
    { lat: 49.389106, lng: 20.329461 },
    { lat: 49.388952, lng: 20.329276 },
    { lat: 49.388816, lng: 20.329101 },
    { lat: 49.388628, lng: 20.328787 },
    { lat: 49.388586, lng: 20.328824 },
    { lat: 49.388559, lng: 20.328848 },
    { lat: 49.388454, lng: 20.328941 },
    { lat: 49.388373, lng: 20.328917 },
    { lat: 49.38836, lng: 20.328913 },
    { lat: 49.388149, lng: 20.32885 },
    { lat: 49.388, lng: 20.328669 },
    { lat: 49.387939, lng: 20.328626 },
    { lat: 49.387619, lng: 20.328395 },
    { lat: 49.387602, lng: 20.328238 },
    { lat: 49.387442, lng: 20.32818 },
    { lat: 49.387316, lng: 20.328165 },
    { lat: 49.387168, lng: 20.328183 },
    { lat: 49.387101, lng: 20.328191 },
    { lat: 49.3871, lng: 20.328177 },
    { lat: 49.387075, lng: 20.327926 },
    { lat: 49.387083, lng: 20.327752 },
    { lat: 49.387058, lng: 20.327669 },
    { lat: 49.386967, lng: 20.327583 },
    { lat: 49.386859, lng: 20.32756 },
    { lat: 49.386755, lng: 20.327645 },
    { lat: 49.38649, lng: 20.32778 },
    { lat: 49.386302, lng: 20.327858 },
    { lat: 49.386284, lng: 20.327866 },
    { lat: 49.386252, lng: 20.327879 },
    { lat: 49.386285, lng: 20.327818 },
    { lat: 49.386304, lng: 20.327783 },
    { lat: 49.386312, lng: 20.327769 },
    { lat: 49.386434, lng: 20.327767 },
    { lat: 49.386392, lng: 20.327382 },
    { lat: 49.386338, lng: 20.327306 },
    { lat: 49.386332, lng: 20.327296 },
    { lat: 49.386284, lng: 20.32723 },
    { lat: 49.386096, lng: 20.327252 },
    { lat: 49.386025, lng: 20.327129 },
    { lat: 49.386004, lng: 20.327094 },
    { lat: 49.38596, lng: 20.327016 },
    { lat: 49.385648, lng: 20.326919 },
    { lat: 49.385574, lng: 20.326896 },
    { lat: 49.385514, lng: 20.326878 },
    { lat: 49.38516, lng: 20.32685 },
    { lat: 49.385076, lng: 20.32683 },
    { lat: 49.384792, lng: 20.326767 },
    { lat: 49.384758, lng: 20.326734 },
    { lat: 49.384437, lng: 20.326424 },
    { lat: 49.384387, lng: 20.326424 },
    { lat: 49.384215, lng: 20.326421 },
    { lat: 49.384203, lng: 20.326421 },
    { lat: 49.384317, lng: 20.326147 },
    { lat: 49.384259, lng: 20.326081 },
    { lat: 49.384243, lng: 20.326087 },
    { lat: 49.384133, lng: 20.326125 },
    { lat: 49.384053, lng: 20.32607 },
    { lat: 49.384024, lng: 20.326051 },
    { lat: 49.383892, lng: 20.325962 },
    { lat: 49.383744, lng: 20.325863 },
    { lat: 49.383729, lng: 20.325857 },
    { lat: 49.383544, lng: 20.325783 },
    { lat: 49.383498, lng: 20.325623 },
    { lat: 49.383485, lng: 20.325581 },
    { lat: 49.383439, lng: 20.325523 },
    { lat: 49.38336, lng: 20.325421 },
    { lat: 49.383304, lng: 20.32524 },
    { lat: 49.383255, lng: 20.325077 },
    { lat: 49.383168, lng: 20.32498 },
    { lat: 49.383155, lng: 20.324966 },
    { lat: 49.383114, lng: 20.324919 },
    { lat: 49.382989, lng: 20.324865 },
    { lat: 49.382782, lng: 20.324758 },
    { lat: 49.382636, lng: 20.324725 },
    { lat: 49.382375, lng: 20.324698 },
    { lat: 49.382264, lng: 20.32455 },
    { lat: 49.382044, lng: 20.324163 },
    { lat: 49.381994, lng: 20.324164 },
    { lat: 49.381974, lng: 20.324165 },
    { lat: 49.381859, lng: 20.324169 },
    { lat: 49.381849, lng: 20.324169 },
    { lat: 49.381843, lng: 20.324171 },
    { lat: 49.381721, lng: 20.324206 },
    { lat: 49.381676, lng: 20.324219 },
    { lat: 49.381662, lng: 20.324223 },
    { lat: 49.381657, lng: 20.324214 },
    { lat: 49.38164, lng: 20.324183 },
    { lat: 49.381611, lng: 20.32413 },
    { lat: 49.381441, lng: 20.324145 },
    { lat: 49.381245, lng: 20.324125 },
    { lat: 49.381223, lng: 20.324046 },
    { lat: 49.381207, lng: 20.323993 },
    { lat: 49.381206, lng: 20.323991 },
    { lat: 49.381207, lng: 20.323991 },
    { lat: 49.381227, lng: 20.323983 },
    { lat: 49.381381, lng: 20.323924 },
    { lat: 49.381439, lng: 20.323862 },
    { lat: 49.381461, lng: 20.323721 },
    { lat: 49.381319, lng: 20.323627 },
    { lat: 49.381311, lng: 20.323621 },
    { lat: 49.381195, lng: 20.323568 },
    { lat: 49.381164, lng: 20.323554 },
    { lat: 49.38111, lng: 20.32368 },
    { lat: 49.38104, lng: 20.323778 },
    { lat: 49.380987, lng: 20.323853 },
    { lat: 49.380957, lng: 20.323895 },
    { lat: 49.380943, lng: 20.32388 },
    { lat: 49.38089, lng: 20.323827 },
    { lat: 49.380886, lng: 20.323824 },
    { lat: 49.380886, lng: 20.323816 },
    { lat: 49.380885, lng: 20.323732 },
    { lat: 49.380883, lng: 20.323661 },
    { lat: 49.380891, lng: 20.323524 },
    { lat: 49.380894, lng: 20.323454 },
    { lat: 49.380898, lng: 20.323361 },
    { lat: 49.380842, lng: 20.323334 },
    { lat: 49.380797, lng: 20.323312 },
    { lat: 49.380555, lng: 20.323194 },
    { lat: 49.380333, lng: 20.323075 },
    { lat: 49.380346, lng: 20.323378 },
    { lat: 49.380346, lng: 20.323396 },
    { lat: 49.380335, lng: 20.323381 },
    { lat: 49.380227, lng: 20.323226 },
    { lat: 49.380194, lng: 20.323198 },
    { lat: 49.380178, lng: 20.323186 },
    { lat: 49.380047, lng: 20.323077 },
    { lat: 49.379951, lng: 20.322997 },
    { lat: 49.379769, lng: 20.323025 },
    { lat: 49.37973, lng: 20.323019 },
    { lat: 49.379675, lng: 20.323013 },
    { lat: 49.379655, lng: 20.32301 },
    { lat: 49.379502, lng: 20.322926 },
    { lat: 49.379479, lng: 20.322913 },
    { lat: 49.379422, lng: 20.322817 },
    { lat: 49.379385, lng: 20.322754 },
    { lat: 49.379253, lng: 20.322566 },
    { lat: 49.379159, lng: 20.322426 },
    { lat: 49.379134, lng: 20.32239 },
    { lat: 49.378938, lng: 20.32222 },
    { lat: 49.378929, lng: 20.322194 },
    { lat: 49.378812, lng: 20.321877 },
    { lat: 49.378666, lng: 20.321684 },
    { lat: 49.378545, lng: 20.321578 },
    { lat: 49.378384, lng: 20.321378 },
    { lat: 49.378345, lng: 20.321507 },
    { lat: 49.378153, lng: 20.321507 },
    { lat: 49.3779, lng: 20.321288 },
    { lat: 49.377783, lng: 20.32131 },
    { lat: 49.377677, lng: 20.321239 },
    { lat: 49.37758, lng: 20.321172 },
    { lat: 49.377503, lng: 20.321133 },
    { lat: 49.377404, lng: 20.321084 },
    { lat: 49.377203, lng: 20.321355 },
    { lat: 49.377069, lng: 20.32137 },
    { lat: 49.37689, lng: 20.321335 },
    { lat: 49.376762, lng: 20.321286 },
    { lat: 49.376582, lng: 20.321022 },
    { lat: 49.376544, lng: 20.321096 },
    { lat: 49.37653, lng: 20.321121 },
    { lat: 49.376494, lng: 20.321187 },
    { lat: 49.376474, lng: 20.321211 },
    { lat: 49.376233, lng: 20.321486 },
    { lat: 49.376213, lng: 20.321509 },
    { lat: 49.376095, lng: 20.321465 },
    { lat: 49.376038, lng: 20.321444 },
    { lat: 49.3758677, lng: 20.3213515 },
    { lat: 49.3758354, lng: 20.3214008 },
    { lat: 49.3757762, lng: 20.3214914 },
    { lat: 49.375579, lng: 20.321872 },
    { lat: 49.375113, lng: 20.322669 },
    { lat: 49.374131, lng: 20.322947 },
    { lat: 49.37351, lng: 20.323243 },
    { lat: 49.373048, lng: 20.323608 },
    { lat: 49.372375, lng: 20.324111 },
    { lat: 49.372161, lng: 20.324354 },
    { lat: 49.3721, lng: 20.324902 },
    { lat: 49.372099, lng: 20.324911 },
    { lat: 49.372026, lng: 20.324902 },
    { lat: 49.371391, lng: 20.324825 },
    { lat: 49.371277, lng: 20.325584 },
    { lat: 49.37045, lng: 20.325448 },
    { lat: 49.36952, lng: 20.325299 },
    { lat: 49.368453, lng: 20.325374 },
    { lat: 49.3678211, lng: 20.3254407 },
    { lat: 49.367038, lng: 20.325733 },
    { lat: 49.3670311, lng: 20.3257357 },
    { lat: 49.3658616, lng: 20.3262156 },
    { lat: 49.3647057, lng: 20.3253258 },
    { lat: 49.3647017, lng: 20.3253491 },
    { lat: 49.3645946, lng: 20.3260929 },
    { lat: 49.3642586, lng: 20.3276018 },
    { lat: 49.3642337, lng: 20.3291822 },
    { lat: 49.3642891, lng: 20.3300955 },
    { lat: 49.3642075, lng: 20.3308781 },
    { lat: 49.3640562, lng: 20.3317413 },
    { lat: 49.3643105, lng: 20.331942 },
    { lat: 49.3643576, lng: 20.3321426 },
    { lat: 49.3644625, lng: 20.3322094 },
    { lat: 49.3645156, lng: 20.3323567 },
    { lat: 49.3650024, lng: 20.332457 },
    { lat: 49.3650878, lng: 20.3325443 },
    { lat: 49.3651992, lng: 20.3325376 },
    { lat: 49.3652848, lng: 20.3326603 },
    { lat: 49.3653904, lng: 20.3325948 },
    { lat: 49.3653985, lng: 20.3326894 },
    { lat: 49.3654709, lng: 20.3327559 },
    { lat: 49.3657133, lng: 20.3325888 },
    { lat: 49.3659999, lng: 20.3327534 },
    { lat: 49.3660287, lng: 20.3328574 },
    { lat: 49.3662034, lng: 20.3329218 },
    { lat: 49.3663212, lng: 20.3328798 },
    { lat: 49.3664709, lng: 20.333142 },
    { lat: 49.3666474, lng: 20.3330751 },
    { lat: 49.3667376, lng: 20.3331693 },
    { lat: 49.3667355, lng: 20.3333161 },
    { lat: 49.3669821, lng: 20.3336143 },
    { lat: 49.3671777, lng: 20.3336079 },
    { lat: 49.3673285, lng: 20.3338064 },
    { lat: 49.3673958, lng: 20.3337465 },
    { lat: 49.367442, lng: 20.3338005 },
    { lat: 49.367416, lng: 20.3338907 },
    { lat: 49.3674606, lng: 20.3339965 },
    { lat: 49.3675922, lng: 20.3341678 },
    { lat: 49.3677174, lng: 20.334232 },
    { lat: 49.3678079, lng: 20.334187 },
    { lat: 49.3679462, lng: 20.3345015 },
    { lat: 49.368038, lng: 20.3344904 },
    { lat: 49.367651, lng: 20.3366939 },
    { lat: 49.3672989, lng: 20.3394924 },
    { lat: 49.3673636, lng: 20.3412415 },
    { lat: 49.3672788, lng: 20.3440525 },
    { lat: 49.3672047, lng: 20.3450259 },
    { lat: 49.3672548, lng: 20.3471289 },
    { lat: 49.3670925, lng: 20.3500393 },
    { lat: 49.3671465, lng: 20.3508353 },
    { lat: 49.3673036, lng: 20.3517042 },
    { lat: 49.3671486, lng: 20.3556 },
    { lat: 49.3671983, lng: 20.3568152 },
    { lat: 49.3671659, lng: 20.3589458 },
    { lat: 49.3669984, lng: 20.3603961 },
    { lat: 49.3669217, lng: 20.3619863 },
    { lat: 49.3663134, lng: 20.3612662 },
    { lat: 49.3660846, lng: 20.3611739 },
    { lat: 49.3660687, lng: 20.3614614 },
    { lat: 49.3661541, lng: 20.3619025 },
    { lat: 49.3662121, lng: 20.3626234 },
    { lat: 49.3662371, lng: 20.3639913 },
    { lat: 49.3661306, lng: 20.3655968 },
    { lat: 49.3660797, lng: 20.369377 },
    { lat: 49.3660454, lng: 20.3697764 },
    { lat: 49.3660052, lng: 20.3697568 },
    { lat: 49.3657799, lng: 20.3727062 },
    { lat: 49.3655447, lng: 20.3741562 },
    { lat: 49.3656569, lng: 20.3761023 },
    { lat: 49.3643173, lng: 20.3770814 },
    { lat: 49.3634986, lng: 20.3777872 },
    { lat: 49.3625876, lng: 20.3781372 },
    { lat: 49.3620211, lng: 20.3786398 },
    { lat: 49.3609119, lng: 20.3800805 },
    { lat: 49.3607161, lng: 20.380207 },
    { lat: 49.3598676, lng: 20.3810718 },
    { lat: 49.3588605, lng: 20.3813825 },
    { lat: 49.3584949, lng: 20.3815707 },
    { lat: 49.3580656, lng: 20.3816158 },
    { lat: 49.3574498, lng: 20.3818274 },
    { lat: 49.3568258, lng: 20.3821755 },
    { lat: 49.3564451, lng: 20.3824694 },
    { lat: 49.3554586, lng: 20.3834969 },
    { lat: 49.3554164, lng: 20.3835411 },
    { lat: 49.3558862, lng: 20.3841451 },
    { lat: 49.3560841, lng: 20.3845244 },
    { lat: 49.3561816, lng: 20.3848668 },
    { lat: 49.3562461, lng: 20.3853493 },
    { lat: 49.3563399, lng: 20.3856263 },
    { lat: 49.3564099, lng: 20.3862582 },
    { lat: 49.3564655, lng: 20.3864162 },
    { lat: 49.3563207, lng: 20.3868108 },
    { lat: 49.356275, lng: 20.3870912 },
    { lat: 49.3561947, lng: 20.3881229 },
    { lat: 49.3563778, lng: 20.389803 },
    { lat: 49.3563668, lng: 20.3905516 },
    { lat: 49.356528, lng: 20.3917168 },
    { lat: 49.3561588, lng: 20.3927273 },
    { lat: 49.3560739, lng: 20.393101 },
    { lat: 49.3559902, lng: 20.3940428 },
    { lat: 49.358185, lng: 20.3947411 },
    { lat: 49.3582397, lng: 20.394759 },
    { lat: 49.3583809, lng: 20.3944142 },
    { lat: 49.3586717, lng: 20.3939759 },
    { lat: 49.3587525, lng: 20.3940284 },
    { lat: 49.3595891, lng: 20.3933498 },
    { lat: 49.3601076, lng: 20.3928892 },
    { lat: 49.3602138, lng: 20.3926912 },
    { lat: 49.3604312, lng: 20.392488 },
    { lat: 49.3616091, lng: 20.3922877 },
    { lat: 49.3626958, lng: 20.3922345 },
    { lat: 49.3629737, lng: 20.3921105 },
    { lat: 49.3641386, lng: 20.3919514 },
    { lat: 49.3647418, lng: 20.391977 },
    { lat: 49.3647411, lng: 20.392076 },
    { lat: 49.364968, lng: 20.3920938 },
    { lat: 49.3670098, lng: 20.3919312 },
    { lat: 49.3676645, lng: 20.3917683 },
    { lat: 49.368128, lng: 20.3914067 },
    { lat: 49.3689891, lng: 20.3913995 },
    { lat: 49.3693474, lng: 20.3914663 },
    { lat: 49.3694508, lng: 20.3914015 },
    { lat: 49.3694681, lng: 20.391297 },
    { lat: 49.369736, lng: 20.3914134 },
    { lat: 49.3699533, lng: 20.3913829 },
    { lat: 49.3707416, lng: 20.3910368 },
    { lat: 49.3709892, lng: 20.3906471 },
    { lat: 49.3711684, lng: 20.3905322 },
    { lat: 49.3719829, lng: 20.3904861 },
    { lat: 49.3722018, lng: 20.3905299 },
    { lat: 49.3724535, lng: 20.3904523 },
    { lat: 49.3726228, lng: 20.3904954 },
    { lat: 49.3728337, lng: 20.39027 },
    { lat: 49.3730578, lng: 20.3901829 },
    { lat: 49.3732006, lng: 20.3897619 },
    { lat: 49.373376, lng: 20.3894898 },
    { lat: 49.3735311, lng: 20.3894486 },
    { lat: 49.373638, lng: 20.3892693 },
    { lat: 49.3737355, lng: 20.3893773 },
    { lat: 49.3737973, lng: 20.3891652 },
    { lat: 49.3737545, lng: 20.388958 },
    { lat: 49.3739544, lng: 20.3888842 },
    { lat: 49.3739585, lng: 20.3887007 },
    { lat: 49.3740745, lng: 20.3885184 },
    { lat: 49.3740299, lng: 20.3881762 },
    { lat: 49.3741388, lng: 20.3880502 },
    { lat: 49.3743981, lng: 20.3879997 },
    { lat: 49.3745193, lng: 20.387869 },
    { lat: 49.3745272, lng: 20.3876042 },
    { lat: 49.3746611, lng: 20.3874824 },
    { lat: 49.3747395, lng: 20.3873241 },
    { lat: 49.3749369, lng: 20.3872462 },
    { lat: 49.3750244, lng: 20.3870895 },
    { lat: 49.3751333, lng: 20.3871055 },
    { lat: 49.3752108, lng: 20.3870173 },
    { lat: 49.3752384, lng: 20.3869112 },
    { lat: 49.3751326, lng: 20.3868259 },
    { lat: 49.3751292, lng: 20.3866452 },
    { lat: 49.3752117, lng: 20.3864435 },
    { lat: 49.3755936, lng: 20.3861248 },
    { lat: 49.3756924, lng: 20.3857616 },
    { lat: 49.375875, lng: 20.385651 },
    { lat: 49.3758164, lng: 20.3854427 },
    { lat: 49.3758564, lng: 20.3853347 },
    { lat: 49.3759483, lng: 20.3853573 },
    { lat: 49.3760737, lng: 20.3852789 },
    { lat: 49.3761548, lng: 20.385103 },
    { lat: 49.3762447, lng: 20.3851279 },
    { lat: 49.3766405, lng: 20.3846992 },
    { lat: 49.3765571, lng: 20.384372 },
    { lat: 49.3764054, lng: 20.3841109 },
    { lat: 49.3764029, lng: 20.3837451 },
    { lat: 49.3766643, lng: 20.3834856 },
    { lat: 49.3770762, lng: 20.3836577 },
    { lat: 49.3776289, lng: 20.384105 },
    { lat: 49.3781878, lng: 20.3844401 },
    { lat: 49.3783069, lng: 20.3844649 },
    { lat: 49.3788763, lng: 20.3843394 },
    { lat: 49.3792003, lng: 20.3845389 },
    { lat: 49.3794899, lng: 20.3845193 },
    { lat: 49.3805766, lng: 20.3849111 },
    { lat: 49.3817587, lng: 20.3855622 },
    { lat: 49.3824495, lng: 20.3852283 },
    { lat: 49.3827244, lng: 20.3850813 },
    { lat: 49.3831637, lng: 20.3841677 },
    { lat: 49.3837659, lng: 20.3844632 },
    { lat: 49.3840022, lng: 20.3835091 },
    { lat: 49.3845186, lng: 20.3828745 },
    { lat: 49.384693, lng: 20.3830248 },
    { lat: 49.3848516, lng: 20.3833753 },
    { lat: 49.3851269, lng: 20.3837176 },
    { lat: 49.3855545, lng: 20.3840687 },
    { lat: 49.3857525, lng: 20.3840948 },
    { lat: 49.3858393, lng: 20.3842125 },
    { lat: 49.3861864, lng: 20.384315 },
    { lat: 49.3862655, lng: 20.3844091 },
    { lat: 49.3867956, lng: 20.3844967 },
    { lat: 49.3875989, lng: 20.3847461 },
    { lat: 49.3879022, lng: 20.3852038 },
    { lat: 49.3879857, lng: 20.3852401 },
  ],
  Jezersko: [
    { lat: 49.2991599, lng: 20.3216005 },
    { lat: 49.2973321, lng: 20.3189329 },
    { lat: 49.2952604, lng: 20.317706 },
    { lat: 49.2938846, lng: 20.3170072 },
    { lat: 49.2926271, lng: 20.3159628 },
    { lat: 49.2911884, lng: 20.3154126 },
    { lat: 49.2884753, lng: 20.3153627 },
    { lat: 49.2881102, lng: 20.315738 },
    { lat: 49.2868871, lng: 20.3152622 },
    { lat: 49.286091, lng: 20.315058 },
    { lat: 49.285382, lng: 20.316382 },
    { lat: 49.285362, lng: 20.316407 },
    { lat: 49.284391, lng: 20.318197 },
    { lat: 49.28418, lng: 20.318606 },
    { lat: 49.283894, lng: 20.319123 },
    { lat: 49.283766, lng: 20.319367 },
    { lat: 49.2833103, lng: 20.3208386 },
    { lat: 49.2828062, lng: 20.3220164 },
    { lat: 49.2826186, lng: 20.32264 },
    { lat: 49.2824619, lng: 20.3229859 },
    { lat: 49.2820665, lng: 20.3234171 },
    { lat: 49.281711, lng: 20.3236327 },
    { lat: 49.2815752, lng: 20.3238169 },
    { lat: 49.2809396, lng: 20.3254404 },
    { lat: 49.2808556, lng: 20.325919 },
    { lat: 49.280882, lng: 20.3259609 },
    { lat: 49.2805421, lng: 20.3269313 },
    { lat: 49.2801703, lng: 20.327329 },
    { lat: 49.2800281, lng: 20.3278756 },
    { lat: 49.2795103, lng: 20.328731 },
    { lat: 49.2790363, lng: 20.3284661 },
    { lat: 49.2786423, lng: 20.3281167 },
    { lat: 49.2781772, lng: 20.3278531 },
    { lat: 49.2779604, lng: 20.3283915 },
    { lat: 49.2778497, lng: 20.3288804 },
    { lat: 49.2775901, lng: 20.3312899 },
    { lat: 49.2768973, lng: 20.3348792 },
    { lat: 49.2768378, lng: 20.3356761 },
    { lat: 49.2767496, lng: 20.3359285 },
    { lat: 49.2764758, lng: 20.3372408 },
    { lat: 49.2764636, lng: 20.3375441 },
    { lat: 49.2761519, lng: 20.3395063 },
    { lat: 49.2760834, lng: 20.3403905 },
    { lat: 49.2759589, lng: 20.3410796 },
    { lat: 49.2757977, lng: 20.3415455 },
    { lat: 49.2757349, lng: 20.3418995 },
    { lat: 49.2757192, lng: 20.3424313 },
    { lat: 49.2769297, lng: 20.3436664 },
    { lat: 49.2784868, lng: 20.3449548 },
    { lat: 49.2787668, lng: 20.3453307 },
    { lat: 49.2798485, lng: 20.3460841 },
    { lat: 49.280382, lng: 20.3466948 },
    { lat: 49.2806503, lng: 20.3471154 },
    { lat: 49.280959, lng: 20.3474747 },
    { lat: 49.2810146, lng: 20.3478982 },
    { lat: 49.2813081, lng: 20.3483591 },
    { lat: 49.2822585, lng: 20.3492712 },
    { lat: 49.2826754, lng: 20.3495721 },
    { lat: 49.2832615, lng: 20.3498835 },
    { lat: 49.2843536, lng: 20.3510019 },
    { lat: 49.2848904, lng: 20.3512874 },
    { lat: 49.285395, lng: 20.3514406 },
    { lat: 49.2866412, lng: 20.3521994 },
    { lat: 49.2872354, lng: 20.3521956 },
    { lat: 49.2884125, lng: 20.3533855 },
    { lat: 49.2896671, lng: 20.3543636 },
    { lat: 49.2900933, lng: 20.3545282 },
    { lat: 49.2910103, lng: 20.3550677 },
    { lat: 49.2914014, lng: 20.3551712 },
    { lat: 49.2920679, lng: 20.3551964 },
    { lat: 49.2929705, lng: 20.3553963 },
    { lat: 49.2935411, lng: 20.3553319 },
    { lat: 49.2943662, lng: 20.3556167 },
    { lat: 49.2951153, lng: 20.3562773 },
    { lat: 49.2954217, lng: 20.3566333 },
    { lat: 49.2956579, lng: 20.3567457 },
    { lat: 49.2960796, lng: 20.3575023 },
    { lat: 49.2965439, lng: 20.3578901 },
    { lat: 49.2974922, lng: 20.3588428 },
    { lat: 49.2979364, lng: 20.3591256 },
    { lat: 49.2995287, lng: 20.3593657 },
    { lat: 49.300815, lng: 20.359293 },
    { lat: 49.3011868, lng: 20.3593301 },
    { lat: 49.3017647, lng: 20.3594881 },
    { lat: 49.3019858, lng: 20.3596115 },
    { lat: 49.3039742, lng: 20.3599707 },
    { lat: 49.3047003, lng: 20.3595291 },
    { lat: 49.305513, lng: 20.3593165 },
    { lat: 49.3061345, lng: 20.3593436 },
    { lat: 49.3068922, lng: 20.3563107 },
    { lat: 49.3072071, lng: 20.3554772 },
    { lat: 49.3074334, lng: 20.354652 },
    { lat: 49.3074869, lng: 20.3541377 },
    { lat: 49.3080085, lng: 20.3522331 },
    { lat: 49.3081796, lng: 20.3517018 },
    { lat: 49.3084805, lng: 20.3511261 },
    { lat: 49.309119, lng: 20.3493611 },
    { lat: 49.3090406, lng: 20.3491375 },
    { lat: 49.3076513, lng: 20.3464653 },
    { lat: 49.3072549, lng: 20.3450564 },
    { lat: 49.306772, lng: 20.3437947 },
    { lat: 49.3062895, lng: 20.3426949 },
    { lat: 49.3055293, lng: 20.3416294 },
    { lat: 49.3052294, lng: 20.3409715 },
    { lat: 49.3046854, lng: 20.3390897 },
    { lat: 49.3044037, lng: 20.3384998 },
    { lat: 49.3042588, lng: 20.3380092 },
    { lat: 49.3039778, lng: 20.3357044 },
    { lat: 49.3039284, lng: 20.3319572 },
    { lat: 49.3037788, lng: 20.3309691 },
    { lat: 49.3035052, lng: 20.3297951 },
    { lat: 49.3031767, lng: 20.3288675 },
    { lat: 49.3030211, lng: 20.3281033 },
    { lat: 49.3027035, lng: 20.3273677 },
    { lat: 49.3022001, lng: 20.3266647 },
    { lat: 49.3016274, lng: 20.3256206 },
    { lat: 49.2991599, lng: 20.3216005 },
  ],
  SpišskéHanušovce: [
    { lat: 49.3409515, lng: 20.3173645 },
    { lat: 49.3406717, lng: 20.3168817 },
    { lat: 49.3405654, lng: 20.3168169 },
    { lat: 49.3403356, lng: 20.3168431 },
    { lat: 49.3401409, lng: 20.3169885 },
    { lat: 49.3391919, lng: 20.3156527 },
    { lat: 49.3376144, lng: 20.3156204 },
    { lat: 49.336496, lng: 20.3159287 },
    { lat: 49.3355022, lng: 20.3160873 },
    { lat: 49.3345855, lng: 20.3158152 },
    { lat: 49.3337949, lng: 20.3157559 },
    { lat: 49.3331184, lng: 20.3157289 },
    { lat: 49.3323712, lng: 20.315887 },
    { lat: 49.3321135, lng: 20.3153912 },
    { lat: 49.3318755, lng: 20.3152928 },
    { lat: 49.3313171, lng: 20.3154107 },
    { lat: 49.3310454, lng: 20.314936 },
    { lat: 49.3299125, lng: 20.3155668 },
    { lat: 49.3295561, lng: 20.3153806 },
    { lat: 49.3289936, lng: 20.315422 },
    { lat: 49.326828, lng: 20.3163713 },
    { lat: 49.3250327, lng: 20.3176111 },
    { lat: 49.3236557, lng: 20.3176704 },
    { lat: 49.3232409, lng: 20.3174541 },
    { lat: 49.3227615, lng: 20.3166737 },
    { lat: 49.3222424, lng: 20.3163474 },
    { lat: 49.32218, lng: 20.3160152 },
    { lat: 49.3220411, lng: 20.3159476 },
    { lat: 49.3210786, lng: 20.3158912 },
    { lat: 49.3204479, lng: 20.3161524 },
    { lat: 49.3198385, lng: 20.3165203 },
    { lat: 49.3200868, lng: 20.3170061 },
    { lat: 49.3190147, lng: 20.3174937 },
    { lat: 49.3172091, lng: 20.3192872 },
    { lat: 49.3165031, lng: 20.3196645 },
    { lat: 49.315754, lng: 20.3203553 },
    { lat: 49.314552, lng: 20.3210682 },
    { lat: 49.313496, lng: 20.3211605 },
    { lat: 49.3118464, lng: 20.3218833 },
    { lat: 49.3106841, lng: 20.3228127 },
    { lat: 49.3103975, lng: 20.3229431 },
    { lat: 49.3097707, lng: 20.3230675 },
    { lat: 49.3089528, lng: 20.3233761 },
    { lat: 49.3078649, lng: 20.3239194 },
    { lat: 49.3070744, lng: 20.3240396 },
    { lat: 49.3057833, lng: 20.324515 },
    { lat: 49.3053836, lng: 20.3245712 },
    { lat: 49.3053736, lng: 20.3247813 },
    { lat: 49.3050881, lng: 20.3254619 },
    { lat: 49.3045159, lng: 20.3261026 },
    { lat: 49.3042351, lng: 20.3265021 },
    { lat: 49.3039379, lng: 20.3267345 },
    { lat: 49.3027164, lng: 20.3273528 },
    { lat: 49.3027035, lng: 20.3273677 },
    { lat: 49.3030211, lng: 20.3281033 },
    { lat: 49.3031767, lng: 20.3288675 },
    { lat: 49.3035052, lng: 20.3297951 },
    { lat: 49.3037788, lng: 20.3309691 },
    { lat: 49.3039284, lng: 20.3319572 },
    { lat: 49.3039778, lng: 20.3357044 },
    { lat: 49.3042588, lng: 20.3380092 },
    { lat: 49.3044037, lng: 20.3384998 },
    { lat: 49.3046854, lng: 20.3390897 },
    { lat: 49.3052294, lng: 20.3409715 },
    { lat: 49.3055293, lng: 20.3416294 },
    { lat: 49.3062895, lng: 20.3426949 },
    { lat: 49.306772, lng: 20.3437947 },
    { lat: 49.3072549, lng: 20.3450564 },
    { lat: 49.3076513, lng: 20.3464653 },
    { lat: 49.3090406, lng: 20.3491375 },
    { lat: 49.309119, lng: 20.3493611 },
    { lat: 49.3084805, lng: 20.3511261 },
    { lat: 49.3081796, lng: 20.3517018 },
    { lat: 49.3080085, lng: 20.3522331 },
    { lat: 49.3074869, lng: 20.3541377 },
    { lat: 49.3074334, lng: 20.354652 },
    { lat: 49.3072071, lng: 20.3554772 },
    { lat: 49.3068922, lng: 20.3563107 },
    { lat: 49.3061345, lng: 20.3593436 },
    { lat: 49.305513, lng: 20.3593165 },
    { lat: 49.3047003, lng: 20.3595291 },
    { lat: 49.3039742, lng: 20.3599707 },
    { lat: 49.3041029, lng: 20.3602742 },
    { lat: 49.3054326, lng: 20.3634007 },
    { lat: 49.3056232, lng: 20.3639376 },
    { lat: 49.3060383, lng: 20.3647253 },
    { lat: 49.3066231, lng: 20.3660095 },
    { lat: 49.3067854, lng: 20.3663053 },
    { lat: 49.3073145, lng: 20.3668982 },
    { lat: 49.3072137, lng: 20.3672615 },
    { lat: 49.3070947, lng: 20.3674507 },
    { lat: 49.3068905, lng: 20.3677627 },
    { lat: 49.3066599, lng: 20.3679156 },
    { lat: 49.3066882, lng: 20.3680694 },
    { lat: 49.3058701, lng: 20.3687279 },
    { lat: 49.3055398, lng: 20.3691589 },
    { lat: 49.3051046, lng: 20.3699118 },
    { lat: 49.3040332, lng: 20.3723474 },
    { lat: 49.3041473, lng: 20.3725003 },
    { lat: 49.3039854, lng: 20.3734513 },
    { lat: 49.3035806, lng: 20.3751517 },
    { lat: 49.3022103, lng: 20.3779885 },
    { lat: 49.301803, lng: 20.3784999 },
    { lat: 49.3014925, lng: 20.378709 },
    { lat: 49.3013343, lng: 20.3793764 },
    { lat: 49.3022631, lng: 20.381093 },
    { lat: 49.3027356, lng: 20.3817065 },
    { lat: 49.3054929, lng: 20.3839288 },
    { lat: 49.3069713, lng: 20.3858561 },
    { lat: 49.3072816, lng: 20.3862944 },
    { lat: 49.3076375, lng: 20.3859113 },
    { lat: 49.3080507, lng: 20.3851389 },
    { lat: 49.3084115, lng: 20.3839963 },
    { lat: 49.3087992, lng: 20.3835021 },
    { lat: 49.3094698, lng: 20.3828406 },
    { lat: 49.3103206, lng: 20.3818258 },
    { lat: 49.310972, lng: 20.3813103 },
    { lat: 49.3121318, lng: 20.3811382 },
    { lat: 49.3139541, lng: 20.3814481 },
    { lat: 49.3147333, lng: 20.3813041 },
    { lat: 49.3156266, lng: 20.3804563 },
    { lat: 49.3159439, lng: 20.3801907 },
    { lat: 49.3161386, lng: 20.3801232 },
    { lat: 49.3164496, lng: 20.37984 },
    { lat: 49.317247, lng: 20.378747 },
    { lat: 49.3179984, lng: 20.3780204 },
    { lat: 49.3185295, lng: 20.377923 },
    { lat: 49.3188546, lng: 20.3776804 },
    { lat: 49.3189219, lng: 20.3774965 },
    { lat: 49.3199371, lng: 20.3758607 },
    { lat: 49.3203096, lng: 20.3751169 },
    { lat: 49.3203692, lng: 20.3749084 },
    { lat: 49.320737, lng: 20.3745754 },
    { lat: 49.320919, lng: 20.3745029 },
    { lat: 49.3209545, lng: 20.3746358 },
    { lat: 49.3214233, lng: 20.3742959 },
    { lat: 49.3229775, lng: 20.3734814 },
    { lat: 49.3245924, lng: 20.3729321 },
    { lat: 49.3251064, lng: 20.3726495 },
    { lat: 49.3261241, lng: 20.3719116 },
    { lat: 49.3279666, lng: 20.3716564 },
    { lat: 49.3281826, lng: 20.3714797 },
    { lat: 49.3291434, lng: 20.3706909 },
    { lat: 49.32945, lng: 20.3699319 },
    { lat: 49.3295229, lng: 20.3694258 },
    { lat: 49.32993, lng: 20.3687902 },
    { lat: 49.3301239, lng: 20.368382 },
    { lat: 49.3301643, lng: 20.3671085 },
    { lat: 49.3302184, lng: 20.3667257 },
    { lat: 49.3303124, lng: 20.3666559 },
    { lat: 49.3303912, lng: 20.366302 },
    { lat: 49.3303962, lng: 20.3657225 },
    { lat: 49.3305605, lng: 20.365014 },
    { lat: 49.3305382, lng: 20.3648987 },
    { lat: 49.3311906, lng: 20.3632611 },
    { lat: 49.3313382, lng: 20.3630559 },
    { lat: 49.3315352, lng: 20.3625856 },
    { lat: 49.3322596, lng: 20.3591477 },
    { lat: 49.3331435, lng: 20.3572377 },
    { lat: 49.3340899, lng: 20.3555722 },
    { lat: 49.3350364, lng: 20.354412 },
    { lat: 49.3355552, lng: 20.3532882 },
    { lat: 49.3358689, lng: 20.3522814 },
    { lat: 49.3362034, lng: 20.351429 },
    { lat: 49.3363435, lng: 20.3507053 },
    { lat: 49.3363493, lng: 20.3502105 },
    { lat: 49.3362417, lng: 20.3495129 },
    { lat: 49.3364383, lng: 20.3487696 },
    { lat: 49.3366569, lng: 20.3483594 },
    { lat: 49.3367525, lng: 20.3480363 },
    { lat: 49.3364798, lng: 20.3478828 },
    { lat: 49.3364726, lng: 20.3469 },
    { lat: 49.3365279, lng: 20.3457567 },
    { lat: 49.3364411, lng: 20.3454612 },
    { lat: 49.3365452, lng: 20.3442421 },
    { lat: 49.3370465, lng: 20.3406651 },
    { lat: 49.3370901, lng: 20.3396318 },
    { lat: 49.3372532, lng: 20.3376896 },
    { lat: 49.3372461, lng: 20.3365952 },
    { lat: 49.337101, lng: 20.3350713 },
    { lat: 49.3370835, lng: 20.3336822 },
    { lat: 49.3371497, lng: 20.3327353 },
    { lat: 49.3371073, lng: 20.330722 },
    { lat: 49.3371792, lng: 20.3297815 },
    { lat: 49.3375368, lng: 20.3288976 },
    { lat: 49.3377819, lng: 20.3280675 },
    { lat: 49.3379652, lng: 20.327208 },
    { lat: 49.337922, lng: 20.326232 },
    { lat: 49.3377637, lng: 20.3255152 },
    { lat: 49.3377766, lng: 20.3248266 },
    { lat: 49.3381115, lng: 20.3240194 },
    { lat: 49.3397807, lng: 20.3213173 },
    { lat: 49.3399701, lng: 20.3208764 },
    { lat: 49.3402447, lng: 20.320237 },
    { lat: 49.3407521, lng: 20.3184184 },
    { lat: 49.3408661, lng: 20.3172873 },
    { lat: 49.3409515, lng: 20.3173645 },
  ],
  Matiašovce: [
    { lat: 49.3554164, lng: 20.3835411 },
    { lat: 49.3554586, lng: 20.3834969 },
    { lat: 49.3564451, lng: 20.3824694 },
    { lat: 49.3568258, lng: 20.3821755 },
    { lat: 49.3574498, lng: 20.3818274 },
    { lat: 49.3580656, lng: 20.3816158 },
    { lat: 49.3584949, lng: 20.3815707 },
    { lat: 49.3588605, lng: 20.3813825 },
    { lat: 49.3598676, lng: 20.3810718 },
    { lat: 49.3607161, lng: 20.380207 },
    { lat: 49.3609119, lng: 20.3800805 },
    { lat: 49.3620211, lng: 20.3786398 },
    { lat: 49.3625876, lng: 20.3781372 },
    { lat: 49.3634986, lng: 20.3777872 },
    { lat: 49.3643173, lng: 20.3770814 },
    { lat: 49.3656569, lng: 20.3761023 },
    { lat: 49.3655447, lng: 20.3741562 },
    { lat: 49.3657799, lng: 20.3727062 },
    { lat: 49.3660052, lng: 20.3697568 },
    { lat: 49.3660454, lng: 20.3697764 },
    { lat: 49.3660797, lng: 20.369377 },
    { lat: 49.3661306, lng: 20.3655968 },
    { lat: 49.3662371, lng: 20.3639913 },
    { lat: 49.3662121, lng: 20.3626234 },
    { lat: 49.3661541, lng: 20.3619025 },
    { lat: 49.3660687, lng: 20.3614614 },
    { lat: 49.3660846, lng: 20.3611739 },
    { lat: 49.3663134, lng: 20.3612662 },
    { lat: 49.3669217, lng: 20.3619863 },
    { lat: 49.3669984, lng: 20.3603961 },
    { lat: 49.3671659, lng: 20.3589458 },
    { lat: 49.3671983, lng: 20.3568152 },
    { lat: 49.3671486, lng: 20.3556 },
    { lat: 49.3673036, lng: 20.3517042 },
    { lat: 49.3671465, lng: 20.3508353 },
    { lat: 49.3670925, lng: 20.3500393 },
    { lat: 49.3672548, lng: 20.3471289 },
    { lat: 49.3672047, lng: 20.3450259 },
    { lat: 49.3672788, lng: 20.3440525 },
    { lat: 49.3673636, lng: 20.3412415 },
    { lat: 49.3672989, lng: 20.3394924 },
    { lat: 49.367651, lng: 20.3366939 },
    { lat: 49.368038, lng: 20.3344904 },
    { lat: 49.3679462, lng: 20.3345015 },
    { lat: 49.3678079, lng: 20.334187 },
    { lat: 49.3677174, lng: 20.334232 },
    { lat: 49.3675922, lng: 20.3341678 },
    { lat: 49.3674606, lng: 20.3339965 },
    { lat: 49.367416, lng: 20.3338907 },
    { lat: 49.367442, lng: 20.3338005 },
    { lat: 49.3673958, lng: 20.3337465 },
    { lat: 49.3673285, lng: 20.3338064 },
    { lat: 49.3671777, lng: 20.3336079 },
    { lat: 49.3669821, lng: 20.3336143 },
    { lat: 49.3667355, lng: 20.3333161 },
    { lat: 49.3667376, lng: 20.3331693 },
    { lat: 49.3666474, lng: 20.3330751 },
    { lat: 49.3664709, lng: 20.333142 },
    { lat: 49.3663212, lng: 20.3328798 },
    { lat: 49.3662034, lng: 20.3329218 },
    { lat: 49.3660287, lng: 20.3328574 },
    { lat: 49.3659999, lng: 20.3327534 },
    { lat: 49.3657133, lng: 20.3325888 },
    { lat: 49.3654709, lng: 20.3327559 },
    { lat: 49.3653985, lng: 20.3326894 },
    { lat: 49.3653904, lng: 20.3325948 },
    { lat: 49.3652848, lng: 20.3326603 },
    { lat: 49.3651992, lng: 20.3325376 },
    { lat: 49.3650878, lng: 20.3325443 },
    { lat: 49.3650024, lng: 20.332457 },
    { lat: 49.3645156, lng: 20.3323567 },
    { lat: 49.3644625, lng: 20.3322094 },
    { lat: 49.3643576, lng: 20.3321426 },
    { lat: 49.3643105, lng: 20.331942 },
    { lat: 49.3640562, lng: 20.3317413 },
    { lat: 49.3642075, lng: 20.3308781 },
    { lat: 49.3642891, lng: 20.3300955 },
    { lat: 49.3642337, lng: 20.3291822 },
    { lat: 49.3642586, lng: 20.3276018 },
    { lat: 49.3645946, lng: 20.3260929 },
    { lat: 49.3647017, lng: 20.3253491 },
    { lat: 49.3647057, lng: 20.3253258 },
    { lat: 49.363455, lng: 20.3249128 },
    { lat: 49.3623715, lng: 20.3251627 },
    { lat: 49.3613876, lng: 20.3252516 },
    { lat: 49.361224, lng: 20.325124 },
    { lat: 49.361168, lng: 20.32508 },
    { lat: 49.3607259, lng: 20.3247328 },
    { lat: 49.3601402, lng: 20.3245148 },
    { lat: 49.3591762, lng: 20.3239747 },
    { lat: 49.3589141, lng: 20.3241484 },
    { lat: 49.358909, lng: 20.324153 },
    { lat: 49.3589022, lng: 20.3241506 },
    { lat: 49.3581386, lng: 20.3240375 },
    { lat: 49.3577409, lng: 20.3237721 },
    { lat: 49.357726, lng: 20.323764 },
    { lat: 49.357119, lng: 20.323539 },
    { lat: 49.356504, lng: 20.323306 },
    { lat: 49.3558673, lng: 20.3230608 },
    { lat: 49.3557487, lng: 20.3233804 },
    { lat: 49.3550642, lng: 20.3234357 },
    { lat: 49.3543842, lng: 20.3232643 },
    { lat: 49.3537115, lng: 20.323049 },
    { lat: 49.3530411, lng: 20.3228806 },
    { lat: 49.3530198, lng: 20.3230796 },
    { lat: 49.3526133, lng: 20.3230341 },
    { lat: 49.3524607, lng: 20.3234706 },
    { lat: 49.3521993, lng: 20.3237194 },
    { lat: 49.3518116, lng: 20.3236537 },
    { lat: 49.3510916, lng: 20.323108 },
    { lat: 49.351081, lng: 20.323102 },
    { lat: 49.350381, lng: 20.32235 },
    { lat: 49.3495226, lng: 20.3214366 },
    { lat: 49.3488455, lng: 20.3213102 },
    { lat: 49.3483303, lng: 20.3210246 },
    { lat: 49.3476705, lng: 20.3203916 },
    { lat: 49.3469688, lng: 20.3195574 },
    { lat: 49.3467789, lng: 20.319033 },
    { lat: 49.3465217, lng: 20.3186499 },
    { lat: 49.3461087, lng: 20.3181565 },
    { lat: 49.346101, lng: 20.31815 },
    { lat: 49.3458459, lng: 20.3178586 },
    { lat: 49.3447484, lng: 20.3177273 },
    { lat: 49.3443582, lng: 20.3177405 },
    { lat: 49.3438374, lng: 20.3178658 },
    { lat: 49.3433273, lng: 20.3181592 },
    { lat: 49.3430441, lng: 20.318409 },
    { lat: 49.3426687, lng: 20.3185978 },
    { lat: 49.3423189, lng: 20.3184044 },
    { lat: 49.3419837, lng: 20.3184733 },
    { lat: 49.3416746, lng: 20.3186309 },
    { lat: 49.3412076, lng: 20.3176143 },
    { lat: 49.3409515, lng: 20.3173645 },
    { lat: 49.3408661, lng: 20.3172873 },
    { lat: 49.3407521, lng: 20.3184184 },
    { lat: 49.3402447, lng: 20.320237 },
    { lat: 49.3399701, lng: 20.3208764 },
    { lat: 49.3397807, lng: 20.3213173 },
    { lat: 49.3381115, lng: 20.3240194 },
    { lat: 49.3377766, lng: 20.3248266 },
    { lat: 49.3377637, lng: 20.3255152 },
    { lat: 49.337922, lng: 20.326232 },
    { lat: 49.3379652, lng: 20.327208 },
    { lat: 49.3377819, lng: 20.3280675 },
    { lat: 49.3375368, lng: 20.3288976 },
    { lat: 49.3371792, lng: 20.3297815 },
    { lat: 49.3371073, lng: 20.330722 },
    { lat: 49.3371497, lng: 20.3327353 },
    { lat: 49.3370835, lng: 20.3336822 },
    { lat: 49.337101, lng: 20.3350713 },
    { lat: 49.3372461, lng: 20.3365952 },
    { lat: 49.3372532, lng: 20.3376896 },
    { lat: 49.3370901, lng: 20.3396318 },
    { lat: 49.3370465, lng: 20.3406651 },
    { lat: 49.3365452, lng: 20.3442421 },
    { lat: 49.3364411, lng: 20.3454612 },
    { lat: 49.3365279, lng: 20.3457567 },
    { lat: 49.3364726, lng: 20.3469 },
    { lat: 49.3364798, lng: 20.3478828 },
    { lat: 49.3367525, lng: 20.3480363 },
    { lat: 49.3366569, lng: 20.3483594 },
    { lat: 49.3364383, lng: 20.3487696 },
    { lat: 49.3362417, lng: 20.3495129 },
    { lat: 49.3363493, lng: 20.3502105 },
    { lat: 49.3363435, lng: 20.3507053 },
    { lat: 49.3362034, lng: 20.351429 },
    { lat: 49.3358689, lng: 20.3522814 },
    { lat: 49.3355552, lng: 20.3532882 },
    { lat: 49.3350364, lng: 20.354412 },
    { lat: 49.3340899, lng: 20.3555722 },
    { lat: 49.3331435, lng: 20.3572377 },
    { lat: 49.3322596, lng: 20.3591477 },
    { lat: 49.3315352, lng: 20.3625856 },
    { lat: 49.3313382, lng: 20.3630559 },
    { lat: 49.3311906, lng: 20.3632611 },
    { lat: 49.3305382, lng: 20.3648987 },
    { lat: 49.3305605, lng: 20.365014 },
    { lat: 49.3303962, lng: 20.3657225 },
    { lat: 49.3303912, lng: 20.366302 },
    { lat: 49.3303124, lng: 20.3666559 },
    { lat: 49.3302184, lng: 20.3667257 },
    { lat: 49.3301643, lng: 20.3671085 },
    { lat: 49.3301239, lng: 20.368382 },
    { lat: 49.32993, lng: 20.3687902 },
    { lat: 49.3295229, lng: 20.3694258 },
    { lat: 49.32945, lng: 20.3699319 },
    { lat: 49.3291434, lng: 20.3706909 },
    { lat: 49.3281826, lng: 20.3714797 },
    { lat: 49.3282961, lng: 20.3717086 },
    { lat: 49.3285089, lng: 20.3719148 },
    { lat: 49.3292251, lng: 20.3746687 },
    { lat: 49.3300072, lng: 20.3754026 },
    { lat: 49.3306771, lng: 20.3783874 },
    { lat: 49.3304851, lng: 20.3785792 },
    { lat: 49.3309402, lng: 20.3800068 },
    { lat: 49.3312964, lng: 20.380553 },
    { lat: 49.3316041, lng: 20.3813306 },
    { lat: 49.3312841, lng: 20.381502 },
    { lat: 49.3312404, lng: 20.3816233 },
    { lat: 49.3311819, lng: 20.3823382 },
    { lat: 49.3313027, lng: 20.3824927 },
    { lat: 49.3312625, lng: 20.3825663 },
    { lat: 49.3313401, lng: 20.3833034 },
    { lat: 49.3313054, lng: 20.3834278 },
    { lat: 49.3314162, lng: 20.3837612 },
    { lat: 49.3313623, lng: 20.3838891 },
    { lat: 49.3314109, lng: 20.3842736 },
    { lat: 49.3314936, lng: 20.3844067 },
    { lat: 49.3315858, lng: 20.3843757 },
    { lat: 49.3316274, lng: 20.3846691 },
    { lat: 49.3316969, lng: 20.3846593 },
    { lat: 49.3317872, lng: 20.3853476 },
    { lat: 49.3316906, lng: 20.3855078 },
    { lat: 49.3316471, lng: 20.3857218 },
    { lat: 49.3316808, lng: 20.3867906 },
    { lat: 49.3316447, lng: 20.3870266 },
    { lat: 49.3317152, lng: 20.3876965 },
    { lat: 49.3317995, lng: 20.3879915 },
    { lat: 49.3321545, lng: 20.3882365 },
    { lat: 49.3323942, lng: 20.3888837 },
    { lat: 49.3327814, lng: 20.3892663 },
    { lat: 49.3337624, lng: 20.3910029 },
    { lat: 49.3338802, lng: 20.3910159 },
    { lat: 49.3338656, lng: 20.3913165 },
    { lat: 49.3335942, lng: 20.3923207 },
    { lat: 49.3334881, lng: 20.3930322 },
    { lat: 49.3341576, lng: 20.3950103 },
    { lat: 49.3345839, lng: 20.3958335 },
    { lat: 49.3352571, lng: 20.3955611 },
    { lat: 49.3364405, lng: 20.3947031 },
    { lat: 49.3374046, lng: 20.3937049 },
    { lat: 49.3373876, lng: 20.3936114 },
    { lat: 49.33754, lng: 20.393443 },
    { lat: 49.3381037, lng: 20.3932908 },
    { lat: 49.3383626, lng: 20.3933494 },
    { lat: 49.3389652, lng: 20.393175 },
    { lat: 49.3398374, lng: 20.3935554 },
    { lat: 49.3399681, lng: 20.3935561 },
    { lat: 49.3400118, lng: 20.3936392 },
    { lat: 49.3401616, lng: 20.3936133 },
    { lat: 49.3404401, lng: 20.3939211 },
    { lat: 49.3404761, lng: 20.3937995 },
    { lat: 49.3405442, lng: 20.3939032 },
    { lat: 49.3408573, lng: 20.3939678 },
    { lat: 49.3409287, lng: 20.3940398 },
    { lat: 49.3409768, lng: 20.3940031 },
    { lat: 49.341058, lng: 20.394097 },
    { lat: 49.3413458, lng: 20.3940519 },
    { lat: 49.3419277, lng: 20.3942852 },
    { lat: 49.34212, lng: 20.3945786 },
    { lat: 49.3422404, lng: 20.3944564 },
    { lat: 49.3423017, lng: 20.3945874 },
    { lat: 49.3426454, lng: 20.3946858 },
    { lat: 49.3428594, lng: 20.3948972 },
    { lat: 49.3429988, lng: 20.3948036 },
    { lat: 49.3432068, lng: 20.3952123 },
    { lat: 49.3432717, lng: 20.3951133 },
    { lat: 49.3436716, lng: 20.3951457 },
    { lat: 49.3440727, lng: 20.3957814 },
    { lat: 49.3442448, lng: 20.3958661 },
    { lat: 49.3444128, lng: 20.3957797 },
    { lat: 49.3445758, lng: 20.3958032 },
    { lat: 49.3446388, lng: 20.3959521 },
    { lat: 49.3447556, lng: 20.3958302 },
    { lat: 49.3448037, lng: 20.396052 },
    { lat: 49.3453113, lng: 20.3959022 },
    { lat: 49.3454016, lng: 20.3961185 },
    { lat: 49.3457019, lng: 20.3961973 },
    { lat: 49.345805, lng: 20.3965163 },
    { lat: 49.3460656, lng: 20.3963192 },
    { lat: 49.3462181, lng: 20.3965718 },
    { lat: 49.3463167, lng: 20.396498 },
    { lat: 49.3463324, lng: 20.3965873 },
    { lat: 49.3462482, lng: 20.3967431 },
    { lat: 49.3464691, lng: 20.3970149 },
    { lat: 49.346576, lng: 20.3968646 },
    { lat: 49.3468001, lng: 20.396865 },
    { lat: 49.3468741, lng: 20.3970068 },
    { lat: 49.3471259, lng: 20.396939 },
    { lat: 49.3471047, lng: 20.397059 },
    { lat: 49.3472775, lng: 20.3971917 },
    { lat: 49.3473365, lng: 20.3975346 },
    { lat: 49.3474844, lng: 20.3974286 },
    { lat: 49.3475292, lng: 20.3973282 },
    { lat: 49.3480365, lng: 20.3983286 },
    { lat: 49.348702, lng: 20.3974434 },
    { lat: 49.3493872, lng: 20.3962975 },
    { lat: 49.3501509, lng: 20.3942877 },
    { lat: 49.3503512, lng: 20.3924752 },
    { lat: 49.3507617, lng: 20.3919281 },
    { lat: 49.3513756, lng: 20.3894717 },
    { lat: 49.3521052, lng: 20.3877215 },
    { lat: 49.3522522, lng: 20.3872617 },
    { lat: 49.3524809, lng: 20.3869106 },
    { lat: 49.3531589, lng: 20.3862685 },
    { lat: 49.3550794, lng: 20.3837123 },
    { lat: 49.3554164, lng: 20.3835411 },
  ],
  Abrahámovce: [
    { lat: 49.0555723, lng: 20.4664621 },
    { lat: 49.0554635, lng: 20.4665053 },
    { lat: 49.0551515, lng: 20.4664717 },
    { lat: 49.0550761, lng: 20.4665292 },
    { lat: 49.0545028, lng: 20.466435 },
    { lat: 49.0539992, lng: 20.4664902 },
    { lat: 49.0538569, lng: 20.4663249 },
    { lat: 49.053248, lng: 20.4662449 },
    { lat: 49.0526317, lng: 20.4650898 },
    { lat: 49.0529956, lng: 20.464854 },
    { lat: 49.0529692, lng: 20.4643889 },
    { lat: 49.0537561, lng: 20.4640014 },
    { lat: 49.0535483, lng: 20.4632298 },
    { lat: 49.053479, lng: 20.4627017 },
    { lat: 49.0527389, lng: 20.4604478 },
    { lat: 49.052371, lng: 20.4590992 },
    { lat: 49.0521766, lng: 20.4579757 },
    { lat: 49.0517257, lng: 20.4561918 },
    { lat: 49.0508264, lng: 20.4556602 },
    { lat: 49.050851, lng: 20.4555668 },
    { lat: 49.0506241, lng: 20.4536314 },
    { lat: 49.0505193, lng: 20.4533017 },
    { lat: 49.0500965, lng: 20.4512196 },
    { lat: 49.0496723, lng: 20.4494909 },
    { lat: 49.0487586, lng: 20.4473233 },
    { lat: 49.0487689, lng: 20.4471784 },
    { lat: 49.0487012, lng: 20.4471466 },
    { lat: 49.0486791, lng: 20.4468535 },
    { lat: 49.0488539, lng: 20.4457579 },
    { lat: 49.048782, lng: 20.4457028 },
    { lat: 49.0488993, lng: 20.4452284 },
    { lat: 49.0488375, lng: 20.4442773 },
    { lat: 49.0488844, lng: 20.4438478 },
    { lat: 49.04881, lng: 20.4432197 },
    { lat: 49.0482963, lng: 20.4419293 },
    { lat: 49.0482084, lng: 20.4418088 },
    { lat: 49.0481561, lng: 20.4415855 },
    { lat: 49.047753, lng: 20.441106 },
    { lat: 49.0473557, lng: 20.4402125 },
    { lat: 49.0471046, lng: 20.4398227 },
    { lat: 49.0467734, lng: 20.4391366 },
    { lat: 49.0464907, lng: 20.4379413 },
    { lat: 49.0460909, lng: 20.4353432 },
    { lat: 49.0459572, lng: 20.4351719 },
    { lat: 49.0457687, lng: 20.4351902 },
    { lat: 49.0454436, lng: 20.4354339 },
    { lat: 49.0451097, lng: 20.4354441 },
    { lat: 49.0450211, lng: 20.4356912 },
    { lat: 49.0448368, lng: 20.4354963 },
    { lat: 49.0447278, lng: 20.4354968 },
    { lat: 49.0447395, lng: 20.4357826 },
    { lat: 49.0445662, lng: 20.4357848 },
    { lat: 49.0444877, lng: 20.4358491 },
    { lat: 49.0444284, lng: 20.436397 },
    { lat: 49.0442386, lng: 20.4365018 },
    { lat: 49.0442086, lng: 20.4366322 },
    { lat: 49.0440949, lng: 20.4367807 },
    { lat: 49.0439413, lng: 20.4368874 },
    { lat: 49.0439091, lng: 20.4369843 },
    { lat: 49.0437137, lng: 20.4371535 },
    { lat: 49.0436892, lng: 20.4372468 },
    { lat: 49.0435437, lng: 20.4373299 },
    { lat: 49.0432828, lng: 20.4371876 },
    { lat: 49.0426696, lng: 20.4372304 },
    { lat: 49.0422412, lng: 20.4371271 },
    { lat: 49.0422199, lng: 20.4380495 },
    { lat: 49.0424209, lng: 20.4382739 },
    { lat: 49.0413158, lng: 20.4389497 },
    { lat: 49.0414224, lng: 20.4378945 },
    { lat: 49.0411437, lng: 20.4377911 },
    { lat: 49.0414792, lng: 20.4340926 },
    { lat: 49.0416052, lng: 20.4332888 },
    { lat: 49.0417656, lng: 20.4317204 },
    { lat: 49.0426989, lng: 20.4263271 },
    { lat: 49.0436627, lng: 20.4217793 },
    { lat: 49.0445321, lng: 20.4173886 },
    { lat: 49.03795, lng: 20.415813 },
    { lat: 49.037723, lng: 20.415814 },
    { lat: 49.037664, lng: 20.415814 },
    { lat: 49.037663, lng: 20.415841 },
    { lat: 49.03764, lng: 20.416112 },
    { lat: 49.037174, lng: 20.41605 },
    { lat: 49.03678, lng: 20.415976 },
    { lat: 49.036763, lng: 20.415973 },
    { lat: 49.036749, lng: 20.416157 },
    { lat: 49.036673, lng: 20.417091 },
    { lat: 49.036663, lng: 20.417244 },
    { lat: 49.036632, lng: 20.418494 },
    { lat: 49.036583, lng: 20.42042 },
    { lat: 49.036454, lng: 20.421419 },
    { lat: 49.036415, lng: 20.421704 },
    { lat: 49.036403, lng: 20.421758 },
    { lat: 49.036381, lng: 20.421871 },
    { lat: 49.036356, lng: 20.421988 },
    { lat: 49.036291, lng: 20.422424 },
    { lat: 49.036254, lng: 20.422649 },
    { lat: 49.036215, lng: 20.422917 },
    { lat: 49.036177, lng: 20.423147 },
    { lat: 49.036167, lng: 20.42324 },
    { lat: 49.035933, lng: 20.424287 },
    { lat: 49.035766, lng: 20.425156 },
    { lat: 49.03569, lng: 20.425535 },
    { lat: 49.035599, lng: 20.425988 },
    { lat: 49.035511, lng: 20.426362 },
    { lat: 49.03548, lng: 20.426508 },
    { lat: 49.035455, lng: 20.426647 },
    { lat: 49.035456, lng: 20.426893 },
    { lat: 49.035457, lng: 20.427078 },
    { lat: 49.03544, lng: 20.428061 },
    { lat: 49.035434, lng: 20.428159 },
    { lat: 49.035425, lng: 20.428242 },
    { lat: 49.035415, lng: 20.42835 },
    { lat: 49.035408, lng: 20.428423 },
    { lat: 49.035381, lng: 20.428496 },
    { lat: 49.03535, lng: 20.428561 },
    { lat: 49.035319, lng: 20.428612 },
    { lat: 49.035315, lng: 20.428618 },
    { lat: 49.035286, lng: 20.428645 },
    { lat: 49.035249, lng: 20.428672 },
    { lat: 49.035221, lng: 20.428679 },
    { lat: 49.035195, lng: 20.428685 },
    { lat: 49.035192, lng: 20.428718 },
    { lat: 49.035195, lng: 20.429103 },
    { lat: 49.035184, lng: 20.429215 },
    { lat: 49.03514, lng: 20.429371 },
    { lat: 49.035138, lng: 20.429389 },
    { lat: 49.035133, lng: 20.429454 },
    { lat: 49.035115, lng: 20.429588 },
    { lat: 49.035057, lng: 20.429741 },
    { lat: 49.035093, lng: 20.429988 },
    { lat: 49.035103, lng: 20.430065 },
    { lat: 49.035194, lng: 20.43053 },
    { lat: 49.0351081, lng: 20.4308553 },
    { lat: 49.035164, lng: 20.431522 },
    { lat: 49.035167, lng: 20.431569 },
    { lat: 49.03518, lng: 20.431709 },
    { lat: 49.035198, lng: 20.431868 },
    { lat: 49.035259, lng: 20.432165 },
    { lat: 49.035283, lng: 20.43225 },
    { lat: 49.035353, lng: 20.432448 },
    { lat: 49.035423, lng: 20.432632 },
    { lat: 49.035489, lng: 20.432783 },
    { lat: 49.035674, lng: 20.433101 },
    { lat: 49.035861, lng: 20.433433 },
    { lat: 49.03592, lng: 20.433558 },
    { lat: 49.035938, lng: 20.433595 },
    { lat: 49.036027, lng: 20.433848 },
    { lat: 49.036154, lng: 20.43423 },
    { lat: 49.036166, lng: 20.434266 },
    { lat: 49.035836, lng: 20.434358 },
    { lat: 49.035699, lng: 20.434401 },
    { lat: 49.035764, lng: 20.434703 },
    { lat: 49.035831, lng: 20.435004 },
    { lat: 49.035855, lng: 20.435146 },
    { lat: 49.03588, lng: 20.435321 },
    { lat: 49.035891, lng: 20.435444 },
    { lat: 49.035905, lng: 20.43566 },
    { lat: 49.035916, lng: 20.435844 },
    { lat: 49.03591, lng: 20.436048 },
    { lat: 49.035902, lng: 20.436156 },
    { lat: 49.035903, lng: 20.436171 },
    { lat: 49.035911, lng: 20.436248 },
    { lat: 49.035906, lng: 20.436304 },
    { lat: 49.035901, lng: 20.436333 },
    { lat: 49.036597, lng: 20.436757 },
    { lat: 49.036465, lng: 20.437571 },
    { lat: 49.036514, lng: 20.438754 },
    { lat: 49.03654, lng: 20.439385 },
    { lat: 49.036527, lng: 20.440051 },
    { lat: 49.036486, lng: 20.441138 },
    { lat: 49.036458, lng: 20.441847 },
    { lat: 49.036471, lng: 20.442463 },
    { lat: 49.037047, lng: 20.442561 },
    { lat: 49.037529, lng: 20.442682 },
    { lat: 49.03737, lng: 20.443045 },
    { lat: 49.037159, lng: 20.443462 },
    { lat: 49.036762, lng: 20.444247 },
    { lat: 49.036694, lng: 20.444379 },
    { lat: 49.035228, lng: 20.444438 },
    { lat: 49.03477, lng: 20.444519 },
    { lat: 49.034702, lng: 20.44458 },
    { lat: 49.034692, lng: 20.444556 },
    { lat: 49.03426, lng: 20.444997 },
    { lat: 49.033921, lng: 20.445435 },
    { lat: 49.033536, lng: 20.445969 },
    { lat: 49.033548, lng: 20.445996 },
    { lat: 49.033705, lng: 20.447017 },
    { lat: 49.033786, lng: 20.447531 },
    { lat: 49.033853, lng: 20.447997 },
    { lat: 49.033887, lng: 20.448248 },
    { lat: 49.033894, lng: 20.448311 },
    { lat: 49.033913, lng: 20.448442 },
    { lat: 49.033933, lng: 20.448587 },
    { lat: 49.034013, lng: 20.449608 },
    { lat: 49.034024, lng: 20.449763 },
    { lat: 49.03403, lng: 20.450172 },
    { lat: 49.034078, lng: 20.451322 },
    { lat: 49.033863, lng: 20.45137 },
    { lat: 49.033849, lng: 20.451369 },
    { lat: 49.033635, lng: 20.45138 },
    { lat: 49.033628, lng: 20.451555 },
    { lat: 49.033559, lng: 20.451562 },
    { lat: 49.033392, lng: 20.451583 },
    { lat: 49.032859, lng: 20.451649 },
    { lat: 49.032525, lng: 20.451691 },
    { lat: 49.032422, lng: 20.451724 },
    { lat: 49.032002, lng: 20.451877 },
    { lat: 49.032062, lng: 20.45291 },
    { lat: 49.032156, lng: 20.45369 },
    { lat: 49.032132, lng: 20.454619 },
    { lat: 49.032129, lng: 20.455322 },
    { lat: 49.0321, lng: 20.455585 },
    { lat: 49.031988, lng: 20.456123 },
    { lat: 49.031746, lng: 20.456574 },
    { lat: 49.031905, lng: 20.456639 },
    { lat: 49.031946, lng: 20.456746 },
    { lat: 49.032011, lng: 20.456916 },
    { lat: 49.032025, lng: 20.456946 },
    { lat: 49.032093, lng: 20.457012 },
    { lat: 49.032168, lng: 20.457004 },
    { lat: 49.032174, lng: 20.45726 },
    { lat: 49.032146, lng: 20.457414 },
    { lat: 49.032078, lng: 20.457799 },
    { lat: 49.032077, lng: 20.457973 },
    { lat: 49.032076, lng: 20.458068 },
    { lat: 49.031974, lng: 20.458504 },
    { lat: 49.031814, lng: 20.458943 },
    { lat: 49.031868, lng: 20.459028 },
    { lat: 49.032113, lng: 20.459416 },
    { lat: 49.032121, lng: 20.459429 },
    { lat: 49.032127, lng: 20.459438 },
    { lat: 49.032138, lng: 20.459452 },
    { lat: 49.032145, lng: 20.459461 },
    { lat: 49.03215, lng: 20.459473 },
    { lat: 49.032167, lng: 20.459517 },
    { lat: 49.032483, lng: 20.460313 },
    { lat: 49.032555, lng: 20.460493 },
    { lat: 49.032631, lng: 20.46069 },
    { lat: 49.032901, lng: 20.461389 },
    { lat: 49.033027, lng: 20.461744 },
    { lat: 49.033245, lng: 20.462356 },
    { lat: 49.033251, lng: 20.462372 },
    { lat: 49.03342, lng: 20.462827 },
    { lat: 49.033474, lng: 20.462971 },
    { lat: 49.033533, lng: 20.463134 },
    { lat: 49.033685, lng: 20.463536 },
    { lat: 49.033698, lng: 20.463573 },
    { lat: 49.033703, lng: 20.463587 },
    { lat: 49.033756, lng: 20.463751 },
    { lat: 49.033892, lng: 20.464166 },
    { lat: 49.033904, lng: 20.464178 },
    { lat: 49.033912, lng: 20.464185 },
    { lat: 49.033931, lng: 20.464204 },
    { lat: 49.03397, lng: 20.46424 },
    { lat: 49.033945, lng: 20.464334 },
    { lat: 49.03405, lng: 20.464761 },
    { lat: 49.034067, lng: 20.464833 },
    { lat: 49.034091, lng: 20.464931 },
    { lat: 49.034228, lng: 20.465482 },
    { lat: 49.034229, lng: 20.465488 },
    { lat: 49.03434, lng: 20.465853 },
    { lat: 49.034446, lng: 20.466204 },
    { lat: 49.03465, lng: 20.466805 },
    { lat: 49.034873, lng: 20.467463 },
    { lat: 49.035084, lng: 20.467953 },
    { lat: 49.03552, lng: 20.469047 },
    { lat: 49.035559, lng: 20.469106 },
    { lat: 49.035695, lng: 20.469309 },
    { lat: 49.035894, lng: 20.469643 },
    { lat: 49.035913, lng: 20.469674 },
    { lat: 49.035932, lng: 20.46976 },
    { lat: 49.036044, lng: 20.470263 },
    { lat: 49.036148, lng: 20.470617 },
    { lat: 49.036331, lng: 20.471135 },
    { lat: 49.036359, lng: 20.471208 },
    { lat: 49.036389, lng: 20.471286 },
    { lat: 49.036452, lng: 20.471479 },
    { lat: 49.036462, lng: 20.471508 },
    { lat: 49.036471, lng: 20.471535 },
    { lat: 49.036531, lng: 20.471713 },
    { lat: 49.036706, lng: 20.472238 },
    { lat: 49.036822, lng: 20.4727 },
    { lat: 49.036919, lng: 20.473079 },
    { lat: 49.036968, lng: 20.473271 },
    { lat: 49.037043, lng: 20.473557 },
    { lat: 49.037053, lng: 20.47359 },
    { lat: 49.037155, lng: 20.473981 },
    { lat: 49.037206, lng: 20.474163 },
    { lat: 49.037286, lng: 20.474455 },
    { lat: 49.037383, lng: 20.474805 },
    { lat: 49.037437, lng: 20.475086 },
    { lat: 49.037454, lng: 20.475171 },
    { lat: 49.03746, lng: 20.475201 },
    { lat: 49.03806, lng: 20.475013 },
    { lat: 49.038335, lng: 20.474958 },
    { lat: 49.03862, lng: 20.474885 },
    { lat: 49.038806, lng: 20.474816 },
    { lat: 49.039059, lng: 20.474793 },
    { lat: 49.039129, lng: 20.474776 },
    { lat: 49.039265, lng: 20.47476 },
    { lat: 49.039461, lng: 20.474697 },
    { lat: 49.039909, lng: 20.474511 },
    { lat: 49.039912, lng: 20.47451 },
    { lat: 49.040154, lng: 20.474442 },
    { lat: 49.040461, lng: 20.474322 },
    { lat: 49.040483, lng: 20.474313 },
    { lat: 49.040728, lng: 20.474274 },
    { lat: 49.041217, lng: 20.474162 },
    { lat: 49.041214, lng: 20.474136 },
    { lat: 49.043097, lng: 20.473432 },
    { lat: 49.043409, lng: 20.473314 },
    { lat: 49.043526, lng: 20.473269 },
    { lat: 49.04399, lng: 20.473104 },
    { lat: 49.044279, lng: 20.473083 },
    { lat: 49.044985, lng: 20.472895 },
    { lat: 49.045174, lng: 20.472769 },
    { lat: 49.045179, lng: 20.472765 },
    { lat: 49.045251, lng: 20.472922 },
    { lat: 49.045359, lng: 20.473284 },
    { lat: 49.045433, lng: 20.473548 },
    { lat: 49.045536, lng: 20.473828 },
    { lat: 49.045601, lng: 20.474007 },
    { lat: 49.045647, lng: 20.474086 },
    { lat: 49.045688, lng: 20.474158 },
    { lat: 49.045772, lng: 20.474305 },
    { lat: 49.045983, lng: 20.474604 },
    { lat: 49.046268, lng: 20.474882 },
    { lat: 49.046325, lng: 20.474929 },
    { lat: 49.046346, lng: 20.474945 },
    { lat: 49.046685, lng: 20.475195 },
    { lat: 49.046802, lng: 20.475266 },
    { lat: 49.046844, lng: 20.475291 },
    { lat: 49.04692, lng: 20.475333 },
    { lat: 49.047018, lng: 20.475387 },
    { lat: 49.047026, lng: 20.47539 },
    { lat: 49.047193, lng: 20.475464 },
    { lat: 49.047201, lng: 20.475467 },
    { lat: 49.047235, lng: 20.475479 },
    { lat: 49.04762, lng: 20.47569 },
    { lat: 49.047821, lng: 20.475799 },
    { lat: 49.048034, lng: 20.475965 },
    { lat: 49.048255, lng: 20.476134 },
    { lat: 49.048349, lng: 20.476233 },
    { lat: 49.048389, lng: 20.476283 },
    { lat: 49.048396, lng: 20.476291 },
    { lat: 49.048443, lng: 20.476359 },
    { lat: 49.048521, lng: 20.476472 },
    { lat: 49.048567, lng: 20.476538 },
    { lat: 49.048616, lng: 20.476643 },
    { lat: 49.048904, lng: 20.477173 },
    { lat: 49.049258, lng: 20.477829 },
    { lat: 49.049261, lng: 20.477834 },
    { lat: 49.049398, lng: 20.478058 },
    { lat: 49.049446, lng: 20.478118 },
    { lat: 49.049796, lng: 20.478613 },
    { lat: 49.05006, lng: 20.478862 },
    { lat: 49.050259, lng: 20.479084 },
    { lat: 49.05028, lng: 20.479097 },
    { lat: 49.050492, lng: 20.479544 },
    { lat: 49.050502, lng: 20.479561 },
    { lat: 49.050517, lng: 20.479622 },
    { lat: 49.050582, lng: 20.479872 },
    { lat: 49.050585, lng: 20.479899 },
    { lat: 49.050648, lng: 20.480391 },
    { lat: 49.050768, lng: 20.480752 },
    { lat: 49.050773, lng: 20.480767 },
    { lat: 49.051117, lng: 20.481767 },
    { lat: 49.051151, lng: 20.481909 },
    { lat: 49.051168, lng: 20.481968 },
    { lat: 49.051266, lng: 20.482321 },
    { lat: 49.051293, lng: 20.482403 },
    { lat: 49.051601, lng: 20.48256 },
    { lat: 49.051865, lng: 20.482695 },
    { lat: 49.052156, lng: 20.482901 },
    { lat: 49.052497, lng: 20.483306 },
    { lat: 49.052524, lng: 20.483338 },
    { lat: 49.052749, lng: 20.483688 },
    { lat: 49.052843, lng: 20.483909 },
    { lat: 49.052994, lng: 20.484472 },
    { lat: 49.0534398, lng: 20.4837992 },
    { lat: 49.0544497, lng: 20.4827976 },
    { lat: 49.0547897, lng: 20.4823489 },
    { lat: 49.0556012, lng: 20.4810129 },
    { lat: 49.0557256, lng: 20.4806059 },
    { lat: 49.0556621, lng: 20.4803525 },
    { lat: 49.0556099, lng: 20.4794111 },
    { lat: 49.0549382, lng: 20.4774038 },
    { lat: 49.0545099, lng: 20.4749543 },
    { lat: 49.0544859, lng: 20.4737129 },
    { lat: 49.0543069, lng: 20.4727197 },
    { lat: 49.0544517, lng: 20.4718923 },
    { lat: 49.054865, lng: 20.4709069 },
    { lat: 49.05507, lng: 20.4702466 },
    { lat: 49.0552539, lng: 20.4700461 },
    { lat: 49.0554869, lng: 20.4689619 },
    { lat: 49.0555806, lng: 20.4680994 },
    { lat: 49.0553611, lng: 20.4674041 },
    { lat: 49.0553073, lng: 20.4669636 },
    { lat: 49.0553264, lng: 20.4668679 },
    { lat: 49.0555241, lng: 20.4665912 },
    { lat: 49.0555723, lng: 20.4664621 },
  ],
  MaláFranková: [
    { lat: 49.2991599, lng: 20.3216005 },
    { lat: 49.3014666, lng: 20.3206259 },
    { lat: 49.3046415, lng: 20.3191628 },
    { lat: 49.3061836, lng: 20.3179824 },
    { lat: 49.3069858, lng: 20.3179414 },
    { lat: 49.3087541, lng: 20.3170818 },
    { lat: 49.3097855, lng: 20.3170256 },
    { lat: 49.3116186, lng: 20.3164163 },
    { lat: 49.3126353, lng: 20.3159157 },
    { lat: 49.3138448, lng: 20.3152226 },
    { lat: 49.3159404, lng: 20.3138894 },
    { lat: 49.3168932, lng: 20.3129956 },
    { lat: 49.3193033, lng: 20.3096297 },
    { lat: 49.3203702, lng: 20.3110159 },
    { lat: 49.3208498, lng: 20.3105196 },
    { lat: 49.3211046, lng: 20.3099988 },
    { lat: 49.3219627, lng: 20.3089568 },
    { lat: 49.3220252, lng: 20.3086495 },
    { lat: 49.322526, lng: 20.3077733 },
    { lat: 49.3231808, lng: 20.306476 },
    { lat: 49.3235643, lng: 20.3055014 },
    { lat: 49.3235816, lng: 20.3051689 },
    { lat: 49.3232049, lng: 20.3051235 },
    { lat: 49.3232075, lng: 20.3044317 },
    { lat: 49.3230616, lng: 20.3024901 },
    { lat: 49.3232672, lng: 20.3007367 },
    { lat: 49.3224791, lng: 20.3001804 },
    { lat: 49.3224138, lng: 20.2978864 },
    { lat: 49.3225339, lng: 20.2947674 },
    { lat: 49.3219213, lng: 20.2911078 },
    { lat: 49.3212654, lng: 20.2909454 },
    { lat: 49.3201297, lng: 20.2905205 },
    { lat: 49.3195177, lng: 20.2900429 },
    { lat: 49.3190735, lng: 20.2898248 },
    { lat: 49.3164675, lng: 20.2870212 },
    { lat: 49.3154555, lng: 20.286243 },
    { lat: 49.3142501, lng: 20.285594 },
    { lat: 49.3126244, lng: 20.286174 },
    { lat: 49.310994, lng: 20.2841133 },
    { lat: 49.3100801, lng: 20.2828248 },
    { lat: 49.3096727, lng: 20.2815523 },
    { lat: 49.3084462, lng: 20.2824732 },
    { lat: 49.3076762, lng: 20.2816068 },
    { lat: 49.3056064, lng: 20.2799776 },
    { lat: 49.2987122, lng: 20.276184 },
    { lat: 49.2983038, lng: 20.276083 },
    { lat: 49.2980812, lng: 20.2754503 },
    { lat: 49.2964595, lng: 20.2735333 },
    { lat: 49.2959579, lng: 20.2722521 },
    { lat: 49.29525, lng: 20.2708159 },
    { lat: 49.2948363, lng: 20.2695596 },
    { lat: 49.2943373, lng: 20.2685142 },
    { lat: 49.2926248, lng: 20.2676559 },
    { lat: 49.291561, lng: 20.267182 },
    { lat: 49.291152, lng: 20.267906 },
    { lat: 49.290455, lng: 20.269074 },
    { lat: 49.289946, lng: 20.269519 },
    { lat: 49.289824, lng: 20.270654 },
    { lat: 49.289704, lng: 20.271552 },
    { lat: 49.289471, lng: 20.271964 },
    { lat: 49.289093, lng: 20.272434 },
    { lat: 49.289108, lng: 20.273866 },
    { lat: 49.288872, lng: 20.274531 },
    { lat: 49.288671, lng: 20.275043 },
    { lat: 49.288642, lng: 20.275626 },
    { lat: 49.288349, lng: 20.276536 },
    { lat: 49.288103, lng: 20.277551 },
    { lat: 49.287503, lng: 20.279259 },
    { lat: 49.287102, lng: 20.280944 },
    { lat: 49.286565, lng: 20.282733 },
    { lat: 49.286212, lng: 20.283945 },
    { lat: 49.286049, lng: 20.284547 },
    { lat: 49.285946, lng: 20.284927 },
    { lat: 49.286051, lng: 20.285607 },
    { lat: 49.286362, lng: 20.286506 },
    { lat: 49.286792, lng: 20.287702 },
    { lat: 49.286908, lng: 20.288267 },
    { lat: 49.286849, lng: 20.289081 },
    { lat: 49.286747, lng: 20.291117 },
    { lat: 49.286766, lng: 20.292997 },
    { lat: 49.286815, lng: 20.294323 },
    { lat: 49.287002, lng: 20.295219 },
    { lat: 49.286973, lng: 20.296053 },
    { lat: 49.287128, lng: 20.296762 },
    { lat: 49.287083, lng: 20.297665 },
    { lat: 49.286808, lng: 20.299215 },
    { lat: 49.286709, lng: 20.299321 },
    { lat: 49.285725, lng: 20.300844 },
    { lat: 49.285061, lng: 20.302095 },
    { lat: 49.284927, lng: 20.302974 },
    { lat: 49.284923, lng: 20.303557 },
    { lat: 49.284751, lng: 20.304673 },
    { lat: 49.284652, lng: 20.305928 },
    { lat: 49.284684, lng: 20.307396 },
    { lat: 49.28466, lng: 20.309355 },
    { lat: 49.284787, lng: 20.310775 },
    { lat: 49.284923, lng: 20.311931 },
    { lat: 49.285037, lng: 20.312447 },
    { lat: 49.285172, lng: 20.31313 },
    { lat: 49.28542, lng: 20.313612 },
    { lat: 49.285848, lng: 20.314746 },
    { lat: 49.286091, lng: 20.315058 },
    { lat: 49.2868871, lng: 20.3152622 },
    { lat: 49.2881102, lng: 20.315738 },
    { lat: 49.2884753, lng: 20.3153627 },
    { lat: 49.2911884, lng: 20.3154126 },
    { lat: 49.2926271, lng: 20.3159628 },
    { lat: 49.2938846, lng: 20.3170072 },
    { lat: 49.2952604, lng: 20.317706 },
    { lat: 49.2973321, lng: 20.3189329 },
    { lat: 49.2991599, lng: 20.3216005 },
  ],
  SpišskáBelá: [
    { lat: 49.1720808, lng: 20.4209548 },
    { lat: 49.1719365, lng: 20.4212187 },
    { lat: 49.1706772, lng: 20.4233436 },
    { lat: 49.1696008, lng: 20.4248037 },
    { lat: 49.1689874, lng: 20.4257966 },
    { lat: 49.1684429, lng: 20.4260715 },
    { lat: 49.1676997, lng: 20.4280962 },
    { lat: 49.1675635, lng: 20.4281006 },
    { lat: 49.1672469, lng: 20.428871 },
    { lat: 49.1666552, lng: 20.4299896 },
    { lat: 49.1690031, lng: 20.4307458 },
    { lat: 49.1688114, lng: 20.4316074 },
    { lat: 49.1708427, lng: 20.4324432 },
    { lat: 49.1708068, lng: 20.4325374 },
    { lat: 49.1713612, lng: 20.4328486 },
    { lat: 49.1710023, lng: 20.4334867 },
    { lat: 49.1705624, lng: 20.4348979 },
    { lat: 49.169832, lng: 20.4364584 },
    { lat: 49.1696603, lng: 20.4383581 },
    { lat: 49.1688739, lng: 20.4405566 },
    { lat: 49.1687506, lng: 20.4410477 },
    { lat: 49.1687391, lng: 20.4413455 },
    { lat: 49.1688567, lng: 20.4427327 },
    { lat: 49.1688358, lng: 20.4431073 },
    { lat: 49.1676976, lng: 20.4464294 },
    { lat: 49.1673409, lng: 20.4482084 },
    { lat: 49.1672701, lng: 20.4482377 },
    { lat: 49.1672549, lng: 20.4484045 },
    { lat: 49.1671648, lng: 20.4486146 },
    { lat: 49.1670694, lng: 20.4486477 },
    { lat: 49.1670228, lng: 20.4487899 },
    { lat: 49.166883, lng: 20.448931 },
    { lat: 49.1666362, lng: 20.4498865 },
    { lat: 49.1665458, lng: 20.4500865 },
    { lat: 49.1663695, lng: 20.4502206 },
    { lat: 49.166409, lng: 20.4505205 },
    { lat: 49.165821, lng: 20.4520775 },
    { lat: 49.1657431, lng: 20.4523951 },
    { lat: 49.1657221, lng: 20.4527675 },
    { lat: 49.166817, lng: 20.4530253 },
    { lat: 49.1671944, lng: 20.4530272 },
    { lat: 49.1683329, lng: 20.4533985 },
    { lat: 49.1683414, lng: 20.4540468 },
    { lat: 49.1684342, lng: 20.4543557 },
    { lat: 49.1684823, lng: 20.4548908 },
    { lat: 49.1684746, lng: 20.45513 },
    { lat: 49.1680976, lng: 20.4555907 },
    { lat: 49.1682485, lng: 20.4560992 },
    { lat: 49.1687848, lng: 20.4572207 },
    { lat: 49.1692725, lng: 20.4587015 },
    { lat: 49.1695491, lng: 20.4592891 },
    { lat: 49.1697644, lng: 20.4598957 },
    { lat: 49.1707411, lng: 20.4591665 },
    { lat: 49.1709376, lng: 20.4592095 },
    { lat: 49.171019, lng: 20.4602681 },
    { lat: 49.1712631, lng: 20.4611342 },
    { lat: 49.1715433, lng: 20.4618265 },
    { lat: 49.1716403, lng: 20.46213 },
    { lat: 49.1717486, lng: 20.4627089 },
    { lat: 49.1718098, lng: 20.4637711 },
    { lat: 49.1717337, lng: 20.4643847 },
    { lat: 49.1714956, lng: 20.4650322 },
    { lat: 49.171436, lng: 20.465457 },
    { lat: 49.1718208, lng: 20.4680699 },
    { lat: 49.1718445, lng: 20.4681625 },
    { lat: 49.172137, lng: 20.4690571 },
    { lat: 49.1723322, lng: 20.4699745 },
    { lat: 49.1726216, lng: 20.4709698 },
    { lat: 49.1728551, lng: 20.4715231 },
    { lat: 49.1732757, lng: 20.4722375 },
    { lat: 49.1737437, lng: 20.4735245 },
    { lat: 49.1740552, lng: 20.4740574 },
    { lat: 49.1742287, lng: 20.4742118 },
    { lat: 49.1744505, lng: 20.4743012 },
    { lat: 49.1746914, lng: 20.4743274 },
    { lat: 49.175033, lng: 20.4742499 },
    { lat: 49.1759195, lng: 20.4743819 },
    { lat: 49.1766018, lng: 20.4745325 },
    { lat: 49.1771042, lng: 20.4747533 },
    { lat: 49.17752, lng: 20.47528 },
    { lat: 49.1780396, lng: 20.476136 },
    { lat: 49.1785539, lng: 20.4774321 },
    { lat: 49.1800731, lng: 20.4779876 },
    { lat: 49.1806115, lng: 20.4780827 },
    { lat: 49.1811555, lng: 20.4780574 },
    { lat: 49.18204, lng: 20.4777154 },
    { lat: 49.1830522, lng: 20.4774793 },
    { lat: 49.1840287, lng: 20.4774301 },
    { lat: 49.1846683, lng: 20.4775495 },
    { lat: 49.1849854, lng: 20.4774802 },
    { lat: 49.1851712, lng: 20.4773025 },
    { lat: 49.1853295, lng: 20.4769548 },
    { lat: 49.185813, lng: 20.4762484 },
    { lat: 49.1859083, lng: 20.4759054 },
    { lat: 49.1860579, lng: 20.4748778 },
    { lat: 49.1863538, lng: 20.4737533 },
    { lat: 49.186666, lng: 20.4729156 },
    { lat: 49.1868802, lng: 20.4727113 },
    { lat: 49.1881944, lng: 20.4731773 },
    { lat: 49.1898593, lng: 20.4738673 },
    { lat: 49.1908536, lng: 20.4740797 },
    { lat: 49.1913226, lng: 20.4743402 },
    { lat: 49.1920727, lng: 20.474892 },
    { lat: 49.1925696, lng: 20.4753873 },
    { lat: 49.1929798, lng: 20.4760633 },
    { lat: 49.193129, lng: 20.4766216 },
    { lat: 49.1931205, lng: 20.4770568 },
    { lat: 49.1929841, lng: 20.4774437 },
    { lat: 49.1927715, lng: 20.477752 },
    { lat: 49.192209, lng: 20.4783634 },
    { lat: 49.1919674, lng: 20.478735 },
    { lat: 49.19191, lng: 20.47907 },
    { lat: 49.1919778, lng: 20.4794698 },
    { lat: 49.192341, lng: 20.4799415 },
    { lat: 49.1931464, lng: 20.4806189 },
    { lat: 49.1935114, lng: 20.4811078 },
    { lat: 49.194134, lng: 20.4821657 },
    { lat: 49.1946246, lng: 20.4825817 },
    { lat: 49.1949862, lng: 20.4828013 },
    { lat: 49.1961958, lng: 20.4832644 },
    { lat: 49.1974104, lng: 20.4844407 },
    { lat: 49.1982268, lng: 20.4847479 },
    { lat: 49.1985825, lng: 20.4851091 },
    { lat: 49.1990773, lng: 20.4858201 },
    { lat: 49.1993256, lng: 20.4860513 },
    { lat: 49.2004535, lng: 20.4864957 },
    { lat: 49.2008284, lng: 20.4865287 },
    { lat: 49.2013897, lng: 20.486245 },
    { lat: 49.2014715, lng: 20.4859906 },
    { lat: 49.2015019, lng: 20.4856592 },
    { lat: 49.2014287, lng: 20.48496 },
    { lat: 49.2015364, lng: 20.4845895 },
    { lat: 49.2016332, lng: 20.4844699 },
    { lat: 49.201842, lng: 20.4843628 },
    { lat: 49.2020932, lng: 20.4843627 },
    { lat: 49.2024587, lng: 20.4845362 },
    { lat: 49.2032769, lng: 20.485284 },
    { lat: 49.2038406, lng: 20.4861565 },
    { lat: 49.2045445, lng: 20.4869455 },
    { lat: 49.2056354, lng: 20.4883693 },
    { lat: 49.2058902, lng: 20.4890483 },
    { lat: 49.2062041, lng: 20.4903716 },
    { lat: 49.2062893, lng: 20.4905443 },
    { lat: 49.2063571, lng: 20.4902147 },
    { lat: 49.2066442, lng: 20.4893074 },
    { lat: 49.2066545, lng: 20.488981 },
    { lat: 49.2062545, lng: 20.4884907 },
    { lat: 49.2063417, lng: 20.4879553 },
    { lat: 49.2061851, lng: 20.4878608 },
    { lat: 49.2061617, lng: 20.4877203 },
    { lat: 49.2061145, lng: 20.4877201 },
    { lat: 49.206103, lng: 20.487563 },
    { lat: 49.2061513, lng: 20.4869842 },
    { lat: 49.206271, lng: 20.486752 },
    { lat: 49.2065071, lng: 20.4860147 },
    { lat: 49.2065717, lng: 20.4856656 },
    { lat: 49.2065544, lng: 20.485563 },
    { lat: 49.2066462, lng: 20.4854866 },
    { lat: 49.2066329, lng: 20.4845912 },
    { lat: 49.2067374, lng: 20.4845833 },
    { lat: 49.2068614, lng: 20.4844663 },
    { lat: 49.2067941, lng: 20.4842889 },
    { lat: 49.2067747, lng: 20.4840407 },
    { lat: 49.2069923, lng: 20.4838713 },
    { lat: 49.2070034, lng: 20.4835614 },
    { lat: 49.206914, lng: 20.4832184 },
    { lat: 49.2069661, lng: 20.4831048 },
    { lat: 49.2069248, lng: 20.4830202 },
    { lat: 49.20698, lng: 20.4828109 },
    { lat: 49.2069266, lng: 20.4822581 },
    { lat: 49.2070048, lng: 20.4817231 },
    { lat: 49.2069974, lng: 20.4813785 },
    { lat: 49.2068878, lng: 20.4808807 },
    { lat: 49.2069441, lng: 20.4807004 },
    { lat: 49.2070316, lng: 20.4805999 },
    { lat: 49.2066778, lng: 20.4797777 },
    { lat: 49.2067045, lng: 20.4796465 },
    { lat: 49.2066279, lng: 20.4795267 },
    { lat: 49.2066859, lng: 20.4792992 },
    { lat: 49.2066055, lng: 20.4791369 },
    { lat: 49.2066515, lng: 20.4789205 },
    { lat: 49.2064365, lng: 20.4783472 },
    { lat: 49.2064485, lng: 20.4782472 },
    { lat: 49.2063891, lng: 20.4781339 },
    { lat: 49.2064265, lng: 20.477929 },
    { lat: 49.2062647, lng: 20.4778159 },
    { lat: 49.2062385, lng: 20.4774733 },
    { lat: 49.2061932, lng: 20.4774095 },
    { lat: 49.2059714, lng: 20.4773449 },
    { lat: 49.2059838, lng: 20.4771087 },
    { lat: 49.2066032, lng: 20.4759617 },
    { lat: 49.2072516, lng: 20.4747547 },
    { lat: 49.2079152, lng: 20.4738937 },
    { lat: 49.2083226, lng: 20.4731666 },
    { lat: 49.2088659, lng: 20.4717306 },
    { lat: 49.2094502, lng: 20.4706809 },
    { lat: 49.2097979, lng: 20.4691635 },
    { lat: 49.2100069, lng: 20.4673179 },
    { lat: 49.2096048, lng: 20.4670389 },
    { lat: 49.2106271, lng: 20.4644196 },
    { lat: 49.2117446, lng: 20.4635365 },
    { lat: 49.2126471, lng: 20.4631932 },
    { lat: 49.2135034, lng: 20.4630321 },
    { lat: 49.2141221, lng: 20.4631673 },
    { lat: 49.2159343, lng: 20.4638218 },
    { lat: 49.2176858, lng: 20.4636015 },
    { lat: 49.2181877, lng: 20.4634789 },
    { lat: 49.2185861, lng: 20.4631996 },
    { lat: 49.2187685, lng: 20.4631427 },
    { lat: 49.2190095, lng: 20.4628433 },
    { lat: 49.2191052, lng: 20.4628724 },
    { lat: 49.2199255, lng: 20.4623493 },
    { lat: 49.2201802, lng: 20.4623861 },
    { lat: 49.2203297, lng: 20.4622585 },
    { lat: 49.2204488, lng: 20.4622806 },
    { lat: 49.2205945, lng: 20.4622031 },
    { lat: 49.2208839, lng: 20.4617536 },
    { lat: 49.2212098, lng: 20.4614972 },
    { lat: 49.2213441, lng: 20.4605162 },
    { lat: 49.2212766, lng: 20.4594946 },
    { lat: 49.2210813, lng: 20.4583666 },
    { lat: 49.2210504, lng: 20.4555874 },
    { lat: 49.2207691, lng: 20.4543269 },
    { lat: 49.2203737, lng: 20.4533007 },
    { lat: 49.2197687, lng: 20.4526293 },
    { lat: 49.2191204, lng: 20.4517604 },
    { lat: 49.2193139, lng: 20.4492976 },
    { lat: 49.2189175, lng: 20.4483343 },
    { lat: 49.2179306, lng: 20.4469674 },
    { lat: 49.2172533, lng: 20.4454478 },
    { lat: 49.2170802, lng: 20.4456372 },
    { lat: 49.2169677, lng: 20.4457591 },
    { lat: 49.2162897, lng: 20.444823 },
    { lat: 49.2160697, lng: 20.4443932 },
    { lat: 49.2159779, lng: 20.4440158 },
    { lat: 49.2159509, lng: 20.4436231 },
    { lat: 49.2160253, lng: 20.4426943 },
    { lat: 49.2159255, lng: 20.4422299 },
    { lat: 49.2156763, lng: 20.4415887 },
    { lat: 49.2151558, lng: 20.4406511 },
    { lat: 49.2143577, lng: 20.4397548 },
    { lat: 49.2140443, lng: 20.4392943 },
    { lat: 49.2139389, lng: 20.4388008 },
    { lat: 49.2139558, lng: 20.4385019 },
    { lat: 49.2142023, lng: 20.4368842 },
    { lat: 49.2144801, lng: 20.4358277 },
    { lat: 49.2145969, lng: 20.4351952 },
    { lat: 49.2146065, lng: 20.4344266 },
    { lat: 49.2145127, lng: 20.4340293 },
    { lat: 49.2143352, lng: 20.4335936 },
    { lat: 49.2137293, lng: 20.4326582 },
    { lat: 49.2134097, lng: 20.4320658 },
    { lat: 49.2132483, lng: 20.4314591 },
    { lat: 49.2132479, lng: 20.4300457 },
    { lat: 49.2136314, lng: 20.428927 },
    { lat: 49.2136462, lng: 20.4287496 },
    { lat: 49.2135939, lng: 20.4278459 },
    { lat: 49.2134652, lng: 20.4274478 },
    { lat: 49.2133329, lng: 20.4257845 },
    { lat: 49.2132989, lng: 20.4252473 },
    { lat: 49.2133375, lng: 20.4248381 },
    { lat: 49.2136765, lng: 20.4239429 },
    { lat: 49.2143314, lng: 20.4229158 },
    { lat: 49.2146688, lng: 20.4222855 },
    { lat: 49.21446, lng: 20.4219099 },
    { lat: 49.2137171, lng: 20.4210814 },
    { lat: 49.2136362, lng: 20.4207871 },
    { lat: 49.2137205, lng: 20.4204577 },
    { lat: 49.2139458, lng: 20.4202513 },
    { lat: 49.2141031, lng: 20.4198554 },
    { lat: 49.2140457, lng: 20.4187502 },
    { lat: 49.2140869, lng: 20.4181718 },
    { lat: 49.2139445, lng: 20.4175624 },
    { lat: 49.2140306, lng: 20.417306 },
    { lat: 49.2140934, lng: 20.4172446 },
    { lat: 49.214613, lng: 20.4172302 },
    { lat: 49.2146862, lng: 20.4168118 },
    { lat: 49.2143642, lng: 20.4151158 },
    { lat: 49.2144022, lng: 20.414273 },
    { lat: 49.2145821, lng: 20.4133719 },
    { lat: 49.2142985, lng: 20.4122431 },
    { lat: 49.2142727, lng: 20.4117747 },
    { lat: 49.2143521, lng: 20.4114889 },
    { lat: 49.214764, lng: 20.4115455 },
    { lat: 49.2150684, lng: 20.4114348 },
    { lat: 49.2152697, lng: 20.4112542 },
    { lat: 49.2154368, lng: 20.4109395 },
    { lat: 49.2154965, lng: 20.4103713 },
    { lat: 49.215359, lng: 20.409003 },
    { lat: 49.2154072, lng: 20.4082256 },
    { lat: 49.2154312, lng: 20.407962 },
    { lat: 49.2154747, lng: 20.4076283 },
    { lat: 49.2160301, lng: 20.4057919 },
    { lat: 49.2163472, lng: 20.4051314 },
    { lat: 49.2165738, lng: 20.4044239 },
    { lat: 49.2167388, lng: 20.4031308 },
    { lat: 49.2169319, lng: 20.4022886 },
    { lat: 49.2171276, lng: 20.4019551 },
    { lat: 49.2178957, lng: 20.4011313 },
    { lat: 49.2181014, lng: 20.4007969 },
    { lat: 49.218527, lng: 20.3996942 },
    { lat: 49.2188758, lng: 20.399099 },
    { lat: 49.219051, lng: 20.3985199 },
    { lat: 49.2191053, lng: 20.3981715 },
    { lat: 49.2190546, lng: 20.3974333 },
    { lat: 49.2185579, lng: 20.3953187 },
    { lat: 49.2185523, lng: 20.3947286 },
    { lat: 49.2185929, lng: 20.3943126 },
    { lat: 49.2186636, lng: 20.3940996 },
    { lat: 49.2192138, lng: 20.3931706 },
    { lat: 49.2194498, lng: 20.3924211 },
    { lat: 49.2195597, lng: 20.3919024 },
    { lat: 49.2196164, lng: 20.3914713 },
    { lat: 49.21961, lng: 20.3910044 },
    { lat: 49.2193459, lng: 20.3899833 },
    { lat: 49.2193575, lng: 20.3896934 },
    { lat: 49.219421, lng: 20.3894403 },
    { lat: 49.2195951, lng: 20.389136 },
    { lat: 49.2203393, lng: 20.3884236 },
    { lat: 49.2204387, lng: 20.388222 },
    { lat: 49.2204179, lng: 20.3876742 },
    { lat: 49.2206107, lng: 20.3872721 },
    { lat: 49.2207639, lng: 20.3873 },
    { lat: 49.2210584, lng: 20.3870538 },
    { lat: 49.221242, lng: 20.3868174 },
    { lat: 49.2214094, lng: 20.3863624 },
    { lat: 49.2214629, lng: 20.3856978 },
    { lat: 49.2219676, lng: 20.3855018 },
    { lat: 49.2220886, lng: 20.3853948 },
    { lat: 49.2222675, lng: 20.3853653 },
    { lat: 49.2229257, lng: 20.3847565 },
    { lat: 49.2230418, lng: 20.3847325 },
    { lat: 49.2233084, lng: 20.3843101 },
    { lat: 49.2233615, lng: 20.383934 },
    { lat: 49.2232401, lng: 20.3832853 },
    { lat: 49.2232863, lng: 20.3829923 },
    { lat: 49.2232034, lng: 20.3829172 },
    { lat: 49.2232156, lng: 20.3826413 },
    { lat: 49.2230501, lng: 20.3825594 },
    { lat: 49.2227445, lng: 20.3822155 },
    { lat: 49.2226654, lng: 20.3822085 },
    { lat: 49.2226702, lng: 20.3820431 },
    { lat: 49.2225539, lng: 20.3819439 },
    { lat: 49.2223617, lng: 20.3822495 },
    { lat: 49.2221288, lng: 20.3824899 },
    { lat: 49.2219361, lng: 20.3824245 },
    { lat: 49.2217612, lng: 20.3822056 },
    { lat: 49.2219777, lng: 20.3817451 },
    { lat: 49.2220293, lng: 20.3812028 },
    { lat: 49.222023, lng: 20.380363 },
    { lat: 49.2226423, lng: 20.3791503 },
    { lat: 49.2236606, lng: 20.3783956 },
    { lat: 49.2242343, lng: 20.3776422 },
    { lat: 49.2244249, lng: 20.3771786 },
    { lat: 49.2246144, lng: 20.3765116 },
    { lat: 49.2246322, lng: 20.3757352 },
    { lat: 49.2244654, lng: 20.3749352 },
    { lat: 49.2242737, lng: 20.3745449 },
    { lat: 49.2240856, lng: 20.3735645 },
    { lat: 49.2240975, lng: 20.3730844 },
    { lat: 49.2242812, lng: 20.3720573 },
    { lat: 49.2242512, lng: 20.3719458 },
    { lat: 49.2237896, lng: 20.3713842 },
    { lat: 49.2236787, lng: 20.3711387 },
    { lat: 49.2236482, lng: 20.3705193 },
    { lat: 49.2236848, lng: 20.3700867 },
    { lat: 49.2241004, lng: 20.3687367 },
    { lat: 49.2242106, lng: 20.3684643 },
    { lat: 49.224439, lng: 20.3681079 },
    { lat: 49.2245923, lng: 20.3679948 },
    { lat: 49.2252641, lng: 20.3678053 },
    { lat: 49.2255867, lng: 20.3676163 },
    { lat: 49.2256335, lng: 20.3674896 },
    { lat: 49.2256198, lng: 20.367278 },
    { lat: 49.2254468, lng: 20.3668153 },
    { lat: 49.2252833, lng: 20.3659567 },
    { lat: 49.2253368, lng: 20.3654675 },
    { lat: 49.2254239, lng: 20.3652717 },
    { lat: 49.2260301, lng: 20.3648412 },
    { lat: 49.226593, lng: 20.3640749 },
    { lat: 49.2269739, lng: 20.3634357 },
    { lat: 49.2270742, lng: 20.3631465 },
    { lat: 49.2271264, lng: 20.362773 },
    { lat: 49.2269543, lng: 20.3613078 },
    { lat: 49.2269901, lng: 20.3605041 },
    { lat: 49.2271624, lng: 20.3595943 },
    { lat: 49.2268574, lng: 20.3581827 },
    { lat: 49.2268282, lng: 20.3578028 },
    { lat: 49.2266512, lng: 20.3569461 },
    { lat: 49.2265823, lng: 20.3554421 },
    { lat: 49.2265298, lng: 20.3551017 },
    { lat: 49.2263691, lng: 20.3545509 },
    { lat: 49.2261318, lng: 20.3541156 },
    { lat: 49.2259289, lng: 20.353931 },
    { lat: 49.2256512, lng: 20.3537958 },
    { lat: 49.2254778, lng: 20.3535321 },
    { lat: 49.225261, lng: 20.352499 },
    { lat: 49.225203, lng: 20.352513 },
    { lat: 49.225085, lng: 20.352547 },
    { lat: 49.224913, lng: 20.352642 },
    { lat: 49.224514, lng: 20.353528 },
    { lat: 49.224494, lng: 20.353481 },
    { lat: 49.224413, lng: 20.353289 },
    { lat: 49.224349, lng: 20.35348 },
    { lat: 49.224244, lng: 20.353783 },
    { lat: 49.224205, lng: 20.35419 },
    { lat: 49.224202, lng: 20.35737 },
    { lat: 49.224169, lng: 20.357663 },
    { lat: 49.223767, lng: 20.360313 },
    { lat: 49.223695, lng: 20.360611 },
    { lat: 49.223189, lng: 20.362065 },
    { lat: 49.223092, lng: 20.362286 },
    { lat: 49.222869, lng: 20.363247 },
    { lat: 49.222806, lng: 20.363444 },
    { lat: 49.222701, lng: 20.36364 },
    { lat: 49.222547, lng: 20.363766 },
    { lat: 49.222411, lng: 20.363825 },
    { lat: 49.221959, lng: 20.363887 },
    { lat: 49.221924, lng: 20.363923 },
    { lat: 49.221758, lng: 20.364098 },
    { lat: 49.220984, lng: 20.364695 },
    { lat: 49.220828, lng: 20.364842 },
    { lat: 49.220721, lng: 20.365036 },
    { lat: 49.220585, lng: 20.365383 },
    { lat: 49.220437, lng: 20.366053 },
    { lat: 49.220328, lng: 20.366325 },
    { lat: 49.219834, lng: 20.36723 },
    { lat: 49.218955, lng: 20.368408 },
    { lat: 49.218806, lng: 20.368574 },
    { lat: 49.218487, lng: 20.368728 },
    { lat: 49.21843, lng: 20.368756 },
    { lat: 49.218366, lng: 20.368789 },
    { lat: 49.21833, lng: 20.368807 },
    { lat: 49.218165, lng: 20.368695 },
    { lat: 49.218135, lng: 20.368675 },
    { lat: 49.217912, lng: 20.368524 },
    { lat: 49.217805, lng: 20.368455 },
    { lat: 49.21772, lng: 20.368426 },
    { lat: 49.217439, lng: 20.368332 },
    { lat: 49.217275, lng: 20.368377 },
    { lat: 49.21717, lng: 20.368406 },
    { lat: 49.216826, lng: 20.368502 },
    { lat: 49.216752, lng: 20.368522 },
    { lat: 49.216552, lng: 20.368578 },
    { lat: 49.21626, lng: 20.368709 },
    { lat: 49.21564, lng: 20.368986 },
    { lat: 49.21519, lng: 20.369129 },
    { lat: 49.214935, lng: 20.369215 },
    { lat: 49.214729, lng: 20.369184 },
    { lat: 49.214605, lng: 20.369165 },
    { lat: 49.214406, lng: 20.369135 },
    { lat: 49.214154, lng: 20.369169 },
    { lat: 49.213818, lng: 20.369314 },
    { lat: 49.213557, lng: 20.369334 },
    { lat: 49.212932, lng: 20.369503 },
    { lat: 49.212414, lng: 20.369737 },
    { lat: 49.212307, lng: 20.369726 },
    { lat: 49.212138, lng: 20.369611 },
    { lat: 49.212096, lng: 20.369437 },
    { lat: 49.21206, lng: 20.369291 },
    { lat: 49.212086, lng: 20.368586 },
    { lat: 49.212098, lng: 20.368109 },
    { lat: 49.212101, lng: 20.36802 },
    { lat: 49.212172, lng: 20.367912 },
    { lat: 49.212104, lng: 20.367661 },
    { lat: 49.212025, lng: 20.366919 },
    { lat: 49.211899, lng: 20.365415 },
    { lat: 49.211852, lng: 20.365414 },
    { lat: 49.211619, lng: 20.365408 },
    { lat: 49.211601, lng: 20.365424 },
    { lat: 49.211562, lng: 20.365428 },
    { lat: 49.211536, lng: 20.36543 },
    { lat: 49.211479, lng: 20.365429 },
    { lat: 49.211468, lng: 20.36543 },
    { lat: 49.210429, lng: 20.365422 },
    { lat: 49.209341, lng: 20.365435 },
    { lat: 49.209303, lng: 20.365456 },
    { lat: 49.209282, lng: 20.365467 },
    { lat: 49.209244, lng: 20.365488 },
    { lat: 49.208094, lng: 20.365525 },
    { lat: 49.207841, lng: 20.365449 },
    { lat: 49.206473, lng: 20.365463 },
    { lat: 49.205833, lng: 20.365773 },
    { lat: 49.205798, lng: 20.36579 },
    { lat: 49.205779, lng: 20.365799 },
    { lat: 49.205764, lng: 20.365806 },
    { lat: 49.205349, lng: 20.365775 },
    { lat: 49.205214, lng: 20.365812 },
    { lat: 49.204275, lng: 20.36607 },
    { lat: 49.203069, lng: 20.366463 },
    { lat: 49.202562, lng: 20.366646 },
    { lat: 49.202008, lng: 20.366844 },
    { lat: 49.200952, lng: 20.367213 },
    { lat: 49.200941, lng: 20.367217 },
    { lat: 49.2010022, lng: 20.3710594 },
    { lat: 49.2007593, lng: 20.3724513 },
    { lat: 49.2008918, lng: 20.3724435 },
    { lat: 49.200598, lng: 20.3761061 },
    { lat: 49.200276, lng: 20.3771359 },
    { lat: 49.200095, lng: 20.3772017 },
    { lat: 49.1990872, lng: 20.3797967 },
    { lat: 49.1983468, lng: 20.3809349 },
    { lat: 49.1970612, lng: 20.3834183 },
    { lat: 49.1966491, lng: 20.384392 },
    { lat: 49.19572, lng: 20.3857801 },
    { lat: 49.1948033, lng: 20.3874313 },
    { lat: 49.1939389, lng: 20.3887413 },
    { lat: 49.1934651, lng: 20.389806 },
    { lat: 49.1908143, lng: 20.3975565 },
    { lat: 49.1856639, lng: 20.4092995 },
    { lat: 49.182958, lng: 20.4155935 },
    { lat: 49.1821706, lng: 20.418948 },
    { lat: 49.1818157, lng: 20.4233217 },
    { lat: 49.1813081, lng: 20.4252053 },
    { lat: 49.1801737, lng: 20.4243553 },
    { lat: 49.1802091, lng: 20.4243046 },
    { lat: 49.1802128, lng: 20.4241658 },
    { lat: 49.1803531, lng: 20.423976 },
    { lat: 49.1803429, lng: 20.4239136 },
    { lat: 49.1801966, lng: 20.4239732 },
    { lat: 49.1801757, lng: 20.4238397 },
    { lat: 49.180231, lng: 20.423755 },
    { lat: 49.1801678, lng: 20.4235421 },
    { lat: 49.1802316, lng: 20.4234084 },
    { lat: 49.1800806, lng: 20.4233209 },
    { lat: 49.1800655, lng: 20.4232441 },
    { lat: 49.1801393, lng: 20.4231998 },
    { lat: 49.1801449, lng: 20.4231122 },
    { lat: 49.1800848, lng: 20.4230995 },
    { lat: 49.1800683, lng: 20.4229621 },
    { lat: 49.1799849, lng: 20.4228974 },
    { lat: 49.1798948, lng: 20.4226916 },
    { lat: 49.1798896, lng: 20.4225243 },
    { lat: 49.1800451, lng: 20.4225564 },
    { lat: 49.1800963, lng: 20.4224493 },
    { lat: 49.1799976, lng: 20.4222849 },
    { lat: 49.1800331, lng: 20.4221176 },
    { lat: 49.1799948, lng: 20.4220292 },
    { lat: 49.1799207, lng: 20.4220041 },
    { lat: 49.1799009, lng: 20.422167 },
    { lat: 49.1798516, lng: 20.4221426 },
    { lat: 49.1798404, lng: 20.4216948 },
    { lat: 49.1797561, lng: 20.4215262 },
    { lat: 49.1798344, lng: 20.4214801 },
    { lat: 49.1797781, lng: 20.4213893 },
    { lat: 49.1798504, lng: 20.4213679 },
    { lat: 49.1798815, lng: 20.4211478 },
    { lat: 49.1797312, lng: 20.4211045 },
    { lat: 49.1796539, lng: 20.4211739 },
    { lat: 49.179658, lng: 20.4210454 },
    { lat: 49.1795486, lng: 20.4209165 },
    { lat: 49.1795302, lng: 20.420641 },
    { lat: 49.1795953, lng: 20.4204528 },
    { lat: 49.1795847, lng: 20.420322 },
    { lat: 49.1797267, lng: 20.4203855 },
    { lat: 49.1797731, lng: 20.4203008 },
    { lat: 49.1797627, lng: 20.4201509 },
    { lat: 49.1796598, lng: 20.4201056 },
    { lat: 49.179671, lng: 20.4197797 },
    { lat: 49.1798018, lng: 20.4196929 },
    { lat: 49.1798049, lng: 20.4195959 },
    { lat: 49.1797381, lng: 20.419587 },
    { lat: 49.1798326, lng: 20.4193786 },
    { lat: 49.1799371, lng: 20.4194043 },
    { lat: 49.1800042, lng: 20.4192368 },
    { lat: 49.1800011, lng: 20.4189812 },
    { lat: 49.1798348, lng: 20.4189883 },
    { lat: 49.1798498, lng: 20.4187282 },
    { lat: 49.1799298, lng: 20.4187615 },
    { lat: 49.1799995, lng: 20.4184573 },
    { lat: 49.1799302, lng: 20.4182029 },
    { lat: 49.1799472, lng: 20.4181172 },
    { lat: 49.1801134, lng: 20.4179586 },
    { lat: 49.1801636, lng: 20.4177379 },
    { lat: 49.180271, lng: 20.4177814 },
    { lat: 49.180336, lng: 20.4176795 },
    { lat: 49.1802973, lng: 20.4174918 },
    { lat: 49.1801792, lng: 20.4174648 },
    { lat: 49.1802715, lng: 20.4173782 },
    { lat: 49.1802372, lng: 20.4172996 },
    { lat: 49.1802724, lng: 20.4172515 },
    { lat: 49.1802601, lng: 20.4169261 },
    { lat: 49.1801833, lng: 20.416916 },
    { lat: 49.1801298, lng: 20.417024 },
    { lat: 49.1800909, lng: 20.4170055 },
    { lat: 49.1801414, lng: 20.4167315 },
    { lat: 49.1800912, lng: 20.4166891 },
    { lat: 49.1800955, lng: 20.4166225 },
    { lat: 49.1802535, lng: 20.4166331 },
    { lat: 49.1802903, lng: 20.4165344 },
    { lat: 49.1802073, lng: 20.416242 },
    { lat: 49.1800798, lng: 20.4162946 },
    { lat: 49.1800757, lng: 20.4160999 },
    { lat: 49.1800008, lng: 20.4158725 },
    { lat: 49.1800746, lng: 20.4157387 },
    { lat: 49.1800647, lng: 20.4156586 },
    { lat: 49.1801832, lng: 20.4154872 },
    { lat: 49.1801275, lng: 20.4153871 },
    { lat: 49.1801915, lng: 20.4152879 },
    { lat: 49.1801128, lng: 20.4152354 },
    { lat: 49.1801655, lng: 20.4150803 },
    { lat: 49.1801129, lng: 20.4150861 },
    { lat: 49.1800031, lng: 20.4147175 },
    { lat: 49.1801236, lng: 20.4147141 },
    { lat: 49.1801655, lng: 20.4146323 },
    { lat: 49.1800304, lng: 20.4144282 },
    { lat: 49.1800204, lng: 20.4143388 },
    { lat: 49.1801991, lng: 20.4143907 },
    { lat: 49.1802872, lng: 20.4141886 },
    { lat: 49.1801236, lng: 20.4141221 },
    { lat: 49.180109, lng: 20.4138563 },
    { lat: 49.1800646, lng: 20.4137558 },
    { lat: 49.1801455, lng: 20.4137487 },
    { lat: 49.1801612, lng: 20.413689 },
    { lat: 49.1800383, lng: 20.4134159 },
    { lat: 49.1800847, lng: 20.4133892 },
    { lat: 49.1801275, lng: 20.4134786 },
    { lat: 49.1802666, lng: 20.4135574 },
    { lat: 49.1802512, lng: 20.4131503 },
    { lat: 49.1803219, lng: 20.4131153 },
    { lat: 49.1803386, lng: 20.4130209 },
    { lat: 49.1804626, lng: 20.4130809 },
    { lat: 49.1805612, lng: 20.4127444 },
    { lat: 49.1804799, lng: 20.4127101 },
    { lat: 49.180459, lng: 20.4126321 },
    { lat: 49.1805722, lng: 20.4125555 },
    { lat: 49.1805514, lng: 20.4123346 },
    { lat: 49.1804976, lng: 20.4123095 },
    { lat: 49.1805578, lng: 20.4120558 },
    { lat: 49.1806522, lng: 20.4119622 },
    { lat: 49.1806852, lng: 20.4120615 },
    { lat: 49.1807756, lng: 20.4121011 },
    { lat: 49.1807688, lng: 20.4117724 },
    { lat: 49.1807013, lng: 20.4117467 },
    { lat: 49.180682, lng: 20.4116533 },
    { lat: 49.1805457, lng: 20.4115945 },
    { lat: 49.1807932, lng: 20.4114701 },
    { lat: 49.1808369, lng: 20.4113712 },
    { lat: 49.1808142, lng: 20.4112195 },
    { lat: 49.1807466, lng: 20.4111298 },
    { lat: 49.1807747, lng: 20.4110373 },
    { lat: 49.180749, lng: 20.4108931 },
    { lat: 49.1807947, lng: 20.410793 },
    { lat: 49.1806775, lng: 20.4106367 },
    { lat: 49.1806773, lng: 20.4102225 },
    { lat: 49.1807156, lng: 20.4101306 },
    { lat: 49.1806648, lng: 20.4099781 },
    { lat: 49.1806087, lng: 20.4099543 },
    { lat: 49.1807241, lng: 20.4095515 },
    { lat: 49.1806847, lng: 20.4093768 },
    { lat: 49.1805805, lng: 20.4093842 },
    { lat: 49.1805162, lng: 20.4094737 },
    { lat: 49.1805032, lng: 20.4093064 },
    { lat: 49.1805838, lng: 20.4093278 },
    { lat: 49.1806032, lng: 20.4092739 },
    { lat: 49.1805301, lng: 20.4090694 },
    { lat: 49.1806854, lng: 20.4091281 },
    { lat: 49.1806879, lng: 20.4090436 },
    { lat: 49.1807651, lng: 20.4089706 },
    { lat: 49.180704, lng: 20.4086956 },
    { lat: 49.1807633, lng: 20.4084755 },
    { lat: 49.180653, lng: 20.4084223 },
    { lat: 49.1806687, lng: 20.4083 },
    { lat: 49.1807326, lng: 20.408317 },
    { lat: 49.1807755, lng: 20.4082366 },
    { lat: 49.1807433, lng: 20.4081898 },
    { lat: 49.1807743, lng: 20.408087 },
    { lat: 49.1807005, lng: 20.4079823 },
    { lat: 49.180747, lng: 20.4076586 },
    { lat: 49.1807818, lng: 20.4076001 },
    { lat: 49.1808764, lng: 20.4076626 },
    { lat: 49.181041, lng: 20.4074851 },
    { lat: 49.1810119, lng: 20.4072836 },
    { lat: 49.181117, lng: 20.4072934 },
    { lat: 49.1811611, lng: 20.4071798 },
    { lat: 49.1812225, lng: 20.4072575 },
    { lat: 49.1813322, lng: 20.4071812 },
    { lat: 49.1813391, lng: 20.4070964 },
    { lat: 49.1814155, lng: 20.407058 },
    { lat: 49.1814088, lng: 20.4068239 },
    { lat: 49.1815316, lng: 20.4067667 },
    { lat: 49.1815025, lng: 20.4066814 },
    { lat: 49.1814457, lng: 20.4066972 },
    { lat: 49.1814802, lng: 20.4065106 },
    { lat: 49.181446, lng: 20.4063812 },
    { lat: 49.1815104, lng: 20.4063878 },
    { lat: 49.1815298, lng: 20.4063313 },
    { lat: 49.181361, lng: 20.4060993 },
    { lat: 49.1814114, lng: 20.4054363 },
    { lat: 49.1815852, lng: 20.404676 },
    { lat: 49.181482, lng: 20.4046228 },
    { lat: 49.1801043, lng: 20.4042791 },
    { lat: 49.1800267, lng: 20.4043726 },
    { lat: 49.1797853, lng: 20.405013 },
    { lat: 49.1787079, lng: 20.4068869 },
    { lat: 49.1777131, lng: 20.4077628 },
    { lat: 49.1773372, lng: 20.4083587 },
    { lat: 49.1767336, lng: 20.4096319 },
    { lat: 49.1764372, lng: 20.4104911 },
    { lat: 49.1760796, lng: 20.4116565 },
    { lat: 49.1758933, lng: 20.4130411 },
    { lat: 49.1755755, lng: 20.4143451 },
    { lat: 49.1748138, lng: 20.4164115 },
    { lat: 49.1737105, lng: 20.4184352 },
    { lat: 49.1720808, lng: 20.4209548 },
  ],
  KrížováVes: [
    { lat: 49.172066, lng: 20.5116092 },
    { lat: 49.1723174, lng: 20.5118607 },
    { lat: 49.1724778, lng: 20.5121865 },
    { lat: 49.1736543, lng: 20.5137051 },
    { lat: 49.1737988, lng: 20.5139877 },
    { lat: 49.1740892, lng: 20.5151732 },
    { lat: 49.1742634, lng: 20.5163812 },
    { lat: 49.1747109, lng: 20.5171912 },
    { lat: 49.1749146, lng: 20.5174629 },
    { lat: 49.1749105, lng: 20.5175684 },
    { lat: 49.175031, lng: 20.5178102 },
    { lat: 49.1760261, lng: 20.5196051 },
    { lat: 49.1768351, lng: 20.5213625 },
    { lat: 49.1773671, lng: 20.5225971 },
    { lat: 49.1774208, lng: 20.5223449 },
    { lat: 49.1772977, lng: 20.5210575 },
    { lat: 49.1773657, lng: 20.5206681 },
    { lat: 49.1774243, lng: 20.5205504 },
    { lat: 49.1775413, lng: 20.5198473 },
    { lat: 49.177637, lng: 20.5195696 },
    { lat: 49.1780052, lng: 20.5187502 },
    { lat: 49.1782372, lng: 20.5184212 },
    { lat: 49.1783794, lng: 20.5180624 },
    { lat: 49.1784448, lng: 20.5175473 },
    { lat: 49.1784209, lng: 20.5171427 },
    { lat: 49.1784832, lng: 20.5166965 },
    { lat: 49.1785237, lng: 20.5158136 },
    { lat: 49.1784428, lng: 20.5156352 },
    { lat: 49.1784355, lng: 20.5151968 },
    { lat: 49.1782707, lng: 20.5146946 },
    { lat: 49.1779841, lng: 20.5145235 },
    { lat: 49.1778855, lng: 20.5143578 },
    { lat: 49.1777766, lng: 20.5138757 },
    { lat: 49.1778446, lng: 20.5134304 },
    { lat: 49.1782293, lng: 20.5126625 },
    { lat: 49.17843, lng: 20.5119111 },
    { lat: 49.1785893, lng: 20.5115045 },
    { lat: 49.1786824, lng: 20.5113993 },
    { lat: 49.1791752, lng: 20.5110294 },
    { lat: 49.1814045, lng: 20.5106376 },
    { lat: 49.182394, lng: 20.5111818 },
    { lat: 49.1828418, lng: 20.5110806 },
    { lat: 49.1831499, lng: 20.5114075 },
    { lat: 49.1839392, lng: 20.5118711 },
    { lat: 49.1840839, lng: 20.511643 },
    { lat: 49.1844407, lng: 20.5118185 },
    { lat: 49.1848077, lng: 20.512122 },
    { lat: 49.1850022, lng: 20.5121499 },
    { lat: 49.1855837, lng: 20.5126231 },
    { lat: 49.1856967, lng: 20.5129347 },
    { lat: 49.1862251, lng: 20.5136785 },
    { lat: 49.186482, lng: 20.5137741 },
    { lat: 49.1867142, lng: 20.5136933 },
    { lat: 49.1870354, lng: 20.5137293 },
    { lat: 49.1875673, lng: 20.5135612 },
    { lat: 49.187742, lng: 20.5134173 },
    { lat: 49.1880378, lng: 20.5134112 },
    { lat: 49.1883805, lng: 20.5137276 },
    { lat: 49.1885901, lng: 20.5141897 },
    { lat: 49.1887723, lng: 20.5148233 },
    { lat: 49.1888445, lng: 20.5147667 },
    { lat: 49.1888799, lng: 20.5144412 },
    { lat: 49.1898098, lng: 20.5145361 },
    { lat: 49.19009, lng: 20.5149309 },
    { lat: 49.1902696, lng: 20.5150659 },
    { lat: 49.1905141, lng: 20.5154379 },
    { lat: 49.1907403, lng: 20.5159216 },
    { lat: 49.1913501, lng: 20.5164584 },
    { lat: 49.1916356, lng: 20.5167844 },
    { lat: 49.1920241, lng: 20.5173643 },
    { lat: 49.1922212, lng: 20.5175468 },
    { lat: 49.1936885, lng: 20.5197862 },
    { lat: 49.1951498, lng: 20.5189907 },
    { lat: 49.1953452, lng: 20.5194076 },
    { lat: 49.1955714, lng: 20.5204735 },
    { lat: 49.1972507, lng: 20.5194867 },
    { lat: 49.1976869, lng: 20.5207196 },
    { lat: 49.1997517, lng: 20.5195355 },
    { lat: 49.2005458, lng: 20.5187184 },
    { lat: 49.201246, lng: 20.5178356 },
    { lat: 49.201703, lng: 20.5174031 },
    { lat: 49.2024568, lng: 20.5171489 },
    { lat: 49.202861, lng: 20.5173327 },
    { lat: 49.2029931, lng: 20.517313 },
    { lat: 49.2040336, lng: 20.51683 },
    { lat: 49.2054953, lng: 20.5152611 },
    { lat: 49.2064256, lng: 20.5145682 },
    { lat: 49.2084257, lng: 20.5141919 },
    { lat: 49.2091035, lng: 20.513435 },
    { lat: 49.2094505, lng: 20.512318 },
    { lat: 49.2093877, lng: 20.5114358 },
    { lat: 49.2097267, lng: 20.5107412 },
    { lat: 49.2099759, lng: 20.5099024 },
    { lat: 49.210185, lng: 20.5081325 },
    { lat: 49.2102057, lng: 20.5069832 },
    { lat: 49.2102691, lng: 20.5062352 },
    { lat: 49.2104468, lng: 20.5057403 },
    { lat: 49.2107234, lng: 20.5053092 },
    { lat: 49.2103729, lng: 20.5050254 },
    { lat: 49.2106745, lng: 20.5042889 },
    { lat: 49.2107794, lng: 20.5032619 },
    { lat: 49.2106828, lng: 20.502482 },
    { lat: 49.2107723, lng: 20.5021607 },
    { lat: 49.2114425, lng: 20.5014726 },
    { lat: 49.2119966, lng: 20.5012681 },
    { lat: 49.2123369, lng: 20.5009388 },
    { lat: 49.2127143, lng: 20.5008561 },
    { lat: 49.2137221, lng: 20.5001943 },
    { lat: 49.2138002, lng: 20.4999661 },
    { lat: 49.2142003, lng: 20.499431 },
    { lat: 49.2142723, lng: 20.4991522 },
    { lat: 49.2146852, lng: 20.4982054 },
    { lat: 49.2147039, lng: 20.4979546 },
    { lat: 49.2151367, lng: 20.4972628 },
    { lat: 49.2153916, lng: 20.4970377 },
    { lat: 49.2155047, lng: 20.4968046 },
    { lat: 49.2155377, lng: 20.4967294 },
    { lat: 49.21547, lng: 20.4966326 },
    { lat: 49.2155206, lng: 20.4962051 },
    { lat: 49.2149455, lng: 20.495665 },
    { lat: 49.2149497, lng: 20.4952577 },
    { lat: 49.2132406, lng: 20.4955009 },
    { lat: 49.2120414, lng: 20.4959496 },
    { lat: 49.2105696, lng: 20.4962116 },
    { lat: 49.2102845, lng: 20.4960821 },
    { lat: 49.2089806, lng: 20.4957441 },
    { lat: 49.2083963, lng: 20.4954979 },
    { lat: 49.2078557, lng: 20.4950373 },
    { lat: 49.2077074, lng: 20.4948359 },
    { lat: 49.2076668, lng: 20.4946525 },
    { lat: 49.2075077, lng: 20.4945795 },
    { lat: 49.2067189, lng: 20.4938956 },
    { lat: 49.2062066, lng: 20.4931676 },
    { lat: 49.2061203, lng: 20.4928449 },
    { lat: 49.2062139, lng: 20.4908756 },
    { lat: 49.2062893, lng: 20.4905443 },
    { lat: 49.2062041, lng: 20.4903716 },
    { lat: 49.2058902, lng: 20.4890483 },
    { lat: 49.2056354, lng: 20.4883693 },
    { lat: 49.2045445, lng: 20.4869455 },
    { lat: 49.2038406, lng: 20.4861565 },
    { lat: 49.2032769, lng: 20.485284 },
    { lat: 49.2024587, lng: 20.4845362 },
    { lat: 49.2020932, lng: 20.4843627 },
    { lat: 49.201842, lng: 20.4843628 },
    { lat: 49.2016332, lng: 20.4844699 },
    { lat: 49.2015364, lng: 20.4845895 },
    { lat: 49.2014287, lng: 20.48496 },
    { lat: 49.2015019, lng: 20.4856592 },
    { lat: 49.2014715, lng: 20.4859906 },
    { lat: 49.2013897, lng: 20.486245 },
    { lat: 49.2008284, lng: 20.4865287 },
    { lat: 49.2004535, lng: 20.4864957 },
    { lat: 49.1993256, lng: 20.4860513 },
    { lat: 49.1990773, lng: 20.4858201 },
    { lat: 49.1985825, lng: 20.4851091 },
    { lat: 49.1982268, lng: 20.4847479 },
    { lat: 49.1974104, lng: 20.4844407 },
    { lat: 49.1961958, lng: 20.4832644 },
    { lat: 49.1949862, lng: 20.4828013 },
    { lat: 49.1946246, lng: 20.4825817 },
    { lat: 49.194134, lng: 20.4821657 },
    { lat: 49.1935114, lng: 20.4811078 },
    { lat: 49.1931464, lng: 20.4806189 },
    { lat: 49.192341, lng: 20.4799415 },
    { lat: 49.1919778, lng: 20.4794698 },
    { lat: 49.19191, lng: 20.47907 },
    { lat: 49.1919674, lng: 20.478735 },
    { lat: 49.192209, lng: 20.4783634 },
    { lat: 49.1927715, lng: 20.477752 },
    { lat: 49.1929841, lng: 20.4774437 },
    { lat: 49.1931205, lng: 20.4770568 },
    { lat: 49.193129, lng: 20.4766216 },
    { lat: 49.1929798, lng: 20.4760633 },
    { lat: 49.1925696, lng: 20.4753873 },
    { lat: 49.1920727, lng: 20.474892 },
    { lat: 49.1913226, lng: 20.4743402 },
    { lat: 49.1908536, lng: 20.4740797 },
    { lat: 49.1898593, lng: 20.4738673 },
    { lat: 49.1881944, lng: 20.4731773 },
    { lat: 49.1868802, lng: 20.4727113 },
    { lat: 49.186666, lng: 20.4729156 },
    { lat: 49.1863538, lng: 20.4737533 },
    { lat: 49.1860579, lng: 20.4748778 },
    { lat: 49.1859083, lng: 20.4759054 },
    { lat: 49.185813, lng: 20.4762484 },
    { lat: 49.1853295, lng: 20.4769548 },
    { lat: 49.1851712, lng: 20.4773025 },
    { lat: 49.1849854, lng: 20.4774802 },
    { lat: 49.1846683, lng: 20.4775495 },
    { lat: 49.1840287, lng: 20.4774301 },
    { lat: 49.1830522, lng: 20.4774793 },
    { lat: 49.18204, lng: 20.4777154 },
    { lat: 49.1811555, lng: 20.4780574 },
    { lat: 49.1806115, lng: 20.4780827 },
    { lat: 49.1800731, lng: 20.4779876 },
    { lat: 49.1785539, lng: 20.4774321 },
    { lat: 49.1780396, lng: 20.476136 },
    { lat: 49.17752, lng: 20.47528 },
    { lat: 49.1771042, lng: 20.4747533 },
    { lat: 49.1766018, lng: 20.4745325 },
    { lat: 49.1759195, lng: 20.4743819 },
    { lat: 49.175033, lng: 20.4742499 },
    { lat: 49.1746914, lng: 20.4743274 },
    { lat: 49.1744505, lng: 20.4743012 },
    { lat: 49.1742287, lng: 20.4742118 },
    { lat: 49.1740552, lng: 20.4740574 },
    { lat: 49.1737437, lng: 20.4735245 },
    { lat: 49.1732757, lng: 20.4722375 },
    { lat: 49.1728551, lng: 20.4715231 },
    { lat: 49.1726216, lng: 20.4709698 },
    { lat: 49.1723322, lng: 20.4699745 },
    { lat: 49.172137, lng: 20.4690571 },
    { lat: 49.1718445, lng: 20.4681625 },
    { lat: 49.1718208, lng: 20.4680699 },
    { lat: 49.1717099, lng: 20.4682019 },
    { lat: 49.1715543, lng: 20.4683766 },
    { lat: 49.1711458, lng: 20.4692872 },
    { lat: 49.1705864, lng: 20.4700997 },
    { lat: 49.1704138, lng: 20.4704561 },
    { lat: 49.1699045, lng: 20.4724653 },
    { lat: 49.1695826, lng: 20.4734301 },
    { lat: 49.1693972, lng: 20.474164 },
    { lat: 49.1690442, lng: 20.4751096 },
    { lat: 49.1688188, lng: 20.4756185 },
    { lat: 49.168213, lng: 20.4763407 },
    { lat: 49.1682926, lng: 20.4777772 },
    { lat: 49.1682531, lng: 20.4798307 },
    { lat: 49.1678207, lng: 20.4817624 },
    { lat: 49.1675359, lng: 20.4833324 },
    { lat: 49.1681948, lng: 20.4844259 },
    { lat: 49.1684589, lng: 20.4862926 },
    { lat: 49.1693124, lng: 20.4871703 },
    { lat: 49.1697593, lng: 20.488257 },
    { lat: 49.169702, lng: 20.4901084 },
    { lat: 49.1694832, lng: 20.4911855 },
    { lat: 49.1695932, lng: 20.4919344 },
    { lat: 49.1698354, lng: 20.4929986 },
    { lat: 49.1698972, lng: 20.4941155 },
    { lat: 49.1695359, lng: 20.4961187 },
    { lat: 49.1690427, lng: 20.4972339 },
    { lat: 49.1688297, lng: 20.4981431 },
    { lat: 49.1686291, lng: 20.4991941 },
    { lat: 49.1683608, lng: 20.5013993 },
    { lat: 49.1684123, lng: 20.5013941 },
    { lat: 49.1702539, lng: 20.5034188 },
    { lat: 49.1716204, lng: 20.5053547 },
    { lat: 49.1717721, lng: 20.5056809 },
    { lat: 49.1718338, lng: 20.505709 },
    { lat: 49.1716798, lng: 20.506321 },
    { lat: 49.1714179, lng: 20.506576 },
    { lat: 49.1712314, lng: 20.5070746 },
    { lat: 49.1712134, lng: 20.5076774 },
    { lat: 49.1712885, lng: 20.5079717 },
    { lat: 49.1712292, lng: 20.5081034 },
    { lat: 49.1712667, lng: 20.5084226 },
    { lat: 49.1712349, lng: 20.5088693 },
    { lat: 49.1711799, lng: 20.5090522 },
    { lat: 49.1711041, lng: 20.509013 },
    { lat: 49.1710363, lng: 20.5090688 },
    { lat: 49.1710549, lng: 20.5092975 },
    { lat: 49.1707815, lng: 20.5097294 },
    { lat: 49.1706793, lng: 20.5102193 },
    { lat: 49.1720564, lng: 20.5115989 },
    { lat: 49.172066, lng: 20.5116092 },
  ],
  Kežmarok: [
    { lat: 49.1720808, lng: 20.4209548 },
    { lat: 49.1690447, lng: 20.4182839 },
    { lat: 49.1689588, lng: 20.418634 },
    { lat: 49.1688272, lng: 20.4188723 },
    { lat: 49.1683164, lng: 20.4195652 },
    { lat: 49.1681729, lng: 20.419675 },
    { lat: 49.1677335, lng: 20.4198403 },
    { lat: 49.1673678, lng: 20.4198465 },
    { lat: 49.1665661, lng: 20.4195457 },
    { lat: 49.1662714, lng: 20.4192291 },
    { lat: 49.1661018, lng: 20.4192098 },
    { lat: 49.1660515, lng: 20.4191276 },
    { lat: 49.166198, lng: 20.418983 },
    { lat: 49.1648341, lng: 20.416918 },
    { lat: 49.1644341, lng: 20.4174155 },
    { lat: 49.1631954, lng: 20.4156242 },
    { lat: 49.1627024, lng: 20.4150031 },
    { lat: 49.1623911, lng: 20.4157353 },
    { lat: 49.1617536, lng: 20.4147434 },
    { lat: 49.1611262, lng: 20.413963 },
    { lat: 49.1601794, lng: 20.4129293 },
    { lat: 49.1582228, lng: 20.4109727 },
    { lat: 49.1581227, lng: 20.4114744 },
    { lat: 49.1562689, lng: 20.4100089 },
    { lat: 49.1552709, lng: 20.4094028 },
    { lat: 49.1552063, lng: 20.4093636 },
    { lat: 49.1551582, lng: 20.4092197 },
    { lat: 49.1549189, lng: 20.4089652 },
    { lat: 49.1548197, lng: 20.408402 },
    { lat: 49.154817, lng: 20.4080861 },
    { lat: 49.1549353, lng: 20.4072922 },
    { lat: 49.1549982, lng: 20.4061579 },
    { lat: 49.1548658, lng: 20.4060199 },
    { lat: 49.1547719, lng: 20.4056538 },
    { lat: 49.15348, lng: 20.4044868 },
    { lat: 49.1535089, lng: 20.4043846 },
    { lat: 49.1523755, lng: 20.4032102 },
    { lat: 49.1516441, lng: 20.4025705 },
    { lat: 49.1516601, lng: 20.4025116 },
    { lat: 49.1514275, lng: 20.4022594 },
    { lat: 49.152114, lng: 20.4013931 },
    { lat: 49.1536787, lng: 20.3990997 },
    { lat: 49.1551806, lng: 20.3961933 },
    { lat: 49.1565912, lng: 20.3936477 },
    { lat: 49.1572933, lng: 20.3925676 },
    { lat: 49.1582304, lng: 20.3913638 },
    { lat: 49.1585395, lng: 20.3908763 },
    { lat: 49.1593902, lng: 20.3891757 },
    { lat: 49.1596674, lng: 20.3886223 },
    { lat: 49.1607677, lng: 20.3863858 },
    { lat: 49.1608831, lng: 20.3858331 },
    { lat: 49.1600285, lng: 20.3855415 },
    { lat: 49.1571301, lng: 20.3854458 },
    { lat: 49.156854, lng: 20.3853497 },
    { lat: 49.1568919, lng: 20.3848352 },
    { lat: 49.156839, lng: 20.3840665 },
    { lat: 49.1568032, lng: 20.3839556 },
    { lat: 49.1562194, lng: 20.3837062 },
    { lat: 49.156356, lng: 20.3831851 },
    { lat: 49.154221, lng: 20.3823503 },
    { lat: 49.1542653, lng: 20.3819464 },
    { lat: 49.1522266, lng: 20.3811876 },
    { lat: 49.149947, lng: 20.3796669 },
    { lat: 49.1499378, lng: 20.3796612 },
    { lat: 49.1496817, lng: 20.3806223 },
    { lat: 49.1493168, lng: 20.3817071 },
    { lat: 49.1487389, lng: 20.3826435 },
    { lat: 49.1484502, lng: 20.3836046 },
    { lat: 49.1481465, lng: 20.3841494 },
    { lat: 49.147884, lng: 20.3847952 },
    { lat: 49.1468377, lng: 20.386971 },
    { lat: 49.1459233, lng: 20.3882101 },
    { lat: 49.1440034, lng: 20.3916034 },
    { lat: 49.1428027, lng: 20.3934577 },
    { lat: 49.1422735, lng: 20.3948761 },
    { lat: 49.141967, lng: 20.3945414 },
    { lat: 49.1418466, lng: 20.3944845 },
    { lat: 49.1416046, lng: 20.3951999 },
    { lat: 49.1405338, lng: 20.3941276 },
    { lat: 49.1403677, lng: 20.3945321 },
    { lat: 49.1401157, lng: 20.3943275 },
    { lat: 49.1399651, lng: 20.3947227 },
    { lat: 49.13907, lng: 20.3946594 },
    { lat: 49.1382664, lng: 20.3944309 },
    { lat: 49.1375997, lng: 20.3943953 },
    { lat: 49.1366877, lng: 20.3942184 },
    { lat: 49.1363373, lng: 20.3942742 },
    { lat: 49.1351975, lng: 20.3948258 },
    { lat: 49.134013, lng: 20.3952394 },
    { lat: 49.1340118, lng: 20.395306 },
    { lat: 49.1340112, lng: 20.3953395 },
    { lat: 49.1337205, lng: 20.3953325 },
    { lat: 49.1335168, lng: 20.3952196 },
    { lat: 49.1331347, lng: 20.3952088 },
    { lat: 49.1322988, lng: 20.3947814 },
    { lat: 49.1316003, lng: 20.394178 },
    { lat: 49.1313366, lng: 20.393845 },
    { lat: 49.1308792, lng: 20.3930707 },
    { lat: 49.1304402, lng: 20.3920395 },
    { lat: 49.1303634, lng: 20.3918809 },
    { lat: 49.1298377, lng: 20.3906403 },
    { lat: 49.1283159, lng: 20.3921346 },
    { lat: 49.1274962, lng: 20.3927819 },
    { lat: 49.1261626, lng: 20.3936598 },
    { lat: 49.1255278, lng: 20.3945492 },
    { lat: 49.1252986, lng: 20.3950681 },
    { lat: 49.1246375, lng: 20.3957132 },
    { lat: 49.1248389, lng: 20.3964493 },
    { lat: 49.12512, lng: 20.397976 },
    { lat: 49.125558, lng: 20.3991331 },
    { lat: 49.1257452, lng: 20.3993786 },
    { lat: 49.1261516, lng: 20.3994945 },
    { lat: 49.1264187, lng: 20.399413 },
    { lat: 49.1266705, lng: 20.3992229 },
    { lat: 49.1268181, lng: 20.3992879 },
    { lat: 49.1269985, lng: 20.3996207 },
    { lat: 49.127061, lng: 20.399874 },
    { lat: 49.1270414, lng: 20.4003039 },
    { lat: 49.1268406, lng: 20.4015702 },
    { lat: 49.1268211, lng: 20.4021489 },
    { lat: 49.1267511, lng: 20.4024427 },
    { lat: 49.126803, lng: 20.4035422 },
    { lat: 49.1267012, lng: 20.4040372 },
    { lat: 49.126476, lng: 20.4046844 },
    { lat: 49.1268297, lng: 20.405731 },
    { lat: 49.1267902, lng: 20.4057514 },
    { lat: 49.1270244, lng: 20.4069139 },
    { lat: 49.1270396, lng: 20.4071638 },
    { lat: 49.1269527, lng: 20.4076356 },
    { lat: 49.1272469, lng: 20.4084111 },
    { lat: 49.1273951, lng: 20.4089675 },
    { lat: 49.1275146, lng: 20.4091489 },
    { lat: 49.1274339, lng: 20.4099886 },
    { lat: 49.1269522, lng: 20.4101145 },
    { lat: 49.1270475, lng: 20.4107877 },
    { lat: 49.1270263, lng: 20.4113544 },
    { lat: 49.1268416, lng: 20.4117981 },
    { lat: 49.1268838, lng: 20.4120507 },
    { lat: 49.1265447, lng: 20.4130264 },
    { lat: 49.1259439, lng: 20.4124771 },
    { lat: 49.1254706, lng: 20.4117796 },
    { lat: 49.1253574, lng: 20.4113765 },
    { lat: 49.1253879, lng: 20.4112343 },
    { lat: 49.1255919, lng: 20.4110262 },
    { lat: 49.1255709, lng: 20.4109209 },
    { lat: 49.1253236, lng: 20.4107515 },
    { lat: 49.1248711, lng: 20.4111134 },
    { lat: 49.1245741, lng: 20.4110321 },
    { lat: 49.1243049, lng: 20.4108207 },
    { lat: 49.1242209, lng: 20.4106548 },
    { lat: 49.1242007, lng: 20.4101756 },
    { lat: 49.1244956, lng: 20.4097024 },
    { lat: 49.12461, lng: 20.4092735 },
    { lat: 49.1243786, lng: 20.409021 },
    { lat: 49.1239668, lng: 20.4087934 },
    { lat: 49.1240186, lng: 20.4084973 },
    { lat: 49.1241582, lng: 20.408315 },
    { lat: 49.1244304, lng: 20.4082209 },
    { lat: 49.124523, lng: 20.407932 },
    { lat: 49.1244652, lng: 20.4078645 },
    { lat: 49.1243098, lng: 20.4079217 },
    { lat: 49.1241588, lng: 20.4078904 },
    { lat: 49.124025, lng: 20.4076545 },
    { lat: 49.124027, lng: 20.4075023 },
    { lat: 49.1241729, lng: 20.4072493 },
    { lat: 49.1241721, lng: 20.4071728 },
    { lat: 49.1240201, lng: 20.4072005 },
    { lat: 49.123962, lng: 20.4070313 },
    { lat: 49.1238935, lng: 20.4069809 },
    { lat: 49.1237014, lng: 20.4070456 },
    { lat: 49.1236, lng: 20.4074342 },
    { lat: 49.1235443, lng: 20.4075088 },
    { lat: 49.1234799, lng: 20.4072967 },
    { lat: 49.1233706, lng: 20.4072322 },
    { lat: 49.1232259, lng: 20.4074237 },
    { lat: 49.1230287, lng: 20.4073925 },
    { lat: 49.1229976, lng: 20.4073151 },
    { lat: 49.1231633, lng: 20.4069931 },
    { lat: 49.1232375, lng: 20.4066052 },
    { lat: 49.1231334, lng: 20.406527 },
    { lat: 49.1229794, lng: 20.4062429 },
    { lat: 49.1231148, lng: 20.4059509 },
    { lat: 49.1229195, lng: 20.4057597 },
    { lat: 49.1228738, lng: 20.405539 },
    { lat: 49.1228125, lng: 20.4055207 },
    { lat: 49.1227587, lng: 20.40562 },
    { lat: 49.1225938, lng: 20.4057269 },
    { lat: 49.1225189, lng: 20.4057044 },
    { lat: 49.1224756, lng: 20.4052783 },
    { lat: 49.1223462, lng: 20.4051322 },
    { lat: 49.121848, lng: 20.405532 },
    { lat: 49.1217389, lng: 20.4054507 },
    { lat: 49.1217387, lng: 20.4050848 },
    { lat: 49.1216685, lng: 20.4050194 },
    { lat: 49.1214792, lng: 20.4045691 },
    { lat: 49.1214036, lng: 20.4045338 },
    { lat: 49.1212941, lng: 20.4046469 },
    { lat: 49.1210461, lng: 20.4044901 },
    { lat: 49.1207755, lng: 20.4044461 },
    { lat: 49.1206667, lng: 20.4042849 },
    { lat: 49.1205976, lng: 20.404273 },
    { lat: 49.1203384, lng: 20.4044127 },
    { lat: 49.1203092, lng: 20.4045073 },
    { lat: 49.1203225, lng: 20.4045579 },
    { lat: 49.1204311, lng: 20.4045415 },
    { lat: 49.1205707, lng: 20.4048685 },
    { lat: 49.120538, lng: 20.4049846 },
    { lat: 49.1202999, lng: 20.4050567 },
    { lat: 49.1199221, lng: 20.4054289 },
    { lat: 49.119809, lng: 20.4054446 },
    { lat: 49.1197673, lng: 20.4067197 },
    { lat: 49.1196387, lng: 20.4076024 },
    { lat: 49.119645, lng: 20.4086877 },
    { lat: 49.1195752, lng: 20.4096414 },
    { lat: 49.1196142, lng: 20.410519 },
    { lat: 49.1195864, lng: 20.411094 },
    { lat: 49.1194868, lng: 20.4116711 },
    { lat: 49.1191782, lng: 20.4126803 },
    { lat: 49.1190866, lng: 20.4133206 },
    { lat: 49.1192015, lng: 20.4136793 },
    { lat: 49.1192723, lng: 20.4137363 },
    { lat: 49.1194617, lng: 20.4144256 },
    { lat: 49.1196901, lng: 20.4155795 },
    { lat: 49.1197711, lng: 20.4164103 },
    { lat: 49.1197681, lng: 20.4165978 },
    { lat: 49.1197106, lng: 20.4165996 },
    { lat: 49.1197019, lng: 20.4169926 },
    { lat: 49.1199597, lng: 20.4187168 },
    { lat: 49.1199592, lng: 20.4187604 },
    { lat: 49.1205482, lng: 20.4185271 },
    { lat: 49.1209544, lng: 20.4185255 },
    { lat: 49.1212918, lng: 20.4183949 },
    { lat: 49.1216795, lng: 20.4183723 },
    { lat: 49.1219495, lng: 20.4185157 },
    { lat: 49.1228925, lng: 20.4195124 },
    { lat: 49.1240759, lng: 20.4200565 },
    { lat: 49.1249575, lng: 20.4249382 },
    { lat: 49.1256023, lng: 20.4274599 },
    { lat: 49.1257654, lng: 20.4292832 },
    { lat: 49.1258149, lng: 20.4298228 },
    { lat: 49.1271172, lng: 20.4300675 },
    { lat: 49.1270832, lng: 20.4306597 },
    { lat: 49.1269606, lng: 20.4314686 },
    { lat: 49.1269087, lng: 20.4323873 },
    { lat: 49.1275966, lng: 20.4349011 },
    { lat: 49.1276344, lng: 20.4348572 },
    { lat: 49.1277793, lng: 20.4352345 },
    { lat: 49.1276529, lng: 20.4354929 },
    { lat: 49.1274165, lng: 20.4357358 },
    { lat: 49.1276618, lng: 20.4365987 },
    { lat: 49.1277823, lng: 20.4367478 },
    { lat: 49.1278968, lng: 20.4372422 },
    { lat: 49.127691, lng: 20.4373425 },
    { lat: 49.1279276, lng: 20.4382432 },
    { lat: 49.1276957, lng: 20.4383296 },
    { lat: 49.1277634, lng: 20.4387558 },
    { lat: 49.1273023, lng: 20.4390112 },
    { lat: 49.1273533, lng: 20.4392544 },
    { lat: 49.1280816, lng: 20.4388919 },
    { lat: 49.1288912, lng: 20.4386885 },
    { lat: 49.1294994, lng: 20.4384555 },
    { lat: 49.1294427, lng: 20.4382027 },
    { lat: 49.12975, lng: 20.4381139 },
    { lat: 49.1297845, lng: 20.4382519 },
    { lat: 49.1302634, lng: 20.4379622 },
    { lat: 49.1305981, lng: 20.4390755 },
    { lat: 49.1306788, lng: 20.439634 },
    { lat: 49.1308434, lng: 20.4402955 },
    { lat: 49.1310252, lng: 20.4402814 },
    { lat: 49.1308627, lng: 20.4396172 },
    { lat: 49.1310695, lng: 20.4394589 },
    { lat: 49.1310972, lng: 20.43956 },
    { lat: 49.1317446, lng: 20.4390288 },
    { lat: 49.1317935, lng: 20.4390054 },
    { lat: 49.1321775, lng: 20.43878 },
    { lat: 49.1329205, lng: 20.4383226 },
    { lat: 49.1333817, lng: 20.4396248 },
    { lat: 49.1334694, lng: 20.4407321 },
    { lat: 49.1335251, lng: 20.4407139 },
    { lat: 49.1335326, lng: 20.4407923 },
    { lat: 49.1338356, lng: 20.440763 },
    { lat: 49.1338705, lng: 20.4409458 },
    { lat: 49.1340437, lng: 20.4412391 },
    { lat: 49.1350723, lng: 20.4419154 },
    { lat: 49.1352617, lng: 20.4422228 },
    { lat: 49.1353333, lng: 20.4424815 },
    { lat: 49.1353358, lng: 20.4429059 },
    { lat: 49.1352438, lng: 20.4435152 },
    { lat: 49.1351656, lng: 20.443769 },
    { lat: 49.1349202, lng: 20.4441008 },
    { lat: 49.1347708, lng: 20.4444371 },
    { lat: 49.1347258, lng: 20.4448268 },
    { lat: 49.134735, lng: 20.4453984 },
    { lat: 49.1349294, lng: 20.4458679 },
    { lat: 49.1349957, lng: 20.446195 },
    { lat: 49.1349592, lng: 20.4465118 },
    { lat: 49.1346906, lng: 20.4471853 },
    { lat: 49.1343361, lng: 20.4476948 },
    { lat: 49.1343568, lng: 20.4477387 },
    { lat: 49.1344034, lng: 20.4476925 },
    { lat: 49.1347676, lng: 20.448122 },
    { lat: 49.1349675, lng: 20.4479924 },
    { lat: 49.1350381, lng: 20.4480387 },
    { lat: 49.1351604, lng: 20.4479968 },
    { lat: 49.1354948, lng: 20.4483875 },
    { lat: 49.1358468, lng: 20.4489792 },
    { lat: 49.1361672, lng: 20.4501644 },
    { lat: 49.1364308, lng: 20.450676 },
    { lat: 49.1367315, lng: 20.451013 },
    { lat: 49.1374592, lng: 20.4517114 },
    { lat: 49.1379936, lng: 20.4520596 },
    { lat: 49.1391758, lng: 20.4525415 },
    { lat: 49.1398559, lng: 20.4537102 },
    { lat: 49.1410956, lng: 20.456418 },
    { lat: 49.1415416, lng: 20.4566575 },
    { lat: 49.1438697, lng: 20.4570742 },
    { lat: 49.144441, lng: 20.4572988 },
    { lat: 49.1448699, lng: 20.4564537 },
    { lat: 49.1456467, lng: 20.4553136 },
    { lat: 49.1463679, lng: 20.4538036 },
    { lat: 49.1466923, lng: 20.4530243 },
    { lat: 49.1466614, lng: 20.4529245 },
    { lat: 49.146795, lng: 20.452819 },
    { lat: 49.1468602, lng: 20.4526403 },
    { lat: 49.14672, lng: 20.4521156 },
    { lat: 49.1468431, lng: 20.452065 },
    { lat: 49.1472644, lng: 20.4520681 },
    { lat: 49.1476968, lng: 20.4522509 },
    { lat: 49.1484425, lng: 20.4524433 },
    { lat: 49.1489045, lng: 20.452421 },
    { lat: 49.1500185, lng: 20.4519292 },
    { lat: 49.1501788, lng: 20.4524778 },
    { lat: 49.1502991, lng: 20.4523845 },
    { lat: 49.1503929, lng: 20.452512 },
    { lat: 49.1507986, lng: 20.4527924 },
    { lat: 49.1511713, lng: 20.4531249 },
    { lat: 49.1514789, lng: 20.4535836 },
    { lat: 49.1515758, lng: 20.4540661 },
    { lat: 49.1515852, lng: 20.4544599 },
    { lat: 49.1516896, lng: 20.4549032 },
    { lat: 49.152499, lng: 20.4561048 },
    { lat: 49.1530774, lng: 20.4566349 },
    { lat: 49.1528555, lng: 20.4575635 },
    { lat: 49.1522367, lng: 20.4596151 },
    { lat: 49.1525062, lng: 20.4598394 },
    { lat: 49.152569, lng: 20.4602245 },
    { lat: 49.152474, lng: 20.4609637 },
    { lat: 49.1521371, lng: 20.462759 },
    { lat: 49.1519872, lng: 20.4639903 },
    { lat: 49.1517466, lng: 20.4648992 },
    { lat: 49.1511795, lng: 20.4662297 },
    { lat: 49.1516713, lng: 20.4670404 },
    { lat: 49.1521313, lng: 20.4675068 },
    { lat: 49.1524973, lng: 20.468171 },
    { lat: 49.1525304, lng: 20.4683361 },
    { lat: 49.1523574, lng: 20.468286 },
    { lat: 49.1523469, lng: 20.4683635 },
    { lat: 49.1524034, lng: 20.4684309 },
    { lat: 49.1523454, lng: 20.4686827 },
    { lat: 49.152196, lng: 20.468727 },
    { lat: 49.1521785, lng: 20.4685853 },
    { lat: 49.1521148, lng: 20.4685103 },
    { lat: 49.1519521, lng: 20.4687971 },
    { lat: 49.1517795, lng: 20.468906 },
    { lat: 49.1517902, lng: 20.4691028 },
    { lat: 49.1518572, lng: 20.4691805 },
    { lat: 49.1519276, lng: 20.4691384 },
    { lat: 49.1519881, lng: 20.469186 },
    { lat: 49.1519543, lng: 20.4693983 },
    { lat: 49.1520214, lng: 20.4695323 },
    { lat: 49.1517612, lng: 20.4695014 },
    { lat: 49.1514856, lng: 20.469626 },
    { lat: 49.151455, lng: 20.4695035 },
    { lat: 49.1513553, lng: 20.4694546 },
    { lat: 49.1513063, lng: 20.4695632 },
    { lat: 49.1514745, lng: 20.4702334 },
    { lat: 49.1516132, lng: 20.4703904 },
    { lat: 49.1514845, lng: 20.4706842 },
    { lat: 49.1513903, lng: 20.4706928 },
    { lat: 49.1514764, lng: 20.4710087 },
    { lat: 49.1512086, lng: 20.4713164 },
    { lat: 49.1513002, lng: 20.4714761 },
    { lat: 49.1513163, lng: 20.4716052 },
    { lat: 49.151242, lng: 20.4716674 },
    { lat: 49.151203, lng: 20.4715579 },
    { lat: 49.1511386, lng: 20.471794 },
    { lat: 49.151123, lng: 20.4720374 },
    { lat: 49.1511816, lng: 20.4720971 },
    { lat: 49.1512057, lng: 20.4722946 },
    { lat: 49.151092, lng: 20.4723523 },
    { lat: 49.1510416, lng: 20.4721193 },
    { lat: 49.1509403, lng: 20.4722054 },
    { lat: 49.1510552, lng: 20.4726299 },
    { lat: 49.1508845, lng: 20.4727965 },
    { lat: 49.1508725, lng: 20.4729242 },
    { lat: 49.1507942, lng: 20.4729108 },
    { lat: 49.1508402, lng: 20.4730561 },
    { lat: 49.1508208, lng: 20.4732006 },
    { lat: 49.150718, lng: 20.4730056 },
    { lat: 49.1506197, lng: 20.4730896 },
    { lat: 49.1506063, lng: 20.4732426 },
    { lat: 49.1506738, lng: 20.4733004 },
    { lat: 49.1506509, lng: 20.4734153 },
    { lat: 49.1507078, lng: 20.4735154 },
    { lat: 49.1506721, lng: 20.4736224 },
    { lat: 49.150539, lng: 20.4735185 },
    { lat: 49.1503793, lng: 20.4737104 },
    { lat: 49.1503424, lng: 20.4738327 },
    { lat: 49.1503756, lng: 20.4739697 },
    { lat: 49.1504938, lng: 20.4739716 },
    { lat: 49.1504629, lng: 20.4741337 },
    { lat: 49.1503883, lng: 20.4742462 },
    { lat: 49.1502889, lng: 20.4742398 },
    { lat: 49.1502614, lng: 20.4744069 },
    { lat: 49.15, lng: 20.4745245 },
    { lat: 49.149915, lng: 20.4749282 },
    { lat: 49.1497894, lng: 20.4750334 },
    { lat: 49.1498575, lng: 20.4751393 },
    { lat: 49.1498676, lng: 20.4752575 },
    { lat: 49.1498002, lng: 20.4753227 },
    { lat: 49.1496243, lng: 20.475225 },
    { lat: 49.1495623, lng: 20.4752832 },
    { lat: 49.1495951, lng: 20.4754099 },
    { lat: 49.1495083, lng: 20.4756174 },
    { lat: 49.1495151, lng: 20.4757358 },
    { lat: 49.1493721, lng: 20.475768 },
    { lat: 49.1492864, lng: 20.4760053 },
    { lat: 49.1493261, lng: 20.4762226 },
    { lat: 49.1492652, lng: 20.4764621 },
    { lat: 49.1491611, lng: 20.4765099 },
    { lat: 49.1491933, lng: 20.476647 },
    { lat: 49.1494255, lng: 20.4770214 },
    { lat: 49.1501941, lng: 20.477733 },
    { lat: 49.1508808, lng: 20.4786363 },
    { lat: 49.1520667, lng: 20.4798232 },
    { lat: 49.1525213, lng: 20.4801215 },
    { lat: 49.153409, lng: 20.4813092 },
    { lat: 49.1537503, lng: 20.4820961 },
    { lat: 49.1542829, lng: 20.4836696 },
    { lat: 49.155273, lng: 20.484169 },
    { lat: 49.1556873, lng: 20.4842263 },
    { lat: 49.1570462, lng: 20.4863747 },
    { lat: 49.1574456, lng: 20.4872494 },
    { lat: 49.1576158, lng: 20.4879473 },
    { lat: 49.1573301, lng: 20.4887964 },
    { lat: 49.1578578, lng: 20.4900892 },
    { lat: 49.1587427, lng: 20.4911551 },
    { lat: 49.15922, lng: 20.492816 },
    { lat: 49.1590317, lng: 20.4931075 },
    { lat: 49.1601281, lng: 20.495834 },
    { lat: 49.1609513, lng: 20.4988076 },
    { lat: 49.1612533, lng: 20.4986938 },
    { lat: 49.1624624, lng: 20.498512 },
    { lat: 49.1629801, lng: 20.4986911 },
    { lat: 49.1636424, lng: 20.499031 },
    { lat: 49.1637421, lng: 20.4989911 },
    { lat: 49.1638627, lng: 20.49908 },
    { lat: 49.1647707, lng: 20.5005543 },
    { lat: 49.1668555, lng: 20.5012159 },
    { lat: 49.167131, lng: 20.501509 },
    { lat: 49.1683483, lng: 20.5014004 },
    { lat: 49.1683608, lng: 20.5013993 },
    { lat: 49.1686291, lng: 20.4991941 },
    { lat: 49.1688297, lng: 20.4981431 },
    { lat: 49.1690427, lng: 20.4972339 },
    { lat: 49.1695359, lng: 20.4961187 },
    { lat: 49.1698972, lng: 20.4941155 },
    { lat: 49.1698354, lng: 20.4929986 },
    { lat: 49.1695932, lng: 20.4919344 },
    { lat: 49.1694832, lng: 20.4911855 },
    { lat: 49.169702, lng: 20.4901084 },
    { lat: 49.1697593, lng: 20.488257 },
    { lat: 49.1693124, lng: 20.4871703 },
    { lat: 49.1684589, lng: 20.4862926 },
    { lat: 49.1681948, lng: 20.4844259 },
    { lat: 49.1675359, lng: 20.4833324 },
    { lat: 49.1678207, lng: 20.4817624 },
    { lat: 49.1682531, lng: 20.4798307 },
    { lat: 49.1682926, lng: 20.4777772 },
    { lat: 49.168213, lng: 20.4763407 },
    { lat: 49.1688188, lng: 20.4756185 },
    { lat: 49.1690442, lng: 20.4751096 },
    { lat: 49.1693972, lng: 20.474164 },
    { lat: 49.1695826, lng: 20.4734301 },
    { lat: 49.1699045, lng: 20.4724653 },
    { lat: 49.1704138, lng: 20.4704561 },
    { lat: 49.1705864, lng: 20.4700997 },
    { lat: 49.1711458, lng: 20.4692872 },
    { lat: 49.1715543, lng: 20.4683766 },
    { lat: 49.1717099, lng: 20.4682019 },
    { lat: 49.1718208, lng: 20.4680699 },
    { lat: 49.171436, lng: 20.465457 },
    { lat: 49.1714956, lng: 20.4650322 },
    { lat: 49.1717337, lng: 20.4643847 },
    { lat: 49.1718098, lng: 20.4637711 },
    { lat: 49.1717486, lng: 20.4627089 },
    { lat: 49.1716403, lng: 20.46213 },
    { lat: 49.1715433, lng: 20.4618265 },
    { lat: 49.1712631, lng: 20.4611342 },
    { lat: 49.171019, lng: 20.4602681 },
    { lat: 49.1709376, lng: 20.4592095 },
    { lat: 49.1707411, lng: 20.4591665 },
    { lat: 49.1697644, lng: 20.4598957 },
    { lat: 49.1695491, lng: 20.4592891 },
    { lat: 49.1692725, lng: 20.4587015 },
    { lat: 49.1687848, lng: 20.4572207 },
    { lat: 49.1682485, lng: 20.4560992 },
    { lat: 49.1680976, lng: 20.4555907 },
    { lat: 49.1684746, lng: 20.45513 },
    { lat: 49.1684823, lng: 20.4548908 },
    { lat: 49.1684342, lng: 20.4543557 },
    { lat: 49.1683414, lng: 20.4540468 },
    { lat: 49.1683329, lng: 20.4533985 },
    { lat: 49.1671944, lng: 20.4530272 },
    { lat: 49.166817, lng: 20.4530253 },
    { lat: 49.1657221, lng: 20.4527675 },
    { lat: 49.1657431, lng: 20.4523951 },
    { lat: 49.165821, lng: 20.4520775 },
    { lat: 49.166409, lng: 20.4505205 },
    { lat: 49.1663695, lng: 20.4502206 },
    { lat: 49.1665458, lng: 20.4500865 },
    { lat: 49.1666362, lng: 20.4498865 },
    { lat: 49.166883, lng: 20.448931 },
    { lat: 49.1670228, lng: 20.4487899 },
    { lat: 49.1670694, lng: 20.4486477 },
    { lat: 49.1671648, lng: 20.4486146 },
    { lat: 49.1672549, lng: 20.4484045 },
    { lat: 49.1672701, lng: 20.4482377 },
    { lat: 49.1673409, lng: 20.4482084 },
    { lat: 49.1676976, lng: 20.4464294 },
    { lat: 49.1688358, lng: 20.4431073 },
    { lat: 49.1688567, lng: 20.4427327 },
    { lat: 49.1687391, lng: 20.4413455 },
    { lat: 49.1687506, lng: 20.4410477 },
    { lat: 49.1688739, lng: 20.4405566 },
    { lat: 49.1696603, lng: 20.4383581 },
    { lat: 49.169832, lng: 20.4364584 },
    { lat: 49.1705624, lng: 20.4348979 },
    { lat: 49.1710023, lng: 20.4334867 },
    { lat: 49.1713612, lng: 20.4328486 },
    { lat: 49.1708068, lng: 20.4325374 },
    { lat: 49.1708427, lng: 20.4324432 },
    { lat: 49.1688114, lng: 20.4316074 },
    { lat: 49.1690031, lng: 20.4307458 },
    { lat: 49.1666552, lng: 20.4299896 },
    { lat: 49.1672469, lng: 20.428871 },
    { lat: 49.1675635, lng: 20.4281006 },
    { lat: 49.1676997, lng: 20.4280962 },
    { lat: 49.1684429, lng: 20.4260715 },
    { lat: 49.1689874, lng: 20.4257966 },
    { lat: 49.1696008, lng: 20.4248037 },
    { lat: 49.1706772, lng: 20.4233436 },
    { lat: 49.1719365, lng: 20.4212187 },
    { lat: 49.1720808, lng: 20.4209548 },
  ],
  Reľov: [
    { lat: 49.3013343, lng: 20.3793764 },
    { lat: 49.3014925, lng: 20.378709 },
    { lat: 49.301803, lng: 20.3784999 },
    { lat: 49.3022103, lng: 20.3779885 },
    { lat: 49.3035806, lng: 20.3751517 },
    { lat: 49.3039854, lng: 20.3734513 },
    { lat: 49.3041473, lng: 20.3725003 },
    { lat: 49.3040332, lng: 20.3723474 },
    { lat: 49.3051046, lng: 20.3699118 },
    { lat: 49.3055398, lng: 20.3691589 },
    { lat: 49.3058701, lng: 20.3687279 },
    { lat: 49.3066882, lng: 20.3680694 },
    { lat: 49.3066599, lng: 20.3679156 },
    { lat: 49.3068905, lng: 20.3677627 },
    { lat: 49.3070947, lng: 20.3674507 },
    { lat: 49.3072137, lng: 20.3672615 },
    { lat: 49.3073145, lng: 20.3668982 },
    { lat: 49.3067854, lng: 20.3663053 },
    { lat: 49.3066231, lng: 20.3660095 },
    { lat: 49.3060383, lng: 20.3647253 },
    { lat: 49.3056232, lng: 20.3639376 },
    { lat: 49.3054326, lng: 20.3634007 },
    { lat: 49.3041029, lng: 20.3602742 },
    { lat: 49.3039742, lng: 20.3599707 },
    { lat: 49.3019858, lng: 20.3596115 },
    { lat: 49.3017647, lng: 20.3594881 },
    { lat: 49.3011868, lng: 20.3593301 },
    { lat: 49.300815, lng: 20.359293 },
    { lat: 49.2995287, lng: 20.3593657 },
    { lat: 49.2979364, lng: 20.3591256 },
    { lat: 49.2974922, lng: 20.3588428 },
    { lat: 49.2965439, lng: 20.3578901 },
    { lat: 49.2960796, lng: 20.3575023 },
    { lat: 49.2956579, lng: 20.3567457 },
    { lat: 49.2954217, lng: 20.3566333 },
    { lat: 49.2951153, lng: 20.3562773 },
    { lat: 49.2943662, lng: 20.3556167 },
    { lat: 49.2935411, lng: 20.3553319 },
    { lat: 49.2929705, lng: 20.3553963 },
    { lat: 49.2920679, lng: 20.3551964 },
    { lat: 49.2914014, lng: 20.3551712 },
    { lat: 49.2910103, lng: 20.3550677 },
    { lat: 49.2900933, lng: 20.3545282 },
    { lat: 49.2896671, lng: 20.3543636 },
    { lat: 49.2884125, lng: 20.3533855 },
    { lat: 49.2872354, lng: 20.3521956 },
    { lat: 49.2866412, lng: 20.3521994 },
    { lat: 49.285395, lng: 20.3514406 },
    { lat: 49.2848904, lng: 20.3512874 },
    { lat: 49.2843536, lng: 20.3510019 },
    { lat: 49.2832615, lng: 20.3498835 },
    { lat: 49.2826754, lng: 20.3495721 },
    { lat: 49.2822585, lng: 20.3492712 },
    { lat: 49.2813081, lng: 20.3483591 },
    { lat: 49.2810146, lng: 20.3478982 },
    { lat: 49.280959, lng: 20.3474747 },
    { lat: 49.2806503, lng: 20.3471154 },
    { lat: 49.280382, lng: 20.3466948 },
    { lat: 49.2798485, lng: 20.3460841 },
    { lat: 49.2787668, lng: 20.3453307 },
    { lat: 49.2784868, lng: 20.3449548 },
    { lat: 49.2769297, lng: 20.3436664 },
    { lat: 49.2757192, lng: 20.3424313 },
    { lat: 49.2755644, lng: 20.3428855 },
    { lat: 49.2754766, lng: 20.3433589 },
    { lat: 49.2754058, lng: 20.3441816 },
    { lat: 49.2753616, lng: 20.3449677 },
    { lat: 49.2753568, lng: 20.3481016 },
    { lat: 49.2751671, lng: 20.3485279 },
    { lat: 49.2750412, lng: 20.3493851 },
    { lat: 49.2748966, lng: 20.3499259 },
    { lat: 49.2747255, lng: 20.3503134 },
    { lat: 49.2745942, lng: 20.3507654 },
    { lat: 49.2743308, lng: 20.3512927 },
    { lat: 49.2740975, lng: 20.3516381 },
    { lat: 49.2737261, lng: 20.3519385 },
    { lat: 49.2734752, lng: 20.3527062 },
    { lat: 49.2734694, lng: 20.3529379 },
    { lat: 49.2733595, lng: 20.3532167 },
    { lat: 49.2733178, lng: 20.3535367 },
    { lat: 49.2719793, lng: 20.3556005 },
    { lat: 49.270554, lng: 20.3572758 },
    { lat: 49.2705018, lng: 20.3581515 },
    { lat: 49.2700842, lng: 20.3615376 },
    { lat: 49.2701205, lng: 20.3629639 },
    { lat: 49.2715336, lng: 20.3663266 },
    { lat: 49.2729195, lng: 20.3698014 },
    { lat: 49.2729261, lng: 20.3700049 },
    { lat: 49.2756793, lng: 20.377047 },
    { lat: 49.2758189, lng: 20.3807439 },
    { lat: 49.2760461, lng: 20.3815368 },
    { lat: 49.2754089, lng: 20.3828425 },
    { lat: 49.2751657, lng: 20.3859751 },
    { lat: 49.2749865, lng: 20.3865359 },
    { lat: 49.2759589, lng: 20.3914782 },
    { lat: 49.2766675, lng: 20.3938868 },
    { lat: 49.2771064, lng: 20.3955517 },
    { lat: 49.2781795, lng: 20.4002453 },
    { lat: 49.2796396, lng: 20.4055352 },
    { lat: 49.2797202, lng: 20.4057888 },
    { lat: 49.2806422, lng: 20.4073063 },
    { lat: 49.2823042, lng: 20.4094566 },
    { lat: 49.2833897, lng: 20.4104429 },
    { lat: 49.2863579, lng: 20.4157045 },
    { lat: 49.2875267, lng: 20.4179428 },
    { lat: 49.287974, lng: 20.4185814 },
    { lat: 49.2883876, lng: 20.4189204 },
    { lat: 49.2886022, lng: 20.4192928 },
    { lat: 49.2887485, lng: 20.4197451 },
    { lat: 49.2892215, lng: 20.4234172 },
    { lat: 49.28928, lng: 20.4243762 },
    { lat: 49.2896685, lng: 20.4267671 },
    { lat: 49.2900283, lng: 20.4278899 },
    { lat: 49.2900703, lng: 20.4278401 },
    { lat: 49.2902211, lng: 20.4276595 },
    { lat: 49.2911006, lng: 20.4273953 },
    { lat: 49.293637, lng: 20.428333 },
    { lat: 49.293831, lng: 20.427586 },
    { lat: 49.29385, lng: 20.427512 },
    { lat: 49.294244, lng: 20.425957 },
    { lat: 49.294849, lng: 20.42386 },
    { lat: 49.294854, lng: 20.423842 },
    { lat: 49.294858, lng: 20.423204 },
    { lat: 49.294859, lng: 20.423146 },
    { lat: 49.294978, lng: 20.422961 },
    { lat: 49.295313, lng: 20.422222 },
    { lat: 49.29546, lng: 20.422041 },
    { lat: 49.295575, lng: 20.42169 },
    { lat: 49.295619, lng: 20.421458 },
    { lat: 49.295687, lng: 20.421228 },
    { lat: 49.295977, lng: 20.421212 },
    { lat: 49.296082, lng: 20.420868 },
    { lat: 49.296169, lng: 20.420378 },
    { lat: 49.296175, lng: 20.420237 },
    { lat: 49.296037, lng: 20.420133 },
    { lat: 49.295953, lng: 20.419152 },
    { lat: 49.29603, lng: 20.418175 },
    { lat: 49.296251, lng: 20.41803 },
    { lat: 49.296402, lng: 20.417366 },
    { lat: 49.296451, lng: 20.417054 },
    { lat: 49.296558, lng: 20.416665 },
    { lat: 49.296818, lng: 20.415979 },
    { lat: 49.296853, lng: 20.415854 },
    { lat: 49.296952, lng: 20.415722 },
    { lat: 49.297125, lng: 20.415351 },
    { lat: 49.297298, lng: 20.415085 },
    { lat: 49.297618, lng: 20.4148 },
    { lat: 49.298051, lng: 20.414615 },
    { lat: 49.298212, lng: 20.414463 },
    { lat: 49.298756, lng: 20.414006 },
    { lat: 49.29885, lng: 20.41386 },
    { lat: 49.299024, lng: 20.413739 },
    { lat: 49.299159, lng: 20.413508 },
    { lat: 49.29923, lng: 20.41313 },
    { lat: 49.299721, lng: 20.412618 },
    { lat: 49.299773, lng: 20.412594 },
    { lat: 49.300082, lng: 20.41215 },
    { lat: 49.300072, lng: 20.412115 },
    { lat: 49.300148, lng: 20.412036 },
    { lat: 49.300244, lng: 20.411938 },
    { lat: 49.30041, lng: 20.411711 },
    { lat: 49.300398, lng: 20.411679 },
    { lat: 49.300553, lng: 20.411479 },
    { lat: 49.300742, lng: 20.411375 },
    { lat: 49.300928, lng: 20.411219 },
    { lat: 49.301364, lng: 20.410776 },
    { lat: 49.301586, lng: 20.410528 },
    { lat: 49.30164, lng: 20.410389 },
    { lat: 49.301781, lng: 20.410152 },
    { lat: 49.30184, lng: 20.410017 },
    { lat: 49.301898, lng: 20.409743 },
    { lat: 49.301967, lng: 20.40955 },
    { lat: 49.301878, lng: 20.409422 },
    { lat: 49.301986, lng: 20.409204 },
    { lat: 49.30213, lng: 20.409049 },
    { lat: 49.302251, lng: 20.408894 },
    { lat: 49.302525, lng: 20.408455 },
    { lat: 49.30254, lng: 20.408479 },
    { lat: 49.302743, lng: 20.408336 },
    { lat: 49.302838, lng: 20.408026 },
    { lat: 49.302837, lng: 20.407669 },
    { lat: 49.302782, lng: 20.407478 },
    { lat: 49.30277, lng: 20.407151 },
    { lat: 49.302735, lng: 20.406911 },
    { lat: 49.302629, lng: 20.406574 },
    { lat: 49.302623, lng: 20.406357 },
    { lat: 49.302653, lng: 20.406129 },
    { lat: 49.302659, lng: 20.405803 },
    { lat: 49.302705, lng: 20.405656 },
    { lat: 49.302795, lng: 20.405466 },
    { lat: 49.30286, lng: 20.405263 },
    { lat: 49.302864, lng: 20.405035 },
    { lat: 49.302941, lng: 20.40451 },
    { lat: 49.302873, lng: 20.404378 },
    { lat: 49.303188, lng: 20.40351 },
    { lat: 49.303138, lng: 20.403424 },
    { lat: 49.303437, lng: 20.403079 },
    { lat: 49.303598, lng: 20.402725 },
    { lat: 49.303556, lng: 20.402658 },
    { lat: 49.304011, lng: 20.402056 },
    { lat: 49.303998, lng: 20.402012 },
    { lat: 49.30406, lng: 20.401927 },
    { lat: 49.304324, lng: 20.401687 },
    { lat: 49.304527, lng: 20.401366 },
    { lat: 49.3045328, lng: 20.4012976 },
    { lat: 49.3039701, lng: 20.401035 },
    { lat: 49.3040175, lng: 20.3999059 },
    { lat: 49.3043415, lng: 20.3986812 },
    { lat: 49.3042856, lng: 20.3986641 },
    { lat: 49.3044051, lng: 20.3983119 },
    { lat: 49.304581, lng: 20.3979048 },
    { lat: 49.3047977, lng: 20.3976223 },
    { lat: 49.3051085, lng: 20.3970557 },
    { lat: 49.3053246, lng: 20.3964019 },
    { lat: 49.3056625, lng: 20.3959636 },
    { lat: 49.3057435, lng: 20.3960213 },
    { lat: 49.3059488, lng: 20.3959108 },
    { lat: 49.3064801, lng: 20.3952265 },
    { lat: 49.3066873, lng: 20.3954942 },
    { lat: 49.3067724, lng: 20.3954178 },
    { lat: 49.306866, lng: 20.3952735 },
    { lat: 49.306986, lng: 20.3949096 },
    { lat: 49.3069357, lng: 20.3947651 },
    { lat: 49.3072652, lng: 20.3944901 },
    { lat: 49.3073224, lng: 20.3943072 },
    { lat: 49.3073216, lng: 20.394112 },
    { lat: 49.3073698, lng: 20.3941637 },
    { lat: 49.3074375, lng: 20.3936587 },
    { lat: 49.3074134, lng: 20.3935591 },
    { lat: 49.3074824, lng: 20.3934166 },
    { lat: 49.3075397, lng: 20.3929125 },
    { lat: 49.3074946, lng: 20.3924655 },
    { lat: 49.307311, lng: 20.3918103 },
    { lat: 49.3074177, lng: 20.3915692 },
    { lat: 49.3074252, lng: 20.3913499 },
    { lat: 49.3073139, lng: 20.3906667 },
    { lat: 49.3074524, lng: 20.389896 },
    { lat: 49.3074422, lng: 20.3895404 },
    { lat: 49.3075464, lng: 20.3890545 },
    { lat: 49.3075049, lng: 20.3889683 },
    { lat: 49.3075632, lng: 20.3887575 },
    { lat: 49.3075905, lng: 20.3883762 },
    { lat: 49.3079677, lng: 20.3875807 },
    { lat: 49.3072816, lng: 20.3862944 },
    { lat: 49.3069713, lng: 20.3858561 },
    { lat: 49.3054929, lng: 20.3839288 },
    { lat: 49.3027356, lng: 20.3817065 },
    { lat: 49.3022631, lng: 20.381093 },
    { lat: 49.3013343, lng: 20.3793764 },
  ],
  Osturňa: [
    { lat: 49.3096727, lng: 20.2815523 },
    { lat: 49.3120081, lng: 20.2804315 },
    { lat: 49.3145301, lng: 20.2792973 },
    { lat: 49.3161562, lng: 20.2795633 },
    { lat: 49.3181964, lng: 20.2802016 },
    { lat: 49.3201747, lng: 20.2800548 },
    { lat: 49.3209176, lng: 20.2801666 },
    { lat: 49.3242772, lng: 20.2802475 },
    { lat: 49.3250005, lng: 20.27959 },
    { lat: 49.3262491, lng: 20.2802868 },
    { lat: 49.3267614, lng: 20.2806885 },
    { lat: 49.3277514, lng: 20.2804645 },
    { lat: 49.3280647, lng: 20.2798934 },
    { lat: 49.3293773, lng: 20.2800887 },
    { lat: 49.3303142, lng: 20.2801493 },
    { lat: 49.3312665, lng: 20.2805518 },
    { lat: 49.3318355, lng: 20.2804159 },
    { lat: 49.332366, lng: 20.2806243 },
    { lat: 49.3332551, lng: 20.2799466 },
    { lat: 49.3336588, lng: 20.2789773 },
    { lat: 49.3344643, lng: 20.2779212 },
    { lat: 49.3347134, lng: 20.2778946 },
    { lat: 49.335281, lng: 20.2772085 },
    { lat: 49.3354903, lng: 20.2769122 },
    { lat: 49.3356706, lng: 20.2764813 },
    { lat: 49.3363109, lng: 20.2758684 },
    { lat: 49.3364083, lng: 20.2758498 },
    { lat: 49.3377818, lng: 20.2747469 },
    { lat: 49.3398987, lng: 20.2727009 },
    { lat: 49.340408, lng: 20.2719814 },
    { lat: 49.3408985, lng: 20.2715713 },
    { lat: 49.3412496, lng: 20.2695103 },
    { lat: 49.3422484, lng: 20.2687324 },
    { lat: 49.3428099, lng: 20.2682034 },
    { lat: 49.3436609, lng: 20.2675906 },
    { lat: 49.3452191, lng: 20.2670709 },
    { lat: 49.3459697, lng: 20.2676942 },
    { lat: 49.3466365, lng: 20.2662475 },
    { lat: 49.3470731, lng: 20.2663955 },
    { lat: 49.347319, lng: 20.264958 },
    { lat: 49.3474385, lng: 20.2642833 },
    { lat: 49.347467, lng: 20.264117 },
    { lat: 49.3477588, lng: 20.262535 },
    { lat: 49.3474799, lng: 20.262109 },
    { lat: 49.3476165, lng: 20.2616602 },
    { lat: 49.3477059, lng: 20.2600028 },
    { lat: 49.3476717, lng: 20.2595789 },
    { lat: 49.3483376, lng: 20.258623 },
    { lat: 49.3486927, lng: 20.2578192 },
    { lat: 49.3487761, lng: 20.2568943 },
    { lat: 49.3489375, lng: 20.2561011 },
    { lat: 49.3490557, lng: 20.2549959 },
    { lat: 49.3489929, lng: 20.2546023 },
    { lat: 49.3489517, lng: 20.2544336 },
    { lat: 49.3489089, lng: 20.2542056 },
    { lat: 49.348906, lng: 20.2542 },
    { lat: 49.3489086, lng: 20.2539729 },
    { lat: 49.3494039, lng: 20.2534564 },
    { lat: 49.349723, lng: 20.253443 },
    { lat: 49.349751, lng: 20.2534424 },
    { lat: 49.3506927, lng: 20.2534483 },
    { lat: 49.350698, lng: 20.253446 },
    { lat: 49.3517581, lng: 20.2531904 },
    { lat: 49.3512356, lng: 20.252246 },
    { lat: 49.351009, lng: 20.251812 },
    { lat: 49.3505375, lng: 20.2509026 },
    { lat: 49.3499839, lng: 20.2495377 },
    { lat: 49.3497209, lng: 20.2483478 },
    { lat: 49.3497163, lng: 20.2477604 },
    { lat: 49.3499665, lng: 20.2469429 },
    { lat: 49.349967, lng: 20.246937 },
    { lat: 49.3502421, lng: 20.2462927 },
    { lat: 49.350245, lng: 20.246282 },
    { lat: 49.3506727, lng: 20.2453661 },
    { lat: 49.350956, lng: 20.2439719 },
    { lat: 49.3507451, lng: 20.2434878 },
    { lat: 49.3509714, lng: 20.2430403 },
    { lat: 49.3508439, lng: 20.2427507 },
    { lat: 49.3508651, lng: 20.2425059 },
    { lat: 49.3505245, lng: 20.2420135 },
    { lat: 49.3495893, lng: 20.2415095 },
    { lat: 49.3490906, lng: 20.2413533 },
    { lat: 49.3485008, lng: 20.2410334 },
    { lat: 49.3483394, lng: 20.2407232 },
    { lat: 49.3484978, lng: 20.2399813 },
    { lat: 49.3484337, lng: 20.2392509 },
    { lat: 49.3480239, lng: 20.2382846 },
    { lat: 49.3476009, lng: 20.2374245 },
    { lat: 49.347554, lng: 20.2368312 },
    { lat: 49.3475904, lng: 20.2358479 },
    { lat: 49.3475849, lng: 20.2353008 },
    { lat: 49.3472143, lng: 20.234524 },
    { lat: 49.3466533, lng: 20.2334882 },
    { lat: 49.3463553, lng: 20.2328597 },
    { lat: 49.3463209, lng: 20.2318153 },
    { lat: 49.3464453, lng: 20.2315699 },
    { lat: 49.346599, lng: 20.231263 },
    { lat: 49.3467, lng: 20.230934 },
    { lat: 49.346762, lng: 20.23058 },
    { lat: 49.346795, lng: 20.230274 },
    { lat: 49.346976, lng: 20.230019 },
    { lat: 49.347107, lng: 20.229884 },
    { lat: 49.347219, lng: 20.229681 },
    { lat: 49.34734, lng: 20.22951 },
    { lat: 49.347687, lng: 20.229083 },
    { lat: 49.347914, lng: 20.228973 },
    { lat: 49.348079, lng: 20.2287 },
    { lat: 49.348355, lng: 20.228383 },
    { lat: 49.348688, lng: 20.228204 },
    { lat: 49.348859, lng: 20.227858 },
    { lat: 49.34907, lng: 20.227676 },
    { lat: 49.349279, lng: 20.227494 },
    { lat: 49.349391, lng: 20.227271 },
    { lat: 49.349486, lng: 20.226815 },
    { lat: 49.349736, lng: 20.226157 },
    { lat: 49.350366, lng: 20.224914 },
    { lat: 49.351175, lng: 20.223159 },
    { lat: 49.351419, lng: 20.222886 },
    { lat: 49.351807, lng: 20.221876 },
    { lat: 49.351838, lng: 20.221428 },
    { lat: 49.35179, lng: 20.220823 },
    { lat: 49.351619, lng: 20.220274 },
    { lat: 49.3511038, lng: 20.2194415 },
    { lat: 49.3507665, lng: 20.2194064 },
    { lat: 49.3504152, lng: 20.2192542 },
    { lat: 49.3500747, lng: 20.2187246 },
    { lat: 49.3498084, lng: 20.2182294 },
    { lat: 49.3494285, lng: 20.2171149 },
    { lat: 49.34942, lng: 20.217095 },
    { lat: 49.349228, lng: 20.216696 },
    { lat: 49.3488983, lng: 20.2159853 },
    { lat: 49.348896, lng: 20.215981 },
    { lat: 49.3487328, lng: 20.2150209 },
    { lat: 49.3484299, lng: 20.2139922 },
    { lat: 49.347703, lng: 20.2140017 },
    { lat: 49.3473989, lng: 20.2135027 },
    { lat: 49.3467487, lng: 20.2132264 },
    { lat: 49.3460828, lng: 20.2131894 },
    { lat: 49.3453676, lng: 20.2135026 },
    { lat: 49.3451898, lng: 20.2134242 },
    { lat: 49.3448515, lng: 20.2127932 },
    { lat: 49.3446654, lng: 20.2123248 },
    { lat: 49.3445828, lng: 20.2119278 },
    { lat: 49.3444788, lng: 20.2107361 },
    { lat: 49.3443539, lng: 20.2102129 },
    { lat: 49.344188, lng: 20.209859 },
    { lat: 49.3441871, lng: 20.2098529 },
    { lat: 49.3439549, lng: 20.2093236 },
    { lat: 49.3438852, lng: 20.2089793 },
    { lat: 49.343627, lng: 20.208215 },
    { lat: 49.3435802, lng: 20.2080688 },
    { lat: 49.343461, lng: 20.20771 },
    { lat: 49.3432157, lng: 20.2069172 },
    { lat: 49.3430985, lng: 20.2065821 },
    { lat: 49.342751, lng: 20.205589 },
    { lat: 49.342899, lng: 20.20504 },
    { lat: 49.342766, lng: 20.204564 },
    { lat: 49.342533, lng: 20.203898 },
    { lat: 49.342033, lng: 20.203621 },
    { lat: 49.341806, lng: 20.203358 },
    { lat: 49.34175, lng: 20.202845 },
    { lat: 49.341844, lng: 20.20256 },
    { lat: 49.341945, lng: 20.200942 },
    { lat: 49.34204, lng: 20.199779 },
    { lat: 49.342029, lng: 20.198539 },
    { lat: 49.342029, lng: 20.198206 },
    { lat: 49.342171, lng: 20.197601 },
    { lat: 49.342224, lng: 20.1971362 },
    { lat: 49.3422057, lng: 20.1963568 },
    { lat: 49.3423098, lng: 20.1958379 },
    { lat: 49.342309, lng: 20.195831 },
    { lat: 49.3423095, lng: 20.1955474 },
    { lat: 49.342306, lng: 20.195541 },
    { lat: 49.3422682, lng: 20.1952298 },
    { lat: 49.341878, lng: 20.194759 },
    { lat: 49.34141, lng: 20.1941944 },
    { lat: 49.3414039, lng: 20.1939894 },
    { lat: 49.340978, lng: 20.1932141 },
    { lat: 49.3406941, lng: 20.1928356 },
    { lat: 49.340478, lng: 20.1927056 },
    { lat: 49.3401953, lng: 20.1923637 },
    { lat: 49.3395843, lng: 20.1916738 },
    { lat: 49.339174, lng: 20.191173 },
    { lat: 49.339023, lng: 20.190891 },
    { lat: 49.3386957, lng: 20.1902724 },
    { lat: 49.3383548, lng: 20.1901025 },
    { lat: 49.3377277, lng: 20.1901309 },
    { lat: 49.337487, lng: 20.190188 },
    { lat: 49.3371005, lng: 20.1902755 },
    { lat: 49.3359114, lng: 20.1901947 },
    { lat: 49.335566, lng: 20.190076 },
    { lat: 49.334613, lng: 20.189749 },
    { lat: 49.3346081, lng: 20.1897462 },
    { lat: 49.33456, lng: 20.189749 },
    { lat: 49.3342827, lng: 20.1897508 },
    { lat: 49.334215, lng: 20.18975 },
    { lat: 49.3335507, lng: 20.1897358 },
    { lat: 49.332944, lng: 20.1896094 },
    { lat: 49.3322577, lng: 20.1892115 },
    { lat: 49.331812, lng: 20.188487 },
    { lat: 49.3317975, lng: 20.1884592 },
    { lat: 49.3315958, lng: 20.18811 },
    { lat: 49.3312692, lng: 20.1876227 },
    { lat: 49.3310216, lng: 20.187206 },
    { lat: 49.3309168, lng: 20.1869523 },
    { lat: 49.3306668, lng: 20.186567 },
    { lat: 49.3293472, lng: 20.18513 },
    { lat: 49.328502, lng: 20.184705 },
    { lat: 49.32841, lng: 20.1846585 },
    { lat: 49.3278079, lng: 20.1841559 },
    { lat: 49.327195, lng: 20.183982 },
    { lat: 49.326488, lng: 20.183992 },
    { lat: 49.326243, lng: 20.184078 },
    { lat: 49.326052, lng: 20.18409 },
    { lat: 49.325744, lng: 20.184072 },
    { lat: 49.325098, lng: 20.184037 },
    { lat: 49.324579, lng: 20.18417 },
    { lat: 49.324283, lng: 20.184184 },
    { lat: 49.324178, lng: 20.184126 },
    { lat: 49.323983, lng: 20.183937 },
    { lat: 49.323835, lng: 20.183908 },
    { lat: 49.323471, lng: 20.184141 },
    { lat: 49.322993, lng: 20.184294 },
    { lat: 49.322459, lng: 20.184312 },
    { lat: 49.322282, lng: 20.184238 },
    { lat: 49.321745, lng: 20.184169 },
    { lat: 49.321395, lng: 20.184288 },
    { lat: 49.321245, lng: 20.184337 },
    { lat: 49.32111, lng: 20.184354 },
    { lat: 49.320849, lng: 20.184362 },
    { lat: 49.320723, lng: 20.184443 },
    { lat: 49.320594, lng: 20.184635 },
    { lat: 49.320531, lng: 20.184803 },
    { lat: 49.320444, lng: 20.184962 },
    { lat: 49.319877, lng: 20.185769 },
    { lat: 49.319624, lng: 20.185886 },
    { lat: 49.318718, lng: 20.185745 },
    { lat: 49.317455, lng: 20.185046 },
    { lat: 49.317028, lng: 20.184656 },
    { lat: 49.316775, lng: 20.184499 },
    { lat: 49.316021, lng: 20.184285 },
    { lat: 49.31567, lng: 20.183943 },
    { lat: 49.31545, lng: 20.183882 },
    { lat: 49.314859, lng: 20.183247 },
    { lat: 49.314533, lng: 20.182906 },
    { lat: 49.314232, lng: 20.182521 },
    { lat: 49.314011, lng: 20.182319 },
    { lat: 49.313903, lng: 20.182251 },
    { lat: 49.313674, lng: 20.182251 },
    { lat: 49.313202, lng: 20.182548 },
    { lat: 49.312873, lng: 20.182743 },
    { lat: 49.312638, lng: 20.182843 },
    { lat: 49.31251, lng: 20.182901 },
    { lat: 49.311927, lng: 20.182923 },
    { lat: 49.310841, lng: 20.183256 },
    { lat: 49.310648, lng: 20.183128 },
    { lat: 49.310321, lng: 20.183009 },
    { lat: 49.310064, lng: 20.183701 },
    { lat: 49.309631, lng: 20.184548 },
    { lat: 49.309486, lng: 20.184671 },
    { lat: 49.309206, lng: 20.184782 },
    { lat: 49.308971, lng: 20.184932 },
    { lat: 49.30869, lng: 20.185288 },
    { lat: 49.308577, lng: 20.185492 },
    { lat: 49.308459, lng: 20.185646 },
    { lat: 49.308296, lng: 20.185796 },
    { lat: 49.308247, lng: 20.185906 },
    { lat: 49.308138, lng: 20.186043 },
    { lat: 49.307696, lng: 20.186338 },
    { lat: 49.307401, lng: 20.186631 },
    { lat: 49.307235, lng: 20.186654 },
    { lat: 49.307042, lng: 20.186721 },
    { lat: 49.306887, lng: 20.186675 },
    { lat: 49.306662, lng: 20.186753 },
    { lat: 49.306561, lng: 20.186798 },
    { lat: 49.306434, lng: 20.186889 },
    { lat: 49.30623, lng: 20.187056 },
    { lat: 49.305925, lng: 20.187194 },
    { lat: 49.305857, lng: 20.187256 },
    { lat: 49.305687, lng: 20.187422 },
    { lat: 49.305502, lng: 20.187699 },
    { lat: 49.305198, lng: 20.187717 },
    { lat: 49.305111, lng: 20.187838 },
    { lat: 49.305001, lng: 20.188225 },
    { lat: 49.304734, lng: 20.189062 },
    { lat: 49.304599, lng: 20.189394 },
    { lat: 49.304475, lng: 20.189729 },
    { lat: 49.304308, lng: 20.190779 },
    { lat: 49.30419, lng: 20.191382 },
    { lat: 49.304146, lng: 20.191658 },
    { lat: 49.304063, lng: 20.192005 },
    { lat: 49.304, lng: 20.192236 },
    { lat: 49.303807, lng: 20.192962 },
    { lat: 49.303514, lng: 20.193633 },
    { lat: 49.303379, lng: 20.194037 },
    { lat: 49.303313, lng: 20.194265 },
    { lat: 49.303103, lng: 20.194783 },
    { lat: 49.30289, lng: 20.195453 },
    { lat: 49.302792, lng: 20.195706 },
    { lat: 49.302601, lng: 20.1962 },
    { lat: 49.302525, lng: 20.196349 },
    { lat: 49.302222, lng: 20.196805 },
    { lat: 49.302122, lng: 20.197018 },
    { lat: 49.301859, lng: 20.197398 },
    { lat: 49.301448, lng: 20.197972 },
    { lat: 49.301131, lng: 20.19851 },
    { lat: 49.301013, lng: 20.19864 },
    { lat: 49.300858, lng: 20.198682 },
    { lat: 49.300565, lng: 20.198886 },
    { lat: 49.300391, lng: 20.199083 },
    { lat: 49.300084, lng: 20.199319 },
    { lat: 49.299812, lng: 20.199508 },
    { lat: 49.299707, lng: 20.199535 },
    { lat: 49.29936, lng: 20.199807 },
    { lat: 49.298904, lng: 20.200115 },
    { lat: 49.298627, lng: 20.200263 },
    { lat: 49.298413, lng: 20.200421 },
    { lat: 49.2982, lng: 20.200639 },
    { lat: 49.298027, lng: 20.200869 },
    { lat: 49.297849, lng: 20.20114 },
    { lat: 49.297676, lng: 20.201501 },
    { lat: 49.297532, lng: 20.201729 },
    { lat: 49.297343, lng: 20.201902 },
    { lat: 49.296963, lng: 20.202204 },
    { lat: 49.296708, lng: 20.202285 },
    { lat: 49.296543, lng: 20.202287 },
    { lat: 49.296373, lng: 20.202243 },
    { lat: 49.296235, lng: 20.20216 },
    { lat: 49.295987, lng: 20.201983 },
    { lat: 49.295554, lng: 20.201591 },
    { lat: 49.295026, lng: 20.201242 },
    { lat: 49.294735, lng: 20.200942 },
    { lat: 49.29431, lng: 20.200753 },
    { lat: 49.294125, lng: 20.200542 },
    { lat: 49.293804, lng: 20.200318 },
    { lat: 49.293502, lng: 20.200162 },
    { lat: 49.293279, lng: 20.2 },
    { lat: 49.293031, lng: 20.199921 },
    { lat: 49.292732, lng: 20.199665 },
    { lat: 49.292615, lng: 20.199673 },
    { lat: 49.292278, lng: 20.199633 },
    { lat: 49.291794, lng: 20.199628 },
    { lat: 49.291592, lng: 20.199699 },
    { lat: 49.291264, lng: 20.200092 },
    { lat: 49.290926, lng: 20.200438 },
    { lat: 49.290491, lng: 20.201097 },
    { lat: 49.290112, lng: 20.201727 },
    { lat: 49.289047, lng: 20.202973 },
    { lat: 49.288806, lng: 20.20343 },
    { lat: 49.288329, lng: 20.204068 },
    { lat: 49.28798, lng: 20.20466 },
    { lat: 49.287483, lng: 20.205393 },
    { lat: 49.287253, lng: 20.205844 },
    { lat: 49.28702, lng: 20.206003 },
    { lat: 49.286848, lng: 20.206137 },
    { lat: 49.286707, lng: 20.206276 },
    { lat: 49.2862202, lng: 20.2069561 },
    { lat: 49.286224, lng: 20.208403 },
    { lat: 49.286097, lng: 20.209941 },
    { lat: 49.285984, lng: 20.210816 },
    { lat: 49.285816, lng: 20.211308 },
    { lat: 49.285229, lng: 20.212229 },
    { lat: 49.285061, lng: 20.212761 },
    { lat: 49.284756, lng: 20.213773 },
    { lat: 49.284341, lng: 20.214718 },
    { lat: 49.2839, lng: 20.215106 },
    { lat: 49.283649, lng: 20.215249 },
    { lat: 49.283272, lng: 20.215731 },
    { lat: 49.283095, lng: 20.216268 },
    { lat: 49.283042, lng: 20.216426 },
    { lat: 49.28286, lng: 20.21701 },
    { lat: 49.282657, lng: 20.217788 },
    { lat: 49.282409, lng: 20.218525 },
    { lat: 49.282346, lng: 20.21872 },
    { lat: 49.282293, lng: 20.21888 },
    { lat: 49.282064, lng: 20.220043 },
    { lat: 49.281909, lng: 20.220763 },
    { lat: 49.281929, lng: 20.221072 },
    { lat: 49.281927, lng: 20.221129 },
    { lat: 49.28196, lng: 20.22139 },
    { lat: 49.281906, lng: 20.222231 },
    { lat: 49.281562, lng: 20.2234 },
    { lat: 49.281734, lng: 20.22495 },
    { lat: 49.2817464, lng: 20.2252898 },
    { lat: 49.2817744, lng: 20.2255262 },
    { lat: 49.2819269, lng: 20.2256981 },
    { lat: 49.2820057, lng: 20.225784 },
    { lat: 49.2820566, lng: 20.2259828 },
    { lat: 49.2820478, lng: 20.2263482 },
    { lat: 49.2820898, lng: 20.2265899 },
    { lat: 49.2821705, lng: 20.2269311 },
    { lat: 49.2823106, lng: 20.2273206 },
    { lat: 49.2823895, lng: 20.2275167 },
    { lat: 49.2825536, lng: 20.2283088 },
    { lat: 49.282801, lng: 20.228977 },
    { lat: 49.283179, lng: 20.229955 },
    { lat: 49.2832641, lng: 20.2301511 },
    { lat: 49.2836252, lng: 20.2302418 },
    { lat: 49.2843, lng: 20.230496 },
    { lat: 49.2847855, lng: 20.2309588 },
    { lat: 49.2850163, lng: 20.2311857 },
    { lat: 49.2852354, lng: 20.2317121 },
    { lat: 49.2854307, lng: 20.2322203 },
    { lat: 49.2860936, lng: 20.233962 },
    { lat: 49.286451, lng: 20.235079 },
    { lat: 49.286733, lng: 20.235595 },
    { lat: 49.2867901, lng: 20.2357003 },
    { lat: 49.287212, lng: 20.236653 },
    { lat: 49.2875884, lng: 20.2372988 },
    { lat: 49.2878316, lng: 20.2376289 },
    { lat: 49.2882143, lng: 20.2379161 },
    { lat: 49.2885053, lng: 20.2381911 },
    { lat: 49.2889238, lng: 20.2384967 },
    { lat: 49.2893703, lng: 20.2389672 },
    { lat: 49.2895058, lng: 20.2391261 },
    { lat: 49.2898565, lng: 20.2394684 },
    { lat: 49.290226, lng: 20.239834 },
    { lat: 49.290676, lng: 20.240155 },
    { lat: 49.291322, lng: 20.240788 },
    { lat: 49.29146, lng: 20.241621 },
    { lat: 49.291478, lng: 20.242418 },
    { lat: 49.291408, lng: 20.243969 },
    { lat: 49.291187, lng: 20.245607 },
    { lat: 49.29096, lng: 20.24718 },
    { lat: 49.29074, lng: 20.248626 },
    { lat: 49.290623, lng: 20.251076 },
    { lat: 49.290695, lng: 20.252189 },
    { lat: 49.290919, lng: 20.253804 },
    { lat: 49.291252, lng: 20.255284 },
    { lat: 49.291317, lng: 20.256421 },
    { lat: 49.291137, lng: 20.2579 },
    { lat: 49.290944, lng: 20.258475 },
    { lat: 49.29098, lng: 20.258887 },
    { lat: 49.290924, lng: 20.260742 },
    { lat: 49.290981, lng: 20.262766 },
    { lat: 49.291089, lng: 20.264574 },
    { lat: 49.29116, lng: 20.265954 },
    { lat: 49.291561, lng: 20.267182 },
    { lat: 49.2926248, lng: 20.2676559 },
    { lat: 49.2943373, lng: 20.2685142 },
    { lat: 49.2948363, lng: 20.2695596 },
    { lat: 49.29525, lng: 20.2708159 },
    { lat: 49.2959579, lng: 20.2722521 },
    { lat: 49.2964595, lng: 20.2735333 },
    { lat: 49.2980812, lng: 20.2754503 },
    { lat: 49.2983038, lng: 20.276083 },
    { lat: 49.2987122, lng: 20.276184 },
    { lat: 49.3056064, lng: 20.2799776 },
    { lat: 49.3076762, lng: 20.2816068 },
    { lat: 49.3084462, lng: 20.2824732 },
    { lat: 49.3096727, lng: 20.2815523 },
  ],
  Žakovce: [
    { lat: 49.0906609, lng: 20.3769284 },
    { lat: 49.0906511, lng: 20.3769382 },
    { lat: 49.0893108, lng: 20.3780762 },
    { lat: 49.088937, lng: 20.3784639 },
    { lat: 49.0881564, lng: 20.3790404 },
    { lat: 49.0873889, lng: 20.3794051 },
    { lat: 49.0863511, lng: 20.3794457 },
    { lat: 49.0863188, lng: 20.3793958 },
    { lat: 49.0864328, lng: 20.3788102 },
    { lat: 49.0869762, lng: 20.3768003 },
    { lat: 49.088087, lng: 20.373778 },
    { lat: 49.087776, lng: 20.373492 },
    { lat: 49.087653, lng: 20.373379 },
    { lat: 49.087552, lng: 20.373284 },
    { lat: 49.087443, lng: 20.373178 },
    { lat: 49.087283, lng: 20.373027 },
    { lat: 49.087155, lng: 20.372906 },
    { lat: 49.086846, lng: 20.372612 },
    { lat: 49.086655, lng: 20.372429 },
    { lat: 49.086497, lng: 20.372277 },
    { lat: 49.086007, lng: 20.371815 },
    { lat: 49.085924, lng: 20.371733 },
    { lat: 49.085939, lng: 20.371693 },
    { lat: 49.085367, lng: 20.371169 },
    { lat: 49.085336, lng: 20.371141 },
    { lat: 49.084977, lng: 20.370813 },
    { lat: 49.084129, lng: 20.370036 },
    { lat: 49.083842, lng: 20.369752 },
    { lat: 49.08358, lng: 20.369436 },
    { lat: 49.081857, lng: 20.366907 },
    { lat: 49.081902, lng: 20.366866 },
    { lat: 49.081861, lng: 20.366769 },
    { lat: 49.081788, lng: 20.366809 },
    { lat: 49.081468, lng: 20.36636 },
    { lat: 49.079903, lng: 20.364569 },
    { lat: 49.079578, lng: 20.364227 },
    { lat: 49.079565, lng: 20.364214 },
    { lat: 49.077735, lng: 20.362333 },
    { lat: 49.077379, lng: 20.361913 },
    { lat: 49.0771, lng: 20.361489 },
    { lat: 49.0763, lng: 20.360134 },
    { lat: 49.075762, lng: 20.359224 },
    { lat: 49.075721, lng: 20.359102 },
    { lat: 49.075474, lng: 20.357135 },
    { lat: 49.075459, lng: 20.357009 },
    { lat: 49.075403, lng: 20.356773 },
    { lat: 49.075377, lng: 20.356788 },
    { lat: 49.075306, lng: 20.356829 },
    { lat: 49.075218, lng: 20.35653 },
    { lat: 49.07509, lng: 20.356654 },
    { lat: 49.074764, lng: 20.356942 },
    { lat: 49.074591, lng: 20.357102 },
    { lat: 49.074553, lng: 20.357134 },
    { lat: 49.074356, lng: 20.357296 },
    { lat: 49.074283, lng: 20.357355 },
    { lat: 49.074085, lng: 20.35753 },
    { lat: 49.074001, lng: 20.357606 },
    { lat: 49.073823, lng: 20.357761 },
    { lat: 49.07373, lng: 20.357847 },
    { lat: 49.073632, lng: 20.357923 },
    { lat: 49.073592, lng: 20.357952 },
    { lat: 49.073551, lng: 20.357987 },
    { lat: 49.073506, lng: 20.358028 },
    { lat: 49.073424, lng: 20.358087 },
    { lat: 49.073382, lng: 20.358122 },
    { lat: 49.073284, lng: 20.358185 },
    { lat: 49.073231, lng: 20.358209 },
    { lat: 49.073079, lng: 20.358289 },
    { lat: 49.072942, lng: 20.358357 },
    { lat: 49.072887, lng: 20.358378 },
    { lat: 49.072736, lng: 20.358448 },
    { lat: 49.072646, lng: 20.358499 },
    { lat: 49.072454, lng: 20.358596 },
    { lat: 49.072372, lng: 20.358642 },
    { lat: 49.072259, lng: 20.358692 },
    { lat: 49.072198, lng: 20.358734 },
    { lat: 49.072127, lng: 20.35877 },
    { lat: 49.07198, lng: 20.358853 },
    { lat: 49.071793, lng: 20.358962 },
    { lat: 49.071478, lng: 20.359175 },
    { lat: 49.071427, lng: 20.359216 },
    { lat: 49.071389, lng: 20.359253 },
    { lat: 49.071206, lng: 20.359406 },
    { lat: 49.071111, lng: 20.359487 },
    { lat: 49.071019, lng: 20.359573 },
    { lat: 49.070942, lng: 20.359656 },
    { lat: 49.070836, lng: 20.359758 },
    { lat: 49.070741, lng: 20.359854 },
    { lat: 49.070631, lng: 20.359967 },
    { lat: 49.070588, lng: 20.360017 },
    { lat: 49.070484, lng: 20.360123 },
    { lat: 49.070418, lng: 20.360197 },
    { lat: 49.070264, lng: 20.360362 },
    { lat: 49.070155, lng: 20.360488 },
    { lat: 49.070085, lng: 20.360567 },
    { lat: 49.06997, lng: 20.360688 },
    { lat: 49.069958, lng: 20.360698 },
    { lat: 49.069848, lng: 20.360525 },
    { lat: 49.069731, lng: 20.360635 },
    { lat: 49.069583, lng: 20.360756 },
    { lat: 49.069483, lng: 20.360831 },
    { lat: 49.069266, lng: 20.361006 },
    { lat: 49.069071, lng: 20.361155 },
    { lat: 49.06896, lng: 20.361243 },
    { lat: 49.068838, lng: 20.361334 },
    { lat: 49.068703, lng: 20.361407 },
    { lat: 49.068566, lng: 20.361477 },
    { lat: 49.068295, lng: 20.361592 },
    { lat: 49.068094, lng: 20.361646 },
    { lat: 49.068037, lng: 20.36162 },
    { lat: 49.067945, lng: 20.36155 },
    { lat: 49.067886, lng: 20.361569 },
    { lat: 49.067855, lng: 20.361588 },
    { lat: 49.067658, lng: 20.36175 },
    { lat: 49.067629, lng: 20.361783 },
    { lat: 49.067357, lng: 20.362021 },
    { lat: 49.067087, lng: 20.362272 },
    { lat: 49.066798, lng: 20.362558 },
    { lat: 49.066767, lng: 20.362595 },
    { lat: 49.066562, lng: 20.362779 },
    { lat: 49.066464, lng: 20.36286 },
    { lat: 49.066157, lng: 20.36308 },
    { lat: 49.065343, lng: 20.363639 },
    { lat: 49.065322, lng: 20.363637 },
    { lat: 49.065277, lng: 20.363724 },
    { lat: 49.065205, lng: 20.363795 },
    { lat: 49.065147, lng: 20.363859 },
    { lat: 49.064914, lng: 20.364088 },
    { lat: 49.064813, lng: 20.364205 },
    { lat: 49.064743, lng: 20.364286 },
    { lat: 49.064488, lng: 20.364598 },
    { lat: 49.06446, lng: 20.364637 },
    { lat: 49.06443, lng: 20.364664 },
    { lat: 49.064317, lng: 20.36479 },
    { lat: 49.064163, lng: 20.364948 },
    { lat: 49.064095, lng: 20.365034 },
    { lat: 49.064004, lng: 20.365106 },
    { lat: 49.063933, lng: 20.365179 },
    { lat: 49.063768, lng: 20.36535 },
    { lat: 49.063711, lng: 20.365447 },
    { lat: 49.063644, lng: 20.365553 },
    { lat: 49.063579, lng: 20.36564 },
    { lat: 49.0635, lng: 20.365767 },
    { lat: 49.063364, lng: 20.36597 },
    { lat: 49.063217, lng: 20.366183 },
    { lat: 49.063138, lng: 20.366302 },
    { lat: 49.063054, lng: 20.366416 },
    { lat: 49.062925, lng: 20.366557 },
    { lat: 49.062788, lng: 20.366685 },
    { lat: 49.062543, lng: 20.366894 },
    { lat: 49.062181, lng: 20.367198 },
    { lat: 49.06209, lng: 20.367264 },
    { lat: 49.061918, lng: 20.367428 },
    { lat: 49.061733, lng: 20.367594 },
    { lat: 49.06166, lng: 20.367652 },
    { lat: 49.061462, lng: 20.367782 },
    { lat: 49.06114, lng: 20.36796 },
    { lat: 49.060938, lng: 20.368088 },
    { lat: 49.060905, lng: 20.368112 },
    { lat: 49.060708, lng: 20.36828 },
    { lat: 49.060535, lng: 20.368536 },
    { lat: 49.060377, lng: 20.368819 },
    { lat: 49.060174, lng: 20.369154 },
    { lat: 49.059932, lng: 20.369545 },
    { lat: 49.059791, lng: 20.369787 },
    { lat: 49.059743, lng: 20.369881 },
    { lat: 49.059666, lng: 20.370014 },
    { lat: 49.059471, lng: 20.370363 },
    { lat: 49.059374, lng: 20.370545 },
    { lat: 49.059302, lng: 20.370722 },
    { lat: 49.059284, lng: 20.370775 },
    { lat: 49.059203, lng: 20.370982 },
    { lat: 49.059156, lng: 20.371093 },
    { lat: 49.059107, lng: 20.371211 },
    { lat: 49.059058, lng: 20.371382 },
    { lat: 49.059019, lng: 20.371516 },
    { lat: 49.058998, lng: 20.371578 },
    { lat: 49.058957, lng: 20.371684 },
    { lat: 49.058911, lng: 20.37183 },
    { lat: 49.058815, lng: 20.372097 },
    { lat: 49.058736, lng: 20.372286 },
    { lat: 49.05866, lng: 20.372494 },
    { lat: 49.058547, lng: 20.372752 },
    { lat: 49.058425, lng: 20.373023 },
    { lat: 49.058218, lng: 20.373533 },
    { lat: 49.058211, lng: 20.373558 },
    { lat: 49.057664, lng: 20.374019 },
    { lat: 49.057294, lng: 20.374335 },
    { lat: 49.05706, lng: 20.374457 },
    { lat: 49.056769, lng: 20.374604 },
    { lat: 49.056749, lng: 20.374614 },
    { lat: 49.056737, lng: 20.374614 },
    { lat: 49.056683, lng: 20.374642 },
    { lat: 49.056525, lng: 20.374723 },
    { lat: 49.05643, lng: 20.374778 },
    { lat: 49.056416, lng: 20.374786 },
    { lat: 49.055934, lng: 20.375038 },
    { lat: 49.055442, lng: 20.37529 },
    { lat: 49.054308, lng: 20.376138 },
    { lat: 49.053972, lng: 20.376443 },
    { lat: 49.053638, lng: 20.376766 },
    { lat: 49.053243, lng: 20.377204 },
    { lat: 49.053107, lng: 20.377332 },
    { lat: 49.053091, lng: 20.377316 },
    { lat: 49.052933, lng: 20.377752 },
    { lat: 49.052794, lng: 20.378099 },
    { lat: 49.052561, lng: 20.3786 },
    { lat: 49.05243, lng: 20.378911 },
    { lat: 49.052201, lng: 20.379549 },
    { lat: 49.052124, lng: 20.379914 },
    { lat: 49.052067, lng: 20.380339 },
    { lat: 49.05193, lng: 20.380888 },
    { lat: 49.051838, lng: 20.381186 },
    { lat: 49.051564, lng: 20.381953 },
    { lat: 49.051406, lng: 20.382372 },
    { lat: 49.051233, lng: 20.382811 },
    { lat: 49.050891, lng: 20.383593 },
    { lat: 49.050493, lng: 20.384382 },
    { lat: 49.050217, lng: 20.384925 },
    { lat: 49.049944, lng: 20.385456 },
    { lat: 49.049717, lng: 20.385798 },
    { lat: 49.049571, lng: 20.385948 },
    { lat: 49.049122, lng: 20.386341 },
    { lat: 49.048604, lng: 20.386846 },
    { lat: 49.04811, lng: 20.387341 },
    { lat: 49.047914, lng: 20.387508 },
    { lat: 49.04766, lng: 20.387696 },
    { lat: 49.047474, lng: 20.387845 },
    { lat: 49.047347, lng: 20.387966 },
    { lat: 49.047213, lng: 20.388083 },
    { lat: 49.047008, lng: 20.388262 },
    { lat: 49.046744, lng: 20.388512 },
    { lat: 49.046545, lng: 20.388677 },
    { lat: 49.046279, lng: 20.388879 },
    { lat: 49.046027, lng: 20.389106 },
    { lat: 49.045628, lng: 20.389521 },
    { lat: 49.045315, lng: 20.389684 },
    { lat: 49.045115, lng: 20.38983 },
    { lat: 49.044896, lng: 20.390057 },
    { lat: 49.044388, lng: 20.390708 },
    { lat: 49.044342, lng: 20.390775 },
    { lat: 49.044138, lng: 20.391078 },
    { lat: 49.043955, lng: 20.391349 },
    { lat: 49.043892, lng: 20.391448 },
    { lat: 49.043819, lng: 20.391563 },
    { lat: 49.043777, lng: 20.391646 },
    { lat: 49.043752, lng: 20.391697 },
    { lat: 49.043749, lng: 20.391705 },
    { lat: 49.043716, lng: 20.391822 },
    { lat: 49.043592, lng: 20.392066 },
    { lat: 49.043528, lng: 20.392187 },
    { lat: 49.043518, lng: 20.392207 },
    { lat: 49.043554, lng: 20.392263 },
    { lat: 49.043573, lng: 20.392298 },
    { lat: 49.043619, lng: 20.39238 },
    { lat: 49.043829, lng: 20.392802 },
    { lat: 49.044047, lng: 20.393317 },
    { lat: 49.044304, lng: 20.393954 },
    { lat: 49.04458, lng: 20.39465 },
    { lat: 49.044871, lng: 20.395409 },
    { lat: 49.045416, lng: 20.396838 },
    { lat: 49.045671, lng: 20.397624 },
    { lat: 49.045962, lng: 20.398496 },
    { lat: 49.046084, lng: 20.398846 },
    { lat: 49.046323, lng: 20.399501 },
    { lat: 49.046421, lng: 20.399742 },
    { lat: 49.046576, lng: 20.40009 },
    { lat: 49.046726, lng: 20.40039 },
    { lat: 49.04686, lng: 20.400628 },
    { lat: 49.04687, lng: 20.400647 },
    { lat: 49.046897, lng: 20.400645 },
    { lat: 49.047036, lng: 20.400864 },
    { lat: 49.047173, lng: 20.401108 },
    { lat: 49.047264, lng: 20.401241 },
    { lat: 49.047362, lng: 20.401349 },
    { lat: 49.047554, lng: 20.401579 },
    { lat: 49.047595, lng: 20.401607 },
    { lat: 49.047649, lng: 20.401659 },
    { lat: 49.047723, lng: 20.401763 },
    { lat: 49.04783, lng: 20.401995 },
    { lat: 49.047936, lng: 20.402211 },
    { lat: 49.04813, lng: 20.402751 },
    { lat: 49.048252, lng: 20.402983 },
    { lat: 49.0487003, lng: 20.4038947 },
    { lat: 49.0491527, lng: 20.4049532 },
    { lat: 49.0498249, lng: 20.4048036 },
    { lat: 49.0506591, lng: 20.4048339 },
    { lat: 49.0528517, lng: 20.4047256 },
    { lat: 49.0538183, lng: 20.4050921 },
    { lat: 49.0542567, lng: 20.4053709 },
    { lat: 49.0553823, lng: 20.4065495 },
    { lat: 49.0556612, lng: 20.4069514 },
    { lat: 49.0563276, lng: 20.4084279 },
    { lat: 49.056946, lng: 20.4089683 },
    { lat: 49.0583995, lng: 20.4097395 },
    { lat: 49.0586821, lng: 20.409944 },
    { lat: 49.0610005, lng: 20.4121134 },
    { lat: 49.0622203, lng: 20.41334 },
    { lat: 49.0625205, lng: 20.4138022 },
    { lat: 49.0630926, lng: 20.4143713 },
    { lat: 49.0636137, lng: 20.4152853 },
    { lat: 49.0637469, lng: 20.4153246 },
    { lat: 49.0638777, lng: 20.4154772 },
    { lat: 49.0639861, lng: 20.4156648 },
    { lat: 49.0645111, lng: 20.4162635 },
    { lat: 49.0646627, lng: 20.4165543 },
    { lat: 49.0649149, lng: 20.4166101 },
    { lat: 49.0649486, lng: 20.4167568 },
    { lat: 49.0650458, lng: 20.4167691 },
    { lat: 49.0651761, lng: 20.4167279 },
    { lat: 49.0655435, lng: 20.4164123 },
    { lat: 49.0661271, lng: 20.4166889 },
    { lat: 49.0668402, lng: 20.4165494 },
    { lat: 49.0669161, lng: 20.4163855 },
    { lat: 49.0669974, lng: 20.4163884 },
    { lat: 49.0670336, lng: 20.4165712 },
    { lat: 49.0671232, lng: 20.4166503 },
    { lat: 49.0672956, lng: 20.4166541 },
    { lat: 49.0673922, lng: 20.4167383 },
    { lat: 49.0675618, lng: 20.4165518 },
    { lat: 49.0677481, lng: 20.416678 },
    { lat: 49.0679132, lng: 20.4174743 },
    { lat: 49.068275, lng: 20.4180188 },
    { lat: 49.0684052, lng: 20.4180666 },
    { lat: 49.0686254, lng: 20.4178753 },
    { lat: 49.0688158, lng: 20.4177279 },
    { lat: 49.0688226, lng: 20.417551 },
    { lat: 49.0689337, lng: 20.417246 },
    { lat: 49.0690478, lng: 20.4171057 },
    { lat: 49.0692148, lng: 20.4171165 },
    { lat: 49.0692728, lng: 20.4170116 },
    { lat: 49.0694006, lng: 20.4170883 },
    { lat: 49.0694636, lng: 20.4170272 },
    { lat: 49.0700469, lng: 20.4171156 },
    { lat: 49.071077, lng: 20.4169921 },
    { lat: 49.0712932, lng: 20.4167875 },
    { lat: 49.0713639, lng: 20.4168416 },
    { lat: 49.0718483, lng: 20.4166691 },
    { lat: 49.0733155, lng: 20.4168767 },
    { lat: 49.0739925, lng: 20.4175143 },
    { lat: 49.0746683, lng: 20.4177275 },
    { lat: 49.0746051, lng: 20.4165659 },
    { lat: 49.0745522, lng: 20.4162962 },
    { lat: 49.0752972, lng: 20.4157534 },
    { lat: 49.0757791, lng: 20.4148585 },
    { lat: 49.0757951, lng: 20.4147221 },
    { lat: 49.0760373, lng: 20.4142465 },
    { lat: 49.0767002, lng: 20.413142 },
    { lat: 49.0769299, lng: 20.4128482 },
    { lat: 49.0772519, lng: 20.4125782 },
    { lat: 49.0779315, lng: 20.4121815 },
    { lat: 49.0782434, lng: 20.4119411 },
    { lat: 49.0787307, lng: 20.4114335 },
    { lat: 49.0798777, lng: 20.4105654 },
    { lat: 49.0802514, lng: 20.410117 },
    { lat: 49.0806741, lng: 20.4099775 },
    { lat: 49.0816134, lng: 20.4101947 },
    { lat: 49.0824477, lng: 20.410228 },
    { lat: 49.083945, lng: 20.4105147 },
    { lat: 49.0852895, lng: 20.4106662 },
    { lat: 49.0852599, lng: 20.4098861 },
    { lat: 49.0876082, lng: 20.4093723 },
    { lat: 49.088809, lng: 20.4085281 },
    { lat: 49.0892301, lng: 20.4083236 },
    { lat: 49.0896034, lng: 20.4080145 },
    { lat: 49.091851, lng: 20.4057049 },
    { lat: 49.0932234, lng: 20.4046036 },
    { lat: 49.0932185, lng: 20.4024635 },
    { lat: 49.093288, lng: 20.4025152 },
    { lat: 49.0936173, lng: 20.4023216 },
    { lat: 49.093647, lng: 20.4021839 },
    { lat: 49.0941641, lng: 20.4017482 },
    { lat: 49.094323, lng: 20.4015157 },
    { lat: 49.0943552, lng: 20.4012334 },
    { lat: 49.094483, lng: 20.4011944 },
    { lat: 49.0946867, lng: 20.4009217 },
    { lat: 49.0946555, lng: 20.4008106 },
    { lat: 49.0947542, lng: 20.4006578 },
    { lat: 49.094771, lng: 20.4000632 },
    { lat: 49.0948209, lng: 20.3998055 },
    { lat: 49.0949531, lng: 20.3995761 },
    { lat: 49.0949946, lng: 20.3993651 },
    { lat: 49.0951118, lng: 20.3993081 },
    { lat: 49.0954462, lng: 20.3984792 },
    { lat: 49.0957276, lng: 20.3981547 },
    { lat: 49.0959822, lng: 20.3980999 },
    { lat: 49.0960413, lng: 20.3982017 },
    { lat: 49.0963169, lng: 20.3981332 },
    { lat: 49.0964802, lng: 20.3981971 },
    { lat: 49.0965154, lng: 20.3983223 },
    { lat: 49.0966271, lng: 20.3984497 },
    { lat: 49.0969758, lng: 20.3985537 },
    { lat: 49.096994, lng: 20.3987101 },
    { lat: 49.0972899, lng: 20.3988536 },
    { lat: 49.09738, lng: 20.3991202 },
    { lat: 49.097601, lng: 20.3992419 },
    { lat: 49.0976607, lng: 20.3994206 },
    { lat: 49.0978769, lng: 20.3996038 },
    { lat: 49.0981401, lng: 20.3995339 },
    { lat: 49.098263, lng: 20.3995999 },
    { lat: 49.0990978, lng: 20.3995823 },
    { lat: 49.0998114, lng: 20.3992502 },
    { lat: 49.1001435, lng: 20.3991606 },
    { lat: 49.1005302, lng: 20.3988451 },
    { lat: 49.100777, lng: 20.3985294 },
    { lat: 49.1007688, lng: 20.3985186 },
    { lat: 49.1002888, lng: 20.3973246 },
    { lat: 49.0994866, lng: 20.3958309 },
    { lat: 49.0985794, lng: 20.3930613 },
    { lat: 49.098309, lng: 20.3928179 },
    { lat: 49.0979851, lng: 20.3921832 },
    { lat: 49.0976674, lng: 20.3917029 },
    { lat: 49.0969549, lng: 20.3902288 },
    { lat: 49.0966782, lng: 20.3895322 },
    { lat: 49.0962915, lng: 20.3882129 },
    { lat: 49.0959828, lng: 20.3874135 },
    { lat: 49.0944764, lng: 20.3848791 },
    { lat: 49.0936957, lng: 20.3833666 },
    { lat: 49.0921805, lng: 20.380013 },
    { lat: 49.0906609, lng: 20.3769284 },
  ],
  SlovenskáVes: [
    { lat: 49.2774226, lng: 20.4361266 },
    { lat: 49.2786435, lng: 20.4364106 },
    { lat: 49.2793491, lng: 20.4368557 },
    { lat: 49.2797702, lng: 20.4368861 },
    { lat: 49.2802726, lng: 20.4367468 },
    { lat: 49.2806291, lng: 20.4364941 },
    { lat: 49.2810922, lng: 20.4348881 },
    { lat: 49.2818387, lng: 20.4334519 },
    { lat: 49.282099, lng: 20.4331282 },
    { lat: 49.2823457, lng: 20.4329201 },
    { lat: 49.2833558, lng: 20.4321509 },
    { lat: 49.2836592, lng: 20.4319829 },
    { lat: 49.28388, lng: 20.4319891 },
    { lat: 49.2848353, lng: 20.4322733 },
    { lat: 49.2855474, lng: 20.4320492 },
    { lat: 49.2863187, lng: 20.43199 },
    { lat: 49.2875459, lng: 20.4321462 },
    { lat: 49.2880165, lng: 20.4320814 },
    { lat: 49.2885257, lng: 20.4313124 },
    { lat: 49.2888365, lng: 20.4306568 },
    { lat: 49.2892774, lng: 20.4294769 },
    { lat: 49.2898305, lng: 20.4284083 },
    { lat: 49.2899578, lng: 20.4280527 },
    { lat: 49.2900283, lng: 20.4278899 },
    { lat: 49.2896685, lng: 20.4267671 },
    { lat: 49.28928, lng: 20.4243762 },
    { lat: 49.2892215, lng: 20.4234172 },
    { lat: 49.2887485, lng: 20.4197451 },
    { lat: 49.2886022, lng: 20.4192928 },
    { lat: 49.2883876, lng: 20.4189204 },
    { lat: 49.287974, lng: 20.4185814 },
    { lat: 49.2875267, lng: 20.4179428 },
    { lat: 49.2863579, lng: 20.4157045 },
    { lat: 49.2833897, lng: 20.4104429 },
    { lat: 49.2823042, lng: 20.4094566 },
    { lat: 49.2806422, lng: 20.4073063 },
    { lat: 49.2797202, lng: 20.4057888 },
    { lat: 49.2796396, lng: 20.4055352 },
    { lat: 49.2781795, lng: 20.4002453 },
    { lat: 49.2771064, lng: 20.3955517 },
    { lat: 49.2766675, lng: 20.3938868 },
    { lat: 49.2759589, lng: 20.3914782 },
    { lat: 49.2749865, lng: 20.3865359 },
    { lat: 49.2751657, lng: 20.3859751 },
    { lat: 49.2754089, lng: 20.3828425 },
    { lat: 49.2760461, lng: 20.3815368 },
    { lat: 49.2758189, lng: 20.3807439 },
    { lat: 49.2756793, lng: 20.377047 },
    { lat: 49.2729261, lng: 20.3700049 },
    { lat: 49.2729195, lng: 20.3698014 },
    { lat: 49.2715336, lng: 20.3663266 },
    { lat: 49.2701205, lng: 20.3629639 },
    { lat: 49.2696231, lng: 20.3638495 },
    { lat: 49.2695263, lng: 20.3642633 },
    { lat: 49.2695158, lng: 20.364579 },
    { lat: 49.2696162, lng: 20.3651451 },
    { lat: 49.2697889, lng: 20.3655422 },
    { lat: 49.2699645, lng: 20.3664499 },
    { lat: 49.2699472, lng: 20.367179 },
    { lat: 49.2700124, lng: 20.3678225 },
    { lat: 49.2699306, lng: 20.3684871 },
    { lat: 49.2699362, lng: 20.3693988 },
    { lat: 49.2692922, lng: 20.3722597 },
    { lat: 49.2692652, lng: 20.3727943 },
    { lat: 49.2697543, lng: 20.3740775 },
    { lat: 49.2699923, lng: 20.3748564 },
    { lat: 49.2703294, lng: 20.3764636 },
    { lat: 49.2704451, lng: 20.3774322 },
    { lat: 49.2704375, lng: 20.3800389 },
    { lat: 49.2701116, lng: 20.3807925 },
    { lat: 49.2700195, lng: 20.3814555 },
    { lat: 49.2699456, lng: 20.3816383 },
    { lat: 49.2695198, lng: 20.3822538 },
    { lat: 49.2687823, lng: 20.3831417 },
    { lat: 49.2683125, lng: 20.3838471 },
    { lat: 49.2682366, lng: 20.3845129 },
    { lat: 49.2655224, lng: 20.3852925 },
    { lat: 49.2654075, lng: 20.3853774 },
    { lat: 49.2652971, lng: 20.3853464 },
    { lat: 49.2650233, lng: 20.3855481 },
    { lat: 49.2649045, lng: 20.3855331 },
    { lat: 49.2647315, lng: 20.3856295 },
    { lat: 49.2646532, lng: 20.385736 },
    { lat: 49.2642895, lng: 20.3858505 },
    { lat: 49.2641494, lng: 20.3860453 },
    { lat: 49.2637901, lng: 20.3862499 },
    { lat: 49.2634793, lng: 20.3866019 },
    { lat: 49.2633154, lng: 20.3866164 },
    { lat: 49.2631907, lng: 20.3867119 },
    { lat: 49.2630204, lng: 20.3869992 },
    { lat: 49.2628063, lng: 20.3871429 },
    { lat: 49.2627397, lng: 20.3872598 },
    { lat: 49.2623536, lng: 20.3874048 },
    { lat: 49.2623092, lng: 20.3875713 },
    { lat: 49.2620562, lng: 20.3877314 },
    { lat: 49.2617415, lng: 20.3881854 },
    { lat: 49.2615375, lng: 20.3883001 },
    { lat: 49.2610915, lng: 20.388862 },
    { lat: 49.2602134, lng: 20.3896003 },
    { lat: 49.2598288, lng: 20.3898543 },
    { lat: 49.2596959, lng: 20.3900586 },
    { lat: 49.2595487, lng: 20.3901144 },
    { lat: 49.2595073, lng: 20.3901301 },
    { lat: 49.2593257, lng: 20.3906829 },
    { lat: 49.2591993, lng: 20.390914 },
    { lat: 49.2589835, lng: 20.3910157 },
    { lat: 49.2589363, lng: 20.3911024 },
    { lat: 49.2587892, lng: 20.3910881 },
    { lat: 49.2586167, lng: 20.3914335 },
    { lat: 49.2586065, lng: 20.3916132 },
    { lat: 49.2583548, lng: 20.3918003 },
    { lat: 49.2583083, lng: 20.3917633 },
    { lat: 49.2582227, lng: 20.3918808 },
    { lat: 49.258015, lng: 20.3918992 },
    { lat: 49.2579376, lng: 20.3920849 },
    { lat: 49.2578173, lng: 20.3921761 },
    { lat: 49.2576916, lng: 20.3922714 },
    { lat: 49.2575424, lng: 20.3926426 },
    { lat: 49.2572511, lng: 20.3926821 },
    { lat: 49.2571573, lng: 20.3928832 },
    { lat: 49.2569526, lng: 20.3931209 },
    { lat: 49.2567583, lng: 20.3931932 },
    { lat: 49.2565365, lng: 20.3935845 },
    { lat: 49.2563969, lng: 20.3936106 },
    { lat: 49.2563298, lng: 20.3937679 },
    { lat: 49.2560742, lng: 20.393859 },
    { lat: 49.2560121, lng: 20.3940019 },
    { lat: 49.2559038, lng: 20.3939704 },
    { lat: 49.2559052, lng: 20.3943004 },
    { lat: 49.2555091, lng: 20.3945417 },
    { lat: 49.2547092, lng: 20.3952725 },
    { lat: 49.2539913, lng: 20.3957902 },
    { lat: 49.2537887, lng: 20.395643 },
    { lat: 49.2535, lng: 20.395599 },
    { lat: 49.2530911, lng: 20.3956493 },
    { lat: 49.2528596, lng: 20.3957523 },
    { lat: 49.2526353, lng: 20.3961023 },
    { lat: 49.251659, lng: 20.3967806 },
    { lat: 49.251372, lng: 20.3970813 },
    { lat: 49.2506967, lng: 20.3975264 },
    { lat: 49.2499311, lng: 20.3982414 },
    { lat: 49.2494977, lng: 20.3991328 },
    { lat: 49.245647, lng: 20.4016238 },
    { lat: 49.2449816, lng: 20.4022192 },
    { lat: 49.2429428, lng: 20.4033774 },
    { lat: 49.2408058, lng: 20.4047431 },
    { lat: 49.2395394, lng: 20.405198 },
    { lat: 49.2388223, lng: 20.405849 },
    { lat: 49.2368795, lng: 20.4062814 },
    { lat: 49.2367229, lng: 20.4064877 },
    { lat: 49.2347089, lng: 20.4073803 },
    { lat: 49.2342798, lng: 20.4075007 },
    { lat: 49.2333177, lng: 20.4079297 },
    { lat: 49.2312728, lng: 20.4086322 },
    { lat: 49.2309778, lng: 20.4087541 },
    { lat: 49.2304292, lng: 20.4091328 },
    { lat: 49.229873, lng: 20.4092781 },
    { lat: 49.2291029, lng: 20.4096072 },
    { lat: 49.2277426, lng: 20.4098094 },
    { lat: 49.2252934, lng: 20.4098875 },
    { lat: 49.2240507, lng: 20.4098602 },
    { lat: 49.2233448, lng: 20.4097165 },
    { lat: 49.2221039, lng: 20.409178 },
    { lat: 49.2210906, lng: 20.4089328 },
    { lat: 49.2154072, lng: 20.4082256 },
    { lat: 49.215359, lng: 20.409003 },
    { lat: 49.2154965, lng: 20.4103713 },
    { lat: 49.2154368, lng: 20.4109395 },
    { lat: 49.2152697, lng: 20.4112542 },
    { lat: 49.2150684, lng: 20.4114348 },
    { lat: 49.214764, lng: 20.4115455 },
    { lat: 49.2143521, lng: 20.4114889 },
    { lat: 49.2142727, lng: 20.4117747 },
    { lat: 49.2142985, lng: 20.4122431 },
    { lat: 49.2145821, lng: 20.4133719 },
    { lat: 49.2144022, lng: 20.414273 },
    { lat: 49.2143642, lng: 20.4151158 },
    { lat: 49.2146862, lng: 20.4168118 },
    { lat: 49.214613, lng: 20.4172302 },
    { lat: 49.2140934, lng: 20.4172446 },
    { lat: 49.2140306, lng: 20.417306 },
    { lat: 49.2139445, lng: 20.4175624 },
    { lat: 49.2140869, lng: 20.4181718 },
    { lat: 49.2140457, lng: 20.4187502 },
    { lat: 49.2141031, lng: 20.4198554 },
    { lat: 49.2139458, lng: 20.4202513 },
    { lat: 49.2137205, lng: 20.4204577 },
    { lat: 49.2136362, lng: 20.4207871 },
    { lat: 49.2137171, lng: 20.4210814 },
    { lat: 49.21446, lng: 20.4219099 },
    { lat: 49.2146688, lng: 20.4222855 },
    { lat: 49.2143314, lng: 20.4229158 },
    { lat: 49.2136765, lng: 20.4239429 },
    { lat: 49.2133375, lng: 20.4248381 },
    { lat: 49.2132989, lng: 20.4252473 },
    { lat: 49.2133329, lng: 20.4257845 },
    { lat: 49.2134652, lng: 20.4274478 },
    { lat: 49.2135939, lng: 20.4278459 },
    { lat: 49.2136462, lng: 20.4287496 },
    { lat: 49.2136314, lng: 20.428927 },
    { lat: 49.2132479, lng: 20.4300457 },
    { lat: 49.2132483, lng: 20.4314591 },
    { lat: 49.2134097, lng: 20.4320658 },
    { lat: 49.2137293, lng: 20.4326582 },
    { lat: 49.2143352, lng: 20.4335936 },
    { lat: 49.2145127, lng: 20.4340293 },
    { lat: 49.2146065, lng: 20.4344266 },
    { lat: 49.2145969, lng: 20.4351952 },
    { lat: 49.2144801, lng: 20.4358277 },
    { lat: 49.2142023, lng: 20.4368842 },
    { lat: 49.2139558, lng: 20.4385019 },
    { lat: 49.2139389, lng: 20.4388008 },
    { lat: 49.2140443, lng: 20.4392943 },
    { lat: 49.2143577, lng: 20.4397548 },
    { lat: 49.2151558, lng: 20.4406511 },
    { lat: 49.2156763, lng: 20.4415887 },
    { lat: 49.2159255, lng: 20.4422299 },
    { lat: 49.2160253, lng: 20.4426943 },
    { lat: 49.2159509, lng: 20.4436231 },
    { lat: 49.2159779, lng: 20.4440158 },
    { lat: 49.2160697, lng: 20.4443932 },
    { lat: 49.2162897, lng: 20.444823 },
    { lat: 49.2169677, lng: 20.4457591 },
    { lat: 49.2170802, lng: 20.4456372 },
    { lat: 49.2172533, lng: 20.4454478 },
    { lat: 49.2172663, lng: 20.4454355 },
    { lat: 49.2179921, lng: 20.4447028 },
    { lat: 49.2183597, lng: 20.4441506 },
    { lat: 49.218523, lng: 20.4437643 },
    { lat: 49.2187357, lng: 20.4436419 },
    { lat: 49.2192635, lng: 20.4435172 },
    { lat: 49.2207445, lng: 20.4436786 },
    { lat: 49.2233277, lng: 20.4444721 },
    { lat: 49.2241509, lng: 20.4446252 },
    { lat: 49.2248974, lng: 20.4446564 },
    { lat: 49.2254822, lng: 20.4445508 },
    { lat: 49.2260325, lng: 20.4445786 },
    { lat: 49.2301403, lng: 20.4448913 },
    { lat: 49.2324605, lng: 20.4452239 },
    { lat: 49.232441, lng: 20.4455084 },
    { lat: 49.2343925, lng: 20.4458236 },
    { lat: 49.2343917, lng: 20.4459216 },
    { lat: 49.234427, lng: 20.4459308 },
    { lat: 49.2364148, lng: 20.4462187 },
    { lat: 49.2385513, lng: 20.4467213 },
    { lat: 49.2402368, lng: 20.4467886 },
    { lat: 49.2402525, lng: 20.4463328 },
    { lat: 49.2403074, lng: 20.4460542 },
    { lat: 49.2402588, lng: 20.4459597 },
    { lat: 49.2401163, lng: 20.4458829 },
    { lat: 49.2400786, lng: 20.4457478 },
    { lat: 49.2402432, lng: 20.4452178 },
    { lat: 49.2401972, lng: 20.44477 },
    { lat: 49.23993, lng: 20.4445786 },
    { lat: 49.239811, lng: 20.4440176 },
    { lat: 49.2396093, lng: 20.4437474 },
    { lat: 49.2396595, lng: 20.4429842 },
    { lat: 49.2397796, lng: 20.4427027 },
    { lat: 49.2397581, lng: 20.4424574 },
    { lat: 49.2397062, lng: 20.4421561 },
    { lat: 49.2394954, lng: 20.442034 },
    { lat: 49.2394495, lng: 20.4419469 },
    { lat: 49.2394738, lng: 20.441872 },
    { lat: 49.2396362, lng: 20.4417933 },
    { lat: 49.2395605, lng: 20.4412465 },
    { lat: 49.2396791, lng: 20.4411644 },
    { lat: 49.2399254, lng: 20.441252 },
    { lat: 49.2399282, lng: 20.4409923 },
    { lat: 49.2399668, lng: 20.4409403 },
    { lat: 49.2401207, lng: 20.4409621 },
    { lat: 49.2400968, lng: 20.4405963 },
    { lat: 49.2401924, lng: 20.4404474 },
    { lat: 49.2401408, lng: 20.4401208 },
    { lat: 49.2401549, lng: 20.4398692 },
    { lat: 49.2402844, lng: 20.4395044 },
    { lat: 49.2403359, lng: 20.4390469 },
    { lat: 49.2404767, lng: 20.4387532 },
    { lat: 49.2404011, lng: 20.4383905 },
    { lat: 49.2404242, lng: 20.4381968 },
    { lat: 49.2409175, lng: 20.4375677 },
    { lat: 49.2408758, lng: 20.436881 },
    { lat: 49.2409583, lng: 20.4361697 },
    { lat: 49.240791, lng: 20.4357613 },
    { lat: 49.2408492, lng: 20.4356341 },
    { lat: 49.2411526, lng: 20.4353833 },
    { lat: 49.241441, lng: 20.4344562 },
    { lat: 49.2414995, lng: 20.4343945 },
    { lat: 49.2417176, lng: 20.4346528 },
    { lat: 49.2420905, lng: 20.4346002 },
    { lat: 49.2421654, lng: 20.4341079 },
    { lat: 49.2423196, lng: 20.4338123 },
    { lat: 49.242713, lng: 20.433377 },
    { lat: 49.2432458, lng: 20.4331098 },
    { lat: 49.2433749, lng: 20.4329846 },
    { lat: 49.2434265, lng: 20.4328881 },
    { lat: 49.2434819, lng: 20.4324757 },
    { lat: 49.243874, lng: 20.4320053 },
    { lat: 49.2440951, lng: 20.4314796 },
    { lat: 49.2444747, lng: 20.4315735 },
    { lat: 49.2451219, lng: 20.4312107 },
    { lat: 49.2452307, lng: 20.4311053 },
    { lat: 49.2453702, lng: 20.4308376 },
    { lat: 49.2456029, lng: 20.4302258 },
    { lat: 49.2456045, lng: 20.4303013 },
    { lat: 49.2457585, lng: 20.4301746 },
    { lat: 49.2458811, lng: 20.4301729 },
    { lat: 49.2459403, lng: 20.4302783 },
    { lat: 49.2459398, lng: 20.4304473 },
    { lat: 49.2460444, lng: 20.430561 },
    { lat: 49.2461929, lng: 20.430588 },
    { lat: 49.2463815, lng: 20.430397 },
    { lat: 49.2465975, lng: 20.4303625 },
    { lat: 49.2467694, lng: 20.4302364 },
    { lat: 49.2468179, lng: 20.4299652 },
    { lat: 49.2470365, lng: 20.4300292 },
    { lat: 49.2472492, lng: 20.4297885 },
    { lat: 49.2473064, lng: 20.4295172 },
    { lat: 49.247528, lng: 20.429332 },
    { lat: 49.2477477, lng: 20.4292449 },
    { lat: 49.2478382, lng: 20.4293735 },
    { lat: 49.2479586, lng: 20.429377 },
    { lat: 49.2480205, lng: 20.4292829 },
    { lat: 49.2479278, lng: 20.4290965 },
    { lat: 49.2479507, lng: 20.4289835 },
    { lat: 49.2480569, lng: 20.4289031 },
    { lat: 49.2482457, lng: 20.428568 },
    { lat: 49.2484335, lng: 20.4284116 },
    { lat: 49.2484465, lng: 20.428248 },
    { lat: 49.2486054, lng: 20.42822 },
    { lat: 49.2486126, lng: 20.4280539 },
    { lat: 49.2487424, lng: 20.4279438 },
    { lat: 49.2487412, lng: 20.4278228 },
    { lat: 49.248805, lng: 20.4278136 },
    { lat: 49.2488426, lng: 20.4279739 },
    { lat: 49.2493213, lng: 20.4277415 },
    { lat: 49.2496725, lng: 20.4274706 },
    { lat: 49.2522747, lng: 20.4246729 },
    { lat: 49.2524657, lng: 20.4243941 },
    { lat: 49.2528295, lng: 20.4243934 },
    { lat: 49.2534006, lng: 20.4247923 },
    { lat: 49.2565269, lng: 20.427658 },
    { lat: 49.2568724, lng: 20.4277423 },
    { lat: 49.2577567, lng: 20.4285966 },
    { lat: 49.2595992, lng: 20.4302232 },
    { lat: 49.2616895, lng: 20.4324572 },
    { lat: 49.2630424, lng: 20.4337034 },
    { lat: 49.2636896, lng: 20.434032 },
    { lat: 49.2645476, lng: 20.4345756 },
    { lat: 49.265036, lng: 20.4351041 },
    { lat: 49.2660698, lng: 20.4366103 },
    { lat: 49.2668006, lng: 20.4373926 },
    { lat: 49.2674719, lng: 20.4378244 },
    { lat: 49.2678127, lng: 20.4379657 },
    { lat: 49.2680761, lng: 20.4379938 },
    { lat: 49.2692101, lng: 20.4379672 },
    { lat: 49.2696046, lng: 20.4378925 },
    { lat: 49.2697552, lng: 20.4379449 },
    { lat: 49.2698604, lng: 20.4377183 },
    { lat: 49.2698178, lng: 20.4374185 },
    { lat: 49.2699151, lng: 20.4373483 },
    { lat: 49.2699715, lng: 20.4371404 },
    { lat: 49.2700323, lng: 20.437081 },
    { lat: 49.2703457, lng: 20.437242 },
    { lat: 49.2708871, lng: 20.4367272 },
    { lat: 49.2709624, lng: 20.4369041 },
    { lat: 49.2710465, lng: 20.436957 },
    { lat: 49.2710181, lng: 20.4366199 },
    { lat: 49.2712811, lng: 20.4364558 },
    { lat: 49.2714648, lng: 20.4361905 },
    { lat: 49.2716246, lng: 20.4362183 },
    { lat: 49.2716955, lng: 20.4360711 },
    { lat: 49.2719008, lng: 20.436047 },
    { lat: 49.2719223, lng: 20.4359034 },
    { lat: 49.2720451, lng: 20.4359074 },
    { lat: 49.2720914, lng: 20.4359719 },
    { lat: 49.2721579, lng: 20.4359383 },
    { lat: 49.2723606, lng: 20.435525 },
    { lat: 49.2726049, lng: 20.435643 },
    { lat: 49.2727185, lng: 20.4354012 },
    { lat: 49.2728002, lng: 20.4353912 },
    { lat: 49.272851, lng: 20.435567 },
    { lat: 49.2729208, lng: 20.4355994 },
    { lat: 49.2731082, lng: 20.4354056 },
    { lat: 49.2732374, lng: 20.4351282 },
    { lat: 49.2732882, lng: 20.4347105 },
    { lat: 49.2734111, lng: 20.4345649 },
    { lat: 49.2734579, lng: 20.4344043 },
    { lat: 49.274129, lng: 20.4340198 },
    { lat: 49.274137, lng: 20.43378 },
    { lat: 49.2742407, lng: 20.4336966 },
    { lat: 49.2743538, lng: 20.4333792 },
    { lat: 49.2746093, lng: 20.4331087 },
    { lat: 49.2748984, lng: 20.4331049 },
    { lat: 49.2752499, lng: 20.4334692 },
    { lat: 49.2767475, lng: 20.4353003 },
    { lat: 49.2774226, lng: 20.4361266 },
  ],
  MalýSlavkov: [
    { lat: 49.1499378, lng: 20.3796612 },
    { lat: 49.1500537, lng: 20.379069 },
    { lat: 49.1505715, lng: 20.3762923 },
    { lat: 49.1507163, lng: 20.3757532 },
    { lat: 49.1511262, lng: 20.3749565 },
    { lat: 49.1520865, lng: 20.3724767 },
    { lat: 49.1533459, lng: 20.3681627 },
    { lat: 49.1547295, lng: 20.3640159 },
    { lat: 49.1548467, lng: 20.3634839 },
    { lat: 49.155436, lng: 20.3619662 },
    { lat: 49.1572912, lng: 20.3583778 },
    { lat: 49.1575924, lng: 20.3576314 },
    { lat: 49.1580062, lng: 20.3568492 },
    { lat: 49.1584083, lng: 20.3562107 },
    { lat: 49.1588421, lng: 20.3556612 },
    { lat: 49.1592805, lng: 20.3551702 },
    { lat: 49.1596095, lng: 20.3549697 },
    { lat: 49.1597838, lng: 20.3547343 },
    { lat: 49.1604305, lng: 20.3537434 },
    { lat: 49.1615502, lng: 20.3515597 },
    { lat: 49.162482, lng: 20.350477 },
    { lat: 49.16266, lng: 20.350171 },
    { lat: 49.161979, lng: 20.349266 },
    { lat: 49.161489, lng: 20.348639 },
    { lat: 49.160665, lng: 20.347619 },
    { lat: 49.161874, lng: 20.345579 },
    { lat: 49.162172, lng: 20.344907 },
    { lat: 49.163036, lng: 20.343472 },
    { lat: 49.163154, lng: 20.343292 },
    { lat: 49.16364, lng: 20.342568 },
    { lat: 49.163851, lng: 20.342379 },
    { lat: 49.163508, lng: 20.342363 },
    { lat: 49.163256, lng: 20.342351 },
    { lat: 49.162193, lng: 20.343104 },
    { lat: 49.161884, lng: 20.343266 },
    { lat: 49.161075, lng: 20.343161 },
    { lat: 49.162256, lng: 20.341174 },
    { lat: 49.162762, lng: 20.340311 },
    { lat: 49.163823, lng: 20.338925 },
    { lat: 49.163957, lng: 20.338587 },
    { lat: 49.164069, lng: 20.338375 },
    { lat: 49.164271, lng: 20.337835 },
    { lat: 49.164451, lng: 20.337406 },
    { lat: 49.164541, lng: 20.33717 },
    { lat: 49.164615, lng: 20.337073 },
    { lat: 49.164806, lng: 20.336826 },
    { lat: 49.165438, lng: 20.336007 },
    { lat: 49.165592, lng: 20.335645 },
    { lat: 49.165964, lng: 20.334988 },
    { lat: 49.166408, lng: 20.33373 },
    { lat: 49.166562, lng: 20.333458 },
    { lat: 49.166835, lng: 20.332831 },
    { lat: 49.167058, lng: 20.332013 },
    { lat: 49.166728, lng: 20.331509 },
    { lat: 49.165999, lng: 20.33044 },
    { lat: 49.165967, lng: 20.330393 },
    { lat: 49.1657981, lng: 20.3310092 },
    { lat: 49.1656995, lng: 20.3310824 },
    { lat: 49.1656295, lng: 20.3312808 },
    { lat: 49.1654782, lng: 20.331389 },
    { lat: 49.1653666, lng: 20.3316768 },
    { lat: 49.1654077, lng: 20.3317454 },
    { lat: 49.1652927, lng: 20.3324772 },
    { lat: 49.1651156, lng: 20.3326377 },
    { lat: 49.1650474, lng: 20.3327726 },
    { lat: 49.1650915, lng: 20.3328953 },
    { lat: 49.1648517, lng: 20.3330093 },
    { lat: 49.1647644, lng: 20.3331671 },
    { lat: 49.1645078, lng: 20.3333477 },
    { lat: 49.1644542, lng: 20.3335065 },
    { lat: 49.1644948, lng: 20.3337403 },
    { lat: 49.1644162, lng: 20.3338913 },
    { lat: 49.1643, lng: 20.3343536 },
    { lat: 49.164265, lng: 20.3347921 },
    { lat: 49.1641637, lng: 20.3349055 },
    { lat: 49.1639074, lng: 20.3348612 },
    { lat: 49.1637257, lng: 20.3350513 },
    { lat: 49.1636018, lng: 20.335026 },
    { lat: 49.1635271, lng: 20.3351878 },
    { lat: 49.1636403, lng: 20.3353504 },
    { lat: 49.1636765, lng: 20.3355569 },
    { lat: 49.1636468, lng: 20.3357236 },
    { lat: 49.163444, lng: 20.3359263 },
    { lat: 49.163302, lng: 20.3359779 },
    { lat: 49.1630189, lng: 20.3363148 },
    { lat: 49.162813, lng: 20.3363229 },
    { lat: 49.162637, lng: 20.3364264 },
    { lat: 49.1626683, lng: 20.3367438 },
    { lat: 49.1626088, lng: 20.3368956 },
    { lat: 49.1622973, lng: 20.3370771 },
    { lat: 49.1621934, lng: 20.3374253 },
    { lat: 49.1621016, lng: 20.3374684 },
    { lat: 49.1618534, lng: 20.3377735 },
    { lat: 49.1615145, lng: 20.3383064 },
    { lat: 49.1611456, lng: 20.338633 },
    { lat: 49.1610546, lng: 20.3388441 },
    { lat: 49.1606949, lng: 20.3391209 },
    { lat: 49.160482, lng: 20.3390651 },
    { lat: 49.1603936, lng: 20.3391954 },
    { lat: 49.1602938, lng: 20.3392313 },
    { lat: 49.1601499, lng: 20.3391542 },
    { lat: 49.1600403, lng: 20.3392302 },
    { lat: 49.1600327, lng: 20.3393813 },
    { lat: 49.1600959, lng: 20.3394476 },
    { lat: 49.1600307, lng: 20.339593 },
    { lat: 49.1599295, lng: 20.3396251 },
    { lat: 49.1598446, lng: 20.3395582 },
    { lat: 49.159699, lng: 20.3396925 },
    { lat: 49.1596317, lng: 20.3401698 },
    { lat: 49.1594313, lng: 20.3402892 },
    { lat: 49.1592498, lng: 20.3401335 },
    { lat: 49.159155, lng: 20.3402164 },
    { lat: 49.1589158, lng: 20.3404983 },
    { lat: 49.1588246, lng: 20.34079 },
    { lat: 49.1586853, lng: 20.3410024 },
    { lat: 49.1583184, lng: 20.3413864 },
    { lat: 49.1581978, lng: 20.3415889 },
    { lat: 49.1579959, lng: 20.3416642 },
    { lat: 49.1579482, lng: 20.3418034 },
    { lat: 49.1578614, lng: 20.3418611 },
    { lat: 49.1576685, lng: 20.3419065 },
    { lat: 49.1576107, lng: 20.3420174 },
    { lat: 49.1573877, lng: 20.3421017 },
    { lat: 49.1573032, lng: 20.3423021 },
    { lat: 49.1573324, lng: 20.3424484 },
    { lat: 49.1573043, lng: 20.3425945 },
    { lat: 49.1571219, lng: 20.3427414 },
    { lat: 49.1570486, lng: 20.3426832 },
    { lat: 49.1568816, lng: 20.3429053 },
    { lat: 49.1568152, lng: 20.3429084 },
    { lat: 49.1567572, lng: 20.3432715 },
    { lat: 49.1565486, lng: 20.3433258 },
    { lat: 49.1566074, lng: 20.3434436 },
    { lat: 49.1565887, lng: 20.3434859 },
    { lat: 49.1562577, lng: 20.3434597 },
    { lat: 49.1561776, lng: 20.3437471 },
    { lat: 49.1560934, lng: 20.3437522 },
    { lat: 49.155968, lng: 20.3439163 },
    { lat: 49.1559161, lng: 20.343886 },
    { lat: 49.1558274, lng: 20.3439781 },
    { lat: 49.1558492, lng: 20.3441409 },
    { lat: 49.1557808, lng: 20.3442312 },
    { lat: 49.1555808, lng: 20.3442153 },
    { lat: 49.1553851, lng: 20.3444519 },
    { lat: 49.155317, lng: 20.3445824 },
    { lat: 49.155339, lng: 20.3447754 },
    { lat: 49.1554366, lng: 20.3450304 },
    { lat: 49.1553348, lng: 20.3452866 },
    { lat: 49.1552419, lng: 20.3452068 },
    { lat: 49.1551343, lng: 20.345607 },
    { lat: 49.1550522, lng: 20.345723 },
    { lat: 49.155013, lng: 20.3458755 },
    { lat: 49.1550315, lng: 20.3460076 },
    { lat: 49.1549708, lng: 20.3461515 },
    { lat: 49.1549078, lng: 20.34601 },
    { lat: 49.1546666, lng: 20.3461197 },
    { lat: 49.1546962, lng: 20.3461851 },
    { lat: 49.1546632, lng: 20.3462643 },
    { lat: 49.1545022, lng: 20.3463855 },
    { lat: 49.1545513, lng: 20.3465462 },
    { lat: 49.1544625, lng: 20.3467592 },
    { lat: 49.1544037, lng: 20.3467824 },
    { lat: 49.1544175, lng: 20.3466992 },
    { lat: 49.1543415, lng: 20.3466243 },
    { lat: 49.1542794, lng: 20.3466472 },
    { lat: 49.1540725, lng: 20.3470656 },
    { lat: 49.1539154, lng: 20.3471095 },
    { lat: 49.1538732, lng: 20.3472687 },
    { lat: 49.1538602, lng: 20.3474598 },
    { lat: 49.1539066, lng: 20.3475564 },
    { lat: 49.1538703, lng: 20.3477229 },
    { lat: 49.1539829, lng: 20.347755 },
    { lat: 49.1539639, lng: 20.3477876 },
    { lat: 49.1536632, lng: 20.348104 },
    { lat: 49.1534972, lng: 20.3480325 },
    { lat: 49.1534495, lng: 20.348081 },
    { lat: 49.1534615, lng: 20.3486264 },
    { lat: 49.153358, lng: 20.349187 },
    { lat: 49.1532413, lng: 20.3494608 },
    { lat: 49.1532779, lng: 20.3499467 },
    { lat: 49.1531816, lng: 20.3497848 },
    { lat: 49.1531218, lng: 20.3497819 },
    { lat: 49.1530087, lng: 20.3501808 },
    { lat: 49.1528899, lng: 20.3501652 },
    { lat: 49.1528206, lng: 20.3503267 },
    { lat: 49.1528067, lng: 20.3504909 },
    { lat: 49.1529136, lng: 20.3505735 },
    { lat: 49.1529679, lng: 20.3507269 },
    { lat: 49.152829, lng: 20.3509494 },
    { lat: 49.1528317, lng: 20.3511682 },
    { lat: 49.1527649, lng: 20.3513331 },
    { lat: 49.1525468, lng: 20.351108 },
    { lat: 49.1524782, lng: 20.3512026 },
    { lat: 49.1524554, lng: 20.3512791 },
    { lat: 49.1525175, lng: 20.3516049 },
    { lat: 49.1524282, lng: 20.3522164 },
    { lat: 49.1523551, lng: 20.3522431 },
    { lat: 49.1522565, lng: 20.3520843 },
    { lat: 49.152147, lng: 20.3524897 },
    { lat: 49.1520633, lng: 20.3525328 },
    { lat: 49.1519633, lng: 20.352478 },
    { lat: 49.1518844, lng: 20.3525383 },
    { lat: 49.1518222, lng: 20.352791 },
    { lat: 49.1513993, lng: 20.3530413 },
    { lat: 49.1513225, lng: 20.3531485 },
    { lat: 49.1512412, lng: 20.353441 },
    { lat: 49.151277, lng: 20.353782 },
    { lat: 49.1511794, lng: 20.3537979 },
    { lat: 49.1511668, lng: 20.3538746 },
    { lat: 49.1511909, lng: 20.354098 },
    { lat: 49.1511477, lng: 20.3542 },
    { lat: 49.1510125, lng: 20.3542009 },
    { lat: 49.1507452, lng: 20.3546258 },
    { lat: 49.1504869, lng: 20.3547622 },
    { lat: 49.1504908, lng: 20.3549809 },
    { lat: 49.1501955, lng: 20.355445 },
    { lat: 49.1502925, lng: 20.3555608 },
    { lat: 49.1502597, lng: 20.3556799 },
    { lat: 49.1500468, lng: 20.3558485 },
    { lat: 49.1498337, lng: 20.3561685 },
    { lat: 49.149745, lng: 20.3562049 },
    { lat: 49.1496451, lng: 20.3563548 },
    { lat: 49.1496272, lng: 20.3565053 },
    { lat: 49.1493834, lng: 20.3566389 },
    { lat: 49.1492431, lng: 20.356824 },
    { lat: 49.1491573, lng: 20.356968 },
    { lat: 49.1490966, lng: 20.3572309 },
    { lat: 49.1486103, lng: 20.3573232 },
    { lat: 49.1483974, lng: 20.3575857 },
    { lat: 49.1482651, lng: 20.3576 },
    { lat: 49.1481469, lng: 20.3573656 },
    { lat: 49.1480034, lng: 20.3573521 },
    { lat: 49.1478553, lng: 20.3576009 },
    { lat: 49.1475455, lng: 20.3576537 },
    { lat: 49.1474057, lng: 20.3578287 },
    { lat: 49.1473765, lng: 20.3579723 },
    { lat: 49.1472296, lng: 20.3582278 },
    { lat: 49.1470652, lng: 20.3583144 },
    { lat: 49.146972, lng: 20.3585853 },
    { lat: 49.1468704, lng: 20.3586918 },
    { lat: 49.1467398, lng: 20.3586351 },
    { lat: 49.1466975, lng: 20.3585026 },
    { lat: 49.1466162, lng: 20.3584987 },
    { lat: 49.1464913, lng: 20.3586137 },
    { lat: 49.1464552, lng: 20.3587299 },
    { lat: 49.1465211, lng: 20.3587765 },
    { lat: 49.1465362, lng: 20.3591132 },
    { lat: 49.1462891, lng: 20.3596837 },
    { lat: 49.1462504, lng: 20.3599674 },
    { lat: 49.1461213, lng: 20.3600091 },
    { lat: 49.1460408, lng: 20.3601997 },
    { lat: 49.1458889, lng: 20.3603171 },
    { lat: 49.1457557, lng: 20.360338 },
    { lat: 49.1454893, lng: 20.3605304 },
    { lat: 49.1454723, lng: 20.3606409 },
    { lat: 49.1456391, lng: 20.3609109 },
    { lat: 49.1455663, lng: 20.3611292 },
    { lat: 49.1455414, lng: 20.3614137 },
    { lat: 49.1454333, lng: 20.3614759 },
    { lat: 49.1452774, lng: 20.3616973 },
    { lat: 49.1451781, lng: 20.3614839 },
    { lat: 49.1449006, lng: 20.3614708 },
    { lat: 49.14476, lng: 20.3615315 },
    { lat: 49.144678, lng: 20.3616589 },
    { lat: 49.1447081, lng: 20.3619131 },
    { lat: 49.1445843, lng: 20.3620881 },
    { lat: 49.144658, lng: 20.362485 },
    { lat: 49.1445906, lng: 20.3626399 },
    { lat: 49.1444653, lng: 20.362745 },
    { lat: 49.1441245, lng: 20.3626987 },
    { lat: 49.1438899, lng: 20.3628019 },
    { lat: 49.1438609, lng: 20.3629013 },
    { lat: 49.1440179, lng: 20.3630872 },
    { lat: 49.14362, lng: 20.3634604 },
    { lat: 49.1436369, lng: 20.3637236 },
    { lat: 49.1435667, lng: 20.3640966 },
    { lat: 49.1434677, lng: 20.3639807 },
    { lat: 49.1433855, lng: 20.3640103 },
    { lat: 49.1432843, lng: 20.3642203 },
    { lat: 49.1433026, lng: 20.3643726 },
    { lat: 49.1431492, lng: 20.3646304 },
    { lat: 49.1431772, lng: 20.3650084 },
    { lat: 49.1431067, lng: 20.3653441 },
    { lat: 49.1428754, lng: 20.3656247 },
    { lat: 49.1428099, lng: 20.3657996 },
    { lat: 49.1426624, lng: 20.365799 },
    { lat: 49.1425296, lng: 20.365917 },
    { lat: 49.1425459, lng: 20.3659914 },
    { lat: 49.1426899, lng: 20.3660426 },
    { lat: 49.1426225, lng: 20.3662241 },
    { lat: 49.1427122, lng: 20.3663362 },
    { lat: 49.1426722, lng: 20.3663743 },
    { lat: 49.1423981, lng: 20.366098 },
    { lat: 49.1422469, lng: 20.3662352 },
    { lat: 49.1422545, lng: 20.3664334 },
    { lat: 49.1423724, lng: 20.3666613 },
    { lat: 49.1423725, lng: 20.3668732 },
    { lat: 49.1423077, lng: 20.3670044 },
    { lat: 49.1421918, lng: 20.3669716 },
    { lat: 49.1419908, lng: 20.3674286 },
    { lat: 49.1421447, lng: 20.3677454 },
    { lat: 49.1420987, lng: 20.3678674 },
    { lat: 49.1420196, lng: 20.3678299 },
    { lat: 49.1419679, lng: 20.3678909 },
    { lat: 49.1419657, lng: 20.3680119 },
    { lat: 49.1419994, lng: 20.3680978 },
    { lat: 49.1421064, lng: 20.3680998 },
    { lat: 49.1421351, lng: 20.3682288 },
    { lat: 49.1420462, lng: 20.368463 },
    { lat: 49.1419939, lng: 20.3684536 },
    { lat: 49.1419652, lng: 20.3683511 },
    { lat: 49.1418559, lng: 20.3683191 },
    { lat: 49.1416649, lng: 20.3684436 },
    { lat: 49.1416441, lng: 20.3685232 },
    { lat: 49.1417012, lng: 20.3687446 },
    { lat: 49.141348, lng: 20.369523 },
    { lat: 49.1412914, lng: 20.3700171 },
    { lat: 49.1411751, lng: 20.3703004 },
    { lat: 49.1412156, lng: 20.3705945 },
    { lat: 49.1411982, lng: 20.3709057 },
    { lat: 49.1411232, lng: 20.3711206 },
    { lat: 49.1411693, lng: 20.3714452 },
    { lat: 49.1411122, lng: 20.3715664 },
    { lat: 49.1409826, lng: 20.3715974 },
    { lat: 49.1407629, lng: 20.3719786 },
    { lat: 49.1405729, lng: 20.3721304 },
    { lat: 49.1404727, lng: 20.3723061 },
    { lat: 49.1404821, lng: 20.3723776 },
    { lat: 49.1405971, lng: 20.37238 },
    { lat: 49.1406384, lng: 20.3724863 },
    { lat: 49.1406121, lng: 20.3725685 },
    { lat: 49.1404768, lng: 20.3727096 },
    { lat: 49.1404867, lng: 20.3728511 },
    { lat: 49.1404337, lng: 20.3729396 },
    { lat: 49.140144, lng: 20.3731937 },
    { lat: 49.1404378, lng: 20.3736315 },
    { lat: 49.1404453, lng: 20.3741257 },
    { lat: 49.1403365, lng: 20.3742551 },
    { lat: 49.1401447, lng: 20.3742423 },
    { lat: 49.1399295, lng: 20.3747656 },
    { lat: 49.1398336, lng: 20.3747946 },
    { lat: 49.1396386, lng: 20.3751981 },
    { lat: 49.1394962, lng: 20.3752151 },
    { lat: 49.1394459, lng: 20.3755216 },
    { lat: 49.1393564, lng: 20.3755073 },
    { lat: 49.1391997, lng: 20.3756172 },
    { lat: 49.139166, lng: 20.3757363 },
    { lat: 49.1392041, lng: 20.3757951 },
    { lat: 49.1392609, lng: 20.3758347 },
    { lat: 49.1392829, lng: 20.3757321 },
    { lat: 49.1393388, lng: 20.3757818 },
    { lat: 49.1393287, lng: 20.3758682 },
    { lat: 49.1392252, lng: 20.3759641 },
    { lat: 49.1391625, lng: 20.3762066 },
    { lat: 49.1378932, lng: 20.3754286 },
    { lat: 49.1376637, lng: 20.3759369 },
    { lat: 49.1369038, lng: 20.3772981 },
    { lat: 49.136139, lng: 20.3789064 },
    { lat: 49.1354515, lng: 20.3802191 },
    { lat: 49.134687, lng: 20.3822573 },
    { lat: 49.1339192, lng: 20.3844993 },
    { lat: 49.1333755, lng: 20.3855941 },
    { lat: 49.1328496, lng: 20.3865087 },
    { lat: 49.1314285, lng: 20.3851779 },
    { lat: 49.130658, lng: 20.3869971 },
    { lat: 49.132033, lng: 20.3883596 },
    { lat: 49.1315781, lng: 20.3904018 },
    { lat: 49.1303688, lng: 20.3918747 },
    { lat: 49.1303634, lng: 20.3918809 },
    { lat: 49.1304402, lng: 20.3920395 },
    { lat: 49.1308792, lng: 20.3930707 },
    { lat: 49.1313366, lng: 20.393845 },
    { lat: 49.1316003, lng: 20.394178 },
    { lat: 49.1322988, lng: 20.3947814 },
    { lat: 49.1331347, lng: 20.3952088 },
    { lat: 49.1335168, lng: 20.3952196 },
    { lat: 49.1337205, lng: 20.3953325 },
    { lat: 49.1340112, lng: 20.3953395 },
    { lat: 49.1340118, lng: 20.395306 },
    { lat: 49.134013, lng: 20.3952394 },
    { lat: 49.1351975, lng: 20.3948258 },
    { lat: 49.1363373, lng: 20.3942742 },
    { lat: 49.1366877, lng: 20.3942184 },
    { lat: 49.1375997, lng: 20.3943953 },
    { lat: 49.1382664, lng: 20.3944309 },
    { lat: 49.13907, lng: 20.3946594 },
    { lat: 49.1399651, lng: 20.3947227 },
    { lat: 49.1401157, lng: 20.3943275 },
    { lat: 49.1403677, lng: 20.3945321 },
    { lat: 49.1405338, lng: 20.3941276 },
    { lat: 49.1416046, lng: 20.3951999 },
    { lat: 49.1418466, lng: 20.3944845 },
    { lat: 49.141967, lng: 20.3945414 },
    { lat: 49.1422735, lng: 20.3948761 },
    { lat: 49.1428027, lng: 20.3934577 },
    { lat: 49.1440034, lng: 20.3916034 },
    { lat: 49.1459233, lng: 20.3882101 },
    { lat: 49.1468377, lng: 20.386971 },
    { lat: 49.147884, lng: 20.3847952 },
    { lat: 49.1481465, lng: 20.3841494 },
    { lat: 49.1484502, lng: 20.3836046 },
    { lat: 49.1487389, lng: 20.3826435 },
    { lat: 49.1493168, lng: 20.3817071 },
    { lat: 49.1496817, lng: 20.3806223 },
    { lat: 49.1499378, lng: 20.3796612 },
  ],
  Lendak: [
    { lat: 49.2701205, lng: 20.3629639 },
    { lat: 49.2700842, lng: 20.3615376 },
    { lat: 49.2705018, lng: 20.3581515 },
    { lat: 49.270554, lng: 20.3572758 },
    { lat: 49.2719793, lng: 20.3556005 },
    { lat: 49.2733178, lng: 20.3535367 },
    { lat: 49.2733595, lng: 20.3532167 },
    { lat: 49.2734694, lng: 20.3529379 },
    { lat: 49.2734752, lng: 20.3527062 },
    { lat: 49.2737261, lng: 20.3519385 },
    { lat: 49.2740975, lng: 20.3516381 },
    { lat: 49.2743308, lng: 20.3512927 },
    { lat: 49.2745942, lng: 20.3507654 },
    { lat: 49.2747255, lng: 20.3503134 },
    { lat: 49.2748966, lng: 20.3499259 },
    { lat: 49.2750412, lng: 20.3493851 },
    { lat: 49.2751671, lng: 20.3485279 },
    { lat: 49.2753568, lng: 20.3481016 },
    { lat: 49.2753616, lng: 20.3449677 },
    { lat: 49.2754058, lng: 20.3441816 },
    { lat: 49.2754766, lng: 20.3433589 },
    { lat: 49.2755644, lng: 20.3428855 },
    { lat: 49.2757192, lng: 20.3424313 },
    { lat: 49.2757349, lng: 20.3418995 },
    { lat: 49.2757977, lng: 20.3415455 },
    { lat: 49.2759589, lng: 20.3410796 },
    { lat: 49.2760834, lng: 20.3403905 },
    { lat: 49.2761519, lng: 20.3395063 },
    { lat: 49.2764636, lng: 20.3375441 },
    { lat: 49.2764758, lng: 20.3372408 },
    { lat: 49.2767496, lng: 20.3359285 },
    { lat: 49.2768378, lng: 20.3356761 },
    { lat: 49.2768973, lng: 20.3348792 },
    { lat: 49.2775901, lng: 20.3312899 },
    { lat: 49.2778497, lng: 20.3288804 },
    { lat: 49.2779604, lng: 20.3283915 },
    { lat: 49.2781772, lng: 20.3278531 },
    { lat: 49.2786423, lng: 20.3281167 },
    { lat: 49.2790363, lng: 20.3284661 },
    { lat: 49.2795103, lng: 20.328731 },
    { lat: 49.2800281, lng: 20.3278756 },
    { lat: 49.2801703, lng: 20.327329 },
    { lat: 49.2805421, lng: 20.3269313 },
    { lat: 49.280882, lng: 20.3259609 },
    { lat: 49.2808556, lng: 20.325919 },
    { lat: 49.2809396, lng: 20.3254404 },
    { lat: 49.2815752, lng: 20.3238169 },
    { lat: 49.281711, lng: 20.3236327 },
    { lat: 49.2820665, lng: 20.3234171 },
    { lat: 49.2824619, lng: 20.3229859 },
    { lat: 49.2826186, lng: 20.32264 },
    { lat: 49.2828062, lng: 20.3220164 },
    { lat: 49.2833103, lng: 20.3208386 },
    { lat: 49.283766, lng: 20.319367 },
    { lat: 49.283455, lng: 20.319752 },
    { lat: 49.282924, lng: 20.319999 },
    { lat: 49.281532, lng: 20.32013 },
    { lat: 49.281356, lng: 20.320152 },
    { lat: 49.281253, lng: 20.32013 },
    { lat: 49.279481, lng: 20.319742 },
    { lat: 49.278398, lng: 20.319824 },
    { lat: 49.278322, lng: 20.319752 },
    { lat: 49.277592, lng: 20.319885 },
    { lat: 49.276396, lng: 20.321174 },
    { lat: 49.276112, lng: 20.321125 },
    { lat: 49.2745, lng: 20.320638 },
    { lat: 49.272813, lng: 20.31931 },
    { lat: 49.271399, lng: 20.318872 },
    { lat: 49.269485, lng: 20.318897 },
    { lat: 49.26858, lng: 20.319182 },
    { lat: 49.267942, lng: 20.318404 },
    { lat: 49.266098, lng: 20.317078 },
    { lat: 49.263974, lng: 20.317105 },
    { lat: 49.263052, lng: 20.316464 },
    { lat: 49.262639, lng: 20.316546 },
    { lat: 49.262475, lng: 20.316579 },
    { lat: 49.261963, lng: 20.316942 },
    { lat: 49.261783, lng: 20.317604 },
    { lat: 49.261462, lng: 20.31793 },
    { lat: 49.260403, lng: 20.319823 },
    { lat: 49.260293, lng: 20.320314 },
    { lat: 49.260134, lng: 20.321342 },
    { lat: 49.259792, lng: 20.321993 },
    { lat: 49.259392, lng: 20.322828 },
    { lat: 49.2586, lng: 20.322906 },
    { lat: 49.258231, lng: 20.323098 },
    { lat: 49.258155, lng: 20.323035 },
    { lat: 49.257769, lng: 20.322964 },
    { lat: 49.257533, lng: 20.323324 },
    { lat: 49.256551, lng: 20.323099 },
    { lat: 49.255347, lng: 20.322628 },
    { lat: 49.254845, lng: 20.322431 },
    { lat: 49.25312, lng: 20.322088 },
    { lat: 49.252666, lng: 20.322165 },
    { lat: 49.252462, lng: 20.322207 },
    { lat: 49.250996, lng: 20.322296 },
    { lat: 49.249641, lng: 20.32216 },
    { lat: 49.248664, lng: 20.322797 },
    { lat: 49.247825, lng: 20.323232 },
    { lat: 49.247056, lng: 20.323635 },
    { lat: 49.246152, lng: 20.324098 },
    { lat: 49.245604, lng: 20.324463 },
    { lat: 49.244728, lng: 20.325916 },
    { lat: 49.24391, lng: 20.326528 },
    { lat: 49.243564, lng: 20.326448 },
    { lat: 49.242904, lng: 20.326119 },
    { lat: 49.242618, lng: 20.326306 },
    { lat: 49.242425, lng: 20.326451 },
    { lat: 49.241296, lng: 20.326774 },
    { lat: 49.24093, lng: 20.326818 },
    { lat: 49.240237, lng: 20.32728 },
    { lat: 49.240195, lng: 20.327468 },
    { lat: 49.239689, lng: 20.328475 },
    { lat: 49.238942, lng: 20.328953 },
    { lat: 49.238191, lng: 20.329209 },
    { lat: 49.237753, lng: 20.329383 },
    { lat: 49.236911, lng: 20.329494 },
    { lat: 49.235919, lng: 20.329661 },
    { lat: 49.235512, lng: 20.329452 },
    { lat: 49.234975, lng: 20.329355 },
    { lat: 49.23403, lng: 20.328896 },
    { lat: 49.233531, lng: 20.328793 },
    { lat: 49.232945, lng: 20.328599 },
    { lat: 49.232005, lng: 20.329186 },
    { lat: 49.231631, lng: 20.329217 },
    { lat: 49.231072, lng: 20.328928 },
    { lat: 49.230576, lng: 20.32902 },
    { lat: 49.230526, lng: 20.329039 },
    { lat: 49.230436, lng: 20.329075 },
    { lat: 49.230864, lng: 20.328086 },
    { lat: 49.230761, lng: 20.327284 },
    { lat: 49.2306, lng: 20.327504 },
    { lat: 49.230229, lng: 20.328021 },
    { lat: 49.230011, lng: 20.328506 },
    { lat: 49.229607, lng: 20.329113 },
    { lat: 49.229436, lng: 20.329037 },
    { lat: 49.229359, lng: 20.329004 },
    { lat: 49.229368, lng: 20.329112 },
    { lat: 49.229114, lng: 20.329425 },
    { lat: 49.228875, lng: 20.329571 },
    { lat: 49.2286, lng: 20.329739 },
    { lat: 49.228351, lng: 20.330043 },
    { lat: 49.228324, lng: 20.330092 },
    { lat: 49.228501, lng: 20.330581 },
    { lat: 49.228833, lng: 20.331221 },
    { lat: 49.228925, lng: 20.331412 },
    { lat: 49.229322, lng: 20.331981 },
    { lat: 49.229441, lng: 20.332288 },
    { lat: 49.229391, lng: 20.332756 },
    { lat: 49.229241, lng: 20.33328 },
    { lat: 49.229099, lng: 20.333635 },
    { lat: 49.228971, lng: 20.33389 },
    { lat: 49.228604, lng: 20.334008 },
    { lat: 49.228341, lng: 20.334071 },
    { lat: 49.228083, lng: 20.334268 },
    { lat: 49.227837, lng: 20.334752 },
    { lat: 49.227517, lng: 20.335363 },
    { lat: 49.22706, lng: 20.335869 },
    { lat: 49.226969, lng: 20.335974 },
    { lat: 49.226735, lng: 20.336219 },
    { lat: 49.226581, lng: 20.336439 },
    { lat: 49.226185, lng: 20.337061 },
    { lat: 49.226054, lng: 20.33752 },
    { lat: 49.225981, lng: 20.337851 },
    { lat: 49.225989, lng: 20.338327 },
    { lat: 49.226044, lng: 20.338527 },
    { lat: 49.226122, lng: 20.338685 },
    { lat: 49.226491, lng: 20.338961 },
    { lat: 49.226683, lng: 20.339061 },
    { lat: 49.226991, lng: 20.339052 },
    { lat: 49.227347, lng: 20.338799 },
    { lat: 49.227727, lng: 20.338765 },
    { lat: 49.227848, lng: 20.338963 },
    { lat: 49.227871, lng: 20.339057 },
    { lat: 49.227807, lng: 20.339363 },
    { lat: 49.227685, lng: 20.339624 },
    { lat: 49.227294, lng: 20.340159 },
    { lat: 49.227087, lng: 20.340734 },
    { lat: 49.227047, lng: 20.341113 },
    { lat: 49.226621, lng: 20.341559 },
    { lat: 49.226517, lng: 20.341567 },
    { lat: 49.226413, lng: 20.341622 },
    { lat: 49.226285, lng: 20.341798 },
    { lat: 49.226167, lng: 20.34187 },
    { lat: 49.226079, lng: 20.342084 },
    { lat: 49.22605, lng: 20.342258 },
    { lat: 49.226048, lng: 20.342499 },
    { lat: 49.226096, lng: 20.342552 },
    { lat: 49.226175, lng: 20.342632 },
    { lat: 49.226911, lng: 20.34335 },
    { lat: 49.227078, lng: 20.343635 },
    { lat: 49.227106, lng: 20.343731 },
    { lat: 49.227143, lng: 20.343841 },
    { lat: 49.227108, lng: 20.344046 },
    { lat: 49.22678, lng: 20.344231 },
    { lat: 49.226742, lng: 20.344434 },
    { lat: 49.226188, lng: 20.344678 },
    { lat: 49.225712, lng: 20.344925 },
    { lat: 49.225525, lng: 20.345308 },
    { lat: 49.225405, lng: 20.346034 },
    { lat: 49.22537, lng: 20.346224 },
    { lat: 49.225143, lng: 20.346002 },
    { lat: 49.225039, lng: 20.346354 },
    { lat: 49.224835, lng: 20.346842 },
    { lat: 49.224846, lng: 20.347078 },
    { lat: 49.224894, lng: 20.347327 },
    { lat: 49.225044, lng: 20.347607 },
    { lat: 49.225119, lng: 20.347886 },
    { lat: 49.225089, lng: 20.348641 },
    { lat: 49.225038, lng: 20.348761 },
    { lat: 49.225007, lng: 20.34886 },
    { lat: 49.224978, lng: 20.349205 },
    { lat: 49.225005, lng: 20.349544 },
    { lat: 49.225196, lng: 20.350124 },
    { lat: 49.225268, lng: 20.350559 },
    { lat: 49.22519, lng: 20.350963 },
    { lat: 49.225235, lng: 20.351972 },
    { lat: 49.225261, lng: 20.352499 },
    { lat: 49.2254778, lng: 20.3535321 },
    { lat: 49.2256512, lng: 20.3537958 },
    { lat: 49.2259289, lng: 20.353931 },
    { lat: 49.2261318, lng: 20.3541156 },
    { lat: 49.2263691, lng: 20.3545509 },
    { lat: 49.2265298, lng: 20.3551017 },
    { lat: 49.2265823, lng: 20.3554421 },
    { lat: 49.2266512, lng: 20.3569461 },
    { lat: 49.2268282, lng: 20.3578028 },
    { lat: 49.2268574, lng: 20.3581827 },
    { lat: 49.2271624, lng: 20.3595943 },
    { lat: 49.2269901, lng: 20.3605041 },
    { lat: 49.2269543, lng: 20.3613078 },
    { lat: 49.2271264, lng: 20.362773 },
    { lat: 49.2270742, lng: 20.3631465 },
    { lat: 49.2269739, lng: 20.3634357 },
    { lat: 49.226593, lng: 20.3640749 },
    { lat: 49.2260301, lng: 20.3648412 },
    { lat: 49.2254239, lng: 20.3652717 },
    { lat: 49.2253368, lng: 20.3654675 },
    { lat: 49.2252833, lng: 20.3659567 },
    { lat: 49.2254468, lng: 20.3668153 },
    { lat: 49.2256198, lng: 20.367278 },
    { lat: 49.2256335, lng: 20.3674896 },
    { lat: 49.2255867, lng: 20.3676163 },
    { lat: 49.2252641, lng: 20.3678053 },
    { lat: 49.2245923, lng: 20.3679948 },
    { lat: 49.224439, lng: 20.3681079 },
    { lat: 49.2242106, lng: 20.3684643 },
    { lat: 49.2241004, lng: 20.3687367 },
    { lat: 49.2236848, lng: 20.3700867 },
    { lat: 49.2236482, lng: 20.3705193 },
    { lat: 49.2236787, lng: 20.3711387 },
    { lat: 49.2237896, lng: 20.3713842 },
    { lat: 49.2242512, lng: 20.3719458 },
    { lat: 49.2242812, lng: 20.3720573 },
    { lat: 49.2240975, lng: 20.3730844 },
    { lat: 49.2240856, lng: 20.3735645 },
    { lat: 49.2242737, lng: 20.3745449 },
    { lat: 49.2244654, lng: 20.3749352 },
    { lat: 49.2246322, lng: 20.3757352 },
    { lat: 49.2246144, lng: 20.3765116 },
    { lat: 49.2244249, lng: 20.3771786 },
    { lat: 49.2242343, lng: 20.3776422 },
    { lat: 49.2243342, lng: 20.3777522 },
    { lat: 49.2249327, lng: 20.3780348 },
    { lat: 49.227065, lng: 20.378801 },
    { lat: 49.2282552, lng: 20.3788616 },
    { lat: 49.2292094, lng: 20.378672 },
    { lat: 49.2327244, lng: 20.3784627 },
    { lat: 49.2341467, lng: 20.3778528 },
    { lat: 49.2351462, lng: 20.3772246 },
    { lat: 49.235815, lng: 20.3770445 },
    { lat: 49.2369796, lng: 20.376586 },
    { lat: 49.2377638, lng: 20.3764504 },
    { lat: 49.2380521, lng: 20.3766052 },
    { lat: 49.2398823, lng: 20.3766746 },
    { lat: 49.2403889, lng: 20.3768497 },
    { lat: 49.2412378, lng: 20.3769998 },
    { lat: 49.2415921, lng: 20.3765449 },
    { lat: 49.2422913, lng: 20.3763371 },
    { lat: 49.2425679, lng: 20.3768617 },
    { lat: 49.2427677, lng: 20.3770266 },
    { lat: 49.2430961, lng: 20.3776075 },
    { lat: 49.2435167, lng: 20.3779485 },
    { lat: 49.2436639, lng: 20.3779721 },
    { lat: 49.2439365, lng: 20.3779421 },
    { lat: 49.2443686, lng: 20.3776735 },
    { lat: 49.2455777, lng: 20.3766942 },
    { lat: 49.2468017, lng: 20.3774074 },
    { lat: 49.2481913, lng: 20.3775289 },
    { lat: 49.2487675, lng: 20.377464 },
    { lat: 49.2499676, lng: 20.3769601 },
    { lat: 49.2507986, lng: 20.3765208 },
    { lat: 49.2517809, lng: 20.3762388 },
    { lat: 49.2521514, lng: 20.3763914 },
    { lat: 49.2523226, lng: 20.3765687 },
    { lat: 49.2532773, lng: 20.3770359 },
    { lat: 49.2539065, lng: 20.3772096 },
    { lat: 49.2542919, lng: 20.3768037 },
    { lat: 49.2545291, lng: 20.3766759 },
    { lat: 49.2550482, lng: 20.375941 },
    { lat: 49.2553991, lng: 20.3751636 },
    { lat: 49.2561223, lng: 20.3739953 },
    { lat: 49.2562771, lng: 20.3734448 },
    { lat: 49.2564581, lng: 20.373026 },
    { lat: 49.2569967, lng: 20.3725052 },
    { lat: 49.2581518, lng: 20.3704692 },
    { lat: 49.258477, lng: 20.3696382 },
    { lat: 49.2589815, lng: 20.3692887 },
    { lat: 49.259396, lng: 20.3688167 },
    { lat: 49.2599462, lng: 20.3680132 },
    { lat: 49.2605899, lng: 20.3665031 },
    { lat: 49.2607553, lng: 20.3662605 },
    { lat: 49.2609811, lng: 20.3655097 },
    { lat: 49.2614345, lng: 20.3644397 },
    { lat: 49.2617018, lng: 20.3639826 },
    { lat: 49.2622809, lng: 20.3631941 },
    { lat: 49.2627905, lng: 20.3626811 },
    { lat: 49.2629696, lng: 20.3626258 },
    { lat: 49.263331, lng: 20.3627424 },
    { lat: 49.2642277, lng: 20.3623455 },
    { lat: 49.2649742, lng: 20.3619025 },
    { lat: 49.2655427, lng: 20.361992 },
    { lat: 49.265708, lng: 20.3619592 },
    { lat: 49.2659606, lng: 20.3620549 },
    { lat: 49.2662233, lng: 20.3624154 },
    { lat: 49.2664908, lng: 20.3626436 },
    { lat: 49.2675854, lng: 20.3628805 },
    { lat: 49.2701205, lng: 20.3629639 },
  ],
  Mlynčeky: [
    { lat: 49.1720808, lng: 20.4209548 },
    { lat: 49.1737105, lng: 20.4184352 },
    { lat: 49.1748138, lng: 20.4164115 },
    { lat: 49.1755755, lng: 20.4143451 },
    { lat: 49.1758933, lng: 20.4130411 },
    { lat: 49.1760796, lng: 20.4116565 },
    { lat: 49.1764372, lng: 20.4104911 },
    { lat: 49.1767336, lng: 20.4096319 },
    { lat: 49.1773372, lng: 20.4083587 },
    { lat: 49.1777131, lng: 20.4077628 },
    { lat: 49.1787079, lng: 20.4068869 },
    { lat: 49.1797853, lng: 20.405013 },
    { lat: 49.1800267, lng: 20.4043726 },
    { lat: 49.1801043, lng: 20.4042791 },
    { lat: 49.181482, lng: 20.4046228 },
    { lat: 49.1815852, lng: 20.404676 },
    { lat: 49.1817046, lng: 20.4043789 },
    { lat: 49.181808, lng: 20.4042557 },
    { lat: 49.1817074, lng: 20.4040674 },
    { lat: 49.1817993, lng: 20.4038207 },
    { lat: 49.1825107, lng: 20.4036985 },
    { lat: 49.1825564, lng: 20.4034486 },
    { lat: 49.1824551, lng: 20.4029731 },
    { lat: 49.1824652, lng: 20.4028528 },
    { lat: 49.1824412, lng: 20.4027555 },
    { lat: 49.1823697, lng: 20.4027665 },
    { lat: 49.1823002, lng: 20.4026892 },
    { lat: 49.1823183, lng: 20.4025688 },
    { lat: 49.1823504, lng: 20.4026185 },
    { lat: 49.1824014, lng: 20.4025644 },
    { lat: 49.1824899, lng: 20.4021355 },
    { lat: 49.1824302, lng: 20.4021119 },
    { lat: 49.1824556, lng: 20.4020658 },
    { lat: 49.1824186, lng: 20.4019485 },
    { lat: 49.1825086, lng: 20.4017699 },
    { lat: 49.182536, lng: 20.4015426 },
    { lat: 49.1826243, lng: 20.4013192 },
    { lat: 49.1826234, lng: 20.4011472 },
    { lat: 49.1828158, lng: 20.4005231 },
    { lat: 49.1831469, lng: 20.3989833 },
    { lat: 49.1833273, lng: 20.3986326 },
    { lat: 49.183345, lng: 20.3984763 },
    { lat: 49.1836762, lng: 20.397594 },
    { lat: 49.1836102, lng: 20.3974836 },
    { lat: 49.1836001, lng: 20.3973362 },
    { lat: 49.1837143, lng: 20.3971688 },
    { lat: 49.1837553, lng: 20.3964407 },
    { lat: 49.1838664, lng: 20.3962221 },
    { lat: 49.1840863, lng: 20.3959673 },
    { lat: 49.1839038, lng: 20.3953741 },
    { lat: 49.1839892, lng: 20.394953 },
    { lat: 49.1839861, lng: 20.3938645 },
    { lat: 49.1840961, lng: 20.3934139 },
    { lat: 49.1841875, lng: 20.3933288 },
    { lat: 49.1842464, lng: 20.3930979 },
    { lat: 49.1842296, lng: 20.3929241 },
    { lat: 49.1843087, lng: 20.3927267 },
    { lat: 49.1842454, lng: 20.3926013 },
    { lat: 49.1842339, lng: 20.3924465 },
    { lat: 49.1841407, lng: 20.3923359 },
    { lat: 49.1839926, lng: 20.3915792 },
    { lat: 49.183868, lng: 20.3914106 },
    { lat: 49.1839584, lng: 20.3913015 },
    { lat: 49.183869, lng: 20.39111 },
    { lat: 49.1838901, lng: 20.3908382 },
    { lat: 49.1837815, lng: 20.3906135 },
    { lat: 49.1838057, lng: 20.39048 },
    { lat: 49.1837235, lng: 20.3904541 },
    { lat: 49.1836602, lng: 20.3902967 },
    { lat: 49.1836944, lng: 20.3900421 },
    { lat: 49.1840757, lng: 20.3891427 },
    { lat: 49.1820749, lng: 20.3848297 },
    { lat: 49.181239, lng: 20.3834221 },
    { lat: 49.1810517, lng: 20.3828845 },
    { lat: 49.1808495, lng: 20.3820059 },
    { lat: 49.1810739, lng: 20.3802743 },
    { lat: 49.1813251, lng: 20.3776819 },
    { lat: 49.1816198, lng: 20.3760798 },
    { lat: 49.1817997, lng: 20.374482 },
    { lat: 49.1818654, lng: 20.3742253 },
    { lat: 49.1818273, lng: 20.3735556 },
    { lat: 49.1819153, lng: 20.3727035 },
    { lat: 49.1819572, lng: 20.3726814 },
    { lat: 49.1820683, lng: 20.3723476 },
    { lat: 49.1821943, lng: 20.3721942 },
    { lat: 49.182434, lng: 20.3715999 },
    { lat: 49.1825433, lng: 20.3715164 },
    { lat: 49.1826168, lng: 20.3713458 },
    { lat: 49.1828277, lng: 20.370388 },
    { lat: 49.1831128, lng: 20.3696554 },
    { lat: 49.1831632, lng: 20.3694141 },
    { lat: 49.1831357, lng: 20.3691938 },
    { lat: 49.1831909, lng: 20.3689599 },
    { lat: 49.1831258, lng: 20.3689035 },
    { lat: 49.1831897, lng: 20.3687481 },
    { lat: 49.1832354, lng: 20.368148 },
    { lat: 49.1833571, lng: 20.3679734 },
    { lat: 49.1833443, lng: 20.3678798 },
    { lat: 49.1835018, lng: 20.3675189 },
    { lat: 49.1835495, lng: 20.3672674 },
    { lat: 49.1836127, lng: 20.367243 },
    { lat: 49.1838009, lng: 20.3667144 },
    { lat: 49.1844847, lng: 20.3635477 },
    { lat: 49.184359, lng: 20.358192 },
    { lat: 49.18433, lng: 20.358176 },
    { lat: 49.184207, lng: 20.358591 },
    { lat: 49.18399, lng: 20.359059 },
    { lat: 49.183848, lng: 20.359195 },
    { lat: 49.183712, lng: 20.35923 },
    { lat: 49.183473, lng: 20.359229 },
    { lat: 49.183358, lng: 20.35923 },
    { lat: 49.182802, lng: 20.359661 },
    { lat: 49.182407, lng: 20.36026 },
    { lat: 49.182093, lng: 20.361021 },
    { lat: 49.181534, lng: 20.362064 },
    { lat: 49.181429, lng: 20.362238 },
    { lat: 49.181272, lng: 20.362589 },
    { lat: 49.180841, lng: 20.363416 },
    { lat: 49.180436, lng: 20.364021 },
    { lat: 49.180235, lng: 20.364322 },
    { lat: 49.179916, lng: 20.364727 },
    { lat: 49.179551, lng: 20.365379 },
    { lat: 49.179074, lng: 20.366272 },
    { lat: 49.178827, lng: 20.366678 },
    { lat: 49.178516, lng: 20.367241 },
    { lat: 49.178377, lng: 20.367525 },
    { lat: 49.178336, lng: 20.367581 },
    { lat: 49.178246, lng: 20.367653 },
    { lat: 49.178218, lng: 20.367674 },
    { lat: 49.178151, lng: 20.367684 },
    { lat: 49.178072, lng: 20.367649 },
    { lat: 49.177975, lng: 20.367538 },
    { lat: 49.177919, lng: 20.367475 },
    { lat: 49.177671, lng: 20.367409 },
    { lat: 49.17755, lng: 20.367375 },
    { lat: 49.177548, lng: 20.367398 },
    { lat: 49.177538, lng: 20.367439 },
    { lat: 49.177485, lng: 20.367414 },
    { lat: 49.17712, lng: 20.368458 },
    { lat: 49.176527, lng: 20.369978 },
    { lat: 49.176235, lng: 20.370607 },
    { lat: 49.17596, lng: 20.371135 },
    { lat: 49.175614, lng: 20.371739 },
    { lat: 49.174829, lng: 20.373064 },
    { lat: 49.174166, lng: 20.374074 },
    { lat: 49.173069, lng: 20.375695 },
    { lat: 49.172488, lng: 20.376594 },
    { lat: 49.171802, lng: 20.377654 },
    { lat: 49.171843, lng: 20.377717 },
    { lat: 49.172078, lng: 20.378232 },
    { lat: 49.172903, lng: 20.379124 },
    { lat: 49.173505, lng: 20.379767 },
    { lat: 49.174052, lng: 20.380501 },
    { lat: 49.174691, lng: 20.380711 },
    { lat: 49.174148, lng: 20.381739 },
    { lat: 49.173565, lng: 20.382844 },
    { lat: 49.172686, lng: 20.38333 },
    { lat: 49.171827, lng: 20.384084 },
    { lat: 49.170931, lng: 20.384875 },
    { lat: 49.170216, lng: 20.385012 },
    { lat: 49.1702, lng: 20.384546 },
    { lat: 49.170198, lng: 20.384464 },
    { lat: 49.170222, lng: 20.384052 },
    { lat: 49.169771, lng: 20.384088 },
    { lat: 49.169745, lng: 20.383417 },
    { lat: 49.169881, lng: 20.383236 },
    { lat: 49.171022, lng: 20.381448 },
    { lat: 49.170613, lng: 20.380401 },
    { lat: 49.170603, lng: 20.380377 },
    { lat: 49.170685, lng: 20.38015 },
    { lat: 49.1711, lng: 20.379095 },
    { lat: 49.171074, lng: 20.379056 },
    { lat: 49.171055, lng: 20.379027 },
    { lat: 49.171035, lng: 20.379009 },
    { lat: 49.170863, lng: 20.37886 },
    { lat: 49.170868, lng: 20.378839 },
    { lat: 49.170886, lng: 20.378756 },
    { lat: 49.17104, lng: 20.378285 },
    { lat: 49.171078, lng: 20.378183 },
    { lat: 49.17116, lng: 20.377936 },
    { lat: 49.171182, lng: 20.377629 },
    { lat: 49.171219, lng: 20.377466 },
    { lat: 49.171294, lng: 20.377359 },
    { lat: 49.171331, lng: 20.377208 },
    { lat: 49.171403, lng: 20.376842 },
    { lat: 49.171395, lng: 20.376822 },
    { lat: 49.17137, lng: 20.37675 },
    { lat: 49.171387, lng: 20.376683 },
    { lat: 49.171385, lng: 20.376574 },
    { lat: 49.171417, lng: 20.376529 },
    { lat: 49.171417, lng: 20.376493 },
    { lat: 49.171386, lng: 20.37641 },
    { lat: 49.171362, lng: 20.376382 },
    { lat: 49.171361, lng: 20.376276 },
    { lat: 49.171298, lng: 20.376218 },
    { lat: 49.171229, lng: 20.376053 },
    { lat: 49.171171, lng: 20.375975 },
    { lat: 49.171145, lng: 20.375924 },
    { lat: 49.171119, lng: 20.37595 },
    { lat: 49.171006, lng: 20.37617 },
    { lat: 49.170995, lng: 20.376274 },
    { lat: 49.170728, lng: 20.376451 },
    { lat: 49.170674, lng: 20.376459 },
    { lat: 49.170633, lng: 20.376477 },
    { lat: 49.17062, lng: 20.376514 },
    { lat: 49.17017, lng: 20.377755 },
    { lat: 49.169926, lng: 20.37843 },
    { lat: 49.169662, lng: 20.379154 },
    { lat: 49.169614, lng: 20.379288 },
    { lat: 49.16959, lng: 20.379351 },
    { lat: 49.169577, lng: 20.379388 },
    { lat: 49.169547, lng: 20.379353 },
    { lat: 49.168853, lng: 20.378531 },
    { lat: 49.168782, lng: 20.378447 },
    { lat: 49.168569, lng: 20.378194 },
    { lat: 49.1684941, lng: 20.378105 },
    { lat: 49.168463, lng: 20.378068 },
    { lat: 49.168418, lng: 20.378016 },
    { lat: 49.168309, lng: 20.378084 },
    { lat: 49.16797, lng: 20.378586 },
    { lat: 49.167419, lng: 20.379033 },
    { lat: 49.167338, lng: 20.379247 },
    { lat: 49.16728, lng: 20.37914 },
    { lat: 49.167247, lng: 20.379079 },
    { lat: 49.1672, lng: 20.378991 },
    { lat: 49.167341, lng: 20.378802 },
    { lat: 49.167455, lng: 20.378589 },
    { lat: 49.167645, lng: 20.378325 },
    { lat: 49.167686, lng: 20.378222 },
    { lat: 49.167809, lng: 20.377916 },
    { lat: 49.167878, lng: 20.377846 },
    { lat: 49.168032, lng: 20.377464 },
    { lat: 49.168096, lng: 20.377179 },
    { lat: 49.168026, lng: 20.377033 },
    { lat: 49.1677041, lng: 20.3767787 },
    { lat: 49.167999, lng: 20.3761552 },
    { lat: 49.167706, lng: 20.3758232 },
    { lat: 49.1666029, lng: 20.3781016 },
    { lat: 49.1648359, lng: 20.3830773 },
    { lat: 49.1656999, lng: 20.383566 },
    { lat: 49.1657315, lng: 20.3847786 },
    { lat: 49.1656555, lng: 20.3848529 },
    { lat: 49.1655088, lng: 20.3847238 },
    { lat: 49.1652023, lng: 20.3853676 },
    { lat: 49.1651925, lng: 20.3857614 },
    { lat: 49.1645504, lng: 20.3859316 },
    { lat: 49.1640892, lng: 20.3856472 },
    { lat: 49.1640791, lng: 20.3857046 },
    { lat: 49.1641818, lng: 20.3857437 },
    { lat: 49.164436, lng: 20.3861191 },
    { lat: 49.1644154, lng: 20.3869074 },
    { lat: 49.1645478, lng: 20.3873998 },
    { lat: 49.164728, lng: 20.3874914 },
    { lat: 49.164851, lng: 20.3876726 },
    { lat: 49.164997, lng: 20.3876334 },
    { lat: 49.1650605, lng: 20.3880869 },
    { lat: 49.1648498, lng: 20.3883545 },
    { lat: 49.1647055, lng: 20.3882881 },
    { lat: 49.1646374, lng: 20.3885697 },
    { lat: 49.1643693, lng: 20.3891242 },
    { lat: 49.1642741, lng: 20.389469 },
    { lat: 49.1637908, lng: 20.3903461 },
    { lat: 49.1635065, lng: 20.3907735 },
    { lat: 49.1632273, lng: 20.3910691 },
    { lat: 49.1630425, lng: 20.3915704 },
    { lat: 49.1596674, lng: 20.3886223 },
    { lat: 49.1593902, lng: 20.3891757 },
    { lat: 49.1585395, lng: 20.3908763 },
    { lat: 49.1582304, lng: 20.3913638 },
    { lat: 49.1572933, lng: 20.3925676 },
    { lat: 49.1565912, lng: 20.3936477 },
    { lat: 49.1551806, lng: 20.3961933 },
    { lat: 49.1536787, lng: 20.3990997 },
    { lat: 49.152114, lng: 20.4013931 },
    { lat: 49.1514275, lng: 20.4022594 },
    { lat: 49.1516601, lng: 20.4025116 },
    { lat: 49.1516441, lng: 20.4025705 },
    { lat: 49.1523755, lng: 20.4032102 },
    { lat: 49.1535089, lng: 20.4043846 },
    { lat: 49.15348, lng: 20.4044868 },
    { lat: 49.1547719, lng: 20.4056538 },
    { lat: 49.1548658, lng: 20.4060199 },
    { lat: 49.1549982, lng: 20.4061579 },
    { lat: 49.1549353, lng: 20.4072922 },
    { lat: 49.154817, lng: 20.4080861 },
    { lat: 49.1548197, lng: 20.408402 },
    { lat: 49.1549189, lng: 20.4089652 },
    { lat: 49.1551582, lng: 20.4092197 },
    { lat: 49.1552063, lng: 20.4093636 },
    { lat: 49.1552709, lng: 20.4094028 },
    { lat: 49.1562689, lng: 20.4100089 },
    { lat: 49.1581227, lng: 20.4114744 },
    { lat: 49.1582228, lng: 20.4109727 },
    { lat: 49.1601794, lng: 20.4129293 },
    { lat: 49.1611262, lng: 20.413963 },
    { lat: 49.1617536, lng: 20.4147434 },
    { lat: 49.1623911, lng: 20.4157353 },
    { lat: 49.1627024, lng: 20.4150031 },
    { lat: 49.1631954, lng: 20.4156242 },
    { lat: 49.1644341, lng: 20.4174155 },
    { lat: 49.1648341, lng: 20.416918 },
    { lat: 49.166198, lng: 20.418983 },
    { lat: 49.1660515, lng: 20.4191276 },
    { lat: 49.1661018, lng: 20.4192098 },
    { lat: 49.1662714, lng: 20.4192291 },
    { lat: 49.1665661, lng: 20.4195457 },
    { lat: 49.1673678, lng: 20.4198465 },
    { lat: 49.1677335, lng: 20.4198403 },
    { lat: 49.1681729, lng: 20.419675 },
    { lat: 49.1683164, lng: 20.4195652 },
    { lat: 49.1688272, lng: 20.4188723 },
    { lat: 49.1689588, lng: 20.418634 },
    { lat: 49.1690447, lng: 20.4182839 },
    { lat: 49.1720808, lng: 20.4209548 },
  ],
  Holumnica: [
    { lat: 49.1915712, lng: 20.6214074 },
    { lat: 49.1920314, lng: 20.6208123 },
    { lat: 49.1926151, lng: 20.6200493 },
    { lat: 49.1931568, lng: 20.6193379 },
    { lat: 49.1938797, lng: 20.6183094 },
    { lat: 49.1940832, lng: 20.6180259 },
    { lat: 49.1942873, lng: 20.6172572 },
    { lat: 49.1943212, lng: 20.6169477 },
    { lat: 49.1943402, lng: 20.616597 },
    { lat: 49.1943738, lng: 20.6165024 },
    { lat: 49.1943327, lng: 20.615705 },
    { lat: 49.1943257, lng: 20.6152301 },
    { lat: 49.1942736, lng: 20.6143804 },
    { lat: 49.194239, lng: 20.614109 },
    { lat: 49.1941788, lng: 20.6136904 },
    { lat: 49.1940736, lng: 20.6131144 },
    { lat: 49.1940366, lng: 20.612678 },
    { lat: 49.1941029, lng: 20.6123971 },
    { lat: 49.1942932, lng: 20.611866 },
    { lat: 49.1944418, lng: 20.6117423 },
    { lat: 49.1945267, lng: 20.611697 },
    { lat: 49.1946078, lng: 20.6114476 },
    { lat: 49.1947549, lng: 20.611437 },
    { lat: 49.1948101, lng: 20.611315 },
    { lat: 49.1949817, lng: 20.6113236 },
    { lat: 49.1950834, lng: 20.611337 },
    { lat: 49.1955057, lng: 20.6112151 },
    { lat: 49.1957626, lng: 20.6111629 },
    { lat: 49.1958953, lng: 20.6110607 },
    { lat: 49.1960355, lng: 20.6109859 },
    { lat: 49.196136, lng: 20.6109666 },
    { lat: 49.1963735, lng: 20.6107436 },
    { lat: 49.196597, lng: 20.6104538 },
    { lat: 49.196717, lng: 20.6103461 },
    { lat: 49.1968638, lng: 20.610107 },
    { lat: 49.1970858, lng: 20.6096178 },
    { lat: 49.1971925, lng: 20.6095473 },
    { lat: 49.1972135, lng: 20.6094444 },
    { lat: 49.1974864, lng: 20.6088952 },
    { lat: 49.1975667, lng: 20.6086901 },
    { lat: 49.197588, lng: 20.6085619 },
    { lat: 49.1976713, lng: 20.6084703 },
    { lat: 49.1978178, lng: 20.6082226 },
    { lat: 49.1978798, lng: 20.6081047 },
    { lat: 49.1979652, lng: 20.6080061 },
    { lat: 49.1980305, lng: 20.6076935 },
    { lat: 49.1981532, lng: 20.6076619 },
    { lat: 49.198236, lng: 20.6075898 },
    { lat: 49.198362, lng: 20.6074914 },
    { lat: 49.1985095, lng: 20.6072709 },
    { lat: 49.1985376, lng: 20.6071152 },
    { lat: 49.1986436, lng: 20.6071178 },
    { lat: 49.1989956, lng: 20.606893 },
    { lat: 49.1990919, lng: 20.6069133 },
    { lat: 49.1993921, lng: 20.6069302 },
    { lat: 49.1994558, lng: 20.6069511 },
    { lat: 49.1997371, lng: 20.60735 },
    { lat: 49.1997728, lng: 20.6074967 },
    { lat: 49.1998608, lng: 20.6075952 },
    { lat: 49.2000401, lng: 20.6076081 },
    { lat: 49.2002031, lng: 20.6077258 },
    { lat: 49.2003269, lng: 20.6077827 },
    { lat: 49.2005255, lng: 20.6079848 },
    { lat: 49.2005459, lng: 20.607955 },
    { lat: 49.2005762, lng: 20.6079255 },
    { lat: 49.2006738, lng: 20.6079759 },
    { lat: 49.2008373, lng: 20.6082322 },
    { lat: 49.2012784, lng: 20.6083556 },
    { lat: 49.2013011, lng: 20.6083241 },
    { lat: 49.2014446, lng: 20.6081241 },
    { lat: 49.2017076, lng: 20.6077704 },
    { lat: 49.2019857, lng: 20.6073993 },
    { lat: 49.2022249, lng: 20.607072 },
    { lat: 49.2027391, lng: 20.6063786 },
    { lat: 49.203252, lng: 20.6056852 },
    { lat: 49.203765, lng: 20.6049911 },
    { lat: 49.2042791, lng: 20.6042951 },
    { lat: 49.2047877, lng: 20.603606 },
    { lat: 49.2052985, lng: 20.6029098 },
    { lat: 49.2055494, lng: 20.60257 },
    { lat: 49.205809, lng: 20.6022155 },
    { lat: 49.2060625, lng: 20.6018733 },
    { lat: 49.2063178, lng: 20.6015289 },
    { lat: 49.2065755, lng: 20.6011799 },
    { lat: 49.2068287, lng: 20.6008374 },
    { lat: 49.2070831, lng: 20.6004951 },
    { lat: 49.2072751, lng: 20.6002344 },
    { lat: 49.2074651, lng: 20.5999779 },
    { lat: 49.2076571, lng: 20.5997172 },
    { lat: 49.2078492, lng: 20.5994591 },
    { lat: 49.2080402, lng: 20.5991953 },
    { lat: 49.2082324, lng: 20.5989396 },
    { lat: 49.2084232, lng: 20.5986787 },
    { lat: 49.208785, lng: 20.5981956 },
    { lat: 49.2093136, lng: 20.5974661 },
    { lat: 49.209641, lng: 20.5970219 },
    { lat: 49.2099274, lng: 20.5966337 },
    { lat: 49.2106542, lng: 20.5956578 },
    { lat: 49.210354, lng: 20.5942746 },
    { lat: 49.2098131, lng: 20.59181 },
    { lat: 49.2095567, lng: 20.5906356 },
    { lat: 49.2092935, lng: 20.5894352 },
    { lat: 49.2087743, lng: 20.5870718 },
    { lat: 49.2084938, lng: 20.5858228 },
    { lat: 49.2085554, lng: 20.5857283 },
    { lat: 49.2087986, lng: 20.5854171 },
    { lat: 49.2095042, lng: 20.5844814 },
    { lat: 49.2109173, lng: 20.5826042 },
    { lat: 49.2113522, lng: 20.5820233 },
    { lat: 49.212008, lng: 20.5811421 },
    { lat: 49.2127321, lng: 20.5801611 },
    { lat: 49.2127506, lng: 20.5801437 },
    { lat: 49.2126692, lng: 20.578711 },
    { lat: 49.2125753, lng: 20.5770914 },
    { lat: 49.2125103, lng: 20.5759872 },
    { lat: 49.2126078, lng: 20.5759105 },
    { lat: 49.2128303, lng: 20.5755614 },
    { lat: 49.2129625, lng: 20.5753568 },
    { lat: 49.2130743, lng: 20.5752145 },
    { lat: 49.2131284, lng: 20.5750291 },
    { lat: 49.2131861, lng: 20.5746969 },
    { lat: 49.2132475, lng: 20.574342 },
    { lat: 49.2133231, lng: 20.5741012 },
    { lat: 49.2134397, lng: 20.5738432 },
    { lat: 49.2135473, lng: 20.5736105 },
    { lat: 49.2136239, lng: 20.5733695 },
    { lat: 49.2136741, lng: 20.5728579 },
    { lat: 49.2137333, lng: 20.5724755 },
    { lat: 49.2137843, lng: 20.5719004 },
    { lat: 49.2138251, lng: 20.5717501 },
    { lat: 49.2138285, lng: 20.5716619 },
    { lat: 49.2138368, lng: 20.5714902 },
    { lat: 49.2138262, lng: 20.5713791 },
    { lat: 49.2138802, lng: 20.5712236 },
    { lat: 49.2139124, lng: 20.5711813 },
    { lat: 49.2139079, lng: 20.5710927 },
    { lat: 49.2139461, lng: 20.5710581 },
    { lat: 49.213929, lng: 20.5709591 },
    { lat: 49.2139623, lng: 20.5707327 },
    { lat: 49.2139976, lng: 20.5705568 },
    { lat: 49.2140321, lng: 20.5702089 },
    { lat: 49.2140154, lng: 20.5700292 },
    { lat: 49.2140255, lng: 20.5700273 },
    { lat: 49.2142282, lng: 20.5699651 },
    { lat: 49.2144575, lng: 20.5698948 },
    { lat: 49.21454, lng: 20.5697816 },
    { lat: 49.2147625, lng: 20.5697068 },
    { lat: 49.2150324, lng: 20.5694797 },
    { lat: 49.2150549, lng: 20.5693924 },
    { lat: 49.2150871, lng: 20.5692522 },
    { lat: 49.2151099, lng: 20.5692002 },
    { lat: 49.2151463, lng: 20.5692064 },
    { lat: 49.2151743, lng: 20.5691724 },
    { lat: 49.2152119, lng: 20.5690975 },
    { lat: 49.2152372, lng: 20.5690813 },
    { lat: 49.215353, lng: 20.5691691 },
    { lat: 49.2154916, lng: 20.5691745 },
    { lat: 49.2155473, lng: 20.5691889 },
    { lat: 49.2155957, lng: 20.5691884 },
    { lat: 49.2157437, lng: 20.5691384 },
    { lat: 49.215869, lng: 20.5690877 },
    { lat: 49.2159775, lng: 20.5689708 },
    { lat: 49.2160611, lng: 20.5688579 },
    { lat: 49.2161929, lng: 20.5687973 },
    { lat: 49.2162412, lng: 20.5687385 },
    { lat: 49.2163039, lng: 20.5686651 },
    { lat: 49.2164451, lng: 20.5686426 },
    { lat: 49.2165018, lng: 20.5685946 },
    { lat: 49.2166665, lng: 20.5685428 },
    { lat: 49.2168725, lng: 20.5684219 },
    { lat: 49.2169708, lng: 20.5683044 },
    { lat: 49.2170533, lng: 20.5681008 },
    { lat: 49.2171156, lng: 20.5681409 },
    { lat: 49.2172358, lng: 20.5681634 },
    { lat: 49.217513, lng: 20.5680153 },
    { lat: 49.2176107, lng: 20.5680444 },
    { lat: 49.2178686, lng: 20.5680138 },
    { lat: 49.2178984, lng: 20.5679951 },
    { lat: 49.2179348, lng: 20.5679787 },
    { lat: 49.2180075, lng: 20.5679991 },
    { lat: 49.218107, lng: 20.5681318 },
    { lat: 49.2182192, lng: 20.5681486 },
    { lat: 49.2183263, lng: 20.5682464 },
    { lat: 49.2185614, lng: 20.5683013 },
    { lat: 49.2186351, lng: 20.5682608 },
    { lat: 49.2189346, lng: 20.5681994 },
    { lat: 49.2190863, lng: 20.5684404 },
    { lat: 49.2192272, lng: 20.5685645 },
    { lat: 49.2193503, lng: 20.5686401 },
    { lat: 49.2194166, lng: 20.5686654 },
    { lat: 49.2195796, lng: 20.5685961 },
    { lat: 49.219642, lng: 20.5687352 },
    { lat: 49.2197219, lng: 20.5688239 },
    { lat: 49.2198311, lng: 20.5688866 },
    { lat: 49.2199148, lng: 20.5688975 },
    { lat: 49.2199999, lng: 20.5689486 },
    { lat: 49.2201027, lng: 20.5689528 },
    { lat: 49.2201469, lng: 20.568962 },
    { lat: 49.2201993, lng: 20.5689842 },
    { lat: 49.2205311, lng: 20.5693414 },
    { lat: 49.2207713, lng: 20.5693256 },
    { lat: 49.2207701, lng: 20.5692926 },
    { lat: 49.2207685, lng: 20.5690932 },
    { lat: 49.2207618, lng: 20.5687848 },
    { lat: 49.2207446, lng: 20.567977 },
    { lat: 49.2207365, lng: 20.5676013 },
    { lat: 49.2207161, lng: 20.5666739 },
    { lat: 49.2207146, lng: 20.5666013 },
    { lat: 49.2206971, lng: 20.5657453 },
    { lat: 49.2206841, lng: 20.5651194 },
    { lat: 49.2207473, lng: 20.5651173 },
    { lat: 49.2213071, lng: 20.5650988 },
    { lat: 49.2215266, lng: 20.5650949 },
    { lat: 49.2215885, lng: 20.5650975 },
    { lat: 49.2216502, lng: 20.5650898 },
    { lat: 49.2216712, lng: 20.5650833 },
    { lat: 49.2216784, lng: 20.5650607 },
    { lat: 49.2216958, lng: 20.5649224 },
    { lat: 49.2217007, lng: 20.5644376 },
    { lat: 49.2217455, lng: 20.5638809 },
    { lat: 49.2217877, lng: 20.5637059 },
    { lat: 49.2218473, lng: 20.5634581 },
    { lat: 49.2221241, lng: 20.5636413 },
    { lat: 49.2222012, lng: 20.5636873 },
    { lat: 49.2223772, lng: 20.563422 },
    { lat: 49.2224552, lng: 20.5633041 },
    { lat: 49.2225446, lng: 20.5631689 },
    { lat: 49.2225907, lng: 20.5630995 },
    { lat: 49.2226128, lng: 20.5629694 },
    { lat: 49.2226669, lng: 20.5626612 },
    { lat: 49.2227088, lng: 20.5624177 },
    { lat: 49.2227235, lng: 20.5623318 },
    { lat: 49.2227618, lng: 20.562146 },
    { lat: 49.2228163, lng: 20.5618817 },
    { lat: 49.2228459, lng: 20.5617372 },
    { lat: 49.2228546, lng: 20.5616955 },
    { lat: 49.2230412, lng: 20.5614779 },
    { lat: 49.2232895, lng: 20.5617963 },
    { lat: 49.2233164, lng: 20.5618546 },
    { lat: 49.2234159, lng: 20.562055 },
    { lat: 49.2234944, lng: 20.5621099 },
    { lat: 49.2237665, lng: 20.5619452 },
    { lat: 49.2238754, lng: 20.5620022 },
    { lat: 49.2239627, lng: 20.5620473 },
    { lat: 49.2240029, lng: 20.5620684 },
    { lat: 49.2241108, lng: 20.5621247 },
    { lat: 49.2242511, lng: 20.5619932 },
    { lat: 49.224383, lng: 20.5618718 },
    { lat: 49.2245181, lng: 20.5617472 },
    { lat: 49.2246183, lng: 20.5616547 },
    { lat: 49.2247653, lng: 20.5615208 },
    { lat: 49.2248373, lng: 20.5614574 },
    { lat: 49.2249179, lng: 20.5613835 },
    { lat: 49.2250499, lng: 20.5612704 },
    { lat: 49.225126, lng: 20.5611969 },
    { lat: 49.2251349, lng: 20.5611889 },
    { lat: 49.225137, lng: 20.5611873 },
    { lat: 49.225282, lng: 20.5610586 },
    { lat: 49.2254234, lng: 20.5609245 },
    { lat: 49.2251232, lng: 20.5602895 },
    { lat: 49.225099, lng: 20.5602404 },
    { lat: 49.2250661, lng: 20.5602032 },
    { lat: 49.2250098, lng: 20.5601411 },
    { lat: 49.2249699, lng: 20.5600969 },
    { lat: 49.2248772, lng: 20.5599924 },
    { lat: 49.224856, lng: 20.5599669 },
    { lat: 49.2248365, lng: 20.5597766 },
    { lat: 49.2248362, lng: 20.5597362 },
    { lat: 49.2248654, lng: 20.5596362 },
    { lat: 49.2248672, lng: 20.5596277 },
    { lat: 49.2248649, lng: 20.5591968 },
    { lat: 49.2248601, lng: 20.559002 },
    { lat: 49.2248559, lng: 20.5589512 },
    { lat: 49.2248541, lng: 20.5589031 },
    { lat: 49.2248771, lng: 20.5587261 },
    { lat: 49.2248799, lng: 20.5587125 },
    { lat: 49.2249043, lng: 20.5585775 },
    { lat: 49.2249333, lng: 20.5584184 },
    { lat: 49.2249372, lng: 20.5583097 },
    { lat: 49.2249413, lng: 20.5581123 },
    { lat: 49.2249443, lng: 20.5580079 },
    { lat: 49.2249461, lng: 20.557935 },
    { lat: 49.2250675, lng: 20.5576564 },
    { lat: 49.2250793, lng: 20.5576366 },
    { lat: 49.2251636, lng: 20.5574512 },
    { lat: 49.2252009, lng: 20.557363 },
    { lat: 49.2255193, lng: 20.5570841 },
    { lat: 49.2256988, lng: 20.5569405 },
    { lat: 49.225744, lng: 20.5568862 },
    { lat: 49.2258531, lng: 20.5567606 },
    { lat: 49.2259589, lng: 20.5566349 },
    { lat: 49.2261716, lng: 20.5564774 },
    { lat: 49.2262104, lng: 20.5564607 },
    { lat: 49.2262859, lng: 20.5564608 },
    { lat: 49.2268947, lng: 20.5566728 },
    { lat: 49.2269095, lng: 20.5566781 },
    { lat: 49.2271934, lng: 20.5565905 },
    { lat: 49.2272774, lng: 20.5564237 },
    { lat: 49.2274118, lng: 20.5561627 },
    { lat: 49.2274386, lng: 20.5561229 },
    { lat: 49.2278725, lng: 20.55552 },
    { lat: 49.2281765, lng: 20.555001 },
    { lat: 49.228316, lng: 20.5551515 },
    { lat: 49.2283887, lng: 20.5552293 },
    { lat: 49.2284104, lng: 20.5551781 },
    { lat: 49.2284882, lng: 20.5549957 },
    { lat: 49.2285526, lng: 20.5548479 },
    { lat: 49.2286322, lng: 20.5544067 },
    { lat: 49.2286358, lng: 20.5543837 },
    { lat: 49.2286008, lng: 20.5542891 },
    { lat: 49.2286216, lng: 20.5539361 },
    { lat: 49.2286278, lng: 20.5538282 },
    { lat: 49.228645, lng: 20.5537756 },
    { lat: 49.2287389, lng: 20.5535122 },
    { lat: 49.2287653, lng: 20.5534358 },
    { lat: 49.2288064, lng: 20.5531391 },
    { lat: 49.2288892, lng: 20.553038 },
    { lat: 49.2288823, lng: 20.5529391 },
    { lat: 49.2289712, lng: 20.5526618 },
    { lat: 49.2290136, lng: 20.5524993 },
    { lat: 49.229023, lng: 20.552488 },
    { lat: 49.229023, lng: 20.552467 },
    { lat: 49.229407, lng: 20.552095 },
    { lat: 49.229716, lng: 20.551474 },
    { lat: 49.229692, lng: 20.551293 },
    { lat: 49.229892, lng: 20.550889 },
    { lat: 49.229977, lng: 20.550422 },
    { lat: 49.230037, lng: 20.550419 },
    { lat: 49.230116, lng: 20.549896 },
    { lat: 49.23011, lng: 20.549424 },
    { lat: 49.230077, lng: 20.549259 },
    { lat: 49.230294, lng: 20.548617 },
    { lat: 49.230272, lng: 20.548504 },
    { lat: 49.230268, lng: 20.54837 },
    { lat: 49.23036, lng: 20.548127 },
    { lat: 49.230297, lng: 20.547865 },
    { lat: 49.230272, lng: 20.54755 },
    { lat: 49.230317, lng: 20.547379 },
    { lat: 49.230323, lng: 20.547186 },
    { lat: 49.230415, lng: 20.547089 },
    { lat: 49.230496, lng: 20.547159 },
    { lat: 49.230566, lng: 20.547116 },
    { lat: 49.230794, lng: 20.546608 },
    { lat: 49.230818, lng: 20.546196 },
    { lat: 49.230966, lng: 20.546097 },
    { lat: 49.231027, lng: 20.545904 },
    { lat: 49.231155, lng: 20.545748 },
    { lat: 49.231281, lng: 20.545757 },
    { lat: 49.231404, lng: 20.545696 },
    { lat: 49.231419, lng: 20.545667 },
    { lat: 49.231481, lng: 20.545495 },
    { lat: 49.231684, lng: 20.545185 },
    { lat: 49.231746, lng: 20.545131 },
    { lat: 49.231846, lng: 20.545076 },
    { lat: 49.231924, lng: 20.545011 },
    { lat: 49.23214, lng: 20.544784 },
    { lat: 49.232214, lng: 20.544691 },
    { lat: 49.232317, lng: 20.544493 },
    { lat: 49.232332, lng: 20.544463 },
    { lat: 49.232392, lng: 20.544402 },
    { lat: 49.232464, lng: 20.544364 },
    { lat: 49.232491, lng: 20.544363 },
    { lat: 49.232525, lng: 20.544417 },
    { lat: 49.2326, lng: 20.544448 },
    { lat: 49.232647, lng: 20.544378 },
    { lat: 49.23263, lng: 20.544293 },
    { lat: 49.232673, lng: 20.544084 },
    { lat: 49.232694, lng: 20.54403 },
    { lat: 49.232878, lng: 20.544013 },
    { lat: 49.232945, lng: 20.543965 },
    { lat: 49.23298, lng: 20.543719 },
    { lat: 49.233, lng: 20.543686 },
    { lat: 49.233082, lng: 20.543628 },
    { lat: 49.23314, lng: 20.543555 },
    { lat: 49.233422, lng: 20.54328 },
    { lat: 49.23334, lng: 20.542994 },
    { lat: 49.233339, lng: 20.542932 },
    { lat: 49.233347, lng: 20.542906 },
    { lat: 49.233376, lng: 20.542855 },
    { lat: 49.233437, lng: 20.542781 },
    { lat: 49.233489, lng: 20.542691 },
    { lat: 49.233528, lng: 20.542592 },
    { lat: 49.233761, lng: 20.542479 },
    { lat: 49.233917, lng: 20.542217 },
    { lat: 49.233939, lng: 20.542152 },
    { lat: 49.234126, lng: 20.541815 },
    { lat: 49.234183, lng: 20.541743 },
    { lat: 49.234426, lng: 20.541597 },
    { lat: 49.23463, lng: 20.541584 },
    { lat: 49.23481, lng: 20.541309 },
    { lat: 49.235002, lng: 20.541249 },
    { lat: 49.23503, lng: 20.541246 },
    { lat: 49.235107, lng: 20.541276 },
    { lat: 49.235217, lng: 20.541206 },
    { lat: 49.235256, lng: 20.541088 },
    { lat: 49.235334, lng: 20.540936 },
    { lat: 49.235367, lng: 20.54083 },
    { lat: 49.235307, lng: 20.540715 },
    { lat: 49.235306, lng: 20.540688 },
    { lat: 49.235386, lng: 20.540551 },
    { lat: 49.235458, lng: 20.540396 },
    { lat: 49.235548, lng: 20.540324 },
    { lat: 49.235702, lng: 20.540267 },
    { lat: 49.235721, lng: 20.540254 },
    { lat: 49.235738, lng: 20.540225 },
    { lat: 49.235748, lng: 20.540197 },
    { lat: 49.235758, lng: 20.540021 },
    { lat: 49.235808, lng: 20.539925 },
    { lat: 49.235883, lng: 20.53984 },
    { lat: 49.235906, lng: 20.539651 },
    { lat: 49.235886, lng: 20.539116 },
    { lat: 49.235912, lng: 20.539014 },
    { lat: 49.235937, lng: 20.538786 },
    { lat: 49.236002, lng: 20.538656 },
    { lat: 49.236081, lng: 20.53858 },
    { lat: 49.236204, lng: 20.538569 },
    { lat: 49.236258, lng: 20.538527 },
    { lat: 49.236329, lng: 20.538325 },
    { lat: 49.236569, lng: 20.538295 },
    { lat: 49.236593, lng: 20.538266 },
    { lat: 49.236717, lng: 20.538007 },
    { lat: 49.237143, lng: 20.537589 },
    { lat: 49.23722, lng: 20.537475 },
    { lat: 49.237324, lng: 20.537358 },
    { lat: 49.237346, lng: 20.537363 },
    { lat: 49.237406, lng: 20.537393 },
    { lat: 49.237499, lng: 20.53744 },
    { lat: 49.237554, lng: 20.537379 },
    { lat: 49.23769, lng: 20.537307 },
    { lat: 49.237708, lng: 20.537297 },
    { lat: 49.237858, lng: 20.53713 },
    { lat: 49.237899, lng: 20.536976 },
    { lat: 49.238015, lng: 20.536838 },
    { lat: 49.238037, lng: 20.536827 },
    { lat: 49.238156, lng: 20.536851 },
    { lat: 49.238234, lng: 20.536797 },
    { lat: 49.238405, lng: 20.536437 },
    { lat: 49.238465, lng: 20.536345 },
    { lat: 49.23848, lng: 20.536328 },
    { lat: 49.238589, lng: 20.53632 },
    { lat: 49.238641, lng: 20.536274 },
    { lat: 49.238695, lng: 20.536172 },
    { lat: 49.238735, lng: 20.536035 },
    { lat: 49.238908, lng: 20.535707 },
    { lat: 49.239001, lng: 20.535419 },
    { lat: 49.238989, lng: 20.535288 },
    { lat: 49.239063, lng: 20.535107 },
    { lat: 49.239175, lng: 20.535075 },
    { lat: 49.239193, lng: 20.535091 },
    { lat: 49.239325, lng: 20.535341 },
    { lat: 49.239394, lng: 20.535381 },
    { lat: 49.239454, lng: 20.535371 },
    { lat: 49.239497, lng: 20.535314 },
    { lat: 49.239568, lng: 20.535061 },
    { lat: 49.23961, lng: 20.534568 },
    { lat: 49.239685, lng: 20.534403 },
    { lat: 49.239719, lng: 20.533629 },
    { lat: 49.239748, lng: 20.533184 },
    { lat: 49.239847, lng: 20.532862 },
    { lat: 49.239891, lng: 20.532732 },
    { lat: 49.239902, lng: 20.532642 },
    { lat: 49.239834, lng: 20.532435 },
    { lat: 49.239751, lng: 20.53239 },
    { lat: 49.239706, lng: 20.532272 },
    { lat: 49.239706, lng: 20.532239 },
    { lat: 49.239741, lng: 20.532101 },
    { lat: 49.23975, lng: 20.531918 },
    { lat: 49.239717, lng: 20.53182 },
    { lat: 49.239626, lng: 20.531756 },
    { lat: 49.239531, lng: 20.531536 },
    { lat: 49.239533, lng: 20.53149 },
    { lat: 49.23961, lng: 20.531482 },
    { lat: 49.23984, lng: 20.531103 },
    { lat: 49.23989, lng: 20.5309 },
    { lat: 49.239861, lng: 20.530636 },
    { lat: 49.239831, lng: 20.530495 },
    { lat: 49.239702, lng: 20.530399 },
    { lat: 49.239663, lng: 20.530293 },
    { lat: 49.239728, lng: 20.53015 },
    { lat: 49.240038, lng: 20.529001 },
    { lat: 49.240043, lng: 20.528877 },
    { lat: 49.240009, lng: 20.528784 },
    { lat: 49.239917, lng: 20.528619 },
    { lat: 49.239903, lng: 20.528436 },
    { lat: 49.239901, lng: 20.528394 },
    { lat: 49.240013, lng: 20.528337 },
    { lat: 49.240074, lng: 20.528251 },
    { lat: 49.240126, lng: 20.528122 },
    { lat: 49.240104, lng: 20.527894 },
    { lat: 49.240024, lng: 20.527741 },
    { lat: 49.240032, lng: 20.527572 },
    { lat: 49.240156, lng: 20.527257 },
    { lat: 49.240154, lng: 20.527025 },
    { lat: 49.240215, lng: 20.526849 },
    { lat: 49.240465, lng: 20.526445 },
    { lat: 49.240494, lng: 20.526327 },
    { lat: 49.240484, lng: 20.526158 },
    { lat: 49.240517, lng: 20.525831 },
    { lat: 49.240461, lng: 20.525631 },
    { lat: 49.240465, lng: 20.525501 },
    { lat: 49.240534, lng: 20.525278 },
    { lat: 49.240549, lng: 20.525144 },
    { lat: 49.240593, lng: 20.525035 },
    { lat: 49.240841, lng: 20.524969 },
    { lat: 49.240958, lng: 20.524867 },
    { lat: 49.240975, lng: 20.524853 },
    { lat: 49.240978, lng: 20.524849 },
    { lat: 49.241001, lng: 20.524785 },
    { lat: 49.241024, lng: 20.524683 },
    { lat: 49.241033, lng: 20.524527 },
    { lat: 49.241129, lng: 20.524481 },
    { lat: 49.241203, lng: 20.524511 },
    { lat: 49.241217, lng: 20.524523 },
    { lat: 49.241304, lng: 20.524654 },
    { lat: 49.241322, lng: 20.524646 },
    { lat: 49.241349, lng: 20.524647 },
    { lat: 49.241373, lng: 20.524612 },
    { lat: 49.241634, lng: 20.5243 },
    { lat: 49.241625, lng: 20.524223 },
    { lat: 49.241765, lng: 20.523935 },
    { lat: 49.241881, lng: 20.523616 },
    { lat: 49.241996, lng: 20.523494 },
    { lat: 49.242079, lng: 20.523631 },
    { lat: 49.242299, lng: 20.523813 },
    { lat: 49.242372, lng: 20.523785 },
    { lat: 49.242546, lng: 20.523625 },
    { lat: 49.242684, lng: 20.523462 },
    { lat: 49.242703, lng: 20.523454 },
    { lat: 49.242796, lng: 20.523527 },
    { lat: 49.242891, lng: 20.523485 },
    { lat: 49.24308, lng: 20.523336 },
    { lat: 49.243115, lng: 20.5233 },
    { lat: 49.24318, lng: 20.52302 },
    { lat: 49.243202, lng: 20.523002 },
    { lat: 49.243448, lng: 20.523052 },
    { lat: 49.243629, lng: 20.523064 },
    { lat: 49.243735, lng: 20.522991 },
    { lat: 49.243752, lng: 20.522944 },
    { lat: 49.24376, lng: 20.522789 },
    { lat: 49.243928, lng: 20.522394 },
    { lat: 49.244003, lng: 20.522344 },
    { lat: 49.244408, lng: 20.522036 },
    { lat: 49.244581, lng: 20.521672 },
    { lat: 49.244708, lng: 20.521369 },
    { lat: 49.244718, lng: 20.521333 },
    { lat: 49.244728, lng: 20.521166 },
    { lat: 49.244796, lng: 20.521086 },
    { lat: 49.244817, lng: 20.52108 },
    { lat: 49.244979, lng: 20.521158 },
    { lat: 49.245031, lng: 20.521127 },
    { lat: 49.245002, lng: 20.520983 },
    { lat: 49.245022, lng: 20.520866 },
    { lat: 49.245033, lng: 20.520846 },
    { lat: 49.245177, lng: 20.52075 },
    { lat: 49.24535, lng: 20.520682 },
    { lat: 49.245629, lng: 20.52061 },
    { lat: 49.245659, lng: 20.520596 },
    { lat: 49.245721, lng: 20.520486 },
    { lat: 49.24576, lng: 20.520394 },
    { lat: 49.245849, lng: 20.520355 },
    { lat: 49.245909, lng: 20.520218 },
    { lat: 49.246026, lng: 20.520132 },
    { lat: 49.246048, lng: 20.52011 },
    { lat: 49.246147, lng: 20.519793 },
    { lat: 49.246196, lng: 20.519701 },
    { lat: 49.24623, lng: 20.519658 },
    { lat: 49.246277, lng: 20.51967 },
    { lat: 49.246303, lng: 20.519752 },
    { lat: 49.246349, lng: 20.519746 },
    { lat: 49.2464, lng: 20.519633 },
    { lat: 49.246493, lng: 20.519523 },
    { lat: 49.24665, lng: 20.519451 },
    { lat: 49.24671, lng: 20.519428 },
    { lat: 49.2467717, lng: 20.519383 },
    { lat: 49.246793, lng: 20.519396 },
    { lat: 49.246805, lng: 20.519409 },
    { lat: 49.2468801, lng: 20.5192657 },
    { lat: 49.2469571, lng: 20.5192026 },
    { lat: 49.247045, lng: 20.51923 },
    { lat: 49.247159, lng: 20.51929 },
    { lat: 49.247224, lng: 20.519328 },
    { lat: 49.247307, lng: 20.519399 },
    { lat: 49.24737, lng: 20.519469 },
    { lat: 49.247526, lng: 20.519712 },
    { lat: 49.247634, lng: 20.519919 },
    { lat: 49.24769, lng: 20.520059 },
    { lat: 49.247704, lng: 20.520048 },
    { lat: 49.2477783, lng: 20.5200595 },
    { lat: 49.247852, lng: 20.520071 },
    { lat: 49.248121, lng: 20.519959 },
    { lat: 49.249395, lng: 20.518423 },
    { lat: 49.249587, lng: 20.518227 },
    { lat: 49.249535, lng: 20.518001 },
    { lat: 49.249453, lng: 20.517629 },
    { lat: 49.249411, lng: 20.517244 },
    { lat: 49.249323, lng: 20.516834 },
    { lat: 49.249214, lng: 20.516518 },
    { lat: 49.248986, lng: 20.51598 },
    { lat: 49.249298, lng: 20.515895 },
    { lat: 49.249117, lng: 20.515506 },
    { lat: 49.249075, lng: 20.515274 },
    { lat: 49.249056, lng: 20.515147 },
    { lat: 49.248987, lng: 20.514469 },
    { lat: 49.248884, lng: 20.513418 },
    { lat: 49.248846, lng: 20.513311 },
    { lat: 49.248732, lng: 20.512936 },
    { lat: 49.248906, lng: 20.512716 },
    { lat: 49.24877, lng: 20.512378 },
    { lat: 49.248607, lng: 20.512132 },
    { lat: 49.248554, lng: 20.512027 },
    { lat: 49.248471, lng: 20.511854 },
    { lat: 49.24839, lng: 20.511684 },
    { lat: 49.248306, lng: 20.511473 },
    { lat: 49.248235, lng: 20.511564 },
    { lat: 49.247995, lng: 20.511755 },
    { lat: 49.247389, lng: 20.512245 },
    { lat: 49.247275, lng: 20.511718 },
    { lat: 49.247209, lng: 20.511446 },
    { lat: 49.24702, lng: 20.510754 },
    { lat: 49.246969, lng: 20.510586 },
    { lat: 49.246792, lng: 20.510391 },
    { lat: 49.246674, lng: 20.510239 },
    { lat: 49.24661, lng: 20.510079 },
    { lat: 49.246573, lng: 20.509842 },
    { lat: 49.246557, lng: 20.509688 },
    { lat: 49.246555, lng: 20.509647 },
    { lat: 49.246539, lng: 20.509456 },
    { lat: 49.246548, lng: 20.509232 },
    { lat: 49.24656, lng: 20.509091 },
    { lat: 49.246583, lng: 20.508948 },
    { lat: 49.246661, lng: 20.508741 },
    { lat: 49.246727, lng: 20.508535 },
    { lat: 49.2466653, lng: 20.508442 },
    { lat: 49.2467783, lng: 20.5078099 },
    { lat: 49.2470464, lng: 20.5070243 },
    { lat: 49.2470828, lng: 20.5066432 },
    { lat: 49.2472326, lng: 20.506062 },
    { lat: 49.2474735, lng: 20.5056372 },
    { lat: 49.2475294, lng: 20.5052605 },
    { lat: 49.2470614, lng: 20.5040884 },
    { lat: 49.2466211, lng: 20.5023777 },
    { lat: 49.2464201, lng: 20.5018484 },
    { lat: 49.2463251, lng: 20.501621 },
    { lat: 49.246151, lng: 20.5019978 },
    { lat: 49.2453509, lng: 20.5010638 },
    { lat: 49.2436943, lng: 20.4994716 },
    { lat: 49.2428263, lng: 20.4985263 },
    { lat: 49.2421405, lng: 20.497883 },
    { lat: 49.2413903, lng: 20.4969628 },
    { lat: 49.24051, lng: 20.4960549 },
    { lat: 49.2404103, lng: 20.4958867 },
    { lat: 49.2402941, lng: 20.495851 },
    { lat: 49.2402517, lng: 20.496069 },
    { lat: 49.2401889, lng: 20.4960471 },
    { lat: 49.2401222, lng: 20.4961332 },
    { lat: 49.2400359, lng: 20.4963568 },
    { lat: 49.2400611, lng: 20.4964876 },
    { lat: 49.2399835, lng: 20.4966997 },
    { lat: 49.2399774, lng: 20.4970234 },
    { lat: 49.2398094, lng: 20.4976846 },
    { lat: 49.2398421, lng: 20.4977139 },
    { lat: 49.2398088, lng: 20.4978223 },
    { lat: 49.239722, lng: 20.4977264 },
    { lat: 49.2395717, lng: 20.4984712 },
    { lat: 49.2390149, lng: 20.4985712 },
    { lat: 49.2388393, lng: 20.4985096 },
    { lat: 49.238716, lng: 20.4993158 },
    { lat: 49.2377144, lng: 20.4989034 },
    { lat: 49.2374631, lng: 20.4989529 },
    { lat: 49.2365905, lng: 20.4987116 },
    { lat: 49.2361828, lng: 20.4985178 },
    { lat: 49.2357799, lng: 20.4980997 },
    { lat: 49.2356095, lng: 20.4983915 },
    { lat: 49.2353012, lng: 20.498335 },
    { lat: 49.2349338, lng: 20.4980515 },
    { lat: 49.2340455, lng: 20.4976297 },
    { lat: 49.2337575, lng: 20.4963574 },
    { lat: 49.2332877, lng: 20.4958602 },
    { lat: 49.2320612, lng: 20.4951802 },
    { lat: 49.2308726, lng: 20.4949736 },
    { lat: 49.2305955, lng: 20.4952757 },
    { lat: 49.2304367, lng: 20.4952736 },
    { lat: 49.2303063, lng: 20.4954041 },
    { lat: 49.2302263, lng: 20.495371 },
    { lat: 49.2299348, lng: 20.4956142 },
    { lat: 49.2295167, lng: 20.4964519 },
    { lat: 49.2272511, lng: 20.4967393 },
    { lat: 49.2268813, lng: 20.4966679 },
    { lat: 49.2268396, lng: 20.4977233 },
    { lat: 49.227032, lng: 20.4985427 },
    { lat: 49.2268994, lng: 20.4996092 },
    { lat: 49.2268063, lng: 20.5007176 },
    { lat: 49.2266907, lng: 20.5020939 },
    { lat: 49.2266469, lng: 20.5020963 },
    { lat: 49.226447, lng: 20.502107 },
    { lat: 49.2262614, lng: 20.5036946 },
    { lat: 49.2262809, lng: 20.5045523 },
    { lat: 49.2262158, lng: 20.5049221 },
    { lat: 49.2262552, lng: 20.505164 },
    { lat: 49.2262028, lng: 20.5056666 },
    { lat: 49.2260947, lng: 20.5059699 },
    { lat: 49.226176, lng: 20.5060389 },
    { lat: 49.226129, lng: 20.5064078 },
    { lat: 49.2261855, lng: 20.5066576 },
    { lat: 49.2261408, lng: 20.5068527 },
    { lat: 49.2260286, lng: 20.5070158 },
    { lat: 49.2253195, lng: 20.5074098 },
    { lat: 49.2249623, lng: 20.5077994 },
    { lat: 49.2248618, lng: 20.507396 },
    { lat: 49.2239119, lng: 20.5087692 },
    { lat: 49.223692, lng: 20.5086423 },
    { lat: 49.2231648, lng: 20.5079303 },
    { lat: 49.2220503, lng: 20.5089351 },
    { lat: 49.2219919, lng: 20.5090896 },
    { lat: 49.2207908, lng: 20.5098224 },
    { lat: 49.2191365, lng: 20.5111776 },
    { lat: 49.2189381, lng: 20.5112678 },
    { lat: 49.2182739, lng: 20.5113589 },
    { lat: 49.2163881, lng: 20.5126627 },
    { lat: 49.2154881, lng: 20.513624 },
    { lat: 49.2149752, lng: 20.5140891 },
    { lat: 49.2147097, lng: 20.5141573 },
    { lat: 49.2145956, lng: 20.5142687 },
    { lat: 49.2143245, lng: 20.5147012 },
    { lat: 49.2141127, lng: 20.5152189 },
    { lat: 49.2140942, lng: 20.5154156 },
    { lat: 49.2144166, lng: 20.5161599 },
    { lat: 49.2144953, lng: 20.5161568 },
    { lat: 49.2148196, lng: 20.5167997 },
    { lat: 49.2152625, lng: 20.5172701 },
    { lat: 49.2153548, lng: 20.517143 },
    { lat: 49.2165867, lng: 20.5193788 },
    { lat: 49.2158422, lng: 20.5214768 },
    { lat: 49.2160895, lng: 20.5220731 },
    { lat: 49.2163003, lng: 20.5222945 },
    { lat: 49.216398, lng: 20.5229012 },
    { lat: 49.2165614, lng: 20.5231169 },
    { lat: 49.2165784, lng: 20.5232458 },
    { lat: 49.2164904, lng: 20.5237903 },
    { lat: 49.2165179, lng: 20.5244703 },
    { lat: 49.2164719, lng: 20.5246875 },
    { lat: 49.2166071, lng: 20.5249391 },
    { lat: 49.2166417, lng: 20.5251425 },
    { lat: 49.2168057, lng: 20.5255321 },
    { lat: 49.2164757, lng: 20.5268709 },
    { lat: 49.2166338, lng: 20.5272812 },
    { lat: 49.2163049, lng: 20.5277934 },
    { lat: 49.2160732, lng: 20.5282909 },
    { lat: 49.2159594, lng: 20.528745 },
    { lat: 49.2163186, lng: 20.5292086 },
    { lat: 49.2163983, lng: 20.5302717 },
    { lat: 49.2164656, lng: 20.5304798 },
    { lat: 49.2162086, lng: 20.5312329 },
    { lat: 49.2159041, lng: 20.5316181 },
    { lat: 49.2160129, lng: 20.5319714 },
    { lat: 49.2156503, lng: 20.5323946 },
    { lat: 49.2152716, lng: 20.5332118 },
    { lat: 49.2143893, lng: 20.5346299 },
    { lat: 49.2141638, lng: 20.5351055 },
    { lat: 49.2140789, lng: 20.5353425 },
    { lat: 49.2138513, lng: 20.5367437 },
    { lat: 49.2134024, lng: 20.5388941 },
    { lat: 49.2131525, lng: 20.5395726 },
    { lat: 49.212789, lng: 20.5401561 },
    { lat: 49.2126752, lng: 20.5415605 },
    { lat: 49.2128269, lng: 20.5429609 },
    { lat: 49.2128159, lng: 20.5432477 },
    { lat: 49.2122624, lng: 20.5446407 },
    { lat: 49.2120148, lng: 20.5466934 },
    { lat: 49.2121718, lng: 20.5475698 },
    { lat: 49.2120916, lng: 20.5488186 },
    { lat: 49.2121851, lng: 20.549651 },
    { lat: 49.212173, lng: 20.5510718 },
    { lat: 49.2119869, lng: 20.5523545 },
    { lat: 49.2115888, lng: 20.5535933 },
    { lat: 49.2112839, lng: 20.5549188 },
    { lat: 49.2117839, lng: 20.5577251 },
    { lat: 49.2116775, lng: 20.5584802 },
    { lat: 49.21102, lng: 20.5597688 },
    { lat: 49.2106239, lng: 20.5598302 },
    { lat: 49.210452, lng: 20.5600595 },
    { lat: 49.2102662, lng: 20.5604876 },
    { lat: 49.2101544, lng: 20.561281 },
    { lat: 49.2095976, lng: 20.5629263 },
    { lat: 49.2088701, lng: 20.5644868 },
    { lat: 49.208415, lng: 20.5648485 },
    { lat: 49.2069038, lng: 20.5651442 },
    { lat: 49.2061922, lng: 20.56604 },
    { lat: 49.2052373, lng: 20.5677845 },
    { lat: 49.2049331, lng: 20.5698744 },
    { lat: 49.204998, lng: 20.571808 },
    { lat: 49.2047478, lng: 20.5720841 },
    { lat: 49.2039101, lng: 20.5741782 },
    { lat: 49.2035557, lng: 20.5755101 },
    { lat: 49.2035097, lng: 20.5759435 },
    { lat: 49.2035127, lng: 20.5766481 },
    { lat: 49.2036377, lng: 20.577418 },
    { lat: 49.2040121, lng: 20.5784623 },
    { lat: 49.2042006, lng: 20.5794 },
    { lat: 49.2042011, lng: 20.5796931 },
    { lat: 49.2042783, lng: 20.5802071 },
    { lat: 49.2028229, lng: 20.5815052 },
    { lat: 49.2023872, lng: 20.5828363 },
    { lat: 49.2022104, lng: 20.583117 },
    { lat: 49.2021552, lng: 20.5832415 },
    { lat: 49.2019191, lng: 20.5848574 },
    { lat: 49.2015702, lng: 20.5865307 },
    { lat: 49.2008336, lng: 20.5878839 },
    { lat: 49.2003436, lng: 20.5889611 },
    { lat: 49.1999212, lng: 20.5897546 },
    { lat: 49.1994053, lng: 20.5903972 },
    { lat: 49.1993474, lng: 20.5906019 },
    { lat: 49.1992595, lng: 20.5907213 },
    { lat: 49.1991787, lng: 20.5911673 },
    { lat: 49.1989234, lng: 20.5917306 },
    { lat: 49.198805, lng: 20.5922521 },
    { lat: 49.1982005, lng: 20.593678 },
    { lat: 49.1969585, lng: 20.5947242 },
    { lat: 49.1964311, lng: 20.5953641 },
    { lat: 49.1955808, lng: 20.5967177 },
    { lat: 49.194968, lng: 20.5979504 },
    { lat: 49.194776, lng: 20.5981207 },
    { lat: 49.1943403, lng: 20.5987377 },
    { lat: 49.1936779, lng: 20.5994076 },
    { lat: 49.1931474, lng: 20.5997724 },
    { lat: 49.1925842, lng: 20.5997961 },
    { lat: 49.1922666, lng: 20.6000986 },
    { lat: 49.1911547, lng: 20.600448 },
    { lat: 49.1909435, lng: 20.6006486 },
    { lat: 49.1903088, lng: 20.6015227 },
    { lat: 49.1902381, lng: 20.601686 },
    { lat: 49.1898736, lng: 20.6020957 },
    { lat: 49.1893388, lng: 20.6026569 },
    { lat: 49.1887397, lng: 20.6031137 },
    { lat: 49.1885863, lng: 20.6035103 },
    { lat: 49.1883233, lng: 20.6038355 },
    { lat: 49.1882136, lng: 20.6041611 },
    { lat: 49.1876669, lng: 20.6048874 },
    { lat: 49.1877835, lng: 20.6065237 },
    { lat: 49.1878065, lng: 20.60755 },
    { lat: 49.1878145, lng: 20.6079099 },
    { lat: 49.1877621, lng: 20.6086611 },
    { lat: 49.1877463, lng: 20.6088869 },
    { lat: 49.1878084, lng: 20.6093928 },
    { lat: 49.1877962, lng: 20.6095106 },
    { lat: 49.1876983, lng: 20.610453 },
    { lat: 49.1876506, lng: 20.6109118 },
    { lat: 49.1876537, lng: 20.6116273 },
    { lat: 49.1876541, lng: 20.6117216 },
    { lat: 49.1877146, lng: 20.6124018 },
    { lat: 49.1878041, lng: 20.6129287 },
    { lat: 49.1879977, lng: 20.6140694 },
    { lat: 49.1880589, lng: 20.6143911 },
    { lat: 49.1881836, lng: 20.6145972 },
    { lat: 49.1884566, lng: 20.6153566 },
    { lat: 49.1885177, lng: 20.6155265 },
    { lat: 49.1887534, lng: 20.6160109 },
    { lat: 49.1888701, lng: 20.6163433 },
    { lat: 49.1891406, lng: 20.616787 },
    { lat: 49.1894196, lng: 20.6174056 },
    { lat: 49.1898838, lng: 20.6181364 },
    { lat: 49.1901243, lng: 20.6185312 },
    { lat: 49.1902724, lng: 20.6189809 },
    { lat: 49.190518, lng: 20.6192043 },
    { lat: 49.1907943, lng: 20.6198476 },
    { lat: 49.1910791, lng: 20.620315 },
    { lat: 49.1913069, lng: 20.6208224 },
    { lat: 49.1915712, lng: 20.6214074 },
  ],
  Vlkovce: [
    { lat: 49.0610056, lng: 20.4906124 },
    { lat: 49.0608472, lng: 20.4899257 },
    { lat: 49.0603506, lng: 20.4887479 },
    { lat: 49.0601178, lng: 20.4885113 },
    { lat: 49.0592205, lng: 20.4880284 },
    { lat: 49.0589725, lng: 20.4876912 },
    { lat: 49.0585952, lng: 20.4863309 },
    { lat: 49.0583415, lng: 20.4858345 },
    { lat: 49.0582567, lng: 20.4854971 },
    { lat: 49.0575633, lng: 20.4855596 },
    { lat: 49.0572076, lng: 20.4858029 },
    { lat: 49.05684, lng: 20.4850951 },
    { lat: 49.0567408, lng: 20.4846033 },
    { lat: 49.0566155, lng: 20.482943 },
    { lat: 49.056499, lng: 20.4822599 },
    { lat: 49.0560127, lng: 20.4814817 },
    { lat: 49.0558211, lng: 20.4809009 },
    { lat: 49.0557256, lng: 20.4806059 },
    { lat: 49.0556012, lng: 20.4810129 },
    { lat: 49.0547897, lng: 20.4823489 },
    { lat: 49.0544497, lng: 20.4827976 },
    { lat: 49.0534398, lng: 20.4837992 },
    { lat: 49.052994, lng: 20.484472 },
    { lat: 49.053152, lng: 20.485668 },
    { lat: 49.053031, lng: 20.485693 },
    { lat: 49.053015, lng: 20.485695 },
    { lat: 49.052727, lng: 20.485694 },
    { lat: 49.052368, lng: 20.485938 },
    { lat: 49.052145, lng: 20.486243 },
    { lat: 49.052117, lng: 20.486275 },
    { lat: 49.052044, lng: 20.486381 },
    { lat: 49.052096, lng: 20.486572 },
    { lat: 49.052228, lng: 20.486947 },
    { lat: 49.05224, lng: 20.487102 },
    { lat: 49.052169, lng: 20.487348 },
    { lat: 49.052294, lng: 20.487798 },
    { lat: 49.052116, lng: 20.488907 },
    { lat: 49.052083, lng: 20.48905 },
    { lat: 49.052003, lng: 20.48922 },
    { lat: 49.051932, lng: 20.489412 },
    { lat: 49.051843, lng: 20.489692 },
    { lat: 49.051774, lng: 20.489805 },
    { lat: 49.051692, lng: 20.490093 },
    { lat: 49.051673, lng: 20.490263 },
    { lat: 49.051695, lng: 20.490431 },
    { lat: 49.051751, lng: 20.490575 },
    { lat: 49.051823, lng: 20.490668 },
    { lat: 49.051915, lng: 20.490766 },
    { lat: 49.052018, lng: 20.490835 },
    { lat: 49.052205, lng: 20.490894 },
    { lat: 49.052192, lng: 20.491293 },
    { lat: 49.051864, lng: 20.491471 },
    { lat: 49.05167, lng: 20.491725 },
    { lat: 49.051625, lng: 20.491848 },
    { lat: 49.051649, lng: 20.492507 },
    { lat: 49.05157, lng: 20.492546 },
    { lat: 49.051471, lng: 20.492589 },
    { lat: 49.051403, lng: 20.492644 },
    { lat: 49.051259, lng: 20.492752 },
    { lat: 49.051164, lng: 20.49278 },
    { lat: 49.051048, lng: 20.492812 },
    { lat: 49.050934, lng: 20.492818 },
    { lat: 49.050778, lng: 20.492825 },
    { lat: 49.050736, lng: 20.4928 },
    { lat: 49.050647, lng: 20.492749 },
    { lat: 49.050577, lng: 20.492732 },
    { lat: 49.050527, lng: 20.492719 },
    { lat: 49.050327, lng: 20.492718 },
    { lat: 49.050261, lng: 20.492347 },
    { lat: 49.050174, lng: 20.492387 },
    { lat: 49.049982, lng: 20.492427 },
    { lat: 49.049794, lng: 20.492514 },
    { lat: 49.049568, lng: 20.492685 },
    { lat: 49.0492, lng: 20.492836 },
    { lat: 49.048987, lng: 20.492935 },
    { lat: 49.048823, lng: 20.493053 },
    { lat: 49.048638, lng: 20.4931 },
    { lat: 49.047816, lng: 20.493439 },
    { lat: 49.04745, lng: 20.493579 },
    { lat: 49.047232, lng: 20.493663 },
    { lat: 49.047017, lng: 20.493966 },
    { lat: 49.046975, lng: 20.494021 },
    { lat: 49.046567, lng: 20.494609 },
    { lat: 49.046542, lng: 20.494646 },
    { lat: 49.046422, lng: 20.49482 },
    { lat: 49.046375, lng: 20.494878 },
    { lat: 49.046144, lng: 20.495433 },
    { lat: 49.046149, lng: 20.495842 },
    { lat: 49.045976, lng: 20.496145 },
    { lat: 49.045978, lng: 20.496298 },
    { lat: 49.046014, lng: 20.496401 },
    { lat: 49.046054, lng: 20.496437 },
    { lat: 49.046063, lng: 20.496445 },
    { lat: 49.046215, lng: 20.496586 },
    { lat: 49.046085, lng: 20.497775 },
    { lat: 49.046016, lng: 20.498057 },
    { lat: 49.045983, lng: 20.498136 },
    { lat: 49.04593, lng: 20.498261 },
    { lat: 49.045716, lng: 20.498734 },
    { lat: 49.045596, lng: 20.498889 },
    { lat: 49.0455, lng: 20.499109 },
    { lat: 49.045179, lng: 20.499657 },
    { lat: 49.044999, lng: 20.499893 },
    { lat: 49.044764, lng: 20.500313 },
    { lat: 49.044698, lng: 20.500434 },
    { lat: 49.044665, lng: 20.500594 },
    { lat: 49.044696, lng: 20.500792 },
    { lat: 49.044744, lng: 20.500989 },
    { lat: 49.044768, lng: 20.501179 },
    { lat: 49.04474, lng: 20.501464 },
    { lat: 49.044725, lng: 20.501617 },
    { lat: 49.044744, lng: 20.501861 },
    { lat: 49.044727, lng: 20.502108 },
    { lat: 49.04458, lng: 20.502601 },
    { lat: 49.044508, lng: 20.502711 },
    { lat: 49.04427, lng: 20.503078 },
    { lat: 49.044183, lng: 20.503179 },
    { lat: 49.044056, lng: 20.50333 },
    { lat: 49.043963, lng: 20.503397 },
    { lat: 49.043784, lng: 20.503523 },
    { lat: 49.043733, lng: 20.503543 },
    { lat: 49.0435, lng: 20.503643 },
    { lat: 49.043293, lng: 20.503778 },
    { lat: 49.043127, lng: 20.503984 },
    { lat: 49.042965, lng: 20.504243 },
    { lat: 49.042947, lng: 20.504374 },
    { lat: 49.042906, lng: 20.50467 },
    { lat: 49.042819, lng: 20.505004 },
    { lat: 49.042707, lng: 20.504817 },
    { lat: 49.042633, lng: 20.504925 },
    { lat: 49.042555, lng: 20.505037 },
    { lat: 49.042387, lng: 20.505221 },
    { lat: 49.042231, lng: 20.505386 },
    { lat: 49.04206, lng: 20.505558 },
    { lat: 49.041882, lng: 20.505747 },
    { lat: 49.041703, lng: 20.505885 },
    { lat: 49.041412, lng: 20.506047 },
    { lat: 49.041217, lng: 20.506121 },
    { lat: 49.041136, lng: 20.506095 },
    { lat: 49.041013, lng: 20.506058 },
    { lat: 49.04093, lng: 20.506033 },
    { lat: 49.040851, lng: 20.506062 },
    { lat: 49.040752, lng: 20.506099 },
    { lat: 49.040605, lng: 20.506189 },
    { lat: 49.040382, lng: 20.506413 },
    { lat: 49.040298, lng: 20.506521 },
    { lat: 49.040205, lng: 20.506707 },
    { lat: 49.04016, lng: 20.50686 },
    { lat: 49.040082, lng: 20.507094 },
    { lat: 49.039966, lng: 20.507488 },
    { lat: 49.039832, lng: 20.508095 },
    { lat: 49.039791, lng: 20.508258 },
    { lat: 49.039683, lng: 20.508347 },
    { lat: 49.039566, lng: 20.508442 },
    { lat: 49.039406, lng: 20.508659 },
    { lat: 49.039364, lng: 20.509073 },
    { lat: 49.039375, lng: 20.509348 },
    { lat: 49.039451, lng: 20.509666 },
    { lat: 49.039679, lng: 20.510025 },
    { lat: 49.039936, lng: 20.510265 },
    { lat: 49.040095, lng: 20.510354 },
    { lat: 49.040384, lng: 20.510472 },
    { lat: 49.040723, lng: 20.510609 },
    { lat: 49.040941, lng: 20.510673 },
    { lat: 49.041227, lng: 20.510733 },
    { lat: 49.04156, lng: 20.510847 },
    { lat: 49.041836, lng: 20.510929 },
    { lat: 49.042193, lng: 20.511251 },
    { lat: 49.042256, lng: 20.511224 },
    { lat: 49.042365, lng: 20.511181 },
    { lat: 49.042659, lng: 20.511071 },
    { lat: 49.04293, lng: 20.510958 },
    { lat: 49.043146, lng: 20.510864 },
    { lat: 49.043283, lng: 20.51081 },
    { lat: 49.043371, lng: 20.510771 },
    { lat: 49.043435, lng: 20.510744 },
    { lat: 49.043582, lng: 20.510686 },
    { lat: 49.043905, lng: 20.510554 },
    { lat: 49.044121, lng: 20.510465 },
    { lat: 49.044323, lng: 20.510384 },
    { lat: 49.044357, lng: 20.510368 },
    { lat: 49.044579, lng: 20.510271 },
    { lat: 49.044755, lng: 20.510194 },
    { lat: 49.044795, lng: 20.510177 },
    { lat: 49.045003, lng: 20.510087 },
    { lat: 49.045055, lng: 20.510063 },
    { lat: 49.045075, lng: 20.510267 },
    { lat: 49.045119, lng: 20.510756 },
    { lat: 49.045159, lng: 20.511096 },
    { lat: 49.045192, lng: 20.511485 },
    { lat: 49.045247, lng: 20.512047 },
    { lat: 49.045177, lng: 20.512133 },
    { lat: 49.045385, lng: 20.512619 },
    { lat: 49.045574, lng: 20.513057 },
    { lat: 49.045915, lng: 20.513879 },
    { lat: 49.046108, lng: 20.514351 },
    { lat: 49.046249, lng: 20.514691 },
    { lat: 49.046618, lng: 20.515564 },
    { lat: 49.046705, lng: 20.515775 },
    { lat: 49.046846, lng: 20.516178 },
    { lat: 49.046913, lng: 20.516358 },
    { lat: 49.047091, lng: 20.516788 },
    { lat: 49.047261, lng: 20.517206 },
    { lat: 49.047454, lng: 20.51767 },
    { lat: 49.047544, lng: 20.517894 },
    { lat: 49.047663, lng: 20.51803 },
    { lat: 49.047675, lng: 20.518079 },
    { lat: 49.047764, lng: 20.518325 },
    { lat: 49.047873, lng: 20.518619 },
    { lat: 49.047967, lng: 20.518874 },
    { lat: 49.047984, lng: 20.518944 },
    { lat: 49.048028, lng: 20.519335 },
    { lat: 49.048069, lng: 20.519741 },
    { lat: 49.048101, lng: 20.520125 },
    { lat: 49.0481569, lng: 20.5194807 },
    { lat: 49.0480368, lng: 20.5188485 },
    { lat: 49.0480848, lng: 20.5186309 },
    { lat: 49.048312, lng: 20.5185913 },
    { lat: 49.0490381, lng: 20.5172843 },
    { lat: 49.0499979, lng: 20.5165663 },
    { lat: 49.0503418, lng: 20.5164298 },
    { lat: 49.0508819, lng: 20.5172413 },
    { lat: 49.0551452, lng: 20.5148665 },
    { lat: 49.0552327, lng: 20.5147305 },
    { lat: 49.0552076, lng: 20.5146658 },
    { lat: 49.0556331, lng: 20.5132617 },
    { lat: 49.0559213, lng: 20.5127505 },
    { lat: 49.0559883, lng: 20.5131572 },
    { lat: 49.0559245, lng: 20.5135055 },
    { lat: 49.0560241, lng: 20.5136718 },
    { lat: 49.0572299, lng: 20.5119988 },
    { lat: 49.0573462, lng: 20.5113125 },
    { lat: 49.0572689, lng: 20.510837 },
    { lat: 49.0573042, lng: 20.5107837 },
    { lat: 49.0572506, lng: 20.5105855 },
    { lat: 49.0572823, lng: 20.5101692 },
    { lat: 49.0572497, lng: 20.5101035 },
    { lat: 49.057259, lng: 20.510024 },
    { lat: 49.057377, lng: 20.5100491 },
    { lat: 49.0574179, lng: 20.5099964 },
    { lat: 49.057333, lng: 20.5099258 },
    { lat: 49.0572925, lng: 20.5097771 },
    { lat: 49.0573213, lng: 20.5095804 },
    { lat: 49.0576849, lng: 20.5094856 },
    { lat: 49.0576589, lng: 20.5091461 },
    { lat: 49.057713, lng: 20.5091245 },
    { lat: 49.0579244, lng: 20.5093271 },
    { lat: 49.0580228, lng: 20.5098241 },
    { lat: 49.0582976, lng: 20.5096433 },
    { lat: 49.0586252, lng: 20.5096424 },
    { lat: 49.0587868, lng: 20.5098138 },
    { lat: 49.0588385, lng: 20.5100236 },
    { lat: 49.0590401, lng: 20.5101736 },
    { lat: 49.0591802, lng: 20.5106114 },
    { lat: 49.0593193, lng: 20.5107477 },
    { lat: 49.0593473, lng: 20.5109564 },
    { lat: 49.0594556, lng: 20.5110253 },
    { lat: 49.0596518, lng: 20.5106104 },
    { lat: 49.0596664, lng: 20.5104264 },
    { lat: 49.0601786, lng: 20.5097907 },
    { lat: 49.0608899, lng: 20.5090855 },
    { lat: 49.0611993, lng: 20.50844 },
    { lat: 49.0616556, lng: 20.507873 },
    { lat: 49.0621601, lng: 20.507057 },
    { lat: 49.0624492, lng: 20.5064423 },
    { lat: 49.0632411, lng: 20.505063 },
    { lat: 49.0635594, lng: 20.5041743 },
    { lat: 49.063743, lng: 20.5038447 },
    { lat: 49.0640471, lng: 20.5033938 },
    { lat: 49.0643991, lng: 20.503198 },
    { lat: 49.0645748, lng: 20.5029305 },
    { lat: 49.0650557, lng: 20.501416 },
    { lat: 49.0651339, lng: 20.5009795 },
    { lat: 49.0649871, lng: 20.500181 },
    { lat: 49.0648053, lng: 20.4995928 },
    { lat: 49.0641567, lng: 20.4982276 },
    { lat: 49.062649, lng: 20.4959539 },
    { lat: 49.0616503, lng: 20.49344 },
    { lat: 49.0614445, lng: 20.4923614 },
    { lat: 49.0611145, lng: 20.4919637 },
    { lat: 49.0608702, lng: 20.4918469 },
    { lat: 49.0610131, lng: 20.4911169 },
    { lat: 49.0610056, lng: 20.4906124 },
  ],
  Toporec: [
    { lat: 49.2463251, lng: 20.501621 },
    { lat: 49.2464201, lng: 20.5018484 },
    { lat: 49.2466211, lng: 20.5023777 },
    { lat: 49.2470614, lng: 20.5040884 },
    { lat: 49.2475294, lng: 20.5052605 },
    { lat: 49.2474735, lng: 20.5056372 },
    { lat: 49.2472326, lng: 20.506062 },
    { lat: 49.2470828, lng: 20.5066432 },
    { lat: 49.2470464, lng: 20.5070243 },
    { lat: 49.2467783, lng: 20.5078099 },
    { lat: 49.2466653, lng: 20.508442 },
    { lat: 49.246727, lng: 20.508535 },
    { lat: 49.246771, lng: 20.508449 },
    { lat: 49.246859, lng: 20.508305 },
    { lat: 49.246906, lng: 20.508304 },
    { lat: 49.246939, lng: 20.508303 },
    { lat: 49.246987, lng: 20.508302 },
    { lat: 49.247095, lng: 20.508304 },
    { lat: 49.2472, lng: 20.508326 },
    { lat: 49.24742, lng: 20.508449 },
    { lat: 49.247633, lng: 20.508653 },
    { lat: 49.248009, lng: 20.508967 },
    { lat: 49.2481, lng: 20.509061 },
    { lat: 49.248215, lng: 20.509179 },
    { lat: 49.248368, lng: 20.509454 },
    { lat: 49.248478, lng: 20.509632 },
    { lat: 49.248637, lng: 20.509826 },
    { lat: 49.248812, lng: 20.510087 },
    { lat: 49.249153, lng: 20.510487 },
    { lat: 49.249223, lng: 20.510569 },
    { lat: 49.24935, lng: 20.510739 },
    { lat: 49.249579, lng: 20.51113 },
    { lat: 49.249666, lng: 20.511302 },
    { lat: 49.24975, lng: 20.511444 },
    { lat: 49.249903, lng: 20.511703 },
    { lat: 49.250061, lng: 20.511943 },
    { lat: 49.250142, lng: 20.512145 },
    { lat: 49.250219, lng: 20.512305 },
    { lat: 49.250279, lng: 20.512496 },
    { lat: 49.250781, lng: 20.513151 },
    { lat: 49.250933, lng: 20.513617 },
    { lat: 49.251067, lng: 20.513853 },
    { lat: 49.251182, lng: 20.513972 },
    { lat: 49.251304, lng: 20.514072 },
    { lat: 49.25146, lng: 20.514253 },
    { lat: 49.251602, lng: 20.514485 },
    { lat: 49.251659, lng: 20.514617 },
    { lat: 49.251705, lng: 20.514753 },
    { lat: 49.251743, lng: 20.514902 },
    { lat: 49.251805, lng: 20.515069 },
    { lat: 49.251806, lng: 20.515315 },
    { lat: 49.251851, lng: 20.515504 },
    { lat: 49.251911, lng: 20.515733 },
    { lat: 49.251943, lng: 20.515912 },
    { lat: 49.252028, lng: 20.516378 },
    { lat: 49.252131, lng: 20.516264 },
    { lat: 49.252564, lng: 20.515536 },
    { lat: 49.252665, lng: 20.515368 },
    { lat: 49.252799, lng: 20.515147 },
    { lat: 49.253123, lng: 20.514629 },
    { lat: 49.253286, lng: 20.514391 },
    { lat: 49.253804, lng: 20.513554 },
    { lat: 49.253928, lng: 20.513382 },
    { lat: 49.254626, lng: 20.512169 },
    { lat: 49.254769, lng: 20.511881 },
    { lat: 49.25487, lng: 20.511732 },
    { lat: 49.255133, lng: 20.511248 },
    { lat: 49.255438, lng: 20.510777 },
    { lat: 49.255499, lng: 20.510695 },
    { lat: 49.25559, lng: 20.510599 },
    { lat: 49.255772, lng: 20.510318 },
    { lat: 49.255917, lng: 20.510117 },
    { lat: 49.255981, lng: 20.510006 },
    { lat: 49.256001, lng: 20.509974 },
    { lat: 49.256036, lng: 20.509915 },
    { lat: 49.256085, lng: 20.50983 },
    { lat: 49.256305, lng: 20.509524 },
    { lat: 49.256703, lng: 20.50909 },
    { lat: 49.257166, lng: 20.508683 },
    { lat: 49.257458, lng: 20.508381 },
    { lat: 49.258097, lng: 20.507497 },
    { lat: 49.25843, lng: 20.506994 },
    { lat: 49.258585, lng: 20.506774 },
    { lat: 49.258768, lng: 20.506486 },
    { lat: 49.259018, lng: 20.506075 },
    { lat: 49.259221, lng: 20.506271 },
    { lat: 49.259272, lng: 20.506209 },
    { lat: 49.259311, lng: 20.506258 },
    { lat: 49.259434, lng: 20.505986 },
    { lat: 49.259526, lng: 20.505784 },
    { lat: 49.259564, lng: 20.505722 },
    { lat: 49.259593, lng: 20.505675 },
    { lat: 49.259605, lng: 20.505655 },
    { lat: 49.259612, lng: 20.505644 },
    { lat: 49.259653, lng: 20.505563 },
    { lat: 49.259749, lng: 20.505376 },
    { lat: 49.259783, lng: 20.50531 },
    { lat: 49.259825, lng: 20.505258 },
    { lat: 49.259856, lng: 20.50522 },
    { lat: 49.259918, lng: 20.505118 },
    { lat: 49.259941, lng: 20.50506 },
    { lat: 49.259954, lng: 20.50503 },
    { lat: 49.259967, lng: 20.504997 },
    { lat: 49.25998, lng: 20.504969 },
    { lat: 49.260009, lng: 20.504918 },
    { lat: 49.260043, lng: 20.504862 },
    { lat: 49.260126, lng: 20.504723 },
    { lat: 49.26014, lng: 20.504685 },
    { lat: 49.260185, lng: 20.504571 },
    { lat: 49.260199, lng: 20.504533 },
    { lat: 49.260248, lng: 20.504406 },
    { lat: 49.260291, lng: 20.504314 },
    { lat: 49.260304, lng: 20.504288 },
    { lat: 49.260323, lng: 20.504245 },
    { lat: 49.260359, lng: 20.504177 },
    { lat: 49.260379, lng: 20.504139 },
    { lat: 49.260465, lng: 20.503972 },
    { lat: 49.260486, lng: 20.503932 },
    { lat: 49.260548, lng: 20.503814 },
    { lat: 49.260576, lng: 20.503771 },
    { lat: 49.260656, lng: 20.503646 },
    { lat: 49.260757, lng: 20.503487 },
    { lat: 49.260823, lng: 20.503386 },
    { lat: 49.260844, lng: 20.503353 },
    { lat: 49.260959, lng: 20.503194 },
    { lat: 49.261094, lng: 20.503008 },
    { lat: 49.261142, lng: 20.502941 },
    { lat: 49.261319, lng: 20.502733 },
    { lat: 49.261434, lng: 20.502645 },
    { lat: 49.26151, lng: 20.502587 },
    { lat: 49.261546, lng: 20.502511 },
    { lat: 49.261619, lng: 20.50236 },
    { lat: 49.261709, lng: 20.502194 },
    { lat: 49.261743, lng: 20.502185 },
    { lat: 49.261817, lng: 20.502167 },
    { lat: 49.262506, lng: 20.501006 },
    { lat: 49.262944, lng: 20.500267 },
    { lat: 49.26326, lng: 20.499737 },
    { lat: 49.263371, lng: 20.499549 },
    { lat: 49.264536, lng: 20.500719 },
    { lat: 49.264863, lng: 20.501074 },
    { lat: 49.265038, lng: 20.501262 },
    { lat: 49.265559, lng: 20.501766 },
    { lat: 49.265978, lng: 20.502171 },
    { lat: 49.267443, lng: 20.503553 },
    { lat: 49.268157, lng: 20.504215 },
    { lat: 49.268798, lng: 20.50482 },
    { lat: 49.26885, lng: 20.50487 },
    { lat: 49.268902, lng: 20.504919 },
    { lat: 49.269352, lng: 20.505331 },
    { lat: 49.269577, lng: 20.505537 },
    { lat: 49.269811, lng: 20.505777 },
    { lat: 49.269896, lng: 20.505871 },
    { lat: 49.270258, lng: 20.505177 },
    { lat: 49.270387, lng: 20.504981 },
    { lat: 49.270499, lng: 20.504869 },
    { lat: 49.27056, lng: 20.504829 },
    { lat: 49.270625, lng: 20.504758 },
    { lat: 49.270813, lng: 20.504634 },
    { lat: 49.270868, lng: 20.504535 },
    { lat: 49.271106, lng: 20.504347 },
    { lat: 49.271362, lng: 20.504255 },
    { lat: 49.271449, lng: 20.504197 },
    { lat: 49.271487, lng: 20.504128 },
    { lat: 49.271667, lng: 20.504044 },
    { lat: 49.271872, lng: 20.503917 },
    { lat: 49.27199, lng: 20.503784 },
    { lat: 49.271976, lng: 20.503732 },
    { lat: 49.272195, lng: 20.503495 },
    { lat: 49.272267, lng: 20.503464 },
    { lat: 49.272363, lng: 20.503362 },
    { lat: 49.272526, lng: 20.503133 },
    { lat: 49.27278, lng: 20.502831 },
    { lat: 49.274811, lng: 20.505399 },
    { lat: 49.274836, lng: 20.505432 },
    { lat: 49.274963, lng: 20.505673 },
    { lat: 49.275611, lng: 20.506583 },
    { lat: 49.275829, lng: 20.506764 },
    { lat: 49.276446, lng: 20.507313 },
    { lat: 49.277389, lng: 20.507775 },
    { lat: 49.27881, lng: 20.508472 },
    { lat: 49.279818, lng: 20.511088 },
    { lat: 49.280042, lng: 20.511185 },
    { lat: 49.280975, lng: 20.511907 },
    { lat: 49.28129, lng: 20.511975 },
    { lat: 49.282129, lng: 20.511885 },
    { lat: 49.282342, lng: 20.511756 },
    { lat: 49.282653, lng: 20.511668 },
    { lat: 49.282777, lng: 20.511987 },
    { lat: 49.283272, lng: 20.512291 },
    { lat: 49.28394, lng: 20.511832 },
    { lat: 49.283898, lng: 20.511318 },
    { lat: 49.283863, lng: 20.510895 },
    { lat: 49.283848, lng: 20.510696 },
    { lat: 49.283811, lng: 20.510316 },
    { lat: 49.283767, lng: 20.510027 },
    { lat: 49.283804, lng: 20.50964 },
    { lat: 49.284258, lng: 20.509004 },
    { lat: 49.284401, lng: 20.508803 },
    { lat: 49.28483, lng: 20.508282 },
    { lat: 49.285418, lng: 20.507525 },
    { lat: 49.28544, lng: 20.507485 },
    { lat: 49.286125, lng: 20.506271 },
    { lat: 49.28623, lng: 20.505658 },
    { lat: 49.286231, lng: 20.505555 },
    { lat: 49.286214, lng: 20.505078 },
    { lat: 49.286267, lng: 20.50505 },
    { lat: 49.286424, lng: 20.504779 },
    { lat: 49.286678, lng: 20.504416 },
    { lat: 49.286847, lng: 20.50412 },
    { lat: 49.287041, lng: 20.50388 },
    { lat: 49.287337, lng: 20.503911 },
    { lat: 49.287351, lng: 20.503913 },
    { lat: 49.287567, lng: 20.503955 },
    { lat: 49.287976, lng: 20.503965 },
    { lat: 49.288181, lng: 20.503875 },
    { lat: 49.288401, lng: 20.503881 },
    { lat: 49.288674, lng: 20.503857 },
    { lat: 49.288884, lng: 20.503871 },
    { lat: 49.289058, lng: 20.50389 },
    { lat: 49.289449, lng: 20.503806 },
    { lat: 49.289777, lng: 20.503573 },
    { lat: 49.290007, lng: 20.50357 },
    { lat: 49.29019, lng: 20.503775 },
    { lat: 49.291096, lng: 20.50388 },
    { lat: 49.291401, lng: 20.503459 },
    { lat: 49.291569, lng: 20.502984 },
    { lat: 49.291835, lng: 20.502781 },
    { lat: 49.292051, lng: 20.502721 },
    { lat: 49.292188, lng: 20.50266 },
    { lat: 49.292354, lng: 20.502573 },
    { lat: 49.292609, lng: 20.50216 },
    { lat: 49.292646, lng: 20.502049 },
    { lat: 49.293268, lng: 20.501314 },
    { lat: 49.29426, lng: 20.500296 },
    { lat: 49.294401, lng: 20.50015 },
    { lat: 49.295045, lng: 20.49949 },
    { lat: 49.295933, lng: 20.499756 },
    { lat: 49.296742, lng: 20.499187 },
    { lat: 49.29832, lng: 20.499069 },
    { lat: 49.299122, lng: 20.499019 },
    { lat: 49.299165, lng: 20.499016 },
    { lat: 49.299639, lng: 20.498986 },
    { lat: 49.300076, lng: 20.498928 },
    { lat: 49.300241, lng: 20.498905 },
    { lat: 49.300557, lng: 20.498954 },
    { lat: 49.301188, lng: 20.499051 },
    { lat: 49.301294, lng: 20.499068 },
    { lat: 49.30357, lng: 20.499071 },
    { lat: 49.305323, lng: 20.497211 },
    { lat: 49.307, lng: 20.497157 },
    { lat: 49.308168, lng: 20.497148 },
    { lat: 49.3082, lng: 20.497148 },
    { lat: 49.30823, lng: 20.497148 },
    { lat: 49.308306, lng: 20.497148 },
    { lat: 49.308442, lng: 20.497147 },
    { lat: 49.308474, lng: 20.497146 },
    { lat: 49.308651, lng: 20.497146 },
    { lat: 49.311063, lng: 20.496311 },
    { lat: 49.312445, lng: 20.495725 },
    { lat: 49.313568, lng: 20.494584 },
    { lat: 49.315786, lng: 20.493781 },
    { lat: 49.318005, lng: 20.493138 },
    { lat: 49.318772, lng: 20.49269 },
    { lat: 49.319632, lng: 20.492442 },
    { lat: 49.320165, lng: 20.492288 },
    { lat: 49.32078, lng: 20.491972 },
    { lat: 49.321353, lng: 20.491673 },
    { lat: 49.321382, lng: 20.491658 },
    { lat: 49.321983, lng: 20.491339 },
    { lat: 49.321964, lng: 20.491301 },
    { lat: 49.321067, lng: 20.490395 },
    { lat: 49.320446, lng: 20.489986 },
    { lat: 49.319674, lng: 20.489439 },
    { lat: 49.319629, lng: 20.489405 },
    { lat: 49.318773, lng: 20.488798 },
    { lat: 49.318282, lng: 20.488337 },
    { lat: 49.317094, lng: 20.487433 },
    { lat: 49.317018, lng: 20.487389 },
    { lat: 49.31624, lng: 20.486943 },
    { lat: 49.315579, lng: 20.485447 },
    { lat: 49.315484, lng: 20.485243 },
    { lat: 49.314891, lng: 20.483964 },
    { lat: 49.314881, lng: 20.483908 },
    { lat: 49.314916, lng: 20.4831 },
    { lat: 49.314931, lng: 20.482751 },
    { lat: 49.315338, lng: 20.480856 },
    { lat: 49.315907, lng: 20.480102 },
    { lat: 49.315967, lng: 20.480021 },
    { lat: 49.31615, lng: 20.479761 },
    { lat: 49.316335, lng: 20.479556 },
    { lat: 49.316512, lng: 20.479202 },
    { lat: 49.316806, lng: 20.478732 },
    { lat: 49.317002, lng: 20.478127 },
    { lat: 49.317059, lng: 20.477939 },
    { lat: 49.317092, lng: 20.477552 },
    { lat: 49.317185, lng: 20.477264 },
    { lat: 49.31734, lng: 20.477023 },
    { lat: 49.31755, lng: 20.476421 },
    { lat: 49.317695, lng: 20.476139 },
    { lat: 49.316954, lng: 20.474199 },
    { lat: 49.316737, lng: 20.473448 },
    { lat: 49.31669, lng: 20.473289 },
    { lat: 49.316462, lng: 20.472498 },
    { lat: 49.316296, lng: 20.472458 },
    { lat: 49.31624, lng: 20.472445 },
    { lat: 49.315852, lng: 20.472227 },
    { lat: 49.315653, lng: 20.471951 },
    { lat: 49.315515, lng: 20.471759 },
    { lat: 49.315106, lng: 20.471343 },
    { lat: 49.314871, lng: 20.471177 },
    { lat: 49.314746, lng: 20.471089 },
    { lat: 49.314451, lng: 20.470742 },
    { lat: 49.313951, lng: 20.470372 },
    { lat: 49.313743, lng: 20.470217 },
    { lat: 49.31351, lng: 20.46974 },
    { lat: 49.31333, lng: 20.469588 },
    { lat: 49.312641, lng: 20.469009 },
    { lat: 49.312211, lng: 20.468479 },
    { lat: 49.31199, lng: 20.46821 },
    { lat: 49.311848, lng: 20.468037 },
    { lat: 49.311707, lng: 20.467864 },
    { lat: 49.311517, lng: 20.467448 },
    { lat: 49.310886, lng: 20.466705 },
    { lat: 49.310609, lng: 20.466521 },
    { lat: 49.310248, lng: 20.466282 },
    { lat: 49.310101, lng: 20.466184 },
    { lat: 49.310086, lng: 20.466077 },
    { lat: 49.310031, lng: 20.465693 },
    { lat: 49.309953, lng: 20.465141 },
    { lat: 49.309792, lng: 20.463998 },
    { lat: 49.309781, lng: 20.463928 },
    { lat: 49.309769, lng: 20.463914 },
    { lat: 49.309492, lng: 20.463605 },
    { lat: 49.309024, lng: 20.463367 },
    { lat: 49.308727, lng: 20.46315 },
    { lat: 49.308322, lng: 20.462663 },
    { lat: 49.308014, lng: 20.462417 },
    { lat: 49.307479, lng: 20.462195 },
    { lat: 49.307326, lng: 20.461458 },
    { lat: 49.307336, lng: 20.460903 },
    { lat: 49.307231, lng: 20.459773 },
    { lat: 49.307248, lng: 20.459237 },
    { lat: 49.307244, lng: 20.458951 },
    { lat: 49.307341, lng: 20.458518 },
    { lat: 49.306879, lng: 20.457988 },
    { lat: 49.30684, lng: 20.457852 },
    { lat: 49.306548, lng: 20.456847 },
    { lat: 49.306462, lng: 20.456678 },
    { lat: 49.30585, lng: 20.455488 },
    { lat: 49.305706, lng: 20.45512 },
    { lat: 49.305598, lng: 20.454858 },
    { lat: 49.305102, lng: 20.453641 },
    { lat: 49.304992, lng: 20.453369 },
    { lat: 49.304654, lng: 20.452552 },
    { lat: 49.304299, lng: 20.451689 },
    { lat: 49.303383, lng: 20.449456 },
    { lat: 49.303379, lng: 20.449448 },
    { lat: 49.302646, lng: 20.44773 },
    { lat: 49.301717, lng: 20.445551 },
    { lat: 49.301676, lng: 20.445456 },
    { lat: 49.301644, lng: 20.445377 },
    { lat: 49.301352, lng: 20.44468 },
    { lat: 49.301002, lng: 20.443857 },
    { lat: 49.300904, lng: 20.443624 },
    { lat: 49.300702, lng: 20.443149 },
    { lat: 49.300465, lng: 20.442587 },
    { lat: 49.300441, lng: 20.442531 },
    { lat: 49.300425, lng: 20.442492 },
    { lat: 49.300418, lng: 20.442475 },
    { lat: 49.300386, lng: 20.442393 },
    { lat: 49.299382, lng: 20.439908 },
    { lat: 49.299352, lng: 20.439837 },
    { lat: 49.299174, lng: 20.43941 },
    { lat: 49.298637, lng: 20.438125 },
    { lat: 49.298553, lng: 20.437927 },
    { lat: 49.298469, lng: 20.437731 },
    { lat: 49.297474, lng: 20.435382 },
    { lat: 49.297469, lng: 20.435373 },
    { lat: 49.297442, lng: 20.435309 },
    { lat: 49.296655, lng: 20.433401 },
    { lat: 49.29534, lng: 20.431603 },
    { lat: 49.29526, lng: 20.431424 },
    { lat: 49.295049, lng: 20.43095 },
    { lat: 49.294693, lng: 20.430155 },
    { lat: 49.294664, lng: 20.43009 },
    { lat: 49.294612, lng: 20.429974 },
    { lat: 49.294152, lng: 20.428948 },
    { lat: 49.29397, lng: 20.428713 },
    { lat: 49.293637, lng: 20.428333 },
    { lat: 49.2911006, lng: 20.4273953 },
    { lat: 49.2902211, lng: 20.4276595 },
    { lat: 49.2900703, lng: 20.4278401 },
    { lat: 49.2900283, lng: 20.4278899 },
    { lat: 49.2899578, lng: 20.4280527 },
    { lat: 49.2898305, lng: 20.4284083 },
    { lat: 49.2892774, lng: 20.4294769 },
    { lat: 49.2888365, lng: 20.4306568 },
    { lat: 49.2885257, lng: 20.4313124 },
    { lat: 49.2880165, lng: 20.4320814 },
    { lat: 49.2875459, lng: 20.4321462 },
    { lat: 49.2863187, lng: 20.43199 },
    { lat: 49.2855474, lng: 20.4320492 },
    { lat: 49.2848353, lng: 20.4322733 },
    { lat: 49.28388, lng: 20.4319891 },
    { lat: 49.2836592, lng: 20.4319829 },
    { lat: 49.2833558, lng: 20.4321509 },
    { lat: 49.2823457, lng: 20.4329201 },
    { lat: 49.282099, lng: 20.4331282 },
    { lat: 49.2818387, lng: 20.4334519 },
    { lat: 49.2810922, lng: 20.4348881 },
    { lat: 49.2806291, lng: 20.4364941 },
    { lat: 49.2802726, lng: 20.4367468 },
    { lat: 49.2797702, lng: 20.4368861 },
    { lat: 49.2793491, lng: 20.4368557 },
    { lat: 49.2786435, lng: 20.4364106 },
    { lat: 49.2774226, lng: 20.4361266 },
    { lat: 49.2771623, lng: 20.4365706 },
    { lat: 49.2764519, lng: 20.4373548 },
    { lat: 49.2761612, lng: 20.4378552 },
    { lat: 49.2759845, lng: 20.4380358 },
    { lat: 49.2752204, lng: 20.4391243 },
    { lat: 49.274514, lng: 20.4398321 },
    { lat: 49.273718, lng: 20.440906 },
    { lat: 49.2732376, lng: 20.4416397 },
    { lat: 49.2730688, lng: 20.4417879 },
    { lat: 49.2725403, lng: 20.4427348 },
    { lat: 49.2717451, lng: 20.4435353 },
    { lat: 49.2708051, lng: 20.445305 },
    { lat: 49.2698503, lng: 20.4462989 },
    { lat: 49.2688985, lng: 20.4477865 },
    { lat: 49.2674173, lng: 20.450378 },
    { lat: 49.2667335, lng: 20.4513571 },
    { lat: 49.2668447, lng: 20.4518931 },
    { lat: 49.2666784, lng: 20.4525926 },
    { lat: 49.2657619, lng: 20.4534312 },
    { lat: 49.2657375, lng: 20.453442 },
    { lat: 49.265763, lng: 20.4536139 },
    { lat: 49.265606, lng: 20.4540829 },
    { lat: 49.2654274, lng: 20.4547507 },
    { lat: 49.2653676, lng: 20.4551697 },
    { lat: 49.2652964, lng: 20.45534 },
    { lat: 49.2649839, lng: 20.4556899 },
    { lat: 49.2648037, lng: 20.4558051 },
    { lat: 49.2645448, lng: 20.4561983 },
    { lat: 49.2641991, lng: 20.4564982 },
    { lat: 49.263853, lng: 20.4574768 },
    { lat: 49.2636093, lng: 20.4585154 },
    { lat: 49.2634156, lng: 20.4599623 },
    { lat: 49.2630292, lng: 20.4610093 },
    { lat: 49.2629927, lng: 20.4621977 },
    { lat: 49.2624789, lng: 20.4638783 },
    { lat: 49.2625013, lng: 20.4648049 },
    { lat: 49.2622862, lng: 20.4675808 },
    { lat: 49.2620882, lng: 20.4683691 },
    { lat: 49.2620264, lng: 20.4688492 },
    { lat: 49.2617487, lng: 20.4696152 },
    { lat: 49.2607616, lng: 20.4708589 },
    { lat: 49.2603647, lng: 20.4718115 },
    { lat: 49.2603259, lng: 20.472069 },
    { lat: 49.2601611, lng: 20.4724179 },
    { lat: 49.2600331, lng: 20.4732137 },
    { lat: 49.2598857, lng: 20.4736385 },
    { lat: 49.259893, lng: 20.473746 },
    { lat: 49.2599728, lng: 20.4738295 },
    { lat: 49.2600063, lng: 20.4742783 },
    { lat: 49.2598457, lng: 20.4745914 },
    { lat: 49.2596303, lng: 20.4755152 },
    { lat: 49.2573348, lng: 20.4792376 },
    { lat: 49.256529, lng: 20.480666 },
    { lat: 49.2562777, lng: 20.4818996 },
    { lat: 49.2556008, lng: 20.4824925 },
    { lat: 49.2550295, lng: 20.483478 },
    { lat: 49.2547042, lng: 20.4857829 },
    { lat: 49.2545854, lng: 20.4872504 },
    { lat: 49.2543143, lng: 20.488558 },
    { lat: 49.2539754, lng: 20.4892851 },
    { lat: 49.2537026, lng: 20.4891242 },
    { lat: 49.2535462, lng: 20.4894917 },
    { lat: 49.2532869, lng: 20.4897833 },
    { lat: 49.2533044, lng: 20.4905837 },
    { lat: 49.2531632, lng: 20.4912418 },
    { lat: 49.2514378, lng: 20.4936216 },
    { lat: 49.2502705, lng: 20.4953822 },
    { lat: 49.2499584, lng: 20.4960752 },
    { lat: 49.2484831, lng: 20.4980356 },
    { lat: 49.2474364, lng: 20.4995333 },
    { lat: 49.2463251, lng: 20.501621 },
  ],
  Zálesie: [
    { lat: 49.3281826, lng: 20.3714797 },
    { lat: 49.3279666, lng: 20.3716564 },
    { lat: 49.3261241, lng: 20.3719116 },
    { lat: 49.3251064, lng: 20.3726495 },
    { lat: 49.3245924, lng: 20.3729321 },
    { lat: 49.3229775, lng: 20.3734814 },
    { lat: 49.3214233, lng: 20.3742959 },
    { lat: 49.3209545, lng: 20.3746358 },
    { lat: 49.320919, lng: 20.3745029 },
    { lat: 49.320737, lng: 20.3745754 },
    { lat: 49.3203692, lng: 20.3749084 },
    { lat: 49.3203096, lng: 20.3751169 },
    { lat: 49.3199371, lng: 20.3758607 },
    { lat: 49.3189219, lng: 20.3774965 },
    { lat: 49.3188546, lng: 20.3776804 },
    { lat: 49.3185295, lng: 20.377923 },
    { lat: 49.3179984, lng: 20.3780204 },
    { lat: 49.317247, lng: 20.378747 },
    { lat: 49.3164496, lng: 20.37984 },
    { lat: 49.3161386, lng: 20.3801232 },
    { lat: 49.3159439, lng: 20.3801907 },
    { lat: 49.3156266, lng: 20.3804563 },
    { lat: 49.3147333, lng: 20.3813041 },
    { lat: 49.3139541, lng: 20.3814481 },
    { lat: 49.3121318, lng: 20.3811382 },
    { lat: 49.310972, lng: 20.3813103 },
    { lat: 49.3103206, lng: 20.3818258 },
    { lat: 49.3094698, lng: 20.3828406 },
    { lat: 49.3087992, lng: 20.3835021 },
    { lat: 49.3084115, lng: 20.3839963 },
    { lat: 49.3080507, lng: 20.3851389 },
    { lat: 49.3076375, lng: 20.3859113 },
    { lat: 49.3072816, lng: 20.3862944 },
    { lat: 49.3079677, lng: 20.3875807 },
    { lat: 49.3075905, lng: 20.3883762 },
    { lat: 49.3075632, lng: 20.3887575 },
    { lat: 49.3075049, lng: 20.3889683 },
    { lat: 49.3075464, lng: 20.3890545 },
    { lat: 49.3074422, lng: 20.3895404 },
    { lat: 49.3074524, lng: 20.389896 },
    { lat: 49.3073139, lng: 20.3906667 },
    { lat: 49.3074252, lng: 20.3913499 },
    { lat: 49.3074177, lng: 20.3915692 },
    { lat: 49.307311, lng: 20.3918103 },
    { lat: 49.3074946, lng: 20.3924655 },
    { lat: 49.3075397, lng: 20.3929125 },
    { lat: 49.3074824, lng: 20.3934166 },
    { lat: 49.3074134, lng: 20.3935591 },
    { lat: 49.3074375, lng: 20.3936587 },
    { lat: 49.3073698, lng: 20.3941637 },
    { lat: 49.3073216, lng: 20.394112 },
    { lat: 49.3073224, lng: 20.3943072 },
    { lat: 49.3072652, lng: 20.3944901 },
    { lat: 49.3069357, lng: 20.3947651 },
    { lat: 49.306986, lng: 20.3949096 },
    { lat: 49.306866, lng: 20.3952735 },
    { lat: 49.3067724, lng: 20.3954178 },
    { lat: 49.3066873, lng: 20.3954942 },
    { lat: 49.3064801, lng: 20.3952265 },
    { lat: 49.3059488, lng: 20.3959108 },
    { lat: 49.3057435, lng: 20.3960213 },
    { lat: 49.3056625, lng: 20.3959636 },
    { lat: 49.3053246, lng: 20.3964019 },
    { lat: 49.3051085, lng: 20.3970557 },
    { lat: 49.3047977, lng: 20.3976223 },
    { lat: 49.304581, lng: 20.3979048 },
    { lat: 49.3044051, lng: 20.3983119 },
    { lat: 49.3042856, lng: 20.3986641 },
    { lat: 49.3043415, lng: 20.3986812 },
    { lat: 49.3040175, lng: 20.3999059 },
    { lat: 49.3039701, lng: 20.401035 },
    { lat: 49.3045328, lng: 20.4012976 },
    { lat: 49.304527, lng: 20.401366 },
    { lat: 49.304543, lng: 20.40134 },
    { lat: 49.304567, lng: 20.401311 },
    { lat: 49.30508, lng: 20.401516 },
    { lat: 49.305534, lng: 20.401852 },
    { lat: 49.305996, lng: 20.402253 },
    { lat: 49.306643, lng: 20.402837 },
    { lat: 49.30711, lng: 20.403127 },
    { lat: 49.307158, lng: 20.403157 },
    { lat: 49.307214, lng: 20.403192 },
    { lat: 49.3076, lng: 20.403386 },
    { lat: 49.30831, lng: 20.403911 },
    { lat: 49.30895, lng: 20.404364 },
    { lat: 49.309834, lng: 20.40463 },
    { lat: 49.310309, lng: 20.404907 },
    { lat: 49.310991, lng: 20.404323 },
    { lat: 49.311635, lng: 20.403833 },
    { lat: 49.312643, lng: 20.403517 },
    { lat: 49.313213, lng: 20.403408 },
    { lat: 49.314123, lng: 20.403251 },
    { lat: 49.314175, lng: 20.40321 },
    { lat: 49.314768, lng: 20.402702 },
    { lat: 49.315138, lng: 20.402359 },
    { lat: 49.315646, lng: 20.401922 },
    { lat: 49.31599, lng: 20.401865 },
    { lat: 49.316779, lng: 20.401662 },
    { lat: 49.317347, lng: 20.401472 },
    { lat: 49.317913, lng: 20.401538 },
    { lat: 49.318733, lng: 20.401347 },
    { lat: 49.319401, lng: 20.401344 },
    { lat: 49.319849, lng: 20.401974 },
    { lat: 49.320049, lng: 20.402389 },
    { lat: 49.320285, lng: 20.402991 },
    { lat: 49.320387, lng: 20.403362 },
    { lat: 49.320559, lng: 20.403852 },
    { lat: 49.320643, lng: 20.404094 },
    { lat: 49.32078, lng: 20.404105 },
    { lat: 49.32084, lng: 20.40411 },
    { lat: 49.320842, lng: 20.40411 },
    { lat: 49.320952, lng: 20.404125 },
    { lat: 49.321063, lng: 20.40414 },
    { lat: 49.3211081, lng: 20.4041118 },
    { lat: 49.3211559, lng: 20.4033547 },
    { lat: 49.3213378, lng: 20.4019076 },
    { lat: 49.3213481, lng: 20.4014686 },
    { lat: 49.3214712, lng: 20.4009134 },
    { lat: 49.3219696, lng: 20.3994152 },
    { lat: 49.3220741, lng: 20.3992663 },
    { lat: 49.3231972, lng: 20.3984456 },
    { lat: 49.3239474, lng: 20.3976851 },
    { lat: 49.3241898, lng: 20.3973655 },
    { lat: 49.3251558, lng: 20.3971875 },
    { lat: 49.3261023, lng: 20.3974814 },
    { lat: 49.3266005, lng: 20.3975228 },
    { lat: 49.3269649, lng: 20.3974526 },
    { lat: 49.3270849, lng: 20.3974983 },
    { lat: 49.3272585, lng: 20.3973862 },
    { lat: 49.3273561, lng: 20.3974714 },
    { lat: 49.3274401, lng: 20.3974282 },
    { lat: 49.3274875, lng: 20.3973106 },
    { lat: 49.3282497, lng: 20.3968655 },
    { lat: 49.3287405, lng: 20.3967426 },
    { lat: 49.3289789, lng: 20.3967659 },
    { lat: 49.3298182, lng: 20.3963077 },
    { lat: 49.3302012, lng: 20.3961925 },
    { lat: 49.3314763, lng: 20.3961531 },
    { lat: 49.3321963, lng: 20.3962524 },
    { lat: 49.3333348, lng: 20.3962024 },
    { lat: 49.3336593, lng: 20.3962674 },
    { lat: 49.3337863, lng: 20.3960584 },
    { lat: 49.3345839, lng: 20.3958335 },
    { lat: 49.3341576, lng: 20.3950103 },
    { lat: 49.3334881, lng: 20.3930322 },
    { lat: 49.3335942, lng: 20.3923207 },
    { lat: 49.3338656, lng: 20.3913165 },
    { lat: 49.3338802, lng: 20.3910159 },
    { lat: 49.3337624, lng: 20.3910029 },
    { lat: 49.3327814, lng: 20.3892663 },
    { lat: 49.3323942, lng: 20.3888837 },
    { lat: 49.3321545, lng: 20.3882365 },
    { lat: 49.3317995, lng: 20.3879915 },
    { lat: 49.3317152, lng: 20.3876965 },
    { lat: 49.3316447, lng: 20.3870266 },
    { lat: 49.3316808, lng: 20.3867906 },
    { lat: 49.3316471, lng: 20.3857218 },
    { lat: 49.3316906, lng: 20.3855078 },
    { lat: 49.3317872, lng: 20.3853476 },
    { lat: 49.3316969, lng: 20.3846593 },
    { lat: 49.3316274, lng: 20.3846691 },
    { lat: 49.3315858, lng: 20.3843757 },
    { lat: 49.3314936, lng: 20.3844067 },
    { lat: 49.3314109, lng: 20.3842736 },
    { lat: 49.3313623, lng: 20.3838891 },
    { lat: 49.3314162, lng: 20.3837612 },
    { lat: 49.3313054, lng: 20.3834278 },
    { lat: 49.3313401, lng: 20.3833034 },
    { lat: 49.3312625, lng: 20.3825663 },
    { lat: 49.3313027, lng: 20.3824927 },
    { lat: 49.3311819, lng: 20.3823382 },
    { lat: 49.3312404, lng: 20.3816233 },
    { lat: 49.3312841, lng: 20.381502 },
    { lat: 49.3316041, lng: 20.3813306 },
    { lat: 49.3312964, lng: 20.380553 },
    { lat: 49.3309402, lng: 20.3800068 },
    { lat: 49.3304851, lng: 20.3785792 },
    { lat: 49.3306771, lng: 20.3783874 },
    { lat: 49.3300072, lng: 20.3754026 },
    { lat: 49.3292251, lng: 20.3746687 },
    { lat: 49.3285089, lng: 20.3719148 },
    { lat: 49.3282961, lng: 20.3717086 },
    { lat: 49.3281826, lng: 20.3714797 },
  ],
  StránepodTatrami: [
    { lat: 49.1499378, lng: 20.3796612 },
    { lat: 49.149947, lng: 20.3796669 },
    { lat: 49.1522266, lng: 20.3811876 },
    { lat: 49.1542653, lng: 20.3819464 },
    { lat: 49.154221, lng: 20.3823503 },
    { lat: 49.156356, lng: 20.3831851 },
    { lat: 49.1562194, lng: 20.3837062 },
    { lat: 49.1568032, lng: 20.3839556 },
    { lat: 49.156839, lng: 20.3840665 },
    { lat: 49.1568919, lng: 20.3848352 },
    { lat: 49.156854, lng: 20.3853497 },
    { lat: 49.1571301, lng: 20.3854458 },
    { lat: 49.1600285, lng: 20.3855415 },
    { lat: 49.1608831, lng: 20.3858331 },
    { lat: 49.1607677, lng: 20.3863858 },
    { lat: 49.1596674, lng: 20.3886223 },
    { lat: 49.1630425, lng: 20.3915704 },
    { lat: 49.1632273, lng: 20.3910691 },
    { lat: 49.1635065, lng: 20.3907735 },
    { lat: 49.1637908, lng: 20.3903461 },
    { lat: 49.1642741, lng: 20.389469 },
    { lat: 49.1643693, lng: 20.3891242 },
    { lat: 49.1646374, lng: 20.3885697 },
    { lat: 49.1647055, lng: 20.3882881 },
    { lat: 49.1648498, lng: 20.3883545 },
    { lat: 49.1650605, lng: 20.3880869 },
    { lat: 49.164997, lng: 20.3876334 },
    { lat: 49.164851, lng: 20.3876726 },
    { lat: 49.164728, lng: 20.3874914 },
    { lat: 49.1645478, lng: 20.3873998 },
    { lat: 49.1644154, lng: 20.3869074 },
    { lat: 49.164436, lng: 20.3861191 },
    { lat: 49.1641818, lng: 20.3857437 },
    { lat: 49.1640791, lng: 20.3857046 },
    { lat: 49.1640892, lng: 20.3856472 },
    { lat: 49.1645504, lng: 20.3859316 },
    { lat: 49.1651925, lng: 20.3857614 },
    { lat: 49.1652023, lng: 20.3853676 },
    { lat: 49.1655088, lng: 20.3847238 },
    { lat: 49.1656555, lng: 20.3848529 },
    { lat: 49.1657315, lng: 20.3847786 },
    { lat: 49.1656999, lng: 20.383566 },
    { lat: 49.1648359, lng: 20.3830773 },
    { lat: 49.1666029, lng: 20.3781016 },
    { lat: 49.167706, lng: 20.3758232 },
    { lat: 49.167999, lng: 20.3761552 },
    { lat: 49.1677041, lng: 20.3767787 },
    { lat: 49.168026, lng: 20.377033 },
    { lat: 49.168094, lng: 20.376966 },
    { lat: 49.168166, lng: 20.376798 },
    { lat: 49.168194, lng: 20.376666 },
    { lat: 49.168367, lng: 20.376084 },
    { lat: 49.168442, lng: 20.375731 },
    { lat: 49.168657, lng: 20.37529 },
    { lat: 49.168854, lng: 20.374891 },
    { lat: 49.16902, lng: 20.374611 },
    { lat: 49.169142, lng: 20.374386 },
    { lat: 49.169238, lng: 20.374239 },
    { lat: 49.169303, lng: 20.374014 },
    { lat: 49.169627, lng: 20.372805 },
    { lat: 49.169703, lng: 20.372635 },
    { lat: 49.169803, lng: 20.372361 },
    { lat: 49.169902, lng: 20.372029 },
    { lat: 49.170151, lng: 20.371754 },
    { lat: 49.170353, lng: 20.371725 },
    { lat: 49.170491, lng: 20.371655 },
    { lat: 49.170821, lng: 20.371201 },
    { lat: 49.170894, lng: 20.370955 },
    { lat: 49.171109, lng: 20.370399 },
    { lat: 49.171105, lng: 20.370208 },
    { lat: 49.171219, lng: 20.369945 },
    { lat: 49.1719, lng: 20.36867 },
    { lat: 49.172161, lng: 20.368038 },
    { lat: 49.17231, lng: 20.366605 },
    { lat: 49.172569, lng: 20.365296 },
    { lat: 49.172832, lng: 20.363985 },
    { lat: 49.173093, lng: 20.362672 },
    { lat: 49.173359, lng: 20.361374 },
    { lat: 49.173506, lng: 20.360588 },
    { lat: 49.173546, lng: 20.360349 },
    { lat: 49.173598, lng: 20.360052 },
    { lat: 49.173214, lng: 20.360008 },
    { lat: 49.172438, lng: 20.359951 },
    { lat: 49.171846, lng: 20.359823 },
    { lat: 49.171607, lng: 20.35963 },
    { lat: 49.17041, lng: 20.359236 },
    { lat: 49.170264, lng: 20.358049 },
    { lat: 49.169653, lng: 20.357498 },
    { lat: 49.169576, lng: 20.357428 },
    { lat: 49.168326, lng: 20.356305 },
    { lat: 49.167701, lng: 20.355742 },
    { lat: 49.1674, lng: 20.355471 },
    { lat: 49.167866, lng: 20.35441 },
    { lat: 49.168113, lng: 20.353847 },
    { lat: 49.167915, lng: 20.353735 },
    { lat: 49.16805, lng: 20.353436 },
    { lat: 49.167624, lng: 20.353008 },
    { lat: 49.16779, lng: 20.352459 },
    { lat: 49.167325, lng: 20.351816 },
    { lat: 49.167378, lng: 20.351684 },
    { lat: 49.166883, lng: 20.351195 },
    { lat: 49.166496, lng: 20.351277 },
    { lat: 49.165817, lng: 20.350555 },
    { lat: 49.165465, lng: 20.350195 },
    { lat: 49.165432, lng: 20.350183 },
    { lat: 49.165421, lng: 20.350171 },
    { lat: 49.16506, lng: 20.350598 },
    { lat: 49.16507, lng: 20.35081 },
    { lat: 49.164778, lng: 20.351221 },
    { lat: 49.164731, lng: 20.351377 },
    { lat: 49.164582, lng: 20.351577 },
    { lat: 49.164526, lng: 20.351718 },
    { lat: 49.164468, lng: 20.351844 },
    { lat: 49.164236, lng: 20.352266 },
    { lat: 49.164085, lng: 20.352391 },
    { lat: 49.164089, lng: 20.352481 },
    { lat: 49.163973, lng: 20.352686 },
    { lat: 49.163839, lng: 20.352666 },
    { lat: 49.163832, lng: 20.352643 },
    { lat: 49.163752, lng: 20.352492 },
    { lat: 49.163449, lng: 20.352155 },
    { lat: 49.163076, lng: 20.351504 },
    { lat: 49.162825, lng: 20.351068 },
    { lat: 49.162482, lng: 20.350477 },
    { lat: 49.1615502, lng: 20.3515597 },
    { lat: 49.1604305, lng: 20.3537434 },
    { lat: 49.1597838, lng: 20.3547343 },
    { lat: 49.1596095, lng: 20.3549697 },
    { lat: 49.1592805, lng: 20.3551702 },
    { lat: 49.1588421, lng: 20.3556612 },
    { lat: 49.1584083, lng: 20.3562107 },
    { lat: 49.1580062, lng: 20.3568492 },
    { lat: 49.1575924, lng: 20.3576314 },
    { lat: 49.1572912, lng: 20.3583778 },
    { lat: 49.155436, lng: 20.3619662 },
    { lat: 49.1548467, lng: 20.3634839 },
    { lat: 49.1547295, lng: 20.3640159 },
    { lat: 49.1533459, lng: 20.3681627 },
    { lat: 49.1520865, lng: 20.3724767 },
    { lat: 49.1511262, lng: 20.3749565 },
    { lat: 49.1507163, lng: 20.3757532 },
    { lat: 49.1505715, lng: 20.3762923 },
    { lat: 49.1500537, lng: 20.379069 },
    { lat: 49.1499378, lng: 20.3796612 },
  ],
  Ľubica: [
    { lat: 49.1199592, lng: 20.4187604 },
    { lat: 49.1198764, lng: 20.418804 },
    { lat: 49.1197625, lng: 20.4188614 },
    { lat: 49.1193623, lng: 20.4186996 },
    { lat: 49.1188781, lng: 20.4187278 },
    { lat: 49.1186785, lng: 20.418865 },
    { lat: 49.1180756, lng: 20.4190621 },
    { lat: 49.1165424, lng: 20.4193949 },
    { lat: 49.1160402, lng: 20.4197779 },
    { lat: 49.1153738, lng: 20.4204985 },
    { lat: 49.1151553, lng: 20.4208211 },
    { lat: 49.1149953, lng: 20.42127 },
    { lat: 49.1145499, lng: 20.4231234 },
    { lat: 49.1143008, lng: 20.4238296 },
    { lat: 49.1137794, lng: 20.4249604 },
    { lat: 49.1138791, lng: 20.4251242 },
    { lat: 49.1135985, lng: 20.4256549 },
    { lat: 49.1138144, lng: 20.4261226 },
    { lat: 49.1137729, lng: 20.4263091 },
    { lat: 49.113622, lng: 20.4266951 },
    { lat: 49.1132368, lng: 20.4274067 },
    { lat: 49.1131105, lng: 20.4277862 },
    { lat: 49.1123567, lng: 20.4284302 },
    { lat: 49.112036, lng: 20.4289086 },
    { lat: 49.112115, lng: 20.4293322 },
    { lat: 49.1120436, lng: 20.4320045 },
    { lat: 49.1117365, lng: 20.4319243 },
    { lat: 49.1116463, lng: 20.4322168 },
    { lat: 49.1114765, lng: 20.432461 },
    { lat: 49.1113841, lng: 20.4328439 },
    { lat: 49.1113488, lng: 20.4333404 },
    { lat: 49.1112324, lng: 20.4337197 },
    { lat: 49.1107292, lng: 20.4351535 },
    { lat: 49.1105049, lng: 20.4355613 },
    { lat: 49.1101388, lng: 20.4364598 },
    { lat: 49.1099108, lng: 20.4372747 },
    { lat: 49.1097786, lng: 20.4374749 },
    { lat: 49.1097794, lng: 20.4377638 },
    { lat: 49.1094321, lng: 20.4386789 },
    { lat: 49.1092503, lng: 20.4397068 },
    { lat: 49.1091141, lng: 20.4400932 },
    { lat: 49.1089906, lng: 20.4401985 },
    { lat: 49.1089349, lng: 20.4404218 },
    { lat: 49.108716, lng: 20.4404974 },
    { lat: 49.1087263, lng: 20.4403215 },
    { lat: 49.1088024, lng: 20.4401312 },
    { lat: 49.1083845, lng: 20.4396157 },
    { lat: 49.1083714, lng: 20.4395371 },
    { lat: 49.1084599, lng: 20.4394054 },
    { lat: 49.1084231, lng: 20.4392674 },
    { lat: 49.1083485, lng: 20.4392286 },
    { lat: 49.1082974, lng: 20.4392769 },
    { lat: 49.1082196, lng: 20.4394843 },
    { lat: 49.1080349, lng: 20.4392719 },
    { lat: 49.1080354, lng: 20.4391108 },
    { lat: 49.1081624, lng: 20.4391946 },
    { lat: 49.1082764, lng: 20.4391699 },
    { lat: 49.1082894, lng: 20.4388888 },
    { lat: 49.1080929, lng: 20.4388391 },
    { lat: 49.10775, lng: 20.438913 },
    { lat: 49.1073883, lng: 20.438637 },
    { lat: 49.1072479, lng: 20.4386802 },
    { lat: 49.1071821, lng: 20.4386371 },
    { lat: 49.1071442, lng: 20.438313 },
    { lat: 49.1071007, lng: 20.4382981 },
    { lat: 49.1070284, lng: 20.4384087 },
    { lat: 49.1067444, lng: 20.4384899 },
    { lat: 49.1066809, lng: 20.4384218 },
    { lat: 49.1065626, lng: 20.4384185 },
    { lat: 49.1064861, lng: 20.4385103 },
    { lat: 49.1064316, lng: 20.4384975 },
    { lat: 49.1063916, lng: 20.4388381 },
    { lat: 49.1064459, lng: 20.4393213 },
    { lat: 49.1063326, lng: 20.4394516 },
    { lat: 49.106182, lng: 20.4392808 },
    { lat: 49.1060964, lng: 20.4392814 },
    { lat: 49.105813, lng: 20.439588 },
    { lat: 49.1055998, lng: 20.4394894 },
    { lat: 49.1049898, lng: 20.439762 },
    { lat: 49.1046928, lng: 20.4395612 },
    { lat: 49.1046369, lng: 20.4395992 },
    { lat: 49.1046051, lng: 20.4397708 },
    { lat: 49.1043914, lng: 20.4394787 },
    { lat: 49.1043261, lng: 20.4394804 },
    { lat: 49.1042797, lng: 20.4396243 },
    { lat: 49.1042074, lng: 20.4396787 },
    { lat: 49.1039487, lng: 20.4392935 },
    { lat: 49.1037989, lng: 20.439445 },
    { lat: 49.1036207, lng: 20.4392848 },
    { lat: 49.1034969, lng: 20.4392888 },
    { lat: 49.103306, lng: 20.439179 },
    { lat: 49.1031249, lng: 20.4392128 },
    { lat: 49.1032436, lng: 20.4394098 },
    { lat: 49.1031327, lng: 20.43951 },
    { lat: 49.1030361, lng: 20.4395508 },
    { lat: 49.102899, lng: 20.4394608 },
    { lat: 49.1027271, lng: 20.4394449 },
    { lat: 49.1026019, lng: 20.4391091 },
    { lat: 49.1026533, lng: 20.4389202 },
    { lat: 49.1024994, lng: 20.4389003 },
    { lat: 49.1022984, lng: 20.4387619 },
    { lat: 49.1024426, lng: 20.4385251 },
    { lat: 49.1024704, lng: 20.4383365 },
    { lat: 49.1024112, lng: 20.4381998 },
    { lat: 49.102202, lng: 20.4381764 },
    { lat: 49.1018907, lng: 20.4379855 },
    { lat: 49.1018502, lng: 20.4380138 },
    { lat: 49.1018994, lng: 20.4382729 },
    { lat: 49.1018019, lng: 20.4384349 },
    { lat: 49.1016195, lng: 20.4384336 },
    { lat: 49.1016123, lng: 20.4381866 },
    { lat: 49.101292, lng: 20.4381137 },
    { lat: 49.1012167, lng: 20.4382615 },
    { lat: 49.1010277, lng: 20.4383254 },
    { lat: 49.1009313, lng: 20.4385451 },
    { lat: 49.1007395, lng: 20.4385414 },
    { lat: 49.1006455, lng: 20.4387006 },
    { lat: 49.1005894, lng: 20.438708 },
    { lat: 49.1005487, lng: 20.4383738 },
    { lat: 49.100475, lng: 20.438239 },
    { lat: 49.100394, lng: 20.4382134 },
    { lat: 49.1002738, lng: 20.4379203 },
    { lat: 49.1002136, lng: 20.4379076 },
    { lat: 49.1000523, lng: 20.4380489 },
    { lat: 49.0997309, lng: 20.4380968 },
    { lat: 49.0996586, lng: 20.4380859 },
    { lat: 49.0995464, lng: 20.4378571 },
    { lat: 49.0993326, lng: 20.4378316 },
    { lat: 49.0992602, lng: 20.4379694 },
    { lat: 49.098834, lng: 20.4381687 },
    { lat: 49.0987891, lng: 20.4381087 },
    { lat: 49.0988147, lng: 20.4379261 },
    { lat: 49.0987014, lng: 20.4377222 },
    { lat: 49.0987157, lng: 20.437564 },
    { lat: 49.0986534, lng: 20.4374954 },
    { lat: 49.0983053, lng: 20.4373739 },
    { lat: 49.0981275, lng: 20.4374336 },
    { lat: 49.0980634, lng: 20.4373781 },
    { lat: 49.098057, lng: 20.4376266 },
    { lat: 49.0979213, lng: 20.4375756 },
    { lat: 49.0978199, lng: 20.4378482 },
    { lat: 49.0977296, lng: 20.4378112 },
    { lat: 49.0977051, lng: 20.4376692 },
    { lat: 49.0977995, lng: 20.4376631 },
    { lat: 49.0978965, lng: 20.437489 },
    { lat: 49.0974555, lng: 20.4374989 },
    { lat: 49.0974392, lng: 20.4377245 },
    { lat: 49.0970442, lng: 20.4377849 },
    { lat: 49.0967069, lng: 20.4381575 },
    { lat: 49.0966681, lng: 20.4380222 },
    { lat: 49.0966405, lng: 20.4380408 },
    { lat: 49.0965875, lng: 20.4381245 },
    { lat: 49.096635, lng: 20.4384034 },
    { lat: 49.0965827, lng: 20.438537 },
    { lat: 49.0964913, lng: 20.4385658 },
    { lat: 49.0963672, lng: 20.4382576 },
    { lat: 49.0963273, lng: 20.4384195 },
    { lat: 49.0964025, lng: 20.438506 },
    { lat: 49.0962594, lng: 20.4387161 },
    { lat: 49.0962048, lng: 20.4387008 },
    { lat: 49.0961769, lng: 20.4385541 },
    { lat: 49.0960468, lng: 20.4386284 },
    { lat: 49.0959779, lng: 20.4383258 },
    { lat: 49.0958922, lng: 20.4382618 },
    { lat: 49.0956639, lng: 20.4385081 },
    { lat: 49.0957496, lng: 20.4387158 },
    { lat: 49.0957202, lng: 20.4387751 },
    { lat: 49.0955849, lng: 20.438741 },
    { lat: 49.0955021, lng: 20.4388409 },
    { lat: 49.0953631, lng: 20.4388592 },
    { lat: 49.095112, lng: 20.4392763 },
    { lat: 49.0951812, lng: 20.4393224 },
    { lat: 49.0955113, lng: 20.4392669 },
    { lat: 49.0955533, lng: 20.4394878 },
    { lat: 49.0954492, lng: 20.4396216 },
    { lat: 49.0954564, lng: 20.4399895 },
    { lat: 49.0953084, lng: 20.4400384 },
    { lat: 49.0951975, lng: 20.4398746 },
    { lat: 49.095156, lng: 20.4399386 },
    { lat: 49.0952187, lng: 20.4401677 },
    { lat: 49.0951454, lng: 20.4402499 },
    { lat: 49.095015, lng: 20.4401082 },
    { lat: 49.0949638, lng: 20.4402144 },
    { lat: 49.0947592, lng: 20.4402506 },
    { lat: 49.0947108, lng: 20.4403137 },
    { lat: 49.0947602, lng: 20.4406105 },
    { lat: 49.094649, lng: 20.4407014 },
    { lat: 49.094728, lng: 20.4408256 },
    { lat: 49.0946763, lng: 20.4408941 },
    { lat: 49.0947163, lng: 20.4410846 },
    { lat: 49.0945993, lng: 20.4410295 },
    { lat: 49.0945428, lng: 20.4412032 },
    { lat: 49.0945199, lng: 20.4416073 },
    { lat: 49.0943996, lng: 20.4417029 },
    { lat: 49.0943146, lng: 20.4419888 },
    { lat: 49.094403, lng: 20.4421258 },
    { lat: 49.0944409, lng: 20.4423244 },
    { lat: 49.0944019, lng: 20.442423 },
    { lat: 49.0944944, lng: 20.4427595 },
    { lat: 49.0943211, lng: 20.4430013 },
    { lat: 49.0941573, lng: 20.4428608 },
    { lat: 49.0940947, lng: 20.4428799 },
    { lat: 49.0940187, lng: 20.4432473 },
    { lat: 49.0940884, lng: 20.4433687 },
    { lat: 49.0940061, lng: 20.443451 },
    { lat: 49.0940844, lng: 20.4437391 },
    { lat: 49.0940246, lng: 20.4437986 },
    { lat: 49.094015, lng: 20.4439018 },
    { lat: 49.0941088, lng: 20.4439665 },
    { lat: 49.0941981, lng: 20.4442149 },
    { lat: 49.0941435, lng: 20.4442933 },
    { lat: 49.0940352, lng: 20.4441048 },
    { lat: 49.0939586, lng: 20.4441388 },
    { lat: 49.0939596, lng: 20.4442522 },
    { lat: 49.0938556, lng: 20.4444485 },
    { lat: 49.0938858, lng: 20.4445058 },
    { lat: 49.0939814, lng: 20.4444443 },
    { lat: 49.0940173, lng: 20.4445572 },
    { lat: 49.0939761, lng: 20.4446909 },
    { lat: 49.0938704, lng: 20.4446028 },
    { lat: 49.0938025, lng: 20.4447438 },
    { lat: 49.0938526, lng: 20.4447886 },
    { lat: 49.0938459, lng: 20.4448815 },
    { lat: 49.0938671, lng: 20.4449055 },
    { lat: 49.0941953, lng: 20.445313 },
    { lat: 49.0942006, lng: 20.4456607 },
    { lat: 49.0949511, lng: 20.4479229 },
    { lat: 49.095327, lng: 20.4487839 },
    { lat: 49.0955516, lng: 20.4491865 },
    { lat: 49.096353, lng: 20.4501139 },
    { lat: 49.097053, lng: 20.4505836 },
    { lat: 49.0975619, lng: 20.4511241 },
    { lat: 49.0981498, lng: 20.4516049 },
    { lat: 49.0989388, lng: 20.4520481 },
    { lat: 49.0990533, lng: 20.4519164 },
    { lat: 49.0991696, lng: 20.4520162 },
    { lat: 49.0992887, lng: 20.4519853 },
    { lat: 49.0997709, lng: 20.4521977 },
    { lat: 49.100691, lng: 20.4535522 },
    { lat: 49.1010196, lng: 20.4546007 },
    { lat: 49.1010559, lng: 20.4549066 },
    { lat: 49.1010467, lng: 20.4555286 },
    { lat: 49.1008168, lng: 20.4567843 },
    { lat: 49.1011153, lng: 20.4569355 },
    { lat: 49.1009291, lng: 20.4581862 },
    { lat: 49.1009474, lng: 20.4597534 },
    { lat: 49.1009977, lng: 20.4600465 },
    { lat: 49.1010116, lng: 20.460719 },
    { lat: 49.100934, lng: 20.4612604 },
    { lat: 49.1008868, lng: 20.4655593 },
    { lat: 49.1007605, lng: 20.4662749 },
    { lat: 49.1000066, lng: 20.46854 },
    { lat: 49.1000292, lng: 20.4701646 },
    { lat: 49.1000989, lng: 20.4707039 },
    { lat: 49.1002825, lng: 20.4715173 },
    { lat: 49.1009216, lng: 20.4737056 },
    { lat: 49.1009799, lng: 20.4740915 },
    { lat: 49.1016353, lng: 20.4755434 },
    { lat: 49.1027869, lng: 20.4783666 },
    { lat: 49.1030478, lng: 20.4790867 },
    { lat: 49.1032862, lng: 20.4799492 },
    { lat: 49.1033077, lng: 20.4837249 },
    { lat: 49.103409, lng: 20.4853266 },
    { lat: 49.1033027, lng: 20.4860953 },
    { lat: 49.1038991, lng: 20.4871444 },
    { lat: 49.1041609, lng: 20.4886754 },
    { lat: 49.1040095, lng: 20.4898056 },
    { lat: 49.1035564, lng: 20.4906702 },
    { lat: 49.1033225, lng: 20.4918247 },
    { lat: 49.1040947, lng: 20.4941198 },
    { lat: 49.1038298, lng: 20.495746 },
    { lat: 49.1030557, lng: 20.4976097 },
    { lat: 49.1030486, lng: 20.4981417 },
    { lat: 49.1036585, lng: 20.4994053 },
    { lat: 49.104332, lng: 20.5020198 },
    { lat: 49.1051157, lng: 20.5035067 },
    { lat: 49.1055355, lng: 20.5056567 },
    { lat: 49.1058994, lng: 20.5078784 },
    { lat: 49.1060046, lng: 20.5081952 },
    { lat: 49.1072688, lng: 20.5117445 },
    { lat: 49.1073343, lng: 20.5127863 },
    { lat: 49.1083136, lng: 20.5127801 },
    { lat: 49.1089604, lng: 20.5139506 },
    { lat: 49.1091357, lng: 20.5144286 },
    { lat: 49.1092563, lng: 20.5144937 },
    { lat: 49.1092588, lng: 20.51468 },
    { lat: 49.1091929, lng: 20.5147244 },
    { lat: 49.1092358, lng: 20.5149141 },
    { lat: 49.1093075, lng: 20.5149906 },
    { lat: 49.109976, lng: 20.5170253 },
    { lat: 49.1102424, lng: 20.5179846 },
    { lat: 49.110802, lng: 20.5191127 },
    { lat: 49.1117638, lng: 20.5213125 },
    { lat: 49.1108646, lng: 20.5221773 },
    { lat: 49.1115431, lng: 20.5247554 },
    { lat: 49.1118672, lng: 20.5256052 },
    { lat: 49.1121119, lng: 20.5265894 },
    { lat: 49.1128821, lng: 20.5291235 },
    { lat: 49.1129779, lng: 20.5309603 },
    { lat: 49.1138148, lng: 20.5324343 },
    { lat: 49.1139506, lng: 20.5333089 },
    { lat: 49.1139318, lng: 20.5341741 },
    { lat: 49.1132407, lng: 20.5363165 },
    { lat: 49.1131626, lng: 20.5381988 },
    { lat: 49.112322, lng: 20.5402291 },
    { lat: 49.1112884, lng: 20.5413535 },
    { lat: 49.111582, lng: 20.5422027 },
    { lat: 49.1125523, lng: 20.5438276 },
    { lat: 49.1128764, lng: 20.5466993 },
    { lat: 49.1132714, lng: 20.5492045 },
    { lat: 49.1135164, lng: 20.5598461 },
    { lat: 49.1139658, lng: 20.5612357 },
    { lat: 49.1145098, lng: 20.5653905 },
    { lat: 49.1145165, lng: 20.5654525 },
    { lat: 49.1144097, lng: 20.5655449 },
    { lat: 49.1143673, lng: 20.5656822 },
    { lat: 49.1143551, lng: 20.5659611 },
    { lat: 49.1145458, lng: 20.5662205 },
    { lat: 49.1145945, lng: 20.5665085 },
    { lat: 49.1143785, lng: 20.5671544 },
    { lat: 49.1145683, lng: 20.5674811 },
    { lat: 49.1146168, lng: 20.5677303 },
    { lat: 49.1147912, lng: 20.5679775 },
    { lat: 49.1146391, lng: 20.5686499 },
    { lat: 49.1150289, lng: 20.5688134 },
    { lat: 49.1151394, lng: 20.5689711 },
    { lat: 49.1152922, lng: 20.5697617 },
    { lat: 49.1155301, lng: 20.570107 },
    { lat: 49.1155282, lng: 20.5703077 },
    { lat: 49.1154178, lng: 20.5705773 },
    { lat: 49.1154581, lng: 20.5706669 },
    { lat: 49.1155367, lng: 20.5706886 },
    { lat: 49.1155892, lng: 20.5710464 },
    { lat: 49.1155044, lng: 20.5713469 },
    { lat: 49.1156388, lng: 20.571513 },
    { lat: 49.1155019, lng: 20.5723562 },
    { lat: 49.1157299, lng: 20.5728627 },
    { lat: 49.1157277, lng: 20.573334 },
    { lat: 49.1159149, lng: 20.5738335 },
    { lat: 49.1158131, lng: 20.5747406 },
    { lat: 49.1159907, lng: 20.5752319 },
    { lat: 49.1161003, lng: 20.5757006 },
    { lat: 49.1160817, lng: 20.5758693 },
    { lat: 49.1158868, lng: 20.5760809 },
    { lat: 49.1160821, lng: 20.5766886 },
    { lat: 49.115939, lng: 20.5769872 },
    { lat: 49.1162664, lng: 20.5775118 },
    { lat: 49.1164081, lng: 20.5780906 },
    { lat: 49.116443, lng: 20.578557 },
    { lat: 49.1166404, lng: 20.578871 },
    { lat: 49.1168102, lng: 20.5794258 },
    { lat: 49.1168685, lng: 20.579822 },
    { lat: 49.1168085, lng: 20.5801803 },
    { lat: 49.1168238, lng: 20.5804464 },
    { lat: 49.1173421, lng: 20.5813837 },
    { lat: 49.117472, lng: 20.5820422 },
    { lat: 49.11755, lng: 20.5822416 },
    { lat: 49.1175142, lng: 20.5826439 },
    { lat: 49.1175445, lng: 20.5831067 },
    { lat: 49.1176142, lng: 20.5834124 },
    { lat: 49.1175836, lng: 20.5836874 },
    { lat: 49.117644, lng: 20.5840424 },
    { lat: 49.1174876, lng: 20.5846343 },
    { lat: 49.1175486, lng: 20.5851024 },
    { lat: 49.117482, lng: 20.5853467 },
    { lat: 49.1175038, lng: 20.5859777 },
    { lat: 49.1174272, lng: 20.586037 },
    { lat: 49.1175192, lng: 20.5864602 },
    { lat: 49.1173988, lng: 20.5869559 },
    { lat: 49.117358, lng: 20.5876957 },
    { lat: 49.1175105, lng: 20.5888189 },
    { lat: 49.1174531, lng: 20.5892244 },
    { lat: 49.1174884, lng: 20.5893864 },
    { lat: 49.117477, lng: 20.5897532 },
    { lat: 49.1176031, lng: 20.5900948 },
    { lat: 49.1175251, lng: 20.5902451 },
    { lat: 49.1175406, lng: 20.5903933 },
    { lat: 49.1176845, lng: 20.5910018 },
    { lat: 49.1180795, lng: 20.5921128 },
    { lat: 49.118193, lng: 20.5922951 },
    { lat: 49.1182846, lng: 20.5926496 },
    { lat: 49.118382, lng: 20.5927593 },
    { lat: 49.1185098, lng: 20.5932355 },
    { lat: 49.1187339, lng: 20.5937614 },
    { lat: 49.118874, lng: 20.5938395 },
    { lat: 49.1188778, lng: 20.5940081 },
    { lat: 49.1190973, lng: 20.5944079 },
    { lat: 49.1191612, lng: 20.5948683 },
    { lat: 49.1193168, lng: 20.5949641 },
    { lat: 49.1195919, lng: 20.5956898 },
    { lat: 49.1198869, lng: 20.5958759 },
    { lat: 49.120018, lng: 20.5962642 },
    { lat: 49.120573, lng: 20.5973147 },
    { lat: 49.1206433, lng: 20.5975481 },
    { lat: 49.1209845, lng: 20.5982331 },
    { lat: 49.1211543, lng: 20.5989394 },
    { lat: 49.1211715, lng: 20.5993233 },
    { lat: 49.1212387, lng: 20.5995304 },
    { lat: 49.1211741, lng: 20.5997641 },
    { lat: 49.1212165, lng: 20.5999122 },
    { lat: 49.1212016, lng: 20.6000608 },
    { lat: 49.1213137, lng: 20.6004186 },
    { lat: 49.1214574, lng: 20.6006545 },
    { lat: 49.1215443, lng: 20.6009114 },
    { lat: 49.1216703, lng: 20.6009712 },
    { lat: 49.1217266, lng: 20.601093 },
    { lat: 49.1218163, lng: 20.6011447 },
    { lat: 49.1218929, lng: 20.6013651 },
    { lat: 49.1221796, lng: 20.601691 },
    { lat: 49.1225747, lng: 20.6023751 },
    { lat: 49.1229633, lng: 20.6036561 },
    { lat: 49.1229559, lng: 20.6038573 },
    { lat: 49.1231058, lng: 20.6046656 },
    { lat: 49.1233895, lng: 20.6051323 },
    { lat: 49.1235695, lng: 20.6056589 },
    { lat: 49.1237234, lng: 20.6058637 },
    { lat: 49.1237598, lng: 20.6060296 },
    { lat: 49.1240351, lng: 20.6065445 },
    { lat: 49.1242258, lng: 20.6073019 },
    { lat: 49.124309, lng: 20.6092276 },
    { lat: 49.124598, lng: 20.6098733 },
    { lat: 49.1253387, lng: 20.6115529 },
    { lat: 49.1260025, lng: 20.6134057 },
    { lat: 49.1261951, lng: 20.6136617 },
    { lat: 49.1264632, lng: 20.6143498 },
    { lat: 49.1269348, lng: 20.6155586 },
    { lat: 49.1304122, lng: 20.6197967 },
    { lat: 49.1304482, lng: 20.6198609 },
    { lat: 49.132905, lng: 20.624238 },
    { lat: 49.1338289, lng: 20.6258833 },
    { lat: 49.1340178, lng: 20.6262212 },
    { lat: 49.134573, lng: 20.6266957 },
    { lat: 49.1368103, lng: 20.6294614 },
    { lat: 49.1380096, lng: 20.6309084 },
    { lat: 49.140659, lng: 20.6341143 },
    { lat: 49.1426576, lng: 20.6365637 },
    { lat: 49.1426963, lng: 20.6366098 },
    { lat: 49.1428152, lng: 20.6367562 },
    { lat: 49.1428518, lng: 20.6368021 },
    { lat: 49.1429954, lng: 20.636977 },
    { lat: 49.1474679, lng: 20.6395416 },
    { lat: 49.1496718, lng: 20.6408208 },
    { lat: 49.1513346, lng: 20.6418285 },
    { lat: 49.1520748, lng: 20.6422525 },
    { lat: 49.1522633, lng: 20.642299 },
    { lat: 49.1525624, lng: 20.6423521 },
    { lat: 49.1527269, lng: 20.6422636 },
    { lat: 49.1529193, lng: 20.642136 },
    { lat: 49.1532851, lng: 20.6418174 },
    { lat: 49.1537632, lng: 20.6407796 },
    { lat: 49.1540941, lng: 20.6400557 },
    { lat: 49.1542024, lng: 20.6397481 },
    { lat: 49.1542972, lng: 20.6394716 },
    { lat: 49.1543446, lng: 20.6393191 },
    { lat: 49.1544334, lng: 20.638942 },
    { lat: 49.1545467, lng: 20.6383657 },
    { lat: 49.1546494, lng: 20.6378112 },
    { lat: 49.1548013, lng: 20.6373054 },
    { lat: 49.1548994, lng: 20.6370609 },
    { lat: 49.1551485, lng: 20.6365039 },
    { lat: 49.1553516, lng: 20.6361409 },
    { lat: 49.155742, lng: 20.6356365 },
    { lat: 49.1560152, lng: 20.6354338 },
    { lat: 49.1562659, lng: 20.6352427 },
    { lat: 49.1563716, lng: 20.6351123 },
    { lat: 49.1564153, lng: 20.6350098 },
    { lat: 49.1565783, lng: 20.6345041 },
    { lat: 49.1567377, lng: 20.6341146 },
    { lat: 49.1568921, lng: 20.6335608 },
    { lat: 49.1569572, lng: 20.6333967 },
    { lat: 49.1570445, lng: 20.6331621 },
    { lat: 49.157185, lng: 20.6329698 },
    { lat: 49.1574459, lng: 20.6326797 },
    { lat: 49.1575812, lng: 20.6325917 },
    { lat: 49.1575902, lng: 20.6325611 },
    { lat: 49.1583276, lng: 20.630213 },
    { lat: 49.1581601, lng: 20.6293153 },
    { lat: 49.1579339, lng: 20.6284458 },
    { lat: 49.1576294, lng: 20.6275812 },
    { lat: 49.1575886, lng: 20.6265149 },
    { lat: 49.1576637, lng: 20.6248168 },
    { lat: 49.1575629, lng: 20.6243033 },
    { lat: 49.157551, lng: 20.6237242 },
    { lat: 49.1574404, lng: 20.6230264 },
    { lat: 49.1572235, lng: 20.6222234 },
    { lat: 49.1571179, lng: 20.621358 },
    { lat: 49.156808, lng: 20.6205686 },
    { lat: 49.1573591, lng: 20.6198386 },
    { lat: 49.158506, lng: 20.617243 },
    { lat: 49.1587482, lng: 20.616999 },
    { lat: 49.1589915, lng: 20.6169102 },
    { lat: 49.1593862, lng: 20.616553 },
    { lat: 49.1605709, lng: 20.6166315 },
    { lat: 49.1606864, lng: 20.6164931 },
    { lat: 49.160716, lng: 20.615445 },
    { lat: 49.1604154, lng: 20.6138818 },
    { lat: 49.1602669, lng: 20.6127372 },
    { lat: 49.1598654, lng: 20.6118415 },
    { lat: 49.1589257, lng: 20.6104494 },
    { lat: 49.1586984, lng: 20.6092064 },
    { lat: 49.1593816, lng: 20.6071218 },
    { lat: 49.1600819, lng: 20.6056611 },
    { lat: 49.1602196, lng: 20.6028126 },
    { lat: 49.1601129, lng: 20.6018224 },
    { lat: 49.1598928, lng: 20.6010022 },
    { lat: 49.1595169, lng: 20.6004134 },
    { lat: 49.1599754, lng: 20.5993946 },
    { lat: 49.1609249, lng: 20.5978603 },
    { lat: 49.1612274, lng: 20.5963956 },
    { lat: 49.1610779, lng: 20.5949403 },
    { lat: 49.1607113, lng: 20.5935249 },
    { lat: 49.1609666, lng: 20.5915055 },
    { lat: 49.1609344, lng: 20.5894126 },
    { lat: 49.1608629, lng: 20.5889302 },
    { lat: 49.1609094, lng: 20.5879785 },
    { lat: 49.1608402, lng: 20.5876581 },
    { lat: 49.1607142, lng: 20.585895 },
    { lat: 49.1608214, lng: 20.5847231 },
    { lat: 49.1614252, lng: 20.5822641 },
    { lat: 49.1616509, lng: 20.5809465 },
    { lat: 49.1614283, lng: 20.5795973 },
    { lat: 49.1614271, lng: 20.5792528 },
    { lat: 49.1622421, lng: 20.5777446 },
    { lat: 49.1626636, lng: 20.5765783 },
    { lat: 49.1626761, lng: 20.5754128 },
    { lat: 49.1627967, lng: 20.5744907 },
    { lat: 49.1638878, lng: 20.573165 },
    { lat: 49.1640506, lng: 20.5729713 },
    { lat: 49.1643576, lng: 20.5719398 },
    { lat: 49.164635, lng: 20.5716814 },
    { lat: 49.1648755, lng: 20.5706523 },
    { lat: 49.1660728, lng: 20.5694005 },
    { lat: 49.1669456, lng: 20.5671315 },
    { lat: 49.1669893, lng: 20.5668828 },
    { lat: 49.1668835, lng: 20.5658651 },
    { lat: 49.1663718, lng: 20.5649236 },
    { lat: 49.1660927, lng: 20.5635749 },
    { lat: 49.1655252, lng: 20.5624936 },
    { lat: 49.1652199, lng: 20.5621181 },
    { lat: 49.1649172, lng: 20.5611362 },
    { lat: 49.16448, lng: 20.5603856 },
    { lat: 49.1642294, lng: 20.560031 },
    { lat: 49.1634954, lng: 20.5594399 },
    { lat: 49.1631858, lng: 20.5588914 },
    { lat: 49.1632029, lng: 20.5582496 },
    { lat: 49.1635121, lng: 20.5565204 },
    { lat: 49.1636331, lng: 20.5563144 },
    { lat: 49.1647552, lng: 20.5562162 },
    { lat: 49.1677066, lng: 20.557066 },
    { lat: 49.1668565, lng: 20.5523627 },
    { lat: 49.1652309, lng: 20.5524851 },
    { lat: 49.1625681, lng: 20.5501139 },
    { lat: 49.1625744, lng: 20.5491817 },
    { lat: 49.1619512, lng: 20.548033 },
    { lat: 49.1616749, lng: 20.5473429 },
    { lat: 49.1614709, lng: 20.5467215 },
    { lat: 49.1613325, lng: 20.5460171 },
    { lat: 49.1612421, lng: 20.5447831 },
    { lat: 49.1615548, lng: 20.5446506 },
    { lat: 49.1616027, lng: 20.5434116 },
    { lat: 49.1614556, lng: 20.5414954 },
    { lat: 49.1613312, lng: 20.5415129 },
    { lat: 49.1612411, lng: 20.5399803 },
    { lat: 49.1612741, lng: 20.5394656 },
    { lat: 49.1617567, lng: 20.5381106 },
    { lat: 49.1621342, lng: 20.5362232 },
    { lat: 49.1618004, lng: 20.5353507 },
    { lat: 49.1616537, lng: 20.5347949 },
    { lat: 49.1615595, lng: 20.5329079 },
    { lat: 49.1616247, lng: 20.5324775 },
    { lat: 49.1618386, lng: 20.5318664 },
    { lat: 49.162174, lng: 20.5301552 },
    { lat: 49.1623666, lng: 20.5286278 },
    { lat: 49.1623975, lng: 20.527542 },
    { lat: 49.1622846, lng: 20.5264063 },
    { lat: 49.1614479, lng: 20.5250273 },
    { lat: 49.1613848, lng: 20.5246597 },
    { lat: 49.1614429, lng: 20.5244044 },
    { lat: 49.1619199, lng: 20.5244872 },
    { lat: 49.1617213, lng: 20.5239528 },
    { lat: 49.1611805, lng: 20.5218984 },
    { lat: 49.161079, lng: 20.5215014 },
    { lat: 49.161059, lng: 20.5214121 },
    { lat: 49.1609269, lng: 20.5207612 },
    { lat: 49.1614613, lng: 20.5200883 },
    { lat: 49.1616078, lng: 20.5200879 },
    { lat: 49.1618518, lng: 20.519718 },
    { lat: 49.1623497, lng: 20.5201483 },
    { lat: 49.1628382, lng: 20.5208193 },
    { lat: 49.1629358, lng: 20.5206559 },
    { lat: 49.1631743, lng: 20.5209246 },
    { lat: 49.1635232, lng: 20.5207083 },
    { lat: 49.1633019, lng: 20.5202024 },
    { lat: 49.1639276, lng: 20.5201983 },
    { lat: 49.1640573, lng: 20.520144 },
    { lat: 49.1645675, lng: 20.519785 },
    { lat: 49.1649683, lng: 20.5192934 },
    { lat: 49.165349, lng: 20.5190236 },
    { lat: 49.1665981, lng: 20.5182823 },
    { lat: 49.1675806, lng: 20.517783 },
    { lat: 49.1680182, lng: 20.5173806 },
    { lat: 49.1680474, lng: 20.5174432 },
    { lat: 49.1692189, lng: 20.516025 },
    { lat: 49.1701791, lng: 20.5152023 },
    { lat: 49.1710845, lng: 20.5138705 },
    { lat: 49.1714208, lng: 20.5132551 },
    { lat: 49.1717186, lng: 20.5125987 },
    { lat: 49.172066, lng: 20.5116092 },
    { lat: 49.1720564, lng: 20.5115989 },
    { lat: 49.1706793, lng: 20.5102193 },
    { lat: 49.1707815, lng: 20.5097294 },
    { lat: 49.1710549, lng: 20.5092975 },
    { lat: 49.1710363, lng: 20.5090688 },
    { lat: 49.1711041, lng: 20.509013 },
    { lat: 49.1711799, lng: 20.5090522 },
    { lat: 49.1712349, lng: 20.5088693 },
    { lat: 49.1712667, lng: 20.5084226 },
    { lat: 49.1712292, lng: 20.5081034 },
    { lat: 49.1712885, lng: 20.5079717 },
    { lat: 49.1712134, lng: 20.5076774 },
    { lat: 49.1712314, lng: 20.5070746 },
    { lat: 49.1714179, lng: 20.506576 },
    { lat: 49.1716798, lng: 20.506321 },
    { lat: 49.1718338, lng: 20.505709 },
    { lat: 49.1717721, lng: 20.5056809 },
    { lat: 49.1716204, lng: 20.5053547 },
    { lat: 49.1702539, lng: 20.5034188 },
    { lat: 49.1684123, lng: 20.5013941 },
    { lat: 49.1683608, lng: 20.5013993 },
    { lat: 49.1683483, lng: 20.5014004 },
    { lat: 49.167131, lng: 20.501509 },
    { lat: 49.1668555, lng: 20.5012159 },
    { lat: 49.1647707, lng: 20.5005543 },
    { lat: 49.1638627, lng: 20.49908 },
    { lat: 49.1637421, lng: 20.4989911 },
    { lat: 49.1636424, lng: 20.499031 },
    { lat: 49.1629801, lng: 20.4986911 },
    { lat: 49.1624624, lng: 20.498512 },
    { lat: 49.1612533, lng: 20.4986938 },
    { lat: 49.1609513, lng: 20.4988076 },
    { lat: 49.1601281, lng: 20.495834 },
    { lat: 49.1590317, lng: 20.4931075 },
    { lat: 49.15922, lng: 20.492816 },
    { lat: 49.1587427, lng: 20.4911551 },
    { lat: 49.1578578, lng: 20.4900892 },
    { lat: 49.1573301, lng: 20.4887964 },
    { lat: 49.1576158, lng: 20.4879473 },
    { lat: 49.1574456, lng: 20.4872494 },
    { lat: 49.1570462, lng: 20.4863747 },
    { lat: 49.1556873, lng: 20.4842263 },
    { lat: 49.155273, lng: 20.484169 },
    { lat: 49.1542829, lng: 20.4836696 },
    { lat: 49.1537503, lng: 20.4820961 },
    { lat: 49.153409, lng: 20.4813092 },
    { lat: 49.1525213, lng: 20.4801215 },
    { lat: 49.1520667, lng: 20.4798232 },
    { lat: 49.1508808, lng: 20.4786363 },
    { lat: 49.1501941, lng: 20.477733 },
    { lat: 49.1494255, lng: 20.4770214 },
    { lat: 49.1491933, lng: 20.476647 },
    { lat: 49.1491611, lng: 20.4765099 },
    { lat: 49.1492652, lng: 20.4764621 },
    { lat: 49.1493261, lng: 20.4762226 },
    { lat: 49.1492864, lng: 20.4760053 },
    { lat: 49.1493721, lng: 20.475768 },
    { lat: 49.1495151, lng: 20.4757358 },
    { lat: 49.1495083, lng: 20.4756174 },
    { lat: 49.1495951, lng: 20.4754099 },
    { lat: 49.1495623, lng: 20.4752832 },
    { lat: 49.1496243, lng: 20.475225 },
    { lat: 49.1498002, lng: 20.4753227 },
    { lat: 49.1498676, lng: 20.4752575 },
    { lat: 49.1498575, lng: 20.4751393 },
    { lat: 49.1497894, lng: 20.4750334 },
    { lat: 49.149915, lng: 20.4749282 },
    { lat: 49.15, lng: 20.4745245 },
    { lat: 49.1502614, lng: 20.4744069 },
    { lat: 49.1502889, lng: 20.4742398 },
    { lat: 49.1503883, lng: 20.4742462 },
    { lat: 49.1504629, lng: 20.4741337 },
    { lat: 49.1504938, lng: 20.4739716 },
    { lat: 49.1503756, lng: 20.4739697 },
    { lat: 49.1503424, lng: 20.4738327 },
    { lat: 49.1503793, lng: 20.4737104 },
    { lat: 49.150539, lng: 20.4735185 },
    { lat: 49.1506721, lng: 20.4736224 },
    { lat: 49.1507078, lng: 20.4735154 },
    { lat: 49.1506509, lng: 20.4734153 },
    { lat: 49.1506738, lng: 20.4733004 },
    { lat: 49.1506063, lng: 20.4732426 },
    { lat: 49.1506197, lng: 20.4730896 },
    { lat: 49.150718, lng: 20.4730056 },
    { lat: 49.1508208, lng: 20.4732006 },
    { lat: 49.1508402, lng: 20.4730561 },
    { lat: 49.1507942, lng: 20.4729108 },
    { lat: 49.1508725, lng: 20.4729242 },
    { lat: 49.1508845, lng: 20.4727965 },
    { lat: 49.1510552, lng: 20.4726299 },
    { lat: 49.1509403, lng: 20.4722054 },
    { lat: 49.1510416, lng: 20.4721193 },
    { lat: 49.151092, lng: 20.4723523 },
    { lat: 49.1512057, lng: 20.4722946 },
    { lat: 49.1511816, lng: 20.4720971 },
    { lat: 49.151123, lng: 20.4720374 },
    { lat: 49.1511386, lng: 20.471794 },
    { lat: 49.151203, lng: 20.4715579 },
    { lat: 49.151242, lng: 20.4716674 },
    { lat: 49.1513163, lng: 20.4716052 },
    { lat: 49.1513002, lng: 20.4714761 },
    { lat: 49.1512086, lng: 20.4713164 },
    { lat: 49.1514764, lng: 20.4710087 },
    { lat: 49.1513903, lng: 20.4706928 },
    { lat: 49.1514845, lng: 20.4706842 },
    { lat: 49.1516132, lng: 20.4703904 },
    { lat: 49.1514745, lng: 20.4702334 },
    { lat: 49.1513063, lng: 20.4695632 },
    { lat: 49.1513553, lng: 20.4694546 },
    { lat: 49.151455, lng: 20.4695035 },
    { lat: 49.1514856, lng: 20.469626 },
    { lat: 49.1517612, lng: 20.4695014 },
    { lat: 49.1520214, lng: 20.4695323 },
    { lat: 49.1519543, lng: 20.4693983 },
    { lat: 49.1519881, lng: 20.469186 },
    { lat: 49.1519276, lng: 20.4691384 },
    { lat: 49.1518572, lng: 20.4691805 },
    { lat: 49.1517902, lng: 20.4691028 },
    { lat: 49.1517795, lng: 20.468906 },
    { lat: 49.1519521, lng: 20.4687971 },
    { lat: 49.1521148, lng: 20.4685103 },
    { lat: 49.1521785, lng: 20.4685853 },
    { lat: 49.152196, lng: 20.468727 },
    { lat: 49.1523454, lng: 20.4686827 },
    { lat: 49.1524034, lng: 20.4684309 },
    { lat: 49.1523469, lng: 20.4683635 },
    { lat: 49.1523574, lng: 20.468286 },
    { lat: 49.1525304, lng: 20.4683361 },
    { lat: 49.1524973, lng: 20.468171 },
    { lat: 49.1521313, lng: 20.4675068 },
    { lat: 49.1516713, lng: 20.4670404 },
    { lat: 49.1511795, lng: 20.4662297 },
    { lat: 49.1517466, lng: 20.4648992 },
    { lat: 49.1519872, lng: 20.4639903 },
    { lat: 49.1521371, lng: 20.462759 },
    { lat: 49.152474, lng: 20.4609637 },
    { lat: 49.152569, lng: 20.4602245 },
    { lat: 49.1525062, lng: 20.4598394 },
    { lat: 49.1522367, lng: 20.4596151 },
    { lat: 49.1528555, lng: 20.4575635 },
    { lat: 49.1530774, lng: 20.4566349 },
    { lat: 49.152499, lng: 20.4561048 },
    { lat: 49.1516896, lng: 20.4549032 },
    { lat: 49.1515852, lng: 20.4544599 },
    { lat: 49.1515758, lng: 20.4540661 },
    { lat: 49.1514789, lng: 20.4535836 },
    { lat: 49.1511713, lng: 20.4531249 },
    { lat: 49.1507986, lng: 20.4527924 },
    { lat: 49.1503929, lng: 20.452512 },
    { lat: 49.1502991, lng: 20.4523845 },
    { lat: 49.1501788, lng: 20.4524778 },
    { lat: 49.1500185, lng: 20.4519292 },
    { lat: 49.1489045, lng: 20.452421 },
    { lat: 49.1484425, lng: 20.4524433 },
    { lat: 49.1476968, lng: 20.4522509 },
    { lat: 49.1472644, lng: 20.4520681 },
    { lat: 49.1468431, lng: 20.452065 },
    { lat: 49.14672, lng: 20.4521156 },
    { lat: 49.1468602, lng: 20.4526403 },
    { lat: 49.146795, lng: 20.452819 },
    { lat: 49.1466614, lng: 20.4529245 },
    { lat: 49.1466923, lng: 20.4530243 },
    { lat: 49.1463679, lng: 20.4538036 },
    { lat: 49.1456467, lng: 20.4553136 },
    { lat: 49.1448699, lng: 20.4564537 },
    { lat: 49.144441, lng: 20.4572988 },
    { lat: 49.1438697, lng: 20.4570742 },
    { lat: 49.1415416, lng: 20.4566575 },
    { lat: 49.1410956, lng: 20.456418 },
    { lat: 49.1398559, lng: 20.4537102 },
    { lat: 49.1391758, lng: 20.4525415 },
    { lat: 49.1379936, lng: 20.4520596 },
    { lat: 49.1374592, lng: 20.4517114 },
    { lat: 49.1367315, lng: 20.451013 },
    { lat: 49.1364308, lng: 20.450676 },
    { lat: 49.1361672, lng: 20.4501644 },
    { lat: 49.1358468, lng: 20.4489792 },
    { lat: 49.1354948, lng: 20.4483875 },
    { lat: 49.1351604, lng: 20.4479968 },
    { lat: 49.1350381, lng: 20.4480387 },
    { lat: 49.1349675, lng: 20.4479924 },
    { lat: 49.1347676, lng: 20.448122 },
    { lat: 49.1344034, lng: 20.4476925 },
    { lat: 49.1343568, lng: 20.4477387 },
    { lat: 49.1343361, lng: 20.4476948 },
    { lat: 49.1346906, lng: 20.4471853 },
    { lat: 49.1349592, lng: 20.4465118 },
    { lat: 49.1349957, lng: 20.446195 },
    { lat: 49.1349294, lng: 20.4458679 },
    { lat: 49.134735, lng: 20.4453984 },
    { lat: 49.1347258, lng: 20.4448268 },
    { lat: 49.1347708, lng: 20.4444371 },
    { lat: 49.1349202, lng: 20.4441008 },
    { lat: 49.1351656, lng: 20.443769 },
    { lat: 49.1352438, lng: 20.4435152 },
    { lat: 49.1353358, lng: 20.4429059 },
    { lat: 49.1353333, lng: 20.4424815 },
    { lat: 49.1352617, lng: 20.4422228 },
    { lat: 49.1350723, lng: 20.4419154 },
    { lat: 49.1340437, lng: 20.4412391 },
    { lat: 49.1338705, lng: 20.4409458 },
    { lat: 49.1338356, lng: 20.440763 },
    { lat: 49.1335326, lng: 20.4407923 },
    { lat: 49.1335251, lng: 20.4407139 },
    { lat: 49.1334694, lng: 20.4407321 },
    { lat: 49.1333817, lng: 20.4396248 },
    { lat: 49.1329205, lng: 20.4383226 },
    { lat: 49.1321775, lng: 20.43878 },
    { lat: 49.1317935, lng: 20.4390054 },
    { lat: 49.1317446, lng: 20.4390288 },
    { lat: 49.1310972, lng: 20.43956 },
    { lat: 49.1310695, lng: 20.4394589 },
    { lat: 49.1308627, lng: 20.4396172 },
    { lat: 49.1310252, lng: 20.4402814 },
    { lat: 49.1308434, lng: 20.4402955 },
    { lat: 49.1306788, lng: 20.439634 },
    { lat: 49.1305981, lng: 20.4390755 },
    { lat: 49.1302634, lng: 20.4379622 },
    { lat: 49.1297845, lng: 20.4382519 },
    { lat: 49.12975, lng: 20.4381139 },
    { lat: 49.1294427, lng: 20.4382027 },
    { lat: 49.1294994, lng: 20.4384555 },
    { lat: 49.1288912, lng: 20.4386885 },
    { lat: 49.1280816, lng: 20.4388919 },
    { lat: 49.1273533, lng: 20.4392544 },
    { lat: 49.1273023, lng: 20.4390112 },
    { lat: 49.1277634, lng: 20.4387558 },
    { lat: 49.1276957, lng: 20.4383296 },
    { lat: 49.1279276, lng: 20.4382432 },
    { lat: 49.127691, lng: 20.4373425 },
    { lat: 49.1278968, lng: 20.4372422 },
    { lat: 49.1277823, lng: 20.4367478 },
    { lat: 49.1276618, lng: 20.4365987 },
    { lat: 49.1274165, lng: 20.4357358 },
    { lat: 49.1276529, lng: 20.4354929 },
    { lat: 49.1277793, lng: 20.4352345 },
    { lat: 49.1276344, lng: 20.4348572 },
    { lat: 49.1275966, lng: 20.4349011 },
    { lat: 49.1269087, lng: 20.4323873 },
    { lat: 49.1269606, lng: 20.4314686 },
    { lat: 49.1270832, lng: 20.4306597 },
    { lat: 49.1271172, lng: 20.4300675 },
    { lat: 49.1258149, lng: 20.4298228 },
    { lat: 49.1257654, lng: 20.4292832 },
    { lat: 49.1256023, lng: 20.4274599 },
    { lat: 49.1249575, lng: 20.4249382 },
    { lat: 49.1240759, lng: 20.4200565 },
    { lat: 49.1228925, lng: 20.4195124 },
    { lat: 49.1219495, lng: 20.4185157 },
    { lat: 49.1216795, lng: 20.4183723 },
    { lat: 49.1212918, lng: 20.4183949 },
    { lat: 49.1209544, lng: 20.4185255 },
    { lat: 49.1205482, lng: 20.4185271 },
    { lat: 49.1199592, lng: 20.4187604 },
  ],
  Hradisko: [
    { lat: 49.0668548, lng: 20.5475992 },
    { lat: 49.0668952, lng: 20.547473 },
    { lat: 49.0672417, lng: 20.546273 },
    { lat: 49.0672738, lng: 20.5459845 },
    { lat: 49.0673514, lng: 20.5450357 },
    { lat: 49.0673761, lng: 20.5447848 },
    { lat: 49.0674046, lng: 20.5445519 },
    { lat: 49.0674105, lng: 20.5445252 },
    { lat: 49.0675025, lng: 20.5442434 },
    { lat: 49.0675694, lng: 20.5440416 },
    { lat: 49.0675991, lng: 20.5439648 },
    { lat: 49.0677518, lng: 20.5435809 },
    { lat: 49.0678473, lng: 20.5433365 },
    { lat: 49.0679402, lng: 20.5431059 },
    { lat: 49.0680309, lng: 20.5428781 },
    { lat: 49.0682681, lng: 20.5423495 },
    { lat: 49.0683693, lng: 20.5421308 },
    { lat: 49.0684241, lng: 20.5419649 },
    { lat: 49.0687488, lng: 20.5410335 },
    { lat: 49.0686547, lng: 20.5408939 },
    { lat: 49.0679641, lng: 20.5397203 },
    { lat: 49.0677575, lng: 20.5385125 },
    { lat: 49.0675353, lng: 20.5376794 },
    { lat: 49.0659753, lng: 20.5343315 },
    { lat: 49.0655125, lng: 20.5329862 },
    { lat: 49.0644821, lng: 20.531575 },
    { lat: 49.0634779, lng: 20.5271918 },
    { lat: 49.0631953, lng: 20.5244487 },
    { lat: 49.0627552, lng: 20.5221363 },
    { lat: 49.0623477, lng: 20.5212866 },
    { lat: 49.0621156, lng: 20.520675 },
    { lat: 49.0619033, lng: 20.5198036 },
    { lat: 49.0617557, lng: 20.5193793 },
    { lat: 49.0616805, lng: 20.5188957 },
    { lat: 49.0613625, lng: 20.5179752 },
    { lat: 49.0612526, lng: 20.5172246 },
    { lat: 49.0609884, lng: 20.5161979 },
    { lat: 49.0608392, lng: 20.5152425 },
    { lat: 49.0606674, lng: 20.5144962 },
    { lat: 49.0605893, lng: 20.5143352 },
    { lat: 49.0604734, lng: 20.5130865 },
    { lat: 49.0600292, lng: 20.5120401 },
    { lat: 49.0594331, lng: 20.5111763 },
    { lat: 49.059453, lng: 20.5110446 },
    { lat: 49.0594556, lng: 20.5110253 },
    { lat: 49.0593473, lng: 20.5109564 },
    { lat: 49.0593193, lng: 20.5107477 },
    { lat: 49.0591802, lng: 20.5106114 },
    { lat: 49.0590401, lng: 20.5101736 },
    { lat: 49.0588385, lng: 20.5100236 },
    { lat: 49.0587868, lng: 20.5098138 },
    { lat: 49.0586252, lng: 20.5096424 },
    { lat: 49.0582976, lng: 20.5096433 },
    { lat: 49.0580228, lng: 20.5098241 },
    { lat: 49.0579244, lng: 20.5093271 },
    { lat: 49.057713, lng: 20.5091245 },
    { lat: 49.0576589, lng: 20.5091461 },
    { lat: 49.0576849, lng: 20.5094856 },
    { lat: 49.0573213, lng: 20.5095804 },
    { lat: 49.0572925, lng: 20.5097771 },
    { lat: 49.057333, lng: 20.5099258 },
    { lat: 49.0574179, lng: 20.5099964 },
    { lat: 49.057377, lng: 20.5100491 },
    { lat: 49.057259, lng: 20.510024 },
    { lat: 49.0572497, lng: 20.5101035 },
    { lat: 49.0572823, lng: 20.5101692 },
    { lat: 49.0572506, lng: 20.5105855 },
    { lat: 49.0573042, lng: 20.5107837 },
    { lat: 49.0572689, lng: 20.510837 },
    { lat: 49.0573462, lng: 20.5113125 },
    { lat: 49.0572299, lng: 20.5119988 },
    { lat: 49.0560241, lng: 20.5136718 },
    { lat: 49.0559245, lng: 20.5135055 },
    { lat: 49.0559883, lng: 20.5131572 },
    { lat: 49.0559213, lng: 20.5127505 },
    { lat: 49.0556331, lng: 20.5132617 },
    { lat: 49.0552076, lng: 20.5146658 },
    { lat: 49.0552327, lng: 20.5147305 },
    { lat: 49.0551452, lng: 20.5148665 },
    { lat: 49.0508819, lng: 20.5172413 },
    { lat: 49.0503418, lng: 20.5164298 },
    { lat: 49.0499979, lng: 20.5165663 },
    { lat: 49.0490381, lng: 20.5172843 },
    { lat: 49.048312, lng: 20.5185913 },
    { lat: 49.0480848, lng: 20.5186309 },
    { lat: 49.0480368, lng: 20.5188485 },
    { lat: 49.0481569, lng: 20.5194807 },
    { lat: 49.048101, lng: 20.520125 },
    { lat: 49.047975, lng: 20.520917 },
    { lat: 49.047846, lng: 20.521308 },
    { lat: 49.047683, lng: 20.521369 },
    { lat: 49.047465, lng: 20.52145 },
    { lat: 49.047325, lng: 20.521428 },
    { lat: 49.04739, lng: 20.521668 },
    { lat: 49.047378, lng: 20.521894 },
    { lat: 49.047436, lng: 20.522312 },
    { lat: 49.047383, lng: 20.52294 },
    { lat: 49.047432, lng: 20.523198 },
    { lat: 49.047467, lng: 20.523446 },
    { lat: 49.047492, lng: 20.523616 },
    { lat: 49.04756, lng: 20.524186 },
    { lat: 49.047598, lng: 20.524452 },
    { lat: 49.047676, lng: 20.524734 },
    { lat: 49.047686, lng: 20.524856 },
    { lat: 49.047626, lng: 20.524917 },
    { lat: 49.047609, lng: 20.525095 },
    { lat: 49.047721, lng: 20.525171 },
    { lat: 49.047789, lng: 20.525164 },
    { lat: 49.047801, lng: 20.525372 },
    { lat: 49.047884, lng: 20.525582 },
    { lat: 49.047943, lng: 20.52572 },
    { lat: 49.048021, lng: 20.525765 },
    { lat: 49.048064, lng: 20.52578 },
    { lat: 49.048142, lng: 20.525911 },
    { lat: 49.048244, lng: 20.526096 },
    { lat: 49.048511, lng: 20.526582 },
    { lat: 49.04874, lng: 20.526831 },
    { lat: 49.048845, lng: 20.527004 },
    { lat: 49.048949, lng: 20.527185 },
    { lat: 49.048877, lng: 20.527363 },
    { lat: 49.048895, lng: 20.527587 },
    { lat: 49.048955, lng: 20.527737 },
    { lat: 49.04912, lng: 20.527906 },
    { lat: 49.049081, lng: 20.528074 },
    { lat: 49.049188, lng: 20.528206 },
    { lat: 49.04931, lng: 20.528283 },
    { lat: 49.049424, lng: 20.528347 },
    { lat: 49.049514, lng: 20.52887 },
    { lat: 49.049427, lng: 20.52905 },
    { lat: 49.049387, lng: 20.529171 },
    { lat: 49.049328, lng: 20.529384 },
    { lat: 49.049301, lng: 20.529551 },
    { lat: 49.049178, lng: 20.529832 },
    { lat: 49.04914, lng: 20.53008 },
    { lat: 49.049219, lng: 20.530356 },
    { lat: 49.049324, lng: 20.530862 },
    { lat: 49.049336, lng: 20.531069 },
    { lat: 49.049356, lng: 20.531079 },
    { lat: 49.049435, lng: 20.531119 },
    { lat: 49.049556, lng: 20.531176 },
    { lat: 49.04962, lng: 20.531207 },
    { lat: 49.049646, lng: 20.531517 },
    { lat: 49.049675, lng: 20.531798 },
    { lat: 49.049713, lng: 20.532062 },
    { lat: 49.049926, lng: 20.532606 },
    { lat: 49.050058, lng: 20.5329 },
    { lat: 49.050169, lng: 20.533042 },
    { lat: 49.050203, lng: 20.53305 },
    { lat: 49.050434, lng: 20.533108 },
    { lat: 49.050503, lng: 20.533384 },
    { lat: 49.050554, lng: 20.533517 },
    { lat: 49.050567, lng: 20.533713 },
    { lat: 49.050639, lng: 20.533934 },
    { lat: 49.050749, lng: 20.534152 },
    { lat: 49.050897, lng: 20.534691 },
    { lat: 49.050901, lng: 20.534931 },
    { lat: 49.050956, lng: 20.535148 },
    { lat: 49.050994, lng: 20.534891 },
    { lat: 49.05104, lng: 20.534749 },
    { lat: 49.051083, lng: 20.534704 },
    { lat: 49.051124, lng: 20.534691 },
    { lat: 49.051231, lng: 20.534905 },
    { lat: 49.0513, lng: 20.534799 },
    { lat: 49.051321, lng: 20.534642 },
    { lat: 49.051223, lng: 20.534387 },
    { lat: 49.051146, lng: 20.53389 },
    { lat: 49.051019, lng: 20.533534 },
    { lat: 49.051081, lng: 20.533485 },
    { lat: 49.051057, lng: 20.533299 },
    { lat: 49.051048, lng: 20.533244 },
    { lat: 49.051231, lng: 20.532983 },
    { lat: 49.051284, lng: 20.532998 },
    { lat: 49.051514, lng: 20.533457 },
    { lat: 49.051628, lng: 20.533658 },
    { lat: 49.051659, lng: 20.533795 },
    { lat: 49.051732, lng: 20.534023 },
    { lat: 49.051788, lng: 20.534478 },
    { lat: 49.05178, lng: 20.534675 },
    { lat: 49.051745, lng: 20.534864 },
    { lat: 49.051851, lng: 20.535471 },
    { lat: 49.051662, lng: 20.535332 },
    { lat: 49.051581, lng: 20.535639 },
    { lat: 49.05158, lng: 20.536145 },
    { lat: 49.051546, lng: 20.536727 },
    { lat: 49.051607, lng: 20.537056 },
    { lat: 49.051654, lng: 20.537603 },
    { lat: 49.051695, lng: 20.537861 },
    { lat: 49.051695, lng: 20.537959 },
    { lat: 49.051639, lng: 20.538131 },
    { lat: 49.051864, lng: 20.538199 },
    { lat: 49.051931, lng: 20.53819 },
    { lat: 49.051983, lng: 20.538197 },
    { lat: 49.05212, lng: 20.538243 },
    { lat: 49.052151, lng: 20.538654 },
    { lat: 49.052199, lng: 20.538655 },
    { lat: 49.052266, lng: 20.538462 },
    { lat: 49.05233, lng: 20.538534 },
    { lat: 49.052376, lng: 20.538475 },
    { lat: 49.052395, lng: 20.538349 },
    { lat: 49.052476, lng: 20.53839 },
    { lat: 49.052575, lng: 20.538687 },
    { lat: 49.0526, lng: 20.538685 },
    { lat: 49.052729, lng: 20.538677 },
    { lat: 49.052769, lng: 20.538707 },
    { lat: 49.052802, lng: 20.538729 },
    { lat: 49.052896, lng: 20.538782 },
    { lat: 49.052926, lng: 20.538877 },
    { lat: 49.052953, lng: 20.538971 },
    { lat: 49.053057, lng: 20.539131 },
    { lat: 49.053121, lng: 20.53936 },
    { lat: 49.053181, lng: 20.53961 },
    { lat: 49.053331, lng: 20.539926 },
    { lat: 49.053535, lng: 20.540222 },
    { lat: 49.05373, lng: 20.540547 },
    { lat: 49.053936, lng: 20.540743 },
    { lat: 49.054157, lng: 20.540889 },
    { lat: 49.054112, lng: 20.540976 },
    { lat: 49.054263, lng: 20.541284 },
    { lat: 49.054405, lng: 20.541578 },
    { lat: 49.054686, lng: 20.542025 },
    { lat: 49.054719, lng: 20.542293 },
    { lat: 49.054917, lng: 20.542894 },
    { lat: 49.05487, lng: 20.543576 },
    { lat: 49.054756, lng: 20.544008 },
    { lat: 49.054574, lng: 20.544727 },
    { lat: 49.05455, lng: 20.544926 },
    { lat: 49.054785, lng: 20.545408 },
    { lat: 49.054875, lng: 20.545431 },
    { lat: 49.05508, lng: 20.545547 },
    { lat: 49.055144, lng: 20.545713 },
    { lat: 49.055292, lng: 20.546305 },
    { lat: 49.055482, lng: 20.546712 },
    { lat: 49.055523, lng: 20.547364 },
    { lat: 49.055366, lng: 20.547953 },
    { lat: 49.055494, lng: 20.548023 },
    { lat: 49.055832, lng: 20.547616 },
    { lat: 49.056031, lng: 20.547636 },
    { lat: 49.056474, lng: 20.548081 },
    { lat: 49.05657, lng: 20.547983 },
    { lat: 49.056744, lng: 20.548017 },
    { lat: 49.056831, lng: 20.548125 },
    { lat: 49.056789, lng: 20.548372 },
    { lat: 49.056925, lng: 20.548522 },
    { lat: 49.057163, lng: 20.548002 },
    { lat: 49.05734, lng: 20.547806 },
    { lat: 49.057436, lng: 20.547791 },
    { lat: 49.057802, lng: 20.547745 },
    { lat: 49.058133, lng: 20.547507 },
    { lat: 49.058809, lng: 20.547427 },
    { lat: 49.058865, lng: 20.54718 },
    { lat: 49.058879, lng: 20.547116 },
    { lat: 49.058999, lng: 20.546686 },
    { lat: 49.058438, lng: 20.546552 },
    { lat: 49.057967, lng: 20.546435 },
    { lat: 49.057973, lng: 20.546061 },
    { lat: 49.058062, lng: 20.545531 },
    { lat: 49.058123, lng: 20.545203 },
    { lat: 49.058137, lng: 20.544949 },
    { lat: 49.058268, lng: 20.54473 },
    { lat: 49.058485, lng: 20.544627 },
    { lat: 49.058487, lng: 20.544423 },
    { lat: 49.058521, lng: 20.544216 },
    { lat: 49.058635, lng: 20.544006 },
    { lat: 49.058741, lng: 20.543968 },
    { lat: 49.058707, lng: 20.543289 },
    { lat: 49.058915, lng: 20.542938 },
    { lat: 49.058928, lng: 20.542682 },
    { lat: 49.058831, lng: 20.542579 },
    { lat: 49.058635, lng: 20.541871 },
    { lat: 49.058551, lng: 20.541735 },
    { lat: 49.058647, lng: 20.541526 },
    { lat: 49.058982, lng: 20.541495 },
    { lat: 49.059524, lng: 20.541452 },
    { lat: 49.059684, lng: 20.540897 },
    { lat: 49.059603, lng: 20.540078 },
    { lat: 49.059623, lng: 20.539664 },
    { lat: 49.059403, lng: 20.539181 },
    { lat: 49.059315, lng: 20.539021 },
    { lat: 49.059172, lng: 20.538798 },
    { lat: 49.059086, lng: 20.538515 },
    { lat: 49.059099, lng: 20.538279 },
    { lat: 49.0595, lng: 20.53838 },
    { lat: 49.059746, lng: 20.53844 },
    { lat: 49.059755, lng: 20.538443 },
    { lat: 49.060053, lng: 20.538543 },
    { lat: 49.060475, lng: 20.538685 },
    { lat: 49.060534, lng: 20.538724 },
    { lat: 49.061166, lng: 20.539142 },
    { lat: 49.061167, lng: 20.539142 },
    { lat: 49.061774, lng: 20.539289 },
    { lat: 49.061892, lng: 20.539186 },
    { lat: 49.062023, lng: 20.53907 },
    { lat: 49.062322, lng: 20.539168 },
    { lat: 49.062595, lng: 20.539256 },
    { lat: 49.063116, lng: 20.539428 },
    { lat: 49.063526, lng: 20.539558 },
    { lat: 49.06392, lng: 20.539703 },
    { lat: 49.064342, lng: 20.539855 },
    { lat: 49.064833, lng: 20.540026 },
    { lat: 49.065341, lng: 20.540179 },
    { lat: 49.066049, lng: 20.540391 },
    { lat: 49.066404, lng: 20.540493 },
    { lat: 49.066404, lng: 20.540494 },
    { lat: 49.066881, lng: 20.540449 },
    { lat: 49.066786, lng: 20.540703 },
    { lat: 49.066701, lng: 20.540932 },
    { lat: 49.066617, lng: 20.541293 },
    { lat: 49.066694, lng: 20.541484 },
    { lat: 49.066694, lng: 20.541493 },
    { lat: 49.066686, lng: 20.541678 },
    { lat: 49.066693, lng: 20.541687 },
    { lat: 49.066975, lng: 20.542051 },
    { lat: 49.066897, lng: 20.542265 },
    { lat: 49.066761, lng: 20.542495 },
    { lat: 49.066671, lng: 20.542628 },
    { lat: 49.066574, lng: 20.542834 },
    { lat: 49.066492, lng: 20.543058 },
    { lat: 49.066404, lng: 20.543279 },
    { lat: 49.066237, lng: 20.544035 },
    { lat: 49.066138, lng: 20.544269 },
    { lat: 49.066138, lng: 20.544271 },
    { lat: 49.065964, lng: 20.544618 },
    { lat: 49.065974, lng: 20.544742 },
    { lat: 49.065997, lng: 20.545025 },
    { lat: 49.065756, lng: 20.545298 },
    { lat: 49.065755, lng: 20.545299 },
    { lat: 49.065652, lng: 20.54536 },
    { lat: 49.0656, lng: 20.545392 },
    { lat: 49.065599, lng: 20.545392 },
    { lat: 49.064953, lng: 20.545675 },
    { lat: 49.064641, lng: 20.545933 },
    { lat: 49.064858, lng: 20.546107 },
    { lat: 49.065064, lng: 20.546185 },
    { lat: 49.065066, lng: 20.546186 },
    { lat: 49.06535, lng: 20.546464 },
    { lat: 49.065258, lng: 20.546774 },
    { lat: 49.065239, lng: 20.546839 },
    { lat: 49.0651381, lng: 20.5471477 },
    { lat: 49.0653771, lng: 20.5471862 },
    { lat: 49.0659127, lng: 20.5472713 },
    { lat: 49.0663074, lng: 20.5472574 },
    { lat: 49.0664945, lng: 20.5473549 },
    { lat: 49.0667175, lng: 20.5477212 },
    { lat: 49.0668548, lng: 20.5475992 },
  ],
  Majere: [
    { lat: 49.39084, lng: 20.386532 },
    { lat: 49.390853, lng: 20.384581 },
    { lat: 49.390989, lng: 20.38288 },
    { lat: 49.3910494, lng: 20.3825319 },
    { lat: 49.391229, lng: 20.382309 },
    { lat: 49.391718, lng: 20.381915 },
    { lat: 49.391926, lng: 20.381749 },
    { lat: 49.392246, lng: 20.381546 },
    { lat: 49.392495, lng: 20.38142 },
    { lat: 49.392662, lng: 20.381344 },
    { lat: 49.393062, lng: 20.381239 },
    { lat: 49.393172, lng: 20.381159 },
    { lat: 49.393752, lng: 20.381079 },
    { lat: 49.393796, lng: 20.381076 },
    { lat: 49.394001, lng: 20.380932 },
    { lat: 49.39419, lng: 20.380757 },
    { lat: 49.394254, lng: 20.380704 },
    { lat: 49.394446, lng: 20.380666 },
    { lat: 49.394715, lng: 20.380608 },
    { lat: 49.395019, lng: 20.380549 },
    { lat: 49.395292, lng: 20.380522 },
    { lat: 49.395536, lng: 20.380484 },
    { lat: 49.39563, lng: 20.38047 },
    { lat: 49.395857, lng: 20.380532 },
    { lat: 49.396072, lng: 20.380603 },
    { lat: 49.396445, lng: 20.380987 },
    { lat: 49.396679, lng: 20.381146 },
    { lat: 49.396917, lng: 20.381184 },
    { lat: 49.397033, lng: 20.381174 },
    { lat: 49.397319, lng: 20.381209 },
    { lat: 49.397594, lng: 20.381217 },
    { lat: 49.397899, lng: 20.381257 },
    { lat: 49.398627, lng: 20.381344 },
    { lat: 49.398905, lng: 20.381362 },
    { lat: 49.399142, lng: 20.381392 },
    { lat: 49.399466, lng: 20.381373 },
    { lat: 49.399751, lng: 20.38136 },
    { lat: 49.400069, lng: 20.381395 },
    { lat: 49.400315, lng: 20.381388 },
    { lat: 49.400501, lng: 20.381358 },
    { lat: 49.400675, lng: 20.381333 },
    { lat: 49.400889, lng: 20.381353 },
    { lat: 49.401144, lng: 20.381339 },
    { lat: 49.401441, lng: 20.381309 },
    { lat: 49.401689, lng: 20.381189 },
    { lat: 49.402071, lng: 20.381092 },
    { lat: 49.402238, lng: 20.381083 },
    { lat: 49.402429, lng: 20.381021 },
    { lat: 49.402579, lng: 20.381017 },
    { lat: 49.402992, lng: 20.380947 },
    { lat: 49.403257, lng: 20.380912 },
    { lat: 49.403432, lng: 20.380742 },
    { lat: 49.403641, lng: 20.380587 },
    { lat: 49.40423, lng: 20.380119 },
    { lat: 49.404618, lng: 20.37983 },
    { lat: 49.404933, lng: 20.37956 },
    { lat: 49.405184, lng: 20.379314 },
    { lat: 49.405582, lng: 20.379263 },
    { lat: 49.405888, lng: 20.378845 },
    { lat: 49.406197, lng: 20.378448 },
    { lat: 49.4064691, lng: 20.3779963 },
    { lat: 49.406807, lng: 20.377477 },
    { lat: 49.407083, lng: 20.377031 },
    { lat: 49.407551, lng: 20.376347 },
    { lat: 49.407713, lng: 20.37606 },
    { lat: 49.40786, lng: 20.375681 },
    { lat: 49.407954, lng: 20.37496 },
    { lat: 49.407949, lng: 20.37471 },
    { lat: 49.408, lng: 20.374327 },
    { lat: 49.407995, lng: 20.373931 },
    { lat: 49.407983, lng: 20.373129 },
    { lat: 49.407892, lng: 20.372559 },
    { lat: 49.407788, lng: 20.372117 },
    { lat: 49.407625, lng: 20.37162 },
    { lat: 49.407381, lng: 20.371134 },
    { lat: 49.407138, lng: 20.370811 },
    { lat: 49.406794, lng: 20.370466 },
    { lat: 49.406444, lng: 20.370192 },
    { lat: 49.40611, lng: 20.370028 },
    { lat: 49.405748, lng: 20.369942 },
    { lat: 49.405493, lng: 20.369873 },
    { lat: 49.405302, lng: 20.36983 },
    { lat: 49.404936, lng: 20.369767 },
    { lat: 49.404664, lng: 20.369759 },
    { lat: 49.4044302, lng: 20.3702052 },
    { lat: 49.4042814, lng: 20.3706411 },
    { lat: 49.4040991, lng: 20.3708866 },
    { lat: 49.4038558, lng: 20.3709694 },
    { lat: 49.4034884, lng: 20.3712856 },
    { lat: 49.4028907, lng: 20.3716734 },
    { lat: 49.4025561, lng: 20.3719932 },
    { lat: 49.4022922, lng: 20.3720695 },
    { lat: 49.4020388, lng: 20.3720947 },
    { lat: 49.4014333, lng: 20.3720107 },
    { lat: 49.4008948, lng: 20.3717088 },
    { lat: 49.4004209, lng: 20.3713257 },
    { lat: 49.3999643, lng: 20.3706272 },
    { lat: 49.3995306, lng: 20.3703207 },
    { lat: 49.3988622, lng: 20.3705998 },
    { lat: 49.3987854, lng: 20.3704466 },
    { lat: 49.3985652, lng: 20.3702768 },
    { lat: 49.3981089, lng: 20.3702088 },
    { lat: 49.3973332, lng: 20.3706851 },
    { lat: 49.3970999, lng: 20.3707666 },
    { lat: 49.3968607, lng: 20.3706929 },
    { lat: 49.3966155, lng: 20.3708131 },
    { lat: 49.3964511, lng: 20.3711123 },
    { lat: 49.3961629, lng: 20.3714066 },
    { lat: 49.3947517, lng: 20.3723993 },
    { lat: 49.3942773, lng: 20.3723893 },
    { lat: 49.3942821, lng: 20.3725161 },
    { lat: 49.3942245, lng: 20.3726575 },
    { lat: 49.3938662, lng: 20.3732154 },
    { lat: 49.3937704, lng: 20.3734776 },
    { lat: 49.3936522, lng: 20.3741908 },
    { lat: 49.393528, lng: 20.3743844 },
    { lat: 49.3933746, lng: 20.3742597 },
    { lat: 49.3932078, lng: 20.3739319 },
    { lat: 49.3928554, lng: 20.373988 },
    { lat: 49.3925202, lng: 20.374144 },
    { lat: 49.3922121, lng: 20.3744249 },
    { lat: 49.3917674, lng: 20.376465 },
    { lat: 49.3908576, lng: 20.376253 },
    { lat: 49.3907331, lng: 20.3771539 },
    { lat: 49.3905187, lng: 20.3781167 },
    { lat: 49.3903607, lng: 20.3782558 },
    { lat: 49.3901272, lng: 20.3787801 },
    { lat: 49.3899291, lng: 20.37896 },
    { lat: 49.3892813, lng: 20.3799856 },
    { lat: 49.389067, lng: 20.3812512 },
    { lat: 49.3879857, lng: 20.3852401 },
    { lat: 49.3880337, lng: 20.3852607 },
    { lat: 49.3882893, lng: 20.3852022 },
    { lat: 49.3883447, lng: 20.3854137 },
    { lat: 49.3886255, lng: 20.3854267 },
    { lat: 49.388836, lng: 20.3855775 },
    { lat: 49.39084, lng: 20.386532 },
  ],
  Vojňany: [
    { lat: 49.2774226, lng: 20.4361266 },
    { lat: 49.2767475, lng: 20.4353003 },
    { lat: 49.2752499, lng: 20.4334692 },
    { lat: 49.2748984, lng: 20.4331049 },
    { lat: 49.2746093, lng: 20.4331087 },
    { lat: 49.2743538, lng: 20.4333792 },
    { lat: 49.2742407, lng: 20.4336966 },
    { lat: 49.274137, lng: 20.43378 },
    { lat: 49.274129, lng: 20.4340198 },
    { lat: 49.2734579, lng: 20.4344043 },
    { lat: 49.2734111, lng: 20.4345649 },
    { lat: 49.2732882, lng: 20.4347105 },
    { lat: 49.2732374, lng: 20.4351282 },
    { lat: 49.2731082, lng: 20.4354056 },
    { lat: 49.2729208, lng: 20.4355994 },
    { lat: 49.272851, lng: 20.435567 },
    { lat: 49.2728002, lng: 20.4353912 },
    { lat: 49.2727185, lng: 20.4354012 },
    { lat: 49.2726049, lng: 20.435643 },
    { lat: 49.2723606, lng: 20.435525 },
    { lat: 49.2721579, lng: 20.4359383 },
    { lat: 49.2720914, lng: 20.4359719 },
    { lat: 49.2720451, lng: 20.4359074 },
    { lat: 49.2719223, lng: 20.4359034 },
    { lat: 49.2719008, lng: 20.436047 },
    { lat: 49.2716955, lng: 20.4360711 },
    { lat: 49.2716246, lng: 20.4362183 },
    { lat: 49.2714648, lng: 20.4361905 },
    { lat: 49.2712811, lng: 20.4364558 },
    { lat: 49.2710181, lng: 20.4366199 },
    { lat: 49.2710465, lng: 20.436957 },
    { lat: 49.2709624, lng: 20.4369041 },
    { lat: 49.2708871, lng: 20.4367272 },
    { lat: 49.2703457, lng: 20.437242 },
    { lat: 49.2700323, lng: 20.437081 },
    { lat: 49.2699715, lng: 20.4371404 },
    { lat: 49.2699151, lng: 20.4373483 },
    { lat: 49.2698178, lng: 20.4374185 },
    { lat: 49.2698604, lng: 20.4377183 },
    { lat: 49.2697552, lng: 20.4379449 },
    { lat: 49.2696046, lng: 20.4378925 },
    { lat: 49.2692101, lng: 20.4379672 },
    { lat: 49.2680761, lng: 20.4379938 },
    { lat: 49.2678127, lng: 20.4379657 },
    { lat: 49.2674719, lng: 20.4378244 },
    { lat: 49.2668006, lng: 20.4373926 },
    { lat: 49.2660698, lng: 20.4366103 },
    { lat: 49.265036, lng: 20.4351041 },
    { lat: 49.2645476, lng: 20.4345756 },
    { lat: 49.2636896, lng: 20.434032 },
    { lat: 49.2630424, lng: 20.4337034 },
    { lat: 49.2616895, lng: 20.4324572 },
    { lat: 49.2595992, lng: 20.4302232 },
    { lat: 49.2577567, lng: 20.4285966 },
    { lat: 49.2568724, lng: 20.4277423 },
    { lat: 49.2565269, lng: 20.427658 },
    { lat: 49.2534006, lng: 20.4247923 },
    { lat: 49.2528295, lng: 20.4243934 },
    { lat: 49.2524657, lng: 20.4243941 },
    { lat: 49.2522747, lng: 20.4246729 },
    { lat: 49.2496725, lng: 20.4274706 },
    { lat: 49.2493213, lng: 20.4277415 },
    { lat: 49.2488426, lng: 20.4279739 },
    { lat: 49.248805, lng: 20.4278136 },
    { lat: 49.2487412, lng: 20.4278228 },
    { lat: 49.2487424, lng: 20.4279438 },
    { lat: 49.2486126, lng: 20.4280539 },
    { lat: 49.2486054, lng: 20.42822 },
    { lat: 49.2484465, lng: 20.428248 },
    { lat: 49.2484335, lng: 20.4284116 },
    { lat: 49.2482457, lng: 20.428568 },
    { lat: 49.2480569, lng: 20.4289031 },
    { lat: 49.2479507, lng: 20.4289835 },
    { lat: 49.2479278, lng: 20.4290965 },
    { lat: 49.2480205, lng: 20.4292829 },
    { lat: 49.2479586, lng: 20.429377 },
    { lat: 49.2478382, lng: 20.4293735 },
    { lat: 49.2477477, lng: 20.4292449 },
    { lat: 49.247528, lng: 20.429332 },
    { lat: 49.2473064, lng: 20.4295172 },
    { lat: 49.2472492, lng: 20.4297885 },
    { lat: 49.2470365, lng: 20.4300292 },
    { lat: 49.2468179, lng: 20.4299652 },
    { lat: 49.2467694, lng: 20.4302364 },
    { lat: 49.2465975, lng: 20.4303625 },
    { lat: 49.2463815, lng: 20.430397 },
    { lat: 49.2461929, lng: 20.430588 },
    { lat: 49.2460444, lng: 20.430561 },
    { lat: 49.2459398, lng: 20.4304473 },
    { lat: 49.2459403, lng: 20.4302783 },
    { lat: 49.2458811, lng: 20.4301729 },
    { lat: 49.2457585, lng: 20.4301746 },
    { lat: 49.2456045, lng: 20.4303013 },
    { lat: 49.2456029, lng: 20.4302258 },
    { lat: 49.2453702, lng: 20.4308376 },
    { lat: 49.2452307, lng: 20.4311053 },
    { lat: 49.2451219, lng: 20.4312107 },
    { lat: 49.2444747, lng: 20.4315735 },
    { lat: 49.2440951, lng: 20.4314796 },
    { lat: 49.243874, lng: 20.4320053 },
    { lat: 49.2434819, lng: 20.4324757 },
    { lat: 49.2434265, lng: 20.4328881 },
    { lat: 49.2433749, lng: 20.4329846 },
    { lat: 49.2432458, lng: 20.4331098 },
    { lat: 49.242713, lng: 20.433377 },
    { lat: 49.2423196, lng: 20.4338123 },
    { lat: 49.2421654, lng: 20.4341079 },
    { lat: 49.2420905, lng: 20.4346002 },
    { lat: 49.2417176, lng: 20.4346528 },
    { lat: 49.2414995, lng: 20.4343945 },
    { lat: 49.241441, lng: 20.4344562 },
    { lat: 49.2411526, lng: 20.4353833 },
    { lat: 49.2408492, lng: 20.4356341 },
    { lat: 49.240791, lng: 20.4357613 },
    { lat: 49.2409583, lng: 20.4361697 },
    { lat: 49.2408758, lng: 20.436881 },
    { lat: 49.2409175, lng: 20.4375677 },
    { lat: 49.2404242, lng: 20.4381968 },
    { lat: 49.2404011, lng: 20.4383905 },
    { lat: 49.2404767, lng: 20.4387532 },
    { lat: 49.2403359, lng: 20.4390469 },
    { lat: 49.2402844, lng: 20.4395044 },
    { lat: 49.2401549, lng: 20.4398692 },
    { lat: 49.2401408, lng: 20.4401208 },
    { lat: 49.2401924, lng: 20.4404474 },
    { lat: 49.2400968, lng: 20.4405963 },
    { lat: 49.2401207, lng: 20.4409621 },
    { lat: 49.2399668, lng: 20.4409403 },
    { lat: 49.2399282, lng: 20.4409923 },
    { lat: 49.2399254, lng: 20.441252 },
    { lat: 49.2396791, lng: 20.4411644 },
    { lat: 49.2395605, lng: 20.4412465 },
    { lat: 49.2396362, lng: 20.4417933 },
    { lat: 49.2394738, lng: 20.441872 },
    { lat: 49.2394495, lng: 20.4419469 },
    { lat: 49.2394954, lng: 20.442034 },
    { lat: 49.2397062, lng: 20.4421561 },
    { lat: 49.2397581, lng: 20.4424574 },
    { lat: 49.2397796, lng: 20.4427027 },
    { lat: 49.2396595, lng: 20.4429842 },
    { lat: 49.2396093, lng: 20.4437474 },
    { lat: 49.239811, lng: 20.4440176 },
    { lat: 49.23993, lng: 20.4445786 },
    { lat: 49.2401972, lng: 20.44477 },
    { lat: 49.2402432, lng: 20.4452178 },
    { lat: 49.2400786, lng: 20.4457478 },
    { lat: 49.2401163, lng: 20.4458829 },
    { lat: 49.2402588, lng: 20.4459597 },
    { lat: 49.2403074, lng: 20.4460542 },
    { lat: 49.2402525, lng: 20.4463328 },
    { lat: 49.2402368, lng: 20.4467886 },
    { lat: 49.2402743, lng: 20.4467939 },
    { lat: 49.2403127, lng: 20.4467992 },
    { lat: 49.2403913, lng: 20.4463409 },
    { lat: 49.2412046, lng: 20.4465626 },
    { lat: 49.2419758, lng: 20.446952 },
    { lat: 49.2433309, lng: 20.4478135 },
    { lat: 49.2454567, lng: 20.449292 },
    { lat: 49.246239, lng: 20.4500073 },
    { lat: 49.2476076, lng: 20.4514941 },
    { lat: 49.2490003, lng: 20.4535439 },
    { lat: 49.2491025, lng: 20.4536842 },
    { lat: 49.2491385, lng: 20.4536551 },
    { lat: 49.2492242, lng: 20.4538725 },
    { lat: 49.2497333, lng: 20.4546273 },
    { lat: 49.2499577, lng: 20.4553926 },
    { lat: 49.2502323, lng: 20.4560158 },
    { lat: 49.2503733, lng: 20.4565989 },
    { lat: 49.2507889, lng: 20.4576239 },
    { lat: 49.2520523, lng: 20.4599797 },
    { lat: 49.2524569, lng: 20.460502 },
    { lat: 49.252809, lng: 20.4612756 },
    { lat: 49.2542044, lng: 20.463547 },
    { lat: 49.2542816, lng: 20.463417 },
    { lat: 49.254267, lng: 20.4632979 },
    { lat: 49.2543644, lng: 20.4628299 },
    { lat: 49.2546985, lng: 20.4624317 },
    { lat: 49.2549262, lng: 20.4618662 },
    { lat: 49.2550534, lng: 20.4618673 },
    { lat: 49.2552017, lng: 20.461649 },
    { lat: 49.2551933, lng: 20.4615142 },
    { lat: 49.2554084, lng: 20.4610651 },
    { lat: 49.2556708, lng: 20.4607632 },
    { lat: 49.2557514, lng: 20.4605075 },
    { lat: 49.2558088, lng: 20.4604787 },
    { lat: 49.2563043, lng: 20.4595795 },
    { lat: 49.2565352, lng: 20.4590089 },
    { lat: 49.2565279, lng: 20.4588769 },
    { lat: 49.2567389, lng: 20.458501 },
    { lat: 49.2574288, lng: 20.4581636 },
    { lat: 49.2578743, lng: 20.4576723 },
    { lat: 49.2580563, lng: 20.4576626 },
    { lat: 49.2582238, lng: 20.4574749 },
    { lat: 49.2584945, lng: 20.4569741 },
    { lat: 49.2586245, lng: 20.4564743 },
    { lat: 49.2588319, lng: 20.4560907 },
    { lat: 49.2595479, lng: 20.4550402 },
    { lat: 49.2612388, lng: 20.4541654 },
    { lat: 49.262266, lng: 20.4538457 },
    { lat: 49.2630899, lng: 20.4539034 },
    { lat: 49.2657375, lng: 20.453442 },
    { lat: 49.2657619, lng: 20.4534312 },
    { lat: 49.2666784, lng: 20.4525926 },
    { lat: 49.2668447, lng: 20.4518931 },
    { lat: 49.2667335, lng: 20.4513571 },
    { lat: 49.2674173, lng: 20.450378 },
    { lat: 49.2688985, lng: 20.4477865 },
    { lat: 49.2698503, lng: 20.4462989 },
    { lat: 49.2708051, lng: 20.445305 },
    { lat: 49.2717451, lng: 20.4435353 },
    { lat: 49.2725403, lng: 20.4427348 },
    { lat: 49.2730688, lng: 20.4417879 },
    { lat: 49.2732376, lng: 20.4416397 },
    { lat: 49.273718, lng: 20.440906 },
    { lat: 49.274514, lng: 20.4398321 },
    { lat: 49.2752204, lng: 20.4391243 },
    { lat: 49.2759845, lng: 20.4380358 },
    { lat: 49.2761612, lng: 20.4378552 },
    { lat: 49.2764519, lng: 20.4373548 },
    { lat: 49.2771623, lng: 20.4365706 },
    { lat: 49.2774226, lng: 20.4361266 },
  ],
  VeľkáFranková: [
    { lat: 49.3027035, lng: 20.3273677 },
    { lat: 49.3027164, lng: 20.3273528 },
    { lat: 49.3039379, lng: 20.3267345 },
    { lat: 49.3042351, lng: 20.3265021 },
    { lat: 49.3045159, lng: 20.3261026 },
    { lat: 49.3050881, lng: 20.3254619 },
    { lat: 49.3053736, lng: 20.3247813 },
    { lat: 49.3053836, lng: 20.3245712 },
    { lat: 49.3057833, lng: 20.324515 },
    { lat: 49.3070744, lng: 20.3240396 },
    { lat: 49.3078649, lng: 20.3239194 },
    { lat: 49.3089528, lng: 20.3233761 },
    { lat: 49.3097707, lng: 20.3230675 },
    { lat: 49.3103975, lng: 20.3229431 },
    { lat: 49.3106841, lng: 20.3228127 },
    { lat: 49.3118464, lng: 20.3218833 },
    { lat: 49.313496, lng: 20.3211605 },
    { lat: 49.314552, lng: 20.3210682 },
    { lat: 49.315754, lng: 20.3203553 },
    { lat: 49.3165031, lng: 20.3196645 },
    { lat: 49.3172091, lng: 20.3192872 },
    { lat: 49.3190147, lng: 20.3174937 },
    { lat: 49.3200868, lng: 20.3170061 },
    { lat: 49.3198385, lng: 20.3165203 },
    { lat: 49.3204479, lng: 20.3161524 },
    { lat: 49.3210786, lng: 20.3158912 },
    { lat: 49.3220411, lng: 20.3159476 },
    { lat: 49.32218, lng: 20.3160152 },
    { lat: 49.3222424, lng: 20.3163474 },
    { lat: 49.3227615, lng: 20.3166737 },
    { lat: 49.3232409, lng: 20.3174541 },
    { lat: 49.3236557, lng: 20.3176704 },
    { lat: 49.3250327, lng: 20.3176111 },
    { lat: 49.326828, lng: 20.3163713 },
    { lat: 49.3289936, lng: 20.315422 },
    { lat: 49.3295561, lng: 20.3153806 },
    { lat: 49.3299125, lng: 20.3155668 },
    { lat: 49.3310454, lng: 20.314936 },
    { lat: 49.3313171, lng: 20.3154107 },
    { lat: 49.3318755, lng: 20.3152928 },
    { lat: 49.3321135, lng: 20.3153912 },
    { lat: 49.3323712, lng: 20.315887 },
    { lat: 49.3331184, lng: 20.3157289 },
    { lat: 49.3337949, lng: 20.3157559 },
    { lat: 49.3345855, lng: 20.3158152 },
    { lat: 49.3355022, lng: 20.3160873 },
    { lat: 49.336496, lng: 20.3159287 },
    { lat: 49.3376144, lng: 20.3156204 },
    { lat: 49.3391919, lng: 20.3156527 },
    { lat: 49.3401409, lng: 20.3169885 },
    { lat: 49.3403356, lng: 20.3168431 },
    { lat: 49.3405654, lng: 20.3168169 },
    { lat: 49.3406717, lng: 20.3168817 },
    { lat: 49.3409515, lng: 20.3173645 },
    { lat: 49.3412076, lng: 20.3176143 },
    { lat: 49.3416746, lng: 20.3186309 },
    { lat: 49.3419837, lng: 20.3184733 },
    { lat: 49.3423189, lng: 20.3184044 },
    { lat: 49.3426687, lng: 20.3185978 },
    { lat: 49.3430441, lng: 20.318409 },
    { lat: 49.3433273, lng: 20.3181592 },
    { lat: 49.3438374, lng: 20.3178658 },
    { lat: 49.3443582, lng: 20.3177405 },
    { lat: 49.3447484, lng: 20.3177273 },
    { lat: 49.3458459, lng: 20.3178586 },
    { lat: 49.3455028, lng: 20.3171903 },
    { lat: 49.3450276, lng: 20.3169318 },
    { lat: 49.3447236, lng: 20.3168556 },
    { lat: 49.3445299, lng: 20.3164888 },
    { lat: 49.3446189, lng: 20.3161897 },
    { lat: 49.3443108, lng: 20.3159029 },
    { lat: 49.3441547, lng: 20.31537 },
    { lat: 49.3435077, lng: 20.3141766 },
    { lat: 49.3432946, lng: 20.3128612 },
    { lat: 49.3433216, lng: 20.3113076 },
    { lat: 49.343708, lng: 20.3110461 },
    { lat: 49.343767, lng: 20.31028 },
    { lat: 49.3438825, lng: 20.3087963 },
    { lat: 49.3439398, lng: 20.3084345 },
    { lat: 49.3442129, lng: 20.3079128 },
    { lat: 49.344212, lng: 20.307908 },
    { lat: 49.3443411, lng: 20.3077147 },
    { lat: 49.3445329, lng: 20.3075726 },
    { lat: 49.3446398, lng: 20.3073374 },
    { lat: 49.3447802, lng: 20.3070639 },
    { lat: 49.344779, lng: 20.307059 },
    { lat: 49.3450302, lng: 20.3067089 },
    { lat: 49.3452026, lng: 20.3064257 },
    { lat: 49.345201, lng: 20.306421 },
    { lat: 49.3454175, lng: 20.306167 },
    { lat: 49.345574, lng: 20.305785 },
    { lat: 49.3455956, lng: 20.305741 },
    { lat: 49.345721, lng: 20.305449 },
    { lat: 49.3458398, lng: 20.3051929 },
    { lat: 49.345842, lng: 20.305184 },
    { lat: 49.3460624, lng: 20.3048978 },
    { lat: 49.346061, lng: 20.304893 },
    { lat: 49.3462201, lng: 20.3045973 },
    { lat: 49.3463852, lng: 20.3043254 },
    { lat: 49.346386, lng: 20.304319 },
    { lat: 49.3465384, lng: 20.3040903 },
    { lat: 49.3467207, lng: 20.3039176 },
    { lat: 49.346765, lng: 20.3037142 },
    { lat: 49.3468444, lng: 20.3034899 },
    { lat: 49.346888, lng: 20.303314 },
    { lat: 49.3469867, lng: 20.3032003 },
    { lat: 49.3470737, lng: 20.303057 },
    { lat: 49.3470849, lng: 20.302961 },
    { lat: 49.3472149, lng: 20.3027367 },
    { lat: 49.347453, lng: 20.3026308 },
    { lat: 49.3475834, lng: 20.3025172 },
    { lat: 49.347696, lng: 20.302368 },
    { lat: 49.3477018, lng: 20.302367 },
    { lat: 49.3478813, lng: 20.3020982 },
    { lat: 49.348039, lng: 20.3019464 },
    { lat: 49.3482517, lng: 20.301718 },
    { lat: 49.348251, lng: 20.301713 },
    { lat: 49.3483785, lng: 20.3015069 },
    { lat: 49.3485309, lng: 20.3011308 },
    { lat: 49.3487668, lng: 20.3006738 },
    { lat: 49.3488191, lng: 20.3003135 },
    { lat: 49.3487909, lng: 20.3000559 },
    { lat: 49.3488662, lng: 20.2996941 },
    { lat: 49.3487621, lng: 20.2992979 },
    { lat: 49.3488311, lng: 20.2989273 },
    { lat: 49.348863, lng: 20.298694 },
    { lat: 49.3488836, lng: 20.2985479 },
    { lat: 49.3489658, lng: 20.2984663 },
    { lat: 49.348965, lng: 20.298461 },
    { lat: 49.34897, lng: 20.298454 },
    { lat: 49.3490626, lng: 20.2983312 },
    { lat: 49.3491865, lng: 20.2981813 },
    { lat: 49.349202, lng: 20.297937 },
    { lat: 49.3492408, lng: 20.2974361 },
    { lat: 49.3493488, lng: 20.2971601 },
    { lat: 49.349454, lng: 20.297244 },
    { lat: 49.3494908, lng: 20.2970558 },
    { lat: 49.3494695, lng: 20.2966495 },
    { lat: 49.349468, lng: 20.296644 },
    { lat: 49.349478, lng: 20.2961537 },
    { lat: 49.3495382, lng: 20.2957695 },
    { lat: 49.3494912, lng: 20.2955082 },
    { lat: 49.3495041, lng: 20.2953622 },
    { lat: 49.3495232, lng: 20.2951468 },
    { lat: 49.3496685, lng: 20.2949518 },
    { lat: 49.3498257, lng: 20.2948109 },
    { lat: 49.3499876, lng: 20.29444 },
    { lat: 49.3501759, lng: 20.294476 },
    { lat: 49.3502388, lng: 20.2942872 },
    { lat: 49.3502166, lng: 20.2940675 },
    { lat: 49.3502562, lng: 20.2939807 },
    { lat: 49.3504935, lng: 20.2939941 },
    { lat: 49.3506359, lng: 20.2943071 },
    { lat: 49.3508348, lng: 20.2943901 },
    { lat: 49.3508912, lng: 20.2941566 },
    { lat: 49.3510724, lng: 20.294044 },
    { lat: 49.3512318, lng: 20.2936711 },
    { lat: 49.351241, lng: 20.293642 },
    { lat: 49.3513362, lng: 20.2934402 },
    { lat: 49.3513499, lng: 20.2931148 },
    { lat: 49.3515319, lng: 20.2930467 },
    { lat: 49.3515563, lng: 20.2928163 },
    { lat: 49.3514944, lng: 20.2927915 },
    { lat: 49.351416, lng: 20.291138 },
    { lat: 49.3514157, lng: 20.2910861 },
    { lat: 49.351412, lng: 20.29107 },
    { lat: 49.3512621, lng: 20.2897489 },
    { lat: 49.351239, lng: 20.289569 },
    { lat: 49.351205, lng: 20.288168 },
    { lat: 49.3511702, lng: 20.2874167 },
    { lat: 49.351148, lng: 20.287115 },
    { lat: 49.351092, lng: 20.2864651 },
    { lat: 49.351087, lng: 20.28646 },
    { lat: 49.3508888, lng: 20.2861203 },
    { lat: 49.3508833, lng: 20.2859906 },
    { lat: 49.3510039, lng: 20.2857321 },
    { lat: 49.3510891, lng: 20.2854303 },
    { lat: 49.3509299, lng: 20.2850823 },
    { lat: 49.3508733, lng: 20.2848456 },
    { lat: 49.3509189, lng: 20.2843631 },
    { lat: 49.350997, lng: 20.2839876 },
    { lat: 49.350963, lng: 20.2836696 },
    { lat: 49.351054, lng: 20.2831967 },
    { lat: 49.351057, lng: 20.283168 },
    { lat: 49.3511648, lng: 20.2825008 },
    { lat: 49.351163, lng: 20.282494 },
    { lat: 49.3511581, lng: 20.2821172 },
    { lat: 49.351156, lng: 20.282111 },
    { lat: 49.3513379, lng: 20.2821127 },
    { lat: 49.351385, lng: 20.281747 },
    { lat: 49.35139, lng: 20.2817275 },
    { lat: 49.351398, lng: 20.28158 },
    { lat: 49.351417, lng: 20.281246 },
    { lat: 49.3514271, lng: 20.2811385 },
    { lat: 49.351436, lng: 20.280985 },
    { lat: 49.3514712, lng: 20.2807327 },
    { lat: 49.351482, lng: 20.280656 },
    { lat: 49.3515311, lng: 20.280385 },
    { lat: 49.3516241, lng: 20.2803527 },
    { lat: 49.3516029, lng: 20.2800517 },
    { lat: 49.3516771, lng: 20.2797367 },
    { lat: 49.3516477, lng: 20.2795612 },
    { lat: 49.3515751, lng: 20.279462 },
    { lat: 49.35137, lng: 20.279349 },
    { lat: 49.3512513, lng: 20.2792859 },
    { lat: 49.351323, lng: 20.279081 },
    { lat: 49.3513936, lng: 20.2788903 },
    { lat: 49.3514212, lng: 20.2787687 },
    { lat: 49.3513768, lng: 20.2786154 },
    { lat: 49.3514062, lng: 20.2783871 },
    { lat: 49.3514786, lng: 20.2782259 },
    { lat: 49.3514891, lng: 20.2779473 },
    { lat: 49.3514724, lng: 20.2777482 },
    { lat: 49.3513653, lng: 20.277512 },
    { lat: 49.3512456, lng: 20.277538 },
    { lat: 49.351171, lng: 20.2775094 },
    { lat: 49.3511735, lng: 20.2772247 },
    { lat: 49.351126, lng: 20.277081 },
    { lat: 49.3510875, lng: 20.2769519 },
    { lat: 49.351086, lng: 20.276947 },
    { lat: 49.3512239, lng: 20.2766899 },
    { lat: 49.351223, lng: 20.276684 },
    { lat: 49.351207, lng: 20.276431 },
    { lat: 49.3511944, lng: 20.2761335 },
    { lat: 49.351193, lng: 20.276125 },
    { lat: 49.3512679, lng: 20.275806 },
    { lat: 49.351266, lng: 20.275799 },
    { lat: 49.351221, lng: 20.275699 },
    { lat: 49.3511691, lng: 20.2755699 },
    { lat: 49.351167, lng: 20.275564 },
    { lat: 49.3511843, lng: 20.2753514 },
    { lat: 49.351182, lng: 20.275346 },
    { lat: 49.3512452, lng: 20.2750618 },
    { lat: 49.351244, lng: 20.275053 },
    { lat: 49.3512451, lng: 20.2746024 },
    { lat: 49.351243, lng: 20.274587 },
    { lat: 49.3512226, lng: 20.2742165 },
    { lat: 49.3512719, lng: 20.2738011 },
    { lat: 49.3513123, lng: 20.2735784 },
    { lat: 49.35131, lng: 20.273573 },
    { lat: 49.3513721, lng: 20.2730539 },
    { lat: 49.3512979, lng: 20.2729032 },
    { lat: 49.351292, lng: 20.272891 },
    { lat: 49.3512017, lng: 20.27268 },
    { lat: 49.3509174, lng: 20.2724987 },
    { lat: 49.350762, lng: 20.2723341 },
    { lat: 49.350756, lng: 20.272327 },
    { lat: 49.3506002, lng: 20.2721492 },
    { lat: 49.3503098, lng: 20.2718469 },
    { lat: 49.3499955, lng: 20.2714202 },
    { lat: 49.3499309, lng: 20.2712871 },
    { lat: 49.3496318, lng: 20.2710571 },
    { lat: 49.349326, lng: 20.2707869 },
    { lat: 49.349088, lng: 20.2705262 },
    { lat: 49.3490711, lng: 20.2704504 },
    { lat: 49.3487928, lng: 20.2702042 },
    { lat: 49.3487364, lng: 20.2701671 },
    { lat: 49.3484709, lng: 20.2698967 },
    { lat: 49.3483734, lng: 20.2698268 },
    { lat: 49.3482167, lng: 20.269692 },
    { lat: 49.3478782, lng: 20.2695274 },
    { lat: 49.3477504, lng: 20.269404 },
    { lat: 49.347573, lng: 20.2694935 },
    { lat: 49.347469, lng: 20.269595 },
    { lat: 49.3474044, lng: 20.26966 },
    { lat: 49.3472366, lng: 20.2695713 },
    { lat: 49.3471079, lng: 20.2694447 },
    { lat: 49.3469289, lng: 20.2693309 },
    { lat: 49.346889, lng: 20.269268 },
    { lat: 49.346848, lng: 20.2692053 },
    { lat: 49.3464745, lng: 20.2687935 },
    { lat: 49.3461256, lng: 20.2689624 },
    { lat: 49.3460694, lng: 20.2691013 },
    { lat: 49.345694, lng: 20.2686569 },
    { lat: 49.3459697, lng: 20.2676942 },
    { lat: 49.3452191, lng: 20.2670709 },
    { lat: 49.3436609, lng: 20.2675906 },
    { lat: 49.3428099, lng: 20.2682034 },
    { lat: 49.3422484, lng: 20.2687324 },
    { lat: 49.3412496, lng: 20.2695103 },
    { lat: 49.3408985, lng: 20.2715713 },
    { lat: 49.340408, lng: 20.2719814 },
    { lat: 49.3398987, lng: 20.2727009 },
    { lat: 49.3377818, lng: 20.2747469 },
    { lat: 49.3364083, lng: 20.2758498 },
    { lat: 49.3363109, lng: 20.2758684 },
    { lat: 49.3356706, lng: 20.2764813 },
    { lat: 49.3354903, lng: 20.2769122 },
    { lat: 49.335281, lng: 20.2772085 },
    { lat: 49.3347134, lng: 20.2778946 },
    { lat: 49.3344643, lng: 20.2779212 },
    { lat: 49.3336588, lng: 20.2789773 },
    { lat: 49.3332551, lng: 20.2799466 },
    { lat: 49.332366, lng: 20.2806243 },
    { lat: 49.3318355, lng: 20.2804159 },
    { lat: 49.3312665, lng: 20.2805518 },
    { lat: 49.3303142, lng: 20.2801493 },
    { lat: 49.3293773, lng: 20.2800887 },
    { lat: 49.3280647, lng: 20.2798934 },
    { lat: 49.3277514, lng: 20.2804645 },
    { lat: 49.3267614, lng: 20.2806885 },
    { lat: 49.3262491, lng: 20.2802868 },
    { lat: 49.3250005, lng: 20.27959 },
    { lat: 49.3242772, lng: 20.2802475 },
    { lat: 49.3209176, lng: 20.2801666 },
    { lat: 49.3201747, lng: 20.2800548 },
    { lat: 49.3181964, lng: 20.2802016 },
    { lat: 49.3161562, lng: 20.2795633 },
    { lat: 49.3145301, lng: 20.2792973 },
    { lat: 49.3120081, lng: 20.2804315 },
    { lat: 49.3096727, lng: 20.2815523 },
    { lat: 49.3100801, lng: 20.2828248 },
    { lat: 49.310994, lng: 20.2841133 },
    { lat: 49.3126244, lng: 20.286174 },
    { lat: 49.3142501, lng: 20.285594 },
    { lat: 49.3154555, lng: 20.286243 },
    { lat: 49.3164675, lng: 20.2870212 },
    { lat: 49.3190735, lng: 20.2898248 },
    { lat: 49.3195177, lng: 20.2900429 },
    { lat: 49.3201297, lng: 20.2905205 },
    { lat: 49.3212654, lng: 20.2909454 },
    { lat: 49.3219213, lng: 20.2911078 },
    { lat: 49.3225339, lng: 20.2947674 },
    { lat: 49.3224138, lng: 20.2978864 },
    { lat: 49.3224791, lng: 20.3001804 },
    { lat: 49.3232672, lng: 20.3007367 },
    { lat: 49.3230616, lng: 20.3024901 },
    { lat: 49.3232075, lng: 20.3044317 },
    { lat: 49.3232049, lng: 20.3051235 },
    { lat: 49.3235816, lng: 20.3051689 },
    { lat: 49.3235643, lng: 20.3055014 },
    { lat: 49.3231808, lng: 20.306476 },
    { lat: 49.322526, lng: 20.3077733 },
    { lat: 49.3220252, lng: 20.3086495 },
    { lat: 49.3219627, lng: 20.3089568 },
    { lat: 49.3211046, lng: 20.3099988 },
    { lat: 49.3208498, lng: 20.3105196 },
    { lat: 49.3203702, lng: 20.3110159 },
    { lat: 49.3193033, lng: 20.3096297 },
    { lat: 49.3168932, lng: 20.3129956 },
    { lat: 49.3159404, lng: 20.3138894 },
    { lat: 49.3138448, lng: 20.3152226 },
    { lat: 49.3126353, lng: 20.3159157 },
    { lat: 49.3116186, lng: 20.3164163 },
    { lat: 49.3097855, lng: 20.3170256 },
    { lat: 49.3087541, lng: 20.3170818 },
    { lat: 49.3069858, lng: 20.3179414 },
    { lat: 49.3061836, lng: 20.3179824 },
    { lat: 49.3046415, lng: 20.3191628 },
    { lat: 49.3014666, lng: 20.3206259 },
    { lat: 49.2991599, lng: 20.3216005 },
    { lat: 49.3016274, lng: 20.3256206 },
    { lat: 49.3022001, lng: 20.3266647 },
    { lat: 49.3027035, lng: 20.3273677 },
  ],
  Výborná: [
    { lat: 49.2701205, lng: 20.3629639 },
    { lat: 49.2675854, lng: 20.3628805 },
    { lat: 49.2664908, lng: 20.3626436 },
    { lat: 49.2662233, lng: 20.3624154 },
    { lat: 49.2659606, lng: 20.3620549 },
    { lat: 49.265708, lng: 20.3619592 },
    { lat: 49.2655427, lng: 20.361992 },
    { lat: 49.2649742, lng: 20.3619025 },
    { lat: 49.2642277, lng: 20.3623455 },
    { lat: 49.263331, lng: 20.3627424 },
    { lat: 49.2629696, lng: 20.3626258 },
    { lat: 49.2627905, lng: 20.3626811 },
    { lat: 49.2622809, lng: 20.3631941 },
    { lat: 49.2617018, lng: 20.3639826 },
    { lat: 49.2614345, lng: 20.3644397 },
    { lat: 49.2609811, lng: 20.3655097 },
    { lat: 49.2607553, lng: 20.3662605 },
    { lat: 49.2605899, lng: 20.3665031 },
    { lat: 49.2599462, lng: 20.3680132 },
    { lat: 49.259396, lng: 20.3688167 },
    { lat: 49.2589815, lng: 20.3692887 },
    { lat: 49.258477, lng: 20.3696382 },
    { lat: 49.2581518, lng: 20.3704692 },
    { lat: 49.2569967, lng: 20.3725052 },
    { lat: 49.2564581, lng: 20.373026 },
    { lat: 49.2562771, lng: 20.3734448 },
    { lat: 49.2561223, lng: 20.3739953 },
    { lat: 49.2553991, lng: 20.3751636 },
    { lat: 49.2550482, lng: 20.375941 },
    { lat: 49.2545291, lng: 20.3766759 },
    { lat: 49.2542919, lng: 20.3768037 },
    { lat: 49.2539065, lng: 20.3772096 },
    { lat: 49.2532773, lng: 20.3770359 },
    { lat: 49.2523226, lng: 20.3765687 },
    { lat: 49.2521514, lng: 20.3763914 },
    { lat: 49.2517809, lng: 20.3762388 },
    { lat: 49.2507986, lng: 20.3765208 },
    { lat: 49.2499676, lng: 20.3769601 },
    { lat: 49.2487675, lng: 20.377464 },
    { lat: 49.2481913, lng: 20.3775289 },
    { lat: 49.2468017, lng: 20.3774074 },
    { lat: 49.2455777, lng: 20.3766942 },
    { lat: 49.2443686, lng: 20.3776735 },
    { lat: 49.2439365, lng: 20.3779421 },
    { lat: 49.2436639, lng: 20.3779721 },
    { lat: 49.2435167, lng: 20.3779485 },
    { lat: 49.2430961, lng: 20.3776075 },
    { lat: 49.2427677, lng: 20.3770266 },
    { lat: 49.2425679, lng: 20.3768617 },
    { lat: 49.2422913, lng: 20.3763371 },
    { lat: 49.2415921, lng: 20.3765449 },
    { lat: 49.2412378, lng: 20.3769998 },
    { lat: 49.2403889, lng: 20.3768497 },
    { lat: 49.2398823, lng: 20.3766746 },
    { lat: 49.2380521, lng: 20.3766052 },
    { lat: 49.2377638, lng: 20.3764504 },
    { lat: 49.2369796, lng: 20.376586 },
    { lat: 49.235815, lng: 20.3770445 },
    { lat: 49.2351462, lng: 20.3772246 },
    { lat: 49.2341467, lng: 20.3778528 },
    { lat: 49.2327244, lng: 20.3784627 },
    { lat: 49.2292094, lng: 20.378672 },
    { lat: 49.2282552, lng: 20.3788616 },
    { lat: 49.227065, lng: 20.378801 },
    { lat: 49.2249327, lng: 20.3780348 },
    { lat: 49.2243342, lng: 20.3777522 },
    { lat: 49.2242343, lng: 20.3776422 },
    { lat: 49.2236606, lng: 20.3783956 },
    { lat: 49.2226423, lng: 20.3791503 },
    { lat: 49.222023, lng: 20.380363 },
    { lat: 49.2220293, lng: 20.3812028 },
    { lat: 49.2219777, lng: 20.3817451 },
    { lat: 49.2217612, lng: 20.3822056 },
    { lat: 49.2219361, lng: 20.3824245 },
    { lat: 49.2221288, lng: 20.3824899 },
    { lat: 49.2223617, lng: 20.3822495 },
    { lat: 49.2225539, lng: 20.3819439 },
    { lat: 49.2226702, lng: 20.3820431 },
    { lat: 49.2226654, lng: 20.3822085 },
    { lat: 49.2227445, lng: 20.3822155 },
    { lat: 49.2230501, lng: 20.3825594 },
    { lat: 49.2232156, lng: 20.3826413 },
    { lat: 49.2232034, lng: 20.3829172 },
    { lat: 49.2232863, lng: 20.3829923 },
    { lat: 49.2232401, lng: 20.3832853 },
    { lat: 49.2233615, lng: 20.383934 },
    { lat: 49.2233084, lng: 20.3843101 },
    { lat: 49.2230418, lng: 20.3847325 },
    { lat: 49.2229257, lng: 20.3847565 },
    { lat: 49.2222675, lng: 20.3853653 },
    { lat: 49.2220886, lng: 20.3853948 },
    { lat: 49.2219676, lng: 20.3855018 },
    { lat: 49.2214629, lng: 20.3856978 },
    { lat: 49.2214094, lng: 20.3863624 },
    { lat: 49.221242, lng: 20.3868174 },
    { lat: 49.2210584, lng: 20.3870538 },
    { lat: 49.2207639, lng: 20.3873 },
    { lat: 49.2206107, lng: 20.3872721 },
    { lat: 49.2204179, lng: 20.3876742 },
    { lat: 49.2204387, lng: 20.388222 },
    { lat: 49.2203393, lng: 20.3884236 },
    { lat: 49.2195951, lng: 20.389136 },
    { lat: 49.219421, lng: 20.3894403 },
    { lat: 49.2193575, lng: 20.3896934 },
    { lat: 49.2193459, lng: 20.3899833 },
    { lat: 49.21961, lng: 20.3910044 },
    { lat: 49.2196164, lng: 20.3914713 },
    { lat: 49.2195597, lng: 20.3919024 },
    { lat: 49.2194498, lng: 20.3924211 },
    { lat: 49.2192138, lng: 20.3931706 },
    { lat: 49.2186636, lng: 20.3940996 },
    { lat: 49.2185929, lng: 20.3943126 },
    { lat: 49.2185523, lng: 20.3947286 },
    { lat: 49.2185579, lng: 20.3953187 },
    { lat: 49.2190546, lng: 20.3974333 },
    { lat: 49.2191053, lng: 20.3981715 },
    { lat: 49.219051, lng: 20.3985199 },
    { lat: 49.2188758, lng: 20.399099 },
    { lat: 49.218527, lng: 20.3996942 },
    { lat: 49.2181014, lng: 20.4007969 },
    { lat: 49.2178957, lng: 20.4011313 },
    { lat: 49.2171276, lng: 20.4019551 },
    { lat: 49.2169319, lng: 20.4022886 },
    { lat: 49.2167388, lng: 20.4031308 },
    { lat: 49.2165738, lng: 20.4044239 },
    { lat: 49.2163472, lng: 20.4051314 },
    { lat: 49.2160301, lng: 20.4057919 },
    { lat: 49.2154747, lng: 20.4076283 },
    { lat: 49.2154312, lng: 20.407962 },
    { lat: 49.2154072, lng: 20.4082256 },
    { lat: 49.2210906, lng: 20.4089328 },
    { lat: 49.2221039, lng: 20.409178 },
    { lat: 49.2233448, lng: 20.4097165 },
    { lat: 49.2240507, lng: 20.4098602 },
    { lat: 49.2252934, lng: 20.4098875 },
    { lat: 49.2277426, lng: 20.4098094 },
    { lat: 49.2291029, lng: 20.4096072 },
    { lat: 49.229873, lng: 20.4092781 },
    { lat: 49.2304292, lng: 20.4091328 },
    { lat: 49.2309778, lng: 20.4087541 },
    { lat: 49.2312728, lng: 20.4086322 },
    { lat: 49.2333177, lng: 20.4079297 },
    { lat: 49.2342798, lng: 20.4075007 },
    { lat: 49.2347089, lng: 20.4073803 },
    { lat: 49.2367229, lng: 20.4064877 },
    { lat: 49.2368795, lng: 20.4062814 },
    { lat: 49.2388223, lng: 20.405849 },
    { lat: 49.2395394, lng: 20.405198 },
    { lat: 49.2408058, lng: 20.4047431 },
    { lat: 49.2429428, lng: 20.4033774 },
    { lat: 49.2449816, lng: 20.4022192 },
    { lat: 49.245647, lng: 20.4016238 },
    { lat: 49.2494977, lng: 20.3991328 },
    { lat: 49.2499311, lng: 20.3982414 },
    { lat: 49.2506967, lng: 20.3975264 },
    { lat: 49.251372, lng: 20.3970813 },
    { lat: 49.251659, lng: 20.3967806 },
    { lat: 49.2526353, lng: 20.3961023 },
    { lat: 49.2528596, lng: 20.3957523 },
    { lat: 49.2530911, lng: 20.3956493 },
    { lat: 49.2535, lng: 20.395599 },
    { lat: 49.2537887, lng: 20.395643 },
    { lat: 49.2539913, lng: 20.3957902 },
    { lat: 49.2547092, lng: 20.3952725 },
    { lat: 49.2555091, lng: 20.3945417 },
    { lat: 49.2559052, lng: 20.3943004 },
    { lat: 49.2559038, lng: 20.3939704 },
    { lat: 49.2560121, lng: 20.3940019 },
    { lat: 49.2560742, lng: 20.393859 },
    { lat: 49.2563298, lng: 20.3937679 },
    { lat: 49.2563969, lng: 20.3936106 },
    { lat: 49.2565365, lng: 20.3935845 },
    { lat: 49.2567583, lng: 20.3931932 },
    { lat: 49.2569526, lng: 20.3931209 },
    { lat: 49.2571573, lng: 20.3928832 },
    { lat: 49.2572511, lng: 20.3926821 },
    { lat: 49.2575424, lng: 20.3926426 },
    { lat: 49.2576916, lng: 20.3922714 },
    { lat: 49.2578173, lng: 20.3921761 },
    { lat: 49.2579376, lng: 20.3920849 },
    { lat: 49.258015, lng: 20.3918992 },
    { lat: 49.2582227, lng: 20.3918808 },
    { lat: 49.2583083, lng: 20.3917633 },
    { lat: 49.2583548, lng: 20.3918003 },
    { lat: 49.2586065, lng: 20.3916132 },
    { lat: 49.2586167, lng: 20.3914335 },
    { lat: 49.2587892, lng: 20.3910881 },
    { lat: 49.2589363, lng: 20.3911024 },
    { lat: 49.2589835, lng: 20.3910157 },
    { lat: 49.2591993, lng: 20.390914 },
    { lat: 49.2593257, lng: 20.3906829 },
    { lat: 49.2595073, lng: 20.3901301 },
    { lat: 49.2595487, lng: 20.3901144 },
    { lat: 49.2596959, lng: 20.3900586 },
    { lat: 49.2598288, lng: 20.3898543 },
    { lat: 49.2602134, lng: 20.3896003 },
    { lat: 49.2610915, lng: 20.388862 },
    { lat: 49.2615375, lng: 20.3883001 },
    { lat: 49.2617415, lng: 20.3881854 },
    { lat: 49.2620562, lng: 20.3877314 },
    { lat: 49.2623092, lng: 20.3875713 },
    { lat: 49.2623536, lng: 20.3874048 },
    { lat: 49.2627397, lng: 20.3872598 },
    { lat: 49.2628063, lng: 20.3871429 },
    { lat: 49.2630204, lng: 20.3869992 },
    { lat: 49.2631907, lng: 20.3867119 },
    { lat: 49.2633154, lng: 20.3866164 },
    { lat: 49.2634793, lng: 20.3866019 },
    { lat: 49.2637901, lng: 20.3862499 },
    { lat: 49.2641494, lng: 20.3860453 },
    { lat: 49.2642895, lng: 20.3858505 },
    { lat: 49.2646532, lng: 20.385736 },
    { lat: 49.2647315, lng: 20.3856295 },
    { lat: 49.2649045, lng: 20.3855331 },
    { lat: 49.2650233, lng: 20.3855481 },
    { lat: 49.2652971, lng: 20.3853464 },
    { lat: 49.2654075, lng: 20.3853774 },
    { lat: 49.2655224, lng: 20.3852925 },
    { lat: 49.2682366, lng: 20.3845129 },
    { lat: 49.2683125, lng: 20.3838471 },
    { lat: 49.2687823, lng: 20.3831417 },
    { lat: 49.2695198, lng: 20.3822538 },
    { lat: 49.2699456, lng: 20.3816383 },
    { lat: 49.2700195, lng: 20.3814555 },
    { lat: 49.2701116, lng: 20.3807925 },
    { lat: 49.2704375, lng: 20.3800389 },
    { lat: 49.2704451, lng: 20.3774322 },
    { lat: 49.2703294, lng: 20.3764636 },
    { lat: 49.2699923, lng: 20.3748564 },
    { lat: 49.2697543, lng: 20.3740775 },
    { lat: 49.2692652, lng: 20.3727943 },
    { lat: 49.2692922, lng: 20.3722597 },
    { lat: 49.2699362, lng: 20.3693988 },
    { lat: 49.2699306, lng: 20.3684871 },
    { lat: 49.2700124, lng: 20.3678225 },
    { lat: 49.2699472, lng: 20.367179 },
    { lat: 49.2699645, lng: 20.3664499 },
    { lat: 49.2697889, lng: 20.3655422 },
    { lat: 49.2696162, lng: 20.3651451 },
    { lat: 49.2695158, lng: 20.364579 },
    { lat: 49.2695263, lng: 20.3642633 },
    { lat: 49.2696231, lng: 20.3638495 },
    { lat: 49.2701205, lng: 20.3629639 },
  ],
  StaráLesná: [
    { lat: 49.136346, lng: 20.276448 },
    { lat: 49.13633, lng: 20.276579 },
    { lat: 49.136321, lng: 20.276914 },
    { lat: 49.135974, lng: 20.277619 },
    { lat: 49.135765, lng: 20.278004 },
    { lat: 49.135654, lng: 20.278643 },
    { lat: 49.134921, lng: 20.279753 },
    { lat: 49.134767, lng: 20.280016 },
    { lat: 49.134333, lng: 20.280598 },
    { lat: 49.133878, lng: 20.281026 },
    { lat: 49.133645, lng: 20.281395 },
    { lat: 49.133238, lng: 20.282339 },
    { lat: 49.133052, lng: 20.283117 },
    { lat: 49.132998, lng: 20.283606 },
    { lat: 49.132947, lng: 20.283767 },
    { lat: 49.132963, lng: 20.284163 },
    { lat: 49.132938, lng: 20.285198 },
    { lat: 49.132935, lng: 20.285317 },
    { lat: 49.132782, lng: 20.285939 },
    { lat: 49.132524, lng: 20.286558 },
    { lat: 49.132348, lng: 20.286927 },
    { lat: 49.131862, lng: 20.287507 },
    { lat: 49.131785, lng: 20.287597 },
    { lat: 49.131533, lng: 20.288193 },
    { lat: 49.131612, lng: 20.288479 },
    { lat: 49.131362, lng: 20.288543 },
    { lat: 49.131406, lng: 20.28931 },
    { lat: 49.131444, lng: 20.289876 },
    { lat: 49.131435, lng: 20.289949 },
    { lat: 49.131375, lng: 20.289903 },
    { lat: 49.131277, lng: 20.289837 },
    { lat: 49.131159, lng: 20.289662 },
    { lat: 49.130896, lng: 20.289869 },
    { lat: 49.130871, lng: 20.290105 },
    { lat: 49.130825, lng: 20.290221 },
    { lat: 49.130839, lng: 20.290366 },
    { lat: 49.130815, lng: 20.290412 },
    { lat: 49.130809, lng: 20.290482 },
    { lat: 49.130788, lng: 20.290565 },
    { lat: 49.130787, lng: 20.290654 },
    { lat: 49.130735, lng: 20.290765 },
    { lat: 49.13071, lng: 20.290767 },
    { lat: 49.130682, lng: 20.290758 },
    { lat: 49.130635, lng: 20.290824 },
    { lat: 49.130559, lng: 20.290865 },
    { lat: 49.1305, lng: 20.290848 },
    { lat: 49.130475, lng: 20.290738 },
    { lat: 49.130353, lng: 20.290784 },
    { lat: 49.130352, lng: 20.290946 },
    { lat: 49.130303, lng: 20.291061 },
    { lat: 49.130294, lng: 20.291203 },
    { lat: 49.13024, lng: 20.291289 },
    { lat: 49.130199, lng: 20.291343 },
    { lat: 49.130069, lng: 20.291432 },
    { lat: 49.130008, lng: 20.291431 },
    { lat: 49.129975, lng: 20.291406 },
    { lat: 49.129944, lng: 20.291404 },
    { lat: 49.129914, lng: 20.291428 },
    { lat: 49.12993, lng: 20.291554 },
    { lat: 49.129896, lng: 20.2916 },
    { lat: 49.129867, lng: 20.291647 },
    { lat: 49.129753, lng: 20.291669 },
    { lat: 49.129726, lng: 20.291732 },
    { lat: 49.129636, lng: 20.291825 },
    { lat: 49.129592, lng: 20.291819 },
    { lat: 49.129574, lng: 20.291875 },
    { lat: 49.129489, lng: 20.2919 },
    { lat: 49.129454, lng: 20.291944 },
    { lat: 49.129365, lng: 20.291943 },
    { lat: 49.129317, lng: 20.292015 },
    { lat: 49.129288, lng: 20.292014 },
    { lat: 49.129253, lng: 20.292057 },
    { lat: 49.12922, lng: 20.292069 },
    { lat: 49.129202, lng: 20.292102 },
    { lat: 49.129166, lng: 20.292121 },
    { lat: 49.129131, lng: 20.29212 },
    { lat: 49.129107, lng: 20.292109 },
    { lat: 49.129028, lng: 20.292132 },
    { lat: 49.128973, lng: 20.292183 },
    { lat: 49.128968, lng: 20.292216 },
    { lat: 49.128953, lng: 20.292229 },
    { lat: 49.128914, lng: 20.292246 },
    { lat: 49.128891, lng: 20.29222 },
    { lat: 49.128839, lng: 20.292284 },
    { lat: 49.12867, lng: 20.292342 },
    { lat: 49.128478, lng: 20.29213 },
    { lat: 49.128243, lng: 20.292197 },
    { lat: 49.128209, lng: 20.292255 },
    { lat: 49.128087, lng: 20.292312 },
    { lat: 49.128096, lng: 20.292354 },
    { lat: 49.128074, lng: 20.292464 },
    { lat: 49.128026, lng: 20.292524 },
    { lat: 49.12801, lng: 20.292671 },
    { lat: 49.127978, lng: 20.292732 },
    { lat: 49.127949, lng: 20.292746 },
    { lat: 49.127929, lng: 20.292811 },
    { lat: 49.127845, lng: 20.292883 },
    { lat: 49.127826, lng: 20.292964 },
    { lat: 49.127717, lng: 20.293015 },
    { lat: 49.127716, lng: 20.293132 },
    { lat: 49.127693, lng: 20.293203 },
    { lat: 49.127667, lng: 20.293273 },
    { lat: 49.127621, lng: 20.29343 },
    { lat: 49.127507, lng: 20.293479 },
    { lat: 49.12747, lng: 20.293486 },
    { lat: 49.127449, lng: 20.293527 },
    { lat: 49.127363, lng: 20.293509 },
    { lat: 49.127339, lng: 20.293588 },
    { lat: 49.127308, lng: 20.293632 },
    { lat: 49.127308, lng: 20.293662 },
    { lat: 49.127251, lng: 20.293702 },
    { lat: 49.127183, lng: 20.293687 },
    { lat: 49.12712, lng: 20.293758 },
    { lat: 49.127071, lng: 20.293757 },
    { lat: 49.127019, lng: 20.293809 },
    { lat: 49.12697, lng: 20.293808 },
    { lat: 49.126937, lng: 20.293785 },
    { lat: 49.126875, lng: 20.293784 },
    { lat: 49.126841, lng: 20.293758 },
    { lat: 49.126802, lng: 20.293799 },
    { lat: 49.126742, lng: 20.293784 },
    { lat: 49.12669, lng: 20.293758 },
    { lat: 49.126643, lng: 20.293714 },
    { lat: 49.1266, lng: 20.293664 },
    { lat: 49.126522, lng: 20.293609 },
    { lat: 49.126483, lng: 20.293651 },
    { lat: 49.126349, lng: 20.293723 },
    { lat: 49.126276, lng: 20.293709 },
    { lat: 49.126232, lng: 20.293773 },
    { lat: 49.126232, lng: 20.293857 },
    { lat: 49.12615, lng: 20.2939 },
    { lat: 49.126128, lng: 20.293926 },
    { lat: 49.126011, lng: 20.293985 },
    { lat: 49.125924, lng: 20.293928 },
    { lat: 49.125901, lng: 20.293961 },
    { lat: 49.125844, lng: 20.293917 },
    { lat: 49.125794, lng: 20.293946 },
    { lat: 49.125744, lng: 20.293944 },
    { lat: 49.125733, lng: 20.294021 },
    { lat: 49.125681, lng: 20.29402 },
    { lat: 49.125627, lng: 20.293996 },
    { lat: 49.125604, lng: 20.294112 },
    { lat: 49.125539, lng: 20.294159 },
    { lat: 49.125487, lng: 20.294224 },
    { lat: 49.125462, lng: 20.294268 },
    { lat: 49.125338, lng: 20.294485 },
    { lat: 49.125299, lng: 20.294556 },
    { lat: 49.125195, lng: 20.294589 },
    { lat: 49.125143, lng: 20.294636 },
    { lat: 49.125129, lng: 20.294694 },
    { lat: 49.125085, lng: 20.294746 },
    { lat: 49.125095, lng: 20.294891 },
    { lat: 49.125075, lng: 20.294923 },
    { lat: 49.12506, lng: 20.294983 },
    { lat: 49.125019, lng: 20.294982 },
    { lat: 49.124993, lng: 20.295027 },
    { lat: 49.125, lng: 20.295093 },
    { lat: 49.125, lng: 20.295138 },
    { lat: 49.125005, lng: 20.29522 },
    { lat: 49.125072, lng: 20.295203 },
    { lat: 49.125099, lng: 20.295262 },
    { lat: 49.125074, lng: 20.295262 },
    { lat: 49.125032, lng: 20.295394 },
    { lat: 49.125031, lng: 20.295567 },
    { lat: 49.124981, lng: 20.295581 },
    { lat: 49.124969, lng: 20.29563 },
    { lat: 49.125015, lng: 20.295698 },
    { lat: 49.124926, lng: 20.295826 },
    { lat: 49.124926, lng: 20.29587 },
    { lat: 49.124886, lng: 20.295868 },
    { lat: 49.12484, lng: 20.295953 },
    { lat: 49.124849, lng: 20.295982 },
    { lat: 49.124837, lng: 20.296012 },
    { lat: 49.124768, lng: 20.295995 },
    { lat: 49.124787, lng: 20.295946 },
    { lat: 49.124776, lng: 20.295911 },
    { lat: 49.124733, lng: 20.29593 },
    { lat: 49.124665, lng: 20.295939 },
    { lat: 49.124612, lng: 20.296097 },
    { lat: 49.124552, lng: 20.296067 },
    { lat: 49.124521, lng: 20.29601 },
    { lat: 49.124452, lng: 20.295974 },
    { lat: 49.124395, lng: 20.295987 },
    { lat: 49.124207, lng: 20.296027 },
    { lat: 49.124143, lng: 20.29612 },
    { lat: 49.124128, lng: 20.296319 },
    { lat: 49.124061, lng: 20.296393 },
    { lat: 49.124035, lng: 20.296427 },
    { lat: 49.124033, lng: 20.296622 },
    { lat: 49.123942, lng: 20.296631 },
    { lat: 49.123941, lng: 20.296731 },
    { lat: 49.123907, lng: 20.296778 },
    { lat: 49.123884, lng: 20.29684 },
    { lat: 49.123842, lng: 20.2969 },
    { lat: 49.123842, lng: 20.297006 },
    { lat: 49.123801, lng: 20.297104 },
    { lat: 49.123759, lng: 20.297104 },
    { lat: 49.123736, lng: 20.29718 },
    { lat: 49.123662, lng: 20.297299 },
    { lat: 49.123625, lng: 20.297251 },
    { lat: 49.123612, lng: 20.297214 },
    { lat: 49.123569, lng: 20.297241 },
    { lat: 49.123406, lng: 20.29735 },
    { lat: 49.123394, lng: 20.297454 },
    { lat: 49.123301, lng: 20.297449 },
    { lat: 49.123317, lng: 20.297483 },
    { lat: 49.123339, lng: 20.297542 },
    { lat: 49.123482, lng: 20.298311 },
    { lat: 49.123595, lng: 20.299024 },
    { lat: 49.123601, lng: 20.299066 },
    { lat: 49.12336, lng: 20.29929 },
    { lat: 49.122542, lng: 20.300308 },
    { lat: 49.122132, lng: 20.300873 },
    { lat: 49.121644, lng: 20.301476 },
    { lat: 49.121467, lng: 20.301767 },
    { lat: 49.121187, lng: 20.302229 },
    { lat: 49.1206, lng: 20.303036 },
    { lat: 49.120443, lng: 20.303206 },
    { lat: 49.120219, lng: 20.303534 },
    { lat: 49.119959, lng: 20.303913 },
    { lat: 49.119696, lng: 20.304266 },
    { lat: 49.119545, lng: 20.304462 },
    { lat: 49.119337, lng: 20.304766 },
    { lat: 49.119183, lng: 20.305003 },
    { lat: 49.119097, lng: 20.305146 },
    { lat: 49.119019, lng: 20.305315 },
    { lat: 49.118964, lng: 20.305401 },
    { lat: 49.118828, lng: 20.305626 },
    { lat: 49.118739, lng: 20.305785 },
    { lat: 49.118611, lng: 20.306076 },
    { lat: 49.118435, lng: 20.306391 },
    { lat: 49.118355, lng: 20.306539 },
    { lat: 49.1182, lng: 20.306769 },
    { lat: 49.118133, lng: 20.306922 },
    { lat: 49.118109, lng: 20.306977 },
    { lat: 49.117996, lng: 20.307125 },
    { lat: 49.117976, lng: 20.30722 },
    { lat: 49.117903, lng: 20.307336 },
    { lat: 49.117789, lng: 20.307506 },
    { lat: 49.117647, lng: 20.307695 },
    { lat: 49.117489, lng: 20.307867 },
    { lat: 49.117402, lng: 20.30796 },
    { lat: 49.117335, lng: 20.308053 },
    { lat: 49.117212, lng: 20.308241 },
    { lat: 49.117145, lng: 20.308355 },
    { lat: 49.117067, lng: 20.308473 },
    { lat: 49.117021, lng: 20.308544 },
    { lat: 49.116968, lng: 20.308646 },
    { lat: 49.116909, lng: 20.308752 },
    { lat: 49.116828, lng: 20.308895 },
    { lat: 49.116745, lng: 20.309031 },
    { lat: 49.116627, lng: 20.309225 },
    { lat: 49.116533, lng: 20.309381 },
    { lat: 49.116415, lng: 20.309568 },
    { lat: 49.116236, lng: 20.309889 },
    { lat: 49.116146, lng: 20.310077 },
    { lat: 49.116072, lng: 20.310202 },
    { lat: 49.115927, lng: 20.310454 },
    { lat: 49.115769, lng: 20.31073 },
    { lat: 49.11567, lng: 20.31088 },
    { lat: 49.115602, lng: 20.310953 },
    { lat: 49.115556, lng: 20.311037 },
    { lat: 49.115492, lng: 20.31112 },
    { lat: 49.115423, lng: 20.311218 },
    { lat: 49.115229, lng: 20.311495 },
    { lat: 49.11508, lng: 20.311671 },
    { lat: 49.115013, lng: 20.311779 },
    { lat: 49.114936, lng: 20.311902 },
    { lat: 49.114837, lng: 20.312009 },
    { lat: 49.114766, lng: 20.312082 },
    { lat: 49.114659, lng: 20.312199 },
    { lat: 49.114451, lng: 20.312445 },
    { lat: 49.114346, lng: 20.312587 },
    { lat: 49.11429, lng: 20.312648 },
    { lat: 49.114149, lng: 20.312801 },
    { lat: 49.114052, lng: 20.31292 },
    { lat: 49.115402, lng: 20.3150057 },
    { lat: 49.1159827, lng: 20.3161332 },
    { lat: 49.1163634, lng: 20.3170066 },
    { lat: 49.1171182, lng: 20.3184161 },
    { lat: 49.117679, lng: 20.3193147 },
    { lat: 49.1183426, lng: 20.3205789 },
    { lat: 49.1189115, lng: 20.3214887 },
    { lat: 49.1194161, lng: 20.3221875 },
    { lat: 49.1199833, lng: 20.3227908 },
    { lat: 49.1208232, lng: 20.3240156 },
    { lat: 49.1217843, lng: 20.3260378 },
    { lat: 49.1223464, lng: 20.3265093 },
    { lat: 49.1223517, lng: 20.3264143 },
    { lat: 49.1226862, lng: 20.3258077 },
    { lat: 49.1227564, lng: 20.3255864 },
    { lat: 49.1230589, lng: 20.3251084 },
    { lat: 49.123529, lng: 20.3251475 },
    { lat: 49.1240876, lng: 20.3246314 },
    { lat: 49.1242104, lng: 20.3246294 },
    { lat: 49.1248414, lng: 20.3254852 },
    { lat: 49.1258492, lng: 20.3263349 },
    { lat: 49.1268927, lng: 20.3278117 },
    { lat: 49.1271152, lng: 20.3272722 },
    { lat: 49.1273399, lng: 20.327 },
    { lat: 49.1282802, lng: 20.3266219 },
    { lat: 49.1284108, lng: 20.3276457 },
    { lat: 49.1291401, lng: 20.3276094 },
    { lat: 49.1292626, lng: 20.3278594 },
    { lat: 49.1297625, lng: 20.3279792 },
    { lat: 49.1300875, lng: 20.3277318 },
    { lat: 49.1300082, lng: 20.3275178 },
    { lat: 49.1300925, lng: 20.3274272 },
    { lat: 49.1303929, lng: 20.3273934 },
    { lat: 49.1305493, lng: 20.3273003 },
    { lat: 49.1307876, lng: 20.3274098 },
    { lat: 49.1310126, lng: 20.3273695 },
    { lat: 49.1310124, lng: 20.3275119 },
    { lat: 49.1314883, lng: 20.3280563 },
    { lat: 49.131573, lng: 20.3281232 },
    { lat: 49.1317491, lng: 20.3280765 },
    { lat: 49.1322475, lng: 20.3283586 },
    { lat: 49.1327446, lng: 20.32799 },
    { lat: 49.1328144, lng: 20.328105 },
    { lat: 49.1330287, lng: 20.3280904 },
    { lat: 49.1333559, lng: 20.3276425 },
    { lat: 49.1333948, lng: 20.3274812 },
    { lat: 49.1335516, lng: 20.3273693 },
    { lat: 49.1335609, lng: 20.3272322 },
    { lat: 49.1338964, lng: 20.3269137 },
    { lat: 49.1339023, lng: 20.3266881 },
    { lat: 49.1339606, lng: 20.3265354 },
    { lat: 49.1344265, lng: 20.325677 },
    { lat: 49.1344483, lng: 20.325546 },
    { lat: 49.134558, lng: 20.3254958 },
    { lat: 49.13473, lng: 20.3255832 },
    { lat: 49.1347729, lng: 20.3255269 },
    { lat: 49.1347603, lng: 20.3253472 },
    { lat: 49.1348268, lng: 20.3251452 },
    { lat: 49.1349716, lng: 20.3248668 },
    { lat: 49.1355256, lng: 20.3243184 },
    { lat: 49.1356375, lng: 20.3240336 },
    { lat: 49.1358643, lng: 20.3239614 },
    { lat: 49.136213, lng: 20.3236004 },
    { lat: 49.1362718, lng: 20.3236382 },
    { lat: 49.1364427, lng: 20.3235424 },
    { lat: 49.1383801, lng: 20.3205882 },
    { lat: 49.1387322, lng: 20.319536 },
    { lat: 49.1389036, lng: 20.3193368 },
    { lat: 49.140028, lng: 20.318455 },
    { lat: 49.1402726, lng: 20.3181658 },
    { lat: 49.140895, lng: 20.3170871 },
    { lat: 49.1410559, lng: 20.3169352 },
    { lat: 49.1410951, lng: 20.316959 },
    { lat: 49.1412018, lng: 20.3166067 },
    { lat: 49.1412897, lng: 20.3164895 },
    { lat: 49.1417306, lng: 20.3160729 },
    { lat: 49.14248, lng: 20.3155376 },
    { lat: 49.1429213, lng: 20.316087 },
    { lat: 49.145041, lng: 20.3178759 },
    { lat: 49.1458663, lng: 20.3187479 },
    { lat: 49.145838, lng: 20.318813 },
    { lat: 49.146245, lng: 20.318463 },
    { lat: 49.146346, lng: 20.318617 },
    { lat: 49.146453, lng: 20.3184 },
    { lat: 49.146785, lng: 20.31835 },
    { lat: 49.14708, lng: 20.317864 },
    { lat: 49.147394, lng: 20.317052 },
    { lat: 49.147476, lng: 20.317112 },
    { lat: 49.147584, lng: 20.316728 },
    { lat: 49.147611, lng: 20.316717 },
    { lat: 49.147629, lng: 20.316711 },
    { lat: 49.147605, lng: 20.316649 },
    { lat: 49.147608, lng: 20.316638 },
    { lat: 49.147877, lng: 20.316256 },
    { lat: 49.148061, lng: 20.315913 },
    { lat: 49.148171, lng: 20.315857 },
    { lat: 49.148208, lng: 20.315815 },
    { lat: 49.148162, lng: 20.315584 },
    { lat: 49.148323, lng: 20.315159 },
    { lat: 49.148416, lng: 20.315048 },
    { lat: 49.148623, lng: 20.314916 },
    { lat: 49.148933, lng: 20.314578 },
    { lat: 49.149029, lng: 20.314447 },
    { lat: 49.149091, lng: 20.314316 },
    { lat: 49.149153, lng: 20.314173 },
    { lat: 49.149242, lng: 20.313716 },
    { lat: 49.149259, lng: 20.31337 },
    { lat: 49.149346, lng: 20.313154 },
    { lat: 49.14936, lng: 20.312925 },
    { lat: 49.14967, lng: 20.31291 },
    { lat: 49.149713, lng: 20.312829 },
    { lat: 49.149727, lng: 20.312521 },
    { lat: 49.14975, lng: 20.312321 },
    { lat: 49.149736, lng: 20.312187 },
    { lat: 49.149735, lng: 20.312076 },
    { lat: 49.149789, lng: 20.311788 },
    { lat: 49.149771, lng: 20.311477 },
    { lat: 49.149732, lng: 20.311309 },
    { lat: 49.149627, lng: 20.311136 },
    { lat: 49.149634, lng: 20.311035 },
    { lat: 49.149702, lng: 20.310936 },
    { lat: 49.149757, lng: 20.310869 },
    { lat: 49.149966, lng: 20.310717 },
    { lat: 49.150058, lng: 20.310749 },
    { lat: 49.150161, lng: 20.310712 },
    { lat: 49.150322, lng: 20.310468 },
    { lat: 49.150319, lng: 20.31034 },
    { lat: 49.150308, lng: 20.310322 },
    { lat: 49.150212, lng: 20.310271 },
    { lat: 49.150168, lng: 20.310183 },
    { lat: 49.15018, lng: 20.310081 },
    { lat: 49.150282, lng: 20.309917 },
    { lat: 49.150297, lng: 20.309741 },
    { lat: 49.150283, lng: 20.309722 },
    { lat: 49.150208, lng: 20.309691 },
    { lat: 49.150194, lng: 20.309528 },
    { lat: 49.150221, lng: 20.309352 },
    { lat: 49.150272, lng: 20.309157 },
    { lat: 49.150272, lng: 20.309065 },
    { lat: 49.15027, lng: 20.309036 },
    { lat: 49.150258, lng: 20.308968 },
    { lat: 49.150158, lng: 20.308623 },
    { lat: 49.150271, lng: 20.308433 },
    { lat: 49.150317, lng: 20.308322 },
    { lat: 49.150379, lng: 20.308139 },
    { lat: 49.150402, lng: 20.307937 },
    { lat: 49.150448, lng: 20.307858 },
    { lat: 49.150484, lng: 20.307815 },
    { lat: 49.150561, lng: 20.307746 },
    { lat: 49.150611, lng: 20.307653 },
    { lat: 49.15063, lng: 20.307566 },
    { lat: 49.150621, lng: 20.307231 },
    { lat: 49.150615, lng: 20.307216 },
    { lat: 49.15053, lng: 20.307173 },
    { lat: 49.150527, lng: 20.307008 },
    { lat: 49.150546, lng: 20.306919 },
    { lat: 49.150597, lng: 20.306871 },
    { lat: 49.15066, lng: 20.306732 },
    { lat: 49.150759, lng: 20.306675 },
    { lat: 49.150836, lng: 20.306581 },
    { lat: 49.150859, lng: 20.30642 },
    { lat: 49.150852, lng: 20.306357 },
    { lat: 49.15095, lng: 20.306246 },
    { lat: 49.150981, lng: 20.306069 },
    { lat: 49.150958, lng: 20.305866 },
    { lat: 49.150904, lng: 20.305702 },
    { lat: 49.150833, lng: 20.30558 },
    { lat: 49.150733, lng: 20.305152 },
    { lat: 49.150715, lng: 20.304918 },
    { lat: 49.150703, lng: 20.304758 },
    { lat: 49.1507, lng: 20.304719 },
    { lat: 49.150712, lng: 20.304675 },
    { lat: 49.150734, lng: 20.304601 },
    { lat: 49.150822, lng: 20.304508 },
    { lat: 49.150892, lng: 20.304393 },
    { lat: 49.15094, lng: 20.30425 },
    { lat: 49.150966, lng: 20.304082 },
    { lat: 49.150962, lng: 20.304061 },
    { lat: 49.150856, lng: 20.303924 },
    { lat: 49.150853, lng: 20.303745 },
    { lat: 49.150932, lng: 20.303587 },
    { lat: 49.151092, lng: 20.303429 },
    { lat: 49.151227, lng: 20.303352 },
    { lat: 49.151244, lng: 20.303363 },
    { lat: 49.151357, lng: 20.303514 },
    { lat: 49.151441, lng: 20.30346 },
    { lat: 49.151471, lng: 20.303345 },
    { lat: 49.151503, lng: 20.302657 },
    { lat: 49.151507, lng: 20.302405 },
    { lat: 49.151609, lng: 20.302234 },
    { lat: 49.151728, lng: 20.30219 },
    { lat: 49.151828, lng: 20.302034 },
    { lat: 49.151861, lng: 20.302022 },
    { lat: 49.15188, lng: 20.301964 },
    { lat: 49.151899, lng: 20.301942 },
    { lat: 49.151953, lng: 20.30183 },
    { lat: 49.151965, lng: 20.30182 },
    { lat: 49.151979, lng: 20.301872 },
    { lat: 49.151994, lng: 20.301879 },
    { lat: 49.152081, lng: 20.301715 },
    { lat: 49.15208, lng: 20.301596 },
    { lat: 49.152038, lng: 20.301466 },
    { lat: 49.15204, lng: 20.301443 },
    { lat: 49.152041, lng: 20.301358 },
    { lat: 49.152059, lng: 20.301297 },
    { lat: 49.152148, lng: 20.301236 },
    { lat: 49.15219, lng: 20.301188 },
    { lat: 49.152207, lng: 20.301184 },
    { lat: 49.152228, lng: 20.301237 },
    { lat: 49.152257, lng: 20.301261 },
    { lat: 49.152266, lng: 20.301329 },
    { lat: 49.15224, lng: 20.301369 },
    { lat: 49.152224, lng: 20.301386 },
    { lat: 49.152222, lng: 20.301404 },
    { lat: 49.152234, lng: 20.30144 },
    { lat: 49.152279, lng: 20.301452 },
    { lat: 49.152476, lng: 20.301172 },
    { lat: 49.152512, lng: 20.301032 },
    { lat: 49.152509, lng: 20.300904 },
    { lat: 49.152521, lng: 20.300822 },
    { lat: 49.152612, lng: 20.300641 },
    { lat: 49.152614, lng: 20.300462 },
    { lat: 49.152696, lng: 20.300311 },
    { lat: 49.152906, lng: 20.300234 },
    { lat: 49.15291, lng: 20.300056 },
    { lat: 49.152814, lng: 20.299853 },
    { lat: 49.152897, lng: 20.299688 },
    { lat: 49.152972, lng: 20.299668 },
    { lat: 49.153025, lng: 20.299661 },
    { lat: 49.153049, lng: 20.299657 },
    { lat: 49.153169, lng: 20.299429 },
    { lat: 49.153237, lng: 20.299311 },
    { lat: 49.153096, lng: 20.299102 },
    { lat: 49.153061, lng: 20.299026 },
    { lat: 49.153079, lng: 20.298937 },
    { lat: 49.153162, lng: 20.298777 },
    { lat: 49.153173, lng: 20.298581 },
    { lat: 49.153242, lng: 20.298407 },
    { lat: 49.153248, lng: 20.298224 },
    { lat: 49.153162, lng: 20.29822 },
    { lat: 49.15311, lng: 20.298087 },
    { lat: 49.153141, lng: 20.29792 },
    { lat: 49.153232, lng: 20.297863 },
    { lat: 49.153243, lng: 20.297851 },
    { lat: 49.153258, lng: 20.297688 },
    { lat: 49.153239, lng: 20.297511 },
    { lat: 49.153258, lng: 20.297401 },
    { lat: 49.153303, lng: 20.297396 },
    { lat: 49.153475, lng: 20.297454 },
    { lat: 49.153712, lng: 20.297443 },
    { lat: 49.153763, lng: 20.29726 },
    { lat: 49.153778, lng: 20.296856 },
    { lat: 49.153815, lng: 20.296437 },
    { lat: 49.154118, lng: 20.296286 },
    { lat: 49.154233, lng: 20.296179 },
    { lat: 49.15425, lng: 20.295972 },
    { lat: 49.154231, lng: 20.29569 },
    { lat: 49.154187, lng: 20.295478 },
    { lat: 49.154201, lng: 20.295238 },
    { lat: 49.154191, lng: 20.295027 },
    { lat: 49.154237, lng: 20.294939 },
    { lat: 49.154325, lng: 20.294785 },
    { lat: 49.154436, lng: 20.294558 },
    { lat: 49.154457, lng: 20.294491 },
    { lat: 49.154501, lng: 20.294328 },
    { lat: 49.154602, lng: 20.294292 },
    { lat: 49.154723, lng: 20.294355 },
    { lat: 49.15474, lng: 20.294241 },
    { lat: 49.154781, lng: 20.294217 },
    { lat: 49.154894, lng: 20.294198 },
    { lat: 49.154989, lng: 20.294108 },
    { lat: 49.155131, lng: 20.293914 },
    { lat: 49.155153, lng: 20.29378 },
    { lat: 49.155183, lng: 20.29373 },
    { lat: 49.155321, lng: 20.293609 },
    { lat: 49.155391, lng: 20.29358 },
    { lat: 49.155452, lng: 20.293619 },
    { lat: 49.155509, lng: 20.293631 },
    { lat: 49.155539, lng: 20.293627 },
    { lat: 49.155619, lng: 20.293607 },
    { lat: 49.155677, lng: 20.293539 },
    { lat: 49.155718, lng: 20.293382 },
    { lat: 49.155714, lng: 20.293238 },
    { lat: 49.155685, lng: 20.293152 },
    { lat: 49.1557, lng: 20.293082 },
    { lat: 49.155665, lng: 20.293062 },
    { lat: 49.155687, lng: 20.293024 },
    { lat: 49.155748, lng: 20.292954 },
    { lat: 49.155772, lng: 20.292804 },
    { lat: 49.155805, lng: 20.292764 },
    { lat: 49.155871, lng: 20.292718 },
    { lat: 49.155922, lng: 20.292631 },
    { lat: 49.15598, lng: 20.292564 },
    { lat: 49.156007, lng: 20.292524 },
    { lat: 49.156062, lng: 20.292472 },
    { lat: 49.156061, lng: 20.29237 },
    { lat: 49.156055, lng: 20.292181 },
    { lat: 49.156089, lng: 20.292109 },
    { lat: 49.156133, lng: 20.292042 },
    { lat: 49.156192, lng: 20.292028 },
    { lat: 49.156201, lng: 20.29197 },
    { lat: 49.156218, lng: 20.291925 },
    { lat: 49.156267, lng: 20.291904 },
    { lat: 49.156345, lng: 20.291866 },
    { lat: 49.156446, lng: 20.291884 },
    { lat: 49.156467, lng: 20.291871 },
    { lat: 49.156524, lng: 20.291755 },
    { lat: 49.156608, lng: 20.291668 },
    { lat: 49.156661, lng: 20.291521 },
    { lat: 49.15667, lng: 20.291478 },
    { lat: 49.156758, lng: 20.291404 },
    { lat: 49.15681, lng: 20.291319 },
    { lat: 49.156878, lng: 20.291231 },
    { lat: 49.156938, lng: 20.291121 },
    { lat: 49.156989, lng: 20.290916 },
    { lat: 49.15698, lng: 20.290861 },
    { lat: 49.156924, lng: 20.290731 },
    { lat: 49.156893, lng: 20.290601 },
    { lat: 49.156885, lng: 20.290458 },
    { lat: 49.156886, lng: 20.290401 },
    { lat: 49.156902, lng: 20.290348 },
    { lat: 49.156968, lng: 20.290265 },
    { lat: 49.157041, lng: 20.290087 },
    { lat: 49.1571, lng: 20.289921 },
    { lat: 49.157116, lng: 20.289844 },
    { lat: 49.157108, lng: 20.289772 },
    { lat: 49.157121, lng: 20.289577 },
    { lat: 49.157096, lng: 20.289465 },
    { lat: 49.157111, lng: 20.289386 },
    { lat: 49.157143, lng: 20.289293 },
    { lat: 49.157239, lng: 20.289057 },
    { lat: 49.157249, lng: 20.289036 },
    { lat: 49.157256, lng: 20.289013 },
    { lat: 49.157257, lng: 20.288971 },
    { lat: 49.157311, lng: 20.288844 },
    { lat: 49.157337, lng: 20.28876 },
    { lat: 49.157353, lng: 20.288709 },
    { lat: 49.157406, lng: 20.288604 },
    { lat: 49.157436, lng: 20.288442 },
    { lat: 49.157444, lng: 20.288365 },
    { lat: 49.157362, lng: 20.288261 },
    { lat: 49.157325, lng: 20.288196 },
    { lat: 49.157317, lng: 20.288118 },
    { lat: 49.157326, lng: 20.288055 },
    { lat: 49.157341, lng: 20.288012 },
    { lat: 49.157366, lng: 20.287992 },
    { lat: 49.157409, lng: 20.287932 },
    { lat: 49.157487, lng: 20.287843 },
    { lat: 49.157528, lng: 20.287736 },
    { lat: 49.157538, lng: 20.287654 },
    { lat: 49.15754, lng: 20.287615 },
    { lat: 49.15754, lng: 20.287612 },
    { lat: 49.157535, lng: 20.287584 },
    { lat: 49.157514, lng: 20.287596 },
    { lat: 49.157248, lng: 20.287922 },
    { lat: 49.156691, lng: 20.288666 },
    { lat: 49.156519, lng: 20.288896 },
    { lat: 49.156081, lng: 20.288421 },
    { lat: 49.155738, lng: 20.288093 },
    { lat: 49.155736, lng: 20.287868 },
    { lat: 49.153725, lng: 20.286032 },
    { lat: 49.152151, lng: 20.28495 },
    { lat: 49.150907, lng: 20.284172 },
    { lat: 49.150628, lng: 20.283891 },
    { lat: 49.149337, lng: 20.283022 },
    { lat: 49.149176, lng: 20.282865 },
    { lat: 49.149011, lng: 20.282744 },
    { lat: 49.14895, lng: 20.282717 },
    { lat: 49.148928, lng: 20.28263 },
    { lat: 49.148179, lng: 20.282466 },
    { lat: 49.148091, lng: 20.282369 },
    { lat: 49.146967, lng: 20.283315 },
    { lat: 49.146451, lng: 20.283879 },
    { lat: 49.146343, lng: 20.283975 },
    { lat: 49.14585, lng: 20.284475 },
    { lat: 49.145687, lng: 20.284644 },
    { lat: 49.145562, lng: 20.284669 },
    { lat: 49.144175, lng: 20.286013 },
    { lat: 49.143143, lng: 20.286623 },
    { lat: 49.141978, lng: 20.288123 },
    { lat: 49.141042, lng: 20.2889 },
    { lat: 49.141043, lng: 20.287091 },
    { lat: 49.141062, lng: 20.285678 },
    { lat: 49.141063, lng: 20.28561 },
    { lat: 49.141076, lng: 20.283719 },
    { lat: 49.140641, lng: 20.283495 },
    { lat: 49.1388, lng: 20.285312 },
    { lat: 49.138569, lng: 20.284221 },
    { lat: 49.13823, lng: 20.282744 },
    { lat: 49.137766, lng: 20.280477 },
    { lat: 49.137234, lng: 20.278439 },
    { lat: 49.136855, lng: 20.277593 },
    { lat: 49.136789, lng: 20.277358 },
    { lat: 49.136346, lng: 20.276448 },
  ],
  Tvarožná: [
    { lat: 49.0687488, lng: 20.5410335 },
    { lat: 49.0688296, lng: 20.541177 },
    { lat: 49.0688932, lng: 20.541289 },
    { lat: 49.0689187, lng: 20.5413357 },
    { lat: 49.0689938, lng: 20.5414786 },
    { lat: 49.0690555, lng: 20.5415969 },
    { lat: 49.0690954, lng: 20.5416746 },
    { lat: 49.0691392, lng: 20.541761 },
    { lat: 49.0691841, lng: 20.5418487 },
    { lat: 49.0692034, lng: 20.5418848 },
    { lat: 49.069316, lng: 20.5420974 },
    { lat: 49.0693619, lng: 20.5421851 },
    { lat: 49.069409, lng: 20.5422723 },
    { lat: 49.0694671, lng: 20.5423826 },
    { lat: 49.0695362, lng: 20.5425174 },
    { lat: 49.0696321, lng: 20.542706 },
    { lat: 49.0697559, lng: 20.5429464 },
    { lat: 49.069807, lng: 20.5430465 },
    { lat: 49.0698541, lng: 20.5431359 },
    { lat: 49.0698966, lng: 20.5432188 },
    { lat: 49.0700176, lng: 20.5434498 },
    { lat: 49.0701705, lng: 20.5436859 },
    { lat: 49.0701765, lng: 20.5438179 },
    { lat: 49.0701964, lng: 20.5442363 },
    { lat: 49.0702119, lng: 20.5445678 },
    { lat: 49.0702219, lng: 20.5449626 },
    { lat: 49.0702298, lng: 20.5452072 },
    { lat: 49.0702342, lng: 20.5453598 },
    { lat: 49.0702439, lng: 20.545438 },
    { lat: 49.070249, lng: 20.5454853 },
    { lat: 49.070256, lng: 20.5455558 },
    { lat: 49.0702663, lng: 20.545649 },
    { lat: 49.070268, lng: 20.5456636 },
    { lat: 49.0702937, lng: 20.5459059 },
    { lat: 49.0703043, lng: 20.5459757 },
    { lat: 49.0703444, lng: 20.5461181 },
    { lat: 49.0704631, lng: 20.5465277 },
    { lat: 49.0705014, lng: 20.5465894 },
    { lat: 49.0706203, lng: 20.5467617 },
    { lat: 49.0706947, lng: 20.5468613 },
    { lat: 49.0710505, lng: 20.5472855 },
    { lat: 49.0715556, lng: 20.5478881 },
    { lat: 49.0717061, lng: 20.5480595 },
    { lat: 49.0719087, lng: 20.5482771 },
    { lat: 49.0719968, lng: 20.5483758 },
    { lat: 49.072069, lng: 20.548472 },
    { lat: 49.0721237, lng: 20.5485492 },
    { lat: 49.0721875, lng: 20.5486364 },
    { lat: 49.0722471, lng: 20.5487229 },
    { lat: 49.072362, lng: 20.5488769 },
    { lat: 49.0724616, lng: 20.5490135 },
    { lat: 49.0724617, lng: 20.5490145 },
    { lat: 49.0724996, lng: 20.5490641 },
    { lat: 49.0725359, lng: 20.5491095 },
    { lat: 49.0725933, lng: 20.5491678 },
    { lat: 49.0727477, lng: 20.5492929 },
    { lat: 49.073012, lng: 20.5495005 },
    { lat: 49.0732984, lng: 20.5497246 },
    { lat: 49.0743903, lng: 20.5500934 },
    { lat: 49.0752597, lng: 20.5503713 },
    { lat: 49.075592, lng: 20.5504701 },
    { lat: 49.0758966, lng: 20.550548 },
    { lat: 49.0761459, lng: 20.5505935 },
    { lat: 49.0762774, lng: 20.5505891 },
    { lat: 49.0765371, lng: 20.5505735 },
    { lat: 49.0771047, lng: 20.5504918 },
    { lat: 49.077442, lng: 20.5504767 },
    { lat: 49.0776743, lng: 20.5505205 },
    { lat: 49.0777303, lng: 20.5505412 },
    { lat: 49.0779745, lng: 20.5506321 },
    { lat: 49.0792946, lng: 20.5515999 },
    { lat: 49.0796366, lng: 20.5518656 },
    { lat: 49.0799732, lng: 20.5521415 },
    { lat: 49.0801767, lng: 20.552409 },
    { lat: 49.0803011, lng: 20.5526092 },
    { lat: 49.080701, lng: 20.5533232 },
    { lat: 49.0809006, lng: 20.5536395 },
    { lat: 49.0812262, lng: 20.5541336 },
    { lat: 49.0814189, lng: 20.5543837 },
    { lat: 49.081433, lng: 20.5543689 },
    { lat: 49.0816926, lng: 20.5540825 },
    { lat: 49.0818256, lng: 20.5538697 },
    { lat: 49.0818586, lng: 20.5538173 },
    { lat: 49.0819415, lng: 20.5536845 },
    { lat: 49.0820172, lng: 20.5531706 },
    { lat: 49.0820513, lng: 20.5526926 },
    { lat: 49.0821401, lng: 20.5525026 },
    { lat: 49.0822788, lng: 20.5523216 },
    { lat: 49.0823721, lng: 20.5522605 },
    { lat: 49.0824802, lng: 20.5521407 },
    { lat: 49.0825582, lng: 20.5520536 },
    { lat: 49.0826412, lng: 20.5519237 },
    { lat: 49.0827755, lng: 20.5517137 },
    { lat: 49.0829225, lng: 20.5515815 },
    { lat: 49.0830434, lng: 20.5514736 },
    { lat: 49.0831219, lng: 20.5514029 },
    { lat: 49.0833477, lng: 20.5512031 },
    { lat: 49.0833988, lng: 20.5511233 },
    { lat: 49.083445, lng: 20.5506022 },
    { lat: 49.0835776, lng: 20.5501671 },
    { lat: 49.0836198, lng: 20.5499103 },
    { lat: 49.0836777, lng: 20.5495544 },
    { lat: 49.0837572, lng: 20.5493272 },
    { lat: 49.0837774, lng: 20.5490489 },
    { lat: 49.0837087, lng: 20.5488371 },
    { lat: 49.083598, lng: 20.5484884 },
    { lat: 49.0835069, lng: 20.5481546 },
    { lat: 49.0835015, lng: 20.5477952 },
    { lat: 49.0833236, lng: 20.5474555 },
    { lat: 49.08331, lng: 20.5474258 },
    { lat: 49.0832563, lng: 20.5473108 },
    { lat: 49.0832633, lng: 20.5471629 },
    { lat: 49.0831374, lng: 20.5469524 },
    { lat: 49.0831481, lng: 20.5468125 },
    { lat: 49.0832648, lng: 20.5468949 },
    { lat: 49.083401, lng: 20.5468984 },
    { lat: 49.0835228, lng: 20.5466869 },
    { lat: 49.083585, lng: 20.5466331 },
    { lat: 49.0839542, lng: 20.546543 },
    { lat: 49.0839851, lng: 20.5465569 },
    { lat: 49.0840227, lng: 20.5465659 },
    { lat: 49.0840817, lng: 20.5465458 },
    { lat: 49.0841795, lng: 20.5465127 },
    { lat: 49.0842874, lng: 20.5464762 },
    { lat: 49.0843897, lng: 20.5464409 },
    { lat: 49.0845717, lng: 20.5463094 },
    { lat: 49.0846552, lng: 20.5464366 },
    { lat: 49.084925, lng: 20.546211 },
    { lat: 49.0851665, lng: 20.5460478 },
    { lat: 49.0851854, lng: 20.5460362 },
    { lat: 49.085313, lng: 20.5458698 },
    { lat: 49.08534, lng: 20.5458341 },
    { lat: 49.0855725, lng: 20.5457288 },
    { lat: 49.0855942, lng: 20.5457086 },
    { lat: 49.0857345, lng: 20.5455709 },
    { lat: 49.0857388, lng: 20.5455673 },
    { lat: 49.0858793, lng: 20.545435 },
    { lat: 49.0861324, lng: 20.5454601 },
    { lat: 49.0862944, lng: 20.5453375 },
    { lat: 49.0864532, lng: 20.5452165 },
    { lat: 49.0864576, lng: 20.5452129 },
    { lat: 49.0867061, lng: 20.5452356 },
    { lat: 49.0867822, lng: 20.5452826 },
    { lat: 49.086842, lng: 20.5453177 },
    { lat: 49.0870823, lng: 20.5453938 },
    { lat: 49.0872715, lng: 20.5453917 },
    { lat: 49.0872995, lng: 20.5455779 },
    { lat: 49.0874507, lng: 20.5457033 },
    { lat: 49.0876661, lng: 20.5457826 },
    { lat: 49.0880132, lng: 20.5460907 },
    { lat: 49.0882188, lng: 20.5462692 },
    { lat: 49.0882759, lng: 20.5463186 },
    { lat: 49.0884478, lng: 20.546522 },
    { lat: 49.0887526, lng: 20.5469148 },
    { lat: 49.0888957, lng: 20.5471296 },
    { lat: 49.0891029, lng: 20.5473185 },
    { lat: 49.0891218, lng: 20.5473363 },
    { lat: 49.0891973, lng: 20.5474006 },
    { lat: 49.0892405, lng: 20.5474799 },
    { lat: 49.0893431, lng: 20.5476651 },
    { lat: 49.08952, lng: 20.5477926 },
    { lat: 49.0896947, lng: 20.5479495 },
    { lat: 49.0898148, lng: 20.5480922 },
    { lat: 49.0899432, lng: 20.5481822 },
    { lat: 49.0899726, lng: 20.5482185 },
    { lat: 49.0900492, lng: 20.5483122 },
    { lat: 49.0901462, lng: 20.5485015 },
    { lat: 49.0902127, lng: 20.5486532 },
    { lat: 49.0902287, lng: 20.5486913 },
    { lat: 49.0902872, lng: 20.5490326 },
    { lat: 49.090341, lng: 20.5493578 },
    { lat: 49.0903426, lng: 20.5493691 },
    { lat: 49.0903832, lng: 20.5496789 },
    { lat: 49.0904399, lng: 20.5499952 },
    { lat: 49.0904761, lng: 20.5502823 },
    { lat: 49.0905233, lng: 20.550649 },
    { lat: 49.0905903, lng: 20.5509052 },
    { lat: 49.0906083, lng: 20.5509381 },
    { lat: 49.0907834, lng: 20.5512623 },
    { lat: 49.0908593, lng: 20.5514272 },
    { lat: 49.0908605, lng: 20.5514274 },
    { lat: 49.0909545, lng: 20.5515006 },
    { lat: 49.090973, lng: 20.5515152 },
    { lat: 49.0910737, lng: 20.5515932 },
    { lat: 49.0912262, lng: 20.5516086 },
    { lat: 49.0913142, lng: 20.5516715 },
    { lat: 49.0913981, lng: 20.5517215 },
    { lat: 49.0914085, lng: 20.5517278 },
    { lat: 49.0914761, lng: 20.5517576 },
    { lat: 49.0915013, lng: 20.5517687 },
    { lat: 49.0915161, lng: 20.5517736 },
    { lat: 49.0915992, lng: 20.551802 },
    { lat: 49.0916984, lng: 20.5518367 },
    { lat: 49.0917911, lng: 20.5519387 },
    { lat: 49.0918582, lng: 20.5520182 },
    { lat: 49.0919254, lng: 20.5521022 },
    { lat: 49.0919967, lng: 20.5522 },
    { lat: 49.0920716, lng: 20.5523107 },
    { lat: 49.0921249, lng: 20.5523827 },
    { lat: 49.0921523, lng: 20.5524202 },
    { lat: 49.092175, lng: 20.5524553 },
    { lat: 49.0922095, lng: 20.5525084 },
    { lat: 49.0922203, lng: 20.5525261 },
    { lat: 49.0922405, lng: 20.552555 },
    { lat: 49.0922833, lng: 20.552617 },
    { lat: 49.0923373, lng: 20.5527048 },
    { lat: 49.0923849, lng: 20.5528063 },
    { lat: 49.0924289, lng: 20.552901 },
    { lat: 49.0924851, lng: 20.5530237 },
    { lat: 49.0925464, lng: 20.5531561 },
    { lat: 49.0926086, lng: 20.5532888 },
    { lat: 49.0926598, lng: 20.5534001 },
    { lat: 49.0926907, lng: 20.5534689 },
    { lat: 49.0927323, lng: 20.5535609 },
    { lat: 49.0927826, lng: 20.5536727 },
    { lat: 49.0928362, lng: 20.5537845 },
    { lat: 49.0928865, lng: 20.5539002 },
    { lat: 49.0929513, lng: 20.5540402 },
    { lat: 49.0929797, lng: 20.5541074 },
    { lat: 49.0929994, lng: 20.5541543 },
    { lat: 49.0930469, lng: 20.554402 },
    { lat: 49.0930823, lng: 20.5545995 },
    { lat: 49.0931209, lng: 20.5548243 },
    { lat: 49.0931452, lng: 20.5549648 },
    { lat: 49.0931791, lng: 20.5551559 },
    { lat: 49.0932236, lng: 20.5554154 },
    { lat: 49.0932513, lng: 20.5555862 },
    { lat: 49.0932804, lng: 20.5557371 },
    { lat: 49.093362, lng: 20.5562126 },
    { lat: 49.0934237, lng: 20.5565742 },
    { lat: 49.0937569, lng: 20.5585331 },
    { lat: 49.0939431, lng: 20.5588815 },
    { lat: 49.0939828, lng: 20.5589539 },
    { lat: 49.0945085, lng: 20.5599675 },
    { lat: 49.0945242, lng: 20.5599978 },
    { lat: 49.0949287, lng: 20.5608065 },
    { lat: 49.0952411, lng: 20.5614316 },
    { lat: 49.0955162, lng: 20.5619996 },
    { lat: 49.0957477, lng: 20.5624789 },
    { lat: 49.0957561, lng: 20.562495 },
    { lat: 49.0959743, lng: 20.5629446 },
    { lat: 49.0965916, lng: 20.5641869 },
    { lat: 49.0974094, lng: 20.5658598 },
    { lat: 49.0977908, lng: 20.5666253 },
    { lat: 49.0983884, lng: 20.5678507 },
    { lat: 49.0991664, lng: 20.5691455 },
    { lat: 49.1001206, lng: 20.5707003 },
    { lat: 49.1011834, lng: 20.5720304 },
    { lat: 49.1022754, lng: 20.5733197 },
    { lat: 49.1022289, lng: 20.5763631 },
    { lat: 49.1031292, lng: 20.5778315 },
    { lat: 49.1031412, lng: 20.577857 },
    { lat: 49.1031595, lng: 20.5778918 },
    { lat: 49.1032142, lng: 20.5779752 },
    { lat: 49.1042178, lng: 20.5782951 },
    { lat: 49.1046563, lng: 20.5784675 },
    { lat: 49.1049191, lng: 20.578571 },
    { lat: 49.1053337, lng: 20.578735 },
    { lat: 49.1055776, lng: 20.5789992 },
    { lat: 49.1058392, lng: 20.5792904 },
    { lat: 49.1060013, lng: 20.5796938 },
    { lat: 49.1063482, lng: 20.5805613 },
    { lat: 49.1065067, lng: 20.5806148 },
    { lat: 49.1070172, lng: 20.5807872 },
    { lat: 49.1075765, lng: 20.5813945 },
    { lat: 49.1078717, lng: 20.5817741 },
    { lat: 49.1081052, lng: 20.5820939 },
    { lat: 49.1085519, lng: 20.5834198 },
    { lat: 49.1090805, lng: 20.5849195 },
    { lat: 49.1097279, lng: 20.5867739 },
    { lat: 49.1102272, lng: 20.5881838 },
    { lat: 49.1109373, lng: 20.5899672 },
    { lat: 49.1117582, lng: 20.5923907 },
    { lat: 49.1123394, lng: 20.5940047 },
    { lat: 49.1132824, lng: 20.5958578 },
    { lat: 49.113806, lng: 20.596881 },
    { lat: 49.1142208, lng: 20.597702 },
    { lat: 49.1157971, lng: 20.599196 },
    { lat: 49.1159419, lng: 20.5993703 },
    { lat: 49.1159619, lng: 20.5993945 },
    { lat: 49.116786, lng: 20.5993984 },
    { lat: 49.1170015, lng: 20.5993767 },
    { lat: 49.1172778, lng: 20.5994231 },
    { lat: 49.1175162, lng: 20.5994472 },
    { lat: 49.1179501, lng: 20.5993701 },
    { lat: 49.118209, lng: 20.5993666 },
    { lat: 49.1183229, lng: 20.5994051 },
    { lat: 49.1186889, lng: 20.5996884 },
    { lat: 49.1191926, lng: 20.6009689 },
    { lat: 49.1194522, lng: 20.6016436 },
    { lat: 49.1195823, lng: 20.601872 },
    { lat: 49.1198052, lng: 20.6025282 },
    { lat: 49.1206248, lng: 20.6040485 },
    { lat: 49.121064, lng: 20.6046441 },
    { lat: 49.1215303, lng: 20.6053064 },
    { lat: 49.1217055, lng: 20.6056363 },
    { lat: 49.1217865, lng: 20.6059127 },
    { lat: 49.1220202, lng: 20.6067697 },
    { lat: 49.1223999, lng: 20.6069613 },
    { lat: 49.1230497, lng: 20.6076884 },
    { lat: 49.1239351, lng: 20.6091936 },
    { lat: 49.1241355, lng: 20.6097835 },
    { lat: 49.1243362, lng: 20.6101013 },
    { lat: 49.124369, lng: 20.6100766 },
    { lat: 49.1244088, lng: 20.6100876 },
    { lat: 49.124457, lng: 20.6100836 },
    { lat: 49.124598, lng: 20.6098733 },
    { lat: 49.124309, lng: 20.6092276 },
    { lat: 49.1242258, lng: 20.6073019 },
    { lat: 49.1240351, lng: 20.6065445 },
    { lat: 49.1237598, lng: 20.6060296 },
    { lat: 49.1237234, lng: 20.6058637 },
    { lat: 49.1235695, lng: 20.6056589 },
    { lat: 49.1233895, lng: 20.6051323 },
    { lat: 49.1231058, lng: 20.6046656 },
    { lat: 49.1229559, lng: 20.6038573 },
    { lat: 49.1229633, lng: 20.6036561 },
    { lat: 49.1225747, lng: 20.6023751 },
    { lat: 49.1221796, lng: 20.601691 },
    { lat: 49.1218929, lng: 20.6013651 },
    { lat: 49.1218163, lng: 20.6011447 },
    { lat: 49.1217266, lng: 20.601093 },
    { lat: 49.1216703, lng: 20.6009712 },
    { lat: 49.1215443, lng: 20.6009114 },
    { lat: 49.1214574, lng: 20.6006545 },
    { lat: 49.1213137, lng: 20.6004186 },
    { lat: 49.1212016, lng: 20.6000608 },
    { lat: 49.1212165, lng: 20.5999122 },
    { lat: 49.1211741, lng: 20.5997641 },
    { lat: 49.1212387, lng: 20.5995304 },
    { lat: 49.1211715, lng: 20.5993233 },
    { lat: 49.1211543, lng: 20.5989394 },
    { lat: 49.1209845, lng: 20.5982331 },
    { lat: 49.1206433, lng: 20.5975481 },
    { lat: 49.120573, lng: 20.5973147 },
    { lat: 49.120018, lng: 20.5962642 },
    { lat: 49.1198869, lng: 20.5958759 },
    { lat: 49.1195919, lng: 20.5956898 },
    { lat: 49.1193168, lng: 20.5949641 },
    { lat: 49.1191612, lng: 20.5948683 },
    { lat: 49.1190973, lng: 20.5944079 },
    { lat: 49.1188778, lng: 20.5940081 },
    { lat: 49.118874, lng: 20.5938395 },
    { lat: 49.1187339, lng: 20.5937614 },
    { lat: 49.1185098, lng: 20.5932355 },
    { lat: 49.118382, lng: 20.5927593 },
    { lat: 49.1182846, lng: 20.5926496 },
    { lat: 49.118193, lng: 20.5922951 },
    { lat: 49.1180795, lng: 20.5921128 },
    { lat: 49.1176845, lng: 20.5910018 },
    { lat: 49.1175406, lng: 20.5903933 },
    { lat: 49.1175251, lng: 20.5902451 },
    { lat: 49.1176031, lng: 20.5900948 },
    { lat: 49.117477, lng: 20.5897532 },
    { lat: 49.1174884, lng: 20.5893864 },
    { lat: 49.1174531, lng: 20.5892244 },
    { lat: 49.1175105, lng: 20.5888189 },
    { lat: 49.117358, lng: 20.5876957 },
    { lat: 49.1173988, lng: 20.5869559 },
    { lat: 49.1175192, lng: 20.5864602 },
    { lat: 49.1174272, lng: 20.586037 },
    { lat: 49.1175038, lng: 20.5859777 },
    { lat: 49.117482, lng: 20.5853467 },
    { lat: 49.1175486, lng: 20.5851024 },
    { lat: 49.1174876, lng: 20.5846343 },
    { lat: 49.117644, lng: 20.5840424 },
    { lat: 49.1175836, lng: 20.5836874 },
    { lat: 49.1176142, lng: 20.5834124 },
    { lat: 49.1175445, lng: 20.5831067 },
    { lat: 49.1175142, lng: 20.5826439 },
    { lat: 49.11755, lng: 20.5822416 },
    { lat: 49.117472, lng: 20.5820422 },
    { lat: 49.1173421, lng: 20.5813837 },
    { lat: 49.1168238, lng: 20.5804464 },
    { lat: 49.1168085, lng: 20.5801803 },
    { lat: 49.1168685, lng: 20.579822 },
    { lat: 49.1168102, lng: 20.5794258 },
    { lat: 49.1166404, lng: 20.578871 },
    { lat: 49.116443, lng: 20.578557 },
    { lat: 49.1164081, lng: 20.5780906 },
    { lat: 49.1162664, lng: 20.5775118 },
    { lat: 49.115939, lng: 20.5769872 },
    { lat: 49.1160821, lng: 20.5766886 },
    { lat: 49.1158868, lng: 20.5760809 },
    { lat: 49.1160817, lng: 20.5758693 },
    { lat: 49.1161003, lng: 20.5757006 },
    { lat: 49.1159907, lng: 20.5752319 },
    { lat: 49.1158131, lng: 20.5747406 },
    { lat: 49.1159149, lng: 20.5738335 },
    { lat: 49.1157277, lng: 20.573334 },
    { lat: 49.1157299, lng: 20.5728627 },
    { lat: 49.1155019, lng: 20.5723562 },
    { lat: 49.1156388, lng: 20.571513 },
    { lat: 49.1155044, lng: 20.5713469 },
    { lat: 49.1155892, lng: 20.5710464 },
    { lat: 49.1155367, lng: 20.5706886 },
    { lat: 49.1154581, lng: 20.5706669 },
    { lat: 49.1154178, lng: 20.5705773 },
    { lat: 49.1155282, lng: 20.5703077 },
    { lat: 49.1155301, lng: 20.570107 },
    { lat: 49.1152922, lng: 20.5697617 },
    { lat: 49.1151394, lng: 20.5689711 },
    { lat: 49.1150289, lng: 20.5688134 },
    { lat: 49.1146391, lng: 20.5686499 },
    { lat: 49.1147912, lng: 20.5679775 },
    { lat: 49.1146168, lng: 20.5677303 },
    { lat: 49.1145683, lng: 20.5674811 },
    { lat: 49.1143785, lng: 20.5671544 },
    { lat: 49.1145945, lng: 20.5665085 },
    { lat: 49.1145458, lng: 20.5662205 },
    { lat: 49.1143551, lng: 20.5659611 },
    { lat: 49.1143673, lng: 20.5656822 },
    { lat: 49.1144097, lng: 20.5655449 },
    { lat: 49.1145165, lng: 20.5654525 },
    { lat: 49.1145098, lng: 20.5653905 },
    { lat: 49.1139658, lng: 20.5612357 },
    { lat: 49.1135164, lng: 20.5598461 },
    { lat: 49.1132714, lng: 20.5492045 },
    { lat: 49.1128764, lng: 20.5466993 },
    { lat: 49.1125523, lng: 20.5438276 },
    { lat: 49.111582, lng: 20.5422027 },
    { lat: 49.1112884, lng: 20.5413535 },
    { lat: 49.112322, lng: 20.5402291 },
    { lat: 49.1131626, lng: 20.5381988 },
    { lat: 49.1132407, lng: 20.5363165 },
    { lat: 49.1139318, lng: 20.5341741 },
    { lat: 49.1139506, lng: 20.5333089 },
    { lat: 49.1138148, lng: 20.5324343 },
    { lat: 49.1129779, lng: 20.5309603 },
    { lat: 49.1128821, lng: 20.5291235 },
    { lat: 49.1121119, lng: 20.5265894 },
    { lat: 49.1118672, lng: 20.5256052 },
    { lat: 49.1115431, lng: 20.5247554 },
    { lat: 49.1108646, lng: 20.5221773 },
    { lat: 49.1117638, lng: 20.5213125 },
    { lat: 49.110802, lng: 20.5191127 },
    { lat: 49.1102424, lng: 20.5179846 },
    { lat: 49.109976, lng: 20.5170253 },
    { lat: 49.1093075, lng: 20.5149906 },
    { lat: 49.1092358, lng: 20.5149141 },
    { lat: 49.1091929, lng: 20.5147244 },
    { lat: 49.1092588, lng: 20.51468 },
    { lat: 49.1092563, lng: 20.5144937 },
    { lat: 49.1091357, lng: 20.5144286 },
    { lat: 49.1089604, lng: 20.5139506 },
    { lat: 49.1083136, lng: 20.5127801 },
    { lat: 49.1073343, lng: 20.5127863 },
    { lat: 49.1072688, lng: 20.5117445 },
    { lat: 49.1060046, lng: 20.5081952 },
    { lat: 49.1058994, lng: 20.5078784 },
    { lat: 49.1055355, lng: 20.5056567 },
    { lat: 49.1051157, lng: 20.5035067 },
    { lat: 49.104332, lng: 20.5020198 },
    { lat: 49.1036585, lng: 20.4994053 },
    { lat: 49.1030486, lng: 20.4981417 },
    { lat: 49.1030557, lng: 20.4976097 },
    { lat: 49.1038298, lng: 20.495746 },
    { lat: 49.1040947, lng: 20.4941198 },
    { lat: 49.1033225, lng: 20.4918247 },
    { lat: 49.1035564, lng: 20.4906702 },
    { lat: 49.1040095, lng: 20.4898056 },
    { lat: 49.1041609, lng: 20.4886754 },
    { lat: 49.1038991, lng: 20.4871444 },
    { lat: 49.1033027, lng: 20.4860953 },
    { lat: 49.103409, lng: 20.4853266 },
    { lat: 49.1033077, lng: 20.4837249 },
    { lat: 49.1032862, lng: 20.4799492 },
    { lat: 49.1030478, lng: 20.4790867 },
    { lat: 49.1027869, lng: 20.4783666 },
    { lat: 49.1016353, lng: 20.4755434 },
    { lat: 49.1009799, lng: 20.4740915 },
    { lat: 49.1009216, lng: 20.4737056 },
    { lat: 49.1002825, lng: 20.4715173 },
    { lat: 49.1000989, lng: 20.4707039 },
    { lat: 49.1000292, lng: 20.4701646 },
    { lat: 49.1000066, lng: 20.46854 },
    { lat: 49.1007605, lng: 20.4662749 },
    { lat: 49.1008868, lng: 20.4655593 },
    { lat: 49.100934, lng: 20.4612604 },
    { lat: 49.1010116, lng: 20.460719 },
    { lat: 49.1009977, lng: 20.4600465 },
    { lat: 49.1009474, lng: 20.4597534 },
    { lat: 49.1009291, lng: 20.4581862 },
    { lat: 49.1011153, lng: 20.4569355 },
    { lat: 49.1008168, lng: 20.4567843 },
    { lat: 49.1010467, lng: 20.4555286 },
    { lat: 49.1010559, lng: 20.4549066 },
    { lat: 49.1010196, lng: 20.4546007 },
    { lat: 49.100691, lng: 20.4535522 },
    { lat: 49.0997709, lng: 20.4521977 },
    { lat: 49.0992887, lng: 20.4519853 },
    { lat: 49.0991696, lng: 20.4520162 },
    { lat: 49.0990533, lng: 20.4519164 },
    { lat: 49.0989388, lng: 20.4520481 },
    { lat: 49.0981498, lng: 20.4516049 },
    { lat: 49.0975619, lng: 20.4511241 },
    { lat: 49.097053, lng: 20.4505836 },
    { lat: 49.096353, lng: 20.4501139 },
    { lat: 49.0955516, lng: 20.4491865 },
    { lat: 49.095327, lng: 20.4487839 },
    { lat: 49.0949511, lng: 20.4479229 },
    { lat: 49.0942006, lng: 20.4456607 },
    { lat: 49.0941953, lng: 20.445313 },
    { lat: 49.0938671, lng: 20.4449055 },
    { lat: 49.0938459, lng: 20.4448815 },
    { lat: 49.0937446, lng: 20.4450028 },
    { lat: 49.0936821, lng: 20.4452849 },
    { lat: 49.0935529, lng: 20.4453824 },
    { lat: 49.0935236, lng: 20.445501 },
    { lat: 49.0931782, lng: 20.4459256 },
    { lat: 49.0928912, lng: 20.4460178 },
    { lat: 49.0925758, lng: 20.4462577 },
    { lat: 49.0925433, lng: 20.4464096 },
    { lat: 49.0923308, lng: 20.4464476 },
    { lat: 49.092204, lng: 20.4465466 },
    { lat: 49.0920242, lng: 20.446853 },
    { lat: 49.0919368, lng: 20.4468362 },
    { lat: 49.0917399, lng: 20.4469288 },
    { lat: 49.0917062, lng: 20.4470546 },
    { lat: 49.0915949, lng: 20.4470546 },
    { lat: 49.0915023, lng: 20.4471661 },
    { lat: 49.0915001, lng: 20.4473201 },
    { lat: 49.0914441, lng: 20.4474439 },
    { lat: 49.0911937, lng: 20.4477355 },
    { lat: 49.0912034, lng: 20.4479322 },
    { lat: 49.090878, lng: 20.4482861 },
    { lat: 49.0907532, lng: 20.4482621 },
    { lat: 49.0907452, lng: 20.4484712 },
    { lat: 49.0906038, lng: 20.4484235 },
    { lat: 49.0903245, lng: 20.4486013 },
    { lat: 49.0902027, lng: 20.4485038 },
    { lat: 49.0901691, lng: 20.44839 },
    { lat: 49.090371, lng: 20.4483447 },
    { lat: 49.0903894, lng: 20.4481739 },
    { lat: 49.0902727, lng: 20.4480644 },
    { lat: 49.0902357, lng: 20.4479459 },
    { lat: 49.0899052, lng: 20.447872 },
    { lat: 49.089879, lng: 20.4480406 },
    { lat: 49.0897727, lng: 20.4480617 },
    { lat: 49.0897291, lng: 20.4481589 },
    { lat: 49.0897204, lng: 20.4484036 },
    { lat: 49.0896717, lng: 20.4485436 },
    { lat: 49.0895829, lng: 20.4484034 },
    { lat: 49.0894428, lng: 20.4484813 },
    { lat: 49.0894367, lng: 20.4486123 },
    { lat: 49.0891288, lng: 20.448721 },
    { lat: 49.0891437, lng: 20.4490272 },
    { lat: 49.0890148, lng: 20.4492526 },
    { lat: 49.0890073, lng: 20.4493542 },
    { lat: 49.0891165, lng: 20.4494391 },
    { lat: 49.0891131, lng: 20.4495005 },
    { lat: 49.0889775, lng: 20.4496646 },
    { lat: 49.0889905, lng: 20.4497411 },
    { lat: 49.0891129, lng: 20.4498525 },
    { lat: 49.0889292, lng: 20.4502378 },
    { lat: 49.0889866, lng: 20.4504469 },
    { lat: 49.0889643, lng: 20.450631 },
    { lat: 49.0888759, lng: 20.4507659 },
    { lat: 49.0889017, lng: 20.4509425 },
    { lat: 49.0888365, lng: 20.450978 },
    { lat: 49.0888782, lng: 20.4512147 },
    { lat: 49.0889669, lng: 20.451304 },
    { lat: 49.0888681, lng: 20.4514879 },
    { lat: 49.0887782, lng: 20.4513182 },
    { lat: 49.0887094, lng: 20.4513393 },
    { lat: 49.0886824, lng: 20.4515806 },
    { lat: 49.0887508, lng: 20.4516928 },
    { lat: 49.088655, lng: 20.4519005 },
    { lat: 49.0886693, lng: 20.4521054 },
    { lat: 49.088728, lng: 20.4522218 },
    { lat: 49.0886669, lng: 20.4524545 },
    { lat: 49.0887111, lng: 20.4526442 },
    { lat: 49.0887983, lng: 20.4527491 },
    { lat: 49.088797, lng: 20.4528347 },
    { lat: 49.0887636, lng: 20.4529619 },
    { lat: 49.0886608, lng: 20.4528631 },
    { lat: 49.0885311, lng: 20.4528916 },
    { lat: 49.0885052, lng: 20.4531234 },
    { lat: 49.088503, lng: 20.4532777 },
    { lat: 49.0887255, lng: 20.4534739 },
    { lat: 49.0888483, lng: 20.4538989 },
    { lat: 49.088828, lng: 20.4542236 },
    { lat: 49.0889041, lng: 20.4546042 },
    { lat: 49.088859, lng: 20.4547238 },
    { lat: 49.0884701, lng: 20.4545572 },
    { lat: 49.0882745, lng: 20.4543552 },
    { lat: 49.088107, lng: 20.4548762 },
    { lat: 49.0875482, lng: 20.4544163 },
    { lat: 49.087117, lng: 20.4542979 },
    { lat: 49.0869579, lng: 20.4550072 },
    { lat: 49.086609, lng: 20.4559356 },
    { lat: 49.0865013, lng: 20.4564874 },
    { lat: 49.0863626, lng: 20.4568698 },
    { lat: 49.0861231, lng: 20.4573285 },
    { lat: 49.0854808, lng: 20.4582745 },
    { lat: 49.0853622, lng: 20.4580251 },
    { lat: 49.0852711, lng: 20.4580313 },
    { lat: 49.0849635, lng: 20.4583835 },
    { lat: 49.0847041, lng: 20.4588202 },
    { lat: 49.0846532, lng: 20.4587546 },
    { lat: 49.0842674, lng: 20.4592723 },
    { lat: 49.0840107, lng: 20.4594861 },
    { lat: 49.0838661, lng: 20.459198 },
    { lat: 49.0837374, lng: 20.4585613 },
    { lat: 49.0835264, lng: 20.4579166 },
    { lat: 49.0834007, lng: 20.4576603 },
    { lat: 49.083189, lng: 20.4578094 },
    { lat: 49.0830171, lng: 20.4580334 },
    { lat: 49.0825493, lng: 20.4591847 },
    { lat: 49.082048, lng: 20.4599887 },
    { lat: 49.0819169, lng: 20.4601097 },
    { lat: 49.0808737, lng: 20.4610729 },
    { lat: 49.0806583, lng: 20.4612718 },
    { lat: 49.0804247, lng: 20.4618241 },
    { lat: 49.07917, lng: 20.4636732 },
    { lat: 49.0794467, lng: 20.4643578 },
    { lat: 49.0794745, lng: 20.4645565 },
    { lat: 49.0790333, lng: 20.4647155 },
    { lat: 49.0786734, lng: 20.4647692 },
    { lat: 49.0782162, lng: 20.4648375 },
    { lat: 49.0781445, lng: 20.4647873 },
    { lat: 49.0779592, lng: 20.4648766 },
    { lat: 49.0777607, lng: 20.4649722 },
    { lat: 49.0776276, lng: 20.4652054 },
    { lat: 49.0775808, lng: 20.4654261 },
    { lat: 49.0774993, lng: 20.4658105 },
    { lat: 49.0770881, lng: 20.4665225 },
    { lat: 49.0769755, lng: 20.4667589 },
    { lat: 49.0763125, lng: 20.4681517 },
    { lat: 49.0757701, lng: 20.4688822 },
    { lat: 49.0754681, lng: 20.4694744 },
    { lat: 49.0754518, lng: 20.4700271 },
    { lat: 49.0753118, lng: 20.4705589 },
    { lat: 49.0748346, lng: 20.4712865 },
    { lat: 49.0745291, lng: 20.471577 },
    { lat: 49.0739297, lng: 20.4717439 },
    { lat: 49.0738228, lng: 20.4719578 },
    { lat: 49.0737725, lng: 20.4721784 },
    { lat: 49.0741809, lng: 20.4725944 },
    { lat: 49.0745072, lng: 20.4731299 },
    { lat: 49.0746525, lng: 20.4732494 },
    { lat: 49.0745913, lng: 20.4733585 },
    { lat: 49.0749346, lng: 20.4742003 },
    { lat: 49.0750685, lng: 20.4754956 },
    { lat: 49.0752539, lng: 20.4765687 },
    { lat: 49.0753322, lng: 20.4775463 },
    { lat: 49.0752823, lng: 20.4783812 },
    { lat: 49.0751863, lng: 20.4788876 },
    { lat: 49.0750626, lng: 20.4793074 },
    { lat: 49.0748879, lng: 20.4796647 },
    { lat: 49.0743994, lng: 20.481568 },
    { lat: 49.074232, lng: 20.4819696 },
    { lat: 49.0739545, lng: 20.4823469 },
    { lat: 49.0738018, lng: 20.4823594 },
    { lat: 49.0738207, lng: 20.482593 },
    { lat: 49.0733752, lng: 20.4827562 },
    { lat: 49.0733895, lng: 20.4839547 },
    { lat: 49.0735161, lng: 20.4851158 },
    { lat: 49.0735336, lng: 20.4865209 },
    { lat: 49.0726463, lng: 20.4872975 },
    { lat: 49.0718936, lng: 20.4877066 },
    { lat: 49.0696541, lng: 20.48861 },
    { lat: 49.0694713, lng: 20.4887496 },
    { lat: 49.0687727, lng: 20.4889777 },
    { lat: 49.0671076, lng: 20.4899416 },
    { lat: 49.0633835, lng: 20.4905849 },
    { lat: 49.0613086, lng: 20.4905963 },
    { lat: 49.0610242, lng: 20.4905979 },
    { lat: 49.0610056, lng: 20.4906124 },
    { lat: 49.0610131, lng: 20.4911169 },
    { lat: 49.0608702, lng: 20.4918469 },
    { lat: 49.0611145, lng: 20.4919637 },
    { lat: 49.0614445, lng: 20.4923614 },
    { lat: 49.0616503, lng: 20.49344 },
    { lat: 49.062649, lng: 20.4959539 },
    { lat: 49.0641567, lng: 20.4982276 },
    { lat: 49.0648053, lng: 20.4995928 },
    { lat: 49.0649871, lng: 20.500181 },
    { lat: 49.0651339, lng: 20.5009795 },
    { lat: 49.0650557, lng: 20.501416 },
    { lat: 49.0645748, lng: 20.5029305 },
    { lat: 49.0643991, lng: 20.503198 },
    { lat: 49.0640471, lng: 20.5033938 },
    { lat: 49.063743, lng: 20.5038447 },
    { lat: 49.0635594, lng: 20.5041743 },
    { lat: 49.0632411, lng: 20.505063 },
    { lat: 49.0624492, lng: 20.5064423 },
    { lat: 49.0621601, lng: 20.507057 },
    { lat: 49.0616556, lng: 20.507873 },
    { lat: 49.0611993, lng: 20.50844 },
    { lat: 49.0608899, lng: 20.5090855 },
    { lat: 49.0601786, lng: 20.5097907 },
    { lat: 49.0596664, lng: 20.5104264 },
    { lat: 49.0596518, lng: 20.5106104 },
    { lat: 49.0594556, lng: 20.5110253 },
    { lat: 49.059453, lng: 20.5110446 },
    { lat: 49.0594331, lng: 20.5111763 },
    { lat: 49.0600292, lng: 20.5120401 },
    { lat: 49.0604734, lng: 20.5130865 },
    { lat: 49.0605893, lng: 20.5143352 },
    { lat: 49.0606674, lng: 20.5144962 },
    { lat: 49.0608392, lng: 20.5152425 },
    { lat: 49.0609884, lng: 20.5161979 },
    { lat: 49.0612526, lng: 20.5172246 },
    { lat: 49.0613625, lng: 20.5179752 },
    { lat: 49.0616805, lng: 20.5188957 },
    { lat: 49.0617557, lng: 20.5193793 },
    { lat: 49.0619033, lng: 20.5198036 },
    { lat: 49.0621156, lng: 20.520675 },
    { lat: 49.0623477, lng: 20.5212866 },
    { lat: 49.0627552, lng: 20.5221363 },
    { lat: 49.0631953, lng: 20.5244487 },
    { lat: 49.0634779, lng: 20.5271918 },
    { lat: 49.0644821, lng: 20.531575 },
    { lat: 49.0655125, lng: 20.5329862 },
    { lat: 49.0659753, lng: 20.5343315 },
    { lat: 49.0675353, lng: 20.5376794 },
    { lat: 49.0677575, lng: 20.5385125 },
    { lat: 49.0679641, lng: 20.5397203 },
    { lat: 49.0686547, lng: 20.5408939 },
    { lat: 49.0687488, lng: 20.5410335 },
  ],
  Huncovce: [
    { lat: 49.1303634, lng: 20.3918809 },
    { lat: 49.1303688, lng: 20.3918747 },
    { lat: 49.1315781, lng: 20.3904018 },
    { lat: 49.132033, lng: 20.3883596 },
    { lat: 49.130658, lng: 20.3869971 },
    { lat: 49.1314285, lng: 20.3851779 },
    { lat: 49.1328496, lng: 20.3865087 },
    { lat: 49.1333755, lng: 20.3855941 },
    { lat: 49.1339192, lng: 20.3844993 },
    { lat: 49.134687, lng: 20.3822573 },
    { lat: 49.1354515, lng: 20.3802191 },
    { lat: 49.136139, lng: 20.3789064 },
    { lat: 49.1369038, lng: 20.3772981 },
    { lat: 49.1376637, lng: 20.3759369 },
    { lat: 49.1378932, lng: 20.3754286 },
    { lat: 49.1391625, lng: 20.3762066 },
    { lat: 49.1392252, lng: 20.3759641 },
    { lat: 49.1393287, lng: 20.3758682 },
    { lat: 49.1393388, lng: 20.3757818 },
    { lat: 49.1392829, lng: 20.3757321 },
    { lat: 49.1392609, lng: 20.3758347 },
    { lat: 49.1392041, lng: 20.3757951 },
    { lat: 49.139166, lng: 20.3757363 },
    { lat: 49.1391997, lng: 20.3756172 },
    { lat: 49.1393564, lng: 20.3755073 },
    { lat: 49.1394459, lng: 20.3755216 },
    { lat: 49.1394962, lng: 20.3752151 },
    { lat: 49.1396386, lng: 20.3751981 },
    { lat: 49.1398336, lng: 20.3747946 },
    { lat: 49.1399295, lng: 20.3747656 },
    { lat: 49.1401447, lng: 20.3742423 },
    { lat: 49.1403365, lng: 20.3742551 },
    { lat: 49.1404453, lng: 20.3741257 },
    { lat: 49.1404378, lng: 20.3736315 },
    { lat: 49.140144, lng: 20.3731937 },
    { lat: 49.1404337, lng: 20.3729396 },
    { lat: 49.1404867, lng: 20.3728511 },
    { lat: 49.1404768, lng: 20.3727096 },
    { lat: 49.1406121, lng: 20.3725685 },
    { lat: 49.1406384, lng: 20.3724863 },
    { lat: 49.1405971, lng: 20.37238 },
    { lat: 49.1404821, lng: 20.3723776 },
    { lat: 49.1404727, lng: 20.3723061 },
    { lat: 49.1405729, lng: 20.3721304 },
    { lat: 49.1407629, lng: 20.3719786 },
    { lat: 49.1409826, lng: 20.3715974 },
    { lat: 49.1411122, lng: 20.3715664 },
    { lat: 49.1411693, lng: 20.3714452 },
    { lat: 49.1411232, lng: 20.3711206 },
    { lat: 49.1411982, lng: 20.3709057 },
    { lat: 49.1412156, lng: 20.3705945 },
    { lat: 49.1411751, lng: 20.3703004 },
    { lat: 49.1412914, lng: 20.3700171 },
    { lat: 49.141348, lng: 20.369523 },
    { lat: 49.1417012, lng: 20.3687446 },
    { lat: 49.1416441, lng: 20.3685232 },
    { lat: 49.1416649, lng: 20.3684436 },
    { lat: 49.1418559, lng: 20.3683191 },
    { lat: 49.1419652, lng: 20.3683511 },
    { lat: 49.1419939, lng: 20.3684536 },
    { lat: 49.1420462, lng: 20.368463 },
    { lat: 49.1421351, lng: 20.3682288 },
    { lat: 49.1421064, lng: 20.3680998 },
    { lat: 49.1419994, lng: 20.3680978 },
    { lat: 49.1419657, lng: 20.3680119 },
    { lat: 49.1419679, lng: 20.3678909 },
    { lat: 49.1420196, lng: 20.3678299 },
    { lat: 49.1420987, lng: 20.3678674 },
    { lat: 49.1421447, lng: 20.3677454 },
    { lat: 49.1419908, lng: 20.3674286 },
    { lat: 49.1421918, lng: 20.3669716 },
    { lat: 49.1423077, lng: 20.3670044 },
    { lat: 49.1423725, lng: 20.3668732 },
    { lat: 49.1423724, lng: 20.3666613 },
    { lat: 49.1422545, lng: 20.3664334 },
    { lat: 49.1422469, lng: 20.3662352 },
    { lat: 49.1423981, lng: 20.366098 },
    { lat: 49.1426722, lng: 20.3663743 },
    { lat: 49.1427122, lng: 20.3663362 },
    { lat: 49.1426225, lng: 20.3662241 },
    { lat: 49.1426899, lng: 20.3660426 },
    { lat: 49.1425459, lng: 20.3659914 },
    { lat: 49.1425296, lng: 20.365917 },
    { lat: 49.1426624, lng: 20.365799 },
    { lat: 49.1428099, lng: 20.3657996 },
    { lat: 49.1428754, lng: 20.3656247 },
    { lat: 49.1431067, lng: 20.3653441 },
    { lat: 49.1431772, lng: 20.3650084 },
    { lat: 49.1431492, lng: 20.3646304 },
    { lat: 49.1433026, lng: 20.3643726 },
    { lat: 49.1432843, lng: 20.3642203 },
    { lat: 49.1433855, lng: 20.3640103 },
    { lat: 49.1434677, lng: 20.3639807 },
    { lat: 49.1435667, lng: 20.3640966 },
    { lat: 49.1436369, lng: 20.3637236 },
    { lat: 49.14362, lng: 20.3634604 },
    { lat: 49.1440179, lng: 20.3630872 },
    { lat: 49.1438609, lng: 20.3629013 },
    { lat: 49.1438899, lng: 20.3628019 },
    { lat: 49.1441245, lng: 20.3626987 },
    { lat: 49.1444653, lng: 20.362745 },
    { lat: 49.1445906, lng: 20.3626399 },
    { lat: 49.144658, lng: 20.362485 },
    { lat: 49.1445843, lng: 20.3620881 },
    { lat: 49.1447081, lng: 20.3619131 },
    { lat: 49.144678, lng: 20.3616589 },
    { lat: 49.14476, lng: 20.3615315 },
    { lat: 49.1449006, lng: 20.3614708 },
    { lat: 49.1451781, lng: 20.3614839 },
    { lat: 49.1452774, lng: 20.3616973 },
    { lat: 49.1454333, lng: 20.3614759 },
    { lat: 49.1455414, lng: 20.3614137 },
    { lat: 49.1455663, lng: 20.3611292 },
    { lat: 49.1456391, lng: 20.3609109 },
    { lat: 49.1454723, lng: 20.3606409 },
    { lat: 49.1454893, lng: 20.3605304 },
    { lat: 49.1457557, lng: 20.360338 },
    { lat: 49.1458889, lng: 20.3603171 },
    { lat: 49.1460408, lng: 20.3601997 },
    { lat: 49.1461213, lng: 20.3600091 },
    { lat: 49.1462504, lng: 20.3599674 },
    { lat: 49.1462891, lng: 20.3596837 },
    { lat: 49.1465362, lng: 20.3591132 },
    { lat: 49.1465211, lng: 20.3587765 },
    { lat: 49.1464552, lng: 20.3587299 },
    { lat: 49.1464913, lng: 20.3586137 },
    { lat: 49.1466162, lng: 20.3584987 },
    { lat: 49.1466975, lng: 20.3585026 },
    { lat: 49.1467398, lng: 20.3586351 },
    { lat: 49.1468704, lng: 20.3586918 },
    { lat: 49.146972, lng: 20.3585853 },
    { lat: 49.1470652, lng: 20.3583144 },
    { lat: 49.1472296, lng: 20.3582278 },
    { lat: 49.1473765, lng: 20.3579723 },
    { lat: 49.1474057, lng: 20.3578287 },
    { lat: 49.1475455, lng: 20.3576537 },
    { lat: 49.1478553, lng: 20.3576009 },
    { lat: 49.1480034, lng: 20.3573521 },
    { lat: 49.1481469, lng: 20.3573656 },
    { lat: 49.1482651, lng: 20.3576 },
    { lat: 49.1483974, lng: 20.3575857 },
    { lat: 49.1486103, lng: 20.3573232 },
    { lat: 49.1490966, lng: 20.3572309 },
    { lat: 49.1491573, lng: 20.356968 },
    { lat: 49.1492431, lng: 20.356824 },
    { lat: 49.1493834, lng: 20.3566389 },
    { lat: 49.1496272, lng: 20.3565053 },
    { lat: 49.1496451, lng: 20.3563548 },
    { lat: 49.149745, lng: 20.3562049 },
    { lat: 49.1498337, lng: 20.3561685 },
    { lat: 49.1500468, lng: 20.3558485 },
    { lat: 49.1502597, lng: 20.3556799 },
    { lat: 49.1502925, lng: 20.3555608 },
    { lat: 49.1501955, lng: 20.355445 },
    { lat: 49.1504908, lng: 20.3549809 },
    { lat: 49.1504869, lng: 20.3547622 },
    { lat: 49.1507452, lng: 20.3546258 },
    { lat: 49.1510125, lng: 20.3542009 },
    { lat: 49.1511477, lng: 20.3542 },
    { lat: 49.1511909, lng: 20.354098 },
    { lat: 49.1511668, lng: 20.3538746 },
    { lat: 49.1511794, lng: 20.3537979 },
    { lat: 49.151277, lng: 20.353782 },
    { lat: 49.1512412, lng: 20.353441 },
    { lat: 49.1513225, lng: 20.3531485 },
    { lat: 49.1513993, lng: 20.3530413 },
    { lat: 49.1518222, lng: 20.352791 },
    { lat: 49.1518844, lng: 20.3525383 },
    { lat: 49.1519633, lng: 20.352478 },
    { lat: 49.1520633, lng: 20.3525328 },
    { lat: 49.152147, lng: 20.3524897 },
    { lat: 49.1522565, lng: 20.3520843 },
    { lat: 49.1523551, lng: 20.3522431 },
    { lat: 49.1524282, lng: 20.3522164 },
    { lat: 49.1525175, lng: 20.3516049 },
    { lat: 49.1524554, lng: 20.3512791 },
    { lat: 49.1524782, lng: 20.3512026 },
    { lat: 49.1525468, lng: 20.351108 },
    { lat: 49.1527649, lng: 20.3513331 },
    { lat: 49.1528317, lng: 20.3511682 },
    { lat: 49.152829, lng: 20.3509494 },
    { lat: 49.1529679, lng: 20.3507269 },
    { lat: 49.1529136, lng: 20.3505735 },
    { lat: 49.1528067, lng: 20.3504909 },
    { lat: 49.1528206, lng: 20.3503267 },
    { lat: 49.1528899, lng: 20.3501652 },
    { lat: 49.1530087, lng: 20.3501808 },
    { lat: 49.1531218, lng: 20.3497819 },
    { lat: 49.1531816, lng: 20.3497848 },
    { lat: 49.1532779, lng: 20.3499467 },
    { lat: 49.1532413, lng: 20.3494608 },
    { lat: 49.153358, lng: 20.349187 },
    { lat: 49.1534615, lng: 20.3486264 },
    { lat: 49.1534495, lng: 20.348081 },
    { lat: 49.1534972, lng: 20.3480325 },
    { lat: 49.1536632, lng: 20.348104 },
    { lat: 49.1539639, lng: 20.3477876 },
    { lat: 49.1539829, lng: 20.347755 },
    { lat: 49.1538703, lng: 20.3477229 },
    { lat: 49.1539066, lng: 20.3475564 },
    { lat: 49.1538602, lng: 20.3474598 },
    { lat: 49.1538732, lng: 20.3472687 },
    { lat: 49.1539154, lng: 20.3471095 },
    { lat: 49.1540725, lng: 20.3470656 },
    { lat: 49.1542794, lng: 20.3466472 },
    { lat: 49.1543415, lng: 20.3466243 },
    { lat: 49.1544175, lng: 20.3466992 },
    { lat: 49.1544037, lng: 20.3467824 },
    { lat: 49.1544625, lng: 20.3467592 },
    { lat: 49.1545513, lng: 20.3465462 },
    { lat: 49.1545022, lng: 20.3463855 },
    { lat: 49.1546632, lng: 20.3462643 },
    { lat: 49.1546962, lng: 20.3461851 },
    { lat: 49.1546666, lng: 20.3461197 },
    { lat: 49.1549078, lng: 20.34601 },
    { lat: 49.1549708, lng: 20.3461515 },
    { lat: 49.1550315, lng: 20.3460076 },
    { lat: 49.155013, lng: 20.3458755 },
    { lat: 49.1550522, lng: 20.345723 },
    { lat: 49.1551343, lng: 20.345607 },
    { lat: 49.1552419, lng: 20.3452068 },
    { lat: 49.1553348, lng: 20.3452866 },
    { lat: 49.1554366, lng: 20.3450304 },
    { lat: 49.155339, lng: 20.3447754 },
    { lat: 49.155317, lng: 20.3445824 },
    { lat: 49.1553851, lng: 20.3444519 },
    { lat: 49.1555808, lng: 20.3442153 },
    { lat: 49.1557808, lng: 20.3442312 },
    { lat: 49.1558492, lng: 20.3441409 },
    { lat: 49.1558274, lng: 20.3439781 },
    { lat: 49.1559161, lng: 20.343886 },
    { lat: 49.155968, lng: 20.3439163 },
    { lat: 49.1560934, lng: 20.3437522 },
    { lat: 49.1561776, lng: 20.3437471 },
    { lat: 49.1562577, lng: 20.3434597 },
    { lat: 49.1565887, lng: 20.3434859 },
    { lat: 49.1566074, lng: 20.3434436 },
    { lat: 49.1565486, lng: 20.3433258 },
    { lat: 49.1567572, lng: 20.3432715 },
    { lat: 49.1568152, lng: 20.3429084 },
    { lat: 49.1568816, lng: 20.3429053 },
    { lat: 49.1570486, lng: 20.3426832 },
    { lat: 49.1571219, lng: 20.3427414 },
    { lat: 49.1573043, lng: 20.3425945 },
    { lat: 49.1573324, lng: 20.3424484 },
    { lat: 49.1573032, lng: 20.3423021 },
    { lat: 49.1573877, lng: 20.3421017 },
    { lat: 49.1576107, lng: 20.3420174 },
    { lat: 49.1576685, lng: 20.3419065 },
    { lat: 49.1578614, lng: 20.3418611 },
    { lat: 49.1579482, lng: 20.3418034 },
    { lat: 49.1579959, lng: 20.3416642 },
    { lat: 49.1581978, lng: 20.3415889 },
    { lat: 49.1583184, lng: 20.3413864 },
    { lat: 49.1586853, lng: 20.3410024 },
    { lat: 49.1588246, lng: 20.34079 },
    { lat: 49.1589158, lng: 20.3404983 },
    { lat: 49.159155, lng: 20.3402164 },
    { lat: 49.1592498, lng: 20.3401335 },
    { lat: 49.1594313, lng: 20.3402892 },
    { lat: 49.1596317, lng: 20.3401698 },
    { lat: 49.159699, lng: 20.3396925 },
    { lat: 49.1598446, lng: 20.3395582 },
    { lat: 49.1599295, lng: 20.3396251 },
    { lat: 49.1600307, lng: 20.339593 },
    { lat: 49.1600959, lng: 20.3394476 },
    { lat: 49.1600327, lng: 20.3393813 },
    { lat: 49.1600403, lng: 20.3392302 },
    { lat: 49.1601499, lng: 20.3391542 },
    { lat: 49.1602938, lng: 20.3392313 },
    { lat: 49.1603936, lng: 20.3391954 },
    { lat: 49.160482, lng: 20.3390651 },
    { lat: 49.1606949, lng: 20.3391209 },
    { lat: 49.1610546, lng: 20.3388441 },
    { lat: 49.1611456, lng: 20.338633 },
    { lat: 49.1615145, lng: 20.3383064 },
    { lat: 49.1618534, lng: 20.3377735 },
    { lat: 49.1621016, lng: 20.3374684 },
    { lat: 49.1621934, lng: 20.3374253 },
    { lat: 49.1622973, lng: 20.3370771 },
    { lat: 49.1626088, lng: 20.3368956 },
    { lat: 49.1626683, lng: 20.3367438 },
    { lat: 49.162637, lng: 20.3364264 },
    { lat: 49.162813, lng: 20.3363229 },
    { lat: 49.1630189, lng: 20.3363148 },
    { lat: 49.163302, lng: 20.3359779 },
    { lat: 49.163444, lng: 20.3359263 },
    { lat: 49.1636468, lng: 20.3357236 },
    { lat: 49.1636765, lng: 20.3355569 },
    { lat: 49.1636403, lng: 20.3353504 },
    { lat: 49.1635271, lng: 20.3351878 },
    { lat: 49.1636018, lng: 20.335026 },
    { lat: 49.1637257, lng: 20.3350513 },
    { lat: 49.1639074, lng: 20.3348612 },
    { lat: 49.1641637, lng: 20.3349055 },
    { lat: 49.164265, lng: 20.3347921 },
    { lat: 49.1643, lng: 20.3343536 },
    { lat: 49.1644162, lng: 20.3338913 },
    { lat: 49.1644948, lng: 20.3337403 },
    { lat: 49.1644542, lng: 20.3335065 },
    { lat: 49.1645078, lng: 20.3333477 },
    { lat: 49.1647644, lng: 20.3331671 },
    { lat: 49.1648517, lng: 20.3330093 },
    { lat: 49.1650915, lng: 20.3328953 },
    { lat: 49.1650474, lng: 20.3327726 },
    { lat: 49.1651156, lng: 20.3326377 },
    { lat: 49.1652927, lng: 20.3324772 },
    { lat: 49.1654077, lng: 20.3317454 },
    { lat: 49.1653666, lng: 20.3316768 },
    { lat: 49.1654782, lng: 20.331389 },
    { lat: 49.1656295, lng: 20.3312808 },
    { lat: 49.1656995, lng: 20.3310824 },
    { lat: 49.1657981, lng: 20.3310092 },
    { lat: 49.165967, lng: 20.330393 },
    { lat: 49.165935, lng: 20.330346 },
    { lat: 49.16516, lng: 20.329439 },
    { lat: 49.16487, lng: 20.328939 },
    { lat: 49.164529, lng: 20.328533 },
    { lat: 49.164482, lng: 20.328462 },
    { lat: 49.164468, lng: 20.32844 },
    { lat: 49.164439, lng: 20.328398 },
    { lat: 49.164406, lng: 20.328346 },
    { lat: 49.164382, lng: 20.32831 },
    { lat: 49.16446, lng: 20.328163 },
    { lat: 49.164323, lng: 20.327942 },
    { lat: 49.164305, lng: 20.327915 },
    { lat: 49.163692, lng: 20.326933 },
    { lat: 49.163331, lng: 20.32582 },
    { lat: 49.162741, lng: 20.324344 },
    { lat: 49.16193, lng: 20.322518 },
    { lat: 49.16191, lng: 20.322456 },
    { lat: 49.161755, lng: 20.322801 },
    { lat: 49.161055, lng: 20.323754 },
    { lat: 49.160263, lng: 20.324839 },
    { lat: 49.159229, lng: 20.326257 },
    { lat: 49.159122, lng: 20.326404 },
    { lat: 49.158009, lng: 20.327929 },
    { lat: 49.157045, lng: 20.329258 },
    { lat: 49.156425, lng: 20.328139 },
    { lat: 49.155723, lng: 20.326869 },
    { lat: 49.15505, lng: 20.32565 },
    { lat: 49.154277, lng: 20.324247 },
    { lat: 49.153599, lng: 20.323013 },
    { lat: 49.152828, lng: 20.321619 },
    { lat: 49.152592, lng: 20.3212 },
    { lat: 49.152484, lng: 20.320994 },
    { lat: 49.152365, lng: 20.321735 },
    { lat: 49.15102, lng: 20.323099 },
    { lat: 49.150695, lng: 20.324108 },
    { lat: 49.149877, lng: 20.326444 },
    { lat: 49.1497, lng: 20.327682 },
    { lat: 49.149675, lng: 20.327874 },
    { lat: 49.149508, lng: 20.329031 },
    { lat: 49.1495192, lng: 20.3306726 },
    { lat: 49.14891, lng: 20.3332357 },
    { lat: 49.1497257, lng: 20.3370476 },
    { lat: 49.1496745, lng: 20.3387869 },
    { lat: 49.1495044, lng: 20.34051 },
    { lat: 49.1491172, lng: 20.3423545 },
    { lat: 49.1480041, lng: 20.3453545 },
    { lat: 49.1474038, lng: 20.3467512 },
    { lat: 49.1468918, lng: 20.3481097 },
    { lat: 49.1440988, lng: 20.3529263 },
    { lat: 49.1436121, lng: 20.3535656 },
    { lat: 49.1431829, lng: 20.3540272 },
    { lat: 49.1423573, lng: 20.3547582 },
    { lat: 49.1415353, lng: 20.3556378 },
    { lat: 49.1412705, lng: 20.3556898 },
    { lat: 49.1406343, lng: 20.3564201 },
    { lat: 49.1403156, lng: 20.356888 },
    { lat: 49.139975, lng: 20.3571661 },
    { lat: 49.1394695, lng: 20.3573108 },
    { lat: 49.1380171, lng: 20.358582 },
    { lat: 49.1373305, lng: 20.3590232 },
    { lat: 49.1369603, lng: 20.3595021 },
    { lat: 49.1367815, lng: 20.3596511 },
    { lat: 49.1357697, lng: 20.3600738 },
    { lat: 49.1351266, lng: 20.3605126 },
    { lat: 49.1348, lng: 20.3606287 },
    { lat: 49.1346495, lng: 20.3607514 },
    { lat: 49.1343096, lng: 20.3608471 },
    { lat: 49.1341225, lng: 20.3608102 },
    { lat: 49.1340367, lng: 20.3606589 },
    { lat: 49.1324957, lng: 20.3615261 },
    { lat: 49.1324223, lng: 20.3614024 },
    { lat: 49.131063, lng: 20.3623598 },
    { lat: 49.1310565, lng: 20.3624876 },
    { lat: 49.1297364, lng: 20.3634418 },
    { lat: 49.129616, lng: 20.3635103 },
    { lat: 49.1281704, lng: 20.3643333 },
    { lat: 49.126945, lng: 20.3652339 },
    { lat: 49.1257025, lng: 20.3664208 },
    { lat: 49.1256659, lng: 20.3665801 },
    { lat: 49.1254915, lng: 20.3667887 },
    { lat: 49.1237454, lng: 20.3678574 },
    { lat: 49.1217515, lng: 20.3692389 },
    { lat: 49.1213314, lng: 20.3684171 },
    { lat: 49.1210043, lng: 20.3674892 },
    { lat: 49.1205515, lng: 20.3667494 },
    { lat: 49.119209, lng: 20.3680331 },
    { lat: 49.1181229, lng: 20.3688366 },
    { lat: 49.1179615, lng: 20.3688546 },
    { lat: 49.1179259, lng: 20.3687819 },
    { lat: 49.118066, lng: 20.3687062 },
    { lat: 49.1179337, lng: 20.3686044 },
    { lat: 49.1178564, lng: 20.3683179 },
    { lat: 49.1177325, lng: 20.3683778 },
    { lat: 49.1174699, lng: 20.3678724 },
    { lat: 49.1171572, lng: 20.3680558 },
    { lat: 49.1171334, lng: 20.3679638 },
    { lat: 49.1170201, lng: 20.3680612 },
    { lat: 49.1165551, lng: 20.3670332 },
    { lat: 49.1159947, lng: 20.3659863 },
    { lat: 49.1158334, lng: 20.3654144 },
    { lat: 49.1154141, lng: 20.36491 },
    { lat: 49.1148141, lng: 20.3643236 },
    { lat: 49.1144812, lng: 20.3640664 },
    { lat: 49.1142617, lng: 20.3640392 },
    { lat: 49.1134422, lng: 20.3651886 },
    { lat: 49.1146634, lng: 20.3660317 },
    { lat: 49.1150878, lng: 20.3662036 },
    { lat: 49.113537, lng: 20.3682866 },
    { lat: 49.1129218, lng: 20.366977 },
    { lat: 49.1125443, lng: 20.3664096 },
    { lat: 49.1119515, lng: 20.3671042 },
    { lat: 49.1119928, lng: 20.3671835 },
    { lat: 49.1119227, lng: 20.3672645 },
    { lat: 49.1114592, lng: 20.3663917 },
    { lat: 49.1115156, lng: 20.3675997 },
    { lat: 49.1119683, lng: 20.3711594 },
    { lat: 49.1119203, lng: 20.3712006 },
    { lat: 49.1114684, lng: 20.3715675 },
    { lat: 49.1113703, lng: 20.3711873 },
    { lat: 49.1111126, lng: 20.3712785 },
    { lat: 49.1107338, lng: 20.3712941 },
    { lat: 49.1101327, lng: 20.3711793 },
    { lat: 49.1070381, lng: 20.3724934 },
    { lat: 49.1071008, lng: 20.3743652 },
    { lat: 49.1070767, lng: 20.3765635 },
    { lat: 49.1065894, lng: 20.3765111 },
    { lat: 49.10542, lng: 20.3765516 },
    { lat: 49.1042406, lng: 20.376363 },
    { lat: 49.102927, lng: 20.3762854 },
    { lat: 49.1026487, lng: 20.3763938 },
    { lat: 49.1016333, lng: 20.3763402 },
    { lat: 49.1001926, lng: 20.3760928 },
    { lat: 49.0994822, lng: 20.3758285 },
    { lat: 49.0975808, lng: 20.3760154 },
    { lat: 49.0969902, lng: 20.3759097 },
    { lat: 49.0958223, lng: 20.3758707 },
    { lat: 49.0948843, lng: 20.3761633 },
    { lat: 49.0935723, lng: 20.3761841 },
    { lat: 49.0927601, lng: 20.3760291 },
    { lat: 49.0918535, lng: 20.3761116 },
    { lat: 49.0911859, lng: 20.3764112 },
    { lat: 49.0906609, lng: 20.3769284 },
    { lat: 49.0921805, lng: 20.380013 },
    { lat: 49.0936957, lng: 20.3833666 },
    { lat: 49.0944764, lng: 20.3848791 },
    { lat: 49.0959828, lng: 20.3874135 },
    { lat: 49.0962915, lng: 20.3882129 },
    { lat: 49.0966782, lng: 20.3895322 },
    { lat: 49.0969549, lng: 20.3902288 },
    { lat: 49.0976674, lng: 20.3917029 },
    { lat: 49.0979851, lng: 20.3921832 },
    { lat: 49.098309, lng: 20.3928179 },
    { lat: 49.0985794, lng: 20.3930613 },
    { lat: 49.0994866, lng: 20.3958309 },
    { lat: 49.1002888, lng: 20.3973246 },
    { lat: 49.1007688, lng: 20.3985186 },
    { lat: 49.100777, lng: 20.3985294 },
    { lat: 49.1009236, lng: 20.3982969 },
    { lat: 49.1010028, lng: 20.3981897 },
    { lat: 49.1012933, lng: 20.3980408 },
    { lat: 49.1014524, lng: 20.3981112 },
    { lat: 49.1015195, lng: 20.3979763 },
    { lat: 49.1017699, lng: 20.3979297 },
    { lat: 49.1018067, lng: 20.3978611 },
    { lat: 49.1019779, lng: 20.3978043 },
    { lat: 49.1021813, lng: 20.3978226 },
    { lat: 49.1023428, lng: 20.3976891 },
    { lat: 49.1024034, lng: 20.3977448 },
    { lat: 49.102471, lng: 20.3977173 },
    { lat: 49.1025038, lng: 20.3978093 },
    { lat: 49.1028438, lng: 20.3977466 },
    { lat: 49.1029761, lng: 20.3977895 },
    { lat: 49.1030068, lng: 20.3977397 },
    { lat: 49.1031691, lng: 20.3978637 },
    { lat: 49.1033581, lng: 20.3978032 },
    { lat: 49.1033895, lng: 20.3977397 },
    { lat: 49.1037171, lng: 20.397769 },
    { lat: 49.1039196, lng: 20.3976146 },
    { lat: 49.1040021, lng: 20.3972961 },
    { lat: 49.1041141, lng: 20.3973089 },
    { lat: 49.1042512, lng: 20.3971537 },
    { lat: 49.1042013, lng: 20.3970891 },
    { lat: 49.1042578, lng: 20.3969731 },
    { lat: 49.1043911, lng: 20.3969858 },
    { lat: 49.1043741, lng: 20.3969219 },
    { lat: 49.1044722, lng: 20.3968403 },
    { lat: 49.1044969, lng: 20.3966897 },
    { lat: 49.1050933, lng: 20.3962367 },
    { lat: 49.1054386, lng: 20.3960797 },
    { lat: 49.1058887, lng: 20.3956871 },
    { lat: 49.1071448, lng: 20.3961759 },
    { lat: 49.108438, lng: 20.3956305 },
    { lat: 49.1101241, lng: 20.3955017 },
    { lat: 49.1115725, lng: 20.3966051 },
    { lat: 49.1122641, lng: 20.3969117 },
    { lat: 49.1132792, lng: 20.3971613 },
    { lat: 49.1135006, lng: 20.3972999 },
    { lat: 49.1136075, lng: 20.3975065 },
    { lat: 49.1138332, lng: 20.3985274 },
    { lat: 49.1137756, lng: 20.3993811 },
    { lat: 49.1135727, lng: 20.4003195 },
    { lat: 49.1136729, lng: 20.4004965 },
    { lat: 49.113604, lng: 20.4006395 },
    { lat: 49.1136804, lng: 20.4006949 },
    { lat: 49.1136136, lng: 20.4008924 },
    { lat: 49.1136359, lng: 20.4010942 },
    { lat: 49.1137868, lng: 20.4012149 },
    { lat: 49.1138106, lng: 20.4013095 },
    { lat: 49.1137415, lng: 20.4017696 },
    { lat: 49.1138699, lng: 20.4019196 },
    { lat: 49.1138447, lng: 20.4021443 },
    { lat: 49.1138799, lng: 20.4021516 },
    { lat: 49.113914, lng: 20.4020415 },
    { lat: 49.1139951, lng: 20.4020732 },
    { lat: 49.1138858, lng: 20.4024548 },
    { lat: 49.113928, lng: 20.4024723 },
    { lat: 49.114041, lng: 20.4023365 },
    { lat: 49.1140938, lng: 20.4024789 },
    { lat: 49.1139916, lng: 20.402544 },
    { lat: 49.1139255, lng: 20.4027313 },
    { lat: 49.1140798, lng: 20.4028553 },
    { lat: 49.1140414, lng: 20.4030226 },
    { lat: 49.1141965, lng: 20.403106 },
    { lat: 49.1143058, lng: 20.4033199 },
    { lat: 49.1142009, lng: 20.4034025 },
    { lat: 49.1142228, lng: 20.4034764 },
    { lat: 49.1143663, lng: 20.4034591 },
    { lat: 49.114386, lng: 20.4035066 },
    { lat: 49.1143165, lng: 20.4039559 },
    { lat: 49.1145065, lng: 20.4039761 },
    { lat: 49.1146218, lng: 20.4041669 },
    { lat: 49.1146333, lng: 20.4043819 },
    { lat: 49.114705, lng: 20.4045179 },
    { lat: 49.1146358, lng: 20.4048633 },
    { lat: 49.1147863, lng: 20.4048187 },
    { lat: 49.1153708, lng: 20.4051827 },
    { lat: 49.1155024, lng: 20.405 },
    { lat: 49.1157279, lng: 20.4048867 },
    { lat: 49.1159392, lng: 20.4049712 },
    { lat: 49.1160955, lng: 20.4051418 },
    { lat: 49.1162973, lng: 20.4050568 },
    { lat: 49.1168376, lng: 20.4051465 },
    { lat: 49.1173223, lng: 20.4051293 },
    { lat: 49.1180584, lng: 20.4053577 },
    { lat: 49.1193819, lng: 20.4054363 },
    { lat: 49.1195828, lng: 20.4053269 },
    { lat: 49.1195397, lng: 20.4050266 },
    { lat: 49.1195638, lng: 20.4047721 },
    { lat: 49.1197876, lng: 20.4049426 },
    { lat: 49.1198068, lng: 20.4051181 },
    { lat: 49.119766, lng: 20.4053176 },
    { lat: 49.119809, lng: 20.4054446 },
    { lat: 49.1199221, lng: 20.4054289 },
    { lat: 49.1202999, lng: 20.4050567 },
    { lat: 49.120538, lng: 20.4049846 },
    { lat: 49.1205707, lng: 20.4048685 },
    { lat: 49.1204311, lng: 20.4045415 },
    { lat: 49.1203225, lng: 20.4045579 },
    { lat: 49.1203092, lng: 20.4045073 },
    { lat: 49.1203384, lng: 20.4044127 },
    { lat: 49.1205976, lng: 20.404273 },
    { lat: 49.1206667, lng: 20.4042849 },
    { lat: 49.1207755, lng: 20.4044461 },
    { lat: 49.1210461, lng: 20.4044901 },
    { lat: 49.1212941, lng: 20.4046469 },
    { lat: 49.1214036, lng: 20.4045338 },
    { lat: 49.1214792, lng: 20.4045691 },
    { lat: 49.1216685, lng: 20.4050194 },
    { lat: 49.1217387, lng: 20.4050848 },
    { lat: 49.1217389, lng: 20.4054507 },
    { lat: 49.121848, lng: 20.405532 },
    { lat: 49.1223462, lng: 20.4051322 },
    { lat: 49.1224756, lng: 20.4052783 },
    { lat: 49.1225189, lng: 20.4057044 },
    { lat: 49.1225938, lng: 20.4057269 },
    { lat: 49.1227587, lng: 20.40562 },
    { lat: 49.1228125, lng: 20.4055207 },
    { lat: 49.1228738, lng: 20.405539 },
    { lat: 49.1229195, lng: 20.4057597 },
    { lat: 49.1231148, lng: 20.4059509 },
    { lat: 49.1229794, lng: 20.4062429 },
    { lat: 49.1231334, lng: 20.406527 },
    { lat: 49.1232375, lng: 20.4066052 },
    { lat: 49.1231633, lng: 20.4069931 },
    { lat: 49.1229976, lng: 20.4073151 },
    { lat: 49.1230287, lng: 20.4073925 },
    { lat: 49.1232259, lng: 20.4074237 },
    { lat: 49.1233706, lng: 20.4072322 },
    { lat: 49.1234799, lng: 20.4072967 },
    { lat: 49.1235443, lng: 20.4075088 },
    { lat: 49.1236, lng: 20.4074342 },
    { lat: 49.1237014, lng: 20.4070456 },
    { lat: 49.1238935, lng: 20.4069809 },
    { lat: 49.123962, lng: 20.4070313 },
    { lat: 49.1240201, lng: 20.4072005 },
    { lat: 49.1241721, lng: 20.4071728 },
    { lat: 49.1241729, lng: 20.4072493 },
    { lat: 49.124027, lng: 20.4075023 },
    { lat: 49.124025, lng: 20.4076545 },
    { lat: 49.1241588, lng: 20.4078904 },
    { lat: 49.1243098, lng: 20.4079217 },
    { lat: 49.1244652, lng: 20.4078645 },
    { lat: 49.124523, lng: 20.407932 },
    { lat: 49.1244304, lng: 20.4082209 },
    { lat: 49.1241582, lng: 20.408315 },
    { lat: 49.1240186, lng: 20.4084973 },
    { lat: 49.1239668, lng: 20.4087934 },
    { lat: 49.1243786, lng: 20.409021 },
    { lat: 49.12461, lng: 20.4092735 },
    { lat: 49.1244956, lng: 20.4097024 },
    { lat: 49.1242007, lng: 20.4101756 },
    { lat: 49.1242209, lng: 20.4106548 },
    { lat: 49.1243049, lng: 20.4108207 },
    { lat: 49.1245741, lng: 20.4110321 },
    { lat: 49.1248711, lng: 20.4111134 },
    { lat: 49.1253236, lng: 20.4107515 },
    { lat: 49.1255709, lng: 20.4109209 },
    { lat: 49.1255919, lng: 20.4110262 },
    { lat: 49.1253879, lng: 20.4112343 },
    { lat: 49.1253574, lng: 20.4113765 },
    { lat: 49.1254706, lng: 20.4117796 },
    { lat: 49.1259439, lng: 20.4124771 },
    { lat: 49.1265447, lng: 20.4130264 },
    { lat: 49.1268838, lng: 20.4120507 },
    { lat: 49.1268416, lng: 20.4117981 },
    { lat: 49.1270263, lng: 20.4113544 },
    { lat: 49.1270475, lng: 20.4107877 },
    { lat: 49.1269522, lng: 20.4101145 },
    { lat: 49.1274339, lng: 20.4099886 },
    { lat: 49.1275146, lng: 20.4091489 },
    { lat: 49.1273951, lng: 20.4089675 },
    { lat: 49.1272469, lng: 20.4084111 },
    { lat: 49.1269527, lng: 20.4076356 },
    { lat: 49.1270396, lng: 20.4071638 },
    { lat: 49.1270244, lng: 20.4069139 },
    { lat: 49.1267902, lng: 20.4057514 },
    { lat: 49.1268297, lng: 20.405731 },
    { lat: 49.126476, lng: 20.4046844 },
    { lat: 49.1267012, lng: 20.4040372 },
    { lat: 49.126803, lng: 20.4035422 },
    { lat: 49.1267511, lng: 20.4024427 },
    { lat: 49.1268211, lng: 20.4021489 },
    { lat: 49.1268406, lng: 20.4015702 },
    { lat: 49.1270414, lng: 20.4003039 },
    { lat: 49.127061, lng: 20.399874 },
    { lat: 49.1269985, lng: 20.3996207 },
    { lat: 49.1268181, lng: 20.3992879 },
    { lat: 49.1266705, lng: 20.3992229 },
    { lat: 49.1264187, lng: 20.399413 },
    { lat: 49.1261516, lng: 20.3994945 },
    { lat: 49.1257452, lng: 20.3993786 },
    { lat: 49.125558, lng: 20.3991331 },
    { lat: 49.12512, lng: 20.397976 },
    { lat: 49.1248389, lng: 20.3964493 },
    { lat: 49.1246375, lng: 20.3957132 },
    { lat: 49.1252986, lng: 20.3950681 },
    { lat: 49.1255278, lng: 20.3945492 },
    { lat: 49.1261626, lng: 20.3936598 },
    { lat: 49.1274962, lng: 20.3927819 },
    { lat: 49.1283159, lng: 20.3921346 },
    { lat: 49.1298377, lng: 20.3906403 },
    { lat: 49.1303634, lng: 20.3918809 },
  ],
  Bušovce: [
    { lat: 49.2094505, lng: 20.512318 },
    { lat: 49.2094843, lng: 20.512377 },
    { lat: 49.2099441, lng: 20.5129377 },
    { lat: 49.2107327, lng: 20.5147803 },
    { lat: 49.2109286, lng: 20.5150847 },
    { lat: 49.211337, lng: 20.5155327 },
    { lat: 49.2117906, lng: 20.515498 },
    { lat: 49.2120243, lng: 20.515547 },
    { lat: 49.2120391, lng: 20.5152775 },
    { lat: 49.2130188, lng: 20.5154103 },
    { lat: 49.2130506, lng: 20.5152948 },
    { lat: 49.2140942, lng: 20.5154156 },
    { lat: 49.2141127, lng: 20.5152189 },
    { lat: 49.2143245, lng: 20.5147012 },
    { lat: 49.2145956, lng: 20.5142687 },
    { lat: 49.2147097, lng: 20.5141573 },
    { lat: 49.2149752, lng: 20.5140891 },
    { lat: 49.2154881, lng: 20.513624 },
    { lat: 49.2163881, lng: 20.5126627 },
    { lat: 49.2182739, lng: 20.5113589 },
    { lat: 49.2189381, lng: 20.5112678 },
    { lat: 49.2191365, lng: 20.5111776 },
    { lat: 49.2207908, lng: 20.5098224 },
    { lat: 49.2219919, lng: 20.5090896 },
    { lat: 49.2220503, lng: 20.5089351 },
    { lat: 49.2231648, lng: 20.5079303 },
    { lat: 49.223692, lng: 20.5086423 },
    { lat: 49.2239119, lng: 20.5087692 },
    { lat: 49.2248618, lng: 20.507396 },
    { lat: 49.2249623, lng: 20.5077994 },
    { lat: 49.2253195, lng: 20.5074098 },
    { lat: 49.2260286, lng: 20.5070158 },
    { lat: 49.2261408, lng: 20.5068527 },
    { lat: 49.2261855, lng: 20.5066576 },
    { lat: 49.226129, lng: 20.5064078 },
    { lat: 49.226176, lng: 20.5060389 },
    { lat: 49.2260947, lng: 20.5059699 },
    { lat: 49.2262028, lng: 20.5056666 },
    { lat: 49.2262552, lng: 20.505164 },
    { lat: 49.2262158, lng: 20.5049221 },
    { lat: 49.2262809, lng: 20.5045523 },
    { lat: 49.2262614, lng: 20.5036946 },
    { lat: 49.226447, lng: 20.502107 },
    { lat: 49.2266469, lng: 20.5020963 },
    { lat: 49.2266907, lng: 20.5020939 },
    { lat: 49.2268063, lng: 20.5007176 },
    { lat: 49.2268994, lng: 20.4996092 },
    { lat: 49.227032, lng: 20.4985427 },
    { lat: 49.2268396, lng: 20.4977233 },
    { lat: 49.2268813, lng: 20.4966679 },
    { lat: 49.22688, lng: 20.4966633 },
    { lat: 49.2261672, lng: 20.4934706 },
    { lat: 49.2268622, lng: 20.4927279 },
    { lat: 49.2269051, lng: 20.4914548 },
    { lat: 49.2271629, lng: 20.4895425 },
    { lat: 49.2272398, lng: 20.4883742 },
    { lat: 49.2272131, lng: 20.4879284 },
    { lat: 49.227095, lng: 20.4875961 },
    { lat: 49.2265998, lng: 20.4869982 },
    { lat: 49.226464, lng: 20.4867337 },
    { lat: 49.2263442, lng: 20.4863605 },
    { lat: 49.2263233, lng: 20.4859462 },
    { lat: 49.2262201, lng: 20.4855366 },
    { lat: 49.2250774, lng: 20.4826018 },
    { lat: 49.2256048, lng: 20.4821296 },
    { lat: 49.2253607, lng: 20.4815619 },
    { lat: 49.2250514, lng: 20.4810855 },
    { lat: 49.2247685, lng: 20.4807434 },
    { lat: 49.2245289, lng: 20.4805672 },
    { lat: 49.2248359, lng: 20.4800442 },
    { lat: 49.224767, lng: 20.4793096 },
    { lat: 49.2248575, lng: 20.478295 },
    { lat: 49.2249645, lng: 20.4777213 },
    { lat: 49.2255957, lng: 20.4754221 },
    { lat: 49.2258197, lng: 20.4748422 },
    { lat: 49.226692, lng: 20.471942 },
    { lat: 49.226342, lng: 20.4709452 },
    { lat: 49.2267129, lng: 20.470271 },
    { lat: 49.2267659, lng: 20.4700276 },
    { lat: 49.2265205, lng: 20.4694262 },
    { lat: 49.2263515, lng: 20.4696321 },
    { lat: 49.226185, lng: 20.4690357 },
    { lat: 49.2264692, lng: 20.4687808 },
    { lat: 49.2264427, lng: 20.4685501 },
    { lat: 49.2267229, lng: 20.468548 },
    { lat: 49.2268311, lng: 20.4682501 },
    { lat: 49.2269632, lng: 20.4683178 },
    { lat: 49.2270765, lng: 20.4680029 },
    { lat: 49.2272556, lng: 20.4681306 },
    { lat: 49.2273041, lng: 20.4680724 },
    { lat: 49.2272108, lng: 20.4675636 },
    { lat: 49.2273453, lng: 20.4673934 },
    { lat: 49.2275913, lng: 20.4676501 },
    { lat: 49.2276087, lng: 20.4679097 },
    { lat: 49.2276931, lng: 20.4680263 },
    { lat: 49.2277473, lng: 20.4680313 },
    { lat: 49.227855, lng: 20.4679019 },
    { lat: 49.227912, lng: 20.4677666 },
    { lat: 49.227893, lng: 20.4674725 },
    { lat: 49.2281182, lng: 20.4673499 },
    { lat: 49.2280996, lng: 20.4670932 },
    { lat: 49.228065, lng: 20.4669485 },
    { lat: 49.2280048, lng: 20.4669351 },
    { lat: 49.2277081, lng: 20.4664065 },
    { lat: 49.2277929, lng: 20.4662079 },
    { lat: 49.2279648, lng: 20.4661374 },
    { lat: 49.2280864, lng: 20.4659572 },
    { lat: 49.2284547, lng: 20.4659002 },
    { lat: 49.2284897, lng: 20.4654545 },
    { lat: 49.2281191, lng: 20.4653531 },
    { lat: 49.2281534, lng: 20.4648271 },
    { lat: 49.2285173, lng: 20.4650443 },
    { lat: 49.2286499, lng: 20.464406 },
    { lat: 49.2279834, lng: 20.463655 },
    { lat: 49.2282231, lng: 20.463289 },
    { lat: 49.2281285, lng: 20.4631705 },
    { lat: 49.2281745, lng: 20.4630436 },
    { lat: 49.2280844, lng: 20.4629236 },
    { lat: 49.2281558, lng: 20.4627254 },
    { lat: 49.2287655, lng: 20.4628596 },
    { lat: 49.2287526, lng: 20.462213 },
    { lat: 49.2284831, lng: 20.4603688 },
    { lat: 49.2288098, lng: 20.4597679 },
    { lat: 49.2294181, lng: 20.4602317 },
    { lat: 49.2294337, lng: 20.4596526 },
    { lat: 49.2296702, lng: 20.4592645 },
    { lat: 49.2296593, lng: 20.4590922 },
    { lat: 49.2298804, lng: 20.4589882 },
    { lat: 49.2299884, lng: 20.4588344 },
    { lat: 49.2299987, lng: 20.4587196 },
    { lat: 49.2302035, lng: 20.4585368 },
    { lat: 49.2300851, lng: 20.4583169 },
    { lat: 49.2302109, lng: 20.4581284 },
    { lat: 49.2302649, lng: 20.4576813 },
    { lat: 49.2304945, lng: 20.4573493 },
    { lat: 49.2306604, lng: 20.4573236 },
    { lat: 49.2306191, lng: 20.4570019 },
    { lat: 49.2308218, lng: 20.4567386 },
    { lat: 49.2309559, lng: 20.4567683 },
    { lat: 49.2310325, lng: 20.45662 },
    { lat: 49.2312984, lng: 20.4567467 },
    { lat: 49.2313482, lng: 20.4564206 },
    { lat: 49.2317323, lng: 20.4562761 },
    { lat: 49.2321057, lng: 20.4564514 },
    { lat: 49.2322385, lng: 20.4564189 },
    { lat: 49.2324451, lng: 20.4566426 },
    { lat: 49.2325902, lng: 20.4567002 },
    { lat: 49.2326684, lng: 20.456653 },
    { lat: 49.2328804, lng: 20.456752 },
    { lat: 49.2330789, lng: 20.4563384 },
    { lat: 49.2331935, lng: 20.4566072 },
    { lat: 49.2334143, lng: 20.4562795 },
    { lat: 49.2333802, lng: 20.4560545 },
    { lat: 49.2334848, lng: 20.4559625 },
    { lat: 49.2337238, lng: 20.4560028 },
    { lat: 49.2338041, lng: 20.455896 },
    { lat: 49.2337835, lng: 20.4558265 },
    { lat: 49.2338286, lng: 20.455708 },
    { lat: 49.2337752, lng: 20.4553888 },
    { lat: 49.2344658, lng: 20.4545359 },
    { lat: 49.2348218, lng: 20.4547815 },
    { lat: 49.2349899, lng: 20.4544873 },
    { lat: 49.2350714, lng: 20.4544719 },
    { lat: 49.2351574, lng: 20.4545699 },
    { lat: 49.2352594, lng: 20.4544082 },
    { lat: 49.2356003, lng: 20.4545218 },
    { lat: 49.2356066, lng: 20.454633 },
    { lat: 49.2356975, lng: 20.4545977 },
    { lat: 49.2357824, lng: 20.454516 },
    { lat: 49.2357443, lng: 20.4544311 },
    { lat: 49.2357901, lng: 20.4543622 },
    { lat: 49.2365303, lng: 20.4539859 },
    { lat: 49.2367045, lng: 20.4537942 },
    { lat: 49.2367935, lng: 20.4538592 },
    { lat: 49.2373137, lng: 20.453381 },
    { lat: 49.2375304, lng: 20.4534543 },
    { lat: 49.2375959, lng: 20.4530671 },
    { lat: 49.2377541, lng: 20.4531466 },
    { lat: 49.2378501, lng: 20.4531198 },
    { lat: 49.2379041, lng: 20.4528813 },
    { lat: 49.2378806, lng: 20.4527075 },
    { lat: 49.2379333, lng: 20.4525768 },
    { lat: 49.2382645, lng: 20.4522232 },
    { lat: 49.2384285, lng: 20.4518515 },
    { lat: 49.2386103, lng: 20.4517178 },
    { lat: 49.2386554, lng: 20.4515117 },
    { lat: 49.238863, lng: 20.4514936 },
    { lat: 49.239021, lng: 20.4513835 },
    { lat: 49.2391595, lng: 20.4510828 },
    { lat: 49.2391982, lng: 20.4508799 },
    { lat: 49.2393203, lng: 20.4507135 },
    { lat: 49.239596, lng: 20.4497242 },
    { lat: 49.2398267, lng: 20.4484912 },
    { lat: 49.2401254, lng: 20.4476533 },
    { lat: 49.2400973, lng: 20.4474151 },
    { lat: 49.2401606, lng: 20.4472471 },
    { lat: 49.2401485, lng: 20.4468608 },
    { lat: 49.2402368, lng: 20.4467886 },
    { lat: 49.2385513, lng: 20.4467213 },
    { lat: 49.2364148, lng: 20.4462187 },
    { lat: 49.234427, lng: 20.4459308 },
    { lat: 49.2343917, lng: 20.4459216 },
    { lat: 49.2343925, lng: 20.4458236 },
    { lat: 49.232441, lng: 20.4455084 },
    { lat: 49.2324605, lng: 20.4452239 },
    { lat: 49.2301403, lng: 20.4448913 },
    { lat: 49.2260325, lng: 20.4445786 },
    { lat: 49.2254822, lng: 20.4445508 },
    { lat: 49.2248974, lng: 20.4446564 },
    { lat: 49.2241509, lng: 20.4446252 },
    { lat: 49.2233277, lng: 20.4444721 },
    { lat: 49.2207445, lng: 20.4436786 },
    { lat: 49.2192635, lng: 20.4435172 },
    { lat: 49.2187357, lng: 20.4436419 },
    { lat: 49.218523, lng: 20.4437643 },
    { lat: 49.2183597, lng: 20.4441506 },
    { lat: 49.2179921, lng: 20.4447028 },
    { lat: 49.2172663, lng: 20.4454355 },
    { lat: 49.2172533, lng: 20.4454478 },
    { lat: 49.2179306, lng: 20.4469674 },
    { lat: 49.2189175, lng: 20.4483343 },
    { lat: 49.2193139, lng: 20.4492976 },
    { lat: 49.2191204, lng: 20.4517604 },
    { lat: 49.2197687, lng: 20.4526293 },
    { lat: 49.2203737, lng: 20.4533007 },
    { lat: 49.2207691, lng: 20.4543269 },
    { lat: 49.2210504, lng: 20.4555874 },
    { lat: 49.2210813, lng: 20.4583666 },
    { lat: 49.2212766, lng: 20.4594946 },
    { lat: 49.2213441, lng: 20.4605162 },
    { lat: 49.2212098, lng: 20.4614972 },
    { lat: 49.2208839, lng: 20.4617536 },
    { lat: 49.2205945, lng: 20.4622031 },
    { lat: 49.2204488, lng: 20.4622806 },
    { lat: 49.2203297, lng: 20.4622585 },
    { lat: 49.2201802, lng: 20.4623861 },
    { lat: 49.2199255, lng: 20.4623493 },
    { lat: 49.2191052, lng: 20.4628724 },
    { lat: 49.2190095, lng: 20.4628433 },
    { lat: 49.2187685, lng: 20.4631427 },
    { lat: 49.2185861, lng: 20.4631996 },
    { lat: 49.2181877, lng: 20.4634789 },
    { lat: 49.2176858, lng: 20.4636015 },
    { lat: 49.2159343, lng: 20.4638218 },
    { lat: 49.2141221, lng: 20.4631673 },
    { lat: 49.2135034, lng: 20.4630321 },
    { lat: 49.2126471, lng: 20.4631932 },
    { lat: 49.2117446, lng: 20.4635365 },
    { lat: 49.2106271, lng: 20.4644196 },
    { lat: 49.2096048, lng: 20.4670389 },
    { lat: 49.2100069, lng: 20.4673179 },
    { lat: 49.2097979, lng: 20.4691635 },
    { lat: 49.2094502, lng: 20.4706809 },
    { lat: 49.2088659, lng: 20.4717306 },
    { lat: 49.2083226, lng: 20.4731666 },
    { lat: 49.2079152, lng: 20.4738937 },
    { lat: 49.2072516, lng: 20.4747547 },
    { lat: 49.2066032, lng: 20.4759617 },
    { lat: 49.2059838, lng: 20.4771087 },
    { lat: 49.2059714, lng: 20.4773449 },
    { lat: 49.2061932, lng: 20.4774095 },
    { lat: 49.2062385, lng: 20.4774733 },
    { lat: 49.2062647, lng: 20.4778159 },
    { lat: 49.2064265, lng: 20.477929 },
    { lat: 49.2063891, lng: 20.4781339 },
    { lat: 49.2064485, lng: 20.4782472 },
    { lat: 49.2064365, lng: 20.4783472 },
    { lat: 49.2066515, lng: 20.4789205 },
    { lat: 49.2066055, lng: 20.4791369 },
    { lat: 49.2066859, lng: 20.4792992 },
    { lat: 49.2066279, lng: 20.4795267 },
    { lat: 49.2067045, lng: 20.4796465 },
    { lat: 49.2066778, lng: 20.4797777 },
    { lat: 49.2070316, lng: 20.4805999 },
    { lat: 49.2069441, lng: 20.4807004 },
    { lat: 49.2068878, lng: 20.4808807 },
    { lat: 49.2069974, lng: 20.4813785 },
    { lat: 49.2070048, lng: 20.4817231 },
    { lat: 49.2069266, lng: 20.4822581 },
    { lat: 49.20698, lng: 20.4828109 },
    { lat: 49.2069248, lng: 20.4830202 },
    { lat: 49.2069661, lng: 20.4831048 },
    { lat: 49.206914, lng: 20.4832184 },
    { lat: 49.2070034, lng: 20.4835614 },
    { lat: 49.2069923, lng: 20.4838713 },
    { lat: 49.2067747, lng: 20.4840407 },
    { lat: 49.2067941, lng: 20.4842889 },
    { lat: 49.2068614, lng: 20.4844663 },
    { lat: 49.2067374, lng: 20.4845833 },
    { lat: 49.2066329, lng: 20.4845912 },
    { lat: 49.2066462, lng: 20.4854866 },
    { lat: 49.2065544, lng: 20.485563 },
    { lat: 49.2065717, lng: 20.4856656 },
    { lat: 49.2065071, lng: 20.4860147 },
    { lat: 49.206271, lng: 20.486752 },
    { lat: 49.2061513, lng: 20.4869842 },
    { lat: 49.206103, lng: 20.487563 },
    { lat: 49.2061145, lng: 20.4877201 },
    { lat: 49.2061617, lng: 20.4877203 },
    { lat: 49.2061851, lng: 20.4878608 },
    { lat: 49.2063417, lng: 20.4879553 },
    { lat: 49.2062545, lng: 20.4884907 },
    { lat: 49.2066545, lng: 20.488981 },
    { lat: 49.2066442, lng: 20.4893074 },
    { lat: 49.2063571, lng: 20.4902147 },
    { lat: 49.2062893, lng: 20.4905443 },
    { lat: 49.2062139, lng: 20.4908756 },
    { lat: 49.2061203, lng: 20.4928449 },
    { lat: 49.2062066, lng: 20.4931676 },
    { lat: 49.2067189, lng: 20.4938956 },
    { lat: 49.2075077, lng: 20.4945795 },
    { lat: 49.2076668, lng: 20.4946525 },
    { lat: 49.2077074, lng: 20.4948359 },
    { lat: 49.2078557, lng: 20.4950373 },
    { lat: 49.2083963, lng: 20.4954979 },
    { lat: 49.2089806, lng: 20.4957441 },
    { lat: 49.2102845, lng: 20.4960821 },
    { lat: 49.2105696, lng: 20.4962116 },
    { lat: 49.2120414, lng: 20.4959496 },
    { lat: 49.2132406, lng: 20.4955009 },
    { lat: 49.2149497, lng: 20.4952577 },
    { lat: 49.2149455, lng: 20.495665 },
    { lat: 49.2155206, lng: 20.4962051 },
    { lat: 49.21547, lng: 20.4966326 },
    { lat: 49.2155377, lng: 20.4967294 },
    { lat: 49.2155047, lng: 20.4968046 },
    { lat: 49.2153916, lng: 20.4970377 },
    { lat: 49.2151367, lng: 20.4972628 },
    { lat: 49.2147039, lng: 20.4979546 },
    { lat: 49.2146852, lng: 20.4982054 },
    { lat: 49.2142723, lng: 20.4991522 },
    { lat: 49.2142003, lng: 20.499431 },
    { lat: 49.2138002, lng: 20.4999661 },
    { lat: 49.2137221, lng: 20.5001943 },
    { lat: 49.2127143, lng: 20.5008561 },
    { lat: 49.2123369, lng: 20.5009388 },
    { lat: 49.2119966, lng: 20.5012681 },
    { lat: 49.2114425, lng: 20.5014726 },
    { lat: 49.2107723, lng: 20.5021607 },
    { lat: 49.2106828, lng: 20.502482 },
    { lat: 49.2107794, lng: 20.5032619 },
    { lat: 49.2106745, lng: 20.5042889 },
    { lat: 49.2103729, lng: 20.5050254 },
    { lat: 49.2107234, lng: 20.5053092 },
    { lat: 49.2104468, lng: 20.5057403 },
    { lat: 49.2102691, lng: 20.5062352 },
    { lat: 49.2102057, lng: 20.5069832 },
    { lat: 49.210185, lng: 20.5081325 },
    { lat: 49.2099759, lng: 20.5099024 },
    { lat: 49.2097267, lng: 20.5107412 },
    { lat: 49.2093877, lng: 20.5114358 },
    { lat: 49.2094505, lng: 20.512318 },
  ],
  Podhorany: [
    { lat: 49.2657375, lng: 20.453442 },
    { lat: 49.2630899, lng: 20.4539034 },
    { lat: 49.262266, lng: 20.4538457 },
    { lat: 49.2612388, lng: 20.4541654 },
    { lat: 49.2595479, lng: 20.4550402 },
    { lat: 49.2588319, lng: 20.4560907 },
    { lat: 49.2586245, lng: 20.4564743 },
    { lat: 49.2584945, lng: 20.4569741 },
    { lat: 49.2582238, lng: 20.4574749 },
    { lat: 49.2580563, lng: 20.4576626 },
    { lat: 49.2578743, lng: 20.4576723 },
    { lat: 49.2574288, lng: 20.4581636 },
    { lat: 49.2567389, lng: 20.458501 },
    { lat: 49.2565279, lng: 20.4588769 },
    { lat: 49.2565352, lng: 20.4590089 },
    { lat: 49.2563043, lng: 20.4595795 },
    { lat: 49.2558088, lng: 20.4604787 },
    { lat: 49.2557514, lng: 20.4605075 },
    { lat: 49.2556708, lng: 20.4607632 },
    { lat: 49.2554084, lng: 20.4610651 },
    { lat: 49.2551933, lng: 20.4615142 },
    { lat: 49.2552017, lng: 20.461649 },
    { lat: 49.2550534, lng: 20.4618673 },
    { lat: 49.2549262, lng: 20.4618662 },
    { lat: 49.2546985, lng: 20.4624317 },
    { lat: 49.2543644, lng: 20.4628299 },
    { lat: 49.254267, lng: 20.4632979 },
    { lat: 49.2542816, lng: 20.463417 },
    { lat: 49.2542044, lng: 20.463547 },
    { lat: 49.252809, lng: 20.4612756 },
    { lat: 49.2524569, lng: 20.460502 },
    { lat: 49.2520523, lng: 20.4599797 },
    { lat: 49.2507889, lng: 20.4576239 },
    { lat: 49.2503733, lng: 20.4565989 },
    { lat: 49.2502323, lng: 20.4560158 },
    { lat: 49.2499577, lng: 20.4553926 },
    { lat: 49.2497333, lng: 20.4546273 },
    { lat: 49.2492242, lng: 20.4538725 },
    { lat: 49.2491385, lng: 20.4536551 },
    { lat: 49.2491025, lng: 20.4536842 },
    { lat: 49.2490003, lng: 20.4535439 },
    { lat: 49.2476076, lng: 20.4514941 },
    { lat: 49.246239, lng: 20.4500073 },
    { lat: 49.2454567, lng: 20.449292 },
    { lat: 49.2433309, lng: 20.4478135 },
    { lat: 49.2419758, lng: 20.446952 },
    { lat: 49.2412046, lng: 20.4465626 },
    { lat: 49.2403913, lng: 20.4463409 },
    { lat: 49.2403127, lng: 20.4467992 },
    { lat: 49.2402743, lng: 20.4467939 },
    { lat: 49.2402368, lng: 20.4467886 },
    { lat: 49.2401485, lng: 20.4468608 },
    { lat: 49.2401606, lng: 20.4472471 },
    { lat: 49.2400973, lng: 20.4474151 },
    { lat: 49.2401254, lng: 20.4476533 },
    { lat: 49.2398267, lng: 20.4484912 },
    { lat: 49.239596, lng: 20.4497242 },
    { lat: 49.2393203, lng: 20.4507135 },
    { lat: 49.2391982, lng: 20.4508799 },
    { lat: 49.2391595, lng: 20.4510828 },
    { lat: 49.239021, lng: 20.4513835 },
    { lat: 49.238863, lng: 20.4514936 },
    { lat: 49.2386554, lng: 20.4515117 },
    { lat: 49.2386103, lng: 20.4517178 },
    { lat: 49.2384285, lng: 20.4518515 },
    { lat: 49.2382645, lng: 20.4522232 },
    { lat: 49.2379333, lng: 20.4525768 },
    { lat: 49.2378806, lng: 20.4527075 },
    { lat: 49.2379041, lng: 20.4528813 },
    { lat: 49.2378501, lng: 20.4531198 },
    { lat: 49.2377541, lng: 20.4531466 },
    { lat: 49.2375959, lng: 20.4530671 },
    { lat: 49.2375304, lng: 20.4534543 },
    { lat: 49.2373137, lng: 20.453381 },
    { lat: 49.2367935, lng: 20.4538592 },
    { lat: 49.2367045, lng: 20.4537942 },
    { lat: 49.2365303, lng: 20.4539859 },
    { lat: 49.2357901, lng: 20.4543622 },
    { lat: 49.2357443, lng: 20.4544311 },
    { lat: 49.2357824, lng: 20.454516 },
    { lat: 49.2356975, lng: 20.4545977 },
    { lat: 49.2356066, lng: 20.454633 },
    { lat: 49.2356003, lng: 20.4545218 },
    { lat: 49.2352594, lng: 20.4544082 },
    { lat: 49.2351574, lng: 20.4545699 },
    { lat: 49.2350714, lng: 20.4544719 },
    { lat: 49.2349899, lng: 20.4544873 },
    { lat: 49.2348218, lng: 20.4547815 },
    { lat: 49.2344658, lng: 20.4545359 },
    { lat: 49.2337752, lng: 20.4553888 },
    { lat: 49.2338286, lng: 20.455708 },
    { lat: 49.2337835, lng: 20.4558265 },
    { lat: 49.2338041, lng: 20.455896 },
    { lat: 49.2337238, lng: 20.4560028 },
    { lat: 49.2334848, lng: 20.4559625 },
    { lat: 49.2333802, lng: 20.4560545 },
    { lat: 49.2334143, lng: 20.4562795 },
    { lat: 49.2331935, lng: 20.4566072 },
    { lat: 49.2330789, lng: 20.4563384 },
    { lat: 49.2328804, lng: 20.456752 },
    { lat: 49.2326684, lng: 20.456653 },
    { lat: 49.2325902, lng: 20.4567002 },
    { lat: 49.2324451, lng: 20.4566426 },
    { lat: 49.2322385, lng: 20.4564189 },
    { lat: 49.2321057, lng: 20.4564514 },
    { lat: 49.2317323, lng: 20.4562761 },
    { lat: 49.2313482, lng: 20.4564206 },
    { lat: 49.2312984, lng: 20.4567467 },
    { lat: 49.2310325, lng: 20.45662 },
    { lat: 49.2309559, lng: 20.4567683 },
    { lat: 49.2308218, lng: 20.4567386 },
    { lat: 49.2306191, lng: 20.4570019 },
    { lat: 49.2306604, lng: 20.4573236 },
    { lat: 49.2304945, lng: 20.4573493 },
    { lat: 49.2302649, lng: 20.4576813 },
    { lat: 49.2302109, lng: 20.4581284 },
    { lat: 49.2300851, lng: 20.4583169 },
    { lat: 49.2302035, lng: 20.4585368 },
    { lat: 49.2299987, lng: 20.4587196 },
    { lat: 49.2299884, lng: 20.4588344 },
    { lat: 49.2298804, lng: 20.4589882 },
    { lat: 49.2296593, lng: 20.4590922 },
    { lat: 49.2296702, lng: 20.4592645 },
    { lat: 49.2294337, lng: 20.4596526 },
    { lat: 49.2294181, lng: 20.4602317 },
    { lat: 49.2288098, lng: 20.4597679 },
    { lat: 49.2284831, lng: 20.4603688 },
    { lat: 49.2287526, lng: 20.462213 },
    { lat: 49.2287655, lng: 20.4628596 },
    { lat: 49.2281558, lng: 20.4627254 },
    { lat: 49.2280844, lng: 20.4629236 },
    { lat: 49.2281745, lng: 20.4630436 },
    { lat: 49.2281285, lng: 20.4631705 },
    { lat: 49.2282231, lng: 20.463289 },
    { lat: 49.2279834, lng: 20.463655 },
    { lat: 49.2286499, lng: 20.464406 },
    { lat: 49.2285173, lng: 20.4650443 },
    { lat: 49.2281534, lng: 20.4648271 },
    { lat: 49.2281191, lng: 20.4653531 },
    { lat: 49.2284897, lng: 20.4654545 },
    { lat: 49.2284547, lng: 20.4659002 },
    { lat: 49.2280864, lng: 20.4659572 },
    { lat: 49.2279648, lng: 20.4661374 },
    { lat: 49.2277929, lng: 20.4662079 },
    { lat: 49.2277081, lng: 20.4664065 },
    { lat: 49.2280048, lng: 20.4669351 },
    { lat: 49.228065, lng: 20.4669485 },
    { lat: 49.2280996, lng: 20.4670932 },
    { lat: 49.2281182, lng: 20.4673499 },
    { lat: 49.227893, lng: 20.4674725 },
    { lat: 49.227912, lng: 20.4677666 },
    { lat: 49.227855, lng: 20.4679019 },
    { lat: 49.2277473, lng: 20.4680313 },
    { lat: 49.2276931, lng: 20.4680263 },
    { lat: 49.2276087, lng: 20.4679097 },
    { lat: 49.2275913, lng: 20.4676501 },
    { lat: 49.2273453, lng: 20.4673934 },
    { lat: 49.2272108, lng: 20.4675636 },
    { lat: 49.2273041, lng: 20.4680724 },
    { lat: 49.2272556, lng: 20.4681306 },
    { lat: 49.2270765, lng: 20.4680029 },
    { lat: 49.2269632, lng: 20.4683178 },
    { lat: 49.2268311, lng: 20.4682501 },
    { lat: 49.2267229, lng: 20.468548 },
    { lat: 49.2264427, lng: 20.4685501 },
    { lat: 49.2264692, lng: 20.4687808 },
    { lat: 49.226185, lng: 20.4690357 },
    { lat: 49.2263515, lng: 20.4696321 },
    { lat: 49.2265205, lng: 20.4694262 },
    { lat: 49.2267659, lng: 20.4700276 },
    { lat: 49.2267129, lng: 20.470271 },
    { lat: 49.226342, lng: 20.4709452 },
    { lat: 49.226692, lng: 20.471942 },
    { lat: 49.2258197, lng: 20.4748422 },
    { lat: 49.2255957, lng: 20.4754221 },
    { lat: 49.2249645, lng: 20.4777213 },
    { lat: 49.2248575, lng: 20.478295 },
    { lat: 49.224767, lng: 20.4793096 },
    { lat: 49.2248359, lng: 20.4800442 },
    { lat: 49.2245289, lng: 20.4805672 },
    { lat: 49.2247685, lng: 20.4807434 },
    { lat: 49.2250514, lng: 20.4810855 },
    { lat: 49.2253607, lng: 20.4815619 },
    { lat: 49.2256048, lng: 20.4821296 },
    { lat: 49.2250774, lng: 20.4826018 },
    { lat: 49.2262201, lng: 20.4855366 },
    { lat: 49.2263233, lng: 20.4859462 },
    { lat: 49.2263442, lng: 20.4863605 },
    { lat: 49.226464, lng: 20.4867337 },
    { lat: 49.2265998, lng: 20.4869982 },
    { lat: 49.227095, lng: 20.4875961 },
    { lat: 49.2272131, lng: 20.4879284 },
    { lat: 49.2272398, lng: 20.4883742 },
    { lat: 49.2271629, lng: 20.4895425 },
    { lat: 49.2269051, lng: 20.4914548 },
    { lat: 49.2268622, lng: 20.4927279 },
    { lat: 49.2261672, lng: 20.4934706 },
    { lat: 49.22688, lng: 20.4966633 },
    { lat: 49.2268813, lng: 20.4966679 },
    { lat: 49.2272511, lng: 20.4967393 },
    { lat: 49.2295167, lng: 20.4964519 },
    { lat: 49.2299348, lng: 20.4956142 },
    { lat: 49.2302263, lng: 20.495371 },
    { lat: 49.2303063, lng: 20.4954041 },
    { lat: 49.2304367, lng: 20.4952736 },
    { lat: 49.2305955, lng: 20.4952757 },
    { lat: 49.2308726, lng: 20.4949736 },
    { lat: 49.2320612, lng: 20.4951802 },
    { lat: 49.2332877, lng: 20.4958602 },
    { lat: 49.2337575, lng: 20.4963574 },
    { lat: 49.2340455, lng: 20.4976297 },
    { lat: 49.2349338, lng: 20.4980515 },
    { lat: 49.2353012, lng: 20.498335 },
    { lat: 49.2356095, lng: 20.4983915 },
    { lat: 49.2357799, lng: 20.4980997 },
    { lat: 49.2361828, lng: 20.4985178 },
    { lat: 49.2365905, lng: 20.4987116 },
    { lat: 49.2374631, lng: 20.4989529 },
    { lat: 49.2377144, lng: 20.4989034 },
    { lat: 49.238716, lng: 20.4993158 },
    { lat: 49.2388393, lng: 20.4985096 },
    { lat: 49.2390149, lng: 20.4985712 },
    { lat: 49.2395717, lng: 20.4984712 },
    { lat: 49.239722, lng: 20.4977264 },
    { lat: 49.2398088, lng: 20.4978223 },
    { lat: 49.2398421, lng: 20.4977139 },
    { lat: 49.2398094, lng: 20.4976846 },
    { lat: 49.2399774, lng: 20.4970234 },
    { lat: 49.2399835, lng: 20.4966997 },
    { lat: 49.2400611, lng: 20.4964876 },
    { lat: 49.2400359, lng: 20.4963568 },
    { lat: 49.2401222, lng: 20.4961332 },
    { lat: 49.2401889, lng: 20.4960471 },
    { lat: 49.2402517, lng: 20.496069 },
    { lat: 49.2402941, lng: 20.495851 },
    { lat: 49.2404103, lng: 20.4958867 },
    { lat: 49.24051, lng: 20.4960549 },
    { lat: 49.2413903, lng: 20.4969628 },
    { lat: 49.2421405, lng: 20.497883 },
    { lat: 49.2428263, lng: 20.4985263 },
    { lat: 49.2436943, lng: 20.4994716 },
    { lat: 49.2453509, lng: 20.5010638 },
    { lat: 49.246151, lng: 20.5019978 },
    { lat: 49.2463251, lng: 20.501621 },
    { lat: 49.2474364, lng: 20.4995333 },
    { lat: 49.2484831, lng: 20.4980356 },
    { lat: 49.2499584, lng: 20.4960752 },
    { lat: 49.2502705, lng: 20.4953822 },
    { lat: 49.2514378, lng: 20.4936216 },
    { lat: 49.2531632, lng: 20.4912418 },
    { lat: 49.2533044, lng: 20.4905837 },
    { lat: 49.2532869, lng: 20.4897833 },
    { lat: 49.2535462, lng: 20.4894917 },
    { lat: 49.2537026, lng: 20.4891242 },
    { lat: 49.2539754, lng: 20.4892851 },
    { lat: 49.2543143, lng: 20.488558 },
    { lat: 49.2545854, lng: 20.4872504 },
    { lat: 49.2547042, lng: 20.4857829 },
    { lat: 49.2550295, lng: 20.483478 },
    { lat: 49.2556008, lng: 20.4824925 },
    { lat: 49.2562777, lng: 20.4818996 },
    { lat: 49.256529, lng: 20.480666 },
    { lat: 49.2573348, lng: 20.4792376 },
    { lat: 49.2596303, lng: 20.4755152 },
    { lat: 49.2598457, lng: 20.4745914 },
    { lat: 49.2600063, lng: 20.4742783 },
    { lat: 49.2599728, lng: 20.4738295 },
    { lat: 49.259893, lng: 20.473746 },
    { lat: 49.2598857, lng: 20.4736385 },
    { lat: 49.2600331, lng: 20.4732137 },
    { lat: 49.2601611, lng: 20.4724179 },
    { lat: 49.2603259, lng: 20.472069 },
    { lat: 49.2603647, lng: 20.4718115 },
    { lat: 49.2607616, lng: 20.4708589 },
    { lat: 49.2617487, lng: 20.4696152 },
    { lat: 49.2620264, lng: 20.4688492 },
    { lat: 49.2620882, lng: 20.4683691 },
    { lat: 49.2622862, lng: 20.4675808 },
    { lat: 49.2625013, lng: 20.4648049 },
    { lat: 49.2624789, lng: 20.4638783 },
    { lat: 49.2629927, lng: 20.4621977 },
    { lat: 49.2630292, lng: 20.4610093 },
    { lat: 49.2634156, lng: 20.4599623 },
    { lat: 49.2636093, lng: 20.4585154 },
    { lat: 49.263853, lng: 20.4574768 },
    { lat: 49.2641991, lng: 20.4564982 },
    { lat: 49.2645448, lng: 20.4561983 },
    { lat: 49.2648037, lng: 20.4558051 },
    { lat: 49.2649839, lng: 20.4556899 },
    { lat: 49.2652964, lng: 20.45534 },
    { lat: 49.2653676, lng: 20.4551697 },
    { lat: 49.2654274, lng: 20.4547507 },
    { lat: 49.265606, lng: 20.4540829 },
    { lat: 49.265763, lng: 20.4536139 },
    { lat: 49.2657375, lng: 20.453442 },
  ],
  Lechnica: [
    { lat: 49.395969, lng: 20.431821 },
    { lat: 49.395476, lng: 20.4321006 },
    { lat: 49.3952103, lng: 20.4326408 },
    { lat: 49.3944909, lng: 20.4331831 },
    { lat: 49.3943137, lng: 20.4331701 },
    { lat: 49.3941476, lng: 20.4332447 },
    { lat: 49.3939348, lng: 20.4331236 },
    { lat: 49.3938066, lng: 20.4329762 },
    { lat: 49.3928676, lng: 20.430032 },
    { lat: 49.3921406, lng: 20.4308757 },
    { lat: 49.3914869, lng: 20.4315169 },
    { lat: 49.3916006, lng: 20.4309214 },
    { lat: 49.3915712, lng: 20.4297794 },
    { lat: 49.3915513, lng: 20.4296944 },
    { lat: 49.3912881, lng: 20.4296103 },
    { lat: 49.3912655, lng: 20.4296994 },
    { lat: 49.3911625, lng: 20.429625 },
    { lat: 49.3916417, lng: 20.4259268 },
    { lat: 49.3909181, lng: 20.4258771 },
    { lat: 49.3900616, lng: 20.4255189 },
    { lat: 49.3900501, lng: 20.4253956 },
    { lat: 49.3899171, lng: 20.4253029 },
    { lat: 49.3896558, lng: 20.4246968 },
    { lat: 49.3893401, lng: 20.4235479 },
    { lat: 49.3888187, lng: 20.4231563 },
    { lat: 49.3886186, lng: 20.4218766 },
    { lat: 49.3885071, lng: 20.4204987 },
    { lat: 49.3892965, lng: 20.4204756 },
    { lat: 49.3898079, lng: 20.4203911 },
    { lat: 49.3899013, lng: 20.4204468 },
    { lat: 49.3904484, lng: 20.4204498 },
    { lat: 49.3906288, lng: 20.4201896 },
    { lat: 49.390971, lng: 20.4198619 },
    { lat: 49.3903454, lng: 20.41825 },
    { lat: 49.3902776, lng: 20.4183044 },
    { lat: 49.3894092, lng: 20.4160794 },
    { lat: 49.3882466, lng: 20.412861 },
    { lat: 49.3871037, lng: 20.4084706 },
    { lat: 49.3874264, lng: 20.4080083 },
    { lat: 49.3881333, lng: 20.4074983 },
    { lat: 49.3882675, lng: 20.4068782 },
    { lat: 49.3884689, lng: 20.4062211 },
    { lat: 49.3885318, lng: 20.4061306 },
    { lat: 49.389307, lng: 20.405668 },
    { lat: 49.3888606, lng: 20.404279 },
    { lat: 49.3884192, lng: 20.4031483 },
    { lat: 49.3879151, lng: 20.4012765 },
    { lat: 49.3873502, lng: 20.3995819 },
    { lat: 49.3873534, lng: 20.3994662 },
    { lat: 49.3873681, lng: 20.398928 },
    { lat: 49.3872841, lng: 20.39546 },
    { lat: 49.3871252, lng: 20.3943209 },
    { lat: 49.3874899, lng: 20.3939651 },
    { lat: 49.3873128, lng: 20.392678 },
    { lat: 49.3872282, lng: 20.3924922 },
    { lat: 49.3867091, lng: 20.3921907 },
    { lat: 49.3865454, lng: 20.3920307 },
    { lat: 49.3863519, lng: 20.3917134 },
    { lat: 49.3858916, lng: 20.3916862 },
    { lat: 49.3857778, lng: 20.3916193 },
    { lat: 49.3855777, lng: 20.3913643 },
    { lat: 49.3854929, lng: 20.3911294 },
    { lat: 49.3852855, lng: 20.3899787 },
    { lat: 49.3851686, lng: 20.3895385 },
    { lat: 49.3837626, lng: 20.3874914 },
    { lat: 49.3835756, lng: 20.3869522 },
    { lat: 49.3833082, lng: 20.3864018 },
    { lat: 49.3831632, lng: 20.3862073 },
    { lat: 49.3828118, lng: 20.3859354 },
    { lat: 49.3827649, lng: 20.3858235 },
    { lat: 49.3827602, lng: 20.3851974 },
    { lat: 49.3827244, lng: 20.3850813 },
    { lat: 49.3824495, lng: 20.3852283 },
    { lat: 49.3817587, lng: 20.3855622 },
    { lat: 49.3805766, lng: 20.3849111 },
    { lat: 49.3794899, lng: 20.3845193 },
    { lat: 49.3792003, lng: 20.3845389 },
    { lat: 49.3788763, lng: 20.3843394 },
    { lat: 49.3783069, lng: 20.3844649 },
    { lat: 49.3781878, lng: 20.3844401 },
    { lat: 49.3776289, lng: 20.384105 },
    { lat: 49.3770762, lng: 20.3836577 },
    { lat: 49.3766643, lng: 20.3834856 },
    { lat: 49.3764029, lng: 20.3837451 },
    { lat: 49.3764054, lng: 20.3841109 },
    { lat: 49.3765571, lng: 20.384372 },
    { lat: 49.3766405, lng: 20.3846992 },
    { lat: 49.3762447, lng: 20.3851279 },
    { lat: 49.3761548, lng: 20.385103 },
    { lat: 49.3760737, lng: 20.3852789 },
    { lat: 49.3759483, lng: 20.3853573 },
    { lat: 49.3758564, lng: 20.3853347 },
    { lat: 49.3758164, lng: 20.3854427 },
    { lat: 49.375875, lng: 20.385651 },
    { lat: 49.3756924, lng: 20.3857616 },
    { lat: 49.3755936, lng: 20.3861248 },
    { lat: 49.3752117, lng: 20.3864435 },
    { lat: 49.3751292, lng: 20.3866452 },
    { lat: 49.3751326, lng: 20.3868259 },
    { lat: 49.3752384, lng: 20.3869112 },
    { lat: 49.3752108, lng: 20.3870173 },
    { lat: 49.3751333, lng: 20.3871055 },
    { lat: 49.3750244, lng: 20.3870895 },
    { lat: 49.3749369, lng: 20.3872462 },
    { lat: 49.3747395, lng: 20.3873241 },
    { lat: 49.3746611, lng: 20.3874824 },
    { lat: 49.3745272, lng: 20.3876042 },
    { lat: 49.3745193, lng: 20.387869 },
    { lat: 49.3743981, lng: 20.3879997 },
    { lat: 49.3741388, lng: 20.3880502 },
    { lat: 49.3740299, lng: 20.3881762 },
    { lat: 49.3740745, lng: 20.3885184 },
    { lat: 49.3739585, lng: 20.3887007 },
    { lat: 49.3739544, lng: 20.3888842 },
    { lat: 49.3737545, lng: 20.388958 },
    { lat: 49.3737973, lng: 20.3891652 },
    { lat: 49.3737355, lng: 20.3893773 },
    { lat: 49.373638, lng: 20.3892693 },
    { lat: 49.3735311, lng: 20.3894486 },
    { lat: 49.373376, lng: 20.3894898 },
    { lat: 49.3732006, lng: 20.3897619 },
    { lat: 49.3730578, lng: 20.3901829 },
    { lat: 49.3728337, lng: 20.39027 },
    { lat: 49.3726228, lng: 20.3904954 },
    { lat: 49.3724535, lng: 20.3904523 },
    { lat: 49.3722018, lng: 20.3905299 },
    { lat: 49.3719829, lng: 20.3904861 },
    { lat: 49.3711684, lng: 20.3905322 },
    { lat: 49.3709892, lng: 20.3906471 },
    { lat: 49.3707416, lng: 20.3910368 },
    { lat: 49.3699533, lng: 20.3913829 },
    { lat: 49.369736, lng: 20.3914134 },
    { lat: 49.3694681, lng: 20.391297 },
    { lat: 49.3694508, lng: 20.3914015 },
    { lat: 49.3693474, lng: 20.3914663 },
    { lat: 49.3689891, lng: 20.3913995 },
    { lat: 49.368128, lng: 20.3914067 },
    { lat: 49.3676645, lng: 20.3917683 },
    { lat: 49.3670098, lng: 20.3919312 },
    { lat: 49.364968, lng: 20.3920938 },
    { lat: 49.3647411, lng: 20.392076 },
    { lat: 49.3647418, lng: 20.391977 },
    { lat: 49.3641386, lng: 20.3919514 },
    { lat: 49.3629737, lng: 20.3921105 },
    { lat: 49.3626958, lng: 20.3922345 },
    { lat: 49.3616091, lng: 20.3922877 },
    { lat: 49.3604312, lng: 20.392488 },
    { lat: 49.3602138, lng: 20.3926912 },
    { lat: 49.3601076, lng: 20.3928892 },
    { lat: 49.3595891, lng: 20.3933498 },
    { lat: 49.3587525, lng: 20.3940284 },
    { lat: 49.3586717, lng: 20.3939759 },
    { lat: 49.3583809, lng: 20.3944142 },
    { lat: 49.3582397, lng: 20.394759 },
    { lat: 49.3581917, lng: 20.3948875 },
    { lat: 49.3581682, lng: 20.3950391 },
    { lat: 49.3585451, lng: 20.39533 },
    { lat: 49.3590152, lng: 20.3965676 },
    { lat: 49.359163, lng: 20.3966639 },
    { lat: 49.3582411, lng: 20.3974706 },
    { lat: 49.3573845, lng: 20.3979818 },
    { lat: 49.357157, lng: 20.398279 },
    { lat: 49.3571011, lng: 20.3986704 },
    { lat: 49.357498, lng: 20.3989288 },
    { lat: 49.3572599, lng: 20.3991522 },
    { lat: 49.3568321, lng: 20.3998666 },
    { lat: 49.3560027, lng: 20.4017766 },
    { lat: 49.356015, lng: 20.4023165 },
    { lat: 49.3559738, lng: 20.4025361 },
    { lat: 49.3566037, lng: 20.4035898 },
    { lat: 49.3560465, lng: 20.4042481 },
    { lat: 49.3558155, lng: 20.4048084 },
    { lat: 49.3556083, lng: 20.4050749 },
    { lat: 49.3553419, lng: 20.405148 },
    { lat: 49.355278, lng: 20.4052428 },
    { lat: 49.3566475, lng: 20.4083854 },
    { lat: 49.3570735, lng: 20.4079515 },
    { lat: 49.3577539, lng: 20.407485 },
    { lat: 49.3583112, lng: 20.4070055 },
    { lat: 49.359065, lng: 20.4065754 },
    { lat: 49.3603466, lng: 20.4055167 },
    { lat: 49.3604468, lng: 20.4058452 },
    { lat: 49.3604759, lng: 20.4063162 },
    { lat: 49.3606313, lng: 20.4070028 },
    { lat: 49.359516, lng: 20.408293 },
    { lat: 49.3589335, lng: 20.4088224 },
    { lat: 49.358833, lng: 20.4089595 },
    { lat: 49.358857, lng: 20.4090256 },
    { lat: 49.3587234, lng: 20.409157 },
    { lat: 49.3580863, lng: 20.4096096 },
    { lat: 49.3571913, lng: 20.4099986 },
    { lat: 49.3569567, lng: 20.4102259 },
    { lat: 49.3562503, lng: 20.4112234 },
    { lat: 49.3562331, lng: 20.4111881 },
    { lat: 49.3556555, lng: 20.4117553 },
    { lat: 49.3559222, lng: 20.4127021 },
    { lat: 49.3561692, lng: 20.4133701 },
    { lat: 49.3559072, lng: 20.4134987 },
    { lat: 49.3558476, lng: 20.4134444 },
    { lat: 49.355511, lng: 20.4136829 },
    { lat: 49.354189, lng: 20.4141236 },
    { lat: 49.3539653, lng: 20.4143417 },
    { lat: 49.3537114, lng: 20.414445 },
    { lat: 49.3530762, lng: 20.4145556 },
    { lat: 49.3529857, lng: 20.4144578 },
    { lat: 49.3525749, lng: 20.4145172 },
    { lat: 49.3522308, lng: 20.4144393 },
    { lat: 49.3522066, lng: 20.4143049 },
    { lat: 49.3517376, lng: 20.4145533 },
    { lat: 49.3516089, lng: 20.4147579 },
    { lat: 49.3516481, lng: 20.4151079 },
    { lat: 49.3516001, lng: 20.4154487 },
    { lat: 49.3510902, lng: 20.4160474 },
    { lat: 49.3505725, lng: 20.4165275 },
    { lat: 49.3497011, lng: 20.4177567 },
    { lat: 49.3495498, lng: 20.418072 },
    { lat: 49.3495188, lng: 20.4182968 },
    { lat: 49.3494544, lng: 20.4183765 },
    { lat: 49.3490126, lng: 20.4184531 },
    { lat: 49.3487988, lng: 20.4182766 },
    { lat: 49.3483693, lng: 20.4181744 },
    { lat: 49.3483394, lng: 20.4185757 },
    { lat: 49.3480008, lng: 20.4186117 },
    { lat: 49.3474256, lng: 20.4192431 },
    { lat: 49.3469249, lng: 20.4189829 },
    { lat: 49.3464931, lng: 20.4189928 },
    { lat: 49.346457, lng: 20.419445 },
    { lat: 49.347083, lng: 20.419623 },
    { lat: 49.347367, lng: 20.419706 },
    { lat: 49.347679, lng: 20.419798 },
    { lat: 49.347858, lng: 20.41985 },
    { lat: 49.347917, lng: 20.419838 },
    { lat: 49.347988, lng: 20.419848 },
    { lat: 49.348082, lng: 20.419912 },
    { lat: 49.34811, lng: 20.419895 },
    { lat: 49.34823, lng: 20.419826 },
    { lat: 49.348288, lng: 20.419776 },
    { lat: 49.348347, lng: 20.419751 },
    { lat: 49.348536, lng: 20.419646 },
    { lat: 49.348808, lng: 20.419503 },
    { lat: 49.348991, lng: 20.419279 },
    { lat: 49.349125, lng: 20.419114 },
    { lat: 49.349255, lng: 20.419303 },
    { lat: 49.349487, lng: 20.419812 },
    { lat: 49.349601, lng: 20.419977 },
    { lat: 49.349735, lng: 20.420136 },
    { lat: 49.349818, lng: 20.4202 },
    { lat: 49.349916, lng: 20.420276 },
    { lat: 49.350067, lng: 20.4205 },
    { lat: 49.350081, lng: 20.420522 },
    { lat: 49.350173, lng: 20.420665 },
    { lat: 49.350421, lng: 20.420857 },
    { lat: 49.350666, lng: 20.421036 },
    { lat: 49.35116, lng: 20.421398 },
    { lat: 49.351262, lng: 20.421408 },
    { lat: 49.351349, lng: 20.421562 },
    { lat: 49.35142, lng: 20.421734 },
    { lat: 49.35156, lng: 20.421629 },
    { lat: 49.351708, lng: 20.421597 },
    { lat: 49.351804, lng: 20.421696 },
    { lat: 49.351921, lng: 20.421819 },
    { lat: 49.352008, lng: 20.421955 },
    { lat: 49.352055, lng: 20.422038 },
    { lat: 49.352475, lng: 20.422173 },
    { lat: 49.352489, lng: 20.422191 },
    { lat: 49.352618, lng: 20.42236 },
    { lat: 49.352661, lng: 20.422414 },
    { lat: 49.352679, lng: 20.422435 },
    { lat: 49.352747, lng: 20.422508 },
    { lat: 49.352787, lng: 20.422568 },
    { lat: 49.352898, lng: 20.422738 },
    { lat: 49.352995, lng: 20.422766 },
    { lat: 49.353056, lng: 20.423028 },
    { lat: 49.353063, lng: 20.423057 },
    { lat: 49.353089, lng: 20.423165 },
    { lat: 49.353156, lng: 20.423677 },
    { lat: 49.35318, lng: 20.423845 },
    { lat: 49.353371, lng: 20.42385 },
    { lat: 49.353713, lng: 20.42396 },
    { lat: 49.353807, lng: 20.423998 },
    { lat: 49.354098, lng: 20.424088 },
    { lat: 49.354412, lng: 20.424203 },
    { lat: 49.354486, lng: 20.424271 },
    { lat: 49.354645, lng: 20.4244 },
    { lat: 49.354726, lng: 20.424515 },
    { lat: 49.354815, lng: 20.424583 },
    { lat: 49.354995, lng: 20.424802 },
    { lat: 49.355077, lng: 20.424929 },
    { lat: 49.355366, lng: 20.425265 },
    { lat: 49.355459, lng: 20.425315 },
    { lat: 49.355513, lng: 20.425346 },
    { lat: 49.355699, lng: 20.425475 },
    { lat: 49.355777, lng: 20.425579 },
    { lat: 49.355876, lng: 20.425743 },
    { lat: 49.356057, lng: 20.425938 },
    { lat: 49.356257, lng: 20.426206 },
    { lat: 49.356427, lng: 20.426365 },
    { lat: 49.35654, lng: 20.426457 },
    { lat: 49.356539, lng: 20.426559 },
    { lat: 49.356764, lng: 20.426678 },
    { lat: 49.356872, lng: 20.426775 },
    { lat: 49.356969, lng: 20.426838 },
    { lat: 49.357152, lng: 20.426937 },
    { lat: 49.357209, lng: 20.427035 },
    { lat: 49.35757, lng: 20.427175 },
    { lat: 49.357776, lng: 20.427233 },
    { lat: 49.358071, lng: 20.427324 },
    { lat: 49.358118, lng: 20.427367 },
    { lat: 49.358348, lng: 20.427396 },
    { lat: 49.358565, lng: 20.427521 },
    { lat: 49.35871, lng: 20.427617 },
    { lat: 49.358811, lng: 20.427683 },
    { lat: 49.358948, lng: 20.427775 },
    { lat: 49.359112, lng: 20.427844 },
    { lat: 49.359128, lng: 20.427861 },
    { lat: 49.359158, lng: 20.427893 },
    { lat: 49.35924, lng: 20.427901 },
    { lat: 49.359298, lng: 20.427944 },
    { lat: 49.359322, lng: 20.427867 },
    { lat: 49.359407, lng: 20.427887 },
    { lat: 49.359626, lng: 20.427868 },
    { lat: 49.359735, lng: 20.427859 },
    { lat: 49.360229, lng: 20.427614 },
    { lat: 49.360276, lng: 20.427585 },
    { lat: 49.360437, lng: 20.427475 },
    { lat: 49.360672, lng: 20.427272 },
    { lat: 49.360877, lng: 20.427116 },
    { lat: 49.361023, lng: 20.426964 },
    { lat: 49.361349, lng: 20.426673 },
    { lat: 49.361411, lng: 20.426632 },
    { lat: 49.361514, lng: 20.426545 },
    { lat: 49.361709, lng: 20.426396 },
    { lat: 49.361863, lng: 20.426262 },
    { lat: 49.362132, lng: 20.42621 },
    { lat: 49.362292, lng: 20.426178 },
    { lat: 49.362409, lng: 20.426139 },
    { lat: 49.362518, lng: 20.426119 },
    { lat: 49.362698, lng: 20.426105 },
    { lat: 49.36285, lng: 20.426115 },
    { lat: 49.363009, lng: 20.426125 },
    { lat: 49.36314, lng: 20.42614 },
    { lat: 49.363207, lng: 20.426148 },
    { lat: 49.363402, lng: 20.426183 },
    { lat: 49.363561, lng: 20.426229 },
    { lat: 49.36389, lng: 20.426417 },
    { lat: 49.364037, lng: 20.426528 },
    { lat: 49.364133, lng: 20.426621 },
    { lat: 49.364246, lng: 20.426677 },
    { lat: 49.364339, lng: 20.426709 },
    { lat: 49.364348, lng: 20.426608 },
    { lat: 49.364452, lng: 20.426641 },
    { lat: 49.364572, lng: 20.42668 },
    { lat: 49.364654, lng: 20.426682 },
    { lat: 49.364747, lng: 20.42672 },
    { lat: 49.364837, lng: 20.426687 },
    { lat: 49.364976, lng: 20.426774 },
    { lat: 49.365044, lng: 20.426734 },
    { lat: 49.36512, lng: 20.426796 },
    { lat: 49.365221, lng: 20.426816 },
    { lat: 49.365589, lng: 20.426838 },
    { lat: 49.365591, lng: 20.426838 },
    { lat: 49.365681, lng: 20.426852 },
    { lat: 49.366151, lng: 20.42693 },
    { lat: 49.36624, lng: 20.426974 },
    { lat: 49.366256, lng: 20.426975 },
    { lat: 49.366397, lng: 20.426608 },
    { lat: 49.366721, lng: 20.426407 },
    { lat: 49.366823, lng: 20.426398 },
    { lat: 49.367031, lng: 20.426314 },
    { lat: 49.367182, lng: 20.426324 },
    { lat: 49.367315, lng: 20.426351 },
    { lat: 49.367474, lng: 20.426284 },
    { lat: 49.367581, lng: 20.426209 },
    { lat: 49.36757, lng: 20.426184 },
    { lat: 49.367581, lng: 20.426179 },
    { lat: 49.367634, lng: 20.426234 },
    { lat: 49.367716, lng: 20.426332 },
    { lat: 49.3678, lng: 20.426453 },
    { lat: 49.367895, lng: 20.426659 },
    { lat: 49.367963, lng: 20.426828 },
    { lat: 49.368001, lng: 20.42696 },
    { lat: 49.368, lng: 20.427038 },
    { lat: 49.367948, lng: 20.427103 },
    { lat: 49.36789, lng: 20.427137 },
    { lat: 49.367859, lng: 20.427148 },
    { lat: 49.367807, lng: 20.427242 },
    { lat: 49.367833, lng: 20.427404 },
    { lat: 49.367916, lng: 20.427568 },
    { lat: 49.368021, lng: 20.427642 },
    { lat: 49.368168, lng: 20.427676 },
    { lat: 49.368231, lng: 20.427665 },
    { lat: 49.3682, lng: 20.427623 },
    { lat: 49.36831, lng: 20.42759 },
    { lat: 49.368381, lng: 20.42746 },
    { lat: 49.368487, lng: 20.42738 },
    { lat: 49.368503, lng: 20.427434 },
    { lat: 49.368662, lng: 20.427426 },
    { lat: 49.368764, lng: 20.427357 },
    { lat: 49.368866, lng: 20.427335 },
    { lat: 49.368987, lng: 20.427273 },
    { lat: 49.36911, lng: 20.427372 },
    { lat: 49.369212, lng: 20.427375 },
    { lat: 49.369305, lng: 20.427419 },
    { lat: 49.369465, lng: 20.427381 },
    { lat: 49.36962, lng: 20.427403 },
    { lat: 49.369706, lng: 20.427483 },
    { lat: 49.369811, lng: 20.427468 },
    { lat: 49.369876, lng: 20.427583 },
    { lat: 49.369976, lng: 20.427633 },
    { lat: 49.37041, lng: 20.427495 },
    { lat: 49.370649, lng: 20.427753 },
    { lat: 49.370958, lng: 20.427665 },
    { lat: 49.37118, lng: 20.427671 },
    { lat: 49.3713, lng: 20.427674 },
    { lat: 49.371555, lng: 20.427809 },
    { lat: 49.372098, lng: 20.428095 },
    { lat: 49.372687, lng: 20.428386 },
    { lat: 49.372743, lng: 20.428572 },
    { lat: 49.37279, lng: 20.42892 },
    { lat: 49.372981, lng: 20.429313 },
    { lat: 49.373042, lng: 20.429441 },
    { lat: 49.373323, lng: 20.430094 },
    { lat: 49.373527, lng: 20.430654 },
    { lat: 49.373684, lng: 20.430904 },
    { lat: 49.3739, lng: 20.431227 },
    { lat: 49.374101, lng: 20.431524 },
    { lat: 49.374172, lng: 20.43164 },
    { lat: 49.37437, lng: 20.43196 },
    { lat: 49.37442, lng: 20.43204 },
    { lat: 49.374456, lng: 20.432098 },
    { lat: 49.374504, lng: 20.432174 },
    { lat: 49.374579, lng: 20.432292 },
    { lat: 49.375063, lng: 20.43321 },
    { lat: 49.37516, lng: 20.433385 },
    { lat: 49.375681, lng: 20.434079 },
    { lat: 49.375747, lng: 20.434166 },
    { lat: 49.375848, lng: 20.434251 },
    { lat: 49.375986, lng: 20.434444 },
    { lat: 49.376102, lng: 20.43456 },
    { lat: 49.376211, lng: 20.434687 },
    { lat: 49.376338, lng: 20.434868 },
    { lat: 49.376549, lng: 20.435314 },
    { lat: 49.376594, lng: 20.435458 },
    { lat: 49.376636, lng: 20.435637 },
    { lat: 49.376713, lng: 20.435836 },
    { lat: 49.37677, lng: 20.435974 },
    { lat: 49.376828, lng: 20.43613 },
    { lat: 49.376908, lng: 20.436323 },
    { lat: 49.37707, lng: 20.436588 },
    { lat: 49.377196, lng: 20.436895 },
    { lat: 49.377343, lng: 20.437136 },
    { lat: 49.377455, lng: 20.43727 },
    { lat: 49.377539, lng: 20.437409 },
    { lat: 49.377713, lng: 20.437639 },
    { lat: 49.377958, lng: 20.437744 },
    { lat: 49.378854, lng: 20.438082 },
    { lat: 49.37888, lng: 20.43809 },
    { lat: 49.378969, lng: 20.438117 },
    { lat: 49.379063, lng: 20.438145 },
    { lat: 49.3791044, lng: 20.4379687 },
    { lat: 49.3791256, lng: 20.4378747 },
    { lat: 49.3791777, lng: 20.4376711 },
    { lat: 49.3792389, lng: 20.4377119 },
    { lat: 49.3793108, lng: 20.4374715 },
    { lat: 49.3793918, lng: 20.437141 },
    { lat: 49.3794098, lng: 20.4370476 },
    { lat: 49.3795118, lng: 20.4364956 },
    { lat: 49.3795718, lng: 20.4362342 },
    { lat: 49.37964, lng: 20.4359203 },
    { lat: 49.3796553, lng: 20.4358518 },
    { lat: 49.3796748, lng: 20.4358602 },
    { lat: 49.3797394, lng: 20.4355087 },
    { lat: 49.3797423, lng: 20.4354976 },
    { lat: 49.3798269, lng: 20.4351419 },
    { lat: 49.3799757, lng: 20.4345174 },
    { lat: 49.3799381, lng: 20.4341446 },
    { lat: 49.3802417, lng: 20.4341623 },
    { lat: 49.3802411, lng: 20.4340912 },
    { lat: 49.3802501, lng: 20.4339097 },
    { lat: 49.3806679, lng: 20.4330412 },
    { lat: 49.3806946, lng: 20.4330063 },
    { lat: 49.380775, lng: 20.4328966 },
    { lat: 49.3807985, lng: 20.4328649 },
    { lat: 49.3809926, lng: 20.4326036 },
    { lat: 49.381075, lng: 20.4324923 },
    { lat: 49.3811551, lng: 20.4324017 },
    { lat: 49.3818377, lng: 20.433645 },
    { lat: 49.3820831, lng: 20.4340928 },
    { lat: 49.3821946, lng: 20.434424 },
    { lat: 49.382282, lng: 20.43525 },
    { lat: 49.382349, lng: 20.43524 },
    { lat: 49.382359, lng: 20.435239 },
    { lat: 49.382406, lng: 20.435233 },
    { lat: 49.382714, lng: 20.435274 },
    { lat: 49.382912, lng: 20.43523 },
    { lat: 49.383156, lng: 20.435084 },
    { lat: 49.383506, lng: 20.435031 },
    { lat: 49.383608, lng: 20.434991 },
    { lat: 49.38369, lng: 20.43501 },
    { lat: 49.384214, lng: 20.435718 },
    { lat: 49.384753, lng: 20.43676 },
    { lat: 49.384849, lng: 20.437484 },
    { lat: 49.385112, lng: 20.437722 },
    { lat: 49.386069, lng: 20.440067 },
    { lat: 49.386442, lng: 20.44128 },
    { lat: 49.386244, lng: 20.442221 },
    { lat: 49.386458, lng: 20.444459 },
    { lat: 49.386527, lng: 20.44572 },
    { lat: 49.386493, lng: 20.446149 },
    { lat: 49.386348, lng: 20.446768 },
    { lat: 49.386384, lng: 20.447611 },
    { lat: 49.386566, lng: 20.448372 },
    { lat: 49.386578, lng: 20.448393 },
    { lat: 49.387029, lng: 20.449162 },
    { lat: 49.38705, lng: 20.449198 },
    { lat: 49.387358, lng: 20.449724 },
    { lat: 49.387486, lng: 20.449929 },
    { lat: 49.387532, lng: 20.450044 },
    { lat: 49.387562, lng: 20.450212 },
    { lat: 49.387609, lng: 20.450678 },
    { lat: 49.387614, lng: 20.450726 },
    { lat: 49.38769, lng: 20.450649 },
    { lat: 49.387718, lng: 20.450612 },
    { lat: 49.388082, lng: 20.450867 },
    { lat: 49.388549, lng: 20.451199 },
    { lat: 49.388656, lng: 20.451595 },
    { lat: 49.388781, lng: 20.45157 },
    { lat: 49.388969, lng: 20.451534 },
    { lat: 49.389091, lng: 20.45151 },
    { lat: 49.389213, lng: 20.451486 },
    { lat: 49.389328, lng: 20.451464 },
    { lat: 49.389338, lng: 20.451464 },
    { lat: 49.389471, lng: 20.451474 },
    { lat: 49.389605, lng: 20.451485 },
    { lat: 49.389727, lng: 20.451494 },
    { lat: 49.389838, lng: 20.451503 },
    { lat: 49.390376, lng: 20.451091 },
    { lat: 49.390599, lng: 20.451067 },
    { lat: 49.390805, lng: 20.450774 },
    { lat: 49.390923, lng: 20.450618 },
    { lat: 49.391121, lng: 20.450083 },
    { lat: 49.391195, lng: 20.449916 },
    { lat: 49.391286, lng: 20.449863 },
    { lat: 49.391339, lng: 20.449798 },
    { lat: 49.391427, lng: 20.44962 },
    { lat: 49.391497, lng: 20.449533 },
    { lat: 49.39158, lng: 20.449431 },
    { lat: 49.391654, lng: 20.449349 },
    { lat: 49.391747, lng: 20.449219 },
    { lat: 49.391833, lng: 20.449106 },
    { lat: 49.391926, lng: 20.448976 },
    { lat: 49.391969, lng: 20.44891 },
    { lat: 49.391976, lng: 20.4489 },
    { lat: 49.392022, lng: 20.448832 },
    { lat: 49.392064, lng: 20.448768 },
    { lat: 49.392078, lng: 20.448745 },
    { lat: 49.392134, lng: 20.448653 },
    { lat: 49.392168, lng: 20.448386 },
    { lat: 49.39225, lng: 20.447804 },
    { lat: 49.39234, lng: 20.447443 },
    { lat: 49.392521, lng: 20.446743 },
    { lat: 49.392621, lng: 20.446187 },
    { lat: 49.393142, lng: 20.445647 },
    { lat: 49.393556, lng: 20.445228 },
    { lat: 49.394036, lng: 20.444721 },
    { lat: 49.394399, lng: 20.444345 },
    { lat: 49.394722, lng: 20.44401 },
    { lat: 49.394979, lng: 20.443741 },
    { lat: 49.395434, lng: 20.443266 },
    { lat: 49.395485, lng: 20.443112 },
    { lat: 49.395499, lng: 20.443066 },
    { lat: 49.395775, lng: 20.442234 },
    { lat: 49.395932, lng: 20.441172 },
    { lat: 49.396004, lng: 20.440784 },
    { lat: 49.396196, lng: 20.439912 },
    { lat: 49.396406, lng: 20.439057 },
    { lat: 49.396519, lng: 20.438579 },
    { lat: 49.396531, lng: 20.438528 },
    { lat: 49.396799, lng: 20.437518 },
    { lat: 49.396823, lng: 20.437427 },
    { lat: 49.397126, lng: 20.436289 },
    { lat: 49.397152, lng: 20.436176 },
    { lat: 49.397338, lng: 20.435407 },
    { lat: 49.397438, lng: 20.434839 },
    { lat: 49.397452, lng: 20.434764 },
    { lat: 49.39747, lng: 20.434702 },
    { lat: 49.397496, lng: 20.434608 },
    { lat: 49.397598, lng: 20.434241 },
    { lat: 49.39734, lng: 20.434017 },
    { lat: 49.397085, lng: 20.433771 },
    { lat: 49.396778, lng: 20.433481 },
    { lat: 49.396615, lng: 20.43327 },
    { lat: 49.396373, lng: 20.43279 },
    { lat: 49.396148, lng: 20.432381 },
    { lat: 49.396008, lng: 20.431959 },
    { lat: 49.395969, lng: 20.431821 },
  ],
};

export default UnitsKežmarok;
