import { useMediaQuery } from "usehooks-ts";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { REOffices } from "../../data/RealEstateOffices";
import RealEstateOffices from "../../Images/Pins/RealEstateOffices.png";
import RealEstateOfficesDark from "../../Images/Pins/RealEstateOfficesDark.png";

export default function REOfficesPins({
  subjects,
  mouseOver,
  view,
  previewREO,
  setMouseOver,
  zoom,
  highlight,
  hideMarkers,
  navSmall,
  setNavSmall,
  indx,
  setSubjects,
  subjectsMenu,
  setSubjectsMenu,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const showDetail = (index) => {
    if (window.location.pathname.includes("share")) {
      window.history.replaceState(null, "", "/");
    }
    indx.current = index;
    setSubjects({ ...subjects, ROffice: true, RBroker: false, FInstitution: false, FBroker: false });
    setSubjectsMenu({ ...subjectsMenu, REO: true, FI: false, FIB: false, REB: false });
    setNavSmall({
      ...navSmall,
      properties: false,
      subjects: true,
      helper: false,
      options: false,
      login: false,
    });
  };

  return ((subjects.ROffice || zoom > 1) && !hideMarkers.REO) || (hideMarkers.REO && subjects.ROffice) ? (
    <>
      {REOffices?.map((item, index) => (
        // <Fragment key={index}>
        <Marker
          key={index}
          onClick={() => {
            if (isSmallDevice) {
              showDetail(index);
            }
          }}
          position={{ lat: Number(item?.lat), lng: Number(item?.lng) }}
          onMouseOver={() => previewREO(index, item)}
          // onMouseOut={() => setMouseOver({ ...mouseOver, previewREO: false, indexREO: null })}
          label={{
            text: zoom > 12 ? item.name : " ",
            color: view === "hybrid" ? "#fff" : "#5491f5",
            fontSize: "14px",
            className: `absolute top-9 left-3 p-1 + ${view === "hybrid" ? "specialtext" : "specialtextWhite"}`,
          }}
          icon={{
            url: highlight.reo && highlight.index === index ? RealEstateOfficesDark : RealEstateOffices,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        >
          {mouseOver.previewREO && mouseOver.indexREO === index ? (
            <InfoWindow position={{ lat: Number(item?.lat), lng: Number(item?.lng) }}>
              <div
                onMouseLeave={() => setMouseOver({ ...mouseOver, previewREO: false, indexREO: null })}
                onClick={() => {
                  showDetail(index);
                }}
                className="absolute bottom-1 w-60 h-52 z-50 cursor-pointer text-lg flex flex-col justify-start items-center rounded-lg overflow-hidden"
              >
                <div className="w-60 h-40 bg-sky-100 z-50 text-lg flex flex-col justify-center items-center rounded-lg overflow-hidden">
                  <div className="w-full h-1/2 rounded-t-lg">
                    <img src={item?.img} alt="" className="w-full h-full object-cover rounded-t-lg" />
                  </div>
                  <div className="w-full h-3/4 flex flex-row justify-start p-1 text-base">
                    <div className="w-4/5 flex flex-col">
                      <p className="font-medium truncate">{item?.name}</p>
                      <p className="truncate">
                        {item?.city} {item?.number}
                      </p>
                    </div>
                    <div className="w-1/4 h-full flex justify-end items-end text-xl font-semibold ">
                      <img src={item?.url} alt="" className="w-full h-8 rounded-l-lg object-contain" />
                    </div>
                  </div>
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
        // </Fragment>
      ))}
    </>
  ) : null;
}
