import clsx from "clsx";
import { motion, AnimatePresence, wrap } from "framer-motion";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faX } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";

export default function Gallery({ images, setGallery }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  //   console.log("images = ", images);
  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const [[page, direction], setPage] = useState([0, 0]);

  const imageIndex = wrap(0, images?.length, page);
  //   console.log("is Object ? ", images instanceof Object);
  //   console.log("images.length = ", images?.length);
  //   console.log("imageIndex = ", imageIndex);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <>
      <button
        className={clsx(
          "absolute right-10 top-16  border-2 p-1 w-10 h-10 flex justify-center items-center rounded-full bg-sky-100 hover:bg-sky-200 z-[55]",
          {
            // "sm:-right-2 -right-14 -top-16": !isSmallDevice,
            "top-[30px] right-2": isSmallDevice, //top-[70px]
            "top-5 !right-2": !isSmallDevice,
          },
        )}
        onClick={(e) => {
          setGallery(false);
          e.stopPropagation();
        }}
      >
        <FontAwesomeIcon icon={faX} />
      </button>
      <AnimatePresence initial={false} custom={direction}>
        <div
          style={{
            // backgroundImage: isSmallDevice ? `url(${images[imageIndex]?.img})` : null,
            backgroundImage: `url(${images[imageIndex]?.img})`,
          }}
          className={clsx("absolute top-0 left-0 w-full h-full z-[51]", {
            " -top-[10]": isSmallDevice, // top?? -top-[24]
          })}
        >
          <div className={clsx("w-full h-full z-[51] backdrop-blur-xl", { "backdrop-blur-md": isSmallDevice })}>
            <motion.img
              className={clsx("absolute top-20 left-[16%] w-2/3 h-4/5 z-50 object-contain bg-cover", {
                "!backdrop-blur-lg w-full h-full !-top-20 !left-0 ": isSmallDevice, //flex??
                "!backdrop-blur-lg w-full h-full !-top-0 !-left-0": !isSmallDevice,
              })}
              key={page}
              src={images[imageIndex]?.img}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            />
            <div
              className={clsx(
                "absolute top-[48%] left-[18%] bg-sky-100 rounded-full w-10 h-10 flex justify-center items-center text-xl z-50 cursor-pointer",
                {
                  "left-[5%]": !isSmallDevice,
                  "left-[5%] top-[38%]": isSmallDevice,
                },
              )}
              onClick={() => paginate(1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div
              className={clsx(
                "absolute top-[48%] right-[19.5%] bg-sky-100 rounded-full w-10 h-10 flex justify-center items-center text-xl z-50 cursor-pointer",
                {
                  "right-[5%]": !isSmallDevice,
                  "right-[6.5%] top-[38%]": isSmallDevice,
                },
              )}
              onClick={() => paginate(-1)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      </AnimatePresence>
    </>
  );
}
