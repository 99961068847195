const UnitsNitra = {
  Bádice: [
    { lat: 48.3938829, lng: 18.1208086 },
    { lat: 48.393864, lng: 18.1209304 },
    { lat: 48.3930486, lng: 18.1268952 },
    { lat: 48.3925717, lng: 18.1291593 },
    { lat: 48.3922986, lng: 18.1309143 },
    { lat: 48.391519, lng: 18.1333098 },
    { lat: 48.3911089, lng: 18.1347894 },
    { lat: 48.3907263, lng: 18.1358095 },
    { lat: 48.3903752, lng: 18.137008 },
    { lat: 48.3901749, lng: 18.1369957 },
    { lat: 48.3900269, lng: 18.1378836 },
    { lat: 48.3895704, lng: 18.137833 },
    { lat: 48.3893188, lng: 18.1394763 },
    { lat: 48.3891612, lng: 18.1409123 },
    { lat: 48.3890079, lng: 18.1417281 },
    { lat: 48.3892592, lng: 18.1420316 },
    { lat: 48.3896167, lng: 18.1427234 },
    { lat: 48.3904297, lng: 18.1446065 },
    { lat: 48.3907427, lng: 18.1448274 },
    { lat: 48.3912353, lng: 18.1450459 },
    { lat: 48.3916877, lng: 18.1455701 },
    { lat: 48.3926825, lng: 18.1461558 },
    { lat: 48.3937772, lng: 18.1466427 },
    { lat: 48.3941237, lng: 18.147553 },
    { lat: 48.3945337, lng: 18.1489134 },
    { lat: 48.3949957, lng: 18.1495568 },
    { lat: 48.3957203, lng: 18.1510694 },
    { lat: 48.3959868, lng: 18.1518858 },
    { lat: 48.3960663, lng: 18.1530177 },
    { lat: 48.3963409, lng: 18.154418 },
    { lat: 48.3965191, lng: 18.1550732 },
    { lat: 48.3970918, lng: 18.15644 },
    { lat: 48.3973405, lng: 18.157163 },
    { lat: 48.3977005, lng: 18.1578408 },
    { lat: 48.3984044, lng: 18.1588066 },
    { lat: 48.3986405, lng: 18.1592393 },
    { lat: 48.3991183, lng: 18.1597078 },
    { lat: 48.3995921, lng: 18.1607898 },
    { lat: 48.3998803, lng: 18.1620404 },
    { lat: 48.3999218, lng: 18.1623644 },
    { lat: 48.3999087, lng: 18.1626826 },
    { lat: 48.3998617, lng: 18.1628628 },
    { lat: 48.3993563, lng: 18.1639344 },
    { lat: 48.3989048, lng: 18.1653101 },
    { lat: 48.3988633, lng: 18.1655648 },
    { lat: 48.3987863, lng: 18.1665301 },
    { lat: 48.3988479, lng: 18.1674546 },
    { lat: 48.3989806, lng: 18.1684926 },
    { lat: 48.3990007, lng: 18.1684722 },
    { lat: 48.3996834, lng: 18.167927 },
    { lat: 48.4008751, lng: 18.1667824 },
    { lat: 48.4012514, lng: 18.1661329 },
    { lat: 48.4016136, lng: 18.1661529 },
    { lat: 48.4017674, lng: 18.1663688 },
    { lat: 48.4015981, lng: 18.1657689 },
    { lat: 48.4010008, lng: 18.1649069 },
    { lat: 48.4007992, lng: 18.1629386 },
    { lat: 48.4002868, lng: 18.1558043 },
    { lat: 48.400175, lng: 18.152858 },
    { lat: 48.4001591, lng: 18.1506718 },
    { lat: 48.4008546, lng: 18.1474442 },
    { lat: 48.4025728, lng: 18.1403407 },
    { lat: 48.4025917, lng: 18.1388867 },
    { lat: 48.4026896, lng: 18.1381859 },
    { lat: 48.4027712, lng: 18.1370536 },
    { lat: 48.4029176, lng: 18.1367584 },
    { lat: 48.4030365, lng: 18.1355881 },
    { lat: 48.4033472, lng: 18.1343776 },
    { lat: 48.403497, lng: 18.1340432 },
    { lat: 48.4037366, lng: 18.1330445 },
    { lat: 48.4038576, lng: 18.1320082 },
    { lat: 48.4039601, lng: 18.1315205 },
    { lat: 48.4038754, lng: 18.1311723 },
    { lat: 48.4038326, lng: 18.1309045 },
    { lat: 48.4035888, lng: 18.1293775 },
    { lat: 48.4030916, lng: 18.1275565 },
    { lat: 48.4026996, lng: 18.1264291 },
    { lat: 48.4017963, lng: 18.1229489 },
    { lat: 48.4027599, lng: 18.1222527 },
    { lat: 48.4024799, lng: 18.1215172 },
    { lat: 48.4016569, lng: 18.1186821 },
    { lat: 48.402084, lng: 18.1182265 },
    { lat: 48.4027783, lng: 18.1172692 },
    { lat: 48.4036051, lng: 18.1180127 },
    { lat: 48.4041054, lng: 18.1183099 },
    { lat: 48.4048, lng: 18.1181822 },
    { lat: 48.4045731, lng: 18.1158305 },
    { lat: 48.4044152, lng: 18.1115048 },
    { lat: 48.4041499, lng: 18.1114646 },
    { lat: 48.4043098, lng: 18.1103399 },
    { lat: 48.4036689, lng: 18.1102823 },
    { lat: 48.4027467, lng: 18.1104185 },
    { lat: 48.4031396, lng: 18.1090173 },
    { lat: 48.4033207, lng: 18.1086573 },
    { lat: 48.4037175, lng: 18.1080728 },
    { lat: 48.4041791, lng: 18.1075762 },
    { lat: 48.4044346, lng: 18.1072142 },
    { lat: 48.4047076, lng: 18.1064266 },
    { lat: 48.4045152, lng: 18.1063597 },
    { lat: 48.4046529, lng: 18.1055313 },
    { lat: 48.4048544, lng: 18.1055437 },
    { lat: 48.4050388, lng: 18.1054539 },
    { lat: 48.405174, lng: 18.1053561 },
    { lat: 48.4054765, lng: 18.1049534 },
    { lat: 48.4055363, lng: 18.1040107 },
    { lat: 48.4045779, lng: 18.1044263 },
    { lat: 48.4041721, lng: 18.1048005 },
    { lat: 48.4040644, lng: 18.1048321 },
    { lat: 48.404005, lng: 18.1047217 },
    { lat: 48.403954, lng: 18.1044131 },
    { lat: 48.4039079, lng: 18.103088 },
    { lat: 48.4038672, lng: 18.1027986 },
    { lat: 48.4037636, lng: 18.1024966 },
    { lat: 48.4036537, lng: 18.1023723 },
    { lat: 48.403522, lng: 18.1024362 },
    { lat: 48.4034453, lng: 18.1025519 },
    { lat: 48.4032897, lng: 18.1034785 },
    { lat: 48.4029696, lng: 18.1039073 },
    { lat: 48.4026667, lng: 18.104054 },
    { lat: 48.4025419, lng: 18.1040219 },
    { lat: 48.4024285, lng: 18.1038998 },
    { lat: 48.4022924, lng: 18.1031965 },
    { lat: 48.4022037, lng: 18.1024354 },
    { lat: 48.4021215, lng: 18.1023195 },
    { lat: 48.4018932, lng: 18.1023913 },
    { lat: 48.4015282, lng: 18.102884 },
    { lat: 48.4012608, lng: 18.1042874 },
    { lat: 48.4012077, lng: 18.1043825 },
    { lat: 48.4011189, lng: 18.1044088 },
    { lat: 48.4010126, lng: 18.1043462 },
    { lat: 48.4008294, lng: 18.1040732 },
    { lat: 48.4006894, lng: 18.1037267 },
    { lat: 48.4006111, lng: 18.1033524 },
    { lat: 48.4003537, lng: 18.1026016 },
    { lat: 48.4003057, lng: 18.1022837 },
    { lat: 48.4003135, lng: 18.1020533 },
    { lat: 48.4004889, lng: 18.1013109 },
    { lat: 48.4006763, lng: 18.1008293 },
    { lat: 48.4006004, lng: 18.1006334 },
    { lat: 48.4004864, lng: 18.1006126 },
    { lat: 48.4003382, lng: 18.1006255 },
    { lat: 48.4000564, lng: 18.1009622 },
    { lat: 48.3998241, lng: 18.1014036 },
    { lat: 48.3994739, lng: 18.1023989 },
    { lat: 48.3992545, lng: 18.1027536 },
    { lat: 48.399108, lng: 18.1026441 },
    { lat: 48.399026, lng: 18.1018832 },
    { lat: 48.3989155, lng: 18.1014231 },
    { lat: 48.3987318, lng: 18.1013519 },
    { lat: 48.3985209, lng: 18.1015627 },
    { lat: 48.3983072, lng: 18.1020189 },
    { lat: 48.3981744, lng: 18.1020443 },
    { lat: 48.3981107, lng: 18.1018624 },
    { lat: 48.3981518, lng: 18.1009677 },
    { lat: 48.3980911, lng: 18.1008561 },
    { lat: 48.3979804, lng: 18.1008708 },
    { lat: 48.3978476, lng: 18.1010343 },
    { lat: 48.3973991, lng: 18.1018892 },
    { lat: 48.3973301, lng: 18.1021768 },
    { lat: 48.3973213, lng: 18.1032955 },
    { lat: 48.3973107, lng: 18.1033647 },
    { lat: 48.3973481, lng: 18.1033651 },
    { lat: 48.3973555, lng: 18.1038567 },
    { lat: 48.3973213, lng: 18.1043147 },
    { lat: 48.3968247, lng: 18.1073681 },
    { lat: 48.3963659, lng: 18.1096082 },
    { lat: 48.3962214, lng: 18.1125108 },
    { lat: 48.3940766, lng: 18.1208977 },
    { lat: 48.3938829, lng: 18.1208086 },
  ],
  Hruboňovo: [
    { lat: 48.443907, lng: 18.057204 },
    { lat: 48.443945, lng: 18.057223 },
    { lat: 48.444057, lng: 18.057286 },
    { lat: 48.444197, lng: 18.057366 },
    { lat: 48.444297, lng: 18.057429 },
    { lat: 48.444429, lng: 18.057521 },
    { lat: 48.444525, lng: 18.05759 },
    { lat: 48.444565, lng: 18.057619 },
    { lat: 48.444641, lng: 18.057673 },
    { lat: 48.444675, lng: 18.057697 },
    { lat: 48.444786, lng: 18.05779 },
    { lat: 48.444876, lng: 18.05787 },
    { lat: 48.444921, lng: 18.057899 },
    { lat: 48.445018, lng: 18.057957 },
    { lat: 48.445178, lng: 18.058047 },
    { lat: 48.445275, lng: 18.058101 },
    { lat: 48.445377, lng: 18.058159 },
    { lat: 48.445427, lng: 18.05818 },
    { lat: 48.445518, lng: 18.058224 },
    { lat: 48.445668, lng: 18.058294 },
    { lat: 48.44577, lng: 18.058338 },
    { lat: 48.445891, lng: 18.058392 },
    { lat: 48.445988, lng: 18.058426 },
    { lat: 48.446111, lng: 18.05847 },
    { lat: 48.446157, lng: 18.058488 },
    { lat: 48.446256, lng: 18.058492 },
    { lat: 48.446394, lng: 18.058501 },
    { lat: 48.446446, lng: 18.058504 },
    { lat: 48.446453, lng: 18.0585 },
    { lat: 48.446574, lng: 18.058431 },
    { lat: 48.446654, lng: 18.058384 },
    { lat: 48.446723, lng: 18.058377 },
    { lat: 48.44682, lng: 18.058368 },
    { lat: 48.446877, lng: 18.058392 },
    { lat: 48.446976, lng: 18.058437 },
    { lat: 48.447066, lng: 18.058477 },
    { lat: 48.447152, lng: 18.058516 },
    { lat: 48.447248, lng: 18.058556 },
    { lat: 48.447355, lng: 18.058596 },
    { lat: 48.447445, lng: 18.05863 },
    { lat: 48.447546, lng: 18.058661 },
    { lat: 48.447627, lng: 18.058688 },
    { lat: 48.447667, lng: 18.05869 },
    { lat: 48.447772, lng: 18.058689 },
    { lat: 48.447891, lng: 18.058731 },
    { lat: 48.447993, lng: 18.058766 },
    { lat: 48.448079, lng: 18.058793 },
    { lat: 48.448169, lng: 18.058822 },
    { lat: 48.448269, lng: 18.058853 },
    { lat: 48.448372, lng: 18.058877 },
    { lat: 48.448492, lng: 18.058902 },
    { lat: 48.448556, lng: 18.058922 },
    { lat: 48.44867, lng: 18.058956 },
    { lat: 48.448722, lng: 18.058971 },
    { lat: 48.448769, lng: 18.058985 },
    { lat: 48.448845, lng: 18.059 },
    { lat: 48.448928, lng: 18.05902 },
    { lat: 48.449001, lng: 18.059037 },
    { lat: 48.449088, lng: 18.059057 },
    { lat: 48.449133, lng: 18.059066 },
    { lat: 48.449253, lng: 18.059112 },
    { lat: 48.449335, lng: 18.059142 },
    { lat: 48.449426, lng: 18.059176 },
    { lat: 48.449445, lng: 18.059176 },
    { lat: 48.449584, lng: 18.059181 },
    { lat: 48.449644, lng: 18.059184 },
    { lat: 48.449722, lng: 18.059187 },
    { lat: 48.449876, lng: 18.059193 },
    { lat: 48.450015, lng: 18.059195 },
    { lat: 48.450017, lng: 18.05915 },
    { lat: 48.450026, lng: 18.059043 },
    { lat: 48.450056, lng: 18.058718 },
    { lat: 48.450057, lng: 18.058717 },
    { lat: 48.450075, lng: 18.058493 },
    { lat: 48.450078, lng: 18.058454 },
    { lat: 48.450095, lng: 18.058271 },
    { lat: 48.450106, lng: 18.058138 },
    { lat: 48.450109, lng: 18.058103 },
    { lat: 48.450123, lng: 18.057936 },
    { lat: 48.450133, lng: 18.057826 },
    { lat: 48.450148, lng: 18.057647 },
    { lat: 48.450154, lng: 18.057606 },
    { lat: 48.450186, lng: 18.057445 },
    { lat: 48.450213, lng: 18.05731 },
    { lat: 48.450231, lng: 18.057218 },
    { lat: 48.450261, lng: 18.057068 },
    { lat: 48.450312, lng: 18.056859 },
    { lat: 48.450349, lng: 18.056712 },
    { lat: 48.450379, lng: 18.056563 },
    { lat: 48.450403, lng: 18.056444 },
    { lat: 48.45044, lng: 18.056269 },
    { lat: 48.450485, lng: 18.056054 },
    { lat: 48.450507, lng: 18.055864 },
    { lat: 48.450531, lng: 18.055645 },
    { lat: 48.450557, lng: 18.055446 },
    { lat: 48.45059, lng: 18.055207 },
    { lat: 48.450595, lng: 18.055148 },
    { lat: 48.450583, lng: 18.054898 },
    { lat: 48.450572, lng: 18.054707 },
    { lat: 48.450572, lng: 18.054706 },
    { lat: 48.450571, lng: 18.054605 },
    { lat: 48.450567, lng: 18.054447 },
    { lat: 48.450564, lng: 18.054281 },
    { lat: 48.450554, lng: 18.05422 },
    { lat: 48.450544, lng: 18.054166 },
    { lat: 48.450544, lng: 18.054161 },
    { lat: 48.450514, lng: 18.053989 },
    { lat: 48.450459, lng: 18.053647 },
    { lat: 48.450424, lng: 18.053434 },
    { lat: 48.450421, lng: 18.053423 },
    { lat: 48.450387, lng: 18.053206 },
    { lat: 48.450386, lng: 18.053177 },
    { lat: 48.450384, lng: 18.053115 },
    { lat: 48.450382, lng: 18.053076 },
    { lat: 48.45038, lng: 18.05302 },
    { lat: 48.45038, lng: 18.052968 },
    { lat: 48.450385, lng: 18.052907 },
    { lat: 48.450389, lng: 18.052859 },
    { lat: 48.450389, lng: 18.052857 },
    { lat: 48.450398, lng: 18.052741 },
    { lat: 48.450398, lng: 18.052739 },
    { lat: 48.450407, lng: 18.052661 },
    { lat: 48.450437, lng: 18.052438 },
    { lat: 48.450458, lng: 18.052308 },
    { lat: 48.450504, lng: 18.052008 },
    { lat: 48.450533, lng: 18.051837 },
    { lat: 48.450578, lng: 18.051557 },
    { lat: 48.450612, lng: 18.051352 },
    { lat: 48.450625, lng: 18.05126 },
    { lat: 48.450651, lng: 18.051082 },
    { lat: 48.450665, lng: 18.050969 },
    { lat: 48.450677, lng: 18.050596 },
    { lat: 48.450685, lng: 18.050377 },
    { lat: 48.450694, lng: 18.050131 },
    { lat: 48.450695, lng: 18.050128 },
    { lat: 48.450701, lng: 18.049953 },
    { lat: 48.450708, lng: 18.049721 },
    { lat: 48.450709, lng: 18.049717 },
    { lat: 48.450713, lng: 18.049576 },
    { lat: 48.45072, lng: 18.049367 },
    { lat: 48.450808, lng: 18.049391 },
    { lat: 48.450901, lng: 18.049412 },
    { lat: 48.450917, lng: 18.04932 },
    { lat: 48.450918, lng: 18.049318 },
    { lat: 48.450938, lng: 18.049226 },
    { lat: 48.450991, lng: 18.048954 },
    { lat: 48.451064, lng: 18.048604 },
    { lat: 48.451064, lng: 18.048603 },
    { lat: 48.451087, lng: 18.048479 },
    { lat: 48.451113, lng: 18.04824 },
    { lat: 48.451115, lng: 18.048239 },
    { lat: 48.451156, lng: 18.04787 },
    { lat: 48.451156, lng: 18.047869 },
    { lat: 48.451173, lng: 18.0477 },
    { lat: 48.451191, lng: 18.047504 },
    { lat: 48.451191, lng: 18.047502 },
    { lat: 48.451196, lng: 18.047438 },
    { lat: 48.451203, lng: 18.047327 },
    { lat: 48.451221, lng: 18.04723 },
    { lat: 48.451239, lng: 18.047132 },
    { lat: 48.451249, lng: 18.047074 },
    { lat: 48.451265, lng: 18.047003 },
    { lat: 48.451278, lng: 18.046983 },
    { lat: 48.451383, lng: 18.046845 },
    { lat: 48.4514, lng: 18.046822 },
    { lat: 48.451438, lng: 18.046768 },
    { lat: 48.451442, lng: 18.046684 },
    { lat: 48.451454, lng: 18.046474 },
    { lat: 48.451459, lng: 18.046396 },
    { lat: 48.451465, lng: 18.046271 },
    { lat: 48.451473, lng: 18.046128 },
    { lat: 48.451476, lng: 18.046066 },
    { lat: 48.451478, lng: 18.046007 },
    { lat: 48.451528, lng: 18.045916 },
    { lat: 48.451589, lng: 18.045803 },
    { lat: 48.451676, lng: 18.045641 },
    { lat: 48.45171, lng: 18.045587 },
    { lat: 48.451737, lng: 18.045509 },
    { lat: 48.45179, lng: 18.045365 },
    { lat: 48.45185, lng: 18.045204 },
    { lat: 48.451851, lng: 18.0452 },
    { lat: 48.45192, lng: 18.045012 },
    { lat: 48.451998, lng: 18.044804 },
    { lat: 48.452057, lng: 18.044644 },
    { lat: 48.452057, lng: 18.044643 },
    { lat: 48.452105, lng: 18.044522 },
    { lat: 48.452201, lng: 18.044303 },
    { lat: 48.452279, lng: 18.044124 },
    { lat: 48.452281, lng: 18.044122 },
    { lat: 48.45235, lng: 18.043964 },
    { lat: 48.452427, lng: 18.043793 },
    { lat: 48.452489, lng: 18.043649 },
    { lat: 48.452498, lng: 18.043629 },
    { lat: 48.452509, lng: 18.043601 },
    { lat: 48.452576, lng: 18.043513 },
    { lat: 48.452651, lng: 18.043417 },
    { lat: 48.45266, lng: 18.043404 },
    { lat: 48.452743, lng: 18.043294 },
    { lat: 48.452802, lng: 18.043218 },
    { lat: 48.452804, lng: 18.043215 },
    { lat: 48.452808, lng: 18.04321 },
    { lat: 48.452884, lng: 18.043102 },
    { lat: 48.452975, lng: 18.042985 },
    { lat: 48.453046, lng: 18.04289 },
    { lat: 48.453094, lng: 18.042826 },
    { lat: 48.453097, lng: 18.042824 },
    { lat: 48.453099, lng: 18.042821 },
    { lat: 48.453128, lng: 18.042786 },
    { lat: 48.453155, lng: 18.042765 },
    { lat: 48.453257, lng: 18.042677 },
    { lat: 48.453367, lng: 18.042585 },
    { lat: 48.453395, lng: 18.042562 },
    { lat: 48.453433, lng: 18.042531 },
    { lat: 48.453436, lng: 18.042529 },
    { lat: 48.453475, lng: 18.042503 },
    { lat: 48.453478, lng: 18.042502 },
    { lat: 48.453588, lng: 18.042432 },
    { lat: 48.453674, lng: 18.042375 },
    { lat: 48.453755, lng: 18.042322 },
    { lat: 48.4539, lng: 18.042228 },
    { lat: 48.453972, lng: 18.042181 },
    { lat: 48.454064, lng: 18.042121 },
    { lat: 48.454049, lng: 18.042053 },
    { lat: 48.45403, lng: 18.041972 },
    { lat: 48.454013, lng: 18.041896 },
    { lat: 48.454012, lng: 18.041891 },
    { lat: 48.453997, lng: 18.041841 },
    { lat: 48.453984, lng: 18.041798 },
    { lat: 48.45394, lng: 18.041654 },
    { lat: 48.453913, lng: 18.041561 },
    { lat: 48.453878, lng: 18.041444 },
    { lat: 48.453831, lng: 18.041292 },
    { lat: 48.453781, lng: 18.041128 },
    { lat: 48.453775, lng: 18.04111 },
    { lat: 48.453767, lng: 18.041085 },
    { lat: 48.453741, lng: 18.041013 },
    { lat: 48.453729, lng: 18.040977 },
    { lat: 48.453716, lng: 18.040939 },
    { lat: 48.453715, lng: 18.040938 },
    { lat: 48.453701, lng: 18.040897 },
    { lat: 48.453683, lng: 18.04084 },
    { lat: 48.453672, lng: 18.040808 },
    { lat: 48.453653, lng: 18.04075 },
    { lat: 48.453613, lng: 18.040612 },
    { lat: 48.453595, lng: 18.040535 },
    { lat: 48.453615, lng: 18.040517 },
    { lat: 48.453664, lng: 18.040471 },
    { lat: 48.453767, lng: 18.040373 },
    { lat: 48.453871, lng: 18.040274 },
    { lat: 48.453972, lng: 18.040189 },
    { lat: 48.454078, lng: 18.040097 },
    { lat: 48.454158, lng: 18.04003 },
    { lat: 48.454239, lng: 18.039964 },
    { lat: 48.454352, lng: 18.039865 },
    { lat: 48.45445, lng: 18.039784 },
    { lat: 48.454583, lng: 18.039673 },
    { lat: 48.454671, lng: 18.039597 },
    { lat: 48.454807, lng: 18.039484 },
    { lat: 48.454849, lng: 18.039447 },
    { lat: 48.454898, lng: 18.039406 },
    { lat: 48.454995, lng: 18.039318 },
    { lat: 48.455081, lng: 18.039239 },
    { lat: 48.455197, lng: 18.039131 },
    { lat: 48.45529, lng: 18.039045 },
    { lat: 48.455398, lng: 18.038958 },
    { lat: 48.4555, lng: 18.038879 },
    { lat: 48.455627, lng: 18.03878 },
    { lat: 48.455708, lng: 18.038725 },
    { lat: 48.455756, lng: 18.038694 },
    { lat: 48.455773, lng: 18.038682 },
    { lat: 48.455782, lng: 18.038676 },
    { lat: 48.455784, lng: 18.03874 },
    { lat: 48.455802, lng: 18.038732 },
    { lat: 48.455925, lng: 18.038676 },
    { lat: 48.455949, lng: 18.038666 },
    { lat: 48.456007, lng: 18.038639 },
    { lat: 48.456083, lng: 18.038604 },
    { lat: 48.456205, lng: 18.038588 },
    { lat: 48.456216, lng: 18.038586 },
    { lat: 48.45629, lng: 18.038573 },
    { lat: 48.45637, lng: 18.038563 },
    { lat: 48.456407, lng: 18.038563 },
    { lat: 48.456442, lng: 18.038563 },
    { lat: 48.456512, lng: 18.038564 },
    { lat: 48.456555, lng: 18.038565 },
    { lat: 48.456656, lng: 18.038568 },
    { lat: 48.456657, lng: 18.038568 },
    { lat: 48.456705, lng: 18.038578 },
    { lat: 48.456816, lng: 18.038599 },
    { lat: 48.456939, lng: 18.038621 },
    { lat: 48.457041, lng: 18.038642 },
    { lat: 48.457081, lng: 18.038649 },
    { lat: 48.457138, lng: 18.038659 },
    { lat: 48.457267, lng: 18.038684 },
    { lat: 48.45735, lng: 18.038704 },
    { lat: 48.457358, lng: 18.038706 },
    { lat: 48.45744, lng: 18.038722 },
    { lat: 48.457442, lng: 18.038723 },
    { lat: 48.457444, lng: 18.038723 },
    { lat: 48.457484, lng: 18.038736 },
    { lat: 48.457593, lng: 18.038768 },
    { lat: 48.457604, lng: 18.03877 },
    { lat: 48.457607, lng: 18.038772 },
    { lat: 48.45768, lng: 18.038793 },
    { lat: 48.457776, lng: 18.038819 },
    { lat: 48.457796, lng: 18.038824 },
    { lat: 48.457915, lng: 18.038858 },
    { lat: 48.457949, lng: 18.038871 },
    { lat: 48.45795, lng: 18.038871 },
    { lat: 48.458106, lng: 18.038925 },
    { lat: 48.458227, lng: 18.038966 },
    { lat: 48.458336, lng: 18.039001 },
    { lat: 48.458433, lng: 18.039033 },
    { lat: 48.45853, lng: 18.039066 },
    { lat: 48.458659, lng: 18.039107 },
    { lat: 48.458685, lng: 18.039117 },
    { lat: 48.458686, lng: 18.039117 },
    { lat: 48.45876, lng: 18.039145 },
    { lat: 48.458871, lng: 18.039185 },
    { lat: 48.458963, lng: 18.039217 },
    { lat: 48.458988, lng: 18.039225 },
    { lat: 48.459038, lng: 18.03924 },
    { lat: 48.459057, lng: 18.039246 },
    { lat: 48.459079, lng: 18.039252 },
    { lat: 48.459095, lng: 18.039257 },
    { lat: 48.459103, lng: 18.039259 },
    { lat: 48.459146, lng: 18.039272 },
    { lat: 48.459256, lng: 18.039303 },
    { lat: 48.459378, lng: 18.039345 },
    { lat: 48.459488, lng: 18.03938 },
    { lat: 48.459622, lng: 18.039425 },
    { lat: 48.459735, lng: 18.039462 },
    { lat: 48.459815, lng: 18.039488 },
    { lat: 48.459972, lng: 18.039539 },
    { lat: 48.460049, lng: 18.039564 },
    { lat: 48.460056, lng: 18.039495 },
    { lat: 48.460081, lng: 18.039236 },
    { lat: 48.460097, lng: 18.039076 },
    { lat: 48.460125, lng: 18.038807 },
    { lat: 48.460148, lng: 18.038586 },
    { lat: 48.46016, lng: 18.038467 },
    { lat: 48.460166, lng: 18.038174 },
    { lat: 48.460169, lng: 18.037979 },
    { lat: 48.460173, lng: 18.037759 },
    { lat: 48.460176, lng: 18.037651 },
    { lat: 48.460179, lng: 18.037517 },
    { lat: 48.460187, lng: 18.037196 },
    { lat: 48.460193, lng: 18.0369 },
    { lat: 48.460199, lng: 18.036601 },
    { lat: 48.460201, lng: 18.036504 },
    { lat: 48.460207, lng: 18.036237 },
    { lat: 48.460212, lng: 18.035941 },
    { lat: 48.460217, lng: 18.035747 },
    { lat: 48.460222, lng: 18.03545 },
    { lat: 48.460229, lng: 18.035118 },
    { lat: 48.460235, lng: 18.034871 },
    { lat: 48.460236, lng: 18.034739 },
    { lat: 48.460108, lng: 18.034713 },
    { lat: 48.460053, lng: 18.034702 },
    { lat: 48.459898, lng: 18.034668 },
    { lat: 48.459755, lng: 18.034639 },
    { lat: 48.459578, lng: 18.034603 },
    { lat: 48.45942, lng: 18.034571 },
    { lat: 48.459302, lng: 18.034542 },
    { lat: 48.459258, lng: 18.034533 },
    { lat: 48.45916, lng: 18.034515 },
    { lat: 48.459171, lng: 18.034251 },
    { lat: 48.45918, lng: 18.034019 },
    { lat: 48.459188, lng: 18.033859 },
    { lat: 48.459196, lng: 18.033727 },
    { lat: 48.459205, lng: 18.033595 },
    { lat: 48.459223, lng: 18.033334 },
    { lat: 48.459223, lng: 18.033329 },
    { lat: 48.45923, lng: 18.033224 },
    { lat: 48.459236, lng: 18.033123 },
    { lat: 48.459236, lng: 18.03311 },
    { lat: 48.459255, lng: 18.032924 },
    { lat: 48.45929, lng: 18.032564 },
    { lat: 48.459315, lng: 18.032313 },
    { lat: 48.459341, lng: 18.03207 },
    { lat: 48.459355, lng: 18.031927 },
    { lat: 48.459446, lng: 18.031882 },
    { lat: 48.459446, lng: 18.03188 },
    { lat: 48.459447, lng: 18.031875 },
    { lat: 48.459463, lng: 18.031731 },
    { lat: 48.459489, lng: 18.031457 },
    { lat: 48.459525, lng: 18.031091 },
    { lat: 48.459547, lng: 18.03086 },
    { lat: 48.459577, lng: 18.03055 },
    { lat: 48.459603, lng: 18.03028 },
    { lat: 48.459626, lng: 18.030043 },
    { lat: 48.459646, lng: 18.029815 },
    { lat: 48.459664, lng: 18.029642 },
    { lat: 48.459677, lng: 18.029506 },
    { lat: 48.459683, lng: 18.029459 },
    { lat: 48.459682, lng: 18.029455 },
    { lat: 48.459708, lng: 18.029198 },
    { lat: 48.459714, lng: 18.029137 },
    { lat: 48.459732, lng: 18.028965 },
    { lat: 48.459748, lng: 18.028778 },
    { lat: 48.459762, lng: 18.028639 },
    { lat: 48.459782, lng: 18.028437 },
    { lat: 48.459796, lng: 18.028275 },
    { lat: 48.459813, lng: 18.028117 },
    { lat: 48.459821, lng: 18.028012 },
    { lat: 48.459821, lng: 18.028011 },
    { lat: 48.459824, lng: 18.027955 },
    { lat: 48.459849, lng: 18.027948 },
    { lat: 48.459984, lng: 18.027896 },
    { lat: 48.460079, lng: 18.027861 },
    { lat: 48.460127, lng: 18.027847 },
    { lat: 48.460135, lng: 18.027844 },
    { lat: 48.46025, lng: 18.027814 },
    { lat: 48.460325, lng: 18.027804 },
    { lat: 48.46044, lng: 18.027791 },
    { lat: 48.460572, lng: 18.027696 },
    { lat: 48.460676, lng: 18.027621 },
    { lat: 48.460865, lng: 18.027521 },
    { lat: 48.460967, lng: 18.027466 },
    { lat: 48.46111, lng: 18.027392 },
    { lat: 48.461202, lng: 18.027342 },
    { lat: 48.461292, lng: 18.027308 },
    { lat: 48.461394, lng: 18.027264 },
    { lat: 48.46153, lng: 18.027198 },
    { lat: 48.461603, lng: 18.027178 },
    { lat: 48.46164, lng: 18.02717 },
    { lat: 48.461778, lng: 18.027257 },
    { lat: 48.461915, lng: 18.027385 },
    { lat: 48.461983, lng: 18.027449 },
    { lat: 48.461991, lng: 18.027457 },
    { lat: 48.461993, lng: 18.027425 },
    { lat: 48.461994, lng: 18.027423 },
    { lat: 48.461995, lng: 18.027367 },
    { lat: 48.462004, lng: 18.027053 },
    { lat: 48.462013, lng: 18.026813 },
    { lat: 48.462019, lng: 18.026592 },
    { lat: 48.462024, lng: 18.026447 },
    { lat: 48.462027, lng: 18.026366 },
    { lat: 48.462033, lng: 18.026299 },
    { lat: 48.46204, lng: 18.026206 },
    { lat: 48.462071, lng: 18.026116 },
    { lat: 48.46214, lng: 18.025894 },
    { lat: 48.462209, lng: 18.025675 },
    { lat: 48.46227, lng: 18.02549 },
    { lat: 48.462333, lng: 18.0253 },
    { lat: 48.46238, lng: 18.025172 },
    { lat: 48.462418, lng: 18.025062 },
    { lat: 48.462432, lng: 18.024984 },
    { lat: 48.462458, lng: 18.024788 },
    { lat: 48.462476, lng: 18.02463 },
    { lat: 48.462498, lng: 18.024519 },
    { lat: 48.462534, lng: 18.024343 },
    { lat: 48.462557, lng: 18.024223 },
    { lat: 48.462579, lng: 18.024132 },
    { lat: 48.462625, lng: 18.023978 },
    { lat: 48.462701, lng: 18.023738 },
    { lat: 48.462771, lng: 18.023513 },
    { lat: 48.462834, lng: 18.023298 },
    { lat: 48.462901, lng: 18.023072 },
    { lat: 48.462961, lng: 18.022841 },
    { lat: 48.463028, lng: 18.022597 },
    { lat: 48.463073, lng: 18.022435 },
    { lat: 48.463111, lng: 18.022298 },
    { lat: 48.463112, lng: 18.022295 },
    { lat: 48.463145, lng: 18.022198 },
    { lat: 48.463182, lng: 18.022141 },
    { lat: 48.463264, lng: 18.022042 },
    { lat: 48.463316, lng: 18.022 },
    { lat: 48.463405, lng: 18.021926 },
    { lat: 48.463459, lng: 18.021896 },
    { lat: 48.463529, lng: 18.021857 },
    { lat: 48.463602, lng: 18.021797 },
    { lat: 48.463666, lng: 18.021742 },
    { lat: 48.463772, lng: 18.021724 },
    { lat: 48.463798, lng: 18.02165 },
    { lat: 48.463837, lng: 18.021538 },
    { lat: 48.46382, lng: 18.021524 },
    { lat: 48.463846, lng: 18.021393 },
    { lat: 48.463846, lng: 18.021392 },
    { lat: 48.463849, lng: 18.021381 },
    { lat: 48.463885, lng: 18.021221 },
    { lat: 48.463934, lng: 18.020992 },
    { lat: 48.463977, lng: 18.020774 },
    { lat: 48.464039, lng: 18.020511 },
    { lat: 48.464053, lng: 18.020454 },
    { lat: 48.464121, lng: 18.020197 },
    { lat: 48.464179, lng: 18.019972 },
    { lat: 48.464223, lng: 18.019808 },
    { lat: 48.464234, lng: 18.019761 },
    { lat: 48.464283, lng: 18.019579 },
    { lat: 48.464323, lng: 18.019439 },
    { lat: 48.464321, lng: 18.019375 },
    { lat: 48.464317, lng: 18.019233 },
    { lat: 48.464317, lng: 18.01921 },
    { lat: 48.464314, lng: 18.019014 },
    { lat: 48.464315, lng: 18.019013 },
    { lat: 48.46433, lng: 18.01893 },
    { lat: 48.464378, lng: 18.018639 },
    { lat: 48.464414, lng: 18.01842 },
    { lat: 48.464435, lng: 18.018292 },
    { lat: 48.464439, lng: 18.018256 },
    { lat: 48.46444, lng: 18.01821 },
    { lat: 48.464442, lng: 18.018203 },
    { lat: 48.464491, lng: 18.018094 },
    { lat: 48.464511, lng: 18.01805 },
    { lat: 48.464609, lng: 18.017819 },
    { lat: 48.464616, lng: 18.017805 },
    { lat: 48.464645, lng: 18.017738 },
    { lat: 48.464688, lng: 18.017634 },
    { lat: 48.464726, lng: 18.017544 },
    { lat: 48.464703, lng: 18.017523 },
    { lat: 48.46467, lng: 18.017499 },
    { lat: 48.464575, lng: 18.017435 },
    { lat: 48.464497, lng: 18.017382 },
    { lat: 48.464496, lng: 18.017381 },
    { lat: 48.464368, lng: 18.017296 },
    { lat: 48.464151, lng: 18.01715 },
    { lat: 48.463948, lng: 18.017013 },
    { lat: 48.463768, lng: 18.016893 },
    { lat: 48.463739, lng: 18.016873 },
    { lat: 48.463585, lng: 18.01677 },
    { lat: 48.463447, lng: 18.016677 },
    { lat: 48.463389, lng: 18.016634 },
    { lat: 48.463423, lng: 18.016417 },
    { lat: 48.463471, lng: 18.016278 },
    { lat: 48.463534, lng: 18.016098 },
    { lat: 48.46355, lng: 18.016052 },
    { lat: 48.463564, lng: 18.016013 },
    { lat: 48.463575, lng: 18.01598 },
    { lat: 48.463624, lng: 18.015833 },
    { lat: 48.463643, lng: 18.015777 },
    { lat: 48.463745, lng: 18.015496 },
    { lat: 48.463835, lng: 18.015236 },
    { lat: 48.464007, lng: 18.014922 },
    { lat: 48.464117, lng: 18.014719 },
    { lat: 48.464244, lng: 18.014484 },
    { lat: 48.464407, lng: 18.014224 },
    { lat: 48.464525, lng: 18.014035 },
    { lat: 48.464535, lng: 18.01402 },
    { lat: 48.46462, lng: 18.013882 },
    { lat: 48.464641, lng: 18.013844 },
    { lat: 48.464642, lng: 18.013842 },
    { lat: 48.464663, lng: 18.013807 },
    { lat: 48.464663, lng: 18.013806 },
    { lat: 48.464677, lng: 18.013704 },
    { lat: 48.464692, lng: 18.01358 },
    { lat: 48.464698, lng: 18.013509 },
    { lat: 48.464704, lng: 18.013376 },
    { lat: 48.464712, lng: 18.013293 },
    { lat: 48.464703, lng: 18.013211 },
    { lat: 48.464689, lng: 18.013165 },
    { lat: 48.464649, lng: 18.013034 },
    { lat: 48.464611, lng: 18.012938 },
    { lat: 48.464545, lng: 18.01277 },
    { lat: 48.464459, lng: 18.012592 },
    { lat: 48.464398, lng: 18.012462 },
    { lat: 48.464343, lng: 18.012346 },
    { lat: 48.464278, lng: 18.012207 },
    { lat: 48.464215, lng: 18.012087 },
    { lat: 48.46416, lng: 18.011979 },
    { lat: 48.464102, lng: 18.011876 },
    { lat: 48.464047, lng: 18.011776 },
    { lat: 48.464013, lng: 18.011708 },
    { lat: 48.463944, lng: 18.011554 },
    { lat: 48.463871, lng: 18.011381 },
    { lat: 48.463836, lng: 18.011291 },
    { lat: 48.463773, lng: 18.011117 },
    { lat: 48.463761, lng: 18.01107 },
    { lat: 48.463729, lng: 18.01094 },
    { lat: 48.463709, lng: 18.01085 },
    { lat: 48.463687, lng: 18.010776 },
    { lat: 48.463654, lng: 18.010662 },
    { lat: 48.463637, lng: 18.010612 },
    { lat: 48.463566, lng: 18.010392 },
    { lat: 48.463551, lng: 18.010331 },
    { lat: 48.463501, lng: 18.010137 },
    { lat: 48.463487, lng: 18.010066 },
    { lat: 48.463459, lng: 18.009919 },
    { lat: 48.463452, lng: 18.009861 },
    { lat: 48.463427, lng: 18.009648 },
    { lat: 48.463428, lng: 18.00964 },
    { lat: 48.463439, lng: 18.00948 },
    { lat: 48.463445, lng: 18.00936 },
    { lat: 48.463448, lng: 18.00925 },
    { lat: 48.463454, lng: 18.008994 },
    { lat: 48.463467, lng: 18.008796 },
    { lat: 48.463472, lng: 18.008684 },
    { lat: 48.463539, lng: 18.008431 },
    { lat: 48.463587, lng: 18.008237 },
    { lat: 48.463627, lng: 18.008084 },
    { lat: 48.463665, lng: 18.007858 },
    { lat: 48.463694, lng: 18.007685 },
    { lat: 48.463651, lng: 18.0074 },
    { lat: 48.46361, lng: 18.007133 },
    { lat: 48.463605, lng: 18.007084 },
    { lat: 48.463601, lng: 18.006979 },
    { lat: 48.463593, lng: 18.006758 },
    { lat: 48.463588, lng: 18.006607 },
    { lat: 48.463579, lng: 18.006344 },
    { lat: 48.463573, lng: 18.006227 },
    { lat: 48.463554, lng: 18.005807 },
    { lat: 48.463543, lng: 18.005502 },
    { lat: 48.463525, lng: 18.005043 },
    { lat: 48.463518, lng: 18.004671 },
    { lat: 48.463512, lng: 18.004371 },
    { lat: 48.463524, lng: 18.004131 },
    { lat: 48.463537, lng: 18.003888 },
    { lat: 48.463539, lng: 18.003805 },
    { lat: 48.463498, lng: 18.003636 },
    { lat: 48.463466, lng: 18.003509 },
    { lat: 48.46343, lng: 18.003366 },
    { lat: 48.463409, lng: 18.003297 },
    { lat: 48.463369, lng: 18.003168 },
    { lat: 48.46327, lng: 18.002865 },
    { lat: 48.463225, lng: 18.002718 },
    { lat: 48.463111, lng: 18.002767 },
    { lat: 48.462992, lng: 18.002818 },
    { lat: 48.46288, lng: 18.002861 },
    { lat: 48.462648, lng: 18.00295 },
    { lat: 48.462613, lng: 18.002962 },
    { lat: 48.462533, lng: 18.00296 },
    { lat: 48.462475, lng: 18.002958 },
    { lat: 48.462194, lng: 18.002952 },
    { lat: 48.462136, lng: 18.00295 },
    { lat: 48.462011, lng: 18.002946 },
    { lat: 48.461905, lng: 18.00294 },
    { lat: 48.461876, lng: 18.002952 },
    { lat: 48.461643, lng: 18.003033 },
    { lat: 48.461638, lng: 18.003034 },
    { lat: 48.461373, lng: 18.003129 },
    { lat: 48.461276, lng: 18.003166 },
    { lat: 48.461067, lng: 18.003244 },
    { lat: 48.460942, lng: 18.00329 },
    { lat: 48.460817, lng: 18.003362 },
    { lat: 48.460816, lng: 18.003363 },
    { lat: 48.460601, lng: 18.003484 },
    { lat: 48.460397, lng: 18.003649 },
    { lat: 48.460313, lng: 18.003737 },
    { lat: 48.46031, lng: 18.00374 },
    { lat: 48.46, lng: 18.004052 },
    { lat: 48.45973, lng: 18.00433 },
    { lat: 48.459574, lng: 18.004493 },
    { lat: 48.459422, lng: 18.004651 },
    { lat: 48.45937, lng: 18.004708 },
    { lat: 48.4593, lng: 18.004727 },
    { lat: 48.459286, lng: 18.004731 },
    { lat: 48.459268, lng: 18.004734 },
    { lat: 48.459149, lng: 18.004761 },
    { lat: 48.459046, lng: 18.004785 },
    { lat: 48.458971, lng: 18.004808 },
    { lat: 48.458901, lng: 18.004831 },
    { lat: 48.458784, lng: 18.004869 },
    { lat: 48.458673, lng: 18.004906 },
    { lat: 48.458619, lng: 18.004924 },
    { lat: 48.45853, lng: 18.004953 },
    { lat: 48.458339, lng: 18.005046 },
    { lat: 48.458194, lng: 18.005112 },
    { lat: 48.458023, lng: 18.005214 },
    { lat: 48.457958, lng: 18.005254 },
    { lat: 48.457885, lng: 18.005295 },
    { lat: 48.457896, lng: 18.005408 },
    { lat: 48.45766, lng: 18.005434 },
    { lat: 48.457591, lng: 18.00544 },
    { lat: 48.457483, lng: 18.005424 },
    { lat: 48.457425, lng: 18.005417 },
    { lat: 48.457341, lng: 18.005373 },
    { lat: 48.457223, lng: 18.005307 },
    { lat: 48.457178, lng: 18.005278 },
    { lat: 48.457129, lng: 18.005241 },
    { lat: 48.457054, lng: 18.005186 },
    { lat: 48.456995, lng: 18.005141 },
    { lat: 48.456921, lng: 18.005086 },
    { lat: 48.456778, lng: 18.004978 },
    { lat: 48.456733, lng: 18.004945 },
    { lat: 48.456629, lng: 18.004835 },
    { lat: 48.456521, lng: 18.00472 },
    { lat: 48.456402, lng: 18.004594 },
    { lat: 48.456307, lng: 18.004494 },
    { lat: 48.456197, lng: 18.004381 },
    { lat: 48.45608, lng: 18.004257 },
    { lat: 48.455924, lng: 18.004095 },
    { lat: 48.455721, lng: 18.003882 },
    { lat: 48.455643, lng: 18.003801 },
    { lat: 48.455631, lng: 18.003788 },
    { lat: 48.455651, lng: 18.00372 },
    { lat: 48.4538951, lng: 18.0025295 },
    { lat: 48.4530479, lng: 18.0022162 },
    { lat: 48.4525206, lng: 18.0017975 },
    { lat: 48.4525032, lng: 18.0017871 },
    { lat: 48.4521268, lng: 18.0016465 },
    { lat: 48.4517257, lng: 18.001619 },
    { lat: 48.4514048, lng: 18.0014045 },
    { lat: 48.4504947, lng: 18.0013417 },
    { lat: 48.4496317, lng: 18.0013865 },
    { lat: 48.4490736, lng: 18.0012838 },
    { lat: 48.4486986, lng: 18.0010869 },
    { lat: 48.4482707, lng: 18.0010865 },
    { lat: 48.4477786, lng: 18.0007659 },
    { lat: 48.4473806, lng: 18.0006005 },
    { lat: 48.4470706, lng: 18.0005896 },
    { lat: 48.4467461, lng: 18.0003318 },
    { lat: 48.4463457, lng: 18.0001852 },
    { lat: 48.4462987, lng: 18.0001752 },
    { lat: 48.4452731, lng: 18.0005788 },
    { lat: 48.4446216, lng: 18.0009534 },
    { lat: 48.4433908, lng: 18.0014949 },
    { lat: 48.4426718, lng: 18.0016855 },
    { lat: 48.4419984, lng: 18.0021096 },
    { lat: 48.4412624, lng: 18.002407 },
    { lat: 48.439639, lng: 18.003464 },
    { lat: 48.4387529, lng: 18.0041655 },
    { lat: 48.4367798, lng: 18.00545 },
    { lat: 48.4360373, lng: 18.0058701 },
    { lat: 48.4349868, lng: 18.0063292 },
    { lat: 48.4347544, lng: 18.0065609 },
    { lat: 48.4339297, lng: 18.0077221 },
    { lat: 48.4337055, lng: 18.0074444 },
    { lat: 48.4335568, lng: 18.0073731 },
    { lat: 48.4330363, lng: 18.0071637 },
    { lat: 48.4321616, lng: 18.0069724 },
    { lat: 48.4311834, lng: 18.0070155 },
    { lat: 48.4305163, lng: 18.007264 },
    { lat: 48.4304956, lng: 18.0072746 },
    { lat: 48.4303838, lng: 18.0090198 },
    { lat: 48.4306949, lng: 18.009773 },
    { lat: 48.4313864, lng: 18.0106571 },
    { lat: 48.4303375, lng: 18.0116748 },
    { lat: 48.4296805, lng: 18.0122201 },
    { lat: 48.4298976, lng: 18.0134608 },
    { lat: 48.4299714, lng: 18.0138116 },
    { lat: 48.4300078, lng: 18.0138341 },
    { lat: 48.430317, lng: 18.0156566 },
    { lat: 48.4310386, lng: 18.0212245 },
    { lat: 48.4311421, lng: 18.0223411 },
    { lat: 48.4310596, lng: 18.0227563 },
    { lat: 48.4310782, lng: 18.0230701 },
    { lat: 48.4311698, lng: 18.0233211 },
    { lat: 48.4313396, lng: 18.0245085 },
    { lat: 48.4315479, lng: 18.0250492 },
    { lat: 48.4316116, lng: 18.0253648 },
    { lat: 48.4317465, lng: 18.0264744 },
    { lat: 48.4318546, lng: 18.0289452 },
    { lat: 48.432112, lng: 18.0302176 },
    { lat: 48.4319574, lng: 18.0304103 },
    { lat: 48.4314369, lng: 18.0307313 },
    { lat: 48.4315442, lng: 18.0312841 },
    { lat: 48.4317147, lng: 18.0328667 },
    { lat: 48.4318262, lng: 18.0334907 },
    { lat: 48.431901, lng: 18.0360993 },
    { lat: 48.4319643, lng: 18.0364857 },
    { lat: 48.4322019, lng: 18.0371593 },
    { lat: 48.4325921, lng: 18.0379754 },
    { lat: 48.4326529, lng: 18.0382196 },
    { lat: 48.4326906, lng: 18.038684 },
    { lat: 48.4326709, lng: 18.0399323 },
    { lat: 48.4322775, lng: 18.0422076 },
    { lat: 48.4323169, lng: 18.0425891 },
    { lat: 48.4324496, lng: 18.0429402 },
    { lat: 48.4325991, lng: 18.0431814 },
    { lat: 48.4330265, lng: 18.0437448 },
    { lat: 48.4332415, lng: 18.0441369 },
    { lat: 48.4338699, lng: 18.0458668 },
    { lat: 48.4341349, lng: 18.0463756 },
    { lat: 48.4346812, lng: 18.0479417 },
    { lat: 48.4356185, lng: 18.0496863 },
    { lat: 48.4359793, lng: 18.050216 },
    { lat: 48.436127, lng: 18.05062 },
    { lat: 48.436323, lng: 18.050912 },
    { lat: 48.436681, lng: 18.051545 },
    { lat: 48.436885, lng: 18.051854 },
    { lat: 48.436989, lng: 18.051973 },
    { lat: 48.437274, lng: 18.052237 },
    { lat: 48.437441, lng: 18.052338 },
    { lat: 48.437559, lng: 18.052399 },
    { lat: 48.437934, lng: 18.052541 },
    { lat: 48.438249, lng: 18.052586 },
    { lat: 48.438256, lng: 18.052587 },
    { lat: 48.43845, lng: 18.052612 },
    { lat: 48.438626, lng: 18.05266 },
    { lat: 48.438793, lng: 18.05271 },
    { lat: 48.439043, lng: 18.052788 },
    { lat: 48.439085, lng: 18.052809 },
    { lat: 48.43921, lng: 18.052867 },
    { lat: 48.439384, lng: 18.052985 },
    { lat: 48.439469, lng: 18.053041 },
    { lat: 48.439497, lng: 18.053079 },
    { lat: 48.439677, lng: 18.053282 },
    { lat: 48.439875, lng: 18.053488 },
    { lat: 48.439969, lng: 18.053623 },
    { lat: 48.440122, lng: 18.053835 },
    { lat: 48.440327, lng: 18.054129 },
    { lat: 48.440396, lng: 18.054207 },
    { lat: 48.440602, lng: 18.054436 },
    { lat: 48.440809, lng: 18.054629 },
    { lat: 48.44095, lng: 18.054806 },
    { lat: 48.441055, lng: 18.054905 },
    { lat: 48.441329, lng: 18.055142 },
    { lat: 48.441492, lng: 18.055247 },
    { lat: 48.441581, lng: 18.055293 },
    { lat: 48.44186, lng: 18.055567 },
    { lat: 48.441896, lng: 18.055609 },
    { lat: 48.442154, lng: 18.055777 },
    { lat: 48.442334, lng: 18.055924 },
    { lat: 48.442576, lng: 18.056108 },
    { lat: 48.442888, lng: 18.056356 },
    { lat: 48.443054, lng: 18.056485 },
    { lat: 48.443092, lng: 18.056867 },
    { lat: 48.443113, lng: 18.056869 },
    { lat: 48.44314, lng: 18.056893 },
    { lat: 48.443567, lng: 18.057067 },
    { lat: 48.443769, lng: 18.057151 },
    { lat: 48.443907, lng: 18.057204 },
  ],
  NováVesnadŽitavou: [
    { lat: 48.2748794, lng: 18.3477346 },
    { lat: 48.2750161, lng: 18.3480359 },
    { lat: 48.2753644, lng: 18.3486284 },
    { lat: 48.2764844, lng: 18.3500138 },
    { lat: 48.2770963, lng: 18.3508774 },
    { lat: 48.277987, lng: 18.351808 },
    { lat: 48.278033, lng: 18.351788 },
    { lat: 48.278057, lng: 18.351775 },
    { lat: 48.278108, lng: 18.351745 },
    { lat: 48.278298, lng: 18.351361 },
    { lat: 48.278483, lng: 18.351042 },
    { lat: 48.278687, lng: 18.350683 },
    { lat: 48.279119, lng: 18.350679 },
    { lat: 48.279319, lng: 18.35068 },
    { lat: 48.27946, lng: 18.35067 },
    { lat: 48.279587, lng: 18.350656 },
    { lat: 48.279646, lng: 18.350648 },
    { lat: 48.279683, lng: 18.350642 },
    { lat: 48.279727, lng: 18.350469 },
    { lat: 48.27982, lng: 18.350128 },
    { lat: 48.279895, lng: 18.349952 },
    { lat: 48.280224, lng: 18.349809 },
    { lat: 48.280402, lng: 18.3497 },
    { lat: 48.280709, lng: 18.349511 },
    { lat: 48.280914, lng: 18.349386 },
    { lat: 48.281135, lng: 18.349188 },
    { lat: 48.281497, lng: 18.348938 },
    { lat: 48.281827, lng: 18.348708 },
    { lat: 48.281929, lng: 18.34864 },
    { lat: 48.282118, lng: 18.348579 },
    { lat: 48.282315, lng: 18.348594 },
    { lat: 48.282338, lng: 18.348605 },
    { lat: 48.282463, lng: 18.348656 },
    { lat: 48.282513, lng: 18.348693 },
    { lat: 48.282567, lng: 18.348724 },
    { lat: 48.282621, lng: 18.348763 },
    { lat: 48.282699, lng: 18.348829 },
    { lat: 48.282774, lng: 18.348892 },
    { lat: 48.282928, lng: 18.349029 },
    { lat: 48.283036, lng: 18.349112 },
    { lat: 48.283141, lng: 18.349194 },
    { lat: 48.283211, lng: 18.34925 },
    { lat: 48.283272, lng: 18.349297 },
    { lat: 48.283455, lng: 18.349379 },
    { lat: 48.283562, lng: 18.349427 },
    { lat: 48.28367, lng: 18.349489 },
    { lat: 48.283773, lng: 18.34955 },
    { lat: 48.283922, lng: 18.349667 },
    { lat: 48.284077, lng: 18.349777 },
    { lat: 48.284126, lng: 18.349813 },
    { lat: 48.284179, lng: 18.349855 },
    { lat: 48.284228, lng: 18.349891 },
    { lat: 48.284444, lng: 18.350039 },
    { lat: 48.284554, lng: 18.35011 },
    { lat: 48.284654, lng: 18.350177 },
    { lat: 48.284722, lng: 18.350217 },
    { lat: 48.284784, lng: 18.350258 },
    { lat: 48.284815, lng: 18.350285 },
    { lat: 48.284919, lng: 18.350043 },
    { lat: 48.284952, lng: 18.350057 },
    { lat: 48.285175, lng: 18.350107 },
    { lat: 48.285278, lng: 18.35015 },
    { lat: 48.285392, lng: 18.350203 },
    { lat: 48.285458, lng: 18.350227 },
    { lat: 48.285537, lng: 18.350255 },
    { lat: 48.28561, lng: 18.350286 },
    { lat: 48.286052, lng: 18.350491 },
    { lat: 48.286187, lng: 18.350579 },
    { lat: 48.286251, lng: 18.350625 },
    { lat: 48.286325, lng: 18.350671 },
    { lat: 48.286458, lng: 18.350754 },
    { lat: 48.286534, lng: 18.350804 },
    { lat: 48.286566, lng: 18.350822 },
    { lat: 48.286703, lng: 18.350936 },
    { lat: 48.286807, lng: 18.351022 },
    { lat: 48.287016, lng: 18.351202 },
    { lat: 48.287066, lng: 18.351246 },
    { lat: 48.287214, lng: 18.35141 },
    { lat: 48.28725, lng: 18.351452 },
    { lat: 48.287292, lng: 18.351498 },
    { lat: 48.28737, lng: 18.351579 },
    { lat: 48.287447, lng: 18.351655 },
    { lat: 48.287553, lng: 18.351772 },
    { lat: 48.287711, lng: 18.351939 },
    { lat: 48.287774, lng: 18.35201 },
    { lat: 48.287855, lng: 18.352152 },
    { lat: 48.288016, lng: 18.352459 },
    { lat: 48.288039, lng: 18.352499 },
    { lat: 48.288091, lng: 18.352602 },
    { lat: 48.288157, lng: 18.352727 },
    { lat: 48.288281, lng: 18.352996 },
    { lat: 48.28832, lng: 18.353087 },
    { lat: 48.288346, lng: 18.353161 },
    { lat: 48.288391, lng: 18.353297 },
    { lat: 48.288402, lng: 18.353343 },
    { lat: 48.288468, lng: 18.353565 },
    { lat: 48.288516, lng: 18.353706 },
    { lat: 48.288559, lng: 18.353777 },
    { lat: 48.288658, lng: 18.353942 },
    { lat: 48.288755, lng: 18.354101 },
    { lat: 48.288821, lng: 18.354212 },
    { lat: 48.288987, lng: 18.354492 },
    { lat: 48.289164, lng: 18.354777 },
    { lat: 48.289217, lng: 18.354861 },
    { lat: 48.28924, lng: 18.35489 },
    { lat: 48.289264, lng: 18.354934 },
    { lat: 48.289361, lng: 18.355077 },
    { lat: 48.28945, lng: 18.355207 },
    { lat: 48.289594, lng: 18.355428 },
    { lat: 48.289741, lng: 18.355631 },
    { lat: 48.289795, lng: 18.355703 },
    { lat: 48.290037, lng: 18.355992 },
    { lat: 48.290539, lng: 18.356854 },
    { lat: 48.290835, lng: 18.357357 },
    { lat: 48.291063, lng: 18.357744 },
    { lat: 48.291446, lng: 18.358386 },
    { lat: 48.291722, lng: 18.358863 },
    { lat: 48.292681, lng: 18.357254 },
    { lat: 48.292884, lng: 18.356933 },
    { lat: 48.293212, lng: 18.356299 },
    { lat: 48.293502, lng: 18.35595 },
    { lat: 48.294094, lng: 18.355387 },
    { lat: 48.294618, lng: 18.353802 },
    { lat: 48.294781, lng: 18.352384 },
    { lat: 48.295319, lng: 18.351227 },
    { lat: 48.295496, lng: 18.350854 },
    { lat: 48.295639, lng: 18.350548 },
    { lat: 48.295776, lng: 18.34961 },
    { lat: 48.295725, lng: 18.348616 },
    { lat: 48.295605, lng: 18.347309 },
    { lat: 48.295567, lng: 18.346859 },
    { lat: 48.295395, lng: 18.345033 },
    { lat: 48.295374, lng: 18.344364 },
    { lat: 48.295388, lng: 18.34381 },
    { lat: 48.295499, lng: 18.343383 },
    { lat: 48.295606, lng: 18.342973 },
    { lat: 48.295638, lng: 18.342853 },
    { lat: 48.295899, lng: 18.341849 },
    { lat: 48.295929, lng: 18.341749 },
    { lat: 48.295742, lng: 18.341445 },
    { lat: 48.295637, lng: 18.341249 },
    { lat: 48.295435, lng: 18.340934 },
    { lat: 48.295325, lng: 18.340806 },
    { lat: 48.295325, lng: 18.340655 },
    { lat: 48.295389, lng: 18.339547 },
    { lat: 48.295392, lng: 18.339501 },
    { lat: 48.29549, lng: 18.337391 },
    { lat: 48.295594, lng: 18.335548 },
    { lat: 48.295598, lng: 18.335427 },
    { lat: 48.295645, lng: 18.334477 },
    { lat: 48.295701, lng: 18.333337 },
    { lat: 48.295666, lng: 18.333321 },
    { lat: 48.296117, lng: 18.331684 },
    { lat: 48.296455, lng: 18.330461 },
    { lat: 48.296818, lng: 18.329134 },
    { lat: 48.2968569, lng: 18.3290256 },
    { lat: 48.296869, lng: 18.328968 },
    { lat: 48.297563, lng: 18.326457 },
    { lat: 48.297619, lng: 18.326273 },
    { lat: 48.298057, lng: 18.324668 },
    { lat: 48.298477, lng: 18.323111 },
    { lat: 48.298482, lng: 18.321 },
    { lat: 48.298485, lng: 18.319087 },
    { lat: 48.29848, lng: 18.317252 },
    { lat: 48.298484, lng: 18.315046 },
    { lat: 48.29846, lng: 18.314877 },
    { lat: 48.298372, lng: 18.313449 },
    { lat: 48.298289, lng: 18.312091 },
    { lat: 48.298283, lng: 18.311876 },
    { lat: 48.298227, lng: 18.309588 },
    { lat: 48.298251, lng: 18.309586 },
    { lat: 48.29822, lng: 18.30914 },
    { lat: 48.298189, lng: 18.308694 },
    { lat: 48.298188, lng: 18.308681 },
    { lat: 48.298187, lng: 18.30867 },
    { lat: 48.298186, lng: 18.308653 },
    { lat: 48.298183, lng: 18.308627 },
    { lat: 48.298179, lng: 18.30856 },
    { lat: 48.298176, lng: 18.308532 },
    { lat: 48.298175, lng: 18.308515 },
    { lat: 48.298175, lng: 18.308499 },
    { lat: 48.298173, lng: 18.308448 },
    { lat: 48.298144, lng: 18.307078 },
    { lat: 48.298468, lng: 18.307046 },
    { lat: 48.298787, lng: 18.307013 },
    { lat: 48.298805, lng: 18.306998 },
    { lat: 48.298857, lng: 18.306967 },
    { lat: 48.298912, lng: 18.306977 },
    { lat: 48.299021, lng: 18.306995 },
    { lat: 48.299132, lng: 18.307015 },
    { lat: 48.299474, lng: 18.307075 },
    { lat: 48.299795, lng: 18.307133 },
    { lat: 48.299826, lng: 18.307147 },
    { lat: 48.299946, lng: 18.307229 },
    { lat: 48.300027, lng: 18.307283 },
    { lat: 48.30007, lng: 18.307346 },
    { lat: 48.300164, lng: 18.307487 },
    { lat: 48.300194, lng: 18.30756 },
    { lat: 48.300261, lng: 18.307722 },
    { lat: 48.300335, lng: 18.308153 },
    { lat: 48.300376, lng: 18.308247 },
    { lat: 48.300488, lng: 18.308233 },
    { lat: 48.300612, lng: 18.308256 },
    { lat: 48.3005622, lng: 18.3066703 },
    { lat: 48.3002736, lng: 18.3037462 },
    { lat: 48.3001032, lng: 18.3025427 },
    { lat: 48.2997732, lng: 18.3008507 },
    { lat: 48.2997628, lng: 18.3005313 },
    { lat: 48.2998376, lng: 18.3000607 },
    { lat: 48.2999577, lng: 18.2996754 },
    { lat: 48.2999689, lng: 18.299338 },
    { lat: 48.2999065, lng: 18.2991355 },
    { lat: 48.299767, lng: 18.2991644 },
    { lat: 48.2979761, lng: 18.2994554 },
    { lat: 48.2969565, lng: 18.2996978 },
    { lat: 48.2960955, lng: 18.3000117 },
    { lat: 48.2961946, lng: 18.3010177 },
    { lat: 48.295792, lng: 18.3011792 },
    { lat: 48.2952728, lng: 18.3012755 },
    { lat: 48.2933123, lng: 18.3018766 },
    { lat: 48.2884599, lng: 18.3030319 },
    { lat: 48.2870568, lng: 18.303515 },
    { lat: 48.2865326, lng: 18.3036385 },
    { lat: 48.284868, lng: 18.3043861 },
    { lat: 48.2823933, lng: 18.3049937 },
    { lat: 48.2822187, lng: 18.3047794 },
    { lat: 48.2819182, lng: 18.3024229 },
    { lat: 48.2819061, lng: 18.3015792 },
    { lat: 48.2820294, lng: 18.30024 },
    { lat: 48.2819064, lng: 18.2990552 },
    { lat: 48.2814272, lng: 18.2974791 },
    { lat: 48.2805927, lng: 18.2953176 },
    { lat: 48.2800284, lng: 18.295593 },
    { lat: 48.2777973, lng: 18.2969642 },
    { lat: 48.2766666, lng: 18.2973189 },
    { lat: 48.2765227, lng: 18.2967384 },
    { lat: 48.2757841, lng: 18.2943941 },
    { lat: 48.2744336, lng: 18.290314 },
    { lat: 48.2732234, lng: 18.2913665 },
    { lat: 48.2722551, lng: 18.2919562 },
    { lat: 48.2722542, lng: 18.2918408 },
    { lat: 48.2721625, lng: 18.2916606 },
    { lat: 48.2716147, lng: 18.291194 },
    { lat: 48.2702253, lng: 18.2915424 },
    { lat: 48.2691776, lng: 18.2919644 },
    { lat: 48.2691973, lng: 18.2920352 },
    { lat: 48.2696739, lng: 18.2939942 },
    { lat: 48.2704961, lng: 18.2978949 },
    { lat: 48.2708308, lng: 18.2991922 },
    { lat: 48.2709404, lng: 18.2992525 },
    { lat: 48.2709748, lng: 18.2992839 },
    { lat: 48.2711465, lng: 18.3006098 },
    { lat: 48.2714393, lng: 18.3005808 },
    { lat: 48.2714714, lng: 18.3005306 },
    { lat: 48.2716244, lng: 18.3016068 },
    { lat: 48.2713714, lng: 18.3034207 },
    { lat: 48.271709, lng: 18.3035439 },
    { lat: 48.2716357, lng: 18.3054831 },
    { lat: 48.2716713, lng: 18.3075104 },
    { lat: 48.2720756, lng: 18.3075355 },
    { lat: 48.2725454, lng: 18.3077009 },
    { lat: 48.2724715, lng: 18.3088388 },
    { lat: 48.2725262, lng: 18.3118153 },
    { lat: 48.272704, lng: 18.3119434 },
    { lat: 48.2727888, lng: 18.3118107 },
    { lat: 48.2732689, lng: 18.3117581 },
    { lat: 48.2733761, lng: 18.3118184 },
    { lat: 48.2735463, lng: 18.3120754 },
    { lat: 48.2738022, lng: 18.3119523 },
    { lat: 48.2740121, lng: 18.311929 },
    { lat: 48.2741237, lng: 18.3120089 },
    { lat: 48.2743314, lng: 18.3123667 },
    { lat: 48.274426, lng: 18.3123627 },
    { lat: 48.274608, lng: 18.3122084 },
    { lat: 48.274824, lng: 18.3122197 },
    { lat: 48.2749621, lng: 18.3123437 },
    { lat: 48.2749298, lng: 18.3124704 },
    { lat: 48.27512, lng: 18.3130191 },
    { lat: 48.2753361, lng: 18.3131693 },
    { lat: 48.2754284, lng: 18.3134819 },
    { lat: 48.2754293, lng: 18.313655 },
    { lat: 48.2755231, lng: 18.3138537 },
    { lat: 48.2756451, lng: 18.313956 },
    { lat: 48.2757115, lng: 18.3141725 },
    { lat: 48.2758265, lng: 18.3141485 },
    { lat: 48.2759391, lng: 18.3139461 },
    { lat: 48.2760275, lng: 18.3138951 },
    { lat: 48.276093, lng: 18.3137181 },
    { lat: 48.2763954, lng: 18.3139383 },
    { lat: 48.2763655, lng: 18.3140428 },
    { lat: 48.2762366, lng: 18.3139806 },
    { lat: 48.2760938, lng: 18.3141462 },
    { lat: 48.2760918, lng: 18.3145669 },
    { lat: 48.2761898, lng: 18.3146815 },
    { lat: 48.2761939, lng: 18.3147734 },
    { lat: 48.2761224, lng: 18.315225 },
    { lat: 48.2762204, lng: 18.3156173 },
    { lat: 48.2765042, lng: 18.315825 },
    { lat: 48.2760907, lng: 18.316904 },
    { lat: 48.2761796, lng: 18.3172366 },
    { lat: 48.276181, lng: 18.3174815 },
    { lat: 48.2759859, lng: 18.3182152 },
    { lat: 48.2760486, lng: 18.3186024 },
    { lat: 48.2760494, lng: 18.3191517 },
    { lat: 48.2758876, lng: 18.3205902 },
    { lat: 48.2758655, lng: 18.3214689 },
    { lat: 48.2758593, lng: 18.3220109 },
    { lat: 48.2762623, lng: 18.3223533 },
    { lat: 48.2761666, lng: 18.3231688 },
    { lat: 48.2757463, lng: 18.3235572 },
    { lat: 48.2755817, lng: 18.324055 },
    { lat: 48.2762329, lng: 18.3244545 },
    { lat: 48.2758972, lng: 18.3253935 },
    { lat: 48.2757763, lng: 18.325529 },
    { lat: 48.2751321, lng: 18.3291425 },
    { lat: 48.2746155, lng: 18.3313873 },
    { lat: 48.2744869, lng: 18.3321411 },
    { lat: 48.2742823, lng: 18.3346135 },
    { lat: 48.2737481, lng: 18.3427506 },
    { lat: 48.2737989, lng: 18.3442036 },
    { lat: 48.2735321, lng: 18.3455643 },
    { lat: 48.2740523, lng: 18.3461242 },
    { lat: 48.2742939, lng: 18.346465 },
    { lat: 48.274807, lng: 18.347554 },
    { lat: 48.2748794, lng: 18.3477346 },
  ],
  VeľkéChyndice: [
    { lat: 48.2690078, lng: 18.2913438 },
    { lat: 48.2690121, lng: 18.291361 },
    { lat: 48.2691776, lng: 18.2919644 },
    { lat: 48.2702253, lng: 18.2915424 },
    { lat: 48.2716147, lng: 18.291194 },
    { lat: 48.2721625, lng: 18.2916606 },
    { lat: 48.2722542, lng: 18.2918408 },
    { lat: 48.2722551, lng: 18.2919562 },
    { lat: 48.2732234, lng: 18.2913665 },
    { lat: 48.2744336, lng: 18.290314 },
    { lat: 48.2757841, lng: 18.2943941 },
    { lat: 48.2765227, lng: 18.2967384 },
    { lat: 48.2766666, lng: 18.2973189 },
    { lat: 48.2777973, lng: 18.2969642 },
    { lat: 48.2800284, lng: 18.295593 },
    { lat: 48.2805927, lng: 18.2953176 },
    { lat: 48.2814272, lng: 18.2974791 },
    { lat: 48.2819064, lng: 18.2990552 },
    { lat: 48.2820294, lng: 18.30024 },
    { lat: 48.2819061, lng: 18.3015792 },
    { lat: 48.2819182, lng: 18.3024229 },
    { lat: 48.2822187, lng: 18.3047794 },
    { lat: 48.2823933, lng: 18.3049937 },
    { lat: 48.284868, lng: 18.3043861 },
    { lat: 48.2865326, lng: 18.3036385 },
    { lat: 48.2870568, lng: 18.303515 },
    { lat: 48.2884599, lng: 18.3030319 },
    { lat: 48.2933123, lng: 18.3018766 },
    { lat: 48.2952728, lng: 18.3012755 },
    { lat: 48.295792, lng: 18.3011792 },
    { lat: 48.2961946, lng: 18.3010177 },
    { lat: 48.2960955, lng: 18.3000117 },
    { lat: 48.2969565, lng: 18.2996978 },
    { lat: 48.2979761, lng: 18.2994554 },
    { lat: 48.299767, lng: 18.2991644 },
    { lat: 48.2999065, lng: 18.2991355 },
    { lat: 48.2998806, lng: 18.2990573 },
    { lat: 48.2996558, lng: 18.2986372 },
    { lat: 48.2992808, lng: 18.2976438 },
    { lat: 48.2986001, lng: 18.2964147 },
    { lat: 48.2982518, lng: 18.2949003 },
    { lat: 48.2980661, lng: 18.2938603 },
    { lat: 48.2980163, lng: 18.2938575 },
    { lat: 48.2972776, lng: 18.2899202 },
    { lat: 48.2967928, lng: 18.2878341 },
    { lat: 48.2963975, lng: 18.2858775 },
    { lat: 48.2961289, lng: 18.2848111 },
    { lat: 48.2959556, lng: 18.2847377 },
    { lat: 48.295332, lng: 18.2833066 },
    { lat: 48.2945334, lng: 18.2819133 },
    { lat: 48.2938062, lng: 18.2809771 },
    { lat: 48.2930077, lng: 18.2797628 },
    { lat: 48.2892962, lng: 18.2735539 },
    { lat: 48.2878605, lng: 18.2715589 },
    { lat: 48.2879394, lng: 18.2714428 },
    { lat: 48.2878379, lng: 18.2711441 },
    { lat: 48.287842, lng: 18.2707056 },
    { lat: 48.2868394, lng: 18.2693602 },
    { lat: 48.2857429, lng: 18.2675746 },
    { lat: 48.2851572, lng: 18.2667683 },
    { lat: 48.2820929, lng: 18.2707844 },
    { lat: 48.2815075, lng: 18.2693879 },
    { lat: 48.2755399, lng: 18.2768894 },
    { lat: 48.2756318, lng: 18.277095 },
    { lat: 48.2756124, lng: 18.2771485 },
    { lat: 48.2722753, lng: 18.2807587 },
    { lat: 48.2751946, lng: 18.2880365 },
    { lat: 48.2747724, lng: 18.2883058 },
    { lat: 48.2745577, lng: 18.2885575 },
    { lat: 48.2740673, lng: 18.2888996 },
    { lat: 48.2736606, lng: 18.2892671 },
    { lat: 48.2733184, lng: 18.2896957 },
    { lat: 48.2730067, lng: 18.2902032 },
    { lat: 48.2729564, lng: 18.2903701 },
    { lat: 48.2724314, lng: 18.2905781 },
    { lat: 48.2719139, lng: 18.2906254 },
    { lat: 48.2713005, lng: 18.2907924 },
    { lat: 48.270969, lng: 18.2906766 },
    { lat: 48.2707191, lng: 18.2906857 },
    { lat: 48.2691078, lng: 18.2912973 },
    { lat: 48.2690078, lng: 18.2913438 },
  ],
  Lužianky: [
    { lat: 48.3548583, lng: 18.0471491 },
    { lat: 48.3548558, lng: 18.0470518 },
    { lat: 48.354829, lng: 18.0461243 },
    { lat: 48.3547092, lng: 18.0453401 },
    { lat: 48.3546735, lng: 18.0452533 },
    { lat: 48.354156, lng: 18.0448611 },
    { lat: 48.3549099, lng: 18.0394011 },
    { lat: 48.3554373, lng: 18.0359822 },
    { lat: 48.3556406, lng: 18.0334955 },
    { lat: 48.3568808, lng: 18.0318016 },
    { lat: 48.3591781, lng: 18.0272506 },
    { lat: 48.3600429, lng: 18.0254302 },
    { lat: 48.3589927, lng: 18.0224819 },
    { lat: 48.3587686, lng: 18.021919 },
    { lat: 48.3584543, lng: 18.0207753 },
    { lat: 48.3578696, lng: 18.0191406 },
    { lat: 48.3577812, lng: 18.0187167 },
    { lat: 48.35769, lng: 18.0185508 },
    { lat: 48.357454, lng: 18.0177931 },
    { lat: 48.3574264, lng: 18.017575 },
    { lat: 48.3573502, lng: 18.0174678 },
    { lat: 48.3573157, lng: 18.0172694 },
    { lat: 48.3571501, lng: 18.0171404 },
    { lat: 48.356338, lng: 18.0144969 },
    { lat: 48.3558853, lng: 18.013718 },
    { lat: 48.3554579, lng: 18.0131734 },
    { lat: 48.3546667, lng: 18.0123242 },
    { lat: 48.3542563, lng: 18.0119701 },
    { lat: 48.3539998, lng: 18.0116419 },
    { lat: 48.3533664, lng: 18.0105775 },
    { lat: 48.3530828, lng: 18.0096056 },
    { lat: 48.352836, lng: 18.0090645 },
    { lat: 48.3523444, lng: 18.0082956 },
    { lat: 48.3522314, lng: 18.0082021 },
    { lat: 48.3520204, lng: 18.0081815 },
    { lat: 48.3504894, lng: 18.0083629 },
    { lat: 48.3493494, lng: 18.0088001 },
    { lat: 48.3489933, lng: 18.0086552 },
    { lat: 48.3485278, lng: 18.0080619 },
    { lat: 48.3480735, lng: 18.008278 },
    { lat: 48.3479491, lng: 18.0085005 },
    { lat: 48.3478623, lng: 18.0088055 },
    { lat: 48.3476407, lng: 18.0101077 },
    { lat: 48.3474342, lng: 18.0107343 },
    { lat: 48.347065, lng: 18.0115073 },
    { lat: 48.3469091, lng: 18.0115626 },
    { lat: 48.3466404, lng: 18.0115018 },
    { lat: 48.346182, lng: 18.0111017 },
    { lat: 48.3460091, lng: 18.0110209 },
    { lat: 48.3457815, lng: 18.0110486 },
    { lat: 48.3450168, lng: 18.0115507 },
    { lat: 48.3444852, lng: 18.0120453 },
    { lat: 48.3441926, lng: 18.0130396 },
    { lat: 48.3431637, lng: 18.0137137 },
    { lat: 48.3427214, lng: 18.0140941 },
    { lat: 48.3402829, lng: 18.0135145 },
    { lat: 48.3398576, lng: 18.0136131 },
    { lat: 48.3389602, lng: 18.0122665 },
    { lat: 48.3380383, lng: 18.0110044 },
    { lat: 48.3365932, lng: 18.009216 },
    { lat: 48.3364413, lng: 18.0090363 },
    { lat: 48.3355098, lng: 18.0083287 },
    { lat: 48.3351449, lng: 18.0075483 },
    { lat: 48.3347681, lng: 18.0069646 },
    { lat: 48.3342292, lng: 18.0067095 },
    { lat: 48.3340014, lng: 18.0063555 },
    { lat: 48.3338405, lng: 18.0062324 },
    { lat: 48.333378, lng: 18.0061029 },
    { lat: 48.3330907, lng: 18.006128 },
    { lat: 48.3329021, lng: 18.0059192 },
    { lat: 48.3327625, lng: 18.0058513 },
    { lat: 48.3326563, lng: 18.0056414 },
    { lat: 48.3324066, lng: 18.0053372 },
    { lat: 48.3321987, lng: 18.0050839 },
    { lat: 48.3312893, lng: 18.0032724 },
    { lat: 48.3305876, lng: 18.0012916 },
    { lat: 48.3300382, lng: 18.000163 },
    { lat: 48.3295845, lng: 17.9989193 },
    { lat: 48.3292905, lng: 17.9982462 },
    { lat: 48.3291636, lng: 17.9983158 },
    { lat: 48.3280353, lng: 17.9986071 },
    { lat: 48.3269847, lng: 17.999177 },
    { lat: 48.32512, lng: 18.0005543 },
    { lat: 48.3244196, lng: 18.0012431 },
    { lat: 48.3238932, lng: 18.0016319 },
    { lat: 48.3227374, lng: 18.0022151 },
    { lat: 48.3223783, lng: 18.00233 },
    { lat: 48.3221456, lng: 18.0024964 },
    { lat: 48.3217819, lng: 18.0029059 },
    { lat: 48.3215207, lng: 18.00307 },
    { lat: 48.3210182, lng: 18.0032747 },
    { lat: 48.3206793, lng: 18.0034964 },
    { lat: 48.3197512, lng: 18.0038497 },
    { lat: 48.3188417, lng: 18.0040409 },
    { lat: 48.3178984, lng: 18.0044441 },
    { lat: 48.317674, lng: 18.0046157 },
    { lat: 48.3172884, lng: 18.0051171 },
    { lat: 48.3162198, lng: 18.0062428 },
    { lat: 48.3152414, lng: 18.0069026 },
    { lat: 48.3140944, lng: 18.0074472 },
    { lat: 48.3141559, lng: 18.0083627 },
    { lat: 48.3143287, lng: 18.0093281 },
    { lat: 48.3143562, lng: 18.009769 },
    { lat: 48.3146412, lng: 18.0107847 },
    { lat: 48.3146222, lng: 18.0110726 },
    { lat: 48.3143634, lng: 18.0115399 },
    { lat: 48.3143028, lng: 18.0118484 },
    { lat: 48.3143458, lng: 18.0122646 },
    { lat: 48.3145036, lng: 18.0129079 },
    { lat: 48.3144052, lng: 18.0137359 },
    { lat: 48.3146756, lng: 18.0146546 },
    { lat: 48.3148137, lng: 18.0149276 },
    { lat: 48.3152877, lng: 18.0162689 },
    { lat: 48.3153884, lng: 18.0169196 },
    { lat: 48.3155925, lng: 18.0176933 },
    { lat: 48.3157483, lng: 18.0185454 },
    { lat: 48.3165385, lng: 18.021238 },
    { lat: 48.3177404, lng: 18.024309 },
    { lat: 48.3184378, lng: 18.0257454 },
    { lat: 48.3189096, lng: 18.0270373 },
    { lat: 48.3193547, lng: 18.0277444 },
    { lat: 48.319683, lng: 18.0284101 },
    { lat: 48.3202989, lng: 18.0293525 },
    { lat: 48.3214676, lng: 18.0313556 },
    { lat: 48.3230656, lng: 18.0342939 },
    { lat: 48.3241284, lng: 18.0363585 },
    { lat: 48.3248414, lng: 18.0381372 },
    { lat: 48.3252662, lng: 18.0394565 },
    { lat: 48.3256153, lng: 18.0402826 },
    { lat: 48.3261897, lng: 18.0414004 },
    { lat: 48.3324582, lng: 18.051641 },
    { lat: 48.3340664, lng: 18.0544022 },
    { lat: 48.3343091, lng: 18.0548434 },
    { lat: 48.3344518, lng: 18.0552518 },
    { lat: 48.3344487, lng: 18.0552951 },
    { lat: 48.3347499, lng: 18.0554057 },
    { lat: 48.3349422, lng: 18.055394 },
    { lat: 48.3349708, lng: 18.0547144 },
    { lat: 48.3350744, lng: 18.0543878 },
    { lat: 48.3350713, lng: 18.0542744 },
    { lat: 48.335043, lng: 18.054105 },
    { lat: 48.3348886, lng: 18.0538777 },
    { lat: 48.3350132, lng: 18.0537529 },
    { lat: 48.3354802, lng: 18.0537109 },
    { lat: 48.335654, lng: 18.0535958 },
    { lat: 48.335685, lng: 18.0534913 },
    { lat: 48.3354287, lng: 18.0529945 },
    { lat: 48.3354278, lng: 18.0528272 },
    { lat: 48.3359273, lng: 18.0524165 },
    { lat: 48.3360713, lng: 18.0521792 },
    { lat: 48.3352573, lng: 18.0507883 },
    { lat: 48.3351581, lng: 18.0501787 },
    { lat: 48.3351704, lng: 18.0499685 },
    { lat: 48.3352943, lng: 18.0496607 },
    { lat: 48.3353837, lng: 18.0495676 },
    { lat: 48.335529, lng: 18.0496582 },
    { lat: 48.3357484, lng: 18.0506189 },
    { lat: 48.3359621, lng: 18.0510427 },
    { lat: 48.3362132, lng: 18.0512217 },
    { lat: 48.3364351, lng: 18.0511595 },
    { lat: 48.3365521, lng: 18.0510587 },
    { lat: 48.3365978, lng: 18.050936 },
    { lat: 48.3366154, lng: 18.0507527 },
    { lat: 48.3365664, lng: 18.0504407 },
    { lat: 48.3361626, lng: 18.0501409 },
    { lat: 48.3362905, lng: 18.0497489 },
    { lat: 48.33642, lng: 18.0495309 },
    { lat: 48.336755, lng: 18.0493972 },
    { lat: 48.3369054, lng: 18.0488667 },
    { lat: 48.3364761, lng: 18.0471113 },
    { lat: 48.3389751, lng: 18.0465218 },
    { lat: 48.34007, lng: 18.046943 },
    { lat: 48.3404459, lng: 18.0474388 },
    { lat: 48.3408678, lng: 18.0477022 },
    { lat: 48.345278, lng: 18.0460009 },
    { lat: 48.347941, lng: 18.048314 },
    { lat: 48.3487644, lng: 18.0491823 },
    { lat: 48.3498073, lng: 18.0489928 },
    { lat: 48.3506465, lng: 18.0494773 },
    { lat: 48.3526033, lng: 18.0484474 },
    { lat: 48.3546671, lng: 18.0472597 },
    { lat: 48.3548583, lng: 18.0471491 },
  ],
  VeľkéZálužie: [
    { lat: 48.3416924, lng: 17.9432004 },
    { lat: 48.3416489, lng: 17.943134 },
    { lat: 48.3408913, lng: 17.9414807 },
    { lat: 48.3405501, lng: 17.9411012 },
    { lat: 48.3403194, lng: 17.9406088 },
    { lat: 48.3395511, lng: 17.939584 },
    { lat: 48.3388826, lng: 17.9383626 },
    { lat: 48.3387756, lng: 17.9379166 },
    { lat: 48.3388588, lng: 17.9377978 },
    { lat: 48.3379066, lng: 17.9365862 },
    { lat: 48.3373244, lng: 17.9356753 },
    { lat: 48.3368394, lng: 17.9347438 },
    { lat: 48.3369788, lng: 17.9343793 },
    { lat: 48.3359834, lng: 17.9316297 },
    { lat: 48.3364765, lng: 17.9306156 },
    { lat: 48.3360773, lng: 17.930099 },
    { lat: 48.3353149, lng: 17.9272845 },
    { lat: 48.3352211, lng: 17.9270595 },
    { lat: 48.3342268, lng: 17.9256616 },
    { lat: 48.3332475, lng: 17.9244147 },
    { lat: 48.3330715, lng: 17.924043 },
    { lat: 48.332659, lng: 17.9227822 },
    { lat: 48.3326309, lng: 17.9217761 },
    { lat: 48.332539, lng: 17.9215087 },
    { lat: 48.3321859, lng: 17.9210781 },
    { lat: 48.3319668, lng: 17.9208929 },
    { lat: 48.3315582, lng: 17.920837 },
    { lat: 48.3314497, lng: 17.9207602 },
    { lat: 48.3299686, lng: 17.9193908 },
    { lat: 48.3292506, lng: 17.9186268 },
    { lat: 48.3284018, lng: 17.9173765 },
    { lat: 48.3280515, lng: 17.9170527 },
    { lat: 48.3284912, lng: 17.9165942 },
    { lat: 48.3291516, lng: 17.9160724 },
    { lat: 48.3302212, lng: 17.9155789 },
    { lat: 48.3318105, lng: 17.9142325 },
    { lat: 48.3329549, lng: 17.9130711 },
    { lat: 48.3340637, lng: 17.9115682 },
    { lat: 48.3346389, lng: 17.9109258 },
    { lat: 48.3350079, lng: 17.9106393 },
    { lat: 48.3358509, lng: 17.9101695 },
    { lat: 48.3362607, lng: 17.9098 },
    { lat: 48.3369749, lng: 17.908786 },
    { lat: 48.3389526, lng: 17.9067165 },
    { lat: 48.3393125, lng: 17.9060579 },
    { lat: 48.3398288, lng: 17.9056001 },
    { lat: 48.3400898, lng: 17.9051569 },
    { lat: 48.3407731, lng: 17.9036151 },
    { lat: 48.3412504, lng: 17.9027911 },
    { lat: 48.3416545, lng: 17.9018032 },
    { lat: 48.3422795, lng: 17.9000077 },
    { lat: 48.3429215, lng: 17.8987354 },
    { lat: 48.3435442, lng: 17.8979092 },
    { lat: 48.3448693, lng: 17.896902 },
    { lat: 48.3455805, lng: 17.8962678 },
    { lat: 48.3461042, lng: 17.8954877 },
    { lat: 48.3461743, lng: 17.8953216 },
    { lat: 48.3460613, lng: 17.8943115 },
    { lat: 48.3459695, lng: 17.8938787 },
    { lat: 48.3453304, lng: 17.8932243 },
    { lat: 48.3453255, lng: 17.8931817 },
    { lat: 48.3449135, lng: 17.8933469 },
    { lat: 48.3443333, lng: 17.8932383 },
    { lat: 48.3441412, lng: 17.8930861 },
    { lat: 48.3439512, lng: 17.89282 },
    { lat: 48.3436219, lng: 17.8926212 },
    { lat: 48.3431504, lng: 17.8925145 },
    { lat: 48.3423816, lng: 17.8921397 },
    { lat: 48.3416894, lng: 17.8914291 },
    { lat: 48.3398968, lng: 17.8897912 },
    { lat: 48.339645, lng: 17.8896606 },
    { lat: 48.3390174, lng: 17.8895317 },
    { lat: 48.3385351, lng: 17.8897114 },
    { lat: 48.3381739, lng: 17.8901787 },
    { lat: 48.3373469, lng: 17.8910044 },
    { lat: 48.3369199, lng: 17.8915934 },
    { lat: 48.3360736, lng: 17.8922883 },
    { lat: 48.3347241, lng: 17.8943631 },
    { lat: 48.3343976, lng: 17.894546 },
    { lat: 48.3336974, lng: 17.8944478 },
    { lat: 48.3331219, lng: 17.89462 },
    { lat: 48.3329156, lng: 17.8948096 },
    { lat: 48.3326463, lng: 17.8949105 },
    { lat: 48.3317278, lng: 17.8948588 },
    { lat: 48.331309, lng: 17.8946942 },
    { lat: 48.3309533, lng: 17.8943948 },
    { lat: 48.3299179, lng: 17.8944991 },
    { lat: 48.3296252, lng: 17.8943645 },
    { lat: 48.3283097, lng: 17.8929797 },
    { lat: 48.3281064, lng: 17.8928875 },
    { lat: 48.3273765, lng: 17.8930063 },
    { lat: 48.3270869, lng: 17.8928851 },
    { lat: 48.3268277, lng: 17.8925547 },
    { lat: 48.32595, lng: 17.8909651 },
    { lat: 48.325254, lng: 17.8901727 },
    { lat: 48.3252427, lng: 17.8902083 },
    { lat: 48.3247427, lng: 17.8917375 },
    { lat: 48.3241837, lng: 17.892813 },
    { lat: 48.3232849, lng: 17.8942774 },
    { lat: 48.3226598, lng: 17.895901 },
    { lat: 48.3220905, lng: 17.8970292 },
    { lat: 48.3211159, lng: 17.8981212 },
    { lat: 48.3203195, lng: 17.8984117 },
    { lat: 48.3190568, lng: 17.8997418 },
    { lat: 48.3183076, lng: 17.9003606 },
    { lat: 48.3168187, lng: 17.900699 },
    { lat: 48.3163273, lng: 17.9005411 },
    { lat: 48.3150255, lng: 17.9010245 },
    { lat: 48.3140091, lng: 17.9016105 },
    { lat: 48.3123224, lng: 17.9024474 },
    { lat: 48.311442, lng: 17.9030447 },
    { lat: 48.3113467, lng: 17.9030534 },
    { lat: 48.310316, lng: 17.9038909 },
    { lat: 48.3097581, lng: 17.9046505 },
    { lat: 48.3088589, lng: 17.905218 },
    { lat: 48.3082417, lng: 17.9058729 },
    { lat: 48.3080516, lng: 17.9068909 },
    { lat: 48.3076801, lng: 17.9079903 },
    { lat: 48.3075599, lng: 17.908224 },
    { lat: 48.3074194, lng: 17.9083257 },
    { lat: 48.3066851, lng: 17.9091905 },
    { lat: 48.3056489, lng: 17.9096229 },
    { lat: 48.3050373, lng: 17.9102224 },
    { lat: 48.3043753, lng: 17.9095792 },
    { lat: 48.3033871, lng: 17.910655 },
    { lat: 48.3018185, lng: 17.9077342 },
    { lat: 48.3002809, lng: 17.9050268 },
    { lat: 48.2982203, lng: 17.9069947 },
    { lat: 48.2978579, lng: 17.9066782 },
    { lat: 48.2975057, lng: 17.9064744 },
    { lat: 48.2965991, lng: 17.9056679 },
    { lat: 48.2959287, lng: 17.9059513 },
    { lat: 48.2954033, lng: 17.9063139 },
    { lat: 48.2943712, lng: 17.9072781 },
    { lat: 48.2937184, lng: 17.9077548 },
    { lat: 48.2929548, lng: 17.9082149 },
    { lat: 48.2910898, lng: 17.9096468 },
    { lat: 48.2899668, lng: 17.9101533 },
    { lat: 48.2893931, lng: 17.910316 },
    { lat: 48.2876378, lng: 17.9112299 },
    { lat: 48.2858857, lng: 17.9120459 },
    { lat: 48.2840939, lng: 17.9138276 },
    { lat: 48.2823397, lng: 17.9158256 },
    { lat: 48.2819967, lng: 17.9158286 },
    { lat: 48.2831511, lng: 17.9177999 },
    { lat: 48.2840154, lng: 17.918801 },
    { lat: 48.2850227, lng: 17.920375 },
    { lat: 48.2849838, lng: 17.9211129 },
    { lat: 48.2852409, lng: 17.9215741 },
    { lat: 48.2864308, lng: 17.9248285 },
    { lat: 48.2869391, lng: 17.9268709 },
    { lat: 48.2875981, lng: 17.9299887 },
    { lat: 48.2889323, lng: 17.9283025 },
    { lat: 48.2894376, lng: 17.9287663 },
    { lat: 48.2908692, lng: 17.9308637 },
    { lat: 48.2904168, lng: 17.9319157 },
    { lat: 48.2889058, lng: 17.9343365 },
    { lat: 48.2877068, lng: 17.9359946 },
    { lat: 48.2865235, lng: 17.9373185 },
    { lat: 48.2856923, lng: 17.9377252 },
    { lat: 48.2840576, lng: 17.9397409 },
    { lat: 48.2831273, lng: 17.9410415 },
    { lat: 48.2828832, lng: 17.940934 },
    { lat: 48.2827849, lng: 17.9428228 },
    { lat: 48.2828538, lng: 17.9445631 },
    { lat: 48.2829213, lng: 17.9445593 },
    { lat: 48.2830034, lng: 17.9446856 },
    { lat: 48.2835802, lng: 17.9457555 },
    { lat: 48.2839727, lng: 17.9458041 },
    { lat: 48.2853939, lng: 17.9470036 },
    { lat: 48.2860807, lng: 17.947297 },
    { lat: 48.286101, lng: 17.9475955 },
    { lat: 48.285846, lng: 17.948667 },
    { lat: 48.2856888, lng: 17.9509271 },
    { lat: 48.2852751, lng: 17.9523453 },
    { lat: 48.2854007, lng: 17.9535876 },
    { lat: 48.2858917, lng: 17.9544483 },
    { lat: 48.2865264, lng: 17.9574375 },
    { lat: 48.286393, lng: 17.9589171 },
    { lat: 48.2864716, lng: 17.9603375 },
    { lat: 48.2864189, lng: 17.9615266 },
    { lat: 48.2864054, lng: 17.9636134 },
    { lat: 48.2861271, lng: 17.9695014 },
    { lat: 48.2858329, lng: 17.9721027 },
    { lat: 48.2856091, lng: 17.9759735 },
    { lat: 48.2858163, lng: 17.9776417 },
    { lat: 48.2860509, lng: 17.9790497 },
    { lat: 48.2862792, lng: 17.9811468 },
    { lat: 48.2864966, lng: 17.9825518 },
    { lat: 48.2867087, lng: 17.9832101 },
    { lat: 48.2871582, lng: 17.9858335 },
    { lat: 48.2874731, lng: 17.9870592 },
    { lat: 48.2875072, lng: 17.9887394 },
    { lat: 48.2871118, lng: 17.9904498 },
    { lat: 48.2870584, lng: 17.9908383 },
    { lat: 48.2875094, lng: 17.9909049 },
    { lat: 48.287929, lng: 17.990902 },
    { lat: 48.28862, lng: 17.9905675 },
    { lat: 48.2890909, lng: 17.9898163 },
    { lat: 48.2895514, lng: 17.9888914 },
    { lat: 48.290466, lng: 17.9875953 },
    { lat: 48.2905122, lng: 17.9877419 },
    { lat: 48.291744, lng: 17.9868468 },
    { lat: 48.2923068, lng: 17.9864057 },
    { lat: 48.2925721, lng: 17.9861237 },
    { lat: 48.2954743, lng: 17.9841709 },
    { lat: 48.2960752, lng: 17.9835534 },
    { lat: 48.2973745, lng: 17.9826339 },
    { lat: 48.29806, lng: 17.9820354 },
    { lat: 48.2989258, lng: 17.9815127 },
    { lat: 48.2996413, lng: 17.9808293 },
    { lat: 48.3002241, lng: 17.9800288 },
    { lat: 48.3013319, lng: 17.9801841 },
    { lat: 48.3031099, lng: 17.9806992 },
    { lat: 48.303624, lng: 17.9807835 },
    { lat: 48.3050233, lng: 17.9808521 },
    { lat: 48.3055809, lng: 17.9809689 },
    { lat: 48.3062762, lng: 17.9809118 },
    { lat: 48.3100408, lng: 17.9784098 },
    { lat: 48.3129677, lng: 17.9768117 },
    { lat: 48.3129328, lng: 17.9758534 },
    { lat: 48.3149622, lng: 17.9740239 },
    { lat: 48.3168705, lng: 17.9731727 },
    { lat: 48.318002, lng: 17.9725595 },
    { lat: 48.3181547, lng: 17.9724517 },
    { lat: 48.3182418, lng: 17.9722083 },
    { lat: 48.3187032, lng: 17.9717188 },
    { lat: 48.3192952, lng: 17.9712173 },
    { lat: 48.3196188, lng: 17.9710793 },
    { lat: 48.3196206, lng: 17.9711486 },
    { lat: 48.3206646, lng: 17.9709943 },
    { lat: 48.3218965, lng: 17.970232 },
    { lat: 48.3223216, lng: 17.9699402 },
    { lat: 48.3237771, lng: 17.9687292 },
    { lat: 48.3253006, lng: 17.9670701 },
    { lat: 48.3258435, lng: 17.9677655 },
    { lat: 48.3270687, lng: 17.9691437 },
    { lat: 48.3275556, lng: 17.9704316 },
    { lat: 48.3279484, lng: 17.9709596 },
    { lat: 48.3282424, lng: 17.9711171 },
    { lat: 48.3283962, lng: 17.9713106 },
    { lat: 48.3285655, lng: 17.9716882 },
    { lat: 48.3286658, lng: 17.9717598 },
    { lat: 48.3290646, lng: 17.9723126 },
    { lat: 48.3303651, lng: 17.9747089 },
    { lat: 48.3312181, lng: 17.9757953 },
    { lat: 48.3317435, lng: 17.97633 },
    { lat: 48.3329763, lng: 17.9770496 },
    { lat: 48.3344093, lng: 17.9787177 },
    { lat: 48.3351599, lng: 17.9794144 },
    { lat: 48.3357998, lng: 17.9797908 },
    { lat: 48.3366567, lng: 17.9805498 },
    { lat: 48.3371019, lng: 17.9810273 },
    { lat: 48.3377297, lng: 17.9815032 },
    { lat: 48.3387569, lng: 17.9820879 },
    { lat: 48.3405538, lng: 17.982748 },
    { lat: 48.3405537, lng: 17.9827108 },
    { lat: 48.3395881, lng: 17.9799281 },
    { lat: 48.3392374, lng: 17.9791779 },
    { lat: 48.3382741, lng: 17.9764039 },
    { lat: 48.3376777, lng: 17.9749446 },
    { lat: 48.3373672, lng: 17.9730399 },
    { lat: 48.336384, lng: 17.9680906 },
    { lat: 48.3355796, lng: 17.9647286 },
    { lat: 48.3341634, lng: 17.9606927 },
    { lat: 48.3339827, lng: 17.9598463 },
    { lat: 48.3337594, lng: 17.9590992 },
    { lat: 48.3331078, lng: 17.9572138 },
    { lat: 48.3316627, lng: 17.9546533 },
    { lat: 48.332061, lng: 17.9541642 },
    { lat: 48.3324935, lng: 17.953774 },
    { lat: 48.3339482, lng: 17.952944 },
    { lat: 48.3347945, lng: 17.952036 },
    { lat: 48.3389535, lng: 17.9471126 },
    { lat: 48.3394728, lng: 17.9465892 },
    { lat: 48.3412153, lng: 17.9440141 },
    { lat: 48.3416924, lng: 17.9432004 },
  ],
  Babindol: [
    { lat: 48.2750917, lng: 18.2235747 },
    { lat: 48.2759621, lng: 18.2335835 },
    { lat: 48.2776513, lng: 18.2373162 },
    { lat: 48.2796851, lng: 18.2401221 },
    { lat: 48.279549, lng: 18.2420519 },
    { lat: 48.279565, lng: 18.2427768 },
    { lat: 48.2799476, lng: 18.2453625 },
    { lat: 48.2806196, lng: 18.2469629 },
    { lat: 48.2810254, lng: 18.247642 },
    { lat: 48.2810558, lng: 18.2478949 },
    { lat: 48.2817343, lng: 18.2494481 },
    { lat: 48.2826018, lng: 18.2502851 },
    { lat: 48.2858855, lng: 18.2525431 },
    { lat: 48.2858582, lng: 18.2530671 },
    { lat: 48.2859, lng: 18.2537571 },
    { lat: 48.2860286, lng: 18.2536378 },
    { lat: 48.286258, lng: 18.254057 },
    { lat: 48.2863008, lng: 18.2542705 },
    { lat: 48.2862638, lng: 18.2548439 },
    { lat: 48.2861958, lng: 18.2550355 },
    { lat: 48.2860755, lng: 18.2551024 },
    { lat: 48.286901, lng: 18.2554857 },
    { lat: 48.2869242, lng: 18.255498 },
    { lat: 48.2870343, lng: 18.2546466 },
    { lat: 48.2871957, lng: 18.2540773 },
    { lat: 48.2876575, lng: 18.253083 },
    { lat: 48.287998, lng: 18.2525657 },
    { lat: 48.2885212, lng: 18.2519145 },
    { lat: 48.2889242, lng: 18.2515259 },
    { lat: 48.2895627, lng: 18.2510714 },
    { lat: 48.2904099, lng: 18.2505782 },
    { lat: 48.2911359, lng: 18.2498193 },
    { lat: 48.2915938, lng: 18.2494653 },
    { lat: 48.2919218, lng: 18.2489477 },
    { lat: 48.2922308, lng: 18.2486516 },
    { lat: 48.2928911, lng: 18.2468715 },
    { lat: 48.294125, lng: 18.2490541 },
    { lat: 48.2956381, lng: 18.2485453 },
    { lat: 48.2956478, lng: 18.2485376 },
    { lat: 48.2957128, lng: 18.2484297 },
    { lat: 48.2960716, lng: 18.2477472 },
    { lat: 48.2958848, lng: 18.2475361 },
    { lat: 48.296271, lng: 18.2464663 },
    { lat: 48.2964112, lng: 18.2458415 },
    { lat: 48.2965256, lng: 18.2456155 },
    { lat: 48.2967866, lng: 18.2451717 },
    { lat: 48.2971846, lng: 18.2446967 },
    { lat: 48.2974927, lng: 18.2444205 },
    { lat: 48.2981106, lng: 18.2442782 },
    { lat: 48.2991006, lng: 18.2431278 },
    { lat: 48.2993412, lng: 18.2427614 },
    { lat: 48.2998582, lng: 18.2416302 },
    { lat: 48.3005283, lng: 18.2397846 },
    { lat: 48.3006579, lng: 18.239058 },
    { lat: 48.3004481, lng: 18.238201 },
    { lat: 48.30034, lng: 18.2370883 },
    { lat: 48.3003376, lng: 18.2356165 },
    { lat: 48.3004534, lng: 18.2342215 },
    { lat: 48.3003909, lng: 18.2337029 },
    { lat: 48.3001868, lng: 18.2329448 },
    { lat: 48.3000836, lng: 18.2323475 },
    { lat: 48.3000836, lng: 18.2314874 },
    { lat: 48.3002612, lng: 18.2293245 },
    { lat: 48.3002842, lng: 18.2282577 },
    { lat: 48.2984889, lng: 18.2269688 },
    { lat: 48.2970181, lng: 18.2257895 },
    { lat: 48.2968186, lng: 18.2262612 },
    { lat: 48.2952088, lng: 18.2250579 },
    { lat: 48.2948212, lng: 18.2247138 },
    { lat: 48.2945652, lng: 18.2244033 },
    { lat: 48.2939366, lng: 18.2234852 },
    { lat: 48.2926893, lng: 18.2209204 },
    { lat: 48.2922668, lng: 18.2201896 },
    { lat: 48.2918187, lng: 18.2196256 },
    { lat: 48.2913379, lng: 18.2188018 },
    { lat: 48.2883108, lng: 18.2132689 },
    { lat: 48.2829617, lng: 18.2181212 },
    { lat: 48.2791942, lng: 18.220349 },
    { lat: 48.2771864, lng: 18.2217251 },
    { lat: 48.276133, lng: 18.2225767 },
    { lat: 48.2754965, lng: 18.2231789 },
    { lat: 48.2750917, lng: 18.2235747 },
  ],
  NitrianskeHrnčiarovce: [
    { lat: 48.3464575, lng: 18.1073263 },
    { lat: 48.3458893, lng: 18.1076256 },
    { lat: 48.3456035, lng: 18.106509 },
    { lat: 48.342948, lng: 18.1047991 },
    { lat: 48.342878, lng: 18.1050269 },
    { lat: 48.342444, lng: 18.1047288 },
    { lat: 48.3424687, lng: 18.1046497 },
    { lat: 48.3404056, lng: 18.1043404 },
    { lat: 48.3393224, lng: 18.1043833 },
    { lat: 48.3368263, lng: 18.1047911 },
    { lat: 48.3353909, lng: 18.10412 },
    { lat: 48.3349308, lng: 18.1052598 },
    { lat: 48.3354441, lng: 18.1053516 },
    { lat: 48.3354279, lng: 18.1054619 },
    { lat: 48.3357852, lng: 18.1056062 },
    { lat: 48.3362012, lng: 18.105675 },
    { lat: 48.3361944, lng: 18.1057922 },
    { lat: 48.3363255, lng: 18.1057536 },
    { lat: 48.3374076, lng: 18.1058287 },
    { lat: 48.337328, lng: 18.106511 },
    { lat: 48.3373359, lng: 18.1071846 },
    { lat: 48.337126, lng: 18.1071825 },
    { lat: 48.3370673, lng: 18.1078164 },
    { lat: 48.3370977, lng: 18.1082628 },
    { lat: 48.3370993, lng: 18.108286 },
    { lat: 48.3371994, lng: 18.1087211 },
    { lat: 48.3368798, lng: 18.1097139 },
    { lat: 48.3367225, lng: 18.109938 },
    { lat: 48.336662, lng: 18.110213 },
    { lat: 48.3366482, lng: 18.1105937 },
    { lat: 48.3364602, lng: 18.1106785 },
    { lat: 48.3364447, lng: 18.1117031 },
    { lat: 48.336267, lng: 18.1116946 },
    { lat: 48.3362634, lng: 18.1125184 },
    { lat: 48.3362063, lng: 18.1127178 },
    { lat: 48.3361617, lng: 18.1127077 },
    { lat: 48.3360896, lng: 18.1131308 },
    { lat: 48.3355853, lng: 18.1130618 },
    { lat: 48.3354699, lng: 18.113323 },
    { lat: 48.335203, lng: 18.1136987 },
    { lat: 48.3351081, lng: 18.1137549 },
    { lat: 48.3347486, lng: 18.1137417 },
    { lat: 48.334473, lng: 18.113527 },
    { lat: 48.3344599, lng: 18.1135936 },
    { lat: 48.3336747, lng: 18.1132905 },
    { lat: 48.3333765, lng: 18.1132465 },
    { lat: 48.332477, lng: 18.1136885 },
    { lat: 48.3317906, lng: 18.1138093 },
    { lat: 48.3291589, lng: 18.1149189 },
    { lat: 48.3291791, lng: 18.1149998 },
    { lat: 48.3281865, lng: 18.1156903 },
    { lat: 48.3277997, lng: 18.1159674 },
    { lat: 48.3275321, lng: 18.1161333 },
    { lat: 48.3270588, lng: 18.1148305 },
    { lat: 48.3248193, lng: 18.1161589 },
    { lat: 48.3229493, lng: 18.1176001 },
    { lat: 48.3227422, lng: 18.1177664 },
    { lat: 48.3215469, lng: 18.1187443 },
    { lat: 48.3212076, lng: 18.1198652 },
    { lat: 48.3211694, lng: 18.1201731 },
    { lat: 48.3212, lng: 18.1204456 },
    { lat: 48.3210428, lng: 18.1206149 },
    { lat: 48.3208879, lng: 18.1205151 },
    { lat: 48.3207535, lng: 18.1210899 },
    { lat: 48.3206347, lng: 18.1221817 },
    { lat: 48.320606, lng: 18.123962 },
    { lat: 48.3212061, lng: 18.1271521 },
    { lat: 48.3218849, lng: 18.1299954 },
    { lat: 48.3221416, lng: 18.1321973 },
    { lat: 48.3220683, lng: 18.1334136 },
    { lat: 48.3207169, lng: 18.1339847 },
    { lat: 48.3203375, lng: 18.1342098 },
    { lat: 48.3183143, lng: 18.1350239 },
    { lat: 48.3169339, lng: 18.1357139 },
    { lat: 48.3165871, lng: 18.1359623 },
    { lat: 48.3157326, lng: 18.1362641 },
    { lat: 48.3154071, lng: 18.1363551 },
    { lat: 48.3152906, lng: 18.1363283 },
    { lat: 48.3151718, lng: 18.1360723 },
    { lat: 48.3149569, lng: 18.1359871 },
    { lat: 48.3138427, lng: 18.1364624 },
    { lat: 48.3132676, lng: 18.1365107 },
    { lat: 48.3123007, lng: 18.13713 },
    { lat: 48.3115516, lng: 18.1376926 },
    { lat: 48.3106766, lng: 18.1381692 },
    { lat: 48.3098472, lng: 18.1388476 },
    { lat: 48.3103732, lng: 18.1389617 },
    { lat: 48.3104866, lng: 18.1391265 },
    { lat: 48.3106941, lng: 18.1398193 },
    { lat: 48.3106856, lng: 18.1400987 },
    { lat: 48.3108331, lng: 18.1402657 },
    { lat: 48.3108675, lng: 18.1404098 },
    { lat: 48.3111633, lng: 18.1407403 },
    { lat: 48.3112149, lng: 18.1408912 },
    { lat: 48.3114197, lng: 18.14092 },
    { lat: 48.3115214, lng: 18.1411342 },
    { lat: 48.311683, lng: 18.1411963 },
    { lat: 48.3115563, lng: 18.1414033 },
    { lat: 48.311572, lng: 18.1414977 },
    { lat: 48.3116412, lng: 18.1415403 },
    { lat: 48.3117004, lng: 18.141396 },
    { lat: 48.311823, lng: 18.1415242 },
    { lat: 48.3117369, lng: 18.1416536 },
    { lat: 48.3117577, lng: 18.1417976 },
    { lat: 48.311854, lng: 18.1416662 },
    { lat: 48.312091, lng: 18.1417074 },
    { lat: 48.3121161, lng: 18.1417682 },
    { lat: 48.3120813, lng: 18.1418483 },
    { lat: 48.3121218, lng: 18.1419426 },
    { lat: 48.312253, lng: 18.1420059 },
    { lat: 48.3124348, lng: 18.141893 },
    { lat: 48.3124205, lng: 18.1420154 },
    { lat: 48.3126251, lng: 18.142292 },
    { lat: 48.3126226, lng: 18.1424025 },
    { lat: 48.3126866, lng: 18.142514 },
    { lat: 48.3128004, lng: 18.1424713 },
    { lat: 48.3128785, lng: 18.1425728 },
    { lat: 48.3129498, lng: 18.1425545 },
    { lat: 48.3130113, lng: 18.1426237 },
    { lat: 48.3129966, lng: 18.1427568 },
    { lat: 48.3130896, lng: 18.1428421 },
    { lat: 48.3132041, lng: 18.142815 },
    { lat: 48.3132641, lng: 18.1429922 },
    { lat: 48.3134743, lng: 18.1429546 },
    { lat: 48.3135134, lng: 18.1433721 },
    { lat: 48.3137885, lng: 18.1434107 },
    { lat: 48.314089, lng: 18.143568 },
    { lat: 48.3141926, lng: 18.1437019 },
    { lat: 48.3141936, lng: 18.1437946 },
    { lat: 48.3142802, lng: 18.1438492 },
    { lat: 48.3142598, lng: 18.1440018 },
    { lat: 48.3143603, lng: 18.1440216 },
    { lat: 48.3143864, lng: 18.1441203 },
    { lat: 48.314721, lng: 18.1442039 },
    { lat: 48.3148862, lng: 18.1441177 },
    { lat: 48.3151514, lng: 18.144253 },
    { lat: 48.315289, lng: 18.1442328 },
    { lat: 48.3153385, lng: 18.1443433 },
    { lat: 48.3153255, lng: 18.1444329 },
    { lat: 48.315471, lng: 18.14445 },
    { lat: 48.3155557, lng: 18.1447973 },
    { lat: 48.3156018, lng: 18.1453553 },
    { lat: 48.3154609, lng: 18.1467845 },
    { lat: 48.3156542, lng: 18.1477018 },
    { lat: 48.3156526, lng: 18.1479818 },
    { lat: 48.3155718, lng: 18.1484721 },
    { lat: 48.3154327, lng: 18.1486991 },
    { lat: 48.314933, lng: 18.1490509 },
    { lat: 48.3145998, lng: 18.1494109 },
    { lat: 48.3144895, lng: 18.1496108 },
    { lat: 48.3136162, lng: 18.1503244 },
    { lat: 48.3133697, lng: 18.1505895 },
    { lat: 48.3130422, lng: 18.1506257 },
    { lat: 48.3122256, lng: 18.1514838 },
    { lat: 48.3119527, lng: 18.1521027 },
    { lat: 48.3118183, lng: 18.1522558 },
    { lat: 48.3111428, lng: 18.1525532 },
    { lat: 48.3106975, lng: 18.152874 },
    { lat: 48.310343, lng: 18.1532945 },
    { lat: 48.3098607, lng: 18.1545436 },
    { lat: 48.309515, lng: 18.154848 },
    { lat: 48.3090357, lng: 18.1556833 },
    { lat: 48.3089254, lng: 18.1565404 },
    { lat: 48.3087566, lng: 18.1572634 },
    { lat: 48.3078978, lng: 18.1585404 },
    { lat: 48.3076347, lng: 18.1590407 },
    { lat: 48.3072444, lng: 18.159525 },
    { lat: 48.307219, lng: 18.1595564 },
    { lat: 48.3073976, lng: 18.1599254 },
    { lat: 48.3075561, lng: 18.1602062 },
    { lat: 48.3077517, lng: 18.160406 },
    { lat: 48.3083236, lng: 18.1607519 },
    { lat: 48.3085268, lng: 18.1609443 },
    { lat: 48.3089569, lng: 18.1616451 },
    { lat: 48.3093893, lng: 18.1609904 },
    { lat: 48.3094712, lng: 18.1607124 },
    { lat: 48.3095422, lng: 18.1601852 },
    { lat: 48.3098992, lng: 18.159243 },
    { lat: 48.31008, lng: 18.1589793 },
    { lat: 48.3114796, lng: 18.1580567 },
    { lat: 48.3120409, lng: 18.1576169 },
    { lat: 48.313367, lng: 18.1556874 },
    { lat: 48.3137056, lng: 18.1550676 },
    { lat: 48.3140595, lng: 18.1538623 },
    { lat: 48.3141708, lng: 18.1536218 },
    { lat: 48.3152753, lng: 18.1519159 },
    { lat: 48.3162401, lng: 18.1508342 },
    { lat: 48.3165405, lng: 18.1514542 },
    { lat: 48.3167928, lng: 18.151794 },
    { lat: 48.3167989, lng: 18.1518021 },
    { lat: 48.3174434, lng: 18.1527719 },
    { lat: 48.3176056, lng: 18.1522639 },
    { lat: 48.3177423, lng: 18.1524994 },
    { lat: 48.3178135, lng: 18.1525362 },
    { lat: 48.318026, lng: 18.1529688 },
    { lat: 48.3180544, lng: 18.153147 },
    { lat: 48.31885, lng: 18.1543644 },
    { lat: 48.3190944, lng: 18.1549737 },
    { lat: 48.3193364, lng: 18.1551213 },
    { lat: 48.3195135, lng: 18.1553679 },
    { lat: 48.3197556, lng: 18.155456 },
    { lat: 48.3199636, lng: 18.1554837 },
    { lat: 48.3201285, lng: 18.155428 },
    { lat: 48.320427, lng: 18.1555137 },
    { lat: 48.3204847, lng: 18.1557295 },
    { lat: 48.3206214, lng: 18.1558863 },
    { lat: 48.3207072, lng: 18.1561782 },
    { lat: 48.3210403, lng: 18.156237 },
    { lat: 48.3213932, lng: 18.1566974 },
    { lat: 48.3216199, lng: 18.1565989 },
    { lat: 48.3218044, lng: 18.1567647 },
    { lat: 48.3218744, lng: 18.1569399 },
    { lat: 48.3220337, lng: 18.1570403 },
    { lat: 48.324411, lng: 18.1575908 },
    { lat: 48.324596, lng: 18.1574949 },
    { lat: 48.3245362, lng: 18.1567209 },
    { lat: 48.3240293, lng: 18.153623 },
    { lat: 48.3242493, lng: 18.1535998 },
    { lat: 48.3246438, lng: 18.1533818 },
    { lat: 48.3261809, lng: 18.1528804 },
    { lat: 48.3268257, lng: 18.1528298 },
    { lat: 48.3272914, lng: 18.1532091 },
    { lat: 48.3272471, lng: 18.1525458 },
    { lat: 48.3279484, lng: 18.1523427 },
    { lat: 48.3288574, lng: 18.1519663 },
    { lat: 48.3293042, lng: 18.1518491 },
    { lat: 48.3294058, lng: 18.1518882 },
    { lat: 48.3303927, lng: 18.1517438 },
    { lat: 48.3312642, lng: 18.1514382 },
    { lat: 48.3320973, lng: 18.1514413 },
    { lat: 48.3331691, lng: 18.1511682 },
    { lat: 48.3337844, lng: 18.1507267 },
    { lat: 48.3344366, lng: 18.1503786 },
    { lat: 48.3358745, lng: 18.1496869 },
    { lat: 48.3375451, lng: 18.1491504 },
    { lat: 48.3378657, lng: 18.1493693 },
    { lat: 48.3380303, lng: 18.1495757 },
    { lat: 48.3383406, lng: 18.1497301 },
    { lat: 48.338887, lng: 18.1495785 },
    { lat: 48.3390873, lng: 18.1493762 },
    { lat: 48.3392751, lng: 18.1490767 },
    { lat: 48.3396289, lng: 18.1488142 },
    { lat: 48.3398942, lng: 18.1486435 },
    { lat: 48.3403602, lng: 18.1482368 },
    { lat: 48.3403053, lng: 18.1474874 },
    { lat: 48.3402136, lng: 18.1469819 },
    { lat: 48.3399511, lng: 18.1459459 },
    { lat: 48.3397376, lng: 18.14546 },
    { lat: 48.339498, lng: 18.1447399 },
    { lat: 48.3393871, lng: 18.1442494 },
    { lat: 48.3393009, lng: 18.1430661 },
    { lat: 48.3392987, lng: 18.1407509 },
    { lat: 48.3394249, lng: 18.1400341 },
    { lat: 48.3396122, lng: 18.1399722 },
    { lat: 48.3407008, lng: 18.140023 },
    { lat: 48.341013, lng: 18.1399795 },
    { lat: 48.3412612, lng: 18.140054 },
    { lat: 48.3413707, lng: 18.1401921 },
    { lat: 48.3417718, lng: 18.1403138 },
    { lat: 48.3422049, lng: 18.1403115 },
    { lat: 48.3428597, lng: 18.1402179 },
    { lat: 48.3431978, lng: 18.1400156 },
    { lat: 48.3433987, lng: 18.1400179 },
    { lat: 48.3436992, lng: 18.1398868 },
    { lat: 48.3439387, lng: 18.1396237 },
    { lat: 48.3443729, lng: 18.1393654 },
    { lat: 48.3447571, lng: 18.1388739 },
    { lat: 48.3456564, lng: 18.138542 },
    { lat: 48.3463173, lng: 18.1379577 },
    { lat: 48.3466384, lng: 18.1377923 },
    { lat: 48.3475004, lng: 18.1367863 },
    { lat: 48.3479534, lng: 18.1361573 },
    { lat: 48.348259, lng: 18.1355203 },
    { lat: 48.3496442, lng: 18.1342158 },
    { lat: 48.351305, lng: 18.1334571 },
    { lat: 48.3520906, lng: 18.1323988 },
    { lat: 48.3525589, lng: 18.1315509 },
    { lat: 48.3527488, lng: 18.1314982 },
    { lat: 48.353157, lng: 18.1316449 },
    { lat: 48.3533752, lng: 18.131652 },
    { lat: 48.353894, lng: 18.1312217 },
    { lat: 48.3538719, lng: 18.1311924 },
    { lat: 48.3532088, lng: 18.1293695 },
    { lat: 48.3524501, lng: 18.1277611 },
    { lat: 48.3519567, lng: 18.1265077 },
    { lat: 48.350465, lng: 18.1246026 },
    { lat: 48.3501134, lng: 18.1240508 },
    { lat: 48.349944, lng: 18.1233554 },
    { lat: 48.3496655, lng: 18.1226503 },
    { lat: 48.3488817, lng: 18.1208858 },
    { lat: 48.3486616, lng: 18.1204991 },
    { lat: 48.3484858, lng: 18.1192163 },
    { lat: 48.3483729, lng: 18.11904 },
    { lat: 48.3482076, lng: 18.1183392 },
    { lat: 48.3480151, lng: 18.1179435 },
    { lat: 48.3478407, lng: 18.1162969 },
    { lat: 48.3478986, lng: 18.1159012 },
    { lat: 48.3478681, lng: 18.1155742 },
    { lat: 48.3476482, lng: 18.1149029 },
    { lat: 48.3474266, lng: 18.1143943 },
    { lat: 48.3473154, lng: 18.1129992 },
    { lat: 48.3473848, lng: 18.1126069 },
    { lat: 48.3471882, lng: 18.1112556 },
    { lat: 48.3465321, lng: 18.1097526 },
    { lat: 48.3463982, lng: 18.1091236 },
    { lat: 48.3464548, lng: 18.1087216 },
    { lat: 48.3465169, lng: 18.1084349 },
    { lat: 48.3465048, lng: 18.107942 },
    { lat: 48.3464575, lng: 18.1073263 },
  ],
  MaléZálužie: [
    { lat: 48.4431354, lng: 17.9555678 },
    { lat: 48.4430814, lng: 17.9555104 },
    { lat: 48.4429672, lng: 17.9552269 },
    { lat: 48.442805, lng: 17.9532563 },
    { lat: 48.4427485, lng: 17.9531242 },
    { lat: 48.4428139, lng: 17.9526717 },
    { lat: 48.4427892, lng: 17.9523358 },
    { lat: 48.4424236, lng: 17.9512282 },
    { lat: 48.4418777, lng: 17.9502556 },
    { lat: 48.441864, lng: 17.950442 },
    { lat: 48.4421097, lng: 17.9514525 },
    { lat: 48.4412304, lng: 17.9519592 },
    { lat: 48.4387879, lng: 17.9537481 },
    { lat: 48.4372642, lng: 17.9544463 },
    { lat: 48.4370405, lng: 17.9545178 },
    { lat: 48.4367082, lng: 17.9544904 },
    { lat: 48.4351442, lng: 17.9556631 },
    { lat: 48.4338639, lng: 17.9563385 },
    { lat: 48.4327112, lng: 17.9567278 },
    { lat: 48.4326514, lng: 17.9619633 },
    { lat: 48.4321299, lng: 17.9666887 },
    { lat: 48.4320514, lng: 17.9677755 },
    { lat: 48.4320306, lng: 17.969499 },
    { lat: 48.4319168, lng: 17.9703319 },
    { lat: 48.4315138, lng: 17.9720874 },
    { lat: 48.4314113, lng: 17.9731295 },
    { lat: 48.4314023, lng: 17.9739592 },
    { lat: 48.4313724, lng: 17.9739299 },
    { lat: 48.4311656, lng: 17.9762453 },
    { lat: 48.4310571, lng: 17.977998 },
    { lat: 48.4308764, lng: 17.9783593 },
    { lat: 48.4307318, lng: 17.9789226 },
    { lat: 48.4307007, lng: 17.9792339 },
    { lat: 48.4308968, lng: 17.9791904 },
    { lat: 48.4311128, lng: 17.9794793 },
    { lat: 48.430974, lng: 17.9817091 },
    { lat: 48.4306837, lng: 17.9818386 },
    { lat: 48.4301144, lng: 17.9818294 },
    { lat: 48.4272421, lng: 17.9806423 },
    { lat: 48.4264643, lng: 17.9802447 },
    { lat: 48.4261647, lng: 17.9801633 },
    { lat: 48.425734, lng: 17.9802264 },
    { lat: 48.4249882, lng: 17.9804955 },
    { lat: 48.4243664, lng: 17.9810265 },
    { lat: 48.4242047, lng: 17.9807785 },
    { lat: 48.424082, lng: 17.9807439 },
    { lat: 48.4245431, lng: 17.9826043 },
    { lat: 48.4242932, lng: 17.9829191 },
    { lat: 48.4245495, lng: 17.9836542 },
    { lat: 48.4249611, lng: 17.985635 },
    { lat: 48.4254221, lng: 17.9882126 },
    { lat: 48.4258139, lng: 17.9914292 },
    { lat: 48.4258279, lng: 17.9920596 },
    { lat: 48.4262084, lng: 17.995544 },
    { lat: 48.4264941, lng: 17.9956231 },
    { lat: 48.4266358, lng: 17.9959157 },
    { lat: 48.4267258, lng: 17.996953 },
    { lat: 48.4269345, lng: 17.9981418 },
    { lat: 48.4269559, lng: 17.9996187 },
    { lat: 48.4270583, lng: 18.0004077 },
    { lat: 48.4270484, lng: 18.0017936 },
    { lat: 48.4271943, lng: 18.0027108 },
    { lat: 48.4272656, lng: 18.0036621 },
    { lat: 48.4273879, lng: 18.0045597 },
    { lat: 48.4273607, lng: 18.0059579 },
    { lat: 48.4270999, lng: 18.0066575 },
    { lat: 48.4274094, lng: 18.0067954 },
    { lat: 48.4282224, lng: 18.007142 },
    { lat: 48.4285418, lng: 18.0073511 },
    { lat: 48.4290948, lng: 18.0078791 },
    { lat: 48.429244, lng: 18.007922 },
    { lat: 48.4297707, lng: 18.0075569 },
    { lat: 48.4304956, lng: 18.0072746 },
    { lat: 48.4305163, lng: 18.007264 },
    { lat: 48.4311834, lng: 18.0070155 },
    { lat: 48.4321616, lng: 18.0069724 },
    { lat: 48.4330363, lng: 18.0071637 },
    { lat: 48.4335568, lng: 18.0073731 },
    { lat: 48.4337055, lng: 18.0074444 },
    { lat: 48.4339297, lng: 18.0077221 },
    { lat: 48.4347544, lng: 18.0065609 },
    { lat: 48.4349868, lng: 18.0063292 },
    { lat: 48.4360373, lng: 18.0058701 },
    { lat: 48.4367798, lng: 18.00545 },
    { lat: 48.4387529, lng: 18.0041655 },
    { lat: 48.439639, lng: 18.003464 },
    { lat: 48.4412624, lng: 18.002407 },
    { lat: 48.4419984, lng: 18.0021096 },
    { lat: 48.4426718, lng: 18.0016855 },
    { lat: 48.4433908, lng: 18.0014949 },
    { lat: 48.4446216, lng: 18.0009534 },
    { lat: 48.4452731, lng: 18.0005788 },
    { lat: 48.4462987, lng: 18.0001752 },
    { lat: 48.4464908, lng: 17.9998944 },
    { lat: 48.4465673, lng: 17.9995297 },
    { lat: 48.4465766, lng: 17.9978482 },
    { lat: 48.4463815, lng: 17.9969448 },
    { lat: 48.4464468, lng: 17.9965802 },
    { lat: 48.4464302, lng: 17.9957345 },
    { lat: 48.4465213, lng: 17.9951239 },
    { lat: 48.4467452, lng: 17.9947492 },
    { lat: 48.4464954, lng: 17.9929085 },
    { lat: 48.4462168, lng: 17.991267 },
    { lat: 48.446129, lng: 17.9909101 },
    { lat: 48.445423, lng: 17.9906921 },
    { lat: 48.445179, lng: 17.990047 },
    { lat: 48.4429922, lng: 17.9823438 },
    { lat: 48.4428818, lng: 17.9821387 },
    { lat: 48.4422455, lng: 17.9822014 },
    { lat: 48.4422347, lng: 17.9821147 },
    { lat: 48.4421408, lng: 17.9821104 },
    { lat: 48.4418258, lng: 17.9807501 },
    { lat: 48.4416369, lng: 17.9801979 },
    { lat: 48.4413332, lng: 17.9789314 },
    { lat: 48.4406343, lng: 17.9756401 },
    { lat: 48.4403718, lng: 17.9743073 },
    { lat: 48.4399218, lng: 17.9713323 },
    { lat: 48.439987, lng: 17.9700864 },
    { lat: 48.4401979, lng: 17.9685918 },
    { lat: 48.4403046, lng: 17.9674153 },
    { lat: 48.4410421, lng: 17.9663841 },
    { lat: 48.4412908, lng: 17.9659004 },
    { lat: 48.4418249, lng: 17.9645306 },
    { lat: 48.4422704, lng: 17.9629221 },
    { lat: 48.4424042, lng: 17.9619533 },
    { lat: 48.4428447, lng: 17.9602054 },
    { lat: 48.443122, lng: 17.9593509 },
    { lat: 48.4432283, lng: 17.958453 },
    { lat: 48.4431372, lng: 17.9555978 },
    { lat: 48.4431354, lng: 17.9555678 },
  ],
  VeľkýCetín: [
    { lat: 48.2105429, lng: 18.2252405 },
    { lat: 48.2105663, lng: 18.2251787 },
    { lat: 48.2128842, lng: 18.223037 },
    { lat: 48.21305, lng: 18.2232501 },
    { lat: 48.213483, lng: 18.2236147 },
    { lat: 48.214012, lng: 18.2247229 },
    { lat: 48.2143428, lng: 18.225135 },
    { lat: 48.2149113, lng: 18.2255004 },
    { lat: 48.2160892, lng: 18.2265324 },
    { lat: 48.2171182, lng: 18.2262566 },
    { lat: 48.2171008, lng: 18.2259966 },
    { lat: 48.2188291, lng: 18.2257155 },
    { lat: 48.2189677, lng: 18.2253242 },
    { lat: 48.219022, lng: 18.2246031 },
    { lat: 48.2194001, lng: 18.2246815 },
    { lat: 48.2195899, lng: 18.2240059 },
    { lat: 48.220034, lng: 18.2241893 },
    { lat: 48.2201288, lng: 18.2240921 },
    { lat: 48.2201551, lng: 18.223962 },
    { lat: 48.2201393, lng: 18.2236523 },
    { lat: 48.2206205, lng: 18.223251 },
    { lat: 48.2207441, lng: 18.2232215 },
    { lat: 48.220892, lng: 18.2236323 },
    { lat: 48.2212951, lng: 18.2237535 },
    { lat: 48.221346, lng: 18.223812 },
    { lat: 48.2213545, lng: 18.2240568 },
    { lat: 48.2216875, lng: 18.2241566 },
    { lat: 48.2221095, lng: 18.2240814 },
    { lat: 48.2222304, lng: 18.2243166 },
    { lat: 48.2225988, lng: 18.2240346 },
    { lat: 48.2228487, lng: 18.2239279 },
    { lat: 48.2234416, lng: 18.2238345 },
    { lat: 48.2243485, lng: 18.2235588 },
    { lat: 48.2260182, lng: 18.2236096 },
    { lat: 48.2260708, lng: 18.2237376 },
    { lat: 48.226479, lng: 18.2236574 },
    { lat: 48.2268151, lng: 18.2234996 },
    { lat: 48.227189, lng: 18.2232098 },
    { lat: 48.2271961, lng: 18.2227488 },
    { lat: 48.2280377, lng: 18.2225488 },
    { lat: 48.2294321, lng: 18.221904 },
    { lat: 48.2296987, lng: 18.2219257 },
    { lat: 48.2310765, lng: 18.2217128 },
    { lat: 48.2316199, lng: 18.2215235 },
    { lat: 48.2317336, lng: 18.2214839 },
    { lat: 48.2321264, lng: 18.221273 },
    { lat: 48.2326834, lng: 18.2211282 },
    { lat: 48.2328069, lng: 18.220982 },
    { lat: 48.2328407, lng: 18.2207284 },
    { lat: 48.2329107, lng: 18.2205732 },
    { lat: 48.2330081, lng: 18.2205195 },
    { lat: 48.2332249, lng: 18.2205597 },
    { lat: 48.2334547, lng: 18.2202825 },
    { lat: 48.2346583, lng: 18.220217 },
    { lat: 48.2354012, lng: 18.2200279 },
    { lat: 48.2362179, lng: 18.2200597 },
    { lat: 48.236481, lng: 18.2198507 },
    { lat: 48.2372314, lng: 18.2197131 },
    { lat: 48.2379805, lng: 18.2193798 },
    { lat: 48.238076, lng: 18.2198608 },
    { lat: 48.2382436, lng: 18.2198108 },
    { lat: 48.2383086, lng: 18.2199206 },
    { lat: 48.2385217, lng: 18.2199584 },
    { lat: 48.2392061, lng: 18.2199472 },
    { lat: 48.2396356, lng: 18.2198423 },
    { lat: 48.2399265, lng: 18.2196074 },
    { lat: 48.2407358, lng: 18.2192178 },
    { lat: 48.2418023, lng: 18.2190256 },
    { lat: 48.2421814, lng: 18.2187534 },
    { lat: 48.2425695, lng: 18.2201357 },
    { lat: 48.2436861, lng: 18.2210809 },
    { lat: 48.2449842, lng: 18.2227109 },
    { lat: 48.2466049, lng: 18.2220897 },
    { lat: 48.2471593, lng: 18.2220178 },
    { lat: 48.2474385, lng: 18.2218906 },
    { lat: 48.2488546, lng: 18.2220656 },
    { lat: 48.2494379, lng: 18.2220452 },
    { lat: 48.2505772, lng: 18.221976 },
    { lat: 48.2520472, lng: 18.2216597 },
    { lat: 48.2529425, lng: 18.2216296 },
    { lat: 48.2545901, lng: 18.2218245 },
    { lat: 48.2543655, lng: 18.221535 },
    { lat: 48.2539433, lng: 18.2205919 },
    { lat: 48.2537589, lng: 18.2200575 },
    { lat: 48.2531856, lng: 18.2181275 },
    { lat: 48.2529416, lng: 18.216661 },
    { lat: 48.2527178, lng: 18.2156994 },
    { lat: 48.2525774, lng: 18.2140553 },
    { lat: 48.2521673, lng: 18.2118457 },
    { lat: 48.2518245, lng: 18.2092025 },
    { lat: 48.2515011, lng: 18.2071063 },
    { lat: 48.2512889, lng: 18.2043237 },
    { lat: 48.2507978, lng: 18.2007389 },
    { lat: 48.2506157, lng: 18.198868 },
    { lat: 48.2503953, lng: 18.197792 },
    { lat: 48.2501326, lng: 18.1969573 },
    { lat: 48.2495479, lng: 18.1974442 },
    { lat: 48.2490237, lng: 18.1977061 },
    { lat: 48.2482233, lng: 18.1977755 },
    { lat: 48.2474879, lng: 18.1977226 },
    { lat: 48.2463853, lng: 18.1981699 },
    { lat: 48.2461731, lng: 18.198206 },
    { lat: 48.2458517, lng: 18.1983675 },
    { lat: 48.2448958, lng: 18.1991224 },
    { lat: 48.2426487, lng: 18.1957724 },
    { lat: 48.241957, lng: 18.1945057 },
    { lat: 48.2418228, lng: 18.19426 },
    { lat: 48.2396847, lng: 18.1927136 },
    { lat: 48.2374806, lng: 18.1895246 },
    { lat: 48.2358099, lng: 18.1873237 },
    { lat: 48.2338696, lng: 18.1847736 },
    { lat: 48.2337203, lng: 18.1843014 },
    { lat: 48.2333765, lng: 18.1826402 },
    { lat: 48.233403, lng: 18.1817795 },
    { lat: 48.2333205, lng: 18.1815227 },
    { lat: 48.2332507, lng: 18.1809824 },
    { lat: 48.2315059, lng: 18.1759827 },
    { lat: 48.2306661, lng: 18.1739444 },
    { lat: 48.2301709, lng: 18.1719725 },
    { lat: 48.2306736, lng: 18.1712871 },
    { lat: 48.2311512, lng: 18.1652583 },
    { lat: 48.2324961, lng: 18.1644429 },
    { lat: 48.2331301, lng: 18.1638949 },
    { lat: 48.2347709, lng: 18.1622339 },
    { lat: 48.2363113, lng: 18.1608805 },
    { lat: 48.2368345, lng: 18.1601023 },
    { lat: 48.2376301, lng: 18.1592488 },
    { lat: 48.2399233, lng: 18.1577085 },
    { lat: 48.2400525, lng: 18.1576006 },
    { lat: 48.2385511, lng: 18.1556216 },
    { lat: 48.2380793, lng: 18.1550544 },
    { lat: 48.2358003, lng: 18.1566353 },
    { lat: 48.2348344, lng: 18.1571586 },
    { lat: 48.232426, lng: 18.1587754 },
    { lat: 48.2314824, lng: 18.1593428 },
    { lat: 48.2289562, lng: 18.161407 },
    { lat: 48.2238439, lng: 18.1657929 },
    { lat: 48.2236626, lng: 18.1659363 },
    { lat: 48.2222257, lng: 18.1668729 },
    { lat: 48.2183935, lng: 18.1687354 },
    { lat: 48.2143939, lng: 18.1705834 },
    { lat: 48.2099318, lng: 18.1724384 },
    { lat: 48.2074087, lng: 18.1726939 },
    { lat: 48.206194, lng: 18.172724 },
    { lat: 48.2051817, lng: 18.1731703 },
    { lat: 48.2043601, lng: 18.1734558 },
    { lat: 48.2028297, lng: 18.1737858 },
    { lat: 48.2019652, lng: 18.1740744 },
    { lat: 48.2010849, lng: 18.1744963 },
    { lat: 48.20074, lng: 18.174804 },
    { lat: 48.200763, lng: 18.174834 },
    { lat: 48.201007, lng: 18.175234 },
    { lat: 48.201474, lng: 18.175994 },
    { lat: 48.2018, lng: 18.176612 },
    { lat: 48.202009, lng: 18.177034 },
    { lat: 48.202621, lng: 18.17824 },
    { lat: 48.202727, lng: 18.178438 },
    { lat: 48.202971, lng: 18.179014 },
    { lat: 48.203374, lng: 18.179938 },
    { lat: 48.203633, lng: 18.180537 },
    { lat: 48.203797, lng: 18.181168 },
    { lat: 48.204103, lng: 18.182335 },
    { lat: 48.204293, lng: 18.183012 },
    { lat: 48.204564, lng: 18.183987 },
    { lat: 48.204678, lng: 18.184383 },
    { lat: 48.204767, lng: 18.18473 },
    { lat: 48.204951, lng: 18.185223 },
    { lat: 48.205145, lng: 18.185653 },
    { lat: 48.205676, lng: 18.186891 },
    { lat: 48.205972, lng: 18.187691 },
    { lat: 48.20624, lng: 18.188414 },
    { lat: 48.206581, lng: 18.189245 },
    { lat: 48.206587, lng: 18.189267 },
    { lat: 48.206619, lng: 18.189412 },
    { lat: 48.206537, lng: 18.189453 },
    { lat: 48.20637, lng: 18.189539 },
    { lat: 48.20624, lng: 18.189669 },
    { lat: 48.206143, lng: 18.189792 },
    { lat: 48.206093, lng: 18.189914 },
    { lat: 48.206052, lng: 18.190048 },
    { lat: 48.206027, lng: 18.190196 },
    { lat: 48.205976, lng: 18.190464 },
    { lat: 48.205934, lng: 18.190605 },
    { lat: 48.205891, lng: 18.1907 },
    { lat: 48.20584, lng: 18.190752 },
    { lat: 48.205794, lng: 18.190765 },
    { lat: 48.205751, lng: 18.190746 },
    { lat: 48.205652, lng: 18.190634 },
    { lat: 48.205434, lng: 18.190352 },
    { lat: 48.205269, lng: 18.190159 },
    { lat: 48.205119, lng: 18.190062 },
    { lat: 48.205012, lng: 18.190017 },
    { lat: 48.204897, lng: 18.190018 },
    { lat: 48.204729, lng: 18.190089 },
    { lat: 48.20462, lng: 18.19016 },
    { lat: 48.204582, lng: 18.19021 },
    { lat: 48.204527, lng: 18.190305 },
    { lat: 48.204469, lng: 18.190516 },
    { lat: 48.204442, lng: 18.190758 },
    { lat: 48.204447, lng: 18.190854 },
    { lat: 48.204474, lng: 18.190928 },
    { lat: 48.204513, lng: 18.190994 },
    { lat: 48.204559, lng: 18.191046 },
    { lat: 48.204609, lng: 18.19109 },
    { lat: 48.204717, lng: 18.19119 },
    { lat: 48.204823, lng: 18.191305 },
    { lat: 48.204936, lng: 18.191422 },
    { lat: 48.205012, lng: 18.191524 },
    { lat: 48.20506, lng: 18.191627 },
    { lat: 48.205081, lng: 18.191731 },
    { lat: 48.205076, lng: 18.191771 },
    { lat: 48.205054, lng: 18.191853 },
    { lat: 48.205014, lng: 18.191895 },
    { lat: 48.204879, lng: 18.191909 },
    { lat: 48.204647, lng: 18.191873 },
    { lat: 48.204509, lng: 18.191822 },
    { lat: 48.204426, lng: 18.19175 },
    { lat: 48.204274, lng: 18.191487 },
    { lat: 48.204173, lng: 18.191322 },
    { lat: 48.204077, lng: 18.191193 },
    { lat: 48.203713, lng: 18.190884 },
    { lat: 48.203615, lng: 18.190811 },
    { lat: 48.203569, lng: 18.190732 },
    { lat: 48.203515, lng: 18.190575 },
    { lat: 48.203479, lng: 18.190452 },
    { lat: 48.203453, lng: 18.190331 },
    { lat: 48.203382, lng: 18.190174 },
    { lat: 48.203301, lng: 18.190071 },
    { lat: 48.203261, lng: 18.190042 },
    { lat: 48.203213, lng: 18.190013 },
    { lat: 48.203153, lng: 18.190026 },
    { lat: 48.203013, lng: 18.19007 },
    { lat: 48.202891, lng: 18.190123 },
    { lat: 48.202731, lng: 18.19019 },
    { lat: 48.202534, lng: 18.190247 },
    { lat: 48.202409, lng: 18.190281 },
    { lat: 48.202326, lng: 18.190264 },
    { lat: 48.202248, lng: 18.190219 },
    { lat: 48.202202, lng: 18.190162 },
    { lat: 48.202174, lng: 18.190086 },
    { lat: 48.202158, lng: 18.189992 },
    { lat: 48.20215, lng: 18.189901 },
    { lat: 48.202169, lng: 18.189656 },
    { lat: 48.202244, lng: 18.189228 },
    { lat: 48.202292, lng: 18.188841 },
    { lat: 48.202295, lng: 18.18867 },
    { lat: 48.202287, lng: 18.188557 },
    { lat: 48.202265, lng: 18.188412 },
    { lat: 48.202233, lng: 18.188311 },
    { lat: 48.202198, lng: 18.188262 },
    { lat: 48.202149, lng: 18.188223 },
    { lat: 48.202088, lng: 18.188194 },
    { lat: 48.201992, lng: 18.188215 },
    { lat: 48.201917, lng: 18.188253 },
    { lat: 48.201728, lng: 18.188315 },
    { lat: 48.201634, lng: 18.188376 },
    { lat: 48.201556, lng: 18.188458 },
    { lat: 48.201486, lng: 18.188565 },
    { lat: 48.201454, lng: 18.188642 },
    { lat: 48.201428, lng: 18.188752 },
    { lat: 48.201383, lng: 18.188994 },
    { lat: 48.201369, lng: 18.18913 },
    { lat: 48.201357, lng: 18.189217 },
    { lat: 48.201351, lng: 18.189321 },
    { lat: 48.201356, lng: 18.189583 },
    { lat: 48.201374, lng: 18.189771 },
    { lat: 48.201389, lng: 18.189872 },
    { lat: 48.201442, lng: 18.190103 },
    { lat: 48.201505, lng: 18.190303 },
    { lat: 48.201607, lng: 18.190549 },
    { lat: 48.2017278, lng: 18.1906285 },
    { lat: 48.2018219, lng: 18.1905791 },
    { lat: 48.2026737, lng: 18.1927261 },
    { lat: 48.2033104, lng: 18.193952 },
    { lat: 48.2034378, lng: 18.1946309 },
    { lat: 48.2037712, lng: 18.1958429 },
    { lat: 48.2043632, lng: 18.1976018 },
    { lat: 48.2049881, lng: 18.1996961 },
    { lat: 48.2057023, lng: 18.201481 },
    { lat: 48.2073092, lng: 18.205854 },
    { lat: 48.2076269, lng: 18.2072861 },
    { lat: 48.2076565, lng: 18.2076212 },
    { lat: 48.2081323, lng: 18.2096919 },
    { lat: 48.208615, lng: 18.21325 },
    { lat: 48.2096471, lng: 18.2153254 },
    { lat: 48.2107687, lng: 18.2199421 },
    { lat: 48.210767, lng: 18.2210444 },
    { lat: 48.2108518, lng: 18.2225249 },
    { lat: 48.2107184, lng: 18.2235377 },
    { lat: 48.2104828, lng: 18.2244392 },
    { lat: 48.2105453, lng: 18.2251885 },
    { lat: 48.2105429, lng: 18.2252405 },
  ],
  MaléChyndice: [
    { lat: 48.315409, lng: 18.26676 },
    { lat: 48.3152523, lng: 18.2665058 },
    { lat: 48.3150951, lng: 18.2665584 },
    { lat: 48.315, lng: 18.2665136 },
    { lat: 48.3146898, lng: 18.2668092 },
    { lat: 48.3144131, lng: 18.2668665 },
    { lat: 48.3142931, lng: 18.2669759 },
    { lat: 48.3138009, lng: 18.2669894 },
    { lat: 48.3134689, lng: 18.2673392 },
    { lat: 48.3129616, lng: 18.267663 },
    { lat: 48.3123545, lng: 18.2675236 },
    { lat: 48.3120442, lng: 18.2675849 },
    { lat: 48.3118025, lng: 18.2678139 },
    { lat: 48.3116221, lng: 18.267896 },
    { lat: 48.311566, lng: 18.2680202 },
    { lat: 48.3109104, lng: 18.268255 },
    { lat: 48.3107562, lng: 18.2684195 },
    { lat: 48.3104956, lng: 18.2685016 },
    { lat: 48.3103016, lng: 18.2684827 },
    { lat: 48.3100619, lng: 18.2688252 },
    { lat: 48.3097615, lng: 18.2690592 },
    { lat: 48.3088625, lng: 18.2691038 },
    { lat: 48.3087027, lng: 18.2691705 },
    { lat: 48.3083341, lng: 18.2690383 },
    { lat: 48.3075492, lng: 18.2691221 },
    { lat: 48.3075434, lng: 18.2690626 },
    { lat: 48.3074855, lng: 18.2690556 },
    { lat: 48.3073162, lng: 18.2691132 },
    { lat: 48.306599, lng: 18.2688271 },
    { lat: 48.3063181, lng: 18.2686298 },
    { lat: 48.3062244, lng: 18.2686698 },
    { lat: 48.3060397, lng: 18.268598 },
    { lat: 48.3058153, lng: 18.2687385 },
    { lat: 48.3057873, lng: 18.2687003 },
    { lat: 48.3054298, lng: 18.2687818 },
    { lat: 48.3052564, lng: 18.2686532 },
    { lat: 48.3051443, lng: 18.2687233 },
    { lat: 48.3048259, lng: 18.2686835 },
    { lat: 48.304742, lng: 18.2687323 },
    { lat: 48.3047272, lng: 18.2685733 },
    { lat: 48.3046358, lng: 18.2685145 },
    { lat: 48.3045051, lng: 18.2685569 },
    { lat: 48.304222, lng: 18.2684389 },
    { lat: 48.3037986, lng: 18.2685721 },
    { lat: 48.3009253, lng: 18.2677195 },
    { lat: 48.3002639, lng: 18.2671251 },
    { lat: 48.2984998, lng: 18.2623439 },
    { lat: 48.2973117, lng: 18.2594563 },
    { lat: 48.2962064, lng: 18.2573637 },
    { lat: 48.2943305, lng: 18.2544126 },
    { lat: 48.2936625, lng: 18.2551781 },
    { lat: 48.2939624, lng: 18.2558987 },
    { lat: 48.2948621, lng: 18.2571617 },
    { lat: 48.2948188, lng: 18.2572339 },
    { lat: 48.2945625, lng: 18.2573467 },
    { lat: 48.2940262, lng: 18.25744 },
    { lat: 48.2940161, lng: 18.2573829 },
    { lat: 48.2935765, lng: 18.2575891 },
    { lat: 48.2929881, lng: 18.2577548 },
    { lat: 48.2924353, lng: 18.2580302 },
    { lat: 48.2920551, lng: 18.2583277 },
    { lat: 48.291741, lng: 18.2586343 },
    { lat: 48.2892027, lng: 18.2616454 },
    { lat: 48.2891035, lng: 18.2617663 },
    { lat: 48.2884064, lng: 18.2625553 },
    { lat: 48.2877516, lng: 18.2630812 },
    { lat: 48.2870643, lng: 18.2637657 },
    { lat: 48.2861706, lng: 18.2650593 },
    { lat: 48.2853014, lng: 18.266529 },
    { lat: 48.2851572, lng: 18.2667683 },
    { lat: 48.2857429, lng: 18.2675746 },
    { lat: 48.2868394, lng: 18.2693602 },
    { lat: 48.287842, lng: 18.2707056 },
    { lat: 48.2878379, lng: 18.2711441 },
    { lat: 48.2879394, lng: 18.2714428 },
    { lat: 48.2878605, lng: 18.2715589 },
    { lat: 48.2892962, lng: 18.2735539 },
    { lat: 48.2930077, lng: 18.2797628 },
    { lat: 48.2938062, lng: 18.2809771 },
    { lat: 48.2945334, lng: 18.2819133 },
    { lat: 48.295332, lng: 18.2833066 },
    { lat: 48.2959556, lng: 18.2847377 },
    { lat: 48.2961289, lng: 18.2848111 },
    { lat: 48.2963975, lng: 18.2858775 },
    { lat: 48.2967928, lng: 18.2878341 },
    { lat: 48.2972776, lng: 18.2899202 },
    { lat: 48.2980163, lng: 18.2938575 },
    { lat: 48.2980661, lng: 18.2938603 },
    { lat: 48.2982518, lng: 18.2949003 },
    { lat: 48.2986001, lng: 18.2964147 },
    { lat: 48.2992808, lng: 18.2976438 },
    { lat: 48.2996558, lng: 18.2986372 },
    { lat: 48.2998806, lng: 18.2990573 },
    { lat: 48.2999065, lng: 18.2991355 },
    { lat: 48.2999689, lng: 18.299338 },
    { lat: 48.2999577, lng: 18.2996754 },
    { lat: 48.2998376, lng: 18.3000607 },
    { lat: 48.2997628, lng: 18.3005313 },
    { lat: 48.2997732, lng: 18.3008507 },
    { lat: 48.3001032, lng: 18.3025427 },
    { lat: 48.3002736, lng: 18.3037462 },
    { lat: 48.3005622, lng: 18.3066703 },
    { lat: 48.300612, lng: 18.308256 },
    { lat: 48.300772, lng: 18.308229 },
    { lat: 48.300826, lng: 18.308223 },
    { lat: 48.30084, lng: 18.308225 },
    { lat: 48.300864, lng: 18.308227 },
    { lat: 48.300945, lng: 18.308265 },
    { lat: 48.301067, lng: 18.308321 },
    { lat: 48.301343, lng: 18.308448 },
    { lat: 48.301468, lng: 18.308505 },
    { lat: 48.301575, lng: 18.308556 },
    { lat: 48.301652, lng: 18.308595 },
    { lat: 48.301678, lng: 18.308607 },
    { lat: 48.301708, lng: 18.308662 },
    { lat: 48.301719, lng: 18.308681 },
    { lat: 48.301738, lng: 18.308706 },
    { lat: 48.301759, lng: 18.308735 },
    { lat: 48.301772, lng: 18.308755 },
    { lat: 48.301815, lng: 18.308743 },
    { lat: 48.301828, lng: 18.308739 },
    { lat: 48.301914, lng: 18.308716 },
    { lat: 48.301963, lng: 18.308703 },
    { lat: 48.302009, lng: 18.308697 },
    { lat: 48.302049, lng: 18.308682 },
    { lat: 48.302102, lng: 18.308663 },
    { lat: 48.302225, lng: 18.30779 },
    { lat: 48.302191, lng: 18.307285 },
    { lat: 48.30237, lng: 18.306943 },
    { lat: 48.302426, lng: 18.306834 },
    { lat: 48.302514, lng: 18.30695 },
    { lat: 48.302712, lng: 18.307213 },
    { lat: 48.302909, lng: 18.307623 },
    { lat: 48.302957, lng: 18.307643 },
    { lat: 48.302995, lng: 18.307662 },
    { lat: 48.303187, lng: 18.307745 },
    { lat: 48.303207, lng: 18.30776 },
    { lat: 48.303401, lng: 18.308033 },
    { lat: 48.303458, lng: 18.308139 },
    { lat: 48.303709, lng: 18.308074 },
    { lat: 48.303785, lng: 18.308054 },
    { lat: 48.303853, lng: 18.308038 },
    { lat: 48.303909, lng: 18.307739 },
    { lat: 48.30397, lng: 18.307663 },
    { lat: 48.304043, lng: 18.307587 },
    { lat: 48.304139, lng: 18.30766 },
    { lat: 48.304228, lng: 18.307835 },
    { lat: 48.30427, lng: 18.307919 },
    { lat: 48.304307, lng: 18.307991 },
    { lat: 48.304316, lng: 18.308007 },
    { lat: 48.304317, lng: 18.308112 },
    { lat: 48.304498, lng: 18.308141 },
    { lat: 48.304817, lng: 18.308209 },
    { lat: 48.304993, lng: 18.308248 },
    { lat: 48.305047, lng: 18.308427 },
    { lat: 48.305043, lng: 18.308655 },
    { lat: 48.305061, lng: 18.308688 },
    { lat: 48.305076, lng: 18.308717 },
    { lat: 48.305085, lng: 18.308733 },
    { lat: 48.305125, lng: 18.308809 },
    { lat: 48.305135, lng: 18.308828 },
    { lat: 48.305144, lng: 18.308845 },
    { lat: 48.305155, lng: 18.308866 },
    { lat: 48.305167, lng: 18.308887 },
    { lat: 48.305178, lng: 18.308897 },
    { lat: 48.305336, lng: 18.309041 },
    { lat: 48.305435, lng: 18.30913 },
    { lat: 48.305518, lng: 18.309097 },
    { lat: 48.305537, lng: 18.309089 },
    { lat: 48.305635, lng: 18.30905 },
    { lat: 48.305655, lng: 18.309042 },
    { lat: 48.30576, lng: 18.30917 },
    { lat: 48.305777, lng: 18.309159 },
    { lat: 48.305882, lng: 18.309098 },
    { lat: 48.305948, lng: 18.309059 },
    { lat: 48.306042, lng: 18.309003 },
    { lat: 48.306074, lng: 18.308984 },
    { lat: 48.306124, lng: 18.308955 },
    { lat: 48.306137, lng: 18.30895 },
    { lat: 48.306175, lng: 18.308937 },
    { lat: 48.306181, lng: 18.308926 },
    { lat: 48.306237, lng: 18.308839 },
    { lat: 48.306247, lng: 18.308823 },
    { lat: 48.306257, lng: 18.308806 },
    { lat: 48.306273, lng: 18.308781 },
    { lat: 48.306309, lng: 18.308726 },
    { lat: 48.306318, lng: 18.30871 },
    { lat: 48.306329, lng: 18.308694 },
    { lat: 48.306344, lng: 18.308671 },
    { lat: 48.306372, lng: 18.30863 },
    { lat: 48.306507, lng: 18.308669 },
    { lat: 48.306576, lng: 18.308689 },
    { lat: 48.306625, lng: 18.308704 },
    { lat: 48.306683, lng: 18.30872 },
    { lat: 48.306698, lng: 18.308777 },
    { lat: 48.306704, lng: 18.308798 },
    { lat: 48.306708, lng: 18.308814 },
    { lat: 48.306714, lng: 18.308831 },
    { lat: 48.306716, lng: 18.308839 },
    { lat: 48.306723, lng: 18.308863 },
    { lat: 48.306908, lng: 18.309488 },
    { lat: 48.306934, lng: 18.309621 },
    { lat: 48.306947, lng: 18.309663 },
    { lat: 48.306979, lng: 18.309761 },
    { lat: 48.307154, lng: 18.309904 },
    { lat: 48.307274, lng: 18.310001 },
    { lat: 48.307316, lng: 18.310033 },
    { lat: 48.307353, lng: 18.31004 },
    { lat: 48.30744, lng: 18.310058 },
    { lat: 48.307522, lng: 18.310074 },
    { lat: 48.307604, lng: 18.310091 },
    { lat: 48.307705, lng: 18.310111 },
    { lat: 48.307778, lng: 18.310073 },
    { lat: 48.307823, lng: 18.31005 },
    { lat: 48.307907, lng: 18.310006 },
    { lat: 48.307973, lng: 18.309972 },
    { lat: 48.308043, lng: 18.30994 },
    { lat: 48.308121, lng: 18.309904 },
    { lat: 48.30812, lng: 18.309568 },
    { lat: 48.308271, lng: 18.309333 },
    { lat: 48.308389, lng: 18.309162 },
    { lat: 48.308534, lng: 18.309051 },
    { lat: 48.308718, lng: 18.309143 },
    { lat: 48.308854, lng: 18.309295 },
    { lat: 48.309017, lng: 18.309456 },
    { lat: 48.309092, lng: 18.30953 },
    { lat: 48.309186, lng: 18.30953 },
    { lat: 48.309245, lng: 18.309512 },
    { lat: 48.309269, lng: 18.30945 },
    { lat: 48.309307, lng: 18.309352 },
    { lat: 48.309259, lng: 18.30908 },
    { lat: 48.309398, lng: 18.309092 },
    { lat: 48.309562, lng: 18.309106 },
    { lat: 48.30976, lng: 18.309304 },
    { lat: 48.309801, lng: 18.309335 },
    { lat: 48.309875, lng: 18.309334 },
    { lat: 48.310053, lng: 18.309332 },
    { lat: 48.310125, lng: 18.309224 },
    { lat: 48.310162, lng: 18.309179 },
    { lat: 48.310252, lng: 18.309214 },
    { lat: 48.31034, lng: 18.309249 },
    { lat: 48.310398, lng: 18.309272 },
    { lat: 48.310484, lng: 18.309305 },
    { lat: 48.310588, lng: 18.309346 },
    { lat: 48.310593, lng: 18.309349 },
    { lat: 48.310669, lng: 18.30934 },
    { lat: 48.310692, lng: 18.309338 },
    { lat: 48.310767, lng: 18.309297 },
    { lat: 48.310848, lng: 18.309226 },
    { lat: 48.310976, lng: 18.30914 },
    { lat: 48.311, lng: 18.309118 },
    { lat: 48.311029, lng: 18.309092 },
    { lat: 48.311031, lng: 18.30898 },
    { lat: 48.311176, lng: 18.309006 },
    { lat: 48.3113, lng: 18.308925 },
    { lat: 48.311321, lng: 18.30892 },
    { lat: 48.311524, lng: 18.30887 },
    { lat: 48.311595, lng: 18.308747 },
    { lat: 48.311521, lng: 18.308698 },
    { lat: 48.311504, lng: 18.308686 },
    { lat: 48.311501, lng: 18.308677 },
    { lat: 48.311496, lng: 18.308665 },
    { lat: 48.31149, lng: 18.308646 },
    { lat: 48.311483, lng: 18.308623 },
    { lat: 48.311466, lng: 18.308577 },
    { lat: 48.311624, lng: 18.308498 },
    { lat: 48.311705, lng: 18.308457 },
    { lat: 48.311703, lng: 18.308436 },
    { lat: 48.311702, lng: 18.308409 },
    { lat: 48.3117, lng: 18.308378 },
    { lat: 48.311697, lng: 18.3083 },
    { lat: 48.31169, lng: 18.308135 },
    { lat: 48.311755, lng: 18.308014 },
    { lat: 48.311799, lng: 18.307932 },
    { lat: 48.311998, lng: 18.30788 },
    { lat: 48.312029, lng: 18.307872 },
    { lat: 48.312094, lng: 18.307822 },
    { lat: 48.312186, lng: 18.307749 },
    { lat: 48.312203, lng: 18.307736 },
    { lat: 48.312213, lng: 18.307631 },
    { lat: 48.312261, lng: 18.307604 },
    { lat: 48.312262, lng: 18.307603 },
    { lat: 48.312308, lng: 18.307581 },
    { lat: 48.312362, lng: 18.307555 },
    { lat: 48.31236, lng: 18.307659 },
    { lat: 48.312467, lng: 18.307551 },
    { lat: 48.312599, lng: 18.307417 },
    { lat: 48.312838, lng: 18.307175 },
    { lat: 48.312851, lng: 18.307161 },
    { lat: 48.312893, lng: 18.307119 },
    { lat: 48.31303, lng: 18.306982 },
    { lat: 48.313085, lng: 18.306925 },
    { lat: 48.313197, lng: 18.306774 },
    { lat: 48.313249, lng: 18.306694 },
    { lat: 48.313241, lng: 18.306677 },
    { lat: 48.313227, lng: 18.306647 },
    { lat: 48.313227, lng: 18.306645 },
    { lat: 48.313086, lng: 18.306315 },
    { lat: 48.312972, lng: 18.306047 },
    { lat: 48.312935, lng: 18.305966 },
    { lat: 48.312905, lng: 18.305912 },
    { lat: 48.313055, lng: 18.305811 },
    { lat: 48.313484, lng: 18.305558 },
    { lat: 48.313779, lng: 18.305384 },
    { lat: 48.314055, lng: 18.305277 },
    { lat: 48.314055, lng: 18.305274 },
    { lat: 48.313849, lng: 18.304512 },
    { lat: 48.313727, lng: 18.304062 },
    { lat: 48.313464, lng: 18.30308 },
    { lat: 48.313308, lng: 18.302518 },
    { lat: 48.313308, lng: 18.302517 },
    { lat: 48.312868, lng: 18.300549 },
    { lat: 48.312837, lng: 18.300414 },
    { lat: 48.312632, lng: 18.299533 },
    { lat: 48.312446, lng: 18.298732 },
    { lat: 48.312056, lng: 18.297016 },
    { lat: 48.312056, lng: 18.297015 },
    { lat: 48.311782, lng: 18.295813 },
    { lat: 48.311762, lng: 18.295728 },
    { lat: 48.311678, lng: 18.295342 },
    { lat: 48.311638, lng: 18.295167 },
    { lat: 48.311346, lng: 18.293825 },
    { lat: 48.311308, lng: 18.293658 },
    { lat: 48.311165, lng: 18.293065 },
    { lat: 48.311093, lng: 18.292776 },
    { lat: 48.310962, lng: 18.292244 },
    { lat: 48.311214, lng: 18.292099 },
    { lat: 48.311281, lng: 18.292063 },
    { lat: 48.311718, lng: 18.291824 },
    { lat: 48.312203, lng: 18.291592 },
    { lat: 48.312742, lng: 18.291339 },
    { lat: 48.312974, lng: 18.29124 },
    { lat: 48.313257, lng: 18.291099 },
    { lat: 48.313816, lng: 18.290819 },
    { lat: 48.314471, lng: 18.290508 },
    { lat: 48.314492, lng: 18.290491 },
    { lat: 48.314499, lng: 18.290487 },
    { lat: 48.314567, lng: 18.290446 },
    { lat: 48.314569, lng: 18.290445 },
    { lat: 48.314611, lng: 18.290427 },
    { lat: 48.314882, lng: 18.290278 },
    { lat: 48.315106, lng: 18.290128 },
    { lat: 48.315339, lng: 18.28988 },
    { lat: 48.31554, lng: 18.289667 },
    { lat: 48.315582, lng: 18.289614 },
    { lat: 48.315969, lng: 18.289137 },
    { lat: 48.316313, lng: 18.2889 },
    { lat: 48.316321, lng: 18.288893 },
    { lat: 48.316915, lng: 18.288452 },
    { lat: 48.317474, lng: 18.288089 },
    { lat: 48.317699, lng: 18.287935 },
    { lat: 48.318062, lng: 18.287729 },
    { lat: 48.318065, lng: 18.287728 },
    { lat: 48.318165, lng: 18.287668 },
    { lat: 48.318311, lng: 18.287568 },
    { lat: 48.318893, lng: 18.287191 },
    { lat: 48.318935, lng: 18.287156 },
    { lat: 48.318969, lng: 18.287122 },
    { lat: 48.319247, lng: 18.286745 },
    { lat: 48.319307, lng: 18.286646 },
    { lat: 48.319317, lng: 18.286661 },
    { lat: 48.31959, lng: 18.28609 },
    { lat: 48.319725, lng: 18.285778 },
    { lat: 48.319811, lng: 18.28565 },
    { lat: 48.319859, lng: 18.285521 },
    { lat: 48.319925, lng: 18.285367 },
    { lat: 48.320037, lng: 18.28503 },
    { lat: 48.32011, lng: 18.284836 },
    { lat: 48.320309, lng: 18.284436 },
    { lat: 48.320412, lng: 18.284158 },
    { lat: 48.320629, lng: 18.28367 },
    { lat: 48.320783, lng: 18.283214 },
    { lat: 48.32094, lng: 18.282316 },
    { lat: 48.321165, lng: 18.281646 },
    { lat: 48.321349, lng: 18.281015 },
    { lat: 48.321519, lng: 18.280402 },
    { lat: 48.321542, lng: 18.280333 },
    { lat: 48.321584, lng: 18.280241 },
    { lat: 48.321784, lng: 18.279846 },
    { lat: 48.321861, lng: 18.279679 },
    { lat: 48.321903, lng: 18.279601 },
    { lat: 48.32203, lng: 18.279173 },
    { lat: 48.322103, lng: 18.278899 },
    { lat: 48.322181, lng: 18.278593 },
    { lat: 48.322355, lng: 18.277943 },
    { lat: 48.322439, lng: 18.277427 },
    { lat: 48.322462, lng: 18.277319 },
    { lat: 48.322524, lng: 18.277032 },
    { lat: 48.322499, lng: 18.277017 },
    { lat: 48.322197, lng: 18.276822 },
    { lat: 48.322002, lng: 18.276696 },
    { lat: 48.321757, lng: 18.276545 },
    { lat: 48.321595, lng: 18.276398 },
    { lat: 48.321268, lng: 18.275984 },
    { lat: 48.321245, lng: 18.275951 },
    { lat: 48.321288, lng: 18.275875 },
    { lat: 48.321358, lng: 18.275738 },
    { lat: 48.321335, lng: 18.275695 },
    { lat: 48.321224, lng: 18.275515 },
    { lat: 48.321007, lng: 18.275077 },
    { lat: 48.320844, lng: 18.274774 },
    { lat: 48.32061, lng: 18.274359 },
    { lat: 48.320307, lng: 18.273865 },
    { lat: 48.320191, lng: 18.2737 },
    { lat: 48.320178, lng: 18.273681 },
    { lat: 48.319902, lng: 18.27328 },
    { lat: 48.319673, lng: 18.272953 },
    { lat: 48.319501, lng: 18.272752 },
    { lat: 48.319135, lng: 18.272346 },
    { lat: 48.319109, lng: 18.272319 },
    { lat: 48.318893, lng: 18.272124 },
    { lat: 48.318332, lng: 18.271625 },
    { lat: 48.317928, lng: 18.271343 },
    { lat: 48.317664, lng: 18.271175 },
    { lat: 48.31733, lng: 18.270808 },
    { lat: 48.317197, lng: 18.270661 },
    { lat: 48.31715, lng: 18.270587 },
    { lat: 48.317096, lng: 18.270505 },
    { lat: 48.316929, lng: 18.270187 },
    { lat: 48.316913, lng: 18.2702 },
    { lat: 48.316759, lng: 18.269844 },
    { lat: 48.316627, lng: 18.269597 },
    { lat: 48.316538, lng: 18.269378 },
    { lat: 48.316496, lng: 18.269279 },
    { lat: 48.31647, lng: 18.269219 },
    { lat: 48.31636, lng: 18.268959 },
    { lat: 48.316211, lng: 18.268628 },
    { lat: 48.316184, lng: 18.268565 },
    { lat: 48.315901, lng: 18.267902 },
    { lat: 48.315617, lng: 18.267236 },
    { lat: 48.315514, lng: 18.266988 },
    { lat: 48.3155, lng: 18.266951 },
    { lat: 48.315409, lng: 18.26676 },
  ],
  NovéSady: [
    { lat: 48.425915, lng: 17.919974 },
    { lat: 48.425872, lng: 17.919965 },
    { lat: 48.425696, lng: 17.919963 },
    { lat: 48.425376, lng: 17.919981 },
    { lat: 48.424971, lng: 17.919978 },
    { lat: 48.424865, lng: 17.919966 },
    { lat: 48.424647, lng: 17.919829 },
    { lat: 48.424495, lng: 17.919697 },
    { lat: 48.4237325, lng: 17.9208922 },
    { lat: 48.4228327, lng: 17.9232099 },
    { lat: 48.4225122, lng: 17.9241476 },
    { lat: 48.4218433, lng: 17.9252589 },
    { lat: 48.4215348, lng: 17.9262117 },
    { lat: 48.4212484, lng: 17.9274888 },
    { lat: 48.421012, lng: 17.9280796 },
    { lat: 48.4204817, lng: 17.9290018 },
    { lat: 48.4200949, lng: 17.9297946 },
    { lat: 48.41946, lng: 17.9307242 },
    { lat: 48.4191085, lng: 17.9316355 },
    { lat: 48.4184089, lng: 17.9327252 },
    { lat: 48.4179241, lng: 17.9340312 },
    { lat: 48.4158132, lng: 17.9380778 },
    { lat: 48.4155221, lng: 17.9388905 },
    { lat: 48.4151782, lng: 17.9398888 },
    { lat: 48.4150346, lng: 17.9402629 },
    { lat: 48.4143548, lng: 17.9418713 },
    { lat: 48.4140793, lng: 17.9427522 },
    { lat: 48.4138744, lng: 17.9431179 },
    { lat: 48.4137531, lng: 17.9434847 },
    { lat: 48.4117157, lng: 17.9446309 },
    { lat: 48.4112458, lng: 17.9453374 },
    { lat: 48.4110926, lng: 17.9460581 },
    { lat: 48.4111727, lng: 17.9470136 },
    { lat: 48.4111604, lng: 17.9472967 },
    { lat: 48.4109766, lng: 17.9475245 },
    { lat: 48.4108408, lng: 17.9475749 },
    { lat: 48.4105208, lng: 17.948186 },
    { lat: 48.4101265, lng: 17.948631 },
    { lat: 48.4100784, lng: 17.9487512 },
    { lat: 48.4098046, lng: 17.9489656 },
    { lat: 48.4093245, lng: 17.9492065 },
    { lat: 48.4092298, lng: 17.9491501 },
    { lat: 48.4091235, lng: 17.9492235 },
    { lat: 48.4089516, lng: 17.9489917 },
    { lat: 48.4088116, lng: 17.9489918 },
    { lat: 48.4086394, lng: 17.9488667 },
    { lat: 48.4083587, lng: 17.9489104 },
    { lat: 48.4079372, lng: 17.9495793 },
    { lat: 48.4047145, lng: 17.9526051 },
    { lat: 48.4016452, lng: 17.9560588 },
    { lat: 48.3983624, lng: 17.9604541 },
    { lat: 48.3948832, lng: 17.9647038 },
    { lat: 48.3920389, lng: 17.9645368 },
    { lat: 48.3921954, lng: 17.9649223 },
    { lat: 48.3937138, lng: 17.9686373 },
    { lat: 48.3939656, lng: 17.9693541 },
    { lat: 48.3946715, lng: 17.9706989 },
    { lat: 48.395173, lng: 17.9714779 },
    { lat: 48.3961201, lng: 17.9726677 },
    { lat: 48.3975485, lng: 17.9747899 },
    { lat: 48.397583, lng: 17.9748444 },
    { lat: 48.3986655, lng: 17.976555 },
    { lat: 48.3994649, lng: 17.9777074 },
    { lat: 48.3999487, lng: 17.9785723 },
    { lat: 48.4018253, lng: 17.9803157 },
    { lat: 48.4034185, lng: 17.978271 },
    { lat: 48.4039551, lng: 17.9775034 },
    { lat: 48.4041797, lng: 17.977276 },
    { lat: 48.4042726, lng: 17.9769626 },
    { lat: 48.4044457, lng: 17.97678 },
    { lat: 48.404634, lng: 17.9767027 },
    { lat: 48.405207, lng: 17.9766567 },
    { lat: 48.4055912, lng: 17.9765121 },
    { lat: 48.4057988, lng: 17.9765505 },
    { lat: 48.4058005, lng: 17.9764842 },
    { lat: 48.4064633, lng: 17.9762039 },
    { lat: 48.4068259, lng: 17.976193 },
    { lat: 48.407932, lng: 17.9780768 },
    { lat: 48.4083572, lng: 17.9801478 },
    { lat: 48.4085142, lng: 17.9816226 },
    { lat: 48.4088222, lng: 17.9832252 },
    { lat: 48.4103566, lng: 17.9848937 },
    { lat: 48.410198, lng: 17.9854342 },
    { lat: 48.4094146, lng: 17.9881123 },
    { lat: 48.4091834, lng: 17.9890621 },
    { lat: 48.4088518, lng: 17.9900671 },
    { lat: 48.4092814, lng: 17.9908536 },
    { lat: 48.4099595, lng: 17.9927035 },
    { lat: 48.4117259, lng: 17.9985884 },
    { lat: 48.4123375, lng: 18.0009319 },
    { lat: 48.4129145, lng: 18.0027167 },
    { lat: 48.4134094, lng: 18.0047349 },
    { lat: 48.4138417, lng: 18.0059846 },
    { lat: 48.414516, lng: 18.0087953 },
    { lat: 48.4160747, lng: 18.0076965 },
    { lat: 48.4163903, lng: 18.0075306 },
    { lat: 48.4175433, lng: 18.0069246 },
    { lat: 48.4178552, lng: 18.0073481 },
    { lat: 48.4182719, lng: 18.0074261 },
    { lat: 48.4185525, lng: 18.0073251 },
    { lat: 48.4188636, lng: 18.0069763 },
    { lat: 48.4190838, lng: 18.006429 },
    { lat: 48.4192131, lng: 18.0062684 },
    { lat: 48.4195312, lng: 18.0060732 },
    { lat: 48.4196524, lng: 18.006064 },
    { lat: 48.421567, lng: 18.0071487 },
    { lat: 48.4218205, lng: 18.0073506 },
    { lat: 48.421835, lng: 18.0074616 },
    { lat: 48.4219724, lng: 18.0077043 },
    { lat: 48.4227325, lng: 18.0078069 },
    { lat: 48.4236679, lng: 18.0081239 },
    { lat: 48.4241971, lng: 18.0081775 },
    { lat: 48.4244467, lng: 18.0083145 },
    { lat: 48.4249168, lng: 18.008778 },
    { lat: 48.4254195, lng: 18.0084261 },
    { lat: 48.4256782, lng: 18.0084095 },
    { lat: 48.426068, lng: 18.0082672 },
    { lat: 48.4267724, lng: 18.007585 },
    { lat: 48.4270876, lng: 18.0066809 },
    { lat: 48.4270999, lng: 18.0066575 },
    { lat: 48.4273607, lng: 18.0059579 },
    { lat: 48.4273879, lng: 18.0045597 },
    { lat: 48.4272656, lng: 18.0036621 },
    { lat: 48.4271943, lng: 18.0027108 },
    { lat: 48.4270484, lng: 18.0017936 },
    { lat: 48.4270583, lng: 18.0004077 },
    { lat: 48.4269559, lng: 17.9996187 },
    { lat: 48.4269345, lng: 17.9981418 },
    { lat: 48.4267258, lng: 17.996953 },
    { lat: 48.4266358, lng: 17.9959157 },
    { lat: 48.4264941, lng: 17.9956231 },
    { lat: 48.4262084, lng: 17.995544 },
    { lat: 48.4258279, lng: 17.9920596 },
    { lat: 48.4258139, lng: 17.9914292 },
    { lat: 48.4254221, lng: 17.9882126 },
    { lat: 48.4249611, lng: 17.985635 },
    { lat: 48.4245495, lng: 17.9836542 },
    { lat: 48.4242932, lng: 17.9829191 },
    { lat: 48.4245431, lng: 17.9826043 },
    { lat: 48.424082, lng: 17.9807439 },
    { lat: 48.4242047, lng: 17.9807785 },
    { lat: 48.4243664, lng: 17.9810265 },
    { lat: 48.4249882, lng: 17.9804955 },
    { lat: 48.425734, lng: 17.9802264 },
    { lat: 48.4261647, lng: 17.9801633 },
    { lat: 48.4264643, lng: 17.9802447 },
    { lat: 48.4272421, lng: 17.9806423 },
    { lat: 48.4301144, lng: 17.9818294 },
    { lat: 48.4306837, lng: 17.9818386 },
    { lat: 48.430974, lng: 17.9817091 },
    { lat: 48.4311128, lng: 17.9794793 },
    { lat: 48.4308968, lng: 17.9791904 },
    { lat: 48.4307007, lng: 17.9792339 },
    { lat: 48.4307318, lng: 17.9789226 },
    { lat: 48.4308764, lng: 17.9783593 },
    { lat: 48.4310571, lng: 17.977998 },
    { lat: 48.4311656, lng: 17.9762453 },
    { lat: 48.4313724, lng: 17.9739299 },
    { lat: 48.4314023, lng: 17.9739592 },
    { lat: 48.4314113, lng: 17.9731295 },
    { lat: 48.4315138, lng: 17.9720874 },
    { lat: 48.4319168, lng: 17.9703319 },
    { lat: 48.4320306, lng: 17.969499 },
    { lat: 48.4320514, lng: 17.9677755 },
    { lat: 48.4321299, lng: 17.9666887 },
    { lat: 48.4326514, lng: 17.9619633 },
    { lat: 48.4327112, lng: 17.9567278 },
    { lat: 48.4338639, lng: 17.9563385 },
    { lat: 48.4351442, lng: 17.9556631 },
    { lat: 48.4367082, lng: 17.9544904 },
    { lat: 48.4370405, lng: 17.9545178 },
    { lat: 48.4372642, lng: 17.9544463 },
    { lat: 48.4387879, lng: 17.9537481 },
    { lat: 48.4412304, lng: 17.9519592 },
    { lat: 48.4421097, lng: 17.9514525 },
    { lat: 48.441864, lng: 17.950442 },
    { lat: 48.4418777, lng: 17.9502556 },
    { lat: 48.4424236, lng: 17.9512282 },
    { lat: 48.4427892, lng: 17.9523358 },
    { lat: 48.4428139, lng: 17.9526717 },
    { lat: 48.4427485, lng: 17.9531242 },
    { lat: 48.442805, lng: 17.9532563 },
    { lat: 48.4429672, lng: 17.9552269 },
    { lat: 48.4430814, lng: 17.9555104 },
    { lat: 48.4431354, lng: 17.9555678 },
    { lat: 48.4433164, lng: 17.9556659 },
    { lat: 48.4436368, lng: 17.9556449 },
    { lat: 48.4443667, lng: 17.955113 },
    { lat: 48.4445933, lng: 17.9548647 },
    { lat: 48.4449239, lng: 17.9547488 },
    { lat: 48.4458323, lng: 17.9549879 },
    { lat: 48.4463922, lng: 17.9550429 },
    { lat: 48.4471126, lng: 17.9554992 },
    { lat: 48.4474191, lng: 17.9544785 },
    { lat: 48.4478605, lng: 17.952653 },
    { lat: 48.4483632, lng: 17.951136 },
    { lat: 48.448368, lng: 17.9509518 },
    { lat: 48.4480578, lng: 17.9505409 },
    { lat: 48.4470793, lng: 17.9487402 },
    { lat: 48.4468304, lng: 17.9481287 },
    { lat: 48.4467276, lng: 17.9477522 },
    { lat: 48.4466593, lng: 17.9473469 },
    { lat: 48.446668, lng: 17.9469646 },
    { lat: 48.446749, lng: 17.947006 },
    { lat: 48.446752, lng: 17.946895 },
    { lat: 48.446786, lng: 17.946819 },
    { lat: 48.446812, lng: 17.946508 },
    { lat: 48.446835, lng: 17.946228 },
    { lat: 48.446823, lng: 17.945809 },
    { lat: 48.446808, lng: 17.945535 },
    { lat: 48.446776, lng: 17.945274 },
    { lat: 48.446671, lng: 17.944671 },
    { lat: 48.446549, lng: 17.944155 },
    { lat: 48.446265, lng: 17.943202 },
    { lat: 48.446205, lng: 17.943011 },
    { lat: 48.445809, lng: 17.943285 },
    { lat: 48.445512, lng: 17.943454 },
    { lat: 48.445077, lng: 17.943693 },
    { lat: 48.444192, lng: 17.944192 },
    { lat: 48.443856, lng: 17.944342 },
    { lat: 48.443355, lng: 17.944551 },
    { lat: 48.442916, lng: 17.944664 },
    { lat: 48.442492, lng: 17.944789 },
    { lat: 48.442173, lng: 17.944868 },
    { lat: 48.441811, lng: 17.944907 },
    { lat: 48.441586, lng: 17.944978 },
    { lat: 48.441309, lng: 17.945048 },
    { lat: 48.44096, lng: 17.945046 },
    { lat: 48.440956, lng: 17.94503 },
    { lat: 48.44093, lng: 17.945014 },
    { lat: 48.441049, lng: 17.944657 },
    { lat: 48.441108, lng: 17.944435 },
    { lat: 48.441229, lng: 17.943907 },
    { lat: 48.441347, lng: 17.943468 },
    { lat: 48.441421, lng: 17.943201 },
    { lat: 48.441437, lng: 17.942975 },
    { lat: 48.441442, lng: 17.942452 },
    { lat: 48.441445, lng: 17.942351 },
    { lat: 48.441411, lng: 17.941928 },
    { lat: 48.441385, lng: 17.94179 },
    { lat: 48.44138, lng: 17.941274 },
    { lat: 48.441378, lng: 17.941021 },
    { lat: 48.441439, lng: 17.940555 },
    { lat: 48.441497, lng: 17.940135 },
    { lat: 48.441557, lng: 17.939747 },
    { lat: 48.441703, lng: 17.938897 },
    { lat: 48.441784, lng: 17.938604 },
    { lat: 48.441801, lng: 17.938175 },
    { lat: 48.441754, lng: 17.937779 },
    { lat: 48.44171, lng: 17.937332 },
    { lat: 48.441678, lng: 17.936964 },
    { lat: 48.441648, lng: 17.936592 },
    { lat: 48.441612, lng: 17.936199 },
    { lat: 48.441596, lng: 17.935979 },
    { lat: 48.441591, lng: 17.93547 },
    { lat: 48.441527, lng: 17.935154 },
    { lat: 48.441578, lng: 17.934879 },
    { lat: 48.441697, lng: 17.934435 },
    { lat: 48.441918, lng: 17.933637 },
    { lat: 48.442046, lng: 17.933228 },
    { lat: 48.442097, lng: 17.933028 },
    { lat: 48.442131, lng: 17.932851 },
    { lat: 48.442164, lng: 17.932667 },
    { lat: 48.442194, lng: 17.932362 },
    { lat: 48.441824, lng: 17.93227 },
    { lat: 48.441457, lng: 17.932167 },
    { lat: 48.440944, lng: 17.932008 },
    { lat: 48.440584, lng: 17.931911 },
    { lat: 48.44002, lng: 17.931787 },
    { lat: 48.439693, lng: 17.931638 },
    { lat: 48.439618, lng: 17.931609 },
    { lat: 48.43905, lng: 17.931542 },
    { lat: 48.438973, lng: 17.93157 },
    { lat: 48.438566, lng: 17.931806 },
    { lat: 48.438157, lng: 17.932053 },
    { lat: 48.437939, lng: 17.932243 },
    { lat: 48.4376, lng: 17.932465 },
    { lat: 48.437479, lng: 17.932517 },
    { lat: 48.437086, lng: 17.932598 },
    { lat: 48.436848, lng: 17.932608 },
    { lat: 48.43657, lng: 17.932547 },
    { lat: 48.43657, lng: 17.932528 },
    { lat: 48.436407, lng: 17.932523 },
    { lat: 48.436243, lng: 17.932451 },
    { lat: 48.435864, lng: 17.932062 },
    { lat: 48.435528, lng: 17.931703 },
    { lat: 48.435386, lng: 17.931569 },
    { lat: 48.435073, lng: 17.931454 },
    { lat: 48.434867, lng: 17.931457 },
    { lat: 48.4347, lng: 17.931552 },
    { lat: 48.434622, lng: 17.931737 },
    { lat: 48.434556, lng: 17.931916 },
    { lat: 48.434197, lng: 17.931503 },
    { lat: 48.43389, lng: 17.931134 },
    { lat: 48.43357, lng: 17.930697 },
    { lat: 48.433243, lng: 17.93025 },
    { lat: 48.432935, lng: 17.929837 },
    { lat: 48.432645, lng: 17.929469 },
    { lat: 48.432534, lng: 17.929312 },
    { lat: 48.432302, lng: 17.928967 },
    { lat: 48.431992, lng: 17.928555 },
    { lat: 48.431677, lng: 17.928048 },
    { lat: 48.431362, lng: 17.927514 },
    { lat: 48.431258, lng: 17.927333 },
    { lat: 48.431021, lng: 17.926794 },
    { lat: 48.430891, lng: 17.926472 },
    { lat: 48.430685, lng: 17.925939 },
    { lat: 48.43049, lng: 17.925408 },
    { lat: 48.430155, lng: 17.924505 },
    { lat: 48.429916, lng: 17.923932 },
    { lat: 48.429796, lng: 17.923653 },
    { lat: 48.42958, lng: 17.923193 },
    { lat: 48.429337, lng: 17.92271 },
    { lat: 48.429276, lng: 17.92257 },
    { lat: 48.429013, lng: 17.922108 },
    { lat: 48.428886, lng: 17.921895 },
    { lat: 48.42873, lng: 17.921576 },
    { lat: 48.428602, lng: 17.92131 },
    { lat: 48.428572, lng: 17.921204 },
    { lat: 48.428545, lng: 17.921158 },
    { lat: 48.428575, lng: 17.920712 },
    { lat: 48.428594, lng: 17.920479 },
    { lat: 48.428432, lng: 17.920496 },
    { lat: 48.428211, lng: 17.920695 },
    { lat: 48.428044, lng: 17.920781 },
    { lat: 48.427881, lng: 17.920815 },
    { lat: 48.427752, lng: 17.920815 },
    { lat: 48.42761, lng: 17.920775 },
    { lat: 48.427308, lng: 17.920509 },
    { lat: 48.426979, lng: 17.920199 },
    { lat: 48.426887, lng: 17.92014 },
    { lat: 48.426796, lng: 17.920106 },
    { lat: 48.426491, lng: 17.920113 },
    { lat: 48.426254, lng: 17.920112 },
    { lat: 48.42611, lng: 17.920096 },
    { lat: 48.425915, lng: 17.919974 },
  ],
  Tajná: [
    { lat: 48.263953, lng: 18.401574 },
    { lat: 48.265362, lng: 18.401099 },
    { lat: 48.265529, lng: 18.400783 },
    { lat: 48.265547, lng: 18.400752 },
    { lat: 48.265655, lng: 18.400577 },
    { lat: 48.265954, lng: 18.40013 },
    { lat: 48.266351, lng: 18.399711 },
    { lat: 48.266731, lng: 18.39931 },
    { lat: 48.266751, lng: 18.399099 },
    { lat: 48.26688, lng: 18.397968 },
    { lat: 48.266887, lng: 18.397909 },
    { lat: 48.266929, lng: 18.39754 },
    { lat: 48.267136, lng: 18.395647 },
    { lat: 48.267256, lng: 18.394459 },
    { lat: 48.267389, lng: 18.393691 },
    { lat: 48.267574, lng: 18.392727 },
    { lat: 48.267522, lng: 18.391886 },
    { lat: 48.267707, lng: 18.390967 },
    { lat: 48.267853, lng: 18.39016 },
    { lat: 48.267948, lng: 18.389812 },
    { lat: 48.268094, lng: 18.389374 },
    { lat: 48.268287, lng: 18.388825 },
    { lat: 48.268419, lng: 18.388391 },
    { lat: 48.268485, lng: 18.387908 },
    { lat: 48.268552, lng: 18.387307 },
    { lat: 48.268686, lng: 18.386405 },
    { lat: 48.268779, lng: 18.385391 },
    { lat: 48.268792, lng: 18.385255 },
    { lat: 48.269038, lng: 18.385034 },
    { lat: 48.269191, lng: 18.384896 },
    { lat: 48.269328, lng: 18.384529 },
    { lat: 48.269357, lng: 18.38445 },
    { lat: 48.26951, lng: 18.384038 },
    { lat: 48.269608, lng: 18.383816 },
    { lat: 48.269935, lng: 18.383079 },
    { lat: 48.269995, lng: 18.382738 },
    { lat: 48.270224, lng: 18.381553 },
    { lat: 48.270232, lng: 18.381501 },
    { lat: 48.270282, lng: 18.381224 },
    { lat: 48.270332, lng: 18.380948 },
    { lat: 48.27038, lng: 18.380671 },
    { lat: 48.270429, lng: 18.380395 },
    { lat: 48.270479, lng: 18.380119 },
    { lat: 48.270528, lng: 18.379844 },
    { lat: 48.270574, lng: 18.379585 },
    { lat: 48.270575, lng: 18.379568 },
    { lat: 48.270604, lng: 18.379282 },
    { lat: 48.270632, lng: 18.378995 },
    { lat: 48.270661, lng: 18.378707 },
    { lat: 48.270691, lng: 18.378418 },
    { lat: 48.270749, lng: 18.377834 },
    { lat: 48.270778, lng: 18.377543 },
    { lat: 48.270807, lng: 18.377251 },
    { lat: 48.270836, lng: 18.376958 },
    { lat: 48.270866, lng: 18.376664 },
    { lat: 48.270896, lng: 18.376369 },
    { lat: 48.270925, lng: 18.376074 },
    { lat: 48.270955, lng: 18.375777 },
    { lat: 48.270984, lng: 18.375479 },
    { lat: 48.271015, lng: 18.37518 },
    { lat: 48.271044, lng: 18.374881 },
    { lat: 48.271074, lng: 18.37458 },
    { lat: 48.271105, lng: 18.374279 },
    { lat: 48.271135, lng: 18.373976 },
    { lat: 48.271165, lng: 18.373673 },
    { lat: 48.271194, lng: 18.373368 },
    { lat: 48.271224, lng: 18.373062 },
    { lat: 48.271253, lng: 18.372754 },
    { lat: 48.271261, lng: 18.372676 },
    { lat: 48.271323, lng: 18.372468 },
    { lat: 48.271405, lng: 18.372192 },
    { lat: 48.271487, lng: 18.371918 },
    { lat: 48.271501, lng: 18.371869 },
    { lat: 48.271569, lng: 18.371648 },
    { lat: 48.271631, lng: 18.371437 },
    { lat: 48.271768, lng: 18.371005 },
    { lat: 48.272381, lng: 18.369133 },
    { lat: 48.273032, lng: 18.367182 },
    { lat: 48.273045, lng: 18.367143 },
    { lat: 48.272967, lng: 18.367058 },
    { lat: 48.273037, lng: 18.366831 },
    { lat: 48.273074, lng: 18.366715 },
    { lat: 48.273599, lng: 18.365012 },
    { lat: 48.273775, lng: 18.36439 },
    { lat: 48.273935, lng: 18.363824 },
    { lat: 48.273948, lng: 18.36378 },
    { lat: 48.274095, lng: 18.363256 },
    { lat: 48.274109, lng: 18.363206 },
    { lat: 48.274125, lng: 18.363152 },
    { lat: 48.274152, lng: 18.363035 },
    { lat: 48.274175, lng: 18.362933 },
    { lat: 48.274189, lng: 18.362874 },
    { lat: 48.274387, lng: 18.362062 },
    { lat: 48.274407, lng: 18.36198 },
    { lat: 48.274476, lng: 18.36169 },
    { lat: 48.274521, lng: 18.361624 },
    { lat: 48.274609, lng: 18.361531 },
    { lat: 48.274619, lng: 18.361521 },
    { lat: 48.274624, lng: 18.361478 },
    { lat: 48.274664, lng: 18.361214 },
    { lat: 48.27472, lng: 18.360924 },
    { lat: 48.274796, lng: 18.360589 },
    { lat: 48.274796, lng: 18.360585 },
    { lat: 48.274923, lng: 18.360025 },
    { lat: 48.275033, lng: 18.35961 },
    { lat: 48.275063, lng: 18.3595 },
    { lat: 48.275213, lng: 18.358938 },
    { lat: 48.275509, lng: 18.357949 },
    { lat: 48.275583, lng: 18.357628 },
    { lat: 48.275583, lng: 18.357599 },
    { lat: 48.275582, lng: 18.357547 },
    { lat: 48.275628, lng: 18.357421 },
    { lat: 48.275684, lng: 18.357264 },
    { lat: 48.275726, lng: 18.357149 },
    { lat: 48.275783, lng: 18.356994 },
    { lat: 48.275833, lng: 18.356858 },
    { lat: 48.275872, lng: 18.356751 },
    { lat: 48.275893, lng: 18.356696 },
    { lat: 48.275989, lng: 18.356432 },
    { lat: 48.276098, lng: 18.356121 },
    { lat: 48.276146, lng: 18.356017 },
    { lat: 48.276147, lng: 18.356014 },
    { lat: 48.276231, lng: 18.355813 },
    { lat: 48.276273, lng: 18.355709 },
    { lat: 48.276312, lng: 18.35561 },
    { lat: 48.27639, lng: 18.355403 },
    { lat: 48.27647, lng: 18.355196 },
    { lat: 48.27657, lng: 18.354953 },
    { lat: 48.276669, lng: 18.35471 },
    { lat: 48.2768, lng: 18.354388 },
    { lat: 48.276891, lng: 18.354197 },
    { lat: 48.276936, lng: 18.354102 },
    { lat: 48.276981, lng: 18.354007 },
    { lat: 48.277193, lng: 18.353514 },
    { lat: 48.277231, lng: 18.353429 },
    { lat: 48.27726, lng: 18.353358 },
    { lat: 48.27729, lng: 18.353286 },
    { lat: 48.27732, lng: 18.353215 },
    { lat: 48.27735, lng: 18.353143 },
    { lat: 48.277477, lng: 18.352854 },
    { lat: 48.277502, lng: 18.352798 },
    { lat: 48.277524, lng: 18.352751 },
    { lat: 48.277544, lng: 18.352708 },
    { lat: 48.277567, lng: 18.352658 },
    { lat: 48.277586, lng: 18.352616 },
    { lat: 48.277627, lng: 18.352527 },
    { lat: 48.277667, lng: 18.352442 },
    { lat: 48.277692, lng: 18.352379 },
    { lat: 48.277755, lng: 18.352242 },
    { lat: 48.27791, lng: 18.351852 },
    { lat: 48.277922, lng: 18.351847 },
    { lat: 48.277987, lng: 18.351808 },
    { lat: 48.2770963, lng: 18.3508774 },
    { lat: 48.2764844, lng: 18.3500138 },
    { lat: 48.2753644, lng: 18.3486284 },
    { lat: 48.2750161, lng: 18.3480359 },
    { lat: 48.2748794, lng: 18.3477346 },
    { lat: 48.274416, lng: 18.3489765 },
    { lat: 48.2742696, lng: 18.3491598 },
    { lat: 48.2739361, lng: 18.3493648 },
    { lat: 48.2733465, lng: 18.3489167 },
    { lat: 48.2734242, lng: 18.3484177 },
    { lat: 48.2728433, lng: 18.3480592 },
    { lat: 48.2723016, lng: 18.347842 },
    { lat: 48.2710856, lng: 18.3478834 },
    { lat: 48.2703268, lng: 18.3480515 },
    { lat: 48.2693708, lng: 18.3481458 },
    { lat: 48.2679422, lng: 18.3478853 },
    { lat: 48.2657837, lng: 18.3480615 },
    { lat: 48.2654571, lng: 18.3481509 },
    { lat: 48.2642052, lng: 18.3480594 },
    { lat: 48.2639595, lng: 18.3479048 },
    { lat: 48.2630968, lng: 18.3459633 },
    { lat: 48.2625986, lng: 18.3450954 },
    { lat: 48.262412, lng: 18.3449113 },
    { lat: 48.260173, lng: 18.3435485 },
    { lat: 48.259634, lng: 18.343279 },
    { lat: 48.2591532, lng: 18.3430969 },
    { lat: 48.2587093, lng: 18.3430083 },
    { lat: 48.2576413, lng: 18.3430724 },
    { lat: 48.257596, lng: 18.3426327 },
    { lat: 48.2565495, lng: 18.3427447 },
    { lat: 48.2553961, lng: 18.3429978 },
    { lat: 48.2543545, lng: 18.3434479 },
    { lat: 48.2531934, lng: 18.3442029 },
    { lat: 48.2532039, lng: 18.3443506 },
    { lat: 48.2531173, lng: 18.3445315 },
    { lat: 48.2531558, lng: 18.3447939 },
    { lat: 48.2530415, lng: 18.3448881 },
    { lat: 48.2531192, lng: 18.3451434 },
    { lat: 48.2531924, lng: 18.3452013 },
    { lat: 48.2533107, lng: 18.3456738 },
    { lat: 48.2532893, lng: 18.345754 },
    { lat: 48.253261, lng: 18.3457934 },
    { lat: 48.2532486, lng: 18.3458127 },
    { lat: 48.2531998, lng: 18.3458854 },
    { lat: 48.2534717, lng: 18.3462063 },
    { lat: 48.2535005, lng: 18.3463559 },
    { lat: 48.2525106, lng: 18.34734 },
    { lat: 48.2522603, lng: 18.3477818 },
    { lat: 48.2516243, lng: 18.3496575 },
    { lat: 48.25089, lng: 18.3514933 },
    { lat: 48.249768, lng: 18.3535921 },
    { lat: 48.2494003, lng: 18.3543291 },
    { lat: 48.2490123, lng: 18.3551292 },
    { lat: 48.2486188, lng: 18.3561658 },
    { lat: 48.2481354, lng: 18.3575323 },
    { lat: 48.2480635, lng: 18.3579372 },
    { lat: 48.2480679, lng: 18.3595292 },
    { lat: 48.2481739, lng: 18.3615805 },
    { lat: 48.2488253, lng: 18.363837 },
    { lat: 48.2490305, lng: 18.3647342 },
    { lat: 48.249076, lng: 18.3653164 },
    { lat: 48.2489217, lng: 18.3662372 },
    { lat: 48.2486281, lng: 18.3670107 },
    { lat: 48.2482197, lng: 18.3684466 },
    { lat: 48.2477496, lng: 18.3697532 },
    { lat: 48.2475832, lng: 18.3703824 },
    { lat: 48.247456, lng: 18.3715447 },
    { lat: 48.2475627, lng: 18.3722731 },
    { lat: 48.2475931, lng: 18.3727554 },
    { lat: 48.2476055, lng: 18.3729516 },
    { lat: 48.2475904, lng: 18.3738643 },
    { lat: 48.2476028, lng: 18.3756977 },
    { lat: 48.2476272, lng: 18.3756904 },
    { lat: 48.2485993, lng: 18.375443 },
    { lat: 48.2496185, lng: 18.3756098 },
    { lat: 48.250504, lng: 18.3777831 },
    { lat: 48.2509165, lng: 18.3784778 },
    { lat: 48.2520589, lng: 18.3794861 },
    { lat: 48.2527741, lng: 18.3798434 },
    { lat: 48.2538079, lng: 18.3799721 },
    { lat: 48.2546048, lng: 18.3802358 },
    { lat: 48.2562384, lng: 18.3809959 },
    { lat: 48.2571384, lng: 18.3816075 },
    { lat: 48.258426, lng: 18.3821328 },
    { lat: 48.2588232, lng: 18.3821742 },
    { lat: 48.2594292, lng: 18.3818982 },
    { lat: 48.2601305, lng: 18.3822906 },
    { lat: 48.2616855, lng: 18.383299 },
    { lat: 48.2616843, lng: 18.3835531 },
    { lat: 48.2617047, lng: 18.3840561 },
    { lat: 48.2615998, lng: 18.3845775 },
    { lat: 48.260799, lng: 18.3862398 },
    { lat: 48.2601031, lng: 18.3884274 },
    { lat: 48.2593418, lng: 18.3904521 },
    { lat: 48.2584976, lng: 18.3918457 },
    { lat: 48.2583958, lng: 18.3924438 },
    { lat: 48.2585543, lng: 18.3936753 },
    { lat: 48.2582415, lng: 18.3957152 },
    { lat: 48.2582436, lng: 18.3978717 },
    { lat: 48.2581778, lng: 18.3992905 },
    { lat: 48.2589854, lng: 18.401413 },
    { lat: 48.259558, lng: 18.4016219 },
    { lat: 48.2607687, lng: 18.4016869 },
    { lat: 48.263423, lng: 18.4014823 },
    { lat: 48.263953, lng: 18.401574 },
  ],
  MalýCetín: [
    { lat: 48.2400525, lng: 18.1576006 },
    { lat: 48.2399233, lng: 18.1577085 },
    { lat: 48.2376301, lng: 18.1592488 },
    { lat: 48.2368345, lng: 18.1601023 },
    { lat: 48.2363113, lng: 18.1608805 },
    { lat: 48.2347709, lng: 18.1622339 },
    { lat: 48.2331301, lng: 18.1638949 },
    { lat: 48.2324961, lng: 18.1644429 },
    { lat: 48.2311512, lng: 18.1652583 },
    { lat: 48.2306736, lng: 18.1712871 },
    { lat: 48.2301709, lng: 18.1719725 },
    { lat: 48.2306661, lng: 18.1739444 },
    { lat: 48.2315059, lng: 18.1759827 },
    { lat: 48.2332507, lng: 18.1809824 },
    { lat: 48.2333205, lng: 18.1815227 },
    { lat: 48.233403, lng: 18.1817795 },
    { lat: 48.2333765, lng: 18.1826402 },
    { lat: 48.2337203, lng: 18.1843014 },
    { lat: 48.2338696, lng: 18.1847736 },
    { lat: 48.2358099, lng: 18.1873237 },
    { lat: 48.2374806, lng: 18.1895246 },
    { lat: 48.2396847, lng: 18.1927136 },
    { lat: 48.2418228, lng: 18.19426 },
    { lat: 48.241957, lng: 18.1945057 },
    { lat: 48.2426487, lng: 18.1957724 },
    { lat: 48.2448958, lng: 18.1991224 },
    { lat: 48.2458517, lng: 18.1983675 },
    { lat: 48.2461731, lng: 18.198206 },
    { lat: 48.2463853, lng: 18.1981699 },
    { lat: 48.2474879, lng: 18.1977226 },
    { lat: 48.2482233, lng: 18.1977755 },
    { lat: 48.2490237, lng: 18.1977061 },
    { lat: 48.2495479, lng: 18.1974442 },
    { lat: 48.2501326, lng: 18.1969573 },
    { lat: 48.25088, lng: 18.1963827 },
    { lat: 48.2509722, lng: 18.1963267 },
    { lat: 48.251113, lng: 18.1962412 },
    { lat: 48.2526556, lng: 18.1953042 },
    { lat: 48.2540881, lng: 18.1940292 },
    { lat: 48.2552362, lng: 18.1932458 },
    { lat: 48.2555306, lng: 18.1931059 },
    { lat: 48.2564895, lng: 18.19209 },
    { lat: 48.2572564, lng: 18.1915372 },
    { lat: 48.2577171, lng: 18.1911354 },
    { lat: 48.2578825, lng: 18.1908932 },
    { lat: 48.2586017, lng: 18.190394 },
    { lat: 48.2588379, lng: 18.1902673 },
    { lat: 48.2567103, lng: 18.1861614 },
    { lat: 48.2559796, lng: 18.1846009 },
    { lat: 48.2550345, lng: 18.182749 },
    { lat: 48.2537023, lng: 18.180583 },
    { lat: 48.2525857, lng: 18.1790443 },
    { lat: 48.2512199, lng: 18.1768896 },
    { lat: 48.2489457, lng: 18.1729708 },
    { lat: 48.2480146, lng: 18.1715162 },
    { lat: 48.2470338, lng: 18.1703139 },
    { lat: 48.2467708, lng: 18.1701174 },
    { lat: 48.2443836, lng: 18.1655654 },
    { lat: 48.2417087, lng: 18.1611109 },
    { lat: 48.2408729, lng: 18.1595132 },
    { lat: 48.2401097, lng: 18.1577503 },
    { lat: 48.2400525, lng: 18.1576006 },
  ],
  Telince: [
    { lat: 48.222948, lng: 18.358724 },
    { lat: 48.222947, lng: 18.358726 },
    { lat: 48.222942, lng: 18.358738 },
    { lat: 48.222923, lng: 18.358781 },
    { lat: 48.222819, lng: 18.35893 },
    { lat: 48.222688, lng: 18.35904 },
    { lat: 48.222612, lng: 18.35913 },
    { lat: 48.222548, lng: 18.35922 },
    { lat: 48.222497, lng: 18.359326 },
    { lat: 48.222391, lng: 18.359574 },
    { lat: 48.222249, lng: 18.359964 },
    { lat: 48.222175, lng: 18.360195 },
    { lat: 48.222147, lng: 18.360301 },
    { lat: 48.222015, lng: 18.361016 },
    { lat: 48.22197, lng: 18.361244 },
    { lat: 48.221889, lng: 18.361566 },
    { lat: 48.221697, lng: 18.36215 },
    { lat: 48.221637, lng: 18.362332 },
    { lat: 48.221578, lng: 18.362508 },
    { lat: 48.221497, lng: 18.362706 },
    { lat: 48.221278, lng: 18.363328 },
    { lat: 48.22094, lng: 18.364183 },
    { lat: 48.22082, lng: 18.364434 },
    { lat: 48.220525, lng: 18.364994 },
    { lat: 48.220522, lng: 18.364999 },
    { lat: 48.220318, lng: 18.365316 },
    { lat: 48.220056, lng: 18.365741 },
    { lat: 48.219736, lng: 18.366234 },
    { lat: 48.219031, lng: 18.36756 },
    { lat: 48.218549, lng: 18.368393 },
    { lat: 48.21829, lng: 18.369336 },
    { lat: 48.218223, lng: 18.369643 },
    { lat: 48.218033, lng: 18.370545 },
    { lat: 48.217753, lng: 18.371721 },
    { lat: 48.217737, lng: 18.371785 },
    { lat: 48.21761, lng: 18.372346 },
    { lat: 48.217653, lng: 18.373246 },
    { lat: 48.217685, lng: 18.374069 },
    { lat: 48.2172, lng: 18.375301 },
    { lat: 48.216934, lng: 18.37588 },
    { lat: 48.21681, lng: 18.376144 },
    { lat: 48.21652, lng: 18.376768 },
    { lat: 48.216368, lng: 18.377203 },
    { lat: 48.215845, lng: 18.378732 },
    { lat: 48.215431, lng: 18.38076 },
    { lat: 48.215384, lng: 18.381424 },
    { lat: 48.215324, lng: 18.382322 },
    { lat: 48.215311, lng: 18.382513 },
    { lat: 48.215303, lng: 18.382636 },
    { lat: 48.215271, lng: 18.383164 },
    { lat: 48.215223, lng: 18.383545 },
    { lat: 48.215206, lng: 18.383611 },
    { lat: 48.215122, lng: 18.383895 },
    { lat: 48.215061, lng: 18.384099 },
    { lat: 48.215036, lng: 18.384194 },
    { lat: 48.214728, lng: 18.38482 },
    { lat: 48.214402, lng: 18.385207 },
    { lat: 48.214218, lng: 18.38537 },
    { lat: 48.214041, lng: 18.385525 },
    { lat: 48.213684, lng: 18.38584 },
    { lat: 48.213346, lng: 18.386237 },
    { lat: 48.213044, lng: 18.38663 },
    { lat: 48.212531, lng: 18.387297 },
    { lat: 48.212325, lng: 18.387577 },
    { lat: 48.212019, lng: 18.387967 },
    { lat: 48.211646, lng: 18.38847 },
    { lat: 48.211224, lng: 18.38904 },
    { lat: 48.211038, lng: 18.389291 },
    { lat: 48.211038, lng: 18.389347 },
    { lat: 48.211037, lng: 18.389367 },
    { lat: 48.211043, lng: 18.389403 },
    { lat: 48.211329, lng: 18.389407 },
    { lat: 48.21157, lng: 18.389406 },
    { lat: 48.211694, lng: 18.389409 },
    { lat: 48.211789, lng: 18.389393 },
    { lat: 48.211983, lng: 18.389342 },
    { lat: 48.212111, lng: 18.389297 },
    { lat: 48.212278, lng: 18.38925 },
    { lat: 48.212451, lng: 18.389189 },
    { lat: 48.212631, lng: 18.389127 },
    { lat: 48.212744, lng: 18.389075 },
    { lat: 48.212995, lng: 18.388948 },
    { lat: 48.213138, lng: 18.388866 },
    { lat: 48.213357, lng: 18.388748 },
    { lat: 48.21357, lng: 18.388643 },
    { lat: 48.21365, lng: 18.388579 },
    { lat: 48.213878, lng: 18.388401 },
    { lat: 48.214036, lng: 18.388301 },
    { lat: 48.214199, lng: 18.388198 },
    { lat: 48.21439, lng: 18.388082 },
    { lat: 48.21449, lng: 18.388043 },
    { lat: 48.214597, lng: 18.388002 },
    { lat: 48.214705, lng: 18.387972 },
    { lat: 48.214807, lng: 18.387959 },
    { lat: 48.214925, lng: 18.387979 },
    { lat: 48.215151, lng: 18.388015 },
    { lat: 48.215282, lng: 18.38803 },
    { lat: 48.215284, lng: 18.38803 },
    { lat: 48.215286, lng: 18.388028 },
    { lat: 48.2153154, lng: 18.3880067 },
    { lat: 48.2155424, lng: 18.3878104 },
    { lat: 48.2156329, lng: 18.3877332 },
    { lat: 48.2157404, lng: 18.3875983 },
    { lat: 48.2157519, lng: 18.3875837 },
    { lat: 48.2159089, lng: 18.3873881 },
    { lat: 48.2159226, lng: 18.3873708 },
    { lat: 48.2159754, lng: 18.3873054 },
    { lat: 48.2160428, lng: 18.3872212 },
    { lat: 48.2160734, lng: 18.3871826 },
    { lat: 48.2161639, lng: 18.3870686 },
    { lat: 48.2161925, lng: 18.3870342 },
    { lat: 48.2162442, lng: 18.3869697 },
    { lat: 48.2163759, lng: 18.3868062 },
    { lat: 48.2164382, lng: 18.3867297 },
    { lat: 48.2165658, lng: 18.3865728 },
    { lat: 48.2167389, lng: 18.3863606 },
    { lat: 48.2167461, lng: 18.3863498 },
    { lat: 48.2169096, lng: 18.3860879 },
    { lat: 48.2170764, lng: 18.3858263 },
    { lat: 48.2171604, lng: 18.3856943 },
    { lat: 48.2172733, lng: 18.3855166 },
    { lat: 48.2173675, lng: 18.3853667 },
    { lat: 48.2174174, lng: 18.3852886 },
    { lat: 48.2174536, lng: 18.3852306 },
    { lat: 48.2175385, lng: 18.3850961 },
    { lat: 48.217555, lng: 18.3850699 },
    { lat: 48.2175624, lng: 18.3850594 },
    { lat: 48.2176546, lng: 18.3849158 },
    { lat: 48.2177036, lng: 18.3848417 },
    { lat: 48.2177543, lng: 18.3847639 },
    { lat: 48.2178549, lng: 18.3846075 },
    { lat: 48.2179556, lng: 18.3844526 },
    { lat: 48.2179784, lng: 18.3844377 },
    { lat: 48.2181689, lng: 18.3843117 },
    { lat: 48.2182505, lng: 18.3842579 },
    { lat: 48.2185072, lng: 18.3840889 },
    { lat: 48.2185375, lng: 18.3840688 },
    { lat: 48.2186766, lng: 18.3839748 },
    { lat: 48.2187973, lng: 18.383893 },
    { lat: 48.2188908, lng: 18.3838493 },
    { lat: 48.2190655, lng: 18.3837685 },
    { lat: 48.2191381, lng: 18.3837353 },
    { lat: 48.2192781, lng: 18.3836783 },
    { lat: 48.2192881, lng: 18.3836746 },
    { lat: 48.2193179, lng: 18.3836623 },
    { lat: 48.2195286, lng: 18.3835749 },
    { lat: 48.2196014, lng: 18.3835451 },
    { lat: 48.2196536, lng: 18.3835318 },
    { lat: 48.2196725, lng: 18.3835273 },
    { lat: 48.2199229, lng: 18.3834659 },
    { lat: 48.2201122, lng: 18.3834222 },
    { lat: 48.220276, lng: 18.3833841 },
    { lat: 48.220307, lng: 18.383377 },
    { lat: 48.220364, lng: 18.3833638 },
    { lat: 48.2206567, lng: 18.3832932 },
    { lat: 48.2208558, lng: 18.3832429 },
    { lat: 48.2208569, lng: 18.3832424 },
    { lat: 48.2208766, lng: 18.383235 },
    { lat: 48.221065, lng: 18.3832724 },
    { lat: 48.2212568, lng: 18.3832952 },
    { lat: 48.2214042, lng: 18.3833059 },
    { lat: 48.2215336, lng: 18.3832973 },
    { lat: 48.2215559, lng: 18.3832945 },
    { lat: 48.2216265, lng: 18.3832693 },
    { lat: 48.2217557, lng: 18.3832364 },
    { lat: 48.2218291, lng: 18.3831981 },
    { lat: 48.2219363, lng: 18.3831406 },
    { lat: 48.2220097, lng: 18.3831002 },
    { lat: 48.2221391, lng: 18.3830357 },
    { lat: 48.2222547, lng: 18.3829854 },
    { lat: 48.2223759, lng: 18.3829322 },
    { lat: 48.2226232, lng: 18.3828741 },
    { lat: 48.222765, lng: 18.3828696 },
    { lat: 48.2228795, lng: 18.3828576 },
    { lat: 48.2230622, lng: 18.3828359 },
    { lat: 48.2232268, lng: 18.3828367 },
    { lat: 48.2233498, lng: 18.3828377 },
    { lat: 48.2234479, lng: 18.3828345 },
    { lat: 48.2235761, lng: 18.3828105 },
    { lat: 48.2237287, lng: 18.3827746 },
    { lat: 48.2237332, lng: 18.3827737 },
    { lat: 48.2239071, lng: 18.382734 },
    { lat: 48.2240613, lng: 18.382711 },
    { lat: 48.2242336, lng: 18.3826853 },
    { lat: 48.2242549, lng: 18.3826805 },
    { lat: 48.2244622, lng: 18.3826546 },
    { lat: 48.2245809, lng: 18.3826379 },
    { lat: 48.2246589, lng: 18.3826213 },
    { lat: 48.2248588, lng: 18.3825659 },
    { lat: 48.2250458, lng: 18.3825228 },
    { lat: 48.2251411, lng: 18.3825111 },
    { lat: 48.2253003, lng: 18.3824924 },
    { lat: 48.2254491, lng: 18.3824888 },
    { lat: 48.225459, lng: 18.3824883 },
    { lat: 48.2254833, lng: 18.3824962 },
    { lat: 48.2255936, lng: 18.3825321 },
    { lat: 48.2257291, lng: 18.3824944 },
    { lat: 48.2257471, lng: 18.382495 },
    { lat: 48.2258465, lng: 18.382498 },
    { lat: 48.2259201, lng: 18.3824986 },
    { lat: 48.2261165, lng: 18.3824808 },
    { lat: 48.2263555, lng: 18.3825178 },
    { lat: 48.2265421, lng: 18.3825866 },
    { lat: 48.2266744, lng: 18.3826512 },
    { lat: 48.2267125, lng: 18.3826687 },
    { lat: 48.2269643, lng: 18.3826684 },
    { lat: 48.2273442, lng: 18.3826646 },
    { lat: 48.2277036, lng: 18.3830165 },
    { lat: 48.2278904, lng: 18.3829879 },
    { lat: 48.2284321, lng: 18.3828883 },
    { lat: 48.2287457, lng: 18.3828281 },
    { lat: 48.229079, lng: 18.3827608 },
    { lat: 48.2294143, lng: 18.3829208 },
    { lat: 48.2298096, lng: 18.3829876 },
    { lat: 48.2298289, lng: 18.3829912 },
    { lat: 48.2298676, lng: 18.3829972 },
    { lat: 48.2300821, lng: 18.3829828 },
    { lat: 48.2300878, lng: 18.3829825 },
    { lat: 48.2300952, lng: 18.382955 },
    { lat: 48.2300991, lng: 18.3829397 },
    { lat: 48.2301, lng: 18.3829381 },
    { lat: 48.2301156, lng: 18.3829132 },
    { lat: 48.2301198, lng: 18.382908 },
    { lat: 48.2301197, lng: 18.382907 },
    { lat: 48.2306223, lng: 18.3830359 },
    { lat: 48.2307217, lng: 18.3830591 },
    { lat: 48.2308042, lng: 18.3830791 },
    { lat: 48.2308178, lng: 18.3830823 },
    { lat: 48.2311015, lng: 18.3831494 },
    { lat: 48.2312328, lng: 18.3831802 },
    { lat: 48.2312387, lng: 18.3832046 },
    { lat: 48.2313586, lng: 18.3836873 },
    { lat: 48.2313901, lng: 18.3838058 },
    { lat: 48.2314198, lng: 18.3838533 },
    { lat: 48.2315684, lng: 18.3840865 },
    { lat: 48.2315843, lng: 18.3840877 },
    { lat: 48.2316945, lng: 18.3840429 },
    { lat: 48.2318428, lng: 18.3840316 },
    { lat: 48.231853, lng: 18.3840307 },
    { lat: 48.2318562, lng: 18.3840303 },
    { lat: 48.2318866, lng: 18.3840282 },
    { lat: 48.2319692, lng: 18.3840132 },
    { lat: 48.2319861, lng: 18.3840096 },
    { lat: 48.2319961, lng: 18.3840076 },
    { lat: 48.2319982, lng: 18.384007 },
    { lat: 48.2322068, lng: 18.3839626 },
    { lat: 48.2324768, lng: 18.383892 },
    { lat: 48.2325457, lng: 18.383873 },
    { lat: 48.2328864, lng: 18.3837503 },
    { lat: 48.2329781, lng: 18.3837175 },
    { lat: 48.2331955, lng: 18.3836695 },
    { lat: 48.2334734, lng: 18.3835795 },
    { lat: 48.2337755, lng: 18.3835105 },
    { lat: 48.2338021, lng: 18.383505 },
    { lat: 48.2338088, lng: 18.3835031 },
    { lat: 48.2338656, lng: 18.3834913 },
    { lat: 48.2342839, lng: 18.3834038 },
    { lat: 48.2345701, lng: 18.38334 },
    { lat: 48.2347786, lng: 18.3833136 },
    { lat: 48.2349647, lng: 18.3832936 },
    { lat: 48.235246, lng: 18.3832595 },
    { lat: 48.2353973, lng: 18.3832435 },
    { lat: 48.235595, lng: 18.3832068 },
    { lat: 48.2356811, lng: 18.3831935 },
    { lat: 48.2357919, lng: 18.3831756 },
    { lat: 48.2359328, lng: 18.383156 },
    { lat: 48.2361635, lng: 18.3831257 },
    { lat: 48.2364904, lng: 18.3830823 },
    { lat: 48.2368444, lng: 18.3830378 },
    { lat: 48.2369901, lng: 18.3830173 },
    { lat: 48.2372598, lng: 18.3829793 },
    { lat: 48.2375444, lng: 18.3829454 },
    { lat: 48.2377693, lng: 18.3828706 },
    { lat: 48.2380519, lng: 18.3827797 },
    { lat: 48.2383673, lng: 18.3826552 },
    { lat: 48.2387799, lng: 18.3824887 },
    { lat: 48.2390766, lng: 18.3823075 },
    { lat: 48.2393038, lng: 18.3821743 },
    { lat: 48.2395572, lng: 18.3820262 },
    { lat: 48.2397664, lng: 18.3818953 },
    { lat: 48.2400134, lng: 18.3817533 },
    { lat: 48.2402349, lng: 18.381623 },
    { lat: 48.240526, lng: 18.3814811 },
    { lat: 48.2408167, lng: 18.3813325 },
    { lat: 48.2409215, lng: 18.3812916 },
    { lat: 48.2412414, lng: 18.381163 },
    { lat: 48.2414667, lng: 18.3810743 },
    { lat: 48.2416231, lng: 18.3810103 },
    { lat: 48.2417667, lng: 18.3809539 },
    { lat: 48.2419441, lng: 18.3808798 },
    { lat: 48.2420941, lng: 18.3808187 },
    { lat: 48.2422749, lng: 18.380747 },
    { lat: 48.2423929, lng: 18.3806973 },
    { lat: 48.2424097, lng: 18.3806931 },
    { lat: 48.242413, lng: 18.3806923 },
    { lat: 48.2424186, lng: 18.3806906 },
    { lat: 48.242423, lng: 18.3806896 },
    { lat: 48.2424398, lng: 18.3806854 },
    { lat: 48.2424407, lng: 18.3806853 },
    { lat: 48.242624, lng: 18.3805934 },
    { lat: 48.2429113, lng: 18.3804449 },
    { lat: 48.2431211, lng: 18.3803458 },
    { lat: 48.2433462, lng: 18.3802373 },
    { lat: 48.2435048, lng: 18.3801652 },
    { lat: 48.2437009, lng: 18.3800243 },
    { lat: 48.2439438, lng: 18.3798509 },
    { lat: 48.2441373, lng: 18.3797011 },
    { lat: 48.2443975, lng: 18.3794905 },
    { lat: 48.2446594, lng: 18.3792772 },
    { lat: 48.2449173, lng: 18.3790499 },
    { lat: 48.2453044, lng: 18.3787128 },
    { lat: 48.2456177, lng: 18.3784661 },
    { lat: 48.2458771, lng: 18.3782619 },
    { lat: 48.2459143, lng: 18.3782215 },
    { lat: 48.2460952, lng: 18.3780088 },
    { lat: 48.2462033, lng: 18.3778837 },
    { lat: 48.2463078, lng: 18.3777791 },
    { lat: 48.2464756, lng: 18.3776156 },
    { lat: 48.2466115, lng: 18.377484 },
    { lat: 48.246618, lng: 18.3774783 },
    { lat: 48.2466234, lng: 18.3774737 },
    { lat: 48.2467286, lng: 18.3772972 },
    { lat: 48.2467739, lng: 18.3772199 },
    { lat: 48.2468437, lng: 18.3771007 },
    { lat: 48.2468716, lng: 18.3770536 },
    { lat: 48.2468838, lng: 18.3770322 },
    { lat: 48.246922, lng: 18.3769665 },
    { lat: 48.2470269, lng: 18.3767865 },
    { lat: 48.2470537, lng: 18.3767417 },
    { lat: 48.2470772, lng: 18.3767009 },
    { lat: 48.2472408, lng: 18.37642 },
    { lat: 48.2472946, lng: 18.3763151 },
    { lat: 48.2474035, lng: 18.3761031 },
    { lat: 48.2475061, lng: 18.3759032 },
    { lat: 48.2475454, lng: 18.3758189 },
    { lat: 48.2475778, lng: 18.3757522 },
    { lat: 48.2476028, lng: 18.3756977 },
    { lat: 48.2475904, lng: 18.3738643 },
    { lat: 48.2476055, lng: 18.3729516 },
    { lat: 48.2475931, lng: 18.3727554 },
    { lat: 48.2475627, lng: 18.3722731 },
    { lat: 48.247456, lng: 18.3715447 },
    { lat: 48.2475832, lng: 18.3703824 },
    { lat: 48.2477496, lng: 18.3697532 },
    { lat: 48.2482197, lng: 18.3684466 },
    { lat: 48.2486281, lng: 18.3670107 },
    { lat: 48.2489217, lng: 18.3662372 },
    { lat: 48.249076, lng: 18.3653164 },
    { lat: 48.2490305, lng: 18.3647342 },
    { lat: 48.2488253, lng: 18.363837 },
    { lat: 48.2481739, lng: 18.3615805 },
    { lat: 48.2480679, lng: 18.3595292 },
    { lat: 48.2480635, lng: 18.3579372 },
    { lat: 48.2481354, lng: 18.3575323 },
    { lat: 48.2486188, lng: 18.3561658 },
    { lat: 48.2490123, lng: 18.3551292 },
    { lat: 48.2494003, lng: 18.3543291 },
    { lat: 48.249768, lng: 18.3535921 },
    { lat: 48.2494209, lng: 18.353599 },
    { lat: 48.2485746, lng: 18.3537976 },
    { lat: 48.2473622, lng: 18.3535467 },
    { lat: 48.2441207, lng: 18.353228 },
    { lat: 48.2412305, lng: 18.3532126 },
    { lat: 48.238884, lng: 18.3531002 },
    { lat: 48.237582, lng: 18.3529213 },
    { lat: 48.237123, lng: 18.3528258 },
    { lat: 48.235213, lng: 18.3524285 },
    { lat: 48.2333588, lng: 18.3521634 },
    { lat: 48.2320643, lng: 18.3520748 },
    { lat: 48.2308878, lng: 18.3521999 },
    { lat: 48.2296009, lng: 18.3525078 },
    { lat: 48.2296657, lng: 18.3526751 },
    { lat: 48.2296081, lng: 18.3527351 },
    { lat: 48.2296023, lng: 18.3528491 },
    { lat: 48.229705, lng: 18.3530303 },
    { lat: 48.2297236, lng: 18.3532165 },
    { lat: 48.2297856, lng: 18.3531761 },
    { lat: 48.2298606, lng: 18.353306 },
    { lat: 48.2298676, lng: 18.3536455 },
    { lat: 48.2296628, lng: 18.3540159 },
    { lat: 48.2296073, lng: 18.3542087 },
    { lat: 48.2292364, lng: 18.3545725 },
    { lat: 48.2292358, lng: 18.354724 },
    { lat: 48.2291774, lng: 18.3548082 },
    { lat: 48.2290839, lng: 18.3547299 },
    { lat: 48.2290355, lng: 18.3548524 },
    { lat: 48.2289689, lng: 18.3548701 },
    { lat: 48.2289775, lng: 18.3550434 },
    { lat: 48.2290431, lng: 18.3550825 },
    { lat: 48.2290363, lng: 18.3552426 },
    { lat: 48.2285056, lng: 18.3552145 },
    { lat: 48.2281864, lng: 18.3565533 },
    { lat: 48.2273006, lng: 18.3579003 },
    { lat: 48.2270595, lng: 18.3584072 },
    { lat: 48.225083, lng: 18.358669 },
    { lat: 48.222982, lng: 18.3588051 },
    { lat: 48.222948, lng: 18.358724 },
  ],
  Melek: [
    { lat: 48.216005, lng: 18.2979944 },
    { lat: 48.2151008, lng: 18.2974929 },
    { lat: 48.2148234, lng: 18.2973965 },
    { lat: 48.2144283, lng: 18.2977096 },
    { lat: 48.213636, lng: 18.2978259 },
    { lat: 48.2131102, lng: 18.3011953 },
    { lat: 48.2110447, lng: 18.301974 },
    { lat: 48.2103061, lng: 18.3025578 },
    { lat: 48.2102778, lng: 18.3025756 },
    { lat: 48.2102735, lng: 18.3027565 },
    { lat: 48.2099252, lng: 18.303094 },
    { lat: 48.2096934, lng: 18.3034452 },
    { lat: 48.2091924, lng: 18.3038585 },
    { lat: 48.2076885, lng: 18.3093511 },
    { lat: 48.2071013, lng: 18.3094604 },
    { lat: 48.2069605, lng: 18.3095248 },
    { lat: 48.2068534, lng: 18.309722 },
    { lat: 48.2051359, lng: 18.3130977 },
    { lat: 48.2042497, lng: 18.3154413 },
    { lat: 48.2035947, lng: 18.3169312 },
    { lat: 48.2024299, lng: 18.3162944 },
    { lat: 48.2020045, lng: 18.3161737 },
    { lat: 48.2018059, lng: 18.3147115 },
    { lat: 48.2006571, lng: 18.3163886 },
    { lat: 48.2004676, lng: 18.3166653 },
    { lat: 48.1999431, lng: 18.3175793 },
    { lat: 48.1993779, lng: 18.318606 },
    { lat: 48.1987522, lng: 18.3197426 },
    { lat: 48.1972041, lng: 18.3233231 },
    { lat: 48.1953624, lng: 18.3271779 },
    { lat: 48.1944884, lng: 18.3288481 },
    { lat: 48.1931721, lng: 18.3311764 },
    { lat: 48.1914882, lng: 18.334614 },
    { lat: 48.1911868, lng: 18.335582 },
    { lat: 48.1904375, lng: 18.3376006 },
    { lat: 48.1899835, lng: 18.3389635 },
    { lat: 48.1895563, lng: 18.3403191 },
    { lat: 48.1891407, lng: 18.3418616 },
    { lat: 48.1888287, lng: 18.3418715 },
    { lat: 48.1886127, lng: 18.3419616 },
    { lat: 48.1871895, lng: 18.3433238 },
    { lat: 48.187136, lng: 18.343375 },
    { lat: 48.18736, lng: 18.343575 },
    { lat: 48.187554, lng: 18.343753 },
    { lat: 48.187788, lng: 18.343971 },
    { lat: 48.187938, lng: 18.344107 },
    { lat: 48.188163, lng: 18.344312 },
    { lat: 48.188359, lng: 18.34449 },
    { lat: 48.188561, lng: 18.344674 },
    { lat: 48.188707, lng: 18.344826 },
    { lat: 48.188822, lng: 18.344923 },
    { lat: 48.188981, lng: 18.345099 },
    { lat: 48.18913, lng: 18.345264 },
    { lat: 48.189165, lng: 18.345309 },
    { lat: 48.189346, lng: 18.34553 },
    { lat: 48.189557, lng: 18.345798 },
    { lat: 48.189643, lng: 18.345917 },
    { lat: 48.189771, lng: 18.346095 },
    { lat: 48.190026, lng: 18.346455 },
    { lat: 48.190146, lng: 18.346668 },
    { lat: 48.190149, lng: 18.346663 },
    { lat: 48.190222, lng: 18.34677 },
    { lat: 48.190708, lng: 18.34758 },
    { lat: 48.191217, lng: 18.348432 },
    { lat: 48.191599, lng: 18.349076 },
    { lat: 48.191611, lng: 18.349182 },
    { lat: 48.19166, lng: 18.34934 },
    { lat: 48.191774, lng: 18.349716 },
    { lat: 48.191861, lng: 18.350001 },
    { lat: 48.191955, lng: 18.350315 },
    { lat: 48.192077, lng: 18.350715 },
    { lat: 48.19211, lng: 18.350821 },
    { lat: 48.192229, lng: 18.351206 },
    { lat: 48.192348, lng: 18.351593 },
    { lat: 48.192553, lng: 18.352255 },
    { lat: 48.192789, lng: 18.352979 },
    { lat: 48.192848, lng: 18.353158 },
    { lat: 48.193016, lng: 18.353674 },
    { lat: 48.193087, lng: 18.353892 },
    { lat: 48.193164, lng: 18.354105 },
    { lat: 48.193277, lng: 18.354622 },
    { lat: 48.193289, lng: 18.354672 },
    { lat: 48.193468, lng: 18.355531 },
    { lat: 48.193517, lng: 18.355766 },
    { lat: 48.193534, lng: 18.355848 },
    { lat: 48.194082, lng: 18.3562 },
    { lat: 48.194313, lng: 18.356343 },
    { lat: 48.194559, lng: 18.356495 },
    { lat: 48.194892, lng: 18.356718 },
    { lat: 48.195261, lng: 18.356929 },
    { lat: 48.195875, lng: 18.35727 },
    { lat: 48.196056, lng: 18.357062 },
    { lat: 48.196187, lng: 18.357055 },
    { lat: 48.19621, lng: 18.357068 },
    { lat: 48.196426, lng: 18.357068 },
    { lat: 48.196561, lng: 18.357116 },
    { lat: 48.196586, lng: 18.357126 },
    { lat: 48.196624, lng: 18.357139 },
    { lat: 48.196659, lng: 18.357153 },
    { lat: 48.196728, lng: 18.357181 },
    { lat: 48.19681, lng: 18.357214 },
    { lat: 48.196882, lng: 18.35724 },
    { lat: 48.196896, lng: 18.357245 },
    { lat: 48.196904, lng: 18.357248 },
    { lat: 48.196911, lng: 18.357251 },
    { lat: 48.196929, lng: 18.357258 },
    { lat: 48.196946, lng: 18.357264 },
    { lat: 48.197019, lng: 18.357291 },
    { lat: 48.197051, lng: 18.357304 },
    { lat: 48.197078, lng: 18.357313 },
    { lat: 48.197149, lng: 18.357337 },
    { lat: 48.197196, lng: 18.357353 },
    { lat: 48.197218, lng: 18.35736 },
    { lat: 48.197249, lng: 18.357369 },
    { lat: 48.197278, lng: 18.357378 },
    { lat: 48.197313, lng: 18.35739 },
    { lat: 48.197381, lng: 18.357411 },
    { lat: 48.197411, lng: 18.35742 },
    { lat: 48.197436, lng: 18.357428 },
    { lat: 48.197469, lng: 18.357439 },
    { lat: 48.19749, lng: 18.357445 },
    { lat: 48.197534, lng: 18.35746 },
    { lat: 48.197578, lng: 18.357475 },
    { lat: 48.19762, lng: 18.357491 },
    { lat: 48.197649, lng: 18.357501 },
    { lat: 48.197707, lng: 18.357522 },
    { lat: 48.197765, lng: 18.357543 },
    { lat: 48.197798, lng: 18.357556 },
    { lat: 48.197825, lng: 18.357568 },
    { lat: 48.197841, lng: 18.357575 },
    { lat: 48.197891, lng: 18.357595 },
    { lat: 48.19798, lng: 18.357633 },
    { lat: 48.198011, lng: 18.357645 },
    { lat: 48.198043, lng: 18.357658 },
    { lat: 48.198074, lng: 18.357671 },
    { lat: 48.198113, lng: 18.357687 },
    { lat: 48.198171, lng: 18.357711 },
    { lat: 48.198215, lng: 18.35773 },
    { lat: 48.198263, lng: 18.357743 },
    { lat: 48.198316, lng: 18.357757 },
    { lat: 48.198357, lng: 18.357768 },
    { lat: 48.198386, lng: 18.357776 },
    { lat: 48.198507, lng: 18.357809 },
    { lat: 48.198525, lng: 18.357813 },
    { lat: 48.198563, lng: 18.35782 },
    { lat: 48.198679, lng: 18.357844 },
    { lat: 48.198701, lng: 18.357848 },
    { lat: 48.198716, lng: 18.357849 },
    { lat: 48.198885, lng: 18.357864 },
    { lat: 48.199052, lng: 18.357874 },
    { lat: 48.199251, lng: 18.357883 },
    { lat: 48.199395, lng: 18.357886 },
    { lat: 48.199469, lng: 18.357883 },
    { lat: 48.199621, lng: 18.357758 },
    { lat: 48.199753, lng: 18.357635 },
    { lat: 48.19987, lng: 18.357526 },
    { lat: 48.200162, lng: 18.357278 },
    { lat: 48.200303, lng: 18.357149 },
    { lat: 48.200546, lng: 18.356938 },
    { lat: 48.200724, lng: 18.356776 },
    { lat: 48.200905, lng: 18.356617 },
    { lat: 48.201021, lng: 18.356505 },
    { lat: 48.201309, lng: 18.356204 },
    { lat: 48.201658, lng: 18.355827 },
    { lat: 48.201986, lng: 18.355483 },
    { lat: 48.202082, lng: 18.355391 },
    { lat: 48.20217, lng: 18.355315 },
    { lat: 48.202273, lng: 18.355232 },
    { lat: 48.202374, lng: 18.355163 },
    { lat: 48.202541, lng: 18.35503 },
    { lat: 48.202875, lng: 18.354746 },
    { lat: 48.203235, lng: 18.354441 },
    { lat: 48.203279, lng: 18.354405 },
    { lat: 48.203281, lng: 18.354403 },
    { lat: 48.203354, lng: 18.354332 },
    { lat: 48.20346, lng: 18.354226 },
    { lat: 48.203548, lng: 18.354144 },
    { lat: 48.203617, lng: 18.354074 },
    { lat: 48.203679, lng: 18.354031 },
    { lat: 48.203722, lng: 18.353971 },
    { lat: 48.20376, lng: 18.353916 },
    { lat: 48.203791, lng: 18.353859 },
    { lat: 48.203878, lng: 18.353658 },
    { lat: 48.203943, lng: 18.353516 },
    { lat: 48.204013, lng: 18.3534 },
    { lat: 48.204093, lng: 18.353268 },
    { lat: 48.204195, lng: 18.353106 },
    { lat: 48.204311, lng: 18.352915 },
    { lat: 48.204392, lng: 18.352785 },
    { lat: 48.204422, lng: 18.352736 },
    { lat: 48.204522, lng: 18.352571 },
    { lat: 48.204593, lng: 18.352449 },
    { lat: 48.204656, lng: 18.35233 },
    { lat: 48.204783, lng: 18.352188 },
    { lat: 48.204838, lng: 18.352125 },
    { lat: 48.204999, lng: 18.351929 },
    { lat: 48.205087, lng: 18.351827 },
    { lat: 48.205223, lng: 18.351659 },
    { lat: 48.205264, lng: 18.351618 },
    { lat: 48.205366, lng: 18.351513 },
    { lat: 48.205511, lng: 18.351374 },
    { lat: 48.205585, lng: 18.3513 },
    { lat: 48.205627, lng: 18.351258 },
    { lat: 48.20569, lng: 18.351129 },
    { lat: 48.20577, lng: 18.350938 },
    { lat: 48.20578, lng: 18.350916 },
    { lat: 48.205819, lng: 18.35083 },
    { lat: 48.205859, lng: 18.35074 },
    { lat: 48.20587, lng: 18.350717 },
    { lat: 48.205915, lng: 18.350628 },
    { lat: 48.205942, lng: 18.350576 },
    { lat: 48.205973, lng: 18.350515 },
    { lat: 48.205981, lng: 18.350498 },
    { lat: 48.206039, lng: 18.350371 },
    { lat: 48.206073, lng: 18.350297 },
    { lat: 48.206163, lng: 18.350185 },
    { lat: 48.206206, lng: 18.350132 },
    { lat: 48.206224, lng: 18.350109 },
    { lat: 48.206232, lng: 18.350097 },
    { lat: 48.20635, lng: 18.349943 },
    { lat: 48.20636, lng: 18.349929 },
    { lat: 48.206393, lng: 18.349882 },
    { lat: 48.206427, lng: 18.349834 },
    { lat: 48.206467, lng: 18.349745 },
    { lat: 48.206474, lng: 18.349729 },
    { lat: 48.206528, lng: 18.34957 },
    { lat: 48.206565, lng: 18.349397 },
    { lat: 48.206586, lng: 18.349296 },
    { lat: 48.206596, lng: 18.349248 },
    { lat: 48.206624, lng: 18.349114 },
    { lat: 48.20664, lng: 18.349042 },
    { lat: 48.206653, lng: 18.348976 },
    { lat: 48.206682, lng: 18.348834 },
    { lat: 48.206697, lng: 18.348765 },
    { lat: 48.206723, lng: 18.348645 },
    { lat: 48.206726, lng: 18.348632 },
    { lat: 48.206736, lng: 18.348571 },
    { lat: 48.206754, lng: 18.34846 },
    { lat: 48.206755, lng: 18.348454 },
    { lat: 48.206781, lng: 18.348243 },
    { lat: 48.206811, lng: 18.348047 },
    { lat: 48.20682, lng: 18.348 },
    { lat: 48.20683, lng: 18.347945 },
    { lat: 48.206856, lng: 18.347813 },
    { lat: 48.206895, lng: 18.347664 },
    { lat: 48.206933, lng: 18.347571 },
    { lat: 48.206949, lng: 18.347533 },
    { lat: 48.207006, lng: 18.347446 },
    { lat: 48.207053, lng: 18.347344 },
    { lat: 48.207136, lng: 18.347199 },
    { lat: 48.207228, lng: 18.347027 },
    { lat: 48.207237, lng: 18.34701 },
    { lat: 48.207311, lng: 18.346891 },
    { lat: 48.207325, lng: 18.346861 },
    { lat: 48.207325, lng: 18.346858 },
    { lat: 48.207369, lng: 18.346765 },
    { lat: 48.207387, lng: 18.346732 },
    { lat: 48.207545, lng: 18.346447 },
    { lat: 48.207552, lng: 18.34642 },
    { lat: 48.207584, lng: 18.346309 },
    { lat: 48.207652, lng: 18.346097 },
    { lat: 48.207683, lng: 18.345984 },
    { lat: 48.207854, lng: 18.345549 },
    { lat: 48.2078211, lng: 18.3441975 },
    { lat: 48.2078659, lng: 18.3436584 },
    { lat: 48.2083545, lng: 18.3408794 },
    { lat: 48.2083355, lng: 18.3401868 },
    { lat: 48.2083764, lng: 18.3395955 },
    { lat: 48.2084279, lng: 18.3393544 },
    { lat: 48.2096251, lng: 18.3360015 },
    { lat: 48.2099735, lng: 18.3346491 },
    { lat: 48.2101296, lng: 18.3335245 },
    { lat: 48.210171, lng: 18.3320495 },
    { lat: 48.2105472, lng: 18.3292643 },
    { lat: 48.2105935, lng: 18.3285941 },
    { lat: 48.2110221, lng: 18.3250244 },
    { lat: 48.2113932, lng: 18.3228443 },
    { lat: 48.2114883, lng: 18.3220571 },
    { lat: 48.2117064, lng: 18.3207983 },
    { lat: 48.2123769, lng: 18.3180323 },
    { lat: 48.2127175, lng: 18.3163683 },
    { lat: 48.2136957, lng: 18.3126905 },
    { lat: 48.21438, lng: 18.3098745 },
    { lat: 48.2151696, lng: 18.3051713 },
    { lat: 48.2153723, lng: 18.3043126 },
    { lat: 48.2155586, lng: 18.3038023 },
    { lat: 48.2159119, lng: 18.3018888 },
    { lat: 48.2155922, lng: 18.3016658 },
    { lat: 48.2157575, lng: 18.3002139 },
    { lat: 48.2161271, lng: 18.2993586 },
    { lat: 48.2162072, lng: 18.2985491 },
    { lat: 48.2161041, lng: 18.2982665 },
    { lat: 48.216005, lng: 18.2979944 },
  ],
  Hosťová: [
    { lat: 48.3231316, lng: 18.210053 },
    { lat: 48.3230849, lng: 18.2101243 },
    { lat: 48.3219136, lng: 18.2119367 },
    { lat: 48.3205075, lng: 18.2143742 },
    { lat: 48.3222477, lng: 18.2170921 },
    { lat: 48.3235329, lng: 18.2193716 },
    { lat: 48.3244574, lng: 18.2211548 },
    { lat: 48.3248229, lng: 18.2217019 },
    { lat: 48.3249864, lng: 18.2217211 },
    { lat: 48.3252384, lng: 18.2220181 },
    { lat: 48.3259969, lng: 18.2230979 },
    { lat: 48.326371, lng: 18.2235166 },
    { lat: 48.326406, lng: 18.2236557 },
    { lat: 48.3270394, lng: 18.2229575 },
    { lat: 48.328063, lng: 18.2247961 },
    { lat: 48.328249, lng: 18.2244585 },
    { lat: 48.3289988, lng: 18.225621 },
    { lat: 48.3296991, lng: 18.2269432 },
    { lat: 48.3311502, lng: 18.230307 },
    { lat: 48.331751, lng: 18.2294535 },
    { lat: 48.3330078, lng: 18.2320735 },
    { lat: 48.3367714, lng: 18.2273233 },
    { lat: 48.337046, lng: 18.2287008 },
    { lat: 48.3373796, lng: 18.2292412 },
    { lat: 48.3380022, lng: 18.2285738 },
    { lat: 48.3384021, lng: 18.2282669 },
    { lat: 48.3397401, lng: 18.2266377 },
    { lat: 48.3397805, lng: 18.2266544 },
    { lat: 48.3398737, lng: 18.2260036 },
    { lat: 48.3398744, lng: 18.2249785 },
    { lat: 48.3399498, lng: 18.2240565 },
    { lat: 48.3399391, lng: 18.2229704 },
    { lat: 48.3401694, lng: 18.2218818 },
    { lat: 48.3402102, lng: 18.2201024 },
    { lat: 48.3401261, lng: 18.2182793 },
    { lat: 48.3399127, lng: 18.2174214 },
    { lat: 48.3398853, lng: 18.2173114 },
    { lat: 48.3398627, lng: 18.2171489 },
    { lat: 48.3397658, lng: 18.2164521 },
    { lat: 48.3397364, lng: 18.2162409 },
    { lat: 48.3397113, lng: 18.2158718 },
    { lat: 48.3396842, lng: 18.2154727 },
    { lat: 48.3394896, lng: 18.2145077 },
    { lat: 48.3394775, lng: 18.2143182 },
    { lat: 48.3395588, lng: 18.2138902 },
    { lat: 48.3395724, lng: 18.2138188 },
    { lat: 48.339583, lng: 18.2137628 },
    { lat: 48.3395918, lng: 18.2137511 },
    { lat: 48.3397018, lng: 18.2134072 },
    { lat: 48.339713, lng: 18.2133722 },
    { lat: 48.3402721, lng: 18.2116919 },
    { lat: 48.3402828, lng: 18.2116597 },
    { lat: 48.3408865, lng: 18.2110143 },
    { lat: 48.3415587, lng: 18.2097794 },
    { lat: 48.3423956, lng: 18.2086028 },
    { lat: 48.342808, lng: 18.2078926 },
    { lat: 48.343231, lng: 18.2073243 },
    { lat: 48.3434867, lng: 18.2067692 },
    { lat: 48.3441497, lng: 18.2058248 },
    { lat: 48.3445995, lng: 18.2049763 },
    { lat: 48.3447999, lng: 18.2043666 },
    { lat: 48.3451394, lng: 18.2035647 },
    { lat: 48.3458379, lng: 18.2023556 },
    { lat: 48.3463871, lng: 18.2015905 },
    { lat: 48.3464095, lng: 18.2014925 },
    { lat: 48.3464767, lng: 18.2011985 },
    { lat: 48.3465186, lng: 18.2010154 },
    { lat: 48.3465055, lng: 18.2003821 },
    { lat: 48.3461762, lng: 18.1990026 },
    { lat: 48.3459196, lng: 18.1982564 },
    { lat: 48.345766, lng: 18.1980785 },
    { lat: 48.3456265, lng: 18.1977927 },
    { lat: 48.3454468, lng: 18.1970258 },
    { lat: 48.3454125, lng: 18.196496 },
    { lat: 48.3452688, lng: 18.1960192 },
    { lat: 48.3452469, lng: 18.1954872 },
    { lat: 48.3448723, lng: 18.1939533 },
    { lat: 48.344533, lng: 18.1929827 },
    { lat: 48.3443659, lng: 18.1921078 },
    { lat: 48.3440459, lng: 18.1912216 },
    { lat: 48.3439002, lng: 18.1903418 },
    { lat: 48.3438575, lng: 18.1903466 },
    { lat: 48.3426687, lng: 18.1888787 },
    { lat: 48.3420956, lng: 18.1884722 },
    { lat: 48.3414319, lng: 18.1882173 },
    { lat: 48.3410053, lng: 18.187886 },
    { lat: 48.3409934, lng: 18.1878564 },
    { lat: 48.3403561, lng: 18.1878807 },
    { lat: 48.3400614, lng: 18.1880146 },
    { lat: 48.3399311, lng: 18.1881969 },
    { lat: 48.3397197, lng: 18.1887754 },
    { lat: 48.3394837, lng: 18.1891999 },
    { lat: 48.3387402, lng: 18.1902699 },
    { lat: 48.3380705, lng: 18.1913532 },
    { lat: 48.3379395, lng: 18.1917204 },
    { lat: 48.3378313, lng: 18.1925006 },
    { lat: 48.3372208, lng: 18.1931981 },
    { lat: 48.3368015, lng: 18.193577 },
    { lat: 48.33624, lng: 18.1943649 },
    { lat: 48.3350675, lng: 18.1952974 },
    { lat: 48.3340966, lng: 18.196534 },
    { lat: 48.3334623, lng: 18.197197 },
    { lat: 48.3326191, lng: 18.1978339 },
    { lat: 48.3307437, lng: 18.1996444 },
    { lat: 48.329874, lng: 18.2000401 },
    { lat: 48.3293352, lng: 18.2005362 },
    { lat: 48.3266975, lng: 18.2036415 },
    { lat: 48.3264619, lng: 18.2039955 },
    { lat: 48.3256318, lng: 18.2060879 },
    { lat: 48.3231628, lng: 18.2100071 },
    { lat: 48.3231316, lng: 18.210053 },
  ],
  Báb: [
    { lat: 48.29675, lng: 17.833366 },
    { lat: 48.296683, lng: 17.833409 },
    { lat: 48.296633, lng: 17.833446 },
    { lat: 48.296349, lng: 17.833646 },
    { lat: 48.295915, lng: 17.833986 },
    { lat: 48.29572, lng: 17.834138 },
    { lat: 48.295536, lng: 17.834258 },
    { lat: 48.294791, lng: 17.834622 },
    { lat: 48.294755, lng: 17.83464 },
    { lat: 48.294437, lng: 17.834721 },
    { lat: 48.29413, lng: 17.83485 },
    { lat: 48.29381, lng: 17.834976 },
    { lat: 48.293468, lng: 17.835115 },
    { lat: 48.293106, lng: 17.835269 },
    { lat: 48.292795, lng: 17.835403 },
    { lat: 48.292722, lng: 17.835429 },
    { lat: 48.292359, lng: 17.835541 },
    { lat: 48.291939, lng: 17.835678 },
    { lat: 48.291699, lng: 17.835753 },
    { lat: 48.291491, lng: 17.835818 },
    { lat: 48.291443, lng: 17.835833 },
    { lat: 48.291393, lng: 17.835853 },
    { lat: 48.291057, lng: 17.835977 },
    { lat: 48.290851, lng: 17.83606 },
    { lat: 48.290661, lng: 17.836131 },
    { lat: 48.290658, lng: 17.836132 },
    { lat: 48.290548, lng: 17.836174 },
    { lat: 48.29048, lng: 17.8362 },
    { lat: 48.290269, lng: 17.836652 },
    { lat: 48.290083, lng: 17.837016 },
    { lat: 48.289668, lng: 17.837632 },
    { lat: 48.28951, lng: 17.837869 },
    { lat: 48.289477, lng: 17.837924 },
    { lat: 48.289291, lng: 17.838408 },
    { lat: 48.289273, lng: 17.838455 },
    { lat: 48.289189, lng: 17.838676 },
    { lat: 48.289158, lng: 17.838758 },
    { lat: 48.288823, lng: 17.83954 },
    { lat: 48.288729, lng: 17.83976 },
    { lat: 48.288687, lng: 17.840134 },
    { lat: 48.288675, lng: 17.840468 },
    { lat: 48.288646, lng: 17.840713 },
    { lat: 48.288596, lng: 17.841591 },
    { lat: 48.28858, lng: 17.841695 },
    { lat: 48.288567, lng: 17.841764 },
    { lat: 48.288551, lng: 17.841986 },
    { lat: 48.288559, lng: 17.842114 },
    { lat: 48.288562, lng: 17.842194 },
    { lat: 48.28858, lng: 17.842475 },
    { lat: 48.28858, lng: 17.842496 },
    { lat: 48.288606, lng: 17.843009 },
    { lat: 48.288621, lng: 17.843285 },
    { lat: 48.288599, lng: 17.843615 },
    { lat: 48.288571, lng: 17.843764 },
    { lat: 48.288533, lng: 17.843963 },
    { lat: 48.288486, lng: 17.844224 },
    { lat: 48.288486, lng: 17.84422 },
    { lat: 48.288439, lng: 17.844481 },
    { lat: 48.288273, lng: 17.844839 },
    { lat: 48.288249, lng: 17.844926 },
    { lat: 48.2883, lng: 17.845567 },
    { lat: 48.288346, lng: 17.846238 },
    { lat: 48.288374, lng: 17.846933 },
    { lat: 48.2884, lng: 17.847693 },
    { lat: 48.288375, lng: 17.848164 },
    { lat: 48.288375, lng: 17.848167 },
    { lat: 48.28836, lng: 17.848442 },
    { lat: 48.288311, lng: 17.848976 },
    { lat: 48.288265, lng: 17.849404 },
    { lat: 48.288269, lng: 17.849466 },
    { lat: 48.288274, lng: 17.849542 },
    { lat: 48.288293, lng: 17.849819 },
    { lat: 48.288309, lng: 17.850132 },
    { lat: 48.288343, lng: 17.85069 },
    { lat: 48.288353, lng: 17.850738 },
    { lat: 48.288445, lng: 17.851379 },
    { lat: 48.288482, lng: 17.85163 },
    { lat: 48.288512, lng: 17.852038 },
    { lat: 48.288496, lng: 17.852682 },
    { lat: 48.28847, lng: 17.854095 },
    { lat: 48.288465, lng: 17.854387 },
    { lat: 48.288423, lng: 17.855541 },
    { lat: 48.288373, lng: 17.856161 },
    { lat: 48.28837, lng: 17.8562 },
    { lat: 48.28785, lng: 17.857203 },
    { lat: 48.287554, lng: 17.857777 },
    { lat: 48.287537, lng: 17.857829 },
    { lat: 48.287536, lng: 17.857831 },
    { lat: 48.287535, lng: 17.857856 },
    { lat: 48.28752, lng: 17.858003 },
    { lat: 48.287519, lng: 17.858008 },
    { lat: 48.287505, lng: 17.858192 },
    { lat: 48.287477, lng: 17.858415 },
    { lat: 48.287456, lng: 17.858565 },
    { lat: 48.28745, lng: 17.858607 },
    { lat: 48.287449, lng: 17.858614 },
    { lat: 48.287449, lng: 17.858622 },
    { lat: 48.287431, lng: 17.858743 },
    { lat: 48.287396, lng: 17.858958 },
    { lat: 48.287276, lng: 17.859686 },
    { lat: 48.287074, lng: 17.860218 },
    { lat: 48.286983, lng: 17.860457 },
    { lat: 48.286581, lng: 17.861307 },
    { lat: 48.286392, lng: 17.861701 },
    { lat: 48.286167, lng: 17.862224 },
    { lat: 48.285998, lng: 17.862365 },
    { lat: 48.285996, lng: 17.862366 },
    { lat: 48.285982, lng: 17.862378 },
    { lat: 48.285959, lng: 17.862395 },
    { lat: 48.285948, lng: 17.862403 },
    { lat: 48.285867, lng: 17.862465 },
    { lat: 48.285864, lng: 17.862467 },
    { lat: 48.285822, lng: 17.862506 },
    { lat: 48.28582, lng: 17.862509 },
    { lat: 48.285797, lng: 17.862529 },
    { lat: 48.285562, lng: 17.862737 },
    { lat: 48.28529, lng: 17.86296 },
    { lat: 48.285241, lng: 17.862999 },
    { lat: 48.285179, lng: 17.863033 },
    { lat: 48.284906, lng: 17.863182 },
    { lat: 48.284757, lng: 17.863276 },
    { lat: 48.284555, lng: 17.863404 },
    { lat: 48.284224, lng: 17.863561 },
    { lat: 48.284091, lng: 17.863623 },
    { lat: 48.283885, lng: 17.863719 },
    { lat: 48.283451, lng: 17.863907 },
    { lat: 48.283415, lng: 17.863924 },
    { lat: 48.283294, lng: 17.863977 },
    { lat: 48.283088, lng: 17.864098 },
    { lat: 48.282962, lng: 17.864172 },
    { lat: 48.282651, lng: 17.864378 },
    { lat: 48.282336, lng: 17.864573 },
    { lat: 48.28201, lng: 17.864777 },
    { lat: 48.281691, lng: 17.864986 },
    { lat: 48.281489, lng: 17.865136 },
    { lat: 48.28138, lng: 17.865203 },
    { lat: 48.281056, lng: 17.865369 },
    { lat: 48.280773, lng: 17.865552 },
    { lat: 48.280737, lng: 17.865577 },
    { lat: 48.280727, lng: 17.865583 },
    { lat: 48.280689, lng: 17.865608 },
    { lat: 48.280865, lng: 17.866921 },
    { lat: 48.28098, lng: 17.867765 },
    { lat: 48.281034, lng: 17.868165 },
    { lat: 48.281193, lng: 17.869364 },
    { lat: 48.281329, lng: 17.870386 },
    { lat: 48.281357, lng: 17.870585 },
    { lat: 48.281368, lng: 17.870663 },
    { lat: 48.28147, lng: 17.871435 },
    { lat: 48.281496, lng: 17.871544 },
    { lat: 48.281549, lng: 17.871588 },
    { lat: 48.281611, lng: 17.87217 },
    { lat: 48.28183, lng: 17.873862 },
    { lat: 48.28197, lng: 17.874953 },
    { lat: 48.28212, lng: 17.876115 },
    { lat: 48.282136, lng: 17.876261 },
    { lat: 48.284014, lng: 17.876353 },
    { lat: 48.284325, lng: 17.878962 },
    { lat: 48.284563, lng: 17.880946 },
    { lat: 48.284572, lng: 17.881018 },
    { lat: 48.283983, lng: 17.881256 },
    { lat: 48.283655, lng: 17.881573 },
    { lat: 48.283536, lng: 17.881689 },
    { lat: 48.282983, lng: 17.882248 },
    { lat: 48.282941, lng: 17.882289 },
    { lat: 48.282919, lng: 17.882311 },
    { lat: 48.282796, lng: 17.882433 },
    { lat: 48.282498, lng: 17.88273 },
    { lat: 48.280923, lng: 17.883593 },
    { lat: 48.280689, lng: 17.883722 },
    { lat: 48.280298, lng: 17.883937 },
    { lat: 48.280269, lng: 17.883958 },
    { lat: 48.280085, lng: 17.884079 },
    { lat: 48.280074, lng: 17.884087 },
    { lat: 48.279082, lng: 17.884744 },
    { lat: 48.277644, lng: 17.885695 },
    { lat: 48.277852, lng: 17.886094 },
    { lat: 48.278577, lng: 17.887485 },
    { lat: 48.279827, lng: 17.889942 },
    { lat: 48.279851, lng: 17.889989 },
    { lat: 48.281478, lng: 17.893196 },
    { lat: 48.281573, lng: 17.893387 },
    { lat: 48.282026, lng: 17.894297 },
    { lat: 48.282936, lng: 17.896091 },
    { lat: 48.283105, lng: 17.896424 },
    { lat: 48.283163, lng: 17.89654 },
    { lat: 48.282726, lng: 17.896673 },
    { lat: 48.282197, lng: 17.896839 },
    { lat: 48.282081, lng: 17.896869 },
    { lat: 48.281712, lng: 17.896956 },
    { lat: 48.281484, lng: 17.897006 },
    { lat: 48.281258, lng: 17.897059 },
    { lat: 48.281156, lng: 17.897086 },
    { lat: 48.281059, lng: 17.897106 },
    { lat: 48.280642, lng: 17.897206 },
    { lat: 48.279903, lng: 17.897369 },
    { lat: 48.279508, lng: 17.89746 },
    { lat: 48.27907, lng: 17.897564 },
    { lat: 48.277162, lng: 17.898037 },
    { lat: 48.277117, lng: 17.898046 },
    { lat: 48.277073, lng: 17.898058 },
    { lat: 48.277012, lng: 17.897935 },
    { lat: 48.27663, lng: 17.898012 },
    { lat: 48.274966, lng: 17.898316 },
    { lat: 48.274677, lng: 17.89837 },
    { lat: 48.274226, lng: 17.898453 },
    { lat: 48.274225, lng: 17.89914 },
    { lat: 48.274336, lng: 17.89961 },
    { lat: 48.274456, lng: 17.900103 },
    { lat: 48.274471, lng: 17.900159 },
    { lat: 48.274805, lng: 17.901398 },
    { lat: 48.274967, lng: 17.902083 },
    { lat: 48.275137, lng: 17.902725 },
    { lat: 48.27519, lng: 17.902945 },
    { lat: 48.275212, lng: 17.90308 },
    { lat: 48.275241, lng: 17.903268 },
    { lat: 48.275269, lng: 17.903449 },
    { lat: 48.27531, lng: 17.903721 },
    { lat: 48.275318, lng: 17.903805 },
    { lat: 48.275334, lng: 17.903965 },
    { lat: 48.275415, lng: 17.904756 },
    { lat: 48.275476, lng: 17.905362 },
    { lat: 48.275502, lng: 17.905615 },
    { lat: 48.27553, lng: 17.905898 },
    { lat: 48.275557, lng: 17.906207 },
    { lat: 48.275567, lng: 17.906331 },
    { lat: 48.275578, lng: 17.90645 },
    { lat: 48.275582, lng: 17.906496 },
    { lat: 48.2773641, lng: 17.9094504 },
    { lat: 48.2784602, lng: 17.9110346 },
    { lat: 48.2788453, lng: 17.9114291 },
    { lat: 48.2792074, lng: 17.9111087 },
    { lat: 48.2799446, lng: 17.9126972 },
    { lat: 48.2802944, lng: 17.913161 },
    { lat: 48.281053, lng: 17.9143915 },
    { lat: 48.2818895, lng: 17.9155107 },
    { lat: 48.2819797, lng: 17.9158451 },
    { lat: 48.2819967, lng: 17.9158286 },
    { lat: 48.2823397, lng: 17.9158256 },
    { lat: 48.2840939, lng: 17.9138276 },
    { lat: 48.2858857, lng: 17.9120459 },
    { lat: 48.2876378, lng: 17.9112299 },
    { lat: 48.2893931, lng: 17.910316 },
    { lat: 48.2899668, lng: 17.9101533 },
    { lat: 48.2910898, lng: 17.9096468 },
    { lat: 48.2929548, lng: 17.9082149 },
    { lat: 48.2937184, lng: 17.9077548 },
    { lat: 48.2943712, lng: 17.9072781 },
    { lat: 48.2954033, lng: 17.9063139 },
    { lat: 48.2959287, lng: 17.9059513 },
    { lat: 48.2965991, lng: 17.9056679 },
    { lat: 48.2975057, lng: 17.9064744 },
    { lat: 48.2978579, lng: 17.9066782 },
    { lat: 48.2982203, lng: 17.9069947 },
    { lat: 48.3002809, lng: 17.9050268 },
    { lat: 48.3018185, lng: 17.9077342 },
    { lat: 48.3033871, lng: 17.910655 },
    { lat: 48.3043753, lng: 17.9095792 },
    { lat: 48.3050373, lng: 17.9102224 },
    { lat: 48.3056489, lng: 17.9096229 },
    { lat: 48.3066851, lng: 17.9091905 },
    { lat: 48.3074194, lng: 17.9083257 },
    { lat: 48.3075599, lng: 17.908224 },
    { lat: 48.3076801, lng: 17.9079903 },
    { lat: 48.3080516, lng: 17.9068909 },
    { lat: 48.3082417, lng: 17.9058729 },
    { lat: 48.3088589, lng: 17.905218 },
    { lat: 48.3097581, lng: 17.9046505 },
    { lat: 48.310316, lng: 17.9038909 },
    { lat: 48.3113467, lng: 17.9030534 },
    { lat: 48.311442, lng: 17.9030447 },
    { lat: 48.3123224, lng: 17.9024474 },
    { lat: 48.3140091, lng: 17.9016105 },
    { lat: 48.3150255, lng: 17.9010245 },
    { lat: 48.3163273, lng: 17.9005411 },
    { lat: 48.3168187, lng: 17.900699 },
    { lat: 48.3183076, lng: 17.9003606 },
    { lat: 48.3190568, lng: 17.8997418 },
    { lat: 48.3203195, lng: 17.8984117 },
    { lat: 48.3211159, lng: 17.8981212 },
    { lat: 48.3220905, lng: 17.8970292 },
    { lat: 48.3226598, lng: 17.895901 },
    { lat: 48.3232849, lng: 17.8942774 },
    { lat: 48.3241837, lng: 17.892813 },
    { lat: 48.3247427, lng: 17.8917375 },
    { lat: 48.3252427, lng: 17.8902083 },
    { lat: 48.325254, lng: 17.8901727 },
    { lat: 48.3239917, lng: 17.8882891 },
    { lat: 48.3227131, lng: 17.8867924 },
    { lat: 48.3222541, lng: 17.8864982 },
    { lat: 48.3216152, lng: 17.8858683 },
    { lat: 48.3206445, lng: 17.8850525 },
    { lat: 48.3188902, lng: 17.8830066 },
    { lat: 48.3174962, lng: 17.8804449 },
    { lat: 48.3173505, lng: 17.8793324 },
    { lat: 48.3174747, lng: 17.8784572 },
    { lat: 48.3174763, lng: 17.8773912 },
    { lat: 48.3175311, lng: 17.8765641 },
    { lat: 48.3175187, lng: 17.8753968 },
    { lat: 48.3175566, lng: 17.8751296 },
    { lat: 48.3175832, lng: 17.8735247 },
    { lat: 48.3175486, lng: 17.8711369 },
    { lat: 48.3174355, lng: 17.8686296 },
    { lat: 48.3169284, lng: 17.8670705 },
    { lat: 48.3169014, lng: 17.8667741 },
    { lat: 48.3168988, lng: 17.8667323 },
    { lat: 48.3170798, lng: 17.8667213 },
    { lat: 48.3174583, lng: 17.8664492 },
    { lat: 48.317551, lng: 17.8664479 },
    { lat: 48.3176814, lng: 17.8662006 },
    { lat: 48.3179253, lng: 17.8660147 },
    { lat: 48.3180021, lng: 17.8660169 },
    { lat: 48.3179595, lng: 17.8652446 },
    { lat: 48.3185005, lng: 17.8652673 },
    { lat: 48.3181745, lng: 17.8624523 },
    { lat: 48.3178807, lng: 17.8610391 },
    { lat: 48.318679, lng: 17.8605261 },
    { lat: 48.3183512, lng: 17.8593701 },
    { lat: 48.3176926, lng: 17.8566791 },
    { lat: 48.3173228, lng: 17.8555566 },
    { lat: 48.3180391, lng: 17.8544947 },
    { lat: 48.3186088, lng: 17.8538409 },
    { lat: 48.3196225, lng: 17.8525272 },
    { lat: 48.320768, lng: 17.851246 },
    { lat: 48.320747, lng: 17.851179 },
    { lat: 48.320743, lng: 17.851164 },
    { lat: 48.320619, lng: 17.850704 },
    { lat: 48.320569, lng: 17.850526 },
    { lat: 48.320225, lng: 17.849277 },
    { lat: 48.319598, lng: 17.846817 },
    { lat: 48.319572, lng: 17.846817 },
    { lat: 48.319563, lng: 17.846817 },
    { lat: 48.319106, lng: 17.846838 },
    { lat: 48.318935, lng: 17.846843 },
    { lat: 48.318775, lng: 17.846857 },
    { lat: 48.318468, lng: 17.84688 },
    { lat: 48.318368, lng: 17.846887 },
    { lat: 48.318268, lng: 17.846889 },
    { lat: 48.318182, lng: 17.846899 },
    { lat: 48.318091, lng: 17.846898 },
    { lat: 48.318008, lng: 17.846897 },
    { lat: 48.317915, lng: 17.84689 },
    { lat: 48.317741, lng: 17.846875 },
    { lat: 48.317554, lng: 17.84686 },
    { lat: 48.317409, lng: 17.846853 },
    { lat: 48.317318, lng: 17.84683 },
    { lat: 48.317224, lng: 17.846807 },
    { lat: 48.317163, lng: 17.84679 },
    { lat: 48.317105, lng: 17.846771 },
    { lat: 48.317037, lng: 17.846752 },
    { lat: 48.316973, lng: 17.846731 },
    { lat: 48.316758, lng: 17.846724 },
    { lat: 48.316677, lng: 17.846722 },
    { lat: 48.316605, lng: 17.84672 },
    { lat: 48.316351, lng: 17.846716 },
    { lat: 48.316293, lng: 17.846716 },
    { lat: 48.316291, lng: 17.846716 },
    { lat: 48.316172, lng: 17.846711 },
    { lat: 48.31603, lng: 17.846708 },
    { lat: 48.315764, lng: 17.846709 },
    { lat: 48.315638, lng: 17.846717 },
    { lat: 48.315574, lng: 17.846718 },
    { lat: 48.315504, lng: 17.846719 },
    { lat: 48.315385, lng: 17.846722 },
    { lat: 48.315259, lng: 17.846723 },
    { lat: 48.315183, lng: 17.846729 },
    { lat: 48.315096, lng: 17.846734 },
    { lat: 48.315012, lng: 17.846728 },
    { lat: 48.314885, lng: 17.846735 },
    { lat: 48.31481, lng: 17.84674 },
    { lat: 48.314732, lng: 17.846741 },
    { lat: 48.314587, lng: 17.846748 },
    { lat: 48.314297, lng: 17.846755 },
    { lat: 48.314157, lng: 17.846755 },
    { lat: 48.314086, lng: 17.846758 },
    { lat: 48.313979, lng: 17.846764 },
    { lat: 48.313914, lng: 17.846764 },
    { lat: 48.313866, lng: 17.846767 },
    { lat: 48.313813, lng: 17.846767 },
    { lat: 48.313688, lng: 17.846775 },
    { lat: 48.313641, lng: 17.846778 },
    { lat: 48.313569, lng: 17.846785 },
    { lat: 48.313481, lng: 17.846783 },
    { lat: 48.313355, lng: 17.846763 },
    { lat: 48.31329, lng: 17.846749 },
    { lat: 48.313226, lng: 17.846735 },
    { lat: 48.313095, lng: 17.846705 },
    { lat: 48.313019, lng: 17.846692 },
    { lat: 48.31297, lng: 17.846687 },
    { lat: 48.312928, lng: 17.846683 },
    { lat: 48.312925, lng: 17.846683 },
    { lat: 48.312423, lng: 17.846705 },
    { lat: 48.312077, lng: 17.846738 },
    { lat: 48.311768, lng: 17.846771 },
    { lat: 48.311281, lng: 17.846824 },
    { lat: 48.310667, lng: 17.846959 },
    { lat: 48.310662, lng: 17.84696 },
    { lat: 48.310632, lng: 17.846967 },
    { lat: 48.310361, lng: 17.84702 },
    { lat: 48.310309, lng: 17.84703 },
    { lat: 48.309885, lng: 17.847046 },
    { lat: 48.30978, lng: 17.84705 },
    { lat: 48.309543, lng: 17.847068 },
    { lat: 48.30946, lng: 17.847069 },
    { lat: 48.309409, lng: 17.84707 },
    { lat: 48.309227, lng: 17.847063 },
    { lat: 48.308944, lng: 17.847056 },
    { lat: 48.308639, lng: 17.847034 },
    { lat: 48.308244, lng: 17.847019 },
    { lat: 48.308178, lng: 17.847015 },
    { lat: 48.308175, lng: 17.847016 },
    { lat: 48.307907, lng: 17.847003 },
    { lat: 48.307655, lng: 17.846999 },
    { lat: 48.307477, lng: 17.846995 },
    { lat: 48.307377, lng: 17.847003 },
    { lat: 48.307216, lng: 17.847021 },
    { lat: 48.306966, lng: 17.847048 },
    { lat: 48.306623, lng: 17.847098 },
    { lat: 48.306544, lng: 17.847025 },
    { lat: 48.306226, lng: 17.846738 },
    { lat: 48.305742, lng: 17.846208 },
    { lat: 48.305448, lng: 17.845887 },
    { lat: 48.305164, lng: 17.845563 },
    { lat: 48.30479, lng: 17.845152 },
    { lat: 48.304378, lng: 17.844721 },
    { lat: 48.303967, lng: 17.844305 },
    { lat: 48.303401, lng: 17.843724 },
    { lat: 48.303346, lng: 17.843666 },
    { lat: 48.303107, lng: 17.843158 },
    { lat: 48.302787, lng: 17.842487 },
    { lat: 48.302291, lng: 17.841445 },
    { lat: 48.302204, lng: 17.841285 },
    { lat: 48.30207, lng: 17.841053 },
    { lat: 48.301922, lng: 17.840819 },
    { lat: 48.301656, lng: 17.840327 },
    { lat: 48.301284, lng: 17.83963 },
    { lat: 48.300976, lng: 17.839052 },
    { lat: 48.300558, lng: 17.838239 },
    { lat: 48.30029, lng: 17.83772 },
    { lat: 48.300126, lng: 17.837423 },
    { lat: 48.299896, lng: 17.837003 },
    { lat: 48.299653, lng: 17.836671 },
    { lat: 48.299339, lng: 17.836246 },
    { lat: 48.299302, lng: 17.836199 },
    { lat: 48.299161, lng: 17.835927 },
    { lat: 48.298932, lng: 17.835452 },
    { lat: 48.298747, lng: 17.835069 },
    { lat: 48.298512, lng: 17.83443 },
    { lat: 48.298227, lng: 17.833649 },
    { lat: 48.298053, lng: 17.833423 },
    { lat: 48.297923, lng: 17.833282 },
    { lat: 48.297574, lng: 17.832953 },
    { lat: 48.297465, lng: 17.833002 },
    { lat: 48.297252, lng: 17.833108 },
    { lat: 48.297144, lng: 17.833161 },
    { lat: 48.296799, lng: 17.833333 },
    { lat: 48.29675, lng: 17.833366 },
  ],
  VeľkáDolina: [
    { lat: 48.1720587, lng: 18.039521 },
    { lat: 48.1742188, lng: 18.0358613 },
    { lat: 48.17427, lng: 18.035886 },
    { lat: 48.1771262, lng: 18.040447 },
    { lat: 48.1771033, lng: 18.0404758 },
    { lat: 48.1771548, lng: 18.040603 },
    { lat: 48.1771858, lng: 18.0405547 },
    { lat: 48.1809449, lng: 18.046447 },
    { lat: 48.1821004, lng: 18.0441874 },
    { lat: 48.1829539, lng: 18.0429786 },
    { lat: 48.1844752, lng: 18.0408498 },
    { lat: 48.1853883, lng: 18.0400549 },
    { lat: 48.1858636, lng: 18.0395446 },
    { lat: 48.1855968, lng: 18.0377562 },
    { lat: 48.1865065, lng: 18.0348191 },
    { lat: 48.1884712, lng: 18.0321813 },
    { lat: 48.190021, lng: 18.0303275 },
    { lat: 48.1913514, lng: 18.0295856 },
    { lat: 48.1923847, lng: 18.0277609 },
    { lat: 48.1928081, lng: 18.0272142 },
    { lat: 48.1929886, lng: 18.027136 },
    { lat: 48.1945694, lng: 18.0268813 },
    { lat: 48.1980655, lng: 18.0250016 },
    { lat: 48.1983983, lng: 18.0244113 },
    { lat: 48.1985198, lng: 18.024065 },
    { lat: 48.198612, lng: 18.0239819 },
    { lat: 48.1997257, lng: 18.0219155 },
    { lat: 48.1999146, lng: 18.021546 },
    { lat: 48.1941208, lng: 18.0184567 },
    { lat: 48.1901317, lng: 18.0139718 },
    { lat: 48.1921223, lng: 18.0106626 },
    { lat: 48.1873343, lng: 18.003565 },
    { lat: 48.1947107, lng: 17.9921306 },
    { lat: 48.1949621, lng: 17.9917326 },
    { lat: 48.1937851, lng: 17.9907764 },
    { lat: 48.1907956, lng: 17.9887115 },
    { lat: 48.1907821, lng: 17.9887491 },
    { lat: 48.1887304, lng: 17.9866848 },
    { lat: 48.1876296, lng: 17.9857389 },
    { lat: 48.1869874, lng: 17.9850805 },
    { lat: 48.1865887, lng: 17.9845342 },
    { lat: 48.1860023, lng: 17.9835083 },
    { lat: 48.1855454, lng: 17.9820636 },
    { lat: 48.1850862, lng: 17.9802815 },
    { lat: 48.184681, lng: 17.979224 },
    { lat: 48.184036, lng: 17.980295 },
    { lat: 48.183713, lng: 17.980827 },
    { lat: 48.183418, lng: 17.981145 },
    { lat: 48.182647, lng: 17.981968 },
    { lat: 48.182031, lng: 17.982857 },
    { lat: 48.181971, lng: 17.982944 },
    { lat: 48.181443, lng: 17.983715 },
    { lat: 48.180927, lng: 17.984449 },
    { lat: 48.180142, lng: 17.985708 },
    { lat: 48.179673, lng: 17.986121 },
    { lat: 48.178889, lng: 17.986844 },
    { lat: 48.178414, lng: 17.987269 },
    { lat: 48.177967, lng: 17.987675 },
    { lat: 48.177459, lng: 17.988116 },
    { lat: 48.177084, lng: 17.988485 },
    { lat: 48.1768, lng: 17.988748 },
    { lat: 48.176454, lng: 17.988985 },
    { lat: 48.17605, lng: 17.989251 },
    { lat: 48.175854, lng: 17.989382 },
    { lat: 48.175819, lng: 17.989407 },
    { lat: 48.174838, lng: 17.990099 },
    { lat: 48.174294, lng: 17.990668 },
    { lat: 48.1741607, lng: 17.9908094 },
    { lat: 48.173933, lng: 17.991051 },
    { lat: 48.173609, lng: 17.991388 },
    { lat: 48.17301, lng: 17.992023 },
    { lat: 48.1725, lng: 17.992549 },
    { lat: 48.172423, lng: 17.992629 },
    { lat: 48.172407, lng: 17.992646 },
    { lat: 48.17235, lng: 17.992708 },
    { lat: 48.171869, lng: 17.99323 },
    { lat: 48.171141, lng: 17.993953 },
    { lat: 48.170711, lng: 17.994423 },
    { lat: 48.170416, lng: 17.99474 },
    { lat: 48.169736, lng: 17.995474 },
    { lat: 48.169208, lng: 17.996016 },
    { lat: 48.168619, lng: 17.996675 },
    { lat: 48.168098, lng: 17.997479 },
    { lat: 48.167652, lng: 17.998325 },
    { lat: 48.167232, lng: 17.999189 },
    { lat: 48.166707, lng: 17.999878 },
    { lat: 48.166254, lng: 18.000616 },
    { lat: 48.165946, lng: 18.001168 },
    { lat: 48.165749, lng: 18.001626 },
    { lat: 48.165545, lng: 18.001913 },
    { lat: 48.165378, lng: 18.002122 },
    { lat: 48.165185, lng: 18.002395 },
    { lat: 48.164941, lng: 18.002829 },
    { lat: 48.164754, lng: 18.003175 },
    { lat: 48.164466, lng: 18.003524 },
    { lat: 48.164433, lng: 18.003561 },
    { lat: 48.16436, lng: 18.003665 },
    { lat: 48.164249, lng: 18.003796 },
    { lat: 48.163896, lng: 18.004366 },
    { lat: 48.163591, lng: 18.004919 },
    { lat: 48.163206, lng: 18.005625 },
    { lat: 48.162836, lng: 18.006282 },
    { lat: 48.162362, lng: 18.007092 },
    { lat: 48.16214, lng: 18.00747 },
    { lat: 48.161546, lng: 18.008198 },
    { lat: 48.161356, lng: 18.008423 },
    { lat: 48.16111, lng: 18.008717 },
    { lat: 48.160605, lng: 18.009307 },
    { lat: 48.160254, lng: 18.009722 },
    { lat: 48.159964, lng: 18.010073 },
    { lat: 48.159449, lng: 18.0107 },
    { lat: 48.159043, lng: 18.011214 },
    { lat: 48.158767, lng: 18.011564 },
    { lat: 48.158397, lng: 18.012043 },
    { lat: 48.15807, lng: 18.012465 },
    { lat: 48.157592, lng: 18.013065 },
    { lat: 48.15747, lng: 18.013209 },
    { lat: 48.157402, lng: 18.013297 },
    { lat: 48.1575486, lng: 18.0135475 },
    { lat: 48.1659565, lng: 18.0303793 },
    { lat: 48.1719344, lng: 18.0393275 },
    { lat: 48.1720587, lng: 18.039521 },
  ],
  MalýLapáš: [
    { lat: 48.3167928, lng: 18.151794 },
    { lat: 48.3165405, lng: 18.1514542 },
    { lat: 48.3162401, lng: 18.1508342 },
    { lat: 48.3152753, lng: 18.1519159 },
    { lat: 48.3141708, lng: 18.1536218 },
    { lat: 48.3140595, lng: 18.1538623 },
    { lat: 48.3137056, lng: 18.1550676 },
    { lat: 48.313367, lng: 18.1556874 },
    { lat: 48.3120409, lng: 18.1576169 },
    { lat: 48.3114796, lng: 18.1580567 },
    { lat: 48.31008, lng: 18.1589793 },
    { lat: 48.3098992, lng: 18.159243 },
    { lat: 48.3095422, lng: 18.1601852 },
    { lat: 48.3094712, lng: 18.1607124 },
    { lat: 48.3093893, lng: 18.1609904 },
    { lat: 48.3089569, lng: 18.1616451 },
    { lat: 48.3085268, lng: 18.1609443 },
    { lat: 48.3083236, lng: 18.1607519 },
    { lat: 48.3077517, lng: 18.160406 },
    { lat: 48.3075561, lng: 18.1602062 },
    { lat: 48.3073976, lng: 18.1599254 },
    { lat: 48.307219, lng: 18.1595564 },
    { lat: 48.3069357, lng: 18.1589717 },
    { lat: 48.306733, lng: 18.1587073 },
    { lat: 48.3047875, lng: 18.1572404 },
    { lat: 48.304201, lng: 18.1565676 },
    { lat: 48.30363, lng: 18.1556816 },
    { lat: 48.3030498, lng: 18.1542452 },
    { lat: 48.3029878, lng: 18.1541307 },
    { lat: 48.3029058, lng: 18.1542535 },
    { lat: 48.3019849, lng: 18.1558764 },
    { lat: 48.2999508, lng: 18.1600432 },
    { lat: 48.298676, lng: 18.1623339 },
    { lat: 48.2968313, lng: 18.1663456 },
    { lat: 48.2966313, lng: 18.1666514 },
    { lat: 48.2957515, lng: 18.1676674 },
    { lat: 48.2961642, lng: 18.168667 },
    { lat: 48.2969935, lng: 18.1710868 },
    { lat: 48.2977285, lng: 18.1737301 },
    { lat: 48.2988677, lng: 18.1787789 },
    { lat: 48.2991674, lng: 18.1804019 },
    { lat: 48.2998092, lng: 18.1828436 },
    { lat: 48.2997908, lng: 18.1829329 },
    { lat: 48.3006429, lng: 18.1873624 },
    { lat: 48.300942, lng: 18.1897345 },
    { lat: 48.3012322, lng: 18.1896785 },
    { lat: 48.3017559, lng: 18.1921294 },
    { lat: 48.3021195, lng: 18.1934551 },
    { lat: 48.302673, lng: 18.1951937 },
    { lat: 48.3027795, lng: 18.1951433 },
    { lat: 48.3043277, lng: 18.1938597 },
    { lat: 48.3051912, lng: 18.193007 },
    { lat: 48.3056773, lng: 18.1926147 },
    { lat: 48.3059127, lng: 18.1922962 },
    { lat: 48.3063028, lng: 18.1921553 },
    { lat: 48.3063889, lng: 18.1919856 },
    { lat: 48.3060763, lng: 18.1904707 },
    { lat: 48.3060497, lng: 18.1904014 },
    { lat: 48.3049851, lng: 18.1876339 },
    { lat: 48.3047761, lng: 18.1863437 },
    { lat: 48.3049079, lng: 18.1862237 },
    { lat: 48.3048727, lng: 18.185932 },
    { lat: 48.3055156, lng: 18.1856744 },
    { lat: 48.3053445, lng: 18.1839504 },
    { lat: 48.3052783, lng: 18.1822303 },
    { lat: 48.3055964, lng: 18.1820722 },
    { lat: 48.3056538, lng: 18.1820671 },
    { lat: 48.3059669, lng: 18.1820394 },
    { lat: 48.3062954, lng: 18.1821212 },
    { lat: 48.3068372, lng: 18.1820076 },
    { lat: 48.3071639, lng: 18.1820351 },
    { lat: 48.3076368, lng: 18.1822296 },
    { lat: 48.3082538, lng: 18.182267 },
    { lat: 48.3090061, lng: 18.1825289 },
    { lat: 48.3095577, lng: 18.1825075 },
    { lat: 48.3098666, lng: 18.1824236 },
    { lat: 48.3107, lng: 18.1824742 },
    { lat: 48.310852, lng: 18.1824282 },
    { lat: 48.310835, lng: 18.1795569 },
    { lat: 48.3107325, lng: 18.1779698 },
    { lat: 48.3107567, lng: 18.1778411 },
    { lat: 48.3104377, lng: 18.1751508 },
    { lat: 48.3106112, lng: 18.1727395 },
    { lat: 48.3113246, lng: 18.1696454 },
    { lat: 48.3122452, lng: 18.1645069 },
    { lat: 48.3135368, lng: 18.1598336 },
    { lat: 48.3140914, lng: 18.1563213 },
    { lat: 48.3140466, lng: 18.1560967 },
    { lat: 48.3143292, lng: 18.1557565 },
    { lat: 48.3167217, lng: 18.151909 },
    { lat: 48.3167928, lng: 18.151794 },
  ],
  Lehota: [
    { lat: 48.3405538, lng: 17.982748 },
    { lat: 48.3387569, lng: 17.9820879 },
    { lat: 48.3377297, lng: 17.9815032 },
    { lat: 48.3371019, lng: 17.9810273 },
    { lat: 48.3366567, lng: 17.9805498 },
    { lat: 48.3357998, lng: 17.9797908 },
    { lat: 48.3351599, lng: 17.9794144 },
    { lat: 48.3344093, lng: 17.9787177 },
    { lat: 48.3329763, lng: 17.9770496 },
    { lat: 48.3317435, lng: 17.97633 },
    { lat: 48.3312181, lng: 17.9757953 },
    { lat: 48.3303651, lng: 17.9747089 },
    { lat: 48.3290646, lng: 17.9723126 },
    { lat: 48.3286658, lng: 17.9717598 },
    { lat: 48.3285655, lng: 17.9716882 },
    { lat: 48.3283962, lng: 17.9713106 },
    { lat: 48.3282424, lng: 17.9711171 },
    { lat: 48.3279484, lng: 17.9709596 },
    { lat: 48.3275556, lng: 17.9704316 },
    { lat: 48.3270687, lng: 17.9691437 },
    { lat: 48.3258435, lng: 17.9677655 },
    { lat: 48.3253006, lng: 17.9670701 },
    { lat: 48.3237771, lng: 17.9687292 },
    { lat: 48.3223216, lng: 17.9699402 },
    { lat: 48.3218965, lng: 17.970232 },
    { lat: 48.3206646, lng: 17.9709943 },
    { lat: 48.3196206, lng: 17.9711486 },
    { lat: 48.3196188, lng: 17.9710793 },
    { lat: 48.3192952, lng: 17.9712173 },
    { lat: 48.3187032, lng: 17.9717188 },
    { lat: 48.3182418, lng: 17.9722083 },
    { lat: 48.3181547, lng: 17.9724517 },
    { lat: 48.318002, lng: 17.9725595 },
    { lat: 48.3168705, lng: 17.9731727 },
    { lat: 48.3149622, lng: 17.9740239 },
    { lat: 48.3129328, lng: 17.9758534 },
    { lat: 48.3129677, lng: 17.9768117 },
    { lat: 48.3100408, lng: 17.9784098 },
    { lat: 48.3062762, lng: 17.9809118 },
    { lat: 48.3055809, lng: 17.9809689 },
    { lat: 48.3050233, lng: 17.9808521 },
    { lat: 48.303624, lng: 17.9807835 },
    { lat: 48.3031099, lng: 17.9806992 },
    { lat: 48.3013319, lng: 17.9801841 },
    { lat: 48.3002241, lng: 17.9800288 },
    { lat: 48.2996413, lng: 17.9808293 },
    { lat: 48.2989258, lng: 17.9815127 },
    { lat: 48.29806, lng: 17.9820354 },
    { lat: 48.2973745, lng: 17.9826339 },
    { lat: 48.2960752, lng: 17.9835534 },
    { lat: 48.2954743, lng: 17.9841709 },
    { lat: 48.2925721, lng: 17.9861237 },
    { lat: 48.2923068, lng: 17.9864057 },
    { lat: 48.291744, lng: 17.9868468 },
    { lat: 48.2905122, lng: 17.9877419 },
    { lat: 48.290466, lng: 17.9875953 },
    { lat: 48.2895514, lng: 17.9888914 },
    { lat: 48.2890909, lng: 17.9898163 },
    { lat: 48.28862, lng: 17.9905675 },
    { lat: 48.287929, lng: 17.990902 },
    { lat: 48.2875094, lng: 17.9909049 },
    { lat: 48.2870584, lng: 17.9908383 },
    { lat: 48.2864389, lng: 17.9912174 },
    { lat: 48.286269, lng: 17.9926986 },
    { lat: 48.2864029, lng: 17.9944657 },
    { lat: 48.2864671, lng: 17.9963697 },
    { lat: 48.2867332, lng: 17.9981783 },
    { lat: 48.287028, lng: 17.9994259 },
    { lat: 48.2873525, lng: 18.0003445 },
    { lat: 48.2875503, lng: 18.00112 },
    { lat: 48.2877527, lng: 18.0020341 },
    { lat: 48.2878617, lng: 18.0019312 },
    { lat: 48.2889691, lng: 18.0030479 },
    { lat: 48.2890433, lng: 18.0031772 },
    { lat: 48.2891797, lng: 18.0037969 },
    { lat: 48.2929797, lng: 18.0052516 },
    { lat: 48.2935944, lng: 18.005487 },
    { lat: 48.2939142, lng: 18.0056802 },
    { lat: 48.2961789, lng: 18.0061616 },
    { lat: 48.2975478, lng: 18.006576 },
    { lat: 48.2978409, lng: 18.0067193 },
    { lat: 48.2983473, lng: 18.0071682 },
    { lat: 48.2992812, lng: 18.0077806 },
    { lat: 48.2995705, lng: 18.0081651 },
    { lat: 48.2997265, lng: 18.0082271 },
    { lat: 48.3002034, lng: 18.0079899 },
    { lat: 48.301237, lng: 18.0079477 },
    { lat: 48.3016961, lng: 18.0080546 },
    { lat: 48.3023962, lng: 18.0083166 },
    { lat: 48.3027881, lng: 18.0084633 },
    { lat: 48.3035626, lng: 18.0084853 },
    { lat: 48.3042893, lng: 18.0081777 },
    { lat: 48.3049704, lng: 18.0079797 },
    { lat: 48.3054916, lng: 18.0078832 },
    { lat: 48.3063143, lng: 18.0078605 },
    { lat: 48.3068839, lng: 18.0079092 },
    { lat: 48.3074796, lng: 18.0081144 },
    { lat: 48.3084107, lng: 18.0082087 },
    { lat: 48.3099921, lng: 18.0086096 },
    { lat: 48.3112344, lng: 18.0086388 },
    { lat: 48.3116039, lng: 18.0085137 },
    { lat: 48.3124068, lng: 18.0080292 },
    { lat: 48.3130897, lng: 18.0077844 },
    { lat: 48.3132915, lng: 18.0078159 },
    { lat: 48.3134177, lng: 18.0076596 },
    { lat: 48.3135565, lng: 18.0074928 },
    { lat: 48.3137905, lng: 18.0074781 },
    { lat: 48.3140944, lng: 18.0074472 },
    { lat: 48.3152414, lng: 18.0069026 },
    { lat: 48.3162198, lng: 18.0062428 },
    { lat: 48.3172884, lng: 18.0051171 },
    { lat: 48.317674, lng: 18.0046157 },
    { lat: 48.3178984, lng: 18.0044441 },
    { lat: 48.3188417, lng: 18.0040409 },
    { lat: 48.3197512, lng: 18.0038497 },
    { lat: 48.3206793, lng: 18.0034964 },
    { lat: 48.3210182, lng: 18.0032747 },
    { lat: 48.3215207, lng: 18.00307 },
    { lat: 48.3217819, lng: 18.0029059 },
    { lat: 48.3221456, lng: 18.0024964 },
    { lat: 48.3223783, lng: 18.00233 },
    { lat: 48.3227374, lng: 18.0022151 },
    { lat: 48.3238932, lng: 18.0016319 },
    { lat: 48.3244196, lng: 18.0012431 },
    { lat: 48.32512, lng: 18.0005543 },
    { lat: 48.3269847, lng: 17.999177 },
    { lat: 48.3280353, lng: 17.9986071 },
    { lat: 48.3291636, lng: 17.9983158 },
    { lat: 48.3292905, lng: 17.9982462 },
    { lat: 48.3298338, lng: 17.9981034 },
    { lat: 48.3312365, lng: 17.9975315 },
    { lat: 48.3318337, lng: 17.9973622 },
    { lat: 48.3336418, lng: 17.9962838 },
    { lat: 48.3340611, lng: 17.9961441 },
    { lat: 48.3347456, lng: 17.995773 },
    { lat: 48.3359737, lng: 17.994963 },
    { lat: 48.3364976, lng: 17.9948163 },
    { lat: 48.3373756, lng: 17.9944325 },
    { lat: 48.3382573, lng: 17.994325 },
    { lat: 48.3385824, lng: 17.9942284 },
    { lat: 48.3390014, lng: 17.9939688 },
    { lat: 48.3393339, lng: 17.9934544 },
    { lat: 48.3396003, lng: 17.9927909 },
    { lat: 48.3398103, lng: 17.9912067 },
    { lat: 48.3399177, lng: 17.9908321 },
    { lat: 48.3402066, lng: 17.9892081 },
    { lat: 48.3404053, lng: 17.9887369 },
    { lat: 48.3404864, lng: 17.9883729 },
    { lat: 48.3405624, lng: 17.9874176 },
    { lat: 48.3405196, lng: 17.985857 },
    { lat: 48.3405942, lng: 17.9847985 },
    { lat: 48.3407335, lng: 17.9837907 },
    { lat: 48.3407048, lng: 17.9828955 },
    { lat: 48.3406633, lng: 17.9828819 },
    { lat: 48.3405538, lng: 17.982748 },
  ],
  HornéLefantovce: [
    { lat: 48.433383, lng: 18.118421 },
    { lat: 48.433364, lng: 18.118455 },
    { lat: 48.433306, lng: 18.118383 },
    { lat: 48.433245, lng: 18.118293 },
    { lat: 48.433208, lng: 18.118219 },
    { lat: 48.433173, lng: 18.118116 },
    { lat: 48.433142, lng: 18.117983 },
    { lat: 48.433113, lng: 18.117845 },
    { lat: 48.433106, lng: 18.117745 },
    { lat: 48.433112, lng: 18.117645 },
    { lat: 48.433116, lng: 18.117568 },
    { lat: 48.433123, lng: 18.117521 },
    { lat: 48.433046, lng: 18.117421 },
    { lat: 48.432932, lng: 18.117288 },
    { lat: 48.432846, lng: 18.117159 },
    { lat: 48.432769, lng: 18.117059 },
    { lat: 48.432671, lng: 18.116919 },
    { lat: 48.432572, lng: 18.116773 },
    { lat: 48.432462, lng: 18.116635 },
    { lat: 48.432385, lng: 18.116528 },
    { lat: 48.432348, lng: 18.116478 },
    { lat: 48.43228, lng: 18.116436 },
    { lat: 48.432205, lng: 18.116406 },
    { lat: 48.432138, lng: 18.11637 },
    { lat: 48.432063, lng: 18.116334 },
    { lat: 48.431988, lng: 18.116298 },
    { lat: 48.431932, lng: 18.116267 },
    { lat: 48.431912, lng: 18.116257 },
    { lat: 48.431837, lng: 18.116209 },
    { lat: 48.431776, lng: 18.116172 },
    { lat: 48.431709, lng: 18.11613 },
    { lat: 48.431657, lng: 18.116087 },
    { lat: 48.4315991, lng: 18.1160572 },
    { lat: 48.4313767, lng: 18.1161161 },
    { lat: 48.4293954, lng: 18.1167019 },
    { lat: 48.4293171, lng: 18.116289 },
    { lat: 48.4286137, lng: 18.1160122 },
    { lat: 48.426879, lng: 18.12563 },
    { lat: 48.4268485, lng: 18.1258241 },
    { lat: 48.4270109, lng: 18.1259366 },
    { lat: 48.4259263, lng: 18.1297965 },
    { lat: 48.4256078, lng: 18.1311602 },
    { lat: 48.4227698, lng: 18.130623 },
    { lat: 48.4229362, lng: 18.1299171 },
    { lat: 48.4214044, lng: 18.1302593 },
    { lat: 48.4213931, lng: 18.1306644 },
    { lat: 48.4212723, lng: 18.1307784 },
    { lat: 48.4188821, lng: 18.1294869 },
    { lat: 48.4157917, lng: 18.1279171 },
    { lat: 48.4155687, lng: 18.1281152 },
    { lat: 48.4151598, lng: 18.1287092 },
    { lat: 48.414747, lng: 18.1290226 },
    { lat: 48.4145873, lng: 18.1289417 },
    { lat: 48.4132353, lng: 18.1282569 },
    { lat: 48.4127717, lng: 18.1301514 },
    { lat: 48.4125804, lng: 18.1307212 },
    { lat: 48.4126037, lng: 18.1308126 },
    { lat: 48.4123993, lng: 18.1312151 },
    { lat: 48.4123438, lng: 18.1311517 },
    { lat: 48.4117528, lng: 18.1321831 },
    { lat: 48.4114308, lng: 18.1329093 },
    { lat: 48.4110521, lng: 18.1334974 },
    { lat: 48.4102966, lng: 18.1340858 },
    { lat: 48.4091872, lng: 18.1343749 },
    { lat: 48.4087205, lng: 18.1346158 },
    { lat: 48.4082493, lng: 18.1349542 },
    { lat: 48.4073661, lng: 18.1350962 },
    { lat: 48.4069488, lng: 18.1348479 },
    { lat: 48.4064979, lng: 18.136208 },
    { lat: 48.4058622, lng: 18.1378474 },
    { lat: 48.4055317, lng: 18.1393351 },
    { lat: 48.4053073, lng: 18.1398978 },
    { lat: 48.4051531, lng: 18.1404835 },
    { lat: 48.4047471, lng: 18.1400643 },
    { lat: 48.404579, lng: 18.139814 },
    { lat: 48.4036654, lng: 18.1391484 },
    { lat: 48.4041702, lng: 18.1355601 },
    { lat: 48.4042159, lng: 18.1354923 },
    { lat: 48.4043001, lng: 18.1348852 },
    { lat: 48.4044696, lng: 18.1327848 },
    { lat: 48.4044463, lng: 18.1326363 },
    { lat: 48.4045002, lng: 18.1325556 },
    { lat: 48.4045455, lng: 18.1318052 },
    { lat: 48.4045073, lng: 18.1310193 },
    { lat: 48.4043297, lng: 18.130509 },
    { lat: 48.4044366, lng: 18.1303283 },
    { lat: 48.4044212, lng: 18.1302216 },
    { lat: 48.404219, lng: 18.1303517 },
    { lat: 48.4041295, lng: 18.1304828 },
    { lat: 48.403982, lng: 18.1308723 },
    { lat: 48.4039964, lng: 18.1311379 },
    { lat: 48.4038754, lng: 18.1311723 },
    { lat: 48.4039601, lng: 18.1315205 },
    { lat: 48.4038576, lng: 18.1320082 },
    { lat: 48.4037366, lng: 18.1330445 },
    { lat: 48.403497, lng: 18.1340432 },
    { lat: 48.4033472, lng: 18.1343776 },
    { lat: 48.4030365, lng: 18.1355881 },
    { lat: 48.4029176, lng: 18.1367584 },
    { lat: 48.4027712, lng: 18.1370536 },
    { lat: 48.4026896, lng: 18.1381859 },
    { lat: 48.4025917, lng: 18.1388867 },
    { lat: 48.4025728, lng: 18.1403407 },
    { lat: 48.4008546, lng: 18.1474442 },
    { lat: 48.4001591, lng: 18.1506718 },
    { lat: 48.400175, lng: 18.152858 },
    { lat: 48.4002868, lng: 18.1558043 },
    { lat: 48.4007992, lng: 18.1629386 },
    { lat: 48.4010008, lng: 18.1649069 },
    { lat: 48.4015981, lng: 18.1657689 },
    { lat: 48.4017674, lng: 18.1663688 },
    { lat: 48.4025744, lng: 18.166779 },
    { lat: 48.4027461, lng: 18.1669386 },
    { lat: 48.4028506, lng: 18.1672588 },
    { lat: 48.4031613, lng: 18.1677497 },
    { lat: 48.4033198, lng: 18.1678567 },
    { lat: 48.403417, lng: 18.1680929 },
    { lat: 48.4036783, lng: 18.1684347 },
    { lat: 48.4037365, lng: 18.1687734 },
    { lat: 48.404099, lng: 18.1697689 },
    { lat: 48.4044394, lng: 18.1701703 },
    { lat: 48.4045985, lng: 18.1704783 },
    { lat: 48.406325, lng: 18.1722444 },
    { lat: 48.4065621, lng: 18.1725773 },
    { lat: 48.4068673, lng: 18.1727835 },
    { lat: 48.4073087, lng: 18.1729405 },
    { lat: 48.407564, lng: 18.1729436 },
    { lat: 48.4078528, lng: 18.1726518 },
    { lat: 48.4082923, lng: 18.1726006 },
    { lat: 48.4086819, lng: 18.1726838 },
    { lat: 48.4092859, lng: 18.1725915 },
    { lat: 48.4097168, lng: 18.1726653 },
    { lat: 48.4105407, lng: 18.1730976 },
    { lat: 48.4110121, lng: 18.1736339 },
    { lat: 48.4112635, lng: 18.1738251 },
    { lat: 48.4116949, lng: 18.1746441 },
    { lat: 48.4117352, lng: 18.1747564 },
    { lat: 48.4117938, lng: 18.1758434 },
    { lat: 48.4120303, lng: 18.1766124 },
    { lat: 48.4125001, lng: 18.1771447 },
    { lat: 48.4127541, lng: 18.1771867 },
    { lat: 48.4131958, lng: 18.1774471 },
    { lat: 48.4134326, lng: 18.1776356 },
    { lat: 48.413822, lng: 18.1780869 },
    { lat: 48.4147804, lng: 18.1788124 },
    { lat: 48.4152772, lng: 18.1789498 },
    { lat: 48.416256, lng: 18.1788206 },
    { lat: 48.4166283, lng: 18.1789157 },
    { lat: 48.4169157, lng: 18.1788326 },
    { lat: 48.4176097, lng: 18.1791658 },
    { lat: 48.4181347, lng: 18.180512 },
    { lat: 48.418307, lng: 18.1808365 },
    { lat: 48.419188, lng: 18.1819179 },
    { lat: 48.4194283, lng: 18.1825394 },
    { lat: 48.4199949, lng: 18.1829097 },
    { lat: 48.4203333, lng: 18.18287 },
    { lat: 48.420436, lng: 18.1829112 },
    { lat: 48.4210988, lng: 18.1835602 },
    { lat: 48.4215329, lng: 18.1844278 },
    { lat: 48.4215875, lng: 18.1851944 },
    { lat: 48.4217884, lng: 18.1857223 },
    { lat: 48.4225427, lng: 18.1864691 },
    { lat: 48.4228377, lng: 18.1866337 },
    { lat: 48.423173, lng: 18.1870453 },
    { lat: 48.4242579, lng: 18.1876583 },
    { lat: 48.4245985, lng: 18.1878096 },
    { lat: 48.4252398, lng: 18.1877772 },
    { lat: 48.4255872, lng: 18.1876969 },
    { lat: 48.4258531, lng: 18.1874749 },
    { lat: 48.4264631, lng: 18.1879912 },
    { lat: 48.4267584, lng: 18.188337 },
    { lat: 48.4272955, lng: 18.1886654 },
    { lat: 48.4281418, lng: 18.1897176 },
    { lat: 48.4285315, lng: 18.1905787 },
    { lat: 48.4290441, lng: 18.1913044 },
    { lat: 48.4294825, lng: 18.191607 },
    { lat: 48.4296168, lng: 18.1918236 },
    { lat: 48.4297938, lng: 18.191976 },
    { lat: 48.429906, lng: 18.1923173 },
    { lat: 48.4299555, lng: 18.1928776 },
    { lat: 48.4300268, lng: 18.1930971 },
    { lat: 48.430229, lng: 18.1932355 },
    { lat: 48.4303909, lng: 18.1935363 },
    { lat: 48.4309333, lng: 18.1941362 },
    { lat: 48.431498, lng: 18.194244 },
    { lat: 48.431532, lng: 18.194237 },
    { lat: 48.431534, lng: 18.194262 },
    { lat: 48.431666, lng: 18.1943 },
    { lat: 48.432584, lng: 18.194461 },
    { lat: 48.432826, lng: 18.194517 },
    { lat: 48.432965, lng: 18.19448 },
    { lat: 48.433621, lng: 18.19417 },
    { lat: 48.433943, lng: 18.193954 },
    { lat: 48.434041, lng: 18.193884 },
    { lat: 48.434252, lng: 18.19369 },
    { lat: 48.434357, lng: 18.193551 },
    { lat: 48.434729, lng: 18.192985 },
    { lat: 48.435361, lng: 18.192024 },
    { lat: 48.436508, lng: 18.190463 },
    { lat: 48.437026, lng: 18.190127 },
    { lat: 48.43703, lng: 18.19018 },
    { lat: 48.437129, lng: 18.190935 },
    { lat: 48.437342, lng: 18.191197 },
    { lat: 48.437405, lng: 18.191527 },
    { lat: 48.437989, lng: 18.191958 },
    { lat: 48.43811, lng: 18.1921 },
    { lat: 48.43818, lng: 18.191827 },
    { lat: 48.438292, lng: 18.191524 },
    { lat: 48.43831, lng: 18.191441 },
    { lat: 48.438524, lng: 18.190407 },
    { lat: 48.438643, lng: 18.18981 },
    { lat: 48.438763, lng: 18.189296 },
    { lat: 48.438895, lng: 18.188775 },
    { lat: 48.43904, lng: 18.188095 },
    { lat: 48.439219, lng: 18.187567 },
    { lat: 48.439225, lng: 18.187191 },
    { lat: 48.439239, lng: 18.186645 },
    { lat: 48.439368, lng: 18.186183 },
    { lat: 48.439537, lng: 18.18552 },
    { lat: 48.4394, lng: 18.184985 },
    { lat: 48.439297, lng: 18.184596 },
    { lat: 48.439217, lng: 18.184552 },
    { lat: 48.43909, lng: 18.184134 },
    { lat: 48.438913, lng: 18.183543 },
    { lat: 48.438854, lng: 18.183263 },
    { lat: 48.439052, lng: 18.182482 },
    { lat: 48.439083, lng: 18.182216 },
    { lat: 48.439093, lng: 18.182093 },
    { lat: 48.439124, lng: 18.181846 },
    { lat: 48.439255, lng: 18.18153 },
    { lat: 48.439354, lng: 18.181087 },
    { lat: 48.439407, lng: 18.180246 },
    { lat: 48.439419, lng: 18.180052 },
    { lat: 48.439442, lng: 18.179576 },
    { lat: 48.439464, lng: 18.179217 },
    { lat: 48.439475, lng: 18.179182 },
    { lat: 48.439674, lng: 18.178471 },
    { lat: 48.439855, lng: 18.177837 },
    { lat: 48.440048, lng: 18.177472 },
    { lat: 48.440207, lng: 18.177173 },
    { lat: 48.440542, lng: 18.176433 },
    { lat: 48.440741, lng: 18.176239 },
    { lat: 48.44075, lng: 18.176242 },
    { lat: 48.440858, lng: 18.175908 },
    { lat: 48.440979, lng: 18.175543 },
    { lat: 48.441008, lng: 18.174881 },
    { lat: 48.441031, lng: 18.174447 },
    { lat: 48.441031, lng: 18.173854 },
    { lat: 48.44103, lng: 18.173409 },
    { lat: 48.441029, lng: 18.172986 },
    { lat: 48.441134, lng: 18.172522 },
    { lat: 48.441156, lng: 18.172306 },
    { lat: 48.441214, lng: 18.171789 },
    { lat: 48.441272, lng: 18.17132 },
    { lat: 48.441399, lng: 18.170915 },
    { lat: 48.441485, lng: 18.170645 },
    { lat: 48.441544, lng: 18.170445 },
    { lat: 48.4415, lng: 18.170257 },
    { lat: 48.441499, lng: 18.169953 },
    { lat: 48.44146, lng: 18.169518 },
    { lat: 48.441455, lng: 18.169231 },
    { lat: 48.441447, lng: 18.168826 },
    { lat: 48.441396, lng: 18.168522 },
    { lat: 48.441349, lng: 18.168217 },
    { lat: 48.441294, lng: 18.167889 },
    { lat: 48.441152, lng: 18.167186 },
    { lat: 48.441069, lng: 18.166787 },
    { lat: 48.441034, lng: 18.166154 },
    { lat: 48.440985, lng: 18.165497 },
    { lat: 48.440993, lng: 18.165163 },
    { lat: 48.441028, lng: 18.164846 },
    { lat: 48.441089, lng: 18.164429 },
    { lat: 48.441088, lng: 18.164048 },
    { lat: 48.441123, lng: 18.163896 },
    { lat: 48.441162, lng: 18.16369 },
    { lat: 48.441309, lng: 18.163203 },
    { lat: 48.441328, lng: 18.162874 },
    { lat: 48.441351, lng: 18.162604 },
    { lat: 48.441495, lng: 18.162439 },
    { lat: 48.441604, lng: 18.162322 },
    { lat: 48.441749, lng: 18.162145 },
    { lat: 48.44188, lng: 18.162004 },
    { lat: 48.44209, lng: 18.161757 },
    { lat: 48.442238, lng: 18.161457 },
    { lat: 48.4423, lng: 18.161369 },
    { lat: 48.442521, lng: 18.161057 },
    { lat: 48.442603, lng: 18.160769 },
    { lat: 48.442654, lng: 18.160534 },
    { lat: 48.4427, lng: 18.160182 },
    { lat: 48.442711, lng: 18.159872 },
    { lat: 48.442683, lng: 18.159467 },
    { lat: 48.442655, lng: 18.159027 },
    { lat: 48.442678, lng: 18.158764 },
    { lat: 48.4427, lng: 18.15847 },
    { lat: 48.442743, lng: 18.158229 },
    { lat: 48.442789, lng: 18.157942 },
    { lat: 48.442824, lng: 18.157672 },
    { lat: 48.442852, lng: 18.157519 },
    { lat: 48.442945, lng: 18.15712 },
    { lat: 48.443072, lng: 18.156597 },
    { lat: 48.443118, lng: 18.156392 },
    { lat: 48.443211, lng: 18.155952 },
    { lat: 48.443301, lng: 18.155693 },
    { lat: 48.443261, lng: 18.155389 },
    { lat: 48.443323, lng: 18.155054 },
    { lat: 48.443368, lng: 18.154408 },
    { lat: 48.443399, lng: 18.153816 },
    { lat: 48.443425, lng: 18.153476 },
    { lat: 48.443475, lng: 18.153095 },
    { lat: 48.443491, lng: 18.152795 },
    { lat: 48.443502, lng: 18.152484 },
    { lat: 48.443509, lng: 18.152338 },
    { lat: 48.443551, lng: 18.151997 },
    { lat: 48.443547, lng: 18.151762 },
    { lat: 48.443546, lng: 18.151557 },
    { lat: 48.443573, lng: 18.15124 },
    { lat: 48.4436, lng: 18.150959 },
    { lat: 48.443615, lng: 18.150842 },
    { lat: 48.443643, lng: 18.150677 },
    { lat: 48.443688, lng: 18.150413 },
    { lat: 48.443711, lng: 18.150224 },
    { lat: 48.443725, lng: 18.150142 },
    { lat: 48.443737, lng: 18.150024 },
    { lat: 48.44374, lng: 18.149878 },
    { lat: 48.443758, lng: 18.149713 },
    { lat: 48.443764, lng: 18.14959 },
    { lat: 48.443778, lng: 18.149344 },
    { lat: 48.443778, lng: 18.149215 },
    { lat: 48.443776, lng: 18.149098 },
    { lat: 48.443788, lng: 18.148986 },
    { lat: 48.443787, lng: 18.148904 },
    { lat: 48.443799, lng: 18.148857 },
    { lat: 48.443817, lng: 18.148763 },
    { lat: 48.443824, lng: 18.148698 },
    { lat: 48.443828, lng: 18.148645 },
    { lat: 48.443827, lng: 18.148575 },
    { lat: 48.443818, lng: 18.148428 },
    { lat: 48.443851, lng: 18.148018 },
    { lat: 48.443851, lng: 18.147953 },
    { lat: 48.443865, lng: 18.147847 },
    { lat: 48.443872, lng: 18.147736 },
    { lat: 48.443886, lng: 18.147442 },
    { lat: 48.44389, lng: 18.147343 },
    { lat: 48.443915, lng: 18.147196 },
    { lat: 48.443941, lng: 18.147066 },
    { lat: 48.443952, lng: 18.146985 },
    { lat: 48.443971, lng: 18.14682 },
    { lat: 48.443959, lng: 18.146785 },
    { lat: 48.44398, lng: 18.14645 },
    { lat: 48.443983, lng: 18.146298 },
    { lat: 48.443998, lng: 18.146175 },
    { lat: 48.444015, lng: 18.14594 },
    { lat: 48.444034, lng: 18.145757 },
    { lat: 48.444053, lng: 18.145611 },
    { lat: 48.444067, lng: 18.145505 },
    { lat: 48.444079, lng: 18.145417 },
    { lat: 48.444094, lng: 18.145346 },
    { lat: 48.44412, lng: 18.145228 },
    { lat: 48.444172, lng: 18.145028 },
    { lat: 48.444199, lng: 18.144811 },
    { lat: 48.444217, lng: 18.144635 },
    { lat: 48.444224, lng: 18.144541 },
    { lat: 48.444311, lng: 18.144158 },
    { lat: 48.444394, lng: 18.143794 },
    { lat: 48.444458, lng: 18.143529 },
    { lat: 48.444499, lng: 18.143282 },
    { lat: 48.444522, lng: 18.143047 },
    { lat: 48.444527, lng: 18.142754 },
    { lat: 48.444534, lng: 18.142619 },
    { lat: 48.444555, lng: 18.142366 },
    { lat: 48.444581, lng: 18.142108 },
    { lat: 48.44461, lng: 18.141832 },
    { lat: 48.444647, lng: 18.141567 },
    { lat: 48.444707, lng: 18.141133 },
    { lat: 48.444733, lng: 18.14098 },
    { lat: 48.444777, lng: 18.140733 },
    { lat: 48.444781, lng: 18.140692 },
    { lat: 48.444788, lng: 18.140581 },
    { lat: 48.444792, lng: 18.140504 },
    { lat: 48.444881, lng: 18.139958 },
    { lat: 48.444938, lng: 18.139675 },
    { lat: 48.444997, lng: 18.139304 },
    { lat: 48.445065, lng: 18.138928 },
    { lat: 48.44511, lng: 18.138676 },
    { lat: 48.445185, lng: 18.138264 },
    { lat: 48.445257, lng: 18.137887 },
    { lat: 48.445312, lng: 18.137588 },
    { lat: 48.445376, lng: 18.137287 },
    { lat: 48.445432, lng: 18.136858 },
    { lat: 48.445499, lng: 18.13647 },
    { lat: 48.445537, lng: 18.136235 },
    { lat: 48.445601, lng: 18.135853 },
    { lat: 48.445634, lng: 18.135665 },
    { lat: 48.445664, lng: 18.135465 },
    { lat: 48.44572, lng: 18.135118 },
    { lat: 48.445768, lng: 18.13476 },
    { lat: 48.445775, lng: 18.134731 },
    { lat: 48.445806, lng: 18.134742 },
    { lat: 48.446006, lng: 18.134826 },
    { lat: 48.446194, lng: 18.134905 },
    { lat: 48.446256, lng: 18.134939 },
    { lat: 48.446287, lng: 18.134956 },
    { lat: 48.446487, lng: 18.135047 },
    { lat: 48.446678, lng: 18.135132 },
    { lat: 48.446819, lng: 18.135194 },
    { lat: 48.446967, lng: 18.135256 },
    { lat: 48.447164, lng: 18.135352 },
    { lat: 48.447193, lng: 18.135082 },
    { lat: 48.447211, lng: 18.134906 },
    { lat: 48.447232, lng: 18.134677 },
    { lat: 48.447111, lng: 18.134643 },
    { lat: 48.447129, lng: 18.13445 },
    { lat: 48.447156, lng: 18.134314 },
    { lat: 48.447171, lng: 18.134179 },
    { lat: 48.447224, lng: 18.133926 },
    { lat: 48.447249, lng: 18.133762 },
    { lat: 48.447324, lng: 18.133338 },
    { lat: 48.447387, lng: 18.13298 },
    { lat: 48.447429, lng: 18.132745 },
    { lat: 48.44746, lng: 18.132615 },
    { lat: 48.447519, lng: 18.132262 },
    { lat: 48.447564, lng: 18.132003 },
    { lat: 48.447586, lng: 18.131845 },
    { lat: 48.447608, lng: 18.131704 },
    { lat: 48.447635, lng: 18.131574 },
    { lat: 48.447646, lng: 18.13148 },
    { lat: 48.44768, lng: 18.13138 },
    { lat: 48.447349, lng: 18.131418 },
    { lat: 48.447102, lng: 18.131243 },
    { lat: 48.444389, lng: 18.130759 },
    { lat: 48.440772, lng: 18.129182 },
    { lat: 48.440702, lng: 18.12908 },
    { lat: 48.437565, lng: 18.124518 },
    { lat: 48.437448, lng: 18.124347 },
    { lat: 48.433401, lng: 18.118448 },
    { lat: 48.433383, lng: 18.118421 },
  ],
  Alekšince: [
    { lat: 48.3407048, lng: 17.9828955 },
    { lat: 48.3410723, lng: 17.9833012 },
    { lat: 48.341354, lng: 17.9837493 },
    { lat: 48.3417611, lng: 17.9840056 },
    { lat: 48.3422442, lng: 17.9846282 },
    { lat: 48.3424898, lng: 17.9848477 },
    { lat: 48.3428264, lng: 17.9849631 },
    { lat: 48.3433458, lng: 17.9850031 },
    { lat: 48.3443133, lng: 17.9846891 },
    { lat: 48.344532, lng: 17.9847944 },
    { lat: 48.3449646, lng: 17.9851779 },
    { lat: 48.3457016, lng: 17.9850631 },
    { lat: 48.3461003, lng: 17.9848997 },
    { lat: 48.3463507, lng: 17.984954 },
    { lat: 48.3469384, lng: 17.9853237 },
    { lat: 48.3476613, lng: 17.985314 },
    { lat: 48.3478335, lng: 17.9851553 },
    { lat: 48.3479932, lng: 17.984879 },
    { lat: 48.3485921, lng: 17.9843264 },
    { lat: 48.3494186, lng: 17.983163 },
    { lat: 48.3495517, lng: 17.9830666 },
    { lat: 48.3509132, lng: 17.982522 },
    { lat: 48.351175, lng: 17.9825211 },
    { lat: 48.351746, lng: 17.9823878 },
    { lat: 48.351869, lng: 17.9824234 },
    { lat: 48.3520874, lng: 17.9826221 },
    { lat: 48.3526793, lng: 17.9834232 },
    { lat: 48.3532927, lng: 17.9838629 },
    { lat: 48.3543162, lng: 17.9849919 },
    { lat: 48.3553707, lng: 17.9856378 },
    { lat: 48.3567357, lng: 17.9870779 },
    { lat: 48.3570317, lng: 17.9874785 },
    { lat: 48.35759, lng: 17.9871716 },
    { lat: 48.3577264, lng: 17.9871521 },
    { lat: 48.3578056, lng: 17.9869654 },
    { lat: 48.3582592, lng: 17.986509 },
    { lat: 48.3584052, lng: 17.986495 },
    { lat: 48.3586969, lng: 17.9862232 },
    { lat: 48.3589429, lng: 17.986144 },
    { lat: 48.359097, lng: 17.9860029 },
    { lat: 48.3591457, lng: 17.9857983 },
    { lat: 48.3594993, lng: 17.9854849 },
    { lat: 48.3598662, lng: 17.9852967 },
    { lat: 48.3603275, lng: 17.9847806 },
    { lat: 48.3619602, lng: 17.9831871 },
    { lat: 48.3627569, lng: 17.9825594 },
    { lat: 48.3641752, lng: 17.9810979 },
    { lat: 48.3646514, lng: 17.9804562 },
    { lat: 48.3650093, lng: 17.9798368 },
    { lat: 48.3650448, lng: 17.9796947 },
    { lat: 48.365222, lng: 17.9796005 },
    { lat: 48.3653293, lng: 17.9794334 },
    { lat: 48.3639874, lng: 17.9774353 },
    { lat: 48.3643601, lng: 17.9754796 },
    { lat: 48.364683, lng: 17.974351 },
    { lat: 48.3648309, lng: 17.9731643 },
    { lat: 48.3649607, lng: 17.9724649 },
    { lat: 48.365036, lng: 17.9723078 },
    { lat: 48.3653413, lng: 17.9720341 },
    { lat: 48.3656489, lng: 17.9718744 },
    { lat: 48.3660841, lng: 17.9717758 },
    { lat: 48.3666145, lng: 17.9717738 },
    { lat: 48.3668984, lng: 17.9716492 },
    { lat: 48.3692803, lng: 17.9695627 },
    { lat: 48.3742047, lng: 17.9642862 },
    { lat: 48.3754771, lng: 17.962562 },
    { lat: 48.3756011, lng: 17.962618 },
    { lat: 48.3759535, lng: 17.9629762 },
    { lat: 48.3766239, lng: 17.9638066 },
    { lat: 48.3771417, lng: 17.9626153 },
    { lat: 48.3776164, lng: 17.9609683 },
    { lat: 48.3776162, lng: 17.9609485 },
    { lat: 48.3774631, lng: 17.9608211 },
    { lat: 48.3772096, lng: 17.9608504 },
    { lat: 48.376416, lng: 17.9601319 },
    { lat: 48.3761475, lng: 17.959722 },
    { lat: 48.3760163, lng: 17.9596037 },
    { lat: 48.3756497, lng: 17.9590604 },
    { lat: 48.3755854, lng: 17.958762 },
    { lat: 48.3753756, lng: 17.9583331 },
    { lat: 48.375168, lng: 17.9581648 },
    { lat: 48.3750494, lng: 17.9578979 },
    { lat: 48.3750437, lng: 17.9578032 },
    { lat: 48.3751343, lng: 17.9576425 },
    { lat: 48.3748421, lng: 17.9570767 },
    { lat: 48.3748855, lng: 17.9568624 },
    { lat: 48.3747043, lng: 17.9564574 },
    { lat: 48.3742684, lng: 17.955965 },
    { lat: 48.374086, lng: 17.9558782 },
    { lat: 48.3738467, lng: 17.9554448 },
    { lat: 48.3732703, lng: 17.9551347 },
    { lat: 48.3730841, lng: 17.9551344 },
    { lat: 48.3726933, lng: 17.9549235 },
    { lat: 48.3725837, lng: 17.9549983 },
    { lat: 48.3723442, lng: 17.9548607 },
    { lat: 48.3722162, lng: 17.9546479 },
    { lat: 48.371895, lng: 17.9545618 },
    { lat: 48.3718726, lng: 17.9534753 },
    { lat: 48.372352, lng: 17.9529642 },
    { lat: 48.3726724, lng: 17.951494 },
    { lat: 48.3729421, lng: 17.9505142 },
    { lat: 48.3736881, lng: 17.9482233 },
    { lat: 48.3734626, lng: 17.9478338 },
    { lat: 48.3736761, lng: 17.9466365 },
    { lat: 48.3743519, lng: 17.944882 },
    { lat: 48.3746752, lng: 17.9444018 },
    { lat: 48.3753514, lng: 17.9431264 },
    { lat: 48.3766748, lng: 17.9404346 },
    { lat: 48.376849, lng: 17.9395397 },
    { lat: 48.3769129, lng: 17.9386721 },
    { lat: 48.37712, lng: 17.9372178 },
    { lat: 48.3771756, lng: 17.936455 },
    { lat: 48.3770125, lng: 17.9350949 },
    { lat: 48.3767379, lng: 17.9342467 },
    { lat: 48.3765062, lng: 17.9339074 },
    { lat: 48.3756634, lng: 17.9336665 },
    { lat: 48.3740506, lng: 17.9333905 },
    { lat: 48.3735188, lng: 17.9332209 },
    { lat: 48.3732465, lng: 17.9330669 },
    { lat: 48.3729702, lng: 17.9328456 },
    { lat: 48.3720473, lng: 17.931843 },
    { lat: 48.3722644, lng: 17.9314727 },
    { lat: 48.3724681, lng: 17.9311248 },
    { lat: 48.3709981, lng: 17.929866 },
    { lat: 48.3706567, lng: 17.9296887 },
    { lat: 48.3694005, lng: 17.9292804 },
    { lat: 48.3682424, lng: 17.9289979 },
    { lat: 48.3673899, lng: 17.9288645 },
    { lat: 48.3673685, lng: 17.9290165 },
    { lat: 48.367337, lng: 17.9290144 },
    { lat: 48.3649113, lng: 17.928438 },
    { lat: 48.364022, lng: 17.9281222 },
    { lat: 48.364009, lng: 17.9281694 },
    { lat: 48.3619776, lng: 17.9264551 },
    { lat: 48.3613915, lng: 17.9258514 },
    { lat: 48.3601111, lng: 17.9245155 },
    { lat: 48.3600408, lng: 17.9242865 },
    { lat: 48.3589509, lng: 17.9229315 },
    { lat: 48.3578205, lng: 17.9246263 },
    { lat: 48.3573125, lng: 17.9251834 },
    { lat: 48.3498039, lng: 17.9322041 },
    { lat: 48.3483809, lng: 17.9340643 },
    { lat: 48.3460397, lng: 17.9374709 },
    { lat: 48.3444866, lng: 17.9396114 },
    { lat: 48.3417201, lng: 17.9432485 },
    { lat: 48.3416924, lng: 17.9432004 },
    { lat: 48.3412153, lng: 17.9440141 },
    { lat: 48.3394728, lng: 17.9465892 },
    { lat: 48.3389535, lng: 17.9471126 },
    { lat: 48.3347945, lng: 17.952036 },
    { lat: 48.3339482, lng: 17.952944 },
    { lat: 48.3324935, lng: 17.953774 },
    { lat: 48.332061, lng: 17.9541642 },
    { lat: 48.3316627, lng: 17.9546533 },
    { lat: 48.3331078, lng: 17.9572138 },
    { lat: 48.3337594, lng: 17.9590992 },
    { lat: 48.3339827, lng: 17.9598463 },
    { lat: 48.3341634, lng: 17.9606927 },
    { lat: 48.3355796, lng: 17.9647286 },
    { lat: 48.336384, lng: 17.9680906 },
    { lat: 48.3373672, lng: 17.9730399 },
    { lat: 48.3376777, lng: 17.9749446 },
    { lat: 48.3382741, lng: 17.9764039 },
    { lat: 48.3392374, lng: 17.9791779 },
    { lat: 48.3395881, lng: 17.9799281 },
    { lat: 48.3405537, lng: 17.9827108 },
    { lat: 48.3405538, lng: 17.982748 },
    { lat: 48.3406633, lng: 17.9828819 },
    { lat: 48.3407048, lng: 17.9828955 },
  ],
  Vráble: [
    { lat: 48.2748794, lng: 18.3477346 },
    { lat: 48.274807, lng: 18.347554 },
    { lat: 48.2742939, lng: 18.346465 },
    { lat: 48.2740523, lng: 18.3461242 },
    { lat: 48.2735321, lng: 18.3455643 },
    { lat: 48.2737989, lng: 18.3442036 },
    { lat: 48.2737481, lng: 18.3427506 },
    { lat: 48.2742823, lng: 18.3346135 },
    { lat: 48.2744869, lng: 18.3321411 },
    { lat: 48.2746155, lng: 18.3313873 },
    { lat: 48.2751321, lng: 18.3291425 },
    { lat: 48.2757763, lng: 18.325529 },
    { lat: 48.2758972, lng: 18.3253935 },
    { lat: 48.2762329, lng: 18.3244545 },
    { lat: 48.2755817, lng: 18.324055 },
    { lat: 48.2757463, lng: 18.3235572 },
    { lat: 48.2761666, lng: 18.3231688 },
    { lat: 48.2762623, lng: 18.3223533 },
    { lat: 48.2758593, lng: 18.3220109 },
    { lat: 48.2758655, lng: 18.3214689 },
    { lat: 48.2758876, lng: 18.3205902 },
    { lat: 48.2760494, lng: 18.3191517 },
    { lat: 48.2760486, lng: 18.3186024 },
    { lat: 48.2759859, lng: 18.3182152 },
    { lat: 48.276181, lng: 18.3174815 },
    { lat: 48.2761796, lng: 18.3172366 },
    { lat: 48.2760907, lng: 18.316904 },
    { lat: 48.2765042, lng: 18.315825 },
    { lat: 48.2762204, lng: 18.3156173 },
    { lat: 48.2761224, lng: 18.315225 },
    { lat: 48.2761939, lng: 18.3147734 },
    { lat: 48.2761898, lng: 18.3146815 },
    { lat: 48.2760918, lng: 18.3145669 },
    { lat: 48.2760938, lng: 18.3141462 },
    { lat: 48.2762366, lng: 18.3139806 },
    { lat: 48.2763655, lng: 18.3140428 },
    { lat: 48.2763954, lng: 18.3139383 },
    { lat: 48.276093, lng: 18.3137181 },
    { lat: 48.2760275, lng: 18.3138951 },
    { lat: 48.2759391, lng: 18.3139461 },
    { lat: 48.2758265, lng: 18.3141485 },
    { lat: 48.2757115, lng: 18.3141725 },
    { lat: 48.2756451, lng: 18.313956 },
    { lat: 48.2755231, lng: 18.3138537 },
    { lat: 48.2754293, lng: 18.313655 },
    { lat: 48.2754284, lng: 18.3134819 },
    { lat: 48.2753361, lng: 18.3131693 },
    { lat: 48.27512, lng: 18.3130191 },
    { lat: 48.2749298, lng: 18.3124704 },
    { lat: 48.2749621, lng: 18.3123437 },
    { lat: 48.274824, lng: 18.3122197 },
    { lat: 48.274608, lng: 18.3122084 },
    { lat: 48.274426, lng: 18.3123627 },
    { lat: 48.2743314, lng: 18.3123667 },
    { lat: 48.2741237, lng: 18.3120089 },
    { lat: 48.2740121, lng: 18.311929 },
    { lat: 48.2738022, lng: 18.3119523 },
    { lat: 48.2735463, lng: 18.3120754 },
    { lat: 48.2733761, lng: 18.3118184 },
    { lat: 48.2732689, lng: 18.3117581 },
    { lat: 48.2727888, lng: 18.3118107 },
    { lat: 48.272704, lng: 18.3119434 },
    { lat: 48.2725262, lng: 18.3118153 },
    { lat: 48.2724715, lng: 18.3088388 },
    { lat: 48.2725454, lng: 18.3077009 },
    { lat: 48.2720756, lng: 18.3075355 },
    { lat: 48.2716713, lng: 18.3075104 },
    { lat: 48.2716357, lng: 18.3054831 },
    { lat: 48.271709, lng: 18.3035439 },
    { lat: 48.2713714, lng: 18.3034207 },
    { lat: 48.2716244, lng: 18.3016068 },
    { lat: 48.2714714, lng: 18.3005306 },
    { lat: 48.2714393, lng: 18.3005808 },
    { lat: 48.2711465, lng: 18.3006098 },
    { lat: 48.2709748, lng: 18.2992839 },
    { lat: 48.2709404, lng: 18.2992525 },
    { lat: 48.2708308, lng: 18.2991922 },
    { lat: 48.2704961, lng: 18.2978949 },
    { lat: 48.2696739, lng: 18.2939942 },
    { lat: 48.2691973, lng: 18.2920352 },
    { lat: 48.2691776, lng: 18.2919644 },
    { lat: 48.2690121, lng: 18.291361 },
    { lat: 48.2690078, lng: 18.2913438 },
    { lat: 48.2684088, lng: 18.2916236 },
    { lat: 48.2680809, lng: 18.291711 },
    { lat: 48.2679751, lng: 18.2916747 },
    { lat: 48.2676353, lng: 18.2896795 },
    { lat: 48.267385, lng: 18.2885819 },
    { lat: 48.2671861, lng: 18.2878825 },
    { lat: 48.2669543, lng: 18.2872869 },
    { lat: 48.2666533, lng: 18.2868152 },
    { lat: 48.2665062, lng: 18.2869475 },
    { lat: 48.2650794, lng: 18.2864455 },
    { lat: 48.2645734, lng: 18.2861769 },
    { lat: 48.2626738, lng: 18.2845912 },
    { lat: 48.2615651, lng: 18.2837521 },
    { lat: 48.2614028, lng: 18.2834216 },
    { lat: 48.2614851, lng: 18.2832029 },
    { lat: 48.260304, lng: 18.2829117 },
    { lat: 48.2594805, lng: 18.282248 },
    { lat: 48.2588577, lng: 18.2814815 },
    { lat: 48.258716, lng: 18.281175 },
    { lat: 48.2586423, lng: 18.2807135 },
    { lat: 48.2585609, lng: 18.2806347 },
    { lat: 48.2569604, lng: 18.2797974 },
    { lat: 48.2558194, lng: 18.2790646 },
    { lat: 48.2553004, lng: 18.2784536 },
    { lat: 48.2549756, lng: 18.2782617 },
    { lat: 48.2548995, lng: 18.2779978 },
    { lat: 48.2547908, lng: 18.2772399 },
    { lat: 48.2546963, lng: 18.2771667 },
    { lat: 48.2545679, lng: 18.2771914 },
    { lat: 48.2544664, lng: 18.2769187 },
    { lat: 48.254352, lng: 18.2763797 },
    { lat: 48.2541324, lng: 18.2747149 },
    { lat: 48.2533944, lng: 18.2717065 },
    { lat: 48.2535843, lng: 18.2713167 },
    { lat: 48.253462, lng: 18.2708786 },
    { lat: 48.2536051, lng: 18.2706204 },
    { lat: 48.2531843, lng: 18.2695241 },
    { lat: 48.2530546, lng: 18.2691862 },
    { lat: 48.2529735, lng: 18.2689182 },
    { lat: 48.2526585, lng: 18.267877 },
    { lat: 48.2523051, lng: 18.2667092 },
    { lat: 48.2521309, lng: 18.2659105 },
    { lat: 48.2520607, lng: 18.2653815 },
    { lat: 48.2518958, lng: 18.2628242 },
    { lat: 48.2516269, lng: 18.2628405 },
    { lat: 48.2515948, lng: 18.2627515 },
    { lat: 48.2515518, lng: 18.2626317 },
    { lat: 48.2515381, lng: 18.262374 },
    { lat: 48.2514422, lng: 18.2607832 },
    { lat: 48.2513289, lng: 18.260264 },
    { lat: 48.2512123, lng: 18.2600248 },
    { lat: 48.2509553, lng: 18.2589282 },
    { lat: 48.2505637, lng: 18.2569469 },
    { lat: 48.2499267, lng: 18.2540948 },
    { lat: 48.2491722, lng: 18.2512987 },
    { lat: 48.2490664, lng: 18.2504595 },
    { lat: 48.2488402, lng: 18.2507071 },
    { lat: 48.2485934, lng: 18.2509256 },
    { lat: 48.2482406, lng: 18.2511109 },
    { lat: 48.2471704, lng: 18.2515738 },
    { lat: 48.2468051, lng: 18.251616 },
    { lat: 48.2462045, lng: 18.2515335 },
    { lat: 48.2461846, lng: 18.2519141 },
    { lat: 48.2449516, lng: 18.2517593 },
    { lat: 48.2445184, lng: 18.2535455 },
    { lat: 48.2432196, lng: 18.2532338 },
    { lat: 48.2421484, lng: 18.2528549 },
    { lat: 48.2405363, lng: 18.2525625 },
    { lat: 48.2376885, lng: 18.2524514 },
    { lat: 48.2363336, lng: 18.2524765 },
    { lat: 48.2356269, lng: 18.2527433 },
    { lat: 48.2347143, lng: 18.253295 },
    { lat: 48.2332451, lng: 18.2544309 },
    { lat: 48.2313295, lng: 18.2560469 },
    { lat: 48.231296, lng: 18.2562483 },
    { lat: 48.2308217, lng: 18.2585161 },
    { lat: 48.2301747, lng: 18.2608778 },
    { lat: 48.2291629, lng: 18.2639858 },
    { lat: 48.22882, lng: 18.2647536 },
    { lat: 48.228335, lng: 18.2663449 },
    { lat: 48.2276031, lng: 18.2679225 },
    { lat: 48.2273634, lng: 18.26878 },
    { lat: 48.2273446, lng: 18.2699052 },
    { lat: 48.2274572, lng: 18.2716668 },
    { lat: 48.2281026, lng: 18.2745394 },
    { lat: 48.2280903, lng: 18.2748371 },
    { lat: 48.2277788, lng: 18.2760701 },
    { lat: 48.227382, lng: 18.2768437 },
    { lat: 48.2272231, lng: 18.2772597 },
    { lat: 48.2269498, lng: 18.2781632 },
    { lat: 48.2268826, lng: 18.2785459 },
    { lat: 48.2268817, lng: 18.2810277 },
    { lat: 48.2267304, lng: 18.2824013 },
    { lat: 48.2269668, lng: 18.2824329 },
    { lat: 48.2262907, lng: 18.2854387 },
    { lat: 48.2260707, lng: 18.2867985 },
    { lat: 48.2259142, lng: 18.2881027 },
    { lat: 48.2253636, lng: 18.2880459 },
    { lat: 48.2250648, lng: 18.2900987 },
    { lat: 48.2245229, lng: 18.2931256 },
    { lat: 48.2242633, lng: 18.2950385 },
    { lat: 48.2241824, lng: 18.2953397 },
    { lat: 48.224201, lng: 18.2954294 },
    { lat: 48.2245088, lng: 18.2956022 },
    { lat: 48.2243972, lng: 18.296689 },
    { lat: 48.2243626, lng: 18.296675 },
    { lat: 48.2242728, lng: 18.2976518 },
    { lat: 48.2243249, lng: 18.2976734 },
    { lat: 48.2242039, lng: 18.2979632 },
    { lat: 48.2240315, lng: 18.2978671 },
    { lat: 48.2239665, lng: 18.2979348 },
    { lat: 48.2240323, lng: 18.2981387 },
    { lat: 48.224154, lng: 18.2982565 },
    { lat: 48.2242182, lng: 18.2984698 },
    { lat: 48.2241216, lng: 18.2987532 },
    { lat: 48.2237026, lng: 18.298728 },
    { lat: 48.2236636, lng: 18.2986573 },
    { lat: 48.2236592, lng: 18.2981869 },
    { lat: 48.2233976, lng: 18.297892 },
    { lat: 48.2230599, lng: 18.297867 },
    { lat: 48.2228306, lng: 18.2977269 },
    { lat: 48.2226105, lng: 18.2977806 },
    { lat: 48.2225188, lng: 18.297736 },
    { lat: 48.2225183, lng: 18.2976109 },
    { lat: 48.2227576, lng: 18.2968641 },
    { lat: 48.2229333, lng: 18.2965429 },
    { lat: 48.2228843, lng: 18.2962187 },
    { lat: 48.2227663, lng: 18.2960484 },
    { lat: 48.2225406, lng: 18.2962238 },
    { lat: 48.2218813, lng: 18.2961972 },
    { lat: 48.2215106, lng: 18.2963848 },
    { lat: 48.2214515, lng: 18.2964596 },
    { lat: 48.2214938, lng: 18.2968225 },
    { lat: 48.2216336, lng: 18.297174 },
    { lat: 48.2216142, lng: 18.2973113 },
    { lat: 48.2213715, lng: 18.2976223 },
    { lat: 48.221224, lng: 18.2976695 },
    { lat: 48.2209753, lng: 18.2974218 },
    { lat: 48.220652, lng: 18.2972127 },
    { lat: 48.2206558, lng: 18.2970643 },
    { lat: 48.2208319, lng: 18.2964152 },
    { lat: 48.2208396, lng: 18.2958028 },
    { lat: 48.2209362, lng: 18.2953584 },
    { lat: 48.2209234, lng: 18.294564 },
    { lat: 48.2206498, lng: 18.2940428 },
    { lat: 48.2204164, lng: 18.2938686 },
    { lat: 48.2202911, lng: 18.2936879 },
    { lat: 48.2200734, lng: 18.2935941 },
    { lat: 48.2199177, lng: 18.293711 },
    { lat: 48.2198375, lng: 18.2939255 },
    { lat: 48.219708, lng: 18.2939881 },
    { lat: 48.219369, lng: 18.2939194 },
    { lat: 48.2192169, lng: 18.293827 },
    { lat: 48.2191035, lng: 18.2940348 },
    { lat: 48.2191883, lng: 18.294274 },
    { lat: 48.2190186, lng: 18.2947005 },
    { lat: 48.2190146, lng: 18.2950428 },
    { lat: 48.2190657, lng: 18.2953239 },
    { lat: 48.2190196, lng: 18.2955442 },
    { lat: 48.2189342, lng: 18.2956507 },
    { lat: 48.2187629, lng: 18.2957108 },
    { lat: 48.21859, lng: 18.2956676 },
    { lat: 48.2181527, lng: 18.2952226 },
    { lat: 48.2180126, lng: 18.2952579 },
    { lat: 48.2178372, lng: 18.2955652 },
    { lat: 48.2177617, lng: 18.2958239 },
    { lat: 48.2177213, lng: 18.2962423 },
    { lat: 48.217493, lng: 18.2966692 },
    { lat: 48.2171675, lng: 18.2969352 },
    { lat: 48.2169592, lng: 18.2969793 },
    { lat: 48.2166494, lng: 18.2969507 },
    { lat: 48.2166048, lng: 18.2968439 },
    { lat: 48.2163575, lng: 18.2979804 },
    { lat: 48.216005, lng: 18.2979944 },
    { lat: 48.2161041, lng: 18.2982665 },
    { lat: 48.2162072, lng: 18.2985491 },
    { lat: 48.2161271, lng: 18.2993586 },
    { lat: 48.2157575, lng: 18.3002139 },
    { lat: 48.2155922, lng: 18.3016658 },
    { lat: 48.2159119, lng: 18.3018888 },
    { lat: 48.2155586, lng: 18.3038023 },
    { lat: 48.2153723, lng: 18.3043126 },
    { lat: 48.2151696, lng: 18.3051713 },
    { lat: 48.21438, lng: 18.3098745 },
    { lat: 48.2136957, lng: 18.3126905 },
    { lat: 48.2127175, lng: 18.3163683 },
    { lat: 48.2123769, lng: 18.3180323 },
    { lat: 48.2117064, lng: 18.3207983 },
    { lat: 48.2114883, lng: 18.3220571 },
    { lat: 48.2113932, lng: 18.3228443 },
    { lat: 48.2110221, lng: 18.3250244 },
    { lat: 48.2105935, lng: 18.3285941 },
    { lat: 48.2105472, lng: 18.3292643 },
    { lat: 48.210171, lng: 18.3320495 },
    { lat: 48.2101296, lng: 18.3335245 },
    { lat: 48.2099735, lng: 18.3346491 },
    { lat: 48.2096251, lng: 18.3360015 },
    { lat: 48.2084279, lng: 18.3393544 },
    { lat: 48.2083764, lng: 18.3395955 },
    { lat: 48.2083355, lng: 18.3401868 },
    { lat: 48.2083545, lng: 18.3408794 },
    { lat: 48.2078659, lng: 18.3436584 },
    { lat: 48.2078211, lng: 18.3441975 },
    { lat: 48.207854, lng: 18.345549 },
    { lat: 48.208116, lng: 18.346479 },
    { lat: 48.208511, lng: 18.347137 },
    { lat: 48.208924, lng: 18.347479 },
    { lat: 48.208928, lng: 18.347481 },
    { lat: 48.209106, lng: 18.347393 },
    { lat: 48.209628, lng: 18.347134 },
    { lat: 48.210423, lng: 18.346732 },
    { lat: 48.210649, lng: 18.346728 },
    { lat: 48.211095, lng: 18.346722 },
    { lat: 48.211349, lng: 18.346718 },
    { lat: 48.21147, lng: 18.346716 },
    { lat: 48.211494, lng: 18.346716 },
    { lat: 48.211589, lng: 18.346714 },
    { lat: 48.212035, lng: 18.346707 },
    { lat: 48.21212, lng: 18.346707 },
    { lat: 48.212149, lng: 18.346701 },
    { lat: 48.212158, lng: 18.3467 },
    { lat: 48.212215, lng: 18.346689 },
    { lat: 48.212369, lng: 18.346662 },
    { lat: 48.213262, lng: 18.346501 },
    { lat: 48.213315, lng: 18.346492 },
    { lat: 48.214628, lng: 18.346231 },
    { lat: 48.214893, lng: 18.346179 },
    { lat: 48.2149, lng: 18.346177 },
    { lat: 48.214901, lng: 18.346174 },
    { lat: 48.214959, lng: 18.345942 },
    { lat: 48.215076, lng: 18.34547 },
    { lat: 48.215193, lng: 18.344858 },
    { lat: 48.215344, lng: 18.34408 },
    { lat: 48.215345, lng: 18.344076 },
    { lat: 48.215345, lng: 18.344074 },
    { lat: 48.21535, lng: 18.344076 },
    { lat: 48.215361, lng: 18.344081 },
    { lat: 48.215391, lng: 18.344094 },
    { lat: 48.215414, lng: 18.344103 },
    { lat: 48.215468, lng: 18.344125 },
    { lat: 48.215703, lng: 18.344223 },
    { lat: 48.215885, lng: 18.344306 },
    { lat: 48.21597, lng: 18.344345 },
    { lat: 48.216067, lng: 18.344412 },
    { lat: 48.216613, lng: 18.344792 },
    { lat: 48.21693, lng: 18.345077 },
    { lat: 48.217557, lng: 18.34567 },
    { lat: 48.218194, lng: 18.346263 },
    { lat: 48.218193, lng: 18.346326 },
    { lat: 48.218192, lng: 18.34641 },
    { lat: 48.218192, lng: 18.346523 },
    { lat: 48.218191, lng: 18.346556 },
    { lat: 48.218191, lng: 18.346586 },
    { lat: 48.218532, lng: 18.346781 },
    { lat: 48.220024, lng: 18.347672 },
    { lat: 48.220058, lng: 18.347692 },
    { lat: 48.220058, lng: 18.347739 },
    { lat: 48.22007, lng: 18.34844 },
    { lat: 48.220127, lng: 18.349819 },
    { lat: 48.220151, lng: 18.350069 },
    { lat: 48.220187, lng: 18.350309 },
    { lat: 48.220364, lng: 18.351357 },
    { lat: 48.220396, lng: 18.351543 },
    { lat: 48.220435, lng: 18.351824 },
    { lat: 48.220447, lng: 18.352112 },
    { lat: 48.220405, lng: 18.352476 },
    { lat: 48.220344, lng: 18.352708 },
    { lat: 48.220252, lng: 18.352943 },
    { lat: 48.220138, lng: 18.35344 },
    { lat: 48.220139, lng: 18.353719 },
    { lat: 48.220174, lng: 18.354643 },
    { lat: 48.22017, lng: 18.354906 },
    { lat: 48.220169, lng: 18.354963 },
    { lat: 48.220167, lng: 18.35516 },
    { lat: 48.220155, lng: 18.358361 },
    { lat: 48.220159, lng: 18.358361 },
    { lat: 48.220477, lng: 18.35836 },
    { lat: 48.221019, lng: 18.35836 },
    { lat: 48.221115, lng: 18.358293 },
    { lat: 48.221613, lng: 18.357964 },
    { lat: 48.222324, lng: 18.357799 },
    { lat: 48.222373, lng: 18.357787 },
    { lat: 48.223, lng: 18.357634 },
    { lat: 48.22303, lng: 18.357627 },
    { lat: 48.222975, lng: 18.358262 },
    { lat: 48.222939, lng: 18.358707 },
    { lat: 48.222939, lng: 18.35871 },
    { lat: 48.222948, lng: 18.358724 },
    { lat: 48.222982, lng: 18.3588051 },
    { lat: 48.225083, lng: 18.358669 },
    { lat: 48.2270595, lng: 18.3584072 },
    { lat: 48.2273006, lng: 18.3579003 },
    { lat: 48.2281864, lng: 18.3565533 },
    { lat: 48.2285056, lng: 18.3552145 },
    { lat: 48.2290363, lng: 18.3552426 },
    { lat: 48.2290431, lng: 18.3550825 },
    { lat: 48.2289775, lng: 18.3550434 },
    { lat: 48.2289689, lng: 18.3548701 },
    { lat: 48.2290355, lng: 18.3548524 },
    { lat: 48.2290839, lng: 18.3547299 },
    { lat: 48.2291774, lng: 18.3548082 },
    { lat: 48.2292358, lng: 18.354724 },
    { lat: 48.2292364, lng: 18.3545725 },
    { lat: 48.2296073, lng: 18.3542087 },
    { lat: 48.2296628, lng: 18.3540159 },
    { lat: 48.2298676, lng: 18.3536455 },
    { lat: 48.2298606, lng: 18.353306 },
    { lat: 48.2297856, lng: 18.3531761 },
    { lat: 48.2297236, lng: 18.3532165 },
    { lat: 48.229705, lng: 18.3530303 },
    { lat: 48.2296023, lng: 18.3528491 },
    { lat: 48.2296081, lng: 18.3527351 },
    { lat: 48.2296657, lng: 18.3526751 },
    { lat: 48.2296009, lng: 18.3525078 },
    { lat: 48.2308878, lng: 18.3521999 },
    { lat: 48.2320643, lng: 18.3520748 },
    { lat: 48.2333588, lng: 18.3521634 },
    { lat: 48.235213, lng: 18.3524285 },
    { lat: 48.237123, lng: 18.3528258 },
    { lat: 48.237582, lng: 18.3529213 },
    { lat: 48.238884, lng: 18.3531002 },
    { lat: 48.2412305, lng: 18.3532126 },
    { lat: 48.2441207, lng: 18.353228 },
    { lat: 48.2473622, lng: 18.3535467 },
    { lat: 48.2485746, lng: 18.3537976 },
    { lat: 48.2494209, lng: 18.353599 },
    { lat: 48.249768, lng: 18.3535921 },
    { lat: 48.25089, lng: 18.3514933 },
    { lat: 48.2516243, lng: 18.3496575 },
    { lat: 48.2522603, lng: 18.3477818 },
    { lat: 48.2525106, lng: 18.34734 },
    { lat: 48.2535005, lng: 18.3463559 },
    { lat: 48.2534717, lng: 18.3462063 },
    { lat: 48.2531998, lng: 18.3458854 },
    { lat: 48.2532486, lng: 18.3458127 },
    { lat: 48.253261, lng: 18.3457934 },
    { lat: 48.2532893, lng: 18.345754 },
    { lat: 48.2533107, lng: 18.3456738 },
    { lat: 48.2531924, lng: 18.3452013 },
    { lat: 48.2531192, lng: 18.3451434 },
    { lat: 48.2530415, lng: 18.3448881 },
    { lat: 48.2531558, lng: 18.3447939 },
    { lat: 48.2531173, lng: 18.3445315 },
    { lat: 48.2532039, lng: 18.3443506 },
    { lat: 48.2531934, lng: 18.3442029 },
    { lat: 48.2543545, lng: 18.3434479 },
    { lat: 48.2553961, lng: 18.3429978 },
    { lat: 48.2565495, lng: 18.3427447 },
    { lat: 48.257596, lng: 18.3426327 },
    { lat: 48.2576413, lng: 18.3430724 },
    { lat: 48.2587093, lng: 18.3430083 },
    { lat: 48.2591532, lng: 18.3430969 },
    { lat: 48.259634, lng: 18.343279 },
    { lat: 48.260173, lng: 18.3435485 },
    { lat: 48.262412, lng: 18.3449113 },
    { lat: 48.2625986, lng: 18.3450954 },
    { lat: 48.2630968, lng: 18.3459633 },
    { lat: 48.2639595, lng: 18.3479048 },
    { lat: 48.2642052, lng: 18.3480594 },
    { lat: 48.2654571, lng: 18.3481509 },
    { lat: 48.2657837, lng: 18.3480615 },
    { lat: 48.2679422, lng: 18.3478853 },
    { lat: 48.2693708, lng: 18.3481458 },
    { lat: 48.2703268, lng: 18.3480515 },
    { lat: 48.2710856, lng: 18.3478834 },
    { lat: 48.2723016, lng: 18.347842 },
    { lat: 48.2728433, lng: 18.3480592 },
    { lat: 48.2734242, lng: 18.3484177 },
    { lat: 48.2733465, lng: 18.3489167 },
    { lat: 48.2739361, lng: 18.3493648 },
    { lat: 48.2742696, lng: 18.3491598 },
    { lat: 48.274416, lng: 18.3489765 },
    { lat: 48.2748794, lng: 18.3477346 },
  ],
  LúčnicanadŽitavou: [
    { lat: 48.206851, lng: 18.244199 },
    { lat: 48.206784, lng: 18.244334 },
    { lat: 48.206559, lng: 18.244856 },
    { lat: 48.206337, lng: 18.24538 },
    { lat: 48.206243, lng: 18.245602 },
    { lat: 48.206087, lng: 18.24597 },
    { lat: 48.205917, lng: 18.24637 },
    { lat: 48.205917, lng: 18.246372 },
    { lat: 48.205774, lng: 18.246709 },
    { lat: 48.205693, lng: 18.2469 },
    { lat: 48.205637, lng: 18.247031 },
    { lat: 48.2055, lng: 18.247357 },
    { lat: 48.205492, lng: 18.247378 },
    { lat: 48.205361, lng: 18.247688 },
    { lat: 48.205236, lng: 18.247984 },
    { lat: 48.20522, lng: 18.248022 },
    { lat: 48.205077, lng: 18.248362 },
    { lat: 48.205027, lng: 18.248482 },
    { lat: 48.204935, lng: 18.248708 },
    { lat: 48.204856, lng: 18.248901 },
    { lat: 48.204765, lng: 18.249035 },
    { lat: 48.20473, lng: 18.249085 },
    { lat: 48.204571, lng: 18.249331 },
    { lat: 48.204553, lng: 18.249361 },
    { lat: 48.20438, lng: 18.249621 },
    { lat: 48.20435, lng: 18.249666 },
    { lat: 48.204194, lng: 18.249905 },
    { lat: 48.204051, lng: 18.250124 },
    { lat: 48.20401, lng: 18.250186 },
    { lat: 48.20383, lng: 18.250461 },
    { lat: 48.203749, lng: 18.250585 },
    { lat: 48.203654, lng: 18.250733 },
    { lat: 48.203555, lng: 18.250884 },
    { lat: 48.203425, lng: 18.251082 },
    { lat: 48.203393, lng: 18.251131 },
    { lat: 48.203276, lng: 18.25131 },
    { lat: 48.203138, lng: 18.25152 },
    { lat: 48.203086, lng: 18.2516 },
    { lat: 48.202971, lng: 18.251774 },
    { lat: 48.202834, lng: 18.25208 },
    { lat: 48.202833, lng: 18.252083 },
    { lat: 48.202699, lng: 18.252357 },
    { lat: 48.202737, lng: 18.252457 },
    { lat: 48.202698, lng: 18.252583 },
    { lat: 48.202687, lng: 18.252679 },
    { lat: 48.202667, lng: 18.252825 },
    { lat: 48.202593, lng: 18.253248 },
    { lat: 48.202569, lng: 18.253406 },
    { lat: 48.202523, lng: 18.253531 },
    { lat: 48.202433, lng: 18.253778 },
    { lat: 48.202428, lng: 18.253793 },
    { lat: 48.202339, lng: 18.254001 },
    { lat: 48.202255, lng: 18.25418 },
    { lat: 48.202126, lng: 18.254444 },
    { lat: 48.201933, lng: 18.254754 },
    { lat: 48.201634, lng: 18.255164 },
    { lat: 48.201539, lng: 18.255282 },
    { lat: 48.201392, lng: 18.255484 },
    { lat: 48.201332, lng: 18.255575 },
    { lat: 48.20117, lng: 18.25581 },
    { lat: 48.200998, lng: 18.25609 },
    { lat: 48.200801, lng: 18.256408 },
    { lat: 48.200731, lng: 18.256531 },
    { lat: 48.200695, lng: 18.256593 },
    { lat: 48.200681, lng: 18.256617 },
    { lat: 48.200625, lng: 18.256689 },
    { lat: 48.200521, lng: 18.256813 },
    { lat: 48.200435, lng: 18.256905 },
    { lat: 48.200107, lng: 18.257247 },
    { lat: 48.200031, lng: 18.257329 },
    { lat: 48.199833, lng: 18.257605 },
    { lat: 48.199774, lng: 18.257689 },
    { lat: 48.199697, lng: 18.257801 },
    { lat: 48.199532, lng: 18.258049 },
    { lat: 48.199377, lng: 18.258292 },
    { lat: 48.199275, lng: 18.258439 },
    { lat: 48.199261, lng: 18.25846 },
    { lat: 48.199094, lng: 18.258688 },
    { lat: 48.198952, lng: 18.25891 },
    { lat: 48.198747, lng: 18.259258 },
    { lat: 48.198581, lng: 18.259639 },
    { lat: 48.198478, lng: 18.259851 },
    { lat: 48.198407, lng: 18.259973 },
    { lat: 48.19832, lng: 18.260125 },
    { lat: 48.198178, lng: 18.260392 },
    { lat: 48.198065, lng: 18.260634 },
    { lat: 48.197935, lng: 18.260956 },
    { lat: 48.197796, lng: 18.261285 },
    { lat: 48.197604, lng: 18.261685 },
    { lat: 48.197581, lng: 18.261831 },
    { lat: 48.197595, lng: 18.262329 },
    { lat: 48.197583, lng: 18.262463 },
    { lat: 48.197552, lng: 18.262598 },
    { lat: 48.19749, lng: 18.262798 },
    { lat: 48.197427, lng: 18.262979 },
    { lat: 48.197312, lng: 18.263348 },
    { lat: 48.197207, lng: 18.263816 },
    { lat: 48.197137, lng: 18.26407 },
    { lat: 48.197035, lng: 18.264409 },
    { lat: 48.196957, lng: 18.264618 },
    { lat: 48.196834, lng: 18.264951 },
    { lat: 48.196768, lng: 18.265132 },
    { lat: 48.196645, lng: 18.265468 },
    { lat: 48.196605, lng: 18.265615 },
    { lat: 48.196562, lng: 18.265827 },
    { lat: 48.196563, lng: 18.266178 },
    { lat: 48.196531, lng: 18.266546 },
    { lat: 48.196504, lng: 18.266865 },
    { lat: 48.196483, lng: 18.267146 },
    { lat: 48.196445, lng: 18.267171 },
    { lat: 48.196412, lng: 18.26728 },
    { lat: 48.19635, lng: 18.26749 },
    { lat: 48.19604, lng: 18.268475 },
    { lat: 48.195838, lng: 18.269105 },
    { lat: 48.195609, lng: 18.269825 },
    { lat: 48.195454, lng: 18.270318 },
    { lat: 48.195399, lng: 18.270496 },
    { lat: 48.195364, lng: 18.270604 },
    { lat: 48.195272, lng: 18.270882 },
    { lat: 48.195193, lng: 18.271117 },
    { lat: 48.195083, lng: 18.271976 },
    { lat: 48.195076, lng: 18.272005 },
    { lat: 48.1950252, lng: 18.2721987 },
    { lat: 48.194953, lng: 18.272474 },
    { lat: 48.194867, lng: 18.272843 },
    { lat: 48.19481, lng: 18.273081 },
    { lat: 48.19473, lng: 18.273465 },
    { lat: 48.194694, lng: 18.273674 },
    { lat: 48.194612, lng: 18.274161 },
    { lat: 48.1944984, lng: 18.2748008 },
    { lat: 48.19447, lng: 18.274961 },
    { lat: 48.194427, lng: 18.275337 },
    { lat: 48.1943671, lng: 18.2758637 },
    { lat: 48.194349, lng: 18.276023 },
    { lat: 48.194301, lng: 18.276416 },
    { lat: 48.194261, lng: 18.276742 },
    { lat: 48.194253, lng: 18.276814 },
    { lat: 48.194216, lng: 18.277207 },
    { lat: 48.1941939, lng: 18.2774038 },
    { lat: 48.194172, lng: 18.277599 },
    { lat: 48.1941, lng: 18.278297 },
    { lat: 48.19409, lng: 18.278442 },
    { lat: 48.194055, lng: 18.278981 },
    { lat: 48.194018, lng: 18.279509 },
    { lat: 48.193997, lng: 18.279988 },
    { lat: 48.193984, lng: 18.280305 },
    { lat: 48.193982, lng: 18.280405 },
    { lat: 48.193978, lng: 18.280716 },
    { lat: 48.193977, lng: 18.280739 },
    { lat: 48.193712, lng: 18.280834 },
    { lat: 48.19371, lng: 18.280934 },
    { lat: 48.193709, lng: 18.280945 },
    { lat: 48.19371, lng: 18.281345 },
    { lat: 48.193709, lng: 18.282134 },
    { lat: 48.193709, lng: 18.28214 },
    { lat: 48.193703, lng: 18.282668 },
    { lat: 48.193698, lng: 18.282962 },
    { lat: 48.193687, lng: 18.283521 },
    { lat: 48.193681, lng: 18.283861 },
    { lat: 48.193674, lng: 18.284288 },
    { lat: 48.193675, lng: 18.284498 },
    { lat: 48.193688, lng: 18.284523 },
    { lat: 48.193695, lng: 18.284539 },
    { lat: 48.193646, lng: 18.285183 },
    { lat: 48.193616, lng: 18.28548 },
    { lat: 48.193606, lng: 18.285552 },
    { lat: 48.193577, lng: 18.285662 },
    { lat: 48.193492, lng: 18.285981 },
    { lat: 48.193499, lng: 18.286156 },
    { lat: 48.193526, lng: 18.286751 },
    { lat: 48.193427, lng: 18.287528 },
    { lat: 48.193426, lng: 18.287533 },
    { lat: 48.193376, lng: 18.28793 },
    { lat: 48.193307, lng: 18.288494 },
    { lat: 48.193228, lng: 18.289132 },
    { lat: 48.193228, lng: 18.289135 },
    { lat: 48.193218, lng: 18.289193 },
    { lat: 48.193486, lng: 18.289291 },
    { lat: 48.193213, lng: 18.292277 },
    { lat: 48.193189, lng: 18.292474 },
    { lat: 48.193265, lng: 18.293461 },
    { lat: 48.193277, lng: 18.293621 },
    { lat: 48.193315, lng: 18.29408 },
    { lat: 48.193332, lng: 18.294303 },
    { lat: 48.1935348, lng: 18.2942341 },
    { lat: 48.1937942, lng: 18.293953 },
    { lat: 48.193642, lng: 18.2936825 },
    { lat: 48.1936854, lng: 18.2933548 },
    { lat: 48.1936072, lng: 18.2930335 },
    { lat: 48.1936768, lng: 18.2927339 },
    { lat: 48.1938566, lng: 18.2927399 },
    { lat: 48.194065, lng: 18.2925385 },
    { lat: 48.1940747, lng: 18.2919576 },
    { lat: 48.1942744, lng: 18.2918602 },
    { lat: 48.1943185, lng: 18.2917803 },
    { lat: 48.1943878, lng: 18.2913976 },
    { lat: 48.1945816, lng: 18.2909997 },
    { lat: 48.1946059, lng: 18.2905563 },
    { lat: 48.1946616, lng: 18.2904067 },
    { lat: 48.1948818, lng: 18.2901521 },
    { lat: 48.1950663, lng: 18.2900624 },
    { lat: 48.1950706, lng: 18.2901587 },
    { lat: 48.1953229, lng: 18.2901706 },
    { lat: 48.1953333, lng: 18.2905292 },
    { lat: 48.1957469, lng: 18.2913062 },
    { lat: 48.1958247, lng: 18.2917199 },
    { lat: 48.1959183, lng: 18.2919593 },
    { lat: 48.1964193, lng: 18.2923916 },
    { lat: 48.1966815, lng: 18.2927721 },
    { lat: 48.1970791, lng: 18.2929185 },
    { lat: 48.1973541, lng: 18.2931093 },
    { lat: 48.1974843, lng: 18.293372 },
    { lat: 48.1975724, lng: 18.2937083 },
    { lat: 48.1977093, lng: 18.2938535 },
    { lat: 48.1979631, lng: 18.2937343 },
    { lat: 48.198114, lng: 18.2934709 },
    { lat: 48.1982601, lng: 18.2931081 },
    { lat: 48.1982976, lng: 18.292739 },
    { lat: 48.1984095, lng: 18.2924676 },
    { lat: 48.1984891, lng: 18.2923803 },
    { lat: 48.1986177, lng: 18.2923585 },
    { lat: 48.1988366, lng: 18.2924981 },
    { lat: 48.1989288, lng: 18.2926882 },
    { lat: 48.1989815, lng: 18.2928742 },
    { lat: 48.1992088, lng: 18.2936762 },
    { lat: 48.1992474, lng: 18.2939952 },
    { lat: 48.1992355, lng: 18.2942407 },
    { lat: 48.1990137, lng: 18.2951151 },
    { lat: 48.199003, lng: 18.2955607 },
    { lat: 48.1994021, lng: 18.2955732 },
    { lat: 48.1996504, lng: 18.2953168 },
    { lat: 48.199749, lng: 18.295289 },
    { lat: 48.1998553, lng: 18.2952715 },
    { lat: 48.2000473, lng: 18.2953742 },
    { lat: 48.2004357, lng: 18.2950058 },
    { lat: 48.2005155, lng: 18.2950359 },
    { lat: 48.200897, lng: 18.2949389 },
    { lat: 48.2011134, lng: 18.2947588 },
    { lat: 48.2011974, lng: 18.2945538 },
    { lat: 48.201367, lng: 18.2940638 },
    { lat: 48.2013172, lng: 18.2940242 },
    { lat: 48.2013239, lng: 18.2938632 },
    { lat: 48.2013889, lng: 18.2937388 },
    { lat: 48.2015391, lng: 18.2937203 },
    { lat: 48.201606, lng: 18.2936283 },
    { lat: 48.2017416, lng: 18.2936894 },
    { lat: 48.2020192, lng: 18.2943619 },
    { lat: 48.2020252, lng: 18.2948216 },
    { lat: 48.2019372, lng: 18.2949369 },
    { lat: 48.2019188, lng: 18.2952675 },
    { lat: 48.201715, lng: 18.2954715 },
    { lat: 48.2017452, lng: 18.2956642 },
    { lat: 48.201686, lng: 18.2959919 },
    { lat: 48.2019792, lng: 18.2960095 },
    { lat: 48.2020509, lng: 18.2956633 },
    { lat: 48.2021745, lng: 18.2954568 },
    { lat: 48.202445, lng: 18.2953539 },
    { lat: 48.2025189, lng: 18.2951068 },
    { lat: 48.2024556, lng: 18.2949264 },
    { lat: 48.2024997, lng: 18.2948715 },
    { lat: 48.202556, lng: 18.294534 },
    { lat: 48.2026282, lng: 18.2944721 },
    { lat: 48.2029119, lng: 18.2944842 },
    { lat: 48.2030489, lng: 18.2945317 },
    { lat: 48.2030796, lng: 18.2946353 },
    { lat: 48.2031682, lng: 18.2946826 },
    { lat: 48.2033683, lng: 18.2940405 },
    { lat: 48.2035021, lng: 18.2938529 },
    { lat: 48.2036977, lng: 18.2937638 },
    { lat: 48.2039776, lng: 18.2938054 },
    { lat: 48.2042614, lng: 18.2940117 },
    { lat: 48.2044187, lng: 18.2941174 },
    { lat: 48.2046265, lng: 18.2942776 },
    { lat: 48.204921, lng: 18.2947138 },
    { lat: 48.2048862, lng: 18.2948742 },
    { lat: 48.2045922, lng: 18.2950778 },
    { lat: 48.2046302, lng: 18.2952091 },
    { lat: 48.2046013, lng: 18.2955516 },
    { lat: 48.2046546, lng: 18.2957132 },
    { lat: 48.2048571, lng: 18.2959794 },
    { lat: 48.2053137, lng: 18.2962293 },
    { lat: 48.2054443, lng: 18.2958501 },
    { lat: 48.2055117, lng: 18.2952146 },
    { lat: 48.2055492, lng: 18.2950227 },
    { lat: 48.205603, lng: 18.2949592 },
    { lat: 48.2057146, lng: 18.2949757 },
    { lat: 48.2058884, lng: 18.2952527 },
    { lat: 48.206025, lng: 18.2953714 },
    { lat: 48.2065131, lng: 18.2955207 },
    { lat: 48.2066614, lng: 18.2957861 },
    { lat: 48.2067637, lng: 18.2963308 },
    { lat: 48.207036, lng: 18.2966935 },
    { lat: 48.207173, lng: 18.2968595 },
    { lat: 48.2073383, lng: 18.2969272 },
    { lat: 48.2073867, lng: 18.2970242 },
    { lat: 48.20773, lng: 18.2972272 },
    { lat: 48.2086527, lng: 18.2981493 },
    { lat: 48.2103554, lng: 18.3003958 },
    { lat: 48.2102778, lng: 18.3025756 },
    { lat: 48.2103061, lng: 18.3025578 },
    { lat: 48.2110447, lng: 18.301974 },
    { lat: 48.2131102, lng: 18.3011953 },
    { lat: 48.213636, lng: 18.2978259 },
    { lat: 48.2144283, lng: 18.2977096 },
    { lat: 48.2148234, lng: 18.2973965 },
    { lat: 48.2151008, lng: 18.2974929 },
    { lat: 48.216005, lng: 18.2979944 },
    { lat: 48.2163575, lng: 18.2979804 },
    { lat: 48.2166048, lng: 18.2968439 },
    { lat: 48.2166494, lng: 18.2969507 },
    { lat: 48.2169592, lng: 18.2969793 },
    { lat: 48.2171675, lng: 18.2969352 },
    { lat: 48.217493, lng: 18.2966692 },
    { lat: 48.2177213, lng: 18.2962423 },
    { lat: 48.2177617, lng: 18.2958239 },
    { lat: 48.2178372, lng: 18.2955652 },
    { lat: 48.2180126, lng: 18.2952579 },
    { lat: 48.2181527, lng: 18.2952226 },
    { lat: 48.21859, lng: 18.2956676 },
    { lat: 48.2187629, lng: 18.2957108 },
    { lat: 48.2189342, lng: 18.2956507 },
    { lat: 48.2190196, lng: 18.2955442 },
    { lat: 48.2190657, lng: 18.2953239 },
    { lat: 48.2190146, lng: 18.2950428 },
    { lat: 48.2190186, lng: 18.2947005 },
    { lat: 48.2191883, lng: 18.294274 },
    { lat: 48.2191035, lng: 18.2940348 },
    { lat: 48.2192169, lng: 18.293827 },
    { lat: 48.219369, lng: 18.2939194 },
    { lat: 48.219708, lng: 18.2939881 },
    { lat: 48.2198375, lng: 18.2939255 },
    { lat: 48.2199177, lng: 18.293711 },
    { lat: 48.2200734, lng: 18.2935941 },
    { lat: 48.2202911, lng: 18.2936879 },
    { lat: 48.2204164, lng: 18.2938686 },
    { lat: 48.2206498, lng: 18.2940428 },
    { lat: 48.2209234, lng: 18.294564 },
    { lat: 48.2209362, lng: 18.2953584 },
    { lat: 48.2208396, lng: 18.2958028 },
    { lat: 48.2208319, lng: 18.2964152 },
    { lat: 48.2206558, lng: 18.2970643 },
    { lat: 48.220652, lng: 18.2972127 },
    { lat: 48.2209753, lng: 18.2974218 },
    { lat: 48.221224, lng: 18.2976695 },
    { lat: 48.2213715, lng: 18.2976223 },
    { lat: 48.2216142, lng: 18.2973113 },
    { lat: 48.2216336, lng: 18.297174 },
    { lat: 48.2214938, lng: 18.2968225 },
    { lat: 48.2214515, lng: 18.2964596 },
    { lat: 48.2215106, lng: 18.2963848 },
    { lat: 48.2218813, lng: 18.2961972 },
    { lat: 48.2225406, lng: 18.2962238 },
    { lat: 48.2227663, lng: 18.2960484 },
    { lat: 48.2228843, lng: 18.2962187 },
    { lat: 48.2229333, lng: 18.2965429 },
    { lat: 48.2227576, lng: 18.2968641 },
    { lat: 48.2225183, lng: 18.2976109 },
    { lat: 48.2225188, lng: 18.297736 },
    { lat: 48.2226105, lng: 18.2977806 },
    { lat: 48.2228306, lng: 18.2977269 },
    { lat: 48.2230599, lng: 18.297867 },
    { lat: 48.2233976, lng: 18.297892 },
    { lat: 48.2236592, lng: 18.2981869 },
    { lat: 48.2236636, lng: 18.2986573 },
    { lat: 48.2237026, lng: 18.298728 },
    { lat: 48.2241216, lng: 18.2987532 },
    { lat: 48.2242182, lng: 18.2984698 },
    { lat: 48.224154, lng: 18.2982565 },
    { lat: 48.2240323, lng: 18.2981387 },
    { lat: 48.2239665, lng: 18.2979348 },
    { lat: 48.2240315, lng: 18.2978671 },
    { lat: 48.2242039, lng: 18.2979632 },
    { lat: 48.2243249, lng: 18.2976734 },
    { lat: 48.2242728, lng: 18.2976518 },
    { lat: 48.2243626, lng: 18.296675 },
    { lat: 48.2243972, lng: 18.296689 },
    { lat: 48.2245088, lng: 18.2956022 },
    { lat: 48.224201, lng: 18.2954294 },
    { lat: 48.2241824, lng: 18.2953397 },
    { lat: 48.2242633, lng: 18.2950385 },
    { lat: 48.2245229, lng: 18.2931256 },
    { lat: 48.2250648, lng: 18.2900987 },
    { lat: 48.2253636, lng: 18.2880459 },
    { lat: 48.2259142, lng: 18.2881027 },
    { lat: 48.2260707, lng: 18.2867985 },
    { lat: 48.2262907, lng: 18.2854387 },
    { lat: 48.2269668, lng: 18.2824329 },
    { lat: 48.2267304, lng: 18.2824013 },
    { lat: 48.2268817, lng: 18.2810277 },
    { lat: 48.2268826, lng: 18.2785459 },
    { lat: 48.2269498, lng: 18.2781632 },
    { lat: 48.2272231, lng: 18.2772597 },
    { lat: 48.227382, lng: 18.2768437 },
    { lat: 48.2277788, lng: 18.2760701 },
    { lat: 48.2280903, lng: 18.2748371 },
    { lat: 48.2281026, lng: 18.2745394 },
    { lat: 48.2274572, lng: 18.2716668 },
    { lat: 48.2273446, lng: 18.2699052 },
    { lat: 48.2273634, lng: 18.26878 },
    { lat: 48.2276031, lng: 18.2679225 },
    { lat: 48.228335, lng: 18.2663449 },
    { lat: 48.22882, lng: 18.2647536 },
    { lat: 48.2291629, lng: 18.2639858 },
    { lat: 48.2301747, lng: 18.2608778 },
    { lat: 48.2308217, lng: 18.2585161 },
    { lat: 48.231296, lng: 18.2562483 },
    { lat: 48.2313295, lng: 18.2560469 },
    { lat: 48.2307865, lng: 18.2556084 },
    { lat: 48.2296947, lng: 18.2546376 },
    { lat: 48.228428, lng: 18.2536671 },
    { lat: 48.226576, lng: 18.2516048 },
    { lat: 48.226114, lng: 18.2510264 },
    { lat: 48.2256996, lng: 18.2507497 },
    { lat: 48.2251559, lng: 18.2505343 },
    { lat: 48.2222802, lng: 18.249857 },
    { lat: 48.221255, lng: 18.2497519 },
    { lat: 48.2204225, lng: 18.2497938 },
    { lat: 48.2191876, lng: 18.249725 },
    { lat: 48.2186705, lng: 18.2494874 },
    { lat: 48.2179103, lng: 18.2493549 },
    { lat: 48.216895, lng: 18.2494381 },
    { lat: 48.2167885, lng: 18.2493135 },
    { lat: 48.2168457, lng: 18.2491484 },
    { lat: 48.2168745, lng: 18.2483564 },
    { lat: 48.2165011, lng: 18.2482043 },
    { lat: 48.2159374, lng: 18.2481918 },
    { lat: 48.2158307, lng: 18.2482395 },
    { lat: 48.2158054, lng: 18.2484286 },
    { lat: 48.2156514, lng: 18.2485949 },
    { lat: 48.2149159, lng: 18.248774 },
    { lat: 48.2147225, lng: 18.2489016 },
    { lat: 48.2100202, lng: 18.2500893 },
    { lat: 48.2099035, lng: 18.2498962 },
    { lat: 48.2092804, lng: 18.2488649 },
    { lat: 48.2070064, lng: 18.2450419 },
    { lat: 48.2068815, lng: 18.2443647 },
    { lat: 48.206851, lng: 18.244199 },
  ],
  Golianovo: [
    { lat: 48.2891813, lng: 18.2122661 },
    { lat: 48.2888576, lng: 18.2116048 },
    { lat: 48.2862712, lng: 18.2052156 },
    { lat: 48.2859607, lng: 18.2045867 },
    { lat: 48.2851583, lng: 18.2023871 },
    { lat: 48.2838477, lng: 18.1995593 },
    { lat: 48.283871, lng: 18.1995336 },
    { lat: 48.2831726, lng: 18.1989329 },
    { lat: 48.28159, lng: 18.1979678 },
    { lat: 48.2799411, lng: 18.1960399 },
    { lat: 48.2785393, lng: 18.1942093 },
    { lat: 48.2775336, lng: 18.192145 },
    { lat: 48.2770208, lng: 18.1909135 },
    { lat: 48.2758847, lng: 18.1885472 },
    { lat: 48.2743454, lng: 18.185477 },
    { lat: 48.2735996, lng: 18.1840423 },
    { lat: 48.2735784, lng: 18.1840677 },
    { lat: 48.2712369, lng: 18.1790643 },
    { lat: 48.2714268, lng: 18.1785663 },
    { lat: 48.2710814, lng: 18.1759881 },
    { lat: 48.271014, lng: 18.1758573 },
    { lat: 48.2706429, lng: 18.1762531 },
    { lat: 48.2706295, lng: 18.1762674 },
    { lat: 48.2685889, lng: 18.1788774 },
    { lat: 48.2679173, lng: 18.1795343 },
    { lat: 48.266734, lng: 18.1809979 },
    { lat: 48.2656816, lng: 18.1826132 },
    { lat: 48.2649997, lng: 18.1832119 },
    { lat: 48.2647353, lng: 18.1835155 },
    { lat: 48.2641248, lng: 18.1846226 },
    { lat: 48.2634767, lng: 18.1855264 },
    { lat: 48.2613616, lng: 18.1875981 },
    { lat: 48.2609264, lng: 18.1880887 },
    { lat: 48.2602581, lng: 18.1890141 },
    { lat: 48.2591581, lng: 18.1900849 },
    { lat: 48.2588379, lng: 18.1902673 },
    { lat: 48.2586017, lng: 18.190394 },
    { lat: 48.2578825, lng: 18.1908932 },
    { lat: 48.2577171, lng: 18.1911354 },
    { lat: 48.2572564, lng: 18.1915372 },
    { lat: 48.2564895, lng: 18.19209 },
    { lat: 48.2555306, lng: 18.1931059 },
    { lat: 48.2552362, lng: 18.1932458 },
    { lat: 48.2540881, lng: 18.1940292 },
    { lat: 48.2526556, lng: 18.1953042 },
    { lat: 48.251113, lng: 18.1962412 },
    { lat: 48.2509722, lng: 18.1963267 },
    { lat: 48.25088, lng: 18.1963827 },
    { lat: 48.2501326, lng: 18.1969573 },
    { lat: 48.2503953, lng: 18.197792 },
    { lat: 48.2506157, lng: 18.198868 },
    { lat: 48.2507978, lng: 18.2007389 },
    { lat: 48.2512889, lng: 18.2043237 },
    { lat: 48.2515011, lng: 18.2071063 },
    { lat: 48.2518245, lng: 18.2092025 },
    { lat: 48.2521673, lng: 18.2118457 },
    { lat: 48.2525774, lng: 18.2140553 },
    { lat: 48.2527178, lng: 18.2156994 },
    { lat: 48.2529416, lng: 18.216661 },
    { lat: 48.2531856, lng: 18.2181275 },
    { lat: 48.2537589, lng: 18.2200575 },
    { lat: 48.2539433, lng: 18.2205919 },
    { lat: 48.2543655, lng: 18.221535 },
    { lat: 48.2545901, lng: 18.2218245 },
    { lat: 48.2546184, lng: 18.2224724 },
    { lat: 48.2548638, lng: 18.2238101 },
    { lat: 48.2553581, lng: 18.2235162 },
    { lat: 48.2554376, lng: 18.2239747 },
    { lat: 48.2557615, lng: 18.2244408 },
    { lat: 48.2558108, lng: 18.2245941 },
    { lat: 48.2557636, lng: 18.225398 },
    { lat: 48.2557884, lng: 18.2258271 },
    { lat: 48.2559078, lng: 18.2268166 },
    { lat: 48.2594341, lng: 18.2355497 },
    { lat: 48.2614068, lng: 18.2354173 },
    { lat: 48.2630361, lng: 18.235535 },
    { lat: 48.2640318, lng: 18.2352679 },
    { lat: 48.2671206, lng: 18.2340637 },
    { lat: 48.2703811, lng: 18.2303776 },
    { lat: 48.2713038, lng: 18.2288314 },
    { lat: 48.2748384, lng: 18.2239185 },
    { lat: 48.2750917, lng: 18.2235747 },
    { lat: 48.2754965, lng: 18.2231789 },
    { lat: 48.276133, lng: 18.2225767 },
    { lat: 48.2771864, lng: 18.2217251 },
    { lat: 48.2791942, lng: 18.220349 },
    { lat: 48.2829617, lng: 18.2181212 },
    { lat: 48.2883108, lng: 18.2132689 },
    { lat: 48.288561, lng: 18.2129919 },
    { lat: 48.2891813, lng: 18.2122661 },
  ],
  VeľkýLapáš: [
    { lat: 48.271014, lng: 18.1758573 },
    { lat: 48.2710814, lng: 18.1759881 },
    { lat: 48.2714268, lng: 18.1785663 },
    { lat: 48.2712369, lng: 18.1790643 },
    { lat: 48.2735784, lng: 18.1840677 },
    { lat: 48.2735996, lng: 18.1840423 },
    { lat: 48.2743454, lng: 18.185477 },
    { lat: 48.2758847, lng: 18.1885472 },
    { lat: 48.2770208, lng: 18.1909135 },
    { lat: 48.2775336, lng: 18.192145 },
    { lat: 48.2785393, lng: 18.1942093 },
    { lat: 48.2799411, lng: 18.1960399 },
    { lat: 48.28159, lng: 18.1979678 },
    { lat: 48.2831726, lng: 18.1989329 },
    { lat: 48.283871, lng: 18.1995336 },
    { lat: 48.2838477, lng: 18.1995593 },
    { lat: 48.2851583, lng: 18.2023871 },
    { lat: 48.2859607, lng: 18.2045867 },
    { lat: 48.2862712, lng: 18.2052156 },
    { lat: 48.2888576, lng: 18.2116048 },
    { lat: 48.2891813, lng: 18.2122661 },
    { lat: 48.2899722, lng: 18.2107615 },
    { lat: 48.2915433, lng: 18.2086268 },
    { lat: 48.2935217, lng: 18.2068383 },
    { lat: 48.293937, lng: 18.2063898 },
    { lat: 48.2945299, lng: 18.2055427 },
    { lat: 48.2949638, lng: 18.2045808 },
    { lat: 48.2957156, lng: 18.2026568 },
    { lat: 48.2962173, lng: 18.2011084 },
    { lat: 48.2965906, lng: 18.2003486 },
    { lat: 48.2972887, lng: 18.1995641 },
    { lat: 48.2987448, lng: 18.1983633 },
    { lat: 48.299486, lng: 18.1976405 },
    { lat: 48.3009204, lng: 18.1965155 },
    { lat: 48.3012672, lng: 18.1961506 },
    { lat: 48.3025837, lng: 18.1952485 },
    { lat: 48.302673, lng: 18.1951937 },
    { lat: 48.3021195, lng: 18.1934551 },
    { lat: 48.3017559, lng: 18.1921294 },
    { lat: 48.3012322, lng: 18.1896785 },
    { lat: 48.300942, lng: 18.1897345 },
    { lat: 48.3006429, lng: 18.1873624 },
    { lat: 48.2997908, lng: 18.1829329 },
    { lat: 48.2998092, lng: 18.1828436 },
    { lat: 48.2991674, lng: 18.1804019 },
    { lat: 48.2988677, lng: 18.1787789 },
    { lat: 48.2977285, lng: 18.1737301 },
    { lat: 48.2969935, lng: 18.1710868 },
    { lat: 48.2961642, lng: 18.168667 },
    { lat: 48.2957515, lng: 18.1676674 },
    { lat: 48.2966313, lng: 18.1666514 },
    { lat: 48.2968313, lng: 18.1663456 },
    { lat: 48.298676, lng: 18.1623339 },
    { lat: 48.2999508, lng: 18.1600432 },
    { lat: 48.3019849, lng: 18.1558764 },
    { lat: 48.3029058, lng: 18.1542535 },
    { lat: 48.3029878, lng: 18.1541307 },
    { lat: 48.3015092, lng: 18.151828 },
    { lat: 48.3013796, lng: 18.1514839 },
    { lat: 48.3010647, lng: 18.1502064 },
    { lat: 48.3005718, lng: 18.1491117 },
    { lat: 48.3003395, lng: 18.1489409 },
    { lat: 48.2999436, lng: 18.1489641 },
    { lat: 48.2998321, lng: 18.149022 },
    { lat: 48.2998601, lng: 18.1490605 },
    { lat: 48.2998044, lng: 18.1490905 },
    { lat: 48.2992956, lng: 18.1503467 },
    { lat: 48.2990821, lng: 18.1507334 },
    { lat: 48.2987511, lng: 18.1511511 },
    { lat: 48.2981431, lng: 18.1517335 },
    { lat: 48.297614, lng: 18.152472 },
    { lat: 48.2962078, lng: 18.1554477 },
    { lat: 48.2955986, lng: 18.1564554 },
    { lat: 48.2953147, lng: 18.1570033 },
    { lat: 48.2951393, lng: 18.1574593 },
    { lat: 48.2950265, lng: 18.1576534 },
    { lat: 48.294862, lng: 18.1577916 },
    { lat: 48.2953809, lng: 18.1587006 },
    { lat: 48.2943951, lng: 18.1598285 },
    { lat: 48.2939185, lng: 18.1589157 },
    { lat: 48.2929213, lng: 18.1598316 },
    { lat: 48.2912419, lng: 18.1616442 },
    { lat: 48.2901217, lng: 18.1625025 },
    { lat: 48.2893515, lng: 18.1629772 },
    { lat: 48.2876505, lng: 18.1642289 },
    { lat: 48.2874849, lng: 18.1636117 },
    { lat: 48.2859272, lng: 18.1638164 },
    { lat: 48.2846751, lng: 18.164081 },
    { lat: 48.2847982, lng: 18.1646229 },
    { lat: 48.284102, lng: 18.164837 },
    { lat: 48.2840358, lng: 18.1648053 },
    { lat: 48.2833484, lng: 18.1652669 },
    { lat: 48.2834943, lng: 18.1657541 },
    { lat: 48.2809973, lng: 18.1673674 },
    { lat: 48.2799086, lng: 18.1681454 },
    { lat: 48.2790019, lng: 18.1684288 },
    { lat: 48.2786164, lng: 18.1687243 },
    { lat: 48.2784145, lng: 18.1686681 },
    { lat: 48.2769264, lng: 18.1704424 },
    { lat: 48.2764783, lng: 18.1702638 },
    { lat: 48.2748481, lng: 18.1719159 },
    { lat: 48.2738606, lng: 18.172609 },
    { lat: 48.2732938, lng: 18.1731655 },
    { lat: 48.2718345, lng: 18.1747328 },
    { lat: 48.2710274, lng: 18.1758361 },
    { lat: 48.271014, lng: 18.1758573 },
  ],
  DolnéObdokovce: [
    { lat: 48.302673, lng: 18.1951937 },
    { lat: 48.3025837, lng: 18.1952485 },
    { lat: 48.3012672, lng: 18.1961506 },
    { lat: 48.3009204, lng: 18.1965155 },
    { lat: 48.299486, lng: 18.1976405 },
    { lat: 48.2987448, lng: 18.1983633 },
    { lat: 48.2972887, lng: 18.1995641 },
    { lat: 48.2965906, lng: 18.2003486 },
    { lat: 48.2962173, lng: 18.2011084 },
    { lat: 48.2957156, lng: 18.2026568 },
    { lat: 48.2949638, lng: 18.2045808 },
    { lat: 48.2945299, lng: 18.2055427 },
    { lat: 48.293937, lng: 18.2063898 },
    { lat: 48.2935217, lng: 18.2068383 },
    { lat: 48.2915433, lng: 18.2086268 },
    { lat: 48.2899722, lng: 18.2107615 },
    { lat: 48.2891813, lng: 18.2122661 },
    { lat: 48.288561, lng: 18.2129919 },
    { lat: 48.2883108, lng: 18.2132689 },
    { lat: 48.2913379, lng: 18.2188018 },
    { lat: 48.2918187, lng: 18.2196256 },
    { lat: 48.2922668, lng: 18.2201896 },
    { lat: 48.2926893, lng: 18.2209204 },
    { lat: 48.2939366, lng: 18.2234852 },
    { lat: 48.2945652, lng: 18.2244033 },
    { lat: 48.2948212, lng: 18.2247138 },
    { lat: 48.2952088, lng: 18.2250579 },
    { lat: 48.2968186, lng: 18.2262612 },
    { lat: 48.2970181, lng: 18.2257895 },
    { lat: 48.2984889, lng: 18.2269688 },
    { lat: 48.3002842, lng: 18.2282577 },
    { lat: 48.3002612, lng: 18.2293245 },
    { lat: 48.3000836, lng: 18.2314874 },
    { lat: 48.3000836, lng: 18.2323475 },
    { lat: 48.3001868, lng: 18.2329448 },
    { lat: 48.3003909, lng: 18.2337029 },
    { lat: 48.3004534, lng: 18.2342215 },
    { lat: 48.3003376, lng: 18.2356165 },
    { lat: 48.30034, lng: 18.2370883 },
    { lat: 48.3004481, lng: 18.238201 },
    { lat: 48.3006579, lng: 18.239058 },
    { lat: 48.3005283, lng: 18.2397846 },
    { lat: 48.2998582, lng: 18.2416302 },
    { lat: 48.2993412, lng: 18.2427614 },
    { lat: 48.2991006, lng: 18.2431278 },
    { lat: 48.2981106, lng: 18.2442782 },
    { lat: 48.2974927, lng: 18.2444205 },
    { lat: 48.2971846, lng: 18.2446967 },
    { lat: 48.2967866, lng: 18.2451717 },
    { lat: 48.2965256, lng: 18.2456155 },
    { lat: 48.2964112, lng: 18.2458415 },
    { lat: 48.296271, lng: 18.2464663 },
    { lat: 48.2958848, lng: 18.2475361 },
    { lat: 48.2960716, lng: 18.2477472 },
    { lat: 48.2957128, lng: 18.2484297 },
    { lat: 48.2956478, lng: 18.2485376 },
    { lat: 48.2962923, lng: 18.2492481 },
    { lat: 48.2970047, lng: 18.2501755 },
    { lat: 48.2980244, lng: 18.2512872 },
    { lat: 48.2992481, lng: 18.2527468 },
    { lat: 48.2999684, lng: 18.2534383 },
    { lat: 48.3004747, lng: 18.2540239 },
    { lat: 48.3027508, lng: 18.2572083 },
    { lat: 48.3033961, lng: 18.2563194 },
    { lat: 48.3036069, lng: 18.2561005 },
    { lat: 48.3038536, lng: 18.2556827 },
    { lat: 48.3040243, lng: 18.2555702 },
    { lat: 48.3044792, lng: 18.254887 },
    { lat: 48.3051024, lng: 18.2545772 },
    { lat: 48.305285, lng: 18.2539082 },
    { lat: 48.3056285, lng: 18.2533081 },
    { lat: 48.3059641, lng: 18.2537478 },
    { lat: 48.3064701, lng: 18.2545589 },
    { lat: 48.3079701, lng: 18.2571574 },
    { lat: 48.3091145, lng: 18.2595772 },
    { lat: 48.3103917, lng: 18.2618552 },
    { lat: 48.3122956, lng: 18.2600119 },
    { lat: 48.3121498, lng: 18.2593998 },
    { lat: 48.3122751, lng: 18.2574163 },
    { lat: 48.3123682, lng: 18.2565646 },
    { lat: 48.3122043, lng: 18.2562472 },
    { lat: 48.3113649, lng: 18.2538935 },
    { lat: 48.3105142, lng: 18.2512054 },
    { lat: 48.3104349, lng: 18.250691 },
    { lat: 48.3105396, lng: 18.2496669 },
    { lat: 48.3101566, lng: 18.2483851 },
    { lat: 48.3096546, lng: 18.2469762 },
    { lat: 48.3086347, lng: 18.2450357 },
    { lat: 48.3082498, lng: 18.2444251 },
    { lat: 48.3077443, lng: 18.2438022 },
    { lat: 48.3074072, lng: 18.2431206 },
    { lat: 48.3067313, lng: 18.2423766 },
    { lat: 48.3066245, lng: 18.2422047 },
    { lat: 48.306648, lng: 18.2421616 },
    { lat: 48.3048266, lng: 18.2403601 },
    { lat: 48.3049387, lng: 18.2401092 },
    { lat: 48.3050306, lng: 18.2397269 },
    { lat: 48.3051427, lng: 18.2388746 },
    { lat: 48.3053062, lng: 18.2385964 },
    { lat: 48.3066844, lng: 18.2371976 },
    { lat: 48.3073522, lng: 18.2363902 },
    { lat: 48.3075091, lng: 18.2361178 },
    { lat: 48.3071386, lng: 18.2353673 },
    { lat: 48.3076802, lng: 18.2347796 },
    { lat: 48.3089499, lng: 18.2328467 },
    { lat: 48.3086158, lng: 18.232101 },
    { lat: 48.3094462, lng: 18.2312401 },
    { lat: 48.30932, lng: 18.2309311 },
    { lat: 48.3099167, lng: 18.2303791 },
    { lat: 48.3103727, lng: 18.2298325 },
    { lat: 48.3106086, lng: 18.230244 },
    { lat: 48.3117235, lng: 18.2286432 },
    { lat: 48.3120596, lng: 18.2280711 },
    { lat: 48.3124738, lng: 18.2270744 },
    { lat: 48.3131795, lng: 18.2249151 },
    { lat: 48.3142816, lng: 18.2224459 },
    { lat: 48.3158559, lng: 18.2209847 },
    { lat: 48.3185807, lng: 18.2179346 },
    { lat: 48.3201538, lng: 18.2150878 },
    { lat: 48.32048, lng: 18.2143487 },
    { lat: 48.3205075, lng: 18.2143742 },
    { lat: 48.3219136, lng: 18.2119367 },
    { lat: 48.3230849, lng: 18.2101243 },
    { lat: 48.3231316, lng: 18.210053 },
    { lat: 48.3227181, lng: 18.2089555 },
    { lat: 48.3227109, lng: 18.2075436 },
    { lat: 48.3226552, lng: 18.2070112 },
    { lat: 48.3222475, lng: 18.2060502 },
    { lat: 48.3220314, lng: 18.2051235 },
    { lat: 48.3217452, lng: 18.204485 },
    { lat: 48.3213638, lng: 18.2026945 },
    { lat: 48.3211854, lng: 18.2024593 },
    { lat: 48.3206724, lng: 18.2020985 },
    { lat: 48.3202365, lng: 18.2013331 },
    { lat: 48.3200151, lng: 18.2007649 },
    { lat: 48.32037, lng: 18.2003854 },
    { lat: 48.3202658, lng: 18.2000852 },
    { lat: 48.3189017, lng: 18.1973461 },
    { lat: 48.3168352, lng: 18.1927461 },
    { lat: 48.3164329, lng: 18.1915513 },
    { lat: 48.3161054, lng: 18.1903061 },
    { lat: 48.3158386, lng: 18.189132 },
    { lat: 48.3156703, lng: 18.1880202 },
    { lat: 48.315066, lng: 18.1877942 },
    { lat: 48.3149829, lng: 18.1877848 },
    { lat: 48.3148165, lng: 18.1878923 },
    { lat: 48.3146177, lng: 18.186915 },
    { lat: 48.3145381, lng: 18.1850011 },
    { lat: 48.31451, lng: 18.1846917 },
    { lat: 48.3144154, lng: 18.1845414 },
    { lat: 48.314397, lng: 18.1844197 },
    { lat: 48.313833, lng: 18.1843817 },
    { lat: 48.313755, lng: 18.1841267 },
    { lat: 48.3136228, lng: 18.1841053 },
    { lat: 48.3131712, lng: 18.1843135 },
    { lat: 48.3128865, lng: 18.1844447 },
    { lat: 48.3125396, lng: 18.1847101 },
    { lat: 48.3121863, lng: 18.1847867 },
    { lat: 48.3117939, lng: 18.1847153 },
    { lat: 48.3116417, lng: 18.1825817 },
    { lat: 48.3113203, lng: 18.1825641 },
    { lat: 48.3108923, lng: 18.1824211 },
    { lat: 48.310852, lng: 18.1824282 },
    { lat: 48.3107, lng: 18.1824742 },
    { lat: 48.3098666, lng: 18.1824236 },
    { lat: 48.3095577, lng: 18.1825075 },
    { lat: 48.3090061, lng: 18.1825289 },
    { lat: 48.3082538, lng: 18.182267 },
    { lat: 48.3076368, lng: 18.1822296 },
    { lat: 48.3071639, lng: 18.1820351 },
    { lat: 48.3068372, lng: 18.1820076 },
    { lat: 48.3062954, lng: 18.1821212 },
    { lat: 48.3059669, lng: 18.1820394 },
    { lat: 48.3056538, lng: 18.1820671 },
    { lat: 48.3055964, lng: 18.1820722 },
    { lat: 48.3052783, lng: 18.1822303 },
    { lat: 48.3053445, lng: 18.1839504 },
    { lat: 48.3055156, lng: 18.1856744 },
    { lat: 48.3048727, lng: 18.185932 },
    { lat: 48.3049079, lng: 18.1862237 },
    { lat: 48.3047761, lng: 18.1863437 },
    { lat: 48.3049851, lng: 18.1876339 },
    { lat: 48.3060497, lng: 18.1904014 },
    { lat: 48.3060763, lng: 18.1904707 },
    { lat: 48.3063889, lng: 18.1919856 },
    { lat: 48.3063028, lng: 18.1921553 },
    { lat: 48.3059127, lng: 18.1922962 },
    { lat: 48.3056773, lng: 18.1926147 },
    { lat: 48.3051912, lng: 18.193007 },
    { lat: 48.3043277, lng: 18.1938597 },
    { lat: 48.3027795, lng: 18.1951433 },
    { lat: 48.302673, lng: 18.1951937 },
  ],
  Lukáčovce: [
    { lat: 48.3776162, lng: 17.9609485 },
    { lat: 48.3778114, lng: 17.9609462 },
    { lat: 48.3782042, lng: 17.9611338 },
    { lat: 48.3782636, lng: 17.9612962 },
    { lat: 48.3784237, lng: 17.9614613 },
    { lat: 48.3784488, lng: 17.9616145 },
    { lat: 48.3785505, lng: 17.9616945 },
    { lat: 48.3786945, lng: 17.9621182 },
    { lat: 48.3788422, lng: 17.9621522 },
    { lat: 48.3788539, lng: 17.9623309 },
    { lat: 48.3789399, lng: 17.9623136 },
    { lat: 48.3791254, lng: 17.9626223 },
    { lat: 48.379213, lng: 17.9628214 },
    { lat: 48.3792377, lng: 17.9630442 },
    { lat: 48.3793814, lng: 17.9632943 },
    { lat: 48.3795103, lng: 17.9638822 },
    { lat: 48.3795167, lng: 17.9641775 },
    { lat: 48.3796151, lng: 17.9642767 },
    { lat: 48.3796031, lng: 17.9644314 },
    { lat: 48.3795396, lng: 17.9644847 },
    { lat: 48.3796135, lng: 17.9646444 },
    { lat: 48.3795594, lng: 17.9647203 },
    { lat: 48.3797222, lng: 17.9651585 },
    { lat: 48.3797339, lng: 17.9655797 },
    { lat: 48.3798292, lng: 17.9657171 },
    { lat: 48.3798191, lng: 17.965908 },
    { lat: 48.3799818, lng: 17.9661158 },
    { lat: 48.3800231, lng: 17.9664099 },
    { lat: 48.3800915, lng: 17.9664979 },
    { lat: 48.3803893, lng: 17.9666278 },
    { lat: 48.380681, lng: 17.9666537 },
    { lat: 48.3808235, lng: 17.9667896 },
    { lat: 48.3809612, lng: 17.9667344 },
    { lat: 48.3811581, lng: 17.9668147 },
    { lat: 48.3812464, lng: 17.9669544 },
    { lat: 48.3813466, lng: 17.9669692 },
    { lat: 48.3816096, lng: 17.9672486 },
    { lat: 48.3817937, lng: 17.9676644 },
    { lat: 48.3819355, lng: 17.9678274 },
    { lat: 48.382094, lng: 17.9678166 },
    { lat: 48.3821939, lng: 17.9679608 },
    { lat: 48.3825563, lng: 17.9681313 },
    { lat: 48.382711, lng: 17.9683557 },
    { lat: 48.3829184, lng: 17.9683916 },
    { lat: 48.3833116, lng: 17.9687359 },
    { lat: 48.3834757, lng: 17.9686281 },
    { lat: 48.3841094, lng: 17.9687524 },
    { lat: 48.3842576, lng: 17.968982 },
    { lat: 48.3844005, lng: 17.9690852 },
    { lat: 48.3845829, lng: 17.9690787 },
    { lat: 48.385036, lng: 17.9693534 },
    { lat: 48.385244, lng: 17.9694141 },
    { lat: 48.3854854, lng: 17.9695984 },
    { lat: 48.385569, lng: 17.9698456 },
    { lat: 48.3857552, lng: 17.970036 },
    { lat: 48.3858105, lng: 17.9701298 },
    { lat: 48.3858367, lng: 17.9704717 },
    { lat: 48.3859821, lng: 17.9706368 },
    { lat: 48.3866863, lng: 17.9699742 },
    { lat: 48.3894476, lng: 17.9680031 },
    { lat: 48.3894757, lng: 17.9680565 },
    { lat: 48.389514, lng: 17.9680023 },
    { lat: 48.3920389, lng: 17.9645368 },
    { lat: 48.3948832, lng: 17.9647038 },
    { lat: 48.3983624, lng: 17.9604541 },
    { lat: 48.4016452, lng: 17.9560588 },
    { lat: 48.4047145, lng: 17.9526051 },
    { lat: 48.4079372, lng: 17.9495793 },
    { lat: 48.4083587, lng: 17.9489104 },
    { lat: 48.4086394, lng: 17.9488667 },
    { lat: 48.4088116, lng: 17.9489918 },
    { lat: 48.4089516, lng: 17.9489917 },
    { lat: 48.4091235, lng: 17.9492235 },
    { lat: 48.4092298, lng: 17.9491501 },
    { lat: 48.4093245, lng: 17.9492065 },
    { lat: 48.4098046, lng: 17.9489656 },
    { lat: 48.4100784, lng: 17.9487512 },
    { lat: 48.4101265, lng: 17.948631 },
    { lat: 48.4105208, lng: 17.948186 },
    { lat: 48.4108408, lng: 17.9475749 },
    { lat: 48.4109766, lng: 17.9475245 },
    { lat: 48.4111604, lng: 17.9472967 },
    { lat: 48.4111727, lng: 17.9470136 },
    { lat: 48.4110926, lng: 17.9460581 },
    { lat: 48.4112458, lng: 17.9453374 },
    { lat: 48.4117157, lng: 17.9446309 },
    { lat: 48.4137531, lng: 17.9434847 },
    { lat: 48.4138744, lng: 17.9431179 },
    { lat: 48.4140793, lng: 17.9427522 },
    { lat: 48.4143548, lng: 17.9418713 },
    { lat: 48.4150346, lng: 17.9402629 },
    { lat: 48.4151782, lng: 17.9398888 },
    { lat: 48.4155221, lng: 17.9388905 },
    { lat: 48.4158132, lng: 17.9380778 },
    { lat: 48.4179241, lng: 17.9340312 },
    { lat: 48.4184089, lng: 17.9327252 },
    { lat: 48.4191085, lng: 17.9316355 },
    { lat: 48.41946, lng: 17.9307242 },
    { lat: 48.4200949, lng: 17.9297946 },
    { lat: 48.4204817, lng: 17.9290018 },
    { lat: 48.421012, lng: 17.9280796 },
    { lat: 48.4212484, lng: 17.9274888 },
    { lat: 48.4215348, lng: 17.9262117 },
    { lat: 48.4218433, lng: 17.9252589 },
    { lat: 48.4225122, lng: 17.9241476 },
    { lat: 48.4228327, lng: 17.9232099 },
    { lat: 48.4237325, lng: 17.9208922 },
    { lat: 48.424495, lng: 17.919697 },
    { lat: 48.424295, lng: 17.919688 },
    { lat: 48.424125, lng: 17.919752 },
    { lat: 48.42388, lng: 17.919859 },
    { lat: 48.423665, lng: 17.919912 },
    { lat: 48.42338, lng: 17.919976 },
    { lat: 48.423111, lng: 17.919978 },
    { lat: 48.422952, lng: 17.920032 },
    { lat: 48.422821, lng: 17.919871 },
    { lat: 48.422576, lng: 17.91959 },
    { lat: 48.422298, lng: 17.919226 },
    { lat: 48.422123, lng: 17.918928 },
    { lat: 48.421774, lng: 17.918511 },
    { lat: 48.421425, lng: 17.918081 },
    { lat: 48.421348, lng: 17.917984 },
    { lat: 48.421183, lng: 17.917648 },
    { lat: 48.420966, lng: 17.917412 },
    { lat: 48.420611, lng: 17.917214 },
    { lat: 48.420293, lng: 17.917428 },
    { lat: 48.420025, lng: 17.917744 },
    { lat: 48.419919, lng: 17.917995 },
    { lat: 48.419832, lng: 17.918051 },
    { lat: 48.419388, lng: 17.917902 },
    { lat: 48.418847, lng: 17.917282 },
    { lat: 48.418658, lng: 17.916822 },
    { lat: 48.41846, lng: 17.91654 },
    { lat: 48.417937, lng: 17.916288 },
    { lat: 48.41762, lng: 17.915864 },
    { lat: 48.417227, lng: 17.915465 },
    { lat: 48.416592, lng: 17.914962 },
    { lat: 48.415824, lng: 17.914333 },
    { lat: 48.415547, lng: 17.914398 },
    { lat: 48.415332, lng: 17.91483 },
    { lat: 48.415235, lng: 17.914689 },
    { lat: 48.414867, lng: 17.91444 },
    { lat: 48.414564, lng: 17.914192 },
    { lat: 48.414301, lng: 17.914008 },
    { lat: 48.413754, lng: 17.913576 },
    { lat: 48.413543, lng: 17.913332 },
    { lat: 48.413507, lng: 17.913335 },
    { lat: 48.413073, lng: 17.912986 },
    { lat: 48.412888, lng: 17.912719 },
    { lat: 48.412451, lng: 17.912335 },
    { lat: 48.412271, lng: 17.912129 },
    { lat: 48.41179, lng: 17.911612 },
    { lat: 48.411766, lng: 17.911618 },
    { lat: 48.411563, lng: 17.911433 },
    { lat: 48.411258, lng: 17.911008 },
    { lat: 48.410977, lng: 17.910724 },
    { lat: 48.41086, lng: 17.910551 },
    { lat: 48.410763, lng: 17.910459 },
    { lat: 48.410373, lng: 17.910044 },
    { lat: 48.410077, lng: 17.909751 },
    { lat: 48.409736, lng: 17.909396 },
    { lat: 48.409644, lng: 17.909393 },
    { lat: 48.409555, lng: 17.90937 },
    { lat: 48.409474, lng: 17.90928 },
    { lat: 48.409467, lng: 17.909292 },
    { lat: 48.40925, lng: 17.909148 },
    { lat: 48.409066, lng: 17.909032 },
    { lat: 48.408895, lng: 17.908932 },
    { lat: 48.40869, lng: 17.908818 },
    { lat: 48.408309, lng: 17.90862 },
    { lat: 48.407933, lng: 17.908404 },
    { lat: 48.407623, lng: 17.908238 },
    { lat: 48.407178, lng: 17.908023 },
    { lat: 48.406738, lng: 17.907834 },
    { lat: 48.406463, lng: 17.90773 },
    { lat: 48.405962, lng: 17.907585 },
    { lat: 48.405459, lng: 17.907367 },
    { lat: 48.404965, lng: 17.907159 },
    { lat: 48.404657, lng: 17.907044 },
    { lat: 48.404273, lng: 17.906884 },
    { lat: 48.404128, lng: 17.906831 },
    { lat: 48.403662, lng: 17.906654 },
    { lat: 48.403271, lng: 17.906488 },
    { lat: 48.402879, lng: 17.906333 },
    { lat: 48.402546, lng: 17.906157 },
    { lat: 48.402176, lng: 17.905986 },
    { lat: 48.401825, lng: 17.90582 },
    { lat: 48.40154, lng: 17.905694 },
    { lat: 48.401258, lng: 17.905602 },
    { lat: 48.400852, lng: 17.905501 },
    { lat: 48.400589, lng: 17.905491 },
    { lat: 48.40033, lng: 17.905465 },
    { lat: 48.399639, lng: 17.905551 },
    { lat: 48.399114, lng: 17.90536 },
    { lat: 48.398523, lng: 17.905155 },
    { lat: 48.398523, lng: 17.905116 },
    { lat: 48.398478, lng: 17.905037 },
    { lat: 48.39817, lng: 17.904902 },
    { lat: 48.397865, lng: 17.904766 },
    { lat: 48.397545, lng: 17.904628 },
    { lat: 48.3974836, lng: 17.9046 },
    { lat: 48.39737, lng: 17.904548 },
    { lat: 48.397034, lng: 17.904417 },
    { lat: 48.396667, lng: 17.904334 },
    { lat: 48.396428, lng: 17.904669 },
    { lat: 48.3964, lng: 17.904601 },
    { lat: 48.396406, lng: 17.904601 },
    { lat: 48.396344, lng: 17.904412 },
    { lat: 48.396271, lng: 17.904062 },
    { lat: 48.396265, lng: 17.903605 },
    { lat: 48.396244, lng: 17.903526 },
    { lat: 48.396197, lng: 17.903466 },
    { lat: 48.396121, lng: 17.90323 },
    { lat: 48.396071, lng: 17.903138 },
    { lat: 48.396051, lng: 17.902866 },
    { lat: 48.396002, lng: 17.902682 },
    { lat: 48.39598, lng: 17.902481 },
    { lat: 48.395941, lng: 17.902275 },
    { lat: 48.395906, lng: 17.901882 },
    { lat: 48.395867, lng: 17.901467 },
    { lat: 48.395579, lng: 17.901183 },
    { lat: 48.395339, lng: 17.900952 },
    { lat: 48.3951223, lng: 17.9008807 },
    { lat: 48.395044, lng: 17.900855 },
    { lat: 48.394477, lng: 17.900629 },
    { lat: 48.394466, lng: 17.900653 },
    { lat: 48.394291, lng: 17.9005454 },
    { lat: 48.394183, lng: 17.900479 },
    { lat: 48.393848, lng: 17.900282 },
    { lat: 48.393707, lng: 17.900197 },
    { lat: 48.393362, lng: 17.900011 },
    { lat: 48.393048, lng: 17.899828 },
    { lat: 48.392824, lng: 17.899729 },
    { lat: 48.392677, lng: 17.899622 },
    { lat: 48.3926408, lng: 17.8996113 },
    { lat: 48.3926066, lng: 17.8998309 },
    { lat: 48.3920664, lng: 17.9009229 },
    { lat: 48.3907847, lng: 17.9039747 },
    { lat: 48.3901564, lng: 17.905848 },
    { lat: 48.3899558, lng: 17.9062614 },
    { lat: 48.3896297, lng: 17.9072918 },
    { lat: 48.3892467, lng: 17.9094312 },
    { lat: 48.3888789, lng: 17.910371 },
    { lat: 48.3883489, lng: 17.912154 },
    { lat: 48.3883894, lng: 17.9135723 },
    { lat: 48.3883306, lng: 17.9152694 },
    { lat: 48.3883501, lng: 17.9158008 },
    { lat: 48.387781, lng: 17.916525 },
    { lat: 48.387388, lng: 17.9178869 },
    { lat: 48.3867686, lng: 17.9187539 },
    { lat: 48.386065, lng: 17.9196061 },
    { lat: 48.3841667, lng: 17.9211457 },
    { lat: 48.3830081, lng: 17.921701 },
    { lat: 48.382716, lng: 17.9219289 },
    { lat: 48.3826356, lng: 17.9219065 },
    { lat: 48.380855, lng: 17.9225691 },
    { lat: 48.3776041, lng: 17.923132 },
    { lat: 48.3764155, lng: 17.9241241 },
    { lat: 48.3758086, lng: 17.9248812 },
    { lat: 48.3753621, lng: 17.9253382 },
    { lat: 48.3744193, lng: 17.9269426 },
    { lat: 48.3733755, lng: 17.9290254 },
    { lat: 48.3724681, lng: 17.9311248 },
    { lat: 48.3722644, lng: 17.9314727 },
    { lat: 48.3720473, lng: 17.931843 },
    { lat: 48.3729702, lng: 17.9328456 },
    { lat: 48.3732465, lng: 17.9330669 },
    { lat: 48.3735188, lng: 17.9332209 },
    { lat: 48.3740506, lng: 17.9333905 },
    { lat: 48.3756634, lng: 17.9336665 },
    { lat: 48.3765062, lng: 17.9339074 },
    { lat: 48.3767379, lng: 17.9342467 },
    { lat: 48.3770125, lng: 17.9350949 },
    { lat: 48.3771756, lng: 17.936455 },
    { lat: 48.37712, lng: 17.9372178 },
    { lat: 48.3769129, lng: 17.9386721 },
    { lat: 48.376849, lng: 17.9395397 },
    { lat: 48.3766748, lng: 17.9404346 },
    { lat: 48.3753514, lng: 17.9431264 },
    { lat: 48.3746752, lng: 17.9444018 },
    { lat: 48.3743519, lng: 17.944882 },
    { lat: 48.3736761, lng: 17.9466365 },
    { lat: 48.3734626, lng: 17.9478338 },
    { lat: 48.3736881, lng: 17.9482233 },
    { lat: 48.3729421, lng: 17.9505142 },
    { lat: 48.3726724, lng: 17.951494 },
    { lat: 48.372352, lng: 17.9529642 },
    { lat: 48.3718726, lng: 17.9534753 },
    { lat: 48.371895, lng: 17.9545618 },
    { lat: 48.3722162, lng: 17.9546479 },
    { lat: 48.3723442, lng: 17.9548607 },
    { lat: 48.3725837, lng: 17.9549983 },
    { lat: 48.3726933, lng: 17.9549235 },
    { lat: 48.3730841, lng: 17.9551344 },
    { lat: 48.3732703, lng: 17.9551347 },
    { lat: 48.3738467, lng: 17.9554448 },
    { lat: 48.374086, lng: 17.9558782 },
    { lat: 48.3742684, lng: 17.955965 },
    { lat: 48.3747043, lng: 17.9564574 },
    { lat: 48.3748855, lng: 17.9568624 },
    { lat: 48.3748421, lng: 17.9570767 },
    { lat: 48.3751343, lng: 17.9576425 },
    { lat: 48.3750437, lng: 17.9578032 },
    { lat: 48.3750494, lng: 17.9578979 },
    { lat: 48.375168, lng: 17.9581648 },
    { lat: 48.3753756, lng: 17.9583331 },
    { lat: 48.3755854, lng: 17.958762 },
    { lat: 48.3756497, lng: 17.9590604 },
    { lat: 48.3760163, lng: 17.9596037 },
    { lat: 48.3761475, lng: 17.959722 },
    { lat: 48.376416, lng: 17.9601319 },
    { lat: 48.3772096, lng: 17.9608504 },
    { lat: 48.3774631, lng: 17.9608211 },
    { lat: 48.3776162, lng: 17.9609485 },
  ],
  DolnéLefantovce: [
    { lat: 48.420514, lng: 18.112097 },
    { lat: 48.4201565, lng: 18.1122796 },
    { lat: 48.4199682, lng: 18.112533 },
    { lat: 48.4195952, lng: 18.1127894 },
    { lat: 48.419283, lng: 18.1127203 },
    { lat: 48.4191233, lng: 18.1125577 },
    { lat: 48.4190179, lng: 18.1123937 },
    { lat: 48.4189283, lng: 18.1121 },
    { lat: 48.418912, lng: 18.1118215 },
    { lat: 48.4190069, lng: 18.1108785 },
    { lat: 48.4189537, lng: 18.110186 },
    { lat: 48.4186735, lng: 18.109428 },
    { lat: 48.4185704, lng: 18.1094043 },
    { lat: 48.4180929, lng: 18.1089769 },
    { lat: 48.4177989, lng: 18.1088908 },
    { lat: 48.4167134, lng: 18.1082575 },
    { lat: 48.4164087, lng: 18.1081316 },
    { lat: 48.4161606, lng: 18.1080291 },
    { lat: 48.416062, lng: 18.1079284 },
    { lat: 48.4159366, lng: 18.1074438 },
    { lat: 48.4157067, lng: 18.1074472 },
    { lat: 48.4156339, lng: 18.1075563 },
    { lat: 48.4153544, lng: 18.1075411 },
    { lat: 48.4149542, lng: 18.1073756 },
    { lat: 48.4144316, lng: 18.1070089 },
    { lat: 48.4143982, lng: 18.1070755 },
    { lat: 48.4134109, lng: 18.1071533 },
    { lat: 48.4132572, lng: 18.107536 },
    { lat: 48.4130897, lng: 18.1076647 },
    { lat: 48.4129243, lng: 18.1075371 },
    { lat: 48.4126976, lng: 18.1068261 },
    { lat: 48.4122244, lng: 18.1063584 },
    { lat: 48.4112799, lng: 18.1064554 },
    { lat: 48.4110624, lng: 18.1066945 },
    { lat: 48.4109063, lng: 18.1069975 },
    { lat: 48.4106249, lng: 18.106989 },
    { lat: 48.4103597, lng: 18.1059513 },
    { lat: 48.4101331, lng: 18.1057252 },
    { lat: 48.4098671, lng: 18.1053438 },
    { lat: 48.4100212, lng: 18.1048751 },
    { lat: 48.4092542, lng: 18.1047852 },
    { lat: 48.4090944, lng: 18.1046963 },
    { lat: 48.4088036, lng: 18.1043554 },
    { lat: 48.4086848, lng: 18.1040085 },
    { lat: 48.4084903, lng: 18.1040591 },
    { lat: 48.4084236, lng: 18.1041511 },
    { lat: 48.408416, lng: 18.1042707 },
    { lat: 48.4085922, lng: 18.1046992 },
    { lat: 48.4085903, lng: 18.104779 },
    { lat: 48.4084831, lng: 18.104839 },
    { lat: 48.4082585, lng: 18.1046816 },
    { lat: 48.408099, lng: 18.1043912 },
    { lat: 48.4078014, lng: 18.104031 },
    { lat: 48.4075797, lng: 18.1039432 },
    { lat: 48.4071643, lng: 18.104482 },
    { lat: 48.4067011, lng: 18.1054899 },
    { lat: 48.4066457, lng: 18.1055299 },
    { lat: 48.4065394, lng: 18.1054662 },
    { lat: 48.4064209, lng: 18.1051807 },
    { lat: 48.4064895, lng: 18.1039647 },
    { lat: 48.4064358, lng: 18.1037427 },
    { lat: 48.4063259, lng: 18.1035616 },
    { lat: 48.4059898, lng: 18.1035457 },
    { lat: 48.4057703, lng: 18.1036689 },
    { lat: 48.4056051, lng: 18.1039326 },
    { lat: 48.4055363, lng: 18.1040107 },
    { lat: 48.4054765, lng: 18.1049534 },
    { lat: 48.405174, lng: 18.1053561 },
    { lat: 48.4050388, lng: 18.1054539 },
    { lat: 48.4048544, lng: 18.1055437 },
    { lat: 48.4046529, lng: 18.1055313 },
    { lat: 48.4045152, lng: 18.1063597 },
    { lat: 48.4047076, lng: 18.1064266 },
    { lat: 48.4044346, lng: 18.1072142 },
    { lat: 48.4041791, lng: 18.1075762 },
    { lat: 48.4037175, lng: 18.1080728 },
    { lat: 48.4033207, lng: 18.1086573 },
    { lat: 48.4031396, lng: 18.1090173 },
    { lat: 48.4027467, lng: 18.1104185 },
    { lat: 48.4036689, lng: 18.1102823 },
    { lat: 48.4043098, lng: 18.1103399 },
    { lat: 48.4041499, lng: 18.1114646 },
    { lat: 48.4044152, lng: 18.1115048 },
    { lat: 48.4045731, lng: 18.1158305 },
    { lat: 48.4048, lng: 18.1181822 },
    { lat: 48.4041054, lng: 18.1183099 },
    { lat: 48.4036051, lng: 18.1180127 },
    { lat: 48.4027783, lng: 18.1172692 },
    { lat: 48.402084, lng: 18.1182265 },
    { lat: 48.4016569, lng: 18.1186821 },
    { lat: 48.4024799, lng: 18.1215172 },
    { lat: 48.4027599, lng: 18.1222527 },
    { lat: 48.4017963, lng: 18.1229489 },
    { lat: 48.4026996, lng: 18.1264291 },
    { lat: 48.4030916, lng: 18.1275565 },
    { lat: 48.4035888, lng: 18.1293775 },
    { lat: 48.4038326, lng: 18.1309045 },
    { lat: 48.4038754, lng: 18.1311723 },
    { lat: 48.4039964, lng: 18.1311379 },
    { lat: 48.403982, lng: 18.1308723 },
    { lat: 48.4041295, lng: 18.1304828 },
    { lat: 48.404219, lng: 18.1303517 },
    { lat: 48.4044212, lng: 18.1302216 },
    { lat: 48.4044366, lng: 18.1303283 },
    { lat: 48.4043297, lng: 18.130509 },
    { lat: 48.4045073, lng: 18.1310193 },
    { lat: 48.4045455, lng: 18.1318052 },
    { lat: 48.4045002, lng: 18.1325556 },
    { lat: 48.4044463, lng: 18.1326363 },
    { lat: 48.4044696, lng: 18.1327848 },
    { lat: 48.4043001, lng: 18.1348852 },
    { lat: 48.4042159, lng: 18.1354923 },
    { lat: 48.4041702, lng: 18.1355601 },
    { lat: 48.4036654, lng: 18.1391484 },
    { lat: 48.404579, lng: 18.139814 },
    { lat: 48.4047471, lng: 18.1400643 },
    { lat: 48.4051531, lng: 18.1404835 },
    { lat: 48.4053073, lng: 18.1398978 },
    { lat: 48.4055317, lng: 18.1393351 },
    { lat: 48.4058622, lng: 18.1378474 },
    { lat: 48.4064979, lng: 18.136208 },
    { lat: 48.4069488, lng: 18.1348479 },
    { lat: 48.4073661, lng: 18.1350962 },
    { lat: 48.4082493, lng: 18.1349542 },
    { lat: 48.4087205, lng: 18.1346158 },
    { lat: 48.4091872, lng: 18.1343749 },
    { lat: 48.4102966, lng: 18.1340858 },
    { lat: 48.4110521, lng: 18.1334974 },
    { lat: 48.4114308, lng: 18.1329093 },
    { lat: 48.4117528, lng: 18.1321831 },
    { lat: 48.4123438, lng: 18.1311517 },
    { lat: 48.4123993, lng: 18.1312151 },
    { lat: 48.4126037, lng: 18.1308126 },
    { lat: 48.4125804, lng: 18.1307212 },
    { lat: 48.4127717, lng: 18.1301514 },
    { lat: 48.4132353, lng: 18.1282569 },
    { lat: 48.4145873, lng: 18.1289417 },
    { lat: 48.414747, lng: 18.1290226 },
    { lat: 48.4151598, lng: 18.1287092 },
    { lat: 48.4155687, lng: 18.1281152 },
    { lat: 48.4157917, lng: 18.1279171 },
    { lat: 48.4188821, lng: 18.1294869 },
    { lat: 48.4212723, lng: 18.1307784 },
    { lat: 48.4213931, lng: 18.1306644 },
    { lat: 48.4214044, lng: 18.1302593 },
    { lat: 48.4229362, lng: 18.1299171 },
    { lat: 48.4227698, lng: 18.130623 },
    { lat: 48.4256078, lng: 18.1311602 },
    { lat: 48.4259263, lng: 18.1297965 },
    { lat: 48.4270109, lng: 18.1259366 },
    { lat: 48.4268485, lng: 18.1258241 },
    { lat: 48.426879, lng: 18.12563 },
    { lat: 48.4286137, lng: 18.1160122 },
    { lat: 48.4293171, lng: 18.116289 },
    { lat: 48.4293954, lng: 18.1167019 },
    { lat: 48.4313767, lng: 18.1161161 },
    { lat: 48.4315991, lng: 18.1160572 },
    { lat: 48.431657, lng: 18.116087 },
    { lat: 48.43166, lng: 18.116034 },
    { lat: 48.431623, lng: 18.115778 },
    { lat: 48.431588, lng: 18.115511 },
    { lat: 48.431563, lng: 18.115324 },
    { lat: 48.431471, lng: 18.115125 },
    { lat: 48.431454, lng: 18.115086 },
    { lat: 48.431365, lng: 18.114874 },
    { lat: 48.431277, lng: 18.114799 },
    { lat: 48.431189, lng: 18.114717 },
    { lat: 48.431133, lng: 18.11468 },
    { lat: 48.431062, lng: 18.114644 },
    { lat: 48.431026, lng: 18.11464 },
    { lat: 48.430953, lng: 18.114704 },
    { lat: 48.430812, lng: 18.114914 },
    { lat: 48.430683, lng: 18.114911 },
    { lat: 48.430652, lng: 18.114914 },
    { lat: 48.430632, lng: 18.114897 },
    { lat: 48.430587, lng: 18.114825 },
    { lat: 48.430539, lng: 18.114769 },
    { lat: 48.430514, lng: 18.114736 },
    { lat: 48.430489, lng: 18.11472 },
    { lat: 48.43043, lng: 18.114677 },
    { lat: 48.430382, lng: 18.114645 },
    { lat: 48.430346, lng: 18.114624 },
    { lat: 48.430318, lng: 18.114609 },
    { lat: 48.43029, lng: 18.114599 },
    { lat: 48.43024, lng: 18.114585 },
    { lat: 48.430212, lng: 18.114575 },
    { lat: 48.430196, lng: 18.114576 },
    { lat: 48.430149, lng: 18.11458 },
    { lat: 48.430126, lng: 18.114581 },
    { lat: 48.430107, lng: 18.114583 },
    { lat: 48.430072, lng: 18.114562 },
    { lat: 48.430011, lng: 18.114531 },
    { lat: 48.429948, lng: 18.1145 },
    { lat: 48.429879, lng: 18.11444 },
    { lat: 48.429812, lng: 18.114398 },
    { lat: 48.429744, lng: 18.114344 },
    { lat: 48.429681, lng: 18.114308 },
    { lat: 48.429621, lng: 18.11427 },
    { lat: 48.429541, lng: 18.114212 },
    { lat: 48.429445, lng: 18.114137 },
    { lat: 48.429356, lng: 18.114061 },
    { lat: 48.429272, lng: 18.113984 },
    { lat: 48.429179, lng: 18.113897 },
    { lat: 48.429115, lng: 18.113837 },
    { lat: 48.429055, lng: 18.113782 },
    { lat: 48.429026, lng: 18.113726 },
    { lat: 48.429002, lng: 18.113611 },
    { lat: 48.428975, lng: 18.113489 },
    { lat: 48.428953, lng: 18.113379 },
    { lat: 48.428941, lng: 18.113251 },
    { lat: 48.42893, lng: 18.113193 },
    { lat: 48.428913, lng: 18.113123 },
    { lat: 48.42888, lng: 18.113073 },
    { lat: 48.428859, lng: 18.113039 },
    { lat: 48.42878, lng: 18.112998 },
    { lat: 48.428678, lng: 18.11294 },
    { lat: 48.42857, lng: 18.112884 },
    { lat: 48.428456, lng: 18.112851 },
    { lat: 48.428393, lng: 18.112838 },
    { lat: 48.428357, lng: 18.112832 },
    { lat: 48.428003, lng: 18.112789 },
    { lat: 48.427739, lng: 18.112698 },
    { lat: 48.427653, lng: 18.112729 },
    { lat: 48.427528, lng: 18.112774 },
    { lat: 48.427077, lng: 18.112794 },
    { lat: 48.426928, lng: 18.112801 },
    { lat: 48.4268503, lng: 18.1128155 },
    { lat: 48.426617, lng: 18.112861 },
    { lat: 48.426328, lng: 18.112942 },
    { lat: 48.425533, lng: 18.113315 },
    { lat: 48.425408, lng: 18.113373 },
    { lat: 48.425256, lng: 18.11341 },
    { lat: 48.424629, lng: 18.113529 },
    { lat: 48.424247, lng: 18.113593 },
    { lat: 48.424099, lng: 18.113614 },
    { lat: 48.423799, lng: 18.113685 },
    { lat: 48.423539, lng: 18.113718 },
    { lat: 48.423289, lng: 18.113769 },
    { lat: 48.42328, lng: 18.11377 },
    { lat: 48.423172, lng: 18.113801 },
    { lat: 48.422881, lng: 18.113813 },
    { lat: 48.422828, lng: 18.113778 },
    { lat: 48.422726, lng: 18.11371 },
    { lat: 48.422459, lng: 18.11372 },
    { lat: 48.42234, lng: 18.113737 },
    { lat: 48.422255, lng: 18.113728 },
    { lat: 48.42212, lng: 18.11369 },
    { lat: 48.422031, lng: 18.113651 },
    { lat: 48.421916, lng: 18.113628 },
    { lat: 48.421788, lng: 18.113644 },
    { lat: 48.421716, lng: 18.11364 },
    { lat: 48.421641, lng: 18.113569 },
    { lat: 48.421487, lng: 18.113323 },
    { lat: 48.421323, lng: 18.113183 },
    { lat: 48.421191, lng: 18.113005 },
    { lat: 48.42104, lng: 18.112637 },
    { lat: 48.421, lng: 18.112549 },
    { lat: 48.420926, lng: 18.112432 },
    { lat: 48.420794, lng: 18.112345 },
    { lat: 48.420692, lng: 18.112493 },
    { lat: 48.420514, lng: 18.112097 },
  ],
  Ľudovítová: [
    { lat: 48.410539, lng: 18.0457736 },
    { lat: 48.4105218, lng: 18.0457723 },
    { lat: 48.4094047, lng: 18.045279 },
    { lat: 48.4090327, lng: 18.0451709 },
    { lat: 48.4083687, lng: 18.0450888 },
    { lat: 48.407605, lng: 18.0448495 },
    { lat: 48.4073068, lng: 18.044862 },
    { lat: 48.4072988, lng: 18.0448822 },
    { lat: 48.4069137, lng: 18.0457365 },
    { lat: 48.4055627, lng: 18.0476799 },
    { lat: 48.4044559, lng: 18.0496094 },
    { lat: 48.4032071, lng: 18.0520785 },
    { lat: 48.4023223, lng: 18.0541604 },
    { lat: 48.3976972, lng: 18.0682353 },
    { lat: 48.3961377, lng: 18.073267 },
    { lat: 48.3954783, lng: 18.0728611 },
    { lat: 48.3941801, lng: 18.0775647 },
    { lat: 48.3940185, lng: 18.0777324 },
    { lat: 48.39377, lng: 18.0787442 },
    { lat: 48.3935861, lng: 18.0798563 },
    { lat: 48.3935531, lng: 18.0804843 },
    { lat: 48.3933294, lng: 18.081227 },
    { lat: 48.3932151, lng: 18.0818725 },
    { lat: 48.3927409, lng: 18.0845508 },
    { lat: 48.3923497, lng: 18.0864571 },
    { lat: 48.3918992, lng: 18.0879674 },
    { lat: 48.3913406, lng: 18.089061 },
    { lat: 48.3904017, lng: 18.0903865 },
    { lat: 48.3893533, lng: 18.0914584 },
    { lat: 48.3893024, lng: 18.0915954 },
    { lat: 48.3895037, lng: 18.0921542 },
    { lat: 48.3896682, lng: 18.0924499 },
    { lat: 48.3897396, lng: 18.0925782 },
    { lat: 48.3898592, lng: 18.0933459 },
    { lat: 48.3906704, lng: 18.0940327 },
    { lat: 48.3908055, lng: 18.0942754 },
    { lat: 48.3909659, lng: 18.0940883 },
    { lat: 48.3909881, lng: 18.0939067 },
    { lat: 48.391049, lng: 18.0938117 },
    { lat: 48.3911672, lng: 18.093731 },
    { lat: 48.3916713, lng: 18.0947784 },
    { lat: 48.3917883, lng: 18.0949067 },
    { lat: 48.3918618, lng: 18.0948888 },
    { lat: 48.3919171, lng: 18.0948517 },
    { lat: 48.3920109, lng: 18.0945086 },
    { lat: 48.3921512, lng: 18.0942241 },
    { lat: 48.3923691, lng: 18.0940379 },
    { lat: 48.3925009, lng: 18.0934185 },
    { lat: 48.3925819, lng: 18.0932807 },
    { lat: 48.3926669, lng: 18.0933422 },
    { lat: 48.3927243, lng: 18.0935299 },
    { lat: 48.392773, lng: 18.0938434 },
    { lat: 48.3927708, lng: 18.0943209 },
    { lat: 48.3928771, lng: 18.0945973 },
    { lat: 48.3930567, lng: 18.0947304 },
    { lat: 48.3933595, lng: 18.0948124 },
    { lat: 48.3934036, lng: 18.0946627 },
    { lat: 48.3940289, lng: 18.0939751 },
    { lat: 48.3941626, lng: 18.0941931 },
    { lat: 48.3941678, lng: 18.0946481 },
    { lat: 48.3939792, lng: 18.0948549 },
    { lat: 48.393653, lng: 18.0948331 },
    { lat: 48.3934959, lng: 18.0948891 },
    { lat: 48.3932653, lng: 18.0954591 },
    { lat: 48.3932795, lng: 18.0958127 },
    { lat: 48.3935521, lng: 18.0965166 },
    { lat: 48.3937285, lng: 18.0967535 },
    { lat: 48.3938923, lng: 18.0967562 },
    { lat: 48.3948261, lng: 18.0965891 },
    { lat: 48.3948755, lng: 18.0964523 },
    { lat: 48.3950354, lng: 18.0963125 },
    { lat: 48.3951996, lng: 18.0961682 },
    { lat: 48.3955535, lng: 18.0953326 },
    { lat: 48.3958776, lng: 18.0943024 },
    { lat: 48.3962658, lng: 18.0922089 },
    { lat: 48.3964802, lng: 18.0914429 },
    { lat: 48.3979339, lng: 18.0848452 },
    { lat: 48.3980038, lng: 18.0833337 },
    { lat: 48.3985993, lng: 18.081888 },
    { lat: 48.3988098, lng: 18.0812254 },
    { lat: 48.3992576, lng: 18.0790205 },
    { lat: 48.3990291, lng: 18.0788193 },
    { lat: 48.3997082, lng: 18.0755391 },
    { lat: 48.3998397, lng: 18.0751497 },
    { lat: 48.401256, lng: 18.0694076 },
    { lat: 48.4014647, lng: 18.068737 },
    { lat: 48.4025574, lng: 18.0641518 },
    { lat: 48.4032211, lng: 18.0610593 },
    { lat: 48.4034959, lng: 18.0593096 },
    { lat: 48.4039699, lng: 18.0571333 },
    { lat: 48.4040709, lng: 18.0568574 },
    { lat: 48.4045596, lng: 18.056171 },
    { lat: 48.4061519, lng: 18.0542795 },
    { lat: 48.4081502, lng: 18.0522854 },
    { lat: 48.4085791, lng: 18.051772 },
    { lat: 48.4103885, lng: 18.047291 },
    { lat: 48.4104598, lng: 18.0468776 },
    { lat: 48.4105327, lng: 18.0458593 },
    { lat: 48.410539, lng: 18.0457736 },
  ],
  Vinodol: [
    { lat: 48.201607, lng: 18.190549 },
    { lat: 48.201573, lng: 18.190715 },
    { lat: 48.201527, lng: 18.190801 },
    { lat: 48.201473, lng: 18.190842 },
    { lat: 48.201397, lng: 18.190859 },
    { lat: 48.201297, lng: 18.190852 },
    { lat: 48.201229, lng: 18.190829 },
    { lat: 48.201063, lng: 18.190747 },
    { lat: 48.200925, lng: 18.190702 },
    { lat: 48.20073, lng: 18.190665 },
    { lat: 48.200548, lng: 18.190655 },
    { lat: 48.200374, lng: 18.190692 },
    { lat: 48.200219, lng: 18.190771 },
    { lat: 48.200113, lng: 18.190828 },
    { lat: 48.200004, lng: 18.190834 },
    { lat: 48.199893, lng: 18.19078 },
    { lat: 48.199742, lng: 18.190712 },
    { lat: 48.199612, lng: 18.190699 },
    { lat: 48.199519, lng: 18.190721 },
    { lat: 48.199343, lng: 18.1908 },
    { lat: 48.199225, lng: 18.190801 },
    { lat: 48.19917, lng: 18.190769 },
    { lat: 48.19911, lng: 18.190686 },
    { lat: 48.199052, lng: 18.190509 },
    { lat: 48.199053, lng: 18.190366 },
    { lat: 48.199047, lng: 18.190262 },
    { lat: 48.199015, lng: 18.190024 },
    { lat: 48.198943, lng: 18.189909 },
    { lat: 48.198798, lng: 18.189879 },
    { lat: 48.198715, lng: 18.189891 },
    { lat: 48.198625, lng: 18.189953 },
    { lat: 48.198583, lng: 18.190043 },
    { lat: 48.198543, lng: 18.190153 },
    { lat: 48.198501, lng: 18.190347 },
    { lat: 48.198442, lng: 18.190539 },
    { lat: 48.198344, lng: 18.190848 },
    { lat: 48.198279, lng: 18.190991 },
    { lat: 48.198152, lng: 18.191242 },
    { lat: 48.198084, lng: 18.19138 },
    { lat: 48.198017, lng: 18.191565 },
    { lat: 48.197984, lng: 18.191689 },
    { lat: 48.197971, lng: 18.191806 },
    { lat: 48.197984, lng: 18.191887 },
    { lat: 48.198044, lng: 18.191977 },
    { lat: 48.19814, lng: 18.192028 },
    { lat: 48.198197, lng: 18.192084 },
    { lat: 48.198265, lng: 18.192223 },
    { lat: 48.198293, lng: 18.192355 },
    { lat: 48.198163, lng: 18.192434 },
    { lat: 48.198083, lng: 18.192542 },
    { lat: 48.19798, lng: 18.192303 },
    { lat: 48.197843, lng: 18.191982 },
    { lat: 48.197821, lng: 18.191956 },
    { lat: 48.197708, lng: 18.191808 },
    { lat: 48.197613, lng: 18.19187 },
    { lat: 48.197533, lng: 18.191915 },
    { lat: 48.197371, lng: 18.192079 },
    { lat: 48.197243, lng: 18.192399 },
    { lat: 48.197343, lng: 18.192688 },
    { lat: 48.197435, lng: 18.192822 },
    { lat: 48.19762, lng: 18.193069 },
    { lat: 48.197858, lng: 18.193198 },
    { lat: 48.198031, lng: 18.193298 },
    { lat: 48.198479, lng: 18.193253 },
    { lat: 48.198581, lng: 18.193186 },
    { lat: 48.198634, lng: 18.19339 },
    { lat: 48.19869, lng: 18.193536 },
    { lat: 48.19868, lng: 18.193616 },
    { lat: 48.198629, lng: 18.193718 },
    { lat: 48.198577, lng: 18.193769 },
    { lat: 48.198505, lng: 18.193811 },
    { lat: 48.198394, lng: 18.193866 },
    { lat: 48.198283, lng: 18.193941 },
    { lat: 48.198144, lng: 18.194066 },
    { lat: 48.198091, lng: 18.194131 },
    { lat: 48.198012, lng: 18.19422 },
    { lat: 48.197925, lng: 18.194302 },
    { lat: 48.197845, lng: 18.194331 },
    { lat: 48.197782, lng: 18.194318 },
    { lat: 48.197722, lng: 18.19429 },
    { lat: 48.197665, lng: 18.194222 },
    { lat: 48.197621, lng: 18.194127 },
    { lat: 48.197603, lng: 18.194009 },
    { lat: 48.197613, lng: 18.19387 },
    { lat: 48.197625, lng: 18.193675 },
    { lat: 48.197617, lng: 18.193554 },
    { lat: 48.197587, lng: 18.193416 },
    { lat: 48.197543, lng: 18.193341 },
    { lat: 48.197436, lng: 18.193248 },
    { lat: 48.19736, lng: 18.193178 },
    { lat: 48.197212, lng: 18.193118 },
    { lat: 48.197121, lng: 18.193103 },
    { lat: 48.196972, lng: 18.193048 },
    { lat: 48.196763, lng: 18.192957 },
    { lat: 48.196599, lng: 18.193003 },
    { lat: 48.196481, lng: 18.193091 },
    { lat: 48.196359, lng: 18.193213 },
    { lat: 48.196182, lng: 18.193359 },
    { lat: 48.196098, lng: 18.193443 },
    { lat: 48.195977, lng: 18.193614 },
    { lat: 48.195943, lng: 18.193694 },
    { lat: 48.19592, lng: 18.193799 },
    { lat: 48.19591, lng: 18.193915 },
    { lat: 48.19591, lng: 18.194011 },
    { lat: 48.195945, lng: 18.194201 },
    { lat: 48.196046, lng: 18.194406 },
    { lat: 48.196181, lng: 18.194577 },
    { lat: 48.19637, lng: 18.194782 },
    { lat: 48.196501, lng: 18.19492 },
    { lat: 48.196525, lng: 18.195045 },
    { lat: 48.196494, lng: 18.19514 },
    { lat: 48.196448, lng: 18.195188 },
    { lat: 48.196381, lng: 18.19519 },
    { lat: 48.196265, lng: 18.195156 },
    { lat: 48.196177, lng: 18.195161 },
    { lat: 48.196114, lng: 18.195185 },
    { lat: 48.196038, lng: 18.195257 },
    { lat: 48.19599, lng: 18.195349 },
    { lat: 48.195821, lng: 18.19524 },
    { lat: 48.195796, lng: 18.195216 },
    { lat: 48.195754, lng: 18.195128 },
    { lat: 48.195705, lng: 18.195068 },
    { lat: 48.195631, lng: 18.19509 },
    { lat: 48.195558, lng: 18.195143 },
    { lat: 48.195517, lng: 18.195207 },
    { lat: 48.195515, lng: 18.195412 },
    { lat: 48.195579, lng: 18.195504 },
    { lat: 48.195695, lng: 18.195634 },
    { lat: 48.195843, lng: 18.195734 },
    { lat: 48.1959, lng: 18.195794 },
    { lat: 48.195996, lng: 18.195875 },
    { lat: 48.196084, lng: 18.195927 },
    { lat: 48.196128, lng: 18.195934 },
    { lat: 48.196156, lng: 18.195931 },
    { lat: 48.196171, lng: 18.195922 },
    { lat: 48.196256, lng: 18.195821 },
    { lat: 48.19633, lng: 18.195891 },
    { lat: 48.196383, lng: 18.195976 },
    { lat: 48.196426, lng: 18.196108 },
    { lat: 48.196416, lng: 18.196205 },
    { lat: 48.196366, lng: 18.196301 },
    { lat: 48.196304, lng: 18.196361 },
    { lat: 48.196245, lng: 18.196391 },
    { lat: 48.196182, lng: 18.196434 },
    { lat: 48.196121, lng: 18.196431 },
    { lat: 48.196079, lng: 18.196397 },
    { lat: 48.196034, lng: 18.196352 },
    { lat: 48.195959, lng: 18.196329 },
    { lat: 48.195801, lng: 18.196311 },
    { lat: 48.195579, lng: 18.196317 },
    { lat: 48.195361, lng: 18.196353 },
    { lat: 48.195149, lng: 18.196396 },
    { lat: 48.194841, lng: 18.196619 },
    { lat: 48.194713, lng: 18.196739 },
    { lat: 48.19464, lng: 18.19678 },
    { lat: 48.194564, lng: 18.196809 },
    { lat: 48.194281, lng: 18.196891 },
    { lat: 48.194105, lng: 18.196941 },
    { lat: 48.19395, lng: 18.197039 },
    { lat: 48.193832, lng: 18.197086 },
    { lat: 48.193692, lng: 18.197162 },
    { lat: 48.193669, lng: 18.196982 },
    { lat: 48.193785, lng: 18.196534 },
    { lat: 48.193849, lng: 18.196305 },
    { lat: 48.193992, lng: 18.196022 },
    { lat: 48.194122, lng: 18.195776 },
    { lat: 48.194143, lng: 18.19558 },
    { lat: 48.19413, lng: 18.195474 },
    { lat: 48.194167, lng: 18.195379 },
    { lat: 48.194235, lng: 18.195303 },
    { lat: 48.194272, lng: 18.195206 },
    { lat: 48.194295, lng: 18.19504 },
    { lat: 48.194286, lng: 18.194904 },
    { lat: 48.19417, lng: 18.194625 },
    { lat: 48.193923, lng: 18.194301 },
    { lat: 48.193783, lng: 18.194092 },
    { lat: 48.193676, lng: 18.194011 },
    { lat: 48.193582, lng: 18.193991 },
    { lat: 48.193471, lng: 18.193978 },
    { lat: 48.193391, lng: 18.193967 },
    { lat: 48.193137, lng: 18.193936 },
    { lat: 48.19296, lng: 18.193931 },
    { lat: 48.192818, lng: 18.193859 },
    { lat: 48.192697, lng: 18.193732 },
    { lat: 48.192545, lng: 18.1935 },
    { lat: 48.192456, lng: 18.193352 },
    { lat: 48.192383, lng: 18.193131 },
    { lat: 48.192373, lng: 18.19306 },
    { lat: 48.192266, lng: 18.192975 },
    { lat: 48.192149, lng: 18.192981 },
    { lat: 48.191873, lng: 18.192977 },
    { lat: 48.191531, lng: 18.193026 },
    { lat: 48.191398, lng: 18.193008 },
    { lat: 48.191297, lng: 18.192765 },
    { lat: 48.191058, lng: 18.192612 },
    { lat: 48.190893, lng: 18.192614 },
    { lat: 48.190784, lng: 18.192366 },
    { lat: 48.190618, lng: 18.191963 },
    { lat: 48.190449, lng: 18.191681 },
    { lat: 48.190184, lng: 18.191238 },
    { lat: 48.189996, lng: 18.190927 },
    { lat: 48.189751, lng: 18.190673 },
    { lat: 48.189401, lng: 18.190312 },
    { lat: 48.189133, lng: 18.190335 },
    { lat: 48.188978, lng: 18.190048 },
    { lat: 48.188809, lng: 18.189734 },
    { lat: 48.188601, lng: 18.189346 },
    { lat: 48.188536, lng: 18.189229 },
    { lat: 48.188327, lng: 18.188722 },
    { lat: 48.188287, lng: 18.188612 },
    { lat: 48.188058, lng: 18.187921 },
    { lat: 48.187967, lng: 18.18764 },
    { lat: 48.187827, lng: 18.187205 },
    { lat: 48.187748, lng: 18.187033 },
    { lat: 48.187564, lng: 18.18663 },
    { lat: 48.187404, lng: 18.186276 },
    { lat: 48.187388, lng: 18.186101 },
    { lat: 48.187277, lng: 18.186075 },
    { lat: 48.187218, lng: 18.186049 },
    { lat: 48.18716, lng: 18.185998 },
    { lat: 48.186979, lng: 18.185797 },
    { lat: 48.186862, lng: 18.185633 },
    { lat: 48.186748, lng: 18.185504 },
    { lat: 48.18663, lng: 18.185394 },
    { lat: 48.186496, lng: 18.185292 },
    { lat: 48.18641, lng: 18.185209 },
    { lat: 48.186263, lng: 18.184998 },
    { lat: 48.186176, lng: 18.184859 },
    { lat: 48.186118, lng: 18.184692 },
    { lat: 48.186058, lng: 18.184475 },
    { lat: 48.186011, lng: 18.184132 },
    { lat: 48.186011, lng: 18.18395 },
    { lat: 48.186117, lng: 18.18344 },
    { lat: 48.186185, lng: 18.183093 },
    { lat: 48.186248, lng: 18.182858 },
    { lat: 48.186316, lng: 18.182672 },
    { lat: 48.186391, lng: 18.18254 },
    { lat: 48.186519, lng: 18.182345 },
    { lat: 48.186659, lng: 18.182149 },
    { lat: 48.186756, lng: 18.181987 },
    { lat: 48.186793, lng: 18.181913 },
    { lat: 48.186816, lng: 18.181803 },
    { lat: 48.186773, lng: 18.181619 },
    { lat: 48.186683, lng: 18.181489 },
    { lat: 48.186596, lng: 18.181438 },
    { lat: 48.186494, lng: 18.18141 },
    { lat: 48.186297, lng: 18.18136 },
    { lat: 48.186209, lng: 18.181344 },
    { lat: 48.186058, lng: 18.181336 },
    { lat: 48.185691, lng: 18.181353 },
    { lat: 48.18527, lng: 18.181395 },
    { lat: 48.185147, lng: 18.181386 },
    { lat: 48.184902, lng: 18.181345 },
    { lat: 48.184606, lng: 18.181274 },
    { lat: 48.184452, lng: 18.181222 },
    { lat: 48.184334, lng: 18.181161 },
    { lat: 48.184212, lng: 18.181091 },
    { lat: 48.184129, lng: 18.180991 },
    { lat: 48.184021, lng: 18.180834 },
    { lat: 48.183896, lng: 18.180667 },
    { lat: 48.183743, lng: 18.180566 },
    { lat: 48.183607, lng: 18.180563 },
    { lat: 48.183516, lng: 18.180697 },
    { lat: 48.183465, lng: 18.1809 },
    { lat: 48.183405, lng: 18.181066 },
    { lat: 48.183315, lng: 18.181191 },
    { lat: 48.183133, lng: 18.181339 },
    { lat: 48.182757, lng: 18.181474 },
    { lat: 48.182607, lng: 18.181518 },
    { lat: 48.182489, lng: 18.181549 },
    { lat: 48.182393, lng: 18.181528 },
    { lat: 48.182192, lng: 18.181459 },
    { lat: 48.182048, lng: 18.181347 },
    { lat: 48.181835, lng: 18.18111 },
    { lat: 48.18167, lng: 18.180926 },
    { lat: 48.181581, lng: 18.18089 },
    { lat: 48.181478, lng: 18.180921 },
    { lat: 48.181454, lng: 18.181027 },
    { lat: 48.181452, lng: 18.181154 },
    { lat: 48.18148, lng: 18.181326 },
    { lat: 48.18155, lng: 18.181542 },
    { lat: 48.181627, lng: 18.181756 },
    { lat: 48.181668, lng: 18.181934 },
    { lat: 48.181732, lng: 18.182264 },
    { lat: 48.181763, lng: 18.182464 },
    { lat: 48.181774, lng: 18.182642 },
    { lat: 48.181728, lng: 18.182936 },
    { lat: 48.181692, lng: 18.183218 },
    { lat: 48.181699, lng: 18.183401 },
    { lat: 48.181726, lng: 18.183558 },
    { lat: 48.18179, lng: 18.183691 },
    { lat: 48.181912, lng: 18.18384 },
    { lat: 48.182054, lng: 18.184019 },
    { lat: 48.182155, lng: 18.184178 },
    { lat: 48.182202, lng: 18.184284 },
    { lat: 48.182225, lng: 18.184382 },
    { lat: 48.18222, lng: 18.184447 },
    { lat: 48.182185, lng: 18.184593 },
    { lat: 48.182107, lng: 18.184744 },
    { lat: 48.182034, lng: 18.184792 },
    { lat: 48.181949, lng: 18.184803 },
    { lat: 48.181732, lng: 18.184773 },
    { lat: 48.181582, lng: 18.184733 },
    { lat: 48.181491, lng: 18.184732 },
    { lat: 48.181408, lng: 18.184753 },
    { lat: 48.181338, lng: 18.184788 },
    { lat: 48.181264, lng: 18.18486 },
    { lat: 48.181183, lng: 18.185012 },
    { lat: 48.181135, lng: 18.185139 },
    { lat: 48.181125, lng: 18.185267 },
    { lat: 48.181125, lng: 18.185492 },
    { lat: 48.18113, lng: 18.185719 },
    { lat: 48.181117, lng: 18.185842 },
    { lat: 48.18106, lng: 18.186048 },
    { lat: 48.181005, lng: 18.186172 },
    { lat: 48.180934, lng: 18.186299 },
    { lat: 48.180889, lng: 18.186349 },
    { lat: 48.180733, lng: 18.186472 },
    { lat: 48.180651, lng: 18.186514 },
    { lat: 48.180594, lng: 18.18653 },
    { lat: 48.180496, lng: 18.186497 },
    { lat: 48.180303, lng: 18.18642 },
    { lat: 48.180196, lng: 18.186391 },
    { lat: 48.180094, lng: 18.186378 },
    { lat: 48.17989, lng: 18.186358 },
    { lat: 48.179779, lng: 18.186352 },
    { lat: 48.17973, lng: 18.186338 },
    { lat: 48.179666, lng: 18.186318 },
    { lat: 48.17952, lng: 18.186316 },
    { lat: 48.179397, lng: 18.186282 },
    { lat: 48.179199, lng: 18.186266 },
    { lat: 48.179053, lng: 18.186235 },
    { lat: 48.178967, lng: 18.186176 },
    { lat: 48.178761, lng: 18.186068 },
    { lat: 48.178617, lng: 18.186009 },
    { lat: 48.178525, lng: 18.186016 },
    { lat: 48.178389, lng: 18.186084 },
    { lat: 48.178306, lng: 18.186168 },
    { lat: 48.178222, lng: 18.186317 },
    { lat: 48.178181, lng: 18.186419 },
    { lat: 48.178071, lng: 18.186504 },
    { lat: 48.177966, lng: 18.186522 },
    { lat: 48.177758, lng: 18.186471 },
    { lat: 48.177602, lng: 18.186406 },
    { lat: 48.177501, lng: 18.186343 },
    { lat: 48.177422, lng: 18.186271 },
    { lat: 48.177286, lng: 18.186171 },
    { lat: 48.177208, lng: 18.186134 },
    { lat: 48.17711, lng: 18.186134 },
    { lat: 48.176972, lng: 18.18621 },
    { lat: 48.176869, lng: 18.18629 },
    { lat: 48.176811, lng: 18.186356 },
    { lat: 48.176719, lng: 18.186539 },
    { lat: 48.176665, lng: 18.186622 },
    { lat: 48.176582, lng: 18.186662 },
    { lat: 48.176472, lng: 18.186674 },
    { lat: 48.176354, lng: 18.186646 },
    { lat: 48.176191, lng: 18.186524 },
    { lat: 48.176009, lng: 18.186332 },
    { lat: 48.175865, lng: 18.186232 },
    { lat: 48.175754, lng: 18.186139 },
    { lat: 48.175441, lng: 18.185854 },
    { lat: 48.175256, lng: 18.185739 },
    { lat: 48.175121, lng: 18.185695 },
    { lat: 48.175017, lng: 18.185703 },
    { lat: 48.174918, lng: 18.185774 },
    { lat: 48.174789, lng: 18.185828 },
    { lat: 48.174253, lng: 18.186304 },
    { lat: 48.17416, lng: 18.186324 },
    { lat: 48.173996, lng: 18.186304 },
    { lat: 48.173807, lng: 18.186113 },
    { lat: 48.17365, lng: 18.185846 },
    { lat: 48.173496, lng: 18.185727 },
    { lat: 48.173345, lng: 18.185739 },
    { lat: 48.173239, lng: 18.185779 },
    { lat: 48.17299, lng: 18.185966 },
    { lat: 48.172821, lng: 18.186173 },
    { lat: 48.172618, lng: 18.186452 },
    { lat: 48.172566, lng: 18.186504 },
    { lat: 48.172411, lng: 18.186509 },
    { lat: 48.172347, lng: 18.186487 },
    { lat: 48.172052, lng: 18.186123 },
    { lat: 48.17194, lng: 18.186133 },
    { lat: 48.171849, lng: 18.186155 },
    { lat: 48.171754, lng: 18.18629 },
    { lat: 48.171703, lng: 18.186385 },
    { lat: 48.171386, lng: 18.187074 },
    { lat: 48.171352, lng: 18.187145 },
    { lat: 48.171336, lng: 18.187179 },
    { lat: 48.171068, lng: 18.187442 },
    { lat: 48.170596, lng: 18.18782 },
    { lat: 48.170518, lng: 18.187974 },
    { lat: 48.170439, lng: 18.188158 },
    { lat: 48.170406, lng: 18.188348 },
    { lat: 48.170402, lng: 18.188593 },
    { lat: 48.170413, lng: 18.18874 },
    { lat: 48.170477, lng: 18.188924 },
    { lat: 48.170532, lng: 18.189051 },
    { lat: 48.170664, lng: 18.189216 },
    { lat: 48.170851, lng: 18.18929 },
    { lat: 48.171436, lng: 18.189498 },
    { lat: 48.171552, lng: 18.189625 },
    { lat: 48.17161, lng: 18.189892 },
    { lat: 48.171613, lng: 18.190207 },
    { lat: 48.171585, lng: 18.190458 },
    { lat: 48.171514, lng: 18.190661 },
    { lat: 48.171318, lng: 18.191045 },
    { lat: 48.171127, lng: 18.191188 },
    { lat: 48.170812, lng: 18.191301 },
    { lat: 48.170695, lng: 18.191295 },
    { lat: 48.17063, lng: 18.191261 },
    { lat: 48.170485, lng: 18.191131 },
    { lat: 48.1703, lng: 18.190848 },
    { lat: 48.170233, lng: 18.190792 },
    { lat: 48.170154, lng: 18.190751 },
    { lat: 48.170049, lng: 18.190768 },
    { lat: 48.16998, lng: 18.190822 },
    { lat: 48.169931, lng: 18.190891 },
    { lat: 48.169879, lng: 18.191125 },
    { lat: 48.169851, lng: 18.191322 },
    { lat: 48.16984, lng: 18.191548 },
    { lat: 48.169947, lng: 18.192077 },
    { lat: 48.169954, lng: 18.192219 },
    { lat: 48.16989, lng: 18.192326 },
    { lat: 48.169798, lng: 18.192386 },
    { lat: 48.169732, lng: 18.192379 },
    { lat: 48.169682, lng: 18.192338 },
    { lat: 48.169649, lng: 18.192264 },
    { lat: 48.169626, lng: 18.192085 },
    { lat: 48.169609, lng: 18.191893 },
    { lat: 48.169552, lng: 18.191649 },
    { lat: 48.169419, lng: 18.191522 },
    { lat: 48.169217, lng: 18.191561 },
    { lat: 48.1691, lng: 18.191615 },
    { lat: 48.168921, lng: 18.19173 },
    { lat: 48.168853, lng: 18.191811 },
    { lat: 48.168782, lng: 18.191905 },
    { lat: 48.168726, lng: 18.1921 },
    { lat: 48.168665, lng: 18.192544 },
    { lat: 48.168687, lng: 18.192643 },
    { lat: 48.168749, lng: 18.192769 },
    { lat: 48.168848, lng: 18.192904 },
    { lat: 48.168999, lng: 18.193007 },
    { lat: 48.169129, lng: 18.193089 },
    { lat: 48.169363, lng: 18.193296 },
    { lat: 48.169435, lng: 18.1934 },
    { lat: 48.169532, lng: 18.193605 },
    { lat: 48.169565, lng: 18.193733 },
    { lat: 48.169591, lng: 18.193982 },
    { lat: 48.169611, lng: 18.194078 },
    { lat: 48.169636, lng: 18.194119 },
    { lat: 48.169665, lng: 18.194151 },
    { lat: 48.1698, lng: 18.194238 },
    { lat: 48.170106, lng: 18.19434 },
    { lat: 48.170416, lng: 18.194357 },
    { lat: 48.170826, lng: 18.194293 },
    { lat: 48.170839, lng: 18.194331 },
    { lat: 48.170948, lng: 18.194365 },
    { lat: 48.17117, lng: 18.19449 },
    { lat: 48.171264, lng: 18.194597 },
    { lat: 48.171308, lng: 18.194859 },
    { lat: 48.171241, lng: 18.195141 },
    { lat: 48.171136, lng: 18.195316 },
    { lat: 48.170972, lng: 18.19549 },
    { lat: 48.17085, lng: 18.195568 },
    { lat: 48.170719, lng: 18.195585 },
    { lat: 48.170638, lng: 18.195567 },
    { lat: 48.170479, lng: 18.195441 },
    { lat: 48.170298, lng: 18.195278 },
    { lat: 48.170077, lng: 18.195111 },
    { lat: 48.169963, lng: 18.195071 },
    { lat: 48.169677, lng: 18.195037 },
    { lat: 48.169551, lng: 18.195046 },
    { lat: 48.169447, lng: 18.195104 },
    { lat: 48.169398, lng: 18.19518 },
    { lat: 48.169374, lng: 18.195278 },
    { lat: 48.169369, lng: 18.19553 },
    { lat: 48.169453, lng: 18.195917 },
    { lat: 48.169526, lng: 18.196103 },
    { lat: 48.169747, lng: 18.196668 },
    { lat: 48.169752, lng: 18.196872 },
    { lat: 48.169729, lng: 18.196984 },
    { lat: 48.169699, lng: 18.19704 },
    { lat: 48.169646, lng: 18.197101 },
    { lat: 48.169467, lng: 18.197128 },
    { lat: 48.169365, lng: 18.197049 },
    { lat: 48.169104, lng: 18.196847 },
    { lat: 48.169035, lng: 18.196834 },
    { lat: 48.168968, lng: 18.19689 },
    { lat: 48.16894, lng: 18.196952 },
    { lat: 48.168921, lng: 18.197276 },
    { lat: 48.168921, lng: 18.197926 },
    { lat: 48.168998, lng: 18.198672 },
    { lat: 48.169067, lng: 18.198889 },
    { lat: 48.169151, lng: 18.199039 },
    { lat: 48.169399, lng: 18.199265 },
    { lat: 48.169565, lng: 18.199442 },
    { lat: 48.169653, lng: 18.199587 },
    { lat: 48.169717, lng: 18.199759 },
    { lat: 48.16982, lng: 18.200153 },
    { lat: 48.16985, lng: 18.200299 },
    { lat: 48.169854, lng: 18.20041 },
    { lat: 48.169819, lng: 18.200516 },
    { lat: 48.169759, lng: 18.200604 },
    { lat: 48.169705, lng: 18.200617 },
    { lat: 48.169638, lng: 18.200613 },
    { lat: 48.169492, lng: 18.200534 },
    { lat: 48.169393, lng: 18.200409 },
    { lat: 48.169362, lng: 18.200342 },
    { lat: 48.16926, lng: 18.200168 },
    { lat: 48.169197, lng: 18.200092 },
    { lat: 48.169141, lng: 18.200065 },
    { lat: 48.169084, lng: 18.200071 },
    { lat: 48.169012, lng: 18.200147 },
    { lat: 48.168964, lng: 18.200309 },
    { lat: 48.168924, lng: 18.20075 },
    { lat: 48.168923, lng: 18.200877 },
    { lat: 48.168942, lng: 18.20112 },
    { lat: 48.169045, lng: 18.201463 },
    { lat: 48.169145, lng: 18.201673 },
    { lat: 48.169523, lng: 18.202022 },
    { lat: 48.169683, lng: 18.2022 },
    { lat: 48.169739, lng: 18.202304 },
    { lat: 48.169799, lng: 18.202449 },
    { lat: 48.169905, lng: 18.202758 },
    { lat: 48.170118, lng: 18.203194 },
    { lat: 48.170173, lng: 18.203384 },
    { lat: 48.170176, lng: 18.203437 },
    { lat: 48.170282, lng: 18.203353 },
    { lat: 48.170645, lng: 18.203558 },
    { lat: 48.172425, lng: 18.207372 },
    { lat: 48.172948, lng: 18.208734 },
    { lat: 48.17301, lng: 18.210176 },
    { lat: 48.173017, lng: 18.21034 },
    { lat: 48.173028, lng: 18.210429 },
    { lat: 48.173055, lng: 18.210475 },
    { lat: 48.172977, lng: 18.210642 },
    { lat: 48.172967, lng: 18.210827 },
    { lat: 48.172965, lng: 18.21088 },
    { lat: 48.173039, lng: 18.211133 },
    { lat: 48.173132, lng: 18.211249 },
    { lat: 48.173447, lng: 18.211645 },
    { lat: 48.173437, lng: 18.211922 },
    { lat: 48.173391, lng: 18.212073 },
    { lat: 48.173318, lng: 18.212149 },
    { lat: 48.172993, lng: 18.212431 },
    { lat: 48.173083, lng: 18.212526 },
    { lat: 48.173311, lng: 18.212768 },
    { lat: 48.17351, lng: 18.212977 },
    { lat: 48.173772, lng: 18.213411 },
    { lat: 48.17395, lng: 18.214093 },
    { lat: 48.173719, lng: 18.214895 },
    { lat: 48.17354, lng: 18.215509 },
    { lat: 48.173581, lng: 18.21637 },
    { lat: 48.173611, lng: 18.21639 },
    { lat: 48.174191, lng: 18.216788 },
    { lat: 48.174491, lng: 18.217005 },
    { lat: 48.174946, lng: 18.21745 },
    { lat: 48.175727, lng: 18.21893 },
    { lat: 48.176497, lng: 18.22037 },
    { lat: 48.176543, lng: 18.220486 },
    { lat: 48.177253, lng: 18.222796 },
    { lat: 48.178001, lng: 18.225425 },
    { lat: 48.178302, lng: 18.226955 },
    { lat: 48.178347, lng: 18.227298 },
    { lat: 48.178376, lng: 18.227736 },
    { lat: 48.178389, lng: 18.22795 },
    { lat: 48.178384, lng: 18.22818 },
    { lat: 48.17837, lng: 18.228324 },
    { lat: 48.178289, lng: 18.228655 },
    { lat: 48.178141, lng: 18.22899 },
    { lat: 48.177909, lng: 18.229461 },
    { lat: 48.177723, lng: 18.229686 },
    { lat: 48.177754, lng: 18.230147 },
    { lat: 48.177842, lng: 18.230423 },
    { lat: 48.177867, lng: 18.230809 },
    { lat: 48.177914, lng: 18.231158 },
    { lat: 48.178065, lng: 18.231603 },
    { lat: 48.178032, lng: 18.232005 },
    { lat: 48.1780305, lng: 18.232119 },
    { lat: 48.178023, lng: 18.232681 },
    { lat: 48.178153, lng: 18.233674 },
    { lat: 48.178252, lng: 18.233995 },
    { lat: 48.178516, lng: 18.234507 },
    { lat: 48.178653, lng: 18.234827 },
    { lat: 48.178698, lng: 18.235074 },
    { lat: 48.178728, lng: 18.235629 },
    { lat: 48.178938, lng: 18.236531 },
    { lat: 48.178898, lng: 18.236545 },
    { lat: 48.178971, lng: 18.23669 },
    { lat: 48.178951, lng: 18.236956 },
    { lat: 48.179391, lng: 18.238008 },
    { lat: 48.179812, lng: 18.239443 },
    { lat: 48.180088, lng: 18.240936 },
    { lat: 48.180112, lng: 18.240999 },
    { lat: 48.180434, lng: 18.241423 },
    { lat: 48.180854, lng: 18.241972 },
    { lat: 48.181505, lng: 18.242905 },
    { lat: 48.181892, lng: 18.243367 },
    { lat: 48.181933, lng: 18.243416 },
    { lat: 48.181994, lng: 18.243487 },
    { lat: 48.182233, lng: 18.24362 },
    { lat: 48.182286, lng: 18.243649 },
    { lat: 48.182886, lng: 18.243983 },
    { lat: 48.18383, lng: 18.244601 },
    { lat: 48.184172, lng: 18.244823 },
    { lat: 48.184287, lng: 18.245942 },
    { lat: 48.184307, lng: 18.245962 },
    { lat: 48.184342, lng: 18.245994 },
    { lat: 48.184384, lng: 18.246044 },
    { lat: 48.184507, lng: 18.246015 },
    { lat: 48.184537, lng: 18.246009 },
    { lat: 48.184586, lng: 18.246001 },
    { lat: 48.184693, lng: 18.245974 },
    { lat: 48.18473, lng: 18.245966 },
    { lat: 48.184762, lng: 18.245958 },
    { lat: 48.184934, lng: 18.245947 },
    { lat: 48.185127, lng: 18.245939 },
    { lat: 48.185175, lng: 18.245933 },
    { lat: 48.185231, lng: 18.245934 },
    { lat: 48.185285, lng: 18.245928 },
    { lat: 48.185447, lng: 18.245959 },
    { lat: 48.185594, lng: 18.245986 },
    { lat: 48.185741, lng: 18.246011 },
    { lat: 48.185892, lng: 18.246037 },
    { lat: 48.18614, lng: 18.246082 },
    { lat: 48.186186, lng: 18.246088 },
    { lat: 48.186312, lng: 18.246106 },
    { lat: 48.186434, lng: 18.246123 },
    { lat: 48.1865, lng: 18.246132 },
    { lat: 48.186562, lng: 18.24614 },
    { lat: 48.186831, lng: 18.246177 },
    { lat: 48.187352, lng: 18.246229 },
    { lat: 48.187385, lng: 18.246218 },
    { lat: 48.188154, lng: 18.24604 },
    { lat: 48.188414, lng: 18.245981 },
    { lat: 48.188875, lng: 18.245871 },
    { lat: 48.18926, lng: 18.245782 },
    { lat: 48.189549, lng: 18.245717 },
    { lat: 48.19003, lng: 18.245669 },
    { lat: 48.190501, lng: 18.245617 },
    { lat: 48.190813, lng: 18.245614 },
    { lat: 48.19117, lng: 18.245605 },
    { lat: 48.191255, lng: 18.245603 },
    { lat: 48.191895, lng: 18.245591 },
    { lat: 48.192483, lng: 18.24558 },
    { lat: 48.192934, lng: 18.245571 },
    { lat: 48.193261, lng: 18.245783 },
    { lat: 48.193631, lng: 18.246026 },
    { lat: 48.193836, lng: 18.246093 },
    { lat: 48.194245, lng: 18.246233 },
    { lat: 48.194509, lng: 18.246031 },
    { lat: 48.194803, lng: 18.245803 },
    { lat: 48.195246, lng: 18.245455 },
    { lat: 48.195365, lng: 18.245378 },
    { lat: 48.195817, lng: 18.245077 },
    { lat: 48.196264, lng: 18.244775 },
    { lat: 48.19666, lng: 18.24451 },
    { lat: 48.196816, lng: 18.244406 },
    { lat: 48.197334, lng: 18.244059 },
    { lat: 48.197818, lng: 18.243733 },
    { lat: 48.19841, lng: 18.243337 },
    { lat: 48.198658, lng: 18.243247 },
    { lat: 48.199335, lng: 18.242986 },
    { lat: 48.199421, lng: 18.242953 },
    { lat: 48.199541, lng: 18.242916 },
    { lat: 48.199638, lng: 18.242888 },
    { lat: 48.199869, lng: 18.242769 },
    { lat: 48.200137, lng: 18.242628 },
    { lat: 48.200458, lng: 18.242459 },
    { lat: 48.200709, lng: 18.24233 },
    { lat: 48.200946, lng: 18.24227 },
    { lat: 48.201233, lng: 18.242196 },
    { lat: 48.201526, lng: 18.242121 },
    { lat: 48.201812, lng: 18.242051 },
    { lat: 48.202009, lng: 18.241935 },
    { lat: 48.202277, lng: 18.241776 },
    { lat: 48.202587, lng: 18.241593 },
    { lat: 48.202777, lng: 18.241484 },
    { lat: 48.202915, lng: 18.24128 },
    { lat: 48.203006, lng: 18.241148 },
    { lat: 48.203109, lng: 18.240996 },
    { lat: 48.203317, lng: 18.240687 },
    { lat: 48.203439, lng: 18.240505 },
    { lat: 48.203561, lng: 18.240319 },
    { lat: 48.203782, lng: 18.23997 },
    { lat: 48.203977, lng: 18.239668 },
    { lat: 48.204135, lng: 18.239419 },
    { lat: 48.204279, lng: 18.239193 },
    { lat: 48.204358, lng: 18.239076 },
    { lat: 48.2046018, lng: 18.2385927 },
    { lat: 48.2057202, lng: 18.2367554 },
    { lat: 48.2059302, lng: 18.2364111 },
    { lat: 48.2062578, lng: 18.2357569 },
    { lat: 48.2063885, lng: 18.2354958 },
    { lat: 48.2066307, lng: 18.2348051 },
    { lat: 48.2071405, lng: 18.2336677 },
    { lat: 48.2073421, lng: 18.2333078 },
    { lat: 48.2094776, lng: 18.2294956 },
    { lat: 48.2096864, lng: 18.2292008 },
    { lat: 48.2099612, lng: 18.2286091 },
    { lat: 48.2102239, lng: 18.2278597 },
    { lat: 48.2105522, lng: 18.2262436 },
    { lat: 48.2105429, lng: 18.2252405 },
    { lat: 48.2105453, lng: 18.2251885 },
    { lat: 48.2104828, lng: 18.2244392 },
    { lat: 48.2107184, lng: 18.2235377 },
    { lat: 48.2108518, lng: 18.2225249 },
    { lat: 48.210767, lng: 18.2210444 },
    { lat: 48.2107687, lng: 18.2199421 },
    { lat: 48.2096471, lng: 18.2153254 },
    { lat: 48.208615, lng: 18.21325 },
    { lat: 48.2081323, lng: 18.2096919 },
    { lat: 48.2076565, lng: 18.2076212 },
    { lat: 48.2076269, lng: 18.2072861 },
    { lat: 48.2073092, lng: 18.205854 },
    { lat: 48.2057023, lng: 18.201481 },
    { lat: 48.2049881, lng: 18.1996961 },
    { lat: 48.2043632, lng: 18.1976018 },
    { lat: 48.2037712, lng: 18.1958429 },
    { lat: 48.2034378, lng: 18.1946309 },
    { lat: 48.2033104, lng: 18.193952 },
    { lat: 48.2026737, lng: 18.1927261 },
    { lat: 48.2018219, lng: 18.1905791 },
    { lat: 48.2017278, lng: 18.1906285 },
    { lat: 48.201607, lng: 18.190549 },
  ],
  Žitavce: [
    { lat: 48.2042614, lng: 18.2940117 },
    { lat: 48.2039776, lng: 18.2938054 },
    { lat: 48.2036977, lng: 18.2937638 },
    { lat: 48.2035021, lng: 18.2938529 },
    { lat: 48.2033683, lng: 18.2940405 },
    { lat: 48.2031682, lng: 18.2946826 },
    { lat: 48.2030796, lng: 18.2946353 },
    { lat: 48.2030489, lng: 18.2945317 },
    { lat: 48.2029119, lng: 18.2944842 },
    { lat: 48.2026282, lng: 18.2944721 },
    { lat: 48.202556, lng: 18.294534 },
    { lat: 48.2024997, lng: 18.2948715 },
    { lat: 48.2024556, lng: 18.2949264 },
    { lat: 48.2025189, lng: 18.2951068 },
    { lat: 48.202445, lng: 18.2953539 },
    { lat: 48.2021745, lng: 18.2954568 },
    { lat: 48.2020509, lng: 18.2956633 },
    { lat: 48.2019792, lng: 18.2960095 },
    { lat: 48.201686, lng: 18.2959919 },
    { lat: 48.2017452, lng: 18.2956642 },
    { lat: 48.201715, lng: 18.2954715 },
    { lat: 48.2019188, lng: 18.2952675 },
    { lat: 48.2019372, lng: 18.2949369 },
    { lat: 48.2020252, lng: 18.2948216 },
    { lat: 48.2020192, lng: 18.2943619 },
    { lat: 48.2017416, lng: 18.2936894 },
    { lat: 48.201606, lng: 18.2936283 },
    { lat: 48.2015391, lng: 18.2937203 },
    { lat: 48.2013889, lng: 18.2937388 },
    { lat: 48.2013239, lng: 18.2938632 },
    { lat: 48.2013172, lng: 18.2940242 },
    { lat: 48.201367, lng: 18.2940638 },
    { lat: 48.2011974, lng: 18.2945538 },
    { lat: 48.2011134, lng: 18.2947588 },
    { lat: 48.200897, lng: 18.2949389 },
    { lat: 48.2005155, lng: 18.2950359 },
    { lat: 48.2004357, lng: 18.2950058 },
    { lat: 48.2000473, lng: 18.2953742 },
    { lat: 48.1998553, lng: 18.2952715 },
    { lat: 48.199749, lng: 18.295289 },
    { lat: 48.1996504, lng: 18.2953168 },
    { lat: 48.1994021, lng: 18.2955732 },
    { lat: 48.199003, lng: 18.2955607 },
    { lat: 48.1990137, lng: 18.2951151 },
    { lat: 48.1992355, lng: 18.2942407 },
    { lat: 48.1992474, lng: 18.2939952 },
    { lat: 48.1992088, lng: 18.2936762 },
    { lat: 48.1989815, lng: 18.2928742 },
    { lat: 48.1989288, lng: 18.2926882 },
    { lat: 48.1988366, lng: 18.2924981 },
    { lat: 48.1986177, lng: 18.2923585 },
    { lat: 48.1984891, lng: 18.2923803 },
    { lat: 48.1984095, lng: 18.2924676 },
    { lat: 48.1982976, lng: 18.292739 },
    { lat: 48.1982601, lng: 18.2931081 },
    { lat: 48.198114, lng: 18.2934709 },
    { lat: 48.1979631, lng: 18.2937343 },
    { lat: 48.1977093, lng: 18.2938535 },
    { lat: 48.1975724, lng: 18.2937083 },
    { lat: 48.1974843, lng: 18.293372 },
    { lat: 48.1973541, lng: 18.2931093 },
    { lat: 48.1970791, lng: 18.2929185 },
    { lat: 48.1966815, lng: 18.2927721 },
    { lat: 48.1964193, lng: 18.2923916 },
    { lat: 48.1959183, lng: 18.2919593 },
    { lat: 48.1958247, lng: 18.2917199 },
    { lat: 48.1957469, lng: 18.2913062 },
    { lat: 48.1953333, lng: 18.2905292 },
    { lat: 48.1953229, lng: 18.2901706 },
    { lat: 48.1950706, lng: 18.2901587 },
    { lat: 48.1950663, lng: 18.2900624 },
    { lat: 48.1948818, lng: 18.2901521 },
    { lat: 48.1946616, lng: 18.2904067 },
    { lat: 48.1946059, lng: 18.2905563 },
    { lat: 48.1945816, lng: 18.2909997 },
    { lat: 48.1943878, lng: 18.2913976 },
    { lat: 48.1943185, lng: 18.2917803 },
    { lat: 48.1942744, lng: 18.2918602 },
    { lat: 48.1940747, lng: 18.2919576 },
    { lat: 48.194065, lng: 18.2925385 },
    { lat: 48.1938566, lng: 18.2927399 },
    { lat: 48.1936768, lng: 18.2927339 },
    { lat: 48.1936072, lng: 18.2930335 },
    { lat: 48.1936854, lng: 18.2933548 },
    { lat: 48.193642, lng: 18.2936825 },
    { lat: 48.1937942, lng: 18.293953 },
    { lat: 48.1935348, lng: 18.2942341 },
    { lat: 48.193332, lng: 18.294303 },
    { lat: 48.193274, lng: 18.294326 },
    { lat: 48.193248, lng: 18.294336 },
    { lat: 48.193153, lng: 18.294337 },
    { lat: 48.193088, lng: 18.294334 },
    { lat: 48.193036, lng: 18.294353 },
    { lat: 48.193011, lng: 18.294353 },
    { lat: 48.192986, lng: 18.294354 },
    { lat: 48.19295, lng: 18.294408 },
    { lat: 48.192892, lng: 18.294495 },
    { lat: 48.192831, lng: 18.294583 },
    { lat: 48.192825, lng: 18.294595 },
    { lat: 48.192818, lng: 18.294609 },
    { lat: 48.192811, lng: 18.294623 },
    { lat: 48.192792, lng: 18.294659 },
    { lat: 48.192758, lng: 18.294722 },
    { lat: 48.192714, lng: 18.294818 },
    { lat: 48.192645, lng: 18.294961 },
    { lat: 48.192627, lng: 18.294998 },
    { lat: 48.192585, lng: 18.295149 },
    { lat: 48.19253, lng: 18.295411 },
    { lat: 48.192512, lng: 18.295486 },
    { lat: 48.192511, lng: 18.29549 },
    { lat: 48.192503, lng: 18.295495 },
    { lat: 48.192437, lng: 18.295525 },
    { lat: 48.192407, lng: 18.295505 },
    { lat: 48.192369, lng: 18.295447 },
    { lat: 48.192364, lng: 18.295439 },
    { lat: 48.192106, lng: 18.295055 },
    { lat: 48.192031, lng: 18.295052 },
    { lat: 48.191964, lng: 18.295111 },
    { lat: 48.191802, lng: 18.295418 },
    { lat: 48.191764, lng: 18.295491 },
    { lat: 48.191745, lng: 18.295528 },
    { lat: 48.191727, lng: 18.295564 },
    { lat: 48.191649, lng: 18.295713 },
    { lat: 48.191546, lng: 18.295904 },
    { lat: 48.191495, lng: 18.295999 },
    { lat: 48.191428, lng: 18.295993 },
    { lat: 48.19141, lng: 18.295992 },
    { lat: 48.191317, lng: 18.295961 },
    { lat: 48.191302, lng: 18.295956 },
    { lat: 48.191187, lng: 18.295911 },
    { lat: 48.191152, lng: 18.295837 },
    { lat: 48.191153, lng: 18.295635 },
    { lat: 48.191126, lng: 18.29554 },
    { lat: 48.191116, lng: 18.295509 },
    { lat: 48.191053, lng: 18.295394 },
    { lat: 48.191049, lng: 18.295387 },
    { lat: 48.191025, lng: 18.295374 },
    { lat: 48.190932, lng: 18.295325 },
    { lat: 48.190915, lng: 18.295316 },
    { lat: 48.190859, lng: 18.29531 },
    { lat: 48.190758, lng: 18.295319 },
    { lat: 48.190712, lng: 18.295347 },
    { lat: 48.190689, lng: 18.295361 },
    { lat: 48.190667, lng: 18.295379 },
    { lat: 48.190632, lng: 18.295426 },
    { lat: 48.190615, lng: 18.295449 },
    { lat: 48.190598, lng: 18.295472 },
    { lat: 48.190534, lng: 18.29555 },
    { lat: 48.190455, lng: 18.295621 },
    { lat: 48.190298, lng: 18.295619 },
    { lat: 48.190174, lng: 18.295608 },
    { lat: 48.189966, lng: 18.295617 },
    { lat: 48.189917, lng: 18.295571 },
    { lat: 48.189877, lng: 18.295539 },
    { lat: 48.189815, lng: 18.29552 },
    { lat: 48.189797, lng: 18.295527 },
    { lat: 48.18978, lng: 18.295535 },
    { lat: 48.189747, lng: 18.295545 },
    { lat: 48.189714, lng: 18.295585 },
    { lat: 48.189679, lng: 18.295627 },
    { lat: 48.18962, lng: 18.295693 },
    { lat: 48.189541, lng: 18.29575 },
    { lat: 48.18948, lng: 18.295777 },
    { lat: 48.189422, lng: 18.295788 },
    { lat: 48.189378, lng: 18.295783 },
    { lat: 48.189359, lng: 18.295736 },
    { lat: 48.189335, lng: 18.295676 },
    { lat: 48.189321, lng: 18.295642 },
    { lat: 48.189299, lng: 18.295619 },
    { lat: 48.18924, lng: 18.295561 },
    { lat: 48.189211, lng: 18.295543 },
    { lat: 48.189123, lng: 18.295487 },
    { lat: 48.189069, lng: 18.295454 },
    { lat: 48.188923, lng: 18.2954 },
    { lat: 48.188763, lng: 18.295376 },
    { lat: 48.188681, lng: 18.295364 },
    { lat: 48.188639, lng: 18.29532 },
    { lat: 48.188625, lng: 18.295269 },
    { lat: 48.188601, lng: 18.295251 },
    { lat: 48.188559, lng: 18.295295 },
    { lat: 48.188493, lng: 18.295461 },
    { lat: 48.188452, lng: 18.295525 },
    { lat: 48.188441, lng: 18.295528 },
    { lat: 48.188408, lng: 18.295539 },
    { lat: 48.188387, lng: 18.295536 },
    { lat: 48.188335, lng: 18.295528 },
    { lat: 48.188286, lng: 18.295497 },
    { lat: 48.188245, lng: 18.295435 },
    { lat: 48.188187, lng: 18.295252 },
    { lat: 48.188177, lng: 18.29506 },
    { lat: 48.18814, lng: 18.295014 },
    { lat: 48.188083, lng: 18.295016 },
    { lat: 48.188033, lng: 18.295038 },
    { lat: 48.187946, lng: 18.295057 },
    { lat: 48.18794, lng: 18.295057 },
    { lat: 48.187854, lng: 18.295056 },
    { lat: 48.187848, lng: 18.295056 },
    { lat: 48.187801, lng: 18.295055 },
    { lat: 48.187761, lng: 18.295054 },
    { lat: 48.187698, lng: 18.295068 },
    { lat: 48.187694, lng: 18.29507 },
    { lat: 48.187633, lng: 18.295132 },
    { lat: 48.187593, lng: 18.295207 },
    { lat: 48.18759, lng: 18.295209 },
    { lat: 48.187558, lng: 18.295213 },
    { lat: 48.187472, lng: 18.295072 },
    { lat: 48.187401, lng: 18.294971 },
    { lat: 48.187383, lng: 18.294944 },
    { lat: 48.18737, lng: 18.294939 },
    { lat: 48.187337, lng: 18.294927 },
    { lat: 48.187182, lng: 18.294935 },
    { lat: 48.187144, lng: 18.294962 },
    { lat: 48.187081, lng: 18.295006 },
    { lat: 48.187066, lng: 18.295017 },
    { lat: 48.187052, lng: 18.295028 },
    { lat: 48.187037, lng: 18.295039 },
    { lat: 48.187025, lng: 18.295047 },
    { lat: 48.187016, lng: 18.295056 },
    { lat: 48.186877, lng: 18.295283 },
    { lat: 48.186838, lng: 18.295337 },
    { lat: 48.186834, lng: 18.295343 },
    { lat: 48.186724, lng: 18.295376 },
    { lat: 48.186657, lng: 18.295394 },
    { lat: 48.18659, lng: 18.295374 },
    { lat: 48.186585, lng: 18.295363 },
    { lat: 48.186575, lng: 18.295343 },
    { lat: 48.186561, lng: 18.295308 },
    { lat: 48.186531, lng: 18.295233 },
    { lat: 48.186499, lng: 18.295185 },
    { lat: 48.186401, lng: 18.295174 },
    { lat: 48.186368, lng: 18.295171 },
    { lat: 48.186328, lng: 18.295152 },
    { lat: 48.186294, lng: 18.295071 },
    { lat: 48.186226, lng: 18.294822 },
    { lat: 48.186219, lng: 18.294794 },
    { lat: 48.186139, lng: 18.294783 },
    { lat: 48.186043, lng: 18.294952 },
    { lat: 48.186026, lng: 18.29497 },
    { lat: 48.18599, lng: 18.295011 },
    { lat: 48.185984, lng: 18.295073 },
    { lat: 48.185981, lng: 18.29512 },
    { lat: 48.185997, lng: 18.295192 },
    { lat: 48.186002, lng: 18.295218 },
    { lat: 48.185991, lng: 18.295233 },
    { lat: 48.185964, lng: 18.295267 },
    { lat: 48.185943, lng: 18.295292 },
    { lat: 48.185787, lng: 18.295415 },
    { lat: 48.185667, lng: 18.295429 },
    { lat: 48.185583, lng: 18.295328 },
    { lat: 48.185532, lng: 18.295153 },
    { lat: 48.185541, lng: 18.295048 },
    { lat: 48.185545, lng: 18.294997 },
    { lat: 48.185546, lng: 18.294967 },
    { lat: 48.185548, lng: 18.294942 },
    { lat: 48.18552, lng: 18.294886 },
    { lat: 48.185506, lng: 18.294855 },
    { lat: 48.185472, lng: 18.294829 },
    { lat: 48.185339, lng: 18.294732 },
    { lat: 48.185246, lng: 18.294697 },
    { lat: 48.185205, lng: 18.294712 },
    { lat: 48.185185, lng: 18.294718 },
    { lat: 48.185163, lng: 18.294762 },
    { lat: 48.185135, lng: 18.294818 },
    { lat: 48.185123, lng: 18.294839 },
    { lat: 48.185078, lng: 18.294884 },
    { lat: 48.185031, lng: 18.294856 },
    { lat: 48.184977, lng: 18.294741 },
    { lat: 48.184974, lng: 18.294716 },
    { lat: 48.184964, lng: 18.294634 },
    { lat: 48.184955, lng: 18.294557 },
    { lat: 48.184951, lng: 18.294515 },
    { lat: 48.184956, lng: 18.2945 },
    { lat: 48.184975, lng: 18.294443 },
    { lat: 48.184997, lng: 18.294383 },
    { lat: 48.184998, lng: 18.294237 },
    { lat: 48.184966, lng: 18.294164 },
    { lat: 48.184947, lng: 18.294078 },
    { lat: 48.184948, lng: 18.294041 },
    { lat: 48.18495, lng: 18.293989 },
    { lat: 48.184951, lng: 18.293942 },
    { lat: 48.184924, lng: 18.293864 },
    { lat: 48.184908, lng: 18.29382 },
    { lat: 48.18485, lng: 18.293757 },
    { lat: 48.184789, lng: 18.29376 },
    { lat: 48.184748, lng: 18.293787 },
    { lat: 48.184711, lng: 18.293814 },
    { lat: 48.18467, lng: 18.293854 },
    { lat: 48.184576, lng: 18.294077 },
    { lat: 48.184558, lng: 18.294135 },
    { lat: 48.184547, lng: 18.294178 },
    { lat: 48.184541, lng: 18.294199 },
    { lat: 48.184528, lng: 18.294253 },
    { lat: 48.184513, lng: 18.294315 },
    { lat: 48.184515, lng: 18.294395 },
    { lat: 48.184515, lng: 18.29445 },
    { lat: 48.184417, lng: 18.294626 },
    { lat: 48.184373, lng: 18.294678 },
    { lat: 48.1843, lng: 18.294713 },
    { lat: 48.184243, lng: 18.2947 },
    { lat: 48.18418, lng: 18.294648 },
    { lat: 48.184119, lng: 18.29441 },
    { lat: 48.1841, lng: 18.294241 },
    { lat: 48.184123, lng: 18.294086 },
    { lat: 48.184146, lng: 18.294026 },
    { lat: 48.184169, lng: 18.293969 },
    { lat: 48.184191, lng: 18.293918 },
    { lat: 48.184219, lng: 18.293855 },
    { lat: 48.184211, lng: 18.293779 },
    { lat: 48.184203, lng: 18.293701 },
    { lat: 48.184253, lng: 18.293579 },
    { lat: 48.184277, lng: 18.293522 },
    { lat: 48.184296, lng: 18.29347 },
    { lat: 48.184316, lng: 18.293421 },
    { lat: 48.184335, lng: 18.293367 },
    { lat: 48.184365, lng: 18.29329 },
    { lat: 48.184457, lng: 18.293202 },
    { lat: 48.184512, lng: 18.29316 },
    { lat: 48.184526, lng: 18.293082 },
    { lat: 48.184445, lng: 18.293042 },
    { lat: 48.184409, lng: 18.293042 },
    { lat: 48.184281, lng: 18.293046 },
    { lat: 48.184204, lng: 18.29307 },
    { lat: 48.184154, lng: 18.293125 },
    { lat: 48.184135, lng: 18.29321 },
    { lat: 48.18412, lng: 18.293269 },
    { lat: 48.184106, lng: 18.293325 },
    { lat: 48.184091, lng: 18.293384 },
    { lat: 48.184074, lng: 18.293456 },
    { lat: 48.184051, lng: 18.293549 },
    { lat: 48.18402, lng: 18.29362 },
    { lat: 48.183999, lng: 18.293666 },
    { lat: 48.183969, lng: 18.293729 },
    { lat: 48.183943, lng: 18.293786 },
    { lat: 48.183917, lng: 18.293839 },
    { lat: 48.183812, lng: 18.293842 },
    { lat: 48.183722, lng: 18.293846 },
    { lat: 48.183643, lng: 18.293889 },
    { lat: 48.183545, lng: 18.294153 },
    { lat: 48.183493, lng: 18.29412 },
    { lat: 48.183428, lng: 18.294017 },
    { lat: 48.183397, lng: 18.293789 },
    { lat: 48.18335, lng: 18.293785 },
    { lat: 48.182932, lng: 18.293767 },
    { lat: 48.182891, lng: 18.293844 },
    { lat: 48.182856, lng: 18.293872 },
    { lat: 48.182797, lng: 18.293848 },
    { lat: 48.182724, lng: 18.293782 },
    { lat: 48.182709, lng: 18.293667 },
    { lat: 48.182684, lng: 18.293642 },
    { lat: 48.182551, lng: 18.29356 },
    { lat: 48.182408, lng: 18.293428 },
    { lat: 48.182346, lng: 18.293346 },
    { lat: 48.182321, lng: 18.293186 },
    { lat: 48.18236, lng: 18.293058 },
    { lat: 48.182379, lng: 18.292992 },
    { lat: 48.182384, lng: 18.292906 },
    { lat: 48.182176, lng: 18.292701 },
    { lat: 48.182164, lng: 18.292622 },
    { lat: 48.182194, lng: 18.292546 },
    { lat: 48.182192, lng: 18.292465 },
    { lat: 48.182185, lng: 18.292223 },
    { lat: 48.18213, lng: 18.292062 },
    { lat: 48.182071, lng: 18.292076 },
    { lat: 48.181959, lng: 18.292164 },
    { lat: 48.181944, lng: 18.292263 },
    { lat: 48.181894, lng: 18.292603 },
    { lat: 48.181736, lng: 18.292871 },
    { lat: 48.181735, lng: 18.292955 },
    { lat: 48.181886, lng: 18.293025 },
    { lat: 48.181993, lng: 18.29298 },
    { lat: 48.182059, lng: 18.292985 },
    { lat: 48.182141, lng: 18.293073 },
    { lat: 48.182072, lng: 18.293791 },
    { lat: 48.182585, lng: 18.293879 },
    { lat: 48.182618, lng: 18.294055 },
    { lat: 48.18258, lng: 18.29412 },
    { lat: 48.182356, lng: 18.294226 },
    { lat: 48.182248, lng: 18.294343 },
    { lat: 48.182262, lng: 18.294474 },
    { lat: 48.182419, lng: 18.294563 },
    { lat: 48.182521, lng: 18.294821 },
    { lat: 48.182578, lng: 18.294907 },
    { lat: 48.182557, lng: 18.295531 },
    { lat: 48.182504, lng: 18.296772 },
    { lat: 48.18249, lng: 18.296889 },
    { lat: 48.182245, lng: 18.298452 },
    { lat: 48.181993, lng: 18.29995 },
    { lat: 48.181738, lng: 18.300049 },
    { lat: 48.181606, lng: 18.300893 },
    { lat: 48.181588, lng: 18.301009 },
    { lat: 48.181559, lng: 18.301276 },
    { lat: 48.181538, lng: 18.301586 },
    { lat: 48.18154, lng: 18.301612 },
    { lat: 48.181517, lng: 18.302201 },
    { lat: 48.181503, lng: 18.302785 },
    { lat: 48.181507, lng: 18.303358 },
    { lat: 48.181525, lng: 18.303526 },
    { lat: 48.181537, lng: 18.303531 },
    { lat: 48.181535, lng: 18.303563 },
    { lat: 48.181521, lng: 18.303657 },
    { lat: 48.181519, lng: 18.303677 },
    { lat: 48.181518, lng: 18.303692 },
    { lat: 48.18152, lng: 18.304103 },
    { lat: 48.181515, lng: 18.304221 },
    { lat: 48.181515, lng: 18.304277 },
    { lat: 48.181459, lng: 18.304701 },
    { lat: 48.181425, lng: 18.304886 },
    { lat: 48.181347, lng: 18.305171 },
    { lat: 48.181245, lng: 18.305522 },
    { lat: 48.181115, lng: 18.306644 },
    { lat: 48.180978, lng: 18.306893 },
    { lat: 48.180735, lng: 18.307419 },
    { lat: 48.180692, lng: 18.307559 },
    { lat: 48.180277, lng: 18.308754 },
    { lat: 48.180149, lng: 18.309048 },
    { lat: 48.17995, lng: 18.309441 },
    { lat: 48.179876, lng: 18.309673 },
    { lat: 48.179834, lng: 18.309777 },
    { lat: 48.179714, lng: 18.310315 },
    { lat: 48.179617, lng: 18.310832 },
    { lat: 48.17958, lng: 18.311121 },
    { lat: 48.179572, lng: 18.311259 },
    { lat: 48.179553, lng: 18.311378 },
    { lat: 48.179573, lng: 18.311655 },
    { lat: 48.179579, lng: 18.312026 },
    { lat: 48.179994, lng: 18.312107 },
    { lat: 48.180208, lng: 18.312185 },
    { lat: 48.18019, lng: 18.312283 },
    { lat: 48.180076, lng: 18.312875 },
    { lat: 48.179972, lng: 18.31338 },
    { lat: 48.179837, lng: 18.313811 },
    { lat: 48.179328, lng: 18.315185 },
    { lat: 48.179033, lng: 18.316051 },
    { lat: 48.178998, lng: 18.316289 },
    { lat: 48.178901, lng: 18.31746 },
    { lat: 48.178894, lng: 18.317786 },
    { lat: 48.17889, lng: 18.317843 },
    { lat: 48.179346, lng: 18.317997 },
    { lat: 48.179723, lng: 18.318142 },
    { lat: 48.180092, lng: 18.318297 },
    { lat: 48.180435, lng: 18.318466 },
    { lat: 48.180668, lng: 18.318596 },
    { lat: 48.181023, lng: 18.318935 },
    { lat: 48.181689, lng: 18.319603 },
    { lat: 48.181699, lng: 18.319569 },
    { lat: 48.181744, lng: 18.319614 },
    { lat: 48.181814, lng: 18.319682 },
    { lat: 48.181499, lng: 18.321038 },
    { lat: 48.181302, lng: 18.321828 },
    { lat: 48.18121, lng: 18.322224 },
    { lat: 48.181114, lng: 18.322699 },
    { lat: 48.180943, lng: 18.323914 },
    { lat: 48.180838, lng: 18.324552 },
    { lat: 48.180833, lng: 18.324585 },
    { lat: 48.180826, lng: 18.324632 },
    { lat: 48.180664, lng: 18.325588 },
    { lat: 48.180483, lng: 18.32608 },
    { lat: 48.179973, lng: 18.327223 },
    { lat: 48.179828, lng: 18.327536 },
    { lat: 48.178435, lng: 18.328682 },
    { lat: 48.177799, lng: 18.329231 },
    { lat: 48.177155, lng: 18.329654 },
    { lat: 48.176684, lng: 18.329978 },
    { lat: 48.17671, lng: 18.330049 },
    { lat: 48.176718, lng: 18.330081 },
    { lat: 48.176979, lng: 18.330602 },
    { lat: 48.17702, lng: 18.330705 },
    { lat: 48.17705, lng: 18.330811 },
    { lat: 48.177072, lng: 18.330974 },
    { lat: 48.177156, lng: 18.331881 },
    { lat: 48.177321, lng: 18.332805 },
    { lat: 48.177409, lng: 18.333146 },
    { lat: 48.177444, lng: 18.33352 },
    { lat: 48.177673, lng: 18.334448 },
    { lat: 48.177766, lng: 18.334888 },
    { lat: 48.177799, lng: 18.335605 },
    { lat: 48.177804, lng: 18.336009 },
    { lat: 48.177757, lng: 18.336415 },
    { lat: 48.177729, lng: 18.336686 },
    { lat: 48.177674, lng: 18.337441 },
    { lat: 48.177555, lng: 18.338375 },
    { lat: 48.177523, lng: 18.33878 },
    { lat: 48.177498, lng: 18.339319 },
    { lat: 48.177486, lng: 18.339898 },
    { lat: 48.177467, lng: 18.340403 },
    { lat: 48.17748, lng: 18.340569 },
    { lat: 48.17759, lng: 18.341198 },
    { lat: 48.177632, lng: 18.342264 },
    { lat: 48.177661, lng: 18.342887 },
    { lat: 48.177722, lng: 18.343147 },
    { lat: 48.177793, lng: 18.343409 },
    { lat: 48.177776, lng: 18.343632 },
    { lat: 48.177735, lng: 18.343748 },
    { lat: 48.177371, lng: 18.345096 },
    { lat: 48.177288, lng: 18.345383 },
    { lat: 48.177199, lng: 18.345653 },
    { lat: 48.177051, lng: 18.346042 },
    { lat: 48.177017, lng: 18.34611 },
    { lat: 48.177004, lng: 18.346125 },
    { lat: 48.177018, lng: 18.346208 },
    { lat: 48.177021, lng: 18.346233 },
    { lat: 48.177037, lng: 18.346279 },
    { lat: 48.177127, lng: 18.346243 },
    { lat: 48.177261, lng: 18.346135 },
    { lat: 48.177464, lng: 18.345961 },
    { lat: 48.177559, lng: 18.345882 },
    { lat: 48.177665, lng: 18.345792 },
    { lat: 48.177787, lng: 18.34569 },
    { lat: 48.177913, lng: 18.345586 },
    { lat: 48.178117, lng: 18.345417 },
    { lat: 48.17821, lng: 18.34531 },
    { lat: 48.178344, lng: 18.345187 },
    { lat: 48.178688, lng: 18.34495 },
    { lat: 48.178834, lng: 18.344831 },
    { lat: 48.178992, lng: 18.344699 },
    { lat: 48.179142, lng: 18.344576 },
    { lat: 48.179321, lng: 18.344433 },
    { lat: 48.179686, lng: 18.344126 },
    { lat: 48.180133, lng: 18.343731 },
    { lat: 48.18041, lng: 18.343486 },
    { lat: 48.180648, lng: 18.34333 },
    { lat: 48.180778, lng: 18.343362 },
    { lat: 48.18114, lng: 18.343461 },
    { lat: 48.181493, lng: 18.343548 },
    { lat: 48.182038, lng: 18.343485 },
    { lat: 48.182567, lng: 18.343428 },
    { lat: 48.182584, lng: 18.343426 },
    { lat: 48.183051, lng: 18.34345 },
    { lat: 48.183248, lng: 18.343466 },
    { lat: 48.183752, lng: 18.343496 },
    { lat: 48.18402, lng: 18.343515 },
    { lat: 48.184552, lng: 18.343569 },
    { lat: 48.184708, lng: 18.343585 },
    { lat: 48.185433, lng: 18.343505 },
    { lat: 48.186079, lng: 18.34344 },
    { lat: 48.186261, lng: 18.343424 },
    { lat: 48.186648, lng: 18.343384 },
    { lat: 48.187132, lng: 18.343394 },
    { lat: 48.187136, lng: 18.343375 },
    { lat: 48.1871895, lng: 18.3433238 },
    { lat: 48.1886127, lng: 18.3419616 },
    { lat: 48.1888287, lng: 18.3418715 },
    { lat: 48.1891407, lng: 18.3418616 },
    { lat: 48.1895563, lng: 18.3403191 },
    { lat: 48.1899835, lng: 18.3389635 },
    { lat: 48.1904375, lng: 18.3376006 },
    { lat: 48.1911868, lng: 18.335582 },
    { lat: 48.1914882, lng: 18.334614 },
    { lat: 48.1931721, lng: 18.3311764 },
    { lat: 48.1944884, lng: 18.3288481 },
    { lat: 48.1953624, lng: 18.3271779 },
    { lat: 48.1972041, lng: 18.3233231 },
    { lat: 48.1987522, lng: 18.3197426 },
    { lat: 48.1993779, lng: 18.318606 },
    { lat: 48.1999431, lng: 18.3175793 },
    { lat: 48.2004676, lng: 18.3166653 },
    { lat: 48.2006571, lng: 18.3163886 },
    { lat: 48.2018059, lng: 18.3147115 },
    { lat: 48.2020045, lng: 18.3161737 },
    { lat: 48.2024299, lng: 18.3162944 },
    { lat: 48.2035947, lng: 18.3169312 },
    { lat: 48.2042497, lng: 18.3154413 },
    { lat: 48.2051359, lng: 18.3130977 },
    { lat: 48.2068534, lng: 18.309722 },
    { lat: 48.2069605, lng: 18.3095248 },
    { lat: 48.2071013, lng: 18.3094604 },
    { lat: 48.2076885, lng: 18.3093511 },
    { lat: 48.2091924, lng: 18.3038585 },
    { lat: 48.2096934, lng: 18.3034452 },
    { lat: 48.2099252, lng: 18.303094 },
    { lat: 48.2102735, lng: 18.3027565 },
    { lat: 48.2102778, lng: 18.3025756 },
    { lat: 48.2103554, lng: 18.3003958 },
    { lat: 48.2086527, lng: 18.2981493 },
    { lat: 48.20773, lng: 18.2972272 },
    { lat: 48.2073867, lng: 18.2970242 },
    { lat: 48.2073383, lng: 18.2969272 },
    { lat: 48.207173, lng: 18.2968595 },
    { lat: 48.207036, lng: 18.2966935 },
    { lat: 48.2067637, lng: 18.2963308 },
    { lat: 48.2066614, lng: 18.2957861 },
    { lat: 48.2065131, lng: 18.2955207 },
    { lat: 48.206025, lng: 18.2953714 },
    { lat: 48.2058884, lng: 18.2952527 },
    { lat: 48.2057146, lng: 18.2949757 },
    { lat: 48.205603, lng: 18.2949592 },
    { lat: 48.2055492, lng: 18.2950227 },
    { lat: 48.2055117, lng: 18.2952146 },
    { lat: 48.2054443, lng: 18.2958501 },
    { lat: 48.2053137, lng: 18.2962293 },
    { lat: 48.2048571, lng: 18.2959794 },
    { lat: 48.2046546, lng: 18.2957132 },
    { lat: 48.2046013, lng: 18.2955516 },
    { lat: 48.2046302, lng: 18.2952091 },
    { lat: 48.2045922, lng: 18.2950778 },
    { lat: 48.2048862, lng: 18.2948742 },
    { lat: 48.204921, lng: 18.2947138 },
    { lat: 48.2046265, lng: 18.2942776 },
    { lat: 48.2044187, lng: 18.2941174 },
    { lat: 48.2042614, lng: 18.2940117 },
  ],
  Svätoplukovo: [
    { lat: 48.2281322, lng: 18.0820985 },
    { lat: 48.2283927, lng: 18.0824573 },
    { lat: 48.2287479, lng: 18.0828725 },
    { lat: 48.2300075, lng: 18.0839814 },
    { lat: 48.2320186, lng: 18.0853184 },
    { lat: 48.2338155, lng: 18.0857825 },
    { lat: 48.2354908, lng: 18.0859633 },
    { lat: 48.2359737, lng: 18.0860937 },
    { lat: 48.2373589, lng: 18.0867321 },
    { lat: 48.2376075, lng: 18.0869282 },
    { lat: 48.2380618, lng: 18.0878533 },
    { lat: 48.2383488, lng: 18.0882805 },
    { lat: 48.2384512, lng: 18.0883433 },
    { lat: 48.2388008, lng: 18.0885578 },
    { lat: 48.2391495, lng: 18.0885962 },
    { lat: 48.2402165, lng: 18.0881457 },
    { lat: 48.2409704, lng: 18.0879344 },
    { lat: 48.241267, lng: 18.0880063 },
    { lat: 48.2415389, lng: 18.0882376 },
    { lat: 48.2417131, lng: 18.0882992 },
    { lat: 48.2422642, lng: 18.0882847 },
    { lat: 48.2428706, lng: 18.0882537 },
    { lat: 48.2447479, lng: 18.0879008 },
    { lat: 48.2470244, lng: 18.0877692 },
    { lat: 48.247025, lng: 18.0870705 },
    { lat: 48.2477123, lng: 18.0864987 },
    { lat: 48.2487994, lng: 18.085392 },
    { lat: 48.248543, lng: 18.0847231 },
    { lat: 48.2483254, lng: 18.0843058 },
    { lat: 48.2474394, lng: 18.0828722 },
    { lat: 48.2472845, lng: 18.0824632 },
    { lat: 48.2467977, lng: 18.0807006 },
    { lat: 48.2463863, lng: 18.0800615 },
    { lat: 48.2462195, lng: 18.0794165 },
    { lat: 48.2458981, lng: 18.0787531 },
    { lat: 48.2457129, lng: 18.0782157 },
    { lat: 48.2448656, lng: 18.0762174 },
    { lat: 48.244715, lng: 18.0755589 },
    { lat: 48.2445701, lng: 18.074425 },
    { lat: 48.2444866, lng: 18.074196 },
    { lat: 48.2435459, lng: 18.0722424 },
    { lat: 48.242874, lng: 18.0706161 },
    { lat: 48.2427668, lng: 18.0704444 },
    { lat: 48.2416944, lng: 18.0719838 },
    { lat: 48.2407386, lng: 18.0701003 },
    { lat: 48.2396391, lng: 18.0668736 },
    { lat: 48.2380325, lng: 18.0607098 },
    { lat: 48.2377196, lng: 18.0594695 },
    { lat: 48.2374237, lng: 18.0583451 },
    { lat: 48.2372859, lng: 18.0578119 },
    { lat: 48.2367401, lng: 18.0537661 },
    { lat: 48.2364428, lng: 18.0523712 },
    { lat: 48.2355377, lng: 18.0496913 },
    { lat: 48.2350628, lng: 18.0485356 },
    { lat: 48.2344177, lng: 18.0473464 },
    { lat: 48.2339906, lng: 18.0476429 },
    { lat: 48.2334552, lng: 18.0467629 },
    { lat: 48.2325066, lng: 18.0454987 },
    { lat: 48.2321052, lng: 18.0450311 },
    { lat: 48.2313433, lng: 18.0442897 },
    { lat: 48.2307582, lng: 18.0435024 },
    { lat: 48.2306674, lng: 18.0435503 },
    { lat: 48.2299037, lng: 18.0426867 },
    { lat: 48.2290969, lng: 18.0415308 },
    { lat: 48.2282176, lng: 18.0400282 },
    { lat: 48.2272791, lng: 18.0387077 },
    { lat: 48.2274771, lng: 18.0383754 },
    { lat: 48.2272261, lng: 18.0379332 },
    { lat: 48.2276513, lng: 18.0377367 },
    { lat: 48.2279651, lng: 18.037474 },
    { lat: 48.2281166, lng: 18.037154 },
    { lat: 48.2281638, lng: 18.0364893 },
    { lat: 48.2279102, lng: 18.0362651 },
    { lat: 48.2273746, lng: 18.0355907 },
    { lat: 48.2266752, lng: 18.034879 },
    { lat: 48.22571, lng: 18.033736 },
    { lat: 48.2257202, lng: 18.0334536 },
    { lat: 48.2247625, lng: 18.0320983 },
    { lat: 48.2227584, lng: 18.0289986 },
    { lat: 48.2208989, lng: 18.0264858 },
    { lat: 48.2190472, lng: 18.0237873 },
    { lat: 48.2170522, lng: 18.0206935 },
    { lat: 48.2165468, lng: 18.0198069 },
    { lat: 48.2155322, lng: 18.0184073 },
    { lat: 48.214931, lng: 18.0177353 },
    { lat: 48.2131239, lng: 18.0160683 },
    { lat: 48.2122829, lng: 18.0150473 },
    { lat: 48.2109025, lng: 18.0130804 },
    { lat: 48.2075934, lng: 18.0092857 },
    { lat: 48.2052452, lng: 18.0068786 },
    { lat: 48.2043521, lng: 18.0058932 },
    { lat: 48.2030251, lng: 18.0041429 },
    { lat: 48.2023606, lng: 18.0031492 },
    { lat: 48.2017861, lng: 18.0024697 },
    { lat: 48.2013904, lng: 18.0021569 },
    { lat: 48.2008722, lng: 18.0015325 },
    { lat: 48.199519, lng: 17.9994803 },
    { lat: 48.1988546, lng: 17.9981485 },
    { lat: 48.1969188, lng: 17.994889 },
    { lat: 48.1954504, lng: 17.9921758 },
    { lat: 48.1949621, lng: 17.9917326 },
    { lat: 48.1947107, lng: 17.9921306 },
    { lat: 48.1873343, lng: 18.003565 },
    { lat: 48.1921223, lng: 18.0106626 },
    { lat: 48.1901317, lng: 18.0139718 },
    { lat: 48.1941208, lng: 18.0184567 },
    { lat: 48.1999146, lng: 18.021546 },
    { lat: 48.2003041, lng: 18.0217558 },
    { lat: 48.2010146, lng: 18.0230673 },
    { lat: 48.201775, lng: 18.0247287 },
    { lat: 48.202136, lng: 18.0256708 },
    { lat: 48.2025745, lng: 18.026305 },
    { lat: 48.2072103, lng: 18.0345532 },
    { lat: 48.207815, lng: 18.036518 },
    { lat: 48.2082784, lng: 18.0373649 },
    { lat: 48.2094436, lng: 18.0408814 },
    { lat: 48.2095329, lng: 18.0409789 },
    { lat: 48.2105968, lng: 18.0434574 },
    { lat: 48.2120199, lng: 18.0464887 },
    { lat: 48.2121884, lng: 18.0468368 },
    { lat: 48.2145916, lng: 18.0507556 },
    { lat: 48.217517, lng: 18.0560033 },
    { lat: 48.2189047, lng: 18.0597036 },
    { lat: 48.2191481, lng: 18.0602502 },
    { lat: 48.2193805, lng: 18.060093 },
    { lat: 48.2197322, lng: 18.0618211 },
    { lat: 48.2194866, lng: 18.0618668 },
    { lat: 48.2195929, lng: 18.0622869 },
    { lat: 48.2196948, lng: 18.0626538 },
    { lat: 48.2200677, lng: 18.0634824 },
    { lat: 48.2202459, lng: 18.0640105 },
    { lat: 48.2210149, lng: 18.0665308 },
    { lat: 48.2212692, lng: 18.0665013 },
    { lat: 48.2214381, lng: 18.0666449 },
    { lat: 48.2217778, lng: 18.06752 },
    { lat: 48.2222458, lng: 18.0690972 },
    { lat: 48.2225166, lng: 18.0697833 },
    { lat: 48.2234506, lng: 18.0718339 },
    { lat: 48.2238134, lng: 18.0723567 },
    { lat: 48.2240915, lng: 18.0729558 },
    { lat: 48.2248685, lng: 18.0752924 },
    { lat: 48.2255903, lng: 18.0770546 },
    { lat: 48.2256532, lng: 18.0772777 },
    { lat: 48.2256557, lng: 18.077913 },
    { lat: 48.2256984, lng: 18.0780635 },
    { lat: 48.225935, lng: 18.0785324 },
    { lat: 48.2267301, lng: 18.0801081 },
    { lat: 48.2270704, lng: 18.0806322 },
    { lat: 48.2273535, lng: 18.0810683 },
    { lat: 48.2281322, lng: 18.0820985 },
  ],
  Klasov: [
    { lat: 48.2594341, lng: 18.2355497 },
    { lat: 48.2592266, lng: 18.2356113 },
    { lat: 48.2569203, lng: 18.2365515 },
    { lat: 48.2567748, lng: 18.2395822 },
    { lat: 48.2560983, lng: 18.240181 },
    { lat: 48.2528264, lng: 18.2427494 },
    { lat: 48.251577, lng: 18.2433931 },
    { lat: 48.2509018, lng: 18.2439534 },
    { lat: 48.2497651, lng: 18.2450803 },
    { lat: 48.2498163, lng: 18.2468878 },
    { lat: 48.2496021, lng: 18.2492808 },
    { lat: 48.2493869, lng: 18.2502637 },
    { lat: 48.2490664, lng: 18.2504595 },
    { lat: 48.2491722, lng: 18.2512987 },
    { lat: 48.2499267, lng: 18.2540948 },
    { lat: 48.2505637, lng: 18.2569469 },
    { lat: 48.2509553, lng: 18.2589282 },
    { lat: 48.2512123, lng: 18.2600248 },
    { lat: 48.2513289, lng: 18.260264 },
    { lat: 48.2514422, lng: 18.2607832 },
    { lat: 48.2515381, lng: 18.262374 },
    { lat: 48.2515518, lng: 18.2626317 },
    { lat: 48.2515948, lng: 18.2627515 },
    { lat: 48.2516269, lng: 18.2628405 },
    { lat: 48.2518958, lng: 18.2628242 },
    { lat: 48.2520607, lng: 18.2653815 },
    { lat: 48.2521309, lng: 18.2659105 },
    { lat: 48.2523051, lng: 18.2667092 },
    { lat: 48.2526585, lng: 18.267877 },
    { lat: 48.2529735, lng: 18.2689182 },
    { lat: 48.2530546, lng: 18.2691862 },
    { lat: 48.2531843, lng: 18.2695241 },
    { lat: 48.2536051, lng: 18.2706204 },
    { lat: 48.253462, lng: 18.2708786 },
    { lat: 48.2535843, lng: 18.2713167 },
    { lat: 48.2533944, lng: 18.2717065 },
    { lat: 48.2541324, lng: 18.2747149 },
    { lat: 48.254352, lng: 18.2763797 },
    { lat: 48.2544664, lng: 18.2769187 },
    { lat: 48.2545679, lng: 18.2771914 },
    { lat: 48.2546963, lng: 18.2771667 },
    { lat: 48.2547908, lng: 18.2772399 },
    { lat: 48.2548995, lng: 18.2779978 },
    { lat: 48.2549756, lng: 18.2782617 },
    { lat: 48.2553004, lng: 18.2784536 },
    { lat: 48.2558194, lng: 18.2790646 },
    { lat: 48.2569604, lng: 18.2797974 },
    { lat: 48.2585609, lng: 18.2806347 },
    { lat: 48.2586423, lng: 18.2807135 },
    { lat: 48.258716, lng: 18.281175 },
    { lat: 48.2588577, lng: 18.2814815 },
    { lat: 48.2594805, lng: 18.282248 },
    { lat: 48.260304, lng: 18.2829117 },
    { lat: 48.2614851, lng: 18.2832029 },
    { lat: 48.2614028, lng: 18.2834216 },
    { lat: 48.2615651, lng: 18.2837521 },
    { lat: 48.2626738, lng: 18.2845912 },
    { lat: 48.2645734, lng: 18.2861769 },
    { lat: 48.2650794, lng: 18.2864455 },
    { lat: 48.2665062, lng: 18.2869475 },
    { lat: 48.2666533, lng: 18.2868152 },
    { lat: 48.2669543, lng: 18.2872869 },
    { lat: 48.2671861, lng: 18.2878825 },
    { lat: 48.267385, lng: 18.2885819 },
    { lat: 48.2676353, lng: 18.2896795 },
    { lat: 48.2679751, lng: 18.2916747 },
    { lat: 48.2680809, lng: 18.291711 },
    { lat: 48.2684088, lng: 18.2916236 },
    { lat: 48.2690078, lng: 18.2913438 },
    { lat: 48.2691078, lng: 18.2912973 },
    { lat: 48.2707191, lng: 18.2906857 },
    { lat: 48.270969, lng: 18.2906766 },
    { lat: 48.2713005, lng: 18.2907924 },
    { lat: 48.2719139, lng: 18.2906254 },
    { lat: 48.2724314, lng: 18.2905781 },
    { lat: 48.2729564, lng: 18.2903701 },
    { lat: 48.2730067, lng: 18.2902032 },
    { lat: 48.2733184, lng: 18.2896957 },
    { lat: 48.2736606, lng: 18.2892671 },
    { lat: 48.2740673, lng: 18.2888996 },
    { lat: 48.2745577, lng: 18.2885575 },
    { lat: 48.2747724, lng: 18.2883058 },
    { lat: 48.2751946, lng: 18.2880365 },
    { lat: 48.2722753, lng: 18.2807587 },
    { lat: 48.2756124, lng: 18.2771485 },
    { lat: 48.2756318, lng: 18.277095 },
    { lat: 48.2755399, lng: 18.2768894 },
    { lat: 48.2815075, lng: 18.2693879 },
    { lat: 48.2820929, lng: 18.2707844 },
    { lat: 48.2851572, lng: 18.2667683 },
    { lat: 48.2853014, lng: 18.266529 },
    { lat: 48.2861706, lng: 18.2650593 },
    { lat: 48.2870643, lng: 18.2637657 },
    { lat: 48.2877516, lng: 18.2630812 },
    { lat: 48.2884064, lng: 18.2625553 },
    { lat: 48.2891035, lng: 18.2617663 },
    { lat: 48.288563, lng: 18.2609797 },
    { lat: 48.2865301, lng: 18.2580611 },
    { lat: 48.2869242, lng: 18.255498 },
    { lat: 48.286901, lng: 18.2554857 },
    { lat: 48.2860755, lng: 18.2551024 },
    { lat: 48.2861958, lng: 18.2550355 },
    { lat: 48.2862638, lng: 18.2548439 },
    { lat: 48.2863008, lng: 18.2542705 },
    { lat: 48.286258, lng: 18.254057 },
    { lat: 48.2860286, lng: 18.2536378 },
    { lat: 48.2859, lng: 18.2537571 },
    { lat: 48.2858582, lng: 18.2530671 },
    { lat: 48.2858855, lng: 18.2525431 },
    { lat: 48.2826018, lng: 18.2502851 },
    { lat: 48.2817343, lng: 18.2494481 },
    { lat: 48.2810558, lng: 18.2478949 },
    { lat: 48.2810254, lng: 18.247642 },
    { lat: 48.2806196, lng: 18.2469629 },
    { lat: 48.2799476, lng: 18.2453625 },
    { lat: 48.279565, lng: 18.2427768 },
    { lat: 48.279549, lng: 18.2420519 },
    { lat: 48.2796851, lng: 18.2401221 },
    { lat: 48.2776513, lng: 18.2373162 },
    { lat: 48.2759621, lng: 18.2335835 },
    { lat: 48.2750917, lng: 18.2235747 },
    { lat: 48.2748384, lng: 18.2239185 },
    { lat: 48.2713038, lng: 18.2288314 },
    { lat: 48.2703811, lng: 18.2303776 },
    { lat: 48.2671206, lng: 18.2340637 },
    { lat: 48.2640318, lng: 18.2352679 },
    { lat: 48.2630361, lng: 18.235535 },
    { lat: 48.2614068, lng: 18.2354173 },
    { lat: 48.2594341, lng: 18.2355497 },
  ],
  Žirany: [
    { lat: 48.3679935, lng: 18.1530103 },
    { lat: 48.3674461, lng: 18.1550593 },
    { lat: 48.3673108, lng: 18.1553497 },
    { lat: 48.3670702, lng: 18.156354 },
    { lat: 48.3663115, lng: 18.1578775 },
    { lat: 48.3654212, lng: 18.1591001 },
    { lat: 48.3645062, lng: 18.160052 },
    { lat: 48.3647707, lng: 18.1613932 },
    { lat: 48.3651906, lng: 18.1622898 },
    { lat: 48.3653993, lng: 18.1651959 },
    { lat: 48.3652883, lng: 18.166758 },
    { lat: 48.3650838, lng: 18.167237 },
    { lat: 48.3646212, lng: 18.168013 },
    { lat: 48.364346, lng: 18.1686346 },
    { lat: 48.3642054, lng: 18.168763 },
    { lat: 48.3638163, lng: 18.1698701 },
    { lat: 48.3636447, lng: 18.1701605 },
    { lat: 48.363014, lng: 18.1708421 },
    { lat: 48.3627158, lng: 18.1713794 },
    { lat: 48.3625658, lng: 18.1718086 },
    { lat: 48.3620898, lng: 18.1726832 },
    { lat: 48.3619337, lng: 18.1730648 },
    { lat: 48.3617589, lng: 18.1737669 },
    { lat: 48.3614181, lng: 18.1744659 },
    { lat: 48.3609227, lng: 18.1749913 },
    { lat: 48.3605031, lng: 18.1760018 },
    { lat: 48.3599132, lng: 18.1771137 },
    { lat: 48.3595729, lng: 18.1774304 },
    { lat: 48.3589708, lng: 18.178508 },
    { lat: 48.3583497, lng: 18.1798819 },
    { lat: 48.3570072, lng: 18.1816556 },
    { lat: 48.3566627, lng: 18.1819213 },
    { lat: 48.3566945, lng: 18.18202 },
    { lat: 48.356646, lng: 18.182081 },
    { lat: 48.3569693, lng: 18.1828474 },
    { lat: 48.357016, lng: 18.1827962 },
    { lat: 48.3575742, lng: 18.1833154 },
    { lat: 48.3579415, lng: 18.1835782 },
    { lat: 48.35834, lng: 18.1836961 },
    { lat: 48.3585563, lng: 18.1836717 },
    { lat: 48.3596363, lng: 18.1833212 },
    { lat: 48.3598208, lng: 18.18333 },
    { lat: 48.3601561, lng: 18.1840917 },
    { lat: 48.3604117, lng: 18.1844131 },
    { lat: 48.3608099, lng: 18.1845679 },
    { lat: 48.361206, lng: 18.1848575 },
    { lat: 48.3620388, lng: 18.1852653 },
    { lat: 48.3626578, lng: 18.1854508 },
    { lat: 48.3641509, lng: 18.1854757 },
    { lat: 48.3651306, lng: 18.1856315 },
    { lat: 48.3654916, lng: 18.1861546 },
    { lat: 48.3674053, lng: 18.1883312 },
    { lat: 48.3688459, lng: 18.1900923 },
    { lat: 48.3701927, lng: 18.1921097 },
    { lat: 48.3712275, lng: 18.19355 },
    { lat: 48.3709277, lng: 18.1941594 },
    { lat: 48.3703945, lng: 18.1955919 },
    { lat: 48.3699585, lng: 18.1970609 },
    { lat: 48.3699878, lng: 18.1978237 },
    { lat: 48.3699529, lng: 18.1984095 },
    { lat: 48.3700509, lng: 18.1987751 },
    { lat: 48.3700338, lng: 18.1990264 },
    { lat: 48.3699792, lng: 18.1989785 },
    { lat: 48.3699912, lng: 18.1992258 },
    { lat: 48.3699271, lng: 18.1992874 },
    { lat: 48.3698523, lng: 18.1991835 },
    { lat: 48.369737, lng: 18.1993157 },
    { lat: 48.3697227, lng: 18.1996164 },
    { lat: 48.3696291, lng: 18.1997358 },
    { lat: 48.3694174, lng: 18.1998397 },
    { lat: 48.3693841, lng: 18.199947 },
    { lat: 48.3694058, lng: 18.2000478 },
    { lat: 48.369295, lng: 18.2002794 },
    { lat: 48.3693313, lng: 18.2004195 },
    { lat: 48.3694799, lng: 18.2004125 },
    { lat: 48.3695127, lng: 18.2005101 },
    { lat: 48.3693255, lng: 18.2007641 },
    { lat: 48.3693424, lng: 18.201076 },
    { lat: 48.3692774, lng: 18.2011427 },
    { lat: 48.3691821, lng: 18.2011334 },
    { lat: 48.369148, lng: 18.2015009 },
    { lat: 48.3688655, lng: 18.2014732 },
    { lat: 48.3688693, lng: 18.2017555 },
    { lat: 48.3689479, lng: 18.2020799 },
    { lat: 48.368891, lng: 18.2021463 },
    { lat: 48.3687972, lng: 18.2021273 },
    { lat: 48.3686927, lng: 18.2019968 },
    { lat: 48.3686261, lng: 18.2020186 },
    { lat: 48.3685519, lng: 18.2021798 },
    { lat: 48.3684295, lng: 18.2019932 },
    { lat: 48.3683617, lng: 18.2020102 },
    { lat: 48.3682813, lng: 18.2021615 },
    { lat: 48.3683097, lng: 18.2022845 },
    { lat: 48.3681613, lng: 18.2023312 },
    { lat: 48.3679825, lng: 18.2022651 },
    { lat: 48.3678165, lng: 18.2025139 },
    { lat: 48.3678832, lng: 18.202772 },
    { lat: 48.3678545, lng: 18.2029585 },
    { lat: 48.3675378, lng: 18.2030431 },
    { lat: 48.367364, lng: 18.2034519 },
    { lat: 48.3672921, lng: 18.2034754 },
    { lat: 48.3671617, lng: 18.2032902 },
    { lat: 48.3669248, lng: 18.2032536 },
    { lat: 48.3666497, lng: 18.2034314 },
    { lat: 48.3666251, lng: 18.2036107 },
    { lat: 48.3664045, lng: 18.2035219 },
    { lat: 48.3663555, lng: 18.2035528 },
    { lat: 48.3662188, lng: 18.2039205 },
    { lat: 48.36606, lng: 18.2040612 },
    { lat: 48.3658856, lng: 18.2038965 },
    { lat: 48.3657987, lng: 18.2039554 },
    { lat: 48.365675, lng: 18.2041745 },
    { lat: 48.3654829, lng: 18.2048142 },
    { lat: 48.3653531, lng: 18.204934 },
    { lat: 48.3651616, lng: 18.2049586 },
    { lat: 48.365132, lng: 18.2052244 },
    { lat: 48.3650532, lng: 18.2053084 },
    { lat: 48.3649107, lng: 18.2052447 },
    { lat: 48.3647289, lng: 18.2048692 },
    { lat: 48.3646464, lng: 18.2049261 },
    { lat: 48.3645825, lng: 18.205131 },
    { lat: 48.3646247, lng: 18.2052145 },
    { lat: 48.364407, lng: 18.2054108 },
    { lat: 48.364385, lng: 18.2055179 },
    { lat: 48.3642817, lng: 18.2055055 },
    { lat: 48.3637051, lng: 18.2059163 },
    { lat: 48.3633404, lng: 18.2059922 },
    { lat: 48.3631089, lng: 18.2061445 },
    { lat: 48.3624007, lng: 18.2062903 },
    { lat: 48.3622886, lng: 18.2061644 },
    { lat: 48.3621573, lng: 18.2058849 },
    { lat: 48.3619909, lng: 18.2060127 },
    { lat: 48.3617684, lng: 18.2059884 },
    { lat: 48.3616741, lng: 18.2062313 },
    { lat: 48.3615528, lng: 18.2063607 },
    { lat: 48.3614006, lng: 18.2063422 },
    { lat: 48.3615273, lng: 18.2070112 },
    { lat: 48.3612088, lng: 18.2072 },
    { lat: 48.3622474, lng: 18.2107997 },
    { lat: 48.3606958, lng: 18.2113062 },
    { lat: 48.3599779, lng: 18.2116338 },
    { lat: 48.3592817, lng: 18.2120626 },
    { lat: 48.3589223, lng: 18.2123861 },
    { lat: 48.3577124, lng: 18.2140802 },
    { lat: 48.3566998, lng: 18.215203 },
    { lat: 48.3580085, lng: 18.218833 },
    { lat: 48.3586886, lng: 18.2208184 },
    { lat: 48.3591001, lng: 18.221868 },
    { lat: 48.3590287, lng: 18.222021 },
    { lat: 48.359679, lng: 18.2235906 },
    { lat: 48.359501, lng: 18.22418 },
    { lat: 48.36025, lng: 18.2260935 },
    { lat: 48.3607788, lng: 18.2253822 },
    { lat: 48.3623125, lng: 18.2238988 },
    { lat: 48.364597, lng: 18.2221094 },
    { lat: 48.3652253, lng: 18.2215413 },
    { lat: 48.3656122, lng: 18.2210715 },
    { lat: 48.3658479, lng: 18.2204596 },
    { lat: 48.3660008, lng: 18.2198074 },
    { lat: 48.366121, lng: 18.2190139 },
    { lat: 48.3660996, lng: 18.218313 },
    { lat: 48.3661598, lng: 18.2182211 },
    { lat: 48.3676283, lng: 18.2163345 },
    { lat: 48.3682291, lng: 18.2157936 },
    { lat: 48.3689086, lng: 18.2154287 },
    { lat: 48.3692682, lng: 18.215109 },
    { lat: 48.369939, lng: 18.214121 },
    { lat: 48.3701883, lng: 18.2144534 },
    { lat: 48.3704872, lng: 18.213984 },
    { lat: 48.37098, lng: 18.2128835 },
    { lat: 48.3712028, lng: 18.2132236 },
    { lat: 48.3715911, lng: 18.2123295 },
    { lat: 48.3722332, lng: 18.210417 },
    { lat: 48.3728805, lng: 18.2109741 },
    { lat: 48.3742055, lng: 18.2093403 },
    { lat: 48.3749766, lng: 18.2081346 },
    { lat: 48.3757162, lng: 18.2067183 },
    { lat: 48.3759369, lng: 18.2062047 },
    { lat: 48.3759817, lng: 18.2057701 },
    { lat: 48.376041, lng: 18.2056058 },
    { lat: 48.3766152, lng: 18.2044502 },
    { lat: 48.3773929, lng: 18.2037138 },
    { lat: 48.377679, lng: 18.2035881 },
    { lat: 48.3785163, lng: 18.2030228 },
    { lat: 48.3792599, lng: 18.2026905 },
    { lat: 48.3812676, lng: 18.2012924 },
    { lat: 48.3813959, lng: 18.2009928 },
    { lat: 48.381489, lng: 18.2003992 },
    { lat: 48.3816909, lng: 18.1999295 },
    { lat: 48.3817524, lng: 18.19966 },
    { lat: 48.3818315, lng: 18.1996293 },
    { lat: 48.3822572, lng: 18.1990508 },
    { lat: 48.382484, lng: 18.1986176 },
    { lat: 48.3836005, lng: 18.1974978 },
    { lat: 48.3842516, lng: 18.1970298 },
    { lat: 48.3853096, lng: 18.1963571 },
    { lat: 48.3865283, lng: 18.1960478 },
    { lat: 48.3872378, lng: 18.195964 },
    { lat: 48.3877425, lng: 18.1956784 },
    { lat: 48.3883304, lng: 18.1956964 },
    { lat: 48.3886726, lng: 18.1955658 },
    { lat: 48.3891733, lng: 18.1955973 },
    { lat: 48.3897607, lng: 18.1951599 },
    { lat: 48.390113, lng: 18.1950092 },
    { lat: 48.3914336, lng: 18.1938657 },
    { lat: 48.3918997, lng: 18.1926772 },
    { lat: 48.3921553, lng: 18.1922979 },
    { lat: 48.3937451, lng: 18.1893168 },
    { lat: 48.3948472, lng: 18.1865669 },
    { lat: 48.395421, lng: 18.1856599 },
    { lat: 48.3955569, lng: 18.1851656 },
    { lat: 48.3959617, lng: 18.1843009 },
    { lat: 48.396543, lng: 18.1833116 },
    { lat: 48.3969088, lng: 18.1825578 },
    { lat: 48.3971229, lng: 18.1819123 },
    { lat: 48.3972568, lng: 18.1812457 },
    { lat: 48.3973839, lng: 18.1801883 },
    { lat: 48.3976676, lng: 18.1789697 },
    { lat: 48.3980728, lng: 18.1777502 },
    { lat: 48.3980371, lng: 18.1771341 },
    { lat: 48.3982128, lng: 18.175864 },
    { lat: 48.3983182, lng: 18.1738531 },
    { lat: 48.3985233, lng: 18.1728159 },
    { lat: 48.3986301, lng: 18.1711425 },
    { lat: 48.3989635, lng: 18.1695974 },
    { lat: 48.3989868, lng: 18.1685617 },
    { lat: 48.3989806, lng: 18.1684926 },
    { lat: 48.3988479, lng: 18.1674546 },
    { lat: 48.3987863, lng: 18.1665301 },
    { lat: 48.3988633, lng: 18.1655648 },
    { lat: 48.3989048, lng: 18.1653101 },
    { lat: 48.3993563, lng: 18.1639344 },
    { lat: 48.3998617, lng: 18.1628628 },
    { lat: 48.3999087, lng: 18.1626826 },
    { lat: 48.3999218, lng: 18.1623644 },
    { lat: 48.3998803, lng: 18.1620404 },
    { lat: 48.3995921, lng: 18.1607898 },
    { lat: 48.3991183, lng: 18.1597078 },
    { lat: 48.3986405, lng: 18.1592393 },
    { lat: 48.3984044, lng: 18.1588066 },
    { lat: 48.3977005, lng: 18.1578408 },
    { lat: 48.3973405, lng: 18.157163 },
    { lat: 48.3970918, lng: 18.15644 },
    { lat: 48.3965191, lng: 18.1550732 },
    { lat: 48.3963409, lng: 18.154418 },
    { lat: 48.3960663, lng: 18.1530177 },
    { lat: 48.3959868, lng: 18.1518858 },
    { lat: 48.3957203, lng: 18.1510694 },
    { lat: 48.3949957, lng: 18.1495568 },
    { lat: 48.3945337, lng: 18.1489134 },
    { lat: 48.3941237, lng: 18.147553 },
    { lat: 48.3937772, lng: 18.1466427 },
    { lat: 48.3926825, lng: 18.1461558 },
    { lat: 48.3916877, lng: 18.1455701 },
    { lat: 48.3912353, lng: 18.1450459 },
    { lat: 48.3907427, lng: 18.1448274 },
    { lat: 48.3904297, lng: 18.1446065 },
    { lat: 48.3896167, lng: 18.1427234 },
    { lat: 48.3892592, lng: 18.1420316 },
    { lat: 48.3890079, lng: 18.1417281 },
    { lat: 48.3887436, lng: 18.1414345 },
    { lat: 48.3883636, lng: 18.1402603 },
    { lat: 48.3882348, lng: 18.1392143 },
    { lat: 48.3880952, lng: 18.1390871 },
    { lat: 48.387903, lng: 18.1390236 },
    { lat: 48.3880005, lng: 18.1385674 },
    { lat: 48.3879311, lng: 18.1376864 },
    { lat: 48.3877042, lng: 18.1380532 },
    { lat: 48.3873259, lng: 18.1382206 },
    { lat: 48.3865093, lng: 18.1380381 },
    { lat: 48.3865196, lng: 18.1375382 },
    { lat: 48.3858037, lng: 18.1375278 },
    { lat: 48.3858911, lng: 18.1382349 },
    { lat: 48.3859117, lng: 18.1388954 },
    { lat: 48.3859663, lng: 18.139153 },
    { lat: 48.3860499, lng: 18.1394843 },
    { lat: 48.3862421, lng: 18.1396652 },
    { lat: 48.3863199, lng: 18.140086 },
    { lat: 48.3864001, lng: 18.1402437 },
    { lat: 48.3861986, lng: 18.140523 },
    { lat: 48.3860827, lng: 18.140855 },
    { lat: 48.3860273, lng: 18.1412784 },
    { lat: 48.3857057, lng: 18.1425186 },
    { lat: 48.3852521, lng: 18.1436586 },
    { lat: 48.3847629, lng: 18.1439403 },
    { lat: 48.3846361, lng: 18.1443002 },
    { lat: 48.3844168, lng: 18.1446018 },
    { lat: 48.3841222, lng: 18.1447357 },
    { lat: 48.3837514, lng: 18.1450528 },
    { lat: 48.3833215, lng: 18.1452266 },
    { lat: 48.383183, lng: 18.1453861 },
    { lat: 48.3827927, lng: 18.145644 },
    { lat: 48.3817889, lng: 18.1459035 },
    { lat: 48.3816792, lng: 18.1463056 },
    { lat: 48.3815921, lng: 18.1463799 },
    { lat: 48.3810937, lng: 18.1462328 },
    { lat: 48.3805535, lng: 18.1463525 },
    { lat: 48.3799601, lng: 18.1467245 },
    { lat: 48.379328, lng: 18.1469336 },
    { lat: 48.3787569, lng: 18.1469502 },
    { lat: 48.37851, lng: 18.1470156 },
    { lat: 48.3777671, lng: 18.1469891 },
    { lat: 48.3774751, lng: 18.146937 },
    { lat: 48.3772796, lng: 18.1468328 },
    { lat: 48.3771207, lng: 18.1468565 },
    { lat: 48.3767647, lng: 18.14658 },
    { lat: 48.3758678, lng: 18.1465801 },
    { lat: 48.3756003, lng: 18.1467384 },
    { lat: 48.3752959, lng: 18.146684 },
    { lat: 48.3749848, lng: 18.1468236 },
    { lat: 48.3748296, lng: 18.1470087 },
    { lat: 48.3741588, lng: 18.1471725 },
    { lat: 48.3741085, lng: 18.1473549 },
    { lat: 48.3738386, lng: 18.147702 },
    { lat: 48.3735695, lng: 18.1476742 },
    { lat: 48.372966, lng: 18.147444 },
    { lat: 48.3727599, lng: 18.1480326 },
    { lat: 48.3726896, lng: 18.1485509 },
    { lat: 48.3723381, lng: 18.148617 },
    { lat: 48.3717338, lng: 18.1489161 },
    { lat: 48.3713662, lng: 18.149675 },
    { lat: 48.3706559, lng: 18.1508476 },
    { lat: 48.3701773, lng: 18.1513098 },
    { lat: 48.3699076, lng: 18.1516565 },
    { lat: 48.369683, lng: 18.1518518 },
    { lat: 48.3691765, lng: 18.1520067 },
    { lat: 48.3687528, lng: 18.1522725 },
    { lat: 48.3685252, lng: 18.1523137 },
    { lat: 48.3682236, lng: 18.1524669 },
    { lat: 48.3679935, lng: 18.1530103 },
  ],
  Kolíňany: [
    { lat: 48.3555241, lng: 18.1835934 },
    { lat: 48.3555349, lng: 18.1836256 },
    { lat: 48.3546377, lng: 18.1845362 },
    { lat: 48.3542042, lng: 18.184804 },
    { lat: 48.3533164, lng: 18.1856654 },
    { lat: 48.35286, lng: 18.1861792 },
    { lat: 48.3525353, lng: 18.1866715 },
    { lat: 48.3518147, lng: 18.1874975 },
    { lat: 48.3514902, lng: 18.1876226 },
    { lat: 48.3505612, lng: 18.1874598 },
    { lat: 48.3500724, lng: 18.1870883 },
    { lat: 48.3495636, lng: 18.1868008 },
    { lat: 48.3487937, lng: 18.1867367 },
    { lat: 48.3484904, lng: 18.1869126 },
    { lat: 48.3472231, lng: 18.1870295 },
    { lat: 48.3455966, lng: 18.1869831 },
    { lat: 48.3448347, lng: 18.1870407 },
    { lat: 48.3409934, lng: 18.1878564 },
    { lat: 48.3410053, lng: 18.187886 },
    { lat: 48.3414319, lng: 18.1882173 },
    { lat: 48.3420956, lng: 18.1884722 },
    { lat: 48.3426687, lng: 18.1888787 },
    { lat: 48.3438575, lng: 18.1903466 },
    { lat: 48.3439002, lng: 18.1903418 },
    { lat: 48.3440459, lng: 18.1912216 },
    { lat: 48.3443659, lng: 18.1921078 },
    { lat: 48.344533, lng: 18.1929827 },
    { lat: 48.3448723, lng: 18.1939533 },
    { lat: 48.3452469, lng: 18.1954872 },
    { lat: 48.3452688, lng: 18.1960192 },
    { lat: 48.3454125, lng: 18.196496 },
    { lat: 48.3454468, lng: 18.1970258 },
    { lat: 48.3456265, lng: 18.1977927 },
    { lat: 48.345766, lng: 18.1980785 },
    { lat: 48.3459196, lng: 18.1982564 },
    { lat: 48.3461762, lng: 18.1990026 },
    { lat: 48.3465055, lng: 18.2003821 },
    { lat: 48.3465186, lng: 18.2010154 },
    { lat: 48.3464767, lng: 18.2011985 },
    { lat: 48.3464095, lng: 18.2014925 },
    { lat: 48.3463871, lng: 18.2015905 },
    { lat: 48.3458379, lng: 18.2023556 },
    { lat: 48.3451394, lng: 18.2035647 },
    { lat: 48.3447999, lng: 18.2043666 },
    { lat: 48.3445995, lng: 18.2049763 },
    { lat: 48.3441497, lng: 18.2058248 },
    { lat: 48.3434867, lng: 18.2067692 },
    { lat: 48.343231, lng: 18.2073243 },
    { lat: 48.342808, lng: 18.2078926 },
    { lat: 48.3423956, lng: 18.2086028 },
    { lat: 48.3415587, lng: 18.2097794 },
    { lat: 48.3408865, lng: 18.2110143 },
    { lat: 48.3402828, lng: 18.2116597 },
    { lat: 48.3402721, lng: 18.2116919 },
    { lat: 48.339713, lng: 18.2133722 },
    { lat: 48.3397018, lng: 18.2134072 },
    { lat: 48.3395918, lng: 18.2137511 },
    { lat: 48.339583, lng: 18.2137628 },
    { lat: 48.3395724, lng: 18.2138188 },
    { lat: 48.3395588, lng: 18.2138902 },
    { lat: 48.3394775, lng: 18.2143182 },
    { lat: 48.3394896, lng: 18.2145077 },
    { lat: 48.3396842, lng: 18.2154727 },
    { lat: 48.3397113, lng: 18.2158718 },
    { lat: 48.3397364, lng: 18.2162409 },
    { lat: 48.3397658, lng: 18.2164521 },
    { lat: 48.3398627, lng: 18.2171489 },
    { lat: 48.3398853, lng: 18.2173114 },
    { lat: 48.3399127, lng: 18.2174214 },
    { lat: 48.3401261, lng: 18.2182793 },
    { lat: 48.3402102, lng: 18.2201024 },
    { lat: 48.3401694, lng: 18.2218818 },
    { lat: 48.3399391, lng: 18.2229704 },
    { lat: 48.3399498, lng: 18.2240565 },
    { lat: 48.3398744, lng: 18.2249785 },
    { lat: 48.3398737, lng: 18.2260036 },
    { lat: 48.3397805, lng: 18.2266544 },
    { lat: 48.3396246, lng: 18.2272602 },
    { lat: 48.3392398, lng: 18.228393 },
    { lat: 48.3390184, lng: 18.2293868 },
    { lat: 48.3388498, lng: 18.230806 },
    { lat: 48.338791, lng: 18.2315656 },
    { lat: 48.3387998, lng: 18.2322471 },
    { lat: 48.3390975, lng: 18.2342975 },
    { lat: 48.3391266, lng: 18.234786 },
    { lat: 48.3390493, lng: 18.2359689 },
    { lat: 48.3388089, lng: 18.2371233 },
    { lat: 48.3387695, lng: 18.2379863 },
    { lat: 48.3381802, lng: 18.2408414 },
    { lat: 48.3379126, lng: 18.2418209 },
    { lat: 48.3375829, lng: 18.2426988 },
    { lat: 48.3374808, lng: 18.2431174 },
    { lat: 48.3375253, lng: 18.2432042 },
    { lat: 48.337396, lng: 18.243806 },
    { lat: 48.33771, lng: 18.243944 },
    { lat: 48.338036, lng: 18.24411 },
    { lat: 48.338357, lng: 18.244263 },
    { lat: 48.338795, lng: 18.244511 },
    { lat: 48.339324, lng: 18.244854 },
    { lat: 48.33972, lng: 18.245175 },
    { lat: 48.339841, lng: 18.245285 },
    { lat: 48.339886, lng: 18.245325 },
    { lat: 48.340319, lng: 18.245499 },
    { lat: 48.34082, lng: 18.245708 },
    { lat: 48.341261, lng: 18.245882 },
    { lat: 48.341178, lng: 18.246291 },
    { lat: 48.340889, lng: 18.246966 },
    { lat: 48.340755, lng: 18.247375 },
    { lat: 48.34074, lng: 18.247496 },
    { lat: 48.340662, lng: 18.247944 },
    { lat: 48.340666, lng: 18.248339 },
    { lat: 48.340664, lng: 18.248502 },
    { lat: 48.340645, lng: 18.248823 },
    { lat: 48.340624, lng: 18.249136 },
    { lat: 48.340624, lng: 18.249637 },
    { lat: 48.340634, lng: 18.249933 },
    { lat: 48.34065, lng: 18.250329 },
    { lat: 48.340625, lng: 18.250627 },
    { lat: 48.340523, lng: 18.250966 },
    { lat: 48.341038, lng: 18.25117 },
    { lat: 48.341336, lng: 18.251382 },
    { lat: 48.341499, lng: 18.251502 },
    { lat: 48.341615, lng: 18.252187 },
    { lat: 48.341628, lng: 18.252287 },
    { lat: 48.341732, lng: 18.252735 },
    { lat: 48.342111, lng: 18.252831 },
    { lat: 48.34218, lng: 18.252734 },
    { lat: 48.342293, lng: 18.252508 },
    { lat: 48.342342, lng: 18.252398 },
    { lat: 48.342361, lng: 18.252273 },
    { lat: 48.342379, lng: 18.252123 },
    { lat: 48.342417, lng: 18.25208 },
    { lat: 48.342409, lng: 18.252024 },
    { lat: 48.342495, lng: 18.251929 },
    { lat: 48.342524, lng: 18.251765 },
    { lat: 48.342571, lng: 18.251733 },
    { lat: 48.342574, lng: 18.251484 },
    { lat: 48.34258, lng: 18.251426 },
    { lat: 48.342629, lng: 18.251393 },
    { lat: 48.342638, lng: 18.251286 },
    { lat: 48.342624, lng: 18.251211 },
    { lat: 48.342607, lng: 18.251205 },
    { lat: 48.342612, lng: 18.251156 },
    { lat: 48.342625, lng: 18.251109 },
    { lat: 48.34283, lng: 18.251303 },
    { lat: 48.34307, lng: 18.251541 },
    { lat: 48.343424, lng: 18.251886 },
    { lat: 48.343783, lng: 18.25223 },
    { lat: 48.343976, lng: 18.252439 },
    { lat: 48.344246, lng: 18.25272 },
    { lat: 48.344612, lng: 18.253084 },
    { lat: 48.345368, lng: 18.253836 },
    { lat: 48.345832, lng: 18.254313 },
    { lat: 48.346274, lng: 18.254734 },
    { lat: 48.346394, lng: 18.254885 },
    { lat: 48.346637, lng: 18.255232 },
    { lat: 48.346844, lng: 18.255549 },
    { lat: 48.347095, lng: 18.255919 },
    { lat: 48.34726, lng: 18.256173 },
    { lat: 48.347493, lng: 18.25651 },
    { lat: 48.347642, lng: 18.256717 },
    { lat: 48.347799, lng: 18.256899 },
    { lat: 48.348, lng: 18.257038 },
    { lat: 48.348374, lng: 18.257245 },
    { lat: 48.348631, lng: 18.25746 },
    { lat: 48.348991, lng: 18.257779 },
    { lat: 48.349404, lng: 18.258143 },
    { lat: 48.350068, lng: 18.258755 },
    { lat: 48.350322, lng: 18.258985 },
    { lat: 48.350783, lng: 18.259258 },
    { lat: 48.351086, lng: 18.259361 },
    { lat: 48.351227, lng: 18.25939 },
    { lat: 48.351661, lng: 18.259447 },
    { lat: 48.351818, lng: 18.259489 },
    { lat: 48.3522, lng: 18.259781 },
    { lat: 48.352399, lng: 18.260106 },
    { lat: 48.3565271, lng: 18.2560599 },
    { lat: 48.3565308, lng: 18.2560048 },
    { lat: 48.355605, lng: 18.2546242 },
    { lat: 48.3605899, lng: 18.2484736 },
    { lat: 48.3604717, lng: 18.2482401 },
    { lat: 48.3613054, lng: 18.2469823 },
    { lat: 48.3645026, lng: 18.237402 },
    { lat: 48.360405, lng: 18.228468 },
    { lat: 48.3597384, lng: 18.2271037 },
    { lat: 48.36025, lng: 18.2260935 },
    { lat: 48.359501, lng: 18.22418 },
    { lat: 48.359679, lng: 18.2235906 },
    { lat: 48.3590287, lng: 18.222021 },
    { lat: 48.3591001, lng: 18.221868 },
    { lat: 48.3586886, lng: 18.2208184 },
    { lat: 48.3580085, lng: 18.218833 },
    { lat: 48.3566998, lng: 18.215203 },
    { lat: 48.3577124, lng: 18.2140802 },
    { lat: 48.3589223, lng: 18.2123861 },
    { lat: 48.3592817, lng: 18.2120626 },
    { lat: 48.3599779, lng: 18.2116338 },
    { lat: 48.3606958, lng: 18.2113062 },
    { lat: 48.3622474, lng: 18.2107997 },
    { lat: 48.3612088, lng: 18.2072 },
    { lat: 48.3615273, lng: 18.2070112 },
    { lat: 48.3614006, lng: 18.2063422 },
    { lat: 48.3615528, lng: 18.2063607 },
    { lat: 48.3616741, lng: 18.2062313 },
    { lat: 48.3617684, lng: 18.2059884 },
    { lat: 48.3619909, lng: 18.2060127 },
    { lat: 48.3621573, lng: 18.2058849 },
    { lat: 48.3622886, lng: 18.2061644 },
    { lat: 48.3624007, lng: 18.2062903 },
    { lat: 48.3631089, lng: 18.2061445 },
    { lat: 48.3633404, lng: 18.2059922 },
    { lat: 48.3637051, lng: 18.2059163 },
    { lat: 48.3642817, lng: 18.2055055 },
    { lat: 48.364385, lng: 18.2055179 },
    { lat: 48.364407, lng: 18.2054108 },
    { lat: 48.3646247, lng: 18.2052145 },
    { lat: 48.3645825, lng: 18.205131 },
    { lat: 48.3646464, lng: 18.2049261 },
    { lat: 48.3647289, lng: 18.2048692 },
    { lat: 48.3649107, lng: 18.2052447 },
    { lat: 48.3650532, lng: 18.2053084 },
    { lat: 48.365132, lng: 18.2052244 },
    { lat: 48.3651616, lng: 18.2049586 },
    { lat: 48.3653531, lng: 18.204934 },
    { lat: 48.3654829, lng: 18.2048142 },
    { lat: 48.365675, lng: 18.2041745 },
    { lat: 48.3657987, lng: 18.2039554 },
    { lat: 48.3658856, lng: 18.2038965 },
    { lat: 48.36606, lng: 18.2040612 },
    { lat: 48.3662188, lng: 18.2039205 },
    { lat: 48.3663555, lng: 18.2035528 },
    { lat: 48.3664045, lng: 18.2035219 },
    { lat: 48.3666251, lng: 18.2036107 },
    { lat: 48.3666497, lng: 18.2034314 },
    { lat: 48.3669248, lng: 18.2032536 },
    { lat: 48.3671617, lng: 18.2032902 },
    { lat: 48.3672921, lng: 18.2034754 },
    { lat: 48.367364, lng: 18.2034519 },
    { lat: 48.3675378, lng: 18.2030431 },
    { lat: 48.3678545, lng: 18.2029585 },
    { lat: 48.3678832, lng: 18.202772 },
    { lat: 48.3678165, lng: 18.2025139 },
    { lat: 48.3679825, lng: 18.2022651 },
    { lat: 48.3681613, lng: 18.2023312 },
    { lat: 48.3683097, lng: 18.2022845 },
    { lat: 48.3682813, lng: 18.2021615 },
    { lat: 48.3683617, lng: 18.2020102 },
    { lat: 48.3684295, lng: 18.2019932 },
    { lat: 48.3685519, lng: 18.2021798 },
    { lat: 48.3686261, lng: 18.2020186 },
    { lat: 48.3686927, lng: 18.2019968 },
    { lat: 48.3687972, lng: 18.2021273 },
    { lat: 48.368891, lng: 18.2021463 },
    { lat: 48.3689479, lng: 18.2020799 },
    { lat: 48.3688693, lng: 18.2017555 },
    { lat: 48.3688655, lng: 18.2014732 },
    { lat: 48.369148, lng: 18.2015009 },
    { lat: 48.3691821, lng: 18.2011334 },
    { lat: 48.3692774, lng: 18.2011427 },
    { lat: 48.3693424, lng: 18.201076 },
    { lat: 48.3693255, lng: 18.2007641 },
    { lat: 48.3695127, lng: 18.2005101 },
    { lat: 48.3694799, lng: 18.2004125 },
    { lat: 48.3693313, lng: 18.2004195 },
    { lat: 48.369295, lng: 18.2002794 },
    { lat: 48.3694058, lng: 18.2000478 },
    { lat: 48.3693841, lng: 18.199947 },
    { lat: 48.3694174, lng: 18.1998397 },
    { lat: 48.3696291, lng: 18.1997358 },
    { lat: 48.3697227, lng: 18.1996164 },
    { lat: 48.369737, lng: 18.1993157 },
    { lat: 48.3698523, lng: 18.1991835 },
    { lat: 48.3699271, lng: 18.1992874 },
    { lat: 48.3699912, lng: 18.1992258 },
    { lat: 48.3699792, lng: 18.1989785 },
    { lat: 48.3700338, lng: 18.1990264 },
    { lat: 48.3700509, lng: 18.1987751 },
    { lat: 48.3699529, lng: 18.1984095 },
    { lat: 48.3699878, lng: 18.1978237 },
    { lat: 48.3699585, lng: 18.1970609 },
    { lat: 48.3703945, lng: 18.1955919 },
    { lat: 48.3709277, lng: 18.1941594 },
    { lat: 48.3712275, lng: 18.19355 },
    { lat: 48.3701927, lng: 18.1921097 },
    { lat: 48.3688459, lng: 18.1900923 },
    { lat: 48.3674053, lng: 18.1883312 },
    { lat: 48.3654916, lng: 18.1861546 },
    { lat: 48.3651306, lng: 18.1856315 },
    { lat: 48.3641509, lng: 18.1854757 },
    { lat: 48.3626578, lng: 18.1854508 },
    { lat: 48.3620388, lng: 18.1852653 },
    { lat: 48.361206, lng: 18.1848575 },
    { lat: 48.3608099, lng: 18.1845679 },
    { lat: 48.3604117, lng: 18.1844131 },
    { lat: 48.3601561, lng: 18.1840917 },
    { lat: 48.3598208, lng: 18.18333 },
    { lat: 48.3596363, lng: 18.1833212 },
    { lat: 48.3585563, lng: 18.1836717 },
    { lat: 48.35834, lng: 18.1836961 },
    { lat: 48.3579415, lng: 18.1835782 },
    { lat: 48.3575742, lng: 18.1833154 },
    { lat: 48.357016, lng: 18.1827962 },
    { lat: 48.3569693, lng: 18.1828474 },
    { lat: 48.356646, lng: 18.182081 },
    { lat: 48.3566006, lng: 18.1821342 },
    { lat: 48.3555241, lng: 18.1835934 },
  ],
  Rišňovce: [
    { lat: 48.392677, lng: 17.899622 },
    { lat: 48.392667, lng: 17.899566 },
    { lat: 48.392616, lng: 17.899429 },
    { lat: 48.392528, lng: 17.899183 },
    { lat: 48.3923, lng: 17.898853 },
    { lat: 48.392004, lng: 17.898478 },
    { lat: 48.391719, lng: 17.898038 },
    { lat: 48.391594, lng: 17.897844 },
    { lat: 48.391341, lng: 17.897332 },
    { lat: 48.391087, lng: 17.89698 },
    { lat: 48.39086, lng: 17.89676 },
    { lat: 48.390714, lng: 17.896635 },
    { lat: 48.390506, lng: 17.896474 },
    { lat: 48.390321, lng: 17.896385 },
    { lat: 48.390128, lng: 17.896371 },
    { lat: 48.38983, lng: 17.896123 },
    { lat: 48.389665, lng: 17.896009 },
    { lat: 48.389454, lng: 17.895843 },
    { lat: 48.389168, lng: 17.895675 },
    { lat: 48.38896, lng: 17.895602 },
    { lat: 48.388759, lng: 17.895324 },
    { lat: 48.388578, lng: 17.894898 },
    { lat: 48.388268, lng: 17.894497 },
    { lat: 48.388068, lng: 17.89424 },
    { lat: 48.387835, lng: 17.894103 },
    { lat: 48.387719, lng: 17.89397 },
    { lat: 48.387555, lng: 17.893761 },
    { lat: 48.387399, lng: 17.893266 },
    { lat: 48.387159, lng: 17.892812 },
    { lat: 48.386815, lng: 17.892368 },
    { lat: 48.386319, lng: 17.89187 },
    { lat: 48.386086, lng: 17.89147 },
    { lat: 48.385965, lng: 17.891417 },
    { lat: 48.385879, lng: 17.891344 },
    { lat: 48.385806, lng: 17.891346 },
    { lat: 48.38561, lng: 17.891269 },
    { lat: 48.385374, lng: 17.891172 },
    { lat: 48.38519, lng: 17.891038 },
    { lat: 48.384941, lng: 17.890718 },
    { lat: 48.384831, lng: 17.890644 },
    { lat: 48.384527, lng: 17.890584 },
    { lat: 48.384294, lng: 17.890545 },
    { lat: 48.383965, lng: 17.890403 },
    { lat: 48.383837, lng: 17.890376 },
    { lat: 48.383548, lng: 17.890275 },
    { lat: 48.38339, lng: 17.890265 },
    { lat: 48.383138, lng: 17.890136 },
    { lat: 48.382819, lng: 17.889954 },
    { lat: 48.382564, lng: 17.889815 },
    { lat: 48.382429, lng: 17.889696 },
    { lat: 48.382274, lng: 17.889461 },
    { lat: 48.382061, lng: 17.889281 },
    { lat: 48.381792, lng: 17.889018 },
    { lat: 48.381564, lng: 17.888802 },
    { lat: 48.381517, lng: 17.88878 },
    { lat: 48.380896, lng: 17.888261 },
    { lat: 48.380567, lng: 17.887887 },
    { lat: 48.38018, lng: 17.887473 },
    { lat: 48.379948, lng: 17.887206 },
    { lat: 48.379872, lng: 17.88711 },
    { lat: 48.379513, lng: 17.88666 },
    { lat: 48.378758, lng: 17.885713 },
    { lat: 48.378423, lng: 17.885295 },
    { lat: 48.378087, lng: 17.884866 },
    { lat: 48.378074, lng: 17.884871 },
    { lat: 48.378047, lng: 17.884838 },
    { lat: 48.377733, lng: 17.884423 },
    { lat: 48.377478, lng: 17.884077 },
    { lat: 48.377462, lng: 17.884098 },
    { lat: 48.377133, lng: 17.883648 },
    { lat: 48.376831, lng: 17.883292 },
    { lat: 48.376604, lng: 17.883031 },
    { lat: 48.376356, lng: 17.882723 },
    { lat: 48.376072, lng: 17.882374 },
    { lat: 48.375753, lng: 17.882024 },
    { lat: 48.37544, lng: 17.881694 },
    { lat: 48.375311, lng: 17.881547 },
    { lat: 48.375192, lng: 17.881452 },
    { lat: 48.374785, lng: 17.88112 },
    { lat: 48.374411, lng: 17.880806 },
    { lat: 48.374118, lng: 17.88056 },
    { lat: 48.373828, lng: 17.880347 },
    { lat: 48.373645, lng: 17.88023 },
    { lat: 48.373187, lng: 17.87996 },
    { lat: 48.372464, lng: 17.879624 },
    { lat: 48.372376, lng: 17.87957 },
    { lat: 48.372003, lng: 17.879443 },
    { lat: 48.371627, lng: 17.879317 },
    { lat: 48.371308, lng: 17.879193 },
    { lat: 48.370948, lng: 17.879069 },
    { lat: 48.370564, lng: 17.878931 },
    { lat: 48.370181, lng: 17.87879 },
    { lat: 48.37001, lng: 17.878768 },
    { lat: 48.369839, lng: 17.878716 },
    { lat: 48.369601, lng: 17.878586 },
    { lat: 48.36947, lng: 17.87859 },
    { lat: 48.369248, lng: 17.87867 },
    { lat: 48.369152, lng: 17.878679 },
    { lat: 48.369064, lng: 17.878747 },
    { lat: 48.368951, lng: 17.878844 },
    { lat: 48.368662, lng: 17.879017 },
    { lat: 48.36847, lng: 17.879017 },
    { lat: 48.368178, lng: 17.878764 },
    { lat: 48.368169, lng: 17.878788 },
    { lat: 48.367951, lng: 17.87872 },
    { lat: 48.367827, lng: 17.878595 },
    { lat: 48.367736, lng: 17.878526 },
    { lat: 48.367457, lng: 17.878163 },
    { lat: 48.367123, lng: 17.877663 },
    { lat: 48.366874, lng: 17.877428 },
    { lat: 48.36629, lng: 17.877017 },
    { lat: 48.3659953, lng: 17.8777697 },
    { lat: 48.3657892, lng: 17.8788398 },
    { lat: 48.365379, lng: 17.8803926 },
    { lat: 48.3650426, lng: 17.8810432 },
    { lat: 48.3645524, lng: 17.8817088 },
    { lat: 48.3641854, lng: 17.8824863 },
    { lat: 48.3636344, lng: 17.8833767 },
    { lat: 48.3629784, lng: 17.8834498 },
    { lat: 48.3627446, lng: 17.8832616 },
    { lat: 48.3625684, lng: 17.8832623 },
    { lat: 48.3623758, lng: 17.8835662 },
    { lat: 48.3622137, lng: 17.8837201 },
    { lat: 48.3620854, lng: 17.8836322 },
    { lat: 48.3616883, lng: 17.8840866 },
    { lat: 48.3615028, lng: 17.8843973 },
    { lat: 48.3613509, lng: 17.8846652 },
    { lat: 48.3610547, lng: 17.8854362 },
    { lat: 48.3603396, lng: 17.8847385 },
    { lat: 48.3590454, lng: 17.883245 },
    { lat: 48.3581247, lng: 17.8828587 },
    { lat: 48.3572956, lng: 17.8820333 },
    { lat: 48.3565302, lng: 17.8815122 },
    { lat: 48.3558502, lng: 17.881191 },
    { lat: 48.3552368, lng: 17.8810158 },
    { lat: 48.3552176, lng: 17.881088 },
    { lat: 48.354821, lng: 17.8810438 },
    { lat: 48.3537421, lng: 17.8809235 },
    { lat: 48.3531165, lng: 17.8807142 },
    { lat: 48.3521638, lng: 17.8798788 },
    { lat: 48.3513173, lng: 17.878915 },
    { lat: 48.3512982, lng: 17.87899 },
    { lat: 48.3504712, lng: 17.8787372 },
    { lat: 48.3501873, lng: 17.8788023 },
    { lat: 48.3500368, lng: 17.8797623 },
    { lat: 48.3499249, lng: 17.8813395 },
    { lat: 48.3496718, lng: 17.8820608 },
    { lat: 48.3493749, lng: 17.8826912 },
    { lat: 48.3492245, lng: 17.8833935 },
    { lat: 48.3485588, lng: 17.8858164 },
    { lat: 48.3482044, lng: 17.8863219 },
    { lat: 48.3480041, lng: 17.8868141 },
    { lat: 48.3476604, lng: 17.8889654 },
    { lat: 48.3470167, lng: 17.8898334 },
    { lat: 48.3468729, lng: 17.8901433 },
    { lat: 48.3468052, lng: 17.8903676 },
    { lat: 48.3467504, lng: 17.8910085 },
    { lat: 48.3465245, lng: 17.8923072 },
    { lat: 48.3465529, lng: 17.892776 },
    { lat: 48.3464824, lng: 17.8930844 },
    { lat: 48.346188, lng: 17.8935533 },
    { lat: 48.3459695, lng: 17.8938787 },
    { lat: 48.3460613, lng: 17.8943115 },
    { lat: 48.3461743, lng: 17.8953216 },
    { lat: 48.3461042, lng: 17.8954877 },
    { lat: 48.3455805, lng: 17.8962678 },
    { lat: 48.3448693, lng: 17.896902 },
    { lat: 48.3435442, lng: 17.8979092 },
    { lat: 48.3429215, lng: 17.8987354 },
    { lat: 48.3422795, lng: 17.9000077 },
    { lat: 48.3416545, lng: 17.9018032 },
    { lat: 48.3412504, lng: 17.9027911 },
    { lat: 48.3407731, lng: 17.9036151 },
    { lat: 48.3400898, lng: 17.9051569 },
    { lat: 48.3398288, lng: 17.9056001 },
    { lat: 48.3393125, lng: 17.9060579 },
    { lat: 48.3389526, lng: 17.9067165 },
    { lat: 48.3369749, lng: 17.908786 },
    { lat: 48.3362607, lng: 17.9098 },
    { lat: 48.3358509, lng: 17.9101695 },
    { lat: 48.3350079, lng: 17.9106393 },
    { lat: 48.3346389, lng: 17.9109258 },
    { lat: 48.3340637, lng: 17.9115682 },
    { lat: 48.3329549, lng: 17.9130711 },
    { lat: 48.3318105, lng: 17.9142325 },
    { lat: 48.3302212, lng: 17.9155789 },
    { lat: 48.3291516, lng: 17.9160724 },
    { lat: 48.3284912, lng: 17.9165942 },
    { lat: 48.3280515, lng: 17.9170527 },
    { lat: 48.3284018, lng: 17.9173765 },
    { lat: 48.3292506, lng: 17.9186268 },
    { lat: 48.3299686, lng: 17.9193908 },
    { lat: 48.3314497, lng: 17.9207602 },
    { lat: 48.3315582, lng: 17.920837 },
    { lat: 48.3319668, lng: 17.9208929 },
    { lat: 48.3321859, lng: 17.9210781 },
    { lat: 48.332539, lng: 17.9215087 },
    { lat: 48.3326309, lng: 17.9217761 },
    { lat: 48.332659, lng: 17.9227822 },
    { lat: 48.3330715, lng: 17.924043 },
    { lat: 48.3332475, lng: 17.9244147 },
    { lat: 48.3342268, lng: 17.9256616 },
    { lat: 48.3352211, lng: 17.9270595 },
    { lat: 48.3353149, lng: 17.9272845 },
    { lat: 48.3360773, lng: 17.930099 },
    { lat: 48.3364765, lng: 17.9306156 },
    { lat: 48.3359834, lng: 17.9316297 },
    { lat: 48.3369788, lng: 17.9343793 },
    { lat: 48.3368394, lng: 17.9347438 },
    { lat: 48.3373244, lng: 17.9356753 },
    { lat: 48.3379066, lng: 17.9365862 },
    { lat: 48.3388588, lng: 17.9377978 },
    { lat: 48.3387756, lng: 17.9379166 },
    { lat: 48.3388826, lng: 17.9383626 },
    { lat: 48.3395511, lng: 17.939584 },
    { lat: 48.3403194, lng: 17.9406088 },
    { lat: 48.3405501, lng: 17.9411012 },
    { lat: 48.3408913, lng: 17.9414807 },
    { lat: 48.3416489, lng: 17.943134 },
    { lat: 48.3416924, lng: 17.9432004 },
    { lat: 48.3417201, lng: 17.9432485 },
    { lat: 48.3444866, lng: 17.9396114 },
    { lat: 48.3460397, lng: 17.9374709 },
    { lat: 48.3483809, lng: 17.9340643 },
    { lat: 48.3498039, lng: 17.9322041 },
    { lat: 48.3573125, lng: 17.9251834 },
    { lat: 48.3578205, lng: 17.9246263 },
    { lat: 48.3589509, lng: 17.9229315 },
    { lat: 48.3600408, lng: 17.9242865 },
    { lat: 48.3601111, lng: 17.9245155 },
    { lat: 48.3613915, lng: 17.9258514 },
    { lat: 48.3619776, lng: 17.9264551 },
    { lat: 48.364009, lng: 17.9281694 },
    { lat: 48.364022, lng: 17.9281222 },
    { lat: 48.3649113, lng: 17.928438 },
    { lat: 48.367337, lng: 17.9290144 },
    { lat: 48.3673685, lng: 17.9290165 },
    { lat: 48.3673899, lng: 17.9288645 },
    { lat: 48.3682424, lng: 17.9289979 },
    { lat: 48.3694005, lng: 17.9292804 },
    { lat: 48.3706567, lng: 17.9296887 },
    { lat: 48.3709981, lng: 17.929866 },
    { lat: 48.3724681, lng: 17.9311248 },
    { lat: 48.3733755, lng: 17.9290254 },
    { lat: 48.3744193, lng: 17.9269426 },
    { lat: 48.3753621, lng: 17.9253382 },
    { lat: 48.3758086, lng: 17.9248812 },
    { lat: 48.3764155, lng: 17.9241241 },
    { lat: 48.3776041, lng: 17.923132 },
    { lat: 48.380855, lng: 17.9225691 },
    { lat: 48.3826356, lng: 17.9219065 },
    { lat: 48.382716, lng: 17.9219289 },
    { lat: 48.3830081, lng: 17.921701 },
    { lat: 48.3841667, lng: 17.9211457 },
    { lat: 48.386065, lng: 17.9196061 },
    { lat: 48.3867686, lng: 17.9187539 },
    { lat: 48.387388, lng: 17.9178869 },
    { lat: 48.387781, lng: 17.916525 },
    { lat: 48.3883501, lng: 17.9158008 },
    { lat: 48.3883306, lng: 17.9152694 },
    { lat: 48.3883894, lng: 17.9135723 },
    { lat: 48.3883489, lng: 17.912154 },
    { lat: 48.3888789, lng: 17.910371 },
    { lat: 48.3892467, lng: 17.9094312 },
    { lat: 48.3896297, lng: 17.9072918 },
    { lat: 48.3899558, lng: 17.9062614 },
    { lat: 48.3901564, lng: 17.905848 },
    { lat: 48.3907847, lng: 17.9039747 },
    { lat: 48.3920664, lng: 17.9009229 },
    { lat: 48.3926066, lng: 17.8998309 },
    { lat: 48.3926408, lng: 17.8996113 },
    { lat: 48.392677, lng: 17.899622 },
  ],
  Čifáre: [
    { lat: 48.2476028, lng: 18.3756977 },
    { lat: 48.2475778, lng: 18.3757522 },
    { lat: 48.2475454, lng: 18.3758189 },
    { lat: 48.2475061, lng: 18.3759032 },
    { lat: 48.2474035, lng: 18.3761031 },
    { lat: 48.2472946, lng: 18.3763151 },
    { lat: 48.2472408, lng: 18.37642 },
    { lat: 48.2470772, lng: 18.3767009 },
    { lat: 48.2470537, lng: 18.3767417 },
    { lat: 48.2470269, lng: 18.3767865 },
    { lat: 48.246922, lng: 18.3769665 },
    { lat: 48.2468838, lng: 18.3770322 },
    { lat: 48.2468716, lng: 18.3770536 },
    { lat: 48.2468437, lng: 18.3771007 },
    { lat: 48.2467739, lng: 18.3772199 },
    { lat: 48.2467286, lng: 18.3772972 },
    { lat: 48.2466234, lng: 18.3774737 },
    { lat: 48.246618, lng: 18.3774783 },
    { lat: 48.2466115, lng: 18.377484 },
    { lat: 48.2464756, lng: 18.3776156 },
    { lat: 48.2463078, lng: 18.3777791 },
    { lat: 48.2462033, lng: 18.3778837 },
    { lat: 48.2460952, lng: 18.3780088 },
    { lat: 48.2459143, lng: 18.3782215 },
    { lat: 48.2458771, lng: 18.3782619 },
    { lat: 48.2456177, lng: 18.3784661 },
    { lat: 48.2453044, lng: 18.3787128 },
    { lat: 48.2449173, lng: 18.3790499 },
    { lat: 48.2446594, lng: 18.3792772 },
    { lat: 48.2443975, lng: 18.3794905 },
    { lat: 48.2441373, lng: 18.3797011 },
    { lat: 48.2439438, lng: 18.3798509 },
    { lat: 48.2437009, lng: 18.3800243 },
    { lat: 48.2435048, lng: 18.3801652 },
    { lat: 48.2433462, lng: 18.3802373 },
    { lat: 48.2431211, lng: 18.3803458 },
    { lat: 48.2429113, lng: 18.3804449 },
    { lat: 48.242624, lng: 18.3805934 },
    { lat: 48.2424407, lng: 18.3806853 },
    { lat: 48.2424398, lng: 18.3806854 },
    { lat: 48.242423, lng: 18.3806896 },
    { lat: 48.2424186, lng: 18.3806906 },
    { lat: 48.242413, lng: 18.3806923 },
    { lat: 48.2424097, lng: 18.3806931 },
    { lat: 48.2423929, lng: 18.3806973 },
    { lat: 48.2422749, lng: 18.380747 },
    { lat: 48.2420941, lng: 18.3808187 },
    { lat: 48.2419441, lng: 18.3808798 },
    { lat: 48.2417667, lng: 18.3809539 },
    { lat: 48.2416231, lng: 18.3810103 },
    { lat: 48.2414667, lng: 18.3810743 },
    { lat: 48.2412414, lng: 18.381163 },
    { lat: 48.2409215, lng: 18.3812916 },
    { lat: 48.2408167, lng: 18.3813325 },
    { lat: 48.240526, lng: 18.3814811 },
    { lat: 48.2402349, lng: 18.381623 },
    { lat: 48.2400134, lng: 18.3817533 },
    { lat: 48.2397664, lng: 18.3818953 },
    { lat: 48.2395572, lng: 18.3820262 },
    { lat: 48.2393038, lng: 18.3821743 },
    { lat: 48.2390766, lng: 18.3823075 },
    { lat: 48.2387799, lng: 18.3824887 },
    { lat: 48.2383673, lng: 18.3826552 },
    { lat: 48.2380519, lng: 18.3827797 },
    { lat: 48.2377693, lng: 18.3828706 },
    { lat: 48.2375444, lng: 18.3829454 },
    { lat: 48.2372598, lng: 18.3829793 },
    { lat: 48.2369901, lng: 18.3830173 },
    { lat: 48.2368444, lng: 18.3830378 },
    { lat: 48.2364904, lng: 18.3830823 },
    { lat: 48.2361635, lng: 18.3831257 },
    { lat: 48.2359328, lng: 18.383156 },
    { lat: 48.2357919, lng: 18.3831756 },
    { lat: 48.2356811, lng: 18.3831935 },
    { lat: 48.235595, lng: 18.3832068 },
    { lat: 48.2353973, lng: 18.3832435 },
    { lat: 48.235246, lng: 18.3832595 },
    { lat: 48.2349647, lng: 18.3832936 },
    { lat: 48.2347786, lng: 18.3833136 },
    { lat: 48.2345701, lng: 18.38334 },
    { lat: 48.2342839, lng: 18.3834038 },
    { lat: 48.2338656, lng: 18.3834913 },
    { lat: 48.2338088, lng: 18.3835031 },
    { lat: 48.2338021, lng: 18.383505 },
    { lat: 48.2337755, lng: 18.3835105 },
    { lat: 48.2334734, lng: 18.3835795 },
    { lat: 48.2331955, lng: 18.3836695 },
    { lat: 48.2329781, lng: 18.3837175 },
    { lat: 48.2328864, lng: 18.3837503 },
    { lat: 48.2325457, lng: 18.383873 },
    { lat: 48.2324768, lng: 18.383892 },
    { lat: 48.2322068, lng: 18.3839626 },
    { lat: 48.2319982, lng: 18.384007 },
    { lat: 48.2319961, lng: 18.3840076 },
    { lat: 48.2319861, lng: 18.3840096 },
    { lat: 48.2319692, lng: 18.3840132 },
    { lat: 48.2318866, lng: 18.3840282 },
    { lat: 48.2318562, lng: 18.3840303 },
    { lat: 48.231853, lng: 18.3840307 },
    { lat: 48.2318428, lng: 18.3840316 },
    { lat: 48.2316945, lng: 18.3840429 },
    { lat: 48.2315843, lng: 18.3840877 },
    { lat: 48.2315684, lng: 18.3840865 },
    { lat: 48.2314198, lng: 18.3838533 },
    { lat: 48.2313901, lng: 18.3838058 },
    { lat: 48.2313586, lng: 18.3836873 },
    { lat: 48.2312387, lng: 18.3832046 },
    { lat: 48.2312328, lng: 18.3831802 },
    { lat: 48.2311015, lng: 18.3831494 },
    { lat: 48.2308178, lng: 18.3830823 },
    { lat: 48.2308042, lng: 18.3830791 },
    { lat: 48.2307217, lng: 18.3830591 },
    { lat: 48.2306223, lng: 18.3830359 },
    { lat: 48.2301197, lng: 18.382907 },
    { lat: 48.2301198, lng: 18.382908 },
    { lat: 48.2301156, lng: 18.3829132 },
    { lat: 48.2301, lng: 18.3829381 },
    { lat: 48.2300991, lng: 18.3829397 },
    { lat: 48.2300952, lng: 18.382955 },
    { lat: 48.2300878, lng: 18.3829825 },
    { lat: 48.2300821, lng: 18.3829828 },
    { lat: 48.2298676, lng: 18.3829972 },
    { lat: 48.2298289, lng: 18.3829912 },
    { lat: 48.2298096, lng: 18.3829876 },
    { lat: 48.2294143, lng: 18.3829208 },
    { lat: 48.229079, lng: 18.3827608 },
    { lat: 48.2287457, lng: 18.3828281 },
    { lat: 48.2284321, lng: 18.3828883 },
    { lat: 48.2278904, lng: 18.3829879 },
    { lat: 48.2277036, lng: 18.3830165 },
    { lat: 48.2273442, lng: 18.3826646 },
    { lat: 48.2269643, lng: 18.3826684 },
    { lat: 48.2267125, lng: 18.3826687 },
    { lat: 48.2266744, lng: 18.3826512 },
    { lat: 48.2265421, lng: 18.3825866 },
    { lat: 48.2263555, lng: 18.3825178 },
    { lat: 48.2261165, lng: 18.3824808 },
    { lat: 48.2259201, lng: 18.3824986 },
    { lat: 48.2258465, lng: 18.382498 },
    { lat: 48.2257471, lng: 18.382495 },
    { lat: 48.2257291, lng: 18.3824944 },
    { lat: 48.2255936, lng: 18.3825321 },
    { lat: 48.2254833, lng: 18.3824962 },
    { lat: 48.225459, lng: 18.3824883 },
    { lat: 48.2254491, lng: 18.3824888 },
    { lat: 48.2253003, lng: 18.3824924 },
    { lat: 48.2251411, lng: 18.3825111 },
    { lat: 48.2250458, lng: 18.3825228 },
    { lat: 48.2248588, lng: 18.3825659 },
    { lat: 48.2246589, lng: 18.3826213 },
    { lat: 48.2245809, lng: 18.3826379 },
    { lat: 48.2244622, lng: 18.3826546 },
    { lat: 48.2242549, lng: 18.3826805 },
    { lat: 48.2242336, lng: 18.3826853 },
    { lat: 48.2240613, lng: 18.382711 },
    { lat: 48.2239071, lng: 18.382734 },
    { lat: 48.2237332, lng: 18.3827737 },
    { lat: 48.2237287, lng: 18.3827746 },
    { lat: 48.2235761, lng: 18.3828105 },
    { lat: 48.2234479, lng: 18.3828345 },
    { lat: 48.2233498, lng: 18.3828377 },
    { lat: 48.2232268, lng: 18.3828367 },
    { lat: 48.2230622, lng: 18.3828359 },
    { lat: 48.2228795, lng: 18.3828576 },
    { lat: 48.222765, lng: 18.3828696 },
    { lat: 48.2226232, lng: 18.3828741 },
    { lat: 48.2223759, lng: 18.3829322 },
    { lat: 48.2222547, lng: 18.3829854 },
    { lat: 48.2221391, lng: 18.3830357 },
    { lat: 48.2220097, lng: 18.3831002 },
    { lat: 48.2219363, lng: 18.3831406 },
    { lat: 48.2218291, lng: 18.3831981 },
    { lat: 48.2217557, lng: 18.3832364 },
    { lat: 48.2216265, lng: 18.3832693 },
    { lat: 48.2215559, lng: 18.3832945 },
    { lat: 48.2215336, lng: 18.3832973 },
    { lat: 48.2214042, lng: 18.3833059 },
    { lat: 48.2212568, lng: 18.3832952 },
    { lat: 48.221065, lng: 18.3832724 },
    { lat: 48.2208766, lng: 18.383235 },
    { lat: 48.2208569, lng: 18.3832424 },
    { lat: 48.2208558, lng: 18.3832429 },
    { lat: 48.2206567, lng: 18.3832932 },
    { lat: 48.220364, lng: 18.3833638 },
    { lat: 48.220307, lng: 18.383377 },
    { lat: 48.220276, lng: 18.3833841 },
    { lat: 48.2201122, lng: 18.3834222 },
    { lat: 48.2199229, lng: 18.3834659 },
    { lat: 48.2196725, lng: 18.3835273 },
    { lat: 48.2196536, lng: 18.3835318 },
    { lat: 48.2196014, lng: 18.3835451 },
    { lat: 48.2195286, lng: 18.3835749 },
    { lat: 48.2193179, lng: 18.3836623 },
    { lat: 48.2192881, lng: 18.3836746 },
    { lat: 48.2192781, lng: 18.3836783 },
    { lat: 48.2191381, lng: 18.3837353 },
    { lat: 48.2190655, lng: 18.3837685 },
    { lat: 48.2188908, lng: 18.3838493 },
    { lat: 48.2187973, lng: 18.383893 },
    { lat: 48.2186766, lng: 18.3839748 },
    { lat: 48.2185375, lng: 18.3840688 },
    { lat: 48.2185072, lng: 18.3840889 },
    { lat: 48.2182505, lng: 18.3842579 },
    { lat: 48.2181689, lng: 18.3843117 },
    { lat: 48.2179784, lng: 18.3844377 },
    { lat: 48.2179556, lng: 18.3844526 },
    { lat: 48.2178549, lng: 18.3846075 },
    { lat: 48.2177543, lng: 18.3847639 },
    { lat: 48.2177036, lng: 18.3848417 },
    { lat: 48.2176546, lng: 18.3849158 },
    { lat: 48.2175624, lng: 18.3850594 },
    { lat: 48.217555, lng: 18.3850699 },
    { lat: 48.2175385, lng: 18.3850961 },
    { lat: 48.2174536, lng: 18.3852306 },
    { lat: 48.2174174, lng: 18.3852886 },
    { lat: 48.2173675, lng: 18.3853667 },
    { lat: 48.2172733, lng: 18.3855166 },
    { lat: 48.2171604, lng: 18.3856943 },
    { lat: 48.2170764, lng: 18.3858263 },
    { lat: 48.2169096, lng: 18.3860879 },
    { lat: 48.2167461, lng: 18.3863498 },
    { lat: 48.2167389, lng: 18.3863606 },
    { lat: 48.2165658, lng: 18.3865728 },
    { lat: 48.2164382, lng: 18.3867297 },
    { lat: 48.2163759, lng: 18.3868062 },
    { lat: 48.2162442, lng: 18.3869697 },
    { lat: 48.2161925, lng: 18.3870342 },
    { lat: 48.2161639, lng: 18.3870686 },
    { lat: 48.2160734, lng: 18.3871826 },
    { lat: 48.2160428, lng: 18.3872212 },
    { lat: 48.2159754, lng: 18.3873054 },
    { lat: 48.2159226, lng: 18.3873708 },
    { lat: 48.2159089, lng: 18.3873881 },
    { lat: 48.2157519, lng: 18.3875837 },
    { lat: 48.2157404, lng: 18.3875983 },
    { lat: 48.2156329, lng: 18.3877332 },
    { lat: 48.2155424, lng: 18.3878104 },
    { lat: 48.2153154, lng: 18.3880067 },
    { lat: 48.215286, lng: 18.388028 },
    { lat: 48.215447, lng: 18.388099 },
    { lat: 48.215709, lng: 18.38821 },
    { lat: 48.216151, lng: 18.388404 },
    { lat: 48.216479, lng: 18.388545 },
    { lat: 48.216815, lng: 18.388688 },
    { lat: 48.217129, lng: 18.388826 },
    { lat: 48.217178, lng: 18.388856 },
    { lat: 48.217316, lng: 18.388974 },
    { lat: 48.217715, lng: 18.389331 },
    { lat: 48.218097, lng: 18.389667 },
    { lat: 48.218311, lng: 18.389849 },
    { lat: 48.218434, lng: 18.389967 },
    { lat: 48.218476, lng: 18.390015 },
    { lat: 48.218671, lng: 18.390235 },
    { lat: 48.218722, lng: 18.390311 },
    { lat: 48.218738, lng: 18.390353 },
    { lat: 48.218833, lng: 18.390569 },
    { lat: 48.218879, lng: 18.390703 },
    { lat: 48.21888, lng: 18.390705 },
    { lat: 48.218922, lng: 18.390844 },
    { lat: 48.218977, lng: 18.391021 },
    { lat: 48.219059, lng: 18.39125 },
    { lat: 48.219068, lng: 18.391296 },
    { lat: 48.219069, lng: 18.391299 },
    { lat: 48.219145, lng: 18.391654 },
    { lat: 48.219155, lng: 18.391699 },
    { lat: 48.219157, lng: 18.391707 },
    { lat: 48.219189, lng: 18.391821 },
    { lat: 48.219219, lng: 18.391952 },
    { lat: 48.219233, lng: 18.392007 },
    { lat: 48.219251, lng: 18.392077 },
    { lat: 48.219311, lng: 18.392225 },
    { lat: 48.219329, lng: 18.392297 },
    { lat: 48.219329, lng: 18.392298 },
    { lat: 48.219308, lng: 18.392298 },
    { lat: 48.219308, lng: 18.392299 },
    { lat: 48.219399, lng: 18.392682 },
    { lat: 48.220117, lng: 18.394288 },
    { lat: 48.220693, lng: 18.395944 },
    { lat: 48.220835, lng: 18.396095 },
    { lat: 48.221098, lng: 18.396378 },
    { lat: 48.22112, lng: 18.396402 },
    { lat: 48.22113, lng: 18.396414 },
    { lat: 48.221424, lng: 18.397059 },
    { lat: 48.221439, lng: 18.397089 },
    { lat: 48.221463, lng: 18.39714 },
    { lat: 48.221503, lng: 18.397225 },
    { lat: 48.221543, lng: 18.397366 },
    { lat: 48.221707, lng: 18.397959 },
    { lat: 48.221715, lng: 18.397976 },
    { lat: 48.221717, lng: 18.397991 },
    { lat: 48.221714, lng: 18.397994 },
    { lat: 48.221592, lng: 18.398101 },
    { lat: 48.221534, lng: 18.398139 },
    { lat: 48.221568, lng: 18.398411 },
    { lat: 48.221602, lng: 18.398707 },
    { lat: 48.221609, lng: 18.39877 },
    { lat: 48.221633, lng: 18.398872 },
    { lat: 48.221662, lng: 18.399005 },
    { lat: 48.22168, lng: 18.39908 },
    { lat: 48.221699, lng: 18.39913 },
    { lat: 48.221735, lng: 18.399237 },
    { lat: 48.221781, lng: 18.399356 },
    { lat: 48.221826, lng: 18.39947 },
    { lat: 48.221848, lng: 18.399538 },
    { lat: 48.222096, lng: 18.400197 },
    { lat: 48.222313, lng: 18.400767 },
    { lat: 48.222392, lng: 18.401052 },
    { lat: 48.22254, lng: 18.401569 },
    { lat: 48.222183, lng: 18.402008 },
    { lat: 48.222353, lng: 18.402403 },
    { lat: 48.222004, lng: 18.402758 },
    { lat: 48.221923, lng: 18.403072 },
    { lat: 48.221871, lng: 18.403013 },
    { lat: 48.221836, lng: 18.402982 },
    { lat: 48.221767, lng: 18.402912 },
    { lat: 48.221695, lng: 18.402839 },
    { lat: 48.221657, lng: 18.402797 },
    { lat: 48.221652, lng: 18.402885 },
    { lat: 48.22165, lng: 18.403 },
    { lat: 48.221651, lng: 18.403081 },
    { lat: 48.221648, lng: 18.40315 },
    { lat: 48.221637, lng: 18.403284 },
    { lat: 48.221503, lng: 18.405249 },
    { lat: 48.221422, lng: 18.406571 },
    { lat: 48.221219, lng: 18.407649 },
    { lat: 48.221285, lng: 18.408342 },
    { lat: 48.221343, lng: 18.408976 },
    { lat: 48.221349, lng: 18.409051 },
    { lat: 48.221356, lng: 18.409174 },
    { lat: 48.221365, lng: 18.409293 },
    { lat: 48.221368, lng: 18.409329 },
    { lat: 48.221301, lng: 18.411947 },
    { lat: 48.2213, lng: 18.411974 },
    { lat: 48.221511, lng: 18.412086 },
    { lat: 48.22227, lng: 18.412853 },
    { lat: 48.222613, lng: 18.413146 },
    { lat: 48.22275, lng: 18.413262 },
    { lat: 48.223105, lng: 18.413543 },
    { lat: 48.223333, lng: 18.413735 },
    { lat: 48.224852, lng: 18.416239 },
    { lat: 48.225031, lng: 18.416664 },
    { lat: 48.225645, lng: 18.417295 },
    { lat: 48.225982, lng: 18.417643 },
    { lat: 48.226277, lng: 18.417927 },
    { lat: 48.22657, lng: 18.418203 },
    { lat: 48.226663, lng: 18.418293 },
    { lat: 48.226707, lng: 18.418334 },
    { lat: 48.226831, lng: 18.418457 },
    { lat: 48.226922, lng: 18.418546 },
    { lat: 48.22705, lng: 18.418676 },
    { lat: 48.227213, lng: 18.418842 },
    { lat: 48.227498, lng: 18.419162 },
    { lat: 48.227859, lng: 18.419563 },
    { lat: 48.228087, lng: 18.419913 },
    { lat: 48.228263, lng: 18.420177 },
    { lat: 48.228379, lng: 18.42035 },
    { lat: 48.228502, lng: 18.42054 },
    { lat: 48.228659, lng: 18.420696 },
    { lat: 48.228992, lng: 18.421015 },
    { lat: 48.229307, lng: 18.421327 },
    { lat: 48.229392, lng: 18.421387 },
    { lat: 48.229516, lng: 18.421489 },
    { lat: 48.229595, lng: 18.421553 },
    { lat: 48.230004, lng: 18.421879 },
    { lat: 48.230322, lng: 18.422141 },
    { lat: 48.230556, lng: 18.422334 },
    { lat: 48.230564, lng: 18.422341 },
    { lat: 48.230822, lng: 18.422555 },
    { lat: 48.231348, lng: 18.422833 },
    { lat: 48.231544, lng: 18.422937 },
    { lat: 48.232412, lng: 18.4234 },
    { lat: 48.232774, lng: 18.423594 },
    { lat: 48.233235, lng: 18.42384 },
    { lat: 48.233452, lng: 18.423957 },
    { lat: 48.233571, lng: 18.424026 },
    { lat: 48.233788, lng: 18.424177 },
    { lat: 48.234083, lng: 18.424386 },
    { lat: 48.234325, lng: 18.424555 },
    { lat: 48.234459, lng: 18.424639 },
    { lat: 48.234526, lng: 18.4247 },
    { lat: 48.234619, lng: 18.42479 },
    { lat: 48.234841, lng: 18.425075 },
    { lat: 48.234964, lng: 18.425236 },
    { lat: 48.235091, lng: 18.425423 },
    { lat: 48.23538, lng: 18.425868 },
    { lat: 48.235464, lng: 18.425998 },
    { lat: 48.235481, lng: 18.42609 },
    { lat: 48.235515, lng: 18.426163 },
    { lat: 48.235548, lng: 18.426226 },
    { lat: 48.235645, lng: 18.426329 },
    { lat: 48.235885, lng: 18.426583 },
    { lat: 48.236102, lng: 18.426759 },
    { lat: 48.236408, lng: 18.427 },
    { lat: 48.236678, lng: 18.427209 },
    { lat: 48.23689, lng: 18.427371 },
    { lat: 48.236929, lng: 18.427403 },
    { lat: 48.237116, lng: 18.427539 },
    { lat: 48.237294, lng: 18.427693 },
    { lat: 48.237434, lng: 18.427814 },
    { lat: 48.237691, lng: 18.428147 },
    { lat: 48.237874, lng: 18.428585 },
    { lat: 48.23805, lng: 18.429052 },
    { lat: 48.23807, lng: 18.429232 },
    { lat: 48.238127, lng: 18.429739 },
    { lat: 48.238158, lng: 18.43002 },
    { lat: 48.238249, lng: 18.430865 },
    { lat: 48.238278, lng: 18.431127 },
    { lat: 48.238417, lng: 18.432374 },
    { lat: 48.238586, lng: 18.434583 },
    { lat: 48.238592, lng: 18.434649 },
    { lat: 48.2386, lng: 18.434755 },
    { lat: 48.23861, lng: 18.434873 },
    { lat: 48.238622, lng: 18.434993 },
    { lat: 48.238623, lng: 18.435009 },
    { lat: 48.238623, lng: 18.435024 },
    { lat: 48.238625, lng: 18.435047 },
    { lat: 48.238631, lng: 18.435123 },
    { lat: 48.238641, lng: 18.435251 },
    { lat: 48.238651, lng: 18.435366 },
    { lat: 48.23866, lng: 18.435491 },
    { lat: 48.238702, lng: 18.436007 },
    { lat: 48.238714, lng: 18.436164 },
    { lat: 48.238696, lng: 18.437661 },
    { lat: 48.238714, lng: 18.438024 },
    { lat: 48.238719, lng: 18.438155 },
    { lat: 48.238728, lng: 18.438268 },
    { lat: 48.238731, lng: 18.438389 },
    { lat: 48.238735, lng: 18.438502 },
    { lat: 48.23874, lng: 18.438614 },
    { lat: 48.239365, lng: 18.438646 },
    { lat: 48.239862, lng: 18.438786 },
    { lat: 48.240367, lng: 18.43863 },
    { lat: 48.241045, lng: 18.43829 },
    { lat: 48.242555, lng: 18.437354 },
    { lat: 48.244033, lng: 18.436347 },
    { lat: 48.245468, lng: 18.4355 },
    { lat: 48.246024, lng: 18.435196 },
    { lat: 48.24606, lng: 18.435176 },
    { lat: 48.246216, lng: 18.435091 },
    { lat: 48.246263, lng: 18.435101 },
    { lat: 48.246382, lng: 18.435043 },
    { lat: 48.246464, lng: 18.435004 },
    { lat: 48.246849, lng: 18.434818 },
    { lat: 48.24686, lng: 18.434813 },
    { lat: 48.246872, lng: 18.434801 },
    { lat: 48.247619, lng: 18.434066 },
    { lat: 48.24784, lng: 18.433811 },
    { lat: 48.247894, lng: 18.433737 },
    { lat: 48.248034, lng: 18.433494 },
    { lat: 48.248889, lng: 18.432284 },
    { lat: 48.248945, lng: 18.432209 },
    { lat: 48.249316, lng: 18.431705 },
    { lat: 48.24924, lng: 18.431471 },
    { lat: 48.24942, lng: 18.431325 },
    { lat: 48.2504, lng: 18.43087 },
    { lat: 48.250907, lng: 18.430724 },
    { lat: 48.250969, lng: 18.43073 },
    { lat: 48.25149, lng: 18.430289 },
    { lat: 48.251498, lng: 18.430217 },
    { lat: 48.251668, lng: 18.430249 },
    { lat: 48.251842, lng: 18.430282 },
    { lat: 48.251967, lng: 18.430306 },
    { lat: 48.252159, lng: 18.43035 },
    { lat: 48.252228, lng: 18.430366 },
    { lat: 48.252332, lng: 18.430415 },
    { lat: 48.252344, lng: 18.430367 },
    { lat: 48.252348, lng: 18.430348 },
    { lat: 48.252283, lng: 18.429018 },
    { lat: 48.252161, lng: 18.427696 },
    { lat: 48.252125, lng: 18.427171 },
    { lat: 48.252037, lng: 18.426743 },
    { lat: 48.252003, lng: 18.42655 },
    { lat: 48.25199, lng: 18.426476 },
    { lat: 48.251988, lng: 18.426467 },
    { lat: 48.251976, lng: 18.426409 },
    { lat: 48.252082, lng: 18.426274 },
    { lat: 48.252219, lng: 18.426188 },
    { lat: 48.252357, lng: 18.426216 },
    { lat: 48.25244, lng: 18.426333 },
    { lat: 48.252517, lng: 18.426366 },
    { lat: 48.252573, lng: 18.426368 },
    { lat: 48.252754, lng: 18.426369 },
    { lat: 48.252925, lng: 18.426269 },
    { lat: 48.253113, lng: 18.4264 },
    { lat: 48.25324, lng: 18.426442 },
    { lat: 48.253387, lng: 18.42659 },
    { lat: 48.253426, lng: 18.42653 },
    { lat: 48.254287, lng: 18.425233 },
    { lat: 48.254998, lng: 18.424299 },
    { lat: 48.255606, lng: 18.423633 },
    { lat: 48.256119, lng: 18.422653 },
    { lat: 48.256729, lng: 18.421589 },
    { lat: 48.257825, lng: 18.419981 },
    { lat: 48.258007, lng: 18.419712 },
    { lat: 48.258753, lng: 18.418575 },
    { lat: 48.258967, lng: 18.417634 },
    { lat: 48.259079, lng: 18.417013 },
    { lat: 48.259206, lng: 18.416365 },
    { lat: 48.259346, lng: 18.416369 },
    { lat: 48.260821, lng: 18.416416 },
    { lat: 48.26174, lng: 18.416896 },
    { lat: 48.262368, lng: 18.417258 },
    { lat: 48.263063, lng: 18.417523 },
    { lat: 48.263713, lng: 18.416567 },
    { lat: 48.264262, lng: 18.415653 },
    { lat: 48.265221, lng: 18.414993 },
    { lat: 48.265717, lng: 18.414261 },
    { lat: 48.266517, lng: 18.413588 },
    { lat: 48.266586, lng: 18.413529 },
    { lat: 48.266762, lng: 18.413311 },
    { lat: 48.266978, lng: 18.412807 },
    { lat: 48.267037, lng: 18.411442 },
    { lat: 48.267099, lng: 18.41024 },
    { lat: 48.266779, lng: 18.408001 },
    { lat: 48.266722, lng: 18.407336 },
    { lat: 48.266257, lng: 18.405646 },
    { lat: 48.26607, lng: 18.405117 },
    { lat: 48.266081, lng: 18.405088 },
    { lat: 48.266093, lng: 18.405082 },
    { lat: 48.265726, lng: 18.404117 },
    { lat: 48.265459, lng: 18.403569 },
    { lat: 48.265191, lng: 18.40302 },
    { lat: 48.265158, lng: 18.402955 },
    { lat: 48.265109, lng: 18.40286 },
    { lat: 48.265063, lng: 18.402781 },
    { lat: 48.264863, lng: 18.402439 },
    { lat: 48.264754, lng: 18.402282 },
    { lat: 48.264584, lng: 18.40207 },
    { lat: 48.264059, lng: 18.401572 },
    { lat: 48.263953, lng: 18.401574 },
    { lat: 48.263423, lng: 18.4014823 },
    { lat: 48.2607687, lng: 18.4016869 },
    { lat: 48.259558, lng: 18.4016219 },
    { lat: 48.2589854, lng: 18.401413 },
    { lat: 48.2581778, lng: 18.3992905 },
    { lat: 48.2582436, lng: 18.3978717 },
    { lat: 48.2582415, lng: 18.3957152 },
    { lat: 48.2585543, lng: 18.3936753 },
    { lat: 48.2583958, lng: 18.3924438 },
    { lat: 48.2584976, lng: 18.3918457 },
    { lat: 48.2593418, lng: 18.3904521 },
    { lat: 48.2601031, lng: 18.3884274 },
    { lat: 48.260799, lng: 18.3862398 },
    { lat: 48.2615998, lng: 18.3845775 },
    { lat: 48.2617047, lng: 18.3840561 },
    { lat: 48.2616843, lng: 18.3835531 },
    { lat: 48.2616855, lng: 18.383299 },
    { lat: 48.2601305, lng: 18.3822906 },
    { lat: 48.2594292, lng: 18.3818982 },
    { lat: 48.2588232, lng: 18.3821742 },
    { lat: 48.258426, lng: 18.3821328 },
    { lat: 48.2571384, lng: 18.3816075 },
    { lat: 48.2562384, lng: 18.3809959 },
    { lat: 48.2546048, lng: 18.3802358 },
    { lat: 48.2538079, lng: 18.3799721 },
    { lat: 48.2527741, lng: 18.3798434 },
    { lat: 48.2520589, lng: 18.3794861 },
    { lat: 48.2509165, lng: 18.3784778 },
    { lat: 48.250504, lng: 18.3777831 },
    { lat: 48.2496185, lng: 18.3756098 },
    { lat: 48.2485993, lng: 18.375443 },
    { lat: 48.2476272, lng: 18.3756904 },
    { lat: 48.2476028, lng: 18.3756977 },
  ],
  Kapince: [
    { lat: 48.45989, lng: 17.962137 },
    { lat: 48.459893, lng: 17.962043 },
    { lat: 48.459861, lng: 17.961703 },
    { lat: 48.459788, lng: 17.96124 },
    { lat: 48.459796, lng: 17.96114 },
    { lat: 48.459856, lng: 17.960898 },
    { lat: 48.459945, lng: 17.960735 },
    { lat: 48.460187, lng: 17.960292 },
    { lat: 48.460337, lng: 17.959931 },
    { lat: 48.460447, lng: 17.959733 },
    { lat: 48.460629, lng: 17.959266 },
    { lat: 48.460761, lng: 17.959037 },
    { lat: 48.460789, lng: 17.9589 },
    { lat: 48.460942, lng: 17.95846 },
    { lat: 48.460998, lng: 17.958268 },
    { lat: 48.461011, lng: 17.958193 },
    { lat: 48.461076, lng: 17.957925 },
    { lat: 48.46115, lng: 17.957787 },
    { lat: 48.461172, lng: 17.957722 },
    { lat: 48.461265, lng: 17.957494 },
    { lat: 48.461277, lng: 17.957378 },
    { lat: 48.461442, lng: 17.956942 },
    { lat: 48.461527, lng: 17.956859 },
    { lat: 48.461565, lng: 17.956738 },
    { lat: 48.461545, lng: 17.956729 },
    { lat: 48.461324, lng: 17.956566 },
    { lat: 48.46118, lng: 17.956455 },
    { lat: 48.461163, lng: 17.956451 },
    { lat: 48.461117, lng: 17.956418 },
    { lat: 48.46059, lng: 17.956085 },
    { lat: 48.460571, lng: 17.956071 },
    { lat: 48.459339, lng: 17.955211 },
    { lat: 48.45872, lng: 17.954792 },
    { lat: 48.458703, lng: 17.954922 },
    { lat: 48.458383, lng: 17.954678 },
    { lat: 48.458346, lng: 17.954649 },
    { lat: 48.457971, lng: 17.954386 },
    { lat: 48.45666, lng: 17.953464 },
    { lat: 48.455475, lng: 17.952627 },
    { lat: 48.455407, lng: 17.952595 },
    { lat: 48.455377, lng: 17.952565 },
    { lat: 48.455074, lng: 17.952295 },
    { lat: 48.454721, lng: 17.951962 },
    { lat: 48.45428, lng: 17.951511 },
    { lat: 48.453849, lng: 17.951059 },
    { lat: 48.453488, lng: 17.950676 },
    { lat: 48.453313, lng: 17.950516 },
    { lat: 48.452886, lng: 17.950079 },
    { lat: 48.452568, lng: 17.949719 },
    { lat: 48.452174, lng: 17.949312 },
    { lat: 48.451782, lng: 17.948849 },
    { lat: 48.451392, lng: 17.948286 },
    { lat: 48.451192, lng: 17.94803 },
    { lat: 48.451097, lng: 17.947909 },
    { lat: 48.450642, lng: 17.947198 },
    { lat: 48.450102, lng: 17.946394 },
    { lat: 48.449151, lng: 17.945056 },
    { lat: 48.448983, lng: 17.945225 },
    { lat: 48.448592, lng: 17.945565 },
    { lat: 48.448212, lng: 17.945887 },
    { lat: 48.447702, lng: 17.946312 },
    { lat: 48.447242, lng: 17.946696 },
    { lat: 48.446815, lng: 17.947056 },
    { lat: 48.446749, lng: 17.947006 },
    { lat: 48.446668, lng: 17.9469646 },
    { lat: 48.4466593, lng: 17.9473469 },
    { lat: 48.4467276, lng: 17.9477522 },
    { lat: 48.4468304, lng: 17.9481287 },
    { lat: 48.4470793, lng: 17.9487402 },
    { lat: 48.4480578, lng: 17.9505409 },
    { lat: 48.448368, lng: 17.9509518 },
    { lat: 48.4483632, lng: 17.951136 },
    { lat: 48.4478605, lng: 17.952653 },
    { lat: 48.4474191, lng: 17.9544785 },
    { lat: 48.4471126, lng: 17.9554992 },
    { lat: 48.4463922, lng: 17.9550429 },
    { lat: 48.4458323, lng: 17.9549879 },
    { lat: 48.4449239, lng: 17.9547488 },
    { lat: 48.4445933, lng: 17.9548647 },
    { lat: 48.4443667, lng: 17.955113 },
    { lat: 48.4436368, lng: 17.9556449 },
    { lat: 48.4433164, lng: 17.9556659 },
    { lat: 48.4431354, lng: 17.9555678 },
    { lat: 48.4431372, lng: 17.9555978 },
    { lat: 48.4432283, lng: 17.958453 },
    { lat: 48.443122, lng: 17.9593509 },
    { lat: 48.4428447, lng: 17.9602054 },
    { lat: 48.4424042, lng: 17.9619533 },
    { lat: 48.4422704, lng: 17.9629221 },
    { lat: 48.4418249, lng: 17.9645306 },
    { lat: 48.4412908, lng: 17.9659004 },
    { lat: 48.4410421, lng: 17.9663841 },
    { lat: 48.4403046, lng: 17.9674153 },
    { lat: 48.4401979, lng: 17.9685918 },
    { lat: 48.439987, lng: 17.9700864 },
    { lat: 48.4399218, lng: 17.9713323 },
    { lat: 48.4403718, lng: 17.9743073 },
    { lat: 48.4406343, lng: 17.9756401 },
    { lat: 48.4413332, lng: 17.9789314 },
    { lat: 48.4416369, lng: 17.9801979 },
    { lat: 48.4418258, lng: 17.9807501 },
    { lat: 48.4421408, lng: 17.9821104 },
    { lat: 48.4422347, lng: 17.9821147 },
    { lat: 48.4422455, lng: 17.9822014 },
    { lat: 48.4428818, lng: 17.9821387 },
    { lat: 48.4429922, lng: 17.9823438 },
    { lat: 48.445179, lng: 17.990047 },
    { lat: 48.445423, lng: 17.9906921 },
    { lat: 48.446129, lng: 17.9909101 },
    { lat: 48.4462168, lng: 17.991267 },
    { lat: 48.4464954, lng: 17.9929085 },
    { lat: 48.4467452, lng: 17.9947492 },
    { lat: 48.4465213, lng: 17.9951239 },
    { lat: 48.4464302, lng: 17.9957345 },
    { lat: 48.4464468, lng: 17.9965802 },
    { lat: 48.4463815, lng: 17.9969448 },
    { lat: 48.4465766, lng: 17.9978482 },
    { lat: 48.4465673, lng: 17.9995297 },
    { lat: 48.4464908, lng: 17.9998944 },
    { lat: 48.4462987, lng: 18.0001752 },
    { lat: 48.4463457, lng: 18.0001852 },
    { lat: 48.4467461, lng: 18.0003318 },
    { lat: 48.4470706, lng: 18.0005896 },
    { lat: 48.4473806, lng: 18.0006005 },
    { lat: 48.4477786, lng: 18.0007659 },
    { lat: 48.4482707, lng: 18.0010865 },
    { lat: 48.4486986, lng: 18.0010869 },
    { lat: 48.4490736, lng: 18.0012838 },
    { lat: 48.4496317, lng: 18.0013865 },
    { lat: 48.4504947, lng: 18.0013417 },
    { lat: 48.4514048, lng: 18.0014045 },
    { lat: 48.4517257, lng: 18.001619 },
    { lat: 48.4521268, lng: 18.0016465 },
    { lat: 48.4525032, lng: 18.0017871 },
    { lat: 48.4525206, lng: 18.0017975 },
    { lat: 48.4530479, lng: 18.0022162 },
    { lat: 48.4538951, lng: 18.0025295 },
    { lat: 48.455651, lng: 18.00372 },
    { lat: 48.455711, lng: 18.003579 },
    { lat: 48.455762, lng: 18.003438 },
    { lat: 48.455811, lng: 18.003302 },
    { lat: 48.455842, lng: 18.003231 },
    { lat: 48.455868, lng: 18.003154 },
    { lat: 48.455922, lng: 18.002983 },
    { lat: 48.455978, lng: 18.002789 },
    { lat: 48.456027, lng: 18.002589 },
    { lat: 48.456061, lng: 18.002448 },
    { lat: 48.456084, lng: 18.002353 },
    { lat: 48.456096, lng: 18.002306 },
    { lat: 48.456114, lng: 18.002171 },
    { lat: 48.456118, lng: 18.002083 },
    { lat: 48.456109, lng: 18.001948 },
    { lat: 48.456095, lng: 18.001673 },
    { lat: 48.456068, lng: 18.001221 },
    { lat: 48.456046, lng: 18.000958 },
    { lat: 48.456033, lng: 18.000717 },
    { lat: 48.456028, lng: 18.000635 },
    { lat: 48.456011, lng: 18.000401 },
    { lat: 48.456007, lng: 18.000289 },
    { lat: 48.455992, lng: 18.000031 },
    { lat: 48.455974, lng: 17.999691 },
    { lat: 48.455956, lng: 17.999339 },
    { lat: 48.455947, lng: 17.999193 },
    { lat: 48.455933, lng: 17.998882 },
    { lat: 48.455918, lng: 17.998548 },
    { lat: 48.455887, lng: 17.99802 },
    { lat: 48.455857, lng: 17.997528 },
    { lat: 48.455838, lng: 17.997235 },
    { lat: 48.455806, lng: 17.996684 },
    { lat: 48.455772, lng: 17.996178 },
    { lat: 48.455768, lng: 17.996068 },
    { lat: 48.455764, lng: 17.995904 },
    { lat: 48.455751, lng: 17.995723 },
    { lat: 48.455743, lng: 17.995553 },
    { lat: 48.455732, lng: 17.995289 },
    { lat: 48.455729, lng: 17.995172 },
    { lat: 48.45573, lng: 17.994955 },
    { lat: 48.45573, lng: 17.994779 },
    { lat: 48.455731, lng: 17.994527 },
    { lat: 48.455732, lng: 17.994257 },
    { lat: 48.45573, lng: 17.993935 },
    { lat: 48.455731, lng: 17.993676 },
    { lat: 48.455728, lng: 17.993448 },
    { lat: 48.455726, lng: 17.993184 },
    { lat: 48.455749, lng: 17.992907 },
    { lat: 48.455756, lng: 17.992713 },
    { lat: 48.455769, lng: 17.99246 },
    { lat: 48.455793, lng: 17.992095 },
    { lat: 48.455808, lng: 17.991883 },
    { lat: 48.455831, lng: 17.991477 },
    { lat: 48.455842, lng: 17.991283 },
    { lat: 48.455851, lng: 17.991059 },
    { lat: 48.455861, lng: 17.990953 },
    { lat: 48.455875, lng: 17.99077 },
    { lat: 48.455881, lng: 17.990547 },
    { lat: 48.455888, lng: 17.99023 },
    { lat: 48.455913, lng: 17.989865 },
    { lat: 48.455931, lng: 17.989517 },
    { lat: 48.455943, lng: 17.989224 },
    { lat: 48.455961, lng: 17.98904 },
    { lat: 48.455998, lng: 17.988692 },
    { lat: 48.456021, lng: 17.988386 },
    { lat: 48.456055, lng: 17.988085 },
    { lat: 48.456095, lng: 17.987678 },
    { lat: 48.456133, lng: 17.987317 },
    { lat: 48.456154, lng: 17.987111 },
    { lat: 48.456241, lng: 17.98673 },
    { lat: 48.456296, lng: 17.986457 },
    { lat: 48.456381, lng: 17.986118 },
    { lat: 48.456453, lng: 17.985761 },
    { lat: 48.456519, lng: 17.98547 },
    { lat: 48.456585, lng: 17.985184 },
    { lat: 48.456601, lng: 17.984902 },
    { lat: 48.456605, lng: 17.984731 },
    { lat: 48.456614, lng: 17.984526 },
    { lat: 48.456621, lng: 17.984367 },
    { lat: 48.456626, lng: 17.984109 },
    { lat: 48.456623, lng: 17.98371 },
    { lat: 48.456617, lng: 17.983458 },
    { lat: 48.456616, lng: 17.983317 },
    { lat: 48.456595, lng: 17.98299 },
    { lat: 48.456578, lng: 17.982616 },
    { lat: 48.456551, lng: 17.982207 },
    { lat: 48.456515, lng: 17.98191 },
    { lat: 48.45649, lng: 17.981682 },
    { lat: 48.456452, lng: 17.981292 },
    { lat: 48.456418, lng: 17.980895 },
    { lat: 48.456411, lng: 17.98079 },
    { lat: 48.456405, lng: 17.980702 },
    { lat: 48.456389, lng: 17.980533 },
    { lat: 48.45637, lng: 17.980288 },
    { lat: 48.456358, lng: 17.980124 },
    { lat: 48.456345, lng: 17.979926 },
    { lat: 48.456298, lng: 17.979759 },
    { lat: 48.456263, lng: 17.979649 },
    { lat: 48.456261, lng: 17.979409 },
    { lat: 48.456268, lng: 17.979109 },
    { lat: 48.456263, lng: 17.978811 },
    { lat: 48.456188, lng: 17.978299 },
    { lat: 48.456175, lng: 17.97827 },
    { lat: 48.456161, lng: 17.978201 },
    { lat: 48.456171, lng: 17.978001 },
    { lat: 48.456188, lng: 17.977712 },
    { lat: 48.4562, lng: 17.97746 },
    { lat: 48.456219, lng: 17.977165 },
    { lat: 48.456234, lng: 17.976889 },
    { lat: 48.456248, lng: 17.97667 },
    { lat: 48.456255, lng: 17.976494 },
    { lat: 48.456281, lng: 17.976288 },
    { lat: 48.456315, lng: 17.97598 },
    { lat: 48.456353, lng: 17.975667 },
    { lat: 48.456393, lng: 17.975389 },
    { lat: 48.456448, lng: 17.974963 },
    { lat: 48.456499, lng: 17.974702 },
    { lat: 48.456542, lng: 17.974453 },
    { lat: 48.456633, lng: 17.974037 },
    { lat: 48.456644, lng: 17.97389 },
    { lat: 48.456682, lng: 17.97367 },
    { lat: 48.456724, lng: 17.973357 },
    { lat: 48.456757, lng: 17.973026 },
    { lat: 48.456807, lng: 17.972689 },
    { lat: 48.456851, lng: 17.972311 },
    { lat: 48.456879, lng: 17.972045 },
    { lat: 48.456904, lng: 17.971821 },
    { lat: 48.456924, lng: 17.971562 },
    { lat: 48.456944, lng: 17.971408 },
    { lat: 48.45696, lng: 17.971324 },
    { lat: 48.45698, lng: 17.971189 },
    { lat: 48.456999, lng: 17.971029 },
    { lat: 48.457044, lng: 17.970645 },
    { lat: 48.457037, lng: 17.970503 },
    { lat: 48.457066, lng: 17.970226 },
    { lat: 48.457092, lng: 17.969996 },
    { lat: 48.457123, lng: 17.969777 },
    { lat: 48.457146, lng: 17.969553 },
    { lat: 48.457176, lng: 17.969299 },
    { lat: 48.457191, lng: 17.969123 },
    { lat: 48.457197, lng: 17.969058 },
    { lat: 48.457211, lng: 17.968969 },
    { lat: 48.457234, lng: 17.968769 },
    { lat: 48.457252, lng: 17.968545 },
    { lat: 48.457267, lng: 17.968292 },
    { lat: 48.457287, lng: 17.968126 },
    { lat: 48.45731, lng: 17.967879 },
    { lat: 48.457331, lng: 17.967626 },
    { lat: 48.457352, lng: 17.967325 },
    { lat: 48.457376, lng: 17.967007 },
    { lat: 48.457401, lng: 17.966695 },
    { lat: 48.45741, lng: 17.96656 },
    { lat: 48.457426, lng: 17.966248 },
    { lat: 48.457391, lng: 17.966061 },
    { lat: 48.457352, lng: 17.966058 },
    { lat: 48.457378, lng: 17.96601 },
    { lat: 48.457428, lng: 17.965942 },
    { lat: 48.457478, lng: 17.965881 },
    { lat: 48.457515, lng: 17.965827 },
    { lat: 48.45755, lng: 17.965761 },
    { lat: 48.457598, lng: 17.965664 },
    { lat: 48.457647, lng: 17.965556 },
    { lat: 48.457688, lng: 17.965472 },
    { lat: 48.457752, lng: 17.965352 },
    { lat: 48.457808, lng: 17.965232 },
    { lat: 48.457857, lng: 17.965147 },
    { lat: 48.457887, lng: 17.965093 },
    { lat: 48.45794, lng: 17.96502 },
    { lat: 48.457993, lng: 17.96493 },
    { lat: 48.458049, lng: 17.96481 },
    { lat: 48.458076, lng: 17.964762 },
    { lat: 48.458137, lng: 17.964706 },
    { lat: 48.458218, lng: 17.964637 },
    { lat: 48.458283, lng: 17.964581 },
    { lat: 48.458356, lng: 17.964513 },
    { lat: 48.458437, lng: 17.964445 },
    { lat: 48.458518, lng: 17.964376 },
    { lat: 48.45861, lng: 17.964296 },
    { lat: 48.458694, lng: 17.964227 },
    { lat: 48.458771, lng: 17.964159 },
    { lat: 48.458843, lng: 17.964056 },
    { lat: 48.458923, lng: 17.96394 },
    { lat: 48.459011, lng: 17.963825 },
    { lat: 48.459036, lng: 17.963782 },
    { lat: 48.459059, lng: 17.963723 },
    { lat: 48.459092, lng: 17.963615 },
    { lat: 48.459117, lng: 17.963532 },
    { lat: 48.459128, lng: 17.96332 },
    { lat: 48.459152, lng: 17.963178 },
    { lat: 48.45918, lng: 17.963001 },
    { lat: 48.459207, lng: 17.962794 },
    { lat: 48.459232, lng: 17.962634 },
    { lat: 48.459267, lng: 17.962451 },
    { lat: 48.459297, lng: 17.962256 },
    { lat: 48.459318, lng: 17.962114 },
    { lat: 48.459325, lng: 17.962061 },
    { lat: 48.45936, lng: 17.962088 },
    { lat: 48.459533, lng: 17.962103 },
    { lat: 48.459681, lng: 17.96212 },
    { lat: 48.459851, lng: 17.962141 },
    { lat: 48.45989, lng: 17.962137 },
  ],
  Rumanová: [
    { lat: 48.347233, lng: 17.857073 },
    { lat: 48.347117, lng: 17.8572 },
    { lat: 48.346616, lng: 17.857755 },
    { lat: 48.346317, lng: 17.858091 },
    { lat: 48.346071, lng: 17.858366 },
    { lat: 48.345466, lng: 17.859048 },
    { lat: 48.344689, lng: 17.859932 },
    { lat: 48.344073, lng: 17.860638 },
    { lat: 48.343755, lng: 17.860997 },
    { lat: 48.343737, lng: 17.860948 },
    { lat: 48.343724, lng: 17.860978 },
    { lat: 48.343613, lng: 17.860746 },
    { lat: 48.343436, lng: 17.860372 },
    { lat: 48.343291, lng: 17.860022 },
    { lat: 48.3432, lng: 17.859801 },
    { lat: 48.343123, lng: 17.859572 },
    { lat: 48.342991, lng: 17.859149 },
    { lat: 48.342926, lng: 17.858877 },
    { lat: 48.342847, lng: 17.858539 },
    { lat: 48.342727, lng: 17.858106 },
    { lat: 48.342653, lng: 17.857842 },
    { lat: 48.342611, lng: 17.857703 },
    { lat: 48.342493, lng: 17.85725 },
    { lat: 48.342393, lng: 17.856821 },
    { lat: 48.342359, lng: 17.856889 },
    { lat: 48.340967, lng: 17.857831 },
    { lat: 48.340328, lng: 17.858266 },
    { lat: 48.33996, lng: 17.858514 },
    { lat: 48.338834, lng: 17.859291 },
    { lat: 48.338027, lng: 17.859844 },
    { lat: 48.337062, lng: 17.860506 },
    { lat: 48.337039, lng: 17.860522 },
    { lat: 48.336942, lng: 17.860307 },
    { lat: 48.336679, lng: 17.859738 },
    { lat: 48.336284, lng: 17.858902 },
    { lat: 48.336056, lng: 17.858418 },
    { lat: 48.33566, lng: 17.857581 },
    { lat: 48.335283, lng: 17.856779 },
    { lat: 48.334908, lng: 17.855981 },
    { lat: 48.33471, lng: 17.855548 },
    { lat: 48.334581, lng: 17.855284 },
    { lat: 48.334377, lng: 17.854851 },
    { lat: 48.334224, lng: 17.854533 },
    { lat: 48.334066, lng: 17.854201 },
    { lat: 48.333997, lng: 17.854044 },
    { lat: 48.333828, lng: 17.853699 },
    { lat: 48.333633, lng: 17.853288 },
    { lat: 48.333491, lng: 17.85292 },
    { lat: 48.333228, lng: 17.852266 },
    { lat: 48.332806, lng: 17.851183 },
    { lat: 48.332508, lng: 17.850413 },
    { lat: 48.332321, lng: 17.849947 },
    { lat: 48.332179, lng: 17.849572 },
    { lat: 48.332082, lng: 17.849344 },
    { lat: 48.332068, lng: 17.84936 },
    { lat: 48.330918, lng: 17.849085 },
    { lat: 48.329861, lng: 17.848832 },
    { lat: 48.329126, lng: 17.848655 },
    { lat: 48.328527, lng: 17.848508 },
    { lat: 48.328026, lng: 17.848384 },
    { lat: 48.32774, lng: 17.84832 },
    { lat: 48.327758, lng: 17.848255 },
    { lat: 48.3276, lng: 17.848245 },
    { lat: 48.327467, lng: 17.848246 },
    { lat: 48.327409, lng: 17.84827 },
    { lat: 48.32725, lng: 17.848346 },
    { lat: 48.32716, lng: 17.848403 },
    { lat: 48.326985, lng: 17.84851 },
    { lat: 48.326935, lng: 17.848512 },
    { lat: 48.326902, lng: 17.8485 },
    { lat: 48.326836, lng: 17.848437 },
    { lat: 48.326742, lng: 17.848318 },
    { lat: 48.326648, lng: 17.848227 },
    { lat: 48.326579, lng: 17.848248 },
    { lat: 48.326409, lng: 17.848305 },
    { lat: 48.326327, lng: 17.848337 },
    { lat: 48.326178, lng: 17.848404 },
    { lat: 48.326096, lng: 17.848443 },
    { lat: 48.325888, lng: 17.848543 },
    { lat: 48.325754, lng: 17.84861 },
    { lat: 48.325648, lng: 17.848662 },
    { lat: 48.325529, lng: 17.848666 },
    { lat: 48.325417, lng: 17.848698 },
    { lat: 48.324997, lng: 17.84883 },
    { lat: 48.324717, lng: 17.848928 },
    { lat: 48.324525, lng: 17.848998 },
    { lat: 48.324301, lng: 17.849091 },
    { lat: 48.324109, lng: 17.849169 },
    { lat: 48.3238, lng: 17.849304 },
    { lat: 48.323652, lng: 17.849377 },
    { lat: 48.323498, lng: 17.849476 },
    { lat: 48.323122, lng: 17.849715 },
    { lat: 48.322819, lng: 17.849906 },
    { lat: 48.322453, lng: 17.850142 },
    { lat: 48.322257, lng: 17.850268 },
    { lat: 48.322034, lng: 17.850415 },
    { lat: 48.321706, lng: 17.850635 },
    { lat: 48.321323, lng: 17.850894 },
    { lat: 48.321019, lng: 17.851105 },
    { lat: 48.320774, lng: 17.851271 },
    { lat: 48.320768, lng: 17.851246 },
    { lat: 48.3196225, lng: 17.8525272 },
    { lat: 48.3186088, lng: 17.8538409 },
    { lat: 48.3180391, lng: 17.8544947 },
    { lat: 48.3173228, lng: 17.8555566 },
    { lat: 48.3176926, lng: 17.8566791 },
    { lat: 48.3183512, lng: 17.8593701 },
    { lat: 48.318679, lng: 17.8605261 },
    { lat: 48.3178807, lng: 17.8610391 },
    { lat: 48.3181745, lng: 17.8624523 },
    { lat: 48.3185005, lng: 17.8652673 },
    { lat: 48.3179595, lng: 17.8652446 },
    { lat: 48.3180021, lng: 17.8660169 },
    { lat: 48.3179253, lng: 17.8660147 },
    { lat: 48.3176814, lng: 17.8662006 },
    { lat: 48.317551, lng: 17.8664479 },
    { lat: 48.3174583, lng: 17.8664492 },
    { lat: 48.3170798, lng: 17.8667213 },
    { lat: 48.3168988, lng: 17.8667323 },
    { lat: 48.3169014, lng: 17.8667741 },
    { lat: 48.3169284, lng: 17.8670705 },
    { lat: 48.3174355, lng: 17.8686296 },
    { lat: 48.3175486, lng: 17.8711369 },
    { lat: 48.3175832, lng: 17.8735247 },
    { lat: 48.3175566, lng: 17.8751296 },
    { lat: 48.3175187, lng: 17.8753968 },
    { lat: 48.3175311, lng: 17.8765641 },
    { lat: 48.3174763, lng: 17.8773912 },
    { lat: 48.3174747, lng: 17.8784572 },
    { lat: 48.3173505, lng: 17.8793324 },
    { lat: 48.3174962, lng: 17.8804449 },
    { lat: 48.3188902, lng: 17.8830066 },
    { lat: 48.3206445, lng: 17.8850525 },
    { lat: 48.3216152, lng: 17.8858683 },
    { lat: 48.3222541, lng: 17.8864982 },
    { lat: 48.3227131, lng: 17.8867924 },
    { lat: 48.3239917, lng: 17.8882891 },
    { lat: 48.325254, lng: 17.8901727 },
    { lat: 48.32595, lng: 17.8909651 },
    { lat: 48.3268277, lng: 17.8925547 },
    { lat: 48.3270869, lng: 17.8928851 },
    { lat: 48.3273765, lng: 17.8930063 },
    { lat: 48.3281064, lng: 17.8928875 },
    { lat: 48.3283097, lng: 17.8929797 },
    { lat: 48.3296252, lng: 17.8943645 },
    { lat: 48.3299179, lng: 17.8944991 },
    { lat: 48.3309533, lng: 17.8943948 },
    { lat: 48.331309, lng: 17.8946942 },
    { lat: 48.3317278, lng: 17.8948588 },
    { lat: 48.3326463, lng: 17.8949105 },
    { lat: 48.3329156, lng: 17.8948096 },
    { lat: 48.3331219, lng: 17.89462 },
    { lat: 48.3336974, lng: 17.8944478 },
    { lat: 48.3343976, lng: 17.894546 },
    { lat: 48.3347241, lng: 17.8943631 },
    { lat: 48.3360736, lng: 17.8922883 },
    { lat: 48.3369199, lng: 17.8915934 },
    { lat: 48.3373469, lng: 17.8910044 },
    { lat: 48.3381739, lng: 17.8901787 },
    { lat: 48.3385351, lng: 17.8897114 },
    { lat: 48.3390174, lng: 17.8895317 },
    { lat: 48.339645, lng: 17.8896606 },
    { lat: 48.3398968, lng: 17.8897912 },
    { lat: 48.3416894, lng: 17.8914291 },
    { lat: 48.3423816, lng: 17.8921397 },
    { lat: 48.3431504, lng: 17.8925145 },
    { lat: 48.3436219, lng: 17.8926212 },
    { lat: 48.3439512, lng: 17.89282 },
    { lat: 48.3441412, lng: 17.8930861 },
    { lat: 48.3443333, lng: 17.8932383 },
    { lat: 48.3449135, lng: 17.8933469 },
    { lat: 48.3453255, lng: 17.8931817 },
    { lat: 48.3453304, lng: 17.8932243 },
    { lat: 48.3459695, lng: 17.8938787 },
    { lat: 48.346188, lng: 17.8935533 },
    { lat: 48.3464824, lng: 17.8930844 },
    { lat: 48.3465529, lng: 17.892776 },
    { lat: 48.3465245, lng: 17.8923072 },
    { lat: 48.3467504, lng: 17.8910085 },
    { lat: 48.3468052, lng: 17.8903676 },
    { lat: 48.3468729, lng: 17.8901433 },
    { lat: 48.3470167, lng: 17.8898334 },
    { lat: 48.3476604, lng: 17.8889654 },
    { lat: 48.3480041, lng: 17.8868141 },
    { lat: 48.3482044, lng: 17.8863219 },
    { lat: 48.3485588, lng: 17.8858164 },
    { lat: 48.3492245, lng: 17.8833935 },
    { lat: 48.3493749, lng: 17.8826912 },
    { lat: 48.3496718, lng: 17.8820608 },
    { lat: 48.3499249, lng: 17.8813395 },
    { lat: 48.3500368, lng: 17.8797623 },
    { lat: 48.3501873, lng: 17.8788023 },
    { lat: 48.3504712, lng: 17.8787372 },
    { lat: 48.3512982, lng: 17.87899 },
    { lat: 48.3513173, lng: 17.878915 },
    { lat: 48.3521638, lng: 17.8798788 },
    { lat: 48.3531165, lng: 17.8807142 },
    { lat: 48.3537421, lng: 17.8809235 },
    { lat: 48.354821, lng: 17.8810438 },
    { lat: 48.3552176, lng: 17.881088 },
    { lat: 48.3552368, lng: 17.8810158 },
    { lat: 48.3558502, lng: 17.881191 },
    { lat: 48.3565302, lng: 17.8815122 },
    { lat: 48.3572956, lng: 17.8820333 },
    { lat: 48.3581247, lng: 17.8828587 },
    { lat: 48.3590454, lng: 17.883245 },
    { lat: 48.3603396, lng: 17.8847385 },
    { lat: 48.3610547, lng: 17.8854362 },
    { lat: 48.3613509, lng: 17.8846652 },
    { lat: 48.3615028, lng: 17.8843973 },
    { lat: 48.3616883, lng: 17.8840866 },
    { lat: 48.3620854, lng: 17.8836322 },
    { lat: 48.3622137, lng: 17.8837201 },
    { lat: 48.3623758, lng: 17.8835662 },
    { lat: 48.3625684, lng: 17.8832623 },
    { lat: 48.3627446, lng: 17.8832616 },
    { lat: 48.3629784, lng: 17.8834498 },
    { lat: 48.3636344, lng: 17.8833767 },
    { lat: 48.3641854, lng: 17.8824863 },
    { lat: 48.3645524, lng: 17.8817088 },
    { lat: 48.3650426, lng: 17.8810432 },
    { lat: 48.365379, lng: 17.8803926 },
    { lat: 48.3657892, lng: 17.8788398 },
    { lat: 48.3659953, lng: 17.8777697 },
    { lat: 48.36629, lng: 17.877017 },
    { lat: 48.36625, lng: 17.876515 },
    { lat: 48.366219, lng: 17.876383 },
    { lat: 48.366022, lng: 17.875852 },
    { lat: 48.365806, lng: 17.875361 },
    { lat: 48.36571, lng: 17.875036 },
    { lat: 48.365595, lng: 17.874679 },
    { lat: 48.365587, lng: 17.874533 },
    { lat: 48.3656, lng: 17.874428 },
    { lat: 48.365546, lng: 17.874449 },
    { lat: 48.365476, lng: 17.874356 },
    { lat: 48.365345, lng: 17.874089 },
    { lat: 48.365142, lng: 17.873755 },
    { lat: 48.364961, lng: 17.873484 },
    { lat: 48.364822, lng: 17.873121 },
    { lat: 48.364695, lng: 17.872774 },
    { lat: 48.36459, lng: 17.872586 },
    { lat: 48.364521, lng: 17.872275 },
    { lat: 48.3645918, lng: 17.8722254 },
    { lat: 48.364466, lng: 17.871987 },
    { lat: 48.364269, lng: 17.871545 },
    { lat: 48.364165, lng: 17.871326 },
    { lat: 48.364075, lng: 17.871145 },
    { lat: 48.363932, lng: 17.870913 },
    { lat: 48.363859, lng: 17.870765 },
    { lat: 48.363789, lng: 17.870393 },
    { lat: 48.363769, lng: 17.870268 },
    { lat: 48.363734, lng: 17.870101 },
    { lat: 48.363651, lng: 17.869744 },
    { lat: 48.363637, lng: 17.869639 },
    { lat: 48.363578, lng: 17.869387 },
    { lat: 48.363489, lng: 17.869304 },
    { lat: 48.363521, lng: 17.86924 },
    { lat: 48.363265, lng: 17.869305 },
    { lat: 48.362956, lng: 17.86933 },
    { lat: 48.362632, lng: 17.869415 },
    { lat: 48.362279, lng: 17.869598 },
    { lat: 48.36184, lng: 17.869892 },
    { lat: 48.361446, lng: 17.870156 },
    { lat: 48.361183, lng: 17.870329 },
    { lat: 48.360841, lng: 17.870436 },
    { lat: 48.360415, lng: 17.870604 },
    { lat: 48.360112, lng: 17.870726 },
    { lat: 48.35978, lng: 17.870743 },
    { lat: 48.359545, lng: 17.87077 },
    { lat: 48.359089, lng: 17.87083 },
    { lat: 48.358855, lng: 17.870838 },
    { lat: 48.358566, lng: 17.870922 },
    { lat: 48.358261, lng: 17.870997 },
    { lat: 48.358073, lng: 17.871004 },
    { lat: 48.357886, lng: 17.870924 },
    { lat: 48.35773, lng: 17.870784 },
    { lat: 48.357498, lng: 17.870484 },
    { lat: 48.357472, lng: 17.870508 },
    { lat: 48.357386, lng: 17.870393 },
    { lat: 48.357305, lng: 17.870257 },
    { lat: 48.357183, lng: 17.869927 },
    { lat: 48.357149, lng: 17.869723 },
    { lat: 48.35711, lng: 17.869544 },
    { lat: 48.357025, lng: 17.869158 },
    { lat: 48.356927, lng: 17.868708 },
    { lat: 48.356806, lng: 17.868233 },
    { lat: 48.35673, lng: 17.868002 },
    { lat: 48.356651, lng: 17.867685 },
    { lat: 48.35659, lng: 17.867213 },
    { lat: 48.356549, lng: 17.866902 },
    { lat: 48.35641, lng: 17.866403 },
    { lat: 48.3563, lng: 17.865998 },
    { lat: 48.356062, lng: 17.86511 },
    { lat: 48.356035, lng: 17.864982 },
    { lat: 48.355805, lng: 17.864365 },
    { lat: 48.355629, lng: 17.863893 },
    { lat: 48.355493, lng: 17.863517 },
    { lat: 48.355346, lng: 17.863166 },
    { lat: 48.35517, lng: 17.862776 },
    { lat: 48.354986, lng: 17.862356 },
    { lat: 48.354823, lng: 17.861966 },
    { lat: 48.354805, lng: 17.861968 },
    { lat: 48.354799, lng: 17.86194 },
    { lat: 48.354599, lng: 17.861713 },
    { lat: 48.354325, lng: 17.861367 },
    { lat: 48.354339, lng: 17.861355 },
    { lat: 48.354163, lng: 17.861082 },
    { lat: 48.353991, lng: 17.860875 },
    { lat: 48.353726, lng: 17.860529 },
    { lat: 48.353397, lng: 17.860096 },
    { lat: 48.35326, lng: 17.859935 },
    { lat: 48.353289, lng: 17.859895 },
    { lat: 48.352996, lng: 17.859609 },
    { lat: 48.352706, lng: 17.859334 },
    { lat: 48.352692, lng: 17.858826 },
    { lat: 48.352628, lng: 17.858644 },
    { lat: 48.352382, lng: 17.858233 },
    { lat: 48.351972, lng: 17.857623 },
    { lat: 48.351774, lng: 17.857441 },
    { lat: 48.351482, lng: 17.857265 },
    { lat: 48.351315, lng: 17.856901 },
    { lat: 48.350987, lng: 17.856339 },
    { lat: 48.35076, lng: 17.855807 },
    { lat: 48.35058, lng: 17.855516 },
    { lat: 48.35043, lng: 17.855323 },
    { lat: 48.350326, lng: 17.855302 },
    { lat: 48.350189, lng: 17.855307 },
    { lat: 48.349932, lng: 17.855454 },
    { lat: 48.349785, lng: 17.855621 },
    { lat: 48.349633, lng: 17.855864 },
    { lat: 48.349297, lng: 17.856563 },
    { lat: 48.349008, lng: 17.857086 },
    { lat: 48.34893, lng: 17.857233 },
    { lat: 48.348588, lng: 17.857751 },
    { lat: 48.348204, lng: 17.858337 },
    { lat: 48.348004, lng: 17.858063 },
    { lat: 48.347727, lng: 17.857743 },
    { lat: 48.347488, lng: 17.857421 },
    { lat: 48.347262, lng: 17.857096 },
    { lat: 48.347233, lng: 17.857073 },
  ],
  Jelenec: [
    { lat: 48.401077, lng: 18.24453 },
    { lat: 48.401519, lng: 18.242178 },
    { lat: 48.401528, lng: 18.242128 },
    { lat: 48.402186, lng: 18.238597 },
    { lat: 48.4022, lng: 18.238562 },
    { lat: 48.402203, lng: 18.238555 },
    { lat: 48.404541, lng: 18.232725 },
    { lat: 48.404577, lng: 18.232636 },
    { lat: 48.404675, lng: 18.232391 },
    { lat: 48.404839, lng: 18.231981 },
    { lat: 48.404875, lng: 18.231806 },
    { lat: 48.405708, lng: 18.227728 },
    { lat: 48.406168, lng: 18.225436 },
    { lat: 48.406173, lng: 18.225411 },
    { lat: 48.406195, lng: 18.225418 },
    { lat: 48.411746, lng: 18.227048 },
    { lat: 48.412606, lng: 18.227294 },
    { lat: 48.416319, lng: 18.21566 },
    { lat: 48.416338, lng: 18.215602 },
    { lat: 48.416359, lng: 18.215541 },
    { lat: 48.416363, lng: 18.215528 },
    { lat: 48.416376, lng: 18.215514 },
    { lat: 48.418912, lng: 18.212407 },
    { lat: 48.418963, lng: 18.212346 },
    { lat: 48.421375, lng: 18.209391 },
    { lat: 48.421431, lng: 18.209324 },
    { lat: 48.423811, lng: 18.206409 },
    { lat: 48.423819, lng: 18.206399 },
    { lat: 48.423858, lng: 18.206366 },
    { lat: 48.423864, lng: 18.206359 },
    { lat: 48.425368, lng: 18.204791 },
    { lat: 48.426735, lng: 18.20219 },
    { lat: 48.426888, lng: 18.201969 },
    { lat: 48.427145, lng: 18.201608 },
    { lat: 48.427197, lng: 18.201535 },
    { lat: 48.427569, lng: 18.201012 },
    { lat: 48.427696, lng: 18.200822 },
    { lat: 48.428443, lng: 18.199722 },
    { lat: 48.429022, lng: 18.19887 },
    { lat: 48.429046, lng: 18.198831 },
    { lat: 48.429332, lng: 18.198392 },
    { lat: 48.429377, lng: 18.198324 },
    { lat: 48.429524, lng: 18.198099 },
    { lat: 48.429623, lng: 18.197948 },
    { lat: 48.429794, lng: 18.197689 },
    { lat: 48.429916, lng: 18.197496 },
    { lat: 48.430891, lng: 18.195999 },
    { lat: 48.431043, lng: 18.195557 },
    { lat: 48.431498, lng: 18.194244 },
    { lat: 48.4309333, lng: 18.1941362 },
    { lat: 48.4303909, lng: 18.1935363 },
    { lat: 48.430229, lng: 18.1932355 },
    { lat: 48.4300268, lng: 18.1930971 },
    { lat: 48.4299555, lng: 18.1928776 },
    { lat: 48.429906, lng: 18.1923173 },
    { lat: 48.4297938, lng: 18.191976 },
    { lat: 48.4296168, lng: 18.1918236 },
    { lat: 48.4294825, lng: 18.191607 },
    { lat: 48.4290441, lng: 18.1913044 },
    { lat: 48.4285315, lng: 18.1905787 },
    { lat: 48.4281418, lng: 18.1897176 },
    { lat: 48.4272955, lng: 18.1886654 },
    { lat: 48.4267584, lng: 18.188337 },
    { lat: 48.4264631, lng: 18.1879912 },
    { lat: 48.4258531, lng: 18.1874749 },
    { lat: 48.4255872, lng: 18.1876969 },
    { lat: 48.4252398, lng: 18.1877772 },
    { lat: 48.4245985, lng: 18.1878096 },
    { lat: 48.4242579, lng: 18.1876583 },
    { lat: 48.423173, lng: 18.1870453 },
    { lat: 48.4228377, lng: 18.1866337 },
    { lat: 48.4225427, lng: 18.1864691 },
    { lat: 48.4217884, lng: 18.1857223 },
    { lat: 48.4215875, lng: 18.1851944 },
    { lat: 48.4215329, lng: 18.1844278 },
    { lat: 48.4210988, lng: 18.1835602 },
    { lat: 48.420436, lng: 18.1829112 },
    { lat: 48.4203333, lng: 18.18287 },
    { lat: 48.4199949, lng: 18.1829097 },
    { lat: 48.4194283, lng: 18.1825394 },
    { lat: 48.419188, lng: 18.1819179 },
    { lat: 48.418307, lng: 18.1808365 },
    { lat: 48.4181347, lng: 18.180512 },
    { lat: 48.4176097, lng: 18.1791658 },
    { lat: 48.4169157, lng: 18.1788326 },
    { lat: 48.4166283, lng: 18.1789157 },
    { lat: 48.416256, lng: 18.1788206 },
    { lat: 48.4152772, lng: 18.1789498 },
    { lat: 48.4147804, lng: 18.1788124 },
    { lat: 48.413822, lng: 18.1780869 },
    { lat: 48.4134326, lng: 18.1776356 },
    { lat: 48.4131958, lng: 18.1774471 },
    { lat: 48.4127541, lng: 18.1771867 },
    { lat: 48.4125001, lng: 18.1771447 },
    { lat: 48.4120303, lng: 18.1766124 },
    { lat: 48.4117938, lng: 18.1758434 },
    { lat: 48.4117352, lng: 18.1747564 },
    { lat: 48.4116949, lng: 18.1746441 },
    { lat: 48.4112635, lng: 18.1738251 },
    { lat: 48.4110121, lng: 18.1736339 },
    { lat: 48.4105407, lng: 18.1730976 },
    { lat: 48.4097168, lng: 18.1726653 },
    { lat: 48.4092859, lng: 18.1725915 },
    { lat: 48.4086819, lng: 18.1726838 },
    { lat: 48.4082923, lng: 18.1726006 },
    { lat: 48.4078528, lng: 18.1726518 },
    { lat: 48.407564, lng: 18.1729436 },
    { lat: 48.4073087, lng: 18.1729405 },
    { lat: 48.4068673, lng: 18.1727835 },
    { lat: 48.4065621, lng: 18.1725773 },
    { lat: 48.406325, lng: 18.1722444 },
    { lat: 48.4045985, lng: 18.1704783 },
    { lat: 48.4044394, lng: 18.1701703 },
    { lat: 48.404099, lng: 18.1697689 },
    { lat: 48.4037365, lng: 18.1687734 },
    { lat: 48.4036783, lng: 18.1684347 },
    { lat: 48.403417, lng: 18.1680929 },
    { lat: 48.4033198, lng: 18.1678567 },
    { lat: 48.4031613, lng: 18.1677497 },
    { lat: 48.4028506, lng: 18.1672588 },
    { lat: 48.4027461, lng: 18.1669386 },
    { lat: 48.4025744, lng: 18.166779 },
    { lat: 48.4017674, lng: 18.1663688 },
    { lat: 48.4016136, lng: 18.1661529 },
    { lat: 48.4012514, lng: 18.1661329 },
    { lat: 48.4008751, lng: 18.1667824 },
    { lat: 48.3996834, lng: 18.167927 },
    { lat: 48.3990007, lng: 18.1684722 },
    { lat: 48.3989806, lng: 18.1684926 },
    { lat: 48.3989868, lng: 18.1685617 },
    { lat: 48.3989635, lng: 18.1695974 },
    { lat: 48.3986301, lng: 18.1711425 },
    { lat: 48.3985233, lng: 18.1728159 },
    { lat: 48.3983182, lng: 18.1738531 },
    { lat: 48.3982128, lng: 18.175864 },
    { lat: 48.3980371, lng: 18.1771341 },
    { lat: 48.3980728, lng: 18.1777502 },
    { lat: 48.3976676, lng: 18.1789697 },
    { lat: 48.3973839, lng: 18.1801883 },
    { lat: 48.3972568, lng: 18.1812457 },
    { lat: 48.3971229, lng: 18.1819123 },
    { lat: 48.3969088, lng: 18.1825578 },
    { lat: 48.396543, lng: 18.1833116 },
    { lat: 48.3959617, lng: 18.1843009 },
    { lat: 48.3955569, lng: 18.1851656 },
    { lat: 48.395421, lng: 18.1856599 },
    { lat: 48.3948472, lng: 18.1865669 },
    { lat: 48.3937451, lng: 18.1893168 },
    { lat: 48.3921553, lng: 18.1922979 },
    { lat: 48.3918997, lng: 18.1926772 },
    { lat: 48.3914336, lng: 18.1938657 },
    { lat: 48.390113, lng: 18.1950092 },
    { lat: 48.3897607, lng: 18.1951599 },
    { lat: 48.3891733, lng: 18.1955973 },
    { lat: 48.3886726, lng: 18.1955658 },
    { lat: 48.3883304, lng: 18.1956964 },
    { lat: 48.3877425, lng: 18.1956784 },
    { lat: 48.3872378, lng: 18.195964 },
    { lat: 48.3865283, lng: 18.1960478 },
    { lat: 48.3853096, lng: 18.1963571 },
    { lat: 48.3842516, lng: 18.1970298 },
    { lat: 48.3836005, lng: 18.1974978 },
    { lat: 48.382484, lng: 18.1986176 },
    { lat: 48.3822572, lng: 18.1990508 },
    { lat: 48.3818315, lng: 18.1996293 },
    { lat: 48.3817524, lng: 18.19966 },
    { lat: 48.3816909, lng: 18.1999295 },
    { lat: 48.381489, lng: 18.2003992 },
    { lat: 48.3813959, lng: 18.2009928 },
    { lat: 48.3812676, lng: 18.2012924 },
    { lat: 48.3792599, lng: 18.2026905 },
    { lat: 48.3785163, lng: 18.2030228 },
    { lat: 48.377679, lng: 18.2035881 },
    { lat: 48.3773929, lng: 18.2037138 },
    { lat: 48.3766152, lng: 18.2044502 },
    { lat: 48.376041, lng: 18.2056058 },
    { lat: 48.3759817, lng: 18.2057701 },
    { lat: 48.3759369, lng: 18.2062047 },
    { lat: 48.3757162, lng: 18.2067183 },
    { lat: 48.3749766, lng: 18.2081346 },
    { lat: 48.3742055, lng: 18.2093403 },
    { lat: 48.3728805, lng: 18.2109741 },
    { lat: 48.3722332, lng: 18.210417 },
    { lat: 48.3715911, lng: 18.2123295 },
    { lat: 48.3712028, lng: 18.2132236 },
    { lat: 48.37098, lng: 18.2128835 },
    { lat: 48.3704872, lng: 18.213984 },
    { lat: 48.3701883, lng: 18.2144534 },
    { lat: 48.369939, lng: 18.214121 },
    { lat: 48.3692682, lng: 18.215109 },
    { lat: 48.3689086, lng: 18.2154287 },
    { lat: 48.3682291, lng: 18.2157936 },
    { lat: 48.3676283, lng: 18.2163345 },
    { lat: 48.3661598, lng: 18.2182211 },
    { lat: 48.3660996, lng: 18.218313 },
    { lat: 48.366121, lng: 18.2190139 },
    { lat: 48.3660008, lng: 18.2198074 },
    { lat: 48.3658479, lng: 18.2204596 },
    { lat: 48.3656122, lng: 18.2210715 },
    { lat: 48.3652253, lng: 18.2215413 },
    { lat: 48.364597, lng: 18.2221094 },
    { lat: 48.3623125, lng: 18.2238988 },
    { lat: 48.3607788, lng: 18.2253822 },
    { lat: 48.36025, lng: 18.2260935 },
    { lat: 48.3597384, lng: 18.2271037 },
    { lat: 48.360405, lng: 18.228468 },
    { lat: 48.3645026, lng: 18.237402 },
    { lat: 48.3613054, lng: 18.2469823 },
    { lat: 48.3604717, lng: 18.2482401 },
    { lat: 48.3605899, lng: 18.2484736 },
    { lat: 48.355605, lng: 18.2546242 },
    { lat: 48.3565308, lng: 18.2560048 },
    { lat: 48.3565271, lng: 18.2560599 },
    { lat: 48.352399, lng: 18.260106 },
    { lat: 48.351451, lng: 18.262018 },
    { lat: 48.351259, lng: 18.262418 },
    { lat: 48.350603, lng: 18.263726 },
    { lat: 48.349571, lng: 18.265783 },
    { lat: 48.3496, lng: 18.265822 },
    { lat: 48.349986, lng: 18.26611 },
    { lat: 48.350298, lng: 18.266328 },
    { lat: 48.35034, lng: 18.266358 },
    { lat: 48.35081, lng: 18.266609 },
    { lat: 48.351174, lng: 18.266855 },
    { lat: 48.351464, lng: 18.26705 },
    { lat: 48.351499, lng: 18.267073 },
    { lat: 48.351833, lng: 18.267298 },
    { lat: 48.352652, lng: 18.267951 },
    { lat: 48.352666, lng: 18.267963 },
    { lat: 48.352729, lng: 18.267898 },
    { lat: 48.352808, lng: 18.267818 },
    { lat: 48.353053, lng: 18.267536 },
    { lat: 48.353339, lng: 18.267233 },
    { lat: 48.353481, lng: 18.267081 },
    { lat: 48.353616, lng: 18.266935 },
    { lat: 48.35369, lng: 18.266855 },
    { lat: 48.353762, lng: 18.266787 },
    { lat: 48.353799, lng: 18.266753 },
    { lat: 48.353836, lng: 18.266732 },
    { lat: 48.353946, lng: 18.266671 },
    { lat: 48.354002, lng: 18.266641 },
    { lat: 48.35402, lng: 18.266626 },
    { lat: 48.354089, lng: 18.266571 },
    { lat: 48.354228, lng: 18.266461 },
    { lat: 48.354371, lng: 18.266294 },
    { lat: 48.354513, lng: 18.266131 },
    { lat: 48.354618, lng: 18.266011 },
    { lat: 48.354847, lng: 18.265764 },
    { lat: 48.354932, lng: 18.265671 },
    { lat: 48.355071, lng: 18.26552 },
    { lat: 48.355198, lng: 18.26538 },
    { lat: 48.355288, lng: 18.26528 },
    { lat: 48.355331, lng: 18.265224 },
    { lat: 48.355443, lng: 18.265076 },
    { lat: 48.355558, lng: 18.264926 },
    { lat: 48.355666, lng: 18.264784 },
    { lat: 48.355788, lng: 18.264624 },
    { lat: 48.355825, lng: 18.264584 },
    { lat: 48.355941, lng: 18.264456 },
    { lat: 48.356092, lng: 18.264291 },
    { lat: 48.356385, lng: 18.263972 },
    { lat: 48.356545, lng: 18.2638 },
    { lat: 48.356578, lng: 18.263765 },
    { lat: 48.356801, lng: 18.26352 },
    { lat: 48.35722, lng: 18.263163 },
    { lat: 48.357329, lng: 18.263057 },
    { lat: 48.357903, lng: 18.262496 },
    { lat: 48.358096, lng: 18.262312 },
    { lat: 48.358479, lng: 18.261939 },
    { lat: 48.358628, lng: 18.261782 },
    { lat: 48.358875, lng: 18.261524 },
    { lat: 48.359127, lng: 18.26126 },
    { lat: 48.359608, lng: 18.26076 },
    { lat: 48.360222, lng: 18.260117 },
    { lat: 48.360226, lng: 18.260113 },
    { lat: 48.36029, lng: 18.260045 },
    { lat: 48.360433, lng: 18.259891 },
    { lat: 48.360824, lng: 18.259571 },
    { lat: 48.36109, lng: 18.259423 },
    { lat: 48.361541, lng: 18.259298 },
    { lat: 48.361593, lng: 18.259284 },
    { lat: 48.361902, lng: 18.259198 },
    { lat: 48.362125, lng: 18.259148 },
    { lat: 48.362668, lng: 18.259381 },
    { lat: 48.362961, lng: 18.259487 },
    { lat: 48.36308, lng: 18.259524 },
    { lat: 48.363571, lng: 18.259658 },
    { lat: 48.363792, lng: 18.259737 },
    { lat: 48.364173, lng: 18.259874 },
    { lat: 48.364365, lng: 18.259912 },
    { lat: 48.364422, lng: 18.259911 },
    { lat: 48.364775, lng: 18.259906 },
    { lat: 48.365002, lng: 18.260037 },
    { lat: 48.365153, lng: 18.26014 },
    { lat: 48.36532, lng: 18.260333 },
    { lat: 48.365337, lng: 18.260352 },
    { lat: 48.365383, lng: 18.26042 },
    { lat: 48.365391, lng: 18.260427 },
    { lat: 48.365466, lng: 18.260534 },
    { lat: 48.365486, lng: 18.260562 },
    { lat: 48.365488, lng: 18.260565 },
    { lat: 48.365521, lng: 18.260608 },
    { lat: 48.365686, lng: 18.260832 },
    { lat: 48.365751, lng: 18.260982 },
    { lat: 48.365816, lng: 18.261134 },
    { lat: 48.366017, lng: 18.261507 },
    { lat: 48.366047, lng: 18.261564 },
    { lat: 48.366147, lng: 18.261609 },
    { lat: 48.366412, lng: 18.261707 },
    { lat: 48.36642, lng: 18.26171 },
    { lat: 48.36648, lng: 18.261732 },
    { lat: 48.366514, lng: 18.261753 },
    { lat: 48.366516, lng: 18.261755 },
    { lat: 48.366883, lng: 18.261956 },
    { lat: 48.367034, lng: 18.262025 },
    { lat: 48.367309, lng: 18.262156 },
    { lat: 48.367573, lng: 18.262281 },
    { lat: 48.368008, lng: 18.262493 },
    { lat: 48.368661, lng: 18.262854 },
    { lat: 48.369503, lng: 18.263246 },
    { lat: 48.369534, lng: 18.26326 },
    { lat: 48.36955, lng: 18.263268 },
    { lat: 48.369556, lng: 18.263271 },
    { lat: 48.36963, lng: 18.263306 },
    { lat: 48.369652, lng: 18.263316 },
    { lat: 48.369656, lng: 18.263316 },
    { lat: 48.369673, lng: 18.263322 },
    { lat: 48.370278, lng: 18.263492 },
    { lat: 48.370519, lng: 18.263548 },
    { lat: 48.370856, lng: 18.263665 },
    { lat: 48.370915, lng: 18.263693 },
    { lat: 48.371087, lng: 18.263771 },
    { lat: 48.371342, lng: 18.2639 },
    { lat: 48.372055, lng: 18.264346 },
    { lat: 48.372656, lng: 18.264733 },
    { lat: 48.372964, lng: 18.265018 },
    { lat: 48.37305, lng: 18.265083 },
    { lat: 48.373081, lng: 18.265108 },
    { lat: 48.373346, lng: 18.265312 },
    { lat: 48.373403, lng: 18.265258 },
    { lat: 48.373409, lng: 18.265252 },
    { lat: 48.373417, lng: 18.265244 },
    { lat: 48.374639, lng: 18.263971 },
    { lat: 48.374872, lng: 18.263728 },
    { lat: 48.374884, lng: 18.263715 },
    { lat: 48.374965, lng: 18.263631 },
    { lat: 48.37503, lng: 18.263564 },
    { lat: 48.375579, lng: 18.26299 },
    { lat: 48.375602, lng: 18.262966 },
    { lat: 48.375609, lng: 18.262959 },
    { lat: 48.375784, lng: 18.262774 },
    { lat: 48.375918, lng: 18.262632 },
    { lat: 48.376635, lng: 18.261865 },
    { lat: 48.377658, lng: 18.260753 },
    { lat: 48.37859, lng: 18.259736 },
    { lat: 48.379964, lng: 18.258249 },
    { lat: 48.3803, lng: 18.257647 },
    { lat: 48.381378, lng: 18.25574 },
    { lat: 48.381544, lng: 18.25549 },
    { lat: 48.382064, lng: 18.25471 },
    { lat: 48.382447, lng: 18.254135 },
    { lat: 48.382718, lng: 18.253732 },
    { lat: 48.382935, lng: 18.253399 },
    { lat: 48.382941, lng: 18.253405 },
    { lat: 48.382995, lng: 18.253324 },
    { lat: 48.382991, lng: 18.253318 },
    { lat: 48.383369, lng: 18.252755 },
    { lat: 48.383877, lng: 18.251991 },
    { lat: 48.384271, lng: 18.251396 },
    { lat: 48.384642, lng: 18.250842 },
    { lat: 48.385148, lng: 18.250086 },
    { lat: 48.385494, lng: 18.249568 },
    { lat: 48.385929, lng: 18.248924 },
    { lat: 48.386298, lng: 18.248369 },
    { lat: 48.387011, lng: 18.247306 },
    { lat: 48.387283, lng: 18.246922 },
    { lat: 48.387288, lng: 18.246916 },
    { lat: 48.387374, lng: 18.246825 },
    { lat: 48.387378, lng: 18.246821 },
    { lat: 48.388852, lng: 18.245268 },
    { lat: 48.38952, lng: 18.244556 },
    { lat: 48.39002, lng: 18.244022 },
    { lat: 48.390265, lng: 18.243851 },
    { lat: 48.391514, lng: 18.242978 },
    { lat: 48.391554, lng: 18.24295 },
    { lat: 48.391567, lng: 18.242941 },
    { lat: 48.391693, lng: 18.242852 },
    { lat: 48.391834, lng: 18.242753 },
    { lat: 48.39188, lng: 18.242721 },
    { lat: 48.391944, lng: 18.242676 },
    { lat: 48.393007, lng: 18.241927 },
    { lat: 48.393762, lng: 18.241567 },
    { lat: 48.39382, lng: 18.241553 },
    { lat: 48.393942, lng: 18.241525 },
    { lat: 48.394043, lng: 18.241498 },
    { lat: 48.395539, lng: 18.24113 },
    { lat: 48.395747, lng: 18.241061 },
    { lat: 48.396895, lng: 18.240683 },
    { lat: 48.398825, lng: 18.241782 },
    { lat: 48.399608, lng: 18.242231 },
    { lat: 48.399823, lng: 18.242354 },
    { lat: 48.400798, lng: 18.243559 },
    { lat: 48.400987, lng: 18.244196 },
    { lat: 48.401077, lng: 18.24453 },
  ],
  Čakajovce: [
    { lat: 48.392137, lng: 18.0274952 },
    { lat: 48.3920843, lng: 18.0275196 },
    { lat: 48.3920067, lng: 18.0275037 },
    { lat: 48.3918478, lng: 18.0273222 },
    { lat: 48.3915734, lng: 18.0275838 },
    { lat: 48.3906712, lng: 18.0279386 },
    { lat: 48.3894898, lng: 18.0279218 },
    { lat: 48.3887606, lng: 18.0277897 },
    { lat: 48.3879786, lng: 18.0274861 },
    { lat: 48.3878992, lng: 18.027387 },
    { lat: 48.3874547, lng: 18.0271628 },
    { lat: 48.3870361, lng: 18.0268964 },
    { lat: 48.386392, lng: 18.0262942 },
    { lat: 48.3859035, lng: 18.0260138 },
    { lat: 48.3850121, lng: 18.025656 },
    { lat: 48.3848177, lng: 18.0256522 },
    { lat: 48.3847081, lng: 18.0255738 },
    { lat: 48.3841971, lng: 18.025464 },
    { lat: 48.3839325, lng: 18.0252112 },
    { lat: 48.3831711, lng: 18.0248956 },
    { lat: 48.3829266, lng: 18.024694 },
    { lat: 48.382092, lng: 18.0245154 },
    { lat: 48.3814747, lng: 18.0245147 },
    { lat: 48.3811208, lng: 18.0246171 },
    { lat: 48.3807948, lng: 18.0245834 },
    { lat: 48.3804766, lng: 18.0244513 },
    { lat: 48.3803831, lng: 18.0243421 },
    { lat: 48.3800883, lng: 18.0243198 },
    { lat: 48.3799042, lng: 18.0241077 },
    { lat: 48.3796067, lng: 18.0241912 },
    { lat: 48.3791627, lng: 18.0241473 },
    { lat: 48.3789906, lng: 18.0237749 },
    { lat: 48.3789697, lng: 18.0235583 },
    { lat: 48.3786622, lng: 18.0232065 },
    { lat: 48.3784229, lng: 18.0231679 },
    { lat: 48.378242, lng: 18.0230295 },
    { lat: 48.3778349, lng: 18.0222572 },
    { lat: 48.3775867, lng: 18.0221471 },
    { lat: 48.3773211, lng: 18.0222554 },
    { lat: 48.3769435, lng: 18.0223011 },
    { lat: 48.3767734, lng: 18.0221531 },
    { lat: 48.376569, lng: 18.0221152 },
    { lat: 48.3760303, lng: 18.0218622 },
    { lat: 48.3758396, lng: 18.0215948 },
    { lat: 48.3755096, lng: 18.0215499 },
    { lat: 48.3754004, lng: 18.0213477 },
    { lat: 48.374886, lng: 18.0212565 },
    { lat: 48.3748822, lng: 18.0213248 },
    { lat: 48.3749905, lng: 18.0213834 },
    { lat: 48.3744436, lng: 18.025492 },
    { lat: 48.3739798, lng: 18.0254164 },
    { lat: 48.3738703, lng: 18.0262919 },
    { lat: 48.3727419, lng: 18.0262549 },
    { lat: 48.3722785, lng: 18.0263013 },
    { lat: 48.3715656, lng: 18.0273903 },
    { lat: 48.3712485, lng: 18.0279819 },
    { lat: 48.3703493, lng: 18.0270946 },
    { lat: 48.3692817, lng: 18.0283113 },
    { lat: 48.3667472, lng: 18.0291183 },
    { lat: 48.3651156, lng: 18.0305686 },
    { lat: 48.3642854, lng: 18.0315283 },
    { lat: 48.3626781, lng: 18.0336741 },
    { lat: 48.3607033, lng: 18.0352357 },
    { lat: 48.3597163, lng: 18.0358368 },
    { lat: 48.3594047, lng: 18.037776 },
    { lat: 48.3592146, lng: 18.0396294 },
    { lat: 48.3591022, lng: 18.041503 },
    { lat: 48.3589392, lng: 18.0427892 },
    { lat: 48.3587034, lng: 18.0438935 },
    { lat: 48.3588237, lng: 18.0440956 },
    { lat: 48.359287, lng: 18.0442964 },
    { lat: 48.3594076, lng: 18.0446348 },
    { lat: 48.3594892, lng: 18.0452893 },
    { lat: 48.3591346, lng: 18.0458346 },
    { lat: 48.3599174, lng: 18.0463596 },
    { lat: 48.3596173, lng: 18.0480211 },
    { lat: 48.3593439, lng: 18.0491017 },
    { lat: 48.359159, lng: 18.0501527 },
    { lat: 48.3591152, lng: 18.0504598 },
    { lat: 48.3591793, lng: 18.0507227 },
    { lat: 48.3588996, lng: 18.0510116 },
    { lat: 48.3586983, lng: 18.051558 },
    { lat: 48.358327, lng: 18.0522066 },
    { lat: 48.3581634, lng: 18.0523433 },
    { lat: 48.3578549, lng: 18.0533071 },
    { lat: 48.3578001, lng: 18.0534475 },
    { lat: 48.3577118, lng: 18.0535196 },
    { lat: 48.3595323, lng: 18.0563054 },
    { lat: 48.3607766, lng: 18.0583175 },
    { lat: 48.360963, lng: 18.0581454 },
    { lat: 48.3610856, lng: 18.0581795 },
    { lat: 48.3611148, lng: 18.0586941 },
    { lat: 48.3611874, lng: 18.0590013 },
    { lat: 48.3620874, lng: 18.0604987 },
    { lat: 48.3627838, lng: 18.0605969 },
    { lat: 48.3631165, lng: 18.0605153 },
    { lat: 48.3633075, lng: 18.0605935 },
    { lat: 48.3633662, lng: 18.0607116 },
    { lat: 48.3633575, lng: 18.0608125 },
    { lat: 48.3630191, lng: 18.0611011 },
    { lat: 48.3628915, lng: 18.0613867 },
    { lat: 48.3629825, lng: 18.0629046 },
    { lat: 48.363096, lng: 18.0637973 },
    { lat: 48.3632785, lng: 18.0646684 },
    { lat: 48.3641921, lng: 18.0646408 },
    { lat: 48.3643683, lng: 18.0647157 },
    { lat: 48.3643714, lng: 18.0649041 },
    { lat: 48.3641469, lng: 18.0650955 },
    { lat: 48.3640572, lng: 18.0654509 },
    { lat: 48.3641208, lng: 18.065802 },
    { lat: 48.3644386, lng: 18.0662505 },
    { lat: 48.3644168, lng: 18.0665139 },
    { lat: 48.3644528, lng: 18.0666831 },
    { lat: 48.3646409, lng: 18.0668678 },
    { lat: 48.3646976, lng: 18.0668514 },
    { lat: 48.3647223, lng: 18.0667373 },
    { lat: 48.3646581, lng: 18.0664343 },
    { lat: 48.3646686, lng: 18.0662849 },
    { lat: 48.3648236, lng: 18.0657773 },
    { lat: 48.364928, lng: 18.0651743 },
    { lat: 48.3651659, lng: 18.0646542 },
    { lat: 48.365248, lng: 18.0645553 },
    { lat: 48.3652281, lng: 18.0645232 },
    { lat: 48.3660641, lng: 18.0627058 },
    { lat: 48.3667389, lng: 18.0614628 },
    { lat: 48.3703045, lng: 18.056831 },
    { lat: 48.372471, lng: 18.0536751 },
    { lat: 48.3726615, lng: 18.0533107 },
    { lat: 48.3729591, lng: 18.0520456 },
    { lat: 48.3730238, lng: 18.0515752 },
    { lat: 48.3731015, lng: 18.050256 },
    { lat: 48.3732084, lng: 18.0495293 },
    { lat: 48.3734849, lng: 18.048212 },
    { lat: 48.3742626, lng: 18.0450529 },
    { lat: 48.3742922, lng: 18.0446737 },
    { lat: 48.3745434, lng: 18.0436568 },
    { lat: 48.3748469, lng: 18.0427242 },
    { lat: 48.3758139, lng: 18.0404529 },
    { lat: 48.3770687, lng: 18.0373106 },
    { lat: 48.3775338, lng: 18.0360371 },
    { lat: 48.3777802, lng: 18.035036 },
    { lat: 48.3782538, lng: 18.0353881 },
    { lat: 48.3786434, lng: 18.0359832 },
    { lat: 48.379234, lng: 18.036654 },
    { lat: 48.3804655, lng: 18.0377785 },
    { lat: 48.3819475, lng: 18.0387516 },
    { lat: 48.3848904, lng: 18.0409097 },
    { lat: 48.3862323, lng: 18.0415555 },
    { lat: 48.387144, lng: 18.0421069 },
    { lat: 48.3877535, lng: 18.0423898 },
    { lat: 48.3897801, lng: 18.0428039 },
    { lat: 48.3912046, lng: 18.0433004 },
    { lat: 48.3919708, lng: 18.0433755 },
    { lat: 48.3919695, lng: 18.043353 },
    { lat: 48.3919845, lng: 18.039644 },
    { lat: 48.3921716, lng: 18.0382716 },
    { lat: 48.3923286, lng: 18.0358387 },
    { lat: 48.3924478, lng: 18.0330133 },
    { lat: 48.3924375, lng: 18.0301428 },
    { lat: 48.3922259, lng: 18.0278895 },
    { lat: 48.392137, lng: 18.0274952 },
  ],
  Šurianky: [
    { lat: 48.4073068, lng: 18.044862 },
    { lat: 48.407605, lng: 18.0448495 },
    { lat: 48.4083687, lng: 18.0450888 },
    { lat: 48.4090327, lng: 18.0451709 },
    { lat: 48.4094047, lng: 18.045279 },
    { lat: 48.4105218, lng: 18.0457723 },
    { lat: 48.410539, lng: 18.0457736 },
    { lat: 48.410964, lng: 18.0458628 },
    { lat: 48.4126015, lng: 18.0458415 },
    { lat: 48.4142131, lng: 18.0449241 },
    { lat: 48.4156773, lng: 18.0446805 },
    { lat: 48.4162366, lng: 18.0444846 },
    { lat: 48.416938, lng: 18.0441112 },
    { lat: 48.4176949, lng: 18.0438374 },
    { lat: 48.4200109, lng: 18.0425226 },
    { lat: 48.4238126, lng: 18.0420338 },
    { lat: 48.4247273, lng: 18.0419271 },
    { lat: 48.4325971, lng: 18.0431838 },
    { lat: 48.4325991, lng: 18.0431814 },
    { lat: 48.4324496, lng: 18.0429402 },
    { lat: 48.4323169, lng: 18.0425891 },
    { lat: 48.4322775, lng: 18.0422076 },
    { lat: 48.4326709, lng: 18.0399323 },
    { lat: 48.4326906, lng: 18.038684 },
    { lat: 48.4326529, lng: 18.0382196 },
    { lat: 48.4325921, lng: 18.0379754 },
    { lat: 48.4322019, lng: 18.0371593 },
    { lat: 48.4319643, lng: 18.0364857 },
    { lat: 48.431901, lng: 18.0360993 },
    { lat: 48.4318262, lng: 18.0334907 },
    { lat: 48.4317147, lng: 18.0328667 },
    { lat: 48.4315442, lng: 18.0312841 },
    { lat: 48.4314369, lng: 18.0307313 },
    { lat: 48.4319574, lng: 18.0304103 },
    { lat: 48.432112, lng: 18.0302176 },
    { lat: 48.4318546, lng: 18.0289452 },
    { lat: 48.4317465, lng: 18.0264744 },
    { lat: 48.4316116, lng: 18.0253648 },
    { lat: 48.4315479, lng: 18.0250492 },
    { lat: 48.4313396, lng: 18.0245085 },
    { lat: 48.4311698, lng: 18.0233211 },
    { lat: 48.4310782, lng: 18.0230701 },
    { lat: 48.4310596, lng: 18.0227563 },
    { lat: 48.4311421, lng: 18.0223411 },
    { lat: 48.4310386, lng: 18.0212245 },
    { lat: 48.430317, lng: 18.0156566 },
    { lat: 48.4300078, lng: 18.0138341 },
    { lat: 48.4299714, lng: 18.0138116 },
    { lat: 48.4298976, lng: 18.0134608 },
    { lat: 48.4296805, lng: 18.0122201 },
    { lat: 48.4303375, lng: 18.0116748 },
    { lat: 48.4313864, lng: 18.0106571 },
    { lat: 48.4306949, lng: 18.009773 },
    { lat: 48.4303838, lng: 18.0090198 },
    { lat: 48.4304956, lng: 18.0072746 },
    { lat: 48.4297707, lng: 18.0075569 },
    { lat: 48.429244, lng: 18.007922 },
    { lat: 48.4290948, lng: 18.0078791 },
    { lat: 48.4285418, lng: 18.0073511 },
    { lat: 48.4282224, lng: 18.007142 },
    { lat: 48.4274094, lng: 18.0067954 },
    { lat: 48.4270999, lng: 18.0066575 },
    { lat: 48.4270876, lng: 18.0066809 },
    { lat: 48.4267724, lng: 18.007585 },
    { lat: 48.426068, lng: 18.0082672 },
    { lat: 48.4256782, lng: 18.0084095 },
    { lat: 48.4254195, lng: 18.0084261 },
    { lat: 48.4249168, lng: 18.008778 },
    { lat: 48.4244467, lng: 18.0083145 },
    { lat: 48.4241971, lng: 18.0081775 },
    { lat: 48.4236679, lng: 18.0081239 },
    { lat: 48.4227325, lng: 18.0078069 },
    { lat: 48.4219724, lng: 18.0077043 },
    { lat: 48.421835, lng: 18.0074616 },
    { lat: 48.4218205, lng: 18.0073506 },
    { lat: 48.421567, lng: 18.0071487 },
    { lat: 48.4196524, lng: 18.006064 },
    { lat: 48.4195312, lng: 18.0060732 },
    { lat: 48.4192131, lng: 18.0062684 },
    { lat: 48.4190838, lng: 18.006429 },
    { lat: 48.4188636, lng: 18.0069763 },
    { lat: 48.4185525, lng: 18.0073251 },
    { lat: 48.4182719, lng: 18.0074261 },
    { lat: 48.4178552, lng: 18.0073481 },
    { lat: 48.4175433, lng: 18.0069246 },
    { lat: 48.4163903, lng: 18.0075306 },
    { lat: 48.4160747, lng: 18.0076965 },
    { lat: 48.414516, lng: 18.0087953 },
    { lat: 48.4144069, lng: 18.0088782 },
    { lat: 48.4141351, lng: 18.0091676 },
    { lat: 48.4135858, lng: 18.0095353 },
    { lat: 48.4121426, lng: 18.0101466 },
    { lat: 48.4109936, lng: 18.0110758 },
    { lat: 48.4108234, lng: 18.0111175 },
    { lat: 48.4102682, lng: 18.010969 },
    { lat: 48.4100131, lng: 18.0110618 },
    { lat: 48.4090596, lng: 18.0116926 },
    { lat: 48.408411, lng: 18.0123815 },
    { lat: 48.4081848, lng: 18.0124875 },
    { lat: 48.407877, lng: 18.0127573 },
    { lat: 48.4078298, lng: 18.0129693 },
    { lat: 48.4075199, lng: 18.0132066 },
    { lat: 48.4066771, lng: 18.013474 },
    { lat: 48.4059161, lng: 18.0138696 },
    { lat: 48.4050387, lng: 18.0142051 },
    { lat: 48.4033103, lng: 18.0148054 },
    { lat: 48.4030042, lng: 18.0149524 },
    { lat: 48.4022475, lng: 18.0155701 },
    { lat: 48.4015301, lng: 18.0159746 },
    { lat: 48.3998896, lng: 18.0165191 },
    { lat: 48.3991454, lng: 18.0171169 },
    { lat: 48.3987226, lng: 18.0173718 },
    { lat: 48.3971407, lng: 18.0178557 },
    { lat: 48.3969343, lng: 18.0180478 },
    { lat: 48.3964391, lng: 18.0189244 },
    { lat: 48.3960569, lng: 18.0194814 },
    { lat: 48.3956662, lng: 18.0199148 },
    { lat: 48.3945167, lng: 18.0203439 },
    { lat: 48.3941644, lng: 18.0203971 },
    { lat: 48.3931111, lng: 18.0203345 },
    { lat: 48.3922512, lng: 18.02036 },
    { lat: 48.3922413, lng: 18.0205513 },
    { lat: 48.3921049, lng: 18.0228523 },
    { lat: 48.3921635, lng: 18.0248648 },
    { lat: 48.3923899, lng: 18.0272859 },
    { lat: 48.392137, lng: 18.0274952 },
    { lat: 48.3922259, lng: 18.0278895 },
    { lat: 48.3924375, lng: 18.0301428 },
    { lat: 48.3924478, lng: 18.0330133 },
    { lat: 48.3923286, lng: 18.0358387 },
    { lat: 48.3921716, lng: 18.0382716 },
    { lat: 48.3919845, lng: 18.039644 },
    { lat: 48.3919695, lng: 18.043353 },
    { lat: 48.3919708, lng: 18.0433755 },
    { lat: 48.3920764, lng: 18.043365 },
    { lat: 48.3925761, lng: 18.0434001 },
    { lat: 48.3931224, lng: 18.0435356 },
    { lat: 48.3935507, lng: 18.0437355 },
    { lat: 48.3959263, lng: 18.0445122 },
    { lat: 48.3975855, lng: 18.0448165 },
    { lat: 48.3985552, lng: 18.0452605 },
    { lat: 48.3993602, lng: 18.0450916 },
    { lat: 48.4014883, lng: 18.0454274 },
    { lat: 48.4020829, lng: 18.0453689 },
    { lat: 48.403182, lng: 18.0450988 },
    { lat: 48.4046465, lng: 18.0450153 },
    { lat: 48.405103, lng: 18.0449291 },
    { lat: 48.4057776, lng: 18.0446546 },
    { lat: 48.4063671, lng: 18.0446604 },
    { lat: 48.4073068, lng: 18.044862 },
  ],
  Čechynce: [
    { lat: 48.271014, lng: 18.1758573 },
    { lat: 48.2707174, lng: 18.1758762 },
    { lat: 48.2701828, lng: 18.1758242 },
    { lat: 48.2694804, lng: 18.1755629 },
    { lat: 48.2688859, lng: 18.1750065 },
    { lat: 48.2679044, lng: 18.1742366 },
    { lat: 48.2672574, lng: 18.1735139 },
    { lat: 48.2661869, lng: 18.1716027 },
    { lat: 48.2654246, lng: 18.1700059 },
    { lat: 48.2644901, lng: 18.168359 },
    { lat: 48.26351, lng: 18.1657927 },
    { lat: 48.2628176, lng: 18.1645442 },
    { lat: 48.2616809, lng: 18.1622755 },
    { lat: 48.2610196, lng: 18.1611349 },
    { lat: 48.2602533, lng: 18.1592574 },
    { lat: 48.2601549, lng: 18.1590786 },
    { lat: 48.259945, lng: 18.1589631 },
    { lat: 48.2602124, lng: 18.1588689 },
    { lat: 48.2615976, lng: 18.1574876 },
    { lat: 48.2594211, lng: 18.1526419 },
    { lat: 48.2586126, lng: 18.1534803 },
    { lat: 48.2578164, lng: 18.154306 },
    { lat: 48.2572657, lng: 18.1530884 },
    { lat: 48.2576483, lng: 18.1526441 },
    { lat: 48.2569721, lng: 18.1513272 },
    { lat: 48.2565938, lng: 18.1504543 },
    { lat: 48.2564295, lng: 18.1501926 },
    { lat: 48.2552644, lng: 18.1494314 },
    { lat: 48.2536946, lng: 18.1469241 },
    { lat: 48.2526042, lng: 18.1450485 },
    { lat: 48.2520416, lng: 18.1446697 },
    { lat: 48.251768, lng: 18.1445849 },
    { lat: 48.2505026, lng: 18.1433445 },
    { lat: 48.2498186, lng: 18.1428816 },
    { lat: 48.2490012, lng: 18.1422166 },
    { lat: 48.2463827, lng: 18.1391288 },
    { lat: 48.2462519, lng: 18.139265 },
    { lat: 48.2456618, lng: 18.1394214 },
    { lat: 48.2454972, lng: 18.1393676 },
    { lat: 48.2453712, lng: 18.139199 },
    { lat: 48.2452077, lng: 18.1391659 },
    { lat: 48.2451283, lng: 18.1393484 },
    { lat: 48.245139, lng: 18.1397046 },
    { lat: 48.2452525, lng: 18.1401404 },
    { lat: 48.2452432, lng: 18.1404259 },
    { lat: 48.24509, lng: 18.1411453 },
    { lat: 48.2449666, lng: 18.1412713 },
    { lat: 48.2447278, lng: 18.1412601 },
    { lat: 48.2445725, lng: 18.1413449 },
    { lat: 48.2444787, lng: 18.14165 },
    { lat: 48.2444315, lng: 18.142079 },
    { lat: 48.2443345, lng: 18.1421965 },
    { lat: 48.2441569, lng: 18.1422081 },
    { lat: 48.243822, lng: 18.1419402 },
    { lat: 48.2436564, lng: 18.1419637 },
    { lat: 48.243563, lng: 18.1420648 },
    { lat: 48.2434396, lng: 18.1431966 },
    { lat: 48.2433849, lng: 18.1434006 },
    { lat: 48.2430725, lng: 18.1439425 },
    { lat: 48.2430353, lng: 18.1441747 },
    { lat: 48.2430436, lng: 18.1447206 },
    { lat: 48.2429788, lng: 18.1449833 },
    { lat: 48.2427737, lng: 18.1453536 },
    { lat: 48.2427208, lng: 18.1455485 },
    { lat: 48.2427229, lng: 18.146223 },
    { lat: 48.2429629, lng: 18.1467357 },
    { lat: 48.2434164, lng: 18.1474254 },
    { lat: 48.2435403, lng: 18.1476923 },
    { lat: 48.2436407, lng: 18.148156 },
    { lat: 48.2435935, lng: 18.1488328 },
    { lat: 48.2434592, lng: 18.1491032 },
    { lat: 48.2430364, lng: 18.1494765 },
    { lat: 48.242961, lng: 18.1496174 },
    { lat: 48.2429614, lng: 18.1497951 },
    { lat: 48.2430786, lng: 18.1499872 },
    { lat: 48.2431675, lng: 18.1500385 },
    { lat: 48.243767, lng: 18.1499104 },
    { lat: 48.2438874, lng: 18.1499427 },
    { lat: 48.2439603, lng: 18.1500504 },
    { lat: 48.2440108, lng: 18.1502937 },
    { lat: 48.243983, lng: 18.1505753 },
    { lat: 48.2437441, lng: 18.1512176 },
    { lat: 48.2430356, lng: 18.1527822 },
    { lat: 48.2429768, lng: 18.153265 },
    { lat: 48.2425438, lng: 18.1537699 },
    { lat: 48.2404423, lng: 18.1549445 },
    { lat: 48.240006, lng: 18.1550859 },
    { lat: 48.2394583, lng: 18.155118 },
    { lat: 48.2380793, lng: 18.1550544 },
    { lat: 48.2385511, lng: 18.1556216 },
    { lat: 48.2400525, lng: 18.1576006 },
    { lat: 48.2401097, lng: 18.1577503 },
    { lat: 48.2408729, lng: 18.1595132 },
    { lat: 48.2417087, lng: 18.1611109 },
    { lat: 48.2443836, lng: 18.1655654 },
    { lat: 48.2467708, lng: 18.1701174 },
    { lat: 48.2470338, lng: 18.1703139 },
    { lat: 48.2480146, lng: 18.1715162 },
    { lat: 48.2489457, lng: 18.1729708 },
    { lat: 48.2512199, lng: 18.1768896 },
    { lat: 48.2525857, lng: 18.1790443 },
    { lat: 48.2537023, lng: 18.180583 },
    { lat: 48.2550345, lng: 18.182749 },
    { lat: 48.2559796, lng: 18.1846009 },
    { lat: 48.2567103, lng: 18.1861614 },
    { lat: 48.2588379, lng: 18.1902673 },
    { lat: 48.2591581, lng: 18.1900849 },
    { lat: 48.2602581, lng: 18.1890141 },
    { lat: 48.2609264, lng: 18.1880887 },
    { lat: 48.2613616, lng: 18.1875981 },
    { lat: 48.2634767, lng: 18.1855264 },
    { lat: 48.2641248, lng: 18.1846226 },
    { lat: 48.2647353, lng: 18.1835155 },
    { lat: 48.2649997, lng: 18.1832119 },
    { lat: 48.2656816, lng: 18.1826132 },
    { lat: 48.266734, lng: 18.1809979 },
    { lat: 48.2679173, lng: 18.1795343 },
    { lat: 48.2685889, lng: 18.1788774 },
    { lat: 48.2706295, lng: 18.1762674 },
    { lat: 48.2706429, lng: 18.1762531 },
    { lat: 48.271014, lng: 18.1758573 },
  ],
  VýčapyOpatovce: [
    { lat: 48.4055363, lng: 18.1040107 },
    { lat: 48.4056051, lng: 18.1039326 },
    { lat: 48.4057703, lng: 18.1036689 },
    { lat: 48.4059898, lng: 18.1035457 },
    { lat: 48.4063259, lng: 18.1035616 },
    { lat: 48.4064358, lng: 18.1037427 },
    { lat: 48.4064895, lng: 18.1039647 },
    { lat: 48.4064209, lng: 18.1051807 },
    { lat: 48.4065394, lng: 18.1054662 },
    { lat: 48.4066457, lng: 18.1055299 },
    { lat: 48.4067011, lng: 18.1054899 },
    { lat: 48.4071643, lng: 18.104482 },
    { lat: 48.4075797, lng: 18.1039432 },
    { lat: 48.4078014, lng: 18.104031 },
    { lat: 48.408099, lng: 18.1043912 },
    { lat: 48.4082585, lng: 18.1046816 },
    { lat: 48.4084831, lng: 18.104839 },
    { lat: 48.4085903, lng: 18.104779 },
    { lat: 48.4085922, lng: 18.1046992 },
    { lat: 48.408416, lng: 18.1042707 },
    { lat: 48.4084236, lng: 18.1041511 },
    { lat: 48.4084903, lng: 18.1040591 },
    { lat: 48.4086848, lng: 18.1040085 },
    { lat: 48.4088036, lng: 18.1043554 },
    { lat: 48.4090944, lng: 18.1046963 },
    { lat: 48.4092542, lng: 18.1047852 },
    { lat: 48.4100212, lng: 18.1048751 },
    { lat: 48.4098671, lng: 18.1053438 },
    { lat: 48.4101331, lng: 18.1057252 },
    { lat: 48.4103597, lng: 18.1059513 },
    { lat: 48.4106249, lng: 18.106989 },
    { lat: 48.4109063, lng: 18.1069975 },
    { lat: 48.4110624, lng: 18.1066945 },
    { lat: 48.4112799, lng: 18.1064554 },
    { lat: 48.4122244, lng: 18.1063584 },
    { lat: 48.4126976, lng: 18.1068261 },
    { lat: 48.4129243, lng: 18.1075371 },
    { lat: 48.4130897, lng: 18.1076647 },
    { lat: 48.4132572, lng: 18.107536 },
    { lat: 48.4134109, lng: 18.1071533 },
    { lat: 48.4143982, lng: 18.1070755 },
    { lat: 48.4144316, lng: 18.1070089 },
    { lat: 48.4149542, lng: 18.1073756 },
    { lat: 48.4153544, lng: 18.1075411 },
    { lat: 48.4156339, lng: 18.1075563 },
    { lat: 48.4157067, lng: 18.1074472 },
    { lat: 48.4159366, lng: 18.1074438 },
    { lat: 48.416062, lng: 18.1079284 },
    { lat: 48.4161606, lng: 18.1080291 },
    { lat: 48.4164087, lng: 18.1081316 },
    { lat: 48.4167134, lng: 18.1082575 },
    { lat: 48.4177989, lng: 18.1088908 },
    { lat: 48.4180929, lng: 18.1089769 },
    { lat: 48.4185704, lng: 18.1094043 },
    { lat: 48.4186735, lng: 18.109428 },
    { lat: 48.4189537, lng: 18.110186 },
    { lat: 48.4190069, lng: 18.1108785 },
    { lat: 48.418912, lng: 18.1118215 },
    { lat: 48.4189283, lng: 18.1121 },
    { lat: 48.4190179, lng: 18.1123937 },
    { lat: 48.4191233, lng: 18.1125577 },
    { lat: 48.419283, lng: 18.1127203 },
    { lat: 48.4195952, lng: 18.1127894 },
    { lat: 48.4199682, lng: 18.112533 },
    { lat: 48.4201565, lng: 18.1122796 },
    { lat: 48.420514, lng: 18.112097 },
    { lat: 48.420785, lng: 18.111943 },
    { lat: 48.420979, lng: 18.111746 },
    { lat: 48.421145, lng: 18.111606 },
    { lat: 48.421375, lng: 18.111195 },
    { lat: 48.421612, lng: 18.110289 },
    { lat: 48.421772, lng: 18.109602 },
    { lat: 48.421586, lng: 18.109552 },
    { lat: 48.421491, lng: 18.109518 },
    { lat: 48.42139, lng: 18.109472 },
    { lat: 48.421284, lng: 18.109367 },
    { lat: 48.421207, lng: 18.109179 },
    { lat: 48.421199, lng: 18.109025 },
    { lat: 48.421149, lng: 18.108879 },
    { lat: 48.421064, lng: 18.108711 },
    { lat: 48.420948, lng: 18.108606 },
    { lat: 48.420921, lng: 18.108363 },
    { lat: 48.420913, lng: 18.108286 },
    { lat: 48.420941, lng: 18.108114 },
    { lat: 48.420942, lng: 18.108102 },
    { lat: 48.420983, lng: 18.10787 },
    { lat: 48.421038, lng: 18.107533 },
    { lat: 48.421046, lng: 18.10744 },
    { lat: 48.421049, lng: 18.107403 },
    { lat: 48.421042, lng: 18.107209 },
    { lat: 48.421042, lng: 18.107198 },
    { lat: 48.421034, lng: 18.106959 },
    { lat: 48.421006, lng: 18.106688 },
    { lat: 48.420978, lng: 18.106349 },
    { lat: 48.420959, lng: 18.105955 },
    { lat: 48.421192, lng: 18.104668 },
    { lat: 48.421291, lng: 18.104027 },
    { lat: 48.421351, lng: 18.103724 },
    { lat: 48.422142, lng: 18.101201 },
    { lat: 48.422228, lng: 18.100873 },
    { lat: 48.422646, lng: 18.098896 },
    { lat: 48.42263, lng: 18.098881 },
    { lat: 48.422614, lng: 18.098754 },
    { lat: 48.422594, lng: 18.098565 },
    { lat: 48.422579, lng: 18.098429 },
    { lat: 48.42253, lng: 18.098037 },
    { lat: 48.422514, lng: 18.097772 },
    { lat: 48.422527, lng: 18.097419 },
    { lat: 48.422553, lng: 18.097159 },
    { lat: 48.422558, lng: 18.097105 },
    { lat: 48.422561, lng: 18.097079 },
    { lat: 48.422709, lng: 18.096444 },
    { lat: 48.422863, lng: 18.096485 },
    { lat: 48.423424, lng: 18.09667 },
    { lat: 48.423486, lng: 18.096497 },
    { lat: 48.423479, lng: 18.096407 },
    { lat: 48.423477, lng: 18.09638 },
    { lat: 48.423471, lng: 18.096302 },
    { lat: 48.423471, lng: 18.096275 },
    { lat: 48.423466, lng: 18.096207 },
    { lat: 48.423465, lng: 18.096188 },
    { lat: 48.42348, lng: 18.096131 },
    { lat: 48.423494, lng: 18.096069 },
    { lat: 48.423901, lng: 18.094439 },
    { lat: 48.424036, lng: 18.094159 },
    { lat: 48.424055, lng: 18.09412 },
    { lat: 48.424075, lng: 18.094078 },
    { lat: 48.42419, lng: 18.093841 },
    { lat: 48.424318, lng: 18.093234 },
    { lat: 48.424368, lng: 18.092987 },
    { lat: 48.424416, lng: 18.092771 },
    { lat: 48.424473, lng: 18.092504 },
    { lat: 48.424512, lng: 18.092312 },
    { lat: 48.424537, lng: 18.09219 },
    { lat: 48.424659, lng: 18.091495 },
    { lat: 48.424695, lng: 18.091438 },
    { lat: 48.424713, lng: 18.091424 },
    { lat: 48.424755, lng: 18.091379 },
    { lat: 48.4248, lng: 18.091335 },
    { lat: 48.424848, lng: 18.091176 },
    { lat: 48.42489, lng: 18.091018 },
    { lat: 48.424935, lng: 18.090843 },
    { lat: 48.42498, lng: 18.090671 },
    { lat: 48.425032, lng: 18.09048 },
    { lat: 48.425073, lng: 18.09032 },
    { lat: 48.425164, lng: 18.089969 },
    { lat: 48.425262, lng: 18.089619 },
    { lat: 48.425276, lng: 18.089566 },
    { lat: 48.425322, lng: 18.089392 },
    { lat: 48.42556, lng: 18.088493 },
    { lat: 48.425564, lng: 18.08848 },
    { lat: 48.425584, lng: 18.088427 },
    { lat: 48.425622, lng: 18.088327 },
    { lat: 48.425602, lng: 18.08823 },
    { lat: 48.42563, lng: 18.088249 },
    { lat: 48.425633, lng: 18.088241 },
    { lat: 48.425643, lng: 18.088192 },
    { lat: 48.426698, lng: 18.083846 },
    { lat: 48.426803, lng: 18.083333 },
    { lat: 48.427364, lng: 18.080811 },
    { lat: 48.42857, lng: 18.075313 },
    { lat: 48.429699, lng: 18.071138 },
    { lat: 48.429712, lng: 18.070878 },
    { lat: 48.429719, lng: 18.070658 },
    { lat: 48.429749, lng: 18.069974 },
    { lat: 48.42974, lng: 18.069709 },
    { lat: 48.429739, lng: 18.069644 },
    { lat: 48.429763, lng: 18.069479 },
    { lat: 48.429778, lng: 18.069382 },
    { lat: 48.429794, lng: 18.069265 },
    { lat: 48.429804, lng: 18.069163 },
    { lat: 48.429813, lng: 18.069015 },
    { lat: 48.429823, lng: 18.068866 },
    { lat: 48.429815, lng: 18.068747 },
    { lat: 48.429807, lng: 18.068628 },
    { lat: 48.429796, lng: 18.068507 },
    { lat: 48.429785, lng: 18.068386 },
    { lat: 48.429786, lng: 18.068375 },
    { lat: 48.42979, lng: 18.068304 },
    { lat: 48.429792, lng: 18.068236 },
    { lat: 48.429795, lng: 18.06817 },
    { lat: 48.429799, lng: 18.06813 },
    { lat: 48.429808, lng: 18.068056 },
    { lat: 48.429839, lng: 18.06786 },
    { lat: 48.429885, lng: 18.067582 },
    { lat: 48.42993, lng: 18.067193 },
    { lat: 48.429938, lng: 18.067151 },
    { lat: 48.429976, lng: 18.066962 },
    { lat: 48.430006, lng: 18.066812 },
    { lat: 48.430011, lng: 18.066786 },
    { lat: 48.43005, lng: 18.066598 },
    { lat: 48.430077, lng: 18.066476 },
    { lat: 48.430119, lng: 18.066348 },
    { lat: 48.430128, lng: 18.066322 },
    { lat: 48.430135, lng: 18.066285 },
    { lat: 48.43018, lng: 18.066178 },
    { lat: 48.430283, lng: 18.065919 },
    { lat: 48.430308, lng: 18.06586 },
    { lat: 48.430326, lng: 18.065783 },
    { lat: 48.430336, lng: 18.065733 },
    { lat: 48.43035, lng: 18.065651 },
    { lat: 48.430358, lng: 18.06559 },
    { lat: 48.430404, lng: 18.065401 },
    { lat: 48.430442, lng: 18.065244 },
    { lat: 48.430448, lng: 18.065212 },
    { lat: 48.430456, lng: 18.065182 },
    { lat: 48.430482, lng: 18.065058 },
    { lat: 48.430498, lng: 18.064959 },
    { lat: 48.430536, lng: 18.064761 },
    { lat: 48.430553, lng: 18.064673 },
    { lat: 48.430553, lng: 18.06455 },
    { lat: 48.430569, lng: 18.064047 },
    { lat: 48.430595, lng: 18.063853 },
    { lat: 48.430602, lng: 18.063803 },
    { lat: 48.430615, lng: 18.063749 },
    { lat: 48.430638, lng: 18.063684 },
    { lat: 48.430729, lng: 18.063401 },
    { lat: 48.430763, lng: 18.063364 },
    { lat: 48.430795, lng: 18.063331 },
    { lat: 48.430822, lng: 18.063295 },
    { lat: 48.430855, lng: 18.063262 },
    { lat: 48.430879, lng: 18.063237 },
    { lat: 48.430899, lng: 18.063214 },
    { lat: 48.430915, lng: 18.063194 },
    { lat: 48.430998, lng: 18.063104 },
    { lat: 48.431042, lng: 18.063055 },
    { lat: 48.431088, lng: 18.063002 },
    { lat: 48.431151, lng: 18.062935 },
    { lat: 48.431203, lng: 18.062865 },
    { lat: 48.431224, lng: 18.062838 },
    { lat: 48.431266, lng: 18.062783 },
    { lat: 48.431315, lng: 18.062715 },
    { lat: 48.431426, lng: 18.062514 },
    { lat: 48.431547, lng: 18.062285 },
    { lat: 48.431609, lng: 18.062161 },
    { lat: 48.431628, lng: 18.062136 },
    { lat: 48.431661, lng: 18.062076 },
    { lat: 48.431705, lng: 18.062009 },
    { lat: 48.431767, lng: 18.061912 },
    { lat: 48.431823, lng: 18.061817 },
    { lat: 48.431868, lng: 18.061741 },
    { lat: 48.431882, lng: 18.061731 },
    { lat: 48.43203, lng: 18.061383 },
    { lat: 48.432207, lng: 18.060995 },
    { lat: 48.432326, lng: 18.060414 },
    { lat: 48.432458, lng: 18.060094 },
    { lat: 48.432605, lng: 18.059807 },
    { lat: 48.432746, lng: 18.05943 },
    { lat: 48.432907, lng: 18.059087 },
    { lat: 48.433086, lng: 18.058815 },
    { lat: 48.433347, lng: 18.058169 },
    { lat: 48.434291, lng: 18.055838 },
    { lat: 48.435531, lng: 18.054678 },
    { lat: 48.435591, lng: 18.054623 },
    { lat: 48.435838, lng: 18.053816 },
    { lat: 48.435761, lng: 18.052539 },
    { lat: 48.43583, lng: 18.051666 },
    { lat: 48.435886, lng: 18.05138 },
    { lat: 48.436115, lng: 18.050635 },
    { lat: 48.436127, lng: 18.05062 },
    { lat: 48.4359793, lng: 18.050216 },
    { lat: 48.4356185, lng: 18.0496863 },
    { lat: 48.4346812, lng: 18.0479417 },
    { lat: 48.4341349, lng: 18.0463756 },
    { lat: 48.4338699, lng: 18.0458668 },
    { lat: 48.4332415, lng: 18.0441369 },
    { lat: 48.4330265, lng: 18.0437448 },
    { lat: 48.4325991, lng: 18.0431814 },
    { lat: 48.4325971, lng: 18.0431838 },
    { lat: 48.4247273, lng: 18.0419271 },
    { lat: 48.4238126, lng: 18.0420338 },
    { lat: 48.4200109, lng: 18.0425226 },
    { lat: 48.4176949, lng: 18.0438374 },
    { lat: 48.416938, lng: 18.0441112 },
    { lat: 48.4162366, lng: 18.0444846 },
    { lat: 48.4156773, lng: 18.0446805 },
    { lat: 48.4142131, lng: 18.0449241 },
    { lat: 48.4126015, lng: 18.0458415 },
    { lat: 48.410964, lng: 18.0458628 },
    { lat: 48.410539, lng: 18.0457736 },
    { lat: 48.4105327, lng: 18.0458593 },
    { lat: 48.4104598, lng: 18.0468776 },
    { lat: 48.4103885, lng: 18.047291 },
    { lat: 48.4085791, lng: 18.051772 },
    { lat: 48.4081502, lng: 18.0522854 },
    { lat: 48.4061519, lng: 18.0542795 },
    { lat: 48.4045596, lng: 18.056171 },
    { lat: 48.4040709, lng: 18.0568574 },
    { lat: 48.4039699, lng: 18.0571333 },
    { lat: 48.4034959, lng: 18.0593096 },
    { lat: 48.4032211, lng: 18.0610593 },
    { lat: 48.4025574, lng: 18.0641518 },
    { lat: 48.4014647, lng: 18.068737 },
    { lat: 48.401256, lng: 18.0694076 },
    { lat: 48.3998397, lng: 18.0751497 },
    { lat: 48.3997082, lng: 18.0755391 },
    { lat: 48.3990291, lng: 18.0788193 },
    { lat: 48.3992576, lng: 18.0790205 },
    { lat: 48.3988098, lng: 18.0812254 },
    { lat: 48.3985993, lng: 18.081888 },
    { lat: 48.3980038, lng: 18.0833337 },
    { lat: 48.3979339, lng: 18.0848452 },
    { lat: 48.3964802, lng: 18.0914429 },
    { lat: 48.3962658, lng: 18.0922089 },
    { lat: 48.3958776, lng: 18.0943024 },
    { lat: 48.3955535, lng: 18.0953326 },
    { lat: 48.3951996, lng: 18.0961682 },
    { lat: 48.3955436, lng: 18.0962987 },
    { lat: 48.3965157, lng: 18.0970175 },
    { lat: 48.3966863, lng: 18.0973451 },
    { lat: 48.3966568, lng: 18.0975912 },
    { lat: 48.3965734, lng: 18.0977294 },
    { lat: 48.3963014, lng: 18.0980353 },
    { lat: 48.3957555, lng: 18.0984237 },
    { lat: 48.3956014, lng: 18.0988365 },
    { lat: 48.395634, lng: 18.0991617 },
    { lat: 48.3957217, lng: 18.0995592 },
    { lat: 48.3957932, lng: 18.0997383 },
    { lat: 48.3959254, lng: 18.0998951 },
    { lat: 48.3963599, lng: 18.1000283 },
    { lat: 48.3966091, lng: 18.1000283 },
    { lat: 48.396938, lng: 18.100304 },
    { lat: 48.3970154, lng: 18.1004872 },
    { lat: 48.3965347, lng: 18.1014867 },
    { lat: 48.3963059, lng: 18.1023788 },
    { lat: 48.396299, lng: 18.1026997 },
    { lat: 48.3963328, lng: 18.1029129 },
    { lat: 48.3965477, lng: 18.103323 },
    { lat: 48.3968992, lng: 18.1036582 },
    { lat: 48.3970754, lng: 18.103718 },
    { lat: 48.3971636, lng: 18.103663 },
    { lat: 48.3973058, lng: 18.1033775 },
    { lat: 48.3973107, lng: 18.1033647 },
    { lat: 48.3973213, lng: 18.1032955 },
    { lat: 48.3973301, lng: 18.1021768 },
    { lat: 48.3973991, lng: 18.1018892 },
    { lat: 48.3978476, lng: 18.1010343 },
    { lat: 48.3979804, lng: 18.1008708 },
    { lat: 48.3980911, lng: 18.1008561 },
    { lat: 48.3981518, lng: 18.1009677 },
    { lat: 48.3981107, lng: 18.1018624 },
    { lat: 48.3981744, lng: 18.1020443 },
    { lat: 48.3983072, lng: 18.1020189 },
    { lat: 48.3985209, lng: 18.1015627 },
    { lat: 48.3987318, lng: 18.1013519 },
    { lat: 48.3989155, lng: 18.1014231 },
    { lat: 48.399026, lng: 18.1018832 },
    { lat: 48.399108, lng: 18.1026441 },
    { lat: 48.3992545, lng: 18.1027536 },
    { lat: 48.3994739, lng: 18.1023989 },
    { lat: 48.3998241, lng: 18.1014036 },
    { lat: 48.4000564, lng: 18.1009622 },
    { lat: 48.4003382, lng: 18.1006255 },
    { lat: 48.4004864, lng: 18.1006126 },
    { lat: 48.4006004, lng: 18.1006334 },
    { lat: 48.4006763, lng: 18.1008293 },
    { lat: 48.4004889, lng: 18.1013109 },
    { lat: 48.4003135, lng: 18.1020533 },
    { lat: 48.4003057, lng: 18.1022837 },
    { lat: 48.4003537, lng: 18.1026016 },
    { lat: 48.4006111, lng: 18.1033524 },
    { lat: 48.4006894, lng: 18.1037267 },
    { lat: 48.4008294, lng: 18.1040732 },
    { lat: 48.4010126, lng: 18.1043462 },
    { lat: 48.4011189, lng: 18.1044088 },
    { lat: 48.4012077, lng: 18.1043825 },
    { lat: 48.4012608, lng: 18.1042874 },
    { lat: 48.4015282, lng: 18.102884 },
    { lat: 48.4018932, lng: 18.1023913 },
    { lat: 48.4021215, lng: 18.1023195 },
    { lat: 48.4022037, lng: 18.1024354 },
    { lat: 48.4022924, lng: 18.1031965 },
    { lat: 48.4024285, lng: 18.1038998 },
    { lat: 48.4025419, lng: 18.1040219 },
    { lat: 48.4026667, lng: 18.104054 },
    { lat: 48.4029696, lng: 18.1039073 },
    { lat: 48.4032897, lng: 18.1034785 },
    { lat: 48.4034453, lng: 18.1025519 },
    { lat: 48.403522, lng: 18.1024362 },
    { lat: 48.4036537, lng: 18.1023723 },
    { lat: 48.4037636, lng: 18.1024966 },
    { lat: 48.4038672, lng: 18.1027986 },
    { lat: 48.4039079, lng: 18.103088 },
    { lat: 48.403954, lng: 18.1044131 },
    { lat: 48.404005, lng: 18.1047217 },
    { lat: 48.4040644, lng: 18.1048321 },
    { lat: 48.4041721, lng: 18.1048005 },
    { lat: 48.4045779, lng: 18.1044263 },
    { lat: 48.4055363, lng: 18.1040107 },
  ],
  Jelšovce: [
    { lat: 48.3919708, lng: 18.0433755 },
    { lat: 48.3912046, lng: 18.0433004 },
    { lat: 48.3897801, lng: 18.0428039 },
    { lat: 48.3877535, lng: 18.0423898 },
    { lat: 48.387144, lng: 18.0421069 },
    { lat: 48.3862323, lng: 18.0415555 },
    { lat: 48.3848904, lng: 18.0409097 },
    { lat: 48.3819475, lng: 18.0387516 },
    { lat: 48.3804655, lng: 18.0377785 },
    { lat: 48.379234, lng: 18.036654 },
    { lat: 48.3786434, lng: 18.0359832 },
    { lat: 48.3782538, lng: 18.0353881 },
    { lat: 48.3777802, lng: 18.035036 },
    { lat: 48.3775338, lng: 18.0360371 },
    { lat: 48.3770687, lng: 18.0373106 },
    { lat: 48.3758139, lng: 18.0404529 },
    { lat: 48.3748469, lng: 18.0427242 },
    { lat: 48.3745434, lng: 18.0436568 },
    { lat: 48.3742922, lng: 18.0446737 },
    { lat: 48.3742626, lng: 18.0450529 },
    { lat: 48.3734849, lng: 18.048212 },
    { lat: 48.3732084, lng: 18.0495293 },
    { lat: 48.3731015, lng: 18.050256 },
    { lat: 48.3730238, lng: 18.0515752 },
    { lat: 48.3729591, lng: 18.0520456 },
    { lat: 48.3726615, lng: 18.0533107 },
    { lat: 48.372471, lng: 18.0536751 },
    { lat: 48.3703045, lng: 18.056831 },
    { lat: 48.3667389, lng: 18.0614628 },
    { lat: 48.3660641, lng: 18.0627058 },
    { lat: 48.3652281, lng: 18.0645232 },
    { lat: 48.365248, lng: 18.0645553 },
    { lat: 48.3661198, lng: 18.0643161 },
    { lat: 48.3667444, lng: 18.0643593 },
    { lat: 48.3670007, lng: 18.0645743 },
    { lat: 48.3671674, lng: 18.0650472 },
    { lat: 48.367161, lng: 18.0654545 },
    { lat: 48.3670546, lng: 18.0656948 },
    { lat: 48.3669617, lng: 18.0657845 },
    { lat: 48.3664808, lng: 18.0654379 },
    { lat: 48.3662131, lng: 18.0653021 },
    { lat: 48.3660423, lng: 18.0652971 },
    { lat: 48.365607, lng: 18.0656439 },
    { lat: 48.3654659, lng: 18.0658732 },
    { lat: 48.365425, lng: 18.0661171 },
    { lat: 48.3655542, lng: 18.0664502 },
    { lat: 48.36584, lng: 18.0664165 },
    { lat: 48.3660698, lng: 18.0666588 },
    { lat: 48.3662533, lng: 18.0669393 },
    { lat: 48.3663608, lng: 18.0673814 },
    { lat: 48.3665434, lng: 18.0674338 },
    { lat: 48.3667091, lng: 18.0673361 },
    { lat: 48.3667753, lng: 18.0673681 },
    { lat: 48.3667488, lng: 18.0679693 },
    { lat: 48.3666241, lng: 18.0684409 },
    { lat: 48.3660456, lng: 18.0691727 },
    { lat: 48.3659996, lng: 18.0695029 },
    { lat: 48.36607, lng: 18.0697384 },
    { lat: 48.3662029, lng: 18.0698098 },
    { lat: 48.3669836, lng: 18.0698426 },
    { lat: 48.3672091, lng: 18.0700316 },
    { lat: 48.3673134, lng: 18.0710545 },
    { lat: 48.3672984, lng: 18.0712971 },
    { lat: 48.3671842, lng: 18.0716339 },
    { lat: 48.3669261, lng: 18.0719645 },
    { lat: 48.3668513, lng: 18.0722474 },
    { lat: 48.3669876, lng: 18.0723979 },
    { lat: 48.3672807, lng: 18.0723504 },
    { lat: 48.3674667, lng: 18.0724247 },
    { lat: 48.3676136, lng: 18.0726373 },
    { lat: 48.3676306, lng: 18.0728501 },
    { lat: 48.367516, lng: 18.0736441 },
    { lat: 48.367608, lng: 18.0737088 },
    { lat: 48.3678975, lng: 18.0735845 },
    { lat: 48.3679522, lng: 18.0736336 },
    { lat: 48.3681306, lng: 18.073595 },
    { lat: 48.3687205, lng: 18.0732088 },
    { lat: 48.3689029, lng: 18.0731612 },
    { lat: 48.3695719, lng: 18.0733686 },
    { lat: 48.3696977, lng: 18.0735687 },
    { lat: 48.3697452, lng: 18.0741814 },
    { lat: 48.3696704, lng: 18.0745403 },
    { lat: 48.3694044, lng: 18.0747744 },
    { lat: 48.3692858, lng: 18.0750411 },
    { lat: 48.3692128, lng: 18.0754114 },
    { lat: 48.3692192, lng: 18.0758081 },
    { lat: 48.369611, lng: 18.0760592 },
    { lat: 48.370002, lng: 18.0761602 },
    { lat: 48.3700444, lng: 18.0764023 },
    { lat: 48.3701554, lng: 18.0764284 },
    { lat: 48.3704043, lng: 18.0763257 },
    { lat: 48.3707698, lng: 18.0757667 },
    { lat: 48.3709924, lng: 18.0749094 },
    { lat: 48.3711422, lng: 18.0747351 },
    { lat: 48.3714003, lng: 18.0749198 },
    { lat: 48.3713973, lng: 18.0753259 },
    { lat: 48.3713037, lng: 18.0755552 },
    { lat: 48.3713248, lng: 18.0756845 },
    { lat: 48.3719526, lng: 18.0759957 },
    { lat: 48.3721248, lng: 18.0761613 },
    { lat: 48.3723066, lng: 18.076545 },
    { lat: 48.3732699, lng: 18.0768071 },
    { lat: 48.3734516, lng: 18.076976 },
    { lat: 48.3735721, lng: 18.0771829 },
    { lat: 48.3736263, lng: 18.0774121 },
    { lat: 48.3736076, lng: 18.0775925 },
    { lat: 48.3734319, lng: 18.0779044 },
    { lat: 48.373276, lng: 18.0779209 },
    { lat: 48.3728947, lng: 18.0777546 },
    { lat: 48.3726022, lng: 18.0773857 },
    { lat: 48.3722776, lng: 18.0773919 },
    { lat: 48.372133, lng: 18.0776195 },
    { lat: 48.3721782, lng: 18.0779084 },
    { lat: 48.3723325, lng: 18.0781154 },
    { lat: 48.3728887, lng: 18.0785708 },
    { lat: 48.3729795, lng: 18.0787518 },
    { lat: 48.3730176, lng: 18.0791649 },
    { lat: 48.3728862, lng: 18.0794078 },
    { lat: 48.37278, lng: 18.0793459 },
    { lat: 48.3724107, lng: 18.0788466 },
    { lat: 48.3721366, lng: 18.0788116 },
    { lat: 48.371294, lng: 18.0794134 },
    { lat: 48.3712081, lng: 18.07962 },
    { lat: 48.371205, lng: 18.0797766 },
    { lat: 48.3714009, lng: 18.0801148 },
    { lat: 48.3715706, lng: 18.0802378 },
    { lat: 48.371983, lng: 18.0803214 },
    { lat: 48.3720932, lng: 18.0804651 },
    { lat: 48.372352, lng: 18.0810292 },
    { lat: 48.3723754, lng: 18.0812323 },
    { lat: 48.372287, lng: 18.0813218 },
    { lat: 48.3716233, lng: 18.0813407 },
    { lat: 48.3715626, lng: 18.0814222 },
    { lat: 48.3715342, lng: 18.0815907 },
    { lat: 48.3715573, lng: 18.0817896 },
    { lat: 48.371789, lng: 18.081969 },
    { lat: 48.372099, lng: 18.0820772 },
    { lat: 48.3722438, lng: 18.082238 },
    { lat: 48.372311, lng: 18.08242 },
    { lat: 48.3722133, lng: 18.082832 },
    { lat: 48.3722089, lng: 18.0831382 },
    { lat: 48.3722859, lng: 18.0833533 },
    { lat: 48.3724484, lng: 18.0835075 },
    { lat: 48.3728368, lng: 18.0829139 },
    { lat: 48.3731386, lng: 18.0828869 },
    { lat: 48.3732271, lng: 18.0830087 },
    { lat: 48.3733565, lng: 18.0837664 },
    { lat: 48.3734179, lng: 18.0839297 },
    { lat: 48.3736092, lng: 18.0841662 },
    { lat: 48.3736792, lng: 18.0847036 },
    { lat: 48.3737642, lng: 18.0849031 },
    { lat: 48.3739215, lng: 18.0850445 },
    { lat: 48.3743619, lng: 18.0851816 },
    { lat: 48.3744184, lng: 18.0851454 },
    { lat: 48.3744399, lng: 18.0849909 },
    { lat: 48.3740822, lng: 18.0843225 },
    { lat: 48.3740433, lng: 18.0840483 },
    { lat: 48.374152, lng: 18.0833814 },
    { lat: 48.3743246, lng: 18.083187 },
    { lat: 48.3744238, lng: 18.0832452 },
    { lat: 48.3744883, lng: 18.0834013 },
    { lat: 48.3745055, lng: 18.0836919 },
    { lat: 48.374663, lng: 18.0841264 },
    { lat: 48.3748072, lng: 18.0842333 },
    { lat: 48.3750241, lng: 18.0843149 },
    { lat: 48.3754357, lng: 18.0837747 },
    { lat: 48.3755636, lng: 18.0837055 },
    { lat: 48.3757239, lng: 18.083954 },
    { lat: 48.3768298, lng: 18.0843159 },
    { lat: 48.3769549, lng: 18.0844846 },
    { lat: 48.3769494, lng: 18.0856759 },
    { lat: 48.3769187, lng: 18.0858454 },
    { lat: 48.376438, lng: 18.0869742 },
    { lat: 48.3765198, lng: 18.087176 },
    { lat: 48.3769001, lng: 18.0875611 },
    { lat: 48.3770446, lng: 18.0877893 },
    { lat: 48.3774477, lng: 18.0886553 },
    { lat: 48.3778581, lng: 18.0888784 },
    { lat: 48.378128, lng: 18.0892281 },
    { lat: 48.3782039, lng: 18.0891925 },
    { lat: 48.378476, lng: 18.088391 },
    { lat: 48.3785931, lng: 18.0878093 },
    { lat: 48.3785795, lng: 18.0874057 },
    { lat: 48.3784412, lng: 18.0870683 },
    { lat: 48.3782828, lng: 18.0868529 },
    { lat: 48.3778782, lng: 18.0869013 },
    { lat: 48.3776353, lng: 18.0864756 },
    { lat: 48.3771856, lng: 18.0861969 },
    { lat: 48.3770924, lng: 18.0860308 },
    { lat: 48.3771139, lng: 18.0858224 },
    { lat: 48.3771891, lng: 18.085681 },
    { lat: 48.3776487, lng: 18.0851493 },
    { lat: 48.3778701, lng: 18.084444 },
    { lat: 48.3782193, lng: 18.0846827 },
    { lat: 48.3784744, lng: 18.0844362 },
    { lat: 48.378786, lng: 18.0844949 },
    { lat: 48.3788008, lng: 18.0848224 },
    { lat: 48.3785748, lng: 18.0855063 },
    { lat: 48.3785339, lng: 18.0857524 },
    { lat: 48.3785791, lng: 18.086078 },
    { lat: 48.3786836, lng: 18.0862626 },
    { lat: 48.3789176, lng: 18.0864605 },
    { lat: 48.3790834, lng: 18.0864967 },
    { lat: 48.3792353, lng: 18.0863541 },
    { lat: 48.3794346, lng: 18.0858498 },
    { lat: 48.3796097, lng: 18.0857754 },
    { lat: 48.3798321, lng: 18.0864634 },
    { lat: 48.3799879, lng: 18.0867725 },
    { lat: 48.3802178, lng: 18.0869586 },
    { lat: 48.3805555, lng: 18.0871179 },
    { lat: 48.3808043, lng: 18.0873958 },
    { lat: 48.3809741, lng: 18.0873843 },
    { lat: 48.3813057, lng: 18.0870542 },
    { lat: 48.3816946, lng: 18.0854738 },
    { lat: 48.3817357, lng: 18.085445 },
    { lat: 48.381796, lng: 18.0856617 },
    { lat: 48.3818646, lng: 18.0857349 },
    { lat: 48.3819608, lng: 18.0857215 },
    { lat: 48.3821596, lng: 18.0854962 },
    { lat: 48.3824476, lng: 18.085403 },
    { lat: 48.3825647, lng: 18.0854744 },
    { lat: 48.3828137, lng: 18.0861372 },
    { lat: 48.3830258, lng: 18.0863086 },
    { lat: 48.3831016, lng: 18.0862552 },
    { lat: 48.3831205, lng: 18.0859825 },
    { lat: 48.3830609, lng: 18.0855073 },
    { lat: 48.3830999, lng: 18.0849607 },
    { lat: 48.383158, lng: 18.0847591 },
    { lat: 48.3834108, lng: 18.0843013 },
    { lat: 48.3835914, lng: 18.0841072 },
    { lat: 48.3840552, lng: 18.0837829 },
    { lat: 48.3843327, lng: 18.0837244 },
    { lat: 48.3845657, lng: 18.083866 },
    { lat: 48.3847069, lng: 18.0840383 },
    { lat: 48.3847519, lng: 18.0841928 },
    { lat: 48.3847403, lng: 18.0843765 },
    { lat: 48.3843074, lng: 18.0852104 },
    { lat: 48.3842472, lng: 18.0854332 },
    { lat: 48.3843114, lng: 18.0856988 },
    { lat: 48.384373, lng: 18.0857101 },
    { lat: 48.3844782, lng: 18.0855605 },
    { lat: 48.3846357, lng: 18.0851711 },
    { lat: 48.384827, lng: 18.0848531 },
    { lat: 48.3850412, lng: 18.0845826 },
    { lat: 48.385149, lng: 18.0846307 },
    { lat: 48.3854373, lng: 18.0844266 },
    { lat: 48.3856826, lng: 18.0843742 },
    { lat: 48.3858962, lng: 18.0846476 },
    { lat: 48.3858319, lng: 18.0848816 },
    { lat: 48.3856267, lng: 18.0852082 },
    { lat: 48.3854163, lng: 18.0857399 },
    { lat: 48.3854301, lng: 18.0861634 },
    { lat: 48.3855134, lng: 18.0862557 },
    { lat: 48.3856695, lng: 18.0862602 },
    { lat: 48.3859075, lng: 18.0860692 },
    { lat: 48.386455, lng: 18.0861449 },
    { lat: 48.3867825, lng: 18.0860166 },
    { lat: 48.3868612, lng: 18.0860523 },
    { lat: 48.3869233, lng: 18.086452 },
    { lat: 48.3864625, lng: 18.0869275 },
    { lat: 48.3863875, lng: 18.0872038 },
    { lat: 48.3863434, lng: 18.0878168 },
    { lat: 48.3863584, lng: 18.088032 },
    { lat: 48.386436, lng: 18.0881815 },
    { lat: 48.3865751, lng: 18.0882454 },
    { lat: 48.3866679, lng: 18.0881717 },
    { lat: 48.3868117, lng: 18.0874528 },
    { lat: 48.3869222, lng: 18.0872773 },
    { lat: 48.387296, lng: 18.0875323 },
    { lat: 48.3876572, lng: 18.0875891 },
    { lat: 48.3877914, lng: 18.0877996 },
    { lat: 48.3878855, lng: 18.0880726 },
    { lat: 48.3879123, lng: 18.0882998 },
    { lat: 48.3877257, lng: 18.0889257 },
    { lat: 48.3876497, lng: 18.0889962 },
    { lat: 48.3874391, lng: 18.089023 },
    { lat: 48.3870092, lng: 18.088871 },
    { lat: 48.3867174, lng: 18.0888994 },
    { lat: 48.3865441, lng: 18.0890612 },
    { lat: 48.3864039, lng: 18.0895155 },
    { lat: 48.3869549, lng: 18.0895957 },
    { lat: 48.3873066, lng: 18.0897248 },
    { lat: 48.3880309, lng: 18.0892414 },
    { lat: 48.388446, lng: 18.0892548 },
    { lat: 48.3890944, lng: 18.0895143 },
    { lat: 48.3894998, lng: 18.0901351 },
    { lat: 48.3889493, lng: 18.0909989 },
    { lat: 48.389305, lng: 18.0914051 },
    { lat: 48.3893533, lng: 18.0914584 },
    { lat: 48.3904017, lng: 18.0903865 },
    { lat: 48.3913406, lng: 18.089061 },
    { lat: 48.3918992, lng: 18.0879674 },
    { lat: 48.3923497, lng: 18.0864571 },
    { lat: 48.3927409, lng: 18.0845508 },
    { lat: 48.3932151, lng: 18.0818725 },
    { lat: 48.3933294, lng: 18.081227 },
    { lat: 48.3935531, lng: 18.0804843 },
    { lat: 48.3935861, lng: 18.0798563 },
    { lat: 48.39377, lng: 18.0787442 },
    { lat: 48.3940185, lng: 18.0777324 },
    { lat: 48.3941801, lng: 18.0775647 },
    { lat: 48.3954783, lng: 18.0728611 },
    { lat: 48.3961377, lng: 18.073267 },
    { lat: 48.3976972, lng: 18.0682353 },
    { lat: 48.4023223, lng: 18.0541604 },
    { lat: 48.4032071, lng: 18.0520785 },
    { lat: 48.4044559, lng: 18.0496094 },
    { lat: 48.4055627, lng: 18.0476799 },
    { lat: 48.4069137, lng: 18.0457365 },
    { lat: 48.4072988, lng: 18.0448822 },
    { lat: 48.4073068, lng: 18.044862 },
    { lat: 48.4063671, lng: 18.0446604 },
    { lat: 48.4057776, lng: 18.0446546 },
    { lat: 48.405103, lng: 18.0449291 },
    { lat: 48.4046465, lng: 18.0450153 },
    { lat: 48.403182, lng: 18.0450988 },
    { lat: 48.4020829, lng: 18.0453689 },
    { lat: 48.4014883, lng: 18.0454274 },
    { lat: 48.3993602, lng: 18.0450916 },
    { lat: 48.3985552, lng: 18.0452605 },
    { lat: 48.3975855, lng: 18.0448165 },
    { lat: 48.3959263, lng: 18.0445122 },
    { lat: 48.3935507, lng: 18.0437355 },
    { lat: 48.3931224, lng: 18.0435356 },
    { lat: 48.3925761, lng: 18.0434001 },
    { lat: 48.3920764, lng: 18.043365 },
    { lat: 48.3919708, lng: 18.0433755 },
  ],
  Štefanovičová: [
    { lat: 48.207077, lng: 18.1215285 },
    { lat: 48.2070974, lng: 18.1215099 },
    { lat: 48.2089884, lng: 18.1193909 },
    { lat: 48.210634, lng: 18.1179932 },
    { lat: 48.2125518, lng: 18.1167532 },
    { lat: 48.2140701, lng: 18.1151861 },
    { lat: 48.2151358, lng: 18.1139661 },
    { lat: 48.2145154, lng: 18.1128186 },
    { lat: 48.2125336, lng: 18.1088291 },
    { lat: 48.2125027, lng: 18.1088632 },
    { lat: 48.2100446, lng: 18.1039175 },
    { lat: 48.2114451, lng: 18.1024441 },
    { lat: 48.2085911, lng: 18.0962841 },
    { lat: 48.2064708, lng: 18.098355 },
    { lat: 48.2046318, lng: 18.0944419 },
    { lat: 48.2036954, lng: 18.0954051 },
    { lat: 48.2020363, lng: 18.0918666 },
    { lat: 48.1987338, lng: 18.0952447 },
    { lat: 48.1983643, lng: 18.0942619 },
    { lat: 48.196818, lng: 18.0974138 },
    { lat: 48.19661, lng: 18.0977921 },
    { lat: 48.1965828, lng: 18.0977519 },
    { lat: 48.193086, lng: 18.0911504 },
    { lat: 48.1827249, lng: 18.0719248 },
    { lat: 48.1835773, lng: 18.0710925 },
    { lat: 48.1832324, lng: 18.0689683 },
    { lat: 48.183625, lng: 18.0672322 },
    { lat: 48.1819134, lng: 18.0637035 },
    { lat: 48.1814903, lng: 18.0628091 },
    { lat: 48.1810731, lng: 18.0631961 },
    { lat: 48.1757471, lng: 18.0645825 },
    { lat: 48.1779101, lng: 18.0723412 },
    { lat: 48.1764106, lng: 18.072408 },
    { lat: 48.1716983, lng: 18.0775449 },
    { lat: 48.166214, lng: 18.082264 },
    { lat: 48.16628, lng: 18.082401 },
    { lat: 48.166353, lng: 18.082552 },
    { lat: 48.167362, lng: 18.084629 },
    { lat: 48.169025, lng: 18.088022 },
    { lat: 48.16927, lng: 18.088542 },
    { lat: 48.169851, lng: 18.089749 },
    { lat: 48.170524, lng: 18.091129 },
    { lat: 48.170576, lng: 18.091237 },
    { lat: 48.169713, lng: 18.09181 },
    { lat: 48.169655, lng: 18.091849 },
    { lat: 48.170023, lng: 18.092511 },
    { lat: 48.170453, lng: 18.093304 },
    { lat: 48.17082, lng: 18.093972 },
    { lat: 48.17101, lng: 18.094326 },
    { lat: 48.171301, lng: 18.094874 },
    { lat: 48.171584, lng: 18.095404 },
    { lat: 48.171902, lng: 18.096005 },
    { lat: 48.172174, lng: 18.096507 },
    { lat: 48.172231, lng: 18.096611 },
    { lat: 48.172454, lng: 18.097025 },
    { lat: 48.172735, lng: 18.09755 },
    { lat: 48.172979, lng: 18.097989 },
    { lat: 48.173202, lng: 18.098405 },
    { lat: 48.173441, lng: 18.098846 },
    { lat: 48.173657, lng: 18.099235 },
    { lat: 48.173961, lng: 18.099808 },
    { lat: 48.174175, lng: 18.100202 },
    { lat: 48.174385, lng: 18.1006 },
    { lat: 48.174651, lng: 18.101094 },
    { lat: 48.174921, lng: 18.101596 },
    { lat: 48.175197, lng: 18.102121 },
    { lat: 48.175431, lng: 18.102556 },
    { lat: 48.175707, lng: 18.103056 },
    { lat: 48.175895, lng: 18.103402 },
    { lat: 48.176166, lng: 18.103914 },
    { lat: 48.176283, lng: 18.104137 },
    { lat: 48.176618, lng: 18.104754 },
    { lat: 48.177016, lng: 18.105478 },
    { lat: 48.177377, lng: 18.106134 },
    { lat: 48.177705, lng: 18.106733 },
    { lat: 48.177982, lng: 18.10725 },
    { lat: 48.178312, lng: 18.107854 },
    { lat: 48.178635, lng: 18.108448 },
    { lat: 48.178905, lng: 18.108987 },
    { lat: 48.179187, lng: 18.109498 },
    { lat: 48.179469, lng: 18.110023 },
    { lat: 48.179575, lng: 18.110214 },
    { lat: 48.179803, lng: 18.110624 },
    { lat: 48.180123, lng: 18.111225 },
    { lat: 48.180356, lng: 18.111644 },
    { lat: 48.180588, lng: 18.112075 },
    { lat: 48.180858, lng: 18.112579 },
    { lat: 48.181151, lng: 18.113106 },
    { lat: 48.181403, lng: 18.113588 },
    { lat: 48.181651, lng: 18.114041 },
    { lat: 48.181853, lng: 18.114402 },
    { lat: 48.182098, lng: 18.115015 },
    { lat: 48.182273, lng: 18.115456 },
    { lat: 48.182541, lng: 18.116124 },
    { lat: 48.182721, lng: 18.116554 },
    { lat: 48.182858, lng: 18.116891 },
    { lat: 48.18315, lng: 18.117613 },
    { lat: 48.183299, lng: 18.117982 },
    { lat: 48.183508, lng: 18.118493 },
    { lat: 48.183677, lng: 18.118901 },
    { lat: 48.18385, lng: 18.119338 },
    { lat: 48.184035, lng: 18.119767 },
    { lat: 48.184117, lng: 18.119985 },
    { lat: 48.184248, lng: 18.120303 },
    { lat: 48.184494, lng: 18.120907 },
    { lat: 48.18464, lng: 18.121253 },
    { lat: 48.184861, lng: 18.121799 },
    { lat: 48.185028, lng: 18.122194 },
    { lat: 48.185188, lng: 18.122595 },
    { lat: 48.185415, lng: 18.12315 },
    { lat: 48.185483, lng: 18.123324 },
    { lat: 48.1856, lng: 18.123598 },
    { lat: 48.185767, lng: 18.124007 },
    { lat: 48.185925, lng: 18.124399 },
    { lat: 48.186061, lng: 18.124731 },
    { lat: 48.18618, lng: 18.12501 },
    { lat: 48.186387, lng: 18.125525 },
    { lat: 48.186568, lng: 18.125962 },
    { lat: 48.186795, lng: 18.126507 },
    { lat: 48.187, lng: 18.127007 },
    { lat: 48.187243, lng: 18.127599 },
    { lat: 48.187371, lng: 18.1279 },
    { lat: 48.187631, lng: 18.12852 },
    { lat: 48.187908, lng: 18.129194 },
    { lat: 48.188041, lng: 18.129509 },
    { lat: 48.188283, lng: 18.130102 },
    { lat: 48.188596, lng: 18.130876 },
    { lat: 48.188915, lng: 18.131661 },
    { lat: 48.189147, lng: 18.132234 },
    { lat: 48.189408, lng: 18.132906 },
    { lat: 48.189784, lng: 18.133861 },
    { lat: 48.189808, lng: 18.133913 },
    { lat: 48.190056, lng: 18.133824 },
    { lat: 48.190346, lng: 18.133605 },
    { lat: 48.190721, lng: 18.13333 },
    { lat: 48.190995, lng: 18.133126 },
    { lat: 48.191305, lng: 18.132871 },
    { lat: 48.191609, lng: 18.132602 },
    { lat: 48.191718, lng: 18.132506 },
    { lat: 48.191732, lng: 18.132569 },
    { lat: 48.191913, lng: 18.132408 },
    { lat: 48.192158, lng: 18.132188 },
    { lat: 48.19241, lng: 18.131983 },
    { lat: 48.19267, lng: 18.13174 },
    { lat: 48.192736, lng: 18.131706 },
    { lat: 48.192891, lng: 18.131645 },
    { lat: 48.193086, lng: 18.131634 },
    { lat: 48.19322, lng: 18.131539 },
    { lat: 48.193316, lng: 18.131501 },
    { lat: 48.1935962, lng: 18.1313743 },
    { lat: 48.1937998, lng: 18.1311725 },
    { lat: 48.1946402, lng: 18.130087 },
    { lat: 48.1953935, lng: 18.1293895 },
    { lat: 48.1972082, lng: 18.1288682 },
    { lat: 48.2002874, lng: 18.1275148 },
    { lat: 48.2033383, lng: 18.1251607 },
    { lat: 48.2036332, lng: 18.1254746 },
    { lat: 48.2040327, lng: 18.1261282 },
    { lat: 48.2045026, lng: 18.1275165 },
    { lat: 48.2050677, lng: 18.1268589 },
    { lat: 48.2051989, lng: 18.1232724 },
    { lat: 48.2060485, lng: 18.1225787 },
    { lat: 48.207077, lng: 18.1215285 },
  ],
  Zbehy: [
    { lat: 48.3776162, lng: 17.9609485 },
    { lat: 48.3776164, lng: 17.9609683 },
    { lat: 48.3771417, lng: 17.9626153 },
    { lat: 48.3766239, lng: 17.9638066 },
    { lat: 48.3759535, lng: 17.9629762 },
    { lat: 48.3756011, lng: 17.962618 },
    { lat: 48.3754771, lng: 17.962562 },
    { lat: 48.3742047, lng: 17.9642862 },
    { lat: 48.3692803, lng: 17.9695627 },
    { lat: 48.3668984, lng: 17.9716492 },
    { lat: 48.3666145, lng: 17.9717738 },
    { lat: 48.3660841, lng: 17.9717758 },
    { lat: 48.3656489, lng: 17.9718744 },
    { lat: 48.3653413, lng: 17.9720341 },
    { lat: 48.365036, lng: 17.9723078 },
    { lat: 48.3649607, lng: 17.9724649 },
    { lat: 48.3648309, lng: 17.9731643 },
    { lat: 48.364683, lng: 17.974351 },
    { lat: 48.3643601, lng: 17.9754796 },
    { lat: 48.3639874, lng: 17.9774353 },
    { lat: 48.3653293, lng: 17.9794334 },
    { lat: 48.365222, lng: 17.9796005 },
    { lat: 48.3650448, lng: 17.9796947 },
    { lat: 48.3650093, lng: 17.9798368 },
    { lat: 48.3646514, lng: 17.9804562 },
    { lat: 48.3641752, lng: 17.9810979 },
    { lat: 48.3627569, lng: 17.9825594 },
    { lat: 48.3619602, lng: 17.9831871 },
    { lat: 48.3603275, lng: 17.9847806 },
    { lat: 48.3598662, lng: 17.9852967 },
    { lat: 48.3594993, lng: 17.9854849 },
    { lat: 48.3591457, lng: 17.9857983 },
    { lat: 48.359097, lng: 17.9860029 },
    { lat: 48.3589429, lng: 17.986144 },
    { lat: 48.3586969, lng: 17.9862232 },
    { lat: 48.3584052, lng: 17.986495 },
    { lat: 48.3582592, lng: 17.986509 },
    { lat: 48.3578056, lng: 17.9869654 },
    { lat: 48.3577264, lng: 17.9871521 },
    { lat: 48.35759, lng: 17.9871716 },
    { lat: 48.3570317, lng: 17.9874785 },
    { lat: 48.3567357, lng: 17.9870779 },
    { lat: 48.3553707, lng: 17.9856378 },
    { lat: 48.3543162, lng: 17.9849919 },
    { lat: 48.3532927, lng: 17.9838629 },
    { lat: 48.3526793, lng: 17.9834232 },
    { lat: 48.3520874, lng: 17.9826221 },
    { lat: 48.351869, lng: 17.9824234 },
    { lat: 48.351746, lng: 17.9823878 },
    { lat: 48.351175, lng: 17.9825211 },
    { lat: 48.3509132, lng: 17.982522 },
    { lat: 48.3495517, lng: 17.9830666 },
    { lat: 48.3494186, lng: 17.983163 },
    { lat: 48.3485921, lng: 17.9843264 },
    { lat: 48.3479932, lng: 17.984879 },
    { lat: 48.3478335, lng: 17.9851553 },
    { lat: 48.3476613, lng: 17.985314 },
    { lat: 48.3469384, lng: 17.9853237 },
    { lat: 48.3463507, lng: 17.984954 },
    { lat: 48.3461003, lng: 17.9848997 },
    { lat: 48.3457016, lng: 17.9850631 },
    { lat: 48.3449646, lng: 17.9851779 },
    { lat: 48.344532, lng: 17.9847944 },
    { lat: 48.3443133, lng: 17.9846891 },
    { lat: 48.3433458, lng: 17.9850031 },
    { lat: 48.3428264, lng: 17.9849631 },
    { lat: 48.3424898, lng: 17.9848477 },
    { lat: 48.3422442, lng: 17.9846282 },
    { lat: 48.3417611, lng: 17.9840056 },
    { lat: 48.341354, lng: 17.9837493 },
    { lat: 48.3410723, lng: 17.9833012 },
    { lat: 48.3407048, lng: 17.9828955 },
    { lat: 48.3407335, lng: 17.9837907 },
    { lat: 48.3405942, lng: 17.9847985 },
    { lat: 48.3405196, lng: 17.985857 },
    { lat: 48.3405624, lng: 17.9874176 },
    { lat: 48.3404864, lng: 17.9883729 },
    { lat: 48.3404053, lng: 17.9887369 },
    { lat: 48.3402066, lng: 17.9892081 },
    { lat: 48.3399177, lng: 17.9908321 },
    { lat: 48.3398103, lng: 17.9912067 },
    { lat: 48.3396003, lng: 17.9927909 },
    { lat: 48.3393339, lng: 17.9934544 },
    { lat: 48.3390014, lng: 17.9939688 },
    { lat: 48.3385824, lng: 17.9942284 },
    { lat: 48.3382573, lng: 17.994325 },
    { lat: 48.3373756, lng: 17.9944325 },
    { lat: 48.3364976, lng: 17.9948163 },
    { lat: 48.3359737, lng: 17.994963 },
    { lat: 48.3347456, lng: 17.995773 },
    { lat: 48.3340611, lng: 17.9961441 },
    { lat: 48.3336418, lng: 17.9962838 },
    { lat: 48.3318337, lng: 17.9973622 },
    { lat: 48.3312365, lng: 17.9975315 },
    { lat: 48.3298338, lng: 17.9981034 },
    { lat: 48.3292905, lng: 17.9982462 },
    { lat: 48.3295845, lng: 17.9989193 },
    { lat: 48.3300382, lng: 18.000163 },
    { lat: 48.3305876, lng: 18.0012916 },
    { lat: 48.3312893, lng: 18.0032724 },
    { lat: 48.3321987, lng: 18.0050839 },
    { lat: 48.3324066, lng: 18.0053372 },
    { lat: 48.3326563, lng: 18.0056414 },
    { lat: 48.3327625, lng: 18.0058513 },
    { lat: 48.3329021, lng: 18.0059192 },
    { lat: 48.3330907, lng: 18.006128 },
    { lat: 48.333378, lng: 18.0061029 },
    { lat: 48.3338405, lng: 18.0062324 },
    { lat: 48.3340014, lng: 18.0063555 },
    { lat: 48.3342292, lng: 18.0067095 },
    { lat: 48.3347681, lng: 18.0069646 },
    { lat: 48.3351449, lng: 18.0075483 },
    { lat: 48.3355098, lng: 18.0083287 },
    { lat: 48.3364413, lng: 18.0090363 },
    { lat: 48.3365932, lng: 18.009216 },
    { lat: 48.3380383, lng: 18.0110044 },
    { lat: 48.3389602, lng: 18.0122665 },
    { lat: 48.3398576, lng: 18.0136131 },
    { lat: 48.3402829, lng: 18.0135145 },
    { lat: 48.3427214, lng: 18.0140941 },
    { lat: 48.3431637, lng: 18.0137137 },
    { lat: 48.3441926, lng: 18.0130396 },
    { lat: 48.3444852, lng: 18.0120453 },
    { lat: 48.3450168, lng: 18.0115507 },
    { lat: 48.3457815, lng: 18.0110486 },
    { lat: 48.3460091, lng: 18.0110209 },
    { lat: 48.346182, lng: 18.0111017 },
    { lat: 48.3466404, lng: 18.0115018 },
    { lat: 48.3469091, lng: 18.0115626 },
    { lat: 48.347065, lng: 18.0115073 },
    { lat: 48.3474342, lng: 18.0107343 },
    { lat: 48.3476407, lng: 18.0101077 },
    { lat: 48.3478623, lng: 18.0088055 },
    { lat: 48.3479491, lng: 18.0085005 },
    { lat: 48.3480735, lng: 18.008278 },
    { lat: 48.3485278, lng: 18.0080619 },
    { lat: 48.3489933, lng: 18.0086552 },
    { lat: 48.3493494, lng: 18.0088001 },
    { lat: 48.3504894, lng: 18.0083629 },
    { lat: 48.3520204, lng: 18.0081815 },
    { lat: 48.3522314, lng: 18.0082021 },
    { lat: 48.3523444, lng: 18.0082956 },
    { lat: 48.352836, lng: 18.0090645 },
    { lat: 48.3530828, lng: 18.0096056 },
    { lat: 48.3533664, lng: 18.0105775 },
    { lat: 48.3539998, lng: 18.0116419 },
    { lat: 48.3542563, lng: 18.0119701 },
    { lat: 48.3546667, lng: 18.0123242 },
    { lat: 48.3554579, lng: 18.0131734 },
    { lat: 48.3558853, lng: 18.013718 },
    { lat: 48.356338, lng: 18.0144969 },
    { lat: 48.3571501, lng: 18.0171404 },
    { lat: 48.3573157, lng: 18.0172694 },
    { lat: 48.3573502, lng: 18.0174678 },
    { lat: 48.3574264, lng: 18.017575 },
    { lat: 48.357454, lng: 18.0177931 },
    { lat: 48.35769, lng: 18.0185508 },
    { lat: 48.3577812, lng: 18.0187167 },
    { lat: 48.3578696, lng: 18.0191406 },
    { lat: 48.3584543, lng: 18.0207753 },
    { lat: 48.3587686, lng: 18.021919 },
    { lat: 48.3589927, lng: 18.0224819 },
    { lat: 48.3600429, lng: 18.0254302 },
    { lat: 48.3591781, lng: 18.0272506 },
    { lat: 48.3568808, lng: 18.0318016 },
    { lat: 48.3556406, lng: 18.0334955 },
    { lat: 48.3554373, lng: 18.0359822 },
    { lat: 48.3549099, lng: 18.0394011 },
    { lat: 48.354156, lng: 18.0448611 },
    { lat: 48.3546735, lng: 18.0452533 },
    { lat: 48.3547092, lng: 18.0453401 },
    { lat: 48.354829, lng: 18.0461243 },
    { lat: 48.3548558, lng: 18.0470518 },
    { lat: 48.3548583, lng: 18.0471491 },
    { lat: 48.3558465, lng: 18.0460374 },
    { lat: 48.357307, lng: 18.0471041 },
    { lat: 48.3577252, lng: 18.0456295 },
    { lat: 48.3579748, lng: 18.0451962 },
    { lat: 48.3585168, lng: 18.0447445 },
    { lat: 48.3587942, lng: 18.0442043 },
    { lat: 48.3588237, lng: 18.0440956 },
    { lat: 48.3587034, lng: 18.0438935 },
    { lat: 48.3589392, lng: 18.0427892 },
    { lat: 48.3591022, lng: 18.041503 },
    { lat: 48.3592146, lng: 18.0396294 },
    { lat: 48.3594047, lng: 18.037776 },
    { lat: 48.3597163, lng: 18.0358368 },
    { lat: 48.3607033, lng: 18.0352357 },
    { lat: 48.3626781, lng: 18.0336741 },
    { lat: 48.3642854, lng: 18.0315283 },
    { lat: 48.3651156, lng: 18.0305686 },
    { lat: 48.3667472, lng: 18.0291183 },
    { lat: 48.3692817, lng: 18.0283113 },
    { lat: 48.3703493, lng: 18.0270946 },
    { lat: 48.3712485, lng: 18.0279819 },
    { lat: 48.3715656, lng: 18.0273903 },
    { lat: 48.3722785, lng: 18.0263013 },
    { lat: 48.3727419, lng: 18.0262549 },
    { lat: 48.3738703, lng: 18.0262919 },
    { lat: 48.3739798, lng: 18.0254164 },
    { lat: 48.3744436, lng: 18.025492 },
    { lat: 48.3749905, lng: 18.0213834 },
    { lat: 48.3748822, lng: 18.0213248 },
    { lat: 48.374886, lng: 18.0212565 },
    { lat: 48.3754004, lng: 18.0213477 },
    { lat: 48.3755096, lng: 18.0215499 },
    { lat: 48.3758396, lng: 18.0215948 },
    { lat: 48.3760303, lng: 18.0218622 },
    { lat: 48.376569, lng: 18.0221152 },
    { lat: 48.3767734, lng: 18.0221531 },
    { lat: 48.3769435, lng: 18.0223011 },
    { lat: 48.3773211, lng: 18.0222554 },
    { lat: 48.3775867, lng: 18.0221471 },
    { lat: 48.3778349, lng: 18.0222572 },
    { lat: 48.378242, lng: 18.0230295 },
    { lat: 48.3784229, lng: 18.0231679 },
    { lat: 48.3786622, lng: 18.0232065 },
    { lat: 48.3789697, lng: 18.0235583 },
    { lat: 48.3789906, lng: 18.0237749 },
    { lat: 48.3791627, lng: 18.0241473 },
    { lat: 48.3796067, lng: 18.0241912 },
    { lat: 48.3799042, lng: 18.0241077 },
    { lat: 48.3800883, lng: 18.0243198 },
    { lat: 48.3803831, lng: 18.0243421 },
    { lat: 48.3804766, lng: 18.0244513 },
    { lat: 48.3807948, lng: 18.0245834 },
    { lat: 48.3811208, lng: 18.0246171 },
    { lat: 48.3814747, lng: 18.0245147 },
    { lat: 48.382092, lng: 18.0245154 },
    { lat: 48.3829266, lng: 18.024694 },
    { lat: 48.3831711, lng: 18.0248956 },
    { lat: 48.3839325, lng: 18.0252112 },
    { lat: 48.3841971, lng: 18.025464 },
    { lat: 48.3847081, lng: 18.0255738 },
    { lat: 48.3848177, lng: 18.0256522 },
    { lat: 48.3850121, lng: 18.025656 },
    { lat: 48.3859035, lng: 18.0260138 },
    { lat: 48.386392, lng: 18.0262942 },
    { lat: 48.3870361, lng: 18.0268964 },
    { lat: 48.3874547, lng: 18.0271628 },
    { lat: 48.3878992, lng: 18.027387 },
    { lat: 48.3879786, lng: 18.0274861 },
    { lat: 48.3887606, lng: 18.0277897 },
    { lat: 48.3894898, lng: 18.0279218 },
    { lat: 48.3906712, lng: 18.0279386 },
    { lat: 48.3915734, lng: 18.0275838 },
    { lat: 48.3918478, lng: 18.0273222 },
    { lat: 48.3920067, lng: 18.0275037 },
    { lat: 48.3920843, lng: 18.0275196 },
    { lat: 48.392137, lng: 18.0274952 },
    { lat: 48.3923899, lng: 18.0272859 },
    { lat: 48.3921635, lng: 18.0248648 },
    { lat: 48.3921049, lng: 18.0228523 },
    { lat: 48.3922413, lng: 18.0205513 },
    { lat: 48.3922512, lng: 18.02036 },
    { lat: 48.3922591, lng: 18.0199395 },
    { lat: 48.3922445, lng: 18.0180998 },
    { lat: 48.3917289, lng: 18.0174598 },
    { lat: 48.3915486, lng: 18.0171402 },
    { lat: 48.3901568, lng: 18.0143105 },
    { lat: 48.3894322, lng: 18.0131564 },
    { lat: 48.3882792, lng: 18.010737 },
    { lat: 48.3876366, lng: 18.009558 },
    { lat: 48.3872729, lng: 18.0087571 },
    { lat: 48.3858878, lng: 18.0062345 },
    { lat: 48.3851184, lng: 18.0040604 },
    { lat: 48.3858659, lng: 18.0024391 },
    { lat: 48.3865888, lng: 18.0013107 },
    { lat: 48.3867393, lng: 18.0009801 },
    { lat: 48.3868409, lng: 18.0008272 },
    { lat: 48.3869686, lng: 18.0007554 },
    { lat: 48.3870147, lng: 18.000545 },
    { lat: 48.3871737, lng: 18.0002569 },
    { lat: 48.3882159, lng: 17.9975582 },
    { lat: 48.3885537, lng: 17.9964294 },
    { lat: 48.3888591, lng: 17.9956052 },
    { lat: 48.3888912, lng: 17.9952959 },
    { lat: 48.3888694, lng: 17.9948546 },
    { lat: 48.3887251, lng: 17.9943275 },
    { lat: 48.3886713, lng: 17.9942123 },
    { lat: 48.3875243, lng: 17.9917576 },
    { lat: 48.3872521, lng: 17.9917385 },
    { lat: 48.3870948, lng: 17.9913538 },
    { lat: 48.385979, lng: 17.9924159 },
    { lat: 48.3854615, lng: 17.992618 },
    { lat: 48.3849431, lng: 17.9926867 },
    { lat: 48.385178, lng: 17.9915195 },
    { lat: 48.3851481, lng: 17.9902978 },
    { lat: 48.3851959, lng: 17.9900218 },
    { lat: 48.3851258, lng: 17.9890924 },
    { lat: 48.3850429, lng: 17.9887833 },
    { lat: 48.3850008, lng: 17.9879829 },
    { lat: 48.384905, lng: 17.9873254 },
    { lat: 48.3845803, lng: 17.9862939 },
    { lat: 48.3844483, lng: 17.9850309 },
    { lat: 48.3844593, lng: 17.9847052 },
    { lat: 48.3843781, lng: 17.9844378 },
    { lat: 48.3843761, lng: 17.9841039 },
    { lat: 48.3842499, lng: 17.9837854 },
    { lat: 48.3842122, lng: 17.9829897 },
    { lat: 48.3843948, lng: 17.982436 },
    { lat: 48.3844271, lng: 17.9822406 },
    { lat: 48.3846443, lng: 17.9817945 },
    { lat: 48.3847155, lng: 17.9814184 },
    { lat: 48.3850158, lng: 17.9809309 },
    { lat: 48.3851516, lng: 17.9800664 },
    { lat: 48.3851549, lng: 17.9797648 },
    { lat: 48.3853805, lng: 17.9788747 },
    { lat: 48.3853611, lng: 17.9785904 },
    { lat: 48.3855564, lng: 17.9779298 },
    { lat: 48.3868347, lng: 17.9785446 },
    { lat: 48.3887288, lng: 17.9772053 },
    { lat: 48.3915123, lng: 17.9750561 },
    { lat: 48.3912278, lng: 17.9738135 },
    { lat: 48.3904836, lng: 17.9709798 },
    { lat: 48.390066, lng: 17.9695833 },
    { lat: 48.3894757, lng: 17.9680565 },
    { lat: 48.3894476, lng: 17.9680031 },
    { lat: 48.3866863, lng: 17.9699742 },
    { lat: 48.3859821, lng: 17.9706368 },
    { lat: 48.3858367, lng: 17.9704717 },
    { lat: 48.3858105, lng: 17.9701298 },
    { lat: 48.3857552, lng: 17.970036 },
    { lat: 48.385569, lng: 17.9698456 },
    { lat: 48.3854854, lng: 17.9695984 },
    { lat: 48.385244, lng: 17.9694141 },
    { lat: 48.385036, lng: 17.9693534 },
    { lat: 48.3845829, lng: 17.9690787 },
    { lat: 48.3844005, lng: 17.9690852 },
    { lat: 48.3842576, lng: 17.968982 },
    { lat: 48.3841094, lng: 17.9687524 },
    { lat: 48.3834757, lng: 17.9686281 },
    { lat: 48.3833116, lng: 17.9687359 },
    { lat: 48.3829184, lng: 17.9683916 },
    { lat: 48.382711, lng: 17.9683557 },
    { lat: 48.3825563, lng: 17.9681313 },
    { lat: 48.3821939, lng: 17.9679608 },
    { lat: 48.382094, lng: 17.9678166 },
    { lat: 48.3819355, lng: 17.9678274 },
    { lat: 48.3817937, lng: 17.9676644 },
    { lat: 48.3816096, lng: 17.9672486 },
    { lat: 48.3813466, lng: 17.9669692 },
    { lat: 48.3812464, lng: 17.9669544 },
    { lat: 48.3811581, lng: 17.9668147 },
    { lat: 48.3809612, lng: 17.9667344 },
    { lat: 48.3808235, lng: 17.9667896 },
    { lat: 48.380681, lng: 17.9666537 },
    { lat: 48.3803893, lng: 17.9666278 },
    { lat: 48.3800915, lng: 17.9664979 },
    { lat: 48.3800231, lng: 17.9664099 },
    { lat: 48.3799818, lng: 17.9661158 },
    { lat: 48.3798191, lng: 17.965908 },
    { lat: 48.3798292, lng: 17.9657171 },
    { lat: 48.3797339, lng: 17.9655797 },
    { lat: 48.3797222, lng: 17.9651585 },
    { lat: 48.3795594, lng: 17.9647203 },
    { lat: 48.3796135, lng: 17.9646444 },
    { lat: 48.3795396, lng: 17.9644847 },
    { lat: 48.3796031, lng: 17.9644314 },
    { lat: 48.3796151, lng: 17.9642767 },
    { lat: 48.3795167, lng: 17.9641775 },
    { lat: 48.3795103, lng: 17.9638822 },
    { lat: 48.3793814, lng: 17.9632943 },
    { lat: 48.3792377, lng: 17.9630442 },
    { lat: 48.379213, lng: 17.9628214 },
    { lat: 48.3791254, lng: 17.9626223 },
    { lat: 48.3789399, lng: 17.9623136 },
    { lat: 48.3788539, lng: 17.9623309 },
    { lat: 48.3788422, lng: 17.9621522 },
    { lat: 48.3786945, lng: 17.9621182 },
    { lat: 48.3785505, lng: 17.9616945 },
    { lat: 48.3784488, lng: 17.9616145 },
    { lat: 48.3784237, lng: 17.9614613 },
    { lat: 48.3782636, lng: 17.9612962 },
    { lat: 48.3782042, lng: 17.9611338 },
    { lat: 48.3778114, lng: 17.9609462 },
    { lat: 48.3776162, lng: 17.9609485 },
  ],
  Čeľadice: [
    { lat: 48.337396, lng: 18.243806 },
    { lat: 48.3375253, lng: 18.2432042 },
    { lat: 48.3374808, lng: 18.2431174 },
    { lat: 48.3375829, lng: 18.2426988 },
    { lat: 48.3379126, lng: 18.2418209 },
    { lat: 48.3381802, lng: 18.2408414 },
    { lat: 48.3387695, lng: 18.2379863 },
    { lat: 48.3388089, lng: 18.2371233 },
    { lat: 48.3390493, lng: 18.2359689 },
    { lat: 48.3391266, lng: 18.234786 },
    { lat: 48.3390975, lng: 18.2342975 },
    { lat: 48.3387998, lng: 18.2322471 },
    { lat: 48.338791, lng: 18.2315656 },
    { lat: 48.3388498, lng: 18.230806 },
    { lat: 48.3390184, lng: 18.2293868 },
    { lat: 48.3392398, lng: 18.228393 },
    { lat: 48.3396246, lng: 18.2272602 },
    { lat: 48.3397805, lng: 18.2266544 },
    { lat: 48.3397401, lng: 18.2266377 },
    { lat: 48.3384021, lng: 18.2282669 },
    { lat: 48.3380022, lng: 18.2285738 },
    { lat: 48.3373796, lng: 18.2292412 },
    { lat: 48.337046, lng: 18.2287008 },
    { lat: 48.3367714, lng: 18.2273233 },
    { lat: 48.3330078, lng: 18.2320735 },
    { lat: 48.331751, lng: 18.2294535 },
    { lat: 48.3311502, lng: 18.230307 },
    { lat: 48.3296991, lng: 18.2269432 },
    { lat: 48.3289988, lng: 18.225621 },
    { lat: 48.328249, lng: 18.2244585 },
    { lat: 48.328063, lng: 18.2247961 },
    { lat: 48.3270394, lng: 18.2229575 },
    { lat: 48.326406, lng: 18.2236557 },
    { lat: 48.326371, lng: 18.2235166 },
    { lat: 48.3259969, lng: 18.2230979 },
    { lat: 48.3252384, lng: 18.2220181 },
    { lat: 48.3249864, lng: 18.2217211 },
    { lat: 48.3248229, lng: 18.2217019 },
    { lat: 48.3244574, lng: 18.2211548 },
    { lat: 48.3235329, lng: 18.2193716 },
    { lat: 48.3222477, lng: 18.2170921 },
    { lat: 48.3205075, lng: 18.2143742 },
    { lat: 48.32048, lng: 18.2143487 },
    { lat: 48.3201538, lng: 18.2150878 },
    { lat: 48.3185807, lng: 18.2179346 },
    { lat: 48.3158559, lng: 18.2209847 },
    { lat: 48.3142816, lng: 18.2224459 },
    { lat: 48.3131795, lng: 18.2249151 },
    { lat: 48.3124738, lng: 18.2270744 },
    { lat: 48.3120596, lng: 18.2280711 },
    { lat: 48.3117235, lng: 18.2286432 },
    { lat: 48.3106086, lng: 18.230244 },
    { lat: 48.3103727, lng: 18.2298325 },
    { lat: 48.3099167, lng: 18.2303791 },
    { lat: 48.30932, lng: 18.2309311 },
    { lat: 48.3094462, lng: 18.2312401 },
    { lat: 48.3086158, lng: 18.232101 },
    { lat: 48.3089499, lng: 18.2328467 },
    { lat: 48.3076802, lng: 18.2347796 },
    { lat: 48.3071386, lng: 18.2353673 },
    { lat: 48.3075091, lng: 18.2361178 },
    { lat: 48.3073522, lng: 18.2363902 },
    { lat: 48.3066844, lng: 18.2371976 },
    { lat: 48.3053062, lng: 18.2385964 },
    { lat: 48.3051427, lng: 18.2388746 },
    { lat: 48.3050306, lng: 18.2397269 },
    { lat: 48.3049387, lng: 18.2401092 },
    { lat: 48.3048266, lng: 18.2403601 },
    { lat: 48.306648, lng: 18.2421616 },
    { lat: 48.3066245, lng: 18.2422047 },
    { lat: 48.3067313, lng: 18.2423766 },
    { lat: 48.3074072, lng: 18.2431206 },
    { lat: 48.3077443, lng: 18.2438022 },
    { lat: 48.3082498, lng: 18.2444251 },
    { lat: 48.3086347, lng: 18.2450357 },
    { lat: 48.3096546, lng: 18.2469762 },
    { lat: 48.3101566, lng: 18.2483851 },
    { lat: 48.3105396, lng: 18.2496669 },
    { lat: 48.3104349, lng: 18.250691 },
    { lat: 48.3105142, lng: 18.2512054 },
    { lat: 48.3113649, lng: 18.2538935 },
    { lat: 48.3122043, lng: 18.2562472 },
    { lat: 48.3123682, lng: 18.2565646 },
    { lat: 48.3122751, lng: 18.2574163 },
    { lat: 48.3121498, lng: 18.2593998 },
    { lat: 48.3122956, lng: 18.2600119 },
    { lat: 48.3103917, lng: 18.2618552 },
    { lat: 48.3091145, lng: 18.2595772 },
    { lat: 48.3079701, lng: 18.2571574 },
    { lat: 48.3064701, lng: 18.2545589 },
    { lat: 48.3059641, lng: 18.2537478 },
    { lat: 48.3056285, lng: 18.2533081 },
    { lat: 48.305285, lng: 18.2539082 },
    { lat: 48.3051024, lng: 18.2545772 },
    { lat: 48.3044792, lng: 18.254887 },
    { lat: 48.3040243, lng: 18.2555702 },
    { lat: 48.3038536, lng: 18.2556827 },
    { lat: 48.3036069, lng: 18.2561005 },
    { lat: 48.3033961, lng: 18.2563194 },
    { lat: 48.3027508, lng: 18.2572083 },
    { lat: 48.3004747, lng: 18.2540239 },
    { lat: 48.2999684, lng: 18.2534383 },
    { lat: 48.2992481, lng: 18.2527468 },
    { lat: 48.2980244, lng: 18.2512872 },
    { lat: 48.2970047, lng: 18.2501755 },
    { lat: 48.2962923, lng: 18.2492481 },
    { lat: 48.2956478, lng: 18.2485376 },
    { lat: 48.2956381, lng: 18.2485453 },
    { lat: 48.294125, lng: 18.2490541 },
    { lat: 48.2928911, lng: 18.2468715 },
    { lat: 48.2922308, lng: 18.2486516 },
    { lat: 48.2919218, lng: 18.2489477 },
    { lat: 48.2915938, lng: 18.2494653 },
    { lat: 48.2911359, lng: 18.2498193 },
    { lat: 48.2904099, lng: 18.2505782 },
    { lat: 48.2895627, lng: 18.2510714 },
    { lat: 48.2889242, lng: 18.2515259 },
    { lat: 48.2885212, lng: 18.2519145 },
    { lat: 48.287998, lng: 18.2525657 },
    { lat: 48.2876575, lng: 18.253083 },
    { lat: 48.2871957, lng: 18.2540773 },
    { lat: 48.2870343, lng: 18.2546466 },
    { lat: 48.2869242, lng: 18.255498 },
    { lat: 48.2865301, lng: 18.2580611 },
    { lat: 48.288563, lng: 18.2609797 },
    { lat: 48.2891035, lng: 18.2617663 },
    { lat: 48.2892027, lng: 18.2616454 },
    { lat: 48.291741, lng: 18.2586343 },
    { lat: 48.2920551, lng: 18.2583277 },
    { lat: 48.2924353, lng: 18.2580302 },
    { lat: 48.2929881, lng: 18.2577548 },
    { lat: 48.2935765, lng: 18.2575891 },
    { lat: 48.2940161, lng: 18.2573829 },
    { lat: 48.2940262, lng: 18.25744 },
    { lat: 48.2945625, lng: 18.2573467 },
    { lat: 48.2948188, lng: 18.2572339 },
    { lat: 48.2948621, lng: 18.2571617 },
    { lat: 48.2939624, lng: 18.2558987 },
    { lat: 48.2936625, lng: 18.2551781 },
    { lat: 48.2943305, lng: 18.2544126 },
    { lat: 48.2962064, lng: 18.2573637 },
    { lat: 48.2973117, lng: 18.2594563 },
    { lat: 48.2984998, lng: 18.2623439 },
    { lat: 48.3002639, lng: 18.2671251 },
    { lat: 48.3009253, lng: 18.2677195 },
    { lat: 48.3037986, lng: 18.2685721 },
    { lat: 48.304222, lng: 18.2684389 },
    { lat: 48.3045051, lng: 18.2685569 },
    { lat: 48.3046358, lng: 18.2685145 },
    { lat: 48.3047272, lng: 18.2685733 },
    { lat: 48.304742, lng: 18.2687323 },
    { lat: 48.3048259, lng: 18.2686835 },
    { lat: 48.3051443, lng: 18.2687233 },
    { lat: 48.3052564, lng: 18.2686532 },
    { lat: 48.3054298, lng: 18.2687818 },
    { lat: 48.3057873, lng: 18.2687003 },
    { lat: 48.3058153, lng: 18.2687385 },
    { lat: 48.3060397, lng: 18.268598 },
    { lat: 48.3062244, lng: 18.2686698 },
    { lat: 48.3063181, lng: 18.2686298 },
    { lat: 48.306599, lng: 18.2688271 },
    { lat: 48.3073162, lng: 18.2691132 },
    { lat: 48.3074855, lng: 18.2690556 },
    { lat: 48.3075434, lng: 18.2690626 },
    { lat: 48.3075492, lng: 18.2691221 },
    { lat: 48.3083341, lng: 18.2690383 },
    { lat: 48.3087027, lng: 18.2691705 },
    { lat: 48.3088625, lng: 18.2691038 },
    { lat: 48.3097615, lng: 18.2690592 },
    { lat: 48.3100619, lng: 18.2688252 },
    { lat: 48.3103016, lng: 18.2684827 },
    { lat: 48.3104956, lng: 18.2685016 },
    { lat: 48.3107562, lng: 18.2684195 },
    { lat: 48.3109104, lng: 18.268255 },
    { lat: 48.311566, lng: 18.2680202 },
    { lat: 48.3116221, lng: 18.267896 },
    { lat: 48.3118025, lng: 18.2678139 },
    { lat: 48.3120442, lng: 18.2675849 },
    { lat: 48.3123545, lng: 18.2675236 },
    { lat: 48.3129616, lng: 18.267663 },
    { lat: 48.3134689, lng: 18.2673392 },
    { lat: 48.3138009, lng: 18.2669894 },
    { lat: 48.3142931, lng: 18.2669759 },
    { lat: 48.3144131, lng: 18.2668665 },
    { lat: 48.3146898, lng: 18.2668092 },
    { lat: 48.315, lng: 18.2665136 },
    { lat: 48.3150951, lng: 18.2665584 },
    { lat: 48.3152523, lng: 18.2665058 },
    { lat: 48.315409, lng: 18.26676 },
    { lat: 48.315473, lng: 18.266692 },
    { lat: 48.315576, lng: 18.26659 },
    { lat: 48.315671, lng: 18.266507 },
    { lat: 48.315823, lng: 18.266335 },
    { lat: 48.3161, lng: 18.266107 },
    { lat: 48.31621, lng: 18.266065 },
    { lat: 48.316292, lng: 18.266044 },
    { lat: 48.316525, lng: 18.265982 },
    { lat: 48.316685, lng: 18.265872 },
    { lat: 48.316883, lng: 18.2657 },
    { lat: 48.317104, lng: 18.265512 },
    { lat: 48.317181, lng: 18.265432 },
    { lat: 48.317387, lng: 18.265193 },
    { lat: 48.317609, lng: 18.264947 },
    { lat: 48.317769, lng: 18.264811 },
    { lat: 48.318058, lng: 18.264599 },
    { lat: 48.318086, lng: 18.264576 },
    { lat: 48.318232, lng: 18.264482 },
    { lat: 48.318356, lng: 18.264419 },
    { lat: 48.318514, lng: 18.264315 },
    { lat: 48.318672, lng: 18.264191 },
    { lat: 48.318793, lng: 18.264108 },
    { lat: 48.318902, lng: 18.264034 },
    { lat: 48.319063, lng: 18.263925 },
    { lat: 48.319239, lng: 18.263806 },
    { lat: 48.319595, lng: 18.263528 },
    { lat: 48.319877, lng: 18.263353 },
    { lat: 48.320148, lng: 18.263188 },
    { lat: 48.32026, lng: 18.263105 },
    { lat: 48.320491, lng: 18.262935 },
    { lat: 48.320598, lng: 18.262865 },
    { lat: 48.32082, lng: 18.262725 },
    { lat: 48.320934, lng: 18.262656 },
    { lat: 48.321008, lng: 18.262608 },
    { lat: 48.321139, lng: 18.262544 },
    { lat: 48.321294, lng: 18.262473 },
    { lat: 48.321559, lng: 18.262343 },
    { lat: 48.321584, lng: 18.262324 },
    { lat: 48.321775, lng: 18.262253 },
    { lat: 48.321802, lng: 18.262237 },
    { lat: 48.322049, lng: 18.262092 },
    { lat: 48.322223, lng: 18.261951 },
    { lat: 48.322453, lng: 18.261719 },
    { lat: 48.322771, lng: 18.261418 },
    { lat: 48.322834, lng: 18.261365 },
    { lat: 48.322943, lng: 18.261269 },
    { lat: 48.322979, lng: 18.261236 },
    { lat: 48.323181, lng: 18.261063 },
    { lat: 48.323365, lng: 18.260899 },
    { lat: 48.323473, lng: 18.260793 },
    { lat: 48.323756, lng: 18.260501 },
    { lat: 48.324019, lng: 18.26023 },
    { lat: 48.324217, lng: 18.259987 },
    { lat: 48.324325, lng: 18.259851 },
    { lat: 48.324435, lng: 18.259704 },
    { lat: 48.324654, lng: 18.259416 },
    { lat: 48.324795, lng: 18.259218 },
    { lat: 48.324836, lng: 18.259282 },
    { lat: 48.325266, lng: 18.259847 },
    { lat: 48.3254, lng: 18.260024 },
    { lat: 48.325545, lng: 18.260212 },
    { lat: 48.325674, lng: 18.260384 },
    { lat: 48.325786, lng: 18.26053 },
    { lat: 48.325823, lng: 18.260577 },
    { lat: 48.325946, lng: 18.260731 },
    { lat: 48.326033, lng: 18.260841 },
    { lat: 48.326131, lng: 18.260963 },
    { lat: 48.326238, lng: 18.261098 },
    { lat: 48.326367, lng: 18.26126 },
    { lat: 48.326673, lng: 18.261639 },
    { lat: 48.326767, lng: 18.261756 },
    { lat: 48.326785, lng: 18.261771 },
    { lat: 48.327031, lng: 18.261665 },
    { lat: 48.327051, lng: 18.261655 },
    { lat: 48.327274, lng: 18.261518 },
    { lat: 48.327435, lng: 18.261392 },
    { lat: 48.327481, lng: 18.261344 },
    { lat: 48.327602, lng: 18.261199 },
    { lat: 48.328003, lng: 18.261584 },
    { lat: 48.328044, lng: 18.261601 },
    { lat: 48.32817, lng: 18.261255 },
    { lat: 48.328253, lng: 18.261036 },
    { lat: 48.328383, lng: 18.260591 },
    { lat: 48.328478, lng: 18.260383 },
    { lat: 48.328615, lng: 18.260099 },
    { lat: 48.328758, lng: 18.259868 },
    { lat: 48.328924, lng: 18.259625 },
    { lat: 48.329017, lng: 18.259446 },
    { lat: 48.329157, lng: 18.25917 },
    { lat: 48.32926, lng: 18.258912 },
    { lat: 48.329416, lng: 18.258423 },
    { lat: 48.32948, lng: 18.258174 },
    { lat: 48.329526, lng: 18.257876 },
    { lat: 48.329614, lng: 18.257546 },
    { lat: 48.329672, lng: 18.257339 },
    { lat: 48.329815, lng: 18.256815 },
    { lat: 48.329964, lng: 18.256339 },
    { lat: 48.330122, lng: 18.255784 },
    { lat: 48.330359, lng: 18.255264 },
    { lat: 48.330732, lng: 18.254517 },
    { lat: 48.33098, lng: 18.25401 },
    { lat: 48.331016, lng: 18.254059 },
    { lat: 48.331324, lng: 18.253479 },
    { lat: 48.331677, lng: 18.252909 },
    { lat: 48.331988, lng: 18.252263 },
    { lat: 48.332305, lng: 18.251693 },
    { lat: 48.332563, lng: 18.251257 },
    { lat: 48.332794, lng: 18.250852 },
    { lat: 48.333029, lng: 18.250434 },
    { lat: 48.333471, lng: 18.249878 },
    { lat: 48.333919, lng: 18.248745 },
    { lat: 48.334224, lng: 18.248254 },
    { lat: 48.334492, lng: 18.247836 },
    { lat: 48.334627, lng: 18.24769 },
    { lat: 48.334915, lng: 18.247433 },
    { lat: 48.335195, lng: 18.247172 },
    { lat: 48.335457, lng: 18.246908 },
    { lat: 48.335885, lng: 18.246305 },
    { lat: 48.33623, lng: 18.245835 },
    { lat: 48.336423, lng: 18.245568 },
    { lat: 48.336452, lng: 18.245528 },
    { lat: 48.336469, lng: 18.24549 },
    { lat: 48.336506, lng: 18.245418 },
    { lat: 48.336521, lng: 18.245388 },
    { lat: 48.336625, lng: 18.245174 },
    { lat: 48.336773, lng: 18.24481 },
    { lat: 48.336819, lng: 18.244741 },
    { lat: 48.33709, lng: 18.244299 },
    { lat: 48.337396, lng: 18.243806 },
  ],
  Nitra: [
    { lat: 48.2728233, lng: 18.0110831 },
    { lat: 48.2724898, lng: 18.0113396 },
    { lat: 48.2715511, lng: 18.0117803 },
    { lat: 48.2704029, lng: 18.0126454 },
    { lat: 48.2696893, lng: 18.0129068 },
    { lat: 48.2674842, lng: 18.0143331 },
    { lat: 48.2669193, lng: 18.0146985 },
    { lat: 48.2669697, lng: 18.015334 },
    { lat: 48.2669451, lng: 18.0176091 },
    { lat: 48.2670269, lng: 18.0182266 },
    { lat: 48.2671381, lng: 18.0190661 },
    { lat: 48.2674555, lng: 18.0205498 },
    { lat: 48.267521, lng: 18.0207674 },
    { lat: 48.2683056, lng: 18.0233725 },
    { lat: 48.2684769, lng: 18.0243737 },
    { lat: 48.2689141, lng: 18.0276191 },
    { lat: 48.2692757, lng: 18.0293722 },
    { lat: 48.2695116, lng: 18.0307754 },
    { lat: 48.2696757, lng: 18.0326786 },
    { lat: 48.2695243, lng: 18.0327137 },
    { lat: 48.2693769, lng: 18.0338971 },
    { lat: 48.2685835, lng: 18.0349414 },
    { lat: 48.2675154, lng: 18.0355256 },
    { lat: 48.2669978, lng: 18.0359128 },
    { lat: 48.2665782, lng: 18.0362575 },
    { lat: 48.2661422, lng: 18.0367258 },
    { lat: 48.2656135, lng: 18.0370571 },
    { lat: 48.2653363, lng: 18.0375421 },
    { lat: 48.2669123, lng: 18.0395218 },
    { lat: 48.269034, lng: 18.0426893 },
    { lat: 48.2731573, lng: 18.050017 },
    { lat: 48.2736894, lng: 18.0510522 },
    { lat: 48.2741061, lng: 18.0517516 },
    { lat: 48.2745338, lng: 18.0524448 },
    { lat: 48.2750163, lng: 18.0530442 },
    { lat: 48.2775334, lng: 18.0555963 },
    { lat: 48.2778242, lng: 18.0560285 },
    { lat: 48.2780377, lng: 18.0564905 },
    { lat: 48.2782446, lng: 18.0572003 },
    { lat: 48.2781824, lng: 18.0572358 },
    { lat: 48.2781713, lng: 18.0572408 },
    { lat: 48.2711142, lng: 18.0605551 },
    { lat: 48.2695294, lng: 18.0616758 },
    { lat: 48.2683148, lng: 18.0623956 },
    { lat: 48.267828, lng: 18.0625977 },
    { lat: 48.2672327, lng: 18.0630292 },
    { lat: 48.2666326, lng: 18.0635666 },
    { lat: 48.265247, lng: 18.0652126 },
    { lat: 48.264871, lng: 18.0653454 },
    { lat: 48.2639028, lng: 18.0653844 },
    { lat: 48.263779, lng: 18.0654274 },
    { lat: 48.2637817, lng: 18.0655128 },
    { lat: 48.2640136, lng: 18.0661302 },
    { lat: 48.2639956, lng: 18.0661502 },
    { lat: 48.2637729, lng: 18.0665422 },
    { lat: 48.262314, lng: 18.067886 },
    { lat: 48.2610621, lng: 18.0693297 },
    { lat: 48.2602095, lng: 18.0704849 },
    { lat: 48.2591328, lng: 18.0722013 },
    { lat: 48.2576298, lng: 18.0744382 },
    { lat: 48.2571585, lng: 18.075055 },
    { lat: 48.2567278, lng: 18.0754815 },
    { lat: 48.2561828, lng: 18.0761519 },
    { lat: 48.2551234, lng: 18.0777057 },
    { lat: 48.2539978, lng: 18.0789608 },
    { lat: 48.2530915, lng: 18.0801593 },
    { lat: 48.2522966, lng: 18.0816071 },
    { lat: 48.2521094, lng: 18.0812855 },
    { lat: 48.2515721, lng: 18.0806527 },
    { lat: 48.2513854, lng: 18.0803188 },
    { lat: 48.2512803, lng: 18.080009 },
    { lat: 48.2510212, lng: 18.0786425 },
    { lat: 48.2508649, lng: 18.0783307 },
    { lat: 48.2505011, lng: 18.0770259 },
    { lat: 48.2499178, lng: 18.0745289 },
    { lat: 48.2497969, lng: 18.0742404 },
    { lat: 48.2493016, lng: 18.073708 },
    { lat: 48.2489364, lng: 18.0731038 },
    { lat: 48.2484058, lng: 18.0722259 },
    { lat: 48.2480837, lng: 18.0718182 },
    { lat: 48.2469327, lng: 18.0700843 },
    { lat: 48.2466355, lng: 18.0695059 },
    { lat: 48.2464792, lng: 18.0684481 },
    { lat: 48.2458869, lng: 18.0672527 },
    { lat: 48.2451227, lng: 18.0646002 },
    { lat: 48.2445036, lng: 18.0617918 },
    { lat: 48.2442838, lng: 18.061434 },
    { lat: 48.2440149, lng: 18.0611075 },
    { lat: 48.2429811, lng: 18.0605519 },
    { lat: 48.2392075, lng: 18.0592672 },
    { lat: 48.2377196, lng: 18.0594695 },
    { lat: 48.2380325, lng: 18.0607098 },
    { lat: 48.2396391, lng: 18.0668736 },
    { lat: 48.2407386, lng: 18.0701003 },
    { lat: 48.2416944, lng: 18.0719838 },
    { lat: 48.2427668, lng: 18.0704444 },
    { lat: 48.242874, lng: 18.0706161 },
    { lat: 48.2435459, lng: 18.0722424 },
    { lat: 48.2444866, lng: 18.074196 },
    { lat: 48.2445701, lng: 18.074425 },
    { lat: 48.244715, lng: 18.0755589 },
    { lat: 48.2448656, lng: 18.0762174 },
    { lat: 48.2457129, lng: 18.0782157 },
    { lat: 48.2458981, lng: 18.0787531 },
    { lat: 48.2462195, lng: 18.0794165 },
    { lat: 48.2463863, lng: 18.0800615 },
    { lat: 48.2467977, lng: 18.0807006 },
    { lat: 48.2472845, lng: 18.0824632 },
    { lat: 48.2474394, lng: 18.0828722 },
    { lat: 48.2483254, lng: 18.0843058 },
    { lat: 48.248543, lng: 18.0847231 },
    { lat: 48.2487994, lng: 18.085392 },
    { lat: 48.2477123, lng: 18.0864987 },
    { lat: 48.247025, lng: 18.0870705 },
    { lat: 48.2470244, lng: 18.0877692 },
    { lat: 48.2472381, lng: 18.0882526 },
    { lat: 48.248021, lng: 18.0897208 },
    { lat: 48.2486022, lng: 18.0915357 },
    { lat: 48.2488098, lng: 18.0923769 },
    { lat: 48.2493473, lng: 18.0936467 },
    { lat: 48.2494741, lng: 18.0936905 },
    { lat: 48.2496582, lng: 18.0939973 },
    { lat: 48.2504558, lng: 18.0948344 },
    { lat: 48.2509626, lng: 18.0957568 },
    { lat: 48.2510682, lng: 18.0961327 },
    { lat: 48.2514368, lng: 18.0981732 },
    { lat: 48.2514808, lng: 18.0989751 },
    { lat: 48.2515908, lng: 18.0998469 },
    { lat: 48.2528379, lng: 18.1043133 },
    { lat: 48.2537345, lng: 18.1070211 },
    { lat: 48.2549496, lng: 18.1101049 },
    { lat: 48.2560105, lng: 18.1125257 },
    { lat: 48.2561449, lng: 18.1127367 },
    { lat: 48.2568349, lng: 18.1155267 },
    { lat: 48.2567408, lng: 18.1168472 },
    { lat: 48.2571469, lng: 18.1183771 },
    { lat: 48.257399, lng: 18.1186548 },
    { lat: 48.2576019, lng: 18.1193994 },
    { lat: 48.2580274, lng: 18.1199606 },
    { lat: 48.2582881, lng: 18.1204366 },
    { lat: 48.2583795, lng: 18.1207096 },
    { lat: 48.2585989, lng: 18.1209085 },
    { lat: 48.2587536, lng: 18.1211402 },
    { lat: 48.2589736, lng: 18.1212738 },
    { lat: 48.2590452, lng: 18.1214344 },
    { lat: 48.2591096, lng: 18.121705 },
    { lat: 48.2591393, lng: 18.122154 },
    { lat: 48.259044, lng: 18.1231447 },
    { lat: 48.2589826, lng: 18.1233111 },
    { lat: 48.2583139, lng: 18.1241836 },
    { lat: 48.2565336, lng: 18.1265597 },
    { lat: 48.2564251, lng: 18.1264037 },
    { lat: 48.2562161, lng: 18.1264772 },
    { lat: 48.2560024, lng: 18.1263732 },
    { lat: 48.255542, lng: 18.1256034 },
    { lat: 48.2540391, lng: 18.1265293 },
    { lat: 48.2539059, lng: 18.1264367 },
    { lat: 48.2535146, lng: 18.1263451 },
    { lat: 48.2530581, lng: 18.1266612 },
    { lat: 48.2523752, lng: 18.1260988 },
    { lat: 48.2516323, lng: 18.1257656 },
    { lat: 48.2501774, lng: 18.1280908 },
    { lat: 48.2498535, lng: 18.1284754 },
    { lat: 48.2498985, lng: 18.128722 },
    { lat: 48.2501229, lng: 18.1290069 },
    { lat: 48.2501433, lng: 18.1293555 },
    { lat: 48.2505321, lng: 18.1300412 },
    { lat: 48.2505564, lng: 18.1302221 },
    { lat: 48.2491765, lng: 18.1340973 },
    { lat: 48.2463827, lng: 18.1391288 },
    { lat: 48.2490012, lng: 18.1422166 },
    { lat: 48.2498186, lng: 18.1428816 },
    { lat: 48.2505026, lng: 18.1433445 },
    { lat: 48.251768, lng: 18.1445849 },
    { lat: 48.2520416, lng: 18.1446697 },
    { lat: 48.2526042, lng: 18.1450485 },
    { lat: 48.2536946, lng: 18.1469241 },
    { lat: 48.2552644, lng: 18.1494314 },
    { lat: 48.2564295, lng: 18.1501926 },
    { lat: 48.2565938, lng: 18.1504543 },
    { lat: 48.2569721, lng: 18.1513272 },
    { lat: 48.2576483, lng: 18.1526441 },
    { lat: 48.2572657, lng: 18.1530884 },
    { lat: 48.2578164, lng: 18.154306 },
    { lat: 48.2586126, lng: 18.1534803 },
    { lat: 48.2594211, lng: 18.1526419 },
    { lat: 48.2615976, lng: 18.1574876 },
    { lat: 48.2602124, lng: 18.1588689 },
    { lat: 48.259945, lng: 18.1589631 },
    { lat: 48.2601549, lng: 18.1590786 },
    { lat: 48.2602533, lng: 18.1592574 },
    { lat: 48.2610196, lng: 18.1611349 },
    { lat: 48.2616809, lng: 18.1622755 },
    { lat: 48.2628176, lng: 18.1645442 },
    { lat: 48.26351, lng: 18.1657927 },
    { lat: 48.2644901, lng: 18.168359 },
    { lat: 48.2654246, lng: 18.1700059 },
    { lat: 48.2661869, lng: 18.1716027 },
    { lat: 48.2672574, lng: 18.1735139 },
    { lat: 48.2679044, lng: 18.1742366 },
    { lat: 48.2688859, lng: 18.1750065 },
    { lat: 48.2694804, lng: 18.1755629 },
    { lat: 48.2701828, lng: 18.1758242 },
    { lat: 48.2707174, lng: 18.1758762 },
    { lat: 48.271014, lng: 18.1758573 },
    { lat: 48.2710274, lng: 18.1758361 },
    { lat: 48.2718345, lng: 18.1747328 },
    { lat: 48.2732938, lng: 18.1731655 },
    { lat: 48.2738606, lng: 18.172609 },
    { lat: 48.2748481, lng: 18.1719159 },
    { lat: 48.2764783, lng: 18.1702638 },
    { lat: 48.2769264, lng: 18.1704424 },
    { lat: 48.2784145, lng: 18.1686681 },
    { lat: 48.2786164, lng: 18.1687243 },
    { lat: 48.2790019, lng: 18.1684288 },
    { lat: 48.2799086, lng: 18.1681454 },
    { lat: 48.2809973, lng: 18.1673674 },
    { lat: 48.2834943, lng: 18.1657541 },
    { lat: 48.2833484, lng: 18.1652669 },
    { lat: 48.2840358, lng: 18.1648053 },
    { lat: 48.284102, lng: 18.164837 },
    { lat: 48.2847982, lng: 18.1646229 },
    { lat: 48.2846751, lng: 18.164081 },
    { lat: 48.2859272, lng: 18.1638164 },
    { lat: 48.2874849, lng: 18.1636117 },
    { lat: 48.2876505, lng: 18.1642289 },
    { lat: 48.2893515, lng: 18.1629772 },
    { lat: 48.2901217, lng: 18.1625025 },
    { lat: 48.2912419, lng: 18.1616442 },
    { lat: 48.2929213, lng: 18.1598316 },
    { lat: 48.2939185, lng: 18.1589157 },
    { lat: 48.2943951, lng: 18.1598285 },
    { lat: 48.2953809, lng: 18.1587006 },
    { lat: 48.294862, lng: 18.1577916 },
    { lat: 48.2950265, lng: 18.1576534 },
    { lat: 48.2951393, lng: 18.1574593 },
    { lat: 48.2953147, lng: 18.1570033 },
    { lat: 48.2955986, lng: 18.1564554 },
    { lat: 48.2962078, lng: 18.1554477 },
    { lat: 48.297614, lng: 18.152472 },
    { lat: 48.2981431, lng: 18.1517335 },
    { lat: 48.2987511, lng: 18.1511511 },
    { lat: 48.2990821, lng: 18.1507334 },
    { lat: 48.2992956, lng: 18.1503467 },
    { lat: 48.2998044, lng: 18.1490905 },
    { lat: 48.2998601, lng: 18.1490605 },
    { lat: 48.2998321, lng: 18.149022 },
    { lat: 48.2999436, lng: 18.1489641 },
    { lat: 48.3003395, lng: 18.1489409 },
    { lat: 48.3005718, lng: 18.1491117 },
    { lat: 48.3010647, lng: 18.1502064 },
    { lat: 48.3013796, lng: 18.1514839 },
    { lat: 48.3015092, lng: 18.151828 },
    { lat: 48.3029878, lng: 18.1541307 },
    { lat: 48.3030498, lng: 18.1542452 },
    { lat: 48.30363, lng: 18.1556816 },
    { lat: 48.304201, lng: 18.1565676 },
    { lat: 48.3047875, lng: 18.1572404 },
    { lat: 48.306733, lng: 18.1587073 },
    { lat: 48.3069357, lng: 18.1589717 },
    { lat: 48.307219, lng: 18.1595564 },
    { lat: 48.3072444, lng: 18.159525 },
    { lat: 48.3076347, lng: 18.1590407 },
    { lat: 48.3078978, lng: 18.1585404 },
    { lat: 48.3087566, lng: 18.1572634 },
    { lat: 48.3089254, lng: 18.1565404 },
    { lat: 48.3090357, lng: 18.1556833 },
    { lat: 48.309515, lng: 18.154848 },
    { lat: 48.3098607, lng: 18.1545436 },
    { lat: 48.310343, lng: 18.1532945 },
    { lat: 48.3106975, lng: 18.152874 },
    { lat: 48.3111428, lng: 18.1525532 },
    { lat: 48.3118183, lng: 18.1522558 },
    { lat: 48.3119527, lng: 18.1521027 },
    { lat: 48.3122256, lng: 18.1514838 },
    { lat: 48.3130422, lng: 18.1506257 },
    { lat: 48.3133697, lng: 18.1505895 },
    { lat: 48.3136162, lng: 18.1503244 },
    { lat: 48.3144895, lng: 18.1496108 },
    { lat: 48.3145998, lng: 18.1494109 },
    { lat: 48.314933, lng: 18.1490509 },
    { lat: 48.3154327, lng: 18.1486991 },
    { lat: 48.3155718, lng: 18.1484721 },
    { lat: 48.3156526, lng: 18.1479818 },
    { lat: 48.3156542, lng: 18.1477018 },
    { lat: 48.3154609, lng: 18.1467845 },
    { lat: 48.3156018, lng: 18.1453553 },
    { lat: 48.3155557, lng: 18.1447973 },
    { lat: 48.315471, lng: 18.14445 },
    { lat: 48.3153255, lng: 18.1444329 },
    { lat: 48.3153385, lng: 18.1443433 },
    { lat: 48.315289, lng: 18.1442328 },
    { lat: 48.3151514, lng: 18.144253 },
    { lat: 48.3148862, lng: 18.1441177 },
    { lat: 48.314721, lng: 18.1442039 },
    { lat: 48.3143864, lng: 18.1441203 },
    { lat: 48.3143603, lng: 18.1440216 },
    { lat: 48.3142598, lng: 18.1440018 },
    { lat: 48.3142802, lng: 18.1438492 },
    { lat: 48.3141936, lng: 18.1437946 },
    { lat: 48.3141926, lng: 18.1437019 },
    { lat: 48.314089, lng: 18.143568 },
    { lat: 48.3137885, lng: 18.1434107 },
    { lat: 48.3135134, lng: 18.1433721 },
    { lat: 48.3134743, lng: 18.1429546 },
    { lat: 48.3132641, lng: 18.1429922 },
    { lat: 48.3132041, lng: 18.142815 },
    { lat: 48.3130896, lng: 18.1428421 },
    { lat: 48.3129966, lng: 18.1427568 },
    { lat: 48.3130113, lng: 18.1426237 },
    { lat: 48.3129498, lng: 18.1425545 },
    { lat: 48.3128785, lng: 18.1425728 },
    { lat: 48.3128004, lng: 18.1424713 },
    { lat: 48.3126866, lng: 18.142514 },
    { lat: 48.3126226, lng: 18.1424025 },
    { lat: 48.3126251, lng: 18.142292 },
    { lat: 48.3124205, lng: 18.1420154 },
    { lat: 48.3124348, lng: 18.141893 },
    { lat: 48.312253, lng: 18.1420059 },
    { lat: 48.3121218, lng: 18.1419426 },
    { lat: 48.3120813, lng: 18.1418483 },
    { lat: 48.3121161, lng: 18.1417682 },
    { lat: 48.312091, lng: 18.1417074 },
    { lat: 48.311854, lng: 18.1416662 },
    { lat: 48.3117577, lng: 18.1417976 },
    { lat: 48.3117369, lng: 18.1416536 },
    { lat: 48.311823, lng: 18.1415242 },
    { lat: 48.3117004, lng: 18.141396 },
    { lat: 48.3116412, lng: 18.1415403 },
    { lat: 48.311572, lng: 18.1414977 },
    { lat: 48.3115563, lng: 18.1414033 },
    { lat: 48.311683, lng: 18.1411963 },
    { lat: 48.3115214, lng: 18.1411342 },
    { lat: 48.3114197, lng: 18.14092 },
    { lat: 48.3112149, lng: 18.1408912 },
    { lat: 48.3111633, lng: 18.1407403 },
    { lat: 48.3108675, lng: 18.1404098 },
    { lat: 48.3108331, lng: 18.1402657 },
    { lat: 48.3106856, lng: 18.1400987 },
    { lat: 48.3106941, lng: 18.1398193 },
    { lat: 48.3104866, lng: 18.1391265 },
    { lat: 48.3103732, lng: 18.1389617 },
    { lat: 48.3098472, lng: 18.1388476 },
    { lat: 48.3106766, lng: 18.1381692 },
    { lat: 48.3115516, lng: 18.1376926 },
    { lat: 48.3123007, lng: 18.13713 },
    { lat: 48.3132676, lng: 18.1365107 },
    { lat: 48.3138427, lng: 18.1364624 },
    { lat: 48.3149569, lng: 18.1359871 },
    { lat: 48.3151718, lng: 18.1360723 },
    { lat: 48.3152906, lng: 18.1363283 },
    { lat: 48.3154071, lng: 18.1363551 },
    { lat: 48.3157326, lng: 18.1362641 },
    { lat: 48.3165871, lng: 18.1359623 },
    { lat: 48.3169339, lng: 18.1357139 },
    { lat: 48.3183143, lng: 18.1350239 },
    { lat: 48.3203375, lng: 18.1342098 },
    { lat: 48.3207169, lng: 18.1339847 },
    { lat: 48.3220683, lng: 18.1334136 },
    { lat: 48.3221416, lng: 18.1321973 },
    { lat: 48.3218849, lng: 18.1299954 },
    { lat: 48.3212061, lng: 18.1271521 },
    { lat: 48.320606, lng: 18.123962 },
    { lat: 48.3206347, lng: 18.1221817 },
    { lat: 48.3207535, lng: 18.1210899 },
    { lat: 48.3208879, lng: 18.1205151 },
    { lat: 48.3210428, lng: 18.1206149 },
    { lat: 48.3212, lng: 18.1204456 },
    { lat: 48.3211694, lng: 18.1201731 },
    { lat: 48.3212076, lng: 18.1198652 },
    { lat: 48.3215469, lng: 18.1187443 },
    { lat: 48.3227422, lng: 18.1177664 },
    { lat: 48.3229493, lng: 18.1176001 },
    { lat: 48.3248193, lng: 18.1161589 },
    { lat: 48.3270588, lng: 18.1148305 },
    { lat: 48.3275321, lng: 18.1161333 },
    { lat: 48.3277997, lng: 18.1159674 },
    { lat: 48.3281865, lng: 18.1156903 },
    { lat: 48.3291791, lng: 18.1149998 },
    { lat: 48.3291589, lng: 18.1149189 },
    { lat: 48.3317906, lng: 18.1138093 },
    { lat: 48.332477, lng: 18.1136885 },
    { lat: 48.3333765, lng: 18.1132465 },
    { lat: 48.3336747, lng: 18.1132905 },
    { lat: 48.3344599, lng: 18.1135936 },
    { lat: 48.334473, lng: 18.113527 },
    { lat: 48.3347486, lng: 18.1137417 },
    { lat: 48.3351081, lng: 18.1137549 },
    { lat: 48.335203, lng: 18.1136987 },
    { lat: 48.3354699, lng: 18.113323 },
    { lat: 48.3355853, lng: 18.1130618 },
    { lat: 48.3360896, lng: 18.1131308 },
    { lat: 48.3361617, lng: 18.1127077 },
    { lat: 48.3362063, lng: 18.1127178 },
    { lat: 48.3362634, lng: 18.1125184 },
    { lat: 48.336267, lng: 18.1116946 },
    { lat: 48.3364447, lng: 18.1117031 },
    { lat: 48.3364602, lng: 18.1106785 },
    { lat: 48.3366482, lng: 18.1105937 },
    { lat: 48.336662, lng: 18.110213 },
    { lat: 48.3367225, lng: 18.109938 },
    { lat: 48.3368798, lng: 18.1097139 },
    { lat: 48.3371994, lng: 18.1087211 },
    { lat: 48.3370993, lng: 18.108286 },
    { lat: 48.3370977, lng: 18.1082628 },
    { lat: 48.3370673, lng: 18.1078164 },
    { lat: 48.337126, lng: 18.1071825 },
    { lat: 48.3373359, lng: 18.1071846 },
    { lat: 48.337328, lng: 18.106511 },
    { lat: 48.3374076, lng: 18.1058287 },
    { lat: 48.3363255, lng: 18.1057536 },
    { lat: 48.3361944, lng: 18.1057922 },
    { lat: 48.3362012, lng: 18.105675 },
    { lat: 48.3357852, lng: 18.1056062 },
    { lat: 48.3354279, lng: 18.1054619 },
    { lat: 48.3354441, lng: 18.1053516 },
    { lat: 48.3349308, lng: 18.1052598 },
    { lat: 48.3353909, lng: 18.10412 },
    { lat: 48.3368263, lng: 18.1047911 },
    { lat: 48.3393224, lng: 18.1043833 },
    { lat: 48.3404056, lng: 18.1043404 },
    { lat: 48.3424687, lng: 18.1046497 },
    { lat: 48.342444, lng: 18.1047288 },
    { lat: 48.342878, lng: 18.1050269 },
    { lat: 48.342948, lng: 18.1047991 },
    { lat: 48.3456035, lng: 18.106509 },
    { lat: 48.3458893, lng: 18.1076256 },
    { lat: 48.3464575, lng: 18.1073263 },
    { lat: 48.3465048, lng: 18.107942 },
    { lat: 48.3465169, lng: 18.1084349 },
    { lat: 48.3464548, lng: 18.1087216 },
    { lat: 48.3467599, lng: 18.1083432 },
    { lat: 48.3479509, lng: 18.1067748 },
    { lat: 48.3488209, lng: 18.103912 },
    { lat: 48.3492733, lng: 18.101221 },
    { lat: 48.349412, lng: 18.0993216 },
    { lat: 48.3508837, lng: 18.0982078 },
    { lat: 48.3513368, lng: 18.0972594 },
    { lat: 48.3514764, lng: 18.0960253 },
    { lat: 48.3545874, lng: 18.0933527 },
    { lat: 48.3560424, lng: 18.0929931 },
    { lat: 48.3580157, lng: 18.0938458 },
    { lat: 48.3619396, lng: 18.0962627 },
    { lat: 48.3625331, lng: 18.0964701 },
    { lat: 48.3631601, lng: 18.0968445 },
    { lat: 48.3635562, lng: 18.0971909 },
    { lat: 48.3644412, lng: 18.0978066 },
    { lat: 48.3645717, lng: 18.098108 },
    { lat: 48.3646281, lng: 18.0984146 },
    { lat: 48.3650533, lng: 18.0994077 },
    { lat: 48.3655638, lng: 18.101104 },
    { lat: 48.3657313, lng: 18.1013632 },
    { lat: 48.3660137, lng: 18.1021322 },
    { lat: 48.3660408, lng: 18.1020019 },
    { lat: 48.3662628, lng: 18.1002864 },
    { lat: 48.3665035, lng: 18.0991 },
    { lat: 48.3669854, lng: 18.0986607 },
    { lat: 48.3672144, lng: 18.0985249 },
    { lat: 48.3675558, lng: 18.0984744 },
    { lat: 48.3685467, lng: 18.0980926 },
    { lat: 48.3689157, lng: 18.0984163 },
    { lat: 48.369791, lng: 18.098622 },
    { lat: 48.3701025, lng: 18.0985264 },
    { lat: 48.3708094, lng: 18.098667 },
    { lat: 48.3721836, lng: 18.0986586 },
    { lat: 48.3728449, lng: 18.0983541 },
    { lat: 48.3730145, lng: 18.0983586 },
    { lat: 48.3741224, lng: 18.0979279 },
    { lat: 48.3743485, lng: 18.0979011 },
    { lat: 48.374553, lng: 18.0977104 },
    { lat: 48.3750644, lng: 18.0974833 },
    { lat: 48.3751728, lng: 18.0975211 },
    { lat: 48.3769604, lng: 18.0967369 },
    { lat: 48.3771894, lng: 18.0967129 },
    { lat: 48.3776358, lng: 18.0965139 },
    { lat: 48.377784, lng: 18.0965216 },
    { lat: 48.3779647, lng: 18.0964053 },
    { lat: 48.378855, lng: 18.0962302 },
    { lat: 48.3788535, lng: 18.0961857 },
    { lat: 48.3796093, lng: 18.0958558 },
    { lat: 48.3801392, lng: 18.095367 },
    { lat: 48.3803549, lng: 18.0950619 },
    { lat: 48.3811165, lng: 18.0942918 },
    { lat: 48.3811753, lng: 18.0940607 },
    { lat: 48.3813618, lng: 18.0940121 },
    { lat: 48.3817549, lng: 18.0930956 },
    { lat: 48.3827018, lng: 18.0938427 },
    { lat: 48.3832336, lng: 18.0943853 },
    { lat: 48.3847934, lng: 18.0885756 },
    { lat: 48.3850346, lng: 18.0868447 },
    { lat: 48.3850701, lng: 18.0856471 },
    { lat: 48.3850412, lng: 18.0845826 },
    { lat: 48.384827, lng: 18.0848531 },
    { lat: 48.3846357, lng: 18.0851711 },
    { lat: 48.3844782, lng: 18.0855605 },
    { lat: 48.384373, lng: 18.0857101 },
    { lat: 48.3843114, lng: 18.0856988 },
    { lat: 48.3842472, lng: 18.0854332 },
    { lat: 48.3843074, lng: 18.0852104 },
    { lat: 48.3847403, lng: 18.0843765 },
    { lat: 48.3847519, lng: 18.0841928 },
    { lat: 48.3847069, lng: 18.0840383 },
    { lat: 48.3845657, lng: 18.083866 },
    { lat: 48.3843327, lng: 18.0837244 },
    { lat: 48.3840552, lng: 18.0837829 },
    { lat: 48.3835914, lng: 18.0841072 },
    { lat: 48.3834108, lng: 18.0843013 },
    { lat: 48.383158, lng: 18.0847591 },
    { lat: 48.3830999, lng: 18.0849607 },
    { lat: 48.3830609, lng: 18.0855073 },
    { lat: 48.3831205, lng: 18.0859825 },
    { lat: 48.3831016, lng: 18.0862552 },
    { lat: 48.3830258, lng: 18.0863086 },
    { lat: 48.3828137, lng: 18.0861372 },
    { lat: 48.3825647, lng: 18.0854744 },
    { lat: 48.3824476, lng: 18.085403 },
    { lat: 48.3821596, lng: 18.0854962 },
    { lat: 48.3819608, lng: 18.0857215 },
    { lat: 48.3818646, lng: 18.0857349 },
    { lat: 48.381796, lng: 18.0856617 },
    { lat: 48.3817357, lng: 18.085445 },
    { lat: 48.3816946, lng: 18.0854738 },
    { lat: 48.3813057, lng: 18.0870542 },
    { lat: 48.3809741, lng: 18.0873843 },
    { lat: 48.3808043, lng: 18.0873958 },
    { lat: 48.3805555, lng: 18.0871179 },
    { lat: 48.3802178, lng: 18.0869586 },
    { lat: 48.3799879, lng: 18.0867725 },
    { lat: 48.3798321, lng: 18.0864634 },
    { lat: 48.3796097, lng: 18.0857754 },
    { lat: 48.3794346, lng: 18.0858498 },
    { lat: 48.3792353, lng: 18.0863541 },
    { lat: 48.3790834, lng: 18.0864967 },
    { lat: 48.3789176, lng: 18.0864605 },
    { lat: 48.3786836, lng: 18.0862626 },
    { lat: 48.3785791, lng: 18.086078 },
    { lat: 48.3785339, lng: 18.0857524 },
    { lat: 48.3785748, lng: 18.0855063 },
    { lat: 48.3788008, lng: 18.0848224 },
    { lat: 48.378786, lng: 18.0844949 },
    { lat: 48.3784744, lng: 18.0844362 },
    { lat: 48.3782193, lng: 18.0846827 },
    { lat: 48.3778701, lng: 18.084444 },
    { lat: 48.3776487, lng: 18.0851493 },
    { lat: 48.3771891, lng: 18.085681 },
    { lat: 48.3771139, lng: 18.0858224 },
    { lat: 48.3770924, lng: 18.0860308 },
    { lat: 48.3771856, lng: 18.0861969 },
    { lat: 48.3776353, lng: 18.0864756 },
    { lat: 48.3778782, lng: 18.0869013 },
    { lat: 48.3782828, lng: 18.0868529 },
    { lat: 48.3784412, lng: 18.0870683 },
    { lat: 48.3785795, lng: 18.0874057 },
    { lat: 48.3785931, lng: 18.0878093 },
    { lat: 48.378476, lng: 18.088391 },
    { lat: 48.3782039, lng: 18.0891925 },
    { lat: 48.378128, lng: 18.0892281 },
    { lat: 48.3778581, lng: 18.0888784 },
    { lat: 48.3774477, lng: 18.0886553 },
    { lat: 48.3770446, lng: 18.0877893 },
    { lat: 48.3769001, lng: 18.0875611 },
    { lat: 48.3765198, lng: 18.087176 },
    { lat: 48.376438, lng: 18.0869742 },
    { lat: 48.3769187, lng: 18.0858454 },
    { lat: 48.3769494, lng: 18.0856759 },
    { lat: 48.3769549, lng: 18.0844846 },
    { lat: 48.3768298, lng: 18.0843159 },
    { lat: 48.3757239, lng: 18.083954 },
    { lat: 48.3755636, lng: 18.0837055 },
    { lat: 48.3754357, lng: 18.0837747 },
    { lat: 48.3750241, lng: 18.0843149 },
    { lat: 48.3748072, lng: 18.0842333 },
    { lat: 48.374663, lng: 18.0841264 },
    { lat: 48.3745055, lng: 18.0836919 },
    { lat: 48.3744883, lng: 18.0834013 },
    { lat: 48.3744238, lng: 18.0832452 },
    { lat: 48.3743246, lng: 18.083187 },
    { lat: 48.374152, lng: 18.0833814 },
    { lat: 48.3740433, lng: 18.0840483 },
    { lat: 48.3740822, lng: 18.0843225 },
    { lat: 48.3744399, lng: 18.0849909 },
    { lat: 48.3744184, lng: 18.0851454 },
    { lat: 48.3743619, lng: 18.0851816 },
    { lat: 48.3739215, lng: 18.0850445 },
    { lat: 48.3737642, lng: 18.0849031 },
    { lat: 48.3736792, lng: 18.0847036 },
    { lat: 48.3736092, lng: 18.0841662 },
    { lat: 48.3734179, lng: 18.0839297 },
    { lat: 48.3733565, lng: 18.0837664 },
    { lat: 48.3732271, lng: 18.0830087 },
    { lat: 48.3731386, lng: 18.0828869 },
    { lat: 48.3728368, lng: 18.0829139 },
    { lat: 48.3724484, lng: 18.0835075 },
    { lat: 48.3722859, lng: 18.0833533 },
    { lat: 48.3722089, lng: 18.0831382 },
    { lat: 48.3722133, lng: 18.082832 },
    { lat: 48.372311, lng: 18.08242 },
    { lat: 48.3722438, lng: 18.082238 },
    { lat: 48.372099, lng: 18.0820772 },
    { lat: 48.371789, lng: 18.081969 },
    { lat: 48.3715573, lng: 18.0817896 },
    { lat: 48.3715342, lng: 18.0815907 },
    { lat: 48.3715626, lng: 18.0814222 },
    { lat: 48.3716233, lng: 18.0813407 },
    { lat: 48.372287, lng: 18.0813218 },
    { lat: 48.3723754, lng: 18.0812323 },
    { lat: 48.372352, lng: 18.0810292 },
    { lat: 48.3720932, lng: 18.0804651 },
    { lat: 48.371983, lng: 18.0803214 },
    { lat: 48.3715706, lng: 18.0802378 },
    { lat: 48.3714009, lng: 18.0801148 },
    { lat: 48.371205, lng: 18.0797766 },
    { lat: 48.3712081, lng: 18.07962 },
    { lat: 48.371294, lng: 18.0794134 },
    { lat: 48.3721366, lng: 18.0788116 },
    { lat: 48.3724107, lng: 18.0788466 },
    { lat: 48.37278, lng: 18.0793459 },
    { lat: 48.3728862, lng: 18.0794078 },
    { lat: 48.3730176, lng: 18.0791649 },
    { lat: 48.3729795, lng: 18.0787518 },
    { lat: 48.3728887, lng: 18.0785708 },
    { lat: 48.3723325, lng: 18.0781154 },
    { lat: 48.3721782, lng: 18.0779084 },
    { lat: 48.372133, lng: 18.0776195 },
    { lat: 48.3722776, lng: 18.0773919 },
    { lat: 48.3726022, lng: 18.0773857 },
    { lat: 48.3728947, lng: 18.0777546 },
    { lat: 48.373276, lng: 18.0779209 },
    { lat: 48.3734319, lng: 18.0779044 },
    { lat: 48.3736076, lng: 18.0775925 },
    { lat: 48.3736263, lng: 18.0774121 },
    { lat: 48.3735721, lng: 18.0771829 },
    { lat: 48.3734516, lng: 18.076976 },
    { lat: 48.3732699, lng: 18.0768071 },
    { lat: 48.3723066, lng: 18.076545 },
    { lat: 48.3721248, lng: 18.0761613 },
    { lat: 48.3719526, lng: 18.0759957 },
    { lat: 48.3713248, lng: 18.0756845 },
    { lat: 48.3713037, lng: 18.0755552 },
    { lat: 48.3713973, lng: 18.0753259 },
    { lat: 48.3714003, lng: 18.0749198 },
    { lat: 48.3711422, lng: 18.0747351 },
    { lat: 48.3709924, lng: 18.0749094 },
    { lat: 48.3707698, lng: 18.0757667 },
    { lat: 48.3704043, lng: 18.0763257 },
    { lat: 48.3701554, lng: 18.0764284 },
    { lat: 48.3700444, lng: 18.0764023 },
    { lat: 48.370002, lng: 18.0761602 },
    { lat: 48.369611, lng: 18.0760592 },
    { lat: 48.3692192, lng: 18.0758081 },
    { lat: 48.3692128, lng: 18.0754114 },
    { lat: 48.3692858, lng: 18.0750411 },
    { lat: 48.3694044, lng: 18.0747744 },
    { lat: 48.3696704, lng: 18.0745403 },
    { lat: 48.3697452, lng: 18.0741814 },
    { lat: 48.3696977, lng: 18.0735687 },
    { lat: 48.3695719, lng: 18.0733686 },
    { lat: 48.3689029, lng: 18.0731612 },
    { lat: 48.3687205, lng: 18.0732088 },
    { lat: 48.3681306, lng: 18.073595 },
    { lat: 48.3679522, lng: 18.0736336 },
    { lat: 48.3678975, lng: 18.0735845 },
    { lat: 48.367608, lng: 18.0737088 },
    { lat: 48.367516, lng: 18.0736441 },
    { lat: 48.3676306, lng: 18.0728501 },
    { lat: 48.3676136, lng: 18.0726373 },
    { lat: 48.3674667, lng: 18.0724247 },
    { lat: 48.3672807, lng: 18.0723504 },
    { lat: 48.3669876, lng: 18.0723979 },
    { lat: 48.3668513, lng: 18.0722474 },
    { lat: 48.3669261, lng: 18.0719645 },
    { lat: 48.3671842, lng: 18.0716339 },
    { lat: 48.3672984, lng: 18.0712971 },
    { lat: 48.3673134, lng: 18.0710545 },
    { lat: 48.3672091, lng: 18.0700316 },
    { lat: 48.3669836, lng: 18.0698426 },
    { lat: 48.3662029, lng: 18.0698098 },
    { lat: 48.36607, lng: 18.0697384 },
    { lat: 48.3659996, lng: 18.0695029 },
    { lat: 48.3660456, lng: 18.0691727 },
    { lat: 48.3666241, lng: 18.0684409 },
    { lat: 48.3667488, lng: 18.0679693 },
    { lat: 48.3667753, lng: 18.0673681 },
    { lat: 48.3667091, lng: 18.0673361 },
    { lat: 48.3665434, lng: 18.0674338 },
    { lat: 48.3663608, lng: 18.0673814 },
    { lat: 48.3662533, lng: 18.0669393 },
    { lat: 48.3660698, lng: 18.0666588 },
    { lat: 48.36584, lng: 18.0664165 },
    { lat: 48.3655542, lng: 18.0664502 },
    { lat: 48.365425, lng: 18.0661171 },
    { lat: 48.3654659, lng: 18.0658732 },
    { lat: 48.365607, lng: 18.0656439 },
    { lat: 48.3660423, lng: 18.0652971 },
    { lat: 48.3662131, lng: 18.0653021 },
    { lat: 48.3664808, lng: 18.0654379 },
    { lat: 48.3669617, lng: 18.0657845 },
    { lat: 48.3670546, lng: 18.0656948 },
    { lat: 48.367161, lng: 18.0654545 },
    { lat: 48.3671674, lng: 18.0650472 },
    { lat: 48.3670007, lng: 18.0645743 },
    { lat: 48.3667444, lng: 18.0643593 },
    { lat: 48.3661198, lng: 18.0643161 },
    { lat: 48.365248, lng: 18.0645553 },
    { lat: 48.3651659, lng: 18.0646542 },
    { lat: 48.364928, lng: 18.0651743 },
    { lat: 48.3648236, lng: 18.0657773 },
    { lat: 48.3646686, lng: 18.0662849 },
    { lat: 48.3646581, lng: 18.0664343 },
    { lat: 48.3647223, lng: 18.0667373 },
    { lat: 48.3646976, lng: 18.0668514 },
    { lat: 48.3646409, lng: 18.0668678 },
    { lat: 48.3644528, lng: 18.0666831 },
    { lat: 48.3644168, lng: 18.0665139 },
    { lat: 48.3644386, lng: 18.0662505 },
    { lat: 48.3641208, lng: 18.065802 },
    { lat: 48.3640572, lng: 18.0654509 },
    { lat: 48.3641469, lng: 18.0650955 },
    { lat: 48.3643714, lng: 18.0649041 },
    { lat: 48.3643683, lng: 18.0647157 },
    { lat: 48.3641921, lng: 18.0646408 },
    { lat: 48.3632785, lng: 18.0646684 },
    { lat: 48.363096, lng: 18.0637973 },
    { lat: 48.3629825, lng: 18.0629046 },
    { lat: 48.3628915, lng: 18.0613867 },
    { lat: 48.3630191, lng: 18.0611011 },
    { lat: 48.3633575, lng: 18.0608125 },
    { lat: 48.3633662, lng: 18.0607116 },
    { lat: 48.3633075, lng: 18.0605935 },
    { lat: 48.3631165, lng: 18.0605153 },
    { lat: 48.3627838, lng: 18.0605969 },
    { lat: 48.3620874, lng: 18.0604987 },
    { lat: 48.3611874, lng: 18.0590013 },
    { lat: 48.3611148, lng: 18.0586941 },
    { lat: 48.3610856, lng: 18.0581795 },
    { lat: 48.360963, lng: 18.0581454 },
    { lat: 48.3607766, lng: 18.0583175 },
    { lat: 48.3595323, lng: 18.0563054 },
    { lat: 48.3577118, lng: 18.0535196 },
    { lat: 48.3578001, lng: 18.0534475 },
    { lat: 48.3578549, lng: 18.0533071 },
    { lat: 48.3581634, lng: 18.0523433 },
    { lat: 48.358327, lng: 18.0522066 },
    { lat: 48.3586983, lng: 18.051558 },
    { lat: 48.3588996, lng: 18.0510116 },
    { lat: 48.3591793, lng: 18.0507227 },
    { lat: 48.3591152, lng: 18.0504598 },
    { lat: 48.359159, lng: 18.0501527 },
    { lat: 48.3593439, lng: 18.0491017 },
    { lat: 48.3596173, lng: 18.0480211 },
    { lat: 48.3599174, lng: 18.0463596 },
    { lat: 48.3591346, lng: 18.0458346 },
    { lat: 48.3594892, lng: 18.0452893 },
    { lat: 48.3594076, lng: 18.0446348 },
    { lat: 48.359287, lng: 18.0442964 },
    { lat: 48.3588237, lng: 18.0440956 },
    { lat: 48.3587942, lng: 18.0442043 },
    { lat: 48.3585168, lng: 18.0447445 },
    { lat: 48.3579748, lng: 18.0451962 },
    { lat: 48.3577252, lng: 18.0456295 },
    { lat: 48.357307, lng: 18.0471041 },
    { lat: 48.3558465, lng: 18.0460374 },
    { lat: 48.3548583, lng: 18.0471491 },
    { lat: 48.3546671, lng: 18.0472597 },
    { lat: 48.3526033, lng: 18.0484474 },
    { lat: 48.3506465, lng: 18.0494773 },
    { lat: 48.3498073, lng: 18.0489928 },
    { lat: 48.3487644, lng: 18.0491823 },
    { lat: 48.347941, lng: 18.048314 },
    { lat: 48.345278, lng: 18.0460009 },
    { lat: 48.3408678, lng: 18.0477022 },
    { lat: 48.3404459, lng: 18.0474388 },
    { lat: 48.34007, lng: 18.046943 },
    { lat: 48.3389751, lng: 18.0465218 },
    { lat: 48.3364761, lng: 18.0471113 },
    { lat: 48.3369054, lng: 18.0488667 },
    { lat: 48.336755, lng: 18.0493972 },
    { lat: 48.33642, lng: 18.0495309 },
    { lat: 48.3362905, lng: 18.0497489 },
    { lat: 48.3361626, lng: 18.0501409 },
    { lat: 48.3365664, lng: 18.0504407 },
    { lat: 48.3366154, lng: 18.0507527 },
    { lat: 48.3365978, lng: 18.050936 },
    { lat: 48.3365521, lng: 18.0510587 },
    { lat: 48.3364351, lng: 18.0511595 },
    { lat: 48.3362132, lng: 18.0512217 },
    { lat: 48.3359621, lng: 18.0510427 },
    { lat: 48.3357484, lng: 18.0506189 },
    { lat: 48.335529, lng: 18.0496582 },
    { lat: 48.3353837, lng: 18.0495676 },
    { lat: 48.3352943, lng: 18.0496607 },
    { lat: 48.3351704, lng: 18.0499685 },
    { lat: 48.3351581, lng: 18.0501787 },
    { lat: 48.3352573, lng: 18.0507883 },
    { lat: 48.3360713, lng: 18.0521792 },
    { lat: 48.3359273, lng: 18.0524165 },
    { lat: 48.3354278, lng: 18.0528272 },
    { lat: 48.3354287, lng: 18.0529945 },
    { lat: 48.335685, lng: 18.0534913 },
    { lat: 48.335654, lng: 18.0535958 },
    { lat: 48.3354802, lng: 18.0537109 },
    { lat: 48.3350132, lng: 18.0537529 },
    { lat: 48.3348886, lng: 18.0538777 },
    { lat: 48.335043, lng: 18.054105 },
    { lat: 48.3350713, lng: 18.0542744 },
    { lat: 48.3350744, lng: 18.0543878 },
    { lat: 48.3349708, lng: 18.0547144 },
    { lat: 48.3349422, lng: 18.055394 },
    { lat: 48.3347499, lng: 18.0554057 },
    { lat: 48.3344487, lng: 18.0552951 },
    { lat: 48.3344518, lng: 18.0552518 },
    { lat: 48.3343091, lng: 18.0548434 },
    { lat: 48.3340664, lng: 18.0544022 },
    { lat: 48.3324582, lng: 18.051641 },
    { lat: 48.3261897, lng: 18.0414004 },
    { lat: 48.3256153, lng: 18.0402826 },
    { lat: 48.3252662, lng: 18.0394565 },
    { lat: 48.3248414, lng: 18.0381372 },
    { lat: 48.3241284, lng: 18.0363585 },
    { lat: 48.3230656, lng: 18.0342939 },
    { lat: 48.3214676, lng: 18.0313556 },
    { lat: 48.3202989, lng: 18.0293525 },
    { lat: 48.319683, lng: 18.0284101 },
    { lat: 48.3193547, lng: 18.0277444 },
    { lat: 48.3189096, lng: 18.0270373 },
    { lat: 48.3184378, lng: 18.0257454 },
    { lat: 48.3177404, lng: 18.024309 },
    { lat: 48.3165385, lng: 18.021238 },
    { lat: 48.3157483, lng: 18.0185454 },
    { lat: 48.3155925, lng: 18.0176933 },
    { lat: 48.3153884, lng: 18.0169196 },
    { lat: 48.3152877, lng: 18.0162689 },
    { lat: 48.3148137, lng: 18.0149276 },
    { lat: 48.3146756, lng: 18.0146546 },
    { lat: 48.3144052, lng: 18.0137359 },
    { lat: 48.3145036, lng: 18.0129079 },
    { lat: 48.3143458, lng: 18.0122646 },
    { lat: 48.3143028, lng: 18.0118484 },
    { lat: 48.3143634, lng: 18.0115399 },
    { lat: 48.3146222, lng: 18.0110726 },
    { lat: 48.3146412, lng: 18.0107847 },
    { lat: 48.3143562, lng: 18.009769 },
    { lat: 48.3143287, lng: 18.0093281 },
    { lat: 48.3141559, lng: 18.0083627 },
    { lat: 48.3140944, lng: 18.0074472 },
    { lat: 48.3137905, lng: 18.0074781 },
    { lat: 48.3135565, lng: 18.0074928 },
    { lat: 48.3134177, lng: 18.0076596 },
    { lat: 48.3132915, lng: 18.0078159 },
    { lat: 48.3130897, lng: 18.0077844 },
    { lat: 48.3124068, lng: 18.0080292 },
    { lat: 48.3116039, lng: 18.0085137 },
    { lat: 48.3112344, lng: 18.0086388 },
    { lat: 48.3099921, lng: 18.0086096 },
    { lat: 48.3084107, lng: 18.0082087 },
    { lat: 48.3074796, lng: 18.0081144 },
    { lat: 48.3068839, lng: 18.0079092 },
    { lat: 48.3063143, lng: 18.0078605 },
    { lat: 48.3054916, lng: 18.0078832 },
    { lat: 48.3049704, lng: 18.0079797 },
    { lat: 48.3042893, lng: 18.0081777 },
    { lat: 48.3035626, lng: 18.0084853 },
    { lat: 48.3027881, lng: 18.0084633 },
    { lat: 48.3023962, lng: 18.0083166 },
    { lat: 48.3016961, lng: 18.0080546 },
    { lat: 48.301237, lng: 18.0079477 },
    { lat: 48.3002034, lng: 18.0079899 },
    { lat: 48.2997265, lng: 18.0082271 },
    { lat: 48.2995705, lng: 18.0081651 },
    { lat: 48.2992812, lng: 18.0077806 },
    { lat: 48.2983473, lng: 18.0071682 },
    { lat: 48.2978409, lng: 18.0067193 },
    { lat: 48.2975478, lng: 18.006576 },
    { lat: 48.2961789, lng: 18.0061616 },
    { lat: 48.2939142, lng: 18.0056802 },
    { lat: 48.2935944, lng: 18.005487 },
    { lat: 48.2929797, lng: 18.0052516 },
    { lat: 48.2891797, lng: 18.0037969 },
    { lat: 48.2890433, lng: 18.0031772 },
    { lat: 48.2889691, lng: 18.0030479 },
    { lat: 48.2878617, lng: 18.0019312 },
    { lat: 48.2877527, lng: 18.0020341 },
    { lat: 48.2878257, lng: 18.0021034 },
    { lat: 48.2869242, lng: 18.0020336 },
    { lat: 48.2857383, lng: 18.0015123 },
    { lat: 48.2846109, lng: 18.0011171 },
    { lat: 48.2841305, lng: 18.0010291 },
    { lat: 48.2836531, lng: 18.0007278 },
    { lat: 48.2835125, lng: 18.0006971 },
    { lat: 48.2834092, lng: 18.0007467 },
    { lat: 48.2832213, lng: 18.0011327 },
    { lat: 48.2829861, lng: 18.0014084 },
    { lat: 48.2823874, lng: 18.0019679 },
    { lat: 48.281979, lng: 18.0023496 },
    { lat: 48.2815819, lng: 18.0028451 },
    { lat: 48.2813313, lng: 18.0029723 },
    { lat: 48.2809616, lng: 18.0033047 },
    { lat: 48.2796155, lng: 18.0048441 },
    { lat: 48.2794205, lng: 18.0052054 },
    { lat: 48.2791079, lng: 18.0055481 },
    { lat: 48.2786278, lng: 18.0063322 },
    { lat: 48.2784629, lng: 18.0065211 },
    { lat: 48.2783668, lng: 18.0066311 },
    { lat: 48.2779853, lng: 18.0068573 },
    { lat: 48.2779205, lng: 18.0069299 },
    { lat: 48.2772744, lng: 18.0076548 },
    { lat: 48.2765135, lng: 18.0083315 },
    { lat: 48.2764024, lng: 18.0083814 },
    { lat: 48.2758859, lng: 18.0086136 },
    { lat: 48.2751763, lng: 18.0090729 },
    { lat: 48.2744905, lng: 18.0097589 },
    { lat: 48.2742954, lng: 18.0099541 },
    { lat: 48.2735696, lng: 18.010558 },
    { lat: 48.2729815, lng: 18.0109606 },
    { lat: 48.2729224, lng: 18.0110011 },
    { lat: 48.2728691, lng: 18.0110376 },
    { lat: 48.2728233, lng: 18.0110831 },
  ],
  Štitáre: [
    { lat: 48.3679935, lng: 18.1530103 },
    { lat: 48.3679141, lng: 18.1529047 },
    { lat: 48.3671337, lng: 18.1517942 },
    { lat: 48.3669622, lng: 18.1510757 },
    { lat: 48.3670771, lng: 18.1506092 },
    { lat: 48.3658404, lng: 18.1481551 },
    { lat: 48.3650288, lng: 18.1475144 },
    { lat: 48.3639562, lng: 18.1463438 },
    { lat: 48.3635389, lng: 18.1461549 },
    { lat: 48.3630435, lng: 18.144977 },
    { lat: 48.3619356, lng: 18.1432639 },
    { lat: 48.3598082, lng: 18.1418678 },
    { lat: 48.3593183, lng: 18.1414805 },
    { lat: 48.3590576, lng: 18.1411916 },
    { lat: 48.3584894, lng: 18.1409289 },
    { lat: 48.3581588, lng: 18.1409128 },
    { lat: 48.3580389, lng: 18.1408271 },
    { lat: 48.3576269, lng: 18.140888 },
    { lat: 48.3573909, lng: 18.1408073 },
    { lat: 48.3570692, lng: 18.1400725 },
    { lat: 48.3570022, lng: 18.1399538 },
    { lat: 48.3567759, lng: 18.139553 },
    { lat: 48.356348, lng: 18.1383706 },
    { lat: 48.3563683, lng: 18.1380433 },
    { lat: 48.3562287, lng: 18.1377015 },
    { lat: 48.3560155, lng: 18.1368532 },
    { lat: 48.3561967, lng: 18.1365923 },
    { lat: 48.3561922, lng: 18.1363619 },
    { lat: 48.3560399, lng: 18.1361715 },
    { lat: 48.3556253, lng: 18.1347341 },
    { lat: 48.3556268, lng: 18.1344121 },
    { lat: 48.3555604, lng: 18.1340291 },
    { lat: 48.3553663, lng: 18.1334529 },
    { lat: 48.3553386, lng: 18.1329797 },
    { lat: 48.3548378, lng: 18.1323098 },
    { lat: 48.3542256, lng: 18.1316833 },
    { lat: 48.353894, lng: 18.1312217 },
    { lat: 48.3533752, lng: 18.131652 },
    { lat: 48.353157, lng: 18.1316449 },
    { lat: 48.3527488, lng: 18.1314982 },
    { lat: 48.3525589, lng: 18.1315509 },
    { lat: 48.3520906, lng: 18.1323988 },
    { lat: 48.351305, lng: 18.1334571 },
    { lat: 48.3496442, lng: 18.1342158 },
    { lat: 48.348259, lng: 18.1355203 },
    { lat: 48.3479534, lng: 18.1361573 },
    { lat: 48.3475004, lng: 18.1367863 },
    { lat: 48.3466384, lng: 18.1377923 },
    { lat: 48.3463173, lng: 18.1379577 },
    { lat: 48.3456564, lng: 18.138542 },
    { lat: 48.3447571, lng: 18.1388739 },
    { lat: 48.3443729, lng: 18.1393654 },
    { lat: 48.3439387, lng: 18.1396237 },
    { lat: 48.3436992, lng: 18.1398868 },
    { lat: 48.3433987, lng: 18.1400179 },
    { lat: 48.3431978, lng: 18.1400156 },
    { lat: 48.3428597, lng: 18.1402179 },
    { lat: 48.3422049, lng: 18.1403115 },
    { lat: 48.3417718, lng: 18.1403138 },
    { lat: 48.3413707, lng: 18.1401921 },
    { lat: 48.3412612, lng: 18.140054 },
    { lat: 48.341013, lng: 18.1399795 },
    { lat: 48.3407008, lng: 18.140023 },
    { lat: 48.3396122, lng: 18.1399722 },
    { lat: 48.3394249, lng: 18.1400341 },
    { lat: 48.3392987, lng: 18.1407509 },
    { lat: 48.3393009, lng: 18.1430661 },
    { lat: 48.3393871, lng: 18.1442494 },
    { lat: 48.339498, lng: 18.1447399 },
    { lat: 48.3397376, lng: 18.14546 },
    { lat: 48.3399511, lng: 18.1459459 },
    { lat: 48.3402136, lng: 18.1469819 },
    { lat: 48.3403053, lng: 18.1474874 },
    { lat: 48.3403602, lng: 18.1482368 },
    { lat: 48.3398942, lng: 18.1486435 },
    { lat: 48.3396289, lng: 18.1488142 },
    { lat: 48.3396594, lng: 18.1488918 },
    { lat: 48.3409275, lng: 18.1518257 },
    { lat: 48.3411661, lng: 18.1516401 },
    { lat: 48.3412883, lng: 18.1517244 },
    { lat: 48.3413806, lng: 18.1516233 },
    { lat: 48.3414338, lng: 18.1516443 },
    { lat: 48.3418242, lng: 18.152223 },
    { lat: 48.3422098, lng: 18.1533968 },
    { lat: 48.3425731, lng: 18.1549057 },
    { lat: 48.3425839, lng: 18.1549943 },
    { lat: 48.3422617, lng: 18.1551175 },
    { lat: 48.3420319, lng: 18.1553974 },
    { lat: 48.3421856, lng: 18.1563871 },
    { lat: 48.3417368, lng: 18.1568177 },
    { lat: 48.3412099, lng: 18.1570366 },
    { lat: 48.3408513, lng: 18.1572893 },
    { lat: 48.3410255, lng: 18.1585168 },
    { lat: 48.3408243, lng: 18.158744 },
    { lat: 48.3410726, lng: 18.1591502 },
    { lat: 48.3407778, lng: 18.1595698 },
    { lat: 48.3413896, lng: 18.1604838 },
    { lat: 48.3412152, lng: 18.1607252 },
    { lat: 48.3415701, lng: 18.1615874 },
    { lat: 48.3421987, lng: 18.1628057 },
    { lat: 48.3424644, lng: 18.1634544 },
    { lat: 48.3427664, lng: 18.1645336 },
    { lat: 48.3424549, lng: 18.1648001 },
    { lat: 48.3430422, lng: 18.1662448 },
    { lat: 48.3447183, lng: 18.1690227 },
    { lat: 48.3451851, lng: 18.1696923 },
    { lat: 48.3458598, lng: 18.1708767 },
    { lat: 48.3460781, lng: 18.1721252 },
    { lat: 48.3465019, lng: 18.1730651 },
    { lat: 48.3470396, lng: 18.1745513 },
    { lat: 48.3478168, lng: 18.1762347 },
    { lat: 48.348775, lng: 18.1763885 },
    { lat: 48.3496932, lng: 18.1774341 },
    { lat: 48.350341, lng: 18.1784616 },
    { lat: 48.3505401, lng: 18.1782777 },
    { lat: 48.350754, lng: 18.1785785 },
    { lat: 48.3507228, lng: 18.178721 },
    { lat: 48.3513322, lng: 18.1794622 },
    { lat: 48.3518834, lng: 18.179934 },
    { lat: 48.3530703, lng: 18.1812898 },
    { lat: 48.353763, lng: 18.18189 },
    { lat: 48.3541582, lng: 18.1823801 },
    { lat: 48.3554902, lng: 18.1835148 },
    { lat: 48.3555241, lng: 18.1835934 },
    { lat: 48.3566006, lng: 18.1821342 },
    { lat: 48.356646, lng: 18.182081 },
    { lat: 48.3566945, lng: 18.18202 },
    { lat: 48.3566627, lng: 18.1819213 },
    { lat: 48.3570072, lng: 18.1816556 },
    { lat: 48.3583497, lng: 18.1798819 },
    { lat: 48.3589708, lng: 18.178508 },
    { lat: 48.3595729, lng: 18.1774304 },
    { lat: 48.3599132, lng: 18.1771137 },
    { lat: 48.3605031, lng: 18.1760018 },
    { lat: 48.3609227, lng: 18.1749913 },
    { lat: 48.3614181, lng: 18.1744659 },
    { lat: 48.3617589, lng: 18.1737669 },
    { lat: 48.3619337, lng: 18.1730648 },
    { lat: 48.3620898, lng: 18.1726832 },
    { lat: 48.3625658, lng: 18.1718086 },
    { lat: 48.3627158, lng: 18.1713794 },
    { lat: 48.363014, lng: 18.1708421 },
    { lat: 48.3636447, lng: 18.1701605 },
    { lat: 48.3638163, lng: 18.1698701 },
    { lat: 48.3642054, lng: 18.168763 },
    { lat: 48.364346, lng: 18.1686346 },
    { lat: 48.3646212, lng: 18.168013 },
    { lat: 48.3650838, lng: 18.167237 },
    { lat: 48.3652883, lng: 18.166758 },
    { lat: 48.3653993, lng: 18.1651959 },
    { lat: 48.3651906, lng: 18.1622898 },
    { lat: 48.3647707, lng: 18.1613932 },
    { lat: 48.3645062, lng: 18.160052 },
    { lat: 48.3654212, lng: 18.1591001 },
    { lat: 48.3663115, lng: 18.1578775 },
    { lat: 48.3670702, lng: 18.156354 },
    { lat: 48.3673108, lng: 18.1553497 },
    { lat: 48.3674461, lng: 18.1550593 },
    { lat: 48.3679935, lng: 18.1530103 },
  ],
  Jarok: [
    { lat: 48.2877527, lng: 18.0020341 },
    { lat: 48.2875503, lng: 18.00112 },
    { lat: 48.2873525, lng: 18.0003445 },
    { lat: 48.287028, lng: 17.9994259 },
    { lat: 48.2867332, lng: 17.9981783 },
    { lat: 48.2864671, lng: 17.9963697 },
    { lat: 48.2864029, lng: 17.9944657 },
    { lat: 48.286269, lng: 17.9926986 },
    { lat: 48.2864389, lng: 17.9912174 },
    { lat: 48.2870584, lng: 17.9908383 },
    { lat: 48.2871118, lng: 17.9904498 },
    { lat: 48.2875072, lng: 17.9887394 },
    { lat: 48.2874731, lng: 17.9870592 },
    { lat: 48.2871582, lng: 17.9858335 },
    { lat: 48.2867087, lng: 17.9832101 },
    { lat: 48.2864966, lng: 17.9825518 },
    { lat: 48.2862792, lng: 17.9811468 },
    { lat: 48.2860509, lng: 17.9790497 },
    { lat: 48.2858163, lng: 17.9776417 },
    { lat: 48.2856091, lng: 17.9759735 },
    { lat: 48.2858329, lng: 17.9721027 },
    { lat: 48.2861271, lng: 17.9695014 },
    { lat: 48.2864054, lng: 17.9636134 },
    { lat: 48.2864189, lng: 17.9615266 },
    { lat: 48.2864716, lng: 17.9603375 },
    { lat: 48.286393, lng: 17.9589171 },
    { lat: 48.2865264, lng: 17.9574375 },
    { lat: 48.2858917, lng: 17.9544483 },
    { lat: 48.2854007, lng: 17.9535876 },
    { lat: 48.2852751, lng: 17.9523453 },
    { lat: 48.2856888, lng: 17.9509271 },
    { lat: 48.285846, lng: 17.948667 },
    { lat: 48.286101, lng: 17.9475955 },
    { lat: 48.2860807, lng: 17.947297 },
    { lat: 48.2853939, lng: 17.9470036 },
    { lat: 48.2839727, lng: 17.9458041 },
    { lat: 48.2835802, lng: 17.9457555 },
    { lat: 48.2830034, lng: 17.9446856 },
    { lat: 48.2829213, lng: 17.9445593 },
    { lat: 48.2828538, lng: 17.9445631 },
    { lat: 48.2827849, lng: 17.9428228 },
    { lat: 48.2828832, lng: 17.940934 },
    { lat: 48.2831273, lng: 17.9410415 },
    { lat: 48.2840576, lng: 17.9397409 },
    { lat: 48.2856923, lng: 17.9377252 },
    { lat: 48.2865235, lng: 17.9373185 },
    { lat: 48.2877068, lng: 17.9359946 },
    { lat: 48.2889058, lng: 17.9343365 },
    { lat: 48.2904168, lng: 17.9319157 },
    { lat: 48.2908692, lng: 17.9308637 },
    { lat: 48.2894376, lng: 17.9287663 },
    { lat: 48.2889323, lng: 17.9283025 },
    { lat: 48.2875981, lng: 17.9299887 },
    { lat: 48.2869391, lng: 17.9268709 },
    { lat: 48.2864308, lng: 17.9248285 },
    { lat: 48.2852409, lng: 17.9215741 },
    { lat: 48.2849838, lng: 17.9211129 },
    { lat: 48.2850227, lng: 17.920375 },
    { lat: 48.2840154, lng: 17.918801 },
    { lat: 48.2831511, lng: 17.9177999 },
    { lat: 48.2819967, lng: 17.9158286 },
    { lat: 48.2819797, lng: 17.9158451 },
    { lat: 48.2818895, lng: 17.9155107 },
    { lat: 48.281053, lng: 17.9143915 },
    { lat: 48.2802944, lng: 17.913161 },
    { lat: 48.2799446, lng: 17.9126972 },
    { lat: 48.2792074, lng: 17.9111087 },
    { lat: 48.2788453, lng: 17.9114291 },
    { lat: 48.2784602, lng: 17.9110346 },
    { lat: 48.2773641, lng: 17.9094504 },
    { lat: 48.275582, lng: 17.906496 },
    { lat: 48.275452, lng: 17.906419 },
    { lat: 48.275264, lng: 17.906407 },
    { lat: 48.275041, lng: 17.906452 },
    { lat: 48.274843, lng: 17.906479 },
    { lat: 48.274662, lng: 17.906468 },
    { lat: 48.274335, lng: 17.906424 },
    { lat: 48.273747, lng: 17.906413 },
    { lat: 48.273537, lng: 17.906431 },
    { lat: 48.273475, lng: 17.906443 },
    { lat: 48.273329, lng: 17.906478 },
    { lat: 48.272734, lng: 17.907048 },
    { lat: 48.272724, lng: 17.907057 },
    { lat: 48.272681, lng: 17.907095 },
    { lat: 48.272758, lng: 17.907241 },
    { lat: 48.272885, lng: 17.908082 },
    { lat: 48.272844, lng: 17.908112 },
    { lat: 48.272708, lng: 17.908211 },
    { lat: 48.272412, lng: 17.908493 },
    { lat: 48.270804, lng: 17.909966 },
    { lat: 48.269547, lng: 17.911652 },
    { lat: 48.270006, lng: 17.912757 },
    { lat: 48.26965, lng: 17.913278 },
    { lat: 48.269191, lng: 17.913993 },
    { lat: 48.268717, lng: 17.914687 },
    { lat: 48.268267, lng: 17.915362 },
    { lat: 48.268572, lng: 17.915735 },
    { lat: 48.268963, lng: 17.916259 },
    { lat: 48.268922, lng: 17.916487 },
    { lat: 48.268713, lng: 17.916974 },
    { lat: 48.268198, lng: 17.917846 },
    { lat: 48.267924, lng: 17.918333 },
    { lat: 48.267677, lng: 17.918874 },
    { lat: 48.267461, lng: 17.919264 },
    { lat: 48.267239, lng: 17.91963 },
    { lat: 48.266524, lng: 17.920553 },
    { lat: 48.266328, lng: 17.920802 },
    { lat: 48.266223, lng: 17.921002 },
    { lat: 48.265942, lng: 17.921657 },
    { lat: 48.265488, lng: 17.922493 },
    { lat: 48.264968, lng: 17.923543 },
    { lat: 48.264613, lng: 17.924111 },
    { lat: 48.264316, lng: 17.924504 },
    { lat: 48.264125, lng: 17.924771 },
    { lat: 48.263776, lng: 17.92526 },
    { lat: 48.263508, lng: 17.925712 },
    { lat: 48.263418, lng: 17.92593 },
    { lat: 48.263447, lng: 17.926272 },
    { lat: 48.263396, lng: 17.926571 },
    { lat: 48.262797, lng: 17.92818 },
    { lat: 48.262707, lng: 17.928372 },
    { lat: 48.262684, lng: 17.928415 },
    { lat: 48.262679, lng: 17.928425 },
    { lat: 48.262102, lng: 17.929317 },
    { lat: 48.261699, lng: 17.929887 },
    { lat: 48.261444, lng: 17.930308 },
    { lat: 48.261325, lng: 17.930495 },
    { lat: 48.261303, lng: 17.930528 },
    { lat: 48.261061, lng: 17.930909 },
    { lat: 48.260213, lng: 17.931887 },
    { lat: 48.260118, lng: 17.931997 },
    { lat: 48.260035, lng: 17.932094 },
    { lat: 48.25999, lng: 17.932091 },
    { lat: 48.259933, lng: 17.93223 },
    { lat: 48.259913, lng: 17.93228 },
    { lat: 48.259865, lng: 17.932396 },
    { lat: 48.259759, lng: 17.932657 },
    { lat: 48.258331, lng: 17.936192 },
    { lat: 48.257317, lng: 17.938655 },
    { lat: 48.256495, lng: 17.940598 },
    { lat: 48.256315, lng: 17.941037 },
    { lat: 48.255896, lng: 17.942053 },
    { lat: 48.25588, lng: 17.942097 },
    { lat: 48.255238, lng: 17.94385 },
    { lat: 48.25508, lng: 17.944281 },
    { lat: 48.254563, lng: 17.945862 },
    { lat: 48.254251, lng: 17.94697 },
    { lat: 48.254033, lng: 17.947782 },
    { lat: 48.253733, lng: 17.948842 },
    { lat: 48.253729, lng: 17.94886 },
    { lat: 48.253721, lng: 17.948956 },
    { lat: 48.253694, lng: 17.949292 },
    { lat: 48.253624, lng: 17.949274 },
    { lat: 48.253585, lng: 17.950335 },
    { lat: 48.253549, lng: 17.951293 },
    { lat: 48.253525, lng: 17.952015 },
    { lat: 48.253514, lng: 17.952267 },
    { lat: 48.253494, lng: 17.952688 },
    { lat: 48.253477, lng: 17.953459 },
    { lat: 48.253377, lng: 17.954188 },
    { lat: 48.253253, lng: 17.954928 },
    { lat: 48.252782, lng: 17.956678 },
    { lat: 48.252658, lng: 17.95714 },
    { lat: 48.252644, lng: 17.957197 },
    { lat: 48.25229, lng: 17.958529 },
    { lat: 48.251908, lng: 17.959956 },
    { lat: 48.251913, lng: 17.961414 },
    { lat: 48.251895, lng: 17.962818 },
    { lat: 48.251961, lng: 17.964142 },
    { lat: 48.251983, lng: 17.9646 },
    { lat: 48.25198, lng: 17.964987 },
    { lat: 48.251937, lng: 17.965428 },
    { lat: 48.251509, lng: 17.967031 },
    { lat: 48.251324, lng: 17.967888 },
    { lat: 48.251352, lng: 17.968425 },
    { lat: 48.251377, lng: 17.968914 },
    { lat: 48.251486, lng: 17.969954 },
    { lat: 48.251489, lng: 17.970262 },
    { lat: 48.251489, lng: 17.970304 },
    { lat: 48.251495, lng: 17.970878 },
    { lat: 48.251634, lng: 17.972615 },
    { lat: 48.251383, lng: 17.974378 },
    { lat: 48.250957, lng: 17.974841 },
    { lat: 48.249943, lng: 17.975901 },
    { lat: 48.250717, lng: 17.977639 },
    { lat: 48.250732, lng: 17.97767 },
    { lat: 48.250769, lng: 17.977756 },
    { lat: 48.250782, lng: 17.977784 },
    { lat: 48.250903, lng: 17.978054 },
    { lat: 48.25149, lng: 17.979235 },
    { lat: 48.252418, lng: 17.981071 },
    { lat: 48.2532235, lng: 17.9827826 },
    { lat: 48.2531734, lng: 17.9828506 },
    { lat: 48.2569293, lng: 17.9899809 },
    { lat: 48.2573087, lng: 17.9907198 },
    { lat: 48.2572813, lng: 17.9908074 },
    { lat: 48.2595773, lng: 17.9945469 },
    { lat: 48.2601923, lng: 17.9953194 },
    { lat: 48.2607621, lng: 17.9963906 },
    { lat: 48.2621617, lng: 17.9980883 },
    { lat: 48.2637873, lng: 17.9996942 },
    { lat: 48.264186, lng: 18.0002666 },
    { lat: 48.2651472, lng: 18.0009751 },
    { lat: 48.2652827, lng: 18.0012042 },
    { lat: 48.2663761, lng: 18.0020853 },
    { lat: 48.267319, lng: 18.0026022 },
    { lat: 48.2685827, lng: 18.0044672 },
    { lat: 48.2693017, lng: 18.0059594 },
    { lat: 48.2701688, lng: 18.0071282 },
    { lat: 48.2710427, lng: 18.0085312 },
    { lat: 48.2715256, lng: 18.0092525 },
    { lat: 48.2722354, lng: 18.0098905 },
    { lat: 48.2724266, lng: 18.0102839 },
    { lat: 48.2727923, lng: 18.0109997 },
    { lat: 48.2728233, lng: 18.0110831 },
    { lat: 48.2728691, lng: 18.0110376 },
    { lat: 48.2729224, lng: 18.0110011 },
    { lat: 48.2729815, lng: 18.0109606 },
    { lat: 48.2735696, lng: 18.010558 },
    { lat: 48.2742954, lng: 18.0099541 },
    { lat: 48.2744905, lng: 18.0097589 },
    { lat: 48.2751763, lng: 18.0090729 },
    { lat: 48.2758859, lng: 18.0086136 },
    { lat: 48.2764024, lng: 18.0083814 },
    { lat: 48.2765135, lng: 18.0083315 },
    { lat: 48.2772744, lng: 18.0076548 },
    { lat: 48.2779205, lng: 18.0069299 },
    { lat: 48.2779853, lng: 18.0068573 },
    { lat: 48.2783668, lng: 18.0066311 },
    { lat: 48.2784629, lng: 18.0065211 },
    { lat: 48.2786278, lng: 18.0063322 },
    { lat: 48.2791079, lng: 18.0055481 },
    { lat: 48.2794205, lng: 18.0052054 },
    { lat: 48.2796155, lng: 18.0048441 },
    { lat: 48.2809616, lng: 18.0033047 },
    { lat: 48.2813313, lng: 18.0029723 },
    { lat: 48.2815819, lng: 18.0028451 },
    { lat: 48.281979, lng: 18.0023496 },
    { lat: 48.2823874, lng: 18.0019679 },
    { lat: 48.2829861, lng: 18.0014084 },
    { lat: 48.2832213, lng: 18.0011327 },
    { lat: 48.2834092, lng: 18.0007467 },
    { lat: 48.2835125, lng: 18.0006971 },
    { lat: 48.2836531, lng: 18.0007278 },
    { lat: 48.2841305, lng: 18.0010291 },
    { lat: 48.2846109, lng: 18.0011171 },
    { lat: 48.2857383, lng: 18.0015123 },
    { lat: 48.2869242, lng: 18.0020336 },
    { lat: 48.2878257, lng: 18.0021034 },
    { lat: 48.2877527, lng: 18.0020341 },
  ],
  CabajČápor: [
    { lat: 48.252418, lng: 17.981071 },
    { lat: 48.25237, lng: 17.981098 },
    { lat: 48.252325, lng: 17.981124 },
    { lat: 48.252299, lng: 17.98114 },
    { lat: 48.252241, lng: 17.981174 },
    { lat: 48.246358, lng: 17.9846 },
    { lat: 48.24624, lng: 17.984667 },
    { lat: 48.238896, lng: 17.988893 },
    { lat: 48.236813, lng: 17.990426 },
    { lat: 48.236792, lng: 17.990451 },
    { lat: 48.236775, lng: 17.990227 },
    { lat: 48.236755, lng: 17.99006 },
    { lat: 48.236773, lng: 17.989851 },
    { lat: 48.236943, lng: 17.989029 },
    { lat: 48.236974, lng: 17.988718 },
    { lat: 48.23696, lng: 17.98826 },
    { lat: 48.23702, lng: 17.987717 },
    { lat: 48.237095, lng: 17.987559 },
    { lat: 48.237306, lng: 17.987191 },
    { lat: 48.237386, lng: 17.986827 },
    { lat: 48.237384, lng: 17.986719 },
    { lat: 48.237378, lng: 17.986626 },
    { lat: 48.237352, lng: 17.986272 },
    { lat: 48.237343, lng: 17.985835 },
    { lat: 48.237353, lng: 17.985572 },
    { lat: 48.23739, lng: 17.985277 },
    { lat: 48.237463, lng: 17.98505 },
    { lat: 48.237824, lng: 17.98403 },
    { lat: 48.237901, lng: 17.98373 },
    { lat: 48.237913, lng: 17.983516 },
    { lat: 48.237798, lng: 17.982394 },
    { lat: 48.237803, lng: 17.98214 },
    { lat: 48.237857, lng: 17.98152 },
    { lat: 48.23795, lng: 17.980842 },
    { lat: 48.23793, lng: 17.980793 },
    { lat: 48.237627, lng: 17.980002 },
    { lat: 48.237615, lng: 17.97997 },
    { lat: 48.236847, lng: 17.980502 },
    { lat: 48.236358, lng: 17.980781 },
    { lat: 48.23604, lng: 17.981013 },
    { lat: 48.235265, lng: 17.981503 },
    { lat: 48.234258, lng: 17.982111 },
    { lat: 48.233903, lng: 17.982317 },
    { lat: 48.233062, lng: 17.982875 },
    { lat: 48.23258, lng: 17.983238 },
    { lat: 48.232364, lng: 17.983413 },
    { lat: 48.231974, lng: 17.983819 },
    { lat: 48.230592, lng: 17.984864 },
    { lat: 48.229986, lng: 17.9851 },
    { lat: 48.229348, lng: 17.985495 },
    { lat: 48.228313, lng: 17.986282 },
    { lat: 48.227601, lng: 17.986577 },
    { lat: 48.226939, lng: 17.986741 },
    { lat: 48.22641, lng: 17.986844 },
    { lat: 48.225873, lng: 17.987008 },
    { lat: 48.225863, lng: 17.986965 },
    { lat: 48.224422, lng: 17.986902 },
    { lat: 48.223217, lng: 17.986634 },
    { lat: 48.222351, lng: 17.986347 },
    { lat: 48.222291, lng: 17.986365 },
    { lat: 48.221197, lng: 17.987138 },
    { lat: 48.219594, lng: 17.987592 },
    { lat: 48.218482, lng: 17.98766 },
    { lat: 48.218399, lng: 17.987666 },
    { lat: 48.218314, lng: 17.987682 },
    { lat: 48.21827, lng: 17.98769 },
    { lat: 48.216297, lng: 17.988345 },
    { lat: 48.215554, lng: 17.988858 },
    { lat: 48.214921, lng: 17.988983 },
    { lat: 48.214717, lng: 17.989023 },
    { lat: 48.213795, lng: 17.989213 },
    { lat: 48.212522, lng: 17.989874 },
    { lat: 48.211561, lng: 17.990526 },
    { lat: 48.209478, lng: 17.991963 },
    { lat: 48.208871, lng: 17.991548 },
    { lat: 48.20764, lng: 17.990546 },
    { lat: 48.20708, lng: 17.990043 },
    { lat: 48.205825, lng: 17.988941 },
    { lat: 48.205145, lng: 17.988351 },
    { lat: 48.204827, lng: 17.98807 },
    { lat: 48.203754, lng: 17.987104 },
    { lat: 48.203015, lng: 17.986194 },
    { lat: 48.202863, lng: 17.986009 },
    { lat: 48.201792, lng: 17.984781 },
    { lat: 48.201445, lng: 17.984309 },
    { lat: 48.201096, lng: 17.98385 },
    { lat: 48.200874, lng: 17.983503 },
    { lat: 48.200677, lng: 17.983153 },
    { lat: 48.200424, lng: 17.982763 },
    { lat: 48.199104, lng: 17.981165 },
    { lat: 48.199066, lng: 17.981154 },
    { lat: 48.198999, lng: 17.981133 },
    { lat: 48.19897, lng: 17.981112 },
    { lat: 48.198491, lng: 17.980777 },
    { lat: 48.198293, lng: 17.980636 },
    { lat: 48.197945, lng: 17.980384 },
    { lat: 48.197328, lng: 17.979933 },
    { lat: 48.196974, lng: 17.979631 },
    { lat: 48.196893, lng: 17.979562 },
    { lat: 48.196466, lng: 17.979141 },
    { lat: 48.196162, lng: 17.978841 },
    { lat: 48.196103, lng: 17.978786 },
    { lat: 48.195583, lng: 17.978301 },
    { lat: 48.195196, lng: 17.977985 },
    { lat: 48.195044, lng: 17.977899 },
    { lat: 48.194752, lng: 17.977643 },
    { lat: 48.194461, lng: 17.977322 },
    { lat: 48.194145, lng: 17.977043 },
    { lat: 48.193847, lng: 17.976768 },
    { lat: 48.193413, lng: 17.976326 },
    { lat: 48.193088, lng: 17.976003 },
    { lat: 48.192588, lng: 17.975514 },
    { lat: 48.191614, lng: 17.974572 },
    { lat: 48.191601, lng: 17.974599 },
    { lat: 48.191558, lng: 17.974683 },
    { lat: 48.191542, lng: 17.974715 },
    { lat: 48.190968, lng: 17.974087 },
    { lat: 48.190485, lng: 17.973458 },
    { lat: 48.189977, lng: 17.972634 },
    { lat: 48.189743, lng: 17.972257 },
    { lat: 48.189716, lng: 17.972293 },
    { lat: 48.189263, lng: 17.971739 },
    { lat: 48.188533, lng: 17.970846 },
    { lat: 48.188421, lng: 17.970686 },
    { lat: 48.188261, lng: 17.970501 },
    { lat: 48.18828, lng: 17.970616 },
    { lat: 48.188788, lng: 17.971514 },
    { lat: 48.188951, lng: 17.9718 },
    { lat: 48.189327, lng: 17.972455 },
    { lat: 48.189439, lng: 17.972639 },
    { lat: 48.188885, lng: 17.973379 },
    { lat: 48.188754, lng: 17.973552 },
    { lat: 48.188591, lng: 17.973767 },
    { lat: 48.188068, lng: 17.974454 },
    { lat: 48.187519, lng: 17.975182 },
    { lat: 48.187166, lng: 17.975648 },
    { lat: 48.187064, lng: 17.975782 },
    { lat: 48.186518, lng: 17.976503 },
    { lat: 48.186463, lng: 17.976581 },
    { lat: 48.186379, lng: 17.976704 },
    { lat: 48.185617, lng: 17.977828 },
    { lat: 48.184703, lng: 17.979185 },
    { lat: 48.184681, lng: 17.979224 },
    { lat: 48.1850862, lng: 17.9802815 },
    { lat: 48.1855454, lng: 17.9820636 },
    { lat: 48.1860023, lng: 17.9835083 },
    { lat: 48.1865887, lng: 17.9845342 },
    { lat: 48.1869874, lng: 17.9850805 },
    { lat: 48.1876296, lng: 17.9857389 },
    { lat: 48.1887304, lng: 17.9866848 },
    { lat: 48.1907821, lng: 17.9887491 },
    { lat: 48.1907956, lng: 17.9887115 },
    { lat: 48.1937851, lng: 17.9907764 },
    { lat: 48.1949621, lng: 17.9917326 },
    { lat: 48.1954504, lng: 17.9921758 },
    { lat: 48.1969188, lng: 17.994889 },
    { lat: 48.1988546, lng: 17.9981485 },
    { lat: 48.199519, lng: 17.9994803 },
    { lat: 48.2008722, lng: 18.0015325 },
    { lat: 48.2013904, lng: 18.0021569 },
    { lat: 48.2017861, lng: 18.0024697 },
    { lat: 48.2023606, lng: 18.0031492 },
    { lat: 48.2030251, lng: 18.0041429 },
    { lat: 48.2043521, lng: 18.0058932 },
    { lat: 48.2052452, lng: 18.0068786 },
    { lat: 48.2075934, lng: 18.0092857 },
    { lat: 48.2109025, lng: 18.0130804 },
    { lat: 48.2122829, lng: 18.0150473 },
    { lat: 48.2131239, lng: 18.0160683 },
    { lat: 48.214931, lng: 18.0177353 },
    { lat: 48.2155322, lng: 18.0184073 },
    { lat: 48.2165468, lng: 18.0198069 },
    { lat: 48.2170522, lng: 18.0206935 },
    { lat: 48.2190472, lng: 18.0237873 },
    { lat: 48.2208989, lng: 18.0264858 },
    { lat: 48.2227584, lng: 18.0289986 },
    { lat: 48.2247625, lng: 18.0320983 },
    { lat: 48.2257202, lng: 18.0334536 },
    { lat: 48.22571, lng: 18.033736 },
    { lat: 48.2266752, lng: 18.034879 },
    { lat: 48.2273746, lng: 18.0355907 },
    { lat: 48.2279102, lng: 18.0362651 },
    { lat: 48.2281638, lng: 18.0364893 },
    { lat: 48.2281166, lng: 18.037154 },
    { lat: 48.2279651, lng: 18.037474 },
    { lat: 48.2276513, lng: 18.0377367 },
    { lat: 48.2272261, lng: 18.0379332 },
    { lat: 48.2274771, lng: 18.0383754 },
    { lat: 48.2272791, lng: 18.0387077 },
    { lat: 48.2282176, lng: 18.0400282 },
    { lat: 48.2290969, lng: 18.0415308 },
    { lat: 48.2299037, lng: 18.0426867 },
    { lat: 48.2306674, lng: 18.0435503 },
    { lat: 48.2307582, lng: 18.0435024 },
    { lat: 48.2313433, lng: 18.0442897 },
    { lat: 48.2321052, lng: 18.0450311 },
    { lat: 48.2325066, lng: 18.0454987 },
    { lat: 48.2334552, lng: 18.0467629 },
    { lat: 48.2339906, lng: 18.0476429 },
    { lat: 48.2344177, lng: 18.0473464 },
    { lat: 48.2350628, lng: 18.0485356 },
    { lat: 48.2355377, lng: 18.0496913 },
    { lat: 48.2364428, lng: 18.0523712 },
    { lat: 48.2367401, lng: 18.0537661 },
    { lat: 48.2372859, lng: 18.0578119 },
    { lat: 48.2374237, lng: 18.0583451 },
    { lat: 48.2377196, lng: 18.0594695 },
    { lat: 48.2392075, lng: 18.0592672 },
    { lat: 48.2429811, lng: 18.0605519 },
    { lat: 48.2440149, lng: 18.0611075 },
    { lat: 48.2442838, lng: 18.061434 },
    { lat: 48.2445036, lng: 18.0617918 },
    { lat: 48.2451227, lng: 18.0646002 },
    { lat: 48.2458869, lng: 18.0672527 },
    { lat: 48.2464792, lng: 18.0684481 },
    { lat: 48.2466355, lng: 18.0695059 },
    { lat: 48.2469327, lng: 18.0700843 },
    { lat: 48.2480837, lng: 18.0718182 },
    { lat: 48.2484058, lng: 18.0722259 },
    { lat: 48.2489364, lng: 18.0731038 },
    { lat: 48.2493016, lng: 18.073708 },
    { lat: 48.2497969, lng: 18.0742404 },
    { lat: 48.2499178, lng: 18.0745289 },
    { lat: 48.2505011, lng: 18.0770259 },
    { lat: 48.2508649, lng: 18.0783307 },
    { lat: 48.2510212, lng: 18.0786425 },
    { lat: 48.2512803, lng: 18.080009 },
    { lat: 48.2513854, lng: 18.0803188 },
    { lat: 48.2515721, lng: 18.0806527 },
    { lat: 48.2521094, lng: 18.0812855 },
    { lat: 48.2522966, lng: 18.0816071 },
    { lat: 48.2530915, lng: 18.0801593 },
    { lat: 48.2539978, lng: 18.0789608 },
    { lat: 48.2551234, lng: 18.0777057 },
    { lat: 48.2561828, lng: 18.0761519 },
    { lat: 48.2567278, lng: 18.0754815 },
    { lat: 48.2571585, lng: 18.075055 },
    { lat: 48.2576298, lng: 18.0744382 },
    { lat: 48.2591328, lng: 18.0722013 },
    { lat: 48.2602095, lng: 18.0704849 },
    { lat: 48.2610621, lng: 18.0693297 },
    { lat: 48.262314, lng: 18.067886 },
    { lat: 48.2637729, lng: 18.0665422 },
    { lat: 48.2639956, lng: 18.0661502 },
    { lat: 48.2640136, lng: 18.0661302 },
    { lat: 48.2637817, lng: 18.0655128 },
    { lat: 48.263779, lng: 18.0654274 },
    { lat: 48.2639028, lng: 18.0653844 },
    { lat: 48.264871, lng: 18.0653454 },
    { lat: 48.265247, lng: 18.0652126 },
    { lat: 48.2666326, lng: 18.0635666 },
    { lat: 48.2672327, lng: 18.0630292 },
    { lat: 48.267828, lng: 18.0625977 },
    { lat: 48.2683148, lng: 18.0623956 },
    { lat: 48.2695294, lng: 18.0616758 },
    { lat: 48.2711142, lng: 18.0605551 },
    { lat: 48.2781713, lng: 18.0572408 },
    { lat: 48.2781824, lng: 18.0572358 },
    { lat: 48.2782446, lng: 18.0572003 },
    { lat: 48.2780377, lng: 18.0564905 },
    { lat: 48.2778242, lng: 18.0560285 },
    { lat: 48.2775334, lng: 18.0555963 },
    { lat: 48.2750163, lng: 18.0530442 },
    { lat: 48.2745338, lng: 18.0524448 },
    { lat: 48.2741061, lng: 18.0517516 },
    { lat: 48.2736894, lng: 18.0510522 },
    { lat: 48.2731573, lng: 18.050017 },
    { lat: 48.269034, lng: 18.0426893 },
    { lat: 48.2669123, lng: 18.0395218 },
    { lat: 48.2653363, lng: 18.0375421 },
    { lat: 48.2656135, lng: 18.0370571 },
    { lat: 48.2661422, lng: 18.0367258 },
    { lat: 48.2665782, lng: 18.0362575 },
    { lat: 48.2669978, lng: 18.0359128 },
    { lat: 48.2675154, lng: 18.0355256 },
    { lat: 48.2685835, lng: 18.0349414 },
    { lat: 48.2693769, lng: 18.0338971 },
    { lat: 48.2695243, lng: 18.0327137 },
    { lat: 48.2696757, lng: 18.0326786 },
    { lat: 48.2695116, lng: 18.0307754 },
    { lat: 48.2692757, lng: 18.0293722 },
    { lat: 48.2689141, lng: 18.0276191 },
    { lat: 48.2684769, lng: 18.0243737 },
    { lat: 48.2683056, lng: 18.0233725 },
    { lat: 48.267521, lng: 18.0207674 },
    { lat: 48.2674555, lng: 18.0205498 },
    { lat: 48.2671381, lng: 18.0190661 },
    { lat: 48.2670269, lng: 18.0182266 },
    { lat: 48.2669451, lng: 18.0176091 },
    { lat: 48.2669697, lng: 18.015334 },
    { lat: 48.2669193, lng: 18.0146985 },
    { lat: 48.2674842, lng: 18.0143331 },
    { lat: 48.2696893, lng: 18.0129068 },
    { lat: 48.2704029, lng: 18.0126454 },
    { lat: 48.2715511, lng: 18.0117803 },
    { lat: 48.2724898, lng: 18.0113396 },
    { lat: 48.2728233, lng: 18.0110831 },
    { lat: 48.2727923, lng: 18.0109997 },
    { lat: 48.2724266, lng: 18.0102839 },
    { lat: 48.2722354, lng: 18.0098905 },
    { lat: 48.2715256, lng: 18.0092525 },
    { lat: 48.2710427, lng: 18.0085312 },
    { lat: 48.2701688, lng: 18.0071282 },
    { lat: 48.2693017, lng: 18.0059594 },
    { lat: 48.2685827, lng: 18.0044672 },
    { lat: 48.267319, lng: 18.0026022 },
    { lat: 48.2663761, lng: 18.0020853 },
    { lat: 48.2652827, lng: 18.0012042 },
    { lat: 48.2651472, lng: 18.0009751 },
    { lat: 48.264186, lng: 18.0002666 },
    { lat: 48.2637873, lng: 17.9996942 },
    { lat: 48.2621617, lng: 17.9980883 },
    { lat: 48.2607621, lng: 17.9963906 },
    { lat: 48.2601923, lng: 17.9953194 },
    { lat: 48.2595773, lng: 17.9945469 },
    { lat: 48.2572813, lng: 17.9908074 },
    { lat: 48.2573087, lng: 17.9907198 },
    { lat: 48.2569293, lng: 17.9899809 },
    { lat: 48.2531734, lng: 17.9828506 },
    { lat: 48.2532235, lng: 17.9827826 },
    { lat: 48.252418, lng: 17.981071 },
  ],
  Paňa: [
    { lat: 48.2100202, lng: 18.2500893 },
    { lat: 48.2147225, lng: 18.2489016 },
    { lat: 48.2149159, lng: 18.248774 },
    { lat: 48.2156514, lng: 18.2485949 },
    { lat: 48.2158054, lng: 18.2484286 },
    { lat: 48.2158307, lng: 18.2482395 },
    { lat: 48.2159374, lng: 18.2481918 },
    { lat: 48.2165011, lng: 18.2482043 },
    { lat: 48.2168745, lng: 18.2483564 },
    { lat: 48.2168457, lng: 18.2491484 },
    { lat: 48.2167885, lng: 18.2493135 },
    { lat: 48.216895, lng: 18.2494381 },
    { lat: 48.2179103, lng: 18.2493549 },
    { lat: 48.2186705, lng: 18.2494874 },
    { lat: 48.2191876, lng: 18.249725 },
    { lat: 48.2204225, lng: 18.2497938 },
    { lat: 48.221255, lng: 18.2497519 },
    { lat: 48.2222802, lng: 18.249857 },
    { lat: 48.2251559, lng: 18.2505343 },
    { lat: 48.2256996, lng: 18.2507497 },
    { lat: 48.226114, lng: 18.2510264 },
    { lat: 48.226576, lng: 18.2516048 },
    { lat: 48.228428, lng: 18.2536671 },
    { lat: 48.2296947, lng: 18.2546376 },
    { lat: 48.2307865, lng: 18.2556084 },
    { lat: 48.2313295, lng: 18.2560469 },
    { lat: 48.2332451, lng: 18.2544309 },
    { lat: 48.2347143, lng: 18.253295 },
    { lat: 48.2356269, lng: 18.2527433 },
    { lat: 48.2363336, lng: 18.2524765 },
    { lat: 48.2376885, lng: 18.2524514 },
    { lat: 48.2405363, lng: 18.2525625 },
    { lat: 48.2421484, lng: 18.2528549 },
    { lat: 48.2432196, lng: 18.2532338 },
    { lat: 48.2445184, lng: 18.2535455 },
    { lat: 48.2449516, lng: 18.2517593 },
    { lat: 48.2461846, lng: 18.2519141 },
    { lat: 48.2462045, lng: 18.2515335 },
    { lat: 48.2468051, lng: 18.251616 },
    { lat: 48.2471704, lng: 18.2515738 },
    { lat: 48.2482406, lng: 18.2511109 },
    { lat: 48.2485934, lng: 18.2509256 },
    { lat: 48.2488402, lng: 18.2507071 },
    { lat: 48.2490664, lng: 18.2504595 },
    { lat: 48.2493869, lng: 18.2502637 },
    { lat: 48.2496021, lng: 18.2492808 },
    { lat: 48.2498163, lng: 18.2468878 },
    { lat: 48.2497651, lng: 18.2450803 },
    { lat: 48.2509018, lng: 18.2439534 },
    { lat: 48.251577, lng: 18.2433931 },
    { lat: 48.2528264, lng: 18.2427494 },
    { lat: 48.2560983, lng: 18.240181 },
    { lat: 48.2567748, lng: 18.2395822 },
    { lat: 48.2569203, lng: 18.2365515 },
    { lat: 48.2592266, lng: 18.2356113 },
    { lat: 48.2594341, lng: 18.2355497 },
    { lat: 48.2559078, lng: 18.2268166 },
    { lat: 48.2557884, lng: 18.2258271 },
    { lat: 48.2557636, lng: 18.225398 },
    { lat: 48.2558108, lng: 18.2245941 },
    { lat: 48.2557615, lng: 18.2244408 },
    { lat: 48.2554376, lng: 18.2239747 },
    { lat: 48.2553581, lng: 18.2235162 },
    { lat: 48.2548638, lng: 18.2238101 },
    { lat: 48.2546184, lng: 18.2224724 },
    { lat: 48.2545901, lng: 18.2218245 },
    { lat: 48.2529425, lng: 18.2216296 },
    { lat: 48.2520472, lng: 18.2216597 },
    { lat: 48.2505772, lng: 18.221976 },
    { lat: 48.2494379, lng: 18.2220452 },
    { lat: 48.2488546, lng: 18.2220656 },
    { lat: 48.2474385, lng: 18.2218906 },
    { lat: 48.2471593, lng: 18.2220178 },
    { lat: 48.2466049, lng: 18.2220897 },
    { lat: 48.2449842, lng: 18.2227109 },
    { lat: 48.2436861, lng: 18.2210809 },
    { lat: 48.2425695, lng: 18.2201357 },
    { lat: 48.2421814, lng: 18.2187534 },
    { lat: 48.2418023, lng: 18.2190256 },
    { lat: 48.2407358, lng: 18.2192178 },
    { lat: 48.2399265, lng: 18.2196074 },
    { lat: 48.2396356, lng: 18.2198423 },
    { lat: 48.2392061, lng: 18.2199472 },
    { lat: 48.2385217, lng: 18.2199584 },
    { lat: 48.2383086, lng: 18.2199206 },
    { lat: 48.2382436, lng: 18.2198108 },
    { lat: 48.238076, lng: 18.2198608 },
    { lat: 48.2379805, lng: 18.2193798 },
    { lat: 48.2372314, lng: 18.2197131 },
    { lat: 48.236481, lng: 18.2198507 },
    { lat: 48.2362179, lng: 18.2200597 },
    { lat: 48.2354012, lng: 18.2200279 },
    { lat: 48.2346583, lng: 18.220217 },
    { lat: 48.2334547, lng: 18.2202825 },
    { lat: 48.2332249, lng: 18.2205597 },
    { lat: 48.2330081, lng: 18.2205195 },
    { lat: 48.2329107, lng: 18.2205732 },
    { lat: 48.2328407, lng: 18.2207284 },
    { lat: 48.2328069, lng: 18.220982 },
    { lat: 48.2326834, lng: 18.2211282 },
    { lat: 48.2321264, lng: 18.221273 },
    { lat: 48.2317336, lng: 18.2214839 },
    { lat: 48.2316199, lng: 18.2215235 },
    { lat: 48.2310765, lng: 18.2217128 },
    { lat: 48.2296987, lng: 18.2219257 },
    { lat: 48.2294321, lng: 18.221904 },
    { lat: 48.2280377, lng: 18.2225488 },
    { lat: 48.2271961, lng: 18.2227488 },
    { lat: 48.227189, lng: 18.2232098 },
    { lat: 48.2268151, lng: 18.2234996 },
    { lat: 48.226479, lng: 18.2236574 },
    { lat: 48.2260708, lng: 18.2237376 },
    { lat: 48.2260182, lng: 18.2236096 },
    { lat: 48.2243485, lng: 18.2235588 },
    { lat: 48.2234416, lng: 18.2238345 },
    { lat: 48.2228487, lng: 18.2239279 },
    { lat: 48.2225988, lng: 18.2240346 },
    { lat: 48.2222304, lng: 18.2243166 },
    { lat: 48.2221095, lng: 18.2240814 },
    { lat: 48.2216875, lng: 18.2241566 },
    { lat: 48.2213545, lng: 18.2240568 },
    { lat: 48.221346, lng: 18.223812 },
    { lat: 48.2212951, lng: 18.2237535 },
    { lat: 48.220892, lng: 18.2236323 },
    { lat: 48.2207441, lng: 18.2232215 },
    { lat: 48.2206205, lng: 18.223251 },
    { lat: 48.2201393, lng: 18.2236523 },
    { lat: 48.2201551, lng: 18.223962 },
    { lat: 48.2201288, lng: 18.2240921 },
    { lat: 48.220034, lng: 18.2241893 },
    { lat: 48.2195899, lng: 18.2240059 },
    { lat: 48.2194001, lng: 18.2246815 },
    { lat: 48.219022, lng: 18.2246031 },
    { lat: 48.2189677, lng: 18.2253242 },
    { lat: 48.2188291, lng: 18.2257155 },
    { lat: 48.2171008, lng: 18.2259966 },
    { lat: 48.2171182, lng: 18.2262566 },
    { lat: 48.2160892, lng: 18.2265324 },
    { lat: 48.2149113, lng: 18.2255004 },
    { lat: 48.2143428, lng: 18.225135 },
    { lat: 48.214012, lng: 18.2247229 },
    { lat: 48.213483, lng: 18.2236147 },
    { lat: 48.21305, lng: 18.2232501 },
    { lat: 48.2128842, lng: 18.223037 },
    { lat: 48.2105663, lng: 18.2251787 },
    { lat: 48.2105429, lng: 18.2252405 },
    { lat: 48.2105522, lng: 18.2262436 },
    { lat: 48.2102239, lng: 18.2278597 },
    { lat: 48.2099612, lng: 18.2286091 },
    { lat: 48.2096864, lng: 18.2292008 },
    { lat: 48.2094776, lng: 18.2294956 },
    { lat: 48.2073421, lng: 18.2333078 },
    { lat: 48.2071405, lng: 18.2336677 },
    { lat: 48.2066307, lng: 18.2348051 },
    { lat: 48.2063885, lng: 18.2354958 },
    { lat: 48.2062578, lng: 18.2357569 },
    { lat: 48.2059302, lng: 18.2364111 },
    { lat: 48.2057202, lng: 18.2367554 },
    { lat: 48.2046018, lng: 18.2385927 },
    { lat: 48.204358, lng: 18.239076 },
    { lat: 48.204384, lng: 18.23914 },
    { lat: 48.204843, lng: 18.239999 },
    { lat: 48.205292, lng: 18.240836 },
    { lat: 48.205746, lng: 18.241679 },
    { lat: 48.206136, lng: 18.242405 },
    { lat: 48.206316, lng: 18.242738 },
    { lat: 48.206332, lng: 18.242777 },
    { lat: 48.206682, lng: 18.243723 },
    { lat: 48.206851, lng: 18.244199 },
    { lat: 48.2068815, lng: 18.2443647 },
    { lat: 48.2070064, lng: 18.2450419 },
    { lat: 48.2092804, lng: 18.2488649 },
    { lat: 48.2099035, lng: 18.2498962 },
    { lat: 48.2100202, lng: 18.2500893 },
  ],
  IvankapriNitre: [
    { lat: 48.2380793, lng: 18.1550544 },
    { lat: 48.2394583, lng: 18.155118 },
    { lat: 48.240006, lng: 18.1550859 },
    { lat: 48.2404423, lng: 18.1549445 },
    { lat: 48.2425438, lng: 18.1537699 },
    { lat: 48.2429768, lng: 18.153265 },
    { lat: 48.2430356, lng: 18.1527822 },
    { lat: 48.2437441, lng: 18.1512176 },
    { lat: 48.243983, lng: 18.1505753 },
    { lat: 48.2440108, lng: 18.1502937 },
    { lat: 48.2439603, lng: 18.1500504 },
    { lat: 48.2438874, lng: 18.1499427 },
    { lat: 48.243767, lng: 18.1499104 },
    { lat: 48.2431675, lng: 18.1500385 },
    { lat: 48.2430786, lng: 18.1499872 },
    { lat: 48.2429614, lng: 18.1497951 },
    { lat: 48.242961, lng: 18.1496174 },
    { lat: 48.2430364, lng: 18.1494765 },
    { lat: 48.2434592, lng: 18.1491032 },
    { lat: 48.2435935, lng: 18.1488328 },
    { lat: 48.2436407, lng: 18.148156 },
    { lat: 48.2435403, lng: 18.1476923 },
    { lat: 48.2434164, lng: 18.1474254 },
    { lat: 48.2429629, lng: 18.1467357 },
    { lat: 48.2427229, lng: 18.146223 },
    { lat: 48.2427208, lng: 18.1455485 },
    { lat: 48.2427737, lng: 18.1453536 },
    { lat: 48.2429788, lng: 18.1449833 },
    { lat: 48.2430436, lng: 18.1447206 },
    { lat: 48.2430353, lng: 18.1441747 },
    { lat: 48.2430725, lng: 18.1439425 },
    { lat: 48.2433849, lng: 18.1434006 },
    { lat: 48.2434396, lng: 18.1431966 },
    { lat: 48.243563, lng: 18.1420648 },
    { lat: 48.2436564, lng: 18.1419637 },
    { lat: 48.243822, lng: 18.1419402 },
    { lat: 48.2441569, lng: 18.1422081 },
    { lat: 48.2443345, lng: 18.1421965 },
    { lat: 48.2444315, lng: 18.142079 },
    { lat: 48.2444787, lng: 18.14165 },
    { lat: 48.2445725, lng: 18.1413449 },
    { lat: 48.2447278, lng: 18.1412601 },
    { lat: 48.2449666, lng: 18.1412713 },
    { lat: 48.24509, lng: 18.1411453 },
    { lat: 48.2452432, lng: 18.1404259 },
    { lat: 48.2452525, lng: 18.1401404 },
    { lat: 48.245139, lng: 18.1397046 },
    { lat: 48.2451283, lng: 18.1393484 },
    { lat: 48.2452077, lng: 18.1391659 },
    { lat: 48.2453712, lng: 18.139199 },
    { lat: 48.2454972, lng: 18.1393676 },
    { lat: 48.2456618, lng: 18.1394214 },
    { lat: 48.2462519, lng: 18.139265 },
    { lat: 48.2463827, lng: 18.1391288 },
    { lat: 48.2491765, lng: 18.1340973 },
    { lat: 48.2505564, lng: 18.1302221 },
    { lat: 48.2505321, lng: 18.1300412 },
    { lat: 48.2501433, lng: 18.1293555 },
    { lat: 48.2501229, lng: 18.1290069 },
    { lat: 48.2498985, lng: 18.128722 },
    { lat: 48.2498535, lng: 18.1284754 },
    { lat: 48.2501774, lng: 18.1280908 },
    { lat: 48.2516323, lng: 18.1257656 },
    { lat: 48.2523752, lng: 18.1260988 },
    { lat: 48.2530581, lng: 18.1266612 },
    { lat: 48.2535146, lng: 18.1263451 },
    { lat: 48.2539059, lng: 18.1264367 },
    { lat: 48.2540391, lng: 18.1265293 },
    { lat: 48.255542, lng: 18.1256034 },
    { lat: 48.2560024, lng: 18.1263732 },
    { lat: 48.2562161, lng: 18.1264772 },
    { lat: 48.2564251, lng: 18.1264037 },
    { lat: 48.2565336, lng: 18.1265597 },
    { lat: 48.2583139, lng: 18.1241836 },
    { lat: 48.2589826, lng: 18.1233111 },
    { lat: 48.259044, lng: 18.1231447 },
    { lat: 48.2591393, lng: 18.122154 },
    { lat: 48.2591096, lng: 18.121705 },
    { lat: 48.2590452, lng: 18.1214344 },
    { lat: 48.2589736, lng: 18.1212738 },
    { lat: 48.2587536, lng: 18.1211402 },
    { lat: 48.2585989, lng: 18.1209085 },
    { lat: 48.2583795, lng: 18.1207096 },
    { lat: 48.2582881, lng: 18.1204366 },
    { lat: 48.2580274, lng: 18.1199606 },
    { lat: 48.2576019, lng: 18.1193994 },
    { lat: 48.257399, lng: 18.1186548 },
    { lat: 48.2571469, lng: 18.1183771 },
    { lat: 48.2567408, lng: 18.1168472 },
    { lat: 48.2568349, lng: 18.1155267 },
    { lat: 48.2561449, lng: 18.1127367 },
    { lat: 48.2560105, lng: 18.1125257 },
    { lat: 48.2549496, lng: 18.1101049 },
    { lat: 48.2537345, lng: 18.1070211 },
    { lat: 48.2528379, lng: 18.1043133 },
    { lat: 48.2515908, lng: 18.0998469 },
    { lat: 48.2514808, lng: 18.0989751 },
    { lat: 48.2514368, lng: 18.0981732 },
    { lat: 48.2510682, lng: 18.0961327 },
    { lat: 48.2509626, lng: 18.0957568 },
    { lat: 48.2504558, lng: 18.0948344 },
    { lat: 48.2496582, lng: 18.0939973 },
    { lat: 48.2494741, lng: 18.0936905 },
    { lat: 48.2493473, lng: 18.0936467 },
    { lat: 48.2488098, lng: 18.0923769 },
    { lat: 48.2486022, lng: 18.0915357 },
    { lat: 48.248021, lng: 18.0897208 },
    { lat: 48.2472381, lng: 18.0882526 },
    { lat: 48.2470244, lng: 18.0877692 },
    { lat: 48.2447479, lng: 18.0879008 },
    { lat: 48.2428706, lng: 18.0882537 },
    { lat: 48.2422642, lng: 18.0882847 },
    { lat: 48.2417131, lng: 18.0882992 },
    { lat: 48.2415389, lng: 18.0882376 },
    { lat: 48.241267, lng: 18.0880063 },
    { lat: 48.2409704, lng: 18.0879344 },
    { lat: 48.2402165, lng: 18.0881457 },
    { lat: 48.2391495, lng: 18.0885962 },
    { lat: 48.2388008, lng: 18.0885578 },
    { lat: 48.2384512, lng: 18.0883433 },
    { lat: 48.2383488, lng: 18.0882805 },
    { lat: 48.2380618, lng: 18.0878533 },
    { lat: 48.2376075, lng: 18.0869282 },
    { lat: 48.2373589, lng: 18.0867321 },
    { lat: 48.2359737, lng: 18.0860937 },
    { lat: 48.2354908, lng: 18.0859633 },
    { lat: 48.2338155, lng: 18.0857825 },
    { lat: 48.2320186, lng: 18.0853184 },
    { lat: 48.2300075, lng: 18.0839814 },
    { lat: 48.2287479, lng: 18.0828725 },
    { lat: 48.2283927, lng: 18.0824573 },
    { lat: 48.2281322, lng: 18.0820985 },
    { lat: 48.2259454, lng: 18.0846093 },
    { lat: 48.2242113, lng: 18.0867167 },
    { lat: 48.213907, lng: 18.0995047 },
    { lat: 48.2183224, lng: 18.1027641 },
    { lat: 48.2164732, lng: 18.104689 },
    { lat: 48.2172477, lng: 18.1061505 },
    { lat: 48.2177981, lng: 18.1072754 },
    { lat: 48.2182002, lng: 18.1082412 },
    { lat: 48.218779, lng: 18.1093674 },
    { lat: 48.2183717, lng: 18.1101946 },
    { lat: 48.217656, lng: 18.111129 },
    { lat: 48.2175907, lng: 18.1111883 },
    { lat: 48.2183961, lng: 18.1126222 },
    { lat: 48.2193684, lng: 18.115376 },
    { lat: 48.2198924, lng: 18.1165905 },
    { lat: 48.2200369, lng: 18.1164168 },
    { lat: 48.2202754, lng: 18.1168855 },
    { lat: 48.2224483, lng: 18.122652 },
    { lat: 48.2246846, lng: 18.128296 },
    { lat: 48.2271372, lng: 18.1340119 },
    { lat: 48.2274728, lng: 18.1345769 },
    { lat: 48.2285575, lng: 18.1368139 },
    { lat: 48.229906, lng: 18.1400849 },
    { lat: 48.230712, lng: 18.1422086 },
    { lat: 48.2310127, lng: 18.1427766 },
    { lat: 48.2312503, lng: 18.142595 },
    { lat: 48.2316166, lng: 18.1426067 },
    { lat: 48.2320429, lng: 18.142984 },
    { lat: 48.2327375, lng: 18.1434217 },
    { lat: 48.2327158, lng: 18.1435527 },
    { lat: 48.2336546, lng: 18.1465734 },
    { lat: 48.2346764, lng: 18.1457371 },
    { lat: 48.2355672, lng: 18.147632 },
    { lat: 48.2367472, lng: 18.1506904 },
    { lat: 48.2381715, lng: 18.1545674 },
    { lat: 48.2380793, lng: 18.1550544 },
  ],
  Mojmírovce: [
    { lat: 48.2281322, lng: 18.0820985 },
    { lat: 48.2273535, lng: 18.0810683 },
    { lat: 48.2270704, lng: 18.0806322 },
    { lat: 48.2267301, lng: 18.0801081 },
    { lat: 48.225935, lng: 18.0785324 },
    { lat: 48.2256984, lng: 18.0780635 },
    { lat: 48.2256557, lng: 18.077913 },
    { lat: 48.2256532, lng: 18.0772777 },
    { lat: 48.2255903, lng: 18.0770546 },
    { lat: 48.2248685, lng: 18.0752924 },
    { lat: 48.2240915, lng: 18.0729558 },
    { lat: 48.2238134, lng: 18.0723567 },
    { lat: 48.2234506, lng: 18.0718339 },
    { lat: 48.2225166, lng: 18.0697833 },
    { lat: 48.2222458, lng: 18.0690972 },
    { lat: 48.2217778, lng: 18.06752 },
    { lat: 48.2214381, lng: 18.0666449 },
    { lat: 48.2212692, lng: 18.0665013 },
    { lat: 48.2210149, lng: 18.0665308 },
    { lat: 48.2202459, lng: 18.0640105 },
    { lat: 48.2200677, lng: 18.0634824 },
    { lat: 48.2196948, lng: 18.0626538 },
    { lat: 48.2195929, lng: 18.0622869 },
    { lat: 48.2194866, lng: 18.0618668 },
    { lat: 48.2197322, lng: 18.0618211 },
    { lat: 48.2193805, lng: 18.060093 },
    { lat: 48.2191481, lng: 18.0602502 },
    { lat: 48.2189047, lng: 18.0597036 },
    { lat: 48.217517, lng: 18.0560033 },
    { lat: 48.2145916, lng: 18.0507556 },
    { lat: 48.2121884, lng: 18.0468368 },
    { lat: 48.2120199, lng: 18.0464887 },
    { lat: 48.2105968, lng: 18.0434574 },
    { lat: 48.2095329, lng: 18.0409789 },
    { lat: 48.2094436, lng: 18.0408814 },
    { lat: 48.2082784, lng: 18.0373649 },
    { lat: 48.207815, lng: 18.036518 },
    { lat: 48.2072103, lng: 18.0345532 },
    { lat: 48.2025745, lng: 18.026305 },
    { lat: 48.202136, lng: 18.0256708 },
    { lat: 48.201775, lng: 18.0247287 },
    { lat: 48.2010146, lng: 18.0230673 },
    { lat: 48.2003041, lng: 18.0217558 },
    { lat: 48.1999146, lng: 18.021546 },
    { lat: 48.1997257, lng: 18.0219155 },
    { lat: 48.198612, lng: 18.0239819 },
    { lat: 48.1985198, lng: 18.024065 },
    { lat: 48.1983983, lng: 18.0244113 },
    { lat: 48.1980655, lng: 18.0250016 },
    { lat: 48.1945694, lng: 18.0268813 },
    { lat: 48.1929886, lng: 18.027136 },
    { lat: 48.1928081, lng: 18.0272142 },
    { lat: 48.1923847, lng: 18.0277609 },
    { lat: 48.1913514, lng: 18.0295856 },
    { lat: 48.190021, lng: 18.0303275 },
    { lat: 48.1884712, lng: 18.0321813 },
    { lat: 48.1865065, lng: 18.0348191 },
    { lat: 48.1855968, lng: 18.0377562 },
    { lat: 48.1858636, lng: 18.0395446 },
    { lat: 48.1853883, lng: 18.0400549 },
    { lat: 48.1844752, lng: 18.0408498 },
    { lat: 48.1829539, lng: 18.0429786 },
    { lat: 48.1821004, lng: 18.0441874 },
    { lat: 48.1809449, lng: 18.046447 },
    { lat: 48.1771858, lng: 18.0405547 },
    { lat: 48.1771548, lng: 18.040603 },
    { lat: 48.1771033, lng: 18.0404758 },
    { lat: 48.1771262, lng: 18.040447 },
    { lat: 48.17427, lng: 18.035886 },
    { lat: 48.1742188, lng: 18.0358613 },
    { lat: 48.1720587, lng: 18.039521 },
    { lat: 48.1714229, lng: 18.040591 },
    { lat: 48.1697962, lng: 18.0433171 },
    { lat: 48.1736232, lng: 18.0489477 },
    { lat: 48.1827001, lng: 18.0620863 },
    { lat: 48.181977, lng: 18.0623594 },
    { lat: 48.1814903, lng: 18.0628091 },
    { lat: 48.1819134, lng: 18.0637035 },
    { lat: 48.183625, lng: 18.0672322 },
    { lat: 48.1832324, lng: 18.0689683 },
    { lat: 48.1835773, lng: 18.0710925 },
    { lat: 48.1827249, lng: 18.0719248 },
    { lat: 48.193086, lng: 18.0911504 },
    { lat: 48.1965828, lng: 18.0977519 },
    { lat: 48.19661, lng: 18.0977921 },
    { lat: 48.196818, lng: 18.0974138 },
    { lat: 48.1983643, lng: 18.0942619 },
    { lat: 48.1987338, lng: 18.0952447 },
    { lat: 48.2020363, lng: 18.0918666 },
    { lat: 48.2036954, lng: 18.0954051 },
    { lat: 48.2046318, lng: 18.0944419 },
    { lat: 48.2064708, lng: 18.098355 },
    { lat: 48.2085911, lng: 18.0962841 },
    { lat: 48.2114451, lng: 18.1024441 },
    { lat: 48.2100446, lng: 18.1039175 },
    { lat: 48.2125027, lng: 18.1088632 },
    { lat: 48.2125336, lng: 18.1088291 },
    { lat: 48.2145154, lng: 18.1128186 },
    { lat: 48.2151358, lng: 18.1139661 },
    { lat: 48.215216, lng: 18.1138699 },
    { lat: 48.2162976, lng: 18.1125554 },
    { lat: 48.2175907, lng: 18.1111883 },
    { lat: 48.217656, lng: 18.111129 },
    { lat: 48.2183717, lng: 18.1101946 },
    { lat: 48.218779, lng: 18.1093674 },
    { lat: 48.2182002, lng: 18.1082412 },
    { lat: 48.2177981, lng: 18.1072754 },
    { lat: 48.2172477, lng: 18.1061505 },
    { lat: 48.2164732, lng: 18.104689 },
    { lat: 48.2183224, lng: 18.1027641 },
    { lat: 48.213907, lng: 18.0995047 },
    { lat: 48.2242113, lng: 18.0867167 },
    { lat: 48.2259454, lng: 18.0846093 },
    { lat: 48.2281322, lng: 18.0820985 },
  ],
  Čab: [
    { lat: 48.414516, lng: 18.0087953 },
    { lat: 48.4138417, lng: 18.0059846 },
    { lat: 48.4134094, lng: 18.0047349 },
    { lat: 48.4129145, lng: 18.0027167 },
    { lat: 48.4123375, lng: 18.0009319 },
    { lat: 48.4117259, lng: 17.9985884 },
    { lat: 48.4099595, lng: 17.9927035 },
    { lat: 48.4092814, lng: 17.9908536 },
    { lat: 48.4088518, lng: 17.9900671 },
    { lat: 48.4091834, lng: 17.9890621 },
    { lat: 48.4094146, lng: 17.9881123 },
    { lat: 48.410198, lng: 17.9854342 },
    { lat: 48.4103566, lng: 17.9848937 },
    { lat: 48.4088222, lng: 17.9832252 },
    { lat: 48.4085142, lng: 17.9816226 },
    { lat: 48.4083572, lng: 17.9801478 },
    { lat: 48.407932, lng: 17.9780768 },
    { lat: 48.4068259, lng: 17.976193 },
    { lat: 48.4064633, lng: 17.9762039 },
    { lat: 48.4058005, lng: 17.9764842 },
    { lat: 48.4057988, lng: 17.9765505 },
    { lat: 48.4055912, lng: 17.9765121 },
    { lat: 48.405207, lng: 17.9766567 },
    { lat: 48.404634, lng: 17.9767027 },
    { lat: 48.4044457, lng: 17.97678 },
    { lat: 48.4042726, lng: 17.9769626 },
    { lat: 48.4041797, lng: 17.977276 },
    { lat: 48.4039551, lng: 17.9775034 },
    { lat: 48.4034185, lng: 17.978271 },
    { lat: 48.4018253, lng: 17.9803157 },
    { lat: 48.3999487, lng: 17.9785723 },
    { lat: 48.3994649, lng: 17.9777074 },
    { lat: 48.3986655, lng: 17.976555 },
    { lat: 48.397583, lng: 17.9748444 },
    { lat: 48.3975485, lng: 17.9747899 },
    { lat: 48.3961201, lng: 17.9726677 },
    { lat: 48.395173, lng: 17.9714779 },
    { lat: 48.3946715, lng: 17.9706989 },
    { lat: 48.3939656, lng: 17.9693541 },
    { lat: 48.3937138, lng: 17.9686373 },
    { lat: 48.3921954, lng: 17.9649223 },
    { lat: 48.3920389, lng: 17.9645368 },
    { lat: 48.389514, lng: 17.9680023 },
    { lat: 48.3894757, lng: 17.9680565 },
    { lat: 48.390066, lng: 17.9695833 },
    { lat: 48.3904836, lng: 17.9709798 },
    { lat: 48.3912278, lng: 17.9738135 },
    { lat: 48.3915123, lng: 17.9750561 },
    { lat: 48.3887288, lng: 17.9772053 },
    { lat: 48.3868347, lng: 17.9785446 },
    { lat: 48.3855564, lng: 17.9779298 },
    { lat: 48.3853611, lng: 17.9785904 },
    { lat: 48.3853805, lng: 17.9788747 },
    { lat: 48.3851549, lng: 17.9797648 },
    { lat: 48.3851516, lng: 17.9800664 },
    { lat: 48.3850158, lng: 17.9809309 },
    { lat: 48.3847155, lng: 17.9814184 },
    { lat: 48.3846443, lng: 17.9817945 },
    { lat: 48.3844271, lng: 17.9822406 },
    { lat: 48.3843948, lng: 17.982436 },
    { lat: 48.3842122, lng: 17.9829897 },
    { lat: 48.3842499, lng: 17.9837854 },
    { lat: 48.3843761, lng: 17.9841039 },
    { lat: 48.3843781, lng: 17.9844378 },
    { lat: 48.3844593, lng: 17.9847052 },
    { lat: 48.3844483, lng: 17.9850309 },
    { lat: 48.3845803, lng: 17.9862939 },
    { lat: 48.384905, lng: 17.9873254 },
    { lat: 48.3850008, lng: 17.9879829 },
    { lat: 48.3850429, lng: 17.9887833 },
    { lat: 48.3851258, lng: 17.9890924 },
    { lat: 48.3851959, lng: 17.9900218 },
    { lat: 48.3851481, lng: 17.9902978 },
    { lat: 48.385178, lng: 17.9915195 },
    { lat: 48.3849431, lng: 17.9926867 },
    { lat: 48.3854615, lng: 17.992618 },
    { lat: 48.385979, lng: 17.9924159 },
    { lat: 48.3870948, lng: 17.9913538 },
    { lat: 48.3872521, lng: 17.9917385 },
    { lat: 48.3875243, lng: 17.9917576 },
    { lat: 48.3886713, lng: 17.9942123 },
    { lat: 48.3887251, lng: 17.9943275 },
    { lat: 48.3888694, lng: 17.9948546 },
    { lat: 48.3888912, lng: 17.9952959 },
    { lat: 48.3888591, lng: 17.9956052 },
    { lat: 48.3885537, lng: 17.9964294 },
    { lat: 48.3882159, lng: 17.9975582 },
    { lat: 48.3871737, lng: 18.0002569 },
    { lat: 48.3870147, lng: 18.000545 },
    { lat: 48.3869686, lng: 18.0007554 },
    { lat: 48.3868409, lng: 18.0008272 },
    { lat: 48.3867393, lng: 18.0009801 },
    { lat: 48.3865888, lng: 18.0013107 },
    { lat: 48.3858659, lng: 18.0024391 },
    { lat: 48.3851184, lng: 18.0040604 },
    { lat: 48.3858878, lng: 18.0062345 },
    { lat: 48.3872729, lng: 18.0087571 },
    { lat: 48.3876366, lng: 18.009558 },
    { lat: 48.3882792, lng: 18.010737 },
    { lat: 48.3894322, lng: 18.0131564 },
    { lat: 48.3901568, lng: 18.0143105 },
    { lat: 48.3915486, lng: 18.0171402 },
    { lat: 48.3917289, lng: 18.0174598 },
    { lat: 48.3922445, lng: 18.0180998 },
    { lat: 48.3922591, lng: 18.0199395 },
    { lat: 48.3922512, lng: 18.02036 },
    { lat: 48.3931111, lng: 18.0203345 },
    { lat: 48.3941644, lng: 18.0203971 },
    { lat: 48.3945167, lng: 18.0203439 },
    { lat: 48.3956662, lng: 18.0199148 },
    { lat: 48.3960569, lng: 18.0194814 },
    { lat: 48.3964391, lng: 18.0189244 },
    { lat: 48.3969343, lng: 18.0180478 },
    { lat: 48.3971407, lng: 18.0178557 },
    { lat: 48.3987226, lng: 18.0173718 },
    { lat: 48.3991454, lng: 18.0171169 },
    { lat: 48.3998896, lng: 18.0165191 },
    { lat: 48.4015301, lng: 18.0159746 },
    { lat: 48.4022475, lng: 18.0155701 },
    { lat: 48.4030042, lng: 18.0149524 },
    { lat: 48.4033103, lng: 18.0148054 },
    { lat: 48.4050387, lng: 18.0142051 },
    { lat: 48.4059161, lng: 18.0138696 },
    { lat: 48.4066771, lng: 18.013474 },
    { lat: 48.4075199, lng: 18.0132066 },
    { lat: 48.4078298, lng: 18.0129693 },
    { lat: 48.407877, lng: 18.0127573 },
    { lat: 48.4081848, lng: 18.0124875 },
    { lat: 48.408411, lng: 18.0123815 },
    { lat: 48.4090596, lng: 18.0116926 },
    { lat: 48.4100131, lng: 18.0110618 },
    { lat: 48.4102682, lng: 18.010969 },
    { lat: 48.4108234, lng: 18.0111175 },
    { lat: 48.4109936, lng: 18.0110758 },
    { lat: 48.4121426, lng: 18.0101466 },
    { lat: 48.4135858, lng: 18.0095353 },
    { lat: 48.4141351, lng: 18.0091676 },
    { lat: 48.4144069, lng: 18.0088782 },
    { lat: 48.414516, lng: 18.0087953 },
  ],
  PoľnýKesov: [
    { lat: 48.1720587, lng: 18.039521 },
    { lat: 48.1719344, lng: 18.0393275 },
    { lat: 48.1659565, lng: 18.0303793 },
    { lat: 48.1575486, lng: 18.0135475 },
    { lat: 48.157402, lng: 18.013297 },
    { lat: 48.157156, lng: 18.013609 },
    { lat: 48.15678, lng: 18.014075 },
    { lat: 48.156533, lng: 18.014436 },
    { lat: 48.156397, lng: 18.014638 },
    { lat: 48.156183, lng: 18.015006 },
    { lat: 48.155918, lng: 18.015448 },
    { lat: 48.155686, lng: 18.015815 },
    { lat: 48.155396, lng: 18.016277 },
    { lat: 48.155178, lng: 18.016502 },
    { lat: 48.154935, lng: 18.016769 },
    { lat: 48.154613, lng: 18.017033 },
    { lat: 48.154339, lng: 18.01727 },
    { lat: 48.154256, lng: 18.017374 },
    { lat: 48.153933, lng: 18.017761 },
    { lat: 48.1538, lng: 18.017914 },
    { lat: 48.153347, lng: 18.018465 },
    { lat: 48.153201, lng: 18.018648 },
    { lat: 48.153284, lng: 18.019585 },
    { lat: 48.15338, lng: 18.020749 },
    { lat: 48.153466, lng: 18.021761 },
    { lat: 48.153514, lng: 18.022345 },
    { lat: 48.153562, lng: 18.022912 },
    { lat: 48.153575, lng: 18.023115 },
    { lat: 48.153744, lng: 18.024197 },
    { lat: 48.153892, lng: 18.02514 },
    { lat: 48.154066, lng: 18.026252 },
    { lat: 48.154111, lng: 18.026568 },
    { lat: 48.154149, lng: 18.02681 },
    { lat: 48.154108, lng: 18.026842 },
    { lat: 48.153728, lng: 18.027136 },
    { lat: 48.153357, lng: 18.027424 },
    { lat: 48.153231, lng: 18.027521 },
    { lat: 48.152763, lng: 18.027864 },
    { lat: 48.152355, lng: 18.028169 },
    { lat: 48.152052, lng: 18.028385 },
    { lat: 48.151533, lng: 18.028805 },
    { lat: 48.150946, lng: 18.029286 },
    { lat: 48.150327, lng: 18.029817 },
    { lat: 48.149527, lng: 18.030516 },
    { lat: 48.148802, lng: 18.031139 },
    { lat: 48.148341, lng: 18.031534 },
    { lat: 48.147781, lng: 18.032111 },
    { lat: 48.147569, lng: 18.032329 },
    { lat: 48.147203, lng: 18.032705 },
    { lat: 48.146429, lng: 18.033414 },
    { lat: 48.145877, lng: 18.033924 },
    { lat: 48.145279, lng: 18.034463 },
    { lat: 48.144627, lng: 18.035047 },
    { lat: 48.144541, lng: 18.035118 },
    { lat: 48.144061, lng: 18.035587 },
    { lat: 48.143558, lng: 18.03609 },
    { lat: 48.143131, lng: 18.036507 },
    { lat: 48.142721, lng: 18.036926 },
    { lat: 48.142017, lng: 18.037822 },
    { lat: 48.141295, lng: 18.038686 },
    { lat: 48.142455, lng: 18.040627 },
    { lat: 48.143596, lng: 18.042513 },
    { lat: 48.143639, lng: 18.042584 },
    { lat: 48.144714, lng: 18.044408 },
    { lat: 48.145863, lng: 18.046344 },
    { lat: 48.14698, lng: 18.048258 },
    { lat: 48.148125, lng: 18.050158 },
    { lat: 48.14981, lng: 18.053057 },
    { lat: 48.15037, lng: 18.054012 },
    { lat: 48.151078, lng: 18.055175 },
    { lat: 48.151524, lng: 18.055951 },
    { lat: 48.152651, lng: 18.057839 },
    { lat: 48.153798, lng: 18.059748 },
    { lat: 48.15493, lng: 18.061655 },
    { lat: 48.155966, lng: 18.063372 },
    { lat: 48.156049, lng: 18.063512 },
    { lat: 48.157041, lng: 18.065159 },
    { lat: 48.157905, lng: 18.066584 },
    { lat: 48.158479, lng: 18.067571 },
    { lat: 48.159617, lng: 18.069481 },
    { lat: 48.160747, lng: 18.071395 },
    { lat: 48.161106, lng: 18.071992 },
    { lat: 48.161144, lng: 18.072058 },
    { lat: 48.161235, lng: 18.07221 },
    { lat: 48.161785, lng: 18.073129 },
    { lat: 48.162796, lng: 18.075207 },
    { lat: 48.163789, lng: 18.077292 },
    { lat: 48.164792, lng: 18.079384 },
    { lat: 48.165819, lng: 18.081439 },
    { lat: 48.166214, lng: 18.082264 },
    { lat: 48.1716983, lng: 18.0775449 },
    { lat: 48.1764106, lng: 18.072408 },
    { lat: 48.1779101, lng: 18.0723412 },
    { lat: 48.1757471, lng: 18.0645825 },
    { lat: 48.1810731, lng: 18.0631961 },
    { lat: 48.1814903, lng: 18.0628091 },
    { lat: 48.181977, lng: 18.0623594 },
    { lat: 48.1827001, lng: 18.0620863 },
    { lat: 48.1736232, lng: 18.0489477 },
    { lat: 48.1697962, lng: 18.0433171 },
    { lat: 48.1714229, lng: 18.040591 },
    { lat: 48.1720587, lng: 18.039521 },
  ],
  Podhorany: [
    { lat: 48.3893533, lng: 18.0914584 },
    { lat: 48.389305, lng: 18.0914051 },
    { lat: 48.3889493, lng: 18.0909989 },
    { lat: 48.3894998, lng: 18.0901351 },
    { lat: 48.3890944, lng: 18.0895143 },
    { lat: 48.388446, lng: 18.0892548 },
    { lat: 48.3880309, lng: 18.0892414 },
    { lat: 48.3873066, lng: 18.0897248 },
    { lat: 48.3869549, lng: 18.0895957 },
    { lat: 48.3864039, lng: 18.0895155 },
    { lat: 48.3865441, lng: 18.0890612 },
    { lat: 48.3867174, lng: 18.0888994 },
    { lat: 48.3870092, lng: 18.088871 },
    { lat: 48.3874391, lng: 18.089023 },
    { lat: 48.3876497, lng: 18.0889962 },
    { lat: 48.3877257, lng: 18.0889257 },
    { lat: 48.3879123, lng: 18.0882998 },
    { lat: 48.3878855, lng: 18.0880726 },
    { lat: 48.3877914, lng: 18.0877996 },
    { lat: 48.3876572, lng: 18.0875891 },
    { lat: 48.387296, lng: 18.0875323 },
    { lat: 48.3869222, lng: 18.0872773 },
    { lat: 48.3868117, lng: 18.0874528 },
    { lat: 48.3866679, lng: 18.0881717 },
    { lat: 48.3865751, lng: 18.0882454 },
    { lat: 48.386436, lng: 18.0881815 },
    { lat: 48.3863584, lng: 18.088032 },
    { lat: 48.3863434, lng: 18.0878168 },
    { lat: 48.3863875, lng: 18.0872038 },
    { lat: 48.3864625, lng: 18.0869275 },
    { lat: 48.3869233, lng: 18.086452 },
    { lat: 48.3868612, lng: 18.0860523 },
    { lat: 48.3867825, lng: 18.0860166 },
    { lat: 48.386455, lng: 18.0861449 },
    { lat: 48.3859075, lng: 18.0860692 },
    { lat: 48.3856695, lng: 18.0862602 },
    { lat: 48.3855134, lng: 18.0862557 },
    { lat: 48.3854301, lng: 18.0861634 },
    { lat: 48.3854163, lng: 18.0857399 },
    { lat: 48.3856267, lng: 18.0852082 },
    { lat: 48.3858319, lng: 18.0848816 },
    { lat: 48.3858962, lng: 18.0846476 },
    { lat: 48.3856826, lng: 18.0843742 },
    { lat: 48.3854373, lng: 18.0844266 },
    { lat: 48.385149, lng: 18.0846307 },
    { lat: 48.3850412, lng: 18.0845826 },
    { lat: 48.3850701, lng: 18.0856471 },
    { lat: 48.3850346, lng: 18.0868447 },
    { lat: 48.3847934, lng: 18.0885756 },
    { lat: 48.3832336, lng: 18.0943853 },
    { lat: 48.3827018, lng: 18.0938427 },
    { lat: 48.3817549, lng: 18.0930956 },
    { lat: 48.3813618, lng: 18.0940121 },
    { lat: 48.3811753, lng: 18.0940607 },
    { lat: 48.3811165, lng: 18.0942918 },
    { lat: 48.3803549, lng: 18.0950619 },
    { lat: 48.3801392, lng: 18.095367 },
    { lat: 48.3796093, lng: 18.0958558 },
    { lat: 48.3788535, lng: 18.0961857 },
    { lat: 48.378855, lng: 18.0962302 },
    { lat: 48.3779647, lng: 18.0964053 },
    { lat: 48.377784, lng: 18.0965216 },
    { lat: 48.3776358, lng: 18.0965139 },
    { lat: 48.3771894, lng: 18.0967129 },
    { lat: 48.3769604, lng: 18.0967369 },
    { lat: 48.3751728, lng: 18.0975211 },
    { lat: 48.3750644, lng: 18.0974833 },
    { lat: 48.374553, lng: 18.0977104 },
    { lat: 48.3743485, lng: 18.0979011 },
    { lat: 48.3741224, lng: 18.0979279 },
    { lat: 48.3730145, lng: 18.0983586 },
    { lat: 48.3728449, lng: 18.0983541 },
    { lat: 48.3721836, lng: 18.0986586 },
    { lat: 48.3708094, lng: 18.098667 },
    { lat: 48.3701025, lng: 18.0985264 },
    { lat: 48.369791, lng: 18.098622 },
    { lat: 48.3689157, lng: 18.0984163 },
    { lat: 48.3685467, lng: 18.0980926 },
    { lat: 48.3675558, lng: 18.0984744 },
    { lat: 48.3672144, lng: 18.0985249 },
    { lat: 48.3669854, lng: 18.0986607 },
    { lat: 48.3665035, lng: 18.0991 },
    { lat: 48.3662628, lng: 18.1002864 },
    { lat: 48.3660408, lng: 18.1020019 },
    { lat: 48.3660137, lng: 18.1021322 },
    { lat: 48.3657313, lng: 18.1013632 },
    { lat: 48.3655638, lng: 18.101104 },
    { lat: 48.3650533, lng: 18.0994077 },
    { lat: 48.3646281, lng: 18.0984146 },
    { lat: 48.3645717, lng: 18.098108 },
    { lat: 48.3644412, lng: 18.0978066 },
    { lat: 48.3635562, lng: 18.0971909 },
    { lat: 48.3631601, lng: 18.0968445 },
    { lat: 48.3625331, lng: 18.0964701 },
    { lat: 48.3619396, lng: 18.0962627 },
    { lat: 48.3580157, lng: 18.0938458 },
    { lat: 48.3560424, lng: 18.0929931 },
    { lat: 48.3545874, lng: 18.0933527 },
    { lat: 48.3514764, lng: 18.0960253 },
    { lat: 48.3513368, lng: 18.0972594 },
    { lat: 48.3508837, lng: 18.0982078 },
    { lat: 48.349412, lng: 18.0993216 },
    { lat: 48.3492733, lng: 18.101221 },
    { lat: 48.3488209, lng: 18.103912 },
    { lat: 48.3479509, lng: 18.1067748 },
    { lat: 48.3467599, lng: 18.1083432 },
    { lat: 48.3464548, lng: 18.1087216 },
    { lat: 48.3463982, lng: 18.1091236 },
    { lat: 48.3465321, lng: 18.1097526 },
    { lat: 48.3471882, lng: 18.1112556 },
    { lat: 48.3473848, lng: 18.1126069 },
    { lat: 48.3473154, lng: 18.1129992 },
    { lat: 48.3474266, lng: 18.1143943 },
    { lat: 48.3476482, lng: 18.1149029 },
    { lat: 48.3478681, lng: 18.1155742 },
    { lat: 48.3478986, lng: 18.1159012 },
    { lat: 48.3478407, lng: 18.1162969 },
    { lat: 48.3480151, lng: 18.1179435 },
    { lat: 48.3482076, lng: 18.1183392 },
    { lat: 48.3483729, lng: 18.11904 },
    { lat: 48.3484858, lng: 18.1192163 },
    { lat: 48.3486616, lng: 18.1204991 },
    { lat: 48.3488817, lng: 18.1208858 },
    { lat: 48.3496655, lng: 18.1226503 },
    { lat: 48.349944, lng: 18.1233554 },
    { lat: 48.3501134, lng: 18.1240508 },
    { lat: 48.350465, lng: 18.1246026 },
    { lat: 48.3519567, lng: 18.1265077 },
    { lat: 48.3524501, lng: 18.1277611 },
    { lat: 48.3532088, lng: 18.1293695 },
    { lat: 48.3538719, lng: 18.1311924 },
    { lat: 48.353894, lng: 18.1312217 },
    { lat: 48.3542256, lng: 18.1316833 },
    { lat: 48.3548378, lng: 18.1323098 },
    { lat: 48.3553386, lng: 18.1329797 },
    { lat: 48.3553663, lng: 18.1334529 },
    { lat: 48.3555604, lng: 18.1340291 },
    { lat: 48.3556268, lng: 18.1344121 },
    { lat: 48.3556253, lng: 18.1347341 },
    { lat: 48.3560399, lng: 18.1361715 },
    { lat: 48.3561922, lng: 18.1363619 },
    { lat: 48.3561967, lng: 18.1365923 },
    { lat: 48.3560155, lng: 18.1368532 },
    { lat: 48.3562287, lng: 18.1377015 },
    { lat: 48.3563683, lng: 18.1380433 },
    { lat: 48.356348, lng: 18.1383706 },
    { lat: 48.3567759, lng: 18.139553 },
    { lat: 48.3570022, lng: 18.1399538 },
    { lat: 48.3570692, lng: 18.1400725 },
    { lat: 48.3573909, lng: 18.1408073 },
    { lat: 48.3576269, lng: 18.140888 },
    { lat: 48.3580389, lng: 18.1408271 },
    { lat: 48.3581588, lng: 18.1409128 },
    { lat: 48.3584894, lng: 18.1409289 },
    { lat: 48.3590576, lng: 18.1411916 },
    { lat: 48.3593183, lng: 18.1414805 },
    { lat: 48.3598082, lng: 18.1418678 },
    { lat: 48.3619356, lng: 18.1432639 },
    { lat: 48.3630435, lng: 18.144977 },
    { lat: 48.3635389, lng: 18.1461549 },
    { lat: 48.3639562, lng: 18.1463438 },
    { lat: 48.3650288, lng: 18.1475144 },
    { lat: 48.3658404, lng: 18.1481551 },
    { lat: 48.3670771, lng: 18.1506092 },
    { lat: 48.3669622, lng: 18.1510757 },
    { lat: 48.3671337, lng: 18.1517942 },
    { lat: 48.3679141, lng: 18.1529047 },
    { lat: 48.3679935, lng: 18.1530103 },
    { lat: 48.3682236, lng: 18.1524669 },
    { lat: 48.3685252, lng: 18.1523137 },
    { lat: 48.3687528, lng: 18.1522725 },
    { lat: 48.3691765, lng: 18.1520067 },
    { lat: 48.369683, lng: 18.1518518 },
    { lat: 48.3699076, lng: 18.1516565 },
    { lat: 48.3701773, lng: 18.1513098 },
    { lat: 48.3706559, lng: 18.1508476 },
    { lat: 48.3713662, lng: 18.149675 },
    { lat: 48.3717338, lng: 18.1489161 },
    { lat: 48.3723381, lng: 18.148617 },
    { lat: 48.3726896, lng: 18.1485509 },
    { lat: 48.3727599, lng: 18.1480326 },
    { lat: 48.372966, lng: 18.147444 },
    { lat: 48.3735695, lng: 18.1476742 },
    { lat: 48.3738386, lng: 18.147702 },
    { lat: 48.3741085, lng: 18.1473549 },
    { lat: 48.3741588, lng: 18.1471725 },
    { lat: 48.3748296, lng: 18.1470087 },
    { lat: 48.3749848, lng: 18.1468236 },
    { lat: 48.3752959, lng: 18.146684 },
    { lat: 48.3756003, lng: 18.1467384 },
    { lat: 48.3758678, lng: 18.1465801 },
    { lat: 48.3767647, lng: 18.14658 },
    { lat: 48.3771207, lng: 18.1468565 },
    { lat: 48.3772796, lng: 18.1468328 },
    { lat: 48.3774751, lng: 18.146937 },
    { lat: 48.3777671, lng: 18.1469891 },
    { lat: 48.37851, lng: 18.1470156 },
    { lat: 48.3787569, lng: 18.1469502 },
    { lat: 48.379328, lng: 18.1469336 },
    { lat: 48.3799601, lng: 18.1467245 },
    { lat: 48.3805535, lng: 18.1463525 },
    { lat: 48.3810937, lng: 18.1462328 },
    { lat: 48.3815921, lng: 18.1463799 },
    { lat: 48.3816792, lng: 18.1463056 },
    { lat: 48.3817889, lng: 18.1459035 },
    { lat: 48.3827927, lng: 18.145644 },
    { lat: 48.383183, lng: 18.1453861 },
    { lat: 48.3833215, lng: 18.1452266 },
    { lat: 48.3837514, lng: 18.1450528 },
    { lat: 48.3841222, lng: 18.1447357 },
    { lat: 48.3844168, lng: 18.1446018 },
    { lat: 48.3846361, lng: 18.1443002 },
    { lat: 48.3847629, lng: 18.1439403 },
    { lat: 48.3852521, lng: 18.1436586 },
    { lat: 48.3857057, lng: 18.1425186 },
    { lat: 48.3860273, lng: 18.1412784 },
    { lat: 48.3860827, lng: 18.140855 },
    { lat: 48.3861986, lng: 18.140523 },
    { lat: 48.3864001, lng: 18.1402437 },
    { lat: 48.3863199, lng: 18.140086 },
    { lat: 48.3862421, lng: 18.1396652 },
    { lat: 48.3860499, lng: 18.1394843 },
    { lat: 48.3859663, lng: 18.139153 },
    { lat: 48.3859117, lng: 18.1388954 },
    { lat: 48.3858911, lng: 18.1382349 },
    { lat: 48.3858037, lng: 18.1375278 },
    { lat: 48.3865196, lng: 18.1375382 },
    { lat: 48.3865093, lng: 18.1380381 },
    { lat: 48.3873259, lng: 18.1382206 },
    { lat: 48.3877042, lng: 18.1380532 },
    { lat: 48.3879311, lng: 18.1376864 },
    { lat: 48.3880005, lng: 18.1385674 },
    { lat: 48.387903, lng: 18.1390236 },
    { lat: 48.3880952, lng: 18.1390871 },
    { lat: 48.3882348, lng: 18.1392143 },
    { lat: 48.3883636, lng: 18.1402603 },
    { lat: 48.3887436, lng: 18.1414345 },
    { lat: 48.3890079, lng: 18.1417281 },
    { lat: 48.3891612, lng: 18.1409123 },
    { lat: 48.3893188, lng: 18.1394763 },
    { lat: 48.3895704, lng: 18.137833 },
    { lat: 48.3900269, lng: 18.1378836 },
    { lat: 48.3901749, lng: 18.1369957 },
    { lat: 48.3903752, lng: 18.137008 },
    { lat: 48.3907263, lng: 18.1358095 },
    { lat: 48.3911089, lng: 18.1347894 },
    { lat: 48.391519, lng: 18.1333098 },
    { lat: 48.3922986, lng: 18.1309143 },
    { lat: 48.3925717, lng: 18.1291593 },
    { lat: 48.3930486, lng: 18.1268952 },
    { lat: 48.393864, lng: 18.1209304 },
    { lat: 48.3938829, lng: 18.1208086 },
    { lat: 48.3940766, lng: 18.1208977 },
    { lat: 48.3962214, lng: 18.1125108 },
    { lat: 48.3963659, lng: 18.1096082 },
    { lat: 48.3968247, lng: 18.1073681 },
    { lat: 48.3973213, lng: 18.1043147 },
    { lat: 48.3973555, lng: 18.1038567 },
    { lat: 48.3973481, lng: 18.1033651 },
    { lat: 48.3973107, lng: 18.1033647 },
    { lat: 48.3973058, lng: 18.1033775 },
    { lat: 48.3971636, lng: 18.103663 },
    { lat: 48.3970754, lng: 18.103718 },
    { lat: 48.3968992, lng: 18.1036582 },
    { lat: 48.3965477, lng: 18.103323 },
    { lat: 48.3963328, lng: 18.1029129 },
    { lat: 48.396299, lng: 18.1026997 },
    { lat: 48.3963059, lng: 18.1023788 },
    { lat: 48.3965347, lng: 18.1014867 },
    { lat: 48.3970154, lng: 18.1004872 },
    { lat: 48.396938, lng: 18.100304 },
    { lat: 48.3966091, lng: 18.1000283 },
    { lat: 48.3963599, lng: 18.1000283 },
    { lat: 48.3959254, lng: 18.0998951 },
    { lat: 48.3957932, lng: 18.0997383 },
    { lat: 48.3957217, lng: 18.0995592 },
    { lat: 48.395634, lng: 18.0991617 },
    { lat: 48.3956014, lng: 18.0988365 },
    { lat: 48.3957555, lng: 18.0984237 },
    { lat: 48.3963014, lng: 18.0980353 },
    { lat: 48.3965734, lng: 18.0977294 },
    { lat: 48.3966568, lng: 18.0975912 },
    { lat: 48.3966863, lng: 18.0973451 },
    { lat: 48.3965157, lng: 18.0970175 },
    { lat: 48.3955436, lng: 18.0962987 },
    { lat: 48.3951996, lng: 18.0961682 },
    { lat: 48.3950354, lng: 18.0963125 },
    { lat: 48.3948755, lng: 18.0964523 },
    { lat: 48.3948261, lng: 18.0965891 },
    { lat: 48.3938923, lng: 18.0967562 },
    { lat: 48.3937285, lng: 18.0967535 },
    { lat: 48.3935521, lng: 18.0965166 },
    { lat: 48.3932795, lng: 18.0958127 },
    { lat: 48.3932653, lng: 18.0954591 },
    { lat: 48.3934959, lng: 18.0948891 },
    { lat: 48.393653, lng: 18.0948331 },
    { lat: 48.3939792, lng: 18.0948549 },
    { lat: 48.3941678, lng: 18.0946481 },
    { lat: 48.3941626, lng: 18.0941931 },
    { lat: 48.3940289, lng: 18.0939751 },
    { lat: 48.3934036, lng: 18.0946627 },
    { lat: 48.3933595, lng: 18.0948124 },
    { lat: 48.3930567, lng: 18.0947304 },
    { lat: 48.3928771, lng: 18.0945973 },
    { lat: 48.3927708, lng: 18.0943209 },
    { lat: 48.392773, lng: 18.0938434 },
    { lat: 48.3927243, lng: 18.0935299 },
    { lat: 48.3926669, lng: 18.0933422 },
    { lat: 48.3925819, lng: 18.0932807 },
    { lat: 48.3925009, lng: 18.0934185 },
    { lat: 48.3923691, lng: 18.0940379 },
    { lat: 48.3921512, lng: 18.0942241 },
    { lat: 48.3920109, lng: 18.0945086 },
    { lat: 48.3919171, lng: 18.0948517 },
    { lat: 48.3918618, lng: 18.0948888 },
    { lat: 48.3917883, lng: 18.0949067 },
    { lat: 48.3916713, lng: 18.0947784 },
    { lat: 48.3911672, lng: 18.093731 },
    { lat: 48.391049, lng: 18.0938117 },
    { lat: 48.3909881, lng: 18.0939067 },
    { lat: 48.3909659, lng: 18.0940883 },
    { lat: 48.3908055, lng: 18.0942754 },
    { lat: 48.3906704, lng: 18.0940327 },
    { lat: 48.3898592, lng: 18.0933459 },
    { lat: 48.3897396, lng: 18.0925782 },
    { lat: 48.3896682, lng: 18.0924499 },
    { lat: 48.3895037, lng: 18.0921542 },
    { lat: 48.3893024, lng: 18.0915954 },
    { lat: 48.3893533, lng: 18.0914584 },
  ],
  Branč: [
    { lat: 48.2151358, lng: 18.1139661 },
    { lat: 48.2140701, lng: 18.1151861 },
    { lat: 48.2125518, lng: 18.1167532 },
    { lat: 48.210634, lng: 18.1179932 },
    { lat: 48.2089884, lng: 18.1193909 },
    { lat: 48.2070974, lng: 18.1215099 },
    { lat: 48.207077, lng: 18.1215285 },
    { lat: 48.2060485, lng: 18.1225787 },
    { lat: 48.2051989, lng: 18.1232724 },
    { lat: 48.2050677, lng: 18.1268589 },
    { lat: 48.2045026, lng: 18.1275165 },
    { lat: 48.2040327, lng: 18.1261282 },
    { lat: 48.2036332, lng: 18.1254746 },
    { lat: 48.2033383, lng: 18.1251607 },
    { lat: 48.2002874, lng: 18.1275148 },
    { lat: 48.1972082, lng: 18.1288682 },
    { lat: 48.1953935, lng: 18.1293895 },
    { lat: 48.1946402, lng: 18.130087 },
    { lat: 48.1937998, lng: 18.1311725 },
    { lat: 48.1935962, lng: 18.1313743 },
    { lat: 48.193316, lng: 18.131501 },
    { lat: 48.193332, lng: 18.131549 },
    { lat: 48.193385, lng: 18.13176 },
    { lat: 48.193505, lng: 18.132294 },
    { lat: 48.193774, lng: 18.133539 },
    { lat: 48.193892, lng: 18.134093 },
    { lat: 48.193977, lng: 18.134496 },
    { lat: 48.193979, lng: 18.134526 },
    { lat: 48.193983, lng: 18.134573 },
    { lat: 48.194016, lng: 18.135015 },
    { lat: 48.194104, lng: 18.136147 },
    { lat: 48.194145, lng: 18.136652 },
    { lat: 48.194228, lng: 18.137183 },
    { lat: 48.194282, lng: 18.137503 },
    { lat: 48.194337, lng: 18.137815 },
    { lat: 48.19438, lng: 18.13805 },
    { lat: 48.194429, lng: 18.13832 },
    { lat: 48.194485, lng: 18.138726 },
    { lat: 48.194551, lng: 18.139295 },
    { lat: 48.194599, lng: 18.139635 },
    { lat: 48.194614, lng: 18.139705 },
    { lat: 48.194702, lng: 18.140005 },
    { lat: 48.194717, lng: 18.140057 },
    { lat: 48.194729, lng: 18.140089 },
    { lat: 48.194744, lng: 18.140129 },
    { lat: 48.194768, lng: 18.140192 },
    { lat: 48.194788, lng: 18.140171 },
    { lat: 48.194815, lng: 18.140148 },
    { lat: 48.194829, lng: 18.14013 },
    { lat: 48.194841, lng: 18.140114 },
    { lat: 48.194866, lng: 18.140086 },
    { lat: 48.19491, lng: 18.14004 },
    { lat: 48.194956, lng: 18.139974 },
    { lat: 48.195021, lng: 18.139884 },
    { lat: 48.19504, lng: 18.139925 },
    { lat: 48.195074, lng: 18.139996 },
    { lat: 48.195234, lng: 18.140345 },
    { lat: 48.195315, lng: 18.140609 },
    { lat: 48.195358, lng: 18.14075 },
    { lat: 48.195553, lng: 18.141401 },
    { lat: 48.195588, lng: 18.141544 },
    { lat: 48.195673, lng: 18.141902 },
    { lat: 48.195708, lng: 18.142071 },
    { lat: 48.195771, lng: 18.142409 },
    { lat: 48.195789, lng: 18.142508 },
    { lat: 48.195843, lng: 18.142817 },
    { lat: 48.195946, lng: 18.143125 },
    { lat: 48.19603, lng: 18.143453 },
    { lat: 48.196089, lng: 18.143665 },
    { lat: 48.1961, lng: 18.143706 },
    { lat: 48.196116, lng: 18.143761 },
    { lat: 48.196195, lng: 18.144005 },
    { lat: 48.196456, lng: 18.144866 },
    { lat: 48.196516, lng: 18.145067 },
    { lat: 48.196543, lng: 18.145158 },
    { lat: 48.196598, lng: 18.145344 },
    { lat: 48.196703, lng: 18.145697 },
    { lat: 48.196785, lng: 18.145973 },
    { lat: 48.197054, lng: 18.146868 },
    { lat: 48.197169, lng: 18.14725 },
    { lat: 48.197239, lng: 18.147485 },
    { lat: 48.197248, lng: 18.147512 },
    { lat: 48.1973, lng: 18.147674 },
    { lat: 48.197306, lng: 18.147695 },
    { lat: 48.197492, lng: 18.148316 },
    { lat: 48.197691, lng: 18.148914 },
    { lat: 48.19785, lng: 18.149394 },
    { lat: 48.198161, lng: 18.150226 },
    { lat: 48.198213, lng: 18.150355 },
    { lat: 48.198235, lng: 18.150411 },
    { lat: 48.197869, lng: 18.151039 },
    { lat: 48.197823, lng: 18.15108 },
    { lat: 48.197674, lng: 18.151184 },
    { lat: 48.197648, lng: 18.151197 },
    { lat: 48.197584, lng: 18.151172 },
    { lat: 48.197544, lng: 18.151142 },
    { lat: 48.197523, lng: 18.151104 },
    { lat: 48.197414, lng: 18.150768 },
    { lat: 48.197378, lng: 18.150695 },
    { lat: 48.197284, lng: 18.150519 },
    { lat: 48.197261, lng: 18.150457 },
    { lat: 48.197253, lng: 18.150414 },
    { lat: 48.197201, lng: 18.150122 },
    { lat: 48.197207, lng: 18.149765 },
    { lat: 48.19717, lng: 18.149643 },
    { lat: 48.197114, lng: 18.149582 },
    { lat: 48.197077, lng: 18.149549 },
    { lat: 48.196919, lng: 18.149575 },
    { lat: 48.196881, lng: 18.149612 },
    { lat: 48.196835, lng: 18.149661 },
    { lat: 48.196765, lng: 18.149767 },
    { lat: 48.196746, lng: 18.149809 },
    { lat: 48.196685, lng: 18.149976 },
    { lat: 48.196657, lng: 18.150125 },
    { lat: 48.19664, lng: 18.150203 },
    { lat: 48.196627, lng: 18.150309 },
    { lat: 48.196572, lng: 18.150597 },
    { lat: 48.196535, lng: 18.150774 },
    { lat: 48.19645, lng: 18.151011 },
    { lat: 48.196334, lng: 18.151332 },
    { lat: 48.196043, lng: 18.15175 },
    { lat: 48.195946, lng: 18.151881 },
    { lat: 48.195709, lng: 18.152178 },
    { lat: 48.195652, lng: 18.152324 },
    { lat: 48.195641, lng: 18.152551 },
    { lat: 48.19566, lng: 18.152624 },
    { lat: 48.195714, lng: 18.152817 },
    { lat: 48.195763, lng: 18.152917 },
    { lat: 48.195945, lng: 18.153251 },
    { lat: 48.195988, lng: 18.153346 },
    { lat: 48.196027, lng: 18.153445 },
    { lat: 48.196062, lng: 18.15356 },
    { lat: 48.196073, lng: 18.153627 },
    { lat: 48.196075, lng: 18.153679 },
    { lat: 48.196068, lng: 18.153723 },
    { lat: 48.19606, lng: 18.153758 },
    { lat: 48.195959, lng: 18.153953 },
    { lat: 48.195633, lng: 18.153977 },
    { lat: 48.19548, lng: 18.153941 },
    { lat: 48.19516, lng: 18.153859 },
    { lat: 48.195035, lng: 18.153746 },
    { lat: 48.194981, lng: 18.153666 },
    { lat: 48.194958, lng: 18.153599 },
    { lat: 48.19493, lng: 18.153487 },
    { lat: 48.194839, lng: 18.153119 },
    { lat: 48.194725, lng: 18.152814 },
    { lat: 48.194611, lng: 18.152549 },
    { lat: 48.194461, lng: 18.152156 },
    { lat: 48.19439, lng: 18.151918 },
    { lat: 48.194325, lng: 18.151559 },
    { lat: 48.194294, lng: 18.151373 },
    { lat: 48.194243, lng: 18.151093 },
    { lat: 48.194215, lng: 18.15096 },
    { lat: 48.194152, lng: 18.150818 },
    { lat: 48.194087, lng: 18.150731 },
    { lat: 48.194036, lng: 18.150681 },
    { lat: 48.19397, lng: 18.150678 },
    { lat: 48.193883, lng: 18.1507 },
    { lat: 48.193904, lng: 18.150796 },
    { lat: 48.193966, lng: 18.151063 },
    { lat: 48.194037, lng: 18.151418 },
    { lat: 48.194075, lng: 18.151599 },
    { lat: 48.194097, lng: 18.151701 },
    { lat: 48.194285, lng: 18.152597 },
    { lat: 48.194347, lng: 18.152863 },
    { lat: 48.194624, lng: 18.154052 },
    { lat: 48.194914, lng: 18.155395 },
    { lat: 48.195361, lng: 18.157169 },
    { lat: 48.195643, lng: 18.158339 },
    { lat: 48.195715, lng: 18.158615 },
    { lat: 48.19597, lng: 18.159585 },
    { lat: 48.196332, lng: 18.160894 },
    { lat: 48.196708, lng: 18.162072 },
    { lat: 48.197034, lng: 18.162924 },
    { lat: 48.197444, lng: 18.164214 },
    { lat: 48.197762, lng: 18.165201 },
    { lat: 48.198027, lng: 18.166134 },
    { lat: 48.198335, lng: 18.167203 },
    { lat: 48.198695, lng: 18.168469 },
    { lat: 48.198751, lng: 18.168662 },
    { lat: 48.19882, lng: 18.168905 },
    { lat: 48.199083, lng: 18.169851 },
    { lat: 48.19934, lng: 18.170624 },
    { lat: 48.199408, lng: 18.170829 },
    { lat: 48.199765, lng: 18.171913 },
    { lat: 48.199861, lng: 18.1722 },
    { lat: 48.200089, lng: 18.172987 },
    { lat: 48.200127, lng: 18.173122 },
    { lat: 48.200728, lng: 18.174779 },
    { lat: 48.20074, lng: 18.174804 },
    { lat: 48.2010849, lng: 18.1744963 },
    { lat: 48.2019652, lng: 18.1740744 },
    { lat: 48.2028297, lng: 18.1737858 },
    { lat: 48.2043601, lng: 18.1734558 },
    { lat: 48.2051817, lng: 18.1731703 },
    { lat: 48.206194, lng: 18.172724 },
    { lat: 48.2074087, lng: 18.1726939 },
    { lat: 48.2099318, lng: 18.1724384 },
    { lat: 48.2143939, lng: 18.1705834 },
    { lat: 48.2183935, lng: 18.1687354 },
    { lat: 48.2222257, lng: 18.1668729 },
    { lat: 48.2236626, lng: 18.1659363 },
    { lat: 48.2238439, lng: 18.1657929 },
    { lat: 48.2289562, lng: 18.161407 },
    { lat: 48.2314824, lng: 18.1593428 },
    { lat: 48.232426, lng: 18.1587754 },
    { lat: 48.2348344, lng: 18.1571586 },
    { lat: 48.2358003, lng: 18.1566353 },
    { lat: 48.2380793, lng: 18.1550544 },
    { lat: 48.2381715, lng: 18.1545674 },
    { lat: 48.2367472, lng: 18.1506904 },
    { lat: 48.2355672, lng: 18.147632 },
    { lat: 48.2346764, lng: 18.1457371 },
    { lat: 48.2336546, lng: 18.1465734 },
    { lat: 48.2327158, lng: 18.1435527 },
    { lat: 48.2327375, lng: 18.1434217 },
    { lat: 48.2320429, lng: 18.142984 },
    { lat: 48.2316166, lng: 18.1426067 },
    { lat: 48.2312503, lng: 18.142595 },
    { lat: 48.2310127, lng: 18.1427766 },
    { lat: 48.230712, lng: 18.1422086 },
    { lat: 48.229906, lng: 18.1400849 },
    { lat: 48.2285575, lng: 18.1368139 },
    { lat: 48.2274728, lng: 18.1345769 },
    { lat: 48.2271372, lng: 18.1340119 },
    { lat: 48.2246846, lng: 18.128296 },
    { lat: 48.2224483, lng: 18.122652 },
    { lat: 48.2202754, lng: 18.1168855 },
    { lat: 48.2200369, lng: 18.1164168 },
    { lat: 48.2198924, lng: 18.1165905 },
    { lat: 48.2193684, lng: 18.115376 },
    { lat: 48.2183961, lng: 18.1126222 },
    { lat: 48.2175907, lng: 18.1111883 },
    { lat: 48.2162976, lng: 18.1125554 },
    { lat: 48.215216, lng: 18.1138699 },
    { lat: 48.2151358, lng: 18.1139661 },
  ],
  Pohranice: [
    { lat: 48.3231316, lng: 18.210053 },
    { lat: 48.3231628, lng: 18.2100071 },
    { lat: 48.3256318, lng: 18.2060879 },
    { lat: 48.3264619, lng: 18.2039955 },
    { lat: 48.3266975, lng: 18.2036415 },
    { lat: 48.3293352, lng: 18.2005362 },
    { lat: 48.329874, lng: 18.2000401 },
    { lat: 48.3307437, lng: 18.1996444 },
    { lat: 48.3326191, lng: 18.1978339 },
    { lat: 48.3334623, lng: 18.197197 },
    { lat: 48.3340966, lng: 18.196534 },
    { lat: 48.3350675, lng: 18.1952974 },
    { lat: 48.33624, lng: 18.1943649 },
    { lat: 48.3368015, lng: 18.193577 },
    { lat: 48.3372208, lng: 18.1931981 },
    { lat: 48.3378313, lng: 18.1925006 },
    { lat: 48.3379395, lng: 18.1917204 },
    { lat: 48.3380705, lng: 18.1913532 },
    { lat: 48.3387402, lng: 18.1902699 },
    { lat: 48.3394837, lng: 18.1891999 },
    { lat: 48.3397197, lng: 18.1887754 },
    { lat: 48.3399311, lng: 18.1881969 },
    { lat: 48.3400614, lng: 18.1880146 },
    { lat: 48.3403561, lng: 18.1878807 },
    { lat: 48.3409934, lng: 18.1878564 },
    { lat: 48.3448347, lng: 18.1870407 },
    { lat: 48.3455966, lng: 18.1869831 },
    { lat: 48.3472231, lng: 18.1870295 },
    { lat: 48.3484904, lng: 18.1869126 },
    { lat: 48.3487937, lng: 18.1867367 },
    { lat: 48.3495636, lng: 18.1868008 },
    { lat: 48.3500724, lng: 18.1870883 },
    { lat: 48.3505612, lng: 18.1874598 },
    { lat: 48.3514902, lng: 18.1876226 },
    { lat: 48.3518147, lng: 18.1874975 },
    { lat: 48.3525353, lng: 18.1866715 },
    { lat: 48.35286, lng: 18.1861792 },
    { lat: 48.3533164, lng: 18.1856654 },
    { lat: 48.3542042, lng: 18.184804 },
    { lat: 48.3546377, lng: 18.1845362 },
    { lat: 48.3555349, lng: 18.1836256 },
    { lat: 48.3555241, lng: 18.1835934 },
    { lat: 48.3554902, lng: 18.1835148 },
    { lat: 48.3541582, lng: 18.1823801 },
    { lat: 48.353763, lng: 18.18189 },
    { lat: 48.3530703, lng: 18.1812898 },
    { lat: 48.3518834, lng: 18.179934 },
    { lat: 48.3513322, lng: 18.1794622 },
    { lat: 48.3507228, lng: 18.178721 },
    { lat: 48.350754, lng: 18.1785785 },
    { lat: 48.3505401, lng: 18.1782777 },
    { lat: 48.350341, lng: 18.1784616 },
    { lat: 48.3496932, lng: 18.1774341 },
    { lat: 48.348775, lng: 18.1763885 },
    { lat: 48.3478168, lng: 18.1762347 },
    { lat: 48.3470396, lng: 18.1745513 },
    { lat: 48.3465019, lng: 18.1730651 },
    { lat: 48.3460781, lng: 18.1721252 },
    { lat: 48.3458598, lng: 18.1708767 },
    { lat: 48.3451851, lng: 18.1696923 },
    { lat: 48.3447183, lng: 18.1690227 },
    { lat: 48.3430422, lng: 18.1662448 },
    { lat: 48.3424549, lng: 18.1648001 },
    { lat: 48.3427664, lng: 18.1645336 },
    { lat: 48.3424644, lng: 18.1634544 },
    { lat: 48.3421987, lng: 18.1628057 },
    { lat: 48.3415701, lng: 18.1615874 },
    { lat: 48.3412152, lng: 18.1607252 },
    { lat: 48.3413896, lng: 18.1604838 },
    { lat: 48.3407778, lng: 18.1595698 },
    { lat: 48.3410726, lng: 18.1591502 },
    { lat: 48.3408243, lng: 18.158744 },
    { lat: 48.3410255, lng: 18.1585168 },
    { lat: 48.3408513, lng: 18.1572893 },
    { lat: 48.3412099, lng: 18.1570366 },
    { lat: 48.3417368, lng: 18.1568177 },
    { lat: 48.3421856, lng: 18.1563871 },
    { lat: 48.3420319, lng: 18.1553974 },
    { lat: 48.3422617, lng: 18.1551175 },
    { lat: 48.3425839, lng: 18.1549943 },
    { lat: 48.3425731, lng: 18.1549057 },
    { lat: 48.3422098, lng: 18.1533968 },
    { lat: 48.3418242, lng: 18.152223 },
    { lat: 48.3414338, lng: 18.1516443 },
    { lat: 48.3413806, lng: 18.1516233 },
    { lat: 48.3412883, lng: 18.1517244 },
    { lat: 48.3411661, lng: 18.1516401 },
    { lat: 48.3409275, lng: 18.1518257 },
    { lat: 48.3396594, lng: 18.1488918 },
    { lat: 48.3396289, lng: 18.1488142 },
    { lat: 48.3392751, lng: 18.1490767 },
    { lat: 48.3390873, lng: 18.1493762 },
    { lat: 48.338887, lng: 18.1495785 },
    { lat: 48.3383406, lng: 18.1497301 },
    { lat: 48.3380303, lng: 18.1495757 },
    { lat: 48.3378657, lng: 18.1493693 },
    { lat: 48.3375451, lng: 18.1491504 },
    { lat: 48.3358745, lng: 18.1496869 },
    { lat: 48.3344366, lng: 18.1503786 },
    { lat: 48.3337844, lng: 18.1507267 },
    { lat: 48.3331691, lng: 18.1511682 },
    { lat: 48.3320973, lng: 18.1514413 },
    { lat: 48.3312642, lng: 18.1514382 },
    { lat: 48.3303927, lng: 18.1517438 },
    { lat: 48.3294058, lng: 18.1518882 },
    { lat: 48.3293042, lng: 18.1518491 },
    { lat: 48.3288574, lng: 18.1519663 },
    { lat: 48.3279484, lng: 18.1523427 },
    { lat: 48.3272471, lng: 18.1525458 },
    { lat: 48.3272914, lng: 18.1532091 },
    { lat: 48.3268257, lng: 18.1528298 },
    { lat: 48.3261809, lng: 18.1528804 },
    { lat: 48.3246438, lng: 18.1533818 },
    { lat: 48.3242493, lng: 18.1535998 },
    { lat: 48.3240293, lng: 18.153623 },
    { lat: 48.3245362, lng: 18.1567209 },
    { lat: 48.324596, lng: 18.1574949 },
    { lat: 48.324411, lng: 18.1575908 },
    { lat: 48.3220337, lng: 18.1570403 },
    { lat: 48.3218744, lng: 18.1569399 },
    { lat: 48.3218044, lng: 18.1567647 },
    { lat: 48.3216199, lng: 18.1565989 },
    { lat: 48.3213932, lng: 18.1566974 },
    { lat: 48.3210403, lng: 18.156237 },
    { lat: 48.3207072, lng: 18.1561782 },
    { lat: 48.3206214, lng: 18.1558863 },
    { lat: 48.3204847, lng: 18.1557295 },
    { lat: 48.320427, lng: 18.1555137 },
    { lat: 48.3201285, lng: 18.155428 },
    { lat: 48.3199636, lng: 18.1554837 },
    { lat: 48.3197556, lng: 18.155456 },
    { lat: 48.3195135, lng: 18.1553679 },
    { lat: 48.3193364, lng: 18.1551213 },
    { lat: 48.3190944, lng: 18.1549737 },
    { lat: 48.31885, lng: 18.1543644 },
    { lat: 48.3180544, lng: 18.153147 },
    { lat: 48.318026, lng: 18.1529688 },
    { lat: 48.3178135, lng: 18.1525362 },
    { lat: 48.3177423, lng: 18.1524994 },
    { lat: 48.3176056, lng: 18.1522639 },
    { lat: 48.3174434, lng: 18.1527719 },
    { lat: 48.3167989, lng: 18.1518021 },
    { lat: 48.3167928, lng: 18.151794 },
    { lat: 48.3167217, lng: 18.151909 },
    { lat: 48.3143292, lng: 18.1557565 },
    { lat: 48.3140466, lng: 18.1560967 },
    { lat: 48.3140914, lng: 18.1563213 },
    { lat: 48.3135368, lng: 18.1598336 },
    { lat: 48.3122452, lng: 18.1645069 },
    { lat: 48.3113246, lng: 18.1696454 },
    { lat: 48.3106112, lng: 18.1727395 },
    { lat: 48.3104377, lng: 18.1751508 },
    { lat: 48.3107567, lng: 18.1778411 },
    { lat: 48.3107325, lng: 18.1779698 },
    { lat: 48.310835, lng: 18.1795569 },
    { lat: 48.310852, lng: 18.1824282 },
    { lat: 48.3108923, lng: 18.1824211 },
    { lat: 48.3113203, lng: 18.1825641 },
    { lat: 48.3116417, lng: 18.1825817 },
    { lat: 48.3117939, lng: 18.1847153 },
    { lat: 48.3121863, lng: 18.1847867 },
    { lat: 48.3125396, lng: 18.1847101 },
    { lat: 48.3128865, lng: 18.1844447 },
    { lat: 48.3131712, lng: 18.1843135 },
    { lat: 48.3136228, lng: 18.1841053 },
    { lat: 48.313755, lng: 18.1841267 },
    { lat: 48.313833, lng: 18.1843817 },
    { lat: 48.314397, lng: 18.1844197 },
    { lat: 48.3144154, lng: 18.1845414 },
    { lat: 48.31451, lng: 18.1846917 },
    { lat: 48.3145381, lng: 18.1850011 },
    { lat: 48.3146177, lng: 18.186915 },
    { lat: 48.3148165, lng: 18.1878923 },
    { lat: 48.3149829, lng: 18.1877848 },
    { lat: 48.315066, lng: 18.1877942 },
    { lat: 48.3156703, lng: 18.1880202 },
    { lat: 48.3158386, lng: 18.189132 },
    { lat: 48.3161054, lng: 18.1903061 },
    { lat: 48.3164329, lng: 18.1915513 },
    { lat: 48.3168352, lng: 18.1927461 },
    { lat: 48.3189017, lng: 18.1973461 },
    { lat: 48.3202658, lng: 18.2000852 },
    { lat: 48.32037, lng: 18.2003854 },
    { lat: 48.3200151, lng: 18.2007649 },
    { lat: 48.3202365, lng: 18.2013331 },
    { lat: 48.3206724, lng: 18.2020985 },
    { lat: 48.3211854, lng: 18.2024593 },
    { lat: 48.3213638, lng: 18.2026945 },
    { lat: 48.3217452, lng: 18.204485 },
    { lat: 48.3220314, lng: 18.2051235 },
    { lat: 48.3222475, lng: 18.2060502 },
    { lat: 48.3226552, lng: 18.2070112 },
    { lat: 48.3227109, lng: 18.2075436 },
    { lat: 48.3227181, lng: 18.2089555 },
    { lat: 48.3231316, lng: 18.210053 },
  ],
};

export default UnitsNitra;
