export default function SelectFloor({ propList, blockOfFlats, setBlockOfFlats }) {
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr?.map((item) => [item[key], item])).values()];
  };
  const arrEntrance = getUniqueListBy(propList?.List?.byt, "poschodie");

  return propList?.List?.hasOwnProperty("byt") ? (
    blockOfFlats.entrance !== null && blockOfFlats.floor === null ? (
      <div className="w-full flex flex-col justify-center items-center">
        <div>Zvoľ poschodie</div>
        {arrEntrance?.map((value, index) => {
          return (
            <div
              onClick={() => {
                setBlockOfFlats({ ...blockOfFlats, floor: value?.poschodie }); //value?.poschodie === "prízemie" ? value?.poschodie : Number(value?.poschodie)
              }}
              key={index}
              className="h-10 border-2 border-gray-300 w-[78%] rounded-lg mb-1 flex items-center justify-center cursor-pointer"
            >
              <div>{value?.poschodie}</div>
            </div>
          );
        })}
      </div>
    ) : null
  ) : null;
}
