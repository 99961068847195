const UnitsKošiceIII = {
  KošicemestskáčasťKošickáNováVes: [
    { lat: 48.723773, lng: 21.320955 },
    { lat: 48.724312, lng: 21.321064 },
    { lat: 48.724701, lng: 21.321106 },
    { lat: 48.726074, lng: 21.320948 },
    { lat: 48.72675, lng: 21.320624 },
    { lat: 48.726895, lng: 21.321405 },
    { lat: 48.727098, lng: 21.322418 },
    { lat: 48.727206, lng: 21.322902 },
    { lat: 48.727403, lng: 21.32386 },
    { lat: 48.727532, lng: 21.324633 },
    { lat: 48.727589, lng: 21.324759 },
    { lat: 48.727777, lng: 21.325828 },
    { lat: 48.728106, lng: 21.325914 },
    { lat: 48.728272, lng: 21.325982 },
    { lat: 48.728477, lng: 21.326066 },
    { lat: 48.7287725, lng: 21.3261876 },
    { lat: 48.7283774, lng: 21.3255879 },
    { lat: 48.7283432, lng: 21.3253158 },
    { lat: 48.7283832, lng: 21.3250386 },
    { lat: 48.7287142, lng: 21.3246354 },
    { lat: 48.7288921, lng: 21.3244883 },
    { lat: 48.7295312, lng: 21.323929 },
    { lat: 48.729852, lng: 21.3236708 },
    { lat: 48.7301778, lng: 21.3233926 },
    { lat: 48.7305096, lng: 21.323201 },
    { lat: 48.7307351, lng: 21.3234021 },
    { lat: 48.7307826, lng: 21.323375 },
    { lat: 48.7308049, lng: 21.3233688 },
    { lat: 48.7308643, lng: 21.3233597 },
    { lat: 48.7312077, lng: 21.3228832 },
    { lat: 48.7317239, lng: 21.3225009 },
    { lat: 48.7325592, lng: 21.3220623 },
    { lat: 48.7332871, lng: 21.3215014 },
    { lat: 48.7334506, lng: 21.3213261 },
    { lat: 48.7335513, lng: 21.3214923 },
    { lat: 48.7336031, lng: 21.3214217 },
    { lat: 48.7335279, lng: 21.3212437 },
    { lat: 48.7339174, lng: 21.320764 },
    { lat: 48.7341515, lng: 21.3203912 },
    { lat: 48.7344098, lng: 21.3198578 },
    { lat: 48.7345996, lng: 21.3193969 },
    { lat: 48.7348186, lng: 21.3187557 },
    { lat: 48.7349354, lng: 21.3180781 },
    { lat: 48.7350161, lng: 21.3173645 },
    { lat: 48.7350174, lng: 21.3168874 },
    { lat: 48.7350026, lng: 21.3161035 },
    { lat: 48.735002, lng: 21.3155303 },
    { lat: 48.7349059, lng: 21.3148867 },
    { lat: 48.7347412, lng: 21.3142047 },
    { lat: 48.7346768, lng: 21.3139728 },
    { lat: 48.7346229, lng: 21.3135349 },
    { lat: 48.7345735, lng: 21.3132385 },
    { lat: 48.7346157, lng: 21.3130395 },
    { lat: 48.7347902, lng: 21.3125974 },
    { lat: 48.7348761, lng: 21.3121302 },
    { lat: 48.7348889, lng: 21.3118819 },
    { lat: 48.7348192, lng: 21.3115865 },
    { lat: 48.734688, lng: 21.3114174 },
    { lat: 48.7345273, lng: 21.3112414 },
    { lat: 48.734451, lng: 21.3109123 },
    { lat: 48.7346118, lng: 21.3108021 },
    { lat: 48.7348615, lng: 21.3105006 },
    { lat: 48.734931, lng: 21.310418 },
    { lat: 48.7351417, lng: 21.3102772 },
    { lat: 48.7351827, lng: 21.3101089 },
    { lat: 48.7352104, lng: 21.3098724 },
    { lat: 48.7352847, lng: 21.3093193 },
    { lat: 48.7349944, lng: 21.3090265 },
    { lat: 48.7349365, lng: 21.3088961 },
    { lat: 48.734844, lng: 21.3086327 },
    { lat: 48.7347533, lng: 21.3085737 },
    { lat: 48.7346584, lng: 21.3086716 },
    { lat: 48.7346581, lng: 21.3085882 },
    { lat: 48.7346735, lng: 21.3083148 },
    { lat: 48.7349464, lng: 21.3085553 },
    { lat: 48.7352373, lng: 21.3086114 },
    { lat: 48.7352767, lng: 21.3082443 },
    { lat: 48.7353385, lng: 21.3071277 },
    { lat: 48.7354233, lng: 21.3064787 },
    { lat: 48.7355514, lng: 21.3056619 },
    { lat: 48.7356352, lng: 21.3048293 },
    { lat: 48.7361782, lng: 21.3044019 },
    { lat: 48.7360581, lng: 21.3038969 },
    { lat: 48.7359514, lng: 21.303423 },
    { lat: 48.7357527, lng: 21.3027007 },
    { lat: 48.7357454, lng: 21.3026438 },
    { lat: 48.7360297, lng: 21.302594 },
    { lat: 48.7360972, lng: 21.302559 },
    { lat: 48.7366153, lng: 21.3027218 },
    { lat: 48.7365997, lng: 21.3026891 },
    { lat: 48.7365876, lng: 21.3026592 },
    { lat: 48.7365568, lng: 21.3026475 },
    { lat: 48.7364102, lng: 21.3024534 },
    { lat: 48.7363417, lng: 21.3024229 },
    { lat: 48.7360481, lng: 21.3021311 },
    { lat: 48.7359271, lng: 21.3021851 },
    { lat: 48.7357946, lng: 21.3017149 },
    { lat: 48.7359232, lng: 21.3013173 },
    { lat: 48.7359909, lng: 21.3012142 },
    { lat: 48.7364282, lng: 21.3009769 },
    { lat: 48.736388, lng: 21.3007259 },
    { lat: 48.7363794, lng: 21.3007037 },
    { lat: 48.7363735, lng: 21.3006567 },
    { lat: 48.7364328, lng: 21.3006126 },
    { lat: 48.7368323, lng: 21.300317 },
    { lat: 48.7369995, lng: 21.3001506 },
    { lat: 48.73709, lng: 21.3000577 },
    { lat: 48.7370887, lng: 21.2999419 },
    { lat: 48.7370874, lng: 21.2997145 },
    { lat: 48.7370905, lng: 21.2995952 },
    { lat: 48.7370932, lng: 21.2994967 },
    { lat: 48.7370964, lng: 21.2992708 },
    { lat: 48.7371143, lng: 21.2990445 },
    { lat: 48.7370939, lng: 21.2988228 },
    { lat: 48.7370935, lng: 21.2986221 },
    { lat: 48.7370927, lng: 21.2985241 },
    { lat: 48.737008, lng: 21.2984024 },
    { lat: 48.7368755, lng: 21.2981917 },
    { lat: 48.7367506, lng: 21.2980096 },
    { lat: 48.7366128, lng: 21.2978071 },
    { lat: 48.7365162, lng: 21.2976652 },
    { lat: 48.7368202, lng: 21.2978158 },
    { lat: 48.7369057, lng: 21.2978562 },
    { lat: 48.7369586, lng: 21.2978896 },
    { lat: 48.7370435, lng: 21.2979429 },
    { lat: 48.7370687, lng: 21.2979589 },
    { lat: 48.7371157, lng: 21.2979884 },
    { lat: 48.7371329, lng: 21.2979994 },
    { lat: 48.7371994, lng: 21.2979626 },
    { lat: 48.7372388, lng: 21.2979282 },
    { lat: 48.7372771, lng: 21.297893 },
    { lat: 48.7373471, lng: 21.2978283 },
    { lat: 48.7374206, lng: 21.297764 },
    { lat: 48.7374996, lng: 21.2976957 },
    { lat: 48.737574, lng: 21.2976285 },
    { lat: 48.7376472, lng: 21.2975563 },
    { lat: 48.7377332, lng: 21.2974612 },
    { lat: 48.7378368, lng: 21.2973891 },
    { lat: 48.7379178, lng: 21.2973513 },
    { lat: 48.7380014, lng: 21.2972888 },
    { lat: 48.7380736, lng: 21.2972178 },
    { lat: 48.7381538, lng: 21.2971577 },
    { lat: 48.7382406, lng: 21.2970504 },
    { lat: 48.7383035, lng: 21.2969722 },
    { lat: 48.7383818, lng: 21.2968819 },
    { lat: 48.7384618, lng: 21.2967764 },
    { lat: 48.7385634, lng: 21.2966763 },
    { lat: 48.7386117, lng: 21.2965991 },
    { lat: 48.7386139, lng: 21.2965252 },
    { lat: 48.7386064, lng: 21.2962801 },
    { lat: 48.7385841, lng: 21.2962124 },
    { lat: 48.7385908, lng: 21.2961742 },
    { lat: 48.7387135, lng: 21.2960658 },
    { lat: 48.7386753, lng: 21.2959173 },
    { lat: 48.7386599, lng: 21.2958542 },
    { lat: 48.7386444, lng: 21.2957904 },
    { lat: 48.7386303, lng: 21.2957357 },
    { lat: 48.7385945, lng: 21.2955942 },
    { lat: 48.7385547, lng: 21.295433 },
    { lat: 48.7385469, lng: 21.2953972 },
    { lat: 48.7386374, lng: 21.2953031 },
    { lat: 48.738634, lng: 21.2952314 },
    { lat: 48.7386542, lng: 21.2951907 },
    { lat: 48.7385954, lng: 21.2948112 },
    { lat: 48.73859, lng: 21.2947852 },
    { lat: 48.7387358, lng: 21.2946959 },
    { lat: 48.7388727, lng: 21.2945736 },
    { lat: 48.7388867, lng: 21.2945149 },
    { lat: 48.7390082, lng: 21.2944765 },
    { lat: 48.7391322, lng: 21.2944443 },
    { lat: 48.7392633, lng: 21.2944276 },
    { lat: 48.7393872, lng: 21.294393 },
    { lat: 48.7394006, lng: 21.2943906 },
    { lat: 48.7395208, lng: 21.2943829 },
    { lat: 48.7396346, lng: 21.2942783 },
    { lat: 48.7395749, lng: 21.2938736 },
    { lat: 48.739541, lng: 21.293647 },
    { lat: 48.7395281, lng: 21.2935902 },
    { lat: 48.739484, lng: 21.2934007 },
    { lat: 48.7394399, lng: 21.2932099 },
    { lat: 48.7394731, lng: 21.2931904 },
    { lat: 48.7394864, lng: 21.2931831 },
    { lat: 48.7394908, lng: 21.2931806 },
    { lat: 48.7394907, lng: 21.2931789 },
    { lat: 48.7396471, lng: 21.2931034 },
    { lat: 48.7403791, lng: 21.2927506 },
    { lat: 48.7404863, lng: 21.2926904 },
    { lat: 48.7405306, lng: 21.2926655 },
    { lat: 48.7405495, lng: 21.2926553 },
    { lat: 48.7405948, lng: 21.2926296 },
    { lat: 48.7412198, lng: 21.2922786 },
    { lat: 48.7416707, lng: 21.2921173 },
    { lat: 48.7416167, lng: 21.2919325 },
    { lat: 48.7416659, lng: 21.2919237 },
    { lat: 48.741672, lng: 21.2917938 },
    { lat: 48.7414049, lng: 21.2906388 },
    { lat: 48.7415964, lng: 21.2905348 },
    { lat: 48.7415952, lng: 21.2905295 },
    { lat: 48.7415589, lng: 21.2904126 },
    { lat: 48.7415611, lng: 21.2904114 },
    { lat: 48.741551, lng: 21.2903747 },
    { lat: 48.7413653, lng: 21.2896746 },
    { lat: 48.7399326, lng: 21.2899662 },
    { lat: 48.7399326, lng: 21.2899638 },
    { lat: 48.7398603, lng: 21.2898322 },
    { lat: 48.7398313, lng: 21.2897794 },
    { lat: 48.7397001, lng: 21.2895386 },
    { lat: 48.7394059, lng: 21.2895606 },
    { lat: 48.7394083, lng: 21.2896382 },
    { lat: 48.7393353, lng: 21.2896408 },
    { lat: 48.7393347, lng: 21.2895867 },
    { lat: 48.7390371, lng: 21.2896064 },
    { lat: 48.7388517, lng: 21.2896183 },
    { lat: 48.7388519, lng: 21.2896571 },
    { lat: 48.7385136, lng: 21.2896723 },
    { lat: 48.7385038, lng: 21.2897189 },
    { lat: 48.7384446, lng: 21.2899202 },
    { lat: 48.7384092, lng: 21.2901224 },
    { lat: 48.738288, lng: 21.290133 },
    { lat: 48.7382793, lng: 21.2902167 },
    { lat: 48.7382706, lng: 21.2903025 },
    { lat: 48.7382574, lng: 21.2903091 },
    { lat: 48.7381986, lng: 21.2903389 },
    { lat: 48.7381265, lng: 21.2903753 },
    { lat: 48.7381067, lng: 21.2903852 },
    { lat: 48.7380712, lng: 21.2904034 },
    { lat: 48.7377712, lng: 21.2904936 },
    { lat: 48.7377623, lng: 21.2904967 },
    { lat: 48.7376131, lng: 21.2905477 },
    { lat: 48.7374539, lng: 21.2906045 },
    { lat: 48.7372912, lng: 21.2906594 },
    { lat: 48.7371756, lng: 21.2907021 },
    { lat: 48.7370975, lng: 21.2907282 },
    { lat: 48.7370185, lng: 21.2907558 },
    { lat: 48.7369372, lng: 21.2907829 },
    { lat: 48.7368594, lng: 21.2908147 },
    { lat: 48.7367814, lng: 21.2908409 },
    { lat: 48.7367022, lng: 21.2908642 },
    { lat: 48.7366811, lng: 21.2908714 },
    { lat: 48.7366232, lng: 21.2908904 },
    { lat: 48.736543, lng: 21.2909181 },
    { lat: 48.7364571, lng: 21.2909462 },
    { lat: 48.736427, lng: 21.2909548 },
    { lat: 48.7363088, lng: 21.2909887 },
    { lat: 48.7361896, lng: 21.2910277 },
    { lat: 48.7360726, lng: 21.2910665 },
    { lat: 48.7359531, lng: 21.2911006 },
    { lat: 48.7358315, lng: 21.2911315 },
    { lat: 48.7358024, lng: 21.291136 },
    { lat: 48.7357961, lng: 21.2911515 },
    { lat: 48.7356271, lng: 21.2914389 },
    { lat: 48.7355966, lng: 21.2913986 },
    { lat: 48.7355878, lng: 21.2914405 },
    { lat: 48.7355871, lng: 21.2914905 },
    { lat: 48.7355913, lng: 21.2915223 },
    { lat: 48.7355993, lng: 21.2915602 },
    { lat: 48.7356129, lng: 21.2915999 },
    { lat: 48.7356251, lng: 21.2916304 },
    { lat: 48.7356466, lng: 21.2916699 },
    { lat: 48.7356676, lng: 21.2917351 },
    { lat: 48.7355578, lng: 21.2917492 },
    { lat: 48.734994, lng: 21.291897 },
    { lat: 48.7348572, lng: 21.2919486 },
    { lat: 48.7347512, lng: 21.2919796 },
    { lat: 48.7346408, lng: 21.2920126 },
    { lat: 48.7345973, lng: 21.2917312 },
    { lat: 48.7344272, lng: 21.2917878 },
    { lat: 48.734037, lng: 21.2919169 },
    { lat: 48.7338779, lng: 21.2919782 },
    { lat: 48.7337622, lng: 21.2920238 },
    { lat: 48.7337054, lng: 21.2920441 },
    { lat: 48.7336688, lng: 21.2920574 },
    { lat: 48.7335953, lng: 21.2920828 },
    { lat: 48.733465, lng: 21.2921276 },
    { lat: 48.7334327, lng: 21.2921388 },
    { lat: 48.7333143, lng: 21.2921702 },
    { lat: 48.7332416, lng: 21.2921835 },
    { lat: 48.7331718, lng: 21.292218 },
    { lat: 48.7330528, lng: 21.2922648 },
    { lat: 48.7327862, lng: 21.2923454 },
    { lat: 48.7327759, lng: 21.2922602 },
    { lat: 48.7327646, lng: 21.2921876 },
    { lat: 48.7327588, lng: 21.2921466 },
    { lat: 48.7327412, lng: 21.2921233 },
    { lat: 48.7326644, lng: 21.2920777 },
    { lat: 48.7326163, lng: 21.2920137 },
    { lat: 48.7325712, lng: 21.2917519 },
    { lat: 48.7325348, lng: 21.2916654 },
    { lat: 48.7324931, lng: 21.2915899 },
    { lat: 48.732466, lng: 21.2915508 },
    { lat: 48.7324554, lng: 21.2915376 },
    { lat: 48.7324368, lng: 21.2915129 },
    { lat: 48.7323001, lng: 21.2913146 },
    { lat: 48.7321164, lng: 21.2910476 },
    { lat: 48.732073, lng: 21.2909925 },
    { lat: 48.7320268, lng: 21.2909494 },
    { lat: 48.7319671, lng: 21.2909137 },
    { lat: 48.7319124, lng: 21.2908915 },
    { lat: 48.731875, lng: 21.2908844 },
    { lat: 48.7318098, lng: 21.2908819 },
    { lat: 48.7317143, lng: 21.29089 },
    { lat: 48.7315011, lng: 21.2909092 },
    { lat: 48.7314562, lng: 21.290913 },
    { lat: 48.7312845, lng: 21.2909279 },
    { lat: 48.7309133, lng: 21.2909692 },
    { lat: 48.7307078, lng: 21.2909867 },
    { lat: 48.7304913, lng: 21.2910094 },
    { lat: 48.7304296, lng: 21.2910158 },
    { lat: 48.7303221, lng: 21.2910326 },
    { lat: 48.7302125, lng: 21.2910545 },
    { lat: 48.730143, lng: 21.2910633 },
    { lat: 48.7300665, lng: 21.2910629 },
    { lat: 48.7299884, lng: 21.2910502 },
    { lat: 48.7299361, lng: 21.2910321 },
    { lat: 48.7298777, lng: 21.2909963 },
    { lat: 48.7297924, lng: 21.2909324 },
    { lat: 48.72976, lng: 21.2909033 },
    { lat: 48.7297319, lng: 21.2908689 },
    { lat: 48.729692, lng: 21.2908139 },
    { lat: 48.7296388, lng: 21.29073 },
    { lat: 48.729591, lng: 21.290642 },
    { lat: 48.7295651, lng: 21.2906018 },
    { lat: 48.7295217, lng: 21.2905464 },
    { lat: 48.7295057, lng: 21.290499 },
    { lat: 48.7294281, lng: 21.2905394 },
    { lat: 48.7294243, lng: 21.2905261 },
    { lat: 48.7293064, lng: 21.2906466 },
    { lat: 48.7292281, lng: 21.2907694 },
    { lat: 48.7291497, lng: 21.2909267 },
    { lat: 48.7291012, lng: 21.2910716 },
    { lat: 48.7290625, lng: 21.2912394 },
    { lat: 48.7290574, lng: 21.2912935 },
    { lat: 48.7290346, lng: 21.2913971 },
    { lat: 48.7290352, lng: 21.2914551 },
    { lat: 48.7290252, lng: 21.291567 },
    { lat: 48.7290433, lng: 21.2916474 },
    { lat: 48.7290777, lng: 21.291702 },
    { lat: 48.7291204, lng: 21.2917379 },
    { lat: 48.7289066, lng: 21.291699 },
    { lat: 48.7287677, lng: 21.2917219 },
    { lat: 48.7281543, lng: 21.2918282 },
    { lat: 48.7265108, lng: 21.2921463 },
    { lat: 48.7264783, lng: 21.2921514 },
    { lat: 48.7260394, lng: 21.2922206 },
    { lat: 48.726029, lng: 21.2921764 },
    { lat: 48.7259845, lng: 21.2921934 },
    { lat: 48.7259938, lng: 21.292238 },
    { lat: 48.7257793, lng: 21.2922915 },
    { lat: 48.7256004, lng: 21.2923286 },
    { lat: 48.7253164, lng: 21.2923873 },
    { lat: 48.7253063, lng: 21.2923894 },
    { lat: 48.72519, lng: 21.2924154 },
    { lat: 48.724386, lng: 21.2925828 },
    { lat: 48.7242697, lng: 21.2926059 },
    { lat: 48.7241645, lng: 21.2926271 },
    { lat: 48.7241433, lng: 21.2926318 },
    { lat: 48.7241266, lng: 21.2926347 },
    { lat: 48.7241205, lng: 21.2926555 },
    { lat: 48.7239706, lng: 21.2926881 },
    { lat: 48.7239461, lng: 21.2926926 },
    { lat: 48.723943, lng: 21.2927035 },
    { lat: 48.72393, lng: 21.2927558 },
    { lat: 48.7238967, lng: 21.2928821 },
    { lat: 48.7238674, lng: 21.2929932 },
    { lat: 48.723858, lng: 21.293025 },
    { lat: 48.723857, lng: 21.293225 },
    { lat: 48.72372, lng: 21.293459 },
    { lat: 48.723656, lng: 21.293537 },
    { lat: 48.723528, lng: 21.293671 },
    { lat: 48.723254, lng: 21.294041 },
    { lat: 48.723073, lng: 21.294299 },
    { lat: 48.72285, lng: 21.294692 },
    { lat: 48.722562, lng: 21.295286 },
    { lat: 48.722406, lng: 21.295502 },
    { lat: 48.7220815, lng: 21.2959228 },
    { lat: 48.721961, lng: 21.296047 },
    { lat: 48.721907, lng: 21.296113 },
    { lat: 48.721722, lng: 21.296108 },
    { lat: 48.721605, lng: 21.296043 },
    { lat: 48.7214888, lng: 21.295931 },
    { lat: 48.720921, lng: 21.295465 },
    { lat: 48.720107, lng: 21.294797 },
    { lat: 48.719444, lng: 21.294344 },
    { lat: 48.719396, lng: 21.294332 },
    { lat: 48.718945, lng: 21.294101 },
    { lat: 48.718375, lng: 21.293885 },
    { lat: 48.717934, lng: 21.293733 },
    { lat: 48.717634, lng: 21.293624 },
    { lat: 48.717486, lng: 21.293659 },
    { lat: 48.71747, lng: 21.293656 },
    { lat: 48.717098, lng: 21.293592 },
    { lat: 48.716689, lng: 21.293582 },
    { lat: 48.715686, lng: 21.293559 },
    { lat: 48.714588, lng: 21.293704 },
    { lat: 48.714074, lng: 21.293697 },
    { lat: 48.713992, lng: 21.293683 },
    { lat: 48.713503, lng: 21.293596 },
    { lat: 48.713588, lng: 21.294409 },
    { lat: 48.713887, lng: 21.294544 },
    { lat: 48.71396, lng: 21.295136 },
    { lat: 48.714166, lng: 21.295929 },
    { lat: 48.714774, lng: 21.29606 },
    { lat: 48.715024, lng: 21.295968 },
    { lat: 48.715066, lng: 21.297199 },
    { lat: 48.715127, lng: 21.297859 },
    { lat: 48.715221, lng: 21.298242 },
    { lat: 48.714425, lng: 21.29898 },
    { lat: 48.714088, lng: 21.29979 },
    { lat: 48.713869, lng: 21.30039 },
    { lat: 48.713441, lng: 21.301422 },
    { lat: 48.713337, lng: 21.301586 },
    { lat: 48.713104, lng: 21.301825 },
    { lat: 48.712846, lng: 21.302384 },
    { lat: 48.712751, lng: 21.302652 },
    { lat: 48.71256, lng: 21.302867 },
    { lat: 48.712355, lng: 21.303162 },
    { lat: 48.7119204, lng: 21.3035999 },
    { lat: 48.711869, lng: 21.303843 },
    { lat: 48.71191, lng: 21.304316 },
    { lat: 48.712005, lng: 21.304589 },
    { lat: 48.712155, lng: 21.304741 },
    { lat: 48.712136, lng: 21.305485 },
    { lat: 48.712329, lng: 21.30627 },
    { lat: 48.712291, lng: 21.306484 },
    { lat: 48.712537, lng: 21.306859 },
    { lat: 48.71259, lng: 21.307574 },
    { lat: 48.712513, lng: 21.308147 },
    { lat: 48.712566, lng: 21.30876 },
    { lat: 48.712351, lng: 21.309337 },
    { lat: 48.712292, lng: 21.310244 },
    { lat: 48.712193, lng: 21.311224 },
    { lat: 48.712133, lng: 21.311322 },
    { lat: 48.712179, lng: 21.311631 },
    { lat: 48.712065, lng: 21.312512 },
    { lat: 48.711991, lng: 21.312866 },
    { lat: 48.712421, lng: 21.313791 },
    { lat: 48.712503, lng: 21.314184 },
    { lat: 48.712536, lng: 21.315378 },
    { lat: 48.712489, lng: 21.31587 },
    { lat: 48.712552, lng: 21.316678 },
    { lat: 48.712559, lng: 21.316765 },
    { lat: 48.712561, lng: 21.31692 },
    { lat: 48.712567, lng: 21.317345 },
    { lat: 48.712571, lng: 21.317615 },
    { lat: 48.712551, lng: 21.31776 },
    { lat: 48.712255, lng: 21.319361 },
    { lat: 48.712462, lng: 21.319511 },
    { lat: 48.712537, lng: 21.319564 },
    { lat: 48.712549, lng: 21.319574 },
    { lat: 48.712576, lng: 21.319585 },
    { lat: 48.712629, lng: 21.319608 },
    { lat: 48.712698, lng: 21.319637 },
    { lat: 48.712605, lng: 21.320064 },
    { lat: 48.712784, lng: 21.320183 },
    { lat: 48.713281, lng: 21.320462 },
    { lat: 48.713606, lng: 21.319225 },
    { lat: 48.713824, lng: 21.319275 },
    { lat: 48.713832, lng: 21.319277 },
    { lat: 48.713772, lng: 21.319579 },
    { lat: 48.713623, lng: 21.320326 },
    { lat: 48.713619, lng: 21.320346 },
    { lat: 48.713618, lng: 21.320355 },
    { lat: 48.715888, lng: 21.321142 },
    { lat: 48.715922, lng: 21.321154 },
    { lat: 48.716238, lng: 21.321263 },
    { lat: 48.716368, lng: 21.321308 },
    { lat: 48.716359, lng: 21.321323 },
    { lat: 48.716269, lng: 21.321478 },
    { lat: 48.716085, lng: 21.321656 },
    { lat: 48.716474, lng: 21.322951 },
    { lat: 48.71654, lng: 21.323398 },
    { lat: 48.716581, lng: 21.323667 },
    { lat: 48.716585, lng: 21.323697 },
    { lat: 48.716341, lng: 21.32383 },
    { lat: 48.715888, lng: 21.323991 },
    { lat: 48.715441, lng: 21.324163 },
    { lat: 48.714813, lng: 21.324716 },
    { lat: 48.714734, lng: 21.324663 },
    { lat: 48.714203, lng: 21.32653 },
    { lat: 48.714177, lng: 21.326622 },
    { lat: 48.714305, lng: 21.326927 },
    { lat: 48.714826, lng: 21.328032 },
    { lat: 48.714978, lng: 21.328385 },
    { lat: 48.715208, lng: 21.328651 },
    { lat: 48.715343, lng: 21.328859 },
    { lat: 48.715499, lng: 21.329095 },
    { lat: 48.71584, lng: 21.329287 },
    { lat: 48.716126, lng: 21.329451 },
    { lat: 48.716245, lng: 21.329584 },
    { lat: 48.716509, lng: 21.329927 },
    { lat: 48.71665, lng: 21.330013 },
    { lat: 48.716983, lng: 21.330024 },
    { lat: 48.717066, lng: 21.329906 },
    { lat: 48.717097, lng: 21.329861 },
    { lat: 48.717199, lng: 21.329737 },
    { lat: 48.717294, lng: 21.329761 },
    { lat: 48.717369, lng: 21.329668 },
    { lat: 48.717387, lng: 21.329603 },
    { lat: 48.717407, lng: 21.329537 },
    { lat: 48.717513, lng: 21.329172 },
    { lat: 48.717596, lng: 21.328596 },
    { lat: 48.717456, lng: 21.328441 },
    { lat: 48.717614, lng: 21.32804 },
    { lat: 48.717587, lng: 21.327668 },
    { lat: 48.717696, lng: 21.327154 },
    { lat: 48.7179, lng: 21.327099 },
    { lat: 48.718137, lng: 21.326624 },
    { lat: 48.718157, lng: 21.326483 },
    { lat: 48.718121, lng: 21.326186 },
    { lat: 48.718365, lng: 21.32549 },
    { lat: 48.718645, lng: 21.325025 },
    { lat: 48.71875, lng: 21.32493 },
    { lat: 48.719027, lng: 21.324433 },
    { lat: 48.719055, lng: 21.324279 },
    { lat: 48.71909, lng: 21.324092 },
    { lat: 48.719167, lng: 21.324091 },
    { lat: 48.719236, lng: 21.324091 },
    { lat: 48.719916, lng: 21.322848 },
    { lat: 48.719909, lng: 21.322715 },
    { lat: 48.719891, lng: 21.32239 },
    { lat: 48.720107, lng: 21.322348 },
    { lat: 48.720384, lng: 21.322293 },
    { lat: 48.720525, lng: 21.321853 },
    { lat: 48.720558, lng: 21.321623 },
    { lat: 48.720538, lng: 21.321424 },
    { lat: 48.720752, lng: 21.321314 },
    { lat: 48.720852, lng: 21.321424 },
    { lat: 48.720799, lng: 21.321162 },
    { lat: 48.720897, lng: 21.32055 },
    { lat: 48.720845, lng: 21.320399 },
    { lat: 48.72085, lng: 21.320172 },
    { lat: 48.720913, lng: 21.320182 },
    { lat: 48.721306, lng: 21.32024 },
    { lat: 48.722955, lng: 21.320719 },
    { lat: 48.723503, lng: 21.320885 },
    { lat: 48.723773, lng: 21.320955 },
  ],
  KošicemestskáčasťDargovskýchhrdinov: [
    { lat: 48.736055, lng: 21.324816 },
    { lat: 48.736122, lng: 21.324758 },
    { lat: 48.736803, lng: 21.324171 },
    { lat: 48.737011, lng: 21.323446 },
    { lat: 48.736983, lng: 21.32274 },
    { lat: 48.73724, lng: 21.322167 },
    { lat: 48.737342, lng: 21.321742 },
    { lat: 48.737314, lng: 21.321485 },
    { lat: 48.737437, lng: 21.320944 },
    { lat: 48.737358, lng: 21.320784 },
    { lat: 48.737498, lng: 21.320671 },
    { lat: 48.737655, lng: 21.319935 },
    { lat: 48.737928, lng: 21.319152 },
    { lat: 48.738257, lng: 21.318289 },
    { lat: 48.738572, lng: 21.318065 },
    { lat: 48.738608, lng: 21.317679 },
    { lat: 48.738581, lng: 21.317423 },
    { lat: 48.73889, lng: 21.316775 },
    { lat: 48.739039, lng: 21.316159 },
    { lat: 48.739075, lng: 21.315785 },
    { lat: 48.738988, lng: 21.315373 },
    { lat: 48.739017, lng: 21.313526 },
    { lat: 48.739434, lng: 21.314324 },
    { lat: 48.739349, lng: 21.31447 },
    { lat: 48.739659, lng: 21.31513 },
    { lat: 48.739792, lng: 21.315232 },
    { lat: 48.741083, lng: 21.316907 },
    { lat: 48.741265, lng: 21.317022 },
    { lat: 48.741403, lng: 21.317132 },
    { lat: 48.741432, lng: 21.31708 },
    { lat: 48.741661, lng: 21.317015 },
    { lat: 48.742089, lng: 21.317136 },
    { lat: 48.74304, lng: 21.317354 },
    { lat: 48.742991, lng: 21.316899 },
    { lat: 48.743469, lng: 21.316587 },
    { lat: 48.743828, lng: 21.316453 },
    { lat: 48.743982, lng: 21.316428 },
    { lat: 48.744077, lng: 21.316412 },
    { lat: 48.744187, lng: 21.316509 },
    { lat: 48.744383, lng: 21.316679 },
    { lat: 48.744621, lng: 21.316888 },
    { lat: 48.744845, lng: 21.317084 },
    { lat: 48.744918, lng: 21.31712 },
    { lat: 48.745078, lng: 21.317197 },
    { lat: 48.7451, lng: 21.317165 },
    { lat: 48.745096, lng: 21.317029 },
    { lat: 48.745088, lng: 21.316798 },
    { lat: 48.745084, lng: 21.316677 },
    { lat: 48.745079, lng: 21.316554 },
    { lat: 48.74509, lng: 21.316404 },
    { lat: 48.745116, lng: 21.316064 },
    { lat: 48.745115, lng: 21.316038 },
    { lat: 48.745109, lng: 21.315759 },
    { lat: 48.745107, lng: 21.315703 },
    { lat: 48.7451, lng: 21.315429 },
    { lat: 48.745229, lng: 21.315275 },
    { lat: 48.74528, lng: 21.315214 },
    { lat: 48.745526, lng: 21.314922 },
    { lat: 48.745769, lng: 21.314632 },
    { lat: 48.745821, lng: 21.31457 },
    { lat: 48.746035, lng: 21.314315 },
    { lat: 48.746248, lng: 21.314254 },
    { lat: 48.746283, lng: 21.314245 },
    { lat: 48.746301, lng: 21.3133 },
    { lat: 48.746302, lng: 21.313245 },
    { lat: 48.746567, lng: 21.312844 },
    { lat: 48.746759, lng: 21.312831 },
    { lat: 48.747073, lng: 21.3124 },
    { lat: 48.747418, lng: 21.312087 },
    { lat: 48.747681, lng: 21.311914 },
    { lat: 48.748146, lng: 21.311505 },
    { lat: 48.748308, lng: 21.311494 },
    { lat: 48.74876, lng: 21.311057 },
    { lat: 48.748833, lng: 21.312782 },
    { lat: 48.749136, lng: 21.312518 },
    { lat: 48.749185, lng: 21.312474 },
    { lat: 48.749511, lng: 21.312166 },
    { lat: 48.750184, lng: 21.31185 },
    { lat: 48.750344, lng: 21.311744 },
    { lat: 48.750738, lng: 21.31112 },
    { lat: 48.750843, lng: 21.311035 },
    { lat: 48.751212, lng: 21.310972 },
    { lat: 48.751483, lng: 21.310861 },
    { lat: 48.751991, lng: 21.310013 },
    { lat: 48.752063, lng: 21.309527 },
    { lat: 48.75242, lng: 21.309011 },
    { lat: 48.752613, lng: 21.308565 },
    { lat: 48.752892, lng: 21.308062 },
    { lat: 48.753164, lng: 21.307835 },
    { lat: 48.753659, lng: 21.307644 },
    { lat: 48.753692, lng: 21.307683 },
    { lat: 48.754405, lng: 21.308525 },
    { lat: 48.754725, lng: 21.308135 },
    { lat: 48.754838, lng: 21.307985 },
    { lat: 48.755257, lng: 21.307248 },
    { lat: 48.75548, lng: 21.307164 },
    { lat: 48.755631, lng: 21.307228 },
    { lat: 48.756828, lng: 21.306233 },
    { lat: 48.757499, lng: 21.305597 },
    { lat: 48.758255, lng: 21.30517 },
    { lat: 48.759275, lng: 21.304659 },
    { lat: 48.759727, lng: 21.304243 },
    { lat: 48.760345, lng: 21.303942 },
    { lat: 48.761298, lng: 21.302979 },
    { lat: 48.762478, lng: 21.301864 },
    { lat: 48.763236, lng: 21.301944 },
    { lat: 48.763588, lng: 21.301843 },
    { lat: 48.764374, lng: 21.301618 },
    { lat: 48.76448, lng: 21.301029 },
    { lat: 48.76488, lng: 21.30073 },
    { lat: 48.765435, lng: 21.300466 },
    { lat: 48.765941, lng: 21.300708 },
    { lat: 48.766346, lng: 21.300524 },
    { lat: 48.766696, lng: 21.300601 },
    { lat: 48.767302, lng: 21.300313 },
    { lat: 48.767529, lng: 21.300205 },
    { lat: 48.76806, lng: 21.300095 },
    { lat: 48.768891, lng: 21.299923 },
    { lat: 48.768914, lng: 21.300033 },
    { lat: 48.769027, lng: 21.300604 },
    { lat: 48.769613, lng: 21.300319 },
    { lat: 48.76969, lng: 21.300718 },
    { lat: 48.76992, lng: 21.30143 },
    { lat: 48.770151, lng: 21.301455 },
    { lat: 48.770695, lng: 21.30064 },
    { lat: 48.771014, lng: 21.300161 },
    { lat: 48.771409, lng: 21.301005 },
    { lat: 48.771426, lng: 21.301043 },
    { lat: 48.771491, lng: 21.301183 },
    { lat: 48.771525, lng: 21.301253 },
    { lat: 48.772057, lng: 21.302395 },
    { lat: 48.772068, lng: 21.302416 },
    { lat: 48.772163, lng: 21.302619 },
    { lat: 48.77244, lng: 21.303213 },
    { lat: 48.774193, lng: 21.301278 },
    { lat: 48.77421, lng: 21.301548 },
    { lat: 48.774326, lng: 21.302698 },
    { lat: 48.774849, lng: 21.304465 },
    { lat: 48.775522, lng: 21.304874 },
    { lat: 48.77638, lng: 21.304795 },
    { lat: 48.776468, lng: 21.304816 },
    { lat: 48.776904, lng: 21.304923 },
    { lat: 48.777039, lng: 21.304871 },
    { lat: 48.777107, lng: 21.304845 },
    { lat: 48.77736, lng: 21.304746 },
    { lat: 48.777479, lng: 21.3047 },
    { lat: 48.778213, lng: 21.304441 },
    { lat: 48.778311, lng: 21.30438 },
    { lat: 48.779124, lng: 21.303882 },
    { lat: 48.779147, lng: 21.303918 },
    { lat: 48.779217, lng: 21.304029 },
    { lat: 48.779293, lng: 21.30415 },
    { lat: 48.779296, lng: 21.304154 },
    { lat: 48.77931, lng: 21.304177 },
    { lat: 48.779438, lng: 21.304378 },
    { lat: 48.77949, lng: 21.304343 },
    { lat: 48.779529, lng: 21.304316 },
    { lat: 48.779533, lng: 21.304314 },
    { lat: 48.77954, lng: 21.304308 },
    { lat: 48.781446, lng: 21.303015 },
    { lat: 48.782874, lng: 21.302047 },
    { lat: 48.785756, lng: 21.300091 },
    { lat: 48.785836, lng: 21.299724 },
    { lat: 48.78583, lng: 21.299463 },
    { lat: 48.7857255, lng: 21.2989737 },
    { lat: 48.785656, lng: 21.298648 },
    { lat: 48.78565, lng: 21.298586 },
    { lat: 48.785544, lng: 21.298019 },
    { lat: 48.785267, lng: 21.297013 },
    { lat: 48.785126, lng: 21.296601 },
    { lat: 48.785029, lng: 21.296324 },
    { lat: 48.784908, lng: 21.296124 },
    { lat: 48.784717, lng: 21.295727 },
    { lat: 48.78463, lng: 21.295429 },
    { lat: 48.784596, lng: 21.295254 },
    { lat: 48.784394, lng: 21.295103 },
    { lat: 48.783557, lng: 21.294007 },
    { lat: 48.782772, lng: 21.293446 },
    { lat: 48.782313, lng: 21.292845 },
    { lat: 48.781606, lng: 21.292409 },
    { lat: 48.780702, lng: 21.291506 },
    { lat: 48.780619, lng: 21.291049 },
    { lat: 48.779389, lng: 21.291199 },
    { lat: 48.778463, lng: 21.291578 },
    { lat: 48.778373, lng: 21.291705 },
    { lat: 48.777976, lng: 21.292261 },
    { lat: 48.776832, lng: 21.293228 },
    { lat: 48.775936, lng: 21.293987 },
    { lat: 48.775533, lng: 21.294332 },
    { lat: 48.775254, lng: 21.294513 },
    { lat: 48.774886, lng: 21.294737 },
    { lat: 48.774484, lng: 21.294888 },
    { lat: 48.774211, lng: 21.294946 },
    { lat: 48.77393, lng: 21.294961 },
    { lat: 48.773665, lng: 21.294935 },
    { lat: 48.773427, lng: 21.294872 },
    { lat: 48.773383, lng: 21.294863 },
    { lat: 48.772215, lng: 21.294447 },
    { lat: 48.772074, lng: 21.294397 },
    { lat: 48.772064, lng: 21.294393 },
    { lat: 48.770517, lng: 21.293842 },
    { lat: 48.770426, lng: 21.293809 },
    { lat: 48.766981, lng: 21.292587 },
    { lat: 48.766446, lng: 21.29236 },
    { lat: 48.766123, lng: 21.29217 },
    { lat: 48.765816, lng: 21.291914 },
    { lat: 48.765595, lng: 21.291688 },
    { lat: 48.76522, lng: 21.2912 },
    { lat: 48.765043, lng: 21.290909 },
    { lat: 48.764892, lng: 21.290568 },
    { lat: 48.764645, lng: 21.289966 },
    { lat: 48.764367, lng: 21.289289 },
    { lat: 48.764158, lng: 21.288535 },
    { lat: 48.76392, lng: 21.288025 },
    { lat: 48.763612, lng: 21.287502 },
    { lat: 48.763241, lng: 21.286961 },
    { lat: 48.761818, lng: 21.285759 },
    { lat: 48.761657, lng: 21.285526 },
    { lat: 48.761574, lng: 21.285412 },
    { lat: 48.761337, lng: 21.285088 },
    { lat: 48.761147, lng: 21.284773 },
    { lat: 48.760594, lng: 21.284084 },
    { lat: 48.760564, lng: 21.284033 },
    { lat: 48.760518, lng: 21.283953 },
    { lat: 48.760494, lng: 21.283925 },
    { lat: 48.760291, lng: 21.283679 },
    { lat: 48.760018, lng: 21.2834 },
    { lat: 48.759318, lng: 21.282781 },
    { lat: 48.759017, lng: 21.282696 },
    { lat: 48.758874, lng: 21.282573 },
    { lat: 48.758128, lng: 21.281808 },
    { lat: 48.758071, lng: 21.281755 },
    { lat: 48.757517, lng: 21.281248 },
    { lat: 48.756965, lng: 21.280695 },
    { lat: 48.756614, lng: 21.280376 },
    { lat: 48.756328, lng: 21.280161 },
    { lat: 48.755985, lng: 21.279821 },
    { lat: 48.755653, lng: 21.279645 },
    { lat: 48.755326, lng: 21.279385 },
    { lat: 48.754704, lng: 21.278823 },
    { lat: 48.75416, lng: 21.278409 },
    { lat: 48.753774, lng: 21.277899 },
    { lat: 48.752751, lng: 21.277475 },
    { lat: 48.752366, lng: 21.277268 },
    { lat: 48.752015, lng: 21.277045 },
    { lat: 48.751322, lng: 21.27666 },
    { lat: 48.750325, lng: 21.276144 },
    { lat: 48.750213, lng: 21.276051 },
    { lat: 48.750049, lng: 21.276002 },
    { lat: 48.749902, lng: 21.27593 },
    { lat: 48.749635, lng: 21.275762 },
    { lat: 48.748595, lng: 21.275272 },
    { lat: 48.747912, lng: 21.274891 },
    { lat: 48.747708, lng: 21.274754 },
    { lat: 48.747619, lng: 21.274695 },
    { lat: 48.747301, lng: 21.27442 },
    { lat: 48.746943, lng: 21.274128 },
    { lat: 48.746662, lng: 21.273961 },
    { lat: 48.746635, lng: 21.273944 },
    { lat: 48.746381, lng: 21.273714 },
    { lat: 48.746191, lng: 21.273455 },
    { lat: 48.745927, lng: 21.273083 },
    { lat: 48.745846, lng: 21.272963 },
    { lat: 48.74582, lng: 21.272926 },
    { lat: 48.745748, lng: 21.272818 },
    { lat: 48.745741, lng: 21.27281 },
    { lat: 48.745679, lng: 21.272716 },
    { lat: 48.74566, lng: 21.272688 },
    { lat: 48.745599, lng: 21.272598 },
    { lat: 48.745281, lng: 21.272137 },
    { lat: 48.74511, lng: 21.271901 },
    { lat: 48.74489, lng: 21.271623 },
    { lat: 48.744675, lng: 21.271347 },
    { lat: 48.744494, lng: 21.27107 },
    { lat: 48.744452, lng: 21.271002 },
    { lat: 48.744307, lng: 21.270711 },
    { lat: 48.744214, lng: 21.270569 },
    { lat: 48.744115, lng: 21.270361 },
    { lat: 48.744024, lng: 21.270151 },
    { lat: 48.743812, lng: 21.269678 },
    { lat: 48.74364, lng: 21.269253 },
    { lat: 48.743481, lng: 21.268809 },
    { lat: 48.743304, lng: 21.268308 },
    { lat: 48.7431596, lng: 21.2677879 },
    { lat: 48.743017, lng: 21.267274 },
    { lat: 48.743002, lng: 21.267211 },
    { lat: 48.742898, lng: 21.266771 },
    { lat: 48.742822, lng: 21.266397 },
    { lat: 48.742757, lng: 21.266034 },
    { lat: 48.742756, lng: 21.265974 },
    { lat: 48.74258, lng: 21.265996 },
    { lat: 48.74257, lng: 21.265996 },
    { lat: 48.742529, lng: 21.265995 },
    { lat: 48.74249, lng: 21.266013 },
    { lat: 48.742447, lng: 21.266071 },
    { lat: 48.742438, lng: 21.266139 },
    { lat: 48.742413, lng: 21.266403 },
    { lat: 48.74237, lng: 21.266828 },
    { lat: 48.742354, lng: 21.26692 },
    { lat: 48.742329, lng: 21.267086 },
    { lat: 48.742306, lng: 21.267263 },
    { lat: 48.742259, lng: 21.267365 },
    { lat: 48.74208, lng: 21.267164 },
    { lat: 48.742067, lng: 21.267151 },
    { lat: 48.742065, lng: 21.267158 },
    { lat: 48.742045, lng: 21.267142 },
    { lat: 48.742008, lng: 21.267085 },
    { lat: 48.741978, lng: 21.267186 },
    { lat: 48.741972, lng: 21.267208 },
    { lat: 48.741912, lng: 21.267327 },
    { lat: 48.741885, lng: 21.267362 },
    { lat: 48.741743, lng: 21.267616 },
    { lat: 48.741529, lng: 21.267951 },
    { lat: 48.741433, lng: 21.268102 },
    { lat: 48.741342, lng: 21.268244 },
    { lat: 48.741264, lng: 21.268366 },
    { lat: 48.741233, lng: 21.268415 },
    { lat: 48.741187, lng: 21.268547 },
    { lat: 48.74116, lng: 21.26863 },
    { lat: 48.740938, lng: 21.26919 },
    { lat: 48.740931, lng: 21.269211 },
    { lat: 48.740916, lng: 21.269246 },
    { lat: 48.740904, lng: 21.269278 },
    { lat: 48.740877, lng: 21.269322 },
    { lat: 48.740837, lng: 21.269387 },
    { lat: 48.740789, lng: 21.269464 },
    { lat: 48.74073, lng: 21.269558 },
    { lat: 48.740729, lng: 21.269696 },
    { lat: 48.740727, lng: 21.269746 },
    { lat: 48.740687, lng: 21.269901 },
    { lat: 48.740626, lng: 21.270128 },
    { lat: 48.740611, lng: 21.270327 },
    { lat: 48.740584, lng: 21.270674 },
    { lat: 48.74045, lng: 21.271046 },
    { lat: 48.740369, lng: 21.271258 },
    { lat: 48.740052, lng: 21.271709 },
    { lat: 48.739744, lng: 21.271892 },
    { lat: 48.739684, lng: 21.27193 },
    { lat: 48.73885, lng: 21.27247 },
    { lat: 48.738451, lng: 21.272757 },
    { lat: 48.738426, lng: 21.272676 },
    { lat: 48.738292, lng: 21.272727 },
    { lat: 48.738214, lng: 21.272757 },
    { lat: 48.737173, lng: 21.273155 },
    { lat: 48.736898, lng: 21.273259 },
    { lat: 48.736656, lng: 21.273345 },
    { lat: 48.736541, lng: 21.273209 },
    { lat: 48.73648, lng: 21.27308 },
    { lat: 48.736471, lng: 21.273051 },
    { lat: 48.736456, lng: 21.273024 },
    { lat: 48.736443, lng: 21.273034 },
    { lat: 48.7363768, lng: 21.2730849 },
    { lat: 48.736352, lng: 21.273104 },
    { lat: 48.7363353, lng: 21.2731041 },
    { lat: 48.736347, lng: 21.273162 },
    { lat: 48.736353, lng: 21.27323 },
    { lat: 48.736356, lng: 21.273259 },
    { lat: 48.736345, lng: 21.273379 },
    { lat: 48.736308, lng: 21.27348 },
    { lat: 48.736244, lng: 21.273572 },
    { lat: 48.7362, lng: 21.273601 },
    { lat: 48.736124, lng: 21.27363 },
    { lat: 48.735819, lng: 21.273724 },
    { lat: 48.735555, lng: 21.273815 },
    { lat: 48.734794, lng: 21.274089 },
    { lat: 48.73442, lng: 21.274345 },
    { lat: 48.734169, lng: 21.274525 },
    { lat: 48.733881, lng: 21.274779 },
    { lat: 48.733857, lng: 21.274767 },
    { lat: 48.733803, lng: 21.27474 },
    { lat: 48.733757, lng: 21.274784 },
    { lat: 48.73374, lng: 21.274797 },
    { lat: 48.733747, lng: 21.274864 },
    { lat: 48.733747, lng: 21.27487 },
    { lat: 48.73375, lng: 21.274925 },
    { lat: 48.733733, lng: 21.274976 },
    { lat: 48.733708, lng: 21.275013 },
    { lat: 48.733103, lng: 21.275579 },
    { lat: 48.732808, lng: 21.27582 },
    { lat: 48.73244, lng: 21.27611 },
    { lat: 48.732431, lng: 21.276117 },
    { lat: 48.732445, lng: 21.27614 },
    { lat: 48.732466, lng: 21.276152 },
    { lat: 48.732358, lng: 21.276221 },
    { lat: 48.732294, lng: 21.276135 },
    { lat: 48.732186, lng: 21.27618 },
    { lat: 48.732139, lng: 21.2762 },
    { lat: 48.731984, lng: 21.27631 },
    { lat: 48.73182, lng: 21.276408 },
    { lat: 48.731736, lng: 21.276441 },
    { lat: 48.731661, lng: 21.276477 },
    { lat: 48.731568, lng: 21.276521 },
    { lat: 48.731516, lng: 21.276545 },
    { lat: 48.731377, lng: 21.276611 },
    { lat: 48.731266, lng: 21.276665 },
    { lat: 48.731161, lng: 21.276717 },
    { lat: 48.731065, lng: 21.276749 },
    { lat: 48.731073, lng: 21.27678 },
    { lat: 48.73099, lng: 21.276809 },
    { lat: 48.730927, lng: 21.27683 },
    { lat: 48.730888, lng: 21.276844 },
    { lat: 48.730766, lng: 21.276885 },
    { lat: 48.730689, lng: 21.276908 },
    { lat: 48.730639, lng: 21.276923 },
    { lat: 48.730549, lng: 21.276948 },
    { lat: 48.730404, lng: 21.276984 },
    { lat: 48.730269, lng: 21.277014 },
    { lat: 48.730173, lng: 21.277032 },
    { lat: 48.730084, lng: 21.277047 },
    { lat: 48.73005, lng: 21.277052 },
    { lat: 48.72988, lng: 21.277074 },
    { lat: 48.729816, lng: 21.277081 },
    { lat: 48.729706, lng: 21.277093 },
    { lat: 48.729645, lng: 21.277098 },
    { lat: 48.7296, lng: 21.277102 },
    { lat: 48.729504, lng: 21.277107 },
    { lat: 48.729411, lng: 21.277111 },
    { lat: 48.729358, lng: 21.277113 },
    { lat: 48.729338, lng: 21.277106 },
    { lat: 48.729327, lng: 21.277096 },
    { lat: 48.729304, lng: 21.277118 },
    { lat: 48.729333, lng: 21.277214 },
    { lat: 48.729368, lng: 21.277269 },
    { lat: 48.729307, lng: 21.277221 },
    { lat: 48.729209, lng: 21.277146 },
    { lat: 48.729169, lng: 21.277115 },
    { lat: 48.729142, lng: 21.277094 },
    { lat: 48.729099, lng: 21.277095 },
    { lat: 48.729059, lng: 21.277097 },
    { lat: 48.728824, lng: 21.277084 },
    { lat: 48.728485, lng: 21.27706 },
    { lat: 48.728131, lng: 21.27705 },
    { lat: 48.727954, lng: 21.277089 },
    { lat: 48.727626, lng: 21.277052 },
    { lat: 48.726765, lng: 21.276941 },
    { lat: 48.726765, lng: 21.276946 },
    { lat: 48.726732, lng: 21.276948 },
    { lat: 48.726731, lng: 21.276932 },
    { lat: 48.726447, lng: 21.276874 },
    { lat: 48.726211, lng: 21.276823 },
    { lat: 48.726233, lng: 21.276952 },
    { lat: 48.725768, lng: 21.276791 },
    { lat: 48.724923, lng: 21.276474 },
    { lat: 48.724892, lng: 21.276462 },
    { lat: 48.724645, lng: 21.276363 },
    { lat: 48.724539, lng: 21.276321 },
    { lat: 48.724532, lng: 21.276356 },
    { lat: 48.724506, lng: 21.276479 },
    { lat: 48.724485, lng: 21.276574 },
    { lat: 48.724479, lng: 21.276601 },
    { lat: 48.724469, lng: 21.276652 },
    { lat: 48.72471, lng: 21.27683 },
    { lat: 48.724848, lng: 21.276979 },
    { lat: 48.724858, lng: 21.276993 },
    { lat: 48.724899, lng: 21.277043 },
    { lat: 48.724919, lng: 21.277024 },
    { lat: 48.725009, lng: 21.277159 },
    { lat: 48.725085, lng: 21.277317 },
    { lat: 48.725136, lng: 21.277423 },
    { lat: 48.72516, lng: 21.277472 },
    { lat: 48.725203, lng: 21.277558 },
    { lat: 48.725205, lng: 21.277563 },
    { lat: 48.725214, lng: 21.27758 },
    { lat: 48.725272, lng: 21.277695 },
    { lat: 48.72528, lng: 21.27771 },
    { lat: 48.725294, lng: 21.277741 },
    { lat: 48.725343, lng: 21.277841 },
    { lat: 48.725353, lng: 21.277861 },
    { lat: 48.725395, lng: 21.277948 },
    { lat: 48.725442, lng: 21.278046 },
    { lat: 48.725518, lng: 21.278201 },
    { lat: 48.725545, lng: 21.27826 },
    { lat: 48.725572, lng: 21.278313 },
    { lat: 48.725591, lng: 21.278354 },
    { lat: 48.725666, lng: 21.278506 },
    { lat: 48.725723, lng: 21.278622 },
    { lat: 48.725743, lng: 21.278662 },
    { lat: 48.725787, lng: 21.278752 },
    { lat: 48.725848, lng: 21.278876 },
    { lat: 48.725854, lng: 21.278888 },
    { lat: 48.725856, lng: 21.27893 },
    { lat: 48.725863, lng: 21.278968 },
    { lat: 48.725979, lng: 21.279047 },
    { lat: 48.725993, lng: 21.279057 },
    { lat: 48.726025, lng: 21.279198 },
    { lat: 48.726038, lng: 21.279211 },
    { lat: 48.72609, lng: 21.279267 },
    { lat: 48.726101, lng: 21.279279 },
    { lat: 48.726118, lng: 21.279276 },
    { lat: 48.726165, lng: 21.279226 },
    { lat: 48.726293, lng: 21.279484 },
    { lat: 48.726311, lng: 21.279529 },
    { lat: 48.726323, lng: 21.279556 },
    { lat: 48.726399, lng: 21.27974 },
    { lat: 48.726382, lng: 21.279761 },
    { lat: 48.726402, lng: 21.279798 },
    { lat: 48.726472, lng: 21.279932 },
    { lat: 48.726494, lng: 21.279962 },
    { lat: 48.726532, lng: 21.280084 },
    { lat: 48.726592, lng: 21.280308 },
    { lat: 48.726615, lng: 21.280408 },
    { lat: 48.72665, lng: 21.280617 },
    { lat: 48.72655, lng: 21.280995 },
    { lat: 48.726576, lng: 21.281105 },
    { lat: 48.726652, lng: 21.281126 },
    { lat: 48.726646, lng: 21.281373 },
    { lat: 48.726528, lng: 21.281772 },
    { lat: 48.726396, lng: 21.281965 },
    { lat: 48.726265, lng: 21.282164 },
    { lat: 48.72616, lng: 21.282436 },
    { lat: 48.726113, lng: 21.282395 },
    { lat: 48.725934, lng: 21.282652 },
    { lat: 48.725751, lng: 21.283052 },
    { lat: 48.725284, lng: 21.283544 },
    { lat: 48.724871, lng: 21.284057 },
    { lat: 48.724505, lng: 21.284493 },
    { lat: 48.724241, lng: 21.285053 },
    { lat: 48.723997, lng: 21.285859 },
    { lat: 48.72381, lng: 21.286259 },
    { lat: 48.723612, lng: 21.287029 },
    { lat: 48.723479, lng: 21.287513 },
    { lat: 48.723318, lng: 21.288017 },
    { lat: 48.723007, lng: 21.288534 },
    { lat: 48.722871, lng: 21.288829 },
    { lat: 48.722647, lng: 21.289028 },
    { lat: 48.722374, lng: 21.289268 },
    { lat: 48.721657, lng: 21.289538 },
    { lat: 48.721331, lng: 21.289561 },
    { lat: 48.72119, lng: 21.289923 },
    { lat: 48.721104, lng: 21.290688 },
    { lat: 48.721215, lng: 21.290979 },
    { lat: 48.721313, lng: 21.291738 },
    { lat: 48.722072, lng: 21.292562 },
    { lat: 48.722605, lng: 21.2927 },
    { lat: 48.722867, lng: 21.292818 },
    { lat: 48.723234, lng: 21.292961 },
    { lat: 48.723711, lng: 21.292982 },
    { lat: 48.723858, lng: 21.293025 },
    { lat: 48.7238674, lng: 21.2929932 },
    { lat: 48.7238967, lng: 21.2928821 },
    { lat: 48.72393, lng: 21.2927558 },
    { lat: 48.723943, lng: 21.2927035 },
    { lat: 48.7239461, lng: 21.2926926 },
    { lat: 48.7239706, lng: 21.2926881 },
    { lat: 48.7241205, lng: 21.2926555 },
    { lat: 48.7241266, lng: 21.2926347 },
    { lat: 48.7241433, lng: 21.2926318 },
    { lat: 48.7241645, lng: 21.2926271 },
    { lat: 48.7242697, lng: 21.2926059 },
    { lat: 48.724386, lng: 21.2925828 },
    { lat: 48.72519, lng: 21.2924154 },
    { lat: 48.7253063, lng: 21.2923894 },
    { lat: 48.7253164, lng: 21.2923873 },
    { lat: 48.7256004, lng: 21.2923286 },
    { lat: 48.7257793, lng: 21.2922915 },
    { lat: 48.7259938, lng: 21.292238 },
    { lat: 48.7259845, lng: 21.2921934 },
    { lat: 48.726029, lng: 21.2921764 },
    { lat: 48.7260394, lng: 21.2922206 },
    { lat: 48.7264783, lng: 21.2921514 },
    { lat: 48.7265108, lng: 21.2921463 },
    { lat: 48.7281543, lng: 21.2918282 },
    { lat: 48.7287677, lng: 21.2917219 },
    { lat: 48.7289066, lng: 21.291699 },
    { lat: 48.7291204, lng: 21.2917379 },
    { lat: 48.7290777, lng: 21.291702 },
    { lat: 48.7290433, lng: 21.2916474 },
    { lat: 48.7290252, lng: 21.291567 },
    { lat: 48.7290352, lng: 21.2914551 },
    { lat: 48.7290346, lng: 21.2913971 },
    { lat: 48.7290574, lng: 21.2912935 },
    { lat: 48.7290625, lng: 21.2912394 },
    { lat: 48.7291012, lng: 21.2910716 },
    { lat: 48.7291497, lng: 21.2909267 },
    { lat: 48.7292281, lng: 21.2907694 },
    { lat: 48.7293064, lng: 21.2906466 },
    { lat: 48.7294243, lng: 21.2905261 },
    { lat: 48.7294281, lng: 21.2905394 },
    { lat: 48.7295057, lng: 21.290499 },
    { lat: 48.7295217, lng: 21.2905464 },
    { lat: 48.7295651, lng: 21.2906018 },
    { lat: 48.729591, lng: 21.290642 },
    { lat: 48.7296388, lng: 21.29073 },
    { lat: 48.729692, lng: 21.2908139 },
    { lat: 48.7297319, lng: 21.2908689 },
    { lat: 48.72976, lng: 21.2909033 },
    { lat: 48.7297924, lng: 21.2909324 },
    { lat: 48.7298777, lng: 21.2909963 },
    { lat: 48.7299361, lng: 21.2910321 },
    { lat: 48.7299884, lng: 21.2910502 },
    { lat: 48.7300665, lng: 21.2910629 },
    { lat: 48.730143, lng: 21.2910633 },
    { lat: 48.7302125, lng: 21.2910545 },
    { lat: 48.7303221, lng: 21.2910326 },
    { lat: 48.7304296, lng: 21.2910158 },
    { lat: 48.7304913, lng: 21.2910094 },
    { lat: 48.7307078, lng: 21.2909867 },
    { lat: 48.7309133, lng: 21.2909692 },
    { lat: 48.7312845, lng: 21.2909279 },
    { lat: 48.7314562, lng: 21.290913 },
    { lat: 48.7315011, lng: 21.2909092 },
    { lat: 48.7317143, lng: 21.29089 },
    { lat: 48.7318098, lng: 21.2908819 },
    { lat: 48.731875, lng: 21.2908844 },
    { lat: 48.7319124, lng: 21.2908915 },
    { lat: 48.7319671, lng: 21.2909137 },
    { lat: 48.7320268, lng: 21.2909494 },
    { lat: 48.732073, lng: 21.2909925 },
    { lat: 48.7321164, lng: 21.2910476 },
    { lat: 48.7323001, lng: 21.2913146 },
    { lat: 48.7324368, lng: 21.2915129 },
    { lat: 48.7324554, lng: 21.2915376 },
    { lat: 48.732466, lng: 21.2915508 },
    { lat: 48.7324931, lng: 21.2915899 },
    { lat: 48.7325348, lng: 21.2916654 },
    { lat: 48.7325712, lng: 21.2917519 },
    { lat: 48.7326163, lng: 21.2920137 },
    { lat: 48.7326644, lng: 21.2920777 },
    { lat: 48.7327412, lng: 21.2921233 },
    { lat: 48.7327588, lng: 21.2921466 },
    { lat: 48.7327646, lng: 21.2921876 },
    { lat: 48.7327759, lng: 21.2922602 },
    { lat: 48.7327862, lng: 21.2923454 },
    { lat: 48.7330528, lng: 21.2922648 },
    { lat: 48.7331718, lng: 21.292218 },
    { lat: 48.7332416, lng: 21.2921835 },
    { lat: 48.7333143, lng: 21.2921702 },
    { lat: 48.7334327, lng: 21.2921388 },
    { lat: 48.733465, lng: 21.2921276 },
    { lat: 48.7335953, lng: 21.2920828 },
    { lat: 48.7336688, lng: 21.2920574 },
    { lat: 48.7337054, lng: 21.2920441 },
    { lat: 48.7337622, lng: 21.2920238 },
    { lat: 48.7338779, lng: 21.2919782 },
    { lat: 48.734037, lng: 21.2919169 },
    { lat: 48.7344272, lng: 21.2917878 },
    { lat: 48.7345973, lng: 21.2917312 },
    { lat: 48.7346408, lng: 21.2920126 },
    { lat: 48.7347512, lng: 21.2919796 },
    { lat: 48.7348572, lng: 21.2919486 },
    { lat: 48.734994, lng: 21.291897 },
    { lat: 48.7355578, lng: 21.2917492 },
    { lat: 48.7356676, lng: 21.2917351 },
    { lat: 48.7356466, lng: 21.2916699 },
    { lat: 48.7356251, lng: 21.2916304 },
    { lat: 48.7356129, lng: 21.2915999 },
    { lat: 48.7355993, lng: 21.2915602 },
    { lat: 48.7355913, lng: 21.2915223 },
    { lat: 48.7355871, lng: 21.2914905 },
    { lat: 48.7355878, lng: 21.2914405 },
    { lat: 48.7355966, lng: 21.2913986 },
    { lat: 48.7356271, lng: 21.2914389 },
    { lat: 48.7357961, lng: 21.2911515 },
    { lat: 48.7358024, lng: 21.291136 },
    { lat: 48.7358315, lng: 21.2911315 },
    { lat: 48.7359531, lng: 21.2911006 },
    { lat: 48.7360726, lng: 21.2910665 },
    { lat: 48.7361896, lng: 21.2910277 },
    { lat: 48.7363088, lng: 21.2909887 },
    { lat: 48.736427, lng: 21.2909548 },
    { lat: 48.7364571, lng: 21.2909462 },
    { lat: 48.736543, lng: 21.2909181 },
    { lat: 48.7366232, lng: 21.2908904 },
    { lat: 48.7366811, lng: 21.2908714 },
    { lat: 48.7367022, lng: 21.2908642 },
    { lat: 48.7367814, lng: 21.2908409 },
    { lat: 48.7368594, lng: 21.2908147 },
    { lat: 48.7369372, lng: 21.2907829 },
    { lat: 48.7370185, lng: 21.2907558 },
    { lat: 48.7370975, lng: 21.2907282 },
    { lat: 48.7371756, lng: 21.2907021 },
    { lat: 48.7372912, lng: 21.2906594 },
    { lat: 48.7374539, lng: 21.2906045 },
    { lat: 48.7376131, lng: 21.2905477 },
    { lat: 48.7377623, lng: 21.2904967 },
    { lat: 48.7377712, lng: 21.2904936 },
    { lat: 48.7380712, lng: 21.2904034 },
    { lat: 48.7381067, lng: 21.2903852 },
    { lat: 48.7381265, lng: 21.2903753 },
    { lat: 48.7381986, lng: 21.2903389 },
    { lat: 48.7382574, lng: 21.2903091 },
    { lat: 48.7382706, lng: 21.2903025 },
    { lat: 48.7382793, lng: 21.2902167 },
    { lat: 48.738288, lng: 21.290133 },
    { lat: 48.7384092, lng: 21.2901224 },
    { lat: 48.7384446, lng: 21.2899202 },
    { lat: 48.7385038, lng: 21.2897189 },
    { lat: 48.7385136, lng: 21.2896723 },
    { lat: 48.7388519, lng: 21.2896571 },
    { lat: 48.7388517, lng: 21.2896183 },
    { lat: 48.7390371, lng: 21.2896064 },
    { lat: 48.7393347, lng: 21.2895867 },
    { lat: 48.7393353, lng: 21.2896408 },
    { lat: 48.7394083, lng: 21.2896382 },
    { lat: 48.7394059, lng: 21.2895606 },
    { lat: 48.7397001, lng: 21.2895386 },
    { lat: 48.7398313, lng: 21.2897794 },
    { lat: 48.7398603, lng: 21.2898322 },
    { lat: 48.7399326, lng: 21.2899638 },
    { lat: 48.7399326, lng: 21.2899662 },
    { lat: 48.7413653, lng: 21.2896746 },
    { lat: 48.741551, lng: 21.2903747 },
    { lat: 48.7415611, lng: 21.2904114 },
    { lat: 48.7415589, lng: 21.2904126 },
    { lat: 48.7415952, lng: 21.2905295 },
    { lat: 48.7415964, lng: 21.2905348 },
    { lat: 48.7414049, lng: 21.2906388 },
    { lat: 48.741672, lng: 21.2917938 },
    { lat: 48.7416659, lng: 21.2919237 },
    { lat: 48.7416167, lng: 21.2919325 },
    { lat: 48.7416707, lng: 21.2921173 },
    { lat: 48.7412198, lng: 21.2922786 },
    { lat: 48.7405948, lng: 21.2926296 },
    { lat: 48.7405495, lng: 21.2926553 },
    { lat: 48.7405306, lng: 21.2926655 },
    { lat: 48.7404863, lng: 21.2926904 },
    { lat: 48.7403791, lng: 21.2927506 },
    { lat: 48.7396471, lng: 21.2931034 },
    { lat: 48.7394907, lng: 21.2931789 },
    { lat: 48.7394908, lng: 21.2931806 },
    { lat: 48.7394864, lng: 21.2931831 },
    { lat: 48.7394731, lng: 21.2931904 },
    { lat: 48.7394399, lng: 21.2932099 },
    { lat: 48.739484, lng: 21.2934007 },
    { lat: 48.7395281, lng: 21.2935902 },
    { lat: 48.739541, lng: 21.293647 },
    { lat: 48.7395749, lng: 21.2938736 },
    { lat: 48.7396346, lng: 21.2942783 },
    { lat: 48.7395208, lng: 21.2943829 },
    { lat: 48.7394006, lng: 21.2943906 },
    { lat: 48.7393872, lng: 21.294393 },
    { lat: 48.7392633, lng: 21.2944276 },
    { lat: 48.7391322, lng: 21.2944443 },
    { lat: 48.7390082, lng: 21.2944765 },
    { lat: 48.7388867, lng: 21.2945149 },
    { lat: 48.7388727, lng: 21.2945736 },
    { lat: 48.7387358, lng: 21.2946959 },
    { lat: 48.73859, lng: 21.2947852 },
    { lat: 48.7385954, lng: 21.2948112 },
    { lat: 48.7386542, lng: 21.2951907 },
    { lat: 48.738634, lng: 21.2952314 },
    { lat: 48.7386374, lng: 21.2953031 },
    { lat: 48.7385469, lng: 21.2953972 },
    { lat: 48.7385547, lng: 21.295433 },
    { lat: 48.7385945, lng: 21.2955942 },
    { lat: 48.7386303, lng: 21.2957357 },
    { lat: 48.7386444, lng: 21.2957904 },
    { lat: 48.7386599, lng: 21.2958542 },
    { lat: 48.7386753, lng: 21.2959173 },
    { lat: 48.7387135, lng: 21.2960658 },
    { lat: 48.7385908, lng: 21.2961742 },
    { lat: 48.7385841, lng: 21.2962124 },
    { lat: 48.7386064, lng: 21.2962801 },
    { lat: 48.7386139, lng: 21.2965252 },
    { lat: 48.7386117, lng: 21.2965991 },
    { lat: 48.7385634, lng: 21.2966763 },
    { lat: 48.7384618, lng: 21.2967764 },
    { lat: 48.7383818, lng: 21.2968819 },
    { lat: 48.7383035, lng: 21.2969722 },
    { lat: 48.7382406, lng: 21.2970504 },
    { lat: 48.7381538, lng: 21.2971577 },
    { lat: 48.7380736, lng: 21.2972178 },
    { lat: 48.7380014, lng: 21.2972888 },
    { lat: 48.7379178, lng: 21.2973513 },
    { lat: 48.7378368, lng: 21.2973891 },
    { lat: 48.7377332, lng: 21.2974612 },
    { lat: 48.7376472, lng: 21.2975563 },
    { lat: 48.737574, lng: 21.2976285 },
    { lat: 48.7374996, lng: 21.2976957 },
    { lat: 48.7374206, lng: 21.297764 },
    { lat: 48.7373471, lng: 21.2978283 },
    { lat: 48.7372771, lng: 21.297893 },
    { lat: 48.7372388, lng: 21.2979282 },
    { lat: 48.7371994, lng: 21.2979626 },
    { lat: 48.7371329, lng: 21.2979994 },
    { lat: 48.7371157, lng: 21.2979884 },
    { lat: 48.7370687, lng: 21.2979589 },
    { lat: 48.7370435, lng: 21.2979429 },
    { lat: 48.7369586, lng: 21.2978896 },
    { lat: 48.7369057, lng: 21.2978562 },
    { lat: 48.7368202, lng: 21.2978158 },
    { lat: 48.7365162, lng: 21.2976652 },
    { lat: 48.7366128, lng: 21.2978071 },
    { lat: 48.7367506, lng: 21.2980096 },
    { lat: 48.7368755, lng: 21.2981917 },
    { lat: 48.737008, lng: 21.2984024 },
    { lat: 48.7370927, lng: 21.2985241 },
    { lat: 48.7370935, lng: 21.2986221 },
    { lat: 48.7370939, lng: 21.2988228 },
    { lat: 48.7371143, lng: 21.2990445 },
    { lat: 48.7370964, lng: 21.2992708 },
    { lat: 48.7370932, lng: 21.2994967 },
    { lat: 48.7370905, lng: 21.2995952 },
    { lat: 48.7370874, lng: 21.2997145 },
    { lat: 48.7370887, lng: 21.2999419 },
    { lat: 48.73709, lng: 21.3000577 },
    { lat: 48.7369995, lng: 21.3001506 },
    { lat: 48.7368323, lng: 21.300317 },
    { lat: 48.7364328, lng: 21.3006126 },
    { lat: 48.7363735, lng: 21.3006567 },
    { lat: 48.7363794, lng: 21.3007037 },
    { lat: 48.736388, lng: 21.3007259 },
    { lat: 48.7364282, lng: 21.3009769 },
    { lat: 48.7359909, lng: 21.3012142 },
    { lat: 48.7359232, lng: 21.3013173 },
    { lat: 48.7357946, lng: 21.3017149 },
    { lat: 48.7359271, lng: 21.3021851 },
    { lat: 48.7360481, lng: 21.3021311 },
    { lat: 48.7363417, lng: 21.3024229 },
    { lat: 48.7364102, lng: 21.3024534 },
    { lat: 48.7365568, lng: 21.3026475 },
    { lat: 48.7365876, lng: 21.3026592 },
    { lat: 48.7365997, lng: 21.3026891 },
    { lat: 48.7366153, lng: 21.3027218 },
    { lat: 48.7360972, lng: 21.302559 },
    { lat: 48.7360297, lng: 21.302594 },
    { lat: 48.7357454, lng: 21.3026438 },
    { lat: 48.7357527, lng: 21.3027007 },
    { lat: 48.7359514, lng: 21.303423 },
    { lat: 48.7360581, lng: 21.3038969 },
    { lat: 48.7361782, lng: 21.3044019 },
    { lat: 48.7356352, lng: 21.3048293 },
    { lat: 48.7355514, lng: 21.3056619 },
    { lat: 48.7354233, lng: 21.3064787 },
    { lat: 48.7353385, lng: 21.3071277 },
    { lat: 48.7352767, lng: 21.3082443 },
    { lat: 48.7352373, lng: 21.3086114 },
    { lat: 48.7349464, lng: 21.3085553 },
    { lat: 48.7346735, lng: 21.3083148 },
    { lat: 48.7346581, lng: 21.3085882 },
    { lat: 48.7346584, lng: 21.3086716 },
    { lat: 48.7347533, lng: 21.3085737 },
    { lat: 48.734844, lng: 21.3086327 },
    { lat: 48.7349365, lng: 21.3088961 },
    { lat: 48.7349944, lng: 21.3090265 },
    { lat: 48.7352847, lng: 21.3093193 },
    { lat: 48.7352104, lng: 21.3098724 },
    { lat: 48.7351827, lng: 21.3101089 },
    { lat: 48.7351417, lng: 21.3102772 },
    { lat: 48.734931, lng: 21.310418 },
    { lat: 48.7348615, lng: 21.3105006 },
    { lat: 48.7346118, lng: 21.3108021 },
    { lat: 48.734451, lng: 21.3109123 },
    { lat: 48.7345273, lng: 21.3112414 },
    { lat: 48.734688, lng: 21.3114174 },
    { lat: 48.7348192, lng: 21.3115865 },
    { lat: 48.7348889, lng: 21.3118819 },
    { lat: 48.7348761, lng: 21.3121302 },
    { lat: 48.7347902, lng: 21.3125974 },
    { lat: 48.7346157, lng: 21.3130395 },
    { lat: 48.7345735, lng: 21.3132385 },
    { lat: 48.7346229, lng: 21.3135349 },
    { lat: 48.7346768, lng: 21.3139728 },
    { lat: 48.7347412, lng: 21.3142047 },
    { lat: 48.7349059, lng: 21.3148867 },
    { lat: 48.735002, lng: 21.3155303 },
    { lat: 48.7350026, lng: 21.3161035 },
    { lat: 48.7350174, lng: 21.3168874 },
    { lat: 48.7350161, lng: 21.3173645 },
    { lat: 48.7349354, lng: 21.3180781 },
    { lat: 48.7348186, lng: 21.3187557 },
    { lat: 48.7345996, lng: 21.3193969 },
    { lat: 48.7344098, lng: 21.3198578 },
    { lat: 48.7341515, lng: 21.3203912 },
    { lat: 48.7339174, lng: 21.320764 },
    { lat: 48.7335279, lng: 21.3212437 },
    { lat: 48.7336031, lng: 21.3214217 },
    { lat: 48.7335513, lng: 21.3214923 },
    { lat: 48.7334506, lng: 21.3213261 },
    { lat: 48.7332871, lng: 21.3215014 },
    { lat: 48.7325592, lng: 21.3220623 },
    { lat: 48.7317239, lng: 21.3225009 },
    { lat: 48.7312077, lng: 21.3228832 },
    { lat: 48.7308643, lng: 21.3233597 },
    { lat: 48.7308049, lng: 21.3233688 },
    { lat: 48.7307826, lng: 21.323375 },
    { lat: 48.7307351, lng: 21.3234021 },
    { lat: 48.7305096, lng: 21.323201 },
    { lat: 48.7301778, lng: 21.3233926 },
    { lat: 48.729852, lng: 21.3236708 },
    { lat: 48.7295312, lng: 21.323929 },
    { lat: 48.7288921, lng: 21.3244883 },
    { lat: 48.7287142, lng: 21.3246354 },
    { lat: 48.7283832, lng: 21.3250386 },
    { lat: 48.7283432, lng: 21.3253158 },
    { lat: 48.7283774, lng: 21.3255879 },
    { lat: 48.7287725, lng: 21.3261876 },
    { lat: 48.729425, lng: 21.326658 },
    { lat: 48.730208, lng: 21.326627 },
    { lat: 48.73021, lng: 21.32657 },
    { lat: 48.730249, lng: 21.325364 },
    { lat: 48.73037, lng: 21.324822 },
    { lat: 48.730724, lng: 21.324338 },
    { lat: 48.730835, lng: 21.324017 },
    { lat: 48.73159, lng: 21.324091 },
    { lat: 48.731816, lng: 21.325009 },
    { lat: 48.732304, lng: 21.324662 },
    { lat: 48.732578, lng: 21.324466 },
    { lat: 48.732922, lng: 21.324441 },
    { lat: 48.733193, lng: 21.324776 },
    { lat: 48.73354, lng: 21.325153 },
    { lat: 48.733833, lng: 21.325351 },
    { lat: 48.733996, lng: 21.325323 },
    { lat: 48.734147, lng: 21.325298 },
    { lat: 48.734467, lng: 21.325188 },
    { lat: 48.734734, lng: 21.325077 },
    { lat: 48.734951, lng: 21.324933 },
    { lat: 48.735202, lng: 21.324851 },
    { lat: 48.735568, lng: 21.324711 },
    { lat: 48.735857, lng: 21.324853 },
    { lat: 48.736055, lng: 21.324816 },
  ],
};
export default UnitsKošiceIII;
