import { React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import cc from "../../Images/cc.png";
import building from "../../Images/building.png";
import { motion, AnimatePresence } from "framer-motion";

export default function HistoryPropertyInfo({ historyItem }) {
  library.add(faChevronDown);
  library.add(faChevronUp);

  const [isExpandedParcel, setIsExpandedParcel] = useState(true);
  const [parcel, setParcel] = useState("auto");
  const [isExpandedBuildings, setIsExpandedBuildings] = useState(true);
  const [buildings, setBuildings] = useState("auto");

  function sizeParcel() {
    setIsExpandedParcel(!isExpandedParcel);
    setParcel(0);
  }

  function sizeBuildings() {
    setIsExpandedBuildings(!isExpandedBuildings);
    setBuildings(0);
  }

  function getTableBodyParcels() {
    if (historyItem?.response?.List?.table_parcely_c instanceof Array) {
      return !historyItem ? null : (
        <tbody>
          {historyItem?.response?.List?.table_parcely_c?.map((item, index) => {
            return (
              <tr key={index} className="border border-opacity-50 border-sky-500">
                <td className="text-center">{item.parcelne_cislo}</td>
                <td className="text-center px-2">{item.vymera_v_m2}</td>
                <td className="text-center">{item.druh_pozemku}</td>
                <td className="text-center">{item.sposob_vyuzivania_pozemku}</td>
                <td className="text-center">{item.druh_chranenej_nehnutelnosti}</td>
                <td className="text-center">{item.umiestnenie_pozemku}</td>
                <td className="text-center">{item.druh_pravneho_vztahu}</td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    if (historyItem?.response?.List?.table_parcely_c instanceof Object) {
      return (
        <tbody>
          <tr className="border border-opacity-50 border-sky-500">
            <td className="text-center">{historyItem?.response?.List?.table_parcely_c?.parcelne_cislo}</td>
            <td className="text-center px-2">{historyItem?.response?.List?.table_parcely_c?.vymera_v_m2}</td>
            <td className="text-center">{historyItem?.response?.List?.table_parcely_c?.druh_pozemku}</td>
            <td className="text-center">{historyItem?.response?.List?.table_parcely_c?.sposob_vyuzivania_pozemku}</td>
            <td className="text-center">
              {historyItem?.response?.List?.table_parcely_c?.druh_chranenej_nehnutelnosti}
            </td>
            <td className="text-center">{historyItem?.response?.List?.table_parcely_c?.umiestnenie_pozemku}</td>
            <td className="text-center">{historyItem?.response?.List?.table_parcely_c?.druh_pravneho_vztahu}</td>
          </tr>
        </tbody>
      );
    }
  }

  function getTableBodyBuildings() {
    if (historyItem?.response?.List?.table_stavby instanceof Array) {
      return !historyItem ? null : (
        <tbody>
          {historyItem?.response?.List?.table_stavby?.map((item, index) => {
            return item?.na_pozemku_parcelne_cislo?.length < 40 ? (
              <tr key={index} className="border border-opacity-50 border-sky-500">
                <td className="text-center">{item.supisne_cislo}</td>
                <td className="text-center">{item.na_pozemku_parcelne_cislo}</td>
                <td className="text-center">{item.druh_stavby}</td>
                <td className="text-center">{item.popis_stavby}</td>
                <td className="text-center">{item.druh_chranenej_nehnutelnosti}</td>
                <td className="text-center">{item.umiestnenie_stavby}</td>
              </tr>
            ) : (
              <tr key={index} className="border border-opacity-50 border-sky-500">
                <td className="text-center" colSpan={6}>
                  {item.na_pozemku_parcelne_cislo}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    if (historyItem?.response?.List?.table_stavby instanceof Object) {
      // console.log("history Item = ", historyItem.response.List.table_stavby);
      return (
        <tbody>
          {historyItem?.response?.List?.table_stavby?.na_pozemku_parcelne_cislo?.length < 40 ? (
            <tr className="border border-opacity-50 border-sky-500">
              <td className="text-center">{historyItem?.response?.List?.table_stavby?.supisne_cislo}</td>
              <td className="text-center">{historyItem?.response?.List?.table_stavby?.na_pozemku_parcelne_cislo}</td>
              <td className="text-center">{historyItem?.response?.List?.table_stavby?.druh_stavby}</td>
              <td className="text-center">{historyItem?.response?.List?.table_stavby?.popis_stavby}</td>
              <td className="text-center">{historyItem?.response?.List?.table_stavby?.druh_chranenej_nehnutelnosti}</td>
              <td className="text-center">{historyItem?.response?.List?.table_stavby?.umiestnenie_stavby}</td>
            </tr>
          ) : (
            <tr className="border border-opacity-50 border-sky-500">
              <td className="text-center" colSpan={6}>
                {historyItem?.response?.List?.table_stavby?.na_pozemku_parcelne_cislo}
              </td>
            </tr>
          )}
        </tbody>
      );
    }
  }

  return (
    <>
      <div className="bg-transparent flex flex-row items-center">
        <img className="h-10" src={cc} alt="CCC" />
        <p className="text-sky-500 font-medium text-base pr-2 text-start">
          Parcely registra "C" evidované na katastrálnej mape
        </p>
        <button onClick={sizeParcel}>
          {isExpandedParcel ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
        </button>
      </div>
      <AnimatePresence>
        {isExpandedParcel ? (
          <motion.div
            className="flex flex-col overflow-scroll"
            initial={{ height: parcel }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
          >
            {historyItem?.response?.List?.table_parcely_c ? (
              <>
                <div className="w-full flex justify-start">
                  <p>
                    Počet parciel:{" "}
                    {historyItem?.response?.List?.table_parcely_c?.length !== undefined
                      ? historyItem?.response?.List?.table_parcely_c?.length
                      : 1}
                  </p>
                </div>
                <table className="w-full">
                  <thead>
                    <tr>
                      <td className="text-center">Parcelné číslo</td>
                      <td className="text-center">
                        Výmera v m<sup>2</sup>
                      </td>
                      <td className="w-52 text-center">Druh pozemku</td>
                      <td className="text-center">Spôsob využívania pozemku</td>
                      <td className="text-center">Druh chránenej nehnuteľnosti</td>
                      <td className="text-center">Umiestnenie pozemku</td>
                      <td className="text-center">Druh právneho vzťahu</td>
                    </tr>
                  </thead>
                  {getTableBodyParcels()}
                </table>
              </>
            ) : null}
          </motion.div>
        ) : null}
      </AnimatePresence>
      <div className="bg-transparent flex flex-row items-center">
        <img className="h-10" src={building} alt="building" />
        <p className="text-sky-500 font-medium text-base pr-2">Stavby</p>
        <button onClick={sizeBuildings}>
          {isExpandedBuildings ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
        </button>
      </div>
      <AnimatePresence>
        {isExpandedBuildings ? (
          <motion.div
            className="flex flex-col overflow-scroll"
            initial={{ height: buildings }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
          >
            {historyItem?.response?.List?.table_stavby ? (
              <>
                <div className="w-full flex justify-start">
                  <p>
                    Počet stavieb:
                    {historyItem?.response?.List?.table_stavby?.length !== undefined
                      ? historyItem?.response?.List?.table_stavby?.length
                      : 1}
                  </p>
                </div>
                <table className="w-full">
                  <thead>
                    <tr>
                      <td className="text-center">Súpisné číslo</td>
                      <td className="text-center">Na pozemku parcelné číslo</td>
                      <td className="text-center">Druh stavby</td>
                      <td className="w-52 text-center">Popis stavby</td>
                      <td className="text-center">Druh chránenej nehnuteľnosti</td>
                      <td className="text-center">Umiestnenie stavby</td>
                    </tr>
                  </thead>
                  {getTableBodyBuildings()}
                </table>
              </>
            ) : null}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
}
