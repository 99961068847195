export const REBrokers = [
  {
    name: "Ing. Mikuláš Cina",
    companyName: "Ahura reality, s.r.o.",
    img: "https://www.ahurareality.sk/wp-content/uploads/2017/11/miki-768x480.png",
    email: "cina@ahurareality.sk",
    tel: "+421 918 584 222",
    city: "Michalovce",
    psc: "07101",
    street: "Fraňa Kráľa",
    number: "59",
    lat: 48.747510835835165,
    lng: 21.922408554392767,
    partners: [
      {
        name: "Ahura reality, s.r.o.",
        url: "https://www.ahurareality.sk/wp-content/uploads/2023/05/ahura-logo-1.png",
        city: "Michalovce",
        psc: "07101",
        street: "Fraňa Kráľa",
        number: "59",
        lat: 48.747510835835165,
        lng: 21.922408554392767,
      },
    ],
    items: [
      {
        owner: "Ing. Mikuláš Cina",
        avgPrice: 100400,
        city: "Michalovce",
        countOfObjects: 137,
        description:
          "Na predaj 3-izbový byt s veľká loggiou za LIDL-om v Michalovciach. - byt umiestnený na 2. poschodí - rozloha 64 m2 - 3 izby, kuchyňa, kúpeľňa, WC, chodba - pivnica v suteréne - zateplený bytový dom, bezbariérový vchod, interiér bytovky je zrekonštruovaný - byt je v pôvodnom stave Zaistíme najvýhodnejší HYPO-úver v bankách SR - využite naše 20-ročné skúsenosti v realitách. CENA: 87.900 € OBHLIADKY: 0905 387 573, 0908 047 689",
        img: [
          {
            id: "20b3a354-f726-406a-90ef-07e95f65df6c",
            img: " https://img.unitedclassifieds.sk/foto/L2p1bA==/OZ6wfa_ut_fss?st=u8m4xmsjpJ7-_PKdbe7EVvM2ohD-_scDSEWXhGFum-w&ts=1706782116&e=0",
            predajId: "0b134426-41be-4d59-9eff-012e71295ca0",
          },
          {
            id: "31021ffe-edbc-4954-80f6-ccb32b6eca08",
            img: " https://img.unitedclassifieds.sk/foto/L2p1bA==/pE6MZj6ej_fss?st=cAJCf8g8K_q3G3g786nwg6oD5nIm2E0QmC5z7oesf0I&ts=1706782116&e=0",
            predajId: "0b134426-41be-4d59-9eff-012e71295ca0",
          },
          {
            id: "5c163cad-6036-4996-bc76-112787ce1ad4",
            img: " https://img.unitedclassifieds.sk/foto/L2p1bA==/1s3DQm2QI_fss?st=13HYeHnYAxAOAJHPc6uB10CBnsIacb14da-X_m8VKSg&ts=1706782126&e=0",
            predajId: "0b134426-41be-4d59-9eff-012e71295ca0",
          },
          {
            id: "8e8aec60-d806-4282-8296-11158ab2047f",
            img: "https://img.unitedclassifieds.sk/foto/L2p1bA==/orvDg7Exs_fss?st=pj3do5DRae4XBOZM_i-Kig7_l8Ibjcgs3ctXOxcJHaM&ts=1706782124&e=0",
            predajId: "0b134426-41be-4d59-9eff-012e71295ca0",
          },
        ],
        lat: "48.7501858",
        link: "https://www.nehnutelnosti.sk/detail/Judbne2hkZO/predaj-3-izb-byt-na-sidlisku-vychod-michalovce-87-900",
        lng: "21.9077605",
        lv: '{"List":{"cislo_listu_vlastnictva":"6664","kod_katastralneho_uzemia":"Michalovce","nazov_katastralneho_uzemia":"836915","nazov_obce":"Michalovce","nazov_okresu":"Michalovce","stav_ku_dnu":"02.04.2024","table_stavby":[{"supisne_cislo":"1315 ","na_pozemku_parcelne_cislo":"3836","druh_stavby":"9","popis_stavby":"bytovy dom","druh_chranenej_nehnutelnosti":"","umiestnenie_stavby":"1"},{"supisne_cislo":"","na_pozemku_parcelne_cislo":"Právny vzťah k pozemku parcelné číslo 3836 pod stavbou s.č. 1315 je evidovaný na liste vlastníctva č. 9 313."}],"byt":[{"cislo_bytu":"12","vchod__cislo_":" 29","poschodie":" 4","supisne_cislo":" 1315","podiel_priestoru_na_spolocnych_castiach_a_":"6165/280416","vlastnici":{"por_c":"36.","titul_priezvisko_meno_rodne_meno_miesto_trvaleho_pobytu_datum_narodenia":"Poľaško Róbert r.Poľaško, 14.09.1993, Okružná 6352/92B, Michalovce, 071 01","spoluvlast_podiel":"1/1","titul_nadobudnutia":"Kúpna zmluva V-2583/020 z.d. 10.09.2020, č.z. 2421/20 "}}],"tarchy":[{"poradove_c_vlastnika_":"1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 41 42 43 44 45 46 47 48 54 57 58 59 60  ","tarcha":"Záložné právo podľa § 15 zák.č.182/1993 Z.z. v prospech ostatných vlastníkov bytov a nebytových priestorov "},{"poradove_c_vlastnika_":"36  ","tarcha":"Záložné právo v prospech Prima banka Slovensko, a.s., Hodžova 11, 010 11 Žilina, IČO: 31 575 951, na základe Zmluvy o úvere č. 0000000000815736 na byt č. 12, vchod 29, 4. posch., bytový dom č.s. 1315 na parc. CKN 3836 a podiel 6165/280416 na spoločných priestoroch a spoločných zariadeniach domu, V-2498/2021 z.d. 2. 8. 2021, č.z. 1888/21 "}]}}',
        number: "1315",
        parameters: '{"area":"64","condition":"Pôvodný stav","type":"3 izbový byt"}',
        price: 87900,
        psc: "07101",
        relevancy: 90,
        street: "Okružná",
        typeOfSale: "1",
      },
    ],
  },
];
