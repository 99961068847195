import { Marker, InfoWindow } from "@react-google-maps/api";
import { useMediaQuery } from "usehooks-ts";
import ForSaleLight from "../../Images/Pins/ForSaleLight.png";
import ForSaleDark from "../../Images/Pins/ForSaleDark.png";
import ForSaleFlatDark from "../../Images/Pins/ForSaleFlatDark.png";
import ForSaleFlatLight from "../../Images/Pins/ForSaleFlatLight.png";
import ForSaleLandDark from "../../Images/Pins/ForSaleLandDark.png";
import ForSaleLandLight from "../../Images/Pins/ForSaleLandLight.png";

export default function ForSalePins({
  viewSale,
  view,
  mouseOver,
  setMouseOver,
  previewFS,
  responseForSale,
  setExpanded,
  setViewSale,
  setVisibilityOfMarkers,
  setCurrentItem,
  isExpanded,
  setForSale,
  forSale,
  indx,
  zoom,
  highlight,
  navSmall,
  setNavSmall,
  hideMarkers,
  filterSale,
}) {
  const obj = () => {
    if (filterSale.length === 0) {
      return responseForSale?.items;
    } else {
      return filterSale?.items;
    }
  };

  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const showDetail = (index) => {
    if (window.location.pathname.includes("share")) {
      window.history.replaceState(null, "", "/");
    }
    setViewSale({ ...viewSale, view: true, index: index });
    setVisibilityOfMarkers("singleForSalePin");
    setCurrentItem(index);
    isExpanded === index ? setExpanded(-1) : setExpanded(index);
    setForSale({ ...forSale, menu: true });
    indx.current = index;
    setMouseOver({ ...mouseOver, previewFS: false, indexFS: null });
    setNavSmall({
      ...navSmall,
      properties: true,
      subjects: false,
      helper: false,
      options: false,
      login: false,
    });
  };

  return viewSale.view || zoom > 1 ? (
    <>
      {obj()?.map(
        (item, index) =>
          // <Fragment key={uuidv4()}>
          (JSON.parse(item?.parameters)?.type.toLowerCase().includes("dom") && hideMarkers.house && !viewSale.view) ||
          (JSON.parse(item?.parameters)?.type.toLowerCase().includes("byt") &&
            hideMarkers.flat &&
            !viewSale.view) ? null : (
            <Marker
              key={index}
              onMouseOver={() => previewFS(index)}
              // onMouseOut={() => setMouseOver({ ...mouseOver, previewFS: false, indexFS: null })}
              position={{ lat: Number(item?.lat), lng: Number(item?.lng) }}
              label={{
                text: zoom > 12 ? item?.price.toString() + " €" : " ",
                color: view === "hybrid" ? "#fff" : "#5491f5",
                fontSize: "14px",
                className: `absolute top-9 left-3 p-1 + ${view === "hybrid" ? "specialtext" : "specialtextWhite"}`,
              }}
              icon={{
                url:
                  highlight.forSale && highlight.indexFS === index
                    ? JSON.parse(item?.parameters).type.includes("byt")
                      ? ForSaleFlatDark
                      : ForSaleDark
                    : JSON.parse(item?.parameters).type.includes("byt")
                      ? ForSaleFlatLight
                      : ForSaleLight,
                scaledSize: new window.google.maps.Size(50, 50),
                fillColor: "green",
              }}
              onClick={() => {
                if (isSmallDevice) {
                  showDetail(index);
                }
              }}
            >
              {mouseOver.previewFS && mouseOver.indexFS === index ? (
                <InfoWindow position={{ lat: Number(item?.lat), lng: Number(item?.lng) }}>
                  <div
                    onClick={() => {
                      showDetail(index);
                    }}
                    onMouseLeave={() => setMouseOver({ ...mouseOver, previewFS: false, indexFS: null })}
                    className="absolute bottom-1 w-60 h-52 z-50 cursor-pointer text-lg flex flex-col justify-start items-center rounded-lg"
                  >
                    <div className="w-60 h-40 bg-sky-100 z-50 text-lg flex flex-col justify-center items-center rounded-lg">
                      <div className="w-full h-1/2 rounded-t-lg">
                        <img src={item?.img[0]?.img} alt="" className="w-full h-full object-cover rounded-t-lg" />
                      </div>
                      <div className="w-full h-1/2 flex flex-col justify-start p-1 text-base">
                        <p className="font-medium ">
                          {item?.city} {item?.number}
                        </p>
                        <p className="text-sm">Predajná cena: {item?.price} €</p>
                        <p className="text-sm truncate">
                          {JSON.parse(item?.parameters)?.type}, {JSON.parse(item?.parameters)?.area} m²,{" "}
                          {JSON.parse(item?.parameters)?.condition}
                        </p>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              ) : null}
            </Marker>
          ),
        // </Fragment>
      )}
    </>
  ) : null;
}
