const UnitsLevice = {
  Rybník: [
    { lat: 48.333149, lng: 18.559092 },
    { lat: 48.332731, lng: 18.559084 },
    { lat: 48.332309, lng: 18.559093 },
    { lat: 48.331968, lng: 18.5591 },
    { lat: 48.3310293, lng: 18.5591042 },
    { lat: 48.3309589, lng: 18.5590949 },
    { lat: 48.3307778, lng: 18.5590709 },
    { lat: 48.3304031, lng: 18.5590214 },
    { lat: 48.3297217, lng: 18.5588185 },
    { lat: 48.3292193, lng: 18.5585107 },
    { lat: 48.3287748, lng: 18.5582383 },
    { lat: 48.3285213, lng: 18.5580156 },
    { lat: 48.328079, lng: 18.557407 },
    { lat: 48.327835, lng: 18.556999 },
    { lat: 48.327572, lng: 18.556482 },
    { lat: 48.327416, lng: 18.556125 },
    { lat: 48.327285, lng: 18.555808 },
    { lat: 48.327127, lng: 18.555409 },
    { lat: 48.326965, lng: 18.555062 },
    { lat: 48.326825, lng: 18.554779 },
    { lat: 48.326718, lng: 18.554585 },
    { lat: 48.326645, lng: 18.554454 },
    { lat: 48.32655, lng: 18.554262 },
    { lat: 48.326411, lng: 18.553965 },
    { lat: 48.326051, lng: 18.55311 },
    { lat: 48.325901, lng: 18.552711 },
    { lat: 48.325766, lng: 18.55222 },
    { lat: 48.325512, lng: 18.551422 },
    { lat: 48.325469, lng: 18.551227 },
    { lat: 48.3254, lng: 18.550694 },
    { lat: 48.325358, lng: 18.549635 },
    { lat: 48.325342, lng: 18.549341 },
    { lat: 48.325313, lng: 18.548985 },
    { lat: 48.325179, lng: 18.548429 },
    { lat: 48.325076, lng: 18.548252 },
    { lat: 48.324692, lng: 18.547584 },
    { lat: 48.324503, lng: 18.547292 },
    { lat: 48.324256, lng: 18.546974 },
    { lat: 48.323674, lng: 18.546182 },
    { lat: 48.32272, lng: 18.544957 },
    { lat: 48.322499, lng: 18.544707 },
    { lat: 48.322002, lng: 18.544407 },
    { lat: 48.321907, lng: 18.544146 },
    { lat: 48.321894, lng: 18.544109 },
    { lat: 48.321822, lng: 18.544076 },
    { lat: 48.321359, lng: 18.543862 },
    { lat: 48.321221, lng: 18.544021 },
    { lat: 48.320771, lng: 18.543805 },
    { lat: 48.320193, lng: 18.543603 },
    { lat: 48.31945, lng: 18.543061 },
    { lat: 48.319213, lng: 18.542783 },
    { lat: 48.31915, lng: 18.542709 },
    { lat: 48.318773, lng: 18.542544 },
    { lat: 48.318074, lng: 18.542304 },
    { lat: 48.317316, lng: 18.54202 },
    { lat: 48.317198, lng: 18.541974 },
    { lat: 48.316651, lng: 18.541764 },
    { lat: 48.316432, lng: 18.541617 },
    { lat: 48.316232, lng: 18.541502 },
    { lat: 48.315888, lng: 18.541311 },
    { lat: 48.315719, lng: 18.541217 },
    { lat: 48.315453, lng: 18.541031 },
    { lat: 48.3154, lng: 18.54099 },
    { lat: 48.315368, lng: 18.540966 },
    { lat: 48.315262, lng: 18.540863 },
    { lat: 48.315107, lng: 18.540693 },
    { lat: 48.31463, lng: 18.540221 },
    { lat: 48.314467, lng: 18.540068 },
    { lat: 48.31429, lng: 18.53991 },
    { lat: 48.314062, lng: 18.539721 },
    { lat: 48.313894, lng: 18.539598 },
    { lat: 48.313495, lng: 18.539303 },
    { lat: 48.31333, lng: 18.539208 },
    { lat: 48.313102, lng: 18.539115 },
    { lat: 48.312667, lng: 18.538888 },
    { lat: 48.312529, lng: 18.538816 },
    { lat: 48.31218, lng: 18.538733 },
    { lat: 48.31204, lng: 18.538647 },
    { lat: 48.311712, lng: 18.538425 },
    { lat: 48.311259, lng: 18.538164 },
    { lat: 48.310926, lng: 18.537994 },
    { lat: 48.310419, lng: 18.537692 },
    { lat: 48.309813, lng: 18.537443 },
    { lat: 48.3094125, lng: 18.5372655 },
    { lat: 48.3085601, lng: 18.5371487 },
    { lat: 48.3079082, lng: 18.5371711 },
    { lat: 48.3068056, lng: 18.5375501 },
    { lat: 48.3065912, lng: 18.5374469 },
    { lat: 48.3062555, lng: 18.5375366 },
    { lat: 48.3051771, lng: 18.5375363 },
    { lat: 48.3045061, lng: 18.5379115 },
    { lat: 48.3043249, lng: 18.5376303 },
    { lat: 48.3040418, lng: 18.5375062 },
    { lat: 48.3024888, lng: 18.5371026 },
    { lat: 48.3023258, lng: 18.5371135 },
    { lat: 48.3018303, lng: 18.5367595 },
    { lat: 48.301456, lng: 18.5363934 },
    { lat: 48.3011613, lng: 18.5360592 },
    { lat: 48.3010095, lng: 18.5357407 },
    { lat: 48.3005875, lng: 18.5351856 },
    { lat: 48.2995539, lng: 18.534466 },
    { lat: 48.2989699, lng: 18.5332384 },
    { lat: 48.2987046, lng: 18.532907 },
    { lat: 48.2986301, lng: 18.5328453 },
    { lat: 48.2978832, lng: 18.5343374 },
    { lat: 48.297143, lng: 18.5352979 },
    { lat: 48.2967703, lng: 18.5359852 },
    { lat: 48.2960185, lng: 18.5371642 },
    { lat: 48.2956131, lng: 18.5380199 },
    { lat: 48.2952252, lng: 18.5390086 },
    { lat: 48.2948854, lng: 18.5394523 },
    { lat: 48.2945997, lng: 18.5400002 },
    { lat: 48.2937996, lng: 18.5423732 },
    { lat: 48.2927089, lng: 18.5449831 },
    { lat: 48.2922647, lng: 18.5462211 },
    { lat: 48.2917177, lng: 18.5481914 },
    { lat: 48.2915146, lng: 18.5494654 },
    { lat: 48.2912915, lng: 18.549552 },
    { lat: 48.2876571, lng: 18.5496789 },
    { lat: 48.2857905, lng: 18.5494136 },
    { lat: 48.2847296, lng: 18.5489508 },
    { lat: 48.2845994, lng: 18.5490044 },
    { lat: 48.2841951, lng: 18.548976 },
    { lat: 48.2838117, lng: 18.5490406 },
    { lat: 48.2819672, lng: 18.5499618 },
    { lat: 48.2818928, lng: 18.5496739 },
    { lat: 48.2816644, lng: 18.5489073 },
    { lat: 48.2814978, lng: 18.549054 },
    { lat: 48.2809413, lng: 18.5496849 },
    { lat: 48.2805993, lng: 18.5502753 },
    { lat: 48.2803524, lng: 18.550588 },
    { lat: 48.2797457, lng: 18.5512046 },
    { lat: 48.2792503, lng: 18.551532 },
    { lat: 48.2789608, lng: 18.5516186 },
    { lat: 48.278878, lng: 18.5513476 },
    { lat: 48.2785002, lng: 18.5514707 },
    { lat: 48.2780769, lng: 18.5507049 },
    { lat: 48.2777136, lng: 18.5510931 },
    { lat: 48.2770335, lng: 18.5519999 },
    { lat: 48.2758788, lng: 18.5533012 },
    { lat: 48.2748952, lng: 18.5527666 },
    { lat: 48.2739884, lng: 18.5524796 },
    { lat: 48.2728939, lng: 18.5525567 },
    { lat: 48.2721686, lng: 18.5528036 },
    { lat: 48.272467, lng: 18.5538443 },
    { lat: 48.2713883, lng: 18.5547406 },
    { lat: 48.2706031, lng: 18.5555224 },
    { lat: 48.2701388, lng: 18.5560632 },
    { lat: 48.2696834, lng: 18.5567672 },
    { lat: 48.270201, lng: 18.5574633 },
    { lat: 48.2713572, lng: 18.5591501 },
    { lat: 48.2714759, lng: 18.5592166 },
    { lat: 48.2729858, lng: 18.5608967 },
    { lat: 48.2713619, lng: 18.5641557 },
    { lat: 48.2697503, lng: 18.5678052 },
    { lat: 48.2703586, lng: 18.5685284 },
    { lat: 48.2705566, lng: 18.5687038 },
    { lat: 48.2708475, lng: 18.5688479 },
    { lat: 48.271817, lng: 18.5697241 },
    { lat: 48.2730584, lng: 18.5712173 },
    { lat: 48.2749737, lng: 18.5732556 },
    { lat: 48.2753186, lng: 18.5735624 },
    { lat: 48.2757256, lng: 18.5738254 },
    { lat: 48.2763853, lng: 18.5744381 },
    { lat: 48.2766325, lng: 18.5746099 },
    { lat: 48.2769607, lng: 18.5746093 },
    { lat: 48.2771411, lng: 18.574812 },
    { lat: 48.2772087, lng: 18.5752832 },
    { lat: 48.2768616, lng: 18.5758081 },
    { lat: 48.2766055, lng: 18.5769004 },
    { lat: 48.2761607, lng: 18.580338 },
    { lat: 48.2760213, lng: 18.5809882 },
    { lat: 48.275597, lng: 18.5823677 },
    { lat: 48.2744898, lng: 18.5844166 },
    { lat: 48.2743964, lng: 18.5847764 },
    { lat: 48.2753659, lng: 18.5851338 },
    { lat: 48.2751662, lng: 18.5861852 },
    { lat: 48.2757191, lng: 18.5866461 },
    { lat: 48.2753907, lng: 18.590116 },
    { lat: 48.2767099, lng: 18.5909314 },
    { lat: 48.2777656, lng: 18.5912805 },
    { lat: 48.2778325, lng: 18.5912893 },
    { lat: 48.277989, lng: 18.589132 },
    { lat: 48.2779403, lng: 18.5891277 },
    { lat: 48.2780702, lng: 18.5871901 },
    { lat: 48.279451, lng: 18.5874614 },
    { lat: 48.2795139, lng: 18.5866091 },
    { lat: 48.2796101, lng: 18.586017 },
    { lat: 48.2802191, lng: 18.5830194 },
    { lat: 48.2805117, lng: 18.5813585 },
    { lat: 48.280827, lng: 18.5791607 },
    { lat: 48.2809546, lng: 18.5777969 },
    { lat: 48.281291, lng: 18.5779071 },
    { lat: 48.2816912, lng: 18.5784997 },
    { lat: 48.282072, lng: 18.578573 },
    { lat: 48.2821466, lng: 18.5787002 },
    { lat: 48.2822227, lng: 18.5794317 },
    { lat: 48.2823871, lng: 18.5794477 },
    { lat: 48.2826791, lng: 18.5796523 },
    { lat: 48.2831142, lng: 18.5798104 },
    { lat: 48.2840891, lng: 18.5797141 },
    { lat: 48.2841177, lng: 18.5801983 },
    { lat: 48.2842437, lng: 18.5804183 },
    { lat: 48.2848901, lng: 18.5807293 },
    { lat: 48.2850311, lng: 18.5809132 },
    { lat: 48.2854746, lng: 18.5808743 },
    { lat: 48.2856714, lng: 18.5811753 },
    { lat: 48.2858959, lng: 18.5811531 },
    { lat: 48.2860873, lng: 18.581766 },
    { lat: 48.2860843, lng: 18.5820444 },
    { lat: 48.2861318, lng: 18.5821487 },
    { lat: 48.2869845, lng: 18.582189 },
    { lat: 48.2870456, lng: 18.5822963 },
    { lat: 48.2870668, lng: 18.5824761 },
    { lat: 48.2874002, lng: 18.5831917 },
    { lat: 48.2874715, lng: 18.5832389 },
    { lat: 48.2876392, lng: 18.5832704 },
    { lat: 48.2878006, lng: 18.5829839 },
    { lat: 48.2880018, lng: 18.5830116 },
    { lat: 48.2881487, lng: 18.5828527 },
    { lat: 48.2882696, lng: 18.582898 },
    { lat: 48.2883378, lng: 18.5828454 },
    { lat: 48.288492, lng: 18.5825917 },
    { lat: 48.2886138, lng: 18.5820954 },
    { lat: 48.2888247, lng: 18.5818443 },
    { lat: 48.2890077, lng: 18.5818505 },
    { lat: 48.2893275, lng: 18.5819455 },
    { lat: 48.2894795, lng: 18.5820834 },
    { lat: 48.2896454, lng: 18.5821265 },
    { lat: 48.2896475, lng: 18.5825813 },
    { lat: 48.2920735, lng: 18.5829448 },
    { lat: 48.2929308, lng: 18.5829802 },
    { lat: 48.2943584, lng: 18.5831583 },
    { lat: 48.2948554, lng: 18.5834901 },
    { lat: 48.2949331, lng: 18.5839389 },
    { lat: 48.295095, lng: 18.5839733 },
    { lat: 48.2952372, lng: 18.5839934 },
    { lat: 48.2954653, lng: 18.5838326 },
    { lat: 48.2955657, lng: 18.5839354 },
    { lat: 48.2957793, lng: 18.5838398 },
    { lat: 48.2958713, lng: 18.5838745 },
    { lat: 48.2958751, lng: 18.5842124 },
    { lat: 48.2962026, lng: 18.5855998 },
    { lat: 48.2962693, lng: 18.5865756 },
    { lat: 48.2967975, lng: 18.5868293 },
    { lat: 48.2970077, lng: 18.5870866 },
    { lat: 48.2974029, lng: 18.5872773 },
    { lat: 48.2978411, lng: 18.587372 },
    { lat: 48.2979648, lng: 18.5876728 },
    { lat: 48.2981762, lng: 18.5877861 },
    { lat: 48.3009527, lng: 18.5862862 },
    { lat: 48.301729, lng: 18.5863526 },
    { lat: 48.3021434, lng: 18.586175 },
    { lat: 48.3022082, lng: 18.5860416 },
    { lat: 48.3022639, lng: 18.5855512 },
    { lat: 48.3025696, lng: 18.5849557 },
    { lat: 48.3025798, lng: 18.5845832 },
    { lat: 48.302767, lng: 18.5844579 },
    { lat: 48.3028371, lng: 18.5841917 },
    { lat: 48.303141, lng: 18.5840354 },
    { lat: 48.3033965, lng: 18.5840476 },
    { lat: 48.303557, lng: 18.5841551 },
    { lat: 48.3038483, lng: 18.5840142 },
    { lat: 48.3039231, lng: 18.5838172 },
    { lat: 48.3040757, lng: 18.5836979 },
    { lat: 48.3041642, lng: 18.5835226 },
    { lat: 48.3043515, lng: 18.5835634 },
    { lat: 48.3045555, lng: 18.5834566 },
    { lat: 48.3047418, lng: 18.5834603 },
    { lat: 48.3049356, lng: 18.5831665 },
    { lat: 48.3053524, lng: 18.5830548 },
    { lat: 48.3055307, lng: 18.5827885 },
    { lat: 48.3058455, lng: 18.5827052 },
    { lat: 48.3060397, lng: 18.582483 },
    { lat: 48.3063002, lng: 18.5825835 },
    { lat: 48.3063763, lng: 18.5825522 },
    { lat: 48.3064543, lng: 18.5823701 },
    { lat: 48.3065052, lng: 18.5823717 },
    { lat: 48.3066204, lng: 18.5824376 },
    { lat: 48.3069727, lng: 18.5824224 },
    { lat: 48.307381, lng: 18.5825891 },
    { lat: 48.3080479, lng: 18.5823886 },
    { lat: 48.3086021, lng: 18.5823514 },
    { lat: 48.3087578, lng: 18.5822076 },
    { lat: 48.3091915, lng: 18.5821164 },
    { lat: 48.3092958, lng: 18.5820009 },
    { lat: 48.3094456, lng: 18.5819556 },
    { lat: 48.3097587, lng: 18.5815947 },
    { lat: 48.3099066, lng: 18.581534 },
    { lat: 48.3102071, lng: 18.5810667 },
    { lat: 48.3102686, lng: 18.5808727 },
    { lat: 48.3106343, lng: 18.5804841 },
    { lat: 48.3111336, lng: 18.5797943 },
    { lat: 48.3116986, lng: 18.5792766 },
    { lat: 48.3122333, lng: 18.5785753 },
    { lat: 48.3128651, lng: 18.5774853 },
    { lat: 48.3133135, lng: 18.5770408 },
    { lat: 48.3133765, lng: 18.5768123 },
    { lat: 48.3137078, lng: 18.5765179 },
    { lat: 48.3138466, lng: 18.576436 },
    { lat: 48.3143901, lng: 18.5764114 },
    { lat: 48.3146057, lng: 18.5765586 },
    { lat: 48.3148726, lng: 18.5765907 },
    { lat: 48.3150187, lng: 18.5766854 },
    { lat: 48.3156825, lng: 18.5765266 },
    { lat: 48.3160594, lng: 18.5767369 },
    { lat: 48.3160631, lng: 18.5769526 },
    { lat: 48.316224, lng: 18.5775005 },
    { lat: 48.3162125, lng: 18.5784453 },
    { lat: 48.3162662, lng: 18.5786885 },
    { lat: 48.3163678, lng: 18.5790166 },
    { lat: 48.3167478, lng: 18.5796762 },
    { lat: 48.3169408, lng: 18.5802362 },
    { lat: 48.3172553, lng: 18.5806462 },
    { lat: 48.3179939, lng: 18.5810953 },
    { lat: 48.3183936, lng: 18.5815757 },
    { lat: 48.3184837, lng: 18.5818623 },
    { lat: 48.318426, lng: 18.5823988 },
    { lat: 48.3185012, lng: 18.583114 },
    { lat: 48.3187137, lng: 18.5834958 },
    { lat: 48.3188298, lng: 18.5844231 },
    { lat: 48.3190583, lng: 18.5849108 },
    { lat: 48.31937, lng: 18.5850603 },
    { lat: 48.3200647, lng: 18.5866075 },
    { lat: 48.3211278, lng: 18.588231 },
    { lat: 48.3212916, lng: 18.5889428 },
    { lat: 48.3216024, lng: 18.5894274 },
    { lat: 48.3218664, lng: 18.5900904 },
    { lat: 48.3224564, lng: 18.5923678 },
    { lat: 48.3226309, lng: 18.592754 },
    { lat: 48.3233145, lng: 18.5936442 },
    { lat: 48.3243501, lng: 18.5953454 },
    { lat: 48.324421, lng: 18.5962739 },
    { lat: 48.3246587, lng: 18.5969713 },
    { lat: 48.3247258, lng: 18.5975217 },
    { lat: 48.324835, lng: 18.5979138 },
    { lat: 48.3250101, lng: 18.5983506 },
    { lat: 48.3251138, lng: 18.5985371 },
    { lat: 48.3255478, lng: 18.598781 },
    { lat: 48.3264521, lng: 18.5991261 },
    { lat: 48.3271574, lng: 18.6004387 },
    { lat: 48.3276209, lng: 18.6024884 },
    { lat: 48.3281198, lng: 18.60349 },
    { lat: 48.3282704, lng: 18.6041436 },
    { lat: 48.3286839, lng: 18.604797 },
    { lat: 48.3292242, lng: 18.6053576 },
    { lat: 48.3297921, lng: 18.605638 },
    { lat: 48.3304392, lng: 18.606171 },
    { lat: 48.3306719, lng: 18.6062833 },
    { lat: 48.3317133, lng: 18.6062481 },
    { lat: 48.3320578, lng: 18.606111 },
    { lat: 48.3325251, lng: 18.6061761 },
    { lat: 48.3330796, lng: 18.6061505 },
    { lat: 48.3336126, lng: 18.6062856 },
    { lat: 48.3349355, lng: 18.6062794 },
    { lat: 48.336452, lng: 18.6064155 },
    { lat: 48.3369851, lng: 18.6063265 },
    { lat: 48.3372464, lng: 18.6063587 },
    { lat: 48.3376649, lng: 18.6065266 },
    { lat: 48.3384987, lng: 18.6067164 },
    { lat: 48.3389516, lng: 18.6071216 },
    { lat: 48.3399771, lng: 18.607729 },
    { lat: 48.3406229, lng: 18.607778 },
    { lat: 48.3417553, lng: 18.6080068 },
    { lat: 48.3421058, lng: 18.607982 },
    { lat: 48.342934, lng: 18.6075893 },
    { lat: 48.3437443, lng: 18.6068304 },
    { lat: 48.3442935, lng: 18.6065399 },
    { lat: 48.344924, lng: 18.605851 },
    { lat: 48.344946, lng: 18.60579 },
    { lat: 48.344153, lng: 18.603835 },
    { lat: 48.343506, lng: 18.601083 },
    { lat: 48.343743, lng: 18.596701 },
    { lat: 48.343745, lng: 18.596649 },
    { lat: 48.343753, lng: 18.59652 },
    { lat: 48.343623, lng: 18.596346 },
    { lat: 48.343664, lng: 18.596177 },
    { lat: 48.343797, lng: 18.595986 },
    { lat: 48.344006, lng: 18.595174 },
    { lat: 48.344172, lng: 18.594651 },
    { lat: 48.344273, lng: 18.594516 },
    { lat: 48.344393, lng: 18.59423 },
    { lat: 48.344384, lng: 18.594016 },
    { lat: 48.344478, lng: 18.593698 },
    { lat: 48.344552, lng: 18.593613 },
    { lat: 48.344692, lng: 18.593393 },
    { lat: 48.344909, lng: 18.592712 },
    { lat: 48.345221, lng: 18.592273 },
    { lat: 48.345164, lng: 18.59195 },
    { lat: 48.345216, lng: 18.591599 },
    { lat: 48.345174, lng: 18.591385 },
    { lat: 48.345265, lng: 18.590822 },
    { lat: 48.345361, lng: 18.590574 },
    { lat: 48.345399, lng: 18.590332 },
    { lat: 48.345352, lng: 18.59015 },
    { lat: 48.345365, lng: 18.589859 },
    { lat: 48.34541, lng: 18.589626 },
    { lat: 48.345471, lng: 18.589395 },
    { lat: 48.345487, lng: 18.589137 },
    { lat: 48.345523, lng: 18.588972 },
    { lat: 48.345563, lng: 18.588551 },
    { lat: 48.345594, lng: 18.588163 },
    { lat: 48.345698, lng: 18.58783 },
    { lat: 48.345668, lng: 18.587528 },
    { lat: 48.345563, lng: 18.587256 },
    { lat: 48.345563, lng: 18.587198 },
    { lat: 48.345605, lng: 18.587058 },
    { lat: 48.345554, lng: 18.586863 },
    { lat: 48.345452, lng: 18.586694 },
    { lat: 48.345403, lng: 18.586493 },
    { lat: 48.34525, lng: 18.586266 },
    { lat: 48.345264, lng: 18.586091 },
    { lat: 48.345176, lng: 18.585934 },
    { lat: 48.345157, lng: 18.585581 },
    { lat: 48.345081, lng: 18.585431 },
    { lat: 48.345056, lng: 18.585271 },
    { lat: 48.345005, lng: 18.585175 },
    { lat: 48.345003, lng: 18.585092 },
    { lat: 48.344983, lng: 18.584966 },
    { lat: 48.344889, lng: 18.584545 },
    { lat: 48.344882, lng: 18.584325 },
    { lat: 48.344823, lng: 18.584057 },
    { lat: 48.344929, lng: 18.583888 },
    { lat: 48.344919, lng: 18.583236 },
    { lat: 48.344899, lng: 18.583047 },
    { lat: 48.344866, lng: 18.582591 },
    { lat: 48.344819, lng: 18.582467 },
    { lat: 48.344736, lng: 18.582354 },
    { lat: 48.34469, lng: 18.582166 },
    { lat: 48.344723, lng: 18.581513 },
    { lat: 48.344673, lng: 18.58118 },
    { lat: 48.344662, lng: 18.580961 },
    { lat: 48.344717, lng: 18.580761 },
    { lat: 48.344665, lng: 18.580501 },
    { lat: 48.344729, lng: 18.580411 },
    { lat: 48.344757, lng: 18.580191 },
    { lat: 48.344974, lng: 18.579847 },
    { lat: 48.345065, lng: 18.579513 },
    { lat: 48.345154, lng: 18.579377 },
    { lat: 48.345344, lng: 18.579274 },
    { lat: 48.345387, lng: 18.579155 },
    { lat: 48.345385, lng: 18.578917 },
    { lat: 48.345434, lng: 18.578836 },
    { lat: 48.345514, lng: 18.578591 },
    { lat: 48.34575, lng: 18.578199 },
    { lat: 48.345952, lng: 18.578088 },
    { lat: 48.346115, lng: 18.577717 },
    { lat: 48.346174, lng: 18.577493 },
    { lat: 48.346211, lng: 18.577411 },
    { lat: 48.346547, lng: 18.576779 },
    { lat: 48.346842, lng: 18.576402 },
    { lat: 48.347045, lng: 18.575994 },
    { lat: 48.347128, lng: 18.575913 },
    { lat: 48.347293, lng: 18.5759 },
    { lat: 48.347335, lng: 18.575871 },
    { lat: 48.347472, lng: 18.575746 },
    { lat: 48.347749, lng: 18.575467 },
    { lat: 48.347782, lng: 18.575366 },
    { lat: 48.347894, lng: 18.575202 },
    { lat: 48.347865, lng: 18.574917 },
    { lat: 48.347966, lng: 18.574687 },
    { lat: 48.348113, lng: 18.574644 },
    { lat: 48.348216, lng: 18.574613 },
    { lat: 48.348352, lng: 18.574607 },
    { lat: 48.348422, lng: 18.574604 },
    { lat: 48.348411, lng: 18.574509 },
    { lat: 48.348402, lng: 18.574427 },
    { lat: 48.348351, lng: 18.574295 },
    { lat: 48.348329, lng: 18.574094 },
    { lat: 48.348258, lng: 18.573507 },
    { lat: 48.34795, lng: 18.573136 },
    { lat: 48.347936, lng: 18.573119 },
    { lat: 48.347921, lng: 18.573142 },
    { lat: 48.347908, lng: 18.57316 },
    { lat: 48.347896, lng: 18.573145 },
    { lat: 48.347836, lng: 18.573074 },
    { lat: 48.347441, lng: 18.572607 },
    { lat: 48.347296, lng: 18.57236 },
    { lat: 48.346969, lng: 18.571811 },
    { lat: 48.346709, lng: 18.571415 },
    { lat: 48.346642, lng: 18.571321 },
    { lat: 48.34659, lng: 18.571253 },
    { lat: 48.346134, lng: 18.570781 },
    { lat: 48.346051, lng: 18.570716 },
    { lat: 48.345868, lng: 18.570583 },
    { lat: 48.345047, lng: 18.570045 },
    { lat: 48.343796, lng: 18.569444 },
    { lat: 48.343199, lng: 18.569231 },
    { lat: 48.342863, lng: 18.569094 },
    { lat: 48.342739, lng: 18.569025 },
    { lat: 48.342444, lng: 18.56891 },
    { lat: 48.342259, lng: 18.568786 },
    { lat: 48.34199, lng: 18.56852 },
    { lat: 48.341647, lng: 18.568063 },
    { lat: 48.341382, lng: 18.567708 },
    { lat: 48.340664, lng: 18.566836 },
    { lat: 48.339859, lng: 18.565683 },
    { lat: 48.339783, lng: 18.565576 },
    { lat: 48.339404, lng: 18.564973 },
    { lat: 48.33918, lng: 18.564679 },
    { lat: 48.338955, lng: 18.564144 },
    { lat: 48.338846, lng: 18.563892 },
    { lat: 48.338834, lng: 18.563852 },
    { lat: 48.338723, lng: 18.56351 },
    { lat: 48.338622, lng: 18.562977 },
    { lat: 48.33863, lng: 18.562718 },
    { lat: 48.338646, lng: 18.562145 },
    { lat: 48.338741, lng: 18.561774 },
    { lat: 48.338801, lng: 18.561623 },
    { lat: 48.339001, lng: 18.561341 },
    { lat: 48.339077, lng: 18.561255 },
    { lat: 48.339306, lng: 18.561054 },
    { lat: 48.339363, lng: 18.561031 },
    { lat: 48.339516, lng: 18.560971 },
    { lat: 48.339597, lng: 18.560939 },
    { lat: 48.339733, lng: 18.560741 },
    { lat: 48.339813, lng: 18.560456 },
    { lat: 48.339871, lng: 18.560247 },
    { lat: 48.339906, lng: 18.560027 },
    { lat: 48.339928, lng: 18.559891 },
    { lat: 48.33994, lng: 18.559808 },
    { lat: 48.33994, lng: 18.559774 },
    { lat: 48.339939, lng: 18.559606 },
    { lat: 48.339886, lng: 18.559274 },
    { lat: 48.339769, lng: 18.558929 },
    { lat: 48.339535, lng: 18.558602 },
    { lat: 48.339443, lng: 18.558512 },
    { lat: 48.339141, lng: 18.558504 },
    { lat: 48.338808, lng: 18.558543 },
    { lat: 48.338548, lng: 18.558672 },
    { lat: 48.337882, lng: 18.55892 },
    { lat: 48.337288, lng: 18.559023 },
    { lat: 48.336806, lng: 18.55912 },
    { lat: 48.33678, lng: 18.559127 },
    { lat: 48.336395, lng: 18.559238 },
    { lat: 48.336132, lng: 18.559305 },
    { lat: 48.33606, lng: 18.559324 },
    { lat: 48.335972, lng: 18.559369 },
    { lat: 48.335463, lng: 18.559384 },
    { lat: 48.335324, lng: 18.559388 },
    { lat: 48.333896, lng: 18.5592 },
    { lat: 48.333599, lng: 18.559139 },
    { lat: 48.333288, lng: 18.559103 },
    { lat: 48.333148, lng: 18.559125 },
    { lat: 48.333149, lng: 18.559092 },
  ],
  Bajka: [
    { lat: 48.1626381, lng: 18.5177988 },
    { lat: 48.1625544, lng: 18.5176717 },
    { lat: 48.1609445, lng: 18.5159564 },
    { lat: 48.1607978, lng: 18.5158555 },
    { lat: 48.1607045, lng: 18.5158607 },
    { lat: 48.1619747, lng: 18.5132915 },
    { lat: 48.1643175, lng: 18.5078715 },
    { lat: 48.1610246, lng: 18.5043912 },
    { lat: 48.1609731, lng: 18.5036464 },
    { lat: 48.1603279, lng: 18.5026894 },
    { lat: 48.1589004, lng: 18.5011767 },
    { lat: 48.1574022, lng: 18.4998743 },
    { lat: 48.1577358, lng: 18.4984053 },
    { lat: 48.158205, lng: 18.4935485 },
    { lat: 48.158395, lng: 18.491993 },
    { lat: 48.1585097, lng: 18.4915989 },
    { lat: 48.1590865, lng: 18.4884821 },
    { lat: 48.1592525, lng: 18.4879333 },
    { lat: 48.1599501, lng: 18.4850222 },
    { lat: 48.1602365, lng: 18.4842888 },
    { lat: 48.1606408, lng: 18.4824345 },
    { lat: 48.1607316, lng: 18.481836 },
    { lat: 48.1602277, lng: 18.4815774 },
    { lat: 48.1599548, lng: 18.4813171 },
    { lat: 48.1597633, lng: 18.4809762 },
    { lat: 48.1593736, lng: 18.4799559 },
    { lat: 48.158918, lng: 18.4791045 },
    { lat: 48.1584259, lng: 18.4783923 },
    { lat: 48.1574656, lng: 18.4772109 },
    { lat: 48.1574268, lng: 18.4771807 },
    { lat: 48.1573196, lng: 18.4771022 },
    { lat: 48.1558018, lng: 18.476082 },
    { lat: 48.1550593, lng: 18.475712 },
    { lat: 48.1537817, lng: 18.4751965 },
    { lat: 48.1528591, lng: 18.4746333 },
    { lat: 48.1517413, lng: 18.4737225 },
    { lat: 48.1498673, lng: 18.4713512 },
    { lat: 48.1493783, lng: 18.4727382 },
    { lat: 48.1484397, lng: 18.4749847 },
    { lat: 48.1471578, lng: 18.477329 },
    { lat: 48.1465343, lng: 18.4781836 },
    { lat: 48.1454833, lng: 18.4793256 },
    { lat: 48.145047, lng: 18.4802007 },
    { lat: 48.1445978, lng: 18.4819417 },
    { lat: 48.1443616, lng: 18.4826415 },
    { lat: 48.1443978, lng: 18.4826836 },
    { lat: 48.1444147, lng: 18.4826434 },
    { lat: 48.1446018, lng: 18.4829225 },
    { lat: 48.146199, lng: 18.4854944 },
    { lat: 48.1470926, lng: 18.4867352 },
    { lat: 48.1491637, lng: 18.4892499 },
    { lat: 48.1517803, lng: 18.4921415 },
    { lat: 48.1514835, lng: 18.4940503 },
    { lat: 48.1512597, lng: 18.494609 },
    { lat: 48.1503327, lng: 18.496338 },
    { lat: 48.1486698, lng: 18.4996707 },
    { lat: 48.1480561, lng: 18.5006065 },
    { lat: 48.1476019, lng: 18.5021598 },
    { lat: 48.1475253, lng: 18.5038902 },
    { lat: 48.1476622, lng: 18.5052148 },
    { lat: 48.1480743, lng: 18.5062616 },
    { lat: 48.148911, lng: 18.5078027 },
    { lat: 48.1479505, lng: 18.5159614 },
    { lat: 48.1499292, lng: 18.5176936 },
    { lat: 48.1498734, lng: 18.5180308 },
    { lat: 48.1497046, lng: 18.5185847 },
    { lat: 48.1496078, lng: 18.5185313 },
    { lat: 48.1494936, lng: 18.5190357 },
    { lat: 48.1493797, lng: 18.5190575 },
    { lat: 48.1493174, lng: 18.5192779 },
    { lat: 48.1486326, lng: 18.5199047 },
    { lat: 48.1484234, lng: 18.5201619 },
    { lat: 48.1482774, lng: 18.5203986 },
    { lat: 48.1481507, lng: 18.5207568 },
    { lat: 48.147079, lng: 18.5251441 },
    { lat: 48.1468492, lng: 18.5271709 },
    { lat: 48.14737, lng: 18.5275528 },
    { lat: 48.1480209, lng: 18.528163 },
    { lat: 48.1514096, lng: 18.5318641 },
    { lat: 48.1529025, lng: 18.5332612 },
    { lat: 48.1534082, lng: 18.5347589 },
    { lat: 48.1545273, lng: 18.5362128 },
    { lat: 48.1549683, lng: 18.5366823 },
    { lat: 48.1550195, lng: 18.5367311 },
    { lat: 48.155134, lng: 18.5365354 },
    { lat: 48.1585654, lng: 18.5304436 },
    { lat: 48.1584979, lng: 18.5302831 },
    { lat: 48.1599437, lng: 18.5276828 },
    { lat: 48.1614967, lng: 18.5252261 },
    { lat: 48.1603977, lng: 18.5237937 },
    { lat: 48.1593686, lng: 18.5222988 },
    { lat: 48.1608194, lng: 18.5221588 },
    { lat: 48.1607982, lng: 18.521652 },
    { lat: 48.1614795, lng: 18.5205068 },
    { lat: 48.1626381, lng: 18.5177988 },
  ],
  Lontov: [
    { lat: 48.0368498, lng: 18.8176079 },
    { lat: 48.0369659, lng: 18.817416 },
    { lat: 48.0371572, lng: 18.8172578 },
    { lat: 48.03771, lng: 18.8169643 },
    { lat: 48.0381848, lng: 18.8161957 },
    { lat: 48.0386922, lng: 18.815745 },
    { lat: 48.0388923, lng: 18.8154562 },
    { lat: 48.0390505, lng: 18.8149259 },
    { lat: 48.0390281, lng: 18.8136304 },
    { lat: 48.0390923, lng: 18.813007 },
    { lat: 48.0392573, lng: 18.8122486 },
    { lat: 48.0394071, lng: 18.8119872 },
    { lat: 48.0397959, lng: 18.8116631 },
    { lat: 48.0400084, lng: 18.8113742 },
    { lat: 48.0405711, lng: 18.8100906 },
    { lat: 48.040761, lng: 18.8098259 },
    { lat: 48.0409118, lng: 18.8096478 },
    { lat: 48.0412999, lng: 18.8093717 },
    { lat: 48.0423849, lng: 18.8089936 },
    { lat: 48.0431706, lng: 18.808792 },
    { lat: 48.0434514, lng: 18.8084316 },
    { lat: 48.0435073, lng: 18.8081431 },
    { lat: 48.0434619, lng: 18.807776 },
    { lat: 48.0432586, lng: 18.8073629 },
    { lat: 48.04317, lng: 18.8069487 },
    { lat: 48.0432364, lng: 18.8063901 },
    { lat: 48.0439441, lng: 18.8050177 },
    { lat: 48.0445357, lng: 18.8041798 },
    { lat: 48.0452048, lng: 18.8030964 },
    { lat: 48.0453731, lng: 18.8027615 },
    { lat: 48.0457131, lng: 18.8015384 },
    { lat: 48.0458091, lng: 18.8013252 },
    { lat: 48.0462047, lng: 18.8009815 },
    { lat: 48.0467511, lng: 18.8008217 },
    { lat: 48.0480232, lng: 18.8008833 },
    { lat: 48.0484232, lng: 18.8007703 },
    { lat: 48.0495148, lng: 18.8006873 },
    { lat: 48.0497597, lng: 18.7994122 },
    { lat: 48.04986, lng: 18.7976868 },
    { lat: 48.050049, lng: 18.7960393 },
    { lat: 48.0504858, lng: 18.7938125 },
    { lat: 48.0506933, lng: 18.7925146 },
    { lat: 48.0509406, lng: 18.7915804 },
    { lat: 48.0509584, lng: 18.7910482 },
    { lat: 48.0508837, lng: 18.7902752 },
    { lat: 48.0508966, lng: 18.7899033 },
    { lat: 48.0509907, lng: 18.7893395 },
    { lat: 48.051363, lng: 18.7876838 },
    { lat: 48.051676, lng: 18.7858648 },
    { lat: 48.0518875, lng: 18.7851113 },
    { lat: 48.0527453, lng: 18.7834055 },
    { lat: 48.0529105, lng: 18.7829701 },
    { lat: 48.0531122, lng: 18.7818822 },
    { lat: 48.0537028, lng: 18.7821314 },
    { lat: 48.0540115, lng: 18.7778352 },
    { lat: 48.0545202, lng: 18.7776689 },
    { lat: 48.0548626, lng: 18.7773214 },
    { lat: 48.0556856, lng: 18.7768921 },
    { lat: 48.0578779, lng: 18.7759148 },
    { lat: 48.059632, lng: 18.7753509 },
    { lat: 48.0604274, lng: 18.7750175 },
    { lat: 48.0612772, lng: 18.7747502 },
    { lat: 48.0622553, lng: 18.7746302 },
    { lat: 48.0626399, lng: 18.7746937 },
    { lat: 48.0636379, lng: 18.7745249 },
    { lat: 48.0650598, lng: 18.774138 },
    { lat: 48.0652573, lng: 18.7725729 },
    { lat: 48.0650528, lng: 18.7696599 },
    { lat: 48.0666278, lng: 18.7667487 },
    { lat: 48.0678147, lng: 18.7652725 },
    { lat: 48.0690985, lng: 18.7641162 },
    { lat: 48.0708561, lng: 18.7626445 },
    { lat: 48.071951, lng: 18.7634019 },
    { lat: 48.0720548, lng: 18.7634654 },
    { lat: 48.0719074, lng: 18.7632406 },
    { lat: 48.0709085, lng: 18.761786 },
    { lat: 48.0700939, lng: 18.7611203 },
    { lat: 48.0694099, lng: 18.7605826 },
    { lat: 48.0684578, lng: 18.7595419 },
    { lat: 48.0678035, lng: 18.7595782 },
    { lat: 48.0665098, lng: 18.7593868 },
    { lat: 48.0650223, lng: 18.7594691 },
    { lat: 48.0645463, lng: 18.7577117 },
    { lat: 48.0639503, lng: 18.7560545 },
    { lat: 48.0635299, lng: 18.7552755 },
    { lat: 48.0632795, lng: 18.7542793 },
    { lat: 48.0629026, lng: 18.753199 },
    { lat: 48.0623525, lng: 18.7503308 },
    { lat: 48.061614, lng: 18.748685 },
    { lat: 48.0611738, lng: 18.7471759 },
    { lat: 48.0610702, lng: 18.7465903 },
    { lat: 48.0608785, lng: 18.7461703 },
    { lat: 48.0608365, lng: 18.7455301 },
    { lat: 48.0609315, lng: 18.7454501 },
    { lat: 48.06089, lng: 18.7453267 },
    { lat: 48.060506, lng: 18.7449168 },
    { lat: 48.0604703, lng: 18.7447124 },
    { lat: 48.060085, lng: 18.7446144 },
    { lat: 48.0599702, lng: 18.7446414 },
    { lat: 48.0594764, lng: 18.7452148 },
    { lat: 48.0595201, lng: 18.7455678 },
    { lat: 48.0587398, lng: 18.7456306 },
    { lat: 48.0575003, lng: 18.7461184 },
    { lat: 48.0569003, lng: 18.7459906 },
    { lat: 48.0560253, lng: 18.7459497 },
    { lat: 48.0556346, lng: 18.7457081 },
    { lat: 48.0550772, lng: 18.7451529 },
    { lat: 48.0548678, lng: 18.745034 },
    { lat: 48.0541912, lng: 18.7452684 },
    { lat: 48.0530698, lng: 18.7445036 },
    { lat: 48.0523457, lng: 18.7438258 },
    { lat: 48.0518305, lng: 18.7431532 },
    { lat: 48.0513148, lng: 18.7433781 },
    { lat: 48.0502279, lng: 18.7443997 },
    { lat: 48.0493588, lng: 18.743354 },
    { lat: 48.0486945, lng: 18.7437742 },
    { lat: 48.0474016, lng: 18.7426471 },
    { lat: 48.0469279, lng: 18.7410416 },
    { lat: 48.0460407, lng: 18.7414738 },
    { lat: 48.0452418, lng: 18.7419487 },
    { lat: 48.0418178, lng: 18.7432784 },
    { lat: 48.0413108, lng: 18.743202 },
    { lat: 48.0393767, lng: 18.7451484 },
    { lat: 48.0391533, lng: 18.7452088 },
    { lat: 48.0364456, lng: 18.7460108 },
    { lat: 48.0348582, lng: 18.7459812 },
    { lat: 48.0330176, lng: 18.7453461 },
    { lat: 48.0300308, lng: 18.7455709 },
    { lat: 48.0281543, lng: 18.7439248 },
    { lat: 48.0278961, lng: 18.745678 },
    { lat: 48.0273749, lng: 18.7475948 },
    { lat: 48.0271932, lng: 18.7500857 },
    { lat: 48.0274287, lng: 18.7511376 },
    { lat: 48.0272344, lng: 18.7526502 },
    { lat: 48.0269031, lng: 18.7536284 },
    { lat: 48.0269378, lng: 18.7552717 },
    { lat: 48.027046, lng: 18.7564137 },
    { lat: 48.0269362, lng: 18.7587093 },
    { lat: 48.0275979, lng: 18.7634388 },
    { lat: 48.0276156, lng: 18.7652123 },
    { lat: 48.0274458, lng: 18.7667678 },
    { lat: 48.027201, lng: 18.7678234 },
    { lat: 48.0269351, lng: 18.768761 },
    { lat: 48.0259731, lng: 18.7717055 },
    { lat: 48.026575, lng: 18.774329 },
    { lat: 48.0271041, lng: 18.7757331 },
    { lat: 48.0277923, lng: 18.7769863 },
    { lat: 48.0286164, lng: 18.7792275 },
    { lat: 48.0292946, lng: 18.7848016 },
    { lat: 48.0294871, lng: 18.7885754 },
    { lat: 48.0296329, lng: 18.7898135 },
    { lat: 48.0295899, lng: 18.7907874 },
    { lat: 48.0296186, lng: 18.7913738 },
    { lat: 48.0302043, lng: 18.79319 },
    { lat: 48.031717, lng: 18.7925135 },
    { lat: 48.0320968, lng: 18.7922767 },
    { lat: 48.0325671, lng: 18.7950008 },
    { lat: 48.0336399, lng: 18.7947955 },
    { lat: 48.0337658, lng: 18.7970191 },
    { lat: 48.0337608, lng: 18.7988825 },
    { lat: 48.0336118, lng: 18.8013928 },
    { lat: 48.0336491, lng: 18.8027579 },
    { lat: 48.0359285, lng: 18.8028276 },
    { lat: 48.0360909, lng: 18.8045124 },
    { lat: 48.0363918, lng: 18.8066785 },
    { lat: 48.0362126, lng: 18.8074054 },
    { lat: 48.0353289, lng: 18.8098481 },
    { lat: 48.0355005, lng: 18.8114488 },
    { lat: 48.0358217, lng: 18.8129215 },
    { lat: 48.0364048, lng: 18.8160583 },
    { lat: 48.0366999, lng: 18.8174634 },
    { lat: 48.0367466, lng: 18.8175108 },
    { lat: 48.0368498, lng: 18.8176079 },
  ],
  DolnáSeč: [
    { lat: 48.1827938, lng: 18.6006405 },
    { lat: 48.1828414, lng: 18.6006209 },
    { lat: 48.1836142, lng: 18.6010182 },
    { lat: 48.1838985, lng: 18.6013292 },
    { lat: 48.1841998, lng: 18.6014575 },
    { lat: 48.1846364, lng: 18.6012501 },
    { lat: 48.1851284, lng: 18.6008204 },
    { lat: 48.1851051, lng: 18.600743 },
    { lat: 48.1851523, lng: 18.6004304 },
    { lat: 48.185083, lng: 18.6001522 },
    { lat: 48.1851198, lng: 18.59973 },
    { lat: 48.1852836, lng: 18.5990338 },
    { lat: 48.1855184, lng: 18.5983333 },
    { lat: 48.1858737, lng: 18.597923 },
    { lat: 48.1858415, lng: 18.59787 },
    { lat: 48.1910399, lng: 18.588716 },
    { lat: 48.1941782, lng: 18.5869447 },
    { lat: 48.1951547, lng: 18.5868984 },
    { lat: 48.1957139, lng: 18.5866872 },
    { lat: 48.1968649, lng: 18.5859977 },
    { lat: 48.1985496, lng: 18.5842532 },
    { lat: 48.2007939, lng: 18.5825173 },
    { lat: 48.2032111, lng: 18.5792802 },
    { lat: 48.201695, lng: 18.5733663 },
    { lat: 48.1995031, lng: 18.5710148 },
    { lat: 48.2026267, lng: 18.5685473 },
    { lat: 48.2028141, lng: 18.5684681 },
    { lat: 48.2027978, lng: 18.5684389 },
    { lat: 48.1964988, lng: 18.5678411 },
    { lat: 48.1960149, lng: 18.5671584 },
    { lat: 48.1958036, lng: 18.5667014 },
    { lat: 48.1941312, lng: 18.5638493 },
    { lat: 48.1931108, lng: 18.5618478 },
    { lat: 48.1924653, lng: 18.5601768 },
    { lat: 48.1921628, lng: 18.5591881 },
    { lat: 48.1916071, lng: 18.5566891 },
    { lat: 48.1909251, lng: 18.5525506 },
    { lat: 48.1905223, lng: 18.5511363 },
    { lat: 48.1887542, lng: 18.5429685 },
    { lat: 48.1899118, lng: 18.536975 },
    { lat: 48.1830426, lng: 18.533465 },
    { lat: 48.1826636, lng: 18.5342438 },
    { lat: 48.1850803, lng: 18.537565 },
    { lat: 48.1851719, lng: 18.5382437 },
    { lat: 48.1851294, lng: 18.5384341 },
    { lat: 48.1847561, lng: 18.5387035 },
    { lat: 48.1837392, lng: 18.5378328 },
    { lat: 48.1818014, lng: 18.535771 },
    { lat: 48.1816005, lng: 18.5361567 },
    { lat: 48.1814675, lng: 18.5361794 },
    { lat: 48.180713, lng: 18.5366301 },
    { lat: 48.1798826, lng: 18.5366692 },
    { lat: 48.1791017, lng: 18.5366234 },
    { lat: 48.1779479, lng: 18.5361344 },
    { lat: 48.1770148, lng: 18.535948 },
    { lat: 48.1767275, lng: 18.5359329 },
    { lat: 48.1762369, lng: 18.5363471 },
    { lat: 48.1758098, lng: 18.5365581 },
    { lat: 48.175272, lng: 18.5374231 },
    { lat: 48.1752126, lng: 18.5383914 },
    { lat: 48.1752719, lng: 18.5390152 },
    { lat: 48.1754792, lng: 18.5396675 },
    { lat: 48.1758188, lng: 18.5403018 },
    { lat: 48.1768615, lng: 18.5412707 },
    { lat: 48.1783397, lng: 18.5424666 },
    { lat: 48.1788063, lng: 18.5426633 },
    { lat: 48.1800535, lng: 18.5426348 },
    { lat: 48.1806891, lng: 18.5429944 },
    { lat: 48.1807083, lng: 18.5443867 },
    { lat: 48.1805497, lng: 18.545339 },
    { lat: 48.1788199, lng: 18.5462722 },
    { lat: 48.1772544, lng: 18.5460906 },
    { lat: 48.1764915, lng: 18.5456336 },
    { lat: 48.1755613, lng: 18.5456583 },
    { lat: 48.1750914, lng: 18.5459162 },
    { lat: 48.1747509, lng: 18.5461631 },
    { lat: 48.1745825, lng: 18.546669 },
    { lat: 48.1746909, lng: 18.548024 },
    { lat: 48.1751508, lng: 18.5491471 },
    { lat: 48.177166, lng: 18.5494834 },
    { lat: 48.1780716, lng: 18.5493175 },
    { lat: 48.1797774, lng: 18.5487472 },
    { lat: 48.1802973, lng: 18.5505889 },
    { lat: 48.1804806, lng: 18.5523824 },
    { lat: 48.1804179, lng: 18.5532758 },
    { lat: 48.1801357, lng: 18.5535975 },
    { lat: 48.1795652, lng: 18.5539316 },
    { lat: 48.1780406, lng: 18.554517 },
    { lat: 48.177286, lng: 18.5546992 },
    { lat: 48.1767985, lng: 18.5546552 },
    { lat: 48.1761249, lng: 18.5559018 },
    { lat: 48.1763828, lng: 18.5565734 },
    { lat: 48.1765795, lng: 18.5568704 },
    { lat: 48.1771081, lng: 18.5571695 },
    { lat: 48.1771956, lng: 18.5571835 },
    { lat: 48.1774494, lng: 18.5570357 },
    { lat: 48.1782392, lng: 18.5571005 },
    { lat: 48.1787337, lng: 18.5569857 },
    { lat: 48.1792236, lng: 18.5572202 },
    { lat: 48.1792785, lng: 18.5576217 },
    { lat: 48.1792346, lng: 18.5582208 },
    { lat: 48.1790482, lng: 18.5587689 },
    { lat: 48.1783284, lng: 18.5596889 },
    { lat: 48.1779417, lng: 18.559981 },
    { lat: 48.1772676, lng: 18.5602783 },
    { lat: 48.1768539, lng: 18.5602821 },
    { lat: 48.1764129, lng: 18.5601578 },
    { lat: 48.1761436, lng: 18.5598771 },
    { lat: 48.1756889, lng: 18.5587858 },
    { lat: 48.1755388, lng: 18.5582518 },
    { lat: 48.1755468, lng: 18.5572677 },
    { lat: 48.1755269, lng: 18.5569475 },
    { lat: 48.175462, lng: 18.55673 },
    { lat: 48.1755398, lng: 18.5561164 },
    { lat: 48.175473, lng: 18.555843 },
    { lat: 48.1752711, lng: 18.5553741 },
    { lat: 48.1751384, lng: 18.5551738 },
    { lat: 48.1749572, lng: 18.5550596 },
    { lat: 48.1747942, lng: 18.5550274 },
    { lat: 48.1746024, lng: 18.554905 },
    { lat: 48.1741671, lng: 18.5549493 },
    { lat: 48.1738022, lng: 18.5547959 },
    { lat: 48.1734114, lng: 18.5549065 },
    { lat: 48.1726295, lng: 18.5538587 },
    { lat: 48.1721213, lng: 18.554812 },
    { lat: 48.1718354, lng: 18.556073 },
    { lat: 48.1713602, lng: 18.557177 },
    { lat: 48.1711732, lng: 18.5575728 },
    { lat: 48.1709888, lng: 18.5578082 },
    { lat: 48.1708126, lng: 18.5581503 },
    { lat: 48.1705245, lng: 18.5589419 },
    { lat: 48.1701668, lng: 18.5593537 },
    { lat: 48.1701002, lng: 18.5598457 },
    { lat: 48.1702527, lng: 18.5602817 },
    { lat: 48.1705104, lng: 18.560678 },
    { lat: 48.1710837, lng: 18.5610751 },
    { lat: 48.1719651, lng: 18.56135 },
    { lat: 48.1721552, lng: 18.5616478 },
    { lat: 48.1722746, lng: 18.5624445 },
    { lat: 48.172262, lng: 18.5631186 },
    { lat: 48.172168, lng: 18.5638093 },
    { lat: 48.1722149, lng: 18.5642332 },
    { lat: 48.1723387, lng: 18.5647449 },
    { lat: 48.1722577, lng: 18.5653019 },
    { lat: 48.1720339, lng: 18.5658665 },
    { lat: 48.1715609, lng: 18.5663956 },
    { lat: 48.171275, lng: 18.5668801 },
    { lat: 48.1710374, lng: 18.5679337 },
    { lat: 48.1710503, lng: 18.5683162 },
    { lat: 48.1711817, lng: 18.5688199 },
    { lat: 48.171264, lng: 18.5690657 },
    { lat: 48.1715104, lng: 18.5695036 },
    { lat: 48.1715579, lng: 18.5697519 },
    { lat: 48.1715401, lng: 18.5701474 },
    { lat: 48.1712423, lng: 18.5707475 },
    { lat: 48.1709065, lng: 18.5712053 },
    { lat: 48.1704772, lng: 18.5716445 },
    { lat: 48.1705473, lng: 18.5717281 },
    { lat: 48.1712848, lng: 18.5725261 },
    { lat: 48.1713157, lng: 18.5726806 },
    { lat: 48.1726491, lng: 18.5742859 },
    { lat: 48.1737321, lng: 18.575707 },
    { lat: 48.1757714, lng: 18.5788996 },
    { lat: 48.1768849, lng: 18.5802837 },
    { lat: 48.1787019, lng: 18.5821973 },
    { lat: 48.1826788, lng: 18.5865827 },
    { lat: 48.1831136, lng: 18.5869608 },
    { lat: 48.1844335, lng: 18.5875847 },
    { lat: 48.1842997, lng: 18.588249 },
    { lat: 48.1843194, lng: 18.5886958 },
    { lat: 48.1845035, lng: 18.5890497 },
    { lat: 48.1847035, lng: 18.5892624 },
    { lat: 48.1847258, lng: 18.5894028 },
    { lat: 48.1846771, lng: 18.5895622 },
    { lat: 48.1842684, lng: 18.5903183 },
    { lat: 48.1839575, lng: 18.5905932 },
    { lat: 48.1836907, lng: 18.5906897 },
    { lat: 48.1829391, lng: 18.5906378 },
    { lat: 48.1824836, lng: 18.5910366 },
    { lat: 48.1819629, lng: 18.5917241 },
    { lat: 48.1817741, lng: 18.5925416 },
    { lat: 48.181502, lng: 18.5934016 },
    { lat: 48.181491, lng: 18.5936549 },
    { lat: 48.1815544, lng: 18.5940543 },
    { lat: 48.1818096, lng: 18.5943896 },
    { lat: 48.1818072, lng: 18.5947572 },
    { lat: 48.1814895, lng: 18.5954279 },
    { lat: 48.1814552, lng: 18.5956219 },
    { lat: 48.1814968, lng: 18.5963441 },
    { lat: 48.1815415, lng: 18.5965013 },
    { lat: 48.1816884, lng: 18.5966282 },
    { lat: 48.1821664, lng: 18.5967081 },
    { lat: 48.1822472, lng: 18.5968898 },
    { lat: 48.1822969, lng: 18.5971756 },
    { lat: 48.1822857, lng: 18.5977768 },
    { lat: 48.1823416, lng: 18.5980764 },
    { lat: 48.1828961, lng: 18.59989 },
    { lat: 48.182892, lng: 18.6001669 },
    { lat: 48.1828013, lng: 18.6004671 },
    { lat: 48.1827938, lng: 18.6006405 },
  ],
  Jesenské: [
    { lat: 48.1762421, lng: 18.4038266 },
    { lat: 48.1757896, lng: 18.4034449 },
    { lat: 48.1753356, lng: 18.4042574 },
    { lat: 48.1754043, lng: 18.4043986 },
    { lat: 48.1749552, lng: 18.4050168 },
    { lat: 48.1747802, lng: 18.4050742 },
    { lat: 48.17432, lng: 18.4046563 },
    { lat: 48.1741411, lng: 18.405044 },
    { lat: 48.1716235, lng: 18.4032296 },
    { lat: 48.1709413, lng: 18.4023574 },
    { lat: 48.1706523, lng: 18.4018951 },
    { lat: 48.1707813, lng: 18.401659 },
    { lat: 48.1690674, lng: 18.3994327 },
    { lat: 48.1682284, lng: 18.3984018 },
    { lat: 48.1680345, lng: 18.3982418 },
    { lat: 48.1677624, lng: 18.3977799 },
    { lat: 48.1675523, lng: 18.397296 },
    { lat: 48.1671574, lng: 18.3959728 },
    { lat: 48.1668958, lng: 18.3949573 },
    { lat: 48.1667319, lng: 18.3936901 },
    { lat: 48.1666366, lng: 18.3933413 },
    { lat: 48.1660936, lng: 18.3919764 },
    { lat: 48.1659267, lng: 18.3913359 },
    { lat: 48.1659299, lng: 18.3911545 },
    { lat: 48.1659222, lng: 18.3910764 },
    { lat: 48.1658898, lng: 18.3911052 },
    { lat: 48.165288, lng: 18.3913546 },
    { lat: 48.1650456, lng: 18.3915594 },
    { lat: 48.1644778, lng: 18.3918911 },
    { lat: 48.163982, lng: 18.3925653 },
    { lat: 48.1635984, lng: 18.3929815 },
    { lat: 48.1625586, lng: 18.3935494 },
    { lat: 48.1620577, lng: 18.3937313 },
    { lat: 48.1613216, lng: 18.3954421 },
    { lat: 48.1601845, lng: 18.3984448 },
    { lat: 48.1589746, lng: 18.400995 },
    { lat: 48.1586376, lng: 18.4018441 },
    { lat: 48.1585747, lng: 18.4020658 },
    { lat: 48.158976, lng: 18.4026212 },
    { lat: 48.1592332, lng: 18.4031412 },
    { lat: 48.1596804, lng: 18.4042509 },
    { lat: 48.1602567, lng: 18.4059886 },
    { lat: 48.1605757, lng: 18.4066057 },
    { lat: 48.1606089, lng: 18.4065754 },
    { lat: 48.1606506, lng: 18.4066345 },
    { lat: 48.1592216, lng: 18.4080227 },
    { lat: 48.1573513, lng: 18.4092899 },
    { lat: 48.1568119, lng: 18.4095889 },
    { lat: 48.1563834, lng: 18.409713 },
    { lat: 48.1553099, lng: 18.4115072 },
    { lat: 48.1515922, lng: 18.4152692 },
    { lat: 48.1537524, lng: 18.4223861 },
    { lat: 48.1549748, lng: 18.4215766 },
    { lat: 48.1566637, lng: 18.425554 },
    { lat: 48.1562934, lng: 18.426252 },
    { lat: 48.155688, lng: 18.427061 },
    { lat: 48.155179, lng: 18.427599 },
    { lat: 48.155277, lng: 18.4276786 },
    { lat: 48.1573989, lng: 18.4294042 },
    { lat: 48.1580347, lng: 18.4298598 },
    { lat: 48.1585243, lng: 18.4293762 },
    { lat: 48.1593574, lng: 18.4290435 },
    { lat: 48.1598492, lng: 18.4286999 },
    { lat: 48.1604921, lng: 18.428979 },
    { lat: 48.1607151, lng: 18.4289924 },
    { lat: 48.1613753, lng: 18.4286135 },
    { lat: 48.161522, lng: 18.4285937 },
    { lat: 48.1618488, lng: 18.4287676 },
    { lat: 48.1626306, lng: 18.4294724 },
    { lat: 48.1642944, lng: 18.4311784 },
    { lat: 48.1654504, lng: 18.430225 },
    { lat: 48.1671928, lng: 18.426073 },
    { lat: 48.1675365, lng: 18.4254037 },
    { lat: 48.1671003, lng: 18.4250276 },
    { lat: 48.1667417, lng: 18.4245906 },
    { lat: 48.1670238, lng: 18.4238463 },
    { lat: 48.1671426, lng: 18.4229323 },
    { lat: 48.1669942, lng: 18.4194647 },
    { lat: 48.1671715, lng: 18.418325 },
    { lat: 48.1675927, lng: 18.4168638 },
    { lat: 48.1677438, lng: 18.4170452 },
    { lat: 48.1685917, lng: 18.4152325 },
    { lat: 48.169056, lng: 18.4137979 },
    { lat: 48.1696623, lng: 18.4125622 },
    { lat: 48.17057, lng: 18.4126559 },
    { lat: 48.172153, lng: 18.4129379 },
    { lat: 48.1761418, lng: 18.404069 },
    { lat: 48.1762421, lng: 18.4038266 },
  ],
  Lula: [
    { lat: 48.177021, lng: 18.346233 },
    { lat: 48.176959, lng: 18.346224 },
    { lat: 48.176907, lng: 18.34621 },
    { lat: 48.176769, lng: 18.346166 },
    { lat: 48.176314, lng: 18.346417 },
    { lat: 48.175966, lng: 18.346618 },
    { lat: 48.175822, lng: 18.346698 },
    { lat: 48.175513, lng: 18.346928 },
    { lat: 48.175462, lng: 18.346973 },
    { lat: 48.175413, lng: 18.346986 },
    { lat: 48.175243, lng: 18.347007 },
    { lat: 48.175125, lng: 18.347027 },
    { lat: 48.174568, lng: 18.347189 },
    { lat: 48.174139, lng: 18.347325 },
    { lat: 48.173844, lng: 18.347425 },
    { lat: 48.173642, lng: 18.347456 },
    { lat: 48.173519, lng: 18.347514 },
    { lat: 48.173112, lng: 18.347488 },
    { lat: 48.172812, lng: 18.347507 },
    { lat: 48.172528, lng: 18.347532 },
    { lat: 48.172386, lng: 18.347502 },
    { lat: 48.172138, lng: 18.347437 },
    { lat: 48.172036, lng: 18.347447 },
    { lat: 48.171833, lng: 18.347477 },
    { lat: 48.171734, lng: 18.347506 },
    { lat: 48.171566, lng: 18.347539 },
    { lat: 48.171489, lng: 18.347545 },
    { lat: 48.171346, lng: 18.34757 },
    { lat: 48.171264, lng: 18.347591 },
    { lat: 48.171097, lng: 18.347651 },
    { lat: 48.170912, lng: 18.347744 },
    { lat: 48.170848, lng: 18.347749 },
    { lat: 48.170711, lng: 18.347749 },
    { lat: 48.170479, lng: 18.347791 },
    { lat: 48.1703, lng: 18.347755 },
    { lat: 48.17001, lng: 18.347717 },
    { lat: 48.169723, lng: 18.347757 },
    { lat: 48.169559, lng: 18.347701 },
    { lat: 48.169376, lng: 18.347691 },
    { lat: 48.169228, lng: 18.347707 },
    { lat: 48.169063, lng: 18.347706 },
    { lat: 48.168916, lng: 18.347723 },
    { lat: 48.168772, lng: 18.347752 },
    { lat: 48.168605, lng: 18.347817 },
    { lat: 48.168458, lng: 18.347872 },
    { lat: 48.168266, lng: 18.347967 },
    { lat: 48.168162, lng: 18.347983 },
    { lat: 48.16798, lng: 18.347991 },
    { lat: 48.167812, lng: 18.347991 },
    { lat: 48.167665, lng: 18.347986 },
    { lat: 48.167498, lng: 18.348006 },
    { lat: 48.167417, lng: 18.348026 },
    { lat: 48.167334, lng: 18.348084 },
    { lat: 48.167176, lng: 18.348179 },
    { lat: 48.167065, lng: 18.348255 },
    { lat: 48.166976, lng: 18.348336 },
    { lat: 48.166929, lng: 18.348355 },
    { lat: 48.166839, lng: 18.348402 },
    { lat: 48.166619, lng: 18.348483 },
    { lat: 48.166308, lng: 18.348561 },
    { lat: 48.165788, lng: 18.34869 },
    { lat: 48.165522, lng: 18.348783 },
    { lat: 48.165032, lng: 18.34887 },
    { lat: 48.164352, lng: 18.348968 },
    { lat: 48.163873, lng: 18.348962 },
    { lat: 48.163596, lng: 18.348984 },
    { lat: 48.163047, lng: 18.349093 },
    { lat: 48.162894, lng: 18.349202 },
    { lat: 48.162623, lng: 18.349579 },
    { lat: 48.162369, lng: 18.349917 },
    { lat: 48.162306, lng: 18.349929 },
    { lat: 48.162035, lng: 18.350347 },
    { lat: 48.161818, lng: 18.350712 },
    { lat: 48.161667, lng: 18.351016 },
    { lat: 48.161609, lng: 18.351156 },
    { lat: 48.16153, lng: 18.351474 },
    { lat: 48.161446, lng: 18.351839 },
    { lat: 48.161103, lng: 18.353293 },
    { lat: 48.160948, lng: 18.353845 },
    { lat: 48.160938, lng: 18.353893 },
    { lat: 48.160724, lng: 18.354425 },
    { lat: 48.160544, lng: 18.35469 },
    { lat: 48.159848, lng: 18.3555 },
    { lat: 48.159666, lng: 18.355755 },
    { lat: 48.159534, lng: 18.356056 },
    { lat: 48.159246, lng: 18.356624 },
    { lat: 48.158931, lng: 18.357247 },
    { lat: 48.158691, lng: 18.357788 },
    { lat: 48.158394, lng: 18.358827 },
    { lat: 48.158293, lng: 18.359143 },
    { lat: 48.157991, lng: 18.360169 },
    { lat: 48.157596, lng: 18.361843 },
    { lat: 48.157401, lng: 18.362682 },
    { lat: 48.157203, lng: 18.363458 },
    { lat: 48.157198, lng: 18.36348 },
    { lat: 48.157172, lng: 18.363573 },
    { lat: 48.157126, lng: 18.363941 },
    { lat: 48.157046, lng: 18.364712 },
    { lat: 48.156889, lng: 18.365818 },
    { lat: 48.156791, lng: 18.36684 },
    { lat: 48.15678, lng: 18.366971 },
    { lat: 48.156779, lng: 18.366979 },
    { lat: 48.156771, lng: 18.367153 },
    { lat: 48.156765, lng: 18.367286 },
    { lat: 48.156748, lng: 18.367609 },
    { lat: 48.156731, lng: 18.367753 },
    { lat: 48.156725, lng: 18.367781 },
    { lat: 48.156629, lng: 18.36818 },
    { lat: 48.156423, lng: 18.369465 },
    { lat: 48.156594, lng: 18.369981 },
    { lat: 48.156614, lng: 18.370522 },
    { lat: 48.156429, lng: 18.371376 },
    { lat: 48.156332, lng: 18.371773 },
    { lat: 48.156283, lng: 18.37223 },
    { lat: 48.156248, lng: 18.373182 },
    { lat: 48.156331, lng: 18.373633 },
    { lat: 48.156452, lng: 18.374095 },
    { lat: 48.156463, lng: 18.374573 },
    { lat: 48.156451, lng: 18.374806 },
    { lat: 48.1564161, lng: 18.3757917 },
    { lat: 48.1561725, lng: 18.3766313 },
    { lat: 48.1559604, lng: 18.3780675 },
    { lat: 48.155776, lng: 18.3797554 },
    { lat: 48.1555046, lng: 18.3809592 },
    { lat: 48.1555207, lng: 18.3813652 },
    { lat: 48.1556562, lng: 18.3821657 },
    { lat: 48.1560977, lng: 18.3829484 },
    { lat: 48.1566034, lng: 18.3840693 },
    { lat: 48.1569326, lng: 18.3850637 },
    { lat: 48.1574391, lng: 18.3860432 },
    { lat: 48.1576446, lng: 18.3863837 },
    { lat: 48.1578155, lng: 18.386413 },
    { lat: 48.1582974, lng: 18.3863179 },
    { lat: 48.1591587, lng: 18.3856873 },
    { lat: 48.1594053, lng: 18.3851806 },
    { lat: 48.1595257, lng: 18.3861322 },
    { lat: 48.1596922, lng: 18.3866638 },
    { lat: 48.1602151, lng: 18.3873505 },
    { lat: 48.1603574, lng: 18.3877142 },
    { lat: 48.1604774, lng: 18.388239 },
    { lat: 48.1607668, lng: 18.3889102 },
    { lat: 48.1617159, lng: 18.3900714 },
    { lat: 48.1625123, lng: 18.3907311 },
    { lat: 48.1631869, lng: 18.3911906 },
    { lat: 48.1644902, lng: 18.3914534 },
    { lat: 48.1652714, lng: 18.3912998 },
    { lat: 48.165288, lng: 18.3913546 },
    { lat: 48.1658898, lng: 18.3911052 },
    { lat: 48.1659222, lng: 18.3910764 },
    { lat: 48.1659346, lng: 18.3909564 },
    { lat: 48.1667439, lng: 18.3904267 },
    { lat: 48.1667658, lng: 18.3902741 },
    { lat: 48.1669281, lng: 18.3899914 },
    { lat: 48.1670333, lng: 18.389958 },
    { lat: 48.1671084, lng: 18.3896676 },
    { lat: 48.1674165, lng: 18.3894513 },
    { lat: 48.167735, lng: 18.3884975 },
    { lat: 48.1679489, lng: 18.3872322 },
    { lat: 48.168197, lng: 18.3866093 },
    { lat: 48.168814, lng: 18.3864664 },
    { lat: 48.1696847, lng: 18.3859638 },
    { lat: 48.1702001, lng: 18.3854198 },
    { lat: 48.1713227, lng: 18.383946 },
    { lat: 48.1718423, lng: 18.3829735 },
    { lat: 48.1731315, lng: 18.3809324 },
    { lat: 48.1736285, lng: 18.3800485 },
    { lat: 48.1738593, lng: 18.3797394 },
    { lat: 48.174132, lng: 18.3792151 },
    { lat: 48.175003, lng: 18.3770581 },
    { lat: 48.1752537, lng: 18.3766259 },
    { lat: 48.176456, lng: 18.3750635 },
    { lat: 48.1776726, lng: 18.3731751 },
    { lat: 48.1783993, lng: 18.3723013 },
    { lat: 48.1804029, lng: 18.3703053 },
    { lat: 48.1821564, lng: 18.3688768 },
    { lat: 48.1824782, lng: 18.3685016 },
    { lat: 48.1839172, lng: 18.3658613 },
    { lat: 48.1871192, lng: 18.360963 },
    { lat: 48.1883769, lng: 18.3586521 },
    { lat: 48.1888055, lng: 18.3576544 },
    { lat: 48.1912806, lng: 18.3509677 },
    { lat: 48.191611, lng: 18.349182 },
    { lat: 48.191599, lng: 18.349076 },
    { lat: 48.191217, lng: 18.348432 },
    { lat: 48.190708, lng: 18.34758 },
    { lat: 48.190222, lng: 18.34677 },
    { lat: 48.190149, lng: 18.346663 },
    { lat: 48.190146, lng: 18.346668 },
    { lat: 48.190026, lng: 18.346455 },
    { lat: 48.189771, lng: 18.346095 },
    { lat: 48.189643, lng: 18.345917 },
    { lat: 48.189557, lng: 18.345798 },
    { lat: 48.189346, lng: 18.34553 },
    { lat: 48.189165, lng: 18.345309 },
    { lat: 48.18913, lng: 18.345264 },
    { lat: 48.188981, lng: 18.345099 },
    { lat: 48.188822, lng: 18.344923 },
    { lat: 48.188707, lng: 18.344826 },
    { lat: 48.188561, lng: 18.344674 },
    { lat: 48.188359, lng: 18.34449 },
    { lat: 48.188163, lng: 18.344312 },
    { lat: 48.187938, lng: 18.344107 },
    { lat: 48.187788, lng: 18.343971 },
    { lat: 48.187554, lng: 18.343753 },
    { lat: 48.18736, lng: 18.343575 },
    { lat: 48.187136, lng: 18.343375 },
    { lat: 48.187132, lng: 18.343394 },
    { lat: 48.186648, lng: 18.343384 },
    { lat: 48.186261, lng: 18.343424 },
    { lat: 48.186079, lng: 18.34344 },
    { lat: 48.185433, lng: 18.343505 },
    { lat: 48.184708, lng: 18.343585 },
    { lat: 48.184552, lng: 18.343569 },
    { lat: 48.18402, lng: 18.343515 },
    { lat: 48.183752, lng: 18.343496 },
    { lat: 48.183248, lng: 18.343466 },
    { lat: 48.183051, lng: 18.34345 },
    { lat: 48.182584, lng: 18.343426 },
    { lat: 48.182567, lng: 18.343428 },
    { lat: 48.182038, lng: 18.343485 },
    { lat: 48.181493, lng: 18.343548 },
    { lat: 48.18114, lng: 18.343461 },
    { lat: 48.180778, lng: 18.343362 },
    { lat: 48.180648, lng: 18.34333 },
    { lat: 48.18041, lng: 18.343486 },
    { lat: 48.180133, lng: 18.343731 },
    { lat: 48.179686, lng: 18.344126 },
    { lat: 48.179321, lng: 18.344433 },
    { lat: 48.179142, lng: 18.344576 },
    { lat: 48.178992, lng: 18.344699 },
    { lat: 48.178834, lng: 18.344831 },
    { lat: 48.178688, lng: 18.34495 },
    { lat: 48.178344, lng: 18.345187 },
    { lat: 48.17821, lng: 18.34531 },
    { lat: 48.178117, lng: 18.345417 },
    { lat: 48.177913, lng: 18.345586 },
    { lat: 48.177787, lng: 18.34569 },
    { lat: 48.177665, lng: 18.345792 },
    { lat: 48.177559, lng: 18.345882 },
    { lat: 48.177464, lng: 18.345961 },
    { lat: 48.177261, lng: 18.346135 },
    { lat: 48.177127, lng: 18.346243 },
    { lat: 48.177037, lng: 18.346279 },
    { lat: 48.177021, lng: 18.346233 },
  ],
  TekovskýHrádok: [
    { lat: 48.1626381, lng: 18.5177988 },
    { lat: 48.1614795, lng: 18.5205068 },
    { lat: 48.1607982, lng: 18.521652 },
    { lat: 48.1608194, lng: 18.5221588 },
    { lat: 48.1593686, lng: 18.5222988 },
    { lat: 48.1603977, lng: 18.5237937 },
    { lat: 48.1614967, lng: 18.5252261 },
    { lat: 48.1599437, lng: 18.5276828 },
    { lat: 48.1584979, lng: 18.5302831 },
    { lat: 48.1585654, lng: 18.5304436 },
    { lat: 48.155134, lng: 18.5365354 },
    { lat: 48.1550195, lng: 18.5367311 },
    { lat: 48.1538787, lng: 18.5392986 },
    { lat: 48.1514353, lng: 18.5435558 },
    { lat: 48.1507864, lng: 18.5451684 },
    { lat: 48.1504896, lng: 18.5460841 },
    { lat: 48.1502205, lng: 18.5471596 },
    { lat: 48.1492453, lng: 18.5492162 },
    { lat: 48.1491229, lng: 18.5496972 },
    { lat: 48.1491109, lng: 18.5500727 },
    { lat: 48.1495745, lng: 18.5506487 },
    { lat: 48.1502975, lng: 18.5514457 },
    { lat: 48.1537251, lng: 18.5558445 },
    { lat: 48.156581, lng: 18.5599464 },
    { lat: 48.1606814, lng: 18.5654299 },
    { lat: 48.1606198, lng: 18.5657067 },
    { lat: 48.1621454, lng: 18.5674818 },
    { lat: 48.1623569, lng: 18.5680286 },
    { lat: 48.1627962, lng: 18.5694954 },
    { lat: 48.1629421, lng: 18.5703064 },
    { lat: 48.1640531, lng: 18.5724226 },
    { lat: 48.166131, lng: 18.5733491 },
    { lat: 48.1664365, lng: 18.5734891 },
    { lat: 48.1663381, lng: 18.5730146 },
    { lat: 48.1662519, lng: 18.5726955 },
    { lat: 48.1661201, lng: 18.5724908 },
    { lat: 48.1644601, lng: 18.5713272 },
    { lat: 48.1636733, lng: 18.5705149 },
    { lat: 48.1633839, lng: 18.5699876 },
    { lat: 48.1631858, lng: 18.5690742 },
    { lat: 48.1631521, lng: 18.5681106 },
    { lat: 48.1634148, lng: 18.5666057 },
    { lat: 48.1638485, lng: 18.5663678 },
    { lat: 48.1637622, lng: 18.5667507 },
    { lat: 48.1641576, lng: 18.5665125 },
    { lat: 48.1649361, lng: 18.5665365 },
    { lat: 48.1651487, lng: 18.5664447 },
    { lat: 48.1653511, lng: 18.5661863 },
    { lat: 48.1657125, lng: 18.5654547 },
    { lat: 48.1658372, lng: 18.5648713 },
    { lat: 48.1657865, lng: 18.5641341 },
    { lat: 48.1658153, lng: 18.563874 },
    { lat: 48.1659079, lng: 18.5635885 },
    { lat: 48.1665919, lng: 18.5638851 },
    { lat: 48.1666868, lng: 18.5642787 },
    { lat: 48.1666937, lng: 18.5643828 },
    { lat: 48.1665121, lng: 18.5649203 },
    { lat: 48.1661881, lng: 18.5654466 },
    { lat: 48.1659425, lng: 18.5664586 },
    { lat: 48.1658945, lng: 18.5668804 },
    { lat: 48.1660166, lng: 18.5672569 },
    { lat: 48.1665455, lng: 18.5681764 },
    { lat: 48.1669609, lng: 18.5698286 },
    { lat: 48.1675021, lng: 18.5707279 },
    { lat: 48.168167, lng: 18.5713732 },
    { lat: 48.1683477, lng: 18.5714186 },
    { lat: 48.168781, lng: 18.571239 },
    { lat: 48.1697864, lng: 18.5716762 },
    { lat: 48.1702202, lng: 18.5717287 },
    { lat: 48.1704772, lng: 18.5716445 },
    { lat: 48.1709065, lng: 18.5712053 },
    { lat: 48.1712423, lng: 18.5707475 },
    { lat: 48.1715401, lng: 18.5701474 },
    { lat: 48.1715579, lng: 18.5697519 },
    { lat: 48.1715104, lng: 18.5695036 },
    { lat: 48.171264, lng: 18.5690657 },
    { lat: 48.1711817, lng: 18.5688199 },
    { lat: 48.1710503, lng: 18.5683162 },
    { lat: 48.1710374, lng: 18.5679337 },
    { lat: 48.171275, lng: 18.5668801 },
    { lat: 48.1715609, lng: 18.5663956 },
    { lat: 48.1720339, lng: 18.5658665 },
    { lat: 48.1722577, lng: 18.5653019 },
    { lat: 48.1723387, lng: 18.5647449 },
    { lat: 48.1722149, lng: 18.5642332 },
    { lat: 48.172168, lng: 18.5638093 },
    { lat: 48.172262, lng: 18.5631186 },
    { lat: 48.1722746, lng: 18.5624445 },
    { lat: 48.1721552, lng: 18.5616478 },
    { lat: 48.1719651, lng: 18.56135 },
    { lat: 48.1710837, lng: 18.5610751 },
    { lat: 48.1705104, lng: 18.560678 },
    { lat: 48.1702527, lng: 18.5602817 },
    { lat: 48.1701002, lng: 18.5598457 },
    { lat: 48.1701668, lng: 18.5593537 },
    { lat: 48.1705245, lng: 18.5589419 },
    { lat: 48.1708126, lng: 18.5581503 },
    { lat: 48.1709888, lng: 18.5578082 },
    { lat: 48.1711732, lng: 18.5575728 },
    { lat: 48.1713602, lng: 18.557177 },
    { lat: 48.1718354, lng: 18.556073 },
    { lat: 48.1721213, lng: 18.554812 },
    { lat: 48.1726295, lng: 18.5538587 },
    { lat: 48.1734114, lng: 18.5549065 },
    { lat: 48.1738022, lng: 18.5547959 },
    { lat: 48.1741671, lng: 18.5549493 },
    { lat: 48.1746024, lng: 18.554905 },
    { lat: 48.1747942, lng: 18.5550274 },
    { lat: 48.1749572, lng: 18.5550596 },
    { lat: 48.1751384, lng: 18.5551738 },
    { lat: 48.1752711, lng: 18.5553741 },
    { lat: 48.175473, lng: 18.555843 },
    { lat: 48.1755398, lng: 18.5561164 },
    { lat: 48.175462, lng: 18.55673 },
    { lat: 48.1755269, lng: 18.5569475 },
    { lat: 48.1755468, lng: 18.5572677 },
    { lat: 48.1755388, lng: 18.5582518 },
    { lat: 48.1756889, lng: 18.5587858 },
    { lat: 48.1761436, lng: 18.5598771 },
    { lat: 48.1764129, lng: 18.5601578 },
    { lat: 48.1768539, lng: 18.5602821 },
    { lat: 48.1772676, lng: 18.5602783 },
    { lat: 48.1779417, lng: 18.559981 },
    { lat: 48.1783284, lng: 18.5596889 },
    { lat: 48.1790482, lng: 18.5587689 },
    { lat: 48.1792346, lng: 18.5582208 },
    { lat: 48.1792785, lng: 18.5576217 },
    { lat: 48.1792236, lng: 18.5572202 },
    { lat: 48.1787337, lng: 18.5569857 },
    { lat: 48.1782392, lng: 18.5571005 },
    { lat: 48.1774494, lng: 18.5570357 },
    { lat: 48.1771956, lng: 18.5571835 },
    { lat: 48.1771081, lng: 18.5571695 },
    { lat: 48.1765795, lng: 18.5568704 },
    { lat: 48.1763828, lng: 18.5565734 },
    { lat: 48.1761249, lng: 18.5559018 },
    { lat: 48.1767985, lng: 18.5546552 },
    { lat: 48.177286, lng: 18.5546992 },
    { lat: 48.1780406, lng: 18.554517 },
    { lat: 48.1795652, lng: 18.5539316 },
    { lat: 48.1801357, lng: 18.5535975 },
    { lat: 48.1804179, lng: 18.5532758 },
    { lat: 48.1804806, lng: 18.5523824 },
    { lat: 48.1802973, lng: 18.5505889 },
    { lat: 48.1797774, lng: 18.5487472 },
    { lat: 48.1780716, lng: 18.5493175 },
    { lat: 48.177166, lng: 18.5494834 },
    { lat: 48.1751508, lng: 18.5491471 },
    { lat: 48.1746909, lng: 18.548024 },
    { lat: 48.1745825, lng: 18.546669 },
    { lat: 48.1747509, lng: 18.5461631 },
    { lat: 48.1750914, lng: 18.5459162 },
    { lat: 48.1755613, lng: 18.5456583 },
    { lat: 48.1764915, lng: 18.5456336 },
    { lat: 48.1772544, lng: 18.5460906 },
    { lat: 48.1788199, lng: 18.5462722 },
    { lat: 48.1805497, lng: 18.545339 },
    { lat: 48.1807083, lng: 18.5443867 },
    { lat: 48.1806891, lng: 18.5429944 },
    { lat: 48.1800535, lng: 18.5426348 },
    { lat: 48.1788063, lng: 18.5426633 },
    { lat: 48.1783397, lng: 18.5424666 },
    { lat: 48.1768615, lng: 18.5412707 },
    { lat: 48.1758188, lng: 18.5403018 },
    { lat: 48.1754792, lng: 18.5396675 },
    { lat: 48.1752719, lng: 18.5390152 },
    { lat: 48.1752126, lng: 18.5383914 },
    { lat: 48.175272, lng: 18.5374231 },
    { lat: 48.1758098, lng: 18.5365581 },
    { lat: 48.1762369, lng: 18.5363471 },
    { lat: 48.1760915, lng: 18.5360802 },
    { lat: 48.1757375, lng: 18.5354704 },
    { lat: 48.174987, lng: 18.5330916 },
    { lat: 48.1722147, lng: 18.5297073 },
    { lat: 48.1693191, lng: 18.5263401 },
    { lat: 48.1651801, lng: 18.5209272 },
    { lat: 48.1626381, lng: 18.5177988 },
  ],
  Devičany: [
    { lat: 48.309451, lng: 18.7204976 },
    { lat: 48.3094773, lng: 18.7205695 },
    { lat: 48.3110248, lng: 18.7239979 },
    { lat: 48.3113849, lng: 18.7235802 },
    { lat: 48.3117523, lng: 18.7242951 },
    { lat: 48.3117785, lng: 18.7242537 },
    { lat: 48.3145018, lng: 18.7285186 },
    { lat: 48.3194763, lng: 18.7364122 },
    { lat: 48.3199872, lng: 18.7361363 },
    { lat: 48.3228364, lng: 18.7339841 },
    { lat: 48.3239653, lng: 18.7335343 },
    { lat: 48.3266845, lng: 18.7316539 },
    { lat: 48.3267098, lng: 18.7317069 },
    { lat: 48.3266632, lng: 18.7317189 },
    { lat: 48.3267232, lng: 18.7320593 },
    { lat: 48.3268258, lng: 18.7323348 },
    { lat: 48.3271633, lng: 18.7329121 },
    { lat: 48.3274853, lng: 18.7331369 },
    { lat: 48.3273953, lng: 18.733417 },
    { lat: 48.3274829, lng: 18.7336425 },
    { lat: 48.3277946, lng: 18.7329719 },
    { lat: 48.3283243, lng: 18.7328574 },
    { lat: 48.3285094, lng: 18.7328813 },
    { lat: 48.3292995, lng: 18.7325581 },
    { lat: 48.3295412, lng: 18.7322024 },
    { lat: 48.3302566, lng: 18.7320206 },
    { lat: 48.3310993, lng: 18.7313444 },
    { lat: 48.3314122, lng: 18.731185 },
    { lat: 48.3319645, lng: 18.7307758 },
    { lat: 48.3327128, lng: 18.730388 },
    { lat: 48.3331391, lng: 18.7298542 },
    { lat: 48.3337183, lng: 18.7293623 },
    { lat: 48.3340438, lng: 18.7289288 },
    { lat: 48.3344435, lng: 18.7285963 },
    { lat: 48.334604, lng: 18.7283534 },
    { lat: 48.3346961, lng: 18.7286 },
    { lat: 48.3352364, lng: 18.7278607 },
    { lat: 48.3359908, lng: 18.7277288 },
    { lat: 48.3363143, lng: 18.7277666 },
    { lat: 48.3367283, lng: 18.7276641 },
    { lat: 48.3369273, lng: 18.7276963 },
    { lat: 48.3373405, lng: 18.727662 },
    { lat: 48.3373278, lng: 18.7273611 },
    { lat: 48.3379573, lng: 18.7271067 },
    { lat: 48.3387705, lng: 18.7266238 },
    { lat: 48.3392094, lng: 18.7264372 },
    { lat: 48.3393396, lng: 18.7261933 },
    { lat: 48.3391546, lng: 18.7254098 },
    { lat: 48.3390037, lng: 18.7248189 },
    { lat: 48.3387446, lng: 18.724147 },
    { lat: 48.3380825, lng: 18.7248056 },
    { lat: 48.3377152, lng: 18.7238168 },
    { lat: 48.3375447, lng: 18.7234728 },
    { lat: 48.3376914, lng: 18.7233485 },
    { lat: 48.3375103, lng: 18.7225752 },
    { lat: 48.3378919, lng: 18.7222238 },
    { lat: 48.3379023, lng: 18.7221439 },
    { lat: 48.3373124, lng: 18.7219722 },
    { lat: 48.3365135, lng: 18.7215233 },
    { lat: 48.3365398, lng: 18.7207038 },
    { lat: 48.3360241, lng: 18.7196406 },
    { lat: 48.3355292, lng: 18.7183173 },
    { lat: 48.3351971, lng: 18.7178599 },
    { lat: 48.3349052, lng: 18.7172307 },
    { lat: 48.3350285, lng: 18.7169869 },
    { lat: 48.3353015, lng: 18.7167343 },
    { lat: 48.3361587, lng: 18.7156768 },
    { lat: 48.3368076, lng: 18.7143132 },
    { lat: 48.3370605, lng: 18.7136001 },
    { lat: 48.3377374, lng: 18.7120012 },
    { lat: 48.3379922, lng: 18.7111734 },
    { lat: 48.3381026, lng: 18.7106653 },
    { lat: 48.3381472, lng: 18.71017 },
    { lat: 48.3380697, lng: 18.7090812 },
    { lat: 48.3379582, lng: 18.7088088 },
    { lat: 48.3376643, lng: 18.7084178 },
    { lat: 48.337506, lng: 18.7080742 },
    { lat: 48.338163, lng: 18.707808 },
    { lat: 48.3384771, lng: 18.707756 },
    { lat: 48.3389153, lng: 18.7074722 },
    { lat: 48.3401117, lng: 18.7063686 },
    { lat: 48.340548, lng: 18.7059053 },
    { lat: 48.340665, lng: 18.7056884 },
    { lat: 48.341754, lng: 18.7049426 },
    { lat: 48.3420504, lng: 18.7045986 },
    { lat: 48.342429, lng: 18.7038103 },
    { lat: 48.3425656, lng: 18.7033291 },
    { lat: 48.3432145, lng: 18.7005984 },
    { lat: 48.3433595, lng: 18.69941 },
    { lat: 48.3434866, lng: 18.6990866 },
    { lat: 48.3436012, lng: 18.698401 },
    { lat: 48.343766, lng: 18.6980273 },
    { lat: 48.3438576, lng: 18.6976334 },
    { lat: 48.3440968, lng: 18.6971082 },
    { lat: 48.3444181, lng: 18.6970004 },
    { lat: 48.3446526, lng: 18.6967452 },
    { lat: 48.3448395, lng: 18.6963994 },
    { lat: 48.3451079, lng: 18.6962312 },
    { lat: 48.345336, lng: 18.6959009 },
    { lat: 48.3453867, lng: 18.6956477 },
    { lat: 48.3453927, lng: 18.6947266 },
    { lat: 48.3454771, lng: 18.6942602 },
    { lat: 48.3457932, lng: 18.6935523 },
    { lat: 48.3463186, lng: 18.6927494 },
    { lat: 48.3465708, lng: 18.6924853 },
    { lat: 48.3474494, lng: 18.6918571 },
    { lat: 48.3476061, lng: 18.6917684 },
    { lat: 48.3483131, lng: 18.6918717 },
    { lat: 48.3485538, lng: 18.6916274 },
    { lat: 48.3493276, lng: 18.6913146 },
    { lat: 48.3493434, lng: 18.6896746 },
    { lat: 48.3493697, lng: 18.6895115 },
    { lat: 48.3495403, lng: 18.6891811 },
    { lat: 48.3496634, lng: 18.68914 },
    { lat: 48.3499289, lng: 18.6894019 },
    { lat: 48.3502369, lng: 18.689342 },
    { lat: 48.3506341, lng: 18.689032 },
    { lat: 48.3509188, lng: 18.6886597 },
    { lat: 48.3510611, lng: 18.6882855 },
    { lat: 48.3515809, lng: 18.6878203 },
    { lat: 48.3516433, lng: 18.6876394 },
    { lat: 48.3517631, lng: 18.687559 },
    { lat: 48.3520092, lng: 18.6872261 },
    { lat: 48.3523257, lng: 18.6864202 },
    { lat: 48.3526418, lng: 18.6862774 },
    { lat: 48.3528394, lng: 18.6857791 },
    { lat: 48.3530643, lng: 18.685575 },
    { lat: 48.3535045, lng: 18.684763 },
    { lat: 48.353582, lng: 18.6840611 },
    { lat: 48.3528813, lng: 18.6829217 },
    { lat: 48.353216, lng: 18.680647 },
    { lat: 48.359352, lng: 18.679665 },
    { lat: 48.359529, lng: 18.679434 },
    { lat: 48.360131, lng: 18.678365 },
    { lat: 48.359528, lng: 18.678039 },
    { lat: 48.358747, lng: 18.677623 },
    { lat: 48.358246, lng: 18.677348 },
    { lat: 48.357405, lng: 18.676917 },
    { lat: 48.357261, lng: 18.676838 },
    { lat: 48.356644, lng: 18.676497 },
    { lat: 48.356434, lng: 18.676364 },
    { lat: 48.355072, lng: 18.675544 },
    { lat: 48.354876, lng: 18.674268 },
    { lat: 48.354045, lng: 18.671073 },
    { lat: 48.353548, lng: 18.668645 },
    { lat: 48.353373, lng: 18.667848 },
    { lat: 48.353247, lng: 18.667267 },
    { lat: 48.353234, lng: 18.667216 },
    { lat: 48.353063, lng: 18.666445 },
    { lat: 48.35302, lng: 18.666266 },
    { lat: 48.351519, lng: 18.663872 },
    { lat: 48.35139, lng: 18.663568 },
    { lat: 48.35086, lng: 18.662298 },
    { lat: 48.349803, lng: 18.660403 },
    { lat: 48.347988, lng: 18.658022 },
    { lat: 48.346811, lng: 18.655931 },
    { lat: 48.345803, lng: 18.653993 },
    { lat: 48.3455401, lng: 18.6534859 },
    { lat: 48.345181, lng: 18.652793 },
    { lat: 48.345307, lng: 18.652774 },
    { lat: 48.3452761, lng: 18.6525108 },
    { lat: 48.3441089, lng: 18.6496314 },
    { lat: 48.342352, lng: 18.6458567 },
    { lat: 48.3413074, lng: 18.6437881 },
    { lat: 48.3396518, lng: 18.6407005 },
    { lat: 48.3384723, lng: 18.6390551 },
    { lat: 48.3357471, lng: 18.6345504 },
    { lat: 48.3338369, lng: 18.6352664 },
    { lat: 48.33312, lng: 18.6351743 },
    { lat: 48.3311263, lng: 18.6362212 },
    { lat: 48.3294655, lng: 18.6372761 },
    { lat: 48.327629, lng: 18.63894 },
    { lat: 48.3266191, lng: 18.6408331 },
    { lat: 48.3258841, lng: 18.6417784 },
    { lat: 48.325092, lng: 18.6424599 },
    { lat: 48.3248967, lng: 18.6425403 },
    { lat: 48.3239588, lng: 18.6426323 },
    { lat: 48.3239462, lng: 18.6428408 },
    { lat: 48.3238453, lng: 18.6438954 },
    { lat: 48.3236326, lng: 18.6442806 },
    { lat: 48.3234074, lng: 18.6449098 },
    { lat: 48.3232288, lng: 18.6449864 },
    { lat: 48.323153, lng: 18.6451923 },
    { lat: 48.3231555, lng: 18.6453224 },
    { lat: 48.3231898, lng: 18.6454139 },
    { lat: 48.3236393, lng: 18.6455723 },
    { lat: 48.3236989, lng: 18.6457961 },
    { lat: 48.3241887, lng: 18.6465309 },
    { lat: 48.3244802, lng: 18.6467077 },
    { lat: 48.3245044, lng: 18.6470511 },
    { lat: 48.3247637, lng: 18.6477 },
    { lat: 48.3250932, lng: 18.6477213 },
    { lat: 48.3251219, lng: 18.6477706 },
    { lat: 48.3250708, lng: 18.6484367 },
    { lat: 48.3251512, lng: 18.6485025 },
    { lat: 48.3251732, lng: 18.6485994 },
    { lat: 48.3250671, lng: 18.6491382 },
    { lat: 48.3250791, lng: 18.6493597 },
    { lat: 48.3251809, lng: 18.6495944 },
    { lat: 48.3252652, lng: 18.6501147 },
    { lat: 48.3253994, lng: 18.6502575 },
    { lat: 48.3257317, lng: 18.6502313 },
    { lat: 48.325854, lng: 18.6503434 },
    { lat: 48.3260642, lng: 18.6508126 },
    { lat: 48.3262902, lng: 18.6511329 },
    { lat: 48.3263765, lng: 18.6514977 },
    { lat: 48.3267641, lng: 18.6518264 },
    { lat: 48.3268057, lng: 18.6521764 },
    { lat: 48.3270654, lng: 18.6525174 },
    { lat: 48.3271829, lng: 18.6532312 },
    { lat: 48.3273141, lng: 18.6532797 },
    { lat: 48.3272182, lng: 18.6538021 },
    { lat: 48.3270987, lng: 18.654014 },
    { lat: 48.3266367, lng: 18.6554222 },
    { lat: 48.3258992, lng: 18.6565118 },
    { lat: 48.3252146, lng: 18.6571406 },
    { lat: 48.324533, lng: 18.657259 },
    { lat: 48.3237023, lng: 18.6572074 },
    { lat: 48.323278, lng: 18.6569519 },
    { lat: 48.3208964, lng: 18.6559543 },
    { lat: 48.3190549, lng: 18.6552967 },
    { lat: 48.3186618, lng: 18.6550109 },
    { lat: 48.3184229, lng: 18.6549754 },
    { lat: 48.3180942, lng: 18.6550295 },
    { lat: 48.317844, lng: 18.6551144 },
    { lat: 48.3171474, lng: 18.655521 },
    { lat: 48.3170044, lng: 18.6557534 },
    { lat: 48.3167152, lng: 18.656477 },
    { lat: 48.3159251, lng: 18.6572613 },
    { lat: 48.3157355, lng: 18.6572786 },
    { lat: 48.3145075, lng: 18.6581429 },
    { lat: 48.3133908, lng: 18.6587052 },
    { lat: 48.3130747, lng: 18.6589745 },
    { lat: 48.3123697, lng: 18.6593923 },
    { lat: 48.3119039, lng: 18.6595384 },
    { lat: 48.3116139, lng: 18.6598276 },
    { lat: 48.3106907, lng: 18.6604227 },
    { lat: 48.3100706, lng: 18.6602403 },
    { lat: 48.3086382, lng: 18.6606806 },
    { lat: 48.3083057, lng: 18.6611634 },
    { lat: 48.3081733, lng: 18.6623068 },
    { lat: 48.3082782, lng: 18.6628726 },
    { lat: 48.3083108, lng: 18.6639985 },
    { lat: 48.3079179, lng: 18.6644755 },
    { lat: 48.3074854, lng: 18.6644676 },
    { lat: 48.306621, lng: 18.6647073 },
    { lat: 48.3061384, lng: 18.6649184 },
    { lat: 48.3060083, lng: 18.6650776 },
    { lat: 48.3054927, lng: 18.6665238 },
    { lat: 48.3055519, lng: 18.6667402 },
    { lat: 48.3035724, lng: 18.6693585 },
    { lat: 48.3033906, lng: 18.6692268 },
    { lat: 48.3019407, lng: 18.6709127 },
    { lat: 48.2974242, lng: 18.6757897 },
    { lat: 48.2953074, lng: 18.6811416 },
    { lat: 48.2948082, lng: 18.6790534 },
    { lat: 48.2941312, lng: 18.6775175 },
    { lat: 48.2937095, lng: 18.6772646 },
    { lat: 48.2933029, lng: 18.6768586 },
    { lat: 48.2921391, lng: 18.67558 },
    { lat: 48.2912872, lng: 18.6744221 },
    { lat: 48.2906608, lng: 18.6737445 },
    { lat: 48.2895731, lng: 18.673757 },
    { lat: 48.2893836, lng: 18.6731693 },
    { lat: 48.2891228, lng: 18.6730797 },
    { lat: 48.2889625, lng: 18.6728689 },
    { lat: 48.2859417, lng: 18.671458 },
    { lat: 48.2854946, lng: 18.6713446 },
    { lat: 48.2854555, lng: 18.671435 },
    { lat: 48.2852802, lng: 18.6714278 },
    { lat: 48.2838005, lng: 18.6710522 },
    { lat: 48.2824603, lng: 18.6709444 },
    { lat: 48.2824277, lng: 18.6710053 },
    { lat: 48.2982771, lng: 18.6924847 },
    { lat: 48.2989815, lng: 18.691571 },
    { lat: 48.2999422, lng: 18.6929762 },
    { lat: 48.2974083, lng: 18.6980739 },
    { lat: 48.2957451, lng: 18.701155 },
    { lat: 48.2958244, lng: 18.7020841 },
    { lat: 48.2960045, lng: 18.7034076 },
    { lat: 48.2960812, lng: 18.7034501 },
    { lat: 48.2963087, lng: 18.7035063 },
    { lat: 48.2966577, lng: 18.7032398 },
    { lat: 48.297134, lng: 18.7029934 },
    { lat: 48.297326, lng: 18.7030613 },
    { lat: 48.2975246, lng: 18.702917 },
    { lat: 48.2976409, lng: 18.7029405 },
    { lat: 48.2978705, lng: 18.7027213 },
    { lat: 48.2980499, lng: 18.7026831 },
    { lat: 48.2980944, lng: 18.7027316 },
    { lat: 48.298168, lng: 18.7026544 },
    { lat: 48.298381, lng: 18.702713 },
    { lat: 48.2985693, lng: 18.7025437 },
    { lat: 48.2991403, lng: 18.7014322 },
    { lat: 48.299426, lng: 18.7010833 },
    { lat: 48.2993993, lng: 18.7010033 },
    { lat: 48.2995712, lng: 18.7006574 },
    { lat: 48.3001974, lng: 18.6998832 },
    { lat: 48.3002631, lng: 18.7000556 },
    { lat: 48.3012461, lng: 18.6996681 },
    { lat: 48.301526, lng: 18.6989993 },
    { lat: 48.3019118, lng: 18.6992079 },
    { lat: 48.3021682, lng: 18.7009641 },
    { lat: 48.3018758, lng: 18.7010645 },
    { lat: 48.3017919, lng: 18.7030983 },
    { lat: 48.3018699, lng: 18.7051023 },
    { lat: 48.30191, lng: 18.7056834 },
    { lat: 48.3020058, lng: 18.7062507 },
    { lat: 48.3025137, lng: 18.7080922 },
    { lat: 48.3034982, lng: 18.7104939 },
    { lat: 48.3043969, lng: 18.7132556 },
    { lat: 48.3048412, lng: 18.7144538 },
    { lat: 48.3051383, lng: 18.715201 },
    { lat: 48.3058952, lng: 18.7167039 },
    { lat: 48.3075401, lng: 18.7193934 },
    { lat: 48.3078012, lng: 18.719977 },
    { lat: 48.3086398, lng: 18.7214657 },
    { lat: 48.3089781, lng: 18.7209369 },
    { lat: 48.309451, lng: 18.7204976 },
  ],
  Želiezovce: [
    { lat: 48.0317211, lng: 18.7110335 },
    { lat: 48.0320784, lng: 18.7107388 },
    { lat: 48.0328467, lng: 18.7104049 },
    { lat: 48.033297, lng: 18.7100316 },
    { lat: 48.0336554, lng: 18.7099024 },
    { lat: 48.0336966, lng: 18.7098284 },
    { lat: 48.0336174, lng: 18.7094506 },
    { lat: 48.0336253, lng: 18.7092189 },
    { lat: 48.0338723, lng: 18.7089652 },
    { lat: 48.0339161, lng: 18.7087951 },
    { lat: 48.0339508, lng: 18.708267 },
    { lat: 48.0339317, lng: 18.7077227 },
    { lat: 48.0340399, lng: 18.7071763 },
    { lat: 48.03416, lng: 18.7070189 },
    { lat: 48.0344541, lng: 18.7068667 },
    { lat: 48.0348815, lng: 18.7068043 },
    { lat: 48.0349575, lng: 18.7067262 },
    { lat: 48.0350025, lng: 18.7065351 },
    { lat: 48.0350165, lng: 18.7059175 },
    { lat: 48.0352178, lng: 18.7056302 },
    { lat: 48.0357074, lng: 18.7053384 },
    { lat: 48.0362187, lng: 18.7051997 },
    { lat: 48.0369277, lng: 18.7046469 },
    { lat: 48.0370098, lng: 18.7044986 },
    { lat: 48.0373378, lng: 18.704349 },
    { lat: 48.0375137, lng: 18.7045965 },
    { lat: 48.0378863, lng: 18.7046468 },
    { lat: 48.038098, lng: 18.7050408 },
    { lat: 48.0384095, lng: 18.7052516 },
    { lat: 48.0385805, lng: 18.7052016 },
    { lat: 48.038852, lng: 18.7047998 },
    { lat: 48.0391808, lng: 18.7040556 },
    { lat: 48.039382, lng: 18.7037933 },
    { lat: 48.0394958, lng: 18.7034358 },
    { lat: 48.0395446, lng: 18.7033972 },
    { lat: 48.0397829, lng: 18.7034039 },
    { lat: 48.0402371, lng: 18.7036484 },
    { lat: 48.0403678, lng: 18.7037927 },
    { lat: 48.0403686, lng: 18.7039978 },
    { lat: 48.0406509, lng: 18.7044648 },
    { lat: 48.0411125, lng: 18.7043422 },
    { lat: 48.0415452, lng: 18.7037925 },
    { lat: 48.0419536, lng: 18.7034367 },
    { lat: 48.0419975, lng: 18.7033081 },
    { lat: 48.0419727, lng: 18.7028479 },
    { lat: 48.0420682, lng: 18.7024841 },
    { lat: 48.0418472, lng: 18.7017944 },
    { lat: 48.0420026, lng: 18.7014762 },
    { lat: 48.0421454, lng: 18.7013851 },
    { lat: 48.0422607, lng: 18.7013713 },
    { lat: 48.0426523, lng: 18.7016254 },
    { lat: 48.0428258, lng: 18.7015374 },
    { lat: 48.0431182, lng: 18.7017935 },
    { lat: 48.043382, lng: 18.7022511 },
    { lat: 48.0436845, lng: 18.7026322 },
    { lat: 48.0439555, lng: 18.7034798 },
    { lat: 48.044194, lng: 18.7033614 },
    { lat: 48.0442581, lng: 18.7032339 },
    { lat: 48.0442828, lng: 18.7029391 },
    { lat: 48.0444045, lng: 18.702872 },
    { lat: 48.0445483, lng: 18.702588 },
    { lat: 48.0444994, lng: 18.7017638 },
    { lat: 48.0447889, lng: 18.7014638 },
    { lat: 48.0449356, lng: 18.7013846 },
    { lat: 48.0455918, lng: 18.7013786 },
    { lat: 48.0458235, lng: 18.7015151 },
    { lat: 48.0463187, lng: 18.7021234 },
    { lat: 48.0466916, lng: 18.7024288 },
    { lat: 48.0473548, lng: 18.7022627 },
    { lat: 48.0475517, lng: 18.7021499 },
    { lat: 48.0478234, lng: 18.7018143 },
    { lat: 48.0479609, lng: 18.701537 },
    { lat: 48.0480026, lng: 18.7013903 },
    { lat: 48.0480194, lng: 18.7007176 },
    { lat: 48.04795, lng: 18.7000347 },
    { lat: 48.0477431, lng: 18.6995456 },
    { lat: 48.0475774, lng: 18.6992944 },
    { lat: 48.0475659, lng: 18.6991868 },
    { lat: 48.0476126, lng: 18.6990931 },
    { lat: 48.0479757, lng: 18.6989016 },
    { lat: 48.0482939, lng: 18.6982139 },
    { lat: 48.0484607, lng: 18.6980638 },
    { lat: 48.0485884, lng: 18.6975427 },
    { lat: 48.0485638, lng: 18.6970677 },
    { lat: 48.0484793, lng: 18.6967761 },
    { lat: 48.0483522, lng: 18.6965527 },
    { lat: 48.0485502, lng: 18.6961223 },
    { lat: 48.0489681, lng: 18.695904 },
    { lat: 48.0491024, lng: 18.6959056 },
    { lat: 48.0492676, lng: 18.6961463 },
    { lat: 48.049346, lng: 18.6964305 },
    { lat: 48.0494348, lng: 18.6964861 },
    { lat: 48.0498115, lng: 18.6961918 },
    { lat: 48.0499727, lng: 18.6956881 },
    { lat: 48.0498756, lng: 18.6953713 },
    { lat: 48.0498976, lng: 18.6950916 },
    { lat: 48.050699, lng: 18.6951002 },
    { lat: 48.0509783, lng: 18.6949889 },
    { lat: 48.0511374, lng: 18.694784 },
    { lat: 48.0512484, lng: 18.6942043 },
    { lat: 48.0512461, lng: 18.6938894 },
    { lat: 48.0514472, lng: 18.6936432 },
    { lat: 48.0517176, lng: 18.693639 },
    { lat: 48.0519128, lng: 18.6937436 },
    { lat: 48.0522007, lng: 18.6941264 },
    { lat: 48.0524941, lng: 18.6943821 },
    { lat: 48.052574, lng: 18.6945273 },
    { lat: 48.0525859, lng: 18.6947046 },
    { lat: 48.0525564, lng: 18.6948276 },
    { lat: 48.0524735, lng: 18.6949429 },
    { lat: 48.0523434, lng: 18.6948309 },
    { lat: 48.0521884, lng: 18.694841 },
    { lat: 48.0521773, lng: 18.6949904 },
    { lat: 48.0522678, lng: 18.6953715 },
    { lat: 48.0521948, lng: 18.6957148 },
    { lat: 48.052261, lng: 18.6961891 },
    { lat: 48.0523742, lng: 18.6963021 },
    { lat: 48.0527023, lng: 18.6964041 },
    { lat: 48.0528191, lng: 18.6963968 },
    { lat: 48.053084, lng: 18.6959749 },
    { lat: 48.0533363, lng: 18.6957183 },
    { lat: 48.0537265, lng: 18.6954428 },
    { lat: 48.0539383, lng: 18.6950434 },
    { lat: 48.0542876, lng: 18.6946568 },
    { lat: 48.0543564, lng: 18.6941747 },
    { lat: 48.0544616, lng: 18.6938907 },
    { lat: 48.0545945, lng: 18.6936995 },
    { lat: 48.0547832, lng: 18.6936001 },
    { lat: 48.0549229, lng: 18.6937007 },
    { lat: 48.0550996, lng: 18.6941777 },
    { lat: 48.0552868, lng: 18.6944066 },
    { lat: 48.0554484, lng: 18.6944746 },
    { lat: 48.0555466, lng: 18.6946232 },
    { lat: 48.0555832, lng: 18.6947789 },
    { lat: 48.0555123, lng: 18.6951357 },
    { lat: 48.0555804, lng: 18.6953123 },
    { lat: 48.0556972, lng: 18.6954083 },
    { lat: 48.0558462, lng: 18.6953664 },
    { lat: 48.0559178, lng: 18.6952313 },
    { lat: 48.0559572, lng: 18.6948511 },
    { lat: 48.0560808, lng: 18.6946156 },
    { lat: 48.0563282, lng: 18.6944351 },
    { lat: 48.0565548, lng: 18.6944552 },
    { lat: 48.057011, lng: 18.6946533 },
    { lat: 48.0573252, lng: 18.6946198 },
    { lat: 48.0576701, lng: 18.6947227 },
    { lat: 48.0580657, lng: 18.6949901 },
    { lat: 48.0584162, lng: 18.6954864 },
    { lat: 48.0585516, lng: 18.6955294 },
    { lat: 48.0588224, lng: 18.695388 },
    { lat: 48.0590116, lng: 18.6950435 },
    { lat: 48.0592511, lng: 18.6949499 },
    { lat: 48.0593524, lng: 18.6949434 },
    { lat: 48.0595548, lng: 18.6950591 },
    { lat: 48.0598014, lng: 18.695051 },
    { lat: 48.0599746, lng: 18.6949594 },
    { lat: 48.0602004, lng: 18.6946048 },
    { lat: 48.0601303, lng: 18.6942071 },
    { lat: 48.0601487, lng: 18.6940459 },
    { lat: 48.0602317, lng: 18.6938261 },
    { lat: 48.0604606, lng: 18.6936584 },
    { lat: 48.0604911, lng: 18.6934689 },
    { lat: 48.0603532, lng: 18.6929838 },
    { lat: 48.0601627, lng: 18.6929012 },
    { lat: 48.0599514, lng: 18.6924971 },
    { lat: 48.0599632, lng: 18.6920896 },
    { lat: 48.0598679, lng: 18.6917858 },
    { lat: 48.0598679, lng: 18.6915975 },
    { lat: 48.0601656, lng: 18.6908243 },
    { lat: 48.0602838, lng: 18.6902933 },
    { lat: 48.0605203, lng: 18.690144 },
    { lat: 48.0606162, lng: 18.6901645 },
    { lat: 48.0607972, lng: 18.6903395 },
    { lat: 48.0611861, lng: 18.6902929 },
    { lat: 48.0614522, lng: 18.6905864 },
    { lat: 48.0616633, lng: 18.6905477 },
    { lat: 48.0619136, lng: 18.6903371 },
    { lat: 48.0619274, lng: 18.689967 },
    { lat: 48.0617923, lng: 18.6894689 },
    { lat: 48.0617945, lng: 18.689299 },
    { lat: 48.0619789, lng: 18.6890805 },
    { lat: 48.0620818, lng: 18.6891031 },
    { lat: 48.0621852, lng: 18.6892253 },
    { lat: 48.0622697, lng: 18.6894549 },
    { lat: 48.0624492, lng: 18.6897296 },
    { lat: 48.0626245, lng: 18.6898644 },
    { lat: 48.0627716, lng: 18.6903125 },
    { lat: 48.0629983, lng: 18.6904147 },
    { lat: 48.0630243, lng: 18.6904362 },
    { lat: 48.0631875, lng: 18.690429 },
    { lat: 48.0633074, lng: 18.69008 },
    { lat: 48.0633668, lng: 18.6900334 },
    { lat: 48.0640592, lng: 18.6900928 },
    { lat: 48.0642139, lng: 18.6900125 },
    { lat: 48.0642766, lng: 18.6899444 },
    { lat: 48.0643153, lng: 18.6897595 },
    { lat: 48.0645129, lng: 18.6894739 },
    { lat: 48.0645503, lng: 18.6891813 },
    { lat: 48.0646473, lng: 18.6889518 },
    { lat: 48.0649461, lng: 18.6886145 },
    { lat: 48.0652995, lng: 18.6886031 },
    { lat: 48.0656656, lng: 18.6884266 },
    { lat: 48.0658769, lng: 18.6884326 },
    { lat: 48.0661195, lng: 18.6883516 },
    { lat: 48.0663038, lng: 18.6883814 },
    { lat: 48.0667145, lng: 18.6886156 },
    { lat: 48.0669644, lng: 18.6889431 },
    { lat: 48.0670914, lng: 18.6876328 },
    { lat: 48.0670742, lng: 18.687358 },
    { lat: 48.0672353, lng: 18.6867248 },
    { lat: 48.0674022, lng: 18.6840718 },
    { lat: 48.0672183, lng: 18.6841757 },
    { lat: 48.0669296, lng: 18.6841113 },
    { lat: 48.0667222, lng: 18.6810608 },
    { lat: 48.0666764, lng: 18.6794813 },
    { lat: 48.0671329, lng: 18.6795848 },
    { lat: 48.0676362, lng: 18.6793166 },
    { lat: 48.0683211, lng: 18.6788425 },
    { lat: 48.0691131, lng: 18.6780326 },
    { lat: 48.0694455, lng: 18.6775451 },
    { lat: 48.0698404, lng: 18.676713 },
    { lat: 48.0699409, lng: 18.6763205 },
    { lat: 48.0700098, lng: 18.6757141 },
    { lat: 48.0704102, lng: 18.6746077 },
    { lat: 48.0704188, lng: 18.6735777 },
    { lat: 48.0699679, lng: 18.6725377 },
    { lat: 48.069658, lng: 18.6713345 },
    { lat: 48.0701433, lng: 18.6709647 },
    { lat: 48.070709, lng: 18.6701187 },
    { lat: 48.0709486, lng: 18.6696064 },
    { lat: 48.0711309, lng: 18.6697868 },
    { lat: 48.0711974, lng: 18.6701822 },
    { lat: 48.0713687, lng: 18.6705125 },
    { lat: 48.0715366, lng: 18.6713672 },
    { lat: 48.0717919, lng: 18.6723129 },
    { lat: 48.0720045, lng: 18.6729091 },
    { lat: 48.0724934, lng: 18.6735866 },
    { lat: 48.0732485, lng: 18.6741584 },
    { lat: 48.0736139, lng: 18.6742417 },
    { lat: 48.0740789, lng: 18.6741442 },
    { lat: 48.0745597, lng: 18.6738377 },
    { lat: 48.0747681, lng: 18.6734356 },
    { lat: 48.0749439, lng: 18.6727871 },
    { lat: 48.0750379, lng: 18.6721466 },
    { lat: 48.0750381, lng: 18.6715047 },
    { lat: 48.0749725, lng: 18.6707487 },
    { lat: 48.0751358, lng: 18.6698047 },
    { lat: 48.0712016, lng: 18.6660079 },
    { lat: 48.0700113, lng: 18.6656057 },
    { lat: 48.0700874, lng: 18.6651561 },
    { lat: 48.0705697, lng: 18.6653149 },
    { lat: 48.0740679, lng: 18.666954 },
    { lat: 48.0743357, lng: 18.6676708 },
    { lat: 48.0745702, lng: 18.6680862 },
    { lat: 48.0751211, lng: 18.66824 },
    { lat: 48.075618, lng: 18.6680831 },
    { lat: 48.0757201, lng: 18.667848 },
    { lat: 48.0759635, lng: 18.6676335 },
    { lat: 48.0763173, lng: 18.6668986 },
    { lat: 48.0768869, lng: 18.6654407 },
    { lat: 48.0769814, lng: 18.665082 },
    { lat: 48.0773098, lng: 18.6646029 },
    { lat: 48.0785064, lng: 18.6631227 },
    { lat: 48.0791372, lng: 18.6625226 },
    { lat: 48.0790167, lng: 18.6629424 },
    { lat: 48.0796673, lng: 18.6655671 },
    { lat: 48.0802323, lng: 18.6665257 },
    { lat: 48.0808917, lng: 18.6668282 },
    { lat: 48.081158, lng: 18.6667919 },
    { lat: 48.0818678, lng: 18.6662331 },
    { lat: 48.082157, lng: 18.666157 },
    { lat: 48.0824645, lng: 18.6657781 },
    { lat: 48.0828492, lng: 18.6643607 },
    { lat: 48.0829955, lng: 18.6641473 },
    { lat: 48.083711, lng: 18.66355 },
    { lat: 48.0844414, lng: 18.6662895 },
    { lat: 48.0846106, lng: 18.6667093 },
    { lat: 48.0848035, lng: 18.6669365 },
    { lat: 48.0850034, lng: 18.6670669 },
    { lat: 48.0852093, lng: 18.6671038 },
    { lat: 48.0856757, lng: 18.6670307 },
    { lat: 48.0861581, lng: 18.6668777 },
    { lat: 48.0862021, lng: 18.6659165 },
    { lat: 48.0868427, lng: 18.666295 },
    { lat: 48.0879953, lng: 18.666813 },
    { lat: 48.0888009, lng: 18.6658993 },
    { lat: 48.0893489, lng: 18.6654603 },
    { lat: 48.0897892, lng: 18.6652196 },
    { lat: 48.0899034, lng: 18.6651201 },
    { lat: 48.0906851, lng: 18.6644322 },
    { lat: 48.0912204, lng: 18.6637369 },
    { lat: 48.0916281, lng: 18.6630591 },
    { lat: 48.0917039, lng: 18.6607706 },
    { lat: 48.0914031, lng: 18.6605483 },
    { lat: 48.0905931, lng: 18.660876 },
    { lat: 48.0874577, lng: 18.6573569 },
    { lat: 48.0872008, lng: 18.6571572 },
    { lat: 48.0873675, lng: 18.6566951 },
    { lat: 48.0878134, lng: 18.6558475 },
    { lat: 48.0879082, lng: 18.6554346 },
    { lat: 48.087919, lng: 18.6546742 },
    { lat: 48.0882333, lng: 18.6539187 },
    { lat: 48.0882116, lng: 18.6537051 },
    { lat: 48.0879838, lng: 18.653122 },
    { lat: 48.0877917, lng: 18.6528873 },
    { lat: 48.0879048, lng: 18.6521843 },
    { lat: 48.0885963, lng: 18.6507751 },
    { lat: 48.088784, lng: 18.6504272 },
    { lat: 48.0891042, lng: 18.6501328 },
    { lat: 48.0893999, lng: 18.6499908 },
    { lat: 48.0901301, lng: 18.6494825 },
    { lat: 48.0904873, lng: 18.6494305 },
    { lat: 48.0908526, lng: 18.6494724 },
    { lat: 48.0912193, lng: 18.6497015 },
    { lat: 48.0912401, lng: 18.6495445 },
    { lat: 48.0910217, lng: 18.6490526 },
    { lat: 48.0899932, lng: 18.6473499 },
    { lat: 48.0891931, lng: 18.6455554 },
    { lat: 48.0896424, lng: 18.6453148 },
    { lat: 48.089648, lng: 18.6450838 },
    { lat: 48.088682, lng: 18.643206 },
    { lat: 48.0867439, lng: 18.6391061 },
    { lat: 48.0862742, lng: 18.6380397 },
    { lat: 48.0854631, lng: 18.635984 },
    { lat: 48.0847495, lng: 18.6339956 },
    { lat: 48.0841626, lng: 18.632831 },
    { lat: 48.0839616, lng: 18.6323298 },
    { lat: 48.0833203, lng: 18.6304738 },
    { lat: 48.0824266, lng: 18.6274375 },
    { lat: 48.0814342, lng: 18.6248337 },
    { lat: 48.0813939, lng: 18.6242753 },
    { lat: 48.0811029, lng: 18.6229942 },
    { lat: 48.0796115, lng: 18.618641 },
    { lat: 48.0786807, lng: 18.6163221 },
    { lat: 48.0778468, lng: 18.6139974 },
    { lat: 48.0770717, lng: 18.6121338 },
    { lat: 48.0747601, lng: 18.6060533 },
    { lat: 48.0739389, lng: 18.6036812 },
    { lat: 48.0716878, lng: 18.5991691 },
    { lat: 48.0709056, lng: 18.5978788 },
    { lat: 48.0700271, lng: 18.5960669 },
    { lat: 48.0695051, lng: 18.5952482 },
    { lat: 48.0694779, lng: 18.5952057 },
    { lat: 48.0694896, lng: 18.5949221 },
    { lat: 48.0693687, lng: 18.5942806 },
    { lat: 48.0694359, lng: 18.5940061 },
    { lat: 48.0695666, lng: 18.5938597 },
    { lat: 48.0698454, lng: 18.5937577 },
    { lat: 48.0700437, lng: 18.5935677 },
    { lat: 48.0701801, lng: 18.593403 },
    { lat: 48.0702761, lng: 18.5931367 },
    { lat: 48.0704338, lng: 18.5929683 },
    { lat: 48.0709314, lng: 18.5926421 },
    { lat: 48.0713432, lng: 18.5927037 },
    { lat: 48.0714572, lng: 18.5926221 },
    { lat: 48.0716057, lng: 18.5923687 },
    { lat: 48.0716308, lng: 18.5916311 },
    { lat: 48.0716733, lng: 18.5915441 },
    { lat: 48.0716961, lng: 18.5915088 },
    { lat: 48.0695651, lng: 18.5889294 },
    { lat: 48.0689662, lng: 18.5880848 },
    { lat: 48.0679905, lng: 18.5868508 },
    { lat: 48.0650942, lng: 18.5824355 },
    { lat: 48.0641521, lng: 18.5808692 },
    { lat: 48.0623413, lng: 18.5784345 },
    { lat: 48.0613439, lng: 18.5772404 },
    { lat: 48.0603121, lng: 18.5758278 },
    { lat: 48.0595354, lng: 18.5749329 },
    { lat: 48.0587809, lng: 18.5739075 },
    { lat: 48.056799, lng: 18.571755 },
    { lat: 48.0568174, lng: 18.5717193 },
    { lat: 48.0560845, lng: 18.5697939 },
    { lat: 48.0555467, lng: 18.5689381 },
    { lat: 48.0532887, lng: 18.5660724 },
    { lat: 48.0510288, lng: 18.5626205 },
    { lat: 48.0489461, lng: 18.559027 },
    { lat: 48.0475596, lng: 18.5570373 },
    { lat: 48.046143, lng: 18.555344 },
    { lat: 48.0452517, lng: 18.5540718 },
    { lat: 48.0446819, lng: 18.5531483 },
    { lat: 48.0444216, lng: 18.5523751 },
    { lat: 48.0443669, lng: 18.5517096 },
    { lat: 48.0439274, lng: 18.5503888 },
    { lat: 48.0438944, lng: 18.5503047 },
    { lat: 48.0433307, lng: 18.5494701 },
    { lat: 48.0431334, lng: 18.5493293 },
    { lat: 48.0415015, lng: 18.5470643 },
    { lat: 48.0400091, lng: 18.5444929 },
    { lat: 48.0388125, lng: 18.5429216 },
    { lat: 48.0345666, lng: 18.535983 },
    { lat: 48.0314824, lng: 18.5319855 },
    { lat: 48.0304691, lng: 18.5310649 },
    { lat: 48.0293613, lng: 18.53365 },
    { lat: 48.0282315, lng: 18.5362278 },
    { lat: 48.0290651, lng: 18.5370211 },
    { lat: 48.0306987, lng: 18.5391501 },
    { lat: 48.0321283, lng: 18.5406255 },
    { lat: 48.0336661, lng: 18.5425272 },
    { lat: 48.0353647, lng: 18.5448516 },
    { lat: 48.0373568, lng: 18.5477742 },
    { lat: 48.0376704, lng: 18.5483257 },
    { lat: 48.0371379, lng: 18.5488954 },
    { lat: 48.0383311, lng: 18.5506896 },
    { lat: 48.0400971, lng: 18.5536258 },
    { lat: 48.0405409, lng: 18.554251 },
    { lat: 48.0411892, lng: 18.5549216 },
    { lat: 48.040975, lng: 18.5554533 },
    { lat: 48.0401407, lng: 18.5565865 },
    { lat: 48.0391227, lng: 18.5577453 },
    { lat: 48.0389115, lng: 18.5582713 },
    { lat: 48.0386866, lng: 18.5585093 },
    { lat: 48.0382824, lng: 18.5587277 },
    { lat: 48.0378721, lng: 18.559309 },
    { lat: 48.0377262, lng: 18.5594231 },
    { lat: 48.0374472, lng: 18.559439 },
    { lat: 48.0373439, lng: 18.5595898 },
    { lat: 48.0371773, lng: 18.5600444 },
    { lat: 48.0368537, lng: 18.56044 },
    { lat: 48.0359354, lng: 18.5608041 },
    { lat: 48.0354626, lng: 18.5611679 },
    { lat: 48.0346215, lng: 18.5616078 },
    { lat: 48.0341189, lng: 18.5621912 },
    { lat: 48.0333969, lng: 18.5625961 },
    { lat: 48.0313084, lng: 18.5656205 },
    { lat: 48.0341867, lng: 18.5699168 },
    { lat: 48.031509, lng: 18.5739637 },
    { lat: 48.0344175, lng: 18.5772369 },
    { lat: 48.0355325, lng: 18.5787223 },
    { lat: 48.0374043, lng: 18.5813834 },
    { lat: 48.0365117, lng: 18.5828375 },
    { lat: 48.0356221, lng: 18.5840534 },
    { lat: 48.0353862, lng: 18.5844621 },
    { lat: 48.0349972, lng: 18.5853293 },
    { lat: 48.0337991, lng: 18.586743 },
    { lat: 48.0330944, lng: 18.5877579 },
    { lat: 48.0326518, lng: 18.5882009 },
    { lat: 48.0323727, lng: 18.5883558 },
    { lat: 48.0319841, lng: 18.5885321 },
    { lat: 48.0318435, lng: 18.5885373 },
    { lat: 48.0302712, lng: 18.5866512 },
    { lat: 48.0290798, lng: 18.5857816 },
    { lat: 48.0243646, lng: 18.59281 },
    { lat: 48.021682, lng: 18.5887728 },
    { lat: 48.0211816, lng: 18.5894623 },
    { lat: 48.0124686, lng: 18.6026479 },
    { lat: 48.0122553, lng: 18.6032649 },
    { lat: 48.0115229, lng: 18.6066179 },
    { lat: 48.0114168, lng: 18.6068217 },
    { lat: 48.0131544, lng: 18.6083589 },
    { lat: 48.0132414, lng: 18.6085054 },
    { lat: 48.0133913, lng: 18.6081727 },
    { lat: 48.0168171, lng: 18.612406 },
    { lat: 48.0199436, lng: 18.6158114 },
    { lat: 48.0228605, lng: 18.6197604 },
    { lat: 48.0260717, lng: 18.6238515 },
    { lat: 48.0262902, lng: 18.6240139 },
    { lat: 48.0277825, lng: 18.6270772 },
    { lat: 48.0326806, lng: 18.6376532 },
    { lat: 48.0335063, lng: 18.6398337 },
    { lat: 48.0334483, lng: 18.6398837 },
    { lat: 48.0334708, lng: 18.6399895 },
    { lat: 48.0336266, lng: 18.6402205 },
    { lat: 48.0335483, lng: 18.6403368 },
    { lat: 48.0334485, lng: 18.6402833 },
    { lat: 48.0333715, lng: 18.6401094 },
    { lat: 48.033292, lng: 18.6401429 },
    { lat: 48.033285, lng: 18.6406357 },
    { lat: 48.0329925, lng: 18.6409427 },
    { lat: 48.03293, lng: 18.6410754 },
    { lat: 48.0329271, lng: 18.6412083 },
    { lat: 48.0330913, lng: 18.6413075 },
    { lat: 48.0330431, lng: 18.6415604 },
    { lat: 48.0329122, lng: 18.6417654 },
    { lat: 48.0328402, lng: 18.6420398 },
    { lat: 48.0326104, lng: 18.6422085 },
    { lat: 48.0323835, lng: 18.6421443 },
    { lat: 48.0321381, lng: 18.6421955 },
    { lat: 48.0318658, lng: 18.6420814 },
    { lat: 48.031582, lng: 18.6416354 },
    { lat: 48.0312701, lng: 18.6414793 },
    { lat: 48.0311159, lng: 18.6416692 },
    { lat: 48.0309745, lng: 18.6413945 },
    { lat: 48.0308764, lng: 18.6413719 },
    { lat: 48.0302018, lng: 18.6420584 },
    { lat: 48.0301015, lng: 18.6423256 },
    { lat: 48.0296934, lng: 18.6425575 },
    { lat: 48.0297103, lng: 18.6426424 },
    { lat: 48.0297913, lng: 18.6426594 },
    { lat: 48.0298525, lng: 18.6427884 },
    { lat: 48.029727, lng: 18.6431151 },
    { lat: 48.0294549, lng: 18.6430884 },
    { lat: 48.0292688, lng: 18.6433167 },
    { lat: 48.0290391, lng: 18.6433476 },
    { lat: 48.0288695, lng: 18.6435897 },
    { lat: 48.0288957, lng: 18.6439019 },
    { lat: 48.0288165, lng: 18.6441708 },
    { lat: 48.028878, lng: 18.644324 },
    { lat: 48.0289808, lng: 18.644306 },
    { lat: 48.0290165, lng: 18.6443845 },
    { lat: 48.0289722, lng: 18.6446872 },
    { lat: 48.0285447, lng: 18.6448967 },
    { lat: 48.0283324, lng: 18.6447039 },
    { lat: 48.0282004, lng: 18.6448294 },
    { lat: 48.0280658, lng: 18.6447579 },
    { lat: 48.0278758, lng: 18.6443524 },
    { lat: 48.0278087, lng: 18.6443239 },
    { lat: 48.0275313, lng: 18.6444286 },
    { lat: 48.0272924, lng: 18.6448263 },
    { lat: 48.0268635, lng: 18.6452357 },
    { lat: 48.0267758, lng: 18.645388 },
    { lat: 48.0267547, lng: 18.6455607 },
    { lat: 48.0264983, lng: 18.6458834 },
    { lat: 48.0264789, lng: 18.6461306 },
    { lat: 48.0261932, lng: 18.6464537 },
    { lat: 48.0259834, lng: 18.646807 },
    { lat: 48.0257688, lng: 18.6467202 },
    { lat: 48.025386, lng: 18.6468136 },
    { lat: 48.0259385, lng: 18.6553936 },
    { lat: 48.0258458, lng: 18.655576 },
    { lat: 48.0259073, lng: 18.6609551 },
    { lat: 48.0260283, lng: 18.6611082 },
    { lat: 48.0259731, lng: 18.6622515 },
    { lat: 48.0257137, lng: 18.6654561 },
    { lat: 48.0257, lng: 18.6663509 },
    { lat: 48.0257912, lng: 18.667429 },
    { lat: 48.0261422, lng: 18.6699359 },
    { lat: 48.0273783, lng: 18.6729361 },
    { lat: 48.0278139, lng: 18.6742744 },
    { lat: 48.0290115, lng: 18.6748771 },
    { lat: 48.0303741, lng: 18.6763387 },
    { lat: 48.0301664, lng: 18.6769003 },
    { lat: 48.0304506, lng: 18.6794863 },
    { lat: 48.0290913, lng: 18.6798384 },
    { lat: 48.0289042, lng: 18.6800071 },
    { lat: 48.0256185, lng: 18.6849286 },
    { lat: 48.0251403, lng: 18.6873525 },
    { lat: 48.0251569, lng: 18.6886026 },
    { lat: 48.023123, lng: 18.6980941 },
    { lat: 48.0246002, lng: 18.6994279 },
    { lat: 48.0273388, lng: 18.7020467 },
    { lat: 48.0307511, lng: 18.7092696 },
    { lat: 48.0315392, lng: 18.7110316 },
    { lat: 48.031695, lng: 18.7110094 },
    { lat: 48.0317211, lng: 18.7110335 },
  ],
  JurnadHronom: [
    { lat: 48.1243488, lng: 18.6661811 },
    { lat: 48.1273693, lng: 18.6658271 },
    { lat: 48.1331092, lng: 18.6645331 },
    { lat: 48.1350156, lng: 18.6642889 },
    { lat: 48.1362222, lng: 18.6640134 },
    { lat: 48.1362389, lng: 18.6639256 },
    { lat: 48.1360633, lng: 18.6637674 },
    { lat: 48.1355009, lng: 18.6635422 },
    { lat: 48.1354465, lng: 18.6634143 },
    { lat: 48.1355073, lng: 18.6632449 },
    { lat: 48.1358167, lng: 18.6631277 },
    { lat: 48.1360058, lng: 18.6628934 },
    { lat: 48.1360642, lng: 18.6626134 },
    { lat: 48.1360838, lng: 18.6621015 },
    { lat: 48.1357579, lng: 18.6619583 },
    { lat: 48.135683, lng: 18.6615525 },
    { lat: 48.1356525, lng: 18.6610484 },
    { lat: 48.1354761, lng: 18.6605835 },
    { lat: 48.1354228, lng: 18.6603061 },
    { lat: 48.1354321, lng: 18.6599593 },
    { lat: 48.135317, lng: 18.6596843 },
    { lat: 48.1354593, lng: 18.6594606 },
    { lat: 48.1357325, lng: 18.6594216 },
    { lat: 48.1358689, lng: 18.6592561 },
    { lat: 48.1360286, lng: 18.6588315 },
    { lat: 48.1360551, lng: 18.6582987 },
    { lat: 48.1361206, lng: 18.6580672 },
    { lat: 48.1363788, lng: 18.6577991 },
    { lat: 48.1357796, lng: 18.6566664 },
    { lat: 48.1354327, lng: 18.6567994 },
    { lat: 48.1350575, lng: 18.6567049 },
    { lat: 48.1347318, lng: 18.6565003 },
    { lat: 48.1347726, lng: 18.6561924 },
    { lat: 48.1345753, lng: 18.6559006 },
    { lat: 48.1344914, lng: 18.6554955 },
    { lat: 48.1346582, lng: 18.6548483 },
    { lat: 48.1347421, lng: 18.6540885 },
    { lat: 48.134703, lng: 18.6537801 },
    { lat: 48.1345889, lng: 18.6534231 },
    { lat: 48.134596, lng: 18.6532401 },
    { lat: 48.1347042, lng: 18.65303 },
    { lat: 48.1359892, lng: 18.6513094 },
    { lat: 48.1365096, lng: 18.6511313 },
    { lat: 48.136769, lng: 18.6511106 },
    { lat: 48.1371873, lng: 18.6514205 },
    { lat: 48.1372673, lng: 18.6515443 },
    { lat: 48.1373982, lng: 18.6520624 },
    { lat: 48.138057, lng: 18.6523344 },
    { lat: 48.1383687, lng: 18.6525534 },
    { lat: 48.1385584, lng: 18.6524514 },
    { lat: 48.1386893, lng: 18.6521393 },
    { lat: 48.1387112, lng: 18.6517772 },
    { lat: 48.1386573, lng: 18.6512565 },
    { lat: 48.1381266, lng: 18.650082 },
    { lat: 48.1384699, lng: 18.6492774 },
    { lat: 48.1384323, lng: 18.6490401 },
    { lat: 48.1384525, lng: 18.6482717 },
    { lat: 48.1386605, lng: 18.6481321 },
    { lat: 48.1387708, lng: 18.6482735 },
    { lat: 48.1388954, lng: 18.6482015 },
    { lat: 48.1390867, lng: 18.6477967 },
    { lat: 48.1392968, lng: 18.6477398 },
    { lat: 48.1393622, lng: 18.6476345 },
    { lat: 48.139262, lng: 18.6471801 },
    { lat: 48.1393115, lng: 18.6469513 },
    { lat: 48.1395205, lng: 18.6468127 },
    { lat: 48.1396843, lng: 18.6468176 },
    { lat: 48.1399879, lng: 18.6472558 },
    { lat: 48.1402098, lng: 18.6471894 },
    { lat: 48.1408096, lng: 18.6464342 },
    { lat: 48.1408368, lng: 18.6461477 },
    { lat: 48.1407194, lng: 18.6456421 },
    { lat: 48.1410911, lng: 18.6454141 },
    { lat: 48.1413885, lng: 18.6452334 },
    { lat: 48.1415233, lng: 18.6452044 },
    { lat: 48.142753, lng: 18.6452896 },
    { lat: 48.1431054, lng: 18.6450718 },
    { lat: 48.1436616, lng: 18.6442855 },
    { lat: 48.1447591, lng: 18.6436818 },
    { lat: 48.1462111, lng: 18.6467033 },
    { lat: 48.1464844, lng: 18.6464618 },
    { lat: 48.1476308, lng: 18.6439119 },
    { lat: 48.1474156, lng: 18.6438317 },
    { lat: 48.1469037, lng: 18.6427734 },
    { lat: 48.1468022, lng: 18.6423799 },
    { lat: 48.1463921, lng: 18.6422614 },
    { lat: 48.1460794, lng: 18.642051 },
    { lat: 48.1458617, lng: 18.6418422 },
    { lat: 48.1455846, lng: 18.6414534 },
    { lat: 48.1452569, lng: 18.6403827 },
    { lat: 48.1451133, lng: 18.6401059 },
    { lat: 48.144976, lng: 18.6396539 },
    { lat: 48.1450482, lng: 18.6387411 },
    { lat: 48.144901, lng: 18.6380635 },
    { lat: 48.1447524, lng: 18.63591 },
    { lat: 48.1444078, lng: 18.6341125 },
    { lat: 48.1442045, lng: 18.6294797 },
    { lat: 48.1442412, lng: 18.6265854 },
    { lat: 48.1444439, lng: 18.6247967 },
    { lat: 48.1444759, lng: 18.6234794 },
    { lat: 48.1442828, lng: 18.6221665 },
    { lat: 48.1439368, lng: 18.6213041 },
    { lat: 48.1456623, lng: 18.6200105 },
    { lat: 48.1460442, lng: 18.6200446 },
    { lat: 48.1461451, lng: 18.6201998 },
    { lat: 48.1473402, lng: 18.6196731 },
    { lat: 48.146157, lng: 18.6174771 },
    { lat: 48.1438108, lng: 18.6137696 },
    { lat: 48.1409607, lng: 18.6086437 },
    { lat: 48.1404844, lng: 18.6079979 },
    { lat: 48.1405242, lng: 18.6078348 },
    { lat: 48.1400627, lng: 18.6066543 },
    { lat: 48.1401007, lng: 18.6059046 },
    { lat: 48.1404028, lng: 18.6040427 },
    { lat: 48.1403944, lng: 18.6037411 },
    { lat: 48.1401041, lng: 18.6030949 },
    { lat: 48.1397676, lng: 18.6025511 },
    { lat: 48.1393218, lng: 18.6021747 },
    { lat: 48.1390947, lng: 18.601815 },
    { lat: 48.138714, lng: 18.6013931 },
    { lat: 48.1384248, lng: 18.6012175 },
    { lat: 48.1381886, lng: 18.6013985 },
    { lat: 48.1382708, lng: 18.6019118 },
    { lat: 48.1377788, lng: 18.602754 },
    { lat: 48.1374118, lng: 18.6031568 },
    { lat: 48.1368494, lng: 18.6034979 },
    { lat: 48.1359637, lng: 18.6034314 },
    { lat: 48.1355212, lng: 18.6032802 },
    { lat: 48.1351792, lng: 18.6028003 },
    { lat: 48.1349834, lng: 18.6021882 },
    { lat: 48.1349275, lng: 18.6010203 },
    { lat: 48.1348925, lng: 18.6005881 },
    { lat: 48.1346063, lng: 18.5971675 },
    { lat: 48.1347171, lng: 18.5956003 },
    { lat: 48.1339778, lng: 18.5937375 },
    { lat: 48.1340678, lng: 18.5934857 },
    { lat: 48.1343557, lng: 18.5929933 },
    { lat: 48.1347714, lng: 18.5924475 },
    { lat: 48.1350764, lng: 18.5921907 },
    { lat: 48.1352798, lng: 18.5920938 },
    { lat: 48.1356461, lng: 18.5920664 },
    { lat: 48.1357916, lng: 18.5919438 },
    { lat: 48.1358492, lng: 18.591822 },
    { lat: 48.1355333, lng: 18.5913044 },
    { lat: 48.1348256, lng: 18.5906574 },
    { lat: 48.1341005, lng: 18.5898536 },
    { lat: 48.1321645, lng: 18.5872738 },
    { lat: 48.1246614, lng: 18.5781335 },
    { lat: 48.1225146, lng: 18.5758042 },
    { lat: 48.1221519, lng: 18.5752798 },
    { lat: 48.1221212, lng: 18.5752338 },
    { lat: 48.1221135, lng: 18.5751575 },
    { lat: 48.1216468, lng: 18.5745664 },
    { lat: 48.120532, lng: 18.5735736 },
    { lat: 48.1198057, lng: 18.5727743 },
    { lat: 48.1195357, lng: 18.5726223 },
    { lat: 48.1192239, lng: 18.5722856 },
    { lat: 48.1186967, lng: 18.5732221 },
    { lat: 48.1180658, lng: 18.5739968 },
    { lat: 48.1160033, lng: 18.5731444 },
    { lat: 48.1111451, lng: 18.5773373 },
    { lat: 48.1107222, lng: 18.5777674 },
    { lat: 48.1102473, lng: 18.5795987 },
    { lat: 48.1101267, lng: 18.5797659 },
    { lat: 48.1099511, lng: 18.5798569 },
    { lat: 48.1097567, lng: 18.5799498 },
    { lat: 48.1099138, lng: 18.5802846 },
    { lat: 48.1110929, lng: 18.5822695 },
    { lat: 48.1132949, lng: 18.5857304 },
    { lat: 48.1148191, lng: 18.5879808 },
    { lat: 48.1164153, lng: 18.5904767 },
    { lat: 48.117546, lng: 18.5922603 },
    { lat: 48.1185563, lng: 18.5940238 },
    { lat: 48.119428, lng: 18.5952596 },
    { lat: 48.1201228, lng: 18.5964552 },
    { lat: 48.1211764, lng: 18.5984546 },
    { lat: 48.1223074, lng: 18.6008641 },
    { lat: 48.1234503, lng: 18.6031201 },
    { lat: 48.1234175, lng: 18.6034499 },
    { lat: 48.1250705, lng: 18.6060411 },
    { lat: 48.1260895, lng: 18.6074767 },
    { lat: 48.1261481, lng: 18.6074763 },
    { lat: 48.1264935, lng: 18.6088249 },
    { lat: 48.1270251, lng: 18.6094317 },
    { lat: 48.1273356, lng: 18.6101406 },
    { lat: 48.127852, lng: 18.6109406 },
    { lat: 48.1279526, lng: 18.613346 },
    { lat: 48.1276116, lng: 18.6132555 },
    { lat: 48.1273501, lng: 18.6130935 },
    { lat: 48.126828, lng: 18.6123316 },
    { lat: 48.1265631, lng: 18.6120019 },
    { lat: 48.1264181, lng: 18.6119313 },
    { lat: 48.125659, lng: 18.6119287 },
    { lat: 48.1246801, lng: 18.6121385 },
    { lat: 48.1241622, lng: 18.6125492 },
    { lat: 48.1239272, lng: 18.6128525 },
    { lat: 48.123548, lng: 18.6139037 },
    { lat: 48.1234641, lng: 18.6148705 },
    { lat: 48.1236812, lng: 18.6167423 },
    { lat: 48.1240161, lng: 18.6183944 },
    { lat: 48.1239872, lng: 18.6189829 },
    { lat: 48.1238941, lng: 18.6194704 },
    { lat: 48.1238373, lng: 18.6196284 },
    { lat: 48.123684, lng: 18.6200209 },
    { lat: 48.1232329, lng: 18.6209568 },
    { lat: 48.1227274, lng: 18.6217825 },
    { lat: 48.1219711, lng: 18.6223294 },
    { lat: 48.1210257, lng: 18.622722 },
    { lat: 48.1208181, lng: 18.622989 },
    { lat: 48.120236, lng: 18.6240633 },
    { lat: 48.1192537, lng: 18.6269902 },
    { lat: 48.1186922, lng: 18.6281116 },
    { lat: 48.1182914, lng: 18.6296392 },
    { lat: 48.117592, lng: 18.630694 },
    { lat: 48.1173955, lng: 18.6309413 },
    { lat: 48.1171761, lng: 18.631096 },
    { lat: 48.1167749, lng: 18.6317677 },
    { lat: 48.1173427, lng: 18.632834 },
    { lat: 48.1187839, lng: 18.6358781 },
    { lat: 48.1173553, lng: 18.638151 },
    { lat: 48.1173306, lng: 18.6381109 },
    { lat: 48.1165801, lng: 18.6390352 },
    { lat: 48.1158979, lng: 18.6401396 },
    { lat: 48.1157712, lng: 18.6416339 },
    { lat: 48.115754, lng: 18.6434145 },
    { lat: 48.1155747, lng: 18.6455127 },
    { lat: 48.1154603, lng: 18.6463803 },
    { lat: 48.1152352, lng: 18.6471148 },
    { lat: 48.115, lng: 18.6476455 },
    { lat: 48.1141307, lng: 18.6484209 },
    { lat: 48.1138116, lng: 18.6480063 },
    { lat: 48.1137245, lng: 18.6484795 },
    { lat: 48.1133881, lng: 18.6496807 },
    { lat: 48.1127397, lng: 18.6499539 },
    { lat: 48.112532, lng: 18.6502829 },
    { lat: 48.1124096, lng: 18.6509379 },
    { lat: 48.1117859, lng: 18.6527518 },
    { lat: 48.111527, lng: 18.6531953 },
    { lat: 48.1111835, lng: 18.6533511 },
    { lat: 48.1111245, lng: 18.6534486 },
    { lat: 48.1110839, lng: 18.6536367 },
    { lat: 48.1112401, lng: 18.654438 },
    { lat: 48.11097, lng: 18.6551987 },
    { lat: 48.1106186, lng: 18.6551868 },
    { lat: 48.1105218, lng: 18.6552758 },
    { lat: 48.110151, lng: 18.6559886 },
    { lat: 48.1102048, lng: 18.6560859 },
    { lat: 48.110264, lng: 18.6561578 },
    { lat: 48.1105582, lng: 18.6561741 },
    { lat: 48.1107454, lng: 18.6563195 },
    { lat: 48.1108527, lng: 18.6566168 },
    { lat: 48.1109779, lng: 18.6573865 },
    { lat: 48.1112615, lng: 18.6578753 },
    { lat: 48.1117277, lng: 18.6583587 },
    { lat: 48.1123931, lng: 18.6578192 },
    { lat: 48.1130863, lng: 18.656767 },
    { lat: 48.1139257, lng: 18.657043 },
    { lat: 48.1147236, lng: 18.6583221 },
    { lat: 48.1145064, lng: 18.6591043 },
    { lat: 48.114635, lng: 18.6595638 },
    { lat: 48.1148999, lng: 18.660122 },
    { lat: 48.115319, lng: 18.6605069 },
    { lat: 48.1153508, lng: 18.6606408 },
    { lat: 48.1159324, lng: 18.6604636 },
    { lat: 48.116081, lng: 18.6604813 },
    { lat: 48.1167462, lng: 18.6608497 },
    { lat: 48.1169694, lng: 18.6609124 },
    { lat: 48.117074, lng: 18.660862 },
    { lat: 48.1174198, lng: 18.6600854 },
    { lat: 48.1179678, lng: 18.6593507 },
    { lat: 48.1183721, lng: 18.6585498 },
    { lat: 48.1185599, lng: 18.658412 },
    { lat: 48.1187915, lng: 18.6586301 },
    { lat: 48.1190693, lng: 18.6595919 },
    { lat: 48.1192516, lng: 18.6600655 },
    { lat: 48.119345, lng: 18.6601675 },
    { lat: 48.1196944, lng: 18.660226 },
    { lat: 48.1198761, lng: 18.6599965 },
    { lat: 48.1207352, lng: 18.6583848 },
    { lat: 48.1208571, lng: 18.6583225 },
    { lat: 48.1212482, lng: 18.6583172 },
    { lat: 48.1222979, lng: 18.6601659 },
    { lat: 48.1225468, lng: 18.6607211 },
    { lat: 48.1234591, lng: 18.6630774 },
    { lat: 48.1240955, lng: 18.665292 },
    { lat: 48.1243488, lng: 18.6661811 },
  ],
  Lok: [
    { lat: 48.2003046, lng: 18.464136 },
    { lat: 48.1999673, lng: 18.4635688 },
    { lat: 48.1996289, lng: 18.4628628 },
    { lat: 48.1988783, lng: 18.4618409 },
    { lat: 48.198372, lng: 18.4613213 },
    { lat: 48.1979508, lng: 18.4611317 },
    { lat: 48.1971321, lng: 18.4609772 },
    { lat: 48.1967526, lng: 18.4606639 },
    { lat: 48.1963784, lng: 18.4604893 },
    { lat: 48.1959888, lng: 18.460197 },
    { lat: 48.1961546, lng: 18.4587631 },
    { lat: 48.1944972, lng: 18.4563529 },
    { lat: 48.1936182, lng: 18.4549388 },
    { lat: 48.192754, lng: 18.4534204 },
    { lat: 48.1923297, lng: 18.4522925 },
    { lat: 48.1926409, lng: 18.4521447 },
    { lat: 48.1903636, lng: 18.4471456 },
    { lat: 48.1895594, lng: 18.4435212 },
    { lat: 48.1886891, lng: 18.439866 },
    { lat: 48.187006, lng: 18.4366437 },
    { lat: 48.1870226, lng: 18.4365568 },
    { lat: 48.1870737, lng: 18.4364846 },
    { lat: 48.1869432, lng: 18.4363284 },
    { lat: 48.1867462, lng: 18.4362135 },
    { lat: 48.1858506, lng: 18.4358954 },
    { lat: 48.1853378, lng: 18.4356249 },
    { lat: 48.184622, lng: 18.4353505 },
    { lat: 48.1834963, lng: 18.4351157 },
    { lat: 48.1809201, lng: 18.4353031 },
    { lat: 48.1796981, lng: 18.4358631 },
    { lat: 48.1777851, lng: 18.4374802 },
    { lat: 48.1767524, lng: 18.4386221 },
    { lat: 48.1756578, lng: 18.4394594 },
    { lat: 48.1747265, lng: 18.4399941 },
    { lat: 48.1735481, lng: 18.4403886 },
    { lat: 48.1731545, lng: 18.4405906 },
    { lat: 48.1731594, lng: 18.4406193 },
    { lat: 48.1713094, lng: 18.442314 },
    { lat: 48.1701464, lng: 18.4436455 },
    { lat: 48.1693431, lng: 18.4447313 },
    { lat: 48.1672447, lng: 18.4467264 },
    { lat: 48.1657168, lng: 18.4483903 },
    { lat: 48.165359, lng: 18.4489767 },
    { lat: 48.1647356, lng: 18.4501925 },
    { lat: 48.1636128, lng: 18.4517805 },
    { lat: 48.1629519, lng: 18.4544291 },
    { lat: 48.1617857, lng: 18.4582901 },
    { lat: 48.1614743, lng: 18.4590166 },
    { lat: 48.1601303, lng: 18.461535 },
    { lat: 48.1597046, lng: 18.462235 },
    { lat: 48.1590842, lng: 18.4630251 },
    { lat: 48.1577087, lng: 18.4645724 },
    { lat: 48.157398, lng: 18.4648503 },
    { lat: 48.1572298, lng: 18.4649285 },
    { lat: 48.156543, lng: 18.4649314 },
    { lat: 48.1565978, lng: 18.4656096 },
    { lat: 48.1565646, lng: 18.4663101 },
    { lat: 48.1563915, lng: 18.4677167 },
    { lat: 48.1565268, lng: 18.4686036 },
    { lat: 48.1566591, lng: 18.4699254 },
    { lat: 48.1567784, lng: 18.4717643 },
    { lat: 48.1571106, lng: 18.4736132 },
    { lat: 48.1572034, lng: 18.4743669 },
    { lat: 48.1574268, lng: 18.4771807 },
    { lat: 48.1574656, lng: 18.4772109 },
    { lat: 48.1584259, lng: 18.4783923 },
    { lat: 48.158918, lng: 18.4791045 },
    { lat: 48.1593736, lng: 18.4799559 },
    { lat: 48.1597633, lng: 18.4809762 },
    { lat: 48.1599548, lng: 18.4813171 },
    { lat: 48.1602277, lng: 18.4815774 },
    { lat: 48.1607316, lng: 18.481836 },
    { lat: 48.1606408, lng: 18.4824345 },
    { lat: 48.1602365, lng: 18.4842888 },
    { lat: 48.1599501, lng: 18.4850222 },
    { lat: 48.1592525, lng: 18.4879333 },
    { lat: 48.1590865, lng: 18.4884821 },
    { lat: 48.1585097, lng: 18.4915989 },
    { lat: 48.158395, lng: 18.491993 },
    { lat: 48.158205, lng: 18.4935485 },
    { lat: 48.1577358, lng: 18.4984053 },
    { lat: 48.1574022, lng: 18.4998743 },
    { lat: 48.1589004, lng: 18.5011767 },
    { lat: 48.1603279, lng: 18.5026894 },
    { lat: 48.1609731, lng: 18.5036464 },
    { lat: 48.1610246, lng: 18.5043912 },
    { lat: 48.1643175, lng: 18.5078715 },
    { lat: 48.1643349, lng: 18.5079206 },
    { lat: 48.1654653, lng: 18.5091845 },
    { lat: 48.1656476, lng: 18.5091983 },
    { lat: 48.1658572, lng: 18.5091109 },
    { lat: 48.1662285, lng: 18.5087091 },
    { lat: 48.1674234, lng: 18.5069802 },
    { lat: 48.1695707, lng: 18.5044689 },
    { lat: 48.1700248, lng: 18.5041151 },
    { lat: 48.1717068, lng: 18.503301 },
    { lat: 48.1724089, lng: 18.5031069 },
    { lat: 48.1732876, lng: 18.50298 },
    { lat: 48.1747429, lng: 18.5023338 },
    { lat: 48.1756521, lng: 18.5021077 },
    { lat: 48.17662, lng: 18.5017396 },
    { lat: 48.1791444, lng: 18.5014334 },
    { lat: 48.1807549, lng: 18.5010796 },
    { lat: 48.1815535, lng: 18.5010587 },
    { lat: 48.18219, lng: 18.5006952 },
    { lat: 48.1833397, lng: 18.5016347 },
    { lat: 48.1853317, lng: 18.4981744 },
    { lat: 48.1855128, lng: 18.4979611 },
    { lat: 48.1860294, lng: 18.4969238 },
    { lat: 48.1862067, lng: 18.4966413 },
    { lat: 48.186965, lng: 18.4957302 },
    { lat: 48.1872116, lng: 18.4953182 },
    { lat: 48.1874053, lng: 18.4948848 },
    { lat: 48.1882603, lng: 18.4922023 },
    { lat: 48.1890164, lng: 18.4906812 },
    { lat: 48.1903226, lng: 18.4887891 },
    { lat: 48.191107, lng: 18.4874971 },
    { lat: 48.1921615, lng: 18.4859904 },
    { lat: 48.1923198, lng: 18.4856744 },
    { lat: 48.1946796, lng: 18.4808812 },
    { lat: 48.1949199, lng: 18.4802531 },
    { lat: 48.1958312, lng: 18.4772291 },
    { lat: 48.196386, lng: 18.4759285 },
    { lat: 48.1966752, lng: 18.4750435 },
    { lat: 48.197348, lng: 18.4723135 },
    { lat: 48.1979709, lng: 18.4704545 },
    { lat: 48.1983041, lng: 18.4690877 },
    { lat: 48.1993455, lng: 18.4666623 },
    { lat: 48.2003046, lng: 18.464136 },
  ],
  Santovka: [
    { lat: 48.1448727, lng: 18.7549491 },
    { lat: 48.1457102, lng: 18.7567408 },
    { lat: 48.1466597, lng: 18.7618472 },
    { lat: 48.1468385, lng: 18.7633427 },
    { lat: 48.1484406, lng: 18.7667718 },
    { lat: 48.1492206, lng: 18.7673181 },
    { lat: 48.1498297, lng: 18.7676576 },
    { lat: 48.1509197, lng: 18.7684316 },
    { lat: 48.1510761, lng: 18.76872 },
    { lat: 48.1514358, lng: 18.7698466 },
    { lat: 48.1520143, lng: 18.7721166 },
    { lat: 48.1520237, lng: 18.7721469 },
    { lat: 48.1517433, lng: 18.7723896 },
    { lat: 48.1504356, lng: 18.7730366 },
    { lat: 48.149373, lng: 18.7733877 },
    { lat: 48.1489697, lng: 18.7735209 },
    { lat: 48.1446768, lng: 18.7749392 },
    { lat: 48.1414937, lng: 18.7763096 },
    { lat: 48.1410241, lng: 18.7765986 },
    { lat: 48.1404234, lng: 18.7771379 },
    { lat: 48.1383615, lng: 18.7795348 },
    { lat: 48.1380194, lng: 18.7797804 },
    { lat: 48.1380992, lng: 18.7801364 },
    { lat: 48.1380761, lng: 18.7804445 },
    { lat: 48.1380096, lng: 18.7805092 },
    { lat: 48.1380148, lng: 18.7806297 },
    { lat: 48.1377044, lng: 18.7809613 },
    { lat: 48.1374488, lng: 18.7821411 },
    { lat: 48.137539, lng: 18.7826519 },
    { lat: 48.1377481, lng: 18.7832577 },
    { lat: 48.137794, lng: 18.7837617 },
    { lat: 48.1377742, lng: 18.7840031 },
    { lat: 48.1379427, lng: 18.7842889 },
    { lat: 48.1379567, lng: 18.7845524 },
    { lat: 48.1381117, lng: 18.7848603 },
    { lat: 48.1380729, lng: 18.7853193 },
    { lat: 48.1382163, lng: 18.7854743 },
    { lat: 48.1383267, lng: 18.7861698 },
    { lat: 48.1383384, lng: 18.786566 },
    { lat: 48.1382373, lng: 18.7876551 },
    { lat: 48.1380205, lng: 18.7884381 },
    { lat: 48.1378252, lng: 18.7899051 },
    { lat: 48.1379519, lng: 18.7907227 },
    { lat: 48.1381576, lng: 18.7916036 },
    { lat: 48.1385814, lng: 18.7927932 },
    { lat: 48.1386795, lng: 18.7936247 },
    { lat: 48.1389699, lng: 18.7952537 },
    { lat: 48.1388962, lng: 18.79689 },
    { lat: 48.1389984, lng: 18.7974541 },
    { lat: 48.1389233, lng: 18.797701 },
    { lat: 48.138939, lng: 18.7978241 },
    { lat: 48.1389997, lng: 18.7978635 },
    { lat: 48.1390519, lng: 18.798364 },
    { lat: 48.1388546, lng: 18.8000665 },
    { lat: 48.1390676, lng: 18.8008325 },
    { lat: 48.1394895, lng: 18.8014568 },
    { lat: 48.1397606, lng: 18.8016995 },
    { lat: 48.1404309, lng: 18.8018979 },
    { lat: 48.1408797, lng: 18.8021348 },
    { lat: 48.1397731, lng: 18.8039848 },
    { lat: 48.1382617, lng: 18.8082313 },
    { lat: 48.1366611, lng: 18.812925 },
    { lat: 48.1362139, lng: 18.8141095 },
    { lat: 48.1357285, lng: 18.8153851 },
    { lat: 48.1352322, lng: 18.8163902 },
    { lat: 48.1348256, lng: 18.816844 },
    { lat: 48.1339523, lng: 18.8179094 },
    { lat: 48.1346176, lng: 18.8194674 },
    { lat: 48.1350707, lng: 18.8203204 },
    { lat: 48.1368283, lng: 18.8228187 },
    { lat: 48.1373575, lng: 18.8231653 },
    { lat: 48.138495, lng: 18.8242048 },
    { lat: 48.1394944, lng: 18.8252609 },
    { lat: 48.140051, lng: 18.8261304 },
    { lat: 48.1400333, lng: 18.8261795 },
    { lat: 48.1409437, lng: 18.827686 },
    { lat: 48.1412898, lng: 18.8284359 },
    { lat: 48.1424574, lng: 18.8315764 },
    { lat: 48.1431479, lng: 18.8332357 },
    { lat: 48.1433966, lng: 18.833651 },
    { lat: 48.1439626, lng: 18.8341015 },
    { lat: 48.1446248, lng: 18.834944 },
    { lat: 48.1449375, lng: 18.8354849 },
    { lat: 48.1449436, lng: 18.8354962 },
    { lat: 48.1450911, lng: 18.8352547 },
    { lat: 48.1458147, lng: 18.8345802 },
    { lat: 48.1460052, lng: 18.8343029 },
    { lat: 48.1465389, lng: 18.8334497 },
    { lat: 48.1480653, lng: 18.8306928 },
    { lat: 48.1500064, lng: 18.8276109 },
    { lat: 48.1502341, lng: 18.8271149 },
    { lat: 48.1503188, lng: 18.8267751 },
    { lat: 48.1503992, lng: 18.8266784 },
    { lat: 48.1507235, lng: 18.8252966 },
    { lat: 48.1509256, lng: 18.8236273 },
    { lat: 48.1507861, lng: 18.8202507 },
    { lat: 48.1513039, lng: 18.8182186 },
    { lat: 48.1532026, lng: 18.8150877 },
    { lat: 48.1540619, lng: 18.8133535 },
    { lat: 48.1548121, lng: 18.8112652 },
    { lat: 48.1563359, lng: 18.8105841 },
    { lat: 48.1573234, lng: 18.8106197 },
    { lat: 48.1586488, lng: 18.8114203 },
    { lat: 48.1593685, lng: 18.8121489 },
    { lat: 48.1595488, lng: 18.8120397 },
    { lat: 48.1604652, lng: 18.8107892 },
    { lat: 48.1608924, lng: 18.8101257 },
    { lat: 48.1610738, lng: 18.8097157 },
    { lat: 48.1620875, lng: 18.8083886 },
    { lat: 48.1623206, lng: 18.8082347 },
    { lat: 48.1626372, lng: 18.8081613 },
    { lat: 48.1628354, lng: 18.808008 },
    { lat: 48.1630505, lng: 18.8075973 },
    { lat: 48.1634993, lng: 18.80736 },
    { lat: 48.162965, lng: 18.8050585 },
    { lat: 48.1628904, lng: 18.8048822 },
    { lat: 48.1626578, lng: 18.8043427 },
    { lat: 48.1609804, lng: 18.8016665 },
    { lat: 48.1608276, lng: 18.8013556 },
    { lat: 48.1602955, lng: 18.7998452 },
    { lat: 48.1606963, lng: 18.7982749 },
    { lat: 48.1590399, lng: 18.7970174 },
    { lat: 48.1594637, lng: 18.7960352 },
    { lat: 48.1600284, lng: 18.794898 },
    { lat: 48.1610827, lng: 18.7930193 },
    { lat: 48.160892, lng: 18.7928056 },
    { lat: 48.1611156, lng: 18.7923408 },
    { lat: 48.1603973, lng: 18.7894859 },
    { lat: 48.160786, lng: 18.7883736 },
    { lat: 48.160352, lng: 18.7880533 },
    { lat: 48.1605216, lng: 18.7873607 },
    { lat: 48.1601808, lng: 18.786588 },
    { lat: 48.1600768, lng: 18.7860966 },
    { lat: 48.1600506, lng: 18.785726 },
    { lat: 48.1601484, lng: 18.7846993 },
    { lat: 48.1605489, lng: 18.7833835 },
    { lat: 48.1607286, lng: 18.7825826 },
    { lat: 48.1607687, lng: 18.7821653 },
    { lat: 48.1607103, lng: 18.781723 },
    { lat: 48.1605222, lng: 18.7812204 },
    { lat: 48.1601822, lng: 18.7809099 },
    { lat: 48.1592122, lng: 18.7807814 },
    { lat: 48.1585802, lng: 18.7805161 },
    { lat: 48.1583011, lng: 18.7800797 },
    { lat: 48.1584332, lng: 18.7793806 },
    { lat: 48.1586354, lng: 18.7788788 },
    { lat: 48.1589968, lng: 18.7789522 },
    { lat: 48.1590542, lng: 18.7778858 },
    { lat: 48.1590357, lng: 18.777047 },
    { lat: 48.1588413, lng: 18.7755516 },
    { lat: 48.1587568, lng: 18.775001 },
    { lat: 48.1585764, lng: 18.7746385 },
    { lat: 48.1584899, lng: 18.7739229 },
    { lat: 48.158597, lng: 18.7737318 },
    { lat: 48.1585251, lng: 18.7730587 },
    { lat: 48.1582352, lng: 18.7726547 },
    { lat: 48.1583698, lng: 18.7716416 },
    { lat: 48.1583654, lng: 18.7709852 },
    { lat: 48.1582691, lng: 18.7700617 },
    { lat: 48.1580617, lng: 18.7687289 },
    { lat: 48.1613562, lng: 18.7674147 },
    { lat: 48.1619369, lng: 18.7672854 },
    { lat: 48.1647981, lng: 18.7670955 },
    { lat: 48.1660543, lng: 18.7670725 },
    { lat: 48.1667397, lng: 18.7669004 },
    { lat: 48.167682, lng: 18.766803 },
    { lat: 48.1684752, lng: 18.7663647 },
    { lat: 48.1689399, lng: 18.7661972 },
    { lat: 48.1722356, lng: 18.7640398 },
    { lat: 48.1731489, lng: 18.7633738 },
    { lat: 48.1732714, lng: 18.7629455 },
    { lat: 48.1750004, lng: 18.7624161 },
    { lat: 48.1753952, lng: 18.7618247 },
    { lat: 48.1757578, lng: 18.7613901 },
    { lat: 48.1767794, lng: 18.7610023 },
    { lat: 48.1773634, lng: 18.7609148 },
    { lat: 48.1780555, lng: 18.7608019 },
    { lat: 48.178053, lng: 18.7607764 },
    { lat: 48.1778845, lng: 18.7587325 },
    { lat: 48.1777476, lng: 18.7579629 },
    { lat: 48.1776019, lng: 18.7574495 },
    { lat: 48.1773651, lng: 18.7568749 },
    { lat: 48.1769452, lng: 18.7561259 },
    { lat: 48.1764579, lng: 18.7546207 },
    { lat: 48.1755383, lng: 18.7532793 },
    { lat: 48.1752436, lng: 18.753078 },
    { lat: 48.1748753, lng: 18.7518605 },
    { lat: 48.1739876, lng: 18.7477294 },
    { lat: 48.1733971, lng: 18.7462978 },
    { lat: 48.1728043, lng: 18.7451 },
    { lat: 48.1725584, lng: 18.7447198 },
    { lat: 48.1721329, lng: 18.744271 },
    { lat: 48.1725261, lng: 18.7430765 },
    { lat: 48.1730465, lng: 18.7420722 },
    { lat: 48.1733767, lng: 18.7415841 },
    { lat: 48.1729834, lng: 18.7408043 },
    { lat: 48.171245, lng: 18.7372852 },
    { lat: 48.1719667, lng: 18.735446 },
    { lat: 48.1715945, lng: 18.7351727 },
    { lat: 48.1721726, lng: 18.7331153 },
    { lat: 48.1720761, lng: 18.7324276 },
    { lat: 48.1734294, lng: 18.7280166 },
    { lat: 48.1738217, lng: 18.7282227 },
    { lat: 48.1742061, lng: 18.7261313 },
    { lat: 48.1740576, lng: 18.722321 },
    { lat: 48.1740197, lng: 18.719171 },
    { lat: 48.1723691, lng: 18.7174521 },
    { lat: 48.1717882, lng: 18.7165666 },
    { lat: 48.1714694, lng: 18.7159847 },
    { lat: 48.1711147, lng: 18.7154898 },
    { lat: 48.1709406, lng: 18.7151047 },
    { lat: 48.1709368, lng: 18.7150104 },
    { lat: 48.1707375, lng: 18.7147001 },
    { lat: 48.1704412, lng: 18.7139074 },
    { lat: 48.1703959, lng: 18.713816 },
    { lat: 48.1700991, lng: 18.7143419 },
    { lat: 48.1697238, lng: 18.7148317 },
    { lat: 48.1692282, lng: 18.7143385 },
    { lat: 48.1688253, lng: 18.7138264 },
    { lat: 48.1671943, lng: 18.7131908 },
    { lat: 48.1670427, lng: 18.71285 },
    { lat: 48.1668987, lng: 18.7125397 },
    { lat: 48.1666809, lng: 18.7136529 },
    { lat: 48.1666271, lng: 18.7136812 },
    { lat: 48.1659749, lng: 18.7149131 },
    { lat: 48.1654969, lng: 18.7161584 },
    { lat: 48.164199, lng: 18.7189654 },
    { lat: 48.1638261, lng: 18.7202556 },
    { lat: 48.1631673, lng: 18.7198727 },
    { lat: 48.1626743, lng: 18.7211572 },
    { lat: 48.1627239, lng: 18.7219792 },
    { lat: 48.1625894, lng: 18.7249457 },
    { lat: 48.1616437, lng: 18.7274668 },
    { lat: 48.1605474, lng: 18.7301015 },
    { lat: 48.1594726, lng: 18.7323577 },
    { lat: 48.1586971, lng: 18.7344135 },
    { lat: 48.1579154, lng: 18.736039 },
    { lat: 48.1567409, lng: 18.7376153 },
    { lat: 48.1555409, lng: 18.7390288 },
    { lat: 48.1542471, lng: 18.7403772 },
    { lat: 48.1523411, lng: 18.7420235 },
    { lat: 48.1507251, lng: 18.7435711 },
    { lat: 48.1497508, lng: 18.7458538 },
    { lat: 48.1485955, lng: 18.7477313 },
    { lat: 48.1469003, lng: 18.7509221 },
    { lat: 48.1458482, lng: 18.7531462 },
    { lat: 48.1448727, lng: 18.7549491 },
  ],
  Sazdice: [
    { lat: 48.1069059, lng: 18.7606638 },
    { lat: 48.1053281, lng: 18.7618267 },
    { lat: 48.1049459, lng: 18.7621719 },
    { lat: 48.1046014, lng: 18.7622654 },
    { lat: 48.1044685, lng: 18.7622229 },
    { lat: 48.1043923, lng: 18.7621071 },
    { lat: 48.1042179, lng: 18.7622445 },
    { lat: 48.1041225, lng: 18.7621878 },
    { lat: 48.1040242, lng: 18.7618014 },
    { lat: 48.1041194, lng: 18.7614497 },
    { lat: 48.1041108, lng: 18.7613106 },
    { lat: 48.1039072, lng: 18.7606023 },
    { lat: 48.1037881, lng: 18.7603543 },
    { lat: 48.10378, lng: 18.760225 },
    { lat: 48.1038618, lng: 18.7599817 },
    { lat: 48.1037801, lng: 18.7596132 },
    { lat: 48.1034337, lng: 18.759184 },
    { lat: 48.1029904, lng: 18.7589953 },
    { lat: 48.1028637, lng: 18.7588787 },
    { lat: 48.102796, lng: 18.7586239 },
    { lat: 48.102415, lng: 18.7585206 },
    { lat: 48.102279, lng: 18.7583815 },
    { lat: 48.1022684, lng: 18.7584385 },
    { lat: 48.1010183, lng: 18.7575175 },
    { lat: 48.0988913, lng: 18.7572013 },
    { lat: 48.0968586, lng: 18.7581451 },
    { lat: 48.0952049, lng: 18.7570996 },
    { lat: 48.0935016, lng: 18.7576031 },
    { lat: 48.0902011, lng: 18.7569116 },
    { lat: 48.0863223, lng: 18.7571149 },
    { lat: 48.0843961, lng: 18.7579643 },
    { lat: 48.0835239, lng: 18.7588875 },
    { lat: 48.0799393, lng: 18.7608032 },
    { lat: 48.076133, lng: 18.7624533 },
    { lat: 48.0721591, lng: 18.7634371 },
    { lat: 48.0720548, lng: 18.7634654 },
    { lat: 48.0722398, lng: 18.7640813 },
    { lat: 48.0721797, lng: 18.7656876 },
    { lat: 48.0718597, lng: 18.7671532 },
    { lat: 48.0717552, lng: 18.7691941 },
    { lat: 48.0719048, lng: 18.7699726 },
    { lat: 48.0719403, lng: 18.770747 },
    { lat: 48.0718496, lng: 18.7724534 },
    { lat: 48.071792, lng: 18.7731975 },
    { lat: 48.0713238, lng: 18.775144 },
    { lat: 48.0712745, lng: 18.7760627 },
    { lat: 48.0713393, lng: 18.7771857 },
    { lat: 48.071404, lng: 18.7783086 },
    { lat: 48.0715461, lng: 18.7791374 },
    { lat: 48.0716956, lng: 18.7816775 },
    { lat: 48.0714595, lng: 18.782692 },
    { lat: 48.0712309, lng: 18.7841428 },
    { lat: 48.0713703, lng: 18.7867027 },
    { lat: 48.071338, lng: 18.7903274 },
    { lat: 48.0721007, lng: 18.7915244 },
    { lat: 48.0724536, lng: 18.79525 },
    { lat: 48.0725734, lng: 18.7984901 },
    { lat: 48.0728238, lng: 18.7993674 },
    { lat: 48.0734205, lng: 18.8004357 },
    { lat: 48.0744197, lng: 18.8032895 },
    { lat: 48.0741564, lng: 18.8045176 },
    { lat: 48.073882, lng: 18.8052773 },
    { lat: 48.0731573, lng: 18.8052653 },
    { lat: 48.0726992, lng: 18.8068802 },
    { lat: 48.0724683, lng: 18.8073372 },
    { lat: 48.072918, lng: 18.8075643 },
    { lat: 48.0728788, lng: 18.8081232 },
    { lat: 48.0736464, lng: 18.8084384 },
    { lat: 48.0734246, lng: 18.8094687 },
    { lat: 48.0729635, lng: 18.8102405 },
    { lat: 48.0727483, lng: 18.810904 },
    { lat: 48.0721998, lng: 18.8112171 },
    { lat: 48.0726022, lng: 18.8116817 },
    { lat: 48.0726129, lng: 18.8121625 },
    { lat: 48.0723347, lng: 18.812491 },
    { lat: 48.0723119, lng: 18.8137246 },
    { lat: 48.0722503, lng: 18.8145227 },
    { lat: 48.0718742, lng: 18.815666 },
    { lat: 48.0713832, lng: 18.8161919 },
    { lat: 48.0712794, lng: 18.8163818 },
    { lat: 48.0714576, lng: 18.8173012 },
    { lat: 48.0718786, lng: 18.8186329 },
    { lat: 48.071224, lng: 18.8198649 },
    { lat: 48.0707415, lng: 18.8194406 },
    { lat: 48.0704272, lng: 18.8193153 },
    { lat: 48.0702828, lng: 18.8192914 },
    { lat: 48.0700316, lng: 18.8196417 },
    { lat: 48.0698479, lng: 18.8195822 },
    { lat: 48.0694723, lng: 18.8189159 },
    { lat: 48.0691902, lng: 18.8188052 },
    { lat: 48.0687077, lng: 18.8191513 },
    { lat: 48.0680774, lng: 18.8190254 },
    { lat: 48.0678432, lng: 18.8194601 },
    { lat: 48.0675693, lng: 18.8190565 },
    { lat: 48.0665601, lng: 18.8180088 },
    { lat: 48.0659244, lng: 18.8181871 },
    { lat: 48.0656049, lng: 18.8174945 },
    { lat: 48.0617717, lng: 18.818604 },
    { lat: 48.0616143, lng: 18.8202158 },
    { lat: 48.0620273, lng: 18.8206041 },
    { lat: 48.0620203, lng: 18.820858 },
    { lat: 48.0620069, lng: 18.8213507 },
    { lat: 48.0625466, lng: 18.8223717 },
    { lat: 48.062776, lng: 18.8224886 },
    { lat: 48.062918, lng: 18.8256701 },
    { lat: 48.0628776, lng: 18.8258448 },
    { lat: 48.0629069, lng: 18.8275169 },
    { lat: 48.0678171, lng: 18.8296163 },
    { lat: 48.071427, lng: 18.830627 },
    { lat: 48.0741823, lng: 18.831811 },
    { lat: 48.0751964, lng: 18.8321354 },
    { lat: 48.0786062, lng: 18.8326907 },
    { lat: 48.0791731, lng: 18.8326422 },
    { lat: 48.0810166, lng: 18.8328723 },
    { lat: 48.0820087, lng: 18.832585 },
    { lat: 48.0831451, lng: 18.8320878 },
    { lat: 48.0862642, lng: 18.8311642 },
    { lat: 48.0892827, lng: 18.8298731 },
    { lat: 48.0908214, lng: 18.8286807 },
    { lat: 48.0922275, lng: 18.8267976 },
    { lat: 48.0927314, lng: 18.8272217 },
    { lat: 48.0941983, lng: 18.8271071 },
    { lat: 48.0947385, lng: 18.8268518 },
    { lat: 48.0959842, lng: 18.8268675 },
    { lat: 48.0970304, lng: 18.8267331 },
    { lat: 48.0978972, lng: 18.8261904 },
    { lat: 48.0987557, lng: 18.8251689 },
    { lat: 48.0996563, lng: 18.8249037 },
    { lat: 48.100461, lng: 18.8250664 },
    { lat: 48.1008159, lng: 18.8252953 },
    { lat: 48.1013712, lng: 18.8250484 },
    { lat: 48.1021845, lng: 18.8249032 },
    { lat: 48.1025639, lng: 18.8246752 },
    { lat: 48.1030722, lng: 18.8244744 },
    { lat: 48.1040544, lng: 18.8237889 },
    { lat: 48.1040653, lng: 18.823781 },
    { lat: 48.103862, lng: 18.8227302 },
    { lat: 48.103536, lng: 18.821679 },
    { lat: 48.1034345, lng: 18.8211208 },
    { lat: 48.1028985, lng: 18.8192101 },
    { lat: 48.1026408, lng: 18.8185168 },
    { lat: 48.1017807, lng: 18.817048 },
    { lat: 48.1015876, lng: 18.8165055 },
    { lat: 48.1009049, lng: 18.8131876 },
    { lat: 48.1007351, lng: 18.8125528 },
    { lat: 48.1002466, lng: 18.8117149 },
    { lat: 48.1000269, lng: 18.8111657 },
    { lat: 48.0981042, lng: 18.8078593 },
    { lat: 48.097818, lng: 18.8072403 },
    { lat: 48.0977263, lng: 18.8069375 },
    { lat: 48.0971717, lng: 18.8059805 },
    { lat: 48.0968341, lng: 18.8057172 },
    { lat: 48.0965146, lng: 18.8052214 },
    { lat: 48.0963362, lng: 18.8047881 },
    { lat: 48.096141, lng: 18.8045335 },
    { lat: 48.0960032, lng: 18.8039747 },
    { lat: 48.0956279, lng: 18.8011852 },
    { lat: 48.0955917, lng: 18.8008614 },
    { lat: 48.0956067, lng: 18.7998239 },
    { lat: 48.0950866, lng: 18.7966447 },
    { lat: 48.0954431, lng: 18.7968606 },
    { lat: 48.095707, lng: 18.7958378 },
    { lat: 48.0963612, lng: 18.7958841 },
    { lat: 48.0972931, lng: 18.7958052 },
    { lat: 48.1022687, lng: 18.7938673 },
    { lat: 48.1049918, lng: 18.792408 },
    { lat: 48.1043762, lng: 18.7896471 },
    { lat: 48.1040387, lng: 18.7871272 },
    { lat: 48.1040871, lng: 18.7849545 },
    { lat: 48.1038716, lng: 18.7841896 },
    { lat: 48.103421, lng: 18.7834113 },
    { lat: 48.1031798, lng: 18.7828418 },
    { lat: 48.1031231, lng: 18.7825563 },
    { lat: 48.103089, lng: 18.7817224 },
    { lat: 48.1029637, lng: 18.781232 },
    { lat: 48.1030932, lng: 18.7802746 },
    { lat: 48.1031169, lng: 18.7795503 },
    { lat: 48.1029783, lng: 18.7787461 },
    { lat: 48.1030104, lng: 18.778056 },
    { lat: 48.1031261, lng: 18.7775378 },
    { lat: 48.1032142, lng: 18.7764774 },
    { lat: 48.1033862, lng: 18.7752962 },
    { lat: 48.1036125, lng: 18.7749533 },
    { lat: 48.1036757, lng: 18.7746346 },
    { lat: 48.1036958, lng: 18.7740779 },
    { lat: 48.1039797, lng: 18.772416 },
    { lat: 48.1041675, lng: 18.7704765 },
    { lat: 48.104312, lng: 18.769988 },
    { lat: 48.1045022, lng: 18.7675044 },
    { lat: 48.1047087, lng: 18.7669119 },
    { lat: 48.1048195, lng: 18.7663645 },
    { lat: 48.1050128, lng: 18.7657778 },
    { lat: 48.1051721, lng: 18.7650405 },
    { lat: 48.1052022, lng: 18.7646732 },
    { lat: 48.1057659, lng: 18.7626367 },
    { lat: 48.1063988, lng: 18.7615807 },
    { lat: 48.1066847, lng: 18.7612737 },
    { lat: 48.1069222, lng: 18.7608026 },
    { lat: 48.1069092, lng: 18.7607036 },
    { lat: 48.1069059, lng: 18.7606638 },
  ],
  TekovskéLužany: [
    { lat: 48.1173554, lng: 18.549669 },
    { lat: 48.1184223, lng: 18.5472091 },
    { lat: 48.1201913, lng: 18.5427377 },
    { lat: 48.1207205, lng: 18.5417773 },
    { lat: 48.1217663, lng: 18.5404113 },
    { lat: 48.1238925, lng: 18.5369114 },
    { lat: 48.1203145, lng: 18.530237 },
    { lat: 48.1205602, lng: 18.5299864 },
    { lat: 48.1182544, lng: 18.5263525 },
    { lat: 48.1180697, lng: 18.525929 },
    { lat: 48.1170918, lng: 18.5241586 },
    { lat: 48.1156567, lng: 18.5224425 },
    { lat: 48.114346, lng: 18.5206691 },
    { lat: 48.1143227, lng: 18.5207786 },
    { lat: 48.112653, lng: 18.518535 },
    { lat: 48.111194, lng: 18.5167752 },
    { lat: 48.1113825, lng: 18.516269 },
    { lat: 48.1116183, lng: 18.5151718 },
    { lat: 48.1117413, lng: 18.513134 },
    { lat: 48.1118781, lng: 18.5120621 },
    { lat: 48.1121989, lng: 18.5105823 },
    { lat: 48.1127975, lng: 18.5097621 },
    { lat: 48.1139163, lng: 18.5076636 },
    { lat: 48.1143715, lng: 18.5071251 },
    { lat: 48.1144092, lng: 18.5071755 },
    { lat: 48.1144624, lng: 18.5071145 },
    { lat: 48.1144172, lng: 18.5070552 },
    { lat: 48.1146913, lng: 18.5065673 },
    { lat: 48.1150405, lng: 18.5057842 },
    { lat: 48.1153695, lng: 18.504718 },
    { lat: 48.1154692, lng: 18.5045263 },
    { lat: 48.1155965, lng: 18.5043189 },
    { lat: 48.1150799, lng: 18.5035039 },
    { lat: 48.1145862, lng: 18.5024378 },
    { lat: 48.1161606, lng: 18.4977561 },
    { lat: 48.115388, lng: 18.4968864 },
    { lat: 48.1154971, lng: 18.4957238 },
    { lat: 48.1161603, lng: 18.4937135 },
    { lat: 48.1147735, lng: 18.4921645 },
    { lat: 48.1149464, lng: 18.4912983 },
    { lat: 48.1148045, lng: 18.4906935 },
    { lat: 48.1146835, lng: 18.4898352 },
    { lat: 48.1142883, lng: 18.4883063 },
    { lat: 48.1142069, lng: 18.4871497 },
    { lat: 48.114246, lng: 18.4857417 },
    { lat: 48.1143706, lng: 18.4843537 },
    { lat: 48.1143109, lng: 18.4837907 },
    { lat: 48.114333, lng: 18.4829683 },
    { lat: 48.1142711, lng: 18.4825283 },
    { lat: 48.1141562, lng: 18.4822274 },
    { lat: 48.1136327, lng: 18.4813534 },
    { lat: 48.1134757, lng: 18.4810054 },
    { lat: 48.1129241, lng: 18.4795702 },
    { lat: 48.1125088, lng: 18.4782296 },
    { lat: 48.1110349, lng: 18.4760551 },
    { lat: 48.1099958, lng: 18.4742507 },
    { lat: 48.1099628, lng: 18.4740861 },
    { lat: 48.1101145, lng: 18.4726189 },
    { lat: 48.1102598, lng: 18.4719651 },
    { lat: 48.1098402, lng: 18.4715828 },
    { lat: 48.1086523, lng: 18.4683519 },
    { lat: 48.1085402, lng: 18.4679402 },
    { lat: 48.1087617, lng: 18.4677245 },
    { lat: 48.1086141, lng: 18.4671382 },
    { lat: 48.1090656, lng: 18.4667996 },
    { lat: 48.1093997, lng: 18.4663013 },
    { lat: 48.1085533, lng: 18.464094 },
    { lat: 48.1087682, lng: 18.463315 },
    { lat: 48.1084285, lng: 18.4625451 },
    { lat: 48.108385, lng: 18.462169 },
    { lat: 48.1082807, lng: 18.4617778 },
    { lat: 48.1075422, lng: 18.4608939 },
    { lat: 48.1075765, lng: 18.460663 },
    { lat: 48.106306, lng: 18.459213 },
    { lat: 48.106277, lng: 18.459228 },
    { lat: 48.106101, lng: 18.459463 },
    { lat: 48.106012, lng: 18.459549 },
    { lat: 48.105959, lng: 18.459592 },
    { lat: 48.105842, lng: 18.459637 },
    { lat: 48.105724, lng: 18.459687 },
    { lat: 48.105544, lng: 18.459791 },
    { lat: 48.105308, lng: 18.459869 },
    { lat: 48.105096, lng: 18.459985 },
    { lat: 48.104954, lng: 18.460069 },
    { lat: 48.104849, lng: 18.460127 },
    { lat: 48.104724, lng: 18.460209 },
    { lat: 48.104515, lng: 18.460324 },
    { lat: 48.10425, lng: 18.460438 },
    { lat: 48.104101, lng: 18.460489 },
    { lat: 48.103902, lng: 18.460548 },
    { lat: 48.103483, lng: 18.460656 },
    { lat: 48.103335, lng: 18.460729 },
    { lat: 48.103178, lng: 18.460722 },
    { lat: 48.10308, lng: 18.460722 },
    { lat: 48.10288, lng: 18.460732 },
    { lat: 48.102822, lng: 18.460741 },
    { lat: 48.102604, lng: 18.460784 },
    { lat: 48.102513, lng: 18.460837 },
    { lat: 48.102345, lng: 18.460821 },
    { lat: 48.10218, lng: 18.46081 },
    { lat: 48.10214, lng: 18.460786 },
    { lat: 48.101987, lng: 18.460814 },
    { lat: 48.101919, lng: 18.460801 },
    { lat: 48.101876, lng: 18.460808 },
    { lat: 48.101712, lng: 18.460886 },
    { lat: 48.101504, lng: 18.461015 },
    { lat: 48.101374, lng: 18.461083 },
    { lat: 48.101338, lng: 18.461091 },
    { lat: 48.101199, lng: 18.461195 },
    { lat: 48.101039, lng: 18.461332 },
    { lat: 48.100858, lng: 18.461387 },
    { lat: 48.100768, lng: 18.461441 },
    { lat: 48.100607, lng: 18.461523 },
    { lat: 48.100371, lng: 18.461637 },
    { lat: 48.100308, lng: 18.461673 },
    { lat: 48.10028, lng: 18.461705 },
    { lat: 48.100213, lng: 18.461784 },
    { lat: 48.100189, lng: 18.461812 },
    { lat: 48.1003495, lng: 18.4624326 },
    { lat: 48.1005595, lng: 18.4629623 },
    { lat: 48.1008901, lng: 18.4635871 },
    { lat: 48.1010023, lng: 18.4639825 },
    { lat: 48.1006937, lng: 18.4647762 },
    { lat: 48.1003145, lng: 18.466169 },
    { lat: 48.1001049, lng: 18.4665551 },
    { lat: 48.10005, lng: 18.4667656 },
    { lat: 48.1000509, lng: 18.4669059 },
    { lat: 48.1002588, lng: 18.4676991 },
    { lat: 48.1005062, lng: 18.4695007 },
    { lat: 48.1004515, lng: 18.4697365 },
    { lat: 48.1002853, lng: 18.4700487 },
    { lat: 48.1001755, lng: 18.4706105 },
    { lat: 48.0999944, lng: 18.4710628 },
    { lat: 48.0997306, lng: 18.4724836 },
    { lat: 48.0997201, lng: 18.4728206 },
    { lat: 48.0997956, lng: 18.4734866 },
    { lat: 48.09975, lng: 18.4742041 },
    { lat: 48.101005, lng: 18.4865656 },
    { lat: 48.1009614, lng: 18.486732 },
    { lat: 48.1023466, lng: 18.490156 },
    { lat: 48.1024014, lng: 18.4909994 },
    { lat: 48.0987737, lng: 18.4933573 },
    { lat: 48.0973027, lng: 18.4948985 },
    { lat: 48.0965407, lng: 18.4957636 },
    { lat: 48.0960519, lng: 18.4965677 },
    { lat: 48.0959665, lng: 18.4970811 },
    { lat: 48.095438, lng: 18.4984098 },
    { lat: 48.0950363, lng: 18.4987952 },
    { lat: 48.0926571, lng: 18.492218 },
    { lat: 48.0876436, lng: 18.4973031 },
    { lat: 48.0845005, lng: 18.5005891 },
    { lat: 48.0844558, lng: 18.5006355 },
    { lat: 48.0837347, lng: 18.4995574 },
    { lat: 48.0812711, lng: 18.4957649 },
    { lat: 48.0802694, lng: 18.493694 },
    { lat: 48.0790701, lng: 18.494504 },
    { lat: 48.0783508, lng: 18.4950996 },
    { lat: 48.0775749, lng: 18.4961986 },
    { lat: 48.0770029, lng: 18.4976797 },
    { lat: 48.0765517, lng: 18.498228 },
    { lat: 48.0738176, lng: 18.5010728 },
    { lat: 48.0711158, lng: 18.5040791 },
    { lat: 48.0700504, lng: 18.5050023 },
    { lat: 48.0695579, lng: 18.5052551 },
    { lat: 48.0694888, lng: 18.5052918 },
    { lat: 48.0676812, lng: 18.5071101 },
    { lat: 48.0646878, lng: 18.5102684 },
    { lat: 48.0627404, lng: 18.5131901 },
    { lat: 48.0634602, lng: 18.5144518 },
    { lat: 48.0642554, lng: 18.5159984 },
    { lat: 48.0664384, lng: 18.5207629 },
    { lat: 48.0670848, lng: 18.5223305 },
    { lat: 48.0678505, lng: 18.523797 },
    { lat: 48.0698711, lng: 18.5263663 },
    { lat: 48.071363, lng: 18.5281494 },
    { lat: 48.0735708, lng: 18.530418 },
    { lat: 48.0742416, lng: 18.5308786 },
    { lat: 48.0755566, lng: 18.5322217 },
    { lat: 48.0784172, lng: 18.5358505 },
    { lat: 48.078161, lng: 18.5363995 },
    { lat: 48.0781382, lng: 18.5368637 },
    { lat: 48.0801746, lng: 18.5391346 },
    { lat: 48.0794165, lng: 18.5408127 },
    { lat: 48.0812785, lng: 18.5430094 },
    { lat: 48.0775662, lng: 18.5454035 },
    { lat: 48.0768643, lng: 18.5457881 },
    { lat: 48.0760127, lng: 18.5464007 },
    { lat: 48.069812, lng: 18.5503895 },
    { lat: 48.0663891, lng: 18.5489674 },
    { lat: 48.0633562, lng: 18.5463582 },
    { lat: 48.0600301, lng: 18.5421143 },
    { lat: 48.0574436, lng: 18.5389943 },
    { lat: 48.052282, lng: 18.548291 },
    { lat: 48.048597, lng: 18.5506662 },
    { lat: 48.0459179, lng: 18.5473168 },
    { lat: 48.0457901, lng: 18.5473304 },
    { lat: 48.0452107, lng: 18.5482327 },
    { lat: 48.0438944, lng: 18.5503047 },
    { lat: 48.0439274, lng: 18.5503888 },
    { lat: 48.0443669, lng: 18.5517096 },
    { lat: 48.0444216, lng: 18.5523751 },
    { lat: 48.0446819, lng: 18.5531483 },
    { lat: 48.0452517, lng: 18.5540718 },
    { lat: 48.046143, lng: 18.555344 },
    { lat: 48.0475596, lng: 18.5570373 },
    { lat: 48.0489461, lng: 18.559027 },
    { lat: 48.0510288, lng: 18.5626205 },
    { lat: 48.0532887, lng: 18.5660724 },
    { lat: 48.0555467, lng: 18.5689381 },
    { lat: 48.0560845, lng: 18.5697939 },
    { lat: 48.0568174, lng: 18.5717193 },
    { lat: 48.056799, lng: 18.571755 },
    { lat: 48.0587809, lng: 18.5739075 },
    { lat: 48.0595354, lng: 18.5749329 },
    { lat: 48.0603121, lng: 18.5758278 },
    { lat: 48.0613439, lng: 18.5772404 },
    { lat: 48.0623413, lng: 18.5784345 },
    { lat: 48.0641521, lng: 18.5808692 },
    { lat: 48.0650942, lng: 18.5824355 },
    { lat: 48.0679905, lng: 18.5868508 },
    { lat: 48.0689662, lng: 18.5880848 },
    { lat: 48.0695651, lng: 18.5889294 },
    { lat: 48.0716961, lng: 18.5915088 },
    { lat: 48.0740825, lng: 18.5888859 },
    { lat: 48.0755384, lng: 18.5878911 },
    { lat: 48.0762567, lng: 18.5865111 },
    { lat: 48.076326, lng: 18.5862914 },
    { lat: 48.0768465, lng: 18.5859291 },
    { lat: 48.0769951, lng: 18.5857004 },
    { lat: 48.0771486, lng: 18.585353 },
    { lat: 48.0770586, lng: 18.5847799 },
    { lat: 48.0771281, lng: 18.5846254 },
    { lat: 48.0773524, lng: 18.5845433 },
    { lat: 48.0774307, lng: 18.5840957 },
    { lat: 48.0774252, lng: 18.5837112 },
    { lat: 48.077541, lng: 18.5832703 },
    { lat: 48.0772373, lng: 18.5826284 },
    { lat: 48.0772393, lng: 18.5822941 },
    { lat: 48.0773945, lng: 18.5819126 },
    { lat: 48.0776638, lng: 18.5816606 },
    { lat: 48.0777575, lng: 18.5814993 },
    { lat: 48.0785144, lng: 18.5810897 },
    { lat: 48.0786178, lng: 18.5807734 },
    { lat: 48.0789536, lng: 18.5811377 },
    { lat: 48.0794178, lng: 18.5815166 },
    { lat: 48.0805223, lng: 18.5828399 },
    { lat: 48.085129, lng: 18.5886225 },
    { lat: 48.0878346, lng: 18.5921807 },
    { lat: 48.0879803, lng: 18.5923481 },
    { lat: 48.0883879, lng: 18.5916966 },
    { lat: 48.089756, lng: 18.5894715 },
    { lat: 48.0918053, lng: 18.591876 },
    { lat: 48.0923613, lng: 18.5922876 },
    { lat: 48.0930782, lng: 18.5901764 },
    { lat: 48.0953953, lng: 18.5861847 },
    { lat: 48.096261, lng: 18.5849785 },
    { lat: 48.0963895, lng: 18.5849991 },
    { lat: 48.0983494, lng: 18.5870904 },
    { lat: 48.099107, lng: 18.5881309 },
    { lat: 48.0993193, lng: 18.5877264 },
    { lat: 48.0993606, lng: 18.5869571 },
    { lat: 48.0994485, lng: 18.5867893 },
    { lat: 48.0999204, lng: 18.586304 },
    { lat: 48.1005712, lng: 18.586202 },
    { lat: 48.1007446, lng: 18.5862559 },
    { lat: 48.1008645, lng: 18.5862233 },
    { lat: 48.1012731, lng: 18.5858163 },
    { lat: 48.1014605, lng: 18.5854487 },
    { lat: 48.1017522, lng: 18.5849868 },
    { lat: 48.1027758, lng: 18.58382 },
    { lat: 48.1052533, lng: 18.5830903 },
    { lat: 48.1054847, lng: 18.582724 },
    { lat: 48.1061685, lng: 18.5820461 },
    { lat: 48.1065908, lng: 18.5819109 },
    { lat: 48.1071265, lng: 18.581417 },
    { lat: 48.1073567, lng: 18.5811277 },
    { lat: 48.1075559, lng: 18.5807514 },
    { lat: 48.107601, lng: 18.5806097 },
    { lat: 48.1075769, lng: 18.5803716 },
    { lat: 48.1076313, lng: 18.5801751 },
    { lat: 48.107715, lng: 18.5801137 },
    { lat: 48.1078252, lng: 18.58015 },
    { lat: 48.1079516, lng: 18.5805199 },
    { lat: 48.1081757, lng: 18.5805977 },
    { lat: 48.1087241, lng: 18.5803747 },
    { lat: 48.1097567, lng: 18.5799498 },
    { lat: 48.1099511, lng: 18.5798569 },
    { lat: 48.1101267, lng: 18.5797659 },
    { lat: 48.1102473, lng: 18.5795987 },
    { lat: 48.1107222, lng: 18.5777674 },
    { lat: 48.1111451, lng: 18.5773373 },
    { lat: 48.1160033, lng: 18.5731444 },
    { lat: 48.1180658, lng: 18.5739968 },
    { lat: 48.1186967, lng: 18.5732221 },
    { lat: 48.1192239, lng: 18.5722856 },
    { lat: 48.1195357, lng: 18.5726223 },
    { lat: 48.1198057, lng: 18.5727743 },
    { lat: 48.120532, lng: 18.5735736 },
    { lat: 48.1216468, lng: 18.5745664 },
    { lat: 48.1221135, lng: 18.5751575 },
    { lat: 48.1221212, lng: 18.5752338 },
    { lat: 48.1241196, lng: 18.571343 },
    { lat: 48.1257935, lng: 18.568428 },
    { lat: 48.1283428, lng: 18.5631493 },
    { lat: 48.1283686, lng: 18.5630632 },
    { lat: 48.1285764, lng: 18.5624534 },
    { lat: 48.1286232, lng: 18.5621574 },
    { lat: 48.1281487, lng: 18.5616097 },
    { lat: 48.1278243, lng: 18.5611249 },
    { lat: 48.1276171, lng: 18.5606807 },
    { lat: 48.1270118, lng: 18.5596841 },
    { lat: 48.1250842, lng: 18.5570595 },
    { lat: 48.124419, lng: 18.5563704 },
    { lat: 48.1232611, lng: 18.5555985 },
    { lat: 48.1210795, lng: 18.5534672 },
    { lat: 48.1176535, lng: 18.5499603 },
    { lat: 48.1173554, lng: 18.549669 },
  ],
  Bátovce: [
    { lat: 48.26807, lng: 18.840583 },
    { lat: 48.268517, lng: 18.840504 },
    { lat: 48.268569, lng: 18.840495 },
    { lat: 48.26913, lng: 18.840422 },
    { lat: 48.26944, lng: 18.840454 },
    { lat: 48.269716, lng: 18.840469 },
    { lat: 48.269747, lng: 18.840481 },
    { lat: 48.26978, lng: 18.84049 },
    { lat: 48.270705, lng: 18.84076 },
    { lat: 48.271152, lng: 18.840968 },
    { lat: 48.271918, lng: 18.841149 },
    { lat: 48.272124, lng: 18.8412 },
    { lat: 48.272948, lng: 18.842335 },
    { lat: 48.273416, lng: 18.842971 },
    { lat: 48.274019, lng: 18.843648 },
    { lat: 48.27492, lng: 18.844026 },
    { lat: 48.274916, lng: 18.844061 },
    { lat: 48.27501, lng: 18.844087 },
    { lat: 48.275291, lng: 18.844106 },
    { lat: 48.275793, lng: 18.843911 },
    { lat: 48.276177, lng: 18.843754 },
    { lat: 48.27649, lng: 18.843529 },
    { lat: 48.276651, lng: 18.843433 },
    { lat: 48.276831, lng: 18.843358 },
    { lat: 48.277233, lng: 18.843152 },
    { lat: 48.277379, lng: 18.843168 },
    { lat: 48.277439, lng: 18.843159 },
    { lat: 48.277598, lng: 18.843103 },
    { lat: 48.277821, lng: 18.843065 },
    { lat: 48.278292, lng: 18.84318 },
    { lat: 48.278839, lng: 18.842982 },
    { lat: 48.278822, lng: 18.842865 },
    { lat: 48.278937, lng: 18.842734 },
    { lat: 48.279041, lng: 18.84265 },
    { lat: 48.279126, lng: 18.84257 },
    { lat: 48.27921, lng: 18.842442 },
    { lat: 48.27932, lng: 18.842405 },
    { lat: 48.279581, lng: 18.842177 },
    { lat: 48.279739, lng: 18.842041 },
    { lat: 48.279826, lng: 18.841982 },
    { lat: 48.279867, lng: 18.842096 },
    { lat: 48.27995, lng: 18.842101 },
    { lat: 48.280019, lng: 18.842029 },
    { lat: 48.280217, lng: 18.841954 },
    { lat: 48.280258, lng: 18.841966 },
    { lat: 48.280365, lng: 18.841996 },
    { lat: 48.280457, lng: 18.841762 },
    { lat: 48.280533, lng: 18.841591 },
    { lat: 48.28049, lng: 18.84139 },
    { lat: 48.280498, lng: 18.841348 },
    { lat: 48.280592, lng: 18.841168 },
    { lat: 48.280706, lng: 18.84121 },
    { lat: 48.280792, lng: 18.84116 },
    { lat: 48.280801, lng: 18.841118 },
    { lat: 48.280798, lng: 18.840906 },
    { lat: 48.280927, lng: 18.840834 },
    { lat: 48.28101, lng: 18.840841 },
    { lat: 48.281079, lng: 18.840804 },
    { lat: 48.281175, lng: 18.840604 },
    { lat: 48.281304, lng: 18.840601 },
    { lat: 48.281482, lng: 18.840534 },
    { lat: 48.281519, lng: 18.840472 },
    { lat: 48.281596, lng: 18.840494 },
    { lat: 48.281645, lng: 18.840504 },
    { lat: 48.281787, lng: 18.840435 },
    { lat: 48.281829, lng: 18.840477 },
    { lat: 48.281939, lng: 18.84041 },
    { lat: 48.282037, lng: 18.840457 },
    { lat: 48.282092, lng: 18.840419 },
    { lat: 48.282204, lng: 18.840249 },
    { lat: 48.282287, lng: 18.840306 },
    { lat: 48.282364, lng: 18.840316 },
    { lat: 48.282447, lng: 18.84039 },
    { lat: 48.282492, lng: 18.840471 },
    { lat: 48.282528, lng: 18.840491 },
    { lat: 48.282627, lng: 18.840446 },
    { lat: 48.282691, lng: 18.840419 },
    { lat: 48.282764, lng: 18.840283 },
    { lat: 48.282818, lng: 18.840283 },
    { lat: 48.282867, lng: 18.840349 },
    { lat: 48.282953, lng: 18.840389 },
    { lat: 48.283027, lng: 18.840503 },
    { lat: 48.283086, lng: 18.840663 },
    { lat: 48.283091, lng: 18.840649 },
    { lat: 48.283137, lng: 18.84052 },
    { lat: 48.283342, lng: 18.840443 },
    { lat: 48.283512, lng: 18.840544 },
    { lat: 48.28368, lng: 18.840581 },
    { lat: 48.283786, lng: 18.84066 },
    { lat: 48.2839, lng: 18.840699 },
    { lat: 48.283921, lng: 18.840709 },
    { lat: 48.284014, lng: 18.840578 },
    { lat: 48.284102, lng: 18.840592 },
    { lat: 48.284177, lng: 18.840464 },
    { lat: 48.284279, lng: 18.840523 },
    { lat: 48.284319, lng: 18.840506 },
    { lat: 48.28446, lng: 18.84033 },
    { lat: 48.284639, lng: 18.840404 },
    { lat: 48.284849, lng: 18.840342 },
    { lat: 48.285119, lng: 18.840269 },
    { lat: 48.285136, lng: 18.840242 },
    { lat: 48.285139, lng: 18.84022 },
    { lat: 48.285104, lng: 18.840203 },
    { lat: 48.28508, lng: 18.84019 },
    { lat: 48.285059, lng: 18.84016 },
    { lat: 48.285072, lng: 18.840136 },
    { lat: 48.285106, lng: 18.840091 },
    { lat: 48.285208, lng: 18.840024 },
    { lat: 48.285277, lng: 18.840069 },
    { lat: 48.285365, lng: 18.839992 },
    { lat: 48.285406, lng: 18.839997 },
    { lat: 48.285426, lng: 18.839928 },
    { lat: 48.2855, lng: 18.839796 },
    { lat: 48.285606, lng: 18.839823 },
    { lat: 48.285671, lng: 18.839885 },
    { lat: 48.285796, lng: 18.840065 },
    { lat: 48.285838, lng: 18.840233 },
    { lat: 48.285847, lng: 18.840401 },
    { lat: 48.28592, lng: 18.840508 },
    { lat: 48.285935, lng: 18.84056 },
    { lat: 48.285945, lng: 18.840678 },
    { lat: 48.285933, lng: 18.840772 },
    { lat: 48.28603, lng: 18.840891 },
    { lat: 48.286057, lng: 18.841007 },
    { lat: 48.28616, lng: 18.84116 },
    { lat: 48.286302, lng: 18.841293 },
    { lat: 48.28633, lng: 18.84135 },
    { lat: 48.286457, lng: 18.841456 },
    { lat: 48.286505, lng: 18.841466 },
    { lat: 48.286525, lng: 18.84151 },
    { lat: 48.286614, lng: 18.841503 },
    { lat: 48.28678, lng: 18.841604 },
    { lat: 48.2869, lng: 18.841683 },
    { lat: 48.286952, lng: 18.841673 },
    { lat: 48.287049, lng: 18.8417 },
    { lat: 48.287123, lng: 18.841633 },
    { lat: 48.287273, lng: 18.841704 },
    { lat: 48.287311, lng: 18.841718 },
    { lat: 48.287345, lng: 18.841703 },
    { lat: 48.287419, lng: 18.841766 },
    { lat: 48.287526, lng: 18.841783 },
    { lat: 48.287564, lng: 18.841656 },
    { lat: 48.287618, lng: 18.841589 },
    { lat: 48.287737, lng: 18.841626 },
    { lat: 48.28779, lng: 18.841586 },
    { lat: 48.287948, lng: 18.841638 },
    { lat: 48.28802, lng: 18.841687 },
    { lat: 48.288117, lng: 18.841665 },
    { lat: 48.288187, lng: 18.841617 },
    { lat: 48.288351, lng: 18.841637 },
    { lat: 48.288404, lng: 18.841653 },
    { lat: 48.288484, lng: 18.841584 },
    { lat: 48.288487, lng: 18.841597 },
    { lat: 48.288491, lng: 18.841666 },
    { lat: 48.288467, lng: 18.841846 },
    { lat: 48.288517, lng: 18.842039 },
    { lat: 48.288854, lng: 18.84207 },
    { lat: 48.289165, lng: 18.842913 },
    { lat: 48.289208, lng: 18.843051 },
    { lat: 48.289804, lng: 18.844036 },
    { lat: 48.290115, lng: 18.844539 },
    { lat: 48.290469, lng: 18.844658 },
    { lat: 48.291664, lng: 18.844534 },
    { lat: 48.292298, lng: 18.844321 },
    { lat: 48.292664, lng: 18.844047 },
    { lat: 48.292976, lng: 18.843791 },
    { lat: 48.29364, lng: 18.843748 },
    { lat: 48.293828, lng: 18.843687 },
    { lat: 48.294079, lng: 18.843689 },
    { lat: 48.294815, lng: 18.844219 },
    { lat: 48.295085, lng: 18.844829 },
    { lat: 48.295306, lng: 18.844885 },
    { lat: 48.295863, lng: 18.844615 },
    { lat: 48.296363, lng: 18.844451 },
    { lat: 48.297075, lng: 18.844198 },
    { lat: 48.297144, lng: 18.844154 },
    { lat: 48.2964577, lng: 18.8427245 },
    { lat: 48.2963466, lng: 18.8420477 },
    { lat: 48.2963116, lng: 18.8412702 },
    { lat: 48.2959873, lng: 18.8395982 },
    { lat: 48.2960674, lng: 18.8393182 },
    { lat: 48.2968518, lng: 18.8385977 },
    { lat: 48.2969652, lng: 18.8380277 },
    { lat: 48.296943, lng: 18.8376276 },
    { lat: 48.2968725, lng: 18.8374004 },
    { lat: 48.2966118, lng: 18.836857 },
    { lat: 48.2962694, lng: 18.8363296 },
    { lat: 48.296066, lng: 18.8355008 },
    { lat: 48.2960594, lng: 18.8348372 },
    { lat: 48.2959699, lng: 18.8344865 },
    { lat: 48.2959584, lng: 18.8341135 },
    { lat: 48.295969, lng: 18.8336268 },
    { lat: 48.2965327, lng: 18.8328946 },
    { lat: 48.2970507, lng: 18.8324923 },
    { lat: 48.2971019, lng: 18.8320085 },
    { lat: 48.2971609, lng: 18.8319718 },
    { lat: 48.297258, lng: 18.8313491 },
    { lat: 48.2972725, lng: 18.8299708 },
    { lat: 48.2971104, lng: 18.8295023 },
    { lat: 48.2966644, lng: 18.827663 },
    { lat: 48.2966583, lng: 18.8273127 },
    { lat: 48.2967737, lng: 18.8267598 },
    { lat: 48.2967654, lng: 18.8261686 },
    { lat: 48.2967177, lng: 18.8260567 },
    { lat: 48.294181, lng: 18.8237499 },
    { lat: 48.2937701, lng: 18.8233139 },
    { lat: 48.2935488, lng: 18.8229196 },
    { lat: 48.2934172, lng: 18.8224514 },
    { lat: 48.2934227, lng: 18.8220219 },
    { lat: 48.2938924, lng: 18.8210426 },
    { lat: 48.2943146, lng: 18.819656 },
    { lat: 48.2944465, lng: 18.8188822 },
    { lat: 48.2945069, lng: 18.8181428 },
    { lat: 48.2944972, lng: 18.81633 },
    { lat: 48.2946396, lng: 18.8148762 },
    { lat: 48.2945506, lng: 18.8139748 },
    { lat: 48.294601, lng: 18.8137103 },
    { lat: 48.2945663, lng: 18.8135465 },
    { lat: 48.2946567, lng: 18.8131615 },
    { lat: 48.2946094, lng: 18.812802 },
    { lat: 48.2945996, lng: 18.8117974 },
    { lat: 48.2946457, lng: 18.8110876 },
    { lat: 48.2946227, lng: 18.8109508 },
    { lat: 48.2944578, lng: 18.8107546 },
    { lat: 48.2941869, lng: 18.810663 },
    { lat: 48.2927115, lng: 18.8096917 },
    { lat: 48.2924757, lng: 18.8080393 },
    { lat: 48.2922065, lng: 18.8072131 },
    { lat: 48.290984, lng: 18.8050481 },
    { lat: 48.2907744, lng: 18.8044521 },
    { lat: 48.2905176, lng: 18.8027211 },
    { lat: 48.2904387, lng: 18.8018473 },
    { lat: 48.2905111, lng: 18.7989839 },
    { lat: 48.2903102, lng: 18.7949148 },
    { lat: 48.2903651, lng: 18.7935407 },
    { lat: 48.290347, lng: 18.7930269 },
    { lat: 48.2905205, lng: 18.7923392 },
    { lat: 48.2905903, lng: 18.7922113 },
    { lat: 48.2907206, lng: 18.7916034 },
    { lat: 48.290548, lng: 18.79141 },
    { lat: 48.2904696, lng: 18.7908874 },
    { lat: 48.2904432, lng: 18.7898087 },
    { lat: 48.2900487, lng: 18.787933 },
    { lat: 48.2895793, lng: 18.7863063 },
    { lat: 48.2896776, lng: 18.7859674 },
    { lat: 48.2895682, lng: 18.7839194 },
    { lat: 48.2898009, lng: 18.7836496 },
    { lat: 48.2901802, lng: 18.782737 },
    { lat: 48.2907024, lng: 18.7802631 },
    { lat: 48.2907729, lng: 18.780083 },
    { lat: 48.2908059, lng: 18.7794675 },
    { lat: 48.2908521, lng: 18.7793859 },
    { lat: 48.2908244, lng: 18.7788389 },
    { lat: 48.2908515, lng: 18.7784766 },
    { lat: 48.2907226, lng: 18.778 },
    { lat: 48.290703, lng: 18.7768617 },
    { lat: 48.2906508, lng: 18.7765375 },
    { lat: 48.2907454, lng: 18.7760868 },
    { lat: 48.2908115, lng: 18.7749075 },
    { lat: 48.2910628, lng: 18.774265 },
    { lat: 48.2909457, lng: 18.7735898 },
    { lat: 48.2904501, lng: 18.7726423 },
    { lat: 48.2900506, lng: 18.7716143 },
    { lat: 48.2899793, lng: 18.7712053 },
    { lat: 48.2900601, lng: 18.7708565 },
    { lat: 48.2901084, lng: 18.7700242 },
    { lat: 48.2899883, lng: 18.7692017 },
    { lat: 48.2899351, lng: 18.769179 },
    { lat: 48.2898457, lng: 18.7687048 },
    { lat: 48.2902447, lng: 18.7673365 },
    { lat: 48.2902686, lng: 18.7671279 },
    { lat: 48.2903082, lng: 18.7671306 },
    { lat: 48.2903058, lng: 18.7668944 },
    { lat: 48.2904104, lng: 18.7667172 },
    { lat: 48.2903849, lng: 18.7662596 },
    { lat: 48.2904521, lng: 18.7658872 },
    { lat: 48.2904164, lng: 18.7656588 },
    { lat: 48.2904662, lng: 18.7654917 },
    { lat: 48.2903939, lng: 18.7654089 },
    { lat: 48.290409, lng: 18.7653498 },
    { lat: 48.2904804, lng: 18.76536 },
    { lat: 48.2905673, lng: 18.7654875 },
    { lat: 48.2908587, lng: 18.7644718 },
    { lat: 48.2909279, lng: 18.7643319 },
    { lat: 48.2910295, lng: 18.7642911 },
    { lat: 48.2914741, lng: 18.7630635 },
    { lat: 48.2919897, lng: 18.7614318 },
    { lat: 48.2923002, lng: 18.7600144 },
    { lat: 48.2925163, lng: 18.7585597 },
    { lat: 48.2927373, lng: 18.7585376 },
    { lat: 48.2932311, lng: 18.7586378 },
    { lat: 48.2939086, lng: 18.7589934 },
    { lat: 48.2951876, lng: 18.760441 },
    { lat: 48.2961493, lng: 18.7610752 },
    { lat: 48.2965759, lng: 18.7615489 },
    { lat: 48.2975625, lng: 18.7624386 },
    { lat: 48.2981663, lng: 18.763125 },
    { lat: 48.3004392, lng: 18.7632212 },
    { lat: 48.3003325, lng: 18.7638651 },
    { lat: 48.3023736, lng: 18.7646276 },
    { lat: 48.3050256, lng: 18.765875 },
    { lat: 48.3053235, lng: 18.7659621 },
    { lat: 48.3055822, lng: 18.7661843 },
    { lat: 48.3074243, lng: 18.7671509 },
    { lat: 48.3078527, lng: 18.7674216 },
    { lat: 48.3083416, lng: 18.7678576 },
    { lat: 48.3083633, lng: 18.767929 },
    { lat: 48.3089601, lng: 18.7688053 },
    { lat: 48.309336, lng: 18.7698566 },
    { lat: 48.3099843, lng: 18.7695134 },
    { lat: 48.3106694, lng: 18.7708443 },
    { lat: 48.3108155, lng: 18.7709612 },
    { lat: 48.3110933, lng: 18.7713731 },
    { lat: 48.3115882, lng: 18.7717766 },
    { lat: 48.3116459, lng: 18.7719281 },
    { lat: 48.3116493, lng: 18.7720949 },
    { lat: 48.3119678, lng: 18.7725534 },
    { lat: 48.3125188, lng: 18.7727131 },
    { lat: 48.3126985, lng: 18.7730857 },
    { lat: 48.3128067, lng: 18.7731696 },
    { lat: 48.3129669, lng: 18.7730439 },
    { lat: 48.3129861, lng: 18.7730845 },
    { lat: 48.3134635, lng: 18.772751 },
    { lat: 48.314266, lng: 18.7723161 },
    { lat: 48.3142061, lng: 18.7706312 },
    { lat: 48.3140877, lng: 18.769588 },
    { lat: 48.3141676, lng: 18.7686677 },
    { lat: 48.3140538, lng: 18.7685016 },
    { lat: 48.3139845, lng: 18.7680847 },
    { lat: 48.3133971, lng: 18.7668338 },
    { lat: 48.3130807, lng: 18.7662671 },
    { lat: 48.3124961, lng: 18.7656225 },
    { lat: 48.3123675, lng: 18.7652782 },
    { lat: 48.3121473, lng: 18.7649537 },
    { lat: 48.31208, lng: 18.7646652 },
    { lat: 48.3120285, lng: 18.7625024 },
    { lat: 48.3120508, lng: 18.7617948 },
    { lat: 48.312154, lng: 18.7604217 },
    { lat: 48.3123838, lng: 18.7595904 },
    { lat: 48.3122659, lng: 18.7589618 },
    { lat: 48.3122062, lng: 18.7567724 },
    { lat: 48.312147, lng: 18.7564227 },
    { lat: 48.3115122, lng: 18.7546597 },
    { lat: 48.3108692, lng: 18.7530674 },
    { lat: 48.3107616, lng: 18.7528422 },
    { lat: 48.3106805, lng: 18.7528026 },
    { lat: 48.309318, lng: 18.7499568 },
    { lat: 48.3082328, lng: 18.7471305 },
    { lat: 48.3085573, lng: 18.7466368 },
    { lat: 48.3085091, lng: 18.7464063 },
    { lat: 48.308332, lng: 18.7460668 },
    { lat: 48.30872, lng: 18.7459408 },
    { lat: 48.3089826, lng: 18.745706 },
    { lat: 48.3091762, lng: 18.7459741 },
    { lat: 48.3092819, lng: 18.7457975 },
    { lat: 48.3091495, lng: 18.7441111 },
    { lat: 48.3093101, lng: 18.7436738 },
    { lat: 48.3093074, lng: 18.7432852 },
    { lat: 48.3092145, lng: 18.7430008 },
    { lat: 48.3091262, lng: 18.7429972 },
    { lat: 48.3091336, lng: 18.7431773 },
    { lat: 48.3090453, lng: 18.7434894 },
    { lat: 48.3089025, lng: 18.7436669 },
    { lat: 48.3084332, lng: 18.7440046 },
    { lat: 48.3078738, lng: 18.7441951 },
    { lat: 48.3076154, lng: 18.7441718 },
    { lat: 48.3069788, lng: 18.7435245 },
    { lat: 48.306888, lng: 18.7433635 },
    { lat: 48.3067356, lng: 18.7432985 },
    { lat: 48.3063009, lng: 18.7433317 },
    { lat: 48.3053038, lng: 18.7438189 },
    { lat: 48.3050257, lng: 18.7417278 },
    { lat: 48.3048106, lng: 18.7410358 },
    { lat: 48.3081416, lng: 18.7375923 },
    { lat: 48.3077616, lng: 18.7368294 },
    { lat: 48.3085557, lng: 18.7358809 },
    { lat: 48.3089558, lng: 18.7352198 },
    { lat: 48.308236, lng: 18.7343679 },
    { lat: 48.3084115, lng: 18.7341279 },
    { lat: 48.3083888, lng: 18.7339929 },
    { lat: 48.3085143, lng: 18.7338514 },
    { lat: 48.3087036, lng: 18.7333855 },
    { lat: 48.3088868, lng: 18.7330986 },
    { lat: 48.30896, lng: 18.7326502 },
    { lat: 48.3091083, lng: 18.7325984 },
    { lat: 48.3091763, lng: 18.732478 },
    { lat: 48.3093582, lng: 18.7318277 },
    { lat: 48.3095088, lng: 18.7316038 },
    { lat: 48.3095608, lng: 18.7315636 },
    { lat: 48.3096421, lng: 18.7316077 },
    { lat: 48.3096995, lng: 18.7315009 },
    { lat: 48.3097365, lng: 18.7312237 },
    { lat: 48.3098712, lng: 18.7309352 },
    { lat: 48.310317, lng: 18.7305168 },
    { lat: 48.3104894, lng: 18.7301554 },
    { lat: 48.3108943, lng: 18.7297962 },
    { lat: 48.3109924, lng: 18.7295616 },
    { lat: 48.3111373, lng: 18.7294029 },
    { lat: 48.3102556, lng: 18.728158 },
    { lat: 48.3096405, lng: 18.7274332 },
    { lat: 48.3103148, lng: 18.726331 },
    { lat: 48.3116137, lng: 18.7245062 },
    { lat: 48.3117523, lng: 18.7242951 },
    { lat: 48.3113849, lng: 18.7235802 },
    { lat: 48.3110248, lng: 18.7239979 },
    { lat: 48.3094773, lng: 18.7205695 },
    { lat: 48.309451, lng: 18.7204976 },
    { lat: 48.3089781, lng: 18.7209369 },
    { lat: 48.3086398, lng: 18.7214657 },
    { lat: 48.3078012, lng: 18.719977 },
    { lat: 48.3075401, lng: 18.7193934 },
    { lat: 48.3058952, lng: 18.7167039 },
    { lat: 48.3051383, lng: 18.715201 },
    { lat: 48.3048412, lng: 18.7144538 },
    { lat: 48.3043969, lng: 18.7132556 },
    { lat: 48.3034982, lng: 18.7104939 },
    { lat: 48.3025137, lng: 18.7080922 },
    { lat: 48.3020058, lng: 18.7062507 },
    { lat: 48.30191, lng: 18.7056834 },
    { lat: 48.3018699, lng: 18.7051023 },
    { lat: 48.3017919, lng: 18.7030983 },
    { lat: 48.3018758, lng: 18.7010645 },
    { lat: 48.3021682, lng: 18.7009641 },
    { lat: 48.3019118, lng: 18.6992079 },
    { lat: 48.301526, lng: 18.6989993 },
    { lat: 48.3012461, lng: 18.6996681 },
    { lat: 48.3002631, lng: 18.7000556 },
    { lat: 48.3001974, lng: 18.6998832 },
    { lat: 48.2995712, lng: 18.7006574 },
    { lat: 48.2993993, lng: 18.7010033 },
    { lat: 48.299426, lng: 18.7010833 },
    { lat: 48.2991403, lng: 18.7014322 },
    { lat: 48.2985693, lng: 18.7025437 },
    { lat: 48.298381, lng: 18.702713 },
    { lat: 48.298168, lng: 18.7026544 },
    { lat: 48.2980944, lng: 18.7027316 },
    { lat: 48.2980499, lng: 18.7026831 },
    { lat: 48.2978705, lng: 18.7027213 },
    { lat: 48.2976409, lng: 18.7029405 },
    { lat: 48.2975246, lng: 18.702917 },
    { lat: 48.297326, lng: 18.7030613 },
    { lat: 48.297134, lng: 18.7029934 },
    { lat: 48.2966577, lng: 18.7032398 },
    { lat: 48.2963087, lng: 18.7035063 },
    { lat: 48.2960812, lng: 18.7034501 },
    { lat: 48.2960045, lng: 18.7034076 },
    { lat: 48.2953963, lng: 18.7033806 },
    { lat: 48.2951849, lng: 18.7031396 },
    { lat: 48.294949, lng: 18.7030508 },
    { lat: 48.2947932, lng: 18.7031736 },
    { lat: 48.2947758, lng: 18.7036907 },
    { lat: 48.2943783, lng: 18.7037669 },
    { lat: 48.2942572, lng: 18.703717 },
    { lat: 48.2941833, lng: 18.70362 },
    { lat: 48.2940987, lng: 18.7033478 },
    { lat: 48.2940375, lng: 18.7033648 },
    { lat: 48.293892, lng: 18.7035965 },
    { lat: 48.293627, lng: 18.7036791 },
    { lat: 48.2934493, lng: 18.7038382 },
    { lat: 48.2932175, lng: 18.7037814 },
    { lat: 48.2930622, lng: 18.7058114 },
    { lat: 48.2912659, lng: 18.7096957 },
    { lat: 48.291037, lng: 18.7102449 },
    { lat: 48.2902609, lng: 18.7125611 },
    { lat: 48.2891434, lng: 18.7151586 },
    { lat: 48.2885388, lng: 18.7164664 },
    { lat: 48.2876319, lng: 18.7178795 },
    { lat: 48.2871094, lng: 18.7184645 },
    { lat: 48.2868483, lng: 18.7185571 },
    { lat: 48.2862172, lng: 18.7190729 },
    { lat: 48.2860609, lng: 18.7192709 },
    { lat: 48.2861543, lng: 18.7196481 },
    { lat: 48.2861364, lng: 18.7196719 },
    { lat: 48.2861152, lng: 18.7196992 },
    { lat: 48.2855616, lng: 18.7205025 },
    { lat: 48.2851594, lng: 18.720976 },
    { lat: 48.2844722, lng: 18.7213491 },
    { lat: 48.2829303, lng: 18.7226989 },
    { lat: 48.2814979, lng: 18.7242179 },
    { lat: 48.2811209, lng: 18.7236194 },
    { lat: 48.2800014, lng: 18.7225529 },
    { lat: 48.2791262, lng: 18.724853 },
    { lat: 48.2786869, lng: 18.724548 },
    { lat: 48.2783297, lng: 18.725357 },
    { lat: 48.2768368, lng: 18.7278318 },
    { lat: 48.2767138, lng: 18.7316812 },
    { lat: 48.2774042, lng: 18.7316644 },
    { lat: 48.2775739, lng: 18.7326432 },
    { lat: 48.2777266, lng: 18.732592 },
    { lat: 48.2778857, lng: 18.7336702 },
    { lat: 48.2779867, lng: 18.7336616 },
    { lat: 48.2783435, lng: 18.7365487 },
    { lat: 48.2782142, lng: 18.7369788 },
    { lat: 48.2781897, lng: 18.7372182 },
    { lat: 48.2776168, lng: 18.7391667 },
    { lat: 48.2767052, lng: 18.741681 },
    { lat: 48.2755603, lng: 18.7453644 },
    { lat: 48.2753707, lng: 18.7456161 },
    { lat: 48.2749144, lng: 18.74696 },
    { lat: 48.2748736, lng: 18.7474003 },
    { lat: 48.2746114, lng: 18.7487666 },
    { lat: 48.2745128, lng: 18.7488856 },
    { lat: 48.2743397, lng: 18.7501291 },
    { lat: 48.2743091, lng: 18.750126 },
    { lat: 48.2741804, lng: 18.752013 },
    { lat: 48.2740362, lng: 18.7521187 },
    { lat: 48.2740062, lng: 18.7522136 },
    { lat: 48.2739952, lng: 18.7526019 },
    { lat: 48.2738845, lng: 18.752915 },
    { lat: 48.2737331, lng: 18.7539306 },
    { lat: 48.2728821, lng: 18.754065 },
    { lat: 48.2728818, lng: 18.7547808 },
    { lat: 48.2724169, lng: 18.7549704 },
    { lat: 48.2721509, lng: 18.7552042 },
    { lat: 48.2723158, lng: 18.755848 },
    { lat: 48.2705922, lng: 18.7579193 },
    { lat: 48.2702085, lng: 18.7589777 },
    { lat: 48.2697368, lng: 18.7599915 },
    { lat: 48.2698138, lng: 18.7606995 },
    { lat: 48.2697843, lng: 18.761247 },
    { lat: 48.2695909, lng: 18.7618747 },
    { lat: 48.269547, lng: 18.762156 },
    { lat: 48.2695871, lng: 18.7626974 },
    { lat: 48.2696249, lng: 18.7627499 },
    { lat: 48.2694505, lng: 18.7627767 },
    { lat: 48.2685684, lng: 18.7634572 },
    { lat: 48.2677926, lng: 18.7639474 },
    { lat: 48.2675441, lng: 18.7641717 },
    { lat: 48.2673605, lng: 18.764476 },
    { lat: 48.2669978, lng: 18.7646777 },
    { lat: 48.2670487, lng: 18.7654228 },
    { lat: 48.2671413, lng: 18.7656581 },
    { lat: 48.267085, lng: 18.7658528 },
    { lat: 48.2671424, lng: 18.7662088 },
    { lat: 48.2670647, lng: 18.7662771 },
    { lat: 48.2669632, lng: 18.7661285 },
    { lat: 48.266822, lng: 18.7660812 },
    { lat: 48.2668193, lng: 18.7664769 },
    { lat: 48.2669521, lng: 18.7666002 },
    { lat: 48.2669332, lng: 18.7667526 },
    { lat: 48.2668203, lng: 18.7669624 },
    { lat: 48.2668743, lng: 18.7671748 },
    { lat: 48.2667809, lng: 18.7672177 },
    { lat: 48.2667029, lng: 18.7670051 },
    { lat: 48.2666245, lng: 18.7669744 },
    { lat: 48.2665901, lng: 18.7670249 },
    { lat: 48.2665535, lng: 18.7675474 },
    { lat: 48.2663153, lng: 18.7682252 },
    { lat: 48.2663167, lng: 18.7686534 },
    { lat: 48.2661685, lng: 18.7689248 },
    { lat: 48.2661264, lng: 18.7692763 },
    { lat: 48.2659287, lng: 18.7700587 },
    { lat: 48.2658284, lng: 18.7702706 },
    { lat: 48.2653955, lng: 18.7720642 },
    { lat: 48.265196, lng: 18.7729823 },
    { lat: 48.2649453, lng: 18.7758914 },
    { lat: 48.2643142, lng: 18.7759899 },
    { lat: 48.263804, lng: 18.7760825 },
    { lat: 48.2639539, lng: 18.7778691 },
    { lat: 48.2638965, lng: 18.7788305 },
    { lat: 48.2640622, lng: 18.7811312 },
    { lat: 48.2640272, lng: 18.7831981 },
    { lat: 48.2635871, lng: 18.7844943 },
    { lat: 48.2631498, lng: 18.7871691 },
    { lat: 48.2632013, lng: 18.7874377 },
    { lat: 48.2630022, lng: 18.7872974 },
    { lat: 48.2627649, lng: 18.7874392 },
    { lat: 48.2625748, lng: 18.7876825 },
    { lat: 48.2625715, lng: 18.7879381 },
    { lat: 48.262687, lng: 18.7882465 },
    { lat: 48.2625506, lng: 18.7888257 },
    { lat: 48.2623689, lng: 18.7889701 },
    { lat: 48.2622841, lng: 18.7896292 },
    { lat: 48.262152, lng: 18.7898608 },
    { lat: 48.2619114, lng: 18.7907741 },
    { lat: 48.2616408, lng: 18.7910726 },
    { lat: 48.2616262, lng: 18.7913055 },
    { lat: 48.2613626, lng: 18.7913763 },
    { lat: 48.2612648, lng: 18.791556 },
    { lat: 48.2611746, lng: 18.7921305 },
    { lat: 48.2609689, lng: 18.7927855 },
    { lat: 48.2610101, lng: 18.7930744 },
    { lat: 48.2607998, lng: 18.7938124 },
    { lat: 48.2606167, lng: 18.7939516 },
    { lat: 48.2608699, lng: 18.7948403 },
    { lat: 48.2608904, lng: 18.7953596 },
    { lat: 48.2609623, lng: 18.7956735 },
    { lat: 48.2609242, lng: 18.7961249 },
    { lat: 48.2610136, lng: 18.7965783 },
    { lat: 48.2609825, lng: 18.7967823 },
    { lat: 48.2611512, lng: 18.7971166 },
    { lat: 48.2613366, lng: 18.7973382 },
    { lat: 48.2618088, lng: 18.7977788 },
    { lat: 48.2619742, lng: 18.7977288 },
    { lat: 48.2621215, lng: 18.7978923 },
    { lat: 48.2623223, lng: 18.797917 },
    { lat: 48.2638477, lng: 18.7994276 },
    { lat: 48.2642013, lng: 18.7999308 },
    { lat: 48.2643343, lng: 18.8002293 },
    { lat: 48.2647462, lng: 18.8007228 },
    { lat: 48.2659293, lng: 18.8011765 },
    { lat: 48.2663105, lng: 18.8017954 },
    { lat: 48.2666067, lng: 18.8019826 },
    { lat: 48.2668492, lng: 18.8020049 },
    { lat: 48.2673308, lng: 18.8021975 },
    { lat: 48.2681845, lng: 18.8032897 },
    { lat: 48.2691979, lng: 18.8043493 },
    { lat: 48.2696022, lng: 18.8046633 },
    { lat: 48.2703041, lng: 18.8065739 },
    { lat: 48.2703321, lng: 18.8071065 },
    { lat: 48.2703237, lng: 18.8075474 },
    { lat: 48.2702189, lng: 18.808084 },
    { lat: 48.2701553, lng: 18.8088866 },
    { lat: 48.270298, lng: 18.8106785 },
    { lat: 48.2699775, lng: 18.813547 },
    { lat: 48.2698713, lng: 18.8155176 },
    { lat: 48.2699834, lng: 18.8167054 },
    { lat: 48.2698487, lng: 18.8177651 },
    { lat: 48.2696664, lng: 18.8183116 },
    { lat: 48.2686706, lng: 18.8202186 },
    { lat: 48.2674703, lng: 18.822854 },
    { lat: 48.2672588, lng: 18.8237836 },
    { lat: 48.2666786, lng: 18.8251916 },
    { lat: 48.2662007, lng: 18.8267436 },
    { lat: 48.265559, lng: 18.826632 },
    { lat: 48.265837, lng: 18.827951 },
    { lat: 48.265813, lng: 18.828784 },
    { lat: 48.26594, lng: 18.830312 },
    { lat: 48.265946, lng: 18.83038 },
    { lat: 48.265956, lng: 18.830505 },
    { lat: 48.265931, lng: 18.830545 },
    { lat: 48.265978, lng: 18.831077 },
    { lat: 48.266109, lng: 18.832662 },
    { lat: 48.266261, lng: 18.83348 },
    { lat: 48.266271, lng: 18.834269 },
    { lat: 48.266277, lng: 18.834713 },
    { lat: 48.266427, lng: 18.835161 },
    { lat: 48.266489, lng: 18.835465 },
    { lat: 48.2665, lng: 18.835512 },
    { lat: 48.266586, lng: 18.83593 },
    { lat: 48.267233, lng: 18.837931 },
    { lat: 48.267739, lng: 18.839037 },
    { lat: 48.26807, lng: 18.840583 },
  ],
  MaléKozmálovce: [
    { lat: 48.281564, lng: 18.474158 },
    { lat: 48.281545, lng: 18.474179 },
    { lat: 48.281522, lng: 18.474218 },
    { lat: 48.281153, lng: 18.474798 },
    { lat: 48.281134, lng: 18.474833 },
    { lat: 48.280649, lng: 18.474804 },
    { lat: 48.280294, lng: 18.474881 },
    { lat: 48.280056, lng: 18.474988 },
    { lat: 48.279818, lng: 18.475107 },
    { lat: 48.27935, lng: 18.4754 },
    { lat: 48.27922, lng: 18.475489 },
    { lat: 48.278961, lng: 18.475751 },
    { lat: 48.278686, lng: 18.476103 },
    { lat: 48.278593, lng: 18.476238 },
    { lat: 48.278537, lng: 18.476309 },
    { lat: 48.278351, lng: 18.476502 },
    { lat: 48.27822, lng: 18.476655 },
    { lat: 48.277872, lng: 18.477195 },
    { lat: 48.277527, lng: 18.47751 },
    { lat: 48.277398, lng: 18.477642 },
    { lat: 48.277247, lng: 18.477877 },
    { lat: 48.277115, lng: 18.478106 },
    { lat: 48.276847, lng: 18.478534 },
    { lat: 48.27671, lng: 18.478765 },
    { lat: 48.276587, lng: 18.478964 },
    { lat: 48.276424, lng: 18.479191 },
    { lat: 48.276078, lng: 18.479496 },
    { lat: 48.275572, lng: 18.479831 },
    { lat: 48.275479, lng: 18.479898 },
    { lat: 48.275132, lng: 18.480112 },
    { lat: 48.274789, lng: 18.480331 },
    { lat: 48.274597, lng: 18.480461 },
    { lat: 48.274394, lng: 18.480587 },
    { lat: 48.2735279, lng: 18.4811051 },
    { lat: 48.2713539, lng: 18.4834992 },
    { lat: 48.2694344, lng: 18.4853345 },
    { lat: 48.2685394, lng: 18.4856106 },
    { lat: 48.2675805, lng: 18.4857148 },
    { lat: 48.2669576, lng: 18.4861974 },
    { lat: 48.265778, lng: 18.4874227 },
    { lat: 48.2642695, lng: 18.4897985 },
    { lat: 48.2641628, lng: 18.4900323 },
    { lat: 48.2638036, lng: 18.4905316 },
    { lat: 48.2632946, lng: 18.4914609 },
    { lat: 48.2622402, lng: 18.4944182 },
    { lat: 48.2615632, lng: 18.4961062 },
    { lat: 48.2610019, lng: 18.4982126 },
    { lat: 48.2607244, lng: 18.4987629 },
    { lat: 48.2597996, lng: 18.5003151 },
    { lat: 48.2601902, lng: 18.5016022 },
    { lat: 48.2606758, lng: 18.5039768 },
    { lat: 48.2614799, lng: 18.5070897 },
    { lat: 48.263124, lng: 18.5121607 },
    { lat: 48.2629462, lng: 18.5126777 },
    { lat: 48.2629422, lng: 18.5128708 },
    { lat: 48.2641934, lng: 18.512647 },
    { lat: 48.2648615, lng: 18.5210473 },
    { lat: 48.2627448, lng: 18.5211638 },
    { lat: 48.2589485, lng: 18.5216876 },
    { lat: 48.2577008, lng: 18.5222385 },
    { lat: 48.2566004, lng: 18.5232271 },
    { lat: 48.256888, lng: 18.5235936 },
    { lat: 48.2574951, lng: 18.524191 },
    { lat: 48.2595095, lng: 18.5255358 },
    { lat: 48.2604462, lng: 18.5263439 },
    { lat: 48.2607629, lng: 18.526703 },
    { lat: 48.2614451, lng: 18.5276633 },
    { lat: 48.2616327, lng: 18.5282019 },
    { lat: 48.262341, lng: 18.5291003 },
    { lat: 48.2629335, lng: 18.5297149 },
    { lat: 48.2635301, lng: 18.530186 },
    { lat: 48.2638576, lng: 18.5303349 },
    { lat: 48.2641454, lng: 18.5306664 },
    { lat: 48.265086, lng: 18.5308652 },
    { lat: 48.2660164, lng: 18.5304758 },
    { lat: 48.2661696, lng: 18.5302448 },
    { lat: 48.2669552, lng: 18.5286164 },
    { lat: 48.2685054, lng: 18.526369 },
    { lat: 48.268892, lng: 18.5256262 },
    { lat: 48.2697234, lng: 18.5247911 },
    { lat: 48.2699419, lng: 18.5248062 },
    { lat: 48.2701638, lng: 18.5250021 },
    { lat: 48.2711329, lng: 18.5248628 },
    { lat: 48.2722073, lng: 18.524358 },
    { lat: 48.2724525, lng: 18.5239775 },
    { lat: 48.2731892, lng: 18.5236713 },
    { lat: 48.2736402, lng: 18.5233627 },
    { lat: 48.2740069, lng: 18.5232008 },
    { lat: 48.2744935, lng: 18.5232082 },
    { lat: 48.275429, lng: 18.5233569 },
    { lat: 48.2756779, lng: 18.5234507 },
    { lat: 48.2759448, lng: 18.5236482 },
    { lat: 48.2759775, lng: 18.5238336 },
    { lat: 48.2766307, lng: 18.5247956 },
    { lat: 48.2765471, lng: 18.5251403 },
    { lat: 48.2770222, lng: 18.5257178 },
    { lat: 48.2774252, lng: 18.526214 },
    { lat: 48.2778045, lng: 18.5265425 },
    { lat: 48.2783396, lng: 18.5268159 },
    { lat: 48.2786635, lng: 18.5269451 },
    { lat: 48.2790906, lng: 18.5270186 },
    { lat: 48.2796514, lng: 18.5262434 },
    { lat: 48.2797858, lng: 18.5259591 },
    { lat: 48.2802491, lng: 18.5254222 },
    { lat: 48.2808499, lng: 18.5249031 },
    { lat: 48.2817377, lng: 18.5245761 },
    { lat: 48.2823503, lng: 18.5245962 },
    { lat: 48.2827834, lng: 18.5245373 },
    { lat: 48.2834475, lng: 18.5246711 },
    { lat: 48.283711, lng: 18.524868 },
    { lat: 48.283794, lng: 18.5247186 },
    { lat: 48.2838921, lng: 18.5247142 },
    { lat: 48.283945, lng: 18.5246099 },
    { lat: 48.2847073, lng: 18.5240353 },
    { lat: 48.2848133, lng: 18.5240115 },
    { lat: 48.2852635, lng: 18.5235464 },
    { lat: 48.2860019, lng: 18.522409 },
    { lat: 48.2862089, lng: 18.5223175 },
    { lat: 48.2861093, lng: 18.5216991 },
    { lat: 48.2859931, lng: 18.5217184 },
    { lat: 48.2858388, lng: 18.5218654 },
    { lat: 48.2860417, lng: 18.5210858 },
    { lat: 48.2861048, lng: 18.5206158 },
    { lat: 48.2861361, lng: 18.5198929 },
    { lat: 48.2862322, lng: 18.5194705 },
    { lat: 48.2861388, lng: 18.5194329 },
    { lat: 48.286416, lng: 18.518315 },
    { lat: 48.2867295, lng: 18.5178122 },
    { lat: 48.2870667, lng: 18.5176263 },
    { lat: 48.287717, lng: 18.5175322 },
    { lat: 48.2878451, lng: 18.5174636 },
    { lat: 48.2880536, lng: 18.5175185 },
    { lat: 48.2883654, lng: 18.5177126 },
    { lat: 48.2887702, lng: 18.5180779 },
    { lat: 48.288936, lng: 18.5183021 },
    { lat: 48.2889713, lng: 18.518449 },
    { lat: 48.291554, lng: 18.5108212 },
    { lat: 48.2935259, lng: 18.5074935 },
    { lat: 48.2937985, lng: 18.507815 },
    { lat: 48.2939205, lng: 18.5077974 },
    { lat: 48.2941917, lng: 18.5082526 },
    { lat: 48.2943131, lng: 18.5087554 },
    { lat: 48.2942781, lng: 18.5088784 },
    { lat: 48.2949615, lng: 18.509794 },
    { lat: 48.2950966, lng: 18.5097075 },
    { lat: 48.2950459, lng: 18.5080757 },
    { lat: 48.2955096, lng: 18.5077496 },
    { lat: 48.2959456, lng: 18.5075368 },
    { lat: 48.2962984, lng: 18.5067557 },
    { lat: 48.2965624, lng: 18.5068367 },
    { lat: 48.2966338, lng: 18.5066415 },
    { lat: 48.2966063, lng: 18.5064086 },
    { lat: 48.2966083, lng: 18.5063994 },
    { lat: 48.2967488, lng: 18.506065 },
    { lat: 48.2968533, lng: 18.5059755 },
    { lat: 48.2969304, lng: 18.506007 },
    { lat: 48.2969918, lng: 18.5059331 },
    { lat: 48.2969393, lng: 18.5053887 },
    { lat: 48.2970174, lng: 18.5052724 },
    { lat: 48.2971607, lng: 18.5048072 },
    { lat: 48.297098, lng: 18.5047537 },
    { lat: 48.2966596, lng: 18.5048846 },
    { lat: 48.2946196, lng: 18.504201 },
    { lat: 48.2936957, lng: 18.5031826 },
    { lat: 48.2921694, lng: 18.5012216 },
    { lat: 48.290905, lng: 18.4999619 },
    { lat: 48.2904605, lng: 18.4997701 },
    { lat: 48.2904155, lng: 18.4997512 },
    { lat: 48.2897507, lng: 18.4990402 },
    { lat: 48.2895574, lng: 18.4987075 },
    { lat: 48.2885277, lng: 18.4964712 },
    { lat: 48.2877401, lng: 18.4950429 },
    { lat: 48.2870714, lng: 18.4946 },
    { lat: 48.2852975, lng: 18.4937337 },
    { lat: 48.2838367, lng: 18.4941024 },
    { lat: 48.2829498, lng: 18.4944061 },
    { lat: 48.2828652, lng: 18.4944871 },
    { lat: 48.2826942, lng: 18.4945325 },
    { lat: 48.2826373, lng: 18.4944648 },
    { lat: 48.2826509, lng: 18.4936305 },
    { lat: 48.2825799, lng: 18.4934892 },
    { lat: 48.282501, lng: 18.4931026 },
    { lat: 48.2825611, lng: 18.4927828 },
    { lat: 48.2825402, lng: 18.4926487 },
    { lat: 48.2823591, lng: 18.4927219 },
    { lat: 48.2823037, lng: 18.4925796 },
    { lat: 48.2823602, lng: 18.4923537 },
    { lat: 48.2824853, lng: 18.4922904 },
    { lat: 48.282489, lng: 18.4921953 },
    { lat: 48.2823736, lng: 18.4920493 },
    { lat: 48.282185, lng: 18.4920451 },
    { lat: 48.2821353, lng: 18.4921037 },
    { lat: 48.2821449, lng: 18.4922987 },
    { lat: 48.2820844, lng: 18.492324 },
    { lat: 48.2819087, lng: 18.4921475 },
    { lat: 48.2818935, lng: 18.4920353 },
    { lat: 48.2820013, lng: 18.4918236 },
    { lat: 48.2820614, lng: 18.4914024 },
    { lat: 48.2819799, lng: 18.4913131 },
    { lat: 48.2819707, lng: 18.4911481 },
    { lat: 48.2818472, lng: 18.4911366 },
    { lat: 48.2818132, lng: 18.4909109 },
    { lat: 48.2819995, lng: 18.4909334 },
    { lat: 48.2820243, lng: 18.4907713 },
    { lat: 48.2819266, lng: 18.4907406 },
    { lat: 48.2819158, lng: 18.4906471 },
    { lat: 48.2819518, lng: 18.4905633 },
    { lat: 48.2820294, lng: 18.490561 },
    { lat: 48.2820399, lng: 18.4903837 },
    { lat: 48.2817655, lng: 18.4899114 },
    { lat: 48.2816799, lng: 18.4904012 },
    { lat: 48.2816223, lng: 18.4903985 },
    { lat: 48.2814596, lng: 18.4901924 },
    { lat: 48.2814488, lng: 18.4898141 },
    { lat: 48.2813293, lng: 18.4894869 },
    { lat: 48.2812678, lng: 18.4891542 },
    { lat: 48.2811097, lng: 18.4890878 },
    { lat: 48.2811109, lng: 18.4890108 },
    { lat: 48.2812529, lng: 18.4888645 },
    { lat: 48.2812301, lng: 18.4887183 },
    { lat: 48.2811204, lng: 18.4886321 },
    { lat: 48.2810429, lng: 18.48876 },
    { lat: 48.2808566, lng: 18.4886164 },
    { lat: 48.2807873, lng: 18.4884621 },
    { lat: 48.2808755, lng: 18.4882446 },
    { lat: 48.2808076, lng: 18.4880771 },
    { lat: 48.2806511, lng: 18.4880225 },
    { lat: 48.2805141, lng: 18.4875626 },
    { lat: 48.2806636, lng: 18.4875347 },
    { lat: 48.2805703, lng: 18.4872772 },
    { lat: 48.2804478, lng: 18.4872421 },
    { lat: 48.2804972, lng: 18.4871397 },
    { lat: 48.2804765, lng: 18.4870087 },
    { lat: 48.2804147, lng: 18.4870125 },
    { lat: 48.2802345, lng: 18.4867768 },
    { lat: 48.2802161, lng: 18.486483 },
    { lat: 48.2801174, lng: 18.4864121 },
    { lat: 48.2800842, lng: 18.4862186 },
    { lat: 48.2800104, lng: 18.4861113 },
    { lat: 48.2800202, lng: 18.4858798 },
    { lat: 48.2798364, lng: 18.4856598 },
    { lat: 48.2797362, lng: 18.4853787 },
    { lat: 48.2796061, lng: 18.4852883 },
    { lat: 48.279505, lng: 18.4847689 },
    { lat: 48.279574, lng: 18.484692 },
    { lat: 48.2795675, lng: 18.4845122 },
    { lat: 48.2796847, lng: 18.4843279 },
    { lat: 48.2796334, lng: 18.4838884 },
    { lat: 48.2795398, lng: 18.4836897 },
    { lat: 48.2795484, lng: 18.4834563 },
    { lat: 48.2796686, lng: 18.4832236 },
    { lat: 48.2801534, lng: 18.4816732 },
    { lat: 48.2809215, lng: 18.4794777 },
    { lat: 48.2816995, lng: 18.4743776 },
    { lat: 48.281564, lng: 18.474158 },
  ],
  Podlužany: [
    { lat: 48.2456192, lng: 18.6513218 },
    { lat: 48.2467582, lng: 18.6502725 },
    { lat: 48.2469931, lng: 18.6498828 },
    { lat: 48.2472488, lng: 18.649292 },
    { lat: 48.2475422, lng: 18.6482546 },
    { lat: 48.2478514, lng: 18.6475079 },
    { lat: 48.2485475, lng: 18.6462975 },
    { lat: 48.2492959, lng: 18.6451645 },
    { lat: 48.249675, lng: 18.6440643 },
    { lat: 48.2500157, lng: 18.6432936 },
    { lat: 48.2505707, lng: 18.6432712 },
    { lat: 48.2508198, lng: 18.6431221 },
    { lat: 48.2513356, lng: 18.642547 },
    { lat: 48.2515867, lng: 18.6421435 },
    { lat: 48.2519154, lng: 18.6412128 },
    { lat: 48.2520786, lng: 18.6410405 },
    { lat: 48.2521846, lng: 18.6407898 },
    { lat: 48.252852, lng: 18.6387853 },
    { lat: 48.2532858, lng: 18.6370883 },
    { lat: 48.2539196, lng: 18.6352768 },
    { lat: 48.254608, lng: 18.6328066 },
    { lat: 48.254638, lng: 18.6327803 },
    { lat: 48.2546638, lng: 18.6328048 },
    { lat: 48.254992, lng: 18.6331168 },
    { lat: 48.2550853, lng: 18.6332055 },
    { lat: 48.255788, lng: 18.6314789 },
    { lat: 48.2561491, lng: 18.6302971 },
    { lat: 48.2575516, lng: 18.6294126 },
    { lat: 48.2575719, lng: 18.6294554 },
    { lat: 48.2582978, lng: 18.6294422 },
    { lat: 48.258307, lng: 18.628554 },
    { lat: 48.2602683, lng: 18.6275958 },
    { lat: 48.2603474, lng: 18.6262046 },
    { lat: 48.2605186, lng: 18.6249346 },
    { lat: 48.2604522, lng: 18.6218618 },
    { lat: 48.2601877, lng: 18.6197939 },
    { lat: 48.2600756, lng: 18.6193325 },
    { lat: 48.2600292, lng: 18.6189134 },
    { lat: 48.2611165, lng: 18.6179962 },
    { lat: 48.2624739, lng: 18.61703 },
    { lat: 48.2624653, lng: 18.6169546 },
    { lat: 48.2623122, lng: 18.616877 },
    { lat: 48.2622257, lng: 18.6166334 },
    { lat: 48.2623044, lng: 18.6162167 },
    { lat: 48.2622096, lng: 18.6161118 },
    { lat: 48.2620165, lng: 18.6150072 },
    { lat: 48.2618963, lng: 18.6131705 },
    { lat: 48.2615431, lng: 18.6105743 },
    { lat: 48.2610313, lng: 18.6069721 },
    { lat: 48.2603774, lng: 18.6034382 },
    { lat: 48.2601556, lng: 18.6018908 },
    { lat: 48.2582818, lng: 18.5983281 },
    { lat: 48.257969, lng: 18.5974942 },
    { lat: 48.2573491, lng: 18.594795 },
    { lat: 48.2571194, lng: 18.594181 },
    { lat: 48.2567859, lng: 18.5935482 },
    { lat: 48.255897, lng: 18.5922019 },
    { lat: 48.2554611, lng: 18.5924007 },
    { lat: 48.2531371, lng: 18.5930323 },
    { lat: 48.2469532, lng: 18.5951584 },
    { lat: 48.2461903, lng: 18.5953331 },
    { lat: 48.2451528, lng: 18.595236 },
    { lat: 48.2451222, lng: 18.5953554 },
    { lat: 48.2426911, lng: 18.5973392 },
    { lat: 48.242499, lng: 18.5974355 },
    { lat: 48.2415167, lng: 18.5983061 },
    { lat: 48.2406171, lng: 18.5995342 },
    { lat: 48.240573, lng: 18.601065 },
    { lat: 48.2405382, lng: 18.6018109 },
    { lat: 48.2405475, lng: 18.6018165 },
    { lat: 48.2410927, lng: 18.6022173 },
    { lat: 48.2415807, lng: 18.6033491 },
    { lat: 48.2417758, lng: 18.6039873 },
    { lat: 48.241655, lng: 18.6042993 },
    { lat: 48.2413961, lng: 18.604723 },
    { lat: 48.2409555, lng: 18.6050835 },
    { lat: 48.2403861, lng: 18.6054167 },
    { lat: 48.2399617, lng: 18.6052898 },
    { lat: 48.2396087, lng: 18.6054571 },
    { lat: 48.2395285, lng: 18.6054339 },
    { lat: 48.2393958, lng: 18.6052754 },
    { lat: 48.2391309, lng: 18.604426 },
    { lat: 48.2390523, lng: 18.6054252 },
    { lat: 48.2390018, lng: 18.607089 },
    { lat: 48.2376425, lng: 18.6066733 },
    { lat: 48.2371806, lng: 18.6064558 },
    { lat: 48.2370852, lng: 18.6066695 },
    { lat: 48.2368498, lng: 18.6083547 },
    { lat: 48.2361351, lng: 18.6109957 },
    { lat: 48.2353009, lng: 18.6133476 },
    { lat: 48.2356834, lng: 18.6136583 },
    { lat: 48.2354877, lng: 18.6143526 },
    { lat: 48.2349567, lng: 18.6157274 },
    { lat: 48.2353526, lng: 18.6163064 },
    { lat: 48.2346005, lng: 18.6177241 },
    { lat: 48.235069, lng: 18.619016 },
    { lat: 48.235402, lng: 18.6197888 },
    { lat: 48.2390065, lng: 18.6231032 },
    { lat: 48.2380249, lng: 18.6244698 },
    { lat: 48.2372786, lng: 18.6258478 },
    { lat: 48.2362034, lng: 18.6280469 },
    { lat: 48.2343872, lng: 18.6315739 },
    { lat: 48.2337653, lng: 18.6326882 },
    { lat: 48.234309, lng: 18.6335621 },
    { lat: 48.2338749, lng: 18.6342959 },
    { lat: 48.2347902, lng: 18.6354873 },
    { lat: 48.2350009, lng: 18.6359867 },
    { lat: 48.2351813, lng: 18.6362528 },
    { lat: 48.2357238, lng: 18.6366695 },
    { lat: 48.236586, lng: 18.6374716 },
    { lat: 48.2376085, lng: 18.638147 },
    { lat: 48.2456082, lng: 18.6512842 },
    { lat: 48.2456192, lng: 18.6513218 },
  ],
  Demandice: [
    { lat: 48.1069059, lng: 18.7606638 },
    { lat: 48.1069092, lng: 18.7607036 },
    { lat: 48.1069222, lng: 18.7608026 },
    { lat: 48.1066847, lng: 18.7612737 },
    { lat: 48.1063988, lng: 18.7615807 },
    { lat: 48.1057659, lng: 18.7626367 },
    { lat: 48.1052022, lng: 18.7646732 },
    { lat: 48.1051721, lng: 18.7650405 },
    { lat: 48.1050128, lng: 18.7657778 },
    { lat: 48.1048195, lng: 18.7663645 },
    { lat: 48.1047087, lng: 18.7669119 },
    { lat: 48.1045022, lng: 18.7675044 },
    { lat: 48.104312, lng: 18.769988 },
    { lat: 48.1041675, lng: 18.7704765 },
    { lat: 48.1039797, lng: 18.772416 },
    { lat: 48.1036958, lng: 18.7740779 },
    { lat: 48.1036757, lng: 18.7746346 },
    { lat: 48.1036125, lng: 18.7749533 },
    { lat: 48.1033862, lng: 18.7752962 },
    { lat: 48.1032142, lng: 18.7764774 },
    { lat: 48.1031261, lng: 18.7775378 },
    { lat: 48.1030104, lng: 18.778056 },
    { lat: 48.1029783, lng: 18.7787461 },
    { lat: 48.1031169, lng: 18.7795503 },
    { lat: 48.1030932, lng: 18.7802746 },
    { lat: 48.1029637, lng: 18.781232 },
    { lat: 48.103089, lng: 18.7817224 },
    { lat: 48.1031231, lng: 18.7825563 },
    { lat: 48.1031798, lng: 18.7828418 },
    { lat: 48.103421, lng: 18.7834113 },
    { lat: 48.1038716, lng: 18.7841896 },
    { lat: 48.1040871, lng: 18.7849545 },
    { lat: 48.1040387, lng: 18.7871272 },
    { lat: 48.1043762, lng: 18.7896471 },
    { lat: 48.1049918, lng: 18.792408 },
    { lat: 48.1022687, lng: 18.7938673 },
    { lat: 48.0972931, lng: 18.7958052 },
    { lat: 48.0963612, lng: 18.7958841 },
    { lat: 48.095707, lng: 18.7958378 },
    { lat: 48.0954431, lng: 18.7968606 },
    { lat: 48.0950866, lng: 18.7966447 },
    { lat: 48.0956067, lng: 18.7998239 },
    { lat: 48.0955917, lng: 18.8008614 },
    { lat: 48.0956279, lng: 18.8011852 },
    { lat: 48.0960032, lng: 18.8039747 },
    { lat: 48.096141, lng: 18.8045335 },
    { lat: 48.0963362, lng: 18.8047881 },
    { lat: 48.0965146, lng: 18.8052214 },
    { lat: 48.0968341, lng: 18.8057172 },
    { lat: 48.0971717, lng: 18.8059805 },
    { lat: 48.0977263, lng: 18.8069375 },
    { lat: 48.097818, lng: 18.8072403 },
    { lat: 48.0981042, lng: 18.8078593 },
    { lat: 48.1000269, lng: 18.8111657 },
    { lat: 48.1002466, lng: 18.8117149 },
    { lat: 48.1007351, lng: 18.8125528 },
    { lat: 48.1009049, lng: 18.8131876 },
    { lat: 48.1015876, lng: 18.8165055 },
    { lat: 48.1017807, lng: 18.817048 },
    { lat: 48.1026408, lng: 18.8185168 },
    { lat: 48.1028985, lng: 18.8192101 },
    { lat: 48.1034345, lng: 18.8211208 },
    { lat: 48.103536, lng: 18.821679 },
    { lat: 48.103862, lng: 18.8227302 },
    { lat: 48.1040653, lng: 18.823781 },
    { lat: 48.1051309, lng: 18.8261386 },
    { lat: 48.1058686, lng: 18.8272103 },
    { lat: 48.1076493, lng: 18.8277677 },
    { lat: 48.1084189, lng: 18.8276505 },
    { lat: 48.1092469, lng: 18.8272689 },
    { lat: 48.1103475, lng: 18.8269478 },
    { lat: 48.113177, lng: 18.8262645 },
    { lat: 48.1155733, lng: 18.826029 },
    { lat: 48.1166068, lng: 18.8255011 },
    { lat: 48.1189546, lng: 18.8228867 },
    { lat: 48.1195653, lng: 18.8220854 },
    { lat: 48.1205263, lng: 18.8212988 },
    { lat: 48.1208825, lng: 18.8210578 },
    { lat: 48.122349, lng: 18.8204919 },
    { lat: 48.1227529, lng: 18.8204307 },
    { lat: 48.1230754, lng: 18.8203042 },
    { lat: 48.1238309, lng: 18.8198286 },
    { lat: 48.124247, lng: 18.8197203 },
    { lat: 48.1250428, lng: 18.8197133 },
    { lat: 48.1259061, lng: 18.8201991 },
    { lat: 48.1267815, lng: 18.8201827 },
    { lat: 48.1276946, lng: 18.8205592 },
    { lat: 48.128735, lng: 18.8208503 },
    { lat: 48.1306423, lng: 18.8204079 },
    { lat: 48.1306478, lng: 18.8204486 },
    { lat: 48.130648, lng: 18.8204528 },
    { lat: 48.1306848, lng: 18.8204417 },
    { lat: 48.131899, lng: 18.8197303 },
    { lat: 48.1339523, lng: 18.8179094 },
    { lat: 48.1348256, lng: 18.816844 },
    { lat: 48.1352322, lng: 18.8163902 },
    { lat: 48.1357285, lng: 18.8153851 },
    { lat: 48.1362139, lng: 18.8141095 },
    { lat: 48.1366611, lng: 18.812925 },
    { lat: 48.1382617, lng: 18.8082313 },
    { lat: 48.1397731, lng: 18.8039848 },
    { lat: 48.1408797, lng: 18.8021348 },
    { lat: 48.1404309, lng: 18.8018979 },
    { lat: 48.1397606, lng: 18.8016995 },
    { lat: 48.1394895, lng: 18.8014568 },
    { lat: 48.1390676, lng: 18.8008325 },
    { lat: 48.1388546, lng: 18.8000665 },
    { lat: 48.1390519, lng: 18.798364 },
    { lat: 48.1389997, lng: 18.7978635 },
    { lat: 48.138939, lng: 18.7978241 },
    { lat: 48.1389233, lng: 18.797701 },
    { lat: 48.1389984, lng: 18.7974541 },
    { lat: 48.1388962, lng: 18.79689 },
    { lat: 48.1389699, lng: 18.7952537 },
    { lat: 48.1386795, lng: 18.7936247 },
    { lat: 48.1385814, lng: 18.7927932 },
    { lat: 48.1381576, lng: 18.7916036 },
    { lat: 48.1379519, lng: 18.7907227 },
    { lat: 48.1378252, lng: 18.7899051 },
    { lat: 48.1380205, lng: 18.7884381 },
    { lat: 48.1382373, lng: 18.7876551 },
    { lat: 48.1383384, lng: 18.786566 },
    { lat: 48.1383267, lng: 18.7861698 },
    { lat: 48.1382163, lng: 18.7854743 },
    { lat: 48.1380729, lng: 18.7853193 },
    { lat: 48.1381117, lng: 18.7848603 },
    { lat: 48.1379567, lng: 18.7845524 },
    { lat: 48.1379427, lng: 18.7842889 },
    { lat: 48.1377742, lng: 18.7840031 },
    { lat: 48.137794, lng: 18.7837617 },
    { lat: 48.1377481, lng: 18.7832577 },
    { lat: 48.137539, lng: 18.7826519 },
    { lat: 48.1374488, lng: 18.7821411 },
    { lat: 48.1377044, lng: 18.7809613 },
    { lat: 48.1380148, lng: 18.7806297 },
    { lat: 48.1380096, lng: 18.7805092 },
    { lat: 48.1380761, lng: 18.7804445 },
    { lat: 48.1380992, lng: 18.7801364 },
    { lat: 48.1380194, lng: 18.7797804 },
    { lat: 48.1383615, lng: 18.7795348 },
    { lat: 48.1404234, lng: 18.7771379 },
    { lat: 48.1410241, lng: 18.7765986 },
    { lat: 48.1414937, lng: 18.7763096 },
    { lat: 48.1446768, lng: 18.7749392 },
    { lat: 48.1489697, lng: 18.7735209 },
    { lat: 48.149373, lng: 18.7733877 },
    { lat: 48.1504356, lng: 18.7730366 },
    { lat: 48.1517433, lng: 18.7723896 },
    { lat: 48.1520237, lng: 18.7721469 },
    { lat: 48.1520143, lng: 18.7721166 },
    { lat: 48.1514358, lng: 18.7698466 },
    { lat: 48.1510761, lng: 18.76872 },
    { lat: 48.1509197, lng: 18.7684316 },
    { lat: 48.1498297, lng: 18.7676576 },
    { lat: 48.1492206, lng: 18.7673181 },
    { lat: 48.1484406, lng: 18.7667718 },
    { lat: 48.1468385, lng: 18.7633427 },
    { lat: 48.1466597, lng: 18.7618472 },
    { lat: 48.1457102, lng: 18.7567408 },
    { lat: 48.1448727, lng: 18.7549491 },
    { lat: 48.1445453, lng: 18.7556211 },
    { lat: 48.144202, lng: 18.7554628 },
    { lat: 48.1440117, lng: 18.7554476 },
    { lat: 48.1436928, lng: 18.7556024 },
    { lat: 48.1426059, lng: 18.7565152 },
    { lat: 48.1420689, lng: 18.7568971 },
    { lat: 48.1413954, lng: 18.7567854 },
    { lat: 48.1410147, lng: 18.7555655 },
    { lat: 48.1394568, lng: 18.754921 },
    { lat: 48.137846, lng: 18.754407 },
    { lat: 48.135397, lng: 18.7519189 },
    { lat: 48.1352525, lng: 18.7506492 },
    { lat: 48.1350685, lng: 18.750455 },
    { lat: 48.1341246, lng: 18.7490139 },
    { lat: 48.1323499, lng: 18.7469501 },
    { lat: 48.1311133, lng: 18.7456096 },
    { lat: 48.131054, lng: 18.7456199 },
    { lat: 48.1292852, lng: 18.750284 },
    { lat: 48.126587, lng: 18.7539767 },
    { lat: 48.1257778, lng: 18.7549826 },
    { lat: 48.1241821, lng: 18.7564475 },
    { lat: 48.1215774, lng: 18.7586989 },
    { lat: 48.1207733, lng: 18.7590994 },
    { lat: 48.1198306, lng: 18.7594425 },
    { lat: 48.1192289, lng: 18.7594539 },
    { lat: 48.1176367, lng: 18.7593296 },
    { lat: 48.1175411, lng: 18.7587413 },
    { lat: 48.1171097, lng: 18.7546026 },
    { lat: 48.1171519, lng: 18.7531283 },
    { lat: 48.1168087, lng: 18.7517048 },
    { lat: 48.1168111, lng: 18.7516435 },
    { lat: 48.1167078, lng: 18.7516293 },
    { lat: 48.1160221, lng: 18.7522042 },
    { lat: 48.1149087, lng: 18.7523217 },
    { lat: 48.1145665, lng: 18.7521224 },
    { lat: 48.1142019, lng: 18.7523719 },
    { lat: 48.1141081, lng: 18.7525365 },
    { lat: 48.1133856, lng: 18.7545577 },
    { lat: 48.1128424, lng: 18.7553263 },
    { lat: 48.1126643, lng: 18.7557282 },
    { lat: 48.1120773, lng: 18.7565228 },
    { lat: 48.111715, lng: 18.7572152 },
    { lat: 48.1112287, lng: 18.7579127 },
    { lat: 48.1109895, lng: 18.7584613 },
    { lat: 48.1087079, lng: 18.7603846 },
    { lat: 48.1070641, lng: 18.7606051 },
    { lat: 48.1069059, lng: 18.7606638 },
  ],
  Tupá: [
    { lat: 48.1117776, lng: 18.8801618 },
    { lat: 48.1117247, lng: 18.880139 },
    { lat: 48.1111956, lng: 18.8804835 },
    { lat: 48.1105905, lng: 18.8805349 },
    { lat: 48.1103998, lng: 18.8803792 },
    { lat: 48.1101254, lng: 18.8797228 },
    { lat: 48.1099864, lng: 18.8796931 },
    { lat: 48.1096483, lng: 18.8799173 },
    { lat: 48.109348, lng: 18.8798213 },
    { lat: 48.1091772, lng: 18.8796824 },
    { lat: 48.1091105, lng: 18.879487 },
    { lat: 48.1091324, lng: 18.8784792 },
    { lat: 48.1090388, lng: 18.8783246 },
    { lat: 48.1088232, lng: 18.8782149 },
    { lat: 48.1087234, lng: 18.8782265 },
    { lat: 48.1085404, lng: 18.8785008 },
    { lat: 48.1083259, lng: 18.8786016 },
    { lat: 48.1081611, lng: 18.8785774 },
    { lat: 48.1080885, lng: 18.8786585 },
    { lat: 48.1080521, lng: 18.8793406 },
    { lat: 48.1081987, lng: 18.8796873 },
    { lat: 48.1081907, lng: 18.8797958 },
    { lat: 48.1078757, lng: 18.8801126 },
    { lat: 48.1077112, lng: 18.8799864 },
    { lat: 48.1076645, lng: 18.8798641 },
    { lat: 48.1076544, lng: 18.8791561 },
    { lat: 48.1076017, lng: 18.8786842 },
    { lat: 48.1075098, lng: 18.8784592 },
    { lat: 48.1073121, lng: 18.8783866 },
    { lat: 48.1071603, lng: 18.8785041 },
    { lat: 48.1070722, lng: 18.8790196 },
    { lat: 48.1069797, lng: 18.8792752 },
    { lat: 48.1067958, lng: 18.879275 },
    { lat: 48.1065761, lng: 18.8790177 },
    { lat: 48.106299, lng: 18.8783577 },
    { lat: 48.1061709, lng: 18.8782128 },
    { lat: 48.1060302, lng: 18.8782609 },
    { lat: 48.1059108, lng: 18.8785038 },
    { lat: 48.1058458, lng: 18.8787717 },
    { lat: 48.1056564, lng: 18.8787507 },
    { lat: 48.1054591, lng: 18.8785337 },
    { lat: 48.1052727, lng: 18.8786338 },
    { lat: 48.1051704, lng: 18.8788323 },
    { lat: 48.1051466, lng: 18.8792411 },
    { lat: 48.1052795, lng: 18.8794797 },
    { lat: 48.1055224, lng: 18.8795957 },
    { lat: 48.1057478, lng: 18.879614 },
    { lat: 48.1057622, lng: 18.8797806 },
    { lat: 48.1056475, lng: 18.8799823 },
    { lat: 48.1052355, lng: 18.8801477 },
    { lat: 48.1051988, lng: 18.880242 },
    { lat: 48.105216, lng: 18.8803761 },
    { lat: 48.1052976, lng: 18.8804961 },
    { lat: 48.1056272, lng: 18.880739 },
    { lat: 48.1057778, lng: 18.880967 },
    { lat: 48.1057597, lng: 18.8812238 },
    { lat: 48.1056576, lng: 18.8814308 },
    { lat: 48.1055624, lng: 18.8815036 },
    { lat: 48.1048316, lng: 18.881396 },
    { lat: 48.1043857, lng: 18.8809751 },
    { lat: 48.1042389, lng: 18.8806715 },
    { lat: 48.1042155, lng: 18.8804403 },
    { lat: 48.1038855, lng: 18.8800123 },
    { lat: 48.103493, lng: 18.880337 },
    { lat: 48.1026765, lng: 18.8800722 },
    { lat: 48.1024587, lng: 18.8802241 },
    { lat: 48.102386, lng: 18.8804055 },
    { lat: 48.1025165, lng: 18.881268 },
    { lat: 48.1026315, lng: 18.8844869 },
    { lat: 48.1026084, lng: 18.8848871 },
    { lat: 48.1027518, lng: 18.8864915 },
    { lat: 48.1034491, lng: 18.8920364 },
    { lat: 48.1035818, lng: 18.894609 },
    { lat: 48.10373, lng: 18.8963071 },
    { lat: 48.1038488, lng: 18.8974219 },
    { lat: 48.1040396, lng: 18.8985738 },
    { lat: 48.1040444, lng: 18.8999474 },
    { lat: 48.1038478, lng: 18.9052292 },
    { lat: 48.1038717, lng: 18.9085536 },
    { lat: 48.1036505, lng: 18.911139 },
    { lat: 48.1035609, lng: 18.9127115 },
    { lat: 48.103602, lng: 18.9134172 },
    { lat: 48.1039289, lng: 18.9155645 },
    { lat: 48.1040388, lng: 18.9180084 },
    { lat: 48.1039353, lng: 18.9199309 },
    { lat: 48.1038083, lng: 18.9210053 },
    { lat: 48.1040451, lng: 18.9252066 },
    { lat: 48.1040446, lng: 18.9260285 },
    { lat: 48.1044608, lng: 18.925701 },
    { lat: 48.1046783, lng: 18.9255917 },
    { lat: 48.1049778, lng: 18.9255133 },
    { lat: 48.1060059, lng: 18.9253364 },
    { lat: 48.1068006, lng: 18.9250901 },
    { lat: 48.1071797, lng: 18.9250926 },
    { lat: 48.1079295, lng: 18.9246376 },
    { lat: 48.1082527, lng: 18.9245873 },
    { lat: 48.1094169, lng: 18.9246377 },
    { lat: 48.1095694, lng: 18.924492 },
    { lat: 48.1096578, lng: 18.9244787 },
    { lat: 48.1092465, lng: 18.9260516 },
    { lat: 48.1092023, lng: 18.9262426 },
    { lat: 48.1096276, lng: 18.9265056 },
    { lat: 48.1101739, lng: 18.9266069 },
    { lat: 48.1104269, lng: 18.9268755 },
    { lat: 48.1104181, lng: 18.9270106 },
    { lat: 48.111075, lng: 18.9278052 },
    { lat: 48.11266, lng: 18.9319163 },
    { lat: 48.11533, lng: 18.9370851 },
    { lat: 48.1149383, lng: 18.9380769 },
    { lat: 48.1150109, lng: 18.9381123 },
    { lat: 48.1197467, lng: 18.9408845 },
    { lat: 48.1198283, lng: 18.9410462 },
    { lat: 48.1203745, lng: 18.940485 },
    { lat: 48.1206172, lng: 18.9403395 },
    { lat: 48.1208508, lng: 18.940362 },
    { lat: 48.1209811, lng: 18.9404631 },
    { lat: 48.1211537, lng: 18.9408551 },
    { lat: 48.1212634, lng: 18.9407556 },
    { lat: 48.1216262, lng: 18.9400719 },
    { lat: 48.12168, lng: 18.9396084 },
    { lat: 48.1219473, lng: 18.9385612 },
    { lat: 48.122156, lng: 18.9382569 },
    { lat: 48.1222722, lng: 18.937693 },
    { lat: 48.1227876, lng: 18.9364507 },
    { lat: 48.123322, lng: 18.9343958 },
    { lat: 48.1232602, lng: 18.9341185 },
    { lat: 48.1234749, lng: 18.9334906 },
    { lat: 48.1236252, lng: 18.9332584 },
    { lat: 48.1237828, lng: 18.932751 },
    { lat: 48.1240308, lng: 18.9324662 },
    { lat: 48.1247218, lng: 18.9297406 },
    { lat: 48.1248453, lng: 18.9293774 },
    { lat: 48.1249187, lng: 18.9291617 },
    { lat: 48.125012, lng: 18.9290465 },
    { lat: 48.1251285, lng: 18.9287277 },
    { lat: 48.1252286, lng: 18.9283746 },
    { lat: 48.1251915, lng: 18.9283548 },
    { lat: 48.1254315, lng: 18.9279156 },
    { lat: 48.1259057, lng: 18.9275139 },
    { lat: 48.1261626, lng: 18.9272037 },
    { lat: 48.1268079, lng: 18.9261455 },
    { lat: 48.1272147, lng: 18.9252448 },
    { lat: 48.1273296, lng: 18.9251348 },
    { lat: 48.1279031, lng: 18.9249535 },
    { lat: 48.1285917, lng: 18.922613 },
    { lat: 48.1298844, lng: 18.9230597 },
    { lat: 48.1300764, lng: 18.9230626 },
    { lat: 48.1311633, lng: 18.9235413 },
    { lat: 48.1313868, lng: 18.9237417 },
    { lat: 48.1319883, lng: 18.9239691 },
    { lat: 48.1323875, lng: 18.9240564 },
    { lat: 48.1325685, lng: 18.9236786 },
    { lat: 48.1332695, lng: 18.9217737 },
    { lat: 48.1337887, lng: 18.9205679 },
    { lat: 48.1343389, lng: 18.9185847 },
    { lat: 48.1345748, lng: 18.9178945 },
    { lat: 48.1348457, lng: 18.9160262 },
    { lat: 48.135192, lng: 18.9149183 },
    { lat: 48.1354192, lng: 18.9138849 },
    { lat: 48.1355425, lng: 18.9127184 },
    { lat: 48.1355747, lng: 18.9121231 },
    { lat: 48.1355472, lng: 18.9116561 },
    { lat: 48.1350218, lng: 18.9080531 },
    { lat: 48.1348208, lng: 18.907243 },
    { lat: 48.1344429, lng: 18.9062186 },
    { lat: 48.1343792, lng: 18.9034083 },
    { lat: 48.1342351, lng: 18.9023505 },
    { lat: 48.1342725, lng: 18.9020048 },
    { lat: 48.1344156, lng: 18.9014626 },
    { lat: 48.1338679, lng: 18.8981904 },
    { lat: 48.1338211, lng: 18.8975082 },
    { lat: 48.1339074, lng: 18.8975645 },
    { lat: 48.1339902, lng: 18.8975298 },
    { lat: 48.1341714, lng: 18.8971336 },
    { lat: 48.1342909, lng: 18.8967007 },
    { lat: 48.1344143, lng: 18.8965342 },
    { lat: 48.1348711, lng: 18.8964487 },
    { lat: 48.1351546, lng: 18.8961338 },
    { lat: 48.1352565, lng: 18.8959479 },
    { lat: 48.135318, lng: 18.8943565 },
    { lat: 48.1352399, lng: 18.8929638 },
    { lat: 48.1352406, lng: 18.8910885 },
    { lat: 48.1355999, lng: 18.8904738 },
    { lat: 48.1359296, lng: 18.8895085 },
    { lat: 48.1361228, lng: 18.889234 },
    { lat: 48.1368525, lng: 18.8883739 },
    { lat: 48.1380575, lng: 18.8872373 },
    { lat: 48.1396502, lng: 18.8855608 },
    { lat: 48.1406947, lng: 18.8848724 },
    { lat: 48.1438162, lng: 18.8831005 },
    { lat: 48.1438326, lng: 18.8827261 },
    { lat: 48.1443379, lng: 18.8827683 },
    { lat: 48.1443447, lng: 18.8822739 },
    { lat: 48.1441641, lng: 18.8816862 },
    { lat: 48.1440099, lng: 18.8814542 },
    { lat: 48.143906, lng: 18.8810369 },
    { lat: 48.1436938, lng: 18.8809056 },
    { lat: 48.1435174, lng: 18.880614 },
    { lat: 48.143387, lng: 18.880537 },
    { lat: 48.1430711, lng: 18.8806201 },
    { lat: 48.1426329, lng: 18.8811244 },
    { lat: 48.1424373, lng: 18.8811585 },
    { lat: 48.142106, lng: 18.8809037 },
    { lat: 48.1419145, lng: 18.8807565 },
    { lat: 48.141891, lng: 18.880737 },
    { lat: 48.1414252, lng: 18.8803682 },
    { lat: 48.1410244, lng: 18.8802497 },
    { lat: 48.1403855, lng: 18.8799145 },
    { lat: 48.1401557, lng: 18.8798551 },
    { lat: 48.1396079, lng: 18.8794688 },
    { lat: 48.1389073, lng: 18.8791821 },
    { lat: 48.1334093, lng: 18.8819596 },
    { lat: 48.1321675, lng: 18.8824927 },
    { lat: 48.132, lng: 18.8826739 },
    { lat: 48.1314793, lng: 18.8828753 },
    { lat: 48.1313808, lng: 18.8830615 },
    { lat: 48.1312895, lng: 18.8835589 },
    { lat: 48.1311675, lng: 18.8836846 },
    { lat: 48.1309241, lng: 18.8836663 },
    { lat: 48.1307992, lng: 18.8838221 },
    { lat: 48.1309309, lng: 18.8841237 },
    { lat: 48.1309262, lng: 18.884251 },
    { lat: 48.1308656, lng: 18.8842979 },
    { lat: 48.1306671, lng: 18.8843616 },
    { lat: 48.1304437, lng: 18.8843149 },
    { lat: 48.1300349, lng: 18.8839385 },
    { lat: 48.1296912, lng: 18.8847187 },
    { lat: 48.1291554, lng: 18.8856472 },
    { lat: 48.127994, lng: 18.8860586 },
    { lat: 48.1287468, lng: 18.8904911 },
    { lat: 48.1283327, lng: 18.89053 },
    { lat: 48.1262921, lng: 18.8910752 },
    { lat: 48.1255155, lng: 18.891625 },
    { lat: 48.1237544, lng: 18.8914127 },
    { lat: 48.120535, lng: 18.8917824 },
    { lat: 48.1199264, lng: 18.8913975 },
    { lat: 48.1191471, lng: 18.8914615 },
    { lat: 48.1182253, lng: 18.8914289 },
    { lat: 48.1177118, lng: 18.8912249 },
    { lat: 48.1174624, lng: 18.8910499 },
    { lat: 48.1165259, lng: 18.8908397 },
    { lat: 48.1165447, lng: 18.8906194 },
    { lat: 48.1168726, lng: 18.887585 },
    { lat: 48.1168824, lng: 18.8869466 },
    { lat: 48.1166993, lng: 18.8861866 },
    { lat: 48.1165384, lng: 18.8852394 },
    { lat: 48.1159425, lng: 18.8853398 },
    { lat: 48.11566, lng: 18.8853052 },
    { lat: 48.1156652, lng: 18.8843667 },
    { lat: 48.1153944, lng: 18.8843847 },
    { lat: 48.1151537, lng: 18.8843104 },
    { lat: 48.115619, lng: 18.882788 },
    { lat: 48.1150877, lng: 18.8830664 },
    { lat: 48.1119208, lng: 18.8821902 },
    { lat: 48.1116631, lng: 18.8816179 },
    { lat: 48.1117506, lng: 18.881306 },
    { lat: 48.111908, lng: 18.8812122 },
    { lat: 48.1121711, lng: 18.8811724 },
    { lat: 48.1122198, lng: 18.8809603 },
    { lat: 48.1119057, lng: 18.8802157 },
    { lat: 48.1117776, lng: 18.8801618 },
  ],
  MaléLudince: [
    { lat: 47.9965249, lng: 18.6894957 },
    { lat: 47.9964464, lng: 18.6885459 },
    { lat: 47.9965217, lng: 18.6882675 },
    { lat: 47.9958776, lng: 18.6879728 },
    { lat: 47.995703, lng: 18.6879578 },
    { lat: 47.995437, lng: 18.6876274 },
    { lat: 47.9952512, lng: 18.6871701 },
    { lat: 47.9950077, lng: 18.6871732 },
    { lat: 47.9946038, lng: 18.6873178 },
    { lat: 47.9945197, lng: 18.6874511 },
    { lat: 47.9944025, lng: 18.6874947 },
    { lat: 47.9942204, lng: 18.6873401 },
    { lat: 47.9938151, lng: 18.6867053 },
    { lat: 47.9936112, lng: 18.6865624 },
    { lat: 47.9932549, lng: 18.686503 },
    { lat: 47.9928962, lng: 18.686188 },
    { lat: 47.9927098, lng: 18.6862642 },
    { lat: 47.992378, lng: 18.6869743 },
    { lat: 47.9922588, lng: 18.6869597 },
    { lat: 47.9918189, lng: 18.6862893 },
    { lat: 47.9917613, lng: 18.6862899 },
    { lat: 47.9915339, lng: 18.6865255 },
    { lat: 47.9914521, lng: 18.6864508 },
    { lat: 47.9912691, lng: 18.6861957 },
    { lat: 47.9912334, lng: 18.6859324 },
    { lat: 47.9913534, lng: 18.6856479 },
    { lat: 47.9913499, lng: 18.6855603 },
    { lat: 47.9911843, lng: 18.6855049 },
    { lat: 47.9908272, lng: 18.6856371 },
    { lat: 47.9906848, lng: 18.6855246 },
    { lat: 47.9906656, lng: 18.6853582 },
    { lat: 47.9904524, lng: 18.685025 },
    { lat: 47.9903245, lng: 18.6846605 },
    { lat: 47.9903442, lng: 18.684544 },
    { lat: 47.9906423, lng: 18.6841555 },
    { lat: 47.9905861, lng: 18.6840742 },
    { lat: 47.9903432, lng: 18.6840048 },
    { lat: 47.9900761, lng: 18.6841354 },
    { lat: 47.9898042, lng: 18.6839458 },
    { lat: 47.9895808, lng: 18.6835023 },
    { lat: 47.9890103, lng: 18.6830091 },
    { lat: 47.9891025, lng: 18.6823143 },
    { lat: 47.9890399, lng: 18.6823458 },
    { lat: 47.9887417, lng: 18.682189 },
    { lat: 47.9883099, lng: 18.6815074 },
    { lat: 47.9875434, lng: 18.6810748 },
    { lat: 47.9874246, lng: 18.6808619 },
    { lat: 47.9874365, lng: 18.6798712 },
    { lat: 47.9872452, lng: 18.6798153 },
    { lat: 47.9871179, lng: 18.6798619 },
    { lat: 47.9866026, lng: 18.6798421 },
    { lat: 47.9864074, lng: 18.67955 },
    { lat: 47.9861884, lng: 18.6793373 },
    { lat: 47.9857527, lng: 18.6794762 },
    { lat: 47.9856759, lng: 18.6791833 },
    { lat: 47.9857134, lng: 18.6790204 },
    { lat: 47.9859008, lng: 18.6786691 },
    { lat: 47.9859242, lng: 18.678516 },
    { lat: 47.9858215, lng: 18.678392 },
    { lat: 47.9857094, lng: 18.6783654 },
    { lat: 47.9855675, lng: 18.6784898 },
    { lat: 47.9851949, lng: 18.6785489 },
    { lat: 47.9848602, lng: 18.6787785 },
    { lat: 47.9847788, lng: 18.6789435 },
    { lat: 47.9847762, lng: 18.6790614 },
    { lat: 47.9844998, lng: 18.6793121 },
    { lat: 47.984024, lng: 18.6793777 },
    { lat: 47.9839904, lng: 18.6794648 },
    { lat: 47.9836425, lng: 18.6795607 },
    { lat: 47.9834853, lng: 18.6794293 },
    { lat: 47.9833387, lng: 18.6791138 },
    { lat: 47.9831682, lng: 18.6789456 },
    { lat: 47.9830456, lng: 18.678973 },
    { lat: 47.9828339, lng: 18.6793321 },
    { lat: 47.98265, lng: 18.6794974 },
    { lat: 47.9824794, lng: 18.6794936 },
    { lat: 47.9823313, lng: 18.6794216 },
    { lat: 47.9821129, lng: 18.6793901 },
    { lat: 47.981757, lng: 18.679731 },
    { lat: 47.9814546, lng: 18.679812 },
    { lat: 47.980995, lng: 18.6798671 },
    { lat: 47.980741, lng: 18.6797979 },
    { lat: 47.9806273, lng: 18.679864 },
    { lat: 47.9805019, lng: 18.6801762 },
    { lat: 47.9803778, lng: 18.6802808 },
    { lat: 47.9800933, lng: 18.6800235 },
    { lat: 47.9798736, lng: 18.6802135 },
    { lat: 47.9797326, lng: 18.6810721 },
    { lat: 47.9793278, lng: 18.6815103 },
    { lat: 47.9793047, lng: 18.6817081 },
    { lat: 47.9793873, lng: 18.6819629 },
    { lat: 47.9793598, lng: 18.6822886 },
    { lat: 47.9792075, lng: 18.6824533 },
    { lat: 47.9790643, lng: 18.6824543 },
    { lat: 47.9788572, lng: 18.6823179 },
    { lat: 47.9785005, lng: 18.6822681 },
    { lat: 47.9781507, lng: 18.6823301 },
    { lat: 47.9780473, lng: 18.6821479 },
    { lat: 47.9780464, lng: 18.6819649 },
    { lat: 47.9780926, lng: 18.6819446 },
    { lat: 47.9782192, lng: 18.6815947 },
    { lat: 47.9784641, lng: 18.6813678 },
    { lat: 47.9784536, lng: 18.6810076 },
    { lat: 47.9783509, lng: 18.6808788 },
    { lat: 47.9782469, lng: 18.6808134 },
    { lat: 47.9782347, lng: 18.6808579 },
    { lat: 47.9779786, lng: 18.6808389 },
    { lat: 47.9776938, lng: 18.6809294 },
    { lat: 47.9773671, lng: 18.6805613 },
    { lat: 47.9766748, lng: 18.6799833 },
    { lat: 47.9763283, lng: 18.6796029 },
    { lat: 47.9760898, lng: 18.6795724 },
    { lat: 47.9758611, lng: 18.6791795 },
    { lat: 47.9758099, lng: 18.6791733 },
    { lat: 47.9755808, lng: 18.6793152 },
    { lat: 47.9754144, lng: 18.6795341 },
    { lat: 47.9753693, lng: 18.6797221 },
    { lat: 47.9753711, lng: 18.6800482 },
    { lat: 47.9754696, lng: 18.6803454 },
    { lat: 47.9754432, lng: 18.6810663 },
    { lat: 47.97534, lng: 18.6813272 },
    { lat: 47.9751995, lng: 18.6813588 },
    { lat: 47.9749078, lng: 18.6811572 },
    { lat: 47.9746828, lng: 18.6810836 },
    { lat: 47.9744593, lng: 18.6811413 },
    { lat: 47.9742024, lng: 18.6813334 },
    { lat: 47.9739328, lng: 18.6810422 },
    { lat: 47.9738694, lng: 18.6807052 },
    { lat: 47.9737798, lng: 18.68059 },
    { lat: 47.9737223, lng: 18.680593 },
    { lat: 47.9731556, lng: 18.6812709 },
    { lat: 47.9730974, lng: 18.6816732 },
    { lat: 47.9728585, lng: 18.6819932 },
    { lat: 47.9726441, lng: 18.6824462 },
    { lat: 47.9727084, lng: 18.6827176 },
    { lat: 47.9726442, lng: 18.6830347 },
    { lat: 47.9724169, lng: 18.6829824 },
    { lat: 47.9722278, lng: 18.682903 },
    { lat: 47.9716546, lng: 18.6832952 },
    { lat: 47.9715958, lng: 18.6835032 },
    { lat: 47.9714578, lng: 18.6836816 },
    { lat: 47.9706821, lng: 18.6835634 },
    { lat: 47.9702967, lng: 18.6836951 },
    { lat: 47.9703155, lng: 18.6840621 },
    { lat: 47.9703174, lng: 18.6840967 },
    { lat: 47.9705341, lng: 18.6838905 },
    { lat: 47.9706123, lng: 18.6839199 },
    { lat: 47.9707584, lng: 18.6842444 },
    { lat: 47.970871, lng: 18.6848669 },
    { lat: 47.9710679, lng: 18.6848989 },
    { lat: 47.9712591, lng: 18.6846793 },
    { lat: 47.9714689, lng: 18.6846999 },
    { lat: 47.9715187, lng: 18.6845383 },
    { lat: 47.9716017, lng: 18.6845069 },
    { lat: 47.9719879, lng: 18.6851645 },
    { lat: 47.9721365, lng: 18.6851587 },
    { lat: 47.972211, lng: 18.6850759 },
    { lat: 47.9723407, lng: 18.6846383 },
    { lat: 47.9725187, lng: 18.6844899 },
    { lat: 47.9726176, lng: 18.6845055 },
    { lat: 47.9726997, lng: 18.6846467 },
    { lat: 47.9726945, lng: 18.6853203 },
    { lat: 47.9729213, lng: 18.6859706 },
    { lat: 47.9737793, lng: 18.6863176 },
    { lat: 47.9742946, lng: 18.6864406 },
    { lat: 47.9746585, lng: 18.6870834 },
    { lat: 47.9738006, lng: 18.6879893 },
    { lat: 47.9735946, lng: 18.6883273 },
    { lat: 47.9734867, lng: 18.6893355 },
    { lat: 47.9734141, lng: 18.6914197 },
    { lat: 47.9734204, lng: 18.6928092 },
    { lat: 47.9732086, lng: 18.6939862 },
    { lat: 47.9730176, lng: 18.6947091 },
    { lat: 47.9729577, lng: 18.697949 },
    { lat: 47.9730954, lng: 18.6990169 },
    { lat: 47.9739181, lng: 18.7018278 },
    { lat: 47.9745644, lng: 18.7044269 },
    { lat: 47.9748071, lng: 18.7051423 },
    { lat: 47.9748724, lng: 18.7060609 },
    { lat: 47.9750126, lng: 18.706716 },
    { lat: 47.9751294, lng: 18.7077115 },
    { lat: 47.9751458, lng: 18.7084561 },
    { lat: 47.9752305, lng: 18.7093403 },
    { lat: 47.9751205, lng: 18.7110689 },
    { lat: 47.9749114, lng: 18.7116271 },
    { lat: 47.9747177, lng: 18.7119856 },
    { lat: 47.9746162, lng: 18.712493 },
    { lat: 47.9758828, lng: 18.7132672 },
    { lat: 47.9767949, lng: 18.7146433 },
    { lat: 47.9760999, lng: 18.7159416 },
    { lat: 47.97637, lng: 18.7177569 },
    { lat: 47.9767116, lng: 18.719412 },
    { lat: 47.9781909, lng: 18.7202406 },
    { lat: 47.9795677, lng: 18.7209526 },
    { lat: 47.9798156, lng: 18.7210301 },
    { lat: 47.9797901, lng: 18.7214805 },
    { lat: 47.9806906, lng: 18.7216175 },
    { lat: 47.9827338, lng: 18.7223023 },
    { lat: 47.9826501, lng: 18.7227597 },
    { lat: 47.9834391, lng: 18.7231096 },
    { lat: 47.9839451, lng: 18.7232447 },
    { lat: 47.9851377, lng: 18.7232987 },
    { lat: 47.9852824, lng: 18.7239566 },
    { lat: 47.9857053, lng: 18.7241263 },
    { lat: 47.9870604, lng: 18.7236931 },
    { lat: 47.9872443, lng: 18.7240292 },
    { lat: 47.9872483, lng: 18.7237911 },
    { lat: 47.987324, lng: 18.7236036 },
    { lat: 47.9874688, lng: 18.7236934 },
    { lat: 47.9872446, lng: 18.7244576 },
    { lat: 47.9870607, lng: 18.7256103 },
    { lat: 47.9870648, lng: 18.7264213 },
    { lat: 47.9882226, lng: 18.7266865 },
    { lat: 47.9892251, lng: 18.7271882 },
    { lat: 47.9892475, lng: 18.7272094 },
    { lat: 47.9892827, lng: 18.7272352 },
    { lat: 47.9903292, lng: 18.7242462 },
    { lat: 47.991204, lng: 18.7220778 },
    { lat: 47.9914941, lng: 18.7209486 },
    { lat: 47.9917879, lng: 18.7200954 },
    { lat: 47.9919026, lng: 18.7196265 },
    { lat: 47.9920113, lng: 18.7179144 },
    { lat: 47.9924441, lng: 18.7166932 },
    { lat: 47.9926886, lng: 18.7155743 },
    { lat: 47.9929742, lng: 18.7147815 },
    { lat: 47.9933648, lng: 18.7130913 },
    { lat: 47.9933658, lng: 18.7126282 },
    { lat: 47.9932599, lng: 18.7123595 },
    { lat: 47.99303, lng: 18.712044 },
    { lat: 47.9925431, lng: 18.7108783 },
    { lat: 47.9924476, lng: 18.7099412 },
    { lat: 47.9924677, lng: 18.7093497 },
    { lat: 47.9927131, lng: 18.7083578 },
    { lat: 47.9930113, lng: 18.7075483 },
    { lat: 47.9933447, lng: 18.7068283 },
    { lat: 47.9943994, lng: 18.7034777 },
    { lat: 47.9951739, lng: 18.7003757 },
    { lat: 47.9957402, lng: 18.6988495 },
    { lat: 47.9961573, lng: 18.6971922 },
    { lat: 47.996272, lng: 18.696326 },
    { lat: 47.9963485, lng: 18.6951538 },
    { lat: 47.9965289, lng: 18.6895258 },
    { lat: 47.9965249, lng: 18.6894957 },
  ],
  Čata: [
    { lat: 47.938825, lng: 18.667258 },
    { lat: 47.938651, lng: 18.668211 },
    { lat: 47.939069, lng: 18.668882 },
    { lat: 47.939669, lng: 18.669533 },
    { lat: 47.939791, lng: 18.669665 },
    { lat: 47.940432, lng: 18.670702 },
    { lat: 47.941069, lng: 18.671463 },
    { lat: 47.94158, lng: 18.672178 },
    { lat: 47.941865, lng: 18.672989 },
    { lat: 47.942071, lng: 18.674014 },
    { lat: 47.942318, lng: 18.67447 },
    { lat: 47.942938, lng: 18.675093 },
    { lat: 47.943478, lng: 18.675704 },
    { lat: 47.943965, lng: 18.676365 },
    { lat: 47.944336, lng: 18.676925 },
    { lat: 47.944404, lng: 18.677462 },
    { lat: 47.944655, lng: 18.678102 },
    { lat: 47.944892, lng: 18.678286 },
    { lat: 47.944934, lng: 18.678318 },
    { lat: 47.94519, lng: 18.678516 },
    { lat: 47.945792, lng: 18.679052 },
    { lat: 47.94615, lng: 18.679888 },
    { lat: 47.94645, lng: 18.680705 },
    { lat: 47.946815, lng: 18.681118 },
    { lat: 47.947358, lng: 18.681569 },
    { lat: 47.947812, lng: 18.681964 },
    { lat: 47.948244, lng: 18.682431 },
    { lat: 47.948591, lng: 18.682806 },
    { lat: 47.949144, lng: 18.683312 },
    { lat: 47.949413, lng: 18.68384 },
    { lat: 47.94945, lng: 18.683912 },
    { lat: 47.950018, lng: 18.684895 },
    { lat: 47.950246, lng: 18.685231 },
    { lat: 47.95056, lng: 18.685666 },
    { lat: 47.950959, lng: 18.686102 },
    { lat: 47.95061, lng: 18.687162 },
    { lat: 47.95052, lng: 18.687424 },
    { lat: 47.95048, lng: 18.687537 },
    { lat: 47.95055, lng: 18.687755 },
    { lat: 47.950625, lng: 18.687853 },
    { lat: 47.950695, lng: 18.687907 },
    { lat: 47.950766, lng: 18.687936 },
    { lat: 47.950873, lng: 18.68795 },
    { lat: 47.950964, lng: 18.687923 },
    { lat: 47.95104, lng: 18.687893 },
    { lat: 47.95112, lng: 18.687817 },
    { lat: 47.951183, lng: 18.687741 },
    { lat: 47.951233, lng: 18.687642 },
    { lat: 47.951268, lng: 18.687582 },
    { lat: 47.9513732, lng: 18.687215 },
    { lat: 47.9514863, lng: 18.6871385 },
    { lat: 47.9518679, lng: 18.6878154 },
    { lat: 47.9524221, lng: 18.6882173 },
    { lat: 47.9531592, lng: 18.6885217 },
    { lat: 47.9534316, lng: 18.6885109 },
    { lat: 47.9536661, lng: 18.6883628 },
    { lat: 47.9537315, lng: 18.6882347 },
    { lat: 47.9537165, lng: 18.6878129 },
    { lat: 47.9537627, lng: 18.6876223 },
    { lat: 47.9538022, lng: 18.687607 },
    { lat: 47.9543903, lng: 18.6880707 },
    { lat: 47.95501, lng: 18.6882287 },
    { lat: 47.955235, lng: 18.6880708 },
    { lat: 47.9553826, lng: 18.687505 },
    { lat: 47.9555101, lng: 18.6874025 },
    { lat: 47.9560943, lng: 18.6874852 },
    { lat: 47.9562993, lng: 18.6877492 },
    { lat: 47.956498, lng: 18.6878784 },
    { lat: 47.9567062, lng: 18.6878078 },
    { lat: 47.9568966, lng: 18.6876798 },
    { lat: 47.9570055, lng: 18.6874605 },
    { lat: 47.9570891, lng: 18.6871085 },
    { lat: 47.9570742, lng: 18.6869787 },
    { lat: 47.9568316, lng: 18.6866661 },
    { lat: 47.9567453, lng: 18.6864027 },
    { lat: 47.956797, lng: 18.6860855 },
    { lat: 47.9567754, lng: 18.6857922 },
    { lat: 47.9568484, lng: 18.6855551 },
    { lat: 47.9569094, lng: 18.6855168 },
    { lat: 47.9569738, lng: 18.6855622 },
    { lat: 47.9569924, lng: 18.6856522 },
    { lat: 47.9569258, lng: 18.6858808 },
    { lat: 47.9571432, lng: 18.6861693 },
    { lat: 47.9574252, lng: 18.6862354 },
    { lat: 47.9576401, lng: 18.6861186 },
    { lat: 47.9579311, lng: 18.685804 },
    { lat: 47.9580078, lng: 18.6852652 },
    { lat: 47.9585052, lng: 18.6849087 },
    { lat: 47.958805, lng: 18.6842989 },
    { lat: 47.9590927, lng: 18.6840118 },
    { lat: 47.9591532, lng: 18.6841083 },
    { lat: 47.9593671, lng: 18.685183 },
    { lat: 47.9595625, lng: 18.6856293 },
    { lat: 47.9597684, lng: 18.6857039 },
    { lat: 47.9599308, lng: 18.6855778 },
    { lat: 47.9600834, lng: 18.6851253 },
    { lat: 47.9603509, lng: 18.6850239 },
    { lat: 47.960838, lng: 18.685479 },
    { lat: 47.9610066, lng: 18.6855724 },
    { lat: 47.961136, lng: 18.6855661 },
    { lat: 47.9612966, lng: 18.6857879 },
    { lat: 47.9614784, lng: 18.6858716 },
    { lat: 47.9617196, lng: 18.6858691 },
    { lat: 47.9619224, lng: 18.6856555 },
    { lat: 47.9622609, lng: 18.6844502 },
    { lat: 47.9624654, lng: 18.6842054 },
    { lat: 47.9628404, lng: 18.6840081 },
    { lat: 47.9630323, lng: 18.6836744 },
    { lat: 47.9634052, lng: 18.6832504 },
    { lat: 47.9635536, lng: 18.6833688 },
    { lat: 47.9636308, lng: 18.6835457 },
    { lat: 47.9635046, lng: 18.6840274 },
    { lat: 47.9635779, lng: 18.684298 },
    { lat: 47.9637288, lng: 18.6843521 },
    { lat: 47.9642951, lng: 18.6841688 },
    { lat: 47.964693, lng: 18.6842474 },
    { lat: 47.9652143, lng: 18.6848564 },
    { lat: 47.9652431, lng: 18.6849927 },
    { lat: 47.9651944, lng: 18.685159 },
    { lat: 47.9653606, lng: 18.6857049 },
    { lat: 47.9655352, lng: 18.6858934 },
    { lat: 47.9657175, lng: 18.6859683 },
    { lat: 47.9658852, lng: 18.685836 },
    { lat: 47.9659738, lng: 18.6852011 },
    { lat: 47.9661883, lng: 18.6847879 },
    { lat: 47.9668925, lng: 18.6843967 },
    { lat: 47.9670957, lng: 18.6844828 },
    { lat: 47.9671274, lng: 18.6845716 },
    { lat: 47.9670007, lng: 18.6848334 },
    { lat: 47.9669717, lng: 18.685154 },
    { lat: 47.9670909, lng: 18.6857105 },
    { lat: 47.9670656, lng: 18.685911 },
    { lat: 47.9671559, lng: 18.6860339 },
    { lat: 47.9672446, lng: 18.6860497 },
    { lat: 47.9673753, lng: 18.6859657 },
    { lat: 47.967719, lng: 18.6852492 },
    { lat: 47.9682202, lng: 18.6844828 },
    { lat: 47.9684303, lng: 18.6842602 },
    { lat: 47.968665, lng: 18.6841603 },
    { lat: 47.9693285, lng: 18.6842265 },
    { lat: 47.9701935, lng: 18.6839774 },
    { lat: 47.9703174, lng: 18.6840967 },
    { lat: 47.9703155, lng: 18.6840621 },
    { lat: 47.9702967, lng: 18.6836951 },
    { lat: 47.9706821, lng: 18.6835634 },
    { lat: 47.9714578, lng: 18.6836816 },
    { lat: 47.9715958, lng: 18.6835032 },
    { lat: 47.9716546, lng: 18.6832952 },
    { lat: 47.9722278, lng: 18.682903 },
    { lat: 47.9724169, lng: 18.6829824 },
    { lat: 47.9723761, lng: 18.6828757 },
    { lat: 47.9722363, lng: 18.6824976 },
    { lat: 47.9720566, lng: 18.6823255 },
    { lat: 47.971727, lng: 18.6811734 },
    { lat: 47.9708968, lng: 18.679982 },
    { lat: 47.9704102, lng: 18.6791601 },
    { lat: 47.9706642, lng: 18.6764559 },
    { lat: 47.9706718, lng: 18.6757008 },
    { lat: 47.9710484, lng: 18.6749249 },
    { lat: 47.9711048, lng: 18.6746746 },
    { lat: 47.9712865, lng: 18.6726361 },
    { lat: 47.9712506, lng: 18.6720171 },
    { lat: 47.9712835, lng: 18.6716236 },
    { lat: 47.9712468, lng: 18.667824 },
    { lat: 47.9713214, lng: 18.6651839 },
    { lat: 47.971259, lng: 18.6638898 },
    { lat: 47.9712961, lng: 18.6634486 },
    { lat: 47.9710746, lng: 18.6632964 },
    { lat: 47.9709252, lng: 18.6611875 },
    { lat: 47.9707814, lng: 18.6613192 },
    { lat: 47.9701909, lng: 18.6623064 },
    { lat: 47.9687407, lng: 18.6604815 },
    { lat: 47.9671543, lng: 18.6578133 },
    { lat: 47.9673735, lng: 18.6576313 },
    { lat: 47.9683385, lng: 18.6573522 },
    { lat: 47.968323, lng: 18.6570064 },
    { lat: 47.968024, lng: 18.6569195 },
    { lat: 47.9675962, lng: 18.6570805 },
    { lat: 47.9672351, lng: 18.6572461 },
    { lat: 47.9670383, lng: 18.6575049 },
    { lat: 47.9664912, lng: 18.6565698 },
    { lat: 47.9661847, lng: 18.6561566 },
    { lat: 47.9663711, lng: 18.6558509 },
    { lat: 47.9660789, lng: 18.6560149 },
    { lat: 47.9655638, lng: 18.6546942 },
    { lat: 47.9650468, lng: 18.6536704 },
    { lat: 47.9647949, lng: 18.6529354 },
    { lat: 47.9644662, lng: 18.6532143 },
    { lat: 47.9641184, lng: 18.6526297 },
    { lat: 47.9642988, lng: 18.6518778 },
    { lat: 47.9635839, lng: 18.651802 },
    { lat: 47.9635899, lng: 18.6515772 },
    { lat: 47.963376, lng: 18.6515639 },
    { lat: 47.9634147, lng: 18.651032 },
    { lat: 47.9624349, lng: 18.6508306 },
    { lat: 47.9619603, lng: 18.6489478 },
    { lat: 47.9619089, lng: 18.6485187 },
    { lat: 47.9620135, lng: 18.6478733 },
    { lat: 47.9626482, lng: 18.6455038 },
    { lat: 47.9641853, lng: 18.6388042 },
    { lat: 47.9648081, lng: 18.6373987 },
    { lat: 47.9654703, lng: 18.6350858 },
    { lat: 47.9665122, lng: 18.632345 },
    { lat: 47.969017, lng: 18.6269016 },
    { lat: 47.9697289, lng: 18.6244884 },
    { lat: 47.970941, lng: 18.6193983 },
    { lat: 47.9712339, lng: 18.6169113 },
    { lat: 47.9712824, lng: 18.6154088 },
    { lat: 47.9713156, lng: 18.6103188 },
    { lat: 47.9712563, lng: 18.610289 },
    { lat: 47.9701834, lng: 18.6097265 },
    { lat: 47.9693142, lng: 18.6090766 },
    { lat: 47.9679405, lng: 18.6083764 },
    { lat: 47.9662817, lng: 18.6076415 },
    { lat: 47.9635934, lng: 18.6060072 },
    { lat: 47.9610599, lng: 18.6038837 },
    { lat: 47.9604685, lng: 18.6039791 },
    { lat: 47.9597056, lng: 18.6037043 },
    { lat: 47.95928, lng: 18.6034519 },
    { lat: 47.958651, lng: 18.6035846 },
    { lat: 47.9580118, lng: 18.6034887 },
    { lat: 47.957993, lng: 18.6036142 },
    { lat: 47.9573197, lng: 18.6050197 },
    { lat: 47.956995, lng: 18.6058227 },
    { lat: 47.954859, lng: 18.6104468 },
    { lat: 47.9542403, lng: 18.6116938 },
    { lat: 47.9540581, lng: 18.6122163 },
    { lat: 47.9532324, lng: 18.6140738 },
    { lat: 47.9511675, lng: 18.6185373 },
    { lat: 47.950659, lng: 18.6178572 },
    { lat: 47.9505639, lng: 18.6173751 },
    { lat: 47.9504539, lng: 18.6156149 },
    { lat: 47.9505403, lng: 18.6154394 },
    { lat: 47.950413, lng: 18.6152156 },
    { lat: 47.9502881, lng: 18.6151199 },
    { lat: 47.9501345, lng: 18.6151149 },
    { lat: 47.9492882, lng: 18.6154377 },
    { lat: 47.9488743, lng: 18.6159938 },
    { lat: 47.9485247, lng: 18.6168024 },
    { lat: 47.9482249, lng: 18.6170125 },
    { lat: 47.9481117, lng: 18.6173534 },
    { lat: 47.9476686, lng: 18.6173595 },
    { lat: 47.9475495, lng: 18.6172438 },
    { lat: 47.947442, lng: 18.617415 },
    { lat: 47.947267, lng: 18.617505 },
    { lat: 47.94682, lng: 18.617092 },
    { lat: 47.946719, lng: 18.616927 },
    { lat: 47.946532, lng: 18.616866 },
    { lat: 47.946276, lng: 18.616988 },
    { lat: 47.946097, lng: 18.617487 },
    { lat: 47.946012, lng: 18.617677 },
    { lat: 47.945902, lng: 18.617845 },
    { lat: 47.945674, lng: 18.617989 },
    { lat: 47.945368, lng: 18.618119 },
    { lat: 47.945769, lng: 18.618697 },
    { lat: 47.946459, lng: 18.619689 },
    { lat: 47.946913, lng: 18.62034 },
    { lat: 47.947447, lng: 18.62103 },
    { lat: 47.948475, lng: 18.622277 },
    { lat: 47.948812, lng: 18.622572 },
    { lat: 47.949169, lng: 18.622952 },
    { lat: 47.949695, lng: 18.62351 },
    { lat: 47.950804, lng: 18.62479 },
    { lat: 47.951332, lng: 18.62525 },
    { lat: 47.950661, lng: 18.627271 },
    { lat: 47.950091, lng: 18.629184 },
    { lat: 47.94978, lng: 18.630179 },
    { lat: 47.949657, lng: 18.630536 },
    { lat: 47.948819, lng: 18.632716 },
    { lat: 47.947864, lng: 18.635454 },
    { lat: 47.947432, lng: 18.636583 },
    { lat: 47.947396, lng: 18.63669 },
    { lat: 47.947081, lng: 18.63762 },
    { lat: 47.946611, lng: 18.639184 },
    { lat: 47.94624, lng: 18.640377 },
    { lat: 47.945916, lng: 18.641381 },
    { lat: 47.945717, lng: 18.642012 },
    { lat: 47.945491, lng: 18.642976 },
    { lat: 47.945437, lng: 18.643235 },
    { lat: 47.945227, lng: 18.644606 },
    { lat: 47.945103, lng: 18.645413 },
    { lat: 47.94497, lng: 18.64769 },
    { lat: 47.944961, lng: 18.647859 },
    { lat: 47.944871, lng: 18.649311 },
    { lat: 47.94484, lng: 18.650105 },
    { lat: 47.944544, lng: 18.649786 },
    { lat: 47.944396, lng: 18.649699 },
    { lat: 47.944275, lng: 18.649672 },
    { lat: 47.944015, lng: 18.649711 },
    { lat: 47.943894, lng: 18.64974 },
    { lat: 47.943738, lng: 18.64979 },
    { lat: 47.943489, lng: 18.64991 },
    { lat: 47.942933, lng: 18.650281 },
    { lat: 47.941482, lng: 18.650623 },
    { lat: 47.9411, lng: 18.65068 },
    { lat: 47.941056, lng: 18.651158 },
    { lat: 47.940964, lng: 18.652055 },
    { lat: 47.940767, lng: 18.653872 },
    { lat: 47.940726, lng: 18.654295 },
    { lat: 47.940677, lng: 18.654726 },
    { lat: 47.940587, lng: 18.655663 },
    { lat: 47.940306, lng: 18.656247 },
    { lat: 47.939957, lng: 18.657134 },
    { lat: 47.939912, lng: 18.657247 },
    { lat: 47.939756, lng: 18.657661 },
    { lat: 47.939712, lng: 18.657782 },
    { lat: 47.939868, lng: 18.658009 },
    { lat: 47.939965, lng: 18.658152 },
    { lat: 47.940155, lng: 18.658625 },
    { lat: 47.940498, lng: 18.659457 },
    { lat: 47.940311, lng: 18.661077 },
    { lat: 47.940345, lng: 18.661476 },
    { lat: 47.940414, lng: 18.661819 },
    { lat: 47.940468, lng: 18.662228 },
    { lat: 47.940377, lng: 18.66288 },
    { lat: 47.940352, lng: 18.662983 },
    { lat: 47.94017, lng: 18.663603 },
    { lat: 47.940052, lng: 18.664728 },
    { lat: 47.939525, lng: 18.665842 },
    { lat: 47.93911, lng: 18.666617 },
    { lat: 47.938825, lng: 18.667258 },
  ],
  Čaka: [
    { lat: 48.034963, lng: 18.441324 },
    { lat: 48.034931, lng: 18.441344 },
    { lat: 48.034789, lng: 18.441446 },
    { lat: 48.034767, lng: 18.441458 },
    { lat: 48.034614, lng: 18.441583 },
    { lat: 48.034462, lng: 18.441748 },
    { lat: 48.034387, lng: 18.441837 },
    { lat: 48.034107, lng: 18.442234 },
    { lat: 48.033902, lng: 18.442536 },
    { lat: 48.033759, lng: 18.442724 },
    { lat: 48.033608, lng: 18.442939 },
    { lat: 48.033532, lng: 18.443026 },
    { lat: 48.033288, lng: 18.443328 },
    { lat: 48.033138, lng: 18.443486 },
    { lat: 48.032973, lng: 18.443643 },
    { lat: 48.032539, lng: 18.444133 },
    { lat: 48.032315, lng: 18.444373 },
    { lat: 48.032127, lng: 18.444568 },
    { lat: 48.032108, lng: 18.444588 },
    { lat: 48.032058, lng: 18.44464 },
    { lat: 48.031914, lng: 18.444838 },
    { lat: 48.031856, lng: 18.444942 },
    { lat: 48.031833, lng: 18.445009 },
    { lat: 48.031761, lng: 18.445199 },
    { lat: 48.031209, lng: 18.445961 },
    { lat: 48.031181, lng: 18.446002 },
    { lat: 48.031081, lng: 18.446139 },
    { lat: 48.030923, lng: 18.446328 },
    { lat: 48.030675, lng: 18.446602 },
    { lat: 48.030525, lng: 18.446764 },
    { lat: 48.029846, lng: 18.447279 },
    { lat: 48.029602, lng: 18.447468 },
    { lat: 48.028927, lng: 18.447891 },
    { lat: 48.028463, lng: 18.448073 },
    { lat: 48.028451, lng: 18.448089 },
    { lat: 48.028179, lng: 18.448431 },
    { lat: 48.027852, lng: 18.448698 },
    { lat: 48.026841, lng: 18.44925 },
    { lat: 48.025925, lng: 18.449979 },
    { lat: 48.025098, lng: 18.449569 },
    { lat: 48.024849, lng: 18.450104 },
    { lat: 48.024564, lng: 18.45065 },
    { lat: 48.024342, lng: 18.451104 },
    { lat: 48.024155, lng: 18.451573 },
    { lat: 48.023602, lng: 18.452705 },
    { lat: 48.023384, lng: 18.453413 },
    { lat: 48.023049, lng: 18.454076 },
    { lat: 48.022765, lng: 18.45502 },
    { lat: 48.022694, lng: 18.45571 },
    { lat: 48.022422, lng: 18.456367 },
    { lat: 48.022278, lng: 18.456806 },
    { lat: 48.022144, lng: 18.457124 },
    { lat: 48.021843, lng: 18.457643 },
    { lat: 48.021757, lng: 18.45793 },
    { lat: 48.021637, lng: 18.458843 },
    { lat: 48.021637, lng: 18.459186 },
    { lat: 48.021611, lng: 18.459579 },
    { lat: 48.021558, lng: 18.459802 },
    { lat: 48.021287, lng: 18.460544 },
    { lat: 48.021191, lng: 18.460917 },
    { lat: 48.021078, lng: 18.461245 },
    { lat: 48.02099, lng: 18.461445 },
    { lat: 48.020705, lng: 18.461843 },
    { lat: 48.020582, lng: 18.462109 },
    { lat: 48.020711, lng: 18.4622503 },
    { lat: 48.0216338, lng: 18.4623709 },
    { lat: 48.0222768, lng: 18.462772 },
    { lat: 48.0227012, lng: 18.4627985 },
    { lat: 48.0229648, lng: 18.4628908 },
    { lat: 48.0234453, lng: 18.4632148 },
    { lat: 48.0239788, lng: 18.4632905 },
    { lat: 48.0254913, lng: 18.4645589 },
    { lat: 48.0272731, lng: 18.4667659 },
    { lat: 48.0275082, lng: 18.467217 },
    { lat: 48.0283171, lng: 18.4688102 },
    { lat: 48.0268299, lng: 18.4689879 },
    { lat: 48.0244001, lng: 18.4731467 },
    { lat: 48.023208, lng: 18.4753564 },
    { lat: 48.0253191, lng: 18.4789994 },
    { lat: 48.0282876, lng: 18.4786259 },
    { lat: 48.0298432, lng: 18.4786243 },
    { lat: 48.0321348, lng: 18.4783055 },
    { lat: 48.0332731, lng: 18.4812179 },
    { lat: 48.0347637, lng: 18.4841188 },
    { lat: 48.0353156, lng: 18.4861281 },
    { lat: 48.0350889, lng: 18.4886562 },
    { lat: 48.0349931, lng: 18.4903821 },
    { lat: 48.0353917, lng: 18.4900007 },
    { lat: 48.0357995, lng: 18.4896489 },
    { lat: 48.037072, lng: 18.48827 },
    { lat: 48.0372825, lng: 18.4879365 },
    { lat: 48.0381768, lng: 18.4868818 },
    { lat: 48.0390113, lng: 18.4862074 },
    { lat: 48.040346, lng: 18.4885803 },
    { lat: 48.0407382, lng: 18.4896674 },
    { lat: 48.0413182, lng: 18.4904211 },
    { lat: 48.0417379, lng: 18.4910685 },
    { lat: 48.0421435, lng: 18.4919112 },
    { lat: 48.0423335, lng: 18.492445 },
    { lat: 48.0424116, lng: 18.4930966 },
    { lat: 48.0424391, lng: 18.4946481 },
    { lat: 48.0421646, lng: 18.4964235 },
    { lat: 48.042291, lng: 18.4973987 },
    { lat: 48.0423642, lng: 18.4977415 },
    { lat: 48.042619, lng: 18.4983663 },
    { lat: 48.043515, lng: 18.4996507 },
    { lat: 48.0439121, lng: 18.5006031 },
    { lat: 48.0442071, lng: 18.5017888 },
    { lat: 48.0446221, lng: 18.5042023 },
    { lat: 48.0476545, lng: 18.5097685 },
    { lat: 48.0491657, lng: 18.5123454 },
    { lat: 48.0496395, lng: 18.5130157 },
    { lat: 48.050701, lng: 18.5139088 },
    { lat: 48.0521738, lng: 18.5160967 },
    { lat: 48.0529184, lng: 18.5170759 },
    { lat: 48.053309, lng: 18.5175263 },
    { lat: 48.0533519, lng: 18.5174896 },
    { lat: 48.0556196, lng: 18.5089328 },
    { lat: 48.0559868, lng: 18.5073593 },
    { lat: 48.0552708, lng: 18.5063509 },
    { lat: 48.0545274, lng: 18.5051125 },
    { lat: 48.0531547, lng: 18.5023138 },
    { lat: 48.0521836, lng: 18.5004894 },
    { lat: 48.0504821, lng: 18.4967699 },
    { lat: 48.0501123, lng: 18.4955365 },
    { lat: 48.0500792, lng: 18.495108 },
    { lat: 48.0502618, lng: 18.4911519 },
    { lat: 48.0502217, lng: 18.4908555 },
    { lat: 48.0497183, lng: 18.4891899 },
    { lat: 48.0493826, lng: 18.4884287 },
    { lat: 48.0491408, lng: 18.4874123 },
    { lat: 48.0490017, lng: 18.487181 },
    { lat: 48.0488455, lng: 18.4866476 },
    { lat: 48.0484384, lng: 18.4856753 },
    { lat: 48.0481535, lng: 18.484814 },
    { lat: 48.0478851, lng: 18.4837435 },
    { lat: 48.0477631, lng: 18.4828096 },
    { lat: 48.0477469, lng: 18.4822997 },
    { lat: 48.0478028, lng: 18.481441 },
    { lat: 48.0478948, lng: 18.4809663 },
    { lat: 48.0482767, lng: 18.4800869 },
    { lat: 48.04901, lng: 18.4789348 },
    { lat: 48.0487692, lng: 18.4766395 },
    { lat: 48.0478092, lng: 18.4748831 },
    { lat: 48.0475881, lng: 18.4730657 },
    { lat: 48.0472844, lng: 18.4719865 },
    { lat: 48.0449814, lng: 18.467193 },
    { lat: 48.0452973, lng: 18.4669739 },
    { lat: 48.0443368, lng: 18.4649073 },
    { lat: 48.0461155, lng: 18.4636108 },
    { lat: 48.0461092, lng: 18.4635559 },
    { lat: 48.0463167, lng: 18.4628123 },
    { lat: 48.0458245, lng: 18.461616 },
    { lat: 48.0455366, lng: 18.4605805 },
    { lat: 48.0447047, lng: 18.458536 },
    { lat: 48.0437472, lng: 18.4556217 },
    { lat: 48.0436309, lng: 18.4547305 },
    { lat: 48.0435078, lng: 18.4543852 },
    { lat: 48.0434311, lng: 18.4537433 },
    { lat: 48.0434466, lng: 18.4525086 },
    { lat: 48.0432669, lng: 18.4517988 },
    { lat: 48.043249, lng: 18.4515384 },
    { lat: 48.0429773, lng: 18.4506398 },
    { lat: 48.0431325, lng: 18.4502285 },
    { lat: 48.043448, lng: 18.4500192 },
    { lat: 48.0441614, lng: 18.4493021 },
    { lat: 48.0447879, lng: 18.4489476 },
    { lat: 48.0454298, lng: 18.4484226 },
    { lat: 48.0464175, lng: 18.4478292 },
    { lat: 48.0469718, lng: 18.4474135 },
    { lat: 48.048673, lng: 18.44651 },
    { lat: 48.048655, lng: 18.446493 },
    { lat: 48.048527, lng: 18.446412 },
    { lat: 48.048455, lng: 18.446366 },
    { lat: 48.047995, lng: 18.44619 },
    { lat: 48.047267, lng: 18.446345 },
    { lat: 48.046659, lng: 18.446472 },
    { lat: 48.046439, lng: 18.446386 },
    { lat: 48.045476, lng: 18.445994 },
    { lat: 48.045135, lng: 18.445611 },
    { lat: 48.044729, lng: 18.445144 },
    { lat: 48.044362, lng: 18.444723 },
    { lat: 48.043655, lng: 18.444345 },
    { lat: 48.042959, lng: 18.443854 },
    { lat: 48.04291, lng: 18.443813 },
    { lat: 48.042889, lng: 18.443791 },
    { lat: 48.042407, lng: 18.443346 },
    { lat: 48.042201, lng: 18.443009 },
    { lat: 48.04215, lng: 18.442927 },
    { lat: 48.042128, lng: 18.442891 },
    { lat: 48.041954, lng: 18.442608 },
    { lat: 48.041927, lng: 18.442565 },
    { lat: 48.041919, lng: 18.442555 },
    { lat: 48.041902, lng: 18.442528 },
    { lat: 48.041135, lng: 18.441319 },
    { lat: 48.041034, lng: 18.441162 },
    { lat: 48.041032, lng: 18.441157 },
    { lat: 48.041031, lng: 18.441156 },
    { lat: 48.040994, lng: 18.441092 },
    { lat: 48.040993, lng: 18.44109 },
    { lat: 48.040991, lng: 18.441085 },
    { lat: 48.040558, lng: 18.440374 },
    { lat: 48.039919, lng: 18.439648 },
    { lat: 48.038604, lng: 18.439599 },
    { lat: 48.037803, lng: 18.439585 },
    { lat: 48.037665, lng: 18.439599 },
    { lat: 48.037455, lng: 18.439619 },
    { lat: 48.037039, lng: 18.439731 },
    { lat: 48.036716, lng: 18.439886 },
    { lat: 48.036602, lng: 18.43994 },
    { lat: 48.036552, lng: 18.439966 },
    { lat: 48.036503, lng: 18.43999 },
    { lat: 48.036453, lng: 18.440015 },
    { lat: 48.036407, lng: 18.440035 },
    { lat: 48.036345, lng: 18.44007 },
    { lat: 48.036319, lng: 18.440083 },
    { lat: 48.036273, lng: 18.44011 },
    { lat: 48.036225, lng: 18.440139 },
    { lat: 48.03615, lng: 18.440182 },
    { lat: 48.036105, lng: 18.440207 },
    { lat: 48.03606, lng: 18.440234 },
    { lat: 48.036018, lng: 18.440259 },
    { lat: 48.035943, lng: 18.440301 },
    { lat: 48.035853, lng: 18.440373 },
    { lat: 48.035816, lng: 18.440403 },
    { lat: 48.035775, lng: 18.440437 },
    { lat: 48.035739, lng: 18.440467 },
    { lat: 48.035663, lng: 18.440527 },
    { lat: 48.035582, lng: 18.440591 },
    { lat: 48.035562, lng: 18.440611 },
    { lat: 48.035523, lng: 18.440638 },
    { lat: 48.0355, lng: 18.440658 },
    { lat: 48.035465, lng: 18.440688 },
    { lat: 48.035432, lng: 18.440715 },
    { lat: 48.035368, lng: 18.440775 },
    { lat: 48.035326, lng: 18.440824 },
    { lat: 48.035297, lng: 18.440868 },
    { lat: 48.03528, lng: 18.440886 },
    { lat: 48.035264, lng: 18.440909 },
    { lat: 48.035203, lng: 18.440995 },
    { lat: 48.035168, lng: 18.441045 },
    { lat: 48.03513, lng: 18.441094 },
    { lat: 48.035097, lng: 18.441141 },
    { lat: 48.035036, lng: 18.441223 },
    { lat: 48.035002, lng: 18.441273 },
    { lat: 48.034963, lng: 18.441324 },
  ],
  PohronskýRuskov: [
    { lat: 47.9713156, lng: 18.6103188 },
    { lat: 47.9712824, lng: 18.6154088 },
    { lat: 47.9712339, lng: 18.6169113 },
    { lat: 47.970941, lng: 18.6193983 },
    { lat: 47.9697289, lng: 18.6244884 },
    { lat: 47.969017, lng: 18.6269016 },
    { lat: 47.9665122, lng: 18.632345 },
    { lat: 47.9654703, lng: 18.6350858 },
    { lat: 47.9648081, lng: 18.6373987 },
    { lat: 47.9641853, lng: 18.6388042 },
    { lat: 47.9626482, lng: 18.6455038 },
    { lat: 47.9620135, lng: 18.6478733 },
    { lat: 47.9619089, lng: 18.6485187 },
    { lat: 47.9619603, lng: 18.6489478 },
    { lat: 47.9624349, lng: 18.6508306 },
    { lat: 47.9634147, lng: 18.651032 },
    { lat: 47.963376, lng: 18.6515639 },
    { lat: 47.9635899, lng: 18.6515772 },
    { lat: 47.9635839, lng: 18.651802 },
    { lat: 47.9642988, lng: 18.6518778 },
    { lat: 47.9641184, lng: 18.6526297 },
    { lat: 47.9644662, lng: 18.6532143 },
    { lat: 47.9647949, lng: 18.6529354 },
    { lat: 47.9650468, lng: 18.6536704 },
    { lat: 47.9655638, lng: 18.6546942 },
    { lat: 47.9660789, lng: 18.6560149 },
    { lat: 47.9663711, lng: 18.6558509 },
    { lat: 47.9661847, lng: 18.6561566 },
    { lat: 47.9664912, lng: 18.6565698 },
    { lat: 47.9670383, lng: 18.6575049 },
    { lat: 47.9672351, lng: 18.6572461 },
    { lat: 47.9675962, lng: 18.6570805 },
    { lat: 47.968024, lng: 18.6569195 },
    { lat: 47.968323, lng: 18.6570064 },
    { lat: 47.9683385, lng: 18.6573522 },
    { lat: 47.9673735, lng: 18.6576313 },
    { lat: 47.9671543, lng: 18.6578133 },
    { lat: 47.9687407, lng: 18.6604815 },
    { lat: 47.9701909, lng: 18.6623064 },
    { lat: 47.9707814, lng: 18.6613192 },
    { lat: 47.9709252, lng: 18.6611875 },
    { lat: 47.9710746, lng: 18.6632964 },
    { lat: 47.9712961, lng: 18.6634486 },
    { lat: 47.971259, lng: 18.6638898 },
    { lat: 47.9713214, lng: 18.6651839 },
    { lat: 47.9712468, lng: 18.667824 },
    { lat: 47.9712835, lng: 18.6716236 },
    { lat: 47.9712506, lng: 18.6720171 },
    { lat: 47.9712865, lng: 18.6726361 },
    { lat: 47.9711048, lng: 18.6746746 },
    { lat: 47.9710484, lng: 18.6749249 },
    { lat: 47.9706718, lng: 18.6757008 },
    { lat: 47.9706642, lng: 18.6764559 },
    { lat: 47.9704102, lng: 18.6791601 },
    { lat: 47.9708968, lng: 18.679982 },
    { lat: 47.971727, lng: 18.6811734 },
    { lat: 47.9720566, lng: 18.6823255 },
    { lat: 47.9722363, lng: 18.6824976 },
    { lat: 47.9723761, lng: 18.6828757 },
    { lat: 47.9724169, lng: 18.6829824 },
    { lat: 47.9726442, lng: 18.6830347 },
    { lat: 47.9727084, lng: 18.6827176 },
    { lat: 47.9726441, lng: 18.6824462 },
    { lat: 47.9728585, lng: 18.6819932 },
    { lat: 47.9730974, lng: 18.6816732 },
    { lat: 47.9731556, lng: 18.6812709 },
    { lat: 47.9737223, lng: 18.680593 },
    { lat: 47.9737798, lng: 18.68059 },
    { lat: 47.9738694, lng: 18.6807052 },
    { lat: 47.9739328, lng: 18.6810422 },
    { lat: 47.9742024, lng: 18.6813334 },
    { lat: 47.9744593, lng: 18.6811413 },
    { lat: 47.9746828, lng: 18.6810836 },
    { lat: 47.9749078, lng: 18.6811572 },
    { lat: 47.9751995, lng: 18.6813588 },
    { lat: 47.97534, lng: 18.6813272 },
    { lat: 47.9754432, lng: 18.6810663 },
    { lat: 47.9754696, lng: 18.6803454 },
    { lat: 47.9753711, lng: 18.6800482 },
    { lat: 47.9753693, lng: 18.6797221 },
    { lat: 47.9754144, lng: 18.6795341 },
    { lat: 47.9755808, lng: 18.6793152 },
    { lat: 47.9758099, lng: 18.6791733 },
    { lat: 47.9758611, lng: 18.6791795 },
    { lat: 47.9760898, lng: 18.6795724 },
    { lat: 47.9763283, lng: 18.6796029 },
    { lat: 47.9766748, lng: 18.6799833 },
    { lat: 47.9773671, lng: 18.6805613 },
    { lat: 47.9776938, lng: 18.6809294 },
    { lat: 47.9779786, lng: 18.6808389 },
    { lat: 47.9782347, lng: 18.6808579 },
    { lat: 47.9782469, lng: 18.6808134 },
    { lat: 47.9783509, lng: 18.6808788 },
    { lat: 47.9784536, lng: 18.6810076 },
    { lat: 47.9784641, lng: 18.6813678 },
    { lat: 47.9782192, lng: 18.6815947 },
    { lat: 47.9780926, lng: 18.6819446 },
    { lat: 47.9780464, lng: 18.6819649 },
    { lat: 47.9780473, lng: 18.6821479 },
    { lat: 47.9781507, lng: 18.6823301 },
    { lat: 47.9785005, lng: 18.6822681 },
    { lat: 47.9788572, lng: 18.6823179 },
    { lat: 47.9790643, lng: 18.6824543 },
    { lat: 47.9792075, lng: 18.6824533 },
    { lat: 47.9793598, lng: 18.6822886 },
    { lat: 47.9793873, lng: 18.6819629 },
    { lat: 47.9793047, lng: 18.6817081 },
    { lat: 47.9793278, lng: 18.6815103 },
    { lat: 47.9797326, lng: 18.6810721 },
    { lat: 47.9798736, lng: 18.6802135 },
    { lat: 47.9800933, lng: 18.6800235 },
    { lat: 47.9803778, lng: 18.6802808 },
    { lat: 47.9805019, lng: 18.6801762 },
    { lat: 47.9806273, lng: 18.679864 },
    { lat: 47.980741, lng: 18.6797979 },
    { lat: 47.980995, lng: 18.6798671 },
    { lat: 47.9814546, lng: 18.679812 },
    { lat: 47.981757, lng: 18.679731 },
    { lat: 47.9821129, lng: 18.6793901 },
    { lat: 47.9823313, lng: 18.6794216 },
    { lat: 47.982266, lng: 18.6787603 },
    { lat: 47.9818343, lng: 18.6765331 },
    { lat: 47.9816466, lng: 18.6748997 },
    { lat: 47.9815638, lng: 18.6746182 },
    { lat: 47.9816561, lng: 18.6744469 },
    { lat: 47.9816992, lng: 18.6738288 },
    { lat: 47.9825761, lng: 18.6701501 },
    { lat: 47.983606, lng: 18.6675075 },
    { lat: 47.983259, lng: 18.666435 },
    { lat: 47.9828394, lng: 18.6659747 },
    { lat: 47.9865615, lng: 18.6570684 },
    { lat: 47.9867262, lng: 18.6556667 },
    { lat: 47.9867279, lng: 18.6555933 },
    { lat: 47.9868508, lng: 18.6556123 },
    { lat: 47.9876759, lng: 18.6546991 },
    { lat: 47.9878338, lng: 18.6545243 },
    { lat: 47.9887774, lng: 18.654682 },
    { lat: 47.9888201, lng: 18.6535576 },
    { lat: 47.9868067, lng: 18.653307 },
    { lat: 47.9876585, lng: 18.6293906 },
    { lat: 47.987364, lng: 18.6283925 },
    { lat: 47.9866734, lng: 18.6265471 },
    { lat: 47.9865236, lng: 18.6266134 },
    { lat: 47.9863913, lng: 18.6268354 },
    { lat: 47.9861901, lng: 18.62699 },
    { lat: 47.9853657, lng: 18.627488 },
    { lat: 47.9839885, lng: 18.6287298 },
    { lat: 47.9836457, lng: 18.6288959 },
    { lat: 47.9834438, lng: 18.6291692 },
    { lat: 47.9792397, lng: 18.624013 },
    { lat: 47.9769428, lng: 18.6156217 },
    { lat: 47.9768406, lng: 18.6130081 },
    { lat: 47.9768966, lng: 18.6116373 },
    { lat: 47.9770787, lng: 18.6096249 },
    { lat: 47.9768401, lng: 18.6094725 },
    { lat: 47.9760193, lng: 18.6092497 },
    { lat: 47.9753203, lng: 18.6089674 },
    { lat: 47.9742536, lng: 18.6083351 },
    { lat: 47.972335, lng: 18.606689 },
    { lat: 47.971372, lng: 18.6096815 },
    { lat: 47.9713156, lng: 18.6103188 },
  ],
  Málaš: [
    { lat: 48.0349931, lng: 18.4903821 },
    { lat: 48.034983, lng: 18.4904207 },
    { lat: 48.033364, lng: 18.4946923 },
    { lat: 48.0327313, lng: 18.496844 },
    { lat: 48.0320953, lng: 18.4987951 },
    { lat: 48.0312548, lng: 18.5007017 },
    { lat: 48.0303137, lng: 18.5024596 },
    { lat: 48.0294292, lng: 18.5042644 },
    { lat: 48.0302749, lng: 18.5078476 },
    { lat: 48.0313934, lng: 18.5119407 },
    { lat: 48.0325698, lng: 18.5166344 },
    { lat: 48.0316125, lng: 18.5194692 },
    { lat: 48.0307685, lng: 18.5221702 },
    { lat: 48.0343965, lng: 18.5266531 },
    { lat: 48.0386557, lng: 18.5320688 },
    { lat: 48.040086, lng: 18.5343926 },
    { lat: 48.0426041, lng: 18.5383163 },
    { lat: 48.0454223, lng: 18.5424253 },
    { lat: 48.0448276, lng: 18.5435854 },
    { lat: 48.0457901, lng: 18.5473304 },
    { lat: 48.0459179, lng: 18.5473168 },
    { lat: 48.048597, lng: 18.5506662 },
    { lat: 48.052282, lng: 18.548291 },
    { lat: 48.0574436, lng: 18.5389943 },
    { lat: 48.0600301, lng: 18.5421143 },
    { lat: 48.0633562, lng: 18.5463582 },
    { lat: 48.0663891, lng: 18.5489674 },
    { lat: 48.069812, lng: 18.5503895 },
    { lat: 48.0760127, lng: 18.5464007 },
    { lat: 48.0768643, lng: 18.5457881 },
    { lat: 48.0775662, lng: 18.5454035 },
    { lat: 48.0812785, lng: 18.5430094 },
    { lat: 48.0794165, lng: 18.5408127 },
    { lat: 48.0801746, lng: 18.5391346 },
    { lat: 48.0781382, lng: 18.5368637 },
    { lat: 48.078161, lng: 18.5363995 },
    { lat: 48.0784172, lng: 18.5358505 },
    { lat: 48.0755566, lng: 18.5322217 },
    { lat: 48.0742416, lng: 18.5308786 },
    { lat: 48.0735708, lng: 18.530418 },
    { lat: 48.071363, lng: 18.5281494 },
    { lat: 48.0698711, lng: 18.5263663 },
    { lat: 48.0678505, lng: 18.523797 },
    { lat: 48.0670848, lng: 18.5223305 },
    { lat: 48.0664384, lng: 18.5207629 },
    { lat: 48.0642554, lng: 18.5159984 },
    { lat: 48.0634602, lng: 18.5144518 },
    { lat: 48.0627404, lng: 18.5131901 },
    { lat: 48.0646878, lng: 18.5102684 },
    { lat: 48.0676812, lng: 18.5071101 },
    { lat: 48.0694888, lng: 18.5052918 },
    { lat: 48.0695579, lng: 18.5052551 },
    { lat: 48.0695427, lng: 18.5052238 },
    { lat: 48.0676605, lng: 18.5011552 },
    { lat: 48.065969, lng: 18.4968616 },
    { lat: 48.0632803, lng: 18.4896206 },
    { lat: 48.0617575, lng: 18.4851636 },
    { lat: 48.0613516, lng: 18.4835688 },
    { lat: 48.0611382, lng: 18.4824556 },
    { lat: 48.0610927, lng: 18.4819619 },
    { lat: 48.0610151, lng: 18.4819634 },
    { lat: 48.0605955, lng: 18.4822482 },
    { lat: 48.0604999, lng: 18.4823948 },
    { lat: 48.0604724, lng: 18.4826097 },
    { lat: 48.0602753, lng: 18.4830648 },
    { lat: 48.0599413, lng: 18.4835061 },
    { lat: 48.0597823, lng: 18.4838121 },
    { lat: 48.0595011, lng: 18.4838273 },
    { lat: 48.0591084, lng: 18.4840851 },
    { lat: 48.0589621, lng: 18.484094 },
    { lat: 48.0588291, lng: 18.4840541 },
    { lat: 48.058781, lng: 18.483979 },
    { lat: 48.058684, lng: 18.4835753 },
    { lat: 48.0584934, lng: 18.4835774 },
    { lat: 48.0581631, lng: 18.4837626 },
    { lat: 48.0572328, lng: 18.48391 },
    { lat: 48.0569879, lng: 18.484049 },
    { lat: 48.0568262, lng: 18.4839435 },
    { lat: 48.0566076, lng: 18.4836868 },
    { lat: 48.0563571, lng: 18.4836442 },
    { lat: 48.0555367, lng: 18.4842071 },
    { lat: 48.054862, lng: 18.4853563 },
    { lat: 48.0546391, lng: 18.4854062 },
    { lat: 48.0545638, lng: 18.4853274 },
    { lat: 48.0545775, lng: 18.4852516 },
    { lat: 48.0545219, lng: 18.485268 },
    { lat: 48.054138, lng: 18.4856248 },
    { lat: 48.053832, lng: 18.4860245 },
    { lat: 48.0536237, lng: 18.4861304 },
    { lat: 48.0536309, lng: 18.4858136 },
    { lat: 48.0535773, lng: 18.4854807 },
    { lat: 48.0534082, lng: 18.484775 },
    { lat: 48.0529953, lng: 18.4823454 },
    { lat: 48.0522571, lng: 18.4795077 },
    { lat: 48.0520665, lng: 18.4789211 },
    { lat: 48.0511809, lng: 18.477242 },
    { lat: 48.0513985, lng: 18.4769181 },
    { lat: 48.05114, lng: 18.4767933 },
    { lat: 48.0510208, lng: 18.4765596 },
    { lat: 48.0509657, lng: 18.4762798 },
    { lat: 48.0509744, lng: 18.4759268 },
    { lat: 48.0510399, lng: 18.4757488 },
    { lat: 48.0507904, lng: 18.4752978 },
    { lat: 48.0504583, lng: 18.4743416 },
    { lat: 48.0507653, lng: 18.47388 },
    { lat: 48.0499659, lng: 18.4726367 },
    { lat: 48.0499984, lng: 18.4722276 },
    { lat: 48.0494659, lng: 18.4707728 },
    { lat: 48.0493506, lng: 18.4700809 },
    { lat: 48.0481966, lng: 18.4685888 },
    { lat: 48.0479518, lng: 18.4674396 },
    { lat: 48.0473412, lng: 18.4668686 },
    { lat: 48.0471883, lng: 18.4663916 },
    { lat: 48.0471487, lng: 18.4657855 },
    { lat: 48.0465384, lng: 18.4646575 },
    { lat: 48.0461155, lng: 18.4636108 },
    { lat: 48.0443368, lng: 18.4649073 },
    { lat: 48.0452973, lng: 18.4669739 },
    { lat: 48.0449814, lng: 18.467193 },
    { lat: 48.0472844, lng: 18.4719865 },
    { lat: 48.0475881, lng: 18.4730657 },
    { lat: 48.0478092, lng: 18.4748831 },
    { lat: 48.0487692, lng: 18.4766395 },
    { lat: 48.04901, lng: 18.4789348 },
    { lat: 48.0482767, lng: 18.4800869 },
    { lat: 48.0478948, lng: 18.4809663 },
    { lat: 48.0478028, lng: 18.481441 },
    { lat: 48.0477469, lng: 18.4822997 },
    { lat: 48.0477631, lng: 18.4828096 },
    { lat: 48.0478851, lng: 18.4837435 },
    { lat: 48.0481535, lng: 18.484814 },
    { lat: 48.0484384, lng: 18.4856753 },
    { lat: 48.0488455, lng: 18.4866476 },
    { lat: 48.0490017, lng: 18.487181 },
    { lat: 48.0491408, lng: 18.4874123 },
    { lat: 48.0493826, lng: 18.4884287 },
    { lat: 48.0497183, lng: 18.4891899 },
    { lat: 48.0502217, lng: 18.4908555 },
    { lat: 48.0502618, lng: 18.4911519 },
    { lat: 48.0500792, lng: 18.495108 },
    { lat: 48.0501123, lng: 18.4955365 },
    { lat: 48.0504821, lng: 18.4967699 },
    { lat: 48.0521836, lng: 18.5004894 },
    { lat: 48.0531547, lng: 18.5023138 },
    { lat: 48.0545274, lng: 18.5051125 },
    { lat: 48.0552708, lng: 18.5063509 },
    { lat: 48.0559868, lng: 18.5073593 },
    { lat: 48.0556196, lng: 18.5089328 },
    { lat: 48.0533519, lng: 18.5174896 },
    { lat: 48.053309, lng: 18.5175263 },
    { lat: 48.0529184, lng: 18.5170759 },
    { lat: 48.0521738, lng: 18.5160967 },
    { lat: 48.050701, lng: 18.5139088 },
    { lat: 48.0496395, lng: 18.5130157 },
    { lat: 48.0491657, lng: 18.5123454 },
    { lat: 48.0476545, lng: 18.5097685 },
    { lat: 48.0446221, lng: 18.5042023 },
    { lat: 48.0442071, lng: 18.5017888 },
    { lat: 48.0439121, lng: 18.5006031 },
    { lat: 48.043515, lng: 18.4996507 },
    { lat: 48.042619, lng: 18.4983663 },
    { lat: 48.0423642, lng: 18.4977415 },
    { lat: 48.042291, lng: 18.4973987 },
    { lat: 48.0421646, lng: 18.4964235 },
    { lat: 48.0424391, lng: 18.4946481 },
    { lat: 48.0424116, lng: 18.4930966 },
    { lat: 48.0423335, lng: 18.492445 },
    { lat: 48.0421435, lng: 18.4919112 },
    { lat: 48.0417379, lng: 18.4910685 },
    { lat: 48.0413182, lng: 18.4904211 },
    { lat: 48.0407382, lng: 18.4896674 },
    { lat: 48.040346, lng: 18.4885803 },
    { lat: 48.0390113, lng: 18.4862074 },
    { lat: 48.0381768, lng: 18.4868818 },
    { lat: 48.0372825, lng: 18.4879365 },
    { lat: 48.037072, lng: 18.48827 },
    { lat: 48.0357995, lng: 18.4896489 },
    { lat: 48.0353917, lng: 18.4900007 },
    { lat: 48.0349931, lng: 18.4903821 },
  ],
  Tlmače: [
    { lat: 48.2986301, lng: 18.5328453 },
    { lat: 48.2967062, lng: 18.5304373 },
    { lat: 48.2964511, lng: 18.5300459 },
    { lat: 48.2960431, lng: 18.5296429 },
    { lat: 48.2954218, lng: 18.5291781 },
    { lat: 48.2948321, lng: 18.5290364 },
    { lat: 48.2949096, lng: 18.5283974 },
    { lat: 48.2948489, lng: 18.5283808 },
    { lat: 48.2954273, lng: 18.5250778 },
    { lat: 48.2957409, lng: 18.5229419 },
    { lat: 48.29819, lng: 18.5236503 },
    { lat: 48.3003768, lng: 18.5073773 },
    { lat: 48.2970102, lng: 18.5060385 },
    { lat: 48.2969304, lng: 18.506007 },
    { lat: 48.2968533, lng: 18.5059755 },
    { lat: 48.2967488, lng: 18.506065 },
    { lat: 48.2966083, lng: 18.5063994 },
    { lat: 48.2966063, lng: 18.5064086 },
    { lat: 48.2966338, lng: 18.5066415 },
    { lat: 48.2965624, lng: 18.5068367 },
    { lat: 48.2962984, lng: 18.5067557 },
    { lat: 48.2959456, lng: 18.5075368 },
    { lat: 48.2955096, lng: 18.5077496 },
    { lat: 48.2950459, lng: 18.5080757 },
    { lat: 48.2950966, lng: 18.5097075 },
    { lat: 48.2949615, lng: 18.509794 },
    { lat: 48.2942781, lng: 18.5088784 },
    { lat: 48.2943131, lng: 18.5087554 },
    { lat: 48.2941917, lng: 18.5082526 },
    { lat: 48.2939205, lng: 18.5077974 },
    { lat: 48.2937985, lng: 18.507815 },
    { lat: 48.2935259, lng: 18.5074935 },
    { lat: 48.291554, lng: 18.5108212 },
    { lat: 48.2889713, lng: 18.518449 },
    { lat: 48.288936, lng: 18.5183021 },
    { lat: 48.2887702, lng: 18.5180779 },
    { lat: 48.2883654, lng: 18.5177126 },
    { lat: 48.2880536, lng: 18.5175185 },
    { lat: 48.2878451, lng: 18.5174636 },
    { lat: 48.287717, lng: 18.5175322 },
    { lat: 48.2870667, lng: 18.5176263 },
    { lat: 48.2867295, lng: 18.5178122 },
    { lat: 48.286416, lng: 18.518315 },
    { lat: 48.2861388, lng: 18.5194329 },
    { lat: 48.2862322, lng: 18.5194705 },
    { lat: 48.2861361, lng: 18.5198929 },
    { lat: 48.2861048, lng: 18.5206158 },
    { lat: 48.2860417, lng: 18.5210858 },
    { lat: 48.2858388, lng: 18.5218654 },
    { lat: 48.2859931, lng: 18.5217184 },
    { lat: 48.2861093, lng: 18.5216991 },
    { lat: 48.2862089, lng: 18.5223175 },
    { lat: 48.2860019, lng: 18.522409 },
    { lat: 48.2852635, lng: 18.5235464 },
    { lat: 48.2848133, lng: 18.5240115 },
    { lat: 48.2847073, lng: 18.5240353 },
    { lat: 48.283945, lng: 18.5246099 },
    { lat: 48.2838921, lng: 18.5247142 },
    { lat: 48.283794, lng: 18.5247186 },
    { lat: 48.283711, lng: 18.524868 },
    { lat: 48.2834475, lng: 18.5246711 },
    { lat: 48.2827834, lng: 18.5245373 },
    { lat: 48.2823503, lng: 18.5245962 },
    { lat: 48.2817377, lng: 18.5245761 },
    { lat: 48.2808499, lng: 18.5249031 },
    { lat: 48.2802491, lng: 18.5254222 },
    { lat: 48.2797858, lng: 18.5259591 },
    { lat: 48.2796514, lng: 18.5262434 },
    { lat: 48.2790906, lng: 18.5270186 },
    { lat: 48.2786635, lng: 18.5269451 },
    { lat: 48.2783396, lng: 18.5268159 },
    { lat: 48.2778045, lng: 18.5265425 },
    { lat: 48.2774252, lng: 18.526214 },
    { lat: 48.2770222, lng: 18.5257178 },
    { lat: 48.2770128, lng: 18.5262201 },
    { lat: 48.2770551, lng: 18.5266408 },
    { lat: 48.2773405, lng: 18.5270914 },
    { lat: 48.2769532, lng: 18.5281875 },
    { lat: 48.2772409, lng: 18.5285358 },
    { lat: 48.2771557, lng: 18.5291823 },
    { lat: 48.277574, lng: 18.5293438 },
    { lat: 48.2775813, lng: 18.5296987 },
    { lat: 48.2775215, lng: 18.530209 },
    { lat: 48.2775502, lng: 18.5308549 },
    { lat: 48.2779332, lng: 18.5325463 },
    { lat: 48.2781484, lng: 18.533235 },
    { lat: 48.2786351, lng: 18.5337153 },
    { lat: 48.278704, lng: 18.533842 },
    { lat: 48.2788785, lng: 18.5345121 },
    { lat: 48.2792222, lng: 18.5364711 },
    { lat: 48.2793344, lng: 18.5374064 },
    { lat: 48.2800009, lng: 18.5408232 },
    { lat: 48.2797155, lng: 18.5409474 },
    { lat: 48.2798497, lng: 18.5414184 },
    { lat: 48.2802684, lng: 18.543431 },
    { lat: 48.2810903, lng: 18.5468129 },
    { lat: 48.2813715, lng: 18.5477227 },
    { lat: 48.2816056, lng: 18.5482024 },
    { lat: 48.2816644, lng: 18.5489073 },
    { lat: 48.2818928, lng: 18.5496739 },
    { lat: 48.2819672, lng: 18.5499618 },
    { lat: 48.2838117, lng: 18.5490406 },
    { lat: 48.2841951, lng: 18.548976 },
    { lat: 48.2845994, lng: 18.5490044 },
    { lat: 48.2847296, lng: 18.5489508 },
    { lat: 48.2857905, lng: 18.5494136 },
    { lat: 48.2876571, lng: 18.5496789 },
    { lat: 48.2912915, lng: 18.549552 },
    { lat: 48.2915146, lng: 18.5494654 },
    { lat: 48.2917177, lng: 18.5481914 },
    { lat: 48.2922647, lng: 18.5462211 },
    { lat: 48.2927089, lng: 18.5449831 },
    { lat: 48.2937996, lng: 18.5423732 },
    { lat: 48.2945997, lng: 18.5400002 },
    { lat: 48.2948854, lng: 18.5394523 },
    { lat: 48.2952252, lng: 18.5390086 },
    { lat: 48.2956131, lng: 18.5380199 },
    { lat: 48.2960185, lng: 18.5371642 },
    { lat: 48.2967703, lng: 18.5359852 },
    { lat: 48.297143, lng: 18.5352979 },
    { lat: 48.2978832, lng: 18.5343374 },
    { lat: 48.2986301, lng: 18.5328453 },
  ],
  Pukanec: [
    { lat: 48.373832, lng: 18.679272 },
    { lat: 48.373737, lng: 18.679042 },
    { lat: 48.373666, lng: 18.679059 },
    { lat: 48.373171, lng: 18.679177 },
    { lat: 48.371204, lng: 18.679566 },
    { lat: 48.369143, lng: 18.680036 },
    { lat: 48.368975, lng: 18.680074 },
    { lat: 48.368302, lng: 18.680193 },
    { lat: 48.367624, lng: 18.680328 },
    { lat: 48.366454, lng: 18.680637 },
    { lat: 48.366423, lng: 18.680645 },
    { lat: 48.365976, lng: 18.680763 },
    { lat: 48.365719, lng: 18.680785 },
    { lat: 48.36388, lng: 18.681167 },
    { lat: 48.362199, lng: 18.681074 },
    { lat: 48.360904, lng: 18.680336 },
    { lat: 48.360803, lng: 18.680312 },
    { lat: 48.359926, lng: 18.679997 },
    { lat: 48.359352, lng: 18.679665 },
    { lat: 48.353216, lng: 18.680647 },
    { lat: 48.3528813, lng: 18.6829217 },
    { lat: 48.353582, lng: 18.6840611 },
    { lat: 48.3535045, lng: 18.684763 },
    { lat: 48.3530643, lng: 18.685575 },
    { lat: 48.3528394, lng: 18.6857791 },
    { lat: 48.3526418, lng: 18.6862774 },
    { lat: 48.3523257, lng: 18.6864202 },
    { lat: 48.3520092, lng: 18.6872261 },
    { lat: 48.3517631, lng: 18.687559 },
    { lat: 48.3516433, lng: 18.6876394 },
    { lat: 48.3515809, lng: 18.6878203 },
    { lat: 48.3510611, lng: 18.6882855 },
    { lat: 48.3509188, lng: 18.6886597 },
    { lat: 48.3506341, lng: 18.689032 },
    { lat: 48.3502369, lng: 18.689342 },
    { lat: 48.3499289, lng: 18.6894019 },
    { lat: 48.3496634, lng: 18.68914 },
    { lat: 48.3495403, lng: 18.6891811 },
    { lat: 48.3493697, lng: 18.6895115 },
    { lat: 48.3493434, lng: 18.6896746 },
    { lat: 48.3493276, lng: 18.6913146 },
    { lat: 48.3485538, lng: 18.6916274 },
    { lat: 48.3483131, lng: 18.6918717 },
    { lat: 48.3476061, lng: 18.6917684 },
    { lat: 48.3474494, lng: 18.6918571 },
    { lat: 48.3465708, lng: 18.6924853 },
    { lat: 48.3463186, lng: 18.6927494 },
    { lat: 48.3457932, lng: 18.6935523 },
    { lat: 48.3454771, lng: 18.6942602 },
    { lat: 48.3453927, lng: 18.6947266 },
    { lat: 48.3453867, lng: 18.6956477 },
    { lat: 48.345336, lng: 18.6959009 },
    { lat: 48.3451079, lng: 18.6962312 },
    { lat: 48.3448395, lng: 18.6963994 },
    { lat: 48.3446526, lng: 18.6967452 },
    { lat: 48.3444181, lng: 18.6970004 },
    { lat: 48.3440968, lng: 18.6971082 },
    { lat: 48.3438576, lng: 18.6976334 },
    { lat: 48.343766, lng: 18.6980273 },
    { lat: 48.3436012, lng: 18.698401 },
    { lat: 48.3434866, lng: 18.6990866 },
    { lat: 48.3433595, lng: 18.69941 },
    { lat: 48.3432145, lng: 18.7005984 },
    { lat: 48.3425656, lng: 18.7033291 },
    { lat: 48.342429, lng: 18.7038103 },
    { lat: 48.3420504, lng: 18.7045986 },
    { lat: 48.341754, lng: 18.7049426 },
    { lat: 48.340665, lng: 18.7056884 },
    { lat: 48.340548, lng: 18.7059053 },
    { lat: 48.3401117, lng: 18.7063686 },
    { lat: 48.3389153, lng: 18.7074722 },
    { lat: 48.3384771, lng: 18.707756 },
    { lat: 48.338163, lng: 18.707808 },
    { lat: 48.337506, lng: 18.7080742 },
    { lat: 48.3376643, lng: 18.7084178 },
    { lat: 48.3379582, lng: 18.7088088 },
    { lat: 48.3380697, lng: 18.7090812 },
    { lat: 48.3381472, lng: 18.71017 },
    { lat: 48.3381026, lng: 18.7106653 },
    { lat: 48.3379922, lng: 18.7111734 },
    { lat: 48.3377374, lng: 18.7120012 },
    { lat: 48.3370605, lng: 18.7136001 },
    { lat: 48.3368076, lng: 18.7143132 },
    { lat: 48.3361587, lng: 18.7156768 },
    { lat: 48.3353015, lng: 18.7167343 },
    { lat: 48.3350285, lng: 18.7169869 },
    { lat: 48.3349052, lng: 18.7172307 },
    { lat: 48.3351971, lng: 18.7178599 },
    { lat: 48.3355292, lng: 18.7183173 },
    { lat: 48.3360241, lng: 18.7196406 },
    { lat: 48.3365398, lng: 18.7207038 },
    { lat: 48.3365135, lng: 18.7215233 },
    { lat: 48.3373124, lng: 18.7219722 },
    { lat: 48.3379023, lng: 18.7221439 },
    { lat: 48.3378919, lng: 18.7222238 },
    { lat: 48.3375103, lng: 18.7225752 },
    { lat: 48.3376914, lng: 18.7233485 },
    { lat: 48.3375447, lng: 18.7234728 },
    { lat: 48.3377152, lng: 18.7238168 },
    { lat: 48.3380825, lng: 18.7248056 },
    { lat: 48.3387446, lng: 18.724147 },
    { lat: 48.3390037, lng: 18.7248189 },
    { lat: 48.3391546, lng: 18.7254098 },
    { lat: 48.3393396, lng: 18.7261933 },
    { lat: 48.3392094, lng: 18.7264372 },
    { lat: 48.3387705, lng: 18.7266238 },
    { lat: 48.3379573, lng: 18.7271067 },
    { lat: 48.3373278, lng: 18.7273611 },
    { lat: 48.3373405, lng: 18.727662 },
    { lat: 48.3373542, lng: 18.7279418 },
    { lat: 48.3376022, lng: 18.7279606 },
    { lat: 48.3379461, lng: 18.7295443 },
    { lat: 48.3380445, lng: 18.7298249 },
    { lat: 48.3378682, lng: 18.7300067 },
    { lat: 48.3380748, lng: 18.7306242 },
    { lat: 48.3377733, lng: 18.7311261 },
    { lat: 48.3378186, lng: 18.731193 },
    { lat: 48.3372205, lng: 18.7319076 },
    { lat: 48.3367905, lng: 18.7323044 },
    { lat: 48.3370263, lng: 18.7328536 },
    { lat: 48.3381344, lng: 18.734814 },
    { lat: 48.338743, lng: 18.7350818 },
    { lat: 48.3387955, lng: 18.7350287 },
    { lat: 48.3389444, lng: 18.7351583 },
    { lat: 48.3403334, lng: 18.7372692 },
    { lat: 48.3403022, lng: 18.737447 },
    { lat: 48.3405718, lng: 18.7379571 },
    { lat: 48.3411138, lng: 18.7392421 },
    { lat: 48.3417887, lng: 18.7406056 },
    { lat: 48.3419999, lng: 18.740927 },
    { lat: 48.3426272, lng: 18.7415521 },
    { lat: 48.3427375, lng: 18.7419898 },
    { lat: 48.3421671, lng: 18.7423335 },
    { lat: 48.3418112, lng: 18.7427703 },
    { lat: 48.3413389, lng: 18.7430253 },
    { lat: 48.3413194, lng: 18.7432779 },
    { lat: 48.3415468, lng: 18.7434605 },
    { lat: 48.341803, lng: 18.7435043 },
    { lat: 48.3422596, lng: 18.7434394 },
    { lat: 48.3424825, lng: 18.7435812 },
    { lat: 48.3426951, lng: 18.7435267 },
    { lat: 48.3428264, lng: 18.7433862 },
    { lat: 48.3431493, lng: 18.7432469 },
    { lat: 48.3432783, lng: 18.7429794 },
    { lat: 48.343549, lng: 18.7428063 },
    { lat: 48.3438214, lng: 18.7428188 },
    { lat: 48.3439593, lng: 18.7427626 },
    { lat: 48.3443574, lng: 18.7424019 },
    { lat: 48.3445438, lng: 18.7425342 },
    { lat: 48.3448522, lng: 18.7422662 },
    { lat: 48.3449241, lng: 18.7421165 },
    { lat: 48.3450587, lng: 18.7423329 },
    { lat: 48.3450749, lng: 18.7424671 },
    { lat: 48.345204, lng: 18.7425215 },
    { lat: 48.345335, lng: 18.7426742 },
    { lat: 48.3456538, lng: 18.7424528 },
    { lat: 48.3458419, lng: 18.7424087 },
    { lat: 48.3460459, lng: 18.7425725 },
    { lat: 48.3467338, lng: 18.7422751 },
    { lat: 48.3480142, lng: 18.742113 },
    { lat: 48.3490795, lng: 18.7423561 },
    { lat: 48.3493723, lng: 18.7422668 },
    { lat: 48.3499652, lng: 18.7440637 },
    { lat: 48.3500653, lng: 18.745249 },
    { lat: 48.3500578, lng: 18.7464015 },
    { lat: 48.3501103, lng: 18.7476432 },
    { lat: 48.3502027, lng: 18.7478527 },
    { lat: 48.3503896, lng: 18.7480169 },
    { lat: 48.3503817, lng: 18.7480589 },
    { lat: 48.3506065, lng: 18.7482381 },
    { lat: 48.3512188, lng: 18.7484064 },
    { lat: 48.3513662, lng: 18.7486557 },
    { lat: 48.351291, lng: 18.7490459 },
    { lat: 48.3513149, lng: 18.7494522 },
    { lat: 48.3512638, lng: 18.7496543 },
    { lat: 48.3513052, lng: 18.7499891 },
    { lat: 48.3515147, lng: 18.7501349 },
    { lat: 48.3516576, lng: 18.7501512 },
    { lat: 48.3518836, lng: 18.7506681 },
    { lat: 48.352056, lng: 18.7515176 },
    { lat: 48.3522281, lng: 18.7516778 },
    { lat: 48.3523167, lng: 18.75207 },
    { lat: 48.3524197, lng: 18.7522244 },
    { lat: 48.3527562, lng: 18.7523611 },
    { lat: 48.3531854, lng: 18.75214 },
    { lat: 48.3533551, lng: 18.7522169 },
    { lat: 48.3546122, lng: 18.7532472 },
    { lat: 48.3547085, lng: 18.7533828 },
    { lat: 48.3550341, lng: 18.7535276 },
    { lat: 48.3554273, lng: 18.7538384 },
    { lat: 48.3555595, lng: 18.7540982 },
    { lat: 48.3556857, lng: 18.7541607 },
    { lat: 48.3561938, lng: 18.7538937 },
    { lat: 48.3563164, lng: 18.7539964 },
    { lat: 48.3566059, lng: 18.7544544 },
    { lat: 48.3566782, lng: 18.7547745 },
    { lat: 48.3568873, lng: 18.7548729 },
    { lat: 48.3572132, lng: 18.7553403 },
    { lat: 48.3574963, lng: 18.7555087 },
    { lat: 48.3576002, lng: 18.7553608 },
    { lat: 48.3582134, lng: 18.7558447 },
    { lat: 48.3583867, lng: 18.7554545 },
    { lat: 48.3592179, lng: 18.7557318 },
    { lat: 48.3592492, lng: 18.7556001 },
    { lat: 48.3594687, lng: 18.7557189 },
    { lat: 48.3597396, lng: 18.7560242 },
    { lat: 48.3600222, lng: 18.7559298 },
    { lat: 48.3603742, lng: 18.7559307 },
    { lat: 48.360711, lng: 18.756221 },
    { lat: 48.3609794, lng: 18.7559446 },
    { lat: 48.3611583, lng: 18.7561491 },
    { lat: 48.3618009, lng: 18.7561451 },
    { lat: 48.3619601, lng: 18.7562949 },
    { lat: 48.3622144, lng: 18.7563937 },
    { lat: 48.3623517, lng: 18.7565588 },
    { lat: 48.3623803, lng: 18.7568842 },
    { lat: 48.3626248, lng: 18.757238 },
    { lat: 48.362946, lng: 18.7572575 },
    { lat: 48.3632198, lng: 18.7575088 },
    { lat: 48.3632921, lng: 18.7578931 },
    { lat: 48.3634089, lng: 18.7581384 },
    { lat: 48.3637905, lng: 18.7585968 },
    { lat: 48.3638796, lng: 18.7587852 },
    { lat: 48.3641469, lng: 18.758937 },
    { lat: 48.3642574, lng: 18.7590646 },
    { lat: 48.3645747, lng: 18.7596236 },
    { lat: 48.3647876, lng: 18.7597663 },
    { lat: 48.3651705, lng: 18.7605249 },
    { lat: 48.365513, lng: 18.7608384 },
    { lat: 48.3657213, lng: 18.761193 },
    { lat: 48.3658718, lng: 18.7613304 },
    { lat: 48.3660123, lng: 18.7612579 },
    { lat: 48.3663257, lng: 18.761405 },
    { lat: 48.36646, lng: 18.7612999 },
    { lat: 48.3667335, lng: 18.7612882 },
    { lat: 48.3670648, lng: 18.7613899 },
    { lat: 48.3676578, lng: 18.7618327 },
    { lat: 48.3679792, lng: 18.761833 },
    { lat: 48.3681323, lng: 18.761913 },
    { lat: 48.3683936, lng: 18.7626514 },
    { lat: 48.3684264, lng: 18.762888 },
    { lat: 48.3685973, lng: 18.7632627 },
    { lat: 48.3692486, lng: 18.7638078 },
    { lat: 48.3692979, lng: 18.7637789 },
    { lat: 48.370048, lng: 18.7642701 },
    { lat: 48.3702938, lng: 18.7641645 },
    { lat: 48.3703422, lng: 18.7642694 },
    { lat: 48.3704248, lng: 18.7642503 },
    { lat: 48.3704873, lng: 18.7644092 },
    { lat: 48.370833, lng: 18.7647432 },
    { lat: 48.3711979, lng: 18.7648367 },
    { lat: 48.3718219, lng: 18.7648254 },
    { lat: 48.3719676, lng: 18.764592 },
    { lat: 48.3722343, lng: 18.7647322 },
    { lat: 48.37221, lng: 18.7648664 },
    { lat: 48.3723393, lng: 18.7649705 },
    { lat: 48.3727806, lng: 18.7650792 },
    { lat: 48.3735025, lng: 18.7650414 },
    { lat: 48.3739003, lng: 18.7651185 },
    { lat: 48.3754361, lng: 18.7649673 },
    { lat: 48.3756593, lng: 18.7646473 },
    { lat: 48.3759256, lng: 18.7641143 },
    { lat: 48.3760292, lng: 18.7637958 },
    { lat: 48.3763939, lng: 18.7631004 },
    { lat: 48.3764461, lng: 18.7631491 },
    { lat: 48.3773193, lng: 18.7621081 },
    { lat: 48.3775463, lng: 18.7619459 },
    { lat: 48.377587, lng: 18.7618836 },
    { lat: 48.3775223, lng: 18.7617075 },
    { lat: 48.3778733, lng: 18.761561 },
    { lat: 48.3780403, lng: 18.7610281 },
    { lat: 48.3780686, lng: 18.7607764 },
    { lat: 48.3787641, lng: 18.7599605 },
    { lat: 48.3789219, lng: 18.759897 },
    { lat: 48.3790792, lng: 18.7596714 },
    { lat: 48.3792219, lng: 18.7595785 },
    { lat: 48.3792314, lng: 18.7594812 },
    { lat: 48.3793159, lng: 18.7594793 },
    { lat: 48.3792808, lng: 18.7594093 },
    { lat: 48.3796697, lng: 18.7589624 },
    { lat: 48.379634, lng: 18.7588638 },
    { lat: 48.3797235, lng: 18.7588708 },
    { lat: 48.3797577, lng: 18.7588118 },
    { lat: 48.3798321, lng: 18.7582346 },
    { lat: 48.3785185, lng: 18.7577291 },
    { lat: 48.3780717, lng: 18.7573879 },
    { lat: 48.3777802, lng: 18.7570152 },
    { lat: 48.377626, lng: 18.7566167 },
    { lat: 48.3775506, lng: 18.7562777 },
    { lat: 48.3775337, lng: 18.7559587 },
    { lat: 48.3776179, lng: 18.7552516 },
    { lat: 48.3775216, lng: 18.7549687 },
    { lat: 48.3773706, lng: 18.7548608 },
    { lat: 48.3768461, lng: 18.7549437 },
    { lat: 48.3765811, lng: 18.7549239 },
    { lat: 48.3760945, lng: 18.7547631 },
    { lat: 48.3756845, lng: 18.754479 },
    { lat: 48.3755031, lng: 18.7542045 },
    { lat: 48.3753425, lng: 18.7536451 },
    { lat: 48.3753955, lng: 18.7532805 },
    { lat: 48.3754823, lng: 18.753091 },
    { lat: 48.376068, lng: 18.7522906 },
    { lat: 48.3761708, lng: 18.7519073 },
    { lat: 48.3754864, lng: 18.7511058 },
    { lat: 48.3756566, lng: 18.7509326 },
    { lat: 48.3757782, lng: 18.7506942 },
    { lat: 48.3758167, lng: 18.7501674 },
    { lat: 48.3758752, lng: 18.7500147 },
    { lat: 48.3762929, lng: 18.7497054 },
    { lat: 48.37653, lng: 18.7493908 },
    { lat: 48.3766067, lng: 18.7490936 },
    { lat: 48.3766829, lng: 18.7484459 },
    { lat: 48.3771089, lng: 18.7477405 },
    { lat: 48.3770881, lng: 18.747688 },
    { lat: 48.3771634, lng: 18.7475346 },
    { lat: 48.3781656, lng: 18.7467812 },
    { lat: 48.3786629, lng: 18.7461864 },
    { lat: 48.3788694, lng: 18.7456597 },
    { lat: 48.3790502, lng: 18.7454504 },
    { lat: 48.3791806, lng: 18.7457208 },
    { lat: 48.3797874, lng: 18.7451497 },
    { lat: 48.3801083, lng: 18.7449279 },
    { lat: 48.3805874, lng: 18.7449646 },
    { lat: 48.3809682, lng: 18.7451078 },
    { lat: 48.3820618, lng: 18.7447179 },
    { lat: 48.3820328, lng: 18.7447022 },
    { lat: 48.3822989, lng: 18.7438523 },
    { lat: 48.3825885, lng: 18.7438059 },
    { lat: 48.3826347, lng: 18.7431046 },
    { lat: 48.3830382, lng: 18.7421222 },
    { lat: 48.3840675, lng: 18.7416041 },
    { lat: 48.3847678, lng: 18.7413293 },
    { lat: 48.3849936, lng: 18.7405498 },
    { lat: 48.3852458, lng: 18.7394158 },
    { lat: 48.3857742, lng: 18.7388114 },
    { lat: 48.3855405, lng: 18.7375529 },
    { lat: 48.3853384, lng: 18.7372323 },
    { lat: 48.386031, lng: 18.7315976 },
    { lat: 48.3859845, lng: 18.7311898 },
    { lat: 48.3861618, lng: 18.7303645 },
    { lat: 48.3863435, lng: 18.7287571 },
    { lat: 48.3863208, lng: 18.7283124 },
    { lat: 48.3866003, lng: 18.7279244 },
    { lat: 48.3867437, lng: 18.7274225 },
    { lat: 48.386767, lng: 18.7265646 },
    { lat: 48.3868596, lng: 18.7262707 },
    { lat: 48.3869884, lng: 18.7262123 },
    { lat: 48.3869842, lng: 18.7260907 },
    { lat: 48.3872319, lng: 18.7261032 },
    { lat: 48.3872256, lng: 18.7254362 },
    { lat: 48.3871371, lng: 18.72534 },
    { lat: 48.3868519, lng: 18.7252395 },
    { lat: 48.3865046, lng: 18.7246694 },
    { lat: 48.3861428, lng: 18.7245057 },
    { lat: 48.3857961, lng: 18.7240307 },
    { lat: 48.3858271, lng: 18.7239553 },
    { lat: 48.3859584, lng: 18.7240907 },
    { lat: 48.3864184, lng: 18.7238137 },
    { lat: 48.3862972, lng: 18.723526 },
    { lat: 48.3860292, lng: 18.7222226 },
    { lat: 48.3862255, lng: 18.7216934 },
    { lat: 48.3861008, lng: 18.7203736 },
    { lat: 48.3859461, lng: 18.7202592 },
    { lat: 48.3859332, lng: 18.72004 },
    { lat: 48.386327, lng: 18.7190492 },
    { lat: 48.3862945, lng: 18.7188647 },
    { lat: 48.3865967, lng: 18.7186726 },
    { lat: 48.3865469, lng: 18.7184782 },
    { lat: 48.3866257, lng: 18.7183487 },
    { lat: 48.387017, lng: 18.7184138 },
    { lat: 48.3870845, lng: 18.7182173 },
    { lat: 48.3869408, lng: 18.7176179 },
    { lat: 48.3869634, lng: 18.7175144 },
    { lat: 48.3874822, lng: 18.7165813 },
    { lat: 48.3884192, lng: 18.7164108 },
    { lat: 48.389118, lng: 18.7168896 },
    { lat: 48.3897696, lng: 18.7166505 },
    { lat: 48.3898744, lng: 18.7168611 },
    { lat: 48.3906115, lng: 18.7170819 },
    { lat: 48.3910051, lng: 18.7170399 },
    { lat: 48.3913732, lng: 18.7168157 },
    { lat: 48.3917304, lng: 18.7164097 },
    { lat: 48.3919267, lng: 18.7160714 },
    { lat: 48.392038, lng: 18.715641 },
    { lat: 48.3926552, lng: 18.7148031 },
    { lat: 48.3929043, lng: 18.7138285 },
    { lat: 48.393279, lng: 18.7134325 },
    { lat: 48.3933995, lng: 18.7132363 },
    { lat: 48.3934936, lng: 18.7128431 },
    { lat: 48.3937286, lng: 18.7123674 },
    { lat: 48.393625, lng: 18.7117364 },
    { lat: 48.393777, lng: 18.7109882 },
    { lat: 48.3938694, lng: 18.7107585 },
    { lat: 48.3938783, lng: 18.71046 },
    { lat: 48.3938385, lng: 18.7103477 },
    { lat: 48.3937318, lng: 18.7102473 },
    { lat: 48.393385, lng: 18.7102369 },
    { lat: 48.3927019, lng: 18.7098465 },
    { lat: 48.3921958, lng: 18.7097246 },
    { lat: 48.392035, lng: 18.7096038 },
    { lat: 48.3917585, lng: 18.7092223 },
    { lat: 48.3916291, lng: 18.7089338 },
    { lat: 48.3915848, lng: 18.708608 },
    { lat: 48.3916585, lng: 18.7081297 },
    { lat: 48.3924, lng: 18.7076976 },
    { lat: 48.3923636, lng: 18.7075203 },
    { lat: 48.3923547, lng: 18.7067846 },
    { lat: 48.3926314, lng: 18.7057559 },
    { lat: 48.3926745, lng: 18.7053203 },
    { lat: 48.3926448, lng: 18.7049942 },
    { lat: 48.3927949, lng: 18.7044679 },
    { lat: 48.3926624, lng: 18.7041649 },
    { lat: 48.392172, lng: 18.7038734 },
    { lat: 48.3920778, lng: 18.7036502 },
    { lat: 48.3923798, lng: 18.7030336 },
    { lat: 48.3923926, lng: 18.7024518 },
    { lat: 48.3923448, lng: 18.7017702 },
    { lat: 48.3924419, lng: 18.7013263 },
    { lat: 48.3922724, lng: 18.7006232 },
    { lat: 48.392306, lng: 18.699891 },
    { lat: 48.392277, lng: 18.699915 },
    { lat: 48.392208, lng: 18.699955 },
    { lat: 48.392055, lng: 18.700042 },
    { lat: 48.391911, lng: 18.700198 },
    { lat: 48.39143, lng: 18.700875 },
    { lat: 48.391143, lng: 18.701961 },
    { lat: 48.390932, lng: 18.702249 },
    { lat: 48.390694, lng: 18.70241 },
    { lat: 48.390421, lng: 18.702516 },
    { lat: 48.390188, lng: 18.702499 },
    { lat: 48.389969, lng: 18.702449 },
    { lat: 48.389739, lng: 18.702678 },
    { lat: 48.389672, lng: 18.702528 },
    { lat: 48.389201, lng: 18.701687 },
    { lat: 48.388864, lng: 18.700599 },
    { lat: 48.388711, lng: 18.699758 },
    { lat: 48.388544, lng: 18.698559 },
    { lat: 48.388155, lng: 18.697785 },
    { lat: 48.387928, lng: 18.697308 },
    { lat: 48.387462, lng: 18.696733 },
    { lat: 48.386973, lng: 18.696393 },
    { lat: 48.386888, lng: 18.696309 },
    { lat: 48.386402, lng: 18.696289 },
    { lat: 48.386014, lng: 18.69622 },
    { lat: 48.385714, lng: 18.696194 },
    { lat: 48.385591, lng: 18.695997 },
    { lat: 48.385485, lng: 18.695825 },
    { lat: 48.385462, lng: 18.695788 },
    { lat: 48.385439, lng: 18.695749 },
    { lat: 48.385437, lng: 18.695736 },
    { lat: 48.385434, lng: 18.695702 },
    { lat: 48.385432, lng: 18.695672 },
    { lat: 48.385429, lng: 18.695632 },
    { lat: 48.385532, lng: 18.695311 },
    { lat: 48.384837, lng: 18.695493 },
    { lat: 48.384522, lng: 18.695609 },
    { lat: 48.384071, lng: 18.695821 },
    { lat: 48.383768, lng: 18.694895 },
    { lat: 48.383759, lng: 18.694868 },
    { lat: 48.383763, lng: 18.694851 },
    { lat: 48.383804, lng: 18.694657 },
    { lat: 48.383805, lng: 18.69465 },
    { lat: 48.383794, lng: 18.694313 },
    { lat: 48.383613, lng: 18.693476 },
    { lat: 48.383444, lng: 18.692541 },
    { lat: 48.383335, lng: 18.691826 },
    { lat: 48.383123, lng: 18.691278 },
    { lat: 48.382917, lng: 18.691166 },
    { lat: 48.382814, lng: 18.690999 },
    { lat: 48.382778, lng: 18.690372 },
    { lat: 48.382871, lng: 18.690211 },
    { lat: 48.382932, lng: 18.69001 },
    { lat: 48.382913, lng: 18.689771 },
    { lat: 48.38271, lng: 18.689527 },
    { lat: 48.382189, lng: 18.688893 },
    { lat: 48.381728, lng: 18.688336 },
    { lat: 48.381395, lng: 18.688363 },
    { lat: 48.38073, lng: 18.688422 },
    { lat: 48.380463, lng: 18.688398 },
    { lat: 48.38006, lng: 18.688515 },
    { lat: 48.379501, lng: 18.688748 },
    { lat: 48.379346, lng: 18.688746 },
    { lat: 48.379268, lng: 18.688589 },
    { lat: 48.379167, lng: 18.6885 },
    { lat: 48.378887, lng: 18.688453 },
    { lat: 48.378421, lng: 18.688557 },
    { lat: 48.37808, lng: 18.688703 },
    { lat: 48.377668, lng: 18.688741 },
    { lat: 48.377407, lng: 18.688927 },
    { lat: 48.377297, lng: 18.68899 },
    { lat: 48.377175, lng: 18.689045 },
    { lat: 48.376975, lng: 18.689111 },
    { lat: 48.376457, lng: 18.688937 },
    { lat: 48.376361, lng: 18.688905 },
    { lat: 48.376296, lng: 18.68894 },
    { lat: 48.376124, lng: 18.689035 },
    { lat: 48.375836, lng: 18.689243 },
    { lat: 48.375559, lng: 18.689463 },
    { lat: 48.374978, lng: 18.689248 },
    { lat: 48.374803, lng: 18.689183 },
    { lat: 48.374628, lng: 18.689118 },
    { lat: 48.374476, lng: 18.688945 },
    { lat: 48.37436, lng: 18.688813 },
    { lat: 48.374286, lng: 18.688501 },
    { lat: 48.37429, lng: 18.688239 },
    { lat: 48.374222, lng: 18.688049 },
    { lat: 48.374188, lng: 18.687605 },
    { lat: 48.374231, lng: 18.687082 },
    { lat: 48.3742227, lng: 18.6870366 },
    { lat: 48.3741809, lng: 18.6868085 },
    { lat: 48.3740025, lng: 18.6858342 },
    { lat: 48.373838, lng: 18.684936 },
    { lat: 48.3737099, lng: 18.6845455 },
    { lat: 48.37367, lng: 18.684424 },
    { lat: 48.3736061, lng: 18.6841133 },
    { lat: 48.373583, lng: 18.684001 },
    { lat: 48.3736026, lng: 18.6838407 },
    { lat: 48.373609, lng: 18.683788 },
    { lat: 48.373537, lng: 18.683759 },
    { lat: 48.373372, lng: 18.6834 },
    { lat: 48.3733503, lng: 18.6832863 },
    { lat: 48.3732932, lng: 18.6829878 },
    { lat: 48.373196, lng: 18.682479 },
    { lat: 48.373231, lng: 18.682292 },
    { lat: 48.373235, lng: 18.682122 },
    { lat: 48.373302, lng: 18.68197 },
    { lat: 48.373335, lng: 18.681341 },
    { lat: 48.373321, lng: 18.681042 },
    { lat: 48.3733061, lng: 18.6810022 },
    { lat: 48.373257, lng: 18.680871 },
    { lat: 48.373284, lng: 18.680758 },
    { lat: 48.373236, lng: 18.680171 },
    { lat: 48.373506, lng: 18.679709 },
    { lat: 48.373539, lng: 18.679645 },
    { lat: 48.373554, lng: 18.679626 },
    { lat: 48.373591, lng: 18.679579 },
    { lat: 48.373832, lng: 18.679272 },
  ],
  Uhliská: [
    { lat: 48.393109, lng: 18.698313 },
    { lat: 48.392447, lng: 18.699705 },
    { lat: 48.392306, lng: 18.699891 },
    { lat: 48.3922724, lng: 18.7006232 },
    { lat: 48.3924419, lng: 18.7013263 },
    { lat: 48.3923448, lng: 18.7017702 },
    { lat: 48.3923926, lng: 18.7024518 },
    { lat: 48.3923798, lng: 18.7030336 },
    { lat: 48.3920778, lng: 18.7036502 },
    { lat: 48.392172, lng: 18.7038734 },
    { lat: 48.3926624, lng: 18.7041649 },
    { lat: 48.3927949, lng: 18.7044679 },
    { lat: 48.3926448, lng: 18.7049942 },
    { lat: 48.3926745, lng: 18.7053203 },
    { lat: 48.3926314, lng: 18.7057559 },
    { lat: 48.3923547, lng: 18.7067846 },
    { lat: 48.3923636, lng: 18.7075203 },
    { lat: 48.3924, lng: 18.7076976 },
    { lat: 48.3916585, lng: 18.7081297 },
    { lat: 48.3915848, lng: 18.708608 },
    { lat: 48.3916291, lng: 18.7089338 },
    { lat: 48.3917585, lng: 18.7092223 },
    { lat: 48.392035, lng: 18.7096038 },
    { lat: 48.3921958, lng: 18.7097246 },
    { lat: 48.3927019, lng: 18.7098465 },
    { lat: 48.393385, lng: 18.7102369 },
    { lat: 48.3937318, lng: 18.7102473 },
    { lat: 48.3938385, lng: 18.7103477 },
    { lat: 48.3938783, lng: 18.71046 },
    { lat: 48.3938694, lng: 18.7107585 },
    { lat: 48.393777, lng: 18.7109882 },
    { lat: 48.393625, lng: 18.7117364 },
    { lat: 48.3937286, lng: 18.7123674 },
    { lat: 48.3934936, lng: 18.7128431 },
    { lat: 48.3933995, lng: 18.7132363 },
    { lat: 48.393279, lng: 18.7134325 },
    { lat: 48.3929043, lng: 18.7138285 },
    { lat: 48.3926552, lng: 18.7148031 },
    { lat: 48.392038, lng: 18.715641 },
    { lat: 48.3919267, lng: 18.7160714 },
    { lat: 48.3917304, lng: 18.7164097 },
    { lat: 48.3913732, lng: 18.7168157 },
    { lat: 48.3910051, lng: 18.7170399 },
    { lat: 48.3906115, lng: 18.7170819 },
    { lat: 48.3898744, lng: 18.7168611 },
    { lat: 48.3897696, lng: 18.7166505 },
    { lat: 48.389118, lng: 18.7168896 },
    { lat: 48.3884192, lng: 18.7164108 },
    { lat: 48.3874822, lng: 18.7165813 },
    { lat: 48.3869634, lng: 18.7175144 },
    { lat: 48.3869408, lng: 18.7176179 },
    { lat: 48.3870845, lng: 18.7182173 },
    { lat: 48.387017, lng: 18.7184138 },
    { lat: 48.3866257, lng: 18.7183487 },
    { lat: 48.3865469, lng: 18.7184782 },
    { lat: 48.3865967, lng: 18.7186726 },
    { lat: 48.3862945, lng: 18.7188647 },
    { lat: 48.386327, lng: 18.7190492 },
    { lat: 48.3859332, lng: 18.72004 },
    { lat: 48.3859461, lng: 18.7202592 },
    { lat: 48.3861008, lng: 18.7203736 },
    { lat: 48.3862255, lng: 18.7216934 },
    { lat: 48.3860292, lng: 18.7222226 },
    { lat: 48.3862972, lng: 18.723526 },
    { lat: 48.3864184, lng: 18.7238137 },
    { lat: 48.3859584, lng: 18.7240907 },
    { lat: 48.3858271, lng: 18.7239553 },
    { lat: 48.3857961, lng: 18.7240307 },
    { lat: 48.3861428, lng: 18.7245057 },
    { lat: 48.3865046, lng: 18.7246694 },
    { lat: 48.3868519, lng: 18.7252395 },
    { lat: 48.3871371, lng: 18.72534 },
    { lat: 48.3872256, lng: 18.7254362 },
    { lat: 48.3872319, lng: 18.7261032 },
    { lat: 48.3869842, lng: 18.7260907 },
    { lat: 48.3869884, lng: 18.7262123 },
    { lat: 48.3868596, lng: 18.7262707 },
    { lat: 48.386767, lng: 18.7265646 },
    { lat: 48.3867437, lng: 18.7274225 },
    { lat: 48.3866003, lng: 18.7279244 },
    { lat: 48.3863208, lng: 18.7283124 },
    { lat: 48.3863435, lng: 18.7287571 },
    { lat: 48.3861618, lng: 18.7303645 },
    { lat: 48.3859845, lng: 18.7311898 },
    { lat: 48.386031, lng: 18.7315976 },
    { lat: 48.3853384, lng: 18.7372323 },
    { lat: 48.3855405, lng: 18.7375529 },
    { lat: 48.3857742, lng: 18.7388114 },
    { lat: 48.3852458, lng: 18.7394158 },
    { lat: 48.3849936, lng: 18.7405498 },
    { lat: 48.3847678, lng: 18.7413293 },
    { lat: 48.3840675, lng: 18.7416041 },
    { lat: 48.3830382, lng: 18.7421222 },
    { lat: 48.3826347, lng: 18.7431046 },
    { lat: 48.3825885, lng: 18.7438059 },
    { lat: 48.3822989, lng: 18.7438523 },
    { lat: 48.3820328, lng: 18.7447022 },
    { lat: 48.3820618, lng: 18.7447179 },
    { lat: 48.3809682, lng: 18.7451078 },
    { lat: 48.3805874, lng: 18.7449646 },
    { lat: 48.3801083, lng: 18.7449279 },
    { lat: 48.3797874, lng: 18.7451497 },
    { lat: 48.3791806, lng: 18.7457208 },
    { lat: 48.3790502, lng: 18.7454504 },
    { lat: 48.3788694, lng: 18.7456597 },
    { lat: 48.3786629, lng: 18.7461864 },
    { lat: 48.3781656, lng: 18.7467812 },
    { lat: 48.3771634, lng: 18.7475346 },
    { lat: 48.3770881, lng: 18.747688 },
    { lat: 48.3771089, lng: 18.7477405 },
    { lat: 48.3766829, lng: 18.7484459 },
    { lat: 48.3766067, lng: 18.7490936 },
    { lat: 48.37653, lng: 18.7493908 },
    { lat: 48.3762929, lng: 18.7497054 },
    { lat: 48.3758752, lng: 18.7500147 },
    { lat: 48.3758167, lng: 18.7501674 },
    { lat: 48.3757782, lng: 18.7506942 },
    { lat: 48.3756566, lng: 18.7509326 },
    { lat: 48.3754864, lng: 18.7511058 },
    { lat: 48.3761708, lng: 18.7519073 },
    { lat: 48.376068, lng: 18.7522906 },
    { lat: 48.3754823, lng: 18.753091 },
    { lat: 48.3753955, lng: 18.7532805 },
    { lat: 48.3753425, lng: 18.7536451 },
    { lat: 48.3755031, lng: 18.7542045 },
    { lat: 48.3756845, lng: 18.754479 },
    { lat: 48.3760945, lng: 18.7547631 },
    { lat: 48.3765811, lng: 18.7549239 },
    { lat: 48.3768461, lng: 18.7549437 },
    { lat: 48.3773706, lng: 18.7548608 },
    { lat: 48.3775216, lng: 18.7549687 },
    { lat: 48.3776179, lng: 18.7552516 },
    { lat: 48.3775337, lng: 18.7559587 },
    { lat: 48.3775506, lng: 18.7562777 },
    { lat: 48.377626, lng: 18.7566167 },
    { lat: 48.3777802, lng: 18.7570152 },
    { lat: 48.3780717, lng: 18.7573879 },
    { lat: 48.3785185, lng: 18.7577291 },
    { lat: 48.3798321, lng: 18.7582346 },
    { lat: 48.3797577, lng: 18.7588118 },
    { lat: 48.3797235, lng: 18.7588708 },
    { lat: 48.379634, lng: 18.7588638 },
    { lat: 48.3796697, lng: 18.7589624 },
    { lat: 48.3792808, lng: 18.7594093 },
    { lat: 48.3793159, lng: 18.7594793 },
    { lat: 48.3792314, lng: 18.7594812 },
    { lat: 48.3792219, lng: 18.7595785 },
    { lat: 48.3790792, lng: 18.7596714 },
    { lat: 48.3789219, lng: 18.759897 },
    { lat: 48.3787641, lng: 18.7599605 },
    { lat: 48.3780686, lng: 18.7607764 },
    { lat: 48.3780403, lng: 18.7610281 },
    { lat: 48.3778733, lng: 18.761561 },
    { lat: 48.3775223, lng: 18.7617075 },
    { lat: 48.377587, lng: 18.7618836 },
    { lat: 48.3775463, lng: 18.7619459 },
    { lat: 48.3773193, lng: 18.7621081 },
    { lat: 48.3764461, lng: 18.7631491 },
    { lat: 48.3763939, lng: 18.7631004 },
    { lat: 48.3760292, lng: 18.7637958 },
    { lat: 48.3759256, lng: 18.7641143 },
    { lat: 48.3756593, lng: 18.7646473 },
    { lat: 48.3754361, lng: 18.7649673 },
    { lat: 48.3739003, lng: 18.7651185 },
    { lat: 48.3735025, lng: 18.7650414 },
    { lat: 48.3727806, lng: 18.7650792 },
    { lat: 48.3723393, lng: 18.7649705 },
    { lat: 48.37221, lng: 18.7648664 },
    { lat: 48.3722343, lng: 18.7647322 },
    { lat: 48.3719676, lng: 18.764592 },
    { lat: 48.3718219, lng: 18.7648254 },
    { lat: 48.3711979, lng: 18.7648367 },
    { lat: 48.370833, lng: 18.7647432 },
    { lat: 48.3704873, lng: 18.7644092 },
    { lat: 48.3704248, lng: 18.7642503 },
    { lat: 48.3703422, lng: 18.7642694 },
    { lat: 48.3702938, lng: 18.7641645 },
    { lat: 48.370048, lng: 18.7642701 },
    { lat: 48.3692979, lng: 18.7637789 },
    { lat: 48.3692486, lng: 18.7638078 },
    { lat: 48.3693404, lng: 18.7640469 },
    { lat: 48.3693023, lng: 18.7647144 },
    { lat: 48.3696894, lng: 18.765274 },
    { lat: 48.3696988, lng: 18.7654289 },
    { lat: 48.369577, lng: 18.765619 },
    { lat: 48.3695691, lng: 18.7658353 },
    { lat: 48.3698152, lng: 18.7663901 },
    { lat: 48.369837, lng: 18.7669099 },
    { lat: 48.369915, lng: 18.7672993 },
    { lat: 48.369819, lng: 18.7677445 },
    { lat: 48.369962, lng: 18.7680169 },
    { lat: 48.3702022, lng: 18.768698 },
    { lat: 48.3704243, lng: 18.7687595 },
    { lat: 48.3708527, lng: 18.7692692 },
    { lat: 48.3712353, lng: 18.7693403 },
    { lat: 48.3713497, lng: 18.7692426 },
    { lat: 48.3717274, lng: 18.7694086 },
    { lat: 48.3721073, lng: 18.769939 },
    { lat: 48.3724459, lng: 18.7701778 },
    { lat: 48.3725336, lng: 18.7703451 },
    { lat: 48.3726393, lng: 18.770811 },
    { lat: 48.3726104, lng: 18.7710503 },
    { lat: 48.3726826, lng: 18.7712673 },
    { lat: 48.3726795, lng: 18.7715042 },
    { lat: 48.3724951, lng: 18.7721771 },
    { lat: 48.3727019, lng: 18.7725713 },
    { lat: 48.372591, lng: 18.7728828 },
    { lat: 48.3726071, lng: 18.7735492 },
    { lat: 48.3728059, lng: 18.7740022 },
    { lat: 48.3730255, lng: 18.7738923 },
    { lat: 48.3731806, lng: 18.7741165 },
    { lat: 48.3732549, lng: 18.7741371 },
    { lat: 48.3732991, lng: 18.7740332 },
    { lat: 48.3734324, lng: 18.7739946 },
    { lat: 48.3737063, lng: 18.7740958 },
    { lat: 48.3736422, lng: 18.7743545 },
    { lat: 48.3743522, lng: 18.774602 },
    { lat: 48.3748913, lng: 18.7745641 },
    { lat: 48.3750526, lng: 18.7742437 },
    { lat: 48.3752645, lng: 18.7742596 },
    { lat: 48.3756253, lng: 18.7741514 },
    { lat: 48.3760606, lng: 18.7744117 },
    { lat: 48.3765964, lng: 18.7740684 },
    { lat: 48.3769549, lng: 18.7740059 },
    { lat: 48.37706, lng: 18.7742424 },
    { lat: 48.3769409, lng: 18.7747195 },
    { lat: 48.3769357, lng: 18.7751997 },
    { lat: 48.3768707, lng: 18.7755256 },
    { lat: 48.3769085, lng: 18.7757708 },
    { lat: 48.376848, lng: 18.77612 },
    { lat: 48.377478, lng: 18.7771617 },
    { lat: 48.377493, lng: 18.7775302 },
    { lat: 48.377739, lng: 18.7782604 },
    { lat: 48.3776721, lng: 18.7784422 },
    { lat: 48.37775, lng: 18.7787628 },
    { lat: 48.377708, lng: 18.7788682 },
    { lat: 48.377752, lng: 18.7791641 },
    { lat: 48.3776693, lng: 18.7792233 },
    { lat: 48.3776646, lng: 18.7793711 },
    { lat: 48.3775844, lng: 18.779409 },
    { lat: 48.3776376, lng: 18.7797145 },
    { lat: 48.3774072, lng: 18.7798752 },
    { lat: 48.3775311, lng: 18.7804508 },
    { lat: 48.3774144, lng: 18.7805665 },
    { lat: 48.3772498, lng: 18.7809943 },
    { lat: 48.3772074, lng: 18.7813693 },
    { lat: 48.3771286, lng: 18.7813709 },
    { lat: 48.3771142, lng: 18.781484 },
    { lat: 48.3770029, lng: 18.7815107 },
    { lat: 48.3771224, lng: 18.7820868 },
    { lat: 48.3769809, lng: 18.7824817 },
    { lat: 48.3769405, lng: 18.7828941 },
    { lat: 48.3767812, lng: 18.78293 },
    { lat: 48.3767028, lng: 18.7831536 },
    { lat: 48.3767439, lng: 18.7834403 },
    { lat: 48.376882, lng: 18.783784 },
    { lat: 48.376891, lng: 18.783772 },
    { lat: 48.377595, lng: 18.782843 },
    { lat: 48.378285, lng: 18.782061 },
    { lat: 48.378686, lng: 18.781545 },
    { lat: 48.379034, lng: 18.781047 },
    { lat: 48.37956, lng: 18.780397 },
    { lat: 48.379806, lng: 18.779941 },
    { lat: 48.385106, lng: 18.777369 },
    { lat: 48.38518, lng: 18.777306 },
    { lat: 48.385348, lng: 18.77714 },
    { lat: 48.385397, lng: 18.777092 },
    { lat: 48.38537, lng: 18.776991 },
    { lat: 48.385356, lng: 18.776959 },
    { lat: 48.38591, lng: 18.776729 },
    { lat: 48.386161, lng: 18.776189 },
    { lat: 48.386533, lng: 18.776161 },
    { lat: 48.386759, lng: 18.776013 },
    { lat: 48.386858, lng: 18.775898 },
    { lat: 48.387055, lng: 18.775915 },
    { lat: 48.387417, lng: 18.776074 },
    { lat: 48.387834, lng: 18.776039 },
    { lat: 48.387958, lng: 18.776015 },
    { lat: 48.388228, lng: 18.775992 },
    { lat: 48.388372, lng: 18.775884 },
    { lat: 48.388729, lng: 18.775893 },
    { lat: 48.389019, lng: 18.775856 },
    { lat: 48.389603, lng: 18.776113 },
    { lat: 48.390157, lng: 18.776188 },
    { lat: 48.390176, lng: 18.776163 },
    { lat: 48.390179, lng: 18.776161 },
    { lat: 48.390433, lng: 18.77583 },
    { lat: 48.390688, lng: 18.774974 },
    { lat: 48.390814, lng: 18.774373 },
    { lat: 48.390849, lng: 18.77353 },
    { lat: 48.390816, lng: 18.772875 },
    { lat: 48.390747, lng: 18.772746 },
    { lat: 48.39083, lng: 18.772563 },
    { lat: 48.390904, lng: 18.772109 },
    { lat: 48.39118, lng: 18.770894 },
    { lat: 48.391293, lng: 18.770688 },
    { lat: 48.391321, lng: 18.770465 },
    { lat: 48.391398, lng: 18.770403 },
    { lat: 48.39147, lng: 18.770236 },
    { lat: 48.391886, lng: 18.769858 },
    { lat: 48.391898, lng: 18.76984 },
    { lat: 48.391933, lng: 18.769789 },
    { lat: 48.391953, lng: 18.769759 },
    { lat: 48.392083, lng: 18.769681 },
    { lat: 48.392417, lng: 18.769306 },
    { lat: 48.392544, lng: 18.769125 },
    { lat: 48.393002, lng: 18.768728 },
    { lat: 48.393603, lng: 18.768323 },
    { lat: 48.394235, lng: 18.767712 },
    { lat: 48.394509, lng: 18.767538 },
    { lat: 48.394787, lng: 18.767278 },
    { lat: 48.395012, lng: 18.766875 },
    { lat: 48.395579, lng: 18.766162 },
    { lat: 48.395847, lng: 18.766011 },
    { lat: 48.396039, lng: 18.766079 },
    { lat: 48.396323, lng: 18.76618 },
    { lat: 48.396599, lng: 18.765908 },
    { lat: 48.396668, lng: 18.765584 },
    { lat: 48.396701, lng: 18.765453 },
    { lat: 48.396784, lng: 18.765116 },
    { lat: 48.397453, lng: 18.764407 },
    { lat: 48.397764, lng: 18.763657 },
    { lat: 48.398342, lng: 18.763353 },
    { lat: 48.398713, lng: 18.763202 },
    { lat: 48.398819, lng: 18.763132 },
    { lat: 48.399237, lng: 18.762855 },
    { lat: 48.399747, lng: 18.762136 },
    { lat: 48.399951, lng: 18.762179 },
    { lat: 48.400723, lng: 18.761489 },
    { lat: 48.400794, lng: 18.761377 },
    { lat: 48.400865, lng: 18.761291 },
    { lat: 48.400946, lng: 18.76104 },
    { lat: 48.400991, lng: 18.760373 },
    { lat: 48.400915, lng: 18.759448 },
    { lat: 48.400779, lng: 18.758998 },
    { lat: 48.400972, lng: 18.75893 },
    { lat: 48.401226, lng: 18.759031 },
    { lat: 48.401385, lng: 18.759109 },
    { lat: 48.401704, lng: 18.759376 },
    { lat: 48.402067, lng: 18.759517 },
    { lat: 48.402099, lng: 18.75952 },
    { lat: 48.402179, lng: 18.759538 },
    { lat: 48.402446, lng: 18.759654 },
    { lat: 48.402598, lng: 18.759638 },
    { lat: 48.403093, lng: 18.759582 },
    { lat: 48.403107, lng: 18.759588 },
    { lat: 48.40336, lng: 18.759621 },
    { lat: 48.403576, lng: 18.759632 },
    { lat: 48.40379, lng: 18.759584 },
    { lat: 48.404063, lng: 18.759509 },
    { lat: 48.404142, lng: 18.759484 },
    { lat: 48.404353, lng: 18.759392 },
    { lat: 48.404682, lng: 18.759134 },
    { lat: 48.404793, lng: 18.758941 },
    { lat: 48.404894, lng: 18.758764 },
    { lat: 48.40502, lng: 18.758406 },
    { lat: 48.40552, lng: 18.758192 },
    { lat: 48.405856, lng: 18.758038 },
    { lat: 48.40649, lng: 18.75779 },
    { lat: 48.406707, lng: 18.7578 },
    { lat: 48.407619, lng: 18.757706 },
    { lat: 48.408157, lng: 18.757229 },
    { lat: 48.408515, lng: 18.757259 },
    { lat: 48.408815, lng: 18.757185 },
    { lat: 48.409248, lng: 18.75662 },
    { lat: 48.409306, lng: 18.756545 },
    { lat: 48.409399, lng: 18.756424 },
    { lat: 48.409409, lng: 18.756353 },
    { lat: 48.409453, lng: 18.755959 },
    { lat: 48.409473, lng: 18.755778 },
    { lat: 48.409486, lng: 18.755669 },
    { lat: 48.409536, lng: 18.755224 },
    { lat: 48.409587, lng: 18.754922 },
    { lat: 48.409641, lng: 18.75415 },
    { lat: 48.409812, lng: 18.753493 },
    { lat: 48.410041, lng: 18.752976 },
    { lat: 48.410123, lng: 18.752839 },
    { lat: 48.410277, lng: 18.752581 },
    { lat: 48.410589, lng: 18.752201 },
    { lat: 48.410982, lng: 18.750913 },
    { lat: 48.411162, lng: 18.750644 },
    { lat: 48.411569, lng: 18.750526 },
    { lat: 48.41198, lng: 18.750289 },
    { lat: 48.412741, lng: 18.749619 },
    { lat: 48.413391, lng: 18.749079 },
    { lat: 48.413546, lng: 18.748891 },
    { lat: 48.414006, lng: 18.748331 },
    { lat: 48.414057, lng: 18.748301 },
    { lat: 48.414395, lng: 18.748097 },
    { lat: 48.415261, lng: 18.747904 },
    { lat: 48.415557, lng: 18.747663 },
    { lat: 48.415572, lng: 18.747642 },
    { lat: 48.415767, lng: 18.747375 },
    { lat: 48.415898, lng: 18.747197 },
    { lat: 48.416012, lng: 18.746978 },
    { lat: 48.416356, lng: 18.746329 },
    { lat: 48.416456, lng: 18.74595 },
    { lat: 48.416562, lng: 18.745443 },
    { lat: 48.416522, lng: 18.745065 },
    { lat: 48.416496, lng: 18.744213 },
    { lat: 48.416048, lng: 18.742988 },
    { lat: 48.415888, lng: 18.742833 },
    { lat: 48.415711, lng: 18.742425 },
    { lat: 48.41549, lng: 18.741446 },
    { lat: 48.41557, lng: 18.740938 },
    { lat: 48.415585, lng: 18.739891 },
    { lat: 48.41556, lng: 18.739612 },
    { lat: 48.41562, lng: 18.739331 },
    { lat: 48.415608, lng: 18.737995 },
    { lat: 48.415533, lng: 18.737419 },
    { lat: 48.415584, lng: 18.736873 },
    { lat: 48.415534, lng: 18.736452 },
    { lat: 48.415545, lng: 18.735112 },
    { lat: 48.415218, lng: 18.734781 },
    { lat: 48.414748, lng: 18.733976 },
    { lat: 48.41538, lng: 18.73306 },
    { lat: 48.415689, lng: 18.732834 },
    { lat: 48.416345, lng: 18.731908 },
    { lat: 48.416777, lng: 18.731439 },
    { lat: 48.415972, lng: 18.730119 },
    { lat: 48.415358, lng: 18.729581 },
    { lat: 48.414925, lng: 18.729273 },
    { lat: 48.414675, lng: 18.729078 },
    { lat: 48.414342, lng: 18.729125 },
    { lat: 48.414059, lng: 18.729084 },
    { lat: 48.414049, lng: 18.729082 },
    { lat: 48.414025, lng: 18.729079 },
    { lat: 48.414014, lng: 18.729077 },
    { lat: 48.413998, lng: 18.729075 },
    { lat: 48.413912, lng: 18.72905 },
    { lat: 48.413894, lng: 18.729044 },
    { lat: 48.413381, lng: 18.728897 },
    { lat: 48.412905, lng: 18.728409 },
    { lat: 48.412825, lng: 18.728271 },
    { lat: 48.412608, lng: 18.727928 },
    { lat: 48.412588, lng: 18.727886 },
    { lat: 48.412036, lng: 18.726738 },
    { lat: 48.409968, lng: 18.724783 },
    { lat: 48.409138, lng: 18.723608 },
    { lat: 48.408068, lng: 18.721382 },
    { lat: 48.407843, lng: 18.721046 },
    { lat: 48.407841, lng: 18.721044 },
    { lat: 48.407813, lng: 18.721001 },
    { lat: 48.407808, lng: 18.720993 },
    { lat: 48.407761, lng: 18.720924 },
    { lat: 48.407726, lng: 18.720871 },
    { lat: 48.407703, lng: 18.720836 },
    { lat: 48.407682, lng: 18.720804 },
    { lat: 48.406869, lng: 18.719589 },
    { lat: 48.406463, lng: 18.719395 },
    { lat: 48.406275, lng: 18.719154 },
    { lat: 48.405438, lng: 18.718549 },
    { lat: 48.405025, lng: 18.717935 },
    { lat: 48.404648, lng: 18.717501 },
    { lat: 48.404094, lng: 18.717688 },
    { lat: 48.403558, lng: 18.717758 },
    { lat: 48.403224, lng: 18.717487 },
    { lat: 48.403114, lng: 18.717399 },
    { lat: 48.402711, lng: 18.717271 },
    { lat: 48.402171, lng: 18.716809 },
    { lat: 48.402024, lng: 18.716658 },
    { lat: 48.401504, lng: 18.716128 },
    { lat: 48.401305, lng: 18.715921 },
    { lat: 48.400664, lng: 18.715256 },
    { lat: 48.399946, lng: 18.714376 },
    { lat: 48.399827, lng: 18.714172 },
    { lat: 48.399803, lng: 18.71413 },
    { lat: 48.39897, lng: 18.712705 },
    { lat: 48.398947, lng: 18.712667 },
    { lat: 48.398444, lng: 18.711806 },
    { lat: 48.398137, lng: 18.710786 },
    { lat: 48.398069, lng: 18.710331 },
    { lat: 48.39794, lng: 18.709775 },
    { lat: 48.397501, lng: 18.708474 },
    { lat: 48.39724, lng: 18.707884 },
    { lat: 48.397032, lng: 18.707553 },
    { lat: 48.396683, lng: 18.707207 },
    { lat: 48.396439, lng: 18.706801 },
    { lat: 48.396346, lng: 18.706364 },
    { lat: 48.396334, lng: 18.70631 },
    { lat: 48.396316, lng: 18.706226 },
    { lat: 48.396323, lng: 18.705844 },
    { lat: 48.39619, lng: 18.705316 },
    { lat: 48.396032, lng: 18.705052 },
    { lat: 48.395799, lng: 18.704817 },
    { lat: 48.395404, lng: 18.704018 },
    { lat: 48.39488, lng: 18.702943 },
    { lat: 48.394831, lng: 18.702709 },
    { lat: 48.39455, lng: 18.702074 },
    { lat: 48.394144, lng: 18.700927 },
    { lat: 48.393759, lng: 18.700229 },
    { lat: 48.393109, lng: 18.698313 },
  ],
  HronskéKosihy: [
    { lat: 48.2624653, lng: 18.6169546 },
    { lat: 48.2626117, lng: 18.6169081 },
    { lat: 48.2626729, lng: 18.616895 },
    { lat: 48.2627738, lng: 18.6170083 },
    { lat: 48.2643066, lng: 18.6161326 },
    { lat: 48.2659897, lng: 18.6150586 },
    { lat: 48.2662008, lng: 18.6154768 },
    { lat: 48.266626, lng: 18.6170332 },
    { lat: 48.2675943, lng: 18.6166706 },
    { lat: 48.2705376, lng: 18.6160568 },
    { lat: 48.2706464, lng: 18.6160842 },
    { lat: 48.2765687, lng: 18.613815 },
    { lat: 48.2766052, lng: 18.6138817 },
    { lat: 48.2766405, lng: 18.6137226 },
    { lat: 48.2766873, lng: 18.6130495 },
    { lat: 48.2766415, lng: 18.6111678 },
    { lat: 48.2767074, lng: 18.6105585 },
    { lat: 48.2766742, lng: 18.6099879 },
    { lat: 48.2768216, lng: 18.6085295 },
    { lat: 48.2768576, lng: 18.6062075 },
    { lat: 48.2769352, lng: 18.6053313 },
    { lat: 48.2771462, lng: 18.6047318 },
    { lat: 48.2771215, lng: 18.6047126 },
    { lat: 48.2773077, lng: 18.6040514 },
    { lat: 48.2773181, lng: 18.6037254 },
    { lat: 48.276882, lng: 18.601515 },
    { lat: 48.2766685, lng: 18.5999785 },
    { lat: 48.2768307, lng: 18.5987126 },
    { lat: 48.2773446, lng: 18.5976254 },
    { lat: 48.2774623, lng: 18.5953582 },
    { lat: 48.2777656, lng: 18.5912805 },
    { lat: 48.2767099, lng: 18.5909314 },
    { lat: 48.2753907, lng: 18.590116 },
    { lat: 48.2757191, lng: 18.5866461 },
    { lat: 48.2751662, lng: 18.5861852 },
    { lat: 48.2753659, lng: 18.5851338 },
    { lat: 48.2743964, lng: 18.5847764 },
    { lat: 48.2744898, lng: 18.5844166 },
    { lat: 48.275597, lng: 18.5823677 },
    { lat: 48.2760213, lng: 18.5809882 },
    { lat: 48.2761607, lng: 18.580338 },
    { lat: 48.2766055, lng: 18.5769004 },
    { lat: 48.2768616, lng: 18.5758081 },
    { lat: 48.2772087, lng: 18.5752832 },
    { lat: 48.2771411, lng: 18.574812 },
    { lat: 48.2769607, lng: 18.5746093 },
    { lat: 48.2766325, lng: 18.5746099 },
    { lat: 48.2763853, lng: 18.5744381 },
    { lat: 48.2757256, lng: 18.5738254 },
    { lat: 48.2753186, lng: 18.5735624 },
    { lat: 48.2749737, lng: 18.5732556 },
    { lat: 48.2730584, lng: 18.5712173 },
    { lat: 48.271817, lng: 18.5697241 },
    { lat: 48.2708475, lng: 18.5688479 },
    { lat: 48.2705566, lng: 18.5687038 },
    { lat: 48.2703586, lng: 18.5685284 },
    { lat: 48.2697503, lng: 18.5678052 },
    { lat: 48.2713619, lng: 18.5641557 },
    { lat: 48.2729858, lng: 18.5608967 },
    { lat: 48.2714759, lng: 18.5592166 },
    { lat: 48.2713572, lng: 18.5591501 },
    { lat: 48.270201, lng: 18.5574633 },
    { lat: 48.2696834, lng: 18.5567672 },
    { lat: 48.2694166, lng: 18.5564276 },
    { lat: 48.2693037, lng: 18.5562825 },
    { lat: 48.2692717, lng: 18.5563152 },
    { lat: 48.2653446, lng: 18.5611818 },
    { lat: 48.2648948, lng: 18.5619279 },
    { lat: 48.2652298, lng: 18.5626489 },
    { lat: 48.2654501, lng: 18.563002 },
    { lat: 48.2658749, lng: 18.5634465 },
    { lat: 48.2660021, lng: 18.5636677 },
    { lat: 48.2660145, lng: 18.5643066 },
    { lat: 48.2659466, lng: 18.5648375 },
    { lat: 48.2659949, lng: 18.564937 },
    { lat: 48.2645812, lng: 18.5664352 },
    { lat: 48.2621783, lng: 18.5694783 },
    { lat: 48.2578078, lng: 18.5746978 },
    { lat: 48.2573585, lng: 18.5752095 },
    { lat: 48.2570661, lng: 18.5754497 },
    { lat: 48.2573621, lng: 18.5763025 },
    { lat: 48.2578645, lng: 18.578085 },
    { lat: 48.2583034, lng: 18.5794499 },
    { lat: 48.2589681, lng: 18.5807733 },
    { lat: 48.2591288, lng: 18.5811971 },
    { lat: 48.2592887, lng: 18.5818136 },
    { lat: 48.2592226, lng: 18.5825017 },
    { lat: 48.2594004, lng: 18.5831351 },
    { lat: 48.2595812, lng: 18.5835706 },
    { lat: 48.2597811, lng: 18.5838849 },
    { lat: 48.2602348, lng: 18.5842629 },
    { lat: 48.2606302, lng: 18.5847685 },
    { lat: 48.2600792, lng: 18.5854172 },
    { lat: 48.2597816, lng: 18.5856898 },
    { lat: 48.2581327, lng: 18.5868326 },
    { lat: 48.2580095, lng: 18.5868661 },
    { lat: 48.2574371, lng: 18.5875206 },
    { lat: 48.2573361, lng: 18.5875719 },
    { lat: 48.2568852, lng: 18.5914173 },
    { lat: 48.2554274, lng: 18.5922774 },
    { lat: 48.2554457, lng: 18.5923439 },
    { lat: 48.2554611, lng: 18.5924007 },
    { lat: 48.255897, lng: 18.5922019 },
    { lat: 48.2567859, lng: 18.5935482 },
    { lat: 48.2571194, lng: 18.594181 },
    { lat: 48.2573491, lng: 18.594795 },
    { lat: 48.257969, lng: 18.5974942 },
    { lat: 48.2582818, lng: 18.5983281 },
    { lat: 48.2601556, lng: 18.6018908 },
    { lat: 48.2603774, lng: 18.6034382 },
    { lat: 48.2610313, lng: 18.6069721 },
    { lat: 48.2615431, lng: 18.6105743 },
    { lat: 48.2618963, lng: 18.6131705 },
    { lat: 48.2620165, lng: 18.6150072 },
    { lat: 48.2622096, lng: 18.6161118 },
    { lat: 48.2623044, lng: 18.6162167 },
    { lat: 48.2622257, lng: 18.6166334 },
    { lat: 48.2623122, lng: 18.616877 },
    { lat: 48.2624653, lng: 18.6169546 },
  ],
  Bory: [
    { lat: 48.2072332, lng: 18.7465536 },
    { lat: 48.2070977, lng: 18.7465298 },
    { lat: 48.2066071, lng: 18.7467658 },
    { lat: 48.2055915, lng: 18.74686 },
    { lat: 48.2045082, lng: 18.7466801 },
    { lat: 48.2037321, lng: 18.7466312 },
    { lat: 48.2033717, lng: 18.746683 },
    { lat: 48.2024304, lng: 18.7470911 },
    { lat: 48.2015321, lng: 18.7478864 },
    { lat: 48.201406, lng: 18.7480598 },
    { lat: 48.2011316, lng: 18.7472476 },
    { lat: 48.2015569, lng: 18.7468696 },
    { lat: 48.2013405, lng: 18.7454113 },
    { lat: 48.2016934, lng: 18.7435897 },
    { lat: 48.2016791, lng: 18.7422841 },
    { lat: 48.2018307, lng: 18.7413635 },
    { lat: 48.2019268, lng: 18.7413039 },
    { lat: 48.2022556, lng: 18.7390329 },
    { lat: 48.2022848, lng: 18.7387558 },
    { lat: 48.2022651, lng: 18.7377305 },
    { lat: 48.2023817, lng: 18.7358973 },
    { lat: 48.2026036, lng: 18.7348785 },
    { lat: 48.2025366, lng: 18.7343626 },
    { lat: 48.2028035, lng: 18.7338301 },
    { lat: 48.2027137, lng: 18.733179 },
    { lat: 48.202814, lng: 18.7327997 },
    { lat: 48.2028397, lng: 18.7323073 },
    { lat: 48.2024534, lng: 18.7305876 },
    { lat: 48.20212, lng: 18.7299597 },
    { lat: 48.2024627, lng: 18.7297253 },
    { lat: 48.2028056, lng: 18.729177 },
    { lat: 48.2024003, lng: 18.7287256 },
    { lat: 48.2021415, lng: 18.7282524 },
    { lat: 48.2016869, lng: 18.728335 },
    { lat: 48.2012812, lng: 18.7286374 },
    { lat: 48.2004045, lng: 18.7294128 },
    { lat: 48.1993946, lng: 18.7305212 },
    { lat: 48.1982862, lng: 18.7311584 },
    { lat: 48.196805, lng: 18.7318066 },
    { lat: 48.1952073, lng: 18.7326769 },
    { lat: 48.19403, lng: 18.7330607 },
    { lat: 48.1917233, lng: 18.733327 },
    { lat: 48.1901098, lng: 18.7343304 },
    { lat: 48.1892849, lng: 18.7345555 },
    { lat: 48.1883395, lng: 18.7343761 },
    { lat: 48.1871366, lng: 18.7350855 },
    { lat: 48.1864074, lng: 18.7356235 },
    { lat: 48.1847639, lng: 18.737897 },
    { lat: 48.184572, lng: 18.7377032 },
    { lat: 48.1840201, lng: 18.7381183 },
    { lat: 48.1837597, lng: 18.7380783 },
    { lat: 48.1836736, lng: 18.7378387 },
    { lat: 48.1832696, lng: 18.7372865 },
    { lat: 48.1826975, lng: 18.7356543 },
    { lat: 48.1823124, lng: 18.7347977 },
    { lat: 48.1815893, lng: 18.7338034 },
    { lat: 48.1812872, lng: 18.7336373 },
    { lat: 48.181219, lng: 18.7335419 },
    { lat: 48.1809937, lng: 18.7334826 },
    { lat: 48.1792538, lng: 18.7344603 },
    { lat: 48.1790889, lng: 18.7344969 },
    { lat: 48.1790544, lng: 18.7344011 },
    { lat: 48.1788607, lng: 18.734557 },
    { lat: 48.1785526, lng: 18.7346783 },
    { lat: 48.1782237, lng: 18.7347271 },
    { lat: 48.1780704, lng: 18.7348362 },
    { lat: 48.1778313, lng: 18.7351955 },
    { lat: 48.1772962, lng: 18.7344251 },
    { lat: 48.1771465, lng: 18.7337765 },
    { lat: 48.1768949, lng: 18.7330748 },
    { lat: 48.1765911, lng: 18.7326022 },
    { lat: 48.1764012, lng: 18.7321313 },
    { lat: 48.1738095, lng: 18.7283089 },
    { lat: 48.1738217, lng: 18.7282227 },
    { lat: 48.1734294, lng: 18.7280166 },
    { lat: 48.1720761, lng: 18.7324276 },
    { lat: 48.1721726, lng: 18.7331153 },
    { lat: 48.1715945, lng: 18.7351727 },
    { lat: 48.1719667, lng: 18.735446 },
    { lat: 48.171245, lng: 18.7372852 },
    { lat: 48.1729834, lng: 18.7408043 },
    { lat: 48.1733767, lng: 18.7415841 },
    { lat: 48.1730465, lng: 18.7420722 },
    { lat: 48.1725261, lng: 18.7430765 },
    { lat: 48.1721329, lng: 18.744271 },
    { lat: 48.1725584, lng: 18.7447198 },
    { lat: 48.1728043, lng: 18.7451 },
    { lat: 48.1733971, lng: 18.7462978 },
    { lat: 48.1739876, lng: 18.7477294 },
    { lat: 48.1748753, lng: 18.7518605 },
    { lat: 48.1752436, lng: 18.753078 },
    { lat: 48.1755383, lng: 18.7532793 },
    { lat: 48.1764579, lng: 18.7546207 },
    { lat: 48.1769452, lng: 18.7561259 },
    { lat: 48.1773651, lng: 18.7568749 },
    { lat: 48.1776019, lng: 18.7574495 },
    { lat: 48.1777476, lng: 18.7579629 },
    { lat: 48.1778845, lng: 18.7587325 },
    { lat: 48.178053, lng: 18.7607764 },
    { lat: 48.1780555, lng: 18.7608019 },
    { lat: 48.1793741, lng: 18.7606578 },
    { lat: 48.1796078, lng: 18.7606827 },
    { lat: 48.1801704, lng: 18.760936 },
    { lat: 48.1805626, lng: 18.7609964 },
    { lat: 48.182064, lng: 18.760871 },
    { lat: 48.1829001, lng: 18.7610723 },
    { lat: 48.1831555, lng: 18.7612925 },
    { lat: 48.1833703, lng: 18.7616221 },
    { lat: 48.1839179, lng: 18.7628452 },
    { lat: 48.1840618, lng: 18.7630666 },
    { lat: 48.1843351, lng: 18.7634373 },
    { lat: 48.1848683, lng: 18.763897 },
    { lat: 48.1854739, lng: 18.7646209 },
    { lat: 48.185947, lng: 18.7648859 },
    { lat: 48.1863744, lng: 18.7650133 },
    { lat: 48.1873094, lng: 18.7651408 },
    { lat: 48.1878328, lng: 18.7653981 },
    { lat: 48.188939, lng: 18.7660947 },
    { lat: 48.1895982, lng: 18.7666579 },
    { lat: 48.1911993, lng: 18.76846 },
    { lat: 48.1915714, lng: 18.7686718 },
    { lat: 48.1929382, lng: 18.7690707 },
    { lat: 48.1936106, lng: 18.7694829 },
    { lat: 48.1941935, lng: 18.7699262 },
    { lat: 48.1947924, lng: 18.7707098 },
    { lat: 48.1991069, lng: 18.771725 },
    { lat: 48.200163, lng: 18.772161 },
    { lat: 48.200818, lng: 18.7723293 },
    { lat: 48.2011927, lng: 18.7723315 },
    { lat: 48.2047367, lng: 18.7708994 },
    { lat: 48.2077737, lng: 18.7695769 },
    { lat: 48.207915, lng: 18.7698836 },
    { lat: 48.2107811, lng: 18.7668515 },
    { lat: 48.2110864, lng: 18.7665257 },
    { lat: 48.2095725, lng: 18.76429 },
    { lat: 48.2087219, lng: 18.7629047 },
    { lat: 48.2097189, lng: 18.7615484 },
    { lat: 48.2084577, lng: 18.7597782 },
    { lat: 48.2076652, lng: 18.7589136 },
    { lat: 48.2080358, lng: 18.7579492 },
    { lat: 48.2081329, lng: 18.7575491 },
    { lat: 48.2081887, lng: 18.7568386 },
    { lat: 48.2081184, lng: 18.7562586 },
    { lat: 48.2078213, lng: 18.7553998 },
    { lat: 48.2074292, lng: 18.7545605 },
    { lat: 48.2072141, lng: 18.754311 },
    { lat: 48.2072374, lng: 18.7542814 },
    { lat: 48.2068324, lng: 18.7535186 },
    { lat: 48.2058989, lng: 18.7514678 },
    { lat: 48.2062345, lng: 18.7495721 },
    { lat: 48.2065208, lng: 18.7482988 },
    { lat: 48.2070657, lng: 18.7468819 },
    { lat: 48.2072332, lng: 18.7465536 },
  ],
  Žemberovce: [
    { lat: 48.2264375, lng: 18.7671843 },
    { lat: 48.2266583, lng: 18.7679029 },
    { lat: 48.2266663, lng: 18.768031 },
    { lat: 48.2268413, lng: 18.7684838 },
    { lat: 48.227151, lng: 18.7690465 },
    { lat: 48.2282893, lng: 18.7705662 },
    { lat: 48.2289255, lng: 18.771332 },
    { lat: 48.2298859, lng: 18.7723038 },
    { lat: 48.2302918, lng: 18.7724088 },
    { lat: 48.2305101, lng: 18.7725685 },
    { lat: 48.2304591, lng: 18.7728187 },
    { lat: 48.230152, lng: 18.7734723 },
    { lat: 48.2296158, lng: 18.7742713 },
    { lat: 48.2296185, lng: 18.7743665 },
    { lat: 48.2299732, lng: 18.7751425 },
    { lat: 48.2302308, lng: 18.775298 },
    { lat: 48.2304617, lng: 18.7759766 },
    { lat: 48.2307258, lng: 18.7762996 },
    { lat: 48.2308573, lng: 18.7770778 },
    { lat: 48.2308384, lng: 18.7776579 },
    { lat: 48.2310828, lng: 18.7779717 },
    { lat: 48.2309779, lng: 18.7782894 },
    { lat: 48.2307415, lng: 18.7787245 },
    { lat: 48.2306313, lng: 18.7787939 },
    { lat: 48.230981, lng: 18.7797307 },
    { lat: 48.2311754, lng: 18.7796988 },
    { lat: 48.231436, lng: 18.7802773 },
    { lat: 48.2316041, lng: 18.7808329 },
    { lat: 48.2320802, lng: 18.781451 },
    { lat: 48.2320716, lng: 18.7818195 },
    { lat: 48.232212, lng: 18.7818343 },
    { lat: 48.2322807, lng: 18.7819181 },
    { lat: 48.2323117, lng: 18.7820973 },
    { lat: 48.232152, lng: 18.7827848 },
    { lat: 48.2322361, lng: 18.7829895 },
    { lat: 48.2326779, lng: 18.7832435 },
    { lat: 48.2328465, lng: 18.7832755 },
    { lat: 48.2334346, lng: 18.7842439 },
    { lat: 48.2339259, lng: 18.7844708 },
    { lat: 48.2347619, lng: 18.7853314 },
    { lat: 48.2347914, lng: 18.7856307 },
    { lat: 48.2350813, lng: 18.7858263 },
    { lat: 48.2354156, lng: 18.785747 },
    { lat: 48.2356565, lng: 18.785762 },
    { lat: 48.2359404, lng: 18.785948 },
    { lat: 48.2362452, lng: 18.7865507 },
    { lat: 48.2364061, lng: 18.7870135 },
    { lat: 48.2365395, lng: 18.7871689 },
    { lat: 48.2365155, lng: 18.7874834 },
    { lat: 48.2368154, lng: 18.7874167 },
    { lat: 48.2368548, lng: 18.7876073 },
    { lat: 48.2367567, lng: 18.7878443 },
    { lat: 48.236821, lng: 18.7879067 },
    { lat: 48.2369304, lng: 18.7878216 },
    { lat: 48.2369646, lng: 18.7878515 },
    { lat: 48.2371501, lng: 18.7882678 },
    { lat: 48.2374315, lng: 18.7882125 },
    { lat: 48.2375802, lng: 18.7883796 },
    { lat: 48.2377229, lng: 18.7883068 },
    { lat: 48.2380596, lng: 18.7895351 },
    { lat: 48.2382436, lng: 18.7898366 },
    { lat: 48.2382176, lng: 18.7902391 },
    { lat: 48.2382969, lng: 18.7903312 },
    { lat: 48.2382609, lng: 18.7907186 },
    { lat: 48.2384129, lng: 18.7910155 },
    { lat: 48.2387153, lng: 18.7919118 },
    { lat: 48.2390202, lng: 18.7920473 },
    { lat: 48.2392623, lng: 18.7923179 },
    { lat: 48.2394518, lng: 18.7921918 },
    { lat: 48.2395654, lng: 18.7923344 },
    { lat: 48.2396845, lng: 18.7921551 },
    { lat: 48.2400826, lng: 18.7923686 },
    { lat: 48.2401225, lng: 18.7926144 },
    { lat: 48.2403003, lng: 18.7929903 },
    { lat: 48.2404132, lng: 18.793464 },
    { lat: 48.2404481, lng: 18.793866 },
    { lat: 48.2403983, lng: 18.7944846 },
    { lat: 48.2408502, lng: 18.7950089 },
    { lat: 48.2410979, lng: 18.7955566 },
    { lat: 48.2419799, lng: 18.7963497 },
    { lat: 48.2420626, lng: 18.7965915 },
    { lat: 48.2426509, lng: 18.7973547 },
    { lat: 48.24302, lng: 18.798003 },
    { lat: 48.2454674, lng: 18.8011489 },
    { lat: 48.2455797, lng: 18.8013515 },
    { lat: 48.2457986, lng: 18.8013535 },
    { lat: 48.2463217, lng: 18.8020605 },
    { lat: 48.2465588, lng: 18.8024687 },
    { lat: 48.2467339, lng: 18.8029253 },
    { lat: 48.2464806, lng: 18.8032542 },
    { lat: 48.2459342, lng: 18.8045479 },
    { lat: 48.2460303, lng: 18.8048741 },
    { lat: 48.2458206, lng: 18.8052396 },
    { lat: 48.245284, lng: 18.805666 },
    { lat: 48.245366, lng: 18.805862 },
    { lat: 48.24552, lng: 18.805958 },
    { lat: 48.245574, lng: 18.806099 },
    { lat: 48.246261, lng: 18.806726 },
    { lat: 48.246562, lng: 18.807059 },
    { lat: 48.246615, lng: 18.807216 },
    { lat: 48.246887, lng: 18.807626 },
    { lat: 48.246931, lng: 18.807793 },
    { lat: 48.247327, lng: 18.808188 },
    { lat: 48.247454, lng: 18.808388 },
    { lat: 48.247565, lng: 18.808551 },
    { lat: 48.247878, lng: 18.808905 },
    { lat: 48.247947, lng: 18.809103 },
    { lat: 48.248094, lng: 18.809269 },
    { lat: 48.249315, lng: 18.811081 },
    { lat: 48.249866, lng: 18.811475 },
    { lat: 48.250386, lng: 18.812198 },
    { lat: 48.250736, lng: 18.812539 },
    { lat: 48.25076, lng: 18.812563 },
    { lat: 48.251105, lng: 18.812905 },
    { lat: 48.251567, lng: 18.812847 },
    { lat: 48.251783, lng: 18.812943 },
    { lat: 48.252036, lng: 18.813 },
    { lat: 48.252142, lng: 18.813105 },
    { lat: 48.252539, lng: 18.81315 },
    { lat: 48.252746, lng: 18.81318 },
    { lat: 48.252748, lng: 18.81318 },
    { lat: 48.253543, lng: 18.8133 },
    { lat: 48.253843, lng: 18.813345 },
    { lat: 48.254561, lng: 18.813351 },
    { lat: 48.25518, lng: 18.813356 },
    { lat: 48.255724, lng: 18.813281 },
    { lat: 48.255807, lng: 18.813311 },
    { lat: 48.256204, lng: 18.813305 },
    { lat: 48.256566, lng: 18.813427 },
    { lat: 48.257055, lng: 18.813525 },
    { lat: 48.257308, lng: 18.813608 },
    { lat: 48.25782, lng: 18.813798 },
    { lat: 48.257867, lng: 18.81383 },
    { lat: 48.258241, lng: 18.814093 },
    { lat: 48.258515, lng: 18.814205 },
    { lat: 48.258777, lng: 18.814556 },
    { lat: 48.259152, lng: 18.815376 },
    { lat: 48.259581, lng: 18.816017 },
    { lat: 48.259984, lng: 18.817212 },
    { lat: 48.260297, lng: 18.817223 },
    { lat: 48.260547, lng: 18.817209 },
    { lat: 48.260715, lng: 18.817493 },
    { lat: 48.26091, lng: 18.817828 },
    { lat: 48.261244, lng: 18.818577 },
    { lat: 48.261447, lng: 18.818889 },
    { lat: 48.262151, lng: 18.8205 },
    { lat: 48.262512, lng: 18.821348 },
    { lat: 48.262912, lng: 18.822265 },
    { lat: 48.263757, lng: 18.824286 },
    { lat: 48.264333, lng: 18.825834 },
    { lat: 48.264881, lng: 18.825674 },
    { lat: 48.26521, lng: 18.826027 },
    { lat: 48.26526, lng: 18.826081 },
    { lat: 48.265368, lng: 18.826255 },
    { lat: 48.265559, lng: 18.826632 },
    { lat: 48.2662007, lng: 18.8267436 },
    { lat: 48.2666786, lng: 18.8251916 },
    { lat: 48.2672588, lng: 18.8237836 },
    { lat: 48.2674703, lng: 18.822854 },
    { lat: 48.2686706, lng: 18.8202186 },
    { lat: 48.2696664, lng: 18.8183116 },
    { lat: 48.2698487, lng: 18.8177651 },
    { lat: 48.2699834, lng: 18.8167054 },
    { lat: 48.2698713, lng: 18.8155176 },
    { lat: 48.2699775, lng: 18.813547 },
    { lat: 48.270298, lng: 18.8106785 },
    { lat: 48.2701553, lng: 18.8088866 },
    { lat: 48.2702189, lng: 18.808084 },
    { lat: 48.2703237, lng: 18.8075474 },
    { lat: 48.2703321, lng: 18.8071065 },
    { lat: 48.2703041, lng: 18.8065739 },
    { lat: 48.2696022, lng: 18.8046633 },
    { lat: 48.2691979, lng: 18.8043493 },
    { lat: 48.2681845, lng: 18.8032897 },
    { lat: 48.2673308, lng: 18.8021975 },
    { lat: 48.2668492, lng: 18.8020049 },
    { lat: 48.2666067, lng: 18.8019826 },
    { lat: 48.2663105, lng: 18.8017954 },
    { lat: 48.2659293, lng: 18.8011765 },
    { lat: 48.2647462, lng: 18.8007228 },
    { lat: 48.2643343, lng: 18.8002293 },
    { lat: 48.2642013, lng: 18.7999308 },
    { lat: 48.2638477, lng: 18.7994276 },
    { lat: 48.2623223, lng: 18.797917 },
    { lat: 48.2621215, lng: 18.7978923 },
    { lat: 48.2619742, lng: 18.7977288 },
    { lat: 48.2618088, lng: 18.7977788 },
    { lat: 48.2613366, lng: 18.7973382 },
    { lat: 48.2611512, lng: 18.7971166 },
    { lat: 48.2609825, lng: 18.7967823 },
    { lat: 48.2610136, lng: 18.7965783 },
    { lat: 48.2609242, lng: 18.7961249 },
    { lat: 48.2609623, lng: 18.7956735 },
    { lat: 48.2608904, lng: 18.7953596 },
    { lat: 48.2608699, lng: 18.7948403 },
    { lat: 48.2606167, lng: 18.7939516 },
    { lat: 48.2607998, lng: 18.7938124 },
    { lat: 48.2610101, lng: 18.7930744 },
    { lat: 48.2609689, lng: 18.7927855 },
    { lat: 48.2611746, lng: 18.7921305 },
    { lat: 48.2612648, lng: 18.791556 },
    { lat: 48.2613626, lng: 18.7913763 },
    { lat: 48.2616262, lng: 18.7913055 },
    { lat: 48.2616408, lng: 18.7910726 },
    { lat: 48.2619114, lng: 18.7907741 },
    { lat: 48.262152, lng: 18.7898608 },
    { lat: 48.2622841, lng: 18.7896292 },
    { lat: 48.2623689, lng: 18.7889701 },
    { lat: 48.2625506, lng: 18.7888257 },
    { lat: 48.262687, lng: 18.7882465 },
    { lat: 48.2625715, lng: 18.7879381 },
    { lat: 48.2625748, lng: 18.7876825 },
    { lat: 48.2627649, lng: 18.7874392 },
    { lat: 48.2630022, lng: 18.7872974 },
    { lat: 48.2632013, lng: 18.7874377 },
    { lat: 48.2631498, lng: 18.7871691 },
    { lat: 48.2635871, lng: 18.7844943 },
    { lat: 48.2640272, lng: 18.7831981 },
    { lat: 48.2640622, lng: 18.7811312 },
    { lat: 48.2638965, lng: 18.7788305 },
    { lat: 48.2639539, lng: 18.7778691 },
    { lat: 48.263804, lng: 18.7760825 },
    { lat: 48.2643142, lng: 18.7759899 },
    { lat: 48.2649453, lng: 18.7758914 },
    { lat: 48.265196, lng: 18.7729823 },
    { lat: 48.2653955, lng: 18.7720642 },
    { lat: 48.2658284, lng: 18.7702706 },
    { lat: 48.2659287, lng: 18.7700587 },
    { lat: 48.2661264, lng: 18.7692763 },
    { lat: 48.2661685, lng: 18.7689248 },
    { lat: 48.2663167, lng: 18.7686534 },
    { lat: 48.2663153, lng: 18.7682252 },
    { lat: 48.2665535, lng: 18.7675474 },
    { lat: 48.2665901, lng: 18.7670249 },
    { lat: 48.2666245, lng: 18.7669744 },
    { lat: 48.2667029, lng: 18.7670051 },
    { lat: 48.2667809, lng: 18.7672177 },
    { lat: 48.2668743, lng: 18.7671748 },
    { lat: 48.2668203, lng: 18.7669624 },
    { lat: 48.2669332, lng: 18.7667526 },
    { lat: 48.2669521, lng: 18.7666002 },
    { lat: 48.2668193, lng: 18.7664769 },
    { lat: 48.266822, lng: 18.7660812 },
    { lat: 48.2669632, lng: 18.7661285 },
    { lat: 48.2670647, lng: 18.7662771 },
    { lat: 48.2671424, lng: 18.7662088 },
    { lat: 48.267085, lng: 18.7658528 },
    { lat: 48.2671413, lng: 18.7656581 },
    { lat: 48.2670487, lng: 18.7654228 },
    { lat: 48.2669978, lng: 18.7646777 },
    { lat: 48.2673605, lng: 18.764476 },
    { lat: 48.2675441, lng: 18.7641717 },
    { lat: 48.2677926, lng: 18.7639474 },
    { lat: 48.2685684, lng: 18.7634572 },
    { lat: 48.2694505, lng: 18.7627767 },
    { lat: 48.2696249, lng: 18.7627499 },
    { lat: 48.2695871, lng: 18.7626974 },
    { lat: 48.269547, lng: 18.762156 },
    { lat: 48.2695909, lng: 18.7618747 },
    { lat: 48.2697843, lng: 18.761247 },
    { lat: 48.2698138, lng: 18.7606995 },
    { lat: 48.2697368, lng: 18.7599915 },
    { lat: 48.2702085, lng: 18.7589777 },
    { lat: 48.2705922, lng: 18.7579193 },
    { lat: 48.2723158, lng: 18.755848 },
    { lat: 48.2721509, lng: 18.7552042 },
    { lat: 48.2724169, lng: 18.7549704 },
    { lat: 48.2728818, lng: 18.7547808 },
    { lat: 48.2728821, lng: 18.754065 },
    { lat: 48.2737331, lng: 18.7539306 },
    { lat: 48.2738845, lng: 18.752915 },
    { lat: 48.2739952, lng: 18.7526019 },
    { lat: 48.2740062, lng: 18.7522136 },
    { lat: 48.2740362, lng: 18.7521187 },
    { lat: 48.2741804, lng: 18.752013 },
    { lat: 48.2743091, lng: 18.750126 },
    { lat: 48.2743397, lng: 18.7501291 },
    { lat: 48.2745128, lng: 18.7488856 },
    { lat: 48.2746114, lng: 18.7487666 },
    { lat: 48.2748736, lng: 18.7474003 },
    { lat: 48.2749144, lng: 18.74696 },
    { lat: 48.2753707, lng: 18.7456161 },
    { lat: 48.2755603, lng: 18.7453644 },
    { lat: 48.2767052, lng: 18.741681 },
    { lat: 48.2776168, lng: 18.7391667 },
    { lat: 48.2781897, lng: 18.7372182 },
    { lat: 48.2782142, lng: 18.7369788 },
    { lat: 48.2783435, lng: 18.7365487 },
    { lat: 48.2779867, lng: 18.7336616 },
    { lat: 48.2778857, lng: 18.7336702 },
    { lat: 48.2777266, lng: 18.732592 },
    { lat: 48.2775739, lng: 18.7326432 },
    { lat: 48.2774042, lng: 18.7316644 },
    { lat: 48.2767138, lng: 18.7316812 },
    { lat: 48.2768368, lng: 18.7278318 },
    { lat: 48.2783297, lng: 18.725357 },
    { lat: 48.2786869, lng: 18.724548 },
    { lat: 48.2791262, lng: 18.724853 },
    { lat: 48.2800014, lng: 18.7225529 },
    { lat: 48.2795861, lng: 18.7221665 },
    { lat: 48.277886, lng: 18.7204021 },
    { lat: 48.2768501, lng: 18.7194645 },
    { lat: 48.2746773, lng: 18.7178908 },
    { lat: 48.2748635, lng: 18.717514 },
    { lat: 48.2742583, lng: 18.7170775 },
    { lat: 48.2728022, lng: 18.7159582 },
    { lat: 48.2710571, lng: 18.7150886 },
    { lat: 48.2710085, lng: 18.715258 },
    { lat: 48.2680182, lng: 18.7130758 },
    { lat: 48.2669288, lng: 18.7120657 },
    { lat: 48.2651198, lng: 18.7143843 },
    { lat: 48.2650584, lng: 18.7138282 },
    { lat: 48.2648836, lng: 18.7134049 },
    { lat: 48.2644144, lng: 18.7123537 },
    { lat: 48.2636534, lng: 18.7109662 },
    { lat: 48.2632701, lng: 18.7113916 },
    { lat: 48.260281, lng: 18.7119532 },
    { lat: 48.2601676, lng: 18.711225 },
    { lat: 48.259949, lng: 18.710326 },
    { lat: 48.2597595, lng: 18.7090807 },
    { lat: 48.2593389, lng: 18.709312 },
    { lat: 48.2589017, lng: 18.7093829 },
    { lat: 48.2588121, lng: 18.7092673 },
    { lat: 48.2587987, lng: 18.7091465 },
    { lat: 48.2588379, lng: 18.7083196 },
    { lat: 48.2587751, lng: 18.708095 },
    { lat: 48.258508, lng: 18.7076751 },
    { lat: 48.2580063, lng: 18.7074692 },
    { lat: 48.2572473, lng: 18.7075943 },
    { lat: 48.2570885, lng: 18.7074941 },
    { lat: 48.2567079, lng: 18.7070419 },
    { lat: 48.2565482, lng: 18.7063154 },
    { lat: 48.256515, lng: 18.7054215 },
    { lat: 48.2562039, lng: 18.7047712 },
    { lat: 48.2558908, lng: 18.7046758 },
    { lat: 48.2555363, lng: 18.704689 },
    { lat: 48.2529461, lng: 18.7063404 },
    { lat: 48.2527812, lng: 18.70642 },
    { lat: 48.2525002, lng: 18.7064392 },
    { lat: 48.2522303, lng: 18.7062621 },
    { lat: 48.2517485, lng: 18.7047201 },
    { lat: 48.2514551, lng: 18.7041715 },
    { lat: 48.2511439, lng: 18.7039212 },
    { lat: 48.2508736, lng: 18.7038436 },
    { lat: 48.2496989, lng: 18.7038949 },
    { lat: 48.2489954, lng: 18.703398 },
    { lat: 48.2485888, lng: 18.7027126 },
    { lat: 48.2485129, lng: 18.7022833 },
    { lat: 48.2484396, lng: 18.7009821 },
    { lat: 48.2484699, lng: 18.7007453 },
    { lat: 48.2486403, lng: 18.700349 },
    { lat: 48.2489163, lng: 18.7001318 },
    { lat: 48.2492444, lng: 18.7000446 },
    { lat: 48.2496205, lng: 18.7000783 },
    { lat: 48.2503779, lng: 18.7004016 },
    { lat: 48.2507583, lng: 18.7004935 },
    { lat: 48.2510421, lng: 18.7004658 },
    { lat: 48.2511859, lng: 18.7003094 },
    { lat: 48.2512567, lng: 18.7000514 },
    { lat: 48.2510298, lng: 18.7001091 },
    { lat: 48.2506535, lng: 18.7000542 },
    { lat: 48.2505127, lng: 18.7001228 },
    { lat: 48.2496153, lng: 18.6997693 },
    { lat: 48.2494653, lng: 18.6998538 },
    { lat: 48.2490677, lng: 18.699822 },
    { lat: 48.2485612, lng: 18.6999452 },
    { lat: 48.2481753, lng: 18.7006597 },
    { lat: 48.2474508, lng: 18.7014074 },
    { lat: 48.2470838, lng: 18.7025577 },
    { lat: 48.2469424, lng: 18.7037243 },
    { lat: 48.2462742, lng: 18.7061353 },
    { lat: 48.2456009, lng: 18.7081396 },
    { lat: 48.2450836, lng: 18.7090941 },
    { lat: 48.2444428, lng: 18.7099516 },
    { lat: 48.243137, lng: 18.7113014 },
    { lat: 48.2422161, lng: 18.713252 },
    { lat: 48.2415079, lng: 18.7149178 },
    { lat: 48.240589, lng: 18.7142723 },
    { lat: 48.2400433, lng: 18.7155586 },
    { lat: 48.2394517, lng: 18.7167716 },
    { lat: 48.2383396, lng: 18.7160314 },
    { lat: 48.2365758, lng: 18.7150853 },
    { lat: 48.2349087, lng: 18.7144935 },
    { lat: 48.2346814, lng: 18.7144196 },
    { lat: 48.2345681, lng: 18.7149132 },
    { lat: 48.2343271, lng: 18.716124 },
    { lat: 48.2342869, lng: 18.7167825 },
    { lat: 48.2340997, lng: 18.7176911 },
    { lat: 48.2338071, lng: 18.7186502 },
    { lat: 48.234157, lng: 18.7194788 },
    { lat: 48.2344541, lng: 18.7203333 },
    { lat: 48.2348112, lng: 18.7209333 },
    { lat: 48.2352789, lng: 18.7213927 },
    { lat: 48.2349998, lng: 18.7220388 },
    { lat: 48.2360879, lng: 18.7228584 },
    { lat: 48.2366569, lng: 18.723647 },
    { lat: 48.2371232, lng: 18.7245234 },
    { lat: 48.2373503, lng: 18.7250148 },
    { lat: 48.2374355, lng: 18.7260391 },
    { lat: 48.2376881, lng: 18.7269665 },
    { lat: 48.2377067, lng: 18.7274871 },
    { lat: 48.2379136, lng: 18.7286358 },
    { lat: 48.2379196, lng: 18.728961 },
    { lat: 48.237844, lng: 18.7294448 },
    { lat: 48.2379374, lng: 18.7299282 },
    { lat: 48.2382657, lng: 18.7308798 },
    { lat: 48.2387565, lng: 18.7317516 },
    { lat: 48.2384608, lng: 18.7325486 },
    { lat: 48.2381026, lng: 18.7331918 },
    { lat: 48.2377895, lng: 18.7348045 },
    { lat: 48.2383448, lng: 18.7351316 },
    { lat: 48.2381479, lng: 18.7369165 },
    { lat: 48.2380944, lng: 18.7371185 },
    { lat: 48.2382344, lng: 18.7387079 },
    { lat: 48.2383138, lng: 18.7390359 },
    { lat: 48.2383104, lng: 18.7403687 },
    { lat: 48.237858, lng: 18.7411698 },
    { lat: 48.2378967, lng: 18.7416 },
    { lat: 48.2382448, lng: 18.7422256 },
    { lat: 48.2378248, lng: 18.743152 },
    { lat: 48.2372903, lng: 18.7441466 },
    { lat: 48.2381566, lng: 18.7455036 },
    { lat: 48.237616, lng: 18.7466817 },
    { lat: 48.2384543, lng: 18.7477311 },
    { lat: 48.2367408, lng: 18.7515561 },
    { lat: 48.236134, lng: 18.752447 },
    { lat: 48.2358117, lng: 18.7527497 },
    { lat: 48.2358088, lng: 18.752842 },
    { lat: 48.2351779, lng: 18.7534085 },
    { lat: 48.2347623, lng: 18.7539267 },
    { lat: 48.2337344, lng: 18.7554885 },
    { lat: 48.2310137, lng: 18.7590847 },
    { lat: 48.229846, lng: 18.7607959 },
    { lat: 48.228054, lng: 18.7631953 },
    { lat: 48.2271692, lng: 18.7642908 },
    { lat: 48.2256284, lng: 18.765858 },
    { lat: 48.2252255, lng: 18.7663851 },
    { lat: 48.2252582, lng: 18.7664052 },
    { lat: 48.2258837, lng: 18.7665233 },
    { lat: 48.2260576, lng: 18.7666345 },
    { lat: 48.2262482, lng: 18.7669758 },
    { lat: 48.2264375, lng: 18.7671843 },
  ],
  VeľkéKozmálovce: [
    { lat: 48.2566004, lng: 18.5232271 },
    { lat: 48.256357, lng: 18.5234374 },
    { lat: 48.2556569, lng: 18.5240596 },
    { lat: 48.2550741, lng: 18.524229 },
    { lat: 48.2540428, lng: 18.5244093 },
    { lat: 48.255662, lng: 18.5260114 },
    { lat: 48.2562336, lng: 18.5288285 },
    { lat: 48.256481, lng: 18.5297575 },
    { lat: 48.2568388, lng: 18.5307423 },
    { lat: 48.2569574, lng: 18.5323637 },
    { lat: 48.2572974, lng: 18.5341542 },
    { lat: 48.2573212, lng: 18.5349718 },
    { lat: 48.2529573, lng: 18.5391002 },
    { lat: 48.2546246, lng: 18.5485584 },
    { lat: 48.2544277, lng: 18.5487901 },
    { lat: 48.2601661, lng: 18.5591069 },
    { lat: 48.2607712, lng: 18.5584788 },
    { lat: 48.2616795, lng: 18.5576622 },
    { lat: 48.2625734, lng: 18.5601778 },
    { lat: 48.2629371, lng: 18.5609693 },
    { lat: 48.2636296, lng: 18.5601658 },
    { lat: 48.2638426, lng: 18.5605545 },
    { lat: 48.2644621, lng: 18.5595883 },
    { lat: 48.2648512, lng: 18.5603915 },
    { lat: 48.2653446, lng: 18.5611818 },
    { lat: 48.2692717, lng: 18.5563152 },
    { lat: 48.2693037, lng: 18.5562825 },
    { lat: 48.2694166, lng: 18.5564276 },
    { lat: 48.2696834, lng: 18.5567672 },
    { lat: 48.2701388, lng: 18.5560632 },
    { lat: 48.2706031, lng: 18.5555224 },
    { lat: 48.2713883, lng: 18.5547406 },
    { lat: 48.272467, lng: 18.5538443 },
    { lat: 48.2721686, lng: 18.5528036 },
    { lat: 48.2728939, lng: 18.5525567 },
    { lat: 48.2739884, lng: 18.5524796 },
    { lat: 48.2748952, lng: 18.5527666 },
    { lat: 48.2758788, lng: 18.5533012 },
    { lat: 48.2770335, lng: 18.5519999 },
    { lat: 48.2777136, lng: 18.5510931 },
    { lat: 48.2780769, lng: 18.5507049 },
    { lat: 48.2785002, lng: 18.5514707 },
    { lat: 48.278878, lng: 18.5513476 },
    { lat: 48.2789608, lng: 18.5516186 },
    { lat: 48.2792503, lng: 18.551532 },
    { lat: 48.2797457, lng: 18.5512046 },
    { lat: 48.2803524, lng: 18.550588 },
    { lat: 48.2805993, lng: 18.5502753 },
    { lat: 48.2809413, lng: 18.5496849 },
    { lat: 48.2814978, lng: 18.549054 },
    { lat: 48.2816644, lng: 18.5489073 },
    { lat: 48.2816056, lng: 18.5482024 },
    { lat: 48.2813715, lng: 18.5477227 },
    { lat: 48.2810903, lng: 18.5468129 },
    { lat: 48.2802684, lng: 18.543431 },
    { lat: 48.2798497, lng: 18.5414184 },
    { lat: 48.2797155, lng: 18.5409474 },
    { lat: 48.2800009, lng: 18.5408232 },
    { lat: 48.2793344, lng: 18.5374064 },
    { lat: 48.2792222, lng: 18.5364711 },
    { lat: 48.2788785, lng: 18.5345121 },
    { lat: 48.278704, lng: 18.533842 },
    { lat: 48.2786351, lng: 18.5337153 },
    { lat: 48.2781484, lng: 18.533235 },
    { lat: 48.2779332, lng: 18.5325463 },
    { lat: 48.2775502, lng: 18.5308549 },
    { lat: 48.2775215, lng: 18.530209 },
    { lat: 48.2775813, lng: 18.5296987 },
    { lat: 48.277574, lng: 18.5293438 },
    { lat: 48.2771557, lng: 18.5291823 },
    { lat: 48.2772409, lng: 18.5285358 },
    { lat: 48.2769532, lng: 18.5281875 },
    { lat: 48.2773405, lng: 18.5270914 },
    { lat: 48.2770551, lng: 18.5266408 },
    { lat: 48.2770128, lng: 18.5262201 },
    { lat: 48.2770222, lng: 18.5257178 },
    { lat: 48.2765471, lng: 18.5251403 },
    { lat: 48.2766307, lng: 18.5247956 },
    { lat: 48.2759775, lng: 18.5238336 },
    { lat: 48.2759448, lng: 18.5236482 },
    { lat: 48.2756779, lng: 18.5234507 },
    { lat: 48.275429, lng: 18.5233569 },
    { lat: 48.2744935, lng: 18.5232082 },
    { lat: 48.2740069, lng: 18.5232008 },
    { lat: 48.2736402, lng: 18.5233627 },
    { lat: 48.2731892, lng: 18.5236713 },
    { lat: 48.2724525, lng: 18.5239775 },
    { lat: 48.2722073, lng: 18.524358 },
    { lat: 48.2711329, lng: 18.5248628 },
    { lat: 48.2701638, lng: 18.5250021 },
    { lat: 48.2699419, lng: 18.5248062 },
    { lat: 48.2697234, lng: 18.5247911 },
    { lat: 48.268892, lng: 18.5256262 },
    { lat: 48.2685054, lng: 18.526369 },
    { lat: 48.2669552, lng: 18.5286164 },
    { lat: 48.2661696, lng: 18.5302448 },
    { lat: 48.2660164, lng: 18.5304758 },
    { lat: 48.265086, lng: 18.5308652 },
    { lat: 48.2641454, lng: 18.5306664 },
    { lat: 48.2638576, lng: 18.5303349 },
    { lat: 48.2635301, lng: 18.530186 },
    { lat: 48.2629335, lng: 18.5297149 },
    { lat: 48.262341, lng: 18.5291003 },
    { lat: 48.2616327, lng: 18.5282019 },
    { lat: 48.2614451, lng: 18.5276633 },
    { lat: 48.2607629, lng: 18.526703 },
    { lat: 48.2604462, lng: 18.5263439 },
    { lat: 48.2595095, lng: 18.5255358 },
    { lat: 48.2574951, lng: 18.524191 },
    { lat: 48.256888, lng: 18.5235936 },
    { lat: 48.2566004, lng: 18.5232271 },
  ],
  Farná: [
    { lat: 47.996572, lng: 18.465616 },
    { lat: 47.9961289, lng: 18.4661079 },
    { lat: 47.9954252, lng: 18.4671603 },
    { lat: 47.9945366, lng: 18.4681747 },
    { lat: 47.9939456, lng: 18.468987 },
    { lat: 47.9919141, lng: 18.4715314 },
    { lat: 47.9912096, lng: 18.4725717 },
    { lat: 47.9897778, lng: 18.4738189 },
    { lat: 47.9891577, lng: 18.4745708 },
    { lat: 47.98818, lng: 18.475464 },
    { lat: 47.9870672, lng: 18.4767488 },
    { lat: 47.9865931, lng: 18.4770668 },
    { lat: 47.9860794, lng: 18.4777871 },
    { lat: 47.9858464, lng: 18.4783583 },
    { lat: 47.9852336, lng: 18.4791445 },
    { lat: 47.9849714, lng: 18.4796815 },
    { lat: 47.9844098, lng: 18.4803096 },
    { lat: 47.9836789, lng: 18.4815466 },
    { lat: 47.9836178, lng: 18.4823277 },
    { lat: 47.9836345, lng: 18.4830711 },
    { lat: 47.9835963, lng: 18.4836402 },
    { lat: 47.9834513, lng: 18.4843789 },
    { lat: 47.9837923, lng: 18.48608 },
    { lat: 47.9839238, lng: 18.4871676 },
    { lat: 47.9840881, lng: 18.4879465 },
    { lat: 47.9842656, lng: 18.4882383 },
    { lat: 47.9844476, lng: 18.4883869 },
    { lat: 47.9847758, lng: 18.4888495 },
    { lat: 47.9851093, lng: 18.4895698 },
    { lat: 47.9854698, lng: 18.4906552 },
    { lat: 47.9855478, lng: 18.4910209 },
    { lat: 47.985561, lng: 18.4914185 },
    { lat: 47.9855085, lng: 18.4917314 },
    { lat: 47.9852572, lng: 18.4919392 },
    { lat: 47.9850425, lng: 18.492277 },
    { lat: 47.9847919, lng: 18.4925193 },
    { lat: 47.9846611, lng: 18.4923981 },
    { lat: 47.984523, lng: 18.4925282 },
    { lat: 47.9838745, lng: 18.4935461 },
    { lat: 47.9834007, lng: 18.4949226 },
    { lat: 47.9828776, lng: 18.4974772 },
    { lat: 47.9825955, lng: 18.4985923 },
    { lat: 47.9823882, lng: 18.4998319 },
    { lat: 47.9822369, lng: 18.5003379 },
    { lat: 47.9820122, lng: 18.5022169 },
    { lat: 47.9816857, lng: 18.5034043 },
    { lat: 47.9810645, lng: 18.5052963 },
    { lat: 47.9810957, lng: 18.5053079 },
    { lat: 47.9803784, lng: 18.5086176 },
    { lat: 47.9803338, lng: 18.508891 },
    { lat: 47.98033, lng: 18.5093925 },
    { lat: 47.9795229, lng: 18.5132411 },
    { lat: 47.9791681, lng: 18.5155215 },
    { lat: 47.9791805, lng: 18.5157875 },
    { lat: 47.9793718, lng: 18.5182161 },
    { lat: 47.979991, lng: 18.5220552 },
    { lat: 47.9802654, lng: 18.5235022 },
    { lat: 47.9803237, lng: 18.5235605 },
    { lat: 47.9823549, lng: 18.525709 },
    { lat: 47.9879971, lng: 18.5310583 },
    { lat: 47.9880698, lng: 18.5312913 },
    { lat: 47.9896694, lng: 18.5328201 },
    { lat: 47.9926775, lng: 18.5359491 },
    { lat: 47.9892025, lng: 18.540627 },
    { lat: 47.988604, lng: 18.5413272 },
    { lat: 47.9893769, lng: 18.542494 },
    { lat: 47.9903036, lng: 18.5435476 },
    { lat: 47.9967105, lng: 18.5497491 },
    { lat: 48.0002895, lng: 18.5537279 },
    { lat: 48.0043657, lng: 18.5577722 },
    { lat: 48.0049195, lng: 18.5583029 },
    { lat: 48.0051718, lng: 18.5584386 },
    { lat: 48.0060189, lng: 18.5570052 },
    { lat: 48.006546, lng: 18.5561144 },
    { lat: 48.0072766, lng: 18.5551024 },
    { lat: 48.0095407, lng: 18.5536549 },
    { lat: 48.0107412, lng: 18.5527589 },
    { lat: 48.011649, lng: 18.5517342 },
    { lat: 48.0130321, lng: 18.5503254 },
    { lat: 48.0165014, lng: 18.5431697 },
    { lat: 48.0169059, lng: 18.5421403 },
    { lat: 48.0179128, lng: 18.5391932 },
    { lat: 48.019885, lng: 18.5405509 },
    { lat: 48.0224041, lng: 18.5420649 },
    { lat: 48.0243895, lng: 18.543127 },
    { lat: 48.0256676, lng: 18.5436579 },
    { lat: 48.0265769, lng: 18.5399651 },
    { lat: 48.0282315, lng: 18.5362278 },
    { lat: 48.0293613, lng: 18.53365 },
    { lat: 48.0304691, lng: 18.5310649 },
    { lat: 48.0314824, lng: 18.5319855 },
    { lat: 48.0345666, lng: 18.535983 },
    { lat: 48.0388125, lng: 18.5429216 },
    { lat: 48.0400091, lng: 18.5444929 },
    { lat: 48.0415015, lng: 18.5470643 },
    { lat: 48.0431334, lng: 18.5493293 },
    { lat: 48.0433307, lng: 18.5494701 },
    { lat: 48.0438944, lng: 18.5503047 },
    { lat: 48.0452107, lng: 18.5482327 },
    { lat: 48.0457901, lng: 18.5473304 },
    { lat: 48.0448276, lng: 18.5435854 },
    { lat: 48.0454223, lng: 18.5424253 },
    { lat: 48.0426041, lng: 18.5383163 },
    { lat: 48.040086, lng: 18.5343926 },
    { lat: 48.0386557, lng: 18.5320688 },
    { lat: 48.0343965, lng: 18.5266531 },
    { lat: 48.0307685, lng: 18.5221702 },
    { lat: 48.0316125, lng: 18.5194692 },
    { lat: 48.0325698, lng: 18.5166344 },
    { lat: 48.0313934, lng: 18.5119407 },
    { lat: 48.0302749, lng: 18.5078476 },
    { lat: 48.0294292, lng: 18.5042644 },
    { lat: 48.0303137, lng: 18.5024596 },
    { lat: 48.0312548, lng: 18.5007017 },
    { lat: 48.0320953, lng: 18.4987951 },
    { lat: 48.0327313, lng: 18.496844 },
    { lat: 48.033364, lng: 18.4946923 },
    { lat: 48.034983, lng: 18.4904207 },
    { lat: 48.0349931, lng: 18.4903821 },
    { lat: 48.0350889, lng: 18.4886562 },
    { lat: 48.0353156, lng: 18.4861281 },
    { lat: 48.0347637, lng: 18.4841188 },
    { lat: 48.0332731, lng: 18.4812179 },
    { lat: 48.0321348, lng: 18.4783055 },
    { lat: 48.0298432, lng: 18.4786243 },
    { lat: 48.0282876, lng: 18.4786259 },
    { lat: 48.0253191, lng: 18.4789994 },
    { lat: 48.023208, lng: 18.4753564 },
    { lat: 48.0244001, lng: 18.4731467 },
    { lat: 48.0268299, lng: 18.4689879 },
    { lat: 48.0283171, lng: 18.4688102 },
    { lat: 48.0275082, lng: 18.467217 },
    { lat: 48.0272731, lng: 18.4667659 },
    { lat: 48.0254913, lng: 18.4645589 },
    { lat: 48.0239788, lng: 18.4632905 },
    { lat: 48.0234453, lng: 18.4632148 },
    { lat: 48.0229648, lng: 18.4628908 },
    { lat: 48.0227012, lng: 18.4627985 },
    { lat: 48.0222768, lng: 18.462772 },
    { lat: 48.0216338, lng: 18.4623709 },
    { lat: 48.020711, lng: 18.4622503 },
    { lat: 48.020582, lng: 18.462109 },
    { lat: 48.020329, lng: 18.462684 },
    { lat: 48.020251, lng: 18.462887 },
    { lat: 48.020029, lng: 18.463391 },
    { lat: 48.019824, lng: 18.463882 },
    { lat: 48.019681, lng: 18.464187 },
    { lat: 48.019659, lng: 18.464238 },
    { lat: 48.019093, lng: 18.465492 },
    { lat: 48.018892, lng: 18.465893 },
    { lat: 48.018603, lng: 18.466478 },
    { lat: 48.018331, lng: 18.467025 },
    { lat: 48.01818, lng: 18.467254 },
    { lat: 48.017872, lng: 18.467694 },
    { lat: 48.01753, lng: 18.468192 },
    { lat: 48.01751, lng: 18.468173 },
    { lat: 48.017411, lng: 18.468083 },
    { lat: 48.017057, lng: 18.467772 },
    { lat: 48.016865, lng: 18.467613 },
    { lat: 48.016529, lng: 18.467428 },
    { lat: 48.015746, lng: 18.466992 },
    { lat: 48.014961, lng: 18.466541 },
    { lat: 48.014558, lng: 18.466316 },
    { lat: 48.014182, lng: 18.466072 },
    { lat: 48.014151, lng: 18.466054 },
    { lat: 48.014041, lng: 18.465983 },
    { lat: 48.014017, lng: 18.465967 },
    { lat: 48.013795, lng: 18.465824 },
    { lat: 48.013771, lng: 18.465807 },
    { lat: 48.013524, lng: 18.465648 },
    { lat: 48.013513, lng: 18.46564 },
    { lat: 48.013469, lng: 18.465612 },
    { lat: 48.01291, lng: 18.465265 },
    { lat: 48.012672, lng: 18.465124 },
    { lat: 48.012606, lng: 18.465081 },
    { lat: 48.012559, lng: 18.465032 },
    { lat: 48.012532, lng: 18.465005 },
    { lat: 48.012521, lng: 18.464994 },
    { lat: 48.012516, lng: 18.464989 },
    { lat: 48.011589, lng: 18.464033 },
    { lat: 48.011563, lng: 18.464004 },
    { lat: 48.011544, lng: 18.463983 },
    { lat: 48.011521, lng: 18.463957 },
    { lat: 48.011504, lng: 18.463938 },
    { lat: 48.010978, lng: 18.463285 },
    { lat: 48.010578, lng: 18.462771 },
    { lat: 48.01042, lng: 18.462558 },
    { lat: 48.009072, lng: 18.460919 },
    { lat: 48.008759, lng: 18.460455 },
    { lat: 48.008288, lng: 18.459768 },
    { lat: 48.0079, lng: 18.459205 },
    { lat: 48.007677, lng: 18.458873 },
    { lat: 48.007343, lng: 18.45826 },
    { lat: 48.007015, lng: 18.457639 },
    { lat: 48.006754, lng: 18.457157 },
    { lat: 48.006633, lng: 18.45693 },
    { lat: 48.00611, lng: 18.45566 },
    { lat: 48.005806, lng: 18.454911 },
    { lat: 48.005569, lng: 18.454486 },
    { lat: 48.005292, lng: 18.453969 },
    { lat: 48.005273, lng: 18.453937 },
    { lat: 48.005063, lng: 18.454201 },
    { lat: 48.004875, lng: 18.45439 },
    { lat: 48.004718, lng: 18.454538 },
    { lat: 48.00454, lng: 18.454699 },
    { lat: 48.004386, lng: 18.454917 },
    { lat: 48.004225, lng: 18.455184 },
    { lat: 48.004095, lng: 18.455298 },
    { lat: 48.003725, lng: 18.455611 },
    { lat: 48.003414, lng: 18.45594 },
    { lat: 48.003257, lng: 18.456081 },
    { lat: 48.003116, lng: 18.456274 },
    { lat: 48.002939, lng: 18.456582 },
    { lat: 48.002686, lng: 18.457011 },
    { lat: 48.00249, lng: 18.457511 },
    { lat: 48.002377, lng: 18.457691 },
    { lat: 48.002274, lng: 18.457856 },
    { lat: 48.002132, lng: 18.458135 },
    { lat: 48.002031, lng: 18.458318 },
    { lat: 48.001918, lng: 18.458712 },
    { lat: 48.001689, lng: 18.459145 },
    { lat: 48.001587, lng: 18.459345 },
    { lat: 48.001487, lng: 18.459591 },
    { lat: 48.001368, lng: 18.459899 },
    { lat: 48.001302, lng: 18.460054 },
    { lat: 48.001039, lng: 18.460636 },
    { lat: 48.000892, lng: 18.460893 },
    { lat: 48.000791, lng: 18.46107 },
    { lat: 48.000706, lng: 18.461216 },
    { lat: 48.000583, lng: 18.461332 },
    { lat: 48.000313, lng: 18.46167 },
    { lat: 48.000165, lng: 18.461929 },
    { lat: 48.000015, lng: 18.462181 },
    { lat: 47.999899, lng: 18.462299 },
    { lat: 47.999629, lng: 18.462542 },
    { lat: 47.999463, lng: 18.462697 },
    { lat: 47.999017, lng: 18.463074 },
    { lat: 47.99883, lng: 18.463252 },
    { lat: 47.99844, lng: 18.463775 },
    { lat: 47.998272, lng: 18.463986 },
    { lat: 47.998147, lng: 18.464109 },
    { lat: 47.997994, lng: 18.464199 },
    { lat: 47.997927, lng: 18.464242 },
    { lat: 47.997888, lng: 18.464284 },
    { lat: 47.997744, lng: 18.464457 },
    { lat: 47.997605, lng: 18.464616 },
    { lat: 47.997436, lng: 18.46487 },
    { lat: 47.997275, lng: 18.465027 },
    { lat: 47.99701, lng: 18.465191 },
    { lat: 47.996805, lng: 18.465346 },
    { lat: 47.996572, lng: 18.465616 },
  ],
  Iňa: [
    { lat: 48.1961565, lng: 18.4221217 },
    { lat: 48.1963721, lng: 18.420454 },
    { lat: 48.1963887, lng: 18.4200679 },
    { lat: 48.1962752, lng: 18.4194113 },
    { lat: 48.1951687, lng: 18.4182523 },
    { lat: 48.1949776, lng: 18.4181837 },
    { lat: 48.1948748, lng: 18.4180626 },
    { lat: 48.1944099, lng: 18.4179803 },
    { lat: 48.1941909, lng: 18.4180447 },
    { lat: 48.1940694, lng: 18.4178445 },
    { lat: 48.1937239, lng: 18.4175973 },
    { lat: 48.1928549, lng: 18.4164283 },
    { lat: 48.1925245, lng: 18.4160689 },
    { lat: 48.1922191, lng: 18.4153927 },
    { lat: 48.1921082, lng: 18.4149831 },
    { lat: 48.1920911, lng: 18.4146423 },
    { lat: 48.1925393, lng: 18.4119585 },
    { lat: 48.1925375, lng: 18.4114224 },
    { lat: 48.1926605, lng: 18.4100414 },
    { lat: 48.1926004, lng: 18.4098552 },
    { lat: 48.1923574, lng: 18.4095651 },
    { lat: 48.1921488, lng: 18.4091858 },
    { lat: 48.1914999, lng: 18.409161 },
    { lat: 48.1903292, lng: 18.4093507 },
    { lat: 48.1887687, lng: 18.4097094 },
    { lat: 48.1883298, lng: 18.4099887 },
    { lat: 48.1880888, lng: 18.4102969 },
    { lat: 48.1875323, lng: 18.410793 },
    { lat: 48.1869773, lng: 18.4109717 },
    { lat: 48.186459, lng: 18.4110446 },
    { lat: 48.1858846, lng: 18.4114414 },
    { lat: 48.1856339, lng: 18.4115408 },
    { lat: 48.1850735, lng: 18.4127865 },
    { lat: 48.1848146, lng: 18.4128582 },
    { lat: 48.1845227, lng: 18.412581 },
    { lat: 48.1835351, lng: 18.411272 },
    { lat: 48.1829104, lng: 18.4106358 },
    { lat: 48.182741, lng: 18.4106501 },
    { lat: 48.182421, lng: 18.411001 },
    { lat: 48.1821405, lng: 18.410885 },
    { lat: 48.1815529, lng: 18.4101487 },
    { lat: 48.1812495, lng: 18.4098482 },
    { lat: 48.1807636, lng: 18.4091751 },
    { lat: 48.1807666, lng: 18.4090266 },
    { lat: 48.1791838, lng: 18.4067835 },
    { lat: 48.1792805, lng: 18.4066576 },
    { lat: 48.1784292, lng: 18.405683 },
    { lat: 48.1772327, lng: 18.4046718 },
    { lat: 48.1762421, lng: 18.4038266 },
    { lat: 48.1761418, lng: 18.404069 },
    { lat: 48.172153, lng: 18.4129379 },
    { lat: 48.17057, lng: 18.4126559 },
    { lat: 48.1696623, lng: 18.4125622 },
    { lat: 48.169056, lng: 18.4137979 },
    { lat: 48.1685917, lng: 18.4152325 },
    { lat: 48.1677438, lng: 18.4170452 },
    { lat: 48.1675927, lng: 18.4168638 },
    { lat: 48.1671715, lng: 18.418325 },
    { lat: 48.1669942, lng: 18.4194647 },
    { lat: 48.1671426, lng: 18.4229323 },
    { lat: 48.1670238, lng: 18.4238463 },
    { lat: 48.1667417, lng: 18.4245906 },
    { lat: 48.1671003, lng: 18.4250276 },
    { lat: 48.1675365, lng: 18.4254037 },
    { lat: 48.1671928, lng: 18.426073 },
    { lat: 48.1654504, lng: 18.430225 },
    { lat: 48.1654783, lng: 18.4302593 },
    { lat: 48.1663501, lng: 18.4312396 },
    { lat: 48.1670645, lng: 18.431872 },
    { lat: 48.1673062, lng: 18.4319544 },
    { lat: 48.16771, lng: 18.4317144 },
    { lat: 48.1684531, lng: 18.4319676 },
    { lat: 48.1686588, lng: 18.4320959 },
    { lat: 48.1690942, lng: 18.4324778 },
    { lat: 48.1694517, lng: 18.4334611 },
    { lat: 48.1698561, lng: 18.4339737 },
    { lat: 48.1705389, lng: 18.4344003 },
    { lat: 48.1716032, lng: 18.4351936 },
    { lat: 48.1719438, lng: 18.43507 },
    { lat: 48.1726408, lng: 18.4355486 },
    { lat: 48.1727301, lng: 18.4357339 },
    { lat: 48.1727621, lng: 18.4360854 },
    { lat: 48.1729638, lng: 18.4365196 },
    { lat: 48.1729836, lng: 18.4366532 },
    { lat: 48.1729018, lng: 18.4370844 },
    { lat: 48.172861, lng: 18.4378497 },
    { lat: 48.1730469, lng: 18.4398986 },
    { lat: 48.1731545, lng: 18.4405906 },
    { lat: 48.1735481, lng: 18.4403886 },
    { lat: 48.1747265, lng: 18.4399941 },
    { lat: 48.1756578, lng: 18.4394594 },
    { lat: 48.1767524, lng: 18.4386221 },
    { lat: 48.1777851, lng: 18.4374802 },
    { lat: 48.1796981, lng: 18.4358631 },
    { lat: 48.1809201, lng: 18.4353031 },
    { lat: 48.1834963, lng: 18.4351157 },
    { lat: 48.184622, lng: 18.4353505 },
    { lat: 48.1853378, lng: 18.4356249 },
    { lat: 48.1858506, lng: 18.4358954 },
    { lat: 48.1867462, lng: 18.4362135 },
    { lat: 48.1869432, lng: 18.4363284 },
    { lat: 48.1870737, lng: 18.4364846 },
    { lat: 48.1872894, lng: 18.4363056 },
    { lat: 48.1878046, lng: 18.4361371 },
    { lat: 48.1888058, lng: 18.4359487 },
    { lat: 48.1894537, lng: 18.4356692 },
    { lat: 48.1904589, lng: 18.4350656 },
    { lat: 48.1908858, lng: 18.4346504 },
    { lat: 48.1911214, lng: 18.4343087 },
    { lat: 48.1913961, lng: 18.4337934 },
    { lat: 48.1914734, lng: 18.4335228 },
    { lat: 48.1915502, lng: 18.4323157 },
    { lat: 48.1916564, lng: 18.431975 },
    { lat: 48.1929371, lng: 18.4290427 },
    { lat: 48.1937509, lng: 18.4269581 },
    { lat: 48.1946972, lng: 18.4251464 },
    { lat: 48.1960963, lng: 18.4228552 },
    { lat: 48.196233, lng: 18.4220778 },
    { lat: 48.1961493, lng: 18.4221746 },
    { lat: 48.1961565, lng: 18.4221217 },
  ],
  Žemliare: [
    { lat: 48.1637033, lng: 18.6118503 },
    { lat: 48.1636769, lng: 18.6118196 },
    { lat: 48.1606573, lng: 18.608528 },
    { lat: 48.1583976, lng: 18.6058729 },
    { lat: 48.156553, lng: 18.6031562 },
    { lat: 48.1536274, lng: 18.5982885 },
    { lat: 48.1526999, lng: 18.5970202 },
    { lat: 48.1524042, lng: 18.5973883 },
    { lat: 48.1515716, lng: 18.5987484 },
    { lat: 48.1509413, lng: 18.5983615 },
    { lat: 48.1503209, lng: 18.5978463 },
    { lat: 48.1498387, lng: 18.5971757 },
    { lat: 48.1493857, lng: 18.5962134 },
    { lat: 48.1491494, lng: 18.5947997 },
    { lat: 48.1491121, lng: 18.5940742 },
    { lat: 48.1491717, lng: 18.5929985 },
    { lat: 48.149392, lng: 18.5910459 },
    { lat: 48.1492256, lng: 18.5901461 },
    { lat: 48.1487724, lng: 18.5906462 },
    { lat: 48.1479658, lng: 18.5912653 },
    { lat: 48.1473653, lng: 18.5915223 },
    { lat: 48.1462711, lng: 18.5914828 },
    { lat: 48.1457264, lng: 18.5913819 },
    { lat: 48.1456643, lng: 18.5911914 },
    { lat: 48.1448877, lng: 18.5909557 },
    { lat: 48.1439467, lng: 18.5908484 },
    { lat: 48.143421, lng: 18.5909501 },
    { lat: 48.1434515, lng: 18.5912405 },
    { lat: 48.1430533, lng: 18.591424 },
    { lat: 48.1424777, lng: 18.5919542 },
    { lat: 48.1423237, lng: 18.5922099 },
    { lat: 48.1415376, lng: 18.5925824 },
    { lat: 48.1408929, lng: 18.5929871 },
    { lat: 48.1402043, lng: 18.5931411 },
    { lat: 48.1401378, lng: 18.59308 },
    { lat: 48.1393133, lng: 18.5936181 },
    { lat: 48.1393297, lng: 18.5937316 },
    { lat: 48.1384855, lng: 18.5949615 },
    { lat: 48.137559, lng: 18.596748 },
    { lat: 48.1369749, lng: 18.5973496 },
    { lat: 48.1366929, lng: 18.5978237 },
    { lat: 48.1364361, lng: 18.5983448 },
    { lat: 48.1358916, lng: 18.5996916 },
    { lat: 48.1355416, lng: 18.6002258 },
    { lat: 48.1348925, lng: 18.6005881 },
    { lat: 48.1349275, lng: 18.6010203 },
    { lat: 48.1349834, lng: 18.6021882 },
    { lat: 48.1351792, lng: 18.6028003 },
    { lat: 48.1355212, lng: 18.6032802 },
    { lat: 48.1359637, lng: 18.6034314 },
    { lat: 48.1368494, lng: 18.6034979 },
    { lat: 48.1374118, lng: 18.6031568 },
    { lat: 48.1377788, lng: 18.602754 },
    { lat: 48.1382708, lng: 18.6019118 },
    { lat: 48.1381886, lng: 18.6013985 },
    { lat: 48.1384248, lng: 18.6012175 },
    { lat: 48.138714, lng: 18.6013931 },
    { lat: 48.1390947, lng: 18.601815 },
    { lat: 48.1393218, lng: 18.6021747 },
    { lat: 48.1397676, lng: 18.6025511 },
    { lat: 48.1401041, lng: 18.6030949 },
    { lat: 48.1403944, lng: 18.6037411 },
    { lat: 48.1404028, lng: 18.6040427 },
    { lat: 48.1401007, lng: 18.6059046 },
    { lat: 48.1400627, lng: 18.6066543 },
    { lat: 48.1405242, lng: 18.6078348 },
    { lat: 48.1404844, lng: 18.6079979 },
    { lat: 48.1409607, lng: 18.6086437 },
    { lat: 48.1438108, lng: 18.6137696 },
    { lat: 48.146157, lng: 18.6174771 },
    { lat: 48.1473402, lng: 18.6196731 },
    { lat: 48.1480817, lng: 18.6207809 },
    { lat: 48.1494284, lng: 18.6229127 },
    { lat: 48.1496652, lng: 18.6233459 },
    { lat: 48.1496111, lng: 18.6238236 },
    { lat: 48.1506404, lng: 18.6254742 },
    { lat: 48.1513991, lng: 18.626834 },
    { lat: 48.1521343, lng: 18.6268539 },
    { lat: 48.1530487, lng: 18.6270752 },
    { lat: 48.1529061, lng: 18.6267355 },
    { lat: 48.1528754, lng: 18.6264178 },
    { lat: 48.1529159, lng: 18.6261667 },
    { lat: 48.1531559, lng: 18.6257267 },
    { lat: 48.1532876, lng: 18.6246846 },
    { lat: 48.1534512, lng: 18.6243154 },
    { lat: 48.1538417, lng: 18.624008 },
    { lat: 48.154006, lng: 18.6239986 },
    { lat: 48.1543891, lng: 18.6242424 },
    { lat: 48.1548077, lng: 18.6240372 },
    { lat: 48.1549405, lng: 18.6233026 },
    { lat: 48.1551486, lng: 18.6228788 },
    { lat: 48.1556247, lng: 18.6224685 },
    { lat: 48.1558432, lng: 18.6219219 },
    { lat: 48.1560782, lng: 18.6215543 },
    { lat: 48.1563696, lng: 18.6213595 },
    { lat: 48.1568818, lng: 18.6207995 },
    { lat: 48.1572648, lng: 18.6206873 },
    { lat: 48.1574324, lng: 18.6201402 },
    { lat: 48.1576324, lng: 18.6200198 },
    { lat: 48.1583517, lng: 18.6202213 },
    { lat: 48.1586204, lng: 18.6206419 },
    { lat: 48.1591943, lng: 18.6211981 },
    { lat: 48.1596439, lng: 18.6211477 },
    { lat: 48.1600371, lng: 18.6213888 },
    { lat: 48.1622867, lng: 18.6213355 },
    { lat: 48.1626399, lng: 18.6211332 },
    { lat: 48.1630094, lng: 18.6210392 },
    { lat: 48.16325, lng: 18.6205687 },
    { lat: 48.1635647, lng: 18.6201548 },
    { lat: 48.1637404, lng: 18.6199869 },
    { lat: 48.1641201, lng: 18.6198548 },
    { lat: 48.1643553, lng: 18.6198852 },
    { lat: 48.1651015, lng: 18.6203934 },
    { lat: 48.1655188, lng: 18.6210344 },
    { lat: 48.1657282, lng: 18.6209464 },
    { lat: 48.1660844, lng: 18.6204042 },
    { lat: 48.1660732, lng: 18.6200943 },
    { lat: 48.1659698, lng: 18.6196674 },
    { lat: 48.1654028, lng: 18.618304 },
    { lat: 48.1653617, lng: 18.6179836 },
    { lat: 48.1654929, lng: 18.6174497 },
    { lat: 48.1654719, lng: 18.6172106 },
    { lat: 48.1654052, lng: 18.617023 },
    { lat: 48.1652716, lng: 18.6169103 },
    { lat: 48.1648127, lng: 18.6168287 },
    { lat: 48.1646046, lng: 18.6166109 },
    { lat: 48.1645462, lng: 18.6163668 },
    { lat: 48.1646303, lng: 18.6158418 },
    { lat: 48.1646369, lng: 18.615381 },
    { lat: 48.1644378, lng: 18.6145421 },
    { lat: 48.1642787, lng: 18.6140644 },
    { lat: 48.1641394, lng: 18.6138061 },
    { lat: 48.1637492, lng: 18.6132466 },
    { lat: 48.1635996, lng: 18.6129257 },
    { lat: 48.1633942, lng: 18.6127352 },
    { lat: 48.1633947, lng: 18.6125401 },
    { lat: 48.1637033, lng: 18.6118503 },
  ],
  Drženice: [
    { lat: 48.2666973, lng: 18.6809012 },
    { lat: 48.2666452, lng: 18.6808944 },
    { lat: 48.2665198, lng: 18.6811043 },
    { lat: 48.2661253, lng: 18.681463 },
    { lat: 48.2660282, lng: 18.6818192 },
    { lat: 48.2660085, lng: 18.6820824 },
    { lat: 48.2657105, lng: 18.6819513 },
    { lat: 48.2648661, lng: 18.6820839 },
    { lat: 48.2645708, lng: 18.6822357 },
    { lat: 48.2643736, lng: 18.6825947 },
    { lat: 48.264269, lng: 18.6825386 },
    { lat: 48.2642387, lng: 18.6822648 },
    { lat: 48.2637071, lng: 18.6824708 },
    { lat: 48.2634876, lng: 18.6823706 },
    { lat: 48.2632058, lng: 18.6820612 },
    { lat: 48.258331, lng: 18.6859184 },
    { lat: 48.2573798, lng: 18.6882203 },
    { lat: 48.2567572, lng: 18.6893316 },
    { lat: 48.2556317, lng: 18.6910998 },
    { lat: 48.25502, lng: 18.6924143 },
    { lat: 48.2542762, lng: 18.6919429 },
    { lat: 48.2537543, lng: 18.6929326 },
    { lat: 48.2541535, lng: 18.693541 },
    { lat: 48.2541766, lng: 18.6936808 },
    { lat: 48.2535352, lng: 18.6942761 },
    { lat: 48.2533722, lng: 18.6947647 },
    { lat: 48.2531041, lng: 18.6951285 },
    { lat: 48.2529348, lng: 18.6956122 },
    { lat: 48.2527824, lng: 18.6957972 },
    { lat: 48.2528657, lng: 18.6959015 },
    { lat: 48.2515182, lng: 18.6987227 },
    { lat: 48.2513484, lng: 18.700034 },
    { lat: 48.2512567, lng: 18.7000514 },
    { lat: 48.2511859, lng: 18.7003094 },
    { lat: 48.2510421, lng: 18.7004658 },
    { lat: 48.2507583, lng: 18.7004935 },
    { lat: 48.2503779, lng: 18.7004016 },
    { lat: 48.2496205, lng: 18.7000783 },
    { lat: 48.2492444, lng: 18.7000446 },
    { lat: 48.2489163, lng: 18.7001318 },
    { lat: 48.2486403, lng: 18.700349 },
    { lat: 48.2484699, lng: 18.7007453 },
    { lat: 48.2484396, lng: 18.7009821 },
    { lat: 48.2485129, lng: 18.7022833 },
    { lat: 48.2485888, lng: 18.7027126 },
    { lat: 48.2489954, lng: 18.703398 },
    { lat: 48.2496989, lng: 18.7038949 },
    { lat: 48.2508736, lng: 18.7038436 },
    { lat: 48.2511439, lng: 18.7039212 },
    { lat: 48.2514551, lng: 18.7041715 },
    { lat: 48.2517485, lng: 18.7047201 },
    { lat: 48.2522303, lng: 18.7062621 },
    { lat: 48.2525002, lng: 18.7064392 },
    { lat: 48.2527812, lng: 18.70642 },
    { lat: 48.2529461, lng: 18.7063404 },
    { lat: 48.2555363, lng: 18.704689 },
    { lat: 48.2558908, lng: 18.7046758 },
    { lat: 48.2562039, lng: 18.7047712 },
    { lat: 48.256515, lng: 18.7054215 },
    { lat: 48.2565482, lng: 18.7063154 },
    { lat: 48.2567079, lng: 18.7070419 },
    { lat: 48.2570885, lng: 18.7074941 },
    { lat: 48.2572473, lng: 18.7075943 },
    { lat: 48.2580063, lng: 18.7074692 },
    { lat: 48.258508, lng: 18.7076751 },
    { lat: 48.2587751, lng: 18.708095 },
    { lat: 48.2588379, lng: 18.7083196 },
    { lat: 48.2587987, lng: 18.7091465 },
    { lat: 48.2588121, lng: 18.7092673 },
    { lat: 48.2589017, lng: 18.7093829 },
    { lat: 48.2593389, lng: 18.709312 },
    { lat: 48.2597595, lng: 18.7090807 },
    { lat: 48.259949, lng: 18.710326 },
    { lat: 48.2601676, lng: 18.711225 },
    { lat: 48.260281, lng: 18.7119532 },
    { lat: 48.2632701, lng: 18.7113916 },
    { lat: 48.2636534, lng: 18.7109662 },
    { lat: 48.2644144, lng: 18.7123537 },
    { lat: 48.2648836, lng: 18.7134049 },
    { lat: 48.2650584, lng: 18.7138282 },
    { lat: 48.2651198, lng: 18.7143843 },
    { lat: 48.2669288, lng: 18.7120657 },
    { lat: 48.2680182, lng: 18.7130758 },
    { lat: 48.2710085, lng: 18.715258 },
    { lat: 48.2710571, lng: 18.7150886 },
    { lat: 48.2728022, lng: 18.7159582 },
    { lat: 48.2742583, lng: 18.7170775 },
    { lat: 48.2748635, lng: 18.717514 },
    { lat: 48.2746773, lng: 18.7178908 },
    { lat: 48.2768501, lng: 18.7194645 },
    { lat: 48.277886, lng: 18.7204021 },
    { lat: 48.2795861, lng: 18.7221665 },
    { lat: 48.2800014, lng: 18.7225529 },
    { lat: 48.2811209, lng: 18.7236194 },
    { lat: 48.2814979, lng: 18.7242179 },
    { lat: 48.2829303, lng: 18.7226989 },
    { lat: 48.2844722, lng: 18.7213491 },
    { lat: 48.2851594, lng: 18.720976 },
    { lat: 48.2855616, lng: 18.7205025 },
    { lat: 48.2861152, lng: 18.7196992 },
    { lat: 48.2861364, lng: 18.7196719 },
    { lat: 48.2861543, lng: 18.7196481 },
    { lat: 48.2860609, lng: 18.7192709 },
    { lat: 48.2862172, lng: 18.7190729 },
    { lat: 48.2868483, lng: 18.7185571 },
    { lat: 48.2871094, lng: 18.7184645 },
    { lat: 48.2876319, lng: 18.7178795 },
    { lat: 48.2885388, lng: 18.7164664 },
    { lat: 48.2891434, lng: 18.7151586 },
    { lat: 48.2902609, lng: 18.7125611 },
    { lat: 48.291037, lng: 18.7102449 },
    { lat: 48.2912659, lng: 18.7096957 },
    { lat: 48.2930622, lng: 18.7058114 },
    { lat: 48.2932175, lng: 18.7037814 },
    { lat: 48.2934493, lng: 18.7038382 },
    { lat: 48.293627, lng: 18.7036791 },
    { lat: 48.293892, lng: 18.7035965 },
    { lat: 48.2940375, lng: 18.7033648 },
    { lat: 48.2940987, lng: 18.7033478 },
    { lat: 48.2941833, lng: 18.70362 },
    { lat: 48.2942572, lng: 18.703717 },
    { lat: 48.2943783, lng: 18.7037669 },
    { lat: 48.2947758, lng: 18.7036907 },
    { lat: 48.2947932, lng: 18.7031736 },
    { lat: 48.294949, lng: 18.7030508 },
    { lat: 48.2951849, lng: 18.7031396 },
    { lat: 48.2953963, lng: 18.7033806 },
    { lat: 48.2960045, lng: 18.7034076 },
    { lat: 48.2958244, lng: 18.7020841 },
    { lat: 48.2957451, lng: 18.701155 },
    { lat: 48.2974083, lng: 18.6980739 },
    { lat: 48.2999422, lng: 18.6929762 },
    { lat: 48.2989815, lng: 18.691571 },
    { lat: 48.2982771, lng: 18.6924847 },
    { lat: 48.2824277, lng: 18.6710053 },
    { lat: 48.2824603, lng: 18.6709444 },
    { lat: 48.2821714, lng: 18.670815 },
    { lat: 48.2815327, lng: 18.6703037 },
    { lat: 48.2809322, lng: 18.6699588 },
    { lat: 48.2798869, lng: 18.6695031 },
    { lat: 48.2790584, lng: 18.6688434 },
    { lat: 48.2789397, lng: 18.6688386 },
    { lat: 48.2787599, lng: 18.6687042 },
    { lat: 48.2785374, lng: 18.6683239 },
    { lat: 48.2785208, lng: 18.668328 },
    { lat: 48.2768582, lng: 18.6687454 },
    { lat: 48.2766705, lng: 18.6676886 },
    { lat: 48.2758384, lng: 18.6679446 },
    { lat: 48.275715, lng: 18.6672688 },
    { lat: 48.2746807, lng: 18.6674951 },
    { lat: 48.2745363, lng: 18.6676015 },
    { lat: 48.2733925, lng: 18.6677883 },
    { lat: 48.2724826, lng: 18.6683359 },
    { lat: 48.272361, lng: 18.6682766 },
    { lat: 48.2722822, lng: 18.668386 },
    { lat: 48.2722558, lng: 18.6685446 },
    { lat: 48.2721235, lng: 18.6685358 },
    { lat: 48.2721033, lng: 18.6684561 },
    { lat: 48.2719463, lng: 18.6687042 },
    { lat: 48.2714156, lng: 18.6691326 },
    { lat: 48.2713739, lng: 18.6690693 },
    { lat: 48.271057, lng: 18.6699693 },
    { lat: 48.2707734, lng: 18.6702543 },
    { lat: 48.2706809, lng: 18.6704634 },
    { lat: 48.2706608, lng: 18.6706566 },
    { lat: 48.2705101, lng: 18.6705396 },
    { lat: 48.2704336, lng: 18.6706076 },
    { lat: 48.2704585, lng: 18.6709646 },
    { lat: 48.2701942, lng: 18.6716253 },
    { lat: 48.2700049, lng: 18.6718408 },
    { lat: 48.2702959, lng: 18.6720258 },
    { lat: 48.2702891, lng: 18.6722776 },
    { lat: 48.2703622, lng: 18.6724635 },
    { lat: 48.270344, lng: 18.6726289 },
    { lat: 48.2702585, lng: 18.6727352 },
    { lat: 48.2700207, lng: 18.6724669 },
    { lat: 48.2699016, lng: 18.6726427 },
    { lat: 48.2697696, lng: 18.6726626 },
    { lat: 48.2697174, lng: 18.6727818 },
    { lat: 48.2699484, lng: 18.6728845 },
    { lat: 48.2699571, lng: 18.6730035 },
    { lat: 48.2698467, lng: 18.6731959 },
    { lat: 48.2698704, lng: 18.6733025 },
    { lat: 48.2696838, lng: 18.673501 },
    { lat: 48.2695865, lng: 18.6734115 },
    { lat: 48.2695116, lng: 18.6735076 },
    { lat: 48.2695386, lng: 18.6738817 },
    { lat: 48.2696089, lng: 18.6740771 },
    { lat: 48.2695142, lng: 18.6742893 },
    { lat: 48.2695733, lng: 18.6745477 },
    { lat: 48.2694852, lng: 18.6749223 },
    { lat: 48.2694968, lng: 18.6751375 },
    { lat: 48.2694109, lng: 18.6752406 },
    { lat: 48.2692786, lng: 18.6752555 },
    { lat: 48.2692202, lng: 18.6755929 },
    { lat: 48.2691054, lng: 18.675765 },
    { lat: 48.2690098, lng: 18.6757942 },
    { lat: 48.2688493, lng: 18.6762678 },
    { lat: 48.2686018, lng: 18.676509 },
    { lat: 48.2685638, lng: 18.676708 },
    { lat: 48.2683098, lng: 18.6772456 },
    { lat: 48.2683938, lng: 18.6773615 },
    { lat: 48.2685328, lng: 18.6774275 },
    { lat: 48.2685701, lng: 18.677537 },
    { lat: 48.2685485, lng: 18.6776565 },
    { lat: 48.2684334, lng: 18.6778024 },
    { lat: 48.2683057, lng: 18.6775073 },
    { lat: 48.2682267, lng: 18.6774843 },
    { lat: 48.2681669, lng: 18.6775673 },
    { lat: 48.2681959, lng: 18.6780445 },
    { lat: 48.2680942, lng: 18.6784384 },
    { lat: 48.268164, lng: 18.6785811 },
    { lat: 48.268154, lng: 18.6787332 },
    { lat: 48.2679348, lng: 18.6789324 },
    { lat: 48.2678137, lng: 18.6789484 },
    { lat: 48.2673797, lng: 18.6798532 },
    { lat: 48.2666973, lng: 18.6809012 },
  ],
  Turá: [
    { lat: 48.1664365, lng: 18.5734891 },
    { lat: 48.166131, lng: 18.5733491 },
    { lat: 48.1640531, lng: 18.5724226 },
    { lat: 48.1629421, lng: 18.5703064 },
    { lat: 48.1627962, lng: 18.5694954 },
    { lat: 48.1623569, lng: 18.5680286 },
    { lat: 48.1621454, lng: 18.5674818 },
    { lat: 48.1606198, lng: 18.5657067 },
    { lat: 48.1606814, lng: 18.5654299 },
    { lat: 48.156581, lng: 18.5599464 },
    { lat: 48.1537251, lng: 18.5558445 },
    { lat: 48.1502975, lng: 18.5514457 },
    { lat: 48.1495745, lng: 18.5506487 },
    { lat: 48.149423, lng: 18.5510319 },
    { lat: 48.1482207, lng: 18.5536571 },
    { lat: 48.1479647, lng: 18.5540707 },
    { lat: 48.147073, lng: 18.5552279 },
    { lat: 48.1458888, lng: 18.5570542 },
    { lat: 48.1453816, lng: 18.5577187 },
    { lat: 48.1445696, lng: 18.5592001 },
    { lat: 48.1417601, lng: 18.5553065 },
    { lat: 48.1414558, lng: 18.5548198 },
    { lat: 48.1380706, lng: 18.5608689 },
    { lat: 48.1367588, lng: 18.5625335 },
    { lat: 48.1357477, lng: 18.5640453 },
    { lat: 48.1352556, lng: 18.5649026 },
    { lat: 48.1338898, lng: 18.5676174 },
    { lat: 48.1328725, lng: 18.5693865 },
    { lat: 48.1327717, lng: 18.5693148 },
    { lat: 48.1304465, lng: 18.5661468 },
    { lat: 48.1284114, lng: 18.5630421 },
    { lat: 48.1283928, lng: 18.5630963 },
    { lat: 48.1283686, lng: 18.5630632 },
    { lat: 48.1283428, lng: 18.5631493 },
    { lat: 48.1257935, lng: 18.568428 },
    { lat: 48.1241196, lng: 18.571343 },
    { lat: 48.1221212, lng: 18.5752338 },
    { lat: 48.1221519, lng: 18.5752798 },
    { lat: 48.1225146, lng: 18.5758042 },
    { lat: 48.1246614, lng: 18.5781335 },
    { lat: 48.1321645, lng: 18.5872738 },
    { lat: 48.1341005, lng: 18.5898536 },
    { lat: 48.1348256, lng: 18.5906574 },
    { lat: 48.1355333, lng: 18.5913044 },
    { lat: 48.1358492, lng: 18.591822 },
    { lat: 48.1357916, lng: 18.5919438 },
    { lat: 48.1356461, lng: 18.5920664 },
    { lat: 48.1352798, lng: 18.5920938 },
    { lat: 48.1350764, lng: 18.5921907 },
    { lat: 48.1347714, lng: 18.5924475 },
    { lat: 48.1343557, lng: 18.5929933 },
    { lat: 48.1340678, lng: 18.5934857 },
    { lat: 48.1339778, lng: 18.5937375 },
    { lat: 48.1347171, lng: 18.5956003 },
    { lat: 48.1346063, lng: 18.5971675 },
    { lat: 48.1348925, lng: 18.6005881 },
    { lat: 48.1355416, lng: 18.6002258 },
    { lat: 48.1358916, lng: 18.5996916 },
    { lat: 48.1364361, lng: 18.5983448 },
    { lat: 48.1366929, lng: 18.5978237 },
    { lat: 48.1369749, lng: 18.5973496 },
    { lat: 48.137559, lng: 18.596748 },
    { lat: 48.1384855, lng: 18.5949615 },
    { lat: 48.1393297, lng: 18.5937316 },
    { lat: 48.1393133, lng: 18.5936181 },
    { lat: 48.1401378, lng: 18.59308 },
    { lat: 48.1402043, lng: 18.5931411 },
    { lat: 48.1408929, lng: 18.5929871 },
    { lat: 48.1415376, lng: 18.5925824 },
    { lat: 48.1423237, lng: 18.5922099 },
    { lat: 48.1424777, lng: 18.5919542 },
    { lat: 48.1430533, lng: 18.591424 },
    { lat: 48.1434515, lng: 18.5912405 },
    { lat: 48.143421, lng: 18.5909501 },
    { lat: 48.1439467, lng: 18.5908484 },
    { lat: 48.1448877, lng: 18.5909557 },
    { lat: 48.1456643, lng: 18.5911914 },
    { lat: 48.1457264, lng: 18.5913819 },
    { lat: 48.1462711, lng: 18.5914828 },
    { lat: 48.1473653, lng: 18.5915223 },
    { lat: 48.1479658, lng: 18.5912653 },
    { lat: 48.1487724, lng: 18.5906462 },
    { lat: 48.1492256, lng: 18.5901461 },
    { lat: 48.1494548, lng: 18.5900069 },
    { lat: 48.149521, lng: 18.5902054 },
    { lat: 48.1501684, lng: 18.5898784 },
    { lat: 48.150862, lng: 18.5891744 },
    { lat: 48.1520782, lng: 18.5877175 },
    { lat: 48.1527714, lng: 18.586429 },
    { lat: 48.1535236, lng: 18.5859085 },
    { lat: 48.1540568, lng: 18.5857999 },
    { lat: 48.154835, lng: 18.5859008 },
    { lat: 48.1555462, lng: 18.5870054 },
    { lat: 48.1557608, lng: 18.5881629 },
    { lat: 48.1553693, lng: 18.5892536 },
    { lat: 48.1548555, lng: 18.5893284 },
    { lat: 48.1541029, lng: 18.5898393 },
    { lat: 48.1531885, lng: 18.5907744 },
    { lat: 48.1527429, lng: 18.5924847 },
    { lat: 48.1528413, lng: 18.5933968 },
    { lat: 48.153193, lng: 18.5943613 },
    { lat: 48.1535257, lng: 18.5949402 },
    { lat: 48.154436, lng: 18.5956405 },
    { lat: 48.1548423, lng: 18.5962038 },
    { lat: 48.1552179, lng: 18.5965762 },
    { lat: 48.1553364, lng: 18.596928 },
    { lat: 48.1555674, lng: 18.5969415 },
    { lat: 48.1563357, lng: 18.5966591 },
    { lat: 48.1567446, lng: 18.5963595 },
    { lat: 48.157342, lng: 18.5956267 },
    { lat: 48.157644, lng: 18.5949645 },
    { lat: 48.1579156, lng: 18.5941344 },
    { lat: 48.1582245, lng: 18.5934707 },
    { lat: 48.1588066, lng: 18.5924197 },
    { lat: 48.1592385, lng: 18.5917557 },
    { lat: 48.1596371, lng: 18.5909404 },
    { lat: 48.1606043, lng: 18.5892586 },
    { lat: 48.160881, lng: 18.5886267 },
    { lat: 48.1612465, lng: 18.5875734 },
    { lat: 48.1613689, lng: 18.5867006 },
    { lat: 48.1613367, lng: 18.5860249 },
    { lat: 48.1611465, lng: 18.5858287 },
    { lat: 48.1610951, lng: 18.5853853 },
    { lat: 48.159556, lng: 18.5847358 },
    { lat: 48.1589504, lng: 18.5840362 },
    { lat: 48.1582049, lng: 18.58297 },
    { lat: 48.158024, lng: 18.5825272 },
    { lat: 48.1578449, lng: 18.5815826 },
    { lat: 48.1577857, lng: 18.5809776 },
    { lat: 48.1577769, lng: 18.5802807 },
    { lat: 48.1578811, lng: 18.5797118 },
    { lat: 48.1582603, lng: 18.5791172 },
    { lat: 48.1589092, lng: 18.5787943 },
    { lat: 48.1597295, lng: 18.5786162 },
    { lat: 48.1614934, lng: 18.5790232 },
    { lat: 48.1619667, lng: 18.5791974 },
    { lat: 48.1624721, lng: 18.5792637 },
    { lat: 48.1637491, lng: 18.5791626 },
    { lat: 48.164144, lng: 18.5790397 },
    { lat: 48.1650028, lng: 18.5785717 },
    { lat: 48.1655442, lng: 18.5779959 },
    { lat: 48.1657241, lng: 18.5776983 },
    { lat: 48.1660476, lng: 18.5768686 },
    { lat: 48.1662302, lng: 18.5762103 },
    { lat: 48.166427, lng: 18.5750064 },
    { lat: 48.1664854, lng: 18.5740968 },
    { lat: 48.1664365, lng: 18.5734891 },
  ],
  Slatina: [
    { lat: 48.162087, lng: 18.887396 },
    { lat: 48.161552, lng: 18.887481 },
    { lat: 48.161076, lng: 18.887547 },
    { lat: 48.16075, lng: 18.887558 },
    { lat: 48.160448, lng: 18.887519 },
    { lat: 48.160362, lng: 18.887499 },
    { lat: 48.160318, lng: 18.887489 },
    { lat: 48.1602, lng: 18.887465 },
    { lat: 48.160065, lng: 18.887393 },
    { lat: 48.159897, lng: 18.887286 },
    { lat: 48.159775, lng: 18.887196 },
    { lat: 48.159594, lng: 18.887032 },
    { lat: 48.15943, lng: 18.88686 },
    { lat: 48.159235, lng: 18.886641 },
    { lat: 48.159085, lng: 18.886499 },
    { lat: 48.158948, lng: 18.88637 },
    { lat: 48.159091, lng: 18.88628 },
    { lat: 48.159129, lng: 18.886255 },
    { lat: 48.159186, lng: 18.88622 },
    { lat: 48.1590771, lng: 18.8857977 },
    { lat: 48.1588729, lng: 18.8839101 },
    { lat: 48.1588397, lng: 18.8833591 },
    { lat: 48.1588604, lng: 18.8826765 },
    { lat: 48.1582297, lng: 18.8835781 },
    { lat: 48.1580816, lng: 18.8837211 },
    { lat: 48.1571934, lng: 18.884357 },
    { lat: 48.1566781, lng: 18.8846384 },
    { lat: 48.1560545, lng: 18.8852011 },
    { lat: 48.1552976, lng: 18.885672 },
    { lat: 48.1531361, lng: 18.8799213 },
    { lat: 48.1527519, lng: 18.8798174 },
    { lat: 48.1523061, lng: 18.8795112 },
    { lat: 48.1518855, lng: 18.8793572 },
    { lat: 48.1516209, lng: 18.8793445 },
    { lat: 48.1512899, lng: 18.8794403 },
    { lat: 48.1508463, lng: 18.8797794 },
    { lat: 48.1499296, lng: 18.8808184 },
    { lat: 48.148502, lng: 18.8817045 },
    { lat: 48.1475871, lng: 18.8818283 },
    { lat: 48.1467111, lng: 18.8821309 },
    { lat: 48.1451046, lng: 18.8820958 },
    { lat: 48.144965, lng: 18.882165 },
    { lat: 48.1446056, lng: 18.8827124 },
    { lat: 48.1443379, lng: 18.8827683 },
    { lat: 48.1438326, lng: 18.8827261 },
    { lat: 48.1438162, lng: 18.8831005 },
    { lat: 48.1406947, lng: 18.8848724 },
    { lat: 48.1396502, lng: 18.8855608 },
    { lat: 48.1380575, lng: 18.8872373 },
    { lat: 48.1368525, lng: 18.8883739 },
    { lat: 48.1361228, lng: 18.889234 },
    { lat: 48.1359296, lng: 18.8895085 },
    { lat: 48.1355999, lng: 18.8904738 },
    { lat: 48.1352406, lng: 18.8910885 },
    { lat: 48.1352399, lng: 18.8929638 },
    { lat: 48.135318, lng: 18.8943565 },
    { lat: 48.1352565, lng: 18.8959479 },
    { lat: 48.1351546, lng: 18.8961338 },
    { lat: 48.1348711, lng: 18.8964487 },
    { lat: 48.1344143, lng: 18.8965342 },
    { lat: 48.1342909, lng: 18.8967007 },
    { lat: 48.1341714, lng: 18.8971336 },
    { lat: 48.1339902, lng: 18.8975298 },
    { lat: 48.1339074, lng: 18.8975645 },
    { lat: 48.1338211, lng: 18.8975082 },
    { lat: 48.1338679, lng: 18.8981904 },
    { lat: 48.1344156, lng: 18.9014626 },
    { lat: 48.1342725, lng: 18.9020048 },
    { lat: 48.1342351, lng: 18.9023505 },
    { lat: 48.1343792, lng: 18.9034083 },
    { lat: 48.1344429, lng: 18.9062186 },
    { lat: 48.1348208, lng: 18.907243 },
    { lat: 48.1350218, lng: 18.9080531 },
    { lat: 48.1355472, lng: 18.9116561 },
    { lat: 48.1355747, lng: 18.9121231 },
    { lat: 48.1355425, lng: 18.9127184 },
    { lat: 48.1354192, lng: 18.9138849 },
    { lat: 48.135192, lng: 18.9149183 },
    { lat: 48.1348457, lng: 18.9160262 },
    { lat: 48.1345748, lng: 18.9178945 },
    { lat: 48.1343389, lng: 18.9185847 },
    { lat: 48.1337887, lng: 18.9205679 },
    { lat: 48.1332695, lng: 18.9217737 },
    { lat: 48.1325685, lng: 18.9236786 },
    { lat: 48.1323875, lng: 18.9240564 },
    { lat: 48.1328407, lng: 18.924382 },
    { lat: 48.1332113, lng: 18.9248306 },
    { lat: 48.1338229, lng: 18.9251482 },
    { lat: 48.1352147, lng: 18.9255875 },
    { lat: 48.1365576, lng: 18.9261776 },
    { lat: 48.1376027, lng: 18.9264328 },
    { lat: 48.1383357, lng: 18.9263252 },
    { lat: 48.1402452, lng: 18.9263133 },
    { lat: 48.1403856, lng: 18.9265869 },
    { lat: 48.1442855, lng: 18.9307212 },
    { lat: 48.1446296, lng: 18.9311383 },
    { lat: 48.1448265, lng: 18.9314782 },
    { lat: 48.1449039, lng: 18.9318206 },
    { lat: 48.1449776, lng: 18.9317834 },
    { lat: 48.1449774, lng: 18.9315833 },
    { lat: 48.1450883, lng: 18.9314826 },
    { lat: 48.1452015, lng: 18.931448 },
    { lat: 48.1453764, lng: 18.9316259 },
    { lat: 48.1459081, lng: 18.9313081 },
    { lat: 48.1472715, lng: 18.9308333 },
    { lat: 48.1473182, lng: 18.9306903 },
    { lat: 48.1474653, lng: 18.9305225 },
    { lat: 48.14905, lng: 18.9288544 },
    { lat: 48.1493104, lng: 18.9293519 },
    { lat: 48.1495327, lng: 18.9301182 },
    { lat: 48.1500809, lng: 18.9303201 },
    { lat: 48.1505724, lng: 18.9302746 },
    { lat: 48.1513115, lng: 18.9297589 },
    { lat: 48.152544, lng: 18.9291049 },
    { lat: 48.1532607, lng: 18.9289698 },
    { lat: 48.1544488, lng: 18.9290251 },
    { lat: 48.1550593, lng: 18.9299938 },
    { lat: 48.1554081, lng: 18.9303738 },
    { lat: 48.1560324, lng: 18.9308671 },
    { lat: 48.1567453, lng: 18.9312654 },
    { lat: 48.1577889, lng: 18.9314055 },
    { lat: 48.1580936, lng: 18.931542 },
    { lat: 48.15875, lng: 18.93166 },
    { lat: 48.158803, lng: 18.931272 },
    { lat: 48.158809, lng: 18.931231 },
    { lat: 48.159019, lng: 18.929706 },
    { lat: 48.159725, lng: 18.924612 },
    { lat: 48.159924, lng: 18.923104 },
    { lat: 48.160141, lng: 18.920958 },
    { lat: 48.159989, lng: 18.920525 },
    { lat: 48.159502, lng: 18.919133 },
    { lat: 48.159184, lng: 18.918543 },
    { lat: 48.159083, lng: 18.918146 },
    { lat: 48.159009, lng: 18.91756 },
    { lat: 48.159004, lng: 18.917226 },
    { lat: 48.159032, lng: 18.917144 },
    { lat: 48.159258, lng: 18.916486 },
    { lat: 48.159313, lng: 18.916325 },
    { lat: 48.15964, lng: 18.915348 },
    { lat: 48.159828, lng: 18.914449 },
    { lat: 48.159865, lng: 18.913758 },
    { lat: 48.15986, lng: 18.912927 },
    { lat: 48.159829, lng: 18.911957 },
    { lat: 48.159828, lng: 18.911385 },
    { lat: 48.159868, lng: 18.911102 },
    { lat: 48.159742, lng: 18.910945 },
    { lat: 48.160095, lng: 18.909958 },
    { lat: 48.160696, lng: 18.908496 },
    { lat: 48.161043, lng: 18.907457 },
    { lat: 48.161098, lng: 18.906083 },
    { lat: 48.16044, lng: 18.904682 },
    { lat: 48.160108, lng: 18.903532 },
    { lat: 48.160007, lng: 18.903266 },
    { lat: 48.159728, lng: 18.903 },
    { lat: 48.159561, lng: 18.90277 },
    { lat: 48.159488, lng: 18.902495 },
    { lat: 48.159485, lng: 18.902361 },
    { lat: 48.159299, lng: 18.901822 },
    { lat: 48.159156, lng: 18.901604 },
    { lat: 48.158462, lng: 18.900228 },
    { lat: 48.159184, lng: 18.898303 },
    { lat: 48.159231, lng: 18.898178 },
    { lat: 48.159384, lng: 18.897479 },
    { lat: 48.159602, lng: 18.896707 },
    { lat: 48.159683, lng: 18.89653 },
    { lat: 48.159752, lng: 18.896461 },
    { lat: 48.159937, lng: 18.896059 },
    { lat: 48.160182, lng: 18.895964 },
    { lat: 48.16048, lng: 18.895412 },
    { lat: 48.160614, lng: 18.89516 },
    { lat: 48.160654, lng: 18.895163 },
    { lat: 48.160676, lng: 18.895128 },
    { lat: 48.160846, lng: 18.894602 },
    { lat: 48.160852, lng: 18.893558 },
    { lat: 48.160888, lng: 18.89333 },
    { lat: 48.160901, lng: 18.892889 },
    { lat: 48.160929, lng: 18.892815 },
    { lat: 48.160947, lng: 18.89264 },
    { lat: 48.160945, lng: 18.892576 },
    { lat: 48.161043, lng: 18.892104 },
    { lat: 48.161051, lng: 18.891933 },
    { lat: 48.161056, lng: 18.891814 },
    { lat: 48.161128, lng: 18.891495 },
    { lat: 48.161161, lng: 18.891333 },
    { lat: 48.16117, lng: 18.891281 },
    { lat: 48.161245, lng: 18.890892 },
    { lat: 48.16128, lng: 18.890563 },
    { lat: 48.161287, lng: 18.890359 },
    { lat: 48.161378, lng: 18.889836 },
    { lat: 48.161436, lng: 18.889414 },
    { lat: 48.161482, lng: 18.889302 },
    { lat: 48.161486, lng: 18.889285 },
    { lat: 48.161582, lng: 18.888797 },
    { lat: 48.161667, lng: 18.888582 },
    { lat: 48.161672, lng: 18.888569 },
    { lat: 48.161685, lng: 18.888494 },
    { lat: 48.161763, lng: 18.888278 },
    { lat: 48.161826, lng: 18.888111 },
    { lat: 48.161846, lng: 18.888049 },
    { lat: 48.161956, lng: 18.887673 },
    { lat: 48.161998, lng: 18.887681 },
    { lat: 48.16205, lng: 18.887514 },
    { lat: 48.162087, lng: 18.887396 },
  ],
  Kozárovce: [
    { lat: 48.334257, lng: 18.490495 },
    { lat: 48.334032, lng: 18.489961 },
    { lat: 48.333906, lng: 18.4897 },
    { lat: 48.333846, lng: 18.489607 },
    { lat: 48.333743, lng: 18.48947 },
    { lat: 48.333639, lng: 18.489332 },
    { lat: 48.333579, lng: 18.489233 },
    { lat: 48.333459, lng: 18.488865 },
    { lat: 48.33338, lng: 18.488697 },
    { lat: 48.333235, lng: 18.48851 },
    { lat: 48.333181, lng: 18.488399 },
    { lat: 48.33311, lng: 18.488252 },
    { lat: 48.333063, lng: 18.488157 },
    { lat: 48.333009, lng: 18.487993 },
    { lat: 48.33299, lng: 18.487935 },
    { lat: 48.332942, lng: 18.487797 },
    { lat: 48.332689, lng: 18.486853 },
    { lat: 48.332664, lng: 18.486777 },
    { lat: 48.332619, lng: 18.486644 },
    { lat: 48.33241, lng: 18.486117 },
    { lat: 48.332258, lng: 18.485751 },
    { lat: 48.332245, lng: 18.485726 },
    { lat: 48.332224, lng: 18.485689 },
    { lat: 48.332127, lng: 18.485522 },
    { lat: 48.332109, lng: 18.48535 },
    { lat: 48.332088, lng: 18.485157 },
    { lat: 48.332037, lng: 18.484685 },
    { lat: 48.332033, lng: 18.484633 },
    { lat: 48.332028, lng: 18.484564 },
    { lat: 48.332024, lng: 18.484503 },
    { lat: 48.332019, lng: 18.484438 },
    { lat: 48.332014, lng: 18.484369 },
    { lat: 48.332008, lng: 18.484294 },
    { lat: 48.332004, lng: 18.484223 },
    { lat: 48.331999, lng: 18.48416 },
    { lat: 48.331995, lng: 18.484115 },
    { lat: 48.33199, lng: 18.484042 },
    { lat: 48.331986, lng: 18.484001 },
    { lat: 48.331984, lng: 18.483973 },
    { lat: 48.33197, lng: 18.483874 },
    { lat: 48.331963, lng: 18.483818 },
    { lat: 48.331959, lng: 18.483789 },
    { lat: 48.331943, lng: 18.483678 },
    { lat: 48.331939, lng: 18.483617 },
    { lat: 48.331936, lng: 18.483577 },
    { lat: 48.331931, lng: 18.483495 },
    { lat: 48.331925, lng: 18.483409 },
    { lat: 48.331921, lng: 18.483319 },
    { lat: 48.33191, lng: 18.483247 },
    { lat: 48.3319, lng: 18.483175 },
    { lat: 48.331889, lng: 18.4831 },
    { lat: 48.331878, lng: 18.483029 },
    { lat: 48.331869, lng: 18.482959 },
    { lat: 48.331858, lng: 18.48289 },
    { lat: 48.331851, lng: 18.482828 },
    { lat: 48.331842, lng: 18.482784 },
    { lat: 48.331828, lng: 18.482714 },
    { lat: 48.331814, lng: 18.482643 },
    { lat: 48.3318, lng: 18.482578 },
    { lat: 48.331787, lng: 18.48251 },
    { lat: 48.331773, lng: 18.482442 },
    { lat: 48.331759, lng: 18.482374 },
    { lat: 48.331742, lng: 18.482263 },
    { lat: 48.331733, lng: 18.482199 },
    { lat: 48.331723, lng: 18.482134 },
    { lat: 48.331714, lng: 18.482069 },
    { lat: 48.331706, lng: 18.482014 },
    { lat: 48.331695, lng: 18.481936 },
    { lat: 48.33169, lng: 18.481905 },
    { lat: 48.331682, lng: 18.481849 },
    { lat: 48.331673, lng: 18.481786 },
    { lat: 48.331666, lng: 18.481718 },
    { lat: 48.331661, lng: 18.481674 },
    { lat: 48.331657, lng: 18.481633 },
    { lat: 48.331656, lng: 18.481613 },
    { lat: 48.331651, lng: 18.48157 },
    { lat: 48.33164, lng: 18.481455 },
    { lat: 48.331637, lng: 18.481374 },
    { lat: 48.331634, lng: 18.48129 },
    { lat: 48.331632, lng: 18.481207 },
    { lat: 48.331629, lng: 18.481123 },
    { lat: 48.331627, lng: 18.481084 },
    { lat: 48.331625, lng: 18.481023 },
    { lat: 48.331624, lng: 18.480975 },
    { lat: 48.331622, lng: 18.480929 },
    { lat: 48.331621, lng: 18.480891 },
    { lat: 48.331619, lng: 18.480835 },
    { lat: 48.331618, lng: 18.480793 },
    { lat: 48.331616, lng: 18.480741 },
    { lat: 48.33161, lng: 18.480655 },
    { lat: 48.331603, lng: 18.480568 },
    { lat: 48.331598, lng: 18.4805 },
    { lat: 48.331592, lng: 18.480434 },
    { lat: 48.331583, lng: 18.480294 },
    { lat: 48.331579, lng: 18.480244 },
    { lat: 48.331578, lng: 18.48023 },
    { lat: 48.331576, lng: 18.480196 },
    { lat: 48.331573, lng: 18.480086 },
    { lat: 48.331571, lng: 18.480035 },
    { lat: 48.33157, lng: 18.479983 },
    { lat: 48.331568, lng: 18.479931 },
    { lat: 48.331566, lng: 18.479879 },
    { lat: 48.331564, lng: 18.479814 },
    { lat: 48.331562, lng: 18.47974 },
    { lat: 48.33156, lng: 18.47969 },
    { lat: 48.331558, lng: 18.479638 },
    { lat: 48.331558, lng: 18.479586 },
    { lat: 48.331556, lng: 18.479534 },
    { lat: 48.331555, lng: 18.47949 },
    { lat: 48.331553, lng: 18.479418 },
    { lat: 48.33155, lng: 18.479345 },
    { lat: 48.331546, lng: 18.479187 },
    { lat: 48.331542, lng: 18.479033 },
    { lat: 48.331537, lng: 18.478913 },
    { lat: 48.331536, lng: 18.478846 },
    { lat: 48.331533, lng: 18.478777 },
    { lat: 48.331534, lng: 18.478765 },
    { lat: 48.331535, lng: 18.478696 },
    { lat: 48.331537, lng: 18.478637 },
    { lat: 48.331539, lng: 18.478576 },
    { lat: 48.331539, lng: 18.478524 },
    { lat: 48.331541, lng: 18.478473 },
    { lat: 48.331548, lng: 18.478243 },
    { lat: 48.33155, lng: 18.478163 },
    { lat: 48.331552, lng: 18.478065 },
    { lat: 48.331563, lng: 18.477967 },
    { lat: 48.331572, lng: 18.477875 },
    { lat: 48.331582, lng: 18.477783 },
    { lat: 48.331583, lng: 18.477769 },
    { lat: 48.331584, lng: 18.477722 },
    { lat: 48.331586, lng: 18.47769 },
    { lat: 48.331589, lng: 18.477603 },
    { lat: 48.331589, lng: 18.47757 },
    { lat: 48.331592, lng: 18.477509 },
    { lat: 48.331592, lng: 18.477463 },
    { lat: 48.33159, lng: 18.477348 },
    { lat: 48.33159, lng: 18.477233 },
    { lat: 48.331588, lng: 18.477099 },
    { lat: 48.33159, lng: 18.477014 },
    { lat: 48.331592, lng: 18.476928 },
    { lat: 48.331593, lng: 18.47685 },
    { lat: 48.331597, lng: 18.476727 },
    { lat: 48.331599, lng: 18.476605 },
    { lat: 48.331598, lng: 18.476537 },
    { lat: 48.331597, lng: 18.476469 },
    { lat: 48.331597, lng: 18.476404 },
    { lat: 48.331596, lng: 18.476342 },
    { lat: 48.331595, lng: 18.47628 },
    { lat: 48.331593, lng: 18.476138 },
    { lat: 48.331592, lng: 18.47604 },
    { lat: 48.331591, lng: 18.476003 },
    { lat: 48.331591, lng: 18.475976 },
    { lat: 48.331591, lng: 18.475929 },
    { lat: 48.33159, lng: 18.475879 },
    { lat: 48.331589, lng: 18.4758 },
    { lat: 48.331594, lng: 18.475731 },
    { lat: 48.3316, lng: 18.475657 },
    { lat: 48.331607, lng: 18.47556 },
    { lat: 48.331615, lng: 18.475455 },
    { lat: 48.331623, lng: 18.475346 },
    { lat: 48.331627, lng: 18.475291 },
    { lat: 48.33163, lng: 18.475242 },
    { lat: 48.331634, lng: 18.475193 },
    { lat: 48.331638, lng: 18.475141 },
    { lat: 48.331645, lng: 18.475056 },
    { lat: 48.33165, lng: 18.474981 },
    { lat: 48.331654, lng: 18.474932 },
    { lat: 48.331665, lng: 18.474819 },
    { lat: 48.331675, lng: 18.474723 },
    { lat: 48.331723, lng: 18.474246 },
    { lat: 48.331736, lng: 18.474117 },
    { lat: 48.331748, lng: 18.47399 },
    { lat: 48.331753, lng: 18.473944 },
    { lat: 48.331756, lng: 18.473916 },
    { lat: 48.33176, lng: 18.473897 },
    { lat: 48.331779, lng: 18.473799 },
    { lat: 48.331832, lng: 18.473544 },
    { lat: 48.331868, lng: 18.473361 },
    { lat: 48.331885, lng: 18.473279 },
    { lat: 48.331938, lng: 18.473017 },
    { lat: 48.331993, lng: 18.472741 },
    { lat: 48.332004, lng: 18.472687 },
    { lat: 48.332015, lng: 18.47263 },
    { lat: 48.33202, lng: 18.472553 },
    { lat: 48.332024, lng: 18.472504 },
    { lat: 48.332031, lng: 18.472414 },
    { lat: 48.332037, lng: 18.472332 },
    { lat: 48.332039, lng: 18.472306 },
    { lat: 48.332045, lng: 18.472214 },
    { lat: 48.332011, lng: 18.471741 },
    { lat: 48.331995, lng: 18.471349 },
    { lat: 48.331902, lng: 18.470621 },
    { lat: 48.33182, lng: 18.470072 },
    { lat: 48.331776, lng: 18.469945 },
    { lat: 48.331712, lng: 18.469762 },
    { lat: 48.331702, lng: 18.469736 },
    { lat: 48.331693, lng: 18.46971 },
    { lat: 48.33166, lng: 18.469649 },
    { lat: 48.331487, lng: 18.469903 },
    { lat: 48.331371, lng: 18.470073 },
    { lat: 48.331219, lng: 18.470276 },
    { lat: 48.330841, lng: 18.470836 },
    { lat: 48.330502, lng: 18.471336 },
    { lat: 48.330258, lng: 18.47177 },
    { lat: 48.330035, lng: 18.472188 },
    { lat: 48.329813, lng: 18.472665 },
    { lat: 48.3297, lng: 18.472933 },
    { lat: 48.329505, lng: 18.473419 },
    { lat: 48.32916, lng: 18.47413 },
    { lat: 48.328948, lng: 18.474509 },
    { lat: 48.328649, lng: 18.47493 },
    { lat: 48.328415, lng: 18.475239 },
    { lat: 48.3283, lng: 18.475364 },
    { lat: 48.328052, lng: 18.475631 },
    { lat: 48.327776, lng: 18.475932 },
    { lat: 48.327426, lng: 18.476311 },
    { lat: 48.327308, lng: 18.476393 },
    { lat: 48.327145, lng: 18.476488 },
    { lat: 48.326303, lng: 18.47682 },
    { lat: 48.326127, lng: 18.476926 },
    { lat: 48.32592, lng: 18.477241 },
    { lat: 48.325829, lng: 18.477408 },
    { lat: 48.325414, lng: 18.478079 },
    { lat: 48.325308, lng: 18.478203 },
    { lat: 48.32504, lng: 18.478477 },
    { lat: 48.324486, lng: 18.47879 },
    { lat: 48.324291, lng: 18.478943 },
    { lat: 48.323932, lng: 18.479202 },
    { lat: 48.323724, lng: 18.479309 },
    { lat: 48.323692, lng: 18.479326 },
    { lat: 48.3234473, lng: 18.4793244 },
    { lat: 48.3232678, lng: 18.4793232 },
    { lat: 48.323245, lng: 18.479323 },
    { lat: 48.322803, lng: 18.479311 },
    { lat: 48.322639, lng: 18.479269 },
    { lat: 48.322324, lng: 18.479232 },
    { lat: 48.321921, lng: 18.479195 },
    { lat: 48.3217013, lng: 18.4791735 },
    { lat: 48.32141, lng: 18.479145 },
    { lat: 48.321245, lng: 18.479066 },
    { lat: 48.320934, lng: 18.478749 },
    { lat: 48.320612, lng: 18.478342 },
    { lat: 48.320217, lng: 18.47804 },
    { lat: 48.320153, lng: 18.477991 },
    { lat: 48.319549, lng: 18.47753 },
    { lat: 48.319325, lng: 18.477367 },
    { lat: 48.3192825, lng: 18.477337 },
    { lat: 48.319233, lng: 18.477302 },
    { lat: 48.318838, lng: 18.477149 },
    { lat: 48.318279, lng: 18.476946 },
    { lat: 48.318191, lng: 18.476844 },
    { lat: 48.317672, lng: 18.476219 },
    { lat: 48.31745, lng: 18.475882 },
    { lat: 48.317367, lng: 18.475757 },
    { lat: 48.317079, lng: 18.47532 },
    { lat: 48.317058, lng: 18.475289 },
    { lat: 48.317042, lng: 18.47527 },
    { lat: 48.316859, lng: 18.47506 },
    { lat: 48.316499, lng: 18.474649 },
    { lat: 48.316449, lng: 18.474595 },
    { lat: 48.316445, lng: 18.474589 },
    { lat: 48.316239, lng: 18.474362 },
    { lat: 48.316148, lng: 18.47426 },
    { lat: 48.316021, lng: 18.47412 },
    { lat: 48.315522, lng: 18.473558 },
    { lat: 48.316016, lng: 18.471661 },
    { lat: 48.316162, lng: 18.471346 },
    { lat: 48.316182, lng: 18.471303 },
    { lat: 48.31622, lng: 18.471222 },
    { lat: 48.316182, lng: 18.471166 },
    { lat: 48.31597, lng: 18.470857 },
    { lat: 48.315101, lng: 18.469569 },
    { lat: 48.314504, lng: 18.470032 },
    { lat: 48.314321, lng: 18.470251 },
    { lat: 48.314057, lng: 18.470667 },
    { lat: 48.313828, lng: 18.471041 },
    { lat: 48.313661, lng: 18.471517 },
    { lat: 48.313123, lng: 18.472161 },
    { lat: 48.313018, lng: 18.472276 },
    { lat: 48.312988, lng: 18.472309 },
    { lat: 48.312986, lng: 18.472311 },
    { lat: 48.312522, lng: 18.472818 },
    { lat: 48.311868, lng: 18.473654 },
    { lat: 48.311207, lng: 18.474466 },
    { lat: 48.310915, lng: 18.474726 },
    { lat: 48.310861, lng: 18.474774 },
    { lat: 48.31084, lng: 18.474781 },
    { lat: 48.310057, lng: 18.475029 },
    { lat: 48.309673, lng: 18.475151 },
    { lat: 48.309083, lng: 18.475519 },
    { lat: 48.308824, lng: 18.475757 },
    { lat: 48.308261, lng: 18.476536 },
    { lat: 48.307838, lng: 18.47709 },
    { lat: 48.307092, lng: 18.478064 },
    { lat: 48.306573, lng: 18.478559 },
    { lat: 48.305828, lng: 18.479021 },
    { lat: 48.305617, lng: 18.479559 },
    { lat: 48.305546, lng: 18.479529 },
    { lat: 48.30428, lng: 18.478989 },
    { lat: 48.304136, lng: 18.478982 },
    { lat: 48.303762, lng: 18.478962 },
    { lat: 48.3027, lng: 18.478548 },
    { lat: 48.301919, lng: 18.477985 },
    { lat: 48.300959, lng: 18.477565 },
    { lat: 48.300202, lng: 18.476996 },
    { lat: 48.299458, lng: 18.476638 },
    { lat: 48.298648, lng: 18.476427 },
    { lat: 48.297866, lng: 18.47629 },
    { lat: 48.297794, lng: 18.476278 },
    { lat: 48.297216, lng: 18.475448 },
    { lat: 48.297136, lng: 18.475333 },
    { lat: 48.29689, lng: 18.474316 },
    { lat: 48.297017, lng: 18.473733 },
    { lat: 48.297273, lng: 18.473505 },
    { lat: 48.297599, lng: 18.472762 },
    { lat: 48.2977446, lng: 18.4717025 },
    { lat: 48.297778, lng: 18.471459 },
    { lat: 48.2976, lng: 18.470595 },
    { lat: 48.297473, lng: 18.469981 },
    { lat: 48.297035, lng: 18.468562 },
    { lat: 48.29727, lng: 18.467799 },
    { lat: 48.296899, lng: 18.466028 },
    { lat: 48.29655, lng: 18.465121 },
    { lat: 48.296532, lng: 18.465091 },
    { lat: 48.29594, lng: 18.464335 },
    { lat: 48.295873, lng: 18.464248 },
    { lat: 48.295381, lng: 18.463605 },
    { lat: 48.295333, lng: 18.463538 },
    { lat: 48.295245, lng: 18.463452 },
    { lat: 48.292428, lng: 18.460679 },
    { lat: 48.29209, lng: 18.460728 },
    { lat: 48.292041, lng: 18.460734 },
    { lat: 48.290416, lng: 18.460967 },
    { lat: 48.289755, lng: 18.461063 },
    { lat: 48.28859, lng: 18.461238 },
    { lat: 48.288534, lng: 18.461243 },
    { lat: 48.285263, lng: 18.461704 },
    { lat: 48.285525, lng: 18.463022 },
    { lat: 48.285541, lng: 18.463105 },
    { lat: 48.285637, lng: 18.463578 },
    { lat: 48.285339, lng: 18.464162 },
    { lat: 48.284528, lng: 18.465747 },
    { lat: 48.284371, lng: 18.466055 },
    { lat: 48.284009, lng: 18.466792 },
    { lat: 48.283479, lng: 18.467806 },
    { lat: 48.283049, lng: 18.468655 },
    { lat: 48.28255, lng: 18.469634 },
    { lat: 48.282515, lng: 18.469702 },
    { lat: 48.282419, lng: 18.469884 },
    { lat: 48.28239, lng: 18.46994 },
    { lat: 48.282089, lng: 18.470536 },
    { lat: 48.281923, lng: 18.471883 },
    { lat: 48.281905, lng: 18.472316 },
    { lat: 48.281899, lng: 18.472508 },
    { lat: 48.281803, lng: 18.473075 },
    { lat: 48.2816303, lng: 18.4737766 },
    { lat: 48.2815337, lng: 18.4740326 },
    { lat: 48.281582, lng: 18.474138 },
    { lat: 48.281564, lng: 18.474158 },
    { lat: 48.2816995, lng: 18.4743776 },
    { lat: 48.2809215, lng: 18.4794777 },
    { lat: 48.2801534, lng: 18.4816732 },
    { lat: 48.2796686, lng: 18.4832236 },
    { lat: 48.2795484, lng: 18.4834563 },
    { lat: 48.2795398, lng: 18.4836897 },
    { lat: 48.2796334, lng: 18.4838884 },
    { lat: 48.2796847, lng: 18.4843279 },
    { lat: 48.2795675, lng: 18.4845122 },
    { lat: 48.279574, lng: 18.484692 },
    { lat: 48.279505, lng: 18.4847689 },
    { lat: 48.2796061, lng: 18.4852883 },
    { lat: 48.2797362, lng: 18.4853787 },
    { lat: 48.2798364, lng: 18.4856598 },
    { lat: 48.2800202, lng: 18.4858798 },
    { lat: 48.2800104, lng: 18.4861113 },
    { lat: 48.2800842, lng: 18.4862186 },
    { lat: 48.2801174, lng: 18.4864121 },
    { lat: 48.2802161, lng: 18.486483 },
    { lat: 48.2802345, lng: 18.4867768 },
    { lat: 48.2804147, lng: 18.4870125 },
    { lat: 48.2804765, lng: 18.4870087 },
    { lat: 48.2804972, lng: 18.4871397 },
    { lat: 48.2804478, lng: 18.4872421 },
    { lat: 48.2805703, lng: 18.4872772 },
    { lat: 48.2806636, lng: 18.4875347 },
    { lat: 48.2805141, lng: 18.4875626 },
    { lat: 48.2806511, lng: 18.4880225 },
    { lat: 48.2808076, lng: 18.4880771 },
    { lat: 48.2808755, lng: 18.4882446 },
    { lat: 48.2807873, lng: 18.4884621 },
    { lat: 48.2808566, lng: 18.4886164 },
    { lat: 48.2810429, lng: 18.48876 },
    { lat: 48.2811204, lng: 18.4886321 },
    { lat: 48.2812301, lng: 18.4887183 },
    { lat: 48.2812529, lng: 18.4888645 },
    { lat: 48.2811109, lng: 18.4890108 },
    { lat: 48.2811097, lng: 18.4890878 },
    { lat: 48.2812678, lng: 18.4891542 },
    { lat: 48.2813293, lng: 18.4894869 },
    { lat: 48.2814488, lng: 18.4898141 },
    { lat: 48.2814596, lng: 18.4901924 },
    { lat: 48.2816223, lng: 18.4903985 },
    { lat: 48.2816799, lng: 18.4904012 },
    { lat: 48.2817655, lng: 18.4899114 },
    { lat: 48.2820399, lng: 18.4903837 },
    { lat: 48.2820294, lng: 18.490561 },
    { lat: 48.2819518, lng: 18.4905633 },
    { lat: 48.2819158, lng: 18.4906471 },
    { lat: 48.2819266, lng: 18.4907406 },
    { lat: 48.2820243, lng: 18.4907713 },
    { lat: 48.2819995, lng: 18.4909334 },
    { lat: 48.2818132, lng: 18.4909109 },
    { lat: 48.2818472, lng: 18.4911366 },
    { lat: 48.2819707, lng: 18.4911481 },
    { lat: 48.2819799, lng: 18.4913131 },
    { lat: 48.2820614, lng: 18.4914024 },
    { lat: 48.2820013, lng: 18.4918236 },
    { lat: 48.2818935, lng: 18.4920353 },
    { lat: 48.2819087, lng: 18.4921475 },
    { lat: 48.2820844, lng: 18.492324 },
    { lat: 48.2821449, lng: 18.4922987 },
    { lat: 48.2821353, lng: 18.4921037 },
    { lat: 48.282185, lng: 18.4920451 },
    { lat: 48.2823736, lng: 18.4920493 },
    { lat: 48.282489, lng: 18.4921953 },
    { lat: 48.2824853, lng: 18.4922904 },
    { lat: 48.2823602, lng: 18.4923537 },
    { lat: 48.2823037, lng: 18.4925796 },
    { lat: 48.2823591, lng: 18.4927219 },
    { lat: 48.2825402, lng: 18.4926487 },
    { lat: 48.2825611, lng: 18.4927828 },
    { lat: 48.282501, lng: 18.4931026 },
    { lat: 48.2825799, lng: 18.4934892 },
    { lat: 48.2826509, lng: 18.4936305 },
    { lat: 48.2826373, lng: 18.4944648 },
    { lat: 48.2826942, lng: 18.4945325 },
    { lat: 48.2828652, lng: 18.4944871 },
    { lat: 48.2829498, lng: 18.4944061 },
    { lat: 48.2838367, lng: 18.4941024 },
    { lat: 48.2852975, lng: 18.4937337 },
    { lat: 48.2870714, lng: 18.4946 },
    { lat: 48.2877401, lng: 18.4950429 },
    { lat: 48.2885277, lng: 18.4964712 },
    { lat: 48.2895574, lng: 18.4987075 },
    { lat: 48.2897507, lng: 18.4990402 },
    { lat: 48.2904155, lng: 18.4997512 },
    { lat: 48.2904605, lng: 18.4997701 },
    { lat: 48.290905, lng: 18.4999619 },
    { lat: 48.2921694, lng: 18.5012216 },
    { lat: 48.2936957, lng: 18.5031826 },
    { lat: 48.2946196, lng: 18.504201 },
    { lat: 48.2966596, lng: 18.5048846 },
    { lat: 48.297098, lng: 18.5047537 },
    { lat: 48.2971607, lng: 18.5048072 },
    { lat: 48.2970174, lng: 18.5052724 },
    { lat: 48.2969393, lng: 18.5053887 },
    { lat: 48.2969918, lng: 18.5059331 },
    { lat: 48.2969304, lng: 18.506007 },
    { lat: 48.2970102, lng: 18.5060385 },
    { lat: 48.3003768, lng: 18.5073773 },
    { lat: 48.29819, lng: 18.5236503 },
    { lat: 48.2957409, lng: 18.5229419 },
    { lat: 48.2954273, lng: 18.5250778 },
    { lat: 48.2948489, lng: 18.5283808 },
    { lat: 48.2949096, lng: 18.5283974 },
    { lat: 48.2948321, lng: 18.5290364 },
    { lat: 48.2954218, lng: 18.5291781 },
    { lat: 48.2960431, lng: 18.5296429 },
    { lat: 48.2964511, lng: 18.5300459 },
    { lat: 48.2967062, lng: 18.5304373 },
    { lat: 48.2986301, lng: 18.5328453 },
    { lat: 48.2987046, lng: 18.532907 },
    { lat: 48.2989699, lng: 18.5332384 },
    { lat: 48.2995539, lng: 18.534466 },
    { lat: 48.3005875, lng: 18.5351856 },
    { lat: 48.3010095, lng: 18.5357407 },
    { lat: 48.3011613, lng: 18.5360592 },
    { lat: 48.301456, lng: 18.5363934 },
    { lat: 48.3018303, lng: 18.5367595 },
    { lat: 48.3023258, lng: 18.5371135 },
    { lat: 48.3024888, lng: 18.5371026 },
    { lat: 48.3040418, lng: 18.5375062 },
    { lat: 48.3043249, lng: 18.5376303 },
    { lat: 48.3045061, lng: 18.5379115 },
    { lat: 48.3051771, lng: 18.5375363 },
    { lat: 48.3062555, lng: 18.5375366 },
    { lat: 48.3065912, lng: 18.5374469 },
    { lat: 48.3068056, lng: 18.5375501 },
    { lat: 48.3079082, lng: 18.5371711 },
    { lat: 48.3085601, lng: 18.5371487 },
    { lat: 48.3094125, lng: 18.5372655 },
    { lat: 48.309813, lng: 18.537443 },
    { lat: 48.309823, lng: 18.537319 },
    { lat: 48.309846, lng: 18.537027 },
    { lat: 48.309872, lng: 18.536937 },
    { lat: 48.309891, lng: 18.536878 },
    { lat: 48.309897, lng: 18.536859 },
    { lat: 48.309921, lng: 18.536834 },
    { lat: 48.309947, lng: 18.536799 },
    { lat: 48.310051, lng: 18.536656 },
    { lat: 48.310097, lng: 18.536594 },
    { lat: 48.310138, lng: 18.53652 },
    { lat: 48.310231, lng: 18.536346 },
    { lat: 48.310334, lng: 18.536153 },
    { lat: 48.310969, lng: 18.536453 },
    { lat: 48.311222, lng: 18.536575 },
    { lat: 48.311271, lng: 18.536507 },
    { lat: 48.311306, lng: 18.536457 },
    { lat: 48.311376, lng: 18.536357 },
    { lat: 48.311395, lng: 18.53633 },
    { lat: 48.311416, lng: 18.5363 },
    { lat: 48.311569, lng: 18.5360777 },
    { lat: 48.311707, lng: 18.535882 },
    { lat: 48.311714, lng: 18.53582 },
    { lat: 48.311758, lng: 18.535486 },
    { lat: 48.31179, lng: 18.535235 },
    { lat: 48.311824, lng: 18.534977 },
    { lat: 48.311849, lng: 18.534784 },
    { lat: 48.311869, lng: 18.534633 },
    { lat: 48.312026, lng: 18.534761 },
    { lat: 48.312083, lng: 18.53481 },
    { lat: 48.312181, lng: 18.53489 },
    { lat: 48.312212, lng: 18.534916 },
    { lat: 48.312292, lng: 18.534982 },
    { lat: 48.3124, lng: 18.535072 },
    { lat: 48.312448, lng: 18.535113 },
    { lat: 48.31251, lng: 18.535146 },
    { lat: 48.312826, lng: 18.535321 },
    { lat: 48.312927, lng: 18.535376 },
    { lat: 48.313018, lng: 18.535426 },
    { lat: 48.313134, lng: 18.535497 },
    { lat: 48.313176, lng: 18.535522 },
    { lat: 48.313273, lng: 18.535568 },
    { lat: 48.31336, lng: 18.535608 },
    { lat: 48.313419, lng: 18.535632 },
    { lat: 48.313476, lng: 18.535379 },
    { lat: 48.313513, lng: 18.535212 },
    { lat: 48.313524, lng: 18.535159 },
    { lat: 48.313572, lng: 18.534961 },
    { lat: 48.313578, lng: 18.53494 },
    { lat: 48.313696, lng: 18.534933 },
    { lat: 48.313809, lng: 18.534945 },
    { lat: 48.313826, lng: 18.534947 },
    { lat: 48.313844, lng: 18.534949 },
    { lat: 48.31393, lng: 18.53496 },
    { lat: 48.313939, lng: 18.534962 },
    { lat: 48.313951, lng: 18.534972 },
    { lat: 48.313995, lng: 18.535007 },
    { lat: 48.31403, lng: 18.535036 },
    { lat: 48.314066, lng: 18.535107 },
    { lat: 48.314075, lng: 18.535129 },
    { lat: 48.314094, lng: 18.535188 },
    { lat: 48.314095, lng: 18.535298 },
    { lat: 48.314095, lng: 18.535337 },
    { lat: 48.314106, lng: 18.53546 },
    { lat: 48.314112, lng: 18.535698 },
    { lat: 48.3141, lng: 18.535874 },
    { lat: 48.314093, lng: 18.535993 },
    { lat: 48.314146, lng: 18.536027 },
    { lat: 48.314278, lng: 18.536103 },
    { lat: 48.314348, lng: 18.536142 },
    { lat: 48.314414, lng: 18.53618 },
    { lat: 48.314489, lng: 18.536218 },
    { lat: 48.31455, lng: 18.536227 },
    { lat: 48.314582, lng: 18.536229 },
    { lat: 48.314674, lng: 18.536227 },
    { lat: 48.314764, lng: 18.536221 },
    { lat: 48.314877, lng: 18.536127 },
    { lat: 48.315025, lng: 18.535831 },
    { lat: 48.315076, lng: 18.535729 },
    { lat: 48.315189, lng: 18.535526 },
    { lat: 48.315232, lng: 18.535428 },
    { lat: 48.315249, lng: 18.535397 },
    { lat: 48.315284, lng: 18.535336 },
    { lat: 48.315315, lng: 18.535253 },
    { lat: 48.315406, lng: 18.535083 },
    { lat: 48.315765, lng: 18.534256 },
    { lat: 48.315798, lng: 18.534294 },
    { lat: 48.315838, lng: 18.534351 },
    { lat: 48.315859, lng: 18.534378 },
    { lat: 48.315925, lng: 18.534458 },
    { lat: 48.315996, lng: 18.534551 },
    { lat: 48.316083, lng: 18.534646 },
    { lat: 48.316107, lng: 18.53467 },
    { lat: 48.316128, lng: 18.534695 },
    { lat: 48.316156, lng: 18.534727 },
    { lat: 48.316166, lng: 18.534736 },
    { lat: 48.316181, lng: 18.534751 },
    { lat: 48.316212, lng: 18.534785 },
    { lat: 48.316245, lng: 18.534817 },
    { lat: 48.316268, lng: 18.534838 },
    { lat: 48.316287, lng: 18.534862 },
    { lat: 48.316308, lng: 18.534882 },
    { lat: 48.316349, lng: 18.534929 },
    { lat: 48.31638, lng: 18.534958 },
    { lat: 48.316403, lng: 18.534982 },
    { lat: 48.316459, lng: 18.53504 },
    { lat: 48.316494, lng: 18.535067 },
    { lat: 48.316761, lng: 18.535258 },
    { lat: 48.316809, lng: 18.535296 },
    { lat: 48.316824, lng: 18.535307 },
    { lat: 48.316852, lng: 18.535328 },
    { lat: 48.31695, lng: 18.535405 },
    { lat: 48.317043, lng: 18.535478 },
    { lat: 48.317184, lng: 18.53559 },
    { lat: 48.317261, lng: 18.53564 },
    { lat: 48.317332, lng: 18.535685 },
    { lat: 48.317405, lng: 18.535736 },
    { lat: 48.317478, lng: 18.53579 },
    { lat: 48.317621, lng: 18.535878 },
    { lat: 48.317694, lng: 18.535934 },
    { lat: 48.317723, lng: 18.535953 },
    { lat: 48.317765, lng: 18.535985 },
    { lat: 48.317828, lng: 18.536036 },
    { lat: 48.317904, lng: 18.536089 },
    { lat: 48.317939, lng: 18.536117 },
    { lat: 48.31797, lng: 18.536142 },
    { lat: 48.318026, lng: 18.536183 },
    { lat: 48.318075, lng: 18.536218 },
    { lat: 48.318093, lng: 18.536233 },
    { lat: 48.318113, lng: 18.536244 },
    { lat: 48.318133, lng: 18.536259 },
    { lat: 48.318173, lng: 18.536283 },
    { lat: 48.318179, lng: 18.536269 },
    { lat: 48.318342, lng: 18.536004 },
    { lat: 48.318435, lng: 18.535898 },
    { lat: 48.318554, lng: 18.535763 },
    { lat: 48.318632, lng: 18.535675 },
    { lat: 48.318703, lng: 18.535597 },
    { lat: 48.318613, lng: 18.535433 },
    { lat: 48.318482, lng: 18.535213 },
    { lat: 48.318437, lng: 18.535138 },
    { lat: 48.317838, lng: 18.534342 },
    { lat: 48.317864, lng: 18.534326 },
    { lat: 48.317884, lng: 18.534316 },
    { lat: 48.317965, lng: 18.534277 },
    { lat: 48.317987, lng: 18.534264 },
    { lat: 48.318015, lng: 18.534251 },
    { lat: 48.318056, lng: 18.534234 },
    { lat: 48.31809, lng: 18.534218 },
    { lat: 48.318129, lng: 18.5342 },
    { lat: 48.318171, lng: 18.53418 },
    { lat: 48.31821, lng: 18.534164 },
    { lat: 48.31824, lng: 18.534151 },
    { lat: 48.318263, lng: 18.534141 },
    { lat: 48.318289, lng: 18.534128 },
    { lat: 48.31845, lng: 18.534064 },
    { lat: 48.318991, lng: 18.534907 },
    { lat: 48.319004, lng: 18.534932 },
    { lat: 48.319081, lng: 18.535049 },
    { lat: 48.31916, lng: 18.53517 },
    { lat: 48.319162, lng: 18.535175 },
    { lat: 48.319201, lng: 18.535238 },
    { lat: 48.319215, lng: 18.53526 },
    { lat: 48.319254, lng: 18.535164 },
    { lat: 48.319381, lng: 18.534849 },
    { lat: 48.3196, lng: 18.534227 },
    { lat: 48.319623, lng: 18.534252 },
    { lat: 48.319669, lng: 18.534293 },
    { lat: 48.319742, lng: 18.534359 },
    { lat: 48.31978, lng: 18.534395 },
    { lat: 48.319828, lng: 18.534439 },
    { lat: 48.319911, lng: 18.534517 },
    { lat: 48.320034, lng: 18.534631 },
    { lat: 48.320063, lng: 18.53466 },
    { lat: 48.320098, lng: 18.534692 },
    { lat: 48.320131, lng: 18.534724 },
    { lat: 48.320157, lng: 18.534748 },
    { lat: 48.320193, lng: 18.53478 },
    { lat: 48.320219, lng: 18.534807 },
    { lat: 48.320256, lng: 18.534838 },
    { lat: 48.320289, lng: 18.534867 },
    { lat: 48.320496, lng: 18.534404 },
    { lat: 48.320577, lng: 18.534479 },
    { lat: 48.320611, lng: 18.534509 },
    { lat: 48.320721, lng: 18.534606 },
    { lat: 48.320826, lng: 18.534701 },
    { lat: 48.32087, lng: 18.53474 },
    { lat: 48.320935, lng: 18.534798 },
    { lat: 48.321044, lng: 18.534899 },
    { lat: 48.321152, lng: 18.534996 },
    { lat: 48.321238, lng: 18.535077 },
    { lat: 48.321255, lng: 18.535093 },
    { lat: 48.3213, lng: 18.535135 },
    { lat: 48.321345, lng: 18.535174 },
    { lat: 48.321395, lng: 18.535221 },
    { lat: 48.321438, lng: 18.535259 },
    { lat: 48.321454, lng: 18.535225 },
    { lat: 48.321466, lng: 18.535201 },
    { lat: 48.321573, lng: 18.53499 },
    { lat: 48.321607, lng: 18.53493 },
    { lat: 48.32164, lng: 18.534879 },
    { lat: 48.32176, lng: 18.534647 },
    { lat: 48.321813, lng: 18.534557 },
    { lat: 48.321835, lng: 18.534518 },
    { lat: 48.321858, lng: 18.534482 },
    { lat: 48.321913, lng: 18.534397 },
    { lat: 48.322002, lng: 18.534267 },
    { lat: 48.322093, lng: 18.534149 },
    { lat: 48.322189, lng: 18.534022 },
    { lat: 48.322201, lng: 18.534002 },
    { lat: 48.322251, lng: 18.533894 },
    { lat: 48.322289, lng: 18.533795 },
    { lat: 48.322322, lng: 18.533708 },
    { lat: 48.322355, lng: 18.533625 },
    { lat: 48.322616, lng: 18.53403 },
    { lat: 48.32273, lng: 18.534204 },
    { lat: 48.322937, lng: 18.534511 },
    { lat: 48.3232874, lng: 18.5349976 },
    { lat: 48.324049, lng: 18.536055 },
    { lat: 48.324254, lng: 18.536355 },
    { lat: 48.324276, lng: 18.536389 },
    { lat: 48.3243141, lng: 18.5364505 },
    { lat: 48.324465, lng: 18.536694 },
    { lat: 48.325083, lng: 18.537775 },
    { lat: 48.3251323, lng: 18.5378633 },
    { lat: 48.32556, lng: 18.53863 },
    { lat: 48.3258677, lng: 18.5392584 },
    { lat: 48.326059, lng: 18.539649 },
    { lat: 48.326284, lng: 18.540124 },
    { lat: 48.326691, lng: 18.540963 },
    { lat: 48.326917, lng: 18.541489 },
    { lat: 48.327015, lng: 18.541717 },
    { lat: 48.327026, lng: 18.54172 },
    { lat: 48.327054, lng: 18.541729 },
    { lat: 48.327063, lng: 18.541707 },
    { lat: 48.327205, lng: 18.541343 },
    { lat: 48.3274, lng: 18.540879 },
    { lat: 48.327542, lng: 18.540518 },
    { lat: 48.327665, lng: 18.54015 },
    { lat: 48.327775, lng: 18.539769 },
    { lat: 48.327809, lng: 18.539543 },
    { lat: 48.327829, lng: 18.53933 },
    { lat: 48.327926, lng: 18.5386 },
    { lat: 48.327933, lng: 18.538561 },
    { lat: 48.328001, lng: 18.538339 },
    { lat: 48.328022, lng: 18.538234 },
    { lat: 48.327266, lng: 18.537185 },
    { lat: 48.326234, lng: 18.535752 },
    { lat: 48.326602, lng: 18.534874 },
    { lat: 48.327061, lng: 18.533746 },
    { lat: 48.327489, lng: 18.532701 },
    { lat: 48.328159, lng: 18.533187 },
    { lat: 48.328406, lng: 18.533365 },
    { lat: 48.329193, lng: 18.533847 },
    { lat: 48.329224, lng: 18.53385 },
    { lat: 48.329265, lng: 18.533743 },
    { lat: 48.329291, lng: 18.533679 },
    { lat: 48.329363, lng: 18.533492 },
    { lat: 48.329385, lng: 18.533435 },
    { lat: 48.32944, lng: 18.533288 },
    { lat: 48.329594, lng: 18.532843 },
    { lat: 48.329703, lng: 18.532484 },
    { lat: 48.329799, lng: 18.53214 },
    { lat: 48.329884, lng: 18.531796 },
    { lat: 48.32993, lng: 18.531607 },
    { lat: 48.329955, lng: 18.531441 },
    { lat: 48.329912, lng: 18.530879 },
    { lat: 48.329916, lng: 18.530609 },
    { lat: 48.32996, lng: 18.530369 },
    { lat: 48.329987, lng: 18.530256 },
    { lat: 48.33004, lng: 18.530077 },
    { lat: 48.330137, lng: 18.529828 },
    { lat: 48.330227, lng: 18.529624 },
    { lat: 48.330328, lng: 18.529395 },
    { lat: 48.330414, lng: 18.529201 },
    { lat: 48.330566, lng: 18.52883 },
    { lat: 48.330602, lng: 18.528742 },
    { lat: 48.330519, lng: 18.528712 },
    { lat: 48.330416, lng: 18.528673 },
    { lat: 48.330342, lng: 18.528661 },
    { lat: 48.330203, lng: 18.528658 },
    { lat: 48.330088, lng: 18.528676 },
    { lat: 48.329824, lng: 18.528756 },
    { lat: 48.329646, lng: 18.528812 },
    { lat: 48.329676, lng: 18.528726 },
    { lat: 48.329759, lng: 18.528578 },
    { lat: 48.329866, lng: 18.528478 },
    { lat: 48.329954, lng: 18.528322 },
    { lat: 48.330068, lng: 18.528084 },
    { lat: 48.330178, lng: 18.527965 },
    { lat: 48.330343, lng: 18.527818 },
    { lat: 48.330371, lng: 18.527792 },
    { lat: 48.330404, lng: 18.527753 },
    { lat: 48.330447, lng: 18.527677 },
    { lat: 48.330473, lng: 18.527611 },
    { lat: 48.3305, lng: 18.527561 },
    { lat: 48.330701, lng: 18.527269 },
    { lat: 48.330849, lng: 18.52716 },
    { lat: 48.330973, lng: 18.527055 },
    { lat: 48.330984, lng: 18.527055 },
    { lat: 48.331045, lng: 18.527046 },
    { lat: 48.331106, lng: 18.527098 },
    { lat: 48.331121, lng: 18.527071 },
    { lat: 48.331132, lng: 18.527054 },
    { lat: 48.331296, lng: 18.526764 },
    { lat: 48.331466, lng: 18.526468 },
    { lat: 48.331638, lng: 18.526168 },
    { lat: 48.331668, lng: 18.526116 },
    { lat: 48.331812, lng: 18.525864 },
    { lat: 48.331989, lng: 18.525554 },
    { lat: 48.332168, lng: 18.525241 },
    { lat: 48.332313, lng: 18.524988 },
    { lat: 48.332642, lng: 18.524412 },
    { lat: 48.333002, lng: 18.525216 },
    { lat: 48.333599, lng: 18.524543 },
    { lat: 48.334638, lng: 18.523374 },
    { lat: 48.335032, lng: 18.52293 },
    { lat: 48.335427, lng: 18.522339 },
    { lat: 48.335785, lng: 18.521805 },
    { lat: 48.335789, lng: 18.521799 },
    { lat: 48.335659, lng: 18.521391 },
    { lat: 48.335533, lng: 18.520997 },
    { lat: 48.335121, lng: 18.519649 },
    { lat: 48.335105, lng: 18.519655 },
    { lat: 48.334894, lng: 18.519727 },
    { lat: 48.334439, lng: 18.518274 },
    { lat: 48.33443, lng: 18.518251 },
    { lat: 48.334299, lng: 18.517906 },
    { lat: 48.334189, lng: 18.517633 },
    { lat: 48.334044, lng: 18.517284 },
    { lat: 48.333898, lng: 18.516961 },
    { lat: 48.333758, lng: 18.516691 },
    { lat: 48.333776, lng: 18.516572 },
    { lat: 48.333649, lng: 18.516341 },
    { lat: 48.333344, lng: 18.515739 },
    { lat: 48.333234, lng: 18.515494 },
    { lat: 48.333194, lng: 18.51538 },
    { lat: 48.332944, lng: 18.514576 },
    { lat: 48.332778, lng: 18.514003 },
    { lat: 48.332663, lng: 18.513623 },
    { lat: 48.332502, lng: 18.513153 },
    { lat: 48.332388, lng: 18.512797 },
    { lat: 48.332237, lng: 18.512382 },
    { lat: 48.332129, lng: 18.512126 },
    { lat: 48.332078, lng: 18.512021 },
    { lat: 48.33207, lng: 18.51189 },
    { lat: 48.332046, lng: 18.511726 },
    { lat: 48.331934, lng: 18.510954 },
    { lat: 48.331728, lng: 18.510259 },
    { lat: 48.331679, lng: 18.510084 },
    { lat: 48.331574, lng: 18.509746 },
    { lat: 48.331368, lng: 18.509115 },
    { lat: 48.331568, lng: 18.508958 },
    { lat: 48.331722, lng: 18.508841 },
    { lat: 48.33166, lng: 18.50866 },
    { lat: 48.331566, lng: 18.508462 },
    { lat: 48.331509, lng: 18.508203 },
    { lat: 48.331468, lng: 18.508024 },
    { lat: 48.331431, lng: 18.50794 },
    { lat: 48.331421, lng: 18.507908 },
    { lat: 48.331415, lng: 18.507888 },
    { lat: 48.3314, lng: 18.50784 },
    { lat: 48.331408, lng: 18.507456 },
    { lat: 48.331559, lng: 18.507199 },
    { lat: 48.331623, lng: 18.50708 },
    { lat: 48.331777, lng: 18.505932 },
    { lat: 48.331795, lng: 18.505754 },
    { lat: 48.331809, lng: 18.505554 },
    { lat: 48.331838, lng: 18.505397 },
    { lat: 48.331922, lng: 18.505129 },
    { lat: 48.331963, lng: 18.505045 },
    { lat: 48.332294, lng: 18.504134 },
    { lat: 48.33251, lng: 18.503499 },
    { lat: 48.332728, lng: 18.502695 },
    { lat: 48.332755, lng: 18.502513 },
    { lat: 48.332809, lng: 18.501748 },
    { lat: 48.332847, lng: 18.501272 },
    { lat: 48.332857, lng: 18.50076 },
    { lat: 48.332852, lng: 18.500239 },
    { lat: 48.332836, lng: 18.499774 },
    { lat: 48.332791, lng: 18.499344 },
    { lat: 48.332804, lng: 18.499209 },
    { lat: 48.332859, lng: 18.498913 },
    { lat: 48.332952, lng: 18.498574 },
    { lat: 48.333058, lng: 18.497804 },
    { lat: 48.333153, lng: 18.497213 },
    { lat: 48.333294, lng: 18.496454 },
    { lat: 48.333357, lng: 18.496273 },
    { lat: 48.333392, lng: 18.496063 },
    { lat: 48.333603, lng: 18.49544 },
    { lat: 48.333675, lng: 18.49533 },
    { lat: 48.333706, lng: 18.495301 },
    { lat: 48.333792, lng: 18.495081 },
    { lat: 48.333843, lng: 18.494994 },
    { lat: 48.333931, lng: 18.494863 },
    { lat: 48.333991, lng: 18.494789 },
    { lat: 48.334017, lng: 18.494782 },
    { lat: 48.334098, lng: 18.494642 },
    { lat: 48.334191, lng: 18.49442 },
    { lat: 48.334254, lng: 18.494259 },
    { lat: 48.334396, lng: 18.493889 },
    { lat: 48.334475, lng: 18.493712 },
    { lat: 48.334576, lng: 18.493634 },
    { lat: 48.334737, lng: 18.49326 },
    { lat: 48.334911, lng: 18.493002 },
    { lat: 48.335058, lng: 18.492833 },
    { lat: 48.335028, lng: 18.492713 },
    { lat: 48.334877, lng: 18.492367 },
    { lat: 48.334702, lng: 18.492057 },
    { lat: 48.334639, lng: 18.491922 },
    { lat: 48.334624, lng: 18.491905 },
    { lat: 48.334611, lng: 18.491875 },
    { lat: 48.33457, lng: 18.491776 },
    { lat: 48.334552, lng: 18.491731 },
    { lat: 48.334468, lng: 18.491433 },
    { lat: 48.334372, lng: 18.491023 },
    { lat: 48.334257, lng: 18.490495 },
  ],
  PlavéVozokany: [
    { lat: 48.1024014, lng: 18.4909994 },
    { lat: 48.1023466, lng: 18.490156 },
    { lat: 48.1009614, lng: 18.486732 },
    { lat: 48.101005, lng: 18.4865656 },
    { lat: 48.09975, lng: 18.4742041 },
    { lat: 48.0997956, lng: 18.4734866 },
    { lat: 48.0997201, lng: 18.4728206 },
    { lat: 48.0997306, lng: 18.4724836 },
    { lat: 48.0999944, lng: 18.4710628 },
    { lat: 48.1001755, lng: 18.4706105 },
    { lat: 48.1002853, lng: 18.4700487 },
    { lat: 48.1004515, lng: 18.4697365 },
    { lat: 48.1005062, lng: 18.4695007 },
    { lat: 48.1002588, lng: 18.4676991 },
    { lat: 48.1000509, lng: 18.4669059 },
    { lat: 48.10005, lng: 18.4667656 },
    { lat: 48.1001049, lng: 18.4665551 },
    { lat: 48.1003145, lng: 18.466169 },
    { lat: 48.1006937, lng: 18.4647762 },
    { lat: 48.1010023, lng: 18.4639825 },
    { lat: 48.1008901, lng: 18.4635871 },
    { lat: 48.1005595, lng: 18.4629623 },
    { lat: 48.1003495, lng: 18.4624326 },
    { lat: 48.100189, lng: 18.461812 },
    { lat: 48.100172, lng: 18.461828 },
    { lat: 48.100141, lng: 18.461737 },
    { lat: 48.100063, lng: 18.461506 },
    { lat: 48.099907, lng: 18.461045 },
    { lat: 48.09986, lng: 18.460902 },
    { lat: 48.099841, lng: 18.460848 },
    { lat: 48.099795, lng: 18.460266 },
    { lat: 48.099865, lng: 18.46001 },
    { lat: 48.099872, lng: 18.459868 },
    { lat: 48.09987, lng: 18.45977 },
    { lat: 48.099836, lng: 18.459541 },
    { lat: 48.09983, lng: 18.459501 },
    { lat: 48.099826, lng: 18.459477 },
    { lat: 48.09977, lng: 18.459006 },
    { lat: 48.099747, lng: 18.458783 },
    { lat: 48.099695, lng: 18.458458 },
    { lat: 48.099663, lng: 18.458152 },
    { lat: 48.099567, lng: 18.457784 },
    { lat: 48.099554, lng: 18.457732 },
    { lat: 48.099314, lng: 18.457011 },
    { lat: 48.09913, lng: 18.456326 },
    { lat: 48.099102, lng: 18.456264 },
    { lat: 48.099012, lng: 18.456062 },
    { lat: 48.098821, lng: 18.455676 },
    { lat: 48.0988, lng: 18.455639 },
    { lat: 48.098652, lng: 18.455381 },
    { lat: 48.098452, lng: 18.455139 },
    { lat: 48.098238, lng: 18.454794 },
    { lat: 48.098202, lng: 18.454728 },
    { lat: 48.097977, lng: 18.454306 },
    { lat: 48.097886, lng: 18.45417 },
    { lat: 48.097847, lng: 18.454138 },
    { lat: 48.097762, lng: 18.454064 },
    { lat: 48.097761, lng: 18.454058 },
    { lat: 48.097763, lng: 18.454032 },
    { lat: 48.097751, lng: 18.45403 },
    { lat: 48.097597, lng: 18.454003 },
    { lat: 48.097276, lng: 18.454105 },
    { lat: 48.096732, lng: 18.454254 },
    { lat: 48.096389, lng: 18.454334 },
    { lat: 48.096018, lng: 18.454382 },
    { lat: 48.095414, lng: 18.453912 },
    { lat: 48.09528, lng: 18.45385 },
    { lat: 48.094915, lng: 18.453921 },
    { lat: 48.094461, lng: 18.453984 },
    { lat: 48.093558, lng: 18.454041 },
    { lat: 48.093167, lng: 18.454047 },
    { lat: 48.092644, lng: 18.454026 },
    { lat: 48.09222, lng: 18.454056 },
    { lat: 48.091906, lng: 18.454128 },
    { lat: 48.091146, lng: 18.454343 },
    { lat: 48.090605, lng: 18.454573 },
    { lat: 48.090108, lng: 18.454834 },
    { lat: 48.089461, lng: 18.454999 },
    { lat: 48.089051, lng: 18.455002 },
    { lat: 48.088764, lng: 18.454851 },
    { lat: 48.088388, lng: 18.454777 },
    { lat: 48.088019, lng: 18.454734 },
    { lat: 48.087761, lng: 18.454809 },
    { lat: 48.087755, lng: 18.454793 },
    { lat: 48.087745, lng: 18.454764 },
    { lat: 48.087734, lng: 18.454736 },
    { lat: 48.087721, lng: 18.454697 },
    { lat: 48.087719, lng: 18.454695 },
    { lat: 48.08765, lng: 18.454499 },
    { lat: 48.087661, lng: 18.454496 },
    { lat: 48.087659, lng: 18.454479 },
    { lat: 48.087539, lng: 18.453567 },
    { lat: 48.087486, lng: 18.453067 },
    { lat: 48.087466, lng: 18.452733 },
    { lat: 48.087463, lng: 18.452682 },
    { lat: 48.087452, lng: 18.452478 },
    { lat: 48.087274, lng: 18.451519 },
    { lat: 48.087223, lng: 18.451374 },
    { lat: 48.087075, lng: 18.45119 },
    { lat: 48.086867, lng: 18.450998 },
    { lat: 48.086673, lng: 18.450608 },
    { lat: 48.086582, lng: 18.450355 },
    { lat: 48.086522, lng: 18.450031 },
    { lat: 48.08648, lng: 18.449853 },
    { lat: 48.08643, lng: 18.449721 },
    { lat: 48.086367, lng: 18.449638 },
    { lat: 48.086138, lng: 18.449541 },
    { lat: 48.085803, lng: 18.44955 },
    { lat: 48.085553, lng: 18.449592 },
    { lat: 48.085294, lng: 18.449596 },
    { lat: 48.085046, lng: 18.449644 },
    { lat: 48.084847, lng: 18.44974 },
    { lat: 48.084569, lng: 18.449813 },
    { lat: 48.084248, lng: 18.450066 },
    { lat: 48.084038, lng: 18.450274 },
    { lat: 48.083442, lng: 18.450805 },
    { lat: 48.083252, lng: 18.450937 },
    { lat: 48.083241, lng: 18.450945 },
    { lat: 48.083099, lng: 18.451016 },
    { lat: 48.082867, lng: 18.451133 },
    { lat: 48.082818, lng: 18.451157 },
    { lat: 48.082677, lng: 18.451229 },
    { lat: 48.082408, lng: 18.451364 },
    { lat: 48.082354, lng: 18.451391 },
    { lat: 48.082094, lng: 18.451639 },
    { lat: 48.081785, lng: 18.451934 },
    { lat: 48.081761, lng: 18.451957 },
    { lat: 48.081643, lng: 18.452052 },
    { lat: 48.081626, lng: 18.452066 },
    { lat: 48.081617, lng: 18.452074 },
    { lat: 48.081086, lng: 18.452504 },
    { lat: 48.081063, lng: 18.452522 },
    { lat: 48.08103, lng: 18.452549 },
    { lat: 48.081044, lng: 18.452502 },
    { lat: 48.081159, lng: 18.452131 },
    { lat: 48.081235, lng: 18.451862 },
    { lat: 48.081419, lng: 18.45121 },
    { lat: 48.081475, lng: 18.4506 },
    { lat: 48.081539, lng: 18.449722 },
    { lat: 48.081543, lng: 18.449345 },
    { lat: 48.081516, lng: 18.449228 },
    { lat: 48.081508, lng: 18.449189 },
    { lat: 48.08166, lng: 18.449107 },
    { lat: 48.08211, lng: 18.447856 },
    { lat: 48.082214, lng: 18.447348 },
    { lat: 48.082332, lng: 18.446815 },
    { lat: 48.082505, lng: 18.446414 },
    { lat: 48.082648, lng: 18.445727 },
    { lat: 48.082772, lng: 18.445336 },
    { lat: 48.08282, lng: 18.444318 },
    { lat: 48.082923, lng: 18.443874 },
    { lat: 48.083051, lng: 18.443653 },
    { lat: 48.08308, lng: 18.443439 },
    { lat: 48.083114, lng: 18.442976 },
    { lat: 48.08326, lng: 18.442362 },
    { lat: 48.083341, lng: 18.442033 },
    { lat: 48.083491, lng: 18.441626 },
    { lat: 48.083619, lng: 18.441083 },
    { lat: 48.08362, lng: 18.441059 },
    { lat: 48.083622, lng: 18.441026 },
    { lat: 48.083645, lng: 18.440573 },
    { lat: 48.083931, lng: 18.439792 },
    { lat: 48.083918, lng: 18.439118 },
    { lat: 48.083915, lng: 18.438966 },
    { lat: 48.083923, lng: 18.438352 },
    { lat: 48.083924, lng: 18.438291 },
    { lat: 48.083925, lng: 18.438277 },
    { lat: 48.083989, lng: 18.437662 },
    { lat: 48.083998, lng: 18.437345 },
    { lat: 48.084008, lng: 18.436986 },
    { lat: 48.084016, lng: 18.436723 },
    { lat: 48.084004, lng: 18.43644 },
    { lat: 48.084004, lng: 18.43643 },
    { lat: 48.083998, lng: 18.436312 },
    { lat: 48.083998, lng: 18.436311 },
    { lat: 48.083956, lng: 18.435393 },
    { lat: 48.083946, lng: 18.435172 },
    { lat: 48.083945, lng: 18.435158 },
    { lat: 48.083944, lng: 18.435085 },
    { lat: 48.083945, lng: 18.435076 },
    { lat: 48.083941, lng: 18.434695 },
    { lat: 48.083925, lng: 18.434099 },
    { lat: 48.083911, lng: 18.4324 },
    { lat: 48.083839, lng: 18.431392 },
    { lat: 48.083825, lng: 18.431195 },
    { lat: 48.083976, lng: 18.430797 },
    { lat: 48.084342, lng: 18.430092 },
    { lat: 48.084738, lng: 18.429069 },
    { lat: 48.084993, lng: 18.428691 },
    { lat: 48.085365, lng: 18.427545 },
    { lat: 48.085621, lng: 18.42657 },
    { lat: 48.08568, lng: 18.426346 },
    { lat: 48.08577, lng: 18.426144 },
    { lat: 48.085914, lng: 18.425825 },
    { lat: 48.085963, lng: 18.425714 },
    { lat: 48.086025, lng: 18.424973 },
    { lat: 48.08611, lng: 18.423955 },
    { lat: 48.086129, lng: 18.423717 },
    { lat: 48.086225, lng: 18.423302 },
    { lat: 48.086267, lng: 18.42312 },
    { lat: 48.086303, lng: 18.422963 },
    { lat: 48.086354, lng: 18.422742 },
    { lat: 48.086366, lng: 18.422694 },
    { lat: 48.086484, lng: 18.422377 },
    { lat: 48.086527, lng: 18.422259 },
    { lat: 48.086572, lng: 18.42214 },
    { lat: 48.086612, lng: 18.422032 },
    { lat: 48.086661, lng: 18.421899 },
    { lat: 48.086737, lng: 18.421733 },
    { lat: 48.086865, lng: 18.421454 },
    { lat: 48.086904, lng: 18.421368 },
    { lat: 48.086943, lng: 18.421283 },
    { lat: 48.086976, lng: 18.42121 },
    { lat: 48.087001, lng: 18.421154 },
    { lat: 48.087036, lng: 18.421078 },
    { lat: 48.087086, lng: 18.42097 },
    { lat: 48.087118, lng: 18.420898 },
    { lat: 48.087138, lng: 18.420856 },
    { lat: 48.087079, lng: 18.420886 },
    { lat: 48.086729, lng: 18.420997 },
    { lat: 48.086394, lng: 18.421235 },
    { lat: 48.086055, lng: 18.421412 },
    { lat: 48.08573, lng: 18.421637 },
    { lat: 48.08544, lng: 18.421924 },
    { lat: 48.085079, lng: 18.422019 },
    { lat: 48.084385, lng: 18.422323 },
    { lat: 48.084041, lng: 18.422557 },
    { lat: 48.083729, lng: 18.422823 },
    { lat: 48.083514, lng: 18.422913 },
    { lat: 48.083481, lng: 18.423072 },
    { lat: 48.083067, lng: 18.42327 },
    { lat: 48.082854, lng: 18.423357 },
    { lat: 48.082774, lng: 18.42323 },
    { lat: 48.082677, lng: 18.423246 },
    { lat: 48.08233, lng: 18.423384 },
    { lat: 48.081949, lng: 18.423527 },
    { lat: 48.081736, lng: 18.423672 },
    { lat: 48.081485, lng: 18.423805 },
    { lat: 48.081077, lng: 18.423997 },
    { lat: 48.080787, lng: 18.424166 },
    { lat: 48.080463, lng: 18.424269 },
    { lat: 48.080057, lng: 18.424407 },
    { lat: 48.079745, lng: 18.42449 },
    { lat: 48.079341, lng: 18.424693 },
    { lat: 48.078343, lng: 18.425106 },
    { lat: 48.078108, lng: 18.425182 },
    { lat: 48.078076, lng: 18.425192 },
    { lat: 48.077928, lng: 18.42529 },
    { lat: 48.077753, lng: 18.425381 },
    { lat: 48.077536, lng: 18.425468 },
    { lat: 48.077388, lng: 18.425505 },
    { lat: 48.076858, lng: 18.425572 },
    { lat: 48.076366, lng: 18.425673 },
    { lat: 48.076123, lng: 18.425769 },
    { lat: 48.075948, lng: 18.425858 },
    { lat: 48.075662, lng: 18.426092 },
    { lat: 48.07545, lng: 18.426266 },
    { lat: 48.075392, lng: 18.426346 },
    { lat: 48.075235, lng: 18.426374 },
    { lat: 48.075104, lng: 18.426413 },
    { lat: 48.075038, lng: 18.426424 },
    { lat: 48.074932, lng: 18.426438 },
    { lat: 48.074538, lng: 18.426451 },
    { lat: 48.074421, lng: 18.426491 },
    { lat: 48.074009, lng: 18.42669 },
    { lat: 48.073732, lng: 18.426897 },
    { lat: 48.073478, lng: 18.427052 },
    { lat: 48.072941, lng: 18.427213 },
    { lat: 48.072806, lng: 18.427251 },
    { lat: 48.072596, lng: 18.427309 },
    { lat: 48.072202, lng: 18.427458 },
    { lat: 48.072004, lng: 18.427555 },
    { lat: 48.071861, lng: 18.427664 },
    { lat: 48.071744, lng: 18.42777 },
    { lat: 48.071362, lng: 18.428171 },
    { lat: 48.071285, lng: 18.428235 },
    { lat: 48.071179, lng: 18.428322 },
    { lat: 48.071156, lng: 18.428334 },
    { lat: 48.071002, lng: 18.428406 },
    { lat: 48.070798, lng: 18.428437 },
    { lat: 48.070448, lng: 18.428558 },
    { lat: 48.069871, lng: 18.428894 },
    { lat: 48.069368, lng: 18.429203 },
    { lat: 48.068829, lng: 18.429617 },
    { lat: 48.068411, lng: 18.430017 },
    { lat: 48.068101, lng: 18.430282 },
    { lat: 48.067788, lng: 18.430561 },
    { lat: 48.067776, lng: 18.430542 },
    { lat: 48.067696, lng: 18.430657 },
    { lat: 48.067286, lng: 18.431227 },
    { lat: 48.067096, lng: 18.431472 },
    { lat: 48.06695, lng: 18.431636 },
    { lat: 48.066905, lng: 18.431686 },
    { lat: 48.066667, lng: 18.431923 },
    { lat: 48.065924, lng: 18.432547 },
    { lat: 48.065593, lng: 18.432869 },
    { lat: 48.06442, lng: 18.433916 },
    { lat: 48.063473, lng: 18.434959 },
    { lat: 48.06326, lng: 18.435162 },
    { lat: 48.063042, lng: 18.435388 },
    { lat: 48.062738, lng: 18.435817 },
    { lat: 48.062568, lng: 18.436093 },
    { lat: 48.062011, lng: 18.437162 },
    { lat: 48.061984, lng: 18.437229 },
    { lat: 48.06189, lng: 18.437464 },
    { lat: 48.061358, lng: 18.439019 },
    { lat: 48.061284, lng: 18.439291 },
    { lat: 48.06108, lng: 18.440001 },
    { lat: 48.060618, lng: 18.441333 },
    { lat: 48.060496, lng: 18.441653 },
    { lat: 48.060325, lng: 18.441974 },
    { lat: 48.059879, lng: 18.442655 },
    { lat: 48.0598, lng: 18.442778 },
    { lat: 48.059732, lng: 18.442884 },
    { lat: 48.059711, lng: 18.442908 },
    { lat: 48.059378, lng: 18.443287 },
    { lat: 48.059012, lng: 18.443632 },
    { lat: 48.05844, lng: 18.444447 },
    { lat: 48.058254, lng: 18.444647 },
    { lat: 48.057991, lng: 18.444805 },
    { lat: 48.057694, lng: 18.444956 },
    { lat: 48.057256, lng: 18.44511 },
    { lat: 48.056845, lng: 18.445216 },
    { lat: 48.056573, lng: 18.445266 },
    { lat: 48.056252, lng: 18.445301 },
    { lat: 48.055754, lng: 18.445313 },
    { lat: 48.055527, lng: 18.44529 },
    { lat: 48.055387, lng: 18.44523 },
    { lat: 48.05538, lng: 18.445271 },
    { lat: 48.05503, lng: 18.445199 },
    { lat: 48.054389, lng: 18.445181 },
    { lat: 48.054215, lng: 18.445206 },
    { lat: 48.054058, lng: 18.445258 },
    { lat: 48.053293, lng: 18.445612 },
    { lat: 48.052717, lng: 18.445839 },
    { lat: 48.052438, lng: 18.445978 },
    { lat: 48.052171, lng: 18.446049 },
    { lat: 48.051763, lng: 18.446111 },
    { lat: 48.051559, lng: 18.446184 },
    { lat: 48.05117, lng: 18.446354 },
    { lat: 48.050581, lng: 18.446659 },
    { lat: 48.050204, lng: 18.446665 },
    { lat: 48.049866, lng: 18.446506 },
    { lat: 48.049444, lng: 18.446353 },
    { lat: 48.049284, lng: 18.446329 },
    { lat: 48.049115, lng: 18.446346 },
    { lat: 48.048851, lng: 18.446399 },
    { lat: 48.048695, lng: 18.446533 },
    { lat: 48.048673, lng: 18.44651 },
    { lat: 48.0469718, lng: 18.4474135 },
    { lat: 48.0464175, lng: 18.4478292 },
    { lat: 48.0454298, lng: 18.4484226 },
    { lat: 48.0447879, lng: 18.4489476 },
    { lat: 48.0441614, lng: 18.4493021 },
    { lat: 48.043448, lng: 18.4500192 },
    { lat: 48.0431325, lng: 18.4502285 },
    { lat: 48.0429773, lng: 18.4506398 },
    { lat: 48.043249, lng: 18.4515384 },
    { lat: 48.0432669, lng: 18.4517988 },
    { lat: 48.0434466, lng: 18.4525086 },
    { lat: 48.0434311, lng: 18.4537433 },
    { lat: 48.0435078, lng: 18.4543852 },
    { lat: 48.0436309, lng: 18.4547305 },
    { lat: 48.0437472, lng: 18.4556217 },
    { lat: 48.0447047, lng: 18.458536 },
    { lat: 48.0455366, lng: 18.4605805 },
    { lat: 48.0458245, lng: 18.461616 },
    { lat: 48.0463167, lng: 18.4628123 },
    { lat: 48.0461092, lng: 18.4635559 },
    { lat: 48.0461155, lng: 18.4636108 },
    { lat: 48.0465384, lng: 18.4646575 },
    { lat: 48.0471487, lng: 18.4657855 },
    { lat: 48.0471883, lng: 18.4663916 },
    { lat: 48.0473412, lng: 18.4668686 },
    { lat: 48.0479518, lng: 18.4674396 },
    { lat: 48.0481966, lng: 18.4685888 },
    { lat: 48.0493506, lng: 18.4700809 },
    { lat: 48.0494659, lng: 18.4707728 },
    { lat: 48.0499984, lng: 18.4722276 },
    { lat: 48.0499659, lng: 18.4726367 },
    { lat: 48.0507653, lng: 18.47388 },
    { lat: 48.0504583, lng: 18.4743416 },
    { lat: 48.0507904, lng: 18.4752978 },
    { lat: 48.0510399, lng: 18.4757488 },
    { lat: 48.0509744, lng: 18.4759268 },
    { lat: 48.0509657, lng: 18.4762798 },
    { lat: 48.0510208, lng: 18.4765596 },
    { lat: 48.05114, lng: 18.4767933 },
    { lat: 48.0513985, lng: 18.4769181 },
    { lat: 48.0511809, lng: 18.477242 },
    { lat: 48.0520665, lng: 18.4789211 },
    { lat: 48.0522571, lng: 18.4795077 },
    { lat: 48.0529953, lng: 18.4823454 },
    { lat: 48.0534082, lng: 18.484775 },
    { lat: 48.0535773, lng: 18.4854807 },
    { lat: 48.0536309, lng: 18.4858136 },
    { lat: 48.0536237, lng: 18.4861304 },
    { lat: 48.053832, lng: 18.4860245 },
    { lat: 48.054138, lng: 18.4856248 },
    { lat: 48.0545219, lng: 18.485268 },
    { lat: 48.0545775, lng: 18.4852516 },
    { lat: 48.0545638, lng: 18.4853274 },
    { lat: 48.0546391, lng: 18.4854062 },
    { lat: 48.054862, lng: 18.4853563 },
    { lat: 48.0555367, lng: 18.4842071 },
    { lat: 48.0563571, lng: 18.4836442 },
    { lat: 48.0566076, lng: 18.4836868 },
    { lat: 48.0568262, lng: 18.4839435 },
    { lat: 48.0569879, lng: 18.484049 },
    { lat: 48.0572328, lng: 18.48391 },
    { lat: 48.0581631, lng: 18.4837626 },
    { lat: 48.0584934, lng: 18.4835774 },
    { lat: 48.058684, lng: 18.4835753 },
    { lat: 48.058781, lng: 18.483979 },
    { lat: 48.0588291, lng: 18.4840541 },
    { lat: 48.0589621, lng: 18.484094 },
    { lat: 48.0591084, lng: 18.4840851 },
    { lat: 48.0595011, lng: 18.4838273 },
    { lat: 48.0597823, lng: 18.4838121 },
    { lat: 48.0599413, lng: 18.4835061 },
    { lat: 48.0602753, lng: 18.4830648 },
    { lat: 48.0604724, lng: 18.4826097 },
    { lat: 48.0604999, lng: 18.4823948 },
    { lat: 48.0605955, lng: 18.4822482 },
    { lat: 48.0610151, lng: 18.4819634 },
    { lat: 48.0610927, lng: 18.4819619 },
    { lat: 48.0611382, lng: 18.4824556 },
    { lat: 48.0613516, lng: 18.4835688 },
    { lat: 48.0617575, lng: 18.4851636 },
    { lat: 48.0632803, lng: 18.4896206 },
    { lat: 48.065969, lng: 18.4968616 },
    { lat: 48.0676605, lng: 18.5011552 },
    { lat: 48.0695427, lng: 18.5052238 },
    { lat: 48.0695579, lng: 18.5052551 },
    { lat: 48.0700504, lng: 18.5050023 },
    { lat: 48.0711158, lng: 18.5040791 },
    { lat: 48.0738176, lng: 18.5010728 },
    { lat: 48.0765517, lng: 18.498228 },
    { lat: 48.0770029, lng: 18.4976797 },
    { lat: 48.0775749, lng: 18.4961986 },
    { lat: 48.0783508, lng: 18.4950996 },
    { lat: 48.0790701, lng: 18.494504 },
    { lat: 48.0802694, lng: 18.493694 },
    { lat: 48.0812711, lng: 18.4957649 },
    { lat: 48.0837347, lng: 18.4995574 },
    { lat: 48.0844558, lng: 18.5006355 },
    { lat: 48.0845005, lng: 18.5005891 },
    { lat: 48.0876436, lng: 18.4973031 },
    { lat: 48.0926571, lng: 18.492218 },
    { lat: 48.0950363, lng: 18.4987952 },
    { lat: 48.095438, lng: 18.4984098 },
    { lat: 48.0959665, lng: 18.4970811 },
    { lat: 48.0960519, lng: 18.4965677 },
    { lat: 48.0965407, lng: 18.4957636 },
    { lat: 48.0973027, lng: 18.4948985 },
    { lat: 48.0987737, lng: 18.4933573 },
    { lat: 48.1024014, lng: 18.4909994 },
  ],
  Pečenice: [
    { lat: 48.3083633, lng: 18.767929 },
    { lat: 48.3083416, lng: 18.7678576 },
    { lat: 48.3078527, lng: 18.7674216 },
    { lat: 48.3074243, lng: 18.7671509 },
    { lat: 48.3055822, lng: 18.7661843 },
    { lat: 48.3053235, lng: 18.7659621 },
    { lat: 48.3050256, lng: 18.765875 },
    { lat: 48.3023736, lng: 18.7646276 },
    { lat: 48.3003325, lng: 18.7638651 },
    { lat: 48.3004392, lng: 18.7632212 },
    { lat: 48.2981663, lng: 18.763125 },
    { lat: 48.2975625, lng: 18.7624386 },
    { lat: 48.2965759, lng: 18.7615489 },
    { lat: 48.2961493, lng: 18.7610752 },
    { lat: 48.2951876, lng: 18.760441 },
    { lat: 48.2939086, lng: 18.7589934 },
    { lat: 48.2932311, lng: 18.7586378 },
    { lat: 48.2927373, lng: 18.7585376 },
    { lat: 48.2925163, lng: 18.7585597 },
    { lat: 48.2923002, lng: 18.7600144 },
    { lat: 48.2919897, lng: 18.7614318 },
    { lat: 48.2914741, lng: 18.7630635 },
    { lat: 48.2910295, lng: 18.7642911 },
    { lat: 48.2909279, lng: 18.7643319 },
    { lat: 48.2908587, lng: 18.7644718 },
    { lat: 48.2905673, lng: 18.7654875 },
    { lat: 48.2904804, lng: 18.76536 },
    { lat: 48.290409, lng: 18.7653498 },
    { lat: 48.2903939, lng: 18.7654089 },
    { lat: 48.2904662, lng: 18.7654917 },
    { lat: 48.2904164, lng: 18.7656588 },
    { lat: 48.2904521, lng: 18.7658872 },
    { lat: 48.2903849, lng: 18.7662596 },
    { lat: 48.2904104, lng: 18.7667172 },
    { lat: 48.2903058, lng: 18.7668944 },
    { lat: 48.2903082, lng: 18.7671306 },
    { lat: 48.2902686, lng: 18.7671279 },
    { lat: 48.2902447, lng: 18.7673365 },
    { lat: 48.2898457, lng: 18.7687048 },
    { lat: 48.2899351, lng: 18.769179 },
    { lat: 48.2899883, lng: 18.7692017 },
    { lat: 48.2901084, lng: 18.7700242 },
    { lat: 48.2900601, lng: 18.7708565 },
    { lat: 48.2899793, lng: 18.7712053 },
    { lat: 48.2900506, lng: 18.7716143 },
    { lat: 48.2904501, lng: 18.7726423 },
    { lat: 48.2909457, lng: 18.7735898 },
    { lat: 48.2910628, lng: 18.774265 },
    { lat: 48.2908115, lng: 18.7749075 },
    { lat: 48.2907454, lng: 18.7760868 },
    { lat: 48.2906508, lng: 18.7765375 },
    { lat: 48.290703, lng: 18.7768617 },
    { lat: 48.2907226, lng: 18.778 },
    { lat: 48.2908515, lng: 18.7784766 },
    { lat: 48.2908244, lng: 18.7788389 },
    { lat: 48.2908521, lng: 18.7793859 },
    { lat: 48.2908059, lng: 18.7794675 },
    { lat: 48.2907729, lng: 18.780083 },
    { lat: 48.2907024, lng: 18.7802631 },
    { lat: 48.2901802, lng: 18.782737 },
    { lat: 48.2898009, lng: 18.7836496 },
    { lat: 48.2895682, lng: 18.7839194 },
    { lat: 48.2896776, lng: 18.7859674 },
    { lat: 48.2895793, lng: 18.7863063 },
    { lat: 48.2900487, lng: 18.787933 },
    { lat: 48.2904432, lng: 18.7898087 },
    { lat: 48.2904696, lng: 18.7908874 },
    { lat: 48.290548, lng: 18.79141 },
    { lat: 48.2907206, lng: 18.7916034 },
    { lat: 48.2905903, lng: 18.7922113 },
    { lat: 48.2905205, lng: 18.7923392 },
    { lat: 48.290347, lng: 18.7930269 },
    { lat: 48.2903651, lng: 18.7935407 },
    { lat: 48.2903102, lng: 18.7949148 },
    { lat: 48.2905111, lng: 18.7989839 },
    { lat: 48.2904387, lng: 18.8018473 },
    { lat: 48.2905176, lng: 18.8027211 },
    { lat: 48.2907744, lng: 18.8044521 },
    { lat: 48.290984, lng: 18.8050481 },
    { lat: 48.2922065, lng: 18.8072131 },
    { lat: 48.2924757, lng: 18.8080393 },
    { lat: 48.2927115, lng: 18.8096917 },
    { lat: 48.2941869, lng: 18.810663 },
    { lat: 48.2944578, lng: 18.8107546 },
    { lat: 48.2946227, lng: 18.8109508 },
    { lat: 48.2946457, lng: 18.8110876 },
    { lat: 48.2945996, lng: 18.8117974 },
    { lat: 48.2946094, lng: 18.812802 },
    { lat: 48.2946567, lng: 18.8131615 },
    { lat: 48.2945663, lng: 18.8135465 },
    { lat: 48.294601, lng: 18.8137103 },
    { lat: 48.2945506, lng: 18.8139748 },
    { lat: 48.2946396, lng: 18.8148762 },
    { lat: 48.2944972, lng: 18.81633 },
    { lat: 48.2945069, lng: 18.8181428 },
    { lat: 48.2944465, lng: 18.8188822 },
    { lat: 48.2943146, lng: 18.819656 },
    { lat: 48.2938924, lng: 18.8210426 },
    { lat: 48.2934227, lng: 18.8220219 },
    { lat: 48.2934172, lng: 18.8224514 },
    { lat: 48.2935488, lng: 18.8229196 },
    { lat: 48.2937701, lng: 18.8233139 },
    { lat: 48.294181, lng: 18.8237499 },
    { lat: 48.2967177, lng: 18.8260567 },
    { lat: 48.2967654, lng: 18.8261686 },
    { lat: 48.2967737, lng: 18.8267598 },
    { lat: 48.2966583, lng: 18.8273127 },
    { lat: 48.2966644, lng: 18.827663 },
    { lat: 48.2971104, lng: 18.8295023 },
    { lat: 48.2972725, lng: 18.8299708 },
    { lat: 48.297258, lng: 18.8313491 },
    { lat: 48.2971609, lng: 18.8319718 },
    { lat: 48.2971019, lng: 18.8320085 },
    { lat: 48.2970507, lng: 18.8324923 },
    { lat: 48.2965327, lng: 18.8328946 },
    { lat: 48.295969, lng: 18.8336268 },
    { lat: 48.2959584, lng: 18.8341135 },
    { lat: 48.2959699, lng: 18.8344865 },
    { lat: 48.2960594, lng: 18.8348372 },
    { lat: 48.296066, lng: 18.8355008 },
    { lat: 48.2962694, lng: 18.8363296 },
    { lat: 48.2966118, lng: 18.836857 },
    { lat: 48.2968725, lng: 18.8374004 },
    { lat: 48.296943, lng: 18.8376276 },
    { lat: 48.2969652, lng: 18.8380277 },
    { lat: 48.2968518, lng: 18.8385977 },
    { lat: 48.2960674, lng: 18.8393182 },
    { lat: 48.2959873, lng: 18.8395982 },
    { lat: 48.2963116, lng: 18.8412702 },
    { lat: 48.2963466, lng: 18.8420477 },
    { lat: 48.2964577, lng: 18.8427245 },
    { lat: 48.297144, lng: 18.844154 },
    { lat: 48.297301, lng: 18.844786 },
    { lat: 48.297436, lng: 18.845353 },
    { lat: 48.29781, lng: 18.846283 },
    { lat: 48.298208, lng: 18.846822 },
    { lat: 48.298418, lng: 18.847307 },
    { lat: 48.298977, lng: 18.8457659 },
    { lat: 48.2989553, lng: 18.8453286 },
    { lat: 48.2990498, lng: 18.8450664 },
    { lat: 48.2996573, lng: 18.8443703 },
    { lat: 48.3001013, lng: 18.843159 },
    { lat: 48.3003355, lng: 18.8428255 },
    { lat: 48.300698, lng: 18.8425357 },
    { lat: 48.3009491, lng: 18.8422274 },
    { lat: 48.3011826, lng: 18.8420785 },
    { lat: 48.3013003, lng: 18.8423005 },
    { lat: 48.3020235, lng: 18.8417462 },
    { lat: 48.3021457, lng: 18.8414942 },
    { lat: 48.3023363, lng: 18.8413018 },
    { lat: 48.3024512, lng: 18.8413627 },
    { lat: 48.3026076, lng: 18.8413174 },
    { lat: 48.3031932, lng: 18.8402646 },
    { lat: 48.3035511, lng: 18.8389814 },
    { lat: 48.303826, lng: 18.8375341 },
    { lat: 48.3034672, lng: 18.8359113 },
    { lat: 48.3028476, lng: 18.8345267 },
    { lat: 48.303302, lng: 18.8337514 },
    { lat: 48.3031951, lng: 18.8317512 },
    { lat: 48.3032215, lng: 18.8306015 },
    { lat: 48.3024636, lng: 18.8297621 },
    { lat: 48.3031743, lng: 18.8284306 },
    { lat: 48.3032077, lng: 18.8280806 },
    { lat: 48.3033098, lng: 18.8277463 },
    { lat: 48.3032437, lng: 18.8273011 },
    { lat: 48.3029067, lng: 18.8265307 },
    { lat: 48.3028514, lng: 18.8262295 },
    { lat: 48.3028938, lng: 18.8257952 },
    { lat: 48.3028729, lng: 18.8255441 },
    { lat: 48.303008, lng: 18.8253046 },
    { lat: 48.3030012, lng: 18.8249817 },
    { lat: 48.3030849, lng: 18.8248557 },
    { lat: 48.3030206, lng: 18.8245771 },
    { lat: 48.3032088, lng: 18.8242088 },
    { lat: 48.3030197, lng: 18.8229256 },
    { lat: 48.3026251, lng: 18.8209547 },
    { lat: 48.3016074, lng: 18.820083 },
    { lat: 48.3011777, lng: 18.8190329 },
    { lat: 48.3017855, lng: 18.814869 },
    { lat: 48.3018283, lng: 18.8148469 },
    { lat: 48.3016233, lng: 18.8142683 },
    { lat: 48.3013285, lng: 18.8137679 },
    { lat: 48.3012177, lng: 18.8134633 },
    { lat: 48.3014394, lng: 18.8130234 },
    { lat: 48.3017023, lng: 18.8127686 },
    { lat: 48.3019241, lng: 18.8127186 },
    { lat: 48.30208, lng: 18.812403 },
    { lat: 48.3021819, lng: 18.8123272 },
    { lat: 48.3023596, lng: 18.8123403 },
    { lat: 48.302462, lng: 18.8119903 },
    { lat: 48.3028529, lng: 18.8116586 },
    { lat: 48.3028504, lng: 18.8114627 },
    { lat: 48.303061, lng: 18.8112841 },
    { lat: 48.3031802, lng: 18.8110883 },
    { lat: 48.3032223, lng: 18.8108779 },
    { lat: 48.3033966, lng: 18.8109788 },
    { lat: 48.3036337, lng: 18.8106837 },
    { lat: 48.3031637, lng: 18.8065703 },
    { lat: 48.3029649, lng: 18.8061794 },
    { lat: 48.3030734, lng: 18.8056725 },
    { lat: 48.3033435, lng: 18.8049322 },
    { lat: 48.3036842, lng: 18.8032204 },
    { lat: 48.3040009, lng: 18.8025124 },
    { lat: 48.3050153, lng: 18.8010439 },
    { lat: 48.3051272, lng: 18.7989734 },
    { lat: 48.3045675, lng: 18.7978555 },
    { lat: 48.3040044, lng: 18.7970356 },
    { lat: 48.3039308, lng: 18.7970698 },
    { lat: 48.3039053, lng: 18.7969308 },
    { lat: 48.3036014, lng: 18.7964079 },
    { lat: 48.3035907, lng: 18.7960333 },
    { lat: 48.3040475, lng: 18.795415 },
    { lat: 48.3041431, lng: 18.7953859 },
    { lat: 48.3044221, lng: 18.7950982 },
    { lat: 48.3048443, lng: 18.7951268 },
    { lat: 48.3048897, lng: 18.7950254 },
    { lat: 48.3052922, lng: 18.7947663 },
    { lat: 48.3055869, lng: 18.7948777 },
    { lat: 48.3057044, lng: 18.7946682 },
    { lat: 48.3058215, lng: 18.7947517 },
    { lat: 48.3059165, lng: 18.7946087 },
    { lat: 48.3060322, lng: 18.7947502 },
    { lat: 48.3061864, lng: 18.7946132 },
    { lat: 48.3063277, lng: 18.7943213 },
    { lat: 48.3064093, lng: 18.7943725 },
    { lat: 48.3065872, lng: 18.7943454 },
    { lat: 48.3070446, lng: 18.7938445 },
    { lat: 48.3073011, lng: 18.7934705 },
    { lat: 48.3075364, lng: 18.7934193 },
    { lat: 48.3078049, lng: 18.7934661 },
    { lat: 48.3080219, lng: 18.7932778 },
    { lat: 48.3081971, lng: 18.7930292 },
    { lat: 48.3083025, lng: 18.7926976 },
    { lat: 48.308651, lng: 18.7921461 },
    { lat: 48.3090258, lng: 18.7906327 },
    { lat: 48.3090874, lng: 18.7900472 },
    { lat: 48.309038, lng: 18.7896276 },
    { lat: 48.3090416, lng: 18.7890098 },
    { lat: 48.3089346, lng: 18.7885644 },
    { lat: 48.3086473, lng: 18.7878447 },
    { lat: 48.3084817, lng: 18.7876975 },
    { lat: 48.3082931, lng: 18.7872451 },
    { lat: 48.3081083, lng: 18.7873121 },
    { lat: 48.307848, lng: 18.787762 },
    { lat: 48.3075249, lng: 18.7880076 },
    { lat: 48.3072299, lng: 18.7880599 },
    { lat: 48.3067767, lng: 18.7879762 },
    { lat: 48.3064946, lng: 18.7877624 },
    { lat: 48.30621, lng: 18.7874122 },
    { lat: 48.3055275, lng: 18.7868064 },
    { lat: 48.305312, lng: 18.7866998 },
    { lat: 48.304683, lng: 18.7860851 },
    { lat: 48.3042067, lng: 18.7853308 },
    { lat: 48.3044746, lng: 18.7848729 },
    { lat: 48.3044106, lng: 18.7832586 },
    { lat: 48.3047368, lng: 18.7827951 },
    { lat: 48.304953, lng: 18.7826774 },
    { lat: 48.3053782, lng: 18.7821253 },
    { lat: 48.3054616, lng: 18.7816765 },
    { lat: 48.3053959, lng: 18.7814796 },
    { lat: 48.3053973, lng: 18.7813154 },
    { lat: 48.3054701, lng: 18.7813064 },
    { lat: 48.3054878, lng: 18.7812119 },
    { lat: 48.3054582, lng: 18.7809062 },
    { lat: 48.3055557, lng: 18.780726 },
    { lat: 48.3057076, lng: 18.7807203 },
    { lat: 48.3057768, lng: 18.7801744 },
    { lat: 48.3062185, lng: 18.7794411 },
    { lat: 48.3063799, lng: 18.778802 },
    { lat: 48.3063938, lng: 18.7766782 },
    { lat: 48.3065642, lng: 18.7755941 },
    { lat: 48.3066341, lng: 18.7746556 },
    { lat: 48.3065716, lng: 18.7741576 },
    { lat: 48.3062937, lng: 18.7739572 },
    { lat: 48.3064814, lng: 18.773175 },
    { lat: 48.3065612, lng: 18.7726172 },
    { lat: 48.3067361, lng: 18.7723852 },
    { lat: 48.307124, lng: 18.77106 },
    { lat: 48.3065321, lng: 18.7706833 },
    { lat: 48.3068398, lng: 18.7698047 },
    { lat: 48.3069811, lng: 18.7692597 },
    { lat: 48.3069615, lng: 18.7691827 },
    { lat: 48.3070201, lng: 18.7690978 },
    { lat: 48.3074497, lng: 18.7695451 },
    { lat: 48.3076289, lng: 18.7692634 },
    { lat: 48.3078067, lng: 18.7687038 },
    { lat: 48.3078559, lng: 18.7683788 },
    { lat: 48.3083633, lng: 18.767929 },
  ],
  Šarovce: [
    { lat: 48.0694779, lng: 18.5952057 },
    { lat: 48.0695051, lng: 18.5952482 },
    { lat: 48.0700271, lng: 18.5960669 },
    { lat: 48.0709056, lng: 18.5978788 },
    { lat: 48.0716878, lng: 18.5991691 },
    { lat: 48.0739389, lng: 18.6036812 },
    { lat: 48.0747601, lng: 18.6060533 },
    { lat: 48.0770717, lng: 18.6121338 },
    { lat: 48.0778468, lng: 18.6139974 },
    { lat: 48.0786807, lng: 18.6163221 },
    { lat: 48.0796115, lng: 18.618641 },
    { lat: 48.0811029, lng: 18.6229942 },
    { lat: 48.0813939, lng: 18.6242753 },
    { lat: 48.0814342, lng: 18.6248337 },
    { lat: 48.0824266, lng: 18.6274375 },
    { lat: 48.0833203, lng: 18.6304738 },
    { lat: 48.0839616, lng: 18.6323298 },
    { lat: 48.0841626, lng: 18.632831 },
    { lat: 48.0847495, lng: 18.6339956 },
    { lat: 48.0854631, lng: 18.635984 },
    { lat: 48.0862742, lng: 18.6380397 },
    { lat: 48.0867439, lng: 18.6391061 },
    { lat: 48.088682, lng: 18.643206 },
    { lat: 48.089648, lng: 18.6450838 },
    { lat: 48.0896424, lng: 18.6453148 },
    { lat: 48.0891931, lng: 18.6455554 },
    { lat: 48.0899932, lng: 18.6473499 },
    { lat: 48.0910217, lng: 18.6490526 },
    { lat: 48.0912401, lng: 18.6495445 },
    { lat: 48.0912193, lng: 18.6497015 },
    { lat: 48.0908526, lng: 18.6494724 },
    { lat: 48.0904873, lng: 18.6494305 },
    { lat: 48.0901301, lng: 18.6494825 },
    { lat: 48.0893999, lng: 18.6499908 },
    { lat: 48.0891042, lng: 18.6501328 },
    { lat: 48.088784, lng: 18.6504272 },
    { lat: 48.0885963, lng: 18.6507751 },
    { lat: 48.0879048, lng: 18.6521843 },
    { lat: 48.0877917, lng: 18.6528873 },
    { lat: 48.0879838, lng: 18.653122 },
    { lat: 48.0882116, lng: 18.6537051 },
    { lat: 48.0882333, lng: 18.6539187 },
    { lat: 48.087919, lng: 18.6546742 },
    { lat: 48.0879082, lng: 18.6554346 },
    { lat: 48.0878134, lng: 18.6558475 },
    { lat: 48.0873675, lng: 18.6566951 },
    { lat: 48.0872008, lng: 18.6571572 },
    { lat: 48.0874577, lng: 18.6573569 },
    { lat: 48.0905931, lng: 18.660876 },
    { lat: 48.0914031, lng: 18.6605483 },
    { lat: 48.0917039, lng: 18.6607706 },
    { lat: 48.0916281, lng: 18.6630591 },
    { lat: 48.0912204, lng: 18.6637369 },
    { lat: 48.0906851, lng: 18.6644322 },
    { lat: 48.0899034, lng: 18.6651201 },
    { lat: 48.0901576, lng: 18.6654405 },
    { lat: 48.0905124, lng: 18.6669545 },
    { lat: 48.0905034, lng: 18.6677049 },
    { lat: 48.0904498, lng: 18.6680903 },
    { lat: 48.0904798, lng: 18.6685195 },
    { lat: 48.09142, lng: 18.6713703 },
    { lat: 48.091522, lng: 18.6722742 },
    { lat: 48.0918736, lng: 18.6735632 },
    { lat: 48.0923894, lng: 18.674659 },
    { lat: 48.0928392, lng: 18.6757624 },
    { lat: 48.0931759, lng: 18.676189 },
    { lat: 48.0938666, lng: 18.676804 },
    { lat: 48.0941046, lng: 18.6773462 },
    { lat: 48.094243, lng: 18.6778838 },
    { lat: 48.0945537, lng: 18.6781005 },
    { lat: 48.0946133, lng: 18.6783734 },
    { lat: 48.0950838, lng: 18.679335 },
    { lat: 48.0954225, lng: 18.6797603 },
    { lat: 48.096472, lng: 18.6807533 },
    { lat: 48.0970601, lng: 18.6819322 },
    { lat: 48.097177, lng: 18.6818434 },
    { lat: 48.0973099, lng: 18.6815871 },
    { lat: 48.0973839, lng: 18.6811612 },
    { lat: 48.0974884, lng: 18.6808607 },
    { lat: 48.0976352, lng: 18.6806776 },
    { lat: 48.0981169, lng: 18.6796953 },
    { lat: 48.0983605, lng: 18.6790909 },
    { lat: 48.0982301, lng: 18.6789517 },
    { lat: 48.0979638, lng: 18.677674 },
    { lat: 48.0980595, lng: 18.6765825 },
    { lat: 48.0984052, lng: 18.6761747 },
    { lat: 48.0985578, lng: 18.676077 },
    { lat: 48.0987767, lng: 18.6762055 },
    { lat: 48.0990706, lng: 18.6762375 },
    { lat: 48.0993702, lng: 18.6766295 },
    { lat: 48.099528, lng: 18.6770229 },
    { lat: 48.099612, lng: 18.6771166 },
    { lat: 48.0996654, lng: 18.6771034 },
    { lat: 48.0999396, lng: 18.6775644 },
    { lat: 48.1001032, lng: 18.6776704 },
    { lat: 48.1006155, lng: 18.6771518 },
    { lat: 48.1008192, lng: 18.6767882 },
    { lat: 48.1013283, lng: 18.6754832 },
    { lat: 48.1017618, lng: 18.6750503 },
    { lat: 48.1022845, lng: 18.6741599 },
    { lat: 48.1025507, lng: 18.6735749 },
    { lat: 48.102723, lng: 18.6729893 },
    { lat: 48.1028202, lng: 18.6728022 },
    { lat: 48.1032723, lng: 18.6723811 },
    { lat: 48.1037008, lng: 18.6723987 },
    { lat: 48.1037957, lng: 18.672341 },
    { lat: 48.1040831, lng: 18.6725683 },
    { lat: 48.104516, lng: 18.6727497 },
    { lat: 48.1049526, lng: 18.6730878 },
    { lat: 48.1051834, lng: 18.673475 },
    { lat: 48.105669, lng: 18.6736974 },
    { lat: 48.1059288, lng: 18.6741012 },
    { lat: 48.1062428, lng: 18.673942 },
    { lat: 48.1064043, lng: 18.6736099 },
    { lat: 48.1065711, lng: 18.6730676 },
    { lat: 48.1076638, lng: 18.6722055 },
    { lat: 48.1076764, lng: 18.6719415 },
    { lat: 48.1077164, lng: 18.6716565 },
    { lat: 48.1081432, lng: 18.6703096 },
    { lat: 48.108229, lng: 18.6697678 },
    { lat: 48.1079562, lng: 18.666932 },
    { lat: 48.1078587, lng: 18.6663187 },
    { lat: 48.1070086, lng: 18.6637586 },
    { lat: 48.1067452, lng: 18.6632882 },
    { lat: 48.1064777, lng: 18.6629704 },
    { lat: 48.1063326, lng: 18.6628736 },
    { lat: 48.1063356, lng: 18.6620556 },
    { lat: 48.1062108, lng: 18.6616666 },
    { lat: 48.1062134, lng: 18.6614839 },
    { lat: 48.1063757, lng: 18.661339 },
    { lat: 48.1063792, lng: 18.6612765 },
    { lat: 48.1061617, lng: 18.6611132 },
    { lat: 48.1058911, lng: 18.6612408 },
    { lat: 48.1059004, lng: 18.660975 },
    { lat: 48.1058483, lng: 18.6607604 },
    { lat: 48.1055423, lng: 18.6606904 },
    { lat: 48.1053784, lng: 18.6604963 },
    { lat: 48.1053381, lng: 18.6602933 },
    { lat: 48.1054025, lng: 18.6600685 },
    { lat: 48.1057375, lng: 18.6596738 },
    { lat: 48.1060335, lng: 18.6591197 },
    { lat: 48.1061188, lng: 18.6587583 },
    { lat: 48.1061277, lng: 18.6584202 },
    { lat: 48.105997, lng: 18.6582151 },
    { lat: 48.1059443, lng: 18.6579525 },
    { lat: 48.1058445, lng: 18.657756 },
    { lat: 48.1058048, lng: 18.6575636 },
    { lat: 48.1058437, lng: 18.6574703 },
    { lat: 48.1063448, lng: 18.657642 },
    { lat: 48.1066367, lng: 18.6575764 },
    { lat: 48.1068415, lng: 18.6571685 },
    { lat: 48.1068769, lng: 18.6567008 },
    { lat: 48.1067755, lng: 18.6566774 },
    { lat: 48.1066668, lng: 18.6563603 },
    { lat: 48.1065947, lng: 18.6559007 },
    { lat: 48.1063295, lng: 18.6555703 },
    { lat: 48.1063256, lng: 18.6551388 },
    { lat: 48.1064586, lng: 18.6549505 },
    { lat: 48.1067249, lng: 18.6547264 },
    { lat: 48.1069316, lng: 18.654712 },
    { lat: 48.1077518, lng: 18.6548798 },
    { lat: 48.107881, lng: 18.6549951 },
    { lat: 48.1079386, lng: 18.6551635 },
    { lat: 48.1081516, lng: 18.6553051 },
    { lat: 48.10841, lng: 18.6555985 },
    { lat: 48.1088113, lng: 18.6558677 },
    { lat: 48.1092002, lng: 18.6563197 },
    { lat: 48.1102048, lng: 18.6560859 },
    { lat: 48.110151, lng: 18.6559886 },
    { lat: 48.1105218, lng: 18.6552758 },
    { lat: 48.1106186, lng: 18.6551868 },
    { lat: 48.11097, lng: 18.6551987 },
    { lat: 48.1112401, lng: 18.654438 },
    { lat: 48.1110839, lng: 18.6536367 },
    { lat: 48.1111245, lng: 18.6534486 },
    { lat: 48.1111835, lng: 18.6533511 },
    { lat: 48.111527, lng: 18.6531953 },
    { lat: 48.1117859, lng: 18.6527518 },
    { lat: 48.1124096, lng: 18.6509379 },
    { lat: 48.112532, lng: 18.6502829 },
    { lat: 48.1127397, lng: 18.6499539 },
    { lat: 48.1133881, lng: 18.6496807 },
    { lat: 48.1137245, lng: 18.6484795 },
    { lat: 48.1138116, lng: 18.6480063 },
    { lat: 48.1141307, lng: 18.6484209 },
    { lat: 48.115, lng: 18.6476455 },
    { lat: 48.1152352, lng: 18.6471148 },
    { lat: 48.1154603, lng: 18.6463803 },
    { lat: 48.1155747, lng: 18.6455127 },
    { lat: 48.115754, lng: 18.6434145 },
    { lat: 48.1157712, lng: 18.6416339 },
    { lat: 48.1158979, lng: 18.6401396 },
    { lat: 48.1165801, lng: 18.6390352 },
    { lat: 48.1173306, lng: 18.6381109 },
    { lat: 48.1173553, lng: 18.638151 },
    { lat: 48.1187839, lng: 18.6358781 },
    { lat: 48.1173427, lng: 18.632834 },
    { lat: 48.1167749, lng: 18.6317677 },
    { lat: 48.1171761, lng: 18.631096 },
    { lat: 48.1173955, lng: 18.6309413 },
    { lat: 48.117592, lng: 18.630694 },
    { lat: 48.1182914, lng: 18.6296392 },
    { lat: 48.1186922, lng: 18.6281116 },
    { lat: 48.1192537, lng: 18.6269902 },
    { lat: 48.120236, lng: 18.6240633 },
    { lat: 48.1208181, lng: 18.622989 },
    { lat: 48.1210257, lng: 18.622722 },
    { lat: 48.1219711, lng: 18.6223294 },
    { lat: 48.1227274, lng: 18.6217825 },
    { lat: 48.1232329, lng: 18.6209568 },
    { lat: 48.123684, lng: 18.6200209 },
    { lat: 48.1238373, lng: 18.6196284 },
    { lat: 48.1238941, lng: 18.6194704 },
    { lat: 48.1239872, lng: 18.6189829 },
    { lat: 48.1240161, lng: 18.6183944 },
    { lat: 48.1236812, lng: 18.6167423 },
    { lat: 48.1234641, lng: 18.6148705 },
    { lat: 48.123548, lng: 18.6139037 },
    { lat: 48.1239272, lng: 18.6128525 },
    { lat: 48.1241622, lng: 18.6125492 },
    { lat: 48.1246801, lng: 18.6121385 },
    { lat: 48.125659, lng: 18.6119287 },
    { lat: 48.1264181, lng: 18.6119313 },
    { lat: 48.1265631, lng: 18.6120019 },
    { lat: 48.126828, lng: 18.6123316 },
    { lat: 48.1273501, lng: 18.6130935 },
    { lat: 48.1276116, lng: 18.6132555 },
    { lat: 48.1279526, lng: 18.613346 },
    { lat: 48.127852, lng: 18.6109406 },
    { lat: 48.1273356, lng: 18.6101406 },
    { lat: 48.1270251, lng: 18.6094317 },
    { lat: 48.1264935, lng: 18.6088249 },
    { lat: 48.1261481, lng: 18.6074763 },
    { lat: 48.1260895, lng: 18.6074767 },
    { lat: 48.1250705, lng: 18.6060411 },
    { lat: 48.1234175, lng: 18.6034499 },
    { lat: 48.1234503, lng: 18.6031201 },
    { lat: 48.1223074, lng: 18.6008641 },
    { lat: 48.1211764, lng: 18.5984546 },
    { lat: 48.1201228, lng: 18.5964552 },
    { lat: 48.119428, lng: 18.5952596 },
    { lat: 48.1185563, lng: 18.5940238 },
    { lat: 48.117546, lng: 18.5922603 },
    { lat: 48.1164153, lng: 18.5904767 },
    { lat: 48.1148191, lng: 18.5879808 },
    { lat: 48.1132949, lng: 18.5857304 },
    { lat: 48.1110929, lng: 18.5822695 },
    { lat: 48.1099138, lng: 18.5802846 },
    { lat: 48.1097567, lng: 18.5799498 },
    { lat: 48.1087241, lng: 18.5803747 },
    { lat: 48.1081757, lng: 18.5805977 },
    { lat: 48.1079516, lng: 18.5805199 },
    { lat: 48.1078252, lng: 18.58015 },
    { lat: 48.107715, lng: 18.5801137 },
    { lat: 48.1076313, lng: 18.5801751 },
    { lat: 48.1075769, lng: 18.5803716 },
    { lat: 48.107601, lng: 18.5806097 },
    { lat: 48.1075559, lng: 18.5807514 },
    { lat: 48.1073567, lng: 18.5811277 },
    { lat: 48.1071265, lng: 18.581417 },
    { lat: 48.1065908, lng: 18.5819109 },
    { lat: 48.1061685, lng: 18.5820461 },
    { lat: 48.1054847, lng: 18.582724 },
    { lat: 48.1052533, lng: 18.5830903 },
    { lat: 48.1027758, lng: 18.58382 },
    { lat: 48.1017522, lng: 18.5849868 },
    { lat: 48.1014605, lng: 18.5854487 },
    { lat: 48.1012731, lng: 18.5858163 },
    { lat: 48.1008645, lng: 18.5862233 },
    { lat: 48.1007446, lng: 18.5862559 },
    { lat: 48.1005712, lng: 18.586202 },
    { lat: 48.0999204, lng: 18.586304 },
    { lat: 48.0994485, lng: 18.5867893 },
    { lat: 48.0993606, lng: 18.5869571 },
    { lat: 48.0993193, lng: 18.5877264 },
    { lat: 48.099107, lng: 18.5881309 },
    { lat: 48.0983494, lng: 18.5870904 },
    { lat: 48.0963895, lng: 18.5849991 },
    { lat: 48.096261, lng: 18.5849785 },
    { lat: 48.0953953, lng: 18.5861847 },
    { lat: 48.0930782, lng: 18.5901764 },
    { lat: 48.0923613, lng: 18.5922876 },
    { lat: 48.0918053, lng: 18.591876 },
    { lat: 48.089756, lng: 18.5894715 },
    { lat: 48.0883879, lng: 18.5916966 },
    { lat: 48.0879803, lng: 18.5923481 },
    { lat: 48.0878346, lng: 18.5921807 },
    { lat: 48.085129, lng: 18.5886225 },
    { lat: 48.0805223, lng: 18.5828399 },
    { lat: 48.0794178, lng: 18.5815166 },
    { lat: 48.0789536, lng: 18.5811377 },
    { lat: 48.0786178, lng: 18.5807734 },
    { lat: 48.0785144, lng: 18.5810897 },
    { lat: 48.0777575, lng: 18.5814993 },
    { lat: 48.0776638, lng: 18.5816606 },
    { lat: 48.0773945, lng: 18.5819126 },
    { lat: 48.0772393, lng: 18.5822941 },
    { lat: 48.0772373, lng: 18.5826284 },
    { lat: 48.077541, lng: 18.5832703 },
    { lat: 48.0774252, lng: 18.5837112 },
    { lat: 48.0774307, lng: 18.5840957 },
    { lat: 48.0773524, lng: 18.5845433 },
    { lat: 48.0771281, lng: 18.5846254 },
    { lat: 48.0770586, lng: 18.5847799 },
    { lat: 48.0771486, lng: 18.585353 },
    { lat: 48.0769951, lng: 18.5857004 },
    { lat: 48.0768465, lng: 18.5859291 },
    { lat: 48.076326, lng: 18.5862914 },
    { lat: 48.0762567, lng: 18.5865111 },
    { lat: 48.0755384, lng: 18.5878911 },
    { lat: 48.0740825, lng: 18.5888859 },
    { lat: 48.0716961, lng: 18.5915088 },
    { lat: 48.0716733, lng: 18.5915441 },
    { lat: 48.0716308, lng: 18.5916311 },
    { lat: 48.0716057, lng: 18.5923687 },
    { lat: 48.0714572, lng: 18.5926221 },
    { lat: 48.0713432, lng: 18.5927037 },
    { lat: 48.0709314, lng: 18.5926421 },
    { lat: 48.0704338, lng: 18.5929683 },
    { lat: 48.0702761, lng: 18.5931367 },
    { lat: 48.0701801, lng: 18.593403 },
    { lat: 48.0700437, lng: 18.5935677 },
    { lat: 48.0698454, lng: 18.5937577 },
    { lat: 48.0695666, lng: 18.5938597 },
    { lat: 48.0694359, lng: 18.5940061 },
    { lat: 48.0693687, lng: 18.5942806 },
    { lat: 48.0694896, lng: 18.5949221 },
    { lat: 48.0694779, lng: 18.5952057 },
  ],
  Domadice: [
    { lat: 48.2170983, lng: 18.7668197 },
    { lat: 48.2164973, lng: 18.7667349 },
    { lat: 48.2155568, lng: 18.7664821 },
    { lat: 48.2144177, lng: 18.766099 },
    { lat: 48.2129697, lng: 18.7642108 },
    { lat: 48.2112903, lng: 18.7668405 },
    { lat: 48.2110864, lng: 18.7665257 },
    { lat: 48.2107811, lng: 18.7668515 },
    { lat: 48.207915, lng: 18.7698836 },
    { lat: 48.2077737, lng: 18.7695769 },
    { lat: 48.2047367, lng: 18.7708994 },
    { lat: 48.2011927, lng: 18.7723315 },
    { lat: 48.200818, lng: 18.7723293 },
    { lat: 48.200163, lng: 18.772161 },
    { lat: 48.1991069, lng: 18.771725 },
    { lat: 48.1947924, lng: 18.7707098 },
    { lat: 48.1941935, lng: 18.7699262 },
    { lat: 48.1936106, lng: 18.7694829 },
    { lat: 48.1929382, lng: 18.7690707 },
    { lat: 48.1915714, lng: 18.7686718 },
    { lat: 48.1911993, lng: 18.76846 },
    { lat: 48.1895982, lng: 18.7666579 },
    { lat: 48.188939, lng: 18.7660947 },
    { lat: 48.1878328, lng: 18.7653981 },
    { lat: 48.1873094, lng: 18.7651408 },
    { lat: 48.1863744, lng: 18.7650133 },
    { lat: 48.185947, lng: 18.7648859 },
    { lat: 48.1854739, lng: 18.7646209 },
    { lat: 48.1848683, lng: 18.763897 },
    { lat: 48.1843351, lng: 18.7634373 },
    { lat: 48.1840618, lng: 18.7630666 },
    { lat: 48.1839179, lng: 18.7628452 },
    { lat: 48.1833703, lng: 18.7616221 },
    { lat: 48.1831555, lng: 18.7612925 },
    { lat: 48.1829001, lng: 18.7610723 },
    { lat: 48.182064, lng: 18.760871 },
    { lat: 48.1805626, lng: 18.7609964 },
    { lat: 48.1801704, lng: 18.760936 },
    { lat: 48.1796078, lng: 18.7606827 },
    { lat: 48.1793741, lng: 18.7606578 },
    { lat: 48.1780555, lng: 18.7608019 },
    { lat: 48.1773634, lng: 18.7609148 },
    { lat: 48.1767794, lng: 18.7610023 },
    { lat: 48.1757578, lng: 18.7613901 },
    { lat: 48.1753952, lng: 18.7618247 },
    { lat: 48.1750004, lng: 18.7624161 },
    { lat: 48.1732714, lng: 18.7629455 },
    { lat: 48.1731489, lng: 18.7633738 },
    { lat: 48.1722356, lng: 18.7640398 },
    { lat: 48.1689399, lng: 18.7661972 },
    { lat: 48.1684752, lng: 18.7663647 },
    { lat: 48.167682, lng: 18.766803 },
    { lat: 48.1667397, lng: 18.7669004 },
    { lat: 48.1660543, lng: 18.7670725 },
    { lat: 48.1647981, lng: 18.7670955 },
    { lat: 48.1619369, lng: 18.7672854 },
    { lat: 48.1613562, lng: 18.7674147 },
    { lat: 48.1580617, lng: 18.7687289 },
    { lat: 48.1582691, lng: 18.7700617 },
    { lat: 48.1583654, lng: 18.7709852 },
    { lat: 48.1583698, lng: 18.7716416 },
    { lat: 48.1582352, lng: 18.7726547 },
    { lat: 48.1585251, lng: 18.7730587 },
    { lat: 48.158597, lng: 18.7737318 },
    { lat: 48.1584899, lng: 18.7739229 },
    { lat: 48.1585764, lng: 18.7746385 },
    { lat: 48.1587568, lng: 18.775001 },
    { lat: 48.1588413, lng: 18.7755516 },
    { lat: 48.1590357, lng: 18.777047 },
    { lat: 48.1590542, lng: 18.7778858 },
    { lat: 48.1589968, lng: 18.7789522 },
    { lat: 48.1586354, lng: 18.7788788 },
    { lat: 48.1584332, lng: 18.7793806 },
    { lat: 48.1583011, lng: 18.7800797 },
    { lat: 48.1585802, lng: 18.7805161 },
    { lat: 48.1592122, lng: 18.7807814 },
    { lat: 48.1601822, lng: 18.7809099 },
    { lat: 48.1605222, lng: 18.7812204 },
    { lat: 48.1607103, lng: 18.781723 },
    { lat: 48.1607687, lng: 18.7821653 },
    { lat: 48.1607286, lng: 18.7825826 },
    { lat: 48.1605489, lng: 18.7833835 },
    { lat: 48.1601484, lng: 18.7846993 },
    { lat: 48.1600506, lng: 18.785726 },
    { lat: 48.1600768, lng: 18.7860966 },
    { lat: 48.1601808, lng: 18.786588 },
    { lat: 48.1605216, lng: 18.7873607 },
    { lat: 48.160352, lng: 18.7880533 },
    { lat: 48.160786, lng: 18.7883736 },
    { lat: 48.1603973, lng: 18.7894859 },
    { lat: 48.1611156, lng: 18.7923408 },
    { lat: 48.160892, lng: 18.7928056 },
    { lat: 48.1610827, lng: 18.7930193 },
    { lat: 48.1600284, lng: 18.794898 },
    { lat: 48.1594637, lng: 18.7960352 },
    { lat: 48.1590399, lng: 18.7970174 },
    { lat: 48.1606963, lng: 18.7982749 },
    { lat: 48.1602955, lng: 18.7998452 },
    { lat: 48.1608276, lng: 18.8013556 },
    { lat: 48.1609804, lng: 18.8016665 },
    { lat: 48.1626578, lng: 18.8043427 },
    { lat: 48.1628904, lng: 18.8048822 },
    { lat: 48.162965, lng: 18.8050585 },
    { lat: 48.1634993, lng: 18.80736 },
    { lat: 48.1638517, lng: 18.8070934 },
    { lat: 48.164461, lng: 18.8077838 },
    { lat: 48.164829, lng: 18.808852 },
    { lat: 48.165309, lng: 18.808623 },
    { lat: 48.166367, lng: 18.808156 },
    { lat: 48.166675, lng: 18.80802 },
    { lat: 48.167101, lng: 18.807412 },
    { lat: 48.16769, lng: 18.807019 },
    { lat: 48.168066, lng: 18.806767 },
    { lat: 48.168446, lng: 18.80653 },
    { lat: 48.168591, lng: 18.806436 },
    { lat: 48.169217, lng: 18.806018 },
    { lat: 48.169632, lng: 18.805744 },
    { lat: 48.169434, lng: 18.804665 },
    { lat: 48.169449, lng: 18.80466 },
    { lat: 48.170529, lng: 18.804318 },
    { lat: 48.170879, lng: 18.804093 },
    { lat: 48.170902, lng: 18.803693 },
    { lat: 48.171374, lng: 18.803239 },
    { lat: 48.171846, lng: 18.802814 },
    { lat: 48.173137, lng: 18.802188 },
    { lat: 48.173634, lng: 18.802042 },
    { lat: 48.174389, lng: 18.801986 },
    { lat: 48.174709, lng: 18.801982 },
    { lat: 48.175395, lng: 18.802236 },
    { lat: 48.176246, lng: 18.802556 },
    { lat: 48.17886, lng: 18.80276 },
    { lat: 48.179765, lng: 18.802215 },
    { lat: 48.180695, lng: 18.8017 },
    { lat: 48.181623, lng: 18.801631 },
    { lat: 48.181907, lng: 18.801926 },
    { lat: 48.18229, lng: 18.801863 },
    { lat: 48.183764, lng: 18.801029 },
    { lat: 48.18459, lng: 18.800517 },
    { lat: 48.184768, lng: 18.800329 },
    { lat: 48.185488, lng: 18.799776 },
    { lat: 48.187255, lng: 18.798495 },
    { lat: 48.187293, lng: 18.798467 },
    { lat: 48.187383, lng: 18.798402 },
    { lat: 48.1881559, lng: 18.7959758 },
    { lat: 48.1891756, lng: 18.793508 },
    { lat: 48.1893794, lng: 18.7929679 },
    { lat: 48.1895453, lng: 18.7927243 },
    { lat: 48.190067, lng: 18.7918313 },
    { lat: 48.1903428, lng: 18.7914465 },
    { lat: 48.1912362, lng: 18.7896205 },
    { lat: 48.1916846, lng: 18.7884057 },
    { lat: 48.1936757, lng: 18.7902303 },
    { lat: 48.1948661, lng: 18.7910081 },
    { lat: 48.1958603, lng: 18.7918087 },
    { lat: 48.1978193, lng: 18.7922599 },
    { lat: 48.199298, lng: 18.7930956 },
    { lat: 48.2001433, lng: 18.7942406 },
    { lat: 48.2009683, lng: 18.7951721 },
    { lat: 48.2018017, lng: 18.7932286 },
    { lat: 48.2024968, lng: 18.7941337 },
    { lat: 48.2038796, lng: 18.7952224 },
    { lat: 48.2065373, lng: 18.7909916 },
    { lat: 48.2074692, lng: 18.7895079 },
    { lat: 48.2077346, lng: 18.7893202 },
    { lat: 48.207937, lng: 18.7893101 },
    { lat: 48.208474, lng: 18.7889319 },
    { lat: 48.2095348, lng: 18.78756 },
    { lat: 48.211247, lng: 18.7826177 },
    { lat: 48.2105444, lng: 18.7814476 },
    { lat: 48.2166883, lng: 18.7677422 },
    { lat: 48.2170983, lng: 18.7668197 },
  ],
  Beša: [
    { lat: 48.141614, lng: 18.386987 },
    { lat: 48.141864, lng: 18.387263 },
    { lat: 48.141938, lng: 18.387336 },
    { lat: 48.141958, lng: 18.387689 },
    { lat: 48.141959, lng: 18.387708 },
    { lat: 48.141902, lng: 18.388108 },
    { lat: 48.141845, lng: 18.388467 },
    { lat: 48.141715, lng: 18.389132 },
    { lat: 48.141447, lng: 18.389947 },
    { lat: 48.141133, lng: 18.390829 },
    { lat: 48.140929, lng: 18.391326 },
    { lat: 48.14067, lng: 18.392031 },
    { lat: 48.140309, lng: 18.393071 },
    { lat: 48.140301, lng: 18.393096 },
    { lat: 48.140284, lng: 18.393086 },
    { lat: 48.14019, lng: 18.393412 },
    { lat: 48.139893, lng: 18.394491 },
    { lat: 48.139845, lng: 18.394676 },
    { lat: 48.139824, lng: 18.394744 },
    { lat: 48.139784, lng: 18.394849 },
    { lat: 48.139681, lng: 18.395143 },
    { lat: 48.139665, lng: 18.395135 },
    { lat: 48.139264, lng: 18.394894 },
    { lat: 48.139241, lng: 18.394973 },
    { lat: 48.139046, lng: 18.395796 },
    { lat: 48.138908, lng: 18.396369 },
    { lat: 48.138895, lng: 18.396379 },
    { lat: 48.138684, lng: 18.396896 },
    { lat: 48.137345, lng: 18.396221 },
    { lat: 48.137206, lng: 18.396155 },
    { lat: 48.137172, lng: 18.396283 },
    { lat: 48.13715, lng: 18.396375 },
    { lat: 48.137092, lng: 18.396647 },
    { lat: 48.136935, lng: 18.397386 },
    { lat: 48.136906, lng: 18.397504 },
    { lat: 48.136841, lng: 18.397816 },
    { lat: 48.136616, lng: 18.39884 },
    { lat: 48.137533, lng: 18.399292 },
    { lat: 48.13759, lng: 18.399341 },
    { lat: 48.137794, lng: 18.399617 },
    { lat: 48.137898, lng: 18.399751 },
    { lat: 48.138048, lng: 18.39998 },
    { lat: 48.138398, lng: 18.400508 },
    { lat: 48.138683, lng: 18.400827 },
    { lat: 48.13868, lng: 18.400845 },
    { lat: 48.138656, lng: 18.400974 },
    { lat: 48.138651, lng: 18.400999 },
    { lat: 48.138645, lng: 18.401027 },
    { lat: 48.138637, lng: 18.401067 },
    { lat: 48.138437, lng: 18.401969 },
    { lat: 48.138375, lng: 18.402254 },
    { lat: 48.13833, lng: 18.402454 },
    { lat: 48.138295, lng: 18.402602 },
    { lat: 48.138265, lng: 18.402754 },
    { lat: 48.138248, lng: 18.402825 },
    { lat: 48.138236, lng: 18.402898 },
    { lat: 48.138138, lng: 18.403418 },
    { lat: 48.138097, lng: 18.40378 },
    { lat: 48.13798, lng: 18.404582 },
    { lat: 48.137968, lng: 18.404615 },
    { lat: 48.137768, lng: 18.405625 },
    { lat: 48.137603, lng: 18.405852 },
    { lat: 48.137592, lng: 18.405873 },
    { lat: 48.137396, lng: 18.406488 },
    { lat: 48.137383, lng: 18.406502 },
    { lat: 48.137381, lng: 18.406536 },
    { lat: 48.137463, lng: 18.406801 },
    { lat: 48.13752, lng: 18.406995 },
    { lat: 48.137165, lng: 18.407591 },
    { lat: 48.137181, lng: 18.40778 },
    { lat: 48.137204, lng: 18.407989 },
    { lat: 48.137223, lng: 18.40821 },
    { lat: 48.137278, lng: 18.408786 },
    { lat: 48.137361, lng: 18.409646 },
    { lat: 48.137342, lng: 18.410059 },
    { lat: 48.137293, lng: 18.410649 },
    { lat: 48.137475, lng: 18.411884 },
    { lat: 48.137511, lng: 18.412317 },
    { lat: 48.1375, lng: 18.412595 },
    { lat: 48.137493, lng: 18.412715 },
    { lat: 48.137391, lng: 18.413865 },
    { lat: 48.137309, lng: 18.41438 },
    { lat: 48.137207, lng: 18.414867 },
    { lat: 48.137175, lng: 18.415032 },
    { lat: 48.137168, lng: 18.415125 },
    { lat: 48.137143, lng: 18.415681 },
    { lat: 48.136758, lng: 18.416414 },
    { lat: 48.136674, lng: 18.416785 },
    { lat: 48.136704, lng: 18.417162 },
    { lat: 48.136766, lng: 18.418002 },
    { lat: 48.136768, lng: 18.418343 },
    { lat: 48.136784, lng: 18.419002 },
    { lat: 48.136778, lng: 18.419078 },
    { lat: 48.136652, lng: 18.420068 },
    { lat: 48.136647, lng: 18.420087 },
    { lat: 48.136414, lng: 18.421062 },
    { lat: 48.136364, lng: 18.421262 },
    { lat: 48.136363, lng: 18.421299 },
    { lat: 48.136313, lng: 18.421692 },
    { lat: 48.13628, lng: 18.421919 },
    { lat: 48.136171, lng: 18.423039 },
    { lat: 48.136047, lng: 18.423754 },
    { lat: 48.136012, lng: 18.424031 },
    { lat: 48.135927, lng: 18.424636 },
    { lat: 48.135903, lng: 18.424982 },
    { lat: 48.135886, lng: 18.425165 },
    { lat: 48.1362404, lng: 18.4260935 },
    { lat: 48.1381875, lng: 18.4261916 },
    { lat: 48.1386845, lng: 18.4261004 },
    { lat: 48.1392236, lng: 18.4259385 },
    { lat: 48.1396051, lng: 18.4256802 },
    { lat: 48.1400695, lng: 18.4251691 },
    { lat: 48.140648, lng: 18.4247027 },
    { lat: 48.1414021, lng: 18.4243896 },
    { lat: 48.141958, lng: 18.4238663 },
    { lat: 48.1433118, lng: 18.4241041 },
    { lat: 48.1440558, lng: 18.4234103 },
    { lat: 48.1444322, lng: 18.4246306 },
    { lat: 48.1446772, lng: 18.4262608 },
    { lat: 48.1446053, lng: 18.42699 },
    { lat: 48.1453709, lng: 18.4275857 },
    { lat: 48.1459301, lng: 18.4272369 },
    { lat: 48.1463268, lng: 18.4272663 },
    { lat: 48.146413, lng: 18.4267358 },
    { lat: 48.1475199, lng: 18.4266985 },
    { lat: 48.1486688, lng: 18.4267431 },
    { lat: 48.151046, lng: 18.4288555 },
    { lat: 48.1537053, lng: 18.4319562 },
    { lat: 48.155179, lng: 18.427599 },
    { lat: 48.155688, lng: 18.427061 },
    { lat: 48.1562934, lng: 18.426252 },
    { lat: 48.1566637, lng: 18.425554 },
    { lat: 48.1549748, lng: 18.4215766 },
    { lat: 48.1537524, lng: 18.4223861 },
    { lat: 48.1515922, lng: 18.4152692 },
    { lat: 48.1553099, lng: 18.4115072 },
    { lat: 48.1563834, lng: 18.409713 },
    { lat: 48.1568119, lng: 18.4095889 },
    { lat: 48.1573513, lng: 18.4092899 },
    { lat: 48.1592216, lng: 18.4080227 },
    { lat: 48.1606506, lng: 18.4066345 },
    { lat: 48.1606089, lng: 18.4065754 },
    { lat: 48.1605757, lng: 18.4066057 },
    { lat: 48.1602567, lng: 18.4059886 },
    { lat: 48.1596804, lng: 18.4042509 },
    { lat: 48.1592332, lng: 18.4031412 },
    { lat: 48.158976, lng: 18.4026212 },
    { lat: 48.1585747, lng: 18.4020658 },
    { lat: 48.1586376, lng: 18.4018441 },
    { lat: 48.1589746, lng: 18.400995 },
    { lat: 48.1601845, lng: 18.3984448 },
    { lat: 48.1613216, lng: 18.3954421 },
    { lat: 48.1620577, lng: 18.3937313 },
    { lat: 48.1625586, lng: 18.3935494 },
    { lat: 48.1635984, lng: 18.3929815 },
    { lat: 48.163982, lng: 18.3925653 },
    { lat: 48.1644778, lng: 18.3918911 },
    { lat: 48.1650456, lng: 18.3915594 },
    { lat: 48.165288, lng: 18.3913546 },
    { lat: 48.1652714, lng: 18.3912998 },
    { lat: 48.1644902, lng: 18.3914534 },
    { lat: 48.1631869, lng: 18.3911906 },
    { lat: 48.1625123, lng: 18.3907311 },
    { lat: 48.1617159, lng: 18.3900714 },
    { lat: 48.1607668, lng: 18.3889102 },
    { lat: 48.1604774, lng: 18.388239 },
    { lat: 48.1603574, lng: 18.3877142 },
    { lat: 48.1602151, lng: 18.3873505 },
    { lat: 48.1596922, lng: 18.3866638 },
    { lat: 48.1595257, lng: 18.3861322 },
    { lat: 48.1594053, lng: 18.3851806 },
    { lat: 48.1591587, lng: 18.3856873 },
    { lat: 48.1582974, lng: 18.3863179 },
    { lat: 48.1578155, lng: 18.386413 },
    { lat: 48.1576446, lng: 18.3863837 },
    { lat: 48.1574391, lng: 18.3860432 },
    { lat: 48.1569326, lng: 18.3850637 },
    { lat: 48.1566034, lng: 18.3840693 },
    { lat: 48.1560977, lng: 18.3829484 },
    { lat: 48.1556562, lng: 18.3821657 },
    { lat: 48.1555207, lng: 18.3813652 },
    { lat: 48.1555046, lng: 18.3809592 },
    { lat: 48.155776, lng: 18.3797554 },
    { lat: 48.1559604, lng: 18.3780675 },
    { lat: 48.1561725, lng: 18.3766313 },
    { lat: 48.1564161, lng: 18.3757917 },
    { lat: 48.156451, lng: 18.374806 },
    { lat: 48.154892, lng: 18.374639 },
    { lat: 48.154823, lng: 18.374636 },
    { lat: 48.154822, lng: 18.375021 },
    { lat: 48.154817, lng: 18.375367 },
    { lat: 48.15479, lng: 18.375691 },
    { lat: 48.154761, lng: 18.376178 },
    { lat: 48.15476, lng: 18.376346 },
    { lat: 48.154768, lng: 18.376694 },
    { lat: 48.15473, lng: 18.37706 },
    { lat: 48.154706, lng: 18.377421 },
    { lat: 48.154719, lng: 18.377665 },
    { lat: 48.154702, lng: 18.377754 },
    { lat: 48.154674, lng: 18.377769 },
    { lat: 48.154452, lng: 18.377609 },
    { lat: 48.154159, lng: 18.377378 },
    { lat: 48.153991, lng: 18.377247 },
    { lat: 48.153919, lng: 18.377156 },
    { lat: 48.153897, lng: 18.377136 },
    { lat: 48.153547, lng: 18.377029 },
    { lat: 48.153526, lng: 18.376931 },
    { lat: 48.153489, lng: 18.376983 },
    { lat: 48.153046, lng: 18.37736 },
    { lat: 48.153132, lng: 18.377475 },
    { lat: 48.153098, lng: 18.377609 },
    { lat: 48.153109, lng: 18.377747 },
    { lat: 48.1531, lng: 18.378339 },
    { lat: 48.153045, lng: 18.379017 },
    { lat: 48.152982, lng: 18.37922 },
    { lat: 48.152924, lng: 18.37944 },
    { lat: 48.152811, lng: 18.380247 },
    { lat: 48.152771, lng: 18.380383 },
    { lat: 48.152705, lng: 18.380646 },
    { lat: 48.152633, lng: 18.381032 },
    { lat: 48.152261, lng: 18.380996 },
    { lat: 48.151862, lng: 18.380946 },
    { lat: 48.151245, lng: 18.380946 },
    { lat: 48.15098, lng: 18.380975 },
    { lat: 48.150654, lng: 18.380993 },
    { lat: 48.149992, lng: 18.380992 },
    { lat: 48.149492, lng: 18.380941 },
    { lat: 48.149141, lng: 18.380882 },
    { lat: 48.149106, lng: 18.381011 },
    { lat: 48.148862, lng: 18.381025 },
    { lat: 48.148626, lng: 18.381049 },
    { lat: 48.148419, lng: 18.381089 },
    { lat: 48.148106, lng: 18.381202 },
    { lat: 48.147906, lng: 18.381374 },
    { lat: 48.14748, lng: 18.381837 },
    { lat: 48.147155, lng: 18.382079 },
    { lat: 48.146838, lng: 18.382331 },
    { lat: 48.146746, lng: 18.382367 },
    { lat: 48.146246, lng: 18.382849 },
    { lat: 48.145714, lng: 18.383326 },
    { lat: 48.145612, lng: 18.383412 },
    { lat: 48.145443, lng: 18.383535 },
    { lat: 48.145255, lng: 18.383672 },
    { lat: 48.14501, lng: 18.383813 },
    { lat: 48.144906, lng: 18.383872 },
    { lat: 48.144844, lng: 18.383908 },
    { lat: 48.144455, lng: 18.384178 },
    { lat: 48.14428, lng: 18.384275 },
    { lat: 48.144211, lng: 18.384296 },
    { lat: 48.144173, lng: 18.384301 },
    { lat: 48.144113, lng: 18.38429 },
    { lat: 48.143815, lng: 18.384189 },
    { lat: 48.143543, lng: 18.384077 },
    { lat: 48.143345, lng: 18.384016 },
    { lat: 48.142657, lng: 18.383801 },
    { lat: 48.141822, lng: 18.386003 },
    { lat: 48.141614, lng: 18.386987 },
  ],
  IpeľskéÚľany: [
    { lat: 48.150198, lng: 19.060484 },
    { lat: 48.150537, lng: 19.060344 },
    { lat: 48.15063, lng: 19.060232 },
    { lat: 48.150797, lng: 19.059986 },
    { lat: 48.15083, lng: 19.059795 },
    { lat: 48.150824, lng: 19.05963 },
    { lat: 48.151106, lng: 19.058986 },
    { lat: 48.151304, lng: 19.058507 },
    { lat: 48.151474, lng: 19.057888 },
    { lat: 48.151718, lng: 19.057502 },
    { lat: 48.151891, lng: 19.05712 },
    { lat: 48.151884, lng: 19.056885 },
    { lat: 48.152066, lng: 19.056573 },
    { lat: 48.152274, lng: 19.056288 },
    { lat: 48.152391, lng: 19.055985 },
    { lat: 48.152474, lng: 19.055894 },
    { lat: 48.152661, lng: 19.055695 },
    { lat: 48.15288, lng: 19.055603 },
    { lat: 48.15302, lng: 19.055308 },
    { lat: 48.153045, lng: 19.055256 },
    { lat: 48.153358, lng: 19.054842 },
    { lat: 48.153748, lng: 19.055017 },
    { lat: 48.154307, lng: 19.055376 },
    { lat: 48.154778, lng: 19.055587 },
    { lat: 48.155397, lng: 19.055666 },
    { lat: 48.155906, lng: 19.055491 },
    { lat: 48.156298, lng: 19.055193 },
    { lat: 48.156667, lng: 19.055042 },
    { lat: 48.15709, lng: 19.054765 },
    { lat: 48.157431, lng: 19.054519 },
    { lat: 48.157708, lng: 19.054152 },
    { lat: 48.157975, lng: 19.053883 },
    { lat: 48.15833, lng: 19.053365 },
    { lat: 48.15836, lng: 19.05327 },
    { lat: 48.158417, lng: 19.053131 },
    { lat: 48.158612, lng: 19.052347 },
    { lat: 48.158708, lng: 19.052227 },
    { lat: 48.158786, lng: 19.051982 },
    { lat: 48.158868, lng: 19.05189 },
    { lat: 48.15897, lng: 19.051594 },
    { lat: 48.159102, lng: 19.051432 },
    { lat: 48.159193, lng: 19.051263 },
    { lat: 48.159383, lng: 19.050996 },
    { lat: 48.159514, lng: 19.050722 },
    { lat: 48.159584, lng: 19.050519 },
    { lat: 48.159862, lng: 19.050346 },
    { lat: 48.159982, lng: 19.050176 },
    { lat: 48.160069, lng: 19.050204 },
    { lat: 48.160256, lng: 19.050073 },
    { lat: 48.160277, lng: 19.050041 },
    { lat: 48.160287, lng: 19.050025 },
    { lat: 48.160307, lng: 19.049995 },
    { lat: 48.160572, lng: 19.04959 },
    { lat: 48.160594, lng: 19.049525 },
    { lat: 48.1605016, lng: 19.0493001 },
    { lat: 48.1600981, lng: 19.0488093 },
    { lat: 48.1600938, lng: 19.0486791 },
    { lat: 48.1602109, lng: 19.0482986 },
    { lat: 48.1601747, lng: 19.0481389 },
    { lat: 48.1599278, lng: 19.0479624 },
    { lat: 48.1598863, lng: 19.0482337 },
    { lat: 48.1598372, lng: 19.0482671 },
    { lat: 48.1597066, lng: 19.0480905 },
    { lat: 48.1596439, lng: 19.0479023 },
    { lat: 48.1590411, lng: 19.0476383 },
    { lat: 48.158807, lng: 19.0472484 },
    { lat: 48.1585689, lng: 19.047179 },
    { lat: 48.1584115, lng: 19.0461855 },
    { lat: 48.158182, lng: 19.0457496 },
    { lat: 48.1578375, lng: 19.0455149 },
    { lat: 48.1575793, lng: 19.0454463 },
    { lat: 48.1575433, lng: 19.0452491 },
    { lat: 48.157421, lng: 19.0450589 },
    { lat: 48.1573362, lng: 19.0447415 },
    { lat: 48.1573925, lng: 19.044697 },
    { lat: 48.1574212, lng: 19.0445553 },
    { lat: 48.1572228, lng: 19.0443317 },
    { lat: 48.1570756, lng: 19.0440514 },
    { lat: 48.1568091, lng: 19.0440007 },
    { lat: 48.1566539, lng: 19.0432923 },
    { lat: 48.1565382, lng: 19.0432386 },
    { lat: 48.1562887, lng: 19.0433396 },
    { lat: 48.1562381, lng: 19.0432783 },
    { lat: 48.1562558, lng: 19.0428501 },
    { lat: 48.1561966, lng: 19.0424786 },
    { lat: 48.1560654, lng: 19.0423851 },
    { lat: 48.1559107, lng: 19.0420223 },
    { lat: 48.1559315, lng: 19.0416538 },
    { lat: 48.1558617, lng: 19.0413676 },
    { lat: 48.1556273, lng: 19.0411724 },
    { lat: 48.1555802, lng: 19.0410633 },
    { lat: 48.1555638, lng: 19.0407633 },
    { lat: 48.1556248, lng: 19.0402331 },
    { lat: 48.1554491, lng: 19.0396796 },
    { lat: 48.1553434, lng: 19.0389105 },
    { lat: 48.154855, lng: 19.0382791 },
    { lat: 48.1547161, lng: 19.0377634 },
    { lat: 48.1545192, lng: 19.0373048 },
    { lat: 48.1544705, lng: 19.0368354 },
    { lat: 48.1542458, lng: 19.0366533 },
    { lat: 48.1540262, lng: 19.0363247 },
    { lat: 48.1538848, lng: 19.0358708 },
    { lat: 48.1536181, lng: 19.0355681 },
    { lat: 48.1533752, lng: 19.0354936 },
    { lat: 48.1531566, lng: 19.0352984 },
    { lat: 48.1530855, lng: 19.0349393 },
    { lat: 48.1529779, lng: 19.0346863 },
    { lat: 48.152538, lng: 19.0347228 },
    { lat: 48.1524549, lng: 19.0348219 },
    { lat: 48.1523738, lng: 19.03458 },
    { lat: 48.1522352, lng: 19.0344461 },
    { lat: 48.1520089, lng: 19.0340332 },
    { lat: 48.1519524, lng: 19.0335199 },
    { lat: 48.152031, lng: 19.0332031 },
    { lat: 48.1520046, lng: 19.0331471 },
    { lat: 48.1519258, lng: 19.0330848 },
    { lat: 48.1517539, lng: 19.0331385 },
    { lat: 48.1517098, lng: 19.0329345 },
    { lat: 48.1512903, lng: 19.032825 },
    { lat: 48.1511844, lng: 19.0320472 },
    { lat: 48.1511145, lng: 19.0319601 },
    { lat: 48.1509665, lng: 19.0319762 },
    { lat: 48.1509285, lng: 19.0319162 },
    { lat: 48.1506782, lng: 19.03145 },
    { lat: 48.1506691, lng: 19.0313162 },
    { lat: 48.1504567, lng: 19.0310193 },
    { lat: 48.1504089, lng: 19.0305901 },
    { lat: 48.150326, lng: 19.0304227 },
    { lat: 48.1498347, lng: 19.0298966 },
    { lat: 48.149914, lng: 19.0296577 },
    { lat: 48.1498664, lng: 19.0295851 },
    { lat: 48.1494982, lng: 19.029486 },
    { lat: 48.1493524, lng: 19.0291926 },
    { lat: 48.1493558, lng: 19.0289659 },
    { lat: 48.1492999, lng: 19.0288663 },
    { lat: 48.1490675, lng: 19.0289513 },
    { lat: 48.1489557, lng: 19.0289276 },
    { lat: 48.1488824, lng: 19.0288604 },
    { lat: 48.1489018, lng: 19.0286428 },
    { lat: 48.1487971, lng: 19.0283589 },
    { lat: 48.1484721, lng: 19.0282386 },
    { lat: 48.1484519, lng: 19.0280242 },
    { lat: 48.1485437, lng: 19.0278958 },
    { lat: 48.1487133, lng: 19.0280849 },
    { lat: 48.1488326, lng: 19.0278817 },
    { lat: 48.1488076, lng: 19.0278111 },
    { lat: 48.1485283, lng: 19.0276617 },
    { lat: 48.1484311, lng: 19.0274796 },
    { lat: 48.1482718, lng: 19.0274737 },
    { lat: 48.1481756, lng: 19.0271757 },
    { lat: 48.1480515, lng: 19.0269734 },
    { lat: 48.14798, lng: 19.0264325 },
    { lat: 48.1479865, lng: 19.026054 },
    { lat: 48.1475566, lng: 19.0256238 },
    { lat: 48.1469947, lng: 19.0244428 },
    { lat: 48.1468145, lng: 19.0242016 },
    { lat: 48.1469012, lng: 19.0234906 },
    { lat: 48.1468602, lng: 19.0233043 },
    { lat: 48.1467858, lng: 19.0232164 },
    { lat: 48.1466066, lng: 19.022466 },
    { lat: 48.1463765, lng: 19.0220209 },
    { lat: 48.1460651, lng: 19.0215705 },
    { lat: 48.1459681, lng: 19.0211731 },
    { lat: 48.1459459, lng: 19.0208017 },
    { lat: 48.1457412, lng: 19.0203922 },
    { lat: 48.1458189, lng: 19.0197263 },
    { lat: 48.1455707, lng: 19.0189267 },
    { lat: 48.1450473, lng: 19.0182346 },
    { lat: 48.1450011, lng: 19.0179013 },
    { lat: 48.1450285, lng: 19.0176659 },
    { lat: 48.1449626, lng: 19.0175029 },
    { lat: 48.1442731, lng: 19.0175756 },
    { lat: 48.144102, lng: 19.0174496 },
    { lat: 48.1438182, lng: 19.0176355 },
    { lat: 48.1435401, lng: 19.0176858 },
    { lat: 48.1432898, lng: 19.0174417 },
    { lat: 48.1429304, lng: 19.0174039 },
    { lat: 48.1422137, lng: 19.0179457 },
    { lat: 48.1419356, lng: 19.01806 },
    { lat: 48.1417231, lng: 19.0179602 },
    { lat: 48.1416382, lng: 19.0177552 },
    { lat: 48.1415015, lng: 19.0176603 },
    { lat: 48.1412632, lng: 19.0177866 },
    { lat: 48.140685, lng: 19.0173034 },
    { lat: 48.1405583, lng: 19.0172734 },
    { lat: 48.1403489, lng: 19.0173476 },
    { lat: 48.1400154, lng: 19.0171967 },
    { lat: 48.1399483, lng: 19.0171179 },
    { lat: 48.139985, lng: 19.0168225 },
    { lat: 48.1398333, lng: 19.0160335 },
    { lat: 48.1397567, lng: 19.0158371 },
    { lat: 48.1394481, lng: 19.0158874 },
    { lat: 48.1393567, lng: 19.0157728 },
    { lat: 48.1393471, lng: 19.0155656 },
    { lat: 48.1395526, lng: 19.0152161 },
    { lat: 48.1395831, lng: 19.0150647 },
    { lat: 48.1391875, lng: 19.0146445 },
    { lat: 48.1391901, lng: 19.014474 },
    { lat: 48.1393168, lng: 19.0141081 },
    { lat: 48.139306, lng: 19.0139621 },
    { lat: 48.1389308, lng: 19.0137432 },
    { lat: 48.138915, lng: 19.0135219 },
    { lat: 48.1390934, lng: 19.01291 },
    { lat: 48.1390757, lng: 19.0127161 },
    { lat: 48.1389408, lng: 19.0124155 },
    { lat: 48.1388923, lng: 19.0120155 },
    { lat: 48.1386272, lng: 19.0111529 },
    { lat: 48.1385175, lng: 19.01099 },
    { lat: 48.1381442, lng: 19.0109015 },
    { lat: 48.1378459, lng: 19.0106213 },
    { lat: 48.1372985, lng: 19.0097281 },
    { lat: 48.1367874, lng: 19.0092999 },
    { lat: 48.1365225, lng: 19.0091705 },
    { lat: 48.1362738, lng: 19.0087128 },
    { lat: 48.1355391, lng: 19.008057 },
    { lat: 48.1353604, lng: 19.0075839 },
    { lat: 48.1351987, lng: 19.0075067 },
    { lat: 48.1349909, lng: 19.0076811 },
    { lat: 48.1345975, lng: 19.00768 },
    { lat: 48.1343683, lng: 19.0075639 },
    { lat: 48.1341135, lng: 19.0075618 },
    { lat: 48.1338702, lng: 19.0077032 },
    { lat: 48.1336836, lng: 19.0079341 },
    { lat: 48.1329384, lng: 19.0073381 },
    { lat: 48.132716, lng: 19.0069148 },
    { lat: 48.1326413, lng: 19.0068852 },
    { lat: 48.1323736, lng: 19.0070527 },
    { lat: 48.1321013, lng: 19.0073982 },
    { lat: 48.1320003, lng: 19.0074277 },
    { lat: 48.1316731, lng: 19.0067799 },
    { lat: 48.1314954, lng: 19.0065711 },
    { lat: 48.1314254, lng: 19.0066341 },
    { lat: 48.1312185, lng: 19.0065984 },
    { lat: 48.1308414, lng: 19.0062602 },
    { lat: 48.1307098, lng: 19.0062879 },
    { lat: 48.1305839, lng: 19.0064935 },
    { lat: 48.1303996, lng: 19.0064827 },
    { lat: 48.1303468, lng: 19.0063984 },
    { lat: 48.1303495, lng: 19.0060769 },
    { lat: 48.1302839, lng: 19.0059841 },
    { lat: 48.1301682, lng: 19.006015 },
    { lat: 48.1300207, lng: 19.0058652 },
    { lat: 48.1298902, lng: 19.005891 },
    { lat: 48.1298734, lng: 19.0061167 },
    { lat: 48.1297909, lng: 19.0061988 },
    { lat: 48.1294823, lng: 19.0060733 },
    { lat: 48.1294518, lng: 19.0060274 },
    { lat: 48.1294736, lng: 19.0055929 },
    { lat: 48.1294009, lng: 19.0054922 },
    { lat: 48.1293069, lng: 19.0055071 },
    { lat: 48.1292484, lng: 19.0056666 },
    { lat: 48.1292465, lng: 19.0064439 },
    { lat: 48.1289235, lng: 19.0064338 },
    { lat: 48.1288759, lng: 19.0065617 },
    { lat: 48.1287537, lng: 19.0066857 },
    { lat: 48.1286249, lng: 19.0066626 },
    { lat: 48.1285578, lng: 19.0064949 },
    { lat: 48.128595, lng: 19.0062062 },
    { lat: 48.1284709, lng: 19.0060933 },
    { lat: 48.1283465, lng: 19.0061335 },
    { lat: 48.1282089, lng: 19.0059784 },
    { lat: 48.1283014, lng: 19.0057131 },
    { lat: 48.1282409, lng: 19.0056109 },
    { lat: 48.1281471, lng: 19.0055821 },
    { lat: 48.1280816, lng: 19.0057572 },
    { lat: 48.1278933, lng: 19.0056459 },
    { lat: 48.1275987, lng: 19.0058189 },
    { lat: 48.1273366, lng: 19.0058078 },
    { lat: 48.1272832, lng: 19.0057098 },
    { lat: 48.1273074, lng: 19.0053703 },
    { lat: 48.1270261, lng: 19.0052231 },
    { lat: 48.1267706, lng: 19.00499 },
    { lat: 48.126341, lng: 19.0051649 },
    { lat: 48.1262162, lng: 19.0050616 },
    { lat: 48.1261866, lng: 19.0043299 },
    { lat: 48.1260949, lng: 19.0042772 },
    { lat: 48.125921, lng: 19.0045366 },
    { lat: 48.1257576, lng: 19.0046262 },
    { lat: 48.1256461, lng: 19.0043873 },
    { lat: 48.1254927, lng: 19.0044076 },
    { lat: 48.1253762, lng: 19.0047967 },
    { lat: 48.1252907, lng: 19.0048488 },
    { lat: 48.1251653, lng: 19.0047286 },
    { lat: 48.1248715, lng: 19.0046301 },
    { lat: 48.1246433, lng: 19.0043575 },
    { lat: 48.1244393, lng: 19.0044252 },
    { lat: 48.1240114, lng: 19.0042803 },
    { lat: 48.1239492, lng: 19.004034 },
    { lat: 48.1242202, lng: 19.0039742 },
    { lat: 48.1243759, lng: 19.0038449 },
    { lat: 48.1242933, lng: 19.0035727 },
    { lat: 48.1238947, lng: 19.0033192 },
    { lat: 48.123658, lng: 19.0035647 },
    { lat: 48.1235282, lng: 19.0036052 },
    { lat: 48.1233573, lng: 19.0031324 },
    { lat: 48.123145, lng: 19.0026795 },
    { lat: 48.1230634, lng: 19.0026087 },
    { lat: 48.1229061, lng: 19.002573 },
    { lat: 48.1228348, lng: 19.0027216 },
    { lat: 48.1223582, lng: 19.0025959 },
    { lat: 48.1222023, lng: 19.0024584 },
    { lat: 48.1219431, lng: 19.0023934 },
    { lat: 48.1217221, lng: 19.0024849 },
    { lat: 48.1215727, lng: 19.0027599 },
    { lat: 48.1212713, lng: 19.0027526 },
    { lat: 48.1209943, lng: 19.0029825 },
    { lat: 48.1206915, lng: 19.0028769 },
    { lat: 48.1206481, lng: 19.0028007 },
    { lat: 48.1207245, lng: 19.0018671 },
    { lat: 48.1206031, lng: 19.0017903 },
    { lat: 48.120307, lng: 19.0018635 },
    { lat: 48.1202472, lng: 19.001774 },
    { lat: 48.1201638, lng: 19.0011367 },
    { lat: 48.1197118, lng: 19.0007204 },
    { lat: 48.1195925, lng: 19.0004799 },
    { lat: 48.1195204, lng: 19.0001748 },
    { lat: 48.1194559, lng: 19.0001225 },
    { lat: 48.1193552, lng: 19.000159 },
    { lat: 48.1193526, lng: 19.0002662 },
    { lat: 48.1193543, lng: 19.0009601 },
    { lat: 48.1194876, lng: 19.0023338 },
    { lat: 48.1194069, lng: 19.0031591 },
    { lat: 48.1196608, lng: 19.0049294 },
    { lat: 48.119425, lng: 19.0059418 },
    { lat: 48.119238, lng: 19.0065182 },
    { lat: 48.1191378, lng: 19.007694 },
    { lat: 48.1192525, lng: 19.008682 },
    { lat: 48.1190547, lng: 19.0091572 },
    { lat: 48.119167, lng: 19.0093484 },
    { lat: 48.1191318, lng: 19.0094508 },
    { lat: 48.1190266, lng: 19.009533 },
    { lat: 48.1190204, lng: 19.0096336 },
    { lat: 48.1192302, lng: 19.0101012 },
    { lat: 48.1186826, lng: 19.0113029 },
    { lat: 48.1186038, lng: 19.0124064 },
    { lat: 48.1184399, lng: 19.0131304 },
    { lat: 48.1181826, lng: 19.0140114 },
    { lat: 48.1179895, lng: 19.0143329 },
    { lat: 48.1178797, lng: 19.0147886 },
    { lat: 48.1177541, lng: 19.0149807 },
    { lat: 48.117733, lng: 19.0151648 },
    { lat: 48.1173513, lng: 19.015705 },
    { lat: 48.1167083, lng: 19.0160727 },
    { lat: 48.1164122, lng: 19.0165026 },
    { lat: 48.1161816, lng: 19.0170687 },
    { lat: 48.1161782, lng: 19.0173771 },
    { lat: 48.1160258, lng: 19.0179931 },
    { lat: 48.1159658, lng: 19.0180765 },
    { lat: 48.1158707, lng: 19.0190445 },
    { lat: 48.1154306, lng: 19.0210016 },
    { lat: 48.1149382, lng: 19.0221156 },
    { lat: 48.1147857, lng: 19.0235925 },
    { lat: 48.1146111, lng: 19.024372 },
    { lat: 48.1141721, lng: 19.025783 },
    { lat: 48.1139651, lng: 19.0262143 },
    { lat: 48.1137818, lng: 19.0268878 },
    { lat: 48.1134363, lng: 19.027519 },
    { lat: 48.1119308, lng: 19.0279777 },
    { lat: 48.1114945, lng: 19.0280247 },
    { lat: 48.1112266, lng: 19.028211 },
    { lat: 48.1110738, lng: 19.0283991 },
    { lat: 48.1110353, lng: 19.02857 },
    { lat: 48.1109392, lng: 19.0287012 },
    { lat: 48.1105976, lng: 19.0290331 },
    { lat: 48.1100385, lng: 19.0293869 },
    { lat: 48.10972, lng: 19.0294893 },
    { lat: 48.1093219, lng: 19.0298428 },
    { lat: 48.108926, lng: 19.030065 },
    { lat: 48.109088, lng: 19.030261 },
    { lat: 48.1092, lng: 19.030356 },
    { lat: 48.109235, lng: 19.030551 },
    { lat: 48.109252, lng: 19.030776 },
    { lat: 48.109308, lng: 19.030926 },
    { lat: 48.109463, lng: 19.031802 },
    { lat: 48.109644, lng: 19.032119 },
    { lat: 48.109829, lng: 19.032675 },
    { lat: 48.109954, lng: 19.03321 },
    { lat: 48.110088, lng: 19.033607 },
    { lat: 48.110151, lng: 19.03386 },
    { lat: 48.110179, lng: 19.034087 },
    { lat: 48.110272, lng: 19.034423 },
    { lat: 48.110366, lng: 19.034733 },
    { lat: 48.11059, lng: 19.035362 },
    { lat: 48.110787, lng: 19.035635 },
    { lat: 48.111176, lng: 19.036579 },
    { lat: 48.111255, lng: 19.036829 },
    { lat: 48.111373, lng: 19.037007 },
    { lat: 48.111685, lng: 19.037704 },
    { lat: 48.111973, lng: 19.038037 },
    { lat: 48.11205, lng: 19.038131 },
    { lat: 48.112302, lng: 19.038738 },
    { lat: 48.112374, lng: 19.0389 },
    { lat: 48.112627, lng: 19.039321 },
    { lat: 48.112859, lng: 19.039782 },
    { lat: 48.112873, lng: 19.039823 },
    { lat: 48.112969, lng: 19.040074 },
    { lat: 48.112984, lng: 19.040113 },
    { lat: 48.113103, lng: 19.040426 },
    { lat: 48.113279, lng: 19.040818 },
    { lat: 48.113456, lng: 19.041083 },
    { lat: 48.113591, lng: 19.041309 },
    { lat: 48.113786, lng: 19.041662 },
    { lat: 48.114022, lng: 19.042139 },
    { lat: 48.113998, lng: 19.043266 },
    { lat: 48.113998, lng: 19.043319 },
    { lat: 48.114035, lng: 19.043341 },
    { lat: 48.113987, lng: 19.04359 },
    { lat: 48.114005, lng: 19.043748 },
    { lat: 48.114013, lng: 19.043822 },
    { lat: 48.114025, lng: 19.043918 },
    { lat: 48.114056, lng: 19.044051 },
    { lat: 48.114059, lng: 19.044064 },
    { lat: 48.114104, lng: 19.044188 },
    { lat: 48.11411, lng: 19.044207 },
    { lat: 48.11413, lng: 19.04426 },
    { lat: 48.114149, lng: 19.044302 },
    { lat: 48.114189, lng: 19.044388 },
    { lat: 48.114284, lng: 19.044589 },
    { lat: 48.114307, lng: 19.04462 },
    { lat: 48.114443, lng: 19.04479 },
    { lat: 48.114449, lng: 19.044801 },
    { lat: 48.11449, lng: 19.044866 },
    { lat: 48.114502, lng: 19.044897 },
    { lat: 48.114524, lng: 19.044952 },
    { lat: 48.114534, lng: 19.044981 },
    { lat: 48.114546, lng: 19.045003 },
    { lat: 48.114554, lng: 19.045012 },
    { lat: 48.114662, lng: 19.045145 },
    { lat: 48.114832, lng: 19.045105 },
    { lat: 48.114856, lng: 19.045203 },
    { lat: 48.114877, lng: 19.045289 },
    { lat: 48.114897, lng: 19.045371 },
    { lat: 48.11489, lng: 19.045503 },
    { lat: 48.11487, lng: 19.045647 },
    { lat: 48.114856, lng: 19.045749 },
    { lat: 48.114843, lng: 19.045834 },
    { lat: 48.11486, lng: 19.045916 },
    { lat: 48.114864, lng: 19.046046 },
    { lat: 48.114858, lng: 19.046156 },
    { lat: 48.114853, lng: 19.046212 },
    { lat: 48.114861, lng: 19.04625 },
    { lat: 48.11487, lng: 19.04629 },
    { lat: 48.11488, lng: 19.046339 },
    { lat: 48.11495, lng: 19.046529 },
    { lat: 48.114965, lng: 19.046572 },
    { lat: 48.114969, lng: 19.046716 },
    { lat: 48.114981, lng: 19.046788 },
    { lat: 48.11501, lng: 19.04698 },
    { lat: 48.115011, lng: 19.047066 },
    { lat: 48.115011, lng: 19.047146 },
    { lat: 48.115034, lng: 19.047306 },
    { lat: 48.115035, lng: 19.047343 },
    { lat: 48.115035, lng: 19.047377 },
    { lat: 48.115037, lng: 19.047399 },
    { lat: 48.115037, lng: 19.047459 },
    { lat: 48.115044, lng: 19.047531 },
    { lat: 48.115051, lng: 19.047606 },
    { lat: 48.115055, lng: 19.047658 },
    { lat: 48.115078, lng: 19.047639 },
    { lat: 48.115126, lng: 19.047601 },
    { lat: 48.115183, lng: 19.047589 },
    { lat: 48.115284, lng: 19.047506 },
    { lat: 48.115408, lng: 19.047457 },
    { lat: 48.115427, lng: 19.04745 },
    { lat: 48.115552, lng: 19.047596 },
    { lat: 48.115669, lng: 19.047714 },
    { lat: 48.115862, lng: 19.047976 },
    { lat: 48.115879, lng: 19.047997 },
    { lat: 48.115942, lng: 19.048083 },
    { lat: 48.116002, lng: 19.048194 },
    { lat: 48.115994, lng: 19.048205 },
    { lat: 48.116008, lng: 19.048224 },
    { lat: 48.116084, lng: 19.048327 },
    { lat: 48.116097, lng: 19.048366 },
    { lat: 48.116196, lng: 19.048646 },
    { lat: 48.116321, lng: 19.048922 },
    { lat: 48.116378, lng: 19.049055 },
    { lat: 48.116448, lng: 19.049223 },
    { lat: 48.1165, lng: 19.049281 },
    { lat: 48.116578, lng: 19.049443 },
    { lat: 48.116633, lng: 19.049557 },
    { lat: 48.116695, lng: 19.04966 },
    { lat: 48.116708, lng: 19.049683 },
    { lat: 48.116808, lng: 19.049812 },
    { lat: 48.116957, lng: 19.050036 },
    { lat: 48.117083, lng: 19.050205 },
    { lat: 48.117195, lng: 19.050356 },
    { lat: 48.117162, lng: 19.05041 },
    { lat: 48.117162, lng: 19.050492 },
    { lat: 48.117241, lng: 19.050513 },
    { lat: 48.117305, lng: 19.05053 },
    { lat: 48.117377, lng: 19.050549 },
    { lat: 48.117434, lng: 19.050558 },
    { lat: 48.117492, lng: 19.050553 },
    { lat: 48.117568, lng: 19.050517 },
    { lat: 48.117854, lng: 19.050203 },
    { lat: 48.117904, lng: 19.050162 },
    { lat: 48.118206, lng: 19.050017 },
    { lat: 48.118416, lng: 19.049664 },
    { lat: 48.118461, lng: 19.049591 },
    { lat: 48.11849, lng: 19.049543 },
    { lat: 48.118578, lng: 19.049399 },
    { lat: 48.118666, lng: 19.049502 },
    { lat: 48.118818, lng: 19.04973 },
    { lat: 48.119222, lng: 19.050304 },
    { lat: 48.119479, lng: 19.050651 },
    { lat: 48.119945, lng: 19.051212 },
    { lat: 48.120003, lng: 19.051282 },
    { lat: 48.120492, lng: 19.051864 },
    { lat: 48.120776, lng: 19.052196 },
    { lat: 48.121167, lng: 19.052643 },
    { lat: 48.121451, lng: 19.052996 },
    { lat: 48.121601, lng: 19.053205 },
    { lat: 48.121919, lng: 19.053652 },
    { lat: 48.122194, lng: 19.053949 },
    { lat: 48.122412, lng: 19.054187 },
    { lat: 48.122858, lng: 19.054699 },
    { lat: 48.123244, lng: 19.055139 },
    { lat: 48.123395, lng: 19.055313 },
    { lat: 48.123614, lng: 19.055588 },
    { lat: 48.123802, lng: 19.055824 },
    { lat: 48.124765, lng: 19.057059 },
    { lat: 48.124732, lng: 19.057185 },
    { lat: 48.124911, lng: 19.057429 },
    { lat: 48.125388, lng: 19.05778 },
    { lat: 48.125513, lng: 19.058139 },
    { lat: 48.126193, lng: 19.058386 },
    { lat: 48.126582, lng: 19.058911 },
    { lat: 48.126641, lng: 19.05933 },
    { lat: 48.12665, lng: 19.059566 },
    { lat: 48.126758, lng: 19.059591 },
    { lat: 48.127083, lng: 19.059565 },
    { lat: 48.127229, lng: 19.059557 },
    { lat: 48.127376, lng: 19.059552 },
    { lat: 48.12741, lng: 19.059564 },
    { lat: 48.127463, lng: 19.059582 },
    { lat: 48.12759, lng: 19.059638 },
    { lat: 48.127862, lng: 19.059749 },
    { lat: 48.128125, lng: 19.059802 },
    { lat: 48.128198, lng: 19.059929 },
    { lat: 48.128486, lng: 19.060438 },
    { lat: 48.129992, lng: 19.061145 },
    { lat: 48.130744, lng: 19.061512 },
    { lat: 48.130904, lng: 19.06173 },
    { lat: 48.132021, lng: 19.062516 },
    { lat: 48.132443, lng: 19.062878 },
    { lat: 48.132485, lng: 19.062914 },
    { lat: 48.132519, lng: 19.062943 },
    { lat: 48.133124, lng: 19.063364 },
    { lat: 48.13339, lng: 19.063568 },
    { lat: 48.133415, lng: 19.063573 },
    { lat: 48.133615, lng: 19.063592 },
    { lat: 48.133642, lng: 19.063595 },
    { lat: 48.133757, lng: 19.063617 },
    { lat: 48.133859, lng: 19.063636 },
    { lat: 48.133901, lng: 19.063765 },
    { lat: 48.133934, lng: 19.063975 },
    { lat: 48.1338638, lng: 19.0643497 },
    { lat: 48.134143, lng: 19.065056 },
    { lat: 48.134368, lng: 19.065739 },
    { lat: 48.134538, lng: 19.06607 },
    { lat: 48.134864, lng: 19.066479 },
    { lat: 48.135236, lng: 19.066612 },
    { lat: 48.136125, lng: 19.065785 },
    { lat: 48.137462, lng: 19.065031 },
    { lat: 48.138765, lng: 19.064305 },
    { lat: 48.139241, lng: 19.064004 },
    { lat: 48.139545, lng: 19.064492 },
    { lat: 48.13998, lng: 19.064838 },
    { lat: 48.140416, lng: 19.064988 },
    { lat: 48.140999, lng: 19.064829 },
    { lat: 48.141209, lng: 19.06465 },
    { lat: 48.141525, lng: 19.064539 },
    { lat: 48.141841, lng: 19.064412 },
    { lat: 48.141937, lng: 19.064404 },
    { lat: 48.142521, lng: 19.064411 },
    { lat: 48.143401, lng: 19.06451 },
    { lat: 48.143665, lng: 19.064162 },
    { lat: 48.144037, lng: 19.063962 },
    { lat: 48.144765, lng: 19.063695 },
    { lat: 48.14502, lng: 19.063649 },
    { lat: 48.14515, lng: 19.063626 },
    { lat: 48.145371, lng: 19.063542 },
    { lat: 48.145419, lng: 19.06356 },
    { lat: 48.145536, lng: 19.063405 },
    { lat: 48.145691, lng: 19.063259 },
    { lat: 48.145729, lng: 19.063132 },
    { lat: 48.145801, lng: 19.063055 },
    { lat: 48.145875, lng: 19.062887 },
    { lat: 48.146066, lng: 19.062696 },
    { lat: 48.146108, lng: 19.062603 },
    { lat: 48.146141, lng: 19.062475 },
    { lat: 48.146225, lng: 19.06243 },
    { lat: 48.146225, lng: 19.062374 },
    { lat: 48.146194, lng: 19.062282 },
    { lat: 48.146259, lng: 19.062152 },
    { lat: 48.146351, lng: 19.06211 },
    { lat: 48.14639, lng: 19.062077 },
    { lat: 48.146399, lng: 19.062015 },
    { lat: 48.146482, lng: 19.06185 },
    { lat: 48.146471, lng: 19.061752 },
    { lat: 48.146547, lng: 19.061724 },
    { lat: 48.146567, lng: 19.061684 },
    { lat: 48.14657, lng: 19.061543 },
    { lat: 48.146597, lng: 19.061438 },
    { lat: 48.146742, lng: 19.061226 },
    { lat: 48.14679, lng: 19.061037 },
    { lat: 48.146902, lng: 19.06092 },
    { lat: 48.147005, lng: 19.060711 },
    { lat: 48.147124, lng: 19.060517 },
    { lat: 48.147222, lng: 19.060423 },
    { lat: 48.147342, lng: 19.060261 },
    { lat: 48.147349, lng: 19.060225 },
    { lat: 48.14737, lng: 19.060167 },
    { lat: 48.14741, lng: 19.060059 },
    { lat: 48.147507, lng: 19.059743 },
    { lat: 48.14764, lng: 19.059505 },
    { lat: 48.147798, lng: 19.059242 },
    { lat: 48.147797, lng: 19.059096 },
    { lat: 48.147865, lng: 19.059003 },
    { lat: 48.147933, lng: 19.058842 },
    { lat: 48.147948, lng: 19.058803 },
    { lat: 48.147987, lng: 19.058709 },
    { lat: 48.147987, lng: 19.058643 },
    { lat: 48.147953, lng: 19.058578 },
    { lat: 48.147993, lng: 19.058549 },
    { lat: 48.148, lng: 19.058448 },
    { lat: 48.147865, lng: 19.058081 },
    { lat: 48.147873, lng: 19.057999 },
    { lat: 48.148025, lng: 19.058061 },
    { lat: 48.148185, lng: 19.058023 },
    { lat: 48.148261, lng: 19.058051 },
    { lat: 48.148404, lng: 19.058144 },
    { lat: 48.148558, lng: 19.058414 },
    { lat: 48.148771, lng: 19.058661 },
    { lat: 48.148903, lng: 19.058827 },
    { lat: 48.148918, lng: 19.05892 },
    { lat: 48.148914, lng: 19.05934 },
    { lat: 48.149057, lng: 19.059585 },
    { lat: 48.149136, lng: 19.059617 },
    { lat: 48.149179, lng: 19.059575 },
    { lat: 48.149204, lng: 19.059516 },
    { lat: 48.149223, lng: 19.059391 },
    { lat: 48.149244, lng: 19.059333 },
    { lat: 48.14928, lng: 19.059304 },
    { lat: 48.149316, lng: 19.059309 },
    { lat: 48.14935, lng: 19.059353 },
    { lat: 48.149463, lng: 19.059675 },
    { lat: 48.149524, lng: 19.05973 },
    { lat: 48.149612, lng: 19.059692 },
    { lat: 48.149757, lng: 19.05969 },
    { lat: 48.149788, lng: 19.059708 },
    { lat: 48.149803, lng: 19.059755 },
    { lat: 48.149792, lng: 19.059819 },
    { lat: 48.149728, lng: 19.060027 },
    { lat: 48.14976, lng: 19.060146 },
    { lat: 48.149819, lng: 19.060192 },
    { lat: 48.150002, lng: 19.060169 },
    { lat: 48.150051, lng: 19.060186 },
    { lat: 48.150091, lng: 19.060219 },
    { lat: 48.150198, lng: 19.060484 },
  ],
  Tehla: [
    { lat: 48.222948, lng: 18.358724 },
    { lat: 48.222939, lng: 18.35871 },
    { lat: 48.222939, lng: 18.358707 },
    { lat: 48.222975, lng: 18.358262 },
    { lat: 48.22303, lng: 18.357627 },
    { lat: 48.223, lng: 18.357634 },
    { lat: 48.222373, lng: 18.357787 },
    { lat: 48.222324, lng: 18.357799 },
    { lat: 48.221613, lng: 18.357964 },
    { lat: 48.221115, lng: 18.358293 },
    { lat: 48.221019, lng: 18.35836 },
    { lat: 48.220477, lng: 18.35836 },
    { lat: 48.220159, lng: 18.358361 },
    { lat: 48.220155, lng: 18.358361 },
    { lat: 48.220167, lng: 18.35516 },
    { lat: 48.220169, lng: 18.354963 },
    { lat: 48.22017, lng: 18.354906 },
    { lat: 48.220174, lng: 18.354643 },
    { lat: 48.220139, lng: 18.353719 },
    { lat: 48.220138, lng: 18.35344 },
    { lat: 48.220252, lng: 18.352943 },
    { lat: 48.220344, lng: 18.352708 },
    { lat: 48.220405, lng: 18.352476 },
    { lat: 48.220447, lng: 18.352112 },
    { lat: 48.220435, lng: 18.351824 },
    { lat: 48.220396, lng: 18.351543 },
    { lat: 48.220364, lng: 18.351357 },
    { lat: 48.220187, lng: 18.350309 },
    { lat: 48.220151, lng: 18.350069 },
    { lat: 48.220127, lng: 18.349819 },
    { lat: 48.22007, lng: 18.34844 },
    { lat: 48.220058, lng: 18.347739 },
    { lat: 48.220058, lng: 18.347692 },
    { lat: 48.220024, lng: 18.347672 },
    { lat: 48.218532, lng: 18.346781 },
    { lat: 48.218191, lng: 18.346586 },
    { lat: 48.218191, lng: 18.346556 },
    { lat: 48.218192, lng: 18.346523 },
    { lat: 48.218192, lng: 18.34641 },
    { lat: 48.218193, lng: 18.346326 },
    { lat: 48.218194, lng: 18.346263 },
    { lat: 48.217557, lng: 18.34567 },
    { lat: 48.21693, lng: 18.345077 },
    { lat: 48.216613, lng: 18.344792 },
    { lat: 48.216067, lng: 18.344412 },
    { lat: 48.21597, lng: 18.344345 },
    { lat: 48.215885, lng: 18.344306 },
    { lat: 48.215703, lng: 18.344223 },
    { lat: 48.215468, lng: 18.344125 },
    { lat: 48.215414, lng: 18.344103 },
    { lat: 48.215391, lng: 18.344094 },
    { lat: 48.215361, lng: 18.344081 },
    { lat: 48.21535, lng: 18.344076 },
    { lat: 48.215345, lng: 18.344074 },
    { lat: 48.215345, lng: 18.344076 },
    { lat: 48.215344, lng: 18.34408 },
    { lat: 48.215193, lng: 18.344858 },
    { lat: 48.215076, lng: 18.34547 },
    { lat: 48.214959, lng: 18.345942 },
    { lat: 48.214901, lng: 18.346174 },
    { lat: 48.2149, lng: 18.346177 },
    { lat: 48.214893, lng: 18.346179 },
    { lat: 48.214628, lng: 18.346231 },
    { lat: 48.213315, lng: 18.346492 },
    { lat: 48.213262, lng: 18.346501 },
    { lat: 48.212369, lng: 18.346662 },
    { lat: 48.212215, lng: 18.346689 },
    { lat: 48.212158, lng: 18.3467 },
    { lat: 48.212149, lng: 18.346701 },
    { lat: 48.21212, lng: 18.346707 },
    { lat: 48.212035, lng: 18.346707 },
    { lat: 48.211589, lng: 18.346714 },
    { lat: 48.211494, lng: 18.346716 },
    { lat: 48.21147, lng: 18.346716 },
    { lat: 48.211349, lng: 18.346718 },
    { lat: 48.211095, lng: 18.346722 },
    { lat: 48.210649, lng: 18.346728 },
    { lat: 48.210423, lng: 18.346732 },
    { lat: 48.209628, lng: 18.347134 },
    { lat: 48.209106, lng: 18.347393 },
    { lat: 48.208928, lng: 18.347481 },
    { lat: 48.208924, lng: 18.347479 },
    { lat: 48.208511, lng: 18.347137 },
    { lat: 48.208116, lng: 18.346479 },
    { lat: 48.207854, lng: 18.345549 },
    { lat: 48.207683, lng: 18.345984 },
    { lat: 48.207652, lng: 18.346097 },
    { lat: 48.207584, lng: 18.346309 },
    { lat: 48.207552, lng: 18.34642 },
    { lat: 48.207545, lng: 18.346447 },
    { lat: 48.207387, lng: 18.346732 },
    { lat: 48.207369, lng: 18.346765 },
    { lat: 48.207325, lng: 18.346858 },
    { lat: 48.207325, lng: 18.346861 },
    { lat: 48.207311, lng: 18.346891 },
    { lat: 48.207237, lng: 18.34701 },
    { lat: 48.207228, lng: 18.347027 },
    { lat: 48.207136, lng: 18.347199 },
    { lat: 48.207053, lng: 18.347344 },
    { lat: 48.207006, lng: 18.347446 },
    { lat: 48.206949, lng: 18.347533 },
    { lat: 48.206933, lng: 18.347571 },
    { lat: 48.206895, lng: 18.347664 },
    { lat: 48.206856, lng: 18.347813 },
    { lat: 48.20683, lng: 18.347945 },
    { lat: 48.20682, lng: 18.348 },
    { lat: 48.206811, lng: 18.348047 },
    { lat: 48.206781, lng: 18.348243 },
    { lat: 48.206755, lng: 18.348454 },
    { lat: 48.206754, lng: 18.34846 },
    { lat: 48.206736, lng: 18.348571 },
    { lat: 48.206726, lng: 18.348632 },
    { lat: 48.206723, lng: 18.348645 },
    { lat: 48.206697, lng: 18.348765 },
    { lat: 48.206682, lng: 18.348834 },
    { lat: 48.206653, lng: 18.348976 },
    { lat: 48.20664, lng: 18.349042 },
    { lat: 48.206624, lng: 18.349114 },
    { lat: 48.206596, lng: 18.349248 },
    { lat: 48.206586, lng: 18.349296 },
    { lat: 48.206565, lng: 18.349397 },
    { lat: 48.206528, lng: 18.34957 },
    { lat: 48.206474, lng: 18.349729 },
    { lat: 48.206467, lng: 18.349745 },
    { lat: 48.206427, lng: 18.349834 },
    { lat: 48.206393, lng: 18.349882 },
    { lat: 48.20636, lng: 18.349929 },
    { lat: 48.20635, lng: 18.349943 },
    { lat: 48.206232, lng: 18.350097 },
    { lat: 48.206224, lng: 18.350109 },
    { lat: 48.206206, lng: 18.350132 },
    { lat: 48.206163, lng: 18.350185 },
    { lat: 48.206073, lng: 18.350297 },
    { lat: 48.206039, lng: 18.350371 },
    { lat: 48.205981, lng: 18.350498 },
    { lat: 48.205973, lng: 18.350515 },
    { lat: 48.205942, lng: 18.350576 },
    { lat: 48.205915, lng: 18.350628 },
    { lat: 48.20587, lng: 18.350717 },
    { lat: 48.205859, lng: 18.35074 },
    { lat: 48.205819, lng: 18.35083 },
    { lat: 48.20578, lng: 18.350916 },
    { lat: 48.20577, lng: 18.350938 },
    { lat: 48.20569, lng: 18.351129 },
    { lat: 48.205627, lng: 18.351258 },
    { lat: 48.205585, lng: 18.3513 },
    { lat: 48.205511, lng: 18.351374 },
    { lat: 48.205366, lng: 18.351513 },
    { lat: 48.205264, lng: 18.351618 },
    { lat: 48.205223, lng: 18.351659 },
    { lat: 48.205087, lng: 18.351827 },
    { lat: 48.204999, lng: 18.351929 },
    { lat: 48.204838, lng: 18.352125 },
    { lat: 48.204783, lng: 18.352188 },
    { lat: 48.204656, lng: 18.35233 },
    { lat: 48.204593, lng: 18.352449 },
    { lat: 48.204522, lng: 18.352571 },
    { lat: 48.204422, lng: 18.352736 },
    { lat: 48.204392, lng: 18.352785 },
    { lat: 48.204311, lng: 18.352915 },
    { lat: 48.204195, lng: 18.353106 },
    { lat: 48.204093, lng: 18.353268 },
    { lat: 48.204013, lng: 18.3534 },
    { lat: 48.203943, lng: 18.353516 },
    { lat: 48.203878, lng: 18.353658 },
    { lat: 48.203791, lng: 18.353859 },
    { lat: 48.20376, lng: 18.353916 },
    { lat: 48.203722, lng: 18.353971 },
    { lat: 48.203679, lng: 18.354031 },
    { lat: 48.203617, lng: 18.354074 },
    { lat: 48.203548, lng: 18.354144 },
    { lat: 48.20346, lng: 18.354226 },
    { lat: 48.203354, lng: 18.354332 },
    { lat: 48.203281, lng: 18.354403 },
    { lat: 48.203279, lng: 18.354405 },
    { lat: 48.203235, lng: 18.354441 },
    { lat: 48.202875, lng: 18.354746 },
    { lat: 48.202541, lng: 18.35503 },
    { lat: 48.202374, lng: 18.355163 },
    { lat: 48.202273, lng: 18.355232 },
    { lat: 48.20217, lng: 18.355315 },
    { lat: 48.202082, lng: 18.355391 },
    { lat: 48.201986, lng: 18.355483 },
    { lat: 48.201658, lng: 18.355827 },
    { lat: 48.201309, lng: 18.356204 },
    { lat: 48.201021, lng: 18.356505 },
    { lat: 48.200905, lng: 18.356617 },
    { lat: 48.200724, lng: 18.356776 },
    { lat: 48.200546, lng: 18.356938 },
    { lat: 48.200303, lng: 18.357149 },
    { lat: 48.200162, lng: 18.357278 },
    { lat: 48.19987, lng: 18.357526 },
    { lat: 48.199753, lng: 18.357635 },
    { lat: 48.199621, lng: 18.357758 },
    { lat: 48.199469, lng: 18.357883 },
    { lat: 48.199395, lng: 18.357886 },
    { lat: 48.199251, lng: 18.357883 },
    { lat: 48.199052, lng: 18.357874 },
    { lat: 48.198885, lng: 18.357864 },
    { lat: 48.198716, lng: 18.357849 },
    { lat: 48.198701, lng: 18.357848 },
    { lat: 48.198679, lng: 18.357844 },
    { lat: 48.198563, lng: 18.35782 },
    { lat: 48.198525, lng: 18.357813 },
    { lat: 48.198507, lng: 18.357809 },
    { lat: 48.198386, lng: 18.357776 },
    { lat: 48.198357, lng: 18.357768 },
    { lat: 48.198316, lng: 18.357757 },
    { lat: 48.198263, lng: 18.357743 },
    { lat: 48.198215, lng: 18.35773 },
    { lat: 48.198171, lng: 18.357711 },
    { lat: 48.198113, lng: 18.357687 },
    { lat: 48.198074, lng: 18.357671 },
    { lat: 48.198043, lng: 18.357658 },
    { lat: 48.198011, lng: 18.357645 },
    { lat: 48.19798, lng: 18.357633 },
    { lat: 48.197891, lng: 18.357595 },
    { lat: 48.197841, lng: 18.357575 },
    { lat: 48.197825, lng: 18.357568 },
    { lat: 48.197798, lng: 18.357556 },
    { lat: 48.197765, lng: 18.357543 },
    { lat: 48.197707, lng: 18.357522 },
    { lat: 48.197649, lng: 18.357501 },
    { lat: 48.19762, lng: 18.357491 },
    { lat: 48.197578, lng: 18.357475 },
    { lat: 48.197534, lng: 18.35746 },
    { lat: 48.19749, lng: 18.357445 },
    { lat: 48.197469, lng: 18.357439 },
    { lat: 48.197436, lng: 18.357428 },
    { lat: 48.197411, lng: 18.35742 },
    { lat: 48.197381, lng: 18.357411 },
    { lat: 48.197313, lng: 18.35739 },
    { lat: 48.197278, lng: 18.357378 },
    { lat: 48.197249, lng: 18.357369 },
    { lat: 48.197218, lng: 18.35736 },
    { lat: 48.197196, lng: 18.357353 },
    { lat: 48.197149, lng: 18.357337 },
    { lat: 48.197078, lng: 18.357313 },
    { lat: 48.197051, lng: 18.357304 },
    { lat: 48.197019, lng: 18.357291 },
    { lat: 48.196946, lng: 18.357264 },
    { lat: 48.196929, lng: 18.357258 },
    { lat: 48.196911, lng: 18.357251 },
    { lat: 48.196904, lng: 18.357248 },
    { lat: 48.196896, lng: 18.357245 },
    { lat: 48.196882, lng: 18.35724 },
    { lat: 48.19681, lng: 18.357214 },
    { lat: 48.196728, lng: 18.357181 },
    { lat: 48.196659, lng: 18.357153 },
    { lat: 48.196624, lng: 18.357139 },
    { lat: 48.196586, lng: 18.357126 },
    { lat: 48.196561, lng: 18.357116 },
    { lat: 48.196426, lng: 18.357068 },
    { lat: 48.19621, lng: 18.357068 },
    { lat: 48.196187, lng: 18.357055 },
    { lat: 48.196056, lng: 18.357062 },
    { lat: 48.195875, lng: 18.35727 },
    { lat: 48.195261, lng: 18.356929 },
    { lat: 48.194892, lng: 18.356718 },
    { lat: 48.194559, lng: 18.356495 },
    { lat: 48.194313, lng: 18.356343 },
    { lat: 48.194082, lng: 18.3562 },
    { lat: 48.193534, lng: 18.355848 },
    { lat: 48.193517, lng: 18.355766 },
    { lat: 48.193468, lng: 18.355531 },
    { lat: 48.193289, lng: 18.354672 },
    { lat: 48.193277, lng: 18.354622 },
    { lat: 48.193164, lng: 18.354105 },
    { lat: 48.193087, lng: 18.353892 },
    { lat: 48.193016, lng: 18.353674 },
    { lat: 48.192848, lng: 18.353158 },
    { lat: 48.192789, lng: 18.352979 },
    { lat: 48.192553, lng: 18.352255 },
    { lat: 48.192348, lng: 18.351593 },
    { lat: 48.192229, lng: 18.351206 },
    { lat: 48.19211, lng: 18.350821 },
    { lat: 48.192077, lng: 18.350715 },
    { lat: 48.191955, lng: 18.350315 },
    { lat: 48.191861, lng: 18.350001 },
    { lat: 48.191774, lng: 18.349716 },
    { lat: 48.19166, lng: 18.34934 },
    { lat: 48.191611, lng: 18.349182 },
    { lat: 48.1912806, lng: 18.3509677 },
    { lat: 48.1888055, lng: 18.3576544 },
    { lat: 48.1883769, lng: 18.3586521 },
    { lat: 48.1871192, lng: 18.360963 },
    { lat: 48.1839172, lng: 18.3658613 },
    { lat: 48.1824782, lng: 18.3685016 },
    { lat: 48.1821564, lng: 18.3688768 },
    { lat: 48.1804029, lng: 18.3703053 },
    { lat: 48.1783993, lng: 18.3723013 },
    { lat: 48.1776726, lng: 18.3731751 },
    { lat: 48.176456, lng: 18.3750635 },
    { lat: 48.1752537, lng: 18.3766259 },
    { lat: 48.175003, lng: 18.3770581 },
    { lat: 48.174132, lng: 18.3792151 },
    { lat: 48.1738593, lng: 18.3797394 },
    { lat: 48.1736285, lng: 18.3800485 },
    { lat: 48.1731315, lng: 18.3809324 },
    { lat: 48.1718423, lng: 18.3829735 },
    { lat: 48.1713227, lng: 18.383946 },
    { lat: 48.1702001, lng: 18.3854198 },
    { lat: 48.1696847, lng: 18.3859638 },
    { lat: 48.168814, lng: 18.3864664 },
    { lat: 48.168197, lng: 18.3866093 },
    { lat: 48.1679489, lng: 18.3872322 },
    { lat: 48.167735, lng: 18.3884975 },
    { lat: 48.1674165, lng: 18.3894513 },
    { lat: 48.1671084, lng: 18.3896676 },
    { lat: 48.1670333, lng: 18.389958 },
    { lat: 48.1669281, lng: 18.3899914 },
    { lat: 48.1667658, lng: 18.3902741 },
    { lat: 48.1667439, lng: 18.3904267 },
    { lat: 48.1659346, lng: 18.3909564 },
    { lat: 48.1659222, lng: 18.3910764 },
    { lat: 48.1659299, lng: 18.3911545 },
    { lat: 48.1659267, lng: 18.3913359 },
    { lat: 48.1660936, lng: 18.3919764 },
    { lat: 48.1666366, lng: 18.3933413 },
    { lat: 48.1667319, lng: 18.3936901 },
    { lat: 48.1668958, lng: 18.3949573 },
    { lat: 48.1671574, lng: 18.3959728 },
    { lat: 48.1675523, lng: 18.397296 },
    { lat: 48.1677624, lng: 18.3977799 },
    { lat: 48.1680345, lng: 18.3982418 },
    { lat: 48.1682284, lng: 18.3984018 },
    { lat: 48.1690674, lng: 18.3994327 },
    { lat: 48.1707813, lng: 18.401659 },
    { lat: 48.1706523, lng: 18.4018951 },
    { lat: 48.1709413, lng: 18.4023574 },
    { lat: 48.1716235, lng: 18.4032296 },
    { lat: 48.1741411, lng: 18.405044 },
    { lat: 48.17432, lng: 18.4046563 },
    { lat: 48.1747802, lng: 18.4050742 },
    { lat: 48.1749552, lng: 18.4050168 },
    { lat: 48.1754043, lng: 18.4043986 },
    { lat: 48.1753356, lng: 18.4042574 },
    { lat: 48.1757896, lng: 18.4034449 },
    { lat: 48.1762421, lng: 18.4038266 },
    { lat: 48.1772327, lng: 18.4046718 },
    { lat: 48.1784292, lng: 18.405683 },
    { lat: 48.1792805, lng: 18.4066576 },
    { lat: 48.1791838, lng: 18.4067835 },
    { lat: 48.1807666, lng: 18.4090266 },
    { lat: 48.1807636, lng: 18.4091751 },
    { lat: 48.1812495, lng: 18.4098482 },
    { lat: 48.1815529, lng: 18.4101487 },
    { lat: 48.1821405, lng: 18.410885 },
    { lat: 48.182421, lng: 18.411001 },
    { lat: 48.182741, lng: 18.4106501 },
    { lat: 48.1829104, lng: 18.4106358 },
    { lat: 48.1835351, lng: 18.411272 },
    { lat: 48.1845227, lng: 18.412581 },
    { lat: 48.1848146, lng: 18.4128582 },
    { lat: 48.1850735, lng: 18.4127865 },
    { lat: 48.1856339, lng: 18.4115408 },
    { lat: 48.1858846, lng: 18.4114414 },
    { lat: 48.186459, lng: 18.4110446 },
    { lat: 48.1869773, lng: 18.4109717 },
    { lat: 48.1875323, lng: 18.410793 },
    { lat: 48.1880888, lng: 18.4102969 },
    { lat: 48.1883298, lng: 18.4099887 },
    { lat: 48.1887687, lng: 18.4097094 },
    { lat: 48.1903292, lng: 18.4093507 },
    { lat: 48.1914999, lng: 18.409161 },
    { lat: 48.1921488, lng: 18.4091858 },
    { lat: 48.1923574, lng: 18.4095651 },
    { lat: 48.1926004, lng: 18.4098552 },
    { lat: 48.1926605, lng: 18.4100414 },
    { lat: 48.1925375, lng: 18.4114224 },
    { lat: 48.1925393, lng: 18.4119585 },
    { lat: 48.1920911, lng: 18.4146423 },
    { lat: 48.1921082, lng: 18.4149831 },
    { lat: 48.1922191, lng: 18.4153927 },
    { lat: 48.1925245, lng: 18.4160689 },
    { lat: 48.1928549, lng: 18.4164283 },
    { lat: 48.1937239, lng: 18.4175973 },
    { lat: 48.1940694, lng: 18.4178445 },
    { lat: 48.1941909, lng: 18.4180447 },
    { lat: 48.1944099, lng: 18.4179803 },
    { lat: 48.1948748, lng: 18.4180626 },
    { lat: 48.1949776, lng: 18.4181837 },
    { lat: 48.1951687, lng: 18.4182523 },
    { lat: 48.1962752, lng: 18.4194113 },
    { lat: 48.1963887, lng: 18.4200679 },
    { lat: 48.1963721, lng: 18.420454 },
    { lat: 48.1961565, lng: 18.4221217 },
    { lat: 48.1967934, lng: 18.4211669 },
    { lat: 48.2007653, lng: 18.4151733 },
    { lat: 48.2021059, lng: 18.4134154 },
    { lat: 48.2057747, lng: 18.4120093 },
    { lat: 48.2072807, lng: 18.4113042 },
    { lat: 48.2077408, lng: 18.41028 },
    { lat: 48.2079766, lng: 18.4097389 },
    { lat: 48.2080804, lng: 18.4074315 },
    { lat: 48.2081468, lng: 18.4023792 },
    { lat: 48.2078334, lng: 18.399799 },
    { lat: 48.2081303, lng: 18.3960757 },
    { lat: 48.208549, lng: 18.3939761 },
    { lat: 48.2091501, lng: 18.3926322 },
    { lat: 48.209541, lng: 18.3916021 },
    { lat: 48.2105603, lng: 18.3899122 },
    { lat: 48.211037, lng: 18.389367 },
    { lat: 48.211038, lng: 18.389347 },
    { lat: 48.211038, lng: 18.389291 },
    { lat: 48.211224, lng: 18.38904 },
    { lat: 48.211646, lng: 18.38847 },
    { lat: 48.212019, lng: 18.387967 },
    { lat: 48.212325, lng: 18.387577 },
    { lat: 48.212531, lng: 18.387297 },
    { lat: 48.213044, lng: 18.38663 },
    { lat: 48.213346, lng: 18.386237 },
    { lat: 48.213684, lng: 18.38584 },
    { lat: 48.214041, lng: 18.385525 },
    { lat: 48.214218, lng: 18.38537 },
    { lat: 48.214402, lng: 18.385207 },
    { lat: 48.214728, lng: 18.38482 },
    { lat: 48.215036, lng: 18.384194 },
    { lat: 48.215061, lng: 18.384099 },
    { lat: 48.215122, lng: 18.383895 },
    { lat: 48.215206, lng: 18.383611 },
    { lat: 48.215223, lng: 18.383545 },
    { lat: 48.215271, lng: 18.383164 },
    { lat: 48.215303, lng: 18.382636 },
    { lat: 48.215311, lng: 18.382513 },
    { lat: 48.215324, lng: 18.382322 },
    { lat: 48.215384, lng: 18.381424 },
    { lat: 48.215431, lng: 18.38076 },
    { lat: 48.215845, lng: 18.378732 },
    { lat: 48.216368, lng: 18.377203 },
    { lat: 48.21652, lng: 18.376768 },
    { lat: 48.21681, lng: 18.376144 },
    { lat: 48.216934, lng: 18.37588 },
    { lat: 48.2172, lng: 18.375301 },
    { lat: 48.217685, lng: 18.374069 },
    { lat: 48.217653, lng: 18.373246 },
    { lat: 48.21761, lng: 18.372346 },
    { lat: 48.217737, lng: 18.371785 },
    { lat: 48.217753, lng: 18.371721 },
    { lat: 48.218033, lng: 18.370545 },
    { lat: 48.218223, lng: 18.369643 },
    { lat: 48.21829, lng: 18.369336 },
    { lat: 48.218549, lng: 18.368393 },
    { lat: 48.219031, lng: 18.36756 },
    { lat: 48.219736, lng: 18.366234 },
    { lat: 48.220056, lng: 18.365741 },
    { lat: 48.220318, lng: 18.365316 },
    { lat: 48.220522, lng: 18.364999 },
    { lat: 48.220525, lng: 18.364994 },
    { lat: 48.22082, lng: 18.364434 },
    { lat: 48.22094, lng: 18.364183 },
    { lat: 48.221278, lng: 18.363328 },
    { lat: 48.221497, lng: 18.362706 },
    { lat: 48.221578, lng: 18.362508 },
    { lat: 48.221637, lng: 18.362332 },
    { lat: 48.221697, lng: 18.36215 },
    { lat: 48.221889, lng: 18.361566 },
    { lat: 48.22197, lng: 18.361244 },
    { lat: 48.222015, lng: 18.361016 },
    { lat: 48.222147, lng: 18.360301 },
    { lat: 48.222175, lng: 18.360195 },
    { lat: 48.222249, lng: 18.359964 },
    { lat: 48.222391, lng: 18.359574 },
    { lat: 48.222497, lng: 18.359326 },
    { lat: 48.222548, lng: 18.35922 },
    { lat: 48.222612, lng: 18.35913 },
    { lat: 48.222688, lng: 18.35904 },
    { lat: 48.222819, lng: 18.35893 },
    { lat: 48.222923, lng: 18.358781 },
    { lat: 48.222942, lng: 18.358738 },
    { lat: 48.222947, lng: 18.358726 },
    { lat: 48.222948, lng: 18.358724 },
  ],
  Levice: [
    { lat: 48.2347943, lng: 18.6826613 },
    { lat: 48.2346377, lng: 18.6827248 },
    { lat: 48.2343004, lng: 18.6828744 },
    { lat: 48.2340029, lng: 18.6833571 },
    { lat: 48.233837, lng: 18.6840085 },
    { lat: 48.233545, lng: 18.6857297 },
    { lat: 48.2334341, lng: 18.6870209 },
    { lat: 48.233146, lng: 18.6878112 },
    { lat: 48.2332603, lng: 18.6890324 },
    { lat: 48.2331129, lng: 18.6896933 },
    { lat: 48.2331628, lng: 18.6905558 },
    { lat: 48.2334569, lng: 18.6924855 },
    { lat: 48.2334288, lng: 18.6932816 },
    { lat: 48.2328934, lng: 18.6957884 },
    { lat: 48.2331913, lng: 18.6965488 },
    { lat: 48.2331086, lng: 18.6975302 },
    { lat: 48.2329958, lng: 18.6981154 },
    { lat: 48.2332424, lng: 18.6991177 },
    { lat: 48.2334835, lng: 18.7008398 },
    { lat: 48.2337113, lng: 18.7029813 },
    { lat: 48.2336499, lng: 18.7036254 },
    { lat: 48.233488, lng: 18.7040754 },
    { lat: 48.2334501, lng: 18.7047393 },
    { lat: 48.2333453, lng: 18.7053908 },
    { lat: 48.2331404, lng: 18.7061975 },
    { lat: 48.2330982, lng: 18.7065875 },
    { lat: 48.2330977, lng: 18.7073371 },
    { lat: 48.2328985, lng: 18.7084615 },
    { lat: 48.2325884, lng: 18.7088628 },
    { lat: 48.2324433, lng: 18.7089732 },
    { lat: 48.232337, lng: 18.7089474 },
    { lat: 48.2316485, lng: 18.7112105 },
    { lat: 48.2319778, lng: 18.7115689 },
    { lat: 48.2334356, lng: 18.7135618 },
    { lat: 48.2346814, lng: 18.7144196 },
    { lat: 48.2349087, lng: 18.7144935 },
    { lat: 48.2365758, lng: 18.7150853 },
    { lat: 48.2383396, lng: 18.7160314 },
    { lat: 48.2394517, lng: 18.7167716 },
    { lat: 48.2400433, lng: 18.7155586 },
    { lat: 48.240589, lng: 18.7142723 },
    { lat: 48.2415079, lng: 18.7149178 },
    { lat: 48.2422161, lng: 18.713252 },
    { lat: 48.243137, lng: 18.7113014 },
    { lat: 48.2444428, lng: 18.7099516 },
    { lat: 48.2450836, lng: 18.7090941 },
    { lat: 48.2456009, lng: 18.7081396 },
    { lat: 48.2462742, lng: 18.7061353 },
    { lat: 48.2469424, lng: 18.7037243 },
    { lat: 48.2470838, lng: 18.7025577 },
    { lat: 48.2474508, lng: 18.7014074 },
    { lat: 48.2481753, lng: 18.7006597 },
    { lat: 48.2485612, lng: 18.6999452 },
    { lat: 48.2490677, lng: 18.699822 },
    { lat: 48.2494653, lng: 18.6998538 },
    { lat: 48.2496153, lng: 18.6997693 },
    { lat: 48.2505127, lng: 18.7001228 },
    { lat: 48.2506535, lng: 18.7000542 },
    { lat: 48.2510298, lng: 18.7001091 },
    { lat: 48.2512567, lng: 18.7000514 },
    { lat: 48.2513484, lng: 18.700034 },
    { lat: 48.2515182, lng: 18.6987227 },
    { lat: 48.2528657, lng: 18.6959015 },
    { lat: 48.2527824, lng: 18.6957972 },
    { lat: 48.2529348, lng: 18.6956122 },
    { lat: 48.2531041, lng: 18.6951285 },
    { lat: 48.2533722, lng: 18.6947647 },
    { lat: 48.2535352, lng: 18.6942761 },
    { lat: 48.2541766, lng: 18.6936808 },
    { lat: 48.2541535, lng: 18.693541 },
    { lat: 48.2537543, lng: 18.6929326 },
    { lat: 48.2542762, lng: 18.6919429 },
    { lat: 48.25502, lng: 18.6924143 },
    { lat: 48.2556317, lng: 18.6910998 },
    { lat: 48.2567572, lng: 18.6893316 },
    { lat: 48.2573798, lng: 18.6882203 },
    { lat: 48.258331, lng: 18.6859184 },
    { lat: 48.2632058, lng: 18.6820612 },
    { lat: 48.2635372, lng: 18.6814917 },
    { lat: 48.2635835, lng: 18.6813675 },
    { lat: 48.2635194, lng: 18.6812662 },
    { lat: 48.2629463, lng: 18.6808002 },
    { lat: 48.2624203, lng: 18.6806658 },
    { lat: 48.2622661, lng: 18.6803782 },
    { lat: 48.2614555, lng: 18.6801128 },
    { lat: 48.2609715, lng: 18.6795451 },
    { lat: 48.2604548, lng: 18.6795225 },
    { lat: 48.2604228, lng: 18.679285 },
    { lat: 48.2597724, lng: 18.6785766 },
    { lat: 48.2594545, lng: 18.6778706 },
    { lat: 48.2575401, lng: 18.6763108 },
    { lat: 48.2569236, lng: 18.6775111 },
    { lat: 48.2549863, lng: 18.6808717 },
    { lat: 48.254449, lng: 18.6816401 },
    { lat: 48.2540031, lng: 18.6821373 },
    { lat: 48.2536057, lng: 18.681459 },
    { lat: 48.2534675, lng: 18.681636 },
    { lat: 48.2528009, lng: 18.6808391 },
    { lat: 48.2524841, lng: 18.6797073 },
    { lat: 48.2518683, lng: 18.6785366 },
    { lat: 48.2516365, lng: 18.6782301 },
    { lat: 48.2510012, lng: 18.6776203 },
    { lat: 48.2499283, lng: 18.6767745 },
    { lat: 48.2481919, lng: 18.6756344 },
    { lat: 48.2485855, lng: 18.6742931 },
    { lat: 48.2473983, lng: 18.6722917 },
    { lat: 48.2468872, lng: 18.671679 },
    { lat: 48.2460847, lng: 18.6696991 },
    { lat: 48.2457528, lng: 18.6694038 },
    { lat: 48.2451983, lng: 18.6691184 },
    { lat: 48.2450973, lng: 18.669253 },
    { lat: 48.2447945, lng: 18.6698094 },
    { lat: 48.2443213, lng: 18.6704667 },
    { lat: 48.24366, lng: 18.6715497 },
    { lat: 48.2432003, lng: 18.6718695 },
    { lat: 48.2428962, lng: 18.6722363 },
    { lat: 48.2415693, lng: 18.6753014 },
    { lat: 48.2394917, lng: 18.6780688 },
    { lat: 48.238701, lng: 18.6793448 },
    { lat: 48.2378645, lng: 18.6810463 },
    { lat: 48.2376486, lng: 18.6813521 },
    { lat: 48.235629, lng: 18.6832704 },
    { lat: 48.2351366, lng: 18.6825228 },
    { lat: 48.2347943, lng: 18.6826613 },
  ],
  LeviceExt: [
    { lat: 48.2208302, lng: 18.5645964 },
    { lat: 48.2135617, lng: 18.5610277 },
    { lat: 48.2131452, lng: 18.5612253 },
    { lat: 48.2123003, lng: 18.561925 },
    { lat: 48.202853, lng: 18.5684583 },
    { lat: 48.2028141, lng: 18.5684681 },
    { lat: 48.2026267, lng: 18.5685473 },
    { lat: 48.1995031, lng: 18.5710148 },
    { lat: 48.201695, lng: 18.5733663 },
    { lat: 48.2032111, lng: 18.5792802 },
    { lat: 48.2007939, lng: 18.5825173 },
    { lat: 48.1985496, lng: 18.5842532 },
    { lat: 48.1968649, lng: 18.5859977 },
    { lat: 48.1957139, lng: 18.5866872 },
    { lat: 48.1951547, lng: 18.5868984 },
    { lat: 48.1941782, lng: 18.5869447 },
    { lat: 48.1910399, lng: 18.588716 },
    { lat: 48.1858415, lng: 18.59787 },
    { lat: 48.1858737, lng: 18.597923 },
    { lat: 48.1855184, lng: 18.5983333 },
    { lat: 48.1852836, lng: 18.5990338 },
    { lat: 48.1851198, lng: 18.59973 },
    { lat: 48.185083, lng: 18.6001522 },
    { lat: 48.1851523, lng: 18.6004304 },
    { lat: 48.1851051, lng: 18.600743 },
    { lat: 48.1851284, lng: 18.6008204 },
    { lat: 48.1846364, lng: 18.6012501 },
    { lat: 48.1841998, lng: 18.6014575 },
    { lat: 48.1838985, lng: 18.6013292 },
    { lat: 48.1836142, lng: 18.6010182 },
    { lat: 48.1828414, lng: 18.6006209 },
    { lat: 48.1827938, lng: 18.6006405 },
    { lat: 48.182329, lng: 18.6009888 },
    { lat: 48.1820506, lng: 18.6012811 },
    { lat: 48.1814417, lng: 18.6015523 },
    { lat: 48.1809301, lng: 18.6016892 },
    { lat: 48.1806653, lng: 18.6016714 },
    { lat: 48.1803351, lng: 18.6018435 },
    { lat: 48.179882, lng: 18.6022815 },
    { lat: 48.1797806, lng: 18.6025929 },
    { lat: 48.1794062, lng: 18.6029478 },
    { lat: 48.1793157, lng: 18.6031913 },
    { lat: 48.1791339, lng: 18.6032898 },
    { lat: 48.1788955, lng: 18.6032002 },
    { lat: 48.1783558, lng: 18.6027963 },
    { lat: 48.1782382, lng: 18.6025435 },
    { lat: 48.1782313, lng: 18.6022948 },
    { lat: 48.1781277, lng: 18.6017615 },
    { lat: 48.1781168, lng: 18.6011677 },
    { lat: 48.1781837, lng: 18.6007389 },
    { lat: 48.1779662, lng: 18.6001439 },
    { lat: 48.1778131, lng: 18.6000463 },
    { lat: 48.1775736, lng: 18.5997511 },
    { lat: 48.177371, lng: 18.5996765 },
    { lat: 48.1766171, lng: 18.599812 },
    { lat: 48.1760899, lng: 18.600053 },
    { lat: 48.1759684, lng: 18.6002851 },
    { lat: 48.175913, lng: 18.6009216 },
    { lat: 48.1758359, lng: 18.6012448 },
    { lat: 48.1753244, lng: 18.6015263 },
    { lat: 48.1751332, lng: 18.6017433 },
    { lat: 48.1749972, lng: 18.6021214 },
    { lat: 48.1750648, lng: 18.6036299 },
    { lat: 48.1749437, lng: 18.6045125 },
    { lat: 48.1744519, lng: 18.604552 },
    { lat: 48.1743663, lng: 18.6046158 },
    { lat: 48.1740054, lng: 18.6042655 },
    { lat: 48.1737568, lng: 18.6043191 },
    { lat: 48.1735074, lng: 18.6047513 },
    { lat: 48.1732511, lng: 18.6049359 },
    { lat: 48.1725903, lng: 18.6049977 },
    { lat: 48.1724517, lng: 18.6051489 },
    { lat: 48.1724087, lng: 18.6053294 },
    { lat: 48.1727017, lng: 18.6057407 },
    { lat: 48.1727606, lng: 18.6060141 },
    { lat: 48.1727466, lng: 18.6063981 },
    { lat: 48.172841, lng: 18.6066177 },
    { lat: 48.1730402, lng: 18.6066503 },
    { lat: 48.1733136, lng: 18.6068227 },
    { lat: 48.1734578, lng: 18.6070712 },
    { lat: 48.1734951, lng: 18.6072557 },
    { lat: 48.1734221, lng: 18.6076558 },
    { lat: 48.1734461, lng: 18.6078479 },
    { lat: 48.1736594, lng: 18.6080587 },
    { lat: 48.1737433, lng: 18.6083368 },
    { lat: 48.1737852, lng: 18.6084197 },
    { lat: 48.1738259, lng: 18.608502 },
    { lat: 48.1737954, lng: 18.6085633 },
    { lat: 48.1781179, lng: 18.6165196 },
    { lat: 48.1781748, lng: 18.6166324 },
    { lat: 48.1823833, lng: 18.6244109 },
    { lat: 48.185182, lng: 18.6324953 },
    { lat: 48.1856286, lng: 18.6336562 },
    { lat: 48.1861514, lng: 18.634891 },
    { lat: 48.1888355, lng: 18.6402741 },
    { lat: 48.1884185, lng: 18.6403829 },
    { lat: 48.1872289, lng: 18.6404289 },
    { lat: 48.1870329, lng: 18.6405372 },
    { lat: 48.187027, lng: 18.640973 },
    { lat: 48.1870776, lng: 18.6411952 },
    { lat: 48.1869049, lng: 18.6426925 },
    { lat: 48.1865039, lng: 18.6432652 },
    { lat: 48.1862539, lng: 18.6441869 },
    { lat: 48.1861704, lng: 18.6447254 },
    { lat: 48.1861852, lng: 18.6450651 },
    { lat: 48.1862133, lng: 18.6451649 },
    { lat: 48.1861304, lng: 18.6452573 },
    { lat: 48.1859222, lng: 18.6453316 },
    { lat: 48.1857708, lng: 18.6454698 },
    { lat: 48.1851574, lng: 18.6463058 },
    { lat: 48.1848138, lng: 18.6466902 },
    { lat: 48.1829546, lng: 18.6485121 },
    { lat: 48.1829001, lng: 18.6486322 },
    { lat: 48.1829202, lng: 18.6487333 },
    { lat: 48.1831345, lng: 18.6492343 },
    { lat: 48.1829335, lng: 18.6494594 },
    { lat: 48.1823624, lng: 18.6498671 },
    { lat: 48.1812639, lng: 18.6504535 },
    { lat: 48.1813486, lng: 18.6506429 },
    { lat: 48.1808785, lng: 18.6511454 },
    { lat: 48.1799005, lng: 18.6525378 },
    { lat: 48.1787213, lng: 18.6538435 },
    { lat: 48.1782118, lng: 18.6545168 },
    { lat: 48.1770614, lng: 18.6557652 },
    { lat: 48.1755213, lng: 18.6569038 },
    { lat: 48.1746373, lng: 18.6579301 },
    { lat: 48.175654, lng: 18.6595242 },
    { lat: 48.1761, lng: 18.6605955 },
    { lat: 48.1762045, lng: 18.6609687 },
    { lat: 48.1765237, lng: 18.663678 },
    { lat: 48.1765766, lng: 18.6637599 },
    { lat: 48.1762308, lng: 18.6641883 },
    { lat: 48.1759716, lng: 18.6642948 },
    { lat: 48.1748385, lng: 18.665284 },
    { lat: 48.1752349, lng: 18.6674683 },
    { lat: 48.1730694, lng: 18.6710613 },
    { lat: 48.1719787, lng: 18.6774611 },
    { lat: 48.1714183, lng: 18.6814202 },
    { lat: 48.1715791, lng: 18.682123 },
    { lat: 48.1716432, lng: 18.6836028 },
    { lat: 48.1716208, lng: 18.6844034 },
    { lat: 48.17158, lng: 18.6847118 },
    { lat: 48.1714511, lng: 18.6850449 },
    { lat: 48.171115, lng: 18.6867432 },
    { lat: 48.1709582, lng: 18.6869521 },
    { lat: 48.1708302, lng: 18.6874011 },
    { lat: 48.1706271, lng: 18.6885113 },
    { lat: 48.170644, lng: 18.689347 },
    { lat: 48.1708498, lng: 18.6906772 },
    { lat: 48.1708742, lng: 18.6930825 },
    { lat: 48.1713512, lng: 18.6950321 },
    { lat: 48.1731776, lng: 18.7016731 },
    { lat: 48.1722399, lng: 18.7030095 },
    { lat: 48.1721176, lng: 18.7032531 },
    { lat: 48.1720675, lng: 18.7033514 },
    { lat: 48.1719299, lng: 18.7034336 },
    { lat: 48.171728, lng: 18.7037896 },
    { lat: 48.1715706, lng: 18.7039035 },
    { lat: 48.1709636, lng: 18.7039201 },
    { lat: 48.1709181, lng: 18.703997 },
    { lat: 48.1707882, lng: 18.7046888 },
    { lat: 48.1708039, lng: 18.7053999 },
    { lat: 48.1708824, lng: 18.7058541 },
    { lat: 48.1708175, lng: 18.7064342 },
    { lat: 48.1707208, lng: 18.7066882 },
    { lat: 48.1705038, lng: 18.7070173 },
    { lat: 48.1702339, lng: 18.7076835 },
    { lat: 48.169857, lng: 18.7088791 },
    { lat: 48.1698787, lng: 18.7097902 },
    { lat: 48.1698373, lng: 18.7111416 },
    { lat: 48.1701339, lng: 18.7119805 },
    { lat: 48.1704364, lng: 18.7132716 },
    { lat: 48.1704506, lng: 18.7135137 },
    { lat: 48.1703959, lng: 18.713816 },
    { lat: 48.1704412, lng: 18.7139074 },
    { lat: 48.1707375, lng: 18.7147001 },
    { lat: 48.1709368, lng: 18.7150104 },
    { lat: 48.1709406, lng: 18.7151047 },
    { lat: 48.1711147, lng: 18.7154898 },
    { lat: 48.1714694, lng: 18.7159847 },
    { lat: 48.1717882, lng: 18.7165666 },
    { lat: 48.1723691, lng: 18.7174521 },
    { lat: 48.1740197, lng: 18.719171 },
    { lat: 48.1740576, lng: 18.722321 },
    { lat: 48.1742061, lng: 18.7261313 },
    { lat: 48.1738217, lng: 18.7282227 },
    { lat: 48.1738095, lng: 18.7283089 },
    { lat: 48.1764012, lng: 18.7321313 },
    { lat: 48.1765911, lng: 18.7326022 },
    { lat: 48.1768949, lng: 18.7330748 },
    { lat: 48.1771465, lng: 18.7337765 },
    { lat: 48.1772962, lng: 18.7344251 },
    { lat: 48.1778313, lng: 18.7351955 },
    { lat: 48.1780704, lng: 18.7348362 },
    { lat: 48.1782237, lng: 18.7347271 },
    { lat: 48.1785526, lng: 18.7346783 },
    { lat: 48.1788607, lng: 18.734557 },
    { lat: 48.1790544, lng: 18.7344011 },
    { lat: 48.1790889, lng: 18.7344969 },
    { lat: 48.1792538, lng: 18.7344603 },
    { lat: 48.1809937, lng: 18.7334826 },
    { lat: 48.181219, lng: 18.7335419 },
    { lat: 48.1812872, lng: 18.7336373 },
    { lat: 48.1815893, lng: 18.7338034 },
    { lat: 48.1823124, lng: 18.7347977 },
    { lat: 48.1826975, lng: 18.7356543 },
    { lat: 48.1832696, lng: 18.7372865 },
    { lat: 48.1836736, lng: 18.7378387 },
    { lat: 48.1837597, lng: 18.7380783 },
    { lat: 48.1840201, lng: 18.7381183 },
    { lat: 48.184572, lng: 18.7377032 },
    { lat: 48.1847639, lng: 18.737897 },
    { lat: 48.1864074, lng: 18.7356235 },
    { lat: 48.1871366, lng: 18.7350855 },
    { lat: 48.1883395, lng: 18.7343761 },
    { lat: 48.1892849, lng: 18.7345555 },
    { lat: 48.1901098, lng: 18.7343304 },
    { lat: 48.1917233, lng: 18.733327 },
    { lat: 48.19403, lng: 18.7330607 },
    { lat: 48.1952073, lng: 18.7326769 },
    { lat: 48.196805, lng: 18.7318066 },
    { lat: 48.1982862, lng: 18.7311584 },
    { lat: 48.1993946, lng: 18.7305212 },
    { lat: 48.2004045, lng: 18.7294128 },
    { lat: 48.2012812, lng: 18.7286374 },
    { lat: 48.2016869, lng: 18.728335 },
    { lat: 48.2021415, lng: 18.7282524 },
    { lat: 48.2021746, lng: 18.7282796 },
    { lat: 48.2022568, lng: 18.7283631 },
    { lat: 48.2027055, lng: 18.7281708 },
    { lat: 48.2030525, lng: 18.727761 },
    { lat: 48.2037036, lng: 18.7277079 },
    { lat: 48.2044372, lng: 18.7281141 },
    { lat: 48.204747, lng: 18.7281498 },
    { lat: 48.2054313, lng: 18.7279581 },
    { lat: 48.2065348, lng: 18.727777 },
    { lat: 48.2066923, lng: 18.7276206 },
    { lat: 48.2069504, lng: 18.7271743 },
    { lat: 48.2076092, lng: 18.7266497 },
    { lat: 48.2080007, lng: 18.7264844 },
    { lat: 48.208331, lng: 18.7264997 },
    { lat: 48.2086928, lng: 18.7262037 },
    { lat: 48.2090625, lng: 18.7255244 },
    { lat: 48.2091124, lng: 18.724998 },
    { lat: 48.2090125, lng: 18.7245043 },
    { lat: 48.2091026, lng: 18.7238465 },
    { lat: 48.2092959, lng: 18.7233294 },
    { lat: 48.2094675, lng: 18.7230784 },
    { lat: 48.2097558, lng: 18.7228158 },
    { lat: 48.2103475, lng: 18.7218377 },
    { lat: 48.2104263, lng: 18.7216242 },
    { lat: 48.2105059, lng: 18.7209811 },
    { lat: 48.2107587, lng: 18.7204197 },
    { lat: 48.2109546, lng: 18.7201357 },
    { lat: 48.2110205, lng: 18.7198089 },
    { lat: 48.2112475, lng: 18.7191389 },
    { lat: 48.2113577, lng: 18.7187349 },
    { lat: 48.211258, lng: 18.717608 },
    { lat: 48.2113504, lng: 18.7170782 },
    { lat: 48.2119653, lng: 18.7160321 },
    { lat: 48.2122201, lng: 18.7157763 },
    { lat: 48.2128349, lng: 18.7147882 },
    { lat: 48.2131342, lng: 18.7136774 },
    { lat: 48.213371, lng: 18.7130023 },
    { lat: 48.2134699, lng: 18.7122195 },
    { lat: 48.2137733, lng: 18.7111853 },
    { lat: 48.2142076, lng: 18.7104744 },
    { lat: 48.2146459, lng: 18.7096029 },
    { lat: 48.2147705, lng: 18.7092339 },
    { lat: 48.2153558, lng: 18.7081997 },
    { lat: 48.2153653, lng: 18.708148 },
    { lat: 48.2142791, lng: 18.7078307 },
    { lat: 48.2137001, lng: 18.7075706 },
    { lat: 48.2133044, lng: 18.7072772 },
    { lat: 48.2126827, lng: 18.7071028 },
    { lat: 48.2122882, lng: 18.7068563 },
    { lat: 48.2120561, lng: 18.7068205 },
    { lat: 48.2105194, lng: 18.7061362 },
    { lat: 48.2103728, lng: 18.7060109 },
    { lat: 48.2099368, lng: 18.7054303 },
    { lat: 48.209391, lng: 18.7051546 },
    { lat: 48.2092648, lng: 18.7050315 },
    { lat: 48.2088291, lng: 18.7042896 },
    { lat: 48.2085867, lng: 18.7041229 },
    { lat: 48.2078398, lng: 18.7038225 },
    { lat: 48.2076102, lng: 18.7032253 },
    { lat: 48.2076676, lng: 18.7019381 },
    { lat: 48.2075228, lng: 18.7011331 },
    { lat: 48.2069637, lng: 18.699646 },
    { lat: 48.2066342, lng: 18.6990742 },
    { lat: 48.2053553, lng: 18.6975453 },
    { lat: 48.2048694, lng: 18.6968764 },
    { lat: 48.2040985, lng: 18.6957716 },
    { lat: 48.2034027, lng: 18.6946404 },
    { lat: 48.2026513, lng: 18.6950981 },
    { lat: 48.2008747, lng: 18.6956998 },
    { lat: 48.200592, lng: 18.6950787 },
    { lat: 48.200303, lng: 18.6946128 },
    { lat: 48.2000315, lng: 18.694346 },
    { lat: 48.1992671, lng: 18.6938698 },
    { lat: 48.1992646, lng: 18.6933169 },
    { lat: 48.1987249, lng: 18.6930544 },
    { lat: 48.1986041, lng: 18.6928614 },
    { lat: 48.1973768, lng: 18.6935735 },
    { lat: 48.1969197, lng: 18.6936695 },
    { lat: 48.1957421, lng: 18.6935442 },
    { lat: 48.1959177, lng: 18.6929727 },
    { lat: 48.1958322, lng: 18.692996 },
    { lat: 48.1968029, lng: 18.6895504 },
    { lat: 48.1975114, lng: 18.6864066 },
    { lat: 48.1979499, lng: 18.6848719 },
    { lat: 48.1982598, lng: 18.6830857 },
    { lat: 48.1987129, lng: 18.6809429 },
    { lat: 48.1989252, lng: 18.6795238 },
    { lat: 48.1991794, lng: 18.676788 },
    { lat: 48.1993399, lng: 18.6750607 },
    { lat: 48.1994123, lng: 18.6750223 },
    { lat: 48.2004267, lng: 18.6722265 },
    { lat: 48.2023659, lng: 18.6736153 },
    { lat: 48.2045593, lng: 18.6654564 },
    { lat: 48.2053577, lng: 18.6648875 },
    { lat: 48.2059281, lng: 18.6648632 },
    { lat: 48.2070364, lng: 18.664456 },
    { lat: 48.2070531, lng: 18.6641789 },
    { lat: 48.2075018, lng: 18.6630077 },
    { lat: 48.2112247, lng: 18.6640892 },
    { lat: 48.211546, lng: 18.6638587 },
    { lat: 48.2122541, lng: 18.6629361 },
    { lat: 48.2126442, lng: 18.6625781 },
    { lat: 48.2127636, lng: 18.662343 },
    { lat: 48.2131503, lng: 18.6613804 },
    { lat: 48.213384, lng: 18.6604872 },
    { lat: 48.2139027, lng: 18.6595849 },
    { lat: 48.2140159, lng: 18.6592834 },
    { lat: 48.2142055, lng: 18.6584708 },
    { lat: 48.2144465, lng: 18.6579402 },
    { lat: 48.2148993, lng: 18.6576983 },
    { lat: 48.2151674, lng: 18.6572762 },
    { lat: 48.215797, lng: 18.6573627 },
    { lat: 48.2164453, lng: 18.656694 },
    { lat: 48.2167329, lng: 18.6565262 },
    { lat: 48.2168222, lng: 18.6563598 },
    { lat: 48.2174688, lng: 18.65593 },
    { lat: 48.2175721, lng: 18.6557124 },
    { lat: 48.2182816, lng: 18.6552959 },
    { lat: 48.2182958, lng: 18.6552867 },
    { lat: 48.2187019, lng: 18.6550169 },
    { lat: 48.219216, lng: 18.6549702 },
    { lat: 48.219626, lng: 18.6551696 },
    { lat: 48.220109, lng: 18.6548183 },
    { lat: 48.2204083, lng: 18.6545575 },
    { lat: 48.2215517, lng: 18.6540714 },
    { lat: 48.2219042, lng: 18.6538494 },
    { lat: 48.2228879, lng: 18.6535831 },
    { lat: 48.2230518, lng: 18.6533401 },
    { lat: 48.2246079, lng: 18.6532698 },
    { lat: 48.2281997, lng: 18.6514461 },
    { lat: 48.2288564, lng: 18.6515759 },
    { lat: 48.2305354, lng: 18.6485854 },
    { lat: 48.2308699, lng: 18.6477213 },
    { lat: 48.2304149, lng: 18.6470435 },
    { lat: 48.230098, lng: 18.6464214 },
    { lat: 48.2295458, lng: 18.645119 },
    { lat: 48.2311761, lng: 18.6425412 },
    { lat: 48.2312577, lng: 18.641861 },
    { lat: 48.2318803, lng: 18.6412774 },
    { lat: 48.2332063, lng: 18.6394159 },
    { lat: 48.2334515, lng: 18.6390504 },
    { lat: 48.2341889, lng: 18.6375061 },
    { lat: 48.2345285, lng: 18.6370298 },
    { lat: 48.2351813, lng: 18.6362528 },
    { lat: 48.2350009, lng: 18.6359867 },
    { lat: 48.2347902, lng: 18.6354873 },
    { lat: 48.2338749, lng: 18.6342959 },
    { lat: 48.234309, lng: 18.6335621 },
    { lat: 48.2337653, lng: 18.6326882 },
    { lat: 48.2343872, lng: 18.6315739 },
    { lat: 48.2362034, lng: 18.6280469 },
    { lat: 48.2372786, lng: 18.6258478 },
    { lat: 48.2380249, lng: 18.6244698 },
    { lat: 48.2390065, lng: 18.6231032 },
    { lat: 48.235402, lng: 18.6197888 },
    { lat: 48.235069, lng: 18.619016 },
    { lat: 48.2346005, lng: 18.6177241 },
    { lat: 48.2353526, lng: 18.6163064 },
    { lat: 48.2349567, lng: 18.6157274 },
    { lat: 48.2354877, lng: 18.6143526 },
    { lat: 48.2356834, lng: 18.6136583 },
    { lat: 48.2353009, lng: 18.6133476 },
    { lat: 48.2361351, lng: 18.6109957 },
    { lat: 48.2368498, lng: 18.6083547 },
    { lat: 48.2370852, lng: 18.6066695 },
    { lat: 48.2371806, lng: 18.6064558 },
    { lat: 48.2376425, lng: 18.6066733 },
    { lat: 48.2390018, lng: 18.607089 },
    { lat: 48.2390523, lng: 18.6054252 },
    { lat: 48.2391309, lng: 18.604426 },
    { lat: 48.2393958, lng: 18.6052754 },
    { lat: 48.2395285, lng: 18.6054339 },
    { lat: 48.2396087, lng: 18.6054571 },
    { lat: 48.2399617, lng: 18.6052898 },
    { lat: 48.2403861, lng: 18.6054167 },
    { lat: 48.2409555, lng: 18.6050835 },
    { lat: 48.2413961, lng: 18.604723 },
    { lat: 48.241655, lng: 18.6042993 },
    { lat: 48.2417758, lng: 18.6039873 },
    { lat: 48.2415807, lng: 18.6033491 },
    { lat: 48.2410927, lng: 18.6022173 },
    { lat: 48.2405475, lng: 18.6018165 },
    { lat: 48.2405382, lng: 18.6018109 },
    { lat: 48.2398718, lng: 18.6016092 },
    { lat: 48.239762, lng: 18.6012072 },
    { lat: 48.2392566, lng: 18.6005436 },
    { lat: 48.2392625, lng: 18.6008207 },
    { lat: 48.2391901, lng: 18.6013146 },
    { lat: 48.2393972, lng: 18.6016145 },
    { lat: 48.2396411, lng: 18.6021168 },
    { lat: 48.2387946, lng: 18.6029357 },
    { lat: 48.2372875, lng: 18.6005928 },
    { lat: 48.2369431, lng: 18.5999251 },
    { lat: 48.2368571, lng: 18.5994229 },
    { lat: 48.2364779, lng: 18.5981991 },
    { lat: 48.2364512, lng: 18.5982243 },
    { lat: 48.2322332, lng: 18.5925326 },
    { lat: 48.2287458, lng: 18.5879298 },
    { lat: 48.2288261, lng: 18.5876482 },
    { lat: 48.2291242, lng: 18.587051 },
    { lat: 48.2293729, lng: 18.5870011 },
    { lat: 48.229493, lng: 18.5869111 },
    { lat: 48.2295674, lng: 18.5866586 },
    { lat: 48.2295421, lng: 18.5863206 },
    { lat: 48.2296594, lng: 18.5860328 },
    { lat: 48.2299572, lng: 18.5859879 },
    { lat: 48.2301795, lng: 18.5856189 },
    { lat: 48.2302551, lng: 18.5853546 },
    { lat: 48.2304416, lng: 18.5850091 },
    { lat: 48.2308748, lng: 18.5848857 },
    { lat: 48.2311102, lng: 18.5846308 },
    { lat: 48.2312843, lng: 18.5840841 },
    { lat: 48.2314062, lng: 18.5834419 },
    { lat: 48.2316268, lng: 18.5830858 },
    { lat: 48.2317899, lng: 18.5826838 },
    { lat: 48.2317254, lng: 18.5826192 },
    { lat: 48.2317021, lng: 18.5824614 },
    { lat: 48.231833, lng: 18.5821913 },
    { lat: 48.2320151, lng: 18.5821595 },
    { lat: 48.2322435, lng: 18.5824205 },
    { lat: 48.2322944, lng: 18.5824203 },
    { lat: 48.2326563, lng: 18.5822104 },
    { lat: 48.2326368, lng: 18.5821431 },
    { lat: 48.2228509, lng: 18.5740927 },
    { lat: 48.2208696, lng: 18.5646164 },
    { lat: 48.2208302, lng: 18.5645964 },
  ],
  Plášťovce: [
    { lat: 48.17702, lng: 18.938199 },
    { lat: 48.176993, lng: 18.938219 },
    { lat: 48.176956, lng: 18.938188 },
    { lat: 48.176926, lng: 18.938247 },
    { lat: 48.176878, lng: 18.938163 },
    { lat: 48.176863, lng: 18.938096 },
    { lat: 48.17684, lng: 18.938088 },
    { lat: 48.176608, lng: 18.938005 },
    { lat: 48.176361, lng: 18.93767 },
    { lat: 48.176092, lng: 18.937487 },
    { lat: 48.175812, lng: 18.937369 },
    { lat: 48.175549, lng: 18.937102 },
    { lat: 48.175291, lng: 18.936727 },
    { lat: 48.175058, lng: 18.936338 },
    { lat: 48.174609, lng: 18.93596 },
    { lat: 48.174443, lng: 18.935752 },
    { lat: 48.174112, lng: 18.935494 },
    { lat: 48.174212, lng: 18.935072 },
    { lat: 48.174262, lng: 18.934724 },
    { lat: 48.174263, lng: 18.93455 },
    { lat: 48.174266, lng: 18.934075 },
    { lat: 48.174121, lng: 18.933422 },
    { lat: 48.174032, lng: 18.933493 },
    { lat: 48.174009, lng: 18.933446 },
    { lat: 48.173837, lng: 18.93375 },
    { lat: 48.173795, lng: 18.93379 },
    { lat: 48.173753, lng: 18.933829 },
    { lat: 48.173733, lng: 18.933813 },
    { lat: 48.173658, lng: 18.933752 },
    { lat: 48.173309, lng: 18.933467 },
    { lat: 48.172762, lng: 18.933196 },
    { lat: 48.172577, lng: 18.933265 },
    { lat: 48.172222, lng: 18.933096 },
    { lat: 48.171451, lng: 18.932608 },
    { lat: 48.171165, lng: 18.932299 },
    { lat: 48.171144, lng: 18.932129 },
    { lat: 48.171064, lng: 18.932022 },
    { lat: 48.170775, lng: 18.931911 },
    { lat: 48.170638, lng: 18.931946 },
    { lat: 48.170351, lng: 18.931836 },
    { lat: 48.169974, lng: 18.931805 },
    { lat: 48.169614, lng: 18.931808 },
    { lat: 48.168192, lng: 18.931498 },
    { lat: 48.16765, lng: 18.931133 },
    { lat: 48.167248, lng: 18.930713 },
    { lat: 48.166829, lng: 18.930401 },
    { lat: 48.166644, lng: 18.930093 },
    { lat: 48.166454, lng: 18.929572 },
    { lat: 48.166394, lng: 18.929028 },
    { lat: 48.16636, lng: 18.928866 },
    { lat: 48.166322, lng: 18.928785 },
    { lat: 48.166243, lng: 18.928719 },
    { lat: 48.166169, lng: 18.928686 },
    { lat: 48.166027, lng: 18.928688 },
    { lat: 48.165874, lng: 18.928782 },
    { lat: 48.165745, lng: 18.928869 },
    { lat: 48.165223, lng: 18.929279 },
    { lat: 48.165153, lng: 18.929358 },
    { lat: 48.164229, lng: 18.930445 },
    { lat: 48.16391, lng: 18.931208 },
    { lat: 48.163194, lng: 18.9316 },
    { lat: 48.162626, lng: 18.932507 },
    { lat: 48.162379, lng: 18.932823 },
    { lat: 48.162388, lng: 18.932841 },
    { lat: 48.162267, lng: 18.933013 },
    { lat: 48.162152, lng: 18.933104 },
    { lat: 48.162017, lng: 18.933177 },
    { lat: 48.161784, lng: 18.933005 },
    { lat: 48.161746, lng: 18.932989 },
    { lat: 48.161577, lng: 18.932928 },
    { lat: 48.160914, lng: 18.932419 },
    { lat: 48.16079, lng: 18.932343 },
    { lat: 48.160398, lng: 18.932129 },
    { lat: 48.160207, lng: 18.931996 },
    { lat: 48.160121, lng: 18.931933 },
    { lat: 48.159839, lng: 18.931747 },
    { lat: 48.159618, lng: 18.931702 },
    { lat: 48.159544, lng: 18.931686 },
    { lat: 48.15941, lng: 18.931693 },
    { lat: 48.159362, lng: 18.931695 },
    { lat: 48.159183, lng: 18.931703 },
    { lat: 48.15875, lng: 18.93166 },
    { lat: 48.1580936, lng: 18.931542 },
    { lat: 48.1577889, lng: 18.9314055 },
    { lat: 48.1567453, lng: 18.9312654 },
    { lat: 48.1560324, lng: 18.9308671 },
    { lat: 48.1554081, lng: 18.9303738 },
    { lat: 48.1550593, lng: 18.9299938 },
    { lat: 48.1544488, lng: 18.9290251 },
    { lat: 48.1532607, lng: 18.9289698 },
    { lat: 48.152544, lng: 18.9291049 },
    { lat: 48.1513115, lng: 18.9297589 },
    { lat: 48.1505724, lng: 18.9302746 },
    { lat: 48.1500809, lng: 18.9303201 },
    { lat: 48.1495327, lng: 18.9301182 },
    { lat: 48.1493104, lng: 18.9293519 },
    { lat: 48.14905, lng: 18.9288544 },
    { lat: 48.1474653, lng: 18.9305225 },
    { lat: 48.1473182, lng: 18.9306903 },
    { lat: 48.1472715, lng: 18.9308333 },
    { lat: 48.1459081, lng: 18.9313081 },
    { lat: 48.1453764, lng: 18.9316259 },
    { lat: 48.1452015, lng: 18.931448 },
    { lat: 48.1450883, lng: 18.9314826 },
    { lat: 48.1449774, lng: 18.9315833 },
    { lat: 48.1449776, lng: 18.9317834 },
    { lat: 48.1449039, lng: 18.9318206 },
    { lat: 48.1449838, lng: 18.9324924 },
    { lat: 48.1450488, lng: 18.9339921 },
    { lat: 48.1451577, lng: 18.9348117 },
    { lat: 48.1452912, lng: 18.9354092 },
    { lat: 48.1453474, lng: 18.9372842 },
    { lat: 48.1456155, lng: 18.9386557 },
    { lat: 48.1457092, lng: 18.9394647 },
    { lat: 48.1456554, lng: 18.9394296 },
    { lat: 48.1449831, lng: 18.9416943 },
    { lat: 48.144339, lng: 18.9436761 },
    { lat: 48.1443417, lng: 18.9442329 },
    { lat: 48.1439764, lng: 18.9459364 },
    { lat: 48.1438473, lng: 18.9461928 },
    { lat: 48.1438857, lng: 18.946301 },
    { lat: 48.1435742, lng: 18.9476728 },
    { lat: 48.1421995, lng: 18.9519149 },
    { lat: 48.1421342, lng: 18.9521784 },
    { lat: 48.1421034, lng: 18.9526317 },
    { lat: 48.1420244, lng: 18.9528268 },
    { lat: 48.141921, lng: 18.9535123 },
    { lat: 48.141926, lng: 18.9538069 },
    { lat: 48.1417036, lng: 18.9545255 },
    { lat: 48.1413707, lng: 18.9549796 },
    { lat: 48.1414261, lng: 18.9550926 },
    { lat: 48.1410779, lng: 18.9568924 },
    { lat: 48.1408174, lng: 18.9571345 },
    { lat: 48.1379018, lng: 18.9612375 },
    { lat: 48.1374317, lng: 18.9623131 },
    { lat: 48.1374208, lng: 18.9625618 },
    { lat: 48.136736, lng: 18.9641551 },
    { lat: 48.1360942, lng: 18.9654861 },
    { lat: 48.1342871, lng: 18.9687403 },
    { lat: 48.1338168, lng: 18.9696806 },
    { lat: 48.1337328, lng: 18.9699599 },
    { lat: 48.1337773, lng: 18.9699876 },
    { lat: 48.1333987, lng: 18.9708936 },
    { lat: 48.1330264, lng: 18.9720309 },
    { lat: 48.132986, lng: 18.9726927 },
    { lat: 48.1329348, lng: 18.9729704 },
    { lat: 48.1328641, lng: 18.973108 },
    { lat: 48.1330554, lng: 18.9736304 },
    { lat: 48.1327417, lng: 18.974548 },
    { lat: 48.1320461, lng: 18.977163 },
    { lat: 48.1321915, lng: 18.9772063 },
    { lat: 48.132124, lng: 18.9811238 },
    { lat: 48.1317982, lng: 18.9816319 },
    { lat: 48.1301768, lng: 18.9836301 },
    { lat: 48.1301974, lng: 18.9860799 },
    { lat: 48.1301457, lng: 18.9865246 },
    { lat: 48.1297326, lng: 18.9876154 },
    { lat: 48.129386, lng: 18.9877394 },
    { lat: 48.1291701, lng: 18.9873529 },
    { lat: 48.128967, lng: 18.9873743 },
    { lat: 48.1288147, lng: 18.9870434 },
    { lat: 48.1286985, lng: 18.9871961 },
    { lat: 48.1285571, lng: 18.9871851 },
    { lat: 48.1284968, lng: 18.9870406 },
    { lat: 48.1284, lng: 18.987088 },
    { lat: 48.1283126, lng: 18.9870337 },
    { lat: 48.1281538, lng: 18.9867113 },
    { lat: 48.1276623, lng: 18.986446 },
    { lat: 48.1272313, lng: 18.9864649 },
    { lat: 48.1270663, lng: 18.9862783 },
    { lat: 48.1263627, lng: 18.9859928 },
    { lat: 48.126164, lng: 18.9861394 },
    { lat: 48.1261349, lng: 18.9858392 },
    { lat: 48.1259028, lng: 18.9861294 },
    { lat: 48.1258653, lng: 18.9861216 },
    { lat: 48.1258479, lng: 18.9860026 },
    { lat: 48.125603, lng: 18.9860874 },
    { lat: 48.1255288, lng: 18.9860225 },
    { lat: 48.1252323, lng: 18.986333 },
    { lat: 48.1250757, lng: 18.9862267 },
    { lat: 48.1250105, lng: 18.9859229 },
    { lat: 48.1249151, lng: 18.9863252 },
    { lat: 48.1248691, lng: 18.9863721 },
    { lat: 48.1244637, lng: 18.985834 },
    { lat: 48.1243948, lng: 18.9860296 },
    { lat: 48.1243092, lng: 18.9859628 },
    { lat: 48.124129, lng: 18.9860106 },
    { lat: 48.1239856, lng: 18.985745 },
    { lat: 48.1235913, lng: 18.985818 },
    { lat: 48.1228965, lng: 18.9854199 },
    { lat: 48.1229867, lng: 18.9849329 },
    { lat: 48.1224989, lng: 18.9850056 },
    { lat: 48.1225711, lng: 18.9844775 },
    { lat: 48.122174, lng: 18.9845198 },
    { lat: 48.1221242, lng: 18.984144 },
    { lat: 48.1220462, lng: 18.9841607 },
    { lat: 48.1217977, lng: 18.9844813 },
    { lat: 48.1216696, lng: 18.9841377 },
    { lat: 48.1213962, lng: 18.9843687 },
    { lat: 48.12112, lng: 18.9841064 },
    { lat: 48.1206764, lng: 18.9855502 },
    { lat: 48.1200547, lng: 18.9887548 },
    { lat: 48.1198164, lng: 18.9895837 },
    { lat: 48.1196512, lng: 18.9898831 },
    { lat: 48.1194712, lng: 18.9906157 },
    { lat: 48.1195231, lng: 18.9911461 },
    { lat: 48.1192806, lng: 18.9928383 },
    { lat: 48.119605, lng: 18.9945285 },
    { lat: 48.119581, lng: 18.9967056 },
    { lat: 48.1195644, lng: 18.9966906 },
    { lat: 48.1193723, lng: 18.9992594 },
    { lat: 48.1193571, lng: 19.0000441 },
    { lat: 48.1193552, lng: 19.000159 },
    { lat: 48.1194559, lng: 19.0001225 },
    { lat: 48.1195204, lng: 19.0001748 },
    { lat: 48.1195925, lng: 19.0004799 },
    { lat: 48.1197118, lng: 19.0007204 },
    { lat: 48.1201638, lng: 19.0011367 },
    { lat: 48.1202472, lng: 19.001774 },
    { lat: 48.120307, lng: 19.0018635 },
    { lat: 48.1206031, lng: 19.0017903 },
    { lat: 48.1207245, lng: 19.0018671 },
    { lat: 48.1206481, lng: 19.0028007 },
    { lat: 48.1206915, lng: 19.0028769 },
    { lat: 48.1209943, lng: 19.0029825 },
    { lat: 48.1212713, lng: 19.0027526 },
    { lat: 48.1215727, lng: 19.0027599 },
    { lat: 48.1217221, lng: 19.0024849 },
    { lat: 48.1219431, lng: 19.0023934 },
    { lat: 48.1222023, lng: 19.0024584 },
    { lat: 48.1223582, lng: 19.0025959 },
    { lat: 48.1228348, lng: 19.0027216 },
    { lat: 48.1229061, lng: 19.002573 },
    { lat: 48.1230634, lng: 19.0026087 },
    { lat: 48.123145, lng: 19.0026795 },
    { lat: 48.1233573, lng: 19.0031324 },
    { lat: 48.1235282, lng: 19.0036052 },
    { lat: 48.123658, lng: 19.0035647 },
    { lat: 48.1238947, lng: 19.0033192 },
    { lat: 48.1242933, lng: 19.0035727 },
    { lat: 48.1243759, lng: 19.0038449 },
    { lat: 48.1242202, lng: 19.0039742 },
    { lat: 48.1239492, lng: 19.004034 },
    { lat: 48.1240114, lng: 19.0042803 },
    { lat: 48.1244393, lng: 19.0044252 },
    { lat: 48.1246433, lng: 19.0043575 },
    { lat: 48.1248715, lng: 19.0046301 },
    { lat: 48.1251653, lng: 19.0047286 },
    { lat: 48.1252907, lng: 19.0048488 },
    { lat: 48.1253762, lng: 19.0047967 },
    { lat: 48.1254927, lng: 19.0044076 },
    { lat: 48.1256461, lng: 19.0043873 },
    { lat: 48.1257576, lng: 19.0046262 },
    { lat: 48.125921, lng: 19.0045366 },
    { lat: 48.1260949, lng: 19.0042772 },
    { lat: 48.1261866, lng: 19.0043299 },
    { lat: 48.1262162, lng: 19.0050616 },
    { lat: 48.126341, lng: 19.0051649 },
    { lat: 48.1267706, lng: 19.00499 },
    { lat: 48.1270261, lng: 19.0052231 },
    { lat: 48.1273074, lng: 19.0053703 },
    { lat: 48.1272832, lng: 19.0057098 },
    { lat: 48.1273366, lng: 19.0058078 },
    { lat: 48.1275987, lng: 19.0058189 },
    { lat: 48.1278933, lng: 19.0056459 },
    { lat: 48.1280816, lng: 19.0057572 },
    { lat: 48.1281471, lng: 19.0055821 },
    { lat: 48.1282409, lng: 19.0056109 },
    { lat: 48.1283014, lng: 19.0057131 },
    { lat: 48.1282089, lng: 19.0059784 },
    { lat: 48.1283465, lng: 19.0061335 },
    { lat: 48.1284709, lng: 19.0060933 },
    { lat: 48.128595, lng: 19.0062062 },
    { lat: 48.1285578, lng: 19.0064949 },
    { lat: 48.1286249, lng: 19.0066626 },
    { lat: 48.1287537, lng: 19.0066857 },
    { lat: 48.1288759, lng: 19.0065617 },
    { lat: 48.1289235, lng: 19.0064338 },
    { lat: 48.1292465, lng: 19.0064439 },
    { lat: 48.1292484, lng: 19.0056666 },
    { lat: 48.1293069, lng: 19.0055071 },
    { lat: 48.1294009, lng: 19.0054922 },
    { lat: 48.1294736, lng: 19.0055929 },
    { lat: 48.1294518, lng: 19.0060274 },
    { lat: 48.1294823, lng: 19.0060733 },
    { lat: 48.1297909, lng: 19.0061988 },
    { lat: 48.1298734, lng: 19.0061167 },
    { lat: 48.1298902, lng: 19.005891 },
    { lat: 48.1300207, lng: 19.0058652 },
    { lat: 48.1301682, lng: 19.006015 },
    { lat: 48.1302839, lng: 19.0059841 },
    { lat: 48.1303495, lng: 19.0060769 },
    { lat: 48.1303468, lng: 19.0063984 },
    { lat: 48.1303996, lng: 19.0064827 },
    { lat: 48.1305839, lng: 19.0064935 },
    { lat: 48.1307098, lng: 19.0062879 },
    { lat: 48.1308414, lng: 19.0062602 },
    { lat: 48.1312185, lng: 19.0065984 },
    { lat: 48.1314254, lng: 19.0066341 },
    { lat: 48.1314954, lng: 19.0065711 },
    { lat: 48.1316731, lng: 19.0067799 },
    { lat: 48.1320003, lng: 19.0074277 },
    { lat: 48.1321013, lng: 19.0073982 },
    { lat: 48.1323736, lng: 19.0070527 },
    { lat: 48.1326413, lng: 19.0068852 },
    { lat: 48.132716, lng: 19.0069148 },
    { lat: 48.1329384, lng: 19.0073381 },
    { lat: 48.1336836, lng: 19.0079341 },
    { lat: 48.1338702, lng: 19.0077032 },
    { lat: 48.1341135, lng: 19.0075618 },
    { lat: 48.1343683, lng: 19.0075639 },
    { lat: 48.1345975, lng: 19.00768 },
    { lat: 48.1349909, lng: 19.0076811 },
    { lat: 48.1351987, lng: 19.0075067 },
    { lat: 48.1353604, lng: 19.0075839 },
    { lat: 48.1355391, lng: 19.008057 },
    { lat: 48.1362738, lng: 19.0087128 },
    { lat: 48.1365225, lng: 19.0091705 },
    { lat: 48.1367874, lng: 19.0092999 },
    { lat: 48.1372985, lng: 19.0097281 },
    { lat: 48.1378459, lng: 19.0106213 },
    { lat: 48.1381442, lng: 19.0109015 },
    { lat: 48.1385175, lng: 19.01099 },
    { lat: 48.1386272, lng: 19.0111529 },
    { lat: 48.1388923, lng: 19.0120155 },
    { lat: 48.1389408, lng: 19.0124155 },
    { lat: 48.1390757, lng: 19.0127161 },
    { lat: 48.1390934, lng: 19.01291 },
    { lat: 48.138915, lng: 19.0135219 },
    { lat: 48.1389308, lng: 19.0137432 },
    { lat: 48.139306, lng: 19.0139621 },
    { lat: 48.1393168, lng: 19.0141081 },
    { lat: 48.1391901, lng: 19.014474 },
    { lat: 48.1391875, lng: 19.0146445 },
    { lat: 48.1395831, lng: 19.0150647 },
    { lat: 48.1395526, lng: 19.0152161 },
    { lat: 48.1393471, lng: 19.0155656 },
    { lat: 48.1393567, lng: 19.0157728 },
    { lat: 48.1394481, lng: 19.0158874 },
    { lat: 48.1397567, lng: 19.0158371 },
    { lat: 48.1398333, lng: 19.0160335 },
    { lat: 48.139985, lng: 19.0168225 },
    { lat: 48.1399483, lng: 19.0171179 },
    { lat: 48.1400154, lng: 19.0171967 },
    { lat: 48.1403489, lng: 19.0173476 },
    { lat: 48.1405583, lng: 19.0172734 },
    { lat: 48.140685, lng: 19.0173034 },
    { lat: 48.1412632, lng: 19.0177866 },
    { lat: 48.1415015, lng: 19.0176603 },
    { lat: 48.1416382, lng: 19.0177552 },
    { lat: 48.1417231, lng: 19.0179602 },
    { lat: 48.1419356, lng: 19.01806 },
    { lat: 48.1422137, lng: 19.0179457 },
    { lat: 48.1429304, lng: 19.0174039 },
    { lat: 48.1432898, lng: 19.0174417 },
    { lat: 48.1435401, lng: 19.0176858 },
    { lat: 48.1438182, lng: 19.0176355 },
    { lat: 48.144102, lng: 19.0174496 },
    { lat: 48.1442731, lng: 19.0175756 },
    { lat: 48.1449626, lng: 19.0175029 },
    { lat: 48.1450285, lng: 19.0176659 },
    { lat: 48.1450011, lng: 19.0179013 },
    { lat: 48.1450473, lng: 19.0182346 },
    { lat: 48.1455707, lng: 19.0189267 },
    { lat: 48.1458189, lng: 19.0197263 },
    { lat: 48.1457412, lng: 19.0203922 },
    { lat: 48.1459459, lng: 19.0208017 },
    { lat: 48.1459681, lng: 19.0211731 },
    { lat: 48.1460651, lng: 19.0215705 },
    { lat: 48.1463765, lng: 19.0220209 },
    { lat: 48.1466066, lng: 19.022466 },
    { lat: 48.1467858, lng: 19.0232164 },
    { lat: 48.1468602, lng: 19.0233043 },
    { lat: 48.1469012, lng: 19.0234906 },
    { lat: 48.1468145, lng: 19.0242016 },
    { lat: 48.1469947, lng: 19.0244428 },
    { lat: 48.1475566, lng: 19.0256238 },
    { lat: 48.1479865, lng: 19.026054 },
    { lat: 48.14798, lng: 19.0264325 },
    { lat: 48.1480515, lng: 19.0269734 },
    { lat: 48.1481756, lng: 19.0271757 },
    { lat: 48.1482718, lng: 19.0274737 },
    { lat: 48.1484311, lng: 19.0274796 },
    { lat: 48.1485283, lng: 19.0276617 },
    { lat: 48.1488076, lng: 19.0278111 },
    { lat: 48.1488326, lng: 19.0278817 },
    { lat: 48.1487133, lng: 19.0280849 },
    { lat: 48.1485437, lng: 19.0278958 },
    { lat: 48.1484519, lng: 19.0280242 },
    { lat: 48.1484721, lng: 19.0282386 },
    { lat: 48.1487971, lng: 19.0283589 },
    { lat: 48.1489018, lng: 19.0286428 },
    { lat: 48.1488824, lng: 19.0288604 },
    { lat: 48.1489557, lng: 19.0289276 },
    { lat: 48.1490675, lng: 19.0289513 },
    { lat: 48.1492999, lng: 19.0288663 },
    { lat: 48.1493558, lng: 19.0289659 },
    { lat: 48.1493524, lng: 19.0291926 },
    { lat: 48.1494982, lng: 19.029486 },
    { lat: 48.1498664, lng: 19.0295851 },
    { lat: 48.149914, lng: 19.0296577 },
    { lat: 48.1498347, lng: 19.0298966 },
    { lat: 48.150326, lng: 19.0304227 },
    { lat: 48.1504089, lng: 19.0305901 },
    { lat: 48.1504567, lng: 19.0310193 },
    { lat: 48.1506691, lng: 19.0313162 },
    { lat: 48.1506782, lng: 19.03145 },
    { lat: 48.1509285, lng: 19.0319162 },
    { lat: 48.1509665, lng: 19.0319762 },
    { lat: 48.1511145, lng: 19.0319601 },
    { lat: 48.1511844, lng: 19.0320472 },
    { lat: 48.1512903, lng: 19.032825 },
    { lat: 48.1517098, lng: 19.0329345 },
    { lat: 48.1517539, lng: 19.0331385 },
    { lat: 48.1519258, lng: 19.0330848 },
    { lat: 48.1520046, lng: 19.0331471 },
    { lat: 48.152031, lng: 19.0332031 },
    { lat: 48.1519524, lng: 19.0335199 },
    { lat: 48.1520089, lng: 19.0340332 },
    { lat: 48.1522352, lng: 19.0344461 },
    { lat: 48.1523738, lng: 19.03458 },
    { lat: 48.1524549, lng: 19.0348219 },
    { lat: 48.152538, lng: 19.0347228 },
    { lat: 48.1529779, lng: 19.0346863 },
    { lat: 48.1530855, lng: 19.0349393 },
    { lat: 48.1531566, lng: 19.0352984 },
    { lat: 48.1533752, lng: 19.0354936 },
    { lat: 48.1536181, lng: 19.0355681 },
    { lat: 48.1538848, lng: 19.0358708 },
    { lat: 48.1540262, lng: 19.0363247 },
    { lat: 48.1542458, lng: 19.0366533 },
    { lat: 48.1544705, lng: 19.0368354 },
    { lat: 48.1545192, lng: 19.0373048 },
    { lat: 48.1547161, lng: 19.0377634 },
    { lat: 48.154855, lng: 19.0382791 },
    { lat: 48.1553434, lng: 19.0389105 },
    { lat: 48.1554491, lng: 19.0396796 },
    { lat: 48.1556248, lng: 19.0402331 },
    { lat: 48.1555638, lng: 19.0407633 },
    { lat: 48.1555802, lng: 19.0410633 },
    { lat: 48.1556273, lng: 19.0411724 },
    { lat: 48.1558617, lng: 19.0413676 },
    { lat: 48.1559315, lng: 19.0416538 },
    { lat: 48.1559107, lng: 19.0420223 },
    { lat: 48.1560654, lng: 19.0423851 },
    { lat: 48.1561966, lng: 19.0424786 },
    { lat: 48.1562558, lng: 19.0428501 },
    { lat: 48.1562381, lng: 19.0432783 },
    { lat: 48.1562887, lng: 19.0433396 },
    { lat: 48.1565382, lng: 19.0432386 },
    { lat: 48.1566539, lng: 19.0432923 },
    { lat: 48.1568091, lng: 19.0440007 },
    { lat: 48.1570756, lng: 19.0440514 },
    { lat: 48.1572228, lng: 19.0443317 },
    { lat: 48.1574212, lng: 19.0445553 },
    { lat: 48.1573925, lng: 19.044697 },
    { lat: 48.1573362, lng: 19.0447415 },
    { lat: 48.157421, lng: 19.0450589 },
    { lat: 48.1575433, lng: 19.0452491 },
    { lat: 48.1575793, lng: 19.0454463 },
    { lat: 48.1578375, lng: 19.0455149 },
    { lat: 48.158182, lng: 19.0457496 },
    { lat: 48.1584115, lng: 19.0461855 },
    { lat: 48.1585689, lng: 19.047179 },
    { lat: 48.158807, lng: 19.0472484 },
    { lat: 48.1590411, lng: 19.0476383 },
    { lat: 48.1596439, lng: 19.0479023 },
    { lat: 48.1597066, lng: 19.0480905 },
    { lat: 48.1598372, lng: 19.0482671 },
    { lat: 48.1598863, lng: 19.0482337 },
    { lat: 48.1599278, lng: 19.0479624 },
    { lat: 48.1601747, lng: 19.0481389 },
    { lat: 48.1602109, lng: 19.0482986 },
    { lat: 48.1600938, lng: 19.0486791 },
    { lat: 48.1600981, lng: 19.0488093 },
    { lat: 48.1605016, lng: 19.0493001 },
    { lat: 48.160594, lng: 19.049525 },
    { lat: 48.160824, lng: 19.050034 },
    { lat: 48.161113, lng: 19.050257 },
    { lat: 48.161385, lng: 19.050616 },
    { lat: 48.161695, lng: 19.050742 },
    { lat: 48.161658, lng: 19.051124 },
    { lat: 48.161664, lng: 19.051157 },
    { lat: 48.161866, lng: 19.051274 },
    { lat: 48.16197, lng: 19.051397 },
    { lat: 48.161983, lng: 19.051609 },
    { lat: 48.161952, lng: 19.051786 },
    { lat: 48.161953, lng: 19.051843 },
    { lat: 48.162172, lng: 19.052047 },
    { lat: 48.162445, lng: 19.052067 },
    { lat: 48.162619, lng: 19.052481 },
    { lat: 48.162603, lng: 19.052705 },
    { lat: 48.162559, lng: 19.053311 },
    { lat: 48.162696, lng: 19.053463 },
    { lat: 48.162968, lng: 19.05362 },
    { lat: 48.163082, lng: 19.0541 },
    { lat: 48.163383, lng: 19.054165 },
    { lat: 48.163295, lng: 19.054529 },
    { lat: 48.163265, lng: 19.054778 },
    { lat: 48.163408, lng: 19.055286 },
    { lat: 48.163442, lng: 19.055333 },
    { lat: 48.163846, lng: 19.055317 },
    { lat: 48.16396, lng: 19.055581 },
    { lat: 48.163933, lng: 19.055749 },
    { lat: 48.163922, lng: 19.055851 },
    { lat: 48.163951, lng: 19.055961 },
    { lat: 48.164018, lng: 19.056161 },
    { lat: 48.16417, lng: 19.056268 },
    { lat: 48.164337, lng: 19.05655 },
    { lat: 48.164305, lng: 19.056664 },
    { lat: 48.164273, lng: 19.05703 },
    { lat: 48.16447, lng: 19.05722 },
    { lat: 48.164483, lng: 19.057569 },
    { lat: 48.164547, lng: 19.057904 },
    { lat: 48.164697, lng: 19.058092 },
    { lat: 48.164786, lng: 19.058286 },
    { lat: 48.164556, lng: 19.058764 },
    { lat: 48.164655, lng: 19.059009 },
    { lat: 48.164782, lng: 19.05914 },
    { lat: 48.164828, lng: 19.059117 },
    { lat: 48.165644, lng: 19.058769 },
    { lat: 48.166112, lng: 19.058505 },
    { lat: 48.1663, lng: 19.058454 },
    { lat: 48.166816, lng: 19.058288 },
    { lat: 48.167198, lng: 19.058184 },
    { lat: 48.167625, lng: 19.058016 },
    { lat: 48.167732, lng: 19.057973 },
    { lat: 48.168005, lng: 19.057918 },
    { lat: 48.168232, lng: 19.057922 },
    { lat: 48.16892, lng: 19.057916 },
    { lat: 48.169063, lng: 19.058 },
    { lat: 48.169396, lng: 19.058158 },
    { lat: 48.169452, lng: 19.058179 },
    { lat: 48.169658, lng: 19.058245 },
    { lat: 48.169745, lng: 19.058238 },
    { lat: 48.169781, lng: 19.058238 },
    { lat: 48.169906, lng: 19.058605 },
    { lat: 48.169936, lng: 19.058746 },
    { lat: 48.169939, lng: 19.05908 },
    { lat: 48.169937, lng: 19.05944 },
    { lat: 48.16991, lng: 19.059772 },
    { lat: 48.170049, lng: 19.060119 },
    { lat: 48.170333, lng: 19.059979 },
    { lat: 48.17068, lng: 19.059589 },
    { lat: 48.170978, lng: 19.05926 },
    { lat: 48.171926, lng: 19.059185 },
    { lat: 48.1722, lng: 19.059176 },
    { lat: 48.172307, lng: 19.059124 },
    { lat: 48.172901, lng: 19.058793 },
    { lat: 48.173309, lng: 19.058467 },
    { lat: 48.173643, lng: 19.058191 },
    { lat: 48.174287, lng: 19.057638 },
    { lat: 48.174408, lng: 19.057551 },
    { lat: 48.17445, lng: 19.057519 },
    { lat: 48.174709, lng: 19.057328 },
    { lat: 48.174768, lng: 19.057071 },
    { lat: 48.17479, lng: 19.056992 },
    { lat: 48.174808, lng: 19.056928 },
    { lat: 48.174966, lng: 19.057171 },
    { lat: 48.175104, lng: 19.057351 },
    { lat: 48.175255, lng: 19.057479 },
    { lat: 48.175322, lng: 19.057477 },
    { lat: 48.175419, lng: 19.057412 },
    { lat: 48.175565, lng: 19.057484 },
    { lat: 48.175644, lng: 19.057464 },
    { lat: 48.17584, lng: 19.057407 },
    { lat: 48.176142, lng: 19.057461 },
    { lat: 48.176276, lng: 19.057515 },
    { lat: 48.176554, lng: 19.057533 },
    { lat: 48.176615, lng: 19.057578 },
    { lat: 48.176825, lng: 19.057683 },
    { lat: 48.177041, lng: 19.057948 },
    { lat: 48.177243, lng: 19.058011 },
    { lat: 48.177441, lng: 19.058188 },
    { lat: 48.177672, lng: 19.058124 },
    { lat: 48.177814, lng: 19.058245 },
    { lat: 48.177853, lng: 19.058329 },
    { lat: 48.177858, lng: 19.058526 },
    { lat: 48.178084, lng: 19.058546 },
    { lat: 48.178666, lng: 19.059057 },
    { lat: 48.178991, lng: 19.059181 },
    { lat: 48.179126, lng: 19.059675 },
    { lat: 48.179256, lng: 19.059578 },
    { lat: 48.179427, lng: 19.059693 },
    { lat: 48.179437, lng: 19.059689 },
    { lat: 48.179857, lng: 19.059835 },
    { lat: 48.179926, lng: 19.059969 },
    { lat: 48.179934, lng: 19.060168 },
    { lat: 48.180102, lng: 19.060166 },
    { lat: 48.180211, lng: 19.060334 },
    { lat: 48.180367, lng: 19.060461 },
    { lat: 48.1806, lng: 19.060743 },
    { lat: 48.180784, lng: 19.061123 },
    { lat: 48.180921, lng: 19.061268 },
    { lat: 48.181127, lng: 19.061208 },
    { lat: 48.181297, lng: 19.061337 },
    { lat: 48.181541, lng: 19.061565 },
    { lat: 48.181544, lng: 19.061703 },
    { lat: 48.181696, lng: 19.061747 },
    { lat: 48.181726, lng: 19.061924 },
    { lat: 48.181855, lng: 19.061967 },
    { lat: 48.181967, lng: 19.062212 },
    { lat: 48.181847, lng: 19.062459 },
    { lat: 48.181933, lng: 19.062644 },
    { lat: 48.182035, lng: 19.062676 },
    { lat: 48.182176, lng: 19.062881 },
    { lat: 48.182086, lng: 19.063048 },
    { lat: 48.182139, lng: 19.06311 },
    { lat: 48.182142, lng: 19.063274 },
    { lat: 48.182299, lng: 19.063305 },
    { lat: 48.182336, lng: 19.063581 },
    { lat: 48.182411, lng: 19.063771 },
    { lat: 48.182607, lng: 19.063865 },
    { lat: 48.182556, lng: 19.064046 },
    { lat: 48.182643, lng: 19.064227 },
    { lat: 48.18261, lng: 19.064336 },
    { lat: 48.182654, lng: 19.064446 },
    { lat: 48.182714, lng: 19.064358 },
    { lat: 48.182783, lng: 19.06443 },
    { lat: 48.182771, lng: 19.064663 },
    { lat: 48.18269, lng: 19.064934 },
    { lat: 48.182813, lng: 19.065009 },
    { lat: 48.18281, lng: 19.065238 },
    { lat: 48.183019, lng: 19.065153 },
    { lat: 48.183101, lng: 19.065191 },
    { lat: 48.183125, lng: 19.06534 },
    { lat: 48.183121, lng: 19.065658 },
    { lat: 48.183181, lng: 19.065861 },
    { lat: 48.183283, lng: 19.065849 },
    { lat: 48.183458, lng: 19.06605 },
    { lat: 48.183471, lng: 19.066335 },
    { lat: 48.183565, lng: 19.066446 },
    { lat: 48.183682, lng: 19.066521 },
    { lat: 48.183683, lng: 19.066778 },
    { lat: 48.183563, lng: 19.067021 },
    { lat: 48.183555, lng: 19.067169 },
    { lat: 48.183669, lng: 19.067649 },
    { lat: 48.183607, lng: 19.067809 },
    { lat: 48.183642, lng: 19.067942 },
    { lat: 48.183751, lng: 19.068183 },
    { lat: 48.183864, lng: 19.068264 },
    { lat: 48.183838, lng: 19.068365 },
    { lat: 48.183731, lng: 19.068405 },
    { lat: 48.183763, lng: 19.068613 },
    { lat: 48.18388, lng: 19.068871 },
    { lat: 48.183729, lng: 19.069067 },
    { lat: 48.183735, lng: 19.069138 },
    { lat: 48.183843, lng: 19.069294 },
    { lat: 48.183769, lng: 19.069533 },
    { lat: 48.183776, lng: 19.069631 },
    { lat: 48.18389, lng: 19.06979 },
    { lat: 48.183933, lng: 19.070089 },
    { lat: 48.18418, lng: 19.070556 },
    { lat: 48.184314, lng: 19.070362 },
    { lat: 48.184638, lng: 19.069895 },
    { lat: 48.184882, lng: 19.06935 },
    { lat: 48.184962, lng: 19.069249 },
    { lat: 48.185067, lng: 19.06912 },
    { lat: 48.185467, lng: 19.069218 },
    { lat: 48.186055, lng: 19.069231 },
    { lat: 48.186083, lng: 19.06923 },
    { lat: 48.185933, lng: 19.068156 },
    { lat: 48.185984, lng: 19.067634 },
    { lat: 48.186058, lng: 19.067448 },
    { lat: 48.18613, lng: 19.067336 },
    { lat: 48.186254, lng: 19.067169 },
    { lat: 48.186698, lng: 19.067137 },
    { lat: 48.186998, lng: 19.067091 },
    { lat: 48.187468, lng: 19.067016 },
    { lat: 48.187707, lng: 19.066933 },
    { lat: 48.187707, lng: 19.066906 },
    { lat: 48.18773, lng: 19.066698 },
    { lat: 48.187828, lng: 19.065565 },
    { lat: 48.187848, lng: 19.064733 },
    { lat: 48.187979, lng: 19.06386 },
    { lat: 48.18788, lng: 19.063808 },
    { lat: 48.187959, lng: 19.063553 },
    { lat: 48.188021, lng: 19.062839 },
    { lat: 48.188256, lng: 19.06238 },
    { lat: 48.188357, lng: 19.062315 },
    { lat: 48.189067, lng: 19.063536 },
    { lat: 48.189299, lng: 19.063936 },
    { lat: 48.189602, lng: 19.064456 },
    { lat: 48.189855, lng: 19.064886 },
    { lat: 48.190244, lng: 19.065544 },
    { lat: 48.190438, lng: 19.065897 },
    { lat: 48.190999, lng: 19.066866 },
    { lat: 48.191886, lng: 19.068352 },
    { lat: 48.192465, lng: 19.069151 },
    { lat: 48.192529, lng: 19.069239 },
    { lat: 48.192571, lng: 19.069367 },
    { lat: 48.192722, lng: 19.069808 },
    { lat: 48.192811, lng: 19.069945 },
    { lat: 48.192878, lng: 19.069989 },
    { lat: 48.19311, lng: 19.070338 },
    { lat: 48.193296, lng: 19.070842 },
    { lat: 48.193331, lng: 19.070869 },
    { lat: 48.193385, lng: 19.070841 },
    { lat: 48.19351, lng: 19.070484 },
    { lat: 48.193839, lng: 19.070542 },
    { lat: 48.194236, lng: 19.070829 },
    { lat: 48.194271, lng: 19.070953 },
    { lat: 48.194268, lng: 19.071342 },
    { lat: 48.194229, lng: 19.071545 },
    { lat: 48.19416, lng: 19.071745 },
    { lat: 48.194166, lng: 19.072108 },
    { lat: 48.194354, lng: 19.072129 },
    { lat: 48.194749, lng: 19.071448 },
    { lat: 48.19479, lng: 19.071378 },
    { lat: 48.194824, lng: 19.071319 },
    { lat: 48.195395, lng: 19.07016 },
    { lat: 48.196321, lng: 19.068107 },
    { lat: 48.196502, lng: 19.067675 },
    { lat: 48.196625, lng: 19.067464 },
    { lat: 48.197035, lng: 19.066483 },
    { lat: 48.197534, lng: 19.065476 },
    { lat: 48.198725, lng: 19.062959 },
    { lat: 48.199987, lng: 19.060261 },
    { lat: 48.200042, lng: 19.060132 },
    { lat: 48.200085, lng: 19.060035 },
    { lat: 48.200148, lng: 19.059892 },
    { lat: 48.200169, lng: 19.059832 },
    { lat: 48.201159, lng: 19.057585 },
    { lat: 48.201178, lng: 19.057539 },
    { lat: 48.201203, lng: 19.057483 },
    { lat: 48.201224, lng: 19.057435 },
    { lat: 48.202018, lng: 19.055779 },
    { lat: 48.202526, lng: 19.05468 },
    { lat: 48.204465, lng: 19.050494 },
    { lat: 48.204493, lng: 19.050431 },
    { lat: 48.204629, lng: 19.050141 },
    { lat: 48.204127, lng: 19.049835 },
    { lat: 48.204069, lng: 19.049761 },
    { lat: 48.20393, lng: 19.049612 },
    { lat: 48.203799, lng: 19.049358 },
    { lat: 48.203655, lng: 19.048932 },
    { lat: 48.20358, lng: 19.048777 },
    { lat: 48.203436, lng: 19.048561 },
    { lat: 48.203215, lng: 19.048153 },
    { lat: 48.203049, lng: 19.047815 },
    { lat: 48.202693, lng: 19.047436 },
    { lat: 48.202721, lng: 19.047369 },
    { lat: 48.202453, lng: 19.04716 },
    { lat: 48.201511, lng: 19.046414 },
    { lat: 48.201056, lng: 19.046081 },
    { lat: 48.201044, lng: 19.046073 },
    { lat: 48.201069, lng: 19.045987 },
    { lat: 48.201079, lng: 19.045962 },
    { lat: 48.201098, lng: 19.045895 },
    { lat: 48.201229, lng: 19.046024 },
    { lat: 48.201538, lng: 19.046237 },
    { lat: 48.201829, lng: 19.046386 },
    { lat: 48.201946, lng: 19.046458 },
    { lat: 48.202099, lng: 19.046588 },
    { lat: 48.202223, lng: 19.046636 },
    { lat: 48.202341, lng: 19.046706 },
    { lat: 48.202411, lng: 19.04671 },
    { lat: 48.202411, lng: 19.046612 },
    { lat: 48.202415, lng: 19.046573 },
    { lat: 48.202511, lng: 19.046461 },
    { lat: 48.202351, lng: 19.04624 },
    { lat: 48.202172, lng: 19.046044 },
    { lat: 48.202076, lng: 19.045935 },
    { lat: 48.20194, lng: 19.045759 },
    { lat: 48.201837, lng: 19.045645 },
    { lat: 48.201745, lng: 19.04556 },
    { lat: 48.201701, lng: 19.045525 },
    { lat: 48.20128, lng: 19.04524 },
    { lat: 48.201144, lng: 19.045164 },
    { lat: 48.200813, lng: 19.044894 },
    { lat: 48.20038, lng: 19.044667 },
    { lat: 48.200272, lng: 19.044542 },
    { lat: 48.200055, lng: 19.044183 },
    { lat: 48.199872, lng: 19.044172 },
    { lat: 48.19963, lng: 19.04404 },
    { lat: 48.199491, lng: 19.043782 },
    { lat: 48.199342, lng: 19.043581 },
    { lat: 48.199238, lng: 19.043536 },
    { lat: 48.198599, lng: 19.04338 },
    { lat: 48.198441, lng: 19.043283 },
    { lat: 48.198172, lng: 19.043034 },
    { lat: 48.197688, lng: 19.042448 },
    { lat: 48.197607, lng: 19.042284 },
    { lat: 48.197157, lng: 19.041319 },
    { lat: 48.196566, lng: 19.040249 },
    { lat: 48.196413, lng: 19.040048 },
    { lat: 48.196195, lng: 19.039776 },
    { lat: 48.196098, lng: 19.039627 },
    { lat: 48.196012, lng: 19.039485 },
    { lat: 48.195977, lng: 19.039378 },
    { lat: 48.195906, lng: 19.038947 },
    { lat: 48.19588, lng: 19.03881 },
    { lat: 48.195812, lng: 19.038627 },
    { lat: 48.195774, lng: 19.03854 },
    { lat: 48.195422, lng: 19.037784 },
    { lat: 48.195222, lng: 19.03723 },
    { lat: 48.19497, lng: 19.036546 },
    { lat: 48.194832, lng: 19.036194 },
    { lat: 48.194365, lng: 19.035552 },
    { lat: 48.193945, lng: 19.034882 },
    { lat: 48.193682, lng: 19.034631 },
    { lat: 48.193448, lng: 19.034408 },
    { lat: 48.193245, lng: 19.034119 },
    { lat: 48.193047, lng: 19.033632 },
    { lat: 48.192879, lng: 19.033197 },
    { lat: 48.192583, lng: 19.032454 },
    { lat: 48.192411, lng: 19.031664 },
    { lat: 48.192374, lng: 19.031238 },
    { lat: 48.192281, lng: 19.030386 },
    { lat: 48.192271, lng: 19.030327 },
    { lat: 48.192256, lng: 19.03026 },
    { lat: 48.192201, lng: 19.029962 },
    { lat: 48.192145, lng: 19.029715 },
    { lat: 48.192144, lng: 19.029195 },
    { lat: 48.192108, lng: 19.02899 },
    { lat: 48.191901, lng: 19.028391 },
    { lat: 48.19155, lng: 19.027014 },
    { lat: 48.191362, lng: 19.026211 },
    { lat: 48.19126, lng: 19.025834 },
    { lat: 48.191145, lng: 19.025571 },
    { lat: 48.190941, lng: 19.024975 },
    { lat: 48.190828, lng: 19.024588 },
    { lat: 48.190778, lng: 19.024355 },
    { lat: 48.190676, lng: 19.024012 },
    { lat: 48.190542, lng: 19.023759 },
    { lat: 48.190383, lng: 19.023408 },
    { lat: 48.190263, lng: 19.023098 },
    { lat: 48.190203, lng: 19.022909 },
    { lat: 48.189864, lng: 19.021862 },
    { lat: 48.189841, lng: 19.021806 },
    { lat: 48.189805, lng: 19.021618 },
    { lat: 48.189785, lng: 19.021512 },
    { lat: 48.18977, lng: 19.02144 },
    { lat: 48.189646, lng: 19.020772 },
    { lat: 48.189476, lng: 19.019812 },
    { lat: 48.189524, lng: 19.019597 },
    { lat: 48.189551, lng: 19.019495 },
    { lat: 48.189659, lng: 19.019231 },
    { lat: 48.189788, lng: 19.019001 },
    { lat: 48.189879, lng: 19.01888 },
    { lat: 48.190435, lng: 19.018316 },
    { lat: 48.190727, lng: 19.018046 },
    { lat: 48.190685, lng: 19.017991 },
    { lat: 48.190747, lng: 19.017885 },
    { lat: 48.190764, lng: 19.017611 },
    { lat: 48.190896, lng: 19.017443 },
    { lat: 48.191004, lng: 19.017462 },
    { lat: 48.191321, lng: 19.017363 },
    { lat: 48.191603, lng: 19.017276 },
    { lat: 48.191774, lng: 19.017224 },
    { lat: 48.191803, lng: 19.017164 },
    { lat: 48.191878, lng: 19.01712 },
    { lat: 48.192069, lng: 19.017107 },
    { lat: 48.192206, lng: 19.017008 },
    { lat: 48.192228, lng: 19.016901 },
    { lat: 48.192275, lng: 19.016655 },
    { lat: 48.192287, lng: 19.016595 },
    { lat: 48.192303, lng: 19.016519 },
    { lat: 48.192421, lng: 19.016573 },
    { lat: 48.192571, lng: 19.016467 },
    { lat: 48.192618, lng: 19.016368 },
    { lat: 48.193372, lng: 19.014776 },
    { lat: 48.193373, lng: 19.011804 },
    { lat: 48.193336, lng: 19.009018 },
    { lat: 48.193323, lng: 19.008915 },
    { lat: 48.19236, lng: 19.008979 },
    { lat: 48.192183, lng: 19.008934 },
    { lat: 48.19195, lng: 19.008894 },
    { lat: 48.191442, lng: 19.008963 },
    { lat: 48.191245, lng: 19.008975 },
    { lat: 48.1911, lng: 19.008984 },
    { lat: 48.190361, lng: 19.009065 },
    { lat: 48.190005, lng: 19.008912 },
    { lat: 48.18971, lng: 19.00882 },
    { lat: 48.18971, lng: 19.008768 },
    { lat: 48.189682, lng: 19.008293 },
    { lat: 48.189618, lng: 19.007193 },
    { lat: 48.189511, lng: 19.006119 },
    { lat: 48.18944, lng: 19.00494 },
    { lat: 48.18917, lng: 19.003087 },
    { lat: 48.189127, lng: 19.002798 },
    { lat: 48.189088, lng: 19.001905 },
    { lat: 48.189108, lng: 19.000411 },
    { lat: 48.189043, lng: 18.999855 },
    { lat: 48.188447, lng: 18.994869 },
    { lat: 48.188429, lng: 18.993967 },
    { lat: 48.187873, lng: 18.992773 },
    { lat: 48.187571, lng: 18.991895 },
    { lat: 48.187243, lng: 18.991256 },
    { lat: 48.187161, lng: 18.990229 },
    { lat: 48.187159, lng: 18.990168 },
    { lat: 48.187118, lng: 18.989274 },
    { lat: 48.187121, lng: 18.989246 },
    { lat: 48.187236, lng: 18.988095 },
    { lat: 48.187399, lng: 18.986917 },
    { lat: 48.187415, lng: 18.986679 },
    { lat: 48.187417, lng: 18.986639 },
    { lat: 48.187359, lng: 18.986447 },
    { lat: 48.18735, lng: 18.986417 },
    { lat: 48.187177, lng: 18.986096 },
    { lat: 48.186913, lng: 18.985867 },
    { lat: 48.186685, lng: 18.985286 },
    { lat: 48.18639, lng: 18.984343 },
    { lat: 48.186071, lng: 18.984075 },
    { lat: 48.186127, lng: 18.983668 },
    { lat: 48.185883, lng: 18.983333 },
    { lat: 48.185754, lng: 18.983241 },
    { lat: 48.18522, lng: 18.982619 },
    { lat: 48.184997, lng: 18.982118 },
    { lat: 48.184881, lng: 18.981356 },
    { lat: 48.184785, lng: 18.980923 },
    { lat: 48.184408, lng: 18.98019 },
    { lat: 48.184384, lng: 18.979821 },
    { lat: 48.184303, lng: 18.979555 },
    { lat: 48.184091, lng: 18.979321 },
    { lat: 48.183822, lng: 18.979113 },
    { lat: 48.183756, lng: 18.979062 },
    { lat: 48.183728, lng: 18.979002 },
    { lat: 48.183798, lng: 18.978518 },
    { lat: 48.183689, lng: 18.978069 },
    { lat: 48.183535, lng: 18.977858 },
    { lat: 48.183314, lng: 18.977718 },
    { lat: 48.183092, lng: 18.977693 },
    { lat: 48.182872, lng: 18.977299 },
    { lat: 48.183061, lng: 18.976943 },
    { lat: 48.182897, lng: 18.97634 },
    { lat: 48.183166, lng: 18.975292 },
    { lat: 48.183165, lng: 18.975097 },
    { lat: 48.18315, lng: 18.974988 },
    { lat: 48.183134, lng: 18.974872 },
    { lat: 48.183129, lng: 18.974831 },
    { lat: 48.183194, lng: 18.974823 },
    { lat: 48.18318, lng: 18.974136 },
    { lat: 48.183152, lng: 18.972736 },
    { lat: 48.183119, lng: 18.972312 },
    { lat: 48.182973, lng: 18.971918 },
    { lat: 48.182912, lng: 18.971689 },
    { lat: 48.182882, lng: 18.971227 },
    { lat: 48.182486, lng: 18.970541 },
    { lat: 48.182391, lng: 18.970548 },
    { lat: 48.182346, lng: 18.970252 },
    { lat: 48.182298, lng: 18.97011 },
    { lat: 48.182045, lng: 18.969594 },
    { lat: 48.181827, lng: 18.969234 },
    { lat: 48.181516, lng: 18.968814 },
    { lat: 48.181281, lng: 18.968755 },
    { lat: 48.181117, lng: 18.968516 },
    { lat: 48.181014, lng: 18.968302 },
    { lat: 48.18091, lng: 18.967996 },
    { lat: 48.180823, lng: 18.967773 },
    { lat: 48.180711, lng: 18.967294 },
    { lat: 48.180727, lng: 18.966778 },
    { lat: 48.180871, lng: 18.966125 },
    { lat: 48.181059, lng: 18.965791 },
    { lat: 48.181365, lng: 18.964912 },
    { lat: 48.181542, lng: 18.964029 },
    { lat: 48.181703, lng: 18.963145 },
    { lat: 48.18194, lng: 18.962729 },
    { lat: 48.182102, lng: 18.962159 },
    { lat: 48.182372, lng: 18.961604 },
    { lat: 48.18268, lng: 18.961258 },
    { lat: 48.182665, lng: 18.961184 },
    { lat: 48.182496, lng: 18.960293 },
    { lat: 48.182437, lng: 18.959778 },
    { lat: 48.182465, lng: 18.959651 },
    { lat: 48.18243, lng: 18.959488 },
    { lat: 48.182477, lng: 18.959383 },
    { lat: 48.182458, lng: 18.959034 },
    { lat: 48.182364, lng: 18.958875 },
    { lat: 48.182353, lng: 18.958777 },
    { lat: 48.182135, lng: 18.958411 },
    { lat: 48.18192, lng: 18.958122 },
    { lat: 48.18178, lng: 18.95785 },
    { lat: 48.181659, lng: 18.957784 },
    { lat: 48.181632, lng: 18.957664 },
    { lat: 48.181547, lng: 18.957596 },
    { lat: 48.181451, lng: 18.957368 },
    { lat: 48.181247, lng: 18.956997 },
    { lat: 48.181111, lng: 18.95683 },
    { lat: 48.180877, lng: 18.95634 },
    { lat: 48.18057, lng: 18.956054 },
    { lat: 48.180398, lng: 18.955928 },
    { lat: 48.180202, lng: 18.955675 },
    { lat: 48.17983, lng: 18.955472 },
    { lat: 48.179547, lng: 18.955399 },
    { lat: 48.179198, lng: 18.955287 },
    { lat: 48.179128, lng: 18.955337 },
    { lat: 48.178927, lng: 18.955237 },
    { lat: 48.178751, lng: 18.955267 },
    { lat: 48.178657, lng: 18.955079 },
    { lat: 48.178476, lng: 18.955027 },
    { lat: 48.178068, lng: 18.954677 },
    { lat: 48.177909, lng: 18.954572 },
    { lat: 48.177837, lng: 18.954494 },
    { lat: 48.177377, lng: 18.954094 },
    { lat: 48.177186, lng: 18.954101 },
    { lat: 48.176853, lng: 18.953974 },
    { lat: 48.176651, lng: 18.95379 },
    { lat: 48.176465, lng: 18.953633 },
    { lat: 48.176376, lng: 18.953599 },
    { lat: 48.176282, lng: 18.953581 },
    { lat: 48.175999, lng: 18.953198 },
    { lat: 48.175703, lng: 18.95259 },
    { lat: 48.175464, lng: 18.951725 },
    { lat: 48.175388, lng: 18.951168 },
    { lat: 48.175216, lng: 18.951073 },
    { lat: 48.175199, lng: 18.950965 },
    { lat: 48.175297, lng: 18.950894 },
    { lat: 48.175278, lng: 18.950834 },
    { lat: 48.175205, lng: 18.950836 },
    { lat: 48.175124, lng: 18.950784 },
    { lat: 48.174931, lng: 18.950326 },
    { lat: 48.174796, lng: 18.95023 },
    { lat: 48.174698, lng: 18.950185 },
    { lat: 48.174463, lng: 18.95007 },
    { lat: 48.174439, lng: 18.949972 },
    { lat: 48.174273, lng: 18.949792 },
    { lat: 48.174284, lng: 18.949632 },
    { lat: 48.174184, lng: 18.949506 },
    { lat: 48.174087, lng: 18.94948 },
    { lat: 48.174, lng: 18.949391 },
    { lat: 48.173994, lng: 18.949266 },
    { lat: 48.173881, lng: 18.949146 },
    { lat: 48.173686, lng: 18.949129 },
    { lat: 48.173607, lng: 18.949079 },
    { lat: 48.173479, lng: 18.948895 },
    { lat: 48.173463, lng: 18.948754 },
    { lat: 48.173478, lng: 18.948599 },
    { lat: 48.173378, lng: 18.948457 },
    { lat: 48.173265, lng: 18.948362 },
    { lat: 48.173214, lng: 18.94827 },
    { lat: 48.173203, lng: 18.947927 },
    { lat: 48.173195, lng: 18.94791 },
    { lat: 48.173185, lng: 18.947883 },
    { lat: 48.173665, lng: 18.947863 },
    { lat: 48.17394, lng: 18.947602 },
    { lat: 48.17456, lng: 18.946767 },
    { lat: 48.174607, lng: 18.946569 },
    { lat: 48.174938, lng: 18.946619 },
    { lat: 48.17506, lng: 18.946381 },
    { lat: 48.175341, lng: 18.946131 },
    { lat: 48.175882, lng: 18.945965 },
    { lat: 48.175976, lng: 18.94592 },
    { lat: 48.176105, lng: 18.9458 },
    { lat: 48.176143, lng: 18.945585 },
    { lat: 48.175987, lng: 18.945377 },
    { lat: 48.176016, lng: 18.945146 },
    { lat: 48.176108, lng: 18.945126 },
    { lat: 48.176181, lng: 18.945084 },
    { lat: 48.176235, lng: 18.945003 },
    { lat: 48.176234, lng: 18.94492 },
    { lat: 48.176199, lng: 18.944801 },
    { lat: 48.176093, lng: 18.944672 },
    { lat: 48.176064, lng: 18.944596 },
    { lat: 48.176068, lng: 18.944473 },
    { lat: 48.176094, lng: 18.944421 },
    { lat: 48.176202, lng: 18.944361 },
    { lat: 48.17637, lng: 18.944382 },
    { lat: 48.176472, lng: 18.944348 },
    { lat: 48.176495, lng: 18.944282 },
    { lat: 48.176437, lng: 18.944125 },
    { lat: 48.176492, lng: 18.944034 },
    { lat: 48.176752, lng: 18.943832 },
    { lat: 48.176954, lng: 18.943594 },
    { lat: 48.177169, lng: 18.943387 },
    { lat: 48.177308, lng: 18.943348 },
    { lat: 48.177339, lng: 18.943341 },
    { lat: 48.177282, lng: 18.943257 },
    { lat: 48.177242, lng: 18.943274 },
    { lat: 48.177211, lng: 18.943032 },
    { lat: 48.177218, lng: 18.943002 },
    { lat: 48.177378, lng: 18.942953 },
    { lat: 48.177413, lng: 18.942926 },
    { lat: 48.177429, lng: 18.942876 },
    { lat: 48.177417, lng: 18.942822 },
    { lat: 48.177342, lng: 18.94256 },
    { lat: 48.177365, lng: 18.942352 },
    { lat: 48.177352, lng: 18.942229 },
    { lat: 48.17732, lng: 18.942123 },
    { lat: 48.177266, lng: 18.941992 },
    { lat: 48.177137, lng: 18.941675 },
    { lat: 48.177091, lng: 18.941377 },
    { lat: 48.176985, lng: 18.941132 },
    { lat: 48.177087, lng: 18.940332 },
    { lat: 48.177061, lng: 18.940297 },
    { lat: 48.176971, lng: 18.940243 },
    { lat: 48.176944, lng: 18.940208 },
    { lat: 48.176979, lng: 18.940018 },
    { lat: 48.177032, lng: 18.939647 },
    { lat: 48.177035, lng: 18.939289 },
    { lat: 48.177034, lng: 18.939173 },
    { lat: 48.176955, lng: 18.939012 },
    { lat: 48.176953, lng: 18.93896 },
    { lat: 48.176972, lng: 18.938753 },
    { lat: 48.17702, lng: 18.938199 },
  ],
  IpeľskýSokolec: [
    { lat: 48.0281543, lng: 18.7439248 },
    { lat: 48.0271157, lng: 18.7438855 },
    { lat: 48.0266084, lng: 18.7438707 },
    { lat: 48.0255422, lng: 18.7435855 },
    { lat: 48.0253176, lng: 18.7436453 },
    { lat: 48.0241312, lng: 18.7451035 },
    { lat: 48.023841, lng: 18.7462568 },
    { lat: 48.0215863, lng: 18.7473916 },
    { lat: 48.0208048, lng: 18.7474533 },
    { lat: 48.018009, lng: 18.7469265 },
    { lat: 48.0162731, lng: 18.7467969 },
    { lat: 48.0149108, lng: 18.7468844 },
    { lat: 48.0131276, lng: 18.7471739 },
    { lat: 48.0120385, lng: 18.7467816 },
    { lat: 48.0112274, lng: 18.7492891 },
    { lat: 48.0106466, lng: 18.751082 },
    { lat: 48.0104547, lng: 18.7521747 },
    { lat: 48.0108878, lng: 18.7532525 },
    { lat: 48.0116285, lng: 18.75479 },
    { lat: 48.0126372, lng: 18.7560049 },
    { lat: 48.0131441, lng: 18.7567349 },
    { lat: 48.0136299, lng: 18.7581238 },
    { lat: 48.0134711, lng: 18.7591429 },
    { lat: 48.0127783, lng: 18.7605933 },
    { lat: 48.0109297, lng: 18.7640574 },
    { lat: 48.0107258, lng: 18.7659263 },
    { lat: 48.0103994, lng: 18.7674811 },
    { lat: 48.0095531, lng: 18.7699313 },
    { lat: 48.0092, lng: 18.7705426 },
    { lat: 48.0087382, lng: 18.770724 },
    { lat: 48.0077559, lng: 18.7718445 },
    { lat: 48.0075019, lng: 18.7723461 },
    { lat: 48.006963, lng: 18.7731127 },
    { lat: 48.0064452, lng: 18.7736634 },
    { lat: 48.0061304, lng: 18.7737865 },
    { lat: 48.0054808, lng: 18.7738277 },
    { lat: 48.004465, lng: 18.7745323 },
    { lat: 48.0037567, lng: 18.7753175 },
    { lat: 48.0033156, lng: 18.7762873 },
    { lat: 48.002884, lng: 18.7777563 },
    { lat: 48.0014241, lng: 18.7806111 },
    { lat: 48.0008681, lng: 18.7813722 },
    { lat: 48.0001489, lng: 18.782144 },
    { lat: 47.9979704, lng: 18.7847168 },
    { lat: 47.9973563, lng: 18.7842382 },
    { lat: 47.9971239, lng: 18.785486 },
    { lat: 47.9970107, lng: 18.7865408 },
    { lat: 47.9967281, lng: 18.7883972 },
    { lat: 47.9951279, lng: 18.7908824 },
    { lat: 47.9941441, lng: 18.7926816 },
    { lat: 47.993993, lng: 18.7931518 },
    { lat: 47.9925679, lng: 18.795239 },
    { lat: 47.9921468, lng: 18.7960177 },
    { lat: 47.9910104, lng: 18.7976402 },
    { lat: 47.9909295, lng: 18.8002837 },
    { lat: 47.990335, lng: 18.8032583 },
    { lat: 47.9900377, lng: 18.8059497 },
    { lat: 47.9881647, lng: 18.8075878 },
    { lat: 47.988941, lng: 18.809335 },
    { lat: 47.989725, lng: 18.81082 },
    { lat: 47.990545, lng: 18.81193 },
    { lat: 47.9906726, lng: 18.8120899 },
    { lat: 47.991716, lng: 18.81325 },
    { lat: 47.992333, lng: 18.814052 },
    { lat: 47.992977, lng: 18.814992 },
    { lat: 47.993344, lng: 18.815411 },
    { lat: 47.993442, lng: 18.815743 },
    { lat: 47.99368, lng: 18.815986 },
    { lat: 47.994198, lng: 18.816143 },
    { lat: 47.994736, lng: 18.816447 },
    { lat: 47.995187, lng: 18.816934 },
    { lat: 47.995617, lng: 18.817217 },
    { lat: 47.99639, lng: 18.817573 },
    { lat: 47.996586, lng: 18.81772 },
    { lat: 47.996627, lng: 18.817637 },
    { lat: 47.996755, lng: 18.817718 },
    { lat: 47.99695, lng: 18.817591 },
    { lat: 47.997022, lng: 18.817375 },
    { lat: 47.997035, lng: 18.816904 },
    { lat: 47.99693, lng: 18.815986 },
    { lat: 47.997017, lng: 18.815587 },
    { lat: 47.997194, lng: 18.815453 },
    { lat: 47.997486, lng: 18.815648 },
    { lat: 47.998059, lng: 18.816676 },
    { lat: 47.998826, lng: 18.817648 },
    { lat: 48.000113, lng: 18.81862 },
    { lat: 48.000454, lng: 18.818826 },
    { lat: 48.000528, lng: 18.818795 },
    { lat: 48.000763, lng: 18.818881 },
    { lat: 48.000843, lng: 18.819036 },
    { lat: 48.000983, lng: 18.81898 },
    { lat: 48.00103, lng: 18.81877 },
    { lat: 48.000934, lng: 18.81816 },
    { lat: 48.000942, lng: 18.817533 },
    { lat: 48.001035, lng: 18.817383 },
    { lat: 48.001275, lng: 18.817351 },
    { lat: 48.001821, lng: 18.817669 },
    { lat: 48.002736, lng: 18.818603 },
    { lat: 48.002997, lng: 18.819052 },
    { lat: 48.003491, lng: 18.819388 },
    { lat: 48.003803, lng: 18.819317 },
    { lat: 48.004602, lng: 18.818849 },
    { lat: 48.004868, lng: 18.818929 },
    { lat: 48.005212, lng: 18.819307 },
    { lat: 48.005296, lng: 18.819618 },
    { lat: 48.006012, lng: 18.820949 },
    { lat: 48.006299, lng: 18.821275 },
    { lat: 48.007669, lng: 18.821151 },
    { lat: 48.007759, lng: 18.821089 },
    { lat: 48.008044, lng: 18.820818 },
    { lat: 48.008471, lng: 18.820252 },
    { lat: 48.009511, lng: 18.819237 },
    { lat: 48.009683, lng: 18.818947 },
    { lat: 48.009909, lng: 18.818301 },
    { lat: 48.010241, lng: 18.817807 },
    { lat: 48.010567, lng: 18.817476 },
    { lat: 48.011047, lng: 18.81717 },
    { lat: 48.012211, lng: 18.817001 },
    { lat: 48.013074, lng: 18.816971 },
    { lat: 48.01489, lng: 18.817361 },
    { lat: 48.015789, lng: 18.817373 },
    { lat: 48.016996, lng: 18.817271 },
    { lat: 48.018758, lng: 18.816594 },
    { lat: 48.020134, lng: 18.81576 },
    { lat: 48.02103, lng: 18.815513 },
    { lat: 48.021423, lng: 18.815567 },
    { lat: 48.021951, lng: 18.815762 },
    { lat: 48.022346, lng: 18.816065 },
    { lat: 48.022632, lng: 18.816378 },
    { lat: 48.022944, lng: 18.816766 },
    { lat: 48.023411, lng: 18.818062 },
    { lat: 48.023768, lng: 18.818543 },
    { lat: 48.02406, lng: 18.819166 },
    { lat: 48.024234, lng: 18.819323 },
    { lat: 48.024811, lng: 18.820039 },
    { lat: 48.025641, lng: 18.821622 },
    { lat: 48.026479, lng: 18.822919 },
    { lat: 48.026963, lng: 18.823526 },
    { lat: 48.027929, lng: 18.824537 },
    { lat: 48.028828, lng: 18.82533 },
    { lat: 48.029827, lng: 18.826023 },
    { lat: 48.03063, lng: 18.826374 },
    { lat: 48.031355, lng: 18.826553 },
    { lat: 48.032019, lng: 18.82662 },
    { lat: 48.032751, lng: 18.826561 },
    { lat: 48.033797, lng: 18.82618 },
    { lat: 48.034304, lng: 18.825933 },
    { lat: 48.034798, lng: 18.825595 },
    { lat: 48.03558, lng: 18.82486 },
    { lat: 48.036062, lng: 18.824138 },
    { lat: 48.036791, lng: 18.822615 },
    { lat: 48.037206, lng: 18.821266 },
    { lat: 48.038091, lng: 18.821835 },
    { lat: 48.038537, lng: 18.820696 },
    { lat: 48.0377164, lng: 18.8200301 },
    { lat: 48.0376311, lng: 18.8196137 },
    { lat: 48.0372659, lng: 18.8187176 },
    { lat: 48.0371219, lng: 18.8183849 },
    { lat: 48.0368593, lng: 18.8179803 },
    { lat: 48.0368229, lng: 18.8178059 },
    { lat: 48.0368498, lng: 18.8176079 },
    { lat: 48.0367466, lng: 18.8175108 },
    { lat: 48.0366999, lng: 18.8174634 },
    { lat: 48.0364048, lng: 18.8160583 },
    { lat: 48.0358217, lng: 18.8129215 },
    { lat: 48.0355005, lng: 18.8114488 },
    { lat: 48.0353289, lng: 18.8098481 },
    { lat: 48.0362126, lng: 18.8074054 },
    { lat: 48.0363918, lng: 18.8066785 },
    { lat: 48.0360909, lng: 18.8045124 },
    { lat: 48.0359285, lng: 18.8028276 },
    { lat: 48.0336491, lng: 18.8027579 },
    { lat: 48.0336118, lng: 18.8013928 },
    { lat: 48.0337608, lng: 18.7988825 },
    { lat: 48.0337658, lng: 18.7970191 },
    { lat: 48.0336399, lng: 18.7947955 },
    { lat: 48.0325671, lng: 18.7950008 },
    { lat: 48.0320968, lng: 18.7922767 },
    { lat: 48.031717, lng: 18.7925135 },
    { lat: 48.0302043, lng: 18.79319 },
    { lat: 48.0296186, lng: 18.7913738 },
    { lat: 48.0295899, lng: 18.7907874 },
    { lat: 48.0296329, lng: 18.7898135 },
    { lat: 48.0294871, lng: 18.7885754 },
    { lat: 48.0292946, lng: 18.7848016 },
    { lat: 48.0286164, lng: 18.7792275 },
    { lat: 48.0277923, lng: 18.7769863 },
    { lat: 48.0271041, lng: 18.7757331 },
    { lat: 48.026575, lng: 18.774329 },
    { lat: 48.0259731, lng: 18.7717055 },
    { lat: 48.0269351, lng: 18.768761 },
    { lat: 48.027201, lng: 18.7678234 },
    { lat: 48.0274458, lng: 18.7667678 },
    { lat: 48.0276156, lng: 18.7652123 },
    { lat: 48.0275979, lng: 18.7634388 },
    { lat: 48.0269362, lng: 18.7587093 },
    { lat: 48.027046, lng: 18.7564137 },
    { lat: 48.0269378, lng: 18.7552717 },
    { lat: 48.0269031, lng: 18.7536284 },
    { lat: 48.0272344, lng: 18.7526502 },
    { lat: 48.0274287, lng: 18.7511376 },
    { lat: 48.0271932, lng: 18.7500857 },
    { lat: 48.0273749, lng: 18.7475948 },
    { lat: 48.0278961, lng: 18.745678 },
    { lat: 48.0281543, lng: 18.7439248 },
  ],
  DolnýPial: [
    { lat: 48.1155965, lng: 18.5043189 },
    { lat: 48.116107, lng: 18.5035772 },
    { lat: 48.1167953, lng: 18.5023836 },
    { lat: 48.1172259, lng: 18.5015219 },
    { lat: 48.1186935, lng: 18.4990416 },
    { lat: 48.1204289, lng: 18.4968386 },
    { lat: 48.1214434, lng: 18.4951386 },
    { lat: 48.1230783, lng: 18.4925874 },
    { lat: 48.1235641, lng: 18.4916879 },
    { lat: 48.1243484, lng: 18.4904319 },
    { lat: 48.1260595, lng: 18.4879952 },
    { lat: 48.1271019, lng: 18.4863952 },
    { lat: 48.1279927, lng: 18.484727 },
    { lat: 48.1286731, lng: 18.4855401 },
    { lat: 48.1303943, lng: 18.4885581 },
    { lat: 48.1313923, lng: 18.4874734 },
    { lat: 48.1316569, lng: 18.4880314 },
    { lat: 48.1321425, lng: 18.486358 },
    { lat: 48.133096, lng: 18.4850044 },
    { lat: 48.133665, lng: 18.4839004 },
    { lat: 48.1346109, lng: 18.4815581 },
    { lat: 48.135029, lng: 18.4807003 },
    { lat: 48.1354379, lng: 18.4796963 },
    { lat: 48.1356459, lng: 18.4790556 },
    { lat: 48.135895, lng: 18.4773528 },
    { lat: 48.1362357, lng: 18.4760133 },
    { lat: 48.1368497, lng: 18.4744999 },
    { lat: 48.1385794, lng: 18.4762099 },
    { lat: 48.1401217, lng: 18.4773866 },
    { lat: 48.1423165, lng: 18.4798461 },
    { lat: 48.1434297, lng: 18.4788324 },
    { lat: 48.1441839, lng: 18.4780319 },
    { lat: 48.1454678, lng: 18.479289 },
    { lat: 48.1454833, lng: 18.4793256 },
    { lat: 48.1465343, lng: 18.4781836 },
    { lat: 48.1471578, lng: 18.477329 },
    { lat: 48.1484397, lng: 18.4749847 },
    { lat: 48.1493783, lng: 18.4727382 },
    { lat: 48.1498673, lng: 18.4713512 },
    { lat: 48.1498858, lng: 18.4713015 },
    { lat: 48.1516847, lng: 18.4615248 },
    { lat: 48.1466967, lng: 18.4572932 },
    { lat: 48.1457677, lng: 18.454461 },
    { lat: 48.145418, lng: 18.4537964 },
    { lat: 48.1451302, lng: 18.4534293 },
    { lat: 48.1448741, lng: 18.4532048 },
    { lat: 48.1446458, lng: 18.4528762 },
    { lat: 48.1445712, lng: 18.4526714 },
    { lat: 48.1451281, lng: 18.4502229 },
    { lat: 48.1455582, lng: 18.4480738 },
    { lat: 48.1464789, lng: 18.4450703 },
    { lat: 48.1468985, lng: 18.4446056 },
    { lat: 48.1442648, lng: 18.4414742 },
    { lat: 48.1447877, lng: 18.4408207 },
    { lat: 48.1438093, lng: 18.4391247 },
    { lat: 48.1446399, lng: 18.4374185 },
    { lat: 48.1448695, lng: 18.4366434 },
    { lat: 48.1453538, lng: 18.4353814 },
    { lat: 48.1462059, lng: 18.4336169 },
    { lat: 48.1465758, lng: 18.432732 },
    { lat: 48.1467054, lng: 18.4328295 },
    { lat: 48.1469178, lng: 18.4323129 },
    { lat: 48.1471394, lng: 18.4320994 },
    { lat: 48.14725, lng: 18.4317372 },
    { lat: 48.14732, lng: 18.4311127 },
    { lat: 48.1472509, lng: 18.4302241 },
    { lat: 48.1475194, lng: 18.4287396 },
    { lat: 48.1474904, lng: 18.4284843 },
    { lat: 48.1475435, lng: 18.4275613 },
    { lat: 48.1475199, lng: 18.4266985 },
    { lat: 48.146413, lng: 18.4267358 },
    { lat: 48.1463268, lng: 18.4272663 },
    { lat: 48.1459301, lng: 18.4272369 },
    { lat: 48.1453709, lng: 18.4275857 },
    { lat: 48.1446053, lng: 18.42699 },
    { lat: 48.1446772, lng: 18.4262608 },
    { lat: 48.1444322, lng: 18.4246306 },
    { lat: 48.1440558, lng: 18.4234103 },
    { lat: 48.1433118, lng: 18.4241041 },
    { lat: 48.141958, lng: 18.4238663 },
    { lat: 48.1414021, lng: 18.4243896 },
    { lat: 48.140648, lng: 18.4247027 },
    { lat: 48.1400695, lng: 18.4251691 },
    { lat: 48.1396051, lng: 18.4256802 },
    { lat: 48.1392236, lng: 18.4259385 },
    { lat: 48.1386845, lng: 18.4261004 },
    { lat: 48.1381875, lng: 18.4261916 },
    { lat: 48.1362404, lng: 18.4260935 },
    { lat: 48.135886, lng: 18.425165 },
    { lat: 48.135803, lng: 18.426037 },
    { lat: 48.135784, lng: 18.426269 },
    { lat: 48.135671, lng: 18.426803 },
    { lat: 48.13556, lng: 18.427244 },
    { lat: 48.135223, lng: 18.428313 },
    { lat: 48.13491, lng: 18.429648 },
    { lat: 48.134777, lng: 18.430344 },
    { lat: 48.134598, lng: 18.431323 },
    { lat: 48.134516, lng: 18.431725 },
    { lat: 48.134512, lng: 18.431744 },
    { lat: 48.134448, lng: 18.432062 },
    { lat: 48.134292, lng: 18.432916 },
    { lat: 48.134134, lng: 18.433623 },
    { lat: 48.134053, lng: 18.434083 },
    { lat: 48.133995, lng: 18.434349 },
    { lat: 48.13388, lng: 18.434704 },
    { lat: 48.133742, lng: 18.435104 },
    { lat: 48.133638, lng: 18.435331 },
    { lat: 48.133549, lng: 18.435474 },
    { lat: 48.1333, lng: 18.435907 },
    { lat: 48.133292, lng: 18.43593 },
    { lat: 48.133221, lng: 18.436133 },
    { lat: 48.133149, lng: 18.436342 },
    { lat: 48.133096, lng: 18.436493 },
    { lat: 48.133022, lng: 18.436707 },
    { lat: 48.132991, lng: 18.436797 },
    { lat: 48.132889, lng: 18.437055 },
    { lat: 48.13287, lng: 18.437102 },
    { lat: 48.132779, lng: 18.437403 },
    { lat: 48.132772, lng: 18.437421 },
    { lat: 48.132693, lng: 18.437632 },
    { lat: 48.132661, lng: 18.437706 },
    { lat: 48.132598, lng: 18.437857 },
    { lat: 48.132544, lng: 18.437968 },
    { lat: 48.132512, lng: 18.438023 },
    { lat: 48.132407, lng: 18.43816 },
    { lat: 48.13223, lng: 18.438334 },
    { lat: 48.131832, lng: 18.438742 },
    { lat: 48.131569, lng: 18.438983 },
    { lat: 48.131465, lng: 18.439079 },
    { lat: 48.13116, lng: 18.439476 },
    { lat: 48.131058, lng: 18.439575 },
    { lat: 48.130835, lng: 18.439735 },
    { lat: 48.130691, lng: 18.439854 },
    { lat: 48.129863, lng: 18.440912 },
    { lat: 48.12957, lng: 18.441343 },
    { lat: 48.129275, lng: 18.44187 },
    { lat: 48.129159, lng: 18.442084 },
    { lat: 48.129138, lng: 18.442124 },
    { lat: 48.129111, lng: 18.442173 },
    { lat: 48.128974, lng: 18.442459 },
    { lat: 48.128828, lng: 18.442807 },
    { lat: 48.128605, lng: 18.443247 },
    { lat: 48.128493, lng: 18.443565 },
    { lat: 48.128272, lng: 18.444209 },
    { lat: 48.128159, lng: 18.444521 },
    { lat: 48.12805, lng: 18.444819 },
    { lat: 48.12793, lng: 18.445176 },
    { lat: 48.127915, lng: 18.44521 },
    { lat: 48.127802, lng: 18.445477 },
    { lat: 48.127677, lng: 18.445707 },
    { lat: 48.127179, lng: 18.446365 },
    { lat: 48.127017, lng: 18.446577 },
    { lat: 48.126819, lng: 18.446846 },
    { lat: 48.126351, lng: 18.447476 },
    { lat: 48.126143, lng: 18.447733 },
    { lat: 48.125991, lng: 18.447889 },
    { lat: 48.125831, lng: 18.448033 },
    { lat: 48.125522, lng: 18.448281 },
    { lat: 48.125213, lng: 18.448467 },
    { lat: 48.12492, lng: 18.448678 },
    { lat: 48.124657, lng: 18.448883 },
    { lat: 48.124003, lng: 18.449368 },
    { lat: 48.123782, lng: 18.449525 },
    { lat: 48.123084, lng: 18.449918 },
    { lat: 48.122888, lng: 18.44998 },
    { lat: 48.122885, lng: 18.449982 },
    { lat: 48.122868, lng: 18.449996 },
    { lat: 48.122772, lng: 18.450082 },
    { lat: 48.122762, lng: 18.450092 },
    { lat: 48.122621, lng: 18.450084 },
    { lat: 48.12238, lng: 18.450183 },
    { lat: 48.122231, lng: 18.450304 },
    { lat: 48.122056, lng: 18.450384 },
    { lat: 48.121914, lng: 18.45048 },
    { lat: 48.121523, lng: 18.450791 },
    { lat: 48.121285, lng: 18.450862 },
    { lat: 48.121023, lng: 18.450906 },
    { lat: 48.120795, lng: 18.450972 },
    { lat: 48.120675, lng: 18.451043 },
    { lat: 48.120472, lng: 18.451207 },
    { lat: 48.120298, lng: 18.4513 },
    { lat: 48.120107, lng: 18.451426 },
    { lat: 48.119967, lng: 18.451528 },
    { lat: 48.119787, lng: 18.451682 },
    { lat: 48.119736, lng: 18.451715 },
    { lat: 48.119531, lng: 18.45183 },
    { lat: 48.11941, lng: 18.451886 },
    { lat: 48.119235, lng: 18.451963 },
    { lat: 48.119064, lng: 18.452033 },
    { lat: 48.118647, lng: 18.452342 },
    { lat: 48.118484, lng: 18.452451 },
    { lat: 48.118426, lng: 18.452484 },
    { lat: 48.118324, lng: 18.452516 },
    { lat: 48.118252, lng: 18.452544 },
    { lat: 48.118081, lng: 18.452628 },
    { lat: 48.118022, lng: 18.452654 },
    { lat: 48.11793, lng: 18.452705 },
    { lat: 48.117888, lng: 18.452721 },
    { lat: 48.117832, lng: 18.452751 },
    { lat: 48.117498, lng: 18.452866 },
    { lat: 48.117396, lng: 18.452907 },
    { lat: 48.117357, lng: 18.452928 },
    { lat: 48.117193, lng: 18.452989 },
    { lat: 48.117029, lng: 18.453039 },
    { lat: 48.117017, lng: 18.453043 },
    { lat: 48.117005, lng: 18.453047 },
    { lat: 48.116992, lng: 18.453051 },
    { lat: 48.116872, lng: 18.453091 },
    { lat: 48.116587, lng: 18.45317 },
    { lat: 48.116459, lng: 18.453212 },
    { lat: 48.116344, lng: 18.453273 },
    { lat: 48.11622, lng: 18.453354 },
    { lat: 48.116098, lng: 18.453445 },
    { lat: 48.11593, lng: 18.453574 },
    { lat: 48.115854, lng: 18.453648 },
    { lat: 48.115814, lng: 18.453695 },
    { lat: 48.115592, lng: 18.453882 },
    { lat: 48.115407, lng: 18.454058 },
    { lat: 48.115352, lng: 18.454104 },
    { lat: 48.115334, lng: 18.454113 },
    { lat: 48.115269, lng: 18.454162 },
    { lat: 48.115099, lng: 18.454271 },
    { lat: 48.115074, lng: 18.454294 },
    { lat: 48.115025, lng: 18.454327 },
    { lat: 48.114907, lng: 18.454386 },
    { lat: 48.114716, lng: 18.45451 },
    { lat: 48.11455, lng: 18.454589 },
    { lat: 48.114466, lng: 18.454618 },
    { lat: 48.1144, lng: 18.454648 },
    { lat: 48.114166, lng: 18.454741 },
    { lat: 48.113914, lng: 18.454852 },
    { lat: 48.113737, lng: 18.454934 },
    { lat: 48.113336, lng: 18.455138 },
    { lat: 48.113126, lng: 18.455228 },
    { lat: 48.113005, lng: 18.455302 },
    { lat: 48.112952, lng: 18.455324 },
    { lat: 48.112778, lng: 18.455429 },
    { lat: 48.112506, lng: 18.455658 },
    { lat: 48.112367, lng: 18.455814 },
    { lat: 48.112298, lng: 18.455862 },
    { lat: 48.112159, lng: 18.455958 },
    { lat: 48.112004, lng: 18.456074 },
    { lat: 48.111845, lng: 18.456213 },
    { lat: 48.111251, lng: 18.456574 },
    { lat: 48.11104, lng: 18.45674 },
    { lat: 48.110844, lng: 18.456865 },
    { lat: 48.110596, lng: 18.45697 },
    { lat: 48.110469, lng: 18.457006 },
    { lat: 48.110314, lng: 18.457041 },
    { lat: 48.110089, lng: 18.457104 },
    { lat: 48.110024, lng: 18.457119 },
    { lat: 48.109776, lng: 18.457162 },
    { lat: 48.109504, lng: 18.457221 },
    { lat: 48.109328, lng: 18.457316 },
    { lat: 48.109239, lng: 18.457371 },
    { lat: 48.109156, lng: 18.457435 },
    { lat: 48.109111, lng: 18.457453 },
    { lat: 48.108906, lng: 18.457515 },
    { lat: 48.108397, lng: 18.457537 },
    { lat: 48.108183, lng: 18.457607 },
    { lat: 48.107944, lng: 18.45773 },
    { lat: 48.107472, lng: 18.457863 },
    { lat: 48.107318, lng: 18.457883 },
    { lat: 48.107194, lng: 18.457959 },
    { lat: 48.107006, lng: 18.458118 },
    { lat: 48.106736, lng: 18.458463 },
    { lat: 48.106603, lng: 18.458632 },
    { lat: 48.106476, lng: 18.458926 },
    { lat: 48.106397, lng: 18.459053 },
    { lat: 48.106331, lng: 18.459166 },
    { lat: 48.106306, lng: 18.459213 },
    { lat: 48.1075765, lng: 18.460663 },
    { lat: 48.1075422, lng: 18.4608939 },
    { lat: 48.1082807, lng: 18.4617778 },
    { lat: 48.108385, lng: 18.462169 },
    { lat: 48.1084285, lng: 18.4625451 },
    { lat: 48.1087682, lng: 18.463315 },
    { lat: 48.1085533, lng: 18.464094 },
    { lat: 48.1093997, lng: 18.4663013 },
    { lat: 48.1090656, lng: 18.4667996 },
    { lat: 48.1086141, lng: 18.4671382 },
    { lat: 48.1087617, lng: 18.4677245 },
    { lat: 48.1085402, lng: 18.4679402 },
    { lat: 48.1086523, lng: 18.4683519 },
    { lat: 48.1098402, lng: 18.4715828 },
    { lat: 48.1102598, lng: 18.4719651 },
    { lat: 48.1101145, lng: 18.4726189 },
    { lat: 48.1099628, lng: 18.4740861 },
    { lat: 48.1099958, lng: 18.4742507 },
    { lat: 48.1110349, lng: 18.4760551 },
    { lat: 48.1125088, lng: 18.4782296 },
    { lat: 48.1129241, lng: 18.4795702 },
    { lat: 48.1134757, lng: 18.4810054 },
    { lat: 48.1136327, lng: 18.4813534 },
    { lat: 48.1141562, lng: 18.4822274 },
    { lat: 48.1142711, lng: 18.4825283 },
    { lat: 48.114333, lng: 18.4829683 },
    { lat: 48.1143109, lng: 18.4837907 },
    { lat: 48.1143706, lng: 18.4843537 },
    { lat: 48.114246, lng: 18.4857417 },
    { lat: 48.1142069, lng: 18.4871497 },
    { lat: 48.1142883, lng: 18.4883063 },
    { lat: 48.1146835, lng: 18.4898352 },
    { lat: 48.1148045, lng: 18.4906935 },
    { lat: 48.1149464, lng: 18.4912983 },
    { lat: 48.1147735, lng: 18.4921645 },
    { lat: 48.1161603, lng: 18.4937135 },
    { lat: 48.1154971, lng: 18.4957238 },
    { lat: 48.115388, lng: 18.4968864 },
    { lat: 48.1161606, lng: 18.4977561 },
    { lat: 48.1145862, lng: 18.5024378 },
    { lat: 48.1150799, lng: 18.5035039 },
    { lat: 48.1155965, lng: 18.5043189 },
  ],
  Bohunice: [
    { lat: 48.3373405, lng: 18.727662 },
    { lat: 48.3369273, lng: 18.7276963 },
    { lat: 48.3367283, lng: 18.7276641 },
    { lat: 48.3363143, lng: 18.7277666 },
    { lat: 48.3359908, lng: 18.7277288 },
    { lat: 48.3352364, lng: 18.7278607 },
    { lat: 48.3346961, lng: 18.7286 },
    { lat: 48.334604, lng: 18.7283534 },
    { lat: 48.3344435, lng: 18.7285963 },
    { lat: 48.3340438, lng: 18.7289288 },
    { lat: 48.3337183, lng: 18.7293623 },
    { lat: 48.3331391, lng: 18.7298542 },
    { lat: 48.3327128, lng: 18.730388 },
    { lat: 48.3319645, lng: 18.7307758 },
    { lat: 48.3314122, lng: 18.731185 },
    { lat: 48.3310993, lng: 18.7313444 },
    { lat: 48.3302566, lng: 18.7320206 },
    { lat: 48.3295412, lng: 18.7322024 },
    { lat: 48.3292995, lng: 18.7325581 },
    { lat: 48.3285094, lng: 18.7328813 },
    { lat: 48.3283243, lng: 18.7328574 },
    { lat: 48.3277946, lng: 18.7329719 },
    { lat: 48.3274829, lng: 18.7336425 },
    { lat: 48.3270765, lng: 18.7339322 },
    { lat: 48.3265293, lng: 18.7347518 },
    { lat: 48.3255397, lng: 18.7369881 },
    { lat: 48.3249329, lng: 18.7380686 },
    { lat: 48.3243179, lng: 18.7385281 },
    { lat: 48.3232662, lng: 18.7394519 },
    { lat: 48.3230924, lng: 18.7397644 },
    { lat: 48.3230376, lng: 18.7402181 },
    { lat: 48.322672, lng: 18.7412764 },
    { lat: 48.3221677, lng: 18.7416758 },
    { lat: 48.3220643, lng: 18.7419816 },
    { lat: 48.3214499, lng: 18.7425327 },
    { lat: 48.3211529, lng: 18.74289 },
    { lat: 48.3208789, lng: 18.7430567 },
    { lat: 48.3206625, lng: 18.7429968 },
    { lat: 48.3204156, lng: 18.7431879 },
    { lat: 48.3207928, lng: 18.7438153 },
    { lat: 48.3209845, lng: 18.7444522 },
    { lat: 48.3212178, lng: 18.7445314 },
    { lat: 48.3214636, lng: 18.7441498 },
    { lat: 48.3216102, lng: 18.7445124 },
    { lat: 48.3216536, lng: 18.7449456 },
    { lat: 48.3220109, lng: 18.7452785 },
    { lat: 48.3218439, lng: 18.7460228 },
    { lat: 48.3215992, lng: 18.7461498 },
    { lat: 48.3214391, lng: 18.746384 },
    { lat: 48.3214623, lng: 18.7470329 },
    { lat: 48.32139, lng: 18.7474519 },
    { lat: 48.3213945, lng: 18.7477493 },
    { lat: 48.3214854, lng: 18.7481446 },
    { lat: 48.3216801, lng: 18.7483927 },
    { lat: 48.3218012, lng: 18.7489112 },
    { lat: 48.3219529, lng: 18.7491323 },
    { lat: 48.3220443, lng: 18.7496196 },
    { lat: 48.3221276, lng: 18.7498315 },
    { lat: 48.322171, lng: 18.7503291 },
    { lat: 48.3221455, lng: 18.7506975 },
    { lat: 48.3220709, lng: 18.7509938 },
    { lat: 48.3218851, lng: 18.7512555 },
    { lat: 48.3216598, lng: 18.7514093 },
    { lat: 48.3215412, lng: 18.7517221 },
    { lat: 48.3215998, lng: 18.7517442 },
    { lat: 48.321716, lng: 18.7527608 },
    { lat: 48.3216544, lng: 18.7539375 },
    { lat: 48.3216112, lng: 18.7541039 },
    { lat: 48.3216945, lng: 18.7571612 },
    { lat: 48.3217894, lng: 18.7576927 },
    { lat: 48.3217548, lng: 18.7578914 },
    { lat: 48.322415, lng: 18.7587329 },
    { lat: 48.3228013, lng: 18.7594441 },
    { lat: 48.3229676, lng: 18.7596032 },
    { lat: 48.3236084, lng: 18.7599474 },
    { lat: 48.3244124, lng: 18.7609235 },
    { lat: 48.3248337, lng: 18.7612302 },
    { lat: 48.3250069, lng: 18.7612659 },
    { lat: 48.3252571, lng: 18.7615401 },
    { lat: 48.3254996, lng: 18.761626 },
    { lat: 48.325852, lng: 18.7614437 },
    { lat: 48.3263292, lng: 18.7616189 },
    { lat: 48.326627, lng: 18.7622341 },
    { lat: 48.3268657, lng: 18.7623998 },
    { lat: 48.3269694, lng: 18.7622698 },
    { lat: 48.3270603, lng: 18.7622586 },
    { lat: 48.327466, lng: 18.7626819 },
    { lat: 48.3276843, lng: 18.7632689 },
    { lat: 48.3280245, lng: 18.7637002 },
    { lat: 48.3284197, lng: 18.7635414 },
    { lat: 48.3286776, lng: 18.7641578 },
    { lat: 48.3287741, lng: 18.7644437 },
    { lat: 48.3287339, lng: 18.7646225 },
    { lat: 48.3288013, lng: 18.7647874 },
    { lat: 48.3293136, lng: 18.765259 },
    { lat: 48.329423, lng: 18.7650893 },
    { lat: 48.3296526, lng: 18.7651679 },
    { lat: 48.3299742, lng: 18.7651272 },
    { lat: 48.3303245, lng: 18.7656326 },
    { lat: 48.3315577, lng: 18.7658132 },
    { lat: 48.3316384, lng: 18.7660595 },
    { lat: 48.331996, lng: 18.7662752 },
    { lat: 48.332424, lng: 18.7667304 },
    { lat: 48.3329724, lng: 18.7675228 },
    { lat: 48.3332002, lng: 18.7679284 },
    { lat: 48.3332741, lng: 18.768454 },
    { lat: 48.3335036, lng: 18.7686741 },
    { lat: 48.3337792, lng: 18.7692364 },
    { lat: 48.3339598, lng: 18.7694516 },
    { lat: 48.3341947, lng: 18.7696523 },
    { lat: 48.3343251, lng: 18.7696867 },
    { lat: 48.3346214, lng: 18.7701278 },
    { lat: 48.3350555, lng: 18.7702757 },
    { lat: 48.3354172, lng: 18.7705676 },
    { lat: 48.3356993, lng: 18.7706559 },
    { lat: 48.3357417, lng: 18.7709263 },
    { lat: 48.3358724, lng: 18.7711762 },
    { lat: 48.3361537, lng: 18.7711636 },
    { lat: 48.3364515, lng: 18.771255 },
    { lat: 48.3364396, lng: 18.7716687 },
    { lat: 48.336623, lng: 18.7719584 },
    { lat: 48.3370274, lng: 18.7721212 },
    { lat: 48.3373353, lng: 18.7720537 },
    { lat: 48.3376189, lng: 18.7723606 },
    { lat: 48.3376272, lng: 18.7727101 },
    { lat: 48.3378148, lng: 18.7729774 },
    { lat: 48.3382628, lng: 18.7731499 },
    { lat: 48.3383254, lng: 18.7732264 },
    { lat: 48.3383533, lng: 18.7735836 },
    { lat: 48.3380707, lng: 18.7737179 },
    { lat: 48.3380729, lng: 18.7743993 },
    { lat: 48.338602, lng: 18.7743189 },
    { lat: 48.338845, lng: 18.7748639 },
    { lat: 48.3390944, lng: 18.7751827 },
    { lat: 48.3396433, lng: 18.7751596 },
    { lat: 48.3398835, lng: 18.7750498 },
    { lat: 48.3406613, lng: 18.7743402 },
    { lat: 48.3411912, lng: 18.7745075 },
    { lat: 48.3413504, lng: 18.7744251 },
    { lat: 48.3417903, lng: 18.7739807 },
    { lat: 48.3420001, lng: 18.7740869 },
    { lat: 48.3422064, lng: 18.7740021 },
    { lat: 48.34309, lng: 18.7733709 },
    { lat: 48.343446, lng: 18.7734097 },
    { lat: 48.343753, lng: 18.7736692 },
    { lat: 48.3439332, lng: 18.7738991 },
    { lat: 48.3442556, lng: 18.7740423 },
    { lat: 48.3443752, lng: 18.7740472 },
    { lat: 48.3445105, lng: 18.7739155 },
    { lat: 48.3449438, lng: 18.774177 },
    { lat: 48.3453382, lng: 18.7739801 },
    { lat: 48.3458364, lng: 18.7740608 },
    { lat: 48.346012, lng: 18.7750985 },
    { lat: 48.3459056, lng: 18.7752179 },
    { lat: 48.34598, lng: 18.7757549 },
    { lat: 48.345948, lng: 18.775981 },
    { lat: 48.3461949, lng: 18.7764037 },
    { lat: 48.3461519, lng: 18.7766984 },
    { lat: 48.3462018, lng: 18.7769187 },
    { lat: 48.3465043, lng: 18.7767547 },
    { lat: 48.347918, lng: 18.7764685 },
    { lat: 48.348387, lng: 18.7766325 },
    { lat: 48.3485698, lng: 18.776531 },
    { lat: 48.3488193, lng: 18.7768126 },
    { lat: 48.3493141, lng: 18.7770838 },
    { lat: 48.3509734, lng: 18.777497 },
    { lat: 48.3514154, lng: 18.7774313 },
    { lat: 48.3518646, lng: 18.7774796 },
    { lat: 48.3523754, lng: 18.7778617 },
    { lat: 48.3529777, lng: 18.7788041 },
    { lat: 48.3534613, lng: 18.7790572 },
    { lat: 48.3538595, lng: 18.7795938 },
    { lat: 48.3543584, lng: 18.7800061 },
    { lat: 48.354501, lng: 18.7801862 },
    { lat: 48.3549489, lng: 18.7805095 },
    { lat: 48.3564829, lng: 18.7808339 },
    { lat: 48.3571305, lng: 18.7812012 },
    { lat: 48.3572924, lng: 18.7813862 },
    { lat: 48.3581127, lng: 18.781884 },
    { lat: 48.3583524, lng: 18.7819598 },
    { lat: 48.358863, lng: 18.781936 },
    { lat: 48.3595636, lng: 18.7821094 },
    { lat: 48.3613779, lng: 18.783048 },
    { lat: 48.361918, lng: 18.7836452 },
    { lat: 48.3622086, lng: 18.784427 },
    { lat: 48.3626114, lng: 18.7847518 },
    { lat: 48.3628796, lng: 18.7851097 },
    { lat: 48.3632904, lng: 18.7855016 },
    { lat: 48.3639553, lng: 18.7859195 },
    { lat: 48.3646967, lng: 18.7865272 },
    { lat: 48.3651925, lng: 18.7872042 },
    { lat: 48.3654562, lng: 18.7873906 },
    { lat: 48.3662543, lng: 18.7875795 },
    { lat: 48.3667658, lng: 18.7875294 },
    { lat: 48.3673155, lng: 18.7871939 },
    { lat: 48.3677212, lng: 18.7867627 },
    { lat: 48.3678643, lng: 18.7865009 },
    { lat: 48.3679763, lng: 18.7864015 },
    { lat: 48.3689205, lng: 18.7861524 },
    { lat: 48.3702065, lng: 18.7861794 },
    { lat: 48.3711234, lng: 18.7859683 },
    { lat: 48.3717375, lng: 18.7859351 },
    { lat: 48.3724436, lng: 18.7857469 },
    { lat: 48.3729704, lng: 18.7850615 },
    { lat: 48.373301, lng: 18.7847892 },
    { lat: 48.3742715, lng: 18.7842684 },
    { lat: 48.3747811, lng: 18.7841585 },
    { lat: 48.3749983, lng: 18.7842345 },
    { lat: 48.3758083, lng: 18.7842815 },
    { lat: 48.3765223, lng: 18.7843921 },
    { lat: 48.3767153, lng: 18.7844806 },
    { lat: 48.3769334, lng: 18.7844695 },
    { lat: 48.37699, lng: 18.784362 },
    { lat: 48.376882, lng: 18.783784 },
    { lat: 48.3767439, lng: 18.7834403 },
    { lat: 48.3767028, lng: 18.7831536 },
    { lat: 48.3767812, lng: 18.78293 },
    { lat: 48.3769405, lng: 18.7828941 },
    { lat: 48.3769809, lng: 18.7824817 },
    { lat: 48.3771224, lng: 18.7820868 },
    { lat: 48.3770029, lng: 18.7815107 },
    { lat: 48.3771142, lng: 18.781484 },
    { lat: 48.3771286, lng: 18.7813709 },
    { lat: 48.3772074, lng: 18.7813693 },
    { lat: 48.3772498, lng: 18.7809943 },
    { lat: 48.3774144, lng: 18.7805665 },
    { lat: 48.3775311, lng: 18.7804508 },
    { lat: 48.3774072, lng: 18.7798752 },
    { lat: 48.3776376, lng: 18.7797145 },
    { lat: 48.3775844, lng: 18.779409 },
    { lat: 48.3776646, lng: 18.7793711 },
    { lat: 48.3776693, lng: 18.7792233 },
    { lat: 48.377752, lng: 18.7791641 },
    { lat: 48.377708, lng: 18.7788682 },
    { lat: 48.37775, lng: 18.7787628 },
    { lat: 48.3776721, lng: 18.7784422 },
    { lat: 48.377739, lng: 18.7782604 },
    { lat: 48.377493, lng: 18.7775302 },
    { lat: 48.377478, lng: 18.7771617 },
    { lat: 48.376848, lng: 18.77612 },
    { lat: 48.3769085, lng: 18.7757708 },
    { lat: 48.3768707, lng: 18.7755256 },
    { lat: 48.3769357, lng: 18.7751997 },
    { lat: 48.3769409, lng: 18.7747195 },
    { lat: 48.37706, lng: 18.7742424 },
    { lat: 48.3769549, lng: 18.7740059 },
    { lat: 48.3765964, lng: 18.7740684 },
    { lat: 48.3760606, lng: 18.7744117 },
    { lat: 48.3756253, lng: 18.7741514 },
    { lat: 48.3752645, lng: 18.7742596 },
    { lat: 48.3750526, lng: 18.7742437 },
    { lat: 48.3748913, lng: 18.7745641 },
    { lat: 48.3743522, lng: 18.774602 },
    { lat: 48.3736422, lng: 18.7743545 },
    { lat: 48.3737063, lng: 18.7740958 },
    { lat: 48.3734324, lng: 18.7739946 },
    { lat: 48.3732991, lng: 18.7740332 },
    { lat: 48.3732549, lng: 18.7741371 },
    { lat: 48.3731806, lng: 18.7741165 },
    { lat: 48.3730255, lng: 18.7738923 },
    { lat: 48.3728059, lng: 18.7740022 },
    { lat: 48.3726071, lng: 18.7735492 },
    { lat: 48.372591, lng: 18.7728828 },
    { lat: 48.3727019, lng: 18.7725713 },
    { lat: 48.3724951, lng: 18.7721771 },
    { lat: 48.3726795, lng: 18.7715042 },
    { lat: 48.3726826, lng: 18.7712673 },
    { lat: 48.3726104, lng: 18.7710503 },
    { lat: 48.3726393, lng: 18.770811 },
    { lat: 48.3725336, lng: 18.7703451 },
    { lat: 48.3724459, lng: 18.7701778 },
    { lat: 48.3721073, lng: 18.769939 },
    { lat: 48.3717274, lng: 18.7694086 },
    { lat: 48.3713497, lng: 18.7692426 },
    { lat: 48.3712353, lng: 18.7693403 },
    { lat: 48.3708527, lng: 18.7692692 },
    { lat: 48.3704243, lng: 18.7687595 },
    { lat: 48.3702022, lng: 18.768698 },
    { lat: 48.369962, lng: 18.7680169 },
    { lat: 48.369819, lng: 18.7677445 },
    { lat: 48.369915, lng: 18.7672993 },
    { lat: 48.369837, lng: 18.7669099 },
    { lat: 48.3698152, lng: 18.7663901 },
    { lat: 48.3695691, lng: 18.7658353 },
    { lat: 48.369577, lng: 18.765619 },
    { lat: 48.3696988, lng: 18.7654289 },
    { lat: 48.3696894, lng: 18.765274 },
    { lat: 48.3693023, lng: 18.7647144 },
    { lat: 48.3693404, lng: 18.7640469 },
    { lat: 48.3692486, lng: 18.7638078 },
    { lat: 48.3685973, lng: 18.7632627 },
    { lat: 48.3684264, lng: 18.762888 },
    { lat: 48.3683936, lng: 18.7626514 },
    { lat: 48.3681323, lng: 18.761913 },
    { lat: 48.3679792, lng: 18.761833 },
    { lat: 48.3676578, lng: 18.7618327 },
    { lat: 48.3670648, lng: 18.7613899 },
    { lat: 48.3667335, lng: 18.7612882 },
    { lat: 48.36646, lng: 18.7612999 },
    { lat: 48.3663257, lng: 18.761405 },
    { lat: 48.3660123, lng: 18.7612579 },
    { lat: 48.3658718, lng: 18.7613304 },
    { lat: 48.3657213, lng: 18.761193 },
    { lat: 48.365513, lng: 18.7608384 },
    { lat: 48.3651705, lng: 18.7605249 },
    { lat: 48.3647876, lng: 18.7597663 },
    { lat: 48.3645747, lng: 18.7596236 },
    { lat: 48.3642574, lng: 18.7590646 },
    { lat: 48.3641469, lng: 18.758937 },
    { lat: 48.3638796, lng: 18.7587852 },
    { lat: 48.3637905, lng: 18.7585968 },
    { lat: 48.3634089, lng: 18.7581384 },
    { lat: 48.3632921, lng: 18.7578931 },
    { lat: 48.3632198, lng: 18.7575088 },
    { lat: 48.362946, lng: 18.7572575 },
    { lat: 48.3626248, lng: 18.757238 },
    { lat: 48.3623803, lng: 18.7568842 },
    { lat: 48.3623517, lng: 18.7565588 },
    { lat: 48.3622144, lng: 18.7563937 },
    { lat: 48.3619601, lng: 18.7562949 },
    { lat: 48.3618009, lng: 18.7561451 },
    { lat: 48.3611583, lng: 18.7561491 },
    { lat: 48.3609794, lng: 18.7559446 },
    { lat: 48.360711, lng: 18.756221 },
    { lat: 48.3603742, lng: 18.7559307 },
    { lat: 48.3600222, lng: 18.7559298 },
    { lat: 48.3597396, lng: 18.7560242 },
    { lat: 48.3594687, lng: 18.7557189 },
    { lat: 48.3592492, lng: 18.7556001 },
    { lat: 48.3592179, lng: 18.7557318 },
    { lat: 48.3583867, lng: 18.7554545 },
    { lat: 48.3582134, lng: 18.7558447 },
    { lat: 48.3576002, lng: 18.7553608 },
    { lat: 48.3574963, lng: 18.7555087 },
    { lat: 48.3572132, lng: 18.7553403 },
    { lat: 48.3568873, lng: 18.7548729 },
    { lat: 48.3566782, lng: 18.7547745 },
    { lat: 48.3566059, lng: 18.7544544 },
    { lat: 48.3563164, lng: 18.7539964 },
    { lat: 48.3561938, lng: 18.7538937 },
    { lat: 48.3556857, lng: 18.7541607 },
    { lat: 48.3555595, lng: 18.7540982 },
    { lat: 48.3554273, lng: 18.7538384 },
    { lat: 48.3550341, lng: 18.7535276 },
    { lat: 48.3547085, lng: 18.7533828 },
    { lat: 48.3546122, lng: 18.7532472 },
    { lat: 48.3533551, lng: 18.7522169 },
    { lat: 48.3531854, lng: 18.75214 },
    { lat: 48.3527562, lng: 18.7523611 },
    { lat: 48.3524197, lng: 18.7522244 },
    { lat: 48.3523167, lng: 18.75207 },
    { lat: 48.3522281, lng: 18.7516778 },
    { lat: 48.352056, lng: 18.7515176 },
    { lat: 48.3518836, lng: 18.7506681 },
    { lat: 48.3516576, lng: 18.7501512 },
    { lat: 48.3515147, lng: 18.7501349 },
    { lat: 48.3513052, lng: 18.7499891 },
    { lat: 48.3512638, lng: 18.7496543 },
    { lat: 48.3513149, lng: 18.7494522 },
    { lat: 48.351291, lng: 18.7490459 },
    { lat: 48.3513662, lng: 18.7486557 },
    { lat: 48.3512188, lng: 18.7484064 },
    { lat: 48.3506065, lng: 18.7482381 },
    { lat: 48.3503817, lng: 18.7480589 },
    { lat: 48.3503896, lng: 18.7480169 },
    { lat: 48.3502027, lng: 18.7478527 },
    { lat: 48.3501103, lng: 18.7476432 },
    { lat: 48.3500578, lng: 18.7464015 },
    { lat: 48.3500653, lng: 18.745249 },
    { lat: 48.3499652, lng: 18.7440637 },
    { lat: 48.3493723, lng: 18.7422668 },
    { lat: 48.3490795, lng: 18.7423561 },
    { lat: 48.3480142, lng: 18.742113 },
    { lat: 48.3467338, lng: 18.7422751 },
    { lat: 48.3460459, lng: 18.7425725 },
    { lat: 48.3458419, lng: 18.7424087 },
    { lat: 48.3456538, lng: 18.7424528 },
    { lat: 48.345335, lng: 18.7426742 },
    { lat: 48.345204, lng: 18.7425215 },
    { lat: 48.3450749, lng: 18.7424671 },
    { lat: 48.3450587, lng: 18.7423329 },
    { lat: 48.3449241, lng: 18.7421165 },
    { lat: 48.3448522, lng: 18.7422662 },
    { lat: 48.3445438, lng: 18.7425342 },
    { lat: 48.3443574, lng: 18.7424019 },
    { lat: 48.3439593, lng: 18.7427626 },
    { lat: 48.3438214, lng: 18.7428188 },
    { lat: 48.343549, lng: 18.7428063 },
    { lat: 48.3432783, lng: 18.7429794 },
    { lat: 48.3431493, lng: 18.7432469 },
    { lat: 48.3428264, lng: 18.7433862 },
    { lat: 48.3426951, lng: 18.7435267 },
    { lat: 48.3424825, lng: 18.7435812 },
    { lat: 48.3422596, lng: 18.7434394 },
    { lat: 48.341803, lng: 18.7435043 },
    { lat: 48.3415468, lng: 18.7434605 },
    { lat: 48.3413194, lng: 18.7432779 },
    { lat: 48.3413389, lng: 18.7430253 },
    { lat: 48.3418112, lng: 18.7427703 },
    { lat: 48.3421671, lng: 18.7423335 },
    { lat: 48.3427375, lng: 18.7419898 },
    { lat: 48.3426272, lng: 18.7415521 },
    { lat: 48.3419999, lng: 18.740927 },
    { lat: 48.3417887, lng: 18.7406056 },
    { lat: 48.3411138, lng: 18.7392421 },
    { lat: 48.3405718, lng: 18.7379571 },
    { lat: 48.3403022, lng: 18.737447 },
    { lat: 48.3403334, lng: 18.7372692 },
    { lat: 48.3389444, lng: 18.7351583 },
    { lat: 48.3387955, lng: 18.7350287 },
    { lat: 48.338743, lng: 18.7350818 },
    { lat: 48.3381344, lng: 18.734814 },
    { lat: 48.3370263, lng: 18.7328536 },
    { lat: 48.3367905, lng: 18.7323044 },
    { lat: 48.3372205, lng: 18.7319076 },
    { lat: 48.3378186, lng: 18.731193 },
    { lat: 48.3377733, lng: 18.7311261 },
    { lat: 48.3380748, lng: 18.7306242 },
    { lat: 48.3378682, lng: 18.7300067 },
    { lat: 48.3380445, lng: 18.7298249 },
    { lat: 48.3379461, lng: 18.7295443 },
    { lat: 48.3376022, lng: 18.7279606 },
    { lat: 48.3373542, lng: 18.7279418 },
    { lat: 48.3373405, lng: 18.727662 },
  ],
  VeľkéLudince: [
    { lat: 47.9802654, lng: 18.5235022 },
    { lat: 47.979991, lng: 18.5220552 },
    { lat: 47.9793718, lng: 18.5182161 },
    { lat: 47.9791805, lng: 18.5157875 },
    { lat: 47.9791681, lng: 18.5155215 },
    { lat: 47.9795229, lng: 18.5132411 },
    { lat: 47.98033, lng: 18.5093925 },
    { lat: 47.9803338, lng: 18.508891 },
    { lat: 47.9803784, lng: 18.5086176 },
    { lat: 47.9810957, lng: 18.5053079 },
    { lat: 47.9810645, lng: 18.5052963 },
    { lat: 47.9816857, lng: 18.5034043 },
    { lat: 47.9820122, lng: 18.5022169 },
    { lat: 47.9822369, lng: 18.5003379 },
    { lat: 47.9823882, lng: 18.4998319 },
    { lat: 47.9825955, lng: 18.4985923 },
    { lat: 47.9828776, lng: 18.4974772 },
    { lat: 47.9834007, lng: 18.4949226 },
    { lat: 47.9838745, lng: 18.4935461 },
    { lat: 47.984523, lng: 18.4925282 },
    { lat: 47.9846611, lng: 18.4923981 },
    { lat: 47.9847919, lng: 18.4925193 },
    { lat: 47.9850425, lng: 18.492277 },
    { lat: 47.9852572, lng: 18.4919392 },
    { lat: 47.9855085, lng: 18.4917314 },
    { lat: 47.985561, lng: 18.4914185 },
    { lat: 47.9855478, lng: 18.4910209 },
    { lat: 47.9854698, lng: 18.4906552 },
    { lat: 47.9851093, lng: 18.4895698 },
    { lat: 47.9847758, lng: 18.4888495 },
    { lat: 47.9844476, lng: 18.4883869 },
    { lat: 47.9842656, lng: 18.4882383 },
    { lat: 47.9840881, lng: 18.4879465 },
    { lat: 47.9839238, lng: 18.4871676 },
    { lat: 47.9837923, lng: 18.48608 },
    { lat: 47.9834513, lng: 18.4843789 },
    { lat: 47.9835963, lng: 18.4836402 },
    { lat: 47.9836345, lng: 18.4830711 },
    { lat: 47.9836178, lng: 18.4823277 },
    { lat: 47.9836789, lng: 18.4815466 },
    { lat: 47.9844098, lng: 18.4803096 },
    { lat: 47.9849714, lng: 18.4796815 },
    { lat: 47.9852336, lng: 18.4791445 },
    { lat: 47.9858464, lng: 18.4783583 },
    { lat: 47.9860794, lng: 18.4777871 },
    { lat: 47.9865931, lng: 18.4770668 },
    { lat: 47.9870672, lng: 18.4767488 },
    { lat: 47.98818, lng: 18.475464 },
    { lat: 47.9891577, lng: 18.4745708 },
    { lat: 47.9897778, lng: 18.4738189 },
    { lat: 47.9912096, lng: 18.4725717 },
    { lat: 47.9919141, lng: 18.4715314 },
    { lat: 47.9939456, lng: 18.468987 },
    { lat: 47.9945366, lng: 18.4681747 },
    { lat: 47.9954252, lng: 18.4671603 },
    { lat: 47.9961289, lng: 18.4661079 },
    { lat: 47.996572, lng: 18.465616 },
    { lat: 47.996556, lng: 18.465588 },
    { lat: 47.996372, lng: 18.465369 },
    { lat: 47.996225, lng: 18.465236 },
    { lat: 47.995951, lng: 18.465065 },
    { lat: 47.995733, lng: 18.46495 },
    { lat: 47.99552, lng: 18.464803 },
    { lat: 47.995311, lng: 18.464623 },
    { lat: 47.995146, lng: 18.464508 },
    { lat: 47.995032, lng: 18.464462 },
    { lat: 47.994949, lng: 18.464444 },
    { lat: 47.99486, lng: 18.464462 },
    { lat: 47.994713, lng: 18.464501 },
    { lat: 47.994444, lng: 18.464461 },
    { lat: 47.994, lng: 18.464481 },
    { lat: 47.993869, lng: 18.464415 },
    { lat: 47.993704, lng: 18.464311 },
    { lat: 47.993522, lng: 18.464284 },
    { lat: 47.993426, lng: 18.464239 },
    { lat: 47.993141, lng: 18.463974 },
    { lat: 47.992995, lng: 18.463909 },
    { lat: 47.992886, lng: 18.463894 },
    { lat: 47.992678, lng: 18.463771 },
    { lat: 47.992452, lng: 18.463543 },
    { lat: 47.992336, lng: 18.463428 },
    { lat: 47.992166, lng: 18.463357 },
    { lat: 47.992016, lng: 18.463381 },
    { lat: 47.991603, lng: 18.46321 },
    { lat: 47.991482, lng: 18.463184 },
    { lat: 47.991138, lng: 18.463172 },
    { lat: 47.990673, lng: 18.462906 },
    { lat: 47.99036, lng: 18.462502 },
    { lat: 47.990126, lng: 18.462296 },
    { lat: 47.989908, lng: 18.461928 },
    { lat: 47.989856, lng: 18.461781 },
    { lat: 47.989752, lng: 18.461257 },
    { lat: 47.989688, lng: 18.460562 },
    { lat: 47.989685, lng: 18.459472 },
    { lat: 47.989706, lng: 18.459034 },
    { lat: 47.989725, lng: 18.458483 },
    { lat: 47.989703, lng: 18.457753 },
    { lat: 47.989672, lng: 18.457714 },
    { lat: 47.989327, lng: 18.457311 },
    { lat: 47.988788, lng: 18.457039 },
    { lat: 47.988186, lng: 18.456901 },
    { lat: 47.987476, lng: 18.456497 },
    { lat: 47.98636, lng: 18.455757 },
    { lat: 47.985785, lng: 18.454527 },
    { lat: 47.984726, lng: 18.452653 },
    { lat: 47.984288, lng: 18.451873 },
    { lat: 47.982694, lng: 18.451673 },
    { lat: 47.981898, lng: 18.451546 },
    { lat: 47.981536, lng: 18.451532 },
    { lat: 47.98128, lng: 18.451541 },
    { lat: 47.980852, lng: 18.451563 },
    { lat: 47.980299, lng: 18.451582 },
    { lat: 47.979213, lng: 18.451419 },
    { lat: 47.978727, lng: 18.451481 },
    { lat: 47.978169, lng: 18.450964 },
    { lat: 47.977898, lng: 18.450605 },
    { lat: 47.977512, lng: 18.450079 },
    { lat: 47.977493, lng: 18.450051 },
    { lat: 47.977319, lng: 18.44982 },
    { lat: 47.977247, lng: 18.449718 },
    { lat: 47.977123, lng: 18.449784 },
    { lat: 47.977003, lng: 18.449853 },
    { lat: 47.976804, lng: 18.449943 },
    { lat: 47.975981, lng: 18.450299 },
    { lat: 47.975919, lng: 18.450319 },
    { lat: 47.975773, lng: 18.450251 },
    { lat: 47.975548, lng: 18.450139 },
    { lat: 47.973897, lng: 18.449978 },
    { lat: 47.973177, lng: 18.450439 },
    { lat: 47.972421, lng: 18.450918 },
    { lat: 47.972134, lng: 18.4513 },
    { lat: 47.971574, lng: 18.452058 },
    { lat: 47.970688, lng: 18.452405 },
    { lat: 47.970228, lng: 18.452578 },
    { lat: 47.969954, lng: 18.452603 },
    { lat: 47.968602, lng: 18.452686 },
    { lat: 47.968449, lng: 18.452693 },
    { lat: 47.967145, lng: 18.452727 },
    { lat: 47.966337, lng: 18.452966 },
    { lat: 47.966287, lng: 18.452978 },
    { lat: 47.965947, lng: 18.45303 },
    { lat: 47.965487, lng: 18.453091 },
    { lat: 47.965066, lng: 18.453168 },
    { lat: 47.964617, lng: 18.453239 },
    { lat: 47.963434, lng: 18.453672 },
    { lat: 47.963079, lng: 18.453898 },
    { lat: 47.96283, lng: 18.454033 },
    { lat: 47.961281, lng: 18.4547 },
    { lat: 47.960586, lng: 18.455113 },
    { lat: 47.960228, lng: 18.45532 },
    { lat: 47.959941, lng: 18.455381 },
    { lat: 47.959412, lng: 18.455479 },
    { lat: 47.959319, lng: 18.455515 },
    { lat: 47.959, lng: 18.455633 },
    { lat: 47.957747, lng: 18.456524 },
    { lat: 47.956746, lng: 18.457624 },
    { lat: 47.956661, lng: 18.457755 },
    { lat: 47.956182, lng: 18.458524 },
    { lat: 47.955531, lng: 18.459163 },
    { lat: 47.955404, lng: 18.459293 },
    { lat: 47.954692, lng: 18.459999 },
    { lat: 47.954598, lng: 18.460079 },
    { lat: 47.954123, lng: 18.460469 },
    { lat: 47.953907, lng: 18.46065 },
    { lat: 47.95378, lng: 18.460752 },
    { lat: 47.953347, lng: 18.461627 },
    { lat: 47.95328, lng: 18.461783 },
    { lat: 47.953082, lng: 18.462268 },
    { lat: 47.952921, lng: 18.462654 },
    { lat: 47.952634, lng: 18.463029 },
    { lat: 47.952549, lng: 18.463078 },
    { lat: 47.952034, lng: 18.463458 },
    { lat: 47.951844, lng: 18.463588 },
    { lat: 47.951344, lng: 18.463883 },
    { lat: 47.95089, lng: 18.464425 },
    { lat: 47.950733, lng: 18.464617 },
    { lat: 47.950424, lng: 18.464926 },
    { lat: 47.9501, lng: 18.465262 },
    { lat: 47.950048, lng: 18.46531 },
    { lat: 47.949687, lng: 18.465543 },
    { lat: 47.949321, lng: 18.465767 },
    { lat: 47.949303, lng: 18.465781 },
    { lat: 47.949116, lng: 18.465903 },
    { lat: 47.9489, lng: 18.466049 },
    { lat: 47.948757, lng: 18.466117 },
    { lat: 47.948596, lng: 18.466204 },
    { lat: 47.948582, lng: 18.466213 },
    { lat: 47.948695, lng: 18.466344 },
    { lat: 47.949329, lng: 18.467028 },
    { lat: 47.949395, lng: 18.467098 },
    { lat: 47.949801, lng: 18.467542 },
    { lat: 47.950047, lng: 18.467814 },
    { lat: 47.950659, lng: 18.468481 },
    { lat: 47.950813, lng: 18.468652 },
    { lat: 47.951066, lng: 18.468926 },
    { lat: 47.951367, lng: 18.46926 },
    { lat: 47.951866, lng: 18.469805 },
    { lat: 47.951832, lng: 18.469864 },
    { lat: 47.951476, lng: 18.470445 },
    { lat: 47.950502, lng: 18.472041 },
    { lat: 47.949904, lng: 18.473016 },
    { lat: 47.949665, lng: 18.473401 },
    { lat: 47.949589, lng: 18.473528 },
    { lat: 47.94936, lng: 18.473736 },
    { lat: 47.949218, lng: 18.47386 },
    { lat: 47.948813, lng: 18.474237 },
    { lat: 47.948223, lng: 18.474776 },
    { lat: 47.94775, lng: 18.475216 },
    { lat: 47.947538, lng: 18.475421 },
    { lat: 47.946984, lng: 18.475952 },
    { lat: 47.946158, lng: 18.476886 },
    { lat: 47.9459, lng: 18.47718 },
    { lat: 47.945848, lng: 18.477233 },
    { lat: 47.945556, lng: 18.478172 },
    { lat: 47.945467, lng: 18.478467 },
    { lat: 47.945336, lng: 18.478678 },
    { lat: 47.944917, lng: 18.479333 },
    { lat: 47.944755, lng: 18.479584 },
    { lat: 47.944666, lng: 18.479728 },
    { lat: 47.944588, lng: 18.479759 },
    { lat: 47.944575, lng: 18.47982 },
    { lat: 47.944549, lng: 18.47989 },
    { lat: 47.944529, lng: 18.479971 },
    { lat: 47.944469, lng: 18.480098 },
    { lat: 47.944428, lng: 18.480172 },
    { lat: 47.944353, lng: 18.480294 },
    { lat: 47.944226, lng: 18.480544 },
    { lat: 47.944096, lng: 18.480767 },
    { lat: 47.944004, lng: 18.480941 },
    { lat: 47.943897, lng: 18.48114 },
    { lat: 47.943655, lng: 18.481571 },
    { lat: 47.943511, lng: 18.481839 },
    { lat: 47.943451, lng: 18.481931 },
    { lat: 47.943155, lng: 18.482499 },
    { lat: 47.943071, lng: 18.48263 },
    { lat: 47.942829, lng: 18.483089 },
    { lat: 47.9427, lng: 18.483327 },
    { lat: 47.942551, lng: 18.483625 },
    { lat: 47.942425, lng: 18.483895 },
    { lat: 47.942298, lng: 18.484148 },
    { lat: 47.942196, lng: 18.484371 },
    { lat: 47.9421, lng: 18.48456 },
    { lat: 47.942124, lng: 18.484668 },
    { lat: 47.942218, lng: 18.48512 },
    { lat: 47.942289, lng: 18.485459 },
    { lat: 47.942485, lng: 18.486393 },
    { lat: 47.942557, lng: 18.486728 },
    { lat: 47.942749, lng: 18.487492 },
    { lat: 47.943108, lng: 18.488942 },
    { lat: 47.943175, lng: 18.489232 },
    { lat: 47.943287, lng: 18.489693 },
    { lat: 47.943385, lng: 18.49107 },
    { lat: 47.94313, lng: 18.491722 },
    { lat: 47.942958, lng: 18.492163 },
    { lat: 47.942932, lng: 18.492262 },
    { lat: 47.942842, lng: 18.492582 },
    { lat: 47.942785, lng: 18.492793 },
    { lat: 47.942738, lng: 18.492954 },
    { lat: 47.942666, lng: 18.493287 },
    { lat: 47.94257, lng: 18.493705 },
    { lat: 47.942492, lng: 18.494064 },
    { lat: 47.942355, lng: 18.495196 },
    { lat: 47.942316, lng: 18.495492 },
    { lat: 47.942256, lng: 18.495969 },
    { lat: 47.942227, lng: 18.496212 },
    { lat: 47.942097, lng: 18.497522 },
    { lat: 47.942054, lng: 18.497955 },
    { lat: 47.941921, lng: 18.499229 },
    { lat: 47.941911, lng: 18.499291 },
    { lat: 47.941903, lng: 18.499389 },
    { lat: 47.941778, lng: 18.50038 },
    { lat: 47.941626, lng: 18.501549 },
    { lat: 47.941595, lng: 18.501816 },
    { lat: 47.941586, lng: 18.501843 },
    { lat: 47.941572, lng: 18.501927 },
    { lat: 47.941566, lng: 18.501957 },
    { lat: 47.941515, lng: 18.502219 },
    { lat: 47.941306, lng: 18.503335 },
    { lat: 47.94115, lng: 18.504135 },
    { lat: 47.941116, lng: 18.504318 },
    { lat: 47.940728, lng: 18.505449 },
    { lat: 47.940256, lng: 18.506807 },
    { lat: 47.940061, lng: 18.507834 },
    { lat: 47.939798, lng: 18.509164 },
    { lat: 47.93975, lng: 18.509409 },
    { lat: 47.93941, lng: 18.510866 },
    { lat: 47.939355, lng: 18.511084 },
    { lat: 47.939307, lng: 18.511297 },
    { lat: 47.939229, lng: 18.511622 },
    { lat: 47.939206, lng: 18.511726 },
    { lat: 47.939152, lng: 18.51194 },
    { lat: 47.939107, lng: 18.512143 },
    { lat: 47.939084, lng: 18.512237 },
    { lat: 47.938897, lng: 18.512794 },
    { lat: 47.938811, lng: 18.513045 },
    { lat: 47.938352, lng: 18.514375 },
    { lat: 47.938238, lng: 18.514673 },
    { lat: 47.938197, lng: 18.514805 },
    { lat: 47.937974, lng: 18.516062 },
    { lat: 47.937822, lng: 18.516938 },
    { lat: 47.937816, lng: 18.516997 },
    { lat: 47.937755, lng: 18.517233 },
    { lat: 47.937581, lng: 18.517937 },
    { lat: 47.937428, lng: 18.518545 },
    { lat: 47.937363, lng: 18.518822 },
    { lat: 47.937215, lng: 18.519422 },
    { lat: 47.937152, lng: 18.519667 },
    { lat: 47.936997, lng: 18.520247 },
    { lat: 47.936924, lng: 18.52053 },
    { lat: 47.936846, lng: 18.520815 },
    { lat: 47.936662, lng: 18.521517 },
    { lat: 47.936474, lng: 18.522248 },
    { lat: 47.936391, lng: 18.522553 },
    { lat: 47.936331, lng: 18.52285 },
    { lat: 47.936213, lng: 18.523461 },
    { lat: 47.93609, lng: 18.52408 },
    { lat: 47.936025, lng: 18.524421 },
    { lat: 47.935962, lng: 18.524755 },
    { lat: 47.935906, lng: 18.525102 },
    { lat: 47.935819, lng: 18.525528 },
    { lat: 47.935784, lng: 18.5257 },
    { lat: 47.935674, lng: 18.526112 },
    { lat: 47.935587, lng: 18.526445 },
    { lat: 47.935448, lng: 18.526947 },
    { lat: 47.9354, lng: 18.52713 },
    { lat: 47.935114, lng: 18.528172 },
    { lat: 47.934931, lng: 18.528774 },
    { lat: 47.934526, lng: 18.530073 },
    { lat: 47.934178, lng: 18.531183 },
    { lat: 47.934116, lng: 18.53141 },
    { lat: 47.933982, lng: 18.531917 },
    { lat: 47.933734, lng: 18.532839 },
    { lat: 47.933479, lng: 18.533766 },
    { lat: 47.933445, lng: 18.533882 },
    { lat: 47.933356, lng: 18.534227 },
    { lat: 47.933222, lng: 18.534759 },
    { lat: 47.933105, lng: 18.535208 },
    { lat: 47.933079, lng: 18.535302 },
    { lat: 47.932734, lng: 18.536126 },
    { lat: 47.932068, lng: 18.537691 },
    { lat: 47.932027, lng: 18.537795 },
    { lat: 47.932017, lng: 18.537815 },
    { lat: 47.932089, lng: 18.537886 },
    { lat: 47.933179, lng: 18.538835 },
    { lat: 47.933266, lng: 18.538913 },
    { lat: 47.933633, lng: 18.539232 },
    { lat: 47.933776, lng: 18.539359 },
    { lat: 47.933946, lng: 18.539518 },
    { lat: 47.935189, lng: 18.540645 },
    { lat: 47.935364, lng: 18.540806 },
    { lat: 47.935588, lng: 18.541003 },
    { lat: 47.93604, lng: 18.541419 },
    { lat: 47.936315, lng: 18.54165 },
    { lat: 47.936427, lng: 18.541751 },
    { lat: 47.936674, lng: 18.541962 },
    { lat: 47.937002, lng: 18.542262 },
    { lat: 47.937087, lng: 18.542334 },
    { lat: 47.937592, lng: 18.542784 },
    { lat: 47.937909, lng: 18.543062 },
    { lat: 47.93828, lng: 18.543396 },
    { lat: 47.939009, lng: 18.544043 },
    { lat: 47.939706, lng: 18.544671 },
    { lat: 47.939793, lng: 18.544806 },
    { lat: 47.940211, lng: 18.54545 },
    { lat: 47.940639, lng: 18.546095 },
    { lat: 47.940872, lng: 18.546453 },
    { lat: 47.941222, lng: 18.546981 },
    { lat: 47.941677, lng: 18.547671 },
    { lat: 47.941726, lng: 18.54775 },
    { lat: 47.94181, lng: 18.547876 },
    { lat: 47.942388, lng: 18.548763 },
    { lat: 47.942536, lng: 18.548992 },
    { lat: 47.9428676, lng: 18.549497 },
    { lat: 47.943161, lng: 18.549944 },
    { lat: 47.943375, lng: 18.550267 },
    { lat: 47.94434, lng: 18.551753 },
    { lat: 47.944856, lng: 18.552528 },
    { lat: 47.945065, lng: 18.552848 },
    { lat: 47.945282, lng: 18.553176 },
    { lat: 47.945297, lng: 18.553198 },
    { lat: 47.945363, lng: 18.553299 },
    { lat: 47.945376, lng: 18.553316 },
    { lat: 47.945478, lng: 18.553498 },
    { lat: 47.946121, lng: 18.554683 },
    { lat: 47.946659, lng: 18.55566 },
    { lat: 47.946688, lng: 18.555714 },
    { lat: 47.9490731, lng: 18.5539706 },
    { lat: 47.9518809, lng: 18.5517263 },
    { lat: 47.953115, lng: 18.5503014 },
    { lat: 47.9550801, lng: 18.5472408 },
    { lat: 47.9591375, lng: 18.5509118 },
    { lat: 47.9630997, lng: 18.5545992 },
    { lat: 47.963249, lng: 18.554178 },
    { lat: 47.9644292, lng: 18.5508555 },
    { lat: 47.9649495, lng: 18.5498172 },
    { lat: 47.966303, lng: 18.5474485 },
    { lat: 47.9676197, lng: 18.5448417 },
    { lat: 47.9688805, lng: 18.5425319 },
    { lat: 47.9702891, lng: 18.5397935 },
    { lat: 47.9710853, lng: 18.5385279 },
    { lat: 47.9716612, lng: 18.5373749 },
    { lat: 47.9722891, lng: 18.5359211 },
    { lat: 47.9728126, lng: 18.5345371 },
    { lat: 47.9736731, lng: 18.5318449 },
    { lat: 47.9741242, lng: 18.5309087 },
    { lat: 47.9752935, lng: 18.5288049 },
    { lat: 47.9758949, lng: 18.5278889 },
    { lat: 47.9766933, lng: 18.5272765 },
    { lat: 47.9795273, lng: 18.5245368 },
    { lat: 47.980233, lng: 18.5234692 },
    { lat: 47.9802654, lng: 18.5235022 },
  ],
  DolnéSemerovce: [
    { lat: 48.130648, lng: 18.8204528 },
    { lat: 48.1306478, lng: 18.8204486 },
    { lat: 48.1306423, lng: 18.8204079 },
    { lat: 48.128735, lng: 18.8208503 },
    { lat: 48.1276946, lng: 18.8205592 },
    { lat: 48.1267815, lng: 18.8201827 },
    { lat: 48.1259061, lng: 18.8201991 },
    { lat: 48.1250428, lng: 18.8197133 },
    { lat: 48.124247, lng: 18.8197203 },
    { lat: 48.1238309, lng: 18.8198286 },
    { lat: 48.1230754, lng: 18.8203042 },
    { lat: 48.1227529, lng: 18.8204307 },
    { lat: 48.122349, lng: 18.8204919 },
    { lat: 48.1208825, lng: 18.8210578 },
    { lat: 48.1205263, lng: 18.8212988 },
    { lat: 48.1195653, lng: 18.8220854 },
    { lat: 48.1189546, lng: 18.8228867 },
    { lat: 48.1166068, lng: 18.8255011 },
    { lat: 48.1155733, lng: 18.826029 },
    { lat: 48.113177, lng: 18.8262645 },
    { lat: 48.1103475, lng: 18.8269478 },
    { lat: 48.1092469, lng: 18.8272689 },
    { lat: 48.1084189, lng: 18.8276505 },
    { lat: 48.1076493, lng: 18.8277677 },
    { lat: 48.1058686, lng: 18.8272103 },
    { lat: 48.1051309, lng: 18.8261386 },
    { lat: 48.1040653, lng: 18.823781 },
    { lat: 48.1040544, lng: 18.8237889 },
    { lat: 48.1030722, lng: 18.8244744 },
    { lat: 48.1025639, lng: 18.8246752 },
    { lat: 48.1021845, lng: 18.8249032 },
    { lat: 48.1013712, lng: 18.8250484 },
    { lat: 48.1008159, lng: 18.8252953 },
    { lat: 48.100461, lng: 18.8250664 },
    { lat: 48.0996563, lng: 18.8249037 },
    { lat: 48.0987557, lng: 18.8251689 },
    { lat: 48.0992762, lng: 18.8261629 },
    { lat: 48.1004713, lng: 18.8274437 },
    { lat: 48.100913, lng: 18.8284224 },
    { lat: 48.1011036, lng: 18.8285725 },
    { lat: 48.1017956, lng: 18.8298083 },
    { lat: 48.1016854, lng: 18.8303695 },
    { lat: 48.1023103, lng: 18.8310801 },
    { lat: 48.1009926, lng: 18.832486 },
    { lat: 48.100915, lng: 18.8339263 },
    { lat: 48.1007376, lng: 18.8346058 },
    { lat: 48.1016586, lng: 18.8354341 },
    { lat: 48.1017126, lng: 18.8362505 },
    { lat: 48.1015526, lng: 18.8375813 },
    { lat: 48.101749, lng: 18.8387006 },
    { lat: 48.101731, lng: 18.8403728 },
    { lat: 48.1017919, lng: 18.8429312 },
    { lat: 48.1020366, lng: 18.8443921 },
    { lat: 48.1017909, lng: 18.8450364 },
    { lat: 48.1020915, lng: 18.8477555 },
    { lat: 48.1017232, lng: 18.8514393 },
    { lat: 48.1007568, lng: 18.8534633 },
    { lat: 48.099608, lng: 18.8545874 },
    { lat: 48.1002319, lng: 18.857584 },
    { lat: 48.1000273, lng: 18.858825 },
    { lat: 48.0997665, lng: 18.859525 },
    { lat: 48.099327, lng: 18.8615564 },
    { lat: 48.0987753, lng: 18.8624558 },
    { lat: 48.0981226, lng: 18.8629559 },
    { lat: 48.0971452, lng: 18.8635871 },
    { lat: 48.0954, lng: 18.8675586 },
    { lat: 48.0947669, lng: 18.8691269 },
    { lat: 48.0944365, lng: 18.8696222 },
    { lat: 48.0940992, lng: 18.8705017 },
    { lat: 48.09348, lng: 18.8718599 },
    { lat: 48.0931306, lng: 18.8717362 },
    { lat: 48.0912124, lng: 18.8736088 },
    { lat: 48.09084, lng: 18.8741657 },
    { lat: 48.0901607, lng: 18.875365 },
    { lat: 48.0899899, lng: 18.8759178 },
    { lat: 48.0896525, lng: 18.87952 },
    { lat: 48.0894697, lng: 18.8842275 },
    { lat: 48.0894627, lng: 18.8850437 },
    { lat: 48.0897524, lng: 18.885021 },
    { lat: 48.0898703, lng: 18.8846857 },
    { lat: 48.0899303, lng: 18.8846265 },
    { lat: 48.0900242, lng: 18.8846561 },
    { lat: 48.0901204, lng: 18.8847688 },
    { lat: 48.090321, lng: 18.885417 },
    { lat: 48.0905155, lng: 18.8854261 },
    { lat: 48.0905281, lng: 18.8853418 },
    { lat: 48.0903924, lng: 18.8850962 },
    { lat: 48.0903967, lng: 18.8847021 },
    { lat: 48.0904389, lng: 18.8845825 },
    { lat: 48.0905274, lng: 18.8845091 },
    { lat: 48.0906605, lng: 18.8845494 },
    { lat: 48.0907995, lng: 18.8848602 },
    { lat: 48.0910718, lng: 18.8851502 },
    { lat: 48.0912227, lng: 18.8851905 },
    { lat: 48.0913552, lng: 18.885093 },
    { lat: 48.0914163, lng: 18.8849007 },
    { lat: 48.0911266, lng: 18.8839552 },
    { lat: 48.0908813, lng: 18.8839185 },
    { lat: 48.0908082, lng: 18.8836843 },
    { lat: 48.0908157, lng: 18.8833526 },
    { lat: 48.0907624, lng: 18.88298 },
    { lat: 48.0908052, lng: 18.8829165 },
    { lat: 48.0909305, lng: 18.8829407 },
    { lat: 48.0910589, lng: 18.8832189 },
    { lat: 48.0913441, lng: 18.8832362 },
    { lat: 48.091421, lng: 18.883307 },
    { lat: 48.0915127, lng: 18.8840504 },
    { lat: 48.0915709, lng: 18.8841286 },
    { lat: 48.0916344, lng: 18.8841138 },
    { lat: 48.0919709, lng: 18.8837107 },
    { lat: 48.0921033, lng: 18.8832865 },
    { lat: 48.0921426, lng: 18.8830245 },
    { lat: 48.0920407, lng: 18.8828882 },
    { lat: 48.0919863, lng: 18.8826021 },
    { lat: 48.0919625, lng: 18.881928 },
    { lat: 48.0920534, lng: 18.8817447 },
    { lat: 48.0921533, lng: 18.8816713 },
    { lat: 48.0922457, lng: 18.8818034 },
    { lat: 48.0922829, lng: 18.8821271 },
    { lat: 48.0924555, lng: 18.8827081 },
    { lat: 48.0925222, lng: 18.8827374 },
    { lat: 48.0926642, lng: 18.8823879 },
    { lat: 48.0926198, lng: 18.8821437 },
    { lat: 48.0927738, lng: 18.8818521 },
    { lat: 48.0929398, lng: 18.8816377 },
    { lat: 48.0931347, lng: 18.881614 },
    { lat: 48.0931767, lng: 18.8818793 },
    { lat: 48.0930569, lng: 18.8822195 },
    { lat: 48.0930571, lng: 18.8825041 },
    { lat: 48.0932746, lng: 18.8830005 },
    { lat: 48.0933358, lng: 18.8830043 },
    { lat: 48.0934419, lng: 18.8828999 },
    { lat: 48.0934861, lng: 18.8827523 },
    { lat: 48.0934354, lng: 18.8824732 },
    { lat: 48.093552, lng: 18.8821358 },
    { lat: 48.0935704, lng: 18.8819044 },
    { lat: 48.0936197, lng: 18.8818105 },
    { lat: 48.0938175, lng: 18.8817124 },
    { lat: 48.0939211, lng: 18.8817553 },
    { lat: 48.0940188, lng: 18.8818964 },
    { lat: 48.0940324, lng: 18.8820034 },
    { lat: 48.0938416, lng: 18.8826496 },
    { lat: 48.0940347, lng: 18.8831534 },
    { lat: 48.0942162, lng: 18.8833263 },
    { lat: 48.0942844, lng: 18.8832923 },
    { lat: 48.0943165, lng: 18.8831051 },
    { lat: 48.0942033, lng: 18.8828389 },
    { lat: 48.094298, lng: 18.8825785 },
    { lat: 48.0943839, lng: 18.8824955 },
    { lat: 48.0944566, lng: 18.8825298 },
    { lat: 48.0945916, lng: 18.8830622 },
    { lat: 48.0946712, lng: 18.8831828 },
    { lat: 48.0948529, lng: 18.8832735 },
    { lat: 48.0948958, lng: 18.8833803 },
    { lat: 48.0949345, lng: 18.8838858 },
    { lat: 48.0949905, lng: 18.8840723 },
    { lat: 48.0951159, lng: 18.8842288 },
    { lat: 48.0953079, lng: 18.8842541 },
    { lat: 48.0953164, lng: 18.884051 },
    { lat: 48.0952175, lng: 18.8838215 },
    { lat: 48.0954757, lng: 18.8833213 },
    { lat: 48.0954902, lng: 18.883169 },
    { lat: 48.0953801, lng: 18.8829395 },
    { lat: 48.0952543, lng: 18.8829693 },
    { lat: 48.0951545, lng: 18.8827865 },
    { lat: 48.0951482, lng: 18.8822991 },
    { lat: 48.0952146, lng: 18.8821859 },
    { lat: 48.0954397, lng: 18.8819848 },
    { lat: 48.0956895, lng: 18.8819088 },
    { lat: 48.0959345, lng: 18.8816539 },
    { lat: 48.0961928, lng: 18.8820655 },
    { lat: 48.0963167, lng: 18.882171 },
    { lat: 48.0964461, lng: 18.8819528 },
    { lat: 48.0967121, lng: 18.880955 },
    { lat: 48.0974254, lng: 18.8809225 },
    { lat: 48.0976473, lng: 18.880769 },
    { lat: 48.0982635, lng: 18.881297 },
    { lat: 48.0983536, lng: 18.881162 },
    { lat: 48.0987442, lng: 18.8809137 },
    { lat: 48.0987875, lng: 18.8808152 },
    { lat: 48.098872, lng: 18.8799027 },
    { lat: 48.0989712, lng: 18.8798622 },
    { lat: 48.0992462, lng: 18.8804634 },
    { lat: 48.0993471, lng: 18.8805624 },
    { lat: 48.0994568, lng: 18.8805236 },
    { lat: 48.0997145, lng: 18.8802541 },
    { lat: 48.0996518, lng: 18.8794818 },
    { lat: 48.0998042, lng: 18.8792686 },
    { lat: 48.0999821, lng: 18.8785544 },
    { lat: 48.1001196, lng: 18.8784223 },
    { lat: 48.1003221, lng: 18.8784989 },
    { lat: 48.1005391, lng: 18.8790483 },
    { lat: 48.1006183, lng: 18.8790991 },
    { lat: 48.1008982, lng: 18.8789283 },
    { lat: 48.1015048, lng: 18.8791424 },
    { lat: 48.1016469, lng: 18.8788228 },
    { lat: 48.1019829, lng: 18.8787131 },
    { lat: 48.1021329, lng: 18.8790566 },
    { lat: 48.102127, lng: 18.8794189 },
    { lat: 48.102032, lng: 18.8796532 },
    { lat: 48.1020603, lng: 18.8803234 },
    { lat: 48.1021585, lng: 18.8804577 },
    { lat: 48.1023704, lng: 18.8803872 },
    { lat: 48.102386, lng: 18.8804055 },
    { lat: 48.1024587, lng: 18.8802241 },
    { lat: 48.1026765, lng: 18.8800722 },
    { lat: 48.103493, lng: 18.880337 },
    { lat: 48.1038855, lng: 18.8800123 },
    { lat: 48.1042155, lng: 18.8804403 },
    { lat: 48.1042389, lng: 18.8806715 },
    { lat: 48.1043857, lng: 18.8809751 },
    { lat: 48.1048316, lng: 18.881396 },
    { lat: 48.1055624, lng: 18.8815036 },
    { lat: 48.1056576, lng: 18.8814308 },
    { lat: 48.1057597, lng: 18.8812238 },
    { lat: 48.1057778, lng: 18.880967 },
    { lat: 48.1056272, lng: 18.880739 },
    { lat: 48.1052976, lng: 18.8804961 },
    { lat: 48.105216, lng: 18.8803761 },
    { lat: 48.1051988, lng: 18.880242 },
    { lat: 48.1052355, lng: 18.8801477 },
    { lat: 48.1056475, lng: 18.8799823 },
    { lat: 48.1057622, lng: 18.8797806 },
    { lat: 48.1057478, lng: 18.879614 },
    { lat: 48.1055224, lng: 18.8795957 },
    { lat: 48.1052795, lng: 18.8794797 },
    { lat: 48.1051466, lng: 18.8792411 },
    { lat: 48.1051704, lng: 18.8788323 },
    { lat: 48.1052727, lng: 18.8786338 },
    { lat: 48.1054591, lng: 18.8785337 },
    { lat: 48.1056564, lng: 18.8787507 },
    { lat: 48.1058458, lng: 18.8787717 },
    { lat: 48.1059108, lng: 18.8785038 },
    { lat: 48.1060302, lng: 18.8782609 },
    { lat: 48.1061709, lng: 18.8782128 },
    { lat: 48.106299, lng: 18.8783577 },
    { lat: 48.1065761, lng: 18.8790177 },
    { lat: 48.1067958, lng: 18.879275 },
    { lat: 48.1069797, lng: 18.8792752 },
    { lat: 48.1070722, lng: 18.8790196 },
    { lat: 48.1071603, lng: 18.8785041 },
    { lat: 48.1073121, lng: 18.8783866 },
    { lat: 48.1075098, lng: 18.8784592 },
    { lat: 48.1076017, lng: 18.8786842 },
    { lat: 48.1076544, lng: 18.8791561 },
    { lat: 48.1076645, lng: 18.8798641 },
    { lat: 48.1077112, lng: 18.8799864 },
    { lat: 48.1078757, lng: 18.8801126 },
    { lat: 48.1081907, lng: 18.8797958 },
    { lat: 48.1081987, lng: 18.8796873 },
    { lat: 48.1080521, lng: 18.8793406 },
    { lat: 48.1080885, lng: 18.8786585 },
    { lat: 48.1081611, lng: 18.8785774 },
    { lat: 48.1083259, lng: 18.8786016 },
    { lat: 48.1085404, lng: 18.8785008 },
    { lat: 48.1087234, lng: 18.8782265 },
    { lat: 48.1088232, lng: 18.8782149 },
    { lat: 48.1090388, lng: 18.8783246 },
    { lat: 48.1091324, lng: 18.8784792 },
    { lat: 48.1091105, lng: 18.879487 },
    { lat: 48.1091772, lng: 18.8796824 },
    { lat: 48.109348, lng: 18.8798213 },
    { lat: 48.1096483, lng: 18.8799173 },
    { lat: 48.1099864, lng: 18.8796931 },
    { lat: 48.1101254, lng: 18.8797228 },
    { lat: 48.1103998, lng: 18.8803792 },
    { lat: 48.1105905, lng: 18.8805349 },
    { lat: 48.1111956, lng: 18.8804835 },
    { lat: 48.1117247, lng: 18.880139 },
    { lat: 48.1117776, lng: 18.8801618 },
    { lat: 48.1121085, lng: 18.8794977 },
    { lat: 48.113519, lng: 18.8766641 },
    { lat: 48.1137121, lng: 18.8765831 },
    { lat: 48.1149802, lng: 18.8732518 },
    { lat: 48.1152705, lng: 18.8723718 },
    { lat: 48.1158335, lng: 18.8696607 },
    { lat: 48.1160558, lng: 18.8690353 },
    { lat: 48.1167694, lng: 18.8677826 },
    { lat: 48.1195426, lng: 18.863392 },
    { lat: 48.1203479, lng: 18.8617971 },
    { lat: 48.1210766, lng: 18.8601207 },
    { lat: 48.1210969, lng: 18.8600629 },
    { lat: 48.1214077, lng: 18.8593927 },
    { lat: 48.1215514, lng: 18.8590242 },
    { lat: 48.1217131, lng: 18.8586131 },
    { lat: 48.1223007, lng: 18.8557513 },
    { lat: 48.1224629, lng: 18.8546039 },
    { lat: 48.1225375, lng: 18.8535278 },
    { lat: 48.1225056, lng: 18.851236 },
    { lat: 48.1224586, lng: 18.8500708 },
    { lat: 48.1224197, lng: 18.8489064 },
    { lat: 48.1272567, lng: 18.8457796 },
    { lat: 48.1293039, lng: 18.8464104 },
    { lat: 48.1300462, lng: 18.8464343 },
    { lat: 48.1316491, lng: 18.8336245 },
    { lat: 48.1314564, lng: 18.827948 },
    { lat: 48.130648, lng: 18.8204528 },
  ],
  Bielovce: [
    { lat: 47.9857598, lng: 18.7355056 },
    { lat: 47.9856868, lng: 18.7357219 },
    { lat: 47.9849198, lng: 18.7379534 },
    { lat: 47.9839604, lng: 18.7374116 },
    { lat: 47.9835471, lng: 18.7373382 },
    { lat: 47.9827628, lng: 18.7376652 },
    { lat: 47.9820521, lng: 18.7382519 },
    { lat: 47.9807718, lng: 18.739617 },
    { lat: 47.9803559, lng: 18.7404215 },
    { lat: 47.9799, lng: 18.7409406 },
    { lat: 47.9790375, lng: 18.7430528 },
    { lat: 47.9787592, lng: 18.7438595 },
    { lat: 47.9781759, lng: 18.7450153 },
    { lat: 47.9774492, lng: 18.7457263 },
    { lat: 47.977281, lng: 18.7461658 },
    { lat: 47.9766517, lng: 18.7490561 },
    { lat: 47.9759526, lng: 18.7516499 },
    { lat: 47.9754722, lng: 18.7537601 },
    { lat: 47.9751586, lng: 18.7545825 },
    { lat: 47.9750268, lng: 18.7545443 },
    { lat: 47.975036, lng: 18.754754 },
    { lat: 47.975553, lng: 18.754881 },
    { lat: 47.976347, lng: 18.75525 },
    { lat: 47.977068, lng: 18.755729 },
    { lat: 47.977249, lng: 18.756004 },
    { lat: 47.977846, lng: 18.756488 },
    { lat: 47.978298, lng: 18.757109 },
    { lat: 47.979017, lng: 18.758294 },
    { lat: 47.979438, lng: 18.759061 },
    { lat: 47.9809, lng: 18.76218 },
    { lat: 47.981324, lng: 18.762891 },
    { lat: 47.982431, lng: 18.764296 },
    { lat: 47.98381, lng: 18.765758 },
    { lat: 47.984106, lng: 18.766189 },
    { lat: 47.984489, lng: 18.766865 },
    { lat: 47.984878, lng: 18.767751 },
    { lat: 47.985184, lng: 18.768791 },
    { lat: 47.985327, lng: 18.769582 },
    { lat: 47.98545, lng: 18.770871 },
    { lat: 47.985381, lng: 18.772558 },
    { lat: 47.985305, lng: 18.772982 },
    { lat: 47.985173, lng: 18.773274 },
    { lat: 47.984998, lng: 18.773415 },
    { lat: 47.984599, lng: 18.773563 },
    { lat: 47.984284, lng: 18.773852 },
    { lat: 47.984182, lng: 18.77442 },
    { lat: 47.984208, lng: 18.775277 },
    { lat: 47.984334, lng: 18.775823 },
    { lat: 47.984408, lng: 18.775967 },
    { lat: 47.984447, lng: 18.776273 },
    { lat: 47.984423, lng: 18.776541 },
    { lat: 47.984216, lng: 18.777031 },
    { lat: 47.984199, lng: 18.777252 },
    { lat: 47.984381, lng: 18.777987 },
    { lat: 47.98517, lng: 18.779593 },
    { lat: 47.985304, lng: 18.780236 },
    { lat: 47.985625, lng: 18.781191 },
    { lat: 47.985744, lng: 18.781672 },
    { lat: 47.985716, lng: 18.782015 },
    { lat: 47.985786, lng: 18.782379 },
    { lat: 47.986338, lng: 18.784065 },
    { lat: 47.986611, lng: 18.785113 },
    { lat: 47.986749, lng: 18.786003 },
    { lat: 47.98687, lng: 18.787494 },
    { lat: 47.986813, lng: 18.788596 },
    { lat: 47.986537, lng: 18.790013 },
    { lat: 47.986366, lng: 18.790791 },
    { lat: 47.985796, lng: 18.792889 },
    { lat: 47.985132, lng: 18.796124 },
    { lat: 47.984984, lng: 18.797405 },
    { lat: 47.985029, lng: 18.798916 },
    { lat: 47.985341, lng: 18.80076 },
    { lat: 47.985594, lng: 18.801561 },
    { lat: 47.986195, lng: 18.803155 },
    { lat: 47.9881647, lng: 18.8075878 },
    { lat: 47.9900377, lng: 18.8059497 },
    { lat: 47.990335, lng: 18.8032583 },
    { lat: 47.9909295, lng: 18.8002837 },
    { lat: 47.9910104, lng: 18.7976402 },
    { lat: 47.9921468, lng: 18.7960177 },
    { lat: 47.9925679, lng: 18.795239 },
    { lat: 47.993993, lng: 18.7931518 },
    { lat: 47.9941441, lng: 18.7926816 },
    { lat: 47.9951279, lng: 18.7908824 },
    { lat: 47.9967281, lng: 18.7883972 },
    { lat: 47.9970107, lng: 18.7865408 },
    { lat: 47.9971239, lng: 18.785486 },
    { lat: 47.9973563, lng: 18.7842382 },
    { lat: 47.9979704, lng: 18.7847168 },
    { lat: 48.0001489, lng: 18.782144 },
    { lat: 48.0008681, lng: 18.7813722 },
    { lat: 48.0014241, lng: 18.7806111 },
    { lat: 48.002884, lng: 18.7777563 },
    { lat: 48.0033156, lng: 18.7762873 },
    { lat: 48.0037567, lng: 18.7753175 },
    { lat: 48.004465, lng: 18.7745323 },
    { lat: 48.0054808, lng: 18.7738277 },
    { lat: 48.0061304, lng: 18.7737865 },
    { lat: 48.0064452, lng: 18.7736634 },
    { lat: 48.006963, lng: 18.7731127 },
    { lat: 48.0075019, lng: 18.7723461 },
    { lat: 48.0077559, lng: 18.7718445 },
    { lat: 48.0087382, lng: 18.770724 },
    { lat: 48.0092, lng: 18.7705426 },
    { lat: 48.0095531, lng: 18.7699313 },
    { lat: 48.0103994, lng: 18.7674811 },
    { lat: 48.0107258, lng: 18.7659263 },
    { lat: 48.0109297, lng: 18.7640574 },
    { lat: 48.0127783, lng: 18.7605933 },
    { lat: 48.0134711, lng: 18.7591429 },
    { lat: 48.0136299, lng: 18.7581238 },
    { lat: 48.0131441, lng: 18.7567349 },
    { lat: 48.0126372, lng: 18.7560049 },
    { lat: 48.0116285, lng: 18.75479 },
    { lat: 48.0108878, lng: 18.7532525 },
    { lat: 48.0104547, lng: 18.7521747 },
    { lat: 48.0106466, lng: 18.751082 },
    { lat: 48.0112274, lng: 18.7492891 },
    { lat: 48.0120385, lng: 18.7467816 },
    { lat: 48.0119805, lng: 18.7467269 },
    { lat: 48.0114305, lng: 18.7462096 },
    { lat: 48.0099167, lng: 18.7430819 },
    { lat: 48.0079509, lng: 18.7417514 },
    { lat: 48.0061503, lng: 18.7411723 },
    { lat: 48.0012058, lng: 18.7429791 },
    { lat: 47.9957585, lng: 18.7444722 },
    { lat: 47.9944242, lng: 18.7416692 },
    { lat: 47.9943551, lng: 18.7402493 },
    { lat: 47.9941461, lng: 18.7398643 },
    { lat: 47.9939409, lng: 18.7396771 },
    { lat: 47.9895562, lng: 18.7373232 },
    { lat: 47.9896455, lng: 18.7369658 },
    { lat: 47.986523, lng: 18.7353317 },
    { lat: 47.9862558, lng: 18.7352702 },
    { lat: 47.9857598, lng: 18.7355056 },
  ],
  Jabloňovce: [
    { lat: 48.3274829, lng: 18.7336425 },
    { lat: 48.3273953, lng: 18.733417 },
    { lat: 48.3274853, lng: 18.7331369 },
    { lat: 48.3271633, lng: 18.7329121 },
    { lat: 48.3268258, lng: 18.7323348 },
    { lat: 48.3267232, lng: 18.7320593 },
    { lat: 48.3266632, lng: 18.7317189 },
    { lat: 48.3267098, lng: 18.7317069 },
    { lat: 48.3266845, lng: 18.7316539 },
    { lat: 48.3239653, lng: 18.7335343 },
    { lat: 48.3228364, lng: 18.7339841 },
    { lat: 48.3199872, lng: 18.7361363 },
    { lat: 48.3194763, lng: 18.7364122 },
    { lat: 48.3145018, lng: 18.7285186 },
    { lat: 48.3117785, lng: 18.7242537 },
    { lat: 48.3117523, lng: 18.7242951 },
    { lat: 48.3116137, lng: 18.7245062 },
    { lat: 48.3103148, lng: 18.726331 },
    { lat: 48.3096405, lng: 18.7274332 },
    { lat: 48.3102556, lng: 18.728158 },
    { lat: 48.3111373, lng: 18.7294029 },
    { lat: 48.3109924, lng: 18.7295616 },
    { lat: 48.3108943, lng: 18.7297962 },
    { lat: 48.3104894, lng: 18.7301554 },
    { lat: 48.310317, lng: 18.7305168 },
    { lat: 48.3098712, lng: 18.7309352 },
    { lat: 48.3097365, lng: 18.7312237 },
    { lat: 48.3096995, lng: 18.7315009 },
    { lat: 48.3096421, lng: 18.7316077 },
    { lat: 48.3095608, lng: 18.7315636 },
    { lat: 48.3095088, lng: 18.7316038 },
    { lat: 48.3093582, lng: 18.7318277 },
    { lat: 48.3091763, lng: 18.732478 },
    { lat: 48.3091083, lng: 18.7325984 },
    { lat: 48.30896, lng: 18.7326502 },
    { lat: 48.3088868, lng: 18.7330986 },
    { lat: 48.3087036, lng: 18.7333855 },
    { lat: 48.3085143, lng: 18.7338514 },
    { lat: 48.3083888, lng: 18.7339929 },
    { lat: 48.3084115, lng: 18.7341279 },
    { lat: 48.308236, lng: 18.7343679 },
    { lat: 48.3089558, lng: 18.7352198 },
    { lat: 48.3085557, lng: 18.7358809 },
    { lat: 48.3077616, lng: 18.7368294 },
    { lat: 48.3081416, lng: 18.7375923 },
    { lat: 48.3048106, lng: 18.7410358 },
    { lat: 48.3050257, lng: 18.7417278 },
    { lat: 48.3053038, lng: 18.7438189 },
    { lat: 48.3063009, lng: 18.7433317 },
    { lat: 48.3067356, lng: 18.7432985 },
    { lat: 48.306888, lng: 18.7433635 },
    { lat: 48.3069788, lng: 18.7435245 },
    { lat: 48.3076154, lng: 18.7441718 },
    { lat: 48.3078738, lng: 18.7441951 },
    { lat: 48.3084332, lng: 18.7440046 },
    { lat: 48.3089025, lng: 18.7436669 },
    { lat: 48.3090453, lng: 18.7434894 },
    { lat: 48.3091336, lng: 18.7431773 },
    { lat: 48.3091262, lng: 18.7429972 },
    { lat: 48.3092145, lng: 18.7430008 },
    { lat: 48.3093074, lng: 18.7432852 },
    { lat: 48.3093101, lng: 18.7436738 },
    { lat: 48.3091495, lng: 18.7441111 },
    { lat: 48.3092819, lng: 18.7457975 },
    { lat: 48.3091762, lng: 18.7459741 },
    { lat: 48.3089826, lng: 18.745706 },
    { lat: 48.30872, lng: 18.7459408 },
    { lat: 48.308332, lng: 18.7460668 },
    { lat: 48.3085091, lng: 18.7464063 },
    { lat: 48.3085573, lng: 18.7466368 },
    { lat: 48.3082328, lng: 18.7471305 },
    { lat: 48.309318, lng: 18.7499568 },
    { lat: 48.3106805, lng: 18.7528026 },
    { lat: 48.3107616, lng: 18.7528422 },
    { lat: 48.3108692, lng: 18.7530674 },
    { lat: 48.3115122, lng: 18.7546597 },
    { lat: 48.312147, lng: 18.7564227 },
    { lat: 48.3122062, lng: 18.7567724 },
    { lat: 48.3122659, lng: 18.7589618 },
    { lat: 48.3123838, lng: 18.7595904 },
    { lat: 48.312154, lng: 18.7604217 },
    { lat: 48.3120508, lng: 18.7617948 },
    { lat: 48.3120285, lng: 18.7625024 },
    { lat: 48.31208, lng: 18.7646652 },
    { lat: 48.3121473, lng: 18.7649537 },
    { lat: 48.3123675, lng: 18.7652782 },
    { lat: 48.3124961, lng: 18.7656225 },
    { lat: 48.3130807, lng: 18.7662671 },
    { lat: 48.3133971, lng: 18.7668338 },
    { lat: 48.3139845, lng: 18.7680847 },
    { lat: 48.3140538, lng: 18.7685016 },
    { lat: 48.3141676, lng: 18.7686677 },
    { lat: 48.3140877, lng: 18.769588 },
    { lat: 48.3142061, lng: 18.7706312 },
    { lat: 48.314266, lng: 18.7723161 },
    { lat: 48.3134635, lng: 18.772751 },
    { lat: 48.3129861, lng: 18.7730845 },
    { lat: 48.3129669, lng: 18.7730439 },
    { lat: 48.3128067, lng: 18.7731696 },
    { lat: 48.3126985, lng: 18.7730857 },
    { lat: 48.3125188, lng: 18.7727131 },
    { lat: 48.3119678, lng: 18.7725534 },
    { lat: 48.3116493, lng: 18.7720949 },
    { lat: 48.3116459, lng: 18.7719281 },
    { lat: 48.3115882, lng: 18.7717766 },
    { lat: 48.3110933, lng: 18.7713731 },
    { lat: 48.3108155, lng: 18.7709612 },
    { lat: 48.3106694, lng: 18.7708443 },
    { lat: 48.3099843, lng: 18.7695134 },
    { lat: 48.309336, lng: 18.7698566 },
    { lat: 48.3089601, lng: 18.7688053 },
    { lat: 48.3083633, lng: 18.767929 },
    { lat: 48.3078559, lng: 18.7683788 },
    { lat: 48.3078067, lng: 18.7687038 },
    { lat: 48.3076289, lng: 18.7692634 },
    { lat: 48.3074497, lng: 18.7695451 },
    { lat: 48.3070201, lng: 18.7690978 },
    { lat: 48.3069615, lng: 18.7691827 },
    { lat: 48.3069811, lng: 18.7692597 },
    { lat: 48.3068398, lng: 18.7698047 },
    { lat: 48.3065321, lng: 18.7706833 },
    { lat: 48.307124, lng: 18.77106 },
    { lat: 48.3067361, lng: 18.7723852 },
    { lat: 48.3065612, lng: 18.7726172 },
    { lat: 48.3064814, lng: 18.773175 },
    { lat: 48.3062937, lng: 18.7739572 },
    { lat: 48.3065716, lng: 18.7741576 },
    { lat: 48.3066341, lng: 18.7746556 },
    { lat: 48.3065642, lng: 18.7755941 },
    { lat: 48.3063938, lng: 18.7766782 },
    { lat: 48.3063799, lng: 18.778802 },
    { lat: 48.3062185, lng: 18.7794411 },
    { lat: 48.3057768, lng: 18.7801744 },
    { lat: 48.3057076, lng: 18.7807203 },
    { lat: 48.3055557, lng: 18.780726 },
    { lat: 48.3054582, lng: 18.7809062 },
    { lat: 48.3054878, lng: 18.7812119 },
    { lat: 48.3054701, lng: 18.7813064 },
    { lat: 48.3053973, lng: 18.7813154 },
    { lat: 48.3053959, lng: 18.7814796 },
    { lat: 48.3054616, lng: 18.7816765 },
    { lat: 48.3053782, lng: 18.7821253 },
    { lat: 48.304953, lng: 18.7826774 },
    { lat: 48.3047368, lng: 18.7827951 },
    { lat: 48.3044106, lng: 18.7832586 },
    { lat: 48.3044746, lng: 18.7848729 },
    { lat: 48.3042067, lng: 18.7853308 },
    { lat: 48.304683, lng: 18.7860851 },
    { lat: 48.305312, lng: 18.7866998 },
    { lat: 48.3055275, lng: 18.7868064 },
    { lat: 48.30621, lng: 18.7874122 },
    { lat: 48.3064946, lng: 18.7877624 },
    { lat: 48.3067767, lng: 18.7879762 },
    { lat: 48.3072299, lng: 18.7880599 },
    { lat: 48.3075249, lng: 18.7880076 },
    { lat: 48.307848, lng: 18.787762 },
    { lat: 48.3081083, lng: 18.7873121 },
    { lat: 48.3082931, lng: 18.7872451 },
    { lat: 48.3084817, lng: 18.7876975 },
    { lat: 48.3086473, lng: 18.7878447 },
    { lat: 48.3089346, lng: 18.7885644 },
    { lat: 48.3090416, lng: 18.7890098 },
    { lat: 48.309038, lng: 18.7896276 },
    { lat: 48.3090874, lng: 18.7900472 },
    { lat: 48.3090258, lng: 18.7906327 },
    { lat: 48.308651, lng: 18.7921461 },
    { lat: 48.3083025, lng: 18.7926976 },
    { lat: 48.3081971, lng: 18.7930292 },
    { lat: 48.3080219, lng: 18.7932778 },
    { lat: 48.3078049, lng: 18.7934661 },
    { lat: 48.3075364, lng: 18.7934193 },
    { lat: 48.3073011, lng: 18.7934705 },
    { lat: 48.3070446, lng: 18.7938445 },
    { lat: 48.3065872, lng: 18.7943454 },
    { lat: 48.3064093, lng: 18.7943725 },
    { lat: 48.3063277, lng: 18.7943213 },
    { lat: 48.3061864, lng: 18.7946132 },
    { lat: 48.3060322, lng: 18.7947502 },
    { lat: 48.3059165, lng: 18.7946087 },
    { lat: 48.3058215, lng: 18.7947517 },
    { lat: 48.3057044, lng: 18.7946682 },
    { lat: 48.3055869, lng: 18.7948777 },
    { lat: 48.3052922, lng: 18.7947663 },
    { lat: 48.3048897, lng: 18.7950254 },
    { lat: 48.3048443, lng: 18.7951268 },
    { lat: 48.3044221, lng: 18.7950982 },
    { lat: 48.3041431, lng: 18.7953859 },
    { lat: 48.3040475, lng: 18.795415 },
    { lat: 48.3035907, lng: 18.7960333 },
    { lat: 48.3036014, lng: 18.7964079 },
    { lat: 48.3039053, lng: 18.7969308 },
    { lat: 48.3039308, lng: 18.7970698 },
    { lat: 48.3040044, lng: 18.7970356 },
    { lat: 48.3045675, lng: 18.7978555 },
    { lat: 48.3051272, lng: 18.7989734 },
    { lat: 48.3050153, lng: 18.8010439 },
    { lat: 48.3040009, lng: 18.8025124 },
    { lat: 48.3036842, lng: 18.8032204 },
    { lat: 48.3033435, lng: 18.8049322 },
    { lat: 48.3030734, lng: 18.8056725 },
    { lat: 48.3029649, lng: 18.8061794 },
    { lat: 48.3031637, lng: 18.8065703 },
    { lat: 48.3036337, lng: 18.8106837 },
    { lat: 48.3033966, lng: 18.8109788 },
    { lat: 48.3032223, lng: 18.8108779 },
    { lat: 48.3031802, lng: 18.8110883 },
    { lat: 48.303061, lng: 18.8112841 },
    { lat: 48.3028504, lng: 18.8114627 },
    { lat: 48.3028529, lng: 18.8116586 },
    { lat: 48.302462, lng: 18.8119903 },
    { lat: 48.3023596, lng: 18.8123403 },
    { lat: 48.3021819, lng: 18.8123272 },
    { lat: 48.30208, lng: 18.812403 },
    { lat: 48.3019241, lng: 18.8127186 },
    { lat: 48.3017023, lng: 18.8127686 },
    { lat: 48.3014394, lng: 18.8130234 },
    { lat: 48.3012177, lng: 18.8134633 },
    { lat: 48.3013285, lng: 18.8137679 },
    { lat: 48.3016233, lng: 18.8142683 },
    { lat: 48.3018283, lng: 18.8148469 },
    { lat: 48.3017855, lng: 18.814869 },
    { lat: 48.3011777, lng: 18.8190329 },
    { lat: 48.3016074, lng: 18.820083 },
    { lat: 48.3026251, lng: 18.8209547 },
    { lat: 48.3030197, lng: 18.8229256 },
    { lat: 48.3032088, lng: 18.8242088 },
    { lat: 48.3030206, lng: 18.8245771 },
    { lat: 48.3030849, lng: 18.8248557 },
    { lat: 48.3030012, lng: 18.8249817 },
    { lat: 48.303008, lng: 18.8253046 },
    { lat: 48.3028729, lng: 18.8255441 },
    { lat: 48.3028938, lng: 18.8257952 },
    { lat: 48.3028514, lng: 18.8262295 },
    { lat: 48.3029067, lng: 18.8265307 },
    { lat: 48.3032437, lng: 18.8273011 },
    { lat: 48.3033098, lng: 18.8277463 },
    { lat: 48.3032077, lng: 18.8280806 },
    { lat: 48.3031743, lng: 18.8284306 },
    { lat: 48.3024636, lng: 18.8297621 },
    { lat: 48.3032215, lng: 18.8306015 },
    { lat: 48.3031951, lng: 18.8317512 },
    { lat: 48.303302, lng: 18.8337514 },
    { lat: 48.3028476, lng: 18.8345267 },
    { lat: 48.3034672, lng: 18.8359113 },
    { lat: 48.303826, lng: 18.8375341 },
    { lat: 48.3035511, lng: 18.8389814 },
    { lat: 48.3031932, lng: 18.8402646 },
    { lat: 48.3026076, lng: 18.8413174 },
    { lat: 48.3024512, lng: 18.8413627 },
    { lat: 48.3023363, lng: 18.8413018 },
    { lat: 48.3021457, lng: 18.8414942 },
    { lat: 48.3020235, lng: 18.8417462 },
    { lat: 48.3013003, lng: 18.8423005 },
    { lat: 48.3011826, lng: 18.8420785 },
    { lat: 48.3009491, lng: 18.8422274 },
    { lat: 48.300698, lng: 18.8425357 },
    { lat: 48.3003355, lng: 18.8428255 },
    { lat: 48.3001013, lng: 18.843159 },
    { lat: 48.2996573, lng: 18.8443703 },
    { lat: 48.2990498, lng: 18.8450664 },
    { lat: 48.2989553, lng: 18.8453286 },
    { lat: 48.298977, lng: 18.8457659 },
    { lat: 48.298418, lng: 18.847307 },
    { lat: 48.299034, lng: 18.847011 },
    { lat: 48.299198, lng: 18.846915 },
    { lat: 48.299329, lng: 18.846847 },
    { lat: 48.299493, lng: 18.846262 },
    { lat: 48.299502, lng: 18.846164 },
    { lat: 48.299576, lng: 18.845943 },
    { lat: 48.299613, lng: 18.845982 },
    { lat: 48.299972, lng: 18.846466 },
    { lat: 48.300043, lng: 18.846557 },
    { lat: 48.300166, lng: 18.846861 },
    { lat: 48.3004, lng: 18.847317 },
    { lat: 48.300596, lng: 18.847856 },
    { lat: 48.300667, lng: 18.848037 },
    { lat: 48.30075, lng: 18.848158 },
    { lat: 48.301119, lng: 18.84819 },
    { lat: 48.301298, lng: 18.8482 },
    { lat: 48.302034, lng: 18.84855 },
    { lat: 48.302085, lng: 18.848566 },
    { lat: 48.302169, lng: 18.848951 },
    { lat: 48.302219, lng: 18.849132 },
    { lat: 48.302234, lng: 18.849219 },
    { lat: 48.302253, lng: 18.849367 },
    { lat: 48.302339, lng: 18.849985 },
    { lat: 48.302418, lng: 18.850183 },
    { lat: 48.302473, lng: 18.850344 },
    { lat: 48.302536, lng: 18.850577 },
    { lat: 48.302546, lng: 18.850729 },
    { lat: 48.302558, lng: 18.850828 },
    { lat: 48.302623, lng: 18.851026 },
    { lat: 48.302646, lng: 18.851143 },
    { lat: 48.302629, lng: 18.851304 },
    { lat: 48.302637, lng: 18.851373 },
    { lat: 48.302666, lng: 18.851416 },
    { lat: 48.302757, lng: 18.851437 },
    { lat: 48.302966, lng: 18.851622 },
    { lat: 48.303148, lng: 18.851829 },
    { lat: 48.303234, lng: 18.8519 },
    { lat: 48.30351, lng: 18.852067 },
    { lat: 48.303624, lng: 18.852101 },
    { lat: 48.303872, lng: 18.852188 },
    { lat: 48.304079, lng: 18.852156 },
    { lat: 48.304305, lng: 18.852107 },
    { lat: 48.304614, lng: 18.85206 },
    { lat: 48.304719, lng: 18.851947 },
    { lat: 48.304853, lng: 18.851874 },
    { lat: 48.305016, lng: 18.851813 },
    { lat: 48.305177, lng: 18.851866 },
    { lat: 48.305217, lng: 18.851868 },
    { lat: 48.305251, lng: 18.851863 },
    { lat: 48.305486, lng: 18.85193 },
    { lat: 48.305602, lng: 18.852025 },
    { lat: 48.305674, lng: 18.852094 },
    { lat: 48.305687, lng: 18.85207 },
    { lat: 48.305788, lng: 18.852422 },
    { lat: 48.3059, lng: 18.852358 },
    { lat: 48.306572, lng: 18.851975 },
    { lat: 48.306628, lng: 18.851943 },
    { lat: 48.306989, lng: 18.85161 },
    { lat: 48.307476, lng: 18.851088 },
    { lat: 48.308699, lng: 18.850367 },
    { lat: 48.30965, lng: 18.849805 },
    { lat: 48.310349, lng: 18.849461 },
    { lat: 48.310722, lng: 18.847888 },
    { lat: 48.310757, lng: 18.847799 },
    { lat: 48.311061, lng: 18.848129 },
    { lat: 48.311288, lng: 18.848411 },
    { lat: 48.3115, lng: 18.848663 },
    { lat: 48.311843, lng: 18.849128 },
    { lat: 48.31264, lng: 18.848564 },
    { lat: 48.313126, lng: 18.848221 },
    { lat: 48.313159, lng: 18.848193 },
    { lat: 48.314125, lng: 18.848061 },
    { lat: 48.31431, lng: 18.848036 },
    { lat: 48.314316, lng: 18.848036 },
    { lat: 48.315381, lng: 18.846943 },
    { lat: 48.316257, lng: 18.846461 },
    { lat: 48.316276, lng: 18.846456 },
    { lat: 48.316714, lng: 18.846336 },
    { lat: 48.317136, lng: 18.84622 },
    { lat: 48.319267, lng: 18.845855 },
    { lat: 48.319712, lng: 18.845779 },
    { lat: 48.320095, lng: 18.845491 },
    { lat: 48.32014, lng: 18.845457 },
    { lat: 48.32025, lng: 18.845443 },
    { lat: 48.320609, lng: 18.845394 },
    { lat: 48.320605, lng: 18.845341 },
    { lat: 48.320591, lng: 18.845151 },
    { lat: 48.320584, lng: 18.844966 },
    { lat: 48.320615, lng: 18.844791 },
    { lat: 48.320632, lng: 18.844521 },
    { lat: 48.320748, lng: 18.844046 },
    { lat: 48.320752, lng: 18.844032 },
    { lat: 48.32082, lng: 18.843734 },
    { lat: 48.32084, lng: 18.843368 },
    { lat: 48.32084, lng: 18.843075 },
    { lat: 48.320823, lng: 18.842925 },
    { lat: 48.320774, lng: 18.842494 },
    { lat: 48.320789, lng: 18.841981 },
    { lat: 48.320771, lng: 18.841728 },
    { lat: 48.320771, lng: 18.841724 },
    { lat: 48.320785, lng: 18.841553 },
    { lat: 48.320794, lng: 18.841529 },
    { lat: 48.320809, lng: 18.841492 },
    { lat: 48.320895, lng: 18.841282 },
    { lat: 48.320909, lng: 18.841249 },
    { lat: 48.320927, lng: 18.841205 },
    { lat: 48.320996, lng: 18.841076 },
    { lat: 48.321092, lng: 18.840895 },
    { lat: 48.321119, lng: 18.840847 },
    { lat: 48.321128, lng: 18.84086 },
    { lat: 48.321208, lng: 18.840688 },
    { lat: 48.321253, lng: 18.840589 },
    { lat: 48.321321, lng: 18.840474 },
    { lat: 48.321355, lng: 18.840419 },
    { lat: 48.321571, lng: 18.840139 },
    { lat: 48.32165, lng: 18.83981 },
    { lat: 48.321675, lng: 18.839607 },
    { lat: 48.321726, lng: 18.83921 },
    { lat: 48.321761, lng: 18.838566 },
    { lat: 48.321773, lng: 18.838374 },
    { lat: 48.321797, lng: 18.838023 },
    { lat: 48.32177, lng: 18.837606 },
    { lat: 48.321767, lng: 18.837543 },
    { lat: 48.321766, lng: 18.837532 },
    { lat: 48.321665, lng: 18.837325 },
    { lat: 48.32164, lng: 18.837169 },
    { lat: 48.321375, lng: 18.835525 },
    { lat: 48.321235, lng: 18.834209 },
    { lat: 48.321167, lng: 18.833578 },
    { lat: 48.321143, lng: 18.832999 },
    { lat: 48.321153, lng: 18.832993 },
    { lat: 48.321506, lng: 18.832761 },
    { lat: 48.32229, lng: 18.832282 },
    { lat: 48.322292, lng: 18.832256 },
    { lat: 48.322298, lng: 18.832131 },
    { lat: 48.322494, lng: 18.83196 },
    { lat: 48.322597, lng: 18.83187 },
    { lat: 48.322802, lng: 18.831677 },
    { lat: 48.32305, lng: 18.831256 },
    { lat: 48.322998, lng: 18.831004 },
    { lat: 48.323052, lng: 18.830912 },
    { lat: 48.323201, lng: 18.830799 },
    { lat: 48.323478, lng: 18.830435 },
    { lat: 48.323722, lng: 18.830221 },
    { lat: 48.323984, lng: 18.829983 },
    { lat: 48.32408, lng: 18.829839 },
    { lat: 48.324506, lng: 18.829457 },
    { lat: 48.324598, lng: 18.829374 },
    { lat: 48.324808, lng: 18.829227 },
    { lat: 48.325226, lng: 18.828972 },
    { lat: 48.325432, lng: 18.828841 },
    { lat: 48.326845, lng: 18.828263 },
    { lat: 48.326891, lng: 18.828239 },
    { lat: 48.32692, lng: 18.828224 },
    { lat: 48.326957, lng: 18.828204 },
    { lat: 48.32698, lng: 18.828191 },
    { lat: 48.327734, lng: 18.827792 },
    { lat: 48.327778, lng: 18.827721 },
    { lat: 48.327893, lng: 18.827662 },
    { lat: 48.327969, lng: 18.827668 },
    { lat: 48.328031, lng: 18.827635 },
    { lat: 48.328899, lng: 18.827417 },
    { lat: 48.329028, lng: 18.826816 },
    { lat: 48.330675, lng: 18.825727 },
    { lat: 48.33126, lng: 18.824955 },
    { lat: 48.331569, lng: 18.824892 },
    { lat: 48.331737, lng: 18.824858 },
    { lat: 48.332163, lng: 18.824594 },
    { lat: 48.332354, lng: 18.824412 },
    { lat: 48.332635, lng: 18.824142 },
    { lat: 48.332661, lng: 18.824137 },
    { lat: 48.332934, lng: 18.824119 },
    { lat: 48.333723, lng: 18.824057 },
    { lat: 48.333804, lng: 18.824051 },
    { lat: 48.334015, lng: 18.823812 },
    { lat: 48.334327, lng: 18.823854 },
    { lat: 48.335388, lng: 18.823997 },
    { lat: 48.335586, lng: 18.824024 },
    { lat: 48.335729, lng: 18.824214 },
    { lat: 48.336143, lng: 18.824765 },
    { lat: 48.33651, lng: 18.824775 },
    { lat: 48.336677, lng: 18.824926 },
    { lat: 48.337056, lng: 18.824977 },
    { lat: 48.337436, lng: 18.824979 },
    { lat: 48.337836, lng: 18.824915 },
    { lat: 48.338245, lng: 18.824894 },
    { lat: 48.338438, lng: 18.824726 },
    { lat: 48.338888, lng: 18.824523 },
    { lat: 48.339303, lng: 18.824124 },
    { lat: 48.339543, lng: 18.823935 },
    { lat: 48.339658, lng: 18.823771 },
    { lat: 48.340002, lng: 18.823815 },
    { lat: 48.340479, lng: 18.823695 },
    { lat: 48.340601, lng: 18.823664 },
    { lat: 48.340938, lng: 18.823793 },
    { lat: 48.341109, lng: 18.82377 },
    { lat: 48.341533, lng: 18.823761 },
    { lat: 48.342435, lng: 18.82374 },
    { lat: 48.342995, lng: 18.8237 },
    { lat: 48.343369, lng: 18.823705 },
    { lat: 48.34377, lng: 18.823539 },
    { lat: 48.343848, lng: 18.823506 },
    { lat: 48.34414, lng: 18.823661 },
    { lat: 48.344534, lng: 18.823808 },
    { lat: 48.344879, lng: 18.823944 },
    { lat: 48.345066, lng: 18.824018 },
    { lat: 48.345562, lng: 18.824213 },
    { lat: 48.346032, lng: 18.82427 },
    { lat: 48.346061, lng: 18.824221 },
    { lat: 48.346054, lng: 18.824149 },
    { lat: 48.346143, lng: 18.823401 },
    { lat: 48.346131, lng: 18.823314 },
    { lat: 48.346015, lng: 18.822523 },
    { lat: 48.346607, lng: 18.822177 },
    { lat: 48.346643, lng: 18.822156 },
    { lat: 48.346639, lng: 18.82209 },
    { lat: 48.346633, lng: 18.821991 },
    { lat: 48.346651, lng: 18.821501 },
    { lat: 48.346654, lng: 18.821102 },
    { lat: 48.346344, lng: 18.820703 },
    { lat: 48.346676, lng: 18.819796 },
    { lat: 48.346695, lng: 18.819827 },
    { lat: 48.346748, lng: 18.81991 },
    { lat: 48.34759, lng: 18.820447 },
    { lat: 48.348118, lng: 18.820832 },
    { lat: 48.348327, lng: 18.8207 },
    { lat: 48.349208, lng: 18.819759 },
    { lat: 48.34955, lng: 18.819525 },
    { lat: 48.352333, lng: 18.81928 },
    { lat: 48.35293, lng: 18.818858 },
    { lat: 48.354631, lng: 18.817581 },
    { lat: 48.356609, lng: 18.816871 },
    { lat: 48.356657, lng: 18.816845 },
    { lat: 48.356768, lng: 18.816785 },
    { lat: 48.356817, lng: 18.816759 },
    { lat: 48.356852, lng: 18.81674 },
    { lat: 48.357115, lng: 18.816373 },
    { lat: 48.358457, lng: 18.816871 },
    { lat: 48.36085, lng: 18.817061 },
    { lat: 48.361893, lng: 18.816881 },
    { lat: 48.36306, lng: 18.816541 },
    { lat: 48.363733, lng: 18.815975 },
    { lat: 48.364458, lng: 18.815313 },
    { lat: 48.365232, lng: 18.813901 },
    { lat: 48.365284, lng: 18.813836 },
    { lat: 48.36532, lng: 18.81378 },
    { lat: 48.366345, lng: 18.812446 },
    { lat: 48.368205, lng: 18.811028 },
    { lat: 48.369696, lng: 18.81121 },
    { lat: 48.370533, lng: 18.810571 },
    { lat: 48.370735, lng: 18.809204 },
    { lat: 48.370827, lng: 18.808716 },
    { lat: 48.370874, lng: 18.808451 },
    { lat: 48.37101, lng: 18.808373 },
    { lat: 48.371045, lng: 18.808157 },
    { lat: 48.37108, lng: 18.807996 },
    { lat: 48.371063, lng: 18.807888 },
    { lat: 48.371118, lng: 18.807736 },
    { lat: 48.37114, lng: 18.807616 },
    { lat: 48.371176, lng: 18.807393 },
    { lat: 48.371222, lng: 18.80727 },
    { lat: 48.371169, lng: 18.807179 },
    { lat: 48.371186, lng: 18.807005 },
    { lat: 48.371235, lng: 18.806906 },
    { lat: 48.371181, lng: 18.80678 },
    { lat: 48.371148, lng: 18.80654 },
    { lat: 48.371197, lng: 18.806399 },
    { lat: 48.371142, lng: 18.806269 },
    { lat: 48.371145, lng: 18.806083 },
    { lat: 48.371182, lng: 18.805802 },
    { lat: 48.37124, lng: 18.805635 },
    { lat: 48.371198, lng: 18.805501 },
    { lat: 48.37117, lng: 18.80535 },
    { lat: 48.371208, lng: 18.805167 },
    { lat: 48.37127, lng: 18.805092 },
    { lat: 48.371282, lng: 18.804962 },
    { lat: 48.371303, lng: 18.804882 },
    { lat: 48.371282, lng: 18.804812 },
    { lat: 48.371315, lng: 18.804457 },
    { lat: 48.371361, lng: 18.804357 },
    { lat: 48.371309, lng: 18.804265 },
    { lat: 48.3712733, lng: 18.8041827 },
    { lat: 48.37127, lng: 18.804175 },
    { lat: 48.37129, lng: 18.804044 },
    { lat: 48.371333, lng: 18.803945 },
    { lat: 48.371319, lng: 18.803881 },
    { lat: 48.371266, lng: 18.803814 },
    { lat: 48.371306, lng: 18.803633 },
    { lat: 48.37129, lng: 18.803441 },
    { lat: 48.371211, lng: 18.803333 },
    { lat: 48.371228, lng: 18.803143 },
    { lat: 48.371281, lng: 18.803048 },
    { lat: 48.371317, lng: 18.802909 },
    { lat: 48.371326, lng: 18.802789 },
    { lat: 48.371284, lng: 18.802707 },
    { lat: 48.371294, lng: 18.802554 },
    { lat: 48.371279, lng: 18.802546 },
    { lat: 48.371265, lng: 18.80254 },
    { lat: 48.371416, lng: 18.801348 },
    { lat: 48.371421, lng: 18.801306 },
    { lat: 48.371463, lng: 18.800977 },
    { lat: 48.371467, lng: 18.800943 },
    { lat: 48.371501, lng: 18.800672 },
    { lat: 48.371491, lng: 18.800135 },
    { lat: 48.371761, lng: 18.799851 },
    { lat: 48.371888, lng: 18.799784 },
    { lat: 48.372447, lng: 18.799611 },
    { lat: 48.372558, lng: 18.799583 },
    { lat: 48.37259, lng: 18.799576 },
    { lat: 48.372789, lng: 18.799525 },
    { lat: 48.372878, lng: 18.799237 },
    { lat: 48.372895, lng: 18.799182 },
    { lat: 48.373063, lng: 18.798612 },
    { lat: 48.373383, lng: 18.797427 },
    { lat: 48.373552, lng: 18.796688 },
    { lat: 48.373911, lng: 18.794978 },
    { lat: 48.374284, lng: 18.793313 },
    { lat: 48.374567, lng: 18.792045 },
    { lat: 48.375394, lng: 18.791801 },
    { lat: 48.37593, lng: 18.790892 },
    { lat: 48.376076, lng: 18.79055 },
    { lat: 48.376203, lng: 18.790275 },
    { lat: 48.376258, lng: 18.790215 },
    { lat: 48.376436, lng: 18.790099 },
    { lat: 48.376491, lng: 18.790047 },
    { lat: 48.376671, lng: 18.78994 },
    { lat: 48.376805, lng: 18.789976 },
    { lat: 48.376847, lng: 18.789953 },
    { lat: 48.376924, lng: 18.789847 },
    { lat: 48.377005, lng: 18.789806 },
    { lat: 48.377058, lng: 18.789827 },
    { lat: 48.377201, lng: 18.789592 },
    { lat: 48.377303, lng: 18.78938 },
    { lat: 48.37747, lng: 18.789239 },
    { lat: 48.377738, lng: 18.788805 },
    { lat: 48.37788, lng: 18.788589 },
    { lat: 48.378429, lng: 18.787068 },
    { lat: 48.378364, lng: 18.786921 },
    { lat: 48.378489, lng: 18.786599 },
    { lat: 48.378574, lng: 18.786289 },
    { lat: 48.378593, lng: 18.78593 },
    { lat: 48.37832, lng: 18.785469 },
    { lat: 48.378346, lng: 18.785276 },
    { lat: 48.378203, lng: 18.785259 },
    { lat: 48.378144, lng: 18.785047 },
    { lat: 48.378023, lng: 18.784954 },
    { lat: 48.377921, lng: 18.785008 },
    { lat: 48.377879, lng: 18.785002 },
    { lat: 48.377801, lng: 18.784911 },
    { lat: 48.37778, lng: 18.784766 },
    { lat: 48.377728, lng: 18.7847 },
    { lat: 48.377537, lng: 18.78467 },
    { lat: 48.377436, lng: 18.784553 },
    { lat: 48.377157, lng: 18.784526 },
    { lat: 48.377045, lng: 18.784452 },
    { lat: 48.377006, lng: 18.784387 },
    { lat: 48.37699, lng: 18.784362 },
    { lat: 48.3769334, lng: 18.7844695 },
    { lat: 48.3767153, lng: 18.7844806 },
    { lat: 48.3765223, lng: 18.7843921 },
    { lat: 48.3758083, lng: 18.7842815 },
    { lat: 48.3749983, lng: 18.7842345 },
    { lat: 48.3747811, lng: 18.7841585 },
    { lat: 48.3742715, lng: 18.7842684 },
    { lat: 48.373301, lng: 18.7847892 },
    { lat: 48.3729704, lng: 18.7850615 },
    { lat: 48.3724436, lng: 18.7857469 },
    { lat: 48.3717375, lng: 18.7859351 },
    { lat: 48.3711234, lng: 18.7859683 },
    { lat: 48.3702065, lng: 18.7861794 },
    { lat: 48.3689205, lng: 18.7861524 },
    { lat: 48.3679763, lng: 18.7864015 },
    { lat: 48.3678643, lng: 18.7865009 },
    { lat: 48.3677212, lng: 18.7867627 },
    { lat: 48.3673155, lng: 18.7871939 },
    { lat: 48.3667658, lng: 18.7875294 },
    { lat: 48.3662543, lng: 18.7875795 },
    { lat: 48.3654562, lng: 18.7873906 },
    { lat: 48.3651925, lng: 18.7872042 },
    { lat: 48.3646967, lng: 18.7865272 },
    { lat: 48.3639553, lng: 18.7859195 },
    { lat: 48.3632904, lng: 18.7855016 },
    { lat: 48.3628796, lng: 18.7851097 },
    { lat: 48.3626114, lng: 18.7847518 },
    { lat: 48.3622086, lng: 18.784427 },
    { lat: 48.361918, lng: 18.7836452 },
    { lat: 48.3613779, lng: 18.783048 },
    { lat: 48.3595636, lng: 18.7821094 },
    { lat: 48.358863, lng: 18.781936 },
    { lat: 48.3583524, lng: 18.7819598 },
    { lat: 48.3581127, lng: 18.781884 },
    { lat: 48.3572924, lng: 18.7813862 },
    { lat: 48.3571305, lng: 18.7812012 },
    { lat: 48.3564829, lng: 18.7808339 },
    { lat: 48.3549489, lng: 18.7805095 },
    { lat: 48.354501, lng: 18.7801862 },
    { lat: 48.3543584, lng: 18.7800061 },
    { lat: 48.3538595, lng: 18.7795938 },
    { lat: 48.3534613, lng: 18.7790572 },
    { lat: 48.3529777, lng: 18.7788041 },
    { lat: 48.3523754, lng: 18.7778617 },
    { lat: 48.3518646, lng: 18.7774796 },
    { lat: 48.3514154, lng: 18.7774313 },
    { lat: 48.3509734, lng: 18.777497 },
    { lat: 48.3493141, lng: 18.7770838 },
    { lat: 48.3488193, lng: 18.7768126 },
    { lat: 48.3485698, lng: 18.776531 },
    { lat: 48.348387, lng: 18.7766325 },
    { lat: 48.347918, lng: 18.7764685 },
    { lat: 48.3465043, lng: 18.7767547 },
    { lat: 48.3462018, lng: 18.7769187 },
    { lat: 48.3461519, lng: 18.7766984 },
    { lat: 48.3461949, lng: 18.7764037 },
    { lat: 48.345948, lng: 18.775981 },
    { lat: 48.34598, lng: 18.7757549 },
    { lat: 48.3459056, lng: 18.7752179 },
    { lat: 48.346012, lng: 18.7750985 },
    { lat: 48.3458364, lng: 18.7740608 },
    { lat: 48.3453382, lng: 18.7739801 },
    { lat: 48.3449438, lng: 18.774177 },
    { lat: 48.3445105, lng: 18.7739155 },
    { lat: 48.3443752, lng: 18.7740472 },
    { lat: 48.3442556, lng: 18.7740423 },
    { lat: 48.3439332, lng: 18.7738991 },
    { lat: 48.343753, lng: 18.7736692 },
    { lat: 48.343446, lng: 18.7734097 },
    { lat: 48.34309, lng: 18.7733709 },
    { lat: 48.3422064, lng: 18.7740021 },
    { lat: 48.3420001, lng: 18.7740869 },
    { lat: 48.3417903, lng: 18.7739807 },
    { lat: 48.3413504, lng: 18.7744251 },
    { lat: 48.3411912, lng: 18.7745075 },
    { lat: 48.3406613, lng: 18.7743402 },
    { lat: 48.3398835, lng: 18.7750498 },
    { lat: 48.3396433, lng: 18.7751596 },
    { lat: 48.3390944, lng: 18.7751827 },
    { lat: 48.338845, lng: 18.7748639 },
    { lat: 48.338602, lng: 18.7743189 },
    { lat: 48.3380729, lng: 18.7743993 },
    { lat: 48.3380707, lng: 18.7737179 },
    { lat: 48.3383533, lng: 18.7735836 },
    { lat: 48.3383254, lng: 18.7732264 },
    { lat: 48.3382628, lng: 18.7731499 },
    { lat: 48.3378148, lng: 18.7729774 },
    { lat: 48.3376272, lng: 18.7727101 },
    { lat: 48.3376189, lng: 18.7723606 },
    { lat: 48.3373353, lng: 18.7720537 },
    { lat: 48.3370274, lng: 18.7721212 },
    { lat: 48.336623, lng: 18.7719584 },
    { lat: 48.3364396, lng: 18.7716687 },
    { lat: 48.3364515, lng: 18.771255 },
    { lat: 48.3361537, lng: 18.7711636 },
    { lat: 48.3358724, lng: 18.7711762 },
    { lat: 48.3357417, lng: 18.7709263 },
    { lat: 48.3356993, lng: 18.7706559 },
    { lat: 48.3354172, lng: 18.7705676 },
    { lat: 48.3350555, lng: 18.7702757 },
    { lat: 48.3346214, lng: 18.7701278 },
    { lat: 48.3343251, lng: 18.7696867 },
    { lat: 48.3341947, lng: 18.7696523 },
    { lat: 48.3339598, lng: 18.7694516 },
    { lat: 48.3337792, lng: 18.7692364 },
    { lat: 48.3335036, lng: 18.7686741 },
    { lat: 48.3332741, lng: 18.768454 },
    { lat: 48.3332002, lng: 18.7679284 },
    { lat: 48.3329724, lng: 18.7675228 },
    { lat: 48.332424, lng: 18.7667304 },
    { lat: 48.331996, lng: 18.7662752 },
    { lat: 48.3316384, lng: 18.7660595 },
    { lat: 48.3315577, lng: 18.7658132 },
    { lat: 48.3303245, lng: 18.7656326 },
    { lat: 48.3299742, lng: 18.7651272 },
    { lat: 48.3296526, lng: 18.7651679 },
    { lat: 48.329423, lng: 18.7650893 },
    { lat: 48.3293136, lng: 18.765259 },
    { lat: 48.3288013, lng: 18.7647874 },
    { lat: 48.3287339, lng: 18.7646225 },
    { lat: 48.3287741, lng: 18.7644437 },
    { lat: 48.3286776, lng: 18.7641578 },
    { lat: 48.3284197, lng: 18.7635414 },
    { lat: 48.3280245, lng: 18.7637002 },
    { lat: 48.3276843, lng: 18.7632689 },
    { lat: 48.327466, lng: 18.7626819 },
    { lat: 48.3270603, lng: 18.7622586 },
    { lat: 48.3269694, lng: 18.7622698 },
    { lat: 48.3268657, lng: 18.7623998 },
    { lat: 48.326627, lng: 18.7622341 },
    { lat: 48.3263292, lng: 18.7616189 },
    { lat: 48.325852, lng: 18.7614437 },
    { lat: 48.3254996, lng: 18.761626 },
    { lat: 48.3252571, lng: 18.7615401 },
    { lat: 48.3250069, lng: 18.7612659 },
    { lat: 48.3248337, lng: 18.7612302 },
    { lat: 48.3244124, lng: 18.7609235 },
    { lat: 48.3236084, lng: 18.7599474 },
    { lat: 48.3229676, lng: 18.7596032 },
    { lat: 48.3228013, lng: 18.7594441 },
    { lat: 48.322415, lng: 18.7587329 },
    { lat: 48.3217548, lng: 18.7578914 },
    { lat: 48.3217894, lng: 18.7576927 },
    { lat: 48.3216945, lng: 18.7571612 },
    { lat: 48.3216112, lng: 18.7541039 },
    { lat: 48.3216544, lng: 18.7539375 },
    { lat: 48.321716, lng: 18.7527608 },
    { lat: 48.3215998, lng: 18.7517442 },
    { lat: 48.3215412, lng: 18.7517221 },
    { lat: 48.3216598, lng: 18.7514093 },
    { lat: 48.3218851, lng: 18.7512555 },
    { lat: 48.3220709, lng: 18.7509938 },
    { lat: 48.3221455, lng: 18.7506975 },
    { lat: 48.322171, lng: 18.7503291 },
    { lat: 48.3221276, lng: 18.7498315 },
    { lat: 48.3220443, lng: 18.7496196 },
    { lat: 48.3219529, lng: 18.7491323 },
    { lat: 48.3218012, lng: 18.7489112 },
    { lat: 48.3216801, lng: 18.7483927 },
    { lat: 48.3214854, lng: 18.7481446 },
    { lat: 48.3213945, lng: 18.7477493 },
    { lat: 48.32139, lng: 18.7474519 },
    { lat: 48.3214623, lng: 18.7470329 },
    { lat: 48.3214391, lng: 18.746384 },
    { lat: 48.3215992, lng: 18.7461498 },
    { lat: 48.3218439, lng: 18.7460228 },
    { lat: 48.3220109, lng: 18.7452785 },
    { lat: 48.3216536, lng: 18.7449456 },
    { lat: 48.3216102, lng: 18.7445124 },
    { lat: 48.3214636, lng: 18.7441498 },
    { lat: 48.3212178, lng: 18.7445314 },
    { lat: 48.3209845, lng: 18.7444522 },
    { lat: 48.3207928, lng: 18.7438153 },
    { lat: 48.3204156, lng: 18.7431879 },
    { lat: 48.3206625, lng: 18.7429968 },
    { lat: 48.3208789, lng: 18.7430567 },
    { lat: 48.3211529, lng: 18.74289 },
    { lat: 48.3214499, lng: 18.7425327 },
    { lat: 48.3220643, lng: 18.7419816 },
    { lat: 48.3221677, lng: 18.7416758 },
    { lat: 48.322672, lng: 18.7412764 },
    { lat: 48.3230376, lng: 18.7402181 },
    { lat: 48.3230924, lng: 18.7397644 },
    { lat: 48.3232662, lng: 18.7394519 },
    { lat: 48.3243179, lng: 18.7385281 },
    { lat: 48.3249329, lng: 18.7380686 },
    { lat: 48.3255397, lng: 18.7369881 },
    { lat: 48.3265293, lng: 18.7347518 },
    { lat: 48.3270765, lng: 18.7339322 },
    { lat: 48.3274829, lng: 18.7336425 },
  ],
  Ondrejovce: [
    { lat: 48.1454833, lng: 18.4793256 },
    { lat: 48.1454678, lng: 18.479289 },
    { lat: 48.1441839, lng: 18.4780319 },
    { lat: 48.1434297, lng: 18.4788324 },
    { lat: 48.1423165, lng: 18.4798461 },
    { lat: 48.1401217, lng: 18.4773866 },
    { lat: 48.1385794, lng: 18.4762099 },
    { lat: 48.1368497, lng: 18.4744999 },
    { lat: 48.1362357, lng: 18.4760133 },
    { lat: 48.135895, lng: 18.4773528 },
    { lat: 48.1356459, lng: 18.4790556 },
    { lat: 48.1354379, lng: 18.4796963 },
    { lat: 48.135029, lng: 18.4807003 },
    { lat: 48.1346109, lng: 18.4815581 },
    { lat: 48.133665, lng: 18.4839004 },
    { lat: 48.133096, lng: 18.4850044 },
    { lat: 48.1321425, lng: 18.486358 },
    { lat: 48.1316569, lng: 18.4880314 },
    { lat: 48.1313923, lng: 18.4874734 },
    { lat: 48.1303943, lng: 18.4885581 },
    { lat: 48.1286731, lng: 18.4855401 },
    { lat: 48.1279927, lng: 18.484727 },
    { lat: 48.1271019, lng: 18.4863952 },
    { lat: 48.1260595, lng: 18.4879952 },
    { lat: 48.1243484, lng: 18.4904319 },
    { lat: 48.1235641, lng: 18.4916879 },
    { lat: 48.1230783, lng: 18.4925874 },
    { lat: 48.1214434, lng: 18.4951386 },
    { lat: 48.1204289, lng: 18.4968386 },
    { lat: 48.1186935, lng: 18.4990416 },
    { lat: 48.1172259, lng: 18.5015219 },
    { lat: 48.1167953, lng: 18.5023836 },
    { lat: 48.116107, lng: 18.5035772 },
    { lat: 48.1155965, lng: 18.5043189 },
    { lat: 48.1154692, lng: 18.5045263 },
    { lat: 48.1153695, lng: 18.504718 },
    { lat: 48.1150405, lng: 18.5057842 },
    { lat: 48.1146913, lng: 18.5065673 },
    { lat: 48.1144172, lng: 18.5070552 },
    { lat: 48.1144624, lng: 18.5071145 },
    { lat: 48.1144092, lng: 18.5071755 },
    { lat: 48.1143715, lng: 18.5071251 },
    { lat: 48.1139163, lng: 18.5076636 },
    { lat: 48.1127975, lng: 18.5097621 },
    { lat: 48.1121989, lng: 18.5105823 },
    { lat: 48.1118781, lng: 18.5120621 },
    { lat: 48.1117413, lng: 18.513134 },
    { lat: 48.1116183, lng: 18.5151718 },
    { lat: 48.1113825, lng: 18.516269 },
    { lat: 48.111194, lng: 18.5167752 },
    { lat: 48.112653, lng: 18.518535 },
    { lat: 48.1143227, lng: 18.5207786 },
    { lat: 48.114346, lng: 18.5206691 },
    { lat: 48.1156567, lng: 18.5224425 },
    { lat: 48.1170918, lng: 18.5241586 },
    { lat: 48.1180697, lng: 18.525929 },
    { lat: 48.1182544, lng: 18.5263525 },
    { lat: 48.1205602, lng: 18.5299864 },
    { lat: 48.1203145, lng: 18.530237 },
    { lat: 48.1238925, lng: 18.5369114 },
    { lat: 48.1217663, lng: 18.5404113 },
    { lat: 48.1207205, lng: 18.5417773 },
    { lat: 48.1201913, lng: 18.5427377 },
    { lat: 48.1184223, lng: 18.5472091 },
    { lat: 48.1173554, lng: 18.549669 },
    { lat: 48.1176535, lng: 18.5499603 },
    { lat: 48.1210795, lng: 18.5534672 },
    { lat: 48.1232611, lng: 18.5555985 },
    { lat: 48.124419, lng: 18.5563704 },
    { lat: 48.1250842, lng: 18.5570595 },
    { lat: 48.1270118, lng: 18.5596841 },
    { lat: 48.1276171, lng: 18.5606807 },
    { lat: 48.1278243, lng: 18.5611249 },
    { lat: 48.1281487, lng: 18.5616097 },
    { lat: 48.1286232, lng: 18.5621574 },
    { lat: 48.1285764, lng: 18.5624534 },
    { lat: 48.1283686, lng: 18.5630632 },
    { lat: 48.1283928, lng: 18.5630963 },
    { lat: 48.1284114, lng: 18.5630421 },
    { lat: 48.1304465, lng: 18.5661468 },
    { lat: 48.1327717, lng: 18.5693148 },
    { lat: 48.1328725, lng: 18.5693865 },
    { lat: 48.1338898, lng: 18.5676174 },
    { lat: 48.1352556, lng: 18.5649026 },
    { lat: 48.1357477, lng: 18.5640453 },
    { lat: 48.1367588, lng: 18.5625335 },
    { lat: 48.1380706, lng: 18.5608689 },
    { lat: 48.1414558, lng: 18.5548198 },
    { lat: 48.1417601, lng: 18.5553065 },
    { lat: 48.1445696, lng: 18.5592001 },
    { lat: 48.1453816, lng: 18.5577187 },
    { lat: 48.1458888, lng: 18.5570542 },
    { lat: 48.147073, lng: 18.5552279 },
    { lat: 48.1479647, lng: 18.5540707 },
    { lat: 48.1482207, lng: 18.5536571 },
    { lat: 48.149423, lng: 18.5510319 },
    { lat: 48.1495745, lng: 18.5506487 },
    { lat: 48.1491109, lng: 18.5500727 },
    { lat: 48.1491229, lng: 18.5496972 },
    { lat: 48.1492453, lng: 18.5492162 },
    { lat: 48.1502205, lng: 18.5471596 },
    { lat: 48.1504896, lng: 18.5460841 },
    { lat: 48.1507864, lng: 18.5451684 },
    { lat: 48.1514353, lng: 18.5435558 },
    { lat: 48.1538787, lng: 18.5392986 },
    { lat: 48.1550195, lng: 18.5367311 },
    { lat: 48.1549683, lng: 18.5366823 },
    { lat: 48.1545273, lng: 18.5362128 },
    { lat: 48.1534082, lng: 18.5347589 },
    { lat: 48.1529025, lng: 18.5332612 },
    { lat: 48.1514096, lng: 18.5318641 },
    { lat: 48.1480209, lng: 18.528163 },
    { lat: 48.14737, lng: 18.5275528 },
    { lat: 48.1468492, lng: 18.5271709 },
    { lat: 48.147079, lng: 18.5251441 },
    { lat: 48.1481507, lng: 18.5207568 },
    { lat: 48.1482774, lng: 18.5203986 },
    { lat: 48.1484234, lng: 18.5201619 },
    { lat: 48.1486326, lng: 18.5199047 },
    { lat: 48.1493174, lng: 18.5192779 },
    { lat: 48.1493797, lng: 18.5190575 },
    { lat: 48.1494936, lng: 18.5190357 },
    { lat: 48.1496078, lng: 18.5185313 },
    { lat: 48.1497046, lng: 18.5185847 },
    { lat: 48.1498734, lng: 18.5180308 },
    { lat: 48.1499292, lng: 18.5176936 },
    { lat: 48.1479505, lng: 18.5159614 },
    { lat: 48.148911, lng: 18.5078027 },
    { lat: 48.1480743, lng: 18.5062616 },
    { lat: 48.1476622, lng: 18.5052148 },
    { lat: 48.1475253, lng: 18.5038902 },
    { lat: 48.1476019, lng: 18.5021598 },
    { lat: 48.1480561, lng: 18.5006065 },
    { lat: 48.1486698, lng: 18.4996707 },
    { lat: 48.1503327, lng: 18.496338 },
    { lat: 48.1512597, lng: 18.494609 },
    { lat: 48.1514835, lng: 18.4940503 },
    { lat: 48.1517803, lng: 18.4921415 },
    { lat: 48.1491637, lng: 18.4892499 },
    { lat: 48.1470926, lng: 18.4867352 },
    { lat: 48.146199, lng: 18.4854944 },
    { lat: 48.1446018, lng: 18.4829225 },
    { lat: 48.1444147, lng: 18.4826434 },
    { lat: 48.1443978, lng: 18.4826836 },
    { lat: 48.1443616, lng: 18.4826415 },
    { lat: 48.1445978, lng: 18.4819417 },
    { lat: 48.145047, lng: 18.4802007 },
    { lat: 48.1454833, lng: 18.4793256 },
  ],
  Kubáňovo: [
    { lat: 48.0720548, lng: 18.7634654 },
    { lat: 48.071951, lng: 18.7634019 },
    { lat: 48.0708561, lng: 18.7626445 },
    { lat: 48.0690985, lng: 18.7641162 },
    { lat: 48.0678147, lng: 18.7652725 },
    { lat: 48.0666278, lng: 18.7667487 },
    { lat: 48.0650528, lng: 18.7696599 },
    { lat: 48.0652573, lng: 18.7725729 },
    { lat: 48.0650598, lng: 18.774138 },
    { lat: 48.0636379, lng: 18.7745249 },
    { lat: 48.0626399, lng: 18.7746937 },
    { lat: 48.0622553, lng: 18.7746302 },
    { lat: 48.0612772, lng: 18.7747502 },
    { lat: 48.0604274, lng: 18.7750175 },
    { lat: 48.059632, lng: 18.7753509 },
    { lat: 48.0578779, lng: 18.7759148 },
    { lat: 48.0556856, lng: 18.7768921 },
    { lat: 48.0548626, lng: 18.7773214 },
    { lat: 48.0545202, lng: 18.7776689 },
    { lat: 48.0540115, lng: 18.7778352 },
    { lat: 48.0537028, lng: 18.7821314 },
    { lat: 48.0531122, lng: 18.7818822 },
    { lat: 48.0529105, lng: 18.7829701 },
    { lat: 48.0527453, lng: 18.7834055 },
    { lat: 48.0518875, lng: 18.7851113 },
    { lat: 48.051676, lng: 18.7858648 },
    { lat: 48.051363, lng: 18.7876838 },
    { lat: 48.0509907, lng: 18.7893395 },
    { lat: 48.0508966, lng: 18.7899033 },
    { lat: 48.0508837, lng: 18.7902752 },
    { lat: 48.0509584, lng: 18.7910482 },
    { lat: 48.0509406, lng: 18.7915804 },
    { lat: 48.0506933, lng: 18.7925146 },
    { lat: 48.0504858, lng: 18.7938125 },
    { lat: 48.050049, lng: 18.7960393 },
    { lat: 48.04986, lng: 18.7976868 },
    { lat: 48.0497597, lng: 18.7994122 },
    { lat: 48.0495148, lng: 18.8006873 },
    { lat: 48.0484232, lng: 18.8007703 },
    { lat: 48.0480232, lng: 18.8008833 },
    { lat: 48.0467511, lng: 18.8008217 },
    { lat: 48.0462047, lng: 18.8009815 },
    { lat: 48.0458091, lng: 18.8013252 },
    { lat: 48.0457131, lng: 18.8015384 },
    { lat: 48.0453731, lng: 18.8027615 },
    { lat: 48.0452048, lng: 18.8030964 },
    { lat: 48.0445357, lng: 18.8041798 },
    { lat: 48.0439441, lng: 18.8050177 },
    { lat: 48.0432364, lng: 18.8063901 },
    { lat: 48.04317, lng: 18.8069487 },
    { lat: 48.0432586, lng: 18.8073629 },
    { lat: 48.0434619, lng: 18.807776 },
    { lat: 48.0435073, lng: 18.8081431 },
    { lat: 48.0434514, lng: 18.8084316 },
    { lat: 48.0431706, lng: 18.808792 },
    { lat: 48.0423849, lng: 18.8089936 },
    { lat: 48.0412999, lng: 18.8093717 },
    { lat: 48.0409118, lng: 18.8096478 },
    { lat: 48.040761, lng: 18.8098259 },
    { lat: 48.0405711, lng: 18.8100906 },
    { lat: 48.0400084, lng: 18.8113742 },
    { lat: 48.0397959, lng: 18.8116631 },
    { lat: 48.0394071, lng: 18.8119872 },
    { lat: 48.0392573, lng: 18.8122486 },
    { lat: 48.0390923, lng: 18.813007 },
    { lat: 48.0390281, lng: 18.8136304 },
    { lat: 48.0390505, lng: 18.8149259 },
    { lat: 48.0388923, lng: 18.8154562 },
    { lat: 48.0386922, lng: 18.815745 },
    { lat: 48.0381848, lng: 18.8161957 },
    { lat: 48.03771, lng: 18.8169643 },
    { lat: 48.0371572, lng: 18.8172578 },
    { lat: 48.0369659, lng: 18.817416 },
    { lat: 48.0368498, lng: 18.8176079 },
    { lat: 48.0368229, lng: 18.8178059 },
    { lat: 48.0368593, lng: 18.8179803 },
    { lat: 48.0371219, lng: 18.8183849 },
    { lat: 48.0372659, lng: 18.8187176 },
    { lat: 48.0376311, lng: 18.8196137 },
    { lat: 48.0377164, lng: 18.8200301 },
    { lat: 48.038537, lng: 18.820696 },
    { lat: 48.039217, lng: 18.821263 },
    { lat: 48.039676, lng: 18.819998 },
    { lat: 48.042571, lng: 18.821997 },
    { lat: 48.043125, lng: 18.822878 },
    { lat: 48.042448, lng: 18.825698 },
    { lat: 48.0415425, lng: 18.8291252 },
    { lat: 48.048239, lng: 18.833268 },
    { lat: 48.046689, lng: 18.837923 },
    { lat: 48.0506, lng: 18.840321 },
    { lat: 48.052572, lng: 18.845585 },
    { lat: 48.0529834, lng: 18.8445148 },
    { lat: 48.0534531, lng: 18.8434744 },
    { lat: 48.0538391, lng: 18.8429418 },
    { lat: 48.0540967, lng: 18.8424151 },
    { lat: 48.0545767, lng: 18.8408849 },
    { lat: 48.0551684, lng: 18.8394019 },
    { lat: 48.0555532, lng: 18.8382886 },
    { lat: 48.0555062, lng: 18.8381457 },
    { lat: 48.0553614, lng: 18.8380072 },
    { lat: 48.0544748, lng: 18.8373394 },
    { lat: 48.054295, lng: 18.8370964 },
    { lat: 48.0540834, lng: 18.8368026 },
    { lat: 48.054013, lng: 18.8364309 },
    { lat: 48.0540434, lng: 18.836213 },
    { lat: 48.0543631, lng: 18.8361368 },
    { lat: 48.0552505, lng: 18.83466 },
    { lat: 48.0554825, lng: 18.8343939 },
    { lat: 48.0556784, lng: 18.8343272 },
    { lat: 48.0558338, lng: 18.8344278 },
    { lat: 48.0560283, lng: 18.8348026 },
    { lat: 48.0560848, lng: 18.8350854 },
    { lat: 48.0562214, lng: 18.8349996 },
    { lat: 48.0568954, lng: 18.8339419 },
    { lat: 48.0570479, lng: 18.8338852 },
    { lat: 48.0575115, lng: 18.8339518 },
    { lat: 48.0577167, lng: 18.834078 },
    { lat: 48.0580701, lng: 18.8340859 },
    { lat: 48.0582587, lng: 18.8339644 },
    { lat: 48.0585509, lng: 18.8331723 },
    { lat: 48.0581433, lng: 18.8323061 },
    { lat: 48.0582218, lng: 18.8312489 },
    { lat: 48.0588009, lng: 18.831131 },
    { lat: 48.0589775, lng: 18.8306284 },
    { lat: 48.0591174, lng: 18.8278925 },
    { lat: 48.0593627, lng: 18.8245654 },
    { lat: 48.0587085, lng: 18.8235775 },
    { lat: 48.0586774, lng: 18.8230119 },
    { lat: 48.0587625, lng: 18.8225059 },
    { lat: 48.0591149, lng: 18.8223042 },
    { lat: 48.0592533, lng: 18.8220201 },
    { lat: 48.0596801, lng: 18.8219672 },
    { lat: 48.0596859, lng: 18.8218174 },
    { lat: 48.0611655, lng: 18.8219719 },
    { lat: 48.0620069, lng: 18.8213507 },
    { lat: 48.0620203, lng: 18.820858 },
    { lat: 48.0620273, lng: 18.8206041 },
    { lat: 48.0616143, lng: 18.8202158 },
    { lat: 48.0617717, lng: 18.818604 },
    { lat: 48.0656049, lng: 18.8174945 },
    { lat: 48.0659244, lng: 18.8181871 },
    { lat: 48.0665601, lng: 18.8180088 },
    { lat: 48.0675693, lng: 18.8190565 },
    { lat: 48.0678432, lng: 18.8194601 },
    { lat: 48.0680774, lng: 18.8190254 },
    { lat: 48.0687077, lng: 18.8191513 },
    { lat: 48.0691902, lng: 18.8188052 },
    { lat: 48.0694723, lng: 18.8189159 },
    { lat: 48.0698479, lng: 18.8195822 },
    { lat: 48.0700316, lng: 18.8196417 },
    { lat: 48.0702828, lng: 18.8192914 },
    { lat: 48.0704272, lng: 18.8193153 },
    { lat: 48.0707415, lng: 18.8194406 },
    { lat: 48.071224, lng: 18.8198649 },
    { lat: 48.0718786, lng: 18.8186329 },
    { lat: 48.0714576, lng: 18.8173012 },
    { lat: 48.0712794, lng: 18.8163818 },
    { lat: 48.0713832, lng: 18.8161919 },
    { lat: 48.0718742, lng: 18.815666 },
    { lat: 48.0722503, lng: 18.8145227 },
    { lat: 48.0723119, lng: 18.8137246 },
    { lat: 48.0723347, lng: 18.812491 },
    { lat: 48.0726129, lng: 18.8121625 },
    { lat: 48.0726022, lng: 18.8116817 },
    { lat: 48.0721998, lng: 18.8112171 },
    { lat: 48.0727483, lng: 18.810904 },
    { lat: 48.0729635, lng: 18.8102405 },
    { lat: 48.0734246, lng: 18.8094687 },
    { lat: 48.0736464, lng: 18.8084384 },
    { lat: 48.0728788, lng: 18.8081232 },
    { lat: 48.072918, lng: 18.8075643 },
    { lat: 48.0724683, lng: 18.8073372 },
    { lat: 48.0726992, lng: 18.8068802 },
    { lat: 48.0731573, lng: 18.8052653 },
    { lat: 48.073882, lng: 18.8052773 },
    { lat: 48.0741564, lng: 18.8045176 },
    { lat: 48.0744197, lng: 18.8032895 },
    { lat: 48.0734205, lng: 18.8004357 },
    { lat: 48.0728238, lng: 18.7993674 },
    { lat: 48.0725734, lng: 18.7984901 },
    { lat: 48.0724536, lng: 18.79525 },
    { lat: 48.0721007, lng: 18.7915244 },
    { lat: 48.071338, lng: 18.7903274 },
    { lat: 48.0713703, lng: 18.7867027 },
    { lat: 48.0712309, lng: 18.7841428 },
    { lat: 48.0714595, lng: 18.782692 },
    { lat: 48.0716956, lng: 18.7816775 },
    { lat: 48.0715461, lng: 18.7791374 },
    { lat: 48.071404, lng: 18.7783086 },
    { lat: 48.0713393, lng: 18.7771857 },
    { lat: 48.0712745, lng: 18.7760627 },
    { lat: 48.0713238, lng: 18.775144 },
    { lat: 48.071792, lng: 18.7731975 },
    { lat: 48.0718496, lng: 18.7724534 },
    { lat: 48.0719403, lng: 18.770747 },
    { lat: 48.0719048, lng: 18.7699726 },
    { lat: 48.0717552, lng: 18.7691941 },
    { lat: 48.0718597, lng: 18.7671532 },
    { lat: 48.0721797, lng: 18.7656876 },
    { lat: 48.0722398, lng: 18.7640813 },
    { lat: 48.0720548, lng: 18.7634654 },
  ],
  Krškany: [
    { lat: 48.2153558, lng: 18.7081997 },
    { lat: 48.2163985, lng: 18.7073306 },
    { lat: 48.2177108, lng: 18.7062275 },
    { lat: 48.2180863, lng: 18.706204 },
    { lat: 48.2184417, lng: 18.7074062 },
    { lat: 48.219654, lng: 18.7074435 },
    { lat: 48.2216796, lng: 18.7076775 },
    { lat: 48.2227259, lng: 18.7080259 },
    { lat: 48.2257631, lng: 18.7083451 },
    { lat: 48.2269706, lng: 18.7086933 },
    { lat: 48.2272712, lng: 18.7088694 },
    { lat: 48.2280666, lng: 18.7095741 },
    { lat: 48.2288428, lng: 18.710061 },
    { lat: 48.2316485, lng: 18.7112105 },
    { lat: 48.232337, lng: 18.7089474 },
    { lat: 48.2324433, lng: 18.7089732 },
    { lat: 48.2325884, lng: 18.7088628 },
    { lat: 48.2328985, lng: 18.7084615 },
    { lat: 48.2330977, lng: 18.7073371 },
    { lat: 48.2330982, lng: 18.7065875 },
    { lat: 48.2331404, lng: 18.7061975 },
    { lat: 48.2333453, lng: 18.7053908 },
    { lat: 48.2334501, lng: 18.7047393 },
    { lat: 48.233488, lng: 18.7040754 },
    { lat: 48.2336499, lng: 18.7036254 },
    { lat: 48.2337113, lng: 18.7029813 },
    { lat: 48.2334835, lng: 18.7008398 },
    { lat: 48.2332424, lng: 18.6991177 },
    { lat: 48.2329958, lng: 18.6981154 },
    { lat: 48.2331086, lng: 18.6975302 },
    { lat: 48.2331913, lng: 18.6965488 },
    { lat: 48.2328934, lng: 18.6957884 },
    { lat: 48.2334288, lng: 18.6932816 },
    { lat: 48.2334569, lng: 18.6924855 },
    { lat: 48.2331628, lng: 18.6905558 },
    { lat: 48.2331129, lng: 18.6896933 },
    { lat: 48.2332603, lng: 18.6890324 },
    { lat: 48.233146, lng: 18.6878112 },
    { lat: 48.2334341, lng: 18.6870209 },
    { lat: 48.233545, lng: 18.6857297 },
    { lat: 48.233837, lng: 18.6840085 },
    { lat: 48.2340029, lng: 18.6833571 },
    { lat: 48.2343004, lng: 18.6828744 },
    { lat: 48.2346377, lng: 18.6827248 },
    { lat: 48.2347943, lng: 18.6826613 },
    { lat: 48.2351366, lng: 18.6825228 },
    { lat: 48.235629, lng: 18.6832704 },
    { lat: 48.2376486, lng: 18.6813521 },
    { lat: 48.2378645, lng: 18.6810463 },
    { lat: 48.238701, lng: 18.6793448 },
    { lat: 48.2394917, lng: 18.6780688 },
    { lat: 48.2415693, lng: 18.6753014 },
    { lat: 48.2428962, lng: 18.6722363 },
    { lat: 48.2432003, lng: 18.6718695 },
    { lat: 48.24366, lng: 18.6715497 },
    { lat: 48.2443213, lng: 18.6704667 },
    { lat: 48.2447945, lng: 18.6698094 },
    { lat: 48.2450973, lng: 18.669253 },
    { lat: 48.2451983, lng: 18.6691184 },
    { lat: 48.2451891, lng: 18.6691142 },
    { lat: 48.2458532, lng: 18.6663694 },
    { lat: 48.2460595, lng: 18.6651127 },
    { lat: 48.2459684, lng: 18.6624026 },
    { lat: 48.2460319, lng: 18.6594582 },
    { lat: 48.2461533, lng: 18.6588668 },
    { lat: 48.2462516, lng: 18.655277 },
    { lat: 48.2463953, lng: 18.6545168 },
    { lat: 48.2464376, lng: 18.6538783 },
    { lat: 48.2462861, lng: 18.653287 },
    { lat: 48.2456192, lng: 18.6513218 },
    { lat: 48.2456082, lng: 18.6512842 },
    { lat: 48.2376085, lng: 18.638147 },
    { lat: 48.236586, lng: 18.6374716 },
    { lat: 48.2357238, lng: 18.6366695 },
    { lat: 48.2351813, lng: 18.6362528 },
    { lat: 48.2345285, lng: 18.6370298 },
    { lat: 48.2341889, lng: 18.6375061 },
    { lat: 48.2334515, lng: 18.6390504 },
    { lat: 48.2332063, lng: 18.6394159 },
    { lat: 48.2318803, lng: 18.6412774 },
    { lat: 48.2312577, lng: 18.641861 },
    { lat: 48.2311761, lng: 18.6425412 },
    { lat: 48.2295458, lng: 18.645119 },
    { lat: 48.230098, lng: 18.6464214 },
    { lat: 48.2304149, lng: 18.6470435 },
    { lat: 48.2308699, lng: 18.6477213 },
    { lat: 48.2305354, lng: 18.6485854 },
    { lat: 48.2288564, lng: 18.6515759 },
    { lat: 48.2281997, lng: 18.6514461 },
    { lat: 48.2246079, lng: 18.6532698 },
    { lat: 48.2230518, lng: 18.6533401 },
    { lat: 48.2228879, lng: 18.6535831 },
    { lat: 48.2219042, lng: 18.6538494 },
    { lat: 48.2215517, lng: 18.6540714 },
    { lat: 48.2204083, lng: 18.6545575 },
    { lat: 48.220109, lng: 18.6548183 },
    { lat: 48.219626, lng: 18.6551696 },
    { lat: 48.219216, lng: 18.6549702 },
    { lat: 48.2187019, lng: 18.6550169 },
    { lat: 48.2182958, lng: 18.6552867 },
    { lat: 48.2182816, lng: 18.6552959 },
    { lat: 48.2175721, lng: 18.6557124 },
    { lat: 48.2174688, lng: 18.65593 },
    { lat: 48.2168222, lng: 18.6563598 },
    { lat: 48.2167329, lng: 18.6565262 },
    { lat: 48.2164453, lng: 18.656694 },
    { lat: 48.215797, lng: 18.6573627 },
    { lat: 48.2151674, lng: 18.6572762 },
    { lat: 48.2148993, lng: 18.6576983 },
    { lat: 48.2144465, lng: 18.6579402 },
    { lat: 48.2142055, lng: 18.6584708 },
    { lat: 48.2140159, lng: 18.6592834 },
    { lat: 48.2139027, lng: 18.6595849 },
    { lat: 48.213384, lng: 18.6604872 },
    { lat: 48.2131503, lng: 18.6613804 },
    { lat: 48.2127636, lng: 18.662343 },
    { lat: 48.2126442, lng: 18.6625781 },
    { lat: 48.2122541, lng: 18.6629361 },
    { lat: 48.211546, lng: 18.6638587 },
    { lat: 48.2112247, lng: 18.6640892 },
    { lat: 48.2075018, lng: 18.6630077 },
    { lat: 48.2070531, lng: 18.6641789 },
    { lat: 48.2070364, lng: 18.664456 },
    { lat: 48.2059281, lng: 18.6648632 },
    { lat: 48.2053577, lng: 18.6648875 },
    { lat: 48.2045593, lng: 18.6654564 },
    { lat: 48.2023659, lng: 18.6736153 },
    { lat: 48.2004267, lng: 18.6722265 },
    { lat: 48.1994123, lng: 18.6750223 },
    { lat: 48.1993399, lng: 18.6750607 },
    { lat: 48.1991794, lng: 18.676788 },
    { lat: 48.1989252, lng: 18.6795238 },
    { lat: 48.1987129, lng: 18.6809429 },
    { lat: 48.1982598, lng: 18.6830857 },
    { lat: 48.1979499, lng: 18.6848719 },
    { lat: 48.1975114, lng: 18.6864066 },
    { lat: 48.1968029, lng: 18.6895504 },
    { lat: 48.1958322, lng: 18.692996 },
    { lat: 48.1959177, lng: 18.6929727 },
    { lat: 48.1957421, lng: 18.6935442 },
    { lat: 48.1969197, lng: 18.6936695 },
    { lat: 48.1973768, lng: 18.6935735 },
    { lat: 48.1986041, lng: 18.6928614 },
    { lat: 48.1987249, lng: 18.6930544 },
    { lat: 48.1992646, lng: 18.6933169 },
    { lat: 48.1992671, lng: 18.6938698 },
    { lat: 48.2000315, lng: 18.694346 },
    { lat: 48.200303, lng: 18.6946128 },
    { lat: 48.200592, lng: 18.6950787 },
    { lat: 48.2008747, lng: 18.6956998 },
    { lat: 48.2026513, lng: 18.6950981 },
    { lat: 48.2034027, lng: 18.6946404 },
    { lat: 48.2040985, lng: 18.6957716 },
    { lat: 48.2048694, lng: 18.6968764 },
    { lat: 48.2053553, lng: 18.6975453 },
    { lat: 48.2066342, lng: 18.6990742 },
    { lat: 48.2069637, lng: 18.699646 },
    { lat: 48.2075228, lng: 18.7011331 },
    { lat: 48.2076676, lng: 18.7019381 },
    { lat: 48.2076102, lng: 18.7032253 },
    { lat: 48.2078398, lng: 18.7038225 },
    { lat: 48.2085867, lng: 18.7041229 },
    { lat: 48.2088291, lng: 18.7042896 },
    { lat: 48.2092648, lng: 18.7050315 },
    { lat: 48.209391, lng: 18.7051546 },
    { lat: 48.2099368, lng: 18.7054303 },
    { lat: 48.2103728, lng: 18.7060109 },
    { lat: 48.2105194, lng: 18.7061362 },
    { lat: 48.2120561, lng: 18.7068205 },
    { lat: 48.2122882, lng: 18.7068563 },
    { lat: 48.2126827, lng: 18.7071028 },
    { lat: 48.2133044, lng: 18.7072772 },
    { lat: 48.2137001, lng: 18.7075706 },
    { lat: 48.2142791, lng: 18.7078307 },
    { lat: 48.2153653, lng: 18.708148 },
    { lat: 48.2153558, lng: 18.7081997 },
  ],
  Nýrovce: [
    { lat: 48.0051718, lng: 18.5584386 },
    { lat: 48.0051681, lng: 18.5584528 },
    { lat: 48.0040268, lng: 18.5621601 },
    { lat: 48.0014231, lng: 18.5730775 },
    { lat: 48.0010107, lng: 18.5746634 },
    { lat: 48.0007051, lng: 18.5760623 },
    { lat: 47.9984638, lng: 18.5850739 },
    { lat: 47.9978902, lng: 18.5876326 },
    { lat: 47.997912, lng: 18.5876595 },
    { lat: 47.9980422, lng: 18.5878525 },
    { lat: 47.9990665, lng: 18.5885528 },
    { lat: 48.0003038, lng: 18.5898862 },
    { lat: 48.001759, lng: 18.5912682 },
    { lat: 48.0047542, lng: 18.594919 },
    { lat: 48.0059938, lng: 18.5961946 },
    { lat: 48.0083028, lng: 18.5995111 },
    { lat: 48.0090434, lng: 18.6003238 },
    { lat: 48.0097033, lng: 18.6009452 },
    { lat: 48.0087841, lng: 18.6036875 },
    { lat: 48.0084248, lng: 18.6044173 },
    { lat: 48.0095682, lng: 18.6052135 },
    { lat: 48.0113563, lng: 18.6067484 },
    { lat: 48.0114168, lng: 18.6068217 },
    { lat: 48.0115229, lng: 18.6066179 },
    { lat: 48.0122553, lng: 18.6032649 },
    { lat: 48.0124686, lng: 18.6026479 },
    { lat: 48.0211816, lng: 18.5894623 },
    { lat: 48.021682, lng: 18.5887728 },
    { lat: 48.0243646, lng: 18.59281 },
    { lat: 48.0290798, lng: 18.5857816 },
    { lat: 48.0302712, lng: 18.5866512 },
    { lat: 48.0318435, lng: 18.5885373 },
    { lat: 48.0319841, lng: 18.5885321 },
    { lat: 48.0323727, lng: 18.5883558 },
    { lat: 48.0326518, lng: 18.5882009 },
    { lat: 48.0330944, lng: 18.5877579 },
    { lat: 48.0337991, lng: 18.586743 },
    { lat: 48.0349972, lng: 18.5853293 },
    { lat: 48.0353862, lng: 18.5844621 },
    { lat: 48.0356221, lng: 18.5840534 },
    { lat: 48.0365117, lng: 18.5828375 },
    { lat: 48.0374043, lng: 18.5813834 },
    { lat: 48.0355325, lng: 18.5787223 },
    { lat: 48.0344175, lng: 18.5772369 },
    { lat: 48.031509, lng: 18.5739637 },
    { lat: 48.0341867, lng: 18.5699168 },
    { lat: 48.0313084, lng: 18.5656205 },
    { lat: 48.0333969, lng: 18.5625961 },
    { lat: 48.0341189, lng: 18.5621912 },
    { lat: 48.0346215, lng: 18.5616078 },
    { lat: 48.0354626, lng: 18.5611679 },
    { lat: 48.0359354, lng: 18.5608041 },
    { lat: 48.0368537, lng: 18.56044 },
    { lat: 48.0371773, lng: 18.5600444 },
    { lat: 48.0373439, lng: 18.5595898 },
    { lat: 48.0374472, lng: 18.559439 },
    { lat: 48.0377262, lng: 18.5594231 },
    { lat: 48.0378721, lng: 18.559309 },
    { lat: 48.0382824, lng: 18.5587277 },
    { lat: 48.0386866, lng: 18.5585093 },
    { lat: 48.0389115, lng: 18.5582713 },
    { lat: 48.0391227, lng: 18.5577453 },
    { lat: 48.0401407, lng: 18.5565865 },
    { lat: 48.040975, lng: 18.5554533 },
    { lat: 48.0411892, lng: 18.5549216 },
    { lat: 48.0405409, lng: 18.554251 },
    { lat: 48.0400971, lng: 18.5536258 },
    { lat: 48.0383311, lng: 18.5506896 },
    { lat: 48.0371379, lng: 18.5488954 },
    { lat: 48.0376704, lng: 18.5483257 },
    { lat: 48.0373568, lng: 18.5477742 },
    { lat: 48.0353647, lng: 18.5448516 },
    { lat: 48.0336661, lng: 18.5425272 },
    { lat: 48.0321283, lng: 18.5406255 },
    { lat: 48.0306987, lng: 18.5391501 },
    { lat: 48.0290651, lng: 18.5370211 },
    { lat: 48.0282315, lng: 18.5362278 },
    { lat: 48.0265769, lng: 18.5399651 },
    { lat: 48.0256676, lng: 18.5436579 },
    { lat: 48.0243895, lng: 18.543127 },
    { lat: 48.0224041, lng: 18.5420649 },
    { lat: 48.019885, lng: 18.5405509 },
    { lat: 48.0179128, lng: 18.5391932 },
    { lat: 48.0169059, lng: 18.5421403 },
    { lat: 48.0165014, lng: 18.5431697 },
    { lat: 48.0130321, lng: 18.5503254 },
    { lat: 48.011649, lng: 18.5517342 },
    { lat: 48.0107412, lng: 18.5527589 },
    { lat: 48.0095407, lng: 18.5536549 },
    { lat: 48.0072766, lng: 18.5551024 },
    { lat: 48.006546, lng: 18.5561144 },
    { lat: 48.0060189, lng: 18.5570052 },
    { lat: 48.0051718, lng: 18.5584386 },
  ],
  HornéTurovce: [
    { lat: 48.1323875, lng: 18.9240564 },
    { lat: 48.1319883, lng: 18.9239691 },
    { lat: 48.1313868, lng: 18.9237417 },
    { lat: 48.1311633, lng: 18.9235413 },
    { lat: 48.1300764, lng: 18.9230626 },
    { lat: 48.1298844, lng: 18.9230597 },
    { lat: 48.1285917, lng: 18.922613 },
    { lat: 48.1279031, lng: 18.9249535 },
    { lat: 48.1273296, lng: 18.9251348 },
    { lat: 48.1272147, lng: 18.9252448 },
    { lat: 48.1268079, lng: 18.9261455 },
    { lat: 48.1261626, lng: 18.9272037 },
    { lat: 48.1259057, lng: 18.9275139 },
    { lat: 48.1254315, lng: 18.9279156 },
    { lat: 48.1251915, lng: 18.9283548 },
    { lat: 48.1252286, lng: 18.9283746 },
    { lat: 48.1251285, lng: 18.9287277 },
    { lat: 48.125012, lng: 18.9290465 },
    { lat: 48.1249187, lng: 18.9291617 },
    { lat: 48.1248453, lng: 18.9293774 },
    { lat: 48.1247218, lng: 18.9297406 },
    { lat: 48.1240308, lng: 18.9324662 },
    { lat: 48.1237828, lng: 18.932751 },
    { lat: 48.1236252, lng: 18.9332584 },
    { lat: 48.1234749, lng: 18.9334906 },
    { lat: 48.1232602, lng: 18.9341185 },
    { lat: 48.123322, lng: 18.9343958 },
    { lat: 48.1227876, lng: 18.9364507 },
    { lat: 48.1222722, lng: 18.937693 },
    { lat: 48.122156, lng: 18.9382569 },
    { lat: 48.1219473, lng: 18.9385612 },
    { lat: 48.12168, lng: 18.9396084 },
    { lat: 48.1216262, lng: 18.9400719 },
    { lat: 48.1212634, lng: 18.9407556 },
    { lat: 48.1211537, lng: 18.9408551 },
    { lat: 48.1209811, lng: 18.9404631 },
    { lat: 48.1208508, lng: 18.940362 },
    { lat: 48.1206172, lng: 18.9403395 },
    { lat: 48.1203745, lng: 18.940485 },
    { lat: 48.1198283, lng: 18.9410462 },
    { lat: 48.1197467, lng: 18.9408845 },
    { lat: 48.1150109, lng: 18.9381123 },
    { lat: 48.1132689, lng: 18.942919 },
    { lat: 48.1132573, lng: 18.9431508 },
    { lat: 48.1139866, lng: 18.9426219 },
    { lat: 48.1144101, lng: 18.9425234 },
    { lat: 48.1145821, lng: 18.9426694 },
    { lat: 48.1148854, lng: 18.9431909 },
    { lat: 48.1152004, lng: 18.9435703 },
    { lat: 48.11536, lng: 18.9439341 },
    { lat: 48.1155416, lng: 18.9447198 },
    { lat: 48.1156296, lng: 18.9448554 },
    { lat: 48.1157915, lng: 18.9448657 },
    { lat: 48.1158869, lng: 18.9447489 },
    { lat: 48.116232, lng: 18.9449661 },
    { lat: 48.1160074, lng: 18.9476712 },
    { lat: 48.1160784, lng: 18.9478708 },
    { lat: 48.1160938, lng: 18.9484298 },
    { lat: 48.1155306, lng: 18.9485055 },
    { lat: 48.1157546, lng: 18.9517355 },
    { lat: 48.1144651, lng: 18.9529896 },
    { lat: 48.1143157, lng: 18.9534199 },
    { lat: 48.1138677, lng: 18.9564963 },
    { lat: 48.1133172, lng: 18.9582461 },
    { lat: 48.1131111, lng: 18.960264 },
    { lat: 48.1131159, lng: 18.9607757 },
    { lat: 48.1127772, lng: 18.9620827 },
    { lat: 48.1119672, lng: 18.964832 },
    { lat: 48.1100423, lng: 18.9748376 },
    { lat: 48.1090259, lng: 18.9811568 },
    { lat: 48.108567, lng: 18.983597 },
    { lat: 48.1079704, lng: 18.986099 },
    { lat: 48.1080288, lng: 18.987017 },
    { lat: 48.1075524, lng: 18.990069 },
    { lat: 48.1073196, lng: 18.9914894 },
    { lat: 48.1075809, lng: 18.9918999 },
    { lat: 48.1086494, lng: 18.992687 },
    { lat: 48.1087092, lng: 18.9936147 },
    { lat: 48.109232, lng: 18.994508 },
    { lat: 48.1093518, lng: 18.9945137 },
    { lat: 48.1094544, lng: 18.9942526 },
    { lat: 48.1098755, lng: 18.9942151 },
    { lat: 48.110648, lng: 18.9946611 },
    { lat: 48.1112063, lng: 18.9948415 },
    { lat: 48.1115741, lng: 18.9948211 },
    { lat: 48.1130645, lng: 18.9943561 },
    { lat: 48.1136812, lng: 18.9940013 },
    { lat: 48.1140829, lng: 18.9936323 },
    { lat: 48.1145309, lng: 18.9933321 },
    { lat: 48.1152339, lng: 18.9954825 },
    { lat: 48.1162976, lng: 18.9953816 },
    { lat: 48.1179377, lng: 18.9957262 },
    { lat: 48.1191451, lng: 18.996448 },
    { lat: 48.1195644, lng: 18.9966906 },
    { lat: 48.119581, lng: 18.9967056 },
    { lat: 48.119605, lng: 18.9945285 },
    { lat: 48.1192806, lng: 18.9928383 },
    { lat: 48.1195231, lng: 18.9911461 },
    { lat: 48.1194712, lng: 18.9906157 },
    { lat: 48.1196512, lng: 18.9898831 },
    { lat: 48.1198164, lng: 18.9895837 },
    { lat: 48.1200547, lng: 18.9887548 },
    { lat: 48.1206764, lng: 18.9855502 },
    { lat: 48.12112, lng: 18.9841064 },
    { lat: 48.1213962, lng: 18.9843687 },
    { lat: 48.1216696, lng: 18.9841377 },
    { lat: 48.1217977, lng: 18.9844813 },
    { lat: 48.1220462, lng: 18.9841607 },
    { lat: 48.1221242, lng: 18.984144 },
    { lat: 48.122174, lng: 18.9845198 },
    { lat: 48.1225711, lng: 18.9844775 },
    { lat: 48.1224989, lng: 18.9850056 },
    { lat: 48.1229867, lng: 18.9849329 },
    { lat: 48.1228965, lng: 18.9854199 },
    { lat: 48.1235913, lng: 18.985818 },
    { lat: 48.1239856, lng: 18.985745 },
    { lat: 48.124129, lng: 18.9860106 },
    { lat: 48.1243092, lng: 18.9859628 },
    { lat: 48.1243948, lng: 18.9860296 },
    { lat: 48.1244637, lng: 18.985834 },
    { lat: 48.1248691, lng: 18.9863721 },
    { lat: 48.1249151, lng: 18.9863252 },
    { lat: 48.1250105, lng: 18.9859229 },
    { lat: 48.1250757, lng: 18.9862267 },
    { lat: 48.1252323, lng: 18.986333 },
    { lat: 48.1255288, lng: 18.9860225 },
    { lat: 48.125603, lng: 18.9860874 },
    { lat: 48.1258479, lng: 18.9860026 },
    { lat: 48.1258653, lng: 18.9861216 },
    { lat: 48.1259028, lng: 18.9861294 },
    { lat: 48.1261349, lng: 18.9858392 },
    { lat: 48.126164, lng: 18.9861394 },
    { lat: 48.1263627, lng: 18.9859928 },
    { lat: 48.1270663, lng: 18.9862783 },
    { lat: 48.1272313, lng: 18.9864649 },
    { lat: 48.1276623, lng: 18.986446 },
    { lat: 48.1281538, lng: 18.9867113 },
    { lat: 48.1283126, lng: 18.9870337 },
    { lat: 48.1284, lng: 18.987088 },
    { lat: 48.1284968, lng: 18.9870406 },
    { lat: 48.1285571, lng: 18.9871851 },
    { lat: 48.1286985, lng: 18.9871961 },
    { lat: 48.1288147, lng: 18.9870434 },
    { lat: 48.128967, lng: 18.9873743 },
    { lat: 48.1291701, lng: 18.9873529 },
    { lat: 48.129386, lng: 18.9877394 },
    { lat: 48.1297326, lng: 18.9876154 },
    { lat: 48.1301457, lng: 18.9865246 },
    { lat: 48.1301974, lng: 18.9860799 },
    { lat: 48.1301768, lng: 18.9836301 },
    { lat: 48.1317982, lng: 18.9816319 },
    { lat: 48.132124, lng: 18.9811238 },
    { lat: 48.1321915, lng: 18.9772063 },
    { lat: 48.1320461, lng: 18.977163 },
    { lat: 48.1327417, lng: 18.974548 },
    { lat: 48.1330554, lng: 18.9736304 },
    { lat: 48.1328641, lng: 18.973108 },
    { lat: 48.1329348, lng: 18.9729704 },
    { lat: 48.132986, lng: 18.9726927 },
    { lat: 48.1330264, lng: 18.9720309 },
    { lat: 48.1333987, lng: 18.9708936 },
    { lat: 48.1337773, lng: 18.9699876 },
    { lat: 48.1337328, lng: 18.9699599 },
    { lat: 48.1338168, lng: 18.9696806 },
    { lat: 48.1342871, lng: 18.9687403 },
    { lat: 48.1360942, lng: 18.9654861 },
    { lat: 48.136736, lng: 18.9641551 },
    { lat: 48.1374208, lng: 18.9625618 },
    { lat: 48.1374317, lng: 18.9623131 },
    { lat: 48.1379018, lng: 18.9612375 },
    { lat: 48.1408174, lng: 18.9571345 },
    { lat: 48.1410779, lng: 18.9568924 },
    { lat: 48.1414261, lng: 18.9550926 },
    { lat: 48.1413707, lng: 18.9549796 },
    { lat: 48.1417036, lng: 18.9545255 },
    { lat: 48.141926, lng: 18.9538069 },
    { lat: 48.141921, lng: 18.9535123 },
    { lat: 48.1420244, lng: 18.9528268 },
    { lat: 48.1421034, lng: 18.9526317 },
    { lat: 48.1421342, lng: 18.9521784 },
    { lat: 48.1421995, lng: 18.9519149 },
    { lat: 48.1435742, lng: 18.9476728 },
    { lat: 48.1438857, lng: 18.946301 },
    { lat: 48.1438473, lng: 18.9461928 },
    { lat: 48.1439764, lng: 18.9459364 },
    { lat: 48.1443417, lng: 18.9442329 },
    { lat: 48.144339, lng: 18.9436761 },
    { lat: 48.1449831, lng: 18.9416943 },
    { lat: 48.1456554, lng: 18.9394296 },
    { lat: 48.1457092, lng: 18.9394647 },
    { lat: 48.1456155, lng: 18.9386557 },
    { lat: 48.1453474, lng: 18.9372842 },
    { lat: 48.1452912, lng: 18.9354092 },
    { lat: 48.1451577, lng: 18.9348117 },
    { lat: 48.1450488, lng: 18.9339921 },
    { lat: 48.1449838, lng: 18.9324924 },
    { lat: 48.1449039, lng: 18.9318206 },
    { lat: 48.1448265, lng: 18.9314782 },
    { lat: 48.1446296, lng: 18.9311383 },
    { lat: 48.1442855, lng: 18.9307212 },
    { lat: 48.1403856, lng: 18.9265869 },
    { lat: 48.1402452, lng: 18.9263133 },
    { lat: 48.1383357, lng: 18.9263252 },
    { lat: 48.1376027, lng: 18.9264328 },
    { lat: 48.1365576, lng: 18.9261776 },
    { lat: 48.1352147, lng: 18.9255875 },
    { lat: 48.1338229, lng: 18.9251482 },
    { lat: 48.1332113, lng: 18.9248306 },
    { lat: 48.1328407, lng: 18.924382 },
    { lat: 48.1323875, lng: 18.9240564 },
  ],
  Hrkovce: [
    { lat: 48.102386, lng: 18.8804055 },
    { lat: 48.1023704, lng: 18.8803872 },
    { lat: 48.1021585, lng: 18.8804577 },
    { lat: 48.1020603, lng: 18.8803234 },
    { lat: 48.102032, lng: 18.8796532 },
    { lat: 48.102127, lng: 18.8794189 },
    { lat: 48.1021329, lng: 18.8790566 },
    { lat: 48.1019829, lng: 18.8787131 },
    { lat: 48.1016469, lng: 18.8788228 },
    { lat: 48.1015048, lng: 18.8791424 },
    { lat: 48.1008982, lng: 18.8789283 },
    { lat: 48.1006183, lng: 18.8790991 },
    { lat: 48.1005391, lng: 18.8790483 },
    { lat: 48.1003221, lng: 18.8784989 },
    { lat: 48.1001196, lng: 18.8784223 },
    { lat: 48.0999821, lng: 18.8785544 },
    { lat: 48.0998042, lng: 18.8792686 },
    { lat: 48.0996518, lng: 18.8794818 },
    { lat: 48.0997145, lng: 18.8802541 },
    { lat: 48.0994568, lng: 18.8805236 },
    { lat: 48.0993471, lng: 18.8805624 },
    { lat: 48.0992462, lng: 18.8804634 },
    { lat: 48.0989712, lng: 18.8798622 },
    { lat: 48.098872, lng: 18.8799027 },
    { lat: 48.0987875, lng: 18.8808152 },
    { lat: 48.0987442, lng: 18.8809137 },
    { lat: 48.0983536, lng: 18.881162 },
    { lat: 48.0982635, lng: 18.881297 },
    { lat: 48.0976473, lng: 18.880769 },
    { lat: 48.0974254, lng: 18.8809225 },
    { lat: 48.0967121, lng: 18.880955 },
    { lat: 48.0964461, lng: 18.8819528 },
    { lat: 48.0963167, lng: 18.882171 },
    { lat: 48.0961928, lng: 18.8820655 },
    { lat: 48.0959345, lng: 18.8816539 },
    { lat: 48.0956895, lng: 18.8819088 },
    { lat: 48.0954397, lng: 18.8819848 },
    { lat: 48.0952146, lng: 18.8821859 },
    { lat: 48.0951482, lng: 18.8822991 },
    { lat: 48.0951545, lng: 18.8827865 },
    { lat: 48.0952543, lng: 18.8829693 },
    { lat: 48.0953801, lng: 18.8829395 },
    { lat: 48.0954902, lng: 18.883169 },
    { lat: 48.0954757, lng: 18.8833213 },
    { lat: 48.0952175, lng: 18.8838215 },
    { lat: 48.0953164, lng: 18.884051 },
    { lat: 48.0953079, lng: 18.8842541 },
    { lat: 48.0951159, lng: 18.8842288 },
    { lat: 48.0949905, lng: 18.8840723 },
    { lat: 48.0949345, lng: 18.8838858 },
    { lat: 48.0948958, lng: 18.8833803 },
    { lat: 48.0948529, lng: 18.8832735 },
    { lat: 48.0946712, lng: 18.8831828 },
    { lat: 48.0945916, lng: 18.8830622 },
    { lat: 48.0944566, lng: 18.8825298 },
    { lat: 48.0943839, lng: 18.8824955 },
    { lat: 48.094298, lng: 18.8825785 },
    { lat: 48.0942033, lng: 18.8828389 },
    { lat: 48.0943165, lng: 18.8831051 },
    { lat: 48.0942844, lng: 18.8832923 },
    { lat: 48.0942162, lng: 18.8833263 },
    { lat: 48.0940347, lng: 18.8831534 },
    { lat: 48.0938416, lng: 18.8826496 },
    { lat: 48.0940324, lng: 18.8820034 },
    { lat: 48.0940188, lng: 18.8818964 },
    { lat: 48.0939211, lng: 18.8817553 },
    { lat: 48.0938175, lng: 18.8817124 },
    { lat: 48.0936197, lng: 18.8818105 },
    { lat: 48.0935704, lng: 18.8819044 },
    { lat: 48.093552, lng: 18.8821358 },
    { lat: 48.0934354, lng: 18.8824732 },
    { lat: 48.0934861, lng: 18.8827523 },
    { lat: 48.0934419, lng: 18.8828999 },
    { lat: 48.0933358, lng: 18.8830043 },
    { lat: 48.0932746, lng: 18.8830005 },
    { lat: 48.0930571, lng: 18.8825041 },
    { lat: 48.0930569, lng: 18.8822195 },
    { lat: 48.0931767, lng: 18.8818793 },
    { lat: 48.0931347, lng: 18.881614 },
    { lat: 48.0929398, lng: 18.8816377 },
    { lat: 48.0927738, lng: 18.8818521 },
    { lat: 48.0926198, lng: 18.8821437 },
    { lat: 48.0926642, lng: 18.8823879 },
    { lat: 48.0925222, lng: 18.8827374 },
    { lat: 48.0924555, lng: 18.8827081 },
    { lat: 48.0922829, lng: 18.8821271 },
    { lat: 48.0922457, lng: 18.8818034 },
    { lat: 48.0921533, lng: 18.8816713 },
    { lat: 48.0920534, lng: 18.8817447 },
    { lat: 48.0919625, lng: 18.881928 },
    { lat: 48.0919863, lng: 18.8826021 },
    { lat: 48.0920407, lng: 18.8828882 },
    { lat: 48.0921426, lng: 18.8830245 },
    { lat: 48.0921033, lng: 18.8832865 },
    { lat: 48.0919709, lng: 18.8837107 },
    { lat: 48.0916344, lng: 18.8841138 },
    { lat: 48.0915709, lng: 18.8841286 },
    { lat: 48.0915127, lng: 18.8840504 },
    { lat: 48.091421, lng: 18.883307 },
    { lat: 48.0913441, lng: 18.8832362 },
    { lat: 48.0910589, lng: 18.8832189 },
    { lat: 48.0909305, lng: 18.8829407 },
    { lat: 48.0908052, lng: 18.8829165 },
    { lat: 48.0907624, lng: 18.88298 },
    { lat: 48.0908157, lng: 18.8833526 },
    { lat: 48.0908082, lng: 18.8836843 },
    { lat: 48.0908813, lng: 18.8839185 },
    { lat: 48.0911266, lng: 18.8839552 },
    { lat: 48.0914163, lng: 18.8849007 },
    { lat: 48.0913552, lng: 18.885093 },
    { lat: 48.0912227, lng: 18.8851905 },
    { lat: 48.0910718, lng: 18.8851502 },
    { lat: 48.0907995, lng: 18.8848602 },
    { lat: 48.0906605, lng: 18.8845494 },
    { lat: 48.0905274, lng: 18.8845091 },
    { lat: 48.0904389, lng: 18.8845825 },
    { lat: 48.0903967, lng: 18.8847021 },
    { lat: 48.0903924, lng: 18.8850962 },
    { lat: 48.0905281, lng: 18.8853418 },
    { lat: 48.0905155, lng: 18.8854261 },
    { lat: 48.090321, lng: 18.885417 },
    { lat: 48.0901204, lng: 18.8847688 },
    { lat: 48.0900242, lng: 18.8846561 },
    { lat: 48.0899303, lng: 18.8846265 },
    { lat: 48.0898703, lng: 18.8846857 },
    { lat: 48.0897524, lng: 18.885021 },
    { lat: 48.0894627, lng: 18.8850437 },
    { lat: 48.0893379, lng: 18.8851782 },
    { lat: 48.0892834, lng: 18.8853488 },
    { lat: 48.0893096, lng: 18.8854608 },
    { lat: 48.0895893, lng: 18.8857626 },
    { lat: 48.0896337, lng: 18.8858576 },
    { lat: 48.0896175, lng: 18.885977 },
    { lat: 48.0891753, lng: 18.886496 },
    { lat: 48.0889505, lng: 18.8865948 },
    { lat: 48.0886598, lng: 18.886449 },
    { lat: 48.0883244, lng: 18.8865702 },
    { lat: 48.0880107, lng: 18.8865675 },
    { lat: 48.0878981, lng: 18.8864148 },
    { lat: 48.087881, lng: 18.8861724 },
    { lat: 48.0878262, lng: 18.8860962 },
    { lat: 48.0875607, lng: 18.8861302 },
    { lat: 48.0874261, lng: 18.8860594 },
    { lat: 48.0873943, lng: 18.8859478 },
    { lat: 48.0874857, lng: 18.8856201 },
    { lat: 48.0874665, lng: 18.8854922 },
    { lat: 48.0874104, lng: 18.8854092 },
    { lat: 48.0872294, lng: 18.8854231 },
    { lat: 48.0871001, lng: 18.8856025 },
    { lat: 48.0868777, lng: 18.8864406 },
    { lat: 48.0870699, lng: 18.8866468 },
    { lat: 48.0871306, lng: 18.8868143 },
    { lat: 48.087093, lng: 18.8869802 },
    { lat: 48.0870107, lng: 18.8870649 },
    { lat: 48.0867032, lng: 18.8870498 },
    { lat: 48.0864225, lng: 18.8864479 },
    { lat: 48.0863504, lng: 18.8864285 },
    { lat: 48.0862194, lng: 18.8865548 },
    { lat: 48.0861348, lng: 18.8868354 },
    { lat: 48.0860831, lng: 18.8869039 },
    { lat: 48.0860439, lng: 18.8868643 },
    { lat: 48.0858837, lng: 18.8864523 },
    { lat: 48.0859018, lng: 18.8862139 },
    { lat: 48.0861354, lng: 18.8856331 },
    { lat: 48.0861267, lng: 18.8854648 },
    { lat: 48.085986, lng: 18.8848474 },
    { lat: 48.0858698, lng: 18.8845855 },
    { lat: 48.0855063, lng: 18.8841224 },
    { lat: 48.0852612, lng: 18.8840073 },
    { lat: 48.0849737, lng: 18.8839462 },
    { lat: 48.0845166, lng: 18.8839799 },
    { lat: 48.084362, lng: 18.8841728 },
    { lat: 48.0843268, lng: 18.884318 },
    { lat: 48.0849537, lng: 18.8847494 },
    { lat: 48.0852387, lng: 18.8850887 },
    { lat: 48.0853509, lng: 18.8855105 },
    { lat: 48.0853365, lng: 18.8862299 },
    { lat: 48.0852928, lng: 18.8864705 },
    { lat: 48.0851936, lng: 18.8867748 },
    { lat: 48.0844226, lng: 18.8877329 },
    { lat: 48.0840922, lng: 18.8879712 },
    { lat: 48.0832657, lng: 18.8883391 },
    { lat: 48.0831121, lng: 18.888485 },
    { lat: 48.0830493, lng: 18.888707 },
    { lat: 48.0830606, lng: 18.889034 },
    { lat: 48.0833273, lng: 18.8898258 },
    { lat: 48.0832158, lng: 18.8898944 },
    { lat: 48.0824449, lng: 18.8936877 },
    { lat: 48.0825179, lng: 18.8940266 },
    { lat: 48.0824637, lng: 18.8944603 },
    { lat: 48.081688, lng: 18.8971672 },
    { lat: 48.0814343, lng: 18.8983402 },
    { lat: 48.0810499, lng: 18.8997709 },
    { lat: 48.0799275, lng: 18.9030591 },
    { lat: 48.0796716, lng: 18.9039276 },
    { lat: 48.0794839, lng: 18.9046751 },
    { lat: 48.0793581, lng: 18.9056417 },
    { lat: 48.0775127, lng: 18.9119222 },
    { lat: 48.0775502, lng: 18.9121196 },
    { lat: 48.0770095, lng: 18.912479 },
    { lat: 48.0768235, lng: 18.9127829 },
    { lat: 48.0766203, lng: 18.9133247 },
    { lat: 48.0765911, lng: 18.9135227 },
    { lat: 48.0766766, lng: 18.9139487 },
    { lat: 48.0766614, lng: 18.9142212 },
    { lat: 48.0764259, lng: 18.9150961 },
    { lat: 48.0761824, lng: 18.9154444 },
    { lat: 48.0760278, lng: 18.9155525 },
    { lat: 48.0751663, lng: 18.9159623 },
    { lat: 48.0747456, lng: 18.9160695 },
    { lat: 48.0743563, lng: 18.9159991 },
    { lat: 48.0741048, lng: 18.9157141 },
    { lat: 48.0733942, lng: 18.9140598 },
    { lat: 48.0732728, lng: 18.9138707 },
    { lat: 48.0731482, lng: 18.9138417 },
    { lat: 48.0728503, lng: 18.9139879 },
    { lat: 48.0724534, lng: 18.9147499 },
    { lat: 48.0718995, lng: 18.9168513 },
    { lat: 48.0717726, lng: 18.9184913 },
    { lat: 48.0715437, lng: 18.9192066 },
    { lat: 48.0745833, lng: 18.9195668 },
    { lat: 48.0770653, lng: 18.9200968 },
    { lat: 48.0780983, lng: 18.9204655 },
    { lat: 48.0798122, lng: 18.9209456 },
    { lat: 48.0847666, lng: 18.9226136 },
    { lat: 48.0868606, lng: 18.9230919 },
    { lat: 48.0872896, lng: 18.9232551 },
    { lat: 48.0878141, lng: 18.9232799 },
    { lat: 48.088688, lng: 18.9231282 },
    { lat: 48.0934122, lng: 18.9249276 },
    { lat: 48.0958769, lng: 18.9257933 },
    { lat: 48.0962818, lng: 18.9259692 },
    { lat: 48.0967051, lng: 18.9263697 },
    { lat: 48.0966971, lng: 18.9263925 },
    { lat: 48.0969373, lng: 18.9264128 },
    { lat: 48.0974542, lng: 18.9262935 },
    { lat: 48.0979783, lng: 18.9260704 },
    { lat: 48.0987408, lng: 18.9255323 },
    { lat: 48.0989698, lng: 18.9261218 },
    { lat: 48.0993417, lng: 18.9266824 },
    { lat: 48.1000007, lng: 18.9268229 },
    { lat: 48.1005154, lng: 18.9275957 },
    { lat: 48.1006599, lng: 18.9284697 },
    { lat: 48.1017339, lng: 18.9279567 },
    { lat: 48.1030806, lng: 18.926767 },
    { lat: 48.1034789, lng: 18.9264864 },
    { lat: 48.1040446, lng: 18.9260285 },
    { lat: 48.1040451, lng: 18.9252066 },
    { lat: 48.1038083, lng: 18.9210053 },
    { lat: 48.1039353, lng: 18.9199309 },
    { lat: 48.1040388, lng: 18.9180084 },
    { lat: 48.1039289, lng: 18.9155645 },
    { lat: 48.103602, lng: 18.9134172 },
    { lat: 48.1035609, lng: 18.9127115 },
    { lat: 48.1036505, lng: 18.911139 },
    { lat: 48.1038717, lng: 18.9085536 },
    { lat: 48.1038478, lng: 18.9052292 },
    { lat: 48.1040444, lng: 18.8999474 },
    { lat: 48.1040396, lng: 18.8985738 },
    { lat: 48.1038488, lng: 18.8974219 },
    { lat: 48.10373, lng: 18.8963071 },
    { lat: 48.1035818, lng: 18.894609 },
    { lat: 48.1034491, lng: 18.8920364 },
    { lat: 48.1027518, lng: 18.8864915 },
    { lat: 48.1026084, lng: 18.8848871 },
    { lat: 48.1026315, lng: 18.8844869 },
    { lat: 48.1025165, lng: 18.881268 },
    { lat: 48.102386, lng: 18.8804055 },
  ],
  Keť: [
    { lat: 47.947442, lng: 18.617415 },
    { lat: 47.9475495, lng: 18.6172438 },
    { lat: 47.9476686, lng: 18.6173595 },
    { lat: 47.9481117, lng: 18.6173534 },
    { lat: 47.9482249, lng: 18.6170125 },
    { lat: 47.9485247, lng: 18.6168024 },
    { lat: 47.9488743, lng: 18.6159938 },
    { lat: 47.9492882, lng: 18.6154377 },
    { lat: 47.9501345, lng: 18.6151149 },
    { lat: 47.9502881, lng: 18.6151199 },
    { lat: 47.950413, lng: 18.6152156 },
    { lat: 47.9505403, lng: 18.6154394 },
    { lat: 47.9504539, lng: 18.6156149 },
    { lat: 47.9505639, lng: 18.6173751 },
    { lat: 47.950659, lng: 18.6178572 },
    { lat: 47.9511675, lng: 18.6185373 },
    { lat: 47.9532324, lng: 18.6140738 },
    { lat: 47.9540581, lng: 18.6122163 },
    { lat: 47.9542403, lng: 18.6116938 },
    { lat: 47.954859, lng: 18.6104468 },
    { lat: 47.956995, lng: 18.6058227 },
    { lat: 47.9573197, lng: 18.6050197 },
    { lat: 47.957993, lng: 18.6036142 },
    { lat: 47.9580118, lng: 18.6034887 },
    { lat: 47.958651, lng: 18.6035846 },
    { lat: 47.95928, lng: 18.6034519 },
    { lat: 47.9597056, lng: 18.6037043 },
    { lat: 47.9604685, lng: 18.6039791 },
    { lat: 47.9610599, lng: 18.6038837 },
    { lat: 47.9635934, lng: 18.6060072 },
    { lat: 47.9662817, lng: 18.6076415 },
    { lat: 47.9679405, lng: 18.6083764 },
    { lat: 47.9693142, lng: 18.6090766 },
    { lat: 47.9701834, lng: 18.6097265 },
    { lat: 47.9712563, lng: 18.610289 },
    { lat: 47.9713156, lng: 18.6103188 },
    { lat: 47.971372, lng: 18.6096815 },
    { lat: 47.972335, lng: 18.606689 },
    { lat: 47.9742536, lng: 18.6083351 },
    { lat: 47.9753203, lng: 18.6089674 },
    { lat: 47.9760193, lng: 18.6092497 },
    { lat: 47.9768401, lng: 18.6094725 },
    { lat: 47.9770787, lng: 18.6096249 },
    { lat: 47.9780626, lng: 18.6088286 },
    { lat: 47.979733, lng: 18.6059831 },
    { lat: 47.9806625, lng: 18.6054682 },
    { lat: 47.9816819, lng: 18.6050086 },
    { lat: 47.9831461, lng: 18.6039637 },
    { lat: 47.9838648, lng: 18.6037384 },
    { lat: 47.9840807, lng: 18.603666 },
    { lat: 47.9855398, lng: 18.6015351 },
    { lat: 47.9871877, lng: 18.5993512 },
    { lat: 47.9882018, lng: 18.5984678 },
    { lat: 47.9886867, lng: 18.597805 },
    { lat: 47.9922742, lng: 18.5937827 },
    { lat: 47.9965141, lng: 18.5892962 },
    { lat: 47.9976412, lng: 18.5882327 },
    { lat: 47.9980422, lng: 18.5878525 },
    { lat: 47.997912, lng: 18.5876595 },
    { lat: 47.9978902, lng: 18.5876326 },
    { lat: 47.9978633, lng: 18.5875943 },
    { lat: 47.99732, lng: 18.5871048 },
    { lat: 47.995872, lng: 18.5853662 },
    { lat: 47.9892845, lng: 18.5780562 },
    { lat: 47.986563, lng: 18.5751088 },
    { lat: 47.9835623, lng: 18.5720672 },
    { lat: 47.980661, lng: 18.5693213 },
    { lat: 47.9798454, lng: 18.5684784 },
    { lat: 47.9754116, lng: 18.5644937 },
    { lat: 47.9743425, lng: 18.563373 },
    { lat: 47.9708117, lng: 18.5618151 },
    { lat: 47.9698061, lng: 18.5613126 },
    { lat: 47.9653417, lng: 18.5565724 },
    { lat: 47.9630997, lng: 18.5545992 },
    { lat: 47.9591375, lng: 18.5509118 },
    { lat: 47.9550801, lng: 18.5472408 },
    { lat: 47.953115, lng: 18.5503014 },
    { lat: 47.9518809, lng: 18.5517263 },
    { lat: 47.9490731, lng: 18.5539706 },
    { lat: 47.946688, lng: 18.555714 },
    { lat: 47.946446, lng: 18.556091 },
    { lat: 47.946251, lng: 18.556379 },
    { lat: 47.946166, lng: 18.556507 },
    { lat: 47.945978, lng: 18.556781 },
    { lat: 47.945537, lng: 18.557339 },
    { lat: 47.945408, lng: 18.557495 },
    { lat: 47.945085, lng: 18.557966 },
    { lat: 47.944923, lng: 18.558197 },
    { lat: 47.94444, lng: 18.558879 },
    { lat: 47.944225, lng: 18.559179 },
    { lat: 47.94409, lng: 18.559501 },
    { lat: 47.943908, lng: 18.559959 },
    { lat: 47.943809, lng: 18.560202 },
    { lat: 47.943722, lng: 18.560402 },
    { lat: 47.94337, lng: 18.561664 },
    { lat: 47.943305, lng: 18.561892 },
    { lat: 47.943385, lng: 18.564225 },
    { lat: 47.943337, lng: 18.565092 },
    { lat: 47.943277, lng: 18.566089 },
    { lat: 47.943128, lng: 18.568704 },
    { lat: 47.943121, lng: 18.570872 },
    { lat: 47.943123, lng: 18.570958 },
    { lat: 47.943175, lng: 18.572187 },
    { lat: 47.943096, lng: 18.575034 },
    { lat: 47.943095, lng: 18.575175 },
    { lat: 47.943094, lng: 18.575282 },
    { lat: 47.943089, lng: 18.575363 },
    { lat: 47.943043, lng: 18.576251 },
    { lat: 47.943022, lng: 18.576595 },
    { lat: 47.942712, lng: 18.579388 },
    { lat: 47.942303, lng: 18.582244 },
    { lat: 47.942178, lng: 18.583097 },
    { lat: 47.941968, lng: 18.584003 },
    { lat: 47.941543, lng: 18.585819 },
    { lat: 47.941126, lng: 18.587578 },
    { lat: 47.940604, lng: 18.589284 },
    { lat: 47.940227, lng: 18.590491 },
    { lat: 47.940021, lng: 18.591176 },
    { lat: 47.938827, lng: 18.594275 },
    { lat: 47.938258, lng: 18.595306 },
    { lat: 47.937872, lng: 18.596015 },
    { lat: 47.937304, lng: 18.597049 },
    { lat: 47.936682, lng: 18.598733 },
    { lat: 47.938663, lng: 18.600595 },
    { lat: 47.93949, lng: 18.601309 },
    { lat: 47.939932, lng: 18.601813 },
    { lat: 47.94056, lng: 18.602585 },
    { lat: 47.941343, lng: 18.603631 },
    { lat: 47.941634, lng: 18.604294 },
    { lat: 47.942, lng: 18.605263 },
    { lat: 47.942734, lng: 18.606821 },
    { lat: 47.943639, lng: 18.608782 },
    { lat: 47.94371, lng: 18.608904 },
    { lat: 47.943932, lng: 18.60932 },
    { lat: 47.9449, lng: 18.611561 },
    { lat: 47.945202, lng: 18.612231 },
    { lat: 47.94582, lng: 18.6138 },
    { lat: 47.946155, lng: 18.614476 },
    { lat: 47.946502, lng: 18.615243 },
    { lat: 47.946872, lng: 18.616174 },
    { lat: 47.947074, lng: 18.616617 },
    { lat: 47.947442, lng: 18.617415 },
  ],
  Šalov: [
    { lat: 48.0113454, lng: 18.6855346 },
    { lat: 48.011379, lng: 18.6855911 },
    { lat: 48.0117103, lng: 18.6863615 },
    { lat: 48.011752, lng: 18.6866513 },
    { lat: 48.0117371, lng: 18.6871293 },
    { lat: 48.012099, lng: 18.687441 },
    { lat: 48.0122712, lng: 18.6878293 },
    { lat: 48.0121369, lng: 18.688454 },
    { lat: 48.0121557, lng: 18.6893901 },
    { lat: 48.0120017, lng: 18.6900042 },
    { lat: 48.0120024, lng: 18.6905 },
    { lat: 48.0119285, lng: 18.6906364 },
    { lat: 48.0112078, lng: 18.6908446 },
    { lat: 48.0109574, lng: 18.6906552 },
    { lat: 48.0107043, lng: 18.6906468 },
    { lat: 48.0104726, lng: 18.6907844 },
    { lat: 48.0100929, lng: 18.6907288 },
    { lat: 48.0098794, lng: 18.690847 },
    { lat: 48.009602, lng: 18.6907422 },
    { lat: 48.0094383, lng: 18.6906347 },
    { lat: 48.0089127, lng: 18.689926 },
    { lat: 48.008516, lng: 18.6895769 },
    { lat: 48.0082536, lng: 18.6891252 },
    { lat: 48.008086, lng: 18.6889438 },
    { lat: 48.0076875, lng: 18.688708 },
    { lat: 48.0075152, lng: 18.6887512 },
    { lat: 48.0058224, lng: 18.6886928 },
    { lat: 48.0055113, lng: 18.6888607 },
    { lat: 48.0053517, lng: 18.6888112 },
    { lat: 48.0053299, lng: 18.6886585 },
    { lat: 48.0052534, lng: 18.6885979 },
    { lat: 48.0052522, lng: 18.6886812 },
    { lat: 48.0048079, lng: 18.688558 },
    { lat: 48.0043596, lng: 18.6886941 },
    { lat: 48.0037985, lng: 18.6886818 },
    { lat: 48.0036759, lng: 18.6886465 },
    { lat: 48.0034767, lng: 18.6884474 },
    { lat: 48.0032904, lng: 18.6881299 },
    { lat: 48.0032419, lng: 18.687897 },
    { lat: 48.0030865, lng: 18.6878212 },
    { lat: 48.0030162, lng: 18.6878747 },
    { lat: 48.0030501, lng: 18.6886299 },
    { lat: 48.0030014, lng: 18.6889185 },
    { lat: 48.0028635, lng: 18.6890788 },
    { lat: 48.0027212, lng: 18.689074 },
    { lat: 48.0021892, lng: 18.6885626 },
    { lat: 48.0020533, lng: 18.6881338 },
    { lat: 48.0019267, lng: 18.6879821 },
    { lat: 48.0015007, lng: 18.6883634 },
    { lat: 48.0014635, lng: 18.6886345 },
    { lat: 48.0016498, lng: 18.6892217 },
    { lat: 48.0016207, lng: 18.6894978 },
    { lat: 48.0014898, lng: 18.6898737 },
    { lat: 48.0009981, lng: 18.6897502 },
    { lat: 48.0006386, lng: 18.6894609 },
    { lat: 48.0004988, lng: 18.6894847 },
    { lat: 48.0002401, lng: 18.689853 },
    { lat: 48.0000457, lng: 18.6900302 },
    { lat: 47.9998358, lng: 18.6900078 },
    { lat: 47.9997282, lng: 18.689665 },
    { lat: 47.9996527, lng: 18.6896072 },
    { lat: 47.9992625, lng: 18.6896691 },
    { lat: 47.9990403, lng: 18.6897943 },
    { lat: 47.9988436, lng: 18.6903108 },
    { lat: 47.9986103, lng: 18.6906466 },
    { lat: 47.9985172, lng: 18.6906563 },
    { lat: 47.9980347, lng: 18.690281 },
    { lat: 47.9976306, lng: 18.6903995 },
    { lat: 47.9974254, lng: 18.689899 },
    { lat: 47.9971983, lng: 18.689541 },
    { lat: 47.9970614, lng: 18.689487 },
    { lat: 47.9968173, lng: 18.6896074 },
    { lat: 47.9966805, lng: 18.6895988 },
    { lat: 47.9965249, lng: 18.6894957 },
    { lat: 47.9965289, lng: 18.6895258 },
    { lat: 47.9963485, lng: 18.6951538 },
    { lat: 47.996272, lng: 18.696326 },
    { lat: 47.9961573, lng: 18.6971922 },
    { lat: 47.9957402, lng: 18.6988495 },
    { lat: 47.9951739, lng: 18.7003757 },
    { lat: 47.9943994, lng: 18.7034777 },
    { lat: 47.9933447, lng: 18.7068283 },
    { lat: 47.9930113, lng: 18.7075483 },
    { lat: 47.9927131, lng: 18.7083578 },
    { lat: 47.9924677, lng: 18.7093497 },
    { lat: 47.9924476, lng: 18.7099412 },
    { lat: 47.9925431, lng: 18.7108783 },
    { lat: 47.99303, lng: 18.712044 },
    { lat: 47.9932599, lng: 18.7123595 },
    { lat: 47.9933658, lng: 18.7126282 },
    { lat: 47.9933648, lng: 18.7130913 },
    { lat: 47.9929742, lng: 18.7147815 },
    { lat: 47.9926886, lng: 18.7155743 },
    { lat: 47.9924441, lng: 18.7166932 },
    { lat: 47.9920113, lng: 18.7179144 },
    { lat: 47.9919026, lng: 18.7196265 },
    { lat: 47.9917879, lng: 18.7200954 },
    { lat: 47.9914941, lng: 18.7209486 },
    { lat: 47.991204, lng: 18.7220778 },
    { lat: 47.9903292, lng: 18.7242462 },
    { lat: 47.9892827, lng: 18.7272352 },
    { lat: 47.9892475, lng: 18.7272094 },
    { lat: 47.9889959, lng: 18.7278147 },
    { lat: 47.9870458, lng: 18.7317872 },
    { lat: 47.9862504, lng: 18.7339708 },
    { lat: 47.9857598, lng: 18.7355056 },
    { lat: 47.9862558, lng: 18.7352702 },
    { lat: 47.986523, lng: 18.7353317 },
    { lat: 47.9896455, lng: 18.7369658 },
    { lat: 47.9895562, lng: 18.7373232 },
    { lat: 47.9939409, lng: 18.7396771 },
    { lat: 47.9941461, lng: 18.7398643 },
    { lat: 47.9943551, lng: 18.7402493 },
    { lat: 47.9944242, lng: 18.7416692 },
    { lat: 47.9957585, lng: 18.7444722 },
    { lat: 48.0012058, lng: 18.7429791 },
    { lat: 48.0061503, lng: 18.7411723 },
    { lat: 48.0079509, lng: 18.7417514 },
    { lat: 48.0099167, lng: 18.7430819 },
    { lat: 48.0114305, lng: 18.7462096 },
    { lat: 48.0119805, lng: 18.7467269 },
    { lat: 48.0120385, lng: 18.7467816 },
    { lat: 48.0131276, lng: 18.7471739 },
    { lat: 48.0149108, lng: 18.7468844 },
    { lat: 48.0162731, lng: 18.7467969 },
    { lat: 48.018009, lng: 18.7469265 },
    { lat: 48.0208048, lng: 18.7474533 },
    { lat: 48.0215863, lng: 18.7473916 },
    { lat: 48.023841, lng: 18.7462568 },
    { lat: 48.0241312, lng: 18.7451035 },
    { lat: 48.0253176, lng: 18.7436453 },
    { lat: 48.0255422, lng: 18.7435855 },
    { lat: 48.0266084, lng: 18.7438707 },
    { lat: 48.0271157, lng: 18.7438855 },
    { lat: 48.0281543, lng: 18.7439248 },
    { lat: 48.0300308, lng: 18.7455709 },
    { lat: 48.0330176, lng: 18.7453461 },
    { lat: 48.0348582, lng: 18.7459812 },
    { lat: 48.0364456, lng: 18.7460108 },
    { lat: 48.0391533, lng: 18.7452088 },
    { lat: 48.0393767, lng: 18.7451484 },
    { lat: 48.0393659, lng: 18.7450693 },
    { lat: 48.0391489, lng: 18.7436048 },
    { lat: 48.0388997, lng: 18.7423602 },
    { lat: 48.0386769, lng: 18.7406818 },
    { lat: 48.038613, lng: 18.7394035 },
    { lat: 48.0383836, lng: 18.7375195 },
    { lat: 48.038116, lng: 18.7360163 },
    { lat: 48.0379269, lng: 18.7336013 },
    { lat: 48.037902, lng: 18.7314325 },
    { lat: 48.0379352, lng: 18.7300324 },
    { lat: 48.0380096, lng: 18.7299862 },
    { lat: 48.0381494, lng: 18.7294205 },
    { lat: 48.0385385, lng: 18.7294397 },
    { lat: 48.038556, lng: 18.7269268 },
    { lat: 48.0383029, lng: 18.7255696 },
    { lat: 48.0380209, lng: 18.7244967 },
    { lat: 48.0379468, lng: 18.7232827 },
    { lat: 48.0376507, lng: 18.7220934 },
    { lat: 48.0372554, lng: 18.7208449 },
    { lat: 48.0374772, lng: 18.7170559 },
    { lat: 48.0374818, lng: 18.7159841 },
    { lat: 48.0376062, lng: 18.7138063 },
    { lat: 48.0373074, lng: 18.7137042 },
    { lat: 48.0371628, lng: 18.7135512 },
    { lat: 48.0371613, lng: 18.7134612 },
    { lat: 48.0370567, lng: 18.7132563 },
    { lat: 48.0359796, lng: 18.713004 },
    { lat: 48.035846, lng: 18.7131426 },
    { lat: 48.03579, lng: 18.7134033 },
    { lat: 48.0356553, lng: 18.7135625 },
    { lat: 48.0354408, lng: 18.7136202 },
    { lat: 48.0353338, lng: 18.7135385 },
    { lat: 48.0351593, lng: 18.7136689 },
    { lat: 48.0351312, lng: 18.7138573 },
    { lat: 48.0350275, lng: 18.7140314 },
    { lat: 48.0346663, lng: 18.7140034 },
    { lat: 48.0342578, lng: 18.7133495 },
    { lat: 48.0340797, lng: 18.7134138 },
    { lat: 48.0336676, lng: 18.7138238 },
    { lat: 48.0335277, lng: 18.7138638 },
    { lat: 48.0332195, lng: 18.7141311 },
    { lat: 48.0331205, lng: 18.7141147 },
    { lat: 48.0325921, lng: 18.714318 },
    { lat: 48.0323878, lng: 18.7141655 },
    { lat: 48.0322506, lng: 18.7138606 },
    { lat: 48.0322366, lng: 18.7135335 },
    { lat: 48.0319402, lng: 18.7131415 },
    { lat: 48.0318278, lng: 18.712453 },
    { lat: 48.0320232, lng: 18.7119991 },
    { lat: 48.0319912, lng: 18.7115702 },
    { lat: 48.0319653, lng: 18.7113827 },
    { lat: 48.0317211, lng: 18.7110335 },
    { lat: 48.031695, lng: 18.7110094 },
    { lat: 48.0315392, lng: 18.7110316 },
    { lat: 48.0307511, lng: 18.7092696 },
    { lat: 48.0273388, lng: 18.7020467 },
    { lat: 48.0246002, lng: 18.6994279 },
    { lat: 48.023123, lng: 18.6980941 },
    { lat: 48.0251569, lng: 18.6886026 },
    { lat: 48.0251403, lng: 18.6873525 },
    { lat: 48.0248132, lng: 18.6873139 },
    { lat: 48.0161787, lng: 18.6862941 },
    { lat: 48.0139182, lng: 18.6857882 },
    { lat: 48.0135495, lng: 18.6860423 },
    { lat: 48.0134241, lng: 18.6860616 },
    { lat: 48.0132779, lng: 18.6859611 },
    { lat: 48.0129384, lng: 18.686048 },
    { lat: 48.0129395, lng: 18.685709 },
    { lat: 48.0113454, lng: 18.6855346 },
  ],
  Pastovce: [
    { lat: 47.975036, lng: 18.754754 },
    { lat: 47.9750268, lng: 18.7545443 },
    { lat: 47.9751586, lng: 18.7545825 },
    { lat: 47.9754722, lng: 18.7537601 },
    { lat: 47.9759526, lng: 18.7516499 },
    { lat: 47.9766517, lng: 18.7490561 },
    { lat: 47.977281, lng: 18.7461658 },
    { lat: 47.9774492, lng: 18.7457263 },
    { lat: 47.9781759, lng: 18.7450153 },
    { lat: 47.9787592, lng: 18.7438595 },
    { lat: 47.9790375, lng: 18.7430528 },
    { lat: 47.9799, lng: 18.7409406 },
    { lat: 47.9803559, lng: 18.7404215 },
    { lat: 47.9807718, lng: 18.739617 },
    { lat: 47.9820521, lng: 18.7382519 },
    { lat: 47.9827628, lng: 18.7376652 },
    { lat: 47.9835471, lng: 18.7373382 },
    { lat: 47.9839604, lng: 18.7374116 },
    { lat: 47.9849198, lng: 18.7379534 },
    { lat: 47.9856868, lng: 18.7357219 },
    { lat: 47.9857598, lng: 18.7355056 },
    { lat: 47.9862504, lng: 18.7339708 },
    { lat: 47.9870458, lng: 18.7317872 },
    { lat: 47.9889959, lng: 18.7278147 },
    { lat: 47.9892475, lng: 18.7272094 },
    { lat: 47.9892251, lng: 18.7271882 },
    { lat: 47.9882226, lng: 18.7266865 },
    { lat: 47.9870648, lng: 18.7264213 },
    { lat: 47.9870607, lng: 18.7256103 },
    { lat: 47.9872446, lng: 18.7244576 },
    { lat: 47.9874688, lng: 18.7236934 },
    { lat: 47.987324, lng: 18.7236036 },
    { lat: 47.9872483, lng: 18.7237911 },
    { lat: 47.9872443, lng: 18.7240292 },
    { lat: 47.9870604, lng: 18.7236931 },
    { lat: 47.9857053, lng: 18.7241263 },
    { lat: 47.9852824, lng: 18.7239566 },
    { lat: 47.9851377, lng: 18.7232987 },
    { lat: 47.9839451, lng: 18.7232447 },
    { lat: 47.9834391, lng: 18.7231096 },
    { lat: 47.9826501, lng: 18.7227597 },
    { lat: 47.9827338, lng: 18.7223023 },
    { lat: 47.9806906, lng: 18.7216175 },
    { lat: 47.9797901, lng: 18.7214805 },
    { lat: 47.9798156, lng: 18.7210301 },
    { lat: 47.9795677, lng: 18.7209526 },
    { lat: 47.9781909, lng: 18.7202406 },
    { lat: 47.9767116, lng: 18.719412 },
    { lat: 47.97637, lng: 18.7177569 },
    { lat: 47.9760999, lng: 18.7159416 },
    { lat: 47.9760915, lng: 18.7159528 },
    { lat: 47.9731491, lng: 18.720463 },
    { lat: 47.9706527, lng: 18.7244018 },
    { lat: 47.9701375, lng: 18.7255848 },
    { lat: 47.9648011, lng: 18.7240139 },
    { lat: 47.964669, lng: 18.7242035 },
    { lat: 47.9639866, lng: 18.7240117 },
    { lat: 47.9619916, lng: 18.7233854 },
    { lat: 47.9607375, lng: 18.722921 },
    { lat: 47.9608045, lng: 18.7223209 },
    { lat: 47.9621357, lng: 18.7202731 },
    { lat: 47.9628, lng: 18.7195381 },
    { lat: 47.960352, lng: 18.7184786 },
    { lat: 47.9605109, lng: 18.7175733 },
    { lat: 47.9595754, lng: 18.7173309 },
    { lat: 47.9583953, lng: 18.7169029 },
    { lat: 47.9569027, lng: 18.7173788 },
    { lat: 47.9562721, lng: 18.7177305 },
    { lat: 47.9549272, lng: 18.7181391 },
    { lat: 47.9520446, lng: 18.718795 },
    { lat: 47.949844, lng: 18.717931 },
    { lat: 47.949598, lng: 18.719254 },
    { lat: 47.949514, lng: 18.720879 },
    { lat: 47.949464, lng: 18.720994 },
    { lat: 47.948861, lng: 18.722369 },
    { lat: 47.948294, lng: 18.723951 },
    { lat: 47.947441, lng: 18.724971 },
    { lat: 47.947273, lng: 18.725173 },
    { lat: 47.947202, lng: 18.725257 },
    { lat: 47.946682, lng: 18.726243 },
    { lat: 47.946684, lng: 18.726653 },
    { lat: 47.946686, lng: 18.726735 },
    { lat: 47.946689, lng: 18.727192 },
    { lat: 47.94655, lng: 18.728691 },
    { lat: 47.946547, lng: 18.730167 },
    { lat: 47.946549, lng: 18.731247 },
    { lat: 47.946556, lng: 18.731832 },
    { lat: 47.946566, lng: 18.732605 },
    { lat: 47.94659, lng: 18.734712 },
    { lat: 47.946591, lng: 18.734754 },
    { lat: 47.946596, lng: 18.7352 },
    { lat: 47.946603, lng: 18.735271 },
    { lat: 47.946681, lng: 18.736048 },
    { lat: 47.946736, lng: 18.73672 },
    { lat: 47.946746, lng: 18.736816 },
    { lat: 47.946772, lng: 18.737145 },
    { lat: 47.946774, lng: 18.737179 },
    { lat: 47.946858, lng: 18.738207 },
    { lat: 47.946884, lng: 18.738612 },
    { lat: 47.946894, lng: 18.738767 },
    { lat: 47.946912, lng: 18.738945 },
    { lat: 47.946914, lng: 18.738959 },
    { lat: 47.947138, lng: 18.739014 },
    { lat: 47.947435, lng: 18.739156 },
    { lat: 47.947733, lng: 18.739213 },
    { lat: 47.9477374, lng: 18.7392634 },
    { lat: 47.947742, lng: 18.739314 },
    { lat: 47.947755, lng: 18.739458 },
    { lat: 47.947788, lng: 18.73965 },
    { lat: 47.947819, lng: 18.739832 },
    { lat: 47.94789, lng: 18.739819 },
    { lat: 47.948416, lng: 18.739629 },
    { lat: 47.948476, lng: 18.739631 },
    { lat: 47.948586, lng: 18.73975 },
    { lat: 47.948659, lng: 18.739876 },
    { lat: 47.948657, lng: 18.739952 },
    { lat: 47.948481, lng: 18.740343 },
    { lat: 47.948464, lng: 18.740422 },
    { lat: 47.948502, lng: 18.740681 },
    { lat: 47.948618, lng: 18.740925 },
    { lat: 47.948832, lng: 18.74137 },
    { lat: 47.948796, lng: 18.741462 },
    { lat: 47.948445, lng: 18.742572 },
    { lat: 47.948397, lng: 18.742753 },
    { lat: 47.948235, lng: 18.743305 },
    { lat: 47.948156, lng: 18.74361 },
    { lat: 47.947936, lng: 18.744561 },
    { lat: 47.947923, lng: 18.744616 },
    { lat: 47.947909, lng: 18.744629 },
    { lat: 47.947268, lng: 18.745206 },
    { lat: 47.947347, lng: 18.746813 },
    { lat: 47.948278, lng: 18.750118 },
    { lat: 47.94829, lng: 18.750547 },
    { lat: 47.948291, lng: 18.750585 },
    { lat: 47.948291, lng: 18.750594 },
    { lat: 47.948306, lng: 18.751039 },
    { lat: 47.948322, lng: 18.751551 },
    { lat: 47.948316, lng: 18.751663 },
    { lat: 47.948298, lng: 18.752071 },
    { lat: 47.948272, lng: 18.752969 },
    { lat: 47.948267, lng: 18.754056 },
    { lat: 47.948244, lng: 18.754313 },
    { lat: 47.948179, lng: 18.755332 },
    { lat: 47.948189, lng: 18.756205 },
    { lat: 47.948221, lng: 18.758286 },
    { lat: 47.948293, lng: 18.760018 },
    { lat: 47.948314, lng: 18.760464 },
    { lat: 47.9483, lng: 18.76133 },
    { lat: 47.94829, lng: 18.761594 },
    { lat: 47.948236, lng: 18.763497 },
    { lat: 47.948201, lng: 18.764121 },
    { lat: 47.948196, lng: 18.764199 },
    { lat: 47.948178, lng: 18.764532 },
    { lat: 47.947989, lng: 18.765639 },
    { lat: 47.947965, lng: 18.765686 },
    { lat: 47.946826, lng: 18.76795 },
    { lat: 47.946727, lng: 18.768161 },
    { lat: 47.946359, lng: 18.768859 },
    { lat: 47.9457, lng: 18.770143 },
    { lat: 47.945563, lng: 18.770581 },
    { lat: 47.945426, lng: 18.771038 },
    { lat: 47.945328, lng: 18.771379 },
    { lat: 47.9451, lng: 18.771998 },
    { lat: 47.945016, lng: 18.772226 },
    { lat: 47.944923, lng: 18.772467 },
    { lat: 47.944899, lng: 18.772511 },
    { lat: 47.944862, lng: 18.772575 },
    { lat: 47.9446921, lng: 18.7728819 },
    { lat: 47.945267, lng: 18.772898 },
    { lat: 47.945978, lng: 18.772788 },
    { lat: 47.946492, lng: 18.772615 },
    { lat: 47.948012, lng: 18.771924 },
    { lat: 47.948614, lng: 18.771743 },
    { lat: 47.949039, lng: 18.771699 },
    { lat: 47.949843, lng: 18.771862 },
    { lat: 47.950736, lng: 18.772337 },
    { lat: 47.951238, lng: 18.772764 },
    { lat: 47.951694, lng: 18.77325 },
    { lat: 47.951902, lng: 18.773519 },
    { lat: 47.952521, lng: 18.774713 },
    { lat: 47.953016, lng: 18.775517 },
    { lat: 47.953398, lng: 18.776026 },
    { lat: 47.953635, lng: 18.77624 },
    { lat: 47.954567, lng: 18.776663 },
    { lat: 47.955092, lng: 18.776746 },
    { lat: 47.955609, lng: 18.776706 },
    { lat: 47.956084, lng: 18.77647 },
    { lat: 47.956827, lng: 18.775951 },
    { lat: 47.957203, lng: 18.775527 },
    { lat: 47.957802, lng: 18.774642 },
    { lat: 47.958363, lng: 18.774127 },
    { lat: 47.958949, lng: 18.773795 },
    { lat: 47.959084, lng: 18.773646 },
    { lat: 47.960983, lng: 18.773133 },
    { lat: 47.961449, lng: 18.772936 },
    { lat: 47.962041, lng: 18.772472 },
    { lat: 47.962303, lng: 18.772184 },
    { lat: 47.962739, lng: 18.771584 },
    { lat: 47.963008, lng: 18.771114 },
    { lat: 47.963272, lng: 18.770018 },
    { lat: 47.963739, lng: 18.767346 },
    { lat: 47.963888, lng: 18.766902 },
    { lat: 47.964205, lng: 18.766271 },
    { lat: 47.96451, lng: 18.765855 },
    { lat: 47.96491, lng: 18.765514 },
    { lat: 47.96701, lng: 18.764574 },
    { lat: 47.967457, lng: 18.764169 },
    { lat: 47.967781, lng: 18.763754 },
    { lat: 47.968109, lng: 18.763153 },
    { lat: 47.968559, lng: 18.762078 },
    { lat: 47.968575, lng: 18.76193 },
    { lat: 47.969044, lng: 18.760643 },
    { lat: 47.970007, lng: 18.758179 },
    { lat: 47.970621, lng: 18.757028 },
    { lat: 47.971364, lng: 18.756165 },
    { lat: 47.972161, lng: 18.755476 },
    { lat: 47.972684, lng: 18.755167 },
    { lat: 47.973496, lng: 18.754845 },
    { lat: 47.974283, lng: 18.754719 },
    { lat: 47.975036, lng: 18.754754 },
  ],
  HornýPial: [
    { lat: 48.1731545, lng: 18.4405906 },
    { lat: 48.1730469, lng: 18.4398986 },
    { lat: 48.172861, lng: 18.4378497 },
    { lat: 48.1729018, lng: 18.4370844 },
    { lat: 48.1729836, lng: 18.4366532 },
    { lat: 48.1729638, lng: 18.4365196 },
    { lat: 48.1727621, lng: 18.4360854 },
    { lat: 48.1727301, lng: 18.4357339 },
    { lat: 48.1726408, lng: 18.4355486 },
    { lat: 48.1719438, lng: 18.43507 },
    { lat: 48.1716032, lng: 18.4351936 },
    { lat: 48.1705389, lng: 18.4344003 },
    { lat: 48.1698561, lng: 18.4339737 },
    { lat: 48.1694517, lng: 18.4334611 },
    { lat: 48.1690942, lng: 18.4324778 },
    { lat: 48.1686588, lng: 18.4320959 },
    { lat: 48.1684531, lng: 18.4319676 },
    { lat: 48.16771, lng: 18.4317144 },
    { lat: 48.1673062, lng: 18.4319544 },
    { lat: 48.1670645, lng: 18.431872 },
    { lat: 48.1663501, lng: 18.4312396 },
    { lat: 48.1654783, lng: 18.4302593 },
    { lat: 48.1654504, lng: 18.430225 },
    { lat: 48.1642944, lng: 18.4311784 },
    { lat: 48.1626306, lng: 18.4294724 },
    { lat: 48.1618488, lng: 18.4287676 },
    { lat: 48.161522, lng: 18.4285937 },
    { lat: 48.1613753, lng: 18.4286135 },
    { lat: 48.1607151, lng: 18.4289924 },
    { lat: 48.1604921, lng: 18.428979 },
    { lat: 48.1598492, lng: 18.4286999 },
    { lat: 48.1593574, lng: 18.4290435 },
    { lat: 48.1585243, lng: 18.4293762 },
    { lat: 48.1580347, lng: 18.4298598 },
    { lat: 48.1573989, lng: 18.4294042 },
    { lat: 48.155277, lng: 18.4276786 },
    { lat: 48.155179, lng: 18.427599 },
    { lat: 48.1537053, lng: 18.4319562 },
    { lat: 48.151046, lng: 18.4288555 },
    { lat: 48.1486688, lng: 18.4267431 },
    { lat: 48.1475199, lng: 18.4266985 },
    { lat: 48.1475435, lng: 18.4275613 },
    { lat: 48.1474904, lng: 18.4284843 },
    { lat: 48.1475194, lng: 18.4287396 },
    { lat: 48.1472509, lng: 18.4302241 },
    { lat: 48.14732, lng: 18.4311127 },
    { lat: 48.14725, lng: 18.4317372 },
    { lat: 48.1471394, lng: 18.4320994 },
    { lat: 48.1469178, lng: 18.4323129 },
    { lat: 48.1467054, lng: 18.4328295 },
    { lat: 48.1465758, lng: 18.432732 },
    { lat: 48.1462059, lng: 18.4336169 },
    { lat: 48.1453538, lng: 18.4353814 },
    { lat: 48.1448695, lng: 18.4366434 },
    { lat: 48.1446399, lng: 18.4374185 },
    { lat: 48.1438093, lng: 18.4391247 },
    { lat: 48.1447877, lng: 18.4408207 },
    { lat: 48.1442648, lng: 18.4414742 },
    { lat: 48.1468985, lng: 18.4446056 },
    { lat: 48.1464789, lng: 18.4450703 },
    { lat: 48.1455582, lng: 18.4480738 },
    { lat: 48.1451281, lng: 18.4502229 },
    { lat: 48.1445712, lng: 18.4526714 },
    { lat: 48.1446458, lng: 18.4528762 },
    { lat: 48.1448741, lng: 18.4532048 },
    { lat: 48.1451302, lng: 18.4534293 },
    { lat: 48.145418, lng: 18.4537964 },
    { lat: 48.1457677, lng: 18.454461 },
    { lat: 48.1466967, lng: 18.4572932 },
    { lat: 48.1516847, lng: 18.4615248 },
    { lat: 48.1498858, lng: 18.4713015 },
    { lat: 48.1498673, lng: 18.4713512 },
    { lat: 48.1517413, lng: 18.4737225 },
    { lat: 48.1528591, lng: 18.4746333 },
    { lat: 48.1537817, lng: 18.4751965 },
    { lat: 48.1550593, lng: 18.475712 },
    { lat: 48.1558018, lng: 18.476082 },
    { lat: 48.1573196, lng: 18.4771022 },
    { lat: 48.1574268, lng: 18.4771807 },
    { lat: 48.1572034, lng: 18.4743669 },
    { lat: 48.1571106, lng: 18.4736132 },
    { lat: 48.1567784, lng: 18.4717643 },
    { lat: 48.1566591, lng: 18.4699254 },
    { lat: 48.1565268, lng: 18.4686036 },
    { lat: 48.1563915, lng: 18.4677167 },
    { lat: 48.1565646, lng: 18.4663101 },
    { lat: 48.1565978, lng: 18.4656096 },
    { lat: 48.156543, lng: 18.4649314 },
    { lat: 48.1572298, lng: 18.4649285 },
    { lat: 48.157398, lng: 18.4648503 },
    { lat: 48.1577087, lng: 18.4645724 },
    { lat: 48.1590842, lng: 18.4630251 },
    { lat: 48.1597046, lng: 18.462235 },
    { lat: 48.1601303, lng: 18.461535 },
    { lat: 48.1614743, lng: 18.4590166 },
    { lat: 48.1617857, lng: 18.4582901 },
    { lat: 48.1629519, lng: 18.4544291 },
    { lat: 48.1636128, lng: 18.4517805 },
    { lat: 48.1647356, lng: 18.4501925 },
    { lat: 48.165359, lng: 18.4489767 },
    { lat: 48.1657168, lng: 18.4483903 },
    { lat: 48.1672447, lng: 18.4467264 },
    { lat: 48.1693431, lng: 18.4447313 },
    { lat: 48.1701464, lng: 18.4436455 },
    { lat: 48.1713094, lng: 18.442314 },
    { lat: 48.1731594, lng: 18.4406193 },
    { lat: 48.1731545, lng: 18.4405906 },
  ],
  VeľkéTurovce: [
    { lat: 48.1096578, lng: 18.9244787 },
    { lat: 48.1095694, lng: 18.924492 },
    { lat: 48.1094169, lng: 18.9246377 },
    { lat: 48.1082527, lng: 18.9245873 },
    { lat: 48.1079295, lng: 18.9246376 },
    { lat: 48.1071797, lng: 18.9250926 },
    { lat: 48.1068006, lng: 18.9250901 },
    { lat: 48.1060059, lng: 18.9253364 },
    { lat: 48.1049778, lng: 18.9255133 },
    { lat: 48.1046783, lng: 18.9255917 },
    { lat: 48.1044608, lng: 18.925701 },
    { lat: 48.1040446, lng: 18.9260285 },
    { lat: 48.1034789, lng: 18.9264864 },
    { lat: 48.1030806, lng: 18.926767 },
    { lat: 48.1017339, lng: 18.9279567 },
    { lat: 48.1006599, lng: 18.9284697 },
    { lat: 48.1005154, lng: 18.9275957 },
    { lat: 48.1000007, lng: 18.9268229 },
    { lat: 48.0993417, lng: 18.9266824 },
    { lat: 48.0989698, lng: 18.9261218 },
    { lat: 48.0987408, lng: 18.9255323 },
    { lat: 48.0979783, lng: 18.9260704 },
    { lat: 48.0974542, lng: 18.9262935 },
    { lat: 48.0969373, lng: 18.9264128 },
    { lat: 48.0966971, lng: 18.9263925 },
    { lat: 48.0966658, lng: 18.9265628 },
    { lat: 48.0964016, lng: 18.9280008 },
    { lat: 48.0962079, lng: 18.9282669 },
    { lat: 48.0957557, lng: 18.9312523 },
    { lat: 48.0957704, lng: 18.9326703 },
    { lat: 48.0956584, lng: 18.9349075 },
    { lat: 48.0956266, lng: 18.9352293 },
    { lat: 48.0955294, lng: 18.9352247 },
    { lat: 48.0951473, lng: 18.9401605 },
    { lat: 48.0950611, lng: 18.9403031 },
    { lat: 48.0950528, lng: 18.9407273 },
    { lat: 48.0949036, lng: 18.9408083 },
    { lat: 48.0952398, lng: 18.9430623 },
    { lat: 48.0953748, lng: 18.9444562 },
    { lat: 48.095386, lng: 18.9455459 },
    { lat: 48.0952582, lng: 18.9471309 },
    { lat: 48.0952539, lng: 18.9477493 },
    { lat: 48.0948125, lng: 18.9478025 },
    { lat: 48.0947007, lng: 18.9479759 },
    { lat: 48.0946356, lng: 18.9489452 },
    { lat: 48.0943538, lng: 18.9503214 },
    { lat: 48.0934551, lng: 18.9540156 },
    { lat: 48.0930496, lng: 18.9538741 },
    { lat: 48.0927126, lng: 18.9556456 },
    { lat: 48.0923544, lng: 18.9582551 },
    { lat: 48.0922294, lng: 18.959792 },
    { lat: 48.0922427, lng: 18.9613458 },
    { lat: 48.0919269, lng: 18.96222 },
    { lat: 48.0918273, lng: 18.9622113 },
    { lat: 48.0916965, lng: 18.9624072 },
    { lat: 48.0914886, lng: 18.9636471 },
    { lat: 48.0912818, lng: 18.9644718 },
    { lat: 48.0908638, lng: 18.965638 },
    { lat: 48.0906629, lng: 18.9668224 },
    { lat: 48.0906078, lng: 18.9679823 },
    { lat: 48.090727, lng: 18.9685048 },
    { lat: 48.0907058, lng: 18.9701743 },
    { lat: 48.0908299, lng: 18.9707921 },
    { lat: 48.0909774, lng: 18.9708104 },
    { lat: 48.0910646, lng: 18.9710829 },
    { lat: 48.091036, lng: 18.9715965 },
    { lat: 48.0911245, lng: 18.9719574 },
    { lat: 48.0910998, lng: 18.9721398 },
    { lat: 48.0911605, lng: 18.9723328 },
    { lat: 48.0911537, lng: 18.9726831 },
    { lat: 48.0912314, lng: 18.9727877 },
    { lat: 48.0911301, lng: 18.9731425 },
    { lat: 48.0912477, lng: 18.9735879 },
    { lat: 48.09121, lng: 18.9747221 },
    { lat: 48.091045, lng: 18.9759017 },
    { lat: 48.0911013, lng: 18.9766902 },
    { lat: 48.0910265, lng: 18.9771431 },
    { lat: 48.0909606, lng: 18.9782122 },
    { lat: 48.0909593, lng: 18.9782279 },
    { lat: 48.0918542, lng: 18.9785061 },
    { lat: 48.0925596, lng: 18.9790017 },
    { lat: 48.0930503, lng: 18.9791628 },
    { lat: 48.0936879, lng: 18.9791273 },
    { lat: 48.0946829, lng: 18.9793126 },
    { lat: 48.0953201, lng: 18.9795145 },
    { lat: 48.095161, lng: 18.9810511 },
    { lat: 48.0953099, lng: 18.9832486 },
    { lat: 48.0965304, lng: 18.9826436 },
    { lat: 48.0980583, lng: 18.9831543 },
    { lat: 48.0981033, lng: 18.98317 },
    { lat: 48.0986077, lng: 18.9833575 },
    { lat: 48.1011583, lng: 18.98417 },
    { lat: 48.1013897, lng: 18.9845076 },
    { lat: 48.1017716, lng: 18.9845645 },
    { lat: 48.102199, lng: 18.9850074 },
    { lat: 48.1024409, lng: 18.9849744 },
    { lat: 48.102859, lng: 18.9851235 },
    { lat: 48.1029375, lng: 18.9850743 },
    { lat: 48.1030958, lng: 18.9851254 },
    { lat: 48.1042047, lng: 18.9849147 },
    { lat: 48.1056253, lng: 18.9850046 },
    { lat: 48.105856, lng: 18.9851041 },
    { lat: 48.1063635, lng: 18.9855471 },
    { lat: 48.106403, lng: 18.9858089 },
    { lat: 48.1067853, lng: 18.9861413 },
    { lat: 48.1068914, lng: 18.9875064 },
    { lat: 48.1068919, lng: 18.9893253 },
    { lat: 48.1072653, lng: 18.991551 },
    { lat: 48.1073196, lng: 18.9914894 },
    { lat: 48.1075524, lng: 18.990069 },
    { lat: 48.1080288, lng: 18.987017 },
    { lat: 48.1079704, lng: 18.986099 },
    { lat: 48.108567, lng: 18.983597 },
    { lat: 48.1090259, lng: 18.9811568 },
    { lat: 48.1100423, lng: 18.9748376 },
    { lat: 48.1119672, lng: 18.964832 },
    { lat: 48.1127772, lng: 18.9620827 },
    { lat: 48.1131159, lng: 18.9607757 },
    { lat: 48.1131111, lng: 18.960264 },
    { lat: 48.1133172, lng: 18.9582461 },
    { lat: 48.1138677, lng: 18.9564963 },
    { lat: 48.1143157, lng: 18.9534199 },
    { lat: 48.1144651, lng: 18.9529896 },
    { lat: 48.1157546, lng: 18.9517355 },
    { lat: 48.1155306, lng: 18.9485055 },
    { lat: 48.1160938, lng: 18.9484298 },
    { lat: 48.1160784, lng: 18.9478708 },
    { lat: 48.1160074, lng: 18.9476712 },
    { lat: 48.116232, lng: 18.9449661 },
    { lat: 48.1158869, lng: 18.9447489 },
    { lat: 48.1157915, lng: 18.9448657 },
    { lat: 48.1156296, lng: 18.9448554 },
    { lat: 48.1155416, lng: 18.9447198 },
    { lat: 48.11536, lng: 18.9439341 },
    { lat: 48.1152004, lng: 18.9435703 },
    { lat: 48.1148854, lng: 18.9431909 },
    { lat: 48.1145821, lng: 18.9426694 },
    { lat: 48.1144101, lng: 18.9425234 },
    { lat: 48.1139866, lng: 18.9426219 },
    { lat: 48.1132573, lng: 18.9431508 },
    { lat: 48.1132689, lng: 18.942919 },
    { lat: 48.1150109, lng: 18.9381123 },
    { lat: 48.1149383, lng: 18.9380769 },
    { lat: 48.11533, lng: 18.9370851 },
    { lat: 48.11266, lng: 18.9319163 },
    { lat: 48.111075, lng: 18.9278052 },
    { lat: 48.1104181, lng: 18.9270106 },
    { lat: 48.1104269, lng: 18.9268755 },
    { lat: 48.1101739, lng: 18.9266069 },
    { lat: 48.1096276, lng: 18.9265056 },
    { lat: 48.1092023, lng: 18.9262426 },
    { lat: 48.1092465, lng: 18.9260516 },
    { lat: 48.1096578, lng: 18.9244787 },
  ],
  Šahy: [
    { lat: 48.0966971, lng: 18.9263925 },
    { lat: 48.0967051, lng: 18.9263697 },
    { lat: 48.0962818, lng: 18.9259692 },
    { lat: 48.0958769, lng: 18.9257933 },
    { lat: 48.0934122, lng: 18.9249276 },
    { lat: 48.088688, lng: 18.9231282 },
    { lat: 48.0878141, lng: 18.9232799 },
    { lat: 48.0872896, lng: 18.9232551 },
    { lat: 48.0868606, lng: 18.9230919 },
    { lat: 48.0847666, lng: 18.9226136 },
    { lat: 48.0798122, lng: 18.9209456 },
    { lat: 48.0780983, lng: 18.9204655 },
    { lat: 48.0770653, lng: 18.9200968 },
    { lat: 48.0745833, lng: 18.9195668 },
    { lat: 48.0715437, lng: 18.9192066 },
    { lat: 48.0717726, lng: 18.9184913 },
    { lat: 48.0718995, lng: 18.9168513 },
    { lat: 48.0724534, lng: 18.9147499 },
    { lat: 48.0728503, lng: 18.9139879 },
    { lat: 48.0731482, lng: 18.9138417 },
    { lat: 48.0732728, lng: 18.9138707 },
    { lat: 48.0733942, lng: 18.9140598 },
    { lat: 48.0741048, lng: 18.9157141 },
    { lat: 48.0743563, lng: 18.9159991 },
    { lat: 48.0747456, lng: 18.9160695 },
    { lat: 48.0751663, lng: 18.9159623 },
    { lat: 48.0760278, lng: 18.9155525 },
    { lat: 48.0761824, lng: 18.9154444 },
    { lat: 48.0764259, lng: 18.9150961 },
    { lat: 48.0766614, lng: 18.9142212 },
    { lat: 48.0766766, lng: 18.9139487 },
    { lat: 48.0765911, lng: 18.9135227 },
    { lat: 48.0766203, lng: 18.9133247 },
    { lat: 48.0768235, lng: 18.9127829 },
    { lat: 48.0770095, lng: 18.912479 },
    { lat: 48.0775502, lng: 18.9121196 },
    { lat: 48.0775127, lng: 18.9119222 },
    { lat: 48.0793581, lng: 18.9056417 },
    { lat: 48.0794839, lng: 18.9046751 },
    { lat: 48.0796716, lng: 18.9039276 },
    { lat: 48.0799275, lng: 18.9030591 },
    { lat: 48.0810499, lng: 18.8997709 },
    { lat: 48.0814343, lng: 18.8983402 },
    { lat: 48.081688, lng: 18.8971672 },
    { lat: 48.0824637, lng: 18.8944603 },
    { lat: 48.0825179, lng: 18.8940266 },
    { lat: 48.0824449, lng: 18.8936877 },
    { lat: 48.0832158, lng: 18.8898944 },
    { lat: 48.0833273, lng: 18.8898258 },
    { lat: 48.0830606, lng: 18.889034 },
    { lat: 48.0830493, lng: 18.888707 },
    { lat: 48.0831121, lng: 18.888485 },
    { lat: 48.0832657, lng: 18.8883391 },
    { lat: 48.0840922, lng: 18.8879712 },
    { lat: 48.0844226, lng: 18.8877329 },
    { lat: 48.0851936, lng: 18.8867748 },
    { lat: 48.0852928, lng: 18.8864705 },
    { lat: 48.0853365, lng: 18.8862299 },
    { lat: 48.0853509, lng: 18.8855105 },
    { lat: 48.0852387, lng: 18.8850887 },
    { lat: 48.0849537, lng: 18.8847494 },
    { lat: 48.0843268, lng: 18.884318 },
    { lat: 48.084362, lng: 18.8841728 },
    { lat: 48.0845166, lng: 18.8839799 },
    { lat: 48.0849737, lng: 18.8839462 },
    { lat: 48.0852612, lng: 18.8840073 },
    { lat: 48.0855063, lng: 18.8841224 },
    { lat: 48.0858698, lng: 18.8845855 },
    { lat: 48.085986, lng: 18.8848474 },
    { lat: 48.0864401, lng: 18.8845559 },
    { lat: 48.0865918, lng: 18.8842428 },
    { lat: 48.0863961, lng: 18.8837799 },
    { lat: 48.086047, lng: 18.8834445 },
    { lat: 48.0858522, lng: 18.8834058 },
    { lat: 48.0857616, lng: 18.8828146 },
    { lat: 48.0860201, lng: 18.8822133 },
    { lat: 48.0859878, lng: 18.8820689 },
    { lat: 48.0858491, lng: 18.8819419 },
    { lat: 48.0854844, lng: 18.881932 },
    { lat: 48.0853931, lng: 18.8818256 },
    { lat: 48.0854189, lng: 18.8816505 },
    { lat: 48.0856037, lng: 18.8811924 },
    { lat: 48.0855778, lng: 18.8809316 },
    { lat: 48.0856228, lng: 18.8805864 },
    { lat: 48.0855104, lng: 18.8803117 },
    { lat: 48.085177, lng: 18.8802112 },
    { lat: 48.0849776, lng: 18.8803038 },
    { lat: 48.0848751, lng: 18.8802413 },
    { lat: 48.0847833, lng: 18.879948 },
    { lat: 48.0846525, lng: 18.8799907 },
    { lat: 48.0844192, lng: 18.8802351 },
    { lat: 48.0842965, lng: 18.8805414 },
    { lat: 48.0841088, lng: 18.8803586 },
    { lat: 48.0840047, lng: 18.8801119 },
    { lat: 48.0839526, lng: 18.8796531 },
    { lat: 48.0837936, lng: 18.8796117 },
    { lat: 48.0834676, lng: 18.8797818 },
    { lat: 48.0833533, lng: 18.8796428 },
    { lat: 48.0833333, lng: 18.8791955 },
    { lat: 48.0833512, lng: 18.8790414 },
    { lat: 48.0835585, lng: 18.8790374 },
    { lat: 48.0835708, lng: 18.8788836 },
    { lat: 48.0834584, lng: 18.878763 },
    { lat: 48.0834215, lng: 18.8785088 },
    { lat: 48.0832978, lng: 18.8784072 },
    { lat: 48.0830386, lng: 18.8783256 },
    { lat: 48.0832319, lng: 18.8773403 },
    { lat: 48.0827383, lng: 18.8772408 },
    { lat: 48.0826141, lng: 18.8773022 },
    { lat: 48.0823937, lng: 18.8769914 },
    { lat: 48.0819898, lng: 18.8766812 },
    { lat: 48.0808111, lng: 18.8762467 },
    { lat: 48.0797123, lng: 18.8751795 },
    { lat: 48.0792209, lng: 18.8744944 },
    { lat: 48.0790738, lng: 18.8741388 },
    { lat: 48.0789918, lng: 18.8736687 },
    { lat: 48.0789191, lng: 18.8724078 },
    { lat: 48.0787975, lng: 18.8716137 },
    { lat: 48.0788423, lng: 18.8711335 },
    { lat: 48.0789594, lng: 18.8708691 },
    { lat: 48.0792617, lng: 18.8704189 },
    { lat: 48.0796875, lng: 18.8700431 },
    { lat: 48.0799133, lng: 18.8699202 },
    { lat: 48.0803691, lng: 18.8698608 },
    { lat: 48.0809644, lng: 18.8702467 },
    { lat: 48.0811804, lng: 18.8703009 },
    { lat: 48.0813703, lng: 18.8702668 },
    { lat: 48.0815259, lng: 18.8701563 },
    { lat: 48.0816978, lng: 18.8697169 },
    { lat: 48.0816724, lng: 18.869442 },
    { lat: 48.0815667, lng: 18.8692152 },
    { lat: 48.0811258, lng: 18.8686749 },
    { lat: 48.0801972, lng: 18.8677809 },
    { lat: 48.0798612, lng: 18.8675453 },
    { lat: 48.0797221, lng: 18.8674908 },
    { lat: 48.079638, lng: 18.8674578 },
    { lat: 48.0794211, lng: 18.8674538 },
    { lat: 48.0781781, lng: 18.8682023 },
    { lat: 48.0775226, lng: 18.8683245 },
    { lat: 48.0773214, lng: 18.8682714 },
    { lat: 48.0761152, lng: 18.8667698 },
    { lat: 48.075898, lng: 18.8666696 },
    { lat: 48.0757218, lng: 18.8666634 },
    { lat: 48.0754548, lng: 18.8667978 },
    { lat: 48.0753411, lng: 18.866933 },
    { lat: 48.075238, lng: 18.8671806 },
    { lat: 48.0751766, lng: 18.8675372 },
    { lat: 48.0752276, lng: 18.8679081 },
    { lat: 48.0754738, lng: 18.8687155 },
    { lat: 48.0756624, lng: 18.8696233 },
    { lat: 48.0756586, lng: 18.8701967 },
    { lat: 48.0755719, lng: 18.8704188 },
    { lat: 48.075222, lng: 18.8706051 },
    { lat: 48.0749796, lng: 18.8704976 },
    { lat: 48.0741224, lng: 18.8698668 },
    { lat: 48.0738213, lng: 18.8698035 },
    { lat: 48.0734645, lng: 18.8698398 },
    { lat: 48.073203, lng: 18.8699898 },
    { lat: 48.072976, lng: 18.8702214 },
    { lat: 48.0724555, lng: 18.8709421 },
    { lat: 48.0724028, lng: 18.8712493 },
    { lat: 48.072476, lng: 18.8715271 },
    { lat: 48.0725968, lng: 18.8717284 },
    { lat: 48.07264, lng: 18.8717604 },
    { lat: 48.0736444, lng: 18.8725024 },
    { lat: 48.0738027, lng: 18.872814 },
    { lat: 48.0737918, lng: 18.8732281 },
    { lat: 48.0737037, lng: 18.8735504 },
    { lat: 48.0735607, lng: 18.8737268 },
    { lat: 48.0729648, lng: 18.8739351 },
    { lat: 48.0718673, lng: 18.8732141 },
    { lat: 48.0713314, lng: 18.8729751 },
    { lat: 48.0707203, lng: 18.872847 },
    { lat: 48.0702828, lng: 18.8728669 },
    { lat: 48.0689373, lng: 18.8732068 },
    { lat: 48.0683914, lng: 18.8731191 },
    { lat: 48.0683491, lng: 18.8733912 },
    { lat: 48.0683358, lng: 18.8754228 },
    { lat: 48.0685727, lng: 18.8757693 },
    { lat: 48.0685394, lng: 18.8759505 },
    { lat: 48.0675173, lng: 18.8760715 },
    { lat: 48.0665791, lng: 18.8765025 },
    { lat: 48.0660688, lng: 18.8764913 },
    { lat: 48.0658097, lng: 18.8781812 },
    { lat: 48.0638782, lng: 18.878637 },
    { lat: 48.0637218, lng: 18.8787504 },
    { lat: 48.0630831, lng: 18.8787876 },
    { lat: 48.0628479, lng: 18.8779308 },
    { lat: 48.063017, lng: 18.8771959 },
    { lat: 48.063029, lng: 18.876327 },
    { lat: 48.0630017, lng: 18.8762336 },
    { lat: 48.0626854, lng: 18.8763314 },
    { lat: 48.0621053, lng: 18.8772498 },
    { lat: 48.0615715, lng: 18.8782989 },
    { lat: 48.06131, lng: 18.8786885 },
    { lat: 48.0609228, lng: 18.8796098 },
    { lat: 48.0607594, lng: 18.8801956 },
    { lat: 48.0606853, lng: 18.8811968 },
    { lat: 48.0607671, lng: 18.8816191 },
    { lat: 48.061334, lng: 18.881891 },
    { lat: 48.0615013, lng: 18.882181 },
    { lat: 48.0614416, lng: 18.882507 },
    { lat: 48.0607283, lng: 18.8829025 },
    { lat: 48.0602066, lng: 18.8830851 },
    { lat: 48.0598338, lng: 18.8831354 },
    { lat: 48.0591809, lng: 18.8829642 },
    { lat: 48.058743, lng: 18.8818772 },
    { lat: 48.0584058, lng: 18.8817035 },
    { lat: 48.0583121, lng: 18.8817239 },
    { lat: 48.0582392, lng: 18.8818797 },
    { lat: 48.0579963, lng: 18.88213 },
    { lat: 48.0576947, lng: 18.8827033 },
    { lat: 48.0575152, lng: 18.8827005 },
    { lat: 48.0573882, lng: 18.8824495 },
    { lat: 48.0573262, lng: 18.8819992 },
    { lat: 48.057367, lng: 18.8813737 },
    { lat: 48.0575006, lng: 18.8809536 },
    { lat: 48.0580691, lng: 18.8806771 },
    { lat: 48.0581958, lng: 18.8805524 },
    { lat: 48.0581525, lng: 18.8802649 },
    { lat: 48.0579927, lng: 18.8799472 },
    { lat: 48.0578151, lng: 18.8798723 },
    { lat: 48.0576447, lng: 18.8798671 },
    { lat: 48.0573588, lng: 18.8799859 },
    { lat: 48.0564357, lng: 18.8808158 },
    { lat: 48.0558597, lng: 18.8811886 },
    { lat: 48.0556245, lng: 18.8815223 },
    { lat: 48.0555568, lng: 18.8817596 },
    { lat: 48.055516, lng: 18.8821637 },
    { lat: 48.0555997, lng: 18.8829649 },
    { lat: 48.0588866, lng: 18.8842148 },
    { lat: 48.0592239, lng: 18.885386 },
    { lat: 48.058682, lng: 18.886674 },
    { lat: 48.058412, lng: 18.887084 },
    { lat: 48.058242, lng: 18.887248 },
    { lat: 48.057594, lng: 18.888597 },
    { lat: 48.056778, lng: 18.889833 },
    { lat: 48.056595, lng: 18.889768 },
    { lat: 48.055607, lng: 18.890974 },
    { lat: 48.055129, lng: 18.891322 },
    { lat: 48.054552, lng: 18.89142 },
    { lat: 48.054391, lng: 18.891548 },
    { lat: 48.054287, lng: 18.891515 },
    { lat: 48.054114, lng: 18.891661 },
    { lat: 48.053989, lng: 18.892139 },
    { lat: 48.055038, lng: 18.893082 },
    { lat: 48.052652, lng: 18.90508 },
    { lat: 48.051708, lng: 18.907598 },
    { lat: 48.051139, lng: 18.908982 },
    { lat: 48.051477, lng: 18.909613 },
    { lat: 48.052151, lng: 18.911399 },
    { lat: 48.0524638, lng: 18.9128946 },
    { lat: 48.0528617, lng: 18.9150734 },
    { lat: 48.053003, lng: 18.915847 },
    { lat: 48.053047, lng: 18.916354 },
    { lat: 48.053335, lng: 18.917679 },
    { lat: 48.054002, lng: 18.920051 },
    { lat: 48.054995, lng: 18.923243 },
    { lat: 48.057064, lng: 18.927924 },
    { lat: 48.057122, lng: 18.928061 },
    { lat: 48.056953, lng: 18.928324 },
    { lat: 48.056827, lng: 18.928391 },
    { lat: 48.058397, lng: 18.935903 },
    { lat: 48.058113, lng: 18.936002 },
    { lat: 48.058466, lng: 18.937047 },
    { lat: 48.058618, lng: 18.937751 },
    { lat: 48.058674, lng: 18.938475 },
    { lat: 48.058612, lng: 18.940546 },
    { lat: 48.058633, lng: 18.9412 },
    { lat: 48.058865, lng: 18.943904 },
    { lat: 48.058846, lng: 18.944627 },
    { lat: 48.058738, lng: 18.945349 },
    { lat: 48.058344, lng: 18.946624 },
    { lat: 48.058172, lng: 18.947629 },
    { lat: 48.058116, lng: 18.948584 },
    { lat: 48.05818, lng: 18.952024 },
    { lat: 48.057899, lng: 18.955331 },
    { lat: 48.057928, lng: 18.956704 },
    { lat: 48.058019, lng: 18.95751 },
    { lat: 48.058464, lng: 18.95913 },
    { lat: 48.058598, lng: 18.960133 },
    { lat: 48.0584773, lng: 18.9613671 },
    { lat: 48.058313, lng: 18.962484 },
    { lat: 48.058082, lng: 18.963319 },
    { lat: 48.057107, lng: 18.965355 },
    { lat: 48.057654, lng: 18.9659353 },
    { lat: 48.0573789, lng: 18.9666588 },
    { lat: 48.0573093, lng: 18.9668322 },
    { lat: 48.0571261, lng: 18.9673235 },
    { lat: 48.058378, lng: 18.96835 },
    { lat: 48.058123, lng: 18.969121 },
    { lat: 48.057999, lng: 18.969065 },
    { lat: 48.057708, lng: 18.969933 },
    { lat: 48.057381, lng: 18.969853 },
    { lat: 48.057364, lng: 18.970141 },
    { lat: 48.057163, lng: 18.970176 },
    { lat: 48.057093, lng: 18.97002 },
    { lat: 48.056659, lng: 18.97019 },
    { lat: 48.056121, lng: 18.971008 },
    { lat: 48.05599, lng: 18.971379 },
    { lat: 48.055956, lng: 18.97182 },
    { lat: 48.05629, lng: 18.973312 },
    { lat: 48.056304, lng: 18.973745 },
    { lat: 48.056241, lng: 18.973945 },
    { lat: 48.056146, lng: 18.974042 },
    { lat: 48.055948, lng: 18.974085 },
    { lat: 48.055318, lng: 18.973998 },
    { lat: 48.054991, lng: 18.974281 },
    { lat: 48.054975, lng: 18.974705 },
    { lat: 48.055103, lng: 18.974608 },
    { lat: 48.055367, lng: 18.974951 },
    { lat: 48.055177, lng: 18.975188 },
    { lat: 48.056419, lng: 18.976179 },
    { lat: 48.0563724, lng: 18.9762383 },
    { lat: 48.056251, lng: 18.976393 },
    { lat: 48.055604, lng: 18.976685 },
    { lat: 48.055243, lng: 18.977117 },
    { lat: 48.055056, lng: 18.977748 },
    { lat: 48.055137, lng: 18.978275 },
    { lat: 48.055038, lng: 18.97928 },
    { lat: 48.05499, lng: 18.97943 },
    { lat: 48.054794, lng: 18.979639 },
    { lat: 48.054416, lng: 18.979761 },
    { lat: 48.054207, lng: 18.979922 },
    { lat: 48.053731, lng: 18.980408 },
    { lat: 48.053593, lng: 18.980743 },
    { lat: 48.053548, lng: 18.981127 },
    { lat: 48.053601, lng: 18.98161 },
    { lat: 48.053903, lng: 18.982342 },
    { lat: 48.054722, lng: 18.983381 },
    { lat: 48.055121, lng: 18.984405 },
    { lat: 48.055185, lng: 18.984953 },
    { lat: 48.055202, lng: 18.98561 },
    { lat: 48.055383, lng: 18.986544 },
    { lat: 48.055572, lng: 18.986779 },
    { lat: 48.055939, lng: 18.987045 },
    { lat: 48.056481, lng: 18.987184 },
    { lat: 48.056878, lng: 18.987382 },
    { lat: 48.057408, lng: 18.988221 },
    { lat: 48.057434, lng: 18.98892 },
    { lat: 48.057635, lng: 18.990698 },
    { lat: 48.05782, lng: 18.991293 },
    { lat: 48.058283, lng: 18.99163 },
    { lat: 48.05848, lng: 18.99153 },
    { lat: 48.058713, lng: 18.991198 },
    { lat: 48.058741, lng: 18.990912 },
    { lat: 48.058589, lng: 18.989862 },
    { lat: 48.058658, lng: 18.989677 },
    { lat: 48.058803, lng: 18.989639 },
    { lat: 48.058899, lng: 18.989757 },
    { lat: 48.059229, lng: 18.990612 },
    { lat: 48.059517, lng: 18.990956 },
    { lat: 48.060072, lng: 18.991387 },
    { lat: 48.060605, lng: 18.992339 },
    { lat: 48.060763, lng: 18.992453 },
    { lat: 48.061089, lng: 18.992483 },
    { lat: 48.061404, lng: 18.992009 },
    { lat: 48.061467, lng: 18.99183 },
    { lat: 48.06151, lng: 18.990715 },
    { lat: 48.061569, lng: 18.990601 },
    { lat: 48.061739, lng: 18.990507 },
    { lat: 48.061912, lng: 18.990561 },
    { lat: 48.062292, lng: 18.990904 },
    { lat: 48.06238, lng: 18.991044 },
    { lat: 48.062599, lng: 18.992077 },
    { lat: 48.062784, lng: 18.992335 },
    { lat: 48.062992, lng: 18.992403 },
    { lat: 48.063443, lng: 18.992322 },
    { lat: 48.064236, lng: 18.991893 },
    { lat: 48.064499, lng: 18.991867 },
    { lat: 48.065294, lng: 18.992281 },
    { lat: 48.065658, lng: 18.992749 },
    { lat: 48.065961, lng: 18.993467 },
    { lat: 48.066308, lng: 18.993875 },
    { lat: 48.067001, lng: 18.994945 },
    { lat: 48.067339, lng: 18.995363 },
    { lat: 48.067516, lng: 18.99534 },
    { lat: 48.068023, lng: 18.995906 },
    { lat: 48.068274, lng: 18.996578 },
    { lat: 48.068353, lng: 18.99738 },
    { lat: 48.068726, lng: 18.999883 },
    { lat: 48.068792, lng: 19.000554 },
    { lat: 48.068774, lng: 19.000925 },
    { lat: 48.068925, lng: 19.001831 },
    { lat: 48.068943, lng: 19.002585 },
    { lat: 48.069037, lng: 19.003164 },
    { lat: 48.069104, lng: 19.003338 },
    { lat: 48.069109, lng: 19.003692 },
    { lat: 48.069038, lng: 19.004201 },
    { lat: 48.06907, lng: 19.004313 },
    { lat: 48.069188, lng: 19.004443 },
    { lat: 48.069556, lng: 19.004537 },
    { lat: 48.06983, lng: 19.005268 },
    { lat: 48.069827, lng: 19.006465 },
    { lat: 48.070043, lng: 19.007233 },
    { lat: 48.070113, lng: 19.007766 },
    { lat: 48.0700507, lng: 19.0083077 },
    { lat: 48.070043, lng: 19.008904 },
    { lat: 48.07042, lng: 19.009282 },
    { lat: 48.070746, lng: 19.00939 },
    { lat: 48.0710556, lng: 19.0092622 },
    { lat: 48.071095, lng: 19.009246 },
    { lat: 48.07159, lng: 19.008927 },
    { lat: 48.071915, lng: 19.008567 },
    { lat: 48.072477, lng: 19.007593 },
    { lat: 48.072887, lng: 19.007501 },
    { lat: 48.073333, lng: 19.007552 },
    { lat: 48.073693, lng: 19.007758 },
    { lat: 48.073923, lng: 19.008163 },
    { lat: 48.073938, lng: 19.008633 },
    { lat: 48.073891, lng: 19.008762 },
    { lat: 48.073614, lng: 19.008982 },
    { lat: 48.07291, lng: 19.009085 },
    { lat: 48.072773, lng: 19.009211 },
    { lat: 48.072751, lng: 19.009487 },
    { lat: 48.072819, lng: 19.009812 },
    { lat: 48.072984, lng: 19.010222 },
    { lat: 48.073085, lng: 19.010703 },
    { lat: 48.072955, lng: 19.011414 },
    { lat: 48.073059, lng: 19.012241 },
    { lat: 48.073138, lng: 19.012528 },
    { lat: 48.073347, lng: 19.012738 },
    { lat: 48.073493, lng: 19.01268 },
    { lat: 48.073779, lng: 19.012318 },
    { lat: 48.07398, lng: 19.011413 },
    { lat: 48.074385, lng: 19.010753 },
    { lat: 48.074433, lng: 19.010369 },
    { lat: 48.074404, lng: 19.010187 },
    { lat: 48.074458, lng: 19.009406 },
    { lat: 48.074597, lng: 19.008641 },
    { lat: 48.074693, lng: 19.008428 },
    { lat: 48.074871, lng: 19.008281 },
    { lat: 48.07512, lng: 19.0083 },
    { lat: 48.075329, lng: 19.008408 },
    { lat: 48.075532, lng: 19.00863 },
    { lat: 48.075738, lng: 19.008637 },
    { lat: 48.076189, lng: 19.008889 },
    { lat: 48.076287, lng: 19.009096 },
    { lat: 48.076307, lng: 19.009383 },
    { lat: 48.07613, lng: 19.010102 },
    { lat: 48.076145, lng: 19.010395 },
    { lat: 48.076059, lng: 19.010569 },
    { lat: 48.075799, lng: 19.010859 },
    { lat: 48.075647, lng: 19.010899 },
    { lat: 48.075152, lng: 19.010768 },
    { lat: 48.075048, lng: 19.010822 },
    { lat: 48.074943, lng: 19.01094 },
    { lat: 48.074899, lng: 19.011093 },
    { lat: 48.07496, lng: 19.011582 },
    { lat: 48.076286, lng: 19.01303 },
    { lat: 48.076727, lng: 19.013838 },
    { lat: 48.077204, lng: 19.01424 },
    { lat: 48.077415, lng: 19.01421 },
    { lat: 48.077645, lng: 19.014343 },
    { lat: 48.077774, lng: 19.014372 },
    { lat: 48.077779, lng: 19.014188 },
    { lat: 48.07778, lng: 19.01391 },
    { lat: 48.077792, lng: 19.013906 },
    { lat: 48.0779, lng: 19.013869 },
    { lat: 48.077978, lng: 19.013842 },
    { lat: 48.077991, lng: 19.013837 },
    { lat: 48.078163, lng: 19.013833 },
    { lat: 48.078188, lng: 19.013832 },
    { lat: 48.078391, lng: 19.013827 },
    { lat: 48.078605, lng: 19.013794 },
    { lat: 48.078609, lng: 19.013794 },
    { lat: 48.078688, lng: 19.013782 },
    { lat: 48.079837, lng: 19.013611 },
    { lat: 48.080493, lng: 19.013548 },
    { lat: 48.081293, lng: 19.013649 },
    { lat: 48.082797, lng: 19.01328 },
    { lat: 48.084106, lng: 19.01296 },
    { lat: 48.084151, lng: 19.012948 },
    { lat: 48.084217, lng: 19.012932 },
    { lat: 48.085926, lng: 19.012156 },
    { lat: 48.087129, lng: 19.012182 },
    { lat: 48.087184, lng: 19.012182 },
    { lat: 48.087621, lng: 19.012181 },
    { lat: 48.088618, lng: 19.012209 },
    { lat: 48.088932, lng: 19.012246 },
    { lat: 48.090401, lng: 19.012417 },
    { lat: 48.091232, lng: 19.012669 },
    { lat: 48.092276, lng: 19.012985 },
    { lat: 48.092734, lng: 19.013123 },
    { lat: 48.093193, lng: 19.013411 },
    { lat: 48.093294, lng: 19.013368 },
    { lat: 48.093356, lng: 19.013342 },
    { lat: 48.093382, lng: 19.013331 },
    { lat: 48.093447, lng: 19.01348 },
    { lat: 48.09355, lng: 19.013648 },
    { lat: 48.093718, lng: 19.013852 },
    { lat: 48.093787, lng: 19.013903 },
    { lat: 48.09405, lng: 19.013949 },
    { lat: 48.094102, lng: 19.013993 },
    { lat: 48.094096, lng: 19.014068 },
    { lat: 48.094027, lng: 19.014234 },
    { lat: 48.093993, lng: 19.014313 },
    { lat: 48.093939, lng: 19.014346 },
    { lat: 48.093875, lng: 19.014386 },
    { lat: 48.093823, lng: 19.014414 },
    { lat: 48.093761, lng: 19.01454 },
    { lat: 48.09382, lng: 19.014598 },
    { lat: 48.094023, lng: 19.014749 },
    { lat: 48.094093, lng: 19.014813 },
    { lat: 48.09408, lng: 19.014956 },
    { lat: 48.094046, lng: 19.015124 },
    { lat: 48.094008, lng: 19.015353 },
    { lat: 48.093977, lng: 19.015545 },
    { lat: 48.093963, lng: 19.015684 },
    { lat: 48.093962, lng: 19.015757 },
    { lat: 48.094023, lng: 19.015805 },
    { lat: 48.094079, lng: 19.01584 },
    { lat: 48.094103, lng: 19.015954 },
    { lat: 48.094115, lng: 19.016057 },
    { lat: 48.094168, lng: 19.016425 },
    { lat: 48.094194, lng: 19.016544 },
    { lat: 48.09423, lng: 19.016696 },
    { lat: 48.09434, lng: 19.016742 },
    { lat: 48.094537, lng: 19.01675 },
    { lat: 48.094735, lng: 19.016771 },
    { lat: 48.094806, lng: 19.016848 },
    { lat: 48.0948, lng: 19.016945 },
    { lat: 48.094756, lng: 19.017067 },
    { lat: 48.094764, lng: 19.0172 },
    { lat: 48.094807, lng: 19.017379 },
    { lat: 48.094828, lng: 19.017507 },
    { lat: 48.094882, lng: 19.017577 },
    { lat: 48.094982, lng: 19.017658 },
    { lat: 48.095071, lng: 19.01774 },
    { lat: 48.095101, lng: 19.017786 },
    { lat: 48.095065, lng: 19.017884 },
    { lat: 48.094984, lng: 19.017912 },
    { lat: 48.094819, lng: 19.017997 },
    { lat: 48.094753, lng: 19.018046 },
    { lat: 48.09475, lng: 19.018167 },
    { lat: 48.094779, lng: 19.01834 },
    { lat: 48.094758, lng: 19.018419 },
    { lat: 48.094687, lng: 19.018431 },
    { lat: 48.094572, lng: 19.018418 },
    { lat: 48.094475, lng: 19.018423 },
    { lat: 48.094396, lng: 19.018474 },
    { lat: 48.09434, lng: 19.01851 },
    { lat: 48.094284, lng: 19.018557 },
    { lat: 48.094274, lng: 19.018678 },
    { lat: 48.094264, lng: 19.019184 },
    { lat: 48.094273, lng: 19.019321 },
    { lat: 48.094311, lng: 19.019448 },
    { lat: 48.094374, lng: 19.019592 },
    { lat: 48.094463, lng: 19.019773 },
    { lat: 48.094475, lng: 19.019857 },
    { lat: 48.094447, lng: 19.019929 },
    { lat: 48.094234, lng: 19.020192 },
    { lat: 48.094201, lng: 19.020275 },
    { lat: 48.094187, lng: 19.020355 },
    { lat: 48.094211, lng: 19.020483 },
    { lat: 48.094287, lng: 19.020436 },
    { lat: 48.094361, lng: 19.020356 },
    { lat: 48.094403, lng: 19.020315 },
    { lat: 48.094488, lng: 19.020312 },
    { lat: 48.094625, lng: 19.020326 },
    { lat: 48.094684, lng: 19.020337 },
    { lat: 48.094729, lng: 19.020444 },
    { lat: 48.094823, lng: 19.02071 },
    { lat: 48.094868, lng: 19.020795 },
    { lat: 48.09494, lng: 19.020809 },
    { lat: 48.09499, lng: 19.02077 },
    { lat: 48.095027, lng: 19.020702 },
    { lat: 48.095068, lng: 19.020643 },
    { lat: 48.095115, lng: 19.020627 },
    { lat: 48.09515, lng: 19.020683 },
    { lat: 48.095244, lng: 19.020918 },
    { lat: 48.095309, lng: 19.021027 },
    { lat: 48.095376, lng: 19.021106 },
    { lat: 48.095435, lng: 19.021136 },
    { lat: 48.095508, lng: 19.021111 },
    { lat: 48.095586, lng: 19.021073 },
    { lat: 48.095661, lng: 19.021047 },
    { lat: 48.095716, lng: 19.021033 },
    { lat: 48.09579, lng: 19.021096 },
    { lat: 48.095753, lng: 19.021177 },
    { lat: 48.095693, lng: 19.021278 },
    { lat: 48.095668, lng: 19.021327 },
    { lat: 48.09564, lng: 19.021378 },
    { lat: 48.095677, lng: 19.021448 },
    { lat: 48.095694, lng: 19.021492 },
    { lat: 48.095686, lng: 19.021588 },
    { lat: 48.09566, lng: 19.021744 },
    { lat: 48.095633, lng: 19.021911 },
    { lat: 48.095616, lng: 19.02206 },
    { lat: 48.095596, lng: 19.022156 },
    { lat: 48.095584, lng: 19.022276 },
    { lat: 48.095661, lng: 19.022366 },
    { lat: 48.095713, lng: 19.022436 },
    { lat: 48.095769, lng: 19.022508 },
    { lat: 48.095805, lng: 19.02261 },
    { lat: 48.095885, lng: 19.022935 },
    { lat: 48.096001, lng: 19.022899 },
    { lat: 48.096061, lng: 19.022859 },
    { lat: 48.096075, lng: 19.022793 },
    { lat: 48.096126, lng: 19.022464 },
    { lat: 48.096133, lng: 19.022389 },
    { lat: 48.096161, lng: 19.022366 },
    { lat: 48.096263, lng: 19.022317 },
    { lat: 48.096319, lng: 19.022291 },
    { lat: 48.096362, lng: 19.022329 },
    { lat: 48.09647, lng: 19.022492 },
    { lat: 48.096516, lng: 19.022538 },
    { lat: 48.096561, lng: 19.022551 },
    { lat: 48.096654, lng: 19.022566 },
    { lat: 48.096729, lng: 19.022595 },
    { lat: 48.096792, lng: 19.022655 },
    { lat: 48.096824, lng: 19.022718 },
    { lat: 48.096807, lng: 19.022786 },
    { lat: 48.096725, lng: 19.022965 },
    { lat: 48.096732, lng: 19.023106 },
    { lat: 48.096767, lng: 19.023206 },
    { lat: 48.096816, lng: 19.023247 },
    { lat: 48.097061, lng: 19.023405 },
    { lat: 48.097102, lng: 19.023472 },
    { lat: 48.097049, lng: 19.023553 },
    { lat: 48.096828, lng: 19.023891 },
    { lat: 48.096794, lng: 19.024262 },
    { lat: 48.096816, lng: 19.0244 },
    { lat: 48.096847, lng: 19.024465 },
    { lat: 48.096889, lng: 19.024507 },
    { lat: 48.096945, lng: 19.024522 },
    { lat: 48.097007, lng: 19.024552 },
    { lat: 48.097029, lng: 19.024643 },
    { lat: 48.097068, lng: 19.024717 },
    { lat: 48.097135, lng: 19.024793 },
    { lat: 48.097209, lng: 19.024867 },
    { lat: 48.097215, lng: 19.024919 },
    { lat: 48.097191, lng: 19.024971 },
    { lat: 48.097072, lng: 19.025017 },
    { lat: 48.096883, lng: 19.025083 },
    { lat: 48.096834, lng: 19.025154 },
    { lat: 48.096787, lng: 19.025244 },
    { lat: 48.096841, lng: 19.025327 },
    { lat: 48.096927, lng: 19.025396 },
    { lat: 48.097021, lng: 19.025472 },
    { lat: 48.09708, lng: 19.025533 },
    { lat: 48.097123, lng: 19.025578 },
    { lat: 48.097234, lng: 19.025792 },
    { lat: 48.097299, lng: 19.025917 },
    { lat: 48.097346, lng: 19.025863 },
    { lat: 48.097332, lng: 19.025793 },
    { lat: 48.097431, lng: 19.025786 },
    { lat: 48.097682, lng: 19.025748 },
    { lat: 48.098051, lng: 19.025861 },
    { lat: 48.098297, lng: 19.025984 },
    { lat: 48.098682, lng: 19.025989 },
    { lat: 48.099134, lng: 19.025853 },
    { lat: 48.099571, lng: 19.025612 },
    { lat: 48.09987, lng: 19.02531 },
    { lat: 48.100023, lng: 19.025132 },
    { lat: 48.100147, lng: 19.024975 },
    { lat: 48.100278, lng: 19.02479 },
    { lat: 48.100406, lng: 19.024753 },
    { lat: 48.100578, lng: 19.024648 },
    { lat: 48.100718, lng: 19.024556 },
    { lat: 48.101001, lng: 19.024326 },
    { lat: 48.101105, lng: 19.024243 },
    { lat: 48.101275, lng: 19.024176 },
    { lat: 48.1014, lng: 19.024129 },
    { lat: 48.101584, lng: 19.024002 },
    { lat: 48.101943, lng: 19.023579 },
    { lat: 48.101996, lng: 19.023515 },
    { lat: 48.102135, lng: 19.023351 },
    { lat: 48.102331, lng: 19.023196 },
    { lat: 48.102357, lng: 19.02321 },
    { lat: 48.103057, lng: 19.023987 },
    { lat: 48.103814, lng: 19.024841 },
    { lat: 48.10457, lng: 19.025578 },
    { lat: 48.104605, lng: 19.025611 },
    { lat: 48.104631, lng: 19.025638 },
    { lat: 48.105358, lng: 19.026416 },
    { lat: 48.105938, lng: 19.027021 },
    { lat: 48.106551, lng: 19.02771 },
    { lat: 48.106776, lng: 19.027939 },
    { lat: 48.106883, lng: 19.028047 },
    { lat: 48.107366, lng: 19.028544 },
    { lat: 48.107706, lng: 19.028856 },
    { lat: 48.108494, lng: 19.029635 },
    { lat: 48.108589, lng: 19.029731 },
    { lat: 48.108654, lng: 19.029795 },
    { lat: 48.108906, lng: 19.030044 },
    { lat: 48.108926, lng: 19.030065 },
    { lat: 48.1093219, lng: 19.0298428 },
    { lat: 48.10972, lng: 19.0294893 },
    { lat: 48.1100385, lng: 19.0293869 },
    { lat: 48.1105976, lng: 19.0290331 },
    { lat: 48.1109392, lng: 19.0287012 },
    { lat: 48.1110353, lng: 19.02857 },
    { lat: 48.1110738, lng: 19.0283991 },
    { lat: 48.1112266, lng: 19.028211 },
    { lat: 48.1114945, lng: 19.0280247 },
    { lat: 48.1119308, lng: 19.0279777 },
    { lat: 48.1134363, lng: 19.027519 },
    { lat: 48.1137818, lng: 19.0268878 },
    { lat: 48.1139651, lng: 19.0262143 },
    { lat: 48.1141721, lng: 19.025783 },
    { lat: 48.1146111, lng: 19.024372 },
    { lat: 48.1147857, lng: 19.0235925 },
    { lat: 48.1149382, lng: 19.0221156 },
    { lat: 48.1154306, lng: 19.0210016 },
    { lat: 48.1158707, lng: 19.0190445 },
    { lat: 48.1159658, lng: 19.0180765 },
    { lat: 48.1160258, lng: 19.0179931 },
    { lat: 48.1161782, lng: 19.0173771 },
    { lat: 48.1161816, lng: 19.0170687 },
    { lat: 48.1164122, lng: 19.0165026 },
    { lat: 48.1167083, lng: 19.0160727 },
    { lat: 48.1173513, lng: 19.015705 },
    { lat: 48.117733, lng: 19.0151648 },
    { lat: 48.1177541, lng: 19.0149807 },
    { lat: 48.1178797, lng: 19.0147886 },
    { lat: 48.1179895, lng: 19.0143329 },
    { lat: 48.1181826, lng: 19.0140114 },
    { lat: 48.1184399, lng: 19.0131304 },
    { lat: 48.1186038, lng: 19.0124064 },
    { lat: 48.1186826, lng: 19.0113029 },
    { lat: 48.1192302, lng: 19.0101012 },
    { lat: 48.1190204, lng: 19.0096336 },
    { lat: 48.1190266, lng: 19.009533 },
    { lat: 48.1191318, lng: 19.0094508 },
    { lat: 48.119167, lng: 19.0093484 },
    { lat: 48.1190547, lng: 19.0091572 },
    { lat: 48.1192525, lng: 19.008682 },
    { lat: 48.1191378, lng: 19.007694 },
    { lat: 48.119238, lng: 19.0065182 },
    { lat: 48.119425, lng: 19.0059418 },
    { lat: 48.1196608, lng: 19.0049294 },
    { lat: 48.1194069, lng: 19.0031591 },
    { lat: 48.1194876, lng: 19.0023338 },
    { lat: 48.1193543, lng: 19.0009601 },
    { lat: 48.1193526, lng: 19.0002662 },
    { lat: 48.1193552, lng: 19.000159 },
    { lat: 48.1193571, lng: 19.0000441 },
    { lat: 48.1193723, lng: 18.9992594 },
    { lat: 48.1195644, lng: 18.9966906 },
    { lat: 48.1191451, lng: 18.996448 },
    { lat: 48.1179377, lng: 18.9957262 },
    { lat: 48.1162976, lng: 18.9953816 },
    { lat: 48.1152339, lng: 18.9954825 },
    { lat: 48.1145309, lng: 18.9933321 },
    { lat: 48.1140829, lng: 18.9936323 },
    { lat: 48.1136812, lng: 18.9940013 },
    { lat: 48.1130645, lng: 18.9943561 },
    { lat: 48.1115741, lng: 18.9948211 },
    { lat: 48.1112063, lng: 18.9948415 },
    { lat: 48.110648, lng: 18.9946611 },
    { lat: 48.1098755, lng: 18.9942151 },
    { lat: 48.1094544, lng: 18.9942526 },
    { lat: 48.1093518, lng: 18.9945137 },
    { lat: 48.109232, lng: 18.994508 },
    { lat: 48.1087092, lng: 18.9936147 },
    { lat: 48.1086494, lng: 18.992687 },
    { lat: 48.1075809, lng: 18.9918999 },
    { lat: 48.1073196, lng: 18.9914894 },
    { lat: 48.1072653, lng: 18.991551 },
    { lat: 48.1068919, lng: 18.9893253 },
    { lat: 48.1068914, lng: 18.9875064 },
    { lat: 48.1067853, lng: 18.9861413 },
    { lat: 48.106403, lng: 18.9858089 },
    { lat: 48.1063635, lng: 18.9855471 },
    { lat: 48.105856, lng: 18.9851041 },
    { lat: 48.1056253, lng: 18.9850046 },
    { lat: 48.1042047, lng: 18.9849147 },
    { lat: 48.1030958, lng: 18.9851254 },
    { lat: 48.1029375, lng: 18.9850743 },
    { lat: 48.102859, lng: 18.9851235 },
    { lat: 48.1024409, lng: 18.9849744 },
    { lat: 48.102199, lng: 18.9850074 },
    { lat: 48.1017716, lng: 18.9845645 },
    { lat: 48.1013897, lng: 18.9845076 },
    { lat: 48.1011583, lng: 18.98417 },
    { lat: 48.0986077, lng: 18.9833575 },
    { lat: 48.0981033, lng: 18.98317 },
    { lat: 48.0980583, lng: 18.9831543 },
    { lat: 48.0965304, lng: 18.9826436 },
    { lat: 48.0953099, lng: 18.9832486 },
    { lat: 48.095161, lng: 18.9810511 },
    { lat: 48.0953201, lng: 18.9795145 },
    { lat: 48.0946829, lng: 18.9793126 },
    { lat: 48.0936879, lng: 18.9791273 },
    { lat: 48.0930503, lng: 18.9791628 },
    { lat: 48.0925596, lng: 18.9790017 },
    { lat: 48.0918542, lng: 18.9785061 },
    { lat: 48.0909593, lng: 18.9782279 },
    { lat: 48.0909606, lng: 18.9782122 },
    { lat: 48.0910265, lng: 18.9771431 },
    { lat: 48.0911013, lng: 18.9766902 },
    { lat: 48.091045, lng: 18.9759017 },
    { lat: 48.09121, lng: 18.9747221 },
    { lat: 48.0912477, lng: 18.9735879 },
    { lat: 48.0911301, lng: 18.9731425 },
    { lat: 48.0912314, lng: 18.9727877 },
    { lat: 48.0911537, lng: 18.9726831 },
    { lat: 48.0911605, lng: 18.9723328 },
    { lat: 48.0910998, lng: 18.9721398 },
    { lat: 48.0911245, lng: 18.9719574 },
    { lat: 48.091036, lng: 18.9715965 },
    { lat: 48.0910646, lng: 18.9710829 },
    { lat: 48.0909774, lng: 18.9708104 },
    { lat: 48.0908299, lng: 18.9707921 },
    { lat: 48.0907058, lng: 18.9701743 },
    { lat: 48.090727, lng: 18.9685048 },
    { lat: 48.0906078, lng: 18.9679823 },
    { lat: 48.0906629, lng: 18.9668224 },
    { lat: 48.0908638, lng: 18.965638 },
    { lat: 48.0912818, lng: 18.9644718 },
    { lat: 48.0914886, lng: 18.9636471 },
    { lat: 48.0916965, lng: 18.9624072 },
    { lat: 48.0918273, lng: 18.9622113 },
    { lat: 48.0919269, lng: 18.96222 },
    { lat: 48.0922427, lng: 18.9613458 },
    { lat: 48.0922294, lng: 18.959792 },
    { lat: 48.0923544, lng: 18.9582551 },
    { lat: 48.0927126, lng: 18.9556456 },
    { lat: 48.0930496, lng: 18.9538741 },
    { lat: 48.0934551, lng: 18.9540156 },
    { lat: 48.0943538, lng: 18.9503214 },
    { lat: 48.0946356, lng: 18.9489452 },
    { lat: 48.0947007, lng: 18.9479759 },
    { lat: 48.0948125, lng: 18.9478025 },
    { lat: 48.0952539, lng: 18.9477493 },
    { lat: 48.0952582, lng: 18.9471309 },
    { lat: 48.095386, lng: 18.9455459 },
    { lat: 48.0953748, lng: 18.9444562 },
    { lat: 48.0952398, lng: 18.9430623 },
    { lat: 48.0949036, lng: 18.9408083 },
    { lat: 48.0950528, lng: 18.9407273 },
    { lat: 48.0950611, lng: 18.9403031 },
    { lat: 48.0951473, lng: 18.9401605 },
    { lat: 48.0955294, lng: 18.9352247 },
    { lat: 48.0956266, lng: 18.9352293 },
    { lat: 48.0956584, lng: 18.9349075 },
    { lat: 48.0957704, lng: 18.9326703 },
    { lat: 48.0957557, lng: 18.9312523 },
    { lat: 48.0962079, lng: 18.9282669 },
    { lat: 48.0964016, lng: 18.9280008 },
    { lat: 48.0966658, lng: 18.9265628 },
    { lat: 48.0966971, lng: 18.9263925 },
  ],
  Kuraľany: [
    { lat: 47.9630997, lng: 18.5545992 },
    { lat: 47.9653417, lng: 18.5565724 },
    { lat: 47.9698061, lng: 18.5613126 },
    { lat: 47.9708117, lng: 18.5618151 },
    { lat: 47.9743425, lng: 18.563373 },
    { lat: 47.9754116, lng: 18.5644937 },
    { lat: 47.9798454, lng: 18.5684784 },
    { lat: 47.980661, lng: 18.5693213 },
    { lat: 47.9835623, lng: 18.5720672 },
    { lat: 47.986563, lng: 18.5751088 },
    { lat: 47.9892845, lng: 18.5780562 },
    { lat: 47.995872, lng: 18.5853662 },
    { lat: 47.99732, lng: 18.5871048 },
    { lat: 47.9978633, lng: 18.5875943 },
    { lat: 47.9978902, lng: 18.5876326 },
    { lat: 47.9984638, lng: 18.5850739 },
    { lat: 48.0007051, lng: 18.5760623 },
    { lat: 48.0010107, lng: 18.5746634 },
    { lat: 48.0014231, lng: 18.5730775 },
    { lat: 48.0040268, lng: 18.5621601 },
    { lat: 48.0051681, lng: 18.5584528 },
    { lat: 48.0051718, lng: 18.5584386 },
    { lat: 48.0049195, lng: 18.5583029 },
    { lat: 48.0043657, lng: 18.5577722 },
    { lat: 48.0002895, lng: 18.5537279 },
    { lat: 47.9967105, lng: 18.5497491 },
    { lat: 47.9903036, lng: 18.5435476 },
    { lat: 47.9893769, lng: 18.542494 },
    { lat: 47.988604, lng: 18.5413272 },
    { lat: 47.9892025, lng: 18.540627 },
    { lat: 47.9926775, lng: 18.5359491 },
    { lat: 47.9896694, lng: 18.5328201 },
    { lat: 47.9880698, lng: 18.5312913 },
    { lat: 47.9879971, lng: 18.5310583 },
    { lat: 47.9823549, lng: 18.525709 },
    { lat: 47.9803237, lng: 18.5235605 },
    { lat: 47.9802654, lng: 18.5235022 },
    { lat: 47.980233, lng: 18.5234692 },
    { lat: 47.9795273, lng: 18.5245368 },
    { lat: 47.9766933, lng: 18.5272765 },
    { lat: 47.9758949, lng: 18.5278889 },
    { lat: 47.9752935, lng: 18.5288049 },
    { lat: 47.9741242, lng: 18.5309087 },
    { lat: 47.9736731, lng: 18.5318449 },
    { lat: 47.9728126, lng: 18.5345371 },
    { lat: 47.9722891, lng: 18.5359211 },
    { lat: 47.9716612, lng: 18.5373749 },
    { lat: 47.9710853, lng: 18.5385279 },
    { lat: 47.9702891, lng: 18.5397935 },
    { lat: 47.9688805, lng: 18.5425319 },
    { lat: 47.9676197, lng: 18.5448417 },
    { lat: 47.966303, lng: 18.5474485 },
    { lat: 47.9649495, lng: 18.5498172 },
    { lat: 47.9644292, lng: 18.5508555 },
    { lat: 47.963249, lng: 18.554178 },
    { lat: 47.9630997, lng: 18.5545992 },
  ],
  HronskéKľačany: [
    { lat: 48.2231506, lng: 18.5619435 },
    { lat: 48.2230884, lng: 18.561965 },
    { lat: 48.222962, lng: 18.5620877 },
    { lat: 48.2230102, lng: 18.5628196 },
    { lat: 48.2229538, lng: 18.5629019 },
    { lat: 48.2227651, lng: 18.56294 },
    { lat: 48.2225135, lng: 18.5631025 },
    { lat: 48.2223507, lng: 18.5631154 },
    { lat: 48.2221183, lng: 18.5633219 },
    { lat: 48.2217434, lng: 18.5632321 },
    { lat: 48.2211767, lng: 18.5634913 },
    { lat: 48.2208092, lng: 18.5640709 },
    { lat: 48.2208302, lng: 18.5645964 },
    { lat: 48.2208696, lng: 18.5646164 },
    { lat: 48.2228509, lng: 18.5740927 },
    { lat: 48.2326368, lng: 18.5821431 },
    { lat: 48.2326563, lng: 18.5822104 },
    { lat: 48.2322944, lng: 18.5824203 },
    { lat: 48.2322435, lng: 18.5824205 },
    { lat: 48.2320151, lng: 18.5821595 },
    { lat: 48.231833, lng: 18.5821913 },
    { lat: 48.2317021, lng: 18.5824614 },
    { lat: 48.2317254, lng: 18.5826192 },
    { lat: 48.2317899, lng: 18.5826838 },
    { lat: 48.2316268, lng: 18.5830858 },
    { lat: 48.2314062, lng: 18.5834419 },
    { lat: 48.2312843, lng: 18.5840841 },
    { lat: 48.2311102, lng: 18.5846308 },
    { lat: 48.2308748, lng: 18.5848857 },
    { lat: 48.2304416, lng: 18.5850091 },
    { lat: 48.2302551, lng: 18.5853546 },
    { lat: 48.2301795, lng: 18.5856189 },
    { lat: 48.2299572, lng: 18.5859879 },
    { lat: 48.2296594, lng: 18.5860328 },
    { lat: 48.2295421, lng: 18.5863206 },
    { lat: 48.2295674, lng: 18.5866586 },
    { lat: 48.229493, lng: 18.5869111 },
    { lat: 48.2293729, lng: 18.5870011 },
    { lat: 48.2291242, lng: 18.587051 },
    { lat: 48.2288261, lng: 18.5876482 },
    { lat: 48.2287458, lng: 18.5879298 },
    { lat: 48.2322332, lng: 18.5925326 },
    { lat: 48.2364512, lng: 18.5982243 },
    { lat: 48.2364779, lng: 18.5981991 },
    { lat: 48.2368571, lng: 18.5994229 },
    { lat: 48.2369431, lng: 18.5999251 },
    { lat: 48.2372875, lng: 18.6005928 },
    { lat: 48.2387946, lng: 18.6029357 },
    { lat: 48.2396411, lng: 18.6021168 },
    { lat: 48.2393972, lng: 18.6016145 },
    { lat: 48.2391901, lng: 18.6013146 },
    { lat: 48.2392625, lng: 18.6008207 },
    { lat: 48.2392566, lng: 18.6005436 },
    { lat: 48.239762, lng: 18.6012072 },
    { lat: 48.2398718, lng: 18.6016092 },
    { lat: 48.2405382, lng: 18.6018109 },
    { lat: 48.240573, lng: 18.601065 },
    { lat: 48.2406171, lng: 18.5995342 },
    { lat: 48.2415167, lng: 18.5983061 },
    { lat: 48.242499, lng: 18.5974355 },
    { lat: 48.2426911, lng: 18.5973392 },
    { lat: 48.2451222, lng: 18.5953554 },
    { lat: 48.2451528, lng: 18.595236 },
    { lat: 48.2461903, lng: 18.5953331 },
    { lat: 48.2469532, lng: 18.5951584 },
    { lat: 48.2531371, lng: 18.5930323 },
    { lat: 48.2554611, lng: 18.5924007 },
    { lat: 48.2554457, lng: 18.5923439 },
    { lat: 48.2554274, lng: 18.5922774 },
    { lat: 48.251898, lng: 18.5792075 },
    { lat: 48.2536736, lng: 18.5779117 },
    { lat: 48.2406, lng: 18.554965 },
    { lat: 48.240602, lng: 18.5550193 },
    { lat: 48.2400003, lng: 18.5549306 },
    { lat: 48.2398875, lng: 18.5548256 },
    { lat: 48.2382298, lng: 18.5519816 },
    { lat: 48.2381598, lng: 18.5519166 },
    { lat: 48.2380476, lng: 18.5519301 },
    { lat: 48.2376021, lng: 18.5520748 },
    { lat: 48.2371174, lng: 18.5524066 },
    { lat: 48.2368589, lng: 18.5530838 },
    { lat: 48.2367538, lng: 18.5532015 },
    { lat: 48.2365575, lng: 18.5531404 },
    { lat: 48.2361211, lng: 18.552381 },
    { lat: 48.2359059, lng: 18.552307 },
    { lat: 48.2357822, lng: 18.552375 },
    { lat: 48.2354997, lng: 18.5529797 },
    { lat: 48.2351932, lng: 18.5531933 },
    { lat: 48.2349294, lng: 18.5536483 },
    { lat: 48.2343266, lng: 18.5541721 },
    { lat: 48.2338996, lng: 18.5551258 },
    { lat: 48.2337062, lng: 18.5557532 },
    { lat: 48.2331114, lng: 18.557023 },
    { lat: 48.2327696, lng: 18.5573106 },
    { lat: 48.2325638, lng: 18.5573805 },
    { lat: 48.2323662, lng: 18.5575665 },
    { lat: 48.2322434, lng: 18.5578133 },
    { lat: 48.2320413, lng: 18.5579344 },
    { lat: 48.2319373, lng: 18.5579295 },
    { lat: 48.2315039, lng: 18.5577005 },
    { lat: 48.231257, lng: 18.557745 },
    { lat: 48.2311499, lng: 18.557847 },
    { lat: 48.2312266, lng: 18.5583183 },
    { lat: 48.2311926, lng: 18.5583752 },
    { lat: 48.2308167, lng: 18.5585771 },
    { lat: 48.2304399, lng: 18.5589252 },
    { lat: 48.2302596, lng: 18.5590129 },
    { lat: 48.2297907, lng: 18.5588547 },
    { lat: 48.2289948, lng: 18.5587205 },
    { lat: 48.2284987, lng: 18.5588466 },
    { lat: 48.2282097, lng: 18.5587788 },
    { lat: 48.2279628, lng: 18.5588839 },
    { lat: 48.2278275, lng: 18.5588053 },
    { lat: 48.2276405, lng: 18.5588092 },
    { lat: 48.2274487, lng: 18.5586046 },
    { lat: 48.2272844, lng: 18.5587341 },
    { lat: 48.2269157, lng: 18.5587979 },
    { lat: 48.226789, lng: 18.5586256 },
    { lat: 48.2265833, lng: 18.5585179 },
    { lat: 48.2263711, lng: 18.5585359 },
    { lat: 48.2255578, lng: 18.5593158 },
    { lat: 48.2254236, lng: 18.559339 },
    { lat: 48.2252217, lng: 18.5592559 },
    { lat: 48.2251383, lng: 18.5592955 },
    { lat: 48.2246623, lng: 18.5599133 },
    { lat: 48.2243164, lng: 18.5602091 },
    { lat: 48.2242697, lng: 18.5607575 },
    { lat: 48.2241418, lng: 18.5609338 },
    { lat: 48.2239569, lng: 18.5610358 },
    { lat: 48.2238066, lng: 18.5615283 },
    { lat: 48.2236321, lng: 18.5617167 },
    { lat: 48.2232627, lng: 18.5618937 },
    { lat: 48.2231506, lng: 18.5619435 },
  ],
  Kukučínov: [
    { lat: 48.0947194, lng: 18.7417392 },
    { lat: 48.0954897, lng: 18.7410873 },
    { lat: 48.0971687, lng: 18.7398774 },
    { lat: 48.0978643, lng: 18.7389238 },
    { lat: 48.0996871, lng: 18.7359559 },
    { lat: 48.1005459, lng: 18.7348233 },
    { lat: 48.1012217, lng: 18.7337534 },
    { lat: 48.1019733, lng: 18.7326886 },
    { lat: 48.1027816, lng: 18.7317543 },
    { lat: 48.1036982, lng: 18.7301877 },
    { lat: 48.104647, lng: 18.7287595 },
    { lat: 48.1053909, lng: 18.7280377 },
    { lat: 48.1057378, lng: 18.7276046 },
    { lat: 48.1046297, lng: 18.7229642 },
    { lat: 48.103564, lng: 18.7198269 },
    { lat: 48.1029144, lng: 18.7173468 },
    { lat: 48.1015011, lng: 18.71383 },
    { lat: 48.1006067, lng: 18.7119819 },
    { lat: 48.100392, lng: 18.7114075 },
    { lat: 48.0997333, lng: 18.7101043 },
    { lat: 48.0996615, lng: 18.709964 },
    { lat: 48.0997024, lng: 18.7098021 },
    { lat: 48.1002553, lng: 18.7091745 },
    { lat: 48.1009853, lng: 18.7086147 },
    { lat: 48.1011408, lng: 18.7085707 },
    { lat: 48.1015548, lng: 18.7082788 },
    { lat: 48.1035938, lng: 18.7066801 },
    { lat: 48.1035655, lng: 18.7066143 },
    { lat: 48.1023784, lng: 18.706224 },
    { lat: 48.1018829, lng: 18.7059661 },
    { lat: 48.1017271, lng: 18.7057106 },
    { lat: 48.1020153, lng: 18.7053021 },
    { lat: 48.1018579, lng: 18.7049766 },
    { lat: 48.1019, lng: 18.7048551 },
    { lat: 48.10239, lng: 18.7048465 },
    { lat: 48.1024552, lng: 18.7047593 },
    { lat: 48.1024276, lng: 18.7044948 },
    { lat: 48.1022783, lng: 18.7041323 },
    { lat: 48.1024088, lng: 18.7036841 },
    { lat: 48.1025703, lng: 18.7036027 },
    { lat: 48.1027886, lng: 18.7041806 },
    { lat: 48.1030945, lng: 18.7043761 },
    { lat: 48.1031643, lng: 18.7042683 },
    { lat: 48.1032683, lng: 18.7033927 },
    { lat: 48.1021751, lng: 18.7009719 },
    { lat: 48.1018182, lng: 18.7000832 },
    { lat: 48.1014304, lng: 18.6996047 },
    { lat: 48.1019827, lng: 18.6980237 },
    { lat: 48.1024708, lng: 18.6974528 },
    { lat: 48.1029633, lng: 18.6966482 },
    { lat: 48.1038095, lng: 18.694792 },
    { lat: 48.1039468, lng: 18.6947833 },
    { lat: 48.1040918, lng: 18.6941882 },
    { lat: 48.1038992, lng: 18.6941933 },
    { lat: 48.1036539, lng: 18.6940365 },
    { lat: 48.1036044, lng: 18.6939323 },
    { lat: 48.1032952, lng: 18.6938265 },
    { lat: 48.1027728, lng: 18.6934866 },
    { lat: 48.1024691, lng: 18.6935654 },
    { lat: 48.1024852, lng: 18.69332 },
    { lat: 48.1027015, lng: 18.692878 },
    { lat: 48.1031844, lng: 18.692145 },
    { lat: 48.1041815, lng: 18.6914121 },
    { lat: 48.104419, lng: 18.6908815 },
    { lat: 48.1044605, lng: 18.6905628 },
    { lat: 48.1042953, lng: 18.6893176 },
    { lat: 48.1041785, lng: 18.6889918 },
    { lat: 48.1029606, lng: 18.6872358 },
    { lat: 48.1027867, lng: 18.6871106 },
    { lat: 48.102608, lng: 18.6871004 },
    { lat: 48.1024688, lng: 18.6870049 },
    { lat: 48.1022599, lng: 18.6867061 },
    { lat: 48.102047, lng: 18.6863784 },
    { lat: 48.1014645, lng: 18.6851351 },
    { lat: 48.1007087, lng: 18.6837948 },
    { lat: 48.1001842, lng: 18.6833547 },
    { lat: 48.0999082, lng: 18.6829214 },
    { lat: 48.0992376, lng: 18.6822576 },
    { lat: 48.0982949, lng: 18.6810728 },
    { lat: 48.0981627, lng: 18.6808171 },
    { lat: 48.0976842, lng: 18.6807479 },
    { lat: 48.0976352, lng: 18.6806776 },
    { lat: 48.0974884, lng: 18.6808607 },
    { lat: 48.0973839, lng: 18.6811612 },
    { lat: 48.0973099, lng: 18.6815871 },
    { lat: 48.097177, lng: 18.6818434 },
    { lat: 48.0970601, lng: 18.6819322 },
    { lat: 48.096472, lng: 18.6807533 },
    { lat: 48.0954225, lng: 18.6797603 },
    { lat: 48.0950838, lng: 18.679335 },
    { lat: 48.0946133, lng: 18.6783734 },
    { lat: 48.0945537, lng: 18.6781005 },
    { lat: 48.094243, lng: 18.6778838 },
    { lat: 48.0941046, lng: 18.6773462 },
    { lat: 48.0938666, lng: 18.676804 },
    { lat: 48.0931759, lng: 18.676189 },
    { lat: 48.0928392, lng: 18.6757624 },
    { lat: 48.0923894, lng: 18.674659 },
    { lat: 48.0918736, lng: 18.6735632 },
    { lat: 48.091522, lng: 18.6722742 },
    { lat: 48.09142, lng: 18.6713703 },
    { lat: 48.0904798, lng: 18.6685195 },
    { lat: 48.0904498, lng: 18.6680903 },
    { lat: 48.0905034, lng: 18.6677049 },
    { lat: 48.0905124, lng: 18.6669545 },
    { lat: 48.0901576, lng: 18.6654405 },
    { lat: 48.0899034, lng: 18.6651201 },
    { lat: 48.0897892, lng: 18.6652196 },
    { lat: 48.0893489, lng: 18.6654603 },
    { lat: 48.0888009, lng: 18.6658993 },
    { lat: 48.0879953, lng: 18.666813 },
    { lat: 48.0868427, lng: 18.666295 },
    { lat: 48.0862021, lng: 18.6659165 },
    { lat: 48.0861581, lng: 18.6668777 },
    { lat: 48.0856757, lng: 18.6670307 },
    { lat: 48.0852093, lng: 18.6671038 },
    { lat: 48.0850034, lng: 18.6670669 },
    { lat: 48.0848035, lng: 18.6669365 },
    { lat: 48.0846106, lng: 18.6667093 },
    { lat: 48.0844414, lng: 18.6662895 },
    { lat: 48.083711, lng: 18.66355 },
    { lat: 48.0829955, lng: 18.6641473 },
    { lat: 48.0828492, lng: 18.6643607 },
    { lat: 48.0824645, lng: 18.6657781 },
    { lat: 48.082157, lng: 18.666157 },
    { lat: 48.0818678, lng: 18.6662331 },
    { lat: 48.081158, lng: 18.6667919 },
    { lat: 48.0808917, lng: 18.6668282 },
    { lat: 48.0802323, lng: 18.6665257 },
    { lat: 48.0796673, lng: 18.6655671 },
    { lat: 48.0790167, lng: 18.6629424 },
    { lat: 48.0791372, lng: 18.6625226 },
    { lat: 48.0785064, lng: 18.6631227 },
    { lat: 48.0773098, lng: 18.6646029 },
    { lat: 48.0769814, lng: 18.665082 },
    { lat: 48.0768869, lng: 18.6654407 },
    { lat: 48.0763173, lng: 18.6668986 },
    { lat: 48.0759635, lng: 18.6676335 },
    { lat: 48.0757201, lng: 18.667848 },
    { lat: 48.075618, lng: 18.6680831 },
    { lat: 48.0751211, lng: 18.66824 },
    { lat: 48.0745702, lng: 18.6680862 },
    { lat: 48.0743357, lng: 18.6676708 },
    { lat: 48.0740679, lng: 18.666954 },
    { lat: 48.0705697, lng: 18.6653149 },
    { lat: 48.0700874, lng: 18.6651561 },
    { lat: 48.0700113, lng: 18.6656057 },
    { lat: 48.0712016, lng: 18.6660079 },
    { lat: 48.0751358, lng: 18.6698047 },
    { lat: 48.0749725, lng: 18.6707487 },
    { lat: 48.0750381, lng: 18.6715047 },
    { lat: 48.0750379, lng: 18.6721466 },
    { lat: 48.0749439, lng: 18.6727871 },
    { lat: 48.0747681, lng: 18.6734356 },
    { lat: 48.0745597, lng: 18.6738377 },
    { lat: 48.0740789, lng: 18.6741442 },
    { lat: 48.0736139, lng: 18.6742417 },
    { lat: 48.0732485, lng: 18.6741584 },
    { lat: 48.0724934, lng: 18.6735866 },
    { lat: 48.0720045, lng: 18.6729091 },
    { lat: 48.0717919, lng: 18.6723129 },
    { lat: 48.0715366, lng: 18.6713672 },
    { lat: 48.0713687, lng: 18.6705125 },
    { lat: 48.0711974, lng: 18.6701822 },
    { lat: 48.0711309, lng: 18.6697868 },
    { lat: 48.0709486, lng: 18.6696064 },
    { lat: 48.070709, lng: 18.6701187 },
    { lat: 48.0701433, lng: 18.6709647 },
    { lat: 48.069658, lng: 18.6713345 },
    { lat: 48.0699679, lng: 18.6725377 },
    { lat: 48.0704188, lng: 18.6735777 },
    { lat: 48.0704102, lng: 18.6746077 },
    { lat: 48.0700098, lng: 18.6757141 },
    { lat: 48.0699409, lng: 18.6763205 },
    { lat: 48.0698404, lng: 18.676713 },
    { lat: 48.0694455, lng: 18.6775451 },
    { lat: 48.0691131, lng: 18.6780326 },
    { lat: 48.0683211, lng: 18.6788425 },
    { lat: 48.0676362, lng: 18.6793166 },
    { lat: 48.0671329, lng: 18.6795848 },
    { lat: 48.0666764, lng: 18.6794813 },
    { lat: 48.0667222, lng: 18.6810608 },
    { lat: 48.0669296, lng: 18.6841113 },
    { lat: 48.0672183, lng: 18.6841757 },
    { lat: 48.0674022, lng: 18.6840718 },
    { lat: 48.0675617, lng: 18.6838298 },
    { lat: 48.0678146, lng: 18.6834359 },
    { lat: 48.0684287, lng: 18.6833618 },
    { lat: 48.0688964, lng: 18.6840243 },
    { lat: 48.0696687, lng: 18.6847944 },
    { lat: 48.0697851, lng: 18.6851119 },
    { lat: 48.0699631, lng: 18.6851691 },
    { lat: 48.0704938, lng: 18.6856439 },
    { lat: 48.070844, lng: 18.6857139 },
    { lat: 48.0710659, lng: 18.6859627 },
    { lat: 48.0712663, lng: 18.6858473 },
    { lat: 48.0717653, lng: 18.6865689 },
    { lat: 48.0724444, lng: 18.6869697 },
    { lat: 48.0731746, lng: 18.6879407 },
    { lat: 48.0738593, lng: 18.6885899 },
    { lat: 48.0744194, lng: 18.688163 },
    { lat: 48.0749005, lng: 18.6879864 },
    { lat: 48.0751805, lng: 18.6887872 },
    { lat: 48.0767792, lng: 18.6912456 },
    { lat: 48.0775022, lng: 18.691246 },
    { lat: 48.0778964, lng: 18.6919482 },
    { lat: 48.0784318, lng: 18.6933888 },
    { lat: 48.0784257, lng: 18.6939863 },
    { lat: 48.0787488, lng: 18.6957149 },
    { lat: 48.0789455, lng: 18.6964368 },
    { lat: 48.0798995, lng: 18.6988858 },
    { lat: 48.0806305, lng: 18.7005416 },
    { lat: 48.0808685, lng: 18.7013416 },
    { lat: 48.0817613, lng: 18.7035769 },
    { lat: 48.0818755, lng: 18.7035382 },
    { lat: 48.0821995, lng: 18.7031023 },
    { lat: 48.0822527, lng: 18.7031301 },
    { lat: 48.0822709, lng: 18.7033187 },
    { lat: 48.0824344, lng: 18.7033619 },
    { lat: 48.0827348, lng: 18.7032879 },
    { lat: 48.0830379, lng: 18.7030082 },
    { lat: 48.0831819, lng: 18.7033165 },
    { lat: 48.0832685, lng: 18.7033714 },
    { lat: 48.083609, lng: 18.7033483 },
    { lat: 48.0836039, lng: 18.7030244 },
    { lat: 48.0835197, lng: 18.7027604 },
    { lat: 48.0831808, lng: 18.7025791 },
    { lat: 48.0832322, lng: 18.7024449 },
    { lat: 48.0834986, lng: 18.7024282 },
    { lat: 48.0837054, lng: 18.7022482 },
    { lat: 48.0837607, lng: 18.7020818 },
    { lat: 48.0835709, lng: 18.7018231 },
    { lat: 48.0835375, lng: 18.7016447 },
    { lat: 48.0835995, lng: 18.7013308 },
    { lat: 48.0835418, lng: 18.7006975 },
    { lat: 48.0836079, lng: 18.7005422 },
    { lat: 48.0839614, lng: 18.7008226 },
    { lat: 48.0849651, lng: 18.7019732 },
    { lat: 48.085944, lng: 18.7032728 },
    { lat: 48.0870036, lng: 18.7049188 },
    { lat: 48.0883777, lng: 18.7065646 },
    { lat: 48.0885961, lng: 18.7077307 },
    { lat: 48.0889146, lng: 18.7090235 },
    { lat: 48.089032, lng: 18.7092998 },
    { lat: 48.0891722, lng: 18.7099963 },
    { lat: 48.0900255, lng: 18.7123391 },
    { lat: 48.0897931, lng: 18.7127613 },
    { lat: 48.0891191, lng: 18.7126415 },
    { lat: 48.089123, lng: 18.7126922 },
    { lat: 48.0896464, lng: 18.7139165 },
    { lat: 48.0894711, lng: 18.714432 },
    { lat: 48.0893293, lng: 18.7146042 },
    { lat: 48.0891902, lng: 18.7145524 },
    { lat: 48.0890998, lng: 18.7143617 },
    { lat: 48.0890114, lng: 18.7148584 },
    { lat: 48.0889591, lng: 18.7156506 },
    { lat: 48.0889757, lng: 18.7160867 },
    { lat: 48.0887508, lng: 18.7176971 },
    { lat: 48.0887935, lng: 18.7190172 },
    { lat: 48.0890951, lng: 18.7194676 },
    { lat: 48.0894588, lng: 18.7203399 },
    { lat: 48.0896882, lng: 18.7216131 },
    { lat: 48.0897524, lng: 18.7222206 },
    { lat: 48.0897412, lng: 18.7229991 },
    { lat: 48.089937, lng: 18.7247176 },
    { lat: 48.0908307, lng: 18.7268333 },
    { lat: 48.0893833, lng: 18.7294346 },
    { lat: 48.0911523, lng: 18.7331763 },
    { lat: 48.0935527, lng: 18.7379324 },
    { lat: 48.0938227, lng: 18.7390404 },
    { lat: 48.0942745, lng: 18.7403398 },
    { lat: 48.0947194, lng: 18.7417392 },
  ],
  NovýTekov: [
    { lat: 48.2540428, lng: 18.5244093 },
    { lat: 48.2550741, lng: 18.524229 },
    { lat: 48.2556569, lng: 18.5240596 },
    { lat: 48.256357, lng: 18.5234374 },
    { lat: 48.2566004, lng: 18.5232271 },
    { lat: 48.2577008, lng: 18.5222385 },
    { lat: 48.2589485, lng: 18.5216876 },
    { lat: 48.2627448, lng: 18.5211638 },
    { lat: 48.2648615, lng: 18.5210473 },
    { lat: 48.2641934, lng: 18.512647 },
    { lat: 48.2629422, lng: 18.5128708 },
    { lat: 48.2629462, lng: 18.5126777 },
    { lat: 48.263124, lng: 18.5121607 },
    { lat: 48.2614799, lng: 18.5070897 },
    { lat: 48.2606758, lng: 18.5039768 },
    { lat: 48.2601902, lng: 18.5016022 },
    { lat: 48.2597996, lng: 18.5003151 },
    { lat: 48.2607244, lng: 18.4987629 },
    { lat: 48.2610019, lng: 18.4982126 },
    { lat: 48.2615632, lng: 18.4961062 },
    { lat: 48.2622402, lng: 18.4944182 },
    { lat: 48.2632946, lng: 18.4914609 },
    { lat: 48.2638036, lng: 18.4905316 },
    { lat: 48.2641628, lng: 18.4900323 },
    { lat: 48.2642695, lng: 18.4897985 },
    { lat: 48.265778, lng: 18.4874227 },
    { lat: 48.2669576, lng: 18.4861974 },
    { lat: 48.2675805, lng: 18.4857148 },
    { lat: 48.2685394, lng: 18.4856106 },
    { lat: 48.2694344, lng: 18.4853345 },
    { lat: 48.2713539, lng: 18.4834992 },
    { lat: 48.2735279, lng: 18.4811051 },
    { lat: 48.274394, lng: 18.480587 },
    { lat: 48.274381, lng: 18.480524 },
    { lat: 48.27437, lng: 18.480287 },
    { lat: 48.27437, lng: 18.480185 },
    { lat: 48.274326, lng: 18.479143 },
    { lat: 48.274225, lng: 18.477111 },
    { lat: 48.274169, lng: 18.476092 },
    { lat: 48.274162, lng: 18.47594 },
    { lat: 48.274097, lng: 18.474595 },
    { lat: 48.274019, lng: 18.473166 },
    { lat: 48.273974, lng: 18.471652 },
    { lat: 48.273931, lng: 18.470648 },
    { lat: 48.273913, lng: 18.470124 },
    { lat: 48.273914, lng: 18.469666 },
    { lat: 48.2739, lng: 18.46924 },
    { lat: 48.273891, lng: 18.469099 },
    { lat: 48.273825, lng: 18.468459 },
    { lat: 48.273794, lng: 18.467078 },
    { lat: 48.273723, lng: 18.465856 },
    { lat: 48.273703, lng: 18.465571 },
    { lat: 48.27368, lng: 18.464879 },
    { lat: 48.27197, lng: 18.4635161 },
    { lat: 48.2714718, lng: 18.4628354 },
    { lat: 48.2705538, lng: 18.4620313 },
    { lat: 48.2701625, lng: 18.4617926 },
    { lat: 48.2693996, lng: 18.4614231 },
    { lat: 48.2688656, lng: 18.4612749 },
    { lat: 48.2685054, lng: 18.46139 },
    { lat: 48.2678178, lng: 18.4618042 },
    { lat: 48.2673338, lng: 18.4599601 },
    { lat: 48.2642744, lng: 18.4609343 },
    { lat: 48.263487, lng: 18.4587876 },
    { lat: 48.2632289, lng: 18.4578438 },
    { lat: 48.26173, lng: 18.4580691 },
    { lat: 48.2609441, lng: 18.4586875 },
    { lat: 48.2554398, lng: 18.4608709 },
    { lat: 48.253131, lng: 18.460855 },
    { lat: 48.2524322, lng: 18.461291 },
    { lat: 48.2523807, lng: 18.4612969 },
    { lat: 48.2494593, lng: 18.4616303 },
    { lat: 48.2469616, lng: 18.4623568 },
    { lat: 48.2458286, lng: 18.4618431 },
    { lat: 48.245649, lng: 18.4617616 },
    { lat: 48.2449125, lng: 18.4615213 },
    { lat: 48.2444463, lng: 18.4611733 },
    { lat: 48.2440868, lng: 18.4612592 },
    { lat: 48.2431372, lng: 18.4610643 },
    { lat: 48.2429602, lng: 18.4611243 },
    { lat: 48.2428982, lng: 18.4610259 },
    { lat: 48.2427445, lng: 18.4610199 },
    { lat: 48.2419762, lng: 18.4570956 },
    { lat: 48.2414365, lng: 18.4555486 },
    { lat: 48.2415612, lng: 18.4542671 },
    { lat: 48.2418331, lng: 18.4541871 },
    { lat: 48.2417436, lng: 18.4533082 },
    { lat: 48.2408743, lng: 18.4518042 },
    { lat: 48.2403071, lng: 18.4506773 },
    { lat: 48.2410089, lng: 18.4498707 },
    { lat: 48.2399711, lng: 18.4447435 },
    { lat: 48.2377365, lng: 18.4442622 },
    { lat: 48.2377426, lng: 18.4442112 },
    { lat: 48.2364953, lng: 18.4440585 },
    { lat: 48.2359452, lng: 18.4439239 },
    { lat: 48.2348902, lng: 18.4441608 },
    { lat: 48.2336492, lng: 18.4441421 },
    { lat: 48.233905, lng: 18.4455715 },
    { lat: 48.2338865, lng: 18.445664 },
    { lat: 48.2334016, lng: 18.4461908 },
    { lat: 48.23147, lng: 18.447964 },
    { lat: 48.2308499, lng: 18.4488777 },
    { lat: 48.2297063, lng: 18.4500565 },
    { lat: 48.2278237, lng: 18.4528674 },
    { lat: 48.2259814, lng: 18.4562802 },
    { lat: 48.2253889, lng: 18.4571039 },
    { lat: 48.2247041, lng: 18.4577534 },
    { lat: 48.2239502, lng: 18.4583128 },
    { lat: 48.2224852, lng: 18.4596976 },
    { lat: 48.2201795, lng: 18.4623685 },
    { lat: 48.2183875, lng: 18.4642204 },
    { lat: 48.2182646, lng: 18.4643433 },
    { lat: 48.2185708, lng: 18.46498 },
    { lat: 48.2190286, lng: 18.4655812 },
    { lat: 48.21994, lng: 18.4666129 },
    { lat: 48.2209144, lng: 18.4683906 },
    { lat: 48.220951, lng: 18.4687211 },
    { lat: 48.2211503, lng: 18.4691617 },
    { lat: 48.2209992, lng: 18.4692839 },
    { lat: 48.2203817, lng: 18.469398 },
    { lat: 48.2191471, lng: 18.4703801 },
    { lat: 48.2186743, lng: 18.4705424 },
    { lat: 48.2186178, lng: 18.4706219 },
    { lat: 48.217913, lng: 18.471678 },
    { lat: 48.2173286, lng: 18.4727126 },
    { lat: 48.215515, lng: 18.4774187 },
    { lat: 48.2134359, lng: 18.4806866 },
    { lat: 48.2124031, lng: 18.4825022 },
    { lat: 48.2115033, lng: 18.4847638 },
    { lat: 48.2114954, lng: 18.4847828 },
    { lat: 48.2107984, lng: 18.4841342 },
    { lat: 48.2104246, lng: 18.4837634 },
    { lat: 48.2092576, lng: 18.482982 },
    { lat: 48.2092104, lng: 18.483431 },
    { lat: 48.2090491, lng: 18.4852417 },
    { lat: 48.2090131, lng: 18.4865798 },
    { lat: 48.2090511, lng: 18.4874903 },
    { lat: 48.2099968, lng: 18.4932228 },
    { lat: 48.2101007, lng: 18.4935918 },
    { lat: 48.210308, lng: 18.4939537 },
    { lat: 48.2102194, lng: 18.4941072 },
    { lat: 48.2103852, lng: 18.4947558 },
    { lat: 48.2110706, lng: 18.4970261 },
    { lat: 48.2136946, lng: 18.5043545 },
    { lat: 48.2152156, lng: 18.5096369 },
    { lat: 48.2172114, lng: 18.5142913 },
    { lat: 48.2172327, lng: 18.5143304 },
    { lat: 48.2172928, lng: 18.5141722 },
    { lat: 48.2173747, lng: 18.5131668 },
    { lat: 48.2174951, lng: 18.5128943 },
    { lat: 48.2177432, lng: 18.5126051 },
    { lat: 48.2180965, lng: 18.5126292 },
    { lat: 48.2182894, lng: 18.5127748 },
    { lat: 48.2184181, lng: 18.513081 },
    { lat: 48.2185898, lng: 18.5132908 },
    { lat: 48.2188643, lng: 18.5133399 },
    { lat: 48.2191855, lng: 18.5131073 },
    { lat: 48.2196238, lng: 18.5125062 },
    { lat: 48.2199319, lng: 18.5122042 },
    { lat: 48.220315, lng: 18.5127453 },
    { lat: 48.220577, lng: 18.512935 },
    { lat: 48.2211378, lng: 18.5130824 },
    { lat: 48.2214315, lng: 18.5129045 },
    { lat: 48.2216336, lng: 18.5126817 },
    { lat: 48.2218005, lng: 18.5122935 },
    { lat: 48.2221094, lng: 18.5121081 },
    { lat: 48.2222516, lng: 18.5121718 },
    { lat: 48.2226488, lng: 18.5121513 },
    { lat: 48.2228094, lng: 18.5122205 },
    { lat: 48.2231172, lng: 18.5125418 },
    { lat: 48.2232572, lng: 18.5125043 },
    { lat: 48.2245659, lng: 18.5129383 },
    { lat: 48.2247328, lng: 18.5136478 },
    { lat: 48.2248722, lng: 18.5146815 },
    { lat: 48.2256194, lng: 18.5220177 },
    { lat: 48.2258765, lng: 18.5236511 },
    { lat: 48.225639, lng: 18.5239267 },
    { lat: 48.2255258, lng: 18.5243266 },
    { lat: 48.2252108, lng: 18.5247129 },
    { lat: 48.2253675, lng: 18.5253855 },
    { lat: 48.2253583, lng: 18.5257072 },
    { lat: 48.2253086, lng: 18.5258888 },
    { lat: 48.2250371, lng: 18.5259991 },
    { lat: 48.2243322, lng: 18.5265129 },
    { lat: 48.223861, lng: 18.5273546 },
    { lat: 48.22375, lng: 18.527841 },
    { lat: 48.2237108, lng: 18.5295208 },
    { lat: 48.2246192, lng: 18.5363 },
    { lat: 48.2273648, lng: 18.5373649 },
    { lat: 48.2274482, lng: 18.5381637 },
    { lat: 48.2279968, lng: 18.5392352 },
    { lat: 48.2285064, lng: 18.5395958 },
    { lat: 48.2289445, lng: 18.5390312 },
    { lat: 48.229552, lng: 18.5384674 },
    { lat: 48.2302152, lng: 18.5382012 },
    { lat: 48.2306037, lng: 18.5367576 },
    { lat: 48.2288949, lng: 18.5318662 },
    { lat: 48.2292646, lng: 18.5312881 },
    { lat: 48.2296543, lng: 18.5315641 },
    { lat: 48.2301342, lng: 18.5316725 },
    { lat: 48.2304454, lng: 18.5314695 },
    { lat: 48.2308338, lng: 18.5314308 },
    { lat: 48.2312381, lng: 18.5318323 },
    { lat: 48.2318324, lng: 18.5334833 },
    { lat: 48.2324052, lng: 18.534543 },
    { lat: 48.2334711, lng: 18.5360173 },
    { lat: 48.2345541, lng: 18.5352004 },
    { lat: 48.2353158, lng: 18.5348959 },
    { lat: 48.236778, lng: 18.5352305 },
    { lat: 48.2371598, lng: 18.5354466 },
    { lat: 48.2387803, lng: 18.5339204 },
    { lat: 48.239328, lng: 18.5310654 },
    { lat: 48.2397237, lng: 18.5277087 },
    { lat: 48.2393707, lng: 18.5249897 },
    { lat: 48.2394757, lng: 18.5243784 },
    { lat: 48.2398162, lng: 18.524723 },
    { lat: 48.2404939, lng: 18.5247135 },
    { lat: 48.2419384, lng: 18.5251973 },
    { lat: 48.2467417, lng: 18.5246075 },
    { lat: 48.2480082, lng: 18.5243963 },
    { lat: 48.2497707, lng: 18.5252638 },
    { lat: 48.2502757, lng: 18.5261984 },
    { lat: 48.2506558, lng: 18.5265835 },
    { lat: 48.2509079, lng: 18.5266553 },
    { lat: 48.251167, lng: 18.5261193 },
    { lat: 48.2516192, lng: 18.5259922 },
    { lat: 48.2520886, lng: 18.5255653 },
    { lat: 48.2524793, lng: 18.5247981 },
    { lat: 48.2539612, lng: 18.5244424 },
    { lat: 48.2540428, lng: 18.5244093 },
  ],
  Hronovce: [
    { lat: 47.9823313, lng: 18.6794216 },
    { lat: 47.9824794, lng: 18.6794936 },
    { lat: 47.98265, lng: 18.6794974 },
    { lat: 47.9828339, lng: 18.6793321 },
    { lat: 47.9830456, lng: 18.678973 },
    { lat: 47.9831682, lng: 18.6789456 },
    { lat: 47.9833387, lng: 18.6791138 },
    { lat: 47.9834853, lng: 18.6794293 },
    { lat: 47.9836425, lng: 18.6795607 },
    { lat: 47.9839904, lng: 18.6794648 },
    { lat: 47.984024, lng: 18.6793777 },
    { lat: 47.9844998, lng: 18.6793121 },
    { lat: 47.9847762, lng: 18.6790614 },
    { lat: 47.9847788, lng: 18.6789435 },
    { lat: 47.9848602, lng: 18.6787785 },
    { lat: 47.9851949, lng: 18.6785489 },
    { lat: 47.9855675, lng: 18.6784898 },
    { lat: 47.9857094, lng: 18.6783654 },
    { lat: 47.9858215, lng: 18.678392 },
    { lat: 47.9859242, lng: 18.678516 },
    { lat: 47.9859008, lng: 18.6786691 },
    { lat: 47.9857134, lng: 18.6790204 },
    { lat: 47.9856759, lng: 18.6791833 },
    { lat: 47.9857527, lng: 18.6794762 },
    { lat: 47.9861884, lng: 18.6793373 },
    { lat: 47.9864074, lng: 18.67955 },
    { lat: 47.9866026, lng: 18.6798421 },
    { lat: 47.9871179, lng: 18.6798619 },
    { lat: 47.9872452, lng: 18.6798153 },
    { lat: 47.9874365, lng: 18.6798712 },
    { lat: 47.9874246, lng: 18.6808619 },
    { lat: 47.9875434, lng: 18.6810748 },
    { lat: 47.9883099, lng: 18.6815074 },
    { lat: 47.9887417, lng: 18.682189 },
    { lat: 47.9890399, lng: 18.6823458 },
    { lat: 47.9891025, lng: 18.6823143 },
    { lat: 47.9890103, lng: 18.6830091 },
    { lat: 47.9895808, lng: 18.6835023 },
    { lat: 47.9898042, lng: 18.6839458 },
    { lat: 47.9900761, lng: 18.6841354 },
    { lat: 47.9903432, lng: 18.6840048 },
    { lat: 47.9905861, lng: 18.6840742 },
    { lat: 47.9906423, lng: 18.6841555 },
    { lat: 47.9903442, lng: 18.684544 },
    { lat: 47.9903245, lng: 18.6846605 },
    { lat: 47.9904524, lng: 18.685025 },
    { lat: 47.9906656, lng: 18.6853582 },
    { lat: 47.9906848, lng: 18.6855246 },
    { lat: 47.9908272, lng: 18.6856371 },
    { lat: 47.9911843, lng: 18.6855049 },
    { lat: 47.9913499, lng: 18.6855603 },
    { lat: 47.9913534, lng: 18.6856479 },
    { lat: 47.9912334, lng: 18.6859324 },
    { lat: 47.9912691, lng: 18.6861957 },
    { lat: 47.9914521, lng: 18.6864508 },
    { lat: 47.9915339, lng: 18.6865255 },
    { lat: 47.9917613, lng: 18.6862899 },
    { lat: 47.9918189, lng: 18.6862893 },
    { lat: 47.9922588, lng: 18.6869597 },
    { lat: 47.992378, lng: 18.6869743 },
    { lat: 47.9927098, lng: 18.6862642 },
    { lat: 47.9928962, lng: 18.686188 },
    { lat: 47.9932549, lng: 18.686503 },
    { lat: 47.9936112, lng: 18.6865624 },
    { lat: 47.9938151, lng: 18.6867053 },
    { lat: 47.9942204, lng: 18.6873401 },
    { lat: 47.9944025, lng: 18.6874947 },
    { lat: 47.9945197, lng: 18.6874511 },
    { lat: 47.9946038, lng: 18.6873178 },
    { lat: 47.9950077, lng: 18.6871732 },
    { lat: 47.9952512, lng: 18.6871701 },
    { lat: 47.995437, lng: 18.6876274 },
    { lat: 47.995703, lng: 18.6879578 },
    { lat: 47.9958776, lng: 18.6879728 },
    { lat: 47.9965217, lng: 18.6882675 },
    { lat: 47.9964464, lng: 18.6885459 },
    { lat: 47.9965249, lng: 18.6894957 },
    { lat: 47.9966805, lng: 18.6895988 },
    { lat: 47.9968173, lng: 18.6896074 },
    { lat: 47.9970614, lng: 18.689487 },
    { lat: 47.9971983, lng: 18.689541 },
    { lat: 47.9974254, lng: 18.689899 },
    { lat: 47.9976306, lng: 18.6903995 },
    { lat: 47.9980347, lng: 18.690281 },
    { lat: 47.9985172, lng: 18.6906563 },
    { lat: 47.9986103, lng: 18.6906466 },
    { lat: 47.9988436, lng: 18.6903108 },
    { lat: 47.9990403, lng: 18.6897943 },
    { lat: 47.9992625, lng: 18.6896691 },
    { lat: 47.9996527, lng: 18.6896072 },
    { lat: 47.9997282, lng: 18.689665 },
    { lat: 47.9998358, lng: 18.6900078 },
    { lat: 48.0000457, lng: 18.6900302 },
    { lat: 48.0002401, lng: 18.689853 },
    { lat: 48.0004988, lng: 18.6894847 },
    { lat: 48.0006386, lng: 18.6894609 },
    { lat: 48.0009981, lng: 18.6897502 },
    { lat: 48.0014898, lng: 18.6898737 },
    { lat: 48.0016207, lng: 18.6894978 },
    { lat: 48.0016498, lng: 18.6892217 },
    { lat: 48.0014635, lng: 18.6886345 },
    { lat: 48.0015007, lng: 18.6883634 },
    { lat: 48.0019267, lng: 18.6879821 },
    { lat: 48.0020533, lng: 18.6881338 },
    { lat: 48.0021892, lng: 18.6885626 },
    { lat: 48.0027212, lng: 18.689074 },
    { lat: 48.0028635, lng: 18.6890788 },
    { lat: 48.0030014, lng: 18.6889185 },
    { lat: 48.0030501, lng: 18.6886299 },
    { lat: 48.0030162, lng: 18.6878747 },
    { lat: 48.0030865, lng: 18.6878212 },
    { lat: 48.0032419, lng: 18.687897 },
    { lat: 48.0032904, lng: 18.6881299 },
    { lat: 48.0034767, lng: 18.6884474 },
    { lat: 48.0036759, lng: 18.6886465 },
    { lat: 48.0037985, lng: 18.6886818 },
    { lat: 48.0043596, lng: 18.6886941 },
    { lat: 48.0048079, lng: 18.688558 },
    { lat: 48.0052522, lng: 18.6886812 },
    { lat: 48.0052534, lng: 18.6885979 },
    { lat: 48.0053299, lng: 18.6886585 },
    { lat: 48.0053517, lng: 18.6888112 },
    { lat: 48.0055113, lng: 18.6888607 },
    { lat: 48.0058224, lng: 18.6886928 },
    { lat: 48.0075152, lng: 18.6887512 },
    { lat: 48.0076875, lng: 18.688708 },
    { lat: 48.008086, lng: 18.6889438 },
    { lat: 48.0082536, lng: 18.6891252 },
    { lat: 48.008516, lng: 18.6895769 },
    { lat: 48.0089127, lng: 18.689926 },
    { lat: 48.0094383, lng: 18.6906347 },
    { lat: 48.009602, lng: 18.6907422 },
    { lat: 48.0098794, lng: 18.690847 },
    { lat: 48.0100929, lng: 18.6907288 },
    { lat: 48.0104726, lng: 18.6907844 },
    { lat: 48.0107043, lng: 18.6906468 },
    { lat: 48.0109574, lng: 18.6906552 },
    { lat: 48.0112078, lng: 18.6908446 },
    { lat: 48.0119285, lng: 18.6906364 },
    { lat: 48.0120024, lng: 18.6905 },
    { lat: 48.0120017, lng: 18.6900042 },
    { lat: 48.0121557, lng: 18.6893901 },
    { lat: 48.0121369, lng: 18.688454 },
    { lat: 48.0122712, lng: 18.6878293 },
    { lat: 48.012099, lng: 18.687441 },
    { lat: 48.0117371, lng: 18.6871293 },
    { lat: 48.011752, lng: 18.6866513 },
    { lat: 48.0117103, lng: 18.6863615 },
    { lat: 48.011379, lng: 18.6855911 },
    { lat: 48.0113454, lng: 18.6855346 },
    { lat: 48.0129395, lng: 18.685709 },
    { lat: 48.0129384, lng: 18.686048 },
    { lat: 48.0132779, lng: 18.6859611 },
    { lat: 48.0134241, lng: 18.6860616 },
    { lat: 48.0135495, lng: 18.6860423 },
    { lat: 48.0139182, lng: 18.6857882 },
    { lat: 48.0161787, lng: 18.6862941 },
    { lat: 48.0248132, lng: 18.6873139 },
    { lat: 48.0251403, lng: 18.6873525 },
    { lat: 48.0256185, lng: 18.6849286 },
    { lat: 48.0289042, lng: 18.6800071 },
    { lat: 48.0290913, lng: 18.6798384 },
    { lat: 48.0304506, lng: 18.6794863 },
    { lat: 48.0301664, lng: 18.6769003 },
    { lat: 48.0303741, lng: 18.6763387 },
    { lat: 48.0290115, lng: 18.6748771 },
    { lat: 48.0278139, lng: 18.6742744 },
    { lat: 48.0273783, lng: 18.6729361 },
    { lat: 48.0261422, lng: 18.6699359 },
    { lat: 48.0257912, lng: 18.667429 },
    { lat: 48.0257, lng: 18.6663509 },
    { lat: 48.0257137, lng: 18.6654561 },
    { lat: 48.0259731, lng: 18.6622515 },
    { lat: 48.0260283, lng: 18.6611082 },
    { lat: 48.0259073, lng: 18.6609551 },
    { lat: 48.0258458, lng: 18.655576 },
    { lat: 48.0259385, lng: 18.6553936 },
    { lat: 48.025386, lng: 18.6468136 },
    { lat: 48.0257688, lng: 18.6467202 },
    { lat: 48.0259834, lng: 18.646807 },
    { lat: 48.0261932, lng: 18.6464537 },
    { lat: 48.0264789, lng: 18.6461306 },
    { lat: 48.0264983, lng: 18.6458834 },
    { lat: 48.0267547, lng: 18.6455607 },
    { lat: 48.0267758, lng: 18.645388 },
    { lat: 48.0268635, lng: 18.6452357 },
    { lat: 48.0272924, lng: 18.6448263 },
    { lat: 48.0275313, lng: 18.6444286 },
    { lat: 48.0278087, lng: 18.6443239 },
    { lat: 48.0278758, lng: 18.6443524 },
    { lat: 48.0280658, lng: 18.6447579 },
    { lat: 48.0282004, lng: 18.6448294 },
    { lat: 48.0283324, lng: 18.6447039 },
    { lat: 48.0285447, lng: 18.6448967 },
    { lat: 48.0289722, lng: 18.6446872 },
    { lat: 48.0290165, lng: 18.6443845 },
    { lat: 48.0289808, lng: 18.644306 },
    { lat: 48.028878, lng: 18.644324 },
    { lat: 48.0288165, lng: 18.6441708 },
    { lat: 48.0288957, lng: 18.6439019 },
    { lat: 48.0288695, lng: 18.6435897 },
    { lat: 48.0290391, lng: 18.6433476 },
    { lat: 48.0292688, lng: 18.6433167 },
    { lat: 48.0294549, lng: 18.6430884 },
    { lat: 48.029727, lng: 18.6431151 },
    { lat: 48.0298525, lng: 18.6427884 },
    { lat: 48.0297913, lng: 18.6426594 },
    { lat: 48.0297103, lng: 18.6426424 },
    { lat: 48.0296934, lng: 18.6425575 },
    { lat: 48.0301015, lng: 18.6423256 },
    { lat: 48.0302018, lng: 18.6420584 },
    { lat: 48.0308764, lng: 18.6413719 },
    { lat: 48.0309745, lng: 18.6413945 },
    { lat: 48.0311159, lng: 18.6416692 },
    { lat: 48.0312701, lng: 18.6414793 },
    { lat: 48.031582, lng: 18.6416354 },
    { lat: 48.0318658, lng: 18.6420814 },
    { lat: 48.0321381, lng: 18.6421955 },
    { lat: 48.0323835, lng: 18.6421443 },
    { lat: 48.0326104, lng: 18.6422085 },
    { lat: 48.0328402, lng: 18.6420398 },
    { lat: 48.0329122, lng: 18.6417654 },
    { lat: 48.0330431, lng: 18.6415604 },
    { lat: 48.0330913, lng: 18.6413075 },
    { lat: 48.0329271, lng: 18.6412083 },
    { lat: 48.03293, lng: 18.6410754 },
    { lat: 48.0329925, lng: 18.6409427 },
    { lat: 48.033285, lng: 18.6406357 },
    { lat: 48.033292, lng: 18.6401429 },
    { lat: 48.0333715, lng: 18.6401094 },
    { lat: 48.0334485, lng: 18.6402833 },
    { lat: 48.0335483, lng: 18.6403368 },
    { lat: 48.0336266, lng: 18.6402205 },
    { lat: 48.0334708, lng: 18.6399895 },
    { lat: 48.0334483, lng: 18.6398837 },
    { lat: 48.0335063, lng: 18.6398337 },
    { lat: 48.0326806, lng: 18.6376532 },
    { lat: 48.0277825, lng: 18.6270772 },
    { lat: 48.0262902, lng: 18.6240139 },
    { lat: 48.0260717, lng: 18.6238515 },
    { lat: 48.0228605, lng: 18.6197604 },
    { lat: 48.0199436, lng: 18.6158114 },
    { lat: 48.0168171, lng: 18.612406 },
    { lat: 48.0133913, lng: 18.6081727 },
    { lat: 48.0132414, lng: 18.6085054 },
    { lat: 48.0131544, lng: 18.6083589 },
    { lat: 48.0114168, lng: 18.6068217 },
    { lat: 48.0113563, lng: 18.6067484 },
    { lat: 48.0095682, lng: 18.6052135 },
    { lat: 48.0084248, lng: 18.6044173 },
    { lat: 48.0087841, lng: 18.6036875 },
    { lat: 48.0097033, lng: 18.6009452 },
    { lat: 48.0090434, lng: 18.6003238 },
    { lat: 48.0083028, lng: 18.5995111 },
    { lat: 48.0059938, lng: 18.5961946 },
    { lat: 48.0047542, lng: 18.594919 },
    { lat: 48.001759, lng: 18.5912682 },
    { lat: 48.0003038, lng: 18.5898862 },
    { lat: 47.9990665, lng: 18.5885528 },
    { lat: 47.9980422, lng: 18.5878525 },
    { lat: 47.9976412, lng: 18.5882327 },
    { lat: 47.9965141, lng: 18.5892962 },
    { lat: 47.9922742, lng: 18.5937827 },
    { lat: 47.9886867, lng: 18.597805 },
    { lat: 47.9882018, lng: 18.5984678 },
    { lat: 47.9871877, lng: 18.5993512 },
    { lat: 47.9855398, lng: 18.6015351 },
    { lat: 47.9840807, lng: 18.603666 },
    { lat: 47.9838648, lng: 18.6037384 },
    { lat: 47.9831461, lng: 18.6039637 },
    { lat: 47.9816819, lng: 18.6050086 },
    { lat: 47.9806625, lng: 18.6054682 },
    { lat: 47.979733, lng: 18.6059831 },
    { lat: 47.9780626, lng: 18.6088286 },
    { lat: 47.9770787, lng: 18.6096249 },
    { lat: 47.9768966, lng: 18.6116373 },
    { lat: 47.9768406, lng: 18.6130081 },
    { lat: 47.9769428, lng: 18.6156217 },
    { lat: 47.9792397, lng: 18.624013 },
    { lat: 47.9834438, lng: 18.6291692 },
    { lat: 47.9836457, lng: 18.6288959 },
    { lat: 47.9839885, lng: 18.6287298 },
    { lat: 47.9853657, lng: 18.627488 },
    { lat: 47.9861901, lng: 18.62699 },
    { lat: 47.9863913, lng: 18.6268354 },
    { lat: 47.9865236, lng: 18.6266134 },
    { lat: 47.9866734, lng: 18.6265471 },
    { lat: 47.987364, lng: 18.6283925 },
    { lat: 47.9876585, lng: 18.6293906 },
    { lat: 47.9868067, lng: 18.653307 },
    { lat: 47.9888201, lng: 18.6535576 },
    { lat: 47.9887774, lng: 18.654682 },
    { lat: 47.9878338, lng: 18.6545243 },
    { lat: 47.9876759, lng: 18.6546991 },
    { lat: 47.9868508, lng: 18.6556123 },
    { lat: 47.9867279, lng: 18.6555933 },
    { lat: 47.9867262, lng: 18.6556667 },
    { lat: 47.9865615, lng: 18.6570684 },
    { lat: 47.9828394, lng: 18.6659747 },
    { lat: 47.983259, lng: 18.666435 },
    { lat: 47.983606, lng: 18.6675075 },
    { lat: 47.9825761, lng: 18.6701501 },
    { lat: 47.9816992, lng: 18.6738288 },
    { lat: 47.9816561, lng: 18.6744469 },
    { lat: 47.9815638, lng: 18.6746182 },
    { lat: 47.9816466, lng: 18.6748997 },
    { lat: 47.9818343, lng: 18.6765331 },
    { lat: 47.982266, lng: 18.6787603 },
    { lat: 47.9823313, lng: 18.6794216 },
  ],
  VeľkýĎur: [
    { lat: 48.2114954, lng: 18.4847828 },
    { lat: 48.2115033, lng: 18.4847638 },
    { lat: 48.2124031, lng: 18.4825022 },
    { lat: 48.2134359, lng: 18.4806866 },
    { lat: 48.215515, lng: 18.4774187 },
    { lat: 48.2173286, lng: 18.4727126 },
    { lat: 48.217913, lng: 18.471678 },
    { lat: 48.2186178, lng: 18.4706219 },
    { lat: 48.2186743, lng: 18.4705424 },
    { lat: 48.2191471, lng: 18.4703801 },
    { lat: 48.2203817, lng: 18.469398 },
    { lat: 48.2209992, lng: 18.4692839 },
    { lat: 48.2211503, lng: 18.4691617 },
    { lat: 48.220951, lng: 18.4687211 },
    { lat: 48.2209144, lng: 18.4683906 },
    { lat: 48.21994, lng: 18.4666129 },
    { lat: 48.2190286, lng: 18.4655812 },
    { lat: 48.2185708, lng: 18.46498 },
    { lat: 48.2182646, lng: 18.4643433 },
    { lat: 48.2183875, lng: 18.4642204 },
    { lat: 48.2201795, lng: 18.4623685 },
    { lat: 48.2224852, lng: 18.4596976 },
    { lat: 48.2239502, lng: 18.4583128 },
    { lat: 48.2247041, lng: 18.4577534 },
    { lat: 48.2253889, lng: 18.4571039 },
    { lat: 48.2259814, lng: 18.4562802 },
    { lat: 48.2278237, lng: 18.4528674 },
    { lat: 48.2297063, lng: 18.4500565 },
    { lat: 48.2308499, lng: 18.4488777 },
    { lat: 48.23147, lng: 18.447964 },
    { lat: 48.2334016, lng: 18.4461908 },
    { lat: 48.2338865, lng: 18.445664 },
    { lat: 48.233905, lng: 18.4455715 },
    { lat: 48.2336492, lng: 18.4441421 },
    { lat: 48.2348902, lng: 18.4441608 },
    { lat: 48.2359452, lng: 18.4439239 },
    { lat: 48.2364953, lng: 18.4440585 },
    { lat: 48.2377426, lng: 18.4442112 },
    { lat: 48.2385682, lng: 18.439348 },
    { lat: 48.23874, lng: 18.438614 },
    { lat: 48.238735, lng: 18.438502 },
    { lat: 48.238731, lng: 18.438389 },
    { lat: 48.238728, lng: 18.438268 },
    { lat: 48.238719, lng: 18.438155 },
    { lat: 48.238714, lng: 18.438024 },
    { lat: 48.238696, lng: 18.437661 },
    { lat: 48.238714, lng: 18.436164 },
    { lat: 48.238702, lng: 18.436007 },
    { lat: 48.23866, lng: 18.435491 },
    { lat: 48.238651, lng: 18.435366 },
    { lat: 48.238641, lng: 18.435251 },
    { lat: 48.238631, lng: 18.435123 },
    { lat: 48.238625, lng: 18.435047 },
    { lat: 48.238623, lng: 18.435024 },
    { lat: 48.238623, lng: 18.435009 },
    { lat: 48.238622, lng: 18.434993 },
    { lat: 48.23861, lng: 18.434873 },
    { lat: 48.2386, lng: 18.434755 },
    { lat: 48.238592, lng: 18.434649 },
    { lat: 48.238586, lng: 18.434583 },
    { lat: 48.238417, lng: 18.432374 },
    { lat: 48.238278, lng: 18.431127 },
    { lat: 48.238249, lng: 18.430865 },
    { lat: 48.238158, lng: 18.43002 },
    { lat: 48.238127, lng: 18.429739 },
    { lat: 48.23807, lng: 18.429232 },
    { lat: 48.23805, lng: 18.429052 },
    { lat: 48.237874, lng: 18.428585 },
    { lat: 48.237691, lng: 18.428147 },
    { lat: 48.237434, lng: 18.427814 },
    { lat: 48.237294, lng: 18.427693 },
    { lat: 48.237116, lng: 18.427539 },
    { lat: 48.236929, lng: 18.427403 },
    { lat: 48.23689, lng: 18.427371 },
    { lat: 48.236678, lng: 18.427209 },
    { lat: 48.236408, lng: 18.427 },
    { lat: 48.236102, lng: 18.426759 },
    { lat: 48.235885, lng: 18.426583 },
    { lat: 48.235645, lng: 18.426329 },
    { lat: 48.235548, lng: 18.426226 },
    { lat: 48.235515, lng: 18.426163 },
    { lat: 48.235481, lng: 18.42609 },
    { lat: 48.235464, lng: 18.425998 },
    { lat: 48.23538, lng: 18.425868 },
    { lat: 48.235091, lng: 18.425423 },
    { lat: 48.234964, lng: 18.425236 },
    { lat: 48.234841, lng: 18.425075 },
    { lat: 48.234619, lng: 18.42479 },
    { lat: 48.234526, lng: 18.4247 },
    { lat: 48.234459, lng: 18.424639 },
    { lat: 48.234325, lng: 18.424555 },
    { lat: 48.234083, lng: 18.424386 },
    { lat: 48.233788, lng: 18.424177 },
    { lat: 48.233571, lng: 18.424026 },
    { lat: 48.233452, lng: 18.423957 },
    { lat: 48.233235, lng: 18.42384 },
    { lat: 48.232774, lng: 18.423594 },
    { lat: 48.232412, lng: 18.4234 },
    { lat: 48.231544, lng: 18.422937 },
    { lat: 48.231348, lng: 18.422833 },
    { lat: 48.230822, lng: 18.422555 },
    { lat: 48.230564, lng: 18.422341 },
    { lat: 48.230556, lng: 18.422334 },
    { lat: 48.230322, lng: 18.422141 },
    { lat: 48.230004, lng: 18.421879 },
    { lat: 48.229595, lng: 18.421553 },
    { lat: 48.229516, lng: 18.421489 },
    { lat: 48.229392, lng: 18.421387 },
    { lat: 48.229307, lng: 18.421327 },
    { lat: 48.228992, lng: 18.421015 },
    { lat: 48.228659, lng: 18.420696 },
    { lat: 48.228502, lng: 18.42054 },
    { lat: 48.228379, lng: 18.42035 },
    { lat: 48.228263, lng: 18.420177 },
    { lat: 48.228087, lng: 18.419913 },
    { lat: 48.227859, lng: 18.419563 },
    { lat: 48.227498, lng: 18.419162 },
    { lat: 48.227213, lng: 18.418842 },
    { lat: 48.22705, lng: 18.418676 },
    { lat: 48.226922, lng: 18.418546 },
    { lat: 48.226831, lng: 18.418457 },
    { lat: 48.226707, lng: 18.418334 },
    { lat: 48.226663, lng: 18.418293 },
    { lat: 48.22657, lng: 18.418203 },
    { lat: 48.226277, lng: 18.417927 },
    { lat: 48.225982, lng: 18.417643 },
    { lat: 48.225645, lng: 18.417295 },
    { lat: 48.225031, lng: 18.416664 },
    { lat: 48.224852, lng: 18.416239 },
    { lat: 48.223333, lng: 18.413735 },
    { lat: 48.223105, lng: 18.413543 },
    { lat: 48.22275, lng: 18.413262 },
    { lat: 48.222613, lng: 18.413146 },
    { lat: 48.22227, lng: 18.412853 },
    { lat: 48.221511, lng: 18.412086 },
    { lat: 48.2213, lng: 18.411974 },
    { lat: 48.221301, lng: 18.411947 },
    { lat: 48.221368, lng: 18.409329 },
    { lat: 48.221365, lng: 18.409293 },
    { lat: 48.221356, lng: 18.409174 },
    { lat: 48.221349, lng: 18.409051 },
    { lat: 48.221343, lng: 18.408976 },
    { lat: 48.221285, lng: 18.408342 },
    { lat: 48.221219, lng: 18.407649 },
    { lat: 48.221422, lng: 18.406571 },
    { lat: 48.221503, lng: 18.405249 },
    { lat: 48.221637, lng: 18.403284 },
    { lat: 48.221648, lng: 18.40315 },
    { lat: 48.221651, lng: 18.403081 },
    { lat: 48.22165, lng: 18.403 },
    { lat: 48.221652, lng: 18.402885 },
    { lat: 48.221657, lng: 18.402797 },
    { lat: 48.221695, lng: 18.402839 },
    { lat: 48.221767, lng: 18.402912 },
    { lat: 48.221836, lng: 18.402982 },
    { lat: 48.221871, lng: 18.403013 },
    { lat: 48.221923, lng: 18.403072 },
    { lat: 48.222004, lng: 18.402758 },
    { lat: 48.222353, lng: 18.402403 },
    { lat: 48.222183, lng: 18.402008 },
    { lat: 48.22254, lng: 18.401569 },
    { lat: 48.222392, lng: 18.401052 },
    { lat: 48.222313, lng: 18.400767 },
    { lat: 48.222096, lng: 18.400197 },
    { lat: 48.221848, lng: 18.399538 },
    { lat: 48.221826, lng: 18.39947 },
    { lat: 48.221781, lng: 18.399356 },
    { lat: 48.221735, lng: 18.399237 },
    { lat: 48.221699, lng: 18.39913 },
    { lat: 48.22168, lng: 18.39908 },
    { lat: 48.221662, lng: 18.399005 },
    { lat: 48.221633, lng: 18.398872 },
    { lat: 48.221609, lng: 18.39877 },
    { lat: 48.221602, lng: 18.398707 },
    { lat: 48.221568, lng: 18.398411 },
    { lat: 48.221534, lng: 18.398139 },
    { lat: 48.221592, lng: 18.398101 },
    { lat: 48.221714, lng: 18.397994 },
    { lat: 48.221717, lng: 18.397991 },
    { lat: 48.221715, lng: 18.397976 },
    { lat: 48.221707, lng: 18.397959 },
    { lat: 48.221543, lng: 18.397366 },
    { lat: 48.221503, lng: 18.397225 },
    { lat: 48.221463, lng: 18.39714 },
    { lat: 48.221439, lng: 18.397089 },
    { lat: 48.221424, lng: 18.397059 },
    { lat: 48.22113, lng: 18.396414 },
    { lat: 48.22112, lng: 18.396402 },
    { lat: 48.221098, lng: 18.396378 },
    { lat: 48.220835, lng: 18.396095 },
    { lat: 48.220693, lng: 18.395944 },
    { lat: 48.220117, lng: 18.394288 },
    { lat: 48.219399, lng: 18.392682 },
    { lat: 48.219308, lng: 18.392299 },
    { lat: 48.219308, lng: 18.392298 },
    { lat: 48.219329, lng: 18.392298 },
    { lat: 48.219329, lng: 18.392297 },
    { lat: 48.219311, lng: 18.392225 },
    { lat: 48.219251, lng: 18.392077 },
    { lat: 48.219233, lng: 18.392007 },
    { lat: 48.219219, lng: 18.391952 },
    { lat: 48.219189, lng: 18.391821 },
    { lat: 48.219157, lng: 18.391707 },
    { lat: 48.219155, lng: 18.391699 },
    { lat: 48.219145, lng: 18.391654 },
    { lat: 48.219069, lng: 18.391299 },
    { lat: 48.219068, lng: 18.391296 },
    { lat: 48.219059, lng: 18.39125 },
    { lat: 48.218977, lng: 18.391021 },
    { lat: 48.218922, lng: 18.390844 },
    { lat: 48.21888, lng: 18.390705 },
    { lat: 48.218879, lng: 18.390703 },
    { lat: 48.218833, lng: 18.390569 },
    { lat: 48.218738, lng: 18.390353 },
    { lat: 48.218722, lng: 18.390311 },
    { lat: 48.218671, lng: 18.390235 },
    { lat: 48.218476, lng: 18.390015 },
    { lat: 48.218434, lng: 18.389967 },
    { lat: 48.218311, lng: 18.389849 },
    { lat: 48.218097, lng: 18.389667 },
    { lat: 48.217715, lng: 18.389331 },
    { lat: 48.217316, lng: 18.388974 },
    { lat: 48.217178, lng: 18.388856 },
    { lat: 48.217129, lng: 18.388826 },
    { lat: 48.216815, lng: 18.388688 },
    { lat: 48.216479, lng: 18.388545 },
    { lat: 48.216151, lng: 18.388404 },
    { lat: 48.215709, lng: 18.38821 },
    { lat: 48.215447, lng: 18.388099 },
    { lat: 48.215286, lng: 18.388028 },
    { lat: 48.215284, lng: 18.38803 },
    { lat: 48.215282, lng: 18.38803 },
    { lat: 48.215151, lng: 18.388015 },
    { lat: 48.214925, lng: 18.387979 },
    { lat: 48.214807, lng: 18.387959 },
    { lat: 48.214705, lng: 18.387972 },
    { lat: 48.214597, lng: 18.388002 },
    { lat: 48.21449, lng: 18.388043 },
    { lat: 48.21439, lng: 18.388082 },
    { lat: 48.214199, lng: 18.388198 },
    { lat: 48.214036, lng: 18.388301 },
    { lat: 48.213878, lng: 18.388401 },
    { lat: 48.21365, lng: 18.388579 },
    { lat: 48.21357, lng: 18.388643 },
    { lat: 48.213357, lng: 18.388748 },
    { lat: 48.213138, lng: 18.388866 },
    { lat: 48.212995, lng: 18.388948 },
    { lat: 48.212744, lng: 18.389075 },
    { lat: 48.212631, lng: 18.389127 },
    { lat: 48.212451, lng: 18.389189 },
    { lat: 48.212278, lng: 18.38925 },
    { lat: 48.212111, lng: 18.389297 },
    { lat: 48.211983, lng: 18.389342 },
    { lat: 48.211789, lng: 18.389393 },
    { lat: 48.211694, lng: 18.389409 },
    { lat: 48.21157, lng: 18.389406 },
    { lat: 48.211329, lng: 18.389407 },
    { lat: 48.211043, lng: 18.389403 },
    { lat: 48.211037, lng: 18.389367 },
    { lat: 48.2105603, lng: 18.3899122 },
    { lat: 48.209541, lng: 18.3916021 },
    { lat: 48.2091501, lng: 18.3926322 },
    { lat: 48.208549, lng: 18.3939761 },
    { lat: 48.2081303, lng: 18.3960757 },
    { lat: 48.2078334, lng: 18.399799 },
    { lat: 48.2081468, lng: 18.4023792 },
    { lat: 48.2080804, lng: 18.4074315 },
    { lat: 48.2079766, lng: 18.4097389 },
    { lat: 48.2077408, lng: 18.41028 },
    { lat: 48.2072807, lng: 18.4113042 },
    { lat: 48.2057747, lng: 18.4120093 },
    { lat: 48.2021059, lng: 18.4134154 },
    { lat: 48.2007653, lng: 18.4151733 },
    { lat: 48.1967934, lng: 18.4211669 },
    { lat: 48.1961565, lng: 18.4221217 },
    { lat: 48.1961493, lng: 18.4221746 },
    { lat: 48.196233, lng: 18.4220778 },
    { lat: 48.1960963, lng: 18.4228552 },
    { lat: 48.1946972, lng: 18.4251464 },
    { lat: 48.1937509, lng: 18.4269581 },
    { lat: 48.1929371, lng: 18.4290427 },
    { lat: 48.1916564, lng: 18.431975 },
    { lat: 48.1915502, lng: 18.4323157 },
    { lat: 48.1914734, lng: 18.4335228 },
    { lat: 48.1913961, lng: 18.4337934 },
    { lat: 48.1911214, lng: 18.4343087 },
    { lat: 48.1908858, lng: 18.4346504 },
    { lat: 48.1904589, lng: 18.4350656 },
    { lat: 48.1894537, lng: 18.4356692 },
    { lat: 48.1888058, lng: 18.4359487 },
    { lat: 48.1878046, lng: 18.4361371 },
    { lat: 48.1872894, lng: 18.4363056 },
    { lat: 48.1870737, lng: 18.4364846 },
    { lat: 48.1870226, lng: 18.4365568 },
    { lat: 48.187006, lng: 18.4366437 },
    { lat: 48.1886891, lng: 18.439866 },
    { lat: 48.1895594, lng: 18.4435212 },
    { lat: 48.1903636, lng: 18.4471456 },
    { lat: 48.1926409, lng: 18.4521447 },
    { lat: 48.1923297, lng: 18.4522925 },
    { lat: 48.192754, lng: 18.4534204 },
    { lat: 48.1936182, lng: 18.4549388 },
    { lat: 48.1944972, lng: 18.4563529 },
    { lat: 48.1961546, lng: 18.4587631 },
    { lat: 48.1959888, lng: 18.460197 },
    { lat: 48.1963784, lng: 18.4604893 },
    { lat: 48.1967526, lng: 18.4606639 },
    { lat: 48.1971321, lng: 18.4609772 },
    { lat: 48.1979508, lng: 18.4611317 },
    { lat: 48.198372, lng: 18.4613213 },
    { lat: 48.1988783, lng: 18.4618409 },
    { lat: 48.1996289, lng: 18.4628628 },
    { lat: 48.1999673, lng: 18.4635688 },
    { lat: 48.2003046, lng: 18.464136 },
    { lat: 48.1993455, lng: 18.4666623 },
    { lat: 48.1983041, lng: 18.4690877 },
    { lat: 48.1979709, lng: 18.4704545 },
    { lat: 48.197348, lng: 18.4723135 },
    { lat: 48.1966752, lng: 18.4750435 },
    { lat: 48.196386, lng: 18.4759285 },
    { lat: 48.1958312, lng: 18.4772291 },
    { lat: 48.1949199, lng: 18.4802531 },
    { lat: 48.1946796, lng: 18.4808812 },
    { lat: 48.1923198, lng: 18.4856744 },
    { lat: 48.1921615, lng: 18.4859904 },
    { lat: 48.1921916, lng: 18.486005 },
    { lat: 48.1936296, lng: 18.4867487 },
    { lat: 48.1969635, lng: 18.488095 },
    { lat: 48.1984659, lng: 18.4892498 },
    { lat: 48.2003186, lng: 18.4899892 },
    { lat: 48.2026085, lng: 18.4881151 },
    { lat: 48.2038728, lng: 18.4869774 },
    { lat: 48.2039947, lng: 18.4868342 },
    { lat: 48.2044327, lng: 18.4858861 },
    { lat: 48.2046891, lng: 18.4857308 },
    { lat: 48.2048497, lng: 18.4850648 },
    { lat: 48.2053054, lng: 18.4842934 },
    { lat: 48.2057111, lng: 18.4834741 },
    { lat: 48.2061523, lng: 18.4839051 },
    { lat: 48.2070547, lng: 18.4816105 },
    { lat: 48.2076343, lng: 18.4822521 },
    { lat: 48.2081584, lng: 18.4826084 },
    { lat: 48.20864, lng: 18.4828307 },
    { lat: 48.2092086, lng: 18.4829732 },
    { lat: 48.2092576, lng: 18.482982 },
    { lat: 48.2104246, lng: 18.4837634 },
    { lat: 48.2107984, lng: 18.4841342 },
    { lat: 48.2114954, lng: 18.4847828 },
  ],
  Zalaba: [
    { lat: 47.94583, lng: 18.713106 },
    { lat: 47.945889, lng: 18.713174 },
    { lat: 47.945956, lng: 18.713249 },
    { lat: 47.946541, lng: 18.713899 },
    { lat: 47.946769, lng: 18.714157 },
    { lat: 47.947709, lng: 18.715318 },
    { lat: 47.948671, lng: 18.716501 },
    { lat: 47.94938, lng: 18.717373 },
    { lat: 47.949844, lng: 18.717931 },
    { lat: 47.9520446, lng: 18.718795 },
    { lat: 47.9549272, lng: 18.7181391 },
    { lat: 47.9562721, lng: 18.7177305 },
    { lat: 47.9569027, lng: 18.7173788 },
    { lat: 47.9583953, lng: 18.7169029 },
    { lat: 47.9595754, lng: 18.7173309 },
    { lat: 47.9605109, lng: 18.7175733 },
    { lat: 47.960352, lng: 18.7184786 },
    { lat: 47.9628, lng: 18.7195381 },
    { lat: 47.9621357, lng: 18.7202731 },
    { lat: 47.9608045, lng: 18.7223209 },
    { lat: 47.9607375, lng: 18.722921 },
    { lat: 47.9619916, lng: 18.7233854 },
    { lat: 47.9639866, lng: 18.7240117 },
    { lat: 47.964669, lng: 18.7242035 },
    { lat: 47.9648011, lng: 18.7240139 },
    { lat: 47.9701375, lng: 18.7255848 },
    { lat: 47.9706527, lng: 18.7244018 },
    { lat: 47.9731491, lng: 18.720463 },
    { lat: 47.9760915, lng: 18.7159528 },
    { lat: 47.9760999, lng: 18.7159416 },
    { lat: 47.9767949, lng: 18.7146433 },
    { lat: 47.9758828, lng: 18.7132672 },
    { lat: 47.9746162, lng: 18.712493 },
    { lat: 47.9747177, lng: 18.7119856 },
    { lat: 47.9749114, lng: 18.7116271 },
    { lat: 47.9751205, lng: 18.7110689 },
    { lat: 47.9752305, lng: 18.7093403 },
    { lat: 47.9751458, lng: 18.7084561 },
    { lat: 47.9751294, lng: 18.7077115 },
    { lat: 47.9750126, lng: 18.706716 },
    { lat: 47.9748724, lng: 18.7060609 },
    { lat: 47.9748071, lng: 18.7051423 },
    { lat: 47.9745644, lng: 18.7044269 },
    { lat: 47.9739181, lng: 18.7018278 },
    { lat: 47.9730954, lng: 18.6990169 },
    { lat: 47.9729577, lng: 18.697949 },
    { lat: 47.9730176, lng: 18.6947091 },
    { lat: 47.9732086, lng: 18.6939862 },
    { lat: 47.9734204, lng: 18.6928092 },
    { lat: 47.9734141, lng: 18.6914197 },
    { lat: 47.9734867, lng: 18.6893355 },
    { lat: 47.9735946, lng: 18.6883273 },
    { lat: 47.9738006, lng: 18.6879893 },
    { lat: 47.9746585, lng: 18.6870834 },
    { lat: 47.9742946, lng: 18.6864406 },
    { lat: 47.9737793, lng: 18.6863176 },
    { lat: 47.9729213, lng: 18.6859706 },
    { lat: 47.9726945, lng: 18.6853203 },
    { lat: 47.9726997, lng: 18.6846467 },
    { lat: 47.9726176, lng: 18.6845055 },
    { lat: 47.9725187, lng: 18.6844899 },
    { lat: 47.9723407, lng: 18.6846383 },
    { lat: 47.972211, lng: 18.6850759 },
    { lat: 47.9721365, lng: 18.6851587 },
    { lat: 47.9719879, lng: 18.6851645 },
    { lat: 47.9716017, lng: 18.6845069 },
    { lat: 47.9715187, lng: 18.6845383 },
    { lat: 47.9714689, lng: 18.6846999 },
    { lat: 47.9712591, lng: 18.6846793 },
    { lat: 47.9710679, lng: 18.6848989 },
    { lat: 47.970871, lng: 18.6848669 },
    { lat: 47.9707584, lng: 18.6842444 },
    { lat: 47.9706123, lng: 18.6839199 },
    { lat: 47.9705341, lng: 18.6838905 },
    { lat: 47.9703174, lng: 18.6840967 },
    { lat: 47.9701935, lng: 18.6839774 },
    { lat: 47.9693285, lng: 18.6842265 },
    { lat: 47.968665, lng: 18.6841603 },
    { lat: 47.9684303, lng: 18.6842602 },
    { lat: 47.9682202, lng: 18.6844828 },
    { lat: 47.967719, lng: 18.6852492 },
    { lat: 47.9673753, lng: 18.6859657 },
    { lat: 47.9672446, lng: 18.6860497 },
    { lat: 47.9671559, lng: 18.6860339 },
    { lat: 47.9670656, lng: 18.685911 },
    { lat: 47.9670909, lng: 18.6857105 },
    { lat: 47.9669717, lng: 18.685154 },
    { lat: 47.9670007, lng: 18.6848334 },
    { lat: 47.9671274, lng: 18.6845716 },
    { lat: 47.9670957, lng: 18.6844828 },
    { lat: 47.9668925, lng: 18.6843967 },
    { lat: 47.9661883, lng: 18.6847879 },
    { lat: 47.9659738, lng: 18.6852011 },
    { lat: 47.9658852, lng: 18.685836 },
    { lat: 47.9657175, lng: 18.6859683 },
    { lat: 47.9655352, lng: 18.6858934 },
    { lat: 47.9653606, lng: 18.6857049 },
    { lat: 47.9651944, lng: 18.685159 },
    { lat: 47.9652431, lng: 18.6849927 },
    { lat: 47.9652143, lng: 18.6848564 },
    { lat: 47.964693, lng: 18.6842474 },
    { lat: 47.9642951, lng: 18.6841688 },
    { lat: 47.9637288, lng: 18.6843521 },
    { lat: 47.9635779, lng: 18.684298 },
    { lat: 47.9635046, lng: 18.6840274 },
    { lat: 47.9636308, lng: 18.6835457 },
    { lat: 47.9635536, lng: 18.6833688 },
    { lat: 47.9634052, lng: 18.6832504 },
    { lat: 47.9630323, lng: 18.6836744 },
    { lat: 47.9628404, lng: 18.6840081 },
    { lat: 47.9624654, lng: 18.6842054 },
    { lat: 47.9622609, lng: 18.6844502 },
    { lat: 47.9619224, lng: 18.6856555 },
    { lat: 47.9617196, lng: 18.6858691 },
    { lat: 47.9614784, lng: 18.6858716 },
    { lat: 47.9612966, lng: 18.6857879 },
    { lat: 47.961136, lng: 18.6855661 },
    { lat: 47.9610066, lng: 18.6855724 },
    { lat: 47.960838, lng: 18.685479 },
    { lat: 47.9603509, lng: 18.6850239 },
    { lat: 47.9600834, lng: 18.6851253 },
    { lat: 47.9599308, lng: 18.6855778 },
    { lat: 47.9597684, lng: 18.6857039 },
    { lat: 47.9595625, lng: 18.6856293 },
    { lat: 47.9593671, lng: 18.685183 },
    { lat: 47.9591532, lng: 18.6841083 },
    { lat: 47.9590927, lng: 18.6840118 },
    { lat: 47.958805, lng: 18.6842989 },
    { lat: 47.9585052, lng: 18.6849087 },
    { lat: 47.9580078, lng: 18.6852652 },
    { lat: 47.9579311, lng: 18.685804 },
    { lat: 47.9576401, lng: 18.6861186 },
    { lat: 47.9574252, lng: 18.6862354 },
    { lat: 47.9571432, lng: 18.6861693 },
    { lat: 47.9569258, lng: 18.6858808 },
    { lat: 47.9569924, lng: 18.6856522 },
    { lat: 47.9569738, lng: 18.6855622 },
    { lat: 47.9569094, lng: 18.6855168 },
    { lat: 47.9568484, lng: 18.6855551 },
    { lat: 47.9567754, lng: 18.6857922 },
    { lat: 47.956797, lng: 18.6860855 },
    { lat: 47.9567453, lng: 18.6864027 },
    { lat: 47.9568316, lng: 18.6866661 },
    { lat: 47.9570742, lng: 18.6869787 },
    { lat: 47.9570891, lng: 18.6871085 },
    { lat: 47.9570055, lng: 18.6874605 },
    { lat: 47.9568966, lng: 18.6876798 },
    { lat: 47.9567062, lng: 18.6878078 },
    { lat: 47.956498, lng: 18.6878784 },
    { lat: 47.9562993, lng: 18.6877492 },
    { lat: 47.9560943, lng: 18.6874852 },
    { lat: 47.9555101, lng: 18.6874025 },
    { lat: 47.9553826, lng: 18.687505 },
    { lat: 47.955235, lng: 18.6880708 },
    { lat: 47.95501, lng: 18.6882287 },
    { lat: 47.9543903, lng: 18.6880707 },
    { lat: 47.9538022, lng: 18.687607 },
    { lat: 47.9537627, lng: 18.6876223 },
    { lat: 47.9537165, lng: 18.6878129 },
    { lat: 47.9537315, lng: 18.6882347 },
    { lat: 47.9536661, lng: 18.6883628 },
    { lat: 47.9534316, lng: 18.6885109 },
    { lat: 47.9531592, lng: 18.6885217 },
    { lat: 47.9524221, lng: 18.6882173 },
    { lat: 47.9518679, lng: 18.6878154 },
    { lat: 47.9514863, lng: 18.6871385 },
    { lat: 47.9513732, lng: 18.687215 },
    { lat: 47.951268, lng: 18.687582 },
    { lat: 47.951317, lng: 18.687609 },
    { lat: 47.951321, lng: 18.687612 },
    { lat: 47.951405, lng: 18.687659 },
    { lat: 47.951367, lng: 18.687836 },
    { lat: 47.951203, lng: 18.68866 },
    { lat: 47.950443, lng: 18.690569 },
    { lat: 47.950401, lng: 18.690684 },
    { lat: 47.950345, lng: 18.690835 },
    { lat: 47.950328, lng: 18.690882 },
    { lat: 47.950312, lng: 18.690929 },
    { lat: 47.95029, lng: 18.690992 },
    { lat: 47.950201, lng: 18.691507 },
    { lat: 47.949948, lng: 18.691484 },
    { lat: 47.949753, lng: 18.692124 },
    { lat: 47.949731, lng: 18.692152 },
    { lat: 47.949508, lng: 18.692541 },
    { lat: 47.949103, lng: 18.692852 },
    { lat: 47.949152, lng: 18.693532 },
    { lat: 47.949459, lng: 18.694104 },
    { lat: 47.949442, lng: 18.694957 },
    { lat: 47.949673, lng: 18.695418 },
    { lat: 47.949732, lng: 18.695472 },
    { lat: 47.949993, lng: 18.695723 },
    { lat: 47.950026, lng: 18.695755 },
    { lat: 47.949895, lng: 18.696545 },
    { lat: 47.949893, lng: 18.696562 },
    { lat: 47.949871, lng: 18.69669 },
    { lat: 47.949867, lng: 18.696709 },
    { lat: 47.949823, lng: 18.696978 },
    { lat: 47.94982, lng: 18.696994 },
    { lat: 47.949805, lng: 18.69709 },
    { lat: 47.949803, lng: 18.697098 },
    { lat: 47.949758, lng: 18.697358 },
    { lat: 47.949816, lng: 18.698935 },
    { lat: 47.949816, lng: 18.698953 },
    { lat: 47.94979, lng: 18.70009 },
    { lat: 47.949733, lng: 18.700903 },
    { lat: 47.949695, lng: 18.701555 },
    { lat: 47.949649, lng: 18.702268 },
    { lat: 47.949641, lng: 18.702325 },
    { lat: 47.949587, lng: 18.703051 },
    { lat: 47.949534, lng: 18.703675 },
    { lat: 47.949534, lng: 18.703687 },
    { lat: 47.949507, lng: 18.704003 },
    { lat: 47.949076, lng: 18.705602 },
    { lat: 47.948619, lng: 18.706866 },
    { lat: 47.948272, lng: 18.707944 },
    { lat: 47.948181, lng: 18.708211 },
    { lat: 47.947704, lng: 18.70944 },
    { lat: 47.947661, lng: 18.709542 },
    { lat: 47.947202, lng: 18.7105 },
    { lat: 47.946916, lng: 18.711087 },
    { lat: 47.94689, lng: 18.711143 },
    { lat: 47.946334, lng: 18.712181 },
    { lat: 47.94583, lng: 18.713106 },
  ],
  HontianskaVrbica: [
    { lat: 48.1448727, lng: 18.7549491 },
    { lat: 48.1458482, lng: 18.7531462 },
    { lat: 48.1469003, lng: 18.7509221 },
    { lat: 48.1485955, lng: 18.7477313 },
    { lat: 48.1497508, lng: 18.7458538 },
    { lat: 48.1507251, lng: 18.7435711 },
    { lat: 48.1523411, lng: 18.7420235 },
    { lat: 48.1542471, lng: 18.7403772 },
    { lat: 48.1555409, lng: 18.7390288 },
    { lat: 48.1567409, lng: 18.7376153 },
    { lat: 48.1579154, lng: 18.736039 },
    { lat: 48.1586971, lng: 18.7344135 },
    { lat: 48.1594726, lng: 18.7323577 },
    { lat: 48.1605474, lng: 18.7301015 },
    { lat: 48.1616437, lng: 18.7274668 },
    { lat: 48.1625894, lng: 18.7249457 },
    { lat: 48.1627239, lng: 18.7219792 },
    { lat: 48.1626743, lng: 18.7211572 },
    { lat: 48.1631673, lng: 18.7198727 },
    { lat: 48.1638261, lng: 18.7202556 },
    { lat: 48.164199, lng: 18.7189654 },
    { lat: 48.1654969, lng: 18.7161584 },
    { lat: 48.1659749, lng: 18.7149131 },
    { lat: 48.1666271, lng: 18.7136812 },
    { lat: 48.1666809, lng: 18.7136529 },
    { lat: 48.1668987, lng: 18.7125397 },
    { lat: 48.1666047, lng: 18.7117016 },
    { lat: 48.1663061, lng: 18.7112894 },
    { lat: 48.1660581, lng: 18.7101332 },
    { lat: 48.165978, lng: 18.7089967 },
    { lat: 48.1660553, lng: 18.7065265 },
    { lat: 48.1659104, lng: 18.7058867 },
    { lat: 48.1655986, lng: 18.7048678 },
    { lat: 48.1653817, lng: 18.7045257 },
    { lat: 48.1639509, lng: 18.7029578 },
    { lat: 48.1626939, lng: 18.701816 },
    { lat: 48.1621734, lng: 18.7014885 },
    { lat: 48.1618414, lng: 18.7014188 },
    { lat: 48.1616454, lng: 18.7015483 },
    { lat: 48.1612347, lng: 18.7014407 },
    { lat: 48.1611371, lng: 18.7015552 },
    { lat: 48.1608436, lng: 18.7015678 },
    { lat: 48.1598203, lng: 18.7019824 },
    { lat: 48.1592994, lng: 18.7020092 },
    { lat: 48.1587581, lng: 18.7014005 },
    { lat: 48.1584067, lng: 18.7009029 },
    { lat: 48.1581237, lng: 18.7001269 },
    { lat: 48.1579838, lng: 18.6992041 },
    { lat: 48.1577977, lng: 18.6987627 },
    { lat: 48.1572167, lng: 18.6963075 },
    { lat: 48.1571899, lng: 18.6958085 },
    { lat: 48.157274, lng: 18.6938913 },
    { lat: 48.1572259, lng: 18.6927639 },
    { lat: 48.1571491, lng: 18.6925527 },
    { lat: 48.1570559, lng: 18.6917822 },
    { lat: 48.1569352, lng: 18.6914068 },
    { lat: 48.156867, lng: 18.6906834 },
    { lat: 48.1568659, lng: 18.6900124 },
    { lat: 48.1566295, lng: 18.6900024 },
    { lat: 48.156106, lng: 18.6897251 },
    { lat: 48.1556433, lng: 18.6894279 },
    { lat: 48.154848, lng: 18.6887125 },
    { lat: 48.1531602, lng: 18.6876315 },
    { lat: 48.1521961, lng: 18.687094 },
    { lat: 48.151958, lng: 18.687176 },
    { lat: 48.1507966, lng: 18.68699 },
    { lat: 48.1491642, lng: 18.6868981 },
    { lat: 48.1489638, lng: 18.6871969 },
    { lat: 48.1486242, lng: 18.6873434 },
    { lat: 48.1485702, lng: 18.6875996 },
    { lat: 48.148354, lng: 18.6877495 },
    { lat: 48.1481452, lng: 18.6878103 },
    { lat: 48.1479604, lng: 18.6874977 },
    { lat: 48.1475598, lng: 18.6874318 },
    { lat: 48.1472499, lng: 18.6872296 },
    { lat: 48.1471592, lng: 18.6876601 },
    { lat: 48.1470124, lng: 18.6876152 },
    { lat: 48.1467958, lng: 18.6873206 },
    { lat: 48.1467853, lng: 18.6869581 },
    { lat: 48.14656, lng: 18.6869612 },
    { lat: 48.1462854, lng: 18.6870588 },
    { lat: 48.1457994, lng: 18.6850905 },
    { lat: 48.1454758, lng: 18.6847791 },
    { lat: 48.1449634, lng: 18.6838307 },
    { lat: 48.1444385, lng: 18.6835273 },
    { lat: 48.1438338, lng: 18.6830424 },
    { lat: 48.1433178, lng: 18.6823234 },
    { lat: 48.1422286, lng: 18.681365 },
    { lat: 48.1413507, lng: 18.6811684 },
    { lat: 48.1409294, lng: 18.6788348 },
    { lat: 48.1406731, lng: 18.6788099 },
    { lat: 48.1405798, lng: 18.6786054 },
    { lat: 48.1403851, lng: 18.6786984 },
    { lat: 48.1402981, lng: 18.678843 },
    { lat: 48.1401713, lng: 18.6789144 },
    { lat: 48.1399949, lng: 18.6789088 },
    { lat: 48.1395989, lng: 18.6792619 },
    { lat: 48.1395525, lng: 18.678818 },
    { lat: 48.1395663, lng: 18.6784244 },
    { lat: 48.1394382, lng: 18.6781001 },
    { lat: 48.1392686, lng: 18.678093 },
    { lat: 48.1388487, lng: 18.6787124 },
    { lat: 48.138821, lng: 18.6781819 },
    { lat: 48.1387475, lng: 18.6779453 },
    { lat: 48.1384495, lng: 18.6778353 },
    { lat: 48.1384067, lng: 18.6774199 },
    { lat: 48.1382368, lng: 18.6772617 },
    { lat: 48.1381905, lng: 18.6771349 },
    { lat: 48.1382907, lng: 18.6766712 },
    { lat: 48.1382494, lng: 18.6764262 },
    { lat: 48.1381099, lng: 18.6763296 },
    { lat: 48.1379846, lng: 18.6763507 },
    { lat: 48.1377081, lng: 18.67664 },
    { lat: 48.1376456, lng: 18.6766073 },
    { lat: 48.1374434, lng: 18.6759343 },
    { lat: 48.1372376, lng: 18.6756724 },
    { lat: 48.1371897, lng: 18.675474 },
    { lat: 48.1372924, lng: 18.6745168 },
    { lat: 48.1374617, lng: 18.6742863 },
    { lat: 48.1377084, lng: 18.6737598 },
    { lat: 48.1377423, lng: 18.6733401 },
    { lat: 48.1376066, lng: 18.6726668 },
    { lat: 48.1378429, lng: 18.6720921 },
    { lat: 48.1379537, lng: 18.6712631 },
    { lat: 48.1377302, lng: 18.6707353 },
    { lat: 48.1373197, lng: 18.6701104 },
    { lat: 48.1373325, lng: 18.6699701 },
    { lat: 48.1375461, lng: 18.6698126 },
    { lat: 48.1378451, lng: 18.6691254 },
    { lat: 48.1377853, lng: 18.6689988 },
    { lat: 48.1375565, lng: 18.6689201 },
    { lat: 48.1373605, lng: 18.6681087 },
    { lat: 48.1372279, lng: 18.667994 },
    { lat: 48.1365968, lng: 18.668151 },
    { lat: 48.1362804, lng: 18.6678237 },
    { lat: 48.1361262, lng: 18.667516 },
    { lat: 48.1361091, lng: 18.6668666 },
    { lat: 48.1362131, lng: 18.6662856 },
    { lat: 48.1361861, lng: 18.6662447 },
    { lat: 48.1362743, lng: 18.6658962 },
    { lat: 48.1362083, lng: 18.6657604 },
    { lat: 48.1359788, lng: 18.6655847 },
    { lat: 48.1359328, lng: 18.6654205 },
    { lat: 48.1359964, lng: 18.6649693 },
    { lat: 48.1362028, lng: 18.6648876 },
    { lat: 48.1362782, lng: 18.6646352 },
    { lat: 48.1362867, lng: 18.6644551 },
    { lat: 48.1362353, lng: 18.664318 },
    { lat: 48.1362757, lng: 18.6640854 },
    { lat: 48.1362563, lng: 18.6640595 },
    { lat: 48.1362222, lng: 18.6640134 },
    { lat: 48.1350156, lng: 18.6642889 },
    { lat: 48.1331092, lng: 18.6645331 },
    { lat: 48.1273693, lng: 18.6658271 },
    { lat: 48.1243488, lng: 18.6661811 },
    { lat: 48.1241421, lng: 18.6667165 },
    { lat: 48.1241192, lng: 18.6667753 },
    { lat: 48.1239841, lng: 18.6667595 },
    { lat: 48.1239502, lng: 18.6673429 },
    { lat: 48.1232047, lng: 18.6695776 },
    { lat: 48.1233736, lng: 18.6715538 },
    { lat: 48.1232729, lng: 18.6719242 },
    { lat: 48.1231775, lng: 18.6721013 },
    { lat: 48.1231812, lng: 18.6722099 },
    { lat: 48.1232141, lng: 18.6721922 },
    { lat: 48.1232645, lng: 18.6726665 },
    { lat: 48.1235218, lng: 18.6739424 },
    { lat: 48.123548, lng: 18.6743674 },
    { lat: 48.1238395, lng: 18.6752102 },
    { lat: 48.1236306, lng: 18.6752887 },
    { lat: 48.1235411, lng: 18.675513 },
    { lat: 48.1227324, lng: 18.6758868 },
    { lat: 48.1224586, lng: 18.6764369 },
    { lat: 48.1222477, lng: 18.6764789 },
    { lat: 48.1220913, lng: 18.6768828 },
    { lat: 48.1220427, lng: 18.6773186 },
    { lat: 48.1217332, lng: 18.6771444 },
    { lat: 48.1214994, lng: 18.6771574 },
    { lat: 48.1212351, lng: 18.6772778 },
    { lat: 48.1196589, lng: 18.6790654 },
    { lat: 48.1196364, lng: 18.6790039 },
    { lat: 48.1195774, lng: 18.6790187 },
    { lat: 48.1195861, lng: 18.679114 },
    { lat: 48.1191053, lng: 18.6794239 },
    { lat: 48.1187081, lng: 18.6795652 },
    { lat: 48.118491, lng: 18.6795758 },
    { lat: 48.1198447, lng: 18.6846638 },
    { lat: 48.1198853, lng: 18.6852275 },
    { lat: 48.1202292, lng: 18.6872991 },
    { lat: 48.1211737, lng: 18.6920857 },
    { lat: 48.1215131, lng: 18.6941804 },
    { lat: 48.1220315, lng: 18.6968973 },
    { lat: 48.1222174, lng: 18.6981732 },
    { lat: 48.1226763, lng: 18.7021782 },
    { lat: 48.1228817, lng: 18.7023887 },
    { lat: 48.1240397, lng: 18.7088085 },
    { lat: 48.1245261, lng: 18.7119269 },
    { lat: 48.1245226, lng: 18.7144265 },
    { lat: 48.1240864, lng: 18.7157586 },
    { lat: 48.1236001, lng: 18.7185517 },
    { lat: 48.1236994, lng: 18.7203199 },
    { lat: 48.1237002, lng: 18.7253043 },
    { lat: 48.1239555, lng: 18.7280522 },
    { lat: 48.1239652, lng: 18.7297704 },
    { lat: 48.123919, lng: 18.7306577 },
    { lat: 48.1236448, lng: 18.7313939 },
    { lat: 48.1233868, lng: 18.7315676 },
    { lat: 48.1223309, lng: 18.731731 },
    { lat: 48.1205323, lng: 18.7336345 },
    { lat: 48.1199194, lng: 18.7352899 },
    { lat: 48.1197709, lng: 18.7365224 },
    { lat: 48.1190922, lng: 18.7381729 },
    { lat: 48.1182619, lng: 18.7403877 },
    { lat: 48.1179987, lng: 18.7407797 },
    { lat: 48.1179966, lng: 18.7417968 },
    { lat: 48.1177646, lng: 18.7431539 },
    { lat: 48.1175168, lng: 18.7442186 },
    { lat: 48.1174232, lng: 18.7449974 },
    { lat: 48.1172273, lng: 18.7481124 },
    { lat: 48.1168111, lng: 18.7516435 },
    { lat: 48.1168087, lng: 18.7517048 },
    { lat: 48.1171519, lng: 18.7531283 },
    { lat: 48.1171097, lng: 18.7546026 },
    { lat: 48.1175411, lng: 18.7587413 },
    { lat: 48.1176367, lng: 18.7593296 },
    { lat: 48.1192289, lng: 18.7594539 },
    { lat: 48.1198306, lng: 18.7594425 },
    { lat: 48.1207733, lng: 18.7590994 },
    { lat: 48.1215774, lng: 18.7586989 },
    { lat: 48.1241821, lng: 18.7564475 },
    { lat: 48.1257778, lng: 18.7549826 },
    { lat: 48.126587, lng: 18.7539767 },
    { lat: 48.1292852, lng: 18.750284 },
    { lat: 48.131054, lng: 18.7456199 },
    { lat: 48.1311133, lng: 18.7456096 },
    { lat: 48.1323499, lng: 18.7469501 },
    { lat: 48.1341246, lng: 18.7490139 },
    { lat: 48.1350685, lng: 18.750455 },
    { lat: 48.1352525, lng: 18.7506492 },
    { lat: 48.135397, lng: 18.7519189 },
    { lat: 48.137846, lng: 18.754407 },
    { lat: 48.1394568, lng: 18.754921 },
    { lat: 48.1410147, lng: 18.7555655 },
    { lat: 48.1413954, lng: 18.7567854 },
    { lat: 48.1420689, lng: 18.7568971 },
    { lat: 48.1426059, lng: 18.7565152 },
    { lat: 48.1436928, lng: 18.7556024 },
    { lat: 48.1440117, lng: 18.7554476 },
    { lat: 48.144202, lng: 18.7554628 },
    { lat: 48.1445453, lng: 18.7556211 },
    { lat: 48.1448727, lng: 18.7549491 },
  ],
  StarýTekov: [
    { lat: 48.2246192, lng: 18.5363 },
    { lat: 48.2241525, lng: 18.5365295 },
    { lat: 48.2207142, lng: 18.5381062 },
    { lat: 48.2200595, lng: 18.538691 },
    { lat: 48.2197475, lng: 18.5388459 },
    { lat: 48.2197651, lng: 18.539514 },
    { lat: 48.2198529, lng: 18.5418848 },
    { lat: 48.2196788, lng: 18.5452205 },
    { lat: 48.2194368, lng: 18.5452514 },
    { lat: 48.2197323, lng: 18.5483231 },
    { lat: 48.2231506, lng: 18.5619435 },
    { lat: 48.2232627, lng: 18.5618937 },
    { lat: 48.2236321, lng: 18.5617167 },
    { lat: 48.2238066, lng: 18.5615283 },
    { lat: 48.2239569, lng: 18.5610358 },
    { lat: 48.2241418, lng: 18.5609338 },
    { lat: 48.2242697, lng: 18.5607575 },
    { lat: 48.2243164, lng: 18.5602091 },
    { lat: 48.2246623, lng: 18.5599133 },
    { lat: 48.2251383, lng: 18.5592955 },
    { lat: 48.2252217, lng: 18.5592559 },
    { lat: 48.2254236, lng: 18.559339 },
    { lat: 48.2255578, lng: 18.5593158 },
    { lat: 48.2263711, lng: 18.5585359 },
    { lat: 48.2265833, lng: 18.5585179 },
    { lat: 48.226789, lng: 18.5586256 },
    { lat: 48.2269157, lng: 18.5587979 },
    { lat: 48.2272844, lng: 18.5587341 },
    { lat: 48.2274487, lng: 18.5586046 },
    { lat: 48.2276405, lng: 18.5588092 },
    { lat: 48.2278275, lng: 18.5588053 },
    { lat: 48.2279628, lng: 18.5588839 },
    { lat: 48.2282097, lng: 18.5587788 },
    { lat: 48.2284987, lng: 18.5588466 },
    { lat: 48.2289948, lng: 18.5587205 },
    { lat: 48.2297907, lng: 18.5588547 },
    { lat: 48.2302596, lng: 18.5590129 },
    { lat: 48.2304399, lng: 18.5589252 },
    { lat: 48.2308167, lng: 18.5585771 },
    { lat: 48.2311926, lng: 18.5583752 },
    { lat: 48.2312266, lng: 18.5583183 },
    { lat: 48.2311499, lng: 18.557847 },
    { lat: 48.231257, lng: 18.557745 },
    { lat: 48.2315039, lng: 18.5577005 },
    { lat: 48.2319373, lng: 18.5579295 },
    { lat: 48.2320413, lng: 18.5579344 },
    { lat: 48.2322434, lng: 18.5578133 },
    { lat: 48.2323662, lng: 18.5575665 },
    { lat: 48.2325638, lng: 18.5573805 },
    { lat: 48.2327696, lng: 18.5573106 },
    { lat: 48.2331114, lng: 18.557023 },
    { lat: 48.2337062, lng: 18.5557532 },
    { lat: 48.2338996, lng: 18.5551258 },
    { lat: 48.2343266, lng: 18.5541721 },
    { lat: 48.2349294, lng: 18.5536483 },
    { lat: 48.2351932, lng: 18.5531933 },
    { lat: 48.2354997, lng: 18.5529797 },
    { lat: 48.2357822, lng: 18.552375 },
    { lat: 48.2359059, lng: 18.552307 },
    { lat: 48.2361211, lng: 18.552381 },
    { lat: 48.2365575, lng: 18.5531404 },
    { lat: 48.2367538, lng: 18.5532015 },
    { lat: 48.2368589, lng: 18.5530838 },
    { lat: 48.2371174, lng: 18.5524066 },
    { lat: 48.2376021, lng: 18.5520748 },
    { lat: 48.2380476, lng: 18.5519301 },
    { lat: 48.2381598, lng: 18.5519166 },
    { lat: 48.2382298, lng: 18.5519816 },
    { lat: 48.2398875, lng: 18.5548256 },
    { lat: 48.2400003, lng: 18.5549306 },
    { lat: 48.240602, lng: 18.5550193 },
    { lat: 48.2406, lng: 18.554965 },
    { lat: 48.2536736, lng: 18.5779117 },
    { lat: 48.251898, lng: 18.5792075 },
    { lat: 48.2554274, lng: 18.5922774 },
    { lat: 48.2568852, lng: 18.5914173 },
    { lat: 48.2573361, lng: 18.5875719 },
    { lat: 48.2574371, lng: 18.5875206 },
    { lat: 48.2580095, lng: 18.5868661 },
    { lat: 48.2581327, lng: 18.5868326 },
    { lat: 48.2597816, lng: 18.5856898 },
    { lat: 48.2600792, lng: 18.5854172 },
    { lat: 48.2606302, lng: 18.5847685 },
    { lat: 48.2602348, lng: 18.5842629 },
    { lat: 48.2597811, lng: 18.5838849 },
    { lat: 48.2595812, lng: 18.5835706 },
    { lat: 48.2594004, lng: 18.5831351 },
    { lat: 48.2592226, lng: 18.5825017 },
    { lat: 48.2592887, lng: 18.5818136 },
    { lat: 48.2591288, lng: 18.5811971 },
    { lat: 48.2589681, lng: 18.5807733 },
    { lat: 48.2583034, lng: 18.5794499 },
    { lat: 48.2578645, lng: 18.578085 },
    { lat: 48.2573621, lng: 18.5763025 },
    { lat: 48.2570661, lng: 18.5754497 },
    { lat: 48.2573585, lng: 18.5752095 },
    { lat: 48.2578078, lng: 18.5746978 },
    { lat: 48.2621783, lng: 18.5694783 },
    { lat: 48.2645812, lng: 18.5664352 },
    { lat: 48.2659949, lng: 18.564937 },
    { lat: 48.2659466, lng: 18.5648375 },
    { lat: 48.2660145, lng: 18.5643066 },
    { lat: 48.2660021, lng: 18.5636677 },
    { lat: 48.2658749, lng: 18.5634465 },
    { lat: 48.2654501, lng: 18.563002 },
    { lat: 48.2652298, lng: 18.5626489 },
    { lat: 48.2648948, lng: 18.5619279 },
    { lat: 48.2653446, lng: 18.5611818 },
    { lat: 48.2648512, lng: 18.5603915 },
    { lat: 48.2644621, lng: 18.5595883 },
    { lat: 48.2638426, lng: 18.5605545 },
    { lat: 48.2636296, lng: 18.5601658 },
    { lat: 48.2629371, lng: 18.5609693 },
    { lat: 48.2625734, lng: 18.5601778 },
    { lat: 48.2616795, lng: 18.5576622 },
    { lat: 48.2607712, lng: 18.5584788 },
    { lat: 48.2601661, lng: 18.5591069 },
    { lat: 48.2544277, lng: 18.5487901 },
    { lat: 48.2546246, lng: 18.5485584 },
    { lat: 48.2529573, lng: 18.5391002 },
    { lat: 48.2573212, lng: 18.5349718 },
    { lat: 48.2572974, lng: 18.5341542 },
    { lat: 48.2569574, lng: 18.5323637 },
    { lat: 48.2568388, lng: 18.5307423 },
    { lat: 48.256481, lng: 18.5297575 },
    { lat: 48.2562336, lng: 18.5288285 },
    { lat: 48.255662, lng: 18.5260114 },
    { lat: 48.2540428, lng: 18.5244093 },
    { lat: 48.2539612, lng: 18.5244424 },
    { lat: 48.2524793, lng: 18.5247981 },
    { lat: 48.2520886, lng: 18.5255653 },
    { lat: 48.2516192, lng: 18.5259922 },
    { lat: 48.251167, lng: 18.5261193 },
    { lat: 48.2509079, lng: 18.5266553 },
    { lat: 48.2506558, lng: 18.5265835 },
    { lat: 48.2502757, lng: 18.5261984 },
    { lat: 48.2497707, lng: 18.5252638 },
    { lat: 48.2480082, lng: 18.5243963 },
    { lat: 48.2467417, lng: 18.5246075 },
    { lat: 48.2419384, lng: 18.5251973 },
    { lat: 48.2404939, lng: 18.5247135 },
    { lat: 48.2398162, lng: 18.524723 },
    { lat: 48.2394757, lng: 18.5243784 },
    { lat: 48.2393707, lng: 18.5249897 },
    { lat: 48.2397237, lng: 18.5277087 },
    { lat: 48.239328, lng: 18.5310654 },
    { lat: 48.2387803, lng: 18.5339204 },
    { lat: 48.2371598, lng: 18.5354466 },
    { lat: 48.236778, lng: 18.5352305 },
    { lat: 48.2353158, lng: 18.5348959 },
    { lat: 48.2345541, lng: 18.5352004 },
    { lat: 48.2334711, lng: 18.5360173 },
    { lat: 48.2324052, lng: 18.534543 },
    { lat: 48.2318324, lng: 18.5334833 },
    { lat: 48.2312381, lng: 18.5318323 },
    { lat: 48.2308338, lng: 18.5314308 },
    { lat: 48.2304454, lng: 18.5314695 },
    { lat: 48.2301342, lng: 18.5316725 },
    { lat: 48.2296543, lng: 18.5315641 },
    { lat: 48.2292646, lng: 18.5312881 },
    { lat: 48.2288949, lng: 18.5318662 },
    { lat: 48.2306037, lng: 18.5367576 },
    { lat: 48.2302152, lng: 18.5382012 },
    { lat: 48.229552, lng: 18.5384674 },
    { lat: 48.2289445, lng: 18.5390312 },
    { lat: 48.2285064, lng: 18.5395958 },
    { lat: 48.2279968, lng: 18.5392352 },
    { lat: 48.2274482, lng: 18.5381637 },
    { lat: 48.2273648, lng: 18.5373649 },
    { lat: 48.2246192, lng: 18.5363 },
  ],
  VyšnénadHronom: [
    { lat: 48.1704772, lng: 18.5716445 },
    { lat: 48.1702202, lng: 18.5717287 },
    { lat: 48.1697864, lng: 18.5716762 },
    { lat: 48.168781, lng: 18.571239 },
    { lat: 48.1683477, lng: 18.5714186 },
    { lat: 48.168167, lng: 18.5713732 },
    { lat: 48.1675021, lng: 18.5707279 },
    { lat: 48.1669609, lng: 18.5698286 },
    { lat: 48.1665455, lng: 18.5681764 },
    { lat: 48.1660166, lng: 18.5672569 },
    { lat: 48.1658945, lng: 18.5668804 },
    { lat: 48.1659425, lng: 18.5664586 },
    { lat: 48.1661881, lng: 18.5654466 },
    { lat: 48.1665121, lng: 18.5649203 },
    { lat: 48.1666937, lng: 18.5643828 },
    { lat: 48.1666868, lng: 18.5642787 },
    { lat: 48.1665919, lng: 18.5638851 },
    { lat: 48.1659079, lng: 18.5635885 },
    { lat: 48.1658153, lng: 18.563874 },
    { lat: 48.1657865, lng: 18.5641341 },
    { lat: 48.1658372, lng: 18.5648713 },
    { lat: 48.1657125, lng: 18.5654547 },
    { lat: 48.1653511, lng: 18.5661863 },
    { lat: 48.1651487, lng: 18.5664447 },
    { lat: 48.1649361, lng: 18.5665365 },
    { lat: 48.1641576, lng: 18.5665125 },
    { lat: 48.1637622, lng: 18.5667507 },
    { lat: 48.1638485, lng: 18.5663678 },
    { lat: 48.1634148, lng: 18.5666057 },
    { lat: 48.1631521, lng: 18.5681106 },
    { lat: 48.1631858, lng: 18.5690742 },
    { lat: 48.1633839, lng: 18.5699876 },
    { lat: 48.1636733, lng: 18.5705149 },
    { lat: 48.1644601, lng: 18.5713272 },
    { lat: 48.1661201, lng: 18.5724908 },
    { lat: 48.1662519, lng: 18.5726955 },
    { lat: 48.1663381, lng: 18.5730146 },
    { lat: 48.1664365, lng: 18.5734891 },
    { lat: 48.1664854, lng: 18.5740968 },
    { lat: 48.166427, lng: 18.5750064 },
    { lat: 48.1662302, lng: 18.5762103 },
    { lat: 48.1660476, lng: 18.5768686 },
    { lat: 48.1657241, lng: 18.5776983 },
    { lat: 48.1655442, lng: 18.5779959 },
    { lat: 48.1650028, lng: 18.5785717 },
    { lat: 48.164144, lng: 18.5790397 },
    { lat: 48.1637491, lng: 18.5791626 },
    { lat: 48.1624721, lng: 18.5792637 },
    { lat: 48.1619667, lng: 18.5791974 },
    { lat: 48.1614934, lng: 18.5790232 },
    { lat: 48.1597295, lng: 18.5786162 },
    { lat: 48.1589092, lng: 18.5787943 },
    { lat: 48.1582603, lng: 18.5791172 },
    { lat: 48.1578811, lng: 18.5797118 },
    { lat: 48.1577769, lng: 18.5802807 },
    { lat: 48.1577857, lng: 18.5809776 },
    { lat: 48.1578449, lng: 18.5815826 },
    { lat: 48.158024, lng: 18.5825272 },
    { lat: 48.1582049, lng: 18.58297 },
    { lat: 48.1589504, lng: 18.5840362 },
    { lat: 48.159556, lng: 18.5847358 },
    { lat: 48.1610951, lng: 18.5853853 },
    { lat: 48.1611465, lng: 18.5858287 },
    { lat: 48.1613367, lng: 18.5860249 },
    { lat: 48.1613689, lng: 18.5867006 },
    { lat: 48.1612465, lng: 18.5875734 },
    { lat: 48.160881, lng: 18.5886267 },
    { lat: 48.1606043, lng: 18.5892586 },
    { lat: 48.1596371, lng: 18.5909404 },
    { lat: 48.1592385, lng: 18.5917557 },
    { lat: 48.1588066, lng: 18.5924197 },
    { lat: 48.1582245, lng: 18.5934707 },
    { lat: 48.1579156, lng: 18.5941344 },
    { lat: 48.157644, lng: 18.5949645 },
    { lat: 48.157342, lng: 18.5956267 },
    { lat: 48.1567446, lng: 18.5963595 },
    { lat: 48.1563357, lng: 18.5966591 },
    { lat: 48.1555674, lng: 18.5969415 },
    { lat: 48.1553364, lng: 18.596928 },
    { lat: 48.1552179, lng: 18.5965762 },
    { lat: 48.1548423, lng: 18.5962038 },
    { lat: 48.154436, lng: 18.5956405 },
    { lat: 48.1535257, lng: 18.5949402 },
    { lat: 48.153193, lng: 18.5943613 },
    { lat: 48.1528413, lng: 18.5933968 },
    { lat: 48.1527429, lng: 18.5924847 },
    { lat: 48.1531885, lng: 18.5907744 },
    { lat: 48.1541029, lng: 18.5898393 },
    { lat: 48.1548555, lng: 18.5893284 },
    { lat: 48.1553693, lng: 18.5892536 },
    { lat: 48.1557608, lng: 18.5881629 },
    { lat: 48.1555462, lng: 18.5870054 },
    { lat: 48.154835, lng: 18.5859008 },
    { lat: 48.1540568, lng: 18.5857999 },
    { lat: 48.1535236, lng: 18.5859085 },
    { lat: 48.1527714, lng: 18.586429 },
    { lat: 48.1520782, lng: 18.5877175 },
    { lat: 48.150862, lng: 18.5891744 },
    { lat: 48.1501684, lng: 18.5898784 },
    { lat: 48.149521, lng: 18.5902054 },
    { lat: 48.1494548, lng: 18.5900069 },
    { lat: 48.1492256, lng: 18.5901461 },
    { lat: 48.149392, lng: 18.5910459 },
    { lat: 48.1491717, lng: 18.5929985 },
    { lat: 48.1491121, lng: 18.5940742 },
    { lat: 48.1491494, lng: 18.5947997 },
    { lat: 48.1493857, lng: 18.5962134 },
    { lat: 48.1498387, lng: 18.5971757 },
    { lat: 48.1503209, lng: 18.5978463 },
    { lat: 48.1509413, lng: 18.5983615 },
    { lat: 48.1515716, lng: 18.5987484 },
    { lat: 48.1524042, lng: 18.5973883 },
    { lat: 48.1526999, lng: 18.5970202 },
    { lat: 48.1536274, lng: 18.5982885 },
    { lat: 48.156553, lng: 18.6031562 },
    { lat: 48.1583976, lng: 18.6058729 },
    { lat: 48.1606573, lng: 18.608528 },
    { lat: 48.1636769, lng: 18.6118196 },
    { lat: 48.1637033, lng: 18.6118503 },
    { lat: 48.1641037, lng: 18.6110378 },
    { lat: 48.1643481, lng: 18.6108038 },
    { lat: 48.1647144, lng: 18.610886 },
    { lat: 48.1648316, lng: 18.6108428 },
    { lat: 48.1651146, lng: 18.6104221 },
    { lat: 48.1653561, lng: 18.6105729 },
    { lat: 48.1656508, lng: 18.6106593 },
    { lat: 48.1659092, lng: 18.6105568 },
    { lat: 48.1660966, lng: 18.6106244 },
    { lat: 48.1663596, lng: 18.610957 },
    { lat: 48.1666153, lng: 18.6109089 },
    { lat: 48.1668657, lng: 18.6106823 },
    { lat: 48.1669975, lng: 18.6103896 },
    { lat: 48.1671641, lng: 18.6101768 },
    { lat: 48.1672046, lng: 18.6099037 },
    { lat: 48.1676097, lng: 18.6093918 },
    { lat: 48.16763, lng: 18.609041 },
    { lat: 48.1676832, lng: 18.6089801 },
    { lat: 48.1680951, lng: 18.6089444 },
    { lat: 48.1682159, lng: 18.6090713 },
    { lat: 48.1684882, lng: 18.6097597 },
    { lat: 48.1687682, lng: 18.6100146 },
    { lat: 48.1686958, lng: 18.6106326 },
    { lat: 48.1687343, lng: 18.6107364 },
    { lat: 48.1688071, lng: 18.6107723 },
    { lat: 48.1690792, lng: 18.6107947 },
    { lat: 48.1693391, lng: 18.6103676 },
    { lat: 48.1695284, lng: 18.6102029 },
    { lat: 48.1697682, lng: 18.6102744 },
    { lat: 48.1699748, lng: 18.6105263 },
    { lat: 48.1701732, lng: 18.6104406 },
    { lat: 48.1707949, lng: 18.6107758 },
    { lat: 48.1709786, lng: 18.6107941 },
    { lat: 48.1711445, lng: 18.6105251 },
    { lat: 48.1712511, lng: 18.6104563 },
    { lat: 48.1714675, lng: 18.6104487 },
    { lat: 48.1716814, lng: 18.610735 },
    { lat: 48.1718054, lng: 18.6107517 },
    { lat: 48.1723783, lng: 18.6102731 },
    { lat: 48.1727923, lng: 18.6096566 },
    { lat: 48.1728168, lng: 18.6088822 },
    { lat: 48.1728969, lng: 18.6087 },
    { lat: 48.1731349, lng: 18.6087611 },
    { lat: 48.1734403, lng: 18.6087126 },
    { lat: 48.1737269, lng: 18.6085299 },
    { lat: 48.1737852, lng: 18.6084197 },
    { lat: 48.1737433, lng: 18.6083368 },
    { lat: 48.1736594, lng: 18.6080587 },
    { lat: 48.1734461, lng: 18.6078479 },
    { lat: 48.1734221, lng: 18.6076558 },
    { lat: 48.1734951, lng: 18.6072557 },
    { lat: 48.1734578, lng: 18.6070712 },
    { lat: 48.1733136, lng: 18.6068227 },
    { lat: 48.1730402, lng: 18.6066503 },
    { lat: 48.172841, lng: 18.6066177 },
    { lat: 48.1727466, lng: 18.6063981 },
    { lat: 48.1727606, lng: 18.6060141 },
    { lat: 48.1727017, lng: 18.6057407 },
    { lat: 48.1724087, lng: 18.6053294 },
    { lat: 48.1724517, lng: 18.6051489 },
    { lat: 48.1725903, lng: 18.6049977 },
    { lat: 48.1732511, lng: 18.6049359 },
    { lat: 48.1735074, lng: 18.6047513 },
    { lat: 48.1737568, lng: 18.6043191 },
    { lat: 48.1740054, lng: 18.6042655 },
    { lat: 48.1743663, lng: 18.6046158 },
    { lat: 48.1744519, lng: 18.604552 },
    { lat: 48.1749437, lng: 18.6045125 },
    { lat: 48.1750648, lng: 18.6036299 },
    { lat: 48.1749972, lng: 18.6021214 },
    { lat: 48.1751332, lng: 18.6017433 },
    { lat: 48.1753244, lng: 18.6015263 },
    { lat: 48.1758359, lng: 18.6012448 },
    { lat: 48.175913, lng: 18.6009216 },
    { lat: 48.1759684, lng: 18.6002851 },
    { lat: 48.1760899, lng: 18.600053 },
    { lat: 48.1766171, lng: 18.599812 },
    { lat: 48.177371, lng: 18.5996765 },
    { lat: 48.1775736, lng: 18.5997511 },
    { lat: 48.1778131, lng: 18.6000463 },
    { lat: 48.1779662, lng: 18.6001439 },
    { lat: 48.1781837, lng: 18.6007389 },
    { lat: 48.1781168, lng: 18.6011677 },
    { lat: 48.1781277, lng: 18.6017615 },
    { lat: 48.1782313, lng: 18.6022948 },
    { lat: 48.1782382, lng: 18.6025435 },
    { lat: 48.1783558, lng: 18.6027963 },
    { lat: 48.1788955, lng: 18.6032002 },
    { lat: 48.1791339, lng: 18.6032898 },
    { lat: 48.1793157, lng: 18.6031913 },
    { lat: 48.1794062, lng: 18.6029478 },
    { lat: 48.1797806, lng: 18.6025929 },
    { lat: 48.179882, lng: 18.6022815 },
    { lat: 48.1803351, lng: 18.6018435 },
    { lat: 48.1806653, lng: 18.6016714 },
    { lat: 48.1809301, lng: 18.6016892 },
    { lat: 48.1814417, lng: 18.6015523 },
    { lat: 48.1820506, lng: 18.6012811 },
    { lat: 48.182329, lng: 18.6009888 },
    { lat: 48.1827938, lng: 18.6006405 },
    { lat: 48.1828013, lng: 18.6004671 },
    { lat: 48.182892, lng: 18.6001669 },
    { lat: 48.1828961, lng: 18.59989 },
    { lat: 48.1823416, lng: 18.5980764 },
    { lat: 48.1822857, lng: 18.5977768 },
    { lat: 48.1822969, lng: 18.5971756 },
    { lat: 48.1822472, lng: 18.5968898 },
    { lat: 48.1821664, lng: 18.5967081 },
    { lat: 48.1816884, lng: 18.5966282 },
    { lat: 48.1815415, lng: 18.5965013 },
    { lat: 48.1814968, lng: 18.5963441 },
    { lat: 48.1814552, lng: 18.5956219 },
    { lat: 48.1814895, lng: 18.5954279 },
    { lat: 48.1818072, lng: 18.5947572 },
    { lat: 48.1818096, lng: 18.5943896 },
    { lat: 48.1815544, lng: 18.5940543 },
    { lat: 48.181491, lng: 18.5936549 },
    { lat: 48.181502, lng: 18.5934016 },
    { lat: 48.1817741, lng: 18.5925416 },
    { lat: 48.1819629, lng: 18.5917241 },
    { lat: 48.1824836, lng: 18.5910366 },
    { lat: 48.1829391, lng: 18.5906378 },
    { lat: 48.1836907, lng: 18.5906897 },
    { lat: 48.1839575, lng: 18.5905932 },
    { lat: 48.1842684, lng: 18.5903183 },
    { lat: 48.1846771, lng: 18.5895622 },
    { lat: 48.1847258, lng: 18.5894028 },
    { lat: 48.1847035, lng: 18.5892624 },
    { lat: 48.1845035, lng: 18.5890497 },
    { lat: 48.1843194, lng: 18.5886958 },
    { lat: 48.1842997, lng: 18.588249 },
    { lat: 48.1844335, lng: 18.5875847 },
    { lat: 48.1831136, lng: 18.5869608 },
    { lat: 48.1826788, lng: 18.5865827 },
    { lat: 48.1787019, lng: 18.5821973 },
    { lat: 48.1768849, lng: 18.5802837 },
    { lat: 48.1757714, lng: 18.5788996 },
    { lat: 48.1737321, lng: 18.575707 },
    { lat: 48.1726491, lng: 18.5742859 },
    { lat: 48.1713157, lng: 18.5726806 },
    { lat: 48.1712848, lng: 18.5725261 },
    { lat: 48.1705473, lng: 18.5717281 },
    { lat: 48.1704772, lng: 18.5716445 },
  ],
  Hokovce: [
    { lat: 48.17393, lng: 18.847541 },
    { lat: 48.173909, lng: 18.847409 },
    { lat: 48.173894, lng: 18.847319 },
    { lat: 48.173827, lng: 18.847236 },
    { lat: 48.173682, lng: 18.846181 },
    { lat: 48.173675, lng: 18.846144 },
    { lat: 48.174083, lng: 18.845225 },
    { lat: 48.174279, lng: 18.844862 },
    { lat: 48.174399, lng: 18.844589 },
    { lat: 48.174497, lng: 18.844309 },
    { lat: 48.174609, lng: 18.844004 },
    { lat: 48.174767, lng: 18.843662 },
    { lat: 48.174943, lng: 18.843117 },
    { lat: 48.175047, lng: 18.842573 },
    { lat: 48.17516, lng: 18.842279 },
    { lat: 48.17525, lng: 18.842114 },
    { lat: 48.175492, lng: 18.841717 },
    { lat: 48.175447, lng: 18.841216 },
    { lat: 48.175678, lng: 18.840833 },
    { lat: 48.175788, lng: 18.840539 },
    { lat: 48.17587, lng: 18.840238 },
    { lat: 48.176074, lng: 18.839833 },
    { lat: 48.176189, lng: 18.839027 },
    { lat: 48.176225, lng: 18.838764 },
    { lat: 48.176653, lng: 18.835345 },
    { lat: 48.176622, lng: 18.835268 },
    { lat: 48.176697, lng: 18.835041 },
    { lat: 48.177061, lng: 18.834137 },
    { lat: 48.177428, lng: 18.833229 },
    { lat: 48.177752, lng: 18.832451 },
    { lat: 48.177181, lng: 18.831846 },
    { lat: 48.17741, lng: 18.830389 },
    { lat: 48.177575, lng: 18.829208 },
    { lat: 48.177604, lng: 18.829034 },
    { lat: 48.177627, lng: 18.828863 },
    { lat: 48.177708, lng: 18.82827 },
    { lat: 48.177599, lng: 18.828147 },
    { lat: 48.17738, lng: 18.827859 },
    { lat: 48.176938, lng: 18.827279 },
    { lat: 48.176706, lng: 18.826976 },
    { lat: 48.176435, lng: 18.826621 },
    { lat: 48.176175, lng: 18.825742 },
    { lat: 48.175963, lng: 18.825021 },
    { lat: 48.175797, lng: 18.824457 },
    { lat: 48.175765, lng: 18.824351 },
    { lat: 48.175752, lng: 18.824306 },
    { lat: 48.175503, lng: 18.824167 },
    { lat: 48.174555, lng: 18.823641 },
    { lat: 48.173848, lng: 18.823112 },
    { lat: 48.173617, lng: 18.82294 },
    { lat: 48.173574, lng: 18.822952 },
    { lat: 48.173391, lng: 18.823 },
    { lat: 48.172844, lng: 18.823145 },
    { lat: 48.172822, lng: 18.823151 },
    { lat: 48.172795, lng: 18.823171 },
    { lat: 48.172512, lng: 18.823215 },
    { lat: 48.171905, lng: 18.823307 },
    { lat: 48.171657, lng: 18.823492 },
    { lat: 48.171395, lng: 18.82368 },
    { lat: 48.171014, lng: 18.824069 },
    { lat: 48.170706, lng: 18.824338 },
    { lat: 48.170648, lng: 18.82433 },
    { lat: 48.170432, lng: 18.824037 },
    { lat: 48.170384, lng: 18.824018 },
    { lat: 48.170312, lng: 18.823947 },
    { lat: 48.169907, lng: 18.82364 },
    { lat: 48.169811, lng: 18.823616 },
    { lat: 48.168873, lng: 18.824034 },
    { lat: 48.168636, lng: 18.823834 },
    { lat: 48.168474, lng: 18.823731 },
    { lat: 48.167176, lng: 18.822244 },
    { lat: 48.166276, lng: 18.821207 },
    { lat: 48.166111, lng: 18.820272 },
    { lat: 48.166069, lng: 18.820038 },
    { lat: 48.165926, lng: 18.81923 },
    { lat: 48.165894, lng: 18.819042 },
    { lat: 48.165796, lng: 18.818496 },
    { lat: 48.165463, lng: 18.81311 },
    { lat: 48.16572, lng: 18.81175 },
    { lat: 48.165525, lng: 18.811361 },
    { lat: 48.165392, lng: 18.811098 },
    { lat: 48.165231, lng: 18.810777 },
    { lat: 48.16502, lng: 18.80939 },
    { lat: 48.164829, lng: 18.808852 },
    { lat: 48.164461, lng: 18.8077838 },
    { lat: 48.1638517, lng: 18.8070934 },
    { lat: 48.1634993, lng: 18.80736 },
    { lat: 48.1630505, lng: 18.8075973 },
    { lat: 48.1628354, lng: 18.808008 },
    { lat: 48.1626372, lng: 18.8081613 },
    { lat: 48.1623206, lng: 18.8082347 },
    { lat: 48.1620875, lng: 18.8083886 },
    { lat: 48.1610738, lng: 18.8097157 },
    { lat: 48.1608924, lng: 18.8101257 },
    { lat: 48.1604652, lng: 18.8107892 },
    { lat: 48.1595488, lng: 18.8120397 },
    { lat: 48.1593685, lng: 18.8121489 },
    { lat: 48.1586488, lng: 18.8114203 },
    { lat: 48.1573234, lng: 18.8106197 },
    { lat: 48.1563359, lng: 18.8105841 },
    { lat: 48.1548121, lng: 18.8112652 },
    { lat: 48.1540619, lng: 18.8133535 },
    { lat: 48.1532026, lng: 18.8150877 },
    { lat: 48.1513039, lng: 18.8182186 },
    { lat: 48.1507861, lng: 18.8202507 },
    { lat: 48.1509256, lng: 18.8236273 },
    { lat: 48.1507235, lng: 18.8252966 },
    { lat: 48.1503992, lng: 18.8266784 },
    { lat: 48.1503188, lng: 18.8267751 },
    { lat: 48.1502341, lng: 18.8271149 },
    { lat: 48.1500064, lng: 18.8276109 },
    { lat: 48.1480653, lng: 18.8306928 },
    { lat: 48.1465389, lng: 18.8334497 },
    { lat: 48.1460052, lng: 18.8343029 },
    { lat: 48.1458147, lng: 18.8345802 },
    { lat: 48.1450911, lng: 18.8352547 },
    { lat: 48.1449436, lng: 18.8354962 },
    { lat: 48.1449623, lng: 18.835529 },
    { lat: 48.1451538, lng: 18.8358451 },
    { lat: 48.1453645, lng: 18.8360383 },
    { lat: 48.1457518, lng: 18.8360236 },
    { lat: 48.1465318, lng: 18.8362753 },
    { lat: 48.1466843, lng: 18.8364972 },
    { lat: 48.1467262, lng: 18.8366685 },
    { lat: 48.1467424, lng: 18.837836 },
    { lat: 48.1463801, lng: 18.8392033 },
    { lat: 48.1456911, lng: 18.8405387 },
    { lat: 48.1447506, lng: 18.841979 },
    { lat: 48.1447335, lng: 18.8421247 },
    { lat: 48.1441997, lng: 18.8428238 },
    { lat: 48.1441347, lng: 18.8430034 },
    { lat: 48.1439855, lng: 18.8431046 },
    { lat: 48.1436951, lng: 18.8434835 },
    { lat: 48.1425823, lng: 18.8459159 },
    { lat: 48.1420046, lng: 18.8462088 },
    { lat: 48.1408508, lng: 18.8484418 },
    { lat: 48.140558, lng: 18.8493343 },
    { lat: 48.1400841, lng: 18.8502283 },
    { lat: 48.1391131, lng: 18.8546198 },
    { lat: 48.1388319, lng: 18.855041 },
    { lat: 48.1387579, lng: 18.855373 },
    { lat: 48.1387663, lng: 18.8562246 },
    { lat: 48.1390959, lng: 18.8565077 },
    { lat: 48.1393118, lng: 18.8572507 },
    { lat: 48.1393382, lng: 18.857756 },
    { lat: 48.1392533, lng: 18.8583725 },
    { lat: 48.1414906, lng: 18.8638052 },
    { lat: 48.1419805, lng: 18.8678707 },
    { lat: 48.1426965, lng: 18.8732007 },
    { lat: 48.1421391, lng: 18.873368 },
    { lat: 48.1427235, lng: 18.8761615 },
    { lat: 48.1432506, lng: 18.8790866 },
    { lat: 48.1419145, lng: 18.8807565 },
    { lat: 48.142106, lng: 18.8809037 },
    { lat: 48.1424373, lng: 18.8811585 },
    { lat: 48.1426329, lng: 18.8811244 },
    { lat: 48.1430711, lng: 18.8806201 },
    { lat: 48.143387, lng: 18.880537 },
    { lat: 48.1435174, lng: 18.880614 },
    { lat: 48.1436938, lng: 18.8809056 },
    { lat: 48.143906, lng: 18.8810369 },
    { lat: 48.1440099, lng: 18.8814542 },
    { lat: 48.1441641, lng: 18.8816862 },
    { lat: 48.1443447, lng: 18.8822739 },
    { lat: 48.1443379, lng: 18.8827683 },
    { lat: 48.1446056, lng: 18.8827124 },
    { lat: 48.144965, lng: 18.882165 },
    { lat: 48.1451046, lng: 18.8820958 },
    { lat: 48.1467111, lng: 18.8821309 },
    { lat: 48.1475871, lng: 18.8818283 },
    { lat: 48.148502, lng: 18.8817045 },
    { lat: 48.1499296, lng: 18.8808184 },
    { lat: 48.1508463, lng: 18.8797794 },
    { lat: 48.1512899, lng: 18.8794403 },
    { lat: 48.1516209, lng: 18.8793445 },
    { lat: 48.1518855, lng: 18.8793572 },
    { lat: 48.1523061, lng: 18.8795112 },
    { lat: 48.1527519, lng: 18.8798174 },
    { lat: 48.1531361, lng: 18.8799213 },
    { lat: 48.1552976, lng: 18.885672 },
    { lat: 48.1560545, lng: 18.8852011 },
    { lat: 48.1566781, lng: 18.8846384 },
    { lat: 48.1571934, lng: 18.884357 },
    { lat: 48.1580816, lng: 18.8837211 },
    { lat: 48.1582297, lng: 18.8835781 },
    { lat: 48.1588604, lng: 18.8826765 },
    { lat: 48.1588397, lng: 18.8833591 },
    { lat: 48.1588729, lng: 18.8839101 },
    { lat: 48.1590771, lng: 18.8857977 },
    { lat: 48.159186, lng: 18.88622 },
    { lat: 48.159323, lng: 18.886258 },
    { lat: 48.159409, lng: 18.886266 },
    { lat: 48.159498, lng: 18.886232 },
    { lat: 48.159644, lng: 18.886136 },
    { lat: 48.159725, lng: 18.886079 },
    { lat: 48.159944, lng: 18.885753 },
    { lat: 48.160173, lng: 18.885401 },
    { lat: 48.160027, lng: 18.885261 },
    { lat: 48.159934, lng: 18.885172 },
    { lat: 48.159781, lng: 18.885014 },
    { lat: 48.159732, lng: 18.884905 },
    { lat: 48.159711, lng: 18.884798 },
    { lat: 48.159701, lng: 18.88465 },
    { lat: 48.159707, lng: 18.884517 },
    { lat: 48.159728, lng: 18.884324 },
    { lat: 48.159743, lng: 18.884257 },
    { lat: 48.159763, lng: 18.884182 },
    { lat: 48.159771, lng: 18.884139 },
    { lat: 48.159775, lng: 18.884101 },
    { lat: 48.159782, lng: 18.883993 },
    { lat: 48.159781, lng: 18.883788 },
    { lat: 48.159793, lng: 18.883653 },
    { lat: 48.159807, lng: 18.883471 },
    { lat: 48.15982, lng: 18.88341 },
    { lat: 48.159828, lng: 18.883348 },
    { lat: 48.159841, lng: 18.883288 },
    { lat: 48.159846, lng: 18.883244 },
    { lat: 48.159857, lng: 18.883194 },
    { lat: 48.1598726, lng: 18.883078 },
    { lat: 48.159918, lng: 18.882919 },
    { lat: 48.159946, lng: 18.882758 },
    { lat: 48.159967, lng: 18.882643 },
    { lat: 48.160014, lng: 18.882428 },
    { lat: 48.16, lng: 18.882366 },
    { lat: 48.160003, lng: 18.882322 },
    { lat: 48.160017, lng: 18.882264 },
    { lat: 48.160019, lng: 18.882257 },
    { lat: 48.160031, lng: 18.88226 },
    { lat: 48.160031, lng: 18.882256 },
    { lat: 48.160046, lng: 18.882201 },
    { lat: 48.160047, lng: 18.882194 },
    { lat: 48.160033, lng: 18.882099 },
    { lat: 48.160052, lng: 18.882023 },
    { lat: 48.160062, lng: 18.881922 },
    { lat: 48.160076, lng: 18.881859 },
    { lat: 48.160091, lng: 18.881808 },
    { lat: 48.160106, lng: 18.881757 },
    { lat: 48.160128, lng: 18.881687 },
    { lat: 48.16019, lng: 18.881569 },
    { lat: 48.16027, lng: 18.8816 },
    { lat: 48.160537, lng: 18.881539 },
    { lat: 48.160691, lng: 18.881508 },
    { lat: 48.160823, lng: 18.881491 },
    { lat: 48.160899, lng: 18.881497 },
    { lat: 48.160954, lng: 18.881485 },
    { lat: 48.161107, lng: 18.881249 },
    { lat: 48.16111, lng: 18.881244 },
    { lat: 48.16114, lng: 18.881191 },
    { lat: 48.161149, lng: 18.881175 },
    { lat: 48.161193, lng: 18.881121 },
    { lat: 48.161235, lng: 18.88106 },
    { lat: 48.161281, lng: 18.880999 },
    { lat: 48.161342, lng: 18.880915 },
    { lat: 48.161408, lng: 18.880823 },
    { lat: 48.161475, lng: 18.880731 },
    { lat: 48.161477, lng: 18.880702 },
    { lat: 48.16148, lng: 18.880669 },
    { lat: 48.161487, lng: 18.880603 },
    { lat: 48.161505, lng: 18.8805 },
    { lat: 48.161516, lng: 18.880456 },
    { lat: 48.161523, lng: 18.880424 },
    { lat: 48.16153, lng: 18.880391 },
    { lat: 48.161538, lng: 18.88033 },
    { lat: 48.161547, lng: 18.880266 },
    { lat: 48.161553, lng: 18.88017 },
    { lat: 48.161561, lng: 18.880051 },
    { lat: 48.161569, lng: 18.879956 },
    { lat: 48.161584, lng: 18.87983 },
    { lat: 48.1616, lng: 18.879712 },
    { lat: 48.161603, lng: 18.879599 },
    { lat: 48.161602, lng: 18.87948 },
    { lat: 48.161595, lng: 18.879387 },
    { lat: 48.161587, lng: 18.87929 },
    { lat: 48.161579, lng: 18.879186 },
    { lat: 48.161549, lng: 18.879088 },
    { lat: 48.161474, lng: 18.878833 },
    { lat: 48.161353, lng: 18.878506 },
    { lat: 48.161319, lng: 18.878488 },
    { lat: 48.161218, lng: 18.878346 },
    { lat: 48.16116, lng: 18.878291 },
    { lat: 48.160899, lng: 18.878144 },
    { lat: 48.160797, lng: 18.878153 },
    { lat: 48.160681, lng: 18.878163 },
    { lat: 48.159308, lng: 18.878224 },
    { lat: 48.158948, lng: 18.878341 },
    { lat: 48.158693, lng: 18.878252 },
    { lat: 48.157388, lng: 18.878314 },
    { lat: 48.156765, lng: 18.878412 },
    { lat: 48.156522, lng: 18.878607 },
    { lat: 48.15662, lng: 18.878168 },
    { lat: 48.156727, lng: 18.877748 },
    { lat: 48.157146, lng: 18.877558 },
    { lat: 48.157257, lng: 18.877384 },
    { lat: 48.157468, lng: 18.877154 },
    { lat: 48.157672, lng: 18.877104 },
    { lat: 48.157841, lng: 18.876879 },
    { lat: 48.158222, lng: 18.876716 },
    { lat: 48.158512, lng: 18.876503 },
    { lat: 48.159019, lng: 18.876324 },
    { lat: 48.159167, lng: 18.876324 },
    { lat: 48.159261, lng: 18.876213 },
    { lat: 48.159451, lng: 18.876115 },
    { lat: 48.159674, lng: 18.876008 },
    { lat: 48.160112, lng: 18.875819 },
    { lat: 48.160422, lng: 18.87561 },
    { lat: 48.160711, lng: 18.875374 },
    { lat: 48.160943, lng: 18.8751 },
    { lat: 48.161343, lng: 18.874613 },
    { lat: 48.161396, lng: 18.874386 },
    { lat: 48.161449, lng: 18.874249 },
    { lat: 48.16144, lng: 18.874139 },
    { lat: 48.161433, lng: 18.873444 },
    { lat: 48.16143, lng: 18.873134 },
    { lat: 48.16143, lng: 18.873062 },
    { lat: 48.161429, lng: 18.87298 },
    { lat: 48.161428, lng: 18.872903 },
    { lat: 48.161428, lng: 18.872744 },
    { lat: 48.161411, lng: 18.870737 },
    { lat: 48.16209, lng: 18.870762 },
    { lat: 48.162351, lng: 18.870768 },
    { lat: 48.162538, lng: 18.870775 },
    { lat: 48.16284, lng: 18.870729 },
    { lat: 48.162939, lng: 18.870722 },
    { lat: 48.163262, lng: 18.870563 },
    { lat: 48.163304, lng: 18.87051 },
    { lat: 48.163694, lng: 18.870113 },
    { lat: 48.163934, lng: 18.869865 },
    { lat: 48.164065, lng: 18.86973 },
    { lat: 48.164331, lng: 18.869409 },
    { lat: 48.164592, lng: 18.869093 },
    { lat: 48.164692, lng: 18.868982 },
    { lat: 48.164877, lng: 18.868768 },
    { lat: 48.165136, lng: 18.868443 },
    { lat: 48.165371, lng: 18.868783 },
    { lat: 48.165533, lng: 18.869023 },
    { lat: 48.165705, lng: 18.869253 },
    { lat: 48.165814, lng: 18.869389 },
    { lat: 48.165947, lng: 18.869581 },
    { lat: 48.166045, lng: 18.869748 },
    { lat: 48.166178, lng: 18.869999 },
    { lat: 48.166336, lng: 18.870359 },
    { lat: 48.166543, lng: 18.870791 },
    { lat: 48.166892, lng: 18.871571 },
    { lat: 48.166978, lng: 18.871514 },
    { lat: 48.16701, lng: 18.87127 },
    { lat: 48.167204, lng: 18.870294 },
    { lat: 48.167214, lng: 18.870015 },
    { lat: 48.167194, lng: 18.86978 },
    { lat: 48.167226, lng: 18.868511 },
    { lat: 48.167261, lng: 18.868062 },
    { lat: 48.167421, lng: 18.866459 },
    { lat: 48.167375, lng: 18.865925 },
    { lat: 48.167345, lng: 18.865611 },
    { lat: 48.167329, lng: 18.865543 },
    { lat: 48.167333, lng: 18.865448 },
    { lat: 48.167337, lng: 18.86533 },
    { lat: 48.167341, lng: 18.865247 },
    { lat: 48.167299, lng: 18.864981 },
    { lat: 48.167087, lng: 18.864644 },
    { lat: 48.166814, lng: 18.864247 },
    { lat: 48.166682, lng: 18.864054 },
    { lat: 48.166424, lng: 18.863518 },
    { lat: 48.166828, lng: 18.8628 },
    { lat: 48.16697, lng: 18.862559 },
    { lat: 48.167082, lng: 18.862217 },
    { lat: 48.167157, lng: 18.861862 },
    { lat: 48.167235, lng: 18.861437 },
    { lat: 48.167363, lng: 18.860611 },
    { lat: 48.167514, lng: 18.860336 },
    { lat: 48.167723, lng: 18.860163 },
    { lat: 48.167517, lng: 18.857983 },
    { lat: 48.167266, lng: 18.855338 },
    { lat: 48.169076, lng: 18.853276 },
    { lat: 48.17017, lng: 18.852019 },
    { lat: 48.172749, lng: 18.849181 },
    { lat: 48.173302, lng: 18.848622 },
    { lat: 48.17413, lng: 18.847965 },
    { lat: 48.17393, lng: 18.847541 },
  ],
  HornáSeč: [
    { lat: 48.1830426, lng: 18.533465 },
    { lat: 48.1899118, lng: 18.536975 },
    { lat: 48.1887542, lng: 18.5429685 },
    { lat: 48.1905223, lng: 18.5511363 },
    { lat: 48.1909251, lng: 18.5525506 },
    { lat: 48.1916071, lng: 18.5566891 },
    { lat: 48.1921628, lng: 18.5591881 },
    { lat: 48.1924653, lng: 18.5601768 },
    { lat: 48.1931108, lng: 18.5618478 },
    { lat: 48.1941312, lng: 18.5638493 },
    { lat: 48.1958036, lng: 18.5667014 },
    { lat: 48.1960149, lng: 18.5671584 },
    { lat: 48.1964988, lng: 18.5678411 },
    { lat: 48.2027978, lng: 18.5684389 },
    { lat: 48.2028141, lng: 18.5684681 },
    { lat: 48.202853, lng: 18.5684583 },
    { lat: 48.2123003, lng: 18.561925 },
    { lat: 48.2131452, lng: 18.5612253 },
    { lat: 48.2135617, lng: 18.5610277 },
    { lat: 48.2208302, lng: 18.5645964 },
    { lat: 48.2208092, lng: 18.5640709 },
    { lat: 48.2211767, lng: 18.5634913 },
    { lat: 48.2217434, lng: 18.5632321 },
    { lat: 48.2221183, lng: 18.5633219 },
    { lat: 48.2223507, lng: 18.5631154 },
    { lat: 48.2225135, lng: 18.5631025 },
    { lat: 48.2227651, lng: 18.56294 },
    { lat: 48.2229538, lng: 18.5629019 },
    { lat: 48.2230102, lng: 18.5628196 },
    { lat: 48.222962, lng: 18.5620877 },
    { lat: 48.2230884, lng: 18.561965 },
    { lat: 48.2231506, lng: 18.5619435 },
    { lat: 48.2197323, lng: 18.5483231 },
    { lat: 48.2194368, lng: 18.5452514 },
    { lat: 48.2196788, lng: 18.5452205 },
    { lat: 48.2198529, lng: 18.5418848 },
    { lat: 48.2197651, lng: 18.539514 },
    { lat: 48.2197475, lng: 18.5388459 },
    { lat: 48.2196959, lng: 18.5388707 },
    { lat: 48.2193151, lng: 18.5389395 },
    { lat: 48.2190231, lng: 18.5388613 },
    { lat: 48.2187136, lng: 18.5387095 },
    { lat: 48.2181307, lng: 18.538224 },
    { lat: 48.2177697, lng: 18.5378335 },
    { lat: 48.2174564, lng: 18.53739 },
    { lat: 48.2172797, lng: 18.5370093 },
    { lat: 48.2169661, lng: 18.5365182 },
    { lat: 48.2167764, lng: 18.5359997 },
    { lat: 48.2166714, lng: 18.5355705 },
    { lat: 48.2169693, lng: 18.5341996 },
    { lat: 48.2169703, lng: 18.5332353 },
    { lat: 48.2171242, lng: 18.5327339 },
    { lat: 48.2172131, lng: 18.5317867 },
    { lat: 48.2173049, lng: 18.5313852 },
    { lat: 48.2172912, lng: 18.5307909 },
    { lat: 48.2171385, lng: 18.5302293 },
    { lat: 48.2169474, lng: 18.5300167 },
    { lat: 48.216588, lng: 18.5300444 },
    { lat: 48.2163181, lng: 18.5299593 },
    { lat: 48.2120849, lng: 18.5279834 },
    { lat: 48.2103482, lng: 18.53081 },
    { lat: 48.2098947, lng: 18.531284 },
    { lat: 48.2095138, lng: 18.531329 },
    { lat: 48.2092405, lng: 18.5314879 },
    { lat: 48.2092944, lng: 18.5320372 },
    { lat: 48.2091503, lng: 18.532346 },
    { lat: 48.2088456, lng: 18.5324059 },
    { lat: 48.2088217, lng: 18.5322384 },
    { lat: 48.208593, lng: 18.5322673 },
    { lat: 48.2081931, lng: 18.531968 },
    { lat: 48.2076777, lng: 18.5313785 },
    { lat: 48.2074052, lng: 18.5308357 },
    { lat: 48.2071426, lng: 18.5296318 },
    { lat: 48.206856, lng: 18.528914 },
    { lat: 48.2057354, lng: 18.5267011 },
    { lat: 48.2045513, lng: 18.5273375 },
    { lat: 48.2045681, lng: 18.5276038 },
    { lat: 48.2052498, lng: 18.5333763 },
    { lat: 48.1998082, lng: 18.5339901 },
    { lat: 48.1996815, lng: 18.5340849 },
    { lat: 48.1885742, lng: 18.5324814 },
    { lat: 48.1867182, lng: 18.531878 },
    { lat: 48.1866951, lng: 18.5311335 },
    { lat: 48.185153, lng: 18.5308859 },
    { lat: 48.1846504, lng: 18.5311419 },
    { lat: 48.1838807, lng: 18.5317279 },
    { lat: 48.183652, lng: 18.5323256 },
    { lat: 48.1830426, lng: 18.533465 },
  ],
  VyškovcenadIpľom: [
    { lat: 48.0620069, lng: 18.8213507 },
    { lat: 48.0611655, lng: 18.8219719 },
    { lat: 48.0596859, lng: 18.8218174 },
    { lat: 48.0596801, lng: 18.8219672 },
    { lat: 48.0592533, lng: 18.8220201 },
    { lat: 48.0591149, lng: 18.8223042 },
    { lat: 48.0587625, lng: 18.8225059 },
    { lat: 48.0586774, lng: 18.8230119 },
    { lat: 48.0587085, lng: 18.8235775 },
    { lat: 48.0593627, lng: 18.8245654 },
    { lat: 48.0591174, lng: 18.8278925 },
    { lat: 48.0589775, lng: 18.8306284 },
    { lat: 48.0588009, lng: 18.831131 },
    { lat: 48.0582218, lng: 18.8312489 },
    { lat: 48.0581433, lng: 18.8323061 },
    { lat: 48.0585509, lng: 18.8331723 },
    { lat: 48.0582587, lng: 18.8339644 },
    { lat: 48.0580701, lng: 18.8340859 },
    { lat: 48.0577167, lng: 18.834078 },
    { lat: 48.0575115, lng: 18.8339518 },
    { lat: 48.0570479, lng: 18.8338852 },
    { lat: 48.0568954, lng: 18.8339419 },
    { lat: 48.0562214, lng: 18.8349996 },
    { lat: 48.0560848, lng: 18.8350854 },
    { lat: 48.0560283, lng: 18.8348026 },
    { lat: 48.0558338, lng: 18.8344278 },
    { lat: 48.0556784, lng: 18.8343272 },
    { lat: 48.0554825, lng: 18.8343939 },
    { lat: 48.0552505, lng: 18.83466 },
    { lat: 48.0543631, lng: 18.8361368 },
    { lat: 48.0540434, lng: 18.836213 },
    { lat: 48.054013, lng: 18.8364309 },
    { lat: 48.0540834, lng: 18.8368026 },
    { lat: 48.054295, lng: 18.8370964 },
    { lat: 48.0544748, lng: 18.8373394 },
    { lat: 48.0553614, lng: 18.8380072 },
    { lat: 48.0555062, lng: 18.8381457 },
    { lat: 48.0555532, lng: 18.8382886 },
    { lat: 48.0551684, lng: 18.8394019 },
    { lat: 48.0545767, lng: 18.8408849 },
    { lat: 48.0540967, lng: 18.8424151 },
    { lat: 48.0538391, lng: 18.8429418 },
    { lat: 48.0534531, lng: 18.8434744 },
    { lat: 48.0529834, lng: 18.8445148 },
    { lat: 48.052572, lng: 18.845585 },
    { lat: 48.051072, lng: 18.849954 },
    { lat: 48.050484, lng: 18.851841 },
    { lat: 48.049931, lng: 18.851681 },
    { lat: 48.049337, lng: 18.853149 },
    { lat: 48.049037, lng: 18.853068 },
    { lat: 48.048741, lng: 18.854042 },
    { lat: 48.048376, lng: 18.853957 },
    { lat: 48.0481948, lng: 18.8539124 },
    { lat: 48.0481714, lng: 18.8555268 },
    { lat: 48.0483047, lng: 18.8560656 },
    { lat: 48.0486185, lng: 18.8570918 },
    { lat: 48.0492248, lng: 18.858527 },
    { lat: 48.0501436, lng: 18.8601595 },
    { lat: 48.049007, lng: 18.8657105 },
    { lat: 48.0470707, lng: 18.8749365 },
    { lat: 48.048777, lng: 18.875462 },
    { lat: 48.048764, lng: 18.87576 },
    { lat: 48.048604, lng: 18.876178 },
    { lat: 48.04825, lng: 18.876823 },
    { lat: 48.049403, lng: 18.878273 },
    { lat: 48.049585, lng: 18.878882 },
    { lat: 48.049423, lng: 18.879518 },
    { lat: 48.049382, lng: 18.880057 },
    { lat: 48.049667, lng: 18.881073 },
    { lat: 48.049986, lng: 18.881456 },
    { lat: 48.050785, lng: 18.882066 },
    { lat: 48.050593, lng: 18.882893 },
    { lat: 48.051057, lng: 18.883008 },
    { lat: 48.050964, lng: 18.883688 },
    { lat: 48.050816, lng: 18.883682 },
    { lat: 48.050734, lng: 18.884261 },
    { lat: 48.050924, lng: 18.88432 },
    { lat: 48.050346, lng: 18.884716 },
    { lat: 48.050312, lng: 18.885241 },
    { lat: 48.050374, lng: 18.885287 },
    { lat: 48.050549, lng: 18.885047 },
    { lat: 48.050946, lng: 18.884889 },
    { lat: 48.051149, lng: 18.884963 },
    { lat: 48.051251, lng: 18.884463 },
    { lat: 48.051547, lng: 18.883976 },
    { lat: 48.051806, lng: 18.883353 },
    { lat: 48.051992, lng: 18.883314 },
    { lat: 48.053457, lng: 18.884103 },
    { lat: 48.054451, lng: 18.884259 },
    { lat: 48.054595, lng: 18.884343 },
    { lat: 48.054805, lng: 18.884836 },
    { lat: 48.054638, lng: 18.88569 },
    { lat: 48.054716, lng: 18.88581 },
    { lat: 48.054991, lng: 18.885665 },
    { lat: 48.055224, lng: 18.885679 },
    { lat: 48.055494, lng: 18.885771 },
    { lat: 48.055669, lng: 18.885963 },
    { lat: 48.055887, lng: 18.886097 },
    { lat: 48.056975, lng: 18.88603 },
    { lat: 48.057299, lng: 18.885902 },
    { lat: 48.058308, lng: 18.886715 },
    { lat: 48.058682, lng: 18.886674 },
    { lat: 48.0592239, lng: 18.885386 },
    { lat: 48.0588866, lng: 18.8842148 },
    { lat: 48.0555997, lng: 18.8829649 },
    { lat: 48.055516, lng: 18.8821637 },
    { lat: 48.0555568, lng: 18.8817596 },
    { lat: 48.0556245, lng: 18.8815223 },
    { lat: 48.0558597, lng: 18.8811886 },
    { lat: 48.0564357, lng: 18.8808158 },
    { lat: 48.0573588, lng: 18.8799859 },
    { lat: 48.0576447, lng: 18.8798671 },
    { lat: 48.0578151, lng: 18.8798723 },
    { lat: 48.0579927, lng: 18.8799472 },
    { lat: 48.0581525, lng: 18.8802649 },
    { lat: 48.0581958, lng: 18.8805524 },
    { lat: 48.0580691, lng: 18.8806771 },
    { lat: 48.0575006, lng: 18.8809536 },
    { lat: 48.057367, lng: 18.8813737 },
    { lat: 48.0573262, lng: 18.8819992 },
    { lat: 48.0573882, lng: 18.8824495 },
    { lat: 48.0575152, lng: 18.8827005 },
    { lat: 48.0576947, lng: 18.8827033 },
    { lat: 48.0579963, lng: 18.88213 },
    { lat: 48.0582392, lng: 18.8818797 },
    { lat: 48.0583121, lng: 18.8817239 },
    { lat: 48.0584058, lng: 18.8817035 },
    { lat: 48.058743, lng: 18.8818772 },
    { lat: 48.0591809, lng: 18.8829642 },
    { lat: 48.0598338, lng: 18.8831354 },
    { lat: 48.0602066, lng: 18.8830851 },
    { lat: 48.0607283, lng: 18.8829025 },
    { lat: 48.0614416, lng: 18.882507 },
    { lat: 48.0615013, lng: 18.882181 },
    { lat: 48.061334, lng: 18.881891 },
    { lat: 48.0607671, lng: 18.8816191 },
    { lat: 48.0606853, lng: 18.8811968 },
    { lat: 48.0607594, lng: 18.8801956 },
    { lat: 48.0609228, lng: 18.8796098 },
    { lat: 48.06131, lng: 18.8786885 },
    { lat: 48.0615715, lng: 18.8782989 },
    { lat: 48.0621053, lng: 18.8772498 },
    { lat: 48.0626854, lng: 18.8763314 },
    { lat: 48.0630017, lng: 18.8762336 },
    { lat: 48.063029, lng: 18.876327 },
    { lat: 48.063017, lng: 18.8771959 },
    { lat: 48.0628479, lng: 18.8779308 },
    { lat: 48.0630831, lng: 18.8787876 },
    { lat: 48.0637218, lng: 18.8787504 },
    { lat: 48.0638782, lng: 18.878637 },
    { lat: 48.0658097, lng: 18.8781812 },
    { lat: 48.0660688, lng: 18.8764913 },
    { lat: 48.0665791, lng: 18.8765025 },
    { lat: 48.0675173, lng: 18.8760715 },
    { lat: 48.0685394, lng: 18.8759505 },
    { lat: 48.0685727, lng: 18.8757693 },
    { lat: 48.0683358, lng: 18.8754228 },
    { lat: 48.0683491, lng: 18.8733912 },
    { lat: 48.0683914, lng: 18.8731191 },
    { lat: 48.0689373, lng: 18.8732068 },
    { lat: 48.0702828, lng: 18.8728669 },
    { lat: 48.0707203, lng: 18.872847 },
    { lat: 48.0713314, lng: 18.8729751 },
    { lat: 48.0718673, lng: 18.8732141 },
    { lat: 48.0729648, lng: 18.8739351 },
    { lat: 48.0735607, lng: 18.8737268 },
    { lat: 48.0737037, lng: 18.8735504 },
    { lat: 48.0737918, lng: 18.8732281 },
    { lat: 48.0738027, lng: 18.872814 },
    { lat: 48.0736444, lng: 18.8725024 },
    { lat: 48.07264, lng: 18.8717604 },
    { lat: 48.0725968, lng: 18.8717284 },
    { lat: 48.072476, lng: 18.8715271 },
    { lat: 48.0724028, lng: 18.8712493 },
    { lat: 48.0724555, lng: 18.8709421 },
    { lat: 48.072976, lng: 18.8702214 },
    { lat: 48.073203, lng: 18.8699898 },
    { lat: 48.0734645, lng: 18.8698398 },
    { lat: 48.0738213, lng: 18.8698035 },
    { lat: 48.0741224, lng: 18.8698668 },
    { lat: 48.0749796, lng: 18.8704976 },
    { lat: 48.075222, lng: 18.8706051 },
    { lat: 48.0755719, lng: 18.8704188 },
    { lat: 48.0756586, lng: 18.8701967 },
    { lat: 48.0756624, lng: 18.8696233 },
    { lat: 48.0754738, lng: 18.8687155 },
    { lat: 48.0752276, lng: 18.8679081 },
    { lat: 48.0751766, lng: 18.8675372 },
    { lat: 48.075238, lng: 18.8671806 },
    { lat: 48.0753411, lng: 18.866933 },
    { lat: 48.0754548, lng: 18.8667978 },
    { lat: 48.0757218, lng: 18.8666634 },
    { lat: 48.075898, lng: 18.8666696 },
    { lat: 48.0761152, lng: 18.8667698 },
    { lat: 48.0773214, lng: 18.8682714 },
    { lat: 48.0775226, lng: 18.8683245 },
    { lat: 48.0781781, lng: 18.8682023 },
    { lat: 48.0794211, lng: 18.8674538 },
    { lat: 48.079638, lng: 18.8674578 },
    { lat: 48.0797221, lng: 18.8674908 },
    { lat: 48.0798612, lng: 18.8675453 },
    { lat: 48.0801972, lng: 18.8677809 },
    { lat: 48.0811258, lng: 18.8686749 },
    { lat: 48.0815667, lng: 18.8692152 },
    { lat: 48.0816724, lng: 18.869442 },
    { lat: 48.0816978, lng: 18.8697169 },
    { lat: 48.0815259, lng: 18.8701563 },
    { lat: 48.0813703, lng: 18.8702668 },
    { lat: 48.0811804, lng: 18.8703009 },
    { lat: 48.0809644, lng: 18.8702467 },
    { lat: 48.0803691, lng: 18.8698608 },
    { lat: 48.0799133, lng: 18.8699202 },
    { lat: 48.0796875, lng: 18.8700431 },
    { lat: 48.0792617, lng: 18.8704189 },
    { lat: 48.0789594, lng: 18.8708691 },
    { lat: 48.0788423, lng: 18.8711335 },
    { lat: 48.0787975, lng: 18.8716137 },
    { lat: 48.0789191, lng: 18.8724078 },
    { lat: 48.0789918, lng: 18.8736687 },
    { lat: 48.0790738, lng: 18.8741388 },
    { lat: 48.0792209, lng: 18.8744944 },
    { lat: 48.0797123, lng: 18.8751795 },
    { lat: 48.0808111, lng: 18.8762467 },
    { lat: 48.0819898, lng: 18.8766812 },
    { lat: 48.0823937, lng: 18.8769914 },
    { lat: 48.0826141, lng: 18.8773022 },
    { lat: 48.0827383, lng: 18.8772408 },
    { lat: 48.0832319, lng: 18.8773403 },
    { lat: 48.0830386, lng: 18.8783256 },
    { lat: 48.0832978, lng: 18.8784072 },
    { lat: 48.0834215, lng: 18.8785088 },
    { lat: 48.0834584, lng: 18.878763 },
    { lat: 48.0835708, lng: 18.8788836 },
    { lat: 48.0835585, lng: 18.8790374 },
    { lat: 48.0833512, lng: 18.8790414 },
    { lat: 48.0833333, lng: 18.8791955 },
    { lat: 48.0833533, lng: 18.8796428 },
    { lat: 48.0834676, lng: 18.8797818 },
    { lat: 48.0837936, lng: 18.8796117 },
    { lat: 48.0839526, lng: 18.8796531 },
    { lat: 48.0840047, lng: 18.8801119 },
    { lat: 48.0841088, lng: 18.8803586 },
    { lat: 48.0842965, lng: 18.8805414 },
    { lat: 48.0844192, lng: 18.8802351 },
    { lat: 48.0846525, lng: 18.8799907 },
    { lat: 48.0847833, lng: 18.879948 },
    { lat: 48.0848751, lng: 18.8802413 },
    { lat: 48.0849776, lng: 18.8803038 },
    { lat: 48.085177, lng: 18.8802112 },
    { lat: 48.0855104, lng: 18.8803117 },
    { lat: 48.0856228, lng: 18.8805864 },
    { lat: 48.0855778, lng: 18.8809316 },
    { lat: 48.0856037, lng: 18.8811924 },
    { lat: 48.0854189, lng: 18.8816505 },
    { lat: 48.0853931, lng: 18.8818256 },
    { lat: 48.0854844, lng: 18.881932 },
    { lat: 48.0858491, lng: 18.8819419 },
    { lat: 48.0859878, lng: 18.8820689 },
    { lat: 48.0860201, lng: 18.8822133 },
    { lat: 48.0857616, lng: 18.8828146 },
    { lat: 48.0858522, lng: 18.8834058 },
    { lat: 48.086047, lng: 18.8834445 },
    { lat: 48.0863961, lng: 18.8837799 },
    { lat: 48.0865918, lng: 18.8842428 },
    { lat: 48.0864401, lng: 18.8845559 },
    { lat: 48.085986, lng: 18.8848474 },
    { lat: 48.0861267, lng: 18.8854648 },
    { lat: 48.0861354, lng: 18.8856331 },
    { lat: 48.0859018, lng: 18.8862139 },
    { lat: 48.0858837, lng: 18.8864523 },
    { lat: 48.0860439, lng: 18.8868643 },
    { lat: 48.0860831, lng: 18.8869039 },
    { lat: 48.0861348, lng: 18.8868354 },
    { lat: 48.0862194, lng: 18.8865548 },
    { lat: 48.0863504, lng: 18.8864285 },
    { lat: 48.0864225, lng: 18.8864479 },
    { lat: 48.0867032, lng: 18.8870498 },
    { lat: 48.0870107, lng: 18.8870649 },
    { lat: 48.087093, lng: 18.8869802 },
    { lat: 48.0871306, lng: 18.8868143 },
    { lat: 48.0870699, lng: 18.8866468 },
    { lat: 48.0868777, lng: 18.8864406 },
    { lat: 48.0871001, lng: 18.8856025 },
    { lat: 48.0872294, lng: 18.8854231 },
    { lat: 48.0874104, lng: 18.8854092 },
    { lat: 48.0874665, lng: 18.8854922 },
    { lat: 48.0874857, lng: 18.8856201 },
    { lat: 48.0873943, lng: 18.8859478 },
    { lat: 48.0874261, lng: 18.8860594 },
    { lat: 48.0875607, lng: 18.8861302 },
    { lat: 48.0878262, lng: 18.8860962 },
    { lat: 48.087881, lng: 18.8861724 },
    { lat: 48.0878981, lng: 18.8864148 },
    { lat: 48.0880107, lng: 18.8865675 },
    { lat: 48.0883244, lng: 18.8865702 },
    { lat: 48.0886598, lng: 18.886449 },
    { lat: 48.0889505, lng: 18.8865948 },
    { lat: 48.0891753, lng: 18.886496 },
    { lat: 48.0896175, lng: 18.885977 },
    { lat: 48.0896337, lng: 18.8858576 },
    { lat: 48.0895893, lng: 18.8857626 },
    { lat: 48.0893096, lng: 18.8854608 },
    { lat: 48.0892834, lng: 18.8853488 },
    { lat: 48.0893379, lng: 18.8851782 },
    { lat: 48.0894627, lng: 18.8850437 },
    { lat: 48.0894697, lng: 18.8842275 },
    { lat: 48.0896525, lng: 18.87952 },
    { lat: 48.0899899, lng: 18.8759178 },
    { lat: 48.0901607, lng: 18.875365 },
    { lat: 48.09084, lng: 18.8741657 },
    { lat: 48.0912124, lng: 18.8736088 },
    { lat: 48.0931306, lng: 18.8717362 },
    { lat: 48.09348, lng: 18.8718599 },
    { lat: 48.0940992, lng: 18.8705017 },
    { lat: 48.0944365, lng: 18.8696222 },
    { lat: 48.0947669, lng: 18.8691269 },
    { lat: 48.0954, lng: 18.8675586 },
    { lat: 48.0971452, lng: 18.8635871 },
    { lat: 48.0981226, lng: 18.8629559 },
    { lat: 48.0987753, lng: 18.8624558 },
    { lat: 48.099327, lng: 18.8615564 },
    { lat: 48.0997665, lng: 18.859525 },
    { lat: 48.1000273, lng: 18.858825 },
    { lat: 48.1002319, lng: 18.857584 },
    { lat: 48.099608, lng: 18.8545874 },
    { lat: 48.1007568, lng: 18.8534633 },
    { lat: 48.1017232, lng: 18.8514393 },
    { lat: 48.1020915, lng: 18.8477555 },
    { lat: 48.1017909, lng: 18.8450364 },
    { lat: 48.1020366, lng: 18.8443921 },
    { lat: 48.1017919, lng: 18.8429312 },
    { lat: 48.101731, lng: 18.8403728 },
    { lat: 48.101749, lng: 18.8387006 },
    { lat: 48.1015526, lng: 18.8375813 },
    { lat: 48.1017126, lng: 18.8362505 },
    { lat: 48.1016586, lng: 18.8354341 },
    { lat: 48.1007376, lng: 18.8346058 },
    { lat: 48.100915, lng: 18.8339263 },
    { lat: 48.1009926, lng: 18.832486 },
    { lat: 48.1023103, lng: 18.8310801 },
    { lat: 48.1016854, lng: 18.8303695 },
    { lat: 48.1017956, lng: 18.8298083 },
    { lat: 48.1011036, lng: 18.8285725 },
    { lat: 48.100913, lng: 18.8284224 },
    { lat: 48.1004713, lng: 18.8274437 },
    { lat: 48.0992762, lng: 18.8261629 },
    { lat: 48.0987557, lng: 18.8251689 },
    { lat: 48.0978972, lng: 18.8261904 },
    { lat: 48.0970304, lng: 18.8267331 },
    { lat: 48.0959842, lng: 18.8268675 },
    { lat: 48.0947385, lng: 18.8268518 },
    { lat: 48.0941983, lng: 18.8271071 },
    { lat: 48.0927314, lng: 18.8272217 },
    { lat: 48.0922275, lng: 18.8267976 },
    { lat: 48.0908214, lng: 18.8286807 },
    { lat: 48.0892827, lng: 18.8298731 },
    { lat: 48.0862642, lng: 18.8311642 },
    { lat: 48.0831451, lng: 18.8320878 },
    { lat: 48.0820087, lng: 18.832585 },
    { lat: 48.0810166, lng: 18.8328723 },
    { lat: 48.0791731, lng: 18.8326422 },
    { lat: 48.0786062, lng: 18.8326907 },
    { lat: 48.0751964, lng: 18.8321354 },
    { lat: 48.0741823, lng: 18.831811 },
    { lat: 48.071427, lng: 18.830627 },
    { lat: 48.0678171, lng: 18.8296163 },
    { lat: 48.0629069, lng: 18.8275169 },
    { lat: 48.0628776, lng: 18.8258448 },
    { lat: 48.062918, lng: 18.8256701 },
    { lat: 48.062776, lng: 18.8224886 },
    { lat: 48.0625466, lng: 18.8223717 },
    { lat: 48.0620069, lng: 18.8213507 },
  ],
  HontianskeTrsťany: [
    { lat: 48.201529, lng: 18.824127 },
    { lat: 48.201839, lng: 18.824463 },
    { lat: 48.202206, lng: 18.824951 },
    { lat: 48.2024, lng: 18.825208 },
    { lat: 48.202747, lng: 18.825757 },
    { lat: 48.203067, lng: 18.826265 },
    { lat: 48.203481, lng: 18.827056 },
    { lat: 48.203703, lng: 18.827418 },
    { lat: 48.204162, lng: 18.828185 },
    { lat: 48.204336, lng: 18.828429 },
    { lat: 48.20438, lng: 18.828554 },
    { lat: 48.204366, lng: 18.828569 },
    { lat: 48.204367, lng: 18.828571 },
    { lat: 48.204436, lng: 18.828665 },
    { lat: 48.204732, lng: 18.829064 },
    { lat: 48.205787, lng: 18.830488 },
    { lat: 48.206271, lng: 18.831632 },
    { lat: 48.206299, lng: 18.831701 },
    { lat: 48.206479, lng: 18.831385 },
    { lat: 48.206851, lng: 18.831189 },
    { lat: 48.2070563, lng: 18.8306008 },
    { lat: 48.2071498, lng: 18.830333 },
    { lat: 48.207183, lng: 18.830238 },
    { lat: 48.207406, lng: 18.830016 },
    { lat: 48.207854, lng: 18.829755 },
    { lat: 48.207857, lng: 18.829626 },
    { lat: 48.207757, lng: 18.829316 },
    { lat: 48.208277, lng: 18.829255 },
    { lat: 48.208394, lng: 18.829155 },
    { lat: 48.208209, lng: 18.82864 },
    { lat: 48.208217, lng: 18.828564 },
    { lat: 48.20849, lng: 18.828573 },
    { lat: 48.208665, lng: 18.828884 },
    { lat: 48.208933, lng: 18.828549 },
    { lat: 48.209019, lng: 18.828125 },
    { lat: 48.209358, lng: 18.82826 },
    { lat: 48.209554, lng: 18.828239 },
    { lat: 48.209694, lng: 18.828057 },
    { lat: 48.209732, lng: 18.827692 },
    { lat: 48.20984, lng: 18.827494 },
    { lat: 48.210539, lng: 18.827211 },
    { lat: 48.210581, lng: 18.826941 },
    { lat: 48.210655, lng: 18.826733 },
    { lat: 48.21077, lng: 18.826642 },
    { lat: 48.210951, lng: 18.826589 },
    { lat: 48.211169, lng: 18.826074 },
    { lat: 48.211438, lng: 18.82569 },
    { lat: 48.211446, lng: 18.825571 },
    { lat: 48.211611, lng: 18.825444 },
    { lat: 48.211761, lng: 18.825436 },
    { lat: 48.211931, lng: 18.825323 },
    { lat: 48.211841, lng: 18.82512 },
    { lat: 48.211842, lng: 18.824916 },
    { lat: 48.212232, lng: 18.824309 },
    { lat: 48.21225, lng: 18.824185 },
    { lat: 48.212299, lng: 18.824143 },
    { lat: 48.212508, lng: 18.824408 },
    { lat: 48.212733, lng: 18.824075 },
    { lat: 48.212867, lng: 18.823591 },
    { lat: 48.212973, lng: 18.823472 },
    { lat: 48.213234, lng: 18.823495 },
    { lat: 48.213562, lng: 18.823629 },
    { lat: 48.213874, lng: 18.823204 },
    { lat: 48.213797, lng: 18.822611 },
    { lat: 48.214021, lng: 18.822519 },
    { lat: 48.2145, lng: 18.822549 },
    { lat: 48.214556, lng: 18.822315 },
    { lat: 48.214477, lng: 18.821868 },
    { lat: 48.2146, lng: 18.821773 },
    { lat: 48.214792, lng: 18.821839 },
    { lat: 48.214968, lng: 18.821658 },
    { lat: 48.2153, lng: 18.821423 },
    { lat: 48.215359, lng: 18.821381 },
    { lat: 48.215472, lng: 18.821134 },
    { lat: 48.21562, lng: 18.821154 },
    { lat: 48.215709, lng: 18.820944 },
    { lat: 48.215935, lng: 18.820724 },
    { lat: 48.215936, lng: 18.820499 },
    { lat: 48.215987, lng: 18.82045 },
    { lat: 48.216291, lng: 18.820497 },
    { lat: 48.216368, lng: 18.820232 },
    { lat: 48.216674, lng: 18.820138 },
    { lat: 48.2171, lng: 18.819803 },
    { lat: 48.217399, lng: 18.819723 },
    { lat: 48.217718, lng: 18.819609 },
    { lat: 48.218323, lng: 18.81932 },
    { lat: 48.218624, lng: 18.819166 },
    { lat: 48.218788, lng: 18.818783 },
    { lat: 48.218942, lng: 18.818744 },
    { lat: 48.219334, lng: 18.818217 },
    { lat: 48.219342, lng: 18.818002 },
    { lat: 48.21945, lng: 18.817764 },
    { lat: 48.219458, lng: 18.817527 },
    { lat: 48.219553, lng: 18.817328 },
    { lat: 48.219597, lng: 18.817072 },
    { lat: 48.219707, lng: 18.816957 },
    { lat: 48.219738, lng: 18.81677 },
    { lat: 48.219897, lng: 18.81653 },
    { lat: 48.220034, lng: 18.816424 },
    { lat: 48.220108, lng: 18.816319 },
    { lat: 48.220183, lng: 18.816278 },
    { lat: 48.220411, lng: 18.816522 },
    { lat: 48.220499, lng: 18.816231 },
    { lat: 48.221527, lng: 18.816133 },
    { lat: 48.221724, lng: 18.816108 },
    { lat: 48.222093, lng: 18.8158 },
    { lat: 48.222459, lng: 18.815652 },
    { lat: 48.22266, lng: 18.815634 },
    { lat: 48.223124, lng: 18.815397 },
    { lat: 48.223308, lng: 18.815317 },
    { lat: 48.223907, lng: 18.815007 },
    { lat: 48.224149, lng: 18.814905 },
    { lat: 48.224722, lng: 18.814535 },
    { lat: 48.224733, lng: 18.814569 },
    { lat: 48.224756, lng: 18.81464 },
    { lat: 48.22587, lng: 18.813714 },
    { lat: 48.227013, lng: 18.812173 },
    { lat: 48.227451, lng: 18.811356 },
    { lat: 48.228206, lng: 18.812083 },
    { lat: 48.228249, lng: 18.812049 },
    { lat: 48.22924, lng: 18.810188 },
    { lat: 48.229912, lng: 18.808755 },
    { lat: 48.231635, lng: 18.810671 },
    { lat: 48.231667, lng: 18.810728 },
    { lat: 48.231818, lng: 18.810904 },
    { lat: 48.231616, lng: 18.811495 },
    { lat: 48.232121, lng: 18.81216 },
    { lat: 48.232221, lng: 18.812199 },
    { lat: 48.232292, lng: 18.812375 },
    { lat: 48.232455, lng: 18.812584 },
    { lat: 48.232624, lng: 18.812801 },
    { lat: 48.233063, lng: 18.813683 },
    { lat: 48.233097, lng: 18.813751 },
    { lat: 48.233056, lng: 18.814152 },
    { lat: 48.233016, lng: 18.814623 },
    { lat: 48.23293, lng: 18.814763 },
    { lat: 48.232947, lng: 18.815138 },
    { lat: 48.232951, lng: 18.815239 },
    { lat: 48.233004, lng: 18.81536 },
    { lat: 48.232967, lng: 18.81567 },
    { lat: 48.232867, lng: 18.81591 },
    { lat: 48.23292, lng: 18.816203 },
    { lat: 48.232847, lng: 18.816324 },
    { lat: 48.232881, lng: 18.816455 },
    { lat: 48.232871, lng: 18.816907 },
    { lat: 48.232836, lng: 18.817356 },
    { lat: 48.232733, lng: 18.817773 },
    { lat: 48.232773, lng: 18.818132 },
    { lat: 48.232729, lng: 18.818267 },
    { lat: 48.232745, lng: 18.818597 },
    { lat: 48.232798, lng: 18.818916 },
    { lat: 48.232755, lng: 18.81912 },
    { lat: 48.232815, lng: 18.819618 },
    { lat: 48.232957, lng: 18.819963 },
    { lat: 48.232945, lng: 18.820115 },
    { lat: 48.233122, lng: 18.820576 },
    { lat: 48.23304, lng: 18.820796 },
    { lat: 48.233044, lng: 18.821243 },
    { lat: 48.233155, lng: 18.821492 },
    { lat: 48.233469, lng: 18.821406 },
    { lat: 48.233583, lng: 18.821296 },
    { lat: 48.233676, lng: 18.820804 },
    { lat: 48.233833, lng: 18.820823 },
    { lat: 48.234227, lng: 18.821077 },
    { lat: 48.234353, lng: 18.821092 },
    { lat: 48.234454, lng: 18.820853 },
    { lat: 48.234569, lng: 18.820867 },
    { lat: 48.2347, lng: 18.821157 },
    { lat: 48.234986, lng: 18.820957 },
    { lat: 48.235183, lng: 18.820624 },
    { lat: 48.235291, lng: 18.820602 },
    { lat: 48.235439, lng: 18.820913 },
    { lat: 48.235564, lng: 18.820921 },
    { lat: 48.235666, lng: 18.820813 },
    { lat: 48.235724, lng: 18.820626 },
    { lat: 48.235885, lng: 18.820431 },
    { lat: 48.23621, lng: 18.820294 },
    { lat: 48.23636, lng: 18.819985 },
    { lat: 48.236473, lng: 18.819832 },
    { lat: 48.236648, lng: 18.819792 },
    { lat: 48.23671, lng: 18.819664 },
    { lat: 48.236709, lng: 18.819444 },
    { lat: 48.236823, lng: 18.819215 },
    { lat: 48.237065, lng: 18.818971 },
    { lat: 48.237201, lng: 18.818936 },
    { lat: 48.237344, lng: 18.818812 },
    { lat: 48.237447, lng: 18.81857 },
    { lat: 48.237606, lng: 18.818383 },
    { lat: 48.237825, lng: 18.818156 },
    { lat: 48.237963, lng: 18.817926 },
    { lat: 48.238201, lng: 18.817789 },
    { lat: 48.238268, lng: 18.817348 },
    { lat: 48.238391, lng: 18.817189 },
    { lat: 48.238626, lng: 18.817267 },
    { lat: 48.238777, lng: 18.817124 },
    { lat: 48.238792, lng: 18.816877 },
    { lat: 48.238867, lng: 18.816562 },
    { lat: 48.238881, lng: 18.816254 },
    { lat: 48.238769, lng: 18.815468 },
    { lat: 48.238968, lng: 18.815286 },
    { lat: 48.238979, lng: 18.815108 },
    { lat: 48.239074, lng: 18.814797 },
    { lat: 48.238792, lng: 18.814583 },
    { lat: 48.238833, lng: 18.814289 },
    { lat: 48.23895, lng: 18.81423 },
    { lat: 48.239089, lng: 18.813989 },
    { lat: 48.239073, lng: 18.813552 },
    { lat: 48.239117, lng: 18.813302 },
    { lat: 48.239294, lng: 18.813232 },
    { lat: 48.2395, lng: 18.812825 },
    { lat: 48.239778, lng: 18.812423 },
    { lat: 48.23986, lng: 18.812177 },
    { lat: 48.24013, lng: 18.811679 },
    { lat: 48.240239, lng: 18.811159 },
    { lat: 48.240071, lng: 18.810388 },
    { lat: 48.240546, lng: 18.809729 },
    { lat: 48.240775, lng: 18.809275 },
    { lat: 48.240902, lng: 18.809236 },
    { lat: 48.241243, lng: 18.809133 },
    { lat: 48.241487, lng: 18.80876 },
    { lat: 48.241811, lng: 18.808653 },
    { lat: 48.241944, lng: 18.808621 },
    { lat: 48.242002, lng: 18.808588 },
    { lat: 48.242549, lng: 18.808275 },
    { lat: 48.242586, lng: 18.808254 },
    { lat: 48.243008, lng: 18.808012 },
    { lat: 48.243176, lng: 18.807982 },
    { lat: 48.243326, lng: 18.808066 },
    { lat: 48.243531, lng: 18.808027 },
    { lat: 48.243675, lng: 18.807827 },
    { lat: 48.243779, lng: 18.80778 },
    { lat: 48.244015, lng: 18.807849 },
    { lat: 48.244195, lng: 18.807117 },
    { lat: 48.244487, lng: 18.806849 },
    { lat: 48.244726, lng: 18.806223 },
    { lat: 48.24505, lng: 18.805752 },
    { lat: 48.245284, lng: 18.805666 },
    { lat: 48.2458206, lng: 18.8052396 },
    { lat: 48.2460303, lng: 18.8048741 },
    { lat: 48.2459342, lng: 18.8045479 },
    { lat: 48.2464806, lng: 18.8032542 },
    { lat: 48.2467339, lng: 18.8029253 },
    { lat: 48.2465588, lng: 18.8024687 },
    { lat: 48.2463217, lng: 18.8020605 },
    { lat: 48.2457986, lng: 18.8013535 },
    { lat: 48.2455797, lng: 18.8013515 },
    { lat: 48.2454674, lng: 18.8011489 },
    { lat: 48.24302, lng: 18.798003 },
    { lat: 48.2426509, lng: 18.7973547 },
    { lat: 48.2420626, lng: 18.7965915 },
    { lat: 48.2419799, lng: 18.7963497 },
    { lat: 48.2410979, lng: 18.7955566 },
    { lat: 48.2408502, lng: 18.7950089 },
    { lat: 48.2403983, lng: 18.7944846 },
    { lat: 48.2404481, lng: 18.793866 },
    { lat: 48.2404132, lng: 18.793464 },
    { lat: 48.2403003, lng: 18.7929903 },
    { lat: 48.2401225, lng: 18.7926144 },
    { lat: 48.2400826, lng: 18.7923686 },
    { lat: 48.2396845, lng: 18.7921551 },
    { lat: 48.2395654, lng: 18.7923344 },
    { lat: 48.2394518, lng: 18.7921918 },
    { lat: 48.2392623, lng: 18.7923179 },
    { lat: 48.2390202, lng: 18.7920473 },
    { lat: 48.2387153, lng: 18.7919118 },
    { lat: 48.2384129, lng: 18.7910155 },
    { lat: 48.2382609, lng: 18.7907186 },
    { lat: 48.2382969, lng: 18.7903312 },
    { lat: 48.2382176, lng: 18.7902391 },
    { lat: 48.2382436, lng: 18.7898366 },
    { lat: 48.2380596, lng: 18.7895351 },
    { lat: 48.2377229, lng: 18.7883068 },
    { lat: 48.2375802, lng: 18.7883796 },
    { lat: 48.2374315, lng: 18.7882125 },
    { lat: 48.2371501, lng: 18.7882678 },
    { lat: 48.2369646, lng: 18.7878515 },
    { lat: 48.2369304, lng: 18.7878216 },
    { lat: 48.236821, lng: 18.7879067 },
    { lat: 48.2367567, lng: 18.7878443 },
    { lat: 48.2368548, lng: 18.7876073 },
    { lat: 48.2368154, lng: 18.7874167 },
    { lat: 48.2365155, lng: 18.7874834 },
    { lat: 48.2365395, lng: 18.7871689 },
    { lat: 48.2364061, lng: 18.7870135 },
    { lat: 48.2362452, lng: 18.7865507 },
    { lat: 48.2359404, lng: 18.785948 },
    { lat: 48.2356565, lng: 18.785762 },
    { lat: 48.2354156, lng: 18.785747 },
    { lat: 48.2350813, lng: 18.7858263 },
    { lat: 48.2347914, lng: 18.7856307 },
    { lat: 48.2347619, lng: 18.7853314 },
    { lat: 48.2339259, lng: 18.7844708 },
    { lat: 48.2334346, lng: 18.7842439 },
    { lat: 48.2328465, lng: 18.7832755 },
    { lat: 48.2326779, lng: 18.7832435 },
    { lat: 48.2322361, lng: 18.7829895 },
    { lat: 48.232152, lng: 18.7827848 },
    { lat: 48.2323117, lng: 18.7820973 },
    { lat: 48.2322807, lng: 18.7819181 },
    { lat: 48.232212, lng: 18.7818343 },
    { lat: 48.2320716, lng: 18.7818195 },
    { lat: 48.2320802, lng: 18.781451 },
    { lat: 48.2316041, lng: 18.7808329 },
    { lat: 48.231436, lng: 18.7802773 },
    { lat: 48.2311754, lng: 18.7796988 },
    { lat: 48.230981, lng: 18.7797307 },
    { lat: 48.2306313, lng: 18.7787939 },
    { lat: 48.2307415, lng: 18.7787245 },
    { lat: 48.2309779, lng: 18.7782894 },
    { lat: 48.2310828, lng: 18.7779717 },
    { lat: 48.2308384, lng: 18.7776579 },
    { lat: 48.2308573, lng: 18.7770778 },
    { lat: 48.2307258, lng: 18.7762996 },
    { lat: 48.2304617, lng: 18.7759766 },
    { lat: 48.2302308, lng: 18.775298 },
    { lat: 48.2299732, lng: 18.7751425 },
    { lat: 48.2296185, lng: 18.7743665 },
    { lat: 48.2296158, lng: 18.7742713 },
    { lat: 48.230152, lng: 18.7734723 },
    { lat: 48.2304591, lng: 18.7728187 },
    { lat: 48.2305101, lng: 18.7725685 },
    { lat: 48.2302918, lng: 18.7724088 },
    { lat: 48.2298859, lng: 18.7723038 },
    { lat: 48.2289255, lng: 18.771332 },
    { lat: 48.2282893, lng: 18.7705662 },
    { lat: 48.227151, lng: 18.7690465 },
    { lat: 48.2268413, lng: 18.7684838 },
    { lat: 48.2266663, lng: 18.768031 },
    { lat: 48.2266583, lng: 18.7679029 },
    { lat: 48.2264375, lng: 18.7671843 },
    { lat: 48.2262482, lng: 18.7669758 },
    { lat: 48.2260576, lng: 18.7666345 },
    { lat: 48.2258837, lng: 18.7665233 },
    { lat: 48.2252582, lng: 18.7664052 },
    { lat: 48.2251955, lng: 18.7664778 },
    { lat: 48.2245723, lng: 18.7671859 },
    { lat: 48.2243847, lng: 18.7673295 },
    { lat: 48.2236914, lng: 18.7676294 },
    { lat: 48.2234067, lng: 18.7676622 },
    { lat: 48.2214007, lng: 18.7674546 },
    { lat: 48.2170983, lng: 18.7668197 },
    { lat: 48.2166883, lng: 18.7677422 },
    { lat: 48.2105444, lng: 18.7814476 },
    { lat: 48.211247, lng: 18.7826177 },
    { lat: 48.2095348, lng: 18.78756 },
    { lat: 48.208474, lng: 18.7889319 },
    { lat: 48.207937, lng: 18.7893101 },
    { lat: 48.2077346, lng: 18.7893202 },
    { lat: 48.2074692, lng: 18.7895079 },
    { lat: 48.2065373, lng: 18.7909916 },
    { lat: 48.2038796, lng: 18.7952224 },
    { lat: 48.2024968, lng: 18.7941337 },
    { lat: 48.2018017, lng: 18.7932286 },
    { lat: 48.2009683, lng: 18.7951721 },
    { lat: 48.2001433, lng: 18.7942406 },
    { lat: 48.199298, lng: 18.7930956 },
    { lat: 48.1978193, lng: 18.7922599 },
    { lat: 48.1958603, lng: 18.7918087 },
    { lat: 48.1948661, lng: 18.7910081 },
    { lat: 48.1936757, lng: 18.7902303 },
    { lat: 48.1916846, lng: 18.7884057 },
    { lat: 48.1912362, lng: 18.7896205 },
    { lat: 48.1903428, lng: 18.7914465 },
    { lat: 48.190067, lng: 18.7918313 },
    { lat: 48.1895453, lng: 18.7927243 },
    { lat: 48.1893794, lng: 18.7929679 },
    { lat: 48.1891756, lng: 18.793508 },
    { lat: 48.1881559, lng: 18.7959758 },
    { lat: 48.187383, lng: 18.798402 },
    { lat: 48.187371, lng: 18.798418 },
    { lat: 48.187187, lng: 18.799813 },
    { lat: 48.187929, lng: 18.800379 },
    { lat: 48.187722, lng: 18.800893 },
    { lat: 48.188626, lng: 18.801593 },
    { lat: 48.189284, lng: 18.802045 },
    { lat: 48.189801, lng: 18.802661 },
    { lat: 48.1909, lng: 18.803397 },
    { lat: 48.191569, lng: 18.804158 },
    { lat: 48.192065, lng: 18.804722 },
    { lat: 48.193545, lng: 18.805854 },
    { lat: 48.19359, lng: 18.805889 },
    { lat: 48.193688, lng: 18.805964 },
    { lat: 48.193854, lng: 18.806093 },
    { lat: 48.194869, lng: 18.806866 },
    { lat: 48.196071, lng: 18.807442 },
    { lat: 48.196066, lng: 18.807462 },
    { lat: 48.195529, lng: 18.809828 },
    { lat: 48.196532, lng: 18.810588 },
    { lat: 48.195878, lng: 18.812832 },
    { lat: 48.195998, lng: 18.812942 },
    { lat: 48.196077, lng: 18.813016 },
    { lat: 48.196562, lng: 18.813792 },
    { lat: 48.196701, lng: 18.814014 },
    { lat: 48.196709, lng: 18.814028 },
    { lat: 48.196636, lng: 18.814123 },
    { lat: 48.196501, lng: 18.814515 },
    { lat: 48.19643, lng: 18.814698 },
    { lat: 48.196069, lng: 18.815884 },
    { lat: 48.196008, lng: 18.815985 },
    { lat: 48.195807, lng: 18.816695 },
    { lat: 48.195678, lng: 18.817043 },
    { lat: 48.195509, lng: 18.817355 },
    { lat: 48.195388, lng: 18.817446 },
    { lat: 48.195375, lng: 18.817813 },
    { lat: 48.195425, lng: 18.817837 },
    { lat: 48.195498, lng: 18.817874 },
    { lat: 48.195527, lng: 18.817889 },
    { lat: 48.195568, lng: 18.81791 },
    { lat: 48.195603, lng: 18.817928 },
    { lat: 48.196758, lng: 18.818479 },
    { lat: 48.196892, lng: 18.818553 },
    { lat: 48.197094, lng: 18.818621 },
    { lat: 48.198558, lng: 18.81883 },
    { lat: 48.198937, lng: 18.818909 },
    { lat: 48.19936, lng: 18.819099 },
    { lat: 48.200811, lng: 18.819782 },
    { lat: 48.202599, lng: 18.820268 },
    { lat: 48.201995, lng: 18.822453 },
    { lat: 48.201899, lng: 18.822796 },
    { lat: 48.201554, lng: 18.824038 },
    { lat: 48.201537, lng: 18.824099 },
    { lat: 48.201529, lng: 18.824127 },
  ],
  Čajkov: [
    { lat: 48.345307, lng: 18.652774 },
    { lat: 48.345503, lng: 18.652789 },
    { lat: 48.345584, lng: 18.652711 },
    { lat: 48.345627, lng: 18.652568 },
    { lat: 48.345715, lng: 18.65251 },
    { lat: 48.345799, lng: 18.652571 },
    { lat: 48.345909, lng: 18.652574 },
    { lat: 48.346087, lng: 18.65284 },
    { lat: 48.346216, lng: 18.652771 },
    { lat: 48.346374, lng: 18.652957 },
    { lat: 48.346487, lng: 18.652923 },
    { lat: 48.346612, lng: 18.653121 },
    { lat: 48.346753, lng: 18.653104 },
    { lat: 48.34688, lng: 18.653239 },
    { lat: 48.347056, lng: 18.653034 },
    { lat: 48.347316, lng: 18.653164 },
    { lat: 48.347459, lng: 18.65319 },
    { lat: 48.347676, lng: 18.653032 },
    { lat: 48.347839, lng: 18.652959 },
    { lat: 48.348074, lng: 18.652907 },
    { lat: 48.348205, lng: 18.653056 },
    { lat: 48.348386, lng: 18.653038 },
    { lat: 48.348713, lng: 18.652874 },
    { lat: 48.348797, lng: 18.652761 },
    { lat: 48.349005, lng: 18.652791 },
    { lat: 48.349095, lng: 18.652704 },
    { lat: 48.349295, lng: 18.652921 },
    { lat: 48.349404, lng: 18.652997 },
    { lat: 48.349646, lng: 18.653007 },
    { lat: 48.349731, lng: 18.652983 },
    { lat: 48.349828, lng: 18.653045 },
    { lat: 48.349918, lng: 18.652991 },
    { lat: 48.350061, lng: 18.652853 },
    { lat: 48.350143, lng: 18.652878 },
    { lat: 48.350209, lng: 18.652779 },
    { lat: 48.350398, lng: 18.65277 },
    { lat: 48.350472, lng: 18.652702 },
    { lat: 48.350571, lng: 18.652709 },
    { lat: 48.350659, lng: 18.652672 },
    { lat: 48.350754, lng: 18.652535 },
    { lat: 48.350884, lng: 18.65247 },
    { lat: 48.351019, lng: 18.652486 },
    { lat: 48.351476, lng: 18.646224 },
    { lat: 48.352711, lng: 18.642951 },
    { lat: 48.352936, lng: 18.640512 },
    { lat: 48.352673, lng: 18.63911 },
    { lat: 48.353504, lng: 18.637363 },
    { lat: 48.353825, lng: 18.636293 },
    { lat: 48.353886, lng: 18.634946 },
    { lat: 48.353822, lng: 18.633187 },
    { lat: 48.353586, lng: 18.632709 },
    { lat: 48.35334, lng: 18.63284 },
    { lat: 48.351597, lng: 18.632018 },
    { lat: 48.349767, lng: 18.630695 },
    { lat: 48.348461, lng: 18.628704 },
    { lat: 48.347681, lng: 18.626551 },
    { lat: 48.34756, lng: 18.625414 },
    { lat: 48.347891, lng: 18.62432 },
    { lat: 48.348631, lng: 18.623582 },
    { lat: 48.348683, lng: 18.622986 },
    { lat: 48.348141, lng: 18.621209 },
    { lat: 48.347538, lng: 18.617554 },
    { lat: 48.346865, lng: 18.617232 },
    { lat: 48.345669, lng: 18.616522 },
    { lat: 48.345494, lng: 18.61641 },
    { lat: 48.344587, lng: 18.614559 },
    { lat: 48.344245, lng: 18.613742 },
    { lat: 48.343784, lng: 18.612639 },
    { lat: 48.343788, lng: 18.612465 },
    { lat: 48.343795, lng: 18.612215 },
    { lat: 48.343846, lng: 18.610535 },
    { lat: 48.344146, lng: 18.607969 },
    { lat: 48.344909, lng: 18.60589 },
    { lat: 48.344924, lng: 18.605851 },
    { lat: 48.3442935, lng: 18.6065399 },
    { lat: 48.3437443, lng: 18.6068304 },
    { lat: 48.342934, lng: 18.6075893 },
    { lat: 48.3421058, lng: 18.607982 },
    { lat: 48.3417553, lng: 18.6080068 },
    { lat: 48.3406229, lng: 18.607778 },
    { lat: 48.3399771, lng: 18.607729 },
    { lat: 48.3389516, lng: 18.6071216 },
    { lat: 48.3384987, lng: 18.6067164 },
    { lat: 48.3376649, lng: 18.6065266 },
    { lat: 48.3372464, lng: 18.6063587 },
    { lat: 48.3369851, lng: 18.6063265 },
    { lat: 48.336452, lng: 18.6064155 },
    { lat: 48.3349355, lng: 18.6062794 },
    { lat: 48.3336126, lng: 18.6062856 },
    { lat: 48.3330796, lng: 18.6061505 },
    { lat: 48.3325251, lng: 18.6061761 },
    { lat: 48.3320578, lng: 18.606111 },
    { lat: 48.3317133, lng: 18.6062481 },
    { lat: 48.3306719, lng: 18.6062833 },
    { lat: 48.3304392, lng: 18.606171 },
    { lat: 48.3297921, lng: 18.605638 },
    { lat: 48.3292242, lng: 18.6053576 },
    { lat: 48.3286839, lng: 18.604797 },
    { lat: 48.3282704, lng: 18.6041436 },
    { lat: 48.3281198, lng: 18.60349 },
    { lat: 48.3276209, lng: 18.6024884 },
    { lat: 48.3271574, lng: 18.6004387 },
    { lat: 48.3264521, lng: 18.5991261 },
    { lat: 48.3255478, lng: 18.598781 },
    { lat: 48.3251138, lng: 18.5985371 },
    { lat: 48.3250101, lng: 18.5983506 },
    { lat: 48.324835, lng: 18.5979138 },
    { lat: 48.3247258, lng: 18.5975217 },
    { lat: 48.3246587, lng: 18.5969713 },
    { lat: 48.324421, lng: 18.5962739 },
    { lat: 48.3243501, lng: 18.5953454 },
    { lat: 48.3233145, lng: 18.5936442 },
    { lat: 48.3226309, lng: 18.592754 },
    { lat: 48.3224564, lng: 18.5923678 },
    { lat: 48.3218664, lng: 18.5900904 },
    { lat: 48.3216024, lng: 18.5894274 },
    { lat: 48.3212916, lng: 18.5889428 },
    { lat: 48.3211278, lng: 18.588231 },
    { lat: 48.3200647, lng: 18.5866075 },
    { lat: 48.31937, lng: 18.5850603 },
    { lat: 48.3190583, lng: 18.5849108 },
    { lat: 48.3188298, lng: 18.5844231 },
    { lat: 48.3187137, lng: 18.5834958 },
    { lat: 48.3185012, lng: 18.583114 },
    { lat: 48.318426, lng: 18.5823988 },
    { lat: 48.3184837, lng: 18.5818623 },
    { lat: 48.3183936, lng: 18.5815757 },
    { lat: 48.3179939, lng: 18.5810953 },
    { lat: 48.3172553, lng: 18.5806462 },
    { lat: 48.3169408, lng: 18.5802362 },
    { lat: 48.3167478, lng: 18.5796762 },
    { lat: 48.3163678, lng: 18.5790166 },
    { lat: 48.3162662, lng: 18.5786885 },
    { lat: 48.3162125, lng: 18.5784453 },
    { lat: 48.316224, lng: 18.5775005 },
    { lat: 48.3160631, lng: 18.5769526 },
    { lat: 48.3160594, lng: 18.5767369 },
    { lat: 48.3156825, lng: 18.5765266 },
    { lat: 48.3150187, lng: 18.5766854 },
    { lat: 48.3148726, lng: 18.5765907 },
    { lat: 48.3146057, lng: 18.5765586 },
    { lat: 48.3143901, lng: 18.5764114 },
    { lat: 48.3138466, lng: 18.576436 },
    { lat: 48.3137078, lng: 18.5765179 },
    { lat: 48.3133765, lng: 18.5768123 },
    { lat: 48.3133135, lng: 18.5770408 },
    { lat: 48.3128651, lng: 18.5774853 },
    { lat: 48.3122333, lng: 18.5785753 },
    { lat: 48.3116986, lng: 18.5792766 },
    { lat: 48.3111336, lng: 18.5797943 },
    { lat: 48.3106343, lng: 18.5804841 },
    { lat: 48.3102686, lng: 18.5808727 },
    { lat: 48.3102071, lng: 18.5810667 },
    { lat: 48.3099066, lng: 18.581534 },
    { lat: 48.3097587, lng: 18.5815947 },
    { lat: 48.3094456, lng: 18.5819556 },
    { lat: 48.3092958, lng: 18.5820009 },
    { lat: 48.3091915, lng: 18.5821164 },
    { lat: 48.3087578, lng: 18.5822076 },
    { lat: 48.3086021, lng: 18.5823514 },
    { lat: 48.3080479, lng: 18.5823886 },
    { lat: 48.307381, lng: 18.5825891 },
    { lat: 48.3069727, lng: 18.5824224 },
    { lat: 48.3066204, lng: 18.5824376 },
    { lat: 48.3065052, lng: 18.5823717 },
    { lat: 48.3064543, lng: 18.5823701 },
    { lat: 48.3063763, lng: 18.5825522 },
    { lat: 48.3063002, lng: 18.5825835 },
    { lat: 48.3060397, lng: 18.582483 },
    { lat: 48.3058455, lng: 18.5827052 },
    { lat: 48.3055307, lng: 18.5827885 },
    { lat: 48.3053524, lng: 18.5830548 },
    { lat: 48.3049356, lng: 18.5831665 },
    { lat: 48.3047418, lng: 18.5834603 },
    { lat: 48.3045555, lng: 18.5834566 },
    { lat: 48.3043515, lng: 18.5835634 },
    { lat: 48.3041642, lng: 18.5835226 },
    { lat: 48.3040757, lng: 18.5836979 },
    { lat: 48.3039231, lng: 18.5838172 },
    { lat: 48.3038483, lng: 18.5840142 },
    { lat: 48.303557, lng: 18.5841551 },
    { lat: 48.3033965, lng: 18.5840476 },
    { lat: 48.303141, lng: 18.5840354 },
    { lat: 48.3028371, lng: 18.5841917 },
    { lat: 48.302767, lng: 18.5844579 },
    { lat: 48.3025798, lng: 18.5845832 },
    { lat: 48.3025696, lng: 18.5849557 },
    { lat: 48.3022639, lng: 18.5855512 },
    { lat: 48.3022082, lng: 18.5860416 },
    { lat: 48.3021434, lng: 18.586175 },
    { lat: 48.301729, lng: 18.5863526 },
    { lat: 48.3009527, lng: 18.5862862 },
    { lat: 48.2981762, lng: 18.5877861 },
    { lat: 48.2979648, lng: 18.5876728 },
    { lat: 48.2978411, lng: 18.587372 },
    { lat: 48.2974029, lng: 18.5872773 },
    { lat: 48.2970077, lng: 18.5870866 },
    { lat: 48.2967975, lng: 18.5868293 },
    { lat: 48.2962693, lng: 18.5865756 },
    { lat: 48.2962026, lng: 18.5855998 },
    { lat: 48.2958751, lng: 18.5842124 },
    { lat: 48.2958713, lng: 18.5838745 },
    { lat: 48.2957793, lng: 18.5838398 },
    { lat: 48.2955657, lng: 18.5839354 },
    { lat: 48.2954653, lng: 18.5838326 },
    { lat: 48.2952372, lng: 18.5839934 },
    { lat: 48.295095, lng: 18.5839733 },
    { lat: 48.2949331, lng: 18.5839389 },
    { lat: 48.2948554, lng: 18.5834901 },
    { lat: 48.2943584, lng: 18.5831583 },
    { lat: 48.2929308, lng: 18.5829802 },
    { lat: 48.2920735, lng: 18.5829448 },
    { lat: 48.2896475, lng: 18.5825813 },
    { lat: 48.2896454, lng: 18.5821265 },
    { lat: 48.2894795, lng: 18.5820834 },
    { lat: 48.2893275, lng: 18.5819455 },
    { lat: 48.2890077, lng: 18.5818505 },
    { lat: 48.2888247, lng: 18.5818443 },
    { lat: 48.2886138, lng: 18.5820954 },
    { lat: 48.288492, lng: 18.5825917 },
    { lat: 48.2883378, lng: 18.5828454 },
    { lat: 48.2882696, lng: 18.582898 },
    { lat: 48.2881487, lng: 18.5828527 },
    { lat: 48.2880018, lng: 18.5830116 },
    { lat: 48.2878006, lng: 18.5829839 },
    { lat: 48.2876392, lng: 18.5832704 },
    { lat: 48.2874715, lng: 18.5832389 },
    { lat: 48.2874002, lng: 18.5831917 },
    { lat: 48.2870668, lng: 18.5824761 },
    { lat: 48.2870456, lng: 18.5822963 },
    { lat: 48.2869845, lng: 18.582189 },
    { lat: 48.2861318, lng: 18.5821487 },
    { lat: 48.2860843, lng: 18.5820444 },
    { lat: 48.2860873, lng: 18.581766 },
    { lat: 48.2858959, lng: 18.5811531 },
    { lat: 48.2856714, lng: 18.5811753 },
    { lat: 48.2854746, lng: 18.5808743 },
    { lat: 48.2850311, lng: 18.5809132 },
    { lat: 48.2848901, lng: 18.5807293 },
    { lat: 48.2842437, lng: 18.5804183 },
    { lat: 48.2841177, lng: 18.5801983 },
    { lat: 48.2840891, lng: 18.5797141 },
    { lat: 48.2831142, lng: 18.5798104 },
    { lat: 48.2826791, lng: 18.5796523 },
    { lat: 48.2823871, lng: 18.5794477 },
    { lat: 48.2822227, lng: 18.5794317 },
    { lat: 48.2821466, lng: 18.5787002 },
    { lat: 48.282072, lng: 18.578573 },
    { lat: 48.2816912, lng: 18.5784997 },
    { lat: 48.281291, lng: 18.5779071 },
    { lat: 48.2809546, lng: 18.5777969 },
    { lat: 48.280827, lng: 18.5791607 },
    { lat: 48.2805117, lng: 18.5813585 },
    { lat: 48.2802191, lng: 18.5830194 },
    { lat: 48.2796101, lng: 18.586017 },
    { lat: 48.2795139, lng: 18.5866091 },
    { lat: 48.279451, lng: 18.5874614 },
    { lat: 48.2780702, lng: 18.5871901 },
    { lat: 48.2779403, lng: 18.5891277 },
    { lat: 48.277989, lng: 18.589132 },
    { lat: 48.2778325, lng: 18.5912893 },
    { lat: 48.2777656, lng: 18.5912805 },
    { lat: 48.2774623, lng: 18.5953582 },
    { lat: 48.2773446, lng: 18.5976254 },
    { lat: 48.2768307, lng: 18.5987126 },
    { lat: 48.2766685, lng: 18.5999785 },
    { lat: 48.276882, lng: 18.601515 },
    { lat: 48.2773181, lng: 18.6037254 },
    { lat: 48.2773077, lng: 18.6040514 },
    { lat: 48.2771215, lng: 18.6047126 },
    { lat: 48.2771462, lng: 18.6047318 },
    { lat: 48.2769352, lng: 18.6053313 },
    { lat: 48.2768576, lng: 18.6062075 },
    { lat: 48.2768216, lng: 18.6085295 },
    { lat: 48.2766742, lng: 18.6099879 },
    { lat: 48.2767074, lng: 18.6105585 },
    { lat: 48.2766415, lng: 18.6111678 },
    { lat: 48.2766873, lng: 18.6130495 },
    { lat: 48.2766405, lng: 18.6137226 },
    { lat: 48.2766052, lng: 18.6138817 },
    { lat: 48.2766551, lng: 18.6141167 },
    { lat: 48.2767998, lng: 18.6144354 },
    { lat: 48.2768574, lng: 18.6147266 },
    { lat: 48.2778622, lng: 18.6217623 },
    { lat: 48.2791959, lng: 18.6211256 },
    { lat: 48.2806343, lng: 18.6206097 },
    { lat: 48.2816485, lng: 18.6200133 },
    { lat: 48.2822503, lng: 18.6198189 },
    { lat: 48.2827006, lng: 18.6197796 },
    { lat: 48.2827737, lng: 18.6197983 },
    { lat: 48.282899, lng: 18.6200475 },
    { lat: 48.2831122, lng: 18.620609 },
    { lat: 48.2837803, lng: 18.6203668 },
    { lat: 48.2843652, lng: 18.6200041 },
    { lat: 48.2846286, lng: 18.6199327 },
    { lat: 48.2848988, lng: 18.6199635 },
    { lat: 48.2855754, lng: 18.6202539 },
    { lat: 48.2857788, lng: 18.620591 },
    { lat: 48.2858164, lng: 18.6208652 },
    { lat: 48.2873116, lng: 18.6209833 },
    { lat: 48.2879406, lng: 18.6208315 },
    { lat: 48.2880883, lng: 18.6214538 },
    { lat: 48.2890515, lng: 18.6212458 },
    { lat: 48.2908658, lng: 18.6207353 },
    { lat: 48.2908611, lng: 18.6209639 },
    { lat: 48.2914427, lng: 18.6207655 },
    { lat: 48.2919543, lng: 18.6204899 },
    { lat: 48.2923757, lng: 18.6219552 },
    { lat: 48.2947484, lng: 18.6206788 },
    { lat: 48.2948656, lng: 18.6218192 },
    { lat: 48.2951228, lng: 18.6227567 },
    { lat: 48.2960315, lng: 18.6224748 },
    { lat: 48.2963235, lng: 18.6237399 },
    { lat: 48.2964101, lng: 18.6239026 },
    { lat: 48.2967655, lng: 18.6238458 },
    { lat: 48.2975648, lng: 18.6258522 },
    { lat: 48.2978839, lng: 18.6256663 },
    { lat: 48.2983269, lng: 18.6265124 },
    { lat: 48.2984597, lng: 18.6264637 },
    { lat: 48.2990537, lng: 18.6259985 },
    { lat: 48.2994869, lng: 18.625793 },
    { lat: 48.2996426, lng: 18.6265024 },
    { lat: 48.2997523, lng: 18.6267543 },
    { lat: 48.3005162, lng: 18.6275926 },
    { lat: 48.3006281, lng: 18.6272626 },
    { lat: 48.3007259, lng: 18.6271133 },
    { lat: 48.3013982, lng: 18.6265315 },
    { lat: 48.3015957, lng: 18.6265939 },
    { lat: 48.3019923, lng: 18.6263812 },
    { lat: 48.3024713, lng: 18.62654 },
    { lat: 48.3033206, lng: 18.626162 },
    { lat: 48.3035974, lng: 18.6259225 },
    { lat: 48.3038654, lng: 18.6255398 },
    { lat: 48.3041208, lng: 18.6253627 },
    { lat: 48.3046403, lng: 18.6254592 },
    { lat: 48.3049614, lng: 18.6253035 },
    { lat: 48.3050564, lng: 18.6253072 },
    { lat: 48.3052454, lng: 18.6254903 },
    { lat: 48.3053668, lng: 18.6257328 },
    { lat: 48.3055855, lng: 18.625834 },
    { lat: 48.3057045, lng: 18.6256743 },
    { lat: 48.3057453, lng: 18.6254507 },
    { lat: 48.3058076, lng: 18.6253914 },
    { lat: 48.3059006, lng: 18.6254027 },
    { lat: 48.3060656, lng: 18.6256781 },
    { lat: 48.306254, lng: 18.6258479 },
    { lat: 48.3064376, lng: 18.6259034 },
    { lat: 48.3069629, lng: 18.6258763 },
    { lat: 48.3071708, lng: 18.6256345 },
    { lat: 48.3073529, lng: 18.625518 },
    { lat: 48.3075748, lng: 18.6255562 },
    { lat: 48.3078197, lng: 18.6261595 },
    { lat: 48.3080102, lng: 18.6262638 },
    { lat: 48.308311, lng: 18.6259214 },
    { lat: 48.3084155, lng: 18.6259353 },
    { lat: 48.3085189, lng: 18.6260941 },
    { lat: 48.3087724, lng: 18.626279 },
    { lat: 48.3092041, lng: 18.6260425 },
    { lat: 48.309446, lng: 18.6260527 },
    { lat: 48.3101456, lng: 18.6264324 },
    { lat: 48.3104269, lng: 18.6263364 },
    { lat: 48.3110412, lng: 18.6265812 },
    { lat: 48.3112997, lng: 18.626623 },
    { lat: 48.3115017, lng: 18.6265205 },
    { lat: 48.3117486, lng: 18.6264968 },
    { lat: 48.3123981, lng: 18.6276844 },
    { lat: 48.3126683, lng: 18.6280469 },
    { lat: 48.3133872, lng: 18.6286166 },
    { lat: 48.3139768, lng: 18.6287744 },
    { lat: 48.3141032, lng: 18.629175 },
    { lat: 48.3142338, lng: 18.6292955 },
    { lat: 48.3144704, lng: 18.6294566 },
    { lat: 48.3146455, lng: 18.6294615 },
    { lat: 48.3149266, lng: 18.629676 },
    { lat: 48.3150502, lng: 18.6298945 },
    { lat: 48.3154945, lng: 18.6301632 },
    { lat: 48.3155873, lng: 18.6302933 },
    { lat: 48.3157664, lng: 18.6309157 },
    { lat: 48.315921, lng: 18.6310825 },
    { lat: 48.3164628, lng: 18.6314843 },
    { lat: 48.3166677, lng: 18.631728 },
    { lat: 48.3169672, lng: 18.6318637 },
    { lat: 48.3171501, lng: 18.6320335 },
    { lat: 48.3172792, lng: 18.6319824 },
    { lat: 48.3176046, lng: 18.6316586 },
    { lat: 48.3176454, lng: 18.6314123 },
    { lat: 48.3177277, lng: 18.6313076 },
    { lat: 48.3177896, lng: 18.6312824 },
    { lat: 48.3180485, lng: 18.6314401 },
    { lat: 48.3182665, lng: 18.6314462 },
    { lat: 48.3184646, lng: 18.6312948 },
    { lat: 48.3186162, lng: 18.6312781 },
    { lat: 48.3189245, lng: 18.6316818 },
    { lat: 48.3191091, lng: 18.6320711 },
    { lat: 48.3191986, lng: 18.6319723 },
    { lat: 48.3194056, lng: 18.6319457 },
    { lat: 48.3195866, lng: 18.6322461 },
    { lat: 48.3198633, lng: 18.6324587 },
    { lat: 48.3200957, lng: 18.6324602 },
    { lat: 48.32014, lng: 18.6325498 },
    { lat: 48.3205852, lng: 18.6328971 },
    { lat: 48.3208135, lng: 18.6329877 },
    { lat: 48.3209278, lng: 18.6329114 },
    { lat: 48.3212338, lng: 18.632356 },
    { lat: 48.3214535, lng: 18.632083 },
    { lat: 48.3218491, lng: 18.6321177 },
    { lat: 48.3219946, lng: 18.6320547 },
    { lat: 48.3221305, lng: 18.6321278 },
    { lat: 48.3222425, lng: 18.632302 },
    { lat: 48.3223699, lng: 18.6323418 },
    { lat: 48.322562, lng: 18.6327419 },
    { lat: 48.3227019, lng: 18.6328514 },
    { lat: 48.3227035, lng: 18.6336479 },
    { lat: 48.322521, lng: 18.6341538 },
    { lat: 48.3225081, lng: 18.6344361 },
    { lat: 48.3225355, lng: 18.6346096 },
    { lat: 48.3226288, lng: 18.6347495 },
    { lat: 48.3228646, lng: 18.6349399 },
    { lat: 48.3229833, lng: 18.6352763 },
    { lat: 48.3228786, lng: 18.6360489 },
    { lat: 48.3227519, lng: 18.636149 },
    { lat: 48.3225016, lng: 18.6365643 },
    { lat: 48.3224651, lng: 18.6371432 },
    { lat: 48.3225766, lng: 18.6374302 },
    { lat: 48.3226949, lng: 18.6375335 },
    { lat: 48.3227248, lng: 18.6376503 },
    { lat: 48.3231492, lng: 18.6376515 },
    { lat: 48.3232761, lng: 18.637748 },
    { lat: 48.3233118, lng: 18.6378447 },
    { lat: 48.3233125, lng: 18.6381508 },
    { lat: 48.3235504, lng: 18.6390043 },
    { lat: 48.3235874, lng: 18.6394177 },
    { lat: 48.3238212, lng: 18.6398379 },
    { lat: 48.3238454, lng: 18.6399746 },
    { lat: 48.3238116, lng: 18.6400808 },
    { lat: 48.3236643, lng: 18.6402107 },
    { lat: 48.3235688, lng: 18.6407001 },
    { lat: 48.323718, lng: 18.6416222 },
    { lat: 48.3238649, lng: 18.6418127 },
    { lat: 48.3238572, lng: 18.6421737 },
    { lat: 48.3238632, lng: 18.6423452 },
    { lat: 48.323965, lng: 18.6425412 },
    { lat: 48.3239588, lng: 18.6426323 },
    { lat: 48.3248967, lng: 18.6425403 },
    { lat: 48.325092, lng: 18.6424599 },
    { lat: 48.3258841, lng: 18.6417784 },
    { lat: 48.3266191, lng: 18.6408331 },
    { lat: 48.327629, lng: 18.63894 },
    { lat: 48.3294655, lng: 18.6372761 },
    { lat: 48.3311263, lng: 18.6362212 },
    { lat: 48.33312, lng: 18.6351743 },
    { lat: 48.3338369, lng: 18.6352664 },
    { lat: 48.3357471, lng: 18.6345504 },
    { lat: 48.3384723, lng: 18.6390551 },
    { lat: 48.3396518, lng: 18.6407005 },
    { lat: 48.3413074, lng: 18.6437881 },
    { lat: 48.342352, lng: 18.6458567 },
    { lat: 48.3441089, lng: 18.6496314 },
    { lat: 48.3452761, lng: 18.6525108 },
    { lat: 48.345307, lng: 18.652774 },
  ],
  KalnánadHronom: [
    { lat: 48.289467, lng: 18.419083 },
    { lat: 48.288829, lng: 18.41826 },
    { lat: 48.28853, lng: 18.417753 },
    { lat: 48.288828, lng: 18.416985 },
    { lat: 48.288967, lng: 18.416801 },
    { lat: 48.289159, lng: 18.416291 },
    { lat: 48.289278, lng: 18.416135 },
    { lat: 48.289375, lng: 18.415859 },
    { lat: 48.289471, lng: 18.415103 },
    { lat: 48.289489, lng: 18.414924 },
    { lat: 48.289509, lng: 18.414677 },
    { lat: 48.289316, lng: 18.413416 },
    { lat: 48.288529, lng: 18.413201 },
    { lat: 48.287438, lng: 18.413239 },
    { lat: 48.28643, lng: 18.413331 },
    { lat: 48.285861, lng: 18.412652 },
    { lat: 48.28557, lng: 18.410482 },
    { lat: 48.285556, lng: 18.410312 },
    { lat: 48.285436, lng: 18.408986 },
    { lat: 48.285551, lng: 18.407692 },
    { lat: 48.285563, lng: 18.406841 },
    { lat: 48.28544, lng: 18.406327 },
    { lat: 48.285349, lng: 18.406102 },
    { lat: 48.285029, lng: 18.405894 },
    { lat: 48.284886, lng: 18.405702 },
    { lat: 48.284874, lng: 18.405668 },
    { lat: 48.284881, lng: 18.405664 },
    { lat: 48.284886, lng: 18.405658 },
    { lat: 48.284847, lng: 18.4053 },
    { lat: 48.284819, lng: 18.405071 },
    { lat: 48.284759, lng: 18.404659 },
    { lat: 48.284717, lng: 18.404261 },
    { lat: 48.284648, lng: 18.403658 },
    { lat: 48.284635, lng: 18.403403 },
    { lat: 48.28421, lng: 18.40156 },
    { lat: 48.28408, lng: 18.401212 },
    { lat: 48.283944, lng: 18.400867 },
    { lat: 48.283763, lng: 18.400455 },
    { lat: 48.283605, lng: 18.400213 },
    { lat: 48.283468, lng: 18.399817 },
    { lat: 48.283481, lng: 18.399668 },
    { lat: 48.283519, lng: 18.3996 },
    { lat: 48.283374, lng: 18.399457 },
    { lat: 48.283359, lng: 18.399256 },
    { lat: 48.283287, lng: 18.399156 },
    { lat: 48.283247, lng: 18.398891 },
    { lat: 48.283241, lng: 18.398658 },
    { lat: 48.283186, lng: 18.398588 },
    { lat: 48.283155, lng: 18.398433 },
    { lat: 48.283089, lng: 18.398393 },
    { lat: 48.283063, lng: 18.39837 },
    { lat: 48.283004, lng: 18.398284 },
    { lat: 48.282936, lng: 18.398284 },
    { lat: 48.282911, lng: 18.398249 },
    { lat: 48.282817, lng: 18.398136 },
    { lat: 48.282757, lng: 18.398046 },
    { lat: 48.282671, lng: 18.397789 },
    { lat: 48.2827, lng: 18.397714 },
    { lat: 48.282689, lng: 18.39761 },
    { lat: 48.282644, lng: 18.397403 },
    { lat: 48.282591, lng: 18.397315 },
    { lat: 48.282586, lng: 18.397303 },
    { lat: 48.282528, lng: 18.397032 },
    { lat: 48.282477, lng: 18.396908 },
    { lat: 48.282394, lng: 18.396728 },
    { lat: 48.282383, lng: 18.396738 },
    { lat: 48.281641, lng: 18.397087 },
    { lat: 48.28114, lng: 18.3973 },
    { lat: 48.279919, lng: 18.39874 },
    { lat: 48.279438, lng: 18.399136 },
    { lat: 48.278766, lng: 18.399272 },
    { lat: 48.276857, lng: 18.400486 },
    { lat: 48.276251, lng: 18.401353 },
    { lat: 48.275862, lng: 18.401967 },
    { lat: 48.27561, lng: 18.402529 },
    { lat: 48.275536, lng: 18.402833 },
    { lat: 48.274756, lng: 18.403197 },
    { lat: 48.274196, lng: 18.403264 },
    { lat: 48.274048, lng: 18.402948 },
    { lat: 48.27379, lng: 18.402949 },
    { lat: 48.273367, lng: 18.40346 },
    { lat: 48.273324, lng: 18.403511 },
    { lat: 48.273202, lng: 18.403658 },
    { lat: 48.273096, lng: 18.4039 },
    { lat: 48.272362, lng: 18.404838 },
    { lat: 48.27168, lng: 18.405324 },
    { lat: 48.271069, lng: 18.405527 },
    { lat: 48.270392, lng: 18.405863 },
    { lat: 48.270369, lng: 18.405856 },
    { lat: 48.270312, lng: 18.405839 },
    { lat: 48.269486, lng: 18.405582 },
    { lat: 48.268673, lng: 18.405302 },
    { lat: 48.267473, lng: 18.405398 },
    { lat: 48.266791, lng: 18.405515 },
    { lat: 48.266284, lng: 18.405625 },
    { lat: 48.266257, lng: 18.405646 },
    { lat: 48.266722, lng: 18.407336 },
    { lat: 48.266779, lng: 18.408001 },
    { lat: 48.267099, lng: 18.41024 },
    { lat: 48.267037, lng: 18.411442 },
    { lat: 48.266978, lng: 18.412807 },
    { lat: 48.266762, lng: 18.413311 },
    { lat: 48.266586, lng: 18.413529 },
    { lat: 48.266517, lng: 18.413588 },
    { lat: 48.265717, lng: 18.414261 },
    { lat: 48.265221, lng: 18.414993 },
    { lat: 48.264262, lng: 18.415653 },
    { lat: 48.263713, lng: 18.416567 },
    { lat: 48.263063, lng: 18.417523 },
    { lat: 48.262368, lng: 18.417258 },
    { lat: 48.26174, lng: 18.416896 },
    { lat: 48.260821, lng: 18.416416 },
    { lat: 48.259346, lng: 18.416369 },
    { lat: 48.259206, lng: 18.416365 },
    { lat: 48.259079, lng: 18.417013 },
    { lat: 48.258967, lng: 18.417634 },
    { lat: 48.258753, lng: 18.418575 },
    { lat: 48.258007, lng: 18.419712 },
    { lat: 48.257825, lng: 18.419981 },
    { lat: 48.256729, lng: 18.421589 },
    { lat: 48.256119, lng: 18.422653 },
    { lat: 48.255606, lng: 18.423633 },
    { lat: 48.254998, lng: 18.424299 },
    { lat: 48.254287, lng: 18.425233 },
    { lat: 48.253426, lng: 18.42653 },
    { lat: 48.253387, lng: 18.42659 },
    { lat: 48.25324, lng: 18.426442 },
    { lat: 48.253113, lng: 18.4264 },
    { lat: 48.252925, lng: 18.426269 },
    { lat: 48.252754, lng: 18.426369 },
    { lat: 48.252573, lng: 18.426368 },
    { lat: 48.252517, lng: 18.426366 },
    { lat: 48.25244, lng: 18.426333 },
    { lat: 48.252357, lng: 18.426216 },
    { lat: 48.252219, lng: 18.426188 },
    { lat: 48.252082, lng: 18.426274 },
    { lat: 48.251976, lng: 18.426409 },
    { lat: 48.251988, lng: 18.426467 },
    { lat: 48.25199, lng: 18.426476 },
    { lat: 48.252003, lng: 18.42655 },
    { lat: 48.252037, lng: 18.426743 },
    { lat: 48.252125, lng: 18.427171 },
    { lat: 48.252161, lng: 18.427696 },
    { lat: 48.252283, lng: 18.429018 },
    { lat: 48.252348, lng: 18.430348 },
    { lat: 48.252344, lng: 18.430367 },
    { lat: 48.252332, lng: 18.430415 },
    { lat: 48.252228, lng: 18.430366 },
    { lat: 48.252159, lng: 18.43035 },
    { lat: 48.251967, lng: 18.430306 },
    { lat: 48.251842, lng: 18.430282 },
    { lat: 48.251668, lng: 18.430249 },
    { lat: 48.251498, lng: 18.430217 },
    { lat: 48.25149, lng: 18.430289 },
    { lat: 48.250969, lng: 18.43073 },
    { lat: 48.250907, lng: 18.430724 },
    { lat: 48.2504, lng: 18.43087 },
    { lat: 48.24942, lng: 18.431325 },
    { lat: 48.24924, lng: 18.431471 },
    { lat: 48.249316, lng: 18.431705 },
    { lat: 48.248945, lng: 18.432209 },
    { lat: 48.248889, lng: 18.432284 },
    { lat: 48.248034, lng: 18.433494 },
    { lat: 48.247894, lng: 18.433737 },
    { lat: 48.24784, lng: 18.433811 },
    { lat: 48.247619, lng: 18.434066 },
    { lat: 48.246872, lng: 18.434801 },
    { lat: 48.24686, lng: 18.434813 },
    { lat: 48.246849, lng: 18.434818 },
    { lat: 48.246464, lng: 18.435004 },
    { lat: 48.246382, lng: 18.435043 },
    { lat: 48.246263, lng: 18.435101 },
    { lat: 48.246216, lng: 18.435091 },
    { lat: 48.24606, lng: 18.435176 },
    { lat: 48.246024, lng: 18.435196 },
    { lat: 48.245468, lng: 18.4355 },
    { lat: 48.244033, lng: 18.436347 },
    { lat: 48.242555, lng: 18.437354 },
    { lat: 48.241045, lng: 18.43829 },
    { lat: 48.240367, lng: 18.43863 },
    { lat: 48.239862, lng: 18.438786 },
    { lat: 48.239365, lng: 18.438646 },
    { lat: 48.23874, lng: 18.438614 },
    { lat: 48.2385682, lng: 18.439348 },
    { lat: 48.2377426, lng: 18.4442112 },
    { lat: 48.2377365, lng: 18.4442622 },
    { lat: 48.2399711, lng: 18.4447435 },
    { lat: 48.2410089, lng: 18.4498707 },
    { lat: 48.2403071, lng: 18.4506773 },
    { lat: 48.2408743, lng: 18.4518042 },
    { lat: 48.2417436, lng: 18.4533082 },
    { lat: 48.2418331, lng: 18.4541871 },
    { lat: 48.2415612, lng: 18.4542671 },
    { lat: 48.2414365, lng: 18.4555486 },
    { lat: 48.2419762, lng: 18.4570956 },
    { lat: 48.2427445, lng: 18.4610199 },
    { lat: 48.2428982, lng: 18.4610259 },
    { lat: 48.2429602, lng: 18.4611243 },
    { lat: 48.2431372, lng: 18.4610643 },
    { lat: 48.2440868, lng: 18.4612592 },
    { lat: 48.2444463, lng: 18.4611733 },
    { lat: 48.2449125, lng: 18.4615213 },
    { lat: 48.245649, lng: 18.4617616 },
    { lat: 48.2458286, lng: 18.4618431 },
    { lat: 48.2469616, lng: 18.4623568 },
    { lat: 48.2494593, lng: 18.4616303 },
    { lat: 48.2523807, lng: 18.4612969 },
    { lat: 48.2524322, lng: 18.461291 },
    { lat: 48.253131, lng: 18.460855 },
    { lat: 48.2554398, lng: 18.4608709 },
    { lat: 48.2609441, lng: 18.4586875 },
    { lat: 48.26173, lng: 18.4580691 },
    { lat: 48.2632289, lng: 18.4578438 },
    { lat: 48.263487, lng: 18.4587876 },
    { lat: 48.2642744, lng: 18.4609343 },
    { lat: 48.2673338, lng: 18.4599601 },
    { lat: 48.2678178, lng: 18.4618042 },
    { lat: 48.2685054, lng: 18.46139 },
    { lat: 48.2688656, lng: 18.4612749 },
    { lat: 48.2693996, lng: 18.4614231 },
    { lat: 48.2701625, lng: 18.4617926 },
    { lat: 48.2705538, lng: 18.4620313 },
    { lat: 48.2714718, lng: 18.4628354 },
    { lat: 48.27197, lng: 18.4635161 },
    { lat: 48.27368, lng: 18.464879 },
    { lat: 48.274331, lng: 18.464556 },
    { lat: 48.274355, lng: 18.464526 },
    { lat: 48.274515, lng: 18.46433 },
    { lat: 48.274864, lng: 18.463905 },
    { lat: 48.275183, lng: 18.463502 },
    { lat: 48.275296, lng: 18.463365 },
    { lat: 48.275433, lng: 18.463082 },
    { lat: 48.275653, lng: 18.462599 },
    { lat: 48.276401, lng: 18.460925 },
    { lat: 48.276909, lng: 18.459786 },
    { lat: 48.277415, lng: 18.459051 },
    { lat: 48.278024, lng: 18.457991 },
    { lat: 48.27897, lng: 18.456874 },
    { lat: 48.279164, lng: 18.456645 },
    { lat: 48.279496, lng: 18.456052 },
    { lat: 48.279999, lng: 18.455149 },
    { lat: 48.280017, lng: 18.455064 },
    { lat: 48.280039, lng: 18.45496 },
    { lat: 48.280086, lng: 18.454731 },
    { lat: 48.280172, lng: 18.454311 },
    { lat: 48.28027, lng: 18.453823 },
    { lat: 48.280361, lng: 18.453377 },
    { lat: 48.280368, lng: 18.453342 },
    { lat: 48.280573, lng: 18.452613 },
    { lat: 48.280598, lng: 18.45253 },
    { lat: 48.280694, lng: 18.452326 },
    { lat: 48.280958, lng: 18.452185 },
    { lat: 48.281101, lng: 18.451892 },
    { lat: 48.281274, lng: 18.450851 },
    { lat: 48.281577, lng: 18.451365 },
    { lat: 48.281585, lng: 18.451341 },
    { lat: 48.282448, lng: 18.449267 },
    { lat: 48.282462, lng: 18.449234 },
    { lat: 48.282509, lng: 18.449124 },
    { lat: 48.282528, lng: 18.449081 },
    { lat: 48.285418, lng: 18.442077 },
    { lat: 48.285781, lng: 18.441563 },
    { lat: 48.286083, lng: 18.440943 },
    { lat: 48.286469, lng: 18.44023 },
    { lat: 48.286712, lng: 18.439434 },
    { lat: 48.287028, lng: 18.438875 },
    { lat: 48.287354, lng: 18.43819 },
    { lat: 48.28778, lng: 18.437363 },
    { lat: 48.288023, lng: 18.437144 },
    { lat: 48.288466, lng: 18.436886 },
    { lat: 48.288568, lng: 18.436732 },
    { lat: 48.288718, lng: 18.436502 },
    { lat: 48.289292, lng: 18.436185 },
    { lat: 48.29011, lng: 18.435661 },
    { lat: 48.290762, lng: 18.435084 },
    { lat: 48.291267, lng: 18.434417 },
    { lat: 48.291541, lng: 18.433816 },
    { lat: 48.291565, lng: 18.433766 },
    { lat: 48.291719, lng: 18.433426 },
    { lat: 48.291746, lng: 18.432847 },
    { lat: 48.291713, lng: 18.431751 },
    { lat: 48.291439, lng: 18.430741 },
    { lat: 48.291255, lng: 18.430282 },
    { lat: 48.291198, lng: 18.430145 },
    { lat: 48.291175, lng: 18.430086 },
    { lat: 48.290901, lng: 18.429474 },
    { lat: 48.290752, lng: 18.429045 },
    { lat: 48.290612, lng: 18.42864 },
    { lat: 48.290458, lng: 18.428025 },
    { lat: 48.290323, lng: 18.427247 },
    { lat: 48.290341, lng: 18.426596 },
    { lat: 48.290385, lng: 18.426128 },
    { lat: 48.290635, lng: 18.424332 },
    { lat: 48.290683, lng: 18.42392 },
    { lat: 48.290797, lng: 18.423625 },
    { lat: 48.290939, lng: 18.423351 },
    { lat: 48.291152, lng: 18.423076 },
    { lat: 48.291473, lng: 18.422703 },
    { lat: 48.291822, lng: 18.422199 },
    { lat: 48.292005, lng: 18.421826 },
    { lat: 48.292195, lng: 18.421175 },
    { lat: 48.292235, lng: 18.421134 },
    { lat: 48.290832, lng: 18.419897 },
    { lat: 48.289467, lng: 18.419083 },
  ],
  KalnánadHronomExt: [
    { lat: 48.1626381, lng: 18.5177988 },
    { lat: 48.1651801, lng: 18.5209272 },
    { lat: 48.1693191, lng: 18.5263401 },
    { lat: 48.1722147, lng: 18.5297073 },
    { lat: 48.174987, lng: 18.5330916 },
    { lat: 48.1757375, lng: 18.5354704 },
    { lat: 48.1760915, lng: 18.5360802 },
    { lat: 48.1762369, lng: 18.5363471 },
    { lat: 48.1767275, lng: 18.5359329 },
    { lat: 48.1770148, lng: 18.535948 },
    { lat: 48.1779479, lng: 18.5361344 },
    { lat: 48.1791017, lng: 18.5366234 },
    { lat: 48.1798826, lng: 18.5366692 },
    { lat: 48.180713, lng: 18.5366301 },
    { lat: 48.1814675, lng: 18.5361794 },
    { lat: 48.1816005, lng: 18.5361567 },
    { lat: 48.1818014, lng: 18.535771 },
    { lat: 48.1837392, lng: 18.5378328 },
    { lat: 48.1847561, lng: 18.5387035 },
    { lat: 48.1851294, lng: 18.5384341 },
    { lat: 48.1851719, lng: 18.5382437 },
    { lat: 48.1850803, lng: 18.537565 },
    { lat: 48.1826636, lng: 18.5342438 },
    { lat: 48.1830426, lng: 18.533465 },
    { lat: 48.183652, lng: 18.5323256 },
    { lat: 48.1838807, lng: 18.5317279 },
    { lat: 48.1846504, lng: 18.5311419 },
    { lat: 48.185153, lng: 18.5308859 },
    { lat: 48.1866951, lng: 18.5311335 },
    { lat: 48.1867182, lng: 18.531878 },
    { lat: 48.1885742, lng: 18.5324814 },
    { lat: 48.1996815, lng: 18.5340849 },
    { lat: 48.1998082, lng: 18.5339901 },
    { lat: 48.2052498, lng: 18.5333763 },
    { lat: 48.2045681, lng: 18.5276038 },
    { lat: 48.2045513, lng: 18.5273375 },
    { lat: 48.2057354, lng: 18.5267011 },
    { lat: 48.206856, lng: 18.528914 },
    { lat: 48.2071426, lng: 18.5296318 },
    { lat: 48.2074052, lng: 18.5308357 },
    { lat: 48.2076777, lng: 18.5313785 },
    { lat: 48.2081931, lng: 18.531968 },
    { lat: 48.208593, lng: 18.5322673 },
    { lat: 48.2088217, lng: 18.5322384 },
    { lat: 48.2088456, lng: 18.5324059 },
    { lat: 48.2091503, lng: 18.532346 },
    { lat: 48.2092944, lng: 18.5320372 },
    { lat: 48.2092405, lng: 18.5314879 },
    { lat: 48.2095138, lng: 18.531329 },
    { lat: 48.2098947, lng: 18.531284 },
    { lat: 48.2103482, lng: 18.53081 },
    { lat: 48.2120849, lng: 18.5279834 },
    { lat: 48.2163181, lng: 18.5299593 },
    { lat: 48.216588, lng: 18.5300444 },
    { lat: 48.2169474, lng: 18.5300167 },
    { lat: 48.2171385, lng: 18.5302293 },
    { lat: 48.2172912, lng: 18.5307909 },
    { lat: 48.2173049, lng: 18.5313852 },
    { lat: 48.2172131, lng: 18.5317867 },
    { lat: 48.2171242, lng: 18.5327339 },
    { lat: 48.2169703, lng: 18.5332353 },
    { lat: 48.2169693, lng: 18.5341996 },
    { lat: 48.2166714, lng: 18.5355705 },
    { lat: 48.2167764, lng: 18.5359997 },
    { lat: 48.2169661, lng: 18.5365182 },
    { lat: 48.2172797, lng: 18.5370093 },
    { lat: 48.2174564, lng: 18.53739 },
    { lat: 48.2177697, lng: 18.5378335 },
    { lat: 48.2181307, lng: 18.538224 },
    { lat: 48.2187136, lng: 18.5387095 },
    { lat: 48.2190231, lng: 18.5388613 },
    { lat: 48.2193151, lng: 18.5389395 },
    { lat: 48.2196959, lng: 18.5388707 },
    { lat: 48.2197475, lng: 18.5388459 },
    { lat: 48.2200595, lng: 18.538691 },
    { lat: 48.2207142, lng: 18.5381062 },
    { lat: 48.2241525, lng: 18.5365295 },
    { lat: 48.2246192, lng: 18.5363 },
    { lat: 48.2237108, lng: 18.5295208 },
    { lat: 48.22375, lng: 18.527841 },
    { lat: 48.223861, lng: 18.5273546 },
    { lat: 48.2243322, lng: 18.5265129 },
    { lat: 48.2250371, lng: 18.5259991 },
    { lat: 48.2253086, lng: 18.5258888 },
    { lat: 48.2253583, lng: 18.5257072 },
    { lat: 48.2253675, lng: 18.5253855 },
    { lat: 48.2252108, lng: 18.5247129 },
    { lat: 48.2255258, lng: 18.5243266 },
    { lat: 48.225639, lng: 18.5239267 },
    { lat: 48.2258765, lng: 18.5236511 },
    { lat: 48.2256194, lng: 18.5220177 },
    { lat: 48.2248722, lng: 18.5146815 },
    { lat: 48.2247328, lng: 18.5136478 },
    { lat: 48.2245659, lng: 18.5129383 },
    { lat: 48.2232572, lng: 18.5125043 },
    { lat: 48.2231172, lng: 18.5125418 },
    { lat: 48.2228094, lng: 18.5122205 },
    { lat: 48.2226488, lng: 18.5121513 },
    { lat: 48.2222516, lng: 18.5121718 },
    { lat: 48.2221094, lng: 18.5121081 },
    { lat: 48.2218005, lng: 18.5122935 },
    { lat: 48.2216336, lng: 18.5126817 },
    { lat: 48.2214315, lng: 18.5129045 },
    { lat: 48.2211378, lng: 18.5130824 },
    { lat: 48.220577, lng: 18.512935 },
    { lat: 48.220315, lng: 18.5127453 },
    { lat: 48.2199319, lng: 18.5122042 },
    { lat: 48.2196238, lng: 18.5125062 },
    { lat: 48.2191855, lng: 18.5131073 },
    { lat: 48.2188643, lng: 18.5133399 },
    { lat: 48.2185898, lng: 18.5132908 },
    { lat: 48.2184181, lng: 18.513081 },
    { lat: 48.2182894, lng: 18.5127748 },
    { lat: 48.2180965, lng: 18.5126292 },
    { lat: 48.2177432, lng: 18.5126051 },
    { lat: 48.2174951, lng: 18.5128943 },
    { lat: 48.2173747, lng: 18.5131668 },
    { lat: 48.2172928, lng: 18.5141722 },
    { lat: 48.2172327, lng: 18.5143304 },
    { lat: 48.2172114, lng: 18.5142913 },
    { lat: 48.2152156, lng: 18.5096369 },
    { lat: 48.2136946, lng: 18.5043545 },
    { lat: 48.2110706, lng: 18.4970261 },
    { lat: 48.2103852, lng: 18.4947558 },
    { lat: 48.2102194, lng: 18.4941072 },
    { lat: 48.210308, lng: 18.4939537 },
    { lat: 48.2101007, lng: 18.4935918 },
    { lat: 48.2099968, lng: 18.4932228 },
    { lat: 48.2090511, lng: 18.4874903 },
    { lat: 48.2090131, lng: 18.4865798 },
    { lat: 48.2090491, lng: 18.4852417 },
    { lat: 48.2092104, lng: 18.483431 },
    { lat: 48.2092576, lng: 18.482982 },
    { lat: 48.2092086, lng: 18.4829732 },
    { lat: 48.20864, lng: 18.4828307 },
    { lat: 48.2081584, lng: 18.4826084 },
    { lat: 48.2076343, lng: 18.4822521 },
    { lat: 48.2070547, lng: 18.4816105 },
    { lat: 48.2061523, lng: 18.4839051 },
    { lat: 48.2057111, lng: 18.4834741 },
    { lat: 48.2053054, lng: 18.4842934 },
    { lat: 48.2048497, lng: 18.4850648 },
    { lat: 48.2046891, lng: 18.4857308 },
    { lat: 48.2044327, lng: 18.4858861 },
    { lat: 48.2039947, lng: 18.4868342 },
    { lat: 48.2038728, lng: 18.4869774 },
    { lat: 48.2026085, lng: 18.4881151 },
    { lat: 48.2003186, lng: 18.4899892 },
    { lat: 48.1984659, lng: 18.4892498 },
    { lat: 48.1969635, lng: 18.488095 },
    { lat: 48.1936296, lng: 18.4867487 },
    { lat: 48.1921916, lng: 18.486005 },
    { lat: 48.1921615, lng: 18.4859904 },
    { lat: 48.191107, lng: 18.4874971 },
    { lat: 48.1903226, lng: 18.4887891 },
    { lat: 48.1890164, lng: 18.4906812 },
    { lat: 48.1882603, lng: 18.4922023 },
    { lat: 48.1874053, lng: 18.4948848 },
    { lat: 48.1872116, lng: 18.4953182 },
    { lat: 48.186965, lng: 18.4957302 },
    { lat: 48.1862067, lng: 18.4966413 },
    { lat: 48.1860294, lng: 18.4969238 },
    { lat: 48.1855128, lng: 18.4979611 },
    { lat: 48.1853317, lng: 18.4981744 },
    { lat: 48.1833397, lng: 18.5016347 },
    { lat: 48.18219, lng: 18.5006952 },
    { lat: 48.1815535, lng: 18.5010587 },
    { lat: 48.1807549, lng: 18.5010796 },
    { lat: 48.1791444, lng: 18.5014334 },
    { lat: 48.17662, lng: 18.5017396 },
    { lat: 48.1756521, lng: 18.5021077 },
    { lat: 48.1747429, lng: 18.5023338 },
    { lat: 48.1732876, lng: 18.50298 },
    { lat: 48.1724089, lng: 18.5031069 },
    { lat: 48.1717068, lng: 18.503301 },
    { lat: 48.1700248, lng: 18.5041151 },
    { lat: 48.1695707, lng: 18.5044689 },
    { lat: 48.1674234, lng: 18.5069802 },
    { lat: 48.1662285, lng: 18.5087091 },
    { lat: 48.1658572, lng: 18.5091109 },
    { lat: 48.1656476, lng: 18.5091983 },
    { lat: 48.1654653, lng: 18.5091845 },
    { lat: 48.1643349, lng: 18.5079206 },
    { lat: 48.1643175, lng: 18.5078715 },
    { lat: 48.1619747, lng: 18.5132915 },
    { lat: 48.1607045, lng: 18.5158607 },
    { lat: 48.1607978, lng: 18.5158555 },
    { lat: 48.1609445, lng: 18.5159564 },
    { lat: 48.1625544, lng: 18.5176717 },
    { lat: 48.1626381, lng: 18.5177988 },
  ],
  StarýHrádok: [
    { lat: 48.1637033, lng: 18.6118503 },
    { lat: 48.1633947, lng: 18.6125401 },
    { lat: 48.1633942, lng: 18.6127352 },
    { lat: 48.1635996, lng: 18.6129257 },
    { lat: 48.1637492, lng: 18.6132466 },
    { lat: 48.1641394, lng: 18.6138061 },
    { lat: 48.1642787, lng: 18.6140644 },
    { lat: 48.1644378, lng: 18.6145421 },
    { lat: 48.1646369, lng: 18.615381 },
    { lat: 48.1646303, lng: 18.6158418 },
    { lat: 48.1645462, lng: 18.6163668 },
    { lat: 48.1646046, lng: 18.6166109 },
    { lat: 48.1648127, lng: 18.6168287 },
    { lat: 48.1652716, lng: 18.6169103 },
    { lat: 48.1654052, lng: 18.617023 },
    { lat: 48.1654719, lng: 18.6172106 },
    { lat: 48.1654929, lng: 18.6174497 },
    { lat: 48.1653617, lng: 18.6179836 },
    { lat: 48.1654028, lng: 18.618304 },
    { lat: 48.1659698, lng: 18.6196674 },
    { lat: 48.1660732, lng: 18.6200943 },
    { lat: 48.1660844, lng: 18.6204042 },
    { lat: 48.1657282, lng: 18.6209464 },
    { lat: 48.1655188, lng: 18.6210344 },
    { lat: 48.1651015, lng: 18.6203934 },
    { lat: 48.1643553, lng: 18.6198852 },
    { lat: 48.1641201, lng: 18.6198548 },
    { lat: 48.1637404, lng: 18.6199869 },
    { lat: 48.1635647, lng: 18.6201548 },
    { lat: 48.16325, lng: 18.6205687 },
    { lat: 48.1630094, lng: 18.6210392 },
    { lat: 48.1626399, lng: 18.6211332 },
    { lat: 48.1622867, lng: 18.6213355 },
    { lat: 48.1600371, lng: 18.6213888 },
    { lat: 48.1596439, lng: 18.6211477 },
    { lat: 48.1591943, lng: 18.6211981 },
    { lat: 48.1586204, lng: 18.6206419 },
    { lat: 48.1583517, lng: 18.6202213 },
    { lat: 48.1576324, lng: 18.6200198 },
    { lat: 48.1574324, lng: 18.6201402 },
    { lat: 48.1572648, lng: 18.6206873 },
    { lat: 48.1568818, lng: 18.6207995 },
    { lat: 48.1563696, lng: 18.6213595 },
    { lat: 48.1560782, lng: 18.6215543 },
    { lat: 48.1558432, lng: 18.6219219 },
    { lat: 48.1556247, lng: 18.6224685 },
    { lat: 48.1551486, lng: 18.6228788 },
    { lat: 48.1549405, lng: 18.6233026 },
    { lat: 48.1548077, lng: 18.6240372 },
    { lat: 48.1543891, lng: 18.6242424 },
    { lat: 48.154006, lng: 18.6239986 },
    { lat: 48.1538417, lng: 18.624008 },
    { lat: 48.1534512, lng: 18.6243154 },
    { lat: 48.1532876, lng: 18.6246846 },
    { lat: 48.1531559, lng: 18.6257267 },
    { lat: 48.1529159, lng: 18.6261667 },
    { lat: 48.1528754, lng: 18.6264178 },
    { lat: 48.1529061, lng: 18.6267355 },
    { lat: 48.1530487, lng: 18.6270752 },
    { lat: 48.1521343, lng: 18.6268539 },
    { lat: 48.1513991, lng: 18.626834 },
    { lat: 48.1506404, lng: 18.6254742 },
    { lat: 48.1496111, lng: 18.6238236 },
    { lat: 48.1496652, lng: 18.6233459 },
    { lat: 48.1494284, lng: 18.6229127 },
    { lat: 48.1480817, lng: 18.6207809 },
    { lat: 48.1473402, lng: 18.6196731 },
    { lat: 48.1461451, lng: 18.6201998 },
    { lat: 48.1460442, lng: 18.6200446 },
    { lat: 48.1456623, lng: 18.6200105 },
    { lat: 48.1439368, lng: 18.6213041 },
    { lat: 48.1442828, lng: 18.6221665 },
    { lat: 48.1444759, lng: 18.6234794 },
    { lat: 48.1444439, lng: 18.6247967 },
    { lat: 48.1442412, lng: 18.6265854 },
    { lat: 48.1442045, lng: 18.6294797 },
    { lat: 48.1444078, lng: 18.6341125 },
    { lat: 48.1447524, lng: 18.63591 },
    { lat: 48.144901, lng: 18.6380635 },
    { lat: 48.1450482, lng: 18.6387411 },
    { lat: 48.144976, lng: 18.6396539 },
    { lat: 48.1451133, lng: 18.6401059 },
    { lat: 48.1452569, lng: 18.6403827 },
    { lat: 48.1455846, lng: 18.6414534 },
    { lat: 48.1458617, lng: 18.6418422 },
    { lat: 48.1460794, lng: 18.642051 },
    { lat: 48.1463921, lng: 18.6422614 },
    { lat: 48.1468022, lng: 18.6423799 },
    { lat: 48.1469037, lng: 18.6427734 },
    { lat: 48.1474156, lng: 18.6438317 },
    { lat: 48.1476308, lng: 18.6439119 },
    { lat: 48.1464844, lng: 18.6464618 },
    { lat: 48.1462111, lng: 18.6467033 },
    { lat: 48.1447591, lng: 18.6436818 },
    { lat: 48.1436616, lng: 18.6442855 },
    { lat: 48.1431054, lng: 18.6450718 },
    { lat: 48.142753, lng: 18.6452896 },
    { lat: 48.1415233, lng: 18.6452044 },
    { lat: 48.1413885, lng: 18.6452334 },
    { lat: 48.1410911, lng: 18.6454141 },
    { lat: 48.1407194, lng: 18.6456421 },
    { lat: 48.1408368, lng: 18.6461477 },
    { lat: 48.1408096, lng: 18.6464342 },
    { lat: 48.1402098, lng: 18.6471894 },
    { lat: 48.1399879, lng: 18.6472558 },
    { lat: 48.1396843, lng: 18.6468176 },
    { lat: 48.1395205, lng: 18.6468127 },
    { lat: 48.1393115, lng: 18.6469513 },
    { lat: 48.139262, lng: 18.6471801 },
    { lat: 48.1393622, lng: 18.6476345 },
    { lat: 48.1392968, lng: 18.6477398 },
    { lat: 48.1390867, lng: 18.6477967 },
    { lat: 48.1388954, lng: 18.6482015 },
    { lat: 48.1387708, lng: 18.6482735 },
    { lat: 48.1386605, lng: 18.6481321 },
    { lat: 48.1384525, lng: 18.6482717 },
    { lat: 48.1384323, lng: 18.6490401 },
    { lat: 48.1384699, lng: 18.6492774 },
    { lat: 48.1381266, lng: 18.650082 },
    { lat: 48.1386573, lng: 18.6512565 },
    { lat: 48.1387112, lng: 18.6517772 },
    { lat: 48.1386893, lng: 18.6521393 },
    { lat: 48.1385584, lng: 18.6524514 },
    { lat: 48.1383687, lng: 18.6525534 },
    { lat: 48.138057, lng: 18.6523344 },
    { lat: 48.1373982, lng: 18.6520624 },
    { lat: 48.1372673, lng: 18.6515443 },
    { lat: 48.1371873, lng: 18.6514205 },
    { lat: 48.136769, lng: 18.6511106 },
    { lat: 48.1365096, lng: 18.6511313 },
    { lat: 48.1359892, lng: 18.6513094 },
    { lat: 48.1347042, lng: 18.65303 },
    { lat: 48.134596, lng: 18.6532401 },
    { lat: 48.1345889, lng: 18.6534231 },
    { lat: 48.134703, lng: 18.6537801 },
    { lat: 48.1347421, lng: 18.6540885 },
    { lat: 48.1346582, lng: 18.6548483 },
    { lat: 48.1344914, lng: 18.6554955 },
    { lat: 48.1345753, lng: 18.6559006 },
    { lat: 48.1347726, lng: 18.6561924 },
    { lat: 48.1347318, lng: 18.6565003 },
    { lat: 48.1350575, lng: 18.6567049 },
    { lat: 48.1354327, lng: 18.6567994 },
    { lat: 48.1357796, lng: 18.6566664 },
    { lat: 48.1363788, lng: 18.6577991 },
    { lat: 48.1361206, lng: 18.6580672 },
    { lat: 48.1360551, lng: 18.6582987 },
    { lat: 48.1360286, lng: 18.6588315 },
    { lat: 48.1358689, lng: 18.6592561 },
    { lat: 48.1357325, lng: 18.6594216 },
    { lat: 48.1354593, lng: 18.6594606 },
    { lat: 48.135317, lng: 18.6596843 },
    { lat: 48.1354321, lng: 18.6599593 },
    { lat: 48.1354228, lng: 18.6603061 },
    { lat: 48.1354761, lng: 18.6605835 },
    { lat: 48.1356525, lng: 18.6610484 },
    { lat: 48.135683, lng: 18.6615525 },
    { lat: 48.1357579, lng: 18.6619583 },
    { lat: 48.1360838, lng: 18.6621015 },
    { lat: 48.1360642, lng: 18.6626134 },
    { lat: 48.1360058, lng: 18.6628934 },
    { lat: 48.1358167, lng: 18.6631277 },
    { lat: 48.1355073, lng: 18.6632449 },
    { lat: 48.1354465, lng: 18.6634143 },
    { lat: 48.1355009, lng: 18.6635422 },
    { lat: 48.1360633, lng: 18.6637674 },
    { lat: 48.1362389, lng: 18.6639256 },
    { lat: 48.1362222, lng: 18.6640134 },
    { lat: 48.1362563, lng: 18.6640595 },
    { lat: 48.1362757, lng: 18.6640854 },
    { lat: 48.1364263, lng: 18.663825 },
    { lat: 48.1364812, lng: 18.6634847 },
    { lat: 48.1366336, lng: 18.6634663 },
    { lat: 48.136678, lng: 18.6633479 },
    { lat: 48.1366719, lng: 18.6630461 },
    { lat: 48.1367967, lng: 18.6630818 },
    { lat: 48.1369518, lng: 18.6629477 },
    { lat: 48.137022, lng: 18.6625766 },
    { lat: 48.1371235, lng: 18.6624947 },
    { lat: 48.1372272, lng: 18.662555 },
    { lat: 48.1375066, lng: 18.6624644 },
    { lat: 48.1375986, lng: 18.6621849 },
    { lat: 48.1375908, lng: 18.6619147 },
    { lat: 48.1376609, lng: 18.6617113 },
    { lat: 48.1380495, lng: 18.661097 },
    { lat: 48.13807, lng: 18.6609946 },
    { lat: 48.1379665, lng: 18.6607101 },
    { lat: 48.1380529, lng: 18.6603247 },
    { lat: 48.1381642, lng: 18.6601721 },
    { lat: 48.138531, lng: 18.6599484 },
    { lat: 48.1389842, lng: 18.6600689 },
    { lat: 48.139321, lng: 18.6600593 },
    { lat: 48.1395608, lng: 18.6599879 },
    { lat: 48.1398498, lng: 18.6597853 },
    { lat: 48.1401099, lng: 18.6595092 },
    { lat: 48.1403836, lng: 18.6595835 },
    { lat: 48.1407341, lng: 18.6594338 },
    { lat: 48.1410667, lng: 18.6593654 },
    { lat: 48.1411945, lng: 18.6593959 },
    { lat: 48.1416501, lng: 18.6597476 },
    { lat: 48.1419867, lng: 18.6605079 },
    { lat: 48.142119, lng: 18.6606826 },
    { lat: 48.1423393, lng: 18.6608139 },
    { lat: 48.1427146, lng: 18.6613082 },
    { lat: 48.1437331, lng: 18.6616012 },
    { lat: 48.1440953, lng: 18.6615433 },
    { lat: 48.1443667, lng: 18.6615741 },
    { lat: 48.1450665, lng: 18.6610182 },
    { lat: 48.1455863, lng: 18.6609145 },
    { lat: 48.1457639, lng: 18.6607337 },
    { lat: 48.1461022, lng: 18.6600046 },
    { lat: 48.1464231, lng: 18.6597423 },
    { lat: 48.1465348, lng: 18.6595169 },
    { lat: 48.1465344, lng: 18.6590691 },
    { lat: 48.1465898, lng: 18.6587827 },
    { lat: 48.1468316, lng: 18.6584759 },
    { lat: 48.1469433, lng: 18.6582046 },
    { lat: 48.1472177, lng: 18.6579808 },
    { lat: 48.147373, lng: 18.6579334 },
    { lat: 48.1474356, lng: 18.6578411 },
    { lat: 48.1476393, lng: 18.657019 },
    { lat: 48.1475012, lng: 18.6565504 },
    { lat: 48.1473178, lng: 18.6561442 },
    { lat: 48.1472481, lng: 18.6555201 },
    { lat: 48.147086, lng: 18.6553426 },
    { lat: 48.1470677, lng: 18.6552523 },
    { lat: 48.1471951, lng: 18.6547071 },
    { lat: 48.1472015, lng: 18.6544323 },
    { lat: 48.1473787, lng: 18.6542262 },
    { lat: 48.1467088, lng: 18.6528535 },
    { lat: 48.1467774, lng: 18.6522434 },
    { lat: 48.1468887, lng: 18.65207 },
    { lat: 48.1472039, lng: 18.6519365 },
    { lat: 48.1474856, lng: 18.6514514 },
    { lat: 48.1477452, lng: 18.6511604 },
    { lat: 48.1483142, lng: 18.6507565 },
    { lat: 48.1485393, lng: 18.6508112 },
    { lat: 48.1491559, lng: 18.650222 },
    { lat: 48.1494182, lng: 18.6502125 },
    { lat: 48.1497324, lng: 18.6500336 },
    { lat: 48.1498909, lng: 18.6501285 },
    { lat: 48.1500434, lng: 18.6503216 },
    { lat: 48.1504246, lng: 18.6503433 },
    { lat: 48.1505634, lng: 18.6502781 },
    { lat: 48.1510391, lng: 18.6504438 },
    { lat: 48.1511577, lng: 18.6502988 },
    { lat: 48.1518994, lng: 18.6500798 },
    { lat: 48.1521893, lng: 18.6497289 },
    { lat: 48.1529015, lng: 18.649238 },
    { lat: 48.15348, lng: 18.6491924 },
    { lat: 48.1536167, lng: 18.6490554 },
    { lat: 48.1538765, lng: 18.6489332 },
    { lat: 48.1542842, lng: 18.6480078 },
    { lat: 48.1543647, lng: 18.6479546 },
    { lat: 48.1547224, lng: 18.6480177 },
    { lat: 48.1556577, lng: 18.6475657 },
    { lat: 48.1563843, lng: 18.647571 },
    { lat: 48.1566467, lng: 18.6474799 },
    { lat: 48.156727, lng: 18.6473173 },
    { lat: 48.1567406, lng: 18.6471104 },
    { lat: 48.1568127, lng: 18.646901 },
    { lat: 48.1570195, lng: 18.6467001 },
    { lat: 48.1573213, lng: 18.6468179 },
    { lat: 48.1580448, lng: 18.6455964 },
    { lat: 48.1585586, lng: 18.6442978 },
    { lat: 48.1583173, lng: 18.6438003 },
    { lat: 48.1583649, lng: 18.6422028 },
    { lat: 48.158786, lng: 18.6412955 },
    { lat: 48.1590625, lng: 18.6401747 },
    { lat: 48.1608633, lng: 18.6357585 },
    { lat: 48.1611821, lng: 18.6347342 },
    { lat: 48.1616852, lng: 18.6334056 },
    { lat: 48.163123, lng: 18.6316576 },
    { lat: 48.163097, lng: 18.63157 },
    { lat: 48.1713908, lng: 18.6266358 },
    { lat: 48.1744824, lng: 18.6235643 },
    { lat: 48.1779937, lng: 18.618237 },
    { lat: 48.1780947, lng: 18.6178558 },
    { lat: 48.1781333, lng: 18.617198 },
    { lat: 48.1781748, lng: 18.6166324 },
    { lat: 48.1781179, lng: 18.6165196 },
    { lat: 48.1737954, lng: 18.6085633 },
    { lat: 48.1738259, lng: 18.608502 },
    { lat: 48.1737852, lng: 18.6084197 },
    { lat: 48.1737269, lng: 18.6085299 },
    { lat: 48.1734403, lng: 18.6087126 },
    { lat: 48.1731349, lng: 18.6087611 },
    { lat: 48.1728969, lng: 18.6087 },
    { lat: 48.1728168, lng: 18.6088822 },
    { lat: 48.1727923, lng: 18.6096566 },
    { lat: 48.1723783, lng: 18.6102731 },
    { lat: 48.1718054, lng: 18.6107517 },
    { lat: 48.1716814, lng: 18.610735 },
    { lat: 48.1714675, lng: 18.6104487 },
    { lat: 48.1712511, lng: 18.6104563 },
    { lat: 48.1711445, lng: 18.6105251 },
    { lat: 48.1709786, lng: 18.6107941 },
    { lat: 48.1707949, lng: 18.6107758 },
    { lat: 48.1701732, lng: 18.6104406 },
    { lat: 48.1699748, lng: 18.6105263 },
    { lat: 48.1697682, lng: 18.6102744 },
    { lat: 48.1695284, lng: 18.6102029 },
    { lat: 48.1693391, lng: 18.6103676 },
    { lat: 48.1690792, lng: 18.6107947 },
    { lat: 48.1688071, lng: 18.6107723 },
    { lat: 48.1687343, lng: 18.6107364 },
    { lat: 48.1686958, lng: 18.6106326 },
    { lat: 48.1687682, lng: 18.6100146 },
    { lat: 48.1684882, lng: 18.6097597 },
    { lat: 48.1682159, lng: 18.6090713 },
    { lat: 48.1680951, lng: 18.6089444 },
    { lat: 48.1676832, lng: 18.6089801 },
    { lat: 48.16763, lng: 18.609041 },
    { lat: 48.1676097, lng: 18.6093918 },
    { lat: 48.1672046, lng: 18.6099037 },
    { lat: 48.1671641, lng: 18.6101768 },
    { lat: 48.1669975, lng: 18.6103896 },
    { lat: 48.1668657, lng: 18.6106823 },
    { lat: 48.1666153, lng: 18.6109089 },
    { lat: 48.1663596, lng: 18.610957 },
    { lat: 48.1660966, lng: 18.6106244 },
    { lat: 48.1659092, lng: 18.6105568 },
    { lat: 48.1656508, lng: 18.6106593 },
    { lat: 48.1653561, lng: 18.6105729 },
    { lat: 48.1651146, lng: 18.6104221 },
    { lat: 48.1648316, lng: 18.6108428 },
    { lat: 48.1647144, lng: 18.610886 },
    { lat: 48.1643481, lng: 18.6108038 },
    { lat: 48.1641037, lng: 18.6110378 },
    { lat: 48.1637033, lng: 18.6118503 },
  ],
  MýtneLudany: [
    { lat: 48.1720675, lng: 18.7033514 },
    { lat: 48.1721176, lng: 18.7032531 },
    { lat: 48.1722399, lng: 18.7030095 },
    { lat: 48.1731776, lng: 18.7016731 },
    { lat: 48.1713512, lng: 18.6950321 },
    { lat: 48.1708742, lng: 18.6930825 },
    { lat: 48.1708498, lng: 18.6906772 },
    { lat: 48.170644, lng: 18.689347 },
    { lat: 48.1706271, lng: 18.6885113 },
    { lat: 48.1708302, lng: 18.6874011 },
    { lat: 48.1709582, lng: 18.6869521 },
    { lat: 48.171115, lng: 18.6867432 },
    { lat: 48.1714511, lng: 18.6850449 },
    { lat: 48.17158, lng: 18.6847118 },
    { lat: 48.1716208, lng: 18.6844034 },
    { lat: 48.1716432, lng: 18.6836028 },
    { lat: 48.1715791, lng: 18.682123 },
    { lat: 48.1714183, lng: 18.6814202 },
    { lat: 48.1719787, lng: 18.6774611 },
    { lat: 48.1730694, lng: 18.6710613 },
    { lat: 48.1752349, lng: 18.6674683 },
    { lat: 48.1748385, lng: 18.665284 },
    { lat: 48.1759716, lng: 18.6642948 },
    { lat: 48.1762308, lng: 18.6641883 },
    { lat: 48.1765766, lng: 18.6637599 },
    { lat: 48.1765237, lng: 18.663678 },
    { lat: 48.1762045, lng: 18.6609687 },
    { lat: 48.1761, lng: 18.6605955 },
    { lat: 48.175654, lng: 18.6595242 },
    { lat: 48.1746373, lng: 18.6579301 },
    { lat: 48.1755213, lng: 18.6569038 },
    { lat: 48.1770614, lng: 18.6557652 },
    { lat: 48.1782118, lng: 18.6545168 },
    { lat: 48.1787213, lng: 18.6538435 },
    { lat: 48.1799005, lng: 18.6525378 },
    { lat: 48.1808785, lng: 18.6511454 },
    { lat: 48.1813486, lng: 18.6506429 },
    { lat: 48.1812639, lng: 18.6504535 },
    { lat: 48.1823624, lng: 18.6498671 },
    { lat: 48.1829335, lng: 18.6494594 },
    { lat: 48.1831345, lng: 18.6492343 },
    { lat: 48.1829202, lng: 18.6487333 },
    { lat: 48.1829001, lng: 18.6486322 },
    { lat: 48.1829546, lng: 18.6485121 },
    { lat: 48.1848138, lng: 18.6466902 },
    { lat: 48.1851574, lng: 18.6463058 },
    { lat: 48.1857708, lng: 18.6454698 },
    { lat: 48.1859222, lng: 18.6453316 },
    { lat: 48.1861304, lng: 18.6452573 },
    { lat: 48.1862133, lng: 18.6451649 },
    { lat: 48.1861852, lng: 18.6450651 },
    { lat: 48.1861704, lng: 18.6447254 },
    { lat: 48.1862539, lng: 18.6441869 },
    { lat: 48.1865039, lng: 18.6432652 },
    { lat: 48.1869049, lng: 18.6426925 },
    { lat: 48.1870776, lng: 18.6411952 },
    { lat: 48.187027, lng: 18.640973 },
    { lat: 48.1870329, lng: 18.6405372 },
    { lat: 48.1872289, lng: 18.6404289 },
    { lat: 48.1884185, lng: 18.6403829 },
    { lat: 48.1888355, lng: 18.6402741 },
    { lat: 48.1861514, lng: 18.634891 },
    { lat: 48.1856286, lng: 18.6336562 },
    { lat: 48.185182, lng: 18.6324953 },
    { lat: 48.1823833, lng: 18.6244109 },
    { lat: 48.1781748, lng: 18.6166324 },
    { lat: 48.1781333, lng: 18.617198 },
    { lat: 48.1780947, lng: 18.6178558 },
    { lat: 48.1779937, lng: 18.618237 },
    { lat: 48.1744824, lng: 18.6235643 },
    { lat: 48.1713908, lng: 18.6266358 },
    { lat: 48.163097, lng: 18.63157 },
    { lat: 48.163123, lng: 18.6316576 },
    { lat: 48.1616852, lng: 18.6334056 },
    { lat: 48.1611821, lng: 18.6347342 },
    { lat: 48.1608633, lng: 18.6357585 },
    { lat: 48.1590625, lng: 18.6401747 },
    { lat: 48.158786, lng: 18.6412955 },
    { lat: 48.1583649, lng: 18.6422028 },
    { lat: 48.1583173, lng: 18.6438003 },
    { lat: 48.1585586, lng: 18.6442978 },
    { lat: 48.1580448, lng: 18.6455964 },
    { lat: 48.1573213, lng: 18.6468179 },
    { lat: 48.1570195, lng: 18.6467001 },
    { lat: 48.1568127, lng: 18.646901 },
    { lat: 48.1567406, lng: 18.6471104 },
    { lat: 48.156727, lng: 18.6473173 },
    { lat: 48.1566467, lng: 18.6474799 },
    { lat: 48.1563843, lng: 18.647571 },
    { lat: 48.1556577, lng: 18.6475657 },
    { lat: 48.1547224, lng: 18.6480177 },
    { lat: 48.1543647, lng: 18.6479546 },
    { lat: 48.1542842, lng: 18.6480078 },
    { lat: 48.1538765, lng: 18.6489332 },
    { lat: 48.1536167, lng: 18.6490554 },
    { lat: 48.15348, lng: 18.6491924 },
    { lat: 48.1529015, lng: 18.649238 },
    { lat: 48.1521893, lng: 18.6497289 },
    { lat: 48.1518994, lng: 18.6500798 },
    { lat: 48.1511577, lng: 18.6502988 },
    { lat: 48.1510391, lng: 18.6504438 },
    { lat: 48.1505634, lng: 18.6502781 },
    { lat: 48.1504246, lng: 18.6503433 },
    { lat: 48.1500434, lng: 18.6503216 },
    { lat: 48.1498909, lng: 18.6501285 },
    { lat: 48.1497324, lng: 18.6500336 },
    { lat: 48.1494182, lng: 18.6502125 },
    { lat: 48.1491559, lng: 18.650222 },
    { lat: 48.1485393, lng: 18.6508112 },
    { lat: 48.1483142, lng: 18.6507565 },
    { lat: 48.1477452, lng: 18.6511604 },
    { lat: 48.1474856, lng: 18.6514514 },
    { lat: 48.1472039, lng: 18.6519365 },
    { lat: 48.1468887, lng: 18.65207 },
    { lat: 48.1467774, lng: 18.6522434 },
    { lat: 48.1467088, lng: 18.6528535 },
    { lat: 48.1473787, lng: 18.6542262 },
    { lat: 48.1472015, lng: 18.6544323 },
    { lat: 48.1471951, lng: 18.6547071 },
    { lat: 48.1470677, lng: 18.6552523 },
    { lat: 48.147086, lng: 18.6553426 },
    { lat: 48.1472481, lng: 18.6555201 },
    { lat: 48.1473178, lng: 18.6561442 },
    { lat: 48.1475012, lng: 18.6565504 },
    { lat: 48.1476393, lng: 18.657019 },
    { lat: 48.1474356, lng: 18.6578411 },
    { lat: 48.147373, lng: 18.6579334 },
    { lat: 48.1472177, lng: 18.6579808 },
    { lat: 48.1469433, lng: 18.6582046 },
    { lat: 48.1468316, lng: 18.6584759 },
    { lat: 48.1465898, lng: 18.6587827 },
    { lat: 48.1465344, lng: 18.6590691 },
    { lat: 48.1465348, lng: 18.6595169 },
    { lat: 48.1464231, lng: 18.6597423 },
    { lat: 48.1461022, lng: 18.6600046 },
    { lat: 48.1457639, lng: 18.6607337 },
    { lat: 48.1455863, lng: 18.6609145 },
    { lat: 48.1450665, lng: 18.6610182 },
    { lat: 48.1443667, lng: 18.6615741 },
    { lat: 48.1440953, lng: 18.6615433 },
    { lat: 48.1437331, lng: 18.6616012 },
    { lat: 48.1427146, lng: 18.6613082 },
    { lat: 48.1423393, lng: 18.6608139 },
    { lat: 48.142119, lng: 18.6606826 },
    { lat: 48.1419867, lng: 18.6605079 },
    { lat: 48.1416501, lng: 18.6597476 },
    { lat: 48.1411945, lng: 18.6593959 },
    { lat: 48.1410667, lng: 18.6593654 },
    { lat: 48.1407341, lng: 18.6594338 },
    { lat: 48.1403836, lng: 18.6595835 },
    { lat: 48.1401099, lng: 18.6595092 },
    { lat: 48.1398498, lng: 18.6597853 },
    { lat: 48.1395608, lng: 18.6599879 },
    { lat: 48.139321, lng: 18.6600593 },
    { lat: 48.1389842, lng: 18.6600689 },
    { lat: 48.138531, lng: 18.6599484 },
    { lat: 48.1381642, lng: 18.6601721 },
    { lat: 48.1380529, lng: 18.6603247 },
    { lat: 48.1379665, lng: 18.6607101 },
    { lat: 48.13807, lng: 18.6609946 },
    { lat: 48.1380495, lng: 18.661097 },
    { lat: 48.1376609, lng: 18.6617113 },
    { lat: 48.1375908, lng: 18.6619147 },
    { lat: 48.1375986, lng: 18.6621849 },
    { lat: 48.1375066, lng: 18.6624644 },
    { lat: 48.1372272, lng: 18.662555 },
    { lat: 48.1371235, lng: 18.6624947 },
    { lat: 48.137022, lng: 18.6625766 },
    { lat: 48.1369518, lng: 18.6629477 },
    { lat: 48.1367967, lng: 18.6630818 },
    { lat: 48.1366719, lng: 18.6630461 },
    { lat: 48.136678, lng: 18.6633479 },
    { lat: 48.1366336, lng: 18.6634663 },
    { lat: 48.1364812, lng: 18.6634847 },
    { lat: 48.1364263, lng: 18.663825 },
    { lat: 48.1362757, lng: 18.6640854 },
    { lat: 48.1362353, lng: 18.664318 },
    { lat: 48.1362867, lng: 18.6644551 },
    { lat: 48.1362782, lng: 18.6646352 },
    { lat: 48.1362028, lng: 18.6648876 },
    { lat: 48.1359964, lng: 18.6649693 },
    { lat: 48.1359328, lng: 18.6654205 },
    { lat: 48.1359788, lng: 18.6655847 },
    { lat: 48.1362083, lng: 18.6657604 },
    { lat: 48.1362743, lng: 18.6658962 },
    { lat: 48.1361861, lng: 18.6662447 },
    { lat: 48.1362131, lng: 18.6662856 },
    { lat: 48.1361091, lng: 18.6668666 },
    { lat: 48.1361262, lng: 18.667516 },
    { lat: 48.1362804, lng: 18.6678237 },
    { lat: 48.1365968, lng: 18.668151 },
    { lat: 48.1372279, lng: 18.667994 },
    { lat: 48.1373605, lng: 18.6681087 },
    { lat: 48.1375565, lng: 18.6689201 },
    { lat: 48.1377853, lng: 18.6689988 },
    { lat: 48.1378451, lng: 18.6691254 },
    { lat: 48.1375461, lng: 18.6698126 },
    { lat: 48.1373325, lng: 18.6699701 },
    { lat: 48.1373197, lng: 18.6701104 },
    { lat: 48.1377302, lng: 18.6707353 },
    { lat: 48.1379537, lng: 18.6712631 },
    { lat: 48.1378429, lng: 18.6720921 },
    { lat: 48.1376066, lng: 18.6726668 },
    { lat: 48.1377423, lng: 18.6733401 },
    { lat: 48.1377084, lng: 18.6737598 },
    { lat: 48.1374617, lng: 18.6742863 },
    { lat: 48.1372924, lng: 18.6745168 },
    { lat: 48.1371897, lng: 18.675474 },
    { lat: 48.1372376, lng: 18.6756724 },
    { lat: 48.1374434, lng: 18.6759343 },
    { lat: 48.1376456, lng: 18.6766073 },
    { lat: 48.1377081, lng: 18.67664 },
    { lat: 48.1379846, lng: 18.6763507 },
    { lat: 48.1381099, lng: 18.6763296 },
    { lat: 48.1382494, lng: 18.6764262 },
    { lat: 48.1382907, lng: 18.6766712 },
    { lat: 48.1381905, lng: 18.6771349 },
    { lat: 48.1382368, lng: 18.6772617 },
    { lat: 48.1384067, lng: 18.6774199 },
    { lat: 48.1384495, lng: 18.6778353 },
    { lat: 48.1387475, lng: 18.6779453 },
    { lat: 48.138821, lng: 18.6781819 },
    { lat: 48.1388487, lng: 18.6787124 },
    { lat: 48.1392686, lng: 18.678093 },
    { lat: 48.1394382, lng: 18.6781001 },
    { lat: 48.1395663, lng: 18.6784244 },
    { lat: 48.1395525, lng: 18.678818 },
    { lat: 48.1395989, lng: 18.6792619 },
    { lat: 48.1399949, lng: 18.6789088 },
    { lat: 48.1401713, lng: 18.6789144 },
    { lat: 48.1402981, lng: 18.678843 },
    { lat: 48.1403851, lng: 18.6786984 },
    { lat: 48.1405798, lng: 18.6786054 },
    { lat: 48.1406731, lng: 18.6788099 },
    { lat: 48.1409294, lng: 18.6788348 },
    { lat: 48.1413507, lng: 18.6811684 },
    { lat: 48.1422286, lng: 18.681365 },
    { lat: 48.1433178, lng: 18.6823234 },
    { lat: 48.1438338, lng: 18.6830424 },
    { lat: 48.1444385, lng: 18.6835273 },
    { lat: 48.1449634, lng: 18.6838307 },
    { lat: 48.1454758, lng: 18.6847791 },
    { lat: 48.1457994, lng: 18.6850905 },
    { lat: 48.1462854, lng: 18.6870588 },
    { lat: 48.14656, lng: 18.6869612 },
    { lat: 48.1467853, lng: 18.6869581 },
    { lat: 48.1467958, lng: 18.6873206 },
    { lat: 48.1470124, lng: 18.6876152 },
    { lat: 48.1471592, lng: 18.6876601 },
    { lat: 48.1472499, lng: 18.6872296 },
    { lat: 48.1475598, lng: 18.6874318 },
    { lat: 48.1479604, lng: 18.6874977 },
    { lat: 48.1481452, lng: 18.6878103 },
    { lat: 48.148354, lng: 18.6877495 },
    { lat: 48.1485702, lng: 18.6875996 },
    { lat: 48.1486242, lng: 18.6873434 },
    { lat: 48.1489638, lng: 18.6871969 },
    { lat: 48.1491642, lng: 18.6868981 },
    { lat: 48.1507966, lng: 18.68699 },
    { lat: 48.151958, lng: 18.687176 },
    { lat: 48.1521961, lng: 18.687094 },
    { lat: 48.1531602, lng: 18.6876315 },
    { lat: 48.154848, lng: 18.6887125 },
    { lat: 48.1556433, lng: 18.6894279 },
    { lat: 48.156106, lng: 18.6897251 },
    { lat: 48.1566295, lng: 18.6900024 },
    { lat: 48.1568659, lng: 18.6900124 },
    { lat: 48.156867, lng: 18.6906834 },
    { lat: 48.1569352, lng: 18.6914068 },
    { lat: 48.1570559, lng: 18.6917822 },
    { lat: 48.1571491, lng: 18.6925527 },
    { lat: 48.1572259, lng: 18.6927639 },
    { lat: 48.157274, lng: 18.6938913 },
    { lat: 48.1571899, lng: 18.6958085 },
    { lat: 48.1572167, lng: 18.6963075 },
    { lat: 48.1577977, lng: 18.6987627 },
    { lat: 48.1579838, lng: 18.6992041 },
    { lat: 48.1581237, lng: 18.7001269 },
    { lat: 48.1584067, lng: 18.7009029 },
    { lat: 48.1587581, lng: 18.7014005 },
    { lat: 48.1592994, lng: 18.7020092 },
    { lat: 48.1598203, lng: 18.7019824 },
    { lat: 48.1608436, lng: 18.7015678 },
    { lat: 48.1611371, lng: 18.7015552 },
    { lat: 48.1612347, lng: 18.7014407 },
    { lat: 48.1616454, lng: 18.7015483 },
    { lat: 48.1618414, lng: 18.7014188 },
    { lat: 48.1621734, lng: 18.7014885 },
    { lat: 48.1626939, lng: 18.701816 },
    { lat: 48.1639509, lng: 18.7029578 },
    { lat: 48.1653817, lng: 18.7045257 },
    { lat: 48.1655986, lng: 18.7048678 },
    { lat: 48.1659104, lng: 18.7058867 },
    { lat: 48.1660553, lng: 18.7065265 },
    { lat: 48.165978, lng: 18.7089967 },
    { lat: 48.1660581, lng: 18.7101332 },
    { lat: 48.1663061, lng: 18.7112894 },
    { lat: 48.1666047, lng: 18.7117016 },
    { lat: 48.1668987, lng: 18.7125397 },
    { lat: 48.1670427, lng: 18.71285 },
    { lat: 48.1671943, lng: 18.7131908 },
    { lat: 48.1688253, lng: 18.7138264 },
    { lat: 48.1692282, lng: 18.7143385 },
    { lat: 48.1697238, lng: 18.7148317 },
    { lat: 48.1700991, lng: 18.7143419 },
    { lat: 48.1703959, lng: 18.713816 },
    { lat: 48.1704506, lng: 18.7135137 },
    { lat: 48.1704364, lng: 18.7132716 },
    { lat: 48.1701339, lng: 18.7119805 },
    { lat: 48.1698373, lng: 18.7111416 },
    { lat: 48.1698787, lng: 18.7097902 },
    { lat: 48.169857, lng: 18.7088791 },
    { lat: 48.1702339, lng: 18.7076835 },
    { lat: 48.1705038, lng: 18.7070173 },
    { lat: 48.1707208, lng: 18.7066882 },
    { lat: 48.1708175, lng: 18.7064342 },
    { lat: 48.1708824, lng: 18.7058541 },
    { lat: 48.1708039, lng: 18.7053999 },
    { lat: 48.1707882, lng: 18.7046888 },
    { lat: 48.1709181, lng: 18.703997 },
    { lat: 48.1709636, lng: 18.7039201 },
    { lat: 48.1715706, lng: 18.7039035 },
    { lat: 48.171728, lng: 18.7037896 },
    { lat: 48.1719299, lng: 18.7034336 },
    { lat: 48.1720675, lng: 18.7033514 },
  ],
  Zbrojníky: [
    { lat: 48.0996615, lng: 18.709964 },
    { lat: 48.0997333, lng: 18.7101043 },
    { lat: 48.100392, lng: 18.7114075 },
    { lat: 48.1006067, lng: 18.7119819 },
    { lat: 48.1015011, lng: 18.71383 },
    { lat: 48.1029144, lng: 18.7173468 },
    { lat: 48.103564, lng: 18.7198269 },
    { lat: 48.1046297, lng: 18.7229642 },
    { lat: 48.1057378, lng: 18.7276046 },
    { lat: 48.1053909, lng: 18.7280377 },
    { lat: 48.104647, lng: 18.7287595 },
    { lat: 48.1036982, lng: 18.7301877 },
    { lat: 48.1027816, lng: 18.7317543 },
    { lat: 48.1019733, lng: 18.7326886 },
    { lat: 48.1012217, lng: 18.7337534 },
    { lat: 48.1005459, lng: 18.7348233 },
    { lat: 48.0996871, lng: 18.7359559 },
    { lat: 48.0978643, lng: 18.7389238 },
    { lat: 48.0971687, lng: 18.7398774 },
    { lat: 48.0954897, lng: 18.7410873 },
    { lat: 48.0947194, lng: 18.7417392 },
    { lat: 48.0954888, lng: 18.7443221 },
    { lat: 48.0956124, lng: 18.7454105 },
    { lat: 48.0960468, lng: 18.7483131 },
    { lat: 48.0960511, lng: 18.7486668 },
    { lat: 48.0960986, lng: 18.7488587 },
    { lat: 48.0962451, lng: 18.7489637 },
    { lat: 48.0962689, lng: 18.7492187 },
    { lat: 48.0962212, lng: 18.7492551 },
    { lat: 48.096193, lng: 18.7494659 },
    { lat: 48.0962668, lng: 18.7497915 },
    { lat: 48.0962643, lng: 18.7499802 },
    { lat: 48.0962128, lng: 18.7500686 },
    { lat: 48.0962334, lng: 18.7503092 },
    { lat: 48.0960854, lng: 18.7505155 },
    { lat: 48.0958908, lng: 18.7513063 },
    { lat: 48.095714, lng: 18.7516081 },
    { lat: 48.0958182, lng: 18.7519972 },
    { lat: 48.0957278, lng: 18.7523759 },
    { lat: 48.0952514, lng: 18.7555592 },
    { lat: 48.0952123, lng: 18.7566045 },
    { lat: 48.0952049, lng: 18.7570996 },
    { lat: 48.0968586, lng: 18.7581451 },
    { lat: 48.0988913, lng: 18.7572013 },
    { lat: 48.1010183, lng: 18.7575175 },
    { lat: 48.1022684, lng: 18.7584385 },
    { lat: 48.102279, lng: 18.7583815 },
    { lat: 48.102415, lng: 18.7585206 },
    { lat: 48.102796, lng: 18.7586239 },
    { lat: 48.1028637, lng: 18.7588787 },
    { lat: 48.1029904, lng: 18.7589953 },
    { lat: 48.1034337, lng: 18.759184 },
    { lat: 48.1037801, lng: 18.7596132 },
    { lat: 48.1038618, lng: 18.7599817 },
    { lat: 48.10378, lng: 18.760225 },
    { lat: 48.1037881, lng: 18.7603543 },
    { lat: 48.1039072, lng: 18.7606023 },
    { lat: 48.1041108, lng: 18.7613106 },
    { lat: 48.1041194, lng: 18.7614497 },
    { lat: 48.1040242, lng: 18.7618014 },
    { lat: 48.1041225, lng: 18.7621878 },
    { lat: 48.1042179, lng: 18.7622445 },
    { lat: 48.1043923, lng: 18.7621071 },
    { lat: 48.1044685, lng: 18.7622229 },
    { lat: 48.1046014, lng: 18.7622654 },
    { lat: 48.1049459, lng: 18.7621719 },
    { lat: 48.1053281, lng: 18.7618267 },
    { lat: 48.1069059, lng: 18.7606638 },
    { lat: 48.1070641, lng: 18.7606051 },
    { lat: 48.1087079, lng: 18.7603846 },
    { lat: 48.1109895, lng: 18.7584613 },
    { lat: 48.1112287, lng: 18.7579127 },
    { lat: 48.111715, lng: 18.7572152 },
    { lat: 48.1120773, lng: 18.7565228 },
    { lat: 48.1126643, lng: 18.7557282 },
    { lat: 48.1128424, lng: 18.7553263 },
    { lat: 48.1133856, lng: 18.7545577 },
    { lat: 48.1141081, lng: 18.7525365 },
    { lat: 48.1142019, lng: 18.7523719 },
    { lat: 48.1145665, lng: 18.7521224 },
    { lat: 48.1149087, lng: 18.7523217 },
    { lat: 48.1160221, lng: 18.7522042 },
    { lat: 48.1167078, lng: 18.7516293 },
    { lat: 48.1168111, lng: 18.7516435 },
    { lat: 48.1172273, lng: 18.7481124 },
    { lat: 48.1174232, lng: 18.7449974 },
    { lat: 48.1175168, lng: 18.7442186 },
    { lat: 48.1177646, lng: 18.7431539 },
    { lat: 48.1179966, lng: 18.7417968 },
    { lat: 48.1179987, lng: 18.7407797 },
    { lat: 48.1182619, lng: 18.7403877 },
    { lat: 48.1190922, lng: 18.7381729 },
    { lat: 48.1197709, lng: 18.7365224 },
    { lat: 48.1199194, lng: 18.7352899 },
    { lat: 48.1205323, lng: 18.7336345 },
    { lat: 48.1223309, lng: 18.731731 },
    { lat: 48.1233868, lng: 18.7315676 },
    { lat: 48.1236448, lng: 18.7313939 },
    { lat: 48.123919, lng: 18.7306577 },
    { lat: 48.1239652, lng: 18.7297704 },
    { lat: 48.1239555, lng: 18.7280522 },
    { lat: 48.1237002, lng: 18.7253043 },
    { lat: 48.1236994, lng: 18.7203199 },
    { lat: 48.1236001, lng: 18.7185517 },
    { lat: 48.1240864, lng: 18.7157586 },
    { lat: 48.1245226, lng: 18.7144265 },
    { lat: 48.1245261, lng: 18.7119269 },
    { lat: 48.1240397, lng: 18.7088085 },
    { lat: 48.1228817, lng: 18.7023887 },
    { lat: 48.1226763, lng: 18.7021782 },
    { lat: 48.1222174, lng: 18.6981732 },
    { lat: 48.1220315, lng: 18.6968973 },
    { lat: 48.1215131, lng: 18.6941804 },
    { lat: 48.1211737, lng: 18.6920857 },
    { lat: 48.1202292, lng: 18.6872991 },
    { lat: 48.1198853, lng: 18.6852275 },
    { lat: 48.1198447, lng: 18.6846638 },
    { lat: 48.118491, lng: 18.6795758 },
    { lat: 48.1187081, lng: 18.6795652 },
    { lat: 48.1191053, lng: 18.6794239 },
    { lat: 48.1195861, lng: 18.679114 },
    { lat: 48.1195774, lng: 18.6790187 },
    { lat: 48.1196364, lng: 18.6790039 },
    { lat: 48.1196589, lng: 18.6790654 },
    { lat: 48.1212351, lng: 18.6772778 },
    { lat: 48.1214994, lng: 18.6771574 },
    { lat: 48.1217332, lng: 18.6771444 },
    { lat: 48.1220427, lng: 18.6773186 },
    { lat: 48.1220913, lng: 18.6768828 },
    { lat: 48.1222477, lng: 18.6764789 },
    { lat: 48.1224586, lng: 18.6764369 },
    { lat: 48.1227324, lng: 18.6758868 },
    { lat: 48.1235411, lng: 18.675513 },
    { lat: 48.1236306, lng: 18.6752887 },
    { lat: 48.1238395, lng: 18.6752102 },
    { lat: 48.123548, lng: 18.6743674 },
    { lat: 48.1235218, lng: 18.6739424 },
    { lat: 48.1232645, lng: 18.6726665 },
    { lat: 48.1232141, lng: 18.6721922 },
    { lat: 48.1231812, lng: 18.6722099 },
    { lat: 48.1231775, lng: 18.6721013 },
    { lat: 48.1232729, lng: 18.6719242 },
    { lat: 48.1233736, lng: 18.6715538 },
    { lat: 48.1232047, lng: 18.6695776 },
    { lat: 48.1239502, lng: 18.6673429 },
    { lat: 48.1239841, lng: 18.6667595 },
    { lat: 48.1241192, lng: 18.6667753 },
    { lat: 48.1241421, lng: 18.6667165 },
    { lat: 48.1243488, lng: 18.6661811 },
    { lat: 48.1240955, lng: 18.665292 },
    { lat: 48.1234591, lng: 18.6630774 },
    { lat: 48.1225468, lng: 18.6607211 },
    { lat: 48.1222979, lng: 18.6601659 },
    { lat: 48.1212482, lng: 18.6583172 },
    { lat: 48.1208571, lng: 18.6583225 },
    { lat: 48.1207352, lng: 18.6583848 },
    { lat: 48.1198761, lng: 18.6599965 },
    { lat: 48.1196944, lng: 18.660226 },
    { lat: 48.119345, lng: 18.6601675 },
    { lat: 48.1192516, lng: 18.6600655 },
    { lat: 48.1190693, lng: 18.6595919 },
    { lat: 48.1187915, lng: 18.6586301 },
    { lat: 48.1185599, lng: 18.658412 },
    { lat: 48.1183721, lng: 18.6585498 },
    { lat: 48.1179678, lng: 18.6593507 },
    { lat: 48.1174198, lng: 18.6600854 },
    { lat: 48.117074, lng: 18.660862 },
    { lat: 48.1169694, lng: 18.6609124 },
    { lat: 48.1167462, lng: 18.6608497 },
    { lat: 48.116081, lng: 18.6604813 },
    { lat: 48.1159324, lng: 18.6604636 },
    { lat: 48.1153508, lng: 18.6606408 },
    { lat: 48.115319, lng: 18.6605069 },
    { lat: 48.1148999, lng: 18.660122 },
    { lat: 48.114635, lng: 18.6595638 },
    { lat: 48.1145064, lng: 18.6591043 },
    { lat: 48.1147236, lng: 18.6583221 },
    { lat: 48.1139257, lng: 18.657043 },
    { lat: 48.1130863, lng: 18.656767 },
    { lat: 48.1123931, lng: 18.6578192 },
    { lat: 48.1117277, lng: 18.6583587 },
    { lat: 48.1112615, lng: 18.6578753 },
    { lat: 48.1109779, lng: 18.6573865 },
    { lat: 48.1108527, lng: 18.6566168 },
    { lat: 48.1107454, lng: 18.6563195 },
    { lat: 48.1105582, lng: 18.6561741 },
    { lat: 48.110264, lng: 18.6561578 },
    { lat: 48.1102048, lng: 18.6560859 },
    { lat: 48.1092002, lng: 18.6563197 },
    { lat: 48.1088113, lng: 18.6558677 },
    { lat: 48.10841, lng: 18.6555985 },
    { lat: 48.1081516, lng: 18.6553051 },
    { lat: 48.1079386, lng: 18.6551635 },
    { lat: 48.107881, lng: 18.6549951 },
    { lat: 48.1077518, lng: 18.6548798 },
    { lat: 48.1069316, lng: 18.654712 },
    { lat: 48.1067249, lng: 18.6547264 },
    { lat: 48.1064586, lng: 18.6549505 },
    { lat: 48.1063256, lng: 18.6551388 },
    { lat: 48.1063295, lng: 18.6555703 },
    { lat: 48.1065947, lng: 18.6559007 },
    { lat: 48.1066668, lng: 18.6563603 },
    { lat: 48.1067755, lng: 18.6566774 },
    { lat: 48.1068769, lng: 18.6567008 },
    { lat: 48.1068415, lng: 18.6571685 },
    { lat: 48.1066367, lng: 18.6575764 },
    { lat: 48.1063448, lng: 18.657642 },
    { lat: 48.1058437, lng: 18.6574703 },
    { lat: 48.1058048, lng: 18.6575636 },
    { lat: 48.1058445, lng: 18.657756 },
    { lat: 48.1059443, lng: 18.6579525 },
    { lat: 48.105997, lng: 18.6582151 },
    { lat: 48.1061277, lng: 18.6584202 },
    { lat: 48.1061188, lng: 18.6587583 },
    { lat: 48.1060335, lng: 18.6591197 },
    { lat: 48.1057375, lng: 18.6596738 },
    { lat: 48.1054025, lng: 18.6600685 },
    { lat: 48.1053381, lng: 18.6602933 },
    { lat: 48.1053784, lng: 18.6604963 },
    { lat: 48.1055423, lng: 18.6606904 },
    { lat: 48.1058483, lng: 18.6607604 },
    { lat: 48.1059004, lng: 18.660975 },
    { lat: 48.1058911, lng: 18.6612408 },
    { lat: 48.1061617, lng: 18.6611132 },
    { lat: 48.1063792, lng: 18.6612765 },
    { lat: 48.1063757, lng: 18.661339 },
    { lat: 48.1062134, lng: 18.6614839 },
    { lat: 48.1062108, lng: 18.6616666 },
    { lat: 48.1063356, lng: 18.6620556 },
    { lat: 48.1063326, lng: 18.6628736 },
    { lat: 48.1064777, lng: 18.6629704 },
    { lat: 48.1067452, lng: 18.6632882 },
    { lat: 48.1070086, lng: 18.6637586 },
    { lat: 48.1078587, lng: 18.6663187 },
    { lat: 48.1079562, lng: 18.666932 },
    { lat: 48.108229, lng: 18.6697678 },
    { lat: 48.1081432, lng: 18.6703096 },
    { lat: 48.1077164, lng: 18.6716565 },
    { lat: 48.1076764, lng: 18.6719415 },
    { lat: 48.1076638, lng: 18.6722055 },
    { lat: 48.1065711, lng: 18.6730676 },
    { lat: 48.1064043, lng: 18.6736099 },
    { lat: 48.1062428, lng: 18.673942 },
    { lat: 48.1059288, lng: 18.6741012 },
    { lat: 48.105669, lng: 18.6736974 },
    { lat: 48.1051834, lng: 18.673475 },
    { lat: 48.1049526, lng: 18.6730878 },
    { lat: 48.104516, lng: 18.6727497 },
    { lat: 48.1040831, lng: 18.6725683 },
    { lat: 48.1037957, lng: 18.672341 },
    { lat: 48.1037008, lng: 18.6723987 },
    { lat: 48.1032723, lng: 18.6723811 },
    { lat: 48.1028202, lng: 18.6728022 },
    { lat: 48.102723, lng: 18.6729893 },
    { lat: 48.1025507, lng: 18.6735749 },
    { lat: 48.1022845, lng: 18.6741599 },
    { lat: 48.1017618, lng: 18.6750503 },
    { lat: 48.1013283, lng: 18.6754832 },
    { lat: 48.1008192, lng: 18.6767882 },
    { lat: 48.1006155, lng: 18.6771518 },
    { lat: 48.1001032, lng: 18.6776704 },
    { lat: 48.0999396, lng: 18.6775644 },
    { lat: 48.0996654, lng: 18.6771034 },
    { lat: 48.099612, lng: 18.6771166 },
    { lat: 48.099528, lng: 18.6770229 },
    { lat: 48.0993702, lng: 18.6766295 },
    { lat: 48.0990706, lng: 18.6762375 },
    { lat: 48.0987767, lng: 18.6762055 },
    { lat: 48.0985578, lng: 18.676077 },
    { lat: 48.0984052, lng: 18.6761747 },
    { lat: 48.0980595, lng: 18.6765825 },
    { lat: 48.0979638, lng: 18.677674 },
    { lat: 48.0982301, lng: 18.6789517 },
    { lat: 48.0983605, lng: 18.6790909 },
    { lat: 48.0981169, lng: 18.6796953 },
    { lat: 48.0976352, lng: 18.6806776 },
    { lat: 48.0976842, lng: 18.6807479 },
    { lat: 48.0981627, lng: 18.6808171 },
    { lat: 48.0982949, lng: 18.6810728 },
    { lat: 48.0992376, lng: 18.6822576 },
    { lat: 48.0999082, lng: 18.6829214 },
    { lat: 48.1001842, lng: 18.6833547 },
    { lat: 48.1007087, lng: 18.6837948 },
    { lat: 48.1014645, lng: 18.6851351 },
    { lat: 48.102047, lng: 18.6863784 },
    { lat: 48.1022599, lng: 18.6867061 },
    { lat: 48.1024688, lng: 18.6870049 },
    { lat: 48.102608, lng: 18.6871004 },
    { lat: 48.1027867, lng: 18.6871106 },
    { lat: 48.1029606, lng: 18.6872358 },
    { lat: 48.1041785, lng: 18.6889918 },
    { lat: 48.1042953, lng: 18.6893176 },
    { lat: 48.1044605, lng: 18.6905628 },
    { lat: 48.104419, lng: 18.6908815 },
    { lat: 48.1041815, lng: 18.6914121 },
    { lat: 48.1031844, lng: 18.692145 },
    { lat: 48.1027015, lng: 18.692878 },
    { lat: 48.1024852, lng: 18.69332 },
    { lat: 48.1024691, lng: 18.6935654 },
    { lat: 48.1027728, lng: 18.6934866 },
    { lat: 48.1032952, lng: 18.6938265 },
    { lat: 48.1036044, lng: 18.6939323 },
    { lat: 48.1036539, lng: 18.6940365 },
    { lat: 48.1038992, lng: 18.6941933 },
    { lat: 48.1040918, lng: 18.6941882 },
    { lat: 48.1039468, lng: 18.6947833 },
    { lat: 48.1038095, lng: 18.694792 },
    { lat: 48.1029633, lng: 18.6966482 },
    { lat: 48.1024708, lng: 18.6974528 },
    { lat: 48.1019827, lng: 18.6980237 },
    { lat: 48.1014304, lng: 18.6996047 },
    { lat: 48.1018182, lng: 18.7000832 },
    { lat: 48.1021751, lng: 18.7009719 },
    { lat: 48.1032683, lng: 18.7033927 },
    { lat: 48.1031643, lng: 18.7042683 },
    { lat: 48.1030945, lng: 18.7043761 },
    { lat: 48.1027886, lng: 18.7041806 },
    { lat: 48.1025703, lng: 18.7036027 },
    { lat: 48.1024088, lng: 18.7036841 },
    { lat: 48.1022783, lng: 18.7041323 },
    { lat: 48.1024276, lng: 18.7044948 },
    { lat: 48.1024552, lng: 18.7047593 },
    { lat: 48.10239, lng: 18.7048465 },
    { lat: 48.1019, lng: 18.7048551 },
    { lat: 48.1018579, lng: 18.7049766 },
    { lat: 48.1020153, lng: 18.7053021 },
    { lat: 48.1017271, lng: 18.7057106 },
    { lat: 48.1018829, lng: 18.7059661 },
    { lat: 48.1023784, lng: 18.706224 },
    { lat: 48.1035655, lng: 18.7066143 },
    { lat: 48.1035938, lng: 18.7066801 },
    { lat: 48.1015548, lng: 18.7082788 },
    { lat: 48.1011408, lng: 18.7085707 },
    { lat: 48.1009853, lng: 18.7086147 },
    { lat: 48.1002553, lng: 18.7091745 },
    { lat: 48.0997024, lng: 18.7098021 },
    { lat: 48.0996615, lng: 18.709964 },
  ],
  Sikenica: [
    { lat: 48.0525564, lng: 18.6948276 },
    { lat: 48.0525859, lng: 18.6947046 },
    { lat: 48.052574, lng: 18.6945273 },
    { lat: 48.0524941, lng: 18.6943821 },
    { lat: 48.0522007, lng: 18.6941264 },
    { lat: 48.0519128, lng: 18.6937436 },
    { lat: 48.0517176, lng: 18.693639 },
    { lat: 48.0514472, lng: 18.6936432 },
    { lat: 48.0512461, lng: 18.6938894 },
    { lat: 48.0512484, lng: 18.6942043 },
    { lat: 48.0511374, lng: 18.694784 },
    { lat: 48.0509783, lng: 18.6949889 },
    { lat: 48.050699, lng: 18.6951002 },
    { lat: 48.0498976, lng: 18.6950916 },
    { lat: 48.0498756, lng: 18.6953713 },
    { lat: 48.0499727, lng: 18.6956881 },
    { lat: 48.0498115, lng: 18.6961918 },
    { lat: 48.0494348, lng: 18.6964861 },
    { lat: 48.049346, lng: 18.6964305 },
    { lat: 48.0492676, lng: 18.6961463 },
    { lat: 48.0491024, lng: 18.6959056 },
    { lat: 48.0489681, lng: 18.695904 },
    { lat: 48.0485502, lng: 18.6961223 },
    { lat: 48.0483522, lng: 18.6965527 },
    { lat: 48.0484793, lng: 18.6967761 },
    { lat: 48.0485638, lng: 18.6970677 },
    { lat: 48.0485884, lng: 18.6975427 },
    { lat: 48.0484607, lng: 18.6980638 },
    { lat: 48.0482939, lng: 18.6982139 },
    { lat: 48.0479757, lng: 18.6989016 },
    { lat: 48.0476126, lng: 18.6990931 },
    { lat: 48.0475659, lng: 18.6991868 },
    { lat: 48.0475774, lng: 18.6992944 },
    { lat: 48.0477431, lng: 18.6995456 },
    { lat: 48.04795, lng: 18.7000347 },
    { lat: 48.0480194, lng: 18.7007176 },
    { lat: 48.0480026, lng: 18.7013903 },
    { lat: 48.0479609, lng: 18.701537 },
    { lat: 48.0478234, lng: 18.7018143 },
    { lat: 48.0475517, lng: 18.7021499 },
    { lat: 48.0473548, lng: 18.7022627 },
    { lat: 48.0466916, lng: 18.7024288 },
    { lat: 48.0463187, lng: 18.7021234 },
    { lat: 48.0458235, lng: 18.7015151 },
    { lat: 48.0455918, lng: 18.7013786 },
    { lat: 48.0449356, lng: 18.7013846 },
    { lat: 48.0447889, lng: 18.7014638 },
    { lat: 48.0444994, lng: 18.7017638 },
    { lat: 48.0445483, lng: 18.702588 },
    { lat: 48.0444045, lng: 18.702872 },
    { lat: 48.0442828, lng: 18.7029391 },
    { lat: 48.0442581, lng: 18.7032339 },
    { lat: 48.044194, lng: 18.7033614 },
    { lat: 48.0439555, lng: 18.7034798 },
    { lat: 48.0436845, lng: 18.7026322 },
    { lat: 48.043382, lng: 18.7022511 },
    { lat: 48.0431182, lng: 18.7017935 },
    { lat: 48.0428258, lng: 18.7015374 },
    { lat: 48.0426523, lng: 18.7016254 },
    { lat: 48.0422607, lng: 18.7013713 },
    { lat: 48.0421454, lng: 18.7013851 },
    { lat: 48.0420026, lng: 18.7014762 },
    { lat: 48.0418472, lng: 18.7017944 },
    { lat: 48.0420682, lng: 18.7024841 },
    { lat: 48.0419727, lng: 18.7028479 },
    { lat: 48.0419975, lng: 18.7033081 },
    { lat: 48.0419536, lng: 18.7034367 },
    { lat: 48.0415452, lng: 18.7037925 },
    { lat: 48.0411125, lng: 18.7043422 },
    { lat: 48.0406509, lng: 18.7044648 },
    { lat: 48.0403686, lng: 18.7039978 },
    { lat: 48.0403678, lng: 18.7037927 },
    { lat: 48.0402371, lng: 18.7036484 },
    { lat: 48.0397829, lng: 18.7034039 },
    { lat: 48.0395446, lng: 18.7033972 },
    { lat: 48.0394958, lng: 18.7034358 },
    { lat: 48.039382, lng: 18.7037933 },
    { lat: 48.0391808, lng: 18.7040556 },
    { lat: 48.038852, lng: 18.7047998 },
    { lat: 48.0385805, lng: 18.7052016 },
    { lat: 48.0384095, lng: 18.7052516 },
    { lat: 48.038098, lng: 18.7050408 },
    { lat: 48.0378863, lng: 18.7046468 },
    { lat: 48.0375137, lng: 18.7045965 },
    { lat: 48.0373378, lng: 18.704349 },
    { lat: 48.0370098, lng: 18.7044986 },
    { lat: 48.0369277, lng: 18.7046469 },
    { lat: 48.0362187, lng: 18.7051997 },
    { lat: 48.0357074, lng: 18.7053384 },
    { lat: 48.0352178, lng: 18.7056302 },
    { lat: 48.0350165, lng: 18.7059175 },
    { lat: 48.0350025, lng: 18.7065351 },
    { lat: 48.0349575, lng: 18.7067262 },
    { lat: 48.0348815, lng: 18.7068043 },
    { lat: 48.0344541, lng: 18.7068667 },
    { lat: 48.03416, lng: 18.7070189 },
    { lat: 48.0340399, lng: 18.7071763 },
    { lat: 48.0339317, lng: 18.7077227 },
    { lat: 48.0339508, lng: 18.708267 },
    { lat: 48.0339161, lng: 18.7087951 },
    { lat: 48.0338723, lng: 18.7089652 },
    { lat: 48.0336253, lng: 18.7092189 },
    { lat: 48.0336174, lng: 18.7094506 },
    { lat: 48.0336966, lng: 18.7098284 },
    { lat: 48.0336554, lng: 18.7099024 },
    { lat: 48.033297, lng: 18.7100316 },
    { lat: 48.0328467, lng: 18.7104049 },
    { lat: 48.0320784, lng: 18.7107388 },
    { lat: 48.0317211, lng: 18.7110335 },
    { lat: 48.0319653, lng: 18.7113827 },
    { lat: 48.0319912, lng: 18.7115702 },
    { lat: 48.0320232, lng: 18.7119991 },
    { lat: 48.0318278, lng: 18.712453 },
    { lat: 48.0319402, lng: 18.7131415 },
    { lat: 48.0322366, lng: 18.7135335 },
    { lat: 48.0322506, lng: 18.7138606 },
    { lat: 48.0323878, lng: 18.7141655 },
    { lat: 48.0325921, lng: 18.714318 },
    { lat: 48.0331205, lng: 18.7141147 },
    { lat: 48.0332195, lng: 18.7141311 },
    { lat: 48.0335277, lng: 18.7138638 },
    { lat: 48.0336676, lng: 18.7138238 },
    { lat: 48.0340797, lng: 18.7134138 },
    { lat: 48.0342578, lng: 18.7133495 },
    { lat: 48.0346663, lng: 18.7140034 },
    { lat: 48.0350275, lng: 18.7140314 },
    { lat: 48.0351312, lng: 18.7138573 },
    { lat: 48.0351593, lng: 18.7136689 },
    { lat: 48.0353338, lng: 18.7135385 },
    { lat: 48.0354408, lng: 18.7136202 },
    { lat: 48.0356553, lng: 18.7135625 },
    { lat: 48.03579, lng: 18.7134033 },
    { lat: 48.035846, lng: 18.7131426 },
    { lat: 48.0359796, lng: 18.713004 },
    { lat: 48.0370567, lng: 18.7132563 },
    { lat: 48.0371613, lng: 18.7134612 },
    { lat: 48.0371628, lng: 18.7135512 },
    { lat: 48.0373074, lng: 18.7137042 },
    { lat: 48.0376062, lng: 18.7138063 },
    { lat: 48.0374818, lng: 18.7159841 },
    { lat: 48.0374772, lng: 18.7170559 },
    { lat: 48.0372554, lng: 18.7208449 },
    { lat: 48.0376507, lng: 18.7220934 },
    { lat: 48.0379468, lng: 18.7232827 },
    { lat: 48.0380209, lng: 18.7244967 },
    { lat: 48.0383029, lng: 18.7255696 },
    { lat: 48.038556, lng: 18.7269268 },
    { lat: 48.0385385, lng: 18.7294397 },
    { lat: 48.0381494, lng: 18.7294205 },
    { lat: 48.0380096, lng: 18.7299862 },
    { lat: 48.0379352, lng: 18.7300324 },
    { lat: 48.037902, lng: 18.7314325 },
    { lat: 48.0379269, lng: 18.7336013 },
    { lat: 48.038116, lng: 18.7360163 },
    { lat: 48.0383836, lng: 18.7375195 },
    { lat: 48.038613, lng: 18.7394035 },
    { lat: 48.0386769, lng: 18.7406818 },
    { lat: 48.0388997, lng: 18.7423602 },
    { lat: 48.0391489, lng: 18.7436048 },
    { lat: 48.0393659, lng: 18.7450693 },
    { lat: 48.0393767, lng: 18.7451484 },
    { lat: 48.0413108, lng: 18.743202 },
    { lat: 48.0418178, lng: 18.7432784 },
    { lat: 48.0452418, lng: 18.7419487 },
    { lat: 48.0460407, lng: 18.7414738 },
    { lat: 48.0469279, lng: 18.7410416 },
    { lat: 48.0474016, lng: 18.7426471 },
    { lat: 48.0486945, lng: 18.7437742 },
    { lat: 48.0493588, lng: 18.743354 },
    { lat: 48.0502279, lng: 18.7443997 },
    { lat: 48.0513148, lng: 18.7433781 },
    { lat: 48.0518305, lng: 18.7431532 },
    { lat: 48.0523457, lng: 18.7438258 },
    { lat: 48.0530698, lng: 18.7445036 },
    { lat: 48.0541912, lng: 18.7452684 },
    { lat: 48.0548678, lng: 18.745034 },
    { lat: 48.0550772, lng: 18.7451529 },
    { lat: 48.0556346, lng: 18.7457081 },
    { lat: 48.0560253, lng: 18.7459497 },
    { lat: 48.0569003, lng: 18.7459906 },
    { lat: 48.0575003, lng: 18.7461184 },
    { lat: 48.0587398, lng: 18.7456306 },
    { lat: 48.0595201, lng: 18.7455678 },
    { lat: 48.0594764, lng: 18.7452148 },
    { lat: 48.0599702, lng: 18.7446414 },
    { lat: 48.060085, lng: 18.7446144 },
    { lat: 48.0604703, lng: 18.7447124 },
    { lat: 48.060506, lng: 18.7449168 },
    { lat: 48.06089, lng: 18.7453267 },
    { lat: 48.0609315, lng: 18.7454501 },
    { lat: 48.0608365, lng: 18.7455301 },
    { lat: 48.0608785, lng: 18.7461703 },
    { lat: 48.0610702, lng: 18.7465903 },
    { lat: 48.0611738, lng: 18.7471759 },
    { lat: 48.061614, lng: 18.748685 },
    { lat: 48.0623525, lng: 18.7503308 },
    { lat: 48.0629026, lng: 18.753199 },
    { lat: 48.0632795, lng: 18.7542793 },
    { lat: 48.0635299, lng: 18.7552755 },
    { lat: 48.0639503, lng: 18.7560545 },
    { lat: 48.0645463, lng: 18.7577117 },
    { lat: 48.0650223, lng: 18.7594691 },
    { lat: 48.0665098, lng: 18.7593868 },
    { lat: 48.0678035, lng: 18.7595782 },
    { lat: 48.0684578, lng: 18.7595419 },
    { lat: 48.0694099, lng: 18.7605826 },
    { lat: 48.0700939, lng: 18.7611203 },
    { lat: 48.0709085, lng: 18.761786 },
    { lat: 48.0719074, lng: 18.7632406 },
    { lat: 48.0720548, lng: 18.7634654 },
    { lat: 48.0721591, lng: 18.7634371 },
    { lat: 48.076133, lng: 18.7624533 },
    { lat: 48.0799393, lng: 18.7608032 },
    { lat: 48.0835239, lng: 18.7588875 },
    { lat: 48.0843961, lng: 18.7579643 },
    { lat: 48.0863223, lng: 18.7571149 },
    { lat: 48.0902011, lng: 18.7569116 },
    { lat: 48.0935016, lng: 18.7576031 },
    { lat: 48.0952049, lng: 18.7570996 },
    { lat: 48.0952123, lng: 18.7566045 },
    { lat: 48.0952514, lng: 18.7555592 },
    { lat: 48.0957278, lng: 18.7523759 },
    { lat: 48.0958182, lng: 18.7519972 },
    { lat: 48.095714, lng: 18.7516081 },
    { lat: 48.0958908, lng: 18.7513063 },
    { lat: 48.0960854, lng: 18.7505155 },
    { lat: 48.0962334, lng: 18.7503092 },
    { lat: 48.0962128, lng: 18.7500686 },
    { lat: 48.0962643, lng: 18.7499802 },
    { lat: 48.0962668, lng: 18.7497915 },
    { lat: 48.096193, lng: 18.7494659 },
    { lat: 48.0962212, lng: 18.7492551 },
    { lat: 48.0962689, lng: 18.7492187 },
    { lat: 48.0962451, lng: 18.7489637 },
    { lat: 48.0960986, lng: 18.7488587 },
    { lat: 48.0960511, lng: 18.7486668 },
    { lat: 48.0960468, lng: 18.7483131 },
    { lat: 48.0956124, lng: 18.7454105 },
    { lat: 48.0954888, lng: 18.7443221 },
    { lat: 48.0947194, lng: 18.7417392 },
    { lat: 48.0942745, lng: 18.7403398 },
    { lat: 48.0938227, lng: 18.7390404 },
    { lat: 48.0935527, lng: 18.7379324 },
    { lat: 48.0911523, lng: 18.7331763 },
    { lat: 48.0893833, lng: 18.7294346 },
    { lat: 48.0908307, lng: 18.7268333 },
    { lat: 48.089937, lng: 18.7247176 },
    { lat: 48.0897412, lng: 18.7229991 },
    { lat: 48.0897524, lng: 18.7222206 },
    { lat: 48.0896882, lng: 18.7216131 },
    { lat: 48.0894588, lng: 18.7203399 },
    { lat: 48.0890951, lng: 18.7194676 },
    { lat: 48.0887935, lng: 18.7190172 },
    { lat: 48.0887508, lng: 18.7176971 },
    { lat: 48.0889757, lng: 18.7160867 },
    { lat: 48.0889591, lng: 18.7156506 },
    { lat: 48.0890114, lng: 18.7148584 },
    { lat: 48.0890998, lng: 18.7143617 },
    { lat: 48.0891902, lng: 18.7145524 },
    { lat: 48.0893293, lng: 18.7146042 },
    { lat: 48.0894711, lng: 18.714432 },
    { lat: 48.0896464, lng: 18.7139165 },
    { lat: 48.089123, lng: 18.7126922 },
    { lat: 48.0891191, lng: 18.7126415 },
    { lat: 48.0897931, lng: 18.7127613 },
    { lat: 48.0900255, lng: 18.7123391 },
    { lat: 48.0891722, lng: 18.7099963 },
    { lat: 48.089032, lng: 18.7092998 },
    { lat: 48.0889146, lng: 18.7090235 },
    { lat: 48.0885961, lng: 18.7077307 },
    { lat: 48.0883777, lng: 18.7065646 },
    { lat: 48.0870036, lng: 18.7049188 },
    { lat: 48.085944, lng: 18.7032728 },
    { lat: 48.0849651, lng: 18.7019732 },
    { lat: 48.0839614, lng: 18.7008226 },
    { lat: 48.0836079, lng: 18.7005422 },
    { lat: 48.0835418, lng: 18.7006975 },
    { lat: 48.0835995, lng: 18.7013308 },
    { lat: 48.0835375, lng: 18.7016447 },
    { lat: 48.0835709, lng: 18.7018231 },
    { lat: 48.0837607, lng: 18.7020818 },
    { lat: 48.0837054, lng: 18.7022482 },
    { lat: 48.0834986, lng: 18.7024282 },
    { lat: 48.0832322, lng: 18.7024449 },
    { lat: 48.0831808, lng: 18.7025791 },
    { lat: 48.0835197, lng: 18.7027604 },
    { lat: 48.0836039, lng: 18.7030244 },
    { lat: 48.083609, lng: 18.7033483 },
    { lat: 48.0832685, lng: 18.7033714 },
    { lat: 48.0831819, lng: 18.7033165 },
    { lat: 48.0830379, lng: 18.7030082 },
    { lat: 48.0827348, lng: 18.7032879 },
    { lat: 48.0824344, lng: 18.7033619 },
    { lat: 48.0822709, lng: 18.7033187 },
    { lat: 48.0822527, lng: 18.7031301 },
    { lat: 48.0821995, lng: 18.7031023 },
    { lat: 48.0818755, lng: 18.7035382 },
    { lat: 48.0817613, lng: 18.7035769 },
    { lat: 48.0808685, lng: 18.7013416 },
    { lat: 48.0806305, lng: 18.7005416 },
    { lat: 48.0798995, lng: 18.6988858 },
    { lat: 48.0789455, lng: 18.6964368 },
    { lat: 48.0787488, lng: 18.6957149 },
    { lat: 48.0784257, lng: 18.6939863 },
    { lat: 48.0784318, lng: 18.6933888 },
    { lat: 48.0778964, lng: 18.6919482 },
    { lat: 48.0775022, lng: 18.691246 },
    { lat: 48.0767792, lng: 18.6912456 },
    { lat: 48.0751805, lng: 18.6887872 },
    { lat: 48.0749005, lng: 18.6879864 },
    { lat: 48.0744194, lng: 18.688163 },
    { lat: 48.0738593, lng: 18.6885899 },
    { lat: 48.0731746, lng: 18.6879407 },
    { lat: 48.0724444, lng: 18.6869697 },
    { lat: 48.0717653, lng: 18.6865689 },
    { lat: 48.0712663, lng: 18.6858473 },
    { lat: 48.0710659, lng: 18.6859627 },
    { lat: 48.070844, lng: 18.6857139 },
    { lat: 48.0704938, lng: 18.6856439 },
    { lat: 48.0699631, lng: 18.6851691 },
    { lat: 48.0697851, lng: 18.6851119 },
    { lat: 48.0696687, lng: 18.6847944 },
    { lat: 48.0688964, lng: 18.6840243 },
    { lat: 48.0684287, lng: 18.6833618 },
    { lat: 48.0678146, lng: 18.6834359 },
    { lat: 48.0675617, lng: 18.6838298 },
    { lat: 48.0674022, lng: 18.6840718 },
    { lat: 48.0672353, lng: 18.6867248 },
    { lat: 48.0670742, lng: 18.687358 },
    { lat: 48.0670914, lng: 18.6876328 },
    { lat: 48.0669644, lng: 18.6889431 },
    { lat: 48.0667145, lng: 18.6886156 },
    { lat: 48.0663038, lng: 18.6883814 },
    { lat: 48.0661195, lng: 18.6883516 },
    { lat: 48.0658769, lng: 18.6884326 },
    { lat: 48.0656656, lng: 18.6884266 },
    { lat: 48.0652995, lng: 18.6886031 },
    { lat: 48.0649461, lng: 18.6886145 },
    { lat: 48.0646473, lng: 18.6889518 },
    { lat: 48.0645503, lng: 18.6891813 },
    { lat: 48.0645129, lng: 18.6894739 },
    { lat: 48.0643153, lng: 18.6897595 },
    { lat: 48.0642766, lng: 18.6899444 },
    { lat: 48.0642139, lng: 18.6900125 },
    { lat: 48.0640592, lng: 18.6900928 },
    { lat: 48.0633668, lng: 18.6900334 },
    { lat: 48.0633074, lng: 18.69008 },
    { lat: 48.0631875, lng: 18.690429 },
    { lat: 48.0630243, lng: 18.6904362 },
    { lat: 48.0629983, lng: 18.6904147 },
    { lat: 48.0627716, lng: 18.6903125 },
    { lat: 48.0626245, lng: 18.6898644 },
    { lat: 48.0624492, lng: 18.6897296 },
    { lat: 48.0622697, lng: 18.6894549 },
    { lat: 48.0621852, lng: 18.6892253 },
    { lat: 48.0620818, lng: 18.6891031 },
    { lat: 48.0619789, lng: 18.6890805 },
    { lat: 48.0617945, lng: 18.689299 },
    { lat: 48.0617923, lng: 18.6894689 },
    { lat: 48.0619274, lng: 18.689967 },
    { lat: 48.0619136, lng: 18.6903371 },
    { lat: 48.0616633, lng: 18.6905477 },
    { lat: 48.0614522, lng: 18.6905864 },
    { lat: 48.0611861, lng: 18.6902929 },
    { lat: 48.0607972, lng: 18.6903395 },
    { lat: 48.0606162, lng: 18.6901645 },
    { lat: 48.0605203, lng: 18.690144 },
    { lat: 48.0602838, lng: 18.6902933 },
    { lat: 48.0601656, lng: 18.6908243 },
    { lat: 48.0598679, lng: 18.6915975 },
    { lat: 48.0598679, lng: 18.6917858 },
    { lat: 48.0599632, lng: 18.6920896 },
    { lat: 48.0599514, lng: 18.6924971 },
    { lat: 48.0601627, lng: 18.6929012 },
    { lat: 48.0603532, lng: 18.6929838 },
    { lat: 48.0604911, lng: 18.6934689 },
    { lat: 48.0604606, lng: 18.6936584 },
    { lat: 48.0602317, lng: 18.6938261 },
    { lat: 48.0601487, lng: 18.6940459 },
    { lat: 48.0601303, lng: 18.6942071 },
    { lat: 48.0602004, lng: 18.6946048 },
    { lat: 48.0599746, lng: 18.6949594 },
    { lat: 48.0598014, lng: 18.695051 },
    { lat: 48.0595548, lng: 18.6950591 },
    { lat: 48.0593524, lng: 18.6949434 },
    { lat: 48.0592511, lng: 18.6949499 },
    { lat: 48.0590116, lng: 18.6950435 },
    { lat: 48.0588224, lng: 18.695388 },
    { lat: 48.0585516, lng: 18.6955294 },
    { lat: 48.0584162, lng: 18.6954864 },
    { lat: 48.0580657, lng: 18.6949901 },
    { lat: 48.0576701, lng: 18.6947227 },
    { lat: 48.0573252, lng: 18.6946198 },
    { lat: 48.057011, lng: 18.6946533 },
    { lat: 48.0565548, lng: 18.6944552 },
    { lat: 48.0563282, lng: 18.6944351 },
    { lat: 48.0560808, lng: 18.6946156 },
    { lat: 48.0559572, lng: 18.6948511 },
    { lat: 48.0559178, lng: 18.6952313 },
    { lat: 48.0558462, lng: 18.6953664 },
    { lat: 48.0556972, lng: 18.6954083 },
    { lat: 48.0555804, lng: 18.6953123 },
    { lat: 48.0555123, lng: 18.6951357 },
    { lat: 48.0555832, lng: 18.6947789 },
    { lat: 48.0555466, lng: 18.6946232 },
    { lat: 48.0554484, lng: 18.6944746 },
    { lat: 48.0552868, lng: 18.6944066 },
    { lat: 48.0550996, lng: 18.6941777 },
    { lat: 48.0549229, lng: 18.6937007 },
    { lat: 48.0547832, lng: 18.6936001 },
    { lat: 48.0545945, lng: 18.6936995 },
    { lat: 48.0544616, lng: 18.6938907 },
    { lat: 48.0543564, lng: 18.6941747 },
    { lat: 48.0542876, lng: 18.6946568 },
    { lat: 48.0539383, lng: 18.6950434 },
    { lat: 48.0537265, lng: 18.6954428 },
    { lat: 48.0533363, lng: 18.6957183 },
    { lat: 48.053084, lng: 18.6959749 },
    { lat: 48.0528191, lng: 18.6963968 },
    { lat: 48.0527023, lng: 18.6964041 },
    { lat: 48.0523742, lng: 18.6963021 },
    { lat: 48.052261, lng: 18.6961891 },
    { lat: 48.0521948, lng: 18.6957148 },
    { lat: 48.0522678, lng: 18.6953715 },
    { lat: 48.0521773, lng: 18.6949904 },
    { lat: 48.0521884, lng: 18.694841 },
    { lat: 48.0523434, lng: 18.6948309 },
    { lat: 48.0524735, lng: 18.6949429 },
    { lat: 48.0525564, lng: 18.6948276 },
  ],
  HornéSemerovce: [
    { lat: 48.1165259, lng: 18.8908397 },
    { lat: 48.1174624, lng: 18.8910499 },
    { lat: 48.1177118, lng: 18.8912249 },
    { lat: 48.1182253, lng: 18.8914289 },
    { lat: 48.1191471, lng: 18.8914615 },
    { lat: 48.1199264, lng: 18.8913975 },
    { lat: 48.120535, lng: 18.8917824 },
    { lat: 48.1237544, lng: 18.8914127 },
    { lat: 48.1255155, lng: 18.891625 },
    { lat: 48.1262921, lng: 18.8910752 },
    { lat: 48.1283327, lng: 18.89053 },
    { lat: 48.1287468, lng: 18.8904911 },
    { lat: 48.127994, lng: 18.8860586 },
    { lat: 48.1291554, lng: 18.8856472 },
    { lat: 48.1296912, lng: 18.8847187 },
    { lat: 48.1300349, lng: 18.8839385 },
    { lat: 48.1304437, lng: 18.8843149 },
    { lat: 48.1306671, lng: 18.8843616 },
    { lat: 48.1308656, lng: 18.8842979 },
    { lat: 48.1309262, lng: 18.884251 },
    { lat: 48.1309309, lng: 18.8841237 },
    { lat: 48.1307992, lng: 18.8838221 },
    { lat: 48.1309241, lng: 18.8836663 },
    { lat: 48.1311675, lng: 18.8836846 },
    { lat: 48.1312895, lng: 18.8835589 },
    { lat: 48.1313808, lng: 18.8830615 },
    { lat: 48.1314793, lng: 18.8828753 },
    { lat: 48.132, lng: 18.8826739 },
    { lat: 48.1321675, lng: 18.8824927 },
    { lat: 48.1334093, lng: 18.8819596 },
    { lat: 48.1389073, lng: 18.8791821 },
    { lat: 48.1396079, lng: 18.8794688 },
    { lat: 48.1401557, lng: 18.8798551 },
    { lat: 48.1403855, lng: 18.8799145 },
    { lat: 48.1410244, lng: 18.8802497 },
    { lat: 48.1414252, lng: 18.8803682 },
    { lat: 48.141891, lng: 18.880737 },
    { lat: 48.1419145, lng: 18.8807565 },
    { lat: 48.1432506, lng: 18.8790866 },
    { lat: 48.1427235, lng: 18.8761615 },
    { lat: 48.1421391, lng: 18.873368 },
    { lat: 48.1426965, lng: 18.8732007 },
    { lat: 48.1419805, lng: 18.8678707 },
    { lat: 48.1414906, lng: 18.8638052 },
    { lat: 48.1392533, lng: 18.8583725 },
    { lat: 48.1393382, lng: 18.857756 },
    { lat: 48.1393118, lng: 18.8572507 },
    { lat: 48.1390959, lng: 18.8565077 },
    { lat: 48.1387663, lng: 18.8562246 },
    { lat: 48.1387579, lng: 18.855373 },
    { lat: 48.1388319, lng: 18.855041 },
    { lat: 48.1391131, lng: 18.8546198 },
    { lat: 48.1400841, lng: 18.8502283 },
    { lat: 48.140558, lng: 18.8493343 },
    { lat: 48.1408508, lng: 18.8484418 },
    { lat: 48.1420046, lng: 18.8462088 },
    { lat: 48.1425823, lng: 18.8459159 },
    { lat: 48.1436951, lng: 18.8434835 },
    { lat: 48.1439855, lng: 18.8431046 },
    { lat: 48.1441347, lng: 18.8430034 },
    { lat: 48.1441997, lng: 18.8428238 },
    { lat: 48.1447335, lng: 18.8421247 },
    { lat: 48.1447506, lng: 18.841979 },
    { lat: 48.1456911, lng: 18.8405387 },
    { lat: 48.1463801, lng: 18.8392033 },
    { lat: 48.1467424, lng: 18.837836 },
    { lat: 48.1467262, lng: 18.8366685 },
    { lat: 48.1466843, lng: 18.8364972 },
    { lat: 48.1465318, lng: 18.8362753 },
    { lat: 48.1457518, lng: 18.8360236 },
    { lat: 48.1453645, lng: 18.8360383 },
    { lat: 48.1451538, lng: 18.8358451 },
    { lat: 48.1449623, lng: 18.835529 },
    { lat: 48.1449436, lng: 18.8354962 },
    { lat: 48.1449375, lng: 18.8354849 },
    { lat: 48.1446248, lng: 18.834944 },
    { lat: 48.1439626, lng: 18.8341015 },
    { lat: 48.1433966, lng: 18.833651 },
    { lat: 48.1431479, lng: 18.8332357 },
    { lat: 48.1424574, lng: 18.8315764 },
    { lat: 48.1412898, lng: 18.8284359 },
    { lat: 48.1409437, lng: 18.827686 },
    { lat: 48.1400333, lng: 18.8261795 },
    { lat: 48.140051, lng: 18.8261304 },
    { lat: 48.1394944, lng: 18.8252609 },
    { lat: 48.138495, lng: 18.8242048 },
    { lat: 48.1373575, lng: 18.8231653 },
    { lat: 48.1368283, lng: 18.8228187 },
    { lat: 48.1350707, lng: 18.8203204 },
    { lat: 48.1346176, lng: 18.8194674 },
    { lat: 48.1339523, lng: 18.8179094 },
    { lat: 48.131899, lng: 18.8197303 },
    { lat: 48.1306848, lng: 18.8204417 },
    { lat: 48.130648, lng: 18.8204528 },
    { lat: 48.1314564, lng: 18.827948 },
    { lat: 48.1316491, lng: 18.8336245 },
    { lat: 48.1300462, lng: 18.8464343 },
    { lat: 48.1293039, lng: 18.8464104 },
    { lat: 48.1272567, lng: 18.8457796 },
    { lat: 48.1224197, lng: 18.8489064 },
    { lat: 48.1224586, lng: 18.8500708 },
    { lat: 48.1225056, lng: 18.851236 },
    { lat: 48.1225375, lng: 18.8535278 },
    { lat: 48.1224629, lng: 18.8546039 },
    { lat: 48.1223007, lng: 18.8557513 },
    { lat: 48.1217131, lng: 18.8586131 },
    { lat: 48.1215514, lng: 18.8590242 },
    { lat: 48.1214077, lng: 18.8593927 },
    { lat: 48.1210969, lng: 18.8600629 },
    { lat: 48.1210766, lng: 18.8601207 },
    { lat: 48.1203479, lng: 18.8617971 },
    { lat: 48.1195426, lng: 18.863392 },
    { lat: 48.1167694, lng: 18.8677826 },
    { lat: 48.1160558, lng: 18.8690353 },
    { lat: 48.1158335, lng: 18.8696607 },
    { lat: 48.1152705, lng: 18.8723718 },
    { lat: 48.1149802, lng: 18.8732518 },
    { lat: 48.1137121, lng: 18.8765831 },
    { lat: 48.113519, lng: 18.8766641 },
    { lat: 48.1121085, lng: 18.8794977 },
    { lat: 48.1117776, lng: 18.8801618 },
    { lat: 48.1119057, lng: 18.8802157 },
    { lat: 48.1122198, lng: 18.8809603 },
    { lat: 48.1121711, lng: 18.8811724 },
    { lat: 48.111908, lng: 18.8812122 },
    { lat: 48.1117506, lng: 18.881306 },
    { lat: 48.1116631, lng: 18.8816179 },
    { lat: 48.1119208, lng: 18.8821902 },
    { lat: 48.1150877, lng: 18.8830664 },
    { lat: 48.115619, lng: 18.882788 },
    { lat: 48.1151537, lng: 18.8843104 },
    { lat: 48.1153944, lng: 18.8843847 },
    { lat: 48.1156652, lng: 18.8843667 },
    { lat: 48.11566, lng: 18.8853052 },
    { lat: 48.1159425, lng: 18.8853398 },
    { lat: 48.1165384, lng: 18.8852394 },
    { lat: 48.1166993, lng: 18.8861866 },
    { lat: 48.1168824, lng: 18.8869466 },
    { lat: 48.1168726, lng: 18.887585 },
    { lat: 48.1165447, lng: 18.8906194 },
    { lat: 48.1165259, lng: 18.8908397 },
  ],
  NováDedina: [
    { lat: 48.2785374, lng: 18.6683239 },
    { lat: 48.2787599, lng: 18.6687042 },
    { lat: 48.2789397, lng: 18.6688386 },
    { lat: 48.2790584, lng: 18.6688434 },
    { lat: 48.2798869, lng: 18.6695031 },
    { lat: 48.2809322, lng: 18.6699588 },
    { lat: 48.2815327, lng: 18.6703037 },
    { lat: 48.2821714, lng: 18.670815 },
    { lat: 48.2824603, lng: 18.6709444 },
    { lat: 48.2838005, lng: 18.6710522 },
    { lat: 48.2852802, lng: 18.6714278 },
    { lat: 48.2854555, lng: 18.671435 },
    { lat: 48.2854946, lng: 18.6713446 },
    { lat: 48.2859417, lng: 18.671458 },
    { lat: 48.2889625, lng: 18.6728689 },
    { lat: 48.2891228, lng: 18.6730797 },
    { lat: 48.2893836, lng: 18.6731693 },
    { lat: 48.2895731, lng: 18.673757 },
    { lat: 48.2906608, lng: 18.6737445 },
    { lat: 48.2912872, lng: 18.6744221 },
    { lat: 48.2921391, lng: 18.67558 },
    { lat: 48.2933029, lng: 18.6768586 },
    { lat: 48.2937095, lng: 18.6772646 },
    { lat: 48.2941312, lng: 18.6775175 },
    { lat: 48.2948082, lng: 18.6790534 },
    { lat: 48.2953074, lng: 18.6811416 },
    { lat: 48.2974242, lng: 18.6757897 },
    { lat: 48.3019407, lng: 18.6709127 },
    { lat: 48.3033906, lng: 18.6692268 },
    { lat: 48.3035724, lng: 18.6693585 },
    { lat: 48.3055519, lng: 18.6667402 },
    { lat: 48.3054927, lng: 18.6665238 },
    { lat: 48.3060083, lng: 18.6650776 },
    { lat: 48.3061384, lng: 18.6649184 },
    { lat: 48.306621, lng: 18.6647073 },
    { lat: 48.3074854, lng: 18.6644676 },
    { lat: 48.3079179, lng: 18.6644755 },
    { lat: 48.3083108, lng: 18.6639985 },
    { lat: 48.3082782, lng: 18.6628726 },
    { lat: 48.3081733, lng: 18.6623068 },
    { lat: 48.3083057, lng: 18.6611634 },
    { lat: 48.3086382, lng: 18.6606806 },
    { lat: 48.3100706, lng: 18.6602403 },
    { lat: 48.3106907, lng: 18.6604227 },
    { lat: 48.3116139, lng: 18.6598276 },
    { lat: 48.3119039, lng: 18.6595384 },
    { lat: 48.3123697, lng: 18.6593923 },
    { lat: 48.3130747, lng: 18.6589745 },
    { lat: 48.3133908, lng: 18.6587052 },
    { lat: 48.3145075, lng: 18.6581429 },
    { lat: 48.3157355, lng: 18.6572786 },
    { lat: 48.3159251, lng: 18.6572613 },
    { lat: 48.3167152, lng: 18.656477 },
    { lat: 48.3170044, lng: 18.6557534 },
    { lat: 48.3171474, lng: 18.655521 },
    { lat: 48.317844, lng: 18.6551144 },
    { lat: 48.3180942, lng: 18.6550295 },
    { lat: 48.3184229, lng: 18.6549754 },
    { lat: 48.3186618, lng: 18.6550109 },
    { lat: 48.3190549, lng: 18.6552967 },
    { lat: 48.3208964, lng: 18.6559543 },
    { lat: 48.323278, lng: 18.6569519 },
    { lat: 48.3237023, lng: 18.6572074 },
    { lat: 48.324533, lng: 18.657259 },
    { lat: 48.3252146, lng: 18.6571406 },
    { lat: 48.3258992, lng: 18.6565118 },
    { lat: 48.3266367, lng: 18.6554222 },
    { lat: 48.3270987, lng: 18.654014 },
    { lat: 48.3272182, lng: 18.6538021 },
    { lat: 48.3273141, lng: 18.6532797 },
    { lat: 48.3271829, lng: 18.6532312 },
    { lat: 48.3270654, lng: 18.6525174 },
    { lat: 48.3268057, lng: 18.6521764 },
    { lat: 48.3267641, lng: 18.6518264 },
    { lat: 48.3263765, lng: 18.6514977 },
    { lat: 48.3262902, lng: 18.6511329 },
    { lat: 48.3260642, lng: 18.6508126 },
    { lat: 48.325854, lng: 18.6503434 },
    { lat: 48.3257317, lng: 18.6502313 },
    { lat: 48.3253994, lng: 18.6502575 },
    { lat: 48.3252652, lng: 18.6501147 },
    { lat: 48.3251809, lng: 18.6495944 },
    { lat: 48.3250791, lng: 18.6493597 },
    { lat: 48.3250671, lng: 18.6491382 },
    { lat: 48.3251732, lng: 18.6485994 },
    { lat: 48.3251512, lng: 18.6485025 },
    { lat: 48.3250708, lng: 18.6484367 },
    { lat: 48.3251219, lng: 18.6477706 },
    { lat: 48.3250932, lng: 18.6477213 },
    { lat: 48.3247637, lng: 18.6477 },
    { lat: 48.3245044, lng: 18.6470511 },
    { lat: 48.3244802, lng: 18.6467077 },
    { lat: 48.3241887, lng: 18.6465309 },
    { lat: 48.3236989, lng: 18.6457961 },
    { lat: 48.3236393, lng: 18.6455723 },
    { lat: 48.3231898, lng: 18.6454139 },
    { lat: 48.3231555, lng: 18.6453224 },
    { lat: 48.323153, lng: 18.6451923 },
    { lat: 48.3232288, lng: 18.6449864 },
    { lat: 48.3234074, lng: 18.6449098 },
    { lat: 48.3236326, lng: 18.6442806 },
    { lat: 48.3238453, lng: 18.6438954 },
    { lat: 48.3239462, lng: 18.6428408 },
    { lat: 48.3239588, lng: 18.6426323 },
    { lat: 48.323965, lng: 18.6425412 },
    { lat: 48.3238632, lng: 18.6423452 },
    { lat: 48.3238572, lng: 18.6421737 },
    { lat: 48.3238649, lng: 18.6418127 },
    { lat: 48.323718, lng: 18.6416222 },
    { lat: 48.3235688, lng: 18.6407001 },
    { lat: 48.3236643, lng: 18.6402107 },
    { lat: 48.3238116, lng: 18.6400808 },
    { lat: 48.3238454, lng: 18.6399746 },
    { lat: 48.3238212, lng: 18.6398379 },
    { lat: 48.3235874, lng: 18.6394177 },
    { lat: 48.3235504, lng: 18.6390043 },
    { lat: 48.3233125, lng: 18.6381508 },
    { lat: 48.3233118, lng: 18.6378447 },
    { lat: 48.3232761, lng: 18.637748 },
    { lat: 48.3231492, lng: 18.6376515 },
    { lat: 48.3227248, lng: 18.6376503 },
    { lat: 48.3226949, lng: 18.6375335 },
    { lat: 48.3225766, lng: 18.6374302 },
    { lat: 48.3224651, lng: 18.6371432 },
    { lat: 48.3225016, lng: 18.6365643 },
    { lat: 48.3227519, lng: 18.636149 },
    { lat: 48.3228786, lng: 18.6360489 },
    { lat: 48.3229833, lng: 18.6352763 },
    { lat: 48.3228646, lng: 18.6349399 },
    { lat: 48.3226288, lng: 18.6347495 },
    { lat: 48.3225355, lng: 18.6346096 },
    { lat: 48.3225081, lng: 18.6344361 },
    { lat: 48.322521, lng: 18.6341538 },
    { lat: 48.3227035, lng: 18.6336479 },
    { lat: 48.3227019, lng: 18.6328514 },
    { lat: 48.322562, lng: 18.6327419 },
    { lat: 48.3223699, lng: 18.6323418 },
    { lat: 48.3222425, lng: 18.632302 },
    { lat: 48.3221305, lng: 18.6321278 },
    { lat: 48.3219946, lng: 18.6320547 },
    { lat: 48.3218491, lng: 18.6321177 },
    { lat: 48.3214535, lng: 18.632083 },
    { lat: 48.3212338, lng: 18.632356 },
    { lat: 48.3209278, lng: 18.6329114 },
    { lat: 48.3208135, lng: 18.6329877 },
    { lat: 48.3205852, lng: 18.6328971 },
    { lat: 48.32014, lng: 18.6325498 },
    { lat: 48.3200957, lng: 18.6324602 },
    { lat: 48.3198633, lng: 18.6324587 },
    { lat: 48.3195866, lng: 18.6322461 },
    { lat: 48.3194056, lng: 18.6319457 },
    { lat: 48.3191986, lng: 18.6319723 },
    { lat: 48.3191091, lng: 18.6320711 },
    { lat: 48.3189245, lng: 18.6316818 },
    { lat: 48.3186162, lng: 18.6312781 },
    { lat: 48.3184646, lng: 18.6312948 },
    { lat: 48.3182665, lng: 18.6314462 },
    { lat: 48.3180485, lng: 18.6314401 },
    { lat: 48.3177896, lng: 18.6312824 },
    { lat: 48.3177277, lng: 18.6313076 },
    { lat: 48.3176454, lng: 18.6314123 },
    { lat: 48.3176046, lng: 18.6316586 },
    { lat: 48.3172792, lng: 18.6319824 },
    { lat: 48.3171501, lng: 18.6320335 },
    { lat: 48.3169672, lng: 18.6318637 },
    { lat: 48.3166677, lng: 18.631728 },
    { lat: 48.3164628, lng: 18.6314843 },
    { lat: 48.315921, lng: 18.6310825 },
    { lat: 48.3157664, lng: 18.6309157 },
    { lat: 48.3155873, lng: 18.6302933 },
    { lat: 48.3154945, lng: 18.6301632 },
    { lat: 48.3150502, lng: 18.6298945 },
    { lat: 48.3149266, lng: 18.629676 },
    { lat: 48.3146455, lng: 18.6294615 },
    { lat: 48.3144704, lng: 18.6294566 },
    { lat: 48.3142338, lng: 18.6292955 },
    { lat: 48.3141032, lng: 18.629175 },
    { lat: 48.3139768, lng: 18.6287744 },
    { lat: 48.3133872, lng: 18.6286166 },
    { lat: 48.3126683, lng: 18.6280469 },
    { lat: 48.3123981, lng: 18.6276844 },
    { lat: 48.3117486, lng: 18.6264968 },
    { lat: 48.3115017, lng: 18.6265205 },
    { lat: 48.3112997, lng: 18.626623 },
    { lat: 48.3110412, lng: 18.6265812 },
    { lat: 48.3104269, lng: 18.6263364 },
    { lat: 48.3101456, lng: 18.6264324 },
    { lat: 48.309446, lng: 18.6260527 },
    { lat: 48.3092041, lng: 18.6260425 },
    { lat: 48.3087724, lng: 18.626279 },
    { lat: 48.3085189, lng: 18.6260941 },
    { lat: 48.3084155, lng: 18.6259353 },
    { lat: 48.308311, lng: 18.6259214 },
    { lat: 48.3080102, lng: 18.6262638 },
    { lat: 48.3078197, lng: 18.6261595 },
    { lat: 48.3075748, lng: 18.6255562 },
    { lat: 48.3073529, lng: 18.625518 },
    { lat: 48.3071708, lng: 18.6256345 },
    { lat: 48.3069629, lng: 18.6258763 },
    { lat: 48.3064376, lng: 18.6259034 },
    { lat: 48.306254, lng: 18.6258479 },
    { lat: 48.3060656, lng: 18.6256781 },
    { lat: 48.3059006, lng: 18.6254027 },
    { lat: 48.3058076, lng: 18.6253914 },
    { lat: 48.3057453, lng: 18.6254507 },
    { lat: 48.3057045, lng: 18.6256743 },
    { lat: 48.3055855, lng: 18.625834 },
    { lat: 48.3053668, lng: 18.6257328 },
    { lat: 48.3052454, lng: 18.6254903 },
    { lat: 48.3050564, lng: 18.6253072 },
    { lat: 48.3049614, lng: 18.6253035 },
    { lat: 48.3046403, lng: 18.6254592 },
    { lat: 48.3041208, lng: 18.6253627 },
    { lat: 48.3038654, lng: 18.6255398 },
    { lat: 48.3035974, lng: 18.6259225 },
    { lat: 48.3033206, lng: 18.626162 },
    { lat: 48.3024713, lng: 18.62654 },
    { lat: 48.3019923, lng: 18.6263812 },
    { lat: 48.3015957, lng: 18.6265939 },
    { lat: 48.3013982, lng: 18.6265315 },
    { lat: 48.3007259, lng: 18.6271133 },
    { lat: 48.3006281, lng: 18.6272626 },
    { lat: 48.3005162, lng: 18.6275926 },
    { lat: 48.2997523, lng: 18.6267543 },
    { lat: 48.2996426, lng: 18.6265024 },
    { lat: 48.2994869, lng: 18.625793 },
    { lat: 48.2990537, lng: 18.6259985 },
    { lat: 48.2984597, lng: 18.6264637 },
    { lat: 48.2983269, lng: 18.6265124 },
    { lat: 48.2978839, lng: 18.6256663 },
    { lat: 48.2975648, lng: 18.6258522 },
    { lat: 48.2967655, lng: 18.6238458 },
    { lat: 48.2964101, lng: 18.6239026 },
    { lat: 48.2963235, lng: 18.6237399 },
    { lat: 48.2960315, lng: 18.6224748 },
    { lat: 48.2951228, lng: 18.6227567 },
    { lat: 48.2948656, lng: 18.6218192 },
    { lat: 48.2947484, lng: 18.6206788 },
    { lat: 48.2923757, lng: 18.6219552 },
    { lat: 48.2919543, lng: 18.6204899 },
    { lat: 48.2914427, lng: 18.6207655 },
    { lat: 48.2908611, lng: 18.6209639 },
    { lat: 48.2908658, lng: 18.6207353 },
    { lat: 48.2890515, lng: 18.6212458 },
    { lat: 48.2880883, lng: 18.6214538 },
    { lat: 48.2879406, lng: 18.6208315 },
    { lat: 48.2873116, lng: 18.6209833 },
    { lat: 48.2858164, lng: 18.6208652 },
    { lat: 48.2857788, lng: 18.620591 },
    { lat: 48.2855754, lng: 18.6202539 },
    { lat: 48.2848988, lng: 18.6199635 },
    { lat: 48.2846286, lng: 18.6199327 },
    { lat: 48.2843652, lng: 18.6200041 },
    { lat: 48.2837803, lng: 18.6203668 },
    { lat: 48.2831122, lng: 18.620609 },
    { lat: 48.282899, lng: 18.6200475 },
    { lat: 48.2827737, lng: 18.6197983 },
    { lat: 48.2827006, lng: 18.6197796 },
    { lat: 48.2822503, lng: 18.6198189 },
    { lat: 48.2816485, lng: 18.6200133 },
    { lat: 48.2806343, lng: 18.6206097 },
    { lat: 48.2791959, lng: 18.6211256 },
    { lat: 48.2778622, lng: 18.6217623 },
    { lat: 48.2768574, lng: 18.6147266 },
    { lat: 48.2767998, lng: 18.6144354 },
    { lat: 48.2766551, lng: 18.6141167 },
    { lat: 48.2766052, lng: 18.6138817 },
    { lat: 48.2765687, lng: 18.613815 },
    { lat: 48.2706464, lng: 18.6160842 },
    { lat: 48.2705376, lng: 18.6160568 },
    { lat: 48.2675943, lng: 18.6166706 },
    { lat: 48.266626, lng: 18.6170332 },
    { lat: 48.2662008, lng: 18.6154768 },
    { lat: 48.2659897, lng: 18.6150586 },
    { lat: 48.2643066, lng: 18.6161326 },
    { lat: 48.2627738, lng: 18.6170083 },
    { lat: 48.2626729, lng: 18.616895 },
    { lat: 48.2626117, lng: 18.6169081 },
    { lat: 48.2624653, lng: 18.6169546 },
    { lat: 48.2624739, lng: 18.61703 },
    { lat: 48.2611165, lng: 18.6179962 },
    { lat: 48.2600292, lng: 18.6189134 },
    { lat: 48.2600756, lng: 18.6193325 },
    { lat: 48.2601877, lng: 18.6197939 },
    { lat: 48.2604522, lng: 18.6218618 },
    { lat: 48.2605186, lng: 18.6249346 },
    { lat: 48.2603474, lng: 18.6262046 },
    { lat: 48.2602683, lng: 18.6275958 },
    { lat: 48.258307, lng: 18.628554 },
    { lat: 48.2582978, lng: 18.6294422 },
    { lat: 48.2575719, lng: 18.6294554 },
    { lat: 48.2575516, lng: 18.6294126 },
    { lat: 48.2561491, lng: 18.6302971 },
    { lat: 48.255788, lng: 18.6314789 },
    { lat: 48.2550853, lng: 18.6332055 },
    { lat: 48.254992, lng: 18.6331168 },
    { lat: 48.2546638, lng: 18.6328048 },
    { lat: 48.254638, lng: 18.6327803 },
    { lat: 48.254608, lng: 18.6328066 },
    { lat: 48.2539196, lng: 18.6352768 },
    { lat: 48.2532858, lng: 18.6370883 },
    { lat: 48.252852, lng: 18.6387853 },
    { lat: 48.2521846, lng: 18.6407898 },
    { lat: 48.2520786, lng: 18.6410405 },
    { lat: 48.2519154, lng: 18.6412128 },
    { lat: 48.2515867, lng: 18.6421435 },
    { lat: 48.2513356, lng: 18.642547 },
    { lat: 48.2508198, lng: 18.6431221 },
    { lat: 48.2505707, lng: 18.6432712 },
    { lat: 48.2500157, lng: 18.6432936 },
    { lat: 48.249675, lng: 18.6440643 },
    { lat: 48.2492959, lng: 18.6451645 },
    { lat: 48.2485475, lng: 18.6462975 },
    { lat: 48.2478514, lng: 18.6475079 },
    { lat: 48.2475422, lng: 18.6482546 },
    { lat: 48.2472488, lng: 18.649292 },
    { lat: 48.2469931, lng: 18.6498828 },
    { lat: 48.2467582, lng: 18.6502725 },
    { lat: 48.2456192, lng: 18.6513218 },
    { lat: 48.2462861, lng: 18.653287 },
    { lat: 48.2464376, lng: 18.6538783 },
    { lat: 48.2463953, lng: 18.6545168 },
    { lat: 48.2462516, lng: 18.655277 },
    { lat: 48.2461533, lng: 18.6588668 },
    { lat: 48.2460319, lng: 18.6594582 },
    { lat: 48.2459684, lng: 18.6624026 },
    { lat: 48.2460595, lng: 18.6651127 },
    { lat: 48.2458532, lng: 18.6663694 },
    { lat: 48.2451891, lng: 18.6691142 },
    { lat: 48.2451983, lng: 18.6691184 },
    { lat: 48.2457528, lng: 18.6694038 },
    { lat: 48.2460847, lng: 18.6696991 },
    { lat: 48.2468872, lng: 18.671679 },
    { lat: 48.2473983, lng: 18.6722917 },
    { lat: 48.2485855, lng: 18.6742931 },
    { lat: 48.2481919, lng: 18.6756344 },
    { lat: 48.2499283, lng: 18.6767745 },
    { lat: 48.2510012, lng: 18.6776203 },
    { lat: 48.2516365, lng: 18.6782301 },
    { lat: 48.2518683, lng: 18.6785366 },
    { lat: 48.2524841, lng: 18.6797073 },
    { lat: 48.2528009, lng: 18.6808391 },
    { lat: 48.2534675, lng: 18.681636 },
    { lat: 48.2536057, lng: 18.681459 },
    { lat: 48.2540031, lng: 18.6821373 },
    { lat: 48.254449, lng: 18.6816401 },
    { lat: 48.2549863, lng: 18.6808717 },
    { lat: 48.2569236, lng: 18.6775111 },
    { lat: 48.2575401, lng: 18.6763108 },
    { lat: 48.2594545, lng: 18.6778706 },
    { lat: 48.2597724, lng: 18.6785766 },
    { lat: 48.2604228, lng: 18.679285 },
    { lat: 48.2604548, lng: 18.6795225 },
    { lat: 48.2609715, lng: 18.6795451 },
    { lat: 48.2614555, lng: 18.6801128 },
    { lat: 48.2622661, lng: 18.6803782 },
    { lat: 48.2624203, lng: 18.6806658 },
    { lat: 48.2629463, lng: 18.6808002 },
    { lat: 48.2635194, lng: 18.6812662 },
    { lat: 48.2635835, lng: 18.6813675 },
    { lat: 48.2635372, lng: 18.6814917 },
    { lat: 48.2632058, lng: 18.6820612 },
    { lat: 48.2634876, lng: 18.6823706 },
    { lat: 48.2637071, lng: 18.6824708 },
    { lat: 48.2642387, lng: 18.6822648 },
    { lat: 48.264269, lng: 18.6825386 },
    { lat: 48.2643736, lng: 18.6825947 },
    { lat: 48.2645708, lng: 18.6822357 },
    { lat: 48.2648661, lng: 18.6820839 },
    { lat: 48.2657105, lng: 18.6819513 },
    { lat: 48.2660085, lng: 18.6820824 },
    { lat: 48.2660282, lng: 18.6818192 },
    { lat: 48.2661253, lng: 18.681463 },
    { lat: 48.2665198, lng: 18.6811043 },
    { lat: 48.2666452, lng: 18.6808944 },
    { lat: 48.2666973, lng: 18.6809012 },
    { lat: 48.2673797, lng: 18.6798532 },
    { lat: 48.2678137, lng: 18.6789484 },
    { lat: 48.2679348, lng: 18.6789324 },
    { lat: 48.268154, lng: 18.6787332 },
    { lat: 48.268164, lng: 18.6785811 },
    { lat: 48.2680942, lng: 18.6784384 },
    { lat: 48.2681959, lng: 18.6780445 },
    { lat: 48.2681669, lng: 18.6775673 },
    { lat: 48.2682267, lng: 18.6774843 },
    { lat: 48.2683057, lng: 18.6775073 },
    { lat: 48.2684334, lng: 18.6778024 },
    { lat: 48.2685485, lng: 18.6776565 },
    { lat: 48.2685701, lng: 18.677537 },
    { lat: 48.2685328, lng: 18.6774275 },
    { lat: 48.2683938, lng: 18.6773615 },
    { lat: 48.2683098, lng: 18.6772456 },
    { lat: 48.2685638, lng: 18.676708 },
    { lat: 48.2686018, lng: 18.676509 },
    { lat: 48.2688493, lng: 18.6762678 },
    { lat: 48.2690098, lng: 18.6757942 },
    { lat: 48.2691054, lng: 18.675765 },
    { lat: 48.2692202, lng: 18.6755929 },
    { lat: 48.2692786, lng: 18.6752555 },
    { lat: 48.2694109, lng: 18.6752406 },
    { lat: 48.2694968, lng: 18.6751375 },
    { lat: 48.2694852, lng: 18.6749223 },
    { lat: 48.2695733, lng: 18.6745477 },
    { lat: 48.2695142, lng: 18.6742893 },
    { lat: 48.2696089, lng: 18.6740771 },
    { lat: 48.2695386, lng: 18.6738817 },
    { lat: 48.2695116, lng: 18.6735076 },
    { lat: 48.2695865, lng: 18.6734115 },
    { lat: 48.2696838, lng: 18.673501 },
    { lat: 48.2698704, lng: 18.6733025 },
    { lat: 48.2698467, lng: 18.6731959 },
    { lat: 48.2699571, lng: 18.6730035 },
    { lat: 48.2699484, lng: 18.6728845 },
    { lat: 48.2697174, lng: 18.6727818 },
    { lat: 48.2697696, lng: 18.6726626 },
    { lat: 48.2699016, lng: 18.6726427 },
    { lat: 48.2700207, lng: 18.6724669 },
    { lat: 48.2702585, lng: 18.6727352 },
    { lat: 48.270344, lng: 18.6726289 },
    { lat: 48.2703622, lng: 18.6724635 },
    { lat: 48.2702891, lng: 18.6722776 },
    { lat: 48.2702959, lng: 18.6720258 },
    { lat: 48.2700049, lng: 18.6718408 },
    { lat: 48.2701942, lng: 18.6716253 },
    { lat: 48.2704585, lng: 18.6709646 },
    { lat: 48.2704336, lng: 18.6706076 },
    { lat: 48.2705101, lng: 18.6705396 },
    { lat: 48.2706608, lng: 18.6706566 },
    { lat: 48.2706809, lng: 18.6704634 },
    { lat: 48.2707734, lng: 18.6702543 },
    { lat: 48.271057, lng: 18.6699693 },
    { lat: 48.2713739, lng: 18.6690693 },
    { lat: 48.2714156, lng: 18.6691326 },
    { lat: 48.2719463, lng: 18.6687042 },
    { lat: 48.2721033, lng: 18.6684561 },
    { lat: 48.2721235, lng: 18.6685358 },
    { lat: 48.2722558, lng: 18.6685446 },
    { lat: 48.2722822, lng: 18.668386 },
    { lat: 48.272361, lng: 18.6682766 },
    { lat: 48.2724826, lng: 18.6683359 },
    { lat: 48.2733925, lng: 18.6677883 },
    { lat: 48.2745363, lng: 18.6676015 },
    { lat: 48.2746807, lng: 18.6674951 },
    { lat: 48.275715, lng: 18.6672688 },
    { lat: 48.2758384, lng: 18.6679446 },
    { lat: 48.2766705, lng: 18.6676886 },
    { lat: 48.2768582, lng: 18.6687454 },
    { lat: 48.2785208, lng: 18.668328 },
    { lat: 48.2785374, lng: 18.6683239 },
  ],
  Brhlovce: [
    { lat: 48.2072332, lng: 18.7465536 },
    { lat: 48.2070657, lng: 18.7468819 },
    { lat: 48.2065208, lng: 18.7482988 },
    { lat: 48.2062345, lng: 18.7495721 },
    { lat: 48.2058989, lng: 18.7514678 },
    { lat: 48.2068324, lng: 18.7535186 },
    { lat: 48.2072374, lng: 18.7542814 },
    { lat: 48.2072141, lng: 18.754311 },
    { lat: 48.2074292, lng: 18.7545605 },
    { lat: 48.2078213, lng: 18.7553998 },
    { lat: 48.2081184, lng: 18.7562586 },
    { lat: 48.2081887, lng: 18.7568386 },
    { lat: 48.2081329, lng: 18.7575491 },
    { lat: 48.2080358, lng: 18.7579492 },
    { lat: 48.2076652, lng: 18.7589136 },
    { lat: 48.2084577, lng: 18.7597782 },
    { lat: 48.2097189, lng: 18.7615484 },
    { lat: 48.2087219, lng: 18.7629047 },
    { lat: 48.2095725, lng: 18.76429 },
    { lat: 48.2110864, lng: 18.7665257 },
    { lat: 48.2112903, lng: 18.7668405 },
    { lat: 48.2129697, lng: 18.7642108 },
    { lat: 48.2144177, lng: 18.766099 },
    { lat: 48.2155568, lng: 18.7664821 },
    { lat: 48.2164973, lng: 18.7667349 },
    { lat: 48.2170983, lng: 18.7668197 },
    { lat: 48.2214007, lng: 18.7674546 },
    { lat: 48.2234067, lng: 18.7676622 },
    { lat: 48.2236914, lng: 18.7676294 },
    { lat: 48.2243847, lng: 18.7673295 },
    { lat: 48.2245723, lng: 18.7671859 },
    { lat: 48.2251955, lng: 18.7664778 },
    { lat: 48.2252582, lng: 18.7664052 },
    { lat: 48.2252255, lng: 18.7663851 },
    { lat: 48.2256284, lng: 18.765858 },
    { lat: 48.2271692, lng: 18.7642908 },
    { lat: 48.228054, lng: 18.7631953 },
    { lat: 48.229846, lng: 18.7607959 },
    { lat: 48.2310137, lng: 18.7590847 },
    { lat: 48.2337344, lng: 18.7554885 },
    { lat: 48.2347623, lng: 18.7539267 },
    { lat: 48.2351779, lng: 18.7534085 },
    { lat: 48.2358088, lng: 18.752842 },
    { lat: 48.2358117, lng: 18.7527497 },
    { lat: 48.236134, lng: 18.752447 },
    { lat: 48.2367408, lng: 18.7515561 },
    { lat: 48.2384543, lng: 18.7477311 },
    { lat: 48.237616, lng: 18.7466817 },
    { lat: 48.2381566, lng: 18.7455036 },
    { lat: 48.2372903, lng: 18.7441466 },
    { lat: 48.2378248, lng: 18.743152 },
    { lat: 48.2382448, lng: 18.7422256 },
    { lat: 48.2378967, lng: 18.7416 },
    { lat: 48.237858, lng: 18.7411698 },
    { lat: 48.2383104, lng: 18.7403687 },
    { lat: 48.2383138, lng: 18.7390359 },
    { lat: 48.2382344, lng: 18.7387079 },
    { lat: 48.2380944, lng: 18.7371185 },
    { lat: 48.2381479, lng: 18.7369165 },
    { lat: 48.2383448, lng: 18.7351316 },
    { lat: 48.2377895, lng: 18.7348045 },
    { lat: 48.2381026, lng: 18.7331918 },
    { lat: 48.2384608, lng: 18.7325486 },
    { lat: 48.2387565, lng: 18.7317516 },
    { lat: 48.2382657, lng: 18.7308798 },
    { lat: 48.2379374, lng: 18.7299282 },
    { lat: 48.237844, lng: 18.7294448 },
    { lat: 48.2379196, lng: 18.728961 },
    { lat: 48.2379136, lng: 18.7286358 },
    { lat: 48.2377067, lng: 18.7274871 },
    { lat: 48.2376881, lng: 18.7269665 },
    { lat: 48.2374355, lng: 18.7260391 },
    { lat: 48.2373503, lng: 18.7250148 },
    { lat: 48.2371232, lng: 18.7245234 },
    { lat: 48.2366569, lng: 18.723647 },
    { lat: 48.2360879, lng: 18.7228584 },
    { lat: 48.2349998, lng: 18.7220388 },
    { lat: 48.2352789, lng: 18.7213927 },
    { lat: 48.2348112, lng: 18.7209333 },
    { lat: 48.2344541, lng: 18.7203333 },
    { lat: 48.234157, lng: 18.7194788 },
    { lat: 48.2338071, lng: 18.7186502 },
    { lat: 48.2340997, lng: 18.7176911 },
    { lat: 48.2342869, lng: 18.7167825 },
    { lat: 48.2343271, lng: 18.716124 },
    { lat: 48.2345681, lng: 18.7149132 },
    { lat: 48.2346814, lng: 18.7144196 },
    { lat: 48.2334356, lng: 18.7135618 },
    { lat: 48.2319778, lng: 18.7115689 },
    { lat: 48.2316485, lng: 18.7112105 },
    { lat: 48.2288428, lng: 18.710061 },
    { lat: 48.2280666, lng: 18.7095741 },
    { lat: 48.2272712, lng: 18.7088694 },
    { lat: 48.2269706, lng: 18.7086933 },
    { lat: 48.2257631, lng: 18.7083451 },
    { lat: 48.2227259, lng: 18.7080259 },
    { lat: 48.2216796, lng: 18.7076775 },
    { lat: 48.219654, lng: 18.7074435 },
    { lat: 48.2184417, lng: 18.7074062 },
    { lat: 48.2180863, lng: 18.706204 },
    { lat: 48.2177108, lng: 18.7062275 },
    { lat: 48.2163985, lng: 18.7073306 },
    { lat: 48.2153558, lng: 18.7081997 },
    { lat: 48.2147705, lng: 18.7092339 },
    { lat: 48.2146459, lng: 18.7096029 },
    { lat: 48.2142076, lng: 18.7104744 },
    { lat: 48.2137733, lng: 18.7111853 },
    { lat: 48.2134699, lng: 18.7122195 },
    { lat: 48.213371, lng: 18.7130023 },
    { lat: 48.2131342, lng: 18.7136774 },
    { lat: 48.2128349, lng: 18.7147882 },
    { lat: 48.2122201, lng: 18.7157763 },
    { lat: 48.2119653, lng: 18.7160321 },
    { lat: 48.2113504, lng: 18.7170782 },
    { lat: 48.211258, lng: 18.717608 },
    { lat: 48.2113577, lng: 18.7187349 },
    { lat: 48.2112475, lng: 18.7191389 },
    { lat: 48.2110205, lng: 18.7198089 },
    { lat: 48.2109546, lng: 18.7201357 },
    { lat: 48.2107587, lng: 18.7204197 },
    { lat: 48.2105059, lng: 18.7209811 },
    { lat: 48.2104263, lng: 18.7216242 },
    { lat: 48.2103475, lng: 18.7218377 },
    { lat: 48.2097558, lng: 18.7228158 },
    { lat: 48.2094675, lng: 18.7230784 },
    { lat: 48.2092959, lng: 18.7233294 },
    { lat: 48.2091026, lng: 18.7238465 },
    { lat: 48.2090125, lng: 18.7245043 },
    { lat: 48.2091124, lng: 18.724998 },
    { lat: 48.2090625, lng: 18.7255244 },
    { lat: 48.2086928, lng: 18.7262037 },
    { lat: 48.208331, lng: 18.7264997 },
    { lat: 48.2080007, lng: 18.7264844 },
    { lat: 48.2076092, lng: 18.7266497 },
    { lat: 48.2069504, lng: 18.7271743 },
    { lat: 48.2066923, lng: 18.7276206 },
    { lat: 48.2065348, lng: 18.727777 },
    { lat: 48.2054313, lng: 18.7279581 },
    { lat: 48.204747, lng: 18.7281498 },
    { lat: 48.2044372, lng: 18.7281141 },
    { lat: 48.2037036, lng: 18.7277079 },
    { lat: 48.2030525, lng: 18.727761 },
    { lat: 48.2027055, lng: 18.7281708 },
    { lat: 48.2022568, lng: 18.7283631 },
    { lat: 48.2021746, lng: 18.7282796 },
    { lat: 48.2021415, lng: 18.7282524 },
    { lat: 48.2024003, lng: 18.7287256 },
    { lat: 48.2028056, lng: 18.729177 },
    { lat: 48.2024627, lng: 18.7297253 },
    { lat: 48.20212, lng: 18.7299597 },
    { lat: 48.2024534, lng: 18.7305876 },
    { lat: 48.2028397, lng: 18.7323073 },
    { lat: 48.202814, lng: 18.7327997 },
    { lat: 48.2027137, lng: 18.733179 },
    { lat: 48.2028035, lng: 18.7338301 },
    { lat: 48.2025366, lng: 18.7343626 },
    { lat: 48.2026036, lng: 18.7348785 },
    { lat: 48.2023817, lng: 18.7358973 },
    { lat: 48.2022651, lng: 18.7377305 },
    { lat: 48.2022848, lng: 18.7387558 },
    { lat: 48.2022556, lng: 18.7390329 },
    { lat: 48.2019268, lng: 18.7413039 },
    { lat: 48.2018307, lng: 18.7413635 },
    { lat: 48.2016791, lng: 18.7422841 },
    { lat: 48.2016934, lng: 18.7435897 },
    { lat: 48.2013405, lng: 18.7454113 },
    { lat: 48.2015569, lng: 18.7468696 },
    { lat: 48.2011316, lng: 18.7472476 },
    { lat: 48.201406, lng: 18.7480598 },
    { lat: 48.2015321, lng: 18.7478864 },
    { lat: 48.2024304, lng: 18.7470911 },
    { lat: 48.2033717, lng: 18.746683 },
    { lat: 48.2037321, lng: 18.7466312 },
    { lat: 48.2045082, lng: 18.7466801 },
    { lat: 48.2055915, lng: 18.74686 },
    { lat: 48.2066071, lng: 18.7467658 },
    { lat: 48.2070977, lng: 18.7465298 },
    { lat: 48.2072332, lng: 18.7465536 },
  ],
};

export default UnitsLevice;
