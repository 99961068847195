import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NaPredaj from "../../../Images/naPredaj.png";
import { faBuilding, faFilePdf } from "@fortawesome/free-solid-svg-icons";

export default function Header({ forSaleItems, indx }) {
  // console.log("forSaleItems = ", forSaleItems);
  const lv = forSaleItems !== undefined ? JSON.parse(forSaleItems()[indx.current]?.lv) : null;
  return (
    <div className="flex flex-row">
      <div className="flex flex-col justify-between w-2/3 p-1 text-xs">
        <div className="w-full h-2/5 flex flex-col items-start justify-start">
          <div className="w-full flex flex-row items-center gap-1">
            {JSON.parse(forSaleItems()[indx.current]?.parameters).type.includes("byt") ? (
              <FontAwesomeIcon icon={faBuilding} className="w- h-3" />
            ) : (
              <img src={NaPredaj} alt="Na predaj" className="w-3 h-3" />
            )}
            {JSON.parse(forSaleItems()[indx.current]?.parameters).type} •{" "}
            {JSON.parse(forSaleItems()[indx.current]?.parameters).area} m²
          </div>
          <div className="w-full flex flex-row items-center gap-1">
            • {JSON.parse(forSaleItems()[indx.current]?.parameters).condition}
          </div>
          <div className="w-full flex flex-row items-center gap-1">
            • {forSaleItems()[indx.current]?.street ? forSaleItems()[indx.current]?.street + ", " : null}
            {forSaleItems()[indx.current]?.city + ", "}{" "}
            {Number(JSON.parse(forSaleItems()[indx.current]?.lv).List?.length) > 1
              ? "okres " + JSON.parse(forSaleItems()[indx.current]?.lv).List[0]?.nazov_okresu
              : "okres " + JSON.parse(forSaleItems()[indx.current]?.lv).List?.nazov_okresu}
          </div>
        </div>
        <div className="w-full h-1/4 flex items-center justify-start text-xl font-semibold">
          {forSaleItems()[indx.current]?.price} €
        </div>
      </div>
      <div
        onClick={() => {
          window.open(
            `https://kataster.skgeodesy.sk/Portal45/api/Bo/GeneratePrfPublic?prfNumber=${lv?.List?.cislo_listu_vlastnictva}&cadastralUnitCode=${lv?.List?.nazov_katastralneho_uzemia}&outputType=pdf`,
            "_blank",
          );
        }}
        className="w-1/3 flex justify-end items-start cursor-pointer"
      >
        <FontAwesomeIcon icon={faFilePdf} className="w-5 h-5" />
      </div>
    </div>
  );
}
