import { React } from 'react';

export default function Info({averagePrice}){

    return (
        <>
        {averagePrice.message !== undefined  
            ? <p className='mb-2 text-red-600'>{`${averagePrice.message}`}</p>
            : null
            } 
            {averagePrice.relevancyMessageStatusMissing !== undefined  
            || averagePrice.relevancyMessageNr1 !== undefined
            || averagePrice.relevancyMessageNr2 !== undefined 
            || averagePrice.relevancyMessageNr3 !== undefined
            || averagePrice.relevancyMessageLoc !== undefined 
            || averagePrice.relevancyMessageCat !== undefined 
            || averagePrice.relevancyMessageStatus !== undefined
            || averagePrice.relevancyMessageUsableAreaMissing !== undefined 
            || averagePrice.relevancyMessage310 !== undefined  
            || averagePrice.relevancyMessageUsable1 !== undefined  
            || averagePrice.relevancyMessageUsable2 !== undefined  
            || averagePrice.relevancyMessageLand1 !== undefined  
            || averagePrice.relevancyMessageLand2 !== undefined  
            || averagePrice.relevancyMessage !== undefined
            || averagePrice.relevancyMessageNr4 !== undefined  
            ?
            <div className={`w-full ml-2 mb-2 relative rounded-lg border-2 max-h-max border-white text-white font-sans font-bold overflow-hidden flex flex-row justify-start`}>
                    {averagePrice.relevancyMessageStatusMissing !== undefined  
                    || averagePrice.relevancyMessageNr1 !== undefined
                    || averagePrice.relevancyMessageNr2 !== undefined 
                    || averagePrice.relevancyMessageNr3 !== undefined 
                    || averagePrice.relevancyMessageLoc !== undefined 
                    || averagePrice.relevancyMessageCat !== undefined 
                    || averagePrice.relevancyMessageStatus !== undefined
                    || averagePrice.relevancyMessageUsableAreaMissing !== undefined 
                    || averagePrice.relevancyMessage310 !== undefined  
                    || averagePrice.relevancyMessageUsable1 !== undefined  
                    || averagePrice.relevancyMessageUsable2 !== undefined  
                    || averagePrice.relevancyMessageLand1 !== undefined  
                    || averagePrice.relevancyMessageLand2 !== undefined  
                    || averagePrice.relevancyMessage !== undefined  
                    || averagePrice.relevancyMessageNr4 !== undefined
                    ?   <div className='fixed flex justify-start items-center transform -translate-y-1/2 w-11/12 px-3'>
                            <h2 className="">
                                <span className="bg-white rounded-md px-2 py-1 text-sm font-medium text-sky-900">Presnosť bola znížená</span>
                            </h2>
                        </div>
                    : null
                }
                <div className='p-3 flex flex-col items-start'>
                    {averagePrice.relevancyMessageStatusMissing !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageStatusMissing}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageNr1 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageNr1}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageNr2 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageNr2}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageUsable1 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageUsable1}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageLand1 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageLand1}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageUsable2 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageUsable2}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageLand2 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageLand2}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageNr3 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageNr3}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageLoc !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageLoc}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageCat !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageCat}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageStatus !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageStatus}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageUsableAreaMissing !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageUsableAreaMissing}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessage310 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessage310}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessage !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessage}`}</p>
                        : null
                    }
                    {averagePrice.relevancyMessageNr4 !== undefined  
                        ? <p className='mb-2'>{`${averagePrice.relevancyMessageNr4}`}</p>
                        : null
                    }
                    </div>
                </div>
            : null
        }
        </>
    )
}