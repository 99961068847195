import clsx from "clsx";

export default function Privacy({ size }) {
  return (
    <div className="w-full h-screen bg-sky-100 flex flex-col justify-start items-center p-2">
      <div className={clsx("w-full h-auto flex flex-col justify-center items-start gap-3", {})}>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h1 className="text-3xl">Ochrana osobných údajov</h1>
          <span>
            Tento dokument stanovujúci zásady ochrany osobných údajov popisuje postupy prijaté spoločnosťou Lotario
            s.r.o, Fr. Kráľa č. 6547/59, Michalovce, 071 01 Michalovce (ďalej len „Lotario“) ohľadom spracovania
            osobných údajov užívateľov. Ide o všeobecnú informáciu adresovanú tomu, kto navštívi tieto webové stránky a
            to v zmysle zákona č. 122/2013 o ochrane osobných údajov v znení zákona č. 84/2014 Z. z. (ďalej len „zákon o
            ochrane osobných údajov“ ). Pri využívaní niektorých služieb ponúkaných a poskytovaných prostredníctvom
            tejto webovej stránky užívatelia poskytnú určité informácie, budú vznášať určité požiadavky a poskytnú
            súhlas so spracovaním svojich osobných údajov, ak je to zo zákona vyžadované.
          </span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h2 className="text-xl">Spracovanie údajov</h2>
          <span>
            Výsledkom prístupu k týmto webovým stránkam môže byť spracovanie údajov týkajúcich sa určených alebo
            určiteľných osôb. Správcom údajov je Lotario, ktorá zaistí primerané organizačné a technické spracovanie
            údajov za účelom zabezpečenia týchto údajov proti neoprávnenému prístupu, strate, poškodeniu, krádeži ,
            použitiu alebo inému zverejneniu údajov.
          </span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h2 className="text-xl">Miesto spracovania údajov</h2>
          <span>
            Osobné údaje budú spracované v priestoroch spoločností Lotario. S údajmi budú pracovať osoby s príslušnými
            technickými zručnosťami a schopnosťami, ktorým boli pridelené špecifické funkcie v oblasti spracovania
            údajov.
          </span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h2 className="text-xl">Postupy pri spracovaní údajov</h2>
          <span>
            Údaje budú spracované korektným spôsobom a plne v súlade so zákonom tak, aby sa zabezpečila ochrana údajov a
            ich dôverný charakter. Údaje budú spracované elektronickými alebo automatizovanými prostriedkami.{" "}
          </span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h2 className="text-xl">Typy spracúvaných údajov</h2>
          <span>
            Informačné systémy a softvérové ​​postupy použité pri prevádzkovaní týchto webových stránok získavajú v
            priebehu svojho bežnej prevádzky určité osobné údaje. Prenos týchto údajov je implicitne daný využitím ICP
            protokolov. Údaje nie sú zhromažďované s cieľom spájať ich s konkrétnymi subjektmi, avšak už vďaka svojmu
            charakteru umožňujú identifikovať užívateľa prostredníctvom ich spracovania a pridruženia k iným údajom
            držaným tretími osobami. Táto kategória údajov obsahuje IP adresy a doménové mená počítačov, ktoré používajú
            užívatelia pripájajúce sa k sieti, ďalej potom URI adresy požadovaných zdrojov, časové určenie požiadaviek,
            použitý postup pri vznášaní požiadaviek a ďalšie parametre vzťahujúce sa k operačnému systému a informačnému
            prostrediu používateľa. Dobrovoľné zaslanie emailovej adresy používateľa na adresu na webovej stránke má za
            následok získanie osobných údajov používateľa potrebných na to, aby bolo možné reagovať na požiadavky tohto
            používateľa.
          </span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h2 className="text-xl">Poskytovanie údajov</h2>
          <span>
            Je na užívateľoch, či poskytnú osobné údaje, ktoré spoločnosť Lotario potrebuje na to, aby mohla poskytnúť
            požadované služby. Neposkytnutie týchto údajov môže vyššie uvedenej spoločnosti znemožniť dodanie
            požadovaných informácií alebo služieb.
          </span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h2 className="text-xl">Cookies</h2>
          <span>
            Cookies sa nepoužívajú na prenos osobných informácií. Užitie cookies relácií, ktoré nie sú trvalo ukladané v
            počítači užívateľa a ktoré po vypnutí prehliadača zmiznú, je striktne obmedzené na prenos identifikačných
            kódov relácie, ktoré obsahujú náhodné čísla generované serverom, potrebné k bezpečnej navigáciu na webovej
            stránke. Používanie týchto cookies relácií tak znemožňuje použitiu iných informačných technológií, ktoré by
            mohli predstavovať potenciálne ohrozenie dôverného charakteru navigácie. Neumožňujú získanie osobných
            informácií, s ktorých pomocou by bolo možné identifikovať používateľa.
          </span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h2 className="text-xl">Práva užívateľa</h2>
          <span>
            V súlade so zákonom o ochrane osobných údajov možno osobné údaje spracovávať len po dobu, ktorá je
            nevyhnutná na účel ich spracovania.
          </span>
          <span>
            Podľa zákona o ochrane osobných údajov majú používatelia – subjekty údajov – právo, ak zistia, alebo ak sa
            domnievajú, že správca alebo spracovateľ vykonáva spracovanie ich osobných údajov, ktoré je v rozpore s
            ochranou ich súkromného a osobného života, alebo v rozpore so zákonom, najmä ak sú osobné údaje nepresné s
            ohľadom na účel ich spracovania,
          </span>
          <ol>
            <li>1. požiadať správcu alebo spracovateľa o vysvetlenie</li>
            <li>
              2. požadovať, aby správca alebo spracovateľ odstránil vzniknutý stav, hlavne údaje blokoval, vykonal
              opravy, doplnenie alebo ich zlikvidoval.
            </li>
          </ol>
          <span>
            Žiadosť podľa predchádzajúceho odseku je potrebné predložiť spoločnosti Lotario s.r.o, Fr. Kráľa č. 6547/59,
            Michalovce, 071 01 Michalovce
          </span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 p-1">
          <h2 className="text-xl">Obchodné oznámenie</h2>
          <span>
            V súlade so zákonom o ochrane osobných údajov je Lotario ako prevádzkovateľ webových stránok{" "}
            <a href="https:lotario.sk" target="_blank" rel="noopener noreferrer" className="text-sky-500 underline">
              lotario.sk
            </a>
            ,{" "}
            <a
              href="https:smartinvoice.sk"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sky-500 underline"
            >
              smartinvoice.sk
            </a>{" "}
            a{" "}
            <a href="https:rekom.sk" target="_blank" rel="noopener noreferrer" className="text-sky-500 underline">
              rekom.sk
            </a>{" "}
            oprávnený použiť telefónne číslo alebo e-mailovú adresu poskytnuté užívateľom pre potreby šírenia obchodných
            oznámení týkajúce sa produktov a služieb Lotario s tým, že užívateľ je kedykoľvek oprávnený odmietnuť súhlas
            so zasielaním obchodných oznámení či s poskytovaním takýchto informácií prostredníctvom telefonického
            kontaktu , a to listinou formou doručením do sídla spoločnosti Lotario alebo zaslaním emailu na adresu
            lotario@lotario.sk. Odmietnutie súhlasu so šírením obchodných oznámení nie je nijako spoplatnené.
          </span>
        </div>
      </div>
    </div>
  );
}
