const UnitsBanskáŠtiavnica = {
  Vysoká: [
    { lat: 48.411162, lng: 18.750644 },
    { lat: 48.410982, lng: 18.750913 },
    { lat: 48.410589, lng: 18.752201 },
    { lat: 48.410277, lng: 18.752581 },
    { lat: 48.410123, lng: 18.752839 },
    { lat: 48.410041, lng: 18.752976 },
    { lat: 48.409812, lng: 18.753493 },
    { lat: 48.409641, lng: 18.75415 },
    { lat: 48.409587, lng: 18.754922 },
    { lat: 48.409536, lng: 18.755224 },
    { lat: 48.409486, lng: 18.755669 },
    { lat: 48.409473, lng: 18.755778 },
    { lat: 48.409453, lng: 18.755959 },
    { lat: 48.409409, lng: 18.756353 },
    { lat: 48.409399, lng: 18.756424 },
    { lat: 48.409306, lng: 18.756545 },
    { lat: 48.409248, lng: 18.75662 },
    { lat: 48.408815, lng: 18.757185 },
    { lat: 48.408515, lng: 18.757259 },
    { lat: 48.408157, lng: 18.757229 },
    { lat: 48.407619, lng: 18.757706 },
    { lat: 48.406707, lng: 18.7578 },
    { lat: 48.40649, lng: 18.75779 },
    { lat: 48.405856, lng: 18.758038 },
    { lat: 48.40552, lng: 18.758192 },
    { lat: 48.40502, lng: 18.758406 },
    { lat: 48.404894, lng: 18.758764 },
    { lat: 48.404793, lng: 18.758941 },
    { lat: 48.404682, lng: 18.759134 },
    { lat: 48.404353, lng: 18.759392 },
    { lat: 48.404142, lng: 18.759484 },
    { lat: 48.404063, lng: 18.759509 },
    { lat: 48.40379, lng: 18.759584 },
    { lat: 48.403576, lng: 18.759632 },
    { lat: 48.40336, lng: 18.759621 },
    { lat: 48.403107, lng: 18.759588 },
    { lat: 48.403093, lng: 18.759582 },
    { lat: 48.402598, lng: 18.759638 },
    { lat: 48.402446, lng: 18.759654 },
    { lat: 48.402179, lng: 18.759538 },
    { lat: 48.402099, lng: 18.75952 },
    { lat: 48.402067, lng: 18.759517 },
    { lat: 48.401704, lng: 18.759376 },
    { lat: 48.401385, lng: 18.759109 },
    { lat: 48.401226, lng: 18.759031 },
    { lat: 48.400972, lng: 18.75893 },
    { lat: 48.400779, lng: 18.758998 },
    { lat: 48.400915, lng: 18.759448 },
    { lat: 48.400991, lng: 18.760373 },
    { lat: 48.400946, lng: 18.76104 },
    { lat: 48.400865, lng: 18.761291 },
    { lat: 48.400794, lng: 18.761377 },
    { lat: 48.400723, lng: 18.761489 },
    { lat: 48.399951, lng: 18.762179 },
    { lat: 48.399747, lng: 18.762136 },
    { lat: 48.399237, lng: 18.762855 },
    { lat: 48.398819, lng: 18.763132 },
    { lat: 48.398713, lng: 18.763202 },
    { lat: 48.398342, lng: 18.763353 },
    { lat: 48.397764, lng: 18.763657 },
    { lat: 48.397453, lng: 18.764407 },
    { lat: 48.396784, lng: 18.765116 },
    { lat: 48.396701, lng: 18.765453 },
    { lat: 48.396668, lng: 18.765584 },
    { lat: 48.396599, lng: 18.765908 },
    { lat: 48.396323, lng: 18.76618 },
    { lat: 48.396039, lng: 18.766079 },
    { lat: 48.395847, lng: 18.766011 },
    { lat: 48.395579, lng: 18.766162 },
    { lat: 48.395012, lng: 18.766875 },
    { lat: 48.394787, lng: 18.767278 },
    { lat: 48.394509, lng: 18.767538 },
    { lat: 48.394235, lng: 18.767712 },
    { lat: 48.393603, lng: 18.768323 },
    { lat: 48.393002, lng: 18.768728 },
    { lat: 48.392544, lng: 18.769125 },
    { lat: 48.392417, lng: 18.769306 },
    { lat: 48.392083, lng: 18.769681 },
    { lat: 48.391953, lng: 18.769759 },
    { lat: 48.391933, lng: 18.769789 },
    { lat: 48.391898, lng: 18.76984 },
    { lat: 48.391886, lng: 18.769858 },
    { lat: 48.39147, lng: 18.770236 },
    { lat: 48.391398, lng: 18.770403 },
    { lat: 48.391321, lng: 18.770465 },
    { lat: 48.391293, lng: 18.770688 },
    { lat: 48.39118, lng: 18.770894 },
    { lat: 48.390904, lng: 18.772109 },
    { lat: 48.39083, lng: 18.772563 },
    { lat: 48.390747, lng: 18.772746 },
    { lat: 48.390816, lng: 18.772875 },
    { lat: 48.390849, lng: 18.77353 },
    { lat: 48.390814, lng: 18.774373 },
    { lat: 48.390688, lng: 18.774974 },
    { lat: 48.390433, lng: 18.77583 },
    { lat: 48.390179, lng: 18.776161 },
    { lat: 48.390176, lng: 18.776163 },
    { lat: 48.390157, lng: 18.776188 },
    { lat: 48.3905479, lng: 18.7763382 },
    { lat: 48.3906914, lng: 18.7762363 },
    { lat: 48.3908728, lng: 18.7762125 },
    { lat: 48.3911462, lng: 18.776136 },
    { lat: 48.3913759, lng: 18.7761718 },
    { lat: 48.3915407, lng: 18.7761543 },
    { lat: 48.3917312, lng: 18.7763665 },
    { lat: 48.392008, lng: 18.776439 },
    { lat: 48.3921395, lng: 18.7765163 },
    { lat: 48.3921574, lng: 18.7765961 },
    { lat: 48.3922396, lng: 18.7766814 },
    { lat: 48.3924809, lng: 18.7766576 },
    { lat: 48.3925129, lng: 18.7766698 },
    { lat: 48.3925648, lng: 18.7766895 },
    { lat: 48.3926283, lng: 18.7767998 },
    { lat: 48.3929278, lng: 18.7768376 },
    { lat: 48.3930723, lng: 18.7768402 },
    { lat: 48.3931954, lng: 18.7767997 },
    { lat: 48.3933205, lng: 18.7770747 },
    { lat: 48.3933266, lng: 18.7771275 },
    { lat: 48.3934301, lng: 18.7772522 },
    { lat: 48.3936544, lng: 18.7773958 },
    { lat: 48.3938959, lng: 18.7775519 },
    { lat: 48.3940059, lng: 18.7775413 },
    { lat: 48.3942258, lng: 18.7775208 },
    { lat: 48.3947163, lng: 18.77773 },
    { lat: 48.3947599, lng: 18.777854 },
    { lat: 48.394789, lng: 18.7779346 },
    { lat: 48.3949373, lng: 18.778094 },
    { lat: 48.3951626, lng: 18.7781985 },
    { lat: 48.3953683, lng: 18.7783347 },
    { lat: 48.3955242, lng: 18.7783155 },
    { lat: 48.3957596, lng: 18.7783953 },
    { lat: 48.3958561, lng: 18.7784066 },
    { lat: 48.3959335, lng: 18.7784229 },
    { lat: 48.3960881, lng: 18.7785092 },
    { lat: 48.3961739, lng: 18.7785128 },
    { lat: 48.396256, lng: 18.7785286 },
    { lat: 48.3963055, lng: 18.778571 },
    { lat: 48.3963595, lng: 18.7786316 },
    { lat: 48.3965671, lng: 18.7787584 },
    { lat: 48.3967271, lng: 18.7787773 },
    { lat: 48.3968471, lng: 18.7787666 },
    { lat: 48.3969929, lng: 18.778817 },
    { lat: 48.3971666, lng: 18.7788794 },
    { lat: 48.3972847, lng: 18.7790037 },
    { lat: 48.3973908, lng: 18.7791107 },
    { lat: 48.3974801, lng: 18.7792228 },
    { lat: 48.3976517, lng: 18.7793131 },
    { lat: 48.3977855, lng: 18.7795155 },
    { lat: 48.3979832, lng: 18.7797144 },
    { lat: 48.3981404, lng: 18.77981 },
    { lat: 48.3982679, lng: 18.7798119 },
    { lat: 48.3983514, lng: 18.7798534 },
    { lat: 48.3986504, lng: 18.7800304 },
    { lat: 48.3986815, lng: 18.7800192 },
    { lat: 48.3988088, lng: 18.7799728 },
    { lat: 48.399009, lng: 18.7800498 },
    { lat: 48.3991631, lng: 18.7801706 },
    { lat: 48.3992323, lng: 18.7802638 },
    { lat: 48.3994097, lng: 18.7803971 },
    { lat: 48.3995233, lng: 18.7805844 },
    { lat: 48.3996213, lng: 18.7808613 },
    { lat: 48.3997115, lng: 18.780989 },
    { lat: 48.3999656, lng: 18.7812497 },
    { lat: 48.4000455, lng: 18.7813754 },
    { lat: 48.4002133, lng: 18.7816266 },
    { lat: 48.40033, lng: 18.7817256 },
    { lat: 48.4005218, lng: 18.7819391 },
    { lat: 48.4005859, lng: 18.7820433 },
    { lat: 48.4005942, lng: 18.7822854 },
    { lat: 48.4006027, lng: 18.7824284 },
    { lat: 48.4006216, lng: 18.7825085 },
    { lat: 48.4006379, lng: 18.7825794 },
    { lat: 48.4006557, lng: 18.7827692 },
    { lat: 48.4007059, lng: 18.7829918 },
    { lat: 48.4008236, lng: 18.7833018 },
    { lat: 48.400984, lng: 18.7834978 },
    { lat: 48.4012085, lng: 18.7836943 },
    { lat: 48.4014339, lng: 18.783797 },
    { lat: 48.4014533, lng: 18.7838008 },
    { lat: 48.4015606, lng: 18.7838242 },
    { lat: 48.4016971, lng: 18.7839524 },
    { lat: 48.4017169, lng: 18.7839866 },
    { lat: 48.4017489, lng: 18.7840829 },
    { lat: 48.4017606, lng: 18.784112 },
    { lat: 48.4018201, lng: 18.7842327 },
    { lat: 48.4018868, lng: 18.7843862 },
    { lat: 48.4019755, lng: 18.7846153 },
    { lat: 48.4020287, lng: 18.7847906 },
    { lat: 48.4020683, lng: 18.7849201 },
    { lat: 48.4021646, lng: 18.7851854 },
    { lat: 48.402214, lng: 18.7853524 },
    { lat: 48.4024014, lng: 18.7857422 },
    { lat: 48.4024588, lng: 18.785889 },
    { lat: 48.4024932, lng: 18.7860262 },
    { lat: 48.4025119, lng: 18.7862117 },
    { lat: 48.4024509, lng: 18.7867211 },
    { lat: 48.402398, lng: 18.7871072 },
    { lat: 48.402478, lng: 18.7873666 },
    { lat: 48.4025652, lng: 18.7875635 },
    { lat: 48.4026742, lng: 18.7878555 },
    { lat: 48.402717, lng: 18.7881126 },
    { lat: 48.4026891, lng: 18.7882686 },
    { lat: 48.4026152, lng: 18.788557 },
    { lat: 48.4026443, lng: 18.7887242 },
    { lat: 48.4027371, lng: 18.7889425 },
    { lat: 48.402728, lng: 18.789093 },
    { lat: 48.4026234, lng: 18.7892687 },
    { lat: 48.4025687, lng: 18.7894945 },
    { lat: 48.4025208, lng: 18.7897216 },
    { lat: 48.4026343, lng: 18.7899459 },
    { lat: 48.4026121, lng: 18.7901441 },
    { lat: 48.4025985, lng: 18.7901869 },
    { lat: 48.4025072, lng: 18.7902304 },
    { lat: 48.402443, lng: 18.7905315 },
    { lat: 48.4025599, lng: 18.7906344 },
    { lat: 48.4025383, lng: 18.79078 },
    { lat: 48.4025311, lng: 18.7908767 },
    { lat: 48.4024498, lng: 18.7910013 },
    { lat: 48.4024549, lng: 18.7911207 },
    { lat: 48.4024447, lng: 18.7912291 },
    { lat: 48.4024907, lng: 18.7913521 },
    { lat: 48.4025236, lng: 18.7914841 },
    { lat: 48.4025548, lng: 18.7916273 },
    { lat: 48.4025333, lng: 18.7918389 },
    { lat: 48.4024969, lng: 18.792048 },
    { lat: 48.4024992, lng: 18.7921155 },
    { lat: 48.402541, lng: 18.7922848 },
    { lat: 48.4025939, lng: 18.7925631 },
    { lat: 48.4025779, lng: 18.7927932 },
    { lat: 48.402552, lng: 18.7931367 },
    { lat: 48.4025445, lng: 18.7933785 },
    { lat: 48.4025031, lng: 18.7936202 },
    { lat: 48.4025906, lng: 18.7938057 },
    { lat: 48.4026306, lng: 18.7939691 },
    { lat: 48.4026858, lng: 18.7941152 },
    { lat: 48.4027164, lng: 18.7942872 },
    { lat: 48.4027668, lng: 18.7944331 },
    { lat: 48.4028064, lng: 18.7946055 },
    { lat: 48.4027938, lng: 18.794667 },
    { lat: 48.4027965, lng: 18.794781 },
    { lat: 48.4028167, lng: 18.7948247 },
    { lat: 48.4029042, lng: 18.795028 },
    { lat: 48.4030838, lng: 18.7952931 },
    { lat: 48.4031963, lng: 18.7954366 },
    { lat: 48.4033175, lng: 18.7955521 },
    { lat: 48.4036303, lng: 18.7958425 },
    { lat: 48.4037316, lng: 18.7959675 },
    { lat: 48.4037798, lng: 18.7963664 },
    { lat: 48.4038912, lng: 18.7967059 },
    { lat: 48.4039156, lng: 18.7969546 },
    { lat: 48.403867, lng: 18.7973593 },
    { lat: 48.4039304, lng: 18.7975811 },
    { lat: 48.4040046, lng: 18.7977696 },
    { lat: 48.404061, lng: 18.7979155 },
    { lat: 48.4040683, lng: 18.7979704 },
    { lat: 48.4040357, lng: 18.798146 },
    { lat: 48.404037, lng: 18.7982555 },
    { lat: 48.404117, lng: 18.7989633 },
    { lat: 48.4041163, lng: 18.7990351 },
    { lat: 48.4040797, lng: 18.7991295 },
    { lat: 48.4040515, lng: 18.7991964 },
    { lat: 48.4040576, lng: 18.7992039 },
    { lat: 48.4041181, lng: 18.799284 },
    { lat: 48.4041801, lng: 18.7993678 },
    { lat: 48.4041485, lng: 18.799516 },
    { lat: 48.4041165, lng: 18.7996181 },
    { lat: 48.4041522, lng: 18.7997175 },
    { lat: 48.4041912, lng: 18.7998339 },
    { lat: 48.4041937, lng: 18.7999053 },
    { lat: 48.4041759, lng: 18.7999969 },
    { lat: 48.404148, lng: 18.8000421 },
    { lat: 48.4041674, lng: 18.8002225 },
    { lat: 48.4043038, lng: 18.8005609 },
    { lat: 48.4043366, lng: 18.8006276 },
    { lat: 48.4043504, lng: 18.8006562 },
    { lat: 48.4044096, lng: 18.8007744 },
    { lat: 48.40444, lng: 18.8008354 },
    { lat: 48.4044676, lng: 18.8008896 },
    { lat: 48.4045349, lng: 18.8010314 },
    { lat: 48.4047298, lng: 18.801179 },
    { lat: 48.4049977, lng: 18.8013854 },
    { lat: 48.4050878, lng: 18.8013594 },
    { lat: 48.4051513, lng: 18.8013441 },
    { lat: 48.4052004, lng: 18.8013798 },
    { lat: 48.4052165, lng: 18.8013836 },
    { lat: 48.4053183, lng: 18.8014105 },
    { lat: 48.4055521, lng: 18.8015684 },
    { lat: 48.4056265, lng: 18.8016984 },
    { lat: 48.4057013, lng: 18.8017058 },
    { lat: 48.4057845, lng: 18.8018951 },
    { lat: 48.4058521, lng: 18.8020205 },
    { lat: 48.4058868, lng: 18.8021239 },
    { lat: 48.4059842, lng: 18.8021745 },
    { lat: 48.4060281, lng: 18.8022807 },
    { lat: 48.4062921, lng: 18.8025159 },
    { lat: 48.4063988, lng: 18.8027251 },
    { lat: 48.4065282, lng: 18.8027634 },
    { lat: 48.4066388, lng: 18.802719 },
    { lat: 48.4067945, lng: 18.8028489 },
    { lat: 48.406909, lng: 18.8030529 },
    { lat: 48.4071194, lng: 18.8032615 },
    { lat: 48.4071902, lng: 18.8034043 },
    { lat: 48.4073183, lng: 18.8034392 },
    { lat: 48.4075045, lng: 18.8034441 },
    { lat: 48.4075709, lng: 18.8035884 },
    { lat: 48.4076967, lng: 18.803603 },
    { lat: 48.408124, lng: 18.8036634 },
    { lat: 48.4082021, lng: 18.8037315 },
    { lat: 48.4082657, lng: 18.8038095 },
    { lat: 48.408314, lng: 18.8038688 },
    { lat: 48.408423, lng: 18.8041181 },
    { lat: 48.4084439, lng: 18.8042363 },
    { lat: 48.4084932, lng: 18.8043391 },
    { lat: 48.4085291, lng: 18.8044186 },
    { lat: 48.4086089, lng: 18.8044989 },
    { lat: 48.4086592, lng: 18.8046648 },
    { lat: 48.4088321, lng: 18.8048427 },
    { lat: 48.4089482, lng: 18.8049618 },
    { lat: 48.4090182, lng: 18.8050336 },
    { lat: 48.4090486, lng: 18.8051229 },
    { lat: 48.4090687, lng: 18.805182 },
    { lat: 48.4091337, lng: 18.8054117 },
    { lat: 48.409196, lng: 18.8055222 },
    { lat: 48.4092156, lng: 18.8056388 },
    { lat: 48.4092754, lng: 18.805724 },
    { lat: 48.4093273, lng: 18.8057895 },
    { lat: 48.4093895, lng: 18.8058354 },
    { lat: 48.40946, lng: 18.8059104 },
    { lat: 48.4094831, lng: 18.8060713 },
    { lat: 48.4095067, lng: 18.8061374 },
    { lat: 48.4095307, lng: 18.8062084 },
    { lat: 48.4095502, lng: 18.8062124 },
    { lat: 48.4095833, lng: 18.8062192 },
    { lat: 48.4096188, lng: 18.8063378 },
    { lat: 48.4096805, lng: 18.8065441 },
    { lat: 48.4097322, lng: 18.8066686 },
    { lat: 48.4097764, lng: 18.8066971 },
    { lat: 48.4099753, lng: 18.8070274 },
    { lat: 48.4099874, lng: 18.8070415 },
    { lat: 48.4102252, lng: 18.8073194 },
    { lat: 48.4102837, lng: 18.8073384 },
    { lat: 48.410305, lng: 18.8073697 },
    { lat: 48.4104939, lng: 18.8076468 },
    { lat: 48.4105394, lng: 18.8076538 },
    { lat: 48.4106449, lng: 18.807751 },
    { lat: 48.4108103, lng: 18.8078943 },
    { lat: 48.4108062, lng: 18.8080094 },
    { lat: 48.4110303, lng: 18.808264 },
    { lat: 48.4110759, lng: 18.8085521 },
    { lat: 48.4111285, lng: 18.8087972 },
    { lat: 48.4111788, lng: 18.8090312 },
    { lat: 48.4111968, lng: 18.8091132 },
    { lat: 48.4111964, lng: 18.8092577 },
    { lat: 48.411269, lng: 18.8093485 },
    { lat: 48.4113036, lng: 18.8094945 },
    { lat: 48.4113752, lng: 18.809589 },
    { lat: 48.4114074, lng: 18.8096888 },
    { lat: 48.4114959, lng: 18.8097855 },
    { lat: 48.4116627, lng: 18.8098708 },
    { lat: 48.4117687, lng: 18.8102785 },
    { lat: 48.4117916, lng: 18.8105612 },
    { lat: 48.4117714, lng: 18.8108025 },
    { lat: 48.4117841, lng: 18.8115588 },
    { lat: 48.4117853, lng: 18.8116271 },
    { lat: 48.4117381, lng: 18.8121236 },
    { lat: 48.4117673, lng: 18.8124414 },
    { lat: 48.4117143, lng: 18.8127405 },
    { lat: 48.4116571, lng: 18.8133308 },
    { lat: 48.4116036, lng: 18.8136225 },
    { lat: 48.4115798, lng: 18.8137492 },
    { lat: 48.4114951, lng: 18.8140674 },
    { lat: 48.4113717, lng: 18.8144213 },
    { lat: 48.4113929, lng: 18.8146548 },
    { lat: 48.4115286, lng: 18.8152649 },
    { lat: 48.4115392, lng: 18.8154255 },
    { lat: 48.4115052, lng: 18.8157415 },
    { lat: 48.4115016, lng: 18.8157636 },
    { lat: 48.4114569, lng: 18.8160912 },
    { lat: 48.411297, lng: 18.8166745 },
    { lat: 48.4113085, lng: 18.8169155 },
    { lat: 48.4111978, lng: 18.8171476 },
    { lat: 48.4112086, lng: 18.8174001 },
    { lat: 48.4112163, lng: 18.817592 },
    { lat: 48.4110132, lng: 18.8179713 },
    { lat: 48.4104095, lng: 18.818546 },
    { lat: 48.4101581, lng: 18.8192586 },
    { lat: 48.410165, lng: 18.8197554 },
    { lat: 48.4103512, lng: 18.8211986 },
    { lat: 48.4103764, lng: 18.8217014 },
    { lat: 48.4104735, lng: 18.8222028 },
    { lat: 48.4106795, lng: 18.822773 },
    { lat: 48.4109226, lng: 18.8230658 },
    { lat: 48.4112275, lng: 18.8232708 },
    { lat: 48.4113909, lng: 18.8233541 },
    { lat: 48.4113978, lng: 18.823377 },
    { lat: 48.4114094, lng: 18.823381 },
    { lat: 48.4114034, lng: 18.82342 },
    { lat: 48.4113967, lng: 18.8234648 },
    { lat: 48.4114496, lng: 18.8234809 },
    { lat: 48.4115309, lng: 18.8235278 },
    { lat: 48.4115929, lng: 18.8235921 },
    { lat: 48.4116366, lng: 18.8236722 },
    { lat: 48.4116755, lng: 18.8237936 },
    { lat: 48.4117632, lng: 18.8240466 },
    { lat: 48.4118266, lng: 18.8242226 },
    { lat: 48.411938, lng: 18.824388 },
    { lat: 48.4120561, lng: 18.8245128 },
    { lat: 48.4121555, lng: 18.8245795 },
    { lat: 48.4122374, lng: 18.8246128 },
    { lat: 48.4123992, lng: 18.8246483 },
    { lat: 48.4129042, lng: 18.8247303 },
    { lat: 48.4129546, lng: 18.8247218 },
    { lat: 48.4129601, lng: 18.8247645 },
    { lat: 48.4129617, lng: 18.8247718 },
    { lat: 48.4129649, lng: 18.824792 },
    { lat: 48.4130099, lng: 18.8251104 },
    { lat: 48.4134468, lng: 18.8247535 },
    { lat: 48.4135283, lng: 18.8247847 },
    { lat: 48.4137137, lng: 18.8248558 },
    { lat: 48.4140377, lng: 18.8250592 },
    { lat: 48.4142726, lng: 18.8252569 },
    { lat: 48.4145604, lng: 18.8257997 },
    { lat: 48.4150267, lng: 18.8285052 },
    { lat: 48.4150349, lng: 18.8285535 },
    { lat: 48.4150636, lng: 18.8286041 },
    { lat: 48.415148, lng: 18.8287542 },
    { lat: 48.4151515, lng: 18.8287535 },
    { lat: 48.4151813, lng: 18.8287453 },
    { lat: 48.4151993, lng: 18.8287404 },
    { lat: 48.4152281, lng: 18.8287323 },
    { lat: 48.4152643, lng: 18.8289716 },
    { lat: 48.4153216, lng: 18.8291636 },
    { lat: 48.4154326, lng: 18.8294064 },
    { lat: 48.4155435, lng: 18.8296106 },
    { lat: 48.4157145, lng: 18.82988 },
    { lat: 48.4158025, lng: 18.8300542 },
    { lat: 48.4158942, lng: 18.830321 },
    { lat: 48.4159869, lng: 18.8306075 },
    { lat: 48.4160662, lng: 18.8308254 },
    { lat: 48.4162047, lng: 18.8310617 },
    { lat: 48.4166097, lng: 18.8315173 },
    { lat: 48.4172205, lng: 18.8321772 },
    { lat: 48.4174331, lng: 18.8324279 },
    { lat: 48.4177241, lng: 18.8328139 },
    { lat: 48.4179026, lng: 18.8331013 },
    { lat: 48.4183774, lng: 18.8339881 },
    { lat: 48.4185242, lng: 18.8342747 },
    { lat: 48.4185865, lng: 18.8344491 },
    { lat: 48.4186317, lng: 18.8346255 },
    { lat: 48.4186607, lng: 18.8347932 },
    { lat: 48.4186785, lng: 18.8350016 },
    { lat: 48.4186791, lng: 18.8352298 },
    { lat: 48.4186778, lng: 18.8352463 },
    { lat: 48.4186535, lng: 18.8354948 },
    { lat: 48.4185962, lng: 18.8357603 },
    { lat: 48.4185316, lng: 18.8359884 },
    { lat: 48.4183269, lng: 18.8366877 },
    { lat: 48.4182178, lng: 18.8370836 },
    { lat: 48.4181583, lng: 18.8373246 },
    { lat: 48.4181224, lng: 18.8375006 },
    { lat: 48.4180965, lng: 18.8377422 },
    { lat: 48.4181059, lng: 18.837986 },
    { lat: 48.4180753, lng: 18.8379829 },
    { lat: 48.4180539, lng: 18.8379808 },
    { lat: 48.4180534, lng: 18.8379936 },
    { lat: 48.4180869, lng: 18.8381594 },
    { lat: 48.4181485, lng: 18.8383658 },
    { lat: 48.4181656, lng: 18.8384135 },
    { lat: 48.4181228, lng: 18.8384398 },
    { lat: 48.4180714, lng: 18.838469 },
    { lat: 48.4180382, lng: 18.83848 },
    { lat: 48.4180004, lng: 18.8384901 },
    { lat: 48.4179236, lng: 18.838513 },
    { lat: 48.4179102, lng: 18.8385146 },
    { lat: 48.4179114, lng: 18.8385368 },
    { lat: 48.4178896, lng: 18.8389684 },
    { lat: 48.417887, lng: 18.8391283 },
    { lat: 48.418041, lng: 18.8393987 },
    { lat: 48.4181017, lng: 18.8396503 },
    { lat: 48.4180469, lng: 18.8400498 },
    { lat: 48.418013, lng: 18.8402653 },
    { lat: 48.4180016, lng: 18.8403906 },
    { lat: 48.4179854, lng: 18.8408174 },
    { lat: 48.4179644, lng: 18.8415778 },
    { lat: 48.4179444, lng: 18.8420416 },
    { lat: 48.4179083, lng: 18.8422787 },
    { lat: 48.4179519, lng: 18.8422704 },
    { lat: 48.4185353, lng: 18.8418009 },
    { lat: 48.4185777, lng: 18.8417767 },
    { lat: 48.4185887, lng: 18.8417679 },
    { lat: 48.4186402, lng: 18.841736 },
    { lat: 48.4186466, lng: 18.841732 },
    { lat: 48.4187265, lng: 18.8415753 },
    { lat: 48.4191066, lng: 18.8415142 },
    { lat: 48.4191894, lng: 18.841394 },
    { lat: 48.4193113, lng: 18.8412444 },
    { lat: 48.4193414, lng: 18.8412135 },
    { lat: 48.4195014, lng: 18.8409973 },
    { lat: 48.4196008, lng: 18.8409142 },
    { lat: 48.4197174, lng: 18.8408156 },
    { lat: 48.4198503, lng: 18.8406182 },
    { lat: 48.4202678, lng: 18.8403833 },
    { lat: 48.420447, lng: 18.840299 },
    { lat: 48.4208021, lng: 18.8401443 },
    { lat: 48.4210493, lng: 18.8400172 },
    { lat: 48.4212178, lng: 18.8399632 },
    { lat: 48.4214423, lng: 18.8397298 },
    { lat: 48.421862, lng: 18.8391904 },
    { lat: 48.4220619, lng: 18.8389602 },
    { lat: 48.4222008, lng: 18.8388153 },
    { lat: 48.4223101, lng: 18.8387045 },
    { lat: 48.4223671, lng: 18.8385653 },
    { lat: 48.4224016, lng: 18.8383841 },
    { lat: 48.4224023, lng: 18.8382037 },
    { lat: 48.4224087, lng: 18.8380222 },
    { lat: 48.42243, lng: 18.8378695 },
    { lat: 48.4225326, lng: 18.8378031 },
    { lat: 48.4226989, lng: 18.8373799 },
    { lat: 48.4226473, lng: 18.837234 },
    { lat: 48.42265, lng: 18.8370498 },
    { lat: 48.4226239, lng: 18.8367458 },
    { lat: 48.4227793, lng: 18.8362645 },
    { lat: 48.4227089, lng: 18.8357808 },
    { lat: 48.4226247, lng: 18.8355542 },
    { lat: 48.422484, lng: 18.8354072 },
    { lat: 48.4222163, lng: 18.8352453 },
    { lat: 48.4220627, lng: 18.8350674 },
    { lat: 48.4215974, lng: 18.8347549 },
    { lat: 48.4214988, lng: 18.8345908 },
    { lat: 48.4214698, lng: 18.8343166 },
    { lat: 48.4213633, lng: 18.8340689 },
    { lat: 48.4212728, lng: 18.8338705 },
    { lat: 48.4212893, lng: 18.8336034 },
    { lat: 48.4212548, lng: 18.8334175 },
    { lat: 48.4211611, lng: 18.8331364 },
    { lat: 48.4211733, lng: 18.8328946 },
    { lat: 48.4211705, lng: 18.8324341 },
    { lat: 48.4211281, lng: 18.8322237 },
    { lat: 48.4211744, lng: 18.8318625 },
    { lat: 48.4210276, lng: 18.830993 },
    { lat: 48.4210648, lng: 18.8308189 },
    { lat: 48.4209966, lng: 18.8306583 },
    { lat: 48.4209781, lng: 18.8305671 },
    { lat: 48.4209295, lng: 18.8301298 },
    { lat: 48.4208932, lng: 18.8298246 },
    { lat: 48.4208472, lng: 18.8294011 },
    { lat: 48.4208594, lng: 18.8290933 },
    { lat: 48.4208498, lng: 18.8286965 },
    { lat: 48.4208426, lng: 18.8284279 },
    { lat: 48.4207954, lng: 18.8281282 },
    { lat: 48.4207324, lng: 18.8278307 },
    { lat: 48.4207698, lng: 18.8276381 },
    { lat: 48.4208229, lng: 18.8273617 },
    { lat: 48.4209794, lng: 18.8269921 },
    { lat: 48.4210212, lng: 18.8268661 },
    { lat: 48.4210721, lng: 18.8267596 },
    { lat: 48.4212046, lng: 18.8265093 },
    { lat: 48.4212287, lng: 18.8264333 },
    { lat: 48.4213736, lng: 18.8261002 },
    { lat: 48.4214794, lng: 18.8257739 },
    { lat: 48.4216142, lng: 18.8252428 },
    { lat: 48.4217201, lng: 18.8247912 },
    { lat: 48.4218163, lng: 18.8244092 },
    { lat: 48.4218862, lng: 18.824129 },
    { lat: 48.4219096, lng: 18.8237591 },
    { lat: 48.4220042, lng: 18.8234763 },
    { lat: 48.4220157, lng: 18.8232662 },
    { lat: 48.4220397, lng: 18.8231825 },
    { lat: 48.4220818, lng: 18.8229314 },
    { lat: 48.4219982, lng: 18.8226066 },
    { lat: 48.4219729, lng: 18.8223408 },
    { lat: 48.4218922, lng: 18.8220273 },
    { lat: 48.4217289, lng: 18.8218382 },
    { lat: 48.4216414, lng: 18.8217136 },
    { lat: 48.4216465, lng: 18.8214233 },
    { lat: 48.4216188, lng: 18.821198 },
    { lat: 48.4215022, lng: 18.8210609 },
    { lat: 48.4213565, lng: 18.8209269 },
    { lat: 48.4212821, lng: 18.8208196 },
    { lat: 48.4212206, lng: 18.8206366 },
    { lat: 48.4212615, lng: 18.8204042 },
    { lat: 48.4211227, lng: 18.8201671 },
    { lat: 48.4209739, lng: 18.8198886 },
    { lat: 48.4207625, lng: 18.819511 },
    { lat: 48.4206261, lng: 18.8194466 },
    { lat: 48.4205925, lng: 18.8193963 },
    { lat: 48.420363, lng: 18.8190528 },
    { lat: 48.4201843, lng: 18.8188268 },
    { lat: 48.4199771, lng: 18.8186419 },
    { lat: 48.4199337, lng: 18.818478 },
    { lat: 48.4199285, lng: 18.818337 },
    { lat: 48.4199567, lng: 18.8181625 },
    { lat: 48.4200874, lng: 18.8176039 },
    { lat: 48.4201641, lng: 18.8173911 },
    { lat: 48.4202596, lng: 18.8171249 },
    { lat: 48.420277, lng: 18.817078 },
    { lat: 48.4202721, lng: 18.8168906 },
    { lat: 48.42027, lng: 18.816812 },
    { lat: 48.4202982, lng: 18.8167736 },
    { lat: 48.4203845, lng: 18.8166563 },
    { lat: 48.420445, lng: 18.816574 },
    { lat: 48.4206432, lng: 18.8163841 },
    { lat: 48.420684, lng: 18.816345 },
    { lat: 48.4207043, lng: 18.8162672 },
    { lat: 48.420773, lng: 18.816003 },
    { lat: 48.4208796, lng: 18.8159011 },
    { lat: 48.420978, lng: 18.815807 },
    { lat: 48.4209861, lng: 18.8157085 },
    { lat: 48.42101, lng: 18.8154203 },
    { lat: 48.421017, lng: 18.815335 },
    { lat: 48.420933, lng: 18.815078 },
    { lat: 48.420897, lng: 18.8149781 },
    { lat: 48.42083, lng: 18.814792 },
    { lat: 48.420854, lng: 18.814543 },
    { lat: 48.420924, lng: 18.814322 },
    { lat: 48.420965, lng: 18.813959 },
    { lat: 48.421039, lng: 18.813654 },
    { lat: 48.4210339, lng: 18.8136403 },
    { lat: 48.421016, lng: 18.8135929 },
    { lat: 48.420923, lng: 18.813346 },
    { lat: 48.420859, lng: 18.81309 },
    { lat: 48.420883, lng: 18.812827 },
    { lat: 48.420881, lng: 18.812656 },
    { lat: 48.4208783, lng: 18.8125856 },
    { lat: 48.4208689, lng: 18.8123386 },
    { lat: 48.420865, lng: 18.812236 },
    { lat: 48.420946, lng: 18.812138 },
    { lat: 48.421025, lng: 18.812005 },
    { lat: 48.421163, lng: 18.811686 },
    { lat: 48.421194, lng: 18.811422 },
    { lat: 48.42123, lng: 18.811265 },
    { lat: 48.421211, lng: 18.811165 },
    { lat: 48.421179, lng: 18.810997 },
    { lat: 48.421258, lng: 18.810832 },
    { lat: 48.4212928, lng: 18.8107437 },
    { lat: 48.4213638, lng: 18.8105635 },
    { lat: 48.421375, lng: 18.810535 },
    { lat: 48.421383, lng: 18.810405 },
    { lat: 48.4214009, lng: 18.8103686 },
    { lat: 48.421439, lng: 18.810291 },
    { lat: 48.421526, lng: 18.810074 },
    { lat: 48.421615, lng: 18.809939 },
    { lat: 48.421751, lng: 18.809761 },
    { lat: 48.421816, lng: 18.809559 },
    { lat: 48.42188, lng: 18.809464 },
    { lat: 48.421955, lng: 18.809392 },
    { lat: 48.422039, lng: 18.809349 },
    { lat: 48.422143, lng: 18.809353 },
    { lat: 48.422204, lng: 18.809322 },
    { lat: 48.42226, lng: 18.809294 },
    { lat: 48.422362, lng: 18.80922 },
    { lat: 48.422486, lng: 18.809186 },
    { lat: 48.422564, lng: 18.809124 },
    { lat: 48.422689, lng: 18.809058 },
    { lat: 48.42282, lng: 18.80896 },
    { lat: 48.422958, lng: 18.808895 },
    { lat: 48.423153, lng: 18.808806 },
    { lat: 48.4231658, lng: 18.8087946 },
    { lat: 48.4232331, lng: 18.8087347 },
    { lat: 48.423317, lng: 18.80866 },
    { lat: 48.423515, lng: 18.808454 },
    { lat: 48.42353, lng: 18.8084216 },
    { lat: 48.423614, lng: 18.80824 },
    { lat: 48.423705, lng: 18.808105 },
    { lat: 48.423793, lng: 18.807989 },
    { lat: 48.423864, lng: 18.807947 },
    { lat: 48.423923, lng: 18.807905 },
    { lat: 48.423985, lng: 18.807673 },
    { lat: 48.424102, lng: 18.807393 },
    { lat: 48.4241166, lng: 18.8073109 },
    { lat: 48.4242, lng: 18.806842 },
    { lat: 48.424304, lng: 18.806663 },
    { lat: 48.424418, lng: 18.806477 },
    { lat: 48.424552, lng: 18.806418 },
    { lat: 48.424602, lng: 18.806271 },
    { lat: 48.424711, lng: 18.806068 },
    { lat: 48.424964, lng: 18.805758 },
    { lat: 48.4250532, lng: 18.805664 },
    { lat: 48.425131, lng: 18.805582 },
    { lat: 48.4251619, lng: 18.8055682 },
    { lat: 48.425315, lng: 18.8055 },
    { lat: 48.425444, lng: 18.805449 },
    { lat: 48.425634, lng: 18.805358 },
    { lat: 48.425794, lng: 18.805217 },
    { lat: 48.4259496, lng: 18.8051008 },
    { lat: 48.425971, lng: 18.805078 },
    { lat: 48.42615, lng: 18.804844 },
    { lat: 48.4261612, lng: 18.8048338 },
    { lat: 48.4263201, lng: 18.804689 },
    { lat: 48.426391, lng: 18.804411 },
    { lat: 48.426434, lng: 18.803984 },
    { lat: 48.426458, lng: 18.803668 },
    { lat: 48.42641, lng: 18.803474 },
    { lat: 48.42639, lng: 18.803213 },
    { lat: 48.426379, lng: 18.803001 },
    { lat: 48.426342, lng: 18.802804 },
    { lat: 48.426243, lng: 18.802601 },
    { lat: 48.426191, lng: 18.802225 },
    { lat: 48.42617, lng: 18.802071 },
    { lat: 48.426132, lng: 18.801971 },
    { lat: 48.426111, lng: 18.801923 },
    { lat: 48.426075, lng: 18.801817 },
    { lat: 48.4261496, lng: 18.8016034 },
    { lat: 48.426207, lng: 18.801201 },
    { lat: 48.4261983, lng: 18.8010448 },
    { lat: 48.426181, lng: 18.800735 },
    { lat: 48.4261955, lng: 18.8006234 },
    { lat: 48.426201, lng: 18.800581 },
    { lat: 48.426361, lng: 18.800522 },
    { lat: 48.426389, lng: 18.800136 },
    { lat: 48.426467, lng: 18.799799 },
    { lat: 48.426341, lng: 18.799504 },
    { lat: 48.426244, lng: 18.799328 },
    { lat: 48.426235, lng: 18.799099 },
    { lat: 48.426023, lng: 18.798508 },
    { lat: 48.425916, lng: 18.79833 },
    { lat: 48.425843, lng: 18.798308 },
    { lat: 48.425815, lng: 18.798302 },
    { lat: 48.4257438, lng: 18.7982812 },
    { lat: 48.425661, lng: 18.798257 },
    { lat: 48.4256519, lng: 18.7982157 },
    { lat: 48.4254409, lng: 18.79726 },
    { lat: 48.42535, lng: 18.796848 },
    { lat: 48.425244, lng: 18.796725 },
    { lat: 48.4251136, lng: 18.7966322 },
    { lat: 48.425081, lng: 18.796609 },
    { lat: 48.4249209, lng: 18.7965348 },
    { lat: 48.424889, lng: 18.79652 },
    { lat: 48.4248617, lng: 18.7964682 },
    { lat: 48.424779, lng: 18.796311 },
    { lat: 48.424773, lng: 18.796125 },
    { lat: 48.4248292, lng: 18.7959764 },
    { lat: 48.424855, lng: 18.795908 },
    { lat: 48.424918, lng: 18.795814 },
    { lat: 48.424949, lng: 18.795486 },
    { lat: 48.424978, lng: 18.795251 },
    { lat: 48.424941, lng: 18.795021 },
    { lat: 48.424936, lng: 18.794757 },
    { lat: 48.42473, lng: 18.794429 },
    { lat: 48.4247217, lng: 18.7943465 },
    { lat: 48.424685, lng: 18.793983 },
    { lat: 48.424597, lng: 18.793641 },
    { lat: 48.424592, lng: 18.793458 },
    { lat: 48.424618, lng: 18.793266 },
    { lat: 48.424685, lng: 18.79322 },
    { lat: 48.4248, lng: 18.793024 },
    { lat: 48.424893, lng: 18.792791 },
    { lat: 48.424995, lng: 18.792656 },
    { lat: 48.4250427, lng: 18.7925509 },
    { lat: 48.425171, lng: 18.7922685 },
    { lat: 48.425188, lng: 18.792231 },
    { lat: 48.425248, lng: 18.791868 },
    { lat: 48.425288, lng: 18.791798 },
    { lat: 48.425309, lng: 18.791614 },
    { lat: 48.425437, lng: 18.791476 },
    { lat: 48.425579, lng: 18.791392 },
    { lat: 48.425755, lng: 18.791227 },
    { lat: 48.425928, lng: 18.791032 },
    { lat: 48.426172, lng: 18.790972 },
    { lat: 48.426261, lng: 18.790901 },
    { lat: 48.4262842, lng: 18.7908858 },
    { lat: 48.426475, lng: 18.790761 },
    { lat: 48.426658, lng: 18.790623 },
    { lat: 48.42677, lng: 18.790506 },
    { lat: 48.4267861, lng: 18.7904759 },
    { lat: 48.426938, lng: 18.790193 },
    { lat: 48.4271, lng: 18.790057 },
    { lat: 48.427139, lng: 18.789804 },
    { lat: 48.427229, lng: 18.789547 },
    { lat: 48.4272856, lng: 18.789491 },
    { lat: 48.427503, lng: 18.789276 },
    { lat: 48.427609, lng: 18.789219 },
    { lat: 48.427742, lng: 18.788967 },
    { lat: 48.42783, lng: 18.788649 },
    { lat: 48.427968, lng: 18.788513 },
    { lat: 48.428037, lng: 18.788376 },
    { lat: 48.4282266, lng: 18.7882132 },
    { lat: 48.428249, lng: 18.788194 },
    { lat: 48.4284188, lng: 18.7881467 },
    { lat: 48.4285, lng: 18.788124 },
    { lat: 48.428716, lng: 18.788028 },
    { lat: 48.428811, lng: 18.787689 },
    { lat: 48.429018, lng: 18.787744 },
    { lat: 48.429216, lng: 18.787389 },
    { lat: 48.429361, lng: 18.787088 },
    { lat: 48.4294951, lng: 18.7868837 },
    { lat: 48.429535, lng: 18.786823 },
    { lat: 48.429744, lng: 18.786789 },
    { lat: 48.429937, lng: 18.786816 },
    { lat: 48.4300304, lng: 18.7868881 },
    { lat: 48.430095, lng: 18.786938 },
    { lat: 48.430234, lng: 18.787101 },
    { lat: 48.430371, lng: 18.787226 },
    { lat: 48.430482, lng: 18.787245 },
    { lat: 48.430662, lng: 18.787216 },
    { lat: 48.430795, lng: 18.787013 },
    { lat: 48.430918, lng: 18.786944 },
    { lat: 48.431231, lng: 18.786919 },
    { lat: 48.431515, lng: 18.786864 },
    { lat: 48.4315755, lng: 18.786857 },
    { lat: 48.431766, lng: 18.786835 },
    { lat: 48.431913, lng: 18.786744 },
    { lat: 48.432002, lng: 18.786668 },
    { lat: 48.43209, lng: 18.786541 },
    { lat: 48.432099, lng: 18.786386 },
    { lat: 48.432327, lng: 18.786118 },
    { lat: 48.432427, lng: 18.785943 },
    { lat: 48.432622, lng: 18.785952 },
    { lat: 48.4326773, lng: 18.7858284 },
    { lat: 48.432746, lng: 18.785675 },
    { lat: 48.432815, lng: 18.785444 },
    { lat: 48.433013, lng: 18.784954 },
    { lat: 48.433254, lng: 18.784698 },
    { lat: 48.4332646, lng: 18.7846667 },
    { lat: 48.433407, lng: 18.7842486 },
    { lat: 48.4335538, lng: 18.7838174 },
    { lat: 48.433574, lng: 18.783758 },
    { lat: 48.433765, lng: 18.783277 },
    { lat: 48.433959, lng: 18.783043 },
    { lat: 48.434036, lng: 18.782695 },
    { lat: 48.434166, lng: 18.782478 },
    { lat: 48.434215, lng: 18.782124 },
    { lat: 48.434226, lng: 18.781829 },
    { lat: 48.434258, lng: 18.781326 },
    { lat: 48.434229, lng: 18.78101 },
    { lat: 48.43418, lng: 18.780422 },
    { lat: 48.43424, lng: 18.780265 },
    { lat: 48.434241, lng: 18.78024 },
    { lat: 48.43424, lng: 18.780137 },
    { lat: 48.43426, lng: 18.78003 },
    { lat: 48.4343224, lng: 18.7794386 },
    { lat: 48.43433, lng: 18.779367 },
    { lat: 48.4342254, lng: 18.7784687 },
    { lat: 48.434193, lng: 18.77819 },
    { lat: 48.434152, lng: 18.777644 },
    { lat: 48.4341611, lng: 18.777509 },
    { lat: 48.4341705, lng: 18.7773695 },
    { lat: 48.434174, lng: 18.777318 },
    { lat: 48.434087, lng: 18.776777 },
    { lat: 48.434087, lng: 18.776558 },
    { lat: 48.434482, lng: 18.775233 },
    { lat: 48.4345402, lng: 18.7749551 },
    { lat: 48.434611, lng: 18.774617 },
    { lat: 48.434803, lng: 18.774258 },
    { lat: 48.434852, lng: 18.773906 },
    { lat: 48.434968, lng: 18.773425 },
    { lat: 48.434973, lng: 18.77268 },
    { lat: 48.434967, lng: 18.772323 },
    { lat: 48.43491, lng: 18.772212 },
    { lat: 48.434839, lng: 18.771197 },
    { lat: 48.434853, lng: 18.770979 },
    { lat: 48.434873, lng: 18.770914 },
    { lat: 48.434967, lng: 18.770745 },
    { lat: 48.435188, lng: 18.770358 },
    { lat: 48.435288, lng: 18.769793 },
    { lat: 48.4354744, lng: 18.7693673 },
    { lat: 48.435739, lng: 18.768763 },
    { lat: 48.436057, lng: 18.768303 },
    { lat: 48.4361093, lng: 18.7681764 },
    { lat: 48.43614, lng: 18.768102 },
    { lat: 48.4362713, lng: 18.7678994 },
    { lat: 48.436442, lng: 18.767636 },
    { lat: 48.436631, lng: 18.767552 },
    { lat: 48.43748, lng: 18.767365 },
    { lat: 48.437644, lng: 18.767045 },
    { lat: 48.437868, lng: 18.766955 },
    { lat: 48.437856, lng: 18.766915 },
    { lat: 48.437873, lng: 18.766904 },
    { lat: 48.437717, lng: 18.766487 },
    { lat: 48.437654, lng: 18.766362 },
    { lat: 48.437579, lng: 18.766211 },
    { lat: 48.437429, lng: 18.765958 },
    { lat: 48.437258, lng: 18.765471 },
    { lat: 48.43725, lng: 18.765355 },
    { lat: 48.437217, lng: 18.765201 },
    { lat: 48.437158, lng: 18.765077 },
    { lat: 48.437088, lng: 18.764938 },
    { lat: 48.437033, lng: 18.764864 },
    { lat: 48.437, lng: 18.764823 },
    { lat: 48.437012, lng: 18.764737 },
    { lat: 48.436926, lng: 18.764512 },
    { lat: 48.436872, lng: 18.764417 },
    { lat: 48.43683, lng: 18.764371 },
    { lat: 48.43668, lng: 18.764383 },
    { lat: 48.436498, lng: 18.764199 },
    { lat: 48.436354, lng: 18.763984 },
    { lat: 48.436099, lng: 18.763746 },
    { lat: 48.435984, lng: 18.763699 },
    { lat: 48.435796, lng: 18.76385 },
    { lat: 48.435693, lng: 18.76382 },
    { lat: 48.435374, lng: 18.763913 },
    { lat: 48.435203, lng: 18.763934 },
    { lat: 48.435198, lng: 18.763934 },
    { lat: 48.435143, lng: 18.76396 },
    { lat: 48.43514, lng: 18.763959 },
    { lat: 48.435083, lng: 18.763938 },
    { lat: 48.435027, lng: 18.763918 },
    { lat: 48.434876, lng: 18.763946 },
    { lat: 48.434531, lng: 18.763916 },
    { lat: 48.434294, lng: 18.76395 },
    { lat: 48.434103, lng: 18.763885 },
    { lat: 48.433878, lng: 18.763916 },
    { lat: 48.4337, lng: 18.763837 },
    { lat: 48.433534, lng: 18.763666 },
    { lat: 48.433405, lng: 18.763589 },
    { lat: 48.433293, lng: 18.763545 },
    { lat: 48.433208, lng: 18.763468 },
    { lat: 48.432969, lng: 18.763281 },
    { lat: 48.432822, lng: 18.763226 },
    { lat: 48.432283, lng: 18.762562 },
    { lat: 48.432219, lng: 18.762533 },
    { lat: 48.432097, lng: 18.762415 },
    { lat: 48.43208, lng: 18.762399 },
    { lat: 48.432047, lng: 18.762374 },
    { lat: 48.431983, lng: 18.762327 },
    { lat: 48.431939, lng: 18.762094 },
    { lat: 48.431923, lng: 18.762019 },
    { lat: 48.431758, lng: 18.76173 },
    { lat: 48.431636, lng: 18.761515 },
    { lat: 48.431579, lng: 18.761352 },
    { lat: 48.431497, lng: 18.761321 },
    { lat: 48.431364, lng: 18.761191 },
    { lat: 48.431109, lng: 18.761179 },
    { lat: 48.430896, lng: 18.761151 },
    { lat: 48.430721, lng: 18.761081 },
    { lat: 48.430527, lng: 18.760988 },
    { lat: 48.430311, lng: 18.760972 },
    { lat: 48.430213, lng: 18.760983 },
    { lat: 48.430019, lng: 18.760953 },
    { lat: 48.429881, lng: 18.760926 },
    { lat: 48.429795, lng: 18.760907 },
    { lat: 48.429665, lng: 18.760878 },
    { lat: 48.42947, lng: 18.760889 },
    { lat: 48.429259, lng: 18.761042 },
    { lat: 48.42914, lng: 18.76107 },
    { lat: 48.428942, lng: 18.760832 },
    { lat: 48.428857, lng: 18.760823 },
    { lat: 48.428789, lng: 18.76075 },
    { lat: 48.428596, lng: 18.760696 },
    { lat: 48.428502, lng: 18.760609 },
    { lat: 48.428377, lng: 18.760559 },
    { lat: 48.428317, lng: 18.76047 },
    { lat: 48.42813, lng: 18.760389 },
    { lat: 48.42807, lng: 18.760403 },
    { lat: 48.427833, lng: 18.76042 },
    { lat: 48.427576, lng: 18.760515 },
    { lat: 48.427554, lng: 18.760504 },
    { lat: 48.427396, lng: 18.760562 },
    { lat: 48.427193, lng: 18.760544 },
    { lat: 48.427, lng: 18.760473 },
    { lat: 48.426899, lng: 18.760692 },
    { lat: 48.426828, lng: 18.760753 },
    { lat: 48.426753, lng: 18.760855 },
    { lat: 48.426603, lng: 18.761008 },
    { lat: 48.426449, lng: 18.76098 },
    { lat: 48.426374, lng: 18.760928 },
    { lat: 48.426221, lng: 18.760874 },
    { lat: 48.426097, lng: 18.760781 },
    { lat: 48.425816, lng: 18.760646 },
    { lat: 48.425667, lng: 18.760591 },
    { lat: 48.425432, lng: 18.760473 },
    { lat: 48.425299, lng: 18.760371 },
    { lat: 48.425185, lng: 18.760355 },
    { lat: 48.425092, lng: 18.760311 },
    { lat: 48.424974, lng: 18.760413 },
    { lat: 48.424822, lng: 18.760275 },
    { lat: 48.424594, lng: 18.760325 },
    { lat: 48.424521, lng: 18.760301 },
    { lat: 48.424419, lng: 18.76044 },
    { lat: 48.42434, lng: 18.760424 },
    { lat: 48.42425, lng: 18.760293 },
    { lat: 48.423952, lng: 18.760283 },
    { lat: 48.4237, lng: 18.760249 },
    { lat: 48.423603, lng: 18.76012 },
    { lat: 48.423053, lng: 18.759815 },
    { lat: 48.422837, lng: 18.759793 },
    { lat: 48.422584, lng: 18.75969 },
    { lat: 48.422518, lng: 18.759691 },
    { lat: 48.422405, lng: 18.759694 },
    { lat: 48.42231, lng: 18.759643 },
    { lat: 48.422067, lng: 18.759608 },
    { lat: 48.421755, lng: 18.759519 },
    { lat: 48.421652, lng: 18.759505 },
    { lat: 48.421537, lng: 18.759419 },
    { lat: 48.421474, lng: 18.75949 },
    { lat: 48.421388, lng: 18.75948 },
    { lat: 48.421045, lng: 18.75957 },
    { lat: 48.420941, lng: 18.759545 },
    { lat: 48.420754, lng: 18.759733 },
    { lat: 48.420686, lng: 18.759631 },
    { lat: 48.420553, lng: 18.759536 },
    { lat: 48.420375, lng: 18.759599 },
    { lat: 48.420051, lng: 18.759465 },
    { lat: 48.419791, lng: 18.759401 },
    { lat: 48.419545, lng: 18.75932 },
    { lat: 48.418721, lng: 18.759024 },
    { lat: 48.418663, lng: 18.759086 },
    { lat: 48.418531, lng: 18.759075 },
    { lat: 48.418383, lng: 18.75894 },
    { lat: 48.41838, lng: 18.758916 },
    { lat: 48.418345, lng: 18.758854 },
    { lat: 48.418218, lng: 18.75863 },
    { lat: 48.417828, lng: 18.758063 },
    { lat: 48.417728, lng: 18.758042 },
    { lat: 48.417304, lng: 18.758122 },
    { lat: 48.417181, lng: 18.758029 },
    { lat: 48.416926, lng: 18.757737 },
    { lat: 48.416711, lng: 18.757408 },
    { lat: 48.416532, lng: 18.757165 },
    { lat: 48.416481, lng: 18.757048 },
    { lat: 48.416249, lng: 18.756745 },
    { lat: 48.416012, lng: 18.756529 },
    { lat: 48.4159, lng: 18.756501 },
    { lat: 48.415522, lng: 18.756221 },
    { lat: 48.415451, lng: 18.756132 },
    { lat: 48.415411, lng: 18.756083 },
    { lat: 48.415383, lng: 18.756058 },
    { lat: 48.41528, lng: 18.755969 },
    { lat: 48.415129, lng: 18.755805 },
    { lat: 48.415095, lng: 18.755768 },
    { lat: 48.414602, lng: 18.755235 },
    { lat: 48.414416, lng: 18.755216 },
    { lat: 48.414255, lng: 18.755212 },
    { lat: 48.414146, lng: 18.755251 },
    { lat: 48.41409, lng: 18.755216 },
    { lat: 48.413521, lng: 18.755192 },
    { lat: 48.412843, lng: 18.754716 },
    { lat: 48.412568, lng: 18.754162 },
    { lat: 48.412395, lng: 18.753756 },
    { lat: 48.412361, lng: 18.753615 },
    { lat: 48.412131, lng: 18.752644 },
    { lat: 48.412115, lng: 18.752582 },
    { lat: 48.41208, lng: 18.752433 },
    { lat: 48.411162, lng: 18.750644 },
  ],
  Baďan: [
    { lat: 48.3059, lng: 18.852358 },
    { lat: 48.30594, lng: 18.852462 },
    { lat: 48.306061, lng: 18.852779 },
    { lat: 48.306027, lng: 18.853158 },
    { lat: 48.305943, lng: 18.854077 },
    { lat: 48.305594, lng: 18.85618 },
    { lat: 48.304978, lng: 18.858539 },
    { lat: 48.305016, lng: 18.858566 },
    { lat: 48.30502, lng: 18.860619 },
    { lat: 48.306106, lng: 18.862718 },
    { lat: 48.305745, lng: 18.863309 },
    { lat: 48.305738, lng: 18.863365 },
    { lat: 48.307571, lng: 18.866548 },
    { lat: 48.30877, lng: 18.867893 },
    { lat: 48.311946, lng: 18.87146 },
    { lat: 48.311985, lng: 18.871523 },
    { lat: 48.31141, lng: 18.872342 },
    { lat: 48.311207, lng: 18.873204 },
    { lat: 48.311193, lng: 18.873257 },
    { lat: 48.31114, lng: 18.873323 },
    { lat: 48.310749, lng: 18.873677 },
    { lat: 48.310676, lng: 18.873765 },
    { lat: 48.310372, lng: 18.874127 },
    { lat: 48.310152, lng: 18.874363 },
    { lat: 48.309861, lng: 18.874642 },
    { lat: 48.309696, lng: 18.874765 },
    { lat: 48.309307, lng: 18.875031 },
    { lat: 48.309021, lng: 18.875266 },
    { lat: 48.308823, lng: 18.875452 },
    { lat: 48.308408, lng: 18.875676 },
    { lat: 48.308387, lng: 18.875829 },
    { lat: 48.308314, lng: 18.876176 },
    { lat: 48.308508, lng: 18.87716 },
    { lat: 48.308542, lng: 18.87733 },
    { lat: 48.308523, lng: 18.87741 },
    { lat: 48.308385, lng: 18.877765 },
    { lat: 48.308237, lng: 18.877931 },
    { lat: 48.3079669, lng: 18.878128 },
    { lat: 48.308012, lng: 18.878293 },
    { lat: 48.308029, lng: 18.878348 },
    { lat: 48.307792, lng: 18.878876 },
    { lat: 48.307734, lng: 18.879531 },
    { lat: 48.308548, lng: 18.880944 },
    { lat: 48.309411, lng: 18.881517 },
    { lat: 48.310746, lng: 18.881909 },
    { lat: 48.310789, lng: 18.881936 },
    { lat: 48.313488, lng: 18.882878 },
    { lat: 48.313529, lng: 18.883496 },
    { lat: 48.313394, lng: 18.88434 },
    { lat: 48.313324, lng: 18.884608 },
    { lat: 48.313323, lng: 18.884641 },
    { lat: 48.313709, lng: 18.884603 },
    { lat: 48.314732, lng: 18.884691 },
    { lat: 48.315158, lng: 18.884896 },
    { lat: 48.315418, lng: 18.885174 },
    { lat: 48.315425, lng: 18.885143 },
    { lat: 48.316693, lng: 18.883891 },
    { lat: 48.318302, lng: 18.882706 },
    { lat: 48.318691, lng: 18.882208 },
    { lat: 48.320886, lng: 18.880566 },
    { lat: 48.322681, lng: 18.880171 },
    { lat: 48.3237645, lng: 18.8804715 },
    { lat: 48.3243938, lng: 18.8806215 },
    { lat: 48.3252833, lng: 18.8803369 },
    { lat: 48.3253099, lng: 18.8805273 },
    { lat: 48.3263585, lng: 18.8801982 },
    { lat: 48.3276006, lng: 18.8800768 },
    { lat: 48.3276377, lng: 18.8799906 },
    { lat: 48.3291872, lng: 18.8796887 },
    { lat: 48.3306729, lng: 18.8776387 },
    { lat: 48.3311046, lng: 18.8771796 },
    { lat: 48.3314994, lng: 18.876856 },
    { lat: 48.3322483, lng: 18.8763173 },
    { lat: 48.3328928, lng: 18.8758922 },
    { lat: 48.3341965, lng: 18.8746847 },
    { lat: 48.3345004, lng: 18.8743663 },
    { lat: 48.3351899, lng: 18.8739656 },
    { lat: 48.3353328, lng: 18.8727032 },
    { lat: 48.3356671, lng: 18.8713712 },
    { lat: 48.3360455, lng: 18.8701464 },
    { lat: 48.3368522, lng: 18.8677717 },
    { lat: 48.3372103, lng: 18.8672832 },
    { lat: 48.3374466, lng: 18.8667122 },
    { lat: 48.3376301, lng: 18.8659929 },
    { lat: 48.3376413, lng: 18.8659483 },
    { lat: 48.3376703, lng: 18.8658333 },
    { lat: 48.3376771, lng: 18.8657868 },
    { lat: 48.3378069, lng: 18.864731 },
    { lat: 48.3378959, lng: 18.8638632 },
    { lat: 48.3378688, lng: 18.8635393 },
    { lat: 48.3378396, lng: 18.8628062 },
    { lat: 48.3378386, lng: 18.8627861 },
    { lat: 48.3378355, lng: 18.8627053 },
    { lat: 48.3378248, lng: 18.8624358 },
    { lat: 48.3377941, lng: 18.862387 },
    { lat: 48.3377401, lng: 18.8623026 },
    { lat: 48.3377164, lng: 18.8622603 },
    { lat: 48.3380517, lng: 18.8621547 },
    { lat: 48.3388797, lng: 18.8621828 },
    { lat: 48.3390817, lng: 18.8619514 },
    { lat: 48.3393091, lng: 18.8620694 },
    { lat: 48.3394283, lng: 18.8617176 },
    { lat: 48.3395756, lng: 18.8614083 },
    { lat: 48.3399486, lng: 18.8613362 },
    { lat: 48.3401387, lng: 18.8614394 },
    { lat: 48.3402866, lng: 18.8614196 },
    { lat: 48.3403386, lng: 18.8616786 },
    { lat: 48.3409063, lng: 18.8622121 },
    { lat: 48.3409692, lng: 18.862422 },
    { lat: 48.3409581, lng: 18.8627072 },
    { lat: 48.3408868, lng: 18.8630264 },
    { lat: 48.3412802, lng: 18.8642992 },
    { lat: 48.341457, lng: 18.8647084 },
    { lat: 48.3416966, lng: 18.8649982 },
    { lat: 48.3423756, lng: 18.8652412 },
    { lat: 48.3426259, lng: 18.8650662 },
    { lat: 48.3430151, lng: 18.864894 },
    { lat: 48.3432272, lng: 18.8647239 },
    { lat: 48.3433942, lng: 18.8644877 },
    { lat: 48.3435175, lng: 18.8644088 },
    { lat: 48.3436438, lng: 18.8645155 },
    { lat: 48.3440108, lng: 18.8646565 },
    { lat: 48.3442781, lng: 18.8648297 },
    { lat: 48.3443636, lng: 18.8647615 },
    { lat: 48.3444792, lng: 18.8644903 },
    { lat: 48.3445291, lng: 18.8640637 },
    { lat: 48.3446796, lng: 18.8638773 },
    { lat: 48.3448673, lng: 18.8638482 },
    { lat: 48.3451857, lng: 18.8639169 },
    { lat: 48.3455571, lng: 18.8636032 },
    { lat: 48.3457343, lng: 18.8637338 },
    { lat: 48.34585, lng: 18.8638787 },
    { lat: 48.3459801, lng: 18.8639085 },
    { lat: 48.3461556, lng: 18.8638344 },
    { lat: 48.3462816, lng: 18.8636353 },
    { lat: 48.3465414, lng: 18.8634474 },
    { lat: 48.3466538, lng: 18.86305 },
    { lat: 48.34667, lng: 18.862845 },
    { lat: 48.3467704, lng: 18.862712 },
    { lat: 48.3469943, lng: 18.862528 },
    { lat: 48.3471698, lng: 18.8622639 },
    { lat: 48.3476221, lng: 18.8619843 },
    { lat: 48.3479934, lng: 18.8621212 },
    { lat: 48.3482276, lng: 18.8619172 },
    { lat: 48.3484118, lng: 18.8616199 },
    { lat: 48.3485817, lng: 18.8614202 },
    { lat: 48.3486322, lng: 18.861197 },
    { lat: 48.3490898, lng: 18.8604602 },
    { lat: 48.3496905, lng: 18.8606694 },
    { lat: 48.3500894, lng: 18.8602302 },
    { lat: 48.3504449, lng: 18.8600414 },
    { lat: 48.3505331, lng: 18.8598931 },
    { lat: 48.3507584, lng: 18.8597182 },
    { lat: 48.3509438, lng: 18.859834 },
    { lat: 48.3510582, lng: 18.8597993 },
    { lat: 48.3512933, lng: 18.8596178 },
    { lat: 48.3517271, lng: 18.85963 },
    { lat: 48.3519448, lng: 18.8593102 },
    { lat: 48.3523747, lng: 18.8590751 },
    { lat: 48.3524735, lng: 18.8587629 },
    { lat: 48.3527487, lng: 18.8584622 },
    { lat: 48.3529996, lng: 18.8581474 },
    { lat: 48.3534724, lng: 18.8576987 },
    { lat: 48.3536928, lng: 18.8575562 },
    { lat: 48.3539044, lng: 18.8572694 },
    { lat: 48.3541325, lng: 18.8571022 },
    { lat: 48.3545523, lng: 18.8570866 },
    { lat: 48.3547759, lng: 18.8569842 },
    { lat: 48.3553377, lng: 18.8564056 },
    { lat: 48.3555798, lng: 18.8562906 },
    { lat: 48.3557747, lng: 18.8558951 },
    { lat: 48.3563717, lng: 18.8555386 },
    { lat: 48.3565303, lng: 18.8556387 },
    { lat: 48.3568089, lng: 18.85564 },
    { lat: 48.3569499, lng: 18.8557706 },
    { lat: 48.3569681, lng: 18.8557295 },
    { lat: 48.3568152, lng: 18.8555889 },
    { lat: 48.3565152, lng: 18.855499 },
    { lat: 48.3565152, lng: 18.8553721 },
    { lat: 48.3572604, lng: 18.8555009 },
    { lat: 48.3576669, lng: 18.8555712 },
    { lat: 48.3580356, lng: 18.8554868 },
    { lat: 48.3579215, lng: 18.8550254 },
    { lat: 48.3578747, lng: 18.8549299 },
    { lat: 48.3572663, lng: 18.8535767 },
    { lat: 48.3565517, lng: 18.8529147 },
    { lat: 48.3557349, lng: 18.8523682 },
    { lat: 48.3552981, lng: 18.8520366 },
    { lat: 48.3541497, lng: 18.8502343 },
    { lat: 48.3534722, lng: 18.8493297 },
    { lat: 48.3526871, lng: 18.8488058 },
    { lat: 48.3520888, lng: 18.8482997 },
    { lat: 48.3513223, lng: 18.8475723 },
    { lat: 48.3511226, lng: 18.8467272 },
    { lat: 48.3508317, lng: 18.8452827 },
    { lat: 48.3507143, lng: 18.8444163 },
    { lat: 48.3505889, lng: 18.8435043 },
    { lat: 48.3505624, lng: 18.8430817 },
    { lat: 48.3504985, lng: 18.8421004 },
    { lat: 48.3504595, lng: 18.8418289 },
    { lat: 48.3502747, lng: 18.840512 },
    { lat: 48.3498501, lng: 18.8385618 },
    { lat: 48.3499526, lng: 18.837807 },
    { lat: 48.3499513, lng: 18.837781 },
    { lat: 48.3499465, lng: 18.8377113 },
    { lat: 48.349944, lng: 18.8376645 },
    { lat: 48.3498573, lng: 18.8368469 },
    { lat: 48.3497647, lng: 18.8359818 },
    { lat: 48.349677, lng: 18.8353834 },
    { lat: 48.349633, lng: 18.835144 },
    { lat: 48.3493938, lng: 18.8309234 },
    { lat: 48.3492893, lng: 18.8290763 },
    { lat: 48.3492039, lng: 18.8284795 },
    { lat: 48.3491399, lng: 18.8277989 },
    { lat: 48.3490814, lng: 18.8270025 },
    { lat: 48.346032, lng: 18.82427 },
    { lat: 48.345562, lng: 18.824213 },
    { lat: 48.345066, lng: 18.824018 },
    { lat: 48.344879, lng: 18.823944 },
    { lat: 48.344534, lng: 18.823808 },
    { lat: 48.34414, lng: 18.823661 },
    { lat: 48.343848, lng: 18.823506 },
    { lat: 48.34377, lng: 18.823539 },
    { lat: 48.343369, lng: 18.823705 },
    { lat: 48.342995, lng: 18.8237 },
    { lat: 48.342435, lng: 18.82374 },
    { lat: 48.341533, lng: 18.823761 },
    { lat: 48.341109, lng: 18.82377 },
    { lat: 48.340938, lng: 18.823793 },
    { lat: 48.340601, lng: 18.823664 },
    { lat: 48.340479, lng: 18.823695 },
    { lat: 48.340002, lng: 18.823815 },
    { lat: 48.339658, lng: 18.823771 },
    { lat: 48.339543, lng: 18.823935 },
    { lat: 48.339303, lng: 18.824124 },
    { lat: 48.338888, lng: 18.824523 },
    { lat: 48.338438, lng: 18.824726 },
    { lat: 48.338245, lng: 18.824894 },
    { lat: 48.337836, lng: 18.824915 },
    { lat: 48.337436, lng: 18.824979 },
    { lat: 48.337056, lng: 18.824977 },
    { lat: 48.336677, lng: 18.824926 },
    { lat: 48.33651, lng: 18.824775 },
    { lat: 48.336143, lng: 18.824765 },
    { lat: 48.335729, lng: 18.824214 },
    { lat: 48.335586, lng: 18.824024 },
    { lat: 48.335388, lng: 18.823997 },
    { lat: 48.334327, lng: 18.823854 },
    { lat: 48.334015, lng: 18.823812 },
    { lat: 48.333804, lng: 18.824051 },
    { lat: 48.333723, lng: 18.824057 },
    { lat: 48.332934, lng: 18.824119 },
    { lat: 48.332661, lng: 18.824137 },
    { lat: 48.332635, lng: 18.824142 },
    { lat: 48.332354, lng: 18.824412 },
    { lat: 48.332163, lng: 18.824594 },
    { lat: 48.331737, lng: 18.824858 },
    { lat: 48.331569, lng: 18.824892 },
    { lat: 48.33126, lng: 18.824955 },
    { lat: 48.330675, lng: 18.825727 },
    { lat: 48.329028, lng: 18.826816 },
    { lat: 48.328899, lng: 18.827417 },
    { lat: 48.328031, lng: 18.827635 },
    { lat: 48.327969, lng: 18.827668 },
    { lat: 48.327893, lng: 18.827662 },
    { lat: 48.327778, lng: 18.827721 },
    { lat: 48.327734, lng: 18.827792 },
    { lat: 48.32698, lng: 18.828191 },
    { lat: 48.326957, lng: 18.828204 },
    { lat: 48.32692, lng: 18.828224 },
    { lat: 48.326891, lng: 18.828239 },
    { lat: 48.326845, lng: 18.828263 },
    { lat: 48.325432, lng: 18.828841 },
    { lat: 48.325226, lng: 18.828972 },
    { lat: 48.324808, lng: 18.829227 },
    { lat: 48.324598, lng: 18.829374 },
    { lat: 48.324506, lng: 18.829457 },
    { lat: 48.32408, lng: 18.829839 },
    { lat: 48.323984, lng: 18.829983 },
    { lat: 48.323722, lng: 18.830221 },
    { lat: 48.323478, lng: 18.830435 },
    { lat: 48.323201, lng: 18.830799 },
    { lat: 48.323052, lng: 18.830912 },
    { lat: 48.322998, lng: 18.831004 },
    { lat: 48.32305, lng: 18.831256 },
    { lat: 48.322802, lng: 18.831677 },
    { lat: 48.322597, lng: 18.83187 },
    { lat: 48.322494, lng: 18.83196 },
    { lat: 48.322298, lng: 18.832131 },
    { lat: 48.322292, lng: 18.832256 },
    { lat: 48.32229, lng: 18.832282 },
    { lat: 48.321506, lng: 18.832761 },
    { lat: 48.321153, lng: 18.832993 },
    { lat: 48.321143, lng: 18.832999 },
    { lat: 48.321167, lng: 18.833578 },
    { lat: 48.321235, lng: 18.834209 },
    { lat: 48.321375, lng: 18.835525 },
    { lat: 48.32164, lng: 18.837169 },
    { lat: 48.321665, lng: 18.837325 },
    { lat: 48.321766, lng: 18.837532 },
    { lat: 48.321767, lng: 18.837543 },
    { lat: 48.32177, lng: 18.837606 },
    { lat: 48.321797, lng: 18.838023 },
    { lat: 48.321773, lng: 18.838374 },
    { lat: 48.321761, lng: 18.838566 },
    { lat: 48.321726, lng: 18.83921 },
    { lat: 48.321675, lng: 18.839607 },
    { lat: 48.32165, lng: 18.83981 },
    { lat: 48.321571, lng: 18.840139 },
    { lat: 48.321355, lng: 18.840419 },
    { lat: 48.321321, lng: 18.840474 },
    { lat: 48.321253, lng: 18.840589 },
    { lat: 48.321208, lng: 18.840688 },
    { lat: 48.321128, lng: 18.84086 },
    { lat: 48.321119, lng: 18.840847 },
    { lat: 48.321092, lng: 18.840895 },
    { lat: 48.320996, lng: 18.841076 },
    { lat: 48.320927, lng: 18.841205 },
    { lat: 48.320909, lng: 18.841249 },
    { lat: 48.320895, lng: 18.841282 },
    { lat: 48.320809, lng: 18.841492 },
    { lat: 48.320794, lng: 18.841529 },
    { lat: 48.320785, lng: 18.841553 },
    { lat: 48.320771, lng: 18.841724 },
    { lat: 48.320771, lng: 18.841728 },
    { lat: 48.320789, lng: 18.841981 },
    { lat: 48.320774, lng: 18.842494 },
    { lat: 48.320823, lng: 18.842925 },
    { lat: 48.32084, lng: 18.843075 },
    { lat: 48.32084, lng: 18.843368 },
    { lat: 48.32082, lng: 18.843734 },
    { lat: 48.320752, lng: 18.844032 },
    { lat: 48.320748, lng: 18.844046 },
    { lat: 48.320632, lng: 18.844521 },
    { lat: 48.320615, lng: 18.844791 },
    { lat: 48.320584, lng: 18.844966 },
    { lat: 48.320591, lng: 18.845151 },
    { lat: 48.320605, lng: 18.845341 },
    { lat: 48.320609, lng: 18.845394 },
    { lat: 48.32025, lng: 18.845443 },
    { lat: 48.32014, lng: 18.845457 },
    { lat: 48.320095, lng: 18.845491 },
    { lat: 48.319712, lng: 18.845779 },
    { lat: 48.319267, lng: 18.845855 },
    { lat: 48.317136, lng: 18.84622 },
    { lat: 48.316714, lng: 18.846336 },
    { lat: 48.316276, lng: 18.846456 },
    { lat: 48.316257, lng: 18.846461 },
    { lat: 48.315381, lng: 18.846943 },
    { lat: 48.314316, lng: 18.848036 },
    { lat: 48.31431, lng: 18.848036 },
    { lat: 48.314125, lng: 18.848061 },
    { lat: 48.313159, lng: 18.848193 },
    { lat: 48.313126, lng: 18.848221 },
    { lat: 48.31264, lng: 18.848564 },
    { lat: 48.311843, lng: 18.849128 },
    { lat: 48.3115, lng: 18.848663 },
    { lat: 48.311288, lng: 18.848411 },
    { lat: 48.311061, lng: 18.848129 },
    { lat: 48.310757, lng: 18.847799 },
    { lat: 48.310722, lng: 18.847888 },
    { lat: 48.310349, lng: 18.849461 },
    { lat: 48.30965, lng: 18.849805 },
    { lat: 48.308699, lng: 18.850367 },
    { lat: 48.307476, lng: 18.851088 },
    { lat: 48.306989, lng: 18.85161 },
    { lat: 48.306628, lng: 18.851943 },
    { lat: 48.306572, lng: 18.851975 },
    { lat: 48.3059, lng: 18.852358 },
  ],
  Podhorie: [
    { lat: 48.5117684, lng: 18.9797361 },
    { lat: 48.5133477, lng: 18.9802147 },
    { lat: 48.513811, lng: 18.9798583 },
    { lat: 48.5139181, lng: 18.9796596 },
    { lat: 48.5146269, lng: 18.9783453 },
    { lat: 48.5148999, lng: 18.9780378 },
    { lat: 48.5150632, lng: 18.9777238 },
    { lat: 48.5151657, lng: 18.9776338 },
    { lat: 48.5158854, lng: 18.9776859 },
    { lat: 48.5163922, lng: 18.9777842 },
    { lat: 48.5169672, lng: 18.9775831 },
    { lat: 48.5176485, lng: 18.9770607 },
    { lat: 48.5182894, lng: 18.9762712 },
    { lat: 48.518424, lng: 18.9756624 },
    { lat: 48.5190717, lng: 18.9749173 },
    { lat: 48.5190983, lng: 18.9748865 },
    { lat: 48.5191451, lng: 18.9748323 },
    { lat: 48.5193211, lng: 18.9743668 },
    { lat: 48.5194368, lng: 18.9734984 },
    { lat: 48.5203134, lng: 18.9714752 },
    { lat: 48.5203047, lng: 18.9707297 },
    { lat: 48.5201617, lng: 18.9703101 },
    { lat: 48.5200085, lng: 18.9698925 },
    { lat: 48.5200251, lng: 18.9696665 },
    { lat: 48.5204011, lng: 18.9684163 },
    { lat: 48.5204435, lng: 18.9682745 },
    { lat: 48.5204657, lng: 18.9682001 },
    { lat: 48.5211742, lng: 18.9667268 },
    { lat: 48.5215038, lng: 18.9663149 },
    { lat: 48.5218454, lng: 18.9660969 },
    { lat: 48.5221121, lng: 18.9658171 },
    { lat: 48.5223212, lng: 18.9651875 },
    { lat: 48.5223312, lng: 18.9646977 },
    { lat: 48.5222838, lng: 18.9640149 },
    { lat: 48.5218314, lng: 18.9622202 },
    { lat: 48.5214281, lng: 18.9609007 },
    { lat: 48.5215652, lng: 18.9604257 },
    { lat: 48.5215716, lng: 18.9598242 },
    { lat: 48.5217863, lng: 18.9590186 },
    { lat: 48.5219134, lng: 18.9583064 },
    { lat: 48.5219582, lng: 18.9580544 },
    { lat: 48.5219149, lng: 18.9575401 },
    { lat: 48.521593, lng: 18.9570204 },
    { lat: 48.5213726, lng: 18.956416 },
    { lat: 48.5209956, lng: 18.9555246 },
    { lat: 48.5205765, lng: 18.954716 },
    { lat: 48.5203466, lng: 18.9545182 },
    { lat: 48.5202888, lng: 18.954468 },
    { lat: 48.5202189, lng: 18.9542715 },
    { lat: 48.5202794, lng: 18.9537766 },
    { lat: 48.5209995, lng: 18.952735 },
    { lat: 48.5211733, lng: 18.9523406 },
    { lat: 48.5211992, lng: 18.9519884 },
    { lat: 48.5212961, lng: 18.9516567 },
    { lat: 48.5214802, lng: 18.9509137 },
    { lat: 48.5217551, lng: 18.9503561 },
    { lat: 48.5218087, lng: 18.9501244 },
    { lat: 48.5216275, lng: 18.9496258 },
    { lat: 48.522104, lng: 18.9487574 },
    { lat: 48.5224141, lng: 18.9480937 },
    { lat: 48.522559, lng: 18.9474673 },
    { lat: 48.5231762, lng: 18.9473434 },
    { lat: 48.5234502, lng: 18.9466639 },
    { lat: 48.5234739, lng: 18.946001 },
    { lat: 48.5235388, lng: 18.9458152 },
    { lat: 48.5237444, lng: 18.9457366 },
    { lat: 48.5238433, lng: 18.9454854 },
    { lat: 48.523888, lng: 18.9449922 },
    { lat: 48.523827, lng: 18.9446411 },
    { lat: 48.5238048, lng: 18.9444719 },
    { lat: 48.5236518, lng: 18.944145 },
    { lat: 48.5233095, lng: 18.9428782 },
    { lat: 48.5232942, lng: 18.9428231 },
    { lat: 48.5231064, lng: 18.9421274 },
    { lat: 48.5228109, lng: 18.9420369 },
    { lat: 48.5224211, lng: 18.9415177 },
    { lat: 48.5218394, lng: 18.941078 },
    { lat: 48.5211279, lng: 18.9414736 },
    { lat: 48.5210034, lng: 18.9404505 },
    { lat: 48.5209059, lng: 18.9397839 },
    { lat: 48.5207614, lng: 18.9393149 },
    { lat: 48.5208594, lng: 18.9383467 },
    { lat: 48.5208849, lng: 18.9381609 },
    { lat: 48.5207087, lng: 18.9377852 },
    { lat: 48.5206765, lng: 18.9376395 },
    { lat: 48.5205649, lng: 18.9373761 },
    { lat: 48.5205201, lng: 18.937116 },
    { lat: 48.5204969, lng: 18.9367723 },
    { lat: 48.5204276, lng: 18.9364997 },
    { lat: 48.5203157, lng: 18.9362993 },
    { lat: 48.5202579, lng: 18.9360933 },
    { lat: 48.5200669, lng: 18.9352704 },
    { lat: 48.5199748, lng: 18.9347057 },
    { lat: 48.5207943, lng: 18.9338237 },
    { lat: 48.5211372, lng: 18.9333645 },
    { lat: 48.5220418, lng: 18.9316761 },
    { lat: 48.5220622, lng: 18.9316371 },
    { lat: 48.5222392, lng: 18.9313057 },
    { lat: 48.5228044, lng: 18.9296216 },
    { lat: 48.5235925, lng: 18.9288505 },
    { lat: 48.5238781, lng: 18.9283282 },
    { lat: 48.5240996, lng: 18.9279663 },
    { lat: 48.5244479, lng: 18.9274804 },
    { lat: 48.524528, lng: 18.9273687 },
    { lat: 48.5246652, lng: 18.9269667 },
    { lat: 48.5247609, lng: 18.9261755 },
    { lat: 48.5248625, lng: 18.9258037 },
    { lat: 48.5248929, lng: 18.9256924 },
    { lat: 48.5251388, lng: 18.924793 },
    { lat: 48.5251642, lng: 18.9246983 },
    { lat: 48.5252138, lng: 18.9245189 },
    { lat: 48.5253173, lng: 18.9239676 },
    { lat: 48.525316, lng: 18.9235064 },
    { lat: 48.5253968, lng: 18.9229492 },
    { lat: 48.5256388, lng: 18.9225866 },
    { lat: 48.5256941, lng: 18.9220623 },
    { lat: 48.5257187, lng: 18.921157 },
    { lat: 48.5258866, lng: 18.9204754 },
    { lat: 48.5260575, lng: 18.9200962 },
    { lat: 48.5262731, lng: 18.9197709 },
    { lat: 48.5264771, lng: 18.9189596 },
    { lat: 48.5267067, lng: 18.9184198 },
    { lat: 48.5270723, lng: 18.917834 },
    { lat: 48.5276134, lng: 18.9171314 },
    { lat: 48.5279328, lng: 18.9169184 },
    { lat: 48.528245, lng: 18.9168062 },
    { lat: 48.5286006, lng: 18.9159832 },
    { lat: 48.5289994, lng: 18.9156013 },
    { lat: 48.5291895, lng: 18.9151305 },
    { lat: 48.5292379, lng: 18.9147809 },
    { lat: 48.5292388, lng: 18.9144677 },
    { lat: 48.5291766, lng: 18.9141583 },
    { lat: 48.5291971, lng: 18.9139425 },
    { lat: 48.5292056, lng: 18.9137128 },
    { lat: 48.5294994, lng: 18.9133754 },
    { lat: 48.5299079, lng: 18.9130994 },
    { lat: 48.5307888, lng: 18.9121403 },
    { lat: 48.5308971, lng: 18.911789 },
    { lat: 48.5309762, lng: 18.9113745 },
    { lat: 48.5308661, lng: 18.9102002 },
    { lat: 48.5307631, lng: 18.9098871 },
    { lat: 48.5306849, lng: 18.9093324 },
    { lat: 48.5306332, lng: 18.9082413 },
    { lat: 48.5306611, lng: 18.9070316 },
    { lat: 48.5306677, lng: 18.906985 },
    { lat: 48.530672, lng: 18.9069578 },
    { lat: 48.5306378, lng: 18.9069504 },
    { lat: 48.5305831, lng: 18.906939 },
    { lat: 48.5305179, lng: 18.9068328 },
    { lat: 48.530439, lng: 18.9065962 },
    { lat: 48.5303498, lng: 18.9065692 },
    { lat: 48.5303081, lng: 18.9064816 },
    { lat: 48.530228, lng: 18.9063711 },
    { lat: 48.5302905, lng: 18.906319 },
    { lat: 48.5306767, lng: 18.9062376 },
    { lat: 48.5307358, lng: 18.906225 },
    { lat: 48.5315343, lng: 18.9060563 },
    { lat: 48.5320123, lng: 18.9057258 },
    { lat: 48.5327295, lng: 18.9059675 },
    { lat: 48.5332317, lng: 18.9064774 },
    { lat: 48.5344473, lng: 18.9069392 },
    { lat: 48.534795, lng: 18.9068822 },
    { lat: 48.5356663, lng: 18.9067235 },
    { lat: 48.5358695, lng: 18.9067698 },
    { lat: 48.535914, lng: 18.9067803 },
    { lat: 48.536743, lng: 18.906955 },
    { lat: 48.536787, lng: 18.902542 },
    { lat: 48.535847, lng: 18.902195 },
    { lat: 48.534393, lng: 18.901976 },
    { lat: 48.533844, lng: 18.901683 },
    { lat: 48.533587, lng: 18.901596 },
    { lat: 48.532131, lng: 18.901843 },
    { lat: 48.531881, lng: 18.901827 },
    { lat: 48.531618, lng: 18.901489 },
    { lat: 48.531445, lng: 18.900883 },
    { lat: 48.531422, lng: 18.900802 },
    { lat: 48.531406, lng: 18.90069 },
    { lat: 48.531383, lng: 18.900574 },
    { lat: 48.531382, lng: 18.900331 },
    { lat: 48.531237, lng: 18.900578 },
    { lat: 48.530943, lng: 18.900343 },
    { lat: 48.530786, lng: 18.900188 },
    { lat: 48.530552, lng: 18.900105 },
    { lat: 48.530174, lng: 18.900003 },
    { lat: 48.529992, lng: 18.899984 },
    { lat: 48.529763, lng: 18.900031 },
    { lat: 48.529348, lng: 18.900392 },
    { lat: 48.528931, lng: 18.900745 },
    { lat: 48.527921, lng: 18.90068 },
    { lat: 48.527591, lng: 18.900359 },
    { lat: 48.526664, lng: 18.899233 },
    { lat: 48.526271, lng: 18.899416 },
    { lat: 48.525715, lng: 18.89926 },
    { lat: 48.525316, lng: 18.899945 },
    { lat: 48.524994, lng: 18.90026 },
    { lat: 48.524926, lng: 18.900394 },
    { lat: 48.524683, lng: 18.900449 },
    { lat: 48.524428, lng: 18.900643 },
    { lat: 48.524269, lng: 18.900603 },
    { lat: 48.524056, lng: 18.900678 },
    { lat: 48.524018, lng: 18.900422 },
    { lat: 48.52374, lng: 18.900516 },
    { lat: 48.523877, lng: 18.899895 },
    { lat: 48.523986, lng: 18.899761 },
    { lat: 48.523792, lng: 18.899415 },
    { lat: 48.523792, lng: 18.898555 },
    { lat: 48.523007, lng: 18.898248 },
    { lat: 48.522433, lng: 18.897725 },
    { lat: 48.522333, lng: 18.897207 },
    { lat: 48.521687, lng: 18.896991 },
    { lat: 48.521521, lng: 18.896326 },
    { lat: 48.521281, lng: 18.896296 },
    { lat: 48.520484, lng: 18.896341 },
    { lat: 48.519867, lng: 18.895247 },
    { lat: 48.51939, lng: 18.895189 },
    { lat: 48.518837, lng: 18.895244 },
    { lat: 48.517667, lng: 18.895004 },
    { lat: 48.516886, lng: 18.893861 },
    { lat: 48.516419, lng: 18.89385 },
    { lat: 48.515436, lng: 18.893118 },
    { lat: 48.51503, lng: 18.892335 },
    { lat: 48.514677, lng: 18.891905 },
    { lat: 48.514388, lng: 18.891461 },
    { lat: 48.514156, lng: 18.890935 },
    { lat: 48.513875, lng: 18.890201 },
    { lat: 48.51338, lng: 18.889861 },
    { lat: 48.512812, lng: 18.889582 },
    { lat: 48.512262, lng: 18.889399 },
    { lat: 48.511674, lng: 18.889372 },
    { lat: 48.511488, lng: 18.88925 },
    { lat: 48.511002, lng: 18.890249 },
    { lat: 48.510884, lng: 18.890191 },
    { lat: 48.51074, lng: 18.890126 },
    { lat: 48.510927, lng: 18.889965 },
    { lat: 48.510978, lng: 18.888844 },
    { lat: 48.510798, lng: 18.888379 },
    { lat: 48.510745, lng: 18.888027 },
    { lat: 48.510715, lng: 18.887433 },
    { lat: 48.510625, lng: 18.887084 },
    { lat: 48.510395, lng: 18.886764 },
    { lat: 48.5106, lng: 18.886378 },
    { lat: 48.51075, lng: 18.886059 },
    { lat: 48.510789, lng: 18.885878 },
    { lat: 48.510882, lng: 18.885862 },
    { lat: 48.511094, lng: 18.885588 },
    { lat: 48.511242, lng: 18.885274 },
    { lat: 48.511386, lng: 18.884845 },
    { lat: 48.51163, lng: 18.884325 },
    { lat: 48.511856, lng: 18.883981 },
    { lat: 48.5117652, lng: 18.8839941 },
    { lat: 48.5117189, lng: 18.8839942 },
    { lat: 48.5116017, lng: 18.8839945 },
    { lat: 48.5114713, lng: 18.8840442 },
    { lat: 48.5112781, lng: 18.8840139 },
    { lat: 48.5106165, lng: 18.8833653 },
    { lat: 48.5103595, lng: 18.8833488 },
    { lat: 48.5097785, lng: 18.8835375 },
    { lat: 48.5093557, lng: 18.8835806 },
    { lat: 48.5089621, lng: 18.8834308 },
    { lat: 48.5086736, lng: 18.883194 },
    { lat: 48.5083676, lng: 18.8828145 },
    { lat: 48.5080289, lng: 18.8823937 },
    { lat: 48.5077197, lng: 18.8818198 },
    { lat: 48.5073968, lng: 18.8811596 },
    { lat: 48.5071679, lng: 18.880833 },
    { lat: 48.5068648, lng: 18.8805094 },
    { lat: 48.506632, lng: 18.8803269 },
    { lat: 48.5064227, lng: 18.8801164 },
    { lat: 48.5063002, lng: 18.8797997 },
    { lat: 48.506143, lng: 18.8795066 },
    { lat: 48.5058585, lng: 18.8792832 },
    { lat: 48.5055917, lng: 18.8791367 },
    { lat: 48.5053575, lng: 18.8791223 },
    { lat: 48.5047326, lng: 18.8792507 },
    { lat: 48.5045425, lng: 18.8793914 },
    { lat: 48.5044072, lng: 18.8795836 },
    { lat: 48.5042747, lng: 18.8796635 },
    { lat: 48.5041747, lng: 18.8796894 },
    { lat: 48.5038805, lng: 18.8796706 },
    { lat: 48.5035498, lng: 18.8795761 },
    { lat: 48.5032808, lng: 18.8795646 },
    { lat: 48.5030196, lng: 18.8797044 },
    { lat: 48.502784, lng: 18.8799233 },
    { lat: 48.5026469, lng: 18.8802597 },
    { lat: 48.5024796, lng: 18.88062 },
    { lat: 48.5022979, lng: 18.8809225 },
    { lat: 48.502125, lng: 18.8810697 },
    { lat: 48.5019508, lng: 18.8811236 },
    { lat: 48.5015454, lng: 18.8810868 },
    { lat: 48.5007051, lng: 18.8810403 },
    { lat: 48.5003744, lng: 18.8810336 },
    { lat: 48.500012, lng: 18.8814415 },
    { lat: 48.4997943, lng: 18.8814799 },
    { lat: 48.4996433, lng: 18.8816352 },
    { lat: 48.4995611, lng: 18.8821379 },
    { lat: 48.4991222, lng: 18.8825463 },
    { lat: 48.4989066, lng: 18.8828272 },
    { lat: 48.4986508, lng: 18.8830261 },
    { lat: 48.4981578, lng: 18.8830657 },
    { lat: 48.4979211, lng: 18.8828923 },
    { lat: 48.4977057, lng: 18.8825893 },
    { lat: 48.4971113, lng: 18.8824994 },
    { lat: 48.4966656, lng: 18.8826675 },
    { lat: 48.4964698, lng: 18.8826496 },
    { lat: 48.4959305, lng: 18.8826022 },
    { lat: 48.4952558, lng: 18.883446 },
    { lat: 48.4950971, lng: 18.8836015 },
    { lat: 48.4950746, lng: 18.8836244 },
    { lat: 48.4945005, lng: 18.8842096 },
    { lat: 48.493773, lng: 18.8849902 },
    { lat: 48.4934976, lng: 18.8855728 },
    { lat: 48.4932707, lng: 18.886225 },
    { lat: 48.4926916, lng: 18.8880614 },
    { lat: 48.4927217, lng: 18.8880764 },
    { lat: 48.4926071, lng: 18.888607 },
    { lat: 48.4915888, lng: 18.8895684 },
    { lat: 48.4907086, lng: 18.8910336 },
    { lat: 48.4903679, lng: 18.8922781 },
    { lat: 48.4897767, lng: 18.8927252 },
    { lat: 48.4897362, lng: 18.8928835 },
    { lat: 48.4897389, lng: 18.8930467 },
    { lat: 48.4900073, lng: 18.893589 },
    { lat: 48.4899857, lng: 18.8938475 },
    { lat: 48.4899023, lng: 18.8940449 },
    { lat: 48.4899664, lng: 18.8940868 },
    { lat: 48.490578, lng: 18.894487 },
    { lat: 48.4910859, lng: 18.8952399 },
    { lat: 48.4915847, lng: 18.8959448 },
    { lat: 48.4916358, lng: 18.8978946 },
    { lat: 48.4916213, lng: 18.897966 },
    { lat: 48.4913097, lng: 18.8993991 },
    { lat: 48.4914984, lng: 18.9001019 },
    { lat: 48.4916962, lng: 18.9004833 },
    { lat: 48.4918594, lng: 18.9012644 },
    { lat: 48.4918083, lng: 18.9016749 },
    { lat: 48.4918417, lng: 18.9022131 },
    { lat: 48.4918544, lng: 18.9024132 },
    { lat: 48.4918168, lng: 18.9024716 },
    { lat: 48.4914602, lng: 18.9030348 },
    { lat: 48.4914214, lng: 18.903308 },
    { lat: 48.4913963, lng: 18.9034809 },
    { lat: 48.4913442, lng: 18.9035211 },
    { lat: 48.4909686, lng: 18.9040395 },
    { lat: 48.4900569, lng: 18.905801 },
    { lat: 48.4899496, lng: 18.9060578 },
    { lat: 48.4899204, lng: 18.906128 },
    { lat: 48.4898833, lng: 18.9070668 },
    { lat: 48.4898307, lng: 18.9081682 },
    { lat: 48.4896718, lng: 18.9089602 },
    { lat: 48.4896752, lng: 18.9095936 },
    { lat: 48.4896798, lng: 18.9104252 },
    { lat: 48.4894321, lng: 18.9118368 },
    { lat: 48.4892773, lng: 18.912093 },
    { lat: 48.48927, lng: 18.9121671 },
    { lat: 48.4888617, lng: 18.9124916 },
    { lat: 48.4878207, lng: 18.912993 },
    { lat: 48.4871232, lng: 18.9131994 },
    { lat: 48.4871681, lng: 18.9134163 },
    { lat: 48.487303, lng: 18.9140614 },
    { lat: 48.4874629, lng: 18.9153966 },
    { lat: 48.4873532, lng: 18.9160869 },
    { lat: 48.4873445, lng: 18.9161384 },
    { lat: 48.4872037, lng: 18.9171717 },
    { lat: 48.4874612, lng: 18.9197422 },
    { lat: 48.487605, lng: 18.919997 },
    { lat: 48.4876087, lng: 18.9200232 },
    { lat: 48.4876381, lng: 18.9201133 },
    { lat: 48.4876418, lng: 18.9201398 },
    { lat: 48.488039, lng: 18.9227577 },
    { lat: 48.4881695, lng: 18.9236045 },
    { lat: 48.4890155, lng: 18.9233674 },
    { lat: 48.4892081, lng: 18.923608 },
    { lat: 48.4895562, lng: 18.9240414 },
    { lat: 48.4895847, lng: 18.9240892 },
    { lat: 48.4899401, lng: 18.9245093 },
    { lat: 48.4900651, lng: 18.9249039 },
    { lat: 48.4898987, lng: 18.9255471 },
    { lat: 48.4892898, lng: 18.9263373 },
    { lat: 48.489457, lng: 18.9266534 },
    { lat: 48.4900847, lng: 18.9272352 },
    { lat: 48.4903306, lng: 18.9275692 },
    { lat: 48.4903346, lng: 18.9275776 },
    { lat: 48.4908179, lng: 18.9289735 },
    { lat: 48.4909301, lng: 18.930832 },
    { lat: 48.4911703, lng: 18.9308759 },
    { lat: 48.4916531, lng: 18.930813 },
    { lat: 48.4923026, lng: 18.9305089 },
    { lat: 48.4926528, lng: 18.9307181 },
    { lat: 48.4927002, lng: 18.9307425 },
    { lat: 48.4930245, lng: 18.9309067 },
    { lat: 48.4931933, lng: 18.9309226 },
    { lat: 48.4934422, lng: 18.9310938 },
    { lat: 48.4935369, lng: 18.9311589 },
    { lat: 48.4939429, lng: 18.9317125 },
    { lat: 48.4945366, lng: 18.9323129 },
    { lat: 48.4945942, lng: 18.9326023 },
    { lat: 48.4945938, lng: 18.9326823 },
    { lat: 48.494559, lng: 18.9328826 },
    { lat: 48.494484, lng: 18.9333101 },
    { lat: 48.4943617, lng: 18.934485 },
    { lat: 48.4943935, lng: 18.9351048 },
    { lat: 48.4945752, lng: 18.935639 },
    { lat: 48.4942994, lng: 18.9365459 },
    { lat: 48.4940614, lng: 18.9375995 },
    { lat: 48.4940979, lng: 18.9380254 },
    { lat: 48.4941723, lng: 18.9384031 },
    { lat: 48.4947216, lng: 18.9391751 },
    { lat: 48.4947309, lng: 18.9390489 },
    { lat: 48.4953643, lng: 18.9409783 },
    { lat: 48.4953135, lng: 18.9417012 },
    { lat: 48.4953799, lng: 18.9421813 },
    { lat: 48.4955044, lng: 18.9426105 },
    { lat: 48.4955259, lng: 18.9428774 },
    { lat: 48.4954297, lng: 18.9431997 },
    { lat: 48.4951929, lng: 18.9438571 },
    { lat: 48.4955785, lng: 18.9445863 },
    { lat: 48.4956255, lng: 18.944932 },
    { lat: 48.4959287, lng: 18.9449516 },
    { lat: 48.4965014, lng: 18.9450395 },
    { lat: 48.4970398, lng: 18.9449355 },
    { lat: 48.4974312, lng: 18.9456448 },
    { lat: 48.4974997, lng: 18.9461247 },
    { lat: 48.4977045, lng: 18.946422 },
    { lat: 48.4975893, lng: 18.9481826 },
    { lat: 48.4979115, lng: 18.9482644 },
    { lat: 48.4984279, lng: 18.9490336 },
    { lat: 48.4990224, lng: 18.9499211 },
    { lat: 48.4992531, lng: 18.9507714 },
    { lat: 48.499539, lng: 18.9510718 },
    { lat: 48.4995968, lng: 18.9513673 },
    { lat: 48.4995152, lng: 18.9516192 },
    { lat: 48.4994016, lng: 18.9519331 },
    { lat: 48.4993529, lng: 18.9523721 },
    { lat: 48.4993109, lng: 18.9529177 },
    { lat: 48.4992272, lng: 18.953064 },
    { lat: 48.4993127, lng: 18.9532162 },
    { lat: 48.4993944, lng: 18.9534698 },
    { lat: 48.499467, lng: 18.9539193 },
    { lat: 48.499483, lng: 18.9539676 },
    { lat: 48.4994932, lng: 18.954056 },
    { lat: 48.4994998, lng: 18.9541214 },
    { lat: 48.4993802, lng: 18.9545355 },
    { lat: 48.499178, lng: 18.9547267 },
    { lat: 48.4989409, lng: 18.9549647 },
    { lat: 48.4986872, lng: 18.9551633 },
    { lat: 48.4986843, lng: 18.9551949 },
    { lat: 48.4986357, lng: 18.9557007 },
    { lat: 48.4986277, lng: 18.9557838 },
    { lat: 48.4985316, lng: 18.9563082 },
    { lat: 48.4990633, lng: 18.9567857 },
    { lat: 48.4993186, lng: 18.9567284 },
    { lat: 48.499808, lng: 18.9566181 },
    { lat: 48.5008904, lng: 18.9563744 },
    { lat: 48.5027022, lng: 18.9569906 },
    { lat: 48.5028676, lng: 18.9586368 },
    { lat: 48.5031006, lng: 18.9599325 },
    { lat: 48.5032576, lng: 18.9617938 },
    { lat: 48.5032809, lng: 18.9623376 },
    { lat: 48.5031022, lng: 18.9625908 },
    { lat: 48.5030448, lng: 18.962591 },
    { lat: 48.503003, lng: 18.9625912 },
    { lat: 48.502985, lng: 18.9625915 },
    { lat: 48.5028983, lng: 18.962748 },
    { lat: 48.5030702, lng: 18.9631765 },
    { lat: 48.5031284, lng: 18.9634137 },
    { lat: 48.5032242, lng: 18.9642082 },
    { lat: 48.5037157, lng: 18.965046 },
    { lat: 48.5038006, lng: 18.9653629 },
    { lat: 48.5039965, lng: 18.965997 },
    { lat: 48.5043935, lng: 18.9672119 },
    { lat: 48.5044982, lng: 18.9675657 },
    { lat: 48.5045057, lng: 18.9676462 },
    { lat: 48.5045259, lng: 18.9678441 },
    { lat: 48.5045372, lng: 18.9679537 },
    { lat: 48.5046199, lng: 18.9682919 },
    { lat: 48.5046307, lng: 18.9685697 },
    { lat: 48.5049179, lng: 18.9690951 },
    { lat: 48.5052584, lng: 18.9695362 },
    { lat: 48.5054213, lng: 18.9696998 },
    { lat: 48.5054395, lng: 18.9697044 },
    { lat: 48.5054931, lng: 18.9697181 },
    { lat: 48.5056475, lng: 18.9697583 },
    { lat: 48.506163, lng: 18.970183 },
    { lat: 48.5063584, lng: 18.9704559 },
    { lat: 48.5064398, lng: 18.9707932 },
    { lat: 48.5065165, lng: 18.9712587 },
    { lat: 48.5066527, lng: 18.9714502 },
    { lat: 48.507024, lng: 18.9719453 },
    { lat: 48.5073626, lng: 18.9723063 },
    { lat: 48.5076052, lng: 18.973127 },
    { lat: 48.5075938, lng: 18.9736773 },
    { lat: 48.50759, lng: 18.9738477 },
    { lat: 48.5077579, lng: 18.9739997 },
    { lat: 48.5077639, lng: 18.9740055 },
    { lat: 48.5077692, lng: 18.9740219 },
    { lat: 48.5078754, lng: 18.9743588 },
    { lat: 48.5079293, lng: 18.9745532 },
    { lat: 48.5079666, lng: 18.9746875 },
    { lat: 48.5080297, lng: 18.9747521 },
    { lat: 48.5082686, lng: 18.9749947 },
    { lat: 48.5083695, lng: 18.9750969 },
    { lat: 48.5085281, lng: 18.9752176 },
    { lat: 48.5087164, lng: 18.9754412 },
    { lat: 48.5088639, lng: 18.9756365 },
    { lat: 48.5088874, lng: 18.9757454 },
    { lat: 48.5088317, lng: 18.9760438 },
    { lat: 48.5088438, lng: 18.9760815 },
    { lat: 48.5089634, lng: 18.9764631 },
    { lat: 48.5090711, lng: 18.9768056 },
    { lat: 48.5091043, lng: 18.9771701 },
    { lat: 48.5091066, lng: 18.9771912 },
    { lat: 48.5091568, lng: 18.9773383 },
    { lat: 48.5092778, lng: 18.9776959 },
    { lat: 48.5091985, lng: 18.9792835 },
    { lat: 48.5092043, lng: 18.9798845 },
    { lat: 48.5090532, lng: 18.9804815 },
    { lat: 48.508902, lng: 18.9808815 },
    { lat: 48.5088245, lng: 18.9811725 },
    { lat: 48.5086428, lng: 18.9817026 },
    { lat: 48.5086083, lng: 18.9820415 },
    { lat: 48.5086658, lng: 18.9819968 },
    { lat: 48.5090594, lng: 18.9816894 },
    { lat: 48.5090833, lng: 18.9816721 },
    { lat: 48.5091723, lng: 18.9816046 },
    { lat: 48.5092004, lng: 18.9815818 },
    { lat: 48.5092114, lng: 18.9815748 },
    { lat: 48.509417, lng: 18.9814274 },
    { lat: 48.5094507, lng: 18.9814032 },
    { lat: 48.5097617, lng: 18.9811775 },
    { lat: 48.5098911, lng: 18.9810826 },
    { lat: 48.510269, lng: 18.9808011 },
    { lat: 48.5117684, lng: 18.9797361 },
  ],
  BanskáBelá: [
    { lat: 48.482915, lng: 19.000607 },
    { lat: 48.483298, lng: 18.999338 },
    { lat: 48.483694, lng: 18.998493 },
    { lat: 48.484027, lng: 18.9981 },
    { lat: 48.485531, lng: 18.998035 },
    { lat: 48.485903, lng: 18.99795 },
    { lat: 48.486788, lng: 18.997584 },
    { lat: 48.487424, lng: 18.997465 },
    { lat: 48.487613, lng: 18.997443 },
    { lat: 48.487854, lng: 18.997349 },
    { lat: 48.488042, lng: 18.997536 },
    { lat: 48.488454, lng: 18.997662 },
    { lat: 48.488495, lng: 18.997674 },
    { lat: 48.488978, lng: 18.997665 },
    { lat: 48.489275, lng: 18.997687 },
    { lat: 48.489636, lng: 18.997666 },
    { lat: 48.490085, lng: 18.997447 },
    { lat: 48.490351, lng: 18.997349 },
    { lat: 48.490889, lng: 18.996667 },
    { lat: 48.491115, lng: 18.996542 },
    { lat: 48.491138, lng: 18.99653 },
    { lat: 48.49139, lng: 18.99639 },
    { lat: 48.492044, lng: 18.996027 },
    { lat: 48.4923513, lng: 18.9951503 },
    { lat: 48.4924974, lng: 18.9948097 },
    { lat: 48.4928814, lng: 18.9942011 },
    { lat: 48.4935916, lng: 18.9938653 },
    { lat: 48.4940563, lng: 18.9943358 },
    { lat: 48.4941972, lng: 18.9945787 },
    { lat: 48.4942464, lng: 18.9946406 },
    { lat: 48.4943064, lng: 18.9945562 },
    { lat: 48.4944979, lng: 18.9944375 },
    { lat: 48.4951846, lng: 18.9937109 },
    { lat: 48.4953922, lng: 18.9932661 },
    { lat: 48.4956203, lng: 18.9932968 },
    { lat: 48.496006, lng: 18.9931881 },
    { lat: 48.4962822, lng: 18.9929416 },
    { lat: 48.4965518, lng: 18.9927008 },
    { lat: 48.4966329, lng: 18.9926321 },
    { lat: 48.4969551, lng: 18.9922495 },
    { lat: 48.4974395, lng: 18.9918451 },
    { lat: 48.4984269, lng: 18.9912615 },
    { lat: 48.4993268, lng: 18.9903126 },
    { lat: 48.4998389, lng: 18.9890268 },
    { lat: 48.5005431, lng: 18.9877562 },
    { lat: 48.5005795, lng: 18.9877381 },
    { lat: 48.5007992, lng: 18.9876261 },
    { lat: 48.5008948, lng: 18.9875774 },
    { lat: 48.5009574, lng: 18.987546 },
    { lat: 48.5015382, lng: 18.9875702 },
    { lat: 48.5017575, lng: 18.9876025 },
    { lat: 48.5021664, lng: 18.9873037 },
    { lat: 48.5022566, lng: 18.9872379 },
    { lat: 48.5025739, lng: 18.9870058 },
    { lat: 48.502937, lng: 18.9866522 },
    { lat: 48.5031315, lng: 18.9864637 },
    { lat: 48.5036476, lng: 18.9855037 },
    { lat: 48.5039551, lng: 18.9851199 },
    { lat: 48.5040324, lng: 18.9850232 },
    { lat: 48.5042635, lng: 18.9847359 },
    { lat: 48.5048131, lng: 18.9841478 },
    { lat: 48.5050991, lng: 18.9840296 },
    { lat: 48.5054977, lng: 18.983973 },
    { lat: 48.5057921, lng: 18.9834686 },
    { lat: 48.5061012, lng: 18.9833815 },
    { lat: 48.5062315, lng: 18.9832818 },
    { lat: 48.5066416, lng: 18.9831461 },
    { lat: 48.5068064, lng: 18.9829472 },
    { lat: 48.5069543, lng: 18.9828423 },
    { lat: 48.5070809, lng: 18.9828039 },
    { lat: 48.5075058, lng: 18.9825313 },
    { lat: 48.5077484, lng: 18.9823817 },
    { lat: 48.5077871, lng: 18.9823676 },
    { lat: 48.5079344, lng: 18.9823126 },
    { lat: 48.5080174, lng: 18.9822821 },
    { lat: 48.5081136, lng: 18.9822457 },
    { lat: 48.5081245, lng: 18.9822416 },
    { lat: 48.5083079, lng: 18.9820761 },
    { lat: 48.5085668, lng: 18.9820463 },
    { lat: 48.5086083, lng: 18.9820415 },
    { lat: 48.5086428, lng: 18.9817026 },
    { lat: 48.5088245, lng: 18.9811725 },
    { lat: 48.508902, lng: 18.9808815 },
    { lat: 48.5090532, lng: 18.9804815 },
    { lat: 48.5092043, lng: 18.9798845 },
    { lat: 48.5091985, lng: 18.9792835 },
    { lat: 48.5092778, lng: 18.9776959 },
    { lat: 48.5091568, lng: 18.9773383 },
    { lat: 48.5091066, lng: 18.9771912 },
    { lat: 48.5091043, lng: 18.9771701 },
    { lat: 48.5090711, lng: 18.9768056 },
    { lat: 48.5089634, lng: 18.9764631 },
    { lat: 48.5088438, lng: 18.9760815 },
    { lat: 48.5088317, lng: 18.9760438 },
    { lat: 48.5088874, lng: 18.9757454 },
    { lat: 48.5088639, lng: 18.9756365 },
    { lat: 48.5087164, lng: 18.9754412 },
    { lat: 48.5085281, lng: 18.9752176 },
    { lat: 48.5083695, lng: 18.9750969 },
    { lat: 48.5082686, lng: 18.9749947 },
    { lat: 48.5080297, lng: 18.9747521 },
    { lat: 48.5079666, lng: 18.9746875 },
    { lat: 48.5079293, lng: 18.9745532 },
    { lat: 48.5078754, lng: 18.9743588 },
    { lat: 48.5077692, lng: 18.9740219 },
    { lat: 48.5077639, lng: 18.9740055 },
    { lat: 48.5077579, lng: 18.9739997 },
    { lat: 48.50759, lng: 18.9738477 },
    { lat: 48.5075938, lng: 18.9736773 },
    { lat: 48.5076052, lng: 18.973127 },
    { lat: 48.5073626, lng: 18.9723063 },
    { lat: 48.507024, lng: 18.9719453 },
    { lat: 48.5066527, lng: 18.9714502 },
    { lat: 48.5065165, lng: 18.9712587 },
    { lat: 48.5064398, lng: 18.9707932 },
    { lat: 48.5063584, lng: 18.9704559 },
    { lat: 48.506163, lng: 18.970183 },
    { lat: 48.5056475, lng: 18.9697583 },
    { lat: 48.5054931, lng: 18.9697181 },
    { lat: 48.5054395, lng: 18.9697044 },
    { lat: 48.5054213, lng: 18.9696998 },
    { lat: 48.5052584, lng: 18.9695362 },
    { lat: 48.5049179, lng: 18.9690951 },
    { lat: 48.5046307, lng: 18.9685697 },
    { lat: 48.5046199, lng: 18.9682919 },
    { lat: 48.5045372, lng: 18.9679537 },
    { lat: 48.5045259, lng: 18.9678441 },
    { lat: 48.5045057, lng: 18.9676462 },
    { lat: 48.5044982, lng: 18.9675657 },
    { lat: 48.5043935, lng: 18.9672119 },
    { lat: 48.5039965, lng: 18.965997 },
    { lat: 48.5038006, lng: 18.9653629 },
    { lat: 48.5037157, lng: 18.965046 },
    { lat: 48.5032242, lng: 18.9642082 },
    { lat: 48.5031284, lng: 18.9634137 },
    { lat: 48.5030702, lng: 18.9631765 },
    { lat: 48.5028983, lng: 18.962748 },
    { lat: 48.502985, lng: 18.9625915 },
    { lat: 48.503003, lng: 18.9625912 },
    { lat: 48.5030448, lng: 18.962591 },
    { lat: 48.5031022, lng: 18.9625908 },
    { lat: 48.5032809, lng: 18.9623376 },
    { lat: 48.5032576, lng: 18.9617938 },
    { lat: 48.5031006, lng: 18.9599325 },
    { lat: 48.5028676, lng: 18.9586368 },
    { lat: 48.5027022, lng: 18.9569906 },
    { lat: 48.5008904, lng: 18.9563744 },
    { lat: 48.499808, lng: 18.9566181 },
    { lat: 48.4993186, lng: 18.9567284 },
    { lat: 48.4990633, lng: 18.9567857 },
    { lat: 48.4985316, lng: 18.9563082 },
    { lat: 48.4986277, lng: 18.9557838 },
    { lat: 48.4986357, lng: 18.9557007 },
    { lat: 48.4986843, lng: 18.9551949 },
    { lat: 48.4986872, lng: 18.9551633 },
    { lat: 48.4989409, lng: 18.9549647 },
    { lat: 48.499178, lng: 18.9547267 },
    { lat: 48.4993802, lng: 18.9545355 },
    { lat: 48.4994998, lng: 18.9541214 },
    { lat: 48.4994932, lng: 18.954056 },
    { lat: 48.499483, lng: 18.9539676 },
    { lat: 48.499467, lng: 18.9539193 },
    { lat: 48.4993944, lng: 18.9534698 },
    { lat: 48.4993127, lng: 18.9532162 },
    { lat: 48.4992272, lng: 18.953064 },
    { lat: 48.4993109, lng: 18.9529177 },
    { lat: 48.4993529, lng: 18.9523721 },
    { lat: 48.4994016, lng: 18.9519331 },
    { lat: 48.4995152, lng: 18.9516192 },
    { lat: 48.4995968, lng: 18.9513673 },
    { lat: 48.499539, lng: 18.9510718 },
    { lat: 48.4992531, lng: 18.9507714 },
    { lat: 48.4990224, lng: 18.9499211 },
    { lat: 48.4984279, lng: 18.9490336 },
    { lat: 48.4979115, lng: 18.9482644 },
    { lat: 48.4975893, lng: 18.9481826 },
    { lat: 48.4977045, lng: 18.946422 },
    { lat: 48.4974997, lng: 18.9461247 },
    { lat: 48.4974312, lng: 18.9456448 },
    { lat: 48.4970398, lng: 18.9449355 },
    { lat: 48.4965014, lng: 18.9450395 },
    { lat: 48.4959287, lng: 18.9449516 },
    { lat: 48.4956255, lng: 18.944932 },
    { lat: 48.4955785, lng: 18.9445863 },
    { lat: 48.4951929, lng: 18.9438571 },
    { lat: 48.4954297, lng: 18.9431997 },
    { lat: 48.4955259, lng: 18.9428774 },
    { lat: 48.4955044, lng: 18.9426105 },
    { lat: 48.4953799, lng: 18.9421813 },
    { lat: 48.4953135, lng: 18.9417012 },
    { lat: 48.4953643, lng: 18.9409783 },
    { lat: 48.4947309, lng: 18.9390489 },
    { lat: 48.4947216, lng: 18.9391751 },
    { lat: 48.4941723, lng: 18.9384031 },
    { lat: 48.4940979, lng: 18.9380254 },
    { lat: 48.4940614, lng: 18.9375995 },
    { lat: 48.4942994, lng: 18.9365459 },
    { lat: 48.4945752, lng: 18.935639 },
    { lat: 48.4943935, lng: 18.9351048 },
    { lat: 48.4943617, lng: 18.934485 },
    { lat: 48.494484, lng: 18.9333101 },
    { lat: 48.494559, lng: 18.9328826 },
    { lat: 48.4945938, lng: 18.9326823 },
    { lat: 48.4945942, lng: 18.9326023 },
    { lat: 48.4945366, lng: 18.9323129 },
    { lat: 48.4939429, lng: 18.9317125 },
    { lat: 48.4935369, lng: 18.9311589 },
    { lat: 48.4934422, lng: 18.9310938 },
    { lat: 48.4931933, lng: 18.9309226 },
    { lat: 48.4930245, lng: 18.9309067 },
    { lat: 48.4927002, lng: 18.9307425 },
    { lat: 48.4926528, lng: 18.9307181 },
    { lat: 48.4923026, lng: 18.9305089 },
    { lat: 48.4916531, lng: 18.930813 },
    { lat: 48.4911703, lng: 18.9308759 },
    { lat: 48.4909301, lng: 18.930832 },
    { lat: 48.4908179, lng: 18.9289735 },
    { lat: 48.4903346, lng: 18.9275776 },
    { lat: 48.4903306, lng: 18.9275692 },
    { lat: 48.4900847, lng: 18.9272352 },
    { lat: 48.489457, lng: 18.9266534 },
    { lat: 48.4892898, lng: 18.9263373 },
    { lat: 48.4898987, lng: 18.9255471 },
    { lat: 48.4900651, lng: 18.9249039 },
    { lat: 48.4899401, lng: 18.9245093 },
    { lat: 48.4895847, lng: 18.9240892 },
    { lat: 48.4895562, lng: 18.9240414 },
    { lat: 48.4892081, lng: 18.923608 },
    { lat: 48.4890155, lng: 18.9233674 },
    { lat: 48.4881695, lng: 18.9236045 },
    { lat: 48.488039, lng: 18.9227577 },
    { lat: 48.4876418, lng: 18.9201398 },
    { lat: 48.4876381, lng: 18.9201133 },
    { lat: 48.4876087, lng: 18.9200232 },
    { lat: 48.487605, lng: 18.919997 },
    { lat: 48.4874612, lng: 18.9197422 },
    { lat: 48.4872037, lng: 18.9171717 },
    { lat: 48.4873445, lng: 18.9161384 },
    { lat: 48.4873532, lng: 18.9160869 },
    { lat: 48.4874629, lng: 18.9153966 },
    { lat: 48.487303, lng: 18.9140614 },
    { lat: 48.4871681, lng: 18.9134163 },
    { lat: 48.4871232, lng: 18.9131994 },
    { lat: 48.486389, lng: 18.9133045 },
    { lat: 48.4851581, lng: 18.9135116 },
    { lat: 48.484995, lng: 18.9135154 },
    { lat: 48.4848977, lng: 18.9135768 },
    { lat: 48.4840018, lng: 18.9150311 },
    { lat: 48.4838511, lng: 18.915391 },
    { lat: 48.4837794, lng: 18.9155721 },
    { lat: 48.4836618, lng: 18.9158541 },
    { lat: 48.4830573, lng: 18.9174482 },
    { lat: 48.4825962, lng: 18.9175382 },
    { lat: 48.4822506, lng: 18.9175038 },
    { lat: 48.4819978, lng: 18.9175453 },
    { lat: 48.4816239, lng: 18.9174872 },
    { lat: 48.4812524, lng: 18.9173894 },
    { lat: 48.4809028, lng: 18.9172769 },
    { lat: 48.4807136, lng: 18.9171948 },
    { lat: 48.4804253, lng: 18.9170468 },
    { lat: 48.4803937, lng: 18.9170484 },
    { lat: 48.480288, lng: 18.9170336 },
    { lat: 48.4801191, lng: 18.9168415 },
    { lat: 48.4799716, lng: 18.9166888 },
    { lat: 48.4797063, lng: 18.9166152 },
    { lat: 48.4794135, lng: 18.916166 },
    { lat: 48.4793363, lng: 18.9158907 },
    { lat: 48.4793049, lng: 18.9153275 },
    { lat: 48.4786927, lng: 18.9155022 },
    { lat: 48.4782742, lng: 18.9152162 },
    { lat: 48.4773758, lng: 18.9153287 },
    { lat: 48.477336, lng: 18.9153465 },
    { lat: 48.4772708, lng: 18.9153683 },
    { lat: 48.4772281, lng: 18.9153758 },
    { lat: 48.4768328, lng: 18.9155587 },
    { lat: 48.4768185, lng: 18.9155656 },
    { lat: 48.4753567, lng: 18.9179214 },
    { lat: 48.4741517, lng: 18.9184078 },
    { lat: 48.47367, lng: 18.9186022 },
    { lat: 48.4736568, lng: 18.9186076 },
    { lat: 48.4736524, lng: 18.9186078 },
    { lat: 48.4736321, lng: 18.9186087 },
    { lat: 48.4735286, lng: 18.9186133 },
    { lat: 48.4734963, lng: 18.9186231 },
    { lat: 48.4734554, lng: 18.9186375 },
    { lat: 48.4733911, lng: 18.9186602 },
    { lat: 48.4733336, lng: 18.9186808 },
    { lat: 48.4730668, lng: 18.9187755 },
    { lat: 48.4728193, lng: 18.9187233 },
    { lat: 48.472792, lng: 18.9187172 },
    { lat: 48.4727658, lng: 18.9187117 },
    { lat: 48.472508, lng: 18.9186575 },
    { lat: 48.4724841, lng: 18.9187395 },
    { lat: 48.4723719, lng: 18.9191214 },
    { lat: 48.4721407, lng: 18.9191004 },
    { lat: 48.4721092, lng: 18.919098 },
    { lat: 48.4720546, lng: 18.919093 },
    { lat: 48.4720289, lng: 18.9190949 },
    { lat: 48.4719382, lng: 18.9193482 },
    { lat: 48.4719192, lng: 18.9193966 },
    { lat: 48.4717961, lng: 18.9195651 },
    { lat: 48.4717831, lng: 18.9195979 },
    { lat: 48.4717664, lng: 18.9200209 },
    { lat: 48.4716871, lng: 18.9202526 },
    { lat: 48.471708, lng: 18.9204214 },
    { lat: 48.4717097, lng: 18.9207158 },
    { lat: 48.4717412, lng: 18.9211304 },
    { lat: 48.4717579, lng: 18.9214998 },
    { lat: 48.4718135, lng: 18.9218785 },
    { lat: 48.4718405, lng: 18.92216 },
    { lat: 48.4718235, lng: 18.9222248 },
    { lat: 48.4718535, lng: 18.9223251 },
    { lat: 48.4718711, lng: 18.9223835 },
    { lat: 48.4719379, lng: 18.9226056 },
    { lat: 48.4719875, lng: 18.9229836 },
    { lat: 48.4721034, lng: 18.9231499 },
    { lat: 48.4721667, lng: 18.9236148 },
    { lat: 48.4721409, lng: 18.924035 },
    { lat: 48.4722003, lng: 18.9244658 },
    { lat: 48.4722054, lng: 18.9244997 },
    { lat: 48.4721519, lng: 18.924512 },
    { lat: 48.4710763, lng: 18.9249519 },
    { lat: 48.4710122, lng: 18.9249782 },
    { lat: 48.4704845, lng: 18.9256109 },
    { lat: 48.4696164, lng: 18.9266897 },
    { lat: 48.4694653, lng: 18.9269311 },
    { lat: 48.4695186, lng: 18.9273975 },
    { lat: 48.4698079, lng: 18.9299328 },
    { lat: 48.4698166, lng: 18.9300143 },
    { lat: 48.4698551, lng: 18.9303466 },
    { lat: 48.4674541, lng: 18.9307748 },
    { lat: 48.4672956, lng: 18.9308529 },
    { lat: 48.4664237, lng: 18.9312847 },
    { lat: 48.4656542, lng: 18.9321326 },
    { lat: 48.465589, lng: 18.9321554 },
    { lat: 48.4652184, lng: 18.9321622 },
    { lat: 48.4646444, lng: 18.932074 },
    { lat: 48.4639453, lng: 18.9321609 },
    { lat: 48.463482, lng: 18.9324058 },
    { lat: 48.4632322, lng: 18.9325475 },
    { lat: 48.4630542, lng: 18.9327479 },
    { lat: 48.4628734, lng: 18.9332894 },
    { lat: 48.4625987, lng: 18.9338666 },
    { lat: 48.4623496, lng: 18.9344174 },
    { lat: 48.4621035, lng: 18.9346961 },
    { lat: 48.4621601, lng: 18.9348124 },
    { lat: 48.4620497, lng: 18.9348807 },
    { lat: 48.4619679, lng: 18.9349541 },
    { lat: 48.461865, lng: 18.9351263 },
    { lat: 48.4616475, lng: 18.9352386 },
    { lat: 48.461571, lng: 18.9354386 },
    { lat: 48.4614693, lng: 18.9357215 },
    { lat: 48.4614909, lng: 18.9360088 },
    { lat: 48.4613392, lng: 18.9362851 },
    { lat: 48.4613296, lng: 18.9365121 },
    { lat: 48.4611474, lng: 18.9365653 },
    { lat: 48.4610018, lng: 18.9366074 },
    { lat: 48.4609787, lng: 18.9366619 },
    { lat: 48.4608835, lng: 18.9367831 },
    { lat: 48.4608692, lng: 18.9368348 },
    { lat: 48.4608926, lng: 18.9369181 },
    { lat: 48.4608458, lng: 18.9369516 },
    { lat: 48.4608726, lng: 18.9370302 },
    { lat: 48.4610776, lng: 18.9374483 },
    { lat: 48.4610935, lng: 18.937514 },
    { lat: 48.4611593, lng: 18.9376321 },
    { lat: 48.4612347, lng: 18.9376742 },
    { lat: 48.4617723, lng: 18.9377582 },
    { lat: 48.4620362, lng: 18.9377812 },
    { lat: 48.4621827, lng: 18.9378228 },
    { lat: 48.4623676, lng: 18.9379531 },
    { lat: 48.4625577, lng: 18.9381218 },
    { lat: 48.4628871, lng: 18.938652 },
    { lat: 48.4634433, lng: 18.9395599 },
    { lat: 48.4636593, lng: 18.9398375 },
    { lat: 48.4638152, lng: 18.9401029 },
    { lat: 48.4640574, lng: 18.9405829 },
    { lat: 48.4642939, lng: 18.9407764 },
    { lat: 48.4644137, lng: 18.9409146 },
    { lat: 48.4645367, lng: 18.9411149 },
    { lat: 48.464719, lng: 18.9416097 },
    { lat: 48.4648281, lng: 18.9417846 },
    { lat: 48.4651598, lng: 18.9423601 },
    { lat: 48.4653696, lng: 18.9427983 },
    { lat: 48.465507, lng: 18.9430627 },
    { lat: 48.4656964, lng: 18.9433879 },
    { lat: 48.4658393, lng: 18.943825 },
    { lat: 48.4659532, lng: 18.9441753 },
    { lat: 48.4660699, lng: 18.9444748 },
    { lat: 48.4662521, lng: 18.9447283 },
    { lat: 48.466362, lng: 18.9448918 },
    { lat: 48.4664708, lng: 18.9451659 },
    { lat: 48.466542, lng: 18.9452596 },
    { lat: 48.4665566, lng: 18.9455443 },
    { lat: 48.4666574, lng: 18.9458609 },
    { lat: 48.4666964, lng: 18.9461147 },
    { lat: 48.4666668, lng: 18.9463519 },
    { lat: 48.4666083, lng: 18.9465523 },
    { lat: 48.4666055, lng: 18.9465622 },
    { lat: 48.4665969, lng: 18.9465941 },
    { lat: 48.4665872, lng: 18.9466258 },
    { lat: 48.4665814, lng: 18.9466467 },
    { lat: 48.4665171, lng: 18.9467505 },
    { lat: 48.4664943, lng: 18.9467719 },
    { lat: 48.4662447, lng: 18.9470044 },
    { lat: 48.4660106, lng: 18.9472976 },
    { lat: 48.4657799, lng: 18.9476598 },
    { lat: 48.4654284, lng: 18.9481947 },
    { lat: 48.4653231, lng: 18.9483813 },
    { lat: 48.4650956, lng: 18.948872 },
    { lat: 48.4650539, lng: 18.9489607 },
    { lat: 48.4648745, lng: 18.9494636 },
    { lat: 48.4647625, lng: 18.9497661 },
    { lat: 48.4647069, lng: 18.9500479 },
    { lat: 48.4646965, lng: 18.9506147 },
    { lat: 48.4646623, lng: 18.9510439 },
    { lat: 48.4646572, lng: 18.9518734 },
    { lat: 48.4646135, lng: 18.9523151 },
    { lat: 48.4645291, lng: 18.9526524 },
    { lat: 48.4642551, lng: 18.9534895 },
    { lat: 48.4640624, lng: 18.9539939 },
    { lat: 48.4639279, lng: 18.9545424 },
    { lat: 48.4638265, lng: 18.9550725 },
    { lat: 48.4637776, lng: 18.9553915 },
    { lat: 48.4637312, lng: 18.9561376 },
    { lat: 48.4636795, lng: 18.9564704 },
    { lat: 48.4636891, lng: 18.9569042 },
    { lat: 48.4637871, lng: 18.9572112 },
    { lat: 48.4638236, lng: 18.9575469 },
    { lat: 48.4638712, lng: 18.9576871 },
    { lat: 48.4638252, lng: 18.9580214 },
    { lat: 48.4643718, lng: 18.9587686 },
    { lat: 48.4646423, lng: 18.9589373 },
    { lat: 48.4648429, lng: 18.9591838 },
    { lat: 48.4650185, lng: 18.9593084 },
    { lat: 48.4651176, lng: 18.9592373 },
    { lat: 48.4653065, lng: 18.9595395 },
    { lat: 48.465422, lng: 18.9598348 },
    { lat: 48.4654667, lng: 18.9601363 },
    { lat: 48.4656244, lng: 18.9603323 },
    { lat: 48.465819, lng: 18.9607418 },
    { lat: 48.4659907, lng: 18.9609263 },
    { lat: 48.4662818, lng: 18.9613694 },
    { lat: 48.4664692, lng: 18.9615915 },
    { lat: 48.4665092, lng: 18.9617134 },
    { lat: 48.4665258, lng: 18.9621026 },
    { lat: 48.4665869, lng: 18.9624384 },
    { lat: 48.466753, lng: 18.9627351 },
    { lat: 48.4670285, lng: 18.9638924 },
    { lat: 48.4670781, lng: 18.964308 },
    { lat: 48.4672448, lng: 18.9646796 },
    { lat: 48.4673474, lng: 18.9647917 },
    { lat: 48.4674333, lng: 18.9651922 },
    { lat: 48.4675194, lng: 18.9654857 },
    { lat: 48.4675249, lng: 18.9657934 },
    { lat: 48.4675706, lng: 18.9662713 },
    { lat: 48.4676773, lng: 18.9670098 },
    { lat: 48.4676679, lng: 18.9674695 },
    { lat: 48.4677022, lng: 18.9680948 },
    { lat: 48.4676327, lng: 18.9682368 },
    { lat: 48.4676759, lng: 18.9685331 },
    { lat: 48.4678458, lng: 18.968899 },
    { lat: 48.4679992, lng: 18.9689875 },
    { lat: 48.4680934, lng: 18.9692228 },
    { lat: 48.4681122, lng: 18.9695905 },
    { lat: 48.4680326, lng: 18.9701739 },
    { lat: 48.4682001, lng: 18.9706925 },
    { lat: 48.4682288, lng: 18.9710146 },
    { lat: 48.4682779, lng: 18.9714211 },
    { lat: 48.4684223, lng: 18.9717782 },
    { lat: 48.4686109, lng: 18.9719611 },
    { lat: 48.4687126, lng: 18.9722077 },
    { lat: 48.4687308, lng: 18.9724123 },
    { lat: 48.4687185, lng: 18.9729211 },
    { lat: 48.4687876, lng: 18.9733257 },
    { lat: 48.468756, lng: 18.9737443 },
    { lat: 48.4686644, lng: 18.9743809 },
    { lat: 48.4685857, lng: 18.9747193 },
    { lat: 48.4686724, lng: 18.9749779 },
    { lat: 48.4686614, lng: 18.9754471 },
    { lat: 48.4686065, lng: 18.9759417 },
    { lat: 48.4685443, lng: 18.9762459 },
    { lat: 48.4684889, lng: 18.9767123 },
    { lat: 48.4683446, lng: 18.9772427 },
    { lat: 48.4683281, lng: 18.9775161 },
    { lat: 48.4683113, lng: 18.9778506 },
    { lat: 48.4682387, lng: 18.9782608 },
    { lat: 48.468385, lng: 18.9786752 },
    { lat: 48.4683161, lng: 18.9790467 },
    { lat: 48.4681165, lng: 18.9793127 },
    { lat: 48.4679655, lng: 18.9794701 },
    { lat: 48.4677036, lng: 18.980418 },
    { lat: 48.4677524, lng: 18.980709 },
    { lat: 48.4678093, lng: 18.9808552 },
    { lat: 48.4676456, lng: 18.981181 },
    { lat: 48.4675336, lng: 18.9813947 },
    { lat: 48.4674818, lng: 18.9815275 },
    { lat: 48.4674215, lng: 18.9818283 },
    { lat: 48.4673462, lng: 18.9818998 },
    { lat: 48.4671003, lng: 18.9823406 },
    { lat: 48.466711, lng: 18.9826437 },
    { lat: 48.4666376, lng: 18.9828636 },
    { lat: 48.4666528, lng: 18.9828984 },
    { lat: 48.4665826, lng: 18.9830216 },
    { lat: 48.4666536, lng: 18.9832014 },
    { lat: 48.4666548, lng: 18.9833449 },
    { lat: 48.4666604, lng: 18.9839098 },
    { lat: 48.4666475, lng: 18.9841848 },
    { lat: 48.4665938, lng: 18.9844397 },
    { lat: 48.4664812, lng: 18.9845997 },
    { lat: 48.4664668, lng: 18.9848924 },
    { lat: 48.4663691, lng: 18.9852095 },
    { lat: 48.4660856, lng: 18.9857768 },
    { lat: 48.4662273, lng: 18.9868757 },
    { lat: 48.4662401, lng: 18.9878147 },
    { lat: 48.466342, lng: 18.9881778 },
    { lat: 48.4664033, lng: 18.988537 },
    { lat: 48.4664267, lng: 18.9888868 },
    { lat: 48.4664803, lng: 18.9890575 },
    { lat: 48.4664607, lng: 18.9892246 },
    { lat: 48.4665729, lng: 18.989567 },
    { lat: 48.4665146, lng: 18.9899073 },
    { lat: 48.4665842, lng: 18.9902553 },
    { lat: 48.4665312, lng: 18.9906519 },
    { lat: 48.4664787, lng: 18.9910185 },
    { lat: 48.4663992, lng: 18.9915176 },
    { lat: 48.4666569, lng: 18.992531 },
    { lat: 48.4666302, lng: 18.9935754 },
    { lat: 48.4667141, lng: 18.9936749 },
    { lat: 48.466744, lng: 18.9937084 },
    { lat: 48.4671281, lng: 18.9940337 },
    { lat: 48.4672987, lng: 18.9944191 },
    { lat: 48.4675408, lng: 18.9946361 },
    { lat: 48.4679447, lng: 18.9945972 },
    { lat: 48.4683233, lng: 18.9941493 },
    { lat: 48.468689, lng: 18.9937762 },
    { lat: 48.4689869, lng: 18.993274 },
    { lat: 48.4697813, lng: 18.9922422 },
    { lat: 48.4700944, lng: 18.9919238 },
    { lat: 48.4702772, lng: 18.9918381 },
    { lat: 48.4704257, lng: 18.991902 },
    { lat: 48.4704369, lng: 18.9920105 },
    { lat: 48.4705141, lng: 18.9930182 },
    { lat: 48.4706672, lng: 18.9931896 },
    { lat: 48.470754, lng: 18.9934571 },
    { lat: 48.4709337, lng: 18.9935345 },
    { lat: 48.4710712, lng: 18.9938168 },
    { lat: 48.4711946, lng: 18.9939387 },
    { lat: 48.4713593, lng: 18.9946979 },
    { lat: 48.4716908, lng: 18.9950283 },
    { lat: 48.4718767, lng: 18.9950933 },
    { lat: 48.4719638, lng: 18.9953419 },
    { lat: 48.4720873, lng: 18.9954198 },
    { lat: 48.4722314, lng: 18.9956839 },
    { lat: 48.4725677, lng: 18.99598 },
    { lat: 48.4730477, lng: 18.9968391 },
    { lat: 48.473625, lng: 18.9971711 },
    { lat: 48.4744783, lng: 18.9975225 },
    { lat: 48.4751432, lng: 18.997697 },
    { lat: 48.4751753, lng: 18.9977047 },
    { lat: 48.4760426, lng: 18.998244 },
    { lat: 48.4769259, lng: 18.9981628 },
    { lat: 48.4771896, lng: 18.9982707 },
    { lat: 48.4778235, lng: 18.998635 },
    { lat: 48.478647, lng: 18.9988414 },
    { lat: 48.4791426, lng: 18.9988954 },
    { lat: 48.4796922, lng: 18.9988639 },
    { lat: 48.4800979, lng: 18.9988148 },
    { lat: 48.4807482, lng: 18.9992124 },
    { lat: 48.4812936, lng: 18.9993822 },
    { lat: 48.482915, lng: 19.000607 },
  ],
  Močiar: [
    { lat: 48.532515, lng: 18.987608 },
    { lat: 48.532494, lng: 18.986968 },
    { lat: 48.532874, lng: 18.986699 },
    { lat: 48.533069, lng: 18.986615 },
    { lat: 48.533363, lng: 18.986394 },
    { lat: 48.533876, lng: 18.986401 },
    { lat: 48.534712, lng: 18.986506 },
    { lat: 48.534884, lng: 18.986429 },
    { lat: 48.535774, lng: 18.985865 },
    { lat: 48.536489, lng: 18.985307 },
    { lat: 48.536546, lng: 18.985293 },
    { lat: 48.537654, lng: 18.985016 },
    { lat: 48.537703, lng: 18.985003 },
    { lat: 48.537725, lng: 18.984998 },
    { lat: 48.53772, lng: 18.984927 },
    { lat: 48.537701, lng: 18.984667 },
    { lat: 48.5384, lng: 18.984258 },
    { lat: 48.539372, lng: 18.983732 },
    { lat: 48.539707, lng: 18.98351 },
    { lat: 48.540123, lng: 18.983251 },
    { lat: 48.541072, lng: 18.982754 },
    { lat: 48.54273, lng: 18.982047 },
    { lat: 48.543025, lng: 18.982003 },
    { lat: 48.543416, lng: 18.981891 },
    { lat: 48.543684, lng: 18.981524 },
    { lat: 48.54373, lng: 18.981536 },
    { lat: 48.544482, lng: 18.980972 },
    { lat: 48.545326, lng: 18.980462 },
    { lat: 48.545657, lng: 18.980236 },
    { lat: 48.546027, lng: 18.980054 },
    { lat: 48.546252, lng: 18.980454 },
    { lat: 48.546555, lng: 18.98051 },
    { lat: 48.546827, lng: 18.980816 },
    { lat: 48.547037, lng: 18.981121 },
    { lat: 48.547368, lng: 18.981207 },
    { lat: 48.547903, lng: 18.981238 },
    { lat: 48.548129, lng: 18.981036 },
    { lat: 48.548467, lng: 18.980544 },
    { lat: 48.548993, lng: 18.979496 },
    { lat: 48.549692, lng: 18.978642 },
    { lat: 48.549794, lng: 18.978532 },
    { lat: 48.550522, lng: 18.978036 },
    { lat: 48.551012, lng: 18.977997 },
    { lat: 48.551761, lng: 18.977817 },
    { lat: 48.552231, lng: 18.9778 },
    { lat: 48.552708, lng: 18.977396 },
    { lat: 48.553223, lng: 18.977023 },
    { lat: 48.553503, lng: 18.976876 },
    { lat: 48.554799, lng: 18.976677 },
    { lat: 48.555182, lng: 18.976801 },
    { lat: 48.555687, lng: 18.976778 },
    { lat: 48.555875, lng: 18.976725 },
    { lat: 48.556516, lng: 18.976406 },
    { lat: 48.557096, lng: 18.976024 },
    { lat: 48.557518, lng: 18.975714 },
    { lat: 48.557992, lng: 18.975347 },
    { lat: 48.558693, lng: 18.974394 },
    { lat: 48.559246, lng: 18.973393 },
    { lat: 48.559415, lng: 18.972908 },
    { lat: 48.558848, lng: 18.972075 },
    { lat: 48.558624, lng: 18.971722 },
    { lat: 48.558634, lng: 18.971122 },
    { lat: 48.558331, lng: 18.969131 },
    { lat: 48.557838, lng: 18.968713 },
    { lat: 48.5576, lng: 18.968354 },
    { lat: 48.556908, lng: 18.96782 },
    { lat: 48.55649, lng: 18.967261 },
    { lat: 48.556521, lng: 18.96523 },
    { lat: 48.55666, lng: 18.963883 },
    { lat: 48.556688, lng: 18.961637 },
    { lat: 48.557016, lng: 18.960578 },
    { lat: 48.557148, lng: 18.959736 },
    { lat: 48.557243, lng: 18.958094 },
    { lat: 48.557292, lng: 18.957507 },
    { lat: 48.557412, lng: 18.956891 },
    { lat: 48.55748, lng: 18.956201 },
    { lat: 48.55765, lng: 18.955434 },
    { lat: 48.557685, lng: 18.954936 },
    { lat: 48.557724, lng: 18.954102 },
    { lat: 48.557728, lng: 18.954058 },
    { lat: 48.55777, lng: 18.952044 },
    { lat: 48.558264, lng: 18.952057 },
    { lat: 48.559017, lng: 18.951966 },
    { lat: 48.559794, lng: 18.952111 },
    { lat: 48.560489, lng: 18.951903 },
    { lat: 48.560987, lng: 18.951844 },
    { lat: 48.561505, lng: 18.951273 },
    { lat: 48.561865, lng: 18.951062 },
    { lat: 48.562099, lng: 18.951026 },
    { lat: 48.562334, lng: 18.951112 },
    { lat: 48.562558, lng: 18.950972 },
    { lat: 48.562674, lng: 18.950964 },
    { lat: 48.562731, lng: 18.950683 },
    { lat: 48.563001, lng: 18.951117 },
    { lat: 48.56303, lng: 18.951299 },
    { lat: 48.563788, lng: 18.951552 },
    { lat: 48.564457, lng: 18.951617 },
    { lat: 48.564626, lng: 18.951695 },
    { lat: 48.564907, lng: 18.951373 },
    { lat: 48.565475, lng: 18.951603 },
    { lat: 48.565718, lng: 18.951851 },
    { lat: 48.566171, lng: 18.952041 },
    { lat: 48.566464, lng: 18.952437 },
    { lat: 48.566676, lng: 18.952403 },
    { lat: 48.566817, lng: 18.952471 },
    { lat: 48.567137, lng: 18.952692 },
    { lat: 48.567198, lng: 18.95257 },
    { lat: 48.567342, lng: 18.952266 },
    { lat: 48.567319, lng: 18.951896 },
    { lat: 48.567135, lng: 18.951447 },
    { lat: 48.566477, lng: 18.951141 },
    { lat: 48.565628, lng: 18.95073 },
    { lat: 48.565405, lng: 18.950257 },
    { lat: 48.565186, lng: 18.950175 },
    { lat: 48.5649, lng: 18.949892 },
    { lat: 48.564694, lng: 18.949502 },
    { lat: 48.564435, lng: 18.949024 },
    { lat: 48.563629, lng: 18.948551 },
    { lat: 48.564135, lng: 18.947568 },
    { lat: 48.564193, lng: 18.947313 },
    { lat: 48.564, lng: 18.947115 },
    { lat: 48.564949, lng: 18.945594 },
    { lat: 48.565254, lng: 18.945008 },
    { lat: 48.565825, lng: 18.943706 },
    { lat: 48.565792, lng: 18.942244 },
    { lat: 48.565798, lng: 18.94212 },
    { lat: 48.565805, lng: 18.94207 },
    { lat: 48.565844, lng: 18.941347 },
    { lat: 48.565509, lng: 18.940036 },
    { lat: 48.565226, lng: 18.939509 },
    { lat: 48.564981, lng: 18.939059 },
    { lat: 48.564642, lng: 18.938428 },
    { lat: 48.564305, lng: 18.936811 },
    { lat: 48.563129, lng: 18.934907 },
    { lat: 48.562467, lng: 18.93424 },
    { lat: 48.562249, lng: 18.93396 },
    { lat: 48.561771, lng: 18.933813 },
    { lat: 48.561422, lng: 18.933834 },
    { lat: 48.560911, lng: 18.933626 },
    { lat: 48.560765, lng: 18.933596 },
    { lat: 48.560728, lng: 18.933588 },
    { lat: 48.560484, lng: 18.933537 },
    { lat: 48.559958, lng: 18.933323 },
    { lat: 48.559635, lng: 18.933272 },
    { lat: 48.559586, lng: 18.933265 },
    { lat: 48.559516, lng: 18.933254 },
    { lat: 48.559206, lng: 18.933296 },
    { lat: 48.558786, lng: 18.93271 },
    { lat: 48.558365, lng: 18.932254 },
    { lat: 48.558007, lng: 18.931522 },
    { lat: 48.557769, lng: 18.931224 },
    { lat: 48.55783, lng: 18.93067 },
    { lat: 48.557701, lng: 18.929902 },
    { lat: 48.557238, lng: 18.929523 },
    { lat: 48.55719, lng: 18.929485 },
    { lat: 48.556956, lng: 18.929214 },
    { lat: 48.556304, lng: 18.928263 },
    { lat: 48.556217, lng: 18.928126 },
    { lat: 48.555866, lng: 18.928017 },
    { lat: 48.555679, lng: 18.92807 },
    { lat: 48.555459, lng: 18.928035 },
    { lat: 48.55514, lng: 18.927859 },
    { lat: 48.555075, lng: 18.927574 },
    { lat: 48.554705, lng: 18.925843 },
    { lat: 48.554671, lng: 18.925763 },
    { lat: 48.554464, lng: 18.9253 },
    { lat: 48.554228, lng: 18.92507 },
    { lat: 48.553848, lng: 18.925 },
    { lat: 48.553681, lng: 18.924916 },
    { lat: 48.553454, lng: 18.92455 },
    { lat: 48.553309, lng: 18.924171 },
    { lat: 48.552781, lng: 18.923798 },
    { lat: 48.552705, lng: 18.92337 },
    { lat: 48.552791, lng: 18.923207 },
    { lat: 48.552791, lng: 18.922944 },
    { lat: 48.552702, lng: 18.922597 },
    { lat: 48.552796, lng: 18.922504 },
    { lat: 48.552567, lng: 18.922292 },
    { lat: 48.552468, lng: 18.922201 },
    { lat: 48.55185, lng: 18.92163 },
    { lat: 48.551162, lng: 18.921596 },
    { lat: 48.550477, lng: 18.921578 },
    { lat: 48.55032, lng: 18.921702 },
    { lat: 48.550307, lng: 18.921713 },
    { lat: 48.550277, lng: 18.921755 },
    { lat: 48.550029, lng: 18.922061 },
    { lat: 48.549751, lng: 18.922083 },
    { lat: 48.549314, lng: 18.921964 },
    { lat: 48.548898, lng: 18.921655 },
    { lat: 48.548857, lng: 18.921625 },
    { lat: 48.54849, lng: 18.921353 },
    { lat: 48.548244, lng: 18.921002 },
    { lat: 48.548123, lng: 18.92064 },
    { lat: 48.547705, lng: 18.920154 },
    { lat: 48.547689, lng: 18.920141 },
    { lat: 48.547489, lng: 18.919982 },
    { lat: 48.547497, lng: 18.919671 },
    { lat: 48.547415, lng: 18.919227 },
    { lat: 48.54736, lng: 18.918897 },
    { lat: 48.547348, lng: 18.918811 },
    { lat: 48.547295, lng: 18.918815 },
    { lat: 48.547233, lng: 18.91882 },
    { lat: 48.547032, lng: 18.918835 },
    { lat: 48.546954, lng: 18.918841 },
    { lat: 48.5467403, lng: 18.9188572 },
    { lat: 48.546506, lng: 18.918875 },
    { lat: 48.546065, lng: 18.917988 },
    { lat: 48.546004, lng: 18.917778 },
    { lat: 48.545841, lng: 18.917237 },
    { lat: 48.545589, lng: 18.916711 },
    { lat: 48.545081, lng: 18.916563 },
    { lat: 48.544359, lng: 18.916123 },
    { lat: 48.5439912, lng: 18.9157333 },
    { lat: 48.543956, lng: 18.915696 },
    { lat: 48.543245, lng: 18.915388 },
    { lat: 48.542407, lng: 18.915306 },
    { lat: 48.541799, lng: 18.915004 },
    { lat: 48.541377, lng: 18.914603 },
    { lat: 48.540696, lng: 18.913942 },
    { lat: 48.540604, lng: 18.913291 },
    { lat: 48.540451, lng: 18.912961 },
    { lat: 48.540737, lng: 18.911357 },
    { lat: 48.540918, lng: 18.910061 },
    { lat: 48.541239, lng: 18.908811 },
    { lat: 48.540906, lng: 18.908712 },
    { lat: 48.540337, lng: 18.908481 },
    { lat: 48.539894, lng: 18.908416 },
    { lat: 48.539691, lng: 18.907832 },
    { lat: 48.539585, lng: 18.907718 },
    { lat: 48.539038, lng: 18.907501 },
    { lat: 48.538468, lng: 18.907459 },
    { lat: 48.537898, lng: 18.907534 },
    { lat: 48.536743, lng: 18.906955 },
    { lat: 48.535914, lng: 18.9067803 },
    { lat: 48.5358695, lng: 18.9067698 },
    { lat: 48.5356663, lng: 18.9067235 },
    { lat: 48.534795, lng: 18.9068822 },
    { lat: 48.5344473, lng: 18.9069392 },
    { lat: 48.5332317, lng: 18.9064774 },
    { lat: 48.5327295, lng: 18.9059675 },
    { lat: 48.5320123, lng: 18.9057258 },
    { lat: 48.5315343, lng: 18.9060563 },
    { lat: 48.5307358, lng: 18.906225 },
    { lat: 48.5306767, lng: 18.9062376 },
    { lat: 48.5302905, lng: 18.906319 },
    { lat: 48.530228, lng: 18.9063711 },
    { lat: 48.5303081, lng: 18.9064816 },
    { lat: 48.5303498, lng: 18.9065692 },
    { lat: 48.530439, lng: 18.9065962 },
    { lat: 48.5305179, lng: 18.9068328 },
    { lat: 48.5305831, lng: 18.906939 },
    { lat: 48.5306378, lng: 18.9069504 },
    { lat: 48.530672, lng: 18.9069578 },
    { lat: 48.5306677, lng: 18.906985 },
    { lat: 48.5306611, lng: 18.9070316 },
    { lat: 48.5306332, lng: 18.9082413 },
    { lat: 48.5306849, lng: 18.9093324 },
    { lat: 48.5307631, lng: 18.9098871 },
    { lat: 48.5308661, lng: 18.9102002 },
    { lat: 48.5309762, lng: 18.9113745 },
    { lat: 48.5308971, lng: 18.911789 },
    { lat: 48.5307888, lng: 18.9121403 },
    { lat: 48.5299079, lng: 18.9130994 },
    { lat: 48.5294994, lng: 18.9133754 },
    { lat: 48.5292056, lng: 18.9137128 },
    { lat: 48.5291971, lng: 18.9139425 },
    { lat: 48.5291766, lng: 18.9141583 },
    { lat: 48.5292388, lng: 18.9144677 },
    { lat: 48.5292379, lng: 18.9147809 },
    { lat: 48.5291895, lng: 18.9151305 },
    { lat: 48.5289994, lng: 18.9156013 },
    { lat: 48.5286006, lng: 18.9159832 },
    { lat: 48.528245, lng: 18.9168062 },
    { lat: 48.5279328, lng: 18.9169184 },
    { lat: 48.5276134, lng: 18.9171314 },
    { lat: 48.5270723, lng: 18.917834 },
    { lat: 48.5267067, lng: 18.9184198 },
    { lat: 48.5264771, lng: 18.9189596 },
    { lat: 48.5262731, lng: 18.9197709 },
    { lat: 48.5260575, lng: 18.9200962 },
    { lat: 48.5258866, lng: 18.9204754 },
    { lat: 48.5257187, lng: 18.921157 },
    { lat: 48.5256941, lng: 18.9220623 },
    { lat: 48.5256388, lng: 18.9225866 },
    { lat: 48.5253968, lng: 18.9229492 },
    { lat: 48.525316, lng: 18.9235064 },
    { lat: 48.5253173, lng: 18.9239676 },
    { lat: 48.5252138, lng: 18.9245189 },
    { lat: 48.5251642, lng: 18.9246983 },
    { lat: 48.5251388, lng: 18.924793 },
    { lat: 48.5248929, lng: 18.9256924 },
    { lat: 48.5248625, lng: 18.9258037 },
    { lat: 48.5247609, lng: 18.9261755 },
    { lat: 48.5246652, lng: 18.9269667 },
    { lat: 48.524528, lng: 18.9273687 },
    { lat: 48.5244479, lng: 18.9274804 },
    { lat: 48.5240996, lng: 18.9279663 },
    { lat: 48.5238781, lng: 18.9283282 },
    { lat: 48.5235925, lng: 18.9288505 },
    { lat: 48.5228044, lng: 18.9296216 },
    { lat: 48.5222392, lng: 18.9313057 },
    { lat: 48.5220622, lng: 18.9316371 },
    { lat: 48.5220418, lng: 18.9316761 },
    { lat: 48.5211372, lng: 18.9333645 },
    { lat: 48.5207943, lng: 18.9338237 },
    { lat: 48.5199748, lng: 18.9347057 },
    { lat: 48.5200669, lng: 18.9352704 },
    { lat: 48.5202579, lng: 18.9360933 },
    { lat: 48.5203157, lng: 18.9362993 },
    { lat: 48.5204276, lng: 18.9364997 },
    { lat: 48.5204969, lng: 18.9367723 },
    { lat: 48.5205201, lng: 18.937116 },
    { lat: 48.5205649, lng: 18.9373761 },
    { lat: 48.5206765, lng: 18.9376395 },
    { lat: 48.5207087, lng: 18.9377852 },
    { lat: 48.5208849, lng: 18.9381609 },
    { lat: 48.5208594, lng: 18.9383467 },
    { lat: 48.5207614, lng: 18.9393149 },
    { lat: 48.5209059, lng: 18.9397839 },
    { lat: 48.5210034, lng: 18.9404505 },
    { lat: 48.5211279, lng: 18.9414736 },
    { lat: 48.5218394, lng: 18.941078 },
    { lat: 48.5224211, lng: 18.9415177 },
    { lat: 48.5228109, lng: 18.9420369 },
    { lat: 48.5231064, lng: 18.9421274 },
    { lat: 48.5232942, lng: 18.9428231 },
    { lat: 48.5233095, lng: 18.9428782 },
    { lat: 48.5236518, lng: 18.944145 },
    { lat: 48.5238048, lng: 18.9444719 },
    { lat: 48.523827, lng: 18.9446411 },
    { lat: 48.523888, lng: 18.9449922 },
    { lat: 48.5238433, lng: 18.9454854 },
    { lat: 48.5237444, lng: 18.9457366 },
    { lat: 48.5235388, lng: 18.9458152 },
    { lat: 48.5234739, lng: 18.946001 },
    { lat: 48.5234502, lng: 18.9466639 },
    { lat: 48.5231762, lng: 18.9473434 },
    { lat: 48.522559, lng: 18.9474673 },
    { lat: 48.5224141, lng: 18.9480937 },
    { lat: 48.522104, lng: 18.9487574 },
    { lat: 48.5216275, lng: 18.9496258 },
    { lat: 48.5218087, lng: 18.9501244 },
    { lat: 48.5217551, lng: 18.9503561 },
    { lat: 48.5214802, lng: 18.9509137 },
    { lat: 48.5212961, lng: 18.9516567 },
    { lat: 48.5211992, lng: 18.9519884 },
    { lat: 48.5211733, lng: 18.9523406 },
    { lat: 48.5209995, lng: 18.952735 },
    { lat: 48.5202794, lng: 18.9537766 },
    { lat: 48.5202189, lng: 18.9542715 },
    { lat: 48.5202888, lng: 18.954468 },
    { lat: 48.5203466, lng: 18.9545182 },
    { lat: 48.5205765, lng: 18.954716 },
    { lat: 48.5209956, lng: 18.9555246 },
    { lat: 48.5213726, lng: 18.956416 },
    { lat: 48.521593, lng: 18.9570204 },
    { lat: 48.5219149, lng: 18.9575401 },
    { lat: 48.5219582, lng: 18.9580544 },
    { lat: 48.5219134, lng: 18.9583064 },
    { lat: 48.5217863, lng: 18.9590186 },
    { lat: 48.5215716, lng: 18.9598242 },
    { lat: 48.5215652, lng: 18.9604257 },
    { lat: 48.5214281, lng: 18.9609007 },
    { lat: 48.5218314, lng: 18.9622202 },
    { lat: 48.5222838, lng: 18.9640149 },
    { lat: 48.5223312, lng: 18.9646977 },
    { lat: 48.5223212, lng: 18.9651875 },
    { lat: 48.5221121, lng: 18.9658171 },
    { lat: 48.5218454, lng: 18.9660969 },
    { lat: 48.5215038, lng: 18.9663149 },
    { lat: 48.5211742, lng: 18.9667268 },
    { lat: 48.5204657, lng: 18.9682001 },
    { lat: 48.5204435, lng: 18.9682745 },
    { lat: 48.5204011, lng: 18.9684163 },
    { lat: 48.5200251, lng: 18.9696665 },
    { lat: 48.5200085, lng: 18.9698925 },
    { lat: 48.5201617, lng: 18.9703101 },
    { lat: 48.5203047, lng: 18.9707297 },
    { lat: 48.5203134, lng: 18.9714752 },
    { lat: 48.5194368, lng: 18.9734984 },
    { lat: 48.5193211, lng: 18.9743668 },
    { lat: 48.5191451, lng: 18.9748323 },
    { lat: 48.5190983, lng: 18.9748865 },
    { lat: 48.5190717, lng: 18.9749173 },
    { lat: 48.518424, lng: 18.9756624 },
    { lat: 48.5182894, lng: 18.9762712 },
    { lat: 48.5176485, lng: 18.9770607 },
    { lat: 48.5169672, lng: 18.9775831 },
    { lat: 48.5163922, lng: 18.9777842 },
    { lat: 48.5158854, lng: 18.9776859 },
    { lat: 48.5151657, lng: 18.9776338 },
    { lat: 48.5150632, lng: 18.9777238 },
    { lat: 48.5148999, lng: 18.9780378 },
    { lat: 48.5146269, lng: 18.9783453 },
    { lat: 48.5139181, lng: 18.9796596 },
    { lat: 48.513811, lng: 18.9798583 },
    { lat: 48.5133477, lng: 18.9802147 },
    { lat: 48.5117684, lng: 18.9797361 },
    { lat: 48.5117465, lng: 18.9802791 },
    { lat: 48.5123032, lng: 18.9813817 },
    { lat: 48.5125992, lng: 18.9817469 },
    { lat: 48.5134241, lng: 18.9819164 },
    { lat: 48.5136455, lng: 18.9820139 },
    { lat: 48.514237, lng: 18.9824013 },
    { lat: 48.5145825, lng: 18.9825001 },
    { lat: 48.515017, lng: 18.9827986 },
    { lat: 48.5154981, lng: 18.9828359 },
    { lat: 48.5163601, lng: 18.9830481 },
    { lat: 48.5168192, lng: 18.9831609 },
    { lat: 48.5175329, lng: 18.9834279 },
    { lat: 48.518248, lng: 18.9836955 },
    { lat: 48.5188366, lng: 18.9837432 },
    { lat: 48.519127, lng: 18.9838005 },
    { lat: 48.5194734, lng: 18.9839586 },
    { lat: 48.520238, lng: 18.9841181 },
    { lat: 48.520846, lng: 18.9842134 },
    { lat: 48.5211035, lng: 18.9842669 },
    { lat: 48.5215476, lng: 18.9843306 },
    { lat: 48.5222058, lng: 18.9844568 },
    { lat: 48.5222581, lng: 18.9844672 },
    { lat: 48.5225029, lng: 18.9845143 },
    { lat: 48.5225633, lng: 18.9845259 },
    { lat: 48.5243526, lng: 18.9862095 },
    { lat: 48.524758, lng: 18.9860689 },
    { lat: 48.5248267, lng: 18.9860453 },
    { lat: 48.5251847, lng: 18.9859219 },
    { lat: 48.5254366, lng: 18.9860433 },
    { lat: 48.5255578, lng: 18.9860991 },
    { lat: 48.5261903, lng: 18.9857648 },
    { lat: 48.5270847, lng: 18.985237 },
    { lat: 48.5273194, lng: 18.9851086 },
    { lat: 48.5276426, lng: 18.9849924 },
    { lat: 48.5288028, lng: 18.9849252 },
    { lat: 48.5290266, lng: 18.9849588 },
    { lat: 48.5294263, lng: 18.9850131 },
    { lat: 48.52966, lng: 18.9854425 },
    { lat: 48.5297477, lng: 18.9856345 },
    { lat: 48.5300598, lng: 18.9858529 },
    { lat: 48.5300825, lng: 18.9860804 },
    { lat: 48.53021, lng: 18.9863482 },
    { lat: 48.5306275, lng: 18.9866184 },
    { lat: 48.5315882, lng: 18.9871303 },
    { lat: 48.5324144, lng: 18.9875709 },
    { lat: 48.532515, lng: 18.987608 },
  ],
  Beluj: [
    { lat: 48.328451, lng: 18.926389 },
    { lat: 48.328542, lng: 18.926412 },
    { lat: 48.328846, lng: 18.92708 },
    { lat: 48.329352, lng: 18.926758 },
    { lat: 48.329712, lng: 18.926926 },
    { lat: 48.329875, lng: 18.926751 },
    { lat: 48.330806, lng: 18.927085 },
    { lat: 48.331575, lng: 18.927535 },
    { lat: 48.3316647, lng: 18.9269943 },
    { lat: 48.3323804, lng: 18.9258288 },
    { lat: 48.3325392, lng: 18.9254071 },
    { lat: 48.3327035, lng: 18.9251152 },
    { lat: 48.3329104, lng: 18.9245837 },
    { lat: 48.3330188, lng: 18.9242331 },
    { lat: 48.3331634, lng: 18.9239119 },
    { lat: 48.333309, lng: 18.9237395 },
    { lat: 48.3335577, lng: 18.9236631 },
    { lat: 48.3338725, lng: 18.9234912 },
    { lat: 48.3342447, lng: 18.9235157 },
    { lat: 48.334332, lng: 18.9236137 },
    { lat: 48.3350133, lng: 18.9235279 },
    { lat: 48.3354347, lng: 18.9233926 },
    { lat: 48.3357254, lng: 18.923344 },
    { lat: 48.3365403, lng: 18.9232292 },
    { lat: 48.3365345, lng: 18.9229637 },
    { lat: 48.33664, lng: 18.9229111 },
    { lat: 48.3366966, lng: 18.9229605 },
    { lat: 48.3368243, lng: 18.9228107 },
    { lat: 48.3370058, lng: 18.92283 },
    { lat: 48.3372825, lng: 18.9229266 },
    { lat: 48.3381082, lng: 18.9226954 },
    { lat: 48.338199, lng: 18.9225948 },
    { lat: 48.3383451, lng: 18.9225651 },
    { lat: 48.3384918, lng: 18.9224127 },
    { lat: 48.3387628, lng: 18.922422 },
    { lat: 48.3390722, lng: 18.9216004 },
    { lat: 48.3391254, lng: 18.9207291 },
    { lat: 48.3393843, lng: 18.9204814 },
    { lat: 48.3396805, lng: 18.9204697 },
    { lat: 48.3398135, lng: 18.9204061 },
    { lat: 48.3399753, lng: 18.9204816 },
    { lat: 48.3401408, lng: 18.9207182 },
    { lat: 48.3404337, lng: 18.9207115 },
    { lat: 48.3412139, lng: 18.9190494 },
    { lat: 48.3413407, lng: 18.9191244 },
    { lat: 48.341788, lng: 18.9176097 },
    { lat: 48.3420487, lng: 18.9169149 },
    { lat: 48.3422151, lng: 18.9165128 },
    { lat: 48.3424218, lng: 18.9160822 },
    { lat: 48.3426096, lng: 18.9158747 },
    { lat: 48.3428347, lng: 18.9156727 },
    { lat: 48.3430176, lng: 18.915458 },
    { lat: 48.3433761, lng: 18.9149332 },
    { lat: 48.343892, lng: 18.9142668 },
    { lat: 48.3443402, lng: 18.9134927 },
    { lat: 48.3447218, lng: 18.9130205 },
    { lat: 48.3449627, lng: 18.9125346 },
    { lat: 48.345129, lng: 18.9119974 },
    { lat: 48.3454022, lng: 18.9113262 },
    { lat: 48.3455032, lng: 18.9107937 },
    { lat: 48.3459454, lng: 18.9103145 },
    { lat: 48.3467358, lng: 18.9098996 },
    { lat: 48.347052, lng: 18.9093435 },
    { lat: 48.3474124, lng: 18.9084846 },
    { lat: 48.3476751, lng: 18.9075699 },
    { lat: 48.3479053, lng: 18.9067438 },
    { lat: 48.3480882, lng: 18.9061589 },
    { lat: 48.3486464, lng: 18.9052879 },
    { lat: 48.3491227, lng: 18.904683 },
    { lat: 48.3495301, lng: 18.9042325 },
    { lat: 48.3499905, lng: 18.9038049 },
    { lat: 48.3502714, lng: 18.9038711 },
    { lat: 48.3506833, lng: 18.9036398 },
    { lat: 48.3513208, lng: 18.9032515 },
    { lat: 48.3519534, lng: 18.9025578 },
    { lat: 48.3524112, lng: 18.9018565 },
    { lat: 48.3529564, lng: 18.9018667 },
    { lat: 48.3529574, lng: 18.9021938 },
    { lat: 48.3531373, lng: 18.9021406 },
    { lat: 48.353348, lng: 18.9018317 },
    { lat: 48.3533634, lng: 18.9014311 },
    { lat: 48.3534802, lng: 18.9015742 },
    { lat: 48.3536143, lng: 18.9015947 },
    { lat: 48.3537742, lng: 18.9015928 },
    { lat: 48.3539491, lng: 18.9018134 },
    { lat: 48.3540224, lng: 18.9017684 },
    { lat: 48.3548085, lng: 18.9010745 },
    { lat: 48.3557773, lng: 18.9003164 },
    { lat: 48.3562476, lng: 18.8998801 },
    { lat: 48.3572832, lng: 18.8992808 },
    { lat: 48.3573123, lng: 18.8994493 },
    { lat: 48.3587311, lng: 18.8987989 },
    { lat: 48.3600327, lng: 18.898043 },
    { lat: 48.36101, lng: 18.8973419 },
    { lat: 48.3624497, lng: 18.8964865 },
    { lat: 48.3639041, lng: 18.8956124 },
    { lat: 48.3639769, lng: 18.8957996 },
    { lat: 48.3652508, lng: 18.8950968 },
    { lat: 48.3654309, lng: 18.8954811 },
    { lat: 48.3671637, lng: 18.8939268 },
    { lat: 48.3685682, lng: 18.8923299 },
    { lat: 48.3697469, lng: 18.8925321 },
    { lat: 48.3705278, lng: 18.891588 },
    { lat: 48.3709423, lng: 18.8912293 },
    { lat: 48.371603, lng: 18.8909465 },
    { lat: 48.3717099, lng: 18.8907202 },
    { lat: 48.3717451, lng: 18.8902692 },
    { lat: 48.3714012, lng: 18.8901849 },
    { lat: 48.3711887, lng: 18.8902381 },
    { lat: 48.3711693, lng: 18.8900371 },
    { lat: 48.3710985, lng: 18.8899113 },
    { lat: 48.3712008, lng: 18.8897778 },
    { lat: 48.3711299, lng: 18.8896506 },
    { lat: 48.3712809, lng: 18.889606 },
    { lat: 48.3714064, lng: 18.8894151 },
    { lat: 48.3720219, lng: 18.8896276 },
    { lat: 48.37212, lng: 18.8896704 },
    { lat: 48.3722604, lng: 18.8895522 },
    { lat: 48.3724247, lng: 18.8894586 },
    { lat: 48.3722669, lng: 18.8877169 },
    { lat: 48.3723108, lng: 18.8876983 },
    { lat: 48.3723334, lng: 18.8876956 },
    { lat: 48.3722167, lng: 18.8874454 },
    { lat: 48.3723157, lng: 18.8871824 },
    { lat: 48.3722591, lng: 18.8870468 },
    { lat: 48.3722805, lng: 18.8868489 },
    { lat: 48.3723338, lng: 18.88666 },
    { lat: 48.3725139, lng: 18.8866116 },
    { lat: 48.3725351, lng: 18.8865414 },
    { lat: 48.3724512, lng: 18.8863143 },
    { lat: 48.3724782, lng: 18.8861413 },
    { lat: 48.3724873, lng: 18.8858161 },
    { lat: 48.3726617, lng: 18.8856797 },
    { lat: 48.3728165, lng: 18.8857063 },
    { lat: 48.3732095, lng: 18.8853717 },
    { lat: 48.3733043, lng: 18.8851978 },
    { lat: 48.373437, lng: 18.8850593 },
    { lat: 48.3735652, lng: 18.8850308 },
    { lat: 48.3737748, lng: 18.8847031 },
    { lat: 48.3739273, lng: 18.8846186 },
    { lat: 48.3740788, lng: 18.8844101 },
    { lat: 48.3741792, lng: 18.8843455 },
    { lat: 48.3742769, lng: 18.8842521 },
    { lat: 48.3745158, lng: 18.884137 },
    { lat: 48.3746648, lng: 18.8839908 },
    { lat: 48.3747899, lng: 18.8840102 },
    { lat: 48.3749432, lng: 18.8838355 },
    { lat: 48.3750794, lng: 18.8838512 },
    { lat: 48.3751482, lng: 18.8837878 },
    { lat: 48.3752462, lng: 18.8835919 },
    { lat: 48.3755202, lng: 18.8836528 },
    { lat: 48.3756433, lng: 18.8833341 },
    { lat: 48.3759079, lng: 18.8830638 },
    { lat: 48.3760253, lng: 18.8830465 },
    { lat: 48.3761076, lng: 18.8828915 },
    { lat: 48.376221, lng: 18.8829052 },
    { lat: 48.3763646, lng: 18.8828036 },
    { lat: 48.37641, lng: 18.8826358 },
    { lat: 48.3764661, lng: 18.8825878 },
    { lat: 48.376606, lng: 18.8826739 },
    { lat: 48.3768623, lng: 18.8825964 },
    { lat: 48.3769246, lng: 18.8825579 },
    { lat: 48.3770782, lng: 18.882585 },
    { lat: 48.3772803, lng: 18.8824596 },
    { lat: 48.377429, lng: 18.8824557 },
    { lat: 48.3775754, lng: 18.8822357 },
    { lat: 48.3778176, lng: 18.8822074 },
    { lat: 48.3779647, lng: 18.8821292 },
    { lat: 48.3782436, lng: 18.8817241 },
    { lat: 48.3783961, lng: 18.8817943 },
    { lat: 48.3784959, lng: 18.8816542 },
    { lat: 48.378674, lng: 18.881694 },
    { lat: 48.3787486, lng: 18.8815704 },
    { lat: 48.3787651, lng: 18.8813414 },
    { lat: 48.3788187, lng: 18.8812678 },
    { lat: 48.3789573, lng: 18.881221 },
    { lat: 48.3791447, lng: 18.8810296 },
    { lat: 48.3793073, lng: 18.8807534 },
    { lat: 48.3794632, lng: 18.880715 },
    { lat: 48.3795744, lng: 18.8805122 },
    { lat: 48.3796955, lng: 18.8804973 },
    { lat: 48.3798152, lng: 18.8803045 },
    { lat: 48.3799758, lng: 18.8802485 },
    { lat: 48.3801667, lng: 18.8802333 },
    { lat: 48.3802792, lng: 18.8800101 },
    { lat: 48.3805924, lng: 18.8798305 },
    { lat: 48.380792, lng: 18.8798575 },
    { lat: 48.3809339, lng: 18.8797629 },
    { lat: 48.3809804, lng: 18.8796166 },
    { lat: 48.3810873, lng: 18.8795907 },
    { lat: 48.3811092, lng: 18.879581 },
    { lat: 48.3812013, lng: 18.879312 },
    { lat: 48.3814022, lng: 18.8791399 },
    { lat: 48.3816635, lng: 18.8787024 },
    { lat: 48.3817947, lng: 18.8786204 },
    { lat: 48.3818454, lng: 18.8783825 },
    { lat: 48.3819859, lng: 18.878261 },
    { lat: 48.3820931, lng: 18.8782432 },
    { lat: 48.3821853, lng: 18.8780664 },
    { lat: 48.382312, lng: 18.8779838 },
    { lat: 48.3824594, lng: 18.8779559 },
    { lat: 48.3826994, lng: 18.8774553 },
    { lat: 48.382794, lng: 18.8774339 },
    { lat: 48.3828449, lng: 18.8768491 },
    { lat: 48.3830752, lng: 18.8766139 },
    { lat: 48.3831727, lng: 18.8761672 },
    { lat: 48.3833167, lng: 18.8761422 },
    { lat: 48.3833564, lng: 18.8759687 },
    { lat: 48.3835077, lng: 18.8757793 },
    { lat: 48.3838335, lng: 18.8759274 },
    { lat: 48.3840727, lng: 18.8759335 },
    { lat: 48.3842987, lng: 18.8757669 },
    { lat: 48.3845984, lng: 18.8757187 },
    { lat: 48.3849557, lng: 18.8758705 },
    { lat: 48.3851674, lng: 18.8759915 },
    { lat: 48.3853933, lng: 18.8761747 },
    { lat: 48.3855049, lng: 18.8764125 },
    { lat: 48.3856507, lng: 18.8766122 },
    { lat: 48.3857101, lng: 18.8768007 },
    { lat: 48.3862914, lng: 18.8774024 },
    { lat: 48.3863996, lng: 18.8776608 },
    { lat: 48.3866225, lng: 18.8776936 },
    { lat: 48.3867, lng: 18.8777522 },
    { lat: 48.3868179, lng: 18.8777246 },
    { lat: 48.3868959, lng: 18.8778577 },
    { lat: 48.3872321, lng: 18.8779699 },
    { lat: 48.387408, lng: 18.8778815 },
    { lat: 48.387617, lng: 18.8779564 },
    { lat: 48.3878228, lng: 18.8777053 },
    { lat: 48.3880166, lng: 18.8776148 },
    { lat: 48.3881486, lng: 18.8774234 },
    { lat: 48.3883144, lng: 18.8770067 },
    { lat: 48.3885355, lng: 18.8767505 },
    { lat: 48.3886562, lng: 18.8767275 },
    { lat: 48.3887428, lng: 18.8765751 },
    { lat: 48.3889744, lng: 18.8762568 },
    { lat: 48.3891008, lng: 18.876215 },
    { lat: 48.389395, lng: 18.8762753 },
    { lat: 48.3895279, lng: 18.8761444 },
    { lat: 48.3899184, lng: 18.8758482 },
    { lat: 48.3899081, lng: 18.8758225 },
    { lat: 48.3902853, lng: 18.8759653 },
    { lat: 48.3906643, lng: 18.8756186 },
    { lat: 48.3906827, lng: 18.8756232 },
    { lat: 48.3907135, lng: 18.8754369 },
    { lat: 48.3909379, lng: 18.8753272 },
    { lat: 48.3910478, lng: 18.8750997 },
    { lat: 48.3911735, lng: 18.8750002 },
    { lat: 48.3912364, lng: 18.8747377 },
    { lat: 48.3915237, lng: 18.8745352 },
    { lat: 48.3915344, lng: 18.8745687 },
    { lat: 48.3919994, lng: 18.8742265 },
    { lat: 48.3922208, lng: 18.8741495 },
    { lat: 48.3923832, lng: 18.8740822 },
    { lat: 48.3925755, lng: 18.874032 },
    { lat: 48.3931007, lng: 18.8738084 },
    { lat: 48.3933259, lng: 18.8735386 },
    { lat: 48.3934428, lng: 18.8732737 },
    { lat: 48.3936529, lng: 18.8731522 },
    { lat: 48.3938939, lng: 18.8724928 },
    { lat: 48.3945085, lng: 18.8721696 },
    { lat: 48.3949178, lng: 18.8717973 },
    { lat: 48.3951505, lng: 18.8717193 },
    { lat: 48.395479, lng: 18.8714889 },
    { lat: 48.39578, lng: 18.8713784 },
    { lat: 48.3961274, lng: 18.8712302 },
    { lat: 48.3966454, lng: 18.8711308 },
    { lat: 48.3971671, lng: 18.8712059 },
    { lat: 48.3972023, lng: 18.8711709 },
    { lat: 48.3972454, lng: 18.8707809 },
    { lat: 48.3972409, lng: 18.8706316 },
    { lat: 48.3972735, lng: 18.8703269 },
    { lat: 48.3973494, lng: 18.8699401 },
    { lat: 48.3976737, lng: 18.8693241 },
    { lat: 48.3978182, lng: 18.8691759 },
    { lat: 48.3979983, lng: 18.8685182 },
    { lat: 48.3980855, lng: 18.8683316 },
    { lat: 48.3984429, lng: 18.8679398 },
    { lat: 48.3987627, lng: 18.8676724 },
    { lat: 48.3987664, lng: 18.8676347 },
    { lat: 48.3985365, lng: 18.8663811 },
    { lat: 48.3985531, lng: 18.8663099 },
    { lat: 48.3986936, lng: 18.8661909 },
    { lat: 48.398885, lng: 18.8658803 },
    { lat: 48.3987987, lng: 18.8648717 },
    { lat: 48.3987447, lng: 18.8648557 },
    { lat: 48.3987367, lng: 18.8648527 },
    { lat: 48.398001, lng: 18.8646271 },
    { lat: 48.3973046, lng: 18.864355 },
    { lat: 48.3971551, lng: 18.8642967 },
    { lat: 48.3962805, lng: 18.8640455 },
    { lat: 48.3962364, lng: 18.8640425 },
    { lat: 48.3961617, lng: 18.8640382 },
    { lat: 48.3959341, lng: 18.8639556 },
    { lat: 48.3958894, lng: 18.8639399 },
    { lat: 48.3953766, lng: 18.8637531 },
    { lat: 48.3944041, lng: 18.8634368 },
    { lat: 48.3937082, lng: 18.8632421 },
    { lat: 48.3936636, lng: 18.8632292 },
    { lat: 48.3936463, lng: 18.8632245 },
    { lat: 48.3924601, lng: 18.8628791 },
    { lat: 48.3915729, lng: 18.8631226 },
    { lat: 48.3906378, lng: 18.863353 },
    { lat: 48.3903224, lng: 18.8634258 },
    { lat: 48.3896929, lng: 18.8635713 },
    { lat: 48.3887703, lng: 18.863697 },
    { lat: 48.3881892, lng: 18.8637552 },
    { lat: 48.3868123, lng: 18.8640363 },
    { lat: 48.3866036, lng: 18.8641658 },
    { lat: 48.3865147, lng: 18.8641886 },
    { lat: 48.3863127, lng: 18.864339 },
    { lat: 48.3862324, lng: 18.8643988 },
    { lat: 48.3861922, lng: 18.864429 },
    { lat: 48.3854042, lng: 18.8650167 },
    { lat: 48.3851392, lng: 18.8653011 },
    { lat: 48.384723, lng: 18.8652764 },
    { lat: 48.3844057, lng: 18.8652463 },
    { lat: 48.3840194, lng: 18.8656232 },
    { lat: 48.3834634, lng: 18.8659041 },
    { lat: 48.383039, lng: 18.8658737 },
    { lat: 48.3829078, lng: 18.8659299 },
    { lat: 48.3825198, lng: 18.8658669 },
    { lat: 48.3824361, lng: 18.8659026 },
    { lat: 48.3820347, lng: 18.8660573 },
    { lat: 48.3811524, lng: 18.8660265 },
    { lat: 48.3809329, lng: 18.8661182 },
    { lat: 48.3806554, lng: 18.8663565 },
    { lat: 48.3804913, lng: 18.8663452 },
    { lat: 48.3804105, lng: 18.8665048 },
    { lat: 48.3802348, lng: 18.8665534 },
    { lat: 48.3801364, lng: 18.8667816 },
    { lat: 48.3798068, lng: 18.8670367 },
    { lat: 48.3795552, lng: 18.8674028 },
    { lat: 48.3793601, lng: 18.8674675 },
    { lat: 48.379085, lng: 18.8679016 },
    { lat: 48.3788621, lng: 18.8679331 },
    { lat: 48.378528, lng: 18.8681875 },
    { lat: 48.3784268, lng: 18.868254 },
    { lat: 48.3780654, lng: 18.8685933 },
    { lat: 48.3776788, lng: 18.8686148 },
    { lat: 48.3775528, lng: 18.8686242 },
    { lat: 48.3774766, lng: 18.8686303 },
    { lat: 48.3774096, lng: 18.8686464 },
    { lat: 48.377237, lng: 18.868558 },
    { lat: 48.3770644, lng: 18.8681019 },
    { lat: 48.3765649, lng: 18.8671475 },
    { lat: 48.3764675, lng: 18.8666836 },
    { lat: 48.3760977, lng: 18.8659946 },
    { lat: 48.3757421, lng: 18.8653354 },
    { lat: 48.3756997, lng: 18.8652567 },
    { lat: 48.3755008, lng: 18.8652257 },
    { lat: 48.375422, lng: 18.8650346 },
    { lat: 48.375221, lng: 18.8647499 },
    { lat: 48.3751615, lng: 18.864664 },
    { lat: 48.3749617, lng: 18.8645292 },
    { lat: 48.3747237, lng: 18.8642482 },
    { lat: 48.3746454, lng: 18.864218 },
    { lat: 48.3744971, lng: 18.864161 },
    { lat: 48.3742789, lng: 18.8640635 },
    { lat: 48.3740751, lng: 18.8638756 },
    { lat: 48.3739308, lng: 18.863745 },
    { lat: 48.3737975, lng: 18.8637869 },
    { lat: 48.373421, lng: 18.863354 },
    { lat: 48.3733332, lng: 18.8634019 },
    { lat: 48.3730968, lng: 18.8633211 },
    { lat: 48.3728958, lng: 18.8630149 },
    { lat: 48.3728583, lng: 18.8628345 },
    { lat: 48.3728073, lng: 18.8628075 },
    { lat: 48.3727584, lng: 18.8627806 },
    { lat: 48.372516, lng: 18.8627587 },
    { lat: 48.3722195, lng: 18.8625877 },
    { lat: 48.3718723, lng: 18.8624831 },
    { lat: 48.3717331, lng: 18.8622547 },
    { lat: 48.3714172, lng: 18.8622956 },
    { lat: 48.3713649, lng: 18.8622447 },
    { lat: 48.3712948, lng: 18.8622419 },
    { lat: 48.3712623, lng: 18.8621986 },
    { lat: 48.3711181, lng: 18.8622027 },
    { lat: 48.3708982, lng: 18.8624399 },
    { lat: 48.3707163, lng: 18.8622821 },
    { lat: 48.3706588, lng: 18.8623268 },
    { lat: 48.3705518, lng: 18.8626312 },
    { lat: 48.3704901, lng: 18.8627034 },
    { lat: 48.3702578, lng: 18.8626792 },
    { lat: 48.3701568, lng: 18.862496 },
    { lat: 48.3701224, lng: 18.8625302 },
    { lat: 48.3700999, lng: 18.8625754 },
    { lat: 48.3700663, lng: 18.862641 },
    { lat: 48.3700303, lng: 18.8626672 },
    { lat: 48.3699944, lng: 18.8626902 },
    { lat: 48.3694963, lng: 18.8627401 },
    { lat: 48.3694379, lng: 18.8626807 },
    { lat: 48.36942, lng: 18.8625966 },
    { lat: 48.3693394, lng: 18.8625007 },
    { lat: 48.3691247, lng: 18.8626827 },
    { lat: 48.3689603, lng: 18.8627126 },
    { lat: 48.3687288, lng: 18.8625546 },
    { lat: 48.3685643, lng: 18.8624682 },
    { lat: 48.3684732, lng: 18.8624509 },
    { lat: 48.3684102, lng: 18.8623899 },
    { lat: 48.3683618, lng: 18.8621962 },
    { lat: 48.3681618, lng: 18.8621888 },
    { lat: 48.3673053, lng: 18.861918 },
    { lat: 48.3671927, lng: 18.8620529 },
    { lat: 48.3671191, lng: 18.8619821 },
    { lat: 48.3670405, lng: 18.8619023 },
    { lat: 48.3667919, lng: 18.8618467 },
    { lat: 48.3665755, lng: 18.8620036 },
    { lat: 48.3661992, lng: 18.8617914 },
    { lat: 48.3660877, lng: 18.861575 },
    { lat: 48.3657615, lng: 18.8617002 },
    { lat: 48.3657268, lng: 18.8617684 },
    { lat: 48.3656415, lng: 18.8617362 },
    { lat: 48.3656079, lng: 18.8618905 },
    { lat: 48.3654594, lng: 18.86192 },
    { lat: 48.3654393, lng: 18.8619234 },
    { lat: 48.3654289, lng: 18.8619193 },
    { lat: 48.3651992, lng: 18.8618144 },
    { lat: 48.3650731, lng: 18.8614721 },
    { lat: 48.3650031, lng: 18.8614703 },
    { lat: 48.3648325, lng: 18.8617701 },
    { lat: 48.3647338, lng: 18.8617583 },
    { lat: 48.3644584, lng: 18.8617506 },
    { lat: 48.3641229, lng: 18.8613935 },
    { lat: 48.3638657, lng: 18.8615009 },
    { lat: 48.3636153, lng: 18.8612608 },
    { lat: 48.3630643, lng: 18.8612265 },
    { lat: 48.3627949, lng: 18.8614886 },
    { lat: 48.3616326, lng: 18.8614321 },
    { lat: 48.361387, lng: 18.8613931 },
    { lat: 48.3611072, lng: 18.8610441 },
    { lat: 48.3609974, lng: 18.860101 },
    { lat: 48.3609188, lng: 18.8596553 },
    { lat: 48.3610065, lng: 18.859456 },
    { lat: 48.3609822, lng: 18.8593337 },
    { lat: 48.3607878, lng: 18.8589824 },
    { lat: 48.3606431, lng: 18.8588661 },
    { lat: 48.3606312, lng: 18.8588565 },
    { lat: 48.3606155, lng: 18.8588137 },
    { lat: 48.3605594, lng: 18.858471 },
    { lat: 48.3604345, lng: 18.8582832 },
    { lat: 48.3600934, lng: 18.858036 },
    { lat: 48.3595158, lng: 18.8577705 },
    { lat: 48.3593968, lng: 18.857542 },
    { lat: 48.3592136, lng: 18.8569685 },
    { lat: 48.3589564, lng: 18.8568586 },
    { lat: 48.3588173, lng: 18.8560712 },
    { lat: 48.3584611, lng: 18.8561197 },
    { lat: 48.3584382, lng: 18.8561104 },
    { lat: 48.3584104, lng: 18.8560987 },
    { lat: 48.3581397, lng: 18.8559869 },
    { lat: 48.3579409, lng: 18.8560035 },
    { lat: 48.357745, lng: 18.8559914 },
    { lat: 48.35758, lng: 18.8560267 },
    { lat: 48.3573458, lng: 18.8561163 },
    { lat: 48.357273, lng: 18.8560837 },
    { lat: 48.3569499, lng: 18.8557706 },
    { lat: 48.3568089, lng: 18.85564 },
    { lat: 48.3565303, lng: 18.8556387 },
    { lat: 48.3563717, lng: 18.8555386 },
    { lat: 48.3557747, lng: 18.8558951 },
    { lat: 48.3555798, lng: 18.8562906 },
    { lat: 48.3553377, lng: 18.8564056 },
    { lat: 48.3547759, lng: 18.8569842 },
    { lat: 48.3545523, lng: 18.8570866 },
    { lat: 48.3541325, lng: 18.8571022 },
    { lat: 48.3539044, lng: 18.8572694 },
    { lat: 48.3536928, lng: 18.8575562 },
    { lat: 48.3534724, lng: 18.8576987 },
    { lat: 48.3529996, lng: 18.8581474 },
    { lat: 48.3527487, lng: 18.8584622 },
    { lat: 48.3524735, lng: 18.8587629 },
    { lat: 48.3523747, lng: 18.8590751 },
    { lat: 48.3519448, lng: 18.8593102 },
    { lat: 48.3517271, lng: 18.85963 },
    { lat: 48.3512933, lng: 18.8596178 },
    { lat: 48.3510582, lng: 18.8597993 },
    { lat: 48.3509438, lng: 18.859834 },
    { lat: 48.3507584, lng: 18.8597182 },
    { lat: 48.3505331, lng: 18.8598931 },
    { lat: 48.3504449, lng: 18.8600414 },
    { lat: 48.3500894, lng: 18.8602302 },
    { lat: 48.3496905, lng: 18.8606694 },
    { lat: 48.3490898, lng: 18.8604602 },
    { lat: 48.3486322, lng: 18.861197 },
    { lat: 48.3485817, lng: 18.8614202 },
    { lat: 48.3484118, lng: 18.8616199 },
    { lat: 48.3482276, lng: 18.8619172 },
    { lat: 48.3479934, lng: 18.8621212 },
    { lat: 48.3476221, lng: 18.8619843 },
    { lat: 48.3471698, lng: 18.8622639 },
    { lat: 48.3469943, lng: 18.862528 },
    { lat: 48.3467704, lng: 18.862712 },
    { lat: 48.34667, lng: 18.862845 },
    { lat: 48.3466538, lng: 18.86305 },
    { lat: 48.3465414, lng: 18.8634474 },
    { lat: 48.3462816, lng: 18.8636353 },
    { lat: 48.3461556, lng: 18.8638344 },
    { lat: 48.3459801, lng: 18.8639085 },
    { lat: 48.34585, lng: 18.8638787 },
    { lat: 48.3457343, lng: 18.8637338 },
    { lat: 48.3455571, lng: 18.8636032 },
    { lat: 48.3451857, lng: 18.8639169 },
    { lat: 48.3448673, lng: 18.8638482 },
    { lat: 48.3446796, lng: 18.8638773 },
    { lat: 48.3445291, lng: 18.8640637 },
    { lat: 48.3444792, lng: 18.8644903 },
    { lat: 48.3443636, lng: 18.8647615 },
    { lat: 48.3442781, lng: 18.8648297 },
    { lat: 48.3440108, lng: 18.8646565 },
    { lat: 48.3436438, lng: 18.8645155 },
    { lat: 48.3435175, lng: 18.8644088 },
    { lat: 48.3433942, lng: 18.8644877 },
    { lat: 48.3432272, lng: 18.8647239 },
    { lat: 48.3430151, lng: 18.864894 },
    { lat: 48.3426259, lng: 18.8650662 },
    { lat: 48.3423756, lng: 18.8652412 },
    { lat: 48.3416966, lng: 18.8649982 },
    { lat: 48.341457, lng: 18.8647084 },
    { lat: 48.3412802, lng: 18.8642992 },
    { lat: 48.3408868, lng: 18.8630264 },
    { lat: 48.3409581, lng: 18.8627072 },
    { lat: 48.3409692, lng: 18.862422 },
    { lat: 48.3409063, lng: 18.8622121 },
    { lat: 48.3403386, lng: 18.8616786 },
    { lat: 48.3402866, lng: 18.8614196 },
    { lat: 48.3401387, lng: 18.8614394 },
    { lat: 48.3399486, lng: 18.8613362 },
    { lat: 48.3395756, lng: 18.8614083 },
    { lat: 48.3394283, lng: 18.8617176 },
    { lat: 48.3393091, lng: 18.8620694 },
    { lat: 48.3390817, lng: 18.8619514 },
    { lat: 48.3388797, lng: 18.8621828 },
    { lat: 48.3380517, lng: 18.8621547 },
    { lat: 48.3377164, lng: 18.8622603 },
    { lat: 48.3377401, lng: 18.8623026 },
    { lat: 48.3377941, lng: 18.862387 },
    { lat: 48.3378248, lng: 18.8624358 },
    { lat: 48.3378355, lng: 18.8627053 },
    { lat: 48.3378386, lng: 18.8627861 },
    { lat: 48.3378396, lng: 18.8628062 },
    { lat: 48.3378688, lng: 18.8635393 },
    { lat: 48.3378959, lng: 18.8638632 },
    { lat: 48.3378069, lng: 18.864731 },
    { lat: 48.3376771, lng: 18.8657868 },
    { lat: 48.3376703, lng: 18.8658333 },
    { lat: 48.3376413, lng: 18.8659483 },
    { lat: 48.3376301, lng: 18.8659929 },
    { lat: 48.3374466, lng: 18.8667122 },
    { lat: 48.3372103, lng: 18.8672832 },
    { lat: 48.3368522, lng: 18.8677717 },
    { lat: 48.3360455, lng: 18.8701464 },
    { lat: 48.3356671, lng: 18.8713712 },
    { lat: 48.3353328, lng: 18.8727032 },
    { lat: 48.3351899, lng: 18.8739656 },
    { lat: 48.3345004, lng: 18.8743663 },
    { lat: 48.3341965, lng: 18.8746847 },
    { lat: 48.3328928, lng: 18.8758922 },
    { lat: 48.3322483, lng: 18.8763173 },
    { lat: 48.3314994, lng: 18.876856 },
    { lat: 48.3311046, lng: 18.8771796 },
    { lat: 48.3306729, lng: 18.8776387 },
    { lat: 48.3291872, lng: 18.8796887 },
    { lat: 48.3276377, lng: 18.8799906 },
    { lat: 48.3276006, lng: 18.8800768 },
    { lat: 48.3263585, lng: 18.8801982 },
    { lat: 48.3253099, lng: 18.8805273 },
    { lat: 48.3252833, lng: 18.8803369 },
    { lat: 48.3243938, lng: 18.8806215 },
    { lat: 48.3237645, lng: 18.8804715 },
    { lat: 48.322681, lng: 18.880171 },
    { lat: 48.322332, lng: 18.881949 },
    { lat: 48.322264, lng: 18.882424 },
    { lat: 48.322109, lng: 18.883492 },
    { lat: 48.321897, lng: 18.884523 },
    { lat: 48.321773, lng: 18.884861 },
    { lat: 48.32162, lng: 18.885107 },
    { lat: 48.32155, lng: 18.885189 },
    { lat: 48.321488, lng: 18.885299 },
    { lat: 48.32138, lng: 18.885513 },
    { lat: 48.321288, lng: 18.885565 },
    { lat: 48.321163, lng: 18.88553 },
    { lat: 48.321112, lng: 18.885611 },
    { lat: 48.321011, lng: 18.885535 },
    { lat: 48.320873, lng: 18.885706 },
    { lat: 48.320845, lng: 18.885606 },
    { lat: 48.320727, lng: 18.885512 },
    { lat: 48.32069, lng: 18.885587 },
    { lat: 48.320474, lng: 18.885487 },
    { lat: 48.320413, lng: 18.885351 },
    { lat: 48.320296, lng: 18.885505 },
    { lat: 48.320202, lng: 18.885438 },
    { lat: 48.320085, lng: 18.885594 },
    { lat: 48.32012, lng: 18.885696 },
    { lat: 48.320015, lng: 18.885752 },
    { lat: 48.319894, lng: 18.885631 },
    { lat: 48.319877, lng: 18.885782 },
    { lat: 48.319807, lng: 18.885881 },
    { lat: 48.319663, lng: 18.88583 },
    { lat: 48.319626, lng: 18.885924 },
    { lat: 48.319649, lng: 18.885978 },
    { lat: 48.319604, lng: 18.886044 },
    { lat: 48.319565, lng: 18.88609 },
    { lat: 48.319522, lng: 18.886018 },
    { lat: 48.319538, lng: 18.885936 },
    { lat: 48.319486, lng: 18.885908 },
    { lat: 48.319458, lng: 18.885961 },
    { lat: 48.319418, lng: 18.885957 },
    { lat: 48.319451, lng: 18.886132 },
    { lat: 48.319343, lng: 18.886073 },
    { lat: 48.319286, lng: 18.886137 },
    { lat: 48.319355, lng: 18.88618 },
    { lat: 48.319352, lng: 18.88627 },
    { lat: 48.319269, lng: 18.88628 },
    { lat: 48.319157, lng: 18.886375 },
    { lat: 48.319144, lng: 18.886517 },
    { lat: 48.318996, lng: 18.88662 },
    { lat: 48.318866, lng: 18.886524 },
    { lat: 48.318822, lng: 18.886654 },
    { lat: 48.318702, lng: 18.886694 },
    { lat: 48.318653, lng: 18.886793 },
    { lat: 48.318532, lng: 18.88679 },
    { lat: 48.318493, lng: 18.886871 },
    { lat: 48.318416, lng: 18.886956 },
    { lat: 48.318371, lng: 18.886911 },
    { lat: 48.318291, lng: 18.886936 },
    { lat: 48.318148, lng: 18.886837 },
    { lat: 48.318062, lng: 18.886982 },
    { lat: 48.317938, lng: 18.887042 },
    { lat: 48.317891, lng: 18.887207 },
    { lat: 48.317792, lng: 18.887181 },
    { lat: 48.317665, lng: 18.887184 },
    { lat: 48.317564, lng: 18.887314 },
    { lat: 48.317443, lng: 18.887269 },
    { lat: 48.317228, lng: 18.887463 },
    { lat: 48.317127, lng: 18.887445 },
    { lat: 48.317075, lng: 18.887396 },
    { lat: 48.317046, lng: 18.887462 },
    { lat: 48.316916, lng: 18.887516 },
    { lat: 48.316853, lng: 18.887523 },
    { lat: 48.316806, lng: 18.887379 },
    { lat: 48.316705, lng: 18.887495 },
    { lat: 48.316655, lng: 18.887688 },
    { lat: 48.31658, lng: 18.887778 },
    { lat: 48.316494, lng: 18.887772 },
    { lat: 48.316429, lng: 18.88789 },
    { lat: 48.316365, lng: 18.887734 },
    { lat: 48.316293, lng: 18.887838 },
    { lat: 48.316289, lng: 18.888058 },
    { lat: 48.316322, lng: 18.888348 },
    { lat: 48.316355, lng: 18.888582 },
    { lat: 48.316317, lng: 18.888672 },
    { lat: 48.316261, lng: 18.888879 },
    { lat: 48.316131, lng: 18.889024 },
    { lat: 48.31605, lng: 18.889208 },
    { lat: 48.315874, lng: 18.889311 },
    { lat: 48.315658, lng: 18.889411 },
    { lat: 48.31561, lng: 18.889562 },
    { lat: 48.315632, lng: 18.889714 },
    { lat: 48.315403, lng: 18.89014 },
    { lat: 48.315345, lng: 18.890142 },
    { lat: 48.315311, lng: 18.890242 },
    { lat: 48.315226, lng: 18.890267 },
    { lat: 48.315076, lng: 18.890653 },
    { lat: 48.314961, lng: 18.890812 },
    { lat: 48.314767, lng: 18.890808 },
    { lat: 48.314592, lng: 18.890686 },
    { lat: 48.314343, lng: 18.89103 },
    { lat: 48.314235, lng: 18.891028 },
    { lat: 48.314308, lng: 18.891178 },
    { lat: 48.314282, lng: 18.891368 },
    { lat: 48.314172, lng: 18.891372 },
    { lat: 48.314166, lng: 18.891431 },
    { lat: 48.314112, lng: 18.89146 },
    { lat: 48.314035, lng: 18.891334 },
    { lat: 48.313978, lng: 18.891323 },
    { lat: 48.313883, lng: 18.891547 },
    { lat: 48.314477, lng: 18.892801 },
    { lat: 48.314736, lng: 18.89335 },
    { lat: 48.314814, lng: 18.893533 },
    { lat: 48.314884, lng: 18.893697 },
    { lat: 48.314924, lng: 18.89403 },
    { lat: 48.31497, lng: 18.894196 },
    { lat: 48.315124, lng: 18.894752 },
    { lat: 48.315159, lng: 18.8951 },
    { lat: 48.315364, lng: 18.895737 },
    { lat: 48.315455, lng: 18.895796 },
    { lat: 48.315496, lng: 18.896196 },
    { lat: 48.31551, lng: 18.896817 },
    { lat: 48.315579, lng: 18.897469 },
    { lat: 48.315692, lng: 18.897726 },
    { lat: 48.315847, lng: 18.898521 },
    { lat: 48.315862, lng: 18.898736 },
    { lat: 48.315953, lng: 18.899254 },
    { lat: 48.316095, lng: 18.899574 },
    { lat: 48.315304, lng: 18.900208 },
    { lat: 48.314965, lng: 18.90022 },
    { lat: 48.31445, lng: 18.900023 },
    { lat: 48.314203, lng: 18.899776 },
    { lat: 48.313825, lng: 18.899664 },
    { lat: 48.313577, lng: 18.899889 },
    { lat: 48.31333, lng: 18.900168 },
    { lat: 48.313104, lng: 18.900208 },
    { lat: 48.312599, lng: 18.900564 },
    { lat: 48.312457, lng: 18.900743 },
    { lat: 48.312407, lng: 18.90083 },
    { lat: 48.311822, lng: 18.90137 },
    { lat: 48.311407, lng: 18.901742 },
    { lat: 48.310998, lng: 18.90202 },
    { lat: 48.310623, lng: 18.902647 },
    { lat: 48.310162, lng: 18.903309 },
    { lat: 48.31014, lng: 18.903311 },
    { lat: 48.310226, lng: 18.903572 },
    { lat: 48.310408, lng: 18.903869 },
    { lat: 48.310469, lng: 18.904421 },
    { lat: 48.310492, lng: 18.904866 },
    { lat: 48.310507, lng: 18.905147 },
    { lat: 48.310387, lng: 18.905499 },
    { lat: 48.310313, lng: 18.905758 },
    { lat: 48.31038, lng: 18.906177 },
    { lat: 48.310496, lng: 18.906989 },
    { lat: 48.310861, lng: 18.90792 },
    { lat: 48.31105, lng: 18.908668 },
    { lat: 48.311319, lng: 18.90946 },
    { lat: 48.311637, lng: 18.910167 },
    { lat: 48.311798, lng: 18.910486 },
    { lat: 48.3123, lng: 18.911916 },
    { lat: 48.312707, lng: 18.912367 },
    { lat: 48.312985, lng: 18.912865 },
    { lat: 48.313249, lng: 18.913076 },
    { lat: 48.31349, lng: 18.913387 },
    { lat: 48.313577, lng: 18.914066 },
    { lat: 48.31371, lng: 18.914324 },
    { lat: 48.313827, lng: 18.914835 },
    { lat: 48.314124, lng: 18.915909 },
    { lat: 48.314184, lng: 18.916161 },
    { lat: 48.314248, lng: 18.916436 },
    { lat: 48.314232, lng: 18.916561 },
    { lat: 48.314289, lng: 18.916817 },
    { lat: 48.314308, lng: 18.916906 },
    { lat: 48.314349, lng: 18.917088 },
    { lat: 48.31432, lng: 18.917402 },
    { lat: 48.314421, lng: 18.91762 },
    { lat: 48.314464, lng: 18.917752 },
    { lat: 48.314479, lng: 18.91802 },
    { lat: 48.314475, lng: 18.918332 },
    { lat: 48.314489, lng: 18.918493 },
    { lat: 48.314559, lng: 18.918552 },
    { lat: 48.314657, lng: 18.918676 },
    { lat: 48.314689, lng: 18.918943 },
    { lat: 48.314727, lng: 18.919169 },
    { lat: 48.314745, lng: 18.919288 },
    { lat: 48.314869, lng: 18.919294 },
    { lat: 48.315002, lng: 18.919264 },
    { lat: 48.315224, lng: 18.919183 },
    { lat: 48.315752, lng: 18.918698 },
    { lat: 48.315972, lng: 18.918639 },
    { lat: 48.316274, lng: 18.918462 },
    { lat: 48.316516, lng: 18.918311 },
    { lat: 48.316669, lng: 18.918469 },
    { lat: 48.317042, lng: 18.918487 },
    { lat: 48.317302, lng: 18.918545 },
    { lat: 48.317856, lng: 18.917895 },
    { lat: 48.318011, lng: 18.917611 },
    { lat: 48.318128, lng: 18.91755 },
    { lat: 48.318232, lng: 18.917342 },
    { lat: 48.31812, lng: 18.916922 },
    { lat: 48.318207, lng: 18.916681 },
    { lat: 48.318589, lng: 18.91682 },
    { lat: 48.318719, lng: 18.916543 },
    { lat: 48.318903, lng: 18.916404 },
    { lat: 48.319005, lng: 18.916413 },
    { lat: 48.319175, lng: 18.916116 },
    { lat: 48.319309, lng: 18.916045 },
    { lat: 48.319584, lng: 18.916218 },
    { lat: 48.319615, lng: 18.916176 },
    { lat: 48.319701, lng: 18.915875 },
    { lat: 48.319937, lng: 18.915484 },
    { lat: 48.319941, lng: 18.91523 },
    { lat: 48.320131, lng: 18.915182 },
    { lat: 48.320467, lng: 18.914984 },
    { lat: 48.320781, lng: 18.914574 },
    { lat: 48.320931, lng: 18.914051 },
    { lat: 48.321198, lng: 18.91365 },
    { lat: 48.321227, lng: 18.913684 },
    { lat: 48.321455, lng: 18.914005 },
    { lat: 48.321527, lng: 18.914192 },
    { lat: 48.321573, lng: 18.91439 },
    { lat: 48.321761, lng: 18.914776 },
    { lat: 48.321864, lng: 18.91489 },
    { lat: 48.321874, lng: 18.914871 },
    { lat: 48.322009, lng: 18.914929 },
    { lat: 48.322318, lng: 18.915048 },
    { lat: 48.3224, lng: 18.915183 },
    { lat: 48.322807, lng: 18.915306 },
    { lat: 48.322944, lng: 18.915481 },
    { lat: 48.32318, lng: 18.915688 },
    { lat: 48.323301, lng: 18.915906 },
    { lat: 48.323564, lng: 18.916273 },
    { lat: 48.323603, lng: 18.916484 },
    { lat: 48.323743, lng: 18.916589 },
    { lat: 48.323881, lng: 18.916911 },
    { lat: 48.324113, lng: 18.917161 },
    { lat: 48.324122, lng: 18.917332 },
    { lat: 48.324269, lng: 18.917598 },
    { lat: 48.324261, lng: 18.917802 },
    { lat: 48.324436, lng: 18.917954 },
    { lat: 48.324466, lng: 18.918324 },
    { lat: 48.324591, lng: 18.918684 },
    { lat: 48.324763, lng: 18.918678 },
    { lat: 48.324791, lng: 18.918851 },
    { lat: 48.325031, lng: 18.918975 },
    { lat: 48.325151, lng: 18.919191 },
    { lat: 48.325184, lng: 18.919551 },
    { lat: 48.325406, lng: 18.919829 },
    { lat: 48.32562, lng: 18.91996 },
    { lat: 48.325887, lng: 18.919889 },
    { lat: 48.325959, lng: 18.92007 },
    { lat: 48.326356, lng: 18.920227 },
    { lat: 48.326436, lng: 18.920325 },
    { lat: 48.326518, lng: 18.920397 },
    { lat: 48.326658, lng: 18.920665 },
    { lat: 48.326685, lng: 18.920932 },
    { lat: 48.32683, lng: 18.920848 },
    { lat: 48.327079, lng: 18.920954 },
    { lat: 48.327185, lng: 18.921098 },
    { lat: 48.327276, lng: 18.921105 },
    { lat: 48.327326, lng: 18.921194 },
    { lat: 48.327419, lng: 18.921253 },
    { lat: 48.327557, lng: 18.921305 },
    { lat: 48.327695, lng: 18.921367 },
    { lat: 48.327888, lng: 18.921604 },
    { lat: 48.328071, lng: 18.921651 },
    { lat: 48.328259, lng: 18.921805 },
    { lat: 48.328312, lng: 18.921961 },
    { lat: 48.328427, lng: 18.922083 },
    { lat: 48.328395, lng: 18.922696 },
    { lat: 48.328098, lng: 18.923745 },
    { lat: 48.328096, lng: 18.924245 },
    { lat: 48.328032, lng: 18.925091 },
    { lat: 48.328252, lng: 18.925296 },
    { lat: 48.328472, lng: 18.9255 },
    { lat: 48.32845, lng: 18.925936 },
    { lat: 48.328424, lng: 18.926317 },
    { lat: 48.328451, lng: 18.926389 },
  ],
  BanskáŠtiavnica: [
    { lat: 48.4132708, lng: 18.8699513 },
    { lat: 48.4136379, lng: 18.8703757 },
    { lat: 48.4140802, lng: 18.8707072 },
    { lat: 48.4145584, lng: 18.8710575 },
    { lat: 48.4149634, lng: 18.8714307 },
    { lat: 48.4156271, lng: 18.8718981 },
    { lat: 48.4159474, lng: 18.8725282 },
    { lat: 48.4162038, lng: 18.8731878 },
    { lat: 48.4164401, lng: 18.8740898 },
    { lat: 48.417052, lng: 18.874865 },
    { lat: 48.4170941, lng: 18.8748707 },
    { lat: 48.41727, lng: 18.8764794 },
    { lat: 48.4177113, lng: 18.8775303 },
    { lat: 48.4181172, lng: 18.8779651 },
    { lat: 48.4181841, lng: 18.878035 },
    { lat: 48.418331, lng: 18.8781925 },
    { lat: 48.4187084, lng: 18.8794056 },
    { lat: 48.4186773, lng: 18.8806957 },
    { lat: 48.4187158, lng: 18.8809441 },
    { lat: 48.4189096, lng: 18.8821963 },
    { lat: 48.4192121, lng: 18.8827268 },
    { lat: 48.418988, lng: 18.8839273 },
    { lat: 48.4198223, lng: 18.8846202 },
    { lat: 48.4201981, lng: 18.886283 },
    { lat: 48.4202298, lng: 18.8874827 },
    { lat: 48.4203802, lng: 18.8880143 },
    { lat: 48.4202727, lng: 18.888963 },
    { lat: 48.4204399, lng: 18.8898433 },
    { lat: 48.4215307, lng: 18.8902831 },
    { lat: 48.4227829, lng: 18.8909703 },
    { lat: 48.4236744, lng: 18.8925311 },
    { lat: 48.4237035, lng: 18.8925501 },
    { lat: 48.4236926, lng: 18.8930157 },
    { lat: 48.4235156, lng: 18.8933633 },
    { lat: 48.4234281, lng: 18.8936764 },
    { lat: 48.4232895, lng: 18.894006 },
    { lat: 48.4232408, lng: 18.8941349 },
    { lat: 48.4231284, lng: 18.8942737 },
    { lat: 48.4231607, lng: 18.8944822 },
    { lat: 48.4231634, lng: 18.8946835 },
    { lat: 48.4231068, lng: 18.8948556 },
    { lat: 48.4231249, lng: 18.8948765 },
    { lat: 48.4231721, lng: 18.8949204 },
    { lat: 48.4231316, lng: 18.8950511 },
    { lat: 48.4231296, lng: 18.8950588 },
    { lat: 48.4232285, lng: 18.8951797 },
    { lat: 48.4232481, lng: 18.8953208 },
    { lat: 48.423264, lng: 18.8954326 },
    { lat: 48.4234174, lng: 18.8956479 },
    { lat: 48.4235131, lng: 18.8959083 },
    { lat: 48.4235603, lng: 18.8960352 },
    { lat: 48.4235448, lng: 18.8962841 },
    { lat: 48.423534, lng: 18.8964675 },
    { lat: 48.4235889, lng: 18.8967667 },
    { lat: 48.4236596, lng: 18.8975224 },
    { lat: 48.4236843, lng: 18.8976285 },
    { lat: 48.423935, lng: 18.8986806 },
    { lat: 48.4240585, lng: 18.8988888 },
    { lat: 48.4242246, lng: 18.8991371 },
    { lat: 48.4243628, lng: 18.8991952 },
    { lat: 48.4244459, lng: 18.8992295 },
    { lat: 48.424473, lng: 18.8993151 },
    { lat: 48.4244822, lng: 18.8993428 },
    { lat: 48.4245036, lng: 18.8994098 },
    { lat: 48.4245156, lng: 18.8994457 },
    { lat: 48.4248177, lng: 18.899398 },
    { lat: 48.4250122, lng: 18.8995624 },
    { lat: 48.4252125, lng: 18.8997723 },
    { lat: 48.4255433, lng: 18.9000408 },
    { lat: 48.4262605, lng: 18.8998928 },
    { lat: 48.426557, lng: 18.8999332 },
    { lat: 48.4267845, lng: 18.900169 },
    { lat: 48.4273596, lng: 18.9003884 },
    { lat: 48.4281517, lng: 18.9003311 },
    { lat: 48.4283527, lng: 18.9003165 },
    { lat: 48.4283114, lng: 18.9008251 },
    { lat: 48.4283063, lng: 18.9008811 },
    { lat: 48.4280811, lng: 18.9019136 },
    { lat: 48.4283226, lng: 18.9033553 },
    { lat: 48.4282845, lng: 18.9036529 },
    { lat: 48.4282751, lng: 18.9037268 },
    { lat: 48.4280261, lng: 18.9046879 },
    { lat: 48.4280914, lng: 18.9057556 },
    { lat: 48.4280476, lng: 18.9058683 },
    { lat: 48.4280098, lng: 18.9059004 },
    { lat: 48.4279876, lng: 18.906999 },
    { lat: 48.4278474, lng: 18.9069923 },
    { lat: 48.4277437, lng: 18.9072135 },
    { lat: 48.4278243, lng: 18.9084265 },
    { lat: 48.4277031, lng: 18.9099239 },
    { lat: 48.4275617, lng: 18.9102617 },
    { lat: 48.4275881, lng: 18.9104475 },
    { lat: 48.4277263, lng: 18.9111159 },
    { lat: 48.4279173, lng: 18.9112967 },
    { lat: 48.4280104, lng: 18.9117272 },
    { lat: 48.4281028, lng: 18.9122085 },
    { lat: 48.4281311, lng: 18.9131478 },
    { lat: 48.42813, lng: 18.9132136 },
    { lat: 48.4281847, lng: 18.9132925 },
    { lat: 48.4281533, lng: 18.9133803 },
    { lat: 48.4281485, lng: 18.9135299 },
    { lat: 48.4276976, lng: 18.9142112 },
    { lat: 48.4266816, lng: 18.9151276 },
    { lat: 48.4260303, lng: 18.9160572 },
    { lat: 48.4259538, lng: 18.9163189 },
    { lat: 48.4259848, lng: 18.9168155 },
    { lat: 48.4256571, lng: 18.9185373 },
    { lat: 48.4253113, lng: 18.9194676 },
    { lat: 48.4245055, lng: 18.9216961 },
    { lat: 48.4257608, lng: 18.9236958 },
    { lat: 48.4264737, lng: 18.925346 },
    { lat: 48.4276739, lng: 18.9261656 },
    { lat: 48.4280759, lng: 18.9266128 },
    { lat: 48.4281165, lng: 18.9269652 },
    { lat: 48.4293237, lng: 18.9284688 },
    { lat: 48.4301563, lng: 18.9292686 },
    { lat: 48.4301818, lng: 18.9293036 },
    { lat: 48.4302943, lng: 18.929429 },
    { lat: 48.4303659, lng: 18.9295944 },
    { lat: 48.4304293, lng: 18.929712 },
    { lat: 48.4304467, lng: 18.9298939 },
    { lat: 48.4305127, lng: 18.9301017 },
    { lat: 48.430459, lng: 18.9301552 },
    { lat: 48.4307354, lng: 18.9312521 },
    { lat: 48.4307893, lng: 18.9312498 },
    { lat: 48.4308443, lng: 18.9313996 },
    { lat: 48.4311329, lng: 18.9317513 },
    { lat: 48.4315321, lng: 18.9322322 },
    { lat: 48.4316284, lng: 18.9323492 },
    { lat: 48.4318544, lng: 18.9326237 },
    { lat: 48.4318886, lng: 18.9326532 },
    { lat: 48.4319277, lng: 18.9327118 },
    { lat: 48.4319509, lng: 18.9327443 },
    { lat: 48.4319835, lng: 18.9327905 },
    { lat: 48.4320068, lng: 18.9328244 },
    { lat: 48.4322287, lng: 18.9344905 },
    { lat: 48.4324479, lng: 18.9360344 },
    { lat: 48.4334701, lng: 18.9371678 },
    { lat: 48.4338621, lng: 18.938936 },
    { lat: 48.4338538, lng: 18.9389945 },
    { lat: 48.4341365, lng: 18.9410325 },
    { lat: 48.4341563, lng: 18.9414186 },
    { lat: 48.4343932, lng: 18.9417527 },
    { lat: 48.4343814, lng: 18.9423978 },
    { lat: 48.4344778, lng: 18.9426959 },
    { lat: 48.4350335, lng: 18.9446504 },
    { lat: 48.4357001, lng: 18.9458863 },
    { lat: 48.4359528, lng: 18.9487694 },
    { lat: 48.4357081, lng: 18.9498455 },
    { lat: 48.4357733, lng: 18.9506334 },
    { lat: 48.435761, lng: 18.9516063 },
    { lat: 48.435676, lng: 18.9525201 },
    { lat: 48.4357694, lng: 18.9528917 },
    { lat: 48.4360529, lng: 18.9529924 },
    { lat: 48.4359987, lng: 18.9538896 },
    { lat: 48.435558, lng: 18.9565805 },
    { lat: 48.4354378, lng: 18.9578457 },
    { lat: 48.4355425, lng: 18.9601334 },
    { lat: 48.4357817, lng: 18.9615076 },
    { lat: 48.4369843, lng: 18.9639239 },
    { lat: 48.4372017, lng: 18.9604772 },
    { lat: 48.4372476, lng: 18.9603817 },
    { lat: 48.4384259, lng: 18.9617955 },
    { lat: 48.4396461, lng: 18.9629674 },
    { lat: 48.4400744, lng: 18.9631876 },
    { lat: 48.4408513, lng: 18.9635871 },
    { lat: 48.4416309, lng: 18.9624827 },
    { lat: 48.4423601, lng: 18.9614498 },
    { lat: 48.4428691, lng: 18.9606435 },
    { lat: 48.4431162, lng: 18.9600286 },
    { lat: 48.4434195, lng: 18.9588572 },
    { lat: 48.4438796, lng: 18.9585089 },
    { lat: 48.4448989, lng: 18.9574986 },
    { lat: 48.4449474, lng: 18.9574529 },
    { lat: 48.445009, lng: 18.9574003 },
    { lat: 48.4467548, lng: 18.9549397 },
    { lat: 48.4467842, lng: 18.9549654 },
    { lat: 48.4470153, lng: 18.9537042 },
    { lat: 48.4475716, lng: 18.952747 },
    { lat: 48.448085, lng: 18.9514317 },
    { lat: 48.448186, lng: 18.9497053 },
    { lat: 48.4484518, lng: 18.9487777 },
    { lat: 48.448518, lng: 18.9485481 },
    { lat: 48.4486724, lng: 18.9474945 },
    { lat: 48.4486741, lng: 18.9468632 },
    { lat: 48.4488777, lng: 18.9463967 },
    { lat: 48.4491069, lng: 18.9458069 },
    { lat: 48.4491297, lng: 18.9456474 },
    { lat: 48.4492905, lng: 18.9450969 },
    { lat: 48.4496958, lng: 18.9441393 },
    { lat: 48.4499447, lng: 18.9434332 },
    { lat: 48.4502481, lng: 18.9427288 },
    { lat: 48.4502882, lng: 18.9421925 },
    { lat: 48.4503886, lng: 18.941755 },
    { lat: 48.4505655, lng: 18.940805 },
    { lat: 48.450265, lng: 18.9394329 },
    { lat: 48.4501205, lng: 18.9382655 },
    { lat: 48.450099, lng: 18.9358908 },
    { lat: 48.4496125, lng: 18.9343212 },
    { lat: 48.449951, lng: 18.9334512 },
    { lat: 48.4503529, lng: 18.933 },
    { lat: 48.4505195, lng: 18.9326407 },
    { lat: 48.4507154, lng: 18.9320209 },
    { lat: 48.4508705, lng: 18.9317692 },
    { lat: 48.4515404, lng: 18.9307636 },
    { lat: 48.4516254, lng: 18.9306842 },
    { lat: 48.4517169, lng: 18.9305989 },
    { lat: 48.4520363, lng: 18.9298802 },
    { lat: 48.4523876, lng: 18.9294991 },
    { lat: 48.4524364, lng: 18.9294587 },
    { lat: 48.4524641, lng: 18.9293862 },
    { lat: 48.4528407, lng: 18.9287055 },
    { lat: 48.4528744, lng: 18.9284611 },
    { lat: 48.453051, lng: 18.9280815 },
    { lat: 48.4530889, lng: 18.9280281 },
    { lat: 48.453105, lng: 18.9280109 },
    { lat: 48.4531659, lng: 18.9279446 },
    { lat: 48.4533997, lng: 18.9276901 },
    { lat: 48.4535351, lng: 18.9276477 },
    { lat: 48.4535904, lng: 18.9276302 },
    { lat: 48.4536271, lng: 18.9276185 },
    { lat: 48.4536483, lng: 18.9276122 },
    { lat: 48.4538424, lng: 18.9275505 },
    { lat: 48.4542215, lng: 18.9274692 },
    { lat: 48.4544243, lng: 18.9273096 },
    { lat: 48.4548231, lng: 18.9272415 },
    { lat: 48.4553753, lng: 18.9275859 },
    { lat: 48.4555926, lng: 18.9277091 },
    { lat: 48.4557041, lng: 18.9278361 },
    { lat: 48.4561358, lng: 18.928296 },
    { lat: 48.4570427, lng: 18.9289832 },
    { lat: 48.4583462, lng: 18.9294008 },
    { lat: 48.4590138, lng: 18.9289442 },
    { lat: 48.4593438, lng: 18.9288685 },
    { lat: 48.4595807, lng: 18.9287185 },
    { lat: 48.4597639, lng: 18.9285973 },
    { lat: 48.4600551, lng: 18.9286914 },
    { lat: 48.4600808, lng: 18.9286856 },
    { lat: 48.460195, lng: 18.9288887 },
    { lat: 48.4602894, lng: 18.9290166 },
    { lat: 48.4604373, lng: 18.9292182 },
    { lat: 48.4606898, lng: 18.92985 },
    { lat: 48.4607252, lng: 18.9300573 },
    { lat: 48.4607351, lng: 18.9301386 },
    { lat: 48.4607704, lng: 18.9304531 },
    { lat: 48.460827, lng: 18.9306995 },
    { lat: 48.4608293, lng: 18.9309838 },
    { lat: 48.4607866, lng: 18.9313849 },
    { lat: 48.4607122, lng: 18.9317576 },
    { lat: 48.4606229, lng: 18.9320629 },
    { lat: 48.4605295, lng: 18.9324382 },
    { lat: 48.4606187, lng: 18.932835 },
    { lat: 48.4608485, lng: 18.9330499 },
    { lat: 48.4610253, lng: 18.9332215 },
    { lat: 48.4610166, lng: 18.9332502 },
    { lat: 48.4615241, lng: 18.9335359 },
    { lat: 48.4619012, lng: 18.9337412 },
    { lat: 48.4619691, lng: 18.9341447 },
    { lat: 48.4617262, lng: 18.9345299 },
    { lat: 48.4613739, lng: 18.9347197 },
    { lat: 48.461294, lng: 18.9350721 },
    { lat: 48.4611589, lng: 18.9350328 },
    { lat: 48.4610685, lng: 18.9350063 },
    { lat: 48.460992, lng: 18.9353616 },
    { lat: 48.4608147, lng: 18.9355754 },
    { lat: 48.460932, lng: 18.9363654 },
    { lat: 48.4609763, lng: 18.9363545 },
    { lat: 48.4610018, lng: 18.9366074 },
    { lat: 48.4611474, lng: 18.9365653 },
    { lat: 48.4613296, lng: 18.9365121 },
    { lat: 48.4613392, lng: 18.9362851 },
    { lat: 48.4614909, lng: 18.9360088 },
    { lat: 48.4614693, lng: 18.9357215 },
    { lat: 48.461571, lng: 18.9354386 },
    { lat: 48.4616475, lng: 18.9352386 },
    { lat: 48.461865, lng: 18.9351263 },
    { lat: 48.4619679, lng: 18.9349541 },
    { lat: 48.4620497, lng: 18.9348807 },
    { lat: 48.4621601, lng: 18.9348124 },
    { lat: 48.4621035, lng: 18.9346961 },
    { lat: 48.4623496, lng: 18.9344174 },
    { lat: 48.4625987, lng: 18.9338666 },
    { lat: 48.4628734, lng: 18.9332894 },
    { lat: 48.4630542, lng: 18.9327479 },
    { lat: 48.4632322, lng: 18.9325475 },
    { lat: 48.463482, lng: 18.9324058 },
    { lat: 48.4639453, lng: 18.9321609 },
    { lat: 48.4646444, lng: 18.932074 },
    { lat: 48.4652184, lng: 18.9321622 },
    { lat: 48.465589, lng: 18.9321554 },
    { lat: 48.4656542, lng: 18.9321326 },
    { lat: 48.4664237, lng: 18.9312847 },
    { lat: 48.4672956, lng: 18.9308529 },
    { lat: 48.4674541, lng: 18.9307748 },
    { lat: 48.4698551, lng: 18.9303466 },
    { lat: 48.4698166, lng: 18.9300143 },
    { lat: 48.4698079, lng: 18.9299328 },
    { lat: 48.4695186, lng: 18.9273975 },
    { lat: 48.4694653, lng: 18.9269311 },
    { lat: 48.4696164, lng: 18.9266897 },
    { lat: 48.4704845, lng: 18.9256109 },
    { lat: 48.4710122, lng: 18.9249782 },
    { lat: 48.4710763, lng: 18.9249519 },
    { lat: 48.4721519, lng: 18.924512 },
    { lat: 48.4722054, lng: 18.9244997 },
    { lat: 48.4722003, lng: 18.9244658 },
    { lat: 48.4721409, lng: 18.924035 },
    { lat: 48.4721667, lng: 18.9236148 },
    { lat: 48.4721034, lng: 18.9231499 },
    { lat: 48.4719875, lng: 18.9229836 },
    { lat: 48.4719379, lng: 18.9226056 },
    { lat: 48.4718711, lng: 18.9223835 },
    { lat: 48.4718535, lng: 18.9223251 },
    { lat: 48.4718235, lng: 18.9222248 },
    { lat: 48.4718405, lng: 18.92216 },
    { lat: 48.4718135, lng: 18.9218785 },
    { lat: 48.4717579, lng: 18.9214998 },
    { lat: 48.4717412, lng: 18.9211304 },
    { lat: 48.4717097, lng: 18.9207158 },
    { lat: 48.471708, lng: 18.9204214 },
    { lat: 48.4716871, lng: 18.9202526 },
    { lat: 48.4717664, lng: 18.9200209 },
    { lat: 48.4717831, lng: 18.9195979 },
    { lat: 48.4717961, lng: 18.9195651 },
    { lat: 48.4719192, lng: 18.9193966 },
    { lat: 48.4719382, lng: 18.9193482 },
    { lat: 48.4720289, lng: 18.9190949 },
    { lat: 48.4720546, lng: 18.919093 },
    { lat: 48.4721092, lng: 18.919098 },
    { lat: 48.4721407, lng: 18.9191004 },
    { lat: 48.4723719, lng: 18.9191214 },
    { lat: 48.4724841, lng: 18.9187395 },
    { lat: 48.472508, lng: 18.9186575 },
    { lat: 48.4727658, lng: 18.9187117 },
    { lat: 48.472792, lng: 18.9187172 },
    { lat: 48.4728193, lng: 18.9187233 },
    { lat: 48.4730668, lng: 18.9187755 },
    { lat: 48.4733336, lng: 18.9186808 },
    { lat: 48.4733911, lng: 18.9186602 },
    { lat: 48.4734554, lng: 18.9186375 },
    { lat: 48.4734963, lng: 18.9186231 },
    { lat: 48.4735286, lng: 18.9186133 },
    { lat: 48.4736321, lng: 18.9186087 },
    { lat: 48.4736524, lng: 18.9186078 },
    { lat: 48.4736568, lng: 18.9186076 },
    { lat: 48.47367, lng: 18.9186022 },
    { lat: 48.4741517, lng: 18.9184078 },
    { lat: 48.4753567, lng: 18.9179214 },
    { lat: 48.4768185, lng: 18.9155656 },
    { lat: 48.4768328, lng: 18.9155587 },
    { lat: 48.4772281, lng: 18.9153758 },
    { lat: 48.4772708, lng: 18.9153683 },
    { lat: 48.477336, lng: 18.9153465 },
    { lat: 48.4773758, lng: 18.9153287 },
    { lat: 48.4782742, lng: 18.9152162 },
    { lat: 48.4786927, lng: 18.9155022 },
    { lat: 48.4793049, lng: 18.9153275 },
    { lat: 48.4793363, lng: 18.9158907 },
    { lat: 48.4794135, lng: 18.916166 },
    { lat: 48.4797063, lng: 18.9166152 },
    { lat: 48.4799716, lng: 18.9166888 },
    { lat: 48.4801191, lng: 18.9168415 },
    { lat: 48.480288, lng: 18.9170336 },
    { lat: 48.4803937, lng: 18.9170484 },
    { lat: 48.4804253, lng: 18.9170468 },
    { lat: 48.4807136, lng: 18.9171948 },
    { lat: 48.4809028, lng: 18.9172769 },
    { lat: 48.4812524, lng: 18.9173894 },
    { lat: 48.4816239, lng: 18.9174872 },
    { lat: 48.4819978, lng: 18.9175453 },
    { lat: 48.4822506, lng: 18.9175038 },
    { lat: 48.4825962, lng: 18.9175382 },
    { lat: 48.4830573, lng: 18.9174482 },
    { lat: 48.4836618, lng: 18.9158541 },
    { lat: 48.4837794, lng: 18.9155721 },
    { lat: 48.4838511, lng: 18.915391 },
    { lat: 48.4840018, lng: 18.9150311 },
    { lat: 48.4848977, lng: 18.9135768 },
    { lat: 48.484995, lng: 18.9135154 },
    { lat: 48.4851581, lng: 18.9135116 },
    { lat: 48.486389, lng: 18.9133045 },
    { lat: 48.4871232, lng: 18.9131994 },
    { lat: 48.4878207, lng: 18.912993 },
    { lat: 48.4888617, lng: 18.9124916 },
    { lat: 48.48927, lng: 18.9121671 },
    { lat: 48.4892773, lng: 18.912093 },
    { lat: 48.4894321, lng: 18.9118368 },
    { lat: 48.4896798, lng: 18.9104252 },
    { lat: 48.4896752, lng: 18.9095936 },
    { lat: 48.4896718, lng: 18.9089602 },
    { lat: 48.4898307, lng: 18.9081682 },
    { lat: 48.4898833, lng: 18.9070668 },
    { lat: 48.4899204, lng: 18.906128 },
    { lat: 48.4899496, lng: 18.9060578 },
    { lat: 48.4900569, lng: 18.905801 },
    { lat: 48.4909686, lng: 18.9040395 },
    { lat: 48.4913442, lng: 18.9035211 },
    { lat: 48.4913963, lng: 18.9034809 },
    { lat: 48.4914214, lng: 18.903308 },
    { lat: 48.4914602, lng: 18.9030348 },
    { lat: 48.4918168, lng: 18.9024716 },
    { lat: 48.4918544, lng: 18.9024132 },
    { lat: 48.4918417, lng: 18.9022131 },
    { lat: 48.4918083, lng: 18.9016749 },
    { lat: 48.4918594, lng: 18.9012644 },
    { lat: 48.4916962, lng: 18.9004833 },
    { lat: 48.4914984, lng: 18.9001019 },
    { lat: 48.4913097, lng: 18.8993991 },
    { lat: 48.4916213, lng: 18.897966 },
    { lat: 48.4916358, lng: 18.8978946 },
    { lat: 48.4915847, lng: 18.8959448 },
    { lat: 48.4910859, lng: 18.8952399 },
    { lat: 48.490578, lng: 18.894487 },
    { lat: 48.4899664, lng: 18.8940868 },
    { lat: 48.4899023, lng: 18.8940449 },
    { lat: 48.4899857, lng: 18.8938475 },
    { lat: 48.4900073, lng: 18.893589 },
    { lat: 48.4897389, lng: 18.8930467 },
    { lat: 48.4897362, lng: 18.8928835 },
    { lat: 48.4897767, lng: 18.8927252 },
    { lat: 48.4903679, lng: 18.8922781 },
    { lat: 48.4907086, lng: 18.8910336 },
    { lat: 48.4915888, lng: 18.8895684 },
    { lat: 48.4926071, lng: 18.888607 },
    { lat: 48.4927217, lng: 18.8880764 },
    { lat: 48.4926916, lng: 18.8880614 },
    { lat: 48.4932707, lng: 18.886225 },
    { lat: 48.4934976, lng: 18.8855728 },
    { lat: 48.493773, lng: 18.8849902 },
    { lat: 48.4945005, lng: 18.8842096 },
    { lat: 48.4950746, lng: 18.8836244 },
    { lat: 48.4950971, lng: 18.8836015 },
    { lat: 48.4952558, lng: 18.883446 },
    { lat: 48.4959305, lng: 18.8826022 },
    { lat: 48.4964698, lng: 18.8826496 },
    { lat: 48.4966656, lng: 18.8826675 },
    { lat: 48.4971113, lng: 18.8824994 },
    { lat: 48.4977057, lng: 18.8825893 },
    { lat: 48.4979211, lng: 18.8828923 },
    { lat: 48.4981578, lng: 18.8830657 },
    { lat: 48.4986508, lng: 18.8830261 },
    { lat: 48.4989066, lng: 18.8828272 },
    { lat: 48.4991222, lng: 18.8825463 },
    { lat: 48.4995611, lng: 18.8821379 },
    { lat: 48.4996433, lng: 18.8816352 },
    { lat: 48.4997943, lng: 18.8814799 },
    { lat: 48.500012, lng: 18.8814415 },
    { lat: 48.5003744, lng: 18.8810336 },
    { lat: 48.5007051, lng: 18.8810403 },
    { lat: 48.5015454, lng: 18.8810868 },
    { lat: 48.5019508, lng: 18.8811236 },
    { lat: 48.502125, lng: 18.8810697 },
    { lat: 48.5022979, lng: 18.8809225 },
    { lat: 48.5024796, lng: 18.88062 },
    { lat: 48.5026469, lng: 18.8802597 },
    { lat: 48.502784, lng: 18.8799233 },
    { lat: 48.5030196, lng: 18.8797044 },
    { lat: 48.5032808, lng: 18.8795646 },
    { lat: 48.5035498, lng: 18.8795761 },
    { lat: 48.5038805, lng: 18.8796706 },
    { lat: 48.5041747, lng: 18.8796894 },
    { lat: 48.5042747, lng: 18.8796635 },
    { lat: 48.5044072, lng: 18.8795836 },
    { lat: 48.5045425, lng: 18.8793914 },
    { lat: 48.5047326, lng: 18.8792507 },
    { lat: 48.5053575, lng: 18.8791223 },
    { lat: 48.5055917, lng: 18.8791367 },
    { lat: 48.5058585, lng: 18.8792832 },
    { lat: 48.506143, lng: 18.8795066 },
    { lat: 48.5063002, lng: 18.8797997 },
    { lat: 48.5064227, lng: 18.8801164 },
    { lat: 48.506632, lng: 18.8803269 },
    { lat: 48.5068648, lng: 18.8805094 },
    { lat: 48.5071679, lng: 18.880833 },
    { lat: 48.5073968, lng: 18.8811596 },
    { lat: 48.5077197, lng: 18.8818198 },
    { lat: 48.5080289, lng: 18.8823937 },
    { lat: 48.5083676, lng: 18.8828145 },
    { lat: 48.5086736, lng: 18.883194 },
    { lat: 48.5089621, lng: 18.8834308 },
    { lat: 48.5093557, lng: 18.8835806 },
    { lat: 48.5097785, lng: 18.8835375 },
    { lat: 48.5103595, lng: 18.8833488 },
    { lat: 48.5106165, lng: 18.8833653 },
    { lat: 48.5112781, lng: 18.8840139 },
    { lat: 48.5114713, lng: 18.8840442 },
    { lat: 48.5116017, lng: 18.8839945 },
    { lat: 48.5117189, lng: 18.8839942 },
    { lat: 48.5117652, lng: 18.8839941 },
    { lat: 48.511856, lng: 18.883981 },
    { lat: 48.512038, lng: 18.883717 },
    { lat: 48.512191, lng: 18.883371 },
    { lat: 48.512183, lng: 18.88299 },
    { lat: 48.512329, lng: 18.882759 },
    { lat: 48.512339, lng: 18.882669 },
    { lat: 48.512338, lng: 18.882503 },
    { lat: 48.512308, lng: 18.882373 },
    { lat: 48.512142, lng: 18.882085 },
    { lat: 48.512122, lng: 18.881778 },
    { lat: 48.51204, lng: 18.881457 },
    { lat: 48.51201, lng: 18.881062 },
    { lat: 48.511944, lng: 18.880783 },
    { lat: 48.511995, lng: 18.880523 },
    { lat: 48.511869, lng: 18.879869 },
    { lat: 48.511798, lng: 18.879666 },
    { lat: 48.511696, lng: 18.879467 },
    { lat: 48.511542, lng: 18.879239 },
    { lat: 48.511385, lng: 18.879147 },
    { lat: 48.511255, lng: 18.8791 },
    { lat: 48.511221, lng: 18.87869 },
    { lat: 48.511274, lng: 18.878299 },
    { lat: 48.511269, lng: 18.878118 },
    { lat: 48.511168, lng: 18.877677 },
    { lat: 48.511014, lng: 18.877201 },
    { lat: 48.510749, lng: 18.87672 },
    { lat: 48.510501, lng: 18.876642 },
    { lat: 48.510372, lng: 18.876573 },
    { lat: 48.510317, lng: 18.876184 },
    { lat: 48.510376, lng: 18.875834 },
    { lat: 48.510482, lng: 18.875687 },
    { lat: 48.510606, lng: 18.875349 },
    { lat: 48.510638, lng: 18.875081 },
    { lat: 48.510641, lng: 18.874665 },
    { lat: 48.510523, lng: 18.874405 },
    { lat: 48.510388, lng: 18.874067 },
    { lat: 48.510404, lng: 18.873631 },
    { lat: 48.510547, lng: 18.873311 },
    { lat: 48.510768, lng: 18.873192 },
    { lat: 48.510957, lng: 18.872968 },
    { lat: 48.5111, lng: 18.872687 },
    { lat: 48.511304, lng: 18.872183 },
    { lat: 48.511417, lng: 18.871811 },
    { lat: 48.511526, lng: 18.871256 },
    { lat: 48.511486, lng: 18.871034 },
    { lat: 48.511617, lng: 18.870815 },
    { lat: 48.511752, lng: 18.870604 },
    { lat: 48.511832, lng: 18.870087 },
    { lat: 48.511852, lng: 18.869406 },
    { lat: 48.511791, lng: 18.868734 },
    { lat: 48.511718, lng: 18.868431 },
    { lat: 48.511663, lng: 18.86802 },
    { lat: 48.51157, lng: 18.867814 },
    { lat: 48.511411, lng: 18.867623 },
    { lat: 48.51134, lng: 18.867436 },
    { lat: 48.511355, lng: 18.867253 },
    { lat: 48.511187, lng: 18.866814 },
    { lat: 48.511188, lng: 18.866438 },
    { lat: 48.511168, lng: 18.866198 },
    { lat: 48.511362, lng: 18.865885 },
    { lat: 48.511489, lng: 18.865726 },
    { lat: 48.511606, lng: 18.865428 },
    { lat: 48.511595, lng: 18.865188 },
    { lat: 48.511782, lng: 18.86497 },
    { lat: 48.511819, lng: 18.864875 },
    { lat: 48.511838, lng: 18.864828 },
    { lat: 48.511865, lng: 18.864759 },
    { lat: 48.511869, lng: 18.864749 },
    { lat: 48.511995, lng: 18.864184 },
    { lat: 48.512029, lng: 18.863587 },
    { lat: 48.511975, lng: 18.863065 },
    { lat: 48.511986, lng: 18.862766 },
    { lat: 48.511582, lng: 18.862639 },
    { lat: 48.510522, lng: 18.86242 },
    { lat: 48.509357, lng: 18.862497 },
    { lat: 48.508734, lng: 18.862497 },
    { lat: 48.508431, lng: 18.862582 },
    { lat: 48.5080338, lng: 18.862909 },
    { lat: 48.50795, lng: 18.862978 },
    { lat: 48.507365, lng: 18.863697 },
    { lat: 48.507137, lng: 18.863801 },
    { lat: 48.506431, lng: 18.863882 },
    { lat: 48.504627, lng: 18.862719 },
    { lat: 48.501921, lng: 18.86166 },
    { lat: 48.501874, lng: 18.861641 },
    { lat: 48.501156, lng: 18.860262 },
    { lat: 48.499421, lng: 18.85906 },
    { lat: 48.498805, lng: 18.858383 },
    { lat: 48.498299, lng: 18.857728 },
    { lat: 48.498278, lng: 18.8577 },
    { lat: 48.498218, lng: 18.857624 },
    { lat: 48.497914, lng: 18.856464 },
    { lat: 48.497833, lng: 18.855964 },
    { lat: 48.497827, lng: 18.855924 },
    { lat: 48.497822, lng: 18.855893 },
    { lat: 48.497641, lng: 18.855386 },
    { lat: 48.497232, lng: 18.854663 },
    { lat: 48.497202, lng: 18.854611 },
    { lat: 48.496771, lng: 18.853847 },
    { lat: 48.496901, lng: 18.852267 },
    { lat: 48.497443, lng: 18.85107 },
    { lat: 48.497489, lng: 18.850969 },
    { lat: 48.497711, lng: 18.850478 },
    { lat: 48.4976544, lng: 18.8503335 },
    { lat: 48.4975801, lng: 18.8501437 },
    { lat: 48.497495, lng: 18.8499263 },
    { lat: 48.4974208, lng: 18.8497369 },
    { lat: 48.4973732, lng: 18.8496155 },
    { lat: 48.497328, lng: 18.8495 },
    { lat: 48.497671, lng: 18.848624 },
    { lat: 48.498124, lng: 18.848032 },
    { lat: 48.4979, lng: 18.847612 },
    { lat: 48.497618, lng: 18.8473699 },
    { lat: 48.497277, lng: 18.847077 },
    { lat: 48.496968, lng: 18.846519 },
    { lat: 48.496761, lng: 18.845809 },
    { lat: 48.496629, lng: 18.845292 },
    { lat: 48.496247, lng: 18.843601 },
    { lat: 48.495869, lng: 18.843272 },
    { lat: 48.495676, lng: 18.843176 },
    { lat: 48.495529, lng: 18.843181 },
    { lat: 48.495376, lng: 18.843254 },
    { lat: 48.495086, lng: 18.843511 },
    { lat: 48.494922, lng: 18.843582 },
    { lat: 48.494518, lng: 18.843423 },
    { lat: 48.494508, lng: 18.843334 },
    { lat: 48.494382, lng: 18.843187 },
    { lat: 48.494376, lng: 18.843178 },
    { lat: 48.494256, lng: 18.843011 },
    { lat: 48.494116, lng: 18.84269 },
    { lat: 48.494111, lng: 18.842663 },
    { lat: 48.494106, lng: 18.842643 },
    { lat: 48.494105, lng: 18.842628 },
    { lat: 48.49409, lng: 18.842533 },
    { lat: 48.494085, lng: 18.842499 },
    { lat: 48.494035, lng: 18.842415 },
    { lat: 48.493957, lng: 18.842265 },
    { lat: 48.493927, lng: 18.842161 },
    { lat: 48.493904, lng: 18.841961 },
    { lat: 48.493757, lng: 18.84188 },
    { lat: 48.493718, lng: 18.841699 },
    { lat: 48.493827, lng: 18.841331 },
    { lat: 48.493832, lng: 18.841126 },
    { lat: 48.493924, lng: 18.841045 },
    { lat: 48.493911, lng: 18.840905 },
    { lat: 48.494016, lng: 18.840686 },
    { lat: 48.494036, lng: 18.840544 },
    { lat: 48.494113, lng: 18.840427 },
    { lat: 48.494173, lng: 18.840333 },
    { lat: 48.494216, lng: 18.840207 },
    { lat: 48.494157, lng: 18.839538 },
    { lat: 48.494172, lng: 18.839471 },
    { lat: 48.494291, lng: 18.838946 },
    { lat: 48.494288, lng: 18.83842 },
    { lat: 48.494249, lng: 18.837049 },
    { lat: 48.494255, lng: 18.836547 },
    { lat: 48.49436, lng: 18.835836 },
    { lat: 48.494582, lng: 18.835108 },
    { lat: 48.494613, lng: 18.835031 },
    { lat: 48.494648, lng: 18.834943 },
    { lat: 48.494724, lng: 18.834748 },
    { lat: 48.494427, lng: 18.834942 },
    { lat: 48.49422, lng: 18.835002 },
    { lat: 48.49399, lng: 18.834966 },
    { lat: 48.493641, lng: 18.834845 },
    { lat: 48.493397, lng: 18.834961 },
    { lat: 48.492991, lng: 18.834799 },
    { lat: 48.492559, lng: 18.83453 },
    { lat: 48.492583, lng: 18.833805 },
    { lat: 48.492528, lng: 18.833401 },
    { lat: 48.492526, lng: 18.833079 },
    { lat: 48.492404, lng: 18.832559 },
    { lat: 48.492357, lng: 18.832288 },
    { lat: 48.492151, lng: 18.831837 },
    { lat: 48.492049, lng: 18.83139 },
    { lat: 48.491971, lng: 18.831125 },
    { lat: 48.491974, lng: 18.830753 },
    { lat: 48.492118, lng: 18.83042 },
    { lat: 48.492223, lng: 18.830376 },
    { lat: 48.492644, lng: 18.830158 },
    { lat: 48.493014, lng: 18.829557 },
    { lat: 48.492872, lng: 18.829121 },
    { lat: 48.492837, lng: 18.828754 },
    { lat: 48.492862, lng: 18.828451 },
    { lat: 48.492932, lng: 18.827899 },
    { lat: 48.493161, lng: 18.826862 },
    { lat: 48.49329, lng: 18.82616 },
    { lat: 48.493361, lng: 18.825523 },
    { lat: 48.493321, lng: 18.825023 },
    { lat: 48.4929, lng: 18.824675 },
    { lat: 48.492567, lng: 18.82471 },
    { lat: 48.49239, lng: 18.82447 },
    { lat: 48.492237, lng: 18.82409 },
    { lat: 48.491775, lng: 18.823314 },
    { lat: 48.491738, lng: 18.822792 },
    { lat: 48.491705, lng: 18.822257 },
    { lat: 48.4917065, lng: 18.821865 },
    { lat: 48.491707, lng: 18.821723 },
    { lat: 48.491847, lng: 18.821085 },
    { lat: 48.4919657, lng: 18.8204982 },
    { lat: 48.491973, lng: 18.820462 },
    { lat: 48.491732, lng: 18.820364 },
    { lat: 48.491499, lng: 18.820313 },
    { lat: 48.491044, lng: 18.820339 },
    { lat: 48.490629, lng: 18.820089 },
    { lat: 48.490398, lng: 18.81991 },
    { lat: 48.490034, lng: 18.819152 },
    { lat: 48.489944, lng: 18.818567 },
    { lat: 48.490152, lng: 18.81787 },
    { lat: 48.490224, lng: 18.817431 },
    { lat: 48.490198, lng: 18.817057 },
    { lat: 48.489887, lng: 18.816212 },
    { lat: 48.489894, lng: 18.815734 },
    { lat: 48.489921, lng: 18.81539 },
    { lat: 48.489812, lng: 18.814828 },
    { lat: 48.489535, lng: 18.814338 },
    { lat: 48.489444, lng: 18.814028 },
    { lat: 48.489404, lng: 18.813897 },
    { lat: 48.489039, lng: 18.813858 },
    { lat: 48.488748, lng: 18.814052 },
    { lat: 48.488328, lng: 18.814199 },
    { lat: 48.488252, lng: 18.814436 },
    { lat: 48.487816, lng: 18.815021 },
    { lat: 48.487179, lng: 18.815627 },
    { lat: 48.487137, lng: 18.815641 },
    { lat: 48.487104, lng: 18.815645 },
    { lat: 48.487034, lng: 18.815707 },
    { lat: 48.48637, lng: 18.81563 },
    { lat: 48.486217, lng: 18.815583 },
    { lat: 48.485863, lng: 18.815298 },
    { lat: 48.4856288, lng: 18.8151562 },
    { lat: 48.485541, lng: 18.815103 },
    { lat: 48.485419, lng: 18.815048 },
    { lat: 48.484757, lng: 18.814926 },
    { lat: 48.484614, lng: 18.8149 },
    { lat: 48.484455, lng: 18.814818 },
    { lat: 48.484359, lng: 18.814702 },
    { lat: 48.48423, lng: 18.814767 },
    { lat: 48.484108, lng: 18.814739 },
    { lat: 48.48391, lng: 18.814806 },
    { lat: 48.483708, lng: 18.814951 },
    { lat: 48.483513, lng: 18.81503 },
    { lat: 48.483313, lng: 18.815138 },
    { lat: 48.483179, lng: 18.815178 },
    { lat: 48.482925, lng: 18.815241 },
    { lat: 48.482668, lng: 18.815357 },
    { lat: 48.482414, lng: 18.815515 },
    { lat: 48.482316, lng: 18.815526 },
    { lat: 48.481951, lng: 18.815289 },
    { lat: 48.481636, lng: 18.815547 },
    { lat: 48.481544, lng: 18.815502 },
    { lat: 48.481351, lng: 18.815474 },
    { lat: 48.481095, lng: 18.815534 },
    { lat: 48.48007, lng: 18.815765 },
    { lat: 48.479216, lng: 18.815437 },
    { lat: 48.478919, lng: 18.815401 },
    { lat: 48.478477, lng: 18.815203 },
    { lat: 48.478114, lng: 18.814963 },
    { lat: 48.477972, lng: 18.814983 },
    { lat: 48.47787, lng: 18.815032 },
    { lat: 48.477742, lng: 18.815282 },
    { lat: 48.477621, lng: 18.815463 },
    { lat: 48.477412, lng: 18.815888 },
    { lat: 48.476981, lng: 18.816451 },
    { lat: 48.476876, lng: 18.81664 },
    { lat: 48.47674, lng: 18.817116 },
    { lat: 48.476604, lng: 18.81765 },
    { lat: 48.476574, lng: 18.817993 },
    { lat: 48.47663, lng: 18.818665 },
    { lat: 48.476674, lng: 18.818805 },
    { lat: 48.476995, lng: 18.819424 },
    { lat: 48.477098, lng: 18.819304 },
    { lat: 48.477408, lng: 18.81904 },
    { lat: 48.477651, lng: 18.819312 },
    { lat: 48.477881, lng: 18.819576 },
    { lat: 48.478148, lng: 18.819514 },
    { lat: 48.478178, lng: 18.819584 },
    { lat: 48.478421, lng: 18.820271 },
    { lat: 48.478463, lng: 18.82091 },
    { lat: 48.478589, lng: 18.821299 },
    { lat: 48.479295, lng: 18.821536 },
    { lat: 48.479527, lng: 18.820988 },
    { lat: 48.479794, lng: 18.820768 },
    { lat: 48.480146, lng: 18.821502 },
    { lat: 48.480386, lng: 18.821398 },
    { lat: 48.481089, lng: 18.820834 },
    { lat: 48.481975, lng: 18.820923 },
    { lat: 48.481704, lng: 18.82177 },
    { lat: 48.481367, lng: 18.822965 },
    { lat: 48.481303, lng: 18.823375 },
    { lat: 48.481261, lng: 18.824394 },
    { lat: 48.481346, lng: 18.825242 },
    { lat: 48.481421, lng: 18.825588 },
    { lat: 48.481972, lng: 18.826683 },
    { lat: 48.482158, lng: 18.827419 },
    { lat: 48.482348, lng: 18.82878 },
    { lat: 48.48205, lng: 18.828613 },
    { lat: 48.481844, lng: 18.828783 },
    { lat: 48.481784, lng: 18.829928 },
    { lat: 48.481726, lng: 18.830021 },
    { lat: 48.48145, lng: 18.830119 },
    { lat: 48.481445, lng: 18.830436 },
    { lat: 48.481505, lng: 18.830425 },
    { lat: 48.481624, lng: 18.831088 },
    { lat: 48.481563, lng: 18.83121 },
    { lat: 48.481651, lng: 18.831624 },
    { lat: 48.481765, lng: 18.832041 },
    { lat: 48.481847, lng: 18.832664 },
    { lat: 48.481793, lng: 18.832707 },
    { lat: 48.48179, lng: 18.832758 },
    { lat: 48.4814504, lng: 18.8331754 },
    { lat: 48.480725, lng: 18.834067 },
    { lat: 48.480655, lng: 18.833868 },
    { lat: 48.480569, lng: 18.833694 },
    { lat: 48.48037, lng: 18.833798 },
    { lat: 48.479347, lng: 18.8342 },
    { lat: 48.479395, lng: 18.834963 },
    { lat: 48.479289, lng: 18.835046 },
    { lat: 48.479107, lng: 18.834951 },
    { lat: 48.478696, lng: 18.835052 },
    { lat: 48.478011, lng: 18.835217 },
    { lat: 48.478075, lng: 18.835521 },
    { lat: 48.478148, lng: 18.836376 },
    { lat: 48.478155, lng: 18.836585 },
    { lat: 48.47812, lng: 18.836814 },
    { lat: 48.477945, lng: 18.837176 },
    { lat: 48.4779, lng: 18.837279 },
    { lat: 48.478101, lng: 18.83792 },
    { lat: 48.478451, lng: 18.838458 },
    { lat: 48.47895, lng: 18.838568 },
    { lat: 48.47877, lng: 18.839224 },
    { lat: 48.478188, lng: 18.838957 },
    { lat: 48.477935, lng: 18.839083 },
    { lat: 48.477673, lng: 18.839423 },
    { lat: 48.477363, lng: 18.839448 },
    { lat: 48.477214, lng: 18.83916 },
    { lat: 48.477164, lng: 18.839077 },
    { lat: 48.477146, lng: 18.838913 },
    { lat: 48.47712, lng: 18.838978 },
    { lat: 48.477057, lng: 18.839263 },
    { lat: 48.477001, lng: 18.839611 },
    { lat: 48.476962, lng: 18.839773 },
    { lat: 48.476846, lng: 18.839965 },
    { lat: 48.476652, lng: 18.840147 },
    { lat: 48.47658, lng: 18.840265 },
    { lat: 48.47644, lng: 18.840618 },
    { lat: 48.476403, lng: 18.840752 },
    { lat: 48.476405, lng: 18.840938 },
    { lat: 48.476412, lng: 18.841202 },
    { lat: 48.476397, lng: 18.841398 },
    { lat: 48.476332, lng: 18.8417 },
    { lat: 48.476178, lng: 18.842193 },
    { lat: 48.476114, lng: 18.842351 },
    { lat: 48.475909, lng: 18.842718 },
    { lat: 48.475482, lng: 18.843324 },
    { lat: 48.475216, lng: 18.843911 },
    { lat: 48.475159, lng: 18.844115 },
    { lat: 48.475092, lng: 18.844434 },
    { lat: 48.475055, lng: 18.844693 },
    { lat: 48.47506, lng: 18.844986 },
    { lat: 48.475138, lng: 18.845442 },
    { lat: 48.475288, lng: 18.846178 },
    { lat: 48.475374, lng: 18.846805 },
    { lat: 48.475359, lng: 18.847202 },
    { lat: 48.4753, lng: 18.847462 },
    { lat: 48.475209, lng: 18.847672 },
    { lat: 48.475091, lng: 18.847836 },
    { lat: 48.474942, lng: 18.848024 },
    { lat: 48.474886, lng: 18.848163 },
    { lat: 48.474838, lng: 18.848484 },
    { lat: 48.474813, lng: 18.84885 },
    { lat: 48.474725, lng: 18.849128 },
    { lat: 48.474553, lng: 18.84955 },
    { lat: 48.474494, lng: 18.849774 },
    { lat: 48.474451, lng: 18.850112 },
    { lat: 48.474433, lng: 18.850568 },
    { lat: 48.474393, lng: 18.850564 },
    { lat: 48.474304, lng: 18.850062 },
    { lat: 48.474348, lng: 18.849772 },
    { lat: 48.474378, lng: 18.849496 },
    { lat: 48.474139, lng: 18.849576 },
    { lat: 48.473775, lng: 18.849687 },
    { lat: 48.473613, lng: 18.849763 },
    { lat: 48.472829, lng: 18.849622 },
    { lat: 48.472372, lng: 18.849569 },
    { lat: 48.471992, lng: 18.849166 },
    { lat: 48.471923, lng: 18.849388 },
    { lat: 48.471852, lng: 18.849471 },
    { lat: 48.472005, lng: 18.850049 },
    { lat: 48.472044, lng: 18.850371 },
    { lat: 48.472181, lng: 18.8506 },
    { lat: 48.472365, lng: 18.851122 },
    { lat: 48.472407, lng: 18.851257 },
    { lat: 48.472664, lng: 18.851191 },
    { lat: 48.472794, lng: 18.851353 },
    { lat: 48.4729, lng: 18.851561 },
    { lat: 48.473073, lng: 18.851549 },
    { lat: 48.473103, lng: 18.851726 },
    { lat: 48.473225, lng: 18.851894 },
    { lat: 48.467434, lng: 18.851917 },
    { lat: 48.46741, lng: 18.851913 },
    { lat: 48.467364, lng: 18.851908 },
    { lat: 48.467345, lng: 18.851905 },
    { lat: 48.467323, lng: 18.852038 },
    { lat: 48.467254, lng: 18.852306 },
    { lat: 48.467123, lng: 18.852618 },
    { lat: 48.467121, lng: 18.852793 },
    { lat: 48.467155, lng: 18.853023 },
    { lat: 48.467225, lng: 18.853254 },
    { lat: 48.467345, lng: 18.853425 },
    { lat: 48.467404, lng: 18.853516 },
    { lat: 48.467439, lng: 18.853597 },
    { lat: 48.467446, lng: 18.853674 },
    { lat: 48.467403, lng: 18.853939 },
    { lat: 48.467462, lng: 18.854299 },
    { lat: 48.467465, lng: 18.854579 },
    { lat: 48.467498, lng: 18.85473 },
    { lat: 48.467561, lng: 18.854824 },
    { lat: 48.467922, lng: 18.855109 },
    { lat: 48.468266, lng: 18.855343 },
    { lat: 48.468725, lng: 18.855699 },
    { lat: 48.469006, lng: 18.855782 },
    { lat: 48.469316, lng: 18.855904 },
    { lat: 48.469423, lng: 18.855903 },
    { lat: 48.469532, lng: 18.855781 },
    { lat: 48.469877, lng: 18.855277 },
    { lat: 48.469953, lng: 18.8553 },
    { lat: 48.469985, lng: 18.855365 },
    { lat: 48.47, lng: 18.855533 },
    { lat: 48.469979, lng: 18.856206 },
    { lat: 48.469992, lng: 18.85642 },
    { lat: 48.470093, lng: 18.856693 },
    { lat: 48.470125, lng: 18.856875 },
    { lat: 48.47012, lng: 18.8573 },
    { lat: 48.470064, lng: 18.857447 },
    { lat: 48.469923, lng: 18.857597 },
    { lat: 48.469825, lng: 18.857729 },
    { lat: 48.46976, lng: 18.857935 },
    { lat: 48.469754, lng: 18.858127 },
    { lat: 48.46979, lng: 18.858265 },
    { lat: 48.469882, lng: 18.858356 },
    { lat: 48.470142, lng: 18.858498 },
    { lat: 48.470277, lng: 18.8586153 },
    { lat: 48.470325, lng: 18.858657 },
    { lat: 48.47044, lng: 18.85882 },
    { lat: 48.470494, lng: 18.859019 },
    { lat: 48.470503, lng: 18.85915 },
    { lat: 48.470475, lng: 18.859251 },
    { lat: 48.470417, lng: 18.859319 },
    { lat: 48.470246, lng: 18.85931 },
    { lat: 48.469995, lng: 18.859298 },
    { lat: 48.469785, lng: 18.859348 },
    { lat: 48.469581, lng: 18.859454 },
    { lat: 48.469386, lng: 18.859511 },
    { lat: 48.469239, lng: 18.859563 },
    { lat: 48.4692, lng: 18.859638 },
    { lat: 48.469178, lng: 18.859821 },
    { lat: 48.469275, lng: 18.860298 },
    { lat: 48.469406, lng: 18.860806 },
    { lat: 48.469472, lng: 18.860901 },
    { lat: 48.469605, lng: 18.860922 },
    { lat: 48.469859, lng: 18.860892 },
    { lat: 48.469993, lng: 18.860901 },
    { lat: 48.470038, lng: 18.86096 },
    { lat: 48.47005, lng: 18.861028 },
    { lat: 48.470034, lng: 18.861131 },
    { lat: 48.469971, lng: 18.861216 },
    { lat: 48.469761, lng: 18.861421 },
    { lat: 48.469592, lng: 18.861719 },
    { lat: 48.469513, lng: 18.861828 },
    { lat: 48.46942, lng: 18.861889 },
    { lat: 48.469265, lng: 18.86191 },
    { lat: 48.469092, lng: 18.861928 },
    { lat: 48.469035, lng: 18.862187 },
    { lat: 48.469089, lng: 18.862372 },
    { lat: 48.469234, lng: 18.862848 },
    { lat: 48.469284, lng: 18.8631 },
    { lat: 48.469322, lng: 18.863438 },
    { lat: 48.469354, lng: 18.863867 },
    { lat: 48.469432, lng: 18.864217 },
    { lat: 48.469463, lng: 18.86437 },
    { lat: 48.469457, lng: 18.864533 },
    { lat: 48.469417, lng: 18.864731 },
    { lat: 48.469395, lng: 18.86491 },
    { lat: 48.469456, lng: 18.865094 },
    { lat: 48.469601, lng: 18.86523 },
    { lat: 48.469719, lng: 18.865323 },
    { lat: 48.469766, lng: 18.865418 },
    { lat: 48.469782, lng: 18.865505 },
    { lat: 48.469777, lng: 18.865625 },
    { lat: 48.469746, lng: 18.865915 },
    { lat: 48.469782, lng: 18.866179 },
    { lat: 48.469852, lng: 18.866409 },
    { lat: 48.469939, lng: 18.866564 },
    { lat: 48.470116, lng: 18.866665 },
    { lat: 48.4703, lng: 18.866694 },
    { lat: 48.470446, lng: 18.866672 },
    { lat: 48.470738, lng: 18.866563 },
    { lat: 48.47086, lng: 18.866543 },
    { lat: 48.470917, lng: 18.866572 },
    { lat: 48.470964, lng: 18.866648 },
    { lat: 48.47097, lng: 18.866688 },
    { lat: 48.470971, lng: 18.866762 },
    { lat: 48.470915, lng: 18.867037 },
    { lat: 48.470896, lng: 18.867239 },
    { lat: 48.47088, lng: 18.867948 },
    { lat: 48.47084, lng: 18.868181 },
    { lat: 48.470723, lng: 18.868447 },
    { lat: 48.470705, lng: 18.868487 },
    { lat: 48.470605, lng: 18.868744 },
    { lat: 48.470533, lng: 18.86899 },
    { lat: 48.470481, lng: 18.869324 },
    { lat: 48.470462, lng: 18.869519 },
    { lat: 48.470474, lng: 18.869742 },
    { lat: 48.470519, lng: 18.870029 },
    { lat: 48.470525, lng: 18.87027 },
    { lat: 48.470508, lng: 18.870443 },
    { lat: 48.470443, lng: 18.870716 },
    { lat: 48.470375, lng: 18.87087 },
    { lat: 48.470232, lng: 18.871016 },
    { lat: 48.46995, lng: 18.87122 },
    { lat: 48.46974, lng: 18.871394 },
    { lat: 48.469593, lng: 18.871568 },
    { lat: 48.469517, lng: 18.871746 },
    { lat: 48.469447, lng: 18.871983 },
    { lat: 48.469378, lng: 18.872176 },
    { lat: 48.469328, lng: 18.872533 },
    { lat: 48.469338, lng: 18.872829 },
    { lat: 48.46969, lng: 18.873696 },
    { lat: 48.469918, lng: 18.874192 },
    { lat: 48.469991, lng: 18.874595 },
    { lat: 48.470007, lng: 18.874984 },
    { lat: 48.469909, lng: 18.875602 },
    { lat: 48.469784, lng: 18.876198 },
    { lat: 48.468949, lng: 18.875868 },
    { lat: 48.469302, lng: 18.875235 },
    { lat: 48.469156, lng: 18.875048 },
    { lat: 48.468347, lng: 18.875352 },
    { lat: 48.467507, lng: 18.87601 },
    { lat: 48.46738, lng: 18.87581 },
    { lat: 48.467355, lng: 18.875765 },
    { lat: 48.467307, lng: 18.87566 },
    { lat: 48.467404, lng: 18.875517 },
    { lat: 48.467295, lng: 18.875345 },
    { lat: 48.467213, lng: 18.875394 },
    { lat: 48.467018, lng: 18.875079 },
    { lat: 48.467102, lng: 18.874676 },
    { lat: 48.466955, lng: 18.874589 },
    { lat: 48.466382, lng: 18.873621 },
    { lat: 48.466465, lng: 18.873393 },
    { lat: 48.466468, lng: 18.873183 },
    { lat: 48.466614, lng: 18.873092 },
    { lat: 48.466656, lng: 18.872803 },
    { lat: 48.466843, lng: 18.872405 },
    { lat: 48.466769, lng: 18.872305 },
    { lat: 48.466815, lng: 18.872183 },
    { lat: 48.466765, lng: 18.872135 },
    { lat: 48.466825, lng: 18.871948 },
    { lat: 48.466798, lng: 18.871668 },
    { lat: 48.466637, lng: 18.871456 },
    { lat: 48.466436, lng: 18.871277 },
    { lat: 48.466375, lng: 18.871275 },
    { lat: 48.466539, lng: 18.871067 },
    { lat: 48.466388, lng: 18.870852 },
    { lat: 48.466046, lng: 18.871532 },
    { lat: 48.465616, lng: 18.871613 },
    { lat: 48.465476, lng: 18.872096 },
    { lat: 48.46475, lng: 18.872005 },
    { lat: 48.464462, lng: 18.87231 },
    { lat: 48.46355, lng: 18.872043 },
    { lat: 48.463511, lng: 18.871872 },
    { lat: 48.463324, lng: 18.871903 },
    { lat: 48.4623, lng: 18.871278 },
    { lat: 48.461549, lng: 18.872448 },
    { lat: 48.461548, lng: 18.872514 },
    { lat: 48.461333, lng: 18.872501 },
    { lat: 48.460926, lng: 18.872199 },
    { lat: 48.460647, lng: 18.872199 },
    { lat: 48.460491, lng: 18.872458 },
    { lat: 48.460517, lng: 18.87299 },
    { lat: 48.460047, lng: 18.873401 },
    { lat: 48.459754, lng: 18.873187 },
    { lat: 48.459464, lng: 18.873403 },
    { lat: 48.459396, lng: 18.873749 },
    { lat: 48.459293, lng: 18.8741 },
    { lat: 48.459228, lng: 18.874456 },
    { lat: 48.459131, lng: 18.874597 },
    { lat: 48.458497, lng: 18.874721 },
    { lat: 48.458146, lng: 18.874743 },
    { lat: 48.457506, lng: 18.874384 },
    { lat: 48.45696, lng: 18.873874 },
    { lat: 48.456745, lng: 18.873059 },
    { lat: 48.456565, lng: 18.872723 },
    { lat: 48.45645, lng: 18.872288 },
    { lat: 48.456233, lng: 18.8740446 },
    { lat: 48.4560589, lng: 18.8749474 },
    { lat: 48.4560278, lng: 18.8758697 },
    { lat: 48.4557065, lng: 18.8768278 },
    { lat: 48.4553016, lng: 18.8777179 },
    { lat: 48.4549197, lng: 18.8783314 },
    { lat: 48.4547071, lng: 18.87806 },
    { lat: 48.4543364, lng: 18.8781336 },
    { lat: 48.4542073, lng: 18.8783814 },
    { lat: 48.4537765, lng: 18.8792063 },
    { lat: 48.4532132, lng: 18.8795213 },
    { lat: 48.4532971, lng: 18.8792706 },
    { lat: 48.4533006, lng: 18.8790092 },
    { lat: 48.4531967, lng: 18.8787474 },
    { lat: 48.4530618, lng: 18.8786233 },
    { lat: 48.4528496, lng: 18.8786183 },
    { lat: 48.4526536, lng: 18.8787123 },
    { lat: 48.4522014, lng: 18.8789284 },
    { lat: 48.4519542, lng: 18.8790088 },
    { lat: 48.4518181, lng: 18.8789733 },
    { lat: 48.4515415, lng: 18.8788558 },
    { lat: 48.4509625, lng: 18.878648 },
    { lat: 48.4505652, lng: 18.8786199 },
    { lat: 48.4502301, lng: 18.8785967 },
    { lat: 48.4502229, lng: 18.8786757 },
    { lat: 48.4502046, lng: 18.878692 },
    { lat: 48.4497736, lng: 18.8790785 },
    { lat: 48.4492196, lng: 18.8795739 },
    { lat: 48.4496222, lng: 18.8797799 },
    { lat: 48.4501442, lng: 18.8800471 },
    { lat: 48.4503224, lng: 18.8804397 },
    { lat: 48.4503076, lng: 18.8807653 },
    { lat: 48.4504188, lng: 18.881061 },
    { lat: 48.4505692, lng: 18.8813538 },
    { lat: 48.4506343, lng: 18.8816524 },
    { lat: 48.4506756, lng: 18.8819487 },
    { lat: 48.450608, lng: 18.8822124 },
    { lat: 48.4504526, lng: 18.8840197 },
    { lat: 48.4504167, lng: 18.884459 },
    { lat: 48.4503555, lng: 18.8849777 },
    { lat: 48.4503168, lng: 18.8853406 },
    { lat: 48.4503363, lng: 18.8862185 },
    { lat: 48.4502875, lng: 18.8868003 },
    { lat: 48.4502392, lng: 18.8871317 },
    { lat: 48.4501004, lng: 18.8874115 },
    { lat: 48.4498332, lng: 18.8877152 },
    { lat: 48.4495549, lng: 18.8877651 },
    { lat: 48.4491653, lng: 18.8876892 },
    { lat: 48.448849, lng: 18.8877 },
    { lat: 48.4478533, lng: 18.8878495 },
    { lat: 48.4475451, lng: 18.887844 },
    { lat: 48.4472867, lng: 18.8878415 },
    { lat: 48.4467412, lng: 18.8878421 },
    { lat: 48.4464572, lng: 18.8878476 },
    { lat: 48.446246, lng: 18.8879934 },
    { lat: 48.4452597, lng: 18.8869521 },
    { lat: 48.4450873, lng: 18.8865219 },
    { lat: 48.4448446, lng: 18.8858631 },
    { lat: 48.4445681, lng: 18.88527 },
    { lat: 48.4444448, lng: 18.8849364 },
    { lat: 48.4442135, lng: 18.8840159 },
    { lat: 48.4440685, lng: 18.8834621 },
    { lat: 48.4439435, lng: 18.8828783 },
    { lat: 48.4438234, lng: 18.8825838 },
    { lat: 48.4435222, lng: 18.8825348 },
    { lat: 48.4432974, lng: 18.8827664 },
    { lat: 48.442766, lng: 18.8832399 },
    { lat: 48.4424807, lng: 18.8833713 },
    { lat: 48.4421947, lng: 18.8834463 },
    { lat: 48.4418873, lng: 18.8834333 },
    { lat: 48.4417647, lng: 18.8833695 },
    { lat: 48.4416589, lng: 18.8832019 },
    { lat: 48.4416082, lng: 18.8828303 },
    { lat: 48.4416242, lng: 18.8820947 },
    { lat: 48.4416252, lng: 18.8820278 },
    { lat: 48.441628, lng: 18.8819085 },
    { lat: 48.4415436, lng: 18.8815846 },
    { lat: 48.4413132, lng: 18.8811872 },
    { lat: 48.4411456, lng: 18.8810204 },
    { lat: 48.4409262, lng: 18.8808543 },
    { lat: 48.4407458, lng: 18.8807713 },
    { lat: 48.4404697, lng: 18.8806865 },
    { lat: 48.4402725, lng: 18.8806251 },
    { lat: 48.4401025, lng: 18.8805406 },
    { lat: 48.4399776, lng: 18.8803918 },
    { lat: 48.4399047, lng: 18.8802065 },
    { lat: 48.4399091, lng: 18.8800721 },
    { lat: 48.4399096, lng: 18.8800372 },
    { lat: 48.439915, lng: 18.8798185 },
    { lat: 48.4398888, lng: 18.8798134 },
    { lat: 48.4398173, lng: 18.8798026 },
    { lat: 48.4397852, lng: 18.8797932 },
    { lat: 48.4397201, lng: 18.8797778 },
    { lat: 48.4395571, lng: 18.8797639 },
    { lat: 48.439361, lng: 18.879747 },
    { lat: 48.4392454, lng: 18.8796274 },
    { lat: 48.4391741, lng: 18.8795779 },
    { lat: 48.4390104, lng: 18.8794657 },
    { lat: 48.4388074, lng: 18.8795274 },
    { lat: 48.4386753, lng: 18.8792222 },
    { lat: 48.4386561, lng: 18.8791765 },
    { lat: 48.4386238, lng: 18.8790997 },
    { lat: 48.4384906, lng: 18.8787904 },
    { lat: 48.4382118, lng: 18.8785002 },
    { lat: 48.4383587, lng: 18.8783122 },
    { lat: 48.438384, lng: 18.8782578 },
    { lat: 48.4384217, lng: 18.8781134 },
    { lat: 48.4384169, lng: 18.878021 },
    { lat: 48.4384161, lng: 18.8779202 },
    { lat: 48.4383795, lng: 18.8777785 },
    { lat: 48.4383417, lng: 18.8776377 },
    { lat: 48.4383259, lng: 18.8775638 },
    { lat: 48.438309, lng: 18.877485 },
    { lat: 48.4382544, lng: 18.8771707 },
    { lat: 48.4382255, lng: 18.8770469 },
    { lat: 48.4380369, lng: 18.8764767 },
    { lat: 48.4378794, lng: 18.8760037 },
    { lat: 48.4378017, lng: 18.8757678 },
    { lat: 48.4377998, lng: 18.8757542 },
    { lat: 48.4377247, lng: 18.8751774 },
    { lat: 48.4377154, lng: 18.8751074 },
    { lat: 48.4377083, lng: 18.8750578 },
    { lat: 48.4377297, lng: 18.8750323 },
    { lat: 48.4377634, lng: 18.8749903 },
    { lat: 48.4379755, lng: 18.8747289 },
    { lat: 48.4383644, lng: 18.8745314 },
    { lat: 48.4387074, lng: 18.8742305 },
    { lat: 48.4390329, lng: 18.8738767 },
    { lat: 48.439066, lng: 18.8738408 },
    { lat: 48.4391065, lng: 18.873797 },
    { lat: 48.4390955, lng: 18.8737812 },
    { lat: 48.4390785, lng: 18.8737534 },
    { lat: 48.4390514, lng: 18.8737108 },
    { lat: 48.439001, lng: 18.8736315 },
    { lat: 48.4389824, lng: 18.8736003 },
    { lat: 48.4389693, lng: 18.8736075 },
    { lat: 48.4389003, lng: 18.8736496 },
    { lat: 48.4388852, lng: 18.8736589 },
    { lat: 48.4388436, lng: 18.8736844 },
    { lat: 48.4386468, lng: 18.873804 },
    { lat: 48.438376, lng: 18.8739086 },
    { lat: 48.4383685, lng: 18.8739137 },
    { lat: 48.4376588, lng: 18.8743854 },
    { lat: 48.4370916, lng: 18.8747967 },
    { lat: 48.4365944, lng: 18.8751881 },
    { lat: 48.4365045, lng: 18.8752593 },
    { lat: 48.4364852, lng: 18.8752605 },
    { lat: 48.436464, lng: 18.8752619 },
    { lat: 48.4364584, lng: 18.8752622 },
    { lat: 48.4362228, lng: 18.8753307 },
    { lat: 48.4362107, lng: 18.8753342 },
    { lat: 48.4361926, lng: 18.8753371 },
    { lat: 48.4361559, lng: 18.8753431 },
    { lat: 48.4360216, lng: 18.8753649 },
    { lat: 48.4359626, lng: 18.8753711 },
    { lat: 48.4358578, lng: 18.875382 },
    { lat: 48.4357168, lng: 18.8753793 },
    { lat: 48.4356246, lng: 18.8753816 },
    { lat: 48.435557, lng: 18.8753863 },
    { lat: 48.435521, lng: 18.8753852 },
    { lat: 48.435487, lng: 18.8754034 },
    { lat: 48.4355211, lng: 18.8749538 },
    { lat: 48.4355553, lng: 18.8745936 },
    { lat: 48.4353737, lng: 18.8741494 },
    { lat: 48.4352906, lng: 18.873946 },
    { lat: 48.4351782, lng: 18.8732142 },
    { lat: 48.4346726, lng: 18.8729252 },
    { lat: 48.4344487, lng: 18.872411 },
    { lat: 48.4342593, lng: 18.871978 },
    { lat: 48.4342336, lng: 18.8719195 },
    { lat: 48.4342001, lng: 18.8718372 },
    { lat: 48.4340711, lng: 18.8715235 },
    { lat: 48.4340233, lng: 18.8714087 },
    { lat: 48.4335312, lng: 18.8710507 },
    { lat: 48.4327225, lng: 18.870549 },
    { lat: 48.4326784, lng: 18.8704995 },
    { lat: 48.4325049, lng: 18.8703071 },
    { lat: 48.4323122, lng: 18.8702466 },
    { lat: 48.4322824, lng: 18.8702136 },
    { lat: 48.4320507, lng: 18.869989 },
    { lat: 48.4314973, lng: 18.869451 },
    { lat: 48.4309792, lng: 18.8689401 },
    { lat: 48.43068, lng: 18.868824 },
    { lat: 48.4293792, lng: 18.868292 },
    { lat: 48.4292209, lng: 18.8682385 },
    { lat: 48.4291887, lng: 18.868227 },
    { lat: 48.429168, lng: 18.8682202 },
    { lat: 48.4289845, lng: 18.8681583 },
    { lat: 48.4283793, lng: 18.8683159 },
    { lat: 48.4278414, lng: 18.8682732 },
    { lat: 48.4270513, lng: 18.8680879 },
    { lat: 48.4263435, lng: 18.8678983 },
    { lat: 48.4261949, lng: 18.8676001 },
    { lat: 48.4260458, lng: 18.8677671 },
    { lat: 48.4252221, lng: 18.8669765 },
    { lat: 48.4246016, lng: 18.8663805 },
    { lat: 48.424268, lng: 18.86606 },
    { lat: 48.423969, lng: 18.8657539 },
    { lat: 48.4234378, lng: 18.8650348 },
    { lat: 48.4231821, lng: 18.8649971 },
    { lat: 48.4220919, lng: 18.8633532 },
    { lat: 48.4217211, lng: 18.8627932 },
    { lat: 48.4212026, lng: 18.8622105 },
    { lat: 48.4211721, lng: 18.8621881 },
    { lat: 48.4211485, lng: 18.8621707 },
    { lat: 48.4211227, lng: 18.8621517 },
    { lat: 48.4198032, lng: 18.8611746 },
    { lat: 48.4192286, lng: 18.8608122 },
    { lat: 48.4188822, lng: 18.8605717 },
    { lat: 48.4185824, lng: 18.8602505 },
    { lat: 48.4183957, lng: 18.8601716 },
    { lat: 48.4183541, lng: 18.8601542 },
    { lat: 48.4183162, lng: 18.860138 },
    { lat: 48.4179691, lng: 18.8599474 },
    { lat: 48.4178546, lng: 18.8598724 },
    { lat: 48.4178648, lng: 18.8598286 },
    { lat: 48.4178725, lng: 18.8597816 },
    { lat: 48.4176367, lng: 18.8597134 },
    { lat: 48.4174725, lng: 18.8596589 },
    { lat: 48.4168125, lng: 18.8592597 },
    { lat: 48.4157719, lng: 18.8586152 },
    { lat: 48.4155874, lng: 18.858448 },
    { lat: 48.4155312, lng: 18.8581714 },
    { lat: 48.4153054, lng: 18.8576481 },
    { lat: 48.4151647, lng: 18.857501 },
    { lat: 48.4150372, lng: 18.857457 },
    { lat: 48.4147672, lng: 18.857531 },
    { lat: 48.4143744, lng: 18.8576336 },
    { lat: 48.4140382, lng: 18.8576944 },
    { lat: 48.4135465, lng: 18.85793 },
    { lat: 48.4133987, lng: 18.8581458 },
    { lat: 48.4133034, lng: 18.8583526 },
    { lat: 48.413181, lng: 18.8586448 },
    { lat: 48.4131033, lng: 18.8587754 },
    { lat: 48.4130057, lng: 18.8588713 },
    { lat: 48.4124451, lng: 18.8589329 },
    { lat: 48.4123283, lng: 18.8589228 },
    { lat: 48.412348, lng: 18.858846 },
    { lat: 48.4123616, lng: 18.858827 },
    { lat: 48.4124313, lng: 18.8586914 },
    { lat: 48.4124474, lng: 18.858654 },
    { lat: 48.4124348, lng: 18.8580226 },
    { lat: 48.4124347, lng: 18.8579978 },
    { lat: 48.4124681, lng: 18.8579037 },
    { lat: 48.4124916, lng: 18.8578346 },
    { lat: 48.412625, lng: 18.8574505 },
    { lat: 48.4125898, lng: 18.8569921 },
    { lat: 48.4125885, lng: 18.8569681 },
    { lat: 48.4125481, lng: 18.8568178 },
    { lat: 48.4123554, lng: 18.8564344 },
    { lat: 48.4123295, lng: 18.8563913 },
    { lat: 48.4123104, lng: 18.8564148 },
    { lat: 48.4115807, lng: 18.8554571 },
    { lat: 48.4113967, lng: 18.8552171 },
    { lat: 48.4112163, lng: 18.8544362 },
    { lat: 48.4108162, lng: 18.8539853 },
    { lat: 48.4107933, lng: 18.8539596 },
    { lat: 48.4106856, lng: 18.8538381 },
    { lat: 48.4101652, lng: 18.8524856 },
    { lat: 48.4101436, lng: 18.8524601 },
    { lat: 48.4096692, lng: 18.8518859 },
    { lat: 48.4092274, lng: 18.8516301 },
    { lat: 48.4082242, lng: 18.851637 },
    { lat: 48.4077648, lng: 18.8512194 },
    { lat: 48.4077329, lng: 18.8511908 },
    { lat: 48.4076584, lng: 18.8511222 },
    { lat: 48.4070038, lng: 18.8505281 },
    { lat: 48.4060645, lng: 18.8503362 },
    { lat: 48.4056929, lng: 18.8500843 },
    { lat: 48.4053285, lng: 18.8501885 },
    { lat: 48.4047837, lng: 18.850253 },
    { lat: 48.4047747, lng: 18.850276 },
    { lat: 48.4047569, lng: 18.8503197 },
    { lat: 48.404701, lng: 18.8504625 },
    { lat: 48.4044911, lng: 18.8509993 },
    { lat: 48.4044542, lng: 18.8509401 },
    { lat: 48.4041146, lng: 18.8504598 },
    { lat: 48.4040997, lng: 18.8505229 },
    { lat: 48.4040196, lng: 18.8506498 },
    { lat: 48.4037429, lng: 18.8507722 },
    { lat: 48.4033709, lng: 18.8506445 },
    { lat: 48.4030461, lng: 18.8512714 },
    { lat: 48.4024646, lng: 18.85115 },
    { lat: 48.4025955, lng: 18.8509767 },
    { lat: 48.4025172, lng: 18.8509028 },
    { lat: 48.4024828, lng: 18.8508448 },
    { lat: 48.4021027, lng: 18.8504363 },
    { lat: 48.401948, lng: 18.8503867 },
    { lat: 48.4018493, lng: 18.8503557 },
    { lat: 48.4017324, lng: 18.8503177 },
    { lat: 48.4015087, lng: 18.8502461 },
    { lat: 48.4014765, lng: 18.850236 },
    { lat: 48.4013515, lng: 18.8501957 },
    { lat: 48.4013355, lng: 18.8501908 },
    { lat: 48.4005076, lng: 18.8499259 },
    { lat: 48.4003184, lng: 18.8498648 },
    { lat: 48.4002838, lng: 18.8498539 },
    { lat: 48.4001324, lng: 18.8498054 },
    { lat: 48.3994456, lng: 18.8495849 },
    { lat: 48.399255, lng: 18.8502978 },
    { lat: 48.3992378, lng: 18.8503346 },
    { lat: 48.3990275, lng: 18.8511056 },
    { lat: 48.3989501, lng: 18.8513933 },
    { lat: 48.3989257, lng: 18.8514877 },
    { lat: 48.3988348, lng: 18.8518185 },
    { lat: 48.3983928, lng: 18.8534441 },
    { lat: 48.398342, lng: 18.8535928 },
    { lat: 48.3983205, lng: 18.853634 },
    { lat: 48.3981985, lng: 18.8538467 },
    { lat: 48.3982044, lng: 18.8538726 },
    { lat: 48.3978649, lng: 18.8552577 },
    { lat: 48.3978187, lng: 18.8554955 },
    { lat: 48.3973666, lng: 18.8570137 },
    { lat: 48.3966786, lng: 18.8565105 },
    { lat: 48.397406, lng: 18.8579633 },
    { lat: 48.3974564, lng: 18.8583878 },
    { lat: 48.3975038, lng: 18.8587807 },
    { lat: 48.3975312, lng: 18.8590031 },
    { lat: 48.3977959, lng: 18.8599929 },
    { lat: 48.3978799, lng: 18.8603077 },
    { lat: 48.3983464, lng: 18.8610357 },
    { lat: 48.3981614, lng: 18.8614485 },
    { lat: 48.3982572, lng: 18.8620727 },
    { lat: 48.3983336, lng: 18.8622178 },
    { lat: 48.3983724, lng: 18.862292 },
    { lat: 48.3993087, lng: 18.8612584 },
    { lat: 48.3993556, lng: 18.8612064 },
    { lat: 48.3998396, lng: 18.8608217 },
    { lat: 48.4007682, lng: 18.859773 },
    { lat: 48.4009805, lng: 18.8595402 },
    { lat: 48.401316, lng: 18.8591626 },
    { lat: 48.401991, lng: 18.8586106 },
    { lat: 48.4020219, lng: 18.8587099 },
    { lat: 48.4021653, lng: 18.8591686 },
    { lat: 48.4023374, lng: 18.8597229 },
    { lat: 48.4024767, lng: 18.8596249 },
    { lat: 48.4025083, lng: 18.8596017 },
    { lat: 48.403189, lng: 18.8591193 },
    { lat: 48.4035142, lng: 18.8588895 },
    { lat: 48.4035601, lng: 18.8589895 },
    { lat: 48.4035892, lng: 18.8590542 },
    { lat: 48.4040941, lng: 18.8601504 },
    { lat: 48.404122, lng: 18.8602114 },
    { lat: 48.4040499, lng: 18.8604048 },
    { lat: 48.4044289, lng: 18.8601732 },
    { lat: 48.4045384, lng: 18.8601068 },
    { lat: 48.4046969, lng: 18.8600097 },
    { lat: 48.4047619, lng: 18.8600248 },
    { lat: 48.4048863, lng: 18.8600542 },
    { lat: 48.4049217, lng: 18.8600628 },
    { lat: 48.4054288, lng: 18.8602077 },
    { lat: 48.4056499, lng: 18.8601427 },
    { lat: 48.4056694, lng: 18.8602178 },
    { lat: 48.4062288, lng: 18.8622758 },
    { lat: 48.4064024, lng: 18.8630323 },
    { lat: 48.4072442, lng: 18.8656236 },
    { lat: 48.4074848, lng: 18.8656529 },
    { lat: 48.4076924, lng: 18.8656782 },
    { lat: 48.4067465, lng: 18.86644 },
    { lat: 48.4062839, lng: 18.8666959 },
    { lat: 48.4055149, lng: 18.8669756 },
    { lat: 48.4054671, lng: 18.8669929 },
    { lat: 48.4054407, lng: 18.8670024 },
    { lat: 48.4053709, lng: 18.867028 },
    { lat: 48.4052611, lng: 18.8670611 },
    { lat: 48.4046216, lng: 18.8672564 },
    { lat: 48.4047792, lng: 18.867706 },
    { lat: 48.4053878, lng: 18.8680027 },
    { lat: 48.4059436, lng: 18.8681721 },
    { lat: 48.4060481, lng: 18.8699463 },
    { lat: 48.4063241, lng: 18.8703111 },
    { lat: 48.4066091, lng: 18.8704796 },
    { lat: 48.4068602, lng: 18.8705364 },
    { lat: 48.4069976, lng: 18.8704255 },
    { lat: 48.4074153, lng: 18.8702385 },
    { lat: 48.4076792, lng: 18.8705415 },
    { lat: 48.4075539, lng: 18.8715815 },
    { lat: 48.4079016, lng: 18.8716114 },
    { lat: 48.408193, lng: 18.8717313 },
    { lat: 48.4086894, lng: 18.8713888 },
    { lat: 48.4091036, lng: 18.8715021 },
    { lat: 48.4092214, lng: 18.8714446 },
    { lat: 48.4095246, lng: 18.8711646 },
    { lat: 48.4098402, lng: 18.8709661 },
    { lat: 48.4099757, lng: 18.8708817 },
    { lat: 48.4101117, lng: 18.8707845 },
    { lat: 48.4102441, lng: 18.8707956 },
    { lat: 48.410461, lng: 18.8710857 },
    { lat: 48.4109876, lng: 18.8713902 },
    { lat: 48.4116241, lng: 18.8710285 },
    { lat: 48.4115392, lng: 18.8706327 },
    { lat: 48.4115584, lng: 18.8705474 },
    { lat: 48.4117673, lng: 18.8703551 },
    { lat: 48.4122267, lng: 18.8703016 },
    { lat: 48.4122768, lng: 18.8701803 },
    { lat: 48.4128553, lng: 18.8698527 },
    { lat: 48.4132708, lng: 18.8699513 },
  ],
  Kozelník: [
    { lat: 48.532515, lng: 18.987608 },
    { lat: 48.5324144, lng: 18.9875709 },
    { lat: 48.5315882, lng: 18.9871303 },
    { lat: 48.5306275, lng: 18.9866184 },
    { lat: 48.53021, lng: 18.9863482 },
    { lat: 48.5300825, lng: 18.9860804 },
    { lat: 48.5300598, lng: 18.9858529 },
    { lat: 48.5297477, lng: 18.9856345 },
    { lat: 48.52966, lng: 18.9854425 },
    { lat: 48.5294263, lng: 18.9850131 },
    { lat: 48.5290266, lng: 18.9849588 },
    { lat: 48.5288028, lng: 18.9849252 },
    { lat: 48.5276426, lng: 18.9849924 },
    { lat: 48.5273194, lng: 18.9851086 },
    { lat: 48.5270847, lng: 18.985237 },
    { lat: 48.5261903, lng: 18.9857648 },
    { lat: 48.5255578, lng: 18.9860991 },
    { lat: 48.5254366, lng: 18.9860433 },
    { lat: 48.5251847, lng: 18.9859219 },
    { lat: 48.5248267, lng: 18.9860453 },
    { lat: 48.524758, lng: 18.9860689 },
    { lat: 48.5243526, lng: 18.9862095 },
    { lat: 48.5225633, lng: 18.9845259 },
    { lat: 48.5225029, lng: 18.9845143 },
    { lat: 48.5222581, lng: 18.9844672 },
    { lat: 48.5222058, lng: 18.9844568 },
    { lat: 48.5215476, lng: 18.9843306 },
    { lat: 48.5211035, lng: 18.9842669 },
    { lat: 48.520846, lng: 18.9842134 },
    { lat: 48.520238, lng: 18.9841181 },
    { lat: 48.5194734, lng: 18.9839586 },
    { lat: 48.519127, lng: 18.9838005 },
    { lat: 48.5188366, lng: 18.9837432 },
    { lat: 48.518248, lng: 18.9836955 },
    { lat: 48.5175329, lng: 18.9834279 },
    { lat: 48.5168192, lng: 18.9831609 },
    { lat: 48.5163601, lng: 18.9830481 },
    { lat: 48.5154981, lng: 18.9828359 },
    { lat: 48.515017, lng: 18.9827986 },
    { lat: 48.5145825, lng: 18.9825001 },
    { lat: 48.514237, lng: 18.9824013 },
    { lat: 48.5136455, lng: 18.9820139 },
    { lat: 48.5134241, lng: 18.9819164 },
    { lat: 48.5125992, lng: 18.9817469 },
    { lat: 48.5123032, lng: 18.9813817 },
    { lat: 48.5117465, lng: 18.9802791 },
    { lat: 48.5117684, lng: 18.9797361 },
    { lat: 48.510269, lng: 18.9808011 },
    { lat: 48.5098911, lng: 18.9810826 },
    { lat: 48.5097617, lng: 18.9811775 },
    { lat: 48.5094507, lng: 18.9814032 },
    { lat: 48.509417, lng: 18.9814274 },
    { lat: 48.5092114, lng: 18.9815748 },
    { lat: 48.5092004, lng: 18.9815818 },
    { lat: 48.5091723, lng: 18.9816046 },
    { lat: 48.5090833, lng: 18.9816721 },
    { lat: 48.5090594, lng: 18.9816894 },
    { lat: 48.5086658, lng: 18.9819968 },
    { lat: 48.5086083, lng: 18.9820415 },
    { lat: 48.5085668, lng: 18.9820463 },
    { lat: 48.5083079, lng: 18.9820761 },
    { lat: 48.5081245, lng: 18.9822416 },
    { lat: 48.5081136, lng: 18.9822457 },
    { lat: 48.5080174, lng: 18.9822821 },
    { lat: 48.5079344, lng: 18.9823126 },
    { lat: 48.5077871, lng: 18.9823676 },
    { lat: 48.5077484, lng: 18.9823817 },
    { lat: 48.5075058, lng: 18.9825313 },
    { lat: 48.5070809, lng: 18.9828039 },
    { lat: 48.5069543, lng: 18.9828423 },
    { lat: 48.5068064, lng: 18.9829472 },
    { lat: 48.5066416, lng: 18.9831461 },
    { lat: 48.5062315, lng: 18.9832818 },
    { lat: 48.5061012, lng: 18.9833815 },
    { lat: 48.5057921, lng: 18.9834686 },
    { lat: 48.5054977, lng: 18.983973 },
    { lat: 48.5050991, lng: 18.9840296 },
    { lat: 48.5048131, lng: 18.9841478 },
    { lat: 48.5042635, lng: 18.9847359 },
    { lat: 48.5040324, lng: 18.9850232 },
    { lat: 48.5039551, lng: 18.9851199 },
    { lat: 48.5036476, lng: 18.9855037 },
    { lat: 48.5031315, lng: 18.9864637 },
    { lat: 48.502937, lng: 18.9866522 },
    { lat: 48.5025739, lng: 18.9870058 },
    { lat: 48.5022566, lng: 18.9872379 },
    { lat: 48.5021664, lng: 18.9873037 },
    { lat: 48.5017575, lng: 18.9876025 },
    { lat: 48.5015382, lng: 18.9875702 },
    { lat: 48.5009574, lng: 18.987546 },
    { lat: 48.5008948, lng: 18.9875774 },
    { lat: 48.5007992, lng: 18.9876261 },
    { lat: 48.5005795, lng: 18.9877381 },
    { lat: 48.5005431, lng: 18.9877562 },
    { lat: 48.4998389, lng: 18.9890268 },
    { lat: 48.4993268, lng: 18.9903126 },
    { lat: 48.4984269, lng: 18.9912615 },
    { lat: 48.4974395, lng: 18.9918451 },
    { lat: 48.4969551, lng: 18.9922495 },
    { lat: 48.4966329, lng: 18.9926321 },
    { lat: 48.4965518, lng: 18.9927008 },
    { lat: 48.4962822, lng: 18.9929416 },
    { lat: 48.496006, lng: 18.9931881 },
    { lat: 48.4956203, lng: 18.9932968 },
    { lat: 48.4953922, lng: 18.9932661 },
    { lat: 48.4951846, lng: 18.9937109 },
    { lat: 48.4944979, lng: 18.9944375 },
    { lat: 48.4943064, lng: 18.9945562 },
    { lat: 48.4942464, lng: 18.9946406 },
    { lat: 48.4941972, lng: 18.9945787 },
    { lat: 48.4940563, lng: 18.9943358 },
    { lat: 48.4935916, lng: 18.9938653 },
    { lat: 48.4928814, lng: 18.9942011 },
    { lat: 48.4924974, lng: 18.9948097 },
    { lat: 48.4923513, lng: 18.9951503 },
    { lat: 48.492044, lng: 18.996027 },
    { lat: 48.49206, lng: 18.996072 },
    { lat: 48.492089, lng: 18.996109 },
    { lat: 48.492761, lng: 18.997032 },
    { lat: 48.492948, lng: 18.997291 },
    { lat: 48.493635, lng: 18.998159 },
    { lat: 48.493657, lng: 18.998141 },
    { lat: 48.493682, lng: 18.998072 },
    { lat: 48.493734, lng: 18.998323 },
    { lat: 48.49389, lng: 18.999078 },
    { lat: 48.494038, lng: 18.999686 },
    { lat: 48.494171, lng: 19.000867 },
    { lat: 48.494171, lng: 19.001274 },
    { lat: 48.494226, lng: 19.001722 },
    { lat: 48.494867, lng: 19.002254 },
    { lat: 48.495375, lng: 19.002622 },
    { lat: 48.496173, lng: 19.00288 },
    { lat: 48.496665, lng: 19.002764 },
    { lat: 48.49729, lng: 19.002734 },
    { lat: 48.497916, lng: 19.002851 },
    { lat: 48.498031, lng: 19.002927 },
    { lat: 48.49865, lng: 19.003374 },
    { lat: 48.498851, lng: 19.003547 },
    { lat: 48.498906, lng: 19.003612 },
    { lat: 48.498939, lng: 19.003623 },
    { lat: 48.499348, lng: 19.003983 },
    { lat: 48.499848, lng: 19.004572 },
    { lat: 48.50038, lng: 19.004694 },
    { lat: 48.501004, lng: 19.005155 },
    { lat: 48.501535, lng: 19.00531 },
    { lat: 48.502165, lng: 19.005319 },
    { lat: 48.502703, lng: 19.005359 },
    { lat: 48.504474, lng: 19.005126 },
    { lat: 48.504636, lng: 19.005156 },
    { lat: 48.504664, lng: 19.005161 },
    { lat: 48.504693, lng: 19.005167 },
    { lat: 48.505201, lng: 19.005328 },
    { lat: 48.506165, lng: 19.005335 },
    { lat: 48.506551, lng: 19.005433 },
    { lat: 48.50675, lng: 19.005619 },
    { lat: 48.507585, lng: 19.006393 },
    { lat: 48.507871, lng: 19.006656 },
    { lat: 48.507984, lng: 19.00676 },
    { lat: 48.508276, lng: 19.007198 },
    { lat: 48.508449, lng: 19.007531 },
    { lat: 48.508471, lng: 19.007571 },
    { lat: 48.508597, lng: 19.007811 },
    { lat: 48.50872, lng: 19.008118 },
    { lat: 48.509266, lng: 19.009481 },
    { lat: 48.509572, lng: 19.009723 },
    { lat: 48.509991, lng: 19.00975 },
    { lat: 48.510726, lng: 19.010205 },
    { lat: 48.511393, lng: 19.010433 },
    { lat: 48.511922, lng: 19.010903 },
    { lat: 48.512744, lng: 19.011297 },
    { lat: 48.512805, lng: 19.011323 },
    { lat: 48.513527, lng: 19.010588 },
    { lat: 48.514477, lng: 19.010238 },
    { lat: 48.515219, lng: 19.009286 },
    { lat: 48.516128, lng: 19.008997 },
    { lat: 48.516536, lng: 19.008981 },
    { lat: 48.517149, lng: 19.009658 },
    { lat: 48.517813, lng: 19.009984 },
    { lat: 48.518069, lng: 19.010409 },
    { lat: 48.518107, lng: 19.010477 },
    { lat: 48.518245, lng: 19.010715 },
    { lat: 48.518403, lng: 19.010485 },
    { lat: 48.518479, lng: 19.010111 },
    { lat: 48.518695, lng: 19.009411 },
    { lat: 48.519176, lng: 19.008138 },
    { lat: 48.519275, lng: 19.007494 },
    { lat: 48.519302, lng: 19.007172 },
    { lat: 48.5191707, lng: 19.0067613 },
    { lat: 48.5196126, lng: 19.0068485 },
    { lat: 48.519892, lng: 19.007447 },
    { lat: 48.520209, lng: 19.008101 },
    { lat: 48.520633, lng: 19.00921 },
    { lat: 48.520689, lng: 19.0097 },
    { lat: 48.520803, lng: 19.010048 },
    { lat: 48.520784, lng: 19.011078 },
    { lat: 48.52077, lng: 19.011506 },
    { lat: 48.521041, lng: 19.012415 },
    { lat: 48.521057, lng: 19.012626 },
    { lat: 48.521769, lng: 19.013251 },
    { lat: 48.523178, lng: 19.013516 },
    { lat: 48.523243, lng: 19.013419 },
    { lat: 48.52338, lng: 19.012322 },
    { lat: 48.523637, lng: 19.011682 },
    { lat: 48.52381, lng: 19.010821 },
    { lat: 48.52403, lng: 19.010784 },
    { lat: 48.524163, lng: 19.010891 },
    { lat: 48.524294, lng: 19.010945 },
    { lat: 48.524606, lng: 19.011697 },
    { lat: 48.525316, lng: 19.012158 },
    { lat: 48.525587, lng: 19.012266 },
    { lat: 48.52611, lng: 19.012409 },
    { lat: 48.527349, lng: 19.012539 },
    { lat: 48.5278, lng: 19.012531 },
    { lat: 48.528163, lng: 19.012472 },
    { lat: 48.528266, lng: 19.012424 },
    { lat: 48.529038, lng: 19.012451 },
    { lat: 48.529392, lng: 19.012705 },
    { lat: 48.530001, lng: 19.012481 },
    { lat: 48.530545, lng: 19.012095 },
    { lat: 48.530929, lng: 19.012333 },
    { lat: 48.531652, lng: 19.013033 },
    { lat: 48.532024, lng: 19.013159 },
    { lat: 48.532148, lng: 19.01314 },
    { lat: 48.532393, lng: 19.012996 },
    { lat: 48.532735, lng: 19.01289 },
    { lat: 48.533324, lng: 19.012848 },
    { lat: 48.533728, lng: 19.013405 },
    { lat: 48.533824, lng: 19.013548 },
    { lat: 48.534015, lng: 19.01377 },
    { lat: 48.534147, lng: 19.013887 },
    { lat: 48.534462, lng: 19.014005 },
    { lat: 48.534641, lng: 19.013966 },
    { lat: 48.535119, lng: 19.013787 },
    { lat: 48.535483, lng: 19.013522 },
    { lat: 48.535657, lng: 19.013334 },
    { lat: 48.535862, lng: 19.013289 },
    { lat: 48.535932, lng: 19.013314 },
    { lat: 48.536075, lng: 19.013365 },
    { lat: 48.536275, lng: 19.013502 },
    { lat: 48.536808, lng: 19.01425 },
    { lat: 48.537187, lng: 19.014243 },
    { lat: 48.537422, lng: 19.014511 },
    { lat: 48.537775, lng: 19.014809 },
    { lat: 48.538429, lng: 19.015183 },
    { lat: 48.538639, lng: 19.015201 },
    { lat: 48.538996, lng: 19.015308 },
    { lat: 48.539161, lng: 19.015287 },
    { lat: 48.539411, lng: 19.015099 },
    { lat: 48.539677, lng: 19.014816 },
    { lat: 48.539732, lng: 19.014752 },
    { lat: 48.540789, lng: 19.014141 },
    { lat: 48.54178, lng: 19.013953 },
    { lat: 48.542554, lng: 19.014121 },
    { lat: 48.543248, lng: 19.014211 },
    { lat: 48.543421, lng: 19.013862 },
    { lat: 48.543465, lng: 19.013556 },
    { lat: 48.543529, lng: 19.01261 },
    { lat: 48.543448, lng: 19.011921 },
    { lat: 48.543035, lng: 19.011274 },
    { lat: 48.542974, lng: 19.011024 },
    { lat: 48.542835, lng: 19.010353 },
    { lat: 48.542501, lng: 19.009537 },
    { lat: 48.542215, lng: 19.008941 },
    { lat: 48.542108, lng: 19.007879 },
    { lat: 48.542016, lng: 19.006257 },
    { lat: 48.541861, lng: 19.005332 },
    { lat: 48.541861, lng: 19.005331 },
    { lat: 48.541814, lng: 19.005051 },
    { lat: 48.541808, lng: 19.005027 },
    { lat: 48.541773, lng: 19.004868 },
    { lat: 48.54176, lng: 19.004811 },
    { lat: 48.541751, lng: 19.004772 },
    { lat: 48.541709, lng: 19.004583 },
    { lat: 48.541661, lng: 19.004371 },
    { lat: 48.54162, lng: 19.00419 },
    { lat: 48.541604, lng: 19.004118 },
    { lat: 48.541594, lng: 19.004074 },
    { lat: 48.541592, lng: 19.004058 },
    { lat: 48.541586, lng: 19.004024 },
    { lat: 48.541581, lng: 19.003995 },
    { lat: 48.541576, lng: 19.00396 },
    { lat: 48.541524, lng: 19.003657 },
    { lat: 48.54146, lng: 19.003493 },
    { lat: 48.541453, lng: 19.003476 },
    { lat: 48.54144, lng: 19.003443 },
    { lat: 48.541432, lng: 19.003415 },
    { lat: 48.541382, lng: 19.003234 },
    { lat: 48.541375, lng: 19.003209 },
    { lat: 48.541373, lng: 19.003204 },
    { lat: 48.541363, lng: 19.003167 },
    { lat: 48.541149, lng: 19.002404 },
    { lat: 48.540976, lng: 19.002114 },
    { lat: 48.54091, lng: 19.001929 },
    { lat: 48.540769, lng: 19.001605 },
    { lat: 48.540602, lng: 19.000907 },
    { lat: 48.54049, lng: 19.000442 },
    { lat: 48.540454, lng: 19.000264 },
    { lat: 48.540439, lng: 19.000189 },
    { lat: 48.540314, lng: 18.999577 },
    { lat: 48.540387, lng: 18.998627 },
    { lat: 48.540427, lng: 18.997402 },
    { lat: 48.540289, lng: 18.99685 },
    { lat: 48.540202, lng: 18.996428 },
    { lat: 48.539556, lng: 18.994993 },
    { lat: 48.53909, lng: 18.994345 },
    { lat: 48.538141, lng: 18.992791 },
    { lat: 48.537886, lng: 18.992069 },
    { lat: 48.537115, lng: 18.990941 },
    { lat: 48.53675, lng: 18.990334 },
    { lat: 48.536223, lng: 18.989784 },
    { lat: 48.535502, lng: 18.989693 },
    { lat: 48.534997, lng: 18.990016 },
    { lat: 48.534646, lng: 18.989947 },
    { lat: 48.533826, lng: 18.989125 },
    { lat: 48.533271, lng: 18.9889 },
    { lat: 48.53294, lng: 18.988553 },
    { lat: 48.532515, lng: 18.987608 },
  ],
  Počúvadlo: [
    { lat: 48.3987987, lng: 18.8648717 },
    { lat: 48.3988601, lng: 18.8648616 },
    { lat: 48.3989395, lng: 18.8648488 },
    { lat: 48.4001001, lng: 18.8646608 },
    { lat: 48.4009245, lng: 18.864228 },
    { lat: 48.4015116, lng: 18.8642184 },
    { lat: 48.4020676, lng: 18.8647628 },
    { lat: 48.4027089, lng: 18.8648866 },
    { lat: 48.4035255, lng: 18.8650442 },
    { lat: 48.4037152, lng: 18.8652196 },
    { lat: 48.404072, lng: 18.8662701 },
    { lat: 48.4044467, lng: 18.866691 },
    { lat: 48.4046216, lng: 18.8672564 },
    { lat: 48.4052611, lng: 18.8670611 },
    { lat: 48.4053709, lng: 18.867028 },
    { lat: 48.4054407, lng: 18.8670024 },
    { lat: 48.4054671, lng: 18.8669929 },
    { lat: 48.4055149, lng: 18.8669756 },
    { lat: 48.4062839, lng: 18.8666959 },
    { lat: 48.4067465, lng: 18.86644 },
    { lat: 48.4076924, lng: 18.8656782 },
    { lat: 48.4074848, lng: 18.8656529 },
    { lat: 48.4072442, lng: 18.8656236 },
    { lat: 48.4064024, lng: 18.8630323 },
    { lat: 48.4062288, lng: 18.8622758 },
    { lat: 48.4056694, lng: 18.8602178 },
    { lat: 48.4056499, lng: 18.8601427 },
    { lat: 48.4054288, lng: 18.8602077 },
    { lat: 48.4049217, lng: 18.8600628 },
    { lat: 48.4048863, lng: 18.8600542 },
    { lat: 48.4047619, lng: 18.8600248 },
    { lat: 48.4046969, lng: 18.8600097 },
    { lat: 48.4045384, lng: 18.8601068 },
    { lat: 48.4044289, lng: 18.8601732 },
    { lat: 48.4040499, lng: 18.8604048 },
    { lat: 48.404122, lng: 18.8602114 },
    { lat: 48.4040941, lng: 18.8601504 },
    { lat: 48.4035892, lng: 18.8590542 },
    { lat: 48.4035601, lng: 18.8589895 },
    { lat: 48.4035142, lng: 18.8588895 },
    { lat: 48.403189, lng: 18.8591193 },
    { lat: 48.4025083, lng: 18.8596017 },
    { lat: 48.4024767, lng: 18.8596249 },
    { lat: 48.4023374, lng: 18.8597229 },
    { lat: 48.4021653, lng: 18.8591686 },
    { lat: 48.4020219, lng: 18.8587099 },
    { lat: 48.401991, lng: 18.8586106 },
    { lat: 48.401316, lng: 18.8591626 },
    { lat: 48.4009805, lng: 18.8595402 },
    { lat: 48.4007682, lng: 18.859773 },
    { lat: 48.3998396, lng: 18.8608217 },
    { lat: 48.3993556, lng: 18.8612064 },
    { lat: 48.3993087, lng: 18.8612584 },
    { lat: 48.3983724, lng: 18.862292 },
    { lat: 48.3983336, lng: 18.8622178 },
    { lat: 48.3982572, lng: 18.8620727 },
    { lat: 48.3981614, lng: 18.8614485 },
    { lat: 48.3983464, lng: 18.8610357 },
    { lat: 48.3978799, lng: 18.8603077 },
    { lat: 48.3977959, lng: 18.8599929 },
    { lat: 48.3975312, lng: 18.8590031 },
    { lat: 48.3975038, lng: 18.8587807 },
    { lat: 48.3974564, lng: 18.8583878 },
    { lat: 48.397406, lng: 18.8579633 },
    { lat: 48.3966786, lng: 18.8565105 },
    { lat: 48.3973666, lng: 18.8570137 },
    { lat: 48.3978187, lng: 18.8554955 },
    { lat: 48.3978649, lng: 18.8552577 },
    { lat: 48.3982044, lng: 18.8538726 },
    { lat: 48.3981985, lng: 18.8538467 },
    { lat: 48.3983205, lng: 18.853634 },
    { lat: 48.398342, lng: 18.8535928 },
    { lat: 48.3983928, lng: 18.8534441 },
    { lat: 48.3988348, lng: 18.8518185 },
    { lat: 48.3989257, lng: 18.8514877 },
    { lat: 48.3989501, lng: 18.8513933 },
    { lat: 48.3990275, lng: 18.8511056 },
    { lat: 48.3992378, lng: 18.8503346 },
    { lat: 48.399255, lng: 18.8502978 },
    { lat: 48.3994456, lng: 18.8495849 },
    { lat: 48.4001324, lng: 18.8498054 },
    { lat: 48.4002838, lng: 18.8498539 },
    { lat: 48.4003184, lng: 18.8498648 },
    { lat: 48.4005076, lng: 18.8499259 },
    { lat: 48.4013355, lng: 18.8501908 },
    { lat: 48.4013515, lng: 18.8501957 },
    { lat: 48.4014765, lng: 18.850236 },
    { lat: 48.4015087, lng: 18.8502461 },
    { lat: 48.4017324, lng: 18.8503177 },
    { lat: 48.4018493, lng: 18.8503557 },
    { lat: 48.401948, lng: 18.8503867 },
    { lat: 48.4021027, lng: 18.8504363 },
    { lat: 48.4024828, lng: 18.8508448 },
    { lat: 48.4025172, lng: 18.8509028 },
    { lat: 48.4025955, lng: 18.8509767 },
    { lat: 48.4024646, lng: 18.85115 },
    { lat: 48.4030461, lng: 18.8512714 },
    { lat: 48.4033709, lng: 18.8506445 },
    { lat: 48.4037429, lng: 18.8507722 },
    { lat: 48.4040196, lng: 18.8506498 },
    { lat: 48.4040997, lng: 18.8505229 },
    { lat: 48.4041146, lng: 18.8504598 },
    { lat: 48.4044542, lng: 18.8509401 },
    { lat: 48.4044911, lng: 18.8509993 },
    { lat: 48.404701, lng: 18.8504625 },
    { lat: 48.4047569, lng: 18.8503197 },
    { lat: 48.4047747, lng: 18.850276 },
    { lat: 48.4047837, lng: 18.850253 },
    { lat: 48.4053285, lng: 18.8501885 },
    { lat: 48.4056929, lng: 18.8500843 },
    { lat: 48.4054189, lng: 18.8491981 },
    { lat: 48.4051413, lng: 18.8486977 },
    { lat: 48.4044425, lng: 18.8480797 },
    { lat: 48.4034553, lng: 18.8475049 },
    { lat: 48.4030697, lng: 18.8472703 },
    { lat: 48.4023005, lng: 18.8476326 },
    { lat: 48.4012545, lng: 18.8472302 },
    { lat: 48.4001441, lng: 18.8470219 },
    { lat: 48.4000463, lng: 18.846185 },
    { lat: 48.3996257, lng: 18.8463566 },
    { lat: 48.3985, lng: 18.84629 },
    { lat: 48.397732, lng: 18.8461341 },
    { lat: 48.3971723, lng: 18.8452852 },
    { lat: 48.3966554, lng: 18.8445005 },
    { lat: 48.3966561, lng: 18.8440796 },
    { lat: 48.3966564, lng: 18.8439994 },
    { lat: 48.397102, lng: 18.8435502 },
    { lat: 48.3969954, lng: 18.8432101 },
    { lat: 48.3964444, lng: 18.8414518 },
    { lat: 48.3962659, lng: 18.8412322 },
    { lat: 48.3950491, lng: 18.8409285 },
    { lat: 48.3932055, lng: 18.8406478 },
    { lat: 48.3914259, lng: 18.8400634 },
    { lat: 48.3900233, lng: 18.8396127 },
    { lat: 48.3892239, lng: 18.8400244 },
    { lat: 48.3880336, lng: 18.8393685 },
    { lat: 48.3878443, lng: 18.8392634 },
    { lat: 48.3877177, lng: 18.8391935 },
    { lat: 48.3871986, lng: 18.8384302 },
    { lat: 48.3863482, lng: 18.838214 },
    { lat: 48.38581, lng: 18.8380766 },
    { lat: 48.3846218, lng: 18.837545 },
    { lat: 48.3843707, lng: 18.8374441 },
    { lat: 48.3831329, lng: 18.836846 },
    { lat: 48.3825855, lng: 18.8361922 },
    { lat: 48.3817619, lng: 18.8354946 },
    { lat: 48.3817443, lng: 18.8354797 },
    { lat: 48.3817202, lng: 18.835429 },
    { lat: 48.3805788, lng: 18.8330758 },
    { lat: 48.3806145, lng: 18.830524 },
    { lat: 48.3809683, lng: 18.8284713 },
    { lat: 48.3806765, lng: 18.8275749 },
    { lat: 48.3800221, lng: 18.8267298 },
    { lat: 48.3791589, lng: 18.8255129 },
    { lat: 48.3790987, lng: 18.8232686 },
    { lat: 48.3809291, lng: 18.820735 },
    { lat: 48.3812071, lng: 18.8182708 },
    { lat: 48.3805238, lng: 18.8176694 },
    { lat: 48.380453, lng: 18.8175909 },
    { lat: 48.3793573, lng: 18.8169073 },
    { lat: 48.3769287, lng: 18.8161229 },
    { lat: 48.3763678, lng: 18.8158352 },
    { lat: 48.3759996, lng: 18.8156322 },
    { lat: 48.3753502, lng: 18.8150507 },
    { lat: 48.3753314, lng: 18.8131035 },
    { lat: 48.3752568, lng: 18.8122859 },
    { lat: 48.3749227, lng: 18.8109513 },
    { lat: 48.3748772, lng: 18.8108766 },
    { lat: 48.3743172, lng: 18.8107385 },
    { lat: 48.3728041, lng: 18.8097113 },
    { lat: 48.3707664, lng: 18.8092846 },
    { lat: 48.3707356, lng: 18.8092333 },
    { lat: 48.370735, lng: 18.809204 },
    { lat: 48.370533, lng: 18.810571 },
    { lat: 48.369696, lng: 18.81121 },
    { lat: 48.368205, lng: 18.811028 },
    { lat: 48.366345, lng: 18.812446 },
    { lat: 48.36532, lng: 18.81378 },
    { lat: 48.365284, lng: 18.813836 },
    { lat: 48.365232, lng: 18.813901 },
    { lat: 48.364458, lng: 18.815313 },
    { lat: 48.363733, lng: 18.815975 },
    { lat: 48.36306, lng: 18.816541 },
    { lat: 48.361893, lng: 18.816881 },
    { lat: 48.36085, lng: 18.817061 },
    { lat: 48.358457, lng: 18.816871 },
    { lat: 48.357115, lng: 18.816373 },
    { lat: 48.356852, lng: 18.81674 },
    { lat: 48.356817, lng: 18.816759 },
    { lat: 48.356768, lng: 18.816785 },
    { lat: 48.356657, lng: 18.816845 },
    { lat: 48.356609, lng: 18.816871 },
    { lat: 48.354631, lng: 18.817581 },
    { lat: 48.35293, lng: 18.818858 },
    { lat: 48.352333, lng: 18.81928 },
    { lat: 48.34955, lng: 18.819525 },
    { lat: 48.349208, lng: 18.819759 },
    { lat: 48.348327, lng: 18.8207 },
    { lat: 48.348118, lng: 18.820832 },
    { lat: 48.34759, lng: 18.820447 },
    { lat: 48.346748, lng: 18.81991 },
    { lat: 48.346695, lng: 18.819827 },
    { lat: 48.346676, lng: 18.819796 },
    { lat: 48.346344, lng: 18.820703 },
    { lat: 48.346654, lng: 18.821102 },
    { lat: 48.346651, lng: 18.821501 },
    { lat: 48.346633, lng: 18.821991 },
    { lat: 48.346639, lng: 18.82209 },
    { lat: 48.346643, lng: 18.822156 },
    { lat: 48.346607, lng: 18.822177 },
    { lat: 48.346015, lng: 18.822523 },
    { lat: 48.346131, lng: 18.823314 },
    { lat: 48.346143, lng: 18.823401 },
    { lat: 48.346054, lng: 18.824149 },
    { lat: 48.346061, lng: 18.824221 },
    { lat: 48.346032, lng: 18.82427 },
    { lat: 48.3490814, lng: 18.8270025 },
    { lat: 48.3491399, lng: 18.8277989 },
    { lat: 48.3492039, lng: 18.8284795 },
    { lat: 48.3492893, lng: 18.8290763 },
    { lat: 48.3493938, lng: 18.8309234 },
    { lat: 48.349633, lng: 18.835144 },
    { lat: 48.349677, lng: 18.8353834 },
    { lat: 48.3497647, lng: 18.8359818 },
    { lat: 48.3498573, lng: 18.8368469 },
    { lat: 48.349944, lng: 18.8376645 },
    { lat: 48.3499465, lng: 18.8377113 },
    { lat: 48.3499513, lng: 18.837781 },
    { lat: 48.3499526, lng: 18.837807 },
    { lat: 48.3498501, lng: 18.8385618 },
    { lat: 48.3502747, lng: 18.840512 },
    { lat: 48.3504595, lng: 18.8418289 },
    { lat: 48.3504985, lng: 18.8421004 },
    { lat: 48.3505624, lng: 18.8430817 },
    { lat: 48.3505889, lng: 18.8435043 },
    { lat: 48.3507143, lng: 18.8444163 },
    { lat: 48.3508317, lng: 18.8452827 },
    { lat: 48.3511226, lng: 18.8467272 },
    { lat: 48.3513223, lng: 18.8475723 },
    { lat: 48.3520888, lng: 18.8482997 },
    { lat: 48.3526871, lng: 18.8488058 },
    { lat: 48.3534722, lng: 18.8493297 },
    { lat: 48.3541497, lng: 18.8502343 },
    { lat: 48.3552981, lng: 18.8520366 },
    { lat: 48.3557349, lng: 18.8523682 },
    { lat: 48.3565517, lng: 18.8529147 },
    { lat: 48.3572663, lng: 18.8535767 },
    { lat: 48.3578747, lng: 18.8549299 },
    { lat: 48.3579215, lng: 18.8550254 },
    { lat: 48.3580356, lng: 18.8554868 },
    { lat: 48.3576669, lng: 18.8555712 },
    { lat: 48.3572604, lng: 18.8555009 },
    { lat: 48.3565152, lng: 18.8553721 },
    { lat: 48.3565152, lng: 18.855499 },
    { lat: 48.3568152, lng: 18.8555889 },
    { lat: 48.3569681, lng: 18.8557295 },
    { lat: 48.3569499, lng: 18.8557706 },
    { lat: 48.357273, lng: 18.8560837 },
    { lat: 48.3573458, lng: 18.8561163 },
    { lat: 48.35758, lng: 18.8560267 },
    { lat: 48.357745, lng: 18.8559914 },
    { lat: 48.3579409, lng: 18.8560035 },
    { lat: 48.3581397, lng: 18.8559869 },
    { lat: 48.3584104, lng: 18.8560987 },
    { lat: 48.3584382, lng: 18.8561104 },
    { lat: 48.3584611, lng: 18.8561197 },
    { lat: 48.3588173, lng: 18.8560712 },
    { lat: 48.3589564, lng: 18.8568586 },
    { lat: 48.3592136, lng: 18.8569685 },
    { lat: 48.3593968, lng: 18.857542 },
    { lat: 48.3595158, lng: 18.8577705 },
    { lat: 48.3600934, lng: 18.858036 },
    { lat: 48.3604345, lng: 18.8582832 },
    { lat: 48.3605594, lng: 18.858471 },
    { lat: 48.3606155, lng: 18.8588137 },
    { lat: 48.3606312, lng: 18.8588565 },
    { lat: 48.3606431, lng: 18.8588661 },
    { lat: 48.3607878, lng: 18.8589824 },
    { lat: 48.3609822, lng: 18.8593337 },
    { lat: 48.3610065, lng: 18.859456 },
    { lat: 48.3609188, lng: 18.8596553 },
    { lat: 48.3609974, lng: 18.860101 },
    { lat: 48.3611072, lng: 18.8610441 },
    { lat: 48.361387, lng: 18.8613931 },
    { lat: 48.3616326, lng: 18.8614321 },
    { lat: 48.3627949, lng: 18.8614886 },
    { lat: 48.3630643, lng: 18.8612265 },
    { lat: 48.3636153, lng: 18.8612608 },
    { lat: 48.3638657, lng: 18.8615009 },
    { lat: 48.3641229, lng: 18.8613935 },
    { lat: 48.3644584, lng: 18.8617506 },
    { lat: 48.3647338, lng: 18.8617583 },
    { lat: 48.3648325, lng: 18.8617701 },
    { lat: 48.3650031, lng: 18.8614703 },
    { lat: 48.3650731, lng: 18.8614721 },
    { lat: 48.3651992, lng: 18.8618144 },
    { lat: 48.3654289, lng: 18.8619193 },
    { lat: 48.3654393, lng: 18.8619234 },
    { lat: 48.3654594, lng: 18.86192 },
    { lat: 48.3656079, lng: 18.8618905 },
    { lat: 48.3656415, lng: 18.8617362 },
    { lat: 48.3657268, lng: 18.8617684 },
    { lat: 48.3657615, lng: 18.8617002 },
    { lat: 48.3660877, lng: 18.861575 },
    { lat: 48.3661992, lng: 18.8617914 },
    { lat: 48.3665755, lng: 18.8620036 },
    { lat: 48.3667919, lng: 18.8618467 },
    { lat: 48.3670405, lng: 18.8619023 },
    { lat: 48.3671191, lng: 18.8619821 },
    { lat: 48.3671927, lng: 18.8620529 },
    { lat: 48.3673053, lng: 18.861918 },
    { lat: 48.3681618, lng: 18.8621888 },
    { lat: 48.3683618, lng: 18.8621962 },
    { lat: 48.3684102, lng: 18.8623899 },
    { lat: 48.3684732, lng: 18.8624509 },
    { lat: 48.3685643, lng: 18.8624682 },
    { lat: 48.3687288, lng: 18.8625546 },
    { lat: 48.3689603, lng: 18.8627126 },
    { lat: 48.3691247, lng: 18.8626827 },
    { lat: 48.3693394, lng: 18.8625007 },
    { lat: 48.36942, lng: 18.8625966 },
    { lat: 48.3694379, lng: 18.8626807 },
    { lat: 48.3694963, lng: 18.8627401 },
    { lat: 48.3699944, lng: 18.8626902 },
    { lat: 48.3700303, lng: 18.8626672 },
    { lat: 48.3700663, lng: 18.862641 },
    { lat: 48.3700999, lng: 18.8625754 },
    { lat: 48.3701224, lng: 18.8625302 },
    { lat: 48.3701568, lng: 18.862496 },
    { lat: 48.3702578, lng: 18.8626792 },
    { lat: 48.3704901, lng: 18.8627034 },
    { lat: 48.3705518, lng: 18.8626312 },
    { lat: 48.3706588, lng: 18.8623268 },
    { lat: 48.3707163, lng: 18.8622821 },
    { lat: 48.3708982, lng: 18.8624399 },
    { lat: 48.3711181, lng: 18.8622027 },
    { lat: 48.3712623, lng: 18.8621986 },
    { lat: 48.3712948, lng: 18.8622419 },
    { lat: 48.3713649, lng: 18.8622447 },
    { lat: 48.3714172, lng: 18.8622956 },
    { lat: 48.3717331, lng: 18.8622547 },
    { lat: 48.3718723, lng: 18.8624831 },
    { lat: 48.3722195, lng: 18.8625877 },
    { lat: 48.372516, lng: 18.8627587 },
    { lat: 48.3727584, lng: 18.8627806 },
    { lat: 48.3728073, lng: 18.8628075 },
    { lat: 48.3728583, lng: 18.8628345 },
    { lat: 48.3728958, lng: 18.8630149 },
    { lat: 48.3730968, lng: 18.8633211 },
    { lat: 48.3733332, lng: 18.8634019 },
    { lat: 48.373421, lng: 18.863354 },
    { lat: 48.3737975, lng: 18.8637869 },
    { lat: 48.3739308, lng: 18.863745 },
    { lat: 48.3740751, lng: 18.8638756 },
    { lat: 48.3742789, lng: 18.8640635 },
    { lat: 48.3744971, lng: 18.864161 },
    { lat: 48.3746454, lng: 18.864218 },
    { lat: 48.3747237, lng: 18.8642482 },
    { lat: 48.3749617, lng: 18.8645292 },
    { lat: 48.3751615, lng: 18.864664 },
    { lat: 48.375221, lng: 18.8647499 },
    { lat: 48.375422, lng: 18.8650346 },
    { lat: 48.3755008, lng: 18.8652257 },
    { lat: 48.3756997, lng: 18.8652567 },
    { lat: 48.3757421, lng: 18.8653354 },
    { lat: 48.3760977, lng: 18.8659946 },
    { lat: 48.3764675, lng: 18.8666836 },
    { lat: 48.3765649, lng: 18.8671475 },
    { lat: 48.3770644, lng: 18.8681019 },
    { lat: 48.377237, lng: 18.868558 },
    { lat: 48.3774096, lng: 18.8686464 },
    { lat: 48.3774766, lng: 18.8686303 },
    { lat: 48.3775528, lng: 18.8686242 },
    { lat: 48.3776788, lng: 18.8686148 },
    { lat: 48.3780654, lng: 18.8685933 },
    { lat: 48.3784268, lng: 18.868254 },
    { lat: 48.378528, lng: 18.8681875 },
    { lat: 48.3788621, lng: 18.8679331 },
    { lat: 48.379085, lng: 18.8679016 },
    { lat: 48.3793601, lng: 18.8674675 },
    { lat: 48.3795552, lng: 18.8674028 },
    { lat: 48.3798068, lng: 18.8670367 },
    { lat: 48.3801364, lng: 18.8667816 },
    { lat: 48.3802348, lng: 18.8665534 },
    { lat: 48.3804105, lng: 18.8665048 },
    { lat: 48.3804913, lng: 18.8663452 },
    { lat: 48.3806554, lng: 18.8663565 },
    { lat: 48.3809329, lng: 18.8661182 },
    { lat: 48.3811524, lng: 18.8660265 },
    { lat: 48.3820347, lng: 18.8660573 },
    { lat: 48.3824361, lng: 18.8659026 },
    { lat: 48.3825198, lng: 18.8658669 },
    { lat: 48.3829078, lng: 18.8659299 },
    { lat: 48.383039, lng: 18.8658737 },
    { lat: 48.3834634, lng: 18.8659041 },
    { lat: 48.3840194, lng: 18.8656232 },
    { lat: 48.3844057, lng: 18.8652463 },
    { lat: 48.384723, lng: 18.8652764 },
    { lat: 48.3851392, lng: 18.8653011 },
    { lat: 48.3854042, lng: 18.8650167 },
    { lat: 48.3861922, lng: 18.864429 },
    { lat: 48.3862324, lng: 18.8643988 },
    { lat: 48.3863127, lng: 18.864339 },
    { lat: 48.3865147, lng: 18.8641886 },
    { lat: 48.3866036, lng: 18.8641658 },
    { lat: 48.3868123, lng: 18.8640363 },
    { lat: 48.3881892, lng: 18.8637552 },
    { lat: 48.3887703, lng: 18.863697 },
    { lat: 48.3896929, lng: 18.8635713 },
    { lat: 48.3903224, lng: 18.8634258 },
    { lat: 48.3906378, lng: 18.863353 },
    { lat: 48.3915729, lng: 18.8631226 },
    { lat: 48.3924601, lng: 18.8628791 },
    { lat: 48.3936463, lng: 18.8632245 },
    { lat: 48.3936636, lng: 18.8632292 },
    { lat: 48.3937082, lng: 18.8632421 },
    { lat: 48.3944041, lng: 18.8634368 },
    { lat: 48.3953766, lng: 18.8637531 },
    { lat: 48.3958894, lng: 18.8639399 },
    { lat: 48.3959341, lng: 18.8639556 },
    { lat: 48.3961617, lng: 18.8640382 },
    { lat: 48.3962364, lng: 18.8640425 },
    { lat: 48.3962805, lng: 18.8640455 },
    { lat: 48.3971551, lng: 18.8642967 },
    { lat: 48.3973046, lng: 18.864355 },
    { lat: 48.398001, lng: 18.8646271 },
    { lat: 48.3987367, lng: 18.8648527 },
    { lat: 48.3987447, lng: 18.8648557 },
    { lat: 48.3987987, lng: 18.8648717 },
  ],
  BanskýStudenec: [
    { lat: 48.48032, lng: 19.007062 },
    { lat: 48.481149, lng: 19.005857 },
    { lat: 48.481268, lng: 19.005683 },
    { lat: 48.481515, lng: 19.005422 },
    { lat: 48.481749, lng: 19.005321 },
    { lat: 48.482203, lng: 19.0035 },
    { lat: 48.482396, lng: 19.002819 },
    { lat: 48.482597, lng: 19.0018 },
    { lat: 48.482915, lng: 19.000607 },
    { lat: 48.4812936, lng: 18.9993822 },
    { lat: 48.4807482, lng: 18.9992124 },
    { lat: 48.4800979, lng: 18.9988148 },
    { lat: 48.4796922, lng: 18.9988639 },
    { lat: 48.4791426, lng: 18.9988954 },
    { lat: 48.478647, lng: 18.9988414 },
    { lat: 48.4778235, lng: 18.998635 },
    { lat: 48.4771896, lng: 18.9982707 },
    { lat: 48.4769259, lng: 18.9981628 },
    { lat: 48.4760426, lng: 18.998244 },
    { lat: 48.4751753, lng: 18.9977047 },
    { lat: 48.4751432, lng: 18.997697 },
    { lat: 48.4744783, lng: 18.9975225 },
    { lat: 48.473625, lng: 18.9971711 },
    { lat: 48.4730477, lng: 18.9968391 },
    { lat: 48.4725677, lng: 18.99598 },
    { lat: 48.4722314, lng: 18.9956839 },
    { lat: 48.4720873, lng: 18.9954198 },
    { lat: 48.4719638, lng: 18.9953419 },
    { lat: 48.4718767, lng: 18.9950933 },
    { lat: 48.4716908, lng: 18.9950283 },
    { lat: 48.4713593, lng: 18.9946979 },
    { lat: 48.4711946, lng: 18.9939387 },
    { lat: 48.4710712, lng: 18.9938168 },
    { lat: 48.4709337, lng: 18.9935345 },
    { lat: 48.470754, lng: 18.9934571 },
    { lat: 48.4706672, lng: 18.9931896 },
    { lat: 48.4705141, lng: 18.9930182 },
    { lat: 48.4704369, lng: 18.9920105 },
    { lat: 48.4704257, lng: 18.991902 },
    { lat: 48.4702772, lng: 18.9918381 },
    { lat: 48.4700944, lng: 18.9919238 },
    { lat: 48.4697813, lng: 18.9922422 },
    { lat: 48.4689869, lng: 18.993274 },
    { lat: 48.468689, lng: 18.9937762 },
    { lat: 48.4683233, lng: 18.9941493 },
    { lat: 48.4679447, lng: 18.9945972 },
    { lat: 48.4675408, lng: 18.9946361 },
    { lat: 48.4672987, lng: 18.9944191 },
    { lat: 48.4671281, lng: 18.9940337 },
    { lat: 48.466744, lng: 18.9937084 },
    { lat: 48.4667141, lng: 18.9936749 },
    { lat: 48.4666302, lng: 18.9935754 },
    { lat: 48.4666569, lng: 18.992531 },
    { lat: 48.4663992, lng: 18.9915176 },
    { lat: 48.4664787, lng: 18.9910185 },
    { lat: 48.4665312, lng: 18.9906519 },
    { lat: 48.4665842, lng: 18.9902553 },
    { lat: 48.4665146, lng: 18.9899073 },
    { lat: 48.4665729, lng: 18.989567 },
    { lat: 48.4664607, lng: 18.9892246 },
    { lat: 48.4664803, lng: 18.9890575 },
    { lat: 48.4664267, lng: 18.9888868 },
    { lat: 48.4664033, lng: 18.988537 },
    { lat: 48.466342, lng: 18.9881778 },
    { lat: 48.4662401, lng: 18.9878147 },
    { lat: 48.4662273, lng: 18.9868757 },
    { lat: 48.4660856, lng: 18.9857768 },
    { lat: 48.4663691, lng: 18.9852095 },
    { lat: 48.4664668, lng: 18.9848924 },
    { lat: 48.4664812, lng: 18.9845997 },
    { lat: 48.4665938, lng: 18.9844397 },
    { lat: 48.4666475, lng: 18.9841848 },
    { lat: 48.4666604, lng: 18.9839098 },
    { lat: 48.4666548, lng: 18.9833449 },
    { lat: 48.4666536, lng: 18.9832014 },
    { lat: 48.4665826, lng: 18.9830216 },
    { lat: 48.4666528, lng: 18.9828984 },
    { lat: 48.4666376, lng: 18.9828636 },
    { lat: 48.466711, lng: 18.9826437 },
    { lat: 48.4671003, lng: 18.9823406 },
    { lat: 48.4673462, lng: 18.9818998 },
    { lat: 48.4674215, lng: 18.9818283 },
    { lat: 48.4674818, lng: 18.9815275 },
    { lat: 48.4675336, lng: 18.9813947 },
    { lat: 48.4676456, lng: 18.981181 },
    { lat: 48.4678093, lng: 18.9808552 },
    { lat: 48.4677524, lng: 18.980709 },
    { lat: 48.4677036, lng: 18.980418 },
    { lat: 48.4679655, lng: 18.9794701 },
    { lat: 48.4681165, lng: 18.9793127 },
    { lat: 48.4683161, lng: 18.9790467 },
    { lat: 48.468385, lng: 18.9786752 },
    { lat: 48.4682387, lng: 18.9782608 },
    { lat: 48.4683113, lng: 18.9778506 },
    { lat: 48.4683281, lng: 18.9775161 },
    { lat: 48.4683446, lng: 18.9772427 },
    { lat: 48.4684889, lng: 18.9767123 },
    { lat: 48.4685443, lng: 18.9762459 },
    { lat: 48.4686065, lng: 18.9759417 },
    { lat: 48.4686614, lng: 18.9754471 },
    { lat: 48.4686724, lng: 18.9749779 },
    { lat: 48.4685857, lng: 18.9747193 },
    { lat: 48.4686644, lng: 18.9743809 },
    { lat: 48.468756, lng: 18.9737443 },
    { lat: 48.4687876, lng: 18.9733257 },
    { lat: 48.4687185, lng: 18.9729211 },
    { lat: 48.4687308, lng: 18.9724123 },
    { lat: 48.4687126, lng: 18.9722077 },
    { lat: 48.4686109, lng: 18.9719611 },
    { lat: 48.4684223, lng: 18.9717782 },
    { lat: 48.4682779, lng: 18.9714211 },
    { lat: 48.4682288, lng: 18.9710146 },
    { lat: 48.4682001, lng: 18.9706925 },
    { lat: 48.4680326, lng: 18.9701739 },
    { lat: 48.4681122, lng: 18.9695905 },
    { lat: 48.4680934, lng: 18.9692228 },
    { lat: 48.4679992, lng: 18.9689875 },
    { lat: 48.4678458, lng: 18.968899 },
    { lat: 48.4676759, lng: 18.9685331 },
    { lat: 48.4676327, lng: 18.9682368 },
    { lat: 48.4677022, lng: 18.9680948 },
    { lat: 48.4676679, lng: 18.9674695 },
    { lat: 48.4676773, lng: 18.9670098 },
    { lat: 48.4675706, lng: 18.9662713 },
    { lat: 48.4675249, lng: 18.9657934 },
    { lat: 48.4675194, lng: 18.9654857 },
    { lat: 48.4674333, lng: 18.9651922 },
    { lat: 48.4673474, lng: 18.9647917 },
    { lat: 48.4672448, lng: 18.9646796 },
    { lat: 48.4670781, lng: 18.964308 },
    { lat: 48.4670285, lng: 18.9638924 },
    { lat: 48.466753, lng: 18.9627351 },
    { lat: 48.4665869, lng: 18.9624384 },
    { lat: 48.4665258, lng: 18.9621026 },
    { lat: 48.4665092, lng: 18.9617134 },
    { lat: 48.4664692, lng: 18.9615915 },
    { lat: 48.4662818, lng: 18.9613694 },
    { lat: 48.4659907, lng: 18.9609263 },
    { lat: 48.465819, lng: 18.9607418 },
    { lat: 48.4656244, lng: 18.9603323 },
    { lat: 48.4654667, lng: 18.9601363 },
    { lat: 48.465422, lng: 18.9598348 },
    { lat: 48.4653065, lng: 18.9595395 },
    { lat: 48.4651176, lng: 18.9592373 },
    { lat: 48.4650185, lng: 18.9593084 },
    { lat: 48.4648429, lng: 18.9591838 },
    { lat: 48.4646423, lng: 18.9589373 },
    { lat: 48.4643718, lng: 18.9587686 },
    { lat: 48.4638252, lng: 18.9580214 },
    { lat: 48.4638712, lng: 18.9576871 },
    { lat: 48.4638236, lng: 18.9575469 },
    { lat: 48.4637871, lng: 18.9572112 },
    { lat: 48.4636891, lng: 18.9569042 },
    { lat: 48.4636795, lng: 18.9564704 },
    { lat: 48.4637312, lng: 18.9561376 },
    { lat: 48.4637776, lng: 18.9553915 },
    { lat: 48.4638265, lng: 18.9550725 },
    { lat: 48.4639279, lng: 18.9545424 },
    { lat: 48.4640624, lng: 18.9539939 },
    { lat: 48.4642551, lng: 18.9534895 },
    { lat: 48.4645291, lng: 18.9526524 },
    { lat: 48.4646135, lng: 18.9523151 },
    { lat: 48.4646572, lng: 18.9518734 },
    { lat: 48.4646623, lng: 18.9510439 },
    { lat: 48.4646965, lng: 18.9506147 },
    { lat: 48.4647069, lng: 18.9500479 },
    { lat: 48.4647625, lng: 18.9497661 },
    { lat: 48.4648745, lng: 18.9494636 },
    { lat: 48.4650539, lng: 18.9489607 },
    { lat: 48.4650956, lng: 18.948872 },
    { lat: 48.4653231, lng: 18.9483813 },
    { lat: 48.4654284, lng: 18.9481947 },
    { lat: 48.4657799, lng: 18.9476598 },
    { lat: 48.4660106, lng: 18.9472976 },
    { lat: 48.4662447, lng: 18.9470044 },
    { lat: 48.4664943, lng: 18.9467719 },
    { lat: 48.4665171, lng: 18.9467505 },
    { lat: 48.4665814, lng: 18.9466467 },
    { lat: 48.4665872, lng: 18.9466258 },
    { lat: 48.4665969, lng: 18.9465941 },
    { lat: 48.4666055, lng: 18.9465622 },
    { lat: 48.4666083, lng: 18.9465523 },
    { lat: 48.4666668, lng: 18.9463519 },
    { lat: 48.4666964, lng: 18.9461147 },
    { lat: 48.4666574, lng: 18.9458609 },
    { lat: 48.4665566, lng: 18.9455443 },
    { lat: 48.466542, lng: 18.9452596 },
    { lat: 48.4664708, lng: 18.9451659 },
    { lat: 48.466362, lng: 18.9448918 },
    { lat: 48.4662521, lng: 18.9447283 },
    { lat: 48.4660699, lng: 18.9444748 },
    { lat: 48.4659532, lng: 18.9441753 },
    { lat: 48.4658393, lng: 18.943825 },
    { lat: 48.4656964, lng: 18.9433879 },
    { lat: 48.465507, lng: 18.9430627 },
    { lat: 48.4653696, lng: 18.9427983 },
    { lat: 48.4651598, lng: 18.9423601 },
    { lat: 48.4648281, lng: 18.9417846 },
    { lat: 48.464719, lng: 18.9416097 },
    { lat: 48.4645367, lng: 18.9411149 },
    { lat: 48.4644137, lng: 18.9409146 },
    { lat: 48.4642939, lng: 18.9407764 },
    { lat: 48.4640574, lng: 18.9405829 },
    { lat: 48.4638152, lng: 18.9401029 },
    { lat: 48.4636593, lng: 18.9398375 },
    { lat: 48.4634433, lng: 18.9395599 },
    { lat: 48.4628871, lng: 18.938652 },
    { lat: 48.4625577, lng: 18.9381218 },
    { lat: 48.4623676, lng: 18.9379531 },
    { lat: 48.4621827, lng: 18.9378228 },
    { lat: 48.4620362, lng: 18.9377812 },
    { lat: 48.4617723, lng: 18.9377582 },
    { lat: 48.4612347, lng: 18.9376742 },
    { lat: 48.4611593, lng: 18.9376321 },
    { lat: 48.4610935, lng: 18.937514 },
    { lat: 48.4610776, lng: 18.9374483 },
    { lat: 48.4608726, lng: 18.9370302 },
    { lat: 48.4608458, lng: 18.9369516 },
    { lat: 48.4608926, lng: 18.9369181 },
    { lat: 48.4608692, lng: 18.9368348 },
    { lat: 48.4608835, lng: 18.9367831 },
    { lat: 48.4609787, lng: 18.9366619 },
    { lat: 48.4610018, lng: 18.9366074 },
    { lat: 48.4609763, lng: 18.9363545 },
    { lat: 48.460932, lng: 18.9363654 },
    { lat: 48.4608147, lng: 18.9355754 },
    { lat: 48.460992, lng: 18.9353616 },
    { lat: 48.4610685, lng: 18.9350063 },
    { lat: 48.4611589, lng: 18.9350328 },
    { lat: 48.461294, lng: 18.9350721 },
    { lat: 48.4613739, lng: 18.9347197 },
    { lat: 48.4617262, lng: 18.9345299 },
    { lat: 48.4619691, lng: 18.9341447 },
    { lat: 48.4619012, lng: 18.9337412 },
    { lat: 48.4615241, lng: 18.9335359 },
    { lat: 48.4610166, lng: 18.9332502 },
    { lat: 48.4610253, lng: 18.9332215 },
    { lat: 48.4608485, lng: 18.9330499 },
    { lat: 48.4606187, lng: 18.932835 },
    { lat: 48.4605295, lng: 18.9324382 },
    { lat: 48.4606229, lng: 18.9320629 },
    { lat: 48.4607122, lng: 18.9317576 },
    { lat: 48.4607866, lng: 18.9313849 },
    { lat: 48.4608293, lng: 18.9309838 },
    { lat: 48.460827, lng: 18.9306995 },
    { lat: 48.4607704, lng: 18.9304531 },
    { lat: 48.4607351, lng: 18.9301386 },
    { lat: 48.4607252, lng: 18.9300573 },
    { lat: 48.4606898, lng: 18.92985 },
    { lat: 48.4604373, lng: 18.9292182 },
    { lat: 48.4602894, lng: 18.9290166 },
    { lat: 48.460195, lng: 18.9288887 },
    { lat: 48.4600808, lng: 18.9286856 },
    { lat: 48.4600551, lng: 18.9286914 },
    { lat: 48.4597639, lng: 18.9285973 },
    { lat: 48.4595807, lng: 18.9287185 },
    { lat: 48.4593438, lng: 18.9288685 },
    { lat: 48.4590138, lng: 18.9289442 },
    { lat: 48.4583462, lng: 18.9294008 },
    { lat: 48.4570427, lng: 18.9289832 },
    { lat: 48.4561358, lng: 18.928296 },
    { lat: 48.4557041, lng: 18.9278361 },
    { lat: 48.4555926, lng: 18.9277091 },
    { lat: 48.4553753, lng: 18.9275859 },
    { lat: 48.4548231, lng: 18.9272415 },
    { lat: 48.4544243, lng: 18.9273096 },
    { lat: 48.4542215, lng: 18.9274692 },
    { lat: 48.4538424, lng: 18.9275505 },
    { lat: 48.4536483, lng: 18.9276122 },
    { lat: 48.4536271, lng: 18.9276185 },
    { lat: 48.4535904, lng: 18.9276302 },
    { lat: 48.4535351, lng: 18.9276477 },
    { lat: 48.4533997, lng: 18.9276901 },
    { lat: 48.4531659, lng: 18.9279446 },
    { lat: 48.453105, lng: 18.9280109 },
    { lat: 48.4530889, lng: 18.9280281 },
    { lat: 48.453051, lng: 18.9280815 },
    { lat: 48.4528744, lng: 18.9284611 },
    { lat: 48.4528407, lng: 18.9287055 },
    { lat: 48.4524641, lng: 18.9293862 },
    { lat: 48.4524364, lng: 18.9294587 },
    { lat: 48.4523876, lng: 18.9294991 },
    { lat: 48.4520363, lng: 18.9298802 },
    { lat: 48.4517169, lng: 18.9305989 },
    { lat: 48.4516254, lng: 18.9306842 },
    { lat: 48.4515404, lng: 18.9307636 },
    { lat: 48.4508705, lng: 18.9317692 },
    { lat: 48.4507154, lng: 18.9320209 },
    { lat: 48.4505195, lng: 18.9326407 },
    { lat: 48.4503529, lng: 18.933 },
    { lat: 48.449951, lng: 18.9334512 },
    { lat: 48.4496125, lng: 18.9343212 },
    { lat: 48.450099, lng: 18.9358908 },
    { lat: 48.4501205, lng: 18.9382655 },
    { lat: 48.450265, lng: 18.9394329 },
    { lat: 48.4505655, lng: 18.940805 },
    { lat: 48.4503886, lng: 18.941755 },
    { lat: 48.4502882, lng: 18.9421925 },
    { lat: 48.4502481, lng: 18.9427288 },
    { lat: 48.4499447, lng: 18.9434332 },
    { lat: 48.4496958, lng: 18.9441393 },
    { lat: 48.4492905, lng: 18.9450969 },
    { lat: 48.4491297, lng: 18.9456474 },
    { lat: 48.4491069, lng: 18.9458069 },
    { lat: 48.4488777, lng: 18.9463967 },
    { lat: 48.4486741, lng: 18.9468632 },
    { lat: 48.4486724, lng: 18.9474945 },
    { lat: 48.448518, lng: 18.9485481 },
    { lat: 48.4484518, lng: 18.9487777 },
    { lat: 48.448186, lng: 18.9497053 },
    { lat: 48.448085, lng: 18.9514317 },
    { lat: 48.4475716, lng: 18.952747 },
    { lat: 48.4470153, lng: 18.9537042 },
    { lat: 48.4467842, lng: 18.9549654 },
    { lat: 48.4467548, lng: 18.9549397 },
    { lat: 48.445009, lng: 18.9574003 },
    { lat: 48.4449474, lng: 18.9574529 },
    { lat: 48.4448989, lng: 18.9574986 },
    { lat: 48.4438796, lng: 18.9585089 },
    { lat: 48.4434195, lng: 18.9588572 },
    { lat: 48.4431162, lng: 18.9600286 },
    { lat: 48.4428691, lng: 18.9606435 },
    { lat: 48.4423601, lng: 18.9614498 },
    { lat: 48.4416309, lng: 18.9624827 },
    { lat: 48.4408513, lng: 18.9635871 },
    { lat: 48.4400744, lng: 18.9631876 },
    { lat: 48.4396461, lng: 18.9629674 },
    { lat: 48.4384259, lng: 18.9617955 },
    { lat: 48.4372476, lng: 18.9603817 },
    { lat: 48.4372017, lng: 18.9604772 },
    { lat: 48.4369843, lng: 18.9639239 },
    { lat: 48.4371143, lng: 18.9647241 },
    { lat: 48.4376381, lng: 18.9658628 },
    { lat: 48.4376415, lng: 18.966058 },
    { lat: 48.4378103, lng: 18.9665825 },
    { lat: 48.4378598, lng: 18.9674642 },
    { lat: 48.4374535, lng: 18.9680948 },
    { lat: 48.4370756, lng: 18.9688656 },
    { lat: 48.4369528, lng: 18.9695267 },
    { lat: 48.4365807, lng: 18.9705234 },
    { lat: 48.4364927, lng: 18.9711833 },
    { lat: 48.4361709, lng: 18.9718393 },
    { lat: 48.4353345, lng: 18.9735669 },
    { lat: 48.4349812, lng: 18.9742865 },
    { lat: 48.4347352, lng: 18.9741927 },
    { lat: 48.4346622, lng: 18.9746201 },
    { lat: 48.4342929, lng: 18.9747635 },
    { lat: 48.4335989, lng: 18.9753672 },
    { lat: 48.4334323, lng: 18.975729 },
    { lat: 48.4333599, lng: 18.9760132 },
    { lat: 48.4332665, lng: 18.9760179 },
    { lat: 48.4330962, lng: 18.9764352 },
    { lat: 48.4329357, lng: 18.9766703 },
    { lat: 48.4327706, lng: 18.9773245 },
    { lat: 48.432756, lng: 18.9773705 },
    { lat: 48.4327698, lng: 18.9779296 },
    { lat: 48.432828, lng: 18.9784498 },
    { lat: 48.4328792, lng: 18.9787907 },
    { lat: 48.4328567, lng: 18.9791242 },
    { lat: 48.4327725, lng: 18.9796587 },
    { lat: 48.4326943, lng: 18.9798079 },
    { lat: 48.4322622, lng: 18.9801332 },
    { lat: 48.4322853, lng: 18.9803677 },
    { lat: 48.4317404, lng: 18.9810659 },
    { lat: 48.4315168, lng: 18.9814819 },
    { lat: 48.4313819, lng: 18.9817324 },
    { lat: 48.4311952, lng: 18.9820933 },
    { lat: 48.4309516, lng: 18.9824498 },
    { lat: 48.4306767, lng: 18.9828286 },
    { lat: 48.4296039, lng: 18.9842344 },
    { lat: 48.429584, lng: 18.9843723 },
    { lat: 48.4299986, lng: 18.9848131 },
    { lat: 48.4297456, lng: 18.985777 },
    { lat: 48.4297584, lng: 18.9858305 },
    { lat: 48.4291598, lng: 18.9878225 },
    { lat: 48.4274949, lng: 18.9912207 },
    { lat: 48.4272233, lng: 18.9919988 },
    { lat: 48.4269766, lng: 18.9923789 },
    { lat: 48.4263566, lng: 18.9934225 },
    { lat: 48.4261003, lng: 18.9939017 },
    { lat: 48.4254928, lng: 18.9940176 },
    { lat: 48.4250064, lng: 18.9940096 },
    { lat: 48.424166, lng: 18.994302 },
    { lat: 48.423815, lng: 18.994823 },
    { lat: 48.423888, lng: 18.996512 },
    { lat: 48.425003, lng: 18.998126 },
    { lat: 48.425231, lng: 18.998645 },
    { lat: 48.425263, lng: 18.998706 },
    { lat: 48.425464, lng: 18.999205 },
    { lat: 48.425904, lng: 18.999986 },
    { lat: 48.426461, lng: 19.000652 },
    { lat: 48.426816, lng: 19.001734 },
    { lat: 48.426939, lng: 19.002235 },
    { lat: 48.427229, lng: 19.003018 },
    { lat: 48.427657, lng: 19.003763 },
    { lat: 48.428003, lng: 19.004751 },
    { lat: 48.428132, lng: 19.004996 },
    { lat: 48.428463, lng: 19.00547 },
    { lat: 48.4291, lng: 19.006057 },
    { lat: 48.429483, lng: 19.006305 },
    { lat: 48.42966, lng: 19.006375 },
    { lat: 48.430126, lng: 19.007073 },
    { lat: 48.43049, lng: 19.007324 },
    { lat: 48.431296, lng: 19.008169 },
    { lat: 48.432362, lng: 19.009516 },
    { lat: 48.432502, lng: 19.009571 },
    { lat: 48.433135, lng: 19.01007 },
    { lat: 48.43417, lng: 19.011059 },
    { lat: 48.434646, lng: 19.01143 },
    { lat: 48.435204, lng: 19.011561 },
    { lat: 48.435415, lng: 19.011524 },
    { lat: 48.435711, lng: 19.011437 },
    { lat: 48.43614, lng: 19.011009 },
    { lat: 48.436505, lng: 19.010721 },
    { lat: 48.437337, lng: 19.010187 },
    { lat: 48.437977, lng: 19.010025 },
    { lat: 48.438898, lng: 19.009843 },
    { lat: 48.439243, lng: 19.009764 },
    { lat: 48.439791, lng: 19.01067 },
    { lat: 48.440708, lng: 19.010229 },
    { lat: 48.441576, lng: 19.009759 },
    { lat: 48.442572, lng: 19.009714 },
    { lat: 48.442729, lng: 19.009764 },
    { lat: 48.443419, lng: 19.009718 },
    { lat: 48.443742, lng: 19.009691 },
    { lat: 48.44463, lng: 19.009709 },
    { lat: 48.445026, lng: 19.009865 },
    { lat: 48.446019, lng: 19.009681 },
    { lat: 48.446801, lng: 19.009683 },
    { lat: 48.448192, lng: 19.010082 },
    { lat: 48.448394, lng: 19.010132 },
    { lat: 48.448639, lng: 19.010187 },
    { lat: 48.449321, lng: 19.0103 },
    { lat: 48.449633, lng: 19.010129 },
    { lat: 48.449894, lng: 19.009714 },
    { lat: 48.450501, lng: 19.008258 },
    { lat: 48.45075, lng: 19.007903 },
    { lat: 48.451363, lng: 19.007493 },
    { lat: 48.451785, lng: 19.006966 },
    { lat: 48.451905, lng: 19.006806 },
    { lat: 48.451981, lng: 19.00618 },
    { lat: 48.452079, lng: 19.005966 },
    { lat: 48.45334, lng: 19.005555 },
    { lat: 48.454304, lng: 19.004601 },
    { lat: 48.455288, lng: 19.004352 },
    { lat: 48.455669, lng: 19.003935 },
    { lat: 48.456218, lng: 19.003479 },
    { lat: 48.456698, lng: 19.002658 },
    { lat: 48.456956, lng: 19.002294 },
    { lat: 48.457373, lng: 19.002171 },
    { lat: 48.458268, lng: 19.002047 },
    { lat: 48.458919, lng: 19.001806 },
    { lat: 48.459146, lng: 19.001826 },
    { lat: 48.460572, lng: 19.001793 },
    { lat: 48.461026, lng: 19.001662 },
    { lat: 48.461529, lng: 19.001387 },
    { lat: 48.461702, lng: 19.001511 },
    { lat: 48.462163, lng: 19.002091 },
    { lat: 48.462398, lng: 19.00218 },
    { lat: 48.462976, lng: 19.002389 },
    { lat: 48.463214, lng: 19.002656 },
    { lat: 48.463368, lng: 19.00287 },
    { lat: 48.463486, lng: 19.002931 },
    { lat: 48.463593, lng: 19.003014 },
    { lat: 48.463721, lng: 19.003079 },
    { lat: 48.463832, lng: 19.00325 },
    { lat: 48.464541, lng: 19.003819 },
    { lat: 48.465529, lng: 19.004586 },
    { lat: 48.465918, lng: 19.00487 },
    { lat: 48.466836, lng: 19.005089 },
    { lat: 48.467012, lng: 19.00515 },
    { lat: 48.46758, lng: 19.005618 },
    { lat: 48.468117, lng: 19.006256 },
    { lat: 48.468907, lng: 19.006728 },
    { lat: 48.469185, lng: 19.007149 },
    { lat: 48.469886, lng: 19.007303 },
    { lat: 48.470143, lng: 19.007422 },
    { lat: 48.470246, lng: 19.007496 },
    { lat: 48.470367, lng: 19.007584 },
    { lat: 48.470743, lng: 19.007855 },
    { lat: 48.471229, lng: 19.008208 },
    { lat: 48.471763, lng: 19.008653 },
    { lat: 48.472197, lng: 19.008907 },
    { lat: 48.472378, lng: 19.008943 },
    { lat: 48.472717, lng: 19.009114 },
    { lat: 48.472949, lng: 19.009243 },
    { lat: 48.473652, lng: 19.009531 },
    { lat: 48.474423, lng: 19.009816 },
    { lat: 48.474821, lng: 19.010614 },
    { lat: 48.475127, lng: 19.010748 },
    { lat: 48.475166, lng: 19.010765 },
    { lat: 48.475278, lng: 19.010814 },
    { lat: 48.475736, lng: 19.011374 },
    { lat: 48.475754, lng: 19.011683 },
    { lat: 48.476014, lng: 19.011972 },
    { lat: 48.476226, lng: 19.012269 },
    { lat: 48.476322, lng: 19.012403 },
    { lat: 48.476816, lng: 19.013091 },
    { lat: 48.477169, lng: 19.013138 },
    { lat: 48.47734, lng: 19.012433 },
    { lat: 48.477388, lng: 19.01205 },
    { lat: 48.477461, lng: 19.011341 },
    { lat: 48.477601, lng: 19.010793 },
    { lat: 48.477816, lng: 19.01024 },
    { lat: 48.478105, lng: 19.009929 },
    { lat: 48.47823, lng: 19.009675 },
    { lat: 48.478738, lng: 19.008675 },
    { lat: 48.47894, lng: 19.008348 },
    { lat: 48.479105, lng: 19.008199 },
    { lat: 48.479317, lng: 19.007956 },
    { lat: 48.479382, lng: 19.007821 },
    { lat: 48.479641, lng: 19.00739 },
    { lat: 48.479725, lng: 19.007248 },
    { lat: 48.479963, lng: 19.007178 },
    { lat: 48.48032, lng: 19.007062 },
  ],
  ŠtiavnickéBane: [
    { lat: 48.420277, lng: 18.817078 },
    { lat: 48.4202596, lng: 18.8171249 },
    { lat: 48.4201641, lng: 18.8173911 },
    { lat: 48.4200874, lng: 18.8176039 },
    { lat: 48.4199567, lng: 18.8181625 },
    { lat: 48.4199285, lng: 18.818337 },
    { lat: 48.4199337, lng: 18.818478 },
    { lat: 48.4199771, lng: 18.8186419 },
    { lat: 48.4201843, lng: 18.8188268 },
    { lat: 48.420363, lng: 18.8190528 },
    { lat: 48.4205925, lng: 18.8193963 },
    { lat: 48.4206261, lng: 18.8194466 },
    { lat: 48.4207625, lng: 18.819511 },
    { lat: 48.4209739, lng: 18.8198886 },
    { lat: 48.4211227, lng: 18.8201671 },
    { lat: 48.4212615, lng: 18.8204042 },
    { lat: 48.4212206, lng: 18.8206366 },
    { lat: 48.4212821, lng: 18.8208196 },
    { lat: 48.4213565, lng: 18.8209269 },
    { lat: 48.4215022, lng: 18.8210609 },
    { lat: 48.4216188, lng: 18.821198 },
    { lat: 48.4216465, lng: 18.8214233 },
    { lat: 48.4216414, lng: 18.8217136 },
    { lat: 48.4217289, lng: 18.8218382 },
    { lat: 48.4218922, lng: 18.8220273 },
    { lat: 48.4219729, lng: 18.8223408 },
    { lat: 48.4219982, lng: 18.8226066 },
    { lat: 48.4220818, lng: 18.8229314 },
    { lat: 48.4220397, lng: 18.8231825 },
    { lat: 48.4220157, lng: 18.8232662 },
    { lat: 48.4220042, lng: 18.8234763 },
    { lat: 48.4219096, lng: 18.8237591 },
    { lat: 48.4218862, lng: 18.824129 },
    { lat: 48.4218163, lng: 18.8244092 },
    { lat: 48.4217201, lng: 18.8247912 },
    { lat: 48.4216142, lng: 18.8252428 },
    { lat: 48.4214794, lng: 18.8257739 },
    { lat: 48.4213736, lng: 18.8261002 },
    { lat: 48.4212287, lng: 18.8264333 },
    { lat: 48.4212046, lng: 18.8265093 },
    { lat: 48.4210721, lng: 18.8267596 },
    { lat: 48.4210212, lng: 18.8268661 },
    { lat: 48.4209794, lng: 18.8269921 },
    { lat: 48.4208229, lng: 18.8273617 },
    { lat: 48.4207698, lng: 18.8276381 },
    { lat: 48.4207324, lng: 18.8278307 },
    { lat: 48.4207954, lng: 18.8281282 },
    { lat: 48.4208426, lng: 18.8284279 },
    { lat: 48.4208498, lng: 18.8286965 },
    { lat: 48.4208594, lng: 18.8290933 },
    { lat: 48.4208472, lng: 18.8294011 },
    { lat: 48.4208932, lng: 18.8298246 },
    { lat: 48.4209295, lng: 18.8301298 },
    { lat: 48.4209781, lng: 18.8305671 },
    { lat: 48.4209966, lng: 18.8306583 },
    { lat: 48.4210648, lng: 18.8308189 },
    { lat: 48.4210276, lng: 18.830993 },
    { lat: 48.4211744, lng: 18.8318625 },
    { lat: 48.4211281, lng: 18.8322237 },
    { lat: 48.4211705, lng: 18.8324341 },
    { lat: 48.4211733, lng: 18.8328946 },
    { lat: 48.4211611, lng: 18.8331364 },
    { lat: 48.4212548, lng: 18.8334175 },
    { lat: 48.4212893, lng: 18.8336034 },
    { lat: 48.4212728, lng: 18.8338705 },
    { lat: 48.4213633, lng: 18.8340689 },
    { lat: 48.4214698, lng: 18.8343166 },
    { lat: 48.4214988, lng: 18.8345908 },
    { lat: 48.4215974, lng: 18.8347549 },
    { lat: 48.4220627, lng: 18.8350674 },
    { lat: 48.4222163, lng: 18.8352453 },
    { lat: 48.422484, lng: 18.8354072 },
    { lat: 48.4226247, lng: 18.8355542 },
    { lat: 48.4227089, lng: 18.8357808 },
    { lat: 48.4227793, lng: 18.8362645 },
    { lat: 48.4226239, lng: 18.8367458 },
    { lat: 48.42265, lng: 18.8370498 },
    { lat: 48.4226473, lng: 18.837234 },
    { lat: 48.4226989, lng: 18.8373799 },
    { lat: 48.4225326, lng: 18.8378031 },
    { lat: 48.42243, lng: 18.8378695 },
    { lat: 48.4224087, lng: 18.8380222 },
    { lat: 48.4224023, lng: 18.8382037 },
    { lat: 48.4224016, lng: 18.8383841 },
    { lat: 48.4223671, lng: 18.8385653 },
    { lat: 48.4223101, lng: 18.8387045 },
    { lat: 48.4222008, lng: 18.8388153 },
    { lat: 48.4220619, lng: 18.8389602 },
    { lat: 48.421862, lng: 18.8391904 },
    { lat: 48.4214423, lng: 18.8397298 },
    { lat: 48.4212178, lng: 18.8399632 },
    { lat: 48.4210493, lng: 18.8400172 },
    { lat: 48.4208021, lng: 18.8401443 },
    { lat: 48.420447, lng: 18.840299 },
    { lat: 48.4202678, lng: 18.8403833 },
    { lat: 48.4198503, lng: 18.8406182 },
    { lat: 48.4197174, lng: 18.8408156 },
    { lat: 48.4196008, lng: 18.8409142 },
    { lat: 48.4195014, lng: 18.8409973 },
    { lat: 48.4193414, lng: 18.8412135 },
    { lat: 48.4193113, lng: 18.8412444 },
    { lat: 48.4191894, lng: 18.841394 },
    { lat: 48.4191066, lng: 18.8415142 },
    { lat: 48.4187265, lng: 18.8415753 },
    { lat: 48.4186466, lng: 18.841732 },
    { lat: 48.4186402, lng: 18.841736 },
    { lat: 48.4185887, lng: 18.8417679 },
    { lat: 48.4185777, lng: 18.8417767 },
    { lat: 48.4185353, lng: 18.8418009 },
    { lat: 48.4179519, lng: 18.8422704 },
    { lat: 48.4179083, lng: 18.8422787 },
    { lat: 48.417866, lng: 18.8422869 },
    { lat: 48.4174289, lng: 18.8428765 },
    { lat: 48.4170268, lng: 18.8433626 },
    { lat: 48.4167093, lng: 18.8439786 },
    { lat: 48.4163571, lng: 18.8447066 },
    { lat: 48.4159515, lng: 18.8453823 },
    { lat: 48.4157745, lng: 18.8454919 },
    { lat: 48.4152769, lng: 18.8458331 },
    { lat: 48.4151768, lng: 18.845902 },
    { lat: 48.4151442, lng: 18.8459246 },
    { lat: 48.4145497, lng: 18.8471291 },
    { lat: 48.4144292, lng: 18.8474152 },
    { lat: 48.4144151, lng: 18.847426 },
    { lat: 48.4145592, lng: 18.8477878 },
    { lat: 48.4145214, lng: 18.8481636 },
    { lat: 48.4144948, lng: 18.8486498 },
    { lat: 48.4149632, lng: 18.8489361 },
    { lat: 48.4154307, lng: 18.8492741 },
    { lat: 48.4156464, lng: 18.849369 },
    { lat: 48.4158631, lng: 18.849609 },
    { lat: 48.4161751, lng: 18.8500363 },
    { lat: 48.4163496, lng: 18.8503749 },
    { lat: 48.4165149, lng: 18.8506487 },
    { lat: 48.4165035, lng: 18.8506657 },
    { lat: 48.4164363, lng: 18.8516064 },
    { lat: 48.4164336, lng: 18.8516642 },
    { lat: 48.4164189, lng: 18.8518577 },
    { lat: 48.4164205, lng: 18.8519299 },
    { lat: 48.4164204, lng: 18.8519945 },
    { lat: 48.416422, lng: 18.8521118 },
    { lat: 48.4164215, lng: 18.8521435 },
    { lat: 48.4164221, lng: 18.8521771 },
    { lat: 48.416422, lng: 18.8522208 },
    { lat: 48.4164987, lng: 18.8523058 },
    { lat: 48.4163678, lng: 18.852563 },
    { lat: 48.4161706, lng: 18.8526965 },
    { lat: 48.4158713, lng: 18.8528166 },
    { lat: 48.4157961, lng: 18.8530377 },
    { lat: 48.4157823, lng: 18.8530585 },
    { lat: 48.4156275, lng: 18.8532669 },
    { lat: 48.4153265, lng: 18.8535253 },
    { lat: 48.4151373, lng: 18.8537715 },
    { lat: 48.4150428, lng: 18.8539043 },
    { lat: 48.4148538, lng: 18.8538918 },
    { lat: 48.4147569, lng: 18.8537406 },
    { lat: 48.4146044, lng: 18.8537166 },
    { lat: 48.4144998, lng: 18.8535678 },
    { lat: 48.4144793, lng: 18.8533476 },
    { lat: 48.4142919, lng: 18.8533022 },
    { lat: 48.4141024, lng: 18.8530619 },
    { lat: 48.4136437, lng: 18.8528702 },
    { lat: 48.4134242, lng: 18.8529034 },
    { lat: 48.4131769, lng: 18.8530227 },
    { lat: 48.4130412, lng: 18.8534465 },
    { lat: 48.4125983, lng: 18.8535869 },
    { lat: 48.4125463, lng: 18.8541428 },
    { lat: 48.4125905, lng: 18.8544755 },
    { lat: 48.4121053, lng: 18.8550313 },
    { lat: 48.4123295, lng: 18.8563913 },
    { lat: 48.4123554, lng: 18.8564344 },
    { lat: 48.4125481, lng: 18.8568178 },
    { lat: 48.4125885, lng: 18.8569681 },
    { lat: 48.4125898, lng: 18.8569921 },
    { lat: 48.412625, lng: 18.8574505 },
    { lat: 48.4124916, lng: 18.8578346 },
    { lat: 48.4124681, lng: 18.8579037 },
    { lat: 48.4124347, lng: 18.8579978 },
    { lat: 48.4124348, lng: 18.8580226 },
    { lat: 48.4124474, lng: 18.858654 },
    { lat: 48.4124313, lng: 18.8586914 },
    { lat: 48.4123616, lng: 18.858827 },
    { lat: 48.412348, lng: 18.858846 },
    { lat: 48.4123283, lng: 18.8589228 },
    { lat: 48.4124451, lng: 18.8589329 },
    { lat: 48.4130057, lng: 18.8588713 },
    { lat: 48.4131033, lng: 18.8587754 },
    { lat: 48.413181, lng: 18.8586448 },
    { lat: 48.4133034, lng: 18.8583526 },
    { lat: 48.4133987, lng: 18.8581458 },
    { lat: 48.4135465, lng: 18.85793 },
    { lat: 48.4140382, lng: 18.8576944 },
    { lat: 48.4143744, lng: 18.8576336 },
    { lat: 48.4147672, lng: 18.857531 },
    { lat: 48.4150372, lng: 18.857457 },
    { lat: 48.4151647, lng: 18.857501 },
    { lat: 48.4153054, lng: 18.8576481 },
    { lat: 48.4155312, lng: 18.8581714 },
    { lat: 48.4155874, lng: 18.858448 },
    { lat: 48.4157719, lng: 18.8586152 },
    { lat: 48.4168125, lng: 18.8592597 },
    { lat: 48.4174725, lng: 18.8596589 },
    { lat: 48.4176367, lng: 18.8597134 },
    { lat: 48.4178725, lng: 18.8597816 },
    { lat: 48.4178648, lng: 18.8598286 },
    { lat: 48.4178546, lng: 18.8598724 },
    { lat: 48.4179691, lng: 18.8599474 },
    { lat: 48.4183162, lng: 18.860138 },
    { lat: 48.4183541, lng: 18.8601542 },
    { lat: 48.4183957, lng: 18.8601716 },
    { lat: 48.4185824, lng: 18.8602505 },
    { lat: 48.4188822, lng: 18.8605717 },
    { lat: 48.4192286, lng: 18.8608122 },
    { lat: 48.4198032, lng: 18.8611746 },
    { lat: 48.4211227, lng: 18.8621517 },
    { lat: 48.4211485, lng: 18.8621707 },
    { lat: 48.4211721, lng: 18.8621881 },
    { lat: 48.4212026, lng: 18.8622105 },
    { lat: 48.4217211, lng: 18.8627932 },
    { lat: 48.4220919, lng: 18.8633532 },
    { lat: 48.4231821, lng: 18.8649971 },
    { lat: 48.4234378, lng: 18.8650348 },
    { lat: 48.423969, lng: 18.8657539 },
    { lat: 48.424268, lng: 18.86606 },
    { lat: 48.4246016, lng: 18.8663805 },
    { lat: 48.4252221, lng: 18.8669765 },
    { lat: 48.4260458, lng: 18.8677671 },
    { lat: 48.4261949, lng: 18.8676001 },
    { lat: 48.4263435, lng: 18.8678983 },
    { lat: 48.4270513, lng: 18.8680879 },
    { lat: 48.4278414, lng: 18.8682732 },
    { lat: 48.4283793, lng: 18.8683159 },
    { lat: 48.4289845, lng: 18.8681583 },
    { lat: 48.429168, lng: 18.8682202 },
    { lat: 48.4291887, lng: 18.868227 },
    { lat: 48.4292209, lng: 18.8682385 },
    { lat: 48.4293792, lng: 18.868292 },
    { lat: 48.43068, lng: 18.868824 },
    { lat: 48.4309792, lng: 18.8689401 },
    { lat: 48.4314973, lng: 18.869451 },
    { lat: 48.4320507, lng: 18.869989 },
    { lat: 48.4322824, lng: 18.8702136 },
    { lat: 48.4323122, lng: 18.8702466 },
    { lat: 48.4325049, lng: 18.8703071 },
    { lat: 48.4326784, lng: 18.8704995 },
    { lat: 48.4327225, lng: 18.870549 },
    { lat: 48.4335312, lng: 18.8710507 },
    { lat: 48.4340233, lng: 18.8714087 },
    { lat: 48.4340711, lng: 18.8715235 },
    { lat: 48.4342001, lng: 18.8718372 },
    { lat: 48.4342336, lng: 18.8719195 },
    { lat: 48.4342593, lng: 18.871978 },
    { lat: 48.4344487, lng: 18.872411 },
    { lat: 48.4346726, lng: 18.8729252 },
    { lat: 48.4351782, lng: 18.8732142 },
    { lat: 48.4352906, lng: 18.873946 },
    { lat: 48.4353737, lng: 18.8741494 },
    { lat: 48.4355553, lng: 18.8745936 },
    { lat: 48.4355211, lng: 18.8749538 },
    { lat: 48.435487, lng: 18.8754034 },
    { lat: 48.435521, lng: 18.8753852 },
    { lat: 48.435557, lng: 18.8753863 },
    { lat: 48.4356246, lng: 18.8753816 },
    { lat: 48.4357168, lng: 18.8753793 },
    { lat: 48.4358578, lng: 18.875382 },
    { lat: 48.4359626, lng: 18.8753711 },
    { lat: 48.4360216, lng: 18.8753649 },
    { lat: 48.4361559, lng: 18.8753431 },
    { lat: 48.4361926, lng: 18.8753371 },
    { lat: 48.4362107, lng: 18.8753342 },
    { lat: 48.4362228, lng: 18.8753307 },
    { lat: 48.4364584, lng: 18.8752622 },
    { lat: 48.436464, lng: 18.8752619 },
    { lat: 48.4364852, lng: 18.8752605 },
    { lat: 48.4365045, lng: 18.8752593 },
    { lat: 48.4365944, lng: 18.8751881 },
    { lat: 48.4370916, lng: 18.8747967 },
    { lat: 48.4376588, lng: 18.8743854 },
    { lat: 48.4383685, lng: 18.8739137 },
    { lat: 48.438376, lng: 18.8739086 },
    { lat: 48.4386468, lng: 18.873804 },
    { lat: 48.4388436, lng: 18.8736844 },
    { lat: 48.4388852, lng: 18.8736589 },
    { lat: 48.4389003, lng: 18.8736496 },
    { lat: 48.4389693, lng: 18.8736075 },
    { lat: 48.4389824, lng: 18.8736003 },
    { lat: 48.439001, lng: 18.8736315 },
    { lat: 48.4390514, lng: 18.8737108 },
    { lat: 48.4390785, lng: 18.8737534 },
    { lat: 48.4390955, lng: 18.8737812 },
    { lat: 48.4391065, lng: 18.873797 },
    { lat: 48.439066, lng: 18.8738408 },
    { lat: 48.4390329, lng: 18.8738767 },
    { lat: 48.4387074, lng: 18.8742305 },
    { lat: 48.4383644, lng: 18.8745314 },
    { lat: 48.4379755, lng: 18.8747289 },
    { lat: 48.4377634, lng: 18.8749903 },
    { lat: 48.4377297, lng: 18.8750323 },
    { lat: 48.4377083, lng: 18.8750578 },
    { lat: 48.4377154, lng: 18.8751074 },
    { lat: 48.4377247, lng: 18.8751774 },
    { lat: 48.4377998, lng: 18.8757542 },
    { lat: 48.4378017, lng: 18.8757678 },
    { lat: 48.4378794, lng: 18.8760037 },
    { lat: 48.4380369, lng: 18.8764767 },
    { lat: 48.4382255, lng: 18.8770469 },
    { lat: 48.4382544, lng: 18.8771707 },
    { lat: 48.438309, lng: 18.877485 },
    { lat: 48.4383259, lng: 18.8775638 },
    { lat: 48.4383417, lng: 18.8776377 },
    { lat: 48.4383795, lng: 18.8777785 },
    { lat: 48.4384161, lng: 18.8779202 },
    { lat: 48.4384169, lng: 18.878021 },
    { lat: 48.4384217, lng: 18.8781134 },
    { lat: 48.438384, lng: 18.8782578 },
    { lat: 48.4383587, lng: 18.8783122 },
    { lat: 48.4382118, lng: 18.8785002 },
    { lat: 48.4384906, lng: 18.8787904 },
    { lat: 48.4386238, lng: 18.8790997 },
    { lat: 48.4386561, lng: 18.8791765 },
    { lat: 48.4386753, lng: 18.8792222 },
    { lat: 48.4388074, lng: 18.8795274 },
    { lat: 48.4390104, lng: 18.8794657 },
    { lat: 48.4391741, lng: 18.8795779 },
    { lat: 48.4392454, lng: 18.8796274 },
    { lat: 48.439361, lng: 18.879747 },
    { lat: 48.4395571, lng: 18.8797639 },
    { lat: 48.4397201, lng: 18.8797778 },
    { lat: 48.4397852, lng: 18.8797932 },
    { lat: 48.4398173, lng: 18.8798026 },
    { lat: 48.4398888, lng: 18.8798134 },
    { lat: 48.439915, lng: 18.8798185 },
    { lat: 48.4399096, lng: 18.8800372 },
    { lat: 48.4399091, lng: 18.8800721 },
    { lat: 48.4399047, lng: 18.8802065 },
    { lat: 48.4399776, lng: 18.8803918 },
    { lat: 48.4401025, lng: 18.8805406 },
    { lat: 48.4402725, lng: 18.8806251 },
    { lat: 48.4404697, lng: 18.8806865 },
    { lat: 48.4407458, lng: 18.8807713 },
    { lat: 48.4409262, lng: 18.8808543 },
    { lat: 48.4411456, lng: 18.8810204 },
    { lat: 48.4413132, lng: 18.8811872 },
    { lat: 48.4415436, lng: 18.8815846 },
    { lat: 48.441628, lng: 18.8819085 },
    { lat: 48.4416252, lng: 18.8820278 },
    { lat: 48.4416242, lng: 18.8820947 },
    { lat: 48.4416082, lng: 18.8828303 },
    { lat: 48.4416589, lng: 18.8832019 },
    { lat: 48.4417647, lng: 18.8833695 },
    { lat: 48.4418873, lng: 18.8834333 },
    { lat: 48.4421947, lng: 18.8834463 },
    { lat: 48.4424807, lng: 18.8833713 },
    { lat: 48.442766, lng: 18.8832399 },
    { lat: 48.4432974, lng: 18.8827664 },
    { lat: 48.4435222, lng: 18.8825348 },
    { lat: 48.4438234, lng: 18.8825838 },
    { lat: 48.4439435, lng: 18.8828783 },
    { lat: 48.4440685, lng: 18.8834621 },
    { lat: 48.4442135, lng: 18.8840159 },
    { lat: 48.4444448, lng: 18.8849364 },
    { lat: 48.4445681, lng: 18.88527 },
    { lat: 48.4448446, lng: 18.8858631 },
    { lat: 48.4450873, lng: 18.8865219 },
    { lat: 48.4452597, lng: 18.8869521 },
    { lat: 48.446246, lng: 18.8879934 },
    { lat: 48.4464572, lng: 18.8878476 },
    { lat: 48.4467412, lng: 18.8878421 },
    { lat: 48.4472867, lng: 18.8878415 },
    { lat: 48.4475451, lng: 18.887844 },
    { lat: 48.4478533, lng: 18.8878495 },
    { lat: 48.448849, lng: 18.8877 },
    { lat: 48.4491653, lng: 18.8876892 },
    { lat: 48.4495549, lng: 18.8877651 },
    { lat: 48.4498332, lng: 18.8877152 },
    { lat: 48.4501004, lng: 18.8874115 },
    { lat: 48.4502392, lng: 18.8871317 },
    { lat: 48.4502875, lng: 18.8868003 },
    { lat: 48.4503363, lng: 18.8862185 },
    { lat: 48.4503168, lng: 18.8853406 },
    { lat: 48.4503555, lng: 18.8849777 },
    { lat: 48.4504167, lng: 18.884459 },
    { lat: 48.4504526, lng: 18.8840197 },
    { lat: 48.450608, lng: 18.8822124 },
    { lat: 48.4506756, lng: 18.8819487 },
    { lat: 48.4506343, lng: 18.8816524 },
    { lat: 48.4505692, lng: 18.8813538 },
    { lat: 48.4504188, lng: 18.881061 },
    { lat: 48.4503076, lng: 18.8807653 },
    { lat: 48.4503224, lng: 18.8804397 },
    { lat: 48.4501442, lng: 18.8800471 },
    { lat: 48.4496222, lng: 18.8797799 },
    { lat: 48.4492196, lng: 18.8795739 },
    { lat: 48.4497736, lng: 18.8790785 },
    { lat: 48.4502046, lng: 18.878692 },
    { lat: 48.4502229, lng: 18.8786757 },
    { lat: 48.4502301, lng: 18.8785967 },
    { lat: 48.4505652, lng: 18.8786199 },
    { lat: 48.4509625, lng: 18.878648 },
    { lat: 48.4515415, lng: 18.8788558 },
    { lat: 48.4518181, lng: 18.8789733 },
    { lat: 48.4519542, lng: 18.8790088 },
    { lat: 48.4522014, lng: 18.8789284 },
    { lat: 48.4526536, lng: 18.8787123 },
    { lat: 48.4528496, lng: 18.8786183 },
    { lat: 48.4530618, lng: 18.8786233 },
    { lat: 48.4531967, lng: 18.8787474 },
    { lat: 48.4533006, lng: 18.8790092 },
    { lat: 48.4532971, lng: 18.8792706 },
    { lat: 48.4532132, lng: 18.8795213 },
    { lat: 48.4537765, lng: 18.8792063 },
    { lat: 48.4542073, lng: 18.8783814 },
    { lat: 48.4543364, lng: 18.8781336 },
    { lat: 48.4547071, lng: 18.87806 },
    { lat: 48.4549197, lng: 18.8783314 },
    { lat: 48.4553016, lng: 18.8777179 },
    { lat: 48.4557065, lng: 18.8768278 },
    { lat: 48.4560278, lng: 18.8758697 },
    { lat: 48.4560589, lng: 18.8749474 },
    { lat: 48.456233, lng: 18.8740446 },
    { lat: 48.45645, lng: 18.872288 },
    { lat: 48.456114, lng: 18.873183 },
    { lat: 48.455595, lng: 18.873069 },
    { lat: 48.455517, lng: 18.872319 },
    { lat: 48.455261, lng: 18.872462 },
    { lat: 48.455115, lng: 18.872027 },
    { lat: 48.452463, lng: 18.869914 },
    { lat: 48.452192, lng: 18.869828 },
    { lat: 48.452003, lng: 18.869415 },
    { lat: 48.4515533, lng: 18.8691468 },
    { lat: 48.450735, lng: 18.868469 },
    { lat: 48.45053, lng: 18.869585 },
    { lat: 48.451007, lng: 18.870317 },
    { lat: 48.450816, lng: 18.871552 },
    { lat: 48.450627, lng: 18.87158 },
    { lat: 48.450509, lng: 18.871324 },
    { lat: 48.450349, lng: 18.871742 },
    { lat: 48.450035, lng: 18.871285 },
    { lat: 48.44949, lng: 18.871335 },
    { lat: 48.449452, lng: 18.871339 },
    { lat: 48.449327, lng: 18.87135 },
    { lat: 48.449033, lng: 18.87077 },
    { lat: 48.448623, lng: 18.870075 },
    { lat: 48.44842, lng: 18.86963 },
    { lat: 48.448379, lng: 18.869307 },
    { lat: 48.447999, lng: 18.86822 },
    { lat: 48.447969, lng: 18.867467 },
    { lat: 48.447964, lng: 18.867324 },
    { lat: 48.447959, lng: 18.86721 },
    { lat: 48.447973, lng: 18.866526 },
    { lat: 48.448091, lng: 18.866143 },
    { lat: 48.448223, lng: 18.865922 },
    { lat: 48.448243, lng: 18.865886 },
    { lat: 48.4482954, lng: 18.8658076 },
    { lat: 48.448405, lng: 18.865674 },
    { lat: 48.448563, lng: 18.865407 },
    { lat: 48.448762, lng: 18.865229 },
    { lat: 48.448943, lng: 18.865125 },
    { lat: 48.449086, lng: 18.865012 },
    { lat: 48.449299, lng: 18.864762 },
    { lat: 48.449535, lng: 18.864619 },
    { lat: 48.44968, lng: 18.864572 },
    { lat: 48.449946, lng: 18.864556 },
    { lat: 48.450161, lng: 18.864523 },
    { lat: 48.450544, lng: 18.864353 },
    { lat: 48.450766, lng: 18.864064 },
    { lat: 48.4508092, lng: 18.8640422 },
    { lat: 48.450893, lng: 18.864 },
    { lat: 48.450937, lng: 18.86398 },
    { lat: 48.450952, lng: 18.863894 },
    { lat: 48.45064, lng: 18.863838 },
    { lat: 48.450547, lng: 18.863762 },
    { lat: 48.450164, lng: 18.863462 },
    { lat: 48.449967, lng: 18.863235 },
    { lat: 48.449731, lng: 18.863267 },
    { lat: 48.449583, lng: 18.863243 },
    { lat: 48.449451, lng: 18.86307 },
    { lat: 48.449432, lng: 18.862813 },
    { lat: 48.449452, lng: 18.862084 },
    { lat: 48.449616, lng: 18.861621 },
    { lat: 48.449553, lng: 18.861348 },
    { lat: 48.449433, lng: 18.861195 },
    { lat: 48.44917, lng: 18.861138 },
    { lat: 48.44905, lng: 18.861079 },
    { lat: 48.44895, lng: 18.86103 },
    { lat: 48.448747, lng: 18.861015 },
    { lat: 48.448561, lng: 18.861163 },
    { lat: 48.448397, lng: 18.861374 },
    { lat: 48.448212, lng: 18.861709 },
    { lat: 48.448051, lng: 18.861842 },
    { lat: 48.44788, lng: 18.861834 },
    { lat: 48.447575, lng: 18.861709 },
    { lat: 48.44718, lng: 18.86173 },
    { lat: 48.446892, lng: 18.8617 },
    { lat: 48.4467, lng: 18.861717 },
    { lat: 48.446601, lng: 18.86176 },
    { lat: 48.446369, lng: 18.861861 },
    { lat: 48.445979, lng: 18.862163 },
    { lat: 48.445681, lng: 18.862336 },
    { lat: 48.445454, lng: 18.862534 },
    { lat: 48.44511, lng: 18.862838 },
    { lat: 48.445114, lng: 18.862784 },
    { lat: 48.445144, lng: 18.862373 },
    { lat: 48.445293, lng: 18.860938 },
    { lat: 48.445206, lng: 18.860125 },
    { lat: 48.445176, lng: 18.859976 },
    { lat: 48.444525, lng: 18.858182 },
    { lat: 48.444292, lng: 18.857508 },
    { lat: 48.444234, lng: 18.857344 },
    { lat: 48.444102, lng: 18.856038 },
    { lat: 48.444275, lng: 18.854964 },
    { lat: 48.444251, lng: 18.854935 },
    { lat: 48.443945, lng: 18.854568 },
    { lat: 48.443634, lng: 18.853973 },
    { lat: 48.443518, lng: 18.85375 },
    { lat: 48.443099, lng: 18.853201 },
    { lat: 48.442852, lng: 18.852877 },
    { lat: 48.442732, lng: 18.852467 },
    { lat: 48.443285, lng: 18.851816 },
    { lat: 48.4436375, lng: 18.8516603 },
    { lat: 48.443344, lng: 18.851053 },
    { lat: 48.442698, lng: 18.850024 },
    { lat: 48.442566, lng: 18.849894 },
    { lat: 48.442208, lng: 18.849543 },
    { lat: 48.441936, lng: 18.849679 },
    { lat: 48.441847, lng: 18.8496559 },
    { lat: 48.441678, lng: 18.849612 },
    { lat: 48.441634, lng: 18.8496 },
    { lat: 48.441289, lng: 18.849791 },
    { lat: 48.441117, lng: 18.849841 },
    { lat: 48.440972, lng: 18.849702 },
    { lat: 48.440924, lng: 18.849615 },
    { lat: 48.440807, lng: 18.849409 },
    { lat: 48.440546, lng: 18.849482 },
    { lat: 48.44043, lng: 18.849465 },
    { lat: 48.440345, lng: 18.849392 },
    { lat: 48.440113, lng: 18.849193 },
    { lat: 48.440055, lng: 18.849119 },
    { lat: 48.439935, lng: 18.848595 },
    { lat: 48.439873, lng: 18.848451 },
    { lat: 48.439732, lng: 18.848117 },
    { lat: 48.439686, lng: 18.848088 },
    { lat: 48.439628, lng: 18.848051 },
    { lat: 48.439582, lng: 18.847522 },
    { lat: 48.439296, lng: 18.846807 },
    { lat: 48.438816, lng: 18.847003 },
    { lat: 48.438664, lng: 18.847064 },
    { lat: 48.438462, lng: 18.846752 },
    { lat: 48.438358, lng: 18.846593 },
    { lat: 48.438336, lng: 18.846458 },
    { lat: 48.437923, lng: 18.845762 },
    { lat: 48.43811, lng: 18.84538 },
    { lat: 48.437885, lng: 18.845053 },
    { lat: 48.437521, lng: 18.844769 },
    { lat: 48.437212, lng: 18.844435 },
    { lat: 48.437068, lng: 18.844621 },
    { lat: 48.43705, lng: 18.844586 },
    { lat: 48.436964, lng: 18.844418 },
    { lat: 48.436637, lng: 18.844015 },
    { lat: 48.436251, lng: 18.843665 },
    { lat: 48.436202, lng: 18.843364 },
    { lat: 48.436134, lng: 18.843053 },
    { lat: 48.436114, lng: 18.843058 },
    { lat: 48.436131, lng: 18.842162 },
    { lat: 48.436322, lng: 18.841005 },
    { lat: 48.436552, lng: 18.840484 },
    { lat: 48.436569, lng: 18.840415 },
    { lat: 48.436704, lng: 18.839873 },
    { lat: 48.43668, lng: 18.839501 },
    { lat: 48.436366, lng: 18.838947 },
    { lat: 48.436112, lng: 18.838342 },
    { lat: 48.435984, lng: 18.837653 },
    { lat: 48.436026, lng: 18.837308 },
    { lat: 48.436085, lng: 18.837142 },
    { lat: 48.436168, lng: 18.83691 },
    { lat: 48.436358, lng: 18.836531 },
    { lat: 48.436381, lng: 18.836554 },
    { lat: 48.436532, lng: 18.836242 },
    { lat: 48.436666, lng: 18.83607 },
    { lat: 48.436988, lng: 18.835662 },
    { lat: 48.437092, lng: 18.835437 },
    { lat: 48.437136, lng: 18.835063 },
    { lat: 48.437217, lng: 18.834549 },
    { lat: 48.437352, lng: 18.833646 },
    { lat: 48.437568, lng: 18.832972 },
    { lat: 48.437621, lng: 18.833032 },
    { lat: 48.438096, lng: 18.832766 },
    { lat: 48.438504, lng: 18.832572 },
    { lat: 48.439013, lng: 18.831729 },
    { lat: 48.439203, lng: 18.830808 },
    { lat: 48.438724, lng: 18.829915 },
    { lat: 48.438409, lng: 18.828921 },
    { lat: 48.438124, lng: 18.827799 },
    { lat: 48.438264, lng: 18.826817 },
    { lat: 48.43834, lng: 18.826584 },
    { lat: 48.438299, lng: 18.825896 },
    { lat: 48.438182, lng: 18.824797 },
    { lat: 48.438357, lng: 18.824233 },
    { lat: 48.438367, lng: 18.823269 },
    { lat: 48.438352, lng: 18.822637 },
    { lat: 48.438288, lng: 18.821968 },
    { lat: 48.43832, lng: 18.821243 },
    { lat: 48.438282, lng: 18.820821 },
    { lat: 48.438403, lng: 18.820439 },
    { lat: 48.438696, lng: 18.819819 },
    { lat: 48.438847, lng: 18.819256 },
    { lat: 48.438801, lng: 18.818886 },
    { lat: 48.43878, lng: 18.818489 },
    { lat: 48.438845, lng: 18.81814 },
    { lat: 48.438877, lng: 18.817393 },
    { lat: 48.4388996, lng: 18.8171356 },
    { lat: 48.438908, lng: 18.817038 },
    { lat: 48.438921, lng: 18.817018 },
    { lat: 48.438916, lng: 18.817008 },
    { lat: 48.438856, lng: 18.817093 },
    { lat: 48.438699, lng: 18.817272 },
    { lat: 48.4386611, lng: 18.8173449 },
    { lat: 48.438482, lng: 18.817584 },
    { lat: 48.438199, lng: 18.817972 },
    { lat: 48.4381443, lng: 18.8181049 },
    { lat: 48.4380976, lng: 18.8181801 },
    { lat: 48.438087, lng: 18.8182045 },
    { lat: 48.438, lng: 18.818338 },
    { lat: 48.437761, lng: 18.818576 },
    { lat: 48.437664, lng: 18.818818 },
    { lat: 48.437554, lng: 18.819171 },
    { lat: 48.437367, lng: 18.819275 },
    { lat: 48.437258, lng: 18.819322 },
    { lat: 48.437016, lng: 18.819338 },
    { lat: 48.436719, lng: 18.8193 },
    { lat: 48.436366, lng: 18.819209 },
    { lat: 48.436201, lng: 18.819162 },
    { lat: 48.435893, lng: 18.819221 },
    { lat: 48.435679, lng: 18.819248 },
    { lat: 48.43553, lng: 18.819668 },
    { lat: 48.4353744, lng: 18.8201034 },
    { lat: 48.435325, lng: 18.820225 },
    { lat: 48.435002, lng: 18.821245 },
    { lat: 48.434812, lng: 18.821752 },
    { lat: 48.4347493, lng: 18.8218457 },
    { lat: 48.434546, lng: 18.82215 },
    { lat: 48.434261, lng: 18.822797 },
    { lat: 48.433941, lng: 18.823605 },
    { lat: 48.433844, lng: 18.824035 },
    { lat: 48.433657, lng: 18.824167 },
    { lat: 48.433472, lng: 18.824406 },
    { lat: 48.433334, lng: 18.824576 },
    { lat: 48.433174, lng: 18.824594 },
    { lat: 48.432961, lng: 18.824901 },
    { lat: 48.432662, lng: 18.825243 },
    { lat: 48.432428, lng: 18.825528 },
    { lat: 48.432363, lng: 18.82601 },
    { lat: 48.432305, lng: 18.826427 },
    { lat: 48.432244, lng: 18.826744 },
    { lat: 48.432102, lng: 18.827126 },
    { lat: 48.431906, lng: 18.82743 },
    { lat: 48.431783, lng: 18.827671 },
    { lat: 48.431236, lng: 18.82804 },
    { lat: 48.430928, lng: 18.828209 },
    { lat: 48.430739, lng: 18.828332 },
    { lat: 48.430446, lng: 18.828431 },
    { lat: 48.430193, lng: 18.828354 },
    { lat: 48.429974, lng: 18.828389 },
    { lat: 48.429856, lng: 18.828408 },
    { lat: 48.429574, lng: 18.82843 },
    { lat: 48.42933, lng: 18.828503 },
    { lat: 48.42926, lng: 18.828584 },
    { lat: 48.42924, lng: 18.828603 },
    { lat: 48.428989, lng: 18.828886 },
    { lat: 48.428785, lng: 18.829074 },
    { lat: 48.428405, lng: 18.829424 },
    { lat: 48.428177, lng: 18.829611 },
    { lat: 48.428123, lng: 18.829663 },
    { lat: 48.42796, lng: 18.829881 },
    { lat: 48.42773, lng: 18.829818 },
    { lat: 48.427627, lng: 18.829672 },
    { lat: 48.427389, lng: 18.829289 },
    { lat: 48.427099, lng: 18.829273 },
    { lat: 48.42682, lng: 18.829218 },
    { lat: 48.426596, lng: 18.829168 },
    { lat: 48.426464, lng: 18.82908 },
    { lat: 48.426342, lng: 18.829077 },
    { lat: 48.42618, lng: 18.828986 },
    { lat: 48.426141, lng: 18.828888 },
    { lat: 48.425959, lng: 18.828856 },
    { lat: 48.425843, lng: 18.828782 },
    { lat: 48.42573, lng: 18.828763 },
    { lat: 48.425611, lng: 18.828595 },
    { lat: 48.425498, lng: 18.828508 },
    { lat: 48.425383, lng: 18.828445 },
    { lat: 48.425209, lng: 18.828266 },
    { lat: 48.424983, lng: 18.827868 },
    { lat: 48.424894, lng: 18.827696 },
    { lat: 48.424774, lng: 18.827532 },
    { lat: 48.424702, lng: 18.827434 },
    { lat: 48.4246, lng: 18.827285 },
    { lat: 48.424572, lng: 18.82719 },
    { lat: 48.424579, lng: 18.826987 },
    { lat: 48.424571, lng: 18.826797 },
    { lat: 48.424579, lng: 18.826482 },
    { lat: 48.424573, lng: 18.82623 },
    { lat: 48.424556, lng: 18.826102 },
    { lat: 48.424596, lng: 18.825858 },
    { lat: 48.424524, lng: 18.825626 },
    { lat: 48.42447, lng: 18.825393 },
    { lat: 48.424355, lng: 18.825346 },
    { lat: 48.424275, lng: 18.825159 },
    { lat: 48.424205, lng: 18.824959 },
    { lat: 48.424111, lng: 18.8249 },
    { lat: 48.424084, lng: 18.824837 },
    { lat: 48.424036, lng: 18.824792 },
    { lat: 48.424033, lng: 18.824693 },
    { lat: 48.424006, lng: 18.82461 },
    { lat: 48.424007, lng: 18.824535 },
    { lat: 48.423966, lng: 18.824422 },
    { lat: 48.423923, lng: 18.824277 },
    { lat: 48.423865, lng: 18.824037 },
    { lat: 48.423845, lng: 18.823878 },
    { lat: 48.423798, lng: 18.823732 },
    { lat: 48.423816, lng: 18.8236 },
    { lat: 48.423781, lng: 18.82347 },
    { lat: 48.423778, lng: 18.823287 },
    { lat: 48.423746, lng: 18.823102 },
    { lat: 48.423647, lng: 18.822911 },
    { lat: 48.423566, lng: 18.822795 },
    { lat: 48.42344, lng: 18.822684 },
    { lat: 48.423252, lng: 18.822646 },
    { lat: 48.42304, lng: 18.822467 },
    { lat: 48.422888, lng: 18.822288 },
    { lat: 48.422744, lng: 18.822009 },
    { lat: 48.422696, lng: 18.8219 },
    { lat: 48.422668, lng: 18.821684 },
    { lat: 48.422636, lng: 18.821484 },
    { lat: 48.422725, lng: 18.821076 },
    { lat: 48.422752, lng: 18.820832 },
    { lat: 48.422789, lng: 18.820672 },
    { lat: 48.422785, lng: 18.820482 },
    { lat: 48.422787, lng: 18.820363 },
    { lat: 48.422791, lng: 18.820182 },
    { lat: 48.422802, lng: 18.820015 },
    { lat: 48.422806, lng: 18.819885 },
    { lat: 48.422834, lng: 18.819778 },
    { lat: 48.422821, lng: 18.819678 },
    { lat: 48.422843, lng: 18.819564 },
    { lat: 48.422881, lng: 18.819324 },
    { lat: 48.42288, lng: 18.819181 },
    { lat: 48.422927, lng: 18.819052 },
    { lat: 48.422929, lng: 18.818906 },
    { lat: 48.42288, lng: 18.818705 },
    { lat: 48.422848, lng: 18.818519 },
    { lat: 48.422719, lng: 18.818399 },
    { lat: 48.422537, lng: 18.818339 },
    { lat: 48.422409, lng: 18.81825 },
    { lat: 48.422282, lng: 18.818191 },
    { lat: 48.422191, lng: 18.818086 },
    { lat: 48.421928, lng: 18.817818 },
    { lat: 48.421842, lng: 18.81769 },
    { lat: 48.421693, lng: 18.817618 },
    { lat: 48.421536, lng: 18.817593 },
    { lat: 48.421359, lng: 18.817636 },
    { lat: 48.421195, lng: 18.817635 },
    { lat: 48.421006, lng: 18.81761 },
    { lat: 48.420839, lng: 18.817615 },
    { lat: 48.420615, lng: 18.817594 },
    { lat: 48.420558, lng: 18.81752 },
    { lat: 48.420408, lng: 18.817406 },
    { lat: 48.420317, lng: 18.817275 },
    { lat: 48.420277, lng: 18.817078 },
  ],
  Ilija: [
    { lat: 48.4132708, lng: 18.8699513 },
    { lat: 48.4128553, lng: 18.8698527 },
    { lat: 48.4122768, lng: 18.8701803 },
    { lat: 48.4122267, lng: 18.8703016 },
    { lat: 48.4117673, lng: 18.8703551 },
    { lat: 48.4115584, lng: 18.8705474 },
    { lat: 48.4115392, lng: 18.8706327 },
    { lat: 48.4116241, lng: 18.8710285 },
    { lat: 48.4109876, lng: 18.8713902 },
    { lat: 48.410461, lng: 18.8710857 },
    { lat: 48.4102441, lng: 18.8707956 },
    { lat: 48.4101117, lng: 18.8707845 },
    { lat: 48.4099757, lng: 18.8708817 },
    { lat: 48.4098402, lng: 18.8709661 },
    { lat: 48.4095246, lng: 18.8711646 },
    { lat: 48.4092214, lng: 18.8714446 },
    { lat: 48.4091036, lng: 18.8715021 },
    { lat: 48.4086894, lng: 18.8713888 },
    { lat: 48.408193, lng: 18.8717313 },
    { lat: 48.4079016, lng: 18.8716114 },
    { lat: 48.4075539, lng: 18.8715815 },
    { lat: 48.4076792, lng: 18.8705415 },
    { lat: 48.4074153, lng: 18.8702385 },
    { lat: 48.4069976, lng: 18.8704255 },
    { lat: 48.4068602, lng: 18.8705364 },
    { lat: 48.4066091, lng: 18.8704796 },
    { lat: 48.4063241, lng: 18.8703111 },
    { lat: 48.4060481, lng: 18.8699463 },
    { lat: 48.4059436, lng: 18.8681721 },
    { lat: 48.4053878, lng: 18.8680027 },
    { lat: 48.4047792, lng: 18.867706 },
    { lat: 48.4046216, lng: 18.8672564 },
    { lat: 48.4044467, lng: 18.866691 },
    { lat: 48.404072, lng: 18.8662701 },
    { lat: 48.4037152, lng: 18.8652196 },
    { lat: 48.4035255, lng: 18.8650442 },
    { lat: 48.4027089, lng: 18.8648866 },
    { lat: 48.4020676, lng: 18.8647628 },
    { lat: 48.4015116, lng: 18.8642184 },
    { lat: 48.4009245, lng: 18.864228 },
    { lat: 48.4001001, lng: 18.8646608 },
    { lat: 48.3989395, lng: 18.8648488 },
    { lat: 48.3988601, lng: 18.8648616 },
    { lat: 48.3987987, lng: 18.8648717 },
    { lat: 48.398885, lng: 18.8658803 },
    { lat: 48.3986936, lng: 18.8661909 },
    { lat: 48.3985531, lng: 18.8663099 },
    { lat: 48.3985365, lng: 18.8663811 },
    { lat: 48.3987664, lng: 18.8676347 },
    { lat: 48.3987627, lng: 18.8676724 },
    { lat: 48.3984429, lng: 18.8679398 },
    { lat: 48.3980855, lng: 18.8683316 },
    { lat: 48.3979983, lng: 18.8685182 },
    { lat: 48.3978182, lng: 18.8691759 },
    { lat: 48.3976737, lng: 18.8693241 },
    { lat: 48.3973494, lng: 18.8699401 },
    { lat: 48.3972735, lng: 18.8703269 },
    { lat: 48.3972409, lng: 18.8706316 },
    { lat: 48.3972454, lng: 18.8707809 },
    { lat: 48.3972023, lng: 18.8711709 },
    { lat: 48.3971671, lng: 18.8712059 },
    { lat: 48.3971623, lng: 18.8712689 },
    { lat: 48.3970969, lng: 18.8713511 },
    { lat: 48.3967745, lng: 18.8719413 },
    { lat: 48.3965905, lng: 18.8723518 },
    { lat: 48.3964832, lng: 18.8726521 },
    { lat: 48.3963908, lng: 18.8732143 },
    { lat: 48.396059, lng: 18.8739689 },
    { lat: 48.3959174, lng: 18.8746958 },
    { lat: 48.3957124, lng: 18.875307 },
    { lat: 48.3951435, lng: 18.8762511 },
    { lat: 48.3949274, lng: 18.876671 },
    { lat: 48.3944204, lng: 18.877375 },
    { lat: 48.3942135, lng: 18.8775624 },
    { lat: 48.3937105, lng: 18.8776916 },
    { lat: 48.3934298, lng: 18.8779739 },
    { lat: 48.393189, lng: 18.8790019 },
    { lat: 48.3927841, lng: 18.8806345 },
    { lat: 48.3925625, lng: 18.8815339 },
    { lat: 48.39244, lng: 18.8820648 },
    { lat: 48.3923854, lng: 18.8830565 },
    { lat: 48.3924174, lng: 18.8833241 },
    { lat: 48.3925027, lng: 18.8835547 },
    { lat: 48.3927667, lng: 18.8839936 },
    { lat: 48.392746, lng: 18.8842249 },
    { lat: 48.3925921, lng: 18.8846051 },
    { lat: 48.3926388, lng: 18.8851145 },
    { lat: 48.3925486, lng: 18.885616 },
    { lat: 48.3926094, lng: 18.886657 },
    { lat: 48.3927253, lng: 18.8868063 },
    { lat: 48.3928546, lng: 18.8869519 },
    { lat: 48.3931837, lng: 18.8871206 },
    { lat: 48.3934531, lng: 18.8871434 },
    { lat: 48.3937103, lng: 18.8874696 },
    { lat: 48.3943043, lng: 18.8875098 },
    { lat: 48.3946224, lng: 18.8877061 },
    { lat: 48.3948277, lng: 18.8880795 },
    { lat: 48.3953853, lng: 18.8884163 },
    { lat: 48.3956545, lng: 18.888778 },
    { lat: 48.3960538, lng: 18.8894584 },
    { lat: 48.3964637, lng: 18.8900761 },
    { lat: 48.3966522, lng: 18.890581 },
    { lat: 48.3970982, lng: 18.8912925 },
    { lat: 48.3975879, lng: 18.8918821 },
    { lat: 48.3976884, lng: 18.8921933 },
    { lat: 48.3982556, lng: 18.8929531 },
    { lat: 48.3983819, lng: 18.8931289 },
    { lat: 48.3984063, lng: 18.8937108 },
    { lat: 48.3983974, lng: 18.8939716 },
    { lat: 48.3984837, lng: 18.8942471 },
    { lat: 48.3985197, lng: 18.8945907 },
    { lat: 48.3984541, lng: 18.8948297 },
    { lat: 48.3982964, lng: 18.8952437 },
    { lat: 48.3982055, lng: 18.8954921 },
    { lat: 48.3981641, lng: 18.8961125 },
    { lat: 48.3982159, lng: 18.8965883 },
    { lat: 48.3984167, lng: 18.897309 },
    { lat: 48.3985665, lng: 18.8984827 },
    { lat: 48.3986141, lng: 18.8989019 },
    { lat: 48.3986695, lng: 18.8999084 },
    { lat: 48.3990206, lng: 18.9004384 },
    { lat: 48.3996317, lng: 18.9008969 },
    { lat: 48.399909, lng: 18.9011375 },
    { lat: 48.4000262, lng: 18.9013513 },
    { lat: 48.4000122, lng: 18.9016059 },
    { lat: 48.3998987, lng: 18.9020932 },
    { lat: 48.3999319, lng: 18.9024722 },
    { lat: 48.3999766, lng: 18.9027054 },
    { lat: 48.4000668, lng: 18.9028777 },
    { lat: 48.4004407, lng: 18.9031549 },
    { lat: 48.4005723, lng: 18.903346 },
    { lat: 48.4006703, lng: 18.9035589 },
    { lat: 48.4007709, lng: 18.9038704 },
    { lat: 48.4008643, lng: 18.9042825 },
    { lat: 48.4008944, lng: 18.9044727 },
    { lat: 48.4009029, lng: 18.9046144 },
    { lat: 48.4008831, lng: 18.9048197 },
    { lat: 48.4008679, lng: 18.9049432 },
    { lat: 48.4008238, lng: 18.905114 },
    { lat: 48.4006613, lng: 18.9056581 },
    { lat: 48.400461, lng: 18.9063411 },
    { lat: 48.4002566, lng: 18.9069462 },
    { lat: 48.4000609, lng: 18.9076119 },
    { lat: 48.3999798, lng: 18.9078765 },
    { lat: 48.3997794, lng: 18.9081653 },
    { lat: 48.3997154, lng: 18.9082802 },
    { lat: 48.3996631, lng: 18.9083782 },
    { lat: 48.3996887, lng: 18.9083937 },
    { lat: 48.3997896, lng: 18.9085178 },
    { lat: 48.3999495, lng: 18.9088186 },
    { lat: 48.4000358, lng: 18.9089381 },
    { lat: 48.4001683, lng: 18.9092995 },
    { lat: 48.4003106, lng: 18.9093688 },
    { lat: 48.4003535, lng: 18.9095862 },
    { lat: 48.4004632, lng: 18.9098581 },
    { lat: 48.4005092, lng: 18.9102679 },
    { lat: 48.4008266, lng: 18.9103881 },
    { lat: 48.4009234, lng: 18.9106667 },
    { lat: 48.4010336, lng: 18.9107281 },
    { lat: 48.4011973, lng: 18.9109063 },
    { lat: 48.4013931, lng: 18.9111603 },
    { lat: 48.4016903, lng: 18.9112829 },
    { lat: 48.401749, lng: 18.9113733 },
    { lat: 48.4018187, lng: 18.911477 },
    { lat: 48.4019549, lng: 18.9116907 },
    { lat: 48.402077, lng: 18.9119831 },
    { lat: 48.4021678, lng: 18.9122117 },
    { lat: 48.4022912, lng: 18.9125713 },
    { lat: 48.4024019, lng: 18.9129445 },
    { lat: 48.4025499, lng: 18.913148 },
    { lat: 48.4026039, lng: 18.9132773 },
    { lat: 48.4026116, lng: 18.9132952 },
    { lat: 48.4028917, lng: 18.913632 },
    { lat: 48.4030832, lng: 18.9139763 },
    { lat: 48.4031907, lng: 18.9140734 },
    { lat: 48.4032515, lng: 18.9141384 },
    { lat: 48.4033995, lng: 18.9149505 },
    { lat: 48.4035587, lng: 18.9153024 },
    { lat: 48.4036614, lng: 18.9158327 },
    { lat: 48.403605, lng: 18.9160724 },
    { lat: 48.4036255, lng: 18.9161854 },
    { lat: 48.4035248, lng: 18.9163969 },
    { lat: 48.4034793, lng: 18.9166311 },
    { lat: 48.4034549, lng: 18.916855 },
    { lat: 48.4034919, lng: 18.9171786 },
    { lat: 48.4035331, lng: 18.9174775 },
    { lat: 48.4035266, lng: 18.9177011 },
    { lat: 48.4036068, lng: 18.9182071 },
    { lat: 48.4036127, lng: 18.9183852 },
    { lat: 48.4037143, lng: 18.9188031 },
    { lat: 48.4038523, lng: 18.9190085 },
    { lat: 48.4038797, lng: 18.9191281 },
    { lat: 48.4039992, lng: 18.919214 },
    { lat: 48.4040279, lng: 18.919442 },
    { lat: 48.4040643, lng: 18.919711 },
    { lat: 48.4041332, lng: 18.9201086 },
    { lat: 48.4041515, lng: 18.9203311 },
    { lat: 48.4042319, lng: 18.9206852 },
    { lat: 48.4043174, lng: 18.9207501 },
    { lat: 48.4044665, lng: 18.9213907 },
    { lat: 48.4044113, lng: 18.9215678 },
    { lat: 48.4044486, lng: 18.9217405 },
    { lat: 48.404491, lng: 18.9218676 },
    { lat: 48.4044094, lng: 18.9220538 },
    { lat: 48.4044413, lng: 18.9224976 },
    { lat: 48.4046133, lng: 18.9227509 },
    { lat: 48.4046257, lng: 18.9227711 },
    { lat: 48.4045461, lng: 18.922979 },
    { lat: 48.4045766, lng: 18.9231114 },
    { lat: 48.4045862, lng: 18.9233385 },
    { lat: 48.4044901, lng: 18.9236211 },
    { lat: 48.4044575, lng: 18.92384 },
    { lat: 48.4043482, lng: 18.9240407 },
    { lat: 48.404371, lng: 18.9244369 },
    { lat: 48.4044843, lng: 18.9245363 },
    { lat: 48.4044754, lng: 18.9248897 },
    { lat: 48.4045845, lng: 18.9252156 },
    { lat: 48.4045438, lng: 18.9252778 },
    { lat: 48.4045548, lng: 18.9254039 },
    { lat: 48.4047003, lng: 18.9254271 },
    { lat: 48.404932, lng: 18.9256138 },
    { lat: 48.4051, lng: 18.9259172 },
    { lat: 48.4051161, lng: 18.9261237 },
    { lat: 48.4051764, lng: 18.9264833 },
    { lat: 48.4051175, lng: 18.9266732 },
    { lat: 48.4050933, lng: 18.9269283 },
    { lat: 48.4051623, lng: 18.9271084 },
    { lat: 48.405287, lng: 18.9273178 },
    { lat: 48.4052963, lng: 18.9274527 },
    { lat: 48.4053755, lng: 18.9276571 },
    { lat: 48.4055725, lng: 18.9277847 },
    { lat: 48.4056177, lng: 18.9278902 },
    { lat: 48.4056297, lng: 18.9280826 },
    { lat: 48.405627, lng: 18.928228 },
    { lat: 48.405581, lng: 18.9283629 },
    { lat: 48.4055458, lng: 18.9285543 },
    { lat: 48.4056774, lng: 18.9287257 },
    { lat: 48.4057896, lng: 18.9290417 },
    { lat: 48.4058704, lng: 18.9291242 },
    { lat: 48.4059306, lng: 18.9292421 },
    { lat: 48.4059882, lng: 18.9294842 },
    { lat: 48.406077, lng: 18.9294327 },
    { lat: 48.4061932, lng: 18.9293912 },
    { lat: 48.4062104, lng: 18.9293995 },
    { lat: 48.4063122, lng: 18.9295584 },
    { lat: 48.406346, lng: 18.9297986 },
    { lat: 48.4063796, lng: 18.9297947 },
    { lat: 48.4064018, lng: 18.9297858 },
    { lat: 48.4064632, lng: 18.9295547 },
    { lat: 48.4065512, lng: 18.9292489 },
    { lat: 48.4066871, lng: 18.9290425 },
    { lat: 48.4067431, lng: 18.92886 },
    { lat: 48.4067266, lng: 18.9287597 },
    { lat: 48.4066295, lng: 18.9286216 },
    { lat: 48.4066194, lng: 18.928605 },
    { lat: 48.4066164, lng: 18.9285 },
    { lat: 48.4067132, lng: 18.928169 },
    { lat: 48.4067385, lng: 18.9279389 },
    { lat: 48.4067049, lng: 18.9276994 },
    { lat: 48.4067772, lng: 18.9273315 },
    { lat: 48.4068753, lng: 18.927066 },
    { lat: 48.4069057, lng: 18.9266288 },
    { lat: 48.4070285, lng: 18.9263207 },
    { lat: 48.4071358, lng: 18.9253153 },
    { lat: 48.4072981, lng: 18.9251814 },
    { lat: 48.4073596, lng: 18.9249763 },
    { lat: 48.4073546, lng: 18.9248562 },
    { lat: 48.4075026, lng: 18.9246197 },
    { lat: 48.4074629, lng: 18.9244412 },
    { lat: 48.4074438, lng: 18.9242443 },
    { lat: 48.4074772, lng: 18.9240612 },
    { lat: 48.407601, lng: 18.9238605 },
    { lat: 48.4076972, lng: 18.9235615 },
    { lat: 48.4078003, lng: 18.9234622 },
    { lat: 48.4079385, lng: 18.9234526 },
    { lat: 48.4080639, lng: 18.9234802 },
    { lat: 48.4082686, lng: 18.9232297 },
    { lat: 48.408181, lng: 18.9229048 },
    { lat: 48.4082111, lng: 18.9228793 },
    { lat: 48.4085907, lng: 18.922761 },
    { lat: 48.4088626, lng: 18.9225445 },
    { lat: 48.4090249, lng: 18.9224118 },
    { lat: 48.4091066, lng: 18.9222681 },
    { lat: 48.4091281, lng: 18.9220065 },
    { lat: 48.4091454, lng: 18.9219935 },
    { lat: 48.4094125, lng: 18.922102 },
    { lat: 48.4096935, lng: 18.9220842 },
    { lat: 48.4099015, lng: 18.9219621 },
    { lat: 48.4100672, lng: 18.9219396 },
    { lat: 48.4102475, lng: 18.9219822 },
    { lat: 48.4102674, lng: 18.9218635 },
    { lat: 48.4102488, lng: 18.9217407 },
    { lat: 48.4102821, lng: 18.9215796 },
    { lat: 48.4103885, lng: 18.9214118 },
    { lat: 48.4105454, lng: 18.9213485 },
    { lat: 48.4107095, lng: 18.92112 },
    { lat: 48.4107933, lng: 18.9208856 },
    { lat: 48.4108991, lng: 18.9206405 },
    { lat: 48.4110915, lng: 18.9205241 },
    { lat: 48.4112059, lng: 18.9204696 },
    { lat: 48.4113362, lng: 18.9203295 },
    { lat: 48.4114806, lng: 18.920393 },
    { lat: 48.4115225, lng: 18.9203544 },
    { lat: 48.4115356, lng: 18.9201914 },
    { lat: 48.4116985, lng: 18.919941 },
    { lat: 48.4121444, lng: 18.9194913 },
    { lat: 48.4122529, lng: 18.9192767 },
    { lat: 48.4123072, lng: 18.9191703 },
    { lat: 48.4124133, lng: 18.9190838 },
    { lat: 48.4127808, lng: 18.9189066 },
    { lat: 48.4128389, lng: 18.9188338 },
    { lat: 48.4129136, lng: 18.9187506 },
    { lat: 48.4130122, lng: 18.918694 },
    { lat: 48.4132074, lng: 18.9186348 },
    { lat: 48.4133207, lng: 18.9181829 },
    { lat: 48.4134923, lng: 18.9179031 },
    { lat: 48.4135577, lng: 18.917706 },
    { lat: 48.4136235, lng: 18.9175386 },
    { lat: 48.4136683, lng: 18.9174046 },
    { lat: 48.4137247, lng: 18.9172688 },
    { lat: 48.4138041, lng: 18.9171506 },
    { lat: 48.4139581, lng: 18.9170081 },
    { lat: 48.414072, lng: 18.9168561 },
    { lat: 48.4141057, lng: 18.9167855 },
    { lat: 48.4141601, lng: 18.9166819 },
    { lat: 48.4142599, lng: 18.9166735 },
    { lat: 48.414317, lng: 18.9166636 },
    { lat: 48.4145525, lng: 18.9166163 },
    { lat: 48.4146194, lng: 18.9165798 },
    { lat: 48.4146569, lng: 18.916541 },
    { lat: 48.4147969, lng: 18.9163938 },
    { lat: 48.4148887, lng: 18.9163596 },
    { lat: 48.4149852, lng: 18.9163694 },
    { lat: 48.4151446, lng: 18.9164005 },
    { lat: 48.415184, lng: 18.9164222 },
    { lat: 48.4152182, lng: 18.9164534 },
    { lat: 48.4153306, lng: 18.9165521 },
    { lat: 48.4154277, lng: 18.9165983 },
    { lat: 48.4155207, lng: 18.9166553 },
    { lat: 48.4156881, lng: 18.9167721 },
    { lat: 48.4157303, lng: 18.9167853 },
    { lat: 48.4158301, lng: 18.9167502 },
    { lat: 48.4158535, lng: 18.9167213 },
    { lat: 48.4159307, lng: 18.9166651 },
    { lat: 48.4160817, lng: 18.9165793 },
    { lat: 48.4161163, lng: 18.9165491 },
    { lat: 48.4161717, lng: 18.9164642 },
    { lat: 48.4161848, lng: 18.916479 },
    { lat: 48.4161895, lng: 18.916483 },
    { lat: 48.416402, lng: 18.9167323 },
    { lat: 48.4165041, lng: 18.9168529 },
    { lat: 48.4166286, lng: 18.9169083 },
    { lat: 48.4167211, lng: 18.917037 },
    { lat: 48.4168166, lng: 18.9171626 },
    { lat: 48.4168867, lng: 18.9172535 },
    { lat: 48.4169353, lng: 18.9173894 },
    { lat: 48.4169946, lng: 18.9174901 },
    { lat: 48.4170091, lng: 18.9175967 },
    { lat: 48.4170232, lng: 18.9176909 },
    { lat: 48.4172991, lng: 18.9179921 },
    { lat: 48.4174221, lng: 18.9181474 },
    { lat: 48.4176275, lng: 18.9182623 },
    { lat: 48.4176321, lng: 18.9182643 },
    { lat: 48.4176553, lng: 18.9182747 },
    { lat: 48.4176715, lng: 18.9182824 },
    { lat: 48.417979, lng: 18.9184309 },
    { lat: 48.4182025, lng: 18.9184127 },
    { lat: 48.418318, lng: 18.918467 },
    { lat: 48.4184682, lng: 18.9185373 },
    { lat: 48.4187234, lng: 18.9186304 },
    { lat: 48.4188498, lng: 18.9186764 },
    { lat: 48.4189328, lng: 18.9187317 },
    { lat: 48.4190233, lng: 18.9188919 },
    { lat: 48.4190453, lng: 18.9190122 },
    { lat: 48.4190577, lng: 18.9192539 },
    { lat: 48.4191561, lng: 18.9193692 },
    { lat: 48.4192119, lng: 18.9194021 },
    { lat: 48.4193689, lng: 18.9193409 },
    { lat: 48.4195708, lng: 18.9194306 },
    { lat: 48.4198426, lng: 18.9194966 },
    { lat: 48.4199055, lng: 18.9195149 },
    { lat: 48.4201836, lng: 18.9195294 },
    { lat: 48.4203139, lng: 18.9195402 },
    { lat: 48.4204851, lng: 18.9194918 },
    { lat: 48.4205048, lng: 18.919481 },
    { lat: 48.4207122, lng: 18.9193685 },
    { lat: 48.4207975, lng: 18.9193426 },
    { lat: 48.4209253, lng: 18.9193043 },
    { lat: 48.4210429, lng: 18.9192488 },
    { lat: 48.4211978, lng: 18.9190575 },
    { lat: 48.4212326, lng: 18.9189914 },
    { lat: 48.4213373, lng: 18.9189004 },
    { lat: 48.4214656, lng: 18.9189142 },
    { lat: 48.4215443, lng: 18.9188447 },
    { lat: 48.4216656, lng: 18.9186811 },
    { lat: 48.4217929, lng: 18.9187033 },
    { lat: 48.4218761, lng: 18.9186542 },
    { lat: 48.422021, lng: 18.9189064 },
    { lat: 48.4220679, lng: 18.9189645 },
    { lat: 48.4223227, lng: 18.9192451 },
    { lat: 48.4226306, lng: 18.9196008 },
    { lat: 48.4227472, lng: 18.9198273 },
    { lat: 48.422866, lng: 18.920002 },
    { lat: 48.4229568, lng: 18.9200963 },
    { lat: 48.4231348, lng: 18.9202146 },
    { lat: 48.4235426, lng: 18.9206559 },
    { lat: 48.4239653, lng: 18.9211926 },
    { lat: 48.4241484, lng: 18.9214759 },
    { lat: 48.4242979, lng: 18.9216363 },
    { lat: 48.424481, lng: 18.9216567 },
    { lat: 48.4245055, lng: 18.9216961 },
    { lat: 48.4253113, lng: 18.9194676 },
    { lat: 48.4256571, lng: 18.9185373 },
    { lat: 48.4259848, lng: 18.9168155 },
    { lat: 48.4259538, lng: 18.9163189 },
    { lat: 48.4260303, lng: 18.9160572 },
    { lat: 48.4266816, lng: 18.9151276 },
    { lat: 48.4276976, lng: 18.9142112 },
    { lat: 48.4281485, lng: 18.9135299 },
    { lat: 48.4281533, lng: 18.9133803 },
    { lat: 48.4281847, lng: 18.9132925 },
    { lat: 48.42813, lng: 18.9132136 },
    { lat: 48.4281311, lng: 18.9131478 },
    { lat: 48.4281028, lng: 18.9122085 },
    { lat: 48.4280104, lng: 18.9117272 },
    { lat: 48.4279173, lng: 18.9112967 },
    { lat: 48.4277263, lng: 18.9111159 },
    { lat: 48.4275881, lng: 18.9104475 },
    { lat: 48.4275617, lng: 18.9102617 },
    { lat: 48.4277031, lng: 18.9099239 },
    { lat: 48.4278243, lng: 18.9084265 },
    { lat: 48.4277437, lng: 18.9072135 },
    { lat: 48.4278474, lng: 18.9069923 },
    { lat: 48.4279876, lng: 18.906999 },
    { lat: 48.4280098, lng: 18.9059004 },
    { lat: 48.4280476, lng: 18.9058683 },
    { lat: 48.4280914, lng: 18.9057556 },
    { lat: 48.4280261, lng: 18.9046879 },
    { lat: 48.4282751, lng: 18.9037268 },
    { lat: 48.4282845, lng: 18.9036529 },
    { lat: 48.4283226, lng: 18.9033553 },
    { lat: 48.4280811, lng: 18.9019136 },
    { lat: 48.4283063, lng: 18.9008811 },
    { lat: 48.4283114, lng: 18.9008251 },
    { lat: 48.4283527, lng: 18.9003165 },
    { lat: 48.4281517, lng: 18.9003311 },
    { lat: 48.4273596, lng: 18.9003884 },
    { lat: 48.4267845, lng: 18.900169 },
    { lat: 48.426557, lng: 18.8999332 },
    { lat: 48.4262605, lng: 18.8998928 },
    { lat: 48.4255433, lng: 18.9000408 },
    { lat: 48.4252125, lng: 18.8997723 },
    { lat: 48.4250122, lng: 18.8995624 },
    { lat: 48.4248177, lng: 18.899398 },
    { lat: 48.4245156, lng: 18.8994457 },
    { lat: 48.4245036, lng: 18.8994098 },
    { lat: 48.4244822, lng: 18.8993428 },
    { lat: 48.424473, lng: 18.8993151 },
    { lat: 48.4244459, lng: 18.8992295 },
    { lat: 48.4243628, lng: 18.8991952 },
    { lat: 48.4242246, lng: 18.8991371 },
    { lat: 48.4240585, lng: 18.8988888 },
    { lat: 48.423935, lng: 18.8986806 },
    { lat: 48.4236843, lng: 18.8976285 },
    { lat: 48.4236596, lng: 18.8975224 },
    { lat: 48.4235889, lng: 18.8967667 },
    { lat: 48.423534, lng: 18.8964675 },
    { lat: 48.4235448, lng: 18.8962841 },
    { lat: 48.4235603, lng: 18.8960352 },
    { lat: 48.4235131, lng: 18.8959083 },
    { lat: 48.4234174, lng: 18.8956479 },
    { lat: 48.423264, lng: 18.8954326 },
    { lat: 48.4232481, lng: 18.8953208 },
    { lat: 48.4232285, lng: 18.8951797 },
    { lat: 48.4231296, lng: 18.8950588 },
    { lat: 48.4231316, lng: 18.8950511 },
    { lat: 48.4231721, lng: 18.8949204 },
    { lat: 48.4231249, lng: 18.8948765 },
    { lat: 48.4231068, lng: 18.8948556 },
    { lat: 48.4231634, lng: 18.8946835 },
    { lat: 48.4231607, lng: 18.8944822 },
    { lat: 48.4231284, lng: 18.8942737 },
    { lat: 48.4232408, lng: 18.8941349 },
    { lat: 48.4232895, lng: 18.894006 },
    { lat: 48.4234281, lng: 18.8936764 },
    { lat: 48.4235156, lng: 18.8933633 },
    { lat: 48.4236926, lng: 18.8930157 },
    { lat: 48.4237035, lng: 18.8925501 },
    { lat: 48.4236744, lng: 18.8925311 },
    { lat: 48.4227829, lng: 18.8909703 },
    { lat: 48.4215307, lng: 18.8902831 },
    { lat: 48.4204399, lng: 18.8898433 },
    { lat: 48.4202727, lng: 18.888963 },
    { lat: 48.4203802, lng: 18.8880143 },
    { lat: 48.4202298, lng: 18.8874827 },
    { lat: 48.4201981, lng: 18.886283 },
    { lat: 48.4198223, lng: 18.8846202 },
    { lat: 48.418988, lng: 18.8839273 },
    { lat: 48.4192121, lng: 18.8827268 },
    { lat: 48.4189096, lng: 18.8821963 },
    { lat: 48.4187158, lng: 18.8809441 },
    { lat: 48.4186773, lng: 18.8806957 },
    { lat: 48.4187084, lng: 18.8794056 },
    { lat: 48.418331, lng: 18.8781925 },
    { lat: 48.4181841, lng: 18.878035 },
    { lat: 48.4181172, lng: 18.8779651 },
    { lat: 48.4177113, lng: 18.8775303 },
    { lat: 48.41727, lng: 18.8764794 },
    { lat: 48.4170941, lng: 18.8748707 },
    { lat: 48.417052, lng: 18.874865 },
    { lat: 48.4164401, lng: 18.8740898 },
    { lat: 48.4162038, lng: 18.8731878 },
    { lat: 48.4159474, lng: 18.8725282 },
    { lat: 48.4156271, lng: 18.8718981 },
    { lat: 48.4149634, lng: 18.8714307 },
    { lat: 48.4145584, lng: 18.8710575 },
    { lat: 48.4140802, lng: 18.8707072 },
    { lat: 48.4136379, lng: 18.8703757 },
    { lat: 48.4132708, lng: 18.8699513 },
  ],
  SvätýAnton: [
    { lat: 48.411295, lng: 18.985252 },
    { lat: 48.411603, lng: 18.985482 },
    { lat: 48.412236, lng: 18.985952 },
    { lat: 48.412545, lng: 18.98636 },
    { lat: 48.41307, lng: 18.986663 },
    { lat: 48.414382, lng: 18.987734 },
    { lat: 48.414727, lng: 18.98833 },
    { lat: 48.415262, lng: 18.989714 },
    { lat: 48.415517, lng: 18.990374 },
    { lat: 48.416237, lng: 18.991109 },
    { lat: 48.417382, lng: 18.99158 },
    { lat: 48.417521, lng: 18.991537 },
    { lat: 48.41765, lng: 18.991573 },
    { lat: 48.418433, lng: 18.992353 },
    { lat: 48.419589, lng: 18.993321 },
    { lat: 48.419883, lng: 18.993648 },
    { lat: 48.419895, lng: 18.99366 },
    { lat: 48.420328, lng: 18.994152 },
    { lat: 48.420514, lng: 18.99431 },
    { lat: 48.420802, lng: 18.994589 },
    { lat: 48.420985, lng: 18.994745 },
    { lat: 48.421496, lng: 18.995314 },
    { lat: 48.422324, lng: 18.995815 },
    { lat: 48.422841, lng: 18.995518 },
    { lat: 48.422943, lng: 18.995402 },
    { lat: 48.422961, lng: 18.995388 },
    { lat: 48.42312, lng: 18.995278 },
    { lat: 48.423254, lng: 18.99512 },
    { lat: 48.423494, lng: 18.994844 },
    { lat: 48.423512, lng: 18.99483 },
    { lat: 48.423684, lng: 18.994704 },
    { lat: 48.423784, lng: 18.994629 },
    { lat: 48.423848, lng: 18.994574 },
    { lat: 48.424166, lng: 18.994302 },
    { lat: 48.4250064, lng: 18.9940096 },
    { lat: 48.4254928, lng: 18.9940176 },
    { lat: 48.4261003, lng: 18.9939017 },
    { lat: 48.4263566, lng: 18.9934225 },
    { lat: 48.4269766, lng: 18.9923789 },
    { lat: 48.4272233, lng: 18.9919988 },
    { lat: 48.4274949, lng: 18.9912207 },
    { lat: 48.4291598, lng: 18.9878225 },
    { lat: 48.4297584, lng: 18.9858305 },
    { lat: 48.4297456, lng: 18.985777 },
    { lat: 48.4299986, lng: 18.9848131 },
    { lat: 48.429584, lng: 18.9843723 },
    { lat: 48.4296039, lng: 18.9842344 },
    { lat: 48.4306767, lng: 18.9828286 },
    { lat: 48.4309516, lng: 18.9824498 },
    { lat: 48.4311952, lng: 18.9820933 },
    { lat: 48.4313819, lng: 18.9817324 },
    { lat: 48.4315168, lng: 18.9814819 },
    { lat: 48.4317404, lng: 18.9810659 },
    { lat: 48.4322853, lng: 18.9803677 },
    { lat: 48.4322622, lng: 18.9801332 },
    { lat: 48.4326943, lng: 18.9798079 },
    { lat: 48.4327725, lng: 18.9796587 },
    { lat: 48.4328567, lng: 18.9791242 },
    { lat: 48.4328792, lng: 18.9787907 },
    { lat: 48.432828, lng: 18.9784498 },
    { lat: 48.4327698, lng: 18.9779296 },
    { lat: 48.432756, lng: 18.9773705 },
    { lat: 48.4327706, lng: 18.9773245 },
    { lat: 48.4329357, lng: 18.9766703 },
    { lat: 48.4330962, lng: 18.9764352 },
    { lat: 48.4332665, lng: 18.9760179 },
    { lat: 48.4333599, lng: 18.9760132 },
    { lat: 48.4334323, lng: 18.975729 },
    { lat: 48.4335989, lng: 18.9753672 },
    { lat: 48.4342929, lng: 18.9747635 },
    { lat: 48.4346622, lng: 18.9746201 },
    { lat: 48.4347352, lng: 18.9741927 },
    { lat: 48.4349812, lng: 18.9742865 },
    { lat: 48.4353345, lng: 18.9735669 },
    { lat: 48.4361709, lng: 18.9718393 },
    { lat: 48.4364927, lng: 18.9711833 },
    { lat: 48.4365807, lng: 18.9705234 },
    { lat: 48.4369528, lng: 18.9695267 },
    { lat: 48.4370756, lng: 18.9688656 },
    { lat: 48.4374535, lng: 18.9680948 },
    { lat: 48.4378598, lng: 18.9674642 },
    { lat: 48.4378103, lng: 18.9665825 },
    { lat: 48.4376415, lng: 18.966058 },
    { lat: 48.4376381, lng: 18.9658628 },
    { lat: 48.4371143, lng: 18.9647241 },
    { lat: 48.4369843, lng: 18.9639239 },
    { lat: 48.4357817, lng: 18.9615076 },
    { lat: 48.4355425, lng: 18.9601334 },
    { lat: 48.4354378, lng: 18.9578457 },
    { lat: 48.435558, lng: 18.9565805 },
    { lat: 48.4359987, lng: 18.9538896 },
    { lat: 48.4360529, lng: 18.9529924 },
    { lat: 48.4357694, lng: 18.9528917 },
    { lat: 48.435676, lng: 18.9525201 },
    { lat: 48.435761, lng: 18.9516063 },
    { lat: 48.4357733, lng: 18.9506334 },
    { lat: 48.4357081, lng: 18.9498455 },
    { lat: 48.4359528, lng: 18.9487694 },
    { lat: 48.4357001, lng: 18.9458863 },
    { lat: 48.4350335, lng: 18.9446504 },
    { lat: 48.4344778, lng: 18.9426959 },
    { lat: 48.4343814, lng: 18.9423978 },
    { lat: 48.4343932, lng: 18.9417527 },
    { lat: 48.4341563, lng: 18.9414186 },
    { lat: 48.4341365, lng: 18.9410325 },
    { lat: 48.4338538, lng: 18.9389945 },
    { lat: 48.4338621, lng: 18.938936 },
    { lat: 48.4334701, lng: 18.9371678 },
    { lat: 48.4324479, lng: 18.9360344 },
    { lat: 48.4322287, lng: 18.9344905 },
    { lat: 48.4320068, lng: 18.9328244 },
    { lat: 48.4319835, lng: 18.9327905 },
    { lat: 48.4319509, lng: 18.9327443 },
    { lat: 48.4319277, lng: 18.9327118 },
    { lat: 48.4318886, lng: 18.9326532 },
    { lat: 48.4318544, lng: 18.9326237 },
    { lat: 48.4316284, lng: 18.9323492 },
    { lat: 48.4315321, lng: 18.9322322 },
    { lat: 48.4311329, lng: 18.9317513 },
    { lat: 48.4308443, lng: 18.9313996 },
    { lat: 48.4307893, lng: 18.9312498 },
    { lat: 48.4307354, lng: 18.9312521 },
    { lat: 48.430459, lng: 18.9301552 },
    { lat: 48.4305127, lng: 18.9301017 },
    { lat: 48.4304467, lng: 18.9298939 },
    { lat: 48.4304293, lng: 18.929712 },
    { lat: 48.4303659, lng: 18.9295944 },
    { lat: 48.4302943, lng: 18.929429 },
    { lat: 48.4301818, lng: 18.9293036 },
    { lat: 48.4301563, lng: 18.9292686 },
    { lat: 48.4293237, lng: 18.9284688 },
    { lat: 48.4281165, lng: 18.9269652 },
    { lat: 48.4280759, lng: 18.9266128 },
    { lat: 48.4276739, lng: 18.9261656 },
    { lat: 48.4264737, lng: 18.925346 },
    { lat: 48.4257608, lng: 18.9236958 },
    { lat: 48.4245055, lng: 18.9216961 },
    { lat: 48.424481, lng: 18.9216567 },
    { lat: 48.4242979, lng: 18.9216363 },
    { lat: 48.4241484, lng: 18.9214759 },
    { lat: 48.4239653, lng: 18.9211926 },
    { lat: 48.4235426, lng: 18.9206559 },
    { lat: 48.4231348, lng: 18.9202146 },
    { lat: 48.4229568, lng: 18.9200963 },
    { lat: 48.422866, lng: 18.920002 },
    { lat: 48.4227472, lng: 18.9198273 },
    { lat: 48.4226306, lng: 18.9196008 },
    { lat: 48.4223227, lng: 18.9192451 },
    { lat: 48.4220679, lng: 18.9189645 },
    { lat: 48.422021, lng: 18.9189064 },
    { lat: 48.4218761, lng: 18.9186542 },
    { lat: 48.4217929, lng: 18.9187033 },
    { lat: 48.4216656, lng: 18.9186811 },
    { lat: 48.4215443, lng: 18.9188447 },
    { lat: 48.4214656, lng: 18.9189142 },
    { lat: 48.4213373, lng: 18.9189004 },
    { lat: 48.4212326, lng: 18.9189914 },
    { lat: 48.4211978, lng: 18.9190575 },
    { lat: 48.4210429, lng: 18.9192488 },
    { lat: 48.4209253, lng: 18.9193043 },
    { lat: 48.4207975, lng: 18.9193426 },
    { lat: 48.4207122, lng: 18.9193685 },
    { lat: 48.4205048, lng: 18.919481 },
    { lat: 48.4204851, lng: 18.9194918 },
    { lat: 48.4203139, lng: 18.9195402 },
    { lat: 48.4201836, lng: 18.9195294 },
    { lat: 48.4199055, lng: 18.9195149 },
    { lat: 48.4198426, lng: 18.9194966 },
    { lat: 48.4195708, lng: 18.9194306 },
    { lat: 48.4193689, lng: 18.9193409 },
    { lat: 48.4192119, lng: 18.9194021 },
    { lat: 48.4191561, lng: 18.9193692 },
    { lat: 48.4190577, lng: 18.9192539 },
    { lat: 48.4190453, lng: 18.9190122 },
    { lat: 48.4190233, lng: 18.9188919 },
    { lat: 48.4189328, lng: 18.9187317 },
    { lat: 48.4188498, lng: 18.9186764 },
    { lat: 48.4187234, lng: 18.9186304 },
    { lat: 48.4184682, lng: 18.9185373 },
    { lat: 48.418318, lng: 18.918467 },
    { lat: 48.4182025, lng: 18.9184127 },
    { lat: 48.417979, lng: 18.9184309 },
    { lat: 48.4176715, lng: 18.9182824 },
    { lat: 48.4176553, lng: 18.9182747 },
    { lat: 48.4176321, lng: 18.9182643 },
    { lat: 48.4176275, lng: 18.9182623 },
    { lat: 48.4174221, lng: 18.9181474 },
    { lat: 48.4172991, lng: 18.9179921 },
    { lat: 48.4170232, lng: 18.9176909 },
    { lat: 48.4170091, lng: 18.9175967 },
    { lat: 48.4169946, lng: 18.9174901 },
    { lat: 48.4169353, lng: 18.9173894 },
    { lat: 48.4168867, lng: 18.9172535 },
    { lat: 48.4168166, lng: 18.9171626 },
    { lat: 48.4167211, lng: 18.917037 },
    { lat: 48.4166286, lng: 18.9169083 },
    { lat: 48.4165041, lng: 18.9168529 },
    { lat: 48.416402, lng: 18.9167323 },
    { lat: 48.4161895, lng: 18.916483 },
    { lat: 48.4161848, lng: 18.916479 },
    { lat: 48.4161717, lng: 18.9164642 },
    { lat: 48.4161163, lng: 18.9165491 },
    { lat: 48.4160817, lng: 18.9165793 },
    { lat: 48.4159307, lng: 18.9166651 },
    { lat: 48.4158535, lng: 18.9167213 },
    { lat: 48.4158301, lng: 18.9167502 },
    { lat: 48.4157303, lng: 18.9167853 },
    { lat: 48.4156881, lng: 18.9167721 },
    { lat: 48.4155207, lng: 18.9166553 },
    { lat: 48.4154277, lng: 18.9165983 },
    { lat: 48.4153306, lng: 18.9165521 },
    { lat: 48.4152182, lng: 18.9164534 },
    { lat: 48.415184, lng: 18.9164222 },
    { lat: 48.4151446, lng: 18.9164005 },
    { lat: 48.4149852, lng: 18.9163694 },
    { lat: 48.4148887, lng: 18.9163596 },
    { lat: 48.4147969, lng: 18.9163938 },
    { lat: 48.4146569, lng: 18.916541 },
    { lat: 48.4146194, lng: 18.9165798 },
    { lat: 48.4145525, lng: 18.9166163 },
    { lat: 48.414317, lng: 18.9166636 },
    { lat: 48.4142599, lng: 18.9166735 },
    { lat: 48.4141601, lng: 18.9166819 },
    { lat: 48.4141057, lng: 18.9167855 },
    { lat: 48.414072, lng: 18.9168561 },
    { lat: 48.4139581, lng: 18.9170081 },
    { lat: 48.4138041, lng: 18.9171506 },
    { lat: 48.4137247, lng: 18.9172688 },
    { lat: 48.4136683, lng: 18.9174046 },
    { lat: 48.4136235, lng: 18.9175386 },
    { lat: 48.4135577, lng: 18.917706 },
    { lat: 48.4134923, lng: 18.9179031 },
    { lat: 48.4133207, lng: 18.9181829 },
    { lat: 48.4132074, lng: 18.9186348 },
    { lat: 48.4130122, lng: 18.918694 },
    { lat: 48.4129136, lng: 18.9187506 },
    { lat: 48.4128389, lng: 18.9188338 },
    { lat: 48.4127808, lng: 18.9189066 },
    { lat: 48.4124133, lng: 18.9190838 },
    { lat: 48.4123072, lng: 18.9191703 },
    { lat: 48.4122529, lng: 18.9192767 },
    { lat: 48.4121444, lng: 18.9194913 },
    { lat: 48.4116985, lng: 18.919941 },
    { lat: 48.4115356, lng: 18.9201914 },
    { lat: 48.4115225, lng: 18.9203544 },
    { lat: 48.4114806, lng: 18.920393 },
    { lat: 48.4113362, lng: 18.9203295 },
    { lat: 48.4112059, lng: 18.9204696 },
    { lat: 48.4110915, lng: 18.9205241 },
    { lat: 48.4108991, lng: 18.9206405 },
    { lat: 48.4107933, lng: 18.9208856 },
    { lat: 48.4107095, lng: 18.92112 },
    { lat: 48.4105454, lng: 18.9213485 },
    { lat: 48.4103885, lng: 18.9214118 },
    { lat: 48.4102821, lng: 18.9215796 },
    { lat: 48.4102488, lng: 18.9217407 },
    { lat: 48.4102674, lng: 18.9218635 },
    { lat: 48.4102475, lng: 18.9219822 },
    { lat: 48.4100672, lng: 18.9219396 },
    { lat: 48.4099015, lng: 18.9219621 },
    { lat: 48.4096935, lng: 18.9220842 },
    { lat: 48.4094125, lng: 18.922102 },
    { lat: 48.4091454, lng: 18.9219935 },
    { lat: 48.4091281, lng: 18.9220065 },
    { lat: 48.4091066, lng: 18.9222681 },
    { lat: 48.4090249, lng: 18.9224118 },
    { lat: 48.4088626, lng: 18.9225445 },
    { lat: 48.4085907, lng: 18.922761 },
    { lat: 48.4082111, lng: 18.9228793 },
    { lat: 48.408181, lng: 18.9229048 },
    { lat: 48.4082686, lng: 18.9232297 },
    { lat: 48.4080639, lng: 18.9234802 },
    { lat: 48.4079385, lng: 18.9234526 },
    { lat: 48.4078003, lng: 18.9234622 },
    { lat: 48.4076972, lng: 18.9235615 },
    { lat: 48.407601, lng: 18.9238605 },
    { lat: 48.4074772, lng: 18.9240612 },
    { lat: 48.4074438, lng: 18.9242443 },
    { lat: 48.4074629, lng: 18.9244412 },
    { lat: 48.4075026, lng: 18.9246197 },
    { lat: 48.4073546, lng: 18.9248562 },
    { lat: 48.4073596, lng: 18.9249763 },
    { lat: 48.4072981, lng: 18.9251814 },
    { lat: 48.4071358, lng: 18.9253153 },
    { lat: 48.4070285, lng: 18.9263207 },
    { lat: 48.4069057, lng: 18.9266288 },
    { lat: 48.4068753, lng: 18.927066 },
    { lat: 48.4067772, lng: 18.9273315 },
    { lat: 48.4067049, lng: 18.9276994 },
    { lat: 48.4067385, lng: 18.9279389 },
    { lat: 48.4067132, lng: 18.928169 },
    { lat: 48.4066164, lng: 18.9285 },
    { lat: 48.4066194, lng: 18.928605 },
    { lat: 48.4066295, lng: 18.9286216 },
    { lat: 48.4067266, lng: 18.9287597 },
    { lat: 48.4067431, lng: 18.92886 },
    { lat: 48.4066871, lng: 18.9290425 },
    { lat: 48.4065512, lng: 18.9292489 },
    { lat: 48.4064632, lng: 18.9295547 },
    { lat: 48.4064018, lng: 18.9297858 },
    { lat: 48.4063796, lng: 18.9297947 },
    { lat: 48.406346, lng: 18.9297986 },
    { lat: 48.4063122, lng: 18.9295584 },
    { lat: 48.4062104, lng: 18.9293995 },
    { lat: 48.4061932, lng: 18.9293912 },
    { lat: 48.406077, lng: 18.9294327 },
    { lat: 48.4059882, lng: 18.9294842 },
    { lat: 48.4059306, lng: 18.9292421 },
    { lat: 48.4058704, lng: 18.9291242 },
    { lat: 48.4057896, lng: 18.9290417 },
    { lat: 48.4056774, lng: 18.9287257 },
    { lat: 48.4055458, lng: 18.9285543 },
    { lat: 48.405581, lng: 18.9283629 },
    { lat: 48.405627, lng: 18.928228 },
    { lat: 48.4056297, lng: 18.9280826 },
    { lat: 48.4056177, lng: 18.9278902 },
    { lat: 48.4055725, lng: 18.9277847 },
    { lat: 48.4053755, lng: 18.9276571 },
    { lat: 48.4052963, lng: 18.9274527 },
    { lat: 48.405287, lng: 18.9273178 },
    { lat: 48.4051623, lng: 18.9271084 },
    { lat: 48.4050933, lng: 18.9269283 },
    { lat: 48.4051175, lng: 18.9266732 },
    { lat: 48.4051764, lng: 18.9264833 },
    { lat: 48.4051161, lng: 18.9261237 },
    { lat: 48.4051, lng: 18.9259172 },
    { lat: 48.404932, lng: 18.9256138 },
    { lat: 48.4047003, lng: 18.9254271 },
    { lat: 48.4045548, lng: 18.9254039 },
    { lat: 48.4045438, lng: 18.9252778 },
    { lat: 48.4045845, lng: 18.9252156 },
    { lat: 48.4044754, lng: 18.9248897 },
    { lat: 48.4044843, lng: 18.9245363 },
    { lat: 48.404371, lng: 18.9244369 },
    { lat: 48.4043482, lng: 18.9240407 },
    { lat: 48.4044575, lng: 18.92384 },
    { lat: 48.4044901, lng: 18.9236211 },
    { lat: 48.4045862, lng: 18.9233385 },
    { lat: 48.4045766, lng: 18.9231114 },
    { lat: 48.4045461, lng: 18.922979 },
    { lat: 48.4046257, lng: 18.9227711 },
    { lat: 48.4046133, lng: 18.9227509 },
    { lat: 48.4044413, lng: 18.9224976 },
    { lat: 48.4044094, lng: 18.9220538 },
    { lat: 48.404491, lng: 18.9218676 },
    { lat: 48.4044486, lng: 18.9217405 },
    { lat: 48.4044113, lng: 18.9215678 },
    { lat: 48.4044665, lng: 18.9213907 },
    { lat: 48.4043174, lng: 18.9207501 },
    { lat: 48.4042319, lng: 18.9206852 },
    { lat: 48.4041515, lng: 18.9203311 },
    { lat: 48.4041332, lng: 18.9201086 },
    { lat: 48.4040643, lng: 18.919711 },
    { lat: 48.4040279, lng: 18.919442 },
    { lat: 48.4039992, lng: 18.919214 },
    { lat: 48.4038797, lng: 18.9191281 },
    { lat: 48.4038523, lng: 18.9190085 },
    { lat: 48.4037143, lng: 18.9188031 },
    { lat: 48.4036127, lng: 18.9183852 },
    { lat: 48.4036068, lng: 18.9182071 },
    { lat: 48.4035266, lng: 18.9177011 },
    { lat: 48.4035331, lng: 18.9174775 },
    { lat: 48.4034919, lng: 18.9171786 },
    { lat: 48.4034549, lng: 18.916855 },
    { lat: 48.4034793, lng: 18.9166311 },
    { lat: 48.4035248, lng: 18.9163969 },
    { lat: 48.4036255, lng: 18.9161854 },
    { lat: 48.403605, lng: 18.9160724 },
    { lat: 48.4036614, lng: 18.9158327 },
    { lat: 48.4035587, lng: 18.9153024 },
    { lat: 48.4033995, lng: 18.9149505 },
    { lat: 48.4032515, lng: 18.9141384 },
    { lat: 48.4031907, lng: 18.9140734 },
    { lat: 48.4030832, lng: 18.9139763 },
    { lat: 48.4028917, lng: 18.913632 },
    { lat: 48.4026116, lng: 18.9132952 },
    { lat: 48.4026039, lng: 18.9132773 },
    { lat: 48.4025499, lng: 18.913148 },
    { lat: 48.4024019, lng: 18.9129445 },
    { lat: 48.4022912, lng: 18.9125713 },
    { lat: 48.4021678, lng: 18.9122117 },
    { lat: 48.402077, lng: 18.9119831 },
    { lat: 48.4019549, lng: 18.9116907 },
    { lat: 48.4018187, lng: 18.911477 },
    { lat: 48.401749, lng: 18.9113733 },
    { lat: 48.4016903, lng: 18.9112829 },
    { lat: 48.4013931, lng: 18.9111603 },
    { lat: 48.4011973, lng: 18.9109063 },
    { lat: 48.4010336, lng: 18.9107281 },
    { lat: 48.4009234, lng: 18.9106667 },
    { lat: 48.4008266, lng: 18.9103881 },
    { lat: 48.4005092, lng: 18.9102679 },
    { lat: 48.4004632, lng: 18.9098581 },
    { lat: 48.4003535, lng: 18.9095862 },
    { lat: 48.4003106, lng: 18.9093688 },
    { lat: 48.4001683, lng: 18.9092995 },
    { lat: 48.4000358, lng: 18.9089381 },
    { lat: 48.3999495, lng: 18.9088186 },
    { lat: 48.3997896, lng: 18.9085178 },
    { lat: 48.3996887, lng: 18.9083937 },
    { lat: 48.3995633, lng: 18.9086507 },
    { lat: 48.3993787, lng: 18.9092917 },
    { lat: 48.3993054, lng: 18.909722 },
    { lat: 48.3989424, lng: 18.9118515 },
    { lat: 48.398653, lng: 18.9128656 },
    { lat: 48.39852, lng: 18.913672 },
    { lat: 48.3983018, lng: 18.9146007 },
    { lat: 48.398142, lng: 18.9149568 },
    { lat: 48.3979108, lng: 18.9153073 },
    { lat: 48.3977816, lng: 18.9154036 },
    { lat: 48.3974926, lng: 18.9153297 },
    { lat: 48.3972122, lng: 18.9151618 },
    { lat: 48.3967241, lng: 18.9151441 },
    { lat: 48.3965149, lng: 18.9152433 },
    { lat: 48.3963194, lng: 18.9154065 },
    { lat: 48.3962961, lng: 18.915505 },
    { lat: 48.3944372, lng: 18.9179423 },
    { lat: 48.3935637, lng: 18.9178192 },
    { lat: 48.3928691, lng: 18.9180767 },
    { lat: 48.3915297, lng: 18.9176006 },
    { lat: 48.3903586, lng: 18.9201016 },
    { lat: 48.3899932, lng: 18.9212367 },
    { lat: 48.3881003, lng: 18.9221218 },
    { lat: 48.3882924, lng: 18.92309 },
    { lat: 48.3880603, lng: 18.9246697 },
    { lat: 48.3875766, lng: 18.9248021 },
    { lat: 48.3866133, lng: 18.9256288 },
    { lat: 48.3860011, lng: 18.9308431 },
    { lat: 48.3859015, lng: 18.9309891 },
    { lat: 48.3857456, lng: 18.9310714 },
    { lat: 48.3856878, lng: 18.9313087 },
    { lat: 48.384671, lng: 18.9315086 },
    { lat: 48.384576, lng: 18.9319399 },
    { lat: 48.3848442, lng: 18.9329681 },
    { lat: 48.38634, lng: 18.942936 },
    { lat: 48.386924, lng: 18.944121 },
    { lat: 48.385449, lng: 18.946494 },
    { lat: 48.384638, lng: 18.949132 },
    { lat: 48.384569, lng: 18.949293 },
    { lat: 48.384415, lng: 18.949637 },
    { lat: 48.384034, lng: 18.950489 },
    { lat: 48.383516, lng: 18.951406 },
    { lat: 48.382879, lng: 18.952352 },
    { lat: 48.382698, lng: 18.953046 },
    { lat: 48.38249, lng: 18.95359 },
    { lat: 48.382291, lng: 18.954115 },
    { lat: 48.381933, lng: 18.954722 },
    { lat: 48.381805, lng: 18.956011 },
    { lat: 48.381742, lng: 18.956816 },
    { lat: 48.381776, lng: 18.957667 },
    { lat: 48.382186, lng: 18.958962 },
    { lat: 48.383147, lng: 18.958643 },
    { lat: 48.384578, lng: 18.957719 },
    { lat: 48.385085, lng: 18.956665 },
    { lat: 48.385094, lng: 18.956645 },
    { lat: 48.38511, lng: 18.956636 },
    { lat: 48.385156, lng: 18.95661 },
    { lat: 48.385312, lng: 18.956446 },
    { lat: 48.385509, lng: 18.956238 },
    { lat: 48.385905, lng: 18.955973 },
    { lat: 48.386116, lng: 18.955877 },
    { lat: 48.386368, lng: 18.955793 },
    { lat: 48.386549, lng: 18.955741 },
    { lat: 48.386766, lng: 18.955675 },
    { lat: 48.386946, lng: 18.955566 },
    { lat: 48.38727, lng: 18.955694 },
    { lat: 48.387562, lng: 18.955748 },
    { lat: 48.387801, lng: 18.955641 },
    { lat: 48.387985, lng: 18.955575 },
    { lat: 48.388213, lng: 18.955483 },
    { lat: 48.388484, lng: 18.955477 },
    { lat: 48.388722, lng: 18.955465 },
    { lat: 48.389001, lng: 18.955436 },
    { lat: 48.389184, lng: 18.955365 },
    { lat: 48.389202, lng: 18.955374 },
    { lat: 48.389928, lng: 18.955433 },
    { lat: 48.390426, lng: 18.955616 },
    { lat: 48.390447, lng: 18.955672 },
    { lat: 48.390448, lng: 18.955677 },
    { lat: 48.39085, lng: 18.956956 },
    { lat: 48.392324, lng: 18.957628 },
    { lat: 48.393944, lng: 18.958507 },
    { lat: 48.394866, lng: 18.959201 },
    { lat: 48.394949, lng: 18.959264 },
    { lat: 48.394976, lng: 18.959284 },
    { lat: 48.396225, lng: 18.960229 },
    { lat: 48.397009, lng: 18.960781 },
    { lat: 48.397532, lng: 18.961144 },
    { lat: 48.398264, lng: 18.961222 },
    { lat: 48.399758, lng: 18.961717 },
    { lat: 48.399884, lng: 18.961761 },
    { lat: 48.399975, lng: 18.961694 },
    { lat: 48.400006, lng: 18.96167 },
    { lat: 48.400061, lng: 18.961628 },
    { lat: 48.400091, lng: 18.961606 },
    { lat: 48.400925, lng: 18.960962 },
    { lat: 48.401892, lng: 18.960271 },
    { lat: 48.402176, lng: 18.96049 },
    { lat: 48.402383, lng: 18.960503 },
    { lat: 48.402654, lng: 18.960641 },
    { lat: 48.40305, lng: 18.961276 },
    { lat: 48.403164, lng: 18.961791 },
    { lat: 48.403456, lng: 18.963186 },
    { lat: 48.403471, lng: 18.963717 },
    { lat: 48.403529, lng: 18.963933 },
    { lat: 48.40354, lng: 18.964072 },
    { lat: 48.403581, lng: 18.964757 },
    { lat: 48.403633, lng: 18.964986 },
    { lat: 48.403682, lng: 18.965219 },
    { lat: 48.404103, lng: 18.965707 },
    { lat: 48.404353, lng: 18.966754 },
    { lat: 48.404413, lng: 18.966952 },
    { lat: 48.404425, lng: 18.967178 },
    { lat: 48.404418, lng: 18.967404 },
    { lat: 48.404546, lng: 18.968213 },
    { lat: 48.404528, lng: 18.968276 },
    { lat: 48.404299, lng: 18.969158 },
    { lat: 48.404205, lng: 18.969543 },
    { lat: 48.404091, lng: 18.970199 },
    { lat: 48.404089, lng: 18.970205 },
    { lat: 48.404084, lng: 18.970226 },
    { lat: 48.404255, lng: 18.970387 },
    { lat: 48.404349, lng: 18.970519 },
    { lat: 48.404458, lng: 18.97067 },
    { lat: 48.404564, lng: 18.970811 },
    { lat: 48.404635, lng: 18.970993 },
    { lat: 48.404688, lng: 18.971313 },
    { lat: 48.404779, lng: 18.971581 },
    { lat: 48.404834, lng: 18.971812 },
    { lat: 48.404869, lng: 18.971991 },
    { lat: 48.404903, lng: 18.972164 },
    { lat: 48.405029, lng: 18.972565 },
    { lat: 48.405092, lng: 18.972741 },
    { lat: 48.405251, lng: 18.97311 },
    { lat: 48.405286, lng: 18.973231 },
    { lat: 48.405307, lng: 18.973411 },
    { lat: 48.405273, lng: 18.973794 },
    { lat: 48.405288, lng: 18.974543 },
    { lat: 48.405788, lng: 18.975078 },
    { lat: 48.405893, lng: 18.975204 },
    { lat: 48.406027, lng: 18.975297 },
    { lat: 48.406234, lng: 18.975387 },
    { lat: 48.406285, lng: 18.975439 },
    { lat: 48.406469, lng: 18.975852 },
    { lat: 48.406532, lng: 18.975938 },
    { lat: 48.406694, lng: 18.976019 },
    { lat: 48.407084, lng: 18.976424 },
    { lat: 48.407139, lng: 18.976497 },
    { lat: 48.407293, lng: 18.976599 },
    { lat: 48.407433, lng: 18.976644 },
    { lat: 48.407468, lng: 18.976714 },
    { lat: 48.407531, lng: 18.976781 },
    { lat: 48.407558, lng: 18.97724 },
    { lat: 48.407602, lng: 18.977365 },
    { lat: 48.407777, lng: 18.97753 },
    { lat: 48.408024, lng: 18.977607 },
    { lat: 48.408183, lng: 18.977685 },
    { lat: 48.408677, lng: 18.977794 },
    { lat: 48.408948, lng: 18.978003 },
    { lat: 48.409123, lng: 18.978137 },
    { lat: 48.409258, lng: 18.978214 },
    { lat: 48.409818, lng: 18.97883 },
    { lat: 48.409969, lng: 18.979 },
    { lat: 48.410373, lng: 18.979094 },
    { lat: 48.410433, lng: 18.979649 },
    { lat: 48.410257, lng: 18.980188 },
    { lat: 48.410146, lng: 18.98066 },
    { lat: 48.410102, lng: 18.981298 },
    { lat: 48.410118, lng: 18.981857 },
    { lat: 48.410201, lng: 18.982135 },
    { lat: 48.410412, lng: 18.98254 },
    { lat: 48.410881, lng: 18.982994 },
    { lat: 48.41114, lng: 18.983394 },
    { lat: 48.411136, lng: 18.983529 },
    { lat: 48.411136, lng: 18.984278 },
    { lat: 48.411136, lng: 18.984326 },
    { lat: 48.411295, lng: 18.985252 },
  ],
  Prenčov: [
    { lat: 48.376845, lng: 18.955003 },
    { lat: 48.376436, lng: 18.953416 },
    { lat: 48.376892, lng: 18.952643 },
    { lat: 48.377265, lng: 18.952077 },
    { lat: 48.377793, lng: 18.951808 },
    { lat: 48.378637, lng: 18.951468 },
    { lat: 48.379341, lng: 18.95101 },
    { lat: 48.379932, lng: 18.949889 },
    { lat: 48.37989, lng: 18.94965 },
    { lat: 48.379659, lng: 18.948557 },
    { lat: 48.37996, lng: 18.946143 },
    { lat: 48.380351, lng: 18.945535 },
    { lat: 48.380624, lng: 18.945113 },
    { lat: 48.380839, lng: 18.944733 },
    { lat: 48.381303, lng: 18.94445 },
    { lat: 48.381861, lng: 18.943835 },
    { lat: 48.382608, lng: 18.943225 },
    { lat: 48.383277, lng: 18.941787 },
    { lat: 48.385427, lng: 18.942604 },
    { lat: 48.38634, lng: 18.942936 },
    { lat: 48.3848442, lng: 18.9329681 },
    { lat: 48.384576, lng: 18.9319399 },
    { lat: 48.384671, lng: 18.9315086 },
    { lat: 48.3856878, lng: 18.9313087 },
    { lat: 48.3857456, lng: 18.9310714 },
    { lat: 48.3859015, lng: 18.9309891 },
    { lat: 48.3860011, lng: 18.9308431 },
    { lat: 48.3866133, lng: 18.9256288 },
    { lat: 48.3875766, lng: 18.9248021 },
    { lat: 48.3880603, lng: 18.9246697 },
    { lat: 48.3882924, lng: 18.92309 },
    { lat: 48.3881003, lng: 18.9221218 },
    { lat: 48.3899932, lng: 18.9212367 },
    { lat: 48.3903586, lng: 18.9201016 },
    { lat: 48.3915297, lng: 18.9176006 },
    { lat: 48.3928691, lng: 18.9180767 },
    { lat: 48.3935637, lng: 18.9178192 },
    { lat: 48.3944372, lng: 18.9179423 },
    { lat: 48.3962961, lng: 18.915505 },
    { lat: 48.3963194, lng: 18.9154065 },
    { lat: 48.3965149, lng: 18.9152433 },
    { lat: 48.3967241, lng: 18.9151441 },
    { lat: 48.3972122, lng: 18.9151618 },
    { lat: 48.3974926, lng: 18.9153297 },
    { lat: 48.3977816, lng: 18.9154036 },
    { lat: 48.3979108, lng: 18.9153073 },
    { lat: 48.398142, lng: 18.9149568 },
    { lat: 48.3983018, lng: 18.9146007 },
    { lat: 48.39852, lng: 18.913672 },
    { lat: 48.398653, lng: 18.9128656 },
    { lat: 48.3989424, lng: 18.9118515 },
    { lat: 48.3993054, lng: 18.909722 },
    { lat: 48.3993787, lng: 18.9092917 },
    { lat: 48.3995633, lng: 18.9086507 },
    { lat: 48.3996887, lng: 18.9083937 },
    { lat: 48.3996631, lng: 18.9083782 },
    { lat: 48.3997154, lng: 18.9082802 },
    { lat: 48.3997794, lng: 18.9081653 },
    { lat: 48.3999798, lng: 18.9078765 },
    { lat: 48.4000609, lng: 18.9076119 },
    { lat: 48.4002566, lng: 18.9069462 },
    { lat: 48.400461, lng: 18.9063411 },
    { lat: 48.4006613, lng: 18.9056581 },
    { lat: 48.4008238, lng: 18.905114 },
    { lat: 48.4008679, lng: 18.9049432 },
    { lat: 48.4008831, lng: 18.9048197 },
    { lat: 48.4009029, lng: 18.9046144 },
    { lat: 48.4008944, lng: 18.9044727 },
    { lat: 48.4008643, lng: 18.9042825 },
    { lat: 48.4007709, lng: 18.9038704 },
    { lat: 48.4006703, lng: 18.9035589 },
    { lat: 48.4005723, lng: 18.903346 },
    { lat: 48.4004407, lng: 18.9031549 },
    { lat: 48.4000668, lng: 18.9028777 },
    { lat: 48.3999766, lng: 18.9027054 },
    { lat: 48.3999319, lng: 18.9024722 },
    { lat: 48.3998987, lng: 18.9020932 },
    { lat: 48.4000122, lng: 18.9016059 },
    { lat: 48.4000262, lng: 18.9013513 },
    { lat: 48.399909, lng: 18.9011375 },
    { lat: 48.3996317, lng: 18.9008969 },
    { lat: 48.3990206, lng: 18.9004384 },
    { lat: 48.3986695, lng: 18.8999084 },
    { lat: 48.3986141, lng: 18.8989019 },
    { lat: 48.3985665, lng: 18.8984827 },
    { lat: 48.3984167, lng: 18.897309 },
    { lat: 48.3982159, lng: 18.8965883 },
    { lat: 48.3981641, lng: 18.8961125 },
    { lat: 48.3982055, lng: 18.8954921 },
    { lat: 48.3982964, lng: 18.8952437 },
    { lat: 48.3984541, lng: 18.8948297 },
    { lat: 48.3985197, lng: 18.8945907 },
    { lat: 48.3984837, lng: 18.8942471 },
    { lat: 48.3983974, lng: 18.8939716 },
    { lat: 48.3984063, lng: 18.8937108 },
    { lat: 48.3983819, lng: 18.8931289 },
    { lat: 48.3982556, lng: 18.8929531 },
    { lat: 48.3976884, lng: 18.8921933 },
    { lat: 48.3975879, lng: 18.8918821 },
    { lat: 48.3970982, lng: 18.8912925 },
    { lat: 48.3966522, lng: 18.890581 },
    { lat: 48.3964637, lng: 18.8900761 },
    { lat: 48.3960538, lng: 18.8894584 },
    { lat: 48.3956545, lng: 18.888778 },
    { lat: 48.3953853, lng: 18.8884163 },
    { lat: 48.3948277, lng: 18.8880795 },
    { lat: 48.3946224, lng: 18.8877061 },
    { lat: 48.3943043, lng: 18.8875098 },
    { lat: 48.3937103, lng: 18.8874696 },
    { lat: 48.3934531, lng: 18.8871434 },
    { lat: 48.3931837, lng: 18.8871206 },
    { lat: 48.3928546, lng: 18.8869519 },
    { lat: 48.3927253, lng: 18.8868063 },
    { lat: 48.3926094, lng: 18.886657 },
    { lat: 48.3925486, lng: 18.885616 },
    { lat: 48.3926388, lng: 18.8851145 },
    { lat: 48.3925921, lng: 18.8846051 },
    { lat: 48.392746, lng: 18.8842249 },
    { lat: 48.3927667, lng: 18.8839936 },
    { lat: 48.3925027, lng: 18.8835547 },
    { lat: 48.3924174, lng: 18.8833241 },
    { lat: 48.3923854, lng: 18.8830565 },
    { lat: 48.39244, lng: 18.8820648 },
    { lat: 48.3925625, lng: 18.8815339 },
    { lat: 48.3927841, lng: 18.8806345 },
    { lat: 48.393189, lng: 18.8790019 },
    { lat: 48.3934298, lng: 18.8779739 },
    { lat: 48.3937105, lng: 18.8776916 },
    { lat: 48.3942135, lng: 18.8775624 },
    { lat: 48.3944204, lng: 18.877375 },
    { lat: 48.3949274, lng: 18.876671 },
    { lat: 48.3951435, lng: 18.8762511 },
    { lat: 48.3957124, lng: 18.875307 },
    { lat: 48.3959174, lng: 18.8746958 },
    { lat: 48.396059, lng: 18.8739689 },
    { lat: 48.3963908, lng: 18.8732143 },
    { lat: 48.3964832, lng: 18.8726521 },
    { lat: 48.3965905, lng: 18.8723518 },
    { lat: 48.3967745, lng: 18.8719413 },
    { lat: 48.3970969, lng: 18.8713511 },
    { lat: 48.3971623, lng: 18.8712689 },
    { lat: 48.3971671, lng: 18.8712059 },
    { lat: 48.3966454, lng: 18.8711308 },
    { lat: 48.3961274, lng: 18.8712302 },
    { lat: 48.39578, lng: 18.8713784 },
    { lat: 48.395479, lng: 18.8714889 },
    { lat: 48.3951505, lng: 18.8717193 },
    { lat: 48.3949178, lng: 18.8717973 },
    { lat: 48.3945085, lng: 18.8721696 },
    { lat: 48.3938939, lng: 18.8724928 },
    { lat: 48.3936529, lng: 18.8731522 },
    { lat: 48.3934428, lng: 18.8732737 },
    { lat: 48.3933259, lng: 18.8735386 },
    { lat: 48.3931007, lng: 18.8738084 },
    { lat: 48.3925755, lng: 18.874032 },
    { lat: 48.3923832, lng: 18.8740822 },
    { lat: 48.3922208, lng: 18.8741495 },
    { lat: 48.3919994, lng: 18.8742265 },
    { lat: 48.3915344, lng: 18.8745687 },
    { lat: 48.3915237, lng: 18.8745352 },
    { lat: 48.3912364, lng: 18.8747377 },
    { lat: 48.3911735, lng: 18.8750002 },
    { lat: 48.3910478, lng: 18.8750997 },
    { lat: 48.3909379, lng: 18.8753272 },
    { lat: 48.3907135, lng: 18.8754369 },
    { lat: 48.3906827, lng: 18.8756232 },
    { lat: 48.3906643, lng: 18.8756186 },
    { lat: 48.3902853, lng: 18.8759653 },
    { lat: 48.3899081, lng: 18.8758225 },
    { lat: 48.3899184, lng: 18.8758482 },
    { lat: 48.3895279, lng: 18.8761444 },
    { lat: 48.389395, lng: 18.8762753 },
    { lat: 48.3891008, lng: 18.876215 },
    { lat: 48.3889744, lng: 18.8762568 },
    { lat: 48.3887428, lng: 18.8765751 },
    { lat: 48.3886562, lng: 18.8767275 },
    { lat: 48.3885355, lng: 18.8767505 },
    { lat: 48.3883144, lng: 18.8770067 },
    { lat: 48.3881486, lng: 18.8774234 },
    { lat: 48.3880166, lng: 18.8776148 },
    { lat: 48.3878228, lng: 18.8777053 },
    { lat: 48.387617, lng: 18.8779564 },
    { lat: 48.387408, lng: 18.8778815 },
    { lat: 48.3872321, lng: 18.8779699 },
    { lat: 48.3868959, lng: 18.8778577 },
    { lat: 48.3868179, lng: 18.8777246 },
    { lat: 48.3867, lng: 18.8777522 },
    { lat: 48.3866225, lng: 18.8776936 },
    { lat: 48.3863996, lng: 18.8776608 },
    { lat: 48.3862914, lng: 18.8774024 },
    { lat: 48.3857101, lng: 18.8768007 },
    { lat: 48.3856507, lng: 18.8766122 },
    { lat: 48.3855049, lng: 18.8764125 },
    { lat: 48.3853933, lng: 18.8761747 },
    { lat: 48.3851674, lng: 18.8759915 },
    { lat: 48.3849557, lng: 18.8758705 },
    { lat: 48.3845984, lng: 18.8757187 },
    { lat: 48.3842987, lng: 18.8757669 },
    { lat: 48.3840727, lng: 18.8759335 },
    { lat: 48.3838335, lng: 18.8759274 },
    { lat: 48.3835077, lng: 18.8757793 },
    { lat: 48.3833564, lng: 18.8759687 },
    { lat: 48.3833167, lng: 18.8761422 },
    { lat: 48.3831727, lng: 18.8761672 },
    { lat: 48.3830752, lng: 18.8766139 },
    { lat: 48.3828449, lng: 18.8768491 },
    { lat: 48.382794, lng: 18.8774339 },
    { lat: 48.3826994, lng: 18.8774553 },
    { lat: 48.3824594, lng: 18.8779559 },
    { lat: 48.382312, lng: 18.8779838 },
    { lat: 48.3821853, lng: 18.8780664 },
    { lat: 48.3820931, lng: 18.8782432 },
    { lat: 48.3819859, lng: 18.878261 },
    { lat: 48.3818454, lng: 18.8783825 },
    { lat: 48.3817947, lng: 18.8786204 },
    { lat: 48.3816635, lng: 18.8787024 },
    { lat: 48.3814022, lng: 18.8791399 },
    { lat: 48.3812013, lng: 18.879312 },
    { lat: 48.3811092, lng: 18.879581 },
    { lat: 48.3810873, lng: 18.8795907 },
    { lat: 48.3809804, lng: 18.8796166 },
    { lat: 48.3809339, lng: 18.8797629 },
    { lat: 48.380792, lng: 18.8798575 },
    { lat: 48.3805924, lng: 18.8798305 },
    { lat: 48.3802792, lng: 18.8800101 },
    { lat: 48.3801667, lng: 18.8802333 },
    { lat: 48.3799758, lng: 18.8802485 },
    { lat: 48.3798152, lng: 18.8803045 },
    { lat: 48.3796955, lng: 18.8804973 },
    { lat: 48.3795744, lng: 18.8805122 },
    { lat: 48.3794632, lng: 18.880715 },
    { lat: 48.3793073, lng: 18.8807534 },
    { lat: 48.3791447, lng: 18.8810296 },
    { lat: 48.3789573, lng: 18.881221 },
    { lat: 48.3788187, lng: 18.8812678 },
    { lat: 48.3787651, lng: 18.8813414 },
    { lat: 48.3787486, lng: 18.8815704 },
    { lat: 48.378674, lng: 18.881694 },
    { lat: 48.3784959, lng: 18.8816542 },
    { lat: 48.3783961, lng: 18.8817943 },
    { lat: 48.3782436, lng: 18.8817241 },
    { lat: 48.3779647, lng: 18.8821292 },
    { lat: 48.3778176, lng: 18.8822074 },
    { lat: 48.3775754, lng: 18.8822357 },
    { lat: 48.377429, lng: 18.8824557 },
    { lat: 48.3772803, lng: 18.8824596 },
    { lat: 48.3770782, lng: 18.882585 },
    { lat: 48.3769246, lng: 18.8825579 },
    { lat: 48.3768623, lng: 18.8825964 },
    { lat: 48.376606, lng: 18.8826739 },
    { lat: 48.3764661, lng: 18.8825878 },
    { lat: 48.37641, lng: 18.8826358 },
    { lat: 48.3763646, lng: 18.8828036 },
    { lat: 48.376221, lng: 18.8829052 },
    { lat: 48.3761076, lng: 18.8828915 },
    { lat: 48.3760253, lng: 18.8830465 },
    { lat: 48.3759079, lng: 18.8830638 },
    { lat: 48.3756433, lng: 18.8833341 },
    { lat: 48.3755202, lng: 18.8836528 },
    { lat: 48.3752462, lng: 18.8835919 },
    { lat: 48.3751482, lng: 18.8837878 },
    { lat: 48.3750794, lng: 18.8838512 },
    { lat: 48.3749432, lng: 18.8838355 },
    { lat: 48.3747899, lng: 18.8840102 },
    { lat: 48.3746648, lng: 18.8839908 },
    { lat: 48.3745158, lng: 18.884137 },
    { lat: 48.3742769, lng: 18.8842521 },
    { lat: 48.3741792, lng: 18.8843455 },
    { lat: 48.3740788, lng: 18.8844101 },
    { lat: 48.3739273, lng: 18.8846186 },
    { lat: 48.3737748, lng: 18.8847031 },
    { lat: 48.3735652, lng: 18.8850308 },
    { lat: 48.373437, lng: 18.8850593 },
    { lat: 48.3733043, lng: 18.8851978 },
    { lat: 48.3732095, lng: 18.8853717 },
    { lat: 48.3728165, lng: 18.8857063 },
    { lat: 48.3726617, lng: 18.8856797 },
    { lat: 48.3724873, lng: 18.8858161 },
    { lat: 48.3724782, lng: 18.8861413 },
    { lat: 48.3724512, lng: 18.8863143 },
    { lat: 48.3725351, lng: 18.8865414 },
    { lat: 48.3725139, lng: 18.8866116 },
    { lat: 48.3723338, lng: 18.88666 },
    { lat: 48.3722805, lng: 18.8868489 },
    { lat: 48.3722591, lng: 18.8870468 },
    { lat: 48.3723157, lng: 18.8871824 },
    { lat: 48.3722167, lng: 18.8874454 },
    { lat: 48.3723334, lng: 18.8876956 },
    { lat: 48.3723108, lng: 18.8876983 },
    { lat: 48.3722669, lng: 18.8877169 },
    { lat: 48.3724247, lng: 18.8894586 },
    { lat: 48.3722604, lng: 18.8895522 },
    { lat: 48.37212, lng: 18.8896704 },
    { lat: 48.3720219, lng: 18.8896276 },
    { lat: 48.3714064, lng: 18.8894151 },
    { lat: 48.3712809, lng: 18.889606 },
    { lat: 48.3711299, lng: 18.8896506 },
    { lat: 48.3712008, lng: 18.8897778 },
    { lat: 48.3710985, lng: 18.8899113 },
    { lat: 48.3711693, lng: 18.8900371 },
    { lat: 48.3711887, lng: 18.8902381 },
    { lat: 48.3714012, lng: 18.8901849 },
    { lat: 48.3717451, lng: 18.8902692 },
    { lat: 48.3717099, lng: 18.8907202 },
    { lat: 48.371603, lng: 18.8909465 },
    { lat: 48.3709423, lng: 18.8912293 },
    { lat: 48.3705278, lng: 18.891588 },
    { lat: 48.3697469, lng: 18.8925321 },
    { lat: 48.3685682, lng: 18.8923299 },
    { lat: 48.3671637, lng: 18.8939268 },
    { lat: 48.3654309, lng: 18.8954811 },
    { lat: 48.3652508, lng: 18.8950968 },
    { lat: 48.3639769, lng: 18.8957996 },
    { lat: 48.3639041, lng: 18.8956124 },
    { lat: 48.3624497, lng: 18.8964865 },
    { lat: 48.36101, lng: 18.8973419 },
    { lat: 48.3600327, lng: 18.898043 },
    { lat: 48.3587311, lng: 18.8987989 },
    { lat: 48.3573123, lng: 18.8994493 },
    { lat: 48.3572832, lng: 18.8992808 },
    { lat: 48.3562476, lng: 18.8998801 },
    { lat: 48.3557773, lng: 18.9003164 },
    { lat: 48.3548085, lng: 18.9010745 },
    { lat: 48.3540224, lng: 18.9017684 },
    { lat: 48.3539491, lng: 18.9018134 },
    { lat: 48.3537742, lng: 18.9015928 },
    { lat: 48.3536143, lng: 18.9015947 },
    { lat: 48.3534802, lng: 18.9015742 },
    { lat: 48.3533634, lng: 18.9014311 },
    { lat: 48.353348, lng: 18.9018317 },
    { lat: 48.3531373, lng: 18.9021406 },
    { lat: 48.3529574, lng: 18.9021938 },
    { lat: 48.3529564, lng: 18.9018667 },
    { lat: 48.3524112, lng: 18.9018565 },
    { lat: 48.3519534, lng: 18.9025578 },
    { lat: 48.3513208, lng: 18.9032515 },
    { lat: 48.3506833, lng: 18.9036398 },
    { lat: 48.3502714, lng: 18.9038711 },
    { lat: 48.3499905, lng: 18.9038049 },
    { lat: 48.3495301, lng: 18.9042325 },
    { lat: 48.3491227, lng: 18.904683 },
    { lat: 48.3486464, lng: 18.9052879 },
    { lat: 48.3480882, lng: 18.9061589 },
    { lat: 48.3479053, lng: 18.9067438 },
    { lat: 48.3476751, lng: 18.9075699 },
    { lat: 48.3474124, lng: 18.9084846 },
    { lat: 48.347052, lng: 18.9093435 },
    { lat: 48.3467358, lng: 18.9098996 },
    { lat: 48.3459454, lng: 18.9103145 },
    { lat: 48.3455032, lng: 18.9107937 },
    { lat: 48.3454022, lng: 18.9113262 },
    { lat: 48.345129, lng: 18.9119974 },
    { lat: 48.3449627, lng: 18.9125346 },
    { lat: 48.3447218, lng: 18.9130205 },
    { lat: 48.3443402, lng: 18.9134927 },
    { lat: 48.343892, lng: 18.9142668 },
    { lat: 48.3433761, lng: 18.9149332 },
    { lat: 48.3430176, lng: 18.915458 },
    { lat: 48.3428347, lng: 18.9156727 },
    { lat: 48.3426096, lng: 18.9158747 },
    { lat: 48.3424218, lng: 18.9160822 },
    { lat: 48.3422151, lng: 18.9165128 },
    { lat: 48.3420487, lng: 18.9169149 },
    { lat: 48.341788, lng: 18.9176097 },
    { lat: 48.3413407, lng: 18.9191244 },
    { lat: 48.3412139, lng: 18.9190494 },
    { lat: 48.3404337, lng: 18.9207115 },
    { lat: 48.3401408, lng: 18.9207182 },
    { lat: 48.3399753, lng: 18.9204816 },
    { lat: 48.3398135, lng: 18.9204061 },
    { lat: 48.3396805, lng: 18.9204697 },
    { lat: 48.3393843, lng: 18.9204814 },
    { lat: 48.3391254, lng: 18.9207291 },
    { lat: 48.3390722, lng: 18.9216004 },
    { lat: 48.3387628, lng: 18.922422 },
    { lat: 48.3384918, lng: 18.9224127 },
    { lat: 48.3383451, lng: 18.9225651 },
    { lat: 48.338199, lng: 18.9225948 },
    { lat: 48.3381082, lng: 18.9226954 },
    { lat: 48.3372825, lng: 18.9229266 },
    { lat: 48.3370058, lng: 18.92283 },
    { lat: 48.3368243, lng: 18.9228107 },
    { lat: 48.3366966, lng: 18.9229605 },
    { lat: 48.33664, lng: 18.9229111 },
    { lat: 48.3365345, lng: 18.9229637 },
    { lat: 48.3365403, lng: 18.9232292 },
    { lat: 48.3357254, lng: 18.923344 },
    { lat: 48.3354347, lng: 18.9233926 },
    { lat: 48.3350133, lng: 18.9235279 },
    { lat: 48.334332, lng: 18.9236137 },
    { lat: 48.3342447, lng: 18.9235157 },
    { lat: 48.3338725, lng: 18.9234912 },
    { lat: 48.3335577, lng: 18.9236631 },
    { lat: 48.333309, lng: 18.9237395 },
    { lat: 48.3331634, lng: 18.9239119 },
    { lat: 48.3330188, lng: 18.9242331 },
    { lat: 48.3329104, lng: 18.9245837 },
    { lat: 48.3327035, lng: 18.9251152 },
    { lat: 48.3325392, lng: 18.9254071 },
    { lat: 48.3323804, lng: 18.9258288 },
    { lat: 48.3316647, lng: 18.9269943 },
    { lat: 48.331575, lng: 18.927535 },
    { lat: 48.331761, lng: 18.92783 },
    { lat: 48.332067, lng: 18.927862 },
    { lat: 48.332295, lng: 18.927969 },
    { lat: 48.332669, lng: 18.928509 },
    { lat: 48.332969, lng: 18.928797 },
    { lat: 48.333408, lng: 18.929307 },
    { lat: 48.333446, lng: 18.929473 },
    { lat: 48.333483, lng: 18.92964 },
    { lat: 48.333619, lng: 18.930067 },
    { lat: 48.333866, lng: 18.930544 },
    { lat: 48.334165, lng: 18.93109 },
    { lat: 48.334253, lng: 18.931278 },
    { lat: 48.33438, lng: 18.931587 },
    { lat: 48.334584, lng: 18.932141 },
    { lat: 48.334765, lng: 18.93252 },
    { lat: 48.3349, lng: 18.932859 },
    { lat: 48.335045, lng: 18.933268 },
    { lat: 48.33515, lng: 18.933632 },
    { lat: 48.335208, lng: 18.934177 },
    { lat: 48.335238, lng: 18.934319 },
    { lat: 48.335361, lng: 18.934904 },
    { lat: 48.335382, lng: 18.935108 },
    { lat: 48.335498, lng: 18.935485 },
    { lat: 48.335512, lng: 18.935561 },
    { lat: 48.335546, lng: 18.936094 },
    { lat: 48.335608, lng: 18.936559 },
    { lat: 48.335635, lng: 18.936631 },
    { lat: 48.335634, lng: 18.937155 },
    { lat: 48.336184, lng: 18.938552 },
    { lat: 48.336715, lng: 18.939564 },
    { lat: 48.337183, lng: 18.940457 },
    { lat: 48.337131, lng: 18.94055 },
    { lat: 48.337025, lng: 18.940855 },
    { lat: 48.336725, lng: 18.941423 },
    { lat: 48.336803, lng: 18.941498 },
    { lat: 48.336642, lng: 18.941786 },
    { lat: 48.336526, lng: 18.942132 },
    { lat: 48.336312, lng: 18.942787 },
    { lat: 48.336095, lng: 18.943185 },
    { lat: 48.33592, lng: 18.943543 },
    { lat: 48.335767, lng: 18.943888 },
    { lat: 48.335679, lng: 18.943696 },
    { lat: 48.335561, lng: 18.943826 },
    { lat: 48.335311, lng: 18.944289 },
    { lat: 48.335323, lng: 18.944538 },
    { lat: 48.33515, lng: 18.944918 },
    { lat: 48.334771, lng: 18.945632 },
    { lat: 48.334773, lng: 18.945964 },
    { lat: 48.334693, lng: 18.946272 },
    { lat: 48.334529, lng: 18.946355 },
    { lat: 48.334392, lng: 18.94666 },
    { lat: 48.33425, lng: 18.947103 },
    { lat: 48.334189, lng: 18.947567 },
    { lat: 48.334103, lng: 18.94788 },
    { lat: 48.334098, lng: 18.948364 },
    { lat: 48.334126, lng: 18.948599 },
    { lat: 48.33413, lng: 18.948795 },
    { lat: 48.334107, lng: 18.949034 },
    { lat: 48.334193, lng: 18.94934 },
    { lat: 48.334503, lng: 18.949776 },
    { lat: 48.334521, lng: 18.949901 },
    { lat: 48.334642, lng: 18.950076 },
    { lat: 48.334784, lng: 18.950366 },
    { lat: 48.33486, lng: 18.95066 },
    { lat: 48.335216, lng: 18.951199 },
    { lat: 48.335293, lng: 18.951295 },
    { lat: 48.33542, lng: 18.951608 },
    { lat: 48.335491, lng: 18.951863 },
    { lat: 48.3356, lng: 18.952043 },
    { lat: 48.335648, lng: 18.952205 },
    { lat: 48.335757, lng: 18.952397 },
    { lat: 48.335893, lng: 18.952545 },
    { lat: 48.336038, lng: 18.952701 },
    { lat: 48.336211, lng: 18.952734 },
    { lat: 48.336204, lng: 18.952849 },
    { lat: 48.33625, lng: 18.953023 },
    { lat: 48.336254, lng: 18.95304 },
    { lat: 48.336298, lng: 18.95316 },
    { lat: 48.336641, lng: 18.953741 },
    { lat: 48.336804, lng: 18.953731 },
    { lat: 48.336951, lng: 18.953881 },
    { lat: 48.336993, lng: 18.953943 },
    { lat: 48.337005, lng: 18.953962 },
    { lat: 48.337028, lng: 18.953996 },
    { lat: 48.337172, lng: 18.954212 },
    { lat: 48.337315, lng: 18.954167 },
    { lat: 48.337707, lng: 18.953973 },
    { lat: 48.337918, lng: 18.953841 },
    { lat: 48.338098, lng: 18.953667 },
    { lat: 48.338406, lng: 18.953348 },
    { lat: 48.338639, lng: 18.953054 },
    { lat: 48.338855, lng: 18.952839 },
    { lat: 48.33907, lng: 18.953027 },
    { lat: 48.339257, lng: 18.953189 },
    { lat: 48.339339, lng: 18.953131 },
    { lat: 48.339655, lng: 18.95327 },
    { lat: 48.339836, lng: 18.953499 },
    { lat: 48.339927, lng: 18.953606 },
    { lat: 48.34031, lng: 18.95319 },
    { lat: 48.340671, lng: 18.952794 },
    { lat: 48.34098, lng: 18.952271 },
    { lat: 48.341025, lng: 18.952277 },
    { lat: 48.341286, lng: 18.952094 },
    { lat: 48.341514, lng: 18.951892 },
    { lat: 48.341594, lng: 18.951922 },
    { lat: 48.341793, lng: 18.951715 },
    { lat: 48.341974, lng: 18.951421 },
    { lat: 48.34227, lng: 18.951032 },
    { lat: 48.342781, lng: 18.950637 },
    { lat: 48.343057, lng: 18.95027 },
    { lat: 48.343418, lng: 18.949843 },
    { lat: 48.343882, lng: 18.949308 },
    { lat: 48.344062, lng: 18.949229 },
    { lat: 48.34413, lng: 18.949217 },
    { lat: 48.34425, lng: 18.949169 },
    { lat: 48.344397, lng: 18.949058 },
    { lat: 48.344379, lng: 18.948535 },
    { lat: 48.344449, lng: 18.947489 },
    { lat: 48.344528, lng: 18.946872 },
    { lat: 48.344624, lng: 18.946739 },
    { lat: 48.344912, lng: 18.946726 },
    { lat: 48.345343, lng: 18.946596 },
    { lat: 48.34565, lng: 18.946361 },
    { lat: 48.345889, lng: 18.945994 },
    { lat: 48.345902, lng: 18.945979 },
    { lat: 48.346002, lng: 18.946075 },
    { lat: 48.346123, lng: 18.94591 },
    { lat: 48.346237, lng: 18.945674 },
    { lat: 48.346272, lng: 18.945558 },
    { lat: 48.346435, lng: 18.945709 },
    { lat: 48.346666, lng: 18.94592 },
    { lat: 48.3469, lng: 18.946114 },
    { lat: 48.347021, lng: 18.946185 },
    { lat: 48.347058, lng: 18.945899 },
    { lat: 48.347079, lng: 18.945735 },
    { lat: 48.347097, lng: 18.945685 },
    { lat: 48.347288, lng: 18.94566 },
    { lat: 48.347446, lng: 18.945884 },
    { lat: 48.347556, lng: 18.945733 },
    { lat: 48.347757, lng: 18.945748 },
    { lat: 48.348081, lng: 18.945696 },
    { lat: 48.348131, lng: 18.94557 },
    { lat: 48.348333, lng: 18.945534 },
    { lat: 48.348429, lng: 18.945433 },
    { lat: 48.348474, lng: 18.94548 },
    { lat: 48.348491, lng: 18.945477 },
    { lat: 48.348515, lng: 18.945442 },
    { lat: 48.348584, lng: 18.945198 },
    { lat: 48.348705, lng: 18.945094 },
    { lat: 48.348765, lng: 18.945068 },
    { lat: 48.348869, lng: 18.945091 },
    { lat: 48.348966, lng: 18.944931 },
    { lat: 48.349002, lng: 18.944907 },
    { lat: 48.349031, lng: 18.944895 },
    { lat: 48.349118, lng: 18.944915 },
    { lat: 48.349214, lng: 18.944833 },
    { lat: 48.349312, lng: 18.944531 },
    { lat: 48.349337, lng: 18.944403 },
    { lat: 48.349399, lng: 18.94429 },
    { lat: 48.349458, lng: 18.944249 },
    { lat: 48.349562, lng: 18.944101 },
    { lat: 48.349614, lng: 18.943857 },
    { lat: 48.349754, lng: 18.943806 },
    { lat: 48.349824, lng: 18.943666 },
    { lat: 48.350021, lng: 18.943589 },
    { lat: 48.350069, lng: 18.943532 },
    { lat: 48.350129, lng: 18.943366 },
    { lat: 48.350191, lng: 18.943197 },
    { lat: 48.350208, lng: 18.94324 },
    { lat: 48.350315, lng: 18.943105 },
    { lat: 48.35041, lng: 18.943012 },
    { lat: 48.350587, lng: 18.942869 },
    { lat: 48.35059, lng: 18.942866 },
    { lat: 48.350695, lng: 18.942792 },
    { lat: 48.350823, lng: 18.942732 },
    { lat: 48.350921, lng: 18.942587 },
    { lat: 48.351077, lng: 18.942527 },
    { lat: 48.35114, lng: 18.942454 },
    { lat: 48.351279, lng: 18.942365 },
    { lat: 48.351363, lng: 18.942216 },
    { lat: 48.351417, lng: 18.941961 },
    { lat: 48.351388, lng: 18.941934 },
    { lat: 48.351407, lng: 18.941876 },
    { lat: 48.351488, lng: 18.941743 },
    { lat: 48.351535, lng: 18.941729 },
    { lat: 48.351571, lng: 18.941674 },
    { lat: 48.351591, lng: 18.941512 },
    { lat: 48.351635, lng: 18.941403 },
    { lat: 48.351674, lng: 18.941347 },
    { lat: 48.351849, lng: 18.94136 },
    { lat: 48.351926, lng: 18.941277 },
    { lat: 48.351976, lng: 18.9408 },
    { lat: 48.352075, lng: 18.940793 },
    { lat: 48.352251, lng: 18.940694 },
    { lat: 48.352285, lng: 18.940713 },
    { lat: 48.35238, lng: 18.940606 },
    { lat: 48.352463, lng: 18.940266 },
    { lat: 48.352489, lng: 18.940299 },
    { lat: 48.352545, lng: 18.940233 },
    { lat: 48.352818, lng: 18.940075 },
    { lat: 48.353019, lng: 18.939866 },
    { lat: 48.353011, lng: 18.939808 },
    { lat: 48.353005, lng: 18.939765 },
    { lat: 48.35332, lng: 18.939549 },
    { lat: 48.35345, lng: 18.939575 },
    { lat: 48.353528, lng: 18.939505 },
    { lat: 48.3537, lng: 18.93948 },
    { lat: 48.353808, lng: 18.93947 },
    { lat: 48.353937, lng: 18.939492 },
    { lat: 48.35398, lng: 18.939519 },
    { lat: 48.354056, lng: 18.93953 },
    { lat: 48.354231, lng: 18.939269 },
    { lat: 48.354292, lng: 18.939046 },
    { lat: 48.354586, lng: 18.938649 },
    { lat: 48.354627, lng: 18.93847 },
    { lat: 48.354636, lng: 18.938191 },
    { lat: 48.354761, lng: 18.938059 },
    { lat: 48.354798, lng: 18.93784 },
    { lat: 48.354871, lng: 18.937817 },
    { lat: 48.35494, lng: 18.937772 },
    { lat: 48.35513, lng: 18.937634 },
    { lat: 48.355193, lng: 18.93756 },
    { lat: 48.355505, lng: 18.937347 },
    { lat: 48.355689, lng: 18.937173 },
    { lat: 48.355774, lng: 18.937189 },
    { lat: 48.355879, lng: 18.937323 },
    { lat: 48.355976, lng: 18.937282 },
    { lat: 48.356173, lng: 18.93734 },
    { lat: 48.356453, lng: 18.937227 },
    { lat: 48.356497, lng: 18.937222 },
    { lat: 48.356677, lng: 18.937229 },
    { lat: 48.356821, lng: 18.937201 },
    { lat: 48.357116, lng: 18.937464 },
    { lat: 48.357379, lng: 18.937482 },
    { lat: 48.35748, lng: 18.937556 },
    { lat: 48.357551, lng: 18.937755 },
    { lat: 48.357605, lng: 18.937827 },
    { lat: 48.357678, lng: 18.937873 },
    { lat: 48.357941, lng: 18.938043 },
    { lat: 48.358047, lng: 18.938317 },
    { lat: 48.358083, lng: 18.938383 },
    { lat: 48.358297, lng: 18.938669 },
    { lat: 48.358293, lng: 18.939418 },
    { lat: 48.358344, lng: 18.939568 },
    { lat: 48.358345, lng: 18.939771 },
    { lat: 48.358331, lng: 18.940105 },
    { lat: 48.358312, lng: 18.940299 },
    { lat: 48.358215, lng: 18.941082 },
    { lat: 48.358218, lng: 18.941105 },
    { lat: 48.358262, lng: 18.941192 },
    { lat: 48.358283, lng: 18.941234 },
    { lat: 48.358401, lng: 18.941348 },
    { lat: 48.358584, lng: 18.941638 },
    { lat: 48.358713, lng: 18.941646 },
    { lat: 48.358754, lng: 18.941564 },
    { lat: 48.358858, lng: 18.941609 },
    { lat: 48.359006, lng: 18.941583 },
    { lat: 48.359248, lng: 18.941616 },
    { lat: 48.359389, lng: 18.941635 },
    { lat: 48.359456, lng: 18.941696 },
    { lat: 48.359522, lng: 18.941618 },
    { lat: 48.359659, lng: 18.941689 },
    { lat: 48.359949, lng: 18.941896 },
    { lat: 48.360094, lng: 18.941971 },
    { lat: 48.360284, lng: 18.942007 },
    { lat: 48.360573, lng: 18.942018 },
    { lat: 48.36068, lng: 18.94211 },
    { lat: 48.360795, lng: 18.942124 },
    { lat: 48.36088, lng: 18.942069 },
    { lat: 48.360984, lng: 18.942064 },
    { lat: 48.361083, lng: 18.942089 },
    { lat: 48.361338, lng: 18.942034 },
    { lat: 48.361396, lng: 18.942068 },
    { lat: 48.361759, lng: 18.942282 },
    { lat: 48.36182, lng: 18.942256 },
    { lat: 48.361839, lng: 18.942191 },
    { lat: 48.361984, lng: 18.942152 },
    { lat: 48.361999, lng: 18.942007 },
    { lat: 48.362194, lng: 18.941721 },
    { lat: 48.362206, lng: 18.941612 },
    { lat: 48.362273, lng: 18.941563 },
    { lat: 48.362296, lng: 18.941441 },
    { lat: 48.362432, lng: 18.941251 },
    { lat: 48.362636, lng: 18.941091 },
    { lat: 48.362801, lng: 18.941039 },
    { lat: 48.362971, lng: 18.940886 },
    { lat: 48.363112, lng: 18.940514 },
    { lat: 48.363559, lng: 18.940283 },
    { lat: 48.363667, lng: 18.939938 },
    { lat: 48.363777, lng: 18.939876 },
    { lat: 48.3638, lng: 18.939768 },
    { lat: 48.363865, lng: 18.939719 },
    { lat: 48.364514, lng: 18.940413 },
    { lat: 48.365854, lng: 18.941867 },
    { lat: 48.366807, lng: 18.942904 },
    { lat: 48.367035, lng: 18.943815 },
    { lat: 48.367078, lng: 18.944186 },
    { lat: 48.367041, lng: 18.944748 },
    { lat: 48.366954, lng: 18.944993 },
    { lat: 48.366946, lng: 18.945158 },
    { lat: 48.366886, lng: 18.94537 },
    { lat: 48.366882, lng: 18.94558 },
    { lat: 48.366933, lng: 18.945963 },
    { lat: 48.36698, lng: 18.946144 },
    { lat: 48.36707, lng: 18.946503 },
    { lat: 48.367241, lng: 18.946845 },
    { lat: 48.367281, lng: 18.947014 },
    { lat: 48.367204, lng: 18.947345 },
    { lat: 48.367146, lng: 18.947662 },
    { lat: 48.367136, lng: 18.947975 },
    { lat: 48.367184, lng: 18.948458 },
    { lat: 48.367202, lng: 18.948942 },
    { lat: 48.367191, lng: 18.949104 },
    { lat: 48.367195, lng: 18.949136 },
    { lat: 48.367299, lng: 18.949479 },
    { lat: 48.367569, lng: 18.949888 },
    { lat: 48.367669, lng: 18.949973 },
    { lat: 48.368083, lng: 18.950346 },
    { lat: 48.368315, lng: 18.950434 },
    { lat: 48.368596, lng: 18.950729 },
    { lat: 48.36912, lng: 18.951035 },
    { lat: 48.369386, lng: 18.951168 },
    { lat: 48.369645, lng: 18.951263 },
    { lat: 48.369983, lng: 18.951572 },
    { lat: 48.370334, lng: 18.951864 },
    { lat: 48.370516, lng: 18.952066 },
    { lat: 48.370827, lng: 18.952286 },
    { lat: 48.371149, lng: 18.952732 },
    { lat: 48.371413, lng: 18.953054 },
    { lat: 48.371663, lng: 18.953304 },
    { lat: 48.371905, lng: 18.953655 },
    { lat: 48.372145, lng: 18.953756 },
    { lat: 48.372178, lng: 18.953782 },
    { lat: 48.372434, lng: 18.95399 },
    { lat: 48.37277, lng: 18.954504 },
    { lat: 48.372927, lng: 18.954778 },
    { lat: 48.373223, lng: 18.954988 },
    { lat: 48.373466, lng: 18.955063 },
    { lat: 48.373633, lng: 18.955092 },
    { lat: 48.373692, lng: 18.955085 },
    { lat: 48.373829, lng: 18.955015 },
    { lat: 48.373889, lng: 18.95502 },
    { lat: 48.374093, lng: 18.955146 },
    { lat: 48.37451, lng: 18.955332 },
    { lat: 48.37513, lng: 18.955564 },
    { lat: 48.375448, lng: 18.955635 },
    { lat: 48.376098, lng: 18.955678 },
    { lat: 48.376281, lng: 18.955514 },
    { lat: 48.376469, lng: 18.955401 },
    { lat: 48.376577, lng: 18.955301 },
    { lat: 48.376845, lng: 18.955003 },
  ],
  Dekýš: [
    { lat: 48.4179083, lng: 18.8422787 },
    { lat: 48.4179444, lng: 18.8420416 },
    { lat: 48.4179644, lng: 18.8415778 },
    { lat: 48.4179854, lng: 18.8408174 },
    { lat: 48.4180016, lng: 18.8403906 },
    { lat: 48.418013, lng: 18.8402653 },
    { lat: 48.4180469, lng: 18.8400498 },
    { lat: 48.4181017, lng: 18.8396503 },
    { lat: 48.418041, lng: 18.8393987 },
    { lat: 48.417887, lng: 18.8391283 },
    { lat: 48.4178896, lng: 18.8389684 },
    { lat: 48.4179114, lng: 18.8385368 },
    { lat: 48.4179102, lng: 18.8385146 },
    { lat: 48.4179236, lng: 18.838513 },
    { lat: 48.4180004, lng: 18.8384901 },
    { lat: 48.4180382, lng: 18.83848 },
    { lat: 48.4180714, lng: 18.838469 },
    { lat: 48.4181228, lng: 18.8384398 },
    { lat: 48.4181656, lng: 18.8384135 },
    { lat: 48.4181485, lng: 18.8383658 },
    { lat: 48.4180869, lng: 18.8381594 },
    { lat: 48.4180534, lng: 18.8379936 },
    { lat: 48.4180539, lng: 18.8379808 },
    { lat: 48.4180753, lng: 18.8379829 },
    { lat: 48.4181059, lng: 18.837986 },
    { lat: 48.4180965, lng: 18.8377422 },
    { lat: 48.4181224, lng: 18.8375006 },
    { lat: 48.4181583, lng: 18.8373246 },
    { lat: 48.4182178, lng: 18.8370836 },
    { lat: 48.4183269, lng: 18.8366877 },
    { lat: 48.4185316, lng: 18.8359884 },
    { lat: 48.4185962, lng: 18.8357603 },
    { lat: 48.4186535, lng: 18.8354948 },
    { lat: 48.4186778, lng: 18.8352463 },
    { lat: 48.4186791, lng: 18.8352298 },
    { lat: 48.4186785, lng: 18.8350016 },
    { lat: 48.4186607, lng: 18.8347932 },
    { lat: 48.4186317, lng: 18.8346255 },
    { lat: 48.4185865, lng: 18.8344491 },
    { lat: 48.4185242, lng: 18.8342747 },
    { lat: 48.4183774, lng: 18.8339881 },
    { lat: 48.4179026, lng: 18.8331013 },
    { lat: 48.4177241, lng: 18.8328139 },
    { lat: 48.4174331, lng: 18.8324279 },
    { lat: 48.4172205, lng: 18.8321772 },
    { lat: 48.4166097, lng: 18.8315173 },
    { lat: 48.4162047, lng: 18.8310617 },
    { lat: 48.4160662, lng: 18.8308254 },
    { lat: 48.4159869, lng: 18.8306075 },
    { lat: 48.4158942, lng: 18.830321 },
    { lat: 48.4158025, lng: 18.8300542 },
    { lat: 48.4157145, lng: 18.82988 },
    { lat: 48.4155435, lng: 18.8296106 },
    { lat: 48.4154326, lng: 18.8294064 },
    { lat: 48.4153216, lng: 18.8291636 },
    { lat: 48.4152643, lng: 18.8289716 },
    { lat: 48.4152281, lng: 18.8287323 },
    { lat: 48.4151993, lng: 18.8287404 },
    { lat: 48.4151813, lng: 18.8287453 },
    { lat: 48.4151515, lng: 18.8287535 },
    { lat: 48.415148, lng: 18.8287542 },
    { lat: 48.4150636, lng: 18.8286041 },
    { lat: 48.4150349, lng: 18.8285535 },
    { lat: 48.4150267, lng: 18.8285052 },
    { lat: 48.4145604, lng: 18.8257997 },
    { lat: 48.4142726, lng: 18.8252569 },
    { lat: 48.4140377, lng: 18.8250592 },
    { lat: 48.4137137, lng: 18.8248558 },
    { lat: 48.4135283, lng: 18.8247847 },
    { lat: 48.4134468, lng: 18.8247535 },
    { lat: 48.4130099, lng: 18.8251104 },
    { lat: 48.4129649, lng: 18.824792 },
    { lat: 48.4129617, lng: 18.8247718 },
    { lat: 48.4129601, lng: 18.8247645 },
    { lat: 48.4129546, lng: 18.8247218 },
    { lat: 48.4129042, lng: 18.8247303 },
    { lat: 48.4123992, lng: 18.8246483 },
    { lat: 48.4122374, lng: 18.8246128 },
    { lat: 48.4121555, lng: 18.8245795 },
    { lat: 48.4120561, lng: 18.8245128 },
    { lat: 48.411938, lng: 18.824388 },
    { lat: 48.4118266, lng: 18.8242226 },
    { lat: 48.4117632, lng: 18.8240466 },
    { lat: 48.4116755, lng: 18.8237936 },
    { lat: 48.4116366, lng: 18.8236722 },
    { lat: 48.4115929, lng: 18.8235921 },
    { lat: 48.4115309, lng: 18.8235278 },
    { lat: 48.4114496, lng: 18.8234809 },
    { lat: 48.4113967, lng: 18.8234648 },
    { lat: 48.4114034, lng: 18.82342 },
    { lat: 48.4114094, lng: 18.823381 },
    { lat: 48.4113978, lng: 18.823377 },
    { lat: 48.4113909, lng: 18.8233541 },
    { lat: 48.4112275, lng: 18.8232708 },
    { lat: 48.4109226, lng: 18.8230658 },
    { lat: 48.4106795, lng: 18.822773 },
    { lat: 48.4104735, lng: 18.8222028 },
    { lat: 48.4103764, lng: 18.8217014 },
    { lat: 48.4103512, lng: 18.8211986 },
    { lat: 48.410165, lng: 18.8197554 },
    { lat: 48.4101581, lng: 18.8192586 },
    { lat: 48.4104095, lng: 18.818546 },
    { lat: 48.4110132, lng: 18.8179713 },
    { lat: 48.4112163, lng: 18.817592 },
    { lat: 48.4112086, lng: 18.8174001 },
    { lat: 48.4111978, lng: 18.8171476 },
    { lat: 48.4113085, lng: 18.8169155 },
    { lat: 48.411297, lng: 18.8166745 },
    { lat: 48.4114569, lng: 18.8160912 },
    { lat: 48.4115016, lng: 18.8157636 },
    { lat: 48.4115052, lng: 18.8157415 },
    { lat: 48.4115392, lng: 18.8154255 },
    { lat: 48.4115286, lng: 18.8152649 },
    { lat: 48.4113929, lng: 18.8146548 },
    { lat: 48.4113717, lng: 18.8144213 },
    { lat: 48.4114951, lng: 18.8140674 },
    { lat: 48.4115798, lng: 18.8137492 },
    { lat: 48.4116036, lng: 18.8136225 },
    { lat: 48.4116571, lng: 18.8133308 },
    { lat: 48.4117143, lng: 18.8127405 },
    { lat: 48.4117673, lng: 18.8124414 },
    { lat: 48.4117381, lng: 18.8121236 },
    { lat: 48.4117853, lng: 18.8116271 },
    { lat: 48.4117841, lng: 18.8115588 },
    { lat: 48.4117714, lng: 18.8108025 },
    { lat: 48.4117916, lng: 18.8105612 },
    { lat: 48.4117687, lng: 18.8102785 },
    { lat: 48.4116627, lng: 18.8098708 },
    { lat: 48.4114959, lng: 18.8097855 },
    { lat: 48.4114074, lng: 18.8096888 },
    { lat: 48.4113752, lng: 18.809589 },
    { lat: 48.4113036, lng: 18.8094945 },
    { lat: 48.411269, lng: 18.8093485 },
    { lat: 48.4111964, lng: 18.8092577 },
    { lat: 48.4111968, lng: 18.8091132 },
    { lat: 48.4111788, lng: 18.8090312 },
    { lat: 48.4111285, lng: 18.8087972 },
    { lat: 48.4110759, lng: 18.8085521 },
    { lat: 48.4110303, lng: 18.808264 },
    { lat: 48.4108062, lng: 18.8080094 },
    { lat: 48.4108103, lng: 18.8078943 },
    { lat: 48.4106449, lng: 18.807751 },
    { lat: 48.4105394, lng: 18.8076538 },
    { lat: 48.4104939, lng: 18.8076468 },
    { lat: 48.410305, lng: 18.8073697 },
    { lat: 48.4102837, lng: 18.8073384 },
    { lat: 48.4102252, lng: 18.8073194 },
    { lat: 48.4099874, lng: 18.8070415 },
    { lat: 48.4099753, lng: 18.8070274 },
    { lat: 48.4097764, lng: 18.8066971 },
    { lat: 48.4097322, lng: 18.8066686 },
    { lat: 48.4096805, lng: 18.8065441 },
    { lat: 48.4096188, lng: 18.8063378 },
    { lat: 48.4095833, lng: 18.8062192 },
    { lat: 48.4095502, lng: 18.8062124 },
    { lat: 48.4095307, lng: 18.8062084 },
    { lat: 48.4095067, lng: 18.8061374 },
    { lat: 48.4094831, lng: 18.8060713 },
    { lat: 48.40946, lng: 18.8059104 },
    { lat: 48.4093895, lng: 18.8058354 },
    { lat: 48.4093273, lng: 18.8057895 },
    { lat: 48.4092754, lng: 18.805724 },
    { lat: 48.4092156, lng: 18.8056388 },
    { lat: 48.409196, lng: 18.8055222 },
    { lat: 48.4091337, lng: 18.8054117 },
    { lat: 48.4090687, lng: 18.805182 },
    { lat: 48.4090486, lng: 18.8051229 },
    { lat: 48.4090182, lng: 18.8050336 },
    { lat: 48.4089482, lng: 18.8049618 },
    { lat: 48.4088321, lng: 18.8048427 },
    { lat: 48.4086592, lng: 18.8046648 },
    { lat: 48.4086089, lng: 18.8044989 },
    { lat: 48.4085291, lng: 18.8044186 },
    { lat: 48.4084932, lng: 18.8043391 },
    { lat: 48.4084439, lng: 18.8042363 },
    { lat: 48.408423, lng: 18.8041181 },
    { lat: 48.408314, lng: 18.8038688 },
    { lat: 48.4082657, lng: 18.8038095 },
    { lat: 48.4082021, lng: 18.8037315 },
    { lat: 48.408124, lng: 18.8036634 },
    { lat: 48.4076967, lng: 18.803603 },
    { lat: 48.4075709, lng: 18.8035884 },
    { lat: 48.4075045, lng: 18.8034441 },
    { lat: 48.4073183, lng: 18.8034392 },
    { lat: 48.4071902, lng: 18.8034043 },
    { lat: 48.4071194, lng: 18.8032615 },
    { lat: 48.406909, lng: 18.8030529 },
    { lat: 48.4067945, lng: 18.8028489 },
    { lat: 48.4066388, lng: 18.802719 },
    { lat: 48.4065282, lng: 18.8027634 },
    { lat: 48.4063988, lng: 18.8027251 },
    { lat: 48.4062921, lng: 18.8025159 },
    { lat: 48.4060281, lng: 18.8022807 },
    { lat: 48.4059842, lng: 18.8021745 },
    { lat: 48.4058868, lng: 18.8021239 },
    { lat: 48.4058521, lng: 18.8020205 },
    { lat: 48.4057845, lng: 18.8018951 },
    { lat: 48.4057013, lng: 18.8017058 },
    { lat: 48.4056265, lng: 18.8016984 },
    { lat: 48.4055521, lng: 18.8015684 },
    { lat: 48.4053183, lng: 18.8014105 },
    { lat: 48.4052165, lng: 18.8013836 },
    { lat: 48.4052004, lng: 18.8013798 },
    { lat: 48.4051513, lng: 18.8013441 },
    { lat: 48.4050878, lng: 18.8013594 },
    { lat: 48.4049977, lng: 18.8013854 },
    { lat: 48.4047298, lng: 18.801179 },
    { lat: 48.4045349, lng: 18.8010314 },
    { lat: 48.4044676, lng: 18.8008896 },
    { lat: 48.40444, lng: 18.8008354 },
    { lat: 48.4044096, lng: 18.8007744 },
    { lat: 48.4043504, lng: 18.8006562 },
    { lat: 48.4043366, lng: 18.8006276 },
    { lat: 48.4043038, lng: 18.8005609 },
    { lat: 48.4041674, lng: 18.8002225 },
    { lat: 48.404148, lng: 18.8000421 },
    { lat: 48.4041759, lng: 18.7999969 },
    { lat: 48.4041937, lng: 18.7999053 },
    { lat: 48.4041912, lng: 18.7998339 },
    { lat: 48.4041522, lng: 18.7997175 },
    { lat: 48.4041165, lng: 18.7996181 },
    { lat: 48.4041485, lng: 18.799516 },
    { lat: 48.4041801, lng: 18.7993678 },
    { lat: 48.4041181, lng: 18.799284 },
    { lat: 48.4040576, lng: 18.7992039 },
    { lat: 48.4040515, lng: 18.7991964 },
    { lat: 48.4040797, lng: 18.7991295 },
    { lat: 48.4041163, lng: 18.7990351 },
    { lat: 48.404117, lng: 18.7989633 },
    { lat: 48.404037, lng: 18.7982555 },
    { lat: 48.4040357, lng: 18.798146 },
    { lat: 48.4040683, lng: 18.7979704 },
    { lat: 48.404061, lng: 18.7979155 },
    { lat: 48.4040046, lng: 18.7977696 },
    { lat: 48.4039304, lng: 18.7975811 },
    { lat: 48.403867, lng: 18.7973593 },
    { lat: 48.4039156, lng: 18.7969546 },
    { lat: 48.4038912, lng: 18.7967059 },
    { lat: 48.4037798, lng: 18.7963664 },
    { lat: 48.4037316, lng: 18.7959675 },
    { lat: 48.4036303, lng: 18.7958425 },
    { lat: 48.4033175, lng: 18.7955521 },
    { lat: 48.4031963, lng: 18.7954366 },
    { lat: 48.4030838, lng: 18.7952931 },
    { lat: 48.4029042, lng: 18.795028 },
    { lat: 48.4028167, lng: 18.7948247 },
    { lat: 48.4027965, lng: 18.794781 },
    { lat: 48.4027938, lng: 18.794667 },
    { lat: 48.4028064, lng: 18.7946055 },
    { lat: 48.4027668, lng: 18.7944331 },
    { lat: 48.4027164, lng: 18.7942872 },
    { lat: 48.4026858, lng: 18.7941152 },
    { lat: 48.4026306, lng: 18.7939691 },
    { lat: 48.4025906, lng: 18.7938057 },
    { lat: 48.4025031, lng: 18.7936202 },
    { lat: 48.4025445, lng: 18.7933785 },
    { lat: 48.402552, lng: 18.7931367 },
    { lat: 48.4025779, lng: 18.7927932 },
    { lat: 48.4025939, lng: 18.7925631 },
    { lat: 48.402541, lng: 18.7922848 },
    { lat: 48.4024992, lng: 18.7921155 },
    { lat: 48.4024969, lng: 18.792048 },
    { lat: 48.4025333, lng: 18.7918389 },
    { lat: 48.4025548, lng: 18.7916273 },
    { lat: 48.4025236, lng: 18.7914841 },
    { lat: 48.4024907, lng: 18.7913521 },
    { lat: 48.4024447, lng: 18.7912291 },
    { lat: 48.4024549, lng: 18.7911207 },
    { lat: 48.4024498, lng: 18.7910013 },
    { lat: 48.4025311, lng: 18.7908767 },
    { lat: 48.4025383, lng: 18.79078 },
    { lat: 48.4025599, lng: 18.7906344 },
    { lat: 48.402443, lng: 18.7905315 },
    { lat: 48.4025072, lng: 18.7902304 },
    { lat: 48.4025985, lng: 18.7901869 },
    { lat: 48.4026121, lng: 18.7901441 },
    { lat: 48.4026343, lng: 18.7899459 },
    { lat: 48.4025208, lng: 18.7897216 },
    { lat: 48.4025687, lng: 18.7894945 },
    { lat: 48.4026234, lng: 18.7892687 },
    { lat: 48.402728, lng: 18.789093 },
    { lat: 48.4027371, lng: 18.7889425 },
    { lat: 48.4026443, lng: 18.7887242 },
    { lat: 48.4026152, lng: 18.788557 },
    { lat: 48.4026891, lng: 18.7882686 },
    { lat: 48.402717, lng: 18.7881126 },
    { lat: 48.4026742, lng: 18.7878555 },
    { lat: 48.4025652, lng: 18.7875635 },
    { lat: 48.402478, lng: 18.7873666 },
    { lat: 48.402398, lng: 18.7871072 },
    { lat: 48.4024509, lng: 18.7867211 },
    { lat: 48.4025119, lng: 18.7862117 },
    { lat: 48.4024932, lng: 18.7860262 },
    { lat: 48.4024588, lng: 18.785889 },
    { lat: 48.4024014, lng: 18.7857422 },
    { lat: 48.402214, lng: 18.7853524 },
    { lat: 48.4021646, lng: 18.7851854 },
    { lat: 48.4020683, lng: 18.7849201 },
    { lat: 48.4020287, lng: 18.7847906 },
    { lat: 48.4019755, lng: 18.7846153 },
    { lat: 48.4018868, lng: 18.7843862 },
    { lat: 48.4018201, lng: 18.7842327 },
    { lat: 48.4017606, lng: 18.784112 },
    { lat: 48.4017489, lng: 18.7840829 },
    { lat: 48.4017169, lng: 18.7839866 },
    { lat: 48.4016971, lng: 18.7839524 },
    { lat: 48.4015606, lng: 18.7838242 },
    { lat: 48.4014533, lng: 18.7838008 },
    { lat: 48.4014339, lng: 18.783797 },
    { lat: 48.4012085, lng: 18.7836943 },
    { lat: 48.400984, lng: 18.7834978 },
    { lat: 48.4008236, lng: 18.7833018 },
    { lat: 48.4007059, lng: 18.7829918 },
    { lat: 48.4006557, lng: 18.7827692 },
    { lat: 48.4006379, lng: 18.7825794 },
    { lat: 48.4006216, lng: 18.7825085 },
    { lat: 48.4006027, lng: 18.7824284 },
    { lat: 48.4005942, lng: 18.7822854 },
    { lat: 48.4005859, lng: 18.7820433 },
    { lat: 48.4005218, lng: 18.7819391 },
    { lat: 48.40033, lng: 18.7817256 },
    { lat: 48.4002133, lng: 18.7816266 },
    { lat: 48.4000455, lng: 18.7813754 },
    { lat: 48.3999656, lng: 18.7812497 },
    { lat: 48.3997115, lng: 18.780989 },
    { lat: 48.3996213, lng: 18.7808613 },
    { lat: 48.3995233, lng: 18.7805844 },
    { lat: 48.3994097, lng: 18.7803971 },
    { lat: 48.3992323, lng: 18.7802638 },
    { lat: 48.3991631, lng: 18.7801706 },
    { lat: 48.399009, lng: 18.7800498 },
    { lat: 48.3988088, lng: 18.7799728 },
    { lat: 48.3986815, lng: 18.7800192 },
    { lat: 48.3986504, lng: 18.7800304 },
    { lat: 48.3983514, lng: 18.7798534 },
    { lat: 48.3982679, lng: 18.7798119 },
    { lat: 48.3981404, lng: 18.77981 },
    { lat: 48.3979832, lng: 18.7797144 },
    { lat: 48.3977855, lng: 18.7795155 },
    { lat: 48.3976517, lng: 18.7793131 },
    { lat: 48.3974801, lng: 18.7792228 },
    { lat: 48.3973908, lng: 18.7791107 },
    { lat: 48.3972847, lng: 18.7790037 },
    { lat: 48.3971666, lng: 18.7788794 },
    { lat: 48.3969929, lng: 18.778817 },
    { lat: 48.3968471, lng: 18.7787666 },
    { lat: 48.3967271, lng: 18.7787773 },
    { lat: 48.3965671, lng: 18.7787584 },
    { lat: 48.3963595, lng: 18.7786316 },
    { lat: 48.3963055, lng: 18.778571 },
    { lat: 48.396256, lng: 18.7785286 },
    { lat: 48.3961739, lng: 18.7785128 },
    { lat: 48.3960881, lng: 18.7785092 },
    { lat: 48.3959335, lng: 18.7784229 },
    { lat: 48.3958561, lng: 18.7784066 },
    { lat: 48.3957596, lng: 18.7783953 },
    { lat: 48.3955242, lng: 18.7783155 },
    { lat: 48.3953683, lng: 18.7783347 },
    { lat: 48.3951626, lng: 18.7781985 },
    { lat: 48.3949373, lng: 18.778094 },
    { lat: 48.394789, lng: 18.7779346 },
    { lat: 48.3947599, lng: 18.777854 },
    { lat: 48.3947163, lng: 18.77773 },
    { lat: 48.3942258, lng: 18.7775208 },
    { lat: 48.3940059, lng: 18.7775413 },
    { lat: 48.3938959, lng: 18.7775519 },
    { lat: 48.3936544, lng: 18.7773958 },
    { lat: 48.3934301, lng: 18.7772522 },
    { lat: 48.3933266, lng: 18.7771275 },
    { lat: 48.3933205, lng: 18.7770747 },
    { lat: 48.3931954, lng: 18.7767997 },
    { lat: 48.3930723, lng: 18.7768402 },
    { lat: 48.3929278, lng: 18.7768376 },
    { lat: 48.3926283, lng: 18.7767998 },
    { lat: 48.3925648, lng: 18.7766895 },
    { lat: 48.3925129, lng: 18.7766698 },
    { lat: 48.3924809, lng: 18.7766576 },
    { lat: 48.3922396, lng: 18.7766814 },
    { lat: 48.3921574, lng: 18.7765961 },
    { lat: 48.3921395, lng: 18.7765163 },
    { lat: 48.392008, lng: 18.776439 },
    { lat: 48.3917312, lng: 18.7763665 },
    { lat: 48.3915407, lng: 18.7761543 },
    { lat: 48.3913759, lng: 18.7761718 },
    { lat: 48.3911462, lng: 18.776136 },
    { lat: 48.3908728, lng: 18.7762125 },
    { lat: 48.3906914, lng: 18.7762363 },
    { lat: 48.3905479, lng: 18.7763382 },
    { lat: 48.390157, lng: 18.776188 },
    { lat: 48.389603, lng: 18.776113 },
    { lat: 48.389019, lng: 18.775856 },
    { lat: 48.388729, lng: 18.775893 },
    { lat: 48.388372, lng: 18.775884 },
    { lat: 48.388228, lng: 18.775992 },
    { lat: 48.387958, lng: 18.776015 },
    { lat: 48.387834, lng: 18.776039 },
    { lat: 48.387417, lng: 18.776074 },
    { lat: 48.387055, lng: 18.775915 },
    { lat: 48.386858, lng: 18.775898 },
    { lat: 48.386759, lng: 18.776013 },
    { lat: 48.386533, lng: 18.776161 },
    { lat: 48.386161, lng: 18.776189 },
    { lat: 48.38591, lng: 18.776729 },
    { lat: 48.385356, lng: 18.776959 },
    { lat: 48.38537, lng: 18.776991 },
    { lat: 48.385397, lng: 18.777092 },
    { lat: 48.385348, lng: 18.77714 },
    { lat: 48.38518, lng: 18.777306 },
    { lat: 48.385106, lng: 18.777369 },
    { lat: 48.379806, lng: 18.779941 },
    { lat: 48.37956, lng: 18.780397 },
    { lat: 48.379034, lng: 18.781047 },
    { lat: 48.378686, lng: 18.781545 },
    { lat: 48.378285, lng: 18.782061 },
    { lat: 48.377595, lng: 18.782843 },
    { lat: 48.376891, lng: 18.783772 },
    { lat: 48.376882, lng: 18.783784 },
    { lat: 48.37699, lng: 18.784362 },
    { lat: 48.377006, lng: 18.784387 },
    { lat: 48.377045, lng: 18.784452 },
    { lat: 48.377157, lng: 18.784526 },
    { lat: 48.377436, lng: 18.784553 },
    { lat: 48.377537, lng: 18.78467 },
    { lat: 48.377728, lng: 18.7847 },
    { lat: 48.37778, lng: 18.784766 },
    { lat: 48.377801, lng: 18.784911 },
    { lat: 48.377879, lng: 18.785002 },
    { lat: 48.377921, lng: 18.785008 },
    { lat: 48.378023, lng: 18.784954 },
    { lat: 48.378144, lng: 18.785047 },
    { lat: 48.378203, lng: 18.785259 },
    { lat: 48.378346, lng: 18.785276 },
    { lat: 48.37832, lng: 18.785469 },
    { lat: 48.378593, lng: 18.78593 },
    { lat: 48.378574, lng: 18.786289 },
    { lat: 48.378489, lng: 18.786599 },
    { lat: 48.378364, lng: 18.786921 },
    { lat: 48.378429, lng: 18.787068 },
    { lat: 48.37788, lng: 18.788589 },
    { lat: 48.377738, lng: 18.788805 },
    { lat: 48.37747, lng: 18.789239 },
    { lat: 48.377303, lng: 18.78938 },
    { lat: 48.377201, lng: 18.789592 },
    { lat: 48.377058, lng: 18.789827 },
    { lat: 48.377005, lng: 18.789806 },
    { lat: 48.376924, lng: 18.789847 },
    { lat: 48.376847, lng: 18.789953 },
    { lat: 48.376805, lng: 18.789976 },
    { lat: 48.376671, lng: 18.78994 },
    { lat: 48.376491, lng: 18.790047 },
    { lat: 48.376436, lng: 18.790099 },
    { lat: 48.376258, lng: 18.790215 },
    { lat: 48.376203, lng: 18.790275 },
    { lat: 48.376076, lng: 18.79055 },
    { lat: 48.37593, lng: 18.790892 },
    { lat: 48.375394, lng: 18.791801 },
    { lat: 48.374567, lng: 18.792045 },
    { lat: 48.374284, lng: 18.793313 },
    { lat: 48.373911, lng: 18.794978 },
    { lat: 48.373552, lng: 18.796688 },
    { lat: 48.373383, lng: 18.797427 },
    { lat: 48.373063, lng: 18.798612 },
    { lat: 48.372895, lng: 18.799182 },
    { lat: 48.372878, lng: 18.799237 },
    { lat: 48.372789, lng: 18.799525 },
    { lat: 48.37259, lng: 18.799576 },
    { lat: 48.372558, lng: 18.799583 },
    { lat: 48.372447, lng: 18.799611 },
    { lat: 48.371888, lng: 18.799784 },
    { lat: 48.371761, lng: 18.799851 },
    { lat: 48.371491, lng: 18.800135 },
    { lat: 48.371501, lng: 18.800672 },
    { lat: 48.371467, lng: 18.800943 },
    { lat: 48.371463, lng: 18.800977 },
    { lat: 48.371421, lng: 18.801306 },
    { lat: 48.371416, lng: 18.801348 },
    { lat: 48.371265, lng: 18.80254 },
    { lat: 48.371279, lng: 18.802546 },
    { lat: 48.371294, lng: 18.802554 },
    { lat: 48.371284, lng: 18.802707 },
    { lat: 48.371326, lng: 18.802789 },
    { lat: 48.371317, lng: 18.802909 },
    { lat: 48.371281, lng: 18.803048 },
    { lat: 48.371228, lng: 18.803143 },
    { lat: 48.371211, lng: 18.803333 },
    { lat: 48.37129, lng: 18.803441 },
    { lat: 48.371306, lng: 18.803633 },
    { lat: 48.371266, lng: 18.803814 },
    { lat: 48.371319, lng: 18.803881 },
    { lat: 48.371333, lng: 18.803945 },
    { lat: 48.37129, lng: 18.804044 },
    { lat: 48.37127, lng: 18.804175 },
    { lat: 48.3712733, lng: 18.8041827 },
    { lat: 48.371309, lng: 18.804265 },
    { lat: 48.371361, lng: 18.804357 },
    { lat: 48.371315, lng: 18.804457 },
    { lat: 48.371282, lng: 18.804812 },
    { lat: 48.371303, lng: 18.804882 },
    { lat: 48.371282, lng: 18.804962 },
    { lat: 48.37127, lng: 18.805092 },
    { lat: 48.371208, lng: 18.805167 },
    { lat: 48.37117, lng: 18.80535 },
    { lat: 48.371198, lng: 18.805501 },
    { lat: 48.37124, lng: 18.805635 },
    { lat: 48.371182, lng: 18.805802 },
    { lat: 48.371145, lng: 18.806083 },
    { lat: 48.371142, lng: 18.806269 },
    { lat: 48.371197, lng: 18.806399 },
    { lat: 48.371148, lng: 18.80654 },
    { lat: 48.371181, lng: 18.80678 },
    { lat: 48.371235, lng: 18.806906 },
    { lat: 48.371186, lng: 18.807005 },
    { lat: 48.371169, lng: 18.807179 },
    { lat: 48.371222, lng: 18.80727 },
    { lat: 48.371176, lng: 18.807393 },
    { lat: 48.37114, lng: 18.807616 },
    { lat: 48.371118, lng: 18.807736 },
    { lat: 48.371063, lng: 18.807888 },
    { lat: 48.37108, lng: 18.807996 },
    { lat: 48.371045, lng: 18.808157 },
    { lat: 48.37101, lng: 18.808373 },
    { lat: 48.370874, lng: 18.808451 },
    { lat: 48.370827, lng: 18.808716 },
    { lat: 48.370735, lng: 18.809204 },
    { lat: 48.3707356, lng: 18.8092333 },
    { lat: 48.3707664, lng: 18.8092846 },
    { lat: 48.3728041, lng: 18.8097113 },
    { lat: 48.3743172, lng: 18.8107385 },
    { lat: 48.3748772, lng: 18.8108766 },
    { lat: 48.3749227, lng: 18.8109513 },
    { lat: 48.3752568, lng: 18.8122859 },
    { lat: 48.3753314, lng: 18.8131035 },
    { lat: 48.3753502, lng: 18.8150507 },
    { lat: 48.3759996, lng: 18.8156322 },
    { lat: 48.3763678, lng: 18.8158352 },
    { lat: 48.3769287, lng: 18.8161229 },
    { lat: 48.3793573, lng: 18.8169073 },
    { lat: 48.380453, lng: 18.8175909 },
    { lat: 48.3805238, lng: 18.8176694 },
    { lat: 48.3812071, lng: 18.8182708 },
    { lat: 48.3809291, lng: 18.820735 },
    { lat: 48.3790987, lng: 18.8232686 },
    { lat: 48.3791589, lng: 18.8255129 },
    { lat: 48.3800221, lng: 18.8267298 },
    { lat: 48.3806765, lng: 18.8275749 },
    { lat: 48.3809683, lng: 18.8284713 },
    { lat: 48.3806145, lng: 18.830524 },
    { lat: 48.3805788, lng: 18.8330758 },
    { lat: 48.3817202, lng: 18.835429 },
    { lat: 48.3817443, lng: 18.8354797 },
    { lat: 48.3817619, lng: 18.8354946 },
    { lat: 48.3825855, lng: 18.8361922 },
    { lat: 48.3831329, lng: 18.836846 },
    { lat: 48.3843707, lng: 18.8374441 },
    { lat: 48.3846218, lng: 18.837545 },
    { lat: 48.38581, lng: 18.8380766 },
    { lat: 48.3863482, lng: 18.838214 },
    { lat: 48.3871986, lng: 18.8384302 },
    { lat: 48.3877177, lng: 18.8391935 },
    { lat: 48.3878443, lng: 18.8392634 },
    { lat: 48.3880336, lng: 18.8393685 },
    { lat: 48.3892239, lng: 18.8400244 },
    { lat: 48.3900233, lng: 18.8396127 },
    { lat: 48.3914259, lng: 18.8400634 },
    { lat: 48.3932055, lng: 18.8406478 },
    { lat: 48.3950491, lng: 18.8409285 },
    { lat: 48.3962659, lng: 18.8412322 },
    { lat: 48.3964444, lng: 18.8414518 },
    { lat: 48.3969954, lng: 18.8432101 },
    { lat: 48.397102, lng: 18.8435502 },
    { lat: 48.3966564, lng: 18.8439994 },
    { lat: 48.3966561, lng: 18.8440796 },
    { lat: 48.3966554, lng: 18.8445005 },
    { lat: 48.3971723, lng: 18.8452852 },
    { lat: 48.397732, lng: 18.8461341 },
    { lat: 48.3985, lng: 18.84629 },
    { lat: 48.3996257, lng: 18.8463566 },
    { lat: 48.4000463, lng: 18.846185 },
    { lat: 48.4001441, lng: 18.8470219 },
    { lat: 48.4012545, lng: 18.8472302 },
    { lat: 48.4023005, lng: 18.8476326 },
    { lat: 48.4030697, lng: 18.8472703 },
    { lat: 48.4034553, lng: 18.8475049 },
    { lat: 48.4044425, lng: 18.8480797 },
    { lat: 48.4051413, lng: 18.8486977 },
    { lat: 48.4054189, lng: 18.8491981 },
    { lat: 48.4056929, lng: 18.8500843 },
    { lat: 48.4060645, lng: 18.8503362 },
    { lat: 48.4070038, lng: 18.8505281 },
    { lat: 48.4076584, lng: 18.8511222 },
    { lat: 48.4077329, lng: 18.8511908 },
    { lat: 48.4077648, lng: 18.8512194 },
    { lat: 48.4082242, lng: 18.851637 },
    { lat: 48.4092274, lng: 18.8516301 },
    { lat: 48.4096692, lng: 18.8518859 },
    { lat: 48.4101436, lng: 18.8524601 },
    { lat: 48.4101652, lng: 18.8524856 },
    { lat: 48.4106856, lng: 18.8538381 },
    { lat: 48.4107933, lng: 18.8539596 },
    { lat: 48.4108162, lng: 18.8539853 },
    { lat: 48.4112163, lng: 18.8544362 },
    { lat: 48.4113967, lng: 18.8552171 },
    { lat: 48.4115807, lng: 18.8554571 },
    { lat: 48.4123104, lng: 18.8564148 },
    { lat: 48.4123295, lng: 18.8563913 },
    { lat: 48.4121053, lng: 18.8550313 },
    { lat: 48.4125905, lng: 18.8544755 },
    { lat: 48.4125463, lng: 18.8541428 },
    { lat: 48.4125983, lng: 18.8535869 },
    { lat: 48.4130412, lng: 18.8534465 },
    { lat: 48.4131769, lng: 18.8530227 },
    { lat: 48.4134242, lng: 18.8529034 },
    { lat: 48.4136437, lng: 18.8528702 },
    { lat: 48.4141024, lng: 18.8530619 },
    { lat: 48.4142919, lng: 18.8533022 },
    { lat: 48.4144793, lng: 18.8533476 },
    { lat: 48.4144998, lng: 18.8535678 },
    { lat: 48.4146044, lng: 18.8537166 },
    { lat: 48.4147569, lng: 18.8537406 },
    { lat: 48.4148538, lng: 18.8538918 },
    { lat: 48.4150428, lng: 18.8539043 },
    { lat: 48.4151373, lng: 18.8537715 },
    { lat: 48.4153265, lng: 18.8535253 },
    { lat: 48.4156275, lng: 18.8532669 },
    { lat: 48.4157823, lng: 18.8530585 },
    { lat: 48.4157961, lng: 18.8530377 },
    { lat: 48.4158713, lng: 18.8528166 },
    { lat: 48.4161706, lng: 18.8526965 },
    { lat: 48.4163678, lng: 18.852563 },
    { lat: 48.4164987, lng: 18.8523058 },
    { lat: 48.416422, lng: 18.8522208 },
    { lat: 48.4164221, lng: 18.8521771 },
    { lat: 48.4164215, lng: 18.8521435 },
    { lat: 48.416422, lng: 18.8521118 },
    { lat: 48.4164204, lng: 18.8519945 },
    { lat: 48.4164205, lng: 18.8519299 },
    { lat: 48.4164189, lng: 18.8518577 },
    { lat: 48.4164336, lng: 18.8516642 },
    { lat: 48.4164363, lng: 18.8516064 },
    { lat: 48.4165035, lng: 18.8506657 },
    { lat: 48.4165149, lng: 18.8506487 },
    { lat: 48.4163496, lng: 18.8503749 },
    { lat: 48.4161751, lng: 18.8500363 },
    { lat: 48.4158631, lng: 18.849609 },
    { lat: 48.4156464, lng: 18.849369 },
    { lat: 48.4154307, lng: 18.8492741 },
    { lat: 48.4149632, lng: 18.8489361 },
    { lat: 48.4144948, lng: 18.8486498 },
    { lat: 48.4145214, lng: 18.8481636 },
    { lat: 48.4145592, lng: 18.8477878 },
    { lat: 48.4144151, lng: 18.847426 },
    { lat: 48.4144292, lng: 18.8474152 },
    { lat: 48.4145497, lng: 18.8471291 },
    { lat: 48.4151442, lng: 18.8459246 },
    { lat: 48.4151768, lng: 18.845902 },
    { lat: 48.4152769, lng: 18.8458331 },
    { lat: 48.4157745, lng: 18.8454919 },
    { lat: 48.4159515, lng: 18.8453823 },
    { lat: 48.4163571, lng: 18.8447066 },
    { lat: 48.4167093, lng: 18.8439786 },
    { lat: 48.4170268, lng: 18.8433626 },
    { lat: 48.4174289, lng: 18.8428765 },
    { lat: 48.417866, lng: 18.8422869 },
    { lat: 48.4179083, lng: 18.8422787 },
  ],
};

export default UnitsBanskáŠtiavnica;
