const UnitsBratislavaIV = {
  Dúbravka: [
    { lat: 48.1917752, lng: 17.0130538 },
    { lat: 48.1908849, lng: 17.0130288 },
    { lat: 48.1905231, lng: 17.0130734 },
    { lat: 48.1897778, lng: 17.0127685 },
    { lat: 48.1895996, lng: 17.0126252 },
    { lat: 48.1894072, lng: 17.0123279 },
    { lat: 48.189039, lng: 17.0114725 },
    { lat: 48.1888182, lng: 17.0113545 },
    { lat: 48.1884608, lng: 17.0114802 },
    { lat: 48.1882481, lng: 17.0114764 },
    { lat: 48.1876518, lng: 17.0118452 },
    { lat: 48.1873122, lng: 17.0119516 },
    { lat: 48.1872105, lng: 17.0120812 },
    { lat: 48.1867842, lng: 17.0123418 },
    { lat: 48.1859551, lng: 17.0134485 },
    { lat: 48.1858418, lng: 17.0133948 },
    { lat: 48.1856208, lng: 17.0134531 },
    { lat: 48.1853883, lng: 17.0135876 },
    { lat: 48.1844625, lng: 17.0145891 },
    { lat: 48.1844003, lng: 17.013763 },
    { lat: 48.1844284, lng: 17.0130717 },
    { lat: 48.1845307, lng: 17.0128999 },
    { lat: 48.1845458, lng: 17.0123129 },
    { lat: 48.1837788, lng: 17.0106233 },
    { lat: 48.1831763, lng: 17.0112286 },
    { lat: 48.1824436, lng: 17.0115001 },
    { lat: 48.1812487, lng: 17.0126626 },
    { lat: 48.1804488, lng: 17.0138671 },
    { lat: 48.1800926, lng: 17.014062 },
    { lat: 48.1796888, lng: 17.0143866 },
    { lat: 48.1795815, lng: 17.0145341 },
    { lat: 48.1796707, lng: 17.0152257 },
    { lat: 48.1790413, lng: 17.0163275 },
    { lat: 48.178721, lng: 17.0165668 },
    { lat: 48.1784551, lng: 17.0166465 },
    { lat: 48.1782394, lng: 17.016846 },
    { lat: 48.1775824, lng: 17.0177459 },
    { lat: 48.1770083, lng: 17.0179887 },
    { lat: 48.1767381, lng: 17.0182453 },
    { lat: 48.17649, lng: 17.0184382 },
    { lat: 48.1761917, lng: 17.0184917 },
    { lat: 48.1759577, lng: 17.0187178 },
    { lat: 48.1757398, lng: 17.0190043 },
    { lat: 48.1756206, lng: 17.0190859 },
    { lat: 48.1753176, lng: 17.019439 },
    { lat: 48.1752527, lng: 17.0196773 },
    { lat: 48.1751262, lng: 17.0197597 },
    { lat: 48.1748292, lng: 17.0198138 },
    { lat: 48.1745065, lng: 17.0202148 },
    { lat: 48.1741025, lng: 17.020521 },
    { lat: 48.1735714, lng: 17.0204881 },
    { lat: 48.172951, lng: 17.0206367 },
    { lat: 48.1729071, lng: 17.0207387 },
    { lat: 48.1730626, lng: 17.0211584 },
    { lat: 48.173285, lng: 17.0215838 },
    { lat: 48.1734471, lng: 17.0217895 },
    { lat: 48.1736216, lng: 17.0218618 },
    { lat: 48.173724, lng: 17.0220706 },
    { lat: 48.1744802, lng: 17.022417 },
    { lat: 48.1746005, lng: 17.0226723 },
    { lat: 48.1750353, lng: 17.0229501 },
    { lat: 48.1757513, lng: 17.0236008 },
    { lat: 48.1761462, lng: 17.0243521 },
    { lat: 48.1764828, lng: 17.0251899 },
    { lat: 48.176624, lng: 17.0252047 },
    { lat: 48.1769044, lng: 17.0256025 },
    { lat: 48.1771246, lng: 17.0256991 },
    { lat: 48.1768109, lng: 17.0262665 },
    { lat: 48.1755861, lng: 17.0276714 },
    { lat: 48.175206, lng: 17.0283067 },
    { lat: 48.1751738, lng: 17.0282341 },
    { lat: 48.1749529, lng: 17.0281935 },
    { lat: 48.1744101, lng: 17.0282371 },
    { lat: 48.1736644, lng: 17.0284026 },
    { lat: 48.1734699, lng: 17.0291606 },
    { lat: 48.1733596, lng: 17.0294317 },
    { lat: 48.1730104, lng: 17.0297918 },
    { lat: 48.1729551, lng: 17.0304194 },
    { lat: 48.1730314, lng: 17.0307281 },
    { lat: 48.1732436, lng: 17.0307724 },
    { lat: 48.173488, lng: 17.0306766 },
    { lat: 48.1739708, lng: 17.0302377 },
    { lat: 48.1739806, lng: 17.0299997 },
    { lat: 48.1742522, lng: 17.030156 },
    { lat: 48.174874, lng: 17.0301889 },
    { lat: 48.1754622, lng: 17.0299679 },
    { lat: 48.1756865, lng: 17.0297614 },
    { lat: 48.1754905, lng: 17.0303335 },
    { lat: 48.1754894, lng: 17.0306286 },
    { lat: 48.1753964, lng: 17.0309706 },
    { lat: 48.1752368, lng: 17.0327819 },
    { lat: 48.174948, lng: 17.032739 },
    { lat: 48.1748203, lng: 17.0326063 },
    { lat: 48.1746428, lng: 17.0326048 },
    { lat: 48.1744862, lng: 17.0327623 },
    { lat: 48.1742503, lng: 17.0338063 },
    { lat: 48.1742515, lng: 17.0342522 },
    { lat: 48.1743067, lng: 17.0345942 },
    { lat: 48.1745213, lng: 17.0347412 },
    { lat: 48.174654, lng: 17.0350817 },
    { lat: 48.174761, lng: 17.0351566 },
    { lat: 48.1744035, lng: 17.036437 },
    { lat: 48.1745169, lng: 17.0367768 },
    { lat: 48.1745016, lng: 17.0374582 },
    { lat: 48.1747525, lng: 17.0376574 },
    { lat: 48.1748326, lng: 17.0380667 },
    { lat: 48.1747936, lng: 17.038522 },
    { lat: 48.1748232, lng: 17.0390202 },
    { lat: 48.1747765, lng: 17.0393796 },
    { lat: 48.1745951, lng: 17.039801 },
    { lat: 48.1746213, lng: 17.0401836 },
    { lat: 48.1745539, lng: 17.0407313 },
    { lat: 48.1742774, lng: 17.0411032 },
    { lat: 48.1739666, lng: 17.0420258 },
    { lat: 48.1736014, lng: 17.0424194 },
    { lat: 48.1734339, lng: 17.0424493 },
    { lat: 48.1733114, lng: 17.0426241 },
    { lat: 48.1726744, lng: 17.0428939 },
    { lat: 48.1724352, lng: 17.0427829 },
    { lat: 48.1725403, lng: 17.041823 },
    { lat: 48.1722739, lng: 17.0417607 },
    { lat: 48.1723827, lng: 17.0413048 },
    { lat: 48.1724116, lng: 17.0408833 },
    { lat: 48.1723678, lng: 17.0407902 },
    { lat: 48.1724308, lng: 17.0405855 },
    { lat: 48.1722751, lng: 17.0405258 },
    { lat: 48.172174, lng: 17.0403518 },
    { lat: 48.1720272, lng: 17.0403877 },
    { lat: 48.1721405, lng: 17.0399456 },
    { lat: 48.1718228, lng: 17.0397949 },
    { lat: 48.1715015, lng: 17.040563 },
    { lat: 48.1706917, lng: 17.0421219 },
    { lat: 48.1699535, lng: 17.043854 },
    { lat: 48.1696345, lng: 17.0444103 },
    { lat: 48.1693719, lng: 17.0447188 },
    { lat: 48.1703659, lng: 17.0443966 },
    { lat: 48.1703014, lng: 17.0460982 },
    { lat: 48.1701234, lng: 17.0475435 },
    { lat: 48.1699684, lng: 17.0502674 },
    { lat: 48.1703232, lng: 17.0503527 },
    { lat: 48.1704418, lng: 17.0504655 },
    { lat: 48.1704479, lng: 17.0506689 },
    { lat: 48.1708922, lng: 17.0507752 },
    { lat: 48.1707815, lng: 17.0511712 },
    { lat: 48.1711445, lng: 17.0512096 },
    { lat: 48.1709635, lng: 17.05213 },
    { lat: 48.1706666, lng: 17.0529626 },
    { lat: 48.172429, lng: 17.0527576 },
    { lat: 48.1731367, lng: 17.0529614 },
    { lat: 48.1732385, lng: 17.0531968 },
    { lat: 48.1734146, lng: 17.053604 },
    { lat: 48.1740548, lng: 17.0532181 },
    { lat: 48.1746628, lng: 17.0532697 },
    { lat: 48.1746927, lng: 17.0538397 },
    { lat: 48.1754421, lng: 17.0541222 },
    { lat: 48.1754216, lng: 17.0545823 },
    { lat: 48.175352, lng: 17.0549045 },
    { lat: 48.1750841, lng: 17.0555826 },
    { lat: 48.1748372, lng: 17.0555913 },
    { lat: 48.1746242, lng: 17.0557973 },
    { lat: 48.1738911, lng: 17.0582343 },
    { lat: 48.1737199, lng: 17.0581762 },
    { lat: 48.173625, lng: 17.0583578 },
    { lat: 48.1735887, lng: 17.0583212 },
    { lat: 48.1732411, lng: 17.0588903 },
    { lat: 48.1732125, lng: 17.0591202 },
    { lat: 48.1732235, lng: 17.0593957 },
    { lat: 48.1734285, lng: 17.0597662 },
    { lat: 48.1740405, lng: 17.0593621 },
    { lat: 48.1741714, lng: 17.0593927 },
    { lat: 48.1746124, lng: 17.0597446 },
    { lat: 48.1750567, lng: 17.0599954 },
    { lat: 48.1747256, lng: 17.0612223 },
    { lat: 48.175046, lng: 17.0615951 },
    { lat: 48.1751715, lng: 17.0618277 },
    { lat: 48.1751169, lng: 17.0622035 },
    { lat: 48.1751589, lng: 17.0626042 },
    { lat: 48.1754164, lng: 17.0637264 },
    { lat: 48.1753755, lng: 17.0637107 },
    { lat: 48.1754284, lng: 17.0639544 },
    { lat: 48.1754828, lng: 17.0640551 },
    { lat: 48.175601, lng: 17.0641139 },
    { lat: 48.1757264, lng: 17.0640498 },
    { lat: 48.1760282, lng: 17.0635528 },
    { lat: 48.1761031, lng: 17.063609 },
    { lat: 48.1768821, lng: 17.0626407 },
    { lat: 48.1768304, lng: 17.0623925 },
    { lat: 48.177069, lng: 17.0622945 },
    { lat: 48.1775172, lng: 17.0616625 },
    { lat: 48.1779821, lng: 17.0609554 },
    { lat: 48.1789121, lng: 17.0592996 },
    { lat: 48.1790632, lng: 17.0589748 },
    { lat: 48.1792136, lng: 17.0585848 },
    { lat: 48.1801303, lng: 17.0557077 },
    { lat: 48.180089, lng: 17.0553844 },
    { lat: 48.1799224, lng: 17.0547991 },
    { lat: 48.1802208, lng: 17.053743 },
    { lat: 48.1804488, lng: 17.0535064 },
    { lat: 48.1809648, lng: 17.0533019 },
    { lat: 48.1812046, lng: 17.0531081 },
    { lat: 48.1816452, lng: 17.0520972 },
    { lat: 48.1825129, lng: 17.0505225 },
    { lat: 48.1836641, lng: 17.0488822 },
    { lat: 48.1838224, lng: 17.0485684 },
    { lat: 48.1846002, lng: 17.047434 },
    { lat: 48.1848048, lng: 17.0473351 },
    { lat: 48.1851701, lng: 17.047423 },
    { lat: 48.1855409, lng: 17.047094 },
    { lat: 48.1863063, lng: 17.0466038 },
    { lat: 48.1873926, lng: 17.0457154 },
    { lat: 48.1884104, lng: 17.0451826 },
    { lat: 48.1902586, lng: 17.0445629 },
    { lat: 48.1963449, lng: 17.0429639 },
    { lat: 48.1974205, lng: 17.0425811 },
    { lat: 48.1974254, lng: 17.042403 },
    { lat: 48.1976263, lng: 17.0423184 },
    { lat: 48.1980198, lng: 17.0423382 },
    { lat: 48.1992213, lng: 17.0419129 },
    { lat: 48.1995545, lng: 17.0416285 },
    { lat: 48.1995953, lng: 17.0416647 },
    { lat: 48.2000605, lng: 17.0409985 },
    { lat: 48.2010823, lng: 17.0391356 },
    { lat: 48.201242, lng: 17.038611 },
    { lat: 48.2016757, lng: 17.0377342 },
    { lat: 48.2019911, lng: 17.0368103 },
    { lat: 48.2025846, lng: 17.0347829 },
    { lat: 48.2028184, lng: 17.0331134 },
    { lat: 48.2028724, lng: 17.0324806 },
    { lat: 48.2029775, lng: 17.0311762 },
    { lat: 48.20316, lng: 17.0270296 },
    { lat: 48.2030376, lng: 17.0269081 },
    { lat: 48.2031223, lng: 17.025819 },
    { lat: 48.2031513, lng: 17.0239332 },
    { lat: 48.2028357, lng: 17.0237135 },
    { lat: 48.202714, lng: 17.0222355 },
    { lat: 48.2025933, lng: 17.0215619 },
    { lat: 48.2026036, lng: 17.0208044 },
    { lat: 48.2032348, lng: 17.0204025 },
    { lat: 48.2036192, lng: 17.010521 },
    { lat: 48.2039361, lng: 17.0078151 },
    { lat: 48.2039697, lng: 17.0069215 },
    { lat: 48.2039172, lng: 17.0066016 },
    { lat: 48.2037297, lng: 17.0066364 },
    { lat: 48.2026388, lng: 17.0053797 },
    { lat: 48.2022784, lng: 17.0052376 },
    { lat: 48.202108, lng: 17.0051705 },
    { lat: 48.202218, lng: 17.0055864 },
    { lat: 48.2001164, lng: 17.0074341 },
    { lat: 48.1987646, lng: 17.0119049 },
    { lat: 48.1980678, lng: 17.0124253 },
    { lat: 48.1978521, lng: 17.0124903 },
    { lat: 48.1968824, lng: 17.0125074 },
    { lat: 48.1964308, lng: 17.0126597 },
    { lat: 48.1960269, lng: 17.012671 },
    { lat: 48.1954938, lng: 17.0128043 },
    { lat: 48.1949726, lng: 17.0127525 },
    { lat: 48.1947713, lng: 17.0128316 },
    { lat: 48.1945413, lng: 17.0127936 },
    { lat: 48.1941089, lng: 17.0130102 },
    { lat: 48.1938931, lng: 17.0130299 },
    { lat: 48.1935832, lng: 17.0128586 },
    { lat: 48.1931757, lng: 17.0130979 },
    { lat: 48.1928215, lng: 17.0132224 },
    { lat: 48.1924473, lng: 17.0130726 },
    { lat: 48.1917752, lng: 17.0130538 },
  ],
  ZáhorskáBystrica: [
    { lat: 48.2279559, lng: 17.1099705 },
    { lat: 48.227977, lng: 17.109943 },
    { lat: 48.228004, lng: 17.109909 },
    { lat: 48.22802, lng: 17.109886 },
    { lat: 48.2280987, lng: 17.10981 },
    { lat: 48.228124, lng: 17.109765 },
    { lat: 48.228167, lng: 17.109684 },
    { lat: 48.228236, lng: 17.109633 },
    { lat: 48.228286, lng: 17.109571 },
    { lat: 48.228367, lng: 17.109572 },
    { lat: 48.228434, lng: 17.109481 },
    { lat: 48.228472, lng: 17.109381 },
    { lat: 48.228527, lng: 17.109386 },
    { lat: 48.228595, lng: 17.109457 },
    { lat: 48.228631, lng: 17.10933 },
    { lat: 48.228696, lng: 17.109347 },
    { lat: 48.228705, lng: 17.109235 },
    { lat: 48.228721, lng: 17.109154 },
    { lat: 48.228781, lng: 17.109205 },
    { lat: 48.228809, lng: 17.109167 },
    { lat: 48.228771, lng: 17.109102 },
    { lat: 48.228865, lng: 17.109051 },
    { lat: 48.229055, lng: 17.108913 },
    { lat: 48.229052, lng: 17.108873 },
    { lat: 48.229329, lng: 17.108425 },
    { lat: 48.229458, lng: 17.108556 },
    { lat: 48.229548, lng: 17.10846 },
    { lat: 48.2297203, lng: 17.1084428 },
    { lat: 48.2297684, lng: 17.1083808 },
    { lat: 48.229824, lng: 17.108256 },
    { lat: 48.230017, lng: 17.108037 },
    { lat: 48.230079, lng: 17.107879 },
    { lat: 48.2303044, lng: 17.1076697 },
    { lat: 48.23071, lng: 17.1075863 },
    { lat: 48.230965, lng: 17.107471 },
    { lat: 48.231, lng: 17.107351 },
    { lat: 48.23148, lng: 17.107355 },
    { lat: 48.231555, lng: 17.107384 },
    { lat: 48.23166, lng: 17.107341 },
    { lat: 48.231851, lng: 17.107353 },
    { lat: 48.231899, lng: 17.107399 },
    { lat: 48.232007, lng: 17.107383 },
    { lat: 48.232055, lng: 17.107295 },
    { lat: 48.232118, lng: 17.107163 },
    { lat: 48.232161, lng: 17.106994 },
    { lat: 48.232238, lng: 17.107064 },
    { lat: 48.232282, lng: 17.107179 },
    { lat: 48.232316, lng: 17.107005 },
    { lat: 48.232326, lng: 17.106928 },
    { lat: 48.232419, lng: 17.106912 },
    { lat: 48.232497, lng: 17.107011 },
    { lat: 48.232654, lng: 17.107068 },
    { lat: 48.232783, lng: 17.106968 },
    { lat: 48.23279, lng: 17.107075 },
    { lat: 48.232838, lng: 17.107046 },
    { lat: 48.232968, lng: 17.107076 },
    { lat: 48.233043, lng: 17.107024 },
    { lat: 48.233076, lng: 17.107018 },
    { lat: 48.233208, lng: 17.107106 },
    { lat: 48.233324, lng: 17.107088 },
    { lat: 48.233395, lng: 17.107199 },
    { lat: 48.233449, lng: 17.107462 },
    { lat: 48.233596, lng: 17.107494 },
    { lat: 48.233677, lng: 17.107495 },
    { lat: 48.233945, lng: 17.107576 },
    { lat: 48.233978, lng: 17.10753 },
    { lat: 48.234088, lng: 17.107554 },
    { lat: 48.234122, lng: 17.107657 },
    { lat: 48.234222, lng: 17.107534 },
    { lat: 48.234369, lng: 17.107566 },
    { lat: 48.234417, lng: 17.107703 },
    { lat: 48.234454, lng: 17.107711 },
    { lat: 48.234488, lng: 17.107554 },
    { lat: 48.234578, lng: 17.107575 },
    { lat: 48.234717, lng: 17.107327 },
    { lat: 48.235005, lng: 17.107483 },
    { lat: 48.235087, lng: 17.107619 },
    { lat: 48.235228, lng: 17.107706 },
    { lat: 48.235334, lng: 17.107663 },
    { lat: 48.235337, lng: 17.107568 },
    { lat: 48.235463, lng: 17.107562 },
    { lat: 48.235501, lng: 17.107634 },
    { lat: 48.235585, lng: 17.107638 },
    { lat: 48.235714, lng: 17.107673 },
    { lat: 48.235945, lng: 17.108017 },
    { lat: 48.236047, lng: 17.108021 },
    { lat: 48.236431, lng: 17.108254 },
    { lat: 48.236428, lng: 17.108075 },
    { lat: 48.236488, lng: 17.108004 },
    { lat: 48.236618, lng: 17.108077 },
    { lat: 48.236663, lng: 17.10807 },
    { lat: 48.236719, lng: 17.107967 },
    { lat: 48.236782, lng: 17.107957 },
    { lat: 48.236834, lng: 17.108057 },
    { lat: 48.237048, lng: 17.10798 },
    { lat: 48.23701, lng: 17.107869 },
    { lat: 48.237021, lng: 17.107745 },
    { lat: 48.236988, lng: 17.107669 },
    { lat: 48.237047, lng: 17.107592 },
    { lat: 48.237187, lng: 17.107666 },
    { lat: 48.237242, lng: 17.107657 },
    { lat: 48.23738, lng: 17.107514 },
    { lat: 48.237415, lng: 17.107434 },
    { lat: 48.237459, lng: 17.107423 },
    { lat: 48.237532, lng: 17.107559 },
    { lat: 48.23759, lng: 17.107605 },
    { lat: 48.237645, lng: 17.107758 },
    { lat: 48.237664, lng: 17.107904 },
    { lat: 48.237725, lng: 17.108004 },
    { lat: 48.237725, lng: 17.108125 },
    { lat: 48.237761, lng: 17.108133 },
    { lat: 48.237787, lng: 17.108116 },
    { lat: 48.237806, lng: 17.108211 },
    { lat: 48.237795, lng: 17.108253 },
    { lat: 48.237754, lng: 17.108432 },
    { lat: 48.237762, lng: 17.108539 },
    { lat: 48.237812, lng: 17.108613 },
    { lat: 48.23794, lng: 17.108634 },
    { lat: 48.237934, lng: 17.108811 },
    { lat: 48.237879, lng: 17.108941 },
    { lat: 48.237884, lng: 17.109008 },
    { lat: 48.237912, lng: 17.109059 },
    { lat: 48.237907, lng: 17.109363 },
    { lat: 48.23793, lng: 17.109531 },
    { lat: 48.237885, lng: 17.109646 },
    { lat: 48.237868, lng: 17.109947 },
    { lat: 48.237921, lng: 17.110125 },
    { lat: 48.237851, lng: 17.110393 },
    { lat: 48.237761, lng: 17.110541 },
    { lat: 48.237771, lng: 17.110689 },
    { lat: 48.23773, lng: 17.110873 },
    { lat: 48.237725, lng: 17.110957 },
    { lat: 48.237682, lng: 17.111104 },
    { lat: 48.237702, lng: 17.11117 },
    { lat: 48.237702, lng: 17.111403 },
    { lat: 48.237693, lng: 17.11154 },
    { lat: 48.237735, lng: 17.111615 },
    { lat: 48.237728, lng: 17.111778 },
    { lat: 48.23777, lng: 17.111867 },
    { lat: 48.237838, lng: 17.111937 },
    { lat: 48.237911, lng: 17.112075 },
    { lat: 48.238055, lng: 17.112202 },
    { lat: 48.238111, lng: 17.112348 },
    { lat: 48.238154, lng: 17.112373 },
    { lat: 48.238193, lng: 17.112514 },
    { lat: 48.238195, lng: 17.112754 },
    { lat: 48.238188, lng: 17.112832 },
    { lat: 48.238194, lng: 17.113061 },
    { lat: 48.238222, lng: 17.113205 },
    { lat: 48.238277, lng: 17.11325 },
    { lat: 48.238349, lng: 17.113375 },
    { lat: 48.238315, lng: 17.11357 },
    { lat: 48.238355, lng: 17.113651 },
    { lat: 48.238352, lng: 17.113728 },
    { lat: 48.238403, lng: 17.113882 },
    { lat: 48.238412, lng: 17.114102 },
    { lat: 48.238456, lng: 17.114117 },
    { lat: 48.238527, lng: 17.114292 },
    { lat: 48.238498, lng: 17.11445 },
    { lat: 48.238541, lng: 17.114844 },
    { lat: 48.2388, lng: 17.114931 },
    { lat: 48.238818, lng: 17.115063 },
    { lat: 48.23886, lng: 17.115152 },
    { lat: 48.239086, lng: 17.115287 },
    { lat: 48.239135, lng: 17.11534 },
    { lat: 48.239225, lng: 17.11534 },
    { lat: 48.239357, lng: 17.115279 },
    { lat: 48.239422, lng: 17.11531 },
    { lat: 48.23947, lng: 17.115369 },
    { lat: 48.239485, lng: 17.115427 },
    { lat: 48.239529, lng: 17.115376 },
    { lat: 48.239668, lng: 17.115575 },
    { lat: 48.239715, lng: 17.115746 },
    { lat: 48.239778, lng: 17.115892 },
    { lat: 48.239834, lng: 17.115983 },
    { lat: 48.239914, lng: 17.116169 },
    { lat: 48.240126, lng: 17.116232 },
    { lat: 48.240185, lng: 17.116304 },
    { lat: 48.240288, lng: 17.11642 },
    { lat: 48.240255, lng: 17.116636 },
    { lat: 48.24029, lng: 17.116789 },
    { lat: 48.240222, lng: 17.116908 },
    { lat: 48.240213, lng: 17.117046 },
    { lat: 48.240235, lng: 17.117177 },
    { lat: 48.240236, lng: 17.117266 },
    { lat: 48.2402928, lng: 17.1174513 },
    { lat: 48.2403313, lng: 17.1174883 },
    { lat: 48.240472, lng: 17.1174783 },
    { lat: 48.240647, lng: 17.117542 },
    { lat: 48.240691, lng: 17.117597 },
    { lat: 48.240746, lng: 17.117608 },
    { lat: 48.240803, lng: 17.117685 },
    { lat: 48.240888, lng: 17.117652 },
    { lat: 48.241044, lng: 17.117741 },
    { lat: 48.241059, lng: 17.117893 },
    { lat: 48.241031, lng: 17.117976 },
    { lat: 48.241089, lng: 17.118139 },
    { lat: 48.241074, lng: 17.118213 },
    { lat: 48.241036, lng: 17.118228 },
    { lat: 48.241053, lng: 17.118346 },
    { lat: 48.241102, lng: 17.118371 },
    { lat: 48.241026, lng: 17.118873 },
    { lat: 48.241077, lng: 17.118963 },
    { lat: 48.241143, lng: 17.119334 },
    { lat: 48.241201, lng: 17.119425 },
    { lat: 48.241164, lng: 17.119552 },
    { lat: 48.241165, lng: 17.119717 },
    { lat: 48.241292, lng: 17.119936 },
    { lat: 48.241287, lng: 17.120076 },
    { lat: 48.241345, lng: 17.120139 },
    { lat: 48.241315, lng: 17.120249 },
    { lat: 48.241417, lng: 17.120538 },
    { lat: 48.24142, lng: 17.120797 },
    { lat: 48.241345, lng: 17.120891 },
    { lat: 48.241264, lng: 17.121122 },
    { lat: 48.241309, lng: 17.121127 },
    { lat: 48.241367, lng: 17.121466 },
    { lat: 48.241478, lng: 17.121533 },
    { lat: 48.241485, lng: 17.121609 },
    { lat: 48.241588, lng: 17.121659 },
    { lat: 48.241708, lng: 17.121624 },
    { lat: 48.241865, lng: 17.121687 },
    { lat: 48.241915, lng: 17.121804 },
    { lat: 48.2419, lng: 17.121876 },
    { lat: 48.241964, lng: 17.121894 },
    { lat: 48.242107, lng: 17.122202 },
    { lat: 48.242028, lng: 17.122303 },
    { lat: 48.24204, lng: 17.122386 },
    { lat: 48.242094, lng: 17.122462 },
    { lat: 48.242125, lng: 17.122668 },
    { lat: 48.242096, lng: 17.12283 },
    { lat: 48.242051, lng: 17.122889 },
    { lat: 48.241982, lng: 17.123059 },
    { lat: 48.241913, lng: 17.12314 },
    { lat: 48.241953, lng: 17.123309 },
    { lat: 48.242011, lng: 17.123298 },
    { lat: 48.242061, lng: 17.123432 },
    { lat: 48.242113, lng: 17.123504 },
    { lat: 48.242048, lng: 17.123642 },
    { lat: 48.241969, lng: 17.123662 },
    { lat: 48.24189, lng: 17.123718 },
    { lat: 48.241882, lng: 17.123785 },
    { lat: 48.2419, lng: 17.123825 },
    { lat: 48.241895, lng: 17.123948 },
    { lat: 48.241816, lng: 17.124108 },
    { lat: 48.241953, lng: 17.124373 },
    { lat: 48.242025, lng: 17.124402 },
    { lat: 48.242103, lng: 17.124472 },
    { lat: 48.24215, lng: 17.124528 },
    { lat: 48.242166, lng: 17.124519 },
    { lat: 48.242185, lng: 17.124486 },
    { lat: 48.242269, lng: 17.124547 },
    { lat: 48.242353, lng: 17.124701 },
    { lat: 48.242389, lng: 17.124799 },
    { lat: 48.242475, lng: 17.124853 },
    { lat: 48.242532, lng: 17.124969 },
    { lat: 48.242569, lng: 17.124974 },
    { lat: 48.242587, lng: 17.125035 },
    { lat: 48.242573, lng: 17.1252 },
    { lat: 48.242613, lng: 17.125402 },
    { lat: 48.242676, lng: 17.125467 },
    { lat: 48.242833, lng: 17.125971 },
    { lat: 48.242877, lng: 17.126167 },
    { lat: 48.242837, lng: 17.126511 },
    { lat: 48.242835, lng: 17.126622 },
    { lat: 48.242693, lng: 17.126849 },
    { lat: 48.242697, lng: 17.126993 },
    { lat: 48.242695, lng: 17.127105 },
    { lat: 48.242874, lng: 17.127196 },
    { lat: 48.242952, lng: 17.127278 },
    { lat: 48.243006, lng: 17.127518 },
    { lat: 48.243005, lng: 17.12774 },
    { lat: 48.242998, lng: 17.127827 },
    { lat: 48.243022, lng: 17.127938 },
    { lat: 48.243071, lng: 17.127964 },
    { lat: 48.243106, lng: 17.128138 },
    { lat: 48.243043, lng: 17.128361 },
    { lat: 48.242969, lng: 17.128352 },
    { lat: 48.242866, lng: 17.128259 },
    { lat: 48.242816, lng: 17.128357 },
    { lat: 48.242796, lng: 17.128507 },
    { lat: 48.242808, lng: 17.128625 },
    { lat: 48.242778, lng: 17.128848 },
    { lat: 48.242753, lng: 17.128945 },
    { lat: 48.242761, lng: 17.129135 },
    { lat: 48.2429151, lng: 17.1293675 },
    { lat: 48.242938, lng: 17.129586 },
    { lat: 48.242823, lng: 17.12985 },
    { lat: 48.242789, lng: 17.130033 },
    { lat: 48.242817, lng: 17.130201 },
    { lat: 48.242885, lng: 17.130307 },
    { lat: 48.242899, lng: 17.130354 },
    { lat: 48.242907, lng: 17.130548 },
    { lat: 48.242891, lng: 17.130624 },
    { lat: 48.242949, lng: 17.130886 },
    { lat: 48.242827, lng: 17.131296 },
    { lat: 48.242857, lng: 17.131458 },
    { lat: 48.2428278, lng: 17.1317057 },
    { lat: 48.242825, lng: 17.131845 },
    { lat: 48.242789, lng: 17.13196 },
    { lat: 48.242807, lng: 17.132098 },
    { lat: 48.242808, lng: 17.132205 },
    { lat: 48.242773, lng: 17.132464 },
    { lat: 48.242748, lng: 17.132525 },
    { lat: 48.242748, lng: 17.132635 },
    { lat: 48.242768, lng: 17.13267 },
    { lat: 48.242694, lng: 17.132909 },
    { lat: 48.242701, lng: 17.132976 },
    { lat: 48.242633, lng: 17.133082 },
    { lat: 48.242702, lng: 17.13332 },
    { lat: 48.242734, lng: 17.133439 },
    { lat: 48.242796, lng: 17.133567 },
    { lat: 48.242837, lng: 17.134222 },
    { lat: 48.243303, lng: 17.135383 },
    { lat: 48.243571, lng: 17.135547 },
    { lat: 48.243593, lng: 17.135561 },
    { lat: 48.243822, lng: 17.135693 },
    { lat: 48.244101, lng: 17.135855 },
    { lat: 48.244788, lng: 17.136032 },
    { lat: 48.246731, lng: 17.136779 },
    { lat: 48.247451, lng: 17.137208 },
    { lat: 48.24752, lng: 17.137305 },
    { lat: 48.247577, lng: 17.136981 },
    { lat: 48.2475, lng: 17.135811 },
    { lat: 48.247518, lng: 17.13461 },
    { lat: 48.247664, lng: 17.133786 },
    { lat: 48.247821, lng: 17.132531 },
    { lat: 48.247873, lng: 17.131642 },
    { lat: 48.247979, lng: 17.131105 },
    { lat: 48.247963, lng: 17.129266 },
    { lat: 48.247571, lng: 17.128104 },
    { lat: 48.246973, lng: 17.126663 },
    { lat: 48.246779, lng: 17.126265 },
    { lat: 48.246665, lng: 17.126156 },
    { lat: 48.246652, lng: 17.125134 },
    { lat: 48.246602, lng: 17.124831 },
    { lat: 48.246329, lng: 17.124179 },
    { lat: 48.245154, lng: 17.122998 },
    { lat: 48.244752, lng: 17.122542 },
    { lat: 48.244633, lng: 17.122257 },
    { lat: 48.244439, lng: 17.121265 },
    { lat: 48.244372, lng: 17.1205 },
    { lat: 48.244498, lng: 17.119449 },
    { lat: 48.244489, lng: 17.11909 },
    { lat: 48.244528, lng: 17.118931 },
    { lat: 48.2445, lng: 17.118723 },
    { lat: 48.24472, lng: 17.117736 },
    { lat: 48.244858, lng: 17.116843 },
    { lat: 48.245021, lng: 17.116431 },
    { lat: 48.245414, lng: 17.114582 },
    { lat: 48.245592, lng: 17.113985 },
    { lat: 48.245738, lng: 17.113767 },
    { lat: 48.246457, lng: 17.112299 },
    { lat: 48.246749, lng: 17.111837 },
    { lat: 48.246908, lng: 17.111699 },
    { lat: 48.247186, lng: 17.111117 },
    { lat: 48.247289, lng: 17.110215 },
    { lat: 48.247437, lng: 17.10966 },
    { lat: 48.247405, lng: 17.10921 },
    { lat: 48.247587, lng: 17.108507 },
    { lat: 48.247825, lng: 17.107826 },
    { lat: 48.248053, lng: 17.106104 },
    { lat: 48.248194, lng: 17.105608 },
    { lat: 48.248231, lng: 17.105317 },
    { lat: 48.248197, lng: 17.104861 },
    { lat: 48.248266, lng: 17.104001 },
    { lat: 48.248538, lng: 17.103059 },
    { lat: 48.248535, lng: 17.102341 },
    { lat: 48.248488, lng: 17.101924 },
    { lat: 48.248595, lng: 17.101133 },
    { lat: 48.248645, lng: 17.100895 },
    { lat: 48.248991, lng: 17.100291 },
    { lat: 48.249376, lng: 17.099352 },
    { lat: 48.249417, lng: 17.09905 },
    { lat: 48.249422, lng: 17.09818 },
    { lat: 48.249529, lng: 17.097528 },
    { lat: 48.249309, lng: 17.096905 },
    { lat: 48.2492, lng: 17.09673 },
    { lat: 48.249238, lng: 17.095907 },
    { lat: 48.2492882, lng: 17.0955952 },
    { lat: 48.249229, lng: 17.095271 },
    { lat: 48.249131, lng: 17.095175 },
    { lat: 48.248802, lng: 17.0950295 },
    { lat: 48.2483796, lng: 17.0945898 },
    { lat: 48.24782, lng: 17.094289 },
    { lat: 48.247637, lng: 17.094067 },
    { lat: 48.247211, lng: 17.093711 },
    { lat: 48.24708, lng: 17.09321 },
    { lat: 48.247068, lng: 17.092524 },
    { lat: 48.246813, lng: 17.09183 },
    { lat: 48.246694, lng: 17.091048 },
    { lat: 48.246727, lng: 17.090811 },
    { lat: 48.247072, lng: 17.089737 },
    { lat: 48.247117, lng: 17.0892 },
    { lat: 48.247219, lng: 17.088795 },
    { lat: 48.247779, lng: 17.087874 },
    { lat: 48.247926, lng: 17.087106 },
    { lat: 48.247905, lng: 17.086236 },
    { lat: 48.248221, lng: 17.085934 },
    { lat: 48.24849, lng: 17.0858 },
    { lat: 48.24862, lng: 17.085602 },
    { lat: 48.2489, lng: 17.084658 },
    { lat: 48.249174, lng: 17.084406 },
    { lat: 48.2491868, lng: 17.0841445 },
    { lat: 48.249287, lng: 17.083646 },
    { lat: 48.24974, lng: 17.082369 },
    { lat: 48.249849, lng: 17.081415 },
    { lat: 48.2497926, lng: 17.0812791 },
    { lat: 48.249643, lng: 17.080919 },
    { lat: 48.249526, lng: 17.081203 },
    { lat: 48.249263, lng: 17.081291 },
    { lat: 48.24908, lng: 17.08161 },
    { lat: 48.248654, lng: 17.08196 },
    { lat: 48.247943, lng: 17.082379 },
    { lat: 48.247403, lng: 17.082556 },
    { lat: 48.247124, lng: 17.082527 },
    { lat: 48.246962, lng: 17.082322 },
    { lat: 48.247148, lng: 17.081526 },
    { lat: 48.246985, lng: 17.081473 },
    { lat: 48.246927, lng: 17.081491 },
    { lat: 48.246873, lng: 17.081646 },
    { lat: 48.246719, lng: 17.081517 },
    { lat: 48.24664, lng: 17.081586 },
    { lat: 48.246457, lng: 17.081535 },
    { lat: 48.246373, lng: 17.081584 },
    { lat: 48.246287, lng: 17.081781 },
    { lat: 48.246154, lng: 17.081628 },
    { lat: 48.245942, lng: 17.081736 },
    { lat: 48.245864, lng: 17.081679 },
    { lat: 48.245439, lng: 17.081772 },
    { lat: 48.245108, lng: 17.081711 },
    { lat: 48.245059, lng: 17.081638 },
    { lat: 48.244797, lng: 17.081611 },
    { lat: 48.244405, lng: 17.081686 },
    { lat: 48.244072, lng: 17.081659 },
    { lat: 48.243822, lng: 17.081554 },
    { lat: 48.24315, lng: 17.080862 },
    { lat: 48.242834, lng: 17.080811 },
    { lat: 48.242692, lng: 17.080468 },
    { lat: 48.242633, lng: 17.079803 },
    { lat: 48.242595, lng: 17.079724 },
    { lat: 48.242565, lng: 17.079087 },
    { lat: 48.242268, lng: 17.078399 },
    { lat: 48.242033, lng: 17.078003 },
    { lat: 48.241844, lng: 17.077951 },
    { lat: 48.241553, lng: 17.077686 },
    { lat: 48.240963, lng: 17.077525 },
    { lat: 48.240676, lng: 17.077283 },
    { lat: 48.240439, lng: 17.077195 },
    { lat: 48.240103, lng: 17.076494 },
    { lat: 48.240131, lng: 17.076425 },
    { lat: 48.240102, lng: 17.076364 },
    { lat: 48.23982, lng: 17.076337 },
    { lat: 48.239631, lng: 17.076222 },
    { lat: 48.239172, lng: 17.07625 },
    { lat: 48.239133, lng: 17.07611 },
    { lat: 48.238822, lng: 17.076079 },
    { lat: 48.238495, lng: 17.076266 },
    { lat: 48.238417, lng: 17.076202 },
    { lat: 48.23829, lng: 17.076291 },
    { lat: 48.23813, lng: 17.076135 },
    { lat: 48.237902, lng: 17.076172 },
    { lat: 48.237727, lng: 17.076388 },
    { lat: 48.237529, lng: 17.076454 },
    { lat: 48.237462, lng: 17.076574 },
    { lat: 48.237331, lng: 17.076522 },
    { lat: 48.23713, lng: 17.076684 },
    { lat: 48.236835, lng: 17.07669 },
    { lat: 48.236743, lng: 17.076624 },
    { lat: 48.236607, lng: 17.076694 },
    { lat: 48.236427, lng: 17.076609 },
    { lat: 48.236214, lng: 17.0767 },
    { lat: 48.235916, lng: 17.07667 },
    { lat: 48.235954, lng: 17.07641 },
    { lat: 48.23583, lng: 17.076175 },
    { lat: 48.23586, lng: 17.075499 },
    { lat: 48.235618, lng: 17.073939 },
    { lat: 48.235631, lng: 17.073779 },
    { lat: 48.236196, lng: 17.072481 },
    { lat: 48.2367, lng: 17.071571 },
    { lat: 48.23666, lng: 17.070831 },
    { lat: 48.2371, lng: 17.069979 },
    { lat: 48.237433, lng: 17.069621 },
    { lat: 48.237849, lng: 17.069406 },
    { lat: 48.23801, lng: 17.069392 },
    { lat: 48.238249, lng: 17.069133 },
    { lat: 48.238336, lng: 17.068941 },
    { lat: 48.238422, lng: 17.068902 },
    { lat: 48.238699, lng: 17.068565 },
    { lat: 48.2396, lng: 17.067873 },
    { lat: 48.240478, lng: 17.067832 },
    { lat: 48.240602, lng: 17.067931 },
    { lat: 48.240777, lng: 17.067956 },
    { lat: 48.24094, lng: 17.068158 },
    { lat: 48.241275, lng: 17.068215 },
    { lat: 48.241517, lng: 17.068096 },
    { lat: 48.242156, lng: 17.067359 },
    { lat: 48.242352, lng: 17.066667 },
    { lat: 48.242314, lng: 17.066506 },
    { lat: 48.242509, lng: 17.066115 },
    { lat: 48.242554, lng: 17.065868 },
    { lat: 48.242663, lng: 17.065608 },
    { lat: 48.242651, lng: 17.064827 },
    { lat: 48.242582, lng: 17.064566 },
    { lat: 48.242578, lng: 17.064164 },
    { lat: 48.242477, lng: 17.063818 },
    { lat: 48.242524, lng: 17.063775 },
    { lat: 48.242533, lng: 17.063526 },
    { lat: 48.242576, lng: 17.063442 },
    { lat: 48.242527, lng: 17.062999 },
    { lat: 48.242711, lng: 17.06215 },
    { lat: 48.242774, lng: 17.062076 },
    { lat: 48.242717, lng: 17.061691 },
    { lat: 48.242798, lng: 17.061537 },
    { lat: 48.242745, lng: 17.06133 },
    { lat: 48.242825, lng: 17.061106 },
    { lat: 48.242737, lng: 17.060889 },
    { lat: 48.242769, lng: 17.060765 },
    { lat: 48.242677, lng: 17.060428 },
    { lat: 48.242707, lng: 17.060045 },
    { lat: 48.242664, lng: 17.059861 },
    { lat: 48.24281, lng: 17.0593 },
    { lat: 48.242922, lng: 17.059199 },
    { lat: 48.24313, lng: 17.058731 },
    { lat: 48.243289, lng: 17.058648 },
    { lat: 48.243486, lng: 17.05866 },
    { lat: 48.2435837, lng: 17.0585677 },
    { lat: 48.243645, lng: 17.057986 },
    { lat: 48.243832, lng: 17.057774 },
    { lat: 48.243798, lng: 17.057634 },
    { lat: 48.243661, lng: 17.057419 },
    { lat: 48.243663, lng: 17.057238 },
    { lat: 48.2437516, lng: 17.0571703 },
    { lat: 48.243856, lng: 17.056658 },
    { lat: 48.2439631, lng: 17.0561278 },
    { lat: 48.2439749, lng: 17.0558694 },
    { lat: 48.243981, lng: 17.055694 },
    { lat: 48.24402, lng: 17.055436 },
    { lat: 48.2442506, lng: 17.054887 },
    { lat: 48.244404, lng: 17.054272 },
    { lat: 48.2445946, lng: 17.0537683 },
    { lat: 48.2446679, lng: 17.0532408 },
    { lat: 48.2447326, lng: 17.0518012 },
    { lat: 48.24475, lng: 17.051414 },
    { lat: 48.244965, lng: 17.050014 },
    { lat: 48.24496, lng: 17.049014 },
    { lat: 48.2451398, lng: 17.0481227 },
    { lat: 48.245391, lng: 17.047398 },
    { lat: 48.245508, lng: 17.04717 },
    { lat: 48.245842, lng: 17.046766 },
    { lat: 48.2459013, lng: 17.0467557 },
    { lat: 48.2460317, lng: 17.0467328 },
    { lat: 48.246581, lng: 17.046193 },
    { lat: 48.246922, lng: 17.04594 },
    { lat: 48.2492919, lng: 17.0448156 },
    { lat: 48.2495777, lng: 17.0447146 },
    { lat: 48.250351, lng: 17.0444415 },
    { lat: 48.251167, lng: 17.04572 },
    { lat: 48.252069, lng: 17.044192 },
    { lat: 48.25175, lng: 17.043598 },
    { lat: 48.251085, lng: 17.042748 },
    { lat: 48.250769, lng: 17.040328 },
    { lat: 48.250708, lng: 17.038487 },
    { lat: 48.250791, lng: 17.036532 },
    { lat: 48.250687, lng: 17.035439 },
    { lat: 48.250534, lng: 17.034883 },
    { lat: 48.251136, lng: 17.03424 },
    { lat: 48.250797, lng: 17.032858 },
    { lat: 48.250664, lng: 17.032003 },
    { lat: 48.25032, lng: 17.030445 },
    { lat: 48.250178, lng: 17.02946 },
    { lat: 48.250985, lng: 17.02887 },
    { lat: 48.250575, lng: 17.025094 },
    { lat: 48.250221, lng: 17.024321 },
    { lat: 48.250119, lng: 17.023943 },
    { lat: 48.250181, lng: 17.023304 },
    { lat: 48.250352, lng: 17.022634 },
    { lat: 48.250112, lng: 17.021797 },
    { lat: 48.250122, lng: 17.020771 },
    { lat: 48.249496, lng: 17.020495 },
    { lat: 48.249057, lng: 17.020183 },
    { lat: 48.248919, lng: 17.019352 },
    { lat: 48.248708, lng: 17.018556 },
    { lat: 48.248486, lng: 17.018406 },
    { lat: 48.248052, lng: 17.01837 },
    { lat: 48.2488042, lng: 17.0145296 },
    { lat: 48.249089, lng: 17.013088 },
    { lat: 48.2504191, lng: 17.0067213 },
    { lat: 48.250443, lng: 17.006607 },
    { lat: 48.250896, lng: 17.00385 },
    { lat: 48.250946, lng: 17.003398 },
    { lat: 48.250802, lng: 17.001291 },
    { lat: 48.2507739, lng: 17.0011304 },
    { lat: 48.250928, lng: 16.99959 },
    { lat: 48.25123, lng: 16.997881 },
    { lat: 48.252003, lng: 16.998202 },
    { lat: 48.252117, lng: 16.997538 },
    { lat: 48.252509, lng: 16.996496 },
    { lat: 48.252606, lng: 16.995964 },
    { lat: 48.252684, lng: 16.994968 },
    { lat: 48.252621, lng: 16.993931 },
    { lat: 48.252342, lng: 16.99398 },
    { lat: 48.252314, lng: 16.993203 },
    { lat: 48.252038, lng: 16.991686 },
    { lat: 48.252172, lng: 16.990789 },
    { lat: 48.252006, lng: 16.989544 },
    { lat: 48.252511, lng: 16.989376 },
    { lat: 48.252365, lng: 16.987904 },
    { lat: 48.252251, lng: 16.987222 },
    { lat: 48.252565, lng: 16.987035 },
    { lat: 48.252618, lng: 16.987152 },
    { lat: 48.252763, lng: 16.986893 },
    { lat: 48.253053, lng: 16.98669 },
    { lat: 48.253418, lng: 16.986555 },
    { lat: 48.253101, lng: 16.985682 },
    { lat: 48.253361, lng: 16.985104 },
    { lat: 48.253673, lng: 16.984633 },
    { lat: 48.253887, lng: 16.984437 },
    { lat: 48.25474, lng: 16.983906 },
    { lat: 48.255159, lng: 16.983479 },
    { lat: 48.255709, lng: 16.982664 },
    { lat: 48.258613, lng: 16.977943 },
    { lat: 48.260222, lng: 16.975631 },
    { lat: 48.260363, lng: 16.975294 },
    { lat: 48.26087, lng: 16.974799 },
    { lat: 48.261632, lng: 16.973347 },
    { lat: 48.262215, lng: 16.972379 },
    { lat: 48.262414, lng: 16.971895 },
    { lat: 48.262546, lng: 16.972003 },
    { lat: 48.262896, lng: 16.971499 },
    { lat: 48.263154, lng: 16.970956 },
    { lat: 48.263642, lng: 16.969698 },
    { lat: 48.26349, lng: 16.967898 },
    { lat: 48.264039, lng: 16.964434 },
    { lat: 48.264194, lng: 16.963701 },
    { lat: 48.264394, lng: 16.963123 },
    { lat: 48.2646447, lng: 16.9615941 },
    { lat: 48.264861, lng: 16.960275 },
    { lat: 48.2650685, lng: 16.9584771 },
    { lat: 48.2643436, lng: 16.9583881 },
    { lat: 48.2638653, lng: 16.9580707 },
    { lat: 48.2634367, lng: 16.9578687 },
    { lat: 48.2633608, lng: 16.9578337 },
    { lat: 48.2632333, lng: 16.9579633 },
    { lat: 48.2630914, lng: 16.9596238 },
    { lat: 48.2618834, lng: 16.9596406 },
    { lat: 48.2609041, lng: 16.9597947 },
    { lat: 48.2603492, lng: 16.9597639 },
    { lat: 48.2603888, lng: 16.9601901 },
    { lat: 48.2601658, lng: 16.9603314 },
    { lat: 48.2599697, lng: 16.9608321 },
    { lat: 48.2600102, lng: 16.9610723 },
    { lat: 48.2598706, lng: 16.9618663 },
    { lat: 48.2596399, lng: 16.9618006 },
    { lat: 48.2595768, lng: 16.9622462 },
    { lat: 48.2591905, lng: 16.9621315 },
    { lat: 48.2591021, lng: 16.962735 },
    { lat: 48.2591298, lng: 16.9628587 },
    { lat: 48.2588616, lng: 16.9627232 },
    { lat: 48.258822, lng: 16.9631305 },
    { lat: 48.2586885, lng: 16.9633916 },
    { lat: 48.2584459, lng: 16.9636923 },
    { lat: 48.2582341, lng: 16.9642403 },
    { lat: 48.2575168, lng: 16.9653117 },
    { lat: 48.2544097, lng: 16.968632 },
    { lat: 48.2542278, lng: 16.96878 },
    { lat: 48.2542114, lng: 16.9687403 },
    { lat: 48.2535136, lng: 16.9695362 },
    { lat: 48.2525224, lng: 16.9704855 },
    { lat: 48.2411995, lng: 16.9807341 },
    { lat: 48.241164, lng: 16.9808066 },
    { lat: 48.2430046, lng: 16.9848562 },
    { lat: 48.2437937, lng: 16.9867833 },
    { lat: 48.2396209, lng: 16.9899313 },
    { lat: 48.2403, lng: 16.991965 },
    { lat: 48.2281909, lng: 17.001071 },
    { lat: 48.2296726, lng: 17.0046257 },
    { lat: 48.2297868, lng: 17.0047935 },
    { lat: 48.2298195, lng: 17.0049558 },
    { lat: 48.2298306, lng: 17.0075208 },
    { lat: 48.2275871, lng: 17.0082903 },
    { lat: 48.2250078, lng: 17.0088594 },
    { lat: 48.2247555, lng: 17.0088227 },
    { lat: 48.2218382, lng: 17.0077102 },
    { lat: 48.2214159, lng: 17.0074845 },
    { lat: 48.2205376, lng: 17.0068059 },
    { lat: 48.2205698, lng: 17.0069955 },
    { lat: 48.2204167, lng: 17.0068727 },
    { lat: 48.2207327, lng: 17.0089067 },
    { lat: 48.2208424, lng: 17.0092382 },
    { lat: 48.2210856, lng: 17.0096523 },
    { lat: 48.2214638, lng: 17.0100594 },
    { lat: 48.2204149, lng: 17.0135299 },
    { lat: 48.220379, lng: 17.0138625 },
    { lat: 48.220389, lng: 17.0158733 },
    { lat: 48.2199816, lng: 17.0174291 },
    { lat: 48.2197734, lng: 17.0181503 },
    { lat: 48.2197136, lng: 17.0186463 },
    { lat: 48.2200275, lng: 17.0213461 },
    { lat: 48.2200203, lng: 17.0215382 },
    { lat: 48.2196971, lng: 17.0237819 },
    { lat: 48.2195379, lng: 17.0241115 },
    { lat: 48.2186661, lng: 17.0250779 },
    { lat: 48.2182979, lng: 17.0253618 },
    { lat: 48.2129852, lng: 17.0308508 },
    { lat: 48.2118337, lng: 17.0318751 },
    { lat: 48.2086412, lng: 17.0363454 },
    { lat: 48.207737, lng: 17.0377149 },
    { lat: 48.20764, lng: 17.0380787 },
    { lat: 48.2076934, lng: 17.0381332 },
    { lat: 48.2077295, lng: 17.0388096 },
    { lat: 48.2058075, lng: 17.0400015 },
    { lat: 48.2049954, lng: 17.0407506 },
    { lat: 48.204853, lng: 17.0409159 },
    { lat: 48.2047634, lng: 17.0411772 },
    { lat: 48.2046838, lng: 17.0412673 },
    { lat: 48.2045928, lng: 17.0413252 },
    { lat: 48.2044863, lng: 17.0412221 },
    { lat: 48.2037516, lng: 17.0415839 },
    { lat: 48.2029947, lng: 17.0421977 },
    { lat: 48.2027401, lng: 17.0426238 },
    { lat: 48.2025551, lng: 17.0431559 },
    { lat: 48.2025151, lng: 17.0434822 },
    { lat: 48.2021741, lng: 17.0447911 },
    { lat: 48.2021336, lng: 17.0450095 },
    { lat: 48.2021469, lng: 17.045283 },
    { lat: 48.2018381, lng: 17.0471987 },
    { lat: 48.2017506, lng: 17.0474887 },
    { lat: 48.201536, lng: 17.0477064 },
    { lat: 48.2013938, lng: 17.0479878 },
    { lat: 48.2012503, lng: 17.0480891 },
    { lat: 48.2011901, lng: 17.0483342 },
    { lat: 48.2009437, lng: 17.0487445 },
    { lat: 48.2005424, lng: 17.0489259 },
    { lat: 48.1999518, lng: 17.0496736 },
    { lat: 48.1993847, lng: 17.0498438 },
    { lat: 48.1992807, lng: 17.0499394 },
    { lat: 48.1985565, lng: 17.0508707 },
    { lat: 48.1984337, lng: 17.0511226 },
    { lat: 48.1983716, lng: 17.0516864 },
    { lat: 48.198301, lng: 17.0518282 },
    { lat: 48.1979962, lng: 17.0520455 },
    { lat: 48.1979748, lng: 17.0521139 },
    { lat: 48.1981348, lng: 17.053548 },
    { lat: 48.1983881, lng: 17.0538493 },
    { lat: 48.1986813, lng: 17.0545023 },
    { lat: 48.1987506, lng: 17.0549203 },
    { lat: 48.1989382, lng: 17.0551544 },
    { lat: 48.199386, lng: 17.0553733 },
    { lat: 48.1997535, lng: 17.0557578 },
    { lat: 48.1998615, lng: 17.0561816 },
    { lat: 48.1998407, lng: 17.0568354 },
    { lat: 48.199916, lng: 17.0569946 },
    { lat: 48.2004426, lng: 17.0571118 },
    { lat: 48.2007455, lng: 17.0573107 },
    { lat: 48.2013829, lng: 17.057512 },
    { lat: 48.2016354, lng: 17.0577175 },
    { lat: 48.2019088, lng: 17.0575362 },
    { lat: 48.202231, lng: 17.0572057 },
    { lat: 48.2025259, lng: 17.0571235 },
    { lat: 48.2033077, lng: 17.0573194 },
    { lat: 48.2038502, lng: 17.0580842 },
    { lat: 48.204329, lng: 17.0584939 },
    { lat: 48.2044734, lng: 17.0585073 },
    { lat: 48.2048627, lng: 17.0583418 },
    { lat: 48.2049677, lng: 17.0582972 },
    { lat: 48.2058002, lng: 17.0581432 },
    { lat: 48.2065501, lng: 17.0586189 },
    { lat: 48.2086815, lng: 17.0586059 },
    { lat: 48.2092338, lng: 17.0587508 },
    { lat: 48.2093529, lng: 17.0587393 },
    { lat: 48.2103616, lng: 17.0578399 },
    { lat: 48.2106449, lng: 17.0576865 },
    { lat: 48.2116804, lng: 17.0568634 },
    { lat: 48.2122182, lng: 17.0563331 },
    { lat: 48.2128537, lng: 17.0563079 },
    { lat: 48.2133607, lng: 17.0566007 },
    { lat: 48.2138397, lng: 17.0571766 },
    { lat: 48.2141237, lng: 17.0580278 },
    { lat: 48.2142902, lng: 17.0582508 },
    { lat: 48.2144236, lng: 17.0588281 },
    { lat: 48.2144574, lng: 17.0592261 },
    { lat: 48.2143514, lng: 17.0602907 },
    { lat: 48.2139389, lng: 17.0610679 },
    { lat: 48.2136932, lng: 17.0617573 },
    { lat: 48.2143693, lng: 17.0621742 },
    { lat: 48.2148341, lng: 17.062928 },
    { lat: 48.2150047, lng: 17.0635064 },
    { lat: 48.2147216, lng: 17.0647408 },
    { lat: 48.2147247, lng: 17.0649536 },
    { lat: 48.214822, lng: 17.0653706 },
    { lat: 48.2145454, lng: 17.0660544 },
    { lat: 48.214318, lng: 17.067702 },
    { lat: 48.214698, lng: 17.068118 },
    { lat: 48.214809, lng: 17.068128 },
    { lat: 48.214881, lng: 17.068154 },
    { lat: 48.215036, lng: 17.06821 },
    { lat: 48.215456, lng: 17.068364 },
    { lat: 48.21603, lng: 17.068584 },
    { lat: 48.21673, lng: 17.069034 },
    { lat: 48.216982, lng: 17.06937 },
    { lat: 48.217225, lng: 17.069669 },
    { lat: 48.217689, lng: 17.069902 },
    { lat: 48.218031, lng: 17.070076 },
    { lat: 48.218862, lng: 17.070506 },
    { lat: 48.2190918, lng: 17.0704674 },
    { lat: 48.2194515, lng: 17.0704065 },
    { lat: 48.2196366, lng: 17.0703231 },
    { lat: 48.2205271, lng: 17.0699109 },
    { lat: 48.221081, lng: 17.070722 },
    { lat: 48.221344, lng: 17.071105 },
    { lat: 48.22169, lng: 17.071609 },
    { lat: 48.22189, lng: 17.072013 },
    { lat: 48.222139, lng: 17.072518 },
    { lat: 48.222234, lng: 17.073142 },
    { lat: 48.22226, lng: 17.073217 },
    { lat: 48.222284, lng: 17.073285 },
    { lat: 48.222495, lng: 17.073887 },
    { lat: 48.222573, lng: 17.074111 },
    { lat: 48.222999, lng: 17.074995 },
    { lat: 48.223063, lng: 17.075128 },
    { lat: 48.223127, lng: 17.075261 },
    { lat: 48.223278, lng: 17.075572 },
    { lat: 48.223211, lng: 17.076663 },
    { lat: 48.223142, lng: 17.077841 },
    { lat: 48.222773, lng: 17.078658 },
    { lat: 48.222591, lng: 17.079066 },
    { lat: 48.222575, lng: 17.079099 },
    { lat: 48.222516, lng: 17.07923 },
    { lat: 48.222495, lng: 17.079276 },
    { lat: 48.221978, lng: 17.080428 },
    { lat: 48.221928, lng: 17.08054 },
    { lat: 48.221934, lng: 17.08068 },
    { lat: 48.221936, lng: 17.080725 },
    { lat: 48.221937, lng: 17.080747 },
    { lat: 48.221939, lng: 17.080797 },
    { lat: 48.221964, lng: 17.081388 },
    { lat: 48.221966, lng: 17.081428 },
    { lat: 48.221954, lng: 17.081471 },
    { lat: 48.221889, lng: 17.081697 },
    { lat: 48.221748, lng: 17.082231 },
    { lat: 48.221739, lng: 17.082265 },
    { lat: 48.221616, lng: 17.08272 },
    { lat: 48.2216, lng: 17.082781 },
    { lat: 48.221525, lng: 17.08305 },
    { lat: 48.22138, lng: 17.083565 },
    { lat: 48.221321, lng: 17.083973 },
    { lat: 48.221309, lng: 17.084048 },
    { lat: 48.221278, lng: 17.084269 },
    { lat: 48.220935, lng: 17.085016 },
    { lat: 48.220535, lng: 17.085895 },
    { lat: 48.220268, lng: 17.086473 },
    { lat: 48.219877, lng: 17.086854 },
    { lat: 48.219616, lng: 17.087114 },
    { lat: 48.219543, lng: 17.087186 },
    { lat: 48.219456, lng: 17.087271 },
    { lat: 48.219208, lng: 17.087516 },
    { lat: 48.219148, lng: 17.087575 },
    { lat: 48.218591, lng: 17.088117 },
    { lat: 48.21819, lng: 17.088515 },
    { lat: 48.218157, lng: 17.088549 },
    { lat: 48.217875, lng: 17.088818 },
    { lat: 48.217852, lng: 17.088843 },
    { lat: 48.217833, lng: 17.089229 },
    { lat: 48.217825, lng: 17.089427 },
    { lat: 48.217816, lng: 17.089603 },
    { lat: 48.217902, lng: 17.090329 },
    { lat: 48.217912, lng: 17.090429 },
    { lat: 48.217928, lng: 17.090574 },
    { lat: 48.217936, lng: 17.090643 },
    { lat: 48.217938, lng: 17.090661 },
    { lat: 48.218077, lng: 17.09068 },
    { lat: 48.218165, lng: 17.090532 },
    { lat: 48.218232, lng: 17.090496 },
    { lat: 48.218323, lng: 17.09036 },
    { lat: 48.218344, lng: 17.090281 },
    { lat: 48.218416, lng: 17.090161 },
    { lat: 48.218452, lng: 17.09007 },
    { lat: 48.218555, lng: 17.089784 },
    { lat: 48.218645, lng: 17.089648 },
    { lat: 48.218735, lng: 17.089635 },
    { lat: 48.218837, lng: 17.089602 },
    { lat: 48.218903, lng: 17.089582 },
    { lat: 48.218958, lng: 17.089588 },
    { lat: 48.219036, lng: 17.089535 },
    { lat: 48.219095, lng: 17.089485 },
    { lat: 48.219125, lng: 17.089402 },
    { lat: 48.219483, lng: 17.08921 },
    { lat: 48.219663, lng: 17.089196 },
    { lat: 48.219722, lng: 17.089255 },
    { lat: 48.22008, lng: 17.089214 },
    { lat: 48.220128, lng: 17.089308 },
    { lat: 48.220216, lng: 17.089347 },
    { lat: 48.220367, lng: 17.089306 },
    { lat: 48.220393, lng: 17.089278 },
    { lat: 48.22043, lng: 17.089261 },
    { lat: 48.220465, lng: 17.089282 },
    { lat: 48.220482, lng: 17.089274 },
    { lat: 48.220496, lng: 17.089205 },
    { lat: 48.220468, lng: 17.089137 },
    { lat: 48.220445, lng: 17.089131 },
    { lat: 48.220427, lng: 17.089095 },
    { lat: 48.220558, lng: 17.088987 },
    { lat: 48.220615, lng: 17.089035 },
    { lat: 48.22073, lng: 17.089331 },
    { lat: 48.22075, lng: 17.089356 },
    { lat: 48.220812, lng: 17.089346 },
    { lat: 48.220866, lng: 17.089338 },
    { lat: 48.220932, lng: 17.08942 },
    { lat: 48.220965, lng: 17.089478 },
    { lat: 48.221023, lng: 17.089517 },
    { lat: 48.221068, lng: 17.08951 },
    { lat: 48.221116, lng: 17.089494 },
    { lat: 48.221185, lng: 17.089509 },
    { lat: 48.221234, lng: 17.089511 },
    { lat: 48.221295, lng: 17.089445 },
    { lat: 48.221368, lng: 17.089426 },
    { lat: 48.221422, lng: 17.089423 },
    { lat: 48.221457, lng: 17.089439 },
    { lat: 48.221625, lng: 17.089466 },
    { lat: 48.22169, lng: 17.089483 },
    { lat: 48.221726, lng: 17.089458 },
    { lat: 48.221765, lng: 17.089473 },
    { lat: 48.221785, lng: 17.089488 },
    { lat: 48.2218024, lng: 17.0895515 },
    { lat: 48.2218154, lng: 17.0896245 },
    { lat: 48.2218184, lng: 17.0896785 },
    { lat: 48.2218454, lng: 17.0896745 },
    { lat: 48.2218814, lng: 17.0896795 },
    { lat: 48.222078, lng: 17.089734 },
    { lat: 48.222107, lng: 17.089806 },
    { lat: 48.22213, lng: 17.089852 },
    { lat: 48.222135, lng: 17.089945 },
    { lat: 48.222146, lng: 17.090167 },
    { lat: 48.222175, lng: 17.090272 },
    { lat: 48.22217, lng: 17.090374 },
    { lat: 48.222137, lng: 17.090484 },
    { lat: 48.2220744, lng: 17.0906312 },
    { lat: 48.2220399, lng: 17.0907412 },
    { lat: 48.2220394, lng: 17.0908332 },
    { lat: 48.222084, lng: 17.090952 },
    { lat: 48.222089, lng: 17.090996 },
    { lat: 48.222099, lng: 17.091089 },
    { lat: 48.222113, lng: 17.091191 },
    { lat: 48.22208, lng: 17.091236 },
    { lat: 48.222032, lng: 17.09119 },
    { lat: 48.222002, lng: 17.091191 },
    { lat: 48.22196, lng: 17.091239 },
    { lat: 48.221993, lng: 17.091425 },
    { lat: 48.221993, lng: 17.091538 },
    { lat: 48.221965, lng: 17.091594 },
    { lat: 48.221978, lng: 17.091681 },
    { lat: 48.222041, lng: 17.091729 },
    { lat: 48.222062, lng: 17.09178 },
    { lat: 48.222039, lng: 17.091838 },
    { lat: 48.22194, lng: 17.09184 },
    { lat: 48.221813, lng: 17.091773 },
    { lat: 48.221775, lng: 17.09181 },
    { lat: 48.221773, lng: 17.091905 },
    { lat: 48.221711, lng: 17.091928 },
    { lat: 48.221692, lng: 17.09192 },
    { lat: 48.221675, lng: 17.091917 },
    { lat: 48.221623, lng: 17.091899 },
    { lat: 48.221591, lng: 17.091892 },
    { lat: 48.2215534, lng: 17.0919604 },
    { lat: 48.221539, lng: 17.092049 },
    { lat: 48.221549, lng: 17.092075 },
    { lat: 48.221579, lng: 17.092111 },
    { lat: 48.221594, lng: 17.09214 },
    { lat: 48.221598, lng: 17.092164 },
    { lat: 48.221598, lng: 17.092188 },
    { lat: 48.22157, lng: 17.092247 },
    { lat: 48.221536, lng: 17.092279 },
    { lat: 48.221493, lng: 17.092313 },
    { lat: 48.221442, lng: 17.092361 },
    { lat: 48.221422, lng: 17.092459 },
    { lat: 48.221402, lng: 17.09257 },
    { lat: 48.221372, lng: 17.092656 },
    { lat: 48.22133, lng: 17.092703 },
    { lat: 48.221337, lng: 17.092777 },
    { lat: 48.22134, lng: 17.09285 },
    { lat: 48.221356, lng: 17.092956 },
    { lat: 48.221365, lng: 17.093119 },
    { lat: 48.221334, lng: 17.093189 },
    { lat: 48.221333, lng: 17.093236 },
    { lat: 48.221366, lng: 17.093584 },
    { lat: 48.22132, lng: 17.09368 },
    { lat: 48.2213537, lng: 17.093724 },
    { lat: 48.221366, lng: 17.09374 },
    { lat: 48.221474, lng: 17.093779 },
    { lat: 48.221506, lng: 17.093783 },
    { lat: 48.221546, lng: 17.093792 },
    { lat: 48.221569, lng: 17.09383 },
    { lat: 48.221556, lng: 17.0939 },
    { lat: 48.221541, lng: 17.093956 },
    { lat: 48.221513, lng: 17.094069 },
    { lat: 48.221493, lng: 17.09418 },
    { lat: 48.221528, lng: 17.094217 },
    { lat: 48.221647, lng: 17.094199 },
    { lat: 48.221825, lng: 17.094323 },
    { lat: 48.221849, lng: 17.094336 },
    { lat: 48.221868, lng: 17.094375 },
    { lat: 48.221853, lng: 17.09449 },
    { lat: 48.221886, lng: 17.094533 },
    { lat: 48.221905, lng: 17.094531 },
    { lat: 48.221953, lng: 17.094523 },
    { lat: 48.221991, lng: 17.094591 },
    { lat: 48.221987, lng: 17.094668 },
    { lat: 48.221967, lng: 17.094771 },
    { lat: 48.221949, lng: 17.094794 },
    { lat: 48.221935, lng: 17.094823 },
    { lat: 48.221935, lng: 17.094908 },
    { lat: 48.221967, lng: 17.095 },
    { lat: 48.221944, lng: 17.09509 },
    { lat: 48.221956, lng: 17.095138 },
    { lat: 48.222012, lng: 17.095156 },
    { lat: 48.222082, lng: 17.095132 },
    { lat: 48.222104, lng: 17.095201 },
    { lat: 48.222108, lng: 17.095238 },
    { lat: 48.2221, lng: 17.095263 },
    { lat: 48.222127, lng: 17.095396 },
    { lat: 48.222155, lng: 17.095497 },
    { lat: 48.222197, lng: 17.095592 },
    { lat: 48.222213, lng: 17.095612 },
    { lat: 48.222231, lng: 17.095615 },
    { lat: 48.22225, lng: 17.095607 },
    { lat: 48.222293, lng: 17.095573 },
    { lat: 48.222339, lng: 17.09558 },
    { lat: 48.222393, lng: 17.095585 },
    { lat: 48.222438, lng: 17.095578 },
    { lat: 48.222456, lng: 17.095576 },
    { lat: 48.22248, lng: 17.095583 },
    { lat: 48.222494, lng: 17.095607 },
    { lat: 48.222537, lng: 17.095701 },
    { lat: 48.222547, lng: 17.095834 },
    { lat: 48.222611, lng: 17.095856 },
    { lat: 48.222709, lng: 17.095848 },
    { lat: 48.22283, lng: 17.095897 },
    { lat: 48.222947, lng: 17.095893 },
    { lat: 48.223011, lng: 17.09595 },
    { lat: 48.223087, lng: 17.096088 },
    { lat: 48.223161, lng: 17.096104 },
    { lat: 48.2232, lng: 17.096152 },
    { lat: 48.223286, lng: 17.096221 },
    { lat: 48.223368, lng: 17.096357 },
    { lat: 48.22337, lng: 17.096397 },
    { lat: 48.223371, lng: 17.096435 },
    { lat: 48.223382, lng: 17.096571 },
    { lat: 48.223419, lng: 17.096636 },
    { lat: 48.223519, lng: 17.096678 },
    { lat: 48.223537, lng: 17.096778 },
    { lat: 48.223525, lng: 17.096851 },
    { lat: 48.223519, lng: 17.096935 },
    { lat: 48.223596, lng: 17.097066 },
    { lat: 48.223727, lng: 17.097131 },
    { lat: 48.223771, lng: 17.09719 },
    { lat: 48.223793, lng: 17.097267 },
    { lat: 48.223884, lng: 17.097307 },
    { lat: 48.223959, lng: 17.097246 },
    { lat: 48.223997, lng: 17.097242 },
    { lat: 48.224018, lng: 17.097284 },
    { lat: 48.223988, lng: 17.097343 },
    { lat: 48.22397, lng: 17.097366 },
    { lat: 48.223972, lng: 17.097401 },
    { lat: 48.224015, lng: 17.09745 },
    { lat: 48.224069, lng: 17.097468 },
    { lat: 48.224087, lng: 17.097506 },
    { lat: 48.224035, lng: 17.097552 },
    { lat: 48.224024, lng: 17.097607 },
    { lat: 48.224064, lng: 17.097665 },
    { lat: 48.224054, lng: 17.097717 },
    { lat: 48.224003, lng: 17.097758 },
    { lat: 48.22396, lng: 17.097779 },
    { lat: 48.223928, lng: 17.097842 },
    { lat: 48.22391, lng: 17.098227 },
    { lat: 48.223945, lng: 17.098267 },
    { lat: 48.223983, lng: 17.098271 },
    { lat: 48.224035, lng: 17.09831 },
    { lat: 48.223992, lng: 17.098468 },
    { lat: 48.224004, lng: 17.098546 },
    { lat: 48.224032, lng: 17.098556 },
    { lat: 48.224062, lng: 17.098605 },
    { lat: 48.224072, lng: 17.098617 },
    { lat: 48.224069, lng: 17.098699 },
    { lat: 48.224017, lng: 17.098734 },
    { lat: 48.223987, lng: 17.09877 },
    { lat: 48.223946, lng: 17.098864 },
    { lat: 48.223922, lng: 17.098938 },
    { lat: 48.223876, lng: 17.099053 },
    { lat: 48.223833, lng: 17.099086 },
    { lat: 48.2238, lng: 17.099132 },
    { lat: 48.223799, lng: 17.099254 },
    { lat: 48.223785, lng: 17.09931 },
    { lat: 48.223747, lng: 17.099289 },
    { lat: 48.223729, lng: 17.099292 },
    { lat: 48.22367, lng: 17.099477 },
    { lat: 48.223694, lng: 17.099568 },
    { lat: 48.223687, lng: 17.099596 },
    { lat: 48.223659, lng: 17.099586 },
    { lat: 48.223622, lng: 17.099578 },
    { lat: 48.223561, lng: 17.099615 },
    { lat: 48.223388, lng: 17.100128 },
    { lat: 48.223392, lng: 17.100182 },
    { lat: 48.22342, lng: 17.100191 },
    { lat: 48.223441, lng: 17.100199 },
    { lat: 48.223484, lng: 17.100218 },
    { lat: 48.223505, lng: 17.100235 },
    { lat: 48.223511, lng: 17.100266 },
    { lat: 48.223497, lng: 17.100333 },
    { lat: 48.223524, lng: 17.100363 },
    { lat: 48.223586, lng: 17.100296 },
    { lat: 48.223658, lng: 17.100254 },
    { lat: 48.223738, lng: 17.100238 },
    { lat: 48.223747, lng: 17.100301 },
    { lat: 48.223735, lng: 17.100318 },
    { lat: 48.223646, lng: 17.100388 },
    { lat: 48.223638, lng: 17.100482 },
    { lat: 48.223606, lng: 17.100507 },
    { lat: 48.22357, lng: 17.100642 },
    { lat: 48.223564, lng: 17.100731 },
    { lat: 48.223572, lng: 17.10078 },
    { lat: 48.223563, lng: 17.100798 },
    { lat: 48.223543, lng: 17.100803 },
    { lat: 48.223506, lng: 17.100781 },
    { lat: 48.223479, lng: 17.100721 },
    { lat: 48.223447, lng: 17.100612 },
    { lat: 48.223421, lng: 17.10061 },
    { lat: 48.223402, lng: 17.100654 },
    { lat: 48.22337, lng: 17.100686 },
    { lat: 48.223326, lng: 17.100679 },
    { lat: 48.223298, lng: 17.10071 },
    { lat: 48.223241, lng: 17.100746 },
    { lat: 48.223201, lng: 17.100698 },
    { lat: 48.223184, lng: 17.100714 },
    { lat: 48.223178, lng: 17.100755 },
    { lat: 48.223187, lng: 17.100889 },
    { lat: 48.223172, lng: 17.100934 },
    { lat: 48.223176, lng: 17.100999 },
    { lat: 48.223169, lng: 17.101027 },
    { lat: 48.223115, lng: 17.101022 },
    { lat: 48.223081, lng: 17.101054 },
    { lat: 48.223056, lng: 17.101123 },
    { lat: 48.223076, lng: 17.101206 },
    { lat: 48.223089, lng: 17.101284 },
    { lat: 48.223102, lng: 17.101353 },
    { lat: 48.223148, lng: 17.101396 },
    { lat: 48.223196, lng: 17.101429 },
    { lat: 48.223243, lng: 17.101449 },
    { lat: 48.223255, lng: 17.101487 },
    { lat: 48.223218, lng: 17.101584 },
    { lat: 48.223155, lng: 17.101611 },
    { lat: 48.223132, lng: 17.101682 },
    { lat: 48.223155, lng: 17.1018 },
    { lat: 48.223181, lng: 17.101814 },
    { lat: 48.223196, lng: 17.101846 },
    { lat: 48.223191, lng: 17.101902 },
    { lat: 48.223177, lng: 17.101976 },
    { lat: 48.223167, lng: 17.102017 },
    { lat: 48.223158, lng: 17.102051 },
    { lat: 48.223153, lng: 17.102086 },
    { lat: 48.223104, lng: 17.102298 },
    { lat: 48.223092, lng: 17.102356 },
    { lat: 48.22308, lng: 17.102414 },
    { lat: 48.223041, lng: 17.102602 },
    { lat: 48.223043, lng: 17.102629 },
    { lat: 48.223132, lng: 17.102751 },
    { lat: 48.223119, lng: 17.10282 },
    { lat: 48.223078, lng: 17.102881 },
    { lat: 48.223053, lng: 17.102912 },
    { lat: 48.223049, lng: 17.10298 },
    { lat: 48.223032, lng: 17.103009 },
    { lat: 48.222995, lng: 17.102988 },
    { lat: 48.222952, lng: 17.102886 },
    { lat: 48.222861, lng: 17.102887 },
    { lat: 48.222836, lng: 17.102917 },
    { lat: 48.222857, lng: 17.102955 },
    { lat: 48.222898, lng: 17.10303 },
    { lat: 48.22296, lng: 17.103142 },
    { lat: 48.222947, lng: 17.103225 },
    { lat: 48.222904, lng: 17.103245 },
    { lat: 48.222906, lng: 17.103272 },
    { lat: 48.222928, lng: 17.103336 },
    { lat: 48.222921, lng: 17.103364 },
    { lat: 48.222869, lng: 17.103381 },
    { lat: 48.222822, lng: 17.103406 },
    { lat: 48.222765, lng: 17.103304 },
    { lat: 48.222736, lng: 17.103283 },
    { lat: 48.2226, lng: 17.10344 },
    { lat: 48.222537, lng: 17.103571 },
    { lat: 48.2225126, lng: 17.1036953 },
    { lat: 48.2225075, lng: 17.1037364 },
    { lat: 48.2225186, lng: 17.1039213 },
    { lat: 48.22254, lng: 17.104043 },
    { lat: 48.222568, lng: 17.104166 },
    { lat: 48.222635, lng: 17.104304 },
    { lat: 48.222814, lng: 17.104536 },
    { lat: 48.222841, lng: 17.104574 },
    { lat: 48.222856, lng: 17.104629 },
    { lat: 48.222867, lng: 17.104688 },
    { lat: 48.222911, lng: 17.104824 },
    { lat: 48.222968, lng: 17.104873 },
    { lat: 48.223017, lng: 17.104849 },
    { lat: 48.22304, lng: 17.104927 },
    { lat: 48.22302, lng: 17.105011 },
    { lat: 48.223055, lng: 17.105141 },
    { lat: 48.223171, lng: 17.105315 },
    { lat: 48.223124, lng: 17.10543 },
    { lat: 48.22313, lng: 17.105511 },
    { lat: 48.223165, lng: 17.105505 },
    { lat: 48.223243, lng: 17.10571 },
    { lat: 48.223192, lng: 17.105758 },
    { lat: 48.223143, lng: 17.105712 },
    { lat: 48.223082, lng: 17.105735 },
    { lat: 48.223082, lng: 17.10587 },
    { lat: 48.223084, lng: 17.10591 },
    { lat: 48.2233092, lng: 17.105889 },
    { lat: 48.2233372, lng: 17.105899 },
    { lat: 48.2233472, lng: 17.105924 },
    { lat: 48.2233332, lng: 17.10598 },
    { lat: 48.2232632, lng: 17.106048 },
    { lat: 48.2232562, lng: 17.106099 },
    { lat: 48.2232692, lng: 17.106136 },
    { lat: 48.2233652, lng: 17.106192 },
    { lat: 48.2234342, lng: 17.10627 },
    { lat: 48.2236142, lng: 17.106304 },
    { lat: 48.2236592, lng: 17.106318 },
    { lat: 48.2237092, lng: 17.10636 },
    { lat: 48.2238522, lng: 17.106307 },
    { lat: 48.2239292, lng: 17.106377 },
    { lat: 48.2239362, lng: 17.106454 },
    { lat: 48.2239532, lng: 17.106484 },
    { lat: 48.22406, lng: 17.106562 },
    { lat: 48.224143, lng: 17.106588 },
    { lat: 48.224162, lng: 17.106612 },
    { lat: 48.224235, lng: 17.106604 },
    { lat: 48.224268, lng: 17.106599 },
    { lat: 48.224275, lng: 17.106628 },
    { lat: 48.224269, lng: 17.106715 },
    { lat: 48.224301, lng: 17.106763 },
    { lat: 48.224341, lng: 17.106728 },
    { lat: 48.224391, lng: 17.106686 },
    { lat: 48.22448, lng: 17.106767 },
    { lat: 48.224598, lng: 17.106776 },
    { lat: 48.224652, lng: 17.106768 },
    { lat: 48.224688, lng: 17.106782 },
    { lat: 48.22471, lng: 17.106826 },
    { lat: 48.224785, lng: 17.106856 },
    { lat: 48.224817, lng: 17.106797 },
    { lat: 48.224835, lng: 17.106794 },
    { lat: 48.224846, lng: 17.106833 },
    { lat: 48.224852, lng: 17.106913 },
    { lat: 48.224853, lng: 17.106927 },
    { lat: 48.224992, lng: 17.107122 },
    { lat: 48.225012, lng: 17.107281 },
    { lat: 48.225036, lng: 17.107359 },
    { lat: 48.225054, lng: 17.107369 },
    { lat: 48.22509, lng: 17.107364 },
    { lat: 48.225113, lng: 17.107293 },
    { lat: 48.22518, lng: 17.107215 },
    { lat: 48.225181, lng: 17.107107 },
    { lat: 48.225198, lng: 17.107091 },
    { lat: 48.225315, lng: 17.107208 },
    { lat: 48.225316, lng: 17.107222 },
    { lat: 48.225317, lng: 17.107248 },
    { lat: 48.225294, lng: 17.107306 },
    { lat: 48.225287, lng: 17.107334 },
    { lat: 48.225336, lng: 17.107394 },
    { lat: 48.225402, lng: 17.107425 },
    { lat: 48.225418, lng: 17.107395 },
    { lat: 48.225403, lng: 17.107317 },
    { lat: 48.22542, lng: 17.1073 },
    { lat: 48.225438, lng: 17.107298 },
    { lat: 48.225477, lng: 17.107332 },
    { lat: 48.225571, lng: 17.10725 },
    { lat: 48.22563, lng: 17.107241 },
    { lat: 48.22567, lng: 17.107249 },
    { lat: 48.225738, lng: 17.107455 },
    { lat: 48.225739, lng: 17.107513 },
    { lat: 48.225727, lng: 17.107552 },
    { lat: 48.22576, lng: 17.107641 },
    { lat: 48.225814, lng: 17.10762 },
    { lat: 48.22586, lng: 17.107502 },
    { lat: 48.225895, lng: 17.107499 },
    { lat: 48.225947, lng: 17.107464 },
    { lat: 48.225955, lng: 17.107449 },
    { lat: 48.2259559, lng: 17.1073529 },
    { lat: 48.2259714, lng: 17.1073393 },
    { lat: 48.2259916, lng: 17.1073374 },
    { lat: 48.2260089, lng: 17.1073449 },
    { lat: 48.2260319, lng: 17.1074209 },
    { lat: 48.2260459, lng: 17.1074309 },
    { lat: 48.2260789, lng: 17.1074429 },
    { lat: 48.2261219, lng: 17.1074089 },
    { lat: 48.2261389, lng: 17.1073929 },
    { lat: 48.2261489, lng: 17.1074049 },
    { lat: 48.2261499, lng: 17.1074179 },
    { lat: 48.2261449, lng: 17.1074729 },
    { lat: 48.2261379, lng: 17.1075149 },
    { lat: 48.226106, lng: 17.10756 },
    { lat: 48.22613, lng: 17.107639 },
    { lat: 48.22615, lng: 17.107663 },
    { lat: 48.226169, lng: 17.107674 },
    { lat: 48.226195, lng: 17.107656 },
    { lat: 48.22623, lng: 17.107651 },
    { lat: 48.22625, lng: 17.107675 },
    { lat: 48.226395, lng: 17.107815 },
    { lat: 48.226426, lng: 17.107865 },
    { lat: 48.226549, lng: 17.107954 },
    { lat: 48.226573, lng: 17.108032 },
    { lat: 48.226632, lng: 17.108104 },
    { lat: 48.226668, lng: 17.108099 },
    { lat: 48.226683, lng: 17.108171 },
    { lat: 48.226686, lng: 17.108231 },
    { lat: 48.226707, lng: 17.108282 },
    { lat: 48.226746, lng: 17.108354 },
    { lat: 48.226793, lng: 17.108485 },
    { lat: 48.226831, lng: 17.108539 },
    { lat: 48.226906, lng: 17.108549 },
    { lat: 48.226926, lng: 17.108574 },
    { lat: 48.226964, lng: 17.108638 },
    { lat: 48.22703, lng: 17.108652 },
    { lat: 48.227032, lng: 17.108679 },
    { lat: 48.227034, lng: 17.108841 },
    { lat: 48.226996, lng: 17.108942 },
    { lat: 48.226959, lng: 17.108934 },
    { lat: 48.226908, lng: 17.108982 },
    { lat: 48.226902, lng: 17.109024 },
    { lat: 48.226912, lng: 17.109036 },
    { lat: 48.227001, lng: 17.109084 },
    { lat: 48.227041, lng: 17.10907 },
    { lat: 48.227074, lng: 17.10911 },
    { lat: 48.227124, lng: 17.109234 },
    { lat: 48.227167, lng: 17.109254 },
    { lat: 48.22726, lng: 17.10924 },
    { lat: 48.227317, lng: 17.109272 },
    { lat: 48.227341, lng: 17.109363 },
    { lat: 48.227334, lng: 17.109404 },
    { lat: 48.227312, lng: 17.109475 },
    { lat: 48.227314, lng: 17.109502 },
    { lat: 48.227418, lng: 17.109568 },
    { lat: 48.227439, lng: 17.109618 },
    { lat: 48.227486, lng: 17.109639 },
    { lat: 48.227537, lng: 17.10959 },
    { lat: 48.227583, lng: 17.109597 },
    { lat: 48.227622, lng: 17.109645 },
    { lat: 48.227726, lng: 17.10971 },
    { lat: 48.227746, lng: 17.109734 },
    { lat: 48.227739, lng: 17.109763 },
    { lat: 48.227611, lng: 17.10989 },
    { lat: 48.227595, lng: 17.109919 },
    { lat: 48.227575, lng: 17.109998 },
    { lat: 48.227657, lng: 17.110032 },
    { lat: 48.227691, lng: 17.11 },
    { lat: 48.227709, lng: 17.109997 },
    { lat: 48.22774, lng: 17.11006 },
    { lat: 48.227742, lng: 17.110222 },
    { lat: 48.227849, lng: 17.110192 },
    { lat: 48.22788, lng: 17.11012 },
    { lat: 48.227906, lng: 17.110054 },
    { lat: 48.227943, lng: 17.109988 },
    { lat: 48.2279559, lng: 17.1099705 },
  ],
  Lamač: [
    { lat: 48.1768821, lng: 17.0626407 },
    { lat: 48.1769202, lng: 17.0627646 },
    { lat: 48.1770211, lng: 17.0630668 },
    { lat: 48.1772411, lng: 17.0633459 },
    { lat: 48.1779285, lng: 17.0637799 },
    { lat: 48.1780193, lng: 17.0638372 },
    { lat: 48.1780683, lng: 17.0638937 },
    { lat: 48.1783641, lng: 17.064235 },
    { lat: 48.1784819, lng: 17.0643708 },
    { lat: 48.178536, lng: 17.064409 },
    { lat: 48.178726, lng: 17.064629 },
    { lat: 48.17889, lng: 17.064783 },
    { lat: 48.179108, lng: 17.064965 },
    { lat: 48.179153, lng: 17.065012 },
    { lat: 48.1793564, lng: 17.0652113 },
    { lat: 48.1795885, lng: 17.0653402 },
    { lat: 48.1796988, lng: 17.0654422 },
    { lat: 48.1797733, lng: 17.065553 },
    { lat: 48.1798343, lng: 17.0656604 },
    { lat: 48.1798765, lng: 17.065754 },
    { lat: 48.1799597, lng: 17.0658884 },
    { lat: 48.1800265, lng: 17.0660097 },
    { lat: 48.1800733, lng: 17.0660959 },
    { lat: 48.1801512, lng: 17.0661825 },
    { lat: 48.1801774, lng: 17.0662264 },
    { lat: 48.1802227, lng: 17.066297 },
    { lat: 48.1802879, lng: 17.0663658 },
    { lat: 48.1803477, lng: 17.0664478 },
    { lat: 48.1804231, lng: 17.0665315 },
    { lat: 48.1804966, lng: 17.0666063 },
    { lat: 48.1805682, lng: 17.0666624 },
    { lat: 48.1806512, lng: 17.0667462 },
    { lat: 48.1807458, lng: 17.0668104 },
    { lat: 48.1808366, lng: 17.0668795 },
    { lat: 48.1809485, lng: 17.0669552 },
    { lat: 48.1810277, lng: 17.0670045 },
    { lat: 48.181089, lng: 17.0670311 },
    { lat: 48.1812697, lng: 17.0671267 },
    { lat: 48.1818435, lng: 17.0673855 },
    { lat: 48.181852, lng: 17.067532 },
    { lat: 48.181894, lng: 17.067649 },
    { lat: 48.181924, lng: 17.067758 },
    { lat: 48.181983, lng: 17.067971 },
    { lat: 48.182013, lng: 17.068082 },
    { lat: 48.182037, lng: 17.068169 },
    { lat: 48.182105, lng: 17.068421 },
    { lat: 48.182203, lng: 17.068778 },
    { lat: 48.182307, lng: 17.06916 },
    { lat: 48.18236, lng: 17.069357 },
    { lat: 48.182352, lng: 17.069482 },
    { lat: 48.182349, lng: 17.069512 },
    { lat: 48.182611, lng: 17.069676 },
    { lat: 48.182684, lng: 17.06971 },
    { lat: 48.182768, lng: 17.069749 },
    { lat: 48.18283, lng: 17.069778 },
    { lat: 48.182843, lng: 17.069766 },
    { lat: 48.182871, lng: 17.069737 },
    { lat: 48.182886, lng: 17.069721 },
    { lat: 48.182915, lng: 17.069693 },
    { lat: 48.182958, lng: 17.069651 },
    { lat: 48.183045, lng: 17.069566 },
    { lat: 48.18307, lng: 17.069538 },
    { lat: 48.183114, lng: 17.069488 },
    { lat: 48.18323, lng: 17.069359 },
    { lat: 48.183278, lng: 17.069305 },
    { lat: 48.183321, lng: 17.069257 },
    { lat: 48.183401, lng: 17.069166 },
    { lat: 48.183466, lng: 17.069093 },
    { lat: 48.183495, lng: 17.069061 },
    { lat: 48.183512, lng: 17.069042 },
    { lat: 48.183538, lng: 17.069013 },
    { lat: 48.183571, lng: 17.068991 },
    { lat: 48.183605, lng: 17.068969 },
    { lat: 48.183648, lng: 17.06895 },
    { lat: 48.18372, lng: 17.06892 },
    { lat: 48.183757, lng: 17.068903 },
    { lat: 48.18381, lng: 17.06888 },
    { lat: 48.183833, lng: 17.06887 },
    { lat: 48.183864, lng: 17.068857 },
    { lat: 48.183888, lng: 17.068846 },
    { lat: 48.183963, lng: 17.068813 },
    { lat: 48.183971, lng: 17.068812 },
    { lat: 48.184021, lng: 17.069011 },
    { lat: 48.18403, lng: 17.069052 },
    { lat: 48.184054, lng: 17.069145 },
    { lat: 48.184085, lng: 17.069267 },
    { lat: 48.184212, lng: 17.06978 },
    { lat: 48.18422, lng: 17.0698 },
    { lat: 48.184237, lng: 17.069835 },
    { lat: 48.184444, lng: 17.070256 },
    { lat: 48.184544, lng: 17.070457 },
    { lat: 48.184836, lng: 17.070264 },
    { lat: 48.185115, lng: 17.070079 },
    { lat: 48.185121, lng: 17.070076 },
    { lat: 48.185203, lng: 17.070021 },
    { lat: 48.185305, lng: 17.069953 },
    { lat: 48.185352, lng: 17.069922 },
    { lat: 48.185407, lng: 17.069886 },
    { lat: 48.185446, lng: 17.069861 },
    { lat: 48.185462, lng: 17.06985 },
    { lat: 48.185531, lng: 17.069804 },
    { lat: 48.185597, lng: 17.069761 },
    { lat: 48.185696, lng: 17.069695 },
    { lat: 48.1857, lng: 17.069692 },
    { lat: 48.185793, lng: 17.069631 },
    { lat: 48.185897, lng: 17.069562 },
    { lat: 48.185989, lng: 17.069502 },
    { lat: 48.186155, lng: 17.069391 },
    { lat: 48.186263, lng: 17.069346 },
    { lat: 48.186355, lng: 17.069308 },
    { lat: 48.18642, lng: 17.069281 },
    { lat: 48.186484, lng: 17.069255 },
    { lat: 48.186594, lng: 17.069209 },
    { lat: 48.1866874, lng: 17.0691711 },
    { lat: 48.186657, lng: 17.069026 },
    { lat: 48.186701, lng: 17.068978 },
    { lat: 48.186757, lng: 17.068918 },
    { lat: 48.186854, lng: 17.068813 },
    { lat: 48.186891, lng: 17.068774 },
    { lat: 48.186967, lng: 17.06869 },
    { lat: 48.186981, lng: 17.068675 },
    { lat: 48.186987, lng: 17.068669 },
    { lat: 48.186995, lng: 17.06866 },
    { lat: 48.18708, lng: 17.068562 },
    { lat: 48.187087, lng: 17.06856 },
    { lat: 48.18709, lng: 17.068556 },
    { lat: 48.187208, lng: 17.068429 },
    { lat: 48.187216, lng: 17.068421 },
    { lat: 48.187297, lng: 17.068333 },
    { lat: 48.187377, lng: 17.068246 },
    { lat: 48.187458, lng: 17.068158 },
    { lat: 48.187486, lng: 17.068248 },
    { lat: 48.187585, lng: 17.068201 },
    { lat: 48.18763, lng: 17.068137 },
    { lat: 48.187664, lng: 17.068091 },
    { lat: 48.187719, lng: 17.068015 },
    { lat: 48.187779, lng: 17.067931 },
    { lat: 48.187807, lng: 17.067894 },
    { lat: 48.187832, lng: 17.06786 },
    { lat: 48.187834, lng: 17.067856 },
    { lat: 48.187852, lng: 17.067831 },
    { lat: 48.18788, lng: 17.067792 },
    { lat: 48.187923, lng: 17.067733 },
    { lat: 48.187972, lng: 17.067664 },
    { lat: 48.187999, lng: 17.067627 },
    { lat: 48.18801, lng: 17.067618 },
    { lat: 48.188052, lng: 17.06758 },
    { lat: 48.188085, lng: 17.067552 },
    { lat: 48.188134, lng: 17.067506 },
    { lat: 48.188265, lng: 17.067557 },
    { lat: 48.188384, lng: 17.067602 },
    { lat: 48.188553, lng: 17.067666 },
    { lat: 48.188693, lng: 17.06772 },
    { lat: 48.188749, lng: 17.067742 },
    { lat: 48.188839, lng: 17.067759 },
    { lat: 48.188924, lng: 17.067777 },
    { lat: 48.18898, lng: 17.0678 },
    { lat: 48.189156, lng: 17.067876 },
    { lat: 48.1893411, lng: 17.067954 },
    { lat: 48.189404, lng: 17.067769 },
    { lat: 48.189423, lng: 17.067715 },
    { lat: 48.18948, lng: 17.067556 },
    { lat: 48.189515, lng: 17.06754 },
    { lat: 48.189591, lng: 17.067505 },
    { lat: 48.1895996, lng: 17.0674563 },
    { lat: 48.1896121, lng: 17.0673814 },
    { lat: 48.189614, lng: 17.06737 },
    { lat: 48.189653, lng: 17.067145 },
    { lat: 48.189688, lng: 17.066946 },
    { lat: 48.189701, lng: 17.066875 },
    { lat: 48.18971, lng: 17.066825 },
    { lat: 48.189721, lng: 17.066816 },
    { lat: 48.189907, lng: 17.066681 },
    { lat: 48.190022, lng: 17.066567 },
    { lat: 48.190024, lng: 17.066566 },
    { lat: 48.190365, lng: 17.066282 },
    { lat: 48.190622, lng: 17.066069 },
    { lat: 48.190653, lng: 17.066092 },
    { lat: 48.190659, lng: 17.066097 },
    { lat: 48.190666, lng: 17.066102 },
    { lat: 48.190771, lng: 17.066183 },
    { lat: 48.191103, lng: 17.06653 },
    { lat: 48.191177, lng: 17.066607 },
    { lat: 48.19148, lng: 17.066829 },
    { lat: 48.191628, lng: 17.066019 },
    { lat: 48.191644, lng: 17.065933 },
    { lat: 48.191603, lng: 17.065764 },
    { lat: 48.191499, lng: 17.065334 },
    { lat: 48.191511, lng: 17.065138 },
    { lat: 48.191537, lng: 17.064701 },
    { lat: 48.191401, lng: 17.064185 },
    { lat: 48.191394, lng: 17.064161 },
    { lat: 48.191651, lng: 17.064153 },
    { lat: 48.191704, lng: 17.064152 },
    { lat: 48.191732, lng: 17.064151 },
    { lat: 48.192857, lng: 17.064116 },
    { lat: 48.19364, lng: 17.064091 },
    { lat: 48.194531, lng: 17.064063 },
    { lat: 48.195859, lng: 17.064021 },
    { lat: 48.196765, lng: 17.063992 },
    { lat: 48.19766, lng: 17.063962 },
    { lat: 48.19802, lng: 17.0645009 },
    { lat: 48.198177, lng: 17.064736 },
    { lat: 48.198571, lng: 17.065324 },
    { lat: 48.1993, lng: 17.065973 },
    { lat: 48.199857, lng: 17.06604 },
    { lat: 48.200029, lng: 17.066005 },
    { lat: 48.200527, lng: 17.065905 },
    { lat: 48.201374, lng: 17.065813 },
    { lat: 48.202507, lng: 17.066681 },
    { lat: 48.20327, lng: 17.065985 },
    { lat: 48.204321, lng: 17.06503 },
    { lat: 48.204614, lng: 17.064854 },
    { lat: 48.204968, lng: 17.064643 },
    { lat: 48.2063394, lng: 17.0644045 },
    { lat: 48.208861, lng: 17.06473 },
    { lat: 48.209774, lng: 17.065259 },
    { lat: 48.210429, lng: 17.0656228 },
    { lat: 48.210788, lng: 17.0657746 },
    { lat: 48.211074, lng: 17.06575 },
    { lat: 48.21152, lng: 17.065669 },
    { lat: 48.211651, lng: 17.065694 },
    { lat: 48.211721, lng: 17.065708 },
    { lat: 48.211777, lng: 17.065719 },
    { lat: 48.211945, lng: 17.065835 },
    { lat: 48.212427, lng: 17.066165 },
    { lat: 48.212515, lng: 17.066229 },
    { lat: 48.213015, lng: 17.066574 },
    { lat: 48.213152, lng: 17.066668 },
    { lat: 48.213588, lng: 17.066906 },
    { lat: 48.21429, lng: 17.067673 },
    { lat: 48.214318, lng: 17.067702 },
    { lat: 48.2145454, lng: 17.0660544 },
    { lat: 48.214822, lng: 17.0653706 },
    { lat: 48.2147247, lng: 17.0649536 },
    { lat: 48.2147216, lng: 17.0647408 },
    { lat: 48.2150047, lng: 17.0635064 },
    { lat: 48.2148341, lng: 17.062928 },
    { lat: 48.2143693, lng: 17.0621742 },
    { lat: 48.2136932, lng: 17.0617573 },
    { lat: 48.2139389, lng: 17.0610679 },
    { lat: 48.2143514, lng: 17.0602907 },
    { lat: 48.2144574, lng: 17.0592261 },
    { lat: 48.2144236, lng: 17.0588281 },
    { lat: 48.2142902, lng: 17.0582508 },
    { lat: 48.2141237, lng: 17.0580278 },
    { lat: 48.2138397, lng: 17.0571766 },
    { lat: 48.2133607, lng: 17.0566007 },
    { lat: 48.2128537, lng: 17.0563079 },
    { lat: 48.2122182, lng: 17.0563331 },
    { lat: 48.2116804, lng: 17.0568634 },
    { lat: 48.2106449, lng: 17.0576865 },
    { lat: 48.2103616, lng: 17.0578399 },
    { lat: 48.2093529, lng: 17.0587393 },
    { lat: 48.2092338, lng: 17.0587508 },
    { lat: 48.2086815, lng: 17.0586059 },
    { lat: 48.2065501, lng: 17.0586189 },
    { lat: 48.2058002, lng: 17.0581432 },
    { lat: 48.2049677, lng: 17.0582972 },
    { lat: 48.2048627, lng: 17.0583418 },
    { lat: 48.2044734, lng: 17.0585073 },
    { lat: 48.204329, lng: 17.0584939 },
    { lat: 48.2038502, lng: 17.0580842 },
    { lat: 48.2033077, lng: 17.0573194 },
    { lat: 48.2025259, lng: 17.0571235 },
    { lat: 48.202231, lng: 17.0572057 },
    { lat: 48.2019088, lng: 17.0575362 },
    { lat: 48.2016354, lng: 17.0577175 },
    { lat: 48.2013829, lng: 17.057512 },
    { lat: 48.2007455, lng: 17.0573107 },
    { lat: 48.2004426, lng: 17.0571118 },
    { lat: 48.199916, lng: 17.0569946 },
    { lat: 48.1998407, lng: 17.0568354 },
    { lat: 48.1998615, lng: 17.0561816 },
    { lat: 48.1997535, lng: 17.0557578 },
    { lat: 48.199386, lng: 17.0553733 },
    { lat: 48.1989382, lng: 17.0551544 },
    { lat: 48.1987506, lng: 17.0549203 },
    { lat: 48.1986813, lng: 17.0545023 },
    { lat: 48.1983881, lng: 17.0538493 },
    { lat: 48.1981348, lng: 17.053548 },
    { lat: 48.1979748, lng: 17.0521139 },
    { lat: 48.1979962, lng: 17.0520455 },
    { lat: 48.198301, lng: 17.0518282 },
    { lat: 48.1983716, lng: 17.0516864 },
    { lat: 48.1984337, lng: 17.0511226 },
    { lat: 48.1985565, lng: 17.0508707 },
    { lat: 48.1992807, lng: 17.0499394 },
    { lat: 48.1993847, lng: 17.0498438 },
    { lat: 48.1999518, lng: 17.0496736 },
    { lat: 48.2005424, lng: 17.0489259 },
    { lat: 48.2009437, lng: 17.0487445 },
    { lat: 48.2011901, lng: 17.0483342 },
    { lat: 48.2012503, lng: 17.0480891 },
    { lat: 48.2013938, lng: 17.0479878 },
    { lat: 48.201536, lng: 17.0477064 },
    { lat: 48.2017506, lng: 17.0474887 },
    { lat: 48.2018381, lng: 17.0471987 },
    { lat: 48.2021469, lng: 17.045283 },
    { lat: 48.2021336, lng: 17.0450095 },
    { lat: 48.2021741, lng: 17.0447911 },
    { lat: 48.2025151, lng: 17.0434822 },
    { lat: 48.2025551, lng: 17.0431559 },
    { lat: 48.2027401, lng: 17.0426238 },
    { lat: 48.2029947, lng: 17.0421977 },
    { lat: 48.2037516, lng: 17.0415839 },
    { lat: 48.2044863, lng: 17.0412221 },
    { lat: 48.2045928, lng: 17.0413252 },
    { lat: 48.2046838, lng: 17.0412673 },
    { lat: 48.2047634, lng: 17.0411772 },
    { lat: 48.204853, lng: 17.0409159 },
    { lat: 48.2049954, lng: 17.0407506 },
    { lat: 48.2058075, lng: 17.0400015 },
    { lat: 48.2077295, lng: 17.0388096 },
    { lat: 48.2076934, lng: 17.0381332 },
    { lat: 48.20764, lng: 17.0380787 },
    { lat: 48.207737, lng: 17.0377149 },
    { lat: 48.2086412, lng: 17.0363454 },
    { lat: 48.2118337, lng: 17.0318751 },
    { lat: 48.2129852, lng: 17.0308508 },
    { lat: 48.2182979, lng: 17.0253618 },
    { lat: 48.2186661, lng: 17.0250779 },
    { lat: 48.2195379, lng: 17.0241115 },
    { lat: 48.2196971, lng: 17.0237819 },
    { lat: 48.2200203, lng: 17.0215382 },
    { lat: 48.2200275, lng: 17.0213461 },
    { lat: 48.2197136, lng: 17.0186463 },
    { lat: 48.2197734, lng: 17.0181503 },
    { lat: 48.2199816, lng: 17.0174291 },
    { lat: 48.220389, lng: 17.0158733 },
    { lat: 48.2191717, lng: 17.0140235 },
    { lat: 48.2178001, lng: 17.0124458 },
    { lat: 48.2167238, lng: 17.0139048 },
    { lat: 48.2166036, lng: 17.0136207 },
    { lat: 48.2165681, lng: 17.0137445 },
    { lat: 48.2142253, lng: 17.0158493 },
    { lat: 48.2139635, lng: 17.01592 },
    { lat: 48.2123074, lng: 17.0160272 },
    { lat: 48.2122268, lng: 17.0160892 },
    { lat: 48.2102032, lng: 17.0192911 },
    { lat: 48.207205, lng: 17.0243307 },
    { lat: 48.2052574, lng: 17.0278881 },
    { lat: 48.2051967, lng: 17.0278896 },
    { lat: 48.2039023, lng: 17.0303753 },
    { lat: 48.2038722, lng: 17.0306452 },
    { lat: 48.2028724, lng: 17.0324806 },
    { lat: 48.2028184, lng: 17.0331134 },
    { lat: 48.2025846, lng: 17.0347829 },
    { lat: 48.2019911, lng: 17.0368103 },
    { lat: 48.2016757, lng: 17.0377342 },
    { lat: 48.201242, lng: 17.038611 },
    { lat: 48.2010823, lng: 17.0391356 },
    { lat: 48.2000605, lng: 17.0409985 },
    { lat: 48.1995953, lng: 17.0416647 },
    { lat: 48.1995545, lng: 17.0416285 },
    { lat: 48.1992213, lng: 17.0419129 },
    { lat: 48.1980198, lng: 17.0423382 },
    { lat: 48.1976263, lng: 17.0423184 },
    { lat: 48.1974254, lng: 17.042403 },
    { lat: 48.1974205, lng: 17.0425811 },
    { lat: 48.1963449, lng: 17.0429639 },
    { lat: 48.1902586, lng: 17.0445629 },
    { lat: 48.1884104, lng: 17.0451826 },
    { lat: 48.1873926, lng: 17.0457154 },
    { lat: 48.1863063, lng: 17.0466038 },
    { lat: 48.1855409, lng: 17.047094 },
    { lat: 48.1851701, lng: 17.047423 },
    { lat: 48.1848048, lng: 17.0473351 },
    { lat: 48.1846002, lng: 17.047434 },
    { lat: 48.1838224, lng: 17.0485684 },
    { lat: 48.1836641, lng: 17.0488822 },
    { lat: 48.1825129, lng: 17.0505225 },
    { lat: 48.1816452, lng: 17.0520972 },
    { lat: 48.1812046, lng: 17.0531081 },
    { lat: 48.1809648, lng: 17.0533019 },
    { lat: 48.1804488, lng: 17.0535064 },
    { lat: 48.1802208, lng: 17.053743 },
    { lat: 48.1799224, lng: 17.0547991 },
    { lat: 48.180089, lng: 17.0553844 },
    { lat: 48.1801303, lng: 17.0557077 },
    { lat: 48.1792136, lng: 17.0585848 },
    { lat: 48.1790632, lng: 17.0589748 },
    { lat: 48.1789121, lng: 17.0592996 },
    { lat: 48.1779821, lng: 17.0609554 },
    { lat: 48.1775172, lng: 17.0616625 },
    { lat: 48.177069, lng: 17.0622945 },
    { lat: 48.1768304, lng: 17.0623925 },
    { lat: 48.1768821, lng: 17.0626407 },
  ],
  Devín: [
    { lat: 48.1747936, lng: 17.038522 },
    { lat: 48.1748326, lng: 17.0380667 },
    { lat: 48.1747525, lng: 17.0376574 },
    { lat: 48.1745016, lng: 17.0374582 },
    { lat: 48.1745169, lng: 17.0367768 },
    { lat: 48.1744035, lng: 17.036437 },
    { lat: 48.174761, lng: 17.0351566 },
    { lat: 48.174654, lng: 17.0350817 },
    { lat: 48.1745213, lng: 17.0347412 },
    { lat: 48.1743067, lng: 17.0345942 },
    { lat: 48.1742515, lng: 17.0342522 },
    { lat: 48.1742503, lng: 17.0338063 },
    { lat: 48.1744862, lng: 17.0327623 },
    { lat: 48.1746428, lng: 17.0326048 },
    { lat: 48.1748203, lng: 17.0326063 },
    { lat: 48.174948, lng: 17.032739 },
    { lat: 48.1752368, lng: 17.0327819 },
    { lat: 48.1753964, lng: 17.0309706 },
    { lat: 48.1754894, lng: 17.0306286 },
    { lat: 48.1754905, lng: 17.0303335 },
    { lat: 48.1756865, lng: 17.0297614 },
    { lat: 48.1754622, lng: 17.0299679 },
    { lat: 48.174874, lng: 17.0301889 },
    { lat: 48.1742522, lng: 17.030156 },
    { lat: 48.1739806, lng: 17.0299997 },
    { lat: 48.1739708, lng: 17.0302377 },
    { lat: 48.173488, lng: 17.0306766 },
    { lat: 48.1732436, lng: 17.0307724 },
    { lat: 48.1730314, lng: 17.0307281 },
    { lat: 48.1729551, lng: 17.0304194 },
    { lat: 48.1730104, lng: 17.0297918 },
    { lat: 48.1733596, lng: 17.0294317 },
    { lat: 48.1734699, lng: 17.0291606 },
    { lat: 48.1736644, lng: 17.0284026 },
    { lat: 48.1744101, lng: 17.0282371 },
    { lat: 48.1749529, lng: 17.0281935 },
    { lat: 48.1751738, lng: 17.0282341 },
    { lat: 48.175206, lng: 17.0283067 },
    { lat: 48.1755861, lng: 17.0276714 },
    { lat: 48.1768109, lng: 17.0262665 },
    { lat: 48.1771246, lng: 17.0256991 },
    { lat: 48.1769044, lng: 17.0256025 },
    { lat: 48.176624, lng: 17.0252047 },
    { lat: 48.1764828, lng: 17.0251899 },
    { lat: 48.1761462, lng: 17.0243521 },
    { lat: 48.1757513, lng: 17.0236008 },
    { lat: 48.1750353, lng: 17.0229501 },
    { lat: 48.1746005, lng: 17.0226723 },
    { lat: 48.1744802, lng: 17.022417 },
    { lat: 48.173724, lng: 17.0220706 },
    { lat: 48.1736216, lng: 17.0218618 },
    { lat: 48.1734471, lng: 17.0217895 },
    { lat: 48.173285, lng: 17.0215838 },
    { lat: 48.1730626, lng: 17.0211584 },
    { lat: 48.1729071, lng: 17.0207387 },
    { lat: 48.172951, lng: 17.0206367 },
    { lat: 48.1735714, lng: 17.0204881 },
    { lat: 48.1741025, lng: 17.020521 },
    { lat: 48.1745065, lng: 17.0202148 },
    { lat: 48.1748292, lng: 17.0198138 },
    { lat: 48.1751262, lng: 17.0197597 },
    { lat: 48.1752527, lng: 17.0196773 },
    { lat: 48.1753176, lng: 17.019439 },
    { lat: 48.1756206, lng: 17.0190859 },
    { lat: 48.1757398, lng: 17.0190043 },
    { lat: 48.1759577, lng: 17.0187178 },
    { lat: 48.1761917, lng: 17.0184917 },
    { lat: 48.17649, lng: 17.0184382 },
    { lat: 48.1767381, lng: 17.0182453 },
    { lat: 48.1770083, lng: 17.0179887 },
    { lat: 48.1775824, lng: 17.0177459 },
    { lat: 48.1782394, lng: 17.016846 },
    { lat: 48.1784551, lng: 17.0166465 },
    { lat: 48.178721, lng: 17.0165668 },
    { lat: 48.1790413, lng: 17.0163275 },
    { lat: 48.1796707, lng: 17.0152257 },
    { lat: 48.1795815, lng: 17.0145341 },
    { lat: 48.1796888, lng: 17.0143866 },
    { lat: 48.1800926, lng: 17.014062 },
    { lat: 48.1804488, lng: 17.0138671 },
    { lat: 48.1812487, lng: 17.0126626 },
    { lat: 48.1824436, lng: 17.0115001 },
    { lat: 48.1831763, lng: 17.0112286 },
    { lat: 48.1837788, lng: 17.0106233 },
    { lat: 48.1845458, lng: 17.0123129 },
    { lat: 48.1845307, lng: 17.0128999 },
    { lat: 48.1844284, lng: 17.0130717 },
    { lat: 48.1844003, lng: 17.013763 },
    { lat: 48.1844625, lng: 17.0145891 },
    { lat: 48.1853883, lng: 17.0135876 },
    { lat: 48.1856208, lng: 17.0134531 },
    { lat: 48.1855581, lng: 17.0115364 },
    { lat: 48.1857881, lng: 17.0110159 },
    { lat: 48.1856813, lng: 17.009924 },
    { lat: 48.1856, lng: 17.0094434 },
    { lat: 48.1855387, lng: 17.0090808 },
    { lat: 48.185879, lng: 17.0082434 },
    { lat: 48.1859328, lng: 17.0078102 },
    { lat: 48.1870327, lng: 17.0060551 },
    { lat: 48.1871948, lng: 17.0055192 },
    { lat: 48.1874494, lng: 17.0043731 },
    { lat: 48.1875965, lng: 17.0039804 },
    { lat: 48.1877536, lng: 17.003685 },
    { lat: 48.1882601, lng: 17.0031135 },
    { lat: 48.1884893, lng: 17.0026161 },
    { lat: 48.1887765, lng: 17.0017001 },
    { lat: 48.188932, lng: 17.0013953 },
    { lat: 48.1891976, lng: 17.0011672 },
    { lat: 48.1898001, lng: 17.0008093 },
    { lat: 48.1913834, lng: 17.0001171 },
    { lat: 48.1917555, lng: 16.9997783 },
    { lat: 48.1922896, lng: 16.9991493 },
    { lat: 48.1923338, lng: 16.9989863 },
    { lat: 48.1928601, lng: 16.9983398 },
    { lat: 48.1924745, lng: 16.9976607 },
    { lat: 48.1916319, lng: 16.9965167 },
    { lat: 48.1913323, lng: 16.9962207 },
    { lat: 48.1910168, lng: 16.9963504 },
    { lat: 48.1904554, lng: 16.9967307 },
    { lat: 48.189054, lng: 16.9948753 },
    { lat: 48.1886164, lng: 16.9939014 },
    { lat: 48.1885846, lng: 16.9937208 },
    { lat: 48.1885345, lng: 16.9934824 },
    { lat: 48.1887899, lng: 16.9932362 },
    { lat: 48.1893131, lng: 16.9922852 },
    { lat: 48.1895236, lng: 16.9920743 },
    { lat: 48.1899201, lng: 16.9914342 },
    { lat: 48.1900321, lng: 16.9912534 },
    { lat: 48.1905104, lng: 16.990798 },
    { lat: 48.1909484, lng: 16.9906423 },
    { lat: 48.191104, lng: 16.9904916 },
    { lat: 48.1915856, lng: 16.98964 },
    { lat: 48.1917405, lng: 16.9892461 },
    { lat: 48.1919581, lng: 16.9883024 },
    { lat: 48.1921636, lng: 16.9876623 },
    { lat: 48.1923888, lng: 16.986535 },
    { lat: 48.1923489, lng: 16.9862337 },
    { lat: 48.1923901, lng: 16.9861756 },
    { lat: 48.1923926, lng: 16.9858331 },
    { lat: 48.1923168, lng: 16.9853893 },
    { lat: 48.192513, lng: 16.9847276 },
    { lat: 48.1929641, lng: 16.9835173 },
    { lat: 48.1930732, lng: 16.9830663 },
    { lat: 48.193076, lng: 16.9828427 },
    { lat: 48.1925053, lng: 16.9821557 },
    { lat: 48.1916664, lng: 16.9821158 },
    { lat: 48.1913197, lng: 16.9817934 },
    { lat: 48.1902081, lng: 16.9819301 },
    { lat: 48.1896191, lng: 16.9816965 },
    { lat: 48.1882659, lng: 16.9814132 },
    { lat: 48.1873743, lng: 16.9803143 },
    { lat: 48.1872041, lng: 16.9790756 },
    { lat: 48.1870969, lng: 16.9765322 },
    { lat: 48.1868337, lng: 16.9764936 },
    { lat: 48.1864518, lng: 16.9764837 },
    { lat: 48.1856939, lng: 16.9765381 },
    { lat: 48.18512, lng: 16.976646 },
    { lat: 48.184644, lng: 16.976781 },
    { lat: 48.1841496, lng: 16.9769589 },
    { lat: 48.1837076, lng: 16.9771537 },
    { lat: 48.1833956, lng: 16.9772671 },
    { lat: 48.1828574, lng: 16.9773855 },
    { lat: 48.1823054, lng: 16.9774454 },
    { lat: 48.1815127, lng: 16.977399 },
    { lat: 48.1810632, lng: 16.9772653 },
    { lat: 48.180395, lng: 16.9769889 },
    { lat: 48.1794976, lng: 16.976441 },
    { lat: 48.178815, lng: 16.976116 },
    { lat: 48.178211, lng: 16.975878 },
    { lat: 48.177573, lng: 16.975683 },
    { lat: 48.177181, lng: 16.975602 },
    { lat: 48.176601, lng: 16.97549 },
    { lat: 48.175899, lng: 16.975441 },
    { lat: 48.17532, lng: 16.975459 },
    { lat: 48.1750349, lng: 16.9754825 },
    { lat: 48.1749228, lng: 16.9754918 },
    { lat: 48.174527, lng: 16.975601 },
    { lat: 48.1729659, lng: 16.976016 },
    { lat: 48.1727521, lng: 16.9760721 },
    { lat: 48.1722736, lng: 16.9761998 },
    { lat: 48.1721643, lng: 16.9762313 },
    { lat: 48.1714234, lng: 16.9775891 },
    { lat: 48.1706864, lng: 16.9789785 },
    { lat: 48.1700281, lng: 16.9803947 },
    { lat: 48.1693466, lng: 16.9818297 },
    { lat: 48.1687374, lng: 16.983018 },
    { lat: 48.1683287, lng: 16.983726 },
    { lat: 48.1680747, lng: 16.9841498 },
    { lat: 48.1673913, lng: 16.9852334 },
    { lat: 48.1661963, lng: 16.9868696 },
    { lat: 48.1643966, lng: 16.989364 },
    { lat: 48.1633933, lng: 16.9908678 },
    { lat: 48.1627221, lng: 16.9919443 },
    { lat: 48.1617524, lng: 16.9935912 },
    { lat: 48.1608256, lng: 16.9952488 },
    { lat: 48.1600992, lng: 16.9966596 },
    { lat: 48.1590978, lng: 16.9987928 },
    { lat: 48.1587072, lng: 16.9996744 },
    { lat: 48.1572579, lng: 17.0032847 },
    { lat: 48.1555688, lng: 17.0078069 },
    { lat: 48.1547207, lng: 17.0098829 },
    { lat: 48.1541481, lng: 17.0110899 },
    { lat: 48.153325, lng: 17.0125866 },
    { lat: 48.1523229, lng: 17.0141208 },
    { lat: 48.1512922, lng: 17.0153868 },
    { lat: 48.1499966, lng: 17.0168245 },
    { lat: 48.1489336, lng: 17.0178866 },
    { lat: 48.1484633, lng: 17.0183623 },
    { lat: 48.1492239, lng: 17.0198172 },
    { lat: 48.1498217, lng: 17.0193558 },
    { lat: 48.1509573, lng: 17.018417 },
    { lat: 48.1512149, lng: 17.0179713 },
    { lat: 48.1513665, lng: 17.0174795 },
    { lat: 48.1515666, lng: 17.0171852 },
    { lat: 48.1530876, lng: 17.0152413 },
    { lat: 48.1538856, lng: 17.0140102 },
    { lat: 48.1541727, lng: 17.0137669 },
    { lat: 48.1542735, lng: 17.0138654 },
    { lat: 48.1543051, lng: 17.0139989 },
    { lat: 48.1544137, lng: 17.0148557 },
    { lat: 48.1544844, lng: 17.0149468 },
    { lat: 48.1548977, lng: 17.0150412 },
    { lat: 48.1552927, lng: 17.015348 },
    { lat: 48.1554179, lng: 17.0154919 },
    { lat: 48.1556417, lng: 17.0160344 },
    { lat: 48.1557241, lng: 17.0164302 },
    { lat: 48.1557129, lng: 17.0166128 },
    { lat: 48.1555331, lng: 17.0170533 },
    { lat: 48.154897, lng: 17.0180289 },
    { lat: 48.1546052, lng: 17.0183203 },
    { lat: 48.1544302, lng: 17.0186833 },
    { lat: 48.1541589, lng: 17.019077 },
    { lat: 48.1536427, lng: 17.0199708 },
    { lat: 48.1530313, lng: 17.0212366 },
    { lat: 48.1522762, lng: 17.0225006 },
    { lat: 48.1513882, lng: 17.0241371 },
    { lat: 48.1501864, lng: 17.0272918 },
    { lat: 48.1498877, lng: 17.0283567 },
    { lat: 48.1497607, lng: 17.0293722 },
    { lat: 48.1498457, lng: 17.0301657 },
    { lat: 48.1500095, lng: 17.030676 },
    { lat: 48.1506921, lng: 17.031999 },
    { lat: 48.1510351, lng: 17.0328297 },
    { lat: 48.1511015, lng: 17.0331388 },
    { lat: 48.1510977, lng: 17.0335778 },
    { lat: 48.1509506, lng: 17.0340044 },
    { lat: 48.150635, lng: 17.0353184 },
    { lat: 48.1508818, lng: 17.035344 },
    { lat: 48.1510341, lng: 17.0355522 },
    { lat: 48.1512667, lng: 17.0355672 },
    { lat: 48.1521678, lng: 17.0361665 },
    { lat: 48.1529871, lng: 17.0365977 },
    { lat: 48.152817, lng: 17.0380913 },
    { lat: 48.1531941, lng: 17.038069 },
    { lat: 48.1532362, lng: 17.0382059 },
    { lat: 48.1536898, lng: 17.0385099 },
    { lat: 48.153726, lng: 17.0390387 },
    { lat: 48.1538586, lng: 17.0389622 },
    { lat: 48.1539375, lng: 17.0391398 },
    { lat: 48.1538671, lng: 17.039301 },
    { lat: 48.1543743, lng: 17.0395773 },
    { lat: 48.1542867, lng: 17.040083 },
    { lat: 48.1543232, lng: 17.0406294 },
    { lat: 48.1543689, lng: 17.0406886 },
    { lat: 48.1547141, lng: 17.0406153 },
    { lat: 48.154714, lng: 17.0405623 },
    { lat: 48.1549955, lng: 17.0405479 },
    { lat: 48.1557051, lng: 17.0406953 },
    { lat: 48.1556095, lng: 17.0419418 },
    { lat: 48.1560674, lng: 17.0419928 },
    { lat: 48.1566503, lng: 17.0423546 },
    { lat: 48.1568426, lng: 17.0425379 },
    { lat: 48.1570341, lng: 17.0425957 },
    { lat: 48.1576856, lng: 17.042556 },
    { lat: 48.1578877, lng: 17.0427176 },
    { lat: 48.1579258, lng: 17.0425337 },
    { lat: 48.1589384, lng: 17.041576 },
    { lat: 48.1592281, lng: 17.042093 },
    { lat: 48.1586557, lng: 17.0426805 },
    { lat: 48.1584824, lng: 17.0430355 },
    { lat: 48.1581805, lng: 17.0443549 },
    { lat: 48.1587745, lng: 17.0443495 },
    { lat: 48.1589966, lng: 17.0435988 },
    { lat: 48.1592421, lng: 17.0436364 },
    { lat: 48.1593405, lng: 17.0435378 },
    { lat: 48.1596925, lng: 17.0437785 },
    { lat: 48.1597713, lng: 17.0443671 },
    { lat: 48.1600794, lng: 17.0442145 },
    { lat: 48.1602504, lng: 17.0446485 },
    { lat: 48.1603272, lng: 17.0446141 },
    { lat: 48.1606241, lng: 17.0451742 },
    { lat: 48.1611447, lng: 17.0453807 },
    { lat: 48.161247, lng: 17.0452659 },
    { lat: 48.1615869, lng: 17.0446623 },
    { lat: 48.1616542, lng: 17.0443908 },
    { lat: 48.1622658, lng: 17.0433371 },
    { lat: 48.162309, lng: 17.0432299 },
    { lat: 48.1624712, lng: 17.0423734 },
    { lat: 48.1625841, lng: 17.0414975 },
    { lat: 48.1626715, lng: 17.0414434 },
    { lat: 48.1627261, lng: 17.0414096 },
    { lat: 48.1627504, lng: 17.0414416 },
    { lat: 48.1629035, lng: 17.0416426 },
    { lat: 48.1628485, lng: 17.0421738 },
    { lat: 48.1631574, lng: 17.041505 },
    { lat: 48.1632958, lng: 17.0413303 },
    { lat: 48.1632674, lng: 17.0412957 },
    { lat: 48.1638141, lng: 17.0404334 },
    { lat: 48.1640488, lng: 17.0407235 },
    { lat: 48.1641583, lng: 17.0405082 },
    { lat: 48.1652148, lng: 17.0407388 },
    { lat: 48.1649701, lng: 17.0411596 },
    { lat: 48.1650545, lng: 17.0411558 },
    { lat: 48.1652218, lng: 17.0413533 },
    { lat: 48.1643818, lng: 17.042916 },
    { lat: 48.16458, lng: 17.0431862 },
    { lat: 48.1653341, lng: 17.0427619 },
    { lat: 48.1653589, lng: 17.0428618 },
    { lat: 48.1656792, lng: 17.0428019 },
    { lat: 48.1659881, lng: 17.0429423 },
    { lat: 48.165953, lng: 17.0432212 },
    { lat: 48.1661068, lng: 17.0432721 },
    { lat: 48.1660918, lng: 17.0433851 },
    { lat: 48.1662504, lng: 17.0434402 },
    { lat: 48.1662348, lng: 17.0435573 },
    { lat: 48.1665953, lng: 17.0438214 },
    { lat: 48.1665422, lng: 17.0443017 },
    { lat: 48.1666447, lng: 17.0444135 },
    { lat: 48.1667276, lng: 17.0444026 },
    { lat: 48.1667346, lng: 17.0445045 },
    { lat: 48.1668144, lng: 17.0445642 },
    { lat: 48.1674351, lng: 17.042852 },
    { lat: 48.1680794, lng: 17.0413666 },
    { lat: 48.1686163, lng: 17.0415023 },
    { lat: 48.1690464, lng: 17.0419915 },
    { lat: 48.1691153, lng: 17.0422376 },
    { lat: 48.168733, lng: 17.0434373 },
    { lat: 48.1674489, lng: 17.0461759 },
    { lat: 48.1679679, lng: 17.0462622 },
    { lat: 48.1681946, lng: 17.0460906 },
    { lat: 48.1693719, lng: 17.0447188 },
    { lat: 48.1696345, lng: 17.0444103 },
    { lat: 48.1699535, lng: 17.043854 },
    { lat: 48.1706917, lng: 17.0421219 },
    { lat: 48.1715015, lng: 17.040563 },
    { lat: 48.1718228, lng: 17.0397949 },
    { lat: 48.1721405, lng: 17.0399456 },
    { lat: 48.1720272, lng: 17.0403877 },
    { lat: 48.172174, lng: 17.0403518 },
    { lat: 48.1722751, lng: 17.0405258 },
    { lat: 48.1724308, lng: 17.0405855 },
    { lat: 48.1723678, lng: 17.0407902 },
    { lat: 48.1724116, lng: 17.0408833 },
    { lat: 48.1723827, lng: 17.0413048 },
    { lat: 48.1722739, lng: 17.0417607 },
    { lat: 48.1725403, lng: 17.041823 },
    { lat: 48.1724352, lng: 17.0427829 },
    { lat: 48.1726744, lng: 17.0428939 },
    { lat: 48.1733114, lng: 17.0426241 },
    { lat: 48.1734339, lng: 17.0424493 },
    { lat: 48.1736014, lng: 17.0424194 },
    { lat: 48.1739666, lng: 17.0420258 },
    { lat: 48.1742774, lng: 17.0411032 },
    { lat: 48.1745539, lng: 17.0407313 },
    { lat: 48.1746213, lng: 17.0401836 },
    { lat: 48.1745951, lng: 17.039801 },
    { lat: 48.1747765, lng: 17.0393796 },
    { lat: 48.1748232, lng: 17.0390202 },
    { lat: 48.1747936, lng: 17.038522 },
  ],
  KarlovaVes: [
    { lat: 48.1581805, lng: 17.0443549 },
    { lat: 48.1584824, lng: 17.0430355 },
    { lat: 48.1586557, lng: 17.0426805 },
    { lat: 48.1592281, lng: 17.042093 },
    { lat: 48.1589384, lng: 17.041576 },
    { lat: 48.1579258, lng: 17.0425337 },
    { lat: 48.1578877, lng: 17.0427176 },
    { lat: 48.1576856, lng: 17.042556 },
    { lat: 48.1570341, lng: 17.0425957 },
    { lat: 48.1568426, lng: 17.0425379 },
    { lat: 48.1566503, lng: 17.0423546 },
    { lat: 48.1560674, lng: 17.0419928 },
    { lat: 48.1556095, lng: 17.0419418 },
    { lat: 48.1557051, lng: 17.0406953 },
    { lat: 48.1549955, lng: 17.0405479 },
    { lat: 48.154714, lng: 17.0405623 },
    { lat: 48.1547141, lng: 17.0406153 },
    { lat: 48.1543689, lng: 17.0406886 },
    { lat: 48.1543232, lng: 17.0406294 },
    { lat: 48.1542867, lng: 17.040083 },
    { lat: 48.1543743, lng: 17.0395773 },
    { lat: 48.1538671, lng: 17.039301 },
    { lat: 48.1539375, lng: 17.0391398 },
    { lat: 48.1538586, lng: 17.0389622 },
    { lat: 48.153726, lng: 17.0390387 },
    { lat: 48.1536898, lng: 17.0385099 },
    { lat: 48.1532362, lng: 17.0382059 },
    { lat: 48.1531941, lng: 17.038069 },
    { lat: 48.152817, lng: 17.0380913 },
    { lat: 48.1529871, lng: 17.0365977 },
    { lat: 48.1521678, lng: 17.0361665 },
    { lat: 48.1512667, lng: 17.0355672 },
    { lat: 48.1510341, lng: 17.0355522 },
    { lat: 48.1508818, lng: 17.035344 },
    { lat: 48.150635, lng: 17.0353184 },
    { lat: 48.1509506, lng: 17.0340044 },
    { lat: 48.1510977, lng: 17.0335778 },
    { lat: 48.1511015, lng: 17.0331388 },
    { lat: 48.1510351, lng: 17.0328297 },
    { lat: 48.1506921, lng: 17.031999 },
    { lat: 48.1500095, lng: 17.030676 },
    { lat: 48.1498457, lng: 17.0301657 },
    { lat: 48.1497607, lng: 17.0293722 },
    { lat: 48.1498877, lng: 17.0283567 },
    { lat: 48.1501864, lng: 17.0272918 },
    { lat: 48.1513882, lng: 17.0241371 },
    { lat: 48.1522762, lng: 17.0225006 },
    { lat: 48.1530313, lng: 17.0212366 },
    { lat: 48.1536427, lng: 17.0199708 },
    { lat: 48.1541589, lng: 17.019077 },
    { lat: 48.1544302, lng: 17.0186833 },
    { lat: 48.1546052, lng: 17.0183203 },
    { lat: 48.154897, lng: 17.0180289 },
    { lat: 48.1555331, lng: 17.0170533 },
    { lat: 48.1557129, lng: 17.0166128 },
    { lat: 48.1557241, lng: 17.0164302 },
    { lat: 48.1556417, lng: 17.0160344 },
    { lat: 48.1554179, lng: 17.0154919 },
    { lat: 48.1552927, lng: 17.015348 },
    { lat: 48.1548977, lng: 17.0150412 },
    { lat: 48.1544844, lng: 17.0149468 },
    { lat: 48.1544137, lng: 17.0148557 },
    { lat: 48.1543051, lng: 17.0139989 },
    { lat: 48.1542735, lng: 17.0138654 },
    { lat: 48.1541727, lng: 17.0137669 },
    { lat: 48.1538856, lng: 17.0140102 },
    { lat: 48.1530876, lng: 17.0152413 },
    { lat: 48.1515666, lng: 17.0171852 },
    { lat: 48.1513665, lng: 17.0174795 },
    { lat: 48.1512149, lng: 17.0179713 },
    { lat: 48.1509573, lng: 17.018417 },
    { lat: 48.1498217, lng: 17.0193558 },
    { lat: 48.1492239, lng: 17.0198172 },
    { lat: 48.1484633, lng: 17.0183623 },
    { lat: 48.1480638, lng: 17.0187342 },
    { lat: 48.1473408, lng: 17.0194101 },
    { lat: 48.1462921, lng: 17.0202792 },
    { lat: 48.14408, lng: 17.0221192 },
    { lat: 48.1430635, lng: 17.0229989 },
    { lat: 48.1423798, lng: 17.0238036 },
    { lat: 48.1418858, lng: 17.0245492 },
    { lat: 48.141467, lng: 17.0253485 },
    { lat: 48.141152, lng: 17.0260727 },
    { lat: 48.1407868, lng: 17.0271885 },
    { lat: 48.1405005, lng: 17.0284545 },
    { lat: 48.1401317, lng: 17.0307934 },
    { lat: 48.1397881, lng: 17.0338297 },
    { lat: 48.1397201, lng: 17.0354712 },
    { lat: 48.1397201, lng: 17.0367318 },
    { lat: 48.1397917, lng: 17.0383358 },
    { lat: 48.1399707, lng: 17.0399773 },
    { lat: 48.1403787, lng: 17.0426327 },
    { lat: 48.1407654, lng: 17.0447141 },
    { lat: 48.1413238, lng: 17.0471281 },
    { lat: 48.142548, lng: 17.052117 },
    { lat: 48.143357, lng: 17.0553946 },
    { lat: 48.1436022, lng: 17.0564273 },
    { lat: 48.1437267, lng: 17.0571997 },
    { lat: 48.1431398, lng: 17.0572472 },
    { lat: 48.141894, lng: 17.057348 },
    { lat: 48.141793, lng: 17.057356 },
    { lat: 48.141906, lng: 17.058065 },
    { lat: 48.141949, lng: 17.058388 },
    { lat: 48.14201, lng: 17.05885 },
    { lat: 48.142056, lng: 17.059161 },
    { lat: 48.142105, lng: 17.059534 },
    { lat: 48.142147, lng: 17.059845 },
    { lat: 48.142191, lng: 17.060231 },
    { lat: 48.142227, lng: 17.060588 },
    { lat: 48.142283, lng: 17.061145 },
    { lat: 48.142286, lng: 17.061323 },
    { lat: 48.142326, lng: 17.061749 },
    { lat: 48.142303, lng: 17.062284 },
    { lat: 48.142288, lng: 17.062623 },
    { lat: 48.142273, lng: 17.062961 },
    { lat: 48.142079, lng: 17.064064 },
    { lat: 48.142013, lng: 17.064486 },
    { lat: 48.14188, lng: 17.065697 },
    { lat: 48.141706, lng: 17.067537 },
    { lat: 48.141579, lng: 17.068651 },
    { lat: 48.141501, lng: 17.069336 },
    { lat: 48.141406, lng: 17.069308 },
    { lat: 48.141275, lng: 17.069387 },
    { lat: 48.141153, lng: 17.069676 },
    { lat: 48.14112, lng: 17.070238 },
    { lat: 48.140967, lng: 17.071383 },
    { lat: 48.140855, lng: 17.072238 },
    { lat: 48.140783, lng: 17.072406 },
    { lat: 48.140815, lng: 17.072447 },
    { lat: 48.140605, lng: 17.07353 },
    { lat: 48.140693, lng: 17.073547 },
    { lat: 48.140796, lng: 17.073568 },
    { lat: 48.140914, lng: 17.073592 },
    { lat: 48.141012, lng: 17.073612 },
    { lat: 48.141048, lng: 17.073619 },
    { lat: 48.14112, lng: 17.073633 },
    { lat: 48.142414, lng: 17.073896 },
    { lat: 48.144113, lng: 17.074241 },
    { lat: 48.144187, lng: 17.074255 },
    { lat: 48.144207, lng: 17.074395 },
    { lat: 48.14426, lng: 17.074415 },
    { lat: 48.144381, lng: 17.074461 },
    { lat: 48.144682, lng: 17.074817 },
    { lat: 48.144793, lng: 17.074917 },
    { lat: 48.144807, lng: 17.074929 },
    { lat: 48.145499, lng: 17.075532 },
    { lat: 48.145507, lng: 17.075539 },
    { lat: 48.145554, lng: 17.075575 },
    { lat: 48.145688, lng: 17.075676 },
    { lat: 48.145706, lng: 17.07569 },
    { lat: 48.145904, lng: 17.075839 },
    { lat: 48.146027, lng: 17.075932 },
    { lat: 48.146172, lng: 17.075976 },
    { lat: 48.146257, lng: 17.076002 },
    { lat: 48.146412, lng: 17.076049 },
    { lat: 48.146636, lng: 17.076117 },
    { lat: 48.146716, lng: 17.076142 },
    { lat: 48.146749, lng: 17.076133 },
    { lat: 48.146761, lng: 17.07613 },
    { lat: 48.146762, lng: 17.076175 },
    { lat: 48.146764, lng: 17.076243 },
    { lat: 48.146765, lng: 17.07629 },
    { lat: 48.146765, lng: 17.076312 },
    { lat: 48.146766, lng: 17.07636 },
    { lat: 48.146767, lng: 17.07639 },
    { lat: 48.146769, lng: 17.076452 },
    { lat: 48.14677, lng: 17.076485 },
    { lat: 48.146771, lng: 17.076525 },
    { lat: 48.14687, lng: 17.076476 },
    { lat: 48.146905, lng: 17.076459 },
    { lat: 48.146981, lng: 17.076426 },
    { lat: 48.147107, lng: 17.076417 },
    { lat: 48.147165, lng: 17.076405 },
    { lat: 48.147321, lng: 17.076414 },
    { lat: 48.147897, lng: 17.07647 },
    { lat: 48.148386, lng: 17.076512 },
    { lat: 48.148536, lng: 17.076516 },
    { lat: 48.1486364, lng: 17.0765219 },
    { lat: 48.149062, lng: 17.076547 },
    { lat: 48.150888, lng: 17.076659 },
    { lat: 48.150886, lng: 17.076695 },
    { lat: 48.151352, lng: 17.076735 },
    { lat: 48.15157, lng: 17.076765 },
    { lat: 48.151979, lng: 17.076865 },
    { lat: 48.152042, lng: 17.076887 },
    { lat: 48.15242, lng: 17.077024 },
    { lat: 48.152465, lng: 17.07704 },
    { lat: 48.152467, lng: 17.077022 },
    { lat: 48.152472, lng: 17.076996 },
    { lat: 48.152577, lng: 17.077033 },
    { lat: 48.152632, lng: 17.077058 },
    { lat: 48.152754, lng: 17.077114 },
    { lat: 48.152932, lng: 17.077194 },
    { lat: 48.153054, lng: 17.07721 },
    { lat: 48.153139, lng: 17.077221 },
    { lat: 48.153392, lng: 17.077193 },
    { lat: 48.153574, lng: 17.077162 },
    { lat: 48.153633, lng: 17.077152 },
    { lat: 48.153642, lng: 17.077151 },
    { lat: 48.153683, lng: 17.077143 },
    { lat: 48.1537, lng: 17.07714 },
    { lat: 48.153862, lng: 17.077105 },
    { lat: 48.154086, lng: 17.077055 },
    { lat: 48.15431, lng: 17.077007 },
    { lat: 48.154774, lng: 17.076907 },
    { lat: 48.154913, lng: 17.076878 },
    { lat: 48.154925, lng: 17.076875 },
    { lat: 48.155035, lng: 17.076852 },
    { lat: 48.155239, lng: 17.076808 },
    { lat: 48.15543, lng: 17.076767 },
    { lat: 48.155491, lng: 17.076754 },
    { lat: 48.155552, lng: 17.076741 },
    { lat: 48.155678, lng: 17.076714 },
    { lat: 48.155749, lng: 17.076703 },
    { lat: 48.156399, lng: 17.076596 },
    { lat: 48.156646, lng: 17.076554 },
    { lat: 48.157434, lng: 17.076531 },
    { lat: 48.158061, lng: 17.076504 },
    { lat: 48.158244, lng: 17.076495 },
    { lat: 48.158333, lng: 17.076495 },
    { lat: 48.158458, lng: 17.076496 },
    { lat: 48.158764, lng: 17.076496 },
    { lat: 48.158986, lng: 17.076495 },
    { lat: 48.159182, lng: 17.076494 },
    { lat: 48.159704, lng: 17.076476 },
    { lat: 48.159926, lng: 17.076474 },
    { lat: 48.160166, lng: 17.076471 },
    { lat: 48.160482, lng: 17.076498 },
    { lat: 48.160483, lng: 17.07652 },
    { lat: 48.160483, lng: 17.076563 },
    { lat: 48.160555, lng: 17.076574 },
    { lat: 48.160559, lng: 17.07657 },
    { lat: 48.160567, lng: 17.076566 },
    { lat: 48.16058, lng: 17.076558 },
    { lat: 48.16061, lng: 17.076557 },
    { lat: 48.160662, lng: 17.076556 },
    { lat: 48.160702, lng: 17.076556 },
    { lat: 48.161227, lng: 17.076548 },
    { lat: 48.161287, lng: 17.076547 },
    { lat: 48.161388, lng: 17.076546 },
    { lat: 48.161429, lng: 17.076545 },
    { lat: 48.161544, lng: 17.076543 },
    { lat: 48.161592, lng: 17.076542 },
    { lat: 48.161641, lng: 17.076542 },
    { lat: 48.161669, lng: 17.076542 },
    { lat: 48.161759, lng: 17.076541 },
    { lat: 48.161841, lng: 17.076523 },
    { lat: 48.161991, lng: 17.07649 },
    { lat: 48.162203, lng: 17.076446 },
    { lat: 48.162276, lng: 17.076447 },
    { lat: 48.162796, lng: 17.076443 },
    { lat: 48.163505, lng: 17.076423 },
    { lat: 48.163703, lng: 17.076422 },
    { lat: 48.163949, lng: 17.07642 },
    { lat: 48.164006, lng: 17.076419 },
    { lat: 48.164086, lng: 17.076419 },
    { lat: 48.164203, lng: 17.076418 },
    { lat: 48.164226, lng: 17.076418 },
    { lat: 48.16462, lng: 17.076414 },
    { lat: 48.164707, lng: 17.076424 },
    { lat: 48.164761, lng: 17.07643 },
    { lat: 48.164828, lng: 17.076438 },
    { lat: 48.16508, lng: 17.076496 },
    { lat: 48.165333, lng: 17.076601 },
    { lat: 48.165524, lng: 17.076712 },
    { lat: 48.16557, lng: 17.076748 },
    { lat: 48.165609, lng: 17.076778 },
    { lat: 48.165671, lng: 17.076825 },
    { lat: 48.165733, lng: 17.076874 },
    { lat: 48.165859, lng: 17.076972 },
    { lat: 48.165858, lng: 17.077005 },
    { lat: 48.165879, lng: 17.077021 },
    { lat: 48.165929, lng: 17.077056 },
    { lat: 48.165945, lng: 17.07707 },
    { lat: 48.166059, lng: 17.077172 },
    { lat: 48.166456, lng: 17.077539 },
    { lat: 48.166493, lng: 17.077596 },
    { lat: 48.166547, lng: 17.077647 },
    { lat: 48.166773, lng: 17.077843 },
    { lat: 48.166833, lng: 17.077895 },
    { lat: 48.1668487, lng: 17.0778269 },
    { lat: 48.16686, lng: 17.077818 },
    { lat: 48.166885, lng: 17.07774 },
    { lat: 48.1669, lng: 17.077695 },
    { lat: 48.166908, lng: 17.077678 },
    { lat: 48.166913, lng: 17.077669 },
    { lat: 48.167116, lng: 17.077859 },
    { lat: 48.167237, lng: 17.077903 },
    { lat: 48.167309, lng: 17.07789 },
    { lat: 48.167401, lng: 17.077823 },
    { lat: 48.16743, lng: 17.077788 },
    { lat: 48.167449, lng: 17.077765 },
    { lat: 48.167506, lng: 17.077697 },
    { lat: 48.167592, lng: 17.077594 },
    { lat: 48.167772, lng: 17.077383 },
    { lat: 48.167982, lng: 17.077135 },
    { lat: 48.16813, lng: 17.076961 },
    { lat: 48.168232, lng: 17.07686 },
    { lat: 48.16834, lng: 17.076754 },
    { lat: 48.16837, lng: 17.076726 },
    { lat: 48.168384, lng: 17.076704 },
    { lat: 48.168408, lng: 17.076673 },
    { lat: 48.168458, lng: 17.076605 },
    { lat: 48.1686514, lng: 17.0763831 },
    { lat: 48.1687248, lng: 17.076285 },
    { lat: 48.1687647, lng: 17.076213 },
    { lat: 48.1688917, lng: 17.0759986 },
    { lat: 48.1690653, lng: 17.0757005 },
    { lat: 48.169173, lng: 17.075519 },
    { lat: 48.169176, lng: 17.075513 },
    { lat: 48.169333, lng: 17.075239 },
    { lat: 48.169342, lng: 17.075223 },
    { lat: 48.169494, lng: 17.074948 },
    { lat: 48.169782, lng: 17.074417 },
    { lat: 48.169804, lng: 17.074375 },
    { lat: 48.169975, lng: 17.074061 },
    { lat: 48.170264, lng: 17.073528 },
    { lat: 48.170296, lng: 17.073469 },
    { lat: 48.170762, lng: 17.07261 },
    { lat: 48.170787, lng: 17.072562 },
    { lat: 48.170797, lng: 17.072543 },
    { lat: 48.170801, lng: 17.072537 },
    { lat: 48.170864, lng: 17.072417 },
    { lat: 48.170869, lng: 17.072407 },
    { lat: 48.170873, lng: 17.0724 },
    { lat: 48.171014, lng: 17.072135 },
    { lat: 48.171054, lng: 17.072183 },
    { lat: 48.171067, lng: 17.072199 },
    { lat: 48.171187, lng: 17.072343 },
    { lat: 48.171221, lng: 17.072386 },
    { lat: 48.171302, lng: 17.072905 },
    { lat: 48.171463, lng: 17.073138 },
    { lat: 48.171898, lng: 17.073767 },
    { lat: 48.171951, lng: 17.073809 },
    { lat: 48.172047, lng: 17.073877 },
    { lat: 48.172089, lng: 17.073901 },
    { lat: 48.172134, lng: 17.073921 },
    { lat: 48.172177, lng: 17.073936 },
    { lat: 48.172221, lng: 17.073943 },
    { lat: 48.172265, lng: 17.073943 },
    { lat: 48.172314, lng: 17.073943 },
    { lat: 48.172356, lng: 17.073942 },
    { lat: 48.173029, lng: 17.07393 },
    { lat: 48.173259, lng: 17.073931 },
    { lat: 48.173347, lng: 17.073918 },
    { lat: 48.173394, lng: 17.073906 },
    { lat: 48.173541, lng: 17.07386 },
    { lat: 48.17358, lng: 17.073847 },
    { lat: 48.173783, lng: 17.073783 },
    { lat: 48.174056, lng: 17.073797 },
    { lat: 48.174074, lng: 17.073819 },
    { lat: 48.174106, lng: 17.073855 },
    { lat: 48.174129, lng: 17.073881 },
    { lat: 48.174311, lng: 17.073778 },
    { lat: 48.174505, lng: 17.073448 },
    { lat: 48.174953, lng: 17.073053 },
    { lat: 48.175167, lng: 17.072489 },
    { lat: 48.175201, lng: 17.072431 },
    { lat: 48.175573, lng: 17.071447 },
    { lat: 48.175917, lng: 17.071464 },
    { lat: 48.17611, lng: 17.071487 },
    { lat: 48.176113, lng: 17.071488 },
    { lat: 48.176165, lng: 17.071373 },
    { lat: 48.176168, lng: 17.071367 },
    { lat: 48.17623, lng: 17.071242 },
    { lat: 48.176684, lng: 17.070292 },
    { lat: 48.176707, lng: 17.070244 },
    { lat: 48.176377, lng: 17.069442 },
    { lat: 48.176975, lng: 17.067846 },
    { lat: 48.177163, lng: 17.067434 },
    { lat: 48.177191, lng: 17.067375 },
    { lat: 48.177262, lng: 17.067223 },
    { lat: 48.17729, lng: 17.067161 },
    { lat: 48.177296, lng: 17.067148 },
    { lat: 48.177354, lng: 17.067022 },
    { lat: 48.177356, lng: 17.06702 },
    { lat: 48.177407, lng: 17.066956 },
    { lat: 48.177447, lng: 17.066903 },
    { lat: 48.177525, lng: 17.066948 },
    { lat: 48.177583, lng: 17.066866 },
    { lat: 48.177599, lng: 17.066838 },
    { lat: 48.177663, lng: 17.06673 },
    { lat: 48.17766, lng: 17.066693 },
    { lat: 48.177656, lng: 17.066675 },
    { lat: 48.177639, lng: 17.06668 },
    { lat: 48.177657, lng: 17.06646 },
    { lat: 48.177667, lng: 17.066407 },
    { lat: 48.177663, lng: 17.066405 },
    { lat: 48.17768, lng: 17.066367 },
    { lat: 48.177682, lng: 17.066363 },
    { lat: 48.177687, lng: 17.066351 },
    { lat: 48.177709, lng: 17.0663 },
    { lat: 48.177796, lng: 17.066101 },
    { lat: 48.177868, lng: 17.065937 },
    { lat: 48.177947, lng: 17.065756 },
    { lat: 48.17801, lng: 17.065612 },
    { lat: 48.178017, lng: 17.065596 },
    { lat: 48.178076, lng: 17.065461 },
    { lat: 48.178135, lng: 17.065325 },
    { lat: 48.1782, lng: 17.065178 },
    { lat: 48.178352, lng: 17.064832 },
    { lat: 48.178416, lng: 17.064685 },
    { lat: 48.178422, lng: 17.064671 },
    { lat: 48.178536, lng: 17.064409 },
    { lat: 48.1784819, lng: 17.0643708 },
    { lat: 48.1783641, lng: 17.064235 },
    { lat: 48.1780683, lng: 17.0638937 },
    { lat: 48.1780193, lng: 17.0638372 },
    { lat: 48.1779285, lng: 17.0637799 },
    { lat: 48.1772411, lng: 17.0633459 },
    { lat: 48.1770211, lng: 17.0630668 },
    { lat: 48.1769202, lng: 17.0627646 },
    { lat: 48.1768821, lng: 17.0626407 },
    { lat: 48.1761031, lng: 17.063609 },
    { lat: 48.1760282, lng: 17.0635528 },
    { lat: 48.1757264, lng: 17.0640498 },
    { lat: 48.175601, lng: 17.0641139 },
    { lat: 48.1754828, lng: 17.0640551 },
    { lat: 48.1754284, lng: 17.0639544 },
    { lat: 48.1753755, lng: 17.0637107 },
    { lat: 48.1754164, lng: 17.0637264 },
    { lat: 48.1751589, lng: 17.0626042 },
    { lat: 48.1751169, lng: 17.0622035 },
    { lat: 48.1751715, lng: 17.0618277 },
    { lat: 48.175046, lng: 17.0615951 },
    { lat: 48.1747256, lng: 17.0612223 },
    { lat: 48.1750567, lng: 17.0599954 },
    { lat: 48.1746124, lng: 17.0597446 },
    { lat: 48.1741714, lng: 17.0593927 },
    { lat: 48.1740405, lng: 17.0593621 },
    { lat: 48.1734285, lng: 17.0597662 },
    { lat: 48.1732235, lng: 17.0593957 },
    { lat: 48.1732125, lng: 17.0591202 },
    { lat: 48.1732411, lng: 17.0588903 },
    { lat: 48.1735887, lng: 17.0583212 },
    { lat: 48.173625, lng: 17.0583578 },
    { lat: 48.1737199, lng: 17.0581762 },
    { lat: 48.1738911, lng: 17.0582343 },
    { lat: 48.1746242, lng: 17.0557973 },
    { lat: 48.1748372, lng: 17.0555913 },
    { lat: 48.1750841, lng: 17.0555826 },
    { lat: 48.175352, lng: 17.0549045 },
    { lat: 48.1754216, lng: 17.0545823 },
    { lat: 48.1754421, lng: 17.0541222 },
    { lat: 48.1746927, lng: 17.0538397 },
    { lat: 48.1746628, lng: 17.0532697 },
    { lat: 48.1740548, lng: 17.0532181 },
    { lat: 48.1734146, lng: 17.053604 },
    { lat: 48.1732385, lng: 17.0531968 },
    { lat: 48.1731367, lng: 17.0529614 },
    { lat: 48.172429, lng: 17.0527576 },
    { lat: 48.1706666, lng: 17.0529626 },
    { lat: 48.1709635, lng: 17.05213 },
    { lat: 48.1711445, lng: 17.0512096 },
    { lat: 48.1707815, lng: 17.0511712 },
    { lat: 48.1708922, lng: 17.0507752 },
    { lat: 48.1704479, lng: 17.0506689 },
    { lat: 48.1704418, lng: 17.0504655 },
    { lat: 48.1703232, lng: 17.0503527 },
    { lat: 48.1699684, lng: 17.0502674 },
    { lat: 48.1701234, lng: 17.0475435 },
    { lat: 48.1703014, lng: 17.0460982 },
    { lat: 48.1703659, lng: 17.0443966 },
    { lat: 48.1693719, lng: 17.0447188 },
    { lat: 48.1681946, lng: 17.0460906 },
    { lat: 48.1679679, lng: 17.0462622 },
    { lat: 48.1674489, lng: 17.0461759 },
    { lat: 48.168733, lng: 17.0434373 },
    { lat: 48.1691153, lng: 17.0422376 },
    { lat: 48.1690464, lng: 17.0419915 },
    { lat: 48.1686163, lng: 17.0415023 },
    { lat: 48.1680794, lng: 17.0413666 },
    { lat: 48.1674351, lng: 17.042852 },
    { lat: 48.1668144, lng: 17.0445642 },
    { lat: 48.1667346, lng: 17.0445045 },
    { lat: 48.1667276, lng: 17.0444026 },
    { lat: 48.1666447, lng: 17.0444135 },
    { lat: 48.1665422, lng: 17.0443017 },
    { lat: 48.1665953, lng: 17.0438214 },
    { lat: 48.1662348, lng: 17.0435573 },
    { lat: 48.1662504, lng: 17.0434402 },
    { lat: 48.1660918, lng: 17.0433851 },
    { lat: 48.1661068, lng: 17.0432721 },
    { lat: 48.165953, lng: 17.0432212 },
    { lat: 48.1659881, lng: 17.0429423 },
    { lat: 48.1656792, lng: 17.0428019 },
    { lat: 48.1653589, lng: 17.0428618 },
    { lat: 48.1653341, lng: 17.0427619 },
    { lat: 48.16458, lng: 17.0431862 },
    { lat: 48.1643818, lng: 17.042916 },
    { lat: 48.1652218, lng: 17.0413533 },
    { lat: 48.1650545, lng: 17.0411558 },
    { lat: 48.1649701, lng: 17.0411596 },
    { lat: 48.1652148, lng: 17.0407388 },
    { lat: 48.1641583, lng: 17.0405082 },
    { lat: 48.1640488, lng: 17.0407235 },
    { lat: 48.1638141, lng: 17.0404334 },
    { lat: 48.1632674, lng: 17.0412957 },
    { lat: 48.1632958, lng: 17.0413303 },
    { lat: 48.1631574, lng: 17.041505 },
    { lat: 48.1628485, lng: 17.0421738 },
    { lat: 48.1629035, lng: 17.0416426 },
    { lat: 48.1627504, lng: 17.0414416 },
    { lat: 48.1627261, lng: 17.0414096 },
    { lat: 48.1626715, lng: 17.0414434 },
    { lat: 48.1625841, lng: 17.0414975 },
    { lat: 48.1624712, lng: 17.0423734 },
    { lat: 48.162309, lng: 17.0432299 },
    { lat: 48.1622658, lng: 17.0433371 },
    { lat: 48.1616542, lng: 17.0443908 },
    { lat: 48.1615869, lng: 17.0446623 },
    { lat: 48.161247, lng: 17.0452659 },
    { lat: 48.1611447, lng: 17.0453807 },
    { lat: 48.1606241, lng: 17.0451742 },
    { lat: 48.1603272, lng: 17.0446141 },
    { lat: 48.1602504, lng: 17.0446485 },
    { lat: 48.1600794, lng: 17.0442145 },
    { lat: 48.1597713, lng: 17.0443671 },
    { lat: 48.1596925, lng: 17.0437785 },
    { lat: 48.1593405, lng: 17.0435378 },
    { lat: 48.1592421, lng: 17.0436364 },
    { lat: 48.1589966, lng: 17.0435988 },
    { lat: 48.1587745, lng: 17.0443495 },
    { lat: 48.1581805, lng: 17.0443549 },
  ],
  DevínskaNováVes: [
    { lat: 48.2281909, lng: 17.001071 },
    { lat: 48.2403, lng: 16.991965 },
    { lat: 48.2396209, lng: 16.9899313 },
    { lat: 48.2437937, lng: 16.9867833 },
    { lat: 48.2430046, lng: 16.9848562 },
    { lat: 48.241164, lng: 16.9808066 },
    { lat: 48.2411995, lng: 16.9807341 },
    { lat: 48.2525224, lng: 16.9704855 },
    { lat: 48.2535136, lng: 16.9695362 },
    { lat: 48.2542114, lng: 16.9687403 },
    { lat: 48.2542278, lng: 16.96878 },
    { lat: 48.2544097, lng: 16.968632 },
    { lat: 48.2575168, lng: 16.9653117 },
    { lat: 48.2582341, lng: 16.9642403 },
    { lat: 48.2584459, lng: 16.9636923 },
    { lat: 48.2586885, lng: 16.9633916 },
    { lat: 48.258822, lng: 16.9631305 },
    { lat: 48.2588616, lng: 16.9627232 },
    { lat: 48.2591298, lng: 16.9628587 },
    { lat: 48.2591021, lng: 16.962735 },
    { lat: 48.2591905, lng: 16.9621315 },
    { lat: 48.2595768, lng: 16.9622462 },
    { lat: 48.2596399, lng: 16.9618006 },
    { lat: 48.2598706, lng: 16.9618663 },
    { lat: 48.2600102, lng: 16.9610723 },
    { lat: 48.2599697, lng: 16.9608321 },
    { lat: 48.2601658, lng: 16.9603314 },
    { lat: 48.2603888, lng: 16.9601901 },
    { lat: 48.2603492, lng: 16.9597639 },
    { lat: 48.2609041, lng: 16.9597947 },
    { lat: 48.2618834, lng: 16.9596406 },
    { lat: 48.2630914, lng: 16.9596238 },
    { lat: 48.2632333, lng: 16.9579633 },
    { lat: 48.2633608, lng: 16.9578337 },
    { lat: 48.263482, lng: 16.957703 },
    { lat: 48.263483, lng: 16.957262 },
    { lat: 48.263386, lng: 16.957306 },
    { lat: 48.263019, lng: 16.957327 },
    { lat: 48.262789, lng: 16.957245 },
    { lat: 48.262523, lng: 16.957072 },
    { lat: 48.2624132, lng: 16.9570033 },
    { lat: 48.262261, lng: 16.956908 },
    { lat: 48.261975, lng: 16.956716 },
    { lat: 48.261432, lng: 16.956241 },
    { lat: 48.261171, lng: 16.956023 },
    { lat: 48.2607708, lng: 16.9556595 },
    { lat: 48.260436, lng: 16.955372 },
    { lat: 48.26016, lng: 16.95506 },
    { lat: 48.259544, lng: 16.95434 },
    { lat: 48.258979, lng: 16.953689 },
    { lat: 48.258524, lng: 16.953133 },
    { lat: 48.258226, lng: 16.952754 },
    { lat: 48.257929, lng: 16.952397 },
    { lat: 48.257677, lng: 16.952112 },
    { lat: 48.257544, lng: 16.951988 },
    { lat: 48.257274, lng: 16.951774 },
    { lat: 48.25697, lng: 16.951588 },
    { lat: 48.256652, lng: 16.951432 },
    { lat: 48.2563037, lng: 16.9512975 },
    { lat: 48.256059, lng: 16.951203 },
    { lat: 48.255307, lng: 16.950937 },
    { lat: 48.254491, lng: 16.950666 },
    { lat: 48.254085, lng: 16.95051 },
    { lat: 48.253166, lng: 16.95011 },
    { lat: 48.252691, lng: 16.949927 },
    { lat: 48.252242, lng: 16.949782 },
    { lat: 48.251782, lng: 16.949698 },
    { lat: 48.251239, lng: 16.949664 },
    { lat: 48.250857, lng: 16.949693 },
    { lat: 48.250027, lng: 16.949795 },
    { lat: 48.249647, lng: 16.949824 },
    { lat: 48.249271, lng: 16.949825 },
    { lat: 48.248759, lng: 16.949759 },
    { lat: 48.248373, lng: 16.949667 },
    { lat: 48.247844, lng: 16.949468 },
    { lat: 48.247375, lng: 16.949233 },
    { lat: 48.246966, lng: 16.948984 },
    { lat: 48.24648, lng: 16.948721 },
    { lat: 48.245987, lng: 16.948524 },
    { lat: 48.245466, lng: 16.948456 },
    { lat: 48.244874, lng: 16.948408 },
    { lat: 48.244263, lng: 16.948364 },
    { lat: 48.243902, lng: 16.948264 },
    { lat: 48.243568, lng: 16.948103 },
    { lat: 48.243171, lng: 16.947826 },
    { lat: 48.2424478, lng: 16.9472106 },
    { lat: 48.242225, lng: 16.947021 },
    { lat: 48.241662, lng: 16.946718 },
    { lat: 48.2414005, lng: 16.9466715 },
    { lat: 48.2412287, lng: 16.9466409 },
    { lat: 48.2412275, lng: 16.9466407 },
    { lat: 48.237109, lng: 16.946065 },
    { lat: 48.23666, lng: 16.946044 },
    { lat: 48.236194, lng: 16.946073 },
    { lat: 48.235881, lng: 16.946121 },
    { lat: 48.235347, lng: 16.946286 },
    { lat: 48.234947, lng: 16.946449 },
    { lat: 48.23435, lng: 16.946765 },
    { lat: 48.23388, lng: 16.947084 },
    { lat: 48.233427, lng: 16.947433 },
    { lat: 48.232958, lng: 16.947847 },
    { lat: 48.232407, lng: 16.948411 },
    { lat: 48.231885, lng: 16.949038 },
    { lat: 48.231455, lng: 16.949632 },
    { lat: 48.231003, lng: 16.950355 },
    { lat: 48.230669, lng: 16.950955 },
    { lat: 48.229343, lng: 16.953534 },
    { lat: 48.228954, lng: 16.954214 },
    { lat: 48.228453, lng: 16.955014 },
    { lat: 48.227889, lng: 16.955835 },
    { lat: 48.227215, lng: 16.956716 },
    { lat: 48.226499, lng: 16.957548 },
    { lat: 48.226122, lng: 16.957935 },
    { lat: 48.225245, lng: 16.958786 },
    { lat: 48.224569, lng: 16.959399 },
    { lat: 48.224048, lng: 16.959817 },
    { lat: 48.223192, lng: 16.96046 },
    { lat: 48.2230813, lng: 16.9605318 },
    { lat: 48.222452, lng: 16.96094 },
    { lat: 48.221699, lng: 16.961365 },
    { lat: 48.220827, lng: 16.96178 },
    { lat: 48.219893, lng: 16.962052 },
    { lat: 48.219527, lng: 16.962145 },
    { lat: 48.21901, lng: 16.962189 },
    { lat: 48.218295, lng: 16.962186 },
    { lat: 48.2174953, lng: 16.9620573 },
    { lat: 48.216234, lng: 16.961965 },
    { lat: 48.215367, lng: 16.961937 },
    { lat: 48.214449, lng: 16.962129 },
    { lat: 48.213972, lng: 16.962286 },
    { lat: 48.213566, lng: 16.962463 },
    { lat: 48.213061, lng: 16.962736 },
    { lat: 48.212537, lng: 16.963108 },
    { lat: 48.212032, lng: 16.963549 },
    { lat: 48.2110638, lng: 16.9645252 },
    { lat: 48.21079, lng: 16.964784 },
    { lat: 48.210456, lng: 16.965056 },
    { lat: 48.210226, lng: 16.965221 },
    { lat: 48.210028, lng: 16.965336 },
    { lat: 48.2099501, lng: 16.9653812 },
    { lat: 48.20978, lng: 16.96548 },
    { lat: 48.209459, lng: 16.965637 },
    { lat: 48.208842, lng: 16.96586 },
    { lat: 48.207032, lng: 16.966366 },
    { lat: 48.206735, lng: 16.966447 },
    { lat: 48.206158, lng: 16.966652 },
    { lat: 48.205269, lng: 16.967054 },
    { lat: 48.204886, lng: 16.967261 },
    { lat: 48.204468, lng: 16.967508 },
    { lat: 48.204029, lng: 16.967795 },
    { lat: 48.2039141, lng: 16.9678866 },
    { lat: 48.203596, lng: 16.96814 },
    { lat: 48.202515, lng: 16.969175 },
    { lat: 48.202105, lng: 16.969507 },
    { lat: 48.2017856, lng: 16.969724 },
    { lat: 48.201681, lng: 16.969795 },
    { lat: 48.201276, lng: 16.970003 },
    { lat: 48.201273, lng: 16.970005 },
    { lat: 48.200842, lng: 16.970194 },
    { lat: 48.198499, lng: 16.970867 },
    { lat: 48.1967833, lng: 16.971385 },
    { lat: 48.1962733, lng: 16.9715843 },
    { lat: 48.195767, lng: 16.9718283 },
    { lat: 48.1952703, lng: 16.97213 },
    { lat: 48.19491, lng: 16.9723726 },
    { lat: 48.19436, lng: 16.972806 },
    { lat: 48.19198, lng: 16.9749314 },
    { lat: 48.1917478, lng: 16.9751264 },
    { lat: 48.1913475, lng: 16.9754603 },
    { lat: 48.1909445, lng: 16.9757406 },
    { lat: 48.1903876, lng: 16.9760587 },
    { lat: 48.1898277, lng: 16.9762884 },
    { lat: 48.189369, lng: 16.9764373 },
    { lat: 48.188597, lng: 16.9765796 },
    { lat: 48.188029, lng: 16.976601 },
    { lat: 48.187354, lng: 16.976555 },
    { lat: 48.1870969, lng: 16.9765322 },
    { lat: 48.1872041, lng: 16.9790756 },
    { lat: 48.1873743, lng: 16.9803143 },
    { lat: 48.1882659, lng: 16.9814132 },
    { lat: 48.1896191, lng: 16.9816965 },
    { lat: 48.1902081, lng: 16.9819301 },
    { lat: 48.1913197, lng: 16.9817934 },
    { lat: 48.1916664, lng: 16.9821158 },
    { lat: 48.1925053, lng: 16.9821557 },
    { lat: 48.193076, lng: 16.9828427 },
    { lat: 48.1930732, lng: 16.9830663 },
    { lat: 48.1929641, lng: 16.9835173 },
    { lat: 48.192513, lng: 16.9847276 },
    { lat: 48.1923168, lng: 16.9853893 },
    { lat: 48.1923926, lng: 16.9858331 },
    { lat: 48.1923901, lng: 16.9861756 },
    { lat: 48.1923489, lng: 16.9862337 },
    { lat: 48.1923888, lng: 16.986535 },
    { lat: 48.1921636, lng: 16.9876623 },
    { lat: 48.1919581, lng: 16.9883024 },
    { lat: 48.1917405, lng: 16.9892461 },
    { lat: 48.1915856, lng: 16.98964 },
    { lat: 48.191104, lng: 16.9904916 },
    { lat: 48.1909484, lng: 16.9906423 },
    { lat: 48.1905104, lng: 16.990798 },
    { lat: 48.1900321, lng: 16.9912534 },
    { lat: 48.1899201, lng: 16.9914342 },
    { lat: 48.1895236, lng: 16.9920743 },
    { lat: 48.1893131, lng: 16.9922852 },
    { lat: 48.1887899, lng: 16.9932362 },
    { lat: 48.1885345, lng: 16.9934824 },
    { lat: 48.1885846, lng: 16.9937208 },
    { lat: 48.1886164, lng: 16.9939014 },
    { lat: 48.189054, lng: 16.9948753 },
    { lat: 48.1904554, lng: 16.9967307 },
    { lat: 48.1910168, lng: 16.9963504 },
    { lat: 48.1913323, lng: 16.9962207 },
    { lat: 48.1916319, lng: 16.9965167 },
    { lat: 48.1924745, lng: 16.9976607 },
    { lat: 48.1928601, lng: 16.9983398 },
    { lat: 48.1923338, lng: 16.9989863 },
    { lat: 48.1922896, lng: 16.9991493 },
    { lat: 48.1917555, lng: 16.9997783 },
    { lat: 48.1913834, lng: 17.0001171 },
    { lat: 48.1898001, lng: 17.0008093 },
    { lat: 48.1891976, lng: 17.0011672 },
    { lat: 48.188932, lng: 17.0013953 },
    { lat: 48.1887765, lng: 17.0017001 },
    { lat: 48.1884893, lng: 17.0026161 },
    { lat: 48.1882601, lng: 17.0031135 },
    { lat: 48.1877536, lng: 17.003685 },
    { lat: 48.1875965, lng: 17.0039804 },
    { lat: 48.1874494, lng: 17.0043731 },
    { lat: 48.1871948, lng: 17.0055192 },
    { lat: 48.1870327, lng: 17.0060551 },
    { lat: 48.1859328, lng: 17.0078102 },
    { lat: 48.185879, lng: 17.0082434 },
    { lat: 48.1855387, lng: 17.0090808 },
    { lat: 48.1856, lng: 17.0094434 },
    { lat: 48.1856813, lng: 17.009924 },
    { lat: 48.1857881, lng: 17.0110159 },
    { lat: 48.1855581, lng: 17.0115364 },
    { lat: 48.1856208, lng: 17.0134531 },
    { lat: 48.1858418, lng: 17.0133948 },
    { lat: 48.1859551, lng: 17.0134485 },
    { lat: 48.1867842, lng: 17.0123418 },
    { lat: 48.1872105, lng: 17.0120812 },
    { lat: 48.1873122, lng: 17.0119516 },
    { lat: 48.1876518, lng: 17.0118452 },
    { lat: 48.1882481, lng: 17.0114764 },
    { lat: 48.1884608, lng: 17.0114802 },
    { lat: 48.1888182, lng: 17.0113545 },
    { lat: 48.189039, lng: 17.0114725 },
    { lat: 48.1894072, lng: 17.0123279 },
    { lat: 48.1895996, lng: 17.0126252 },
    { lat: 48.1897778, lng: 17.0127685 },
    { lat: 48.1905231, lng: 17.0130734 },
    { lat: 48.1908849, lng: 17.0130288 },
    { lat: 48.1917752, lng: 17.0130538 },
    { lat: 48.1924473, lng: 17.0130726 },
    { lat: 48.1928215, lng: 17.0132224 },
    { lat: 48.1931757, lng: 17.0130979 },
    { lat: 48.1935832, lng: 17.0128586 },
    { lat: 48.1938931, lng: 17.0130299 },
    { lat: 48.1941089, lng: 17.0130102 },
    { lat: 48.1945413, lng: 17.0127936 },
    { lat: 48.1947713, lng: 17.0128316 },
    { lat: 48.1949726, lng: 17.0127525 },
    { lat: 48.1954938, lng: 17.0128043 },
    { lat: 48.1960269, lng: 17.012671 },
    { lat: 48.1964308, lng: 17.0126597 },
    { lat: 48.1968824, lng: 17.0125074 },
    { lat: 48.1978521, lng: 17.0124903 },
    { lat: 48.1980678, lng: 17.0124253 },
    { lat: 48.1987646, lng: 17.0119049 },
    { lat: 48.2001164, lng: 17.0074341 },
    { lat: 48.202218, lng: 17.0055864 },
    { lat: 48.202108, lng: 17.0051705 },
    { lat: 48.2022784, lng: 17.0052376 },
    { lat: 48.2026388, lng: 17.0053797 },
    { lat: 48.2037297, lng: 17.0066364 },
    { lat: 48.2039172, lng: 17.0066016 },
    { lat: 48.2039697, lng: 17.0069215 },
    { lat: 48.2039361, lng: 17.0078151 },
    { lat: 48.2036192, lng: 17.010521 },
    { lat: 48.2032348, lng: 17.0204025 },
    { lat: 48.2026036, lng: 17.0208044 },
    { lat: 48.2025933, lng: 17.0215619 },
    { lat: 48.202714, lng: 17.0222355 },
    { lat: 48.2028357, lng: 17.0237135 },
    { lat: 48.2031513, lng: 17.0239332 },
    { lat: 48.2031223, lng: 17.025819 },
    { lat: 48.2030376, lng: 17.0269081 },
    { lat: 48.20316, lng: 17.0270296 },
    { lat: 48.2029775, lng: 17.0311762 },
    { lat: 48.2028724, lng: 17.0324806 },
    { lat: 48.2038722, lng: 17.0306452 },
    { lat: 48.2039023, lng: 17.0303753 },
    { lat: 48.2051967, lng: 17.0278896 },
    { lat: 48.2052574, lng: 17.0278881 },
    { lat: 48.207205, lng: 17.0243307 },
    { lat: 48.2102032, lng: 17.0192911 },
    { lat: 48.2122268, lng: 17.0160892 },
    { lat: 48.2123074, lng: 17.0160272 },
    { lat: 48.2139635, lng: 17.01592 },
    { lat: 48.2142253, lng: 17.0158493 },
    { lat: 48.2165681, lng: 17.0137445 },
    { lat: 48.2166036, lng: 17.0136207 },
    { lat: 48.2167238, lng: 17.0139048 },
    { lat: 48.2178001, lng: 17.0124458 },
    { lat: 48.2191717, lng: 17.0140235 },
    { lat: 48.220389, lng: 17.0158733 },
    { lat: 48.220379, lng: 17.0138625 },
    { lat: 48.2204149, lng: 17.0135299 },
    { lat: 48.2214638, lng: 17.0100594 },
    { lat: 48.2210856, lng: 17.0096523 },
    { lat: 48.2208424, lng: 17.0092382 },
    { lat: 48.2207327, lng: 17.0089067 },
    { lat: 48.2204167, lng: 17.0068727 },
    { lat: 48.2205698, lng: 17.0069955 },
    { lat: 48.2205376, lng: 17.0068059 },
    { lat: 48.2214159, lng: 17.0074845 },
    { lat: 48.2218382, lng: 17.0077102 },
    { lat: 48.2247555, lng: 17.0088227 },
    { lat: 48.2250078, lng: 17.0088594 },
    { lat: 48.2275871, lng: 17.0082903 },
    { lat: 48.2298306, lng: 17.0075208 },
    { lat: 48.2298195, lng: 17.0049558 },
    { lat: 48.2297868, lng: 17.0047935 },
    { lat: 48.2296726, lng: 17.0046257 },
    { lat: 48.2281909, lng: 17.001071 },
  ],
};

export default UnitsBratislavaIV;
