import React from "react";

const Category = (props) => {


    const getMinMax = (event) =>{
        props.changeCategory(event.target.value)

        const temp = props.catList.filter(e => e.children).map(m => m.children)
        if(event.target.value.substring(0, 1)  === '1'){
            const min = temp[0].filter(e => e.code === event.target.value).map(r => r.min)
            const max = temp[0].filter(e => e.code === event.target.value).map(r => r.max)
            props.getMin(min)
            props.getMax(max)
            props.uArea(min)
        } else {
            props.getMin(90)
            props.getMax(600)
            props.uArea(90)
        }  
       // props.changeIsOpen(false)  
        props.resetAvgPrice([])
    }

    
    return (
        <div className="relative w-screen px-2 sm:max-w-sm">
            <p className="text-white">Druh:</p>
            <select name="category"  value={props.catVal} onChange={(event) => getMinMax(event)} className="w-full mb-4 p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-sky-500 ">                     
                <option disabled value="">Kategórie</option>
                {props.catList.map(({categoryName,children},key) => (
                    <optgroup label={categoryName} key={key}>
                        {children.map(({code, name}) => (
                            <option value={code} key={code}>
                                {name}
                            </option>
                        ))}
                </optgroup>
                ))}
                
            </select>
        </div>
    );
}

export default Category