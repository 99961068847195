const UnitsČadca = {
  DlhánadKysucou: [
    { lat: 49.363897, lng: 18.633266 },
    { lat: 49.364273, lng: 18.634641 },
    { lat: 49.364332, lng: 18.636022 },
    { lat: 49.364367, lng: 18.637742 },
    { lat: 49.364467, lng: 18.639973 },
    { lat: 49.36427, lng: 18.64045 },
    { lat: 49.36395, lng: 18.64124 },
    { lat: 49.363852, lng: 18.641367 },
    { lat: 49.36383, lng: 18.641403 },
    { lat: 49.363213, lng: 18.642205 },
    { lat: 49.362942, lng: 18.642445 },
    { lat: 49.362778, lng: 18.642483 },
    { lat: 49.362233, lng: 18.642695 },
    { lat: 49.362037, lng: 18.642775 },
    { lat: 49.361954, lng: 18.642841 },
    { lat: 49.361206, lng: 18.643352 },
    { lat: 49.360643, lng: 18.643826 },
    { lat: 49.360604, lng: 18.643877 },
    { lat: 49.360562, lng: 18.643919 },
    { lat: 49.359992, lng: 18.644506 },
    { lat: 49.35957, lng: 18.645179 },
    { lat: 49.359547, lng: 18.645216 },
    { lat: 49.359054, lng: 18.645724 },
    { lat: 49.359008, lng: 18.645772 },
    { lat: 49.358255, lng: 18.647047 },
    { lat: 49.358077, lng: 18.647649 },
    { lat: 49.357906, lng: 18.648832 },
    { lat: 49.357921, lng: 18.649608 },
    { lat: 49.358109, lng: 18.651829 },
    { lat: 49.358154, lng: 18.652521 },
    { lat: 49.358182, lng: 18.652954 },
    { lat: 49.358238, lng: 18.653537 },
    { lat: 49.358179, lng: 18.654188 },
    { lat: 49.358162, lng: 18.654708 },
    { lat: 49.358079, lng: 18.655015 },
    { lat: 49.357924, lng: 18.655761 },
    { lat: 49.357791, lng: 18.656475 },
    { lat: 49.35754, lng: 18.657067 },
    { lat: 49.357211, lng: 18.657254 },
    { lat: 49.35672, lng: 18.657709 },
    { lat: 49.356461, lng: 18.658084 },
    { lat: 49.356171, lng: 18.658201 },
    { lat: 49.356065, lng: 18.658309 },
    { lat: 49.355464, lng: 18.65951 },
    { lat: 49.354959, lng: 18.66052 },
    { lat: 49.354777, lng: 18.660927 },
    { lat: 49.354656, lng: 18.661576 },
    { lat: 49.354679, lng: 18.661999 },
    { lat: 49.354642, lng: 18.662209 },
    { lat: 49.354638, lng: 18.662458 },
    { lat: 49.354375, lng: 18.66362 },
    { lat: 49.354368, lng: 18.664138 },
    { lat: 49.354323, lng: 18.664396 },
    { lat: 49.354393, lng: 18.664553 },
    { lat: 49.353089, lng: 18.66502 },
    { lat: 49.352918, lng: 18.665082 },
    { lat: 49.352882, lng: 18.665244 },
    { lat: 49.353289, lng: 18.66631 },
    { lat: 49.353177, lng: 18.667013 },
    { lat: 49.353064, lng: 18.667251 },
    { lat: 49.35285, lng: 18.668175 },
    { lat: 49.352731, lng: 18.668853 },
    { lat: 49.352362, lng: 18.669574 },
    { lat: 49.351928, lng: 18.670261 },
    { lat: 49.351807, lng: 18.670141 },
    { lat: 49.349709, lng: 18.670884 },
    { lat: 49.349355, lng: 18.671357 },
    { lat: 49.349271, lng: 18.671414 },
    { lat: 49.34904, lng: 18.6717 },
    { lat: 49.348817, lng: 18.671949 },
    { lat: 49.348554, lng: 18.672165 },
    { lat: 49.347816, lng: 18.672942 },
    { lat: 49.346941, lng: 18.673509 },
    { lat: 49.346878, lng: 18.67355 },
    { lat: 49.346224, lng: 18.673606 },
    { lat: 49.34534, lng: 18.672823 },
    { lat: 49.344867, lng: 18.672981 },
    { lat: 49.343988, lng: 18.673276 },
    { lat: 49.342808, lng: 18.673563 },
    { lat: 49.342283, lng: 18.67364 },
    { lat: 49.34174, lng: 18.673716 },
    { lat: 49.34149, lng: 18.673596 },
    { lat: 49.341257, lng: 18.673582 },
    { lat: 49.341256, lng: 18.673606 },
    { lat: 49.34125, lng: 18.673723 },
    { lat: 49.341244, lng: 18.673781 },
    { lat: 49.341009, lng: 18.67401 },
    { lat: 49.34092, lng: 18.674097 },
    { lat: 49.340743, lng: 18.674276 },
    { lat: 49.3406, lng: 18.674407 },
    { lat: 49.340464, lng: 18.674842 },
    { lat: 49.340435, lng: 18.674933 },
    { lat: 49.340333, lng: 18.675687 },
    { lat: 49.340293, lng: 18.675933 },
    { lat: 49.340254, lng: 18.676165 },
    { lat: 49.340213, lng: 18.67662 },
    { lat: 49.340171, lng: 18.677075 },
    { lat: 49.34016, lng: 18.677924 },
    { lat: 49.340186, lng: 18.67847 },
    { lat: 49.340966, lng: 18.678018 },
    { lat: 49.341518, lng: 18.678191 },
    { lat: 49.342492, lng: 18.678274 },
    { lat: 49.342836, lng: 18.67851 },
    { lat: 49.343097, lng: 18.678571 },
    { lat: 49.343379, lng: 18.678756 },
    { lat: 49.343541, lng: 18.67876 },
    { lat: 49.343793, lng: 18.678592 },
    { lat: 49.343941, lng: 18.678708 },
    { lat: 49.344152, lng: 18.678618 },
    { lat: 49.344271, lng: 18.678475 },
    { lat: 49.344793, lng: 18.678402 },
    { lat: 49.345222, lng: 18.678876 },
    { lat: 49.345532, lng: 18.679411 },
    { lat: 49.345789, lng: 18.679637 },
    { lat: 49.346164, lng: 18.679751 },
    { lat: 49.346575, lng: 18.679686 },
    { lat: 49.347297, lng: 18.679227 },
    { lat: 49.347836, lng: 18.678347 },
    { lat: 49.348753, lng: 18.678154 },
    { lat: 49.348981, lng: 18.678323 },
    { lat: 49.349824, lng: 18.677878 },
    { lat: 49.350076, lng: 18.677466 },
    { lat: 49.350487, lng: 18.677122 },
    { lat: 49.350962, lng: 18.677074 },
    { lat: 49.350987, lng: 18.676562 },
    { lat: 49.351146, lng: 18.676542 },
    { lat: 49.351288, lng: 18.676302 },
    { lat: 49.35208, lng: 18.67646 },
    { lat: 49.352594, lng: 18.676446 },
    { lat: 49.353524, lng: 18.673994 },
    { lat: 49.353799, lng: 18.67365 },
    { lat: 49.353811, lng: 18.673514 },
    { lat: 49.353948, lng: 18.673198 },
    { lat: 49.353966, lng: 18.673232 },
    { lat: 49.35408, lng: 18.673017 },
    { lat: 49.354351, lng: 18.672848 },
    { lat: 49.354708, lng: 18.672968 },
    { lat: 49.354718, lng: 18.672886 },
    { lat: 49.355115, lng: 18.672923 },
    { lat: 49.355166, lng: 18.672871 },
    { lat: 49.3554, lng: 18.672957 },
    { lat: 49.355652, lng: 18.67289 },
    { lat: 49.355967, lng: 18.673106 },
    { lat: 49.356264, lng: 18.673213 },
    { lat: 49.356767, lng: 18.672987 },
    { lat: 49.357338, lng: 18.673215 },
    { lat: 49.357898, lng: 18.673119 },
    { lat: 49.358062, lng: 18.67332 },
    { lat: 49.35769, lng: 18.674386 },
    { lat: 49.357993, lng: 18.675025 },
    { lat: 49.358124, lng: 18.675805 },
    { lat: 49.357911, lng: 18.676082 },
    { lat: 49.357965, lng: 18.676157 },
    { lat: 49.357832, lng: 18.67652 },
    { lat: 49.357699, lng: 18.676611 },
    { lat: 49.357763, lng: 18.676829 },
    { lat: 49.357455, lng: 18.677094 },
    { lat: 49.35717, lng: 18.677135 },
    { lat: 49.356773, lng: 18.676924 },
    { lat: 49.35619, lng: 18.676226 },
    { lat: 49.356379, lng: 18.676887 },
    { lat: 49.356761, lng: 18.677635 },
    { lat: 49.356995, lng: 18.677981 },
    { lat: 49.357136, lng: 18.678093 },
    { lat: 49.357692, lng: 18.678139 },
    { lat: 49.357777, lng: 18.678198 },
    { lat: 49.357821, lng: 18.678082 },
    { lat: 49.357926, lng: 18.677996 },
    { lat: 49.358182, lng: 18.678005 },
    { lat: 49.358467, lng: 18.678111 },
    { lat: 49.358669, lng: 18.678297 },
    { lat: 49.35897, lng: 18.678307 },
    { lat: 49.359823, lng: 18.679237 },
    { lat: 49.360242, lng: 18.679863 },
    { lat: 49.360513, lng: 18.68043 },
    { lat: 49.36065, lng: 18.68092 },
    { lat: 49.360836, lng: 18.681986 },
    { lat: 49.361004, lng: 18.682436 },
    { lat: 49.361171, lng: 18.683248 },
    { lat: 49.361446, lng: 18.685284 },
    { lat: 49.361459, lng: 18.686439 },
    { lat: 49.361396, lng: 18.686669 },
    { lat: 49.36137, lng: 18.687032 },
    { lat: 49.361433, lng: 18.68728 },
    { lat: 49.36144, lng: 18.687557 },
    { lat: 49.361322, lng: 18.688761 },
    { lat: 49.361334, lng: 18.689428 },
    { lat: 49.361236, lng: 18.690181 },
    { lat: 49.361338, lng: 18.690212 },
    { lat: 49.3614693, lng: 18.6900058 },
    { lat: 49.3615291, lng: 18.689769 },
    { lat: 49.3617261, lng: 18.6886913 },
    { lat: 49.3618433, lng: 18.6881067 },
    { lat: 49.361971, lng: 18.6875345 },
    { lat: 49.3621379, lng: 18.6868468 },
    { lat: 49.3622456, lng: 18.686496 },
    { lat: 49.3624557, lng: 18.6860743 },
    { lat: 49.3627165, lng: 18.6858435 },
    { lat: 49.3629654, lng: 18.685624 },
    { lat: 49.3632097, lng: 18.6854854 },
    { lat: 49.3635193, lng: 18.6853683 },
    { lat: 49.3637927, lng: 18.6852749 },
    { lat: 49.3640196, lng: 18.6852123 },
    { lat: 49.3643585, lng: 18.6850967 },
    { lat: 49.364723, lng: 18.6849913 },
    { lat: 49.3649963, lng: 18.6848954 },
    { lat: 49.365315, lng: 18.6846926 },
    { lat: 49.3656916, lng: 18.6845192 },
    { lat: 49.3660917, lng: 18.6842372 },
    { lat: 49.3665201, lng: 18.6839597 },
    { lat: 49.3668208, lng: 18.6836516 },
    { lat: 49.3670911, lng: 18.6834942 },
    { lat: 49.3674343, lng: 18.6830911 },
    { lat: 49.3677342, lng: 18.6826836 },
    { lat: 49.368218, lng: 18.6821912 },
    { lat: 49.3682898, lng: 18.6821234 },
    { lat: 49.3683636, lng: 18.6821103 },
    { lat: 49.368605, lng: 18.6820921 },
    { lat: 49.3689012, lng: 18.6820614 },
    { lat: 49.369194, lng: 18.6819905 },
    { lat: 49.3694511, lng: 18.6820135 },
    { lat: 49.3697998, lng: 18.6820164 },
    { lat: 49.3700768, lng: 18.6820087 },
    { lat: 49.3703442, lng: 18.6820108 },
    { lat: 49.370633, lng: 18.682036 },
    { lat: 49.3709695, lng: 18.6821294 },
    { lat: 49.3713, lng: 18.6821958 },
    { lat: 49.3716219, lng: 18.6821832 },
    { lat: 49.3718588, lng: 18.682163 },
    { lat: 49.3721242, lng: 18.6820438 },
    { lat: 49.3723331, lng: 18.6819446 },
    { lat: 49.3725953, lng: 18.6817802 },
    { lat: 49.3727531, lng: 18.6816713 },
    { lat: 49.3729686, lng: 18.6814837 },
    { lat: 49.3731478, lng: 18.6813317 },
    { lat: 49.3733453, lng: 18.681206 },
    { lat: 49.3735458, lng: 18.6811581 },
    { lat: 49.3738234, lng: 18.6810768 },
    { lat: 49.3740106, lng: 18.6809694 },
    { lat: 49.3742878, lng: 18.6807503 },
    { lat: 49.3745289, lng: 18.6805552 },
    { lat: 49.3746623, lng: 18.6803227 },
    { lat: 49.37476, lng: 18.6801239 },
    { lat: 49.3748436, lng: 18.6800018 },
    { lat: 49.3749857, lng: 18.6798493 },
    { lat: 49.3752871, lng: 18.6796822 },
    { lat: 49.3754078, lng: 18.6796618 },
    { lat: 49.3757167, lng: 18.679619 },
    { lat: 49.3760764, lng: 18.6795345 },
    { lat: 49.3767166, lng: 18.6794815 },
    { lat: 49.3768693, lng: 18.6795604 },
    { lat: 49.3771084, lng: 18.6794969 },
    { lat: 49.3775674, lng: 18.6796234 },
    { lat: 49.3777716, lng: 18.6796886 },
    { lat: 49.3780424, lng: 18.6798007 },
    { lat: 49.3783637, lng: 18.6798613 },
    { lat: 49.3785677, lng: 18.6799888 },
    { lat: 49.3789289, lng: 18.6800814 },
    { lat: 49.3792116, lng: 18.680057 },
    { lat: 49.3795254, lng: 18.679976 },
    { lat: 49.3798121, lng: 18.68 },
    { lat: 49.3800928, lng: 18.6798904 },
    { lat: 49.3803062, lng: 18.6797935 },
    { lat: 49.3805903, lng: 18.6796187 },
    { lat: 49.3807101, lng: 18.6794748 },
    { lat: 49.3808768, lng: 18.6791903 },
    { lat: 49.3809443, lng: 18.67908 },
    { lat: 49.3810249, lng: 18.6786411 },
    { lat: 49.3811963, lng: 18.6782971 },
    { lat: 49.3812379, lng: 18.6782525 },
    { lat: 49.381319, lng: 18.6781854 },
    { lat: 49.381552, lng: 18.6780281 },
    { lat: 49.3816618, lng: 18.6778434 },
    { lat: 49.3817352, lng: 18.6775889 },
    { lat: 49.3817942, lng: 18.6771661 },
    { lat: 49.3818866, lng: 18.6768659 },
    { lat: 49.3820377, lng: 18.6765208 },
    { lat: 49.3824, lng: 18.6758626 },
    { lat: 49.3826677, lng: 18.6753818 },
    { lat: 49.3829961, lng: 18.6748519 },
    { lat: 49.3833253, lng: 18.6745915 },
    { lat: 49.3835822, lng: 18.6742428 },
    { lat: 49.3838645, lng: 18.6739951 },
    { lat: 49.3839598, lng: 18.6738559 },
    { lat: 49.3840029, lng: 18.673603 },
    { lat: 49.3840501, lng: 18.6732783 },
    { lat: 49.3839994, lng: 18.6728538 },
    { lat: 49.3839873, lng: 18.672408 },
    { lat: 49.3837552, lng: 18.6718336 },
    { lat: 49.3836043, lng: 18.6715616 },
    { lat: 49.3834088, lng: 18.670847 },
    { lat: 49.3833277, lng: 18.6703978 },
    { lat: 49.3832195, lng: 18.6697119 },
    { lat: 49.3830964, lng: 18.6690025 },
    { lat: 49.3829489, lng: 18.6683877 },
    { lat: 49.3829345, lng: 18.6683475 },
    { lat: 49.3828248, lng: 18.668171 },
    { lat: 49.3826612, lng: 18.6678695 },
    { lat: 49.3824266, lng: 18.6672072 },
    { lat: 49.3821123, lng: 18.6664451 },
    { lat: 49.3820918, lng: 18.6664592 },
    { lat: 49.3818915, lng: 18.6661249 },
    { lat: 49.3815823, lng: 18.6656919 },
    { lat: 49.3817757, lng: 18.6654472 },
    { lat: 49.3812517, lng: 18.6639803 },
    { lat: 49.3811408, lng: 18.6640371 },
    { lat: 49.3811597, lng: 18.6632164 },
    { lat: 49.3817971, lng: 18.6623887 },
    { lat: 49.3818252, lng: 18.6624066 },
    { lat: 49.381927, lng: 18.6621591 },
    { lat: 49.3821448, lng: 18.661671 },
    { lat: 49.3824078, lng: 18.6613124 },
    { lat: 49.3826111, lng: 18.6609332 },
    { lat: 49.3829002, lng: 18.6607277 },
    { lat: 49.3830887, lng: 18.6604952 },
    { lat: 49.3832803, lng: 18.6600462 },
    { lat: 49.383489, lng: 18.6594072 },
    { lat: 49.3835635, lng: 18.6588293 },
    { lat: 49.3836767, lng: 18.6583028 },
    { lat: 49.3838581, lng: 18.6577015 },
    { lat: 49.3840359, lng: 18.6570979 },
    { lat: 49.3844071, lng: 18.6571641 },
    { lat: 49.3844322, lng: 18.6571925 },
    { lat: 49.3845121, lng: 18.6570411 },
    { lat: 49.3846789, lng: 18.6567402 },
    { lat: 49.3848252, lng: 18.6564988 },
    { lat: 49.3849513, lng: 18.6563004 },
    { lat: 49.3850543, lng: 18.6561805 },
    { lat: 49.3852075, lng: 18.6560504 },
    { lat: 49.3853102, lng: 18.6559638 },
    { lat: 49.385476, lng: 18.6558821 },
    { lat: 49.3855989, lng: 18.6558151 },
    { lat: 49.3857907, lng: 18.6557559 },
    { lat: 49.3859405, lng: 18.6557058 },
    { lat: 49.3860846, lng: 18.6555761 },
    { lat: 49.3861671, lng: 18.6554063 },
    { lat: 49.3862403, lng: 18.6552977 },
    { lat: 49.3865252, lng: 18.6549916 },
    { lat: 49.3868047, lng: 18.654755 },
    { lat: 49.3870118, lng: 18.6545773 },
    { lat: 49.3873013, lng: 18.6542967 },
    { lat: 49.3874294, lng: 18.6539424 },
    { lat: 49.3875463, lng: 18.6534903 },
    { lat: 49.387719, lng: 18.6532571 },
    { lat: 49.3879208, lng: 18.6530196 },
    { lat: 49.388094, lng: 18.6527545 },
    { lat: 49.3882311, lng: 18.6524928 },
    { lat: 49.3882779, lng: 18.6523722 },
    { lat: 49.3884902, lng: 18.6526562 },
    { lat: 49.3886706, lng: 18.6529527 },
    { lat: 49.3888321, lng: 18.6534731 },
    { lat: 49.3889352, lng: 18.6535871 },
    { lat: 49.3891613, lng: 18.6535543 },
    { lat: 49.389801, lng: 18.6528122 },
    { lat: 49.3898655, lng: 18.6527539 },
    { lat: 49.3899859, lng: 18.6526452 },
    { lat: 49.3901246, lng: 18.6526856 },
    { lat: 49.3902967, lng: 18.6526994 },
    { lat: 49.3909001, lng: 18.6529337 },
    { lat: 49.3910355, lng: 18.653002 },
    { lat: 49.3911499, lng: 18.6530103 },
    { lat: 49.3917286, lng: 18.6531409 },
    { lat: 49.3924977, lng: 18.653337 },
    { lat: 49.3926487, lng: 18.6533552 },
    { lat: 49.3926515, lng: 18.6533005 },
    { lat: 49.3927605, lng: 18.6532736 },
    { lat: 49.3929925, lng: 18.6532242 },
    { lat: 49.3931251, lng: 18.6531705 },
    { lat: 49.3931218, lng: 18.6530236 },
    { lat: 49.3935533, lng: 18.6532079 },
    { lat: 49.3936624, lng: 18.6531828 },
    { lat: 49.3949329, lng: 18.6531553 },
    { lat: 49.3948134, lng: 18.6512097 },
    { lat: 49.3947459, lng: 18.6502956 },
    { lat: 49.3946797, lng: 18.6496199 },
    { lat: 49.394669, lng: 18.6493551 },
    { lat: 49.394773, lng: 18.6491449 },
    { lat: 49.3949061, lng: 18.6488062 },
    { lat: 49.3950711, lng: 18.6482789 },
    { lat: 49.395053, lng: 18.6479154 },
    { lat: 49.3949532, lng: 18.6474123 },
    { lat: 49.3949435, lng: 18.6456956 },
    { lat: 49.394857, lng: 18.6453617 },
    { lat: 49.3948683, lng: 18.6447441 },
    { lat: 49.3947305, lng: 18.6439705 },
    { lat: 49.3947028, lng: 18.6430623 },
    { lat: 49.3945438, lng: 18.6424018 },
    { lat: 49.3941618, lng: 18.6412781 },
    { lat: 49.3942658, lng: 18.6410458 },
    { lat: 49.3944371, lng: 18.6403413 },
    { lat: 49.3946093, lng: 18.6396972 },
    { lat: 49.3945745, lng: 18.6396773 },
    { lat: 49.3945075, lng: 18.6392649 },
    { lat: 49.3943623, lng: 18.6384593 },
    { lat: 49.3946129, lng: 18.6380187 },
    { lat: 49.3945823, lng: 18.6378882 },
    { lat: 49.3943951, lng: 18.6371009 },
    { lat: 49.3942452, lng: 18.6366315 },
    { lat: 49.3941299, lng: 18.6360966 },
    { lat: 49.3940391, lng: 18.6355956 },
    { lat: 49.3938713, lng: 18.6353637 },
    { lat: 49.3935298, lng: 18.6350061 },
    { lat: 49.393314, lng: 18.6348263 },
    { lat: 49.3929156, lng: 18.6344834 },
    { lat: 49.3926394, lng: 18.6342701 },
    { lat: 49.3924944, lng: 18.6341292 },
    { lat: 49.3923757, lng: 18.633976 },
    { lat: 49.3922923, lng: 18.6338915 },
    { lat: 49.3922221, lng: 18.6338419 },
    { lat: 49.392075, lng: 18.6337509 },
    { lat: 49.3919343, lng: 18.6340077 },
    { lat: 49.3918495, lng: 18.6345573 },
    { lat: 49.3916377, lng: 18.6347563 },
    { lat: 49.3912113, lng: 18.6349414 },
    { lat: 49.3910544, lng: 18.6351507 },
    { lat: 49.3910011, lng: 18.6354244 },
    { lat: 49.390998, lng: 18.6356428 },
    { lat: 49.3910423, lng: 18.635882 },
    { lat: 49.391094, lng: 18.6362191 },
    { lat: 49.3910827, lng: 18.6364127 },
    { lat: 49.3910382, lng: 18.6366789 },
    { lat: 49.3909516, lng: 18.6367472 },
    { lat: 49.3908791, lng: 18.6368877 },
    { lat: 49.3908139, lng: 18.6371919 },
    { lat: 49.3907581, lng: 18.6374801 },
    { lat: 49.3906971, lng: 18.637752 },
    { lat: 49.3906696, lng: 18.6376385 },
    { lat: 49.3901231, lng: 18.6373722 },
    { lat: 49.3900811, lng: 18.6373242 },
    { lat: 49.3900104, lng: 18.6372429 },
    { lat: 49.3899644, lng: 18.6371653 },
    { lat: 49.3896367, lng: 18.6368926 },
    { lat: 49.3893205, lng: 18.6366292 },
    { lat: 49.3892914, lng: 18.6366162 },
    { lat: 49.3892787, lng: 18.636608 },
    { lat: 49.3889692, lng: 18.6363869 },
    { lat: 49.3889443, lng: 18.6363835 },
    { lat: 49.3887177, lng: 18.6362368 },
    { lat: 49.3885483, lng: 18.636104 },
    { lat: 49.3881108, lng: 18.6358254 },
    { lat: 49.3879345, lng: 18.6357141 },
    { lat: 49.3877724, lng: 18.6356112 },
    { lat: 49.3876671, lng: 18.6354968 },
    { lat: 49.3876362, lng: 18.6354677 },
    { lat: 49.3873756, lng: 18.6355533 },
    { lat: 49.3873368, lng: 18.6355657 },
    { lat: 49.3871254, lng: 18.6356387 },
    { lat: 49.3868034, lng: 18.6357341 },
    { lat: 49.3863273, lng: 18.6358161 },
    { lat: 49.3860007, lng: 18.6358679 },
    { lat: 49.3855186, lng: 18.6359619 },
    { lat: 49.3854869, lng: 18.6359604 },
    { lat: 49.385098, lng: 18.6361322 },
    { lat: 49.3847873, lng: 18.6361628 },
    { lat: 49.3844128, lng: 18.6360972 },
    { lat: 49.3843949, lng: 18.6361421 },
    { lat: 49.3841932, lng: 18.6365961 },
    { lat: 49.3838621, lng: 18.6363046 },
    { lat: 49.3837073, lng: 18.6360013 },
    { lat: 49.3834711, lng: 18.6356503 },
    { lat: 49.3831511, lng: 18.6351008 },
    { lat: 49.3825849, lng: 18.6345037 },
    { lat: 49.3820948, lng: 18.6341296 },
    { lat: 49.3816224, lng: 18.6337058 },
    { lat: 49.3816208, lng: 18.6334842 },
    { lat: 49.3817401, lng: 18.6332239 },
    { lat: 49.3815528, lng: 18.6331372 },
    { lat: 49.3810296, lng: 18.6332247 },
    { lat: 49.3812818, lng: 18.632299 },
    { lat: 49.3811023, lng: 18.6322341 },
    { lat: 49.3807609, lng: 18.6319186 },
    { lat: 49.3806786, lng: 18.6318325 },
    { lat: 49.3802029, lng: 18.631752 },
    { lat: 49.3798225, lng: 18.6315586 },
    { lat: 49.3795786, lng: 18.631231 },
    { lat: 49.3792522, lng: 18.6311362 },
    { lat: 49.3789539, lng: 18.6309529 },
    { lat: 49.3786501, lng: 18.6307761 },
    { lat: 49.3782633, lng: 18.6303316 },
    { lat: 49.3778402, lng: 18.6303705 },
    { lat: 49.3775314, lng: 18.6302004 },
    { lat: 49.377425, lng: 18.6301297 },
    { lat: 49.3765368, lng: 18.6297957 },
    { lat: 49.3763809, lng: 18.6297927 },
    { lat: 49.3755792, lng: 18.6299409 },
    { lat: 49.3752325, lng: 18.6300608 },
    { lat: 49.3750935, lng: 18.6299482 },
    { lat: 49.3749927, lng: 18.6298803 },
    { lat: 49.3749134, lng: 18.6301497 },
    { lat: 49.3745641, lng: 18.6302156 },
    { lat: 49.3742645, lng: 18.6302474 },
    { lat: 49.3740108, lng: 18.6302895 },
    { lat: 49.3738188, lng: 18.6303448 },
    { lat: 49.3735179, lng: 18.6305219 },
    { lat: 49.3731437, lng: 18.6307143 },
    { lat: 49.3728511, lng: 18.630813 },
    { lat: 49.3725634, lng: 18.6307026 },
    { lat: 49.3719772, lng: 18.6309814 },
    { lat: 49.3714147, lng: 18.6311794 },
    { lat: 49.3706271, lng: 18.6315101 },
    { lat: 49.3701453, lng: 18.631591 },
    { lat: 49.369875, lng: 18.6317657 },
    { lat: 49.3695578, lng: 18.6318656 },
    { lat: 49.369222, lng: 18.6319989 },
    { lat: 49.3688042, lng: 18.6323526 },
    { lat: 49.3683779, lng: 18.6324982 },
    { lat: 49.3679944, lng: 18.6326469 },
    { lat: 49.3676384, lng: 18.6328851 },
    { lat: 49.3673356, lng: 18.6330034 },
    { lat: 49.3670062, lng: 18.6332756 },
    { lat: 49.3666139, lng: 18.6335566 },
    { lat: 49.366343, lng: 18.6337419 },
    { lat: 49.3659402, lng: 18.634037 },
    { lat: 49.3656626, lng: 18.6342039 },
    { lat: 49.3652693, lng: 18.6341442 },
    { lat: 49.3649741, lng: 18.6340843 },
    { lat: 49.3647324, lng: 18.6339522 },
    { lat: 49.3644037, lng: 18.6337688 },
    { lat: 49.3641324, lng: 18.6335239 },
    { lat: 49.3639081, lng: 18.6332507 },
    { lat: 49.363897, lng: 18.633266 },
  ],
  Dunajov: [
    { lat: 49.3832532, lng: 18.7868936 },
    { lat: 49.3832798, lng: 18.7868611 },
    { lat: 49.3834559, lng: 18.7861445 },
    { lat: 49.3840181, lng: 18.7832806 },
    { lat: 49.3840421, lng: 18.7823925 },
    { lat: 49.3839239, lng: 18.7818524 },
    { lat: 49.3837514, lng: 18.7810887 },
    { lat: 49.383531, lng: 18.7804039 },
    { lat: 49.3830607, lng: 18.7789399 },
    { lat: 49.3827244, lng: 18.778368 },
    { lat: 49.3825565, lng: 18.7781738 },
    { lat: 49.3812478, lng: 18.7761339 },
    { lat: 49.380726, lng: 18.774706 },
    { lat: 49.378664, lng: 18.77504 },
    { lat: 49.377949, lng: 18.775076 },
    { lat: 49.377313, lng: 18.775654 },
    { lat: 49.376601, lng: 18.7756 },
    { lat: 49.376439, lng: 18.775771 },
    { lat: 49.376219, lng: 18.775876 },
    { lat: 49.37598, lng: 18.776224 },
    { lat: 49.375092, lng: 18.776092 },
    { lat: 49.375523, lng: 18.778246 },
    { lat: 49.375381, lng: 18.779537 },
    { lat: 49.374927, lng: 18.779929 },
    { lat: 49.374557, lng: 18.780382 },
    { lat: 49.373976, lng: 18.780508 },
    { lat: 49.373133, lng: 18.780465 },
    { lat: 49.372365, lng: 18.780297 },
    { lat: 49.371862, lng: 18.780661 },
    { lat: 49.37052, lng: 18.782119 },
    { lat: 49.370369, lng: 18.783252 },
    { lat: 49.369089, lng: 18.78501 },
    { lat: 49.36888, lng: 18.785643 },
    { lat: 49.368826, lng: 18.786922 },
    { lat: 49.368461, lng: 18.789359 },
    { lat: 49.368254, lng: 18.789971 },
    { lat: 49.368021, lng: 18.790877 },
    { lat: 49.367797, lng: 18.791478 },
    { lat: 49.3674, lng: 18.79173 },
    { lat: 49.366443, lng: 18.792535 },
    { lat: 49.366716, lng: 18.792749 },
    { lat: 49.366838, lng: 18.792952 },
    { lat: 49.367386, lng: 18.79334 },
    { lat: 49.368001, lng: 18.793975 },
    { lat: 49.367524, lng: 18.794454 },
    { lat: 49.367203, lng: 18.796 },
    { lat: 49.366691, lng: 18.795975 },
    { lat: 49.366726, lng: 18.795843 },
    { lat: 49.366393, lng: 18.795814 },
    { lat: 49.366244, lng: 18.79609 },
    { lat: 49.366377, lng: 18.796291 },
    { lat: 49.366207, lng: 18.796754 },
    { lat: 49.365965, lng: 18.797013 },
    { lat: 49.365738, lng: 18.797539 },
    { lat: 49.365601, lng: 18.797339 },
    { lat: 49.365505, lng: 18.797613 },
    { lat: 49.365218, lng: 18.797821 },
    { lat: 49.364612, lng: 18.797339 },
    { lat: 49.364176, lng: 18.797538 },
    { lat: 49.364159, lng: 18.797634 },
    { lat: 49.36379, lng: 18.797634 },
    { lat: 49.363789, lng: 18.798578 },
    { lat: 49.363582, lng: 18.798628 },
    { lat: 49.363053, lng: 18.798175 },
    { lat: 49.36146, lng: 18.797877 },
    { lat: 49.359421, lng: 18.79905 },
    { lat: 49.358835, lng: 18.799486 },
    { lat: 49.35853, lng: 18.799623 },
    { lat: 49.358123, lng: 18.800037 },
    { lat: 49.357727, lng: 18.800203 },
    { lat: 49.357811, lng: 18.800827 },
    { lat: 49.35723, lng: 18.801111 },
    { lat: 49.357213, lng: 18.800983 },
    { lat: 49.356585, lng: 18.80198 },
    { lat: 49.35648, lng: 18.80188 },
    { lat: 49.356192, lng: 18.802827 },
    { lat: 49.355808, lng: 18.803804 },
    { lat: 49.354731, lng: 18.804148 },
    { lat: 49.354382, lng: 18.806184 },
    { lat: 49.354291, lng: 18.806991 },
    { lat: 49.354154, lng: 18.807492 },
    { lat: 49.354885, lng: 18.807932 },
    { lat: 49.355314, lng: 18.808955 },
    { lat: 49.355929, lng: 18.810488 },
    { lat: 49.357053, lng: 18.8145 },
    { lat: 49.357866, lng: 18.818248 },
    { lat: 49.357852, lng: 18.818755 },
    { lat: 49.358078, lng: 18.820312 },
    { lat: 49.358474, lng: 18.825509 },
    { lat: 49.35884, lng: 18.827036 },
    { lat: 49.35913, lng: 18.827866 },
    { lat: 49.35848, lng: 18.828552 },
    { lat: 49.358078, lng: 18.829115 },
    { lat: 49.357881, lng: 18.8293 },
    { lat: 49.357577, lng: 18.829459 },
    { lat: 49.35724, lng: 18.829839 },
    { lat: 49.356846, lng: 18.829969 },
    { lat: 49.355806, lng: 18.830563 },
    { lat: 49.354744, lng: 18.830894 },
    { lat: 49.354866, lng: 18.832545 },
    { lat: 49.356273, lng: 18.832837 },
    { lat: 49.356255, lng: 18.833717 },
    { lat: 49.356691, lng: 18.834566 },
    { lat: 49.357124, lng: 18.835195 },
    { lat: 49.358311, lng: 18.837673 },
    { lat: 49.358393, lng: 18.838188 },
    { lat: 49.358391, lng: 18.838949 },
    { lat: 49.358342, lng: 18.83953 },
    { lat: 49.358269, lng: 18.839832 },
    { lat: 49.35829, lng: 18.840542 },
    { lat: 49.358033, lng: 18.842278 },
    { lat: 49.358, lng: 18.842761 },
    { lat: 49.358496, lng: 18.843248 },
    { lat: 49.358975, lng: 18.843514 },
    { lat: 49.3593385, lng: 18.8441192 },
    { lat: 49.359396, lng: 18.844215 },
    { lat: 49.3595305, lng: 18.8441118 },
    { lat: 49.3597047, lng: 18.8440348 },
    { lat: 49.3598845, lng: 18.8439799 },
    { lat: 49.3600453, lng: 18.843859 },
    { lat: 49.3601695, lng: 18.8437099 },
    { lat: 49.3602711, lng: 18.8435121 },
    { lat: 49.3603928, lng: 18.8431384 },
    { lat: 49.3605527, lng: 18.8426482 },
    { lat: 49.3605892, lng: 18.8425259 },
    { lat: 49.3605931, lng: 18.8423815 },
    { lat: 49.3605821, lng: 18.8423228 },
    { lat: 49.3605726, lng: 18.8422671 },
    { lat: 49.3605782, lng: 18.8421767 },
    { lat: 49.360605, lng: 18.8421297 },
    { lat: 49.3609264, lng: 18.8415594 },
    { lat: 49.3611102, lng: 18.8412082 },
    { lat: 49.3611384, lng: 18.8409426 },
    { lat: 49.3611868, lng: 18.8408084 },
    { lat: 49.3613728, lng: 18.8405238 },
    { lat: 49.3614496, lng: 18.8403717 },
    { lat: 49.3614835, lng: 18.8402215 },
    { lat: 49.3614907, lng: 18.8399196 },
    { lat: 49.3615875, lng: 18.8396733 },
    { lat: 49.3617103, lng: 18.8391235 },
    { lat: 49.3618534, lng: 18.8384824 },
    { lat: 49.3618767, lng: 18.8382133 },
    { lat: 49.3620803, lng: 18.8380033 },
    { lat: 49.3621772, lng: 18.8380242 },
    { lat: 49.3623407, lng: 18.8378295 },
    { lat: 49.3624327, lng: 18.8376665 },
    { lat: 49.3626508, lng: 18.8375426 },
    { lat: 49.3628759, lng: 18.8373807 },
    { lat: 49.3631538, lng: 18.8372843 },
    { lat: 49.3633143, lng: 18.8370469 },
    { lat: 49.3636624, lng: 18.8368676 },
    { lat: 49.3637403, lng: 18.836761 },
    { lat: 49.3638035, lng: 18.8366296 },
    { lat: 49.3639859, lng: 18.836603 },
    { lat: 49.3640711, lng: 18.8365501 },
    { lat: 49.3641118, lng: 18.8364164 },
    { lat: 49.3642043, lng: 18.8363087 },
    { lat: 49.364406, lng: 18.8361969 },
    { lat: 49.3645727, lng: 18.8361038 },
    { lat: 49.3648697, lng: 18.8359386 },
    { lat: 49.3648762, lng: 18.835935 },
    { lat: 49.3649475, lng: 18.8358931 },
    { lat: 49.3648898, lng: 18.8357347 },
    { lat: 49.3649041, lng: 18.8357279 },
    { lat: 49.3649811, lng: 18.8356492 },
    { lat: 49.3649973, lng: 18.8356332 },
    { lat: 49.3650026, lng: 18.8356087 },
    { lat: 49.3650184, lng: 18.8355377 },
    { lat: 49.3650534, lng: 18.8353454 },
    { lat: 49.3651216, lng: 18.8352022 },
    { lat: 49.3652228, lng: 18.8349951 },
    { lat: 49.3653276, lng: 18.834777 },
    { lat: 49.3653854, lng: 18.8347795 },
    { lat: 49.3654316, lng: 18.8347605 },
    { lat: 49.3654885, lng: 18.834703 },
    { lat: 49.3655286, lng: 18.8345647 },
    { lat: 49.3655609, lng: 18.8343148 },
    { lat: 49.3657356, lng: 18.8340033 },
    { lat: 49.3657871, lng: 18.8339114 },
    { lat: 49.3659049, lng: 18.8337015 },
    { lat: 49.3659255, lng: 18.8336383 },
    { lat: 49.3659526, lng: 18.8335529 },
    { lat: 49.3659925, lng: 18.8334284 },
    { lat: 49.3659992, lng: 18.8334077 },
    { lat: 49.3660061, lng: 18.8333859 },
    { lat: 49.3660118, lng: 18.8333682 },
    { lat: 49.3660527, lng: 18.8332392 },
    { lat: 49.3660619, lng: 18.8332023 },
    { lat: 49.3661065, lng: 18.8330167 },
    { lat: 49.366153, lng: 18.8328216 },
    { lat: 49.3655902, lng: 18.8322233 },
    { lat: 49.3655857, lng: 18.8322036 },
    { lat: 49.3653803, lng: 18.8313007 },
    { lat: 49.365363, lng: 18.831225 },
    { lat: 49.3653473, lng: 18.8311582 },
    { lat: 49.3653226, lng: 18.8310494 },
    { lat: 49.3653077, lng: 18.8309803 },
    { lat: 49.3652904, lng: 18.8309067 },
    { lat: 49.3652854, lng: 18.8309008 },
    { lat: 49.3645165, lng: 18.8299953 },
    { lat: 49.3645421, lng: 18.8298837 },
    { lat: 49.3645432, lng: 18.829771 },
    { lat: 49.3645117, lng: 18.8297285 },
    { lat: 49.3645169, lng: 18.8297189 },
    { lat: 49.3645187, lng: 18.8297125 },
    { lat: 49.3645544, lng: 18.829617 },
    { lat: 49.3645812, lng: 18.8295447 },
    { lat: 49.3645998, lng: 18.8294926 },
    { lat: 49.3646365, lng: 18.8293937 },
    { lat: 49.3646444, lng: 18.8293725 },
    { lat: 49.3646951, lng: 18.8292821 },
    { lat: 49.3647265, lng: 18.8292599 },
    { lat: 49.364744, lng: 18.8292223 },
    { lat: 49.364794, lng: 18.8291429 },
    { lat: 49.3648303, lng: 18.8291049 },
    { lat: 49.3649036, lng: 18.8289955 },
    { lat: 49.3649611, lng: 18.8289093 },
    { lat: 49.3650191, lng: 18.828787 },
    { lat: 49.3650761, lng: 18.8286235 },
    { lat: 49.3650918, lng: 18.8284859 },
    { lat: 49.3650923, lng: 18.8283669 },
    { lat: 49.3650762, lng: 18.8281828 },
    { lat: 49.3650769, lng: 18.8280674 },
    { lat: 49.3650807, lng: 18.8279859 },
    { lat: 49.3650999, lng: 18.8278761 },
    { lat: 49.3651127, lng: 18.8277986 },
    { lat: 49.3651505, lng: 18.8276968 },
    { lat: 49.3651889, lng: 18.8275894 },
    { lat: 49.3652555, lng: 18.8272623 },
    { lat: 49.3652877, lng: 18.8271846 },
    { lat: 49.3653399, lng: 18.827043 },
    { lat: 49.3654379, lng: 18.8268627 },
    { lat: 49.3654849, lng: 18.8267886 },
    { lat: 49.365507, lng: 18.8267353 },
    { lat: 49.3655267, lng: 18.8267048 },
    { lat: 49.3655815, lng: 18.8266725 },
    { lat: 49.3656025, lng: 18.8266663 },
    { lat: 49.3656807, lng: 18.8266066 },
    { lat: 49.3657575, lng: 18.8265026 },
    { lat: 49.3657932, lng: 18.8264278 },
    { lat: 49.3658231, lng: 18.8263706 },
    { lat: 49.3658381, lng: 18.8263561 },
    { lat: 49.3658503, lng: 18.826354 },
    { lat: 49.3659251, lng: 18.8263381 },
    { lat: 49.3660458, lng: 18.8263192 },
    { lat: 49.3661204, lng: 18.8261655 },
    { lat: 49.3662172, lng: 18.8260768 },
    { lat: 49.366232, lng: 18.8260566 },
    { lat: 49.3662465, lng: 18.8260317 },
    { lat: 49.3662607, lng: 18.8259992 },
    { lat: 49.3662693, lng: 18.8259512 },
    { lat: 49.366281, lng: 18.8257598 },
    { lat: 49.3662963, lng: 18.8257088 },
    { lat: 49.3663206, lng: 18.8256755 },
    { lat: 49.3663841, lng: 18.825615 },
    { lat: 49.3664277, lng: 18.8255848 },
    { lat: 49.3664485, lng: 18.825553 },
    { lat: 49.3664635, lng: 18.825473 },
    { lat: 49.3664492, lng: 18.8253272 },
    { lat: 49.3664703, lng: 18.825122 },
    { lat: 49.3664723, lng: 18.8250968 },
    { lat: 49.3664815, lng: 18.8249677 },
    { lat: 49.3664708, lng: 18.8247993 },
    { lat: 49.3664565, lng: 18.8246328 },
    { lat: 49.3664402, lng: 18.8244716 },
    { lat: 49.3664621, lng: 18.824368 },
    { lat: 49.3665949, lng: 18.8242305 },
    { lat: 49.3666863, lng: 18.8241012 },
    { lat: 49.3666979, lng: 18.8240658 },
    { lat: 49.3667049, lng: 18.8240269 },
    { lat: 49.3667088, lng: 18.8239686 },
    { lat: 49.3666574, lng: 18.8236025 },
    { lat: 49.3666628, lng: 18.8234915 },
    { lat: 49.3666886, lng: 18.8234012 },
    { lat: 49.366721, lng: 18.8233055 },
    { lat: 49.366791, lng: 18.823021 },
    { lat: 49.3668097, lng: 18.8229941 },
    { lat: 49.3670037, lng: 18.8226651 },
    { lat: 49.36712, lng: 18.8224723 },
    { lat: 49.3671707, lng: 18.8224062 },
    { lat: 49.3672888, lng: 18.8222903 },
    { lat: 49.367427, lng: 18.8221246 },
    { lat: 49.367494, lng: 18.8220261 },
    { lat: 49.367539, lng: 18.821936 },
    { lat: 49.3676449, lng: 18.8217163 },
    { lat: 49.36781, lng: 18.8214624 },
    { lat: 49.3679303, lng: 18.8211689 },
    { lat: 49.3680019, lng: 18.8209631 },
    { lat: 49.368182, lng: 18.820559 },
    { lat: 49.3683304, lng: 18.8202025 },
    { lat: 49.3683936, lng: 18.8200057 },
    { lat: 49.3684059, lng: 18.8198052 },
    { lat: 49.3684046, lng: 18.8197609 },
    { lat: 49.3684151, lng: 18.8197444 },
    { lat: 49.3684304, lng: 18.8194503 },
    { lat: 49.368447, lng: 18.8191563 },
    { lat: 49.3685298, lng: 18.8189466 },
    { lat: 49.368542, lng: 18.8185505 },
    { lat: 49.3686917, lng: 18.8176659 },
    { lat: 49.3687199, lng: 18.8173196 },
    { lat: 49.3687421, lng: 18.8172456 },
    { lat: 49.3687555, lng: 18.8171988 },
    { lat: 49.3687651, lng: 18.8171633 },
    { lat: 49.3688136, lng: 18.8170782 },
    { lat: 49.3688197, lng: 18.8169121 },
    { lat: 49.3688441, lng: 18.816445 },
    { lat: 49.3689589, lng: 18.8159333 },
    { lat: 49.3690216, lng: 18.8157756 },
    { lat: 49.3690792, lng: 18.8157336 },
    { lat: 49.3692074, lng: 18.8156396 },
    { lat: 49.369251, lng: 18.8155631 },
    { lat: 49.3692816, lng: 18.8154566 },
    { lat: 49.3693148, lng: 18.8152048 },
    { lat: 49.3694072, lng: 18.8150276 },
    { lat: 49.3695566, lng: 18.8149083 },
    { lat: 49.369597, lng: 18.8149038 },
    { lat: 49.3696718, lng: 18.814847 },
    { lat: 49.3697267, lng: 18.8147716 },
    { lat: 49.3696816, lng: 18.8144685 },
    { lat: 49.3696964, lng: 18.814361 },
    { lat: 49.3698973, lng: 18.8141016 },
    { lat: 49.370011, lng: 18.8140517 },
    { lat: 49.3701488, lng: 18.814191 },
    { lat: 49.3702273, lng: 18.8140502 },
    { lat: 49.3702584, lng: 18.8137353 },
    { lat: 49.3706591, lng: 18.8133498 },
    { lat: 49.3707699, lng: 18.8132221 },
    { lat: 49.3708074, lng: 18.8129205 },
    { lat: 49.3708994, lng: 18.8127817 },
    { lat: 49.3710676, lng: 18.8126337 },
    { lat: 49.3712125, lng: 18.8126672 },
    { lat: 49.3712587, lng: 18.81262 },
    { lat: 49.3713056, lng: 18.8123503 },
    { lat: 49.3713944, lng: 18.8121702 },
    { lat: 49.3714451, lng: 18.8120353 },
    { lat: 49.3714891, lng: 18.8119956 },
    { lat: 49.3716031, lng: 18.8119743 },
    { lat: 49.371647, lng: 18.8118188 },
    { lat: 49.3716485, lng: 18.81165 },
    { lat: 49.3716647, lng: 18.8115696 },
    { lat: 49.3717395, lng: 18.81149 },
    { lat: 49.3717931, lng: 18.8113927 },
    { lat: 49.3718105, lng: 18.8112488 },
    { lat: 49.3718099, lng: 18.8111957 },
    { lat: 49.3718651, lng: 18.8109744 },
    { lat: 49.3718876, lng: 18.8109294 },
    { lat: 49.3718992, lng: 18.8108697 },
    { lat: 49.3718922, lng: 18.8107993 },
    { lat: 49.3718687, lng: 18.8107367 },
    { lat: 49.3717998, lng: 18.8106053 },
    { lat: 49.3717665, lng: 18.8105044 },
    { lat: 49.371751, lng: 18.8103775 },
    { lat: 49.371709, lng: 18.8100262 },
    { lat: 49.371732, lng: 18.8099221 },
    { lat: 49.3718203, lng: 18.8097776 },
    { lat: 49.3718378, lng: 18.8096781 },
    { lat: 49.3718263, lng: 18.8096093 },
    { lat: 49.3717829, lng: 18.8095342 },
    { lat: 49.3717554, lng: 18.8095227 },
    { lat: 49.3717103, lng: 18.8094598 },
    { lat: 49.3716746, lng: 18.8093765 },
    { lat: 49.3716763, lng: 18.8091242 },
    { lat: 49.3717075, lng: 18.8090506 },
    { lat: 49.3718572, lng: 18.8089815 },
    { lat: 49.3718846, lng: 18.808921 },
    { lat: 49.3719021, lng: 18.8088252 },
    { lat: 49.3719144, lng: 18.8087155 },
    { lat: 49.3716854, lng: 18.8084476 },
    { lat: 49.3716773, lng: 18.8082025 },
    { lat: 49.3716996, lng: 18.8081126 },
    { lat: 49.3717993, lng: 18.808076 },
    { lat: 49.3718153, lng: 18.8080567 },
    { lat: 49.3718148, lng: 18.8079605 },
    { lat: 49.3717041, lng: 18.8076736 },
    { lat: 49.371701, lng: 18.8075491 },
    { lat: 49.3717599, lng: 18.8074226 },
    { lat: 49.3718722, lng: 18.8073269 },
    { lat: 49.3719025, lng: 18.8072136 },
    { lat: 49.3718777, lng: 18.8071027 },
    { lat: 49.3718323, lng: 18.8069649 },
    { lat: 49.3718009, lng: 18.8067726 },
    { lat: 49.3718885, lng: 18.8065246 },
    { lat: 49.3719048, lng: 18.806336 },
    { lat: 49.3719334, lng: 18.8059971 },
    { lat: 49.3720054, lng: 18.8059273 },
    { lat: 49.3720193, lng: 18.8059122 },
    { lat: 49.3720416, lng: 18.8058871 },
    { lat: 49.3720962, lng: 18.8058504 },
    { lat: 49.3722092, lng: 18.8056823 },
    { lat: 49.3722251, lng: 18.8056598 },
    { lat: 49.3722249, lng: 18.8056569 },
    { lat: 49.3722431, lng: 18.8054644 },
    { lat: 49.3722163, lng: 18.8053804 },
    { lat: 49.3722596, lng: 18.8051707 },
    { lat: 49.3723168, lng: 18.8051649 },
    { lat: 49.3723684, lng: 18.8051583 },
    { lat: 49.3723705, lng: 18.8051581 },
    { lat: 49.3723704, lng: 18.8051556 },
    { lat: 49.3723878, lng: 18.805012 },
    { lat: 49.3724478, lng: 18.8048426 },
    { lat: 49.3724592, lng: 18.8046929 },
    { lat: 49.3724856, lng: 18.8046127 },
    { lat: 49.3726066, lng: 18.8045079 },
    { lat: 49.3726579, lng: 18.8043668 },
    { lat: 49.3728297, lng: 18.8041564 },
    { lat: 49.3728308, lng: 18.8041559 },
    { lat: 49.3728728, lng: 18.8040072 },
    { lat: 49.3728765, lng: 18.8038407 },
    { lat: 49.3729675, lng: 18.8038315 },
    { lat: 49.3730356, lng: 18.8037777 },
    { lat: 49.3730703, lng: 18.8036168 },
    { lat: 49.3731797, lng: 18.8035312 },
    { lat: 49.3732595, lng: 18.8033729 },
    { lat: 49.3732659, lng: 18.8033005 },
    { lat: 49.3732945, lng: 18.8032456 },
    { lat: 49.373384, lng: 18.8032301 },
    { lat: 49.3735388, lng: 18.8030167 },
    { lat: 49.3735616, lng: 18.8028265 },
    { lat: 49.3736421, lng: 18.8027463 },
    { lat: 49.3737679, lng: 18.8026703 },
    { lat: 49.3737903, lng: 18.8023412 },
    { lat: 49.3739083, lng: 18.8023342 },
    { lat: 49.3739394, lng: 18.8022356 },
    { lat: 49.3739684, lng: 18.802188 },
    { lat: 49.37401, lng: 18.8021844 },
    { lat: 49.3741125, lng: 18.8021836 },
    { lat: 49.3741711, lng: 18.8020278 },
    { lat: 49.3742849, lng: 18.8019619 },
    { lat: 49.3743458, lng: 18.801833 },
    { lat: 49.3744073, lng: 18.8017564 },
    { lat: 49.374512, lng: 18.8017977 },
    { lat: 49.3745696, lng: 18.8017733 },
    { lat: 49.3746149, lng: 18.801694 },
    { lat: 49.3747379, lng: 18.8016933 },
    { lat: 49.3748543, lng: 18.8015681 },
    { lat: 49.3749464, lng: 18.8014303 },
    { lat: 49.3750318, lng: 18.8014461 },
    { lat: 49.3750798, lng: 18.8013726 },
    { lat: 49.3751667, lng: 18.8013306 },
    { lat: 49.3751989, lng: 18.8012127 },
    { lat: 49.3752861, lng: 18.80098 },
    { lat: 49.3754475, lng: 18.8010249 },
    { lat: 49.3755458, lng: 18.8009197 },
    { lat: 49.3756375, lng: 18.8009495 },
    { lat: 49.3758042, lng: 18.8008367 },
    { lat: 49.3758919, lng: 18.8007877 },
    { lat: 49.3759762, lng: 18.8006943 },
    { lat: 49.3760809, lng: 18.8006042 },
    { lat: 49.3761637, lng: 18.8005287 },
    { lat: 49.3762967, lng: 18.8004583 },
    { lat: 49.376371, lng: 18.8003694 },
    { lat: 49.3764602, lng: 18.8001523 },
    { lat: 49.376551, lng: 18.7999919 },
    { lat: 49.3765856, lng: 18.7998307 },
    { lat: 49.3766345, lng: 18.7996884 },
    { lat: 49.3767423, lng: 18.7995693 },
    { lat: 49.3767506, lng: 18.7995343 },
    { lat: 49.3767656, lng: 18.7994728 },
    { lat: 49.3768055, lng: 18.7992649 },
    { lat: 49.3768524, lng: 18.7991435 },
    { lat: 49.376826, lng: 18.7989838 },
    { lat: 49.3768693, lng: 18.7988624 },
    { lat: 49.3769068, lng: 18.7986679 },
    { lat: 49.3769618, lng: 18.7985343 },
    { lat: 49.3769969, lng: 18.7983809 },
    { lat: 49.3770073, lng: 18.7982342 },
    { lat: 49.3770411, lng: 18.7981491 },
    { lat: 49.3770796, lng: 18.7979103 },
    { lat: 49.3770922, lng: 18.7976729 },
    { lat: 49.3770772, lng: 18.7975799 },
    { lat: 49.3770513, lng: 18.7974075 },
    { lat: 49.3770589, lng: 18.7972698 },
    { lat: 49.3770777, lng: 18.7970631 },
    { lat: 49.3770864, lng: 18.796884 },
    { lat: 49.3770446, lng: 18.7966173 },
    { lat: 49.3770407, lng: 18.7964376 },
    { lat: 49.377053, lng: 18.7963861 },
    { lat: 49.3770864, lng: 18.7962508 },
    { lat: 49.3771132, lng: 18.7961339 },
    { lat: 49.3771358, lng: 18.7960052 },
    { lat: 49.3770813, lng: 18.7958226 },
    { lat: 49.3770713, lng: 18.7955191 },
    { lat: 49.3770424, lng: 18.7953305 },
    { lat: 49.3770219, lng: 18.7951976 },
    { lat: 49.3770649, lng: 18.7950705 },
    { lat: 49.377104, lng: 18.7947979 },
    { lat: 49.3771636, lng: 18.7945559 },
    { lat: 49.3771983, lng: 18.7944406 },
    { lat: 49.3773124, lng: 18.7941384 },
    { lat: 49.3773275, lng: 18.7939734 },
    { lat: 49.3773631, lng: 18.7937892 },
    { lat: 49.3773884, lng: 18.7936714 },
    { lat: 49.3774387, lng: 18.7934634 },
    { lat: 49.377451, lng: 18.7933552 },
    { lat: 49.3774469, lng: 18.7932746 },
    { lat: 49.3775133, lng: 18.7931389 },
    { lat: 49.3775337, lng: 18.792988 },
    { lat: 49.3775567, lng: 18.7929132 },
    { lat: 49.3775884, lng: 18.7928486 },
    { lat: 49.3776067, lng: 18.7928091 },
    { lat: 49.3776519, lng: 18.792702 },
    { lat: 49.3777213, lng: 18.7926263 },
    { lat: 49.3777565, lng: 18.7925438 },
    { lat: 49.3778042, lng: 18.7924624 },
    { lat: 49.3778684, lng: 18.7923743 },
    { lat: 49.3779296, lng: 18.7922269 },
    { lat: 49.3779442, lng: 18.7921809 },
    { lat: 49.3779774, lng: 18.7921056 },
    { lat: 49.3780713, lng: 18.7918916 },
    { lat: 49.3781932, lng: 18.7917231 },
    { lat: 49.3782523, lng: 18.7915762 },
    { lat: 49.3783239, lng: 18.7914572 },
    { lat: 49.3783321, lng: 18.7914435 },
    { lat: 49.3784144, lng: 18.7913532 },
    { lat: 49.3786012, lng: 18.7911493 },
    { lat: 49.3787449, lng: 18.7909853 },
    { lat: 49.3787815, lng: 18.7908629 },
    { lat: 49.3788114, lng: 18.7907862 },
    { lat: 49.3788711, lng: 18.7907211 },
    { lat: 49.3789085, lng: 18.7906351 },
    { lat: 49.3789607, lng: 18.7905579 },
    { lat: 49.3790842, lng: 18.7904839 },
    { lat: 49.3793093, lng: 18.7902117 },
    { lat: 49.3795074, lng: 18.7899666 },
    { lat: 49.3796277, lng: 18.7898952 },
    { lat: 49.3796614, lng: 18.789847 },
    { lat: 49.379864, lng: 18.7896675 },
    { lat: 49.3799181, lng: 18.789598 },
    { lat: 49.3800515, lng: 18.7894552 },
    { lat: 49.3801204, lng: 18.7894352 },
    { lat: 49.3801833, lng: 18.789449 },
    { lat: 49.3802652, lng: 18.7893783 },
    { lat: 49.3803499, lng: 18.7892251 },
    { lat: 49.3805152, lng: 18.7890874 },
    { lat: 49.3807013, lng: 18.7889386 },
    { lat: 49.3808238, lng: 18.7888408 },
    { lat: 49.3809768, lng: 18.7887239 },
    { lat: 49.3810793, lng: 18.7886369 },
    { lat: 49.381265, lng: 18.7885449 },
    { lat: 49.3814031, lng: 18.7884243 },
    { lat: 49.3815274, lng: 18.7883419 },
    { lat: 49.3817392, lng: 18.788229 },
    { lat: 49.3818769, lng: 18.7881012 },
    { lat: 49.382161, lng: 18.7879014 },
    { lat: 49.3823355, lng: 18.7877894 },
    { lat: 49.3824785, lng: 18.7877403 },
    { lat: 49.382609, lng: 18.787605 },
    { lat: 49.3827879, lng: 18.787486 },
    { lat: 49.3829168, lng: 18.7874067 },
    { lat: 49.3830001, lng: 18.7872305 },
    { lat: 49.3830663, lng: 18.7871363 },
    { lat: 49.3831912, lng: 18.7869793 },
    { lat: 49.3832046, lng: 18.7869549 },
    { lat: 49.3832283, lng: 18.7869104 },
    { lat: 49.3832532, lng: 18.7868936 },
  ],
  Klokočov: [
    { lat: 49.510237, lng: 18.5972528 },
    { lat: 49.510176, lng: 18.596258 },
    { lat: 49.509896, lng: 18.594925 },
    { lat: 49.509495, lng: 18.593518 },
    { lat: 49.508986, lng: 18.591995 },
    { lat: 49.509001, lng: 18.591881 },
    { lat: 49.509093, lng: 18.591175 },
    { lat: 49.508965, lng: 18.589796 },
    { lat: 49.508673, lng: 18.588851 },
    { lat: 49.508534, lng: 18.588492 },
    { lat: 49.508184, lng: 18.587953 },
    { lat: 49.508111, lng: 18.587815 },
    { lat: 49.508037, lng: 18.587592 },
    { lat: 49.507993, lng: 18.587151 },
    { lat: 49.50799, lng: 18.586915 },
    { lat: 49.508021, lng: 18.586356 },
    { lat: 49.508036, lng: 18.586138 },
    { lat: 49.508048, lng: 18.585724 },
    { lat: 49.508094, lng: 18.585451 },
    { lat: 49.508099, lng: 18.585206 },
    { lat: 49.508082, lng: 18.585001 },
    { lat: 49.508043, lng: 18.584829 },
    { lat: 49.50798, lng: 18.584657 },
    { lat: 49.507893, lng: 18.584487 },
    { lat: 49.507533, lng: 18.583928 },
    { lat: 49.507334, lng: 18.583593 },
    { lat: 49.507119, lng: 18.583144 },
    { lat: 49.506936, lng: 18.582725 },
    { lat: 49.506651, lng: 18.582105 },
    { lat: 49.506431, lng: 18.581679 },
    { lat: 49.506331, lng: 18.581267 },
    { lat: 49.50628, lng: 18.580865 },
    { lat: 49.506287, lng: 18.580568 },
    { lat: 49.506294, lng: 18.580407 },
    { lat: 49.506324, lng: 18.579999 },
    { lat: 49.506355, lng: 18.579815 },
    { lat: 49.506386, lng: 18.579471 },
    { lat: 49.506376, lng: 18.579082 },
    { lat: 49.50636, lng: 18.578882 },
    { lat: 49.50638, lng: 18.578335 },
    { lat: 49.506413, lng: 18.577742 },
    { lat: 49.50649, lng: 18.577214 },
    { lat: 49.506576, lng: 18.576783 },
    { lat: 49.506663, lng: 18.576564 },
    { lat: 49.506829, lng: 18.576158 },
    { lat: 49.506886, lng: 18.576024 },
    { lat: 49.507019, lng: 18.575776 },
    { lat: 49.507141, lng: 18.575472 },
    { lat: 49.507183, lng: 18.575283 },
    { lat: 49.507343, lng: 18.574834 },
    { lat: 49.507455, lng: 18.574629 },
    { lat: 49.507573, lng: 18.574493 },
    { lat: 49.507661, lng: 18.574356 },
    { lat: 49.507858, lng: 18.57401 },
    { lat: 49.507996, lng: 18.573819 },
    { lat: 49.508112, lng: 18.573629 },
    { lat: 49.508272, lng: 18.573255 },
    { lat: 49.508294, lng: 18.573202 },
    { lat: 49.50832, lng: 18.573089 },
    { lat: 49.508329, lng: 18.572906 },
    { lat: 49.508286, lng: 18.572363 },
    { lat: 49.508176, lng: 18.572068 },
    { lat: 49.508068, lng: 18.571782 },
    { lat: 49.507988, lng: 18.571483 },
    { lat: 49.507914, lng: 18.570909 },
    { lat: 49.507827, lng: 18.570192 },
    { lat: 49.507744, lng: 18.569513 },
    { lat: 49.507688, lng: 18.569098 },
    { lat: 49.507668, lng: 18.568869 },
    { lat: 49.507578, lng: 18.568417 },
    { lat: 49.507468, lng: 18.567856 },
    { lat: 49.507352, lng: 18.567463 },
    { lat: 49.507254, lng: 18.567182 },
    { lat: 49.507135, lng: 18.56675 },
    { lat: 49.507051, lng: 18.566328 },
    { lat: 49.506972, lng: 18.565941 },
    { lat: 49.50692, lng: 18.565538 },
    { lat: 49.506864, lng: 18.565145 },
    { lat: 49.506808, lng: 18.564851 },
    { lat: 49.506725, lng: 18.564604 },
    { lat: 49.506506, lng: 18.56418 },
    { lat: 49.506303, lng: 18.563744 },
    { lat: 49.506075, lng: 18.5633 },
    { lat: 49.505874, lng: 18.562863 },
    { lat: 49.505747, lng: 18.562429 },
    { lat: 49.505574, lng: 18.561874 },
    { lat: 49.505405, lng: 18.561523 },
    { lat: 49.50523, lng: 18.561027 },
    { lat: 49.504984, lng: 18.560433 },
    { lat: 49.504747, lng: 18.559885 },
    { lat: 49.504647, lng: 18.559551 },
    { lat: 49.504547, lng: 18.559357 },
    { lat: 49.504389, lng: 18.558952 },
    { lat: 49.504316, lng: 18.558739 },
    { lat: 49.504168, lng: 18.558312 },
    { lat: 49.504041, lng: 18.558048 },
    { lat: 49.503919, lng: 18.557819 },
    { lat: 49.50377, lng: 18.557636 },
    { lat: 49.503696, lng: 18.557354 },
    { lat: 49.503563, lng: 18.557151 },
    { lat: 49.503381, lng: 18.556662 },
    { lat: 49.503356, lng: 18.556442 },
    { lat: 49.503291, lng: 18.555911 },
    { lat: 49.503236, lng: 18.555254 },
    { lat: 49.503191, lng: 18.554877 },
    { lat: 49.503173, lng: 18.554588 },
    { lat: 49.50318, lng: 18.554198 },
    { lat: 49.503205, lng: 18.553785 },
    { lat: 49.503192, lng: 18.553591 },
    { lat: 49.503172, lng: 18.553319 },
    { lat: 49.503157, lng: 18.553124 },
    { lat: 49.5031, lng: 18.552926 },
    { lat: 49.503127, lng: 18.552907 },
    { lat: 49.502795, lng: 18.55262 },
    { lat: 49.502237, lng: 18.552394 },
    { lat: 49.50141, lng: 18.552113 },
    { lat: 49.501234, lng: 18.551843 },
    { lat: 49.501021, lng: 18.551538 },
    { lat: 49.500898, lng: 18.550883 },
    { lat: 49.500704, lng: 18.549941 },
    { lat: 49.500566, lng: 18.549392 },
    { lat: 49.500434, lng: 18.54865 },
    { lat: 49.500245, lng: 18.547747 },
    { lat: 49.500118, lng: 18.546955 },
    { lat: 49.500263, lng: 18.546585 },
    { lat: 49.500401, lng: 18.54597 },
    { lat: 49.50051, lng: 18.545748 },
    { lat: 49.49995, lng: 18.545294 },
    { lat: 49.4994241, lng: 18.5450617 },
    { lat: 49.499053, lng: 18.544906 },
    { lat: 49.498575, lng: 18.544553 },
    { lat: 49.497983, lng: 18.543887 },
    { lat: 49.497274, lng: 18.543177 },
    { lat: 49.497079, lng: 18.542848 },
    { lat: 49.496753, lng: 18.542828 },
    { lat: 49.496682, lng: 18.542805 },
    { lat: 49.496523, lng: 18.542707 },
    { lat: 49.496411, lng: 18.542633 },
    { lat: 49.496244, lng: 18.542548 },
    { lat: 49.496075, lng: 18.542485 },
    { lat: 49.495837, lng: 18.542434 },
    { lat: 49.495624, lng: 18.542463 },
    { lat: 49.495431, lng: 18.542483 },
    { lat: 49.495245, lng: 18.542476 },
    { lat: 49.495191, lng: 18.542468 },
    { lat: 49.494923, lng: 18.542427 },
    { lat: 49.4947659, lng: 18.5423454 },
    { lat: 49.494615, lng: 18.542268 },
    { lat: 49.494233, lng: 18.542188 },
    { lat: 49.493825, lng: 18.541852 },
    { lat: 49.49359, lng: 18.541779 },
    { lat: 49.493219, lng: 18.541762 },
    { lat: 49.493056, lng: 18.541775 },
    { lat: 49.492805, lng: 18.541736 },
    { lat: 49.492706, lng: 18.541633 },
    { lat: 49.492651, lng: 18.541491 },
    { lat: 49.492575, lng: 18.541206 },
    { lat: 49.492495, lng: 18.540948 },
    { lat: 49.492394, lng: 18.54072 },
    { lat: 49.49224, lng: 18.540397 },
    { lat: 49.49212, lng: 18.540099 },
    { lat: 49.492025, lng: 18.539909 },
    { lat: 49.491876, lng: 18.539348 },
    { lat: 49.491695, lng: 18.538778 },
    { lat: 49.491656, lng: 18.538566 },
    { lat: 49.491543, lng: 18.538031 },
    { lat: 49.49145, lng: 18.537498 },
    { lat: 49.491242, lng: 18.536862 },
    { lat: 49.491107, lng: 18.536342 },
    { lat: 49.4910303, lng: 18.5359957 },
    { lat: 49.4910329, lng: 18.5358226 },
    { lat: 49.4908799, lng: 18.5354809 },
    { lat: 49.490623, lng: 18.53521 },
    { lat: 49.490418, lng: 18.534957 },
    { lat: 49.490188, lng: 18.534666 },
    { lat: 49.490094, lng: 18.53458 },
    { lat: 49.4899651, lng: 18.5345362 },
    { lat: 49.4898393, lng: 18.5345032 },
    { lat: 49.489706, lng: 18.534575 },
    { lat: 49.489533, lng: 18.534708 },
    { lat: 49.48933, lng: 18.534838 },
    { lat: 49.489077, lng: 18.53485 },
    { lat: 49.488868, lng: 18.534753 },
    { lat: 49.488683, lng: 18.534839 },
    { lat: 49.488445, lng: 18.534961 },
    { lat: 49.488332, lng: 18.53497 },
    { lat: 49.488162, lng: 18.534786 },
    { lat: 49.487896, lng: 18.534518 },
    { lat: 49.487727, lng: 18.534394 },
    { lat: 49.487602, lng: 18.534272 },
    { lat: 49.487522, lng: 18.534287 },
    { lat: 49.487317, lng: 18.534294 },
    { lat: 49.487056, lng: 18.53434 },
    { lat: 49.486716, lng: 18.534308 },
    { lat: 49.48601, lng: 18.53422 },
    { lat: 49.485748, lng: 18.534191 },
    { lat: 49.485343, lng: 18.534194 },
    { lat: 49.484901, lng: 18.534239 },
    { lat: 49.484805, lng: 18.534323 },
    { lat: 49.484546, lng: 18.534368 },
    { lat: 49.484365, lng: 18.534483 },
    { lat: 49.483707, lng: 18.534921 },
    { lat: 49.483178, lng: 18.535263 },
    { lat: 49.482862, lng: 18.535571 },
    { lat: 49.482409, lng: 18.535925 },
    { lat: 49.482147, lng: 18.536153 },
    { lat: 49.481649, lng: 18.536675 },
    { lat: 49.481546, lng: 18.536833 },
    { lat: 49.481351, lng: 18.537202 },
    { lat: 49.481188, lng: 18.537493 },
    { lat: 49.481065, lng: 18.537795 },
    { lat: 49.480737, lng: 18.53861 },
    { lat: 49.480606, lng: 18.538923 },
    { lat: 49.480343, lng: 18.539291 },
    { lat: 49.479986, lng: 18.539809 },
    { lat: 49.479872, lng: 18.539918 },
    { lat: 49.479564, lng: 18.540274 },
    { lat: 49.479338, lng: 18.540431 },
    { lat: 49.479214, lng: 18.540607 },
    { lat: 49.478976, lng: 18.54092 },
    { lat: 49.478741, lng: 18.541219 },
    { lat: 49.478399, lng: 18.541578 },
    { lat: 49.477867, lng: 18.542086 },
    { lat: 49.477578, lng: 18.542316 },
    { lat: 49.47738, lng: 18.542438 },
    { lat: 49.477104, lng: 18.542537 },
    { lat: 49.47677, lng: 18.542689 },
    { lat: 49.476427, lng: 18.542842 },
    { lat: 49.476077, lng: 18.543013 },
    { lat: 49.475633, lng: 18.543222 },
    { lat: 49.475431, lng: 18.543305 },
    { lat: 49.475197, lng: 18.543309 },
    { lat: 49.474923, lng: 18.543229 },
    { lat: 49.474745, lng: 18.543172 },
    { lat: 49.474744, lng: 18.543242 },
    { lat: 49.474465, lng: 18.543287 },
    { lat: 49.474378, lng: 18.542989 },
    { lat: 49.474055, lng: 18.542972 },
    { lat: 49.473691, lng: 18.543076 },
    { lat: 49.473424, lng: 18.543189 },
    { lat: 49.473272, lng: 18.543274 },
    { lat: 49.473107, lng: 18.543369 },
    { lat: 49.473026, lng: 18.543818 },
    { lat: 49.472936, lng: 18.544284 },
    { lat: 49.472747, lng: 18.544572 },
    { lat: 49.472539, lng: 18.544854 },
    { lat: 49.472292, lng: 18.545166 },
    { lat: 49.47216, lng: 18.545441 },
    { lat: 49.472031, lng: 18.545622 },
    { lat: 49.471799, lng: 18.546005 },
    { lat: 49.471712, lng: 18.54634 },
    { lat: 49.471579, lng: 18.546539 },
    { lat: 49.471395, lng: 18.546897 },
    { lat: 49.471308, lng: 18.546955 },
    { lat: 49.471139, lng: 18.547046 },
    { lat: 49.470973, lng: 18.547141 },
    { lat: 49.470768, lng: 18.547288 },
    { lat: 49.470376, lng: 18.547524 },
    { lat: 49.470324, lng: 18.547494 },
    { lat: 49.470178, lng: 18.547192 },
    { lat: 49.469953, lng: 18.547074 },
    { lat: 49.469663, lng: 18.547183 },
    { lat: 49.469549, lng: 18.547187 },
    { lat: 49.4694, lng: 18.547213 },
    { lat: 49.469143, lng: 18.547361 },
    { lat: 49.468854, lng: 18.547521 },
    { lat: 49.468668, lng: 18.547645 },
    { lat: 49.468432, lng: 18.547707 },
    { lat: 49.468216, lng: 18.547751 },
    { lat: 49.468103, lng: 18.547828 },
    { lat: 49.468007, lng: 18.547927 },
    { lat: 49.467862, lng: 18.547951 },
    { lat: 49.467686, lng: 18.547704 },
    { lat: 49.467624, lng: 18.547546 },
    { lat: 49.46755, lng: 18.547311 },
    { lat: 49.467474, lng: 18.547116 },
    { lat: 49.467386, lng: 18.547012 },
    { lat: 49.467295, lng: 18.546737 },
    { lat: 49.467246, lng: 18.54652 },
    { lat: 49.467161, lng: 18.54637 },
    { lat: 49.467063, lng: 18.546265 },
    { lat: 49.466663, lng: 18.546036 },
    { lat: 49.46641, lng: 18.545831 },
    { lat: 49.46628, lng: 18.545771 },
    { lat: 49.466136, lng: 18.545707 },
    { lat: 49.46598, lng: 18.545543 },
    { lat: 49.4658, lng: 18.545282 },
    { lat: 49.465568, lng: 18.544951 },
    { lat: 49.465429, lng: 18.544693 },
    { lat: 49.465232, lng: 18.544354 },
    { lat: 49.465113, lng: 18.544094 },
    { lat: 49.465042, lng: 18.54391 },
    { lat: 49.46498, lng: 18.543654 },
    { lat: 49.464926, lng: 18.543261 },
    { lat: 49.464849, lng: 18.542798 },
    { lat: 49.46473, lng: 18.542325 },
    { lat: 49.464688, lng: 18.541896 },
    { lat: 49.464663, lng: 18.541583 },
    { lat: 49.464734, lng: 18.541215 },
    { lat: 49.464794, lng: 18.540944 },
    { lat: 49.464848, lng: 18.540703 },
    { lat: 49.464863, lng: 18.540494 },
    { lat: 49.464718, lng: 18.540132 },
    { lat: 49.464607, lng: 18.539792 },
    { lat: 49.464539, lng: 18.539433 },
    { lat: 49.46441, lng: 18.538732 },
    { lat: 49.464393, lng: 18.538357 },
    { lat: 49.464381, lng: 18.538006 },
    { lat: 49.464316, lng: 18.53753 },
    { lat: 49.464303, lng: 18.537104 },
    { lat: 49.464343, lng: 18.53672 },
    { lat: 49.464421, lng: 18.536255 },
    { lat: 49.464275, lng: 18.536081 },
    { lat: 49.464101, lng: 18.535884 },
    { lat: 49.463807, lng: 18.535581 },
    { lat: 49.463518, lng: 18.535247 },
    { lat: 49.463083, lng: 18.534921 },
    { lat: 49.462859, lng: 18.534895 },
    { lat: 49.462601, lng: 18.534716 },
    { lat: 49.462325, lng: 18.534584 },
    { lat: 49.462187, lng: 18.53452 },
    { lat: 49.461944, lng: 18.534389 },
    { lat: 49.461809, lng: 18.534275 },
    { lat: 49.461651, lng: 18.534126 },
    { lat: 49.461264, lng: 18.533933 },
    { lat: 49.461092, lng: 18.533823 },
    { lat: 49.460886, lng: 18.533715 },
    { lat: 49.460387, lng: 18.533479 },
    { lat: 49.460174, lng: 18.533355 },
    { lat: 49.459747, lng: 18.533151 },
    { lat: 49.459553, lng: 18.533096 },
    { lat: 49.459404, lng: 18.533085 },
    { lat: 49.459135, lng: 18.533042 },
    { lat: 49.458739, lng: 18.532938 },
    { lat: 49.458517, lng: 18.532884 },
    { lat: 49.458311, lng: 18.532771 },
    { lat: 49.458122, lng: 18.532729 },
    { lat: 49.458101, lng: 18.532149 },
    { lat: 49.457956, lng: 18.531209 },
    { lat: 49.457875, lng: 18.530797 },
    { lat: 49.457852, lng: 18.530445 },
    { lat: 49.457745, lng: 18.530145 },
    { lat: 49.457673, lng: 18.529855 },
    { lat: 49.457355, lng: 18.529132 },
    { lat: 49.457121, lng: 18.528724 },
    { lat: 49.457047, lng: 18.52855 },
    { lat: 49.456925, lng: 18.528046 },
    { lat: 49.456925, lng: 18.527766 },
    { lat: 49.456739, lng: 18.527069 },
    { lat: 49.456695, lng: 18.526766 },
    { lat: 49.456703, lng: 18.526534 },
    { lat: 49.456703, lng: 18.525912 },
    { lat: 49.456807, lng: 18.525445 },
    { lat: 49.45674, lng: 18.525212 },
    { lat: 49.456481, lng: 18.524801 },
    { lat: 49.456224, lng: 18.524759 },
    { lat: 49.456066, lng: 18.524577 },
    { lat: 49.455668, lng: 18.524389 },
    { lat: 49.455417, lng: 18.524244 },
    { lat: 49.454876, lng: 18.524272 },
    { lat: 49.454761, lng: 18.52411 },
    { lat: 49.454632, lng: 18.52414 },
    { lat: 49.454576, lng: 18.524019 },
    { lat: 49.454491, lng: 18.523886 },
    { lat: 49.454416, lng: 18.523795 },
    { lat: 49.454271, lng: 18.523652 },
    { lat: 49.454109, lng: 18.523574 },
    { lat: 49.453969, lng: 18.523527 },
    { lat: 49.453572, lng: 18.523376 },
    { lat: 49.45345, lng: 18.523295 },
    { lat: 49.453288, lng: 18.523233 },
    { lat: 49.452977, lng: 18.523221 },
    { lat: 49.452648, lng: 18.523211 },
    { lat: 49.452288, lng: 18.523241 },
    { lat: 49.451967, lng: 18.523156 },
    { lat: 49.451749, lng: 18.523453 },
    { lat: 49.451659, lng: 18.523456 },
    { lat: 49.451444, lng: 18.523522 },
    { lat: 49.4514106, lng: 18.5232896 },
    { lat: 49.451085, lng: 18.523249 },
    { lat: 49.451024, lng: 18.523241 },
    { lat: 49.45053, lng: 18.523164 },
    { lat: 49.449369, lng: 18.522972 },
    { lat: 49.449235, lng: 18.5229523 },
    { lat: 49.4490121, lng: 18.5228002 },
    { lat: 49.4488734, lng: 18.522907 },
    { lat: 49.4488517, lng: 18.5229217 },
    { lat: 49.4471303, lng: 18.523672 },
    { lat: 49.4471008, lng: 18.5236862 },
    { lat: 49.4464665, lng: 18.5239921 },
    { lat: 49.4462467, lng: 18.5240982 },
    { lat: 49.4461655, lng: 18.5241614 },
    { lat: 49.4461112, lng: 18.5242027 },
    { lat: 49.4452321, lng: 18.5248828 },
    { lat: 49.4453047, lng: 18.5252266 },
    { lat: 49.4453122, lng: 18.5252597 },
    { lat: 49.4454519, lng: 18.5259274 },
    { lat: 49.4454607, lng: 18.5259633 },
    { lat: 49.4455577, lng: 18.5264269 },
    { lat: 49.4456395, lng: 18.5268138 },
    { lat: 49.4457321, lng: 18.5272548 },
    { lat: 49.4459154, lng: 18.5281276 },
    { lat: 49.4459084, lng: 18.5281643 },
    { lat: 49.4457427, lng: 18.529003 },
    { lat: 49.4452193, lng: 18.5304868 },
    { lat: 49.4463401, lng: 18.5329144 },
    { lat: 49.4461309, lng: 18.5333668 },
    { lat: 49.44612, lng: 18.5333961 },
    { lat: 49.4459114, lng: 18.5338592 },
    { lat: 49.4459054, lng: 18.5338712 },
    { lat: 49.4459025, lng: 18.5338778 },
    { lat: 49.4458753, lng: 18.5339392 },
    { lat: 49.4457765, lng: 18.534159 },
    { lat: 49.4452945, lng: 18.5346749 },
    { lat: 49.4452823, lng: 18.5346601 },
    { lat: 49.4452788, lng: 18.5346555 },
    { lat: 49.4452644, lng: 18.5346391 },
    { lat: 49.4452584, lng: 18.5346323 },
    { lat: 49.4451777, lng: 18.5345997 },
    { lat: 49.4450088, lng: 18.5348549 },
    { lat: 49.4449911, lng: 18.5348829 },
    { lat: 49.4436063, lng: 18.5369844 },
    { lat: 49.4429136, lng: 18.5384267 },
    { lat: 49.4428202, lng: 18.5386415 },
    { lat: 49.4427148, lng: 18.5390114 },
    { lat: 49.442613, lng: 18.5395082 },
    { lat: 49.4424937, lng: 18.5396595 },
    { lat: 49.4422449, lng: 18.5398356 },
    { lat: 49.4418863, lng: 18.5400896 },
    { lat: 49.441865, lng: 18.5401115 },
    { lat: 49.4414589, lng: 18.5405318 },
    { lat: 49.4413528, lng: 18.5406942 },
    { lat: 49.4410112, lng: 18.541286 },
    { lat: 49.4406836, lng: 18.5418616 },
    { lat: 49.4405347, lng: 18.5422392 },
    { lat: 49.4404773, lng: 18.542328 },
    { lat: 49.4404002, lng: 18.5424026 },
    { lat: 49.4402335, lng: 18.5425545 },
    { lat: 49.4401898, lng: 18.5426021 },
    { lat: 49.4401889, lng: 18.5426029 },
    { lat: 49.4401793, lng: 18.5426135 },
    { lat: 49.4400045, lng: 18.5428045 },
    { lat: 49.4395191, lng: 18.543197 },
    { lat: 49.439291, lng: 18.5434208 },
    { lat: 49.4390451, lng: 18.5435668 },
    { lat: 49.4388317, lng: 18.5437296 },
    { lat: 49.4386911, lng: 18.5438238 },
    { lat: 49.4386302, lng: 18.5439044 },
    { lat: 49.4385295, lng: 18.5441095 },
    { lat: 49.4383476, lng: 18.5442912 },
    { lat: 49.4382843, lng: 18.544355 },
    { lat: 49.438189, lng: 18.5446164 },
    { lat: 49.4380338, lng: 18.5448951 },
    { lat: 49.437937, lng: 18.5450682 },
    { lat: 49.4378552, lng: 18.5453112 },
    { lat: 49.4377298, lng: 18.5456419 },
    { lat: 49.4376344, lng: 18.5457971 },
    { lat: 49.4375329, lng: 18.5459857 },
    { lat: 49.4374516, lng: 18.5461913 },
    { lat: 49.4373488, lng: 18.5463808 },
    { lat: 49.4371692, lng: 18.5467731 },
    { lat: 49.4371339, lng: 18.5468504 },
    { lat: 49.4370573, lng: 18.5470612 },
    { lat: 49.4370536, lng: 18.5470768 },
    { lat: 49.4369791, lng: 18.5473707 },
    { lat: 49.4369548, lng: 18.5477586 },
    { lat: 49.4369227, lng: 18.5482298 },
    { lat: 49.4369119, lng: 18.5483895 },
    { lat: 49.4368455, lng: 18.5487914 },
    { lat: 49.4368768, lng: 18.5495634 },
    { lat: 49.4369006, lng: 18.5499622 },
    { lat: 49.4368567, lng: 18.5500917 },
    { lat: 49.436783, lng: 18.5503561 },
    { lat: 49.4367264, lng: 18.5505223 },
    { lat: 49.4365917, lng: 18.5508481 },
    { lat: 49.4365355, lng: 18.5508936 },
    { lat: 49.4364964, lng: 18.550984 },
    { lat: 49.4364265, lng: 18.5511744 },
    { lat: 49.4363787, lng: 18.5513747 },
    { lat: 49.4363504, lng: 18.5515 },
    { lat: 49.436341, lng: 18.5515983 },
    { lat: 49.4363374, lng: 18.5516796 },
    { lat: 49.4363262, lng: 18.5517843 },
    { lat: 49.4363144, lng: 18.55186 },
    { lat: 49.436284, lng: 18.5520277 },
    { lat: 49.4362735, lng: 18.5521051 },
    { lat: 49.4362914, lng: 18.552355 },
    { lat: 49.4363309, lng: 18.552674 },
    { lat: 49.4364075, lng: 18.5530488 },
    { lat: 49.4364628, lng: 18.5532162 },
    { lat: 49.4365408, lng: 18.5533883 },
    { lat: 49.4366398, lng: 18.553533 },
    { lat: 49.4367169, lng: 18.5536657 },
    { lat: 49.4367983, lng: 18.5538185 },
    { lat: 49.4369091, lng: 18.5540561 },
    { lat: 49.43695, lng: 18.5541437 },
    { lat: 49.4370855, lng: 18.5544183 },
    { lat: 49.437134, lng: 18.5545658 },
    { lat: 49.437207, lng: 18.5548158 },
    { lat: 49.4372211, lng: 18.5549912 },
    { lat: 49.4372193, lng: 18.5552313 },
    { lat: 49.4372398, lng: 18.555407 },
    { lat: 49.4372586, lng: 18.555546 },
    { lat: 49.437321, lng: 18.555911 },
    { lat: 49.437334, lng: 18.5560032 },
    { lat: 49.4373497, lng: 18.5561299 },
    { lat: 49.4373772, lng: 18.5562623 },
    { lat: 49.4373919, lng: 18.5564761 },
    { lat: 49.4373816, lng: 18.5565788 },
    { lat: 49.4373315, lng: 18.5567605 },
    { lat: 49.4372941, lng: 18.5570059 },
    { lat: 49.4372304, lng: 18.5575857 },
    { lat: 49.4371651, lng: 18.5579676 },
    { lat: 49.4370854, lng: 18.5583096 },
    { lat: 49.4369818, lng: 18.5588205 },
    { lat: 49.4368949, lng: 18.5591957 },
    { lat: 49.4367031, lng: 18.5595514 },
    { lat: 49.4364095, lng: 18.5600515 },
    { lat: 49.4363221, lng: 18.5602738 },
    { lat: 49.4362414, lng: 18.5604307 },
    { lat: 49.4361172, lng: 18.5606181 },
    { lat: 49.4359329, lng: 18.5608174 },
    { lat: 49.4358132, lng: 18.5609416 },
    { lat: 49.4356738, lng: 18.5611363 },
    { lat: 49.4355626, lng: 18.561338 },
    { lat: 49.4354685, lng: 18.5614735 },
    { lat: 49.4352671, lng: 18.5612916 },
    { lat: 49.4352051, lng: 18.5611664 },
    { lat: 49.4350078, lng: 18.5606857 },
    { lat: 49.434967, lng: 18.5605343 },
    { lat: 49.4349433, lng: 18.5604935 },
    { lat: 49.4349086, lng: 18.5604308 },
    { lat: 49.4348479, lng: 18.5604149 },
    { lat: 49.4346917, lng: 18.560049 },
    { lat: 49.4345539, lng: 18.5597261 },
    { lat: 49.4343754, lng: 18.5595315 },
    { lat: 49.4342871, lng: 18.5593995 },
    { lat: 49.4342748, lng: 18.5593822 },
    { lat: 49.4342263, lng: 18.5591698 },
    { lat: 49.4341413, lng: 18.5591005 },
    { lat: 49.4339553, lng: 18.5590373 },
    { lat: 49.433703, lng: 18.5590223 },
    { lat: 49.4333994, lng: 18.5591022 },
    { lat: 49.4330791, lng: 18.5592524 },
    { lat: 49.4327806, lng: 18.5595723 },
    { lat: 49.4327322, lng: 18.5596139 },
    { lat: 49.4325138, lng: 18.5597899 },
    { lat: 49.4324323, lng: 18.5598473 },
    { lat: 49.4323604, lng: 18.5599165 },
    { lat: 49.4322429, lng: 18.5600838 },
    { lat: 49.4321283, lng: 18.5602168 },
    { lat: 49.4320719, lng: 18.5605083 },
    { lat: 49.4319981, lng: 18.5607539 },
    { lat: 49.4318418, lng: 18.5617319 },
    { lat: 49.431788, lng: 18.5618626 },
    { lat: 49.4314891, lng: 18.56203 },
    { lat: 49.4314079, lng: 18.5620536 },
    { lat: 49.4311939, lng: 18.5620171 },
    { lat: 49.4309842, lng: 18.5620584 },
    { lat: 49.4307544, lng: 18.5622113 },
    { lat: 49.4306757, lng: 18.5623212 },
    { lat: 49.4305932, lng: 18.5625069 },
    { lat: 49.4305687, lng: 18.5626183 },
    { lat: 49.430479, lng: 18.5628814 },
    { lat: 49.4303551, lng: 18.5634931 },
    { lat: 49.4302949, lng: 18.5638249 },
    { lat: 49.4300784, lng: 18.5644551 },
    { lat: 49.4298223, lng: 18.5649819 },
    { lat: 49.4298021, lng: 18.5650243 },
    { lat: 49.4296191, lng: 18.565164 },
    { lat: 49.429507, lng: 18.5654107 },
    { lat: 49.4293327, lng: 18.565695 },
    { lat: 49.4289666, lng: 18.5657026 },
    { lat: 49.4288821, lng: 18.5657046 },
    { lat: 49.4286743, lng: 18.5658673 },
    { lat: 49.428433, lng: 18.5659321 },
    { lat: 49.427223, lng: 18.5669426 },
    { lat: 49.4271626, lng: 18.566993 },
    { lat: 49.4269155, lng: 18.567286 },
    { lat: 49.426875, lng: 18.5673338 },
    { lat: 49.4268561, lng: 18.5673561 },
    { lat: 49.4268337, lng: 18.5673836 },
    { lat: 49.4267483, lng: 18.5674918 },
    { lat: 49.4266352, lng: 18.5677194 },
    { lat: 49.4265762, lng: 18.5678392 },
    { lat: 49.4263918, lng: 18.5682091 },
    { lat: 49.426326, lng: 18.5683485 },
    { lat: 49.426302, lng: 18.568404 },
    { lat: 49.4262115, lng: 18.5686118 },
    { lat: 49.4260992, lng: 18.5688712 },
    { lat: 49.4258769, lng: 18.5692085 },
    { lat: 49.4255082, lng: 18.5694813 },
    { lat: 49.4251223, lng: 18.5695838 },
    { lat: 49.4253106, lng: 18.5700677 },
    { lat: 49.4251596, lng: 18.5710627 },
    { lat: 49.4248184, lng: 18.571386 },
    { lat: 49.4247415, lng: 18.5726927 },
    { lat: 49.4246806, lng: 18.5729623 },
    { lat: 49.4248236, lng: 18.5734457 },
    { lat: 49.4247933, lng: 18.573489 },
    { lat: 49.424795, lng: 18.5735199 },
    { lat: 49.4247733, lng: 18.5737074 },
    { lat: 49.4248039, lng: 18.5739217 },
    { lat: 49.4247949, lng: 18.5741101 },
    { lat: 49.4247774, lng: 18.5743097 },
    { lat: 49.4248093, lng: 18.5745079 },
    { lat: 49.42485, lng: 18.5746158 },
    { lat: 49.4248801, lng: 18.5747363 },
    { lat: 49.4248763, lng: 18.5748513 },
    { lat: 49.424902, lng: 18.5749951 },
    { lat: 49.4249092, lng: 18.5751532 },
    { lat: 49.4248629, lng: 18.5753605 },
    { lat: 49.4249087, lng: 18.575546 },
    { lat: 49.4249122, lng: 18.5757572 },
    { lat: 49.4249377, lng: 18.5758532 },
    { lat: 49.424953, lng: 18.5759716 },
    { lat: 49.424989, lng: 18.5761587 },
    { lat: 49.425021, lng: 18.5762938 },
    { lat: 49.4250635, lng: 18.5764783 },
    { lat: 49.4245779, lng: 18.5776468 },
    { lat: 49.4241663, lng: 18.5785154 },
    { lat: 49.4234088, lng: 18.5797216 },
    { lat: 49.4237847, lng: 18.5801747 },
    { lat: 49.4239795, lng: 18.5806941 },
    { lat: 49.4242409, lng: 18.5814507 },
    { lat: 49.4245446, lng: 18.5827051 },
    { lat: 49.4246498, lng: 18.5832586 },
    { lat: 49.4246336, lng: 18.5832917 },
    { lat: 49.4257023, lng: 18.5851437 },
    { lat: 49.4281807, lng: 18.5900008 },
    { lat: 49.4282716, lng: 18.5900579 },
    { lat: 49.4284327, lng: 18.5902869 },
    { lat: 49.4285135, lng: 18.5907645 },
    { lat: 49.4285212, lng: 18.5908646 },
    { lat: 49.4285228, lng: 18.590872 },
    { lat: 49.4286316, lng: 18.5921333 },
    { lat: 49.428654, lng: 18.5921273 },
    { lat: 49.4286637, lng: 18.5924391 },
    { lat: 49.4286634, lng: 18.5924953 },
    { lat: 49.4286716, lng: 18.5938982 },
    { lat: 49.4288822, lng: 18.5948663 },
    { lat: 49.4293076, lng: 18.5958435 },
    { lat: 49.429317, lng: 18.5963611 },
    { lat: 49.4293601, lng: 18.5964289 },
    { lat: 49.4296927, lng: 18.5979304 },
    { lat: 49.4298715, lng: 18.5997217 },
    { lat: 49.4305166, lng: 18.6019674 },
    { lat: 49.4306984, lng: 18.6022258 },
    { lat: 49.4312988, lng: 18.6026776 },
    { lat: 49.4313226, lng: 18.6027043 },
    { lat: 49.4321271, lng: 18.6034345 },
    { lat: 49.4324997, lng: 18.6028237 },
    { lat: 49.4326996, lng: 18.6026596 },
    { lat: 49.4331197, lng: 18.6023121 },
    { lat: 49.4337209, lng: 18.6022949 },
    { lat: 49.4343821, lng: 18.6027249 },
    { lat: 49.4358651, lng: 18.6042961 },
    { lat: 49.4362335, lng: 18.6045402 },
    { lat: 49.4366933, lng: 18.6048951 },
    { lat: 49.4367136, lng: 18.6049165 },
    { lat: 49.4373395, lng: 18.6053622 },
    { lat: 49.4373571, lng: 18.6053745 },
    { lat: 49.437943, lng: 18.6063626 },
    { lat: 49.4381261, lng: 18.6065615 },
    { lat: 49.438609, lng: 18.6064797 },
    { lat: 49.4391705, lng: 18.60807 },
    { lat: 49.4394027, lng: 18.6082605 },
    { lat: 49.4394499, lng: 18.6082996 },
    { lat: 49.439778, lng: 18.6087402 },
    { lat: 49.4403783, lng: 18.6102549 },
    { lat: 49.4404128, lng: 18.6111178 },
    { lat: 49.4403327, lng: 18.6116678 },
    { lat: 49.4403475, lng: 18.6132412 },
    { lat: 49.4401609, lng: 18.613337 },
    { lat: 49.4396163, lng: 18.6144022 },
    { lat: 49.4402244, lng: 18.614809 },
    { lat: 49.4407593, lng: 18.6151978 },
    { lat: 49.4416412, lng: 18.6158392 },
    { lat: 49.4418188, lng: 18.6161048 },
    { lat: 49.442078, lng: 18.6166291 },
    { lat: 49.4424312, lng: 18.6165923 },
    { lat: 49.4427781, lng: 18.6166497 },
    { lat: 49.4430313, lng: 18.6166783 },
    { lat: 49.4432608, lng: 18.6168613 },
    { lat: 49.4432841, lng: 18.616895 },
    { lat: 49.4436883, lng: 18.6174595 },
    { lat: 49.4442195, lng: 18.6182238 },
    { lat: 49.4445737, lng: 18.6186515 },
    { lat: 49.4451991, lng: 18.6191298 },
    { lat: 49.4454501, lng: 18.6196529 },
    { lat: 49.446134, lng: 18.6195767 },
    { lat: 49.446153, lng: 18.6195747 },
    { lat: 49.4470148, lng: 18.6194719 },
    { lat: 49.4472172, lng: 18.6194615 },
    { lat: 49.4477685, lng: 18.6193988 },
    { lat: 49.4479657, lng: 18.6193728 },
    { lat: 49.4487246, lng: 18.6192868 },
    { lat: 49.448764, lng: 18.6192855 },
    { lat: 49.448869, lng: 18.619247 },
    { lat: 49.4490764, lng: 18.6192411 },
    { lat: 49.4493881, lng: 18.6192315 },
    { lat: 49.449594, lng: 18.6191979 },
    { lat: 49.4497697, lng: 18.6191524 },
    { lat: 49.450499, lng: 18.6187036 },
    { lat: 49.4522162, lng: 18.6178477 },
    { lat: 49.4522189, lng: 18.6178757 },
    { lat: 49.4523275, lng: 18.6178385 },
    { lat: 49.452438, lng: 18.6179038 },
    { lat: 49.4527805, lng: 18.6183449 },
    { lat: 49.4528145, lng: 18.6183896 },
    { lat: 49.4531495, lng: 18.6185617 },
    { lat: 49.4538344, lng: 18.6186748 },
    { lat: 49.4545793, lng: 18.6183691 },
    { lat: 49.4559084, lng: 18.6178711 },
    { lat: 49.45621, lng: 18.6178367 },
    { lat: 49.4566126, lng: 18.6176455 },
    { lat: 49.4569239, lng: 18.6174775 },
    { lat: 49.4570713, lng: 18.6174711 },
    { lat: 49.4578184, lng: 18.6174387 },
    { lat: 49.4578745, lng: 18.6174535 },
    { lat: 49.4592652, lng: 18.6171595 },
    { lat: 49.4597771, lng: 18.617308 },
    { lat: 49.460225, lng: 18.6173141 },
    { lat: 49.460239, lng: 18.6173029 },
    { lat: 49.4609283, lng: 18.6168797 },
    { lat: 49.4609435, lng: 18.6168713 },
    { lat: 49.4609738, lng: 18.6173138 },
    { lat: 49.4608688, lng: 18.6179493 },
    { lat: 49.4608834, lng: 18.6182876 },
    { lat: 49.4609225, lng: 18.6192373 },
    { lat: 49.460973, lng: 18.6192313 },
    { lat: 49.4612795, lng: 18.6206541 },
    { lat: 49.4614741, lng: 18.6215611 },
    { lat: 49.461777, lng: 18.6213149 },
    { lat: 49.461879, lng: 18.6217716 },
    { lat: 49.4619469, lng: 18.6223081 },
    { lat: 49.4618434, lng: 18.6228887 },
    { lat: 49.461664, lng: 18.624056 },
    { lat: 49.4613722, lng: 18.6255313 },
    { lat: 49.4612693, lng: 18.6247367 },
    { lat: 49.4611452, lng: 18.6243513 },
    { lat: 49.4609929, lng: 18.6241803 },
    { lat: 49.4608203, lng: 18.6240097 },
    { lat: 49.4608131, lng: 18.6240428 },
    { lat: 49.4606865, lng: 18.6245477 },
    { lat: 49.4606185, lng: 18.6248197 },
    { lat: 49.4604276, lng: 18.6251738 },
    { lat: 49.4601389, lng: 18.6259649 },
    { lat: 49.4600796, lng: 18.6260579 },
    { lat: 49.4596037, lng: 18.6268031 },
    { lat: 49.4594575, lng: 18.6276167 },
    { lat: 49.4593071, lng: 18.6276318 },
    { lat: 49.4590929, lng: 18.6278441 },
    { lat: 49.4590661, lng: 18.6278732 },
    { lat: 49.4590358, lng: 18.6278947 },
    { lat: 49.4588506, lng: 18.6280161 },
    { lat: 49.4587861, lng: 18.628098 },
    { lat: 49.4586237, lng: 18.6282919 },
    { lat: 49.458527, lng: 18.6284421 },
    { lat: 49.4584112, lng: 18.6285819 },
    { lat: 49.4583609, lng: 18.6286705 },
    { lat: 49.4583179, lng: 18.6288622 },
    { lat: 49.4583152, lng: 18.6290884 },
    { lat: 49.458326, lng: 18.6295253 },
    { lat: 49.458011, lng: 18.6302838 },
    { lat: 49.4579799, lng: 18.6303783 },
    { lat: 49.4578999, lng: 18.6304658 },
    { lat: 49.4577167, lng: 18.630924 },
    { lat: 49.4575578, lng: 18.6313099 },
    { lat: 49.4574046, lng: 18.6318441 },
    { lat: 49.457198, lng: 18.6331426 },
    { lat: 49.4565576, lng: 18.6339542 },
    { lat: 49.4562947, lng: 18.6341627 },
    { lat: 49.4560347, lng: 18.634214 },
    { lat: 49.4557073, lng: 18.6342308 },
    { lat: 49.4553979, lng: 18.6343052 },
    { lat: 49.4551379, lng: 18.634508 },
    { lat: 49.4549892, lng: 18.6347432 },
    { lat: 49.4548679, lng: 18.6349668 },
    { lat: 49.4546733, lng: 18.6352541 },
    { lat: 49.4545588, lng: 18.6357954 },
    { lat: 49.4545323, lng: 18.6363628 },
    { lat: 49.4545295, lng: 18.6364345 },
    { lat: 49.4544945, lng: 18.6364787 },
    { lat: 49.4546572, lng: 18.6366959 },
    { lat: 49.454744, lng: 18.6370991 },
    { lat: 49.454783, lng: 18.6377112 },
    { lat: 49.454741, lng: 18.6381104 },
    { lat: 49.4547302, lng: 18.6384604 },
    { lat: 49.4546994, lng: 18.6387546 },
    { lat: 49.4545458, lng: 18.6395186 },
    { lat: 49.4544393, lng: 18.6405073 },
    { lat: 49.454399, lng: 18.6410274 },
    { lat: 49.4543629, lng: 18.6414973 },
    { lat: 49.4543903, lng: 18.6418419 },
    { lat: 49.4544456, lng: 18.6422265 },
    { lat: 49.4544827, lng: 18.6424825 },
    { lat: 49.4544722, lng: 18.643096 },
    { lat: 49.4544337, lng: 18.6434952 },
    { lat: 49.454397, lng: 18.6440851 },
    { lat: 49.454308, lng: 18.644766 },
    { lat: 49.4542162, lng: 18.6450812 },
    { lat: 49.4542057, lng: 18.645839 },
    { lat: 49.4542128, lng: 18.6461034 },
    { lat: 49.4542757, lng: 18.6463783 },
    { lat: 49.4543131, lng: 18.6467634 },
    { lat: 49.4543259, lng: 18.6472199 },
    { lat: 49.4543616, lng: 18.6474678 },
    { lat: 49.4543661, lng: 18.6478116 },
    { lat: 49.4544682, lng: 18.6482687 },
    { lat: 49.4544591, lng: 18.6485257 },
    { lat: 49.4544679, lng: 18.6488628 },
    { lat: 49.4544393, lng: 18.6493459 },
    { lat: 49.4544585, lng: 18.6495632 },
    { lat: 49.4544973, lng: 18.65006 },
    { lat: 49.4545064, lng: 18.6504913 },
    { lat: 49.4545423, lng: 18.6507022 },
    { lat: 49.4544927, lng: 18.6509976 },
    { lat: 49.4542798, lng: 18.6517326 },
    { lat: 49.4543059, lng: 18.6524833 },
    { lat: 49.4543175, lng: 18.6526167 },
    { lat: 49.454207, lng: 18.6528929 },
    { lat: 49.4542283, lng: 18.6530483 },
    { lat: 49.4542363, lng: 18.6531067 },
    { lat: 49.4543494, lng: 18.653329 },
    { lat: 49.4543696, lng: 18.6534744 },
    { lat: 49.454199, lng: 18.6537895 },
    { lat: 49.4541904, lng: 18.6539026 },
    { lat: 49.4542426, lng: 18.6542118 },
    { lat: 49.4542268, lng: 18.6544675 },
    { lat: 49.4539244, lng: 18.6547831 },
    { lat: 49.4538134, lng: 18.6550306 },
    { lat: 49.4537127, lng: 18.6554292 },
    { lat: 49.4536309, lng: 18.6556111 },
    { lat: 49.4533704, lng: 18.6560403 },
    { lat: 49.4533484, lng: 18.6562 },
    { lat: 49.4532895, lng: 18.6565799 },
    { lat: 49.4531819, lng: 18.656826 },
    { lat: 49.4531084, lng: 18.65695 },
    { lat: 49.453053, lng: 18.6571196 },
    { lat: 49.4530546, lng: 18.6574717 },
    { lat: 49.4530231, lng: 18.6576224 },
    { lat: 49.452961, lng: 18.6579204 },
    { lat: 49.4529321, lng: 18.6580567 },
    { lat: 49.4528275, lng: 18.6581045 },
    { lat: 49.452756, lng: 18.6581195 },
    { lat: 49.4526893, lng: 18.6581361 },
    { lat: 49.4526611, lng: 18.6582005 },
    { lat: 49.4528063, lng: 18.6586015 },
    { lat: 49.4528068, lng: 18.6588048 },
    { lat: 49.4528635, lng: 18.659149 },
    { lat: 49.4529065, lng: 18.659347 },
    { lat: 49.4529092, lng: 18.6596315 },
    { lat: 49.4528968, lng: 18.6598487 },
    { lat: 49.4528531, lng: 18.6602398 },
    { lat: 49.4528821, lng: 18.6604047 },
    { lat: 49.4529811, lng: 18.6602072 },
    { lat: 49.4530255, lng: 18.6601916 },
    { lat: 49.4532115, lng: 18.6599097 },
    { lat: 49.4532992, lng: 18.6598213 },
    { lat: 49.4533903, lng: 18.6595827 },
    { lat: 49.4534885, lng: 18.6593067 },
    { lat: 49.45361, lng: 18.6587425 },
    { lat: 49.4536152, lng: 18.6587165 },
    { lat: 49.4536335, lng: 18.6586323 },
    { lat: 49.453671, lng: 18.6585098 },
    { lat: 49.4537574, lng: 18.6584187 },
    { lat: 49.4540283, lng: 18.6584887 },
    { lat: 49.4542594, lng: 18.6584836 },
    { lat: 49.4545113, lng: 18.658281 },
    { lat: 49.4549214, lng: 18.6575443 },
    { lat: 49.4554627, lng: 18.656969 },
    { lat: 49.4555396, lng: 18.6567789 },
    { lat: 49.455587, lng: 18.6566516 },
    { lat: 49.4556992, lng: 18.6566859 },
    { lat: 49.4559297, lng: 18.6570796 },
    { lat: 49.4560365, lng: 18.6570736 },
    { lat: 49.4563848, lng: 18.6565769 },
    { lat: 49.456909, lng: 18.6564035 },
    { lat: 49.4572139, lng: 18.6562392 },
    { lat: 49.457436, lng: 18.6562399 },
    { lat: 49.4576733, lng: 18.6562482 },
    { lat: 49.4582447, lng: 18.6559855 },
    { lat: 49.4586541, lng: 18.6555178 },
    { lat: 49.4588667, lng: 18.6553367 },
    { lat: 49.4594207, lng: 18.6551284 },
    { lat: 49.4602353, lng: 18.6542834 },
    { lat: 49.4605079, lng: 18.6538059 },
    { lat: 49.4606136, lng: 18.6537358 },
    { lat: 49.4607656, lng: 18.6536662 },
    { lat: 49.4609995, lng: 18.653589 },
    { lat: 49.4613315, lng: 18.6534941 },
    { lat: 49.4615494, lng: 18.6533927 },
    { lat: 49.4615933, lng: 18.6533909 },
    { lat: 49.4619826, lng: 18.6531613 },
    { lat: 49.4621794, lng: 18.6531282 },
    { lat: 49.4625067, lng: 18.6530894 },
    { lat: 49.4627783, lng: 18.6530865 },
    { lat: 49.4628604, lng: 18.6530397 },
    { lat: 49.4629021, lng: 18.6529747 },
    { lat: 49.4630657, lng: 18.6524019 },
    { lat: 49.4631994, lng: 18.6520064 },
    { lat: 49.4632728, lng: 18.6517326 },
    { lat: 49.463455, lng: 18.6517227 },
    { lat: 49.4635009, lng: 18.6517148 },
    { lat: 49.4637054, lng: 18.6516369 },
    { lat: 49.4639041, lng: 18.6514074 },
    { lat: 49.4640235, lng: 18.6512341 },
    { lat: 49.4640842, lng: 18.6509065 },
    { lat: 49.4640035, lng: 18.6506399 },
    { lat: 49.4638523, lng: 18.6503585 },
    { lat: 49.4637733, lng: 18.6502098 },
    { lat: 49.4637428, lng: 18.6499326 },
    { lat: 49.4636977, lng: 18.6496961 },
    { lat: 49.4636535, lng: 18.6494587 },
    { lat: 49.4635376, lng: 18.6494415 },
    { lat: 49.4633222, lng: 18.6485616 },
    { lat: 49.4634153, lng: 18.6475276 },
    { lat: 49.4636333, lng: 18.6468317 },
    { lat: 49.4637646, lng: 18.6466692 },
    { lat: 49.4639845, lng: 18.6465828 },
    { lat: 49.4640719, lng: 18.6462964 },
    { lat: 49.4639964, lng: 18.6459139 },
    { lat: 49.4638871, lng: 18.645615 },
    { lat: 49.4639176, lng: 18.6452758 },
    { lat: 49.4640617, lng: 18.6450146 },
    { lat: 49.4643021, lng: 18.6447629 },
    { lat: 49.4644007, lng: 18.6443025 },
    { lat: 49.4646397, lng: 18.6443004 },
    { lat: 49.4647489, lng: 18.6442726 },
    { lat: 49.464791, lng: 18.6442605 },
    { lat: 49.4650787, lng: 18.6441149 },
    { lat: 49.4651832, lng: 18.6438082 },
    { lat: 49.4652885, lng: 18.6436237 },
    { lat: 49.4653775, lng: 18.6434505 },
    { lat: 49.4655093, lng: 18.6433877 },
    { lat: 49.4654652, lng: 18.6432768 },
    { lat: 49.4654294, lng: 18.6431326 },
    { lat: 49.4654996, lng: 18.6430521 },
    { lat: 49.4655556, lng: 18.6430245 },
    { lat: 49.4656417, lng: 18.6430308 },
    { lat: 49.4656829, lng: 18.643042 },
    { lat: 49.4657496, lng: 18.6431109 },
    { lat: 49.4657942, lng: 18.6429749 },
    { lat: 49.4659446, lng: 18.6428518 },
    { lat: 49.466075, lng: 18.6427387 },
    { lat: 49.4661428, lng: 18.6426981 },
    { lat: 49.4662868, lng: 18.6424992 },
    { lat: 49.4662735, lng: 18.642333 },
    { lat: 49.4664064, lng: 18.6421142 },
    { lat: 49.466481, lng: 18.6414784 },
    { lat: 49.4665246, lng: 18.6414316 },
    { lat: 49.4665322, lng: 18.6414455 },
    { lat: 49.4666244, lng: 18.6416353 },
    { lat: 49.4666908, lng: 18.6416753 },
    { lat: 49.4669882, lng: 18.6418605 },
    { lat: 49.4669975, lng: 18.6418663 },
    { lat: 49.4670651, lng: 18.6419079 },
    { lat: 49.4671487, lng: 18.6418431 },
    { lat: 49.4672013, lng: 18.6416894 },
    { lat: 49.4672391, lng: 18.6414446 },
    { lat: 49.46725, lng: 18.6414132 },
    { lat: 49.4672577, lng: 18.6413481 },
    { lat: 49.4672203, lng: 18.6412795 },
    { lat: 49.4672903, lng: 18.6412716 },
    { lat: 49.4673604, lng: 18.6413063 },
    { lat: 49.4675098, lng: 18.6412935 },
    { lat: 49.4675741, lng: 18.641255 },
    { lat: 49.4677385, lng: 18.6411407 },
    { lat: 49.4677382, lng: 18.6409892 },
    { lat: 49.4675568, lng: 18.64056 },
    { lat: 49.4675476, lng: 18.6404081 },
    { lat: 49.4676254, lng: 18.6403647 },
    { lat: 49.4677393, lng: 18.6397704 },
    { lat: 49.4678022, lng: 18.6396368 },
    { lat: 49.4678819, lng: 18.6394144 },
    { lat: 49.4681772, lng: 18.6391985 },
    { lat: 49.4682616, lng: 18.6390488 },
    { lat: 49.468247, lng: 18.6384103 },
    { lat: 49.4683299, lng: 18.6380153 },
    { lat: 49.4683363, lng: 18.6377516 },
    { lat: 49.4683545, lng: 18.6375648 },
    { lat: 49.4683884, lng: 18.6374993 },
    { lat: 49.468414, lng: 18.6374301 },
    { lat: 49.4685647, lng: 18.6371655 },
    { lat: 49.4686349, lng: 18.6367667 },
    { lat: 49.4687586, lng: 18.6365062 },
    { lat: 49.4689865, lng: 18.6363157 },
    { lat: 49.469105, lng: 18.6361037 },
    { lat: 49.4691511, lng: 18.6357379 },
    { lat: 49.4692058, lng: 18.6355168 },
    { lat: 49.4692634, lng: 18.635431 },
    { lat: 49.4694288, lng: 18.6353411 },
    { lat: 49.4694634, lng: 18.6353328 },
    { lat: 49.4695689, lng: 18.6352606 },
    { lat: 49.4697375, lng: 18.6349938 },
    { lat: 49.4700097, lng: 18.6343587 },
    { lat: 49.4701956, lng: 18.633912 },
    { lat: 49.4702006, lng: 18.633791 },
    { lat: 49.4700454, lng: 18.6336726 },
    { lat: 49.4699883, lng: 18.6335706 },
    { lat: 49.4700347, lng: 18.6333853 },
    { lat: 49.4701444, lng: 18.6332821 },
    { lat: 49.4704228, lng: 18.6333014 },
    { lat: 49.4705188, lng: 18.6331992 },
    { lat: 49.4705213, lng: 18.6330948 },
    { lat: 49.4704896, lng: 18.6330092 },
    { lat: 49.4704408, lng: 18.6330254 },
    { lat: 49.4703986, lng: 18.632973 },
    { lat: 49.4705437, lng: 18.6326039 },
    { lat: 49.4706682, lng: 18.6321878 },
    { lat: 49.4706895, lng: 18.6321202 },
    { lat: 49.4706696, lng: 18.6321071 },
    { lat: 49.4706392, lng: 18.6319347 },
    { lat: 49.4706656, lng: 18.6318168 },
    { lat: 49.4707394, lng: 18.631676 },
    { lat: 49.4708503, lng: 18.6315164 },
    { lat: 49.4708906, lng: 18.6313798 },
    { lat: 49.4709566, lng: 18.6310963 },
    { lat: 49.4708902, lng: 18.6309878 },
    { lat: 49.4707735, lng: 18.6309578 },
    { lat: 49.4707073, lng: 18.6308989 },
    { lat: 49.4706893, lng: 18.6306891 },
    { lat: 49.4708778, lng: 18.6305213 },
    { lat: 49.4709026, lng: 18.630499 },
    { lat: 49.4711469, lng: 18.6300036 },
    { lat: 49.4712182, lng: 18.6298584 },
    { lat: 49.4713171, lng: 18.6299157 },
    { lat: 49.4713804, lng: 18.6298516 },
    { lat: 49.4714185, lng: 18.629617 },
    { lat: 49.4714899, lng: 18.6294743 },
    { lat: 49.4716102, lng: 18.6294872 },
    { lat: 49.4716937, lng: 18.6292306 },
    { lat: 49.4717291, lng: 18.6292207 },
    { lat: 49.4717868, lng: 18.6292034 },
    { lat: 49.4718165, lng: 18.6291885 },
    { lat: 49.4718726, lng: 18.6291604 },
    { lat: 49.4719023, lng: 18.6291455 },
    { lat: 49.4719242, lng: 18.6291341 },
    { lat: 49.4719791, lng: 18.6291047 },
    { lat: 49.4720199, lng: 18.6290054 },
    { lat: 49.4720366, lng: 18.6288738 },
    { lat: 49.4720392, lng: 18.6287093 },
    { lat: 49.4719878, lng: 18.6284397 },
    { lat: 49.4720372, lng: 18.6282881 },
    { lat: 49.47227, lng: 18.6281275 },
    { lat: 49.4725158, lng: 18.6279547 },
    { lat: 49.47267, lng: 18.6278459 },
    { lat: 49.4729296, lng: 18.6275496 },
    { lat: 49.4730697, lng: 18.6272141 },
    { lat: 49.4732284, lng: 18.6269078 },
    { lat: 49.4734, lng: 18.6268299 },
    { lat: 49.4735978, lng: 18.6268788 },
    { lat: 49.4739417, lng: 18.6262822 },
    { lat: 49.4745398, lng: 18.6257159 },
    { lat: 49.4757791, lng: 18.6249514 },
    { lat: 49.4768036, lng: 18.6244482 },
    { lat: 49.4770439, lng: 18.6242786 },
    { lat: 49.4775436, lng: 18.6237282 },
    { lat: 49.4776817, lng: 18.6236326 },
    { lat: 49.4777414, lng: 18.6235907 },
    { lat: 49.4779002, lng: 18.6234791 },
    { lat: 49.4781146, lng: 18.6233741 },
    { lat: 49.4785363, lng: 18.6232689 },
    { lat: 49.4790083, lng: 18.6230032 },
    { lat: 49.4793518, lng: 18.6228469 },
    { lat: 49.4794515, lng: 18.6228761 },
    { lat: 49.4794824, lng: 18.622885 },
    { lat: 49.4796479, lng: 18.6227073 },
    { lat: 49.4800228, lng: 18.6227816 },
    { lat: 49.4802761, lng: 18.6231388 },
    { lat: 49.4805397, lng: 18.6233681 },
    { lat: 49.4809395, lng: 18.6235453 },
    { lat: 49.4812269, lng: 18.6237397 },
    { lat: 49.4813594, lng: 18.6238759 },
    { lat: 49.4816061, lng: 18.6242324 },
    { lat: 49.4824565, lng: 18.6249982 },
    { lat: 49.4826788, lng: 18.6251326 },
    { lat: 49.4826991, lng: 18.6251529 },
    { lat: 49.4828922, lng: 18.6252659 },
    { lat: 49.4829078, lng: 18.6252832 },
    { lat: 49.4831704, lng: 18.6250669 },
    { lat: 49.483187, lng: 18.6250804 },
    { lat: 49.4835481, lng: 18.6249795 },
    { lat: 49.483811, lng: 18.6248963 },
    { lat: 49.4841212, lng: 18.6248769 },
    { lat: 49.4843187, lng: 18.6248587 },
    { lat: 49.4847531, lng: 18.6248016 },
    { lat: 49.4852241, lng: 18.6245173 },
    { lat: 49.4854653, lng: 18.6243642 },
    { lat: 49.4857595, lng: 18.6241277 },
    { lat: 49.4859172, lng: 18.6239332 },
    { lat: 49.4860745, lng: 18.6236473 },
    { lat: 49.4862777, lng: 18.6233521 },
    { lat: 49.4863967, lng: 18.6231493 },
    { lat: 49.4864782, lng: 18.623051 },
    { lat: 49.4865852, lng: 18.622836 },
    { lat: 49.486609, lng: 18.6227949 },
    { lat: 49.4870768, lng: 18.6219625 },
    { lat: 49.4873076, lng: 18.6215688 },
    { lat: 49.4878306, lng: 18.6212061 },
    { lat: 49.4883479, lng: 18.6211125 },
    { lat: 49.4887759, lng: 18.6209125 },
    { lat: 49.4894261, lng: 18.6204555 },
    { lat: 49.4898514, lng: 18.6201826 },
    { lat: 49.4900219, lng: 18.6201892 },
    { lat: 49.4900298, lng: 18.62021 },
    { lat: 49.4900723, lng: 18.6202446 },
    { lat: 49.4902183, lng: 18.6204661 },
    { lat: 49.4905114, lng: 18.6209367 },
    { lat: 49.490784, lng: 18.6210147 },
    { lat: 49.4913172, lng: 18.6220107 },
    { lat: 49.4917491, lng: 18.6222459 },
    { lat: 49.4922394, lng: 18.6231333 },
    { lat: 49.4931115, lng: 18.622843 },
    { lat: 49.493311, lng: 18.6227768 },
    { lat: 49.4938333, lng: 18.6225461 },
    { lat: 49.4938494, lng: 18.622555 },
    { lat: 49.495789, lng: 18.62149 },
    { lat: 49.495531, lng: 18.620321 },
    { lat: 49.495292, lng: 18.618977 },
    { lat: 49.495184, lng: 18.61859 },
    { lat: 49.495062, lng: 18.617747 },
    { lat: 49.495266, lng: 18.616906 },
    { lat: 49.495223, lng: 18.616221 },
    { lat: 49.495244, lng: 18.615704 },
    { lat: 49.495251, lng: 18.61554 },
    { lat: 49.495586, lng: 18.614994 },
    { lat: 49.496019, lng: 18.614277 },
    { lat: 49.496064, lng: 18.613844 },
    { lat: 49.496143, lng: 18.613082 },
    { lat: 49.496263, lng: 18.612751 },
    { lat: 49.496705, lng: 18.611533 },
    { lat: 49.49673, lng: 18.611444 },
    { lat: 49.496735, lng: 18.611305 },
    { lat: 49.496703, lng: 18.610889 },
    { lat: 49.496605, lng: 18.610307 },
    { lat: 49.496539, lng: 18.609985 },
    { lat: 49.496511, lng: 18.609424 },
    { lat: 49.496499, lng: 18.609285 },
    { lat: 49.496509, lng: 18.609106 },
    { lat: 49.496495, lng: 18.608765 },
    { lat: 49.496489, lng: 18.608405 },
    { lat: 49.496509, lng: 18.607878 },
    { lat: 49.496511, lng: 18.607196 },
    { lat: 49.496489, lng: 18.606392 },
    { lat: 49.496524, lng: 18.606008 },
    { lat: 49.496566, lng: 18.605843 },
    { lat: 49.496646, lng: 18.60567 },
    { lat: 49.4967, lng: 18.605434 },
    { lat: 49.496761, lng: 18.604999 },
    { lat: 49.496784, lng: 18.604899 },
    { lat: 49.496867, lng: 18.604647 },
    { lat: 49.496908, lng: 18.604172 },
    { lat: 49.496948, lng: 18.603992 },
    { lat: 49.497061, lng: 18.603834 },
    { lat: 49.497098, lng: 18.603741 },
    { lat: 49.497102, lng: 18.603631 },
    { lat: 49.497083, lng: 18.603507 },
    { lat: 49.497075, lng: 18.602839 },
    { lat: 49.497085, lng: 18.602816 },
    { lat: 49.497158, lng: 18.60276 },
    { lat: 49.497319, lng: 18.602572 },
    { lat: 49.497442, lng: 18.602476 },
    { lat: 49.497577, lng: 18.602314 },
    { lat: 49.497751, lng: 18.602159 },
    { lat: 49.497972, lng: 18.602007 },
    { lat: 49.498203, lng: 18.601793 },
    { lat: 49.498291, lng: 18.601672 },
    { lat: 49.498435, lng: 18.601489 },
    { lat: 49.498537, lng: 18.601417 },
    { lat: 49.49876, lng: 18.601265 },
    { lat: 49.49906, lng: 18.601168 },
    { lat: 49.499179, lng: 18.601199 },
    { lat: 49.499381, lng: 18.601374 },
    { lat: 49.499695, lng: 18.601778 },
    { lat: 49.499759, lng: 18.601847 },
    { lat: 49.499983, lng: 18.602018 },
    { lat: 49.500123, lng: 18.602137 },
    { lat: 49.500233, lng: 18.602237 },
    { lat: 49.500329, lng: 18.602285 },
    { lat: 49.500504, lng: 18.602338 },
    { lat: 49.500585, lng: 18.60234 },
    { lat: 49.500759, lng: 18.602301 },
    { lat: 49.500878, lng: 18.602318 },
    { lat: 49.501009, lng: 18.602375 },
    { lat: 49.501145, lng: 18.602416 },
    { lat: 49.501258, lng: 18.602421 },
    { lat: 49.502128, lng: 18.602509 },
    { lat: 49.502363, lng: 18.602539 },
    { lat: 49.502623, lng: 18.602573 },
    { lat: 49.50332, lng: 18.602394 },
    { lat: 49.504145, lng: 18.602061 },
    { lat: 49.50458, lng: 18.601988 },
    { lat: 49.505097, lng: 18.601905 },
    { lat: 49.50555, lng: 18.601971 },
    { lat: 49.505844, lng: 18.602013 },
    { lat: 49.506517, lng: 18.601959 },
    { lat: 49.506852, lng: 18.601907 },
    { lat: 49.507186, lng: 18.601692 },
    { lat: 49.507538, lng: 18.601384 },
    { lat: 49.507966, lng: 18.600654 },
    { lat: 49.508536, lng: 18.599538 },
    { lat: 49.50903, lng: 18.598853 },
    { lat: 49.509478, lng: 18.598231 },
    { lat: 49.510237, lng: 18.5972528 },
  ],
  Klubina: [
    { lat: 49.418386, lng: 18.97941 },
    { lat: 49.4195509, lng: 18.9779893 },
    { lat: 49.4204417, lng: 18.9749281 },
    { lat: 49.4208277, lng: 18.9737863 },
    { lat: 49.4214921, lng: 18.972443 },
    { lat: 49.4215688, lng: 18.9719046 },
    { lat: 49.4214956, lng: 18.9713183 },
    { lat: 49.4211456, lng: 18.9704763 },
    { lat: 49.4209924, lng: 18.9694634 },
    { lat: 49.4207553, lng: 18.968464 },
    { lat: 49.4207331, lng: 18.9673694 },
    { lat: 49.420965, lng: 18.9672969 },
    { lat: 49.4211778, lng: 18.9672458 },
    { lat: 49.4213531, lng: 18.9671889 },
    { lat: 49.4215909, lng: 18.9669426 },
    { lat: 49.4218989, lng: 18.96663 },
    { lat: 49.4221168, lng: 18.9665005 },
    { lat: 49.4222379, lng: 18.9663761 },
    { lat: 49.4223416, lng: 18.9661496 },
    { lat: 49.422327, lng: 18.9660854 },
    { lat: 49.4223248, lng: 18.9655019 },
    { lat: 49.4222126, lng: 18.9649046 },
    { lat: 49.4221002, lng: 18.9643714 },
    { lat: 49.4219922, lng: 18.9637008 },
    { lat: 49.4219282, lng: 18.9628067 },
    { lat: 49.4219031, lng: 18.9621444 },
    { lat: 49.4217461, lng: 18.9610827 },
    { lat: 49.4217093, lng: 18.9604175 },
    { lat: 49.4216866, lng: 18.9603243 },
    { lat: 49.421771, lng: 18.9592888 },
    { lat: 49.4218052, lng: 18.9584154 },
    { lat: 49.4218116, lng: 18.9584006 },
    { lat: 49.4217688, lng: 18.9582313 },
    { lat: 49.4217075, lng: 18.9579746 },
    { lat: 49.4216009, lng: 18.9576728 },
    { lat: 49.4214615, lng: 18.9574742 },
    { lat: 49.421174, lng: 18.9571829 },
    { lat: 49.4208675, lng: 18.9568931 },
    { lat: 49.4206725, lng: 18.9565793 },
    { lat: 49.4202323, lng: 18.9561623 },
    { lat: 49.4199676, lng: 18.95583 },
    { lat: 49.4197529, lng: 18.9556424 },
    { lat: 49.419656, lng: 18.955463 },
    { lat: 49.4196422, lng: 18.9552531 },
    { lat: 49.4196747, lng: 18.9547814 },
    { lat: 49.4198082, lng: 18.9538021 },
    { lat: 49.4197488, lng: 18.9532689 },
    { lat: 49.4195208, lng: 18.9523167 },
    { lat: 49.4191937, lng: 18.9516647 },
    { lat: 49.4186699, lng: 18.9508394 },
    { lat: 49.4179643, lng: 18.9504403 },
    { lat: 49.4174875, lng: 18.9502395 },
    { lat: 49.4172361, lng: 18.9500784 },
    { lat: 49.4165974, lng: 18.950762 },
    { lat: 49.415768, lng: 18.9520685 },
    { lat: 49.4145466, lng: 18.9537238 },
    { lat: 49.4130725, lng: 18.9549415 },
    { lat: 49.4124836, lng: 18.9553831 },
    { lat: 49.4122886, lng: 18.955518 },
    { lat: 49.4120966, lng: 18.955151 },
    { lat: 49.4120618, lng: 18.9551551 },
    { lat: 49.4117456, lng: 18.9544933 },
    { lat: 49.4117, lng: 18.9543466 },
    { lat: 49.4114384, lng: 18.9537196 },
    { lat: 49.4109496, lng: 18.9525359 },
    { lat: 49.4104001, lng: 18.9512242 },
    { lat: 49.4102252, lng: 18.9508824 },
    { lat: 49.4097738, lng: 18.9496175 },
    { lat: 49.4093362, lng: 18.9485588 },
    { lat: 49.4090346, lng: 18.9475152 },
    { lat: 49.4087022, lng: 18.9457244 },
    { lat: 49.4083751, lng: 18.9447541 },
    { lat: 49.4079421, lng: 18.9430957 },
    { lat: 49.407415, lng: 18.9418917 },
    { lat: 49.4066041, lng: 18.9392637 },
    { lat: 49.4063194, lng: 18.938001 },
    { lat: 49.405971, lng: 18.9373497 },
    { lat: 49.4052907, lng: 18.9357026 },
    { lat: 49.4050113, lng: 18.9346536 },
    { lat: 49.4048976, lng: 18.9344564 },
    { lat: 49.4056728, lng: 18.9329058 },
    { lat: 49.4057647, lng: 18.9326723 },
    { lat: 49.4057916, lng: 18.9324219 },
    { lat: 49.4056079, lng: 18.9313706 },
    { lat: 49.405545, lng: 18.9310649 },
    { lat: 49.4052871, lng: 18.9304833 },
    { lat: 49.4047781, lng: 18.9302517 },
    { lat: 49.4040802, lng: 18.9305874 },
    { lat: 49.4039448, lng: 18.9306042 },
    { lat: 49.4036754, lng: 18.9306505 },
    { lat: 49.4031364, lng: 18.9311386 },
    { lat: 49.4013479, lng: 18.9302115 },
    { lat: 49.4010559, lng: 18.9299847 },
    { lat: 49.4006357, lng: 18.9286984 },
    { lat: 49.4003589, lng: 18.927743 },
    { lat: 49.3999957, lng: 18.9272264 },
    { lat: 49.3987862, lng: 18.9262892 },
    { lat: 49.3973729, lng: 18.9253179 },
    { lat: 49.3967345, lng: 18.9249262 },
    { lat: 49.395269, lng: 18.9242601 },
    { lat: 49.3935137, lng: 18.9229839 },
    { lat: 49.3929425, lng: 18.922344 },
    { lat: 49.3914396, lng: 18.9215163 },
    { lat: 49.3906002, lng: 18.9206542 },
    { lat: 49.390445, lng: 18.9203024 },
    { lat: 49.3897983, lng: 18.9191092 },
    { lat: 49.389763, lng: 18.9191673 },
    { lat: 49.3888708, lng: 18.9187328 },
    { lat: 49.3887302, lng: 18.918406 },
    { lat: 49.388014, lng: 18.9178645 },
    { lat: 49.3879343, lng: 18.9178244 },
    { lat: 49.3874744, lng: 18.9180736 },
    { lat: 49.3872431, lng: 18.918134 },
    { lat: 49.3860567, lng: 18.9184187 },
    { lat: 49.383912, lng: 18.9157097 },
    { lat: 49.381309, lng: 18.9131231 },
    { lat: 49.3808182, lng: 18.9130044 },
    { lat: 49.3798097, lng: 18.9125039 },
    { lat: 49.3793691, lng: 18.9123724 },
    { lat: 49.3787577, lng: 18.9124337 },
    { lat: 49.3785576, lng: 18.9124882 },
    { lat: 49.3785354, lng: 18.9125831 },
    { lat: 49.3774394, lng: 18.9123336 },
    { lat: 49.3773286, lng: 18.912417 },
    { lat: 49.37722, lng: 18.9124078 },
    { lat: 49.3766976, lng: 18.9121315 },
    { lat: 49.3765812, lng: 18.9120154 },
    { lat: 49.375409, lng: 18.9109747 },
    { lat: 49.374577, lng: 18.9097946 },
    { lat: 49.3744597, lng: 18.9097933 },
    { lat: 49.374343, lng: 18.9096907 },
    { lat: 49.3722272, lng: 18.9082509 },
    { lat: 49.3713645, lng: 18.9076638 },
    { lat: 49.3710045, lng: 18.9072783 },
    { lat: 49.3707389, lng: 18.9070667 },
    { lat: 49.3702586, lng: 18.9064404 },
    { lat: 49.3696116, lng: 18.9056859 },
    { lat: 49.3694306, lng: 18.9053634 },
    { lat: 49.3686633, lng: 18.9036577 },
    { lat: 49.3686091, lng: 18.9035669 },
    { lat: 49.3683491, lng: 18.9031315 },
    { lat: 49.3678986, lng: 18.9037844 },
    { lat: 49.3674438, lng: 18.9040859 },
    { lat: 49.367049, lng: 18.9043054 },
    { lat: 49.3665466, lng: 18.9042464 },
    { lat: 49.3661645, lng: 18.9039587 },
    { lat: 49.3657821, lng: 18.903289 },
    { lat: 49.3653372, lng: 18.9021185 },
    { lat: 49.3646435, lng: 18.9006857 },
    { lat: 49.3643463, lng: 18.9001177 },
    { lat: 49.3641666, lng: 18.8997513 },
    { lat: 49.3644131, lng: 18.8994785 },
    { lat: 49.3643721, lng: 18.899359 },
    { lat: 49.3643836, lng: 18.8992502 },
    { lat: 49.3644257, lng: 18.8988378 },
    { lat: 49.364261, lng: 18.8979705 },
    { lat: 49.3642085, lng: 18.8974012 },
    { lat: 49.3642428, lng: 18.8945674 },
    { lat: 49.3643335, lng: 18.8935793 },
    { lat: 49.3643403, lng: 18.8925132 },
    { lat: 49.3643796, lng: 18.8921982 },
    { lat: 49.3643558, lng: 18.8921305 },
    { lat: 49.3644944, lng: 18.8920174 },
    { lat: 49.364623, lng: 18.8915311 },
    { lat: 49.3645839, lng: 18.8914034 },
    { lat: 49.3647483, lng: 18.8911132 },
    { lat: 49.3646616, lng: 18.8908016 },
    { lat: 49.3644886, lng: 18.8907737 },
    { lat: 49.3645312, lng: 18.890259 },
    { lat: 49.3646369, lng: 18.8899389 },
    { lat: 49.3646578, lng: 18.8896861 },
    { lat: 49.3645731, lng: 18.8893953 },
    { lat: 49.3645809, lng: 18.8891943 },
    { lat: 49.3644404, lng: 18.8892009 },
    { lat: 49.364499, lng: 18.8888253 },
    { lat: 49.3649304, lng: 18.8866625 },
    { lat: 49.3650884, lng: 18.8854684 },
    { lat: 49.3651898, lng: 18.8846924 },
    { lat: 49.3652548, lng: 18.8819572 },
    { lat: 49.3643785, lng: 18.8803536 },
    { lat: 49.364289, lng: 18.879728 },
    { lat: 49.3643277, lng: 18.8774308 },
    { lat: 49.3646455, lng: 18.8756999 },
    { lat: 49.3646055, lng: 18.8748731 },
    { lat: 49.3647891, lng: 18.873121 },
    { lat: 49.3647109, lng: 18.8721388 },
    { lat: 49.3650861, lng: 18.8718227 },
    { lat: 49.3651205, lng: 18.8717937 },
    { lat: 49.3657432, lng: 18.8712696 },
    { lat: 49.3653178, lng: 18.8708853 },
    { lat: 49.3650911, lng: 18.8705083 },
    { lat: 49.3645309, lng: 18.869714 },
    { lat: 49.3640173, lng: 18.8689495 },
    { lat: 49.363665, lng: 18.8687395 },
    { lat: 49.3629346, lng: 18.8676386 },
    { lat: 49.3627683, lng: 18.8668095 },
    { lat: 49.3626602, lng: 18.8662144 },
    { lat: 49.3625463, lng: 18.8658576 },
    { lat: 49.3624044, lng: 18.8652198 },
    { lat: 49.3623714, lng: 18.865129 },
    { lat: 49.3622961, lng: 18.8649546 },
    { lat: 49.3621689, lng: 18.8647159 },
    { lat: 49.3621288, lng: 18.8646581 },
    { lat: 49.3620959, lng: 18.8645677 },
    { lat: 49.3620709, lng: 18.864494 },
    { lat: 49.3619501, lng: 18.8642071 },
    { lat: 49.3616928, lng: 18.8634747 },
    { lat: 49.3616509, lng: 18.8633567 },
    { lat: 49.3614428, lng: 18.8625283 },
    { lat: 49.3614026, lng: 18.8622271 },
    { lat: 49.3613725, lng: 18.8619012 },
    { lat: 49.3613096, lng: 18.8614892 },
    { lat: 49.3612352, lng: 18.860758 },
    { lat: 49.361187, lng: 18.8605453 },
    { lat: 49.3611206, lng: 18.8604567 },
    { lat: 49.3610296, lng: 18.8603319 },
    { lat: 49.3608847, lng: 18.8600559 },
    { lat: 49.3604581, lng: 18.8596277 },
    { lat: 49.3601414, lng: 18.8593198 },
    { lat: 49.3600833, lng: 18.8593058 },
    { lat: 49.3599284, lng: 18.859146 },
    { lat: 49.3596947, lng: 18.8590478 },
    { lat: 49.3596238, lng: 18.8590986 },
    { lat: 49.3596011, lng: 18.8590941 },
    { lat: 49.3595829, lng: 18.8590695 },
    { lat: 49.3595535, lng: 18.8589992 },
    { lat: 49.3594216, lng: 18.8585343 },
    { lat: 49.3593508, lng: 18.8580777 },
    { lat: 49.3593603, lng: 18.8578208 },
    { lat: 49.3593548, lng: 18.8577126 },
    { lat: 49.359446, lng: 18.8575364 },
    { lat: 49.3595513, lng: 18.8571237 },
    { lat: 49.3596119, lng: 18.8570119 },
    { lat: 49.3596813, lng: 18.8568908 },
    { lat: 49.3596999, lng: 18.8567909 },
    { lat: 49.359645, lng: 18.856664 },
    { lat: 49.3596805, lng: 18.8564127 },
    { lat: 49.3596285, lng: 18.8562319 },
    { lat: 49.3594366, lng: 18.8560263 },
    { lat: 49.3593831, lng: 18.8559495 },
    { lat: 49.3592909, lng: 18.8556719 },
    { lat: 49.358923, lng: 18.8552639 },
    { lat: 49.3587988, lng: 18.8548852 },
    { lat: 49.35879, lng: 18.8546483 },
    { lat: 49.3587693, lng: 18.8543318 },
    { lat: 49.3588424, lng: 18.853779 },
    { lat: 49.3589039, lng: 18.8535445 },
    { lat: 49.3589023, lng: 18.8533617 },
    { lat: 49.3588944, lng: 18.8533174 },
    { lat: 49.3590158, lng: 18.8520507 },
    { lat: 49.3590732, lng: 18.8517002 },
    { lat: 49.3590845, lng: 18.8512134 },
    { lat: 49.3590555, lng: 18.8505354 },
    { lat: 49.3590215, lng: 18.8503156 },
    { lat: 49.3589404, lng: 18.8500034 },
    { lat: 49.3586571, lng: 18.8489176 },
    { lat: 49.3583842, lng: 18.8481922 },
    { lat: 49.3581818, lng: 18.8474088 },
    { lat: 49.3579583, lng: 18.8465227 },
    { lat: 49.3579546, lng: 18.8463861 },
    { lat: 49.358001, lng: 18.8459259 },
    { lat: 49.358042, lng: 18.845875 },
    { lat: 49.357889, lng: 18.845941 },
    { lat: 49.357672, lng: 18.846444 },
    { lat: 49.35715, lng: 18.846726 },
    { lat: 49.356944, lng: 18.84695 },
    { lat: 49.356895, lng: 18.847191 },
    { lat: 49.357025, lng: 18.847899 },
    { lat: 49.357037, lng: 18.848595 },
    { lat: 49.356851, lng: 18.84973 },
    { lat: 49.356373, lng: 18.850514 },
    { lat: 49.356062, lng: 18.851333 },
    { lat: 49.356091, lng: 18.851793 },
    { lat: 49.355992, lng: 18.852014 },
    { lat: 49.355885, lng: 18.852918 },
    { lat: 49.355932, lng: 18.853305 },
    { lat: 49.355756, lng: 18.853877 },
    { lat: 49.355738, lng: 18.854362 },
    { lat: 49.355546, lng: 18.85468 },
    { lat: 49.355381, lng: 18.855135 },
    { lat: 49.355279, lng: 18.85553 },
    { lat: 49.355159, lng: 18.856426 },
    { lat: 49.355034, lng: 18.8569 },
    { lat: 49.354849, lng: 18.857276 },
    { lat: 49.354102, lng: 18.859607 },
    { lat: 49.353778, lng: 18.860184 },
    { lat: 49.35365, lng: 18.860743 },
    { lat: 49.353029, lng: 18.862816 },
    { lat: 49.352642, lng: 18.863359 },
    { lat: 49.352564, lng: 18.863611 },
    { lat: 49.352503, lng: 18.86413 },
    { lat: 49.352523, lng: 18.865019 },
    { lat: 49.351944, lng: 18.865984 },
    { lat: 49.351849, lng: 18.866467 },
    { lat: 49.351804, lng: 18.867535 },
    { lat: 49.351717, lng: 18.868005 },
    { lat: 49.351572, lng: 18.869717 },
    { lat: 49.35094, lng: 18.869869 },
    { lat: 49.3502, lng: 18.869928 },
    { lat: 49.349809, lng: 18.869894 },
    { lat: 49.349041, lng: 18.869684 },
    { lat: 49.348261, lng: 18.869222 },
    { lat: 49.347196, lng: 18.868741 },
    { lat: 49.346776, lng: 18.868373 },
    { lat: 49.346351, lng: 18.868837 },
    { lat: 49.346185, lng: 18.86952 },
    { lat: 49.345979, lng: 18.869981 },
    { lat: 49.345796, lng: 18.870638 },
    { lat: 49.345747, lng: 18.871196 },
    { lat: 49.345763, lng: 18.872377 },
    { lat: 49.345389, lng: 18.874263 },
    { lat: 49.345189, lng: 18.874619 },
    { lat: 49.344574, lng: 18.875183 },
    { lat: 49.344232, lng: 18.875708 },
    { lat: 49.344089, lng: 18.876186 },
    { lat: 49.343744, lng: 18.876758 },
    { lat: 49.343602, lng: 18.877208 },
    { lat: 49.343022, lng: 18.878401 },
    { lat: 49.341458, lng: 18.880889 },
    { lat: 49.341169, lng: 18.882762 },
    { lat: 49.341084, lng: 18.884238 },
    { lat: 49.34093, lng: 18.885122 },
    { lat: 49.340807, lng: 18.8855 },
    { lat: 49.340546, lng: 18.885832 },
    { lat: 49.340026, lng: 18.886855 },
    { lat: 49.339894, lng: 18.88705 },
    { lat: 49.3399153, lng: 18.8870991 },
    { lat: 49.3405243, lng: 18.8873252 },
    { lat: 49.3414018, lng: 18.8888842 },
    { lat: 49.3416046, lng: 18.8889703 },
    { lat: 49.3416698, lng: 18.8892767 },
    { lat: 49.3419761, lng: 18.8900682 },
    { lat: 49.3425337, lng: 18.8902148 },
    { lat: 49.3429208, lng: 18.8905335 },
    { lat: 49.3437884, lng: 18.8913664 },
    { lat: 49.344771, lng: 18.8917777 },
    { lat: 49.3455019, lng: 18.8920747 },
    { lat: 49.3456367, lng: 18.8920637 },
    { lat: 49.3457963, lng: 18.892275 },
    { lat: 49.3464954, lng: 18.8925874 },
    { lat: 49.3469573, lng: 18.8930484 },
    { lat: 49.3472267, lng: 18.8931393 },
    { lat: 49.3476247, lng: 18.8935606 },
    { lat: 49.3478326, lng: 18.8935709 },
    { lat: 49.3481887, lng: 18.8937669 },
    { lat: 49.3483447, lng: 18.8939512 },
    { lat: 49.3485232, lng: 18.8939567 },
    { lat: 49.3487474, lng: 18.8942656 },
    { lat: 49.3489225, lng: 18.8945398 },
    { lat: 49.3489506, lng: 18.8945824 },
    { lat: 49.3492153, lng: 18.8949971 },
    { lat: 49.3498935, lng: 18.8959467 },
    { lat: 49.3503758, lng: 18.896716 },
    { lat: 49.3510642, lng: 18.8974 },
    { lat: 49.3511914, lng: 18.8973921 },
    { lat: 49.3512054, lng: 18.8974042 },
    { lat: 49.3512361, lng: 18.897432 },
    { lat: 49.3512527, lng: 18.8974474 },
    { lat: 49.3514168, lng: 18.8976174 },
    { lat: 49.3514313, lng: 18.8976334 },
    { lat: 49.3514933, lng: 18.8977005 },
    { lat: 49.3515149, lng: 18.8977247 },
    { lat: 49.3515438, lng: 18.8978283 },
    { lat: 49.3533518, lng: 18.8997571 },
    { lat: 49.3550168, lng: 18.9015323 },
    { lat: 49.3551575, lng: 18.901619 },
    { lat: 49.3558278, lng: 18.90256 },
    { lat: 49.3559076, lng: 18.9028025 },
    { lat: 49.3559437, lng: 18.9029599 },
    { lat: 49.3564332, lng: 18.9034312 },
    { lat: 49.3565453, lng: 18.9036613 },
    { lat: 49.3577764, lng: 18.9057505 },
    { lat: 49.3578637, lng: 18.906028 },
    { lat: 49.3586424, lng: 18.9071566 },
    { lat: 49.3589665, lng: 18.9075264 },
    { lat: 49.3592851, lng: 18.9079613 },
    { lat: 49.3595577, lng: 18.9083807 },
    { lat: 49.3605562, lng: 18.9100402 },
    { lat: 49.3615559, lng: 18.9115282 },
    { lat: 49.3618504, lng: 18.9118665 },
    { lat: 49.3626784, lng: 18.9126599 },
    { lat: 49.3630426, lng: 18.9129245 },
    { lat: 49.3643389, lng: 18.9135241 },
    { lat: 49.3649496, lng: 18.9137208 },
    { lat: 49.3654741, lng: 18.9138561 },
    { lat: 49.3666159, lng: 18.9140317 },
    { lat: 49.3668292, lng: 18.9141233 },
    { lat: 49.3673519, lng: 18.9144954 },
    { lat: 49.3680155, lng: 18.915024 },
    { lat: 49.3683479, lng: 18.9151781 },
    { lat: 49.368659, lng: 18.9154198 },
    { lat: 49.3688029, lng: 18.9155007 },
    { lat: 49.3690374, lng: 18.915504 },
    { lat: 49.3693803, lng: 18.9155531 },
    { lat: 49.369764, lng: 18.9158469 },
    { lat: 49.3702084, lng: 18.9159425 },
    { lat: 49.3706122, lng: 18.9163262 },
    { lat: 49.3713111, lng: 18.9168153 },
    { lat: 49.3715093, lng: 18.9168981 },
    { lat: 49.371819, lng: 18.9170058 },
    { lat: 49.3721802, lng: 18.9171688 },
    { lat: 49.3725489, lng: 18.917459 },
    { lat: 49.3732883, lng: 18.9178127 },
    { lat: 49.3742991, lng: 18.918297 },
    { lat: 49.3746644, lng: 18.9182765 },
    { lat: 49.3750604, lng: 18.9182353 },
    { lat: 49.3753233, lng: 18.9183105 },
    { lat: 49.3756483, lng: 18.9183777 },
    { lat: 49.3757951, lng: 18.9184981 },
    { lat: 49.3763789, lng: 18.9190975 },
    { lat: 49.3768832, lng: 18.9195251 },
    { lat: 49.3777595, lng: 18.9202953 },
    { lat: 49.3787417, lng: 18.9211685 },
    { lat: 49.3788457, lng: 18.9211732 },
    { lat: 49.3790702, lng: 18.9210684 },
    { lat: 49.3794109, lng: 18.9211612 },
    { lat: 49.3804774, lng: 18.9213387 },
    { lat: 49.3812509, lng: 18.9219653 },
    { lat: 49.3824068, lng: 18.9241423 },
    { lat: 49.3824832, lng: 18.9250277 },
    { lat: 49.3828131, lng: 18.9260695 },
    { lat: 49.383522, lng: 18.9270284 },
    { lat: 49.3839, lng: 18.9272775 },
    { lat: 49.3839399, lng: 18.9274866 },
    { lat: 49.3842144, lng: 18.9277031 },
    { lat: 49.3843473, lng: 18.9280981 },
    { lat: 49.3846672, lng: 18.9285436 },
    { lat: 49.3848694, lng: 18.9285718 },
    { lat: 49.3851372, lng: 18.9287438 },
    { lat: 49.3852742, lng: 18.9290005 },
    { lat: 49.3857495, lng: 18.929348 },
    { lat: 49.3861933, lng: 18.9302349 },
    { lat: 49.386967, lng: 18.9311349 },
    { lat: 49.3872641, lng: 18.931124 },
    { lat: 49.3873417, lng: 18.9312357 },
    { lat: 49.3876433, lng: 18.932654 },
    { lat: 49.3875001, lng: 18.9338384 },
    { lat: 49.386805, lng: 18.9360205 },
    { lat: 49.3866578, lng: 18.9366353 },
    { lat: 49.3865261, lng: 18.9395694 },
    { lat: 49.3865531, lng: 18.9397008 },
    { lat: 49.3865038, lng: 18.9406497 },
    { lat: 49.3862396, lng: 18.9421175 },
    { lat: 49.3859461, lng: 18.9429602 },
    { lat: 49.3855369, lng: 18.9436544 },
    { lat: 49.3853704, lng: 18.9441094 },
    { lat: 49.3853457, lng: 18.9445161 },
    { lat: 49.3855509, lng: 18.9460877 },
    { lat: 49.3856656, lng: 18.9464852 },
    { lat: 49.3858912, lng: 18.9469351 },
    { lat: 49.3861198, lng: 18.9478027 },
    { lat: 49.3865781, lng: 18.9488469 },
    { lat: 49.3867914, lng: 18.9492317 },
    { lat: 49.3870383, lng: 18.9500179 },
    { lat: 49.3871531, lng: 18.9509575 },
    { lat: 49.3871046, lng: 18.9512473 },
    { lat: 49.3871138, lng: 18.9516088 },
    { lat: 49.3901759, lng: 18.9539072 },
    { lat: 49.3899269, lng: 18.9551905 },
    { lat: 49.3897124, lng: 18.9557648 },
    { lat: 49.3898728, lng: 18.958067 },
    { lat: 49.3900747, lng: 18.9586718 },
    { lat: 49.3906672, lng: 18.9595621 },
    { lat: 49.3912481, lng: 18.9582882 },
    { lat: 49.3923077, lng: 18.9577491 },
    { lat: 49.3932558, lng: 18.956919 },
    { lat: 49.3936352, lng: 18.956035 },
    { lat: 49.3934964, lng: 18.955692 },
    { lat: 49.3943239, lng: 18.9549311 },
    { lat: 49.3966821, lng: 18.9562768 },
    { lat: 49.3968356, lng: 18.9551121 },
    { lat: 49.3968415, lng: 18.9546 },
    { lat: 49.3970471, lng: 18.954296 },
    { lat: 49.3971756, lng: 18.9529026 },
    { lat: 49.3969972, lng: 18.9513691 },
    { lat: 49.3965963, lng: 18.9499864 },
    { lat: 49.3970698, lng: 18.9493637 },
    { lat: 49.3973877, lng: 18.9490464 },
    { lat: 49.4018444, lng: 18.9525801 },
    { lat: 49.402165, lng: 18.9531262 },
    { lat: 49.4025811, lng: 18.953509 },
    { lat: 49.4042753, lng: 18.9561837 },
    { lat: 49.4042108, lng: 18.9576838 },
    { lat: 49.4042684, lng: 18.9580224 },
    { lat: 49.4049392, lng: 18.9587173 },
    { lat: 49.4051094, lng: 18.9586972 },
    { lat: 49.4052963, lng: 18.9589138 },
    { lat: 49.4059462, lng: 18.9609068 },
    { lat: 49.4070094, lng: 18.9633141 },
    { lat: 49.407871, lng: 18.9632782 },
    { lat: 49.4085955, lng: 18.9650034 },
    { lat: 49.4098587, lng: 18.9685242 },
    { lat: 49.409993, lng: 18.969168 },
    { lat: 49.409999, lng: 18.969166 },
    { lat: 49.410916, lng: 18.968902 },
    { lat: 49.411639, lng: 18.96869 },
    { lat: 49.412452, lng: 18.968701 },
    { lat: 49.412695, lng: 18.9687 },
    { lat: 49.4132432, lng: 18.968744 },
    { lat: 49.4133304, lng: 18.968751 },
    { lat: 49.4136032, lng: 18.9694748 },
    { lat: 49.413911, lng: 18.970163 },
    { lat: 49.414856, lng: 18.972248 },
    { lat: 49.415301, lng: 18.973028 },
    { lat: 49.415514, lng: 18.973878 },
    { lat: 49.415918, lng: 18.975359 },
    { lat: 49.416331, lng: 18.976106 },
    { lat: 49.4172, lng: 18.977935 },
    { lat: 49.418386, lng: 18.97941 },
  ],
  Korňa: [
    { lat: 49.3946369, lng: 18.5753615 },
    { lat: 49.3946604, lng: 18.5754031 },
    { lat: 49.3956338, lng: 18.5771361 },
    { lat: 49.3963018, lng: 18.5783242 },
    { lat: 49.3967565, lng: 18.5794874 },
    { lat: 49.3967376, lng: 18.5794916 },
    { lat: 49.3967603, lng: 18.5799605 },
    { lat: 49.3967761, lng: 18.5799593 },
    { lat: 49.3975107, lng: 18.5808541 },
    { lat: 49.3978214, lng: 18.5817747 },
    { lat: 49.3980993, lng: 18.5822869 },
    { lat: 49.3981081, lng: 18.5823054 },
    { lat: 49.3983551, lng: 18.5828107 },
    { lat: 49.3992884, lng: 18.5844049 },
    { lat: 49.3993313, lng: 18.5848724 },
    { lat: 49.3998769, lng: 18.5846769 },
    { lat: 49.4003181, lng: 18.5855073 },
    { lat: 49.4010047, lng: 18.5858424 },
    { lat: 49.4012287, lng: 18.5859378 },
    { lat: 49.401443, lng: 18.5861278 },
    { lat: 49.4014562, lng: 18.5861019 },
    { lat: 49.4014785, lng: 18.586014 },
    { lat: 49.4015318, lng: 18.5859316 },
    { lat: 49.4015731, lng: 18.5859666 },
    { lat: 49.4015946, lng: 18.5859708 },
    { lat: 49.4017031, lng: 18.5860376 },
    { lat: 49.402497, lng: 18.5867123 },
    { lat: 49.4028122, lng: 18.5874195 },
    { lat: 49.4023966, lng: 18.5880647 },
    { lat: 49.4022092, lng: 18.5884634 },
    { lat: 49.4021901, lng: 18.5884636 },
    { lat: 49.4021375, lng: 18.5887048 },
    { lat: 49.401414, lng: 18.5918202 },
    { lat: 49.4007429, lng: 18.5934685 },
    { lat: 49.4007323, lng: 18.5935636 },
    { lat: 49.4006618, lng: 18.594209 },
    { lat: 49.4006567, lng: 18.5942632 },
    { lat: 49.4004606, lng: 18.5960639 },
    { lat: 49.4005092, lng: 18.5959607 },
    { lat: 49.4011617, lng: 18.5944886 },
    { lat: 49.40155, lng: 18.5939895 },
    { lat: 49.4016442, lng: 18.5937257 },
    { lat: 49.4019665, lng: 18.5935528 },
    { lat: 49.4025499, lng: 18.5932861 },
    { lat: 49.40316, lng: 18.5925437 },
    { lat: 49.4037125, lng: 18.5916753 },
    { lat: 49.4038331, lng: 18.5916324 },
    { lat: 49.4039421, lng: 18.5914778 },
    { lat: 49.4041202, lng: 18.5914331 },
    { lat: 49.4039138, lng: 18.5916222 },
    { lat: 49.4041338, lng: 18.591816 },
    { lat: 49.4046815, lng: 18.5919335 },
    { lat: 49.4048333, lng: 18.5921578 },
    { lat: 49.4050336, lng: 18.5924666 },
    { lat: 49.4050499, lng: 18.5924349 },
    { lat: 49.4054167, lng: 18.591744 },
    { lat: 49.4059907, lng: 18.5925673 },
    { lat: 49.4061945, lng: 18.5927747 },
    { lat: 49.4063992, lng: 18.5928899 },
    { lat: 49.4068021, lng: 18.5931687 },
    { lat: 49.4070228, lng: 18.5931863 },
    { lat: 49.4075428, lng: 18.593228 },
    { lat: 49.4076954, lng: 18.5930014 },
    { lat: 49.407962, lng: 18.5929264 },
    { lat: 49.4080615, lng: 18.5927622 },
    { lat: 49.4084433, lng: 18.5928581 },
    { lat: 49.4085484, lng: 18.5927997 },
    { lat: 49.4086854, lng: 18.5927235 },
    { lat: 49.4088486, lng: 18.5926332 },
    { lat: 49.4090214, lng: 18.5925145 },
    { lat: 49.4093725, lng: 18.5925621 },
    { lat: 49.4096831, lng: 18.5924215 },
    { lat: 49.4098872, lng: 18.5924633 },
    { lat: 49.4101447, lng: 18.5924926 },
    { lat: 49.4103939, lng: 18.592469 },
    { lat: 49.4110113, lng: 18.5925242 },
    { lat: 49.4111468, lng: 18.5924431 },
    { lat: 49.4112528, lng: 18.5923788 },
    { lat: 49.4113082, lng: 18.5920618 },
    { lat: 49.4113991, lng: 18.5913989 },
    { lat: 49.4115032, lng: 18.5914293 },
    { lat: 49.4115295, lng: 18.5913901 },
    { lat: 49.4115777, lng: 18.5913454 },
    { lat: 49.4117677, lng: 18.5912484 },
    { lat: 49.4119836, lng: 18.5911525 },
    { lat: 49.4121513, lng: 18.5910413 },
    { lat: 49.4123367, lng: 18.5909859 },
    { lat: 49.4125991, lng: 18.5908715 },
    { lat: 49.4127246, lng: 18.5907522 },
    { lat: 49.412721, lng: 18.5907259 },
    { lat: 49.4127969, lng: 18.5908196 },
    { lat: 49.4128259, lng: 18.5908533 },
    { lat: 49.4129041, lng: 18.590946 },
    { lat: 49.4129598, lng: 18.5910147 },
    { lat: 49.4129811, lng: 18.5910381 },
    { lat: 49.4130266, lng: 18.5910845 },
    { lat: 49.4130502, lng: 18.5911066 },
    { lat: 49.4131253, lng: 18.5911816 },
    { lat: 49.4142637, lng: 18.5914874 },
    { lat: 49.414676, lng: 18.5921547 },
    { lat: 49.4149967, lng: 18.5916998 },
    { lat: 49.4157105, lng: 18.5912094 },
    { lat: 49.4157731, lng: 18.5912016 },
    { lat: 49.4160984, lng: 18.5907019 },
    { lat: 49.4167595, lng: 18.5899026 },
    { lat: 49.4176323, lng: 18.5888463 },
    { lat: 49.4177347, lng: 18.5885006 },
    { lat: 49.4182609, lng: 18.5867195 },
    { lat: 49.4182736, lng: 18.5859652 },
    { lat: 49.4181934, lng: 18.58499 },
    { lat: 49.4185857, lng: 18.5850469 },
    { lat: 49.4188469, lng: 18.5849783 },
    { lat: 49.4189519, lng: 18.5849184 },
    { lat: 49.4193293, lng: 18.5845726 },
    { lat: 49.4195659, lng: 18.5843564 },
    { lat: 49.4197346, lng: 18.5842225 },
    { lat: 49.4198462, lng: 18.5842198 },
    { lat: 49.4199786, lng: 18.5841626 },
    { lat: 49.420217, lng: 18.5840479 },
    { lat: 49.420669, lng: 18.5840412 },
    { lat: 49.4215338, lng: 18.5839954 },
    { lat: 49.4221855, lng: 18.5841223 },
    { lat: 49.4228592, lng: 18.5841298 },
    { lat: 49.4233029, lng: 18.5840749 },
    { lat: 49.4235326, lng: 18.5840037 },
    { lat: 49.4242374, lng: 18.5835806 },
    { lat: 49.4244543, lng: 18.583461 },
    { lat: 49.4246336, lng: 18.5832917 },
    { lat: 49.4246498, lng: 18.5832586 },
    { lat: 49.4245446, lng: 18.5827051 },
    { lat: 49.4242409, lng: 18.5814507 },
    { lat: 49.4239795, lng: 18.5806941 },
    { lat: 49.4237847, lng: 18.5801747 },
    { lat: 49.4234088, lng: 18.5797216 },
    { lat: 49.4241663, lng: 18.5785154 },
    { lat: 49.4245779, lng: 18.5776468 },
    { lat: 49.4250635, lng: 18.5764783 },
    { lat: 49.425021, lng: 18.5762938 },
    { lat: 49.424989, lng: 18.5761587 },
    { lat: 49.424953, lng: 18.5759716 },
    { lat: 49.4249377, lng: 18.5758532 },
    { lat: 49.4249122, lng: 18.5757572 },
    { lat: 49.4249087, lng: 18.575546 },
    { lat: 49.4248629, lng: 18.5753605 },
    { lat: 49.4249092, lng: 18.5751532 },
    { lat: 49.424902, lng: 18.5749951 },
    { lat: 49.4248763, lng: 18.5748513 },
    { lat: 49.4248801, lng: 18.5747363 },
    { lat: 49.42485, lng: 18.5746158 },
    { lat: 49.4248093, lng: 18.5745079 },
    { lat: 49.4247774, lng: 18.5743097 },
    { lat: 49.4247949, lng: 18.5741101 },
    { lat: 49.4248039, lng: 18.5739217 },
    { lat: 49.4247733, lng: 18.5737074 },
    { lat: 49.424795, lng: 18.5735199 },
    { lat: 49.4247933, lng: 18.573489 },
    { lat: 49.4248236, lng: 18.5734457 },
    { lat: 49.4246806, lng: 18.5729623 },
    { lat: 49.4247415, lng: 18.5726927 },
    { lat: 49.4248184, lng: 18.571386 },
    { lat: 49.4251596, lng: 18.5710627 },
    { lat: 49.4253106, lng: 18.5700677 },
    { lat: 49.4251223, lng: 18.5695838 },
    { lat: 49.4255082, lng: 18.5694813 },
    { lat: 49.4258769, lng: 18.5692085 },
    { lat: 49.4260992, lng: 18.5688712 },
    { lat: 49.4262115, lng: 18.5686118 },
    { lat: 49.426302, lng: 18.568404 },
    { lat: 49.426326, lng: 18.5683485 },
    { lat: 49.4263918, lng: 18.5682091 },
    { lat: 49.4265762, lng: 18.5678392 },
    { lat: 49.4266352, lng: 18.5677194 },
    { lat: 49.4267483, lng: 18.5674918 },
    { lat: 49.4268337, lng: 18.5673836 },
    { lat: 49.4268561, lng: 18.5673561 },
    { lat: 49.426875, lng: 18.5673338 },
    { lat: 49.4269155, lng: 18.567286 },
    { lat: 49.4271626, lng: 18.566993 },
    { lat: 49.427223, lng: 18.5669426 },
    { lat: 49.428433, lng: 18.5659321 },
    { lat: 49.4286743, lng: 18.5658673 },
    { lat: 49.4288821, lng: 18.5657046 },
    { lat: 49.4289666, lng: 18.5657026 },
    { lat: 49.4293327, lng: 18.565695 },
    { lat: 49.429507, lng: 18.5654107 },
    { lat: 49.4296191, lng: 18.565164 },
    { lat: 49.4298021, lng: 18.5650243 },
    { lat: 49.4298223, lng: 18.5649819 },
    { lat: 49.4300784, lng: 18.5644551 },
    { lat: 49.4302949, lng: 18.5638249 },
    { lat: 49.4303551, lng: 18.5634931 },
    { lat: 49.430479, lng: 18.5628814 },
    { lat: 49.4305687, lng: 18.5626183 },
    { lat: 49.4305932, lng: 18.5625069 },
    { lat: 49.4306757, lng: 18.5623212 },
    { lat: 49.4307544, lng: 18.5622113 },
    { lat: 49.4309842, lng: 18.5620584 },
    { lat: 49.4311939, lng: 18.5620171 },
    { lat: 49.4314079, lng: 18.5620536 },
    { lat: 49.4314891, lng: 18.56203 },
    { lat: 49.431788, lng: 18.5618626 },
    { lat: 49.4318418, lng: 18.5617319 },
    { lat: 49.4319981, lng: 18.5607539 },
    { lat: 49.4320719, lng: 18.5605083 },
    { lat: 49.4321283, lng: 18.5602168 },
    { lat: 49.4322429, lng: 18.5600838 },
    { lat: 49.4323604, lng: 18.5599165 },
    { lat: 49.4324323, lng: 18.5598473 },
    { lat: 49.4325138, lng: 18.5597899 },
    { lat: 49.4327322, lng: 18.5596139 },
    { lat: 49.4327806, lng: 18.5595723 },
    { lat: 49.4330791, lng: 18.5592524 },
    { lat: 49.4333994, lng: 18.5591022 },
    { lat: 49.433703, lng: 18.5590223 },
    { lat: 49.4339553, lng: 18.5590373 },
    { lat: 49.4341413, lng: 18.5591005 },
    { lat: 49.4342263, lng: 18.5591698 },
    { lat: 49.4342748, lng: 18.5593822 },
    { lat: 49.4342871, lng: 18.5593995 },
    { lat: 49.4343754, lng: 18.5595315 },
    { lat: 49.4345539, lng: 18.5597261 },
    { lat: 49.4346917, lng: 18.560049 },
    { lat: 49.4348479, lng: 18.5604149 },
    { lat: 49.4349086, lng: 18.5604308 },
    { lat: 49.4349433, lng: 18.5604935 },
    { lat: 49.434967, lng: 18.5605343 },
    { lat: 49.4350078, lng: 18.5606857 },
    { lat: 49.4352051, lng: 18.5611664 },
    { lat: 49.4352671, lng: 18.5612916 },
    { lat: 49.4354685, lng: 18.5614735 },
    { lat: 49.4355626, lng: 18.561338 },
    { lat: 49.4356738, lng: 18.5611363 },
    { lat: 49.4358132, lng: 18.5609416 },
    { lat: 49.4359329, lng: 18.5608174 },
    { lat: 49.4361172, lng: 18.5606181 },
    { lat: 49.4362414, lng: 18.5604307 },
    { lat: 49.4363221, lng: 18.5602738 },
    { lat: 49.4364095, lng: 18.5600515 },
    { lat: 49.4367031, lng: 18.5595514 },
    { lat: 49.4368949, lng: 18.5591957 },
    { lat: 49.4369818, lng: 18.5588205 },
    { lat: 49.4370854, lng: 18.5583096 },
    { lat: 49.4371651, lng: 18.5579676 },
    { lat: 49.4372304, lng: 18.5575857 },
    { lat: 49.4372941, lng: 18.5570059 },
    { lat: 49.4373315, lng: 18.5567605 },
    { lat: 49.4373816, lng: 18.5565788 },
    { lat: 49.4373919, lng: 18.5564761 },
    { lat: 49.4373772, lng: 18.5562623 },
    { lat: 49.4373497, lng: 18.5561299 },
    { lat: 49.437334, lng: 18.5560032 },
    { lat: 49.437321, lng: 18.555911 },
    { lat: 49.4372586, lng: 18.555546 },
    { lat: 49.4372398, lng: 18.555407 },
    { lat: 49.4372193, lng: 18.5552313 },
    { lat: 49.4372211, lng: 18.5549912 },
    { lat: 49.437207, lng: 18.5548158 },
    { lat: 49.437134, lng: 18.5545658 },
    { lat: 49.4370855, lng: 18.5544183 },
    { lat: 49.43695, lng: 18.5541437 },
    { lat: 49.4369091, lng: 18.5540561 },
    { lat: 49.4367983, lng: 18.5538185 },
    { lat: 49.4367169, lng: 18.5536657 },
    { lat: 49.4366398, lng: 18.553533 },
    { lat: 49.4365408, lng: 18.5533883 },
    { lat: 49.4364628, lng: 18.5532162 },
    { lat: 49.4364075, lng: 18.5530488 },
    { lat: 49.4363309, lng: 18.552674 },
    { lat: 49.4362914, lng: 18.552355 },
    { lat: 49.4362735, lng: 18.5521051 },
    { lat: 49.436284, lng: 18.5520277 },
    { lat: 49.4363144, lng: 18.55186 },
    { lat: 49.4363262, lng: 18.5517843 },
    { lat: 49.4363374, lng: 18.5516796 },
    { lat: 49.436341, lng: 18.5515983 },
    { lat: 49.4363504, lng: 18.5515 },
    { lat: 49.4363787, lng: 18.5513747 },
    { lat: 49.4364265, lng: 18.5511744 },
    { lat: 49.4364964, lng: 18.550984 },
    { lat: 49.4365355, lng: 18.5508936 },
    { lat: 49.4365917, lng: 18.5508481 },
    { lat: 49.4367264, lng: 18.5505223 },
    { lat: 49.436783, lng: 18.5503561 },
    { lat: 49.4368567, lng: 18.5500917 },
    { lat: 49.4369006, lng: 18.5499622 },
    { lat: 49.4368768, lng: 18.5495634 },
    { lat: 49.4368455, lng: 18.5487914 },
    { lat: 49.4369119, lng: 18.5483895 },
    { lat: 49.4369227, lng: 18.5482298 },
    { lat: 49.4369548, lng: 18.5477586 },
    { lat: 49.4369791, lng: 18.5473707 },
    { lat: 49.4370536, lng: 18.5470768 },
    { lat: 49.4370573, lng: 18.5470612 },
    { lat: 49.4371339, lng: 18.5468504 },
    { lat: 49.4371692, lng: 18.5467731 },
    { lat: 49.4373488, lng: 18.5463808 },
    { lat: 49.4374516, lng: 18.5461913 },
    { lat: 49.4375329, lng: 18.5459857 },
    { lat: 49.4376344, lng: 18.5457971 },
    { lat: 49.4377298, lng: 18.5456419 },
    { lat: 49.4378552, lng: 18.5453112 },
    { lat: 49.437937, lng: 18.5450682 },
    { lat: 49.4380338, lng: 18.5448951 },
    { lat: 49.438189, lng: 18.5446164 },
    { lat: 49.4382843, lng: 18.544355 },
    { lat: 49.4383476, lng: 18.5442912 },
    { lat: 49.4385295, lng: 18.5441095 },
    { lat: 49.4386302, lng: 18.5439044 },
    { lat: 49.4386911, lng: 18.5438238 },
    { lat: 49.4388317, lng: 18.5437296 },
    { lat: 49.4390451, lng: 18.5435668 },
    { lat: 49.439291, lng: 18.5434208 },
    { lat: 49.4395191, lng: 18.543197 },
    { lat: 49.4400045, lng: 18.5428045 },
    { lat: 49.4401793, lng: 18.5426135 },
    { lat: 49.4401889, lng: 18.5426029 },
    { lat: 49.4401898, lng: 18.5426021 },
    { lat: 49.4402335, lng: 18.5425545 },
    { lat: 49.4404002, lng: 18.5424026 },
    { lat: 49.4404773, lng: 18.542328 },
    { lat: 49.4405347, lng: 18.5422392 },
    { lat: 49.4406836, lng: 18.5418616 },
    { lat: 49.4410112, lng: 18.541286 },
    { lat: 49.4413528, lng: 18.5406942 },
    { lat: 49.4414589, lng: 18.5405318 },
    { lat: 49.441865, lng: 18.5401115 },
    { lat: 49.4418863, lng: 18.5400896 },
    { lat: 49.4422449, lng: 18.5398356 },
    { lat: 49.4424937, lng: 18.5396595 },
    { lat: 49.442613, lng: 18.5395082 },
    { lat: 49.4427148, lng: 18.5390114 },
    { lat: 49.4428202, lng: 18.5386415 },
    { lat: 49.4429136, lng: 18.5384267 },
    { lat: 49.4436063, lng: 18.5369844 },
    { lat: 49.4449911, lng: 18.5348829 },
    { lat: 49.4450088, lng: 18.5348549 },
    { lat: 49.4451777, lng: 18.5345997 },
    { lat: 49.4452584, lng: 18.5346323 },
    { lat: 49.4452644, lng: 18.5346391 },
    { lat: 49.4452788, lng: 18.5346555 },
    { lat: 49.4452823, lng: 18.5346601 },
    { lat: 49.4452945, lng: 18.5346749 },
    { lat: 49.4457765, lng: 18.534159 },
    { lat: 49.4458753, lng: 18.5339392 },
    { lat: 49.4459025, lng: 18.5338778 },
    { lat: 49.4459054, lng: 18.5338712 },
    { lat: 49.4459114, lng: 18.5338592 },
    { lat: 49.44612, lng: 18.5333961 },
    { lat: 49.4461309, lng: 18.5333668 },
    { lat: 49.4463401, lng: 18.5329144 },
    { lat: 49.4452193, lng: 18.5304868 },
    { lat: 49.4457427, lng: 18.529003 },
    { lat: 49.4459084, lng: 18.5281643 },
    { lat: 49.4459154, lng: 18.5281276 },
    { lat: 49.4457321, lng: 18.5272548 },
    { lat: 49.4456395, lng: 18.5268138 },
    { lat: 49.4455577, lng: 18.5264269 },
    { lat: 49.4454607, lng: 18.5259633 },
    { lat: 49.4454519, lng: 18.5259274 },
    { lat: 49.4453122, lng: 18.5252597 },
    { lat: 49.4453047, lng: 18.5252266 },
    { lat: 49.4452321, lng: 18.5248828 },
    { lat: 49.4461112, lng: 18.5242027 },
    { lat: 49.4461655, lng: 18.5241614 },
    { lat: 49.4462467, lng: 18.5240982 },
    { lat: 49.4464665, lng: 18.5239921 },
    { lat: 49.4471008, lng: 18.5236862 },
    { lat: 49.4471303, lng: 18.523672 },
    { lat: 49.4488517, lng: 18.5229217 },
    { lat: 49.4488734, lng: 18.522907 },
    { lat: 49.4490121, lng: 18.5228002 },
    { lat: 49.449235, lng: 18.5229523 },
    { lat: 49.448957, lng: 18.522535 },
    { lat: 49.448767, lng: 18.522285 },
    { lat: 49.448678, lng: 18.52197 },
    { lat: 49.448593, lng: 18.521708 },
    { lat: 49.448455, lng: 18.521143 },
    { lat: 49.448258, lng: 18.520491 },
    { lat: 49.448202, lng: 18.520239 },
    { lat: 49.448176, lng: 18.519883 },
    { lat: 49.448105, lng: 18.519536 },
    { lat: 49.448208, lng: 18.519127 },
    { lat: 49.44825, lng: 18.518839 },
    { lat: 49.448293, lng: 18.518495 },
    { lat: 49.448285, lng: 18.518204 },
    { lat: 49.448368, lng: 18.517892 },
    { lat: 49.448394, lng: 18.517623 },
    { lat: 49.448488, lng: 18.517052 },
    { lat: 49.448412, lng: 18.516749 },
    { lat: 49.448286, lng: 18.516518 },
    { lat: 49.447711, lng: 18.516349 },
    { lat: 49.447497, lng: 18.516232 },
    { lat: 49.447174, lng: 18.515995 },
    { lat: 49.446956, lng: 18.515586 },
    { lat: 49.446768, lng: 18.515168 },
    { lat: 49.446672, lng: 18.514957 },
    { lat: 49.446565, lng: 18.514565 },
    { lat: 49.446526, lng: 18.514318 },
    { lat: 49.446294, lng: 18.513621 },
    { lat: 49.446148, lng: 18.513092 },
    { lat: 49.445874, lng: 18.512602 },
    { lat: 49.445738, lng: 18.512405 },
    { lat: 49.445069, lng: 18.511783 },
    { lat: 49.444953, lng: 18.511465 },
    { lat: 49.444869, lng: 18.511137 },
    { lat: 49.444742, lng: 18.510904 },
    { lat: 49.444597, lng: 18.510705 },
    { lat: 49.444454, lng: 18.510087 },
    { lat: 49.444191, lng: 18.509502 },
    { lat: 49.444039, lng: 18.509234 },
    { lat: 49.443759, lng: 18.508772 },
    { lat: 49.44361, lng: 18.508547 },
    { lat: 49.443319, lng: 18.508175 },
    { lat: 49.443139, lng: 18.507673 },
    { lat: 49.44306, lng: 18.507278 },
    { lat: 49.443037, lng: 18.506532 },
    { lat: 49.442953, lng: 18.505871 },
    { lat: 49.4429, lng: 18.5053 },
    { lat: 49.44272, lng: 18.505043 },
    { lat: 49.44241, lng: 18.504796 },
    { lat: 49.442204, lng: 18.504594 },
    { lat: 49.441522, lng: 18.504052 },
    { lat: 49.441374, lng: 18.503917 },
    { lat: 49.441065, lng: 18.503858 },
    { lat: 49.440782, lng: 18.50377 },
    { lat: 49.440606, lng: 18.50371 },
    { lat: 49.440192, lng: 18.503649 },
    { lat: 49.439627, lng: 18.503633 },
    { lat: 49.439268, lng: 18.503618 },
    { lat: 49.439073, lng: 18.503473 },
    { lat: 49.438804, lng: 18.503157 },
    { lat: 49.438601, lng: 18.502737 },
    { lat: 49.438235, lng: 18.502254 },
    { lat: 49.437747, lng: 18.501975 },
    { lat: 49.437368, lng: 18.501818 },
    { lat: 49.437042, lng: 18.501652 },
    { lat: 49.436733, lng: 18.501457 },
    { lat: 49.436311, lng: 18.501188 },
    { lat: 49.435816, lng: 18.500923 },
    { lat: 49.43574, lng: 18.500609 },
    { lat: 49.435621, lng: 18.500082 },
    { lat: 49.435567, lng: 18.499708 },
    { lat: 49.435561, lng: 18.499369 },
    { lat: 49.435592, lng: 18.498769 },
    { lat: 49.435576, lng: 18.497894 },
    { lat: 49.435542, lng: 18.497387 },
    { lat: 49.435745, lng: 18.496523 },
    { lat: 49.435859, lng: 18.496141 },
    { lat: 49.43587, lng: 18.495961 },
    { lat: 49.4349973, lng: 18.4961847 },
    { lat: 49.434321, lng: 18.4963168 },
    { lat: 49.4341473, lng: 18.4963389 },
    { lat: 49.4336914, lng: 18.4963966 },
    { lat: 49.4335208, lng: 18.4964748 },
    { lat: 49.4330232, lng: 18.4964696 },
    { lat: 49.4327603, lng: 18.49649 },
    { lat: 49.4323543, lng: 18.4969044 },
    { lat: 49.4321675, lng: 18.497121 },
    { lat: 49.4320507, lng: 18.4980073 },
    { lat: 49.4318799, lng: 18.4982707 },
    { lat: 49.4318752, lng: 18.498538 },
    { lat: 49.4317602, lng: 18.4988502 },
    { lat: 49.431323, lng: 18.4995048 },
    { lat: 49.4310214, lng: 18.4997663 },
    { lat: 49.4303669, lng: 18.5003021 },
    { lat: 49.4299492, lng: 18.5007506 },
    { lat: 49.4297756, lng: 18.5010462 },
    { lat: 49.4293517, lng: 18.5016936 },
    { lat: 49.4293669, lng: 18.501937 },
    { lat: 49.4292887, lng: 18.5022818 },
    { lat: 49.4293444, lng: 18.5025599 },
    { lat: 49.4293692, lng: 18.5028973 },
    { lat: 49.4295683, lng: 18.5032391 },
    { lat: 49.4296211, lng: 18.5036342 },
    { lat: 49.429708, lng: 18.504092 },
    { lat: 49.4297937, lng: 18.5042619 },
    { lat: 49.4297702, lng: 18.5044291 },
    { lat: 49.4295909, lng: 18.5045751 },
    { lat: 49.4293053, lng: 18.5050335 },
    { lat: 49.429187, lng: 18.5050514 },
    { lat: 49.4289908, lng: 18.5052651 },
    { lat: 49.4288457, lng: 18.5052096 },
    { lat: 49.4287627, lng: 18.5052162 },
    { lat: 49.4285049, lng: 18.5055591 },
    { lat: 49.4282502, lng: 18.5055433 },
    { lat: 49.4281404, lng: 18.5057841 },
    { lat: 49.4278923, lng: 18.5060351 },
    { lat: 49.4278094, lng: 18.506233 },
    { lat: 49.4275881, lng: 18.5065423 },
    { lat: 49.4272857, lng: 18.5068934 },
    { lat: 49.4270363, lng: 18.5069968 },
    { lat: 49.4268784, lng: 18.5071415 },
    { lat: 49.4265629, lng: 18.5074399 },
    { lat: 49.4263412, lng: 18.5073257 },
    { lat: 49.4260583, lng: 18.5072638 },
    { lat: 49.4259108, lng: 18.5070184 },
    { lat: 49.4257561, lng: 18.5069717 },
    { lat: 49.4255394, lng: 18.5070123 },
    { lat: 49.4253208, lng: 18.5068056 },
    { lat: 49.4251849, lng: 18.5068203 },
    { lat: 49.4250457, lng: 18.5067054 },
    { lat: 49.4249479, lng: 18.5066664 },
    { lat: 49.4246792, lng: 18.506873 },
    { lat: 49.4244461, lng: 18.5068367 },
    { lat: 49.4242041, lng: 18.5070949 },
    { lat: 49.4240304, lng: 18.5072829 },
    { lat: 49.4235802, lng: 18.5078042 },
    { lat: 49.4234156, lng: 18.5078899 },
    { lat: 49.4231903, lng: 18.5083073 },
    { lat: 49.4229858, lng: 18.5084684 },
    { lat: 49.4228219, lng: 18.5086725 },
    { lat: 49.4226791, lng: 18.5087624 },
    { lat: 49.4224963, lng: 18.5090983 },
    { lat: 49.4222087, lng: 18.5097851 },
    { lat: 49.4220488, lng: 18.509875 },
    { lat: 49.4219904, lng: 18.510128 },
    { lat: 49.4218727, lng: 18.510185 },
    { lat: 49.4216758, lng: 18.5100437 },
    { lat: 49.4214048, lng: 18.5103733 },
    { lat: 49.4209409, lng: 18.5105351 },
    { lat: 49.4207732, lng: 18.5108531 },
    { lat: 49.4202394, lng: 18.5112433 },
    { lat: 49.4198846, lng: 18.5120476 },
    { lat: 49.4196945, lng: 18.5122442 },
    { lat: 49.4194246, lng: 18.512632 },
    { lat: 49.4192295, lng: 18.5128839 },
    { lat: 49.4191108, lng: 18.5131935 },
    { lat: 49.4189724, lng: 18.5134288 },
    { lat: 49.4187075, lng: 18.5133711 },
    { lat: 49.4185765, lng: 18.5135536 },
    { lat: 49.418449, lng: 18.513535 },
    { lat: 49.418341, lng: 18.5133502 },
    { lat: 49.4181521, lng: 18.5134459 },
    { lat: 49.4180981, lng: 18.5131774 },
    { lat: 49.4178661, lng: 18.5133702 },
    { lat: 49.4178332, lng: 18.5135323 },
    { lat: 49.4176892, lng: 18.5136669 },
    { lat: 49.4175566, lng: 18.5136754 },
    { lat: 49.4174631, lng: 18.5135275 },
    { lat: 49.4174087, lng: 18.5135224 },
    { lat: 49.4173788, lng: 18.5134929 },
    { lat: 49.4173874, lng: 18.5134219 },
    { lat: 49.4174111, lng: 18.5132131 },
    { lat: 49.4174985, lng: 18.513014 },
    { lat: 49.4176311, lng: 18.5130276 },
    { lat: 49.4177007, lng: 18.512938 },
    { lat: 49.4176373, lng: 18.512578 },
    { lat: 49.4180378, lng: 18.5123714 },
    { lat: 49.4180376, lng: 18.5121182 },
    { lat: 49.4180392, lng: 18.5117098 },
    { lat: 49.4181753, lng: 18.5115947 },
    { lat: 49.4182352, lng: 18.5113469 },
    { lat: 49.4183663, lng: 18.5111846 },
    { lat: 49.4183982, lng: 18.5109223 },
    { lat: 49.4184716, lng: 18.5106087 },
    { lat: 49.4184337, lng: 18.5103063 },
    { lat: 49.4185858, lng: 18.5097186 },
    { lat: 49.4184763, lng: 18.5094242 },
    { lat: 49.4184714, lng: 18.5092049 },
    { lat: 49.4182608, lng: 18.5087941 },
    { lat: 49.4182521, lng: 18.508489 },
    { lat: 49.4182315, lng: 18.5081287 },
    { lat: 49.4181383, lng: 18.5079482 },
    { lat: 49.4179927, lng: 18.5075058 },
    { lat: 49.4178994, lng: 18.5073902 },
    { lat: 49.4179108, lng: 18.5072018 },
    { lat: 49.4178994, lng: 18.5071185 },
    { lat: 49.4178082, lng: 18.5070964 },
    { lat: 49.4176296, lng: 18.5067579 },
    { lat: 49.4176544, lng: 18.506612 },
    { lat: 49.4176145, lng: 18.5064787 },
    { lat: 49.417488, lng: 18.5064726 },
    { lat: 49.4174253, lng: 18.5062303 },
    { lat: 49.4172839, lng: 18.5062037 },
    { lat: 49.4171662, lng: 18.5061552 },
    { lat: 49.4171436, lng: 18.505881 },
    { lat: 49.4169578, lng: 18.5055746 },
    { lat: 49.4170045, lng: 18.5053759 },
    { lat: 49.4169636, lng: 18.5051388 },
    { lat: 49.4164886, lng: 18.5053237 },
    { lat: 49.4163309, lng: 18.5053887 },
    { lat: 49.4160512, lng: 18.5055575 },
    { lat: 49.4159614, lng: 18.5056055 },
    { lat: 49.4151468, lng: 18.5060393 },
    { lat: 49.4149683, lng: 18.5061167 },
    { lat: 49.4142194, lng: 18.5063893 },
    { lat: 49.4139883, lng: 18.5065548 },
    { lat: 49.4140849, lng: 18.507053 },
    { lat: 49.4143118, lng: 18.5078486 },
    { lat: 49.4146158, lng: 18.5087167 },
    { lat: 49.4141076, lng: 18.5094749 },
    { lat: 49.4138749, lng: 18.5089665 },
    { lat: 49.4132625, lng: 18.5095731 },
    { lat: 49.4130459, lng: 18.5098425 },
    { lat: 49.4123915, lng: 18.5104414 },
    { lat: 49.4120999, lng: 18.5106215 },
    { lat: 49.4118159, lng: 18.5108111 },
    { lat: 49.4114401, lng: 18.5110986 },
    { lat: 49.4114162, lng: 18.5111168 },
    { lat: 49.4113039, lng: 18.510854 },
    { lat: 49.4108523, lng: 18.5110105 },
    { lat: 49.4100712, lng: 18.5113342 },
    { lat: 49.4097198, lng: 18.5113842 },
    { lat: 49.409188, lng: 18.5115397 },
    { lat: 49.4088973, lng: 18.5115885 },
    { lat: 49.4086191, lng: 18.5117633 },
    { lat: 49.4084345, lng: 18.5118714 },
    { lat: 49.4077786, lng: 18.512156 },
    { lat: 49.4070369, lng: 18.5126445 },
    { lat: 49.4067085, lng: 18.5131396 },
    { lat: 49.4065157, lng: 18.5135567 },
    { lat: 49.4064101, lng: 18.5136501 },
    { lat: 49.4060191, lng: 18.5138659 },
    { lat: 49.4056574, lng: 18.5141009 },
    { lat: 49.4053698, lng: 18.5141848 },
    { lat: 49.4050778, lng: 18.5142696 },
    { lat: 49.4047646, lng: 18.5143615 },
    { lat: 49.4047081, lng: 18.5143781 },
    { lat: 49.4046725, lng: 18.514388 },
    { lat: 49.4044566, lng: 18.5146088 },
    { lat: 49.4040945, lng: 18.5149415 },
    { lat: 49.4039339, lng: 18.5152053 },
    { lat: 49.4038386, lng: 18.5153618 },
    { lat: 49.4038183, lng: 18.5153843 },
    { lat: 49.4037559, lng: 18.5157305 },
    { lat: 49.4035139, lng: 18.5161385 },
    { lat: 49.4030609, lng: 18.5169181 },
    { lat: 49.402901, lng: 18.5171531 },
    { lat: 49.4028822, lng: 18.5171812 },
    { lat: 49.4028487, lng: 18.5172307 },
    { lat: 49.4025938, lng: 18.5176049 },
    { lat: 49.402562, lng: 18.5176401 },
    { lat: 49.4023114, lng: 18.5179115 },
    { lat: 49.402255, lng: 18.5179911 },
    { lat: 49.4018969, lng: 18.5185022 },
    { lat: 49.401677, lng: 18.5188607 },
    { lat: 49.4016023, lng: 18.5190657 },
    { lat: 49.4015927, lng: 18.519093 },
    { lat: 49.4013292, lng: 18.5198119 },
    { lat: 49.401205, lng: 18.5201243 },
    { lat: 49.4009709, lng: 18.5205302 },
    { lat: 49.400957, lng: 18.5205649 },
    { lat: 49.4009159, lng: 18.5206624 },
    { lat: 49.4007097, lng: 18.5211474 },
    { lat: 49.4004873, lng: 18.5215579 },
    { lat: 49.4003314, lng: 18.5219322 },
    { lat: 49.3999925, lng: 18.5226331 },
    { lat: 49.399795, lng: 18.5230688 },
    { lat: 49.3993556, lng: 18.5239978 },
    { lat: 49.3990964, lng: 18.5246509 },
    { lat: 49.3990286, lng: 18.5248746 },
    { lat: 49.3988558, lng: 18.5254357 },
    { lat: 49.3986229, lng: 18.5259073 },
    { lat: 49.3982802, lng: 18.5265601 },
    { lat: 49.3981663, lng: 18.5269993 },
    { lat: 49.3978312, lng: 18.5276656 },
    { lat: 49.3979621, lng: 18.528442 },
    { lat: 49.3980871, lng: 18.5293804 },
    { lat: 49.3980591, lng: 18.5299691 },
    { lat: 49.3980707, lng: 18.5311071 },
    { lat: 49.3979017, lng: 18.5317814 },
    { lat: 49.3977717, lng: 18.5326108 },
    { lat: 49.3977233, lng: 18.5331611 },
    { lat: 49.3977151, lng: 18.5332574 },
    { lat: 49.3976905, lng: 18.5335342 },
    { lat: 49.3976276, lng: 18.5344598 },
    { lat: 49.397645, lng: 18.5349497 },
    { lat: 49.3976389, lng: 18.5358198 },
    { lat: 49.3976022, lng: 18.5363332 },
    { lat: 49.3975827, lng: 18.5369972 },
    { lat: 49.3976362, lng: 18.5375753 },
    { lat: 49.397642, lng: 18.5376394 },
    { lat: 49.3977017, lng: 18.5382876 },
    { lat: 49.3977456, lng: 18.538747 },
    { lat: 49.3975664, lng: 18.5390662 },
    { lat: 49.3973827, lng: 18.5397154 },
    { lat: 49.3972319, lng: 18.5401027 },
    { lat: 49.397105, lng: 18.5406568 },
    { lat: 49.3968594, lng: 18.5413928 },
    { lat: 49.3967948, lng: 18.5419535 },
    { lat: 49.3967133, lng: 18.5423904 },
    { lat: 49.3965625, lng: 18.5426049 },
    { lat: 49.3964721, lng: 18.5429201 },
    { lat: 49.3963176, lng: 18.5430881 },
    { lat: 49.3960423, lng: 18.5437952 },
    { lat: 49.3960109, lng: 18.5441794 },
    { lat: 49.3960996, lng: 18.5445285 },
    { lat: 49.3961561, lng: 18.5453288 },
    { lat: 49.3960857, lng: 18.5460101 },
    { lat: 49.3960463, lng: 18.5463898 },
    { lat: 49.3960773, lng: 18.5469058 },
    { lat: 49.3960495, lng: 18.5476462 },
    { lat: 49.3960706, lng: 18.5481005 },
    { lat: 49.3961515, lng: 18.5482865 },
    { lat: 49.3967718, lng: 18.548452 },
    { lat: 49.3969545, lng: 18.5488092 },
    { lat: 49.3970619, lng: 18.5490889 },
    { lat: 49.3972842, lng: 18.5495526 },
    { lat: 49.3974776, lng: 18.5498979 },
    { lat: 49.3975195, lng: 18.549944 },
    { lat: 49.3976479, lng: 18.5500861 },
    { lat: 49.3978101, lng: 18.550334 },
    { lat: 49.3979773, lng: 18.5507181 },
    { lat: 49.398172, lng: 18.5511545 },
    { lat: 49.3983956, lng: 18.5513696 },
    { lat: 49.3986223, lng: 18.5517459 },
    { lat: 49.3987441, lng: 18.5519757 },
    { lat: 49.3988225, lng: 18.5522385 },
    { lat: 49.3990321, lng: 18.5527635 },
    { lat: 49.3990804, lng: 18.5528835 },
    { lat: 49.3995116, lng: 18.5537753 },
    { lat: 49.3997269, lng: 18.554405 },
    { lat: 49.3997773, lng: 18.5545866 },
    { lat: 49.3999532, lng: 18.5554911 },
    { lat: 49.3996676, lng: 18.5556303 },
    { lat: 49.3997289, lng: 18.5562479 },
    { lat: 49.3997481, lng: 18.55644 },
    { lat: 49.3997562, lng: 18.5566736 },
    { lat: 49.3997651, lng: 18.5569462 },
    { lat: 49.3997643, lng: 18.5569919 },
    { lat: 49.3997569, lng: 18.5573619 },
    { lat: 49.3997489, lng: 18.5577145 },
    { lat: 49.3997458, lng: 18.5578901 },
    { lat: 49.3997433, lng: 18.5579668 },
    { lat: 49.3997417, lng: 18.5580206 },
    { lat: 49.3997338, lng: 18.558274 },
    { lat: 49.3997286, lng: 18.5584116 },
    { lat: 49.3997224, lng: 18.5586086 },
    { lat: 49.3997251, lng: 18.5586586 },
    { lat: 49.3997306, lng: 18.5587383 },
    { lat: 49.3997416, lng: 18.5589661 },
    { lat: 49.3997461, lng: 18.5590289 },
    { lat: 49.3997598, lng: 18.5592662 },
    { lat: 49.4003125, lng: 18.5592864 },
    { lat: 49.4003371, lng: 18.559829 },
    { lat: 49.4003036, lng: 18.5601309 },
    { lat: 49.400211, lng: 18.5609692 },
    { lat: 49.400166, lng: 18.5614344 },
    { lat: 49.4001571, lng: 18.5617926 },
    { lat: 49.4001488, lng: 18.5621228 },
    { lat: 49.4001252, lng: 18.5630314 },
    { lat: 49.4000694, lng: 18.5635693 },
    { lat: 49.4000629, lng: 18.5636338 },
    { lat: 49.3999139, lng: 18.5641783 },
    { lat: 49.3998024, lng: 18.5645856 },
    { lat: 49.3997392, lng: 18.5650237 },
    { lat: 49.3995784, lng: 18.565645 },
    { lat: 49.399435, lng: 18.5661447 },
    { lat: 49.3993339, lng: 18.5664891 },
    { lat: 49.3992311, lng: 18.5668456 },
    { lat: 49.3992502, lng: 18.56697 },
    { lat: 49.3989975, lng: 18.56737 },
    { lat: 49.3986967, lng: 18.5677753 },
    { lat: 49.3985015, lng: 18.5680233 },
    { lat: 49.3984475, lng: 18.5680924 },
    { lat: 49.3984422, lng: 18.5680992 },
    { lat: 49.3980872, lng: 18.5686931 },
    { lat: 49.3977899, lng: 18.568909 },
    { lat: 49.3968208, lng: 18.5690304 },
    { lat: 49.3965767, lng: 18.569106 },
    { lat: 49.396409, lng: 18.5693407 },
    { lat: 49.3962401, lng: 18.5693072 },
    { lat: 49.3961421, lng: 18.569476 },
    { lat: 49.39616, lng: 18.5697051 },
    { lat: 49.3961065, lng: 18.5706873 },
    { lat: 49.3960934, lng: 18.5712418 },
    { lat: 49.3960301, lng: 18.5715568 },
    { lat: 49.3959934, lng: 18.5720277 },
    { lat: 49.3959595, lng: 18.5725338 },
    { lat: 49.3959621, lng: 18.5727327 },
    { lat: 49.3959169, lng: 18.5728546 },
    { lat: 49.395821, lng: 18.5733369 },
    { lat: 49.3957064, lng: 18.573704 },
    { lat: 49.3955191, lng: 18.5740421 },
    { lat: 49.3951736, lng: 18.5743897 },
    { lat: 49.3950033, lng: 18.5745385 },
    { lat: 49.3947569, lng: 18.5748671 },
    { lat: 49.3947185, lng: 18.5749302 },
    { lat: 49.3946702, lng: 18.5751223 },
    { lat: 49.3946369, lng: 18.5753615 },
  ],
  KrásnonadKysucou: [
    { lat: 49.3657432, lng: 18.8712696 },
    { lat: 49.3657829, lng: 18.8712511 },
    { lat: 49.3660755, lng: 18.8708642 },
    { lat: 49.3664848, lng: 18.8703153 },
    { lat: 49.3668647, lng: 18.8697114 },
    { lat: 49.3671001, lng: 18.8695563 },
    { lat: 49.3679545, lng: 18.8685758 },
    { lat: 49.3689006, lng: 18.8677414 },
    { lat: 49.3700069, lng: 18.8671276 },
    { lat: 49.37041, lng: 18.8670504 },
    { lat: 49.3710885, lng: 18.8666943 },
    { lat: 49.3711088, lng: 18.8666756 },
    { lat: 49.3718722, lng: 18.8660333 },
    { lat: 49.3726172, lng: 18.8656968 },
    { lat: 49.3731732, lng: 18.8655279 },
    { lat: 49.3736707, lng: 18.8654669 },
    { lat: 49.3744068, lng: 18.8650673 },
    { lat: 49.3767744, lng: 18.8638289 },
    { lat: 49.3768045, lng: 18.8637989 },
    { lat: 49.3773354, lng: 18.8632671 },
    { lat: 49.3775664, lng: 18.862357 },
    { lat: 49.377592, lng: 18.8609609 },
    { lat: 49.3779408, lng: 18.8597732 },
    { lat: 49.3782984, lng: 18.8591373 },
    { lat: 49.379038, lng: 18.8584094 },
    { lat: 49.3793095, lng: 18.8580773 },
    { lat: 49.379716, lng: 18.857535 },
    { lat: 49.3797448, lng: 18.8575026 },
    { lat: 49.3801735, lng: 18.8570239 },
    { lat: 49.3807999, lng: 18.8565081 },
    { lat: 49.3809709, lng: 18.8563899 },
    { lat: 49.3811105, lng: 18.8562335 },
    { lat: 49.3813585, lng: 18.8564079 },
    { lat: 49.3814233, lng: 18.8562199 },
    { lat: 49.3816122, lng: 18.8560319 },
    { lat: 49.3817788, lng: 18.8558944 },
    { lat: 49.3821136, lng: 18.8556961 },
    { lat: 49.3823618, lng: 18.8555871 },
    { lat: 49.3826244, lng: 18.8554753 },
    { lat: 49.3836352, lng: 18.8550925 },
    { lat: 49.3841004, lng: 18.8559668 },
    { lat: 49.3841338, lng: 18.855936 },
    { lat: 49.384179, lng: 18.8561611 },
    { lat: 49.384196, lng: 18.8563136 },
    { lat: 49.384207, lng: 18.8563995 },
    { lat: 49.3842197, lng: 18.8564722 },
    { lat: 49.3842488, lng: 18.8566442 },
    { lat: 49.3842757, lng: 18.8567264 },
    { lat: 49.3844493, lng: 18.856947 },
    { lat: 49.3844698, lng: 18.8570382 },
    { lat: 49.3845739, lng: 18.8572447 },
    { lat: 49.3846084, lng: 18.8573936 },
    { lat: 49.3846938, lng: 18.8575202 },
    { lat: 49.3847779, lng: 18.8576878 },
    { lat: 49.3848112, lng: 18.8577204 },
    { lat: 49.3848371, lng: 18.8577864 },
    { lat: 49.3848851, lng: 18.8578432 },
    { lat: 49.3848797, lng: 18.8578909 },
    { lat: 49.3848775, lng: 18.8581347 },
    { lat: 49.3848984, lng: 18.8582332 },
    { lat: 49.3849713, lng: 18.8583157 },
    { lat: 49.3851379, lng: 18.8583753 },
    { lat: 49.3852004, lng: 18.8585401 },
    { lat: 49.3852563, lng: 18.8587737 },
    { lat: 49.3852337, lng: 18.8589758 },
    { lat: 49.3852326, lng: 18.8590834 },
    { lat: 49.3852492, lng: 18.8591212 },
    { lat: 49.3853296, lng: 18.8591507 },
    { lat: 49.3853457, lng: 18.8591791 },
    { lat: 49.3853679, lng: 18.8592391 },
    { lat: 49.3854311, lng: 18.8593705 },
    { lat: 49.3854538, lng: 18.859464 },
    { lat: 49.3855196, lng: 18.8597156 },
    { lat: 49.3857017, lng: 18.8600119 },
    { lat: 49.3857346, lng: 18.8600641 },
    { lat: 49.3857716, lng: 18.8601458 },
    { lat: 49.3858451, lng: 18.8602611 },
    { lat: 49.3859362, lng: 18.860412 },
    { lat: 49.3860942, lng: 18.8606793 },
    { lat: 49.3862164, lng: 18.8610428 },
    { lat: 49.3862796, lng: 18.8612625 },
    { lat: 49.3863299, lng: 18.8614556 },
    { lat: 49.3863929, lng: 18.861608 },
    { lat: 49.3865257, lng: 18.8617788 },
    { lat: 49.3866043, lng: 18.8618606 },
    { lat: 49.386795, lng: 18.8617332 },
    { lat: 49.3868395, lng: 18.8618502 },
    { lat: 49.3868775, lng: 18.8619553 },
    { lat: 49.3869234, lng: 18.8620594 },
    { lat: 49.3869549, lng: 18.8621487 },
    { lat: 49.3870092, lng: 18.8622413 },
    { lat: 49.3870524, lng: 18.8623787 },
    { lat: 49.3871054, lng: 18.8625338 },
    { lat: 49.387144, lng: 18.8626276 },
    { lat: 49.3871914, lng: 18.8627179 },
    { lat: 49.3872352, lng: 18.8628031 },
    { lat: 49.3872853, lng: 18.862902 },
    { lat: 49.3874169, lng: 18.8630915 },
    { lat: 49.3874641, lng: 18.8631792 },
    { lat: 49.3875229, lng: 18.8632941 },
    { lat: 49.387576, lng: 18.8633862 },
    { lat: 49.3876167, lng: 18.8634515 },
    { lat: 49.3876566, lng: 18.8635072 },
    { lat: 49.3877359, lng: 18.8636215 },
    { lat: 49.3878214, lng: 18.8637079 },
    { lat: 49.3879084, lng: 18.8637981 },
    { lat: 49.387997, lng: 18.8638584 },
    { lat: 49.3880839, lng: 18.8639446 },
    { lat: 49.388185, lng: 18.8640755 },
    { lat: 49.3882576, lng: 18.8641688 },
    { lat: 49.3883369, lng: 18.864264 },
    { lat: 49.3883689, lng: 18.8643166 },
    { lat: 49.3883501, lng: 18.8643941 },
    { lat: 49.388443, lng: 18.8645097 },
    { lat: 49.3885732, lng: 18.864544 },
    { lat: 49.3886256, lng: 18.8645971 },
    { lat: 49.3886362, lng: 18.864653 },
    { lat: 49.3887005, lng: 18.8648064 },
    { lat: 49.38886, lng: 18.8649737 },
    { lat: 49.3888955, lng: 18.8649853 },
    { lat: 49.3887348, lng: 18.8652575 },
    { lat: 49.3886072, lng: 18.8654324 },
    { lat: 49.3886558, lng: 18.8655887 },
    { lat: 49.3886957, lng: 18.8656853 },
    { lat: 49.388737, lng: 18.8657875 },
    { lat: 49.3887937, lng: 18.865949 },
    { lat: 49.3887647, lng: 18.8660429 },
    { lat: 49.3888076, lng: 18.8661098 },
    { lat: 49.3889058, lng: 18.8662019 },
    { lat: 49.388947, lng: 18.8663244 },
    { lat: 49.388938, lng: 18.8663928 },
    { lat: 49.3890176, lng: 18.8665588 },
    { lat: 49.3890803, lng: 18.8666635 },
    { lat: 49.3892571, lng: 18.8669004 },
    { lat: 49.3894946, lng: 18.8675784 },
    { lat: 49.3895797, lng: 18.8676783 },
    { lat: 49.3898612, lng: 18.8679823 },
    { lat: 49.3904822, lng: 18.8686651 },
    { lat: 49.3905075, lng: 18.8688051 },
    { lat: 49.3906953, lng: 18.8689715 },
    { lat: 49.390722, lng: 18.8690092 },
    { lat: 49.3908166, lng: 18.8692064 },
    { lat: 49.3909065, lng: 18.8693328 },
    { lat: 49.3909681, lng: 18.8692628 },
    { lat: 49.3912108, lng: 18.8695331 },
    { lat: 49.3912138, lng: 18.869528 },
    { lat: 49.3912222, lng: 18.869535 },
    { lat: 49.3912667, lng: 18.8695688 },
    { lat: 49.3913117, lng: 18.8696068 },
    { lat: 49.39136, lng: 18.8696293 },
    { lat: 49.391418, lng: 18.8696585 },
    { lat: 49.3915577, lng: 18.8697023 },
    { lat: 49.3924805, lng: 18.87031 },
    { lat: 49.3933501, lng: 18.8707399 },
    { lat: 49.3940825, lng: 18.8713715 },
    { lat: 49.3947732, lng: 18.8718308 },
    { lat: 49.3953124, lng: 18.8721884 },
    { lat: 49.3956492, lng: 18.8728916 },
    { lat: 49.3962894, lng: 18.8734445 },
    { lat: 49.3969947, lng: 18.8736548 },
    { lat: 49.3979473, lng: 18.8739859 },
    { lat: 49.3982993, lng: 18.8740375 },
    { lat: 49.3993158, lng: 18.8741376 },
    { lat: 49.3996524, lng: 18.8743743 },
    { lat: 49.3999481, lng: 18.8744917 },
    { lat: 49.4011041, lng: 18.8749617 },
    { lat: 49.4011019, lng: 18.8749881 },
    { lat: 49.4017126, lng: 18.8753137 },
    { lat: 49.4031282, lng: 18.8762577 },
    { lat: 49.4040073, lng: 18.8770284 },
    { lat: 49.4046315, lng: 18.8774657 },
    { lat: 49.4055225, lng: 18.8778498 },
    { lat: 49.4064389, lng: 18.8787747 },
    { lat: 49.4068024, lng: 18.8790517 },
    { lat: 49.4074475, lng: 18.8797889 },
    { lat: 49.4076721, lng: 18.8805915 },
    { lat: 49.4081064, lng: 18.8812623 },
    { lat: 49.4086683, lng: 18.8822267 },
    { lat: 49.4095329, lng: 18.8838208 },
    { lat: 49.4096711, lng: 18.8842754 },
    { lat: 49.4100357, lng: 18.8854408 },
    { lat: 49.4101363, lng: 18.8857785 },
    { lat: 49.4101615, lng: 18.8857636 },
    { lat: 49.4104411, lng: 18.8866976 },
    { lat: 49.4105495, lng: 18.8875008 },
    { lat: 49.4106594, lng: 18.8880235 },
    { lat: 49.4109843, lng: 18.8888903 },
    { lat: 49.4111403, lng: 18.8891889 },
    { lat: 49.4113074, lng: 18.8896139 },
    { lat: 49.4115221, lng: 18.8898038 },
    { lat: 49.41174, lng: 18.8900758 },
    { lat: 49.4120164, lng: 18.8903223 },
    { lat: 49.4124017, lng: 18.8905422 },
    { lat: 49.4126385, lng: 18.8906323 },
    { lat: 49.4127749, lng: 18.8907634 },
    { lat: 49.4130869, lng: 18.8912234 },
    { lat: 49.4134216, lng: 18.8919536 },
    { lat: 49.4137663, lng: 18.8928159 },
    { lat: 49.4141674, lng: 18.8937226 },
    { lat: 49.4144512, lng: 18.8942504 },
    { lat: 49.4148663, lng: 18.8950996 },
    { lat: 49.4154002, lng: 18.8957563 },
    { lat: 49.4157022, lng: 18.8960436 },
    { lat: 49.415887, lng: 18.8964207 },
    { lat: 49.4161012, lng: 18.8961073 },
    { lat: 49.416577, lng: 18.8953999 },
    { lat: 49.4173493, lng: 18.8947992 },
    { lat: 49.4172666, lng: 18.8941467 },
    { lat: 49.4170882, lng: 18.8932978 },
    { lat: 49.4171781, lng: 18.893114 },
    { lat: 49.4175794, lng: 18.8926893 },
    { lat: 49.4181732, lng: 18.8923281 },
    { lat: 49.4182039, lng: 18.8924226 },
    { lat: 49.4184047, lng: 18.89258 },
    { lat: 49.4185598, lng: 18.8923914 },
    { lat: 49.4184268, lng: 18.8920371 },
    { lat: 49.4184441, lng: 18.8918906 },
    { lat: 49.4186193, lng: 18.8915663 },
    { lat: 49.4185122, lng: 18.8913646 },
    { lat: 49.4187811, lng: 18.890797 },
    { lat: 49.4187539, lng: 18.8907086 },
    { lat: 49.4188502, lng: 18.8904748 },
    { lat: 49.418904, lng: 18.8902453 },
    { lat: 49.4189286, lng: 18.8896876 },
    { lat: 49.4188845, lng: 18.8895068 },
    { lat: 49.4187803, lng: 18.8889854 },
    { lat: 49.4186777, lng: 18.8888525 },
    { lat: 49.4187075, lng: 18.888552 },
    { lat: 49.4187082, lng: 18.8883419 },
    { lat: 49.418629, lng: 18.8880478 },
    { lat: 49.4187494, lng: 18.8874878 },
    { lat: 49.4189007, lng: 18.8870236 },
    { lat: 49.4189513, lng: 18.8867782 },
    { lat: 49.4189306, lng: 18.8865245 },
    { lat: 49.4188764, lng: 18.8862677 },
    { lat: 49.4187638, lng: 18.8858634 },
    { lat: 49.4186921, lng: 18.8856474 },
    { lat: 49.418479, lng: 18.8855157 },
    { lat: 49.4184295, lng: 18.8849408 },
    { lat: 49.4183789, lng: 18.8846987 },
    { lat: 49.4182705, lng: 18.8846487 },
    { lat: 49.4180702, lng: 18.8841461 },
    { lat: 49.4179095, lng: 18.8840888 },
    { lat: 49.4178538, lng: 18.8839282 },
    { lat: 49.4177355, lng: 18.8837518 },
    { lat: 49.4175791, lng: 18.8836224 },
    { lat: 49.4174548, lng: 18.8832161 },
    { lat: 49.4173666, lng: 18.883169 },
    { lat: 49.4172811, lng: 18.8825274 },
    { lat: 49.4171913, lng: 18.8820019 },
    { lat: 49.4169784, lng: 18.8818042 },
    { lat: 49.4168139, lng: 18.881586 },
    { lat: 49.4166219, lng: 18.8817117 },
    { lat: 49.4165154, lng: 18.8812573 },
    { lat: 49.4165078, lng: 18.8810649 },
    { lat: 49.4163553, lng: 18.879882 },
    { lat: 49.416383, lng: 18.8798743 },
    { lat: 49.4163083, lng: 18.8792268 },
    { lat: 49.4163299, lng: 18.8784963 },
    { lat: 49.4164073, lng: 18.8773591 },
    { lat: 49.4164451, lng: 18.8761531 },
    { lat: 49.4163992, lng: 18.8761376 },
    { lat: 49.4162938, lng: 18.8748828 },
    { lat: 49.416177, lng: 18.8748483 },
    { lat: 49.416134, lng: 18.8740036 },
    { lat: 49.4160889, lng: 18.87398 },
    { lat: 49.4159602, lng: 18.8735536 },
    { lat: 49.4159268, lng: 18.8734316 },
    { lat: 49.4158619, lng: 18.8733966 },
    { lat: 49.4156951, lng: 18.8726206 },
    { lat: 49.4155085, lng: 18.8720585 },
    { lat: 49.4150271, lng: 18.8699956 },
    { lat: 49.4148566, lng: 18.8692404 },
    { lat: 49.4146926, lng: 18.8684967 },
    { lat: 49.4144693, lng: 18.8675454 },
    { lat: 49.41452, lng: 18.8673453 },
    { lat: 49.4143931, lng: 18.8661851 },
    { lat: 49.4143402, lng: 18.8661849 },
    { lat: 49.4142662, lng: 18.8655717 },
    { lat: 49.4142433, lng: 18.8650813 },
    { lat: 49.414235, lng: 18.8641861 },
    { lat: 49.4140182, lng: 18.8636729 },
    { lat: 49.4140795, lng: 18.8635906 },
    { lat: 49.4137437, lng: 18.8629736 },
    { lat: 49.4136061, lng: 18.8627768 },
    { lat: 49.4133303, lng: 18.8624271 },
    { lat: 49.4133214, lng: 18.8618995 },
    { lat: 49.4127073, lng: 18.8611569 },
    { lat: 49.412654, lng: 18.8609725 },
    { lat: 49.4125087, lng: 18.8606438 },
    { lat: 49.4124869, lng: 18.8605464 },
    { lat: 49.412403, lng: 18.8603827 },
    { lat: 49.4122411, lng: 18.8601044 },
    { lat: 49.411929, lng: 18.8594228 },
    { lat: 49.4111246, lng: 18.8583724 },
    { lat: 49.4108831, lng: 18.8580835 },
    { lat: 49.4107264, lng: 18.8578423 },
    { lat: 49.4103578, lng: 18.8572659 },
    { lat: 49.4098622, lng: 18.8567913 },
    { lat: 49.4092462, lng: 18.8564049 },
    { lat: 49.4086607, lng: 18.856112 },
    { lat: 49.4087844, lng: 18.8557293 },
    { lat: 49.4088034, lng: 18.8556169 },
    { lat: 49.4089207, lng: 18.8553972 },
    { lat: 49.4092055, lng: 18.8550097 },
    { lat: 49.4097572, lng: 18.8544649 },
    { lat: 49.4097545, lng: 18.8537979 },
    { lat: 49.4098045, lng: 18.8532546 },
    { lat: 49.4099823, lng: 18.8526924 },
    { lat: 49.4100659, lng: 18.8519701 },
    { lat: 49.4101273, lng: 18.8515804 },
    { lat: 49.410101, lng: 18.8512009 },
    { lat: 49.4101672, lng: 18.8497128 },
    { lat: 49.4101978, lng: 18.8489013 },
    { lat: 49.4102318, lng: 18.8480638 },
    { lat: 49.4102739, lng: 18.8475652 },
    { lat: 49.4103214, lng: 18.8469986 },
    { lat: 49.4103558, lng: 18.8450882 },
    { lat: 49.4104203, lng: 18.844718 },
    { lat: 49.4104097, lng: 18.8443123 },
    { lat: 49.4104023, lng: 18.8441424 },
    { lat: 49.4103834, lng: 18.8437108 },
    { lat: 49.4103549, lng: 18.8433514 },
    { lat: 49.4103933, lng: 18.8431781 },
    { lat: 49.410442, lng: 18.8430926 },
    { lat: 49.4104805, lng: 18.8429214 },
    { lat: 49.4104791, lng: 18.8428057 },
    { lat: 49.410528, lng: 18.8427679 },
    { lat: 49.4107552, lng: 18.8426049 },
    { lat: 49.4109756, lng: 18.8421942 },
    { lat: 49.4110924, lng: 18.8419651 },
    { lat: 49.4113782, lng: 18.8412518 },
    { lat: 49.4116417, lng: 18.8403631 },
    { lat: 49.4116635, lng: 18.8402808 },
    { lat: 49.4116811, lng: 18.8402277 },
    { lat: 49.4117629, lng: 18.8399745 },
    { lat: 49.4119116, lng: 18.8392248 },
    { lat: 49.4119967, lng: 18.8388612 },
    { lat: 49.4120593, lng: 18.8385202 },
    { lat: 49.412205, lng: 18.838154 },
    { lat: 49.4122791, lng: 18.8373307 },
    { lat: 49.4122305, lng: 18.837262 },
    { lat: 49.4120736, lng: 18.8370408 },
    { lat: 49.4125899, lng: 18.8361343 },
    { lat: 49.412907, lng: 18.8356775 },
    { lat: 49.4132761, lng: 18.8351805 },
    { lat: 49.4138287, lng: 18.8345683 },
    { lat: 49.4143202, lng: 18.8341264 },
    { lat: 49.4144683, lng: 18.8340485 },
    { lat: 49.414346, lng: 18.8336859 },
    { lat: 49.4142769, lng: 18.8335671 },
    { lat: 49.4142719, lng: 18.8335579 },
    { lat: 49.4142238, lng: 18.8334754 },
    { lat: 49.4141848, lng: 18.8333747 },
    { lat: 49.414125, lng: 18.8332215 },
    { lat: 49.4141041, lng: 18.8330531 },
    { lat: 49.4140881, lng: 18.8330268 },
    { lat: 49.4141112, lng: 18.8328824 },
    { lat: 49.4141003, lng: 18.8327805 },
    { lat: 49.4140599, lng: 18.8326518 },
    { lat: 49.4140532, lng: 18.8326312 },
    { lat: 49.4140345, lng: 18.8324462 },
    { lat: 49.4139653, lng: 18.8321301 },
    { lat: 49.4138691, lng: 18.8319259 },
    { lat: 49.4136901, lng: 18.8315995 },
    { lat: 49.4136436, lng: 18.8314809 },
    { lat: 49.4136173, lng: 18.8313453 },
    { lat: 49.4135436, lng: 18.8310924 },
    { lat: 49.4134989, lng: 18.8308153 },
    { lat: 49.4133735, lng: 18.8306364 },
    { lat: 49.4133392, lng: 18.8305159 },
    { lat: 49.413324, lng: 18.8303711 },
    { lat: 49.4133281, lng: 18.8300784 },
    { lat: 49.4132032, lng: 18.8297331 },
    { lat: 49.4130891, lng: 18.8293515 },
    { lat: 49.4130732, lng: 18.8290224 },
    { lat: 49.4129436, lng: 18.8285181 },
    { lat: 49.4128895, lng: 18.8280343 },
    { lat: 49.4128031, lng: 18.8277123 },
    { lat: 49.4126132, lng: 18.8272847 },
    { lat: 49.4125137, lng: 18.8269719 },
    { lat: 49.4124936, lng: 18.8269077 },
    { lat: 49.4123573, lng: 18.8264693 },
    { lat: 49.4122158, lng: 18.8262637 },
    { lat: 49.4121158, lng: 18.8259401 },
    { lat: 49.4121066, lng: 18.8258246 },
    { lat: 49.4121061, lng: 18.8258142 },
    { lat: 49.4120865, lng: 18.8255239 },
    { lat: 49.4120781, lng: 18.8254018 },
    { lat: 49.4120444, lng: 18.8248783 },
    { lat: 49.4119066, lng: 18.8243254 },
    { lat: 49.4118673, lng: 18.8241487 },
    { lat: 49.4117981, lng: 18.8238369 },
    { lat: 49.4116658, lng: 18.8232323 },
    { lat: 49.4116156, lng: 18.8228259 },
    { lat: 49.4114145, lng: 18.8217899 },
    { lat: 49.4112968, lng: 18.8209873 },
    { lat: 49.4111734, lng: 18.8206046 },
    { lat: 49.4110263, lng: 18.8202885 },
    { lat: 49.4110324, lng: 18.8197457 },
    { lat: 49.410955, lng: 18.8195591 },
    { lat: 49.4109144, lng: 18.819182 },
    { lat: 49.4108473, lng: 18.8187549 },
    { lat: 49.4108462, lng: 18.8187546 },
    { lat: 49.4106844, lng: 18.818306 },
    { lat: 49.4106712, lng: 18.8182699 },
    { lat: 49.41067, lng: 18.8182693 },
    { lat: 49.4104022, lng: 18.8176416 },
    { lat: 49.4101971, lng: 18.8174248 },
    { lat: 49.4101949, lng: 18.8174233 },
    { lat: 49.4101613, lng: 18.8172343 },
    { lat: 49.4101016, lng: 18.8169044 },
    { lat: 49.4100482, lng: 18.8166096 },
    { lat: 49.4099789, lng: 18.8164412 },
    { lat: 49.4099618, lng: 18.8163995 },
    { lat: 49.4099097, lng: 18.8161763 },
    { lat: 49.4097517, lng: 18.81536 },
    { lat: 49.4097347, lng: 18.8152904 },
    { lat: 49.409587, lng: 18.8145677 },
    { lat: 49.4095688, lng: 18.8144986 },
    { lat: 49.4095674, lng: 18.8144955 },
    { lat: 49.4094263, lng: 18.8139647 },
    { lat: 49.4093726, lng: 18.8137529 },
    { lat: 49.4092978, lng: 18.81357 },
    { lat: 49.4091955, lng: 18.8134002 },
    { lat: 49.4091855, lng: 18.813404 },
    { lat: 49.409182, lng: 18.8132943 },
    { lat: 49.4090341, lng: 18.8129175 },
    { lat: 49.4089642, lng: 18.8127384 },
    { lat: 49.4088751, lng: 18.8125225 },
    { lat: 49.4087619, lng: 18.8122454 },
    { lat: 49.4087193, lng: 18.8121434 },
    { lat: 49.4085634, lng: 18.8117625 },
    { lat: 49.4085517, lng: 18.8117328 },
    { lat: 49.4085103, lng: 18.8115437 },
    { lat: 49.4084887, lng: 18.811362 },
    { lat: 49.4084381, lng: 18.8111686 },
    { lat: 49.40827, lng: 18.8106647 },
    { lat: 49.4082697, lng: 18.8106593 },
    { lat: 49.4082261, lng: 18.8105096 },
    { lat: 49.4081681, lng: 18.8097776 },
    { lat: 49.4081615, lng: 18.8097246 },
    { lat: 49.4081528, lng: 18.8096545 },
    { lat: 49.4080857, lng: 18.8091405 },
    { lat: 49.4080328, lng: 18.8087269 },
    { lat: 49.4080363, lng: 18.8085291 },
    { lat: 49.4080577, lng: 18.8082879 },
    { lat: 49.408065, lng: 18.8082154 },
    { lat: 49.4080987, lng: 18.8078833 },
    { lat: 49.4081099, lng: 18.8077689 },
    { lat: 49.4081657, lng: 18.8074084 },
    { lat: 49.4081664, lng: 18.807404 },
    { lat: 49.4081996, lng: 18.8071893 },
    { lat: 49.408226, lng: 18.8070228 },
    { lat: 49.4082258, lng: 18.8070203 },
    { lat: 49.4082258, lng: 18.8069783 },
    { lat: 49.4082285, lng: 18.8068556 },
    { lat: 49.4082984, lng: 18.8060668 },
    { lat: 49.4083071, lng: 18.805909 },
    { lat: 49.4083379, lng: 18.8053227 },
    { lat: 49.408342, lng: 18.8052473 },
    { lat: 49.4083465, lng: 18.8051595 },
    { lat: 49.40835, lng: 18.8050997 },
    { lat: 49.4083552, lng: 18.8050042 },
    { lat: 49.4083637, lng: 18.8048384 },
    { lat: 49.4083676, lng: 18.8047796 },
    { lat: 49.4083697, lng: 18.8047348 },
    { lat: 49.4083721, lng: 18.804694 },
    { lat: 49.4083334, lng: 18.8045118 },
    { lat: 49.4082427, lng: 18.804084 },
    { lat: 49.4082095, lng: 18.8039214 },
    { lat: 49.4081557, lng: 18.8036665 },
    { lat: 49.4081527, lng: 18.8036546 },
    { lat: 49.4081412, lng: 18.8036042 },
    { lat: 49.4081118, lng: 18.8034691 },
    { lat: 49.4080546, lng: 18.8032117 },
    { lat: 49.408029, lng: 18.8030924 },
    { lat: 49.4079645, lng: 18.8028029 },
    { lat: 49.4078807, lng: 18.8024231 },
    { lat: 49.4077851, lng: 18.80199 },
    { lat: 49.4077308, lng: 18.8017432 },
    { lat: 49.4076909, lng: 18.8015586 },
    { lat: 49.4076586, lng: 18.8014144 },
    { lat: 49.4069512, lng: 18.801386 },
    { lat: 49.406941, lng: 18.8013861 },
    { lat: 49.4064469, lng: 18.8011364 },
    { lat: 49.4063752, lng: 18.8011012 },
    { lat: 49.4062437, lng: 18.8011541 },
    { lat: 49.4055987, lng: 18.8014149 },
    { lat: 49.4055137, lng: 18.8014495 },
    { lat: 49.4055093, lng: 18.8014511 },
    { lat: 49.4054365, lng: 18.801482 },
    { lat: 49.405378, lng: 18.8015072 },
    { lat: 49.4052766, lng: 18.801551 },
    { lat: 49.4052037, lng: 18.8015822 },
    { lat: 49.4050868, lng: 18.8016322 },
    { lat: 49.4049831, lng: 18.8015922 },
    { lat: 49.4045047, lng: 18.8014083 },
    { lat: 49.4043781, lng: 18.8013592 },
    { lat: 49.4037692, lng: 18.801422 },
    { lat: 49.4036726, lng: 18.8014318 },
    { lat: 49.4036681, lng: 18.801432 },
    { lat: 49.4034989, lng: 18.8014496 },
    { lat: 49.4031382, lng: 18.8008419 },
    { lat: 49.4029791, lng: 18.8005734 },
    { lat: 49.4025561, lng: 18.7995638 },
    { lat: 49.4021508, lng: 18.7989659 },
    { lat: 49.4017705, lng: 18.7985405 },
    { lat: 49.4015562, lng: 18.7984079 },
    { lat: 49.4009586, lng: 18.7984531 },
    { lat: 49.400251, lng: 18.7985065 },
    { lat: 49.3994446, lng: 18.7985672 },
    { lat: 49.3991076, lng: 18.7985931 },
    { lat: 49.3988749, lng: 18.7985384 },
    { lat: 49.3982459, lng: 18.7983897 },
    { lat: 49.3977781, lng: 18.7982798 },
    { lat: 49.3976918, lng: 18.7982233 },
    { lat: 49.3975913, lng: 18.798158 },
    { lat: 49.3974464, lng: 18.7981252 },
    { lat: 49.3970802, lng: 18.7980421 },
    { lat: 49.3967505, lng: 18.7979669 },
    { lat: 49.3963797, lng: 18.7978822 },
    { lat: 49.3962097, lng: 18.7978438 },
    { lat: 49.3962017, lng: 18.7978386 },
    { lat: 49.3957801, lng: 18.7975352 },
    { lat: 49.3956395, lng: 18.7974346 },
    { lat: 49.3953941, lng: 18.7972891 },
    { lat: 49.3952609, lng: 18.7971112 },
    { lat: 49.3949197, lng: 18.7971616 },
    { lat: 49.39464, lng: 18.797203 },
    { lat: 49.394424, lng: 18.7972346 },
    { lat: 49.3943031, lng: 18.797272 },
    { lat: 49.3941578, lng: 18.7972542 },
    { lat: 49.3938991, lng: 18.797309 },
    { lat: 49.3937185, lng: 18.7972205 },
    { lat: 49.3934758, lng: 18.7972375 },
    { lat: 49.3931294, lng: 18.7971626 },
    { lat: 49.3929174, lng: 18.7970557 },
    { lat: 49.3926167, lng: 18.7968891 },
    { lat: 49.3924081, lng: 18.7968202 },
    { lat: 49.3920637, lng: 18.7968532 },
    { lat: 49.3920503, lng: 18.7968545 },
    { lat: 49.391796, lng: 18.7966706 },
    { lat: 49.3917788, lng: 18.7966673 },
    { lat: 49.3915397, lng: 18.7966213 },
    { lat: 49.3910596, lng: 18.7963591 },
    { lat: 49.3910571, lng: 18.7963576 },
    { lat: 49.390511, lng: 18.7959988 },
    { lat: 49.3904257, lng: 18.7959436 },
    { lat: 49.3902026, lng: 18.7958779 },
    { lat: 49.3902026, lng: 18.7958776 },
    { lat: 49.3897442, lng: 18.7957344 },
    { lat: 49.3893608, lng: 18.7958633 },
    { lat: 49.3890144, lng: 18.7961249 },
    { lat: 49.3890124, lng: 18.7961266 },
    { lat: 49.3888716, lng: 18.796173 },
    { lat: 49.3888693, lng: 18.7961736 },
    { lat: 49.388603, lng: 18.7962098 },
    { lat: 49.3881495, lng: 18.7966882 },
    { lat: 49.3879172, lng: 18.79732 },
    { lat: 49.3879141, lng: 18.7973287 },
    { lat: 49.387905, lng: 18.7973497 },
    { lat: 49.3877737, lng: 18.7976432 },
    { lat: 49.3875502, lng: 18.798144 },
    { lat: 49.3874747, lng: 18.798227 },
    { lat: 49.3873303, lng: 18.7983835 },
    { lat: 49.3871704, lng: 18.7985574 },
    { lat: 49.3870241, lng: 18.7987166 },
    { lat: 49.3869941, lng: 18.7987484 },
    { lat: 49.3867913, lng: 18.7991468 },
    { lat: 49.3866901, lng: 18.7993462 },
    { lat: 49.3866349, lng: 18.799481 },
    { lat: 49.3865258, lng: 18.7997475 },
    { lat: 49.3862805, lng: 18.8003472 },
    { lat: 49.3859847, lng: 18.8006671 },
    { lat: 49.3858106, lng: 18.8008557 },
    { lat: 49.3857989, lng: 18.800868 },
    { lat: 49.3857936, lng: 18.8008741 },
    { lat: 49.3855689, lng: 18.8011535 },
    { lat: 49.3853274, lng: 18.8004488 },
    { lat: 49.3852383, lng: 18.800187 },
    { lat: 49.3854273, lng: 18.7995446 },
    { lat: 49.3852401, lng: 18.799108 },
    { lat: 49.3851095, lng: 18.798802 },
    { lat: 49.3850228, lng: 18.7983485 },
    { lat: 49.3849764, lng: 18.7981044 },
    { lat: 49.3847423, lng: 18.797435 },
    { lat: 49.38464, lng: 18.7966993 },
    { lat: 49.3845543, lng: 18.7963702 },
    { lat: 49.3845503, lng: 18.7963562 },
    { lat: 49.3845137, lng: 18.7962145 },
    { lat: 49.3845136, lng: 18.7962127 },
    { lat: 49.3845067, lng: 18.7961885 },
    { lat: 49.3846308, lng: 18.7959061 },
    { lat: 49.3847037, lng: 18.7952632 },
    { lat: 49.3847212, lng: 18.7945767 },
    { lat: 49.3847227, lng: 18.7945414 },
    { lat: 49.3847347, lng: 18.7945316 },
    { lat: 49.3847422, lng: 18.7945256 },
    { lat: 49.3852732, lng: 18.7941298 },
    { lat: 49.3851312, lng: 18.793653 },
    { lat: 49.3849301, lng: 18.7930994 },
    { lat: 49.3847383, lng: 18.7927473 },
    { lat: 49.3845891, lng: 18.7924752 },
    { lat: 49.3846387, lng: 18.7923012 },
    { lat: 49.3843571, lng: 18.7917624 },
    { lat: 49.3841647, lng: 18.7912881 },
    { lat: 49.3844191, lng: 18.7908219 },
    { lat: 49.3844682, lng: 18.7902438 },
    { lat: 49.3845656, lng: 18.789641 },
    { lat: 49.3845665, lng: 18.7896351 },
    { lat: 49.3845859, lng: 18.7894221 },
    { lat: 49.384615, lng: 18.7891149 },
    { lat: 49.3846272, lng: 18.7884559 },
    { lat: 49.3846233, lng: 18.7878762 },
    { lat: 49.3847086, lng: 18.7873006 },
    { lat: 49.384661, lng: 18.7868188 },
    { lat: 49.3845315, lng: 18.7862558 },
    { lat: 49.3845303, lng: 18.7862498 },
    { lat: 49.3845183, lng: 18.786197 },
    { lat: 49.3845173, lng: 18.786196 },
    { lat: 49.3845142, lng: 18.7861816 },
    { lat: 49.3845085, lng: 18.7861577 },
    { lat: 49.3844674, lng: 18.7862122 },
    { lat: 49.3844346, lng: 18.7862553 },
    { lat: 49.3843462, lng: 18.7863329 },
    { lat: 49.3842861, lng: 18.7863938 },
    { lat: 49.3841227, lng: 18.7865707 },
    { lat: 49.3840348, lng: 18.7866335 },
    { lat: 49.3839424, lng: 18.7866566 },
    { lat: 49.3838624, lng: 18.7867032 },
    { lat: 49.3837324, lng: 18.7867168 },
    { lat: 49.3835686, lng: 18.7867569 },
    { lat: 49.383428, lng: 18.7868055 },
    { lat: 49.3832565, lng: 18.786912 },
    { lat: 49.3832532, lng: 18.7868936 },
    { lat: 49.3832283, lng: 18.7869104 },
    { lat: 49.3832046, lng: 18.7869549 },
    { lat: 49.3831912, lng: 18.7869793 },
    { lat: 49.3830663, lng: 18.7871363 },
    { lat: 49.3830001, lng: 18.7872305 },
    { lat: 49.3829168, lng: 18.7874067 },
    { lat: 49.3827879, lng: 18.787486 },
    { lat: 49.382609, lng: 18.787605 },
    { lat: 49.3824785, lng: 18.7877403 },
    { lat: 49.3823355, lng: 18.7877894 },
    { lat: 49.382161, lng: 18.7879014 },
    { lat: 49.3818769, lng: 18.7881012 },
    { lat: 49.3817392, lng: 18.788229 },
    { lat: 49.3815274, lng: 18.7883419 },
    { lat: 49.3814031, lng: 18.7884243 },
    { lat: 49.381265, lng: 18.7885449 },
    { lat: 49.3810793, lng: 18.7886369 },
    { lat: 49.3809768, lng: 18.7887239 },
    { lat: 49.3808238, lng: 18.7888408 },
    { lat: 49.3807013, lng: 18.7889386 },
    { lat: 49.3805152, lng: 18.7890874 },
    { lat: 49.3803499, lng: 18.7892251 },
    { lat: 49.3802652, lng: 18.7893783 },
    { lat: 49.3801833, lng: 18.789449 },
    { lat: 49.3801204, lng: 18.7894352 },
    { lat: 49.3800515, lng: 18.7894552 },
    { lat: 49.3799181, lng: 18.789598 },
    { lat: 49.379864, lng: 18.7896675 },
    { lat: 49.3796614, lng: 18.789847 },
    { lat: 49.3796277, lng: 18.7898952 },
    { lat: 49.3795074, lng: 18.7899666 },
    { lat: 49.3793093, lng: 18.7902117 },
    { lat: 49.3790842, lng: 18.7904839 },
    { lat: 49.3789607, lng: 18.7905579 },
    { lat: 49.3789085, lng: 18.7906351 },
    { lat: 49.3788711, lng: 18.7907211 },
    { lat: 49.3788114, lng: 18.7907862 },
    { lat: 49.3787815, lng: 18.7908629 },
    { lat: 49.3787449, lng: 18.7909853 },
    { lat: 49.3786012, lng: 18.7911493 },
    { lat: 49.3784144, lng: 18.7913532 },
    { lat: 49.3783321, lng: 18.7914435 },
    { lat: 49.3783239, lng: 18.7914572 },
    { lat: 49.3782523, lng: 18.7915762 },
    { lat: 49.3781932, lng: 18.7917231 },
    { lat: 49.3780713, lng: 18.7918916 },
    { lat: 49.3779774, lng: 18.7921056 },
    { lat: 49.3779442, lng: 18.7921809 },
    { lat: 49.3779296, lng: 18.7922269 },
    { lat: 49.3778684, lng: 18.7923743 },
    { lat: 49.3778042, lng: 18.7924624 },
    { lat: 49.3777565, lng: 18.7925438 },
    { lat: 49.3777213, lng: 18.7926263 },
    { lat: 49.3776519, lng: 18.792702 },
    { lat: 49.3776067, lng: 18.7928091 },
    { lat: 49.3775884, lng: 18.7928486 },
    { lat: 49.3775567, lng: 18.7929132 },
    { lat: 49.3775337, lng: 18.792988 },
    { lat: 49.3775133, lng: 18.7931389 },
    { lat: 49.3774469, lng: 18.7932746 },
    { lat: 49.377451, lng: 18.7933552 },
    { lat: 49.3774387, lng: 18.7934634 },
    { lat: 49.3773884, lng: 18.7936714 },
    { lat: 49.3773631, lng: 18.7937892 },
    { lat: 49.3773275, lng: 18.7939734 },
    { lat: 49.3773124, lng: 18.7941384 },
    { lat: 49.3771983, lng: 18.7944406 },
    { lat: 49.3771636, lng: 18.7945559 },
    { lat: 49.377104, lng: 18.7947979 },
    { lat: 49.3770649, lng: 18.7950705 },
    { lat: 49.3770219, lng: 18.7951976 },
    { lat: 49.3770424, lng: 18.7953305 },
    { lat: 49.3770713, lng: 18.7955191 },
    { lat: 49.3770813, lng: 18.7958226 },
    { lat: 49.3771358, lng: 18.7960052 },
    { lat: 49.3771132, lng: 18.7961339 },
    { lat: 49.3770864, lng: 18.7962508 },
    { lat: 49.377053, lng: 18.7963861 },
    { lat: 49.3770407, lng: 18.7964376 },
    { lat: 49.3770446, lng: 18.7966173 },
    { lat: 49.3770864, lng: 18.796884 },
    { lat: 49.3770777, lng: 18.7970631 },
    { lat: 49.3770589, lng: 18.7972698 },
    { lat: 49.3770513, lng: 18.7974075 },
    { lat: 49.3770772, lng: 18.7975799 },
    { lat: 49.3770922, lng: 18.7976729 },
    { lat: 49.3770796, lng: 18.7979103 },
    { lat: 49.3770411, lng: 18.7981491 },
    { lat: 49.3770073, lng: 18.7982342 },
    { lat: 49.3769969, lng: 18.7983809 },
    { lat: 49.3769618, lng: 18.7985343 },
    { lat: 49.3769068, lng: 18.7986679 },
    { lat: 49.3768693, lng: 18.7988624 },
    { lat: 49.376826, lng: 18.7989838 },
    { lat: 49.3768524, lng: 18.7991435 },
    { lat: 49.3768055, lng: 18.7992649 },
    { lat: 49.3767656, lng: 18.7994728 },
    { lat: 49.3767506, lng: 18.7995343 },
    { lat: 49.3767423, lng: 18.7995693 },
    { lat: 49.3766345, lng: 18.7996884 },
    { lat: 49.3765856, lng: 18.7998307 },
    { lat: 49.376551, lng: 18.7999919 },
    { lat: 49.3764602, lng: 18.8001523 },
    { lat: 49.376371, lng: 18.8003694 },
    { lat: 49.3762967, lng: 18.8004583 },
    { lat: 49.3761637, lng: 18.8005287 },
    { lat: 49.3760809, lng: 18.8006042 },
    { lat: 49.3759762, lng: 18.8006943 },
    { lat: 49.3758919, lng: 18.8007877 },
    { lat: 49.3758042, lng: 18.8008367 },
    { lat: 49.3756375, lng: 18.8009495 },
    { lat: 49.3755458, lng: 18.8009197 },
    { lat: 49.3754475, lng: 18.8010249 },
    { lat: 49.3752861, lng: 18.80098 },
    { lat: 49.3751989, lng: 18.8012127 },
    { lat: 49.3751667, lng: 18.8013306 },
    { lat: 49.3750798, lng: 18.8013726 },
    { lat: 49.3750318, lng: 18.8014461 },
    { lat: 49.3749464, lng: 18.8014303 },
    { lat: 49.3748543, lng: 18.8015681 },
    { lat: 49.3747379, lng: 18.8016933 },
    { lat: 49.3746149, lng: 18.801694 },
    { lat: 49.3745696, lng: 18.8017733 },
    { lat: 49.374512, lng: 18.8017977 },
    { lat: 49.3744073, lng: 18.8017564 },
    { lat: 49.3743458, lng: 18.801833 },
    { lat: 49.3742849, lng: 18.8019619 },
    { lat: 49.3741711, lng: 18.8020278 },
    { lat: 49.3741125, lng: 18.8021836 },
    { lat: 49.37401, lng: 18.8021844 },
    { lat: 49.3739684, lng: 18.802188 },
    { lat: 49.3739394, lng: 18.8022356 },
    { lat: 49.3739083, lng: 18.8023342 },
    { lat: 49.3737903, lng: 18.8023412 },
    { lat: 49.3737679, lng: 18.8026703 },
    { lat: 49.3736421, lng: 18.8027463 },
    { lat: 49.3735616, lng: 18.8028265 },
    { lat: 49.3735388, lng: 18.8030167 },
    { lat: 49.373384, lng: 18.8032301 },
    { lat: 49.3732945, lng: 18.8032456 },
    { lat: 49.3732659, lng: 18.8033005 },
    { lat: 49.3732595, lng: 18.8033729 },
    { lat: 49.3731797, lng: 18.8035312 },
    { lat: 49.3730703, lng: 18.8036168 },
    { lat: 49.3730356, lng: 18.8037777 },
    { lat: 49.3729675, lng: 18.8038315 },
    { lat: 49.3728765, lng: 18.8038407 },
    { lat: 49.3728728, lng: 18.8040072 },
    { lat: 49.3728308, lng: 18.8041559 },
    { lat: 49.3728297, lng: 18.8041564 },
    { lat: 49.3726579, lng: 18.8043668 },
    { lat: 49.3726066, lng: 18.8045079 },
    { lat: 49.3724856, lng: 18.8046127 },
    { lat: 49.3724592, lng: 18.8046929 },
    { lat: 49.3724478, lng: 18.8048426 },
    { lat: 49.3723878, lng: 18.805012 },
    { lat: 49.3723704, lng: 18.8051556 },
    { lat: 49.3723705, lng: 18.8051581 },
    { lat: 49.3723684, lng: 18.8051583 },
    { lat: 49.3723168, lng: 18.8051649 },
    { lat: 49.3722596, lng: 18.8051707 },
    { lat: 49.3722163, lng: 18.8053804 },
    { lat: 49.3722431, lng: 18.8054644 },
    { lat: 49.3722249, lng: 18.8056569 },
    { lat: 49.3722251, lng: 18.8056598 },
    { lat: 49.3722092, lng: 18.8056823 },
    { lat: 49.3720962, lng: 18.8058504 },
    { lat: 49.3720416, lng: 18.8058871 },
    { lat: 49.3720193, lng: 18.8059122 },
    { lat: 49.3720054, lng: 18.8059273 },
    { lat: 49.3719334, lng: 18.8059971 },
    { lat: 49.3719048, lng: 18.806336 },
    { lat: 49.3718885, lng: 18.8065246 },
    { lat: 49.3718009, lng: 18.8067726 },
    { lat: 49.3718323, lng: 18.8069649 },
    { lat: 49.3718777, lng: 18.8071027 },
    { lat: 49.3719025, lng: 18.8072136 },
    { lat: 49.3718722, lng: 18.8073269 },
    { lat: 49.3717599, lng: 18.8074226 },
    { lat: 49.371701, lng: 18.8075491 },
    { lat: 49.3717041, lng: 18.8076736 },
    { lat: 49.3718148, lng: 18.8079605 },
    { lat: 49.3718153, lng: 18.8080567 },
    { lat: 49.3717993, lng: 18.808076 },
    { lat: 49.3716996, lng: 18.8081126 },
    { lat: 49.3716773, lng: 18.8082025 },
    { lat: 49.3716854, lng: 18.8084476 },
    { lat: 49.3719144, lng: 18.8087155 },
    { lat: 49.3719021, lng: 18.8088252 },
    { lat: 49.3718846, lng: 18.808921 },
    { lat: 49.3718572, lng: 18.8089815 },
    { lat: 49.3717075, lng: 18.8090506 },
    { lat: 49.3716763, lng: 18.8091242 },
    { lat: 49.3716746, lng: 18.8093765 },
    { lat: 49.3717103, lng: 18.8094598 },
    { lat: 49.3717554, lng: 18.8095227 },
    { lat: 49.3717829, lng: 18.8095342 },
    { lat: 49.3718263, lng: 18.8096093 },
    { lat: 49.3718378, lng: 18.8096781 },
    { lat: 49.3718203, lng: 18.8097776 },
    { lat: 49.371732, lng: 18.8099221 },
    { lat: 49.371709, lng: 18.8100262 },
    { lat: 49.371751, lng: 18.8103775 },
    { lat: 49.3717665, lng: 18.8105044 },
    { lat: 49.3717998, lng: 18.8106053 },
    { lat: 49.3718687, lng: 18.8107367 },
    { lat: 49.3718922, lng: 18.8107993 },
    { lat: 49.3718992, lng: 18.8108697 },
    { lat: 49.3718876, lng: 18.8109294 },
    { lat: 49.3718651, lng: 18.8109744 },
    { lat: 49.3718099, lng: 18.8111957 },
    { lat: 49.3718105, lng: 18.8112488 },
    { lat: 49.3717931, lng: 18.8113927 },
    { lat: 49.3717395, lng: 18.81149 },
    { lat: 49.3716647, lng: 18.8115696 },
    { lat: 49.3716485, lng: 18.81165 },
    { lat: 49.371647, lng: 18.8118188 },
    { lat: 49.3716031, lng: 18.8119743 },
    { lat: 49.3714891, lng: 18.8119956 },
    { lat: 49.3714451, lng: 18.8120353 },
    { lat: 49.3713944, lng: 18.8121702 },
    { lat: 49.3713056, lng: 18.8123503 },
    { lat: 49.3712587, lng: 18.81262 },
    { lat: 49.3712125, lng: 18.8126672 },
    { lat: 49.3710676, lng: 18.8126337 },
    { lat: 49.3708994, lng: 18.8127817 },
    { lat: 49.3708074, lng: 18.8129205 },
    { lat: 49.3707699, lng: 18.8132221 },
    { lat: 49.3706591, lng: 18.8133498 },
    { lat: 49.3702584, lng: 18.8137353 },
    { lat: 49.3702273, lng: 18.8140502 },
    { lat: 49.3701488, lng: 18.814191 },
    { lat: 49.370011, lng: 18.8140517 },
    { lat: 49.3698973, lng: 18.8141016 },
    { lat: 49.3696964, lng: 18.814361 },
    { lat: 49.3696816, lng: 18.8144685 },
    { lat: 49.3697267, lng: 18.8147716 },
    { lat: 49.3696718, lng: 18.814847 },
    { lat: 49.369597, lng: 18.8149038 },
    { lat: 49.3695566, lng: 18.8149083 },
    { lat: 49.3694072, lng: 18.8150276 },
    { lat: 49.3693148, lng: 18.8152048 },
    { lat: 49.3692816, lng: 18.8154566 },
    { lat: 49.369251, lng: 18.8155631 },
    { lat: 49.3692074, lng: 18.8156396 },
    { lat: 49.3690792, lng: 18.8157336 },
    { lat: 49.3690216, lng: 18.8157756 },
    { lat: 49.3689589, lng: 18.8159333 },
    { lat: 49.3688441, lng: 18.816445 },
    { lat: 49.3688197, lng: 18.8169121 },
    { lat: 49.3688136, lng: 18.8170782 },
    { lat: 49.3687651, lng: 18.8171633 },
    { lat: 49.3687555, lng: 18.8171988 },
    { lat: 49.3687421, lng: 18.8172456 },
    { lat: 49.3687199, lng: 18.8173196 },
    { lat: 49.3686917, lng: 18.8176659 },
    { lat: 49.368542, lng: 18.8185505 },
    { lat: 49.3685298, lng: 18.8189466 },
    { lat: 49.368447, lng: 18.8191563 },
    { lat: 49.3684304, lng: 18.8194503 },
    { lat: 49.3684151, lng: 18.8197444 },
    { lat: 49.3684046, lng: 18.8197609 },
    { lat: 49.3684059, lng: 18.8198052 },
    { lat: 49.3683936, lng: 18.8200057 },
    { lat: 49.3683304, lng: 18.8202025 },
    { lat: 49.368182, lng: 18.820559 },
    { lat: 49.3680019, lng: 18.8209631 },
    { lat: 49.3679303, lng: 18.8211689 },
    { lat: 49.36781, lng: 18.8214624 },
    { lat: 49.3676449, lng: 18.8217163 },
    { lat: 49.367539, lng: 18.821936 },
    { lat: 49.367494, lng: 18.8220261 },
    { lat: 49.367427, lng: 18.8221246 },
    { lat: 49.3672888, lng: 18.8222903 },
    { lat: 49.3671707, lng: 18.8224062 },
    { lat: 49.36712, lng: 18.8224723 },
    { lat: 49.3670037, lng: 18.8226651 },
    { lat: 49.3668097, lng: 18.8229941 },
    { lat: 49.366791, lng: 18.823021 },
    { lat: 49.366721, lng: 18.8233055 },
    { lat: 49.3666886, lng: 18.8234012 },
    { lat: 49.3666628, lng: 18.8234915 },
    { lat: 49.3666574, lng: 18.8236025 },
    { lat: 49.3667088, lng: 18.8239686 },
    { lat: 49.3667049, lng: 18.8240269 },
    { lat: 49.3666979, lng: 18.8240658 },
    { lat: 49.3666863, lng: 18.8241012 },
    { lat: 49.3665949, lng: 18.8242305 },
    { lat: 49.3664621, lng: 18.824368 },
    { lat: 49.3664402, lng: 18.8244716 },
    { lat: 49.3664565, lng: 18.8246328 },
    { lat: 49.3664708, lng: 18.8247993 },
    { lat: 49.3664815, lng: 18.8249677 },
    { lat: 49.3664723, lng: 18.8250968 },
    { lat: 49.3664703, lng: 18.825122 },
    { lat: 49.3664492, lng: 18.8253272 },
    { lat: 49.3664635, lng: 18.825473 },
    { lat: 49.3664485, lng: 18.825553 },
    { lat: 49.3664277, lng: 18.8255848 },
    { lat: 49.3663841, lng: 18.825615 },
    { lat: 49.3663206, lng: 18.8256755 },
    { lat: 49.3662963, lng: 18.8257088 },
    { lat: 49.366281, lng: 18.8257598 },
    { lat: 49.3662693, lng: 18.8259512 },
    { lat: 49.3662607, lng: 18.8259992 },
    { lat: 49.3662465, lng: 18.8260317 },
    { lat: 49.366232, lng: 18.8260566 },
    { lat: 49.3662172, lng: 18.8260768 },
    { lat: 49.3661204, lng: 18.8261655 },
    { lat: 49.3660458, lng: 18.8263192 },
    { lat: 49.3659251, lng: 18.8263381 },
    { lat: 49.3658503, lng: 18.826354 },
    { lat: 49.3658381, lng: 18.8263561 },
    { lat: 49.3658231, lng: 18.8263706 },
    { lat: 49.3657932, lng: 18.8264278 },
    { lat: 49.3657575, lng: 18.8265026 },
    { lat: 49.3656807, lng: 18.8266066 },
    { lat: 49.3656025, lng: 18.8266663 },
    { lat: 49.3655815, lng: 18.8266725 },
    { lat: 49.3655267, lng: 18.8267048 },
    { lat: 49.365507, lng: 18.8267353 },
    { lat: 49.3654849, lng: 18.8267886 },
    { lat: 49.3654379, lng: 18.8268627 },
    { lat: 49.3653399, lng: 18.827043 },
    { lat: 49.3652877, lng: 18.8271846 },
    { lat: 49.3652555, lng: 18.8272623 },
    { lat: 49.3651889, lng: 18.8275894 },
    { lat: 49.3651505, lng: 18.8276968 },
    { lat: 49.3651127, lng: 18.8277986 },
    { lat: 49.3650999, lng: 18.8278761 },
    { lat: 49.3650807, lng: 18.8279859 },
    { lat: 49.3650769, lng: 18.8280674 },
    { lat: 49.3650762, lng: 18.8281828 },
    { lat: 49.3650923, lng: 18.8283669 },
    { lat: 49.3650918, lng: 18.8284859 },
    { lat: 49.3650761, lng: 18.8286235 },
    { lat: 49.3650191, lng: 18.828787 },
    { lat: 49.3649611, lng: 18.8289093 },
    { lat: 49.3649036, lng: 18.8289955 },
    { lat: 49.3648303, lng: 18.8291049 },
    { lat: 49.364794, lng: 18.8291429 },
    { lat: 49.364744, lng: 18.8292223 },
    { lat: 49.3647265, lng: 18.8292599 },
    { lat: 49.3646951, lng: 18.8292821 },
    { lat: 49.3646444, lng: 18.8293725 },
    { lat: 49.3646365, lng: 18.8293937 },
    { lat: 49.3645998, lng: 18.8294926 },
    { lat: 49.3645812, lng: 18.8295447 },
    { lat: 49.3645544, lng: 18.829617 },
    { lat: 49.3645187, lng: 18.8297125 },
    { lat: 49.3645169, lng: 18.8297189 },
    { lat: 49.3645117, lng: 18.8297285 },
    { lat: 49.3645432, lng: 18.829771 },
    { lat: 49.3645421, lng: 18.8298837 },
    { lat: 49.3645165, lng: 18.8299953 },
    { lat: 49.3652854, lng: 18.8309008 },
    { lat: 49.3652904, lng: 18.8309067 },
    { lat: 49.3653077, lng: 18.8309803 },
    { lat: 49.3653226, lng: 18.8310494 },
    { lat: 49.3653473, lng: 18.8311582 },
    { lat: 49.365363, lng: 18.831225 },
    { lat: 49.3653803, lng: 18.8313007 },
    { lat: 49.3655857, lng: 18.8322036 },
    { lat: 49.3655902, lng: 18.8322233 },
    { lat: 49.366153, lng: 18.8328216 },
    { lat: 49.3661065, lng: 18.8330167 },
    { lat: 49.3660619, lng: 18.8332023 },
    { lat: 49.3660527, lng: 18.8332392 },
    { lat: 49.3660118, lng: 18.8333682 },
    { lat: 49.3660061, lng: 18.8333859 },
    { lat: 49.3659992, lng: 18.8334077 },
    { lat: 49.3659925, lng: 18.8334284 },
    { lat: 49.3659526, lng: 18.8335529 },
    { lat: 49.3659255, lng: 18.8336383 },
    { lat: 49.3659049, lng: 18.8337015 },
    { lat: 49.3657871, lng: 18.8339114 },
    { lat: 49.3657356, lng: 18.8340033 },
    { lat: 49.3655609, lng: 18.8343148 },
    { lat: 49.3655286, lng: 18.8345647 },
    { lat: 49.3654885, lng: 18.834703 },
    { lat: 49.3654316, lng: 18.8347605 },
    { lat: 49.3653854, lng: 18.8347795 },
    { lat: 49.3653276, lng: 18.834777 },
    { lat: 49.3652228, lng: 18.8349951 },
    { lat: 49.3651216, lng: 18.8352022 },
    { lat: 49.3650534, lng: 18.8353454 },
    { lat: 49.3650184, lng: 18.8355377 },
    { lat: 49.3650026, lng: 18.8356087 },
    { lat: 49.3649973, lng: 18.8356332 },
    { lat: 49.3649811, lng: 18.8356492 },
    { lat: 49.3649041, lng: 18.8357279 },
    { lat: 49.3648898, lng: 18.8357347 },
    { lat: 49.3649475, lng: 18.8358931 },
    { lat: 49.3648762, lng: 18.835935 },
    { lat: 49.3648697, lng: 18.8359386 },
    { lat: 49.3645727, lng: 18.8361038 },
    { lat: 49.364406, lng: 18.8361969 },
    { lat: 49.3642043, lng: 18.8363087 },
    { lat: 49.3641118, lng: 18.8364164 },
    { lat: 49.3640711, lng: 18.8365501 },
    { lat: 49.3639859, lng: 18.836603 },
    { lat: 49.3638035, lng: 18.8366296 },
    { lat: 49.3637403, lng: 18.836761 },
    { lat: 49.3636624, lng: 18.8368676 },
    { lat: 49.3633143, lng: 18.8370469 },
    { lat: 49.3631538, lng: 18.8372843 },
    { lat: 49.3628759, lng: 18.8373807 },
    { lat: 49.3626508, lng: 18.8375426 },
    { lat: 49.3624327, lng: 18.8376665 },
    { lat: 49.3623407, lng: 18.8378295 },
    { lat: 49.3621772, lng: 18.8380242 },
    { lat: 49.3620803, lng: 18.8380033 },
    { lat: 49.3618767, lng: 18.8382133 },
    { lat: 49.3618534, lng: 18.8384824 },
    { lat: 49.3617103, lng: 18.8391235 },
    { lat: 49.3615875, lng: 18.8396733 },
    { lat: 49.3614907, lng: 18.8399196 },
    { lat: 49.3614835, lng: 18.8402215 },
    { lat: 49.3614496, lng: 18.8403717 },
    { lat: 49.3613728, lng: 18.8405238 },
    { lat: 49.3611868, lng: 18.8408084 },
    { lat: 49.3611384, lng: 18.8409426 },
    { lat: 49.3611102, lng: 18.8412082 },
    { lat: 49.3609264, lng: 18.8415594 },
    { lat: 49.360605, lng: 18.8421297 },
    { lat: 49.3605782, lng: 18.8421767 },
    { lat: 49.3605726, lng: 18.8422671 },
    { lat: 49.3605821, lng: 18.8423228 },
    { lat: 49.3605931, lng: 18.8423815 },
    { lat: 49.3605892, lng: 18.8425259 },
    { lat: 49.3605527, lng: 18.8426482 },
    { lat: 49.3603928, lng: 18.8431384 },
    { lat: 49.3602711, lng: 18.8435121 },
    { lat: 49.3601695, lng: 18.8437099 },
    { lat: 49.3600453, lng: 18.843859 },
    { lat: 49.3598845, lng: 18.8439799 },
    { lat: 49.3597047, lng: 18.8440348 },
    { lat: 49.3595305, lng: 18.8441118 },
    { lat: 49.359396, lng: 18.844215 },
    { lat: 49.359203, lng: 18.844361 },
    { lat: 49.3590806, lng: 18.8443428 },
    { lat: 49.358988, lng: 18.844329 },
    { lat: 49.358806, lng: 18.84459 },
    { lat: 49.35859, lng: 18.844729 },
    { lat: 49.3584965, lng: 18.8449417 },
    { lat: 49.358137, lng: 18.845759 },
    { lat: 49.358042, lng: 18.845875 },
    { lat: 49.358001, lng: 18.8459259 },
    { lat: 49.3579546, lng: 18.8463861 },
    { lat: 49.3579583, lng: 18.8465227 },
    { lat: 49.3581818, lng: 18.8474088 },
    { lat: 49.3583842, lng: 18.8481922 },
    { lat: 49.3586571, lng: 18.8489176 },
    { lat: 49.3589404, lng: 18.8500034 },
    { lat: 49.3590215, lng: 18.8503156 },
    { lat: 49.3590555, lng: 18.8505354 },
    { lat: 49.3590845, lng: 18.8512134 },
    { lat: 49.3590732, lng: 18.8517002 },
    { lat: 49.3590158, lng: 18.8520507 },
    { lat: 49.3588944, lng: 18.8533174 },
    { lat: 49.3589023, lng: 18.8533617 },
    { lat: 49.3589039, lng: 18.8535445 },
    { lat: 49.3588424, lng: 18.853779 },
    { lat: 49.3587693, lng: 18.8543318 },
    { lat: 49.35879, lng: 18.8546483 },
    { lat: 49.3587988, lng: 18.8548852 },
    { lat: 49.358923, lng: 18.8552639 },
    { lat: 49.3592909, lng: 18.8556719 },
    { lat: 49.3593831, lng: 18.8559495 },
    { lat: 49.3594366, lng: 18.8560263 },
    { lat: 49.3596285, lng: 18.8562319 },
    { lat: 49.3596805, lng: 18.8564127 },
    { lat: 49.359645, lng: 18.856664 },
    { lat: 49.3596999, lng: 18.8567909 },
    { lat: 49.3596813, lng: 18.8568908 },
    { lat: 49.3596119, lng: 18.8570119 },
    { lat: 49.3595513, lng: 18.8571237 },
    { lat: 49.359446, lng: 18.8575364 },
    { lat: 49.3593548, lng: 18.8577126 },
    { lat: 49.3593603, lng: 18.8578208 },
    { lat: 49.3593508, lng: 18.8580777 },
    { lat: 49.3594216, lng: 18.8585343 },
    { lat: 49.3595535, lng: 18.8589992 },
    { lat: 49.3595829, lng: 18.8590695 },
    { lat: 49.3596011, lng: 18.8590941 },
    { lat: 49.3596238, lng: 18.8590986 },
    { lat: 49.3596947, lng: 18.8590478 },
    { lat: 49.3599284, lng: 18.859146 },
    { lat: 49.3600833, lng: 18.8593058 },
    { lat: 49.3601414, lng: 18.8593198 },
    { lat: 49.3604581, lng: 18.8596277 },
    { lat: 49.3608847, lng: 18.8600559 },
    { lat: 49.3610296, lng: 18.8603319 },
    { lat: 49.3611206, lng: 18.8604567 },
    { lat: 49.361187, lng: 18.8605453 },
    { lat: 49.3612352, lng: 18.860758 },
    { lat: 49.3613096, lng: 18.8614892 },
    { lat: 49.3613725, lng: 18.8619012 },
    { lat: 49.3614026, lng: 18.8622271 },
    { lat: 49.3614428, lng: 18.8625283 },
    { lat: 49.3616509, lng: 18.8633567 },
    { lat: 49.3616928, lng: 18.8634747 },
    { lat: 49.3619501, lng: 18.8642071 },
    { lat: 49.3620709, lng: 18.864494 },
    { lat: 49.3620959, lng: 18.8645677 },
    { lat: 49.3621288, lng: 18.8646581 },
    { lat: 49.3621689, lng: 18.8647159 },
    { lat: 49.3622961, lng: 18.8649546 },
    { lat: 49.3623714, lng: 18.865129 },
    { lat: 49.3624044, lng: 18.8652198 },
    { lat: 49.3625463, lng: 18.8658576 },
    { lat: 49.3626602, lng: 18.8662144 },
    { lat: 49.3627683, lng: 18.8668095 },
    { lat: 49.3629346, lng: 18.8676386 },
    { lat: 49.363665, lng: 18.8687395 },
    { lat: 49.3640173, lng: 18.8689495 },
    { lat: 49.3645309, lng: 18.869714 },
    { lat: 49.3650911, lng: 18.8705083 },
    { lat: 49.3653178, lng: 18.8708853 },
    { lat: 49.3657432, lng: 18.8712696 },
  ],
  Makov: [
    { lat: 49.393683, lng: 18.428944 },
    { lat: 49.393867, lng: 18.428526 },
    { lat: 49.394139, lng: 18.42806 },
    { lat: 49.394356, lng: 18.427679 },
    { lat: 49.394717, lng: 18.42689 },
    { lat: 49.394922, lng: 18.426228 },
    { lat: 49.395106, lng: 18.425638 },
    { lat: 49.395182, lng: 18.425237 },
    { lat: 49.39524, lng: 18.424256 },
    { lat: 49.395395, lng: 18.423088 },
    { lat: 49.395516, lng: 18.422708 },
    { lat: 49.39573, lng: 18.422412 },
    { lat: 49.396164, lng: 18.421808 },
    { lat: 49.396294, lng: 18.421619 },
    { lat: 49.396682, lng: 18.420792 },
    { lat: 49.396869, lng: 18.420366 },
    { lat: 49.39714, lng: 18.419714 },
    { lat: 49.397214, lng: 18.419517 },
    { lat: 49.397327, lng: 18.419008 },
    { lat: 49.397347, lng: 18.418385 },
    { lat: 49.39761, lng: 18.417177 },
    { lat: 49.397747, lng: 18.416275 },
    { lat: 49.39793, lng: 18.415652 },
    { lat: 49.398047, lng: 18.415253 },
    { lat: 49.398281, lng: 18.41439 },
    { lat: 49.398592, lng: 18.413805 },
    { lat: 49.39888, lng: 18.412977 },
    { lat: 49.399001, lng: 18.412423 },
    { lat: 49.3990559, lng: 18.4120916 },
    { lat: 49.39908, lng: 18.411946 },
    { lat: 49.399134, lng: 18.41114 },
    { lat: 49.3991363, lng: 18.4110403 },
    { lat: 49.399156, lng: 18.410176 },
    { lat: 49.3991011, lng: 18.4098086 },
    { lat: 49.399083, lng: 18.409665 },
    { lat: 49.39907, lng: 18.409544 },
    { lat: 49.398969, lng: 18.408764 },
    { lat: 49.398776, lng: 18.407926 },
    { lat: 49.398472, lng: 18.407346 },
    { lat: 49.398081, lng: 18.406911 },
    { lat: 49.397896, lng: 18.406696 },
    { lat: 49.397695, lng: 18.406314 },
    { lat: 49.397487, lng: 18.40592 },
    { lat: 49.397267, lng: 18.405229 },
    { lat: 49.396929, lng: 18.404375 },
    { lat: 49.3966913, lng: 18.4036607 },
    { lat: 49.395557, lng: 18.403902 },
    { lat: 49.394677, lng: 18.404309 },
    { lat: 49.393692, lng: 18.404945 },
    { lat: 49.393198, lng: 18.405316 },
    { lat: 49.392928, lng: 18.405521 },
    { lat: 49.392602, lng: 18.406694 },
    { lat: 49.392187, lng: 18.407325 },
    { lat: 49.391646, lng: 18.407663 },
    { lat: 49.391126, lng: 18.407989 },
    { lat: 49.390449, lng: 18.408509 },
    { lat: 49.389896, lng: 18.408916 },
    { lat: 49.389653, lng: 18.408847 },
    { lat: 49.388832, lng: 18.408612 },
    { lat: 49.388215, lng: 18.40865 },
    { lat: 49.387692, lng: 18.408549 },
    { lat: 49.387236, lng: 18.408221 },
    { lat: 49.386429, lng: 18.408481 },
    { lat: 49.38578, lng: 18.409033 },
    { lat: 49.385049, lng: 18.409657 },
    { lat: 49.384392, lng: 18.409932 },
    { lat: 49.38377, lng: 18.410233 },
    { lat: 49.383127, lng: 18.410602 },
    { lat: 49.38306, lng: 18.410615 },
    { lat: 49.3829629, lng: 18.4106377 },
    { lat: 49.382882, lng: 18.410652 },
    { lat: 49.382542, lng: 18.410712 },
    { lat: 49.382204, lng: 18.410703 },
    { lat: 49.381398, lng: 18.410407 },
    { lat: 49.38093, lng: 18.410258 },
    { lat: 49.380416, lng: 18.410165 },
    { lat: 49.380182, lng: 18.410277 },
    { lat: 49.379568, lng: 18.410747 },
    { lat: 49.379016, lng: 18.411288 },
    { lat: 49.378653, lng: 18.411587 },
    { lat: 49.378552, lng: 18.411878 },
    { lat: 49.377483, lng: 18.411612 },
    { lat: 49.376963, lng: 18.411099 },
    { lat: 49.37648, lng: 18.410631 },
    { lat: 49.376099, lng: 18.410835 },
    { lat: 49.375664, lng: 18.411105 },
    { lat: 49.375441, lng: 18.411421 },
    { lat: 49.374838, lng: 18.412422 },
    { lat: 49.373878, lng: 18.413017 },
    { lat: 49.373045, lng: 18.413486 },
    { lat: 49.372391, lng: 18.413885 },
    { lat: 49.372107, lng: 18.414061 },
    { lat: 49.371806, lng: 18.414208 },
    { lat: 49.371637, lng: 18.414294 },
    { lat: 49.370811, lng: 18.4146 },
    { lat: 49.370038, lng: 18.414839 },
    { lat: 49.369184, lng: 18.414891 },
    { lat: 49.368589, lng: 18.414945 },
    { lat: 49.367992, lng: 18.415211 },
    { lat: 49.367549, lng: 18.415567 },
    { lat: 49.367011, lng: 18.415299 },
    { lat: 49.366808, lng: 18.414973 },
    { lat: 49.366509, lng: 18.414326 },
    { lat: 49.365909, lng: 18.412761 },
    { lat: 49.365467, lng: 18.411872 },
    { lat: 49.365133, lng: 18.411715 },
    { lat: 49.364341, lng: 18.411636 },
    { lat: 49.363592, lng: 18.411229 },
    { lat: 49.36327, lng: 18.410962 },
    { lat: 49.362967, lng: 18.410712 },
    { lat: 49.362665, lng: 18.410492 },
    { lat: 49.362318, lng: 18.410241 },
    { lat: 49.361597, lng: 18.409574 },
    { lat: 49.361091, lng: 18.409168 },
    { lat: 49.360372, lng: 18.408429 },
    { lat: 49.360199, lng: 18.40801 },
    { lat: 49.360033, lng: 18.407262 },
    { lat: 49.359906, lng: 18.406693 },
    { lat: 49.35972, lng: 18.405682 },
    { lat: 49.359501, lng: 18.404496 },
    { lat: 49.359108, lng: 18.403452 },
    { lat: 49.358482, lng: 18.401985 },
    { lat: 49.357885, lng: 18.400764 },
    { lat: 49.357254, lng: 18.399638 },
    { lat: 49.356971, lng: 18.398958 },
    { lat: 49.356797, lng: 18.398178 },
    { lat: 49.356311, lng: 18.398473 },
    { lat: 49.355576, lng: 18.398923 },
    { lat: 49.35483, lng: 18.39938 },
    { lat: 49.354303, lng: 18.399718 },
    { lat: 49.353701, lng: 18.400057 },
    { lat: 49.353373, lng: 18.400263 },
    { lat: 49.353203, lng: 18.400456 },
    { lat: 49.353061, lng: 18.400618 },
    { lat: 49.352675, lng: 18.401337 },
    { lat: 49.352431, lng: 18.401766 },
    { lat: 49.351776, lng: 18.402727 },
    { lat: 49.35152, lng: 18.403029 },
    { lat: 49.351268, lng: 18.403323 },
    { lat: 49.350787, lng: 18.40374 },
    { lat: 49.350488, lng: 18.404008 },
    { lat: 49.350229, lng: 18.404255 },
    { lat: 49.350112, lng: 18.403792 },
    { lat: 49.350069, lng: 18.403619 },
    { lat: 49.349914, lng: 18.403006 },
    { lat: 49.349926, lng: 18.402717 },
    { lat: 49.349932, lng: 18.402528 },
    { lat: 49.349962, lng: 18.401615 },
    { lat: 49.349845, lng: 18.400662 },
    { lat: 49.349813, lng: 18.400407 },
    { lat: 49.349757, lng: 18.400335 },
    { lat: 49.349722, lng: 18.400304 },
    { lat: 49.349664, lng: 18.400253 },
    { lat: 49.349565, lng: 18.400209 },
    { lat: 49.349511, lng: 18.400206 },
    { lat: 49.349494, lng: 18.400205 },
    { lat: 49.349382, lng: 18.400071 },
    { lat: 49.349274, lng: 18.399831 },
    { lat: 49.349218, lng: 18.399435 },
    { lat: 49.349162, lng: 18.399259 },
    { lat: 49.349099, lng: 18.399087 },
    { lat: 49.349024, lng: 18.399008 },
    { lat: 49.349002, lng: 18.398881 },
    { lat: 49.348939, lng: 18.39864 },
    { lat: 49.349015, lng: 18.398567 },
    { lat: 49.349068, lng: 18.398408 },
    { lat: 49.349075, lng: 18.398282 },
    { lat: 49.349054, lng: 18.39818 },
    { lat: 49.349063, lng: 18.398002 },
    { lat: 49.349093, lng: 18.397806 },
    { lat: 49.349089, lng: 18.397721 },
    { lat: 49.349051, lng: 18.397659 },
    { lat: 49.349005, lng: 18.397565 },
    { lat: 49.348915, lng: 18.39752 },
    { lat: 49.348817, lng: 18.397427 },
    { lat: 49.348826, lng: 18.397283 },
    { lat: 49.348766, lng: 18.397061 },
    { lat: 49.348797, lng: 18.396941 },
    { lat: 49.348817, lng: 18.396728 },
    { lat: 49.348821, lng: 18.396613 },
    { lat: 49.348768, lng: 18.396317 },
    { lat: 49.348742, lng: 18.39614 },
    { lat: 49.348772, lng: 18.395964 },
    { lat: 49.348739, lng: 18.395735 },
    { lat: 49.348712, lng: 18.39559 },
    { lat: 49.348719, lng: 18.395321 },
    { lat: 49.348667, lng: 18.39524 },
    { lat: 49.348277, lng: 18.394509 },
    { lat: 49.347736, lng: 18.393871 },
    { lat: 49.347271, lng: 18.393303 },
    { lat: 49.347056, lng: 18.393037 },
    { lat: 49.346901, lng: 18.392843 },
    { lat: 49.34628, lng: 18.39229 },
    { lat: 49.346193, lng: 18.392217 },
    { lat: 49.346074, lng: 18.392104 },
    { lat: 49.345832, lng: 18.391756 },
    { lat: 49.345359, lng: 18.391085 },
    { lat: 49.344766, lng: 18.390281 },
    { lat: 49.344266, lng: 18.389602 },
    { lat: 49.344098, lng: 18.389226 },
    { lat: 49.343914, lng: 18.388968 },
    { lat: 49.343569, lng: 18.388529 },
    { lat: 49.343193, lng: 18.388032 },
    { lat: 49.342443, lng: 18.387513 },
    { lat: 49.341691, lng: 18.387016 },
    { lat: 49.341608, lng: 18.386956 },
    { lat: 49.34136, lng: 18.386774 },
    { lat: 49.340728, lng: 18.38633 },
    { lat: 49.340499, lng: 18.386166 },
    { lat: 49.34017, lng: 18.385922 },
    { lat: 49.339891, lng: 18.385716 },
    { lat: 49.339261, lng: 18.385314 },
    { lat: 49.338807, lng: 18.384954 },
    { lat: 49.338394, lng: 18.384671 },
    { lat: 49.337808, lng: 18.384247 },
    { lat: 49.337608, lng: 18.384115 },
    { lat: 49.337427, lng: 18.384002 },
    { lat: 49.337126, lng: 18.383738 },
    { lat: 49.336553, lng: 18.383276 },
    { lat: 49.336149, lng: 18.38297 },
    { lat: 49.335664, lng: 18.382611 },
    { lat: 49.335101, lng: 18.382189 },
    { lat: 49.334597, lng: 18.381805 },
    { lat: 49.334001, lng: 18.381355 },
    { lat: 49.333875, lng: 18.381256 },
    { lat: 49.333364, lng: 18.380899 },
    { lat: 49.332904, lng: 18.380545 },
    { lat: 49.332557, lng: 18.380288 },
    { lat: 49.331839, lng: 18.379863 },
    { lat: 49.331476, lng: 18.379587 },
    { lat: 49.331391, lng: 18.379522 },
    { lat: 49.330546, lng: 18.378918 },
    { lat: 49.330262, lng: 18.378215 },
    { lat: 49.330069, lng: 18.377736 },
    { lat: 49.3300512, lng: 18.3776894 },
    { lat: 49.330025, lng: 18.377621 },
    { lat: 49.329726, lng: 18.376879 },
    { lat: 49.329422, lng: 18.376228 },
    { lat: 49.329093, lng: 18.375558 },
    { lat: 49.328824, lng: 18.374937 },
    { lat: 49.328599, lng: 18.374469 },
    { lat: 49.328246, lng: 18.373785 },
    { lat: 49.328122, lng: 18.373573 },
    { lat: 49.328057, lng: 18.373462 },
    { lat: 49.32762, lng: 18.372601 },
    { lat: 49.327212, lng: 18.371687 },
    { lat: 49.326798, lng: 18.37081 },
    { lat: 49.326594, lng: 18.370344 },
    { lat: 49.326275, lng: 18.369633 },
    { lat: 49.326187, lng: 18.369424 },
    { lat: 49.325853, lng: 18.368624 },
    { lat: 49.325439, lng: 18.367634 },
    { lat: 49.32515, lng: 18.367067 },
    { lat: 49.324833, lng: 18.366465 },
    { lat: 49.324617, lng: 18.366058 },
    { lat: 49.324417, lng: 18.365672 },
    { lat: 49.324319, lng: 18.365242 },
    { lat: 49.324224, lng: 18.364745 },
    { lat: 49.324134, lng: 18.364226 },
    { lat: 49.324044, lng: 18.363596 },
    { lat: 49.323903, lng: 18.36272 },
    { lat: 49.323735, lng: 18.361854 },
    { lat: 49.323605, lng: 18.361184 },
    { lat: 49.323525, lng: 18.360634 },
    { lat: 49.3234652, lng: 18.3602661 },
    { lat: 49.323401, lng: 18.359946 },
    { lat: 49.323275, lng: 18.359 },
    { lat: 49.323161, lng: 18.358386 },
    { lat: 49.323068, lng: 18.357884 },
    { lat: 49.323012, lng: 18.357519 },
    { lat: 49.322951, lng: 18.357126 },
    { lat: 49.322776, lng: 18.355988 },
    { lat: 49.322683, lng: 18.355396 },
    { lat: 49.322588, lng: 18.354823 },
    { lat: 49.3225686, lng: 18.3547109 },
    { lat: 49.3225498, lng: 18.3546034 },
    { lat: 49.322449, lng: 18.354056 },
    { lat: 49.322357, lng: 18.353529 },
    { lat: 49.322198, lng: 18.352734 },
    { lat: 49.322013, lng: 18.351608 },
    { lat: 49.321974, lng: 18.351345 },
    { lat: 49.321875, lng: 18.350719 },
    { lat: 49.321755, lng: 18.350043 },
    { lat: 49.3216, lng: 18.349131 },
    { lat: 49.321456, lng: 18.348293 },
    { lat: 49.321366, lng: 18.347796 },
    { lat: 49.321337, lng: 18.347633 },
    { lat: 49.321294, lng: 18.347397 },
    { lat: 49.321101, lng: 18.346387 },
    { lat: 49.320924, lng: 18.345497 },
    { lat: 49.320731, lng: 18.344501 },
    { lat: 49.320551, lng: 18.343537 },
    { lat: 49.320379, lng: 18.342648 },
    { lat: 49.320319, lng: 18.342327 },
    { lat: 49.32012, lng: 18.341253 },
    { lat: 49.319985, lng: 18.34056 },
    { lat: 49.319939, lng: 18.340318 },
    { lat: 49.319876, lng: 18.339988 },
    { lat: 49.319724, lng: 18.339173 },
    { lat: 49.319658, lng: 18.338841 },
    { lat: 49.319556, lng: 18.338294 },
    { lat: 49.319387, lng: 18.3374 },
    { lat: 49.319285, lng: 18.336848 },
    { lat: 49.31924, lng: 18.3366 },
    { lat: 49.319212, lng: 18.336436 },
    { lat: 49.319172, lng: 18.335643 },
    { lat: 49.31911, lng: 18.334505 },
    { lat: 49.319037, lng: 18.333379 },
    { lat: 49.31899, lng: 18.332612 },
    { lat: 49.318958, lng: 18.331991 },
    { lat: 49.318923, lng: 18.331421 },
    { lat: 49.318898, lng: 18.330827 },
    { lat: 49.318881, lng: 18.330461 },
    { lat: 49.3188581, lng: 18.3300443 },
    { lat: 49.318823, lng: 18.3295745 },
    { lat: 49.318823, lng: 18.329574 },
    { lat: 49.318629, lng: 18.328603 },
    { lat: 49.318419, lng: 18.327641 },
    { lat: 49.318256, lng: 18.326899 },
    { lat: 49.318208, lng: 18.326669 },
    { lat: 49.317867, lng: 18.326255 },
    { lat: 49.317552, lng: 18.325889 },
    { lat: 49.317395, lng: 18.325671 },
    { lat: 49.316967, lng: 18.325073 },
    { lat: 49.316722, lng: 18.324722 },
    { lat: 49.316373, lng: 18.324266 },
    { lat: 49.316047, lng: 18.323839 },
    { lat: 49.315762, lng: 18.323465 },
    { lat: 49.315475, lng: 18.323085 },
    { lat: 49.315254, lng: 18.322789 },
    { lat: 49.315207, lng: 18.32273 },
    { lat: 49.315109, lng: 18.322605 },
    { lat: 49.315022, lng: 18.322675 },
    { lat: 49.314536, lng: 18.32317 },
    { lat: 49.314447, lng: 18.32337 },
    { lat: 49.313305, lng: 18.325089 },
    { lat: 49.313421, lng: 18.325617 },
    { lat: 49.313663, lng: 18.326475 },
    { lat: 49.314088, lng: 18.327678 },
    { lat: 49.314342, lng: 18.328114 },
    { lat: 49.314367, lng: 18.328173 },
    { lat: 49.314735, lng: 18.329071 },
    { lat: 49.315089, lng: 18.3305 },
    { lat: 49.315264, lng: 18.331358 },
    { lat: 49.315257, lng: 18.331911 },
    { lat: 49.315162, lng: 18.332679 },
    { lat: 49.315088, lng: 18.333895 },
    { lat: 49.315179, lng: 18.335245 },
    { lat: 49.315245, lng: 18.33578 },
    { lat: 49.3156, lng: 18.337231 },
    { lat: 49.315761, lng: 18.337919 },
    { lat: 49.315834, lng: 18.338822 },
    { lat: 49.315818, lng: 18.339226 },
    { lat: 49.315835, lng: 18.339652 },
    { lat: 49.316062, lng: 18.34076 },
    { lat: 49.316609, lng: 18.341883 },
    { lat: 49.316838, lng: 18.342837 },
    { lat: 49.317146, lng: 18.344158 },
    { lat: 49.317211, lng: 18.34451 },
    { lat: 49.317359, lng: 18.345221 },
    { lat: 49.317565, lng: 18.346 },
    { lat: 49.318192, lng: 18.348027 },
    { lat: 49.318499, lng: 18.348765 },
    { lat: 49.318621, lng: 18.349267 },
    { lat: 49.318666, lng: 18.349832 },
    { lat: 49.31866, lng: 18.351352 },
    { lat: 49.318768, lng: 18.352812 },
    { lat: 49.318945, lng: 18.35395 },
    { lat: 49.319348, lng: 18.355027 },
    { lat: 49.319579, lng: 18.35547 },
    { lat: 49.319705, lng: 18.355895 },
    { lat: 49.319646, lng: 18.35637 },
    { lat: 49.319424, lng: 18.357318 },
    { lat: 49.319077, lng: 18.358252 },
    { lat: 49.318777, lng: 18.358701 },
    { lat: 49.318197, lng: 18.359709 },
    { lat: 49.317953, lng: 18.360206 },
    { lat: 49.317772, lng: 18.360671 },
    { lat: 49.317596, lng: 18.361184 },
    { lat: 49.317535, lng: 18.361558 },
    { lat: 49.317459, lng: 18.362009 },
    { lat: 49.317524, lng: 18.362985 },
    { lat: 49.317738, lng: 18.363778 },
    { lat: 49.318202, lng: 18.364894 },
    { lat: 49.318381, lng: 18.365333 },
    { lat: 49.318572, lng: 18.365882 },
    { lat: 49.319061, lng: 18.367331 },
    { lat: 49.319016, lng: 18.367739 },
    { lat: 49.318829, lng: 18.368828 },
    { lat: 49.31868, lng: 18.370246 },
    { lat: 49.318851, lng: 18.372729 },
    { lat: 49.318983, lng: 18.373402 },
    { lat: 49.319411, lng: 18.373883 },
    { lat: 49.320248, lng: 18.374786 },
    { lat: 49.320947, lng: 18.375355 },
    { lat: 49.3213, lng: 18.376009 },
    { lat: 49.32163, lng: 18.376475 },
    { lat: 49.321945, lng: 18.376722 },
    { lat: 49.322377, lng: 18.377456 },
    { lat: 49.322963, lng: 18.377833 },
    { lat: 49.32315, lng: 18.377953 },
    { lat: 49.323232, lng: 18.378088 },
    { lat: 49.323459, lng: 18.378458 },
    { lat: 49.323477, lng: 18.378488 },
    { lat: 49.323495, lng: 18.378518 },
    { lat: 49.323728, lng: 18.378899 },
    { lat: 49.323863, lng: 18.379346 },
    { lat: 49.323881, lng: 18.379446 },
    { lat: 49.323889, lng: 18.379486 },
    { lat: 49.323897, lng: 18.379525 },
    { lat: 49.324075, lng: 18.38048 },
    { lat: 49.323488, lng: 18.381783 },
    { lat: 49.323271, lng: 18.382711 },
    { lat: 49.323323, lng: 18.38331 },
    { lat: 49.323197, lng: 18.384308 },
    { lat: 49.323312, lng: 18.386992 },
    { lat: 49.323529, lng: 18.387237 },
    { lat: 49.323572, lng: 18.387286 },
    { lat: 49.323588, lng: 18.38735 },
    { lat: 49.324132, lng: 18.389567 },
    { lat: 49.324293, lng: 18.390357 },
    { lat: 49.324708, lng: 18.390918 },
    { lat: 49.325555, lng: 18.39139 },
    { lat: 49.326269, lng: 18.391501 },
    { lat: 49.326919, lng: 18.39164 },
    { lat: 49.327315, lng: 18.392653 },
    { lat: 49.327607, lng: 18.39286 },
    { lat: 49.32813, lng: 18.3931 },
    { lat: 49.32895, lng: 18.393783 },
    { lat: 49.32887, lng: 18.394513 },
    { lat: 49.329382, lng: 18.396219 },
    { lat: 49.329639, lng: 18.396888 },
    { lat: 49.329683, lng: 18.397061 },
    { lat: 49.329843, lng: 18.397687 },
    { lat: 49.330001, lng: 18.398233 },
    { lat: 49.330287, lng: 18.399339 },
    { lat: 49.330495, lng: 18.399665 },
    { lat: 49.331131, lng: 18.399827 },
    { lat: 49.331481, lng: 18.399946 },
    { lat: 49.331868, lng: 18.400127 },
    { lat: 49.331879, lng: 18.400133 },
    { lat: 49.331912, lng: 18.400149 },
    { lat: 49.332683, lng: 18.400155 },
    { lat: 49.332935, lng: 18.400306 },
    { lat: 49.333216, lng: 18.400484 },
    { lat: 49.33349, lng: 18.40079 },
    { lat: 49.333789, lng: 18.400944 },
    { lat: 49.334023, lng: 18.40112 },
    { lat: 49.334063, lng: 18.401151 },
    { lat: 49.334328, lng: 18.402029 },
    { lat: 49.334608, lng: 18.402973 },
    { lat: 49.334883, lng: 18.403669 },
    { lat: 49.335085, lng: 18.404423 },
    { lat: 49.335102, lng: 18.404487 },
    { lat: 49.335406, lng: 18.405626 },
    { lat: 49.335423, lng: 18.405691 },
    { lat: 49.335664, lng: 18.406938 },
    { lat: 49.335896, lng: 18.408268 },
    { lat: 49.336425, lng: 18.409592 },
    { lat: 49.336949, lng: 18.410863 },
    { lat: 49.337037, lng: 18.411044 },
    { lat: 49.336694, lng: 18.411643 },
    { lat: 49.336286, lng: 18.412467 },
    { lat: 49.336092, lng: 18.41296 },
    { lat: 49.335913, lng: 18.413182 },
    { lat: 49.335682, lng: 18.413469 },
    { lat: 49.335702, lng: 18.413752 },
    { lat: 49.335359, lng: 18.413991 },
    { lat: 49.335166, lng: 18.414065 },
    { lat: 49.334699, lng: 18.414139 },
    { lat: 49.334404, lng: 18.414335 },
    { lat: 49.334074, lng: 18.414583 },
    { lat: 49.333336, lng: 18.414993 },
    { lat: 49.332735, lng: 18.415403 },
    { lat: 49.332705, lng: 18.415424 },
    { lat: 49.332578, lng: 18.415511 },
    { lat: 49.332264, lng: 18.415995 },
    { lat: 49.332005, lng: 18.416397 },
    { lat: 49.331778, lng: 18.417007 },
    { lat: 49.331751, lng: 18.417033 },
    { lat: 49.331019, lng: 18.417728 },
    { lat: 49.330285, lng: 18.418573 },
    { lat: 49.330131, lng: 18.419341 },
    { lat: 49.330096, lng: 18.419515 },
    { lat: 49.330031, lng: 18.419633 },
    { lat: 49.329907, lng: 18.420138 },
    { lat: 49.329906, lng: 18.420305 },
    { lat: 49.329947, lng: 18.420591 },
    { lat: 49.329984, lng: 18.420991 },
    { lat: 49.329991, lng: 18.422017 },
    { lat: 49.329973, lng: 18.422445 },
    { lat: 49.330258, lng: 18.423703 },
    { lat: 49.330296, lng: 18.424057 },
    { lat: 49.330288, lng: 18.424361 },
    { lat: 49.330227, lng: 18.424853 },
    { lat: 49.33065, lng: 18.428313 },
    { lat: 49.330476, lng: 18.428521 },
    { lat: 49.330487, lng: 18.428838 },
    { lat: 49.330562, lng: 18.428851 },
    { lat: 49.330794, lng: 18.429998 },
    { lat: 49.330695, lng: 18.430114 },
    { lat: 49.330614, lng: 18.429864 },
    { lat: 49.33045, lng: 18.429664 },
    { lat: 49.330069, lng: 18.430569 },
    { lat: 49.329991, lng: 18.431128 },
    { lat: 49.329904, lng: 18.431468 },
    { lat: 49.329902, lng: 18.432235 },
    { lat: 49.330246, lng: 18.432597 },
    { lat: 49.330325, lng: 18.432681 },
    { lat: 49.3309, lng: 18.43219 },
    { lat: 49.331352, lng: 18.432296 },
    { lat: 49.331616, lng: 18.432566 },
    { lat: 49.331653, lng: 18.432605 },
    { lat: 49.331727, lng: 18.434308 },
    { lat: 49.3322, lng: 18.436095 },
    { lat: 49.332205, lng: 18.436463 },
    { lat: 49.332494, lng: 18.437282 },
    { lat: 49.33226, lng: 18.438513 },
    { lat: 49.33298, lng: 18.438223 },
    { lat: 49.333005, lng: 18.438213 },
    { lat: 49.333253, lng: 18.438113 },
    { lat: 49.333548, lng: 18.4390591 },
    { lat: 49.333842, lng: 18.440002 },
    { lat: 49.334226, lng: 18.441104 },
    { lat: 49.334492, lng: 18.441538 },
    { lat: 49.334691, lng: 18.442044 },
    { lat: 49.33482, lng: 18.442618 },
    { lat: 49.33487, lng: 18.443272 },
    { lat: 49.334872, lng: 18.443288 },
    { lat: 49.334835, lng: 18.443851 },
    { lat: 49.334835, lng: 18.443856 },
    { lat: 49.334806, lng: 18.444021 },
    { lat: 49.334629, lng: 18.44505 },
    { lat: 49.334254, lng: 18.445065 },
    { lat: 49.334201, lng: 18.445067 },
    { lat: 49.333695, lng: 18.445251 },
    { lat: 49.33345, lng: 18.445746 },
    { lat: 49.333243, lng: 18.446637 },
    { lat: 49.332911, lng: 18.447197 },
    { lat: 49.33339, lng: 18.448875 },
    { lat: 49.332875, lng: 18.449945 },
    { lat: 49.332117, lng: 18.450864 },
    { lat: 49.331862, lng: 18.451003 },
    { lat: 49.332179, lng: 18.452557 },
    { lat: 49.332226, lng: 18.453402 },
    { lat: 49.332267, lng: 18.454362 },
    { lat: 49.332355, lng: 18.455035 },
    { lat: 49.332664, lng: 18.455172 },
    { lat: 49.332711, lng: 18.455192 },
    { lat: 49.332992, lng: 18.455317 },
    { lat: 49.333511, lng: 18.455929 },
    { lat: 49.333578, lng: 18.456375 },
    { lat: 49.33358, lng: 18.456385 },
    { lat: 49.334211, lng: 18.457147 },
    { lat: 49.334288, lng: 18.458286 },
    { lat: 49.334481, lng: 18.459294 },
    { lat: 49.334684, lng: 18.461032 },
    { lat: 49.335129, lng: 18.461737 },
    { lat: 49.335821, lng: 18.462288 },
    { lat: 49.335799, lng: 18.462565 },
    { lat: 49.335797, lng: 18.462602 },
    { lat: 49.335727, lng: 18.463533 },
    { lat: 49.335196, lng: 18.464099 },
    { lat: 49.334932, lng: 18.464604 },
    { lat: 49.334698, lng: 18.465496 },
    { lat: 49.334899, lng: 18.467278 },
    { lat: 49.335884, lng: 18.467578 },
    { lat: 49.336654, lng: 18.46682 },
    { lat: 49.336673, lng: 18.466801 },
    { lat: 49.336711, lng: 18.466764 },
    { lat: 49.336762, lng: 18.466714 },
    { lat: 49.336885, lng: 18.466592 },
    { lat: 49.337632, lng: 18.46721 },
    { lat: 49.337903, lng: 18.466867 },
    { lat: 49.33826, lng: 18.467991 },
    { lat: 49.338614, lng: 18.469029 },
    { lat: 49.338874, lng: 18.470351 },
    { lat: 49.338795, lng: 18.471906 },
    { lat: 49.338936, lng: 18.472767 },
    { lat: 49.338685, lng: 18.473808 },
    { lat: 49.338409, lng: 18.474477 },
    { lat: 49.338458, lng: 18.475464 },
    { lat: 49.338628, lng: 18.476313 },
    { lat: 49.338656, lng: 18.476456 },
    { lat: 49.339055, lng: 18.476643 },
    { lat: 49.339085, lng: 18.477377 },
    { lat: 49.339057, lng: 18.478315 },
    { lat: 49.3390401, lng: 18.478773 },
    { lat: 49.339077, lng: 18.479221 },
    { lat: 49.339137, lng: 18.479902 },
    { lat: 49.339466, lng: 18.480745 },
    { lat: 49.339619, lng: 18.481718 },
    { lat: 49.339511, lng: 18.481853 },
    { lat: 49.339514, lng: 18.482306 },
    { lat: 49.339174, lng: 18.482347 },
    { lat: 49.339129, lng: 18.482353 },
    { lat: 49.338943, lng: 18.482375 },
    { lat: 49.338888, lng: 18.482382 },
    { lat: 49.338765, lng: 18.482453 },
    { lat: 49.338721, lng: 18.482479 },
    { lat: 49.338207, lng: 18.482776 },
    { lat: 49.338188, lng: 18.482797 },
    { lat: 49.337615, lng: 18.483444 },
    { lat: 49.338235, lng: 18.48417 },
    { lat: 49.33821, lng: 18.484232 },
    { lat: 49.338331, lng: 18.484568 },
    { lat: 49.339333, lng: 18.485898 },
    { lat: 49.33935, lng: 18.48592 },
    { lat: 49.339498, lng: 18.486182 },
    { lat: 49.339669, lng: 18.486447 },
    { lat: 49.339554, lng: 18.486796 },
    { lat: 49.339832, lng: 18.48726 },
    { lat: 49.33978, lng: 18.487402 },
    { lat: 49.339911, lng: 18.487577 },
    { lat: 49.339938, lng: 18.487612 },
    { lat: 49.340289, lng: 18.488064 },
    { lat: 49.340857, lng: 18.488615 },
    { lat: 49.341167, lng: 18.489062 },
    { lat: 49.34123, lng: 18.489138 },
    { lat: 49.341357, lng: 18.489292 },
    { lat: 49.341711, lng: 18.489682 },
    { lat: 49.341719, lng: 18.489821 },
    { lat: 49.341783, lng: 18.489994 },
    { lat: 49.341764, lng: 18.490065 },
    { lat: 49.341876, lng: 18.490338 },
    { lat: 49.341959, lng: 18.490452 },
    { lat: 49.342135, lng: 18.491085 },
    { lat: 49.342311, lng: 18.491474 },
    { lat: 49.342373, lng: 18.491393 },
    { lat: 49.342419, lng: 18.491949 },
    { lat: 49.34263, lng: 18.492279 },
    { lat: 49.342707, lng: 18.492444 },
    { lat: 49.34273, lng: 18.492495 },
    { lat: 49.342857, lng: 18.492779 },
    { lat: 49.342876, lng: 18.492822 },
    { lat: 49.342904, lng: 18.492929 },
    { lat: 49.342989, lng: 18.493256 },
    { lat: 49.343006, lng: 18.493326 },
    { lat: 49.343087, lng: 18.493629 },
    { lat: 49.343445, lng: 18.494608 },
    { lat: 49.343605, lng: 18.495022 },
    { lat: 49.343763, lng: 18.495247 },
    { lat: 49.343992, lng: 18.495663 },
    { lat: 49.344077, lng: 18.495818 },
    { lat: 49.344197, lng: 18.496419 },
    { lat: 49.344208, lng: 18.496471 },
    { lat: 49.344281, lng: 18.49712 },
    { lat: 49.344334, lng: 18.497376 },
    { lat: 49.344332, lng: 18.497653 },
    { lat: 49.344332, lng: 18.49769 },
    { lat: 49.344493, lng: 18.49833 },
    { lat: 49.344587, lng: 18.498897 },
    { lat: 49.344594, lng: 18.498941 },
    { lat: 49.344635, lng: 18.49938 },
    { lat: 49.344582, lng: 18.499801 },
    { lat: 49.344645, lng: 18.499967 },
    { lat: 49.34471, lng: 18.50016 },
    { lat: 49.34468, lng: 18.500383 },
    { lat: 49.34474, lng: 18.50059 },
    { lat: 49.344968, lng: 18.500968 },
    { lat: 49.344976, lng: 18.501093 },
    { lat: 49.34498, lng: 18.501146 },
    { lat: 49.344994, lng: 18.501347 },
    { lat: 49.345097, lng: 18.501606 },
    { lat: 49.345109, lng: 18.501737 },
    { lat: 49.345105, lng: 18.50179 },
    { lat: 49.345101, lng: 18.501839 },
    { lat: 49.345098, lng: 18.501877 },
    { lat: 49.345084, lng: 18.501954 },
    { lat: 49.34508, lng: 18.50198 },
    { lat: 49.345068, lng: 18.502061 },
    { lat: 49.345063, lng: 18.502226 },
    { lat: 49.345104, lng: 18.502572 },
    { lat: 49.345171, lng: 18.502903 },
    { lat: 49.345262, lng: 18.50329 },
    { lat: 49.345311, lng: 18.503397 },
    { lat: 49.345318, lng: 18.503411 },
    { lat: 49.345333, lng: 18.503448 },
    { lat: 49.345666, lng: 18.502411 },
    { lat: 49.346008, lng: 18.501772 },
    { lat: 49.346235, lng: 18.501232 },
    { lat: 49.346526, lng: 18.500172 },
    { lat: 49.346545, lng: 18.500211 },
    { lat: 49.346578, lng: 18.500382 },
    { lat: 49.346666, lng: 18.500435 },
    { lat: 49.346665, lng: 18.500814 },
    { lat: 49.346851, lng: 18.500877 },
    { lat: 49.346963, lng: 18.500932 },
    { lat: 49.346999, lng: 18.50095 },
    { lat: 49.347032, lng: 18.500966 },
    { lat: 49.347043, lng: 18.500972 },
    { lat: 49.347078, lng: 18.500989 },
    { lat: 49.34711, lng: 18.501036 },
    { lat: 49.347179, lng: 18.501138 },
    { lat: 49.347476, lng: 18.5008192 },
    { lat: 49.3478532, lng: 18.5007434 },
    { lat: 49.3484689, lng: 18.5009279 },
    { lat: 49.3489403, lng: 18.5009922 },
    { lat: 49.3489366, lng: 18.5009445 },
    { lat: 49.3488972, lng: 18.5004449 },
    { lat: 49.3488852, lng: 18.5002879 },
    { lat: 49.3488816, lng: 18.5002403 },
    { lat: 49.3491726, lng: 18.5002196 },
    { lat: 49.3494699, lng: 18.5001703 },
    { lat: 49.3496275, lng: 18.5001441 },
    { lat: 49.3496533, lng: 18.5001401 },
    { lat: 49.3498749, lng: 18.5001073 },
    { lat: 49.3500941, lng: 18.500074 },
    { lat: 49.3501691, lng: 18.5000631 },
    { lat: 49.3501944, lng: 18.5000541 },
    { lat: 49.3504899, lng: 18.4999474 },
    { lat: 49.3510871, lng: 18.4997323 },
    { lat: 49.3514002, lng: 18.4994741 },
    { lat: 49.3518066, lng: 18.499293 },
    { lat: 49.3521329, lng: 18.4994006 },
    { lat: 49.3534109, lng: 18.4990918 },
    { lat: 49.3544084, lng: 18.4988283 },
    { lat: 49.3546094, lng: 18.4987091 },
    { lat: 49.3551213, lng: 18.499249 },
    { lat: 49.3554885, lng: 18.499697 },
    { lat: 49.3558557, lng: 18.4999391 },
    { lat: 49.3563068, lng: 18.4991054 },
    { lat: 49.3566966, lng: 18.4988058 },
    { lat: 49.3570092, lng: 18.4987245 },
    { lat: 49.3582346, lng: 18.4980261 },
    { lat: 49.3585363, lng: 18.4979549 },
    { lat: 49.3588525, lng: 18.4977099 },
    { lat: 49.3595115, lng: 18.4974066 },
    { lat: 49.3599246, lng: 18.4974522 },
    { lat: 49.3604515, lng: 18.4973939 },
    { lat: 49.3607868, lng: 18.4974617 },
    { lat: 49.3612634, lng: 18.4974521 },
    { lat: 49.361658, lng: 18.4973441 },
    { lat: 49.3620013, lng: 18.4974785 },
    { lat: 49.3622804, lng: 18.4976559 },
    { lat: 49.3623819, lng: 18.4977798 },
    { lat: 49.3628059, lng: 18.4981127 },
    { lat: 49.3625476, lng: 18.4985269 },
    { lat: 49.3622808, lng: 18.4991387 },
    { lat: 49.3622578, lng: 18.4991934 },
    { lat: 49.3622006, lng: 18.4993248 },
    { lat: 49.3621934, lng: 18.4993401 },
    { lat: 49.3620812, lng: 18.4995986 },
    { lat: 49.3618334, lng: 18.4999149 },
    { lat: 49.362011, lng: 18.5000085 },
    { lat: 49.3622368, lng: 18.4999534 },
    { lat: 49.362343, lng: 18.500038 },
    { lat: 49.3627363, lng: 18.5000738 },
    { lat: 49.3627555, lng: 18.499991 },
    { lat: 49.3628719, lng: 18.4998713 },
    { lat: 49.3629698, lng: 18.4998856 },
    { lat: 49.3630129, lng: 18.4998918 },
    { lat: 49.3632515, lng: 18.50005 },
    { lat: 49.3641239, lng: 18.5000604 },
    { lat: 49.364319, lng: 18.5001019 },
    { lat: 49.364746, lng: 18.5000918 },
    { lat: 49.3652775, lng: 18.5001792 },
    { lat: 49.3653507, lng: 18.5000541 },
    { lat: 49.3657868, lng: 18.499964 },
    { lat: 49.365957, lng: 18.4999 },
    { lat: 49.3659941, lng: 18.4998956 },
    { lat: 49.3667327, lng: 18.4997899 },
    { lat: 49.36764, lng: 18.4996113 },
    { lat: 49.3679731, lng: 18.4996172 },
    { lat: 49.3685811, lng: 18.4994929 },
    { lat: 49.3687069, lng: 18.4994204 },
    { lat: 49.3694236, lng: 18.4992643 },
    { lat: 49.3699356, lng: 18.499201 },
    { lat: 49.3701425, lng: 18.5001501 },
    { lat: 49.370403, lng: 18.5010026 },
    { lat: 49.3706601, lng: 18.500919 },
    { lat: 49.3709417, lng: 18.5008901 },
    { lat: 49.3710695, lng: 18.5008322 },
    { lat: 49.3712971, lng: 18.5008269 },
    { lat: 49.371685, lng: 18.5005575 },
    { lat: 49.3719749, lng: 18.5004737 },
    { lat: 49.3720847, lng: 18.5004402 },
    { lat: 49.372361, lng: 18.5003544 },
    { lat: 49.3724687, lng: 18.5003049 },
    { lat: 49.3727102, lng: 18.5002424 },
    { lat: 49.3729158, lng: 18.5002507 },
    { lat: 49.3729501, lng: 18.5002173 },
    { lat: 49.3729961, lng: 18.5001713 },
    { lat: 49.3730607, lng: 18.5001128 },
    { lat: 49.3731011, lng: 18.5000682 },
    { lat: 49.3731662, lng: 18.5000216 },
    { lat: 49.3729996, lng: 18.4995686 },
    { lat: 49.3729893, lng: 18.4995044 },
    { lat: 49.3729608, lng: 18.4992876 },
    { lat: 49.3732027, lng: 18.4988631 },
    { lat: 49.3734269, lng: 18.4985262 },
    { lat: 49.3734623, lng: 18.4984073 },
    { lat: 49.3735921, lng: 18.4979721 },
    { lat: 49.3736421, lng: 18.4976245 },
    { lat: 49.3736016, lng: 18.4972742 },
    { lat: 49.3733677, lng: 18.4967433 },
    { lat: 49.3734213, lng: 18.4965689 },
    { lat: 49.3736268, lng: 18.4964707 },
    { lat: 49.3736831, lng: 18.496444 },
    { lat: 49.3737621, lng: 18.4964065 },
    { lat: 49.3738857, lng: 18.4962098 },
    { lat: 49.3739228, lng: 18.4961877 },
    { lat: 49.3739862, lng: 18.49615 },
    { lat: 49.374021, lng: 18.4961253 },
    { lat: 49.3741535, lng: 18.4960317 },
    { lat: 49.3745034, lng: 18.4958678 },
    { lat: 49.3745868, lng: 18.4958282 },
    { lat: 49.375031, lng: 18.4954898 },
    { lat: 49.3751201, lng: 18.49543 },
    { lat: 49.3752595, lng: 18.4953373 },
    { lat: 49.3753215, lng: 18.4952951 },
    { lat: 49.3757654, lng: 18.4948893 },
    { lat: 49.3758577, lng: 18.4945348 },
    { lat: 49.3759767, lng: 18.4936687 },
    { lat: 49.3762397, lng: 18.4925078 },
    { lat: 49.3762786, lng: 18.4914564 },
    { lat: 49.3762747, lng: 18.4909704 },
    { lat: 49.3763358, lng: 18.4899357 },
    { lat: 49.3763973, lng: 18.4896973 },
    { lat: 49.3764902, lng: 18.4884338 },
    { lat: 49.3771315, lng: 18.4873032 },
    { lat: 49.3772703, lng: 18.487139 },
    { lat: 49.3773756, lng: 18.4869196 },
    { lat: 49.3774549, lng: 18.4864686 },
    { lat: 49.3775781, lng: 18.4859114 },
    { lat: 49.3777915, lng: 18.4853536 },
    { lat: 49.3775411, lng: 18.484895 },
    { lat: 49.3774364, lng: 18.4847563 },
    { lat: 49.3775081, lng: 18.4844127 },
    { lat: 49.3777711, lng: 18.483959 },
    { lat: 49.3774651, lng: 18.4836816 },
    { lat: 49.3776309, lng: 18.4834972 },
    { lat: 49.3779986, lng: 18.4832267 },
    { lat: 49.3780791, lng: 18.4829858 },
    { lat: 49.3781716, lng: 18.4828383 },
    { lat: 49.3783913, lng: 18.4827318 },
    { lat: 49.3785978, lng: 18.4825657 },
    { lat: 49.3788059, lng: 18.4822022 },
    { lat: 49.3792175, lng: 18.4818693 },
    { lat: 49.3794524, lng: 18.4813553 },
    { lat: 49.3795722, lng: 18.4806975 },
    { lat: 49.3796671, lng: 18.4804907 },
    { lat: 49.3797893, lng: 18.4799387 },
    { lat: 49.3803973, lng: 18.4792534 },
    { lat: 49.3806625, lng: 18.4785712 },
    { lat: 49.3808542, lng: 18.4781584 },
    { lat: 49.3808227, lng: 18.4780353 },
    { lat: 49.3810411, lng: 18.4779604 },
    { lat: 49.3814038, lng: 18.4780767 },
    { lat: 49.3815314, lng: 18.4781249 },
    { lat: 49.3816231, lng: 18.4781074 },
    { lat: 49.3819779, lng: 18.4781425 },
    { lat: 49.3822765, lng: 18.4779702 },
    { lat: 49.3825943, lng: 18.4778428 },
    { lat: 49.3830237, lng: 18.4775017 },
    { lat: 49.3832401, lng: 18.4774784 },
    { lat: 49.3837694, lng: 18.4777552 },
    { lat: 49.3839913, lng: 18.4778113 },
    { lat: 49.3840756, lng: 18.4780134 },
    { lat: 49.3841316, lng: 18.4784443 },
    { lat: 49.3842169, lng: 18.4786449 },
    { lat: 49.3846876, lng: 18.4787507 },
    { lat: 49.3847061, lng: 18.4787603 },
    { lat: 49.3849337, lng: 18.4788822 },
    { lat: 49.3857156, lng: 18.4794889 },
    { lat: 49.385857, lng: 18.4795165 },
    { lat: 49.3860348, lng: 18.4795517 },
    { lat: 49.386554, lng: 18.4795978 },
    { lat: 49.3867822, lng: 18.4787353 },
    { lat: 49.3869127, lng: 18.4780829 },
    { lat: 49.3868582, lng: 18.4772672 },
    { lat: 49.3868687, lng: 18.4771913 },
    { lat: 49.3869738, lng: 18.4764509 },
    { lat: 49.387164, lng: 18.4757573 },
    { lat: 49.3871913, lng: 18.475656 },
    { lat: 49.3872771, lng: 18.4750991 },
    { lat: 49.3873369, lng: 18.4745386 },
    { lat: 49.3875381, lng: 18.4738403 },
    { lat: 49.3875923, lng: 18.473653 },
    { lat: 49.3876503, lng: 18.4734548 },
    { lat: 49.387545, lng: 18.4733054 },
    { lat: 49.3875712, lng: 18.4732046 },
    { lat: 49.3876028, lng: 18.4730818 },
    { lat: 49.3876385, lng: 18.4729466 },
    { lat: 49.3877038, lng: 18.472696 },
    { lat: 49.3878465, lng: 18.4725826 },
    { lat: 49.387901, lng: 18.471929 },
    { lat: 49.3881893, lng: 18.4718752 },
    { lat: 49.3886726, lng: 18.4717237 },
    { lat: 49.3888234, lng: 18.4717584 },
    { lat: 49.3891281, lng: 18.4716757 },
    { lat: 49.389239, lng: 18.4716862 },
    { lat: 49.3891955, lng: 18.4720679 },
    { lat: 49.3891516, lng: 18.4722336 },
    { lat: 49.3893111, lng: 18.4724301 },
    { lat: 49.3896451, lng: 18.4714562 },
    { lat: 49.3900969, lng: 18.471077 },
    { lat: 49.3899999, lng: 18.4707237 },
    { lat: 49.3899991, lng: 18.4702524 },
    { lat: 49.3900731, lng: 18.4695188 },
    { lat: 49.3901792, lng: 18.4693738 },
    { lat: 49.3902162, lng: 18.4689115 },
    { lat: 49.3901645, lng: 18.4686255 },
    { lat: 49.3901614, lng: 18.4682783 },
    { lat: 49.3903956, lng: 18.467483 },
    { lat: 49.3903632, lng: 18.4670751 },
    { lat: 49.39022, lng: 18.4666628 },
    { lat: 49.3901333, lng: 18.4664368 },
    { lat: 49.3901643, lng: 18.4664473 },
    { lat: 49.3903355, lng: 18.4665051 },
    { lat: 49.3904757, lng: 18.4646866 },
    { lat: 49.390489, lng: 18.4646012 },
    { lat: 49.3907751, lng: 18.4640947 },
    { lat: 49.3910855, lng: 18.4632494 },
    { lat: 49.3913074, lng: 18.4632456 },
    { lat: 49.3915578, lng: 18.4624562 },
    { lat: 49.3919611, lng: 18.4622029 },
    { lat: 49.3935436, lng: 18.4608546 },
    { lat: 49.3943771, lng: 18.4602334 },
    { lat: 49.3957094, lng: 18.4588525 },
    { lat: 49.39574, lng: 18.4588327 },
    { lat: 49.3961254, lng: 18.4585791 },
    { lat: 49.3962798, lng: 18.4585166 },
    { lat: 49.3964183, lng: 18.4584906 },
    { lat: 49.3969277, lng: 18.4582733 },
    { lat: 49.3974652, lng: 18.4579754 },
    { lat: 49.3978838, lng: 18.4579377 },
    { lat: 49.3980386, lng: 18.4579237 },
    { lat: 49.3984236, lng: 18.4577847 },
    { lat: 49.3986853, lng: 18.4575991 },
    { lat: 49.3984142, lng: 18.4565763 },
    { lat: 49.397874, lng: 18.4564727 },
    { lat: 49.3973904, lng: 18.4562083 },
    { lat: 49.3971701, lng: 18.4563897 },
    { lat: 49.3969672, lng: 18.4562277 },
    { lat: 49.3967296, lng: 18.4561697 },
    { lat: 49.3961594, lng: 18.4552099 },
    { lat: 49.3959967, lng: 18.4543972 },
    { lat: 49.3958507, lng: 18.4539527 },
    { lat: 49.3957043, lng: 18.4536687 },
    { lat: 49.3956428, lng: 18.4528926 },
    { lat: 49.3956482, lng: 18.4519765 },
    { lat: 49.395331, lng: 18.4512487 },
    { lat: 49.395222, lng: 18.4509664 },
    { lat: 49.3951473, lng: 18.4506928 },
    { lat: 49.395102, lng: 18.4502354 },
    { lat: 49.3950851, lng: 18.4500692 },
    { lat: 49.39509, lng: 18.450021 },
    { lat: 49.394805, lng: 18.450063 },
    { lat: 49.394286, lng: 18.450107 },
    { lat: 49.393855, lng: 18.45008 },
    { lat: 49.39371, lng: 18.450071 },
    { lat: 49.393589, lng: 18.450064 },
    { lat: 49.393502, lng: 18.449956 },
    { lat: 49.393529, lng: 18.449269 },
    { lat: 49.393516, lng: 18.448812 },
    { lat: 49.393364, lng: 18.448322 },
    { lat: 49.393313, lng: 18.448063 },
    { lat: 49.39324, lng: 18.447697 },
    { lat: 49.393224, lng: 18.447448 },
    { lat: 49.393286, lng: 18.447023 },
    { lat: 49.393218, lng: 18.446268 },
    { lat: 49.393199, lng: 18.44536 },
    { lat: 49.393175, lng: 18.445044 },
    { lat: 49.393231, lng: 18.44445 },
    { lat: 49.393417, lng: 18.44327 },
    { lat: 49.393399, lng: 18.442439 },
    { lat: 49.393382, lng: 18.441562 },
    { lat: 49.393303, lng: 18.441228 },
    { lat: 49.393109, lng: 18.440576 },
    { lat: 49.393111, lng: 18.439646 },
    { lat: 49.392999, lng: 18.439157 },
    { lat: 49.392885, lng: 18.438354 },
    { lat: 49.392808, lng: 18.438073 },
    { lat: 49.392672, lng: 18.43709 },
    { lat: 49.39259, lng: 18.43659 },
    { lat: 49.392591, lng: 18.436004 },
    { lat: 49.392613, lng: 18.435605 },
    { lat: 49.392636, lng: 18.435205 },
    { lat: 49.392638, lng: 18.434573 },
    { lat: 49.392701, lng: 18.434191 },
    { lat: 49.392801, lng: 18.433228 },
    { lat: 49.392847, lng: 18.432946 },
    { lat: 49.393078, lng: 18.432183 },
    { lat: 49.393267, lng: 18.431929 },
    { lat: 49.39345, lng: 18.431672 },
    { lat: 49.393794, lng: 18.431152 },
    { lat: 49.393957, lng: 18.430797 },
    { lat: 49.393901, lng: 18.430261 },
    { lat: 49.393683, lng: 18.428944 },
  ],
  Oščadnica: [
    { lat: 49.4249642, lng: 18.9869133 },
    { lat: 49.425318, lng: 18.986951 },
    { lat: 49.426317, lng: 18.987075 },
    { lat: 49.427186, lng: 18.987132 },
    { lat: 49.427984, lng: 18.987195 },
    { lat: 49.428938, lng: 18.987267 },
    { lat: 49.429468, lng: 18.98804 },
    { lat: 49.429655, lng: 18.988244 },
    { lat: 49.429946, lng: 18.988626 },
    { lat: 49.430506, lng: 18.989 },
    { lat: 49.431032, lng: 18.989042 },
    { lat: 49.431299, lng: 18.988972 },
    { lat: 49.431832, lng: 18.988314 },
    { lat: 49.432107, lng: 18.987388 },
    { lat: 49.432493, lng: 18.98623 },
    { lat: 49.432986, lng: 18.984518 },
    { lat: 49.433466, lng: 18.983751 },
    { lat: 49.433678, lng: 18.983692 },
    { lat: 49.43434, lng: 18.983588 },
    { lat: 49.434805, lng: 18.983282 },
    { lat: 49.435288, lng: 18.982912 },
    { lat: 49.435818, lng: 18.982379 },
    { lat: 49.436577, lng: 18.981516 },
    { lat: 49.437188, lng: 18.980889 },
    { lat: 49.437715, lng: 18.980386 },
    { lat: 49.438206, lng: 18.979842 },
    { lat: 49.438981, lng: 18.979309 },
    { lat: 49.439754, lng: 18.9785 },
    { lat: 49.440532, lng: 18.977787 },
    { lat: 49.441766, lng: 18.978379 },
    { lat: 49.442774, lng: 18.978937 },
    { lat: 49.44329, lng: 18.978411 },
    { lat: 49.444025, lng: 18.977581 },
    { lat: 49.444748, lng: 18.976674 },
    { lat: 49.444862, lng: 18.97633 },
    { lat: 49.445036, lng: 18.97596 },
    { lat: 49.445385, lng: 18.975712 },
    { lat: 49.446271, lng: 18.975926 },
    { lat: 49.447012, lng: 18.976195 },
    { lat: 49.447374, lng: 18.976229 },
    { lat: 49.447874, lng: 18.975898 },
    { lat: 49.448478, lng: 18.975349 },
    { lat: 49.449299, lng: 18.975348 },
    { lat: 49.449858, lng: 18.975167 },
    { lat: 49.450667, lng: 18.974708 },
    { lat: 49.451654, lng: 18.973874 },
    { lat: 49.452049, lng: 18.972922 },
    { lat: 49.452382, lng: 18.972477 },
    { lat: 49.452753, lng: 18.971626 },
    { lat: 49.452838, lng: 18.970688 },
    { lat: 49.45277, lng: 18.969886 },
    { lat: 49.452789, lng: 18.968326 },
    { lat: 49.452801, lng: 18.966913 },
    { lat: 49.453005, lng: 18.965511 },
    { lat: 49.453571, lng: 18.963876 },
    { lat: 49.454134, lng: 18.96332 },
    { lat: 49.45442, lng: 18.961549 },
    { lat: 49.454692, lng: 18.960819 },
    { lat: 49.454705, lng: 18.960783 },
    { lat: 49.45548, lng: 18.961093 },
    { lat: 49.455922, lng: 18.961582 },
    { lat: 49.455947, lng: 18.961609 },
    { lat: 49.455949, lng: 18.961612 },
    { lat: 49.456343, lng: 18.962049 },
    { lat: 49.456897, lng: 18.962646 },
    { lat: 49.4571128, lng: 18.9627938 },
    { lat: 49.457266, lng: 18.962881 },
    { lat: 49.45743, lng: 18.963008 },
    { lat: 49.458156, lng: 18.962759 },
    { lat: 49.458607, lng: 18.962914 },
    { lat: 49.459153, lng: 18.963168 },
    { lat: 49.459293, lng: 18.963254 },
    { lat: 49.459906, lng: 18.963214 },
    { lat: 49.460732, lng: 18.963181 },
    { lat: 49.461523, lng: 18.963396 },
    { lat: 49.462212, lng: 18.963673 },
    { lat: 49.462237, lng: 18.963683 },
    { lat: 49.462401, lng: 18.963749 },
    { lat: 49.463009, lng: 18.964003 },
    { lat: 49.46371, lng: 18.964137 },
    { lat: 49.464569, lng: 18.964521 },
    { lat: 49.465371, lng: 18.965103 },
    { lat: 49.466032, lng: 18.965258 },
    { lat: 49.467117, lng: 18.965545 },
    { lat: 49.46746, lng: 18.965788 },
    { lat: 49.467505, lng: 18.965819 },
    { lat: 49.467957, lng: 18.966205 },
    { lat: 49.468854, lng: 18.965897 },
    { lat: 49.469708, lng: 18.965533 },
    { lat: 49.470695, lng: 18.964754 },
    { lat: 49.471846, lng: 18.964474 },
    { lat: 49.472438, lng: 18.964587 },
    { lat: 49.472965, lng: 18.964953 },
    { lat: 49.47303, lng: 18.965 },
    { lat: 49.473096, lng: 18.965057 },
    { lat: 49.473123, lng: 18.965081 },
    { lat: 49.473583, lng: 18.965482 },
    { lat: 49.474567, lng: 18.96589 },
    { lat: 49.475084, lng: 18.96682 },
    { lat: 49.475096, lng: 18.966841 },
    { lat: 49.475821, lng: 18.968077 },
    { lat: 49.476335, lng: 18.96911 },
    { lat: 49.476808, lng: 18.970198 },
    { lat: 49.477502, lng: 18.969481 },
    { lat: 49.478129, lng: 18.968339 },
    { lat: 49.47892, lng: 18.967093 },
    { lat: 49.47961, lng: 18.965701 },
    { lat: 49.479728, lng: 18.965459 },
    { lat: 49.479737, lng: 18.965391 },
    { lat: 49.480601, lng: 18.964184 },
    { lat: 49.4813166, lng: 18.9633801 },
    { lat: 49.481396, lng: 18.963322 },
    { lat: 49.4813357, lng: 18.9632887 },
    { lat: 49.4808509, lng: 18.9622491 },
    { lat: 49.4805224, lng: 18.9618108 },
    { lat: 49.4803014, lng: 18.9612705 },
    { lat: 49.4792284, lng: 18.9597675 },
    { lat: 49.4790871, lng: 18.95931 },
    { lat: 49.4787122, lng: 18.9587114 },
    { lat: 49.4779943, lng: 18.957813 },
    { lat: 49.4770026, lng: 18.9558365 },
    { lat: 49.4766528, lng: 18.954933 },
    { lat: 49.476306, lng: 18.9537922 },
    { lat: 49.4761036, lng: 18.9531045 },
    { lat: 49.4759772, lng: 18.952675 },
    { lat: 49.4758016, lng: 18.9520997 },
    { lat: 49.4757035, lng: 18.9519384 },
    { lat: 49.4752908, lng: 18.9514659 },
    { lat: 49.4752819, lng: 18.9514451 },
    { lat: 49.4752402, lng: 18.9509277 },
    { lat: 49.4751378, lng: 18.9502344 },
    { lat: 49.4750954, lng: 18.950061 },
    { lat: 49.4749311, lng: 18.9491278 },
    { lat: 49.4748825, lng: 18.948315 },
    { lat: 49.4747444, lng: 18.9481453 },
    { lat: 49.4745571, lng: 18.9478738 },
    { lat: 49.4743269, lng: 18.947532 },
    { lat: 49.4740509, lng: 18.94713 },
    { lat: 49.4739493, lng: 18.9468575 },
    { lat: 49.4738581, lng: 18.9465692 },
    { lat: 49.47381, lng: 18.9462838 },
    { lat: 49.4738237, lng: 18.9456579 },
    { lat: 49.4738087, lng: 18.9452932 },
    { lat: 49.473744, lng: 18.9450594 },
    { lat: 49.4736452, lng: 18.9446387 },
    { lat: 49.473639, lng: 18.9446046 },
    { lat: 49.4736594, lng: 18.9438685 },
    { lat: 49.473765, lng: 18.9434776 },
    { lat: 49.4738051, lng: 18.9431564 },
    { lat: 49.4738564, lng: 18.9427631 },
    { lat: 49.4737381, lng: 18.9419546 },
    { lat: 49.4736379, lng: 18.941422 },
    { lat: 49.4734768, lng: 18.9406149 },
    { lat: 49.4734089, lng: 18.9400713 },
    { lat: 49.4731572, lng: 18.9401108 },
    { lat: 49.4729772, lng: 18.9401169 },
    { lat: 49.472862, lng: 18.9400889 },
    { lat: 49.4724908, lng: 18.9400168 },
    { lat: 49.4721264, lng: 18.9400563 },
    { lat: 49.4720077, lng: 18.9398717 },
    { lat: 49.4720356, lng: 18.9391003 },
    { lat: 49.4718732, lng: 18.938604 },
    { lat: 49.4718404, lng: 18.938493 },
    { lat: 49.4717445, lng: 18.9380882 },
    { lat: 49.4716948, lng: 18.9380158 },
    { lat: 49.4715706, lng: 18.9375658 },
    { lat: 49.4714561, lng: 18.9372622 },
    { lat: 49.4714775, lng: 18.9368566 },
    { lat: 49.4712829, lng: 18.9360354 },
    { lat: 49.4712817, lng: 18.935565 },
    { lat: 49.4712904, lng: 18.9354708 },
    { lat: 49.4711578, lng: 18.9351629 },
    { lat: 49.4706805, lng: 18.9329122 },
    { lat: 49.4704628, lng: 18.9310966 },
    { lat: 49.4706047, lng: 18.9286327 },
    { lat: 49.4706891, lng: 18.9277563 },
    { lat: 49.4706816, lng: 18.9272943 },
    { lat: 49.4707184, lng: 18.9270576 },
    { lat: 49.4707783, lng: 18.9269302 },
    { lat: 49.4708356, lng: 18.9263419 },
    { lat: 49.4710147, lng: 18.9258919 },
    { lat: 49.4710731, lng: 18.9255319 },
    { lat: 49.4711626, lng: 18.923885 },
    { lat: 49.4712229, lng: 18.9237847 },
    { lat: 49.4715222, lng: 18.9233251 },
    { lat: 49.4717833, lng: 18.9231154 },
    { lat: 49.4718832, lng: 18.9229734 },
    { lat: 49.4719403, lng: 18.9228089 },
    { lat: 49.4720298, lng: 18.9226392 },
    { lat: 49.4723345, lng: 18.9220393 },
    { lat: 49.4727816, lng: 18.9212685 },
    { lat: 49.4729604, lng: 18.9210392 },
    { lat: 49.4730151, lng: 18.9209613 },
    { lat: 49.473386, lng: 18.9205119 },
    { lat: 49.4733726, lng: 18.9204287 },
    { lat: 49.4733582, lng: 18.9202506 },
    { lat: 49.4734427, lng: 18.919782 },
    { lat: 49.472397, lng: 18.9184065 },
    { lat: 49.4721994, lng: 18.9163199 },
    { lat: 49.4721145, lng: 18.9163608 },
    { lat: 49.4719533, lng: 18.9157358 },
    { lat: 49.471689, lng: 18.9152771 },
    { lat: 49.4718539, lng: 18.915147 },
    { lat: 49.4718333, lng: 18.9148087 },
    { lat: 49.4719351, lng: 18.9142572 },
    { lat: 49.4718634, lng: 18.9137296 },
    { lat: 49.4719878, lng: 18.9135607 },
    { lat: 49.4726821, lng: 18.9109218 },
    { lat: 49.4732093, lng: 18.909864 },
    { lat: 49.4729481, lng: 18.9093129 },
    { lat: 49.4728112, lng: 18.909124 },
    { lat: 49.471928, lng: 18.9085596 },
    { lat: 49.4711296, lng: 18.9082188 },
    { lat: 49.4708667, lng: 18.9077883 },
    { lat: 49.47086, lng: 18.9068299 },
    { lat: 49.4708113, lng: 18.9054217 },
    { lat: 49.4708912, lng: 18.904573 },
    { lat: 49.4710851, lng: 18.9039917 },
    { lat: 49.471307, lng: 18.9035643 },
    { lat: 49.471544, lng: 18.9029662 },
    { lat: 49.4717904, lng: 18.9024914 },
    { lat: 49.4722026, lng: 18.9014121 },
    { lat: 49.4724565, lng: 18.9007478 },
    { lat: 49.4727639, lng: 18.9002497 },
    { lat: 49.4726887, lng: 18.8990341 },
    { lat: 49.4726695, lng: 18.897698 },
    { lat: 49.4726921, lng: 18.8972708 },
    { lat: 49.4728852, lng: 18.8966993 },
    { lat: 49.4729045, lng: 18.8965245 },
    { lat: 49.4731416, lng: 18.8958846 },
    { lat: 49.4734216, lng: 18.8950918 },
    { lat: 49.4734516, lng: 18.8946162 },
    { lat: 49.4735547, lng: 18.8942265 },
    { lat: 49.4739799, lng: 18.8925858 },
    { lat: 49.4741506, lng: 18.8918785 },
    { lat: 49.474158, lng: 18.8909814 },
    { lat: 49.4741627, lng: 18.8904726 },
    { lat: 49.47412, lng: 18.889653 },
    { lat: 49.4735371, lng: 18.8894967 },
    { lat: 49.4731147, lng: 18.8892354 },
    { lat: 49.472579, lng: 18.888831 },
    { lat: 49.4722865, lng: 18.8880854 },
    { lat: 49.4718828, lng: 18.8871316 },
    { lat: 49.4718095, lng: 18.8869055 },
    { lat: 49.4717301, lng: 18.8867196 },
    { lat: 49.47151, lng: 18.886358 },
    { lat: 49.4713554, lng: 18.8861321 },
    { lat: 49.4712673, lng: 18.8859081 },
    { lat: 49.471115, lng: 18.8854808 },
    { lat: 49.4706594, lng: 18.8847464 },
    { lat: 49.47031, lng: 18.8844811 },
    { lat: 49.4700606, lng: 18.8840773 },
    { lat: 49.4699762, lng: 18.8837695 },
    { lat: 49.469976, lng: 18.8833414 },
    { lat: 49.4695309, lng: 18.8830158 },
    { lat: 49.4695843, lng: 18.882801 },
    { lat: 49.4694013, lng: 18.8824593 },
    { lat: 49.4692394, lng: 18.8824899 },
    { lat: 49.4688731, lng: 18.88258 },
    { lat: 49.4687035, lng: 18.8823937 },
    { lat: 49.4684603, lng: 18.8824891 },
    { lat: 49.4682629, lng: 18.8823521 },
    { lat: 49.4680044, lng: 18.8822112 },
    { lat: 49.4671249, lng: 18.8814768 },
    { lat: 49.4668963, lng: 18.8823236 },
    { lat: 49.4668161, lng: 18.882368 },
    { lat: 49.4664652, lng: 18.8822516 },
    { lat: 49.465876, lng: 18.8813937 },
    { lat: 49.466005, lng: 18.8812456 },
    { lat: 49.465932, lng: 18.8806961 },
    { lat: 49.4657751, lng: 18.8803785 },
    { lat: 49.4655556, lng: 18.879611 },
    { lat: 49.4655538, lng: 18.8793984 },
    { lat: 49.4657513, lng: 18.8792026 },
    { lat: 49.4659423, lng: 18.8784103 },
    { lat: 49.465947, lng: 18.8776612 },
    { lat: 49.4657164, lng: 18.8773387 },
    { lat: 49.4652705, lng: 18.877927 },
    { lat: 49.4652355, lng: 18.8776579 },
    { lat: 49.4651068, lng: 18.8777008 },
    { lat: 49.4650849, lng: 18.8779332 },
    { lat: 49.4645553, lng: 18.8780284 },
    { lat: 49.464085, lng: 18.8776015 },
    { lat: 49.4638725, lng: 18.8775465 },
    { lat: 49.4632917, lng: 18.8769864 },
    { lat: 49.4633936, lng: 18.8765508 },
    { lat: 49.4634811, lng: 18.8765204 },
    { lat: 49.4635259, lng: 18.8763378 },
    { lat: 49.4624451, lng: 18.8758593 },
    { lat: 49.4622671, lng: 18.8755732 },
    { lat: 49.4620776, lng: 18.8753723 },
    { lat: 49.4621566, lng: 18.8750163 },
    { lat: 49.4624619, lng: 18.8743492 },
    { lat: 49.4623145, lng: 18.8738427 },
    { lat: 49.4623588, lng: 18.8737153 },
    { lat: 49.4623746, lng: 18.8736721 },
    { lat: 49.462412, lng: 18.8735661 },
    { lat: 49.4625464, lng: 18.8735471 },
    { lat: 49.4626506, lng: 18.8732461 },
    { lat: 49.4630368, lng: 18.8735461 },
    { lat: 49.463256, lng: 18.8732672 },
    { lat: 49.4633335, lng: 18.8728864 },
    { lat: 49.4635783, lng: 18.8726005 },
    { lat: 49.4638188, lng: 18.8724967 },
    { lat: 49.4639955, lng: 18.8722697 },
    { lat: 49.4641901, lng: 18.8723515 },
    { lat: 49.4645487, lng: 18.8721291 },
    { lat: 49.4646918, lng: 18.8719286 },
    { lat: 49.4646565, lng: 18.8715454 },
    { lat: 49.4644767, lng: 18.871336 },
    { lat: 49.4643554, lng: 18.8711436 },
    { lat: 49.4643246, lng: 18.871094 },
    { lat: 49.4642497, lng: 18.8709746 },
    { lat: 49.4641603, lng: 18.870703 },
    { lat: 49.464011, lng: 18.8705124 },
    { lat: 49.4636846, lng: 18.870234 },
    { lat: 49.4634733, lng: 18.8701346 },
    { lat: 49.4633521, lng: 18.8699498 },
    { lat: 49.4630295, lng: 18.8688362 },
    { lat: 49.4626517, lng: 18.8686371 },
    { lat: 49.4625302, lng: 18.8688387 },
    { lat: 49.4623829, lng: 18.8697567 },
    { lat: 49.4625672, lng: 18.8703192 },
    { lat: 49.4623675, lng: 18.8705403 },
    { lat: 49.4621628, lng: 18.8705927 },
    { lat: 49.462047, lng: 18.8705087 },
    { lat: 49.461478, lng: 18.8711383 },
    { lat: 49.4610869, lng: 18.8716697 },
    { lat: 49.4609516, lng: 18.8717359 },
    { lat: 49.4603051, lng: 18.8715933 },
    { lat: 49.4600261, lng: 18.8711859 },
    { lat: 49.4600039, lng: 18.8708602 },
    { lat: 49.4598906, lng: 18.8704954 },
    { lat: 49.4594973, lng: 18.8707194 },
    { lat: 49.4593692, lng: 18.8706807 },
    { lat: 49.4589384, lng: 18.8695957 },
    { lat: 49.4587664, lng: 18.8697573 },
    { lat: 49.4586838, lng: 18.8692442 },
    { lat: 49.4583633, lng: 18.8692362 },
    { lat: 49.4578869, lng: 18.8695132 },
    { lat: 49.457537, lng: 18.8686386 },
    { lat: 49.4563882, lng: 18.8677767 },
    { lat: 49.4557459, lng: 18.8681116 },
    { lat: 49.4552906, lng: 18.8672323 },
    { lat: 49.4551009, lng: 18.8669118 },
    { lat: 49.454548, lng: 18.8664413 },
    { lat: 49.4542846, lng: 18.8660467 },
    { lat: 49.4541747, lng: 18.865837 },
    { lat: 49.4542618, lng: 18.8637447 },
    { lat: 49.4541776, lng: 18.8628419 },
    { lat: 49.4541961, lng: 18.862495 },
    { lat: 49.4542081, lng: 18.8622675 },
    { lat: 49.4538764, lng: 18.8615114 },
    { lat: 49.4534157, lng: 18.8608104 },
    { lat: 49.4532887, lng: 18.8606183 },
    { lat: 49.4530591, lng: 18.8600059 },
    { lat: 49.4528015, lng: 18.8591573 },
    { lat: 49.4528192, lng: 18.8590171 },
    { lat: 49.4526612, lng: 18.8581515 },
    { lat: 49.4522153, lng: 18.856899 },
    { lat: 49.4519415, lng: 18.8564817 },
    { lat: 49.4517366, lng: 18.856091 },
    { lat: 49.4516414, lng: 18.8555078 },
    { lat: 49.4510482, lng: 18.854131 },
    { lat: 49.450761, lng: 18.8538919 },
    { lat: 49.4506354, lng: 18.8535558 },
    { lat: 49.4504848, lng: 18.8535159 },
    { lat: 49.4500825, lng: 18.8535682 },
    { lat: 49.4499203, lng: 18.852997 },
    { lat: 49.4498473, lng: 18.8528025 },
    { lat: 49.4498069, lng: 18.8525425 },
    { lat: 49.4497403, lng: 18.8522743 },
    { lat: 49.4497206, lng: 18.8520872 },
    { lat: 49.4496478, lng: 18.8518093 },
    { lat: 49.449493, lng: 18.851446 },
    { lat: 49.4492589, lng: 18.8510356 },
    { lat: 49.4490775, lng: 18.8507947 },
    { lat: 49.4487565, lng: 18.850227 },
    { lat: 49.44859, lng: 18.8500144 },
    { lat: 49.4481714, lng: 18.8492102 },
    { lat: 49.4479455, lng: 18.8492932 },
    { lat: 49.4478137, lng: 18.8492942 },
    { lat: 49.4475818, lng: 18.8492126 },
    { lat: 49.4475205, lng: 18.8493584 },
    { lat: 49.4471604, lng: 18.8488204 },
    { lat: 49.4471418, lng: 18.8489002 },
    { lat: 49.4458844, lng: 18.848473 },
    { lat: 49.4451562, lng: 18.848651 },
    { lat: 49.4445584, lng: 18.8485565 },
    { lat: 49.4441029, lng: 18.8485982 },
    { lat: 49.4437859, lng: 18.8485529 },
    { lat: 49.4431055, lng: 18.8485626 },
    { lat: 49.4418771, lng: 18.8485721 },
    { lat: 49.4414831, lng: 18.8483604 },
    { lat: 49.4411233, lng: 18.8479399 },
    { lat: 49.4408019, lng: 18.8475633 },
    { lat: 49.4401279, lng: 18.8466385 },
    { lat: 49.4384099, lng: 18.8449473 },
    { lat: 49.4380256, lng: 18.8446617 },
    { lat: 49.436861, lng: 18.8441093 },
    { lat: 49.4364858, lng: 18.8440012 },
    { lat: 49.4363633, lng: 18.8440138 },
    { lat: 49.4363309, lng: 18.8440173 },
    { lat: 49.4347429, lng: 18.8441806 },
    { lat: 49.4338051, lng: 18.844164 },
    { lat: 49.4333453, lng: 18.8442146 },
    { lat: 49.4327946, lng: 18.8445561 },
    { lat: 49.4310289, lng: 18.8445509 },
    { lat: 49.4306185, lng: 18.8444633 },
    { lat: 49.430565, lng: 18.844452 },
    { lat: 49.4300498, lng: 18.8443419 },
    { lat: 49.4288818, lng: 18.8438581 },
    { lat: 49.4282711, lng: 18.8416174 },
    { lat: 49.4281566, lng: 18.8412298 },
    { lat: 49.4275302, lng: 18.8404199 },
    { lat: 49.4271906, lng: 18.8400674 },
    { lat: 49.4264928, lng: 18.8394045 },
    { lat: 49.426369, lng: 18.8390576 },
    { lat: 49.4261604, lng: 18.8385969 },
    { lat: 49.4258544, lng: 18.8381883 },
    { lat: 49.4256652, lng: 18.837926 },
    { lat: 49.4255409, lng: 18.8375469 },
    { lat: 49.4254219, lng: 18.8370505 },
    { lat: 49.4251136, lng: 18.8365805 },
    { lat: 49.424977, lng: 18.8362243 },
    { lat: 49.4249579, lng: 18.8358039 },
    { lat: 49.4247614, lng: 18.8357551 },
    { lat: 49.4246328, lng: 18.8360397 },
    { lat: 49.4244174, lng: 18.836368 },
    { lat: 49.4241807, lng: 18.8366363 },
    { lat: 49.4238842, lng: 18.8368125 },
    { lat: 49.4235904, lng: 18.8372143 },
    { lat: 49.4234044, lng: 18.8368869 },
    { lat: 49.423346, lng: 18.8366914 },
    { lat: 49.4232967, lng: 18.8365224 },
    { lat: 49.4223216, lng: 18.836747 },
    { lat: 49.4216042, lng: 18.8367804 },
    { lat: 49.421027, lng: 18.8363557 },
    { lat: 49.4207501, lng: 18.836152 },
    { lat: 49.4202965, lng: 18.8354181 },
    { lat: 49.4200651, lng: 18.8346645 },
    { lat: 49.4199997, lng: 18.8343726 },
    { lat: 49.4198355, lng: 18.8341438 },
    { lat: 49.419381, lng: 18.8333051 },
    { lat: 49.4189677, lng: 18.8329626 },
    { lat: 49.4184693, lng: 18.8326746 },
    { lat: 49.4173953, lng: 18.8325323 },
    { lat: 49.4172763, lng: 18.8325198 },
    { lat: 49.4168172, lng: 18.8326012 },
    { lat: 49.4161296, lng: 18.8329364 },
    { lat: 49.4144683, lng: 18.8340485 },
    { lat: 49.4143202, lng: 18.8341264 },
    { lat: 49.4138287, lng: 18.8345683 },
    { lat: 49.4132761, lng: 18.8351805 },
    { lat: 49.412907, lng: 18.8356775 },
    { lat: 49.4125899, lng: 18.8361343 },
    { lat: 49.4120736, lng: 18.8370408 },
    { lat: 49.4122305, lng: 18.837262 },
    { lat: 49.4122791, lng: 18.8373307 },
    { lat: 49.412205, lng: 18.838154 },
    { lat: 49.4120593, lng: 18.8385202 },
    { lat: 49.4119967, lng: 18.8388612 },
    { lat: 49.4119116, lng: 18.8392248 },
    { lat: 49.4117629, lng: 18.8399745 },
    { lat: 49.4116811, lng: 18.8402277 },
    { lat: 49.4116635, lng: 18.8402808 },
    { lat: 49.4116417, lng: 18.8403631 },
    { lat: 49.4113782, lng: 18.8412518 },
    { lat: 49.4110924, lng: 18.8419651 },
    { lat: 49.4109756, lng: 18.8421942 },
    { lat: 49.4107552, lng: 18.8426049 },
    { lat: 49.410528, lng: 18.8427679 },
    { lat: 49.4104791, lng: 18.8428057 },
    { lat: 49.4104805, lng: 18.8429214 },
    { lat: 49.410442, lng: 18.8430926 },
    { lat: 49.4103933, lng: 18.8431781 },
    { lat: 49.4103549, lng: 18.8433514 },
    { lat: 49.4103834, lng: 18.8437108 },
    { lat: 49.4104023, lng: 18.8441424 },
    { lat: 49.4104097, lng: 18.8443123 },
    { lat: 49.4104203, lng: 18.844718 },
    { lat: 49.4103558, lng: 18.8450882 },
    { lat: 49.4103214, lng: 18.8469986 },
    { lat: 49.4102739, lng: 18.8475652 },
    { lat: 49.4102318, lng: 18.8480638 },
    { lat: 49.4101978, lng: 18.8489013 },
    { lat: 49.4101672, lng: 18.8497128 },
    { lat: 49.410101, lng: 18.8512009 },
    { lat: 49.4101273, lng: 18.8515804 },
    { lat: 49.4100659, lng: 18.8519701 },
    { lat: 49.4099823, lng: 18.8526924 },
    { lat: 49.4098045, lng: 18.8532546 },
    { lat: 49.4097545, lng: 18.8537979 },
    { lat: 49.4097572, lng: 18.8544649 },
    { lat: 49.4092055, lng: 18.8550097 },
    { lat: 49.4089207, lng: 18.8553972 },
    { lat: 49.4088034, lng: 18.8556169 },
    { lat: 49.4087844, lng: 18.8557293 },
    { lat: 49.4086607, lng: 18.856112 },
    { lat: 49.4092462, lng: 18.8564049 },
    { lat: 49.4098622, lng: 18.8567913 },
    { lat: 49.4103578, lng: 18.8572659 },
    { lat: 49.4107264, lng: 18.8578423 },
    { lat: 49.4108831, lng: 18.8580835 },
    { lat: 49.4111246, lng: 18.8583724 },
    { lat: 49.411929, lng: 18.8594228 },
    { lat: 49.4122411, lng: 18.8601044 },
    { lat: 49.412403, lng: 18.8603827 },
    { lat: 49.4124869, lng: 18.8605464 },
    { lat: 49.4125087, lng: 18.8606438 },
    { lat: 49.412654, lng: 18.8609725 },
    { lat: 49.4127073, lng: 18.8611569 },
    { lat: 49.4133214, lng: 18.8618995 },
    { lat: 49.4133303, lng: 18.8624271 },
    { lat: 49.4136061, lng: 18.8627768 },
    { lat: 49.4137437, lng: 18.8629736 },
    { lat: 49.4140795, lng: 18.8635906 },
    { lat: 49.4140182, lng: 18.8636729 },
    { lat: 49.414235, lng: 18.8641861 },
    { lat: 49.4142433, lng: 18.8650813 },
    { lat: 49.4142662, lng: 18.8655717 },
    { lat: 49.4143402, lng: 18.8661849 },
    { lat: 49.4143931, lng: 18.8661851 },
    { lat: 49.41452, lng: 18.8673453 },
    { lat: 49.4144693, lng: 18.8675454 },
    { lat: 49.4146926, lng: 18.8684967 },
    { lat: 49.4148566, lng: 18.8692404 },
    { lat: 49.4150271, lng: 18.8699956 },
    { lat: 49.4155085, lng: 18.8720585 },
    { lat: 49.4156951, lng: 18.8726206 },
    { lat: 49.4158619, lng: 18.8733966 },
    { lat: 49.4159268, lng: 18.8734316 },
    { lat: 49.4159602, lng: 18.8735536 },
    { lat: 49.4160889, lng: 18.87398 },
    { lat: 49.416134, lng: 18.8740036 },
    { lat: 49.416177, lng: 18.8748483 },
    { lat: 49.4162938, lng: 18.8748828 },
    { lat: 49.4163992, lng: 18.8761376 },
    { lat: 49.4164451, lng: 18.8761531 },
    { lat: 49.4164073, lng: 18.8773591 },
    { lat: 49.4163299, lng: 18.8784963 },
    { lat: 49.4163083, lng: 18.8792268 },
    { lat: 49.416383, lng: 18.8798743 },
    { lat: 49.4163553, lng: 18.879882 },
    { lat: 49.4165078, lng: 18.8810649 },
    { lat: 49.4165154, lng: 18.8812573 },
    { lat: 49.4166219, lng: 18.8817117 },
    { lat: 49.4168139, lng: 18.881586 },
    { lat: 49.4169784, lng: 18.8818042 },
    { lat: 49.4171913, lng: 18.8820019 },
    { lat: 49.4172811, lng: 18.8825274 },
    { lat: 49.4173666, lng: 18.883169 },
    { lat: 49.4174548, lng: 18.8832161 },
    { lat: 49.4175791, lng: 18.8836224 },
    { lat: 49.4177355, lng: 18.8837518 },
    { lat: 49.4178538, lng: 18.8839282 },
    { lat: 49.4179095, lng: 18.8840888 },
    { lat: 49.4180702, lng: 18.8841461 },
    { lat: 49.4182705, lng: 18.8846487 },
    { lat: 49.4183789, lng: 18.8846987 },
    { lat: 49.4184295, lng: 18.8849408 },
    { lat: 49.418479, lng: 18.8855157 },
    { lat: 49.4186921, lng: 18.8856474 },
    { lat: 49.4187638, lng: 18.8858634 },
    { lat: 49.4188764, lng: 18.8862677 },
    { lat: 49.4189306, lng: 18.8865245 },
    { lat: 49.4189513, lng: 18.8867782 },
    { lat: 49.4189007, lng: 18.8870236 },
    { lat: 49.4187494, lng: 18.8874878 },
    { lat: 49.418629, lng: 18.8880478 },
    { lat: 49.4187082, lng: 18.8883419 },
    { lat: 49.4187075, lng: 18.888552 },
    { lat: 49.4186777, lng: 18.8888525 },
    { lat: 49.4187803, lng: 18.8889854 },
    { lat: 49.4188845, lng: 18.8895068 },
    { lat: 49.4189286, lng: 18.8896876 },
    { lat: 49.418904, lng: 18.8902453 },
    { lat: 49.4188502, lng: 18.8904748 },
    { lat: 49.4187539, lng: 18.8907086 },
    { lat: 49.4187811, lng: 18.890797 },
    { lat: 49.4185122, lng: 18.8913646 },
    { lat: 49.4186193, lng: 18.8915663 },
    { lat: 49.4184441, lng: 18.8918906 },
    { lat: 49.4184268, lng: 18.8920371 },
    { lat: 49.4185598, lng: 18.8923914 },
    { lat: 49.4184047, lng: 18.89258 },
    { lat: 49.4182039, lng: 18.8924226 },
    { lat: 49.4181732, lng: 18.8923281 },
    { lat: 49.4175794, lng: 18.8926893 },
    { lat: 49.4171781, lng: 18.893114 },
    { lat: 49.4170882, lng: 18.8932978 },
    { lat: 49.4172666, lng: 18.8941467 },
    { lat: 49.4173493, lng: 18.8947992 },
    { lat: 49.416577, lng: 18.8953999 },
    { lat: 49.4161012, lng: 18.8961073 },
    { lat: 49.415887, lng: 18.8964207 },
    { lat: 49.4152784, lng: 18.8992276 },
    { lat: 49.4151781, lng: 18.9004504 },
    { lat: 49.4152924, lng: 18.9007953 },
    { lat: 49.4152421, lng: 18.900823 },
    { lat: 49.4152566, lng: 18.9008908 },
    { lat: 49.4145249, lng: 18.9011129 },
    { lat: 49.4141303, lng: 18.9012652 },
    { lat: 49.4141237, lng: 18.9013584 },
    { lat: 49.4136987, lng: 18.9015346 },
    { lat: 49.4134241, lng: 18.9018134 },
    { lat: 49.4131788, lng: 18.9019335 },
    { lat: 49.4127842, lng: 18.9021561 },
    { lat: 49.4128274, lng: 18.9035644 },
    { lat: 49.4128902, lng: 18.903918 },
    { lat: 49.4129187, lng: 18.9044982 },
    { lat: 49.4128131, lng: 18.9049978 },
    { lat: 49.4127023, lng: 18.9056138 },
    { lat: 49.4124877, lng: 18.9063218 },
    { lat: 49.412266, lng: 18.9074402 },
    { lat: 49.4120631, lng: 18.9078423 },
    { lat: 49.4118034, lng: 18.908481 },
    { lat: 49.4113639, lng: 18.9096408 },
    { lat: 49.4112046, lng: 18.9100131 },
    { lat: 49.411157, lng: 18.9107027 },
    { lat: 49.4111411, lng: 18.9111032 },
    { lat: 49.4110832, lng: 18.9117379 },
    { lat: 49.4108509, lng: 18.9123611 },
    { lat: 49.4108067, lng: 18.9125571 },
    { lat: 49.4102196, lng: 18.9131873 },
    { lat: 49.4098246, lng: 18.9134898 },
    { lat: 49.4092053, lng: 18.9138156 },
    { lat: 49.4083434, lng: 18.9142286 },
    { lat: 49.4081091, lng: 18.9144117 },
    { lat: 49.4078219, lng: 18.9143296 },
    { lat: 49.407554, lng: 18.9142445 },
    { lat: 49.4070304, lng: 18.9141673 },
    { lat: 49.4063929, lng: 18.9140239 },
    { lat: 49.4060567, lng: 18.9141087 },
    { lat: 49.4057419, lng: 18.9142127 },
    { lat: 49.4056207, lng: 18.9142039 },
    { lat: 49.4054656, lng: 18.9141687 },
    { lat: 49.4050256, lng: 18.9140295 },
    { lat: 49.4049852, lng: 18.9140299 },
    { lat: 49.4049032, lng: 18.9141037 },
    { lat: 49.4045127, lng: 18.9145859 },
    { lat: 49.4042459, lng: 18.91477 },
    { lat: 49.4041013, lng: 18.9149671 },
    { lat: 49.4040767, lng: 18.9150167 },
    { lat: 49.4036158, lng: 18.9156658 },
    { lat: 49.4035075, lng: 18.9159784 },
    { lat: 49.4034979, lng: 18.9160302 },
    { lat: 49.4035246, lng: 18.9168307 },
    { lat: 49.4036726, lng: 18.9178854 },
    { lat: 49.4038129, lng: 18.9190304 },
    { lat: 49.403972, lng: 18.9200374 },
    { lat: 49.4041995, lng: 18.9215301 },
    { lat: 49.4045056, lng: 18.9229046 },
    { lat: 49.4048436, lng: 18.9241735 },
    { lat: 49.4049931, lng: 18.9250797 },
    { lat: 49.4050878, lng: 18.9259094 },
    { lat: 49.4051071, lng: 18.9263571 },
    { lat: 49.4051418, lng: 18.9272927 },
    { lat: 49.4052748, lng: 18.9280292 },
    { lat: 49.4055516, lng: 18.9288656 },
    { lat: 49.4058398, lng: 18.9295555 },
    { lat: 49.4060756, lng: 18.9299168 },
    { lat: 49.4063044, lng: 18.9302524 },
    { lat: 49.4065587, lng: 18.9305304 },
    { lat: 49.4065854, lng: 18.9305671 },
    { lat: 49.4071953, lng: 18.9315976 },
    { lat: 49.4073002, lng: 18.9317906 },
    { lat: 49.407419, lng: 18.9319835 },
    { lat: 49.4079225, lng: 18.9328521 },
    { lat: 49.4088259, lng: 18.934114 },
    { lat: 49.4096944, lng: 18.9352668 },
    { lat: 49.4103086, lng: 18.9364475 },
    { lat: 49.4106911, lng: 18.9373498 },
    { lat: 49.4110059, lng: 18.9383187 },
    { lat: 49.4110169, lng: 18.9389839 },
    { lat: 49.4111676, lng: 18.9397162 },
    { lat: 49.4113706, lng: 18.940168 },
    { lat: 49.4111993, lng: 18.940702 },
    { lat: 49.4113842, lng: 18.9430363 },
    { lat: 49.4115074, lng: 18.9439157 },
    { lat: 49.4115733, lng: 18.9447992 },
    { lat: 49.4116993, lng: 18.9454911 },
    { lat: 49.4118429, lng: 18.9468216 },
    { lat: 49.4117884, lng: 18.9481394 },
    { lat: 49.4117785, lng: 18.948637 },
    { lat: 49.4118744, lng: 18.9492027 },
    { lat: 49.4118185, lng: 18.9501289 },
    { lat: 49.4117046, lng: 18.9506891 },
    { lat: 49.4118107, lng: 18.952397 },
    { lat: 49.4118715, lng: 18.9532917 },
    { lat: 49.4119162, lng: 18.9532893 },
    { lat: 49.4119902, lng: 18.9541318 },
    { lat: 49.4124836, lng: 18.9553831 },
    { lat: 49.4130725, lng: 18.9549415 },
    { lat: 49.4145466, lng: 18.9537238 },
    { lat: 49.415768, lng: 18.9520685 },
    { lat: 49.4165974, lng: 18.950762 },
    { lat: 49.4172361, lng: 18.9500784 },
    { lat: 49.4174875, lng: 18.9502395 },
    { lat: 49.4179643, lng: 18.9504403 },
    { lat: 49.4186699, lng: 18.9508394 },
    { lat: 49.4191937, lng: 18.9516647 },
    { lat: 49.4195208, lng: 18.9523167 },
    { lat: 49.4197488, lng: 18.9532689 },
    { lat: 49.4198082, lng: 18.9538021 },
    { lat: 49.4196747, lng: 18.9547814 },
    { lat: 49.4196422, lng: 18.9552531 },
    { lat: 49.419656, lng: 18.955463 },
    { lat: 49.4197529, lng: 18.9556424 },
    { lat: 49.4199676, lng: 18.95583 },
    { lat: 49.4202323, lng: 18.9561623 },
    { lat: 49.4206725, lng: 18.9565793 },
    { lat: 49.4208675, lng: 18.9568931 },
    { lat: 49.421174, lng: 18.9571829 },
    { lat: 49.4214615, lng: 18.9574742 },
    { lat: 49.4216009, lng: 18.9576728 },
    { lat: 49.4217075, lng: 18.9579746 },
    { lat: 49.4217688, lng: 18.9582313 },
    { lat: 49.4218116, lng: 18.9584006 },
    { lat: 49.4218052, lng: 18.9584154 },
    { lat: 49.421771, lng: 18.9592888 },
    { lat: 49.4216866, lng: 18.9603243 },
    { lat: 49.4217093, lng: 18.9604175 },
    { lat: 49.4217461, lng: 18.9610827 },
    { lat: 49.4219031, lng: 18.9621444 },
    { lat: 49.4219282, lng: 18.9628067 },
    { lat: 49.4219922, lng: 18.9637008 },
    { lat: 49.4221002, lng: 18.9643714 },
    { lat: 49.4222126, lng: 18.9649046 },
    { lat: 49.4223248, lng: 18.9655019 },
    { lat: 49.422327, lng: 18.9660854 },
    { lat: 49.4223416, lng: 18.9661496 },
    { lat: 49.4222379, lng: 18.9663761 },
    { lat: 49.4221168, lng: 18.9665005 },
    { lat: 49.4218989, lng: 18.96663 },
    { lat: 49.4215909, lng: 18.9669426 },
    { lat: 49.4213531, lng: 18.9671889 },
    { lat: 49.4211778, lng: 18.9672458 },
    { lat: 49.420965, lng: 18.9672969 },
    { lat: 49.4207331, lng: 18.9673694 },
    { lat: 49.4207553, lng: 18.968464 },
    { lat: 49.4209924, lng: 18.9694634 },
    { lat: 49.4211456, lng: 18.9704763 },
    { lat: 49.4214956, lng: 18.9713183 },
    { lat: 49.4215688, lng: 18.9719046 },
    { lat: 49.4214921, lng: 18.972443 },
    { lat: 49.4208277, lng: 18.9737863 },
    { lat: 49.4204417, lng: 18.9749281 },
    { lat: 49.4195509, lng: 18.9779893 },
    { lat: 49.418386, lng: 18.97941 },
    { lat: 49.418829, lng: 18.980565 },
    { lat: 49.419043, lng: 18.98092 },
    { lat: 49.419733, lng: 18.981473 },
    { lat: 49.420545, lng: 18.982566 },
    { lat: 49.421076, lng: 18.983636 },
    { lat: 49.421411, lng: 18.984744 },
    { lat: 49.4218487, lng: 18.985723 },
    { lat: 49.422439, lng: 18.985991 },
    { lat: 49.4231578, lng: 18.9863024 },
    { lat: 49.423567, lng: 18.986627 },
    { lat: 49.42406, lng: 18.986938 },
    { lat: 49.424634, lng: 18.986878 },
    { lat: 49.4249642, lng: 18.9869133 },
  ],
  Radôstka: [
    { lat: 49.315461, lng: 18.90415 },
    { lat: 49.315023, lng: 18.905505 },
    { lat: 49.314336, lng: 18.906248 },
    { lat: 49.31422, lng: 18.907545 },
    { lat: 49.314023, lng: 18.908389 },
    { lat: 49.313766, lng: 18.908834 },
    { lat: 49.313372, lng: 18.909277 },
    { lat: 49.313098, lng: 18.90943 },
    { lat: 49.3129, lng: 18.90984 },
    { lat: 49.312463, lng: 18.910347 },
    { lat: 49.311729, lng: 18.910906 },
    { lat: 49.310404, lng: 18.911317 },
    { lat: 49.310346, lng: 18.911345 },
    { lat: 49.310011, lng: 18.911506 },
    { lat: 49.309851, lng: 18.911576 },
    { lat: 49.309692, lng: 18.911617 },
    { lat: 49.309537, lng: 18.911646 },
    { lat: 49.309375, lng: 18.911666 },
    { lat: 49.309345, lng: 18.91167 },
    { lat: 49.309285, lng: 18.91168 },
    { lat: 49.309284, lng: 18.91168 },
    { lat: 49.308991, lng: 18.911722 },
    { lat: 49.308768, lng: 18.911753 },
    { lat: 49.308538, lng: 18.911778 },
    { lat: 49.308315, lng: 18.911784 },
    { lat: 49.308239, lng: 18.911776 },
    { lat: 49.308122, lng: 18.911764 },
    { lat: 49.307925, lng: 18.911744 },
    { lat: 49.307656, lng: 18.911725 },
    { lat: 49.307573, lng: 18.911706 },
    { lat: 49.306922, lng: 18.911521 },
    { lat: 49.306783, lng: 18.911471 },
    { lat: 49.306757, lng: 18.911463 },
    { lat: 49.306523, lng: 18.911403 },
    { lat: 49.305757, lng: 18.912013 },
    { lat: 49.305741, lng: 18.912026 },
    { lat: 49.305624, lng: 18.912222 },
    { lat: 49.305341, lng: 18.912249 },
    { lat: 49.30533, lng: 18.91216 },
    { lat: 49.305332, lng: 18.912134 },
    { lat: 49.305355, lng: 18.911784 },
    { lat: 49.302771, lng: 18.912406 },
    { lat: 49.302732, lng: 18.913418 },
    { lat: 49.302666, lng: 18.913451 },
    { lat: 49.302686, lng: 18.914371 },
    { lat: 49.302858, lng: 18.916081 },
    { lat: 49.302162, lng: 18.916678 },
    { lat: 49.302231, lng: 18.917779 },
    { lat: 49.300975, lng: 18.920017 },
    { lat: 49.300664, lng: 18.920569 },
    { lat: 49.300493, lng: 18.920875 },
    { lat: 49.300491, lng: 18.920884 },
    { lat: 49.300449, lng: 18.92105 },
    { lat: 49.299825, lng: 18.923507 },
    { lat: 49.299789, lng: 18.923746 },
    { lat: 49.299466, lng: 18.925888 },
    { lat: 49.299418, lng: 18.927467 },
    { lat: 49.298154, lng: 18.931019 },
    { lat: 49.298141, lng: 18.931058 },
    { lat: 49.298727, lng: 18.932124 },
    { lat: 49.298988, lng: 18.932597 },
    { lat: 49.29936, lng: 18.933343 },
    { lat: 49.299887, lng: 18.934402 },
    { lat: 49.300076, lng: 18.934752 },
    { lat: 49.300332, lng: 18.935227 },
    { lat: 49.300688, lng: 18.935985 },
    { lat: 49.300694, lng: 18.935999 },
    { lat: 49.300966, lng: 18.936291 },
    { lat: 49.302027, lng: 18.936538 },
    { lat: 49.302029, lng: 18.936539 },
    { lat: 49.302034, lng: 18.936544 },
    { lat: 49.302624, lng: 18.937225 },
    { lat: 49.303693, lng: 18.937162 },
    { lat: 49.30371, lng: 18.937161 },
    { lat: 49.304321, lng: 18.93792 },
    { lat: 49.304358, lng: 18.937967 },
    { lat: 49.304561, lng: 18.938218 },
    { lat: 49.30457, lng: 18.938221 },
    { lat: 49.305707, lng: 18.938526 },
    { lat: 49.307146, lng: 18.938911 },
    { lat: 49.307808, lng: 18.938788 },
    { lat: 49.30781, lng: 18.93879 },
    { lat: 49.307893, lng: 18.938866 },
    { lat: 49.308393, lng: 18.939322 },
    { lat: 49.30859, lng: 18.939568 },
    { lat: 49.308733, lng: 18.939747 },
    { lat: 49.308873, lng: 18.939921 },
    { lat: 49.309021, lng: 18.939953 },
    { lat: 49.309059, lng: 18.940163 },
    { lat: 49.309087, lng: 18.940316 },
    { lat: 49.30911, lng: 18.940442 },
    { lat: 49.309121, lng: 18.940496 },
    { lat: 49.309138, lng: 18.940593 },
    { lat: 49.309162, lng: 18.940724 },
    { lat: 49.309219, lng: 18.941035 },
    { lat: 49.309248, lng: 18.941193 },
    { lat: 49.309305, lng: 18.941313 },
    { lat: 49.309376, lng: 18.941463 },
    { lat: 49.30941, lng: 18.941536 },
    { lat: 49.309475, lng: 18.941675 },
    { lat: 49.309518, lng: 18.941759 },
    { lat: 49.309543, lng: 18.941809 },
    { lat: 49.309586, lng: 18.941896 },
    { lat: 49.309609, lng: 18.941944 },
    { lat: 49.309635, lng: 18.941995 },
    { lat: 49.309675, lng: 18.942076 },
    { lat: 49.309681, lng: 18.942089 },
    { lat: 49.309678, lng: 18.942103 },
    { lat: 49.309672, lng: 18.942145 },
    { lat: 49.309691, lng: 18.942312 },
    { lat: 49.30971, lng: 18.94247 },
    { lat: 49.309791, lng: 18.943158 },
    { lat: 49.309817, lng: 18.943374 },
    { lat: 49.309843, lng: 18.943619 },
    { lat: 49.309844, lng: 18.943636 },
    { lat: 49.309827, lng: 18.94377 },
    { lat: 49.309823, lng: 18.943796 },
    { lat: 49.309808, lng: 18.943925 },
    { lat: 49.309797, lng: 18.944005 },
    { lat: 49.309822, lng: 18.944102 },
    { lat: 49.309974, lng: 18.944703 },
    { lat: 49.30994, lng: 18.944914 },
    { lat: 49.309929, lng: 18.944985 },
    { lat: 49.309913, lng: 18.945081 },
    { lat: 49.309903, lng: 18.945142 },
    { lat: 49.309873, lng: 18.945319 },
    { lat: 49.309844, lng: 18.945501 },
    { lat: 49.309829, lng: 18.945592 },
    { lat: 49.30982, lng: 18.945649 },
    { lat: 49.309821, lng: 18.945696 },
    { lat: 49.309824, lng: 18.945794 },
    { lat: 49.309828, lng: 18.945912 },
    { lat: 49.309832, lng: 18.946058 },
    { lat: 49.309836, lng: 18.946196 },
    { lat: 49.309842, lng: 18.946406 },
    { lat: 49.309846, lng: 18.946552 },
    { lat: 49.309849, lng: 18.946628 },
    { lat: 49.309919, lng: 18.946849 },
    { lat: 49.310098, lng: 18.947418 },
    { lat: 49.310155, lng: 18.947782 },
    { lat: 49.310186, lng: 18.947911 },
    { lat: 49.310516, lng: 18.948718 },
    { lat: 49.310656, lng: 18.949061 },
    { lat: 49.310843, lng: 18.949602 },
    { lat: 49.31116, lng: 18.951181 },
    { lat: 49.311369, lng: 18.951715 },
    { lat: 49.311706, lng: 18.952168 },
    { lat: 49.312086, lng: 18.952626 },
    { lat: 49.312789, lng: 18.95375 },
    { lat: 49.312795, lng: 18.95376 },
    { lat: 49.31289, lng: 18.95389 },
    { lat: 49.31332, lng: 18.954494 },
    { lat: 49.313743, lng: 18.955785 },
    { lat: 49.313749, lng: 18.95697 },
    { lat: 49.313749, lng: 18.957059 },
    { lat: 49.314155, lng: 18.958151 },
    { lat: 49.314324, lng: 18.958607 },
    { lat: 49.314923, lng: 18.960358 },
    { lat: 49.315127, lng: 18.961636 },
    { lat: 49.315571, lng: 18.962692 },
    { lat: 49.315607, lng: 18.962777 },
    { lat: 49.315736, lng: 18.963084 },
    { lat: 49.315893, lng: 18.96511 },
    { lat: 49.315959, lng: 18.970878 },
    { lat: 49.316449, lng: 18.972202 },
    { lat: 49.316416, lng: 18.972224 },
    { lat: 49.315672, lng: 18.97273 },
    { lat: 49.315666, lng: 18.972751 },
    { lat: 49.315539, lng: 18.973094 },
    { lat: 49.315424, lng: 18.973402 },
    { lat: 49.31541, lng: 18.97354 },
    { lat: 49.315239, lng: 18.975059 },
    { lat: 49.315169, lng: 18.975682 },
    { lat: 49.315214, lng: 18.975874 },
    { lat: 49.31532, lng: 18.976324 },
    { lat: 49.3162396, lng: 18.9758177 },
    { lat: 49.316578, lng: 18.9753676 },
    { lat: 49.3170237, lng: 18.9749048 },
    { lat: 49.3175899, lng: 18.9745074 },
    { lat: 49.3189654, lng: 18.9745116 },
    { lat: 49.3203416, lng: 18.9743502 },
    { lat: 49.32029, lng: 18.9737217 },
    { lat: 49.3203218, lng: 18.9721554 },
    { lat: 49.320744, lng: 18.9722544 },
    { lat: 49.3213443, lng: 18.9719078 },
    { lat: 49.321823, lng: 18.971293 },
    { lat: 49.3222099, lng: 18.9701059 },
    { lat: 49.3224186, lng: 18.9696366 },
    { lat: 49.3232607, lng: 18.9691651 },
    { lat: 49.3243807, lng: 18.9675779 },
    { lat: 49.3249602, lng: 18.9671788 },
    { lat: 49.3255357, lng: 18.9672319 },
    { lat: 49.3256833, lng: 18.9676595 },
    { lat: 49.3257971, lng: 18.9683086 },
    { lat: 49.3261113, lng: 18.9692685 },
    { lat: 49.3267247, lng: 18.9701726 },
    { lat: 49.326834, lng: 18.9702357 },
    { lat: 49.3274447, lng: 18.9699638 },
    { lat: 49.327613, lng: 18.9686436 },
    { lat: 49.327844, lng: 18.9679887 },
    { lat: 49.3276605, lng: 18.9674341 },
    { lat: 49.3288183, lng: 18.9671431 },
    { lat: 49.3300715, lng: 18.9665958 },
    { lat: 49.3302849, lng: 18.9664428 },
    { lat: 49.3303046, lng: 18.9661199 },
    { lat: 49.3298205, lng: 18.9652764 },
    { lat: 49.329344, lng: 18.9647251 },
    { lat: 49.3292329, lng: 18.9640829 },
    { lat: 49.3291194, lng: 18.9637099 },
    { lat: 49.3291456, lng: 18.9631064 },
    { lat: 49.3292588, lng: 18.9623547 },
    { lat: 49.3299338, lng: 18.9621445 },
    { lat: 49.3311079, lng: 18.9624478 },
    { lat: 49.3318056, lng: 18.9633663 },
    { lat: 49.3347303, lng: 18.9650374 },
    { lat: 49.3350182, lng: 18.9631821 },
    { lat: 49.3359262, lng: 18.9631234 },
    { lat: 49.3367787, lng: 18.9616503 },
    { lat: 49.3372873, lng: 18.9610486 },
    { lat: 49.3374455, lng: 18.9612783 },
    { lat: 49.3378184, lng: 18.9608896 },
    { lat: 49.3382675, lng: 18.960899 },
    { lat: 49.3385728, lng: 18.9610528 },
    { lat: 49.3388913, lng: 18.9613776 },
    { lat: 49.339046, lng: 18.9617217 },
    { lat: 49.3406123, lng: 18.9615306 },
    { lat: 49.3410128, lng: 18.9618284 },
    { lat: 49.3409135, lng: 18.960925 },
    { lat: 49.3408758, lng: 18.9605817 },
    { lat: 49.3405425, lng: 18.95951 },
    { lat: 49.3402052, lng: 18.9583628 },
    { lat: 49.3400994, lng: 18.9567946 },
    { lat: 49.3402214, lng: 18.9560352 },
    { lat: 49.3402166, lng: 18.9545521 },
    { lat: 49.3402191, lng: 18.9533439 },
    { lat: 49.3401218, lng: 18.9510507 },
    { lat: 49.3405219, lng: 18.9477115 },
    { lat: 49.3405913, lng: 18.9454159 },
    { lat: 49.3410498, lng: 18.9441782 },
    { lat: 49.3413499, lng: 18.9412053 },
    { lat: 49.3414302, lng: 18.940339 },
    { lat: 49.3418068, lng: 18.9394254 },
    { lat: 49.3419039, lng: 18.9386845 },
    { lat: 49.3418413, lng: 18.938038 },
    { lat: 49.341953, lng: 18.9374968 },
    { lat: 49.3416528, lng: 18.9362931 },
    { lat: 49.3414986, lng: 18.9355828 },
    { lat: 49.3415774, lng: 18.9346747 },
    { lat: 49.3415637, lng: 18.9344387 },
    { lat: 49.3413427, lng: 18.9341601 },
    { lat: 49.3413634, lng: 18.9339787 },
    { lat: 49.3412874, lng: 18.9338904 },
    { lat: 49.3411966, lng: 18.9339032 },
    { lat: 49.3409325, lng: 18.9342708 },
    { lat: 49.3408267, lng: 18.9344308 },
    { lat: 49.3406694, lng: 18.9349328 },
    { lat: 49.340407, lng: 18.9352476 },
    { lat: 49.339886, lng: 18.935897 },
    { lat: 49.3391842, lng: 18.9369986 },
    { lat: 49.3389135, lng: 18.9372887 },
    { lat: 49.3387898, lng: 18.9373626 },
    { lat: 49.3387409, lng: 18.9374407 },
    { lat: 49.338682, lng: 18.9374824 },
    { lat: 49.3386382, lng: 18.9375316 },
    { lat: 49.3385803, lng: 18.9375866 },
    { lat: 49.3384122, lng: 18.9377434 },
    { lat: 49.3382856, lng: 18.9378061 },
    { lat: 49.3381944, lng: 18.9378519 },
    { lat: 49.3376877, lng: 18.9379978 },
    { lat: 49.3376512, lng: 18.9380086 },
    { lat: 49.3375611, lng: 18.9380348 },
    { lat: 49.3371743, lng: 18.9380328 },
    { lat: 49.3371359, lng: 18.9380319 },
    { lat: 49.3370908, lng: 18.938031 },
    { lat: 49.3370236, lng: 18.9380144 },
    { lat: 49.3369515, lng: 18.9379961 },
    { lat: 49.3368601, lng: 18.9379733 },
    { lat: 49.3368304, lng: 18.9379653 },
    { lat: 49.3366898, lng: 18.937949 },
    { lat: 49.3365019, lng: 18.9378678 },
    { lat: 49.3364645, lng: 18.9378378 },
    { lat: 49.3363498, lng: 18.9378221 },
    { lat: 49.3360246, lng: 18.9377448 },
    { lat: 49.335874, lng: 18.9376863 },
    { lat: 49.3357837, lng: 18.9376427 },
    { lat: 49.3357798, lng: 18.9376306 },
    { lat: 49.3356991, lng: 18.9375721 },
    { lat: 49.3351551, lng: 18.9374039 },
    { lat: 49.3351267, lng: 18.9373971 },
    { lat: 49.3349053, lng: 18.9373221 },
    { lat: 49.334836, lng: 18.93731 },
    { lat: 49.3347497, lng: 18.9372577 },
    { lat: 49.3347346, lng: 18.9371778 },
    { lat: 49.334705, lng: 18.9370175 },
    { lat: 49.3346985, lng: 18.9369774 },
    { lat: 49.3346988, lng: 18.9369184 },
    { lat: 49.3347015, lng: 18.9367945 },
    { lat: 49.334712, lng: 18.9367126 },
    { lat: 49.3346865, lng: 18.936515 },
    { lat: 49.3346714, lng: 18.9364178 },
    { lat: 49.3346667, lng: 18.9363941 },
    { lat: 49.3346418, lng: 18.9362575 },
    { lat: 49.3346048, lng: 18.9361046 },
    { lat: 49.3345806, lng: 18.9358714 },
    { lat: 49.334565, lng: 18.9358292 },
    { lat: 49.334535, lng: 18.9357503 },
    { lat: 49.3345213, lng: 18.9356988 },
    { lat: 49.3345075, lng: 18.9356502 },
    { lat: 49.3344772, lng: 18.9355406 },
    { lat: 49.3344553, lng: 18.9354637 },
    { lat: 49.3344299, lng: 18.9353629 },
    { lat: 49.3343502, lng: 18.9352158 },
    { lat: 49.3343338, lng: 18.9351809 },
    { lat: 49.3342156, lng: 18.9349346 },
    { lat: 49.3339052, lng: 18.9346835 },
    { lat: 49.3339102, lng: 18.9345799 },
    { lat: 49.3338644, lng: 18.9344537 },
    { lat: 49.3337378, lng: 18.934272 },
    { lat: 49.3336279, lng: 18.9339249 },
    { lat: 49.3335903, lng: 18.9336937 },
    { lat: 49.3335231, lng: 18.9330325 },
    { lat: 49.3334528, lng: 18.9323096 },
    { lat: 49.3332997, lng: 18.9319763 },
    { lat: 49.3332471, lng: 18.9316087 },
    { lat: 49.3331693, lng: 18.9308671 },
    { lat: 49.3331185, lng: 18.9305529 },
    { lat: 49.3330332, lng: 18.9302488 },
    { lat: 49.3329246, lng: 18.9297954 },
    { lat: 49.3328277, lng: 18.9295046 },
    { lat: 49.3327323, lng: 18.9291783 },
    { lat: 49.3326435, lng: 18.92887 },
    { lat: 49.3324894, lng: 18.9282748 },
    { lat: 49.3324324, lng: 18.9280635 },
    { lat: 49.3323729, lng: 18.9278909 },
    { lat: 49.3321597, lng: 18.9274621 },
    { lat: 49.3317797, lng: 18.9268795 },
    { lat: 49.3310685, lng: 18.9257705 },
    { lat: 49.3310178, lng: 18.9257714 },
    { lat: 49.3281786, lng: 18.9240871 },
    { lat: 49.3282458, lng: 18.9238547 },
    { lat: 49.3272669, lng: 18.9231324 },
    { lat: 49.3267893, lng: 18.9226059 },
    { lat: 49.3257521, lng: 18.9221182 },
    { lat: 49.3251508, lng: 18.9212854 },
    { lat: 49.3242871, lng: 18.9203461 },
    { lat: 49.3241209, lng: 18.9198207 },
    { lat: 49.3232664, lng: 18.9185731 },
    { lat: 49.3230155, lng: 18.9184752 },
    { lat: 49.3228088, lng: 18.9176855 },
    { lat: 49.3220814, lng: 18.9171681 },
    { lat: 49.321581, lng: 18.916948 },
    { lat: 49.3211773, lng: 18.9171718 },
    { lat: 49.3202401, lng: 18.9163826 },
    { lat: 49.319699, lng: 18.9154944 },
    { lat: 49.3192849, lng: 18.9159508 },
    { lat: 49.3191139, lng: 18.9123977 },
    { lat: 49.3193176, lng: 18.9112146 },
    { lat: 49.3194329, lng: 18.9110422 },
    { lat: 49.3194731, lng: 18.9101889 },
    { lat: 49.319755, lng: 18.9090308 },
    { lat: 49.3185407, lng: 18.9062054 },
    { lat: 49.3191011, lng: 18.905694 },
    { lat: 49.3190301, lng: 18.9055434 },
    { lat: 49.3204862, lng: 18.9008662 },
    { lat: 49.3206106, lng: 18.8996302 },
    { lat: 49.3203486, lng: 18.8977091 },
    { lat: 49.3203064, lng: 18.8930773 },
    { lat: 49.319865, lng: 18.893266 },
    { lat: 49.319314, lng: 18.893663 },
    { lat: 49.318876, lng: 18.894141 },
    { lat: 49.31892, lng: 18.894155 },
    { lat: 49.31886, lng: 18.894711 },
    { lat: 49.318778, lng: 18.894921 },
    { lat: 49.318632, lng: 18.896417 },
    { lat: 49.318592, lng: 18.897355 },
    { lat: 49.318423, lng: 18.898151 },
    { lat: 49.318425, lng: 18.898746 },
    { lat: 49.31814, lng: 18.899757 },
    { lat: 49.317713, lng: 18.900513 },
    { lat: 49.31721, lng: 18.901636 },
    { lat: 49.316025, lng: 18.903481 },
    { lat: 49.315461, lng: 18.90415 },
  ],
  Raková: [
    { lat: 49.4996409, lng: 18.6976753 },
    { lat: 49.499617, lng: 18.697579 },
    { lat: 49.499908, lng: 18.69627 },
    { lat: 49.499987, lng: 18.695155 },
    { lat: 49.50001, lng: 18.694824 },
    { lat: 49.50017, lng: 18.693222 },
    { lat: 49.5001996, lng: 18.6920411 },
    { lat: 49.500212, lng: 18.691546 },
    { lat: 49.5003878, lng: 18.690914 },
    { lat: 49.500565, lng: 18.690277 },
    { lat: 49.500571, lng: 18.690159 },
    { lat: 49.500671, lng: 18.689209 },
    { lat: 49.500736, lng: 18.687923 },
    { lat: 49.501405, lng: 18.686861 },
    { lat: 49.50152, lng: 18.686678 },
    { lat: 49.5018921, lng: 18.6863472 },
    { lat: 49.502114, lng: 18.68615 },
    { lat: 49.502421, lng: 18.685798 },
    { lat: 49.502758, lng: 18.685389 },
    { lat: 49.503778, lng: 18.68514 },
    { lat: 49.504912, lng: 18.684856 },
    { lat: 49.5052142, lng: 18.6842989 },
    { lat: 49.505422, lng: 18.683916 },
    { lat: 49.5054222, lng: 18.6839158 },
    { lat: 49.5055419, lng: 18.6838073 },
    { lat: 49.506002, lng: 18.68339 },
    { lat: 49.5063603, lng: 18.6826108 },
    { lat: 49.506696, lng: 18.681603 },
    { lat: 49.506969, lng: 18.680402 },
    { lat: 49.507368, lng: 18.679542 },
    { lat: 49.507552, lng: 18.678302 },
    { lat: 49.507628, lng: 18.677825 },
    { lat: 49.5076457, lng: 18.6764362 },
    { lat: 49.507409, lng: 18.675203 },
    { lat: 49.507011, lng: 18.673539 },
    { lat: 49.506769, lng: 18.673055 },
    { lat: 49.506558, lng: 18.672654 },
    { lat: 49.5060923, lng: 18.6708856 },
    { lat: 49.505803, lng: 18.670647 },
    { lat: 49.505521, lng: 18.670433 },
    { lat: 49.505254, lng: 18.670513 },
    { lat: 49.504332, lng: 18.670283 },
    { lat: 49.503872, lng: 18.669469 },
    { lat: 49.503286, lng: 18.667867 },
    { lat: 49.502983, lng: 18.667148 },
    { lat: 49.502826, lng: 18.666237 },
    { lat: 49.502333, lng: 18.664391 },
    { lat: 49.5016627, lng: 18.6635843 },
    { lat: 49.499348, lng: 18.6641239 },
    { lat: 49.4975515, lng: 18.665386 },
    { lat: 49.4974054, lng: 18.6654792 },
    { lat: 49.4964793, lng: 18.6661371 },
    { lat: 49.4962144, lng: 18.6663132 },
    { lat: 49.4961165, lng: 18.6663791 },
    { lat: 49.4960793, lng: 18.6664036 },
    { lat: 49.4958681, lng: 18.6665441 },
    { lat: 49.4958343, lng: 18.6665668 },
    { lat: 49.4952821, lng: 18.6669381 },
    { lat: 49.4935031, lng: 18.6660689 },
    { lat: 49.4934292, lng: 18.6660327 },
    { lat: 49.4933239, lng: 18.6659819 },
    { lat: 49.4911776, lng: 18.6638096 },
    { lat: 49.4903919, lng: 18.6640481 },
    { lat: 49.4891506, lng: 18.6654487 },
    { lat: 49.4890922, lng: 18.6655143 },
    { lat: 49.4890495, lng: 18.6655613 },
    { lat: 49.4890316, lng: 18.6655835 },
    { lat: 49.4888195, lng: 18.6658187 },
    { lat: 49.4873082, lng: 18.6646347 },
    { lat: 49.485187, lng: 18.665936 },
    { lat: 49.4851631, lng: 18.6659535 },
    { lat: 49.4828213, lng: 18.6677604 },
    { lat: 49.4822534, lng: 18.6685854 },
    { lat: 49.4822253, lng: 18.6686259 },
    { lat: 49.4816332, lng: 18.6694866 },
    { lat: 49.4810209, lng: 18.6702573 },
    { lat: 49.4809935, lng: 18.6702926 },
    { lat: 49.4803615, lng: 18.6710998 },
    { lat: 49.480199, lng: 18.6709875 },
    { lat: 49.4800464, lng: 18.6711337 },
    { lat: 49.4799167, lng: 18.6712586 },
    { lat: 49.4796355, lng: 18.671528 },
    { lat: 49.4791394, lng: 18.6720036 },
    { lat: 49.4790005, lng: 18.6720433 },
    { lat: 49.4789141, lng: 18.6720275 },
    { lat: 49.478783, lng: 18.6720241 },
    { lat: 49.478571, lng: 18.6720464 },
    { lat: 49.4784291, lng: 18.672093 },
    { lat: 49.477975, lng: 18.672205 },
    { lat: 49.4775973, lng: 18.6723569 },
    { lat: 49.4775656, lng: 18.6724196 },
    { lat: 49.4774111, lng: 18.6725221 },
    { lat: 49.477315, lng: 18.672628 },
    { lat: 49.4772916, lng: 18.6726327 },
    { lat: 49.4771685, lng: 18.6726494 },
    { lat: 49.4769499, lng: 18.6727794 },
    { lat: 49.4768395, lng: 18.6728456 },
    { lat: 49.4767971, lng: 18.6728759 },
    { lat: 49.4767393, lng: 18.6729132 },
    { lat: 49.4766046, lng: 18.6729897 },
    { lat: 49.4765375, lng: 18.6730215 },
    { lat: 49.4764779, lng: 18.6730699 },
    { lat: 49.4764033, lng: 18.6731303 },
    { lat: 49.475544, lng: 18.6736438 },
    { lat: 49.4755112, lng: 18.6736635 },
    { lat: 49.4745232, lng: 18.6746053 },
    { lat: 49.4745109, lng: 18.6747768 },
    { lat: 49.473439, lng: 18.6752601 },
    { lat: 49.4734203, lng: 18.6752689 },
    { lat: 49.4730976, lng: 18.6754144 },
    { lat: 49.472278, lng: 18.6755857 },
    { lat: 49.4722511, lng: 18.6755909 },
    { lat: 49.4719923, lng: 18.675645 },
    { lat: 49.4717637, lng: 18.6756929 },
    { lat: 49.4713311, lng: 18.6759799 },
    { lat: 49.4702635, lng: 18.6763937 },
    { lat: 49.4688441, lng: 18.6762687 },
    { lat: 49.4683163, lng: 18.6768673 },
    { lat: 49.4675941, lng: 18.6769638 },
    { lat: 49.4668476, lng: 18.6776116 },
    { lat: 49.4666887, lng: 18.678364 },
    { lat: 49.4660953, lng: 18.6792588 },
    { lat: 49.4658788, lng: 18.6799454 },
    { lat: 49.4657484, lng: 18.6799953 },
    { lat: 49.4654636, lng: 18.6803727 },
    { lat: 49.4651642, lng: 18.6810695 },
    { lat: 49.4650071, lng: 18.6811691 },
    { lat: 49.4634841, lng: 18.68339 },
    { lat: 49.4633339, lng: 18.6834288 },
    { lat: 49.4628894, lng: 18.684112 },
    { lat: 49.4622981, lng: 18.6845733 },
    { lat: 49.4621089, lng: 18.6848809 },
    { lat: 49.4615154, lng: 18.6848095 },
    { lat: 49.4614174, lng: 18.6849829 },
    { lat: 49.4603595, lng: 18.6855808 },
    { lat: 49.4600242, lng: 18.6861537 },
    { lat: 49.4596848, lng: 18.6862409 },
    { lat: 49.4594027, lng: 18.6867335 },
    { lat: 49.4593936, lng: 18.6870732 },
    { lat: 49.4592481, lng: 18.6870919 },
    { lat: 49.4590811, lng: 18.6878005 },
    { lat: 49.4587472, lng: 18.6878856 },
    { lat: 49.4585295, lng: 18.6880542 },
    { lat: 49.4581572, lng: 18.6885257 },
    { lat: 49.4576575, lng: 18.6883902 },
    { lat: 49.4575458, lng: 18.688176 },
    { lat: 49.4574299, lng: 18.6882454 },
    { lat: 49.4573687, lng: 18.6883907 },
    { lat: 49.4571813, lng: 18.6882779 },
    { lat: 49.4570813, lng: 18.6883707 },
    { lat: 49.4570068, lng: 18.688609 },
    { lat: 49.4570381, lng: 18.6888585 },
    { lat: 49.4569314, lng: 18.6889952 },
    { lat: 49.4568422, lng: 18.68897 },
    { lat: 49.4567271, lng: 18.689056 },
    { lat: 49.4565953, lng: 18.689061 },
    { lat: 49.4564882, lng: 18.6892935 },
    { lat: 49.456386, lng: 18.6892818 },
    { lat: 49.4562971, lng: 18.689308 },
    { lat: 49.4561846, lng: 18.689506 },
    { lat: 49.4558987, lng: 18.6893438 },
    { lat: 49.4558105, lng: 18.6893836 },
    { lat: 49.4557642, lng: 18.6895731 },
    { lat: 49.4556302, lng: 18.6896622 },
    { lat: 49.4555918, lng: 18.6896611 },
    { lat: 49.4553307, lng: 18.6896295 },
    { lat: 49.4552276, lng: 18.6896415 },
    { lat: 49.4550957, lng: 18.6897259 },
    { lat: 49.4550651, lng: 18.6897456 },
    { lat: 49.4550573, lng: 18.6897506 },
    { lat: 49.4549579, lng: 18.6898096 },
    { lat: 49.4548527, lng: 18.6899085 },
    { lat: 49.4546164, lng: 18.6901337 },
    { lat: 49.4545819, lng: 18.6904667 },
    { lat: 49.4544274, lng: 18.6906794 },
    { lat: 49.4540789, lng: 18.6907651 },
    { lat: 49.4535818, lng: 18.6909199 },
    { lat: 49.4533842, lng: 18.6910864 },
    { lat: 49.4532652, lng: 18.6913537 },
    { lat: 49.4530116, lng: 18.6916575 },
    { lat: 49.4527599, lng: 18.6919782 },
    { lat: 49.4526098, lng: 18.6920173 },
    { lat: 49.4522797, lng: 18.6919363 },
    { lat: 49.452225, lng: 18.692204 },
    { lat: 49.4521578, lng: 18.6925124 },
    { lat: 49.4521294, lng: 18.6926387 },
    { lat: 49.4520905, lng: 18.6926943 },
    { lat: 49.4519515, lng: 18.6927966 },
    { lat: 49.4517045, lng: 18.6928803 },
    { lat: 49.4515206, lng: 18.692966 },
    { lat: 49.4514678, lng: 18.6929903 },
    { lat: 49.4513486, lng: 18.6930822 },
    { lat: 49.4512723, lng: 18.6930242 },
    { lat: 49.4511938, lng: 18.6930321 },
    { lat: 49.4510832, lng: 18.6931592 },
    { lat: 49.4510226, lng: 18.6932108 },
    { lat: 49.4508446, lng: 18.6931465 },
    { lat: 49.4507949, lng: 18.6931219 },
    { lat: 49.4506513, lng: 18.6932671 },
    { lat: 49.4504002, lng: 18.6933825 },
    { lat: 49.45034, lng: 18.6933961 },
    { lat: 49.4503096, lng: 18.6935463 },
    { lat: 49.4501334, lng: 18.6936688 },
    { lat: 49.4500047, lng: 18.693689 },
    { lat: 49.4499378, lng: 18.6936791 },
    { lat: 49.4498547, lng: 18.6937303 },
    { lat: 49.4498145, lng: 18.693911 },
    { lat: 49.4496285, lng: 18.6939318 },
    { lat: 49.4494182, lng: 18.6940092 },
    { lat: 49.4491836, lng: 18.6940276 },
    { lat: 49.4489958, lng: 18.6939108 },
    { lat: 49.4488501, lng: 18.6937541 },
    { lat: 49.4487246, lng: 18.693704 },
    { lat: 49.4485174, lng: 18.6937958 },
    { lat: 49.4483722, lng: 18.6939504 },
    { lat: 49.4482214, lng: 18.6940444 },
    { lat: 49.4480454, lng: 18.6941041 },
    { lat: 49.4479463, lng: 18.6942377 },
    { lat: 49.4476707, lng: 18.6944888 },
    { lat: 49.4476718, lng: 18.6946796 },
    { lat: 49.4475092, lng: 18.6947392 },
    { lat: 49.4472809, lng: 18.6945172 },
    { lat: 49.4470697, lng: 18.6943998 },
    { lat: 49.4469577, lng: 18.6944817 },
    { lat: 49.4468919, lng: 18.6945779 },
    { lat: 49.4467618, lng: 18.6945305 },
    { lat: 49.4466327, lng: 18.694521 },
    { lat: 49.4464935, lng: 18.6946824 },
    { lat: 49.4463081, lng: 18.6947375 },
    { lat: 49.4460432, lng: 18.6949314 },
    { lat: 49.44589, lng: 18.6949797 },
    { lat: 49.4456703, lng: 18.695047 },
    { lat: 49.4454303, lng: 18.6949867 },
    { lat: 49.4451813, lng: 18.6947938 },
    { lat: 49.4449006, lng: 18.6947987 },
    { lat: 49.4446692, lng: 18.6949638 },
    { lat: 49.4438124, lng: 18.6949676 },
    { lat: 49.4436082, lng: 18.695138 },
    { lat: 49.4434745, lng: 18.6954281 },
    { lat: 49.4433171, lng: 18.6953913 },
    { lat: 49.4430931, lng: 18.695549 },
    { lat: 49.4428541, lng: 18.6955959 },
    { lat: 49.4427012, lng: 18.6956234 },
    { lat: 49.4426782, lng: 18.6956563 },
    { lat: 49.4425294, lng: 18.6957899 },
    { lat: 49.4424992, lng: 18.6958164 },
    { lat: 49.4422517, lng: 18.6960397 },
    { lat: 49.4421641, lng: 18.6961128 },
    { lat: 49.442068, lng: 18.6961066 },
    { lat: 49.4420389, lng: 18.6961326 },
    { lat: 49.4417849, lng: 18.6964078 },
    { lat: 49.441436, lng: 18.6968066 },
    { lat: 49.4411648, lng: 18.6971194 },
    { lat: 49.4408244, lng: 18.6975755 },
    { lat: 49.4404705, lng: 18.6980506 },
    { lat: 49.4403683, lng: 18.698189 },
    { lat: 49.440399, lng: 18.6985812 },
    { lat: 49.440267, lng: 18.6987725 },
    { lat: 49.4400756, lng: 18.6989928 },
    { lat: 49.4399736, lng: 18.6988322 },
    { lat: 49.4399083, lng: 18.6989431 },
    { lat: 49.4397651, lng: 18.6991992 },
    { lat: 49.4397532, lng: 18.6992333 },
    { lat: 49.4398803, lng: 18.6993107 },
    { lat: 49.4398964, lng: 18.69932 },
    { lat: 49.4399035, lng: 18.6993246 },
    { lat: 49.4398908, lng: 18.6993624 },
    { lat: 49.4398908, lng: 18.6994076 },
    { lat: 49.4399933, lng: 18.6995726 },
    { lat: 49.4400391, lng: 18.6996941 },
    { lat: 49.4400318, lng: 18.6997313 },
    { lat: 49.4399421, lng: 18.6998688 },
    { lat: 49.4399328, lng: 18.6999692 },
    { lat: 49.4399461, lng: 18.7002248 },
    { lat: 49.4399921, lng: 18.7004344 },
    { lat: 49.4399847, lng: 18.7004462 },
    { lat: 49.439965, lng: 18.7004772 },
    { lat: 49.4397167, lng: 18.7008154 },
    { lat: 49.4397141, lng: 18.7008741 },
    { lat: 49.4397132, lng: 18.7009028 },
    { lat: 49.439584, lng: 18.7009118 },
    { lat: 49.4394597, lng: 18.7010108 },
    { lat: 49.4393545, lng: 18.7011995 },
    { lat: 49.4392901, lng: 18.7014136 },
    { lat: 49.4391723, lng: 18.7016821 },
    { lat: 49.4391626, lng: 18.7016695 },
    { lat: 49.4391493, lng: 18.7016534 },
    { lat: 49.4390586, lng: 18.7015367 },
    { lat: 49.4388054, lng: 18.7015881 },
    { lat: 49.4387992, lng: 18.7016016 },
    { lat: 49.4389127, lng: 18.7017423 },
    { lat: 49.4389474, lng: 18.7018243 },
    { lat: 49.438974, lng: 18.7019667 },
    { lat: 49.4389704, lng: 18.7020048 },
    { lat: 49.4389797, lng: 18.7020123 },
    { lat: 49.4389891, lng: 18.7020203 },
    { lat: 49.4389811, lng: 18.7022332 },
    { lat: 49.4389377, lng: 18.7024781 },
    { lat: 49.4388914, lng: 18.7025403 },
    { lat: 49.4387035, lng: 18.702725 },
    { lat: 49.4387043, lng: 18.7028256 },
    { lat: 49.4387872, lng: 18.7029654 },
    { lat: 49.4387491, lng: 18.7031408 },
    { lat: 49.4387114, lng: 18.7034136 },
    { lat: 49.4386643, lng: 18.7034614 },
    { lat: 49.4384688, lng: 18.7034503 },
    { lat: 49.4382717, lng: 18.7035441 },
    { lat: 49.4382225, lng: 18.7035715 },
    { lat: 49.4382074, lng: 18.7036082 },
    { lat: 49.4382141, lng: 18.7037363 },
    { lat: 49.4381121, lng: 18.7038326 },
    { lat: 49.4380709, lng: 18.7039511 },
    { lat: 49.4380222, lng: 18.7039701 },
    { lat: 49.4379959, lng: 18.7038737 },
    { lat: 49.4378973, lng: 18.7038862 },
    { lat: 49.4378522, lng: 18.7037602 },
    { lat: 49.4377644, lng: 18.7036315 },
    { lat: 49.4377062, lng: 18.7036604 },
    { lat: 49.4375978, lng: 18.7036578 },
    { lat: 49.4375178, lng: 18.7037043 },
    { lat: 49.4374997, lng: 18.7038514 },
    { lat: 49.4375322, lng: 18.7039344 },
    { lat: 49.4376166, lng: 18.7039077 },
    { lat: 49.4376494, lng: 18.7039754 },
    { lat: 49.4376193, lng: 18.7040454 },
    { lat: 49.4377507, lng: 18.7041908 },
    { lat: 49.4377485, lng: 18.7044469 },
    { lat: 49.437638, lng: 18.7044942 },
    { lat: 49.4376198, lng: 18.7045972 },
    { lat: 49.4374634, lng: 18.7047106 },
    { lat: 49.4374381, lng: 18.704701 },
    { lat: 49.4373745, lng: 18.7046063 },
    { lat: 49.4373028, lng: 18.7046162 },
    { lat: 49.4369753, lng: 18.7048652 },
    { lat: 49.4369162, lng: 18.7049196 },
    { lat: 49.4369162, lng: 18.7051997 },
    { lat: 49.4369541, lng: 18.7052997 },
    { lat: 49.4370235, lng: 18.7054212 },
    { lat: 49.4370423, lng: 18.7055407 },
    { lat: 49.4371038, lng: 18.7055958 },
    { lat: 49.4370867, lng: 18.7057428 },
    { lat: 49.4369927, lng: 18.7059073 },
    { lat: 49.4369141, lng: 18.7059361 },
    { lat: 49.4367885, lng: 18.7059465 },
    { lat: 49.4367322, lng: 18.7060357 },
    { lat: 49.4365561, lng: 18.7060744 },
    { lat: 49.4364328, lng: 18.7061975 },
    { lat: 49.436388, lng: 18.7065929 },
    { lat: 49.4364267, lng: 18.7068986 },
    { lat: 49.4363885, lng: 18.7071841 },
    { lat: 49.436276, lng: 18.7072516 },
    { lat: 49.4360344, lng: 18.7071421 },
    { lat: 49.4360323, lng: 18.7070797 },
    { lat: 49.4361118, lng: 18.7067652 },
    { lat: 49.4360929, lng: 18.7067466 },
    { lat: 49.4360363, lng: 18.7067209 },
    { lat: 49.4359861, lng: 18.7066695 },
    { lat: 49.4358678, lng: 18.7065637 },
    { lat: 49.4358323, lng: 18.7065931 },
    { lat: 49.4358069, lng: 18.7067129 },
    { lat: 49.4356418, lng: 18.7069401 },
    { lat: 49.4357042, lng: 18.7072067 },
    { lat: 49.4357219, lng: 18.7073106 },
    { lat: 49.4356253, lng: 18.7077048 },
    { lat: 49.4355597, lng: 18.7078741 },
    { lat: 49.4355691, lng: 18.7078816 },
    { lat: 49.4355904, lng: 18.7079011 },
    { lat: 49.4356208, lng: 18.707922 },
    { lat: 49.435636, lng: 18.7079317 },
    { lat: 49.4356258, lng: 18.7081884 },
    { lat: 49.4356086, lng: 18.7084434 },
    { lat: 49.435667, lng: 18.7084797 },
    { lat: 49.4357357, lng: 18.7085238 },
    { lat: 49.4357479, lng: 18.708539 },
    { lat: 49.4357889, lng: 18.7085922 },
    { lat: 49.4358302, lng: 18.7086451 },
    { lat: 49.4358918, lng: 18.7087242 },
    { lat: 49.4356131, lng: 18.7092381 },
    { lat: 49.4354386, lng: 18.7093755 },
    { lat: 49.4353345, lng: 18.7095394 },
    { lat: 49.4352798, lng: 18.7095523 },
    { lat: 49.4352077, lng: 18.7095727 },
    { lat: 49.4351864, lng: 18.7095724 },
    { lat: 49.4353246, lng: 18.7099515 },
    { lat: 49.4351497, lng: 18.7099512 },
    { lat: 49.4350575, lng: 18.7099375 },
    { lat: 49.4350074, lng: 18.7099274 },
    { lat: 49.4349518, lng: 18.7099168 },
    { lat: 49.4349038, lng: 18.7099068 },
    { lat: 49.4349063, lng: 18.7101717 },
    { lat: 49.4349215, lng: 18.710418 },
    { lat: 49.4349262, lng: 18.7105076 },
    { lat: 49.4349752, lng: 18.7105813 },
    { lat: 49.4349807, lng: 18.710772 },
    { lat: 49.4349703, lng: 18.7107689 },
    { lat: 49.4349097, lng: 18.7107531 },
    { lat: 49.4349308, lng: 18.7111147 },
    { lat: 49.4348645, lng: 18.7111177 },
    { lat: 49.4348228, lng: 18.7111203 },
    { lat: 49.4347971, lng: 18.7111213 },
    { lat: 49.4347344, lng: 18.7109555 },
    { lat: 49.4346469, lng: 18.7109677 },
    { lat: 49.4346535, lng: 18.7109868 },
    { lat: 49.4348139, lng: 18.7114642 },
    { lat: 49.434909, lng: 18.7114304 },
    { lat: 49.4349644, lng: 18.7114109 },
    { lat: 49.4349677, lng: 18.711496 },
    { lat: 49.4350319, lng: 18.7115352 },
    { lat: 49.4350365, lng: 18.7115375 },
    { lat: 49.4350678, lng: 18.7115572 },
    { lat: 49.4350824, lng: 18.7115333 },
    { lat: 49.4351228, lng: 18.7114657 },
    { lat: 49.4351354, lng: 18.7114457 },
    { lat: 49.4351645, lng: 18.7114638 },
    { lat: 49.4351726, lng: 18.7114701 },
    { lat: 49.4351893, lng: 18.7115264 },
    { lat: 49.4352042, lng: 18.711576 },
    { lat: 49.4352233, lng: 18.7116164 },
    { lat: 49.4352689, lng: 18.7117115 },
    { lat: 49.435294, lng: 18.7117624 },
    { lat: 49.4351889, lng: 18.7120155 },
    { lat: 49.4350947, lng: 18.7120901 },
    { lat: 49.4350213, lng: 18.7121318 },
    { lat: 49.4349589, lng: 18.7121673 },
    { lat: 49.4349063, lng: 18.7121973 },
    { lat: 49.4348582, lng: 18.7122268 },
    { lat: 49.4347991, lng: 18.7122627 },
    { lat: 49.4347522, lng: 18.7122909 },
    { lat: 49.4347125, lng: 18.7123719 },
    { lat: 49.4346768, lng: 18.712444 },
    { lat: 49.4346514, lng: 18.712496 },
    { lat: 49.4346268, lng: 18.7125458 },
    { lat: 49.434589, lng: 18.7126225 },
    { lat: 49.4346101, lng: 18.7126377 },
    { lat: 49.4347028, lng: 18.712706 },
    { lat: 49.4348881, lng: 18.7128202 },
    { lat: 49.4350997, lng: 18.7128768 },
    { lat: 49.4350866, lng: 18.7129527 },
    { lat: 49.4350536, lng: 18.713139 },
    { lat: 49.4349526, lng: 18.7131463 },
    { lat: 49.4348105, lng: 18.7131257 },
    { lat: 49.4347606, lng: 18.7130985 },
    { lat: 49.434713, lng: 18.7131602 },
    { lat: 49.4346232, lng: 18.7131458 },
    { lat: 49.4346097, lng: 18.7131678 },
    { lat: 49.4345706, lng: 18.7132374 },
    { lat: 49.4345344, lng: 18.7133006 },
    { lat: 49.4348692, lng: 18.7134718 },
    { lat: 49.4348528, lng: 18.7135702 },
    { lat: 49.434825, lng: 18.7137475 },
    { lat: 49.4348037, lng: 18.7138606 },
    { lat: 49.4347855, lng: 18.7139418 },
    { lat: 49.4347741, lng: 18.7139856 },
    { lat: 49.4347433, lng: 18.7140198 },
    { lat: 49.4347348, lng: 18.7140299 },
    { lat: 49.4346888, lng: 18.7140758 },
    { lat: 49.4346512, lng: 18.7141123 },
    { lat: 49.4346137, lng: 18.7141491 },
    { lat: 49.4345427, lng: 18.7140209 },
    { lat: 49.4345365, lng: 18.7140101 },
    { lat: 49.4344979, lng: 18.7139409 },
    { lat: 49.4344382, lng: 18.7138341 },
    { lat: 49.4344735, lng: 18.7137986 },
    { lat: 49.4345013, lng: 18.7137698 },
    { lat: 49.4343984, lng: 18.7135926 },
    { lat: 49.4343581, lng: 18.7136392 },
    { lat: 49.4342878, lng: 18.7137189 },
    { lat: 49.4340141, lng: 18.7138967 },
    { lat: 49.4340162, lng: 18.7139146 },
    { lat: 49.4340269, lng: 18.7140114 },
    { lat: 49.4340536, lng: 18.7142636 },
    { lat: 49.4340645, lng: 18.714364 },
    { lat: 49.4339883, lng: 18.7144386 },
    { lat: 49.4336535, lng: 18.7140736 },
    { lat: 49.4335804, lng: 18.7141612 },
    { lat: 49.4334625, lng: 18.7143032 },
    { lat: 49.4334327, lng: 18.7143384 },
    { lat: 49.4334147, lng: 18.7143601 },
    { lat: 49.4333757, lng: 18.7141519 },
    { lat: 49.4334431, lng: 18.713977 },
    { lat: 49.4333721, lng: 18.713802 },
    { lat: 49.4333063, lng: 18.713751 },
    { lat: 49.4332209, lng: 18.713888 },
    { lat: 49.4332189, lng: 18.7138911 },
    { lat: 49.4332071, lng: 18.7139052 },
    { lat: 49.433153, lng: 18.7139691 },
    { lat: 49.4331251, lng: 18.7139316 },
    { lat: 49.4331177, lng: 18.713922 },
    { lat: 49.4331059, lng: 18.7139075 },
    { lat: 49.4330461, lng: 18.7140156 },
    { lat: 49.4329607, lng: 18.7141311 },
    { lat: 49.4329683, lng: 18.7141469 },
    { lat: 49.4329759, lng: 18.7141608 },
    { lat: 49.4329896, lng: 18.7141881 },
    { lat: 49.433077, lng: 18.7143281 },
    { lat: 49.433106, lng: 18.7144078 },
    { lat: 49.4329973, lng: 18.7144842 },
    { lat: 49.4329075, lng: 18.7144069 },
    { lat: 49.4327929, lng: 18.7143075 },
    { lat: 49.4327858, lng: 18.7142794 },
    { lat: 49.4327787, lng: 18.714248 },
    { lat: 49.4327726, lng: 18.7142227 },
    { lat: 49.4327273, lng: 18.714237 },
    { lat: 49.4326681, lng: 18.7142486 },
    { lat: 49.4325379, lng: 18.7142617 },
    { lat: 49.43251, lng: 18.7142655 },
    { lat: 49.4324491, lng: 18.7142889 },
    { lat: 49.4324184, lng: 18.7143032 },
    { lat: 49.4323735, lng: 18.7143522 },
    { lat: 49.4322895, lng: 18.7145135 },
    { lat: 49.4321408, lng: 18.71486 },
    { lat: 49.4318935, lng: 18.7147893 },
    { lat: 49.4318171, lng: 18.7147737 },
    { lat: 49.4317245, lng: 18.7147702 },
    { lat: 49.4317394, lng: 18.7148166 },
    { lat: 49.4317472, lng: 18.7148392 },
    { lat: 49.431687, lng: 18.7149574 },
    { lat: 49.4316552, lng: 18.7150178 },
    { lat: 49.4316254, lng: 18.7150751 },
    { lat: 49.43144, lng: 18.7154355 },
    { lat: 49.4313868, lng: 18.7155384 },
    { lat: 49.4313368, lng: 18.7156351 },
    { lat: 49.431305, lng: 18.7156963 },
    { lat: 49.4312866, lng: 18.7157326 },
    { lat: 49.4312579, lng: 18.7157883 },
    { lat: 49.4312291, lng: 18.715844 },
    { lat: 49.4310173, lng: 18.716254 },
    { lat: 49.4309855, lng: 18.7163148 },
    { lat: 49.4308053, lng: 18.716664 },
    { lat: 49.4307613, lng: 18.7167491 },
    { lat: 49.4306336, lng: 18.7167883 },
    { lat: 49.4304971, lng: 18.7167442 },
    { lat: 49.4302625, lng: 18.7165919 },
    { lat: 49.4299125, lng: 18.716758 },
    { lat: 49.4297547, lng: 18.7167357 },
    { lat: 49.4295623, lng: 18.7169383 },
    { lat: 49.4293676, lng: 18.7168162 },
    { lat: 49.4284058, lng: 18.7170763 },
    { lat: 49.4283513, lng: 18.7170909 },
    { lat: 49.4282747, lng: 18.7171119 },
    { lat: 49.4281724, lng: 18.7171393 },
    { lat: 49.4281067, lng: 18.7171572 },
    { lat: 49.4281054, lng: 18.7171548 },
    { lat: 49.4280702, lng: 18.7171696 },
    { lat: 49.4280423, lng: 18.7171789 },
    { lat: 49.4279883, lng: 18.7171978 },
    { lat: 49.4278708, lng: 18.7172376 },
    { lat: 49.4275994, lng: 18.7173299 },
    { lat: 49.4271927, lng: 18.7174686 },
    { lat: 49.4268414, lng: 18.7175881 },
    { lat: 49.4267598, lng: 18.7176007 },
    { lat: 49.4267376, lng: 18.7176041 },
    { lat: 49.4267094, lng: 18.7176083 },
    { lat: 49.4266848, lng: 18.7176117 },
    { lat: 49.4264724, lng: 18.7176439 },
    { lat: 49.4261524, lng: 18.7176923 },
    { lat: 49.4260664, lng: 18.7177097 },
    { lat: 49.4257084, lng: 18.717783 },
    { lat: 49.4253725, lng: 18.7178517 },
    { lat: 49.4252923, lng: 18.7178681 },
    { lat: 49.4250031, lng: 18.7178373 },
    { lat: 49.4247455, lng: 18.7178095 },
    { lat: 49.4245809, lng: 18.7178279 },
    { lat: 49.4241538, lng: 18.7178752 },
    { lat: 49.4240147, lng: 18.7178912 },
    { lat: 49.4237934, lng: 18.7179506 },
    { lat: 49.4234275, lng: 18.7180491 },
    { lat: 49.4230629, lng: 18.7181475 },
    { lat: 49.4227013, lng: 18.7182643 },
    { lat: 49.4224929, lng: 18.7183322 },
    { lat: 49.4221099, lng: 18.7182734 },
    { lat: 49.421829, lng: 18.7184647 },
    { lat: 49.4215532, lng: 18.7185815 },
    { lat: 49.4213064, lng: 18.7186277 },
    { lat: 49.4213095, lng: 18.718819 },
    { lat: 49.4210296, lng: 18.718874 },
    { lat: 49.4209292, lng: 18.7188127 },
    { lat: 49.4208099, lng: 18.7188165 },
    { lat: 49.420533, lng: 18.718845 },
    { lat: 49.420165, lng: 18.7188833 },
    { lat: 49.4199123, lng: 18.7187093 },
    { lat: 49.4197316, lng: 18.7185518 },
    { lat: 49.4194883, lng: 18.7188573 },
    { lat: 49.4194628, lng: 18.7188887 },
    { lat: 49.4194333, lng: 18.7189264 },
    { lat: 49.4194174, lng: 18.7189464 },
    { lat: 49.4192937, lng: 18.7191021 },
    { lat: 49.4191529, lng: 18.7192563 },
    { lat: 49.4186296, lng: 18.719431 },
    { lat: 49.4182668, lng: 18.719587 },
    { lat: 49.4180602, lng: 18.719712 },
    { lat: 49.4181451, lng: 18.7207316 },
    { lat: 49.4178444, lng: 18.7212809 },
    { lat: 49.4176264, lng: 18.7217253 },
    { lat: 49.4176061, lng: 18.7217669 },
    { lat: 49.4175142, lng: 18.7219527 },
    { lat: 49.4174686, lng: 18.7220474 },
    { lat: 49.4174504, lng: 18.7223687 },
    { lat: 49.4174152, lng: 18.7224741 },
    { lat: 49.4158317, lng: 18.7213445 },
    { lat: 49.4150542, lng: 18.7191735 },
    { lat: 49.4150216, lng: 18.7191966 },
    { lat: 49.4147696, lng: 18.7194883 },
    { lat: 49.4147543, lng: 18.7195206 },
    { lat: 49.4147218, lng: 18.7195865 },
    { lat: 49.4147086, lng: 18.7196139 },
    { lat: 49.4151163, lng: 18.7204272 },
    { lat: 49.4144765, lng: 18.7212376 },
    { lat: 49.4139182, lng: 18.7219257 },
    { lat: 49.4132871, lng: 18.7229262 },
    { lat: 49.4128365, lng: 18.7234562 },
    { lat: 49.4127729, lng: 18.7235324 },
    { lat: 49.4126869, lng: 18.7236328 },
    { lat: 49.4120651, lng: 18.7239686 },
    { lat: 49.4110881, lng: 18.7239841 },
    { lat: 49.4099269, lng: 18.7246345 },
    { lat: 49.4089855, lng: 18.725805 },
    { lat: 49.4089618, lng: 18.7261487 },
    { lat: 49.4089019, lng: 18.727012 },
    { lat: 49.4092625, lng: 18.7288867 },
    { lat: 49.4093174, lng: 18.7295285 },
    { lat: 49.4093256, lng: 18.7296209 },
    { lat: 49.4094284, lng: 18.730795 },
    { lat: 49.4097542, lng: 18.7318092 },
    { lat: 49.4101824, lng: 18.7330354 },
    { lat: 49.4105653, lng: 18.7348006 },
    { lat: 49.4109089, lng: 18.7368068 },
    { lat: 49.4108605, lng: 18.7383683 },
    { lat: 49.4108058, lng: 18.7402407 },
    { lat: 49.4110535, lng: 18.742012 },
    { lat: 49.4112129, lng: 18.7437546 },
    { lat: 49.4112962, lng: 18.74527 },
    { lat: 49.4115659, lng: 18.7471158 },
    { lat: 49.4115476, lng: 18.7482152 },
    { lat: 49.4113127, lng: 18.7498144 },
    { lat: 49.4112836, lng: 18.7507729 },
    { lat: 49.4113446, lng: 18.7513323 },
    { lat: 49.4113838, lng: 18.7521603 },
    { lat: 49.411453, lng: 18.7534913 },
    { lat: 49.4116941, lng: 18.7545541 },
    { lat: 49.4120808, lng: 18.7560275 },
    { lat: 49.4123495, lng: 18.7568602 },
    { lat: 49.4120988, lng: 18.758764 },
    { lat: 49.4122558, lng: 18.7593355 },
    { lat: 49.4121775, lng: 18.7604756 },
    { lat: 49.4119922, lng: 18.7614035 },
    { lat: 49.4119528, lng: 18.7630592 },
    { lat: 49.4122641, lng: 18.7641239 },
    { lat: 49.4123012, lng: 18.7640358 },
    { lat: 49.4124307, lng: 18.7637267 },
    { lat: 49.4129176, lng: 18.7630282 },
    { lat: 49.4136896, lng: 18.7623472 },
    { lat: 49.4142593, lng: 18.7620696 },
    { lat: 49.4146652, lng: 18.7617232 },
    { lat: 49.4156595, lng: 18.7612652 },
    { lat: 49.4162709, lng: 18.7612904 },
    { lat: 49.4163059, lng: 18.7612915 },
    { lat: 49.4163829, lng: 18.7612948 },
    { lat: 49.4174037, lng: 18.7613917 },
    { lat: 49.4178115, lng: 18.7616253 },
    { lat: 49.4181828, lng: 18.7614536 },
    { lat: 49.4196296, lng: 18.7622208 },
    { lat: 49.4203994, lng: 18.7629318 },
    { lat: 49.4208645, lng: 18.7630574 },
    { lat: 49.4211369, lng: 18.763131 },
    { lat: 49.4216825, lng: 18.7634329 },
    { lat: 49.4217299, lng: 18.7631696 },
    { lat: 49.4217502, lng: 18.7630418 },
    { lat: 49.4217631, lng: 18.7629642 },
    { lat: 49.4217717, lng: 18.7629103 },
    { lat: 49.42178, lng: 18.7628557 },
    { lat: 49.4222882, lng: 18.7629147 },
    { lat: 49.4228755, lng: 18.762891 },
    { lat: 49.423426, lng: 18.7629355 },
    { lat: 49.4235556, lng: 18.7625238 },
    { lat: 49.4238179, lng: 18.7623655 },
    { lat: 49.4241576, lng: 18.7612845 },
    { lat: 49.4238196, lng: 18.7610899 },
    { lat: 49.423379, lng: 18.7604435 },
    { lat: 49.422836, lng: 18.7599732 },
    { lat: 49.4226802, lng: 18.759776 },
    { lat: 49.4224209, lng: 18.7591038 },
    { lat: 49.4219466, lng: 18.7581555 },
    { lat: 49.4224649, lng: 18.7577123 },
    { lat: 49.4226993, lng: 18.7575124 },
    { lat: 49.4230346, lng: 18.7573035 },
    { lat: 49.4231309, lng: 18.7572468 },
    { lat: 49.4236992, lng: 18.7572478 },
    { lat: 49.424881, lng: 18.7572515 },
    { lat: 49.4251267, lng: 18.7573371 },
    { lat: 49.4257145, lng: 18.7575418 },
    { lat: 49.4266328, lng: 18.7577674 },
    { lat: 49.426922, lng: 18.7578216 },
    { lat: 49.4277625, lng: 18.7578094 },
    { lat: 49.4279689, lng: 18.7576802 },
    { lat: 49.4281265, lng: 18.7578255 },
    { lat: 49.4284959, lng: 18.7577728 },
    { lat: 49.4286755, lng: 18.7578238 },
    { lat: 49.4287702, lng: 18.75776 },
    { lat: 49.4288245, lng: 18.7577172 },
    { lat: 49.4290647, lng: 18.7578064 },
    { lat: 49.4291244, lng: 18.7577831 },
    { lat: 49.4292289, lng: 18.7578211 },
    { lat: 49.429276, lng: 18.7577715 },
    { lat: 49.4294014, lng: 18.7578617 },
    { lat: 49.4295601, lng: 18.7578557 },
    { lat: 49.4296875, lng: 18.7580065 },
    { lat: 49.4298131, lng: 18.7578455 },
    { lat: 49.4298911, lng: 18.757869 },
    { lat: 49.4299627, lng: 18.7580559 },
    { lat: 49.4305721, lng: 18.7578468 },
    { lat: 49.4312231, lng: 18.7576801 },
    { lat: 49.4316432, lng: 18.7574749 },
    { lat: 49.4320124, lng: 18.7572227 },
    { lat: 49.4320486, lng: 18.7572052 },
    { lat: 49.4337089, lng: 18.7560442 },
    { lat: 49.4338656, lng: 18.7560008 },
    { lat: 49.4339907, lng: 18.755912 },
    { lat: 49.43417, lng: 18.7558695 },
    { lat: 49.434888, lng: 18.7557333 },
    { lat: 49.4349447, lng: 18.7559148 },
    { lat: 49.4351542, lng: 18.7559943 },
    { lat: 49.4355584, lng: 18.7559617 },
    { lat: 49.4358541, lng: 18.7559684 },
    { lat: 49.4361502, lng: 18.7560464 },
    { lat: 49.4364104, lng: 18.756107 },
    { lat: 49.4366229, lng: 18.7561601 },
    { lat: 49.4369105, lng: 18.7561598 },
    { lat: 49.4371313, lng: 18.7561818 },
    { lat: 49.4375167, lng: 18.7562641 },
    { lat: 49.4378688, lng: 18.7563556 },
    { lat: 49.4379243, lng: 18.7564057 },
    { lat: 49.4379893, lng: 18.7563959 },
    { lat: 49.4380071, lng: 18.7563938 },
    { lat: 49.4380252, lng: 18.7563912 },
    { lat: 49.4383765, lng: 18.7563404 },
    { lat: 49.4386004, lng: 18.756308 },
    { lat: 49.4387571, lng: 18.7562853 },
    { lat: 49.4388488, lng: 18.7562723 },
    { lat: 49.4389787, lng: 18.7562531 },
    { lat: 49.4392011, lng: 18.7562597 },
    { lat: 49.439984, lng: 18.7561794 },
    { lat: 49.4403796, lng: 18.756246 },
    { lat: 49.4406689, lng: 18.7563886 },
    { lat: 49.4410604, lng: 18.7565655 },
    { lat: 49.4412889, lng: 18.7566214 },
    { lat: 49.4413414, lng: 18.7566349 },
    { lat: 49.4415396, lng: 18.7566937 },
    { lat: 49.4416824, lng: 18.7567969 },
    { lat: 49.4417072, lng: 18.7568196 },
    { lat: 49.4417146, lng: 18.7567883 },
    { lat: 49.4417183, lng: 18.7567911 },
    { lat: 49.4417774, lng: 18.7568469 },
    { lat: 49.441782, lng: 18.7568489 },
    { lat: 49.4418856, lng: 18.7569337 },
    { lat: 49.4418902, lng: 18.7569353 },
    { lat: 49.4419236, lng: 18.7569461 },
    { lat: 49.441927, lng: 18.7569479 },
    { lat: 49.4420365, lng: 18.7570139 },
    { lat: 49.4420419, lng: 18.7569897 },
    { lat: 49.4423573, lng: 18.7570023 },
    { lat: 49.442468, lng: 18.7570094 },
    { lat: 49.4430938, lng: 18.7565295 },
    { lat: 49.4435354, lng: 18.7567847 },
    { lat: 49.4435838, lng: 18.756802 },
    { lat: 49.4436204, lng: 18.7568167 },
    { lat: 49.4436553, lng: 18.7568334 },
    { lat: 49.4437012, lng: 18.7568521 },
    { lat: 49.4437418, lng: 18.7568736 },
    { lat: 49.4437845, lng: 18.7568923 },
    { lat: 49.4438261, lng: 18.7569108 },
    { lat: 49.4438673, lng: 18.756921 },
    { lat: 49.4439038, lng: 18.7569314 },
    { lat: 49.443946, lng: 18.7569404 },
    { lat: 49.4439862, lng: 18.7569536 },
    { lat: 49.4440233, lng: 18.7569719 },
    { lat: 49.4440656, lng: 18.7569863 },
    { lat: 49.4440888, lng: 18.7569958 },
    { lat: 49.4441106, lng: 18.7570066 },
    { lat: 49.4441315, lng: 18.7570171 },
    { lat: 49.4441566, lng: 18.7570238 },
    { lat: 49.4441803, lng: 18.757043 },
    { lat: 49.444205, lng: 18.7570624 },
    { lat: 49.4442325, lng: 18.7572015 },
    { lat: 49.4442304, lng: 18.7572075 },
    { lat: 49.4442002, lng: 18.7573178 },
    { lat: 49.444187, lng: 18.7573683 },
    { lat: 49.4441747, lng: 18.7574129 },
    { lat: 49.4441614, lng: 18.7574613 },
    { lat: 49.4441927, lng: 18.7575006 },
    { lat: 49.4442422, lng: 18.7575627 },
    { lat: 49.4442366, lng: 18.7576097 },
    { lat: 49.4442222, lng: 18.7577416 },
    { lat: 49.4442153, lng: 18.757804 },
    { lat: 49.4441968, lng: 18.7579704 },
    { lat: 49.4441898, lng: 18.7580329 },
    { lat: 49.4441846, lng: 18.7580821 },
    { lat: 49.4441757, lng: 18.758152 },
    { lat: 49.4441657, lng: 18.7582416 },
    { lat: 49.4449139, lng: 18.7587968 },
    { lat: 49.4454275, lng: 18.7595705 },
    { lat: 49.446394, lng: 18.7606847 },
    { lat: 49.4473548, lng: 18.7613387 },
    { lat: 49.4480956, lng: 18.7616003 },
    { lat: 49.4490136, lng: 18.7614065 },
    { lat: 49.4494727, lng: 18.7614343 },
    { lat: 49.4497411, lng: 18.7616523 },
    { lat: 49.4507459, lng: 18.7617668 },
    { lat: 49.4512799, lng: 18.7617112 },
    { lat: 49.4520566, lng: 18.7613856 },
    { lat: 49.4538219, lng: 18.7602426 },
    { lat: 49.4540266, lng: 18.7600393 },
    { lat: 49.4540706, lng: 18.7599959 },
    { lat: 49.4541585, lng: 18.7599087 },
    { lat: 49.4542793, lng: 18.7591488 },
    { lat: 49.455307, lng: 18.7572008 },
    { lat: 49.4557654, lng: 18.7556476 },
    { lat: 49.4555432, lng: 18.7543848 },
    { lat: 49.4564714, lng: 18.7537623 },
    { lat: 49.457378, lng: 18.753154 },
    { lat: 49.4576067, lng: 18.7529115 },
    { lat: 49.4576376, lng: 18.7528783 },
    { lat: 49.4594811, lng: 18.7509221 },
    { lat: 49.4595111, lng: 18.7508905 },
    { lat: 49.4595751, lng: 18.7508222 },
    { lat: 49.4599599, lng: 18.7507375 },
    { lat: 49.4599932, lng: 18.7507298 },
    { lat: 49.460816, lng: 18.7505484 },
    { lat: 49.4610044, lng: 18.7502913 },
    { lat: 49.4617816, lng: 18.7497965 },
    { lat: 49.462917, lng: 18.7496259 },
    { lat: 49.4629647, lng: 18.7494573 },
    { lat: 49.4636615, lng: 18.7493888 },
    { lat: 49.4644197, lng: 18.74888 },
    { lat: 49.4647321, lng: 18.7487494 },
    { lat: 49.4655443, lng: 18.7484102 },
    { lat: 49.4662772, lng: 18.7481038 },
    { lat: 49.4665387, lng: 18.747995 },
    { lat: 49.466597, lng: 18.7479705 },
    { lat: 49.4666446, lng: 18.7479501 },
    { lat: 49.4670892, lng: 18.7473484 },
    { lat: 49.4680623, lng: 18.7473958 },
    { lat: 49.46988, lng: 18.7457035 },
    { lat: 49.4698931, lng: 18.7456918 },
    { lat: 49.4699211, lng: 18.7456669 },
    { lat: 49.4708912, lng: 18.7447888 },
    { lat: 49.4725722, lng: 18.7431764 },
    { lat: 49.4735752, lng: 18.7418677 },
    { lat: 49.4736196, lng: 18.7418086 },
    { lat: 49.4753703, lng: 18.7395236 },
    { lat: 49.475381, lng: 18.7395114 },
    { lat: 49.4753999, lng: 18.7394838 },
    { lat: 49.475417, lng: 18.7394686 },
    { lat: 49.475697, lng: 18.7390645 },
    { lat: 49.4765856, lng: 18.7380898 },
    { lat: 49.4766384, lng: 18.7380195 },
    { lat: 49.4767332, lng: 18.7378941 },
    { lat: 49.4767649, lng: 18.7378528 },
    { lat: 49.4768618, lng: 18.7377003 },
    { lat: 49.4769231, lng: 18.737597 },
    { lat: 49.4769605, lng: 18.7375163 },
    { lat: 49.4770187, lng: 18.7373765 },
    { lat: 49.4770727, lng: 18.737244 },
    { lat: 49.4770745, lng: 18.7372369 },
    { lat: 49.4771299, lng: 18.7370662 },
    { lat: 49.4771735, lng: 18.7369289 },
    { lat: 49.4772201, lng: 18.7367843 },
    { lat: 49.4772658, lng: 18.7366406 },
    { lat: 49.4773104, lng: 18.7365021 },
    { lat: 49.4773306, lng: 18.7364368 },
    { lat: 49.4773677, lng: 18.7363235 },
    { lat: 49.477385, lng: 18.7362645 },
    { lat: 49.4775196, lng: 18.7358187 },
    { lat: 49.4776668, lng: 18.7353318 },
    { lat: 49.4777774, lng: 18.7349252 },
    { lat: 49.4778673, lng: 18.734594 },
    { lat: 49.4780597, lng: 18.7342143 },
    { lat: 49.4780933, lng: 18.7341475 },
    { lat: 49.4781688, lng: 18.7339729 },
    { lat: 49.4782232, lng: 18.7338476 },
    { lat: 49.4782775, lng: 18.7337235 },
    { lat: 49.478351, lng: 18.7335103 },
    { lat: 49.4784227, lng: 18.7333032 },
    { lat: 49.4787327, lng: 18.7328503 },
    { lat: 49.4788061, lng: 18.7327431 },
    { lat: 49.4789347, lng: 18.7326356 },
    { lat: 49.4791822, lng: 18.732429 },
    { lat: 49.4794231, lng: 18.7322272 },
    { lat: 49.479515, lng: 18.7321507 },
    { lat: 49.4795614, lng: 18.7321113 },
    { lat: 49.479609, lng: 18.7320717 },
    { lat: 49.4796856, lng: 18.7320272 },
    { lat: 49.4797787, lng: 18.7319726 },
    { lat: 49.4798367, lng: 18.7319387 },
    { lat: 49.4798946, lng: 18.731904 },
    { lat: 49.4800389, lng: 18.73182 },
    { lat: 49.480287, lng: 18.7320793 },
    { lat: 49.4803238, lng: 18.7323725 },
    { lat: 49.4805271, lng: 18.7322304 },
    { lat: 49.480701, lng: 18.7323444 },
    { lat: 49.4807357, lng: 18.7323608 },
    { lat: 49.4807583, lng: 18.7320545 },
    { lat: 49.4808935, lng: 18.7319883 },
    { lat: 49.4810197, lng: 18.7320522 },
    { lat: 49.4811073, lng: 18.7321967 },
    { lat: 49.4813536, lng: 18.7321839 },
    { lat: 49.4812802, lng: 18.7320098 },
    { lat: 49.4813154, lng: 18.7318861 },
    { lat: 49.4813421, lng: 18.7318361 },
    { lat: 49.4814645, lng: 18.7317819 },
    { lat: 49.4816231, lng: 18.731711 },
    { lat: 49.4819284, lng: 18.7315753 },
    { lat: 49.4820036, lng: 18.7316136 },
    { lat: 49.4820486, lng: 18.7313919 },
    { lat: 49.4821403, lng: 18.7314659 },
    { lat: 49.4823031, lng: 18.7313654 },
    { lat: 49.4824441, lng: 18.7312781 },
    { lat: 49.4824968, lng: 18.7311596 },
    { lat: 49.4825701, lng: 18.7309947 },
    { lat: 49.4826857, lng: 18.730855 },
    { lat: 49.4827356, lng: 18.7307057 },
    { lat: 49.4828439, lng: 18.7305354 },
    { lat: 49.4828888, lng: 18.7304849 },
    { lat: 49.4828605, lng: 18.7302911 },
    { lat: 49.4828691, lng: 18.7302839 },
    { lat: 49.4828909, lng: 18.7302671 },
    { lat: 49.4829721, lng: 18.7302016 },
    { lat: 49.4830831, lng: 18.7301913 },
    { lat: 49.4832756, lng: 18.7300766 },
    { lat: 49.4835298, lng: 18.7298906 },
    { lat: 49.4835768, lng: 18.7296669 },
    { lat: 49.4836146, lng: 18.7294632 },
    { lat: 49.4836704, lng: 18.7295579 },
    { lat: 49.4837372, lng: 18.7295442 },
    { lat: 49.4838087, lng: 18.7294009 },
    { lat: 49.4838135, lng: 18.7292524 },
    { lat: 49.4839345, lng: 18.7291088 },
    { lat: 49.483984, lng: 18.7291285 },
    { lat: 49.4840324, lng: 18.7289321 },
    { lat: 49.4841697, lng: 18.7287967 },
    { lat: 49.4841773, lng: 18.7286398 },
    { lat: 49.4842891, lng: 18.7285579 },
    { lat: 49.4843348, lng: 18.7283739 },
    { lat: 49.4843003, lng: 18.7282737 },
    { lat: 49.4845624, lng: 18.7279358 },
    { lat: 49.4845533, lng: 18.7278506 },
    { lat: 49.4846887, lng: 18.7276331 },
    { lat: 49.4847926, lng: 18.7275105 },
    { lat: 49.4848979, lng: 18.7273634 },
    { lat: 49.4849607, lng: 18.7272088 },
    { lat: 49.4851445, lng: 18.7272301 },
    { lat: 49.4851841, lng: 18.7270559 },
    { lat: 49.485383, lng: 18.727001 },
    { lat: 49.4854229, lng: 18.727032 },
    { lat: 49.4854641, lng: 18.7270201 },
    { lat: 49.4854581, lng: 18.7267956 },
    { lat: 49.4854056, lng: 18.7267411 },
    { lat: 49.4854401, lng: 18.7266687 },
    { lat: 49.4856043, lng: 18.7267048 },
    { lat: 49.485688, lng: 18.7265142 },
    { lat: 49.4858251, lng: 18.7265928 },
    { lat: 49.4858883, lng: 18.7264857 },
    { lat: 49.4858733, lng: 18.7264338 },
    { lat: 49.4859905, lng: 18.7261932 },
    { lat: 49.4860849, lng: 18.7262773 },
    { lat: 49.4862982, lng: 18.7262359 },
    { lat: 49.4864045, lng: 18.7261141 },
    { lat: 49.4863511, lng: 18.7258221 },
    { lat: 49.4865378, lng: 18.7257517 },
    { lat: 49.486731, lng: 18.7257128 },
    { lat: 49.4868553, lng: 18.7258041 },
    { lat: 49.4869115, lng: 18.7257178 },
    { lat: 49.4868435, lng: 18.7254512 },
    { lat: 49.4869733, lng: 18.7254067 },
    { lat: 49.4870908, lng: 18.7252403 },
    { lat: 49.487049, lng: 18.7251101 },
    { lat: 49.4869458, lng: 18.7249658 },
    { lat: 49.4869994, lng: 18.724743 },
    { lat: 49.4869228, lng: 18.7246774 },
    { lat: 49.4869509, lng: 18.7245466 },
    { lat: 49.4870561, lng: 18.7243795 },
    { lat: 49.48714, lng: 18.724412 },
    { lat: 49.4872264, lng: 18.7242281 },
    { lat: 49.4871704, lng: 18.7241472 },
    { lat: 49.4873675, lng: 18.7237984 },
    { lat: 49.4875027, lng: 18.7237546 },
    { lat: 49.4875046, lng: 18.7239176 },
    { lat: 49.4876671, lng: 18.7240082 },
    { lat: 49.4877053, lng: 18.723811 },
    { lat: 49.4877179, lng: 18.7235769 },
    { lat: 49.4877363, lng: 18.7234757 },
    { lat: 49.4877363, lng: 18.7232809 },
    { lat: 49.4877558, lng: 18.7231349 },
    { lat: 49.4877818, lng: 18.7227661 },
    { lat: 49.4877906, lng: 18.7227075 },
    { lat: 49.4878886, lng: 18.7220515 },
    { lat: 49.4882071, lng: 18.721669 },
    { lat: 49.4882964, lng: 18.7215662 },
    { lat: 49.4884423, lng: 18.7212052 },
    { lat: 49.4884949, lng: 18.7210265 },
    { lat: 49.488479, lng: 18.7207219 },
    { lat: 49.4884379, lng: 18.7205177 },
    { lat: 49.488733, lng: 18.7204042 },
    { lat: 49.4888512, lng: 18.7204002 },
    { lat: 49.4889231, lng: 18.7204784 },
    { lat: 49.4890329, lng: 18.7205088 },
    { lat: 49.4891666, lng: 18.7203919 },
    { lat: 49.4891032, lng: 18.7203435 },
    { lat: 49.4890901, lng: 18.7202646 },
    { lat: 49.4891987, lng: 18.7202096 },
    { lat: 49.4892696, lng: 18.7201417 },
    { lat: 49.4894061, lng: 18.7200354 },
    { lat: 49.4895327, lng: 18.7198903 },
    { lat: 49.4895242, lng: 18.7197677 },
    { lat: 49.4894251, lng: 18.7197715 },
    { lat: 49.4892585, lng: 18.7197749 },
    { lat: 49.4891872, lng: 18.7197311 },
    { lat: 49.4891955, lng: 18.7195861 },
    { lat: 49.4892934, lng: 18.7195839 },
    { lat: 49.4893848, lng: 18.7195589 },
    { lat: 49.4894432, lng: 18.7193381 },
    { lat: 49.4894977, lng: 18.7191322 },
    { lat: 49.4896555, lng: 18.7190635 },
    { lat: 49.4897227, lng: 18.7190342 },
    { lat: 49.4898267, lng: 18.7191252 },
    { lat: 49.48985, lng: 18.7191825 },
    { lat: 49.4897973, lng: 18.7192318 },
    { lat: 49.4898217, lng: 18.7192691 },
    { lat: 49.4898605, lng: 18.7193212 },
    { lat: 49.4899404, lng: 18.7192305 },
    { lat: 49.4899361, lng: 18.7192982 },
    { lat: 49.4899483, lng: 18.7193373 },
    { lat: 49.4900339, lng: 18.7192709 },
    { lat: 49.4900553, lng: 18.719314 },
    { lat: 49.4902059, lng: 18.7192621 },
    { lat: 49.4902071, lng: 18.719266 },
    { lat: 49.4902215, lng: 18.7193016 },
    { lat: 49.4903255, lng: 18.7192434 },
    { lat: 49.4903347, lng: 18.7192438 },
    { lat: 49.4904114, lng: 18.719247 },
    { lat: 49.4904218, lng: 18.7193371 },
    { lat: 49.4904807, lng: 18.7193205 },
    { lat: 49.4905217, lng: 18.7194137 },
    { lat: 49.4905714, lng: 18.7194824 },
    { lat: 49.490671, lng: 18.7194679 },
    { lat: 49.4907769, lng: 18.7195732 },
    { lat: 49.4908879, lng: 18.7195621 },
    { lat: 49.4909457, lng: 18.7196108 },
    { lat: 49.4909676, lng: 18.7195552 },
    { lat: 49.491016, lng: 18.7195768 },
    { lat: 49.4909852, lng: 18.7196981 },
    { lat: 49.4910211, lng: 18.7197612 },
    { lat: 49.4911339, lng: 18.719826 },
    { lat: 49.491221, lng: 18.719766 },
    { lat: 49.4913317, lng: 18.719768 },
    { lat: 49.491368, lng: 18.7198154 },
    { lat: 49.4914472, lng: 18.7197581 },
    { lat: 49.4915203, lng: 18.7197093 },
    { lat: 49.4915913, lng: 18.7198155 },
    { lat: 49.4915866, lng: 18.7199202 },
    { lat: 49.4916853, lng: 18.7199327 },
    { lat: 49.4917103, lng: 18.7198502 },
    { lat: 49.4917632, lng: 18.7198735 },
    { lat: 49.4918247, lng: 18.7198387 },
    { lat: 49.4918604, lng: 18.7199373 },
    { lat: 49.4918957, lng: 18.7199889 },
    { lat: 49.4918756, lng: 18.7200385 },
    { lat: 49.4918949, lng: 18.7200836 },
    { lat: 49.4919633, lng: 18.7200995 },
    { lat: 49.4920133, lng: 18.7200201 },
    { lat: 49.4920895, lng: 18.7200089 },
    { lat: 49.4921131, lng: 18.7200728 },
    { lat: 49.4921728, lng: 18.7200698 },
    { lat: 49.4922716, lng: 18.7200221 },
    { lat: 49.4923878, lng: 18.719956 },
    { lat: 49.4924083, lng: 18.7198747 },
    { lat: 49.4923186, lng: 18.7198615 },
    { lat: 49.4923509, lng: 18.7197889 },
    { lat: 49.4924125, lng: 18.7196504 },
    { lat: 49.4925064, lng: 18.7195486 },
    { lat: 49.4925417, lng: 18.7194456 },
    { lat: 49.4926223, lng: 18.7193294 },
    { lat: 49.4927889, lng: 18.7190612 },
    { lat: 49.4929037, lng: 18.7189705 },
    { lat: 49.4930404, lng: 18.7188685 },
    { lat: 49.4947431, lng: 18.7169051 },
    { lat: 49.4955174, lng: 18.7160122 },
    { lat: 49.4956479, lng: 18.7154426 },
    { lat: 49.4960209, lng: 18.7153115 },
    { lat: 49.4963176, lng: 18.7152286 },
    { lat: 49.4964081, lng: 18.7151917 },
    { lat: 49.4964876, lng: 18.7151573 },
    { lat: 49.4966625, lng: 18.7150733 },
    { lat: 49.4967426, lng: 18.7150319 },
    { lat: 49.4968621, lng: 18.714986 },
    { lat: 49.4969763, lng: 18.7149731 },
    { lat: 49.4971271, lng: 18.7149654 },
    { lat: 49.497506, lng: 18.714945 },
    { lat: 49.4978415, lng: 18.7142895 },
    { lat: 49.4980418, lng: 18.7141691 },
    { lat: 49.4984012, lng: 18.7141024 },
    { lat: 49.4992179, lng: 18.7133309 },
    { lat: 49.50161, lng: 18.71301 },
    { lat: 49.501772, lng: 18.712773 },
    { lat: 49.502252, lng: 18.711953 },
    { lat: 49.502275, lng: 18.711611 },
    { lat: 49.502312, lng: 18.711417 },
    { lat: 49.502364, lng: 18.711219 },
    { lat: 49.502472, lng: 18.710617 },
    { lat: 49.502511, lng: 18.710409 },
    { lat: 49.502503, lng: 18.710189 },
    { lat: 49.502399, lng: 18.709439 },
    { lat: 49.502342, lng: 18.708968 },
    { lat: 49.502291, lng: 18.708541 },
    { lat: 49.502277, lng: 18.708318 },
    { lat: 49.502175, lng: 18.707978 },
    { lat: 49.502145, lng: 18.707775 },
    { lat: 49.502053, lng: 18.70747 },
    { lat: 49.502015, lng: 18.707236 },
    { lat: 49.502015, lng: 18.707043 },
    { lat: 49.502003, lng: 18.706983 },
    { lat: 49.501892, lng: 18.706306 },
    { lat: 49.5018756, lng: 18.7061933 },
    { lat: 49.501624, lng: 18.704465 },
    { lat: 49.501368, lng: 18.704049 },
    { lat: 49.501363, lng: 18.703394 },
    { lat: 49.501359, lng: 18.702829 },
    { lat: 49.501358, lng: 18.702491 },
    { lat: 49.501407, lng: 18.702137 },
    { lat: 49.501509, lng: 18.701396 },
    { lat: 49.501188, lng: 18.700274 },
    { lat: 49.500883, lng: 18.699605 },
    { lat: 49.500595, lng: 18.699332 },
    { lat: 49.500483, lng: 18.699228 },
    { lat: 49.500219, lng: 18.69896 },
    { lat: 49.499856, lng: 18.69808 },
    { lat: 49.499662, lng: 18.69776 },
    { lat: 49.4996409, lng: 18.6976753 },
  ],
  Skalité: [
    { lat: 49.484581, lng: 18.963152 },
    { lat: 49.485947, lng: 18.963285 },
    { lat: 49.486439, lng: 18.963422 },
    { lat: 49.486813, lng: 18.963489 },
    { lat: 49.487459, lng: 18.963606 },
    { lat: 49.487936, lng: 18.963826 },
    { lat: 49.4885677, lng: 18.9646057 },
    { lat: 49.488749, lng: 18.964823 },
    { lat: 49.489421, lng: 18.965101 },
    { lat: 49.490255, lng: 18.96512 },
    { lat: 49.490856, lng: 18.965507 },
    { lat: 49.491123, lng: 18.965684 },
    { lat: 49.491941, lng: 18.966102 },
    { lat: 49.4928147, lng: 18.9661999 },
    { lat: 49.493092, lng: 18.966231 },
    { lat: 49.494109, lng: 18.967335 },
    { lat: 49.494604, lng: 18.968058 },
    { lat: 49.495362, lng: 18.970237 },
    { lat: 49.496094, lng: 18.971597 },
    { lat: 49.496405, lng: 18.972727 },
    { lat: 49.496555, lng: 18.973122 },
    { lat: 49.496807, lng: 18.973333 },
    { lat: 49.497861, lng: 18.973143 },
    { lat: 49.49795, lng: 18.973211 },
    { lat: 49.498772, lng: 18.972708 },
    { lat: 49.499145, lng: 18.972672 },
    { lat: 49.499518, lng: 18.97269 },
    { lat: 49.500064, lng: 18.971984 },
    { lat: 49.500833, lng: 18.971755 },
    { lat: 49.50114, lng: 18.971848 },
    { lat: 49.501279, lng: 18.972084 },
    { lat: 49.50136, lng: 18.97224 },
    { lat: 49.501661, lng: 18.972456 },
    { lat: 49.501929, lng: 18.972561 },
    { lat: 49.502144, lng: 18.972756 },
    { lat: 49.502413, lng: 18.972827 },
    { lat: 49.502608, lng: 18.972772 },
    { lat: 49.503016, lng: 18.972355 },
    { lat: 49.503319, lng: 18.972115 },
    { lat: 49.503542, lng: 18.972071 },
    { lat: 49.503723, lng: 18.972012 },
    { lat: 49.5038875, lng: 18.9719174 },
    { lat: 49.503977, lng: 18.97186 },
    { lat: 49.504336, lng: 18.971646 },
    { lat: 49.504447, lng: 18.971181 },
    { lat: 49.504616, lng: 18.97071 },
    { lat: 49.505099, lng: 18.969773 },
    { lat: 49.505836, lng: 18.968999 },
    { lat: 49.506354, lng: 18.968231 },
    { lat: 49.506035, lng: 18.96669 },
    { lat: 49.505605, lng: 18.965899 },
    { lat: 49.505957, lng: 18.965018 },
    { lat: 49.506496, lng: 18.964186 },
    { lat: 49.50701, lng: 18.963924 },
    { lat: 49.507469, lng: 18.963568 },
    { lat: 49.507749, lng: 18.96343 },
    { lat: 49.5081675, lng: 18.9630913 },
    { lat: 49.5082718, lng: 18.9629947 },
    { lat: 49.5087934, lng: 18.9626329 },
    { lat: 49.5088512, lng: 18.962628 },
    { lat: 49.5089229, lng: 18.9626002 },
    { lat: 49.5091005, lng: 18.9622689 },
    { lat: 49.509477, lng: 18.961505 },
    { lat: 49.509664, lng: 18.961107 },
    { lat: 49.509773, lng: 18.960457 },
    { lat: 49.50984, lng: 18.959633 },
    { lat: 49.509965, lng: 18.958727 },
    { lat: 49.510199, lng: 18.95828 },
    { lat: 49.510397, lng: 18.958082 },
    { lat: 49.510754, lng: 18.957339 },
    { lat: 49.51096, lng: 18.956898 },
    { lat: 49.511192, lng: 18.956237 },
    { lat: 49.511418, lng: 18.956043 },
    { lat: 49.512047, lng: 18.955355 },
    { lat: 49.512392, lng: 18.954989 },
    { lat: 49.512579, lng: 18.954709 },
    { lat: 49.5127, lng: 18.95306 },
    { lat: 49.512764, lng: 18.951797 },
    { lat: 49.51254, lng: 18.950413 },
    { lat: 49.512623, lng: 18.949175 },
    { lat: 49.512652, lng: 18.9488009 },
    { lat: 49.513374, lng: 18.947993 },
    { lat: 49.514143, lng: 18.947597 },
    { lat: 49.515063, lng: 18.947064 },
    { lat: 49.515893, lng: 18.946691 },
    { lat: 49.516528, lng: 18.946582 },
    { lat: 49.5165615, lng: 18.9466095 },
    { lat: 49.51742, lng: 18.946319 },
    { lat: 49.517583, lng: 18.945675 },
    { lat: 49.517968, lng: 18.943987 },
    { lat: 49.518324, lng: 18.943083 },
    { lat: 49.518682, lng: 18.942202 },
    { lat: 49.5189, lng: 18.941662 },
    { lat: 49.518872, lng: 18.941224 },
    { lat: 49.518839, lng: 18.940063 },
    { lat: 49.518809, lng: 18.939238 },
    { lat: 49.51873, lng: 18.937521 },
    { lat: 49.518435, lng: 18.935526 },
    { lat: 49.518928, lng: 18.934114 },
    { lat: 49.519202, lng: 18.933033 },
    { lat: 49.519553, lng: 18.932118 },
    { lat: 49.519858, lng: 18.93132 },
    { lat: 49.519946, lng: 18.930851 },
    { lat: 49.519806, lng: 18.93028 },
    { lat: 49.5195741, lng: 18.9300196 },
    { lat: 49.519457, lng: 18.929896 },
    { lat: 49.518977, lng: 18.92904 },
    { lat: 49.518717, lng: 18.927915 },
    { lat: 49.518697, lng: 18.926316 },
    { lat: 49.518577, lng: 18.924877 },
    { lat: 49.518496, lng: 18.924097 },
    { lat: 49.518448, lng: 18.922889 },
    { lat: 49.518439, lng: 18.922667 },
    { lat: 49.518398, lng: 18.922121 },
    { lat: 49.518308, lng: 18.921021 },
    { lat: 49.518277, lng: 18.920568 },
    { lat: 49.51822, lng: 18.92021 },
    { lat: 49.518115, lng: 18.919472 },
    { lat: 49.51801, lng: 18.917976 },
    { lat: 49.517881, lng: 18.916699 },
    { lat: 49.517685, lng: 18.914534 },
    { lat: 49.517683, lng: 18.913961 },
    { lat: 49.517623, lng: 18.913185 },
    { lat: 49.517574, lng: 18.91279 },
    { lat: 49.517365, lng: 18.912179 },
    { lat: 49.51699, lng: 18.911114 },
    { lat: 49.516637, lng: 18.909382 },
    { lat: 49.51652, lng: 18.90883 },
    { lat: 49.5163, lng: 18.908138 },
    { lat: 49.516174, lng: 18.907146 },
    { lat: 49.51605, lng: 18.906407 },
    { lat: 49.515855, lng: 18.905466 },
    { lat: 49.515682, lng: 18.904721 },
    { lat: 49.51556, lng: 18.904207 },
    { lat: 49.51549, lng: 18.903816 },
    { lat: 49.515382, lng: 18.903331 },
    { lat: 49.515243, lng: 18.902602 },
    { lat: 49.515132, lng: 18.901451 },
    { lat: 49.515029, lng: 18.90077 },
    { lat: 49.514923, lng: 18.89998 },
    { lat: 49.514874, lng: 18.899635 },
    { lat: 49.514852, lng: 18.899018 },
    { lat: 49.514815, lng: 18.898426 },
    { lat: 49.514786, lng: 18.89821 },
    { lat: 49.514681, lng: 18.897932 },
    { lat: 49.514549, lng: 18.897607 },
    { lat: 49.514421, lng: 18.897157 },
    { lat: 49.514458, lng: 18.896356 },
    { lat: 49.514522, lng: 18.895838 },
    { lat: 49.514667, lng: 18.895408 },
    { lat: 49.514853, lng: 18.894527 },
    { lat: 49.514886, lng: 18.894166 },
    { lat: 49.514934, lng: 18.893973 },
    { lat: 49.515078, lng: 18.893342 },
    { lat: 49.515137, lng: 18.893082 },
    { lat: 49.515292, lng: 18.892184 },
    { lat: 49.515404, lng: 18.891834 },
    { lat: 49.515667, lng: 18.891013 },
    { lat: 49.5158257, lng: 18.8903029 },
    { lat: 49.5157951, lng: 18.8895975 },
    { lat: 49.515829, lng: 18.8894837 },
    { lat: 49.5158947, lng: 18.889504 },
    { lat: 49.5158903, lng: 18.8894547 },
    { lat: 49.5158911, lng: 18.8894061 },
    { lat: 49.51596, lng: 18.8892187 },
    { lat: 49.515963, lng: 18.889106 },
    { lat: 49.51603, lng: 18.888901 },
    { lat: 49.516113, lng: 18.888528 },
    { lat: 49.516182, lng: 18.888291 },
    { lat: 49.516212, lng: 18.888172 },
    { lat: 49.516271, lng: 18.887892 },
    { lat: 49.516384, lng: 18.887459 },
    { lat: 49.516515, lng: 18.887105 },
    { lat: 49.516694, lng: 18.886727 },
    { lat: 49.516691, lng: 18.886689 },
    { lat: 49.516603, lng: 18.885702 },
    { lat: 49.516618, lng: 18.884658 },
    { lat: 49.516458, lng: 18.883529 },
    { lat: 49.516253, lng: 18.882158 },
    { lat: 49.516146, lng: 18.880647 },
    { lat: 49.516077, lng: 18.87969 },
    { lat: 49.515981, lng: 18.878793 },
    { lat: 49.515891, lng: 18.877826 },
    { lat: 49.515897, lng: 18.877512 },
    { lat: 49.5159188, lng: 18.8760768 },
    { lat: 49.515905, lng: 18.875982 },
    { lat: 49.515916, lng: 18.875927 },
    { lat: 49.515942, lng: 18.875899 },
    { lat: 49.51593, lng: 18.875791 },
    { lat: 49.515947, lng: 18.875727 },
    { lat: 49.515878, lng: 18.875653 },
    { lat: 49.515856, lng: 18.875571 },
    { lat: 49.515868, lng: 18.875497 },
    { lat: 49.515942, lng: 18.87541 },
    { lat: 49.516042, lng: 18.875302 },
    { lat: 49.516123, lng: 18.875162 },
    { lat: 49.516135, lng: 18.875101 },
    { lat: 49.516112, lng: 18.875061 },
    { lat: 49.516069, lng: 18.875069 },
    { lat: 49.516045, lng: 18.875086 },
    { lat: 49.5160237, lng: 18.8750452 },
    { lat: 49.516028, lng: 18.874993 },
    { lat: 49.516084, lng: 18.874852 },
    { lat: 49.516106, lng: 18.874592 },
    { lat: 49.516168, lng: 18.874551 },
    { lat: 49.516317, lng: 18.874622 },
    { lat: 49.51642, lng: 18.874734 },
    { lat: 49.516462, lng: 18.874699 },
    { lat: 49.51649, lng: 18.874655 },
    { lat: 49.516547, lng: 18.87444 },
    { lat: 49.516595, lng: 18.873983 },
    { lat: 49.516613, lng: 18.873852 },
    { lat: 49.516647, lng: 18.873681 },
    { lat: 49.516693, lng: 18.873575 },
    { lat: 49.516724, lng: 18.873573 },
    { lat: 49.516749, lng: 18.873665 },
    { lat: 49.516756, lng: 18.87378 },
    { lat: 49.516783, lng: 18.873817 },
    { lat: 49.516971, lng: 18.873586 },
    { lat: 49.516992, lng: 18.873447 },
    { lat: 49.516991, lng: 18.873367 },
    { lat: 49.517035, lng: 18.873327 },
    { lat: 49.517188, lng: 18.873297 },
    { lat: 49.51719, lng: 18.873102 },
    { lat: 49.517129, lng: 18.872961 },
    { lat: 49.517033, lng: 18.872755 },
    { lat: 49.517085, lng: 18.87261 },
    { lat: 49.517047, lng: 18.872432 },
    { lat: 49.516918, lng: 18.872327 },
    { lat: 49.516839, lng: 18.872172 },
    { lat: 49.516756, lng: 18.872048 },
    { lat: 49.516741, lng: 18.871917 },
    { lat: 49.516835, lng: 18.871926 },
    { lat: 49.516898, lng: 18.871897 },
    { lat: 49.516959, lng: 18.871912 },
    { lat: 49.51699, lng: 18.87184 },
    { lat: 49.516918, lng: 18.871423 },
    { lat: 49.516998, lng: 18.871332 },
    { lat: 49.517307, lng: 18.871185 },
    { lat: 49.517428, lng: 18.871287 },
    { lat: 49.517639, lng: 18.871129 },
    { lat: 49.517733, lng: 18.870853 },
    { lat: 49.517766, lng: 18.870693 },
    { lat: 49.517813, lng: 18.870625 },
    { lat: 49.517888, lng: 18.870476 },
    { lat: 49.518014, lng: 18.870479 },
    { lat: 49.518119, lng: 18.870568 },
    { lat: 49.518172, lng: 18.870539 },
    { lat: 49.518166, lng: 18.870429 },
    { lat: 49.518175, lng: 18.870272 },
    { lat: 49.518189, lng: 18.870119 },
    { lat: 49.518233, lng: 18.870027 },
    { lat: 49.518273, lng: 18.870184 },
    { lat: 49.518324, lng: 18.870072 },
    { lat: 49.518327, lng: 18.869977 },
    { lat: 49.518427, lng: 18.869863 },
    { lat: 49.518427, lng: 18.870012 },
    { lat: 49.518534, lng: 18.870019 },
    { lat: 49.518569, lng: 18.869999 },
    { lat: 49.518601, lng: 18.869743 },
    { lat: 49.518665, lng: 18.86962 },
    { lat: 49.518732, lng: 18.869292 },
    { lat: 49.518833, lng: 18.869079 },
    { lat: 49.518916, lng: 18.869138 },
    { lat: 49.519002, lng: 18.869067 },
    { lat: 49.519004, lng: 18.869031 },
    { lat: 49.51911, lng: 18.868934 },
    { lat: 49.519048, lng: 18.868889 },
    { lat: 49.51906, lng: 18.868699 },
    { lat: 49.519136, lng: 18.868366 },
    { lat: 49.519218, lng: 18.868055 },
    { lat: 49.519307, lng: 18.867783 },
    { lat: 49.519348, lng: 18.86756 },
    { lat: 49.519345, lng: 18.867483 },
    { lat: 49.519316, lng: 18.867342 },
    { lat: 49.5192678, lng: 18.8671713 },
    { lat: 49.5192294, lng: 18.8670583 },
    { lat: 49.519215, lng: 18.8670626 },
    { lat: 49.5191882, lng: 18.8670691 },
    { lat: 49.5189578, lng: 18.867129 },
    { lat: 49.5181413, lng: 18.8675415 },
    { lat: 49.5171749, lng: 18.867849 },
    { lat: 49.5165024, lng: 18.8681237 },
    { lat: 49.5158123, lng: 18.8684757 },
    { lat: 49.5155784, lng: 18.868532 },
    { lat: 49.5152466, lng: 18.8686121 },
    { lat: 49.5146495, lng: 18.8689083 },
    { lat: 49.5142502, lng: 18.8691064 },
    { lat: 49.5139657, lng: 18.8695846 },
    { lat: 49.5139107, lng: 18.8696768 },
    { lat: 49.5136155, lng: 18.8701719 },
    { lat: 49.5132651, lng: 18.8698402 },
    { lat: 49.5130564, lng: 18.8696624 },
    { lat: 49.5128103, lng: 18.8695192 },
    { lat: 49.5127894, lng: 18.8695071 },
    { lat: 49.5124035, lng: 18.8692831 },
    { lat: 49.5122631, lng: 18.869202 },
    { lat: 49.5116881, lng: 18.8688675 },
    { lat: 49.5112073, lng: 18.8687691 },
    { lat: 49.5110352, lng: 18.8687339 },
    { lat: 49.5106666, lng: 18.8684446 },
    { lat: 49.5106009, lng: 18.8683926 },
    { lat: 49.5104306, lng: 18.8682582 },
    { lat: 49.5104129, lng: 18.8682447 },
    { lat: 49.510343, lng: 18.868244 },
    { lat: 49.5102933, lng: 18.8682198 },
    { lat: 49.5100715, lng: 18.8683132 },
    { lat: 49.5099374, lng: 18.8683144 },
    { lat: 49.5097806, lng: 18.8682936 },
    { lat: 49.5097376, lng: 18.8682886 },
    { lat: 49.5097023, lng: 18.868282 },
    { lat: 49.5096602, lng: 18.8682751 },
    { lat: 49.5096044, lng: 18.8682836 },
    { lat: 49.50957, lng: 18.8682953 },
    { lat: 49.5093648, lng: 18.868338 },
    { lat: 49.5091855, lng: 18.8683853 },
    { lat: 49.5091428, lng: 18.8684089 },
    { lat: 49.5090602, lng: 18.8684489 },
    { lat: 49.509023, lng: 18.8684704 },
    { lat: 49.5088166, lng: 18.8685786 },
    { lat: 49.5086519, lng: 18.8686658 },
    { lat: 49.5083581, lng: 18.8688295 },
    { lat: 49.5078538, lng: 18.8691109 },
    { lat: 49.5074757, lng: 18.8692803 },
    { lat: 49.5072507, lng: 18.8693323 },
    { lat: 49.5071853, lng: 18.8693528 },
    { lat: 49.5063565, lng: 18.8695929 },
    { lat: 49.506271, lng: 18.8696637 },
    { lat: 49.5061812, lng: 18.8697361 },
    { lat: 49.506075, lng: 18.8698229 },
    { lat: 49.5053745, lng: 18.8700588 },
    { lat: 49.5043, lng: 18.8703672 },
    { lat: 49.5041, lng: 18.8703146 },
    { lat: 49.5029243, lng: 18.8700028 },
    { lat: 49.50197, lng: 18.8699495 },
    { lat: 49.5005112, lng: 18.8697725 },
    { lat: 49.4995233, lng: 18.8697636 },
    { lat: 49.4992151, lng: 18.8696688 },
    { lat: 49.4980731, lng: 18.8697146 },
    { lat: 49.4976826, lng: 18.8696396 },
    { lat: 49.4966329, lng: 18.8694382 },
    { lat: 49.495642, lng: 18.8694162 },
    { lat: 49.4951991, lng: 18.8695298 },
    { lat: 49.4938729, lng: 18.8696742 },
    { lat: 49.4934359, lng: 18.8697067 },
    { lat: 49.4933876, lng: 18.8697103 },
    { lat: 49.4931462, lng: 18.8697283 },
    { lat: 49.4922816, lng: 18.8699534 },
    { lat: 49.4921427, lng: 18.8699896 },
    { lat: 49.4917089, lng: 18.8699516 },
    { lat: 49.4911392, lng: 18.8699017 },
    { lat: 49.4907789, lng: 18.8700185 },
    { lat: 49.4901244, lng: 18.8702296 },
    { lat: 49.4893543, lng: 18.8704064 },
    { lat: 49.4885236, lng: 18.8706085 },
    { lat: 49.4884394, lng: 18.8706171 },
    { lat: 49.4880063, lng: 18.8708336 },
    { lat: 49.4872552, lng: 18.8713624 },
    { lat: 49.486696, lng: 18.8717561 },
    { lat: 49.4865228, lng: 18.8722109 },
    { lat: 49.4859316, lng: 18.872645 },
    { lat: 49.4852242, lng: 18.8728331 },
    { lat: 49.484886, lng: 18.8729232 },
    { lat: 49.4845033, lng: 18.8730905 },
    { lat: 49.4838504, lng: 18.8731295 },
    { lat: 49.4837066, lng: 18.8731177 },
    { lat: 49.4836647, lng: 18.8731144 },
    { lat: 49.4825755, lng: 18.8730233 },
    { lat: 49.4820618, lng: 18.8728552 },
    { lat: 49.4818623, lng: 18.8727399 },
    { lat: 49.4816819, lng: 18.8728315 },
    { lat: 49.4814746, lng: 18.8726801 },
    { lat: 49.4812346, lng: 18.8727915 },
    { lat: 49.4807453, lng: 18.8727674 },
    { lat: 49.480494, lng: 18.872792 },
    { lat: 49.4801488, lng: 18.8724331 },
    { lat: 49.4797137, lng: 18.8728568 },
    { lat: 49.4793322, lng: 18.8726897 },
    { lat: 49.4788954, lng: 18.872726 },
    { lat: 49.477895, lng: 18.8727414 },
    { lat: 49.4774791, lng: 18.8726091 },
    { lat: 49.4764291, lng: 18.8723788 },
    { lat: 49.4759992, lng: 18.872394 },
    { lat: 49.475632, lng: 18.8722894 },
    { lat: 49.4747136, lng: 18.8723438 },
    { lat: 49.4746217, lng: 18.8723052 },
    { lat: 49.4744546, lng: 18.8722353 },
    { lat: 49.4739681, lng: 18.8728228 },
    { lat: 49.4734399, lng: 18.8730086 },
    { lat: 49.472465, lng: 18.8733921 },
    { lat: 49.4721232, lng: 18.8736113 },
    { lat: 49.4716631, lng: 18.8739426 },
    { lat: 49.4717438, lng: 18.8745543 },
    { lat: 49.4717838, lng: 18.8750306 },
    { lat: 49.4717745, lng: 18.8755595 },
    { lat: 49.4716468, lng: 18.8764814 },
    { lat: 49.4716569, lng: 18.8766792 },
    { lat: 49.4717705, lng: 18.8781164 },
    { lat: 49.4714777, lng: 18.8782827 },
    { lat: 49.4712656, lng: 18.8784084 },
    { lat: 49.4712, lng: 18.8784282 },
    { lat: 49.4712805, lng: 18.8791491 },
    { lat: 49.4711883, lng: 18.8792877 },
    { lat: 49.4710676, lng: 18.8795718 },
    { lat: 49.4709428, lng: 18.8798014 },
    { lat: 49.4708243, lng: 18.880087 },
    { lat: 49.4707532, lng: 18.8800005 },
    { lat: 49.4702498, lng: 18.8812523 },
    { lat: 49.4703298, lng: 18.8817158 },
    { lat: 49.4697772, lng: 18.8826217 },
    { lat: 49.4700383, lng: 18.883061 },
    { lat: 49.469976, lng: 18.8833414 },
    { lat: 49.4699762, lng: 18.8837695 },
    { lat: 49.4700606, lng: 18.8840773 },
    { lat: 49.47031, lng: 18.8844811 },
    { lat: 49.4706594, lng: 18.8847464 },
    { lat: 49.471115, lng: 18.8854808 },
    { lat: 49.4712673, lng: 18.8859081 },
    { lat: 49.4713554, lng: 18.8861321 },
    { lat: 49.47151, lng: 18.886358 },
    { lat: 49.4717301, lng: 18.8867196 },
    { lat: 49.4718095, lng: 18.8869055 },
    { lat: 49.4718828, lng: 18.8871316 },
    { lat: 49.4722865, lng: 18.8880854 },
    { lat: 49.472579, lng: 18.888831 },
    { lat: 49.4731147, lng: 18.8892354 },
    { lat: 49.4735371, lng: 18.8894967 },
    { lat: 49.47412, lng: 18.889653 },
    { lat: 49.4741627, lng: 18.8904726 },
    { lat: 49.474158, lng: 18.8909814 },
    { lat: 49.4741506, lng: 18.8918785 },
    { lat: 49.4739799, lng: 18.8925858 },
    { lat: 49.4735547, lng: 18.8942265 },
    { lat: 49.4734516, lng: 18.8946162 },
    { lat: 49.4734216, lng: 18.8950918 },
    { lat: 49.4731416, lng: 18.8958846 },
    { lat: 49.4729045, lng: 18.8965245 },
    { lat: 49.4728852, lng: 18.8966993 },
    { lat: 49.4726921, lng: 18.8972708 },
    { lat: 49.4726695, lng: 18.897698 },
    { lat: 49.4726887, lng: 18.8990341 },
    { lat: 49.4727639, lng: 18.9002497 },
    { lat: 49.4724565, lng: 18.9007478 },
    { lat: 49.4722026, lng: 18.9014121 },
    { lat: 49.4717904, lng: 18.9024914 },
    { lat: 49.471544, lng: 18.9029662 },
    { lat: 49.471307, lng: 18.9035643 },
    { lat: 49.4710851, lng: 18.9039917 },
    { lat: 49.4708912, lng: 18.904573 },
    { lat: 49.4708113, lng: 18.9054217 },
    { lat: 49.47086, lng: 18.9068299 },
    { lat: 49.4708667, lng: 18.9077883 },
    { lat: 49.4711296, lng: 18.9082188 },
    { lat: 49.471928, lng: 18.9085596 },
    { lat: 49.4728112, lng: 18.909124 },
    { lat: 49.4729481, lng: 18.9093129 },
    { lat: 49.4732093, lng: 18.909864 },
    { lat: 49.4726821, lng: 18.9109218 },
    { lat: 49.4719878, lng: 18.9135607 },
    { lat: 49.4718634, lng: 18.9137296 },
    { lat: 49.4719351, lng: 18.9142572 },
    { lat: 49.4718333, lng: 18.9148087 },
    { lat: 49.4718539, lng: 18.915147 },
    { lat: 49.471689, lng: 18.9152771 },
    { lat: 49.4719533, lng: 18.9157358 },
    { lat: 49.4721145, lng: 18.9163608 },
    { lat: 49.4721994, lng: 18.9163199 },
    { lat: 49.472397, lng: 18.9184065 },
    { lat: 49.4734427, lng: 18.919782 },
    { lat: 49.4733582, lng: 18.9202506 },
    { lat: 49.4733726, lng: 18.9204287 },
    { lat: 49.473386, lng: 18.9205119 },
    { lat: 49.4730151, lng: 18.9209613 },
    { lat: 49.4729604, lng: 18.9210392 },
    { lat: 49.4727816, lng: 18.9212685 },
    { lat: 49.4723345, lng: 18.9220393 },
    { lat: 49.4720298, lng: 18.9226392 },
    { lat: 49.4719403, lng: 18.9228089 },
    { lat: 49.4718832, lng: 18.9229734 },
    { lat: 49.4717833, lng: 18.9231154 },
    { lat: 49.4715222, lng: 18.9233251 },
    { lat: 49.4712229, lng: 18.9237847 },
    { lat: 49.4711626, lng: 18.923885 },
    { lat: 49.4710731, lng: 18.9255319 },
    { lat: 49.4710147, lng: 18.9258919 },
    { lat: 49.4708356, lng: 18.9263419 },
    { lat: 49.4707783, lng: 18.9269302 },
    { lat: 49.4707184, lng: 18.9270576 },
    { lat: 49.4706816, lng: 18.9272943 },
    { lat: 49.4706891, lng: 18.9277563 },
    { lat: 49.4706047, lng: 18.9286327 },
    { lat: 49.4704628, lng: 18.9310966 },
    { lat: 49.4706805, lng: 18.9329122 },
    { lat: 49.4711578, lng: 18.9351629 },
    { lat: 49.4712904, lng: 18.9354708 },
    { lat: 49.4712817, lng: 18.935565 },
    { lat: 49.4712829, lng: 18.9360354 },
    { lat: 49.4714775, lng: 18.9368566 },
    { lat: 49.4714561, lng: 18.9372622 },
    { lat: 49.4715706, lng: 18.9375658 },
    { lat: 49.4716948, lng: 18.9380158 },
    { lat: 49.4717445, lng: 18.9380882 },
    { lat: 49.4718404, lng: 18.938493 },
    { lat: 49.4718732, lng: 18.938604 },
    { lat: 49.4720356, lng: 18.9391003 },
    { lat: 49.4720077, lng: 18.9398717 },
    { lat: 49.4721264, lng: 18.9400563 },
    { lat: 49.4724908, lng: 18.9400168 },
    { lat: 49.472862, lng: 18.9400889 },
    { lat: 49.4729772, lng: 18.9401169 },
    { lat: 49.4731572, lng: 18.9401108 },
    { lat: 49.4734089, lng: 18.9400713 },
    { lat: 49.4734768, lng: 18.9406149 },
    { lat: 49.4736379, lng: 18.941422 },
    { lat: 49.4737381, lng: 18.9419546 },
    { lat: 49.4738564, lng: 18.9427631 },
    { lat: 49.4738051, lng: 18.9431564 },
    { lat: 49.473765, lng: 18.9434776 },
    { lat: 49.4736594, lng: 18.9438685 },
    { lat: 49.473639, lng: 18.9446046 },
    { lat: 49.4736452, lng: 18.9446387 },
    { lat: 49.473744, lng: 18.9450594 },
    { lat: 49.4738087, lng: 18.9452932 },
    { lat: 49.4738237, lng: 18.9456579 },
    { lat: 49.47381, lng: 18.9462838 },
    { lat: 49.4738581, lng: 18.9465692 },
    { lat: 49.4739493, lng: 18.9468575 },
    { lat: 49.4740509, lng: 18.94713 },
    { lat: 49.4743269, lng: 18.947532 },
    { lat: 49.4745571, lng: 18.9478738 },
    { lat: 49.4747444, lng: 18.9481453 },
    { lat: 49.4748825, lng: 18.948315 },
    { lat: 49.4749311, lng: 18.9491278 },
    { lat: 49.4750954, lng: 18.950061 },
    { lat: 49.4751378, lng: 18.9502344 },
    { lat: 49.4752402, lng: 18.9509277 },
    { lat: 49.4752819, lng: 18.9514451 },
    { lat: 49.4752908, lng: 18.9514659 },
    { lat: 49.4757035, lng: 18.9519384 },
    { lat: 49.4758016, lng: 18.9520997 },
    { lat: 49.4759772, lng: 18.952675 },
    { lat: 49.4761036, lng: 18.9531045 },
    { lat: 49.476306, lng: 18.9537922 },
    { lat: 49.4766528, lng: 18.954933 },
    { lat: 49.4770026, lng: 18.9558365 },
    { lat: 49.4779943, lng: 18.957813 },
    { lat: 49.4787122, lng: 18.9587114 },
    { lat: 49.4790871, lng: 18.95931 },
    { lat: 49.4792284, lng: 18.9597675 },
    { lat: 49.4803014, lng: 18.9612705 },
    { lat: 49.4805224, lng: 18.9618108 },
    { lat: 49.4808509, lng: 18.9622491 },
    { lat: 49.4813357, lng: 18.9632887 },
    { lat: 49.481396, lng: 18.963322 },
    { lat: 49.4818844, lng: 18.9627425 },
    { lat: 49.482268, lng: 18.961823 },
    { lat: 49.48275, lng: 18.960451 },
    { lat: 49.483592, lng: 18.961781 },
    { lat: 49.484581, lng: 18.963152 },
  ],
  StaráBystrica: [
    { lat: 49.401027, lng: 18.972275 },
    { lat: 49.401471, lng: 18.971805 },
    { lat: 49.401552, lng: 18.971721 },
    { lat: 49.401799, lng: 18.97146 },
    { lat: 49.402063, lng: 18.971181 },
    { lat: 49.402183, lng: 18.971316 },
    { lat: 49.402475, lng: 18.971645 },
    { lat: 49.403255, lng: 18.9724 },
    { lat: 49.403868, lng: 18.97272 },
    { lat: 49.404329, lng: 18.972962 },
    { lat: 49.405078, lng: 18.973094 },
    { lat: 49.405257, lng: 18.973125 },
    { lat: 49.405409, lng: 18.973128 },
    { lat: 49.406132, lng: 18.973138 },
    { lat: 49.406938, lng: 18.97315 },
    { lat: 49.407255, lng: 18.972748 },
    { lat: 49.407377, lng: 18.972593 },
    { lat: 49.408226, lng: 18.971522 },
    { lat: 49.409325, lng: 18.969599 },
    { lat: 49.409731, lng: 18.969338 },
    { lat: 49.409993, lng: 18.969168 },
    { lat: 49.4098587, lng: 18.9685242 },
    { lat: 49.4085955, lng: 18.9650034 },
    { lat: 49.407871, lng: 18.9632782 },
    { lat: 49.4070094, lng: 18.9633141 },
    { lat: 49.4059462, lng: 18.9609068 },
    { lat: 49.4052963, lng: 18.9589138 },
    { lat: 49.4051094, lng: 18.9586972 },
    { lat: 49.4049392, lng: 18.9587173 },
    { lat: 49.4042684, lng: 18.9580224 },
    { lat: 49.4042108, lng: 18.9576838 },
    { lat: 49.4042753, lng: 18.9561837 },
    { lat: 49.4025811, lng: 18.953509 },
    { lat: 49.402165, lng: 18.9531262 },
    { lat: 49.4018444, lng: 18.9525801 },
    { lat: 49.3973877, lng: 18.9490464 },
    { lat: 49.3970698, lng: 18.9493637 },
    { lat: 49.3965963, lng: 18.9499864 },
    { lat: 49.3969972, lng: 18.9513691 },
    { lat: 49.3971756, lng: 18.9529026 },
    { lat: 49.3970471, lng: 18.954296 },
    { lat: 49.3968415, lng: 18.9546 },
    { lat: 49.3968356, lng: 18.9551121 },
    { lat: 49.3966821, lng: 18.9562768 },
    { lat: 49.3943239, lng: 18.9549311 },
    { lat: 49.3934964, lng: 18.955692 },
    { lat: 49.3936352, lng: 18.956035 },
    { lat: 49.3932558, lng: 18.956919 },
    { lat: 49.3923077, lng: 18.9577491 },
    { lat: 49.3912481, lng: 18.9582882 },
    { lat: 49.3906672, lng: 18.9595621 },
    { lat: 49.3900747, lng: 18.9586718 },
    { lat: 49.3898728, lng: 18.958067 },
    { lat: 49.3897124, lng: 18.9557648 },
    { lat: 49.3899269, lng: 18.9551905 },
    { lat: 49.3901759, lng: 18.9539072 },
    { lat: 49.3871138, lng: 18.9516088 },
    { lat: 49.3871046, lng: 18.9512473 },
    { lat: 49.3871531, lng: 18.9509575 },
    { lat: 49.3870383, lng: 18.9500179 },
    { lat: 49.3867914, lng: 18.9492317 },
    { lat: 49.3865781, lng: 18.9488469 },
    { lat: 49.3861198, lng: 18.9478027 },
    { lat: 49.3858912, lng: 18.9469351 },
    { lat: 49.3856656, lng: 18.9464852 },
    { lat: 49.3855509, lng: 18.9460877 },
    { lat: 49.3853457, lng: 18.9445161 },
    { lat: 49.3853704, lng: 18.9441094 },
    { lat: 49.3855369, lng: 18.9436544 },
    { lat: 49.3859461, lng: 18.9429602 },
    { lat: 49.3862396, lng: 18.9421175 },
    { lat: 49.3865038, lng: 18.9406497 },
    { lat: 49.3865531, lng: 18.9397008 },
    { lat: 49.3865261, lng: 18.9395694 },
    { lat: 49.3866578, lng: 18.9366353 },
    { lat: 49.386805, lng: 18.9360205 },
    { lat: 49.3875001, lng: 18.9338384 },
    { lat: 49.3876433, lng: 18.932654 },
    { lat: 49.3873417, lng: 18.9312357 },
    { lat: 49.3872641, lng: 18.931124 },
    { lat: 49.386967, lng: 18.9311349 },
    { lat: 49.3861933, lng: 18.9302349 },
    { lat: 49.3857495, lng: 18.929348 },
    { lat: 49.3852742, lng: 18.9290005 },
    { lat: 49.3851372, lng: 18.9287438 },
    { lat: 49.3848694, lng: 18.9285718 },
    { lat: 49.3846672, lng: 18.9285436 },
    { lat: 49.3843473, lng: 18.9280981 },
    { lat: 49.3842144, lng: 18.9277031 },
    { lat: 49.3839399, lng: 18.9274866 },
    { lat: 49.3839, lng: 18.9272775 },
    { lat: 49.383522, lng: 18.9270284 },
    { lat: 49.3828131, lng: 18.9260695 },
    { lat: 49.3824832, lng: 18.9250277 },
    { lat: 49.3824068, lng: 18.9241423 },
    { lat: 49.3812509, lng: 18.9219653 },
    { lat: 49.3804774, lng: 18.9213387 },
    { lat: 49.3794109, lng: 18.9211612 },
    { lat: 49.3790702, lng: 18.9210684 },
    { lat: 49.3788457, lng: 18.9211732 },
    { lat: 49.3787417, lng: 18.9211685 },
    { lat: 49.3777595, lng: 18.9202953 },
    { lat: 49.3768832, lng: 18.9195251 },
    { lat: 49.3763789, lng: 18.9190975 },
    { lat: 49.3757951, lng: 18.9184981 },
    { lat: 49.3756483, lng: 18.9183777 },
    { lat: 49.3753233, lng: 18.9183105 },
    { lat: 49.3750604, lng: 18.9182353 },
    { lat: 49.3746644, lng: 18.9182765 },
    { lat: 49.3742991, lng: 18.918297 },
    { lat: 49.3732883, lng: 18.9178127 },
    { lat: 49.3725489, lng: 18.917459 },
    { lat: 49.3721802, lng: 18.9171688 },
    { lat: 49.371819, lng: 18.9170058 },
    { lat: 49.3715093, lng: 18.9168981 },
    { lat: 49.3713111, lng: 18.9168153 },
    { lat: 49.3706122, lng: 18.9163262 },
    { lat: 49.3702084, lng: 18.9159425 },
    { lat: 49.369764, lng: 18.9158469 },
    { lat: 49.3693803, lng: 18.9155531 },
    { lat: 49.3690374, lng: 18.915504 },
    { lat: 49.3688029, lng: 18.9155007 },
    { lat: 49.368659, lng: 18.9154198 },
    { lat: 49.3683479, lng: 18.9151781 },
    { lat: 49.3680155, lng: 18.915024 },
    { lat: 49.3673519, lng: 18.9144954 },
    { lat: 49.3668292, lng: 18.9141233 },
    { lat: 49.3666159, lng: 18.9140317 },
    { lat: 49.3654741, lng: 18.9138561 },
    { lat: 49.3649496, lng: 18.9137208 },
    { lat: 49.3643389, lng: 18.9135241 },
    { lat: 49.3630426, lng: 18.9129245 },
    { lat: 49.3626784, lng: 18.9126599 },
    { lat: 49.3618504, lng: 18.9118665 },
    { lat: 49.3615559, lng: 18.9115282 },
    { lat: 49.3605562, lng: 18.9100402 },
    { lat: 49.3595577, lng: 18.9083807 },
    { lat: 49.3592851, lng: 18.9079613 },
    { lat: 49.3589665, lng: 18.9075264 },
    { lat: 49.3586424, lng: 18.9071566 },
    { lat: 49.3578637, lng: 18.906028 },
    { lat: 49.3577764, lng: 18.9057505 },
    { lat: 49.3565453, lng: 18.9036613 },
    { lat: 49.3564332, lng: 18.9034312 },
    { lat: 49.3559437, lng: 18.9029599 },
    { lat: 49.3559076, lng: 18.9028025 },
    { lat: 49.3558278, lng: 18.90256 },
    { lat: 49.3551575, lng: 18.901619 },
    { lat: 49.3550168, lng: 18.9015323 },
    { lat: 49.3533518, lng: 18.8997571 },
    { lat: 49.3515438, lng: 18.8978283 },
    { lat: 49.3515149, lng: 18.8977247 },
    { lat: 49.3514933, lng: 18.8977005 },
    { lat: 49.3514313, lng: 18.8976334 },
    { lat: 49.3514168, lng: 18.8976174 },
    { lat: 49.3512527, lng: 18.8974474 },
    { lat: 49.3512361, lng: 18.897432 },
    { lat: 49.3512054, lng: 18.8974042 },
    { lat: 49.3511914, lng: 18.8973921 },
    { lat: 49.3510642, lng: 18.8974 },
    { lat: 49.3503758, lng: 18.896716 },
    { lat: 49.3498935, lng: 18.8959467 },
    { lat: 49.3492153, lng: 18.8949971 },
    { lat: 49.3489506, lng: 18.8945824 },
    { lat: 49.3489225, lng: 18.8945398 },
    { lat: 49.3487474, lng: 18.8942656 },
    { lat: 49.3485232, lng: 18.8939567 },
    { lat: 49.3483447, lng: 18.8939512 },
    { lat: 49.3481887, lng: 18.8937669 },
    { lat: 49.3478326, lng: 18.8935709 },
    { lat: 49.3476247, lng: 18.8935606 },
    { lat: 49.3472267, lng: 18.8931393 },
    { lat: 49.3469573, lng: 18.8930484 },
    { lat: 49.3464954, lng: 18.8925874 },
    { lat: 49.3457963, lng: 18.892275 },
    { lat: 49.3456367, lng: 18.8920637 },
    { lat: 49.3455019, lng: 18.8920747 },
    { lat: 49.344771, lng: 18.8917777 },
    { lat: 49.3437884, lng: 18.8913664 },
    { lat: 49.3429208, lng: 18.8905335 },
    { lat: 49.3425337, lng: 18.8902148 },
    { lat: 49.3419761, lng: 18.8900682 },
    { lat: 49.3416698, lng: 18.8892767 },
    { lat: 49.3416046, lng: 18.8889703 },
    { lat: 49.3414018, lng: 18.8888842 },
    { lat: 49.3405243, lng: 18.8873252 },
    { lat: 49.3399153, lng: 18.8870991 },
    { lat: 49.339894, lng: 18.88705 },
    { lat: 49.339766, lng: 18.887239 },
    { lat: 49.339374, lng: 18.887556 },
    { lat: 49.339222, lng: 18.887759 },
    { lat: 49.338588, lng: 18.889255 },
    { lat: 49.338408, lng: 18.890033 },
    { lat: 49.338149, lng: 18.891604 },
    { lat: 49.33768, lng: 18.892547 },
    { lat: 49.337449, lng: 18.893234 },
    { lat: 49.337433, lng: 18.893805 },
    { lat: 49.337297, lng: 18.894528 },
    { lat: 49.337167, lng: 18.894558 },
    { lat: 49.336843, lng: 18.894424 },
    { lat: 49.336579, lng: 18.894459 },
    { lat: 49.335895, lng: 18.894364 },
    { lat: 49.335066, lng: 18.894526 },
    { lat: 49.334554, lng: 18.894738 },
    { lat: 49.334141, lng: 18.894782 },
    { lat: 49.332854, lng: 18.895149 },
    { lat: 49.332143, lng: 18.895666 },
    { lat: 49.331275, lng: 18.895994 },
    { lat: 49.330836, lng: 18.896025 },
    { lat: 49.32965, lng: 18.896468 },
    { lat: 49.328475, lng: 18.895531 },
    { lat: 49.327771, lng: 18.89481 },
    { lat: 49.327249, lng: 18.894554 },
    { lat: 49.326627, lng: 18.894398 },
    { lat: 49.32549, lng: 18.893435 },
    { lat: 49.325201, lng: 18.8937 },
    { lat: 49.324972, lng: 18.89332 },
    { lat: 49.324437, lng: 18.89276 },
    { lat: 49.323896, lng: 18.892645 },
    { lat: 49.323587, lng: 18.892761 },
    { lat: 49.322914, lng: 18.893241 },
    { lat: 49.321794, lng: 18.893062 },
    { lat: 49.32121, lng: 18.892528 },
    { lat: 49.320698, lng: 18.89291 },
    { lat: 49.3203064, lng: 18.8930773 },
    { lat: 49.3203486, lng: 18.8977091 },
    { lat: 49.3206106, lng: 18.8996302 },
    { lat: 49.3204862, lng: 18.9008662 },
    { lat: 49.3190301, lng: 18.9055434 },
    { lat: 49.3191011, lng: 18.905694 },
    { lat: 49.3185407, lng: 18.9062054 },
    { lat: 49.319755, lng: 18.9090308 },
    { lat: 49.3194731, lng: 18.9101889 },
    { lat: 49.3194329, lng: 18.9110422 },
    { lat: 49.3193176, lng: 18.9112146 },
    { lat: 49.3191139, lng: 18.9123977 },
    { lat: 49.3192849, lng: 18.9159508 },
    { lat: 49.319699, lng: 18.9154944 },
    { lat: 49.3202401, lng: 18.9163826 },
    { lat: 49.3211773, lng: 18.9171718 },
    { lat: 49.321581, lng: 18.916948 },
    { lat: 49.3220814, lng: 18.9171681 },
    { lat: 49.3228088, lng: 18.9176855 },
    { lat: 49.3230155, lng: 18.9184752 },
    { lat: 49.3232664, lng: 18.9185731 },
    { lat: 49.3241209, lng: 18.9198207 },
    { lat: 49.3242871, lng: 18.9203461 },
    { lat: 49.3251508, lng: 18.9212854 },
    { lat: 49.3257521, lng: 18.9221182 },
    { lat: 49.3267893, lng: 18.9226059 },
    { lat: 49.3272669, lng: 18.9231324 },
    { lat: 49.3282458, lng: 18.9238547 },
    { lat: 49.3281786, lng: 18.9240871 },
    { lat: 49.3310178, lng: 18.9257714 },
    { lat: 49.3310685, lng: 18.9257705 },
    { lat: 49.3317797, lng: 18.9268795 },
    { lat: 49.3321597, lng: 18.9274621 },
    { lat: 49.3323729, lng: 18.9278909 },
    { lat: 49.3324324, lng: 18.9280635 },
    { lat: 49.3324894, lng: 18.9282748 },
    { lat: 49.3326435, lng: 18.92887 },
    { lat: 49.3327323, lng: 18.9291783 },
    { lat: 49.3328277, lng: 18.9295046 },
    { lat: 49.3329246, lng: 18.9297954 },
    { lat: 49.3330332, lng: 18.9302488 },
    { lat: 49.3331185, lng: 18.9305529 },
    { lat: 49.3331693, lng: 18.9308671 },
    { lat: 49.3332471, lng: 18.9316087 },
    { lat: 49.3332997, lng: 18.9319763 },
    { lat: 49.3334528, lng: 18.9323096 },
    { lat: 49.3335231, lng: 18.9330325 },
    { lat: 49.3335903, lng: 18.9336937 },
    { lat: 49.3336279, lng: 18.9339249 },
    { lat: 49.3337378, lng: 18.934272 },
    { lat: 49.3338644, lng: 18.9344537 },
    { lat: 49.3339102, lng: 18.9345799 },
    { lat: 49.3339052, lng: 18.9346835 },
    { lat: 49.3342156, lng: 18.9349346 },
    { lat: 49.3343338, lng: 18.9351809 },
    { lat: 49.3343502, lng: 18.9352158 },
    { lat: 49.3344299, lng: 18.9353629 },
    { lat: 49.3344553, lng: 18.9354637 },
    { lat: 49.3344772, lng: 18.9355406 },
    { lat: 49.3345075, lng: 18.9356502 },
    { lat: 49.3345213, lng: 18.9356988 },
    { lat: 49.334535, lng: 18.9357503 },
    { lat: 49.334565, lng: 18.9358292 },
    { lat: 49.3345806, lng: 18.9358714 },
    { lat: 49.3346048, lng: 18.9361046 },
    { lat: 49.3346418, lng: 18.9362575 },
    { lat: 49.3346667, lng: 18.9363941 },
    { lat: 49.3346714, lng: 18.9364178 },
    { lat: 49.3346865, lng: 18.936515 },
    { lat: 49.334712, lng: 18.9367126 },
    { lat: 49.3347015, lng: 18.9367945 },
    { lat: 49.3346988, lng: 18.9369184 },
    { lat: 49.3346985, lng: 18.9369774 },
    { lat: 49.334705, lng: 18.9370175 },
    { lat: 49.3347346, lng: 18.9371778 },
    { lat: 49.3347497, lng: 18.9372577 },
    { lat: 49.334836, lng: 18.93731 },
    { lat: 49.3349053, lng: 18.9373221 },
    { lat: 49.3351267, lng: 18.9373971 },
    { lat: 49.3351551, lng: 18.9374039 },
    { lat: 49.3356991, lng: 18.9375721 },
    { lat: 49.3357798, lng: 18.9376306 },
    { lat: 49.3357837, lng: 18.9376427 },
    { lat: 49.335874, lng: 18.9376863 },
    { lat: 49.3360246, lng: 18.9377448 },
    { lat: 49.3363498, lng: 18.9378221 },
    { lat: 49.3364645, lng: 18.9378378 },
    { lat: 49.3365019, lng: 18.9378678 },
    { lat: 49.3366898, lng: 18.937949 },
    { lat: 49.3368304, lng: 18.9379653 },
    { lat: 49.3368601, lng: 18.9379733 },
    { lat: 49.3369515, lng: 18.9379961 },
    { lat: 49.3370236, lng: 18.9380144 },
    { lat: 49.3370908, lng: 18.938031 },
    { lat: 49.3371359, lng: 18.9380319 },
    { lat: 49.3371743, lng: 18.9380328 },
    { lat: 49.3375611, lng: 18.9380348 },
    { lat: 49.3376512, lng: 18.9380086 },
    { lat: 49.3376877, lng: 18.9379978 },
    { lat: 49.3381944, lng: 18.9378519 },
    { lat: 49.3382856, lng: 18.9378061 },
    { lat: 49.3384122, lng: 18.9377434 },
    { lat: 49.3385803, lng: 18.9375866 },
    { lat: 49.3386382, lng: 18.9375316 },
    { lat: 49.338682, lng: 18.9374824 },
    { lat: 49.3387409, lng: 18.9374407 },
    { lat: 49.3387898, lng: 18.9373626 },
    { lat: 49.3389135, lng: 18.9372887 },
    { lat: 49.3391842, lng: 18.9369986 },
    { lat: 49.339886, lng: 18.935897 },
    { lat: 49.340407, lng: 18.9352476 },
    { lat: 49.3406694, lng: 18.9349328 },
    { lat: 49.3408267, lng: 18.9344308 },
    { lat: 49.3409325, lng: 18.9342708 },
    { lat: 49.3411966, lng: 18.9339032 },
    { lat: 49.3412874, lng: 18.9338904 },
    { lat: 49.3413634, lng: 18.9339787 },
    { lat: 49.3413427, lng: 18.9341601 },
    { lat: 49.3415637, lng: 18.9344387 },
    { lat: 49.3415774, lng: 18.9346747 },
    { lat: 49.3414986, lng: 18.9355828 },
    { lat: 49.3416528, lng: 18.9362931 },
    { lat: 49.341953, lng: 18.9374968 },
    { lat: 49.3418413, lng: 18.938038 },
    { lat: 49.3419039, lng: 18.9386845 },
    { lat: 49.3418068, lng: 18.9394254 },
    { lat: 49.3414302, lng: 18.940339 },
    { lat: 49.3413499, lng: 18.9412053 },
    { lat: 49.3410498, lng: 18.9441782 },
    { lat: 49.3405913, lng: 18.9454159 },
    { lat: 49.3405219, lng: 18.9477115 },
    { lat: 49.3401218, lng: 18.9510507 },
    { lat: 49.3402191, lng: 18.9533439 },
    { lat: 49.3402166, lng: 18.9545521 },
    { lat: 49.3402214, lng: 18.9560352 },
    { lat: 49.3400994, lng: 18.9567946 },
    { lat: 49.3402052, lng: 18.9583628 },
    { lat: 49.3405425, lng: 18.95951 },
    { lat: 49.3408758, lng: 18.9605817 },
    { lat: 49.3409135, lng: 18.960925 },
    { lat: 49.3410128, lng: 18.9618284 },
    { lat: 49.3406123, lng: 18.9615306 },
    { lat: 49.339046, lng: 18.9617217 },
    { lat: 49.3388913, lng: 18.9613776 },
    { lat: 49.3385728, lng: 18.9610528 },
    { lat: 49.3382675, lng: 18.960899 },
    { lat: 49.3378184, lng: 18.9608896 },
    { lat: 49.3374455, lng: 18.9612783 },
    { lat: 49.3372873, lng: 18.9610486 },
    { lat: 49.3367787, lng: 18.9616503 },
    { lat: 49.3359262, lng: 18.9631234 },
    { lat: 49.3350182, lng: 18.9631821 },
    { lat: 49.3347303, lng: 18.9650374 },
    { lat: 49.3318056, lng: 18.9633663 },
    { lat: 49.3311079, lng: 18.9624478 },
    { lat: 49.3299338, lng: 18.9621445 },
    { lat: 49.3292588, lng: 18.9623547 },
    { lat: 49.3291456, lng: 18.9631064 },
    { lat: 49.3291194, lng: 18.9637099 },
    { lat: 49.3292329, lng: 18.9640829 },
    { lat: 49.329344, lng: 18.9647251 },
    { lat: 49.3298205, lng: 18.9652764 },
    { lat: 49.3303046, lng: 18.9661199 },
    { lat: 49.3302849, lng: 18.9664428 },
    { lat: 49.3300715, lng: 18.9665958 },
    { lat: 49.3288183, lng: 18.9671431 },
    { lat: 49.3276605, lng: 18.9674341 },
    { lat: 49.327844, lng: 18.9679887 },
    { lat: 49.327613, lng: 18.9686436 },
    { lat: 49.3274447, lng: 18.9699638 },
    { lat: 49.326834, lng: 18.9702357 },
    { lat: 49.3267247, lng: 18.9701726 },
    { lat: 49.3261113, lng: 18.9692685 },
    { lat: 49.3257971, lng: 18.9683086 },
    { lat: 49.3256833, lng: 18.9676595 },
    { lat: 49.3255357, lng: 18.9672319 },
    { lat: 49.3249602, lng: 18.9671788 },
    { lat: 49.3243807, lng: 18.9675779 },
    { lat: 49.3232607, lng: 18.9691651 },
    { lat: 49.3224186, lng: 18.9696366 },
    { lat: 49.3222099, lng: 18.9701059 },
    { lat: 49.321823, lng: 18.971293 },
    { lat: 49.3213443, lng: 18.9719078 },
    { lat: 49.320744, lng: 18.9722544 },
    { lat: 49.3203218, lng: 18.9721554 },
    { lat: 49.32029, lng: 18.9737217 },
    { lat: 49.3203416, lng: 18.9743502 },
    { lat: 49.3189654, lng: 18.9745116 },
    { lat: 49.3175899, lng: 18.9745074 },
    { lat: 49.3170237, lng: 18.9749048 },
    { lat: 49.316578, lng: 18.9753676 },
    { lat: 49.3162396, lng: 18.9758177 },
    { lat: 49.31532, lng: 18.976324 },
    { lat: 49.315361, lng: 18.976495 },
    { lat: 49.315596, lng: 18.977479 },
    { lat: 49.315658, lng: 18.977542 },
    { lat: 49.315305, lng: 18.978191 },
    { lat: 49.315004, lng: 18.978739 },
    { lat: 49.314948, lng: 18.97899 },
    { lat: 49.314704, lng: 18.98042 },
    { lat: 49.3147, lng: 18.980903 },
    { lat: 49.314863, lng: 18.98137 },
    { lat: 49.315127, lng: 18.98181 },
    { lat: 49.314695, lng: 18.982241 },
    { lat: 49.314677, lng: 18.982259 },
    { lat: 49.3150344, lng: 18.9847046 },
    { lat: 49.3152168, lng: 18.986594 },
    { lat: 49.315447, lng: 18.9875462 },
    { lat: 49.31679, lng: 18.9892861 },
    { lat: 49.316749, lng: 18.9898633 },
    { lat: 49.3171422, lng: 18.9906036 },
    { lat: 49.3171433, lng: 18.9916174 },
    { lat: 49.3173917, lng: 18.9931609 },
    { lat: 49.3179154, lng: 18.9943481 },
    { lat: 49.3181695, lng: 18.9959119 },
    { lat: 49.318537, lng: 18.9966827 },
    { lat: 49.319444, lng: 18.9985454 },
    { lat: 49.3204502, lng: 18.9986046 },
    { lat: 49.3204414, lng: 18.9940048 },
    { lat: 49.3208897, lng: 18.9943108 },
    { lat: 49.3215177, lng: 18.9939787 },
    { lat: 49.3219313, lng: 18.9940442 },
    { lat: 49.3222512, lng: 18.9934965 },
    { lat: 49.3225694, lng: 18.9935489 },
    { lat: 49.3232414, lng: 18.9940016 },
    { lat: 49.3238192, lng: 18.9942857 },
    { lat: 49.3242212, lng: 18.9936663 },
    { lat: 49.3246707, lng: 18.9935031 },
    { lat: 49.3250326, lng: 18.9937507 },
    { lat: 49.3255416, lng: 18.9934703 },
    { lat: 49.326048, lng: 18.9924144 },
    { lat: 49.3264897, lng: 18.9917798 },
    { lat: 49.326179, lng: 18.9903466 },
    { lat: 49.3278628, lng: 18.9858723 },
    { lat: 49.3283658, lng: 18.98502 },
    { lat: 49.327745, lng: 18.982754 },
    { lat: 49.3278169, lng: 18.9825663 },
    { lat: 49.3274046, lng: 18.9797377 },
    { lat: 49.328612, lng: 18.9790823 },
    { lat: 49.328663, lng: 18.9801006 },
    { lat: 49.3291367, lng: 18.9810524 },
    { lat: 49.3306684, lng: 18.9811143 },
    { lat: 49.3319438, lng: 18.9810997 },
    { lat: 49.3333001, lng: 18.9811739 },
    { lat: 49.3343492, lng: 18.9812317 },
    { lat: 49.335218, lng: 18.9811541 },
    { lat: 49.3361281, lng: 18.9816406 },
    { lat: 49.337242, lng: 18.9813688 },
    { lat: 49.3381035, lng: 18.9812837 },
    { lat: 49.3384176, lng: 18.9812531 },
    { lat: 49.3403919, lng: 18.9810582 },
    { lat: 49.3413209, lng: 18.9808839 },
    { lat: 49.3413627, lng: 18.9808594 },
    { lat: 49.3429426, lng: 18.9799483 },
    { lat: 49.3433699, lng: 18.9799293 },
    { lat: 49.343064, lng: 18.9772877 },
    { lat: 49.3431587, lng: 18.9762484 },
    { lat: 49.3434867, lng: 18.9757337 },
    { lat: 49.3438079, lng: 18.9742647 },
    { lat: 49.3444008, lng: 18.9730523 },
    { lat: 49.3471381, lng: 18.9751267 },
    { lat: 49.3471971, lng: 18.9751111 },
    { lat: 49.3473522, lng: 18.9750991 },
    { lat: 49.3477846, lng: 18.9750462 },
    { lat: 49.3480225, lng: 18.9750927 },
    { lat: 49.3481408, lng: 18.975115 },
    { lat: 49.3481499, lng: 18.9751378 },
    { lat: 49.3482439, lng: 18.9753488 },
    { lat: 49.3485527, lng: 18.9751893 },
    { lat: 49.3486364, lng: 18.9751487 },
    { lat: 49.3488083, lng: 18.9752265 },
    { lat: 49.3489422, lng: 18.9752694 },
    { lat: 49.349237, lng: 18.9753252 },
    { lat: 49.3496038, lng: 18.975357 },
    { lat: 49.3497609, lng: 18.9752959 },
    { lat: 49.3499391, lng: 18.975322 },
    { lat: 49.3500563, lng: 18.9755443 },
    { lat: 49.3501862, lng: 18.9755725 },
    { lat: 49.3506512, lng: 18.9754272 },
    { lat: 49.350929, lng: 18.9756394 },
    { lat: 49.3510226, lng: 18.9756421 },
    { lat: 49.3511264, lng: 18.9755294 },
    { lat: 49.3512568, lng: 18.975299 },
    { lat: 49.3513946, lng: 18.9752354 },
    { lat: 49.3514966, lng: 18.9753377 },
    { lat: 49.351615, lng: 18.9753155 },
    { lat: 49.351818, lng: 18.9756508 },
    { lat: 49.3520571, lng: 18.9757005 },
    { lat: 49.3523404, lng: 18.975485 },
    { lat: 49.3524646, lng: 18.9754635 },
    { lat: 49.3526102, lng: 18.9756019 },
    { lat: 49.3528027, lng: 18.9755305 },
    { lat: 49.3529074, lng: 18.9756166 },
    { lat: 49.3529693, lng: 18.9757735 },
    { lat: 49.3530883, lng: 18.9758279 },
    { lat: 49.3532569, lng: 18.975734 },
    { lat: 49.353383, lng: 18.9757503 },
    { lat: 49.3534181, lng: 18.9757548 },
    { lat: 49.3534944, lng: 18.975475 },
    { lat: 49.3539948, lng: 18.9755197 },
    { lat: 49.3541916, lng: 18.9752609 },
    { lat: 49.3543773, lng: 18.9752322 },
    { lat: 49.3546457, lng: 18.9750785 },
    { lat: 49.354818, lng: 18.9749856 },
    { lat: 49.3549078, lng: 18.9751099 },
    { lat: 49.3551473, lng: 18.9752127 },
    { lat: 49.3553082, lng: 18.9750951 },
    { lat: 49.3554407, lng: 18.9750626 },
    { lat: 49.3556649, lng: 18.975105 },
    { lat: 49.3559253, lng: 18.9750592 },
    { lat: 49.3560211, lng: 18.9749435 },
    { lat: 49.3561465, lng: 18.9748593 },
    { lat: 49.3564072, lng: 18.9749795 },
    { lat: 49.3565173, lng: 18.9747909 },
    { lat: 49.3566611, lng: 18.9746489 },
    { lat: 49.3567338, lng: 18.9746136 },
    { lat: 49.3569587, lng: 18.9746468 },
    { lat: 49.3571779, lng: 18.9746565 },
    { lat: 49.357401, lng: 18.97454 },
    { lat: 49.357598, lng: 18.9744709 },
    { lat: 49.3577744, lng: 18.9744592 },
    { lat: 49.3579672, lng: 18.9744786 },
    { lat: 49.3581024, lng: 18.9742298 },
    { lat: 49.3583338, lng: 18.9742811 },
    { lat: 49.3585785, lng: 18.9743504 },
    { lat: 49.3588804, lng: 18.9742144 },
    { lat: 49.3593143, lng: 18.9744814 },
    { lat: 49.3594212, lng: 18.9745216 },
    { lat: 49.3594938, lng: 18.9745109 },
    { lat: 49.3596222, lng: 18.9744597 },
    { lat: 49.3598565, lng: 18.9743649 },
    { lat: 49.3605315, lng: 18.9742029 },
    { lat: 49.3611281, lng: 18.9742764 },
    { lat: 49.3617628, lng: 18.9742102 },
    { lat: 49.3624788, lng: 18.9743287 },
    { lat: 49.3629441, lng: 18.9741623 },
    { lat: 49.3634304, lng: 18.9736969 },
    { lat: 49.3637792, lng: 18.9729655 },
    { lat: 49.3639755, lng: 18.9727009 },
    { lat: 49.3645312, lng: 18.9716413 },
    { lat: 49.3645851, lng: 18.9710964 },
    { lat: 49.3648066, lng: 18.9703456 },
    { lat: 49.3650671, lng: 18.969873 },
    { lat: 49.3660121, lng: 18.9686201 },
    { lat: 49.3665471, lng: 18.9682284 },
    { lat: 49.3666714, lng: 18.9679653 },
    { lat: 49.3667583, lng: 18.968031 },
    { lat: 49.3674303, lng: 18.9679452 },
    { lat: 49.3683207, lng: 18.9681675 },
    { lat: 49.3693109, lng: 18.9686241 },
    { lat: 49.3695582, lng: 18.969014 },
    { lat: 49.3706313, lng: 18.9700935 },
    { lat: 49.370997, lng: 18.9702397 },
    { lat: 49.3717524, lng: 18.9703995 },
    { lat: 49.3726114, lng: 18.9709157 },
    { lat: 49.373011, lng: 18.9716024 },
    { lat: 49.3732436, lng: 18.9723742 },
    { lat: 49.37358, lng: 18.9728365 },
    { lat: 49.3743626, lng: 18.97437 },
    { lat: 49.3759803, lng: 18.9757009 },
    { lat: 49.376417, lng: 18.9754174 },
    { lat: 49.3769515, lng: 18.9744292 },
    { lat: 49.37731, lng: 18.9739797 },
    { lat: 49.3777179, lng: 18.9736627 },
    { lat: 49.3782097, lng: 18.9737569 },
    { lat: 49.3788021, lng: 18.9732036 },
    { lat: 49.3797714, lng: 18.9727956 },
    { lat: 49.3800286, lng: 18.9725306 },
    { lat: 49.381032, lng: 18.9716804 },
    { lat: 49.3830897, lng: 18.9726562 },
    { lat: 49.3850084, lng: 18.9727467 },
    { lat: 49.3856151, lng: 18.9729283 },
    { lat: 49.3868222, lng: 18.9728582 },
    { lat: 49.3874165, lng: 18.9727486 },
    { lat: 49.3884697, lng: 18.9728029 },
    { lat: 49.3890314, lng: 18.9739361 },
    { lat: 49.3895349, lng: 18.9743536 },
    { lat: 49.3898111, lng: 18.9749153 },
    { lat: 49.3901341, lng: 18.9750638 },
    { lat: 49.3903456, lng: 18.9753248 },
    { lat: 49.3905229, lng: 18.9753083 },
    { lat: 49.3916979, lng: 18.9765795 },
    { lat: 49.392606, lng: 18.9771775 },
    { lat: 49.3929105, lng: 18.9775218 },
    { lat: 49.3937412, lng: 18.9779931 },
    { lat: 49.3940439, lng: 18.9786322 },
    { lat: 49.3941516, lng: 18.9792551 },
    { lat: 49.3952215, lng: 18.9798538 },
    { lat: 49.395902, lng: 18.979255 },
    { lat: 49.396829, lng: 18.978893 },
    { lat: 49.397163, lng: 18.97793 },
    { lat: 49.397169, lng: 18.977912 },
    { lat: 49.397223, lng: 18.97784 },
    { lat: 49.397689, lng: 18.977222 },
    { lat: 49.398124, lng: 18.976643 },
    { lat: 49.398244, lng: 18.976491 },
    { lat: 49.398602, lng: 18.976036 },
    { lat: 49.399568, lng: 18.974418 },
    { lat: 49.399877, lng: 18.973855 },
    { lat: 49.400123, lng: 18.973407 },
    { lat: 49.400629, lng: 18.972774 },
    { lat: 49.401027, lng: 18.972275 },
  ],
  Staškov: [
    { lat: 49.5030653, lng: 18.660084 },
    { lat: 49.50287, lng: 18.6601253 },
    { lat: 49.5025937, lng: 18.6604067 },
    { lat: 49.5023176, lng: 18.6601296 },
    { lat: 49.5020064, lng: 18.6598918 },
    { lat: 49.5019844, lng: 18.65986 },
    { lat: 49.5018278, lng: 18.6595874 },
    { lat: 49.5016224, lng: 18.6592802 },
    { lat: 49.5013571, lng: 18.6590613 },
    { lat: 49.5011977, lng: 18.6589926 },
    { lat: 49.5010305, lng: 18.6590485 },
    { lat: 49.5008522, lng: 18.6590449 },
    { lat: 49.5006672, lng: 18.6590225 },
    { lat: 49.5005166, lng: 18.6590535 },
    { lat: 49.5003619, lng: 18.65892 },
    { lat: 49.5001557, lng: 18.6588582 },
    { lat: 49.4999911, lng: 18.658945 },
    { lat: 49.4997698, lng: 18.6588763 },
    { lat: 49.4996203, lng: 18.6589308 },
    { lat: 49.4994308, lng: 18.6589721 },
    { lat: 49.4992667, lng: 18.6590011 },
    { lat: 49.499069, lng: 18.6591 },
    { lat: 49.4989109, lng: 18.6592031 },
    { lat: 49.4987858, lng: 18.6592516 },
    { lat: 49.4986563, lng: 18.6593359 },
    { lat: 49.4985237, lng: 18.6594119 },
    { lat: 49.4984059, lng: 18.6594635 },
    { lat: 49.4982156, lng: 18.6596177 },
    { lat: 49.4980198, lng: 18.6597781 },
    { lat: 49.4978793, lng: 18.6598292 },
    { lat: 49.4976698, lng: 18.6600054 },
    { lat: 49.497469, lng: 18.6600873 },
    { lat: 49.4972429, lng: 18.6602499 },
    { lat: 49.4971061, lng: 18.6603489 },
    { lat: 49.4969902, lng: 18.660417 },
    { lat: 49.4968089, lng: 18.6605951 },
    { lat: 49.4966562, lng: 18.6607171 },
    { lat: 49.4965524, lng: 18.6608 },
    { lat: 49.4964408, lng: 18.660861 },
    { lat: 49.4963606, lng: 18.6609671 },
    { lat: 49.4962912, lng: 18.6610235 },
    { lat: 49.4962087, lng: 18.6612515 },
    { lat: 49.4961195, lng: 18.6613793 },
    { lat: 49.4960014, lng: 18.6615543 },
    { lat: 49.4959148, lng: 18.6616463 },
    { lat: 49.4958017, lng: 18.6617246 },
    { lat: 49.4956918, lng: 18.6617382 },
    { lat: 49.495578, lng: 18.6618474 },
    { lat: 49.4954762, lng: 18.6619467 },
    { lat: 49.4953147, lng: 18.6619856 },
    { lat: 49.4951264, lng: 18.6619005 },
    { lat: 49.4950283, lng: 18.6618553 },
    { lat: 49.4949067, lng: 18.6618603 },
    { lat: 49.4947578, lng: 18.6619012 },
    { lat: 49.4946451, lng: 18.6619457 },
    { lat: 49.4945244, lng: 18.661969 },
    { lat: 49.4944181, lng: 18.6619825 },
    { lat: 49.4943214, lng: 18.6619463 },
    { lat: 49.4942212, lng: 18.6619061 },
    { lat: 49.4941405, lng: 18.661787 },
    { lat: 49.4941285, lng: 18.6616209 },
    { lat: 49.4941343, lng: 18.6614529 },
    { lat: 49.4941156, lng: 18.6612913 },
    { lat: 49.4940985, lng: 18.6611363 },
    { lat: 49.4940386, lng: 18.6609863 },
    { lat: 49.4939922, lng: 18.6608324 },
    { lat: 49.4939049, lng: 18.6607178 },
    { lat: 49.4938343, lng: 18.6606612 },
    { lat: 49.493748, lng: 18.6606273 },
    { lat: 49.4936626, lng: 18.6606118 },
    { lat: 49.4935512, lng: 18.6605295 },
    { lat: 49.4934253, lng: 18.6603235 },
    { lat: 49.4933495, lng: 18.6601453 },
    { lat: 49.4932349, lng: 18.6599386 },
    { lat: 49.4931597, lng: 18.6597996 },
    { lat: 49.4930564, lng: 18.6596136 },
    { lat: 49.4929093, lng: 18.6593464 },
    { lat: 49.4927975, lng: 18.6591092 },
    { lat: 49.4927105, lng: 18.6589336 },
    { lat: 49.4927022, lng: 18.6587979 },
    { lat: 49.4927116, lng: 18.6586356 },
    { lat: 49.4927092, lng: 18.6585046 },
    { lat: 49.4927107, lng: 18.6583796 },
    { lat: 49.4926536, lng: 18.6583018 },
    { lat: 49.4925402, lng: 18.6582071 },
    { lat: 49.4924773, lng: 18.6581656 },
    { lat: 49.492378, lng: 18.658233 },
    { lat: 49.4922456, lng: 18.6582625 },
    { lat: 49.4921536, lng: 18.6582507 },
    { lat: 49.4920709, lng: 18.6582251 },
    { lat: 49.4919618, lng: 18.6581853 },
    { lat: 49.4918704, lng: 18.6581194 },
    { lat: 49.4917559, lng: 18.6580665 },
    { lat: 49.4916719, lng: 18.6580344 },
    { lat: 49.4915559, lng: 18.6579937 },
    { lat: 49.4914839, lng: 18.6579555 },
    { lat: 49.4913623, lng: 18.6579162 },
    { lat: 49.4912023, lng: 18.6578298 },
    { lat: 49.4910465, lng: 18.6577218 },
    { lat: 49.4909161, lng: 18.6575783 },
    { lat: 49.4907041, lng: 18.6574077 },
    { lat: 49.4905668, lng: 18.6572858 },
    { lat: 49.4902823, lng: 18.6570828 },
    { lat: 49.4900313, lng: 18.6568804 },
    { lat: 49.4898886, lng: 18.6568056 },
    { lat: 49.4897475, lng: 18.6566925 },
    { lat: 49.4895795, lng: 18.6565882 },
    { lat: 49.4894772, lng: 18.6565715 },
    { lat: 49.4894314, lng: 18.6565366 },
    { lat: 49.4893352, lng: 18.6564872 },
    { lat: 49.4892514, lng: 18.6564168 },
    { lat: 49.4891879, lng: 18.6563913 },
    { lat: 49.4890674, lng: 18.6565205 },
    { lat: 49.4889557, lng: 18.6565844 },
    { lat: 49.4888142, lng: 18.6566353 },
    { lat: 49.488689, lng: 18.6566803 },
    { lat: 49.4885692, lng: 18.6567608 },
    { lat: 49.4884058, lng: 18.6568268 },
    { lat: 49.4883001, lng: 18.6568294 },
    { lat: 49.4881944, lng: 18.6568588 },
    { lat: 49.4880601, lng: 18.6569194 },
    { lat: 49.4879229, lng: 18.656948 },
    { lat: 49.4878315, lng: 18.6569673 },
    { lat: 49.4876976, lng: 18.6569509 },
    { lat: 49.4875949, lng: 18.6569303 },
    { lat: 49.4875309, lng: 18.6569346 },
    { lat: 49.4874647, lng: 18.6569849 },
    { lat: 49.4874239, lng: 18.6570212 },
    { lat: 49.4873631, lng: 18.6570686 },
    { lat: 49.4872725, lng: 18.6570802 },
    { lat: 49.4870756, lng: 18.6571333 },
    { lat: 49.4869904, lng: 18.6571853 },
    { lat: 49.4869378, lng: 18.6572135 },
    { lat: 49.4868745, lng: 18.6572326 },
    { lat: 49.4867, lng: 18.6571531 },
    { lat: 49.486596, lng: 18.6571482 },
    { lat: 49.4864746, lng: 18.6571314 },
    { lat: 49.4863411, lng: 18.6571451 },
    { lat: 49.4861454, lng: 18.6571124 },
    { lat: 49.4860377, lng: 18.6571232 },
    { lat: 49.4859502, lng: 18.6571526 },
    { lat: 49.4858331, lng: 18.6571153 },
    { lat: 49.4857098, lng: 18.6570834 },
    { lat: 49.485555, lng: 18.6570562 },
    { lat: 49.4854101, lng: 18.6570691 },
    { lat: 49.4852522, lng: 18.6570223 },
    { lat: 49.4851475, lng: 18.6568797 },
    { lat: 49.4850927, lng: 18.6567186 },
    { lat: 49.485028, lng: 18.6565565 },
    { lat: 49.4849638, lng: 18.6564111 },
    { lat: 49.4849177, lng: 18.656218 },
    { lat: 49.4848734, lng: 18.65604 },
    { lat: 49.4847622, lng: 18.6559443 },
    { lat: 49.4846296, lng: 18.6558443 },
    { lat: 49.4845376, lng: 18.6557031 },
    { lat: 49.4844711, lng: 18.6555764 },
    { lat: 49.4844129, lng: 18.655436 },
    { lat: 49.4843824, lng: 18.6552838 },
    { lat: 49.4843515, lng: 18.6551277 },
    { lat: 49.484367, lng: 18.6548654 },
    { lat: 49.4843002, lng: 18.6546419 },
    { lat: 49.4841902, lng: 18.654439 },
    { lat: 49.4840732, lng: 18.6541866 },
    { lat: 49.4840138, lng: 18.6540689 },
    { lat: 49.4839698, lng: 18.6539148 },
    { lat: 49.483884, lng: 18.6536998 },
    { lat: 49.4838333, lng: 18.6535527 },
    { lat: 49.4837448, lng: 18.6534796 },
    { lat: 49.4836063, lng: 18.6533767 },
    { lat: 49.4834185, lng: 18.6533188 },
    { lat: 49.4833668, lng: 18.6532385 },
    { lat: 49.4832642, lng: 18.6531526 },
    { lat: 49.483095, lng: 18.6531073 },
    { lat: 49.4829456, lng: 18.6530134 },
    { lat: 49.482761, lng: 18.6529975 },
    { lat: 49.4825986, lng: 18.6529749 },
    { lat: 49.4824817, lng: 18.6528552 },
    { lat: 49.4823868, lng: 18.6528296 },
    { lat: 49.4822401, lng: 18.652763 },
    { lat: 49.4820525, lng: 18.6527993 },
    { lat: 49.482005, lng: 18.6527773 },
    { lat: 49.4819272, lng: 18.6526909 },
    { lat: 49.4818229, lng: 18.6525722 },
    { lat: 49.4817391, lng: 18.6525866 },
    { lat: 49.4816041, lng: 18.6525953 },
    { lat: 49.4815125, lng: 18.6525657 },
    { lat: 49.4814091, lng: 18.6526152 },
    { lat: 49.4812982, lng: 18.6526101 },
    { lat: 49.4812291, lng: 18.6526673 },
    { lat: 49.4811103, lng: 18.6526585 },
    { lat: 49.4809777, lng: 18.6526271 },
    { lat: 49.4808544, lng: 18.6526192 },
    { lat: 49.480733, lng: 18.652623 },
    { lat: 49.4806289, lng: 18.6525946 },
    { lat: 49.4804968, lng: 18.6526353 },
    { lat: 49.4803835, lng: 18.6526059 },
    { lat: 49.4802049, lng: 18.6525747 },
    { lat: 49.4801083, lng: 18.6525182 },
    { lat: 49.4800228, lng: 18.6524153 },
    { lat: 49.479966, lng: 18.652257 },
    { lat: 49.4798779, lng: 18.6521453 },
    { lat: 49.4798256, lng: 18.6520104 },
    { lat: 49.47981, lng: 18.651674 },
    { lat: 49.4798396, lng: 18.6514425 },
    { lat: 49.4798501, lng: 18.6511475 },
    { lat: 49.4798439, lng: 18.6509274 },
    { lat: 49.4798096, lng: 18.6507652 },
    { lat: 49.4797691, lng: 18.6505748 },
    { lat: 49.4796785, lng: 18.6504384 },
    { lat: 49.4795895, lng: 18.6503149 },
    { lat: 49.479545, lng: 18.650282 },
    { lat: 49.4794839, lng: 18.6502566 },
    { lat: 49.4794363, lng: 18.6502324 },
    { lat: 49.4793126, lng: 18.6501945 },
    { lat: 49.4792363, lng: 18.6502043 },
    { lat: 49.4790658, lng: 18.6501566 },
    { lat: 49.4789862, lng: 18.6501219 },
    { lat: 49.4788391, lng: 18.6499635 },
    { lat: 49.4786977, lng: 18.6498669 },
    { lat: 49.4785517, lng: 18.6498132 },
    { lat: 49.4784524, lng: 18.649687 },
    { lat: 49.4784863, lng: 18.6493669 },
    { lat: 49.4784391, lng: 18.6492832 },
    { lat: 49.4784308, lng: 18.6491936 },
    { lat: 49.4783724, lng: 18.6490888 },
    { lat: 49.4783394, lng: 18.6489747 },
    { lat: 49.4782776, lng: 18.6488507 },
    { lat: 49.4782241, lng: 18.6487152 },
    { lat: 49.4781933, lng: 18.6486008 },
    { lat: 49.4781543, lng: 18.6484167 },
    { lat: 49.478069, lng: 18.648296 },
    { lat: 49.4779999, lng: 18.6481839 },
    { lat: 49.4779051, lng: 18.6480963 },
    { lat: 49.4778005, lng: 18.6479947 },
    { lat: 49.4777101, lng: 18.6479254 },
    { lat: 49.4776093, lng: 18.647856 },
    { lat: 49.4775256, lng: 18.6477663 },
    { lat: 49.4774213, lng: 18.6476476 },
    { lat: 49.4772967, lng: 18.6475079 },
    { lat: 49.4771873, lng: 18.6474849 },
    { lat: 49.477067, lng: 18.6473863 },
    { lat: 49.4769188, lng: 18.6472912 },
    { lat: 49.476837, lng: 18.6471955 },
    { lat: 49.4767642, lng: 18.6471429 },
    { lat: 49.4766973, lng: 18.6470247 },
    { lat: 49.4765789, lng: 18.6469621 },
    { lat: 49.476467, lng: 18.6468756 },
    { lat: 49.476352, lng: 18.6468308 },
    { lat: 49.4762509, lng: 18.6467516 },
    { lat: 49.4761714, lng: 18.6468043 },
    { lat: 49.4760662, lng: 18.6468645 },
    { lat: 49.4759394, lng: 18.6468124 },
    { lat: 49.4758542, lng: 18.646757 },
    { lat: 49.4757131, lng: 18.6467513 },
    { lat: 49.4756151, lng: 18.6467755 },
    { lat: 49.4754736, lng: 18.6468526 },
    { lat: 49.4754022, lng: 18.646955 },
    { lat: 49.4752978, lng: 18.6471123 },
    { lat: 49.4752087, lng: 18.6472822 },
    { lat: 49.4751402, lng: 18.6473948 },
    { lat: 49.475089, lng: 18.6474487 },
    { lat: 49.474892, lng: 18.6475623 },
    { lat: 49.4747966, lng: 18.6475499 },
    { lat: 49.4747012, lng: 18.6475556 },
    { lat: 49.4745965, lng: 18.6476266 },
    { lat: 49.4744883, lng: 18.6476901 },
    { lat: 49.4744249, lng: 18.6477527 },
    { lat: 49.4743231, lng: 18.647744 },
    { lat: 49.4741995, lng: 18.6478594 },
    { lat: 49.4740861, lng: 18.6477836 },
    { lat: 49.4739474, lng: 18.6478476 },
    { lat: 49.47385, lng: 18.6478369 },
    { lat: 49.4737313, lng: 18.6477686 },
    { lat: 49.4734752, lng: 18.647766 },
    { lat: 49.4733388, lng: 18.6476611 },
    { lat: 49.4732729, lng: 18.6476301 },
    { lat: 49.4732127, lng: 18.6476231 },
    { lat: 49.4731358, lng: 18.647616 },
    { lat: 49.4730375, lng: 18.6475926 },
    { lat: 49.472981, lng: 18.6475039 },
    { lat: 49.4729321, lng: 18.6472388 },
    { lat: 49.4727516, lng: 18.6471771 },
    { lat: 49.4725444, lng: 18.6470973 },
    { lat: 49.4724222, lng: 18.6470251 },
    { lat: 49.4722988, lng: 18.6468631 },
    { lat: 49.4722122, lng: 18.6466998 },
    { lat: 49.4721055, lng: 18.6465579 },
    { lat: 49.4719388, lng: 18.6464086 },
    { lat: 49.4717518, lng: 18.6462861 },
    { lat: 49.4716602, lng: 18.6462394 },
    { lat: 49.4714733, lng: 18.6461579 },
    { lat: 49.4711415, lng: 18.6460062 },
    { lat: 49.470876, lng: 18.645908 },
    { lat: 49.4707537, lng: 18.6458789 },
    { lat: 49.4707015, lng: 18.6458702 },
    { lat: 49.4707218, lng: 18.6458299 },
    { lat: 49.4708602, lng: 18.6457398 },
    { lat: 49.4707966, lng: 18.6456847 },
    { lat: 49.4706809, lng: 18.6457175 },
    { lat: 49.4704654, lng: 18.6457353 },
    { lat: 49.4702993, lng: 18.6457715 },
    { lat: 49.470178, lng: 18.6457177 },
    { lat: 49.4700809, lng: 18.6456105 },
    { lat: 49.4700255, lng: 18.6454785 },
    { lat: 49.4699692, lng: 18.6453916 },
    { lat: 49.469705, lng: 18.6453251 },
    { lat: 49.4695205, lng: 18.6453291 },
    { lat: 49.4692185, lng: 18.6453573 },
    { lat: 49.4691211, lng: 18.6453291 },
    { lat: 49.4685354, lng: 18.6450615 },
    { lat: 49.4683544, lng: 18.6448385 },
    { lat: 49.4681913, lng: 18.6445473 },
    { lat: 49.4681009, lng: 18.6446289 },
    { lat: 49.4679693, lng: 18.6446152 },
    { lat: 49.4679394, lng: 18.6447748 },
    { lat: 49.4677978, lng: 18.6449113 },
    { lat: 49.4676835, lng: 18.6450122 },
    { lat: 49.4675952, lng: 18.644959 },
    { lat: 49.4670683, lng: 18.6449966 },
    { lat: 49.4669792, lng: 18.6450174 },
    { lat: 49.466849, lng: 18.6449861 },
    { lat: 49.4667261, lng: 18.6449865 },
    { lat: 49.4666172, lng: 18.6450403 },
    { lat: 49.4664584, lng: 18.645171 },
    { lat: 49.4663333, lng: 18.6452805 },
    { lat: 49.4662181, lng: 18.6453405 },
    { lat: 49.4661012, lng: 18.6453688 },
    { lat: 49.4659656, lng: 18.645299 },
    { lat: 49.4658743, lng: 18.6453419 },
    { lat: 49.4657828, lng: 18.6454479 },
    { lat: 49.4656468, lng: 18.6455833 },
    { lat: 49.4654693, lng: 18.6455517 },
    { lat: 49.4653101, lng: 18.6455929 },
    { lat: 49.46521, lng: 18.6457703 },
    { lat: 49.4651297, lng: 18.6459761 },
    { lat: 49.4650462, lng: 18.6460586 },
    { lat: 49.4649796, lng: 18.6460828 },
    { lat: 49.4648801, lng: 18.6461188 },
    { lat: 49.4647422, lng: 18.6460706 },
    { lat: 49.4646081, lng: 18.6460946 },
    { lat: 49.4645942, lng: 18.6461278 },
    { lat: 49.4645846, lng: 18.64612 },
    { lat: 49.464488, lng: 18.6461889 },
    { lat: 49.4644752, lng: 18.6461821 },
    { lat: 49.4644929, lng: 18.6461332 },
    { lat: 49.4642605, lng: 18.6462599 },
    { lat: 49.4641022, lng: 18.6462927 },
    { lat: 49.4640719, lng: 18.6462964 },
    { lat: 49.4639845, lng: 18.6465828 },
    { lat: 49.4637646, lng: 18.6466692 },
    { lat: 49.4636333, lng: 18.6468317 },
    { lat: 49.4634153, lng: 18.6475276 },
    { lat: 49.4633222, lng: 18.6485616 },
    { lat: 49.4635376, lng: 18.6494415 },
    { lat: 49.4636535, lng: 18.6494587 },
    { lat: 49.4636977, lng: 18.6496961 },
    { lat: 49.4637428, lng: 18.6499326 },
    { lat: 49.4637733, lng: 18.6502098 },
    { lat: 49.4638523, lng: 18.6503585 },
    { lat: 49.4640035, lng: 18.6506399 },
    { lat: 49.4640842, lng: 18.6509065 },
    { lat: 49.4640235, lng: 18.6512341 },
    { lat: 49.4639041, lng: 18.6514074 },
    { lat: 49.4637054, lng: 18.6516369 },
    { lat: 49.4635009, lng: 18.6517148 },
    { lat: 49.463455, lng: 18.6517227 },
    { lat: 49.4632728, lng: 18.6517326 },
    { lat: 49.4631994, lng: 18.6520064 },
    { lat: 49.4630657, lng: 18.6524019 },
    { lat: 49.4629021, lng: 18.6529747 },
    { lat: 49.4628604, lng: 18.6530397 },
    { lat: 49.4627783, lng: 18.6530865 },
    { lat: 49.4625067, lng: 18.6530894 },
    { lat: 49.4621794, lng: 18.6531282 },
    { lat: 49.4619826, lng: 18.6531613 },
    { lat: 49.4615933, lng: 18.6533909 },
    { lat: 49.4615494, lng: 18.6533927 },
    { lat: 49.4613315, lng: 18.6534941 },
    { lat: 49.4609995, lng: 18.653589 },
    { lat: 49.4607656, lng: 18.6536662 },
    { lat: 49.4606136, lng: 18.6537358 },
    { lat: 49.4605079, lng: 18.6538059 },
    { lat: 49.4602353, lng: 18.6542834 },
    { lat: 49.4594207, lng: 18.6551284 },
    { lat: 49.4588667, lng: 18.6553367 },
    { lat: 49.4586541, lng: 18.6555178 },
    { lat: 49.4582447, lng: 18.6559855 },
    { lat: 49.4576733, lng: 18.6562482 },
    { lat: 49.457436, lng: 18.6562399 },
    { lat: 49.4572139, lng: 18.6562392 },
    { lat: 49.456909, lng: 18.6564035 },
    { lat: 49.4563848, lng: 18.6565769 },
    { lat: 49.4560365, lng: 18.6570736 },
    { lat: 49.4559297, lng: 18.6570796 },
    { lat: 49.4556992, lng: 18.6566859 },
    { lat: 49.455587, lng: 18.6566516 },
    { lat: 49.4555396, lng: 18.6567789 },
    { lat: 49.4554627, lng: 18.656969 },
    { lat: 49.4549214, lng: 18.6575443 },
    { lat: 49.4545113, lng: 18.658281 },
    { lat: 49.4542594, lng: 18.6584836 },
    { lat: 49.4540283, lng: 18.6584887 },
    { lat: 49.4537574, lng: 18.6584187 },
    { lat: 49.453671, lng: 18.6585098 },
    { lat: 49.4536335, lng: 18.6586323 },
    { lat: 49.4536152, lng: 18.6587165 },
    { lat: 49.45361, lng: 18.6587425 },
    { lat: 49.4534885, lng: 18.6593067 },
    { lat: 49.4533903, lng: 18.6595827 },
    { lat: 49.4532992, lng: 18.6598213 },
    { lat: 49.4532115, lng: 18.6599097 },
    { lat: 49.4530255, lng: 18.6601916 },
    { lat: 49.4529811, lng: 18.6602072 },
    { lat: 49.4528821, lng: 18.6604047 },
    { lat: 49.4527329, lng: 18.6605682 },
    { lat: 49.4526301, lng: 18.6608644 },
    { lat: 49.4526226, lng: 18.6610621 },
    { lat: 49.4526187, lng: 18.6612656 },
    { lat: 49.4526089, lng: 18.6613408 },
    { lat: 49.4525908, lng: 18.6616373 },
    { lat: 49.4525838, lng: 18.6616759 },
    { lat: 49.452551, lng: 18.6618427 },
    { lat: 49.4525092, lng: 18.6619907 },
    { lat: 49.4524423, lng: 18.6621787 },
    { lat: 49.452333, lng: 18.6624598 },
    { lat: 49.4522328, lng: 18.6626107 },
    { lat: 49.4521274, lng: 18.6627495 },
    { lat: 49.4518936, lng: 18.6629586 },
    { lat: 49.451873, lng: 18.6629724 },
    { lat: 49.4517281, lng: 18.6630295 },
    { lat: 49.4516352, lng: 18.6630417 },
    { lat: 49.4514149, lng: 18.6630984 },
    { lat: 49.4510392, lng: 18.6630724 },
    { lat: 49.4508059, lng: 18.6630749 },
    { lat: 49.4506073, lng: 18.6630498 },
    { lat: 49.4505264, lng: 18.6629689 },
    { lat: 49.4504677, lng: 18.6629711 },
    { lat: 49.4503434, lng: 18.6629608 },
    { lat: 49.4502879, lng: 18.6629578 },
    { lat: 49.4501901, lng: 18.662962 },
    { lat: 49.4501474, lng: 18.6629694 },
    { lat: 49.4499424, lng: 18.6629938 },
    { lat: 49.4498156, lng: 18.6630044 },
    { lat: 49.4496586, lng: 18.6630423 },
    { lat: 49.4495552, lng: 18.6630928 },
    { lat: 49.4494338, lng: 18.663122 },
    { lat: 49.4494324, lng: 18.6631403 },
    { lat: 49.4492664, lng: 18.6631547 },
    { lat: 49.4492372, lng: 18.6631572 },
    { lat: 49.449211, lng: 18.6631525 },
    { lat: 49.4491461, lng: 18.6631406 },
    { lat: 49.4490594, lng: 18.6631191 },
    { lat: 49.4487911, lng: 18.6629918 },
    { lat: 49.4486482, lng: 18.6629562 },
    { lat: 49.4486199, lng: 18.6629532 },
    { lat: 49.4485609, lng: 18.6629457 },
    { lat: 49.4485452, lng: 18.6629491 },
    { lat: 49.448467, lng: 18.6629439 },
    { lat: 49.4484029, lng: 18.6629482 },
    { lat: 49.4483466, lng: 18.6629689 },
    { lat: 49.448237, lng: 18.6630054 },
    { lat: 49.4481776, lng: 18.6630124 },
    { lat: 49.4481136, lng: 18.6630631 },
    { lat: 49.4480442, lng: 18.6631815 },
    { lat: 49.4480048, lng: 18.663244 },
    { lat: 49.4479832, lng: 18.6633054 },
    { lat: 49.4478676, lng: 18.6636361 },
    { lat: 49.4478321, lng: 18.6637351 },
    { lat: 49.4478046, lng: 18.6638091 },
    { lat: 49.4476563, lng: 18.6642508 },
    { lat: 49.4476003, lng: 18.6644071 },
    { lat: 49.4475215, lng: 18.6645575 },
    { lat: 49.4474125, lng: 18.6646953 },
    { lat: 49.4474015, lng: 18.6647028 },
    { lat: 49.4472247, lng: 18.6648124 },
    { lat: 49.4471958, lng: 18.664821 },
    { lat: 49.4471255, lng: 18.6648323 },
    { lat: 49.4468308, lng: 18.664867 },
    { lat: 49.4464634, lng: 18.6648944 },
    { lat: 49.4459501, lng: 18.6651272 },
    { lat: 49.4457953, lng: 18.6652692 },
    { lat: 49.4456335, lng: 18.6653664 },
    { lat: 49.4455327, lng: 18.6654206 },
    { lat: 49.4453383, lng: 18.6654769 },
    { lat: 49.4452891, lng: 18.6654841 },
    { lat: 49.445139, lng: 18.6655056 },
    { lat: 49.4449891, lng: 18.665527 },
    { lat: 49.4448639, lng: 18.6655494 },
    { lat: 49.4447651, lng: 18.6655765 },
    { lat: 49.444626, lng: 18.6656141 },
    { lat: 49.4445648, lng: 18.6656263 },
    { lat: 49.4440894, lng: 18.6657037 },
    { lat: 49.4439936, lng: 18.6656619 },
    { lat: 49.4437464, lng: 18.6657639 },
    { lat: 49.4436988, lng: 18.6658209 },
    { lat: 49.443059, lng: 18.6660911 },
    { lat: 49.442986, lng: 18.6660777 },
    { lat: 49.4428529, lng: 18.666054 },
    { lat: 49.4426643, lng: 18.6659425 },
    { lat: 49.4425234, lng: 18.6658596 },
    { lat: 49.4420557, lng: 18.6656707 },
    { lat: 49.4418674, lng: 18.6655853 },
    { lat: 49.4416425, lng: 18.6656392 },
    { lat: 49.4415709, lng: 18.6656496 },
    { lat: 49.4413958, lng: 18.6657068 },
    { lat: 49.4412797, lng: 18.6657722 },
    { lat: 49.4411251, lng: 18.6658791 },
    { lat: 49.4409006, lng: 18.6660685 },
    { lat: 49.4404227, lng: 18.666394 },
    { lat: 49.4403595, lng: 18.6664359 },
    { lat: 49.4401713, lng: 18.6665686 },
    { lat: 49.4400242, lng: 18.6667278 },
    { lat: 49.4399042, lng: 18.6668053 },
    { lat: 49.4396901, lng: 18.6669391 },
    { lat: 49.4395553, lng: 18.6670141 },
    { lat: 49.4394331, lng: 18.6670662 },
    { lat: 49.4393198, lng: 18.667078 },
    { lat: 49.4392003, lng: 18.667057 },
    { lat: 49.4390525, lng: 18.6670542 },
    { lat: 49.4390021, lng: 18.6670405 },
    { lat: 49.4389903, lng: 18.6671609 },
    { lat: 49.438986, lng: 18.6671875 },
    { lat: 49.4389721, lng: 18.6672646 },
    { lat: 49.4389455, lng: 18.6672936 },
    { lat: 49.438932, lng: 18.6673822 },
    { lat: 49.4388959, lng: 18.6677023 },
    { lat: 49.4388595, lng: 18.6678895 },
    { lat: 49.4388304, lng: 18.6679575 },
    { lat: 49.4388199, lng: 18.6680125 },
    { lat: 49.4387802, lng: 18.6681381 },
    { lat: 49.438562, lng: 18.6682129 },
    { lat: 49.4381956, lng: 18.6682158 },
    { lat: 49.4377373, lng: 18.6681863 },
    { lat: 49.437722, lng: 18.6681726 },
    { lat: 49.4376142, lng: 18.668074 },
    { lat: 49.4375492, lng: 18.6680154 },
    { lat: 49.4374468, lng: 18.6678704 },
    { lat: 49.4374415, lng: 18.6678541 },
    { lat: 49.4374202, lng: 18.6677505 },
    { lat: 49.4374107, lng: 18.6677003 },
    { lat: 49.437385, lng: 18.6675759 },
    { lat: 49.4368098, lng: 18.6677851 },
    { lat: 49.4364416, lng: 18.6677756 },
    { lat: 49.4352024, lng: 18.6672855 },
    { lat: 49.4345429, lng: 18.6671588 },
    { lat: 49.4339042, lng: 18.6670024 },
    { lat: 49.4336001, lng: 18.6670085 },
    { lat: 49.4331639, lng: 18.6672509 },
    { lat: 49.4328802, lng: 18.6674711 },
    { lat: 49.432174, lng: 18.6683835 },
    { lat: 49.4321661, lng: 18.6683863 },
    { lat: 49.4317425, lng: 18.6689615 },
    { lat: 49.4310876, lng: 18.6697686 },
    { lat: 49.4307121, lng: 18.6701991 },
    { lat: 49.4304432, lng: 18.6704015 },
    { lat: 49.4301193, lng: 18.6705684 },
    { lat: 49.4296888, lng: 18.6708068 },
    { lat: 49.429082, lng: 18.6711492 },
    { lat: 49.4287951, lng: 18.6712919 },
    { lat: 49.4287458, lng: 18.671315 },
    { lat: 49.4284988, lng: 18.6714452 },
    { lat: 49.4281816, lng: 18.6716322 },
    { lat: 49.4279429, lng: 18.671765 },
    { lat: 49.4277194, lng: 18.6719147 },
    { lat: 49.4275418, lng: 18.6720964 },
    { lat: 49.4273728, lng: 18.6723325 },
    { lat: 49.4272608, lng: 18.6725394 },
    { lat: 49.4271125, lng: 18.6728951 },
    { lat: 49.4268985, lng: 18.6734551 },
    { lat: 49.4267401, lng: 18.6738301 },
    { lat: 49.426626, lng: 18.6740865 },
    { lat: 49.4264226, lng: 18.674379 },
    { lat: 49.4262012, lng: 18.6746491 },
    { lat: 49.4259847, lng: 18.6748646 },
    { lat: 49.4255656, lng: 18.6752783 },
    { lat: 49.425155, lng: 18.6756851 },
    { lat: 49.4249556, lng: 18.6759014 },
    { lat: 49.4247761, lng: 18.6761087 },
    { lat: 49.4244406, lng: 18.6765052 },
    { lat: 49.4241626, lng: 18.67688 },
    { lat: 49.4238107, lng: 18.6774147 },
    { lat: 49.4235691, lng: 18.6778176 },
    { lat: 49.4233251, lng: 18.6782422 },
    { lat: 49.423038, lng: 18.6788261 },
    { lat: 49.4229585, lng: 18.6789892 },
    { lat: 49.4227086, lng: 18.6795996 },
    { lat: 49.4224794, lng: 18.680106 },
    { lat: 49.4222824, lng: 18.6805404 },
    { lat: 49.421994, lng: 18.6812121 },
    { lat: 49.4217398, lng: 18.6817393 },
    { lat: 49.4217032, lng: 18.681771 },
    { lat: 49.4215563, lng: 18.6818765 },
    { lat: 49.4215366, lng: 18.681884 },
    { lat: 49.4215178, lng: 18.6818946 },
    { lat: 49.4214836, lng: 18.6819264 },
    { lat: 49.4214685, lng: 18.6818561 },
    { lat: 49.4214628, lng: 18.6818326 },
    { lat: 49.4213702, lng: 18.6814459 },
    { lat: 49.4213292, lng: 18.6812424 },
    { lat: 49.4210926, lng: 18.6806464 },
    { lat: 49.4210173, lng: 18.6805217 },
    { lat: 49.4209986, lng: 18.680491 },
    { lat: 49.4207778, lng: 18.6801278 },
    { lat: 49.4205023, lng: 18.6798 },
    { lat: 49.4201251, lng: 18.6795289 },
    { lat: 49.419698, lng: 18.679344 },
    { lat: 49.4196707, lng: 18.6793362 },
    { lat: 49.4195378, lng: 18.6792979 },
    { lat: 49.4194989, lng: 18.6800829 },
    { lat: 49.4194924, lng: 18.6811819 },
    { lat: 49.4193793, lng: 18.6812187 },
    { lat: 49.4193284, lng: 18.6812354 },
    { lat: 49.4191798, lng: 18.6813034 },
    { lat: 49.4191751, lng: 18.6813044 },
    { lat: 49.4191073, lng: 18.6813225 },
    { lat: 49.4191041, lng: 18.6813236 },
    { lat: 49.4190909, lng: 18.6813292 },
    { lat: 49.4190049, lng: 18.6813405 },
    { lat: 49.4188914, lng: 18.6813553 },
    { lat: 49.4188869, lng: 18.6813555 },
    { lat: 49.4188474, lng: 18.6813539 },
    { lat: 49.4188441, lng: 18.6813536 },
    { lat: 49.4186158, lng: 18.681389 },
    { lat: 49.4184791, lng: 18.6814269 },
    { lat: 49.4184017, lng: 18.6814342 },
    { lat: 49.4182976, lng: 18.6814261 },
    { lat: 49.4181917, lng: 18.6814084 },
    { lat: 49.4178275, lng: 18.6812987 },
    { lat: 49.4175488, lng: 18.6812523 },
    { lat: 49.4174589, lng: 18.6812373 },
    { lat: 49.417372, lng: 18.6812144 },
    { lat: 49.417245, lng: 18.6811804 },
    { lat: 49.4171782, lng: 18.6811731 },
    { lat: 49.4171004, lng: 18.6811769 },
    { lat: 49.4170058, lng: 18.681198 },
    { lat: 49.4168254, lng: 18.6812416 },
    { lat: 49.416766, lng: 18.6812707 },
    { lat: 49.4167206, lng: 18.6813057 },
    { lat: 49.416713, lng: 18.6813135 },
    { lat: 49.4166798, lng: 18.6813484 },
    { lat: 49.4166702, lng: 18.6813586 },
    { lat: 49.4166548, lng: 18.6813449 },
    { lat: 49.4165616, lng: 18.6812666 },
    { lat: 49.4165336, lng: 18.6812462 },
    { lat: 49.4164625, lng: 18.6812234 },
    { lat: 49.4164047, lng: 18.6812219 },
    { lat: 49.4163782, lng: 18.6812298 },
    { lat: 49.4163532, lng: 18.6812474 },
    { lat: 49.4162999, lng: 18.6812859 },
    { lat: 49.4162859, lng: 18.6812753 },
    { lat: 49.4161616, lng: 18.6813348 },
    { lat: 49.4160789, lng: 18.6813921 },
    { lat: 49.4158414, lng: 18.6815283 },
    { lat: 49.4156093, lng: 18.6816616 },
    { lat: 49.4149712, lng: 18.6818349 },
    { lat: 49.4142915, lng: 18.6818405 },
    { lat: 49.4139342, lng: 18.6818448 },
    { lat: 49.4132646, lng: 18.6821259 },
    { lat: 49.4130839, lng: 18.6822304 },
    { lat: 49.4129459, lng: 18.6823279 },
    { lat: 49.4125294, lng: 18.6824922 },
    { lat: 49.4117007, lng: 18.6828781 },
    { lat: 49.4106473, lng: 18.6836306 },
    { lat: 49.4101724, lng: 18.6839452 },
    { lat: 49.4095187, lng: 18.6842091 },
    { lat: 49.4086628, lng: 18.6845551 },
    { lat: 49.4068068, lng: 18.6851132 },
    { lat: 49.4066781, lng: 18.6851519 },
    { lat: 49.4065372, lng: 18.6851946 },
    { lat: 49.4064593, lng: 18.6852184 },
    { lat: 49.4064483, lng: 18.6852212 },
    { lat: 49.4063727, lng: 18.6852443 },
    { lat: 49.4063262, lng: 18.6852583 },
    { lat: 49.4062976, lng: 18.685272 },
    { lat: 49.4062734, lng: 18.6852833 },
    { lat: 49.4061667, lng: 18.6853345 },
    { lat: 49.4061238, lng: 18.685355 },
    { lat: 49.4060623, lng: 18.6853847 },
    { lat: 49.4059775, lng: 18.6854255 },
    { lat: 49.4059565, lng: 18.6854354 },
    { lat: 49.4059378, lng: 18.6854442 },
    { lat: 49.4057465, lng: 18.6855359 },
    { lat: 49.4056684, lng: 18.6855734 },
    { lat: 49.4055969, lng: 18.6856075 },
    { lat: 49.4055068, lng: 18.6856509 },
    { lat: 49.4050831, lng: 18.6858537 },
    { lat: 49.405064, lng: 18.6859383 },
    { lat: 49.4047141, lng: 18.6861687 },
    { lat: 49.404579, lng: 18.6859764 },
    { lat: 49.4044691, lng: 18.6860124 },
    { lat: 49.4044069, lng: 18.6861149 },
    { lat: 49.4040645, lng: 18.68634 },
    { lat: 49.4038154, lng: 18.686253 },
    { lat: 49.4035254, lng: 18.6859915 },
    { lat: 49.4031087, lng: 18.6861331 },
    { lat: 49.4029249, lng: 18.6860258 },
    { lat: 49.4025439, lng: 18.6859424 },
    { lat: 49.4023644, lng: 18.6860036 },
    { lat: 49.4019018, lng: 18.6862975 },
    { lat: 49.4018783, lng: 18.6866635 },
    { lat: 49.4019285, lng: 18.6888033 },
    { lat: 49.4019722, lng: 18.6891184 },
    { lat: 49.4020322, lng: 18.6893605 },
    { lat: 49.4021135, lng: 18.6898164 },
    { lat: 49.4021465, lng: 18.6900763 },
    { lat: 49.4021924, lng: 18.6904588 },
    { lat: 49.4022253, lng: 18.6907614 },
    { lat: 49.4022553, lng: 18.6910999 },
    { lat: 49.4023466, lng: 18.6915466 },
    { lat: 49.4023849, lng: 18.6918486 },
    { lat: 49.4024522, lng: 18.6921633 },
    { lat: 49.4024572, lng: 18.6922188 },
    { lat: 49.4024691, lng: 18.6923553 },
    { lat: 49.4024595, lng: 18.692582 },
    { lat: 49.4024851, lng: 18.6931781 },
    { lat: 49.4025021, lng: 18.6935243 },
    { lat: 49.4025583, lng: 18.6940373 },
    { lat: 49.4026409, lng: 18.6944265 },
    { lat: 49.4027467, lng: 18.6945113 },
    { lat: 49.4027781, lng: 18.6946153 },
    { lat: 49.4027849, lng: 18.6947224 },
    { lat: 49.4026935, lng: 18.694785 },
    { lat: 49.4028119, lng: 18.6956018 },
    { lat: 49.4029033, lng: 18.6962716 },
    { lat: 49.4029164, lng: 18.6965625 },
    { lat: 49.4029577, lng: 18.6968359 },
    { lat: 49.4030474, lng: 18.6970421 },
    { lat: 49.4032751, lng: 18.6975554 },
    { lat: 49.403376, lng: 18.6977805 },
    { lat: 49.4039533, lng: 18.6984028 },
    { lat: 49.4042308, lng: 18.6988144 },
    { lat: 49.4045189, lng: 18.699082 },
    { lat: 49.4048878, lng: 18.6993668 },
    { lat: 49.4055962, lng: 18.7002544 },
    { lat: 49.4061136, lng: 18.7010019 },
    { lat: 49.4062124, lng: 18.701039 },
    { lat: 49.4063819, lng: 18.701046 },
    { lat: 49.4073013, lng: 18.7020866 },
    { lat: 49.407315, lng: 18.7024571 },
    { lat: 49.4072623, lng: 18.7027413 },
    { lat: 49.4076094, lng: 18.7034268 },
    { lat: 49.4077287, lng: 18.7036606 },
    { lat: 49.4076981, lng: 18.7037628 },
    { lat: 49.4078417, lng: 18.7039244 },
    { lat: 49.4080251, lng: 18.7041282 },
    { lat: 49.4080312, lng: 18.7041589 },
    { lat: 49.4081423, lng: 18.7046874 },
    { lat: 49.4083472, lng: 18.7059966 },
    { lat: 49.4087687, lng: 18.7060776 },
    { lat: 49.4092658, lng: 18.7062472 },
    { lat: 49.4093178, lng: 18.7062082 },
    { lat: 49.409625, lng: 18.7061719 },
    { lat: 49.410151, lng: 18.7060551 },
    { lat: 49.4107021, lng: 18.7060066 },
    { lat: 49.4113542, lng: 18.7059912 },
    { lat: 49.4113769, lng: 18.7059921 },
    { lat: 49.4117429, lng: 18.7060061 },
    { lat: 49.41173, lng: 18.7060395 },
    { lat: 49.4117015, lng: 18.7061176 },
    { lat: 49.4116807, lng: 18.7061756 },
    { lat: 49.4115175, lng: 18.7069543 },
    { lat: 49.4114746, lng: 18.7073844 },
    { lat: 49.4115031, lng: 18.7076949 },
    { lat: 49.4116588, lng: 18.7089454 },
    { lat: 49.4116363, lng: 18.7096362 },
    { lat: 49.4115808, lng: 18.7100196 },
    { lat: 49.4113767, lng: 18.7109019 },
    { lat: 49.4111473, lng: 18.7116036 },
    { lat: 49.411043, lng: 18.7120039 },
    { lat: 49.410995, lng: 18.7123372 },
    { lat: 49.4109579, lng: 18.7128339 },
    { lat: 49.4109575, lng: 18.7130436 },
    { lat: 49.4116341, lng: 18.7139031 },
    { lat: 49.4119547, lng: 18.7142777 },
    { lat: 49.4120744, lng: 18.7147153 },
    { lat: 49.4122545, lng: 18.7152042 },
    { lat: 49.4121995, lng: 18.7157255 },
    { lat: 49.4121727, lng: 18.7160352 },
    { lat: 49.4123593, lng: 18.7160876 },
    { lat: 49.4124992, lng: 18.7163917 },
    { lat: 49.4125104, lng: 18.7165844 },
    { lat: 49.4125649, lng: 18.7167856 },
    { lat: 49.4128675, lng: 18.7168151 },
    { lat: 49.4133871, lng: 18.717502 },
    { lat: 49.4134954, lng: 18.7176767 },
    { lat: 49.4136118, lng: 18.7177475 },
    { lat: 49.413813, lng: 18.7177536 },
    { lat: 49.4140299, lng: 18.7178255 },
    { lat: 49.4140726, lng: 18.7178478 },
    { lat: 49.4142028, lng: 18.7178561 },
    { lat: 49.4143897, lng: 18.7179563 },
    { lat: 49.4146523, lng: 18.7179125 },
    { lat: 49.4149062, lng: 18.7179146 },
    { lat: 49.4151154, lng: 18.7181012 },
    { lat: 49.4151469, lng: 18.7183345 },
    { lat: 49.4151105, lng: 18.718569 },
    { lat: 49.4150734, lng: 18.718826 },
    { lat: 49.4151078, lng: 18.719055 },
    { lat: 49.4151254, lng: 18.7190898 },
    { lat: 49.4150868, lng: 18.719151 },
    { lat: 49.4150542, lng: 18.7191735 },
    { lat: 49.4158317, lng: 18.7213445 },
    { lat: 49.4174152, lng: 18.7224741 },
    { lat: 49.4174504, lng: 18.7223687 },
    { lat: 49.4174686, lng: 18.7220474 },
    { lat: 49.4175142, lng: 18.7219527 },
    { lat: 49.4176061, lng: 18.7217669 },
    { lat: 49.4176264, lng: 18.7217253 },
    { lat: 49.4178444, lng: 18.7212809 },
    { lat: 49.4181451, lng: 18.7207316 },
    { lat: 49.4180602, lng: 18.719712 },
    { lat: 49.4182668, lng: 18.719587 },
    { lat: 49.4186296, lng: 18.719431 },
    { lat: 49.4191529, lng: 18.7192563 },
    { lat: 49.4192937, lng: 18.7191021 },
    { lat: 49.4194174, lng: 18.7189464 },
    { lat: 49.4194333, lng: 18.7189264 },
    { lat: 49.4194628, lng: 18.7188887 },
    { lat: 49.4194883, lng: 18.7188573 },
    { lat: 49.4197316, lng: 18.7185518 },
    { lat: 49.4199123, lng: 18.7187093 },
    { lat: 49.420165, lng: 18.7188833 },
    { lat: 49.420533, lng: 18.718845 },
    { lat: 49.4208099, lng: 18.7188165 },
    { lat: 49.4209292, lng: 18.7188127 },
    { lat: 49.4210296, lng: 18.718874 },
    { lat: 49.4213095, lng: 18.718819 },
    { lat: 49.4213064, lng: 18.7186277 },
    { lat: 49.4215532, lng: 18.7185815 },
    { lat: 49.421829, lng: 18.7184647 },
    { lat: 49.4221099, lng: 18.7182734 },
    { lat: 49.4224929, lng: 18.7183322 },
    { lat: 49.4227013, lng: 18.7182643 },
    { lat: 49.4230629, lng: 18.7181475 },
    { lat: 49.4234275, lng: 18.7180491 },
    { lat: 49.4237934, lng: 18.7179506 },
    { lat: 49.4240147, lng: 18.7178912 },
    { lat: 49.4241538, lng: 18.7178752 },
    { lat: 49.4245809, lng: 18.7178279 },
    { lat: 49.4247455, lng: 18.7178095 },
    { lat: 49.4250031, lng: 18.7178373 },
    { lat: 49.4252923, lng: 18.7178681 },
    { lat: 49.4253725, lng: 18.7178517 },
    { lat: 49.4257084, lng: 18.717783 },
    { lat: 49.4260664, lng: 18.7177097 },
    { lat: 49.4261524, lng: 18.7176923 },
    { lat: 49.4264724, lng: 18.7176439 },
    { lat: 49.4266848, lng: 18.7176117 },
    { lat: 49.4267094, lng: 18.7176083 },
    { lat: 49.4267376, lng: 18.7176041 },
    { lat: 49.4267598, lng: 18.7176007 },
    { lat: 49.4268414, lng: 18.7175881 },
    { lat: 49.4271927, lng: 18.7174686 },
    { lat: 49.4275994, lng: 18.7173299 },
    { lat: 49.4278708, lng: 18.7172376 },
    { lat: 49.4279883, lng: 18.7171978 },
    { lat: 49.4280423, lng: 18.7171789 },
    { lat: 49.4280702, lng: 18.7171696 },
    { lat: 49.4281054, lng: 18.7171548 },
    { lat: 49.4281067, lng: 18.7171572 },
    { lat: 49.4281724, lng: 18.7171393 },
    { lat: 49.4282747, lng: 18.7171119 },
    { lat: 49.4283513, lng: 18.7170909 },
    { lat: 49.4284058, lng: 18.7170763 },
    { lat: 49.4293676, lng: 18.7168162 },
    { lat: 49.4295623, lng: 18.7169383 },
    { lat: 49.4297547, lng: 18.7167357 },
    { lat: 49.4299125, lng: 18.716758 },
    { lat: 49.4302625, lng: 18.7165919 },
    { lat: 49.4304971, lng: 18.7167442 },
    { lat: 49.4306336, lng: 18.7167883 },
    { lat: 49.4307613, lng: 18.7167491 },
    { lat: 49.4308053, lng: 18.716664 },
    { lat: 49.4309855, lng: 18.7163148 },
    { lat: 49.4310173, lng: 18.716254 },
    { lat: 49.4312291, lng: 18.715844 },
    { lat: 49.4312579, lng: 18.7157883 },
    { lat: 49.4312866, lng: 18.7157326 },
    { lat: 49.431305, lng: 18.7156963 },
    { lat: 49.4313368, lng: 18.7156351 },
    { lat: 49.4313868, lng: 18.7155384 },
    { lat: 49.43144, lng: 18.7154355 },
    { lat: 49.4316254, lng: 18.7150751 },
    { lat: 49.4316552, lng: 18.7150178 },
    { lat: 49.431687, lng: 18.7149574 },
    { lat: 49.4317472, lng: 18.7148392 },
    { lat: 49.4317394, lng: 18.7148166 },
    { lat: 49.4317245, lng: 18.7147702 },
    { lat: 49.4318171, lng: 18.7147737 },
    { lat: 49.4318935, lng: 18.7147893 },
    { lat: 49.4321408, lng: 18.71486 },
    { lat: 49.4322895, lng: 18.7145135 },
    { lat: 49.4323735, lng: 18.7143522 },
    { lat: 49.4324184, lng: 18.7143032 },
    { lat: 49.4324491, lng: 18.7142889 },
    { lat: 49.43251, lng: 18.7142655 },
    { lat: 49.4325379, lng: 18.7142617 },
    { lat: 49.4326681, lng: 18.7142486 },
    { lat: 49.4327273, lng: 18.714237 },
    { lat: 49.4327726, lng: 18.7142227 },
    { lat: 49.4327787, lng: 18.714248 },
    { lat: 49.4327858, lng: 18.7142794 },
    { lat: 49.4327929, lng: 18.7143075 },
    { lat: 49.4329075, lng: 18.7144069 },
    { lat: 49.4329973, lng: 18.7144842 },
    { lat: 49.433106, lng: 18.7144078 },
    { lat: 49.433077, lng: 18.7143281 },
    { lat: 49.4329896, lng: 18.7141881 },
    { lat: 49.4329759, lng: 18.7141608 },
    { lat: 49.4329683, lng: 18.7141469 },
    { lat: 49.4329607, lng: 18.7141311 },
    { lat: 49.4330461, lng: 18.7140156 },
    { lat: 49.4331059, lng: 18.7139075 },
    { lat: 49.4331177, lng: 18.713922 },
    { lat: 49.4331251, lng: 18.7139316 },
    { lat: 49.433153, lng: 18.7139691 },
    { lat: 49.4332071, lng: 18.7139052 },
    { lat: 49.4332189, lng: 18.7138911 },
    { lat: 49.4332209, lng: 18.713888 },
    { lat: 49.4333063, lng: 18.713751 },
    { lat: 49.4333721, lng: 18.713802 },
    { lat: 49.4334431, lng: 18.713977 },
    { lat: 49.4333757, lng: 18.7141519 },
    { lat: 49.4334147, lng: 18.7143601 },
    { lat: 49.4334327, lng: 18.7143384 },
    { lat: 49.4334625, lng: 18.7143032 },
    { lat: 49.4335804, lng: 18.7141612 },
    { lat: 49.4336535, lng: 18.7140736 },
    { lat: 49.4339883, lng: 18.7144386 },
    { lat: 49.4340645, lng: 18.714364 },
    { lat: 49.4340536, lng: 18.7142636 },
    { lat: 49.4340269, lng: 18.7140114 },
    { lat: 49.4340162, lng: 18.7139146 },
    { lat: 49.4340141, lng: 18.7138967 },
    { lat: 49.4342878, lng: 18.7137189 },
    { lat: 49.4343581, lng: 18.7136392 },
    { lat: 49.4343984, lng: 18.7135926 },
    { lat: 49.4345013, lng: 18.7137698 },
    { lat: 49.4344735, lng: 18.7137986 },
    { lat: 49.4344382, lng: 18.7138341 },
    { lat: 49.4344979, lng: 18.7139409 },
    { lat: 49.4345365, lng: 18.7140101 },
    { lat: 49.4345427, lng: 18.7140209 },
    { lat: 49.4346137, lng: 18.7141491 },
    { lat: 49.4346512, lng: 18.7141123 },
    { lat: 49.4346888, lng: 18.7140758 },
    { lat: 49.4347348, lng: 18.7140299 },
    { lat: 49.4347433, lng: 18.7140198 },
    { lat: 49.4347741, lng: 18.7139856 },
    { lat: 49.4347855, lng: 18.7139418 },
    { lat: 49.4348037, lng: 18.7138606 },
    { lat: 49.434825, lng: 18.7137475 },
    { lat: 49.4348528, lng: 18.7135702 },
    { lat: 49.4348692, lng: 18.7134718 },
    { lat: 49.4345344, lng: 18.7133006 },
    { lat: 49.4345706, lng: 18.7132374 },
    { lat: 49.4346097, lng: 18.7131678 },
    { lat: 49.4346232, lng: 18.7131458 },
    { lat: 49.434713, lng: 18.7131602 },
    { lat: 49.4347606, lng: 18.7130985 },
    { lat: 49.4348105, lng: 18.7131257 },
    { lat: 49.4349526, lng: 18.7131463 },
    { lat: 49.4350536, lng: 18.713139 },
    { lat: 49.4350866, lng: 18.7129527 },
    { lat: 49.4350997, lng: 18.7128768 },
    { lat: 49.4348881, lng: 18.7128202 },
    { lat: 49.4347028, lng: 18.712706 },
    { lat: 49.4346101, lng: 18.7126377 },
    { lat: 49.434589, lng: 18.7126225 },
    { lat: 49.4346268, lng: 18.7125458 },
    { lat: 49.4346514, lng: 18.712496 },
    { lat: 49.4346768, lng: 18.712444 },
    { lat: 49.4347125, lng: 18.7123719 },
    { lat: 49.4347522, lng: 18.7122909 },
    { lat: 49.4347991, lng: 18.7122627 },
    { lat: 49.4348582, lng: 18.7122268 },
    { lat: 49.4349063, lng: 18.7121973 },
    { lat: 49.4349589, lng: 18.7121673 },
    { lat: 49.4350213, lng: 18.7121318 },
    { lat: 49.4350947, lng: 18.7120901 },
    { lat: 49.4351889, lng: 18.7120155 },
    { lat: 49.435294, lng: 18.7117624 },
    { lat: 49.4352689, lng: 18.7117115 },
    { lat: 49.4352233, lng: 18.7116164 },
    { lat: 49.4352042, lng: 18.711576 },
    { lat: 49.4351893, lng: 18.7115264 },
    { lat: 49.4351726, lng: 18.7114701 },
    { lat: 49.4351645, lng: 18.7114638 },
    { lat: 49.4351354, lng: 18.7114457 },
    { lat: 49.4351228, lng: 18.7114657 },
    { lat: 49.4350824, lng: 18.7115333 },
    { lat: 49.4350678, lng: 18.7115572 },
    { lat: 49.4350365, lng: 18.7115375 },
    { lat: 49.4350319, lng: 18.7115352 },
    { lat: 49.4349677, lng: 18.711496 },
    { lat: 49.4349644, lng: 18.7114109 },
    { lat: 49.434909, lng: 18.7114304 },
    { lat: 49.4348139, lng: 18.7114642 },
    { lat: 49.4346535, lng: 18.7109868 },
    { lat: 49.4346469, lng: 18.7109677 },
    { lat: 49.4347344, lng: 18.7109555 },
    { lat: 49.4347971, lng: 18.7111213 },
    { lat: 49.4348228, lng: 18.7111203 },
    { lat: 49.4348645, lng: 18.7111177 },
    { lat: 49.4349308, lng: 18.7111147 },
    { lat: 49.4349097, lng: 18.7107531 },
    { lat: 49.4349703, lng: 18.7107689 },
    { lat: 49.4349807, lng: 18.710772 },
    { lat: 49.4349752, lng: 18.7105813 },
    { lat: 49.4349262, lng: 18.7105076 },
    { lat: 49.4349215, lng: 18.710418 },
    { lat: 49.4349063, lng: 18.7101717 },
    { lat: 49.4349038, lng: 18.7099068 },
    { lat: 49.4349518, lng: 18.7099168 },
    { lat: 49.4350074, lng: 18.7099274 },
    { lat: 49.4350575, lng: 18.7099375 },
    { lat: 49.4351497, lng: 18.7099512 },
    { lat: 49.4353246, lng: 18.7099515 },
    { lat: 49.4351864, lng: 18.7095724 },
    { lat: 49.4352077, lng: 18.7095727 },
    { lat: 49.4352798, lng: 18.7095523 },
    { lat: 49.4353345, lng: 18.7095394 },
    { lat: 49.4354386, lng: 18.7093755 },
    { lat: 49.4356131, lng: 18.7092381 },
    { lat: 49.4358918, lng: 18.7087242 },
    { lat: 49.4358302, lng: 18.7086451 },
    { lat: 49.4357889, lng: 18.7085922 },
    { lat: 49.4357479, lng: 18.708539 },
    { lat: 49.4357357, lng: 18.7085238 },
    { lat: 49.435667, lng: 18.7084797 },
    { lat: 49.4356086, lng: 18.7084434 },
    { lat: 49.4356258, lng: 18.7081884 },
    { lat: 49.435636, lng: 18.7079317 },
    { lat: 49.4356208, lng: 18.707922 },
    { lat: 49.4355904, lng: 18.7079011 },
    { lat: 49.4355691, lng: 18.7078816 },
    { lat: 49.4355597, lng: 18.7078741 },
    { lat: 49.4356253, lng: 18.7077048 },
    { lat: 49.4357219, lng: 18.7073106 },
    { lat: 49.4357042, lng: 18.7072067 },
    { lat: 49.4356418, lng: 18.7069401 },
    { lat: 49.4358069, lng: 18.7067129 },
    { lat: 49.4358323, lng: 18.7065931 },
    { lat: 49.4358678, lng: 18.7065637 },
    { lat: 49.4359861, lng: 18.7066695 },
    { lat: 49.4360363, lng: 18.7067209 },
    { lat: 49.4360929, lng: 18.7067466 },
    { lat: 49.4361118, lng: 18.7067652 },
    { lat: 49.4360323, lng: 18.7070797 },
    { lat: 49.4360344, lng: 18.7071421 },
    { lat: 49.436276, lng: 18.7072516 },
    { lat: 49.4363885, lng: 18.7071841 },
    { lat: 49.4364267, lng: 18.7068986 },
    { lat: 49.436388, lng: 18.7065929 },
    { lat: 49.4364328, lng: 18.7061975 },
    { lat: 49.4365561, lng: 18.7060744 },
    { lat: 49.4367322, lng: 18.7060357 },
    { lat: 49.4367885, lng: 18.7059465 },
    { lat: 49.4369141, lng: 18.7059361 },
    { lat: 49.4369927, lng: 18.7059073 },
    { lat: 49.4370867, lng: 18.7057428 },
    { lat: 49.4371038, lng: 18.7055958 },
    { lat: 49.4370423, lng: 18.7055407 },
    { lat: 49.4370235, lng: 18.7054212 },
    { lat: 49.4369541, lng: 18.7052997 },
    { lat: 49.4369162, lng: 18.7051997 },
    { lat: 49.4369162, lng: 18.7049196 },
    { lat: 49.4369753, lng: 18.7048652 },
    { lat: 49.4373028, lng: 18.7046162 },
    { lat: 49.4373745, lng: 18.7046063 },
    { lat: 49.4374381, lng: 18.704701 },
    { lat: 49.4374634, lng: 18.7047106 },
    { lat: 49.4376198, lng: 18.7045972 },
    { lat: 49.437638, lng: 18.7044942 },
    { lat: 49.4377485, lng: 18.7044469 },
    { lat: 49.4377507, lng: 18.7041908 },
    { lat: 49.4376193, lng: 18.7040454 },
    { lat: 49.4376494, lng: 18.7039754 },
    { lat: 49.4376166, lng: 18.7039077 },
    { lat: 49.4375322, lng: 18.7039344 },
    { lat: 49.4374997, lng: 18.7038514 },
    { lat: 49.4375178, lng: 18.7037043 },
    { lat: 49.4375978, lng: 18.7036578 },
    { lat: 49.4377062, lng: 18.7036604 },
    { lat: 49.4377644, lng: 18.7036315 },
    { lat: 49.4378522, lng: 18.7037602 },
    { lat: 49.4378973, lng: 18.7038862 },
    { lat: 49.4379959, lng: 18.7038737 },
    { lat: 49.4380222, lng: 18.7039701 },
    { lat: 49.4380709, lng: 18.7039511 },
    { lat: 49.4381121, lng: 18.7038326 },
    { lat: 49.4382141, lng: 18.7037363 },
    { lat: 49.4382074, lng: 18.7036082 },
    { lat: 49.4382225, lng: 18.7035715 },
    { lat: 49.4382717, lng: 18.7035441 },
    { lat: 49.4384688, lng: 18.7034503 },
    { lat: 49.4386643, lng: 18.7034614 },
    { lat: 49.4387114, lng: 18.7034136 },
    { lat: 49.4387491, lng: 18.7031408 },
    { lat: 49.4387872, lng: 18.7029654 },
    { lat: 49.4387043, lng: 18.7028256 },
    { lat: 49.4387035, lng: 18.702725 },
    { lat: 49.4388914, lng: 18.7025403 },
    { lat: 49.4389377, lng: 18.7024781 },
    { lat: 49.4389811, lng: 18.7022332 },
    { lat: 49.4389891, lng: 18.7020203 },
    { lat: 49.4389797, lng: 18.7020123 },
    { lat: 49.4389704, lng: 18.7020048 },
    { lat: 49.438974, lng: 18.7019667 },
    { lat: 49.4389474, lng: 18.7018243 },
    { lat: 49.4389127, lng: 18.7017423 },
    { lat: 49.4387992, lng: 18.7016016 },
    { lat: 49.4388054, lng: 18.7015881 },
    { lat: 49.4390586, lng: 18.7015367 },
    { lat: 49.4391493, lng: 18.7016534 },
    { lat: 49.4391626, lng: 18.7016695 },
    { lat: 49.4391723, lng: 18.7016821 },
    { lat: 49.4392901, lng: 18.7014136 },
    { lat: 49.4393545, lng: 18.7011995 },
    { lat: 49.4394597, lng: 18.7010108 },
    { lat: 49.439584, lng: 18.7009118 },
    { lat: 49.4397132, lng: 18.7009028 },
    { lat: 49.4397141, lng: 18.7008741 },
    { lat: 49.4397167, lng: 18.7008154 },
    { lat: 49.439965, lng: 18.7004772 },
    { lat: 49.4399847, lng: 18.7004462 },
    { lat: 49.4399921, lng: 18.7004344 },
    { lat: 49.4399461, lng: 18.7002248 },
    { lat: 49.4399328, lng: 18.6999692 },
    { lat: 49.4399421, lng: 18.6998688 },
    { lat: 49.4400318, lng: 18.6997313 },
    { lat: 49.4400391, lng: 18.6996941 },
    { lat: 49.4399933, lng: 18.6995726 },
    { lat: 49.4398908, lng: 18.6994076 },
    { lat: 49.4398908, lng: 18.6993624 },
    { lat: 49.4399035, lng: 18.6993246 },
    { lat: 49.4398964, lng: 18.69932 },
    { lat: 49.4398803, lng: 18.6993107 },
    { lat: 49.4397532, lng: 18.6992333 },
    { lat: 49.4397651, lng: 18.6991992 },
    { lat: 49.4399083, lng: 18.6989431 },
    { lat: 49.4399736, lng: 18.6988322 },
    { lat: 49.4400756, lng: 18.6989928 },
    { lat: 49.440267, lng: 18.6987725 },
    { lat: 49.440399, lng: 18.6985812 },
    { lat: 49.4403683, lng: 18.698189 },
    { lat: 49.4404705, lng: 18.6980506 },
    { lat: 49.4408244, lng: 18.6975755 },
    { lat: 49.4411648, lng: 18.6971194 },
    { lat: 49.441436, lng: 18.6968066 },
    { lat: 49.4417849, lng: 18.6964078 },
    { lat: 49.4420389, lng: 18.6961326 },
    { lat: 49.442068, lng: 18.6961066 },
    { lat: 49.4421641, lng: 18.6961128 },
    { lat: 49.4422517, lng: 18.6960397 },
    { lat: 49.4424992, lng: 18.6958164 },
    { lat: 49.4425294, lng: 18.6957899 },
    { lat: 49.4426782, lng: 18.6956563 },
    { lat: 49.4427012, lng: 18.6956234 },
    { lat: 49.4428541, lng: 18.6955959 },
    { lat: 49.4430931, lng: 18.695549 },
    { lat: 49.4433171, lng: 18.6953913 },
    { lat: 49.4434745, lng: 18.6954281 },
    { lat: 49.4436082, lng: 18.695138 },
    { lat: 49.4438124, lng: 18.6949676 },
    { lat: 49.4446692, lng: 18.6949638 },
    { lat: 49.4449006, lng: 18.6947987 },
    { lat: 49.4451813, lng: 18.6947938 },
    { lat: 49.4454303, lng: 18.6949867 },
    { lat: 49.4456703, lng: 18.695047 },
    { lat: 49.44589, lng: 18.6949797 },
    { lat: 49.4460432, lng: 18.6949314 },
    { lat: 49.4463081, lng: 18.6947375 },
    { lat: 49.4464935, lng: 18.6946824 },
    { lat: 49.4466327, lng: 18.694521 },
    { lat: 49.4467618, lng: 18.6945305 },
    { lat: 49.4468919, lng: 18.6945779 },
    { lat: 49.4469577, lng: 18.6944817 },
    { lat: 49.4470697, lng: 18.6943998 },
    { lat: 49.4472809, lng: 18.6945172 },
    { lat: 49.4475092, lng: 18.6947392 },
    { lat: 49.4476718, lng: 18.6946796 },
    { lat: 49.4476707, lng: 18.6944888 },
    { lat: 49.4479463, lng: 18.6942377 },
    { lat: 49.4480454, lng: 18.6941041 },
    { lat: 49.4482214, lng: 18.6940444 },
    { lat: 49.4483722, lng: 18.6939504 },
    { lat: 49.4485174, lng: 18.6937958 },
    { lat: 49.4487246, lng: 18.693704 },
    { lat: 49.4488501, lng: 18.6937541 },
    { lat: 49.4489958, lng: 18.6939108 },
    { lat: 49.4491836, lng: 18.6940276 },
    { lat: 49.4494182, lng: 18.6940092 },
    { lat: 49.4496285, lng: 18.6939318 },
    { lat: 49.4498145, lng: 18.693911 },
    { lat: 49.4498547, lng: 18.6937303 },
    { lat: 49.4499378, lng: 18.6936791 },
    { lat: 49.4500047, lng: 18.693689 },
    { lat: 49.4501334, lng: 18.6936688 },
    { lat: 49.4503096, lng: 18.6935463 },
    { lat: 49.45034, lng: 18.6933961 },
    { lat: 49.4504002, lng: 18.6933825 },
    { lat: 49.4506513, lng: 18.6932671 },
    { lat: 49.4507949, lng: 18.6931219 },
    { lat: 49.4508446, lng: 18.6931465 },
    { lat: 49.4510226, lng: 18.6932108 },
    { lat: 49.4510832, lng: 18.6931592 },
    { lat: 49.4511938, lng: 18.6930321 },
    { lat: 49.4512723, lng: 18.6930242 },
    { lat: 49.4513486, lng: 18.6930822 },
    { lat: 49.4514678, lng: 18.6929903 },
    { lat: 49.4515206, lng: 18.692966 },
    { lat: 49.4517045, lng: 18.6928803 },
    { lat: 49.4519515, lng: 18.6927966 },
    { lat: 49.4520905, lng: 18.6926943 },
    { lat: 49.4521294, lng: 18.6926387 },
    { lat: 49.4521578, lng: 18.6925124 },
    { lat: 49.452225, lng: 18.692204 },
    { lat: 49.4522797, lng: 18.6919363 },
    { lat: 49.4526098, lng: 18.6920173 },
    { lat: 49.4527599, lng: 18.6919782 },
    { lat: 49.4530116, lng: 18.6916575 },
    { lat: 49.4532652, lng: 18.6913537 },
    { lat: 49.4533842, lng: 18.6910864 },
    { lat: 49.4535818, lng: 18.6909199 },
    { lat: 49.4540789, lng: 18.6907651 },
    { lat: 49.4544274, lng: 18.6906794 },
    { lat: 49.4545819, lng: 18.6904667 },
    { lat: 49.4546164, lng: 18.6901337 },
    { lat: 49.4548527, lng: 18.6899085 },
    { lat: 49.4549579, lng: 18.6898096 },
    { lat: 49.4550573, lng: 18.6897506 },
    { lat: 49.4550651, lng: 18.6897456 },
    { lat: 49.4550957, lng: 18.6897259 },
    { lat: 49.4552276, lng: 18.6896415 },
    { lat: 49.4553307, lng: 18.6896295 },
    { lat: 49.4555918, lng: 18.6896611 },
    { lat: 49.4556302, lng: 18.6896622 },
    { lat: 49.4557642, lng: 18.6895731 },
    { lat: 49.4558105, lng: 18.6893836 },
    { lat: 49.4558987, lng: 18.6893438 },
    { lat: 49.4561846, lng: 18.689506 },
    { lat: 49.4562971, lng: 18.689308 },
    { lat: 49.456386, lng: 18.6892818 },
    { lat: 49.4564882, lng: 18.6892935 },
    { lat: 49.4565953, lng: 18.689061 },
    { lat: 49.4567271, lng: 18.689056 },
    { lat: 49.4568422, lng: 18.68897 },
    { lat: 49.4569314, lng: 18.6889952 },
    { lat: 49.4570381, lng: 18.6888585 },
    { lat: 49.4570068, lng: 18.688609 },
    { lat: 49.4570813, lng: 18.6883707 },
    { lat: 49.4571813, lng: 18.6882779 },
    { lat: 49.4573687, lng: 18.6883907 },
    { lat: 49.4574299, lng: 18.6882454 },
    { lat: 49.4575458, lng: 18.688176 },
    { lat: 49.4576575, lng: 18.6883902 },
    { lat: 49.4581572, lng: 18.6885257 },
    { lat: 49.4585295, lng: 18.6880542 },
    { lat: 49.4587472, lng: 18.6878856 },
    { lat: 49.4590811, lng: 18.6878005 },
    { lat: 49.4592481, lng: 18.6870919 },
    { lat: 49.4593936, lng: 18.6870732 },
    { lat: 49.4594027, lng: 18.6867335 },
    { lat: 49.4596848, lng: 18.6862409 },
    { lat: 49.4600242, lng: 18.6861537 },
    { lat: 49.4603595, lng: 18.6855808 },
    { lat: 49.4614174, lng: 18.6849829 },
    { lat: 49.4615154, lng: 18.6848095 },
    { lat: 49.4621089, lng: 18.6848809 },
    { lat: 49.4622981, lng: 18.6845733 },
    { lat: 49.4628894, lng: 18.684112 },
    { lat: 49.4633339, lng: 18.6834288 },
    { lat: 49.4634841, lng: 18.68339 },
    { lat: 49.4650071, lng: 18.6811691 },
    { lat: 49.4651642, lng: 18.6810695 },
    { lat: 49.4654636, lng: 18.6803727 },
    { lat: 49.4657484, lng: 18.6799953 },
    { lat: 49.4658788, lng: 18.6799454 },
    { lat: 49.4660953, lng: 18.6792588 },
    { lat: 49.4666887, lng: 18.678364 },
    { lat: 49.4668476, lng: 18.6776116 },
    { lat: 49.4675941, lng: 18.6769638 },
    { lat: 49.4683163, lng: 18.6768673 },
    { lat: 49.4688441, lng: 18.6762687 },
    { lat: 49.4702635, lng: 18.6763937 },
    { lat: 49.4713311, lng: 18.6759799 },
    { lat: 49.4717637, lng: 18.6756929 },
    { lat: 49.4719923, lng: 18.675645 },
    { lat: 49.4722511, lng: 18.6755909 },
    { lat: 49.472278, lng: 18.6755857 },
    { lat: 49.4730976, lng: 18.6754144 },
    { lat: 49.4734203, lng: 18.6752689 },
    { lat: 49.473439, lng: 18.6752601 },
    { lat: 49.4745109, lng: 18.6747768 },
    { lat: 49.4745232, lng: 18.6746053 },
    { lat: 49.4755112, lng: 18.6736635 },
    { lat: 49.475544, lng: 18.6736438 },
    { lat: 49.4764033, lng: 18.6731303 },
    { lat: 49.4764779, lng: 18.6730699 },
    { lat: 49.4765375, lng: 18.6730215 },
    { lat: 49.4766046, lng: 18.6729897 },
    { lat: 49.4767393, lng: 18.6729132 },
    { lat: 49.4767971, lng: 18.6728759 },
    { lat: 49.4768395, lng: 18.6728456 },
    { lat: 49.4769499, lng: 18.6727794 },
    { lat: 49.4771685, lng: 18.6726494 },
    { lat: 49.4772916, lng: 18.6726327 },
    { lat: 49.477315, lng: 18.672628 },
    { lat: 49.4774111, lng: 18.6725221 },
    { lat: 49.4775656, lng: 18.6724196 },
    { lat: 49.4775973, lng: 18.6723569 },
    { lat: 49.477975, lng: 18.672205 },
    { lat: 49.4784291, lng: 18.672093 },
    { lat: 49.478571, lng: 18.6720464 },
    { lat: 49.478783, lng: 18.6720241 },
    { lat: 49.4789141, lng: 18.6720275 },
    { lat: 49.4790005, lng: 18.6720433 },
    { lat: 49.4791394, lng: 18.6720036 },
    { lat: 49.4796355, lng: 18.671528 },
    { lat: 49.4799167, lng: 18.6712586 },
    { lat: 49.4800464, lng: 18.6711337 },
    { lat: 49.480199, lng: 18.6709875 },
    { lat: 49.4803615, lng: 18.6710998 },
    { lat: 49.4809935, lng: 18.6702926 },
    { lat: 49.4810209, lng: 18.6702573 },
    { lat: 49.4816332, lng: 18.6694866 },
    { lat: 49.4822253, lng: 18.6686259 },
    { lat: 49.4822534, lng: 18.6685854 },
    { lat: 49.4828213, lng: 18.6677604 },
    { lat: 49.4851631, lng: 18.6659535 },
    { lat: 49.485187, lng: 18.665936 },
    { lat: 49.4873082, lng: 18.6646347 },
    { lat: 49.4888195, lng: 18.6658187 },
    { lat: 49.4890316, lng: 18.6655835 },
    { lat: 49.4890495, lng: 18.6655613 },
    { lat: 49.4890922, lng: 18.6655143 },
    { lat: 49.4891506, lng: 18.6654487 },
    { lat: 49.4903919, lng: 18.6640481 },
    { lat: 49.4911776, lng: 18.6638096 },
    { lat: 49.4933239, lng: 18.6659819 },
    { lat: 49.4934292, lng: 18.6660327 },
    { lat: 49.4935031, lng: 18.6660689 },
    { lat: 49.4952821, lng: 18.6669381 },
    { lat: 49.4958343, lng: 18.6665668 },
    { lat: 49.4958681, lng: 18.6665441 },
    { lat: 49.4960793, lng: 18.6664036 },
    { lat: 49.4961165, lng: 18.6663791 },
    { lat: 49.4962144, lng: 18.6663132 },
    { lat: 49.4964793, lng: 18.6661371 },
    { lat: 49.4974054, lng: 18.6654792 },
    { lat: 49.4975515, lng: 18.665386 },
    { lat: 49.499348, lng: 18.6641239 },
    { lat: 49.5016627, lng: 18.6635843 },
    { lat: 49.502333, lng: 18.664391 },
    { lat: 49.502814, lng: 18.663322 },
    { lat: 49.503089, lng: 18.662024 },
    { lat: 49.503023, lng: 18.660939 },
    { lat: 49.5030653, lng: 18.660084 },
  ],
  Svrčinovec: [
    { lat: 49.5081342, lng: 18.7876466 },
    { lat: 49.5080345, lng: 18.7873661 },
    { lat: 49.5079204, lng: 18.7870436 },
    { lat: 49.507844, lng: 18.786815 },
    { lat: 49.507811, lng: 18.786718 },
    { lat: 49.507664, lng: 18.786321 },
    { lat: 49.507589, lng: 18.786103 },
    { lat: 49.507535, lng: 18.785959 },
    { lat: 49.507513, lng: 18.785899 },
    { lat: 49.507155, lng: 18.784878 },
    { lat: 49.506525, lng: 18.783263 },
    { lat: 49.506141, lng: 18.782234 },
    { lat: 49.5058395, lng: 18.781442 },
    { lat: 49.505616, lng: 18.7808532 },
    { lat: 49.5052974, lng: 18.7800942 },
    { lat: 49.5049971, lng: 18.7791719 },
    { lat: 49.50444, lng: 18.778306 },
    { lat: 49.5042069, lng: 18.7779505 },
    { lat: 49.5039086, lng: 18.7774968 },
    { lat: 49.5033183, lng: 18.7764357 },
    { lat: 49.502789, lng: 18.776031 },
    { lat: 49.502329, lng: 18.775379 },
    { lat: 49.501775, lng: 18.774333 },
    { lat: 49.501701, lng: 18.774192 },
    { lat: 49.500991, lng: 18.773064 },
    { lat: 49.5005226, lng: 18.7723096 },
    { lat: 49.500064, lng: 18.771453 },
    { lat: 49.4995398, lng: 18.7704919 },
    { lat: 49.4991989, lng: 18.770053 },
    { lat: 49.49875, lng: 18.769283 },
    { lat: 49.49821, lng: 18.76877 },
    { lat: 49.497672, lng: 18.768316 },
    { lat: 49.497453, lng: 18.768168 },
    { lat: 49.497071, lng: 18.767869 },
    { lat: 49.496765, lng: 18.767559 },
    { lat: 49.496189, lng: 18.767047 },
    { lat: 49.4958027, lng: 18.7667368 },
    { lat: 49.495335, lng: 18.766103 },
    { lat: 49.4952687, lng: 18.7660092 },
    { lat: 49.4952335, lng: 18.7659643 },
    { lat: 49.4951955, lng: 18.7659176 },
    { lat: 49.495074, lng: 18.765732 },
    { lat: 49.494834, lng: 18.765185 },
    { lat: 49.494744, lng: 18.764983 },
    { lat: 49.494654, lng: 18.764785 },
    { lat: 49.494533, lng: 18.764531 },
    { lat: 49.4944314, lng: 18.7643116 },
    { lat: 49.4944059, lng: 18.7642567 },
    { lat: 49.494375, lng: 18.76419 },
    { lat: 49.49426, lng: 18.763943 },
    { lat: 49.493699, lng: 18.762799 },
    { lat: 49.493552, lng: 18.762107 },
    { lat: 49.493239, lng: 18.761174 },
    { lat: 49.492947, lng: 18.760425 },
    { lat: 49.492444, lng: 18.759088 },
    { lat: 49.492068, lng: 18.758257 },
    { lat: 49.491837, lng: 18.757746 },
    { lat: 49.491369, lng: 18.757164 },
    { lat: 49.49065, lng: 18.756296 },
    { lat: 49.489765, lng: 18.755737 },
    { lat: 49.489263, lng: 18.755464 },
    { lat: 49.488657, lng: 18.75514 },
    { lat: 49.488455, lng: 18.754922 },
    { lat: 49.488403, lng: 18.754607 },
    { lat: 49.4883587, lng: 18.7546112 },
    { lat: 49.4882435, lng: 18.7545858 },
    { lat: 49.4880896, lng: 18.7550956 },
    { lat: 49.487963, lng: 18.7552014 },
    { lat: 49.4877718, lng: 18.7552954 },
    { lat: 49.4874364, lng: 18.7554376 },
    { lat: 49.4870497, lng: 18.7556306 },
    { lat: 49.4870364, lng: 18.7556798 },
    { lat: 49.4863921, lng: 18.7554752 },
    { lat: 49.4861056, lng: 18.7552353 },
    { lat: 49.4857406, lng: 18.7548069 },
    { lat: 49.4852308, lng: 18.7542345 },
    { lat: 49.4850587, lng: 18.7542008 },
    { lat: 49.4850552, lng: 18.754087 },
    { lat: 49.4839231, lng: 18.752977 },
    { lat: 49.4834701, lng: 18.7531582 },
    { lat: 49.4829107, lng: 18.7536563 },
    { lat: 49.4823149, lng: 18.7535572 },
    { lat: 49.4818417, lng: 18.7533891 },
    { lat: 49.481661, lng: 18.7536914 },
    { lat: 49.4812659, lng: 18.7545752 },
    { lat: 49.4792733, lng: 18.7565913 },
    { lat: 49.47898, lng: 18.7568893 },
    { lat: 49.4778275, lng: 18.7583448 },
    { lat: 49.4769865, lng: 18.75983 },
    { lat: 49.4760915, lng: 18.7614443 },
    { lat: 49.4755109, lng: 18.7627979 },
    { lat: 49.4753637, lng: 18.7628246 },
    { lat: 49.4746449, lng: 18.7636863 },
    { lat: 49.4741174, lng: 18.7641477 },
    { lat: 49.4732618, lng: 18.7650862 },
    { lat: 49.4717666, lng: 18.7667422 },
    { lat: 49.4713541, lng: 18.7667019 },
    { lat: 49.470807, lng: 18.7661062 },
    { lat: 49.4702779, lng: 18.7658835 },
    { lat: 49.470279, lng: 18.766015 },
    { lat: 49.4695341, lng: 18.7663907 },
    { lat: 49.4692978, lng: 18.7677496 },
    { lat: 49.4690901, lng: 18.7689474 },
    { lat: 49.4691409, lng: 18.7696445 },
    { lat: 49.4691733, lng: 18.770879 },
    { lat: 49.4692959, lng: 18.771331 },
    { lat: 49.4693433, lng: 18.7724897 },
    { lat: 49.4693977, lng: 18.7730171 },
    { lat: 49.4692754, lng: 18.7736628 },
    { lat: 49.4687039, lng: 18.7739687 },
    { lat: 49.46806, lng: 18.7744248 },
    { lat: 49.4677539, lng: 18.7751121 },
    { lat: 49.4676947, lng: 18.7758093 },
    { lat: 49.4675668, lng: 18.7773168 },
    { lat: 49.4675544, lng: 18.7775391 },
    { lat: 49.467372, lng: 18.7776084 },
    { lat: 49.4672746, lng: 18.7779262 },
    { lat: 49.4669953, lng: 18.7783463 },
    { lat: 49.4669059, lng: 18.7787976 },
    { lat: 49.4669362, lng: 18.7790821 },
    { lat: 49.4669742, lng: 18.7798366 },
    { lat: 49.4668492, lng: 18.7815654 },
    { lat: 49.4666532, lng: 18.7825114 },
    { lat: 49.4666459, lng: 18.7825652 },
    { lat: 49.466705, lng: 18.7833581 },
    { lat: 49.4666745, lng: 18.7836884 },
    { lat: 49.4666696, lng: 18.7837441 },
    { lat: 49.4666431, lng: 18.7839714 },
    { lat: 49.4666397, lng: 18.7840389 },
    { lat: 49.4666369, lng: 18.784095 },
    { lat: 49.4666343, lng: 18.7841312 },
    { lat: 49.4666214, lng: 18.7843844 },
    { lat: 49.4666158, lng: 18.7844909 },
    { lat: 49.4666326, lng: 18.7844925 },
    { lat: 49.466591, lng: 18.7850602 },
    { lat: 49.4665843, lng: 18.7852129 },
    { lat: 49.4665695, lng: 18.7855409 },
    { lat: 49.4665601, lng: 18.7857527 },
    { lat: 49.4665516, lng: 18.7858487 },
    { lat: 49.4664284, lng: 18.7871745 },
    { lat: 49.4663859, lng: 18.7876415 },
    { lat: 49.4663624, lng: 18.7878187 },
    { lat: 49.4663505, lng: 18.7879402 },
    { lat: 49.4663371, lng: 18.7880288 },
    { lat: 49.4663403, lng: 18.7882473 },
    { lat: 49.4663285, lng: 18.7883659 },
    { lat: 49.4663321, lng: 18.7884767 },
    { lat: 49.4663067, lng: 18.7885751 },
    { lat: 49.4663509, lng: 18.7887547 },
    { lat: 49.4663395, lng: 18.7888184 },
    { lat: 49.466323, lng: 18.7889157 },
    { lat: 49.4663145, lng: 18.7889704 },
    { lat: 49.4663134, lng: 18.7890122 },
    { lat: 49.4663122, lng: 18.7891407 },
    { lat: 49.4663095, lng: 18.7891994 },
    { lat: 49.4663074, lng: 18.789291 },
    { lat: 49.4662998, lng: 18.7893811 },
    { lat: 49.4662726, lng: 18.7896001 },
    { lat: 49.4662621, lng: 18.7896811 },
    { lat: 49.4662485, lng: 18.7897897 },
    { lat: 49.4662412, lng: 18.7898442 },
    { lat: 49.4662259, lng: 18.7899418 },
    { lat: 49.4662044, lng: 18.7900702 },
    { lat: 49.4661739, lng: 18.7901344 },
    { lat: 49.4661189, lng: 18.7902901 },
    { lat: 49.4660582, lng: 18.7902946 },
    { lat: 49.466038, lng: 18.7903612 },
    { lat: 49.4660153, lng: 18.790472 },
    { lat: 49.4660021, lng: 18.7905859 },
    { lat: 49.4659923, lng: 18.7906807 },
    { lat: 49.4659842, lng: 18.7908078 },
    { lat: 49.4659774, lng: 18.7908949 },
    { lat: 49.4659711, lng: 18.7909703 },
    { lat: 49.4659477, lng: 18.7911044 },
    { lat: 49.4659259, lng: 18.7912481 },
    { lat: 49.4659198, lng: 18.7913093 },
    { lat: 49.4659077, lng: 18.7914232 },
    { lat: 49.4660387, lng: 18.7930019 },
    { lat: 49.4659713, lng: 18.7936847 },
    { lat: 49.4657623, lng: 18.7937634 },
    { lat: 49.4654186, lng: 18.7953428 },
    { lat: 49.4650607, lng: 18.7966041 },
    { lat: 49.4649806, lng: 18.7967784 },
    { lat: 49.4642854, lng: 18.7986487 },
    { lat: 49.4641933, lng: 18.7988973 },
    { lat: 49.464601, lng: 18.8005537 },
    { lat: 49.4645419, lng: 18.8006754 },
    { lat: 49.4645102, lng: 18.8007394 },
    { lat: 49.4646222, lng: 18.8011844 },
    { lat: 49.4644768, lng: 18.8016657 },
    { lat: 49.4644037, lng: 18.8022838 },
    { lat: 49.4642608, lng: 18.8029033 },
    { lat: 49.4643705, lng: 18.8034562 },
    { lat: 49.4644604, lng: 18.8034319 },
    { lat: 49.4649358, lng: 18.8046039 },
    { lat: 49.465344, lng: 18.805659 },
    { lat: 49.4658549, lng: 18.8091278 },
    { lat: 49.4662261, lng: 18.8100577 },
    { lat: 49.4667165, lng: 18.81023 },
    { lat: 49.4671532, lng: 18.8105861 },
    { lat: 49.4680893, lng: 18.8130307 },
    { lat: 49.4679756, lng: 18.8148382 },
    { lat: 49.4681343, lng: 18.8163469 },
    { lat: 49.4681383, lng: 18.8179393 },
    { lat: 49.4686814, lng: 18.818329 },
    { lat: 49.4690149, lng: 18.8191805 },
    { lat: 49.469204, lng: 18.819923 },
    { lat: 49.4693578, lng: 18.8205906 },
    { lat: 49.4694721, lng: 18.821742 },
    { lat: 49.4695071, lng: 18.822822 },
    { lat: 49.4693137, lng: 18.824833 },
    { lat: 49.4693797, lng: 18.8248668 },
    { lat: 49.4692602, lng: 18.8251766 },
    { lat: 49.4692686, lng: 18.8252528 },
    { lat: 49.4693171, lng: 18.8253416 },
    { lat: 49.4693358, lng: 18.8253745 },
    { lat: 49.4695708, lng: 18.8258025 },
    { lat: 49.4692762, lng: 18.826346 },
    { lat: 49.470151, lng: 18.8290061 },
    { lat: 49.470252, lng: 18.8297063 },
    { lat: 49.4706008, lng: 18.8320481 },
    { lat: 49.4705927, lng: 18.8350613 },
    { lat: 49.47139, lng: 18.8362972 },
    { lat: 49.4714709, lng: 18.8359633 },
    { lat: 49.471606, lng: 18.836137 },
    { lat: 49.4718959, lng: 18.8359622 },
    { lat: 49.4720895, lng: 18.8369264 },
    { lat: 49.4720461, lng: 18.8373801 },
    { lat: 49.4719704, lng: 18.8381679 },
    { lat: 49.4719542, lng: 18.8383366 },
    { lat: 49.4719915, lng: 18.8390006 },
    { lat: 49.4720307, lng: 18.8397025 },
    { lat: 49.4719228, lng: 18.8397314 },
    { lat: 49.471925, lng: 18.8403025 },
    { lat: 49.4724602, lng: 18.8404516 },
    { lat: 49.4725739, lng: 18.839409 },
    { lat: 49.4728005, lng: 18.8390363 },
    { lat: 49.4731497, lng: 18.8381252 },
    { lat: 49.4733546, lng: 18.8375919 },
    { lat: 49.4734619, lng: 18.836557 },
    { lat: 49.4736701, lng: 18.8360225 },
    { lat: 49.4740133, lng: 18.8356571 },
    { lat: 49.4741384, lng: 18.8355253 },
    { lat: 49.4744015, lng: 18.835246 },
    { lat: 49.4745326, lng: 18.8350507 },
    { lat: 49.4754378, lng: 18.833904 },
    { lat: 49.4771504, lng: 18.8294367 },
    { lat: 49.4772474, lng: 18.8292137 },
    { lat: 49.4772755, lng: 18.8291486 },
    { lat: 49.4773664, lng: 18.8289401 },
    { lat: 49.4776134, lng: 18.8283697 },
    { lat: 49.4783669, lng: 18.8273662 },
    { lat: 49.4797748, lng: 18.8254675 },
    { lat: 49.4798957, lng: 18.8250965 },
    { lat: 49.4799302, lng: 18.824977 },
    { lat: 49.4799516, lng: 18.824911 },
    { lat: 49.4799995, lng: 18.8247711 },
    { lat: 49.4800894, lng: 18.824456 },
    { lat: 49.4801556, lng: 18.8242072 },
    { lat: 49.4801535, lng: 18.8240799 },
    { lat: 49.4802223, lng: 18.8236372 },
    { lat: 49.4803865, lng: 18.8225774 },
    { lat: 49.4809613, lng: 18.8207668 },
    { lat: 49.4809806, lng: 18.8207043 },
    { lat: 49.4810933, lng: 18.8203498 },
    { lat: 49.4817868, lng: 18.8189469 },
    { lat: 49.4818345, lng: 18.8188215 },
    { lat: 49.482188, lng: 18.8178889 },
    { lat: 49.4829233, lng: 18.8174527 },
    { lat: 49.4836353, lng: 18.8168945 },
    { lat: 49.4847626, lng: 18.8153958 },
    { lat: 49.4855641, lng: 18.8141414 },
    { lat: 49.4864431, lng: 18.8130585 },
    { lat: 49.4866158, lng: 18.8128226 },
    { lat: 49.486634, lng: 18.8127993 },
    { lat: 49.4866762, lng: 18.8127475 },
    { lat: 49.4867176, lng: 18.8126946 },
    { lat: 49.4874677, lng: 18.8116726 },
    { lat: 49.4875046, lng: 18.8116224 },
    { lat: 49.4878322, lng: 18.8111751 },
    { lat: 49.4882907, lng: 18.810551 },
    { lat: 49.4883918, lng: 18.8104137 },
    { lat: 49.4884161, lng: 18.8103813 },
    { lat: 49.4884435, lng: 18.8103443 },
    { lat: 49.488491, lng: 18.8102794 },
    { lat: 49.4885046, lng: 18.81026 },
    { lat: 49.4885248, lng: 18.8102329 },
    { lat: 49.4885457, lng: 18.8102043 },
    { lat: 49.4885647, lng: 18.8101784 },
    { lat: 49.4885859, lng: 18.8101497 },
    { lat: 49.4886692, lng: 18.8100367 },
    { lat: 49.4887006, lng: 18.8099908 },
    { lat: 49.4887776, lng: 18.8098669 },
    { lat: 49.4888316, lng: 18.8097769 },
    { lat: 49.4888802, lng: 18.8096934 },
    { lat: 49.4889867, lng: 18.8096131 },
    { lat: 49.4890878, lng: 18.8093655 },
    { lat: 49.4894718, lng: 18.8088965 },
    { lat: 49.4896086, lng: 18.8084186 },
    { lat: 49.4897675, lng: 18.8082195 },
    { lat: 49.4899041, lng: 18.8079814 },
    { lat: 49.4900585, lng: 18.8079137 },
    { lat: 49.4903268, lng: 18.807738 },
    { lat: 49.4904186, lng: 18.807593 },
    { lat: 49.4905321, lng: 18.8073476 },
    { lat: 49.4907049, lng: 18.8074412 },
    { lat: 49.4908449, lng: 18.8073793 },
    { lat: 49.4909301, lng: 18.8072809 },
    { lat: 49.4910257, lng: 18.8069248 },
    { lat: 49.4911895, lng: 18.8067326 },
    { lat: 49.4913901, lng: 18.8065354 },
    { lat: 49.4918468, lng: 18.806076 },
    { lat: 49.4920403, lng: 18.8057363 },
    { lat: 49.4920743, lng: 18.8056528 },
    { lat: 49.4921707, lng: 18.8055116 },
    { lat: 49.492247, lng: 18.8053954 },
    { lat: 49.4923002, lng: 18.805246 },
    { lat: 49.492456, lng: 18.8052703 },
    { lat: 49.4925289, lng: 18.8052412 },
    { lat: 49.4925795, lng: 18.805131 },
    { lat: 49.4926424, lng: 18.8051466 },
    { lat: 49.4926601, lng: 18.8050944 },
    { lat: 49.4927334, lng: 18.805096 },
    { lat: 49.4928381, lng: 18.8050046 },
    { lat: 49.4929677, lng: 18.8048982 },
    { lat: 49.4930092, lng: 18.8047572 },
    { lat: 49.4931345, lng: 18.8046771 },
    { lat: 49.4931924, lng: 18.8045537 },
    { lat: 49.4932113, lng: 18.8042812 },
    { lat: 49.4932969, lng: 18.8042149 },
    { lat: 49.49336, lng: 18.8039948 },
    { lat: 49.493469, lng: 18.8039449 },
    { lat: 49.4934756, lng: 18.8038125 },
    { lat: 49.4935927, lng: 18.8037406 },
    { lat: 49.4936321, lng: 18.8036071 },
    { lat: 49.4937078, lng: 18.8036773 },
    { lat: 49.4937326, lng: 18.8034567 },
    { lat: 49.4938575, lng: 18.8034325 },
    { lat: 49.4940711, lng: 18.8032684 },
    { lat: 49.4942381, lng: 18.8030687 },
    { lat: 49.494398, lng: 18.8029347 },
    { lat: 49.4945614, lng: 18.8028003 },
    { lat: 49.4945984, lng: 18.8026641 },
    { lat: 49.4946976, lng: 18.8026873 },
    { lat: 49.4947646, lng: 18.8025899 },
    { lat: 49.4948711, lng: 18.8024682 },
    { lat: 49.4949193, lng: 18.8022846 },
    { lat: 49.4950501, lng: 18.8021542 },
    { lat: 49.4951424, lng: 18.8018448 },
    { lat: 49.4953784, lng: 18.8016512 },
    { lat: 49.4956976, lng: 18.8012846 },
    { lat: 49.4958183, lng: 18.8006276 },
    { lat: 49.4960444, lng: 18.8003967 },
    { lat: 49.4962064, lng: 18.8000202 },
    { lat: 49.496171, lng: 18.7998373 },
    { lat: 49.4963026, lng: 18.7994147 },
    { lat: 49.4963756, lng: 18.7992945 },
    { lat: 49.4963788, lng: 18.7991418 },
    { lat: 49.4963747, lng: 18.7989279 },
    { lat: 49.4964447, lng: 18.7985161 },
    { lat: 49.4965459, lng: 18.7984447 },
    { lat: 49.4966585, lng: 18.7982225 },
    { lat: 49.4966697, lng: 18.7980452 },
    { lat: 49.4968119, lng: 18.7978045 },
    { lat: 49.4970576, lng: 18.7972767 },
    { lat: 49.4972385, lng: 18.7969167 },
    { lat: 49.4975372, lng: 18.796583 },
    { lat: 49.497552, lng: 18.7965685 },
    { lat: 49.4987446, lng: 18.7958384 },
    { lat: 49.4992584, lng: 18.7950248 },
    { lat: 49.4993876, lng: 18.7949282 },
    { lat: 49.5000837, lng: 18.7942126 },
    { lat: 49.5009001, lng: 18.7937112 },
    { lat: 49.501634, lng: 18.7927264 },
    { lat: 49.5015375, lng: 18.7926688 },
    { lat: 49.5019168, lng: 18.7925234 },
    { lat: 49.5026745, lng: 18.7919787 },
    { lat: 49.5035538, lng: 18.791055 },
    { lat: 49.5041606, lng: 18.7900997 },
    { lat: 49.50445, lng: 18.7896956 },
    { lat: 49.505308, lng: 18.7889507 },
    { lat: 49.5066895, lng: 18.7881915 },
    { lat: 49.5081342, lng: 18.7876466 },
  ],
  VysokánadKysucou: [
    { lat: 49.3764902, lng: 18.4884338 },
    { lat: 49.3763973, lng: 18.4896973 },
    { lat: 49.3763358, lng: 18.4899357 },
    { lat: 49.3762747, lng: 18.4909704 },
    { lat: 49.3762786, lng: 18.4914564 },
    { lat: 49.3762397, lng: 18.4925078 },
    { lat: 49.3759767, lng: 18.4936687 },
    { lat: 49.3758577, lng: 18.4945348 },
    { lat: 49.3757654, lng: 18.4948893 },
    { lat: 49.3753215, lng: 18.4952951 },
    { lat: 49.3752595, lng: 18.4953373 },
    { lat: 49.3751201, lng: 18.49543 },
    { lat: 49.375031, lng: 18.4954898 },
    { lat: 49.3745868, lng: 18.4958282 },
    { lat: 49.3745034, lng: 18.4958678 },
    { lat: 49.3741535, lng: 18.4960317 },
    { lat: 49.374021, lng: 18.4961253 },
    { lat: 49.3739862, lng: 18.49615 },
    { lat: 49.3739228, lng: 18.4961877 },
    { lat: 49.3738857, lng: 18.4962098 },
    { lat: 49.3737621, lng: 18.4964065 },
    { lat: 49.3736831, lng: 18.496444 },
    { lat: 49.3736268, lng: 18.4964707 },
    { lat: 49.3734213, lng: 18.4965689 },
    { lat: 49.3733677, lng: 18.4967433 },
    { lat: 49.3736016, lng: 18.4972742 },
    { lat: 49.3736421, lng: 18.4976245 },
    { lat: 49.3735921, lng: 18.4979721 },
    { lat: 49.3734623, lng: 18.4984073 },
    { lat: 49.3734269, lng: 18.4985262 },
    { lat: 49.3732027, lng: 18.4988631 },
    { lat: 49.3729608, lng: 18.4992876 },
    { lat: 49.3729893, lng: 18.4995044 },
    { lat: 49.3729996, lng: 18.4995686 },
    { lat: 49.3731662, lng: 18.5000216 },
    { lat: 49.3731011, lng: 18.5000682 },
    { lat: 49.3730607, lng: 18.5001128 },
    { lat: 49.3729961, lng: 18.5001713 },
    { lat: 49.3729501, lng: 18.5002173 },
    { lat: 49.3729158, lng: 18.5002507 },
    { lat: 49.3727102, lng: 18.5002424 },
    { lat: 49.3724687, lng: 18.5003049 },
    { lat: 49.372361, lng: 18.5003544 },
    { lat: 49.3720847, lng: 18.5004402 },
    { lat: 49.3719749, lng: 18.5004737 },
    { lat: 49.371685, lng: 18.5005575 },
    { lat: 49.3712971, lng: 18.5008269 },
    { lat: 49.3710695, lng: 18.5008322 },
    { lat: 49.3709417, lng: 18.5008901 },
    { lat: 49.3706601, lng: 18.500919 },
    { lat: 49.370403, lng: 18.5010026 },
    { lat: 49.3701425, lng: 18.5001501 },
    { lat: 49.3699356, lng: 18.499201 },
    { lat: 49.3694236, lng: 18.4992643 },
    { lat: 49.3687069, lng: 18.4994204 },
    { lat: 49.3685811, lng: 18.4994929 },
    { lat: 49.3679731, lng: 18.4996172 },
    { lat: 49.36764, lng: 18.4996113 },
    { lat: 49.3667327, lng: 18.4997899 },
    { lat: 49.3659941, lng: 18.4998956 },
    { lat: 49.365957, lng: 18.4999 },
    { lat: 49.3657868, lng: 18.499964 },
    { lat: 49.3653507, lng: 18.5000541 },
    { lat: 49.3652775, lng: 18.5001792 },
    { lat: 49.364746, lng: 18.5000918 },
    { lat: 49.364319, lng: 18.5001019 },
    { lat: 49.3641239, lng: 18.5000604 },
    { lat: 49.3632515, lng: 18.50005 },
    { lat: 49.3630129, lng: 18.4998918 },
    { lat: 49.3629698, lng: 18.4998856 },
    { lat: 49.3628719, lng: 18.4998713 },
    { lat: 49.3627555, lng: 18.499991 },
    { lat: 49.3627363, lng: 18.5000738 },
    { lat: 49.362343, lng: 18.500038 },
    { lat: 49.3622368, lng: 18.4999534 },
    { lat: 49.362011, lng: 18.5000085 },
    { lat: 49.3618334, lng: 18.4999149 },
    { lat: 49.3620812, lng: 18.4995986 },
    { lat: 49.3621934, lng: 18.4993401 },
    { lat: 49.3622006, lng: 18.4993248 },
    { lat: 49.3622578, lng: 18.4991934 },
    { lat: 49.3622808, lng: 18.4991387 },
    { lat: 49.3625476, lng: 18.4985269 },
    { lat: 49.3628059, lng: 18.4981127 },
    { lat: 49.3623819, lng: 18.4977798 },
    { lat: 49.3622804, lng: 18.4976559 },
    { lat: 49.3620013, lng: 18.4974785 },
    { lat: 49.361658, lng: 18.4973441 },
    { lat: 49.3612634, lng: 18.4974521 },
    { lat: 49.3607868, lng: 18.4974617 },
    { lat: 49.3604515, lng: 18.4973939 },
    { lat: 49.3599246, lng: 18.4974522 },
    { lat: 49.3595115, lng: 18.4974066 },
    { lat: 49.3588525, lng: 18.4977099 },
    { lat: 49.3585363, lng: 18.4979549 },
    { lat: 49.3582346, lng: 18.4980261 },
    { lat: 49.3570092, lng: 18.4987245 },
    { lat: 49.3566966, lng: 18.4988058 },
    { lat: 49.3563068, lng: 18.4991054 },
    { lat: 49.3558557, lng: 18.4999391 },
    { lat: 49.3554885, lng: 18.499697 },
    { lat: 49.3551213, lng: 18.499249 },
    { lat: 49.3546094, lng: 18.4987091 },
    { lat: 49.3544084, lng: 18.4988283 },
    { lat: 49.3534109, lng: 18.4990918 },
    { lat: 49.3521329, lng: 18.4994006 },
    { lat: 49.3518066, lng: 18.499293 },
    { lat: 49.3514002, lng: 18.4994741 },
    { lat: 49.3510871, lng: 18.4997323 },
    { lat: 49.3504899, lng: 18.4999474 },
    { lat: 49.3501944, lng: 18.5000541 },
    { lat: 49.3501691, lng: 18.5000631 },
    { lat: 49.3500941, lng: 18.500074 },
    { lat: 49.3498749, lng: 18.5001073 },
    { lat: 49.3496533, lng: 18.5001401 },
    { lat: 49.3496275, lng: 18.5001441 },
    { lat: 49.3494699, lng: 18.5001703 },
    { lat: 49.3491726, lng: 18.5002196 },
    { lat: 49.3488816, lng: 18.5002403 },
    { lat: 49.3488852, lng: 18.5002879 },
    { lat: 49.3488972, lng: 18.5004449 },
    { lat: 49.3489366, lng: 18.5009445 },
    { lat: 49.3489403, lng: 18.5009922 },
    { lat: 49.3484689, lng: 18.5009279 },
    { lat: 49.3478532, lng: 18.5007434 },
    { lat: 49.347476, lng: 18.5008192 },
    { lat: 49.347179, lng: 18.501138 },
    { lat: 49.347196, lng: 18.501147 },
    { lat: 49.347305, lng: 18.501204 },
    { lat: 49.347761, lng: 18.501582 },
    { lat: 49.347777, lng: 18.501843 },
    { lat: 49.348081, lng: 18.50236 },
    { lat: 49.34822, lng: 18.502608 },
    { lat: 49.348335, lng: 18.502878 },
    { lat: 49.348386, lng: 18.503132 },
    { lat: 49.348624, lng: 18.50352 },
    { lat: 49.348645, lng: 18.503548 },
    { lat: 49.348819, lng: 18.503791 },
    { lat: 49.349181, lng: 18.504122 },
    { lat: 49.349274, lng: 18.504254 },
    { lat: 49.349338, lng: 18.504271 },
    { lat: 49.349641, lng: 18.504586 },
    { lat: 49.349646, lng: 18.504832 },
    { lat: 49.34975, lng: 18.505068 },
    { lat: 49.349917, lng: 18.505551 },
    { lat: 49.349954, lng: 18.505958 },
    { lat: 49.350147, lng: 18.506593 },
    { lat: 49.350197, lng: 18.506705 },
    { lat: 49.350243, lng: 18.506826 },
    { lat: 49.350202, lng: 18.507221 },
    { lat: 49.350166, lng: 18.50751 },
    { lat: 49.350292, lng: 18.507945 },
    { lat: 49.350519, lng: 18.508176 },
    { lat: 49.350566, lng: 18.508305 },
    { lat: 49.35076, lng: 18.508699 },
    { lat: 49.35081, lng: 18.508957 },
    { lat: 49.351083, lng: 18.509458 },
    { lat: 49.351337, lng: 18.510009 },
    { lat: 49.351707, lng: 18.510533 },
    { lat: 49.35176, lng: 18.51068 },
    { lat: 49.351915, lng: 18.510852 },
    { lat: 49.352257, lng: 18.511031 },
    { lat: 49.352511, lng: 18.51135 },
    { lat: 49.352605, lng: 18.511461 },
    { lat: 49.352693, lng: 18.511482 },
    { lat: 49.352967, lng: 18.51151 },
    { lat: 49.352965, lng: 18.511718 },
    { lat: 49.353001, lng: 18.511774 },
    { lat: 49.353049, lng: 18.51177 },
    { lat: 49.353125, lng: 18.511729 },
    { lat: 49.353208, lng: 18.511873 },
    { lat: 49.353157, lng: 18.511976 },
    { lat: 49.353202, lng: 18.512008 },
    { lat: 49.353398, lng: 18.512061 },
    { lat: 49.353494, lng: 18.512038 },
    { lat: 49.353567, lng: 18.512157 },
    { lat: 49.353858, lng: 18.512211 },
    { lat: 49.353911, lng: 18.512349 },
    { lat: 49.353946, lng: 18.512549 },
    { lat: 49.354105, lng: 18.512918 },
    { lat: 49.354294, lng: 18.512872 },
    { lat: 49.354479, lng: 18.513078 },
    { lat: 49.354523, lng: 18.513267 },
    { lat: 49.35459, lng: 18.513389 },
    { lat: 49.354575, lng: 18.513409 },
    { lat: 49.354549, lng: 18.513484 },
    { lat: 49.354576, lng: 18.513514 },
    { lat: 49.354559, lng: 18.513607 },
    { lat: 49.354506, lng: 18.513795 },
    { lat: 49.354437, lng: 18.513837 },
    { lat: 49.354378, lng: 18.513807 },
    { lat: 49.354326, lng: 18.513771 },
    { lat: 49.354317, lng: 18.513806 },
    { lat: 49.3543, lng: 18.513985 },
    { lat: 49.354248, lng: 18.514036 },
    { lat: 49.354208, lng: 18.514008 },
    { lat: 49.354083, lng: 18.513807 },
    { lat: 49.353987, lng: 18.513697 },
    { lat: 49.353784, lng: 18.513792 },
    { lat: 49.353649, lng: 18.513876 },
    { lat: 49.35353, lng: 18.513967 },
    { lat: 49.353326, lng: 18.513951 },
    { lat: 49.35312, lng: 18.514101 },
    { lat: 49.352996, lng: 18.514168 },
    { lat: 49.353003, lng: 18.514189 },
    { lat: 49.352763, lng: 18.514248 },
    { lat: 49.352481, lng: 18.514164 },
    { lat: 49.352239, lng: 18.514205 },
    { lat: 49.351591, lng: 18.514475 },
    { lat: 49.351596, lng: 18.514451 },
    { lat: 49.351576, lng: 18.514467 },
    { lat: 49.351473, lng: 18.514799 },
    { lat: 49.351132, lng: 18.515045 },
    { lat: 49.351002, lng: 18.515043 },
    { lat: 49.350631, lng: 18.515499 },
    { lat: 49.350477, lng: 18.515659 },
    { lat: 49.35025, lng: 18.515858 },
    { lat: 49.350017, lng: 18.515757 },
    { lat: 49.350012, lng: 18.51581 },
    { lat: 49.349989, lng: 18.515939 },
    { lat: 49.349811, lng: 18.516351 },
    { lat: 49.349672, lng: 18.516648 },
    { lat: 49.349562, lng: 18.517004 },
    { lat: 49.349378, lng: 18.517416 },
    { lat: 49.349007, lng: 18.517999 },
    { lat: 49.348759, lng: 18.518439 },
    { lat: 49.348655, lng: 18.519121 },
    { lat: 49.348516, lng: 18.519612 },
    { lat: 49.348357, lng: 18.520296 },
    { lat: 49.348241, lng: 18.520577 },
    { lat: 49.348059, lng: 18.521158 },
    { lat: 49.347813, lng: 18.522055 },
    { lat: 49.347671, lng: 18.52249 },
    { lat: 49.347652, lng: 18.523116 },
    { lat: 49.34769, lng: 18.523696 },
    { lat: 49.347713, lng: 18.524165 },
    { lat: 49.347769, lng: 18.524485 },
    { lat: 49.347777, lng: 18.524722 },
    { lat: 49.347829, lng: 18.525256 },
    { lat: 49.348461, lng: 18.525264 },
    { lat: 49.348701, lng: 18.525987 },
    { lat: 49.348861, lng: 18.526557 },
    { lat: 49.349605, lng: 18.527531 },
    { lat: 49.350233, lng: 18.528094 },
    { lat: 49.350573, lng: 18.528412 },
    { lat: 49.351005, lng: 18.52903 },
    { lat: 49.351247, lng: 18.529493 },
    { lat: 49.351505, lng: 18.530138 },
    { lat: 49.351674, lng: 18.530852 },
    { lat: 49.351801, lng: 18.531621 },
    { lat: 49.352009, lng: 18.532043 },
    { lat: 49.352062, lng: 18.53344 },
    { lat: 49.352085, lng: 18.533835 },
    { lat: 49.352174, lng: 18.534609 },
    { lat: 49.352376, lng: 18.535351 },
    { lat: 49.352592, lng: 18.535702 },
    { lat: 49.352682, lng: 18.535908 },
    { lat: 49.352919, lng: 18.536502 },
    { lat: 49.353063, lng: 18.53675 },
    { lat: 49.353883, lng: 18.537748 },
    { lat: 49.354039, lng: 18.537844 },
    { lat: 49.354369, lng: 18.537952 },
    { lat: 49.354678, lng: 18.537933 },
    { lat: 49.354976, lng: 18.538128 },
    { lat: 49.355372, lng: 18.538252 },
    { lat: 49.355683, lng: 18.538448 },
    { lat: 49.356115, lng: 18.538654 },
    { lat: 49.356352, lng: 18.538822 },
    { lat: 49.356477, lng: 18.538934 },
    { lat: 49.356993, lng: 18.539486 },
    { lat: 49.357677, lng: 18.540359 },
    { lat: 49.357833, lng: 18.540558 },
    { lat: 49.358035, lng: 18.540927 },
    { lat: 49.358256, lng: 18.541033 },
    { lat: 49.358446, lng: 18.541399 },
    { lat: 49.35862, lng: 18.541548 },
    { lat: 49.358755, lng: 18.541741 },
    { lat: 49.359023, lng: 18.541983 },
    { lat: 49.359194, lng: 18.542167 },
    { lat: 49.359254, lng: 18.542299 },
    { lat: 49.359338, lng: 18.542538 },
    { lat: 49.359493, lng: 18.542746 },
    { lat: 49.359633, lng: 18.542929 },
    { lat: 49.359847, lng: 18.543059 },
    { lat: 49.360074, lng: 18.543176 },
    { lat: 49.360296, lng: 18.543273 },
    { lat: 49.360374, lng: 18.543433 },
    { lat: 49.360192, lng: 18.543723 },
    { lat: 49.359862, lng: 18.54414 },
    { lat: 49.359654, lng: 18.544303 },
    { lat: 49.359389, lng: 18.544578 },
    { lat: 49.359208, lng: 18.544767 },
    { lat: 49.358658, lng: 18.544985 },
    { lat: 49.358663, lng: 18.545012 },
    { lat: 49.358656, lng: 18.545495 },
    { lat: 49.358654, lng: 18.545537 },
    { lat: 49.358849, lng: 18.545497 },
    { lat: 49.35901, lng: 18.54551 },
    { lat: 49.359078, lng: 18.545541 },
    { lat: 49.359216, lng: 18.545689 },
    { lat: 49.35926, lng: 18.545867 },
    { lat: 49.359316, lng: 18.545986 },
    { lat: 49.359437, lng: 18.546292 },
    { lat: 49.359672, lng: 18.546624 },
    { lat: 49.359648, lng: 18.546857 },
    { lat: 49.359669, lng: 18.547012 },
    { lat: 49.359779, lng: 18.547341 },
    { lat: 49.359837, lng: 18.547612 },
    { lat: 49.35955, lng: 18.548283 },
    { lat: 49.359609, lng: 18.548968 },
    { lat: 49.359677, lng: 18.548995 },
    { lat: 49.359811, lng: 18.549048 },
    { lat: 49.359701, lng: 18.549411 },
    { lat: 49.359668, lng: 18.549522 },
    { lat: 49.359635, lng: 18.549678 },
    { lat: 49.359578, lng: 18.549958 },
    { lat: 49.359502, lng: 18.550252 },
    { lat: 49.359494, lng: 18.550288 },
    { lat: 49.359448, lng: 18.550471 },
    { lat: 49.359441, lng: 18.550497 },
    { lat: 49.359337, lng: 18.550797 },
    { lat: 49.359126, lng: 18.551409 },
    { lat: 49.359048, lng: 18.551636 },
    { lat: 49.35902, lng: 18.551898 },
    { lat: 49.35904, lng: 18.551911 },
    { lat: 49.359014, lng: 18.552074 },
    { lat: 49.358966, lng: 18.552152 },
    { lat: 49.358816, lng: 18.552375 },
    { lat: 49.35881, lng: 18.552464 },
    { lat: 49.358925, lng: 18.553155 },
    { lat: 49.359008, lng: 18.553428 },
    { lat: 49.358911, lng: 18.553767 },
    { lat: 49.358958, lng: 18.554339 },
    { lat: 49.358875, lng: 18.554685 },
    { lat: 49.358922, lng: 18.555568 },
    { lat: 49.35888, lng: 18.555923 },
    { lat: 49.358933, lng: 18.556073 },
    { lat: 49.358949, lng: 18.556366 },
    { lat: 49.359117, lng: 18.557024 },
    { lat: 49.359166, lng: 18.557307 },
    { lat: 49.359277, lng: 18.557487 },
    { lat: 49.359327, lng: 18.558004 },
    { lat: 49.359363, lng: 18.558096 },
    { lat: 49.359368, lng: 18.558192 },
    { lat: 49.359343, lng: 18.558283 },
    { lat: 49.359255, lng: 18.558396 },
    { lat: 49.359215, lng: 18.55867 },
    { lat: 49.359181, lng: 18.558705 },
    { lat: 49.359043, lng: 18.559134 },
    { lat: 49.359004, lng: 18.559261 },
    { lat: 49.358769, lng: 18.559301 },
    { lat: 49.358706, lng: 18.559422 },
    { lat: 49.358515, lng: 18.559499 },
    { lat: 49.358506, lng: 18.55955 },
    { lat: 49.358433, lng: 18.559674 },
    { lat: 49.358232, lng: 18.559757 },
    { lat: 49.358147, lng: 18.559782 },
    { lat: 49.358083, lng: 18.559845 },
    { lat: 49.358075, lng: 18.560051 },
    { lat: 49.35796, lng: 18.560284 },
    { lat: 49.357849, lng: 18.560411 },
    { lat: 49.357746, lng: 18.560469 },
    { lat: 49.357736, lng: 18.560605 },
    { lat: 49.357698, lng: 18.560727 },
    { lat: 49.357516, lng: 18.560713 },
    { lat: 49.357326, lng: 18.561 },
    { lat: 49.357297, lng: 18.561039 },
    { lat: 49.357188, lng: 18.561065 },
    { lat: 49.356808, lng: 18.561417 },
    { lat: 49.356701, lng: 18.561682 },
    { lat: 49.356622, lng: 18.561706 },
    { lat: 49.356312, lng: 18.561788 },
    { lat: 49.356222, lng: 18.561961 },
    { lat: 49.35613, lng: 18.562095 },
    { lat: 49.355867, lng: 18.562153 },
    { lat: 49.355777, lng: 18.562364 },
    { lat: 49.355727, lng: 18.562593 },
    { lat: 49.355555, lng: 18.562557 },
    { lat: 49.355418, lng: 18.562316 },
    { lat: 49.355336, lng: 18.562374 },
    { lat: 49.355048, lng: 18.56276 },
    { lat: 49.35507, lng: 18.562795 },
    { lat: 49.35529, lng: 18.563094 },
    { lat: 49.355408, lng: 18.563254 },
    { lat: 49.355424, lng: 18.563381 },
    { lat: 49.355451, lng: 18.563588 },
    { lat: 49.355471, lng: 18.563756 },
    { lat: 49.355627, lng: 18.56429 },
    { lat: 49.355974, lng: 18.565602 },
    { lat: 49.356067, lng: 18.565784 },
    { lat: 49.356114, lng: 18.565993 },
    { lat: 49.356218, lng: 18.56603 },
    { lat: 49.356253, lng: 18.566266 },
    { lat: 49.356264, lng: 18.566341 },
    { lat: 49.356276, lng: 18.566422 },
    { lat: 49.356286, lng: 18.566481 },
    { lat: 49.356361, lng: 18.566664 },
    { lat: 49.356577, lng: 18.56719 },
    { lat: 49.356679, lng: 18.567438 },
    { lat: 49.356809, lng: 18.567755 },
    { lat: 49.356821, lng: 18.567782 },
    { lat: 49.356569, lng: 18.568036 },
    { lat: 49.356407, lng: 18.568028 },
    { lat: 49.355976, lng: 18.568434 },
    { lat: 49.355878, lng: 18.568465 },
    { lat: 49.355882, lng: 18.568505 },
    { lat: 49.3556, lng: 18.568766 },
    { lat: 49.355735, lng: 18.569306 },
    { lat: 49.355799, lng: 18.569671 },
    { lat: 49.355688, lng: 18.569669 },
    { lat: 49.355464, lng: 18.569663 },
    { lat: 49.355443, lng: 18.569658 },
    { lat: 49.355001, lng: 18.569556 },
    { lat: 49.355012, lng: 18.570534 },
    { lat: 49.35485, lng: 18.570478 },
    { lat: 49.354777, lng: 18.570453 },
    { lat: 49.354663, lng: 18.570415 },
    { lat: 49.354543, lng: 18.570986 },
    { lat: 49.354285, lng: 18.571398 },
    { lat: 49.354254, lng: 18.571971 },
    { lat: 49.354336, lng: 18.572219 },
    { lat: 49.354363, lng: 18.572845 },
    { lat: 49.353962, lng: 18.573353 },
    { lat: 49.353795, lng: 18.574012 },
    { lat: 49.353573, lng: 18.574437 },
    { lat: 49.353628, lng: 18.574501 },
    { lat: 49.353843, lng: 18.574746 },
    { lat: 49.354243, lng: 18.575205 },
    { lat: 49.354719, lng: 18.575807 },
    { lat: 49.355179, lng: 18.576122 },
    { lat: 49.355193, lng: 18.576097 },
    { lat: 49.355453, lng: 18.576401 },
    { lat: 49.355644, lng: 18.57693 },
    { lat: 49.356028, lng: 18.577007 },
    { lat: 49.356479, lng: 18.577047 },
    { lat: 49.356673, lng: 18.576932 },
    { lat: 49.356845, lng: 18.576796 },
    { lat: 49.357211, lng: 18.576785 },
    { lat: 49.357383, lng: 18.576712 },
    { lat: 49.357641, lng: 18.576658 },
    { lat: 49.357727, lng: 18.576571 },
    { lat: 49.35804, lng: 18.576493 },
    { lat: 49.358138, lng: 18.576518 },
    { lat: 49.358217, lng: 18.576565 },
    { lat: 49.358351, lng: 18.576543 },
    { lat: 49.358721, lng: 18.576673 },
    { lat: 49.358982, lng: 18.576667 },
    { lat: 49.35925, lng: 18.576796 },
    { lat: 49.359605, lng: 18.576861 },
    { lat: 49.359602, lng: 18.576921 },
    { lat: 49.359572, lng: 18.577244 },
    { lat: 49.359568, lng: 18.577282 },
    { lat: 49.359564, lng: 18.57732 },
    { lat: 49.359531, lng: 18.577665 },
    { lat: 49.359488, lng: 18.578122 },
    { lat: 49.35947, lng: 18.578312 },
    { lat: 49.359457, lng: 18.578449 },
    { lat: 49.359433, lng: 18.57869 },
    { lat: 49.359084, lng: 18.57854 },
    { lat: 49.358946, lng: 18.578482 },
    { lat: 49.358911, lng: 18.578466 },
    { lat: 49.358808, lng: 18.578423 },
    { lat: 49.358657, lng: 18.578358 },
    { lat: 49.35849, lng: 18.578287 },
    { lat: 49.358421, lng: 18.578509 },
    { lat: 49.358253, lng: 18.57905 },
    { lat: 49.358198, lng: 18.579247 },
    { lat: 49.358133, lng: 18.579481 },
    { lat: 49.358116, lng: 18.579544 },
    { lat: 49.357579, lng: 18.580528 },
    { lat: 49.357378, lng: 18.58137 },
    { lat: 49.357292, lng: 18.581941 },
    { lat: 49.357704, lng: 18.581995 },
    { lat: 49.35822, lng: 18.582059 },
    { lat: 49.35918, lng: 18.582071 },
    { lat: 49.359784, lng: 18.582223 },
    { lat: 49.360238, lng: 18.582553 },
    { lat: 49.360447, lng: 18.582656 },
    { lat: 49.36071, lng: 18.582808 },
    { lat: 49.360903, lng: 18.583102 },
    { lat: 49.361572, lng: 18.583541 },
    { lat: 49.361981, lng: 18.583591 },
    { lat: 49.362397, lng: 18.583489 },
    { lat: 49.36276, lng: 18.583645 },
    { lat: 49.362973, lng: 18.583848 },
    { lat: 49.363015, lng: 18.583911 },
    { lat: 49.363113, lng: 18.584057 },
    { lat: 49.363319, lng: 18.584367 },
    { lat: 49.363337, lng: 18.584394 },
    { lat: 49.363394, lng: 18.584479 },
    { lat: 49.363481, lng: 18.58461 },
    { lat: 49.364214, lng: 18.584167 },
    { lat: 49.364631, lng: 18.583915 },
    { lat: 49.364703, lng: 18.583872 },
    { lat: 49.36501, lng: 18.584111 },
    { lat: 49.365043, lng: 18.584105 },
    { lat: 49.36507, lng: 18.5841 },
    { lat: 49.365077, lng: 18.584603 },
    { lat: 49.3651878, lng: 18.5848139 },
    { lat: 49.3652362, lng: 18.584835 },
    { lat: 49.3652623, lng: 18.5848144 },
    { lat: 49.3653182, lng: 18.5848924 },
    { lat: 49.3655107, lng: 18.5848411 },
    { lat: 49.3656221, lng: 18.5848793 },
    { lat: 49.3657437, lng: 18.5848546 },
    { lat: 49.365896, lng: 18.58471 },
    { lat: 49.3661079, lng: 18.5846654 },
    { lat: 49.3661687, lng: 18.5847453 },
    { lat: 49.3662834, lng: 18.584698 },
    { lat: 49.3664374, lng: 18.5845597 },
    { lat: 49.3665475, lng: 18.5845766 },
    { lat: 49.3665882, lng: 18.5846631 },
    { lat: 49.3665471, lng: 18.5848224 },
    { lat: 49.3664271, lng: 18.5849594 },
    { lat: 49.3664301, lng: 18.5850165 },
    { lat: 49.3664846, lng: 18.5851081 },
    { lat: 49.3665217, lng: 18.584979 },
    { lat: 49.3666135, lng: 18.5850518 },
    { lat: 49.3666282, lng: 18.5853058 },
    { lat: 49.3667843, lng: 18.5852095 },
    { lat: 49.3668406, lng: 18.5852285 },
    { lat: 49.3669031, lng: 18.5853415 },
    { lat: 49.3671203, lng: 18.58531 },
    { lat: 49.3673503, lng: 18.5854364 },
    { lat: 49.3673812, lng: 18.5852748 },
    { lat: 49.3674622, lng: 18.5852961 },
    { lat: 49.3675417, lng: 18.5854302 },
    { lat: 49.3674947, lng: 18.5855634 },
    { lat: 49.3676088, lng: 18.5856736 },
    { lat: 49.3677493, lng: 18.5855399 },
    { lat: 49.3678191, lng: 18.5855814 },
    { lat: 49.3678661, lng: 18.5857829 },
    { lat: 49.3679551, lng: 18.5858658 },
    { lat: 49.3682338, lng: 18.585426 },
    { lat: 49.3683543, lng: 18.5852336 },
    { lat: 49.3683852, lng: 18.5849684 },
    { lat: 49.3684232, lng: 18.5846445 },
    { lat: 49.3684391, lng: 18.5845166 },
    { lat: 49.3690406, lng: 18.5847785 },
    { lat: 49.3698766, lng: 18.5852911 },
    { lat: 49.3707221, lng: 18.5859621 },
    { lat: 49.3707494, lng: 18.5859855 },
    { lat: 49.371258, lng: 18.5864114 },
    { lat: 49.3712889, lng: 18.5864379 },
    { lat: 49.3717282, lng: 18.5867861 },
    { lat: 49.3719092, lng: 18.5868839 },
    { lat: 49.3723297, lng: 18.5871099 },
    { lat: 49.3731044, lng: 18.5874927 },
    { lat: 49.3732748, lng: 18.5875771 },
    { lat: 49.3736003, lng: 18.5877375 },
    { lat: 49.3741727, lng: 18.5878801 },
    { lat: 49.3741708, lng: 18.5879455 },
    { lat: 49.3746005, lng: 18.5880755 },
    { lat: 49.374921, lng: 18.5881197 },
    { lat: 49.3752341, lng: 18.5880721 },
    { lat: 49.3751082, lng: 18.5875501 },
    { lat: 49.3751032, lng: 18.5873285 },
    { lat: 49.3750283, lng: 18.5870447 },
    { lat: 49.3749778, lng: 18.5869667 },
    { lat: 49.3749434, lng: 18.5869153 },
    { lat: 49.3748353, lng: 18.5867508 },
    { lat: 49.3748732, lng: 18.58615 },
    { lat: 49.3748079, lng: 18.5855613 },
    { lat: 49.3747132, lng: 18.5850756 },
    { lat: 49.3747073, lng: 18.5850495 },
    { lat: 49.374677, lng: 18.5848615 },
    { lat: 49.3745443, lng: 18.584045 },
    { lat: 49.3745481, lng: 18.583506 },
    { lat: 49.3746662, lng: 18.5827178 },
    { lat: 49.3746103, lng: 18.582305 },
    { lat: 49.3745827, lng: 18.581767 },
    { lat: 49.3743896, lng: 18.5812979 },
    { lat: 49.3743501, lng: 18.5806869 },
    { lat: 49.3743369, lng: 18.5801215 },
    { lat: 49.374246, lng: 18.5795845 },
    { lat: 49.3741933, lng: 18.5791239 },
    { lat: 49.3741676, lng: 18.5787102 },
    { lat: 49.374011, lng: 18.5782246 },
    { lat: 49.3743799, lng: 18.5785428 },
    { lat: 49.3747006, lng: 18.5786597 },
    { lat: 49.3750834, lng: 18.5789841 },
    { lat: 49.3754282, lng: 18.5793777 },
    { lat: 49.3759436, lng: 18.5793774 },
    { lat: 49.3760078, lng: 18.579377 },
    { lat: 49.3761671, lng: 18.579509 },
    { lat: 49.3762306, lng: 18.5796187 },
    { lat: 49.3766387, lng: 18.5802099 },
    { lat: 49.3768597, lng: 18.5806321 },
    { lat: 49.3769738, lng: 18.5808241 },
    { lat: 49.3771348, lng: 18.580902 },
    { lat: 49.377309, lng: 18.5811454 },
    { lat: 49.3774375, lng: 18.5811438 },
    { lat: 49.3776009, lng: 18.5811414 },
    { lat: 49.3777726, lng: 18.5811886 },
    { lat: 49.3778815, lng: 18.5812626 },
    { lat: 49.3780638, lng: 18.5812774 },
    { lat: 49.3781504, lng: 18.5813588 },
    { lat: 49.3782524, lng: 18.5813663 },
    { lat: 49.378371, lng: 18.5812642 },
    { lat: 49.3784632, lng: 18.5812161 },
    { lat: 49.3785073, lng: 18.5812619 },
    { lat: 49.3785345, lng: 18.5811565 },
    { lat: 49.3785548, lng: 18.5811387 },
    { lat: 49.3785991, lng: 18.581123 },
    { lat: 49.3786744, lng: 18.5810958 },
    { lat: 49.3787208, lng: 18.5811009 },
    { lat: 49.3787391, lng: 18.5811026 },
    { lat: 49.3787538, lng: 18.5811043 },
    { lat: 49.3787752, lng: 18.5811067 },
    { lat: 49.3788221, lng: 18.5811389 },
    { lat: 49.3788459, lng: 18.5812267 },
    { lat: 49.3789122, lng: 18.5812423 },
    { lat: 49.3790003, lng: 18.5812631 },
    { lat: 49.3791841, lng: 18.5809708 },
    { lat: 49.379244, lng: 18.5809499 },
    { lat: 49.3792955, lng: 18.5809235 },
    { lat: 49.3793481, lng: 18.5808112 },
    { lat: 49.3794053, lng: 18.5806354 },
    { lat: 49.379424, lng: 18.5805816 },
    { lat: 49.3794335, lng: 18.5805515 },
    { lat: 49.3795194, lng: 18.5804712 },
    { lat: 49.3795443, lng: 18.5804612 },
    { lat: 49.3796091, lng: 18.5804353 },
    { lat: 49.3796497, lng: 18.580419 },
    { lat: 49.3797403, lng: 18.5804464 },
    { lat: 49.3798606, lng: 18.5805012 },
    { lat: 49.3798878, lng: 18.5805057 },
    { lat: 49.380208, lng: 18.5805662 },
    { lat: 49.3803088, lng: 18.5805952 },
    { lat: 49.3804875, lng: 18.5806057 },
    { lat: 49.3805437, lng: 18.5806001 },
    { lat: 49.3805952, lng: 18.5805951 },
    { lat: 49.3807624, lng: 18.5805792 },
    { lat: 49.3810077, lng: 18.5806324 },
    { lat: 49.3810306, lng: 18.5806371 },
    { lat: 49.3811104, lng: 18.5806539 },
    { lat: 49.3816191, lng: 18.5807175 },
    { lat: 49.3817576, lng: 18.5807353 },
    { lat: 49.381897, lng: 18.5807254 },
    { lat: 49.3819575, lng: 18.5807214 },
    { lat: 49.3823462, lng: 18.580694 },
    { lat: 49.382426, lng: 18.5806884 },
    { lat: 49.3832479, lng: 18.5806001 },
    { lat: 49.3841775, lng: 18.580434 },
    { lat: 49.3852127, lng: 18.580181 },
    { lat: 49.3855128, lng: 18.5801843 },
    { lat: 49.3860498, lng: 18.5798053 },
    { lat: 49.3863458, lng: 18.5798999 },
    { lat: 49.3868721, lng: 18.5798086 },
    { lat: 49.3872513, lng: 18.579753 },
    { lat: 49.3877134, lng: 18.5796216 },
    { lat: 49.3880097, lng: 18.5795728 },
    { lat: 49.3884071, lng: 18.5794115 },
    { lat: 49.3886249, lng: 18.579163 },
    { lat: 49.3891524, lng: 18.5790316 },
    { lat: 49.3894623, lng: 18.5788562 },
    { lat: 49.3897922, lng: 18.5784445 },
    { lat: 49.3902108, lng: 18.5777751 },
    { lat: 49.3906427, lng: 18.5773302 },
    { lat: 49.3910189, lng: 18.5771965 },
    { lat: 49.3914641, lng: 18.577171 },
    { lat: 49.3920556, lng: 18.5769889 },
    { lat: 49.3923378, lng: 18.5770784 },
    { lat: 49.392531, lng: 18.5770636 },
    { lat: 49.3927394, lng: 18.5769951 },
    { lat: 49.3931274, lng: 18.5766589 },
    { lat: 49.3934493, lng: 18.5765457 },
    { lat: 49.3934854, lng: 18.5765434 },
    { lat: 49.3937191, lng: 18.5765263 },
    { lat: 49.3939838, lng: 18.5765843 },
    { lat: 49.3941957, lng: 18.5766255 },
    { lat: 49.3944869, lng: 18.5761848 },
    { lat: 49.3945766, lng: 18.5758615 },
    { lat: 49.3946369, lng: 18.5753615 },
    { lat: 49.3946702, lng: 18.5751223 },
    { lat: 49.3947185, lng: 18.5749302 },
    { lat: 49.3947569, lng: 18.5748671 },
    { lat: 49.3950033, lng: 18.5745385 },
    { lat: 49.3951736, lng: 18.5743897 },
    { lat: 49.3955191, lng: 18.5740421 },
    { lat: 49.3957064, lng: 18.573704 },
    { lat: 49.395821, lng: 18.5733369 },
    { lat: 49.3959169, lng: 18.5728546 },
    { lat: 49.3959621, lng: 18.5727327 },
    { lat: 49.3959595, lng: 18.5725338 },
    { lat: 49.3959934, lng: 18.5720277 },
    { lat: 49.3960301, lng: 18.5715568 },
    { lat: 49.3960934, lng: 18.5712418 },
    { lat: 49.3961065, lng: 18.5706873 },
    { lat: 49.39616, lng: 18.5697051 },
    { lat: 49.3961421, lng: 18.569476 },
    { lat: 49.3962401, lng: 18.5693072 },
    { lat: 49.396409, lng: 18.5693407 },
    { lat: 49.3965767, lng: 18.569106 },
    { lat: 49.3968208, lng: 18.5690304 },
    { lat: 49.3977899, lng: 18.568909 },
    { lat: 49.3980872, lng: 18.5686931 },
    { lat: 49.3984422, lng: 18.5680992 },
    { lat: 49.3984475, lng: 18.5680924 },
    { lat: 49.3985015, lng: 18.5680233 },
    { lat: 49.3986967, lng: 18.5677753 },
    { lat: 49.3989975, lng: 18.56737 },
    { lat: 49.3992502, lng: 18.56697 },
    { lat: 49.3992311, lng: 18.5668456 },
    { lat: 49.3993339, lng: 18.5664891 },
    { lat: 49.399435, lng: 18.5661447 },
    { lat: 49.3995784, lng: 18.565645 },
    { lat: 49.3997392, lng: 18.5650237 },
    { lat: 49.3998024, lng: 18.5645856 },
    { lat: 49.3999139, lng: 18.5641783 },
    { lat: 49.4000629, lng: 18.5636338 },
    { lat: 49.4000694, lng: 18.5635693 },
    { lat: 49.4001252, lng: 18.5630314 },
    { lat: 49.4001488, lng: 18.5621228 },
    { lat: 49.4001571, lng: 18.5617926 },
    { lat: 49.400166, lng: 18.5614344 },
    { lat: 49.400211, lng: 18.5609692 },
    { lat: 49.4003036, lng: 18.5601309 },
    { lat: 49.4003371, lng: 18.559829 },
    { lat: 49.4003125, lng: 18.5592864 },
    { lat: 49.3997598, lng: 18.5592662 },
    { lat: 49.3997461, lng: 18.5590289 },
    { lat: 49.3997416, lng: 18.5589661 },
    { lat: 49.3997306, lng: 18.5587383 },
    { lat: 49.3997251, lng: 18.5586586 },
    { lat: 49.3997224, lng: 18.5586086 },
    { lat: 49.3997286, lng: 18.5584116 },
    { lat: 49.3997338, lng: 18.558274 },
    { lat: 49.3997417, lng: 18.5580206 },
    { lat: 49.3997433, lng: 18.5579668 },
    { lat: 49.3997458, lng: 18.5578901 },
    { lat: 49.3997489, lng: 18.5577145 },
    { lat: 49.3997569, lng: 18.5573619 },
    { lat: 49.3997643, lng: 18.5569919 },
    { lat: 49.3997651, lng: 18.5569462 },
    { lat: 49.3997562, lng: 18.5566736 },
    { lat: 49.3997481, lng: 18.55644 },
    { lat: 49.3997289, lng: 18.5562479 },
    { lat: 49.3996676, lng: 18.5556303 },
    { lat: 49.3999532, lng: 18.5554911 },
    { lat: 49.3997773, lng: 18.5545866 },
    { lat: 49.3997269, lng: 18.554405 },
    { lat: 49.3995116, lng: 18.5537753 },
    { lat: 49.3990804, lng: 18.5528835 },
    { lat: 49.3990321, lng: 18.5527635 },
    { lat: 49.3988225, lng: 18.5522385 },
    { lat: 49.3987441, lng: 18.5519757 },
    { lat: 49.3986223, lng: 18.5517459 },
    { lat: 49.3983956, lng: 18.5513696 },
    { lat: 49.398172, lng: 18.5511545 },
    { lat: 49.3979773, lng: 18.5507181 },
    { lat: 49.3978101, lng: 18.550334 },
    { lat: 49.3976479, lng: 18.5500861 },
    { lat: 49.3975195, lng: 18.549944 },
    { lat: 49.3974776, lng: 18.5498979 },
    { lat: 49.3972842, lng: 18.5495526 },
    { lat: 49.3970619, lng: 18.5490889 },
    { lat: 49.3969545, lng: 18.5488092 },
    { lat: 49.3967718, lng: 18.548452 },
    { lat: 49.3961515, lng: 18.5482865 },
    { lat: 49.3960706, lng: 18.5481005 },
    { lat: 49.3960495, lng: 18.5476462 },
    { lat: 49.3960773, lng: 18.5469058 },
    { lat: 49.3960463, lng: 18.5463898 },
    { lat: 49.3960857, lng: 18.5460101 },
    { lat: 49.3961561, lng: 18.5453288 },
    { lat: 49.3960996, lng: 18.5445285 },
    { lat: 49.3960109, lng: 18.5441794 },
    { lat: 49.3960423, lng: 18.5437952 },
    { lat: 49.3963176, lng: 18.5430881 },
    { lat: 49.3964721, lng: 18.5429201 },
    { lat: 49.3965625, lng: 18.5426049 },
    { lat: 49.3967133, lng: 18.5423904 },
    { lat: 49.3967948, lng: 18.5419535 },
    { lat: 49.3968594, lng: 18.5413928 },
    { lat: 49.397105, lng: 18.5406568 },
    { lat: 49.3972319, lng: 18.5401027 },
    { lat: 49.3973827, lng: 18.5397154 },
    { lat: 49.3975664, lng: 18.5390662 },
    { lat: 49.3977456, lng: 18.538747 },
    { lat: 49.3977017, lng: 18.5382876 },
    { lat: 49.397642, lng: 18.5376394 },
    { lat: 49.3976362, lng: 18.5375753 },
    { lat: 49.3975827, lng: 18.5369972 },
    { lat: 49.3976022, lng: 18.5363332 },
    { lat: 49.3976389, lng: 18.5358198 },
    { lat: 49.397645, lng: 18.5349497 },
    { lat: 49.3976276, lng: 18.5344598 },
    { lat: 49.3976905, lng: 18.5335342 },
    { lat: 49.3977151, lng: 18.5332574 },
    { lat: 49.3977233, lng: 18.5331611 },
    { lat: 49.3977717, lng: 18.5326108 },
    { lat: 49.3979017, lng: 18.5317814 },
    { lat: 49.3980707, lng: 18.5311071 },
    { lat: 49.3980591, lng: 18.5299691 },
    { lat: 49.3980871, lng: 18.5293804 },
    { lat: 49.3979621, lng: 18.528442 },
    { lat: 49.3978312, lng: 18.5276656 },
    { lat: 49.3981663, lng: 18.5269993 },
    { lat: 49.3982802, lng: 18.5265601 },
    { lat: 49.3986229, lng: 18.5259073 },
    { lat: 49.3988558, lng: 18.5254357 },
    { lat: 49.3990286, lng: 18.5248746 },
    { lat: 49.3990964, lng: 18.5246509 },
    { lat: 49.3993556, lng: 18.5239978 },
    { lat: 49.399795, lng: 18.5230688 },
    { lat: 49.3999925, lng: 18.5226331 },
    { lat: 49.4003314, lng: 18.5219322 },
    { lat: 49.4004873, lng: 18.5215579 },
    { lat: 49.4007097, lng: 18.5211474 },
    { lat: 49.4009159, lng: 18.5206624 },
    { lat: 49.400957, lng: 18.5205649 },
    { lat: 49.4009709, lng: 18.5205302 },
    { lat: 49.401205, lng: 18.5201243 },
    { lat: 49.4013292, lng: 18.5198119 },
    { lat: 49.4015927, lng: 18.519093 },
    { lat: 49.4016023, lng: 18.5190657 },
    { lat: 49.401677, lng: 18.5188607 },
    { lat: 49.4018969, lng: 18.5185022 },
    { lat: 49.402255, lng: 18.5179911 },
    { lat: 49.4023114, lng: 18.5179115 },
    { lat: 49.402562, lng: 18.5176401 },
    { lat: 49.4025938, lng: 18.5176049 },
    { lat: 49.4028487, lng: 18.5172307 },
    { lat: 49.4028822, lng: 18.5171812 },
    { lat: 49.402901, lng: 18.5171531 },
    { lat: 49.4030609, lng: 18.5169181 },
    { lat: 49.4035139, lng: 18.5161385 },
    { lat: 49.4037559, lng: 18.5157305 },
    { lat: 49.4038183, lng: 18.5153843 },
    { lat: 49.4038386, lng: 18.5153618 },
    { lat: 49.4039339, lng: 18.5152053 },
    { lat: 49.4040945, lng: 18.5149415 },
    { lat: 49.4044566, lng: 18.5146088 },
    { lat: 49.4046725, lng: 18.514388 },
    { lat: 49.4047081, lng: 18.5143781 },
    { lat: 49.4047646, lng: 18.5143615 },
    { lat: 49.4050778, lng: 18.5142696 },
    { lat: 49.4053698, lng: 18.5141848 },
    { lat: 49.4056574, lng: 18.5141009 },
    { lat: 49.4060191, lng: 18.5138659 },
    { lat: 49.4064101, lng: 18.5136501 },
    { lat: 49.4065157, lng: 18.5135567 },
    { lat: 49.4067085, lng: 18.5131396 },
    { lat: 49.4070369, lng: 18.5126445 },
    { lat: 49.4077786, lng: 18.512156 },
    { lat: 49.4084345, lng: 18.5118714 },
    { lat: 49.4086191, lng: 18.5117633 },
    { lat: 49.4088973, lng: 18.5115885 },
    { lat: 49.409188, lng: 18.5115397 },
    { lat: 49.4097198, lng: 18.5113842 },
    { lat: 49.4100712, lng: 18.5113342 },
    { lat: 49.4108523, lng: 18.5110105 },
    { lat: 49.4113039, lng: 18.510854 },
    { lat: 49.4114162, lng: 18.5111168 },
    { lat: 49.4114401, lng: 18.5110986 },
    { lat: 49.4118159, lng: 18.5108111 },
    { lat: 49.4120999, lng: 18.5106215 },
    { lat: 49.4123915, lng: 18.5104414 },
    { lat: 49.4130459, lng: 18.5098425 },
    { lat: 49.4132625, lng: 18.5095731 },
    { lat: 49.4138749, lng: 18.5089665 },
    { lat: 49.4141076, lng: 18.5094749 },
    { lat: 49.4146158, lng: 18.5087167 },
    { lat: 49.4143118, lng: 18.5078486 },
    { lat: 49.4140849, lng: 18.507053 },
    { lat: 49.4139883, lng: 18.5065548 },
    { lat: 49.4142194, lng: 18.5063893 },
    { lat: 49.4149683, lng: 18.5061167 },
    { lat: 49.4151468, lng: 18.5060393 },
    { lat: 49.4159614, lng: 18.5056055 },
    { lat: 49.4160512, lng: 18.5055575 },
    { lat: 49.4163309, lng: 18.5053887 },
    { lat: 49.4164886, lng: 18.5053237 },
    { lat: 49.4169636, lng: 18.5051388 },
    { lat: 49.4170045, lng: 18.5053759 },
    { lat: 49.4169578, lng: 18.5055746 },
    { lat: 49.4171436, lng: 18.505881 },
    { lat: 49.4171662, lng: 18.5061552 },
    { lat: 49.4172839, lng: 18.5062037 },
    { lat: 49.4174253, lng: 18.5062303 },
    { lat: 49.417488, lng: 18.5064726 },
    { lat: 49.4176145, lng: 18.5064787 },
    { lat: 49.4176544, lng: 18.506612 },
    { lat: 49.4176296, lng: 18.5067579 },
    { lat: 49.4178082, lng: 18.5070964 },
    { lat: 49.4178994, lng: 18.5071185 },
    { lat: 49.4179108, lng: 18.5072018 },
    { lat: 49.4178994, lng: 18.5073902 },
    { lat: 49.4179927, lng: 18.5075058 },
    { lat: 49.4181383, lng: 18.5079482 },
    { lat: 49.4182315, lng: 18.5081287 },
    { lat: 49.4182521, lng: 18.508489 },
    { lat: 49.4182608, lng: 18.5087941 },
    { lat: 49.4184714, lng: 18.5092049 },
    { lat: 49.4184763, lng: 18.5094242 },
    { lat: 49.4185858, lng: 18.5097186 },
    { lat: 49.4184337, lng: 18.5103063 },
    { lat: 49.4184716, lng: 18.5106087 },
    { lat: 49.4183982, lng: 18.5109223 },
    { lat: 49.4183663, lng: 18.5111846 },
    { lat: 49.4182352, lng: 18.5113469 },
    { lat: 49.4181753, lng: 18.5115947 },
    { lat: 49.4180392, lng: 18.5117098 },
    { lat: 49.4180376, lng: 18.5121182 },
    { lat: 49.4180378, lng: 18.5123714 },
    { lat: 49.4176373, lng: 18.512578 },
    { lat: 49.4177007, lng: 18.512938 },
    { lat: 49.4176311, lng: 18.5130276 },
    { lat: 49.4174985, lng: 18.513014 },
    { lat: 49.4174111, lng: 18.5132131 },
    { lat: 49.4173874, lng: 18.5134219 },
    { lat: 49.4173788, lng: 18.5134929 },
    { lat: 49.4174087, lng: 18.5135224 },
    { lat: 49.4174631, lng: 18.5135275 },
    { lat: 49.4175566, lng: 18.5136754 },
    { lat: 49.4176892, lng: 18.5136669 },
    { lat: 49.4178332, lng: 18.5135323 },
    { lat: 49.4178661, lng: 18.5133702 },
    { lat: 49.4180981, lng: 18.5131774 },
    { lat: 49.4181521, lng: 18.5134459 },
    { lat: 49.418341, lng: 18.5133502 },
    { lat: 49.418449, lng: 18.513535 },
    { lat: 49.4185765, lng: 18.5135536 },
    { lat: 49.4187075, lng: 18.5133711 },
    { lat: 49.4189724, lng: 18.5134288 },
    { lat: 49.4191108, lng: 18.5131935 },
    { lat: 49.4192295, lng: 18.5128839 },
    { lat: 49.4194246, lng: 18.512632 },
    { lat: 49.4196945, lng: 18.5122442 },
    { lat: 49.4198846, lng: 18.5120476 },
    { lat: 49.4202394, lng: 18.5112433 },
    { lat: 49.4207732, lng: 18.5108531 },
    { lat: 49.4209409, lng: 18.5105351 },
    { lat: 49.4214048, lng: 18.5103733 },
    { lat: 49.4216758, lng: 18.5100437 },
    { lat: 49.4218727, lng: 18.510185 },
    { lat: 49.4219904, lng: 18.510128 },
    { lat: 49.4220488, lng: 18.509875 },
    { lat: 49.4222087, lng: 18.5097851 },
    { lat: 49.4224963, lng: 18.5090983 },
    { lat: 49.4226791, lng: 18.5087624 },
    { lat: 49.4228219, lng: 18.5086725 },
    { lat: 49.4229858, lng: 18.5084684 },
    { lat: 49.4231903, lng: 18.5083073 },
    { lat: 49.4234156, lng: 18.5078899 },
    { lat: 49.4235802, lng: 18.5078042 },
    { lat: 49.4240304, lng: 18.5072829 },
    { lat: 49.4242041, lng: 18.5070949 },
    { lat: 49.4244461, lng: 18.5068367 },
    { lat: 49.4246792, lng: 18.506873 },
    { lat: 49.4249479, lng: 18.5066664 },
    { lat: 49.4250457, lng: 18.5067054 },
    { lat: 49.4251849, lng: 18.5068203 },
    { lat: 49.4253208, lng: 18.5068056 },
    { lat: 49.4255394, lng: 18.5070123 },
    { lat: 49.4257561, lng: 18.5069717 },
    { lat: 49.4259108, lng: 18.5070184 },
    { lat: 49.4260583, lng: 18.5072638 },
    { lat: 49.4263412, lng: 18.5073257 },
    { lat: 49.4265629, lng: 18.5074399 },
    { lat: 49.4268784, lng: 18.5071415 },
    { lat: 49.4270363, lng: 18.5069968 },
    { lat: 49.4272857, lng: 18.5068934 },
    { lat: 49.4275881, lng: 18.5065423 },
    { lat: 49.4278094, lng: 18.506233 },
    { lat: 49.4278923, lng: 18.5060351 },
    { lat: 49.4281404, lng: 18.5057841 },
    { lat: 49.4282502, lng: 18.5055433 },
    { lat: 49.4285049, lng: 18.5055591 },
    { lat: 49.4287627, lng: 18.5052162 },
    { lat: 49.4288457, lng: 18.5052096 },
    { lat: 49.4289908, lng: 18.5052651 },
    { lat: 49.429187, lng: 18.5050514 },
    { lat: 49.4293053, lng: 18.5050335 },
    { lat: 49.4295909, lng: 18.5045751 },
    { lat: 49.4297702, lng: 18.5044291 },
    { lat: 49.4297937, lng: 18.5042619 },
    { lat: 49.429708, lng: 18.504092 },
    { lat: 49.4296211, lng: 18.5036342 },
    { lat: 49.4295683, lng: 18.5032391 },
    { lat: 49.4293692, lng: 18.5028973 },
    { lat: 49.4293444, lng: 18.5025599 },
    { lat: 49.4292887, lng: 18.5022818 },
    { lat: 49.4293669, lng: 18.501937 },
    { lat: 49.4293517, lng: 18.5016936 },
    { lat: 49.4297756, lng: 18.5010462 },
    { lat: 49.4299492, lng: 18.5007506 },
    { lat: 49.4303669, lng: 18.5003021 },
    { lat: 49.4310214, lng: 18.4997663 },
    { lat: 49.431323, lng: 18.4995048 },
    { lat: 49.4317602, lng: 18.4988502 },
    { lat: 49.4318752, lng: 18.498538 },
    { lat: 49.4318799, lng: 18.4982707 },
    { lat: 49.4320507, lng: 18.4980073 },
    { lat: 49.4321675, lng: 18.497121 },
    { lat: 49.4323543, lng: 18.4969044 },
    { lat: 49.4327603, lng: 18.49649 },
    { lat: 49.4330232, lng: 18.4964696 },
    { lat: 49.4335208, lng: 18.4964748 },
    { lat: 49.4336914, lng: 18.4963966 },
    { lat: 49.4341473, lng: 18.4963389 },
    { lat: 49.434321, lng: 18.4963168 },
    { lat: 49.4349973, lng: 18.4961847 },
    { lat: 49.43587, lng: 18.495961 },
    { lat: 49.435892, lng: 18.495582 },
    { lat: 49.435617, lng: 18.494682 },
    { lat: 49.435288, lng: 18.493604 },
    { lat: 49.434976, lng: 18.492546 },
    { lat: 49.434784, lng: 18.492044 },
    { lat: 49.434449, lng: 18.491838 },
    { lat: 49.433883, lng: 18.4914895 },
    { lat: 49.433702, lng: 18.491378 },
    { lat: 49.433325, lng: 18.490362 },
    { lat: 49.433178, lng: 18.489964 },
    { lat: 49.432716, lng: 18.48995 },
    { lat: 49.43241, lng: 18.490052 },
    { lat: 49.43216, lng: 18.490135 },
    { lat: 49.432007, lng: 18.490344 },
    { lat: 49.431888, lng: 18.490558 },
    { lat: 49.431558, lng: 18.491146 },
    { lat: 49.431287, lng: 18.4919 },
    { lat: 49.431222, lng: 18.492204 },
    { lat: 49.431051, lng: 18.492678 },
    { lat: 49.4308, lng: 18.4930131 },
    { lat: 49.4305331, lng: 18.493434 },
    { lat: 49.430553, lng: 18.493445 },
    { lat: 49.430342, lng: 18.493983 },
    { lat: 49.430108, lng: 18.494316 },
    { lat: 49.429767, lng: 18.494692 },
    { lat: 49.429715, lng: 18.494668 },
    { lat: 49.429618, lng: 18.494619 },
    { lat: 49.429251, lng: 18.494429 },
    { lat: 49.428602, lng: 18.494236 },
    { lat: 49.428359, lng: 18.494162 },
    { lat: 49.428183, lng: 18.494108 },
    { lat: 49.427829, lng: 18.493849 },
    { lat: 49.427585, lng: 18.493573 },
    { lat: 49.427372, lng: 18.493127 },
    { lat: 49.427266, lng: 18.492506 },
    { lat: 49.427093, lng: 18.491497 },
    { lat: 49.427015, lng: 18.49086 },
    { lat: 49.426944, lng: 18.490284 },
    { lat: 49.426744, lng: 18.489646 },
    { lat: 49.426579, lng: 18.489238 },
    { lat: 49.426464, lng: 18.488466 },
    { lat: 49.426438, lng: 18.487087 },
    { lat: 49.426248, lng: 18.486049 },
    { lat: 49.4261385, lng: 18.4856914 },
    { lat: 49.426116, lng: 18.485618 },
    { lat: 49.425939, lng: 18.485042 },
    { lat: 49.425419, lng: 18.483996 },
    { lat: 49.424915, lng: 18.483231 },
    { lat: 49.424571, lng: 18.482792 },
    { lat: 49.423907, lng: 18.481947 },
    { lat: 49.423551, lng: 18.481781 },
    { lat: 49.423, lng: 18.481669 },
    { lat: 49.422681, lng: 18.481608 },
    { lat: 49.422205, lng: 18.481655 },
    { lat: 49.421772, lng: 18.481698 },
    { lat: 49.421351, lng: 18.481695 },
    { lat: 49.42111, lng: 18.481696 },
    { lat: 49.420828, lng: 18.481637 },
    { lat: 49.420721, lng: 18.480748 },
    { lat: 49.42052, lng: 18.479866 },
    { lat: 49.420251, lng: 18.479379 },
    { lat: 49.419472, lng: 18.478765 },
    { lat: 49.418704, lng: 18.478349 },
    { lat: 49.418308, lng: 18.478132 },
    { lat: 49.417888, lng: 18.477502 },
    { lat: 49.417585, lng: 18.47728 },
    { lat: 49.416856, lng: 18.476605 },
    { lat: 49.416702, lng: 18.476458 },
    { lat: 49.415953, lng: 18.476642 },
    { lat: 49.415474, lng: 18.476856 },
    { lat: 49.415143, lng: 18.47709 },
    { lat: 49.414811, lng: 18.477325 },
    { lat: 49.414292, lng: 18.477643 },
    { lat: 49.413542, lng: 18.477912 },
    { lat: 49.41323, lng: 18.477841 },
    { lat: 49.412352, lng: 18.47783 },
    { lat: 49.4116838, lng: 18.477819 },
    { lat: 49.411409, lng: 18.477597 },
    { lat: 49.411263, lng: 18.477373 },
    { lat: 49.410738, lng: 18.47717 },
    { lat: 49.410091, lng: 18.476907 },
    { lat: 49.4097, lng: 18.476724 },
    { lat: 49.409427, lng: 18.476595 },
    { lat: 49.409139, lng: 18.476374 },
    { lat: 49.408886, lng: 18.476107 },
    { lat: 49.408546, lng: 18.475691 },
    { lat: 49.408406, lng: 18.475415 },
    { lat: 49.408235, lng: 18.474676 },
    { lat: 49.408111, lng: 18.474249 },
    { lat: 49.407953, lng: 18.473708 },
    { lat: 49.407758, lng: 18.472831 },
    { lat: 49.407712, lng: 18.472436 },
    { lat: 49.407479, lng: 18.471623 },
    { lat: 49.407357, lng: 18.471152 },
    { lat: 49.407069, lng: 18.470532 },
    { lat: 49.406852, lng: 18.470298 },
    { lat: 49.406785, lng: 18.469967 },
    { lat: 49.406674, lng: 18.469388 },
    { lat: 49.40638, lng: 18.468619 },
    { lat: 49.406312, lng: 18.468184 },
    { lat: 49.406269, lng: 18.467285 },
    { lat: 49.406244, lng: 18.466971 },
    { lat: 49.406211, lng: 18.46655 },
    { lat: 49.406176, lng: 18.466259 },
    { lat: 49.406119, lng: 18.465774 },
    { lat: 49.406045, lng: 18.465427 },
    { lat: 49.405935, lng: 18.465093 },
    { lat: 49.405677, lng: 18.464539 },
    { lat: 49.405485, lng: 18.463965 },
    { lat: 49.405351, lng: 18.463317 },
    { lat: 49.405086, lng: 18.462791 },
    { lat: 49.404923, lng: 18.462389 },
    { lat: 49.404735, lng: 18.461836 },
    { lat: 49.404611, lng: 18.461474 },
    { lat: 49.404448, lng: 18.461146 },
    { lat: 49.404117, lng: 18.46041 },
    { lat: 49.403926, lng: 18.460018 },
    { lat: 49.403714, lng: 18.459582 },
    { lat: 49.403534, lng: 18.459204 },
    { lat: 49.40339, lng: 18.458861 },
    { lat: 49.403224, lng: 18.458278 },
    { lat: 49.40315, lng: 18.457326 },
    { lat: 49.403185, lng: 18.457054 },
    { lat: 49.403155, lng: 18.4567 },
    { lat: 49.402974, lng: 18.456349 },
    { lat: 49.402353, lng: 18.455644 },
    { lat: 49.402087, lng: 18.455338 },
    { lat: 49.401669, lng: 18.454886 },
    { lat: 49.401356, lng: 18.45441 },
    { lat: 49.401132, lng: 18.453998 },
    { lat: 49.401026, lng: 18.453494 },
    { lat: 49.40084, lng: 18.452996 },
    { lat: 49.400588, lng: 18.452571 },
    { lat: 49.400332, lng: 18.451937 },
    { lat: 49.400035, lng: 18.451566 },
    { lat: 49.399757, lng: 18.451329 },
    { lat: 49.399482, lng: 18.451158 },
    { lat: 49.399236, lng: 18.451134 },
    { lat: 49.398909, lng: 18.451106 },
    { lat: 49.398681, lng: 18.451003 },
    { lat: 49.398416, lng: 18.450701 },
    { lat: 49.398193, lng: 18.450446 },
    { lat: 49.397965, lng: 18.450027 },
    { lat: 49.397558, lng: 18.449531 },
    { lat: 49.397438, lng: 18.449383 },
    { lat: 49.397146, lng: 18.44956 },
    { lat: 49.396867, lng: 18.449733 },
    { lat: 49.39605, lng: 18.449939 },
    { lat: 49.395811, lng: 18.449998 },
    { lat: 49.395566, lng: 18.4499 },
    { lat: 49.39509, lng: 18.450021 },
    { lat: 49.3950851, lng: 18.4500692 },
    { lat: 49.395102, lng: 18.4502354 },
    { lat: 49.3951473, lng: 18.4506928 },
    { lat: 49.395222, lng: 18.4509664 },
    { lat: 49.395331, lng: 18.4512487 },
    { lat: 49.3956482, lng: 18.4519765 },
    { lat: 49.3956428, lng: 18.4528926 },
    { lat: 49.3957043, lng: 18.4536687 },
    { lat: 49.3958507, lng: 18.4539527 },
    { lat: 49.3959967, lng: 18.4543972 },
    { lat: 49.3961594, lng: 18.4552099 },
    { lat: 49.3967296, lng: 18.4561697 },
    { lat: 49.3969672, lng: 18.4562277 },
    { lat: 49.3971701, lng: 18.4563897 },
    { lat: 49.3973904, lng: 18.4562083 },
    { lat: 49.397874, lng: 18.4564727 },
    { lat: 49.3984142, lng: 18.4565763 },
    { lat: 49.3986853, lng: 18.4575991 },
    { lat: 49.3984236, lng: 18.4577847 },
    { lat: 49.3980386, lng: 18.4579237 },
    { lat: 49.3978838, lng: 18.4579377 },
    { lat: 49.3974652, lng: 18.4579754 },
    { lat: 49.3969277, lng: 18.4582733 },
    { lat: 49.3964183, lng: 18.4584906 },
    { lat: 49.3962798, lng: 18.4585166 },
    { lat: 49.3961254, lng: 18.4585791 },
    { lat: 49.39574, lng: 18.4588327 },
    { lat: 49.3957094, lng: 18.4588525 },
    { lat: 49.3943771, lng: 18.4602334 },
    { lat: 49.3935436, lng: 18.4608546 },
    { lat: 49.3919611, lng: 18.4622029 },
    { lat: 49.3915578, lng: 18.4624562 },
    { lat: 49.3913074, lng: 18.4632456 },
    { lat: 49.3910855, lng: 18.4632494 },
    { lat: 49.3907751, lng: 18.4640947 },
    { lat: 49.390489, lng: 18.4646012 },
    { lat: 49.3904757, lng: 18.4646866 },
    { lat: 49.3903355, lng: 18.4665051 },
    { lat: 49.3901643, lng: 18.4664473 },
    { lat: 49.3901333, lng: 18.4664368 },
    { lat: 49.39022, lng: 18.4666628 },
    { lat: 49.3903632, lng: 18.4670751 },
    { lat: 49.3903956, lng: 18.467483 },
    { lat: 49.3901614, lng: 18.4682783 },
    { lat: 49.3901645, lng: 18.4686255 },
    { lat: 49.3902162, lng: 18.4689115 },
    { lat: 49.3901792, lng: 18.4693738 },
    { lat: 49.3900731, lng: 18.4695188 },
    { lat: 49.3899991, lng: 18.4702524 },
    { lat: 49.3899999, lng: 18.4707237 },
    { lat: 49.3900969, lng: 18.471077 },
    { lat: 49.3896451, lng: 18.4714562 },
    { lat: 49.3893111, lng: 18.4724301 },
    { lat: 49.3891516, lng: 18.4722336 },
    { lat: 49.3891955, lng: 18.4720679 },
    { lat: 49.389239, lng: 18.4716862 },
    { lat: 49.3891281, lng: 18.4716757 },
    { lat: 49.3888234, lng: 18.4717584 },
    { lat: 49.3886726, lng: 18.4717237 },
    { lat: 49.3881893, lng: 18.4718752 },
    { lat: 49.387901, lng: 18.471929 },
    { lat: 49.3878465, lng: 18.4725826 },
    { lat: 49.3877038, lng: 18.472696 },
    { lat: 49.3876385, lng: 18.4729466 },
    { lat: 49.3876028, lng: 18.4730818 },
    { lat: 49.3875712, lng: 18.4732046 },
    { lat: 49.387545, lng: 18.4733054 },
    { lat: 49.3876503, lng: 18.4734548 },
    { lat: 49.3875923, lng: 18.473653 },
    { lat: 49.3875381, lng: 18.4738403 },
    { lat: 49.3873369, lng: 18.4745386 },
    { lat: 49.3872771, lng: 18.4750991 },
    { lat: 49.3871913, lng: 18.475656 },
    { lat: 49.387164, lng: 18.4757573 },
    { lat: 49.3869738, lng: 18.4764509 },
    { lat: 49.3868687, lng: 18.4771913 },
    { lat: 49.3868582, lng: 18.4772672 },
    { lat: 49.3869127, lng: 18.4780829 },
    { lat: 49.3867822, lng: 18.4787353 },
    { lat: 49.386554, lng: 18.4795978 },
    { lat: 49.3860348, lng: 18.4795517 },
    { lat: 49.385857, lng: 18.4795165 },
    { lat: 49.3857156, lng: 18.4794889 },
    { lat: 49.3849337, lng: 18.4788822 },
    { lat: 49.3847061, lng: 18.4787603 },
    { lat: 49.3846876, lng: 18.4787507 },
    { lat: 49.3842169, lng: 18.4786449 },
    { lat: 49.3841316, lng: 18.4784443 },
    { lat: 49.3840756, lng: 18.4780134 },
    { lat: 49.3839913, lng: 18.4778113 },
    { lat: 49.3837694, lng: 18.4777552 },
    { lat: 49.3832401, lng: 18.4774784 },
    { lat: 49.3830237, lng: 18.4775017 },
    { lat: 49.3825943, lng: 18.4778428 },
    { lat: 49.3822765, lng: 18.4779702 },
    { lat: 49.3819779, lng: 18.4781425 },
    { lat: 49.3816231, lng: 18.4781074 },
    { lat: 49.3815314, lng: 18.4781249 },
    { lat: 49.3814038, lng: 18.4780767 },
    { lat: 49.3810411, lng: 18.4779604 },
    { lat: 49.3808227, lng: 18.4780353 },
    { lat: 49.3808542, lng: 18.4781584 },
    { lat: 49.3806625, lng: 18.4785712 },
    { lat: 49.3803973, lng: 18.4792534 },
    { lat: 49.3797893, lng: 18.4799387 },
    { lat: 49.3796671, lng: 18.4804907 },
    { lat: 49.3795722, lng: 18.4806975 },
    { lat: 49.3794524, lng: 18.4813553 },
    { lat: 49.3792175, lng: 18.4818693 },
    { lat: 49.3788059, lng: 18.4822022 },
    { lat: 49.3785978, lng: 18.4825657 },
    { lat: 49.3783913, lng: 18.4827318 },
    { lat: 49.3781716, lng: 18.4828383 },
    { lat: 49.3780791, lng: 18.4829858 },
    { lat: 49.3779986, lng: 18.4832267 },
    { lat: 49.3776309, lng: 18.4834972 },
    { lat: 49.3774651, lng: 18.4836816 },
    { lat: 49.3777711, lng: 18.483959 },
    { lat: 49.3775081, lng: 18.4844127 },
    { lat: 49.3774364, lng: 18.4847563 },
    { lat: 49.3775411, lng: 18.484895 },
    { lat: 49.3777915, lng: 18.4853536 },
    { lat: 49.3775781, lng: 18.4859114 },
    { lat: 49.3774549, lng: 18.4864686 },
    { lat: 49.3773756, lng: 18.4869196 },
    { lat: 49.3772703, lng: 18.487139 },
    { lat: 49.3771315, lng: 18.4873032 },
    { lat: 49.3764902, lng: 18.4884338 },
  ],
  ZborovnadBystricou: [
    { lat: 49.3657432, lng: 18.8712696 },
    { lat: 49.3651205, lng: 18.8717937 },
    { lat: 49.3650861, lng: 18.8718227 },
    { lat: 49.3647109, lng: 18.8721388 },
    { lat: 49.3647891, lng: 18.873121 },
    { lat: 49.3646055, lng: 18.8748731 },
    { lat: 49.3646455, lng: 18.8756999 },
    { lat: 49.3643277, lng: 18.8774308 },
    { lat: 49.364289, lng: 18.879728 },
    { lat: 49.3643785, lng: 18.8803536 },
    { lat: 49.3652548, lng: 18.8819572 },
    { lat: 49.3651898, lng: 18.8846924 },
    { lat: 49.3650884, lng: 18.8854684 },
    { lat: 49.3649304, lng: 18.8866625 },
    { lat: 49.364499, lng: 18.8888253 },
    { lat: 49.3644404, lng: 18.8892009 },
    { lat: 49.3645809, lng: 18.8891943 },
    { lat: 49.3645731, lng: 18.8893953 },
    { lat: 49.3646578, lng: 18.8896861 },
    { lat: 49.3646369, lng: 18.8899389 },
    { lat: 49.3645312, lng: 18.890259 },
    { lat: 49.3644886, lng: 18.8907737 },
    { lat: 49.3646616, lng: 18.8908016 },
    { lat: 49.3647483, lng: 18.8911132 },
    { lat: 49.3645839, lng: 18.8914034 },
    { lat: 49.364623, lng: 18.8915311 },
    { lat: 49.3644944, lng: 18.8920174 },
    { lat: 49.3643558, lng: 18.8921305 },
    { lat: 49.3643796, lng: 18.8921982 },
    { lat: 49.3643403, lng: 18.8925132 },
    { lat: 49.3643335, lng: 18.8935793 },
    { lat: 49.3642428, lng: 18.8945674 },
    { lat: 49.3642085, lng: 18.8974012 },
    { lat: 49.364261, lng: 18.8979705 },
    { lat: 49.3644257, lng: 18.8988378 },
    { lat: 49.3643836, lng: 18.8992502 },
    { lat: 49.3643721, lng: 18.899359 },
    { lat: 49.3644131, lng: 18.8994785 },
    { lat: 49.3641666, lng: 18.8997513 },
    { lat: 49.3643463, lng: 18.9001177 },
    { lat: 49.3646435, lng: 18.9006857 },
    { lat: 49.3653372, lng: 18.9021185 },
    { lat: 49.3657821, lng: 18.903289 },
    { lat: 49.3661645, lng: 18.9039587 },
    { lat: 49.3665466, lng: 18.9042464 },
    { lat: 49.367049, lng: 18.9043054 },
    { lat: 49.3674438, lng: 18.9040859 },
    { lat: 49.3678986, lng: 18.9037844 },
    { lat: 49.3683491, lng: 18.9031315 },
    { lat: 49.3686091, lng: 18.9035669 },
    { lat: 49.3686633, lng: 18.9036577 },
    { lat: 49.3694306, lng: 18.9053634 },
    { lat: 49.3696116, lng: 18.9056859 },
    { lat: 49.3702586, lng: 18.9064404 },
    { lat: 49.3707389, lng: 18.9070667 },
    { lat: 49.3710045, lng: 18.9072783 },
    { lat: 49.3713645, lng: 18.9076638 },
    { lat: 49.3722272, lng: 18.9082509 },
    { lat: 49.374343, lng: 18.9096907 },
    { lat: 49.3744597, lng: 18.9097933 },
    { lat: 49.374577, lng: 18.9097946 },
    { lat: 49.375409, lng: 18.9109747 },
    { lat: 49.3765812, lng: 18.9120154 },
    { lat: 49.3766976, lng: 18.9121315 },
    { lat: 49.37722, lng: 18.9124078 },
    { lat: 49.3773286, lng: 18.912417 },
    { lat: 49.3774394, lng: 18.9123336 },
    { lat: 49.3785354, lng: 18.9125831 },
    { lat: 49.3785576, lng: 18.9124882 },
    { lat: 49.3787577, lng: 18.9124337 },
    { lat: 49.3793691, lng: 18.9123724 },
    { lat: 49.3798097, lng: 18.9125039 },
    { lat: 49.3808182, lng: 18.9130044 },
    { lat: 49.381309, lng: 18.9131231 },
    { lat: 49.383912, lng: 18.9157097 },
    { lat: 49.3860567, lng: 18.9184187 },
    { lat: 49.3872431, lng: 18.918134 },
    { lat: 49.3874744, lng: 18.9180736 },
    { lat: 49.3879343, lng: 18.9178244 },
    { lat: 49.388014, lng: 18.9178645 },
    { lat: 49.3887302, lng: 18.918406 },
    { lat: 49.3888708, lng: 18.9187328 },
    { lat: 49.389763, lng: 18.9191673 },
    { lat: 49.3897983, lng: 18.9191092 },
    { lat: 49.390445, lng: 18.9203024 },
    { lat: 49.3906002, lng: 18.9206542 },
    { lat: 49.3914396, lng: 18.9215163 },
    { lat: 49.3929425, lng: 18.922344 },
    { lat: 49.3935137, lng: 18.9229839 },
    { lat: 49.395269, lng: 18.9242601 },
    { lat: 49.3967345, lng: 18.9249262 },
    { lat: 49.3973729, lng: 18.9253179 },
    { lat: 49.3987862, lng: 18.9262892 },
    { lat: 49.3999957, lng: 18.9272264 },
    { lat: 49.4003589, lng: 18.927743 },
    { lat: 49.4006357, lng: 18.9286984 },
    { lat: 49.4010559, lng: 18.9299847 },
    { lat: 49.4013479, lng: 18.9302115 },
    { lat: 49.4031364, lng: 18.9311386 },
    { lat: 49.4036754, lng: 18.9306505 },
    { lat: 49.4039448, lng: 18.9306042 },
    { lat: 49.4040802, lng: 18.9305874 },
    { lat: 49.4047781, lng: 18.9302517 },
    { lat: 49.4052871, lng: 18.9304833 },
    { lat: 49.405545, lng: 18.9310649 },
    { lat: 49.4056079, lng: 18.9313706 },
    { lat: 49.4057916, lng: 18.9324219 },
    { lat: 49.4057647, lng: 18.9326723 },
    { lat: 49.4056728, lng: 18.9329058 },
    { lat: 49.4048976, lng: 18.9344564 },
    { lat: 49.4050113, lng: 18.9346536 },
    { lat: 49.4052907, lng: 18.9357026 },
    { lat: 49.405971, lng: 18.9373497 },
    { lat: 49.4063194, lng: 18.938001 },
    { lat: 49.4066041, lng: 18.9392637 },
    { lat: 49.407415, lng: 18.9418917 },
    { lat: 49.4079421, lng: 18.9430957 },
    { lat: 49.4083751, lng: 18.9447541 },
    { lat: 49.4087022, lng: 18.9457244 },
    { lat: 49.4090346, lng: 18.9475152 },
    { lat: 49.4093362, lng: 18.9485588 },
    { lat: 49.4097738, lng: 18.9496175 },
    { lat: 49.4102252, lng: 18.9508824 },
    { lat: 49.4104001, lng: 18.9512242 },
    { lat: 49.4109496, lng: 18.9525359 },
    { lat: 49.4114384, lng: 18.9537196 },
    { lat: 49.4117, lng: 18.9543466 },
    { lat: 49.4117456, lng: 18.9544933 },
    { lat: 49.4120618, lng: 18.9551551 },
    { lat: 49.4120966, lng: 18.955151 },
    { lat: 49.4122886, lng: 18.955518 },
    { lat: 49.4124836, lng: 18.9553831 },
    { lat: 49.4119902, lng: 18.9541318 },
    { lat: 49.4119162, lng: 18.9532893 },
    { lat: 49.4118715, lng: 18.9532917 },
    { lat: 49.4118107, lng: 18.952397 },
    { lat: 49.4117046, lng: 18.9506891 },
    { lat: 49.4118185, lng: 18.9501289 },
    { lat: 49.4118744, lng: 18.9492027 },
    { lat: 49.4117785, lng: 18.948637 },
    { lat: 49.4117884, lng: 18.9481394 },
    { lat: 49.4118429, lng: 18.9468216 },
    { lat: 49.4116993, lng: 18.9454911 },
    { lat: 49.4115733, lng: 18.9447992 },
    { lat: 49.4115074, lng: 18.9439157 },
    { lat: 49.4113842, lng: 18.9430363 },
    { lat: 49.4111993, lng: 18.940702 },
    { lat: 49.4113706, lng: 18.940168 },
    { lat: 49.4111676, lng: 18.9397162 },
    { lat: 49.4110169, lng: 18.9389839 },
    { lat: 49.4110059, lng: 18.9383187 },
    { lat: 49.4106911, lng: 18.9373498 },
    { lat: 49.4103086, lng: 18.9364475 },
    { lat: 49.4096944, lng: 18.9352668 },
    { lat: 49.4088259, lng: 18.934114 },
    { lat: 49.4079225, lng: 18.9328521 },
    { lat: 49.407419, lng: 18.9319835 },
    { lat: 49.4073002, lng: 18.9317906 },
    { lat: 49.4071953, lng: 18.9315976 },
    { lat: 49.4065854, lng: 18.9305671 },
    { lat: 49.4065587, lng: 18.9305304 },
    { lat: 49.4063044, lng: 18.9302524 },
    { lat: 49.4060756, lng: 18.9299168 },
    { lat: 49.4058398, lng: 18.9295555 },
    { lat: 49.4055516, lng: 18.9288656 },
    { lat: 49.4052748, lng: 18.9280292 },
    { lat: 49.4051418, lng: 18.9272927 },
    { lat: 49.4051071, lng: 18.9263571 },
    { lat: 49.4050878, lng: 18.9259094 },
    { lat: 49.4049931, lng: 18.9250797 },
    { lat: 49.4048436, lng: 18.9241735 },
    { lat: 49.4045056, lng: 18.9229046 },
    { lat: 49.4041995, lng: 18.9215301 },
    { lat: 49.403972, lng: 18.9200374 },
    { lat: 49.4038129, lng: 18.9190304 },
    { lat: 49.4036726, lng: 18.9178854 },
    { lat: 49.4035246, lng: 18.9168307 },
    { lat: 49.4034979, lng: 18.9160302 },
    { lat: 49.4035075, lng: 18.9159784 },
    { lat: 49.4036158, lng: 18.9156658 },
    { lat: 49.4040767, lng: 18.9150167 },
    { lat: 49.4041013, lng: 18.9149671 },
    { lat: 49.4042459, lng: 18.91477 },
    { lat: 49.4045127, lng: 18.9145859 },
    { lat: 49.4049032, lng: 18.9141037 },
    { lat: 49.4049852, lng: 18.9140299 },
    { lat: 49.4050256, lng: 18.9140295 },
    { lat: 49.4054656, lng: 18.9141687 },
    { lat: 49.4056207, lng: 18.9142039 },
    { lat: 49.4057419, lng: 18.9142127 },
    { lat: 49.4060567, lng: 18.9141087 },
    { lat: 49.4063929, lng: 18.9140239 },
    { lat: 49.4070304, lng: 18.9141673 },
    { lat: 49.407554, lng: 18.9142445 },
    { lat: 49.4078219, lng: 18.9143296 },
    { lat: 49.4081091, lng: 18.9144117 },
    { lat: 49.4083434, lng: 18.9142286 },
    { lat: 49.4092053, lng: 18.9138156 },
    { lat: 49.4098246, lng: 18.9134898 },
    { lat: 49.4102196, lng: 18.9131873 },
    { lat: 49.4108067, lng: 18.9125571 },
    { lat: 49.4108509, lng: 18.9123611 },
    { lat: 49.4110832, lng: 18.9117379 },
    { lat: 49.4111411, lng: 18.9111032 },
    { lat: 49.411157, lng: 18.9107027 },
    { lat: 49.4112046, lng: 18.9100131 },
    { lat: 49.4113639, lng: 18.9096408 },
    { lat: 49.4118034, lng: 18.908481 },
    { lat: 49.4120631, lng: 18.9078423 },
    { lat: 49.412266, lng: 18.9074402 },
    { lat: 49.4124877, lng: 18.9063218 },
    { lat: 49.4127023, lng: 18.9056138 },
    { lat: 49.4128131, lng: 18.9049978 },
    { lat: 49.4129187, lng: 18.9044982 },
    { lat: 49.4128902, lng: 18.903918 },
    { lat: 49.4128274, lng: 18.9035644 },
    { lat: 49.4127842, lng: 18.9021561 },
    { lat: 49.4131788, lng: 18.9019335 },
    { lat: 49.4134241, lng: 18.9018134 },
    { lat: 49.4136987, lng: 18.9015346 },
    { lat: 49.4141237, lng: 18.9013584 },
    { lat: 49.4141303, lng: 18.9012652 },
    { lat: 49.4145249, lng: 18.9011129 },
    { lat: 49.4152566, lng: 18.9008908 },
    { lat: 49.4152421, lng: 18.900823 },
    { lat: 49.4152924, lng: 18.9007953 },
    { lat: 49.4151781, lng: 18.9004504 },
    { lat: 49.4152784, lng: 18.8992276 },
    { lat: 49.415887, lng: 18.8964207 },
    { lat: 49.4157022, lng: 18.8960436 },
    { lat: 49.4154002, lng: 18.8957563 },
    { lat: 49.4148663, lng: 18.8950996 },
    { lat: 49.4144512, lng: 18.8942504 },
    { lat: 49.4141674, lng: 18.8937226 },
    { lat: 49.4137663, lng: 18.8928159 },
    { lat: 49.4134216, lng: 18.8919536 },
    { lat: 49.4130869, lng: 18.8912234 },
    { lat: 49.4127749, lng: 18.8907634 },
    { lat: 49.4126385, lng: 18.8906323 },
    { lat: 49.4124017, lng: 18.8905422 },
    { lat: 49.4120164, lng: 18.8903223 },
    { lat: 49.41174, lng: 18.8900758 },
    { lat: 49.4115221, lng: 18.8898038 },
    { lat: 49.4113074, lng: 18.8896139 },
    { lat: 49.4111403, lng: 18.8891889 },
    { lat: 49.4109843, lng: 18.8888903 },
    { lat: 49.4106594, lng: 18.8880235 },
    { lat: 49.4105495, lng: 18.8875008 },
    { lat: 49.4104411, lng: 18.8866976 },
    { lat: 49.4101615, lng: 18.8857636 },
    { lat: 49.4101363, lng: 18.8857785 },
    { lat: 49.4100357, lng: 18.8854408 },
    { lat: 49.4096711, lng: 18.8842754 },
    { lat: 49.4095329, lng: 18.8838208 },
    { lat: 49.4086683, lng: 18.8822267 },
    { lat: 49.4081064, lng: 18.8812623 },
    { lat: 49.4076721, lng: 18.8805915 },
    { lat: 49.4074475, lng: 18.8797889 },
    { lat: 49.4068024, lng: 18.8790517 },
    { lat: 49.4064389, lng: 18.8787747 },
    { lat: 49.4055225, lng: 18.8778498 },
    { lat: 49.4046315, lng: 18.8774657 },
    { lat: 49.4040073, lng: 18.8770284 },
    { lat: 49.4031282, lng: 18.8762577 },
    { lat: 49.4017126, lng: 18.8753137 },
    { lat: 49.4011019, lng: 18.8749881 },
    { lat: 49.4011041, lng: 18.8749617 },
    { lat: 49.3999481, lng: 18.8744917 },
    { lat: 49.3996524, lng: 18.8743743 },
    { lat: 49.3993158, lng: 18.8741376 },
    { lat: 49.3982993, lng: 18.8740375 },
    { lat: 49.3979473, lng: 18.8739859 },
    { lat: 49.3969947, lng: 18.8736548 },
    { lat: 49.3962894, lng: 18.8734445 },
    { lat: 49.3956492, lng: 18.8728916 },
    { lat: 49.3953124, lng: 18.8721884 },
    { lat: 49.3947732, lng: 18.8718308 },
    { lat: 49.3940825, lng: 18.8713715 },
    { lat: 49.3933501, lng: 18.8707399 },
    { lat: 49.3924805, lng: 18.87031 },
    { lat: 49.3915577, lng: 18.8697023 },
    { lat: 49.391418, lng: 18.8696585 },
    { lat: 49.39136, lng: 18.8696293 },
    { lat: 49.3913117, lng: 18.8696068 },
    { lat: 49.3912667, lng: 18.8695688 },
    { lat: 49.3912222, lng: 18.869535 },
    { lat: 49.3912138, lng: 18.869528 },
    { lat: 49.3912108, lng: 18.8695331 },
    { lat: 49.3909681, lng: 18.8692628 },
    { lat: 49.3909065, lng: 18.8693328 },
    { lat: 49.3908166, lng: 18.8692064 },
    { lat: 49.390722, lng: 18.8690092 },
    { lat: 49.3906953, lng: 18.8689715 },
    { lat: 49.3905075, lng: 18.8688051 },
    { lat: 49.3904822, lng: 18.8686651 },
    { lat: 49.3898612, lng: 18.8679823 },
    { lat: 49.3895797, lng: 18.8676783 },
    { lat: 49.3894946, lng: 18.8675784 },
    { lat: 49.3892571, lng: 18.8669004 },
    { lat: 49.3890803, lng: 18.8666635 },
    { lat: 49.3890176, lng: 18.8665588 },
    { lat: 49.388938, lng: 18.8663928 },
    { lat: 49.388947, lng: 18.8663244 },
    { lat: 49.3889058, lng: 18.8662019 },
    { lat: 49.3888076, lng: 18.8661098 },
    { lat: 49.3887647, lng: 18.8660429 },
    { lat: 49.3887937, lng: 18.865949 },
    { lat: 49.388737, lng: 18.8657875 },
    { lat: 49.3886957, lng: 18.8656853 },
    { lat: 49.3886558, lng: 18.8655887 },
    { lat: 49.3886072, lng: 18.8654324 },
    { lat: 49.3887348, lng: 18.8652575 },
    { lat: 49.3888955, lng: 18.8649853 },
    { lat: 49.38886, lng: 18.8649737 },
    { lat: 49.3887005, lng: 18.8648064 },
    { lat: 49.3886362, lng: 18.864653 },
    { lat: 49.3886256, lng: 18.8645971 },
    { lat: 49.3885732, lng: 18.864544 },
    { lat: 49.388443, lng: 18.8645097 },
    { lat: 49.3883501, lng: 18.8643941 },
    { lat: 49.3883689, lng: 18.8643166 },
    { lat: 49.3883369, lng: 18.864264 },
    { lat: 49.3882576, lng: 18.8641688 },
    { lat: 49.388185, lng: 18.8640755 },
    { lat: 49.3880839, lng: 18.8639446 },
    { lat: 49.387997, lng: 18.8638584 },
    { lat: 49.3879084, lng: 18.8637981 },
    { lat: 49.3878214, lng: 18.8637079 },
    { lat: 49.3877359, lng: 18.8636215 },
    { lat: 49.3876566, lng: 18.8635072 },
    { lat: 49.3876167, lng: 18.8634515 },
    { lat: 49.387576, lng: 18.8633862 },
    { lat: 49.3875229, lng: 18.8632941 },
    { lat: 49.3874641, lng: 18.8631792 },
    { lat: 49.3874169, lng: 18.8630915 },
    { lat: 49.3872853, lng: 18.862902 },
    { lat: 49.3872352, lng: 18.8628031 },
    { lat: 49.3871914, lng: 18.8627179 },
    { lat: 49.387144, lng: 18.8626276 },
    { lat: 49.3871054, lng: 18.8625338 },
    { lat: 49.3870524, lng: 18.8623787 },
    { lat: 49.3870092, lng: 18.8622413 },
    { lat: 49.3869549, lng: 18.8621487 },
    { lat: 49.3869234, lng: 18.8620594 },
    { lat: 49.3868775, lng: 18.8619553 },
    { lat: 49.3868395, lng: 18.8618502 },
    { lat: 49.386795, lng: 18.8617332 },
    { lat: 49.3866043, lng: 18.8618606 },
    { lat: 49.3865257, lng: 18.8617788 },
    { lat: 49.3863929, lng: 18.861608 },
    { lat: 49.3863299, lng: 18.8614556 },
    { lat: 49.3862796, lng: 18.8612625 },
    { lat: 49.3862164, lng: 18.8610428 },
    { lat: 49.3860942, lng: 18.8606793 },
    { lat: 49.3859362, lng: 18.860412 },
    { lat: 49.3858451, lng: 18.8602611 },
    { lat: 49.3857716, lng: 18.8601458 },
    { lat: 49.3857346, lng: 18.8600641 },
    { lat: 49.3857017, lng: 18.8600119 },
    { lat: 49.3855196, lng: 18.8597156 },
    { lat: 49.3854538, lng: 18.859464 },
    { lat: 49.3854311, lng: 18.8593705 },
    { lat: 49.3853679, lng: 18.8592391 },
    { lat: 49.3853457, lng: 18.8591791 },
    { lat: 49.3853296, lng: 18.8591507 },
    { lat: 49.3852492, lng: 18.8591212 },
    { lat: 49.3852326, lng: 18.8590834 },
    { lat: 49.3852337, lng: 18.8589758 },
    { lat: 49.3852563, lng: 18.8587737 },
    { lat: 49.3852004, lng: 18.8585401 },
    { lat: 49.3851379, lng: 18.8583753 },
    { lat: 49.3849713, lng: 18.8583157 },
    { lat: 49.3848984, lng: 18.8582332 },
    { lat: 49.3848775, lng: 18.8581347 },
    { lat: 49.3848797, lng: 18.8578909 },
    { lat: 49.3848851, lng: 18.8578432 },
    { lat: 49.3848371, lng: 18.8577864 },
    { lat: 49.3848112, lng: 18.8577204 },
    { lat: 49.3847779, lng: 18.8576878 },
    { lat: 49.3846938, lng: 18.8575202 },
    { lat: 49.3846084, lng: 18.8573936 },
    { lat: 49.3845739, lng: 18.8572447 },
    { lat: 49.3844698, lng: 18.8570382 },
    { lat: 49.3844493, lng: 18.856947 },
    { lat: 49.3842757, lng: 18.8567264 },
    { lat: 49.3842488, lng: 18.8566442 },
    { lat: 49.3842197, lng: 18.8564722 },
    { lat: 49.384207, lng: 18.8563995 },
    { lat: 49.384196, lng: 18.8563136 },
    { lat: 49.384179, lng: 18.8561611 },
    { lat: 49.3841338, lng: 18.855936 },
    { lat: 49.3841004, lng: 18.8559668 },
    { lat: 49.3836352, lng: 18.8550925 },
    { lat: 49.3826244, lng: 18.8554753 },
    { lat: 49.3823618, lng: 18.8555871 },
    { lat: 49.3821136, lng: 18.8556961 },
    { lat: 49.3817788, lng: 18.8558944 },
    { lat: 49.3816122, lng: 18.8560319 },
    { lat: 49.3814233, lng: 18.8562199 },
    { lat: 49.3813585, lng: 18.8564079 },
    { lat: 49.3811105, lng: 18.8562335 },
    { lat: 49.3809709, lng: 18.8563899 },
    { lat: 49.3807999, lng: 18.8565081 },
    { lat: 49.3801735, lng: 18.8570239 },
    { lat: 49.3797448, lng: 18.8575026 },
    { lat: 49.379716, lng: 18.857535 },
    { lat: 49.3793095, lng: 18.8580773 },
    { lat: 49.379038, lng: 18.8584094 },
    { lat: 49.3782984, lng: 18.8591373 },
    { lat: 49.3779408, lng: 18.8597732 },
    { lat: 49.377592, lng: 18.8609609 },
    { lat: 49.3775664, lng: 18.862357 },
    { lat: 49.3773354, lng: 18.8632671 },
    { lat: 49.3768045, lng: 18.8637989 },
    { lat: 49.3767744, lng: 18.8638289 },
    { lat: 49.3744068, lng: 18.8650673 },
    { lat: 49.3736707, lng: 18.8654669 },
    { lat: 49.3731732, lng: 18.8655279 },
    { lat: 49.3726172, lng: 18.8656968 },
    { lat: 49.3718722, lng: 18.8660333 },
    { lat: 49.3711088, lng: 18.8666756 },
    { lat: 49.3710885, lng: 18.8666943 },
    { lat: 49.37041, lng: 18.8670504 },
    { lat: 49.3700069, lng: 18.8671276 },
    { lat: 49.3689006, lng: 18.8677414 },
    { lat: 49.3679545, lng: 18.8685758 },
    { lat: 49.3671001, lng: 18.8695563 },
    { lat: 49.3668647, lng: 18.8697114 },
    { lat: 49.3664848, lng: 18.8703153 },
    { lat: 49.3660755, lng: 18.8708642 },
    { lat: 49.3657829, lng: 18.8712511 },
    { lat: 49.3657432, lng: 18.8712696 },
  ],
  Zákopčie: [
    { lat: 49.370861, lng: 18.7322 },
    { lat: 49.372561, lng: 18.732308 },
    { lat: 49.374155, lng: 18.732859 },
    { lat: 49.37508, lng: 18.73278 },
    { lat: 49.376503, lng: 18.732111 },
    { lat: 49.377372, lng: 18.733478 },
    { lat: 49.378182, lng: 18.735356 },
    { lat: 49.379123, lng: 18.737818 },
    { lat: 49.379987, lng: 18.739653 },
    { lat: 49.380408, lng: 18.741371 },
    { lat: 49.3805359, lng: 18.741735 },
    { lat: 49.380551, lng: 18.741778 },
    { lat: 49.380518, lng: 18.742352 },
    { lat: 49.380891, lng: 18.742913 },
    { lat: 49.381226, lng: 18.743348 },
    { lat: 49.381892, lng: 18.743627 },
    { lat: 49.383378, lng: 18.7433588 },
    { lat: 49.383482, lng: 18.74334 },
    { lat: 49.384211, lng: 18.743553 },
    { lat: 49.3846465, lng: 18.7438614 },
    { lat: 49.384917, lng: 18.744053 },
    { lat: 49.3860475, lng: 18.7442669 },
    { lat: 49.3861002, lng: 18.7443086 },
    { lat: 49.3863358, lng: 18.7446342 },
    { lat: 49.386936, lng: 18.745389 },
    { lat: 49.387464, lng: 18.746193 },
    { lat: 49.388172, lng: 18.74743 },
    { lat: 49.3882824, lng: 18.7476795 },
    { lat: 49.38841, lng: 18.747968 },
    { lat: 49.3885, lng: 18.748504 },
    { lat: 49.388664, lng: 18.74888 },
    { lat: 49.3891244, lng: 18.7484663 },
    { lat: 49.3893907, lng: 18.7482698 },
    { lat: 49.389559, lng: 18.7481711 },
    { lat: 49.3899972, lng: 18.7485744 },
    { lat: 49.3902287, lng: 18.7487319 },
    { lat: 49.3907458, lng: 18.7497431 },
    { lat: 49.3910713, lng: 18.7500592 },
    { lat: 49.390598, lng: 18.7522996 },
    { lat: 49.3905182, lng: 18.7530195 },
    { lat: 49.3909038, lng: 18.7534963 },
    { lat: 49.3907234, lng: 18.7544953 },
    { lat: 49.3908098, lng: 18.7555529 },
    { lat: 49.3908987, lng: 18.7567007 },
    { lat: 49.3910431, lng: 18.7580036 },
    { lat: 49.3911049, lng: 18.7586053 },
    { lat: 49.3911305, lng: 18.7591437 },
    { lat: 49.3911916, lng: 18.7604303 },
    { lat: 49.3914564, lng: 18.7611676 },
    { lat: 49.3916165, lng: 18.7617938 },
    { lat: 49.3916319, lng: 18.7618531 },
    { lat: 49.3916631, lng: 18.7619771 },
    { lat: 49.3916747, lng: 18.7620267 },
    { lat: 49.3915654, lng: 18.7620285 },
    { lat: 49.3912792, lng: 18.7620333 },
    { lat: 49.3910466, lng: 18.762349 },
    { lat: 49.3909734, lng: 18.7631339 },
    { lat: 49.3905911, lng: 18.7636344 },
    { lat: 49.3902784, lng: 18.7640185 },
    { lat: 49.3903608, lng: 18.7642365 },
    { lat: 49.3901708, lng: 18.7651164 },
    { lat: 49.3901463, lng: 18.765473 },
    { lat: 49.3891038, lng: 18.765416 },
    { lat: 49.3881639, lng: 18.7656856 },
    { lat: 49.3874785, lng: 18.7661013 },
    { lat: 49.3881426, lng: 18.7667516 },
    { lat: 49.389013, lng: 18.7677931 },
    { lat: 49.389604, lng: 18.7684292 },
    { lat: 49.3895677, lng: 18.7685545 },
    { lat: 49.3893488, lng: 18.7693137 },
    { lat: 49.3884956, lng: 18.7699097 },
    { lat: 49.3884787, lng: 18.7702582 },
    { lat: 49.3887774, lng: 18.7723885 },
    { lat: 49.3896138, lng: 18.773735 },
    { lat: 49.3908515, lng: 18.7743809 },
    { lat: 49.3908712, lng: 18.7743908 },
    { lat: 49.3912734, lng: 18.7746015 },
    { lat: 49.3921406, lng: 18.7751064 },
    { lat: 49.3934264, lng: 18.774961 },
    { lat: 49.3944151, lng: 18.7748083 },
    { lat: 49.3944935, lng: 18.7756466 },
    { lat: 49.3954183, lng: 18.7770437 },
    { lat: 49.3955908, lng: 18.7774564 },
    { lat: 49.3956025, lng: 18.7774854 },
    { lat: 49.395769, lng: 18.7777848 },
    { lat: 49.3960166, lng: 18.778233 },
    { lat: 49.3960787, lng: 18.778346 },
    { lat: 49.3961025, lng: 18.778413 },
    { lat: 49.3965801, lng: 18.7791224 },
    { lat: 49.3967036, lng: 18.7793963 },
    { lat: 49.3968653, lng: 18.7796221 },
    { lat: 49.3969723, lng: 18.7797068 },
    { lat: 49.3970969, lng: 18.7797822 },
    { lat: 49.3972965, lng: 18.7798094 },
    { lat: 49.397408, lng: 18.7799135 },
    { lat: 49.39767, lng: 18.7804888 },
    { lat: 49.3979781, lng: 18.7804517 },
    { lat: 49.3981251, lng: 18.780435 },
    { lat: 49.3986757, lng: 18.7812265 },
    { lat: 49.3995038, lng: 18.7822862 },
    { lat: 49.3997481, lng: 18.7826284 },
    { lat: 49.3999268, lng: 18.7828996 },
    { lat: 49.4002873, lng: 18.7830911 },
    { lat: 49.4004695, lng: 18.783431 },
    { lat: 49.4005326, lng: 18.7834303 },
    { lat: 49.4005903, lng: 18.7834997 },
    { lat: 49.400692, lng: 18.7836991 },
    { lat: 49.4007039, lng: 18.7837136 },
    { lat: 49.4010362, lng: 18.7841186 },
    { lat: 49.4010673, lng: 18.7841532 },
    { lat: 49.4012443, lng: 18.7843504 },
    { lat: 49.4015504, lng: 18.784819 },
    { lat: 49.401915, lng: 18.7853511 },
    { lat: 49.4020288, lng: 18.785523 },
    { lat: 49.4022731, lng: 18.7857783 },
    { lat: 49.402298, lng: 18.7858046 },
    { lat: 49.402502, lng: 18.7863458 },
    { lat: 49.4026988, lng: 18.7867299 },
    { lat: 49.4035883, lng: 18.7872723 },
    { lat: 49.4036747, lng: 18.7872209 },
    { lat: 49.4036802, lng: 18.7872184 },
    { lat: 49.4039302, lng: 18.7870802 },
    { lat: 49.403941, lng: 18.7871169 },
    { lat: 49.4041222, lng: 18.7870428 },
    { lat: 49.404323, lng: 18.7869601 },
    { lat: 49.4043444, lng: 18.7869572 },
    { lat: 49.4044103, lng: 18.7869481 },
    { lat: 49.4044479, lng: 18.7869131 },
    { lat: 49.4045278, lng: 18.7868622 },
    { lat: 49.4045537, lng: 18.7868605 },
    { lat: 49.4046097, lng: 18.7868559 },
    { lat: 49.4048039, lng: 18.786774 },
    { lat: 49.4048524, lng: 18.7867533 },
    { lat: 49.4050438, lng: 18.7867026 },
    { lat: 49.4053051, lng: 18.7864134 },
    { lat: 49.4054971, lng: 18.7862243 },
    { lat: 49.4056416, lng: 18.7860775 },
    { lat: 49.4058129, lng: 18.7859221 },
    { lat: 49.4057212, lng: 18.7846486 },
    { lat: 49.405712, lng: 18.7845581 },
    { lat: 49.40571, lng: 18.7845377 },
    { lat: 49.4057111, lng: 18.7845177 },
    { lat: 49.405722, lng: 18.7843353 },
    { lat: 49.4057123, lng: 18.7842116 },
    { lat: 49.4057044, lng: 18.784105 },
    { lat: 49.4057323, lng: 18.7837942 },
    { lat: 49.4060155, lng: 18.7835781 },
    { lat: 49.406019, lng: 18.7834955 },
    { lat: 49.4060209, lng: 18.7834627 },
    { lat: 49.4060384, lng: 18.7831064 },
    { lat: 49.4061977, lng: 18.7823713 },
    { lat: 49.4063509, lng: 18.7818031 },
    { lat: 49.4066813, lng: 18.7806883 },
    { lat: 49.4067498, lng: 18.7804646 },
    { lat: 49.4075565, lng: 18.7783375 },
    { lat: 49.4077306, lng: 18.7769977 },
    { lat: 49.4079142, lng: 18.7752051 },
    { lat: 49.4083978, lng: 18.7737892 },
    { lat: 49.4086741, lng: 18.7734392 },
    { lat: 49.4090933, lng: 18.7726738 },
    { lat: 49.4091593, lng: 18.7725101 },
    { lat: 49.4092023, lng: 18.772406 },
    { lat: 49.4097052, lng: 18.7711659 },
    { lat: 49.4098679, lng: 18.7708462 },
    { lat: 49.4100158, lng: 18.7704571 },
    { lat: 49.4100337, lng: 18.7704104 },
    { lat: 49.410134, lng: 18.7701491 },
    { lat: 49.4104056, lng: 18.7692118 },
    { lat: 49.4109184, lng: 18.7678354 },
    { lat: 49.4108436, lng: 18.7662823 },
    { lat: 49.4108273, lng: 18.7659454 },
    { lat: 49.410751, lng: 18.7654539 },
    { lat: 49.4107531, lng: 18.7652534 },
    { lat: 49.4104848, lng: 18.7643435 },
    { lat: 49.4104414, lng: 18.7639388 },
    { lat: 49.4104333, lng: 18.7633901 },
    { lat: 49.4105515, lng: 18.7616709 },
    { lat: 49.410457, lng: 18.7614337 },
    { lat: 49.4102723, lng: 18.7604828 },
    { lat: 49.4099656, lng: 18.7596587 },
    { lat: 49.4097985, lng: 18.7594347 },
    { lat: 49.4098516, lng: 18.7591092 },
    { lat: 49.4097145, lng: 18.7589028 },
    { lat: 49.4097592, lng: 18.7585893 },
    { lat: 49.4096086, lng: 18.7584475 },
    { lat: 49.4095152, lng: 18.7581689 },
    { lat: 49.4095983, lng: 18.757684 },
    { lat: 49.4097039, lng: 18.7574832 },
    { lat: 49.4096274, lng: 18.7570924 },
    { lat: 49.4095159, lng: 18.7571172 },
    { lat: 49.4094595, lng: 18.756317 },
    { lat: 49.4094448, lng: 18.7560972 },
    { lat: 49.4093098, lng: 18.75504 },
    { lat: 49.4091718, lng: 18.7545933 },
    { lat: 49.4090866, lng: 18.7536271 },
    { lat: 49.4091398, lng: 18.7536319 },
    { lat: 49.4092179, lng: 18.753312 },
    { lat: 49.4092571, lng: 18.753329 },
    { lat: 49.409387, lng: 18.7530108 },
    { lat: 49.4095681, lng: 18.7516757 },
    { lat: 49.4096323, lng: 18.7513695 },
    { lat: 49.4097, lng: 18.7510436 },
    { lat: 49.409737, lng: 18.7508686 },
    { lat: 49.4097442, lng: 18.7508344 },
    { lat: 49.4097506, lng: 18.7508032 },
    { lat: 49.4097968, lng: 18.7505872 },
    { lat: 49.4098012, lng: 18.7504305 },
    { lat: 49.4098143, lng: 18.7499053 },
    { lat: 49.4100301, lng: 18.7499579 },
    { lat: 49.4112836, lng: 18.7507729 },
    { lat: 49.4113127, lng: 18.7498144 },
    { lat: 49.4115476, lng: 18.7482152 },
    { lat: 49.4115659, lng: 18.7471158 },
    { lat: 49.4112962, lng: 18.74527 },
    { lat: 49.4112129, lng: 18.7437546 },
    { lat: 49.4110535, lng: 18.742012 },
    { lat: 49.4108058, lng: 18.7402407 },
    { lat: 49.4108605, lng: 18.7383683 },
    { lat: 49.4109089, lng: 18.7368068 },
    { lat: 49.4105653, lng: 18.7348006 },
    { lat: 49.4101824, lng: 18.7330354 },
    { lat: 49.4097542, lng: 18.7318092 },
    { lat: 49.4094284, lng: 18.730795 },
    { lat: 49.4093256, lng: 18.7296209 },
    { lat: 49.4093174, lng: 18.7295285 },
    { lat: 49.4092625, lng: 18.7288867 },
    { lat: 49.4089019, lng: 18.727012 },
    { lat: 49.4089618, lng: 18.7261487 },
    { lat: 49.4089855, lng: 18.725805 },
    { lat: 49.4099269, lng: 18.7246345 },
    { lat: 49.4110881, lng: 18.7239841 },
    { lat: 49.4120651, lng: 18.7239686 },
    { lat: 49.4126869, lng: 18.7236328 },
    { lat: 49.4127729, lng: 18.7235324 },
    { lat: 49.4128365, lng: 18.7234562 },
    { lat: 49.4132871, lng: 18.7229262 },
    { lat: 49.4139182, lng: 18.7219257 },
    { lat: 49.4144765, lng: 18.7212376 },
    { lat: 49.4151163, lng: 18.7204272 },
    { lat: 49.4147086, lng: 18.7196139 },
    { lat: 49.4147218, lng: 18.7195865 },
    { lat: 49.4147543, lng: 18.7195206 },
    { lat: 49.4147696, lng: 18.7194883 },
    { lat: 49.4150216, lng: 18.7191966 },
    { lat: 49.4150542, lng: 18.7191735 },
    { lat: 49.4150868, lng: 18.719151 },
    { lat: 49.4151254, lng: 18.7190898 },
    { lat: 49.4151078, lng: 18.719055 },
    { lat: 49.4150734, lng: 18.718826 },
    { lat: 49.4151105, lng: 18.718569 },
    { lat: 49.4151469, lng: 18.7183345 },
    { lat: 49.4151154, lng: 18.7181012 },
    { lat: 49.4149062, lng: 18.7179146 },
    { lat: 49.4146523, lng: 18.7179125 },
    { lat: 49.4143897, lng: 18.7179563 },
    { lat: 49.4142028, lng: 18.7178561 },
    { lat: 49.4140726, lng: 18.7178478 },
    { lat: 49.4140299, lng: 18.7178255 },
    { lat: 49.413813, lng: 18.7177536 },
    { lat: 49.4136118, lng: 18.7177475 },
    { lat: 49.4134954, lng: 18.7176767 },
    { lat: 49.4133871, lng: 18.717502 },
    { lat: 49.4128675, lng: 18.7168151 },
    { lat: 49.4125649, lng: 18.7167856 },
    { lat: 49.4125104, lng: 18.7165844 },
    { lat: 49.4124992, lng: 18.7163917 },
    { lat: 49.4123593, lng: 18.7160876 },
    { lat: 49.4121727, lng: 18.7160352 },
    { lat: 49.4121995, lng: 18.7157255 },
    { lat: 49.4122545, lng: 18.7152042 },
    { lat: 49.4120744, lng: 18.7147153 },
    { lat: 49.4119547, lng: 18.7142777 },
    { lat: 49.4116341, lng: 18.7139031 },
    { lat: 49.4109575, lng: 18.7130436 },
    { lat: 49.4109579, lng: 18.7128339 },
    { lat: 49.410995, lng: 18.7123372 },
    { lat: 49.411043, lng: 18.7120039 },
    { lat: 49.4111473, lng: 18.7116036 },
    { lat: 49.4113767, lng: 18.7109019 },
    { lat: 49.4115808, lng: 18.7100196 },
    { lat: 49.4116363, lng: 18.7096362 },
    { lat: 49.4116588, lng: 18.7089454 },
    { lat: 49.4115031, lng: 18.7076949 },
    { lat: 49.4114746, lng: 18.7073844 },
    { lat: 49.4115175, lng: 18.7069543 },
    { lat: 49.4116807, lng: 18.7061756 },
    { lat: 49.4117015, lng: 18.7061176 },
    { lat: 49.41173, lng: 18.7060395 },
    { lat: 49.4117429, lng: 18.7060061 },
    { lat: 49.4113769, lng: 18.7059921 },
    { lat: 49.4113542, lng: 18.7059912 },
    { lat: 49.4107021, lng: 18.7060066 },
    { lat: 49.410151, lng: 18.7060551 },
    { lat: 49.409625, lng: 18.7061719 },
    { lat: 49.4093178, lng: 18.7062082 },
    { lat: 49.4092658, lng: 18.7062472 },
    { lat: 49.4087687, lng: 18.7060776 },
    { lat: 49.4083472, lng: 18.7059966 },
    { lat: 49.4081423, lng: 18.7046874 },
    { lat: 49.4080312, lng: 18.7041589 },
    { lat: 49.4080251, lng: 18.7041282 },
    { lat: 49.4078417, lng: 18.7039244 },
    { lat: 49.4076981, lng: 18.7037628 },
    { lat: 49.4077287, lng: 18.7036606 },
    { lat: 49.4076094, lng: 18.7034268 },
    { lat: 49.4072623, lng: 18.7027413 },
    { lat: 49.407315, lng: 18.7024571 },
    { lat: 49.4073013, lng: 18.7020866 },
    { lat: 49.4063819, lng: 18.701046 },
    { lat: 49.4062124, lng: 18.701039 },
    { lat: 49.4061136, lng: 18.7010019 },
    { lat: 49.4055962, lng: 18.7002544 },
    { lat: 49.4048878, lng: 18.6993668 },
    { lat: 49.4045189, lng: 18.699082 },
    { lat: 49.4042308, lng: 18.6988144 },
    { lat: 49.4039533, lng: 18.6984028 },
    { lat: 49.403376, lng: 18.6977805 },
    { lat: 49.4032751, lng: 18.6975554 },
    { lat: 49.4030474, lng: 18.6970421 },
    { lat: 49.4029577, lng: 18.6968359 },
    { lat: 49.4029164, lng: 18.6965625 },
    { lat: 49.4029033, lng: 18.6962716 },
    { lat: 49.4028119, lng: 18.6956018 },
    { lat: 49.4026935, lng: 18.694785 },
    { lat: 49.4027849, lng: 18.6947224 },
    { lat: 49.4027781, lng: 18.6946153 },
    { lat: 49.4027467, lng: 18.6945113 },
    { lat: 49.4026409, lng: 18.6944265 },
    { lat: 49.4025583, lng: 18.6940373 },
    { lat: 49.4025021, lng: 18.6935243 },
    { lat: 49.4024851, lng: 18.6931781 },
    { lat: 49.4024595, lng: 18.692582 },
    { lat: 49.4024691, lng: 18.6923553 },
    { lat: 49.4024572, lng: 18.6922188 },
    { lat: 49.4024522, lng: 18.6921633 },
    { lat: 49.4023849, lng: 18.6918486 },
    { lat: 49.4023466, lng: 18.6915466 },
    { lat: 49.4022553, lng: 18.6910999 },
    { lat: 49.4022253, lng: 18.6907614 },
    { lat: 49.4021924, lng: 18.6904588 },
    { lat: 49.4021465, lng: 18.6900763 },
    { lat: 49.4021135, lng: 18.6898164 },
    { lat: 49.4020322, lng: 18.6893605 },
    { lat: 49.4019722, lng: 18.6891184 },
    { lat: 49.4019285, lng: 18.6888033 },
    { lat: 49.4018783, lng: 18.6866635 },
    { lat: 49.4019018, lng: 18.6862975 },
    { lat: 49.4018447, lng: 18.6845658 },
    { lat: 49.4018353, lng: 18.6842792 },
    { lat: 49.4018257, lng: 18.684012 },
    { lat: 49.4018168, lng: 18.683734 },
    { lat: 49.4017942, lng: 18.6830485 },
    { lat: 49.4017753, lng: 18.6824719 },
    { lat: 49.4017577, lng: 18.6819657 },
    { lat: 49.4017539, lng: 18.6818492 },
    { lat: 49.4017449, lng: 18.6815744 },
    { lat: 49.4018436, lng: 18.6809398 },
    { lat: 49.4018982, lng: 18.6805855 },
    { lat: 49.4019173, lng: 18.680283 },
    { lat: 49.4019601, lng: 18.6801756 },
    { lat: 49.4019911, lng: 18.6801001 },
    { lat: 49.402017, lng: 18.6799274 },
    { lat: 49.4020143, lng: 18.6797449 },
    { lat: 49.4019919, lng: 18.6795576 },
    { lat: 49.4019577, lng: 18.679379 },
    { lat: 49.4019382, lng: 18.67918 },
    { lat: 49.401951, lng: 18.6788019 },
    { lat: 49.401964, lng: 18.6783846 },
    { lat: 49.401974, lng: 18.6781039 },
    { lat: 49.401968, lng: 18.6779674 },
    { lat: 49.4019444, lng: 18.6774547 },
    { lat: 49.401943, lng: 18.6771902 },
    { lat: 49.4019415, lng: 18.6768207 },
    { lat: 49.401883, lng: 18.6764362 },
    { lat: 49.4018611, lng: 18.6762987 },
    { lat: 49.4018349, lng: 18.6761238 },
    { lat: 49.4018557, lng: 18.6758765 },
    { lat: 49.4018826, lng: 18.6755705 },
    { lat: 49.4018763, lng: 18.6750463 },
    { lat: 49.4018683, lng: 18.6748271 },
    { lat: 49.401849, lng: 18.6742049 },
    { lat: 49.4018174, lng: 18.6732634 },
    { lat: 49.401857, lng: 18.6728577 },
    { lat: 49.4018723, lng: 18.6726987 },
    { lat: 49.4019448, lng: 18.6722306 },
    { lat: 49.4020269, lng: 18.6718866 },
    { lat: 49.4020963, lng: 18.6715981 },
    { lat: 49.4020797, lng: 18.6710915 },
    { lat: 49.4020623, lng: 18.6705005 },
    { lat: 49.4020556, lng: 18.67048 },
    { lat: 49.4019256, lng: 18.6700485 },
    { lat: 49.4018931, lng: 18.6699874 },
    { lat: 49.401743, lng: 18.669704 },
    { lat: 49.4016778, lng: 18.6696009 },
    { lat: 49.4015439, lng: 18.6693929 },
    { lat: 49.4011375, lng: 18.6690873 },
    { lat: 49.4010564, lng: 18.669001 },
    { lat: 49.4009369, lng: 18.6688735 },
    { lat: 49.4008138, lng: 18.6687428 },
    { lat: 49.400772, lng: 18.6686987 },
    { lat: 49.400551, lng: 18.6682716 },
    { lat: 49.4003256, lng: 18.6678462 },
    { lat: 49.4002457, lng: 18.6676964 },
    { lat: 49.4000304, lng: 18.667122 },
    { lat: 49.4, lng: 18.6671015 },
    { lat: 49.3994967, lng: 18.6667521 },
    { lat: 49.3993568, lng: 18.6666673 },
    { lat: 49.3980016, lng: 18.6658368 },
    { lat: 49.397935, lng: 18.6656846 },
    { lat: 49.3978631, lng: 18.6655168 },
    { lat: 49.3979602, lng: 18.6651775 },
    { lat: 49.398077, lng: 18.6648274 },
    { lat: 49.3980645, lng: 18.6647616 },
    { lat: 49.3979049, lng: 18.6647289 },
    { lat: 49.3977838, lng: 18.6646983 },
    { lat: 49.3971836, lng: 18.664779 },
    { lat: 49.3966444, lng: 18.6652646 },
    { lat: 49.3962356, lng: 18.6653637 },
    { lat: 49.3957906, lng: 18.6655861 },
    { lat: 49.395398, lng: 18.6656882 },
    { lat: 49.3953364, lng: 18.665437 },
    { lat: 49.3949174, lng: 18.6655753 },
    { lat: 49.3943998, lng: 18.6655326 },
    { lat: 49.394361, lng: 18.6653976 },
    { lat: 49.394132, lng: 18.6645814 },
    { lat: 49.394017, lng: 18.6642614 },
    { lat: 49.3937872, lng: 18.6638192 },
    { lat: 49.3938695, lng: 18.6637305 },
    { lat: 49.3940204, lng: 18.6637296 },
    { lat: 49.3941561, lng: 18.6636273 },
    { lat: 49.3945142, lng: 18.6632749 },
    { lat: 49.3946335, lng: 18.6633156 },
    { lat: 49.3947139, lng: 18.663105 },
    { lat: 49.3947847, lng: 18.6630188 },
    { lat: 49.3949133, lng: 18.6631676 },
    { lat: 49.3950335, lng: 18.6630724 },
    { lat: 49.395146, lng: 18.6630443 },
    { lat: 49.3953614, lng: 18.6626632 },
    { lat: 49.3955926, lng: 18.6626643 },
    { lat: 49.3960311, lng: 18.6626799 },
    { lat: 49.3963053, lng: 18.662579 },
    { lat: 49.3964086, lng: 18.6624637 },
    { lat: 49.3964903, lng: 18.6623646 },
    { lat: 49.3965081, lng: 18.6623392 },
    { lat: 49.3965306, lng: 18.6622944 },
    { lat: 49.3966174, lng: 18.6621215 },
    { lat: 49.3964033, lng: 18.6620404 },
    { lat: 49.3960192, lng: 18.6618766 },
    { lat: 49.3956465, lng: 18.6616743 },
    { lat: 49.3955099, lng: 18.6615674 },
    { lat: 49.3954354, lng: 18.6614347 },
    { lat: 49.3948953, lng: 18.6610911 },
    { lat: 49.3948915, lng: 18.6610206 },
    { lat: 49.3944693, lng: 18.6605649 },
    { lat: 49.3941319, lng: 18.6603048 },
    { lat: 49.3936899, lng: 18.6598783 },
    { lat: 49.393019, lng: 18.6592982 },
    { lat: 49.3925868, lng: 18.6587399 },
    { lat: 49.3926208, lng: 18.6586347 },
    { lat: 49.3928125, lng: 18.6575437 },
    { lat: 49.3929012, lng: 18.6568963 },
    { lat: 49.3930344, lng: 18.6565377 },
    { lat: 49.3931909, lng: 18.6560817 },
    { lat: 49.3932453, lng: 18.6556177 },
    { lat: 49.393201, lng: 18.6551638 },
    { lat: 49.3932609, lng: 18.6543328 },
    { lat: 49.3935031, lng: 18.6540291 },
    { lat: 49.3936524, lng: 18.6536561 },
    { lat: 49.3936624, lng: 18.6531828 },
    { lat: 49.3935533, lng: 18.6532079 },
    { lat: 49.3931218, lng: 18.6530236 },
    { lat: 49.3931251, lng: 18.6531705 },
    { lat: 49.3929925, lng: 18.6532242 },
    { lat: 49.3927605, lng: 18.6532736 },
    { lat: 49.3926515, lng: 18.6533005 },
    { lat: 49.3926487, lng: 18.6533552 },
    { lat: 49.3924977, lng: 18.653337 },
    { lat: 49.3917286, lng: 18.6531409 },
    { lat: 49.3911499, lng: 18.6530103 },
    { lat: 49.3910355, lng: 18.653002 },
    { lat: 49.3909001, lng: 18.6529337 },
    { lat: 49.3902967, lng: 18.6526994 },
    { lat: 49.3901246, lng: 18.6526856 },
    { lat: 49.3899859, lng: 18.6526452 },
    { lat: 49.3898655, lng: 18.6527539 },
    { lat: 49.389801, lng: 18.6528122 },
    { lat: 49.3891613, lng: 18.6535543 },
    { lat: 49.3889352, lng: 18.6535871 },
    { lat: 49.3888321, lng: 18.6534731 },
    { lat: 49.3886706, lng: 18.6529527 },
    { lat: 49.3884902, lng: 18.6526562 },
    { lat: 49.3882779, lng: 18.6523722 },
    { lat: 49.3882311, lng: 18.6524928 },
    { lat: 49.388094, lng: 18.6527545 },
    { lat: 49.3879208, lng: 18.6530196 },
    { lat: 49.387719, lng: 18.6532571 },
    { lat: 49.3875463, lng: 18.6534903 },
    { lat: 49.3874294, lng: 18.6539424 },
    { lat: 49.3873013, lng: 18.6542967 },
    { lat: 49.3870118, lng: 18.6545773 },
    { lat: 49.3868047, lng: 18.654755 },
    { lat: 49.3865252, lng: 18.6549916 },
    { lat: 49.3862403, lng: 18.6552977 },
    { lat: 49.3861671, lng: 18.6554063 },
    { lat: 49.3860846, lng: 18.6555761 },
    { lat: 49.3859405, lng: 18.6557058 },
    { lat: 49.3857907, lng: 18.6557559 },
    { lat: 49.3855989, lng: 18.6558151 },
    { lat: 49.385476, lng: 18.6558821 },
    { lat: 49.3853102, lng: 18.6559638 },
    { lat: 49.3852075, lng: 18.6560504 },
    { lat: 49.3850543, lng: 18.6561805 },
    { lat: 49.3849513, lng: 18.6563004 },
    { lat: 49.3848252, lng: 18.6564988 },
    { lat: 49.3846789, lng: 18.6567402 },
    { lat: 49.3845121, lng: 18.6570411 },
    { lat: 49.3844322, lng: 18.6571925 },
    { lat: 49.3844071, lng: 18.6571641 },
    { lat: 49.3840359, lng: 18.6570979 },
    { lat: 49.3838581, lng: 18.6577015 },
    { lat: 49.3836767, lng: 18.6583028 },
    { lat: 49.3835635, lng: 18.6588293 },
    { lat: 49.383489, lng: 18.6594072 },
    { lat: 49.3832803, lng: 18.6600462 },
    { lat: 49.3830887, lng: 18.6604952 },
    { lat: 49.3829002, lng: 18.6607277 },
    { lat: 49.3826111, lng: 18.6609332 },
    { lat: 49.3824078, lng: 18.6613124 },
    { lat: 49.3821448, lng: 18.661671 },
    { lat: 49.381927, lng: 18.6621591 },
    { lat: 49.3818252, lng: 18.6624066 },
    { lat: 49.3817971, lng: 18.6623887 },
    { lat: 49.3811597, lng: 18.6632164 },
    { lat: 49.3811408, lng: 18.6640371 },
    { lat: 49.3812517, lng: 18.6639803 },
    { lat: 49.3817757, lng: 18.6654472 },
    { lat: 49.3815823, lng: 18.6656919 },
    { lat: 49.3818915, lng: 18.6661249 },
    { lat: 49.3820918, lng: 18.6664592 },
    { lat: 49.3821123, lng: 18.6664451 },
    { lat: 49.3824266, lng: 18.6672072 },
    { lat: 49.3826612, lng: 18.6678695 },
    { lat: 49.3828248, lng: 18.668171 },
    { lat: 49.3829345, lng: 18.6683475 },
    { lat: 49.3829489, lng: 18.6683877 },
    { lat: 49.3830964, lng: 18.6690025 },
    { lat: 49.3832195, lng: 18.6697119 },
    { lat: 49.3833277, lng: 18.6703978 },
    { lat: 49.3834088, lng: 18.670847 },
    { lat: 49.3836043, lng: 18.6715616 },
    { lat: 49.3837552, lng: 18.6718336 },
    { lat: 49.3839873, lng: 18.672408 },
    { lat: 49.3839994, lng: 18.6728538 },
    { lat: 49.3840501, lng: 18.6732783 },
    { lat: 49.3840029, lng: 18.673603 },
    { lat: 49.3839598, lng: 18.6738559 },
    { lat: 49.3838645, lng: 18.6739951 },
    { lat: 49.3835822, lng: 18.6742428 },
    { lat: 49.3833253, lng: 18.6745915 },
    { lat: 49.3829961, lng: 18.6748519 },
    { lat: 49.3826677, lng: 18.6753818 },
    { lat: 49.3824, lng: 18.6758626 },
    { lat: 49.3820377, lng: 18.6765208 },
    { lat: 49.3818866, lng: 18.6768659 },
    { lat: 49.3817942, lng: 18.6771661 },
    { lat: 49.3817352, lng: 18.6775889 },
    { lat: 49.3816618, lng: 18.6778434 },
    { lat: 49.381552, lng: 18.6780281 },
    { lat: 49.381319, lng: 18.6781854 },
    { lat: 49.3812379, lng: 18.6782525 },
    { lat: 49.3811963, lng: 18.6782971 },
    { lat: 49.3810249, lng: 18.6786411 },
    { lat: 49.3809443, lng: 18.67908 },
    { lat: 49.3808768, lng: 18.6791903 },
    { lat: 49.3807101, lng: 18.6794748 },
    { lat: 49.3805903, lng: 18.6796187 },
    { lat: 49.3803062, lng: 18.6797935 },
    { lat: 49.3800928, lng: 18.6798904 },
    { lat: 49.3798121, lng: 18.68 },
    { lat: 49.3795254, lng: 18.679976 },
    { lat: 49.3792116, lng: 18.680057 },
    { lat: 49.3789289, lng: 18.6800814 },
    { lat: 49.3785677, lng: 18.6799888 },
    { lat: 49.3783637, lng: 18.6798613 },
    { lat: 49.3780424, lng: 18.6798007 },
    { lat: 49.3777716, lng: 18.6796886 },
    { lat: 49.3775674, lng: 18.6796234 },
    { lat: 49.3771084, lng: 18.6794969 },
    { lat: 49.3768693, lng: 18.6795604 },
    { lat: 49.3767166, lng: 18.6794815 },
    { lat: 49.3760764, lng: 18.6795345 },
    { lat: 49.3757167, lng: 18.679619 },
    { lat: 49.3754078, lng: 18.6796618 },
    { lat: 49.3752871, lng: 18.6796822 },
    { lat: 49.3749857, lng: 18.6798493 },
    { lat: 49.3748436, lng: 18.6800018 },
    { lat: 49.37476, lng: 18.6801239 },
    { lat: 49.3746623, lng: 18.6803227 },
    { lat: 49.3745289, lng: 18.6805552 },
    { lat: 49.3742878, lng: 18.6807503 },
    { lat: 49.3740106, lng: 18.6809694 },
    { lat: 49.3738234, lng: 18.6810768 },
    { lat: 49.3735458, lng: 18.6811581 },
    { lat: 49.3733453, lng: 18.681206 },
    { lat: 49.3731478, lng: 18.6813317 },
    { lat: 49.3729686, lng: 18.6814837 },
    { lat: 49.3727531, lng: 18.6816713 },
    { lat: 49.3725953, lng: 18.6817802 },
    { lat: 49.3723331, lng: 18.6819446 },
    { lat: 49.3721242, lng: 18.6820438 },
    { lat: 49.3718588, lng: 18.682163 },
    { lat: 49.3716219, lng: 18.6821832 },
    { lat: 49.3713, lng: 18.6821958 },
    { lat: 49.3709695, lng: 18.6821294 },
    { lat: 49.370633, lng: 18.682036 },
    { lat: 49.3703442, lng: 18.6820108 },
    { lat: 49.3700768, lng: 18.6820087 },
    { lat: 49.3697998, lng: 18.6820164 },
    { lat: 49.3694511, lng: 18.6820135 },
    { lat: 49.369194, lng: 18.6819905 },
    { lat: 49.3689012, lng: 18.6820614 },
    { lat: 49.368605, lng: 18.6820921 },
    { lat: 49.3683636, lng: 18.6821103 },
    { lat: 49.3682898, lng: 18.6821234 },
    { lat: 49.368218, lng: 18.6821912 },
    { lat: 49.3677342, lng: 18.6826836 },
    { lat: 49.3674343, lng: 18.6830911 },
    { lat: 49.3670911, lng: 18.6834942 },
    { lat: 49.3668208, lng: 18.6836516 },
    { lat: 49.3665201, lng: 18.6839597 },
    { lat: 49.3660917, lng: 18.6842372 },
    { lat: 49.3656916, lng: 18.6845192 },
    { lat: 49.365315, lng: 18.6846926 },
    { lat: 49.3649963, lng: 18.6848954 },
    { lat: 49.364723, lng: 18.6849913 },
    { lat: 49.3643585, lng: 18.6850967 },
    { lat: 49.3640196, lng: 18.6852123 },
    { lat: 49.3637927, lng: 18.6852749 },
    { lat: 49.3635193, lng: 18.6853683 },
    { lat: 49.3632097, lng: 18.6854854 },
    { lat: 49.3629654, lng: 18.685624 },
    { lat: 49.3627165, lng: 18.6858435 },
    { lat: 49.3624557, lng: 18.6860743 },
    { lat: 49.3622456, lng: 18.686496 },
    { lat: 49.3621379, lng: 18.6868468 },
    { lat: 49.361971, lng: 18.6875345 },
    { lat: 49.3618433, lng: 18.6881067 },
    { lat: 49.3617261, lng: 18.6886913 },
    { lat: 49.3615291, lng: 18.689769 },
    { lat: 49.3614693, lng: 18.6900058 },
    { lat: 49.361338, lng: 18.690212 },
    { lat: 49.36104, lng: 18.691255 },
    { lat: 49.360512, lng: 18.692085 },
    { lat: 49.359846, lng: 18.692913 },
    { lat: 49.36007, lng: 18.693481 },
    { lat: 49.360453, lng: 18.694117 },
    { lat: 49.360534, lng: 18.694374 },
    { lat: 49.360683, lng: 18.695492 },
    { lat: 49.360885, lng: 18.696212 },
    { lat: 49.3610737, lng: 18.6966616 },
    { lat: 49.361159, lng: 18.696865 },
    { lat: 49.362007, lng: 18.698371 },
    { lat: 49.362111, lng: 18.698759 },
    { lat: 49.362525, lng: 18.699858 },
    { lat: 49.362849, lng: 18.700977 },
    { lat: 49.363826, lng: 18.702075 },
    { lat: 49.364752, lng: 18.702879 },
    { lat: 49.366064, lng: 18.703003 },
    { lat: 49.366396, lng: 18.703199 },
    { lat: 49.367551, lng: 18.704246 },
    { lat: 49.367722, lng: 18.704466 },
    { lat: 49.367837, lng: 18.704731 },
    { lat: 49.368066, lng: 18.705014 },
    { lat: 49.368203, lng: 18.705356 },
    { lat: 49.369359, lng: 18.706709 },
    { lat: 49.369793, lng: 18.708139 },
    { lat: 49.370066, lng: 18.708821 },
    { lat: 49.370157, lng: 18.709974 },
    { lat: 49.370175, lng: 18.710855 },
    { lat: 49.37016, lng: 18.711697 },
    { lat: 49.370071, lng: 18.712119 },
    { lat: 49.370074, lng: 18.712999 },
    { lat: 49.370247, lng: 18.71464 },
    { lat: 49.370174, lng: 18.71583 },
    { lat: 49.369925, lng: 18.716455 },
    { lat: 49.369534, lng: 18.717801 },
    { lat: 49.368743, lng: 18.719906 },
    { lat: 49.368253, lng: 18.720518 },
    { lat: 49.367923, lng: 18.720817 },
    { lat: 49.367434, lng: 18.721258 },
    { lat: 49.366807, lng: 18.721653 },
    { lat: 49.366249, lng: 18.722721 },
    { lat: 49.365944, lng: 18.723489 },
    { lat: 49.365748, lng: 18.724241 },
    { lat: 49.365919, lng: 18.726175 },
    { lat: 49.366039, lng: 18.729477 },
    { lat: 49.366204, lng: 18.730857 },
    { lat: 49.366383, lng: 18.73193 },
    { lat: 49.367506, lng: 18.732011 },
    { lat: 49.367831, lng: 18.732103 },
    { lat: 49.3684, lng: 18.732099 },
    { lat: 49.369021, lng: 18.731905 },
    { lat: 49.369947, lng: 18.732057 },
    { lat: 49.370755, lng: 18.731976 },
    { lat: 49.370861, lng: 18.7322 },
  ],
  Čierne: [
    { lat: 49.5162949, lng: 18.8192338 },
    { lat: 49.5162906, lng: 18.8187414 },
    { lat: 49.516319, lng: 18.817972 },
    { lat: 49.516329, lng: 18.817328 },
    { lat: 49.516343, lng: 18.816648 },
    { lat: 49.516335, lng: 18.816165 },
    { lat: 49.516324, lng: 18.815454 },
    { lat: 49.516347, lng: 18.814488 },
    { lat: 49.516347, lng: 18.813895 },
    { lat: 49.516369, lng: 18.812782 },
    { lat: 49.516377, lng: 18.812371 },
    { lat: 49.51611, lng: 18.811376 },
    { lat: 49.515831, lng: 18.810337 },
    { lat: 49.515562, lng: 18.809287 },
    { lat: 49.515427, lng: 18.808765 },
    { lat: 49.515261, lng: 18.808147 },
    { lat: 49.515076, lng: 18.807584 },
    { lat: 49.514778, lng: 18.807145 },
    { lat: 49.514432, lng: 18.8067 },
    { lat: 49.514129, lng: 18.806313 },
    { lat: 49.513657, lng: 18.805784 },
    { lat: 49.513392, lng: 18.805445 },
    { lat: 49.512888, lng: 18.804794 },
    { lat: 49.51237, lng: 18.80412 },
    { lat: 49.511806, lng: 18.803354 },
    { lat: 49.511006, lng: 18.802189 },
    { lat: 49.510227, lng: 18.801041 },
    { lat: 49.509695, lng: 18.800221 },
    { lat: 49.50955, lng: 18.800028 },
    { lat: 49.509333, lng: 18.79974 },
    { lat: 49.509194, lng: 18.79851 },
    { lat: 49.509128, lng: 18.797818 },
    { lat: 49.509085, lng: 18.797376 },
    { lat: 49.508984, lng: 18.796763 },
    { lat: 49.508952, lng: 18.795945 },
    { lat: 49.508796, lng: 18.794449 },
    { lat: 49.508719, lng: 18.793822 },
    { lat: 49.508634, lng: 18.792999 },
    { lat: 49.50857, lng: 18.792372 },
    { lat: 49.508452, lng: 18.791228 },
    { lat: 49.508402, lng: 18.790074 },
    { lat: 49.508683, lng: 18.7892 },
    { lat: 49.5086583, lng: 18.7888219 },
    { lat: 49.5086577, lng: 18.7888131 },
    { lat: 49.508489, lng: 18.788562 },
    { lat: 49.508286, lng: 18.788037 },
    { lat: 49.5081342, lng: 18.7876466 },
    { lat: 49.5066895, lng: 18.7881915 },
    { lat: 49.505308, lng: 18.7889507 },
    { lat: 49.50445, lng: 18.7896956 },
    { lat: 49.5041606, lng: 18.7900997 },
    { lat: 49.5035538, lng: 18.791055 },
    { lat: 49.5026745, lng: 18.7919787 },
    { lat: 49.5019168, lng: 18.7925234 },
    { lat: 49.5015375, lng: 18.7926688 },
    { lat: 49.501634, lng: 18.7927264 },
    { lat: 49.5009001, lng: 18.7937112 },
    { lat: 49.5000837, lng: 18.7942126 },
    { lat: 49.4993876, lng: 18.7949282 },
    { lat: 49.4992584, lng: 18.7950248 },
    { lat: 49.4987446, lng: 18.7958384 },
    { lat: 49.497552, lng: 18.7965685 },
    { lat: 49.4975372, lng: 18.796583 },
    { lat: 49.4972385, lng: 18.7969167 },
    { lat: 49.4970576, lng: 18.7972767 },
    { lat: 49.4968119, lng: 18.7978045 },
    { lat: 49.4966697, lng: 18.7980452 },
    { lat: 49.4966585, lng: 18.7982225 },
    { lat: 49.4965459, lng: 18.7984447 },
    { lat: 49.4964447, lng: 18.7985161 },
    { lat: 49.4963747, lng: 18.7989279 },
    { lat: 49.4963788, lng: 18.7991418 },
    { lat: 49.4963756, lng: 18.7992945 },
    { lat: 49.4963026, lng: 18.7994147 },
    { lat: 49.496171, lng: 18.7998373 },
    { lat: 49.4962064, lng: 18.8000202 },
    { lat: 49.4960444, lng: 18.8003967 },
    { lat: 49.4958183, lng: 18.8006276 },
    { lat: 49.4956976, lng: 18.8012846 },
    { lat: 49.4953784, lng: 18.8016512 },
    { lat: 49.4951424, lng: 18.8018448 },
    { lat: 49.4950501, lng: 18.8021542 },
    { lat: 49.4949193, lng: 18.8022846 },
    { lat: 49.4948711, lng: 18.8024682 },
    { lat: 49.4947646, lng: 18.8025899 },
    { lat: 49.4946976, lng: 18.8026873 },
    { lat: 49.4945984, lng: 18.8026641 },
    { lat: 49.4945614, lng: 18.8028003 },
    { lat: 49.494398, lng: 18.8029347 },
    { lat: 49.4942381, lng: 18.8030687 },
    { lat: 49.4940711, lng: 18.8032684 },
    { lat: 49.4938575, lng: 18.8034325 },
    { lat: 49.4937326, lng: 18.8034567 },
    { lat: 49.4937078, lng: 18.8036773 },
    { lat: 49.4936321, lng: 18.8036071 },
    { lat: 49.4935927, lng: 18.8037406 },
    { lat: 49.4934756, lng: 18.8038125 },
    { lat: 49.493469, lng: 18.8039449 },
    { lat: 49.49336, lng: 18.8039948 },
    { lat: 49.4932969, lng: 18.8042149 },
    { lat: 49.4932113, lng: 18.8042812 },
    { lat: 49.4931924, lng: 18.8045537 },
    { lat: 49.4931345, lng: 18.8046771 },
    { lat: 49.4930092, lng: 18.8047572 },
    { lat: 49.4929677, lng: 18.8048982 },
    { lat: 49.4928381, lng: 18.8050046 },
    { lat: 49.4927334, lng: 18.805096 },
    { lat: 49.4926601, lng: 18.8050944 },
    { lat: 49.4926424, lng: 18.8051466 },
    { lat: 49.4925795, lng: 18.805131 },
    { lat: 49.4925289, lng: 18.8052412 },
    { lat: 49.492456, lng: 18.8052703 },
    { lat: 49.4923002, lng: 18.805246 },
    { lat: 49.492247, lng: 18.8053954 },
    { lat: 49.4921707, lng: 18.8055116 },
    { lat: 49.4920743, lng: 18.8056528 },
    { lat: 49.4920403, lng: 18.8057363 },
    { lat: 49.4918468, lng: 18.806076 },
    { lat: 49.4913901, lng: 18.8065354 },
    { lat: 49.4911895, lng: 18.8067326 },
    { lat: 49.4910257, lng: 18.8069248 },
    { lat: 49.4909301, lng: 18.8072809 },
    { lat: 49.4908449, lng: 18.8073793 },
    { lat: 49.4907049, lng: 18.8074412 },
    { lat: 49.4905321, lng: 18.8073476 },
    { lat: 49.4904186, lng: 18.807593 },
    { lat: 49.4903268, lng: 18.807738 },
    { lat: 49.4900585, lng: 18.8079137 },
    { lat: 49.4899041, lng: 18.8079814 },
    { lat: 49.4897675, lng: 18.8082195 },
    { lat: 49.4896086, lng: 18.8084186 },
    { lat: 49.4894718, lng: 18.8088965 },
    { lat: 49.4890878, lng: 18.8093655 },
    { lat: 49.4889867, lng: 18.8096131 },
    { lat: 49.4888802, lng: 18.8096934 },
    { lat: 49.4888316, lng: 18.8097769 },
    { lat: 49.4887776, lng: 18.8098669 },
    { lat: 49.4887006, lng: 18.8099908 },
    { lat: 49.4886692, lng: 18.8100367 },
    { lat: 49.4885859, lng: 18.8101497 },
    { lat: 49.4885647, lng: 18.8101784 },
    { lat: 49.4885457, lng: 18.8102043 },
    { lat: 49.4885248, lng: 18.8102329 },
    { lat: 49.4885046, lng: 18.81026 },
    { lat: 49.488491, lng: 18.8102794 },
    { lat: 49.4884435, lng: 18.8103443 },
    { lat: 49.4884161, lng: 18.8103813 },
    { lat: 49.4883918, lng: 18.8104137 },
    { lat: 49.4882907, lng: 18.810551 },
    { lat: 49.4878322, lng: 18.8111751 },
    { lat: 49.4875046, lng: 18.8116224 },
    { lat: 49.4874677, lng: 18.8116726 },
    { lat: 49.4867176, lng: 18.8126946 },
    { lat: 49.4866762, lng: 18.8127475 },
    { lat: 49.486634, lng: 18.8127993 },
    { lat: 49.4866158, lng: 18.8128226 },
    { lat: 49.4864431, lng: 18.8130585 },
    { lat: 49.4855641, lng: 18.8141414 },
    { lat: 49.4847626, lng: 18.8153958 },
    { lat: 49.4836353, lng: 18.8168945 },
    { lat: 49.4829233, lng: 18.8174527 },
    { lat: 49.482188, lng: 18.8178889 },
    { lat: 49.4818345, lng: 18.8188215 },
    { lat: 49.4817868, lng: 18.8189469 },
    { lat: 49.4810933, lng: 18.8203498 },
    { lat: 49.4809806, lng: 18.8207043 },
    { lat: 49.4809613, lng: 18.8207668 },
    { lat: 49.4803865, lng: 18.8225774 },
    { lat: 49.4802223, lng: 18.8236372 },
    { lat: 49.4801535, lng: 18.8240799 },
    { lat: 49.4801556, lng: 18.8242072 },
    { lat: 49.4800894, lng: 18.824456 },
    { lat: 49.4799995, lng: 18.8247711 },
    { lat: 49.4799516, lng: 18.824911 },
    { lat: 49.4799302, lng: 18.824977 },
    { lat: 49.4798957, lng: 18.8250965 },
    { lat: 49.4797748, lng: 18.8254675 },
    { lat: 49.4783669, lng: 18.8273662 },
    { lat: 49.4776134, lng: 18.8283697 },
    { lat: 49.4773664, lng: 18.8289401 },
    { lat: 49.4772755, lng: 18.8291486 },
    { lat: 49.4772474, lng: 18.8292137 },
    { lat: 49.4771504, lng: 18.8294367 },
    { lat: 49.4754378, lng: 18.833904 },
    { lat: 49.4745326, lng: 18.8350507 },
    { lat: 49.4744015, lng: 18.835246 },
    { lat: 49.4741384, lng: 18.8355253 },
    { lat: 49.4740133, lng: 18.8356571 },
    { lat: 49.4736701, lng: 18.8360225 },
    { lat: 49.4734619, lng: 18.836557 },
    { lat: 49.4733546, lng: 18.8375919 },
    { lat: 49.4731497, lng: 18.8381252 },
    { lat: 49.4728005, lng: 18.8390363 },
    { lat: 49.4725739, lng: 18.839409 },
    { lat: 49.4724602, lng: 18.8404516 },
    { lat: 49.4724048, lng: 18.8413519 },
    { lat: 49.4724094, lng: 18.8414184 },
    { lat: 49.4726366, lng: 18.8414313 },
    { lat: 49.4734023, lng: 18.8413254 },
    { lat: 49.473649, lng: 18.8410999 },
    { lat: 49.4737898, lng: 18.840438 },
    { lat: 49.4739244, lng: 18.840225 },
    { lat: 49.4743374, lng: 18.8403634 },
    { lat: 49.4744681, lng: 18.8405184 },
    { lat: 49.4745168, lng: 18.8404763 },
    { lat: 49.4746715, lng: 18.8405045 },
    { lat: 49.4754675, lng: 18.8407683 },
    { lat: 49.4760838, lng: 18.8411829 },
    { lat: 49.4765101, lng: 18.8417776 },
    { lat: 49.4770435, lng: 18.8425114 },
    { lat: 49.4772749, lng: 18.843134 },
    { lat: 49.4771357, lng: 18.8434749 },
    { lat: 49.4775591, lng: 18.8442405 },
    { lat: 49.4775514, lng: 18.8448846 },
    { lat: 49.4775721, lng: 18.8455122 },
    { lat: 49.4774418, lng: 18.8460696 },
    { lat: 49.4772342, lng: 18.8462697 },
    { lat: 49.4773854, lng: 18.8466028 },
    { lat: 49.4774579, lng: 18.8470509 },
    { lat: 49.4773314, lng: 18.8478634 },
    { lat: 49.4774314, lng: 18.8496649 },
    { lat: 49.4774916, lng: 18.851601 },
    { lat: 49.4768345, lng: 18.8517561 },
    { lat: 49.476623, lng: 18.8523203 },
    { lat: 49.4766983, lng: 18.852912 },
    { lat: 49.476492, lng: 18.8535314 },
    { lat: 49.476674, lng: 18.8535381 },
    { lat: 49.4768213, lng: 18.8536416 },
    { lat: 49.4772009, lng: 18.8556064 },
    { lat: 49.4776376, lng: 18.8571604 },
    { lat: 49.4778271, lng: 18.8577606 },
    { lat: 49.4778446, lng: 18.8578165 },
    { lat: 49.4784515, lng: 18.8596613 },
    { lat: 49.4789141, lng: 18.8609052 },
    { lat: 49.4794039, lng: 18.8619363 },
    { lat: 49.4791172, lng: 18.8622582 },
    { lat: 49.4789145, lng: 18.8624856 },
    { lat: 49.4792794, lng: 18.8632536 },
    { lat: 49.479638, lng: 18.8642526 },
    { lat: 49.4791988, lng: 18.8652349 },
    { lat: 49.4788888, lng: 18.8669439 },
    { lat: 49.4791548, lng: 18.8692466 },
    { lat: 49.4795537, lng: 18.8702847 },
    { lat: 49.4797098, lng: 18.8712921 },
    { lat: 49.4807453, lng: 18.8727674 },
    { lat: 49.4812346, lng: 18.8727915 },
    { lat: 49.4814746, lng: 18.8726801 },
    { lat: 49.4816819, lng: 18.8728315 },
    { lat: 49.4818623, lng: 18.8727399 },
    { lat: 49.4820618, lng: 18.8728552 },
    { lat: 49.4825755, lng: 18.8730233 },
    { lat: 49.4836647, lng: 18.8731144 },
    { lat: 49.4837066, lng: 18.8731177 },
    { lat: 49.4838504, lng: 18.8731295 },
    { lat: 49.4845033, lng: 18.8730905 },
    { lat: 49.484886, lng: 18.8729232 },
    { lat: 49.4852242, lng: 18.8728331 },
    { lat: 49.4859316, lng: 18.872645 },
    { lat: 49.4865228, lng: 18.8722109 },
    { lat: 49.486696, lng: 18.8717561 },
    { lat: 49.4872552, lng: 18.8713624 },
    { lat: 49.4880063, lng: 18.8708336 },
    { lat: 49.4884394, lng: 18.8706171 },
    { lat: 49.4885236, lng: 18.8706085 },
    { lat: 49.4893543, lng: 18.8704064 },
    { lat: 49.4901244, lng: 18.8702296 },
    { lat: 49.4907789, lng: 18.8700185 },
    { lat: 49.4911392, lng: 18.8699017 },
    { lat: 49.4917089, lng: 18.8699516 },
    { lat: 49.4921427, lng: 18.8699896 },
    { lat: 49.4922816, lng: 18.8699534 },
    { lat: 49.4931462, lng: 18.8697283 },
    { lat: 49.4933876, lng: 18.8697103 },
    { lat: 49.4934359, lng: 18.8697067 },
    { lat: 49.4938729, lng: 18.8696742 },
    { lat: 49.4951991, lng: 18.8695298 },
    { lat: 49.495642, lng: 18.8694162 },
    { lat: 49.4966329, lng: 18.8694382 },
    { lat: 49.4976826, lng: 18.8696396 },
    { lat: 49.4980731, lng: 18.8697146 },
    { lat: 49.4992151, lng: 18.8696688 },
    { lat: 49.4995233, lng: 18.8697636 },
    { lat: 49.5005112, lng: 18.8697725 },
    { lat: 49.50197, lng: 18.8699495 },
    { lat: 49.5029243, lng: 18.8700028 },
    { lat: 49.5041, lng: 18.8703146 },
    { lat: 49.5043, lng: 18.8703672 },
    { lat: 49.5053745, lng: 18.8700588 },
    { lat: 49.506075, lng: 18.8698229 },
    { lat: 49.5061812, lng: 18.8697361 },
    { lat: 49.506271, lng: 18.8696637 },
    { lat: 49.5063565, lng: 18.8695929 },
    { lat: 49.5071853, lng: 18.8693528 },
    { lat: 49.5072507, lng: 18.8693323 },
    { lat: 49.5074757, lng: 18.8692803 },
    { lat: 49.5078538, lng: 18.8691109 },
    { lat: 49.5083581, lng: 18.8688295 },
    { lat: 49.5086519, lng: 18.8686658 },
    { lat: 49.5088166, lng: 18.8685786 },
    { lat: 49.509023, lng: 18.8684704 },
    { lat: 49.5090602, lng: 18.8684489 },
    { lat: 49.5091428, lng: 18.8684089 },
    { lat: 49.5091855, lng: 18.8683853 },
    { lat: 49.5093648, lng: 18.868338 },
    { lat: 49.50957, lng: 18.8682953 },
    { lat: 49.5096044, lng: 18.8682836 },
    { lat: 49.5096602, lng: 18.8682751 },
    { lat: 49.5097023, lng: 18.868282 },
    { lat: 49.5097376, lng: 18.8682886 },
    { lat: 49.5097806, lng: 18.8682936 },
    { lat: 49.5099374, lng: 18.8683144 },
    { lat: 49.5100715, lng: 18.8683132 },
    { lat: 49.5102933, lng: 18.8682198 },
    { lat: 49.510343, lng: 18.868244 },
    { lat: 49.5104129, lng: 18.8682447 },
    { lat: 49.5104306, lng: 18.8682582 },
    { lat: 49.5106009, lng: 18.8683926 },
    { lat: 49.5106666, lng: 18.8684446 },
    { lat: 49.5110352, lng: 18.8687339 },
    { lat: 49.5112073, lng: 18.8687691 },
    { lat: 49.5116881, lng: 18.8688675 },
    { lat: 49.5122631, lng: 18.869202 },
    { lat: 49.5124035, lng: 18.8692831 },
    { lat: 49.5127894, lng: 18.8695071 },
    { lat: 49.5128103, lng: 18.8695192 },
    { lat: 49.5130564, lng: 18.8696624 },
    { lat: 49.5132651, lng: 18.8698402 },
    { lat: 49.5136155, lng: 18.8701719 },
    { lat: 49.5139107, lng: 18.8696768 },
    { lat: 49.5139657, lng: 18.8695846 },
    { lat: 49.5142502, lng: 18.8691064 },
    { lat: 49.5146495, lng: 18.8689083 },
    { lat: 49.5152466, lng: 18.8686121 },
    { lat: 49.5155784, lng: 18.868532 },
    { lat: 49.5158123, lng: 18.8684757 },
    { lat: 49.5165024, lng: 18.8681237 },
    { lat: 49.5171749, lng: 18.867849 },
    { lat: 49.5181413, lng: 18.8675415 },
    { lat: 49.5189578, lng: 18.867129 },
    { lat: 49.5191882, lng: 18.8670691 },
    { lat: 49.519215, lng: 18.8670626 },
    { lat: 49.5192294, lng: 18.8670583 },
    { lat: 49.5192678, lng: 18.8671713 },
    { lat: 49.519316, lng: 18.867342 },
    { lat: 49.519395, lng: 18.866964 },
    { lat: 49.519396, lng: 18.866823 },
    { lat: 49.519423, lng: 18.86677 },
    { lat: 49.519388, lng: 18.866542 },
    { lat: 49.519398, lng: 18.866323 },
    { lat: 49.519386, lng: 18.866161 },
    { lat: 49.519331, lng: 18.86599 },
    { lat: 49.519288, lng: 18.865928 },
    { lat: 49.519303, lng: 18.865816 },
    { lat: 49.519354, lng: 18.865701 },
    { lat: 49.519331, lng: 18.865577 },
    { lat: 49.519298, lng: 18.865508 },
    { lat: 49.519299, lng: 18.865394 },
    { lat: 49.519325, lng: 18.86526 },
    { lat: 49.519286, lng: 18.865183 },
    { lat: 49.519211, lng: 18.865135 },
    { lat: 49.519192, lng: 18.86502 },
    { lat: 49.519187, lng: 18.864956 },
    { lat: 49.519068, lng: 18.86486 },
    { lat: 49.519052, lng: 18.864744 },
    { lat: 49.518964, lng: 18.86448 },
    { lat: 49.518915, lng: 18.86435 },
    { lat: 49.518915, lng: 18.864201 },
    { lat: 49.518907, lng: 18.864108 },
    { lat: 49.518841, lng: 18.863874 },
    { lat: 49.518825, lng: 18.863876 },
    { lat: 49.518679, lng: 18.863997 },
    { lat: 49.518617, lng: 18.863758 },
    { lat: 49.518654, lng: 18.86357 },
    { lat: 49.518621, lng: 18.863504 },
    { lat: 49.518534, lng: 18.863297 },
    { lat: 49.518548, lng: 18.863105 },
    { lat: 49.518556, lng: 18.862892 },
    { lat: 49.518498, lng: 18.862738 },
    { lat: 49.518476, lng: 18.862462 },
    { lat: 49.518447, lng: 18.862239 },
    { lat: 49.518407, lng: 18.862024 },
    { lat: 49.518424, lng: 18.861831 },
    { lat: 49.518382, lng: 18.861715 },
    { lat: 49.518386, lng: 18.86153 },
    { lat: 49.518375, lng: 18.861349 },
    { lat: 49.518438, lng: 18.861316 },
    { lat: 49.518441, lng: 18.861251 },
    { lat: 49.518423, lng: 18.861064 },
    { lat: 49.518392, lng: 18.860907 },
    { lat: 49.518347, lng: 18.860753 },
    { lat: 49.518356, lng: 18.860656 },
    { lat: 49.518303, lng: 18.860476 },
    { lat: 49.518426, lng: 18.860396 },
    { lat: 49.518461, lng: 18.860333 },
    { lat: 49.518422, lng: 18.860138 },
    { lat: 49.518427, lng: 18.86002 },
    { lat: 49.518329, lng: 18.859911 },
    { lat: 49.518271, lng: 18.859749 },
    { lat: 49.518209, lng: 18.859391 },
    { lat: 49.518203, lng: 18.859103 },
    { lat: 49.518207, lng: 18.858935 },
    { lat: 49.518206, lng: 18.858889 },
    { lat: 49.518201, lng: 18.858858 },
    { lat: 49.518011, lng: 18.858457 },
    { lat: 49.517889, lng: 18.857171 },
    { lat: 49.517597, lng: 18.85602 },
    { lat: 49.517466, lng: 18.855333 },
    { lat: 49.517256, lng: 18.854458 },
    { lat: 49.516962, lng: 18.853412 },
    { lat: 49.517316, lng: 18.852571 },
    { lat: 49.517348, lng: 18.852314 },
    { lat: 49.517372, lng: 18.852226 },
    { lat: 49.517367, lng: 18.852101 },
    { lat: 49.517293, lng: 18.851997 },
    { lat: 49.517256, lng: 18.851829 },
    { lat: 49.517219, lng: 18.851479 },
    { lat: 49.517175, lng: 18.85108 },
    { lat: 49.5171489, lng: 18.8509071 },
    { lat: 49.5171365, lng: 18.850759 },
    { lat: 49.5169804, lng: 18.8500933 },
    { lat: 49.5169795, lng: 18.8498991 },
    { lat: 49.5169568, lng: 18.8496129 },
    { lat: 49.5169692, lng: 18.8492687 },
    { lat: 49.5169939, lng: 18.8490874 },
    { lat: 49.5169684, lng: 18.8488958 },
    { lat: 49.5170323, lng: 18.848381 },
    { lat: 49.5168806, lng: 18.8478937 },
    { lat: 49.5168473, lng: 18.8472808 },
    { lat: 49.5168187, lng: 18.8467925 },
    { lat: 49.5167417, lng: 18.8461343 },
    { lat: 49.5167699, lng: 18.8454371 },
    { lat: 49.516745, lng: 18.8451993 },
    { lat: 49.5168277, lng: 18.8448417 },
    { lat: 49.5167623, lng: 18.844735 },
    { lat: 49.5166147, lng: 18.8444289 },
    { lat: 49.5166821, lng: 18.8440231 },
    { lat: 49.516521, lng: 18.8435236 },
    { lat: 49.5164408, lng: 18.8428851 },
    { lat: 49.5164567, lng: 18.8428255 },
    { lat: 49.5164109, lng: 18.8426949 },
    { lat: 49.516368, lng: 18.8426268 },
    { lat: 49.516258, lng: 18.842497 },
    { lat: 49.516216, lng: 18.842395 },
    { lat: 49.516133, lng: 18.842206 },
    { lat: 49.516026, lng: 18.842059 },
    { lat: 49.515917, lng: 18.841971 },
    { lat: 49.515679, lng: 18.8419 },
    { lat: 49.515504, lng: 18.841834 },
    { lat: 49.515468, lng: 18.841799 },
    { lat: 49.5153146, lng: 18.8416766 },
    { lat: 49.515242, lng: 18.841576 },
    { lat: 49.515187, lng: 18.841476 },
    { lat: 49.515129, lng: 18.841321 },
    { lat: 49.515002, lng: 18.840876 },
    { lat: 49.514972, lng: 18.8407249 },
    { lat: 49.514949, lng: 18.840433 },
    { lat: 49.514919, lng: 18.840213 },
    { lat: 49.514913, lng: 18.840051 },
    { lat: 49.514918, lng: 18.840011 },
    { lat: 49.5149359, lng: 18.8394021 },
    { lat: 49.5150024, lng: 18.8387517 },
    { lat: 49.515183, lng: 18.837418 },
    { lat: 49.515387, lng: 18.835978 },
    { lat: 49.515428, lng: 18.835944 },
    { lat: 49.515502, lng: 18.835755 },
    { lat: 49.515518, lng: 18.835606 },
    { lat: 49.5155403, lng: 18.8354856 },
    { lat: 49.5155235, lng: 18.8352691 },
    { lat: 49.515562, lng: 18.834983 },
    { lat: 49.5155235, lng: 18.8348889 },
    { lat: 49.515532, lng: 18.834575 },
    { lat: 49.515512, lng: 18.834356 },
    { lat: 49.515512, lng: 18.834179 },
    { lat: 49.515544, lng: 18.834051 },
    { lat: 49.51556, lng: 18.833824 },
    { lat: 49.515519, lng: 18.833618 },
    { lat: 49.515572, lng: 18.833409 },
    { lat: 49.515609, lng: 18.832943 },
    { lat: 49.515734, lng: 18.832002 },
    { lat: 49.5158034, lng: 18.8309848 },
    { lat: 49.516006, lng: 18.829949 },
    { lat: 49.515994, lng: 18.829646 },
    { lat: 49.51598, lng: 18.829319 },
    { lat: 49.5159501, lng: 18.8288453 },
    { lat: 49.515892, lng: 18.827945 },
    { lat: 49.515892, lng: 18.82662 },
    { lat: 49.5159115, lng: 18.825904 },
    { lat: 49.515953, lng: 18.8248538 },
    { lat: 49.5160164, lng: 18.8236267 },
    { lat: 49.51604, lng: 18.822554 },
    { lat: 49.5161024, lng: 18.8220617 },
    { lat: 49.5161613, lng: 18.8215976 },
    { lat: 49.516228, lng: 18.820574 },
    { lat: 49.5162955, lng: 18.8193151 },
    { lat: 49.5162949, lng: 18.8192338 },
  ],
  Turzovka: [
    { lat: 49.365077, lng: 18.584603 },
    { lat: 49.365051, lng: 18.584599 },
    { lat: 49.364992, lng: 18.584591 },
    { lat: 49.364598, lng: 18.584835 },
    { lat: 49.364301, lng: 18.585068 },
    { lat: 49.364183, lng: 18.585104 },
    { lat: 49.364002, lng: 18.585158 },
    { lat: 49.363734, lng: 18.585317 },
    { lat: 49.363767, lng: 18.585612 },
    { lat: 49.363676, lng: 18.585684 },
    { lat: 49.363544, lng: 18.585592 },
    { lat: 49.363371, lng: 18.585633 },
    { lat: 49.363303, lng: 18.58572 },
    { lat: 49.363231, lng: 18.585813 },
    { lat: 49.363054, lng: 18.585808 },
    { lat: 49.363, lng: 18.585904 },
    { lat: 49.362932, lng: 18.586083 },
    { lat: 49.362857, lng: 18.586128 },
    { lat: 49.362726, lng: 18.586138 },
    { lat: 49.362428, lng: 18.586573 },
    { lat: 49.362299, lng: 18.586755 },
    { lat: 49.36219, lng: 18.587068 },
    { lat: 49.361884, lng: 18.587189 },
    { lat: 49.361879, lng: 18.587157 },
    { lat: 49.36187, lng: 18.587109 },
    { lat: 49.361774, lng: 18.587201 },
    { lat: 49.361645, lng: 18.587114 },
    { lat: 49.361555, lng: 18.587108 },
    { lat: 49.36145, lng: 18.586898 },
    { lat: 49.36141, lng: 18.586854 },
    { lat: 49.361181, lng: 18.586866 },
    { lat: 49.361068, lng: 18.586834 },
    { lat: 49.361006, lng: 18.586797 },
    { lat: 49.360904, lng: 18.586847 },
    { lat: 49.360819, lng: 18.586967 },
    { lat: 49.360816, lng: 18.586996 },
    { lat: 49.360812, lng: 18.587077 },
    { lat: 49.360804, lng: 18.587149 },
    { lat: 49.360751, lng: 18.587204 },
    { lat: 49.360679, lng: 18.587283 },
    { lat: 49.36051, lng: 18.587545 },
    { lat: 49.36039, lng: 18.587723 },
    { lat: 49.360266, lng: 18.588042 },
    { lat: 49.36024, lng: 18.588121 },
    { lat: 49.360118, lng: 18.58824 },
    { lat: 49.36013, lng: 18.588403 },
    { lat: 49.360121, lng: 18.58858 },
    { lat: 49.360059, lng: 18.588728 },
    { lat: 49.360045, lng: 18.589146 },
    { lat: 49.36005, lng: 18.589398 },
    { lat: 49.360031, lng: 18.589583 },
    { lat: 49.360056, lng: 18.589696 },
    { lat: 49.360041, lng: 18.589817 },
    { lat: 49.360037, lng: 18.590236 },
    { lat: 49.360057, lng: 18.590468 },
    { lat: 49.360185, lng: 18.591132 },
    { lat: 49.360271, lng: 18.591929 },
    { lat: 49.360237, lng: 18.592012 },
    { lat: 49.36024, lng: 18.592163 },
    { lat: 49.360264, lng: 18.592394 },
    { lat: 49.360247, lng: 18.592556 },
    { lat: 49.360187, lng: 18.592794 },
    { lat: 49.360092, lng: 18.59296 },
    { lat: 49.360063, lng: 18.593129 },
    { lat: 49.360007, lng: 18.593288 },
    { lat: 49.35995, lng: 18.593616 },
    { lat: 49.359928, lng: 18.593799 },
    { lat: 49.359803, lng: 18.594052 },
    { lat: 49.359766, lng: 18.594187 },
    { lat: 49.359753, lng: 18.594294 },
    { lat: 49.359611, lng: 18.594511 },
    { lat: 49.359527, lng: 18.594622 },
    { lat: 49.359528, lng: 18.594736 },
    { lat: 49.359428, lng: 18.594852 },
    { lat: 49.359376, lng: 18.594939 },
    { lat: 49.359128, lng: 18.59533 },
    { lat: 49.359144, lng: 18.595349 },
    { lat: 49.35918, lng: 18.5954 },
    { lat: 49.35932, lng: 18.595626 },
    { lat: 49.359572, lng: 18.595972 },
    { lat: 49.360012, lng: 18.596492 },
    { lat: 49.360207, lng: 18.596677 },
    { lat: 49.360487, lng: 18.596941 },
    { lat: 49.360813, lng: 18.597265 },
    { lat: 49.361048, lng: 18.597546 },
    { lat: 49.361351, lng: 18.597913 },
    { lat: 49.36171, lng: 18.598348 },
    { lat: 49.362001, lng: 18.598697 },
    { lat: 49.361985, lng: 18.598718 },
    { lat: 49.362005, lng: 18.598742 },
    { lat: 49.361245, lng: 18.599694 },
    { lat: 49.360434, lng: 18.600482 },
    { lat: 49.360386, lng: 18.600528 },
    { lat: 49.360276, lng: 18.600649 },
    { lat: 49.359705, lng: 18.601275 },
    { lat: 49.359332, lng: 18.60181 },
    { lat: 49.358787, lng: 18.602186 },
    { lat: 49.358568, lng: 18.602314 },
    { lat: 49.358206, lng: 18.602525 },
    { lat: 49.357808, lng: 18.602778 },
    { lat: 49.357521, lng: 18.602822 },
    { lat: 49.357029, lng: 18.602958 },
    { lat: 49.357016, lng: 18.602993 },
    { lat: 49.3567, lng: 18.602784 },
    { lat: 49.35602, lng: 18.602637 },
    { lat: 49.355796, lng: 18.602604 },
    { lat: 49.355627, lng: 18.602616 },
    { lat: 49.355284, lng: 18.602533 },
    { lat: 49.355189, lng: 18.602322 },
    { lat: 49.355065, lng: 18.602201 },
    { lat: 49.354876, lng: 18.602164 },
    { lat: 49.354405, lng: 18.602196 },
    { lat: 49.354029, lng: 18.602263 },
    { lat: 49.353891, lng: 18.60236 },
    { lat: 49.353775, lng: 18.602077 },
    { lat: 49.353329, lng: 18.60255 },
    { lat: 49.353265, lng: 18.602525 },
    { lat: 49.353097, lng: 18.602627 },
    { lat: 49.352828, lng: 18.602896 },
    { lat: 49.35198, lng: 18.603664 },
    { lat: 49.351958, lng: 18.60368 },
    { lat: 49.351946, lng: 18.603941 },
    { lat: 49.351849, lng: 18.604566 },
    { lat: 49.351834, lng: 18.604783 },
    { lat: 49.351837, lng: 18.605002 },
    { lat: 49.351893, lng: 18.605708 },
    { lat: 49.351881, lng: 18.606171 },
    { lat: 49.351793, lng: 18.607021 },
    { lat: 49.351748, lng: 18.607157 },
    { lat: 49.351492, lng: 18.607314 },
    { lat: 49.351335, lng: 18.607325 },
    { lat: 49.351128, lng: 18.607416 },
    { lat: 49.35103, lng: 18.607773 },
    { lat: 49.350997, lng: 18.608397 },
    { lat: 49.35101, lng: 18.608916 },
    { lat: 49.351245, lng: 18.609722 },
    { lat: 49.351448, lng: 18.61032 },
    { lat: 49.351537, lng: 18.610708 },
    { lat: 49.351544, lng: 18.611119 },
    { lat: 49.351589, lng: 18.611451 },
    { lat: 49.351568, lng: 18.61187 },
    { lat: 49.351431, lng: 18.612398 },
    { lat: 49.35126, lng: 18.613 },
    { lat: 49.351177, lng: 18.613527 },
    { lat: 49.351071, lng: 18.613585 },
    { lat: 49.350901, lng: 18.613828 },
    { lat: 49.350785, lng: 18.614065 },
    { lat: 49.350677, lng: 18.614413 },
    { lat: 49.350591, lng: 18.61505 },
    { lat: 49.350531, lng: 18.615772 },
    { lat: 49.350268, lng: 18.616334 },
    { lat: 49.350125, lng: 18.616924 },
    { lat: 49.349913, lng: 18.617303 },
    { lat: 49.349891, lng: 18.617594 },
    { lat: 49.349727, lng: 18.617823 },
    { lat: 49.349456, lng: 18.618434 },
    { lat: 49.349215, lng: 18.618874 },
    { lat: 49.34914, lng: 18.619933 },
    { lat: 49.349195, lng: 18.620333 },
    { lat: 49.349423, lng: 18.621268 },
    { lat: 49.349757, lng: 18.621572 },
    { lat: 49.3502, lng: 18.621661 },
    { lat: 49.350644, lng: 18.621828 },
    { lat: 49.351178, lng: 18.622169 },
    { lat: 49.351443, lng: 18.622722 },
    { lat: 49.351688, lng: 18.6232 },
    { lat: 49.351976, lng: 18.623518 },
    { lat: 49.352387, lng: 18.624175 },
    { lat: 49.352827, lng: 18.624854 },
    { lat: 49.353078, lng: 18.625851 },
    { lat: 49.353333, lng: 18.626563 },
    { lat: 49.353525, lng: 18.626978 },
    { lat: 49.353868, lng: 18.627489 },
    { lat: 49.35402, lng: 18.627651 },
    { lat: 49.354253, lng: 18.62784 },
    { lat: 49.354715, lng: 18.628034 },
    { lat: 49.355199, lng: 18.628159 },
    { lat: 49.355538, lng: 18.628462 },
    { lat: 49.356411, lng: 18.628889 },
    { lat: 49.356838, lng: 18.628965 },
    { lat: 49.357229, lng: 18.629168 },
    { lat: 49.358074, lng: 18.629158 },
    { lat: 49.358501, lng: 18.629051 },
    { lat: 49.359232, lng: 18.629001 },
    { lat: 49.359838, lng: 18.62934 },
    { lat: 49.360123, lng: 18.629477 },
    { lat: 49.36088, lng: 18.629661 },
    { lat: 49.361722, lng: 18.630153 },
    { lat: 49.362002, lng: 18.630397 },
    { lat: 49.36233, lng: 18.630565 },
    { lat: 49.362866, lng: 18.63097 },
    { lat: 49.363127, lng: 18.631279 },
    { lat: 49.363443, lng: 18.631961 },
    { lat: 49.363897, lng: 18.633266 },
    { lat: 49.3639081, lng: 18.6332507 },
    { lat: 49.3641324, lng: 18.6335239 },
    { lat: 49.3644037, lng: 18.6337688 },
    { lat: 49.3647324, lng: 18.6339522 },
    { lat: 49.3649741, lng: 18.6340843 },
    { lat: 49.3652693, lng: 18.6341442 },
    { lat: 49.3656626, lng: 18.6342039 },
    { lat: 49.3659402, lng: 18.634037 },
    { lat: 49.366343, lng: 18.6337419 },
    { lat: 49.3666139, lng: 18.6335566 },
    { lat: 49.3670062, lng: 18.6332756 },
    { lat: 49.3673356, lng: 18.6330034 },
    { lat: 49.3676384, lng: 18.6328851 },
    { lat: 49.3679944, lng: 18.6326469 },
    { lat: 49.3683779, lng: 18.6324982 },
    { lat: 49.3688042, lng: 18.6323526 },
    { lat: 49.369222, lng: 18.6319989 },
    { lat: 49.3695578, lng: 18.6318656 },
    { lat: 49.369875, lng: 18.6317657 },
    { lat: 49.3701453, lng: 18.631591 },
    { lat: 49.3706271, lng: 18.6315101 },
    { lat: 49.3714147, lng: 18.6311794 },
    { lat: 49.3719772, lng: 18.6309814 },
    { lat: 49.3725634, lng: 18.6307026 },
    { lat: 49.3728511, lng: 18.630813 },
    { lat: 49.3731437, lng: 18.6307143 },
    { lat: 49.3735179, lng: 18.6305219 },
    { lat: 49.3738188, lng: 18.6303448 },
    { lat: 49.3740108, lng: 18.6302895 },
    { lat: 49.3742645, lng: 18.6302474 },
    { lat: 49.3745641, lng: 18.6302156 },
    { lat: 49.3749134, lng: 18.6301497 },
    { lat: 49.3749927, lng: 18.6298803 },
    { lat: 49.3750935, lng: 18.6299482 },
    { lat: 49.3752325, lng: 18.6300608 },
    { lat: 49.3755792, lng: 18.6299409 },
    { lat: 49.3763809, lng: 18.6297927 },
    { lat: 49.3765368, lng: 18.6297957 },
    { lat: 49.377425, lng: 18.6301297 },
    { lat: 49.3775314, lng: 18.6302004 },
    { lat: 49.3778402, lng: 18.6303705 },
    { lat: 49.3782633, lng: 18.6303316 },
    { lat: 49.3786501, lng: 18.6307761 },
    { lat: 49.3789539, lng: 18.6309529 },
    { lat: 49.3792522, lng: 18.6311362 },
    { lat: 49.3795786, lng: 18.631231 },
    { lat: 49.3798225, lng: 18.6315586 },
    { lat: 49.3802029, lng: 18.631752 },
    { lat: 49.3806786, lng: 18.6318325 },
    { lat: 49.3807609, lng: 18.6319186 },
    { lat: 49.3811023, lng: 18.6322341 },
    { lat: 49.3812818, lng: 18.632299 },
    { lat: 49.3810296, lng: 18.6332247 },
    { lat: 49.3815528, lng: 18.6331372 },
    { lat: 49.3817401, lng: 18.6332239 },
    { lat: 49.3816208, lng: 18.6334842 },
    { lat: 49.3816224, lng: 18.6337058 },
    { lat: 49.3820948, lng: 18.6341296 },
    { lat: 49.3825849, lng: 18.6345037 },
    { lat: 49.3831511, lng: 18.6351008 },
    { lat: 49.3834711, lng: 18.6356503 },
    { lat: 49.3837073, lng: 18.6360013 },
    { lat: 49.3838621, lng: 18.6363046 },
    { lat: 49.3841932, lng: 18.6365961 },
    { lat: 49.3843949, lng: 18.6361421 },
    { lat: 49.3844128, lng: 18.6360972 },
    { lat: 49.3847873, lng: 18.6361628 },
    { lat: 49.385098, lng: 18.6361322 },
    { lat: 49.3854869, lng: 18.6359604 },
    { lat: 49.3855186, lng: 18.6359619 },
    { lat: 49.3860007, lng: 18.6358679 },
    { lat: 49.3863273, lng: 18.6358161 },
    { lat: 49.3868034, lng: 18.6357341 },
    { lat: 49.3871254, lng: 18.6356387 },
    { lat: 49.3873368, lng: 18.6355657 },
    { lat: 49.3873756, lng: 18.6355533 },
    { lat: 49.3876362, lng: 18.6354677 },
    { lat: 49.3876671, lng: 18.6354968 },
    { lat: 49.3877724, lng: 18.6356112 },
    { lat: 49.3879345, lng: 18.6357141 },
    { lat: 49.3881108, lng: 18.6358254 },
    { lat: 49.3885483, lng: 18.636104 },
    { lat: 49.3887177, lng: 18.6362368 },
    { lat: 49.3889443, lng: 18.6363835 },
    { lat: 49.3889692, lng: 18.6363869 },
    { lat: 49.3892787, lng: 18.636608 },
    { lat: 49.3892914, lng: 18.6366162 },
    { lat: 49.3893205, lng: 18.6366292 },
    { lat: 49.3896367, lng: 18.6368926 },
    { lat: 49.3899644, lng: 18.6371653 },
    { lat: 49.3900104, lng: 18.6372429 },
    { lat: 49.3900811, lng: 18.6373242 },
    { lat: 49.3901231, lng: 18.6373722 },
    { lat: 49.3906696, lng: 18.6376385 },
    { lat: 49.3906971, lng: 18.637752 },
    { lat: 49.3907581, lng: 18.6374801 },
    { lat: 49.3908139, lng: 18.6371919 },
    { lat: 49.3908791, lng: 18.6368877 },
    { lat: 49.3909516, lng: 18.6367472 },
    { lat: 49.3910382, lng: 18.6366789 },
    { lat: 49.3910827, lng: 18.6364127 },
    { lat: 49.391094, lng: 18.6362191 },
    { lat: 49.3910423, lng: 18.635882 },
    { lat: 49.390998, lng: 18.6356428 },
    { lat: 49.3910011, lng: 18.6354244 },
    { lat: 49.3910544, lng: 18.6351507 },
    { lat: 49.3912113, lng: 18.6349414 },
    { lat: 49.3916377, lng: 18.6347563 },
    { lat: 49.3918495, lng: 18.6345573 },
    { lat: 49.3919343, lng: 18.6340077 },
    { lat: 49.392075, lng: 18.6337509 },
    { lat: 49.3922221, lng: 18.6338419 },
    { lat: 49.3922923, lng: 18.6338915 },
    { lat: 49.3923757, lng: 18.633976 },
    { lat: 49.3924944, lng: 18.6341292 },
    { lat: 49.3926394, lng: 18.6342701 },
    { lat: 49.3929156, lng: 18.6344834 },
    { lat: 49.393314, lng: 18.6348263 },
    { lat: 49.3935298, lng: 18.6350061 },
    { lat: 49.3938713, lng: 18.6353637 },
    { lat: 49.3940391, lng: 18.6355956 },
    { lat: 49.3941299, lng: 18.6360966 },
    { lat: 49.3942452, lng: 18.6366315 },
    { lat: 49.3943951, lng: 18.6371009 },
    { lat: 49.3945823, lng: 18.6378882 },
    { lat: 49.3946497, lng: 18.6376698 },
    { lat: 49.3946796, lng: 18.6375293 },
    { lat: 49.3947047, lng: 18.6374106 },
    { lat: 49.3947094, lng: 18.6373286 },
    { lat: 49.3947437, lng: 18.6372092 },
    { lat: 49.3947751, lng: 18.6371648 },
    { lat: 49.3948607, lng: 18.6368388 },
    { lat: 49.3948986, lng: 18.6366635 },
    { lat: 49.3949529, lng: 18.6365196 },
    { lat: 49.3949866, lng: 18.6360889 },
    { lat: 49.3950308, lng: 18.6358571 },
    { lat: 49.3951602, lng: 18.6356817 },
    { lat: 49.395283, lng: 18.6354254 },
    { lat: 49.3953689, lng: 18.6352181 },
    { lat: 49.3954187, lng: 18.6350707 },
    { lat: 49.395476, lng: 18.634875 },
    { lat: 49.3954909, lng: 18.6348134 },
    { lat: 49.395498, lng: 18.6348237 },
    { lat: 49.3956331, lng: 18.6347106 },
    { lat: 49.3957107, lng: 18.6346857 },
    { lat: 49.3958369, lng: 18.6348333 },
    { lat: 49.3958921, lng: 18.634875 },
    { lat: 49.3960004, lng: 18.634876 },
    { lat: 49.3960662, lng: 18.6350532 },
    { lat: 49.3961995, lng: 18.6352484 },
    { lat: 49.3962153, lng: 18.6352711 },
    { lat: 49.3962165, lng: 18.635272 },
    { lat: 49.3963391, lng: 18.6354349 },
    { lat: 49.3964419, lng: 18.6357175 },
    { lat: 49.3965154, lng: 18.6359361 },
    { lat: 49.3967009, lng: 18.6363504 },
    { lat: 49.3968291, lng: 18.6366386 },
    { lat: 49.3968817, lng: 18.6368026 },
    { lat: 49.3969278, lng: 18.6370786 },
    { lat: 49.3970996, lng: 18.6371503 },
    { lat: 49.3972037, lng: 18.6373263 },
    { lat: 49.3973835, lng: 18.6375042 },
    { lat: 49.3976654, lng: 18.6378439 },
    { lat: 49.3977302, lng: 18.6379209 },
    { lat: 49.3977497, lng: 18.6379446 },
    { lat: 49.3977488, lng: 18.638079 },
    { lat: 49.3978185, lng: 18.6382452 },
    { lat: 49.3983984, lng: 18.638932 },
    { lat: 49.398407, lng: 18.6390099 },
    { lat: 49.3986478, lng: 18.639211 },
    { lat: 49.3985631, lng: 18.6393167 },
    { lat: 49.3985437, lng: 18.639464 },
    { lat: 49.3986274, lng: 18.6399326 },
    { lat: 49.3987118, lng: 18.6399326 },
    { lat: 49.3988009, lng: 18.6398263 },
    { lat: 49.3988684, lng: 18.6398197 },
    { lat: 49.3989015, lng: 18.6398696 },
    { lat: 49.3989436, lng: 18.6398571 },
    { lat: 49.3990291, lng: 18.6398964 },
    { lat: 49.3990882, lng: 18.6398605 },
    { lat: 49.3991432, lng: 18.6399224 },
    { lat: 49.3993057, lng: 18.6398588 },
    { lat: 49.3994138, lng: 18.6399196 },
    { lat: 49.399554, lng: 18.6398222 },
    { lat: 49.3997391, lng: 18.6398466 },
    { lat: 49.3999066, lng: 18.6398146 },
    { lat: 49.4000801, lng: 18.6397709 },
    { lat: 49.4001828, lng: 18.6397509 },
    { lat: 49.400363, lng: 18.6397879 },
    { lat: 49.4004521, lng: 18.6397442 },
    { lat: 49.4005433, lng: 18.639784 },
    { lat: 49.4006325, lng: 18.6398094 },
    { lat: 49.4007585, lng: 18.6397388 },
    { lat: 49.4009062, lng: 18.6396766 },
    { lat: 49.4010387, lng: 18.639705 },
    { lat: 49.4011712, lng: 18.6397403 },
    { lat: 49.4013945, lng: 18.6396105 },
    { lat: 49.401406, lng: 18.6395511 },
    { lat: 49.4015707, lng: 18.6395104 },
    { lat: 49.4017885, lng: 18.6395148 },
    { lat: 49.401764, lng: 18.6396899 },
    { lat: 49.4021131, lng: 18.6397008 },
    { lat: 49.4024424, lng: 18.6397428 },
    { lat: 49.4025378, lng: 18.6398222 },
    { lat: 49.4025819, lng: 18.6399098 },
    { lat: 49.4027076, lng: 18.6405336 },
    { lat: 49.4027619, lng: 18.6406051 },
    { lat: 49.4028826, lng: 18.6407338 },
    { lat: 49.4030275, lng: 18.6408046 },
    { lat: 49.403333, lng: 18.6409339 },
    { lat: 49.403345, lng: 18.6409234 },
    { lat: 49.4033538, lng: 18.6408136 },
    { lat: 49.4033834, lng: 18.6408201 },
    { lat: 49.4033831, lng: 18.6408136 },
    { lat: 49.4034123, lng: 18.6408125 },
    { lat: 49.4034366, lng: 18.6408026 },
    { lat: 49.4034617, lng: 18.640785 },
    { lat: 49.4034857, lng: 18.6407302 },
    { lat: 49.4035805, lng: 18.6408159 },
    { lat: 49.4036461, lng: 18.6408809 },
    { lat: 49.4038665, lng: 18.6409151 },
    { lat: 49.403512, lng: 18.6419053 },
    { lat: 49.4034774, lng: 18.6427877 },
    { lat: 49.4035974, lng: 18.6432033 },
    { lat: 49.4037824, lng: 18.6435022 },
    { lat: 49.4042901, lng: 18.6437187 },
    { lat: 49.4049311, lng: 18.6440244 },
    { lat: 49.4054649, lng: 18.6439266 },
    { lat: 49.4055138, lng: 18.6439582 },
    { lat: 49.4057177, lng: 18.6438681 },
    { lat: 49.4059371, lng: 18.6434936 },
    { lat: 49.4062191, lng: 18.6433716 },
    { lat: 49.4064532, lng: 18.6433379 },
    { lat: 49.406708, lng: 18.6435543 },
    { lat: 49.4068124, lng: 18.6434146 },
    { lat: 49.4068756, lng: 18.6433307 },
    { lat: 49.4070504, lng: 18.6430978 },
    { lat: 49.4070968, lng: 18.6430544 },
    { lat: 49.4072062, lng: 18.6429528 },
    { lat: 49.4072575, lng: 18.6429389 },
    { lat: 49.4072831, lng: 18.642932 },
    { lat: 49.4073398, lng: 18.6429174 },
    { lat: 49.4074152, lng: 18.6428917 },
    { lat: 49.4074869, lng: 18.6428003 },
    { lat: 49.4075077, lng: 18.6427854 },
    { lat: 49.4075088, lng: 18.6427842 },
    { lat: 49.4075331, lng: 18.6427576 },
    { lat: 49.4075711, lng: 18.6427062 },
    { lat: 49.4077013, lng: 18.6425245 },
    { lat: 49.4077392, lng: 18.6424713 },
    { lat: 49.4078316, lng: 18.6423432 },
    { lat: 49.4078413, lng: 18.6423333 },
    { lat: 49.4079867, lng: 18.642183 },
    { lat: 49.4080091, lng: 18.6421596 },
    { lat: 49.4081777, lng: 18.6421083 },
    { lat: 49.4082287, lng: 18.6420903 },
    { lat: 49.4082951, lng: 18.6420676 },
    { lat: 49.4083459, lng: 18.6420493 },
    { lat: 49.4084721, lng: 18.6420054 },
    { lat: 49.4088038, lng: 18.6419646 },
    { lat: 49.4088632, lng: 18.6419572 },
    { lat: 49.4088644, lng: 18.641957 },
    { lat: 49.4089436, lng: 18.6419617 },
    { lat: 49.4089847, lng: 18.6419707 },
    { lat: 49.409378, lng: 18.6420526 },
    { lat: 49.4097734, lng: 18.6422367 },
    { lat: 49.4102842, lng: 18.6421497 },
    { lat: 49.4109448, lng: 18.6419749 },
    { lat: 49.4111197, lng: 18.6418713 },
    { lat: 49.4113959, lng: 18.6418483 },
    { lat: 49.4118284, lng: 18.6418191 },
    { lat: 49.4120444, lng: 18.641721 },
    { lat: 49.4121048, lng: 18.6417156 },
    { lat: 49.4126144, lng: 18.6416686 },
    { lat: 49.4129379, lng: 18.6414314 },
    { lat: 49.4132891, lng: 18.6412889 },
    { lat: 49.4137687, lng: 18.6411043 },
    { lat: 49.4139266, lng: 18.6410434 },
    { lat: 49.4143365, lng: 18.6408186 },
    { lat: 49.4146521, lng: 18.6406458 },
    { lat: 49.4148019, lng: 18.6405967 },
    { lat: 49.4151331, lng: 18.6404886 },
    { lat: 49.4154281, lng: 18.6403918 },
    { lat: 49.4154731, lng: 18.6403703 },
    { lat: 49.4159713, lng: 18.640132 },
    { lat: 49.4162268, lng: 18.6400336 },
    { lat: 49.4164056, lng: 18.6399835 },
    { lat: 49.4169598, lng: 18.6396936 },
    { lat: 49.4170925, lng: 18.6396246 },
    { lat: 49.4184989, lng: 18.6397979 },
    { lat: 49.4198166, lng: 18.6399786 },
    { lat: 49.4205183, lng: 18.6392158 },
    { lat: 49.4212809, lng: 18.6395213 },
    { lat: 49.4223262, lng: 18.6399691 },
    { lat: 49.4226603, lng: 18.6401873 },
    { lat: 49.4228916, lng: 18.640339 },
    { lat: 49.423039, lng: 18.6403956 },
    { lat: 49.4231642, lng: 18.6403544 },
    { lat: 49.4234318, lng: 18.6402552 },
    { lat: 49.4237689, lng: 18.6399311 },
    { lat: 49.4242915, lng: 18.6391119 },
    { lat: 49.4247681, lng: 18.6384639 },
    { lat: 49.4250162, lng: 18.6378694 },
    { lat: 49.4256574, lng: 18.6375187 },
    { lat: 49.425901, lng: 18.6374957 },
    { lat: 49.4268883, lng: 18.6366795 },
    { lat: 49.4288342, lng: 18.6368381 },
    { lat: 49.4291531, lng: 18.6365144 },
    { lat: 49.4297623, lng: 18.636435 },
    { lat: 49.430504, lng: 18.6363844 },
    { lat: 49.430912, lng: 18.636021 },
    { lat: 49.4310819, lng: 18.6356901 },
    { lat: 49.4314956, lng: 18.6352655 },
    { lat: 49.4315204, lng: 18.6352417 },
    { lat: 49.4321996, lng: 18.6344804 },
    { lat: 49.4328024, lng: 18.6340467 },
    { lat: 49.433185, lng: 18.6339216 },
    { lat: 49.4336383, lng: 18.6337724 },
    { lat: 49.4336427, lng: 18.6337929 },
    { lat: 49.4339393, lng: 18.6337078 },
    { lat: 49.4342224, lng: 18.6337087 },
    { lat: 49.4345244, lng: 18.6335969 },
    { lat: 49.4345406, lng: 18.6335869 },
    { lat: 49.4347986, lng: 18.633433 },
    { lat: 49.435098, lng: 18.6333132 },
    { lat: 49.435506, lng: 18.6329493 },
    { lat: 49.4356902, lng: 18.6327416 },
    { lat: 49.4360153, lng: 18.6325145 },
    { lat: 49.4360871, lng: 18.6323385 },
    { lat: 49.4364222, lng: 18.631978 },
    { lat: 49.4364989, lng: 18.6317887 },
    { lat: 49.4366001, lng: 18.6317402 },
    { lat: 49.4368342, lng: 18.6315165 },
    { lat: 49.4370554, lng: 18.6312665 },
    { lat: 49.4372049, lng: 18.6310638 },
    { lat: 49.437293, lng: 18.6309839 },
    { lat: 49.4373858, lng: 18.6309231 },
    { lat: 49.4376101, lng: 18.6307952 },
    { lat: 49.4376868, lng: 18.6306907 },
    { lat: 49.4378249, lng: 18.630505 },
    { lat: 49.4379398, lng: 18.6303323 },
    { lat: 49.4380702, lng: 18.6301353 },
    { lat: 49.43815, lng: 18.6300887 },
    { lat: 49.4381815, lng: 18.6300859 },
    { lat: 49.4383611, lng: 18.630069 },
    { lat: 49.4383735, lng: 18.6300486 },
    { lat: 49.438539, lng: 18.6297689 },
    { lat: 49.438681, lng: 18.6295551 },
    { lat: 49.4389348, lng: 18.6292112 },
    { lat: 49.4393747, lng: 18.6287049 },
    { lat: 49.4395035, lng: 18.628541 },
    { lat: 49.4398541, lng: 18.6280262 },
    { lat: 49.4400888, lng: 18.627902 },
    { lat: 49.4402891, lng: 18.6278296 },
    { lat: 49.4406062, lng: 18.6277702 },
    { lat: 49.4408518, lng: 18.6277635 },
    { lat: 49.4409025, lng: 18.6277659 },
    { lat: 49.4409444, lng: 18.6277672 },
    { lat: 49.4411501, lng: 18.6277745 },
    { lat: 49.4412461, lng: 18.6277568 },
    { lat: 49.4414585, lng: 18.6277212 },
    { lat: 49.441764, lng: 18.6276353 },
    { lat: 49.4419883, lng: 18.6275059 },
    { lat: 49.4421992, lng: 18.6274416 },
    { lat: 49.4424781, lng: 18.6273398 },
    { lat: 49.4425836, lng: 18.627227 },
    { lat: 49.4427483, lng: 18.6272275 },
    { lat: 49.4428718, lng: 18.6271541 },
    { lat: 49.4429736, lng: 18.6270106 },
    { lat: 49.4432097, lng: 18.6262756 },
    { lat: 49.4433425, lng: 18.6259724 },
    { lat: 49.4434499, lng: 18.6255719 },
    { lat: 49.4436022, lng: 18.6253609 },
    { lat: 49.4437255, lng: 18.6252169 },
    { lat: 49.4442407, lng: 18.6250664 },
    { lat: 49.4443613, lng: 18.6250021 },
    { lat: 49.4445352, lng: 18.6249659 },
    { lat: 49.4449322, lng: 18.6246663 },
    { lat: 49.4449375, lng: 18.6246602 },
    { lat: 49.4451106, lng: 18.6245013 },
    { lat: 49.4454118, lng: 18.6245228 },
    { lat: 49.4457082, lng: 18.6241354 },
    { lat: 49.4458478, lng: 18.6240687 },
    { lat: 49.4460837, lng: 18.6240509 },
    { lat: 49.4463495, lng: 18.6239833 },
    { lat: 49.4469277, lng: 18.6239337 },
    { lat: 49.4471868, lng: 18.6239706 },
    { lat: 49.447512, lng: 18.6240845 },
    { lat: 49.4476726, lng: 18.6244787 },
    { lat: 49.4479428, lng: 18.6251949 },
    { lat: 49.448053, lng: 18.6254628 },
    { lat: 49.4481312, lng: 18.6257035 },
    { lat: 49.4481606, lng: 18.6258118 },
    { lat: 49.4482657, lng: 18.6259234 },
    { lat: 49.4486755, lng: 18.6262308 },
    { lat: 49.448934, lng: 18.6264044 },
    { lat: 49.4491284, lng: 18.6265407 },
    { lat: 49.4492635, lng: 18.6266214 },
    { lat: 49.4495055, lng: 18.6267199 },
    { lat: 49.4498095, lng: 18.6267752 },
    { lat: 49.4498117, lng: 18.6266875 },
    { lat: 49.4499612, lng: 18.6266134 },
    { lat: 49.4500965, lng: 18.6267647 },
    { lat: 49.4502213, lng: 18.6269069 },
    { lat: 49.4503453, lng: 18.6271154 },
    { lat: 49.4505477, lng: 18.6274255 },
    { lat: 49.450741, lng: 18.6276051 },
    { lat: 49.4511685, lng: 18.627778 },
    { lat: 49.4516615, lng: 18.6282925 },
    { lat: 49.4521741, lng: 18.6287705 },
    { lat: 49.4523921, lng: 18.6293755 },
    { lat: 49.4525441, lng: 18.6296273 },
    { lat: 49.4526487, lng: 18.6300267 },
    { lat: 49.4527968, lng: 18.6304806 },
    { lat: 49.4528915, lng: 18.6308421 },
    { lat: 49.4529619, lng: 18.6312114 },
    { lat: 49.4530445, lng: 18.6315404 },
    { lat: 49.4530428, lng: 18.6318241 },
    { lat: 49.4530551, lng: 18.6320792 },
    { lat: 49.4530856, lng: 18.6323345 },
    { lat: 49.4531212, lng: 18.6326893 },
    { lat: 49.4531292, lng: 18.6330098 },
    { lat: 49.4531601, lng: 18.633446 },
    { lat: 49.4531989, lng: 18.6337964 },
    { lat: 49.4533227, lng: 18.6344294 },
    { lat: 49.4535265, lng: 18.6348517 },
    { lat: 49.4537691, lng: 18.6353014 },
    { lat: 49.4540505, lng: 18.6355017 },
    { lat: 49.4542913, lng: 18.6357461 },
    { lat: 49.4543521, lng: 18.6363243 },
    { lat: 49.4544945, lng: 18.6364787 },
    { lat: 49.4545295, lng: 18.6364345 },
    { lat: 49.4545323, lng: 18.6363628 },
    { lat: 49.4545588, lng: 18.6357954 },
    { lat: 49.4546733, lng: 18.6352541 },
    { lat: 49.4548679, lng: 18.6349668 },
    { lat: 49.4549892, lng: 18.6347432 },
    { lat: 49.4551379, lng: 18.634508 },
    { lat: 49.4553979, lng: 18.6343052 },
    { lat: 49.4557073, lng: 18.6342308 },
    { lat: 49.4560347, lng: 18.634214 },
    { lat: 49.4562947, lng: 18.6341627 },
    { lat: 49.4565576, lng: 18.6339542 },
    { lat: 49.457198, lng: 18.6331426 },
    { lat: 49.4574046, lng: 18.6318441 },
    { lat: 49.4575578, lng: 18.6313099 },
    { lat: 49.4577167, lng: 18.630924 },
    { lat: 49.4578999, lng: 18.6304658 },
    { lat: 49.4579799, lng: 18.6303783 },
    { lat: 49.458011, lng: 18.6302838 },
    { lat: 49.458326, lng: 18.6295253 },
    { lat: 49.4583152, lng: 18.6290884 },
    { lat: 49.4583179, lng: 18.6288622 },
    { lat: 49.4583609, lng: 18.6286705 },
    { lat: 49.4584112, lng: 18.6285819 },
    { lat: 49.458527, lng: 18.6284421 },
    { lat: 49.4586237, lng: 18.6282919 },
    { lat: 49.4587861, lng: 18.628098 },
    { lat: 49.4588506, lng: 18.6280161 },
    { lat: 49.4590358, lng: 18.6278947 },
    { lat: 49.4590661, lng: 18.6278732 },
    { lat: 49.4590929, lng: 18.6278441 },
    { lat: 49.4593071, lng: 18.6276318 },
    { lat: 49.4594575, lng: 18.6276167 },
    { lat: 49.4596037, lng: 18.6268031 },
    { lat: 49.4600796, lng: 18.6260579 },
    { lat: 49.4601389, lng: 18.6259649 },
    { lat: 49.4604276, lng: 18.6251738 },
    { lat: 49.4606185, lng: 18.6248197 },
    { lat: 49.4606865, lng: 18.6245477 },
    { lat: 49.4608131, lng: 18.6240428 },
    { lat: 49.4608203, lng: 18.6240097 },
    { lat: 49.4609929, lng: 18.6241803 },
    { lat: 49.4611452, lng: 18.6243513 },
    { lat: 49.4612693, lng: 18.6247367 },
    { lat: 49.4613722, lng: 18.6255313 },
    { lat: 49.461664, lng: 18.624056 },
    { lat: 49.4618434, lng: 18.6228887 },
    { lat: 49.4619469, lng: 18.6223081 },
    { lat: 49.461879, lng: 18.6217716 },
    { lat: 49.461777, lng: 18.6213149 },
    { lat: 49.4614741, lng: 18.6215611 },
    { lat: 49.4612795, lng: 18.6206541 },
    { lat: 49.460973, lng: 18.6192313 },
    { lat: 49.4609225, lng: 18.6192373 },
    { lat: 49.4608834, lng: 18.6182876 },
    { lat: 49.4608688, lng: 18.6179493 },
    { lat: 49.4609738, lng: 18.6173138 },
    { lat: 49.4609435, lng: 18.6168713 },
    { lat: 49.4609283, lng: 18.6168797 },
    { lat: 49.460239, lng: 18.6173029 },
    { lat: 49.460225, lng: 18.6173141 },
    { lat: 49.4597771, lng: 18.617308 },
    { lat: 49.4592652, lng: 18.6171595 },
    { lat: 49.4578745, lng: 18.6174535 },
    { lat: 49.4578184, lng: 18.6174387 },
    { lat: 49.4570713, lng: 18.6174711 },
    { lat: 49.4569239, lng: 18.6174775 },
    { lat: 49.4566126, lng: 18.6176455 },
    { lat: 49.45621, lng: 18.6178367 },
    { lat: 49.4559084, lng: 18.6178711 },
    { lat: 49.4545793, lng: 18.6183691 },
    { lat: 49.4538344, lng: 18.6186748 },
    { lat: 49.4531495, lng: 18.6185617 },
    { lat: 49.4528145, lng: 18.6183896 },
    { lat: 49.4527805, lng: 18.6183449 },
    { lat: 49.452438, lng: 18.6179038 },
    { lat: 49.4523275, lng: 18.6178385 },
    { lat: 49.4522189, lng: 18.6178757 },
    { lat: 49.4522162, lng: 18.6178477 },
    { lat: 49.450499, lng: 18.6187036 },
    { lat: 49.4497697, lng: 18.6191524 },
    { lat: 49.449594, lng: 18.6191979 },
    { lat: 49.4493881, lng: 18.6192315 },
    { lat: 49.4490764, lng: 18.6192411 },
    { lat: 49.448869, lng: 18.619247 },
    { lat: 49.448764, lng: 18.6192855 },
    { lat: 49.4487246, lng: 18.6192868 },
    { lat: 49.4479657, lng: 18.6193728 },
    { lat: 49.4477685, lng: 18.6193988 },
    { lat: 49.4472172, lng: 18.6194615 },
    { lat: 49.4470148, lng: 18.6194719 },
    { lat: 49.446153, lng: 18.6195747 },
    { lat: 49.446134, lng: 18.6195767 },
    { lat: 49.4454501, lng: 18.6196529 },
    { lat: 49.4451991, lng: 18.6191298 },
    { lat: 49.4445737, lng: 18.6186515 },
    { lat: 49.4442195, lng: 18.6182238 },
    { lat: 49.4436883, lng: 18.6174595 },
    { lat: 49.4432841, lng: 18.616895 },
    { lat: 49.4432608, lng: 18.6168613 },
    { lat: 49.4430313, lng: 18.6166783 },
    { lat: 49.4427781, lng: 18.6166497 },
    { lat: 49.4424312, lng: 18.6165923 },
    { lat: 49.442078, lng: 18.6166291 },
    { lat: 49.4418188, lng: 18.6161048 },
    { lat: 49.4416412, lng: 18.6158392 },
    { lat: 49.4407593, lng: 18.6151978 },
    { lat: 49.4402244, lng: 18.614809 },
    { lat: 49.4396163, lng: 18.6144022 },
    { lat: 49.4401609, lng: 18.613337 },
    { lat: 49.4403475, lng: 18.6132412 },
    { lat: 49.4403327, lng: 18.6116678 },
    { lat: 49.4404128, lng: 18.6111178 },
    { lat: 49.4403783, lng: 18.6102549 },
    { lat: 49.439778, lng: 18.6087402 },
    { lat: 49.4394499, lng: 18.6082996 },
    { lat: 49.4394027, lng: 18.6082605 },
    { lat: 49.4391705, lng: 18.60807 },
    { lat: 49.438609, lng: 18.6064797 },
    { lat: 49.4381261, lng: 18.6065615 },
    { lat: 49.437943, lng: 18.6063626 },
    { lat: 49.4373571, lng: 18.6053745 },
    { lat: 49.4373395, lng: 18.6053622 },
    { lat: 49.4367136, lng: 18.6049165 },
    { lat: 49.4366933, lng: 18.6048951 },
    { lat: 49.4362335, lng: 18.6045402 },
    { lat: 49.4358651, lng: 18.6042961 },
    { lat: 49.4343821, lng: 18.6027249 },
    { lat: 49.4337209, lng: 18.6022949 },
    { lat: 49.4331197, lng: 18.6023121 },
    { lat: 49.4326996, lng: 18.6026596 },
    { lat: 49.4324997, lng: 18.6028237 },
    { lat: 49.4321271, lng: 18.6034345 },
    { lat: 49.4313226, lng: 18.6027043 },
    { lat: 49.4312988, lng: 18.6026776 },
    { lat: 49.4306984, lng: 18.6022258 },
    { lat: 49.4305166, lng: 18.6019674 },
    { lat: 49.4298715, lng: 18.5997217 },
    { lat: 49.4296927, lng: 18.5979304 },
    { lat: 49.4293601, lng: 18.5964289 },
    { lat: 49.429317, lng: 18.5963611 },
    { lat: 49.4293076, lng: 18.5958435 },
    { lat: 49.4288822, lng: 18.5948663 },
    { lat: 49.4286716, lng: 18.5938982 },
    { lat: 49.4286634, lng: 18.5924953 },
    { lat: 49.4286637, lng: 18.5924391 },
    { lat: 49.428654, lng: 18.5921273 },
    { lat: 49.4286316, lng: 18.5921333 },
    { lat: 49.4285228, lng: 18.590872 },
    { lat: 49.4285212, lng: 18.5908646 },
    { lat: 49.4285135, lng: 18.5907645 },
    { lat: 49.4284327, lng: 18.5902869 },
    { lat: 49.4282716, lng: 18.5900579 },
    { lat: 49.4281807, lng: 18.5900008 },
    { lat: 49.4257023, lng: 18.5851437 },
    { lat: 49.4246336, lng: 18.5832917 },
    { lat: 49.4244543, lng: 18.583461 },
    { lat: 49.4242374, lng: 18.5835806 },
    { lat: 49.4235326, lng: 18.5840037 },
    { lat: 49.4233029, lng: 18.5840749 },
    { lat: 49.4228592, lng: 18.5841298 },
    { lat: 49.4221855, lng: 18.5841223 },
    { lat: 49.4215338, lng: 18.5839954 },
    { lat: 49.420669, lng: 18.5840412 },
    { lat: 49.420217, lng: 18.5840479 },
    { lat: 49.4199786, lng: 18.5841626 },
    { lat: 49.4198462, lng: 18.5842198 },
    { lat: 49.4197346, lng: 18.5842225 },
    { lat: 49.4195659, lng: 18.5843564 },
    { lat: 49.4193293, lng: 18.5845726 },
    { lat: 49.4189519, lng: 18.5849184 },
    { lat: 49.4188469, lng: 18.5849783 },
    { lat: 49.4185857, lng: 18.5850469 },
    { lat: 49.4181934, lng: 18.58499 },
    { lat: 49.4182736, lng: 18.5859652 },
    { lat: 49.4182609, lng: 18.5867195 },
    { lat: 49.4177347, lng: 18.5885006 },
    { lat: 49.4176323, lng: 18.5888463 },
    { lat: 49.4167595, lng: 18.5899026 },
    { lat: 49.4160984, lng: 18.5907019 },
    { lat: 49.4157731, lng: 18.5912016 },
    { lat: 49.4157105, lng: 18.5912094 },
    { lat: 49.4149967, lng: 18.5916998 },
    { lat: 49.414676, lng: 18.5921547 },
    { lat: 49.4142637, lng: 18.5914874 },
    { lat: 49.4131253, lng: 18.5911816 },
    { lat: 49.4130502, lng: 18.5911066 },
    { lat: 49.4130266, lng: 18.5910845 },
    { lat: 49.4129811, lng: 18.5910381 },
    { lat: 49.4129598, lng: 18.5910147 },
    { lat: 49.4129041, lng: 18.590946 },
    { lat: 49.4128259, lng: 18.5908533 },
    { lat: 49.4127969, lng: 18.5908196 },
    { lat: 49.412721, lng: 18.5907259 },
    { lat: 49.4127246, lng: 18.5907522 },
    { lat: 49.4125991, lng: 18.5908715 },
    { lat: 49.4123367, lng: 18.5909859 },
    { lat: 49.4121513, lng: 18.5910413 },
    { lat: 49.4119836, lng: 18.5911525 },
    { lat: 49.4117677, lng: 18.5912484 },
    { lat: 49.4115777, lng: 18.5913454 },
    { lat: 49.4115295, lng: 18.5913901 },
    { lat: 49.4115032, lng: 18.5914293 },
    { lat: 49.4113991, lng: 18.5913989 },
    { lat: 49.4113082, lng: 18.5920618 },
    { lat: 49.4112528, lng: 18.5923788 },
    { lat: 49.4111468, lng: 18.5924431 },
    { lat: 49.4110113, lng: 18.5925242 },
    { lat: 49.4103939, lng: 18.592469 },
    { lat: 49.4101447, lng: 18.5924926 },
    { lat: 49.4098872, lng: 18.5924633 },
    { lat: 49.4096831, lng: 18.5924215 },
    { lat: 49.4093725, lng: 18.5925621 },
    { lat: 49.4090214, lng: 18.5925145 },
    { lat: 49.4088486, lng: 18.5926332 },
    { lat: 49.4086854, lng: 18.5927235 },
    { lat: 49.4085484, lng: 18.5927997 },
    { lat: 49.4084433, lng: 18.5928581 },
    { lat: 49.4080615, lng: 18.5927622 },
    { lat: 49.407962, lng: 18.5929264 },
    { lat: 49.4076954, lng: 18.5930014 },
    { lat: 49.4075428, lng: 18.593228 },
    { lat: 49.4070228, lng: 18.5931863 },
    { lat: 49.4068021, lng: 18.5931687 },
    { lat: 49.4063992, lng: 18.5928899 },
    { lat: 49.4061945, lng: 18.5927747 },
    { lat: 49.4059907, lng: 18.5925673 },
    { lat: 49.4054167, lng: 18.591744 },
    { lat: 49.4050499, lng: 18.5924349 },
    { lat: 49.4050336, lng: 18.5924666 },
    { lat: 49.4048333, lng: 18.5921578 },
    { lat: 49.4046815, lng: 18.5919335 },
    { lat: 49.4041338, lng: 18.591816 },
    { lat: 49.4039138, lng: 18.5916222 },
    { lat: 49.4041202, lng: 18.5914331 },
    { lat: 49.4039421, lng: 18.5914778 },
    { lat: 49.4038331, lng: 18.5916324 },
    { lat: 49.4037125, lng: 18.5916753 },
    { lat: 49.40316, lng: 18.5925437 },
    { lat: 49.4025499, lng: 18.5932861 },
    { lat: 49.4019665, lng: 18.5935528 },
    { lat: 49.4016442, lng: 18.5937257 },
    { lat: 49.40155, lng: 18.5939895 },
    { lat: 49.4011617, lng: 18.5944886 },
    { lat: 49.4005092, lng: 18.5959607 },
    { lat: 49.4004606, lng: 18.5960639 },
    { lat: 49.4006567, lng: 18.5942632 },
    { lat: 49.4006618, lng: 18.594209 },
    { lat: 49.4007323, lng: 18.5935636 },
    { lat: 49.4007429, lng: 18.5934685 },
    { lat: 49.401414, lng: 18.5918202 },
    { lat: 49.4021375, lng: 18.5887048 },
    { lat: 49.4021901, lng: 18.5884636 },
    { lat: 49.4022092, lng: 18.5884634 },
    { lat: 49.4023966, lng: 18.5880647 },
    { lat: 49.4028122, lng: 18.5874195 },
    { lat: 49.402497, lng: 18.5867123 },
    { lat: 49.4017031, lng: 18.5860376 },
    { lat: 49.4015946, lng: 18.5859708 },
    { lat: 49.4015731, lng: 18.5859666 },
    { lat: 49.4015318, lng: 18.5859316 },
    { lat: 49.4014785, lng: 18.586014 },
    { lat: 49.4014562, lng: 18.5861019 },
    { lat: 49.401443, lng: 18.5861278 },
    { lat: 49.4012287, lng: 18.5859378 },
    { lat: 49.4010047, lng: 18.5858424 },
    { lat: 49.4003181, lng: 18.5855073 },
    { lat: 49.3998769, lng: 18.5846769 },
    { lat: 49.3993313, lng: 18.5848724 },
    { lat: 49.3992884, lng: 18.5844049 },
    { lat: 49.3983551, lng: 18.5828107 },
    { lat: 49.3981081, lng: 18.5823054 },
    { lat: 49.3980993, lng: 18.5822869 },
    { lat: 49.3978214, lng: 18.5817747 },
    { lat: 49.3975107, lng: 18.5808541 },
    { lat: 49.3967761, lng: 18.5799593 },
    { lat: 49.3967603, lng: 18.5799605 },
    { lat: 49.3967376, lng: 18.5794916 },
    { lat: 49.3967565, lng: 18.5794874 },
    { lat: 49.3963018, lng: 18.5783242 },
    { lat: 49.3956338, lng: 18.5771361 },
    { lat: 49.3946604, lng: 18.5754031 },
    { lat: 49.3946369, lng: 18.5753615 },
    { lat: 49.3945766, lng: 18.5758615 },
    { lat: 49.3944869, lng: 18.5761848 },
    { lat: 49.3941957, lng: 18.5766255 },
    { lat: 49.3939838, lng: 18.5765843 },
    { lat: 49.3937191, lng: 18.5765263 },
    { lat: 49.3934854, lng: 18.5765434 },
    { lat: 49.3934493, lng: 18.5765457 },
    { lat: 49.3931274, lng: 18.5766589 },
    { lat: 49.3927394, lng: 18.5769951 },
    { lat: 49.392531, lng: 18.5770636 },
    { lat: 49.3923378, lng: 18.5770784 },
    { lat: 49.3920556, lng: 18.5769889 },
    { lat: 49.3914641, lng: 18.577171 },
    { lat: 49.3910189, lng: 18.5771965 },
    { lat: 49.3906427, lng: 18.5773302 },
    { lat: 49.3902108, lng: 18.5777751 },
    { lat: 49.3897922, lng: 18.5784445 },
    { lat: 49.3894623, lng: 18.5788562 },
    { lat: 49.3891524, lng: 18.5790316 },
    { lat: 49.3886249, lng: 18.579163 },
    { lat: 49.3884071, lng: 18.5794115 },
    { lat: 49.3880097, lng: 18.5795728 },
    { lat: 49.3877134, lng: 18.5796216 },
    { lat: 49.3872513, lng: 18.579753 },
    { lat: 49.3868721, lng: 18.5798086 },
    { lat: 49.3863458, lng: 18.5798999 },
    { lat: 49.3860498, lng: 18.5798053 },
    { lat: 49.3855128, lng: 18.5801843 },
    { lat: 49.3852127, lng: 18.580181 },
    { lat: 49.3841775, lng: 18.580434 },
    { lat: 49.3832479, lng: 18.5806001 },
    { lat: 49.382426, lng: 18.5806884 },
    { lat: 49.3823462, lng: 18.580694 },
    { lat: 49.3819575, lng: 18.5807214 },
    { lat: 49.381897, lng: 18.5807254 },
    { lat: 49.3817576, lng: 18.5807353 },
    { lat: 49.3816191, lng: 18.5807175 },
    { lat: 49.3811104, lng: 18.5806539 },
    { lat: 49.3810306, lng: 18.5806371 },
    { lat: 49.3810077, lng: 18.5806324 },
    { lat: 49.3807624, lng: 18.5805792 },
    { lat: 49.3805952, lng: 18.5805951 },
    { lat: 49.3805437, lng: 18.5806001 },
    { lat: 49.3804875, lng: 18.5806057 },
    { lat: 49.3803088, lng: 18.5805952 },
    { lat: 49.380208, lng: 18.5805662 },
    { lat: 49.3798878, lng: 18.5805057 },
    { lat: 49.3798606, lng: 18.5805012 },
    { lat: 49.3797403, lng: 18.5804464 },
    { lat: 49.3796497, lng: 18.580419 },
    { lat: 49.3796091, lng: 18.5804353 },
    { lat: 49.3795443, lng: 18.5804612 },
    { lat: 49.3795194, lng: 18.5804712 },
    { lat: 49.3794335, lng: 18.5805515 },
    { lat: 49.379424, lng: 18.5805816 },
    { lat: 49.3794053, lng: 18.5806354 },
    { lat: 49.3793481, lng: 18.5808112 },
    { lat: 49.3792955, lng: 18.5809235 },
    { lat: 49.379244, lng: 18.5809499 },
    { lat: 49.3791841, lng: 18.5809708 },
    { lat: 49.3790003, lng: 18.5812631 },
    { lat: 49.3789122, lng: 18.5812423 },
    { lat: 49.3788459, lng: 18.5812267 },
    { lat: 49.3788221, lng: 18.5811389 },
    { lat: 49.3787752, lng: 18.5811067 },
    { lat: 49.3787538, lng: 18.5811043 },
    { lat: 49.3787391, lng: 18.5811026 },
    { lat: 49.3787208, lng: 18.5811009 },
    { lat: 49.3786744, lng: 18.5810958 },
    { lat: 49.3785991, lng: 18.581123 },
    { lat: 49.3785548, lng: 18.5811387 },
    { lat: 49.3785345, lng: 18.5811565 },
    { lat: 49.3785073, lng: 18.5812619 },
    { lat: 49.3784632, lng: 18.5812161 },
    { lat: 49.378371, lng: 18.5812642 },
    { lat: 49.3782524, lng: 18.5813663 },
    { lat: 49.3781504, lng: 18.5813588 },
    { lat: 49.3780638, lng: 18.5812774 },
    { lat: 49.3778815, lng: 18.5812626 },
    { lat: 49.3777726, lng: 18.5811886 },
    { lat: 49.3776009, lng: 18.5811414 },
    { lat: 49.3774375, lng: 18.5811438 },
    { lat: 49.377309, lng: 18.5811454 },
    { lat: 49.3771348, lng: 18.580902 },
    { lat: 49.3769738, lng: 18.5808241 },
    { lat: 49.3768597, lng: 18.5806321 },
    { lat: 49.3766387, lng: 18.5802099 },
    { lat: 49.3762306, lng: 18.5796187 },
    { lat: 49.3761671, lng: 18.579509 },
    { lat: 49.3760078, lng: 18.579377 },
    { lat: 49.3759436, lng: 18.5793774 },
    { lat: 49.3754282, lng: 18.5793777 },
    { lat: 49.3750834, lng: 18.5789841 },
    { lat: 49.3747006, lng: 18.5786597 },
    { lat: 49.3743799, lng: 18.5785428 },
    { lat: 49.374011, lng: 18.5782246 },
    { lat: 49.3741676, lng: 18.5787102 },
    { lat: 49.3741933, lng: 18.5791239 },
    { lat: 49.374246, lng: 18.5795845 },
    { lat: 49.3743369, lng: 18.5801215 },
    { lat: 49.3743501, lng: 18.5806869 },
    { lat: 49.3743896, lng: 18.5812979 },
    { lat: 49.3745827, lng: 18.581767 },
    { lat: 49.3746103, lng: 18.582305 },
    { lat: 49.3746662, lng: 18.5827178 },
    { lat: 49.3745481, lng: 18.583506 },
    { lat: 49.3745443, lng: 18.584045 },
    { lat: 49.374677, lng: 18.5848615 },
    { lat: 49.3747073, lng: 18.5850495 },
    { lat: 49.3747132, lng: 18.5850756 },
    { lat: 49.3748079, lng: 18.5855613 },
    { lat: 49.3748732, lng: 18.58615 },
    { lat: 49.3748353, lng: 18.5867508 },
    { lat: 49.3749434, lng: 18.5869153 },
    { lat: 49.3749778, lng: 18.5869667 },
    { lat: 49.3750283, lng: 18.5870447 },
    { lat: 49.3751032, lng: 18.5873285 },
    { lat: 49.3751082, lng: 18.5875501 },
    { lat: 49.3752341, lng: 18.5880721 },
    { lat: 49.374921, lng: 18.5881197 },
    { lat: 49.3746005, lng: 18.5880755 },
    { lat: 49.3741708, lng: 18.5879455 },
    { lat: 49.3741727, lng: 18.5878801 },
    { lat: 49.3736003, lng: 18.5877375 },
    { lat: 49.3732748, lng: 18.5875771 },
    { lat: 49.3731044, lng: 18.5874927 },
    { lat: 49.3723297, lng: 18.5871099 },
    { lat: 49.3719092, lng: 18.5868839 },
    { lat: 49.3717282, lng: 18.5867861 },
    { lat: 49.3712889, lng: 18.5864379 },
    { lat: 49.371258, lng: 18.5864114 },
    { lat: 49.3707494, lng: 18.5859855 },
    { lat: 49.3707221, lng: 18.5859621 },
    { lat: 49.3698766, lng: 18.5852911 },
    { lat: 49.3690406, lng: 18.5847785 },
    { lat: 49.3684391, lng: 18.5845166 },
    { lat: 49.3684232, lng: 18.5846445 },
    { lat: 49.3683852, lng: 18.5849684 },
    { lat: 49.3683543, lng: 18.5852336 },
    { lat: 49.3682338, lng: 18.585426 },
    { lat: 49.3679551, lng: 18.5858658 },
    { lat: 49.3678661, lng: 18.5857829 },
    { lat: 49.3678191, lng: 18.5855814 },
    { lat: 49.3677493, lng: 18.5855399 },
    { lat: 49.3676088, lng: 18.5856736 },
    { lat: 49.3674947, lng: 18.5855634 },
    { lat: 49.3675417, lng: 18.5854302 },
    { lat: 49.3674622, lng: 18.5852961 },
    { lat: 49.3673812, lng: 18.5852748 },
    { lat: 49.3673503, lng: 18.5854364 },
    { lat: 49.3671203, lng: 18.58531 },
    { lat: 49.3669031, lng: 18.5853415 },
    { lat: 49.3668406, lng: 18.5852285 },
    { lat: 49.3667843, lng: 18.5852095 },
    { lat: 49.3666282, lng: 18.5853058 },
    { lat: 49.3666135, lng: 18.5850518 },
    { lat: 49.3665217, lng: 18.584979 },
    { lat: 49.3664846, lng: 18.5851081 },
    { lat: 49.3664301, lng: 18.5850165 },
    { lat: 49.3664271, lng: 18.5849594 },
    { lat: 49.3665471, lng: 18.5848224 },
    { lat: 49.3665882, lng: 18.5846631 },
    { lat: 49.3665475, lng: 18.5845766 },
    { lat: 49.3664374, lng: 18.5845597 },
    { lat: 49.3662834, lng: 18.584698 },
    { lat: 49.3661687, lng: 18.5847453 },
    { lat: 49.3661079, lng: 18.5846654 },
    { lat: 49.365896, lng: 18.58471 },
    { lat: 49.3657437, lng: 18.5848546 },
    { lat: 49.3656221, lng: 18.5848793 },
    { lat: 49.3655107, lng: 18.5848411 },
    { lat: 49.3653182, lng: 18.5848924 },
    { lat: 49.3652623, lng: 18.5848144 },
    { lat: 49.3652362, lng: 18.584835 },
    { lat: 49.3651878, lng: 18.5848139 },
    { lat: 49.365077, lng: 18.584603 },
  ],
  Olešná: [
    { lat: 49.4640719, lng: 18.6462964 },
    { lat: 49.4641022, lng: 18.6462927 },
    { lat: 49.4642605, lng: 18.6462599 },
    { lat: 49.4644929, lng: 18.6461332 },
    { lat: 49.4644752, lng: 18.6461821 },
    { lat: 49.464488, lng: 18.6461889 },
    { lat: 49.4645846, lng: 18.64612 },
    { lat: 49.4645942, lng: 18.6461278 },
    { lat: 49.4646081, lng: 18.6460946 },
    { lat: 49.4647422, lng: 18.6460706 },
    { lat: 49.4648801, lng: 18.6461188 },
    { lat: 49.4649796, lng: 18.6460828 },
    { lat: 49.4650462, lng: 18.6460586 },
    { lat: 49.4651297, lng: 18.6459761 },
    { lat: 49.46521, lng: 18.6457703 },
    { lat: 49.4653101, lng: 18.6455929 },
    { lat: 49.4654693, lng: 18.6455517 },
    { lat: 49.4656468, lng: 18.6455833 },
    { lat: 49.4657828, lng: 18.6454479 },
    { lat: 49.4658743, lng: 18.6453419 },
    { lat: 49.4659656, lng: 18.645299 },
    { lat: 49.4661012, lng: 18.6453688 },
    { lat: 49.4662181, lng: 18.6453405 },
    { lat: 49.4663333, lng: 18.6452805 },
    { lat: 49.4664584, lng: 18.645171 },
    { lat: 49.4666172, lng: 18.6450403 },
    { lat: 49.4667261, lng: 18.6449865 },
    { lat: 49.466849, lng: 18.6449861 },
    { lat: 49.4669792, lng: 18.6450174 },
    { lat: 49.4670683, lng: 18.6449966 },
    { lat: 49.4675952, lng: 18.644959 },
    { lat: 49.4676835, lng: 18.6450122 },
    { lat: 49.4677978, lng: 18.6449113 },
    { lat: 49.4679394, lng: 18.6447748 },
    { lat: 49.4679693, lng: 18.6446152 },
    { lat: 49.4681009, lng: 18.6446289 },
    { lat: 49.4681913, lng: 18.6445473 },
    { lat: 49.4683544, lng: 18.6448385 },
    { lat: 49.4685354, lng: 18.6450615 },
    { lat: 49.4691211, lng: 18.6453291 },
    { lat: 49.4692185, lng: 18.6453573 },
    { lat: 49.4695205, lng: 18.6453291 },
    { lat: 49.469705, lng: 18.6453251 },
    { lat: 49.4699692, lng: 18.6453916 },
    { lat: 49.4700255, lng: 18.6454785 },
    { lat: 49.4700809, lng: 18.6456105 },
    { lat: 49.470178, lng: 18.6457177 },
    { lat: 49.4702993, lng: 18.6457715 },
    { lat: 49.4704654, lng: 18.6457353 },
    { lat: 49.4706809, lng: 18.6457175 },
    { lat: 49.4707966, lng: 18.6456847 },
    { lat: 49.4708602, lng: 18.6457398 },
    { lat: 49.4707218, lng: 18.6458299 },
    { lat: 49.4707015, lng: 18.6458702 },
    { lat: 49.4707537, lng: 18.6458789 },
    { lat: 49.470876, lng: 18.645908 },
    { lat: 49.4711415, lng: 18.6460062 },
    { lat: 49.4714733, lng: 18.6461579 },
    { lat: 49.4716602, lng: 18.6462394 },
    { lat: 49.4717518, lng: 18.6462861 },
    { lat: 49.4719388, lng: 18.6464086 },
    { lat: 49.4721055, lng: 18.6465579 },
    { lat: 49.4722122, lng: 18.6466998 },
    { lat: 49.4722988, lng: 18.6468631 },
    { lat: 49.4724222, lng: 18.6470251 },
    { lat: 49.4725444, lng: 18.6470973 },
    { lat: 49.4727516, lng: 18.6471771 },
    { lat: 49.4729321, lng: 18.6472388 },
    { lat: 49.472981, lng: 18.6475039 },
    { lat: 49.4730375, lng: 18.6475926 },
    { lat: 49.4731358, lng: 18.647616 },
    { lat: 49.4732127, lng: 18.6476231 },
    { lat: 49.4732729, lng: 18.6476301 },
    { lat: 49.4733388, lng: 18.6476611 },
    { lat: 49.4734752, lng: 18.647766 },
    { lat: 49.4737313, lng: 18.6477686 },
    { lat: 49.47385, lng: 18.6478369 },
    { lat: 49.4739474, lng: 18.6478476 },
    { lat: 49.4740861, lng: 18.6477836 },
    { lat: 49.4741995, lng: 18.6478594 },
    { lat: 49.4743231, lng: 18.647744 },
    { lat: 49.4744249, lng: 18.6477527 },
    { lat: 49.4744883, lng: 18.6476901 },
    { lat: 49.4745965, lng: 18.6476266 },
    { lat: 49.4747012, lng: 18.6475556 },
    { lat: 49.4747966, lng: 18.6475499 },
    { lat: 49.474892, lng: 18.6475623 },
    { lat: 49.475089, lng: 18.6474487 },
    { lat: 49.4751402, lng: 18.6473948 },
    { lat: 49.4752087, lng: 18.6472822 },
    { lat: 49.4752978, lng: 18.6471123 },
    { lat: 49.4754022, lng: 18.646955 },
    { lat: 49.4754736, lng: 18.6468526 },
    { lat: 49.4756151, lng: 18.6467755 },
    { lat: 49.4757131, lng: 18.6467513 },
    { lat: 49.4758542, lng: 18.646757 },
    { lat: 49.4759394, lng: 18.6468124 },
    { lat: 49.4760662, lng: 18.6468645 },
    { lat: 49.4761714, lng: 18.6468043 },
    { lat: 49.4762509, lng: 18.6467516 },
    { lat: 49.476352, lng: 18.6468308 },
    { lat: 49.476467, lng: 18.6468756 },
    { lat: 49.4765789, lng: 18.6469621 },
    { lat: 49.4766973, lng: 18.6470247 },
    { lat: 49.4767642, lng: 18.6471429 },
    { lat: 49.476837, lng: 18.6471955 },
    { lat: 49.4769188, lng: 18.6472912 },
    { lat: 49.477067, lng: 18.6473863 },
    { lat: 49.4771873, lng: 18.6474849 },
    { lat: 49.4772967, lng: 18.6475079 },
    { lat: 49.4774213, lng: 18.6476476 },
    { lat: 49.4775256, lng: 18.6477663 },
    { lat: 49.4776093, lng: 18.647856 },
    { lat: 49.4777101, lng: 18.6479254 },
    { lat: 49.4778005, lng: 18.6479947 },
    { lat: 49.4779051, lng: 18.6480963 },
    { lat: 49.4779999, lng: 18.6481839 },
    { lat: 49.478069, lng: 18.648296 },
    { lat: 49.4781543, lng: 18.6484167 },
    { lat: 49.4781933, lng: 18.6486008 },
    { lat: 49.4782241, lng: 18.6487152 },
    { lat: 49.4782776, lng: 18.6488507 },
    { lat: 49.4783394, lng: 18.6489747 },
    { lat: 49.4783724, lng: 18.6490888 },
    { lat: 49.4784308, lng: 18.6491936 },
    { lat: 49.4784391, lng: 18.6492832 },
    { lat: 49.4784863, lng: 18.6493669 },
    { lat: 49.4784524, lng: 18.649687 },
    { lat: 49.4785517, lng: 18.6498132 },
    { lat: 49.4786977, lng: 18.6498669 },
    { lat: 49.4788391, lng: 18.6499635 },
    { lat: 49.4789862, lng: 18.6501219 },
    { lat: 49.4790658, lng: 18.6501566 },
    { lat: 49.4792363, lng: 18.6502043 },
    { lat: 49.4793126, lng: 18.6501945 },
    { lat: 49.4794363, lng: 18.6502324 },
    { lat: 49.4794839, lng: 18.6502566 },
    { lat: 49.479545, lng: 18.650282 },
    { lat: 49.4795895, lng: 18.6503149 },
    { lat: 49.4796785, lng: 18.6504384 },
    { lat: 49.4797691, lng: 18.6505748 },
    { lat: 49.4798096, lng: 18.6507652 },
    { lat: 49.4798439, lng: 18.6509274 },
    { lat: 49.4798501, lng: 18.6511475 },
    { lat: 49.4798396, lng: 18.6514425 },
    { lat: 49.47981, lng: 18.651674 },
    { lat: 49.4798256, lng: 18.6520104 },
    { lat: 49.4798779, lng: 18.6521453 },
    { lat: 49.479966, lng: 18.652257 },
    { lat: 49.4800228, lng: 18.6524153 },
    { lat: 49.4801083, lng: 18.6525182 },
    { lat: 49.4802049, lng: 18.6525747 },
    { lat: 49.4803835, lng: 18.6526059 },
    { lat: 49.4804968, lng: 18.6526353 },
    { lat: 49.4806289, lng: 18.6525946 },
    { lat: 49.480733, lng: 18.652623 },
    { lat: 49.4808544, lng: 18.6526192 },
    { lat: 49.4809777, lng: 18.6526271 },
    { lat: 49.4811103, lng: 18.6526585 },
    { lat: 49.4812291, lng: 18.6526673 },
    { lat: 49.4812982, lng: 18.6526101 },
    { lat: 49.4814091, lng: 18.6526152 },
    { lat: 49.4815125, lng: 18.6525657 },
    { lat: 49.4816041, lng: 18.6525953 },
    { lat: 49.4817391, lng: 18.6525866 },
    { lat: 49.4818229, lng: 18.6525722 },
    { lat: 49.4819272, lng: 18.6526909 },
    { lat: 49.482005, lng: 18.6527773 },
    { lat: 49.4820525, lng: 18.6527993 },
    { lat: 49.4822401, lng: 18.652763 },
    { lat: 49.4823868, lng: 18.6528296 },
    { lat: 49.4824817, lng: 18.6528552 },
    { lat: 49.4825986, lng: 18.6529749 },
    { lat: 49.482761, lng: 18.6529975 },
    { lat: 49.4829456, lng: 18.6530134 },
    { lat: 49.483095, lng: 18.6531073 },
    { lat: 49.4832642, lng: 18.6531526 },
    { lat: 49.4833668, lng: 18.6532385 },
    { lat: 49.4834185, lng: 18.6533188 },
    { lat: 49.4836063, lng: 18.6533767 },
    { lat: 49.4837448, lng: 18.6534796 },
    { lat: 49.4838333, lng: 18.6535527 },
    { lat: 49.483884, lng: 18.6536998 },
    { lat: 49.4839698, lng: 18.6539148 },
    { lat: 49.4840138, lng: 18.6540689 },
    { lat: 49.4840732, lng: 18.6541866 },
    { lat: 49.4841902, lng: 18.654439 },
    { lat: 49.4843002, lng: 18.6546419 },
    { lat: 49.484367, lng: 18.6548654 },
    { lat: 49.4843515, lng: 18.6551277 },
    { lat: 49.4843824, lng: 18.6552838 },
    { lat: 49.4844129, lng: 18.655436 },
    { lat: 49.4844711, lng: 18.6555764 },
    { lat: 49.4845376, lng: 18.6557031 },
    { lat: 49.4846296, lng: 18.6558443 },
    { lat: 49.4847622, lng: 18.6559443 },
    { lat: 49.4848734, lng: 18.65604 },
    { lat: 49.4849177, lng: 18.656218 },
    { lat: 49.4849638, lng: 18.6564111 },
    { lat: 49.485028, lng: 18.6565565 },
    { lat: 49.4850927, lng: 18.6567186 },
    { lat: 49.4851475, lng: 18.6568797 },
    { lat: 49.4852522, lng: 18.6570223 },
    { lat: 49.4854101, lng: 18.6570691 },
    { lat: 49.485555, lng: 18.6570562 },
    { lat: 49.4857098, lng: 18.6570834 },
    { lat: 49.4858331, lng: 18.6571153 },
    { lat: 49.4859502, lng: 18.6571526 },
    { lat: 49.4860377, lng: 18.6571232 },
    { lat: 49.4861454, lng: 18.6571124 },
    { lat: 49.4863411, lng: 18.6571451 },
    { lat: 49.4864746, lng: 18.6571314 },
    { lat: 49.486596, lng: 18.6571482 },
    { lat: 49.4867, lng: 18.6571531 },
    { lat: 49.4868745, lng: 18.6572326 },
    { lat: 49.4869378, lng: 18.6572135 },
    { lat: 49.4869904, lng: 18.6571853 },
    { lat: 49.4870756, lng: 18.6571333 },
    { lat: 49.4872725, lng: 18.6570802 },
    { lat: 49.4873631, lng: 18.6570686 },
    { lat: 49.4874239, lng: 18.6570212 },
    { lat: 49.4874647, lng: 18.6569849 },
    { lat: 49.4875309, lng: 18.6569346 },
    { lat: 49.4875949, lng: 18.6569303 },
    { lat: 49.4876976, lng: 18.6569509 },
    { lat: 49.4878315, lng: 18.6569673 },
    { lat: 49.4879229, lng: 18.656948 },
    { lat: 49.4880601, lng: 18.6569194 },
    { lat: 49.4881944, lng: 18.6568588 },
    { lat: 49.4883001, lng: 18.6568294 },
    { lat: 49.4884058, lng: 18.6568268 },
    { lat: 49.4885692, lng: 18.6567608 },
    { lat: 49.488689, lng: 18.6566803 },
    { lat: 49.4888142, lng: 18.6566353 },
    { lat: 49.4889557, lng: 18.6565844 },
    { lat: 49.4890674, lng: 18.6565205 },
    { lat: 49.4891879, lng: 18.6563913 },
    { lat: 49.4892514, lng: 18.6564168 },
    { lat: 49.4893352, lng: 18.6564872 },
    { lat: 49.4894314, lng: 18.6565366 },
    { lat: 49.4894772, lng: 18.6565715 },
    { lat: 49.4895795, lng: 18.6565882 },
    { lat: 49.4897475, lng: 18.6566925 },
    { lat: 49.4898886, lng: 18.6568056 },
    { lat: 49.4900313, lng: 18.6568804 },
    { lat: 49.4902823, lng: 18.6570828 },
    { lat: 49.4905668, lng: 18.6572858 },
    { lat: 49.4907041, lng: 18.6574077 },
    { lat: 49.4909161, lng: 18.6575783 },
    { lat: 49.4910465, lng: 18.6577218 },
    { lat: 49.4912023, lng: 18.6578298 },
    { lat: 49.4913623, lng: 18.6579162 },
    { lat: 49.4914839, lng: 18.6579555 },
    { lat: 49.4915559, lng: 18.6579937 },
    { lat: 49.4916719, lng: 18.6580344 },
    { lat: 49.4917559, lng: 18.6580665 },
    { lat: 49.4918704, lng: 18.6581194 },
    { lat: 49.4919618, lng: 18.6581853 },
    { lat: 49.4920709, lng: 18.6582251 },
    { lat: 49.4921536, lng: 18.6582507 },
    { lat: 49.4922456, lng: 18.6582625 },
    { lat: 49.492378, lng: 18.658233 },
    { lat: 49.4924773, lng: 18.6581656 },
    { lat: 49.4925402, lng: 18.6582071 },
    { lat: 49.4926536, lng: 18.6583018 },
    { lat: 49.4927107, lng: 18.6583796 },
    { lat: 49.4927092, lng: 18.6585046 },
    { lat: 49.4927116, lng: 18.6586356 },
    { lat: 49.4927022, lng: 18.6587979 },
    { lat: 49.4927105, lng: 18.6589336 },
    { lat: 49.4927975, lng: 18.6591092 },
    { lat: 49.4929093, lng: 18.6593464 },
    { lat: 49.4930564, lng: 18.6596136 },
    { lat: 49.4931597, lng: 18.6597996 },
    { lat: 49.4932349, lng: 18.6599386 },
    { lat: 49.4933495, lng: 18.6601453 },
    { lat: 49.4934253, lng: 18.6603235 },
    { lat: 49.4935512, lng: 18.6605295 },
    { lat: 49.4936626, lng: 18.6606118 },
    { lat: 49.493748, lng: 18.6606273 },
    { lat: 49.4938343, lng: 18.6606612 },
    { lat: 49.4939049, lng: 18.6607178 },
    { lat: 49.4939922, lng: 18.6608324 },
    { lat: 49.4940386, lng: 18.6609863 },
    { lat: 49.4940985, lng: 18.6611363 },
    { lat: 49.4941156, lng: 18.6612913 },
    { lat: 49.4941343, lng: 18.6614529 },
    { lat: 49.4941285, lng: 18.6616209 },
    { lat: 49.4941405, lng: 18.661787 },
    { lat: 49.4942212, lng: 18.6619061 },
    { lat: 49.4943214, lng: 18.6619463 },
    { lat: 49.4944181, lng: 18.6619825 },
    { lat: 49.4945244, lng: 18.661969 },
    { lat: 49.4946451, lng: 18.6619457 },
    { lat: 49.4947578, lng: 18.6619012 },
    { lat: 49.4949067, lng: 18.6618603 },
    { lat: 49.4950283, lng: 18.6618553 },
    { lat: 49.4951264, lng: 18.6619005 },
    { lat: 49.4953147, lng: 18.6619856 },
    { lat: 49.4954762, lng: 18.6619467 },
    { lat: 49.495578, lng: 18.6618474 },
    { lat: 49.4956918, lng: 18.6617382 },
    { lat: 49.4958017, lng: 18.6617246 },
    { lat: 49.4959148, lng: 18.6616463 },
    { lat: 49.4960014, lng: 18.6615543 },
    { lat: 49.4961195, lng: 18.6613793 },
    { lat: 49.4962087, lng: 18.6612515 },
    { lat: 49.4962912, lng: 18.6610235 },
    { lat: 49.4963606, lng: 18.6609671 },
    { lat: 49.4964408, lng: 18.660861 },
    { lat: 49.4965524, lng: 18.6608 },
    { lat: 49.4966562, lng: 18.6607171 },
    { lat: 49.4968089, lng: 18.6605951 },
    { lat: 49.4969902, lng: 18.660417 },
    { lat: 49.4971061, lng: 18.6603489 },
    { lat: 49.4972429, lng: 18.6602499 },
    { lat: 49.497469, lng: 18.6600873 },
    { lat: 49.4976698, lng: 18.6600054 },
    { lat: 49.4978793, lng: 18.6598292 },
    { lat: 49.4980198, lng: 18.6597781 },
    { lat: 49.4982156, lng: 18.6596177 },
    { lat: 49.4984059, lng: 18.6594635 },
    { lat: 49.4985237, lng: 18.6594119 },
    { lat: 49.4986563, lng: 18.6593359 },
    { lat: 49.4987858, lng: 18.6592516 },
    { lat: 49.4989109, lng: 18.6592031 },
    { lat: 49.499069, lng: 18.6591 },
    { lat: 49.4992667, lng: 18.6590011 },
    { lat: 49.4994308, lng: 18.6589721 },
    { lat: 49.4996203, lng: 18.6589308 },
    { lat: 49.4997698, lng: 18.6588763 },
    { lat: 49.4999911, lng: 18.658945 },
    { lat: 49.5001557, lng: 18.6588582 },
    { lat: 49.5003619, lng: 18.65892 },
    { lat: 49.5005166, lng: 18.6590535 },
    { lat: 49.5006672, lng: 18.6590225 },
    { lat: 49.5008522, lng: 18.6590449 },
    { lat: 49.5010305, lng: 18.6590485 },
    { lat: 49.5011977, lng: 18.6589926 },
    { lat: 49.5013571, lng: 18.6590613 },
    { lat: 49.5016224, lng: 18.6592802 },
    { lat: 49.5018278, lng: 18.6595874 },
    { lat: 49.5019844, lng: 18.65986 },
    { lat: 49.5020064, lng: 18.6598918 },
    { lat: 49.5023176, lng: 18.6601296 },
    { lat: 49.5025937, lng: 18.6604067 },
    { lat: 49.50287, lng: 18.6601253 },
    { lat: 49.5030653, lng: 18.660084 },
    { lat: 49.503074, lng: 18.658645 },
    { lat: 49.503069, lng: 18.656971 },
    { lat: 49.503069, lng: 18.655942 },
    { lat: 49.503143, lng: 18.654891 },
    { lat: 49.502854, lng: 18.654382 },
    { lat: 49.502414, lng: 18.654109 },
    { lat: 49.501736, lng: 18.653838 },
    { lat: 49.501453, lng: 18.653093 },
    { lat: 49.501095, lng: 18.651345 },
    { lat: 49.501175, lng: 18.650679 },
    { lat: 49.501587, lng: 18.649645 },
    { lat: 49.501405, lng: 18.648435 },
    { lat: 49.5012, lng: 18.647045 },
    { lat: 49.500796, lng: 18.645457 },
    { lat: 49.500702, lng: 18.645004 },
    { lat: 49.500649, lng: 18.644005 },
    { lat: 49.50091, lng: 18.64317 },
    { lat: 49.500789, lng: 18.642328 },
    { lat: 49.499955, lng: 18.641964 },
    { lat: 49.499183, lng: 18.641626 },
    { lat: 49.498439, lng: 18.641307 },
    { lat: 49.498209, lng: 18.641015 },
    { lat: 49.497967, lng: 18.640701 },
    { lat: 49.497546, lng: 18.640441 },
    { lat: 49.496979, lng: 18.640096 },
    { lat: 49.496645, lng: 18.639841 },
    { lat: 49.496229, lng: 18.639521 },
    { lat: 49.496175, lng: 18.63908 },
    { lat: 49.496463, lng: 18.638666 },
    { lat: 49.496696, lng: 18.638332 },
    { lat: 49.497011, lng: 18.637874 },
    { lat: 49.497334, lng: 18.636889 },
    { lat: 49.497751, lng: 18.636092 },
    { lat: 49.498003, lng: 18.634812 },
    { lat: 49.49802, lng: 18.634198 },
    { lat: 49.498256, lng: 18.6331 },
    { lat: 49.498647, lng: 18.632273 },
    { lat: 49.498727, lng: 18.631542 },
    { lat: 49.498502, lng: 18.6302 },
    { lat: 49.498383, lng: 18.629496 },
    { lat: 49.498116, lng: 18.627999 },
    { lat: 49.497751, lng: 18.627536 },
    { lat: 49.497642, lng: 18.626965 },
    { lat: 49.497579, lng: 18.626635 },
    { lat: 49.497207, lng: 18.625565 },
    { lat: 49.497035, lng: 18.624811 },
    { lat: 49.496781, lng: 18.623969 },
    { lat: 49.496618, lng: 18.623525 },
    { lat: 49.496488, lng: 18.622981 },
    { lat: 49.496327, lng: 18.6225 },
    { lat: 49.495964, lng: 18.621933 },
    { lat: 49.495789, lng: 18.62149 },
    { lat: 49.4938494, lng: 18.622555 },
    { lat: 49.4938333, lng: 18.6225461 },
    { lat: 49.493311, lng: 18.6227768 },
    { lat: 49.4931115, lng: 18.622843 },
    { lat: 49.4922394, lng: 18.6231333 },
    { lat: 49.4917491, lng: 18.6222459 },
    { lat: 49.4913172, lng: 18.6220107 },
    { lat: 49.490784, lng: 18.6210147 },
    { lat: 49.4905114, lng: 18.6209367 },
    { lat: 49.4902183, lng: 18.6204661 },
    { lat: 49.4900723, lng: 18.6202446 },
    { lat: 49.4900298, lng: 18.62021 },
    { lat: 49.4900219, lng: 18.6201892 },
    { lat: 49.4898514, lng: 18.6201826 },
    { lat: 49.4894261, lng: 18.6204555 },
    { lat: 49.4887759, lng: 18.6209125 },
    { lat: 49.4883479, lng: 18.6211125 },
    { lat: 49.4878306, lng: 18.6212061 },
    { lat: 49.4873076, lng: 18.6215688 },
    { lat: 49.4870768, lng: 18.6219625 },
    { lat: 49.486609, lng: 18.6227949 },
    { lat: 49.4865852, lng: 18.622836 },
    { lat: 49.4864782, lng: 18.623051 },
    { lat: 49.4863967, lng: 18.6231493 },
    { lat: 49.4862777, lng: 18.6233521 },
    { lat: 49.4860745, lng: 18.6236473 },
    { lat: 49.4859172, lng: 18.6239332 },
    { lat: 49.4857595, lng: 18.6241277 },
    { lat: 49.4854653, lng: 18.6243642 },
    { lat: 49.4852241, lng: 18.6245173 },
    { lat: 49.4847531, lng: 18.6248016 },
    { lat: 49.4843187, lng: 18.6248587 },
    { lat: 49.4841212, lng: 18.6248769 },
    { lat: 49.483811, lng: 18.6248963 },
    { lat: 49.4835481, lng: 18.6249795 },
    { lat: 49.483187, lng: 18.6250804 },
    { lat: 49.4831704, lng: 18.6250669 },
    { lat: 49.4829078, lng: 18.6252832 },
    { lat: 49.4828922, lng: 18.6252659 },
    { lat: 49.4826991, lng: 18.6251529 },
    { lat: 49.4826788, lng: 18.6251326 },
    { lat: 49.4824565, lng: 18.6249982 },
    { lat: 49.4816061, lng: 18.6242324 },
    { lat: 49.4813594, lng: 18.6238759 },
    { lat: 49.4812269, lng: 18.6237397 },
    { lat: 49.4809395, lng: 18.6235453 },
    { lat: 49.4805397, lng: 18.6233681 },
    { lat: 49.4802761, lng: 18.6231388 },
    { lat: 49.4800228, lng: 18.6227816 },
    { lat: 49.4796479, lng: 18.6227073 },
    { lat: 49.4794824, lng: 18.622885 },
    { lat: 49.4794515, lng: 18.6228761 },
    { lat: 49.4793518, lng: 18.6228469 },
    { lat: 49.4790083, lng: 18.6230032 },
    { lat: 49.4785363, lng: 18.6232689 },
    { lat: 49.4781146, lng: 18.6233741 },
    { lat: 49.4779002, lng: 18.6234791 },
    { lat: 49.4777414, lng: 18.6235907 },
    { lat: 49.4776817, lng: 18.6236326 },
    { lat: 49.4775436, lng: 18.6237282 },
    { lat: 49.4770439, lng: 18.6242786 },
    { lat: 49.4768036, lng: 18.6244482 },
    { lat: 49.4757791, lng: 18.6249514 },
    { lat: 49.4745398, lng: 18.6257159 },
    { lat: 49.4739417, lng: 18.6262822 },
    { lat: 49.4735978, lng: 18.6268788 },
    { lat: 49.4734, lng: 18.6268299 },
    { lat: 49.4732284, lng: 18.6269078 },
    { lat: 49.4730697, lng: 18.6272141 },
    { lat: 49.4729296, lng: 18.6275496 },
    { lat: 49.47267, lng: 18.6278459 },
    { lat: 49.4725158, lng: 18.6279547 },
    { lat: 49.47227, lng: 18.6281275 },
    { lat: 49.4720372, lng: 18.6282881 },
    { lat: 49.4719878, lng: 18.6284397 },
    { lat: 49.4720392, lng: 18.6287093 },
    { lat: 49.4720366, lng: 18.6288738 },
    { lat: 49.4720199, lng: 18.6290054 },
    { lat: 49.4719791, lng: 18.6291047 },
    { lat: 49.4719242, lng: 18.6291341 },
    { lat: 49.4719023, lng: 18.6291455 },
    { lat: 49.4718726, lng: 18.6291604 },
    { lat: 49.4718165, lng: 18.6291885 },
    { lat: 49.4717868, lng: 18.6292034 },
    { lat: 49.4717291, lng: 18.6292207 },
    { lat: 49.4716937, lng: 18.6292306 },
    { lat: 49.4716102, lng: 18.6294872 },
    { lat: 49.4714899, lng: 18.6294743 },
    { lat: 49.4714185, lng: 18.629617 },
    { lat: 49.4713804, lng: 18.6298516 },
    { lat: 49.4713171, lng: 18.6299157 },
    { lat: 49.4712182, lng: 18.6298584 },
    { lat: 49.4711469, lng: 18.6300036 },
    { lat: 49.4709026, lng: 18.630499 },
    { lat: 49.4708778, lng: 18.6305213 },
    { lat: 49.4706893, lng: 18.6306891 },
    { lat: 49.4707073, lng: 18.6308989 },
    { lat: 49.4707735, lng: 18.6309578 },
    { lat: 49.4708902, lng: 18.6309878 },
    { lat: 49.4709566, lng: 18.6310963 },
    { lat: 49.4708906, lng: 18.6313798 },
    { lat: 49.4708503, lng: 18.6315164 },
    { lat: 49.4707394, lng: 18.631676 },
    { lat: 49.4706656, lng: 18.6318168 },
    { lat: 49.4706392, lng: 18.6319347 },
    { lat: 49.4706696, lng: 18.6321071 },
    { lat: 49.4706895, lng: 18.6321202 },
    { lat: 49.4706682, lng: 18.6321878 },
    { lat: 49.4705437, lng: 18.6326039 },
    { lat: 49.4703986, lng: 18.632973 },
    { lat: 49.4704408, lng: 18.6330254 },
    { lat: 49.4704896, lng: 18.6330092 },
    { lat: 49.4705213, lng: 18.6330948 },
    { lat: 49.4705188, lng: 18.6331992 },
    { lat: 49.4704228, lng: 18.6333014 },
    { lat: 49.4701444, lng: 18.6332821 },
    { lat: 49.4700347, lng: 18.6333853 },
    { lat: 49.4699883, lng: 18.6335706 },
    { lat: 49.4700454, lng: 18.6336726 },
    { lat: 49.4702006, lng: 18.633791 },
    { lat: 49.4701956, lng: 18.633912 },
    { lat: 49.4700097, lng: 18.6343587 },
    { lat: 49.4697375, lng: 18.6349938 },
    { lat: 49.4695689, lng: 18.6352606 },
    { lat: 49.4694634, lng: 18.6353328 },
    { lat: 49.4694288, lng: 18.6353411 },
    { lat: 49.4692634, lng: 18.635431 },
    { lat: 49.4692058, lng: 18.6355168 },
    { lat: 49.4691511, lng: 18.6357379 },
    { lat: 49.469105, lng: 18.6361037 },
    { lat: 49.4689865, lng: 18.6363157 },
    { lat: 49.4687586, lng: 18.6365062 },
    { lat: 49.4686349, lng: 18.6367667 },
    { lat: 49.4685647, lng: 18.6371655 },
    { lat: 49.468414, lng: 18.6374301 },
    { lat: 49.4683884, lng: 18.6374993 },
    { lat: 49.4683545, lng: 18.6375648 },
    { lat: 49.4683363, lng: 18.6377516 },
    { lat: 49.4683299, lng: 18.6380153 },
    { lat: 49.468247, lng: 18.6384103 },
    { lat: 49.4682616, lng: 18.6390488 },
    { lat: 49.4681772, lng: 18.6391985 },
    { lat: 49.4678819, lng: 18.6394144 },
    { lat: 49.4678022, lng: 18.6396368 },
    { lat: 49.4677393, lng: 18.6397704 },
    { lat: 49.4676254, lng: 18.6403647 },
    { lat: 49.4675476, lng: 18.6404081 },
    { lat: 49.4675568, lng: 18.64056 },
    { lat: 49.4677382, lng: 18.6409892 },
    { lat: 49.4677385, lng: 18.6411407 },
    { lat: 49.4675741, lng: 18.641255 },
    { lat: 49.4675098, lng: 18.6412935 },
    { lat: 49.4673604, lng: 18.6413063 },
    { lat: 49.4672903, lng: 18.6412716 },
    { lat: 49.4672203, lng: 18.6412795 },
    { lat: 49.4672577, lng: 18.6413481 },
    { lat: 49.46725, lng: 18.6414132 },
    { lat: 49.4672391, lng: 18.6414446 },
    { lat: 49.4672013, lng: 18.6416894 },
    { lat: 49.4671487, lng: 18.6418431 },
    { lat: 49.4670651, lng: 18.6419079 },
    { lat: 49.4669975, lng: 18.6418663 },
    { lat: 49.4669882, lng: 18.6418605 },
    { lat: 49.4666908, lng: 18.6416753 },
    { lat: 49.4666244, lng: 18.6416353 },
    { lat: 49.4665322, lng: 18.6414455 },
    { lat: 49.4665246, lng: 18.6414316 },
    { lat: 49.466481, lng: 18.6414784 },
    { lat: 49.4664064, lng: 18.6421142 },
    { lat: 49.4662735, lng: 18.642333 },
    { lat: 49.4662868, lng: 18.6424992 },
    { lat: 49.4661428, lng: 18.6426981 },
    { lat: 49.466075, lng: 18.6427387 },
    { lat: 49.4659446, lng: 18.6428518 },
    { lat: 49.4657942, lng: 18.6429749 },
    { lat: 49.4657496, lng: 18.6431109 },
    { lat: 49.4656829, lng: 18.643042 },
    { lat: 49.4656417, lng: 18.6430308 },
    { lat: 49.4655556, lng: 18.6430245 },
    { lat: 49.4654996, lng: 18.6430521 },
    { lat: 49.4654294, lng: 18.6431326 },
    { lat: 49.4654652, lng: 18.6432768 },
    { lat: 49.4655093, lng: 18.6433877 },
    { lat: 49.4653775, lng: 18.6434505 },
    { lat: 49.4652885, lng: 18.6436237 },
    { lat: 49.4651832, lng: 18.6438082 },
    { lat: 49.4650787, lng: 18.6441149 },
    { lat: 49.464791, lng: 18.6442605 },
    { lat: 49.4647489, lng: 18.6442726 },
    { lat: 49.4646397, lng: 18.6443004 },
    { lat: 49.4644007, lng: 18.6443025 },
    { lat: 49.4643021, lng: 18.6447629 },
    { lat: 49.4640617, lng: 18.6450146 },
    { lat: 49.4639176, lng: 18.6452758 },
    { lat: 49.4638871, lng: 18.645615 },
    { lat: 49.4639964, lng: 18.6459139 },
    { lat: 49.4640719, lng: 18.6462964 },

    { lat: 49.4528821, lng: 18.6604047 },
    { lat: 49.4528531, lng: 18.6602398 },
    { lat: 49.4528968, lng: 18.6598487 },
    { lat: 49.4529092, lng: 18.6596315 },
    { lat: 49.4529065, lng: 18.659347 },
    { lat: 49.4528635, lng: 18.659149 },
    { lat: 49.4528068, lng: 18.6588048 },
    { lat: 49.4528063, lng: 18.6586015 },
    { lat: 49.4526611, lng: 18.6582005 },
    { lat: 49.4526893, lng: 18.6581361 },
    { lat: 49.452756, lng: 18.6581195 },
    { lat: 49.4528275, lng: 18.6581045 },
    { lat: 49.4529321, lng: 18.6580567 },
    { lat: 49.452961, lng: 18.6579204 },
    { lat: 49.4530231, lng: 18.6576224 },
    { lat: 49.4530546, lng: 18.6574717 },
    { lat: 49.453053, lng: 18.6571196 },
    { lat: 49.4531084, lng: 18.65695 },
    { lat: 49.4531819, lng: 18.656826 },
    { lat: 49.4532895, lng: 18.6565799 },
    { lat: 49.4533484, lng: 18.6562 },
    { lat: 49.4533704, lng: 18.6560403 },
    { lat: 49.4536309, lng: 18.6556111 },
    { lat: 49.4537127, lng: 18.6554292 },
    { lat: 49.4538134, lng: 18.6550306 },
    { lat: 49.4539244, lng: 18.6547831 },
    { lat: 49.4542268, lng: 18.6544675 },
    { lat: 49.4542426, lng: 18.6542118 },
    { lat: 49.4541904, lng: 18.6539026 },
    { lat: 49.454199, lng: 18.6537895 },
    { lat: 49.4543696, lng: 18.6534744 },
    { lat: 49.4543494, lng: 18.653329 },
    { lat: 49.4542363, lng: 18.6531067 },
    { lat: 49.4542283, lng: 18.6530483 },
    { lat: 49.454207, lng: 18.6528929 },
    { lat: 49.4543175, lng: 18.6526167 },
    { lat: 49.4543059, lng: 18.6524833 },
    { lat: 49.4542798, lng: 18.6517326 },
    { lat: 49.4544927, lng: 18.6509976 },
    { lat: 49.4545423, lng: 18.6507022 },
    { lat: 49.4545064, lng: 18.6504913 },
    { lat: 49.4544973, lng: 18.65006 },
    { lat: 49.4544585, lng: 18.6495632 },
    { lat: 49.4544393, lng: 18.6493459 },
    { lat: 49.4544679, lng: 18.6488628 },
    { lat: 49.4544591, lng: 18.6485257 },
    { lat: 49.4544682, lng: 18.6482687 },
    { lat: 49.4543661, lng: 18.6478116 },
    { lat: 49.4543616, lng: 18.6474678 },
    { lat: 49.4543259, lng: 18.6472199 },
    { lat: 49.4543131, lng: 18.6467634 },
    { lat: 49.4542757, lng: 18.6463783 },
    { lat: 49.4542128, lng: 18.6461034 },
    { lat: 49.4542057, lng: 18.645839 },
    { lat: 49.4542162, lng: 18.6450812 },
    { lat: 49.454308, lng: 18.644766 },
    { lat: 49.454397, lng: 18.6440851 },
    { lat: 49.4544337, lng: 18.6434952 },
    { lat: 49.4544722, lng: 18.643096 },
    { lat: 49.4544827, lng: 18.6424825 },
    { lat: 49.4544456, lng: 18.6422265 },
    { lat: 49.4543903, lng: 18.6418419 },
    { lat: 49.4543629, lng: 18.6414973 },
    { lat: 49.454399, lng: 18.6410274 },
    { lat: 49.4544393, lng: 18.6405073 },
    { lat: 49.4545458, lng: 18.6395186 },
    { lat: 49.4546994, lng: 18.6387546 },
    { lat: 49.4547302, lng: 18.6384604 },
    { lat: 49.454741, lng: 18.6381104 },
    { lat: 49.454783, lng: 18.6377112 },
    { lat: 49.454744, lng: 18.6370991 },
    { lat: 49.4546572, lng: 18.6366959 },
    { lat: 49.4544945, lng: 18.6364787 },
    { lat: 49.4543521, lng: 18.6363243 },
    { lat: 49.4542913, lng: 18.6357461 },
    { lat: 49.4540505, lng: 18.6355017 },
    { lat: 49.4537691, lng: 18.6353014 },
    { lat: 49.4535265, lng: 18.6348517 },
    { lat: 49.4533227, lng: 18.6344294 },
    { lat: 49.4531989, lng: 18.6337964 },
    { lat: 49.4531601, lng: 18.633446 },
    { lat: 49.4531292, lng: 18.6330098 },
    { lat: 49.4531212, lng: 18.6326893 },
    { lat: 49.4530856, lng: 18.6323345 },
    { lat: 49.4530551, lng: 18.6320792 },
    { lat: 49.4530428, lng: 18.6318241 },
    { lat: 49.4530445, lng: 18.6315404 },
    { lat: 49.4529619, lng: 18.6312114 },
    { lat: 49.4528915, lng: 18.6308421 },
    { lat: 49.4527968, lng: 18.6304806 },
    { lat: 49.4526487, lng: 18.6300267 },
    { lat: 49.4525441, lng: 18.6296273 },
    { lat: 49.4523921, lng: 18.6293755 },
    { lat: 49.4521741, lng: 18.6287705 },
    { lat: 49.4516615, lng: 18.6282925 },
    { lat: 49.4511685, lng: 18.627778 },
    { lat: 49.450741, lng: 18.6276051 },
    { lat: 49.4505477, lng: 18.6274255 },
    { lat: 49.4503453, lng: 18.6271154 },
    { lat: 49.4502213, lng: 18.6269069 },
    { lat: 49.4500965, lng: 18.6267647 },
    { lat: 49.4499612, lng: 18.6266134 },
    { lat: 49.4498117, lng: 18.6266875 },
    { lat: 49.4498095, lng: 18.6267752 },
    { lat: 49.4495055, lng: 18.6267199 },
    { lat: 49.4492635, lng: 18.6266214 },
    { lat: 49.4491284, lng: 18.6265407 },
    { lat: 49.448934, lng: 18.6264044 },
    { lat: 49.4486755, lng: 18.6262308 },
    { lat: 49.4482657, lng: 18.6259234 },
    { lat: 49.4481606, lng: 18.6258118 },
    { lat: 49.4481312, lng: 18.6257035 },
    { lat: 49.448053, lng: 18.6254628 },
    { lat: 49.4479428, lng: 18.6251949 },
    { lat: 49.4476726, lng: 18.6244787 },
    { lat: 49.447512, lng: 18.6240845 },
    { lat: 49.4471868, lng: 18.6239706 },
    { lat: 49.4469277, lng: 18.6239337 },
    { lat: 49.4463495, lng: 18.6239833 },
    { lat: 49.4460837, lng: 18.6240509 },
    { lat: 49.4458478, lng: 18.6240687 },
    { lat: 49.4457082, lng: 18.6241354 },
    { lat: 49.4454118, lng: 18.6245228 },
    { lat: 49.4451106, lng: 18.6245013 },
    { lat: 49.4449375, lng: 18.6246602 },
    { lat: 49.4449322, lng: 18.6246663 },
    { lat: 49.4445352, lng: 18.6249659 },
    { lat: 49.4443613, lng: 18.6250021 },
    { lat: 49.4442407, lng: 18.6250664 },
    { lat: 49.4437255, lng: 18.6252169 },
    { lat: 49.4436022, lng: 18.6253609 },
    { lat: 49.4434499, lng: 18.6255719 },
    { lat: 49.4433425, lng: 18.6259724 },
    { lat: 49.4432097, lng: 18.6262756 },
    { lat: 49.4429736, lng: 18.6270106 },
    { lat: 49.4428718, lng: 18.6271541 },
    { lat: 49.4427483, lng: 18.6272275 },
    { lat: 49.4425836, lng: 18.627227 },
    { lat: 49.4424781, lng: 18.6273398 },
    { lat: 49.4421992, lng: 18.6274416 },
    { lat: 49.4419883, lng: 18.6275059 },
    { lat: 49.441764, lng: 18.6276353 },
    { lat: 49.4414585, lng: 18.6277212 },
    { lat: 49.4412461, lng: 18.6277568 },
    { lat: 49.4411501, lng: 18.6277745 },
    { lat: 49.4409444, lng: 18.6277672 },
    { lat: 49.4409025, lng: 18.6277659 },
    { lat: 49.4408518, lng: 18.6277635 },
    { lat: 49.4406062, lng: 18.6277702 },
    { lat: 49.4402891, lng: 18.6278296 },
    { lat: 49.4400888, lng: 18.627902 },
    { lat: 49.4398541, lng: 18.6280262 },
    { lat: 49.4395035, lng: 18.628541 },
    { lat: 49.4393747, lng: 18.6287049 },
    { lat: 49.4389348, lng: 18.6292112 },
    { lat: 49.438681, lng: 18.6295551 },
    { lat: 49.438539, lng: 18.6297689 },
    { lat: 49.4383735, lng: 18.6300486 },
    { lat: 49.4383611, lng: 18.630069 },
    { lat: 49.4381815, lng: 18.6300859 },
    { lat: 49.43815, lng: 18.6300887 },
    { lat: 49.4380702, lng: 18.6301353 },
    { lat: 49.4379398, lng: 18.6303323 },
    { lat: 49.4378249, lng: 18.630505 },
    { lat: 49.4376868, lng: 18.6306907 },
    { lat: 49.4376101, lng: 18.6307952 },
    { lat: 49.4373858, lng: 18.6309231 },
    { lat: 49.437293, lng: 18.6309839 },
    { lat: 49.4372049, lng: 18.6310638 },
    { lat: 49.4370554, lng: 18.6312665 },
    { lat: 49.4368342, lng: 18.6315165 },
    { lat: 49.4366001, lng: 18.6317402 },
    { lat: 49.4364989, lng: 18.6317887 },
    { lat: 49.4364222, lng: 18.631978 },
    { lat: 49.4360871, lng: 18.6323385 },
    { lat: 49.4360153, lng: 18.6325145 },
    { lat: 49.4356902, lng: 18.6327416 },
    { lat: 49.435506, lng: 18.6329493 },
    { lat: 49.435098, lng: 18.6333132 },
    { lat: 49.4347986, lng: 18.633433 },
    { lat: 49.4345406, lng: 18.6335869 },
    { lat: 49.4345244, lng: 18.6335969 },
    { lat: 49.4342224, lng: 18.6337087 },
    { lat: 49.4339393, lng: 18.6337078 },
    { lat: 49.4336427, lng: 18.6337929 },
    { lat: 49.4336383, lng: 18.6337724 },
    { lat: 49.433185, lng: 18.6339216 },
    { lat: 49.4328024, lng: 18.6340467 },
    { lat: 49.4321996, lng: 18.6344804 },
    { lat: 49.4315204, lng: 18.6352417 },
    { lat: 49.4314956, lng: 18.6352655 },
    { lat: 49.4310819, lng: 18.6356901 },
    { lat: 49.430912, lng: 18.636021 },
    { lat: 49.430504, lng: 18.6363844 },
    { lat: 49.4297623, lng: 18.636435 },
    { lat: 49.4291531, lng: 18.6365144 },
    { lat: 49.4288342, lng: 18.6368381 },
    { lat: 49.4268883, lng: 18.6366795 },
    { lat: 49.425901, lng: 18.6374957 },
    { lat: 49.4256574, lng: 18.6375187 },
    { lat: 49.4250162, lng: 18.6378694 },
    { lat: 49.4247681, lng: 18.6384639 },
    { lat: 49.4242915, lng: 18.6391119 },
    { lat: 49.4237689, lng: 18.6399311 },
    { lat: 49.4234318, lng: 18.6402552 },
    { lat: 49.4231642, lng: 18.6403544 },
    { lat: 49.423039, lng: 18.6403956 },
    { lat: 49.4228916, lng: 18.640339 },
    { lat: 49.4226603, lng: 18.6401873 },
    { lat: 49.4223262, lng: 18.6399691 },
    { lat: 49.4212809, lng: 18.6395213 },
    { lat: 49.4205183, lng: 18.6392158 },
    { lat: 49.4198166, lng: 18.6399786 },
    { lat: 49.4184989, lng: 18.6397979 },
    { lat: 49.4170925, lng: 18.6396246 },
    { lat: 49.4169598, lng: 18.6396936 },
    { lat: 49.4164056, lng: 18.6399835 },
    { lat: 49.4162268, lng: 18.6400336 },
    { lat: 49.4159713, lng: 18.640132 },
    { lat: 49.4154731, lng: 18.6403703 },
    { lat: 49.4154281, lng: 18.6403918 },
    { lat: 49.4151331, lng: 18.6404886 },
    { lat: 49.4148019, lng: 18.6405967 },
    { lat: 49.4146521, lng: 18.6406458 },
    { lat: 49.4143365, lng: 18.6408186 },
    { lat: 49.4139266, lng: 18.6410434 },
    { lat: 49.4137687, lng: 18.6411043 },
    { lat: 49.4132891, lng: 18.6412889 },
    { lat: 49.4129379, lng: 18.6414314 },
    { lat: 49.4126144, lng: 18.6416686 },
    { lat: 49.4121048, lng: 18.6417156 },
    { lat: 49.4120444, lng: 18.641721 },
    { lat: 49.4118284, lng: 18.6418191 },
    { lat: 49.4113959, lng: 18.6418483 },
    { lat: 49.4111197, lng: 18.6418713 },
    { lat: 49.4109448, lng: 18.6419749 },
    { lat: 49.4102842, lng: 18.6421497 },
    { lat: 49.4097734, lng: 18.6422367 },
    { lat: 49.409378, lng: 18.6420526 },
    { lat: 49.4089847, lng: 18.6419707 },
    { lat: 49.4089436, lng: 18.6419617 },
    { lat: 49.4088644, lng: 18.641957 },
    { lat: 49.4088632, lng: 18.6419572 },
    { lat: 49.4088038, lng: 18.6419646 },
    { lat: 49.4084721, lng: 18.6420054 },
    { lat: 49.4083459, lng: 18.6420493 },
    { lat: 49.4082951, lng: 18.6420676 },
    { lat: 49.4082287, lng: 18.6420903 },
    { lat: 49.4081777, lng: 18.6421083 },
    { lat: 49.4080091, lng: 18.6421596 },
    { lat: 49.4079867, lng: 18.642183 },
    { lat: 49.4078413, lng: 18.6423333 },
    { lat: 49.4078316, lng: 18.6423432 },
    { lat: 49.4077392, lng: 18.6424713 },
    { lat: 49.4077013, lng: 18.6425245 },
    { lat: 49.4075711, lng: 18.6427062 },
    { lat: 49.4075331, lng: 18.6427576 },
    { lat: 49.4075088, lng: 18.6427842 },
    { lat: 49.4075077, lng: 18.6427854 },
    { lat: 49.4074869, lng: 18.6428003 },
    { lat: 49.4074152, lng: 18.6428917 },
    { lat: 49.4073398, lng: 18.6429174 },
    { lat: 49.4072831, lng: 18.642932 },
    { lat: 49.4072575, lng: 18.6429389 },
    { lat: 49.4072062, lng: 18.6429528 },
    { lat: 49.4070968, lng: 18.6430544 },
    { lat: 49.4070504, lng: 18.6430978 },
    { lat: 49.4068756, lng: 18.6433307 },
    { lat: 49.4068124, lng: 18.6434146 },
    { lat: 49.406708, lng: 18.6435543 },
    { lat: 49.4066638, lng: 18.6437586 },
    { lat: 49.4067245, lng: 18.6438206 },
    { lat: 49.4069718, lng: 18.6442744 },
    { lat: 49.4072217, lng: 18.6449705 },
    { lat: 49.4073296, lng: 18.6453888 },
    { lat: 49.4073905, lng: 18.6460522 },
    { lat: 49.4074729, lng: 18.6467138 },
    { lat: 49.4075973, lng: 18.647725 },
    { lat: 49.4077513, lng: 18.648655 },
    { lat: 49.4077119, lng: 18.6493809 },
    { lat: 49.4078003, lng: 18.6501757 },
    { lat: 49.4078596, lng: 18.6506409 },
    { lat: 49.4079393, lng: 18.6510811 },
    { lat: 49.4080418, lng: 18.6512053 },
    { lat: 49.408532, lng: 18.6516899 },
    { lat: 49.4089768, lng: 18.6519342 },
    { lat: 49.4093723, lng: 18.6519659 },
    { lat: 49.4094084, lng: 18.6519688 },
    { lat: 49.4094427, lng: 18.6519715 },
    { lat: 49.410151, lng: 18.6520283 },
    { lat: 49.4104773, lng: 18.6524398 },
    { lat: 49.4105546, lng: 18.6527084 },
    { lat: 49.4107781, lng: 18.6535226 },
    { lat: 49.4108676, lng: 18.6539143 },
    { lat: 49.4110097, lng: 18.6546644 },
    { lat: 49.4110323, lng: 18.6550064 },
    { lat: 49.4110272, lng: 18.6552507 },
    { lat: 49.4108648, lng: 18.6565569 },
    { lat: 49.4107884, lng: 18.6571199 },
    { lat: 49.4107675, lng: 18.657274 },
    { lat: 49.4108763, lng: 18.6573301 },
    { lat: 49.4110376, lng: 18.6574126 },
    { lat: 49.4110717, lng: 18.6575902 },
    { lat: 49.411094, lng: 18.6577573 },
    { lat: 49.4111059, lng: 18.6580263 },
    { lat: 49.4111283, lng: 18.6580894 },
    { lat: 49.411176, lng: 18.6582454 },
    { lat: 49.4112252, lng: 18.6585544 },
    { lat: 49.4112552, lng: 18.658739 },
    { lat: 49.4112783, lng: 18.6587919 },
    { lat: 49.4113089, lng: 18.6588598 },
    { lat: 49.4113152, lng: 18.6588724 },
    { lat: 49.4113676, lng: 18.6589884 },
    { lat: 49.4115606, lng: 18.6588656 },
    { lat: 49.4115703, lng: 18.6588966 },
    { lat: 49.4116533, lng: 18.6591674 },
    { lat: 49.4117313, lng: 18.6594275 },
    { lat: 49.4118129, lng: 18.6596926 },
    { lat: 49.4118935, lng: 18.6599575 },
    { lat: 49.4119522, lng: 18.660152 },
    { lat: 49.4119584, lng: 18.6601845 },
    { lat: 49.4119557, lng: 18.6602196 },
    { lat: 49.4119836, lng: 18.6603625 },
    { lat: 49.4120728, lng: 18.6602569 },
    { lat: 49.4122524, lng: 18.6600913 },
    { lat: 49.412276, lng: 18.6600695 },
    { lat: 49.4123312, lng: 18.6600261 },
    { lat: 49.4123416, lng: 18.6600538 },
    { lat: 49.4124557, lng: 18.6603587 },
    { lat: 49.4125819, lng: 18.6606951 },
    { lat: 49.4127078, lng: 18.6610293 },
    { lat: 49.4128416, lng: 18.6613883 },
    { lat: 49.4130579, lng: 18.661551 },
    { lat: 49.4131223, lng: 18.6615988 },
    { lat: 49.4141086, lng: 18.6637739 },
    { lat: 49.4140791, lng: 18.6638992 },
    { lat: 49.4140433, lng: 18.6640536 },
    { lat: 49.4142365, lng: 18.6641014 },
    { lat: 49.4142927, lng: 18.6641419 },
    { lat: 49.4145976, lng: 18.6645775 },
    { lat: 49.4148478, lng: 18.6655182 },
    { lat: 49.4154334, lng: 18.6666178 },
    { lat: 49.4155448, lng: 18.6668275 },
    { lat: 49.4158132, lng: 18.6674904 },
    { lat: 49.4158157, lng: 18.6677306 },
    { lat: 49.4158149, lng: 18.6678391 },
    { lat: 49.4158042, lng: 18.6692879 },
    { lat: 49.4157907, lng: 18.6694781 },
    { lat: 49.4157706, lng: 18.6697629 },
    { lat: 49.4157608, lng: 18.6697927 },
    { lat: 49.415751, lng: 18.6700719 },
    { lat: 49.4157224, lng: 18.6708866 },
    { lat: 49.4161, lng: 18.6716932 },
    { lat: 49.416331, lng: 18.6720558 },
    { lat: 49.416724, lng: 18.6726225 },
    { lat: 49.4170192, lng: 18.6728965 },
    { lat: 49.4174489, lng: 18.6730929 },
    { lat: 49.417661, lng: 18.6731647 },
    { lat: 49.4180436, lng: 18.6732943 },
    { lat: 49.4187522, lng: 18.6735808 },
    { lat: 49.4190786, lng: 18.6738064 },
    { lat: 49.41926, lng: 18.673994 },
    { lat: 49.4193512, lng: 18.6741417 },
    { lat: 49.4195201, lng: 18.674716 },
    { lat: 49.4195693, lng: 18.6751152 },
    { lat: 49.4196225, lng: 18.6759117 },
    { lat: 49.4196646, lng: 18.6768435 },
    { lat: 49.419675, lng: 18.6775757 },
    { lat: 49.4196909, lng: 18.678134 },
    { lat: 49.4196476, lng: 18.678472 },
    { lat: 49.4195597, lng: 18.6791572 },
    { lat: 49.4195558, lng: 18.6791913 },
    { lat: 49.4195512, lng: 18.6792296 },
    { lat: 49.4195378, lng: 18.6792979 },
    { lat: 49.4196707, lng: 18.6793362 },
    { lat: 49.419698, lng: 18.679344 },
    { lat: 49.4201251, lng: 18.6795289 },
    { lat: 49.4205023, lng: 18.6798 },
    { lat: 49.4207778, lng: 18.6801278 },
    { lat: 49.4209986, lng: 18.680491 },
    { lat: 49.4210173, lng: 18.6805217 },
    { lat: 49.4210926, lng: 18.6806464 },
    { lat: 49.4213292, lng: 18.6812424 },
    { lat: 49.4213702, lng: 18.6814459 },
    { lat: 49.4214628, lng: 18.6818326 },
    { lat: 49.4214685, lng: 18.6818561 },
    { lat: 49.4214836, lng: 18.6819264 },
    { lat: 49.4215178, lng: 18.6818946 },
    { lat: 49.4215366, lng: 18.681884 },
    { lat: 49.4215563, lng: 18.6818765 },
    { lat: 49.4217032, lng: 18.681771 },
    { lat: 49.4217398, lng: 18.6817393 },
    { lat: 49.421994, lng: 18.6812121 },
    { lat: 49.4222824, lng: 18.6805404 },
    { lat: 49.4224794, lng: 18.680106 },
    { lat: 49.4227086, lng: 18.6795996 },
    { lat: 49.4229585, lng: 18.6789892 },
    { lat: 49.423038, lng: 18.6788261 },
    { lat: 49.4233251, lng: 18.6782422 },
    { lat: 49.4235691, lng: 18.6778176 },
    { lat: 49.4238107, lng: 18.6774147 },
    { lat: 49.4241626, lng: 18.67688 },
    { lat: 49.4244406, lng: 18.6765052 },
    { lat: 49.4247761, lng: 18.6761087 },
    { lat: 49.4249556, lng: 18.6759014 },
    { lat: 49.425155, lng: 18.6756851 },
    { lat: 49.4255656, lng: 18.6752783 },
    { lat: 49.4259847, lng: 18.6748646 },
    { lat: 49.4262012, lng: 18.6746491 },
    { lat: 49.4264226, lng: 18.674379 },
    { lat: 49.426626, lng: 18.6740865 },
    { lat: 49.4267401, lng: 18.6738301 },
    { lat: 49.4268985, lng: 18.6734551 },
    { lat: 49.4271125, lng: 18.6728951 },
    { lat: 49.4272608, lng: 18.6725394 },
    { lat: 49.4273728, lng: 18.6723325 },
    { lat: 49.4275418, lng: 18.6720964 },
    { lat: 49.4277194, lng: 18.6719147 },
    { lat: 49.4279429, lng: 18.671765 },
    { lat: 49.4281816, lng: 18.6716322 },
    { lat: 49.4284988, lng: 18.6714452 },
    { lat: 49.4287458, lng: 18.671315 },
    { lat: 49.4287951, lng: 18.6712919 },
    { lat: 49.429082, lng: 18.6711492 },
    { lat: 49.4296888, lng: 18.6708068 },
    { lat: 49.4301193, lng: 18.6705684 },
    { lat: 49.4304432, lng: 18.6704015 },
    { lat: 49.4307121, lng: 18.6701991 },
    { lat: 49.4310876, lng: 18.6697686 },
    { lat: 49.4317425, lng: 18.6689615 },
    { lat: 49.4321661, lng: 18.6683863 },
    { lat: 49.432174, lng: 18.6683835 },
    { lat: 49.4328802, lng: 18.6674711 },
    { lat: 49.4331639, lng: 18.6672509 },
    { lat: 49.4336001, lng: 18.6670085 },
    { lat: 49.4339042, lng: 18.6670024 },
    { lat: 49.4345429, lng: 18.6671588 },
    { lat: 49.4352024, lng: 18.6672855 },
    { lat: 49.4364416, lng: 18.6677756 },
    { lat: 49.4368098, lng: 18.6677851 },
    { lat: 49.437385, lng: 18.6675759 },
    { lat: 49.4374107, lng: 18.6677003 },
    { lat: 49.4374202, lng: 18.6677505 },
    { lat: 49.4374415, lng: 18.6678541 },
    { lat: 49.4374468, lng: 18.6678704 },
    { lat: 49.4375492, lng: 18.6680154 },
    { lat: 49.4376142, lng: 18.668074 },
    { lat: 49.437722, lng: 18.6681726 },
    { lat: 49.4377373, lng: 18.6681863 },
    { lat: 49.4381956, lng: 18.6682158 },
    { lat: 49.438562, lng: 18.6682129 },
    { lat: 49.4387802, lng: 18.6681381 },
    { lat: 49.4388199, lng: 18.6680125 },
    { lat: 49.4388304, lng: 18.6679575 },
    { lat: 49.4388595, lng: 18.6678895 },
    { lat: 49.4388959, lng: 18.6677023 },
    { lat: 49.438932, lng: 18.6673822 },
    { lat: 49.4389455, lng: 18.6672936 },
    { lat: 49.4389721, lng: 18.6672646 },
    { lat: 49.438986, lng: 18.6671875 },
    { lat: 49.4389903, lng: 18.6671609 },
    { lat: 49.4390021, lng: 18.6670405 },
    { lat: 49.4390525, lng: 18.6670542 },
    { lat: 49.4392003, lng: 18.667057 },
    { lat: 49.4393198, lng: 18.667078 },
    { lat: 49.4394331, lng: 18.6670662 },
    { lat: 49.4395553, lng: 18.6670141 },
    { lat: 49.4396901, lng: 18.6669391 },
    { lat: 49.4399042, lng: 18.6668053 },
    { lat: 49.4400242, lng: 18.6667278 },
    { lat: 49.4401713, lng: 18.6665686 },
    { lat: 49.4403595, lng: 18.6664359 },
    { lat: 49.4404227, lng: 18.666394 },
    { lat: 49.4409006, lng: 18.6660685 },
    { lat: 49.4411251, lng: 18.6658791 },
    { lat: 49.4412797, lng: 18.6657722 },
    { lat: 49.4413958, lng: 18.6657068 },
    { lat: 49.4415709, lng: 18.6656496 },
    { lat: 49.4416425, lng: 18.6656392 },
    { lat: 49.4418674, lng: 18.6655853 },
    { lat: 49.4420557, lng: 18.6656707 },
    { lat: 49.4425234, lng: 18.6658596 },
    { lat: 49.4426643, lng: 18.6659425 },
    { lat: 49.4428529, lng: 18.666054 },
    { lat: 49.442986, lng: 18.6660777 },
    { lat: 49.443059, lng: 18.6660911 },
    { lat: 49.4436988, lng: 18.6658209 },
    { lat: 49.4437464, lng: 18.6657639 },
    { lat: 49.4439936, lng: 18.6656619 },
    { lat: 49.4440894, lng: 18.6657037 },
    { lat: 49.4445648, lng: 18.6656263 },
    { lat: 49.444626, lng: 18.6656141 },
    { lat: 49.4447651, lng: 18.6655765 },
    { lat: 49.4448639, lng: 18.6655494 },
    { lat: 49.4449891, lng: 18.665527 },
    { lat: 49.445139, lng: 18.6655056 },
    { lat: 49.4452891, lng: 18.6654841 },
    { lat: 49.4453383, lng: 18.6654769 },
    { lat: 49.4455327, lng: 18.6654206 },
    { lat: 49.4456335, lng: 18.6653664 },
    { lat: 49.4457953, lng: 18.6652692 },
    { lat: 49.4459501, lng: 18.6651272 },
    { lat: 49.4464634, lng: 18.6648944 },
    { lat: 49.4468308, lng: 18.664867 },
    { lat: 49.4471255, lng: 18.6648323 },
    { lat: 49.4471958, lng: 18.664821 },
    { lat: 49.4472247, lng: 18.6648124 },
    { lat: 49.4474015, lng: 18.6647028 },
    { lat: 49.4474125, lng: 18.6646953 },
    { lat: 49.4475215, lng: 18.6645575 },
    { lat: 49.4476003, lng: 18.6644071 },
    { lat: 49.4476563, lng: 18.6642508 },
    { lat: 49.4478046, lng: 18.6638091 },
    { lat: 49.4478321, lng: 18.6637351 },
    { lat: 49.4478676, lng: 18.6636361 },
    { lat: 49.4479832, lng: 18.6633054 },
    { lat: 49.4480048, lng: 18.663244 },
    { lat: 49.4480442, lng: 18.6631815 },
    { lat: 49.4481136, lng: 18.6630631 },
    { lat: 49.4481776, lng: 18.6630124 },
    { lat: 49.448237, lng: 18.6630054 },
    { lat: 49.4483466, lng: 18.6629689 },
    { lat: 49.4484029, lng: 18.6629482 },
    { lat: 49.448467, lng: 18.6629439 },
    { lat: 49.4485452, lng: 18.6629491 },
    { lat: 49.4485609, lng: 18.6629457 },
    { lat: 49.4486199, lng: 18.6629532 },
    { lat: 49.4486482, lng: 18.6629562 },
    { lat: 49.4487911, lng: 18.6629918 },
    { lat: 49.4490594, lng: 18.6631191 },
    { lat: 49.4491461, lng: 18.6631406 },
    { lat: 49.449211, lng: 18.6631525 },
    { lat: 49.4492372, lng: 18.6631572 },
    { lat: 49.4492664, lng: 18.6631547 },
    { lat: 49.4494324, lng: 18.6631403 },
    { lat: 49.4494338, lng: 18.663122 },
    { lat: 49.4495552, lng: 18.6630928 },
    { lat: 49.4496586, lng: 18.6630423 },
    { lat: 49.4498156, lng: 18.6630044 },
    { lat: 49.4499424, lng: 18.6629938 },
    { lat: 49.4501474, lng: 18.6629694 },
    { lat: 49.4501901, lng: 18.662962 },
    { lat: 49.4502879, lng: 18.6629578 },
    { lat: 49.4503434, lng: 18.6629608 },
    { lat: 49.4504677, lng: 18.6629711 },
    { lat: 49.4505264, lng: 18.6629689 },
    { lat: 49.4506073, lng: 18.6630498 },
    { lat: 49.4508059, lng: 18.6630749 },
    { lat: 49.4510392, lng: 18.6630724 },
    { lat: 49.4514149, lng: 18.6630984 },
    { lat: 49.4516352, lng: 18.6630417 },
    { lat: 49.4517281, lng: 18.6630295 },
    { lat: 49.451873, lng: 18.6629724 },
    { lat: 49.4518936, lng: 18.6629586 },
    { lat: 49.4521274, lng: 18.6627495 },
    { lat: 49.4522328, lng: 18.6626107 },
    { lat: 49.452333, lng: 18.6624598 },
    { lat: 49.4524423, lng: 18.6621787 },
    { lat: 49.4525092, lng: 18.6619907 },
    { lat: 49.452551, lng: 18.6618427 },
    { lat: 49.4525838, lng: 18.6616759 },
    { lat: 49.4525908, lng: 18.6616373 },
    { lat: 49.4526089, lng: 18.6613408 },
    { lat: 49.4526187, lng: 18.6612656 },
    { lat: 49.4526226, lng: 18.6610621 },
    { lat: 49.4526301, lng: 18.6608644 },
    { lat: 49.4527329, lng: 18.6605682 },
    { lat: 49.4528821, lng: 18.6604047 },
  ],
  Čadca: [
    { lat: 49.3962097, lng: 18.7978438 },
    { lat: 49.3963797, lng: 18.7978822 },
    { lat: 49.3967505, lng: 18.7979669 },
    { lat: 49.3970802, lng: 18.7980421 },
    { lat: 49.3974464, lng: 18.7981252 },
    { lat: 49.3975913, lng: 18.798158 },
    { lat: 49.3976918, lng: 18.7982233 },
    { lat: 49.3977781, lng: 18.7982798 },
    { lat: 49.3982459, lng: 18.7983897 },
    { lat: 49.3988749, lng: 18.7985384 },
    { lat: 49.3991076, lng: 18.7985931 },
    { lat: 49.3994446, lng: 18.7985672 },
    { lat: 49.400251, lng: 18.7985065 },
    { lat: 49.4009586, lng: 18.7984531 },
    { lat: 49.4015562, lng: 18.7984079 },
    { lat: 49.4017705, lng: 18.7985405 },
    { lat: 49.4021508, lng: 18.7989659 },
    { lat: 49.4025561, lng: 18.7995638 },
    { lat: 49.4029791, lng: 18.8005734 },
    { lat: 49.4031382, lng: 18.8008419 },
    { lat: 49.4034989, lng: 18.8014496 },
    { lat: 49.4036681, lng: 18.801432 },
    { lat: 49.4036726, lng: 18.8014318 },
    { lat: 49.4037692, lng: 18.801422 },
    { lat: 49.4043781, lng: 18.8013592 },
    { lat: 49.4045047, lng: 18.8014083 },
    { lat: 49.4049831, lng: 18.8015922 },
    { lat: 49.4050868, lng: 18.8016322 },
    { lat: 49.4052037, lng: 18.8015822 },
    { lat: 49.4052766, lng: 18.801551 },
    { lat: 49.405378, lng: 18.8015072 },
    { lat: 49.4054365, lng: 18.801482 },
    { lat: 49.4055093, lng: 18.8014511 },
    { lat: 49.4055137, lng: 18.8014495 },
    { lat: 49.4055987, lng: 18.8014149 },
    { lat: 49.4062437, lng: 18.8011541 },
    { lat: 49.4063752, lng: 18.8011012 },
    { lat: 49.4064469, lng: 18.8011364 },
    { lat: 49.406941, lng: 18.8013861 },
    { lat: 49.4069512, lng: 18.801386 },
    { lat: 49.4076586, lng: 18.8014144 },
    { lat: 49.4076909, lng: 18.8015586 },
    { lat: 49.4077308, lng: 18.8017432 },
    { lat: 49.4077851, lng: 18.80199 },
    { lat: 49.4078807, lng: 18.8024231 },
    { lat: 49.4079645, lng: 18.8028029 },
    { lat: 49.408029, lng: 18.8030924 },
    { lat: 49.4080546, lng: 18.8032117 },
    { lat: 49.4081118, lng: 18.8034691 },
    { lat: 49.4081412, lng: 18.8036042 },
    { lat: 49.4081527, lng: 18.8036546 },
    { lat: 49.4081557, lng: 18.8036665 },
    { lat: 49.4082095, lng: 18.8039214 },
    { lat: 49.4082427, lng: 18.804084 },
    { lat: 49.4083334, lng: 18.8045118 },
    { lat: 49.4083721, lng: 18.804694 },
    { lat: 49.4083697, lng: 18.8047348 },
    { lat: 49.4083676, lng: 18.8047796 },
    { lat: 49.4083637, lng: 18.8048384 },
    { lat: 49.4083552, lng: 18.8050042 },
    { lat: 49.40835, lng: 18.8050997 },
    { lat: 49.4083465, lng: 18.8051595 },
    { lat: 49.408342, lng: 18.8052473 },
    { lat: 49.4083379, lng: 18.8053227 },
    { lat: 49.4083071, lng: 18.805909 },
    { lat: 49.4082984, lng: 18.8060668 },
    { lat: 49.4082285, lng: 18.8068556 },
    { lat: 49.4082258, lng: 18.8069783 },
    { lat: 49.4082258, lng: 18.8070203 },
    { lat: 49.408226, lng: 18.8070228 },
    { lat: 49.4081996, lng: 18.8071893 },
    { lat: 49.4081664, lng: 18.807404 },
    { lat: 49.4081657, lng: 18.8074084 },
    { lat: 49.4081099, lng: 18.8077689 },
    { lat: 49.4080987, lng: 18.8078833 },
    { lat: 49.408065, lng: 18.8082154 },
    { lat: 49.4080577, lng: 18.8082879 },
    { lat: 49.4080363, lng: 18.8085291 },
    { lat: 49.4080328, lng: 18.8087269 },
    { lat: 49.4080857, lng: 18.8091405 },
    { lat: 49.4081528, lng: 18.8096545 },
    { lat: 49.4081615, lng: 18.8097246 },
    { lat: 49.4081681, lng: 18.8097776 },
    { lat: 49.4082261, lng: 18.8105096 },
    { lat: 49.4082697, lng: 18.8106593 },
    { lat: 49.40827, lng: 18.8106647 },
    { lat: 49.4084381, lng: 18.8111686 },
    { lat: 49.4084887, lng: 18.811362 },
    { lat: 49.4085103, lng: 18.8115437 },
    { lat: 49.4085517, lng: 18.8117328 },
    { lat: 49.4085634, lng: 18.8117625 },
    { lat: 49.4087193, lng: 18.8121434 },
    { lat: 49.4087619, lng: 18.8122454 },
    { lat: 49.4088751, lng: 18.8125225 },
    { lat: 49.4089642, lng: 18.8127384 },
    { lat: 49.4090341, lng: 18.8129175 },
    { lat: 49.409182, lng: 18.8132943 },
    { lat: 49.4091855, lng: 18.813404 },
    { lat: 49.4091955, lng: 18.8134002 },
    { lat: 49.4092978, lng: 18.81357 },
    { lat: 49.4093726, lng: 18.8137529 },
    { lat: 49.4094263, lng: 18.8139647 },
    { lat: 49.4095674, lng: 18.8144955 },
    { lat: 49.4095688, lng: 18.8144986 },
    { lat: 49.409587, lng: 18.8145677 },
    { lat: 49.4097347, lng: 18.8152904 },
    { lat: 49.4097517, lng: 18.81536 },
    { lat: 49.4099097, lng: 18.8161763 },
    { lat: 49.4099618, lng: 18.8163995 },
    { lat: 49.4099789, lng: 18.8164412 },
    { lat: 49.4100482, lng: 18.8166096 },
    { lat: 49.4101016, lng: 18.8169044 },
    { lat: 49.4101613, lng: 18.8172343 },
    { lat: 49.4101949, lng: 18.8174233 },
    { lat: 49.4101971, lng: 18.8174248 },
    { lat: 49.4104022, lng: 18.8176416 },
    { lat: 49.41067, lng: 18.8182693 },
    { lat: 49.4106712, lng: 18.8182699 },
    { lat: 49.4106844, lng: 18.818306 },
    { lat: 49.4108462, lng: 18.8187546 },
    { lat: 49.4108473, lng: 18.8187549 },
    { lat: 49.4109144, lng: 18.819182 },
    { lat: 49.410955, lng: 18.8195591 },
    { lat: 49.4110324, lng: 18.8197457 },
    { lat: 49.4110263, lng: 18.8202885 },
    { lat: 49.4111734, lng: 18.8206046 },
    { lat: 49.4112968, lng: 18.8209873 },
    { lat: 49.4114145, lng: 18.8217899 },
    { lat: 49.4116156, lng: 18.8228259 },
    { lat: 49.4116658, lng: 18.8232323 },
    { lat: 49.4117981, lng: 18.8238369 },
    { lat: 49.4118673, lng: 18.8241487 },
    { lat: 49.4119066, lng: 18.8243254 },
    { lat: 49.4120444, lng: 18.8248783 },
    { lat: 49.4120781, lng: 18.8254018 },
    { lat: 49.4120865, lng: 18.8255239 },
    { lat: 49.4121061, lng: 18.8258142 },
    { lat: 49.4121066, lng: 18.8258246 },
    { lat: 49.4121158, lng: 18.8259401 },
    { lat: 49.4122158, lng: 18.8262637 },
    { lat: 49.4123573, lng: 18.8264693 },
    { lat: 49.4124936, lng: 18.8269077 },
    { lat: 49.4125137, lng: 18.8269719 },
    { lat: 49.4126132, lng: 18.8272847 },
    { lat: 49.4128031, lng: 18.8277123 },
    { lat: 49.4128895, lng: 18.8280343 },
    { lat: 49.4129436, lng: 18.8285181 },
    { lat: 49.4130732, lng: 18.8290224 },
    { lat: 49.4130891, lng: 18.8293515 },
    { lat: 49.4132032, lng: 18.8297331 },
    { lat: 49.4133281, lng: 18.8300784 },
    { lat: 49.413324, lng: 18.8303711 },
    { lat: 49.4133392, lng: 18.8305159 },
    { lat: 49.4133735, lng: 18.8306364 },
    { lat: 49.4134989, lng: 18.8308153 },
    { lat: 49.4135436, lng: 18.8310924 },
    { lat: 49.4136173, lng: 18.8313453 },
    { lat: 49.4136436, lng: 18.8314809 },
    { lat: 49.4136901, lng: 18.8315995 },
    { lat: 49.4138691, lng: 18.8319259 },
    { lat: 49.4139653, lng: 18.8321301 },
    { lat: 49.4140345, lng: 18.8324462 },
    { lat: 49.4140532, lng: 18.8326312 },
    { lat: 49.4140599, lng: 18.8326518 },
    { lat: 49.4141003, lng: 18.8327805 },
    { lat: 49.4141112, lng: 18.8328824 },
    { lat: 49.4140881, lng: 18.8330268 },
    { lat: 49.4141041, lng: 18.8330531 },
    { lat: 49.414125, lng: 18.8332215 },
    { lat: 49.4141848, lng: 18.8333747 },
    { lat: 49.4142238, lng: 18.8334754 },
    { lat: 49.4142719, lng: 18.8335579 },
    { lat: 49.4142769, lng: 18.8335671 },
    { lat: 49.414346, lng: 18.8336859 },
    { lat: 49.4144683, lng: 18.8340485 },
    { lat: 49.4161296, lng: 18.8329364 },
    { lat: 49.4168172, lng: 18.8326012 },
    { lat: 49.4172763, lng: 18.8325198 },
    { lat: 49.4173953, lng: 18.8325323 },
    { lat: 49.4184693, lng: 18.8326746 },
    { lat: 49.4189677, lng: 18.8329626 },
    { lat: 49.419381, lng: 18.8333051 },
    { lat: 49.4198355, lng: 18.8341438 },
    { lat: 49.4199997, lng: 18.8343726 },
    { lat: 49.4200651, lng: 18.8346645 },
    { lat: 49.4202965, lng: 18.8354181 },
    { lat: 49.4207501, lng: 18.836152 },
    { lat: 49.421027, lng: 18.8363557 },
    { lat: 49.4216042, lng: 18.8367804 },
    { lat: 49.4223216, lng: 18.836747 },
    { lat: 49.4232967, lng: 18.8365224 },
    { lat: 49.423346, lng: 18.8366914 },
    { lat: 49.4234044, lng: 18.8368869 },
    { lat: 49.4235904, lng: 18.8372143 },
    { lat: 49.4238842, lng: 18.8368125 },
    { lat: 49.4241807, lng: 18.8366363 },
    { lat: 49.4244174, lng: 18.836368 },
    { lat: 49.4246328, lng: 18.8360397 },
    { lat: 49.4247614, lng: 18.8357551 },
    { lat: 49.4249579, lng: 18.8358039 },
    { lat: 49.424977, lng: 18.8362243 },
    { lat: 49.4251136, lng: 18.8365805 },
    { lat: 49.4254219, lng: 18.8370505 },
    { lat: 49.4255409, lng: 18.8375469 },
    { lat: 49.4256652, lng: 18.837926 },
    { lat: 49.4258544, lng: 18.8381883 },
    { lat: 49.4261604, lng: 18.8385969 },
    { lat: 49.426369, lng: 18.8390576 },
    { lat: 49.4264928, lng: 18.8394045 },
    { lat: 49.4271906, lng: 18.8400674 },
    { lat: 49.4275302, lng: 18.8404199 },
    { lat: 49.4281566, lng: 18.8412298 },
    { lat: 49.4282711, lng: 18.8416174 },
    { lat: 49.4288818, lng: 18.8438581 },
    { lat: 49.4300498, lng: 18.8443419 },
    { lat: 49.430565, lng: 18.844452 },
    { lat: 49.4306185, lng: 18.8444633 },
    { lat: 49.4310289, lng: 18.8445509 },
    { lat: 49.4327946, lng: 18.8445561 },
    { lat: 49.4333453, lng: 18.8442146 },
    { lat: 49.4338051, lng: 18.844164 },
    { lat: 49.4347429, lng: 18.8441806 },
    { lat: 49.4363309, lng: 18.8440173 },
    { lat: 49.4363633, lng: 18.8440138 },
    { lat: 49.4364858, lng: 18.8440012 },
    { lat: 49.436861, lng: 18.8441093 },
    { lat: 49.4380256, lng: 18.8446617 },
    { lat: 49.4384099, lng: 18.8449473 },
    { lat: 49.4401279, lng: 18.8466385 },
    { lat: 49.4408019, lng: 18.8475633 },
    { lat: 49.4411233, lng: 18.8479399 },
    { lat: 49.4414831, lng: 18.8483604 },
    { lat: 49.4418771, lng: 18.8485721 },
    { lat: 49.4431055, lng: 18.8485626 },
    { lat: 49.4437859, lng: 18.8485529 },
    { lat: 49.4441029, lng: 18.8485982 },
    { lat: 49.4445584, lng: 18.8485565 },
    { lat: 49.4451562, lng: 18.848651 },
    { lat: 49.4458844, lng: 18.848473 },
    { lat: 49.4471418, lng: 18.8489002 },
    { lat: 49.4471604, lng: 18.8488204 },
    { lat: 49.4475205, lng: 18.8493584 },
    { lat: 49.4475818, lng: 18.8492126 },
    { lat: 49.4478137, lng: 18.8492942 },
    { lat: 49.4479455, lng: 18.8492932 },
    { lat: 49.4481714, lng: 18.8492102 },
    { lat: 49.44859, lng: 18.8500144 },
    { lat: 49.4487565, lng: 18.850227 },
    { lat: 49.4490775, lng: 18.8507947 },
    { lat: 49.4492589, lng: 18.8510356 },
    { lat: 49.449493, lng: 18.851446 },
    { lat: 49.4496478, lng: 18.8518093 },
    { lat: 49.4497206, lng: 18.8520872 },
    { lat: 49.4497403, lng: 18.8522743 },
    { lat: 49.4498069, lng: 18.8525425 },
    { lat: 49.4498473, lng: 18.8528025 },
    { lat: 49.4499203, lng: 18.852997 },
    { lat: 49.4500825, lng: 18.8535682 },
    { lat: 49.4504848, lng: 18.8535159 },
    { lat: 49.4506354, lng: 18.8535558 },
    { lat: 49.450761, lng: 18.8538919 },
    { lat: 49.4510482, lng: 18.854131 },
    { lat: 49.4516414, lng: 18.8555078 },
    { lat: 49.4517366, lng: 18.856091 },
    { lat: 49.4519415, lng: 18.8564817 },
    { lat: 49.4522153, lng: 18.856899 },
    { lat: 49.4526612, lng: 18.8581515 },
    { lat: 49.4528192, lng: 18.8590171 },
    { lat: 49.4528015, lng: 18.8591573 },
    { lat: 49.4530591, lng: 18.8600059 },
    { lat: 49.4532887, lng: 18.8606183 },
    { lat: 49.4534157, lng: 18.8608104 },
    { lat: 49.4538764, lng: 18.8615114 },
    { lat: 49.4542081, lng: 18.8622675 },
    { lat: 49.4541961, lng: 18.862495 },
    { lat: 49.4541776, lng: 18.8628419 },
    { lat: 49.4542618, lng: 18.8637447 },
    { lat: 49.4541747, lng: 18.865837 },
    { lat: 49.4542846, lng: 18.8660467 },
    { lat: 49.454548, lng: 18.8664413 },
    { lat: 49.4551009, lng: 18.8669118 },
    { lat: 49.4552906, lng: 18.8672323 },
    { lat: 49.4557459, lng: 18.8681116 },
    { lat: 49.4563882, lng: 18.8677767 },
    { lat: 49.457537, lng: 18.8686386 },
    { lat: 49.4578869, lng: 18.8695132 },
    { lat: 49.4583633, lng: 18.8692362 },
    { lat: 49.4586838, lng: 18.8692442 },
    { lat: 49.4587664, lng: 18.8697573 },
    { lat: 49.4589384, lng: 18.8695957 },
    { lat: 49.4593692, lng: 18.8706807 },
    { lat: 49.4594973, lng: 18.8707194 },
    { lat: 49.4598906, lng: 18.8704954 },
    { lat: 49.4600039, lng: 18.8708602 },
    { lat: 49.4600261, lng: 18.8711859 },
    { lat: 49.4603051, lng: 18.8715933 },
    { lat: 49.4609516, lng: 18.8717359 },
    { lat: 49.4610869, lng: 18.8716697 },
    { lat: 49.461478, lng: 18.8711383 },
    { lat: 49.462047, lng: 18.8705087 },
    { lat: 49.4621628, lng: 18.8705927 },
    { lat: 49.4623675, lng: 18.8705403 },
    { lat: 49.4625672, lng: 18.8703192 },
    { lat: 49.4623829, lng: 18.8697567 },
    { lat: 49.4625302, lng: 18.8688387 },
    { lat: 49.4626517, lng: 18.8686371 },
    { lat: 49.4630295, lng: 18.8688362 },
    { lat: 49.4633521, lng: 18.8699498 },
    { lat: 49.4634733, lng: 18.8701346 },
    { lat: 49.4636846, lng: 18.870234 },
    { lat: 49.464011, lng: 18.8705124 },
    { lat: 49.4641603, lng: 18.870703 },
    { lat: 49.4642497, lng: 18.8709746 },
    { lat: 49.4643246, lng: 18.871094 },
    { lat: 49.4643554, lng: 18.8711436 },
    { lat: 49.4644767, lng: 18.871336 },
    { lat: 49.4646565, lng: 18.8715454 },
    { lat: 49.4646918, lng: 18.8719286 },
    { lat: 49.4645487, lng: 18.8721291 },
    { lat: 49.4641901, lng: 18.8723515 },
    { lat: 49.4639955, lng: 18.8722697 },
    { lat: 49.4638188, lng: 18.8724967 },
    { lat: 49.4635783, lng: 18.8726005 },
    { lat: 49.4633335, lng: 18.8728864 },
    { lat: 49.463256, lng: 18.8732672 },
    { lat: 49.4630368, lng: 18.8735461 },
    { lat: 49.4626506, lng: 18.8732461 },
    { lat: 49.4625464, lng: 18.8735471 },
    { lat: 49.462412, lng: 18.8735661 },
    { lat: 49.4623746, lng: 18.8736721 },
    { lat: 49.4623588, lng: 18.8737153 },
    { lat: 49.4623145, lng: 18.8738427 },
    { lat: 49.4624619, lng: 18.8743492 },
    { lat: 49.4621566, lng: 18.8750163 },
    { lat: 49.4620776, lng: 18.8753723 },
    { lat: 49.4622671, lng: 18.8755732 },
    { lat: 49.4624451, lng: 18.8758593 },
    { lat: 49.4635259, lng: 18.8763378 },
    { lat: 49.4634811, lng: 18.8765204 },
    { lat: 49.4633936, lng: 18.8765508 },
    { lat: 49.4632917, lng: 18.8769864 },
    { lat: 49.4638725, lng: 18.8775465 },
    { lat: 49.464085, lng: 18.8776015 },
    { lat: 49.4645553, lng: 18.8780284 },
    { lat: 49.4650849, lng: 18.8779332 },
    { lat: 49.4651068, lng: 18.8777008 },
    { lat: 49.4652355, lng: 18.8776579 },
    { lat: 49.4652705, lng: 18.877927 },
    { lat: 49.4657164, lng: 18.8773387 },
    { lat: 49.465947, lng: 18.8776612 },
    { lat: 49.4659423, lng: 18.8784103 },
    { lat: 49.4657513, lng: 18.8792026 },
    { lat: 49.4655538, lng: 18.8793984 },
    { lat: 49.4655556, lng: 18.879611 },
    { lat: 49.4657751, lng: 18.8803785 },
    { lat: 49.465932, lng: 18.8806961 },
    { lat: 49.466005, lng: 18.8812456 },
    { lat: 49.465876, lng: 18.8813937 },
    { lat: 49.4664652, lng: 18.8822516 },
    { lat: 49.4668161, lng: 18.882368 },
    { lat: 49.4668963, lng: 18.8823236 },
    { lat: 49.4671249, lng: 18.8814768 },
    { lat: 49.4680044, lng: 18.8822112 },
    { lat: 49.4682629, lng: 18.8823521 },
    { lat: 49.4684603, lng: 18.8824891 },
    { lat: 49.4687035, lng: 18.8823937 },
    { lat: 49.4688731, lng: 18.88258 },
    { lat: 49.4692394, lng: 18.8824899 },
    { lat: 49.4694013, lng: 18.8824593 },
    { lat: 49.4695843, lng: 18.882801 },
    { lat: 49.4695309, lng: 18.8830158 },
    { lat: 49.469976, lng: 18.8833414 },
    { lat: 49.4700383, lng: 18.883061 },
    { lat: 49.4697772, lng: 18.8826217 },
    { lat: 49.4703298, lng: 18.8817158 },
    { lat: 49.4702498, lng: 18.8812523 },
    { lat: 49.4707532, lng: 18.8800005 },
    { lat: 49.4708243, lng: 18.880087 },
    { lat: 49.4709428, lng: 18.8798014 },
    { lat: 49.4710676, lng: 18.8795718 },
    { lat: 49.4711883, lng: 18.8792877 },
    { lat: 49.4712805, lng: 18.8791491 },
    { lat: 49.4712, lng: 18.8784282 },
    { lat: 49.4712656, lng: 18.8784084 },
    { lat: 49.4714777, lng: 18.8782827 },
    { lat: 49.4717705, lng: 18.8781164 },
    { lat: 49.4716569, lng: 18.8766792 },
    { lat: 49.4716468, lng: 18.8764814 },
    { lat: 49.4717745, lng: 18.8755595 },
    { lat: 49.4717838, lng: 18.8750306 },
    { lat: 49.4717438, lng: 18.8745543 },
    { lat: 49.4716631, lng: 18.8739426 },
    { lat: 49.4721232, lng: 18.8736113 },
    { lat: 49.472465, lng: 18.8733921 },
    { lat: 49.4734399, lng: 18.8730086 },
    { lat: 49.4739681, lng: 18.8728228 },
    { lat: 49.4744546, lng: 18.8722353 },
    { lat: 49.4746217, lng: 18.8723052 },
    { lat: 49.4747136, lng: 18.8723438 },
    { lat: 49.475632, lng: 18.8722894 },
    { lat: 49.4759992, lng: 18.872394 },
    { lat: 49.4764291, lng: 18.8723788 },
    { lat: 49.4774791, lng: 18.8726091 },
    { lat: 49.477895, lng: 18.8727414 },
    { lat: 49.4788954, lng: 18.872726 },
    { lat: 49.4793322, lng: 18.8726897 },
    { lat: 49.4797137, lng: 18.8728568 },
    { lat: 49.4801488, lng: 18.8724331 },
    { lat: 49.480494, lng: 18.872792 },
    { lat: 49.4807453, lng: 18.8727674 },
    { lat: 49.4797098, lng: 18.8712921 },
    { lat: 49.4795537, lng: 18.8702847 },
    { lat: 49.4791548, lng: 18.8692466 },
    { lat: 49.4788888, lng: 18.8669439 },
    { lat: 49.4791988, lng: 18.8652349 },
    { lat: 49.479638, lng: 18.8642526 },
    { lat: 49.4792794, lng: 18.8632536 },
    { lat: 49.4789145, lng: 18.8624856 },
    { lat: 49.4791172, lng: 18.8622582 },
    { lat: 49.4794039, lng: 18.8619363 },
    { lat: 49.4789141, lng: 18.8609052 },
    { lat: 49.4784515, lng: 18.8596613 },
    { lat: 49.4778446, lng: 18.8578165 },
    { lat: 49.4778271, lng: 18.8577606 },
    { lat: 49.4776376, lng: 18.8571604 },
    { lat: 49.4772009, lng: 18.8556064 },
    { lat: 49.4768213, lng: 18.8536416 },
    { lat: 49.476674, lng: 18.8535381 },
    { lat: 49.476492, lng: 18.8535314 },
    { lat: 49.4766983, lng: 18.852912 },
    { lat: 49.476623, lng: 18.8523203 },
    { lat: 49.4768345, lng: 18.8517561 },
    { lat: 49.4774916, lng: 18.851601 },
    { lat: 49.4774314, lng: 18.8496649 },
    { lat: 49.4773314, lng: 18.8478634 },
    { lat: 49.4774579, lng: 18.8470509 },
    { lat: 49.4773854, lng: 18.8466028 },
    { lat: 49.4772342, lng: 18.8462697 },
    { lat: 49.4774418, lng: 18.8460696 },
    { lat: 49.4775721, lng: 18.8455122 },
    { lat: 49.4775514, lng: 18.8448846 },
    { lat: 49.4775591, lng: 18.8442405 },
    { lat: 49.4771357, lng: 18.8434749 },
    { lat: 49.4772749, lng: 18.843134 },
    { lat: 49.4770435, lng: 18.8425114 },
    { lat: 49.4765101, lng: 18.8417776 },
    { lat: 49.4760838, lng: 18.8411829 },
    { lat: 49.4754675, lng: 18.8407683 },
    { lat: 49.4746715, lng: 18.8405045 },
    { lat: 49.4745168, lng: 18.8404763 },
    { lat: 49.4744681, lng: 18.8405184 },
    { lat: 49.4743374, lng: 18.8403634 },
    { lat: 49.4739244, lng: 18.840225 },
    { lat: 49.4737898, lng: 18.840438 },
    { lat: 49.473649, lng: 18.8410999 },
    { lat: 49.4734023, lng: 18.8413254 },
    { lat: 49.4726366, lng: 18.8414313 },
    { lat: 49.4724094, lng: 18.8414184 },
    { lat: 49.4724048, lng: 18.8413519 },
    { lat: 49.4724602, lng: 18.8404516 },
    { lat: 49.471925, lng: 18.8403025 },
    { lat: 49.4719228, lng: 18.8397314 },
    { lat: 49.4720307, lng: 18.8397025 },
    { lat: 49.4719915, lng: 18.8390006 },
    { lat: 49.4719542, lng: 18.8383366 },
    { lat: 49.4719704, lng: 18.8381679 },
    { lat: 49.4720461, lng: 18.8373801 },
    { lat: 49.4720895, lng: 18.8369264 },
    { lat: 49.4718959, lng: 18.8359622 },
    { lat: 49.471606, lng: 18.836137 },
    { lat: 49.4714709, lng: 18.8359633 },
    { lat: 49.47139, lng: 18.8362972 },
    { lat: 49.4705927, lng: 18.8350613 },
    { lat: 49.4706008, lng: 18.8320481 },
    { lat: 49.470252, lng: 18.8297063 },
    { lat: 49.470151, lng: 18.8290061 },
    { lat: 49.4692762, lng: 18.826346 },
    { lat: 49.4695708, lng: 18.8258025 },
    { lat: 49.4693358, lng: 18.8253745 },
    { lat: 49.4693171, lng: 18.8253416 },
    { lat: 49.4692686, lng: 18.8252528 },
    { lat: 49.4692602, lng: 18.8251766 },
    { lat: 49.4693797, lng: 18.8248668 },
    { lat: 49.4693137, lng: 18.824833 },
    { lat: 49.4695071, lng: 18.822822 },
    { lat: 49.4694721, lng: 18.821742 },
    { lat: 49.4693578, lng: 18.8205906 },
    { lat: 49.469204, lng: 18.819923 },
    { lat: 49.4690149, lng: 18.8191805 },
    { lat: 49.4686814, lng: 18.818329 },
    { lat: 49.4681383, lng: 18.8179393 },
    { lat: 49.4681343, lng: 18.8163469 },
    { lat: 49.4679756, lng: 18.8148382 },
    { lat: 49.4680893, lng: 18.8130307 },
    { lat: 49.4671532, lng: 18.8105861 },
    { lat: 49.4667165, lng: 18.81023 },
    { lat: 49.4662261, lng: 18.8100577 },
    { lat: 49.4658549, lng: 18.8091278 },
    { lat: 49.465344, lng: 18.805659 },
    { lat: 49.4649358, lng: 18.8046039 },
    { lat: 49.4644604, lng: 18.8034319 },
    { lat: 49.4643705, lng: 18.8034562 },
    { lat: 49.4642608, lng: 18.8029033 },
    { lat: 49.4644037, lng: 18.8022838 },
    { lat: 49.4644768, lng: 18.8016657 },
    { lat: 49.4646222, lng: 18.8011844 },
    { lat: 49.4645102, lng: 18.8007394 },
    { lat: 49.4645419, lng: 18.8006754 },
    { lat: 49.464601, lng: 18.8005537 },
    { lat: 49.4641933, lng: 18.7988973 },
    { lat: 49.4642854, lng: 18.7986487 },
    { lat: 49.4649806, lng: 18.7967784 },
    { lat: 49.4650607, lng: 18.7966041 },
    { lat: 49.4654186, lng: 18.7953428 },
    { lat: 49.4657623, lng: 18.7937634 },
    { lat: 49.4659713, lng: 18.7936847 },
    { lat: 49.4660387, lng: 18.7930019 },
    { lat: 49.4659077, lng: 18.7914232 },
    { lat: 49.4659198, lng: 18.7913093 },
    { lat: 49.4659259, lng: 18.7912481 },
    { lat: 49.4659477, lng: 18.7911044 },
    { lat: 49.4659711, lng: 18.7909703 },
    { lat: 49.4659774, lng: 18.7908949 },
    { lat: 49.4659842, lng: 18.7908078 },
    { lat: 49.4659923, lng: 18.7906807 },
    { lat: 49.4660021, lng: 18.7905859 },
    { lat: 49.4660153, lng: 18.790472 },
    { lat: 49.466038, lng: 18.7903612 },
    { lat: 49.4660582, lng: 18.7902946 },
    { lat: 49.4661189, lng: 18.7902901 },
    { lat: 49.4661739, lng: 18.7901344 },
    { lat: 49.4662044, lng: 18.7900702 },
    { lat: 49.4662259, lng: 18.7899418 },
    { lat: 49.4662412, lng: 18.7898442 },
    { lat: 49.4662485, lng: 18.7897897 },
    { lat: 49.4662621, lng: 18.7896811 },
    { lat: 49.4662726, lng: 18.7896001 },
    { lat: 49.4662998, lng: 18.7893811 },
    { lat: 49.4663074, lng: 18.789291 },
    { lat: 49.4663095, lng: 18.7891994 },
    { lat: 49.4663122, lng: 18.7891407 },
    { lat: 49.4663134, lng: 18.7890122 },
    { lat: 49.4663145, lng: 18.7889704 },
    { lat: 49.466323, lng: 18.7889157 },
    { lat: 49.4663395, lng: 18.7888184 },
    { lat: 49.4663509, lng: 18.7887547 },
    { lat: 49.4663067, lng: 18.7885751 },
    { lat: 49.4663321, lng: 18.7884767 },
    { lat: 49.4663285, lng: 18.7883659 },
    { lat: 49.4663403, lng: 18.7882473 },
    { lat: 49.4663371, lng: 18.7880288 },
    { lat: 49.4663505, lng: 18.7879402 },
    { lat: 49.4663624, lng: 18.7878187 },
    { lat: 49.4663859, lng: 18.7876415 },
    { lat: 49.4664284, lng: 18.7871745 },
    { lat: 49.4665516, lng: 18.7858487 },
    { lat: 49.4665601, lng: 18.7857527 },
    { lat: 49.4665695, lng: 18.7855409 },
    { lat: 49.4665843, lng: 18.7852129 },
    { lat: 49.466591, lng: 18.7850602 },
    { lat: 49.4666326, lng: 18.7844925 },
    { lat: 49.4666158, lng: 18.7844909 },
    { lat: 49.4666214, lng: 18.7843844 },
    { lat: 49.4666343, lng: 18.7841312 },
    { lat: 49.4666369, lng: 18.784095 },
    { lat: 49.4666397, lng: 18.7840389 },
    { lat: 49.4666431, lng: 18.7839714 },
    { lat: 49.4666696, lng: 18.7837441 },
    { lat: 49.4666745, lng: 18.7836884 },
    { lat: 49.466705, lng: 18.7833581 },
    { lat: 49.4666459, lng: 18.7825652 },
    { lat: 49.4666532, lng: 18.7825114 },
    { lat: 49.4668492, lng: 18.7815654 },
    { lat: 49.4669742, lng: 18.7798366 },
    { lat: 49.4669362, lng: 18.7790821 },
    { lat: 49.4669059, lng: 18.7787976 },
    { lat: 49.4669953, lng: 18.7783463 },
    { lat: 49.4672746, lng: 18.7779262 },
    { lat: 49.467372, lng: 18.7776084 },
    { lat: 49.4675544, lng: 18.7775391 },
    { lat: 49.4675668, lng: 18.7773168 },
    { lat: 49.4676947, lng: 18.7758093 },
    { lat: 49.4677539, lng: 18.7751121 },
    { lat: 49.46806, lng: 18.7744248 },
    { lat: 49.4687039, lng: 18.7739687 },
    { lat: 49.4692754, lng: 18.7736628 },
    { lat: 49.4693977, lng: 18.7730171 },
    { lat: 49.4693433, lng: 18.7724897 },
    { lat: 49.4692959, lng: 18.771331 },
    { lat: 49.4691733, lng: 18.770879 },
    { lat: 49.4691409, lng: 18.7696445 },
    { lat: 49.4690901, lng: 18.7689474 },
    { lat: 49.4692978, lng: 18.7677496 },
    { lat: 49.4695341, lng: 18.7663907 },
    { lat: 49.470279, lng: 18.766015 },
    { lat: 49.4702779, lng: 18.7658835 },
    { lat: 49.470807, lng: 18.7661062 },
    { lat: 49.4713541, lng: 18.7667019 },
    { lat: 49.4717666, lng: 18.7667422 },
    { lat: 49.4732618, lng: 18.7650862 },
    { lat: 49.4741174, lng: 18.7641477 },
    { lat: 49.4746449, lng: 18.7636863 },
    { lat: 49.4753637, lng: 18.7628246 },
    { lat: 49.4755109, lng: 18.7627979 },
    { lat: 49.4760915, lng: 18.7614443 },
    { lat: 49.4769865, lng: 18.75983 },
    { lat: 49.4778275, lng: 18.7583448 },
    { lat: 49.47898, lng: 18.7568893 },
    { lat: 49.4792733, lng: 18.7565913 },
    { lat: 49.4812659, lng: 18.7545752 },
    { lat: 49.481661, lng: 18.7536914 },
    { lat: 49.4818417, lng: 18.7533891 },
    { lat: 49.4823149, lng: 18.7535572 },
    { lat: 49.4829107, lng: 18.7536563 },
    { lat: 49.4834701, lng: 18.7531582 },
    { lat: 49.4839231, lng: 18.752977 },
    { lat: 49.4850552, lng: 18.754087 },
    { lat: 49.4850587, lng: 18.7542008 },
    { lat: 49.4852308, lng: 18.7542345 },
    { lat: 49.4857406, lng: 18.7548069 },
    { lat: 49.4861056, lng: 18.7552353 },
    { lat: 49.4863921, lng: 18.7554752 },
    { lat: 49.4870364, lng: 18.7556798 },
    { lat: 49.4870497, lng: 18.7556306 },
    { lat: 49.4874364, lng: 18.7554376 },
    { lat: 49.4877718, lng: 18.7552954 },
    { lat: 49.487963, lng: 18.7552014 },
    { lat: 49.4880896, lng: 18.7550956 },
    { lat: 49.4882435, lng: 18.7545858 },
    { lat: 49.4883587, lng: 18.7546112 },
    { lat: 49.488403, lng: 18.754607 },
    { lat: 49.4883823, lng: 18.7544821 },
    { lat: 49.488453, lng: 18.754218 },
    { lat: 49.488647, lng: 18.753728 },
    { lat: 49.488809, lng: 18.753331 },
    { lat: 49.489165, lng: 18.75257 },
    { lat: 49.489582, lng: 18.751464 },
    { lat: 49.490037, lng: 18.750447 },
    { lat: 49.490014, lng: 18.750427 },
    { lat: 49.490072, lng: 18.750219 },
    { lat: 49.490094, lng: 18.750134 },
    { lat: 49.490174, lng: 18.749791 },
    { lat: 49.490197, lng: 18.749672 },
    { lat: 49.490194, lng: 18.74958 },
    { lat: 49.490176, lng: 18.749492 },
    { lat: 49.490129, lng: 18.749366 },
    { lat: 49.489897, lng: 18.748856 },
    { lat: 49.489838, lng: 18.748741 },
    { lat: 49.489744, lng: 18.748582 },
    { lat: 49.489596, lng: 18.748368 },
    { lat: 49.489522, lng: 18.748258 },
    { lat: 49.489465, lng: 18.748157 },
    { lat: 49.489412, lng: 18.74803 },
    { lat: 49.489366, lng: 18.747892 },
    { lat: 49.489324, lng: 18.747722 },
    { lat: 49.489304, lng: 18.747572 },
    { lat: 49.489255, lng: 18.747482 },
    { lat: 49.489274, lng: 18.74746 },
    { lat: 49.488892, lng: 18.74641 },
    { lat: 49.488766, lng: 18.745375 },
    { lat: 49.488691, lng: 18.744758 },
    { lat: 49.488676, lng: 18.7439 },
    { lat: 49.488764, lng: 18.743038 },
    { lat: 49.488821, lng: 18.742482 },
    { lat: 49.488897, lng: 18.742034 },
    { lat: 49.489023, lng: 18.741289 },
    { lat: 49.4890499, lng: 18.7410878 },
    { lat: 49.489143, lng: 18.74039 },
    { lat: 49.489425, lng: 18.739808 },
    { lat: 49.489522, lng: 18.739609 },
    { lat: 49.489598, lng: 18.739348 },
    { lat: 49.489719, lng: 18.738939 },
    { lat: 49.4897886, lng: 18.7386499 },
    { lat: 49.489832, lng: 18.73847 },
    { lat: 49.4900131, lng: 18.7375455 },
    { lat: 49.4900465, lng: 18.7373474 },
    { lat: 49.490058, lng: 18.737002 },
    { lat: 49.490187, lng: 18.735948 },
    { lat: 49.490218, lng: 18.73551 },
    { lat: 49.4903, lng: 18.735218 },
    { lat: 49.490389, lng: 18.734908 },
    { lat: 49.490641, lng: 18.734676 },
    { lat: 49.490763, lng: 18.734112 },
    { lat: 49.491089, lng: 18.733583 },
    { lat: 49.491391, lng: 18.733175 },
    { lat: 49.492263, lng: 18.732585 },
    { lat: 49.492568, lng: 18.732338 },
    { lat: 49.4928479, lng: 18.7320136 },
    { lat: 49.492945, lng: 18.731903 },
    { lat: 49.493086, lng: 18.731741 },
    { lat: 49.493639, lng: 18.73122 },
    { lat: 49.493936, lng: 18.73094 },
    { lat: 49.494201, lng: 18.730636 },
    { lat: 49.494704, lng: 18.7299063 },
    { lat: 49.494751, lng: 18.729838 },
    { lat: 49.495159, lng: 18.72904 },
    { lat: 49.495225, lng: 18.728573 },
    { lat: 49.495285, lng: 18.727047 },
    { lat: 49.495456, lng: 18.726483 },
    { lat: 49.495782, lng: 18.725586 },
    { lat: 49.495961, lng: 18.725045 },
    { lat: 49.496098, lng: 18.72469 },
    { lat: 49.49619, lng: 18.724452 },
    { lat: 49.496458, lng: 18.724113 },
    { lat: 49.496723, lng: 18.723762 },
    { lat: 49.497158, lng: 18.723279 },
    { lat: 49.497319, lng: 18.7231 },
    { lat: 49.49767, lng: 18.722632 },
    { lat: 49.498, lng: 18.722149 },
    { lat: 49.498251, lng: 18.721783 },
    { lat: 49.4989, lng: 18.721093 },
    { lat: 49.4991, lng: 18.72088 },
    { lat: 49.499656, lng: 18.72061 },
    { lat: 49.500101, lng: 18.720392 },
    { lat: 49.50004, lng: 18.720065 },
    { lat: 49.499818, lng: 18.718873 },
    { lat: 49.499848, lng: 18.717763 },
    { lat: 49.499821, lng: 18.716853 },
    { lat: 49.499779, lng: 18.71592 },
    { lat: 49.499779, lng: 18.715598 },
    { lat: 49.49978, lng: 18.71521 },
    { lat: 49.499784, lng: 18.715168 },
    { lat: 49.499871, lng: 18.715018 },
    { lat: 49.500039, lng: 18.714852 },
    { lat: 49.50026, lng: 18.714655 },
    { lat: 49.500545, lng: 18.714329 },
    { lat: 49.50076, lng: 18.714043 },
    { lat: 49.501079, lng: 18.713656 },
    { lat: 49.501318, lng: 18.713345 },
    { lat: 49.501443, lng: 18.713207 },
    { lat: 49.50161, lng: 18.71301 },
    { lat: 49.4992179, lng: 18.7133309 },
    { lat: 49.4984012, lng: 18.7141024 },
    { lat: 49.4980418, lng: 18.7141691 },
    { lat: 49.4978415, lng: 18.7142895 },
    { lat: 49.497506, lng: 18.714945 },
    { lat: 49.4971271, lng: 18.7149654 },
    { lat: 49.4969763, lng: 18.7149731 },
    { lat: 49.4968621, lng: 18.714986 },
    { lat: 49.4967426, lng: 18.7150319 },
    { lat: 49.4966625, lng: 18.7150733 },
    { lat: 49.4964876, lng: 18.7151573 },
    { lat: 49.4964081, lng: 18.7151917 },
    { lat: 49.4963176, lng: 18.7152286 },
    { lat: 49.4960209, lng: 18.7153115 },
    { lat: 49.4956479, lng: 18.7154426 },
    { lat: 49.4955174, lng: 18.7160122 },
    { lat: 49.4947431, lng: 18.7169051 },
    { lat: 49.4930404, lng: 18.7188685 },
    { lat: 49.4929037, lng: 18.7189705 },
    { lat: 49.4927889, lng: 18.7190612 },
    { lat: 49.4926223, lng: 18.7193294 },
    { lat: 49.4925417, lng: 18.7194456 },
    { lat: 49.4925064, lng: 18.7195486 },
    { lat: 49.4924125, lng: 18.7196504 },
    { lat: 49.4923509, lng: 18.7197889 },
    { lat: 49.4923186, lng: 18.7198615 },
    { lat: 49.4924083, lng: 18.7198747 },
    { lat: 49.4923878, lng: 18.719956 },
    { lat: 49.4922716, lng: 18.7200221 },
    { lat: 49.4921728, lng: 18.7200698 },
    { lat: 49.4921131, lng: 18.7200728 },
    { lat: 49.4920895, lng: 18.7200089 },
    { lat: 49.4920133, lng: 18.7200201 },
    { lat: 49.4919633, lng: 18.7200995 },
    { lat: 49.4918949, lng: 18.7200836 },
    { lat: 49.4918756, lng: 18.7200385 },
    { lat: 49.4918957, lng: 18.7199889 },
    { lat: 49.4918604, lng: 18.7199373 },
    { lat: 49.4918247, lng: 18.7198387 },
    { lat: 49.4917632, lng: 18.7198735 },
    { lat: 49.4917103, lng: 18.7198502 },
    { lat: 49.4916853, lng: 18.7199327 },
    { lat: 49.4915866, lng: 18.7199202 },
    { lat: 49.4915913, lng: 18.7198155 },
    { lat: 49.4915203, lng: 18.7197093 },
    { lat: 49.4914472, lng: 18.7197581 },
    { lat: 49.491368, lng: 18.7198154 },
    { lat: 49.4913317, lng: 18.719768 },
    { lat: 49.491221, lng: 18.719766 },
    { lat: 49.4911339, lng: 18.719826 },
    { lat: 49.4910211, lng: 18.7197612 },
    { lat: 49.4909852, lng: 18.7196981 },
    { lat: 49.491016, lng: 18.7195768 },
    { lat: 49.4909676, lng: 18.7195552 },
    { lat: 49.4909457, lng: 18.7196108 },
    { lat: 49.4908879, lng: 18.7195621 },
    { lat: 49.4907769, lng: 18.7195732 },
    { lat: 49.490671, lng: 18.7194679 },
    { lat: 49.4905714, lng: 18.7194824 },
    { lat: 49.4905217, lng: 18.7194137 },
    { lat: 49.4904807, lng: 18.7193205 },
    { lat: 49.4904218, lng: 18.7193371 },
    { lat: 49.4904114, lng: 18.719247 },
    { lat: 49.4903347, lng: 18.7192438 },
    { lat: 49.4903255, lng: 18.7192434 },
    { lat: 49.4902215, lng: 18.7193016 },
    { lat: 49.4902071, lng: 18.719266 },
    { lat: 49.4902059, lng: 18.7192621 },
    { lat: 49.4900553, lng: 18.719314 },
    { lat: 49.4900339, lng: 18.7192709 },
    { lat: 49.4899483, lng: 18.7193373 },
    { lat: 49.4899361, lng: 18.7192982 },
    { lat: 49.4899404, lng: 18.7192305 },
    { lat: 49.4898605, lng: 18.7193212 },
    { lat: 49.4898217, lng: 18.7192691 },
    { lat: 49.4897973, lng: 18.7192318 },
    { lat: 49.48985, lng: 18.7191825 },
    { lat: 49.4898267, lng: 18.7191252 },
    { lat: 49.4897227, lng: 18.7190342 },
    { lat: 49.4896555, lng: 18.7190635 },
    { lat: 49.4894977, lng: 18.7191322 },
    { lat: 49.4894432, lng: 18.7193381 },
    { lat: 49.4893848, lng: 18.7195589 },
    { lat: 49.4892934, lng: 18.7195839 },
    { lat: 49.4891955, lng: 18.7195861 },
    { lat: 49.4891872, lng: 18.7197311 },
    { lat: 49.4892585, lng: 18.7197749 },
    { lat: 49.4894251, lng: 18.7197715 },
    { lat: 49.4895242, lng: 18.7197677 },
    { lat: 49.4895327, lng: 18.7198903 },
    { lat: 49.4894061, lng: 18.7200354 },
    { lat: 49.4892696, lng: 18.7201417 },
    { lat: 49.4891987, lng: 18.7202096 },
    { lat: 49.4890901, lng: 18.7202646 },
    { lat: 49.4891032, lng: 18.7203435 },
    { lat: 49.4891666, lng: 18.7203919 },
    { lat: 49.4890329, lng: 18.7205088 },
    { lat: 49.4889231, lng: 18.7204784 },
    { lat: 49.4888512, lng: 18.7204002 },
    { lat: 49.488733, lng: 18.7204042 },
    { lat: 49.4884379, lng: 18.7205177 },
    { lat: 49.488479, lng: 18.7207219 },
    { lat: 49.4884949, lng: 18.7210265 },
    { lat: 49.4884423, lng: 18.7212052 },
    { lat: 49.4882964, lng: 18.7215662 },
    { lat: 49.4882071, lng: 18.721669 },
    { lat: 49.4878886, lng: 18.7220515 },
    { lat: 49.4877906, lng: 18.7227075 },
    { lat: 49.4877818, lng: 18.7227661 },
    { lat: 49.4877558, lng: 18.7231349 },
    { lat: 49.4877363, lng: 18.7232809 },
    { lat: 49.4877363, lng: 18.7234757 },
    { lat: 49.4877179, lng: 18.7235769 },
    { lat: 49.4877053, lng: 18.723811 },
    { lat: 49.4876671, lng: 18.7240082 },
    { lat: 49.4875046, lng: 18.7239176 },
    { lat: 49.4875027, lng: 18.7237546 },
    { lat: 49.4873675, lng: 18.7237984 },
    { lat: 49.4871704, lng: 18.7241472 },
    { lat: 49.4872264, lng: 18.7242281 },
    { lat: 49.48714, lng: 18.724412 },
    { lat: 49.4870561, lng: 18.7243795 },
    { lat: 49.4869509, lng: 18.7245466 },
    { lat: 49.4869228, lng: 18.7246774 },
    { lat: 49.4869994, lng: 18.724743 },
    { lat: 49.4869458, lng: 18.7249658 },
    { lat: 49.487049, lng: 18.7251101 },
    { lat: 49.4870908, lng: 18.7252403 },
    { lat: 49.4869733, lng: 18.7254067 },
    { lat: 49.4868435, lng: 18.7254512 },
    { lat: 49.4869115, lng: 18.7257178 },
    { lat: 49.4868553, lng: 18.7258041 },
    { lat: 49.486731, lng: 18.7257128 },
    { lat: 49.4865378, lng: 18.7257517 },
    { lat: 49.4863511, lng: 18.7258221 },
    { lat: 49.4864045, lng: 18.7261141 },
    { lat: 49.4862982, lng: 18.7262359 },
    { lat: 49.4860849, lng: 18.7262773 },
    { lat: 49.4859905, lng: 18.7261932 },
    { lat: 49.4858733, lng: 18.7264338 },
    { lat: 49.4858883, lng: 18.7264857 },
    { lat: 49.4858251, lng: 18.7265928 },
    { lat: 49.485688, lng: 18.7265142 },
    { lat: 49.4856043, lng: 18.7267048 },
    { lat: 49.4854401, lng: 18.7266687 },
    { lat: 49.4854056, lng: 18.7267411 },
    { lat: 49.4854581, lng: 18.7267956 },
    { lat: 49.4854641, lng: 18.7270201 },
    { lat: 49.4854229, lng: 18.727032 },
    { lat: 49.485383, lng: 18.727001 },
    { lat: 49.4851841, lng: 18.7270559 },
    { lat: 49.4851445, lng: 18.7272301 },
    { lat: 49.4849607, lng: 18.7272088 },
    { lat: 49.4848979, lng: 18.7273634 },
    { lat: 49.4847926, lng: 18.7275105 },
    { lat: 49.4846887, lng: 18.7276331 },
    { lat: 49.4845533, lng: 18.7278506 },
    { lat: 49.4845624, lng: 18.7279358 },
    { lat: 49.4843003, lng: 18.7282737 },
    { lat: 49.4843348, lng: 18.7283739 },
    { lat: 49.4842891, lng: 18.7285579 },
    { lat: 49.4841773, lng: 18.7286398 },
    { lat: 49.4841697, lng: 18.7287967 },
    { lat: 49.4840324, lng: 18.7289321 },
    { lat: 49.483984, lng: 18.7291285 },
    { lat: 49.4839345, lng: 18.7291088 },
    { lat: 49.4838135, lng: 18.7292524 },
    { lat: 49.4838087, lng: 18.7294009 },
    { lat: 49.4837372, lng: 18.7295442 },
    { lat: 49.4836704, lng: 18.7295579 },
    { lat: 49.4836146, lng: 18.7294632 },
    { lat: 49.4835768, lng: 18.7296669 },
    { lat: 49.4835298, lng: 18.7298906 },
    { lat: 49.4832756, lng: 18.7300766 },
    { lat: 49.4830831, lng: 18.7301913 },
    { lat: 49.4829721, lng: 18.7302016 },
    { lat: 49.4828909, lng: 18.7302671 },
    { lat: 49.4828691, lng: 18.7302839 },
    { lat: 49.4828605, lng: 18.7302911 },
    { lat: 49.4828888, lng: 18.7304849 },
    { lat: 49.4828439, lng: 18.7305354 },
    { lat: 49.4827356, lng: 18.7307057 },
    { lat: 49.4826857, lng: 18.730855 },
    { lat: 49.4825701, lng: 18.7309947 },
    { lat: 49.4824968, lng: 18.7311596 },
    { lat: 49.4824441, lng: 18.7312781 },
    { lat: 49.4823031, lng: 18.7313654 },
    { lat: 49.4821403, lng: 18.7314659 },
    { lat: 49.4820486, lng: 18.7313919 },
    { lat: 49.4820036, lng: 18.7316136 },
    { lat: 49.4819284, lng: 18.7315753 },
    { lat: 49.4816231, lng: 18.731711 },
    { lat: 49.4814645, lng: 18.7317819 },
    { lat: 49.4813421, lng: 18.7318361 },
    { lat: 49.4813154, lng: 18.7318861 },
    { lat: 49.4812802, lng: 18.7320098 },
    { lat: 49.4813536, lng: 18.7321839 },
    { lat: 49.4811073, lng: 18.7321967 },
    { lat: 49.4810197, lng: 18.7320522 },
    { lat: 49.4808935, lng: 18.7319883 },
    { lat: 49.4807583, lng: 18.7320545 },
    { lat: 49.4807357, lng: 18.7323608 },
    { lat: 49.480701, lng: 18.7323444 },
    { lat: 49.4805271, lng: 18.7322304 },
    { lat: 49.4803238, lng: 18.7323725 },
    { lat: 49.480287, lng: 18.7320793 },
    { lat: 49.4800389, lng: 18.73182 },
    { lat: 49.4798946, lng: 18.731904 },
    { lat: 49.4798367, lng: 18.7319387 },
    { lat: 49.4797787, lng: 18.7319726 },
    { lat: 49.4796856, lng: 18.7320272 },
    { lat: 49.479609, lng: 18.7320717 },
    { lat: 49.4795614, lng: 18.7321113 },
    { lat: 49.479515, lng: 18.7321507 },
    { lat: 49.4794231, lng: 18.7322272 },
    { lat: 49.4791822, lng: 18.732429 },
    { lat: 49.4789347, lng: 18.7326356 },
    { lat: 49.4788061, lng: 18.7327431 },
    { lat: 49.4787327, lng: 18.7328503 },
    { lat: 49.4784227, lng: 18.7333032 },
    { lat: 49.478351, lng: 18.7335103 },
    { lat: 49.4782775, lng: 18.7337235 },
    { lat: 49.4782232, lng: 18.7338476 },
    { lat: 49.4781688, lng: 18.7339729 },
    { lat: 49.4780933, lng: 18.7341475 },
    { lat: 49.4780597, lng: 18.7342143 },
    { lat: 49.4778673, lng: 18.734594 },
    { lat: 49.4777774, lng: 18.7349252 },
    { lat: 49.4776668, lng: 18.7353318 },
    { lat: 49.4775196, lng: 18.7358187 },
    { lat: 49.477385, lng: 18.7362645 },
    { lat: 49.4773677, lng: 18.7363235 },
    { lat: 49.4773306, lng: 18.7364368 },
    { lat: 49.4773104, lng: 18.7365021 },
    { lat: 49.4772658, lng: 18.7366406 },
    { lat: 49.4772201, lng: 18.7367843 },
    { lat: 49.4771735, lng: 18.7369289 },
    { lat: 49.4771299, lng: 18.7370662 },
    { lat: 49.4770745, lng: 18.7372369 },
    { lat: 49.4770727, lng: 18.737244 },
    { lat: 49.4770187, lng: 18.7373765 },
    { lat: 49.4769605, lng: 18.7375163 },
    { lat: 49.4769231, lng: 18.737597 },
    { lat: 49.4768618, lng: 18.7377003 },
    { lat: 49.4767649, lng: 18.7378528 },
    { lat: 49.4767332, lng: 18.7378941 },
    { lat: 49.4766384, lng: 18.7380195 },
    { lat: 49.4765856, lng: 18.7380898 },
    { lat: 49.475697, lng: 18.7390645 },
    { lat: 49.475417, lng: 18.7394686 },
    { lat: 49.4753999, lng: 18.7394838 },
    { lat: 49.475381, lng: 18.7395114 },
    { lat: 49.4753703, lng: 18.7395236 },
    { lat: 49.4736196, lng: 18.7418086 },
    { lat: 49.4735752, lng: 18.7418677 },
    { lat: 49.4725722, lng: 18.7431764 },
    { lat: 49.4708912, lng: 18.7447888 },
    { lat: 49.4699211, lng: 18.7456669 },
    { lat: 49.4698931, lng: 18.7456918 },
    { lat: 49.46988, lng: 18.7457035 },
    { lat: 49.4680623, lng: 18.7473958 },
    { lat: 49.4670892, lng: 18.7473484 },
    { lat: 49.4666446, lng: 18.7479501 },
    { lat: 49.466597, lng: 18.7479705 },
    { lat: 49.4665387, lng: 18.747995 },
    { lat: 49.4662772, lng: 18.7481038 },
    { lat: 49.4655443, lng: 18.7484102 },
    { lat: 49.4647321, lng: 18.7487494 },
    { lat: 49.4644197, lng: 18.74888 },
    { lat: 49.4636615, lng: 18.7493888 },
    { lat: 49.4629647, lng: 18.7494573 },
    { lat: 49.462917, lng: 18.7496259 },
    { lat: 49.4617816, lng: 18.7497965 },
    { lat: 49.4610044, lng: 18.7502913 },
    { lat: 49.460816, lng: 18.7505484 },
    { lat: 49.4599932, lng: 18.7507298 },
    { lat: 49.4599599, lng: 18.7507375 },
    { lat: 49.4595751, lng: 18.7508222 },
    { lat: 49.4595111, lng: 18.7508905 },
    { lat: 49.4594811, lng: 18.7509221 },
    { lat: 49.4576376, lng: 18.7528783 },
    { lat: 49.4576067, lng: 18.7529115 },
    { lat: 49.457378, lng: 18.753154 },
    { lat: 49.4564714, lng: 18.7537623 },
    { lat: 49.4555432, lng: 18.7543848 },
    { lat: 49.4557654, lng: 18.7556476 },
    { lat: 49.455307, lng: 18.7572008 },
    { lat: 49.4542793, lng: 18.7591488 },
    { lat: 49.4541585, lng: 18.7599087 },
    { lat: 49.4540706, lng: 18.7599959 },
    { lat: 49.4540266, lng: 18.7600393 },
    { lat: 49.4538219, lng: 18.7602426 },
    { lat: 49.4520566, lng: 18.7613856 },
    { lat: 49.4512799, lng: 18.7617112 },
    { lat: 49.4507459, lng: 18.7617668 },
    { lat: 49.4497411, lng: 18.7616523 },
    { lat: 49.4494727, lng: 18.7614343 },
    { lat: 49.4490136, lng: 18.7614065 },
    { lat: 49.4480956, lng: 18.7616003 },
    { lat: 49.4473548, lng: 18.7613387 },
    { lat: 49.446394, lng: 18.7606847 },
    { lat: 49.4454275, lng: 18.7595705 },
    { lat: 49.4449139, lng: 18.7587968 },
    { lat: 49.4441657, lng: 18.7582416 },
    { lat: 49.4441757, lng: 18.758152 },
    { lat: 49.4441846, lng: 18.7580821 },
    { lat: 49.4441898, lng: 18.7580329 },
    { lat: 49.4441968, lng: 18.7579704 },
    { lat: 49.4442153, lng: 18.757804 },
    { lat: 49.4442222, lng: 18.7577416 },
    { lat: 49.4442366, lng: 18.7576097 },
    { lat: 49.4442422, lng: 18.7575627 },
    { lat: 49.4441927, lng: 18.7575006 },
    { lat: 49.4441614, lng: 18.7574613 },
    { lat: 49.4441747, lng: 18.7574129 },
    { lat: 49.444187, lng: 18.7573683 },
    { lat: 49.4442002, lng: 18.7573178 },
    { lat: 49.4442304, lng: 18.7572075 },
    { lat: 49.4442325, lng: 18.7572015 },
    { lat: 49.444205, lng: 18.7570624 },
    { lat: 49.4441803, lng: 18.757043 },
    { lat: 49.4441566, lng: 18.7570238 },
    { lat: 49.4441315, lng: 18.7570171 },
    { lat: 49.4441106, lng: 18.7570066 },
    { lat: 49.4440888, lng: 18.7569958 },
    { lat: 49.4440656, lng: 18.7569863 },
    { lat: 49.4440233, lng: 18.7569719 },
    { lat: 49.4439862, lng: 18.7569536 },
    { lat: 49.443946, lng: 18.7569404 },
    { lat: 49.4439038, lng: 18.7569314 },
    { lat: 49.4438673, lng: 18.756921 },
    { lat: 49.4438261, lng: 18.7569108 },
    { lat: 49.4437845, lng: 18.7568923 },
    { lat: 49.4437418, lng: 18.7568736 },
    { lat: 49.4437012, lng: 18.7568521 },
    { lat: 49.4436553, lng: 18.7568334 },
    { lat: 49.4436204, lng: 18.7568167 },
    { lat: 49.4435838, lng: 18.756802 },
    { lat: 49.4435354, lng: 18.7567847 },
    { lat: 49.4430938, lng: 18.7565295 },
    { lat: 49.442468, lng: 18.7570094 },
    { lat: 49.4423573, lng: 18.7570023 },
    { lat: 49.4420419, lng: 18.7569897 },
    { lat: 49.4420365, lng: 18.7570139 },
    { lat: 49.441927, lng: 18.7569479 },
    { lat: 49.4419236, lng: 18.7569461 },
    { lat: 49.4418902, lng: 18.7569353 },
    { lat: 49.4418856, lng: 18.7569337 },
    { lat: 49.441782, lng: 18.7568489 },
    { lat: 49.4417774, lng: 18.7568469 },
    { lat: 49.4417183, lng: 18.7567911 },
    { lat: 49.4417146, lng: 18.7567883 },
    { lat: 49.4417072, lng: 18.7568196 },
    { lat: 49.4416824, lng: 18.7567969 },
    { lat: 49.4415396, lng: 18.7566937 },
    { lat: 49.4413414, lng: 18.7566349 },
    { lat: 49.4412889, lng: 18.7566214 },
    { lat: 49.4410604, lng: 18.7565655 },
    { lat: 49.4406689, lng: 18.7563886 },
    { lat: 49.4403796, lng: 18.756246 },
    { lat: 49.439984, lng: 18.7561794 },
    { lat: 49.4392011, lng: 18.7562597 },
    { lat: 49.4389787, lng: 18.7562531 },
    { lat: 49.4388488, lng: 18.7562723 },
    { lat: 49.4387571, lng: 18.7562853 },
    { lat: 49.4386004, lng: 18.756308 },
    { lat: 49.4383765, lng: 18.7563404 },
    { lat: 49.4380252, lng: 18.7563912 },
    { lat: 49.4380071, lng: 18.7563938 },
    { lat: 49.4379893, lng: 18.7563959 },
    { lat: 49.4379243, lng: 18.7564057 },
    { lat: 49.4378688, lng: 18.7563556 },
    { lat: 49.4375167, lng: 18.7562641 },
    { lat: 49.4371313, lng: 18.7561818 },
    { lat: 49.4369105, lng: 18.7561598 },
    { lat: 49.4366229, lng: 18.7561601 },
    { lat: 49.4364104, lng: 18.756107 },
    { lat: 49.4361502, lng: 18.7560464 },
    { lat: 49.4358541, lng: 18.7559684 },
    { lat: 49.4355584, lng: 18.7559617 },
    { lat: 49.4351542, lng: 18.7559943 },
    { lat: 49.4349447, lng: 18.7559148 },
    { lat: 49.434888, lng: 18.7557333 },
    { lat: 49.43417, lng: 18.7558695 },
    { lat: 49.4339907, lng: 18.755912 },
    { lat: 49.4338656, lng: 18.7560008 },
    { lat: 49.4337089, lng: 18.7560442 },
    { lat: 49.4320486, lng: 18.7572052 },
    { lat: 49.4320124, lng: 18.7572227 },
    { lat: 49.4316432, lng: 18.7574749 },
    { lat: 49.4312231, lng: 18.7576801 },
    { lat: 49.4305721, lng: 18.7578468 },
    { lat: 49.4299627, lng: 18.7580559 },
    { lat: 49.4298911, lng: 18.757869 },
    { lat: 49.4298131, lng: 18.7578455 },
    { lat: 49.4296875, lng: 18.7580065 },
    { lat: 49.4295601, lng: 18.7578557 },
    { lat: 49.4294014, lng: 18.7578617 },
    { lat: 49.429276, lng: 18.7577715 },
    { lat: 49.4292289, lng: 18.7578211 },
    { lat: 49.4291244, lng: 18.7577831 },
    { lat: 49.4290647, lng: 18.7578064 },
    { lat: 49.4288245, lng: 18.7577172 },
    { lat: 49.4287702, lng: 18.75776 },
    { lat: 49.4286755, lng: 18.7578238 },
    { lat: 49.4284959, lng: 18.7577728 },
    { lat: 49.4281265, lng: 18.7578255 },
    { lat: 49.4279689, lng: 18.7576802 },
    { lat: 49.4277625, lng: 18.7578094 },
    { lat: 49.426922, lng: 18.7578216 },
    { lat: 49.4266328, lng: 18.7577674 },
    { lat: 49.4257145, lng: 18.7575418 },
    { lat: 49.4251267, lng: 18.7573371 },
    { lat: 49.424881, lng: 18.7572515 },
    { lat: 49.4236992, lng: 18.7572478 },
    { lat: 49.4231309, lng: 18.7572468 },
    { lat: 49.4230346, lng: 18.7573035 },
    { lat: 49.4226993, lng: 18.7575124 },
    { lat: 49.4224649, lng: 18.7577123 },
    { lat: 49.4219466, lng: 18.7581555 },
    { lat: 49.4224209, lng: 18.7591038 },
    { lat: 49.4226802, lng: 18.759776 },
    { lat: 49.422836, lng: 18.7599732 },
    { lat: 49.423379, lng: 18.7604435 },
    { lat: 49.4238196, lng: 18.7610899 },
    { lat: 49.4241576, lng: 18.7612845 },
    { lat: 49.4238179, lng: 18.7623655 },
    { lat: 49.4235556, lng: 18.7625238 },
    { lat: 49.423426, lng: 18.7629355 },
    { lat: 49.4228755, lng: 18.762891 },
    { lat: 49.4222882, lng: 18.7629147 },
    { lat: 49.42178, lng: 18.7628557 },
    { lat: 49.4217717, lng: 18.7629103 },
    { lat: 49.4217631, lng: 18.7629642 },
    { lat: 49.4217502, lng: 18.7630418 },
    { lat: 49.4217299, lng: 18.7631696 },
    { lat: 49.4216825, lng: 18.7634329 },
    { lat: 49.4211369, lng: 18.763131 },
    { lat: 49.4208645, lng: 18.7630574 },
    { lat: 49.4203994, lng: 18.7629318 },
    { lat: 49.4196296, lng: 18.7622208 },
    { lat: 49.4181828, lng: 18.7614536 },
    { lat: 49.4178115, lng: 18.7616253 },
    { lat: 49.4174037, lng: 18.7613917 },
    { lat: 49.4163829, lng: 18.7612948 },
    { lat: 49.4163059, lng: 18.7612915 },
    { lat: 49.4162709, lng: 18.7612904 },
    { lat: 49.4156595, lng: 18.7612652 },
    { lat: 49.4146652, lng: 18.7617232 },
    { lat: 49.4142593, lng: 18.7620696 },
    { lat: 49.4136896, lng: 18.7623472 },
    { lat: 49.4129176, lng: 18.7630282 },
    { lat: 49.4124307, lng: 18.7637267 },
    { lat: 49.4123012, lng: 18.7640358 },
    { lat: 49.4122641, lng: 18.7641239 },
    { lat: 49.4119528, lng: 18.7630592 },
    { lat: 49.4119922, lng: 18.7614035 },
    { lat: 49.4121775, lng: 18.7604756 },
    { lat: 49.4122558, lng: 18.7593355 },
    { lat: 49.4120988, lng: 18.758764 },
    { lat: 49.4123495, lng: 18.7568602 },
    { lat: 49.4120808, lng: 18.7560275 },
    { lat: 49.4116941, lng: 18.7545541 },
    { lat: 49.411453, lng: 18.7534913 },
    { lat: 49.4113838, lng: 18.7521603 },
    { lat: 49.4113446, lng: 18.7513323 },
    { lat: 49.4112836, lng: 18.7507729 },
    { lat: 49.4100301, lng: 18.7499579 },
    { lat: 49.4098143, lng: 18.7499053 },
    { lat: 49.4098012, lng: 18.7504305 },
    { lat: 49.4097968, lng: 18.7505872 },
    { lat: 49.4097506, lng: 18.7508032 },
    { lat: 49.4097442, lng: 18.7508344 },
    { lat: 49.409737, lng: 18.7508686 },
    { lat: 49.4097, lng: 18.7510436 },
    { lat: 49.4096323, lng: 18.7513695 },
    { lat: 49.4095681, lng: 18.7516757 },
    { lat: 49.409387, lng: 18.7530108 },
    { lat: 49.4092571, lng: 18.753329 },
    { lat: 49.4092179, lng: 18.753312 },
    { lat: 49.4091398, lng: 18.7536319 },
    { lat: 49.4090866, lng: 18.7536271 },
    { lat: 49.4091718, lng: 18.7545933 },
    { lat: 49.4093098, lng: 18.75504 },
    { lat: 49.4094448, lng: 18.7560972 },
    { lat: 49.4094595, lng: 18.756317 },
    { lat: 49.4095159, lng: 18.7571172 },
    { lat: 49.4096274, lng: 18.7570924 },
    { lat: 49.4097039, lng: 18.7574832 },
    { lat: 49.4095983, lng: 18.757684 },
    { lat: 49.4095152, lng: 18.7581689 },
    { lat: 49.4096086, lng: 18.7584475 },
    { lat: 49.4097592, lng: 18.7585893 },
    { lat: 49.4097145, lng: 18.7589028 },
    { lat: 49.4098516, lng: 18.7591092 },
    { lat: 49.4097985, lng: 18.7594347 },
    { lat: 49.4099656, lng: 18.7596587 },
    { lat: 49.4102723, lng: 18.7604828 },
    { lat: 49.410457, lng: 18.7614337 },
    { lat: 49.4105515, lng: 18.7616709 },
    { lat: 49.4104333, lng: 18.7633901 },
    { lat: 49.4104414, lng: 18.7639388 },
    { lat: 49.4104848, lng: 18.7643435 },
    { lat: 49.4107531, lng: 18.7652534 },
    { lat: 49.410751, lng: 18.7654539 },
    { lat: 49.4108273, lng: 18.7659454 },
    { lat: 49.4108436, lng: 18.7662823 },
    { lat: 49.4109184, lng: 18.7678354 },
    { lat: 49.4104056, lng: 18.7692118 },
    { lat: 49.410134, lng: 18.7701491 },
    { lat: 49.4100337, lng: 18.7704104 },
    { lat: 49.4100158, lng: 18.7704571 },
    { lat: 49.4098679, lng: 18.7708462 },
    { lat: 49.4097052, lng: 18.7711659 },
    { lat: 49.4092023, lng: 18.772406 },
    { lat: 49.4091593, lng: 18.7725101 },
    { lat: 49.4090933, lng: 18.7726738 },
    { lat: 49.4086741, lng: 18.7734392 },
    { lat: 49.4083978, lng: 18.7737892 },
    { lat: 49.4079142, lng: 18.7752051 },
    { lat: 49.4077306, lng: 18.7769977 },
    { lat: 49.4075565, lng: 18.7783375 },
    { lat: 49.4067498, lng: 18.7804646 },
    { lat: 49.4066813, lng: 18.7806883 },
    { lat: 49.4063509, lng: 18.7818031 },
    { lat: 49.4061977, lng: 18.7823713 },
    { lat: 49.4060384, lng: 18.7831064 },
    { lat: 49.4060209, lng: 18.7834627 },
    { lat: 49.406019, lng: 18.7834955 },
    { lat: 49.4060155, lng: 18.7835781 },
    { lat: 49.4057323, lng: 18.7837942 },
    { lat: 49.4057044, lng: 18.784105 },
    { lat: 49.4057123, lng: 18.7842116 },
    { lat: 49.405722, lng: 18.7843353 },
    { lat: 49.4057111, lng: 18.7845177 },
    { lat: 49.40571, lng: 18.7845377 },
    { lat: 49.405712, lng: 18.7845581 },
    { lat: 49.4057212, lng: 18.7846486 },
    { lat: 49.4058129, lng: 18.7859221 },
    { lat: 49.4056416, lng: 18.7860775 },
    { lat: 49.4054971, lng: 18.7862243 },
    { lat: 49.4053051, lng: 18.7864134 },
    { lat: 49.4050438, lng: 18.7867026 },
    { lat: 49.4048524, lng: 18.7867533 },
    { lat: 49.4048039, lng: 18.786774 },
    { lat: 49.4046097, lng: 18.7868559 },
    { lat: 49.4045537, lng: 18.7868605 },
    { lat: 49.4045278, lng: 18.7868622 },
    { lat: 49.4044479, lng: 18.7869131 },
    { lat: 49.4044103, lng: 18.7869481 },
    { lat: 49.4043444, lng: 18.7869572 },
    { lat: 49.404323, lng: 18.7869601 },
    { lat: 49.4041222, lng: 18.7870428 },
    { lat: 49.403941, lng: 18.7871169 },
    { lat: 49.4039302, lng: 18.7870802 },
    { lat: 49.4036802, lng: 18.7872184 },
    { lat: 49.4036747, lng: 18.7872209 },
    { lat: 49.4035883, lng: 18.7872723 },
    { lat: 49.4026988, lng: 18.7867299 },
    { lat: 49.402502, lng: 18.7863458 },
    { lat: 49.402298, lng: 18.7858046 },
    { lat: 49.4022731, lng: 18.7857783 },
    { lat: 49.4020288, lng: 18.785523 },
    { lat: 49.401915, lng: 18.7853511 },
    { lat: 49.4015504, lng: 18.784819 },
    { lat: 49.4012443, lng: 18.7843504 },
    { lat: 49.4010673, lng: 18.7841532 },
    { lat: 49.4010362, lng: 18.7841186 },
    { lat: 49.4007039, lng: 18.7837136 },
    { lat: 49.400692, lng: 18.7836991 },
    { lat: 49.4005903, lng: 18.7834997 },
    { lat: 49.4005326, lng: 18.7834303 },
    { lat: 49.4004695, lng: 18.783431 },
    { lat: 49.4002873, lng: 18.7830911 },
    { lat: 49.3999268, lng: 18.7828996 },
    { lat: 49.3997481, lng: 18.7826284 },
    { lat: 49.3995038, lng: 18.7822862 },
    { lat: 49.3986757, lng: 18.7812265 },
    { lat: 49.3981251, lng: 18.780435 },
    { lat: 49.3979781, lng: 18.7804517 },
    { lat: 49.39767, lng: 18.7804888 },
    { lat: 49.397408, lng: 18.7799135 },
    { lat: 49.3972965, lng: 18.7798094 },
    { lat: 49.3970969, lng: 18.7797822 },
    { lat: 49.3969723, lng: 18.7797068 },
    { lat: 49.3968653, lng: 18.7796221 },
    { lat: 49.3967036, lng: 18.7793963 },
    { lat: 49.3965801, lng: 18.7791224 },
    { lat: 49.3961025, lng: 18.778413 },
    { lat: 49.3960787, lng: 18.778346 },
    { lat: 49.3960166, lng: 18.778233 },
    { lat: 49.395769, lng: 18.7777848 },
    { lat: 49.3956025, lng: 18.7774854 },
    { lat: 49.3955908, lng: 18.7774564 },
    { lat: 49.3954183, lng: 18.7770437 },
    { lat: 49.3944935, lng: 18.7756466 },
    { lat: 49.3944151, lng: 18.7748083 },
    { lat: 49.3934264, lng: 18.774961 },
    { lat: 49.3921406, lng: 18.7751064 },
    { lat: 49.3912734, lng: 18.7746015 },
    { lat: 49.3908712, lng: 18.7743908 },
    { lat: 49.3908515, lng: 18.7743809 },
    { lat: 49.3896138, lng: 18.773735 },
    { lat: 49.3887774, lng: 18.7723885 },
    { lat: 49.3884787, lng: 18.7702582 },
    { lat: 49.3884956, lng: 18.7699097 },
    { lat: 49.3893488, lng: 18.7693137 },
    { lat: 49.3895677, lng: 18.7685545 },
    { lat: 49.389604, lng: 18.7684292 },
    { lat: 49.389013, lng: 18.7677931 },
    { lat: 49.3881426, lng: 18.7667516 },
    { lat: 49.3874785, lng: 18.7661013 },
    { lat: 49.3881639, lng: 18.7656856 },
    { lat: 49.3891038, lng: 18.765416 },
    { lat: 49.3901463, lng: 18.765473 },
    { lat: 49.3901708, lng: 18.7651164 },
    { lat: 49.3903608, lng: 18.7642365 },
    { lat: 49.3902784, lng: 18.7640185 },
    { lat: 49.3905911, lng: 18.7636344 },
    { lat: 49.3909734, lng: 18.7631339 },
    { lat: 49.3910466, lng: 18.762349 },
    { lat: 49.3912792, lng: 18.7620333 },
    { lat: 49.3915654, lng: 18.7620285 },
    { lat: 49.3916747, lng: 18.7620267 },
    { lat: 49.3916631, lng: 18.7619771 },
    { lat: 49.3916319, lng: 18.7618531 },
    { lat: 49.3916165, lng: 18.7617938 },
    { lat: 49.3914564, lng: 18.7611676 },
    { lat: 49.3911916, lng: 18.7604303 },
    { lat: 49.3911305, lng: 18.7591437 },
    { lat: 49.3911049, lng: 18.7586053 },
    { lat: 49.3910431, lng: 18.7580036 },
    { lat: 49.3908987, lng: 18.7567007 },
    { lat: 49.3908098, lng: 18.7555529 },
    { lat: 49.3907234, lng: 18.7544953 },
    { lat: 49.3909038, lng: 18.7534963 },
    { lat: 49.3905182, lng: 18.7530195 },
    { lat: 49.390598, lng: 18.7522996 },
    { lat: 49.3910713, lng: 18.7500592 },
    { lat: 49.3907458, lng: 18.7497431 },
    { lat: 49.3902287, lng: 18.7487319 },
    { lat: 49.3899972, lng: 18.7485744 },
    { lat: 49.389559, lng: 18.7481711 },
    { lat: 49.3893907, lng: 18.7482698 },
    { lat: 49.3891244, lng: 18.7484663 },
    { lat: 49.388664, lng: 18.74888 },
    { lat: 49.388652, lng: 18.749621 },
    { lat: 49.3885244, lng: 18.7507939 },
    { lat: 49.388367, lng: 18.751365 },
    { lat: 49.388073, lng: 18.752322 },
    { lat: 49.387745, lng: 18.752807 },
    { lat: 49.387573, lng: 18.753449 },
    { lat: 49.387254, lng: 18.754352 },
    { lat: 49.387168, lng: 18.754796 },
    { lat: 49.387045, lng: 18.756861 },
    { lat: 49.387033, lng: 18.757736 },
    { lat: 49.386355, lng: 18.759244 },
    { lat: 49.38608, lng: 18.75942 },
    { lat: 49.385433, lng: 18.75965 },
    { lat: 49.385252, lng: 18.75985 },
    { lat: 49.384784, lng: 18.76011 },
    { lat: 49.384125, lng: 18.760821 },
    { lat: 49.383856, lng: 18.761948 },
    { lat: 49.383757, lng: 18.762189 },
    { lat: 49.383488, lng: 18.762375 },
    { lat: 49.38332, lng: 18.762635 },
    { lat: 49.383271, lng: 18.762632 },
    { lat: 49.383218, lng: 18.762871 },
    { lat: 49.382275, lng: 18.765164 },
    { lat: 49.382221, lng: 18.765559 },
    { lat: 49.381135, lng: 18.769771 },
    { lat: 49.380921, lng: 18.7708 },
    { lat: 49.380981, lng: 18.772896 },
    { lat: 49.380974, lng: 18.773804 },
    { lat: 49.380906, lng: 18.774548 },
    { lat: 49.380726, lng: 18.774706 },
    { lat: 49.3812478, lng: 18.7761339 },
    { lat: 49.3825565, lng: 18.7781738 },
    { lat: 49.3827244, lng: 18.778368 },
    { lat: 49.3830607, lng: 18.7789399 },
    { lat: 49.383531, lng: 18.7804039 },
    { lat: 49.3837514, lng: 18.7810887 },
    { lat: 49.3839239, lng: 18.7818524 },
    { lat: 49.3840421, lng: 18.7823925 },
    { lat: 49.3840181, lng: 18.7832806 },
    { lat: 49.3834559, lng: 18.7861445 },
    { lat: 49.3832798, lng: 18.7868611 },
    { lat: 49.3832532, lng: 18.7868936 },
    { lat: 49.3832565, lng: 18.786912 },
    { lat: 49.383428, lng: 18.7868055 },
    { lat: 49.3835686, lng: 18.7867569 },
    { lat: 49.3837324, lng: 18.7867168 },
    { lat: 49.3838624, lng: 18.7867032 },
    { lat: 49.3839424, lng: 18.7866566 },
    { lat: 49.3840348, lng: 18.7866335 },
    { lat: 49.3841227, lng: 18.7865707 },
    { lat: 49.3842861, lng: 18.7863938 },
    { lat: 49.3843462, lng: 18.7863329 },
    { lat: 49.3844346, lng: 18.7862553 },
    { lat: 49.3844674, lng: 18.7862122 },
    { lat: 49.3845085, lng: 18.7861577 },
    { lat: 49.3845142, lng: 18.7861816 },
    { lat: 49.3845173, lng: 18.786196 },
    { lat: 49.3845183, lng: 18.786197 },
    { lat: 49.3845303, lng: 18.7862498 },
    { lat: 49.3845315, lng: 18.7862558 },
    { lat: 49.384661, lng: 18.7868188 },
    { lat: 49.3847086, lng: 18.7873006 },
    { lat: 49.3846233, lng: 18.7878762 },
    { lat: 49.3846272, lng: 18.7884559 },
    { lat: 49.384615, lng: 18.7891149 },
    { lat: 49.3845859, lng: 18.7894221 },
    { lat: 49.3845665, lng: 18.7896351 },
    { lat: 49.3845656, lng: 18.789641 },
    { lat: 49.3844682, lng: 18.7902438 },
    { lat: 49.3844191, lng: 18.7908219 },
    { lat: 49.3841647, lng: 18.7912881 },
    { lat: 49.3843571, lng: 18.7917624 },
    { lat: 49.3846387, lng: 18.7923012 },
    { lat: 49.3845891, lng: 18.7924752 },
    { lat: 49.3847383, lng: 18.7927473 },
    { lat: 49.3849301, lng: 18.7930994 },
    { lat: 49.3851312, lng: 18.793653 },
    { lat: 49.3852732, lng: 18.7941298 },
    { lat: 49.3847422, lng: 18.7945256 },
    { lat: 49.3847347, lng: 18.7945316 },
    { lat: 49.3847227, lng: 18.7945414 },
    { lat: 49.3847212, lng: 18.7945767 },
    { lat: 49.3847037, lng: 18.7952632 },
    { lat: 49.3846308, lng: 18.7959061 },
    { lat: 49.3845067, lng: 18.7961885 },
    { lat: 49.3845136, lng: 18.7962127 },
    { lat: 49.3845137, lng: 18.7962145 },
    { lat: 49.3845503, lng: 18.7963562 },
    { lat: 49.3845543, lng: 18.7963702 },
    { lat: 49.38464, lng: 18.7966993 },
    { lat: 49.3847423, lng: 18.797435 },
    { lat: 49.3849764, lng: 18.7981044 },
    { lat: 49.3850228, lng: 18.7983485 },
    { lat: 49.3851095, lng: 18.798802 },
    { lat: 49.3852401, lng: 18.799108 },
    { lat: 49.3854273, lng: 18.7995446 },
    { lat: 49.3852383, lng: 18.800187 },
    { lat: 49.3853274, lng: 18.8004488 },
    { lat: 49.3855689, lng: 18.8011535 },
    { lat: 49.3857936, lng: 18.8008741 },
    { lat: 49.3857989, lng: 18.800868 },
    { lat: 49.3858106, lng: 18.8008557 },
    { lat: 49.3859847, lng: 18.8006671 },
    { lat: 49.3862805, lng: 18.8003472 },
    { lat: 49.3865258, lng: 18.7997475 },
    { lat: 49.3866349, lng: 18.799481 },
    { lat: 49.3866901, lng: 18.7993462 },
    { lat: 49.3867913, lng: 18.7991468 },
    { lat: 49.3869941, lng: 18.7987484 },
    { lat: 49.3870241, lng: 18.7987166 },
    { lat: 49.3871704, lng: 18.7985574 },
    { lat: 49.3873303, lng: 18.7983835 },
    { lat: 49.3874747, lng: 18.798227 },
    { lat: 49.3875502, lng: 18.798144 },
    { lat: 49.3877737, lng: 18.7976432 },
    { lat: 49.387905, lng: 18.7973497 },
    { lat: 49.3879141, lng: 18.7973287 },
    { lat: 49.3879172, lng: 18.79732 },
    { lat: 49.3881495, lng: 18.7966882 },
    { lat: 49.388603, lng: 18.7962098 },
    { lat: 49.3888693, lng: 18.7961736 },
    { lat: 49.3888716, lng: 18.796173 },
    { lat: 49.3890124, lng: 18.7961266 },
    { lat: 49.3890144, lng: 18.7961249 },
    { lat: 49.3893608, lng: 18.7958633 },
    { lat: 49.3897442, lng: 18.7957344 },
    { lat: 49.3902026, lng: 18.7958776 },
    { lat: 49.3902026, lng: 18.7958779 },
    { lat: 49.3904257, lng: 18.7959436 },
    { lat: 49.390511, lng: 18.7959988 },
    { lat: 49.3910571, lng: 18.7963576 },
    { lat: 49.3910596, lng: 18.7963591 },
    { lat: 49.3915397, lng: 18.7966213 },
    { lat: 49.3917788, lng: 18.7966673 },
    { lat: 49.391796, lng: 18.7966706 },
    { lat: 49.3920503, lng: 18.7968545 },
    { lat: 49.3920637, lng: 18.7968532 },
    { lat: 49.3924081, lng: 18.7968202 },
    { lat: 49.3926167, lng: 18.7968891 },
    { lat: 49.3929174, lng: 18.7970557 },
    { lat: 49.3931294, lng: 18.7971626 },
    { lat: 49.3934758, lng: 18.7972375 },
    { lat: 49.3937185, lng: 18.7972205 },
    { lat: 49.3938991, lng: 18.797309 },
    { lat: 49.3941578, lng: 18.7972542 },
    { lat: 49.3943031, lng: 18.797272 },
    { lat: 49.394424, lng: 18.7972346 },
    { lat: 49.39464, lng: 18.797203 },
    { lat: 49.3949197, lng: 18.7971616 },
    { lat: 49.3952609, lng: 18.7971112 },
    { lat: 49.3953941, lng: 18.7972891 },
    { lat: 49.3956395, lng: 18.7974346 },
    { lat: 49.3957801, lng: 18.7975352 },
    { lat: 49.3962017, lng: 18.7978386 },
    { lat: 49.3962097, lng: 18.7978438 },
  ],
  NováBystrica: [
    { lat: 49.333364, lng: 19.146303 },
    { lat: 49.333803, lng: 19.146182 },
    { lat: 49.333879, lng: 19.146161 },
    { lat: 49.334385, lng: 19.145743 },
    { lat: 49.334519, lng: 19.145692 },
    { lat: 49.334793, lng: 19.145688 },
    { lat: 49.335088, lng: 19.145555 },
    { lat: 49.335227, lng: 19.145572 },
    { lat: 49.335509, lng: 19.145546 },
    { lat: 49.335513, lng: 19.145581 },
    { lat: 49.335589, lng: 19.145586 },
    { lat: 49.335725, lng: 19.145597 },
    { lat: 49.335824, lng: 19.145604 },
    { lat: 49.335883, lng: 19.145629 },
    { lat: 49.336102, lng: 19.14572 },
    { lat: 49.336344, lng: 19.14582 },
    { lat: 49.33649, lng: 19.145881 },
    { lat: 49.336514, lng: 19.145891 },
    { lat: 49.336605, lng: 19.145928 },
    { lat: 49.336795, lng: 19.146161 },
    { lat: 49.337044, lng: 19.146318 },
    { lat: 49.337091, lng: 19.146348 },
    { lat: 49.33715, lng: 19.146385 },
    { lat: 49.337197, lng: 19.146415 },
    { lat: 49.33726, lng: 19.146455 },
    { lat: 49.337309, lng: 19.146485 },
    { lat: 49.337404, lng: 19.146546 },
    { lat: 49.337851, lng: 19.146999 },
    { lat: 49.338123, lng: 19.147182 },
    { lat: 49.33813, lng: 19.147187 },
    { lat: 49.338528, lng: 19.147296 },
    { lat: 49.338572, lng: 19.147308 },
    { lat: 49.338702, lng: 19.14739 },
    { lat: 49.33876, lng: 19.147427 },
    { lat: 49.33886, lng: 19.147395 },
    { lat: 49.338986, lng: 19.147354 },
    { lat: 49.339044, lng: 19.147336 },
    { lat: 49.339061, lng: 19.147331 },
    { lat: 49.339302, lng: 19.14718 },
    { lat: 49.33931, lng: 19.147175 },
    { lat: 49.339329, lng: 19.147163 },
    { lat: 49.339334, lng: 19.14716 },
    { lat: 49.339583, lng: 19.146972 },
    { lat: 49.339612, lng: 19.146951 },
    { lat: 49.339739, lng: 19.146903 },
    { lat: 49.339788, lng: 19.146891 },
    { lat: 49.339952, lng: 19.146851 },
    { lat: 49.340233, lng: 19.146781 },
    { lat: 49.340334, lng: 19.146756 },
    { lat: 49.340679, lng: 19.146828 },
    { lat: 49.34108, lng: 19.146911 },
    { lat: 49.341318, lng: 19.147014 },
    { lat: 49.341513, lng: 19.147043 },
    { lat: 49.341835, lng: 19.147091 },
    { lat: 49.341933, lng: 19.147106 },
    { lat: 49.342112, lng: 19.147132 },
    { lat: 49.342284, lng: 19.147207 },
    { lat: 49.342428, lng: 19.147328 },
    { lat: 49.342443, lng: 19.14734 },
    { lat: 49.342604, lng: 19.147301 },
    { lat: 49.342714, lng: 19.147457 },
    { lat: 49.342776, lng: 19.147466 },
    { lat: 49.342914, lng: 19.147486 },
    { lat: 49.343023, lng: 19.147502 },
    { lat: 49.343452, lng: 19.148049 },
    { lat: 49.343629, lng: 19.148228 },
    { lat: 49.343663, lng: 19.148264 },
    { lat: 49.343916, lng: 19.148521 },
    { lat: 49.344111, lng: 19.148765 },
    { lat: 49.344326, lng: 19.148844 },
    { lat: 49.344518, lng: 19.148671 },
    { lat: 49.344696, lng: 19.148606 },
    { lat: 49.344937, lng: 19.148675 },
    { lat: 49.345038, lng: 19.148835 },
    { lat: 49.345391, lng: 19.149236 },
    { lat: 49.345692, lng: 19.149536 },
    { lat: 49.345788, lng: 19.149663 },
    { lat: 49.346825, lng: 19.150365 },
    { lat: 49.34703, lng: 19.150544 },
    { lat: 49.347281, lng: 19.150891 },
    { lat: 49.347867, lng: 19.151818 },
    { lat: 49.348095, lng: 19.152227 },
    { lat: 49.348445, lng: 19.1528 },
    { lat: 49.348813, lng: 19.153403 },
    { lat: 49.349084, lng: 19.153844 },
    { lat: 49.349213, lng: 19.154021 },
    { lat: 49.349419, lng: 19.154235 },
    { lat: 49.349456, lng: 19.154222 },
    { lat: 49.349631, lng: 19.154162 },
    { lat: 49.349764, lng: 19.154293 },
    { lat: 49.350265, lng: 19.154591 },
    { lat: 49.350422, lng: 19.154754 },
    { lat: 49.350858, lng: 19.154833 },
    { lat: 49.350948, lng: 19.154803 },
    { lat: 49.351086, lng: 19.154702 },
    { lat: 49.351648, lng: 19.154292 },
    { lat: 49.351994, lng: 19.154182 },
    { lat: 49.352136, lng: 19.154156 },
    { lat: 49.352794, lng: 19.154033 },
    { lat: 49.353168, lng: 19.154018 },
    { lat: 49.35378, lng: 19.153857 },
    { lat: 49.35388, lng: 19.153831 },
    { lat: 49.354447, lng: 19.15363 },
    { lat: 49.354612, lng: 19.153612 },
    { lat: 49.354659, lng: 19.153579 },
    { lat: 49.355092, lng: 19.153447 },
    { lat: 49.355661, lng: 19.153427 },
    { lat: 49.355733, lng: 19.153463 },
    { lat: 49.356021, lng: 19.153456 },
    { lat: 49.356191, lng: 19.153336 },
    { lat: 49.356299, lng: 19.153147 },
    { lat: 49.356444, lng: 19.153024 },
    { lat: 49.356954, lng: 19.152714 },
    { lat: 49.358016, lng: 19.151591 },
    { lat: 49.358056, lng: 19.151547 },
    { lat: 49.358282, lng: 19.151268 },
    { lat: 49.358308, lng: 19.151235 },
    { lat: 49.35838, lng: 19.151147 },
    { lat: 49.358539, lng: 19.150828 },
    { lat: 49.35863, lng: 19.150644 },
    { lat: 49.358708, lng: 19.150537 },
    { lat: 49.358905, lng: 19.150266 },
    { lat: 49.359, lng: 19.150091 },
    { lat: 49.359191, lng: 19.149742 },
    { lat: 49.359203, lng: 19.149716 },
    { lat: 49.359221, lng: 19.149673 },
    { lat: 49.359235, lng: 19.14964 },
    { lat: 49.359281, lng: 19.149535 },
    { lat: 49.359376, lng: 19.14923 },
    { lat: 49.359417, lng: 19.149107 },
    { lat: 49.359448, lng: 19.14901 },
    { lat: 49.359452, lng: 19.148993 },
    { lat: 49.359495, lng: 19.148768 },
    { lat: 49.359497, lng: 19.148595 },
    { lat: 49.359571, lng: 19.14838 },
    { lat: 49.359663, lng: 19.14823 },
    { lat: 49.359812, lng: 19.147985 },
    { lat: 49.359859, lng: 19.147841 },
    { lat: 49.35986, lng: 19.147838 },
    { lat: 49.359978, lng: 19.147116 },
    { lat: 49.360013, lng: 19.146272 },
    { lat: 49.360133, lng: 19.145894 },
    { lat: 49.360245, lng: 19.145763 },
    { lat: 49.360648, lng: 19.145018 },
    { lat: 49.360849, lng: 19.144728 },
    { lat: 49.361013, lng: 19.144534 },
    { lat: 49.361735, lng: 19.143816 },
    { lat: 49.362784, lng: 19.143062 },
    { lat: 49.363109, lng: 19.142815 },
    { lat: 49.36353, lng: 19.142097 },
    { lat: 49.36377, lng: 19.141581 },
    { lat: 49.363956, lng: 19.141091 },
    { lat: 49.364078, lng: 19.140675 },
    { lat: 49.364197, lng: 19.140413 },
    { lat: 49.364475, lng: 19.140032 },
    { lat: 49.364575, lng: 19.139844 },
    { lat: 49.364703, lng: 19.139552 },
    { lat: 49.364839, lng: 19.138799 },
    { lat: 49.364679, lng: 19.138458 },
    { lat: 49.364619, lng: 19.138182 },
    { lat: 49.364592, lng: 19.137924 },
    { lat: 49.364566, lng: 19.137665 },
    { lat: 49.364496, lng: 19.137307 },
    { lat: 49.364473, lng: 19.13702 },
    { lat: 49.364483, lng: 19.136624 },
    { lat: 49.364602, lng: 19.136335 },
    { lat: 49.364618, lng: 19.13618 },
    { lat: 49.364574, lng: 19.135851 },
    { lat: 49.364775, lng: 19.134967 },
    { lat: 49.364832, lng: 19.134496 },
    { lat: 49.364808, lng: 19.134162 },
    { lat: 49.364842, lng: 19.133933 },
    { lat: 49.365028, lng: 19.133358 },
    { lat: 49.365042, lng: 19.133144 },
    { lat: 49.365049, lng: 19.132729 },
    { lat: 49.365145, lng: 19.132427 },
    { lat: 49.365356, lng: 19.132004 },
    { lat: 49.365473, lng: 19.131814 },
    { lat: 49.36561, lng: 19.131679 },
    { lat: 49.365649, lng: 19.131679 },
    { lat: 49.366234, lng: 19.131669 },
    { lat: 49.3664, lng: 19.131766 },
    { lat: 49.366478, lng: 19.131902 },
    { lat: 49.366484, lng: 19.131878 },
    { lat: 49.366495, lng: 19.131826 },
    { lat: 49.366517, lng: 19.131725 },
    { lat: 49.366632, lng: 19.131629 },
    { lat: 49.367041, lng: 19.131285 },
    { lat: 49.367323, lng: 19.131188 },
    { lat: 49.367602, lng: 19.131068 },
    { lat: 49.367776, lng: 19.130964 },
    { lat: 49.368192, lng: 19.13101 },
    { lat: 49.368426, lng: 19.13042 },
    { lat: 49.368528, lng: 19.130302 },
    { lat: 49.368844, lng: 19.129926 },
    { lat: 49.36892, lng: 19.129712 },
    { lat: 49.369064, lng: 19.129181 },
    { lat: 49.36902, lng: 19.128926 },
    { lat: 49.368958, lng: 19.128825 },
    { lat: 49.368968, lng: 19.128506 },
    { lat: 49.368737, lng: 19.128298 },
    { lat: 49.368605, lng: 19.128312 },
    { lat: 49.368499, lng: 19.12774 },
    { lat: 49.36834, lng: 19.127287 },
    { lat: 49.368265, lng: 19.126855 },
    { lat: 49.368235, lng: 19.126595 },
    { lat: 49.368199, lng: 19.126162 },
    { lat: 49.368167, lng: 19.12578 },
    { lat: 49.368149, lng: 19.125756 },
    { lat: 49.367954, lng: 19.125102 },
    { lat: 49.36795, lng: 19.12484 },
    { lat: 49.367931, lng: 19.124608 },
    { lat: 49.367897, lng: 19.12419 },
    { lat: 49.367946, lng: 19.123852 },
    { lat: 49.367944, lng: 19.123842 },
    { lat: 49.367938, lng: 19.123793 },
    { lat: 49.367957, lng: 19.123691 },
    { lat: 49.367983, lng: 19.123547 },
    { lat: 49.367867, lng: 19.122876 },
    { lat: 49.36785, lng: 19.122778 },
    { lat: 49.367836, lng: 19.122748 },
    { lat: 49.367669, lng: 19.122404 },
    { lat: 49.367585, lng: 19.12223 },
    { lat: 49.367563, lng: 19.122186 },
    { lat: 49.367227, lng: 19.121925 },
    { lat: 49.366866, lng: 19.121357 },
    { lat: 49.366771, lng: 19.121185 },
    { lat: 49.366656, lng: 19.120942 },
    { lat: 49.366552, lng: 19.120691 },
    { lat: 49.366505, lng: 19.120452 },
    { lat: 49.366318, lng: 19.120032 },
    { lat: 49.36618, lng: 19.119722 },
    { lat: 49.366161, lng: 19.119678 },
    { lat: 49.366269, lng: 19.119168 },
    { lat: 49.366443, lng: 19.118663 },
    { lat: 49.366463, lng: 19.118602 },
    { lat: 49.366113, lng: 19.117825 },
    { lat: 49.366321, lng: 19.117147 },
    { lat: 49.366569, lng: 19.116819 },
    { lat: 49.36671, lng: 19.116593 },
    { lat: 49.367044, lng: 19.115684 },
    { lat: 49.36677, lng: 19.115142 },
    { lat: 49.367187, lng: 19.114861 },
    { lat: 49.367466, lng: 19.114574 },
    { lat: 49.368012, lng: 19.113519 },
    { lat: 49.368344, lng: 19.113532 },
    { lat: 49.368388, lng: 19.113534 },
    { lat: 49.369207, lng: 19.11335 },
    { lat: 49.369283, lng: 19.113261 },
    { lat: 49.369478, lng: 19.113034 },
    { lat: 49.369596, lng: 19.112896 },
    { lat: 49.37089, lng: 19.112356 },
    { lat: 49.371065, lng: 19.111704 },
    { lat: 49.371511, lng: 19.1112 },
    { lat: 49.371775, lng: 19.111576 },
    { lat: 49.371933, lng: 19.111799 },
    { lat: 49.371961, lng: 19.111876 },
    { lat: 49.372143, lng: 19.112376 },
    { lat: 49.372171, lng: 19.11245 },
    { lat: 49.372304, lng: 19.112792 },
    { lat: 49.372478, lng: 19.113123 },
    { lat: 49.372479, lng: 19.113125 },
    { lat: 49.37255, lng: 19.113251 },
    { lat: 49.37299, lng: 19.113626 },
    { lat: 49.373664, lng: 19.114458 },
    { lat: 49.373772, lng: 19.114506 },
    { lat: 49.374241, lng: 19.114714 },
    { lat: 49.374477, lng: 19.114818 },
    { lat: 49.374674, lng: 19.114905 },
    { lat: 49.374684, lng: 19.11491 },
    { lat: 49.37482, lng: 19.115095 },
    { lat: 49.374989, lng: 19.115326 },
    { lat: 49.375085, lng: 19.115459 },
    { lat: 49.375156, lng: 19.1157 },
    { lat: 49.375378, lng: 19.116449 },
    { lat: 49.375582, lng: 19.117142 },
    { lat: 49.375659, lng: 19.117398 },
    { lat: 49.375795, lng: 19.117702 },
    { lat: 49.375989, lng: 19.118135 },
    { lat: 49.376135, lng: 19.118462 },
    { lat: 49.376204, lng: 19.118562 },
    { lat: 49.376447, lng: 19.118915 },
    { lat: 49.376517, lng: 19.119019 },
    { lat: 49.376587, lng: 19.119041 },
    { lat: 49.376731, lng: 19.119085 },
    { lat: 49.376764, lng: 19.119095 },
    { lat: 49.376987, lng: 19.119361 },
    { lat: 49.377235, lng: 19.119656 },
    { lat: 49.37735, lng: 19.11978 },
    { lat: 49.377388, lng: 19.119819 },
    { lat: 49.3775, lng: 19.119939 },
    { lat: 49.377624, lng: 19.120071 },
    { lat: 49.377965, lng: 19.120432 },
    { lat: 49.378119, lng: 19.120598 },
    { lat: 49.378149, lng: 19.120631 },
    { lat: 49.378296, lng: 19.120796 },
    { lat: 49.3785, lng: 19.121024 },
    { lat: 49.378635, lng: 19.121176 },
    { lat: 49.378854, lng: 19.121422 },
    { lat: 49.379074, lng: 19.121668 },
    { lat: 49.379205, lng: 19.121816 },
    { lat: 49.379266, lng: 19.121954 },
    { lat: 49.3794228, lng: 19.1223027 },
    { lat: 49.379485, lng: 19.122441 },
    { lat: 49.379498, lng: 19.12247 },
    { lat: 49.379561, lng: 19.122608 },
    { lat: 49.379804, lng: 19.12278 },
    { lat: 49.380192, lng: 19.123055 },
    { lat: 49.381081, lng: 19.123091 },
    { lat: 49.381169, lng: 19.123094 },
    { lat: 49.38146, lng: 19.122817 },
    { lat: 49.381693, lng: 19.123029 },
    { lat: 49.381824, lng: 19.122708 },
    { lat: 49.382328, lng: 19.123089 },
    { lat: 49.382726, lng: 19.123011 },
    { lat: 49.383089, lng: 19.12294 },
    { lat: 49.383851, lng: 19.123145 },
    { lat: 49.384099, lng: 19.123098 },
    { lat: 49.384608, lng: 19.123248 },
    { lat: 49.384959, lng: 19.123484 },
    { lat: 49.385325, lng: 19.123728 },
    { lat: 49.385634, lng: 19.123935 },
    { lat: 49.386228, lng: 19.12387 },
    { lat: 49.386888, lng: 19.123987 },
    { lat: 49.386951, lng: 19.12429 },
    { lat: 49.387041, lng: 19.124464 },
    { lat: 49.387124, lng: 19.124628 },
    { lat: 49.387246, lng: 19.124867 },
    { lat: 49.387926, lng: 19.125624 },
    { lat: 49.388016, lng: 19.125725 },
    { lat: 49.38803, lng: 19.125741 },
    { lat: 49.388388, lng: 19.12614 },
    { lat: 49.388991, lng: 19.126472 },
    { lat: 49.389005, lng: 19.12648 },
    { lat: 49.389049, lng: 19.126503 },
    { lat: 49.389124, lng: 19.126545 },
    { lat: 49.389359, lng: 19.126312 },
    { lat: 49.389737, lng: 19.1262638 },
    { lat: 49.390225, lng: 19.126716 },
    { lat: 49.390255, lng: 19.126746 },
    { lat: 49.391354, lng: 19.12714 },
    { lat: 49.391816, lng: 19.127046 },
    { lat: 49.3926, lng: 19.12647 },
    { lat: 49.392911, lng: 19.126241 },
    { lat: 49.393314, lng: 19.125503 },
    { lat: 49.393449, lng: 19.124836 },
    { lat: 49.39391, lng: 19.123991 },
    { lat: 49.394821, lng: 19.124561 },
    { lat: 49.395089, lng: 19.124544 },
    { lat: 49.395489, lng: 19.12435 },
    { lat: 49.395702, lng: 19.124129 },
    { lat: 49.395767, lng: 19.12406 },
    { lat: 49.39581, lng: 19.123949 },
    { lat: 49.396155, lng: 19.124034 },
    { lat: 49.396222, lng: 19.123984 },
    { lat: 49.396705, lng: 19.123632 },
    { lat: 49.3968, lng: 19.123637 },
    { lat: 49.396829, lng: 19.123638 },
    { lat: 49.398226, lng: 19.123712 },
    { lat: 49.399231, lng: 19.123765 },
    { lat: 49.399844, lng: 19.122909 },
    { lat: 49.400346, lng: 19.122959 },
    { lat: 49.401762, lng: 19.122166 },
    { lat: 49.402197, lng: 19.121959 },
    { lat: 49.402675, lng: 19.121496 },
    { lat: 49.402676, lng: 19.121495 },
    { lat: 49.402659, lng: 19.120358 },
    { lat: 49.402889, lng: 19.119381 },
    { lat: 49.403015, lng: 19.118875 },
    { lat: 49.403132, lng: 19.118418 },
    { lat: 49.403368, lng: 19.117492 },
    { lat: 49.403547, lng: 19.116926 },
    { lat: 49.403838, lng: 19.116018 },
    { lat: 49.404017, lng: 19.114183 },
    { lat: 49.404154, lng: 19.112814 },
    { lat: 49.404036, lng: 19.11179 },
    { lat: 49.403986, lng: 19.110751 },
    { lat: 49.403892, lng: 19.109115 },
    { lat: 49.403803, lng: 19.108376 },
    { lat: 49.403682, lng: 19.107287 },
    { lat: 49.404011, lng: 19.106722 },
    { lat: 49.40422, lng: 19.106653 },
    { lat: 49.40458, lng: 19.106523 },
    { lat: 49.404752, lng: 19.106444 },
    { lat: 49.405177, lng: 19.106233 },
    { lat: 49.406002, lng: 19.105902 },
    { lat: 49.406347, lng: 19.10574 },
    { lat: 49.40654, lng: 19.105646 },
    { lat: 49.406684, lng: 19.105576 },
    { lat: 49.407158, lng: 19.104903 },
    { lat: 49.407227, lng: 19.104825 },
    { lat: 49.407791, lng: 19.104252 },
    { lat: 49.40796, lng: 19.104081 },
    { lat: 49.408446, lng: 19.103488 },
    { lat: 49.408855, lng: 19.102989 },
    { lat: 49.409356, lng: 19.103112 },
    { lat: 49.409791, lng: 19.102998 },
    { lat: 49.409918, lng: 19.101994 },
    { lat: 49.410119, lng: 19.101285 },
    { lat: 49.410485, lng: 19.10076 },
    { lat: 49.410585, lng: 19.100628 },
    { lat: 49.411009, lng: 19.100211 },
    { lat: 49.410665, lng: 19.098574 },
    { lat: 49.410517, lng: 19.097386 },
    { lat: 49.410398, lng: 19.095976 },
    { lat: 49.410384, lng: 19.095428 },
    { lat: 49.41038, lng: 19.09521 },
    { lat: 49.410296, lng: 19.094347 },
    { lat: 49.410562, lng: 19.093981 },
    { lat: 49.410796, lng: 19.093639 },
    { lat: 49.411323, lng: 19.093672 },
    { lat: 49.412212, lng: 19.093924 },
    { lat: 49.412654, lng: 19.093739 },
    { lat: 49.412728, lng: 19.093708 },
    { lat: 49.413034, lng: 19.093384 },
    { lat: 49.413242, lng: 19.09317 },
    { lat: 49.4138, lng: 19.092936 },
    { lat: 49.414545, lng: 19.092345 },
    { lat: 49.415642, lng: 19.091854 },
    { lat: 49.415836, lng: 19.091767 },
    { lat: 49.415973, lng: 19.091472 },
    { lat: 49.415947, lng: 19.091133 },
    { lat: 49.416022, lng: 19.090773 },
    { lat: 49.416063, lng: 19.090161 },
    { lat: 49.416105, lng: 19.089944 },
    { lat: 49.416032, lng: 19.089079 },
    { lat: 49.416106, lng: 19.088723 },
    { lat: 49.416077, lng: 19.087868 },
    { lat: 49.416012, lng: 19.086527 },
    { lat: 49.415995, lng: 19.086038 },
    { lat: 49.416292, lng: 19.085526 },
    { lat: 49.416397, lng: 19.085069 },
    { lat: 49.41644, lng: 19.084048 },
    { lat: 49.416435, lng: 19.083418 },
    { lat: 49.416451, lng: 19.0829 },
    { lat: 49.41656, lng: 19.082218 },
    { lat: 49.416583, lng: 19.081928 },
    { lat: 49.416642, lng: 19.081196 },
    { lat: 49.416611, lng: 19.080746 },
    { lat: 49.416595, lng: 19.080044 },
    { lat: 49.416725, lng: 19.079066 },
    { lat: 49.416781, lng: 19.078633 },
    { lat: 49.416873, lng: 19.077755 },
    { lat: 49.417028, lng: 19.076536 },
    { lat: 49.417456, lng: 19.074356 },
    { lat: 49.417897, lng: 19.072972 },
    { lat: 49.417984, lng: 19.072731 },
    { lat: 49.4175715, lng: 19.0723637 },
    { lat: 49.4170333, lng: 19.0719206 },
    { lat: 49.416315, lng: 19.0714293 },
    { lat: 49.4157085, lng: 19.0710967 },
    { lat: 49.4157052, lng: 19.071048 },
    { lat: 49.4147864, lng: 19.0707933 },
    { lat: 49.4137376, lng: 19.0706539 },
    { lat: 49.4128504, lng: 19.0702114 },
    { lat: 49.4123937, lng: 19.0701891 },
    { lat: 49.412628, lng: 19.06975 },
    { lat: 49.412788, lng: 19.06921 },
    { lat: 49.412957, lng: 19.068771 },
    { lat: 49.4131386, lng: 19.0681164 },
    { lat: 49.413303, lng: 19.067479 },
    { lat: 49.4136021, lng: 19.0664101 },
    { lat: 49.413882, lng: 19.0658103 },
    { lat: 49.4142013, lng: 19.0651676 },
    { lat: 49.4143222, lng: 19.0647897 },
    { lat: 49.4144593, lng: 19.0643618 },
    { lat: 49.4144709, lng: 19.0638709 },
    { lat: 49.4143374, lng: 19.0634415 },
    { lat: 49.4142473, lng: 19.0630422 },
    { lat: 49.4139974, lng: 19.0624946 },
    { lat: 49.4139638, lng: 19.0619694 },
    { lat: 49.4141172, lng: 19.0612139 },
    { lat: 49.4141771, lng: 19.0605333 },
    { lat: 49.4142866, lng: 19.0596051 },
    { lat: 49.414504, lng: 19.0586669 },
    { lat: 49.4146674, lng: 19.0578772 },
    { lat: 49.4147144, lng: 19.0574313 },
    { lat: 49.4146197, lng: 19.0568227 },
    { lat: 49.4147106, lng: 19.0564577 },
    { lat: 49.4147081, lng: 19.0561235 },
    { lat: 49.4147564, lng: 19.055618 },
    { lat: 49.4149198, lng: 19.0549276 },
    { lat: 49.4150487, lng: 19.0545836 },
    { lat: 49.4151627, lng: 19.0539125 },
    { lat: 49.4144025, lng: 19.0534608 },
    { lat: 49.4132228, lng: 19.0527178 },
    { lat: 49.4120599, lng: 19.0524872 },
    { lat: 49.4112544, lng: 19.0523861 },
    { lat: 49.4104126, lng: 19.051853 },
    { lat: 49.4092632, lng: 19.0507325 },
    { lat: 49.4088976, lng: 19.0501377 },
    { lat: 49.4081713, lng: 19.0490553 },
    { lat: 49.4075476, lng: 19.0484011 },
    { lat: 49.4071372, lng: 19.0481706 },
    { lat: 49.4070731, lng: 19.048136 },
    { lat: 49.4064487, lng: 19.0469439 },
    { lat: 49.4060104, lng: 19.0459541 },
    { lat: 49.4051254, lng: 19.0447354 },
    { lat: 49.4047359, lng: 19.0443084 },
    { lat: 49.4032507, lng: 19.0453353 },
    { lat: 49.402776, lng: 19.045591 },
    { lat: 49.4019114, lng: 19.0460587 },
    { lat: 49.4013211, lng: 19.0455418 },
    { lat: 49.4004467, lng: 19.044693 },
    { lat: 49.3995001, lng: 19.0425153 },
    { lat: 49.3988631, lng: 19.0414007 },
    { lat: 49.3982961, lng: 19.0401954 },
    { lat: 49.3977623, lng: 19.0394177 },
    { lat: 49.3970754, lng: 19.0386903 },
    { lat: 49.3965863, lng: 19.0375516 },
    { lat: 49.3960686, lng: 19.0363388 },
    { lat: 49.395923, lng: 19.035814 },
    { lat: 49.3957194, lng: 19.0350225 },
    { lat: 49.3956453, lng: 19.0334369 },
    { lat: 49.3955907, lng: 19.0322802 },
    { lat: 49.3953631, lng: 19.0310511 },
    { lat: 49.3949749, lng: 19.0297664 },
    { lat: 49.3946231, lng: 19.02859 },
    { lat: 49.3939781, lng: 19.0269995 },
    { lat: 49.3939945, lng: 19.0264935 },
    { lat: 49.3940109, lng: 19.0259001 },
    { lat: 49.394156, lng: 19.0252034 },
    { lat: 49.3945761, lng: 19.0235019 },
    { lat: 49.3947125, lng: 19.02204 },
    { lat: 49.3950659, lng: 19.0198895 },
    { lat: 49.3954611, lng: 19.0182981 },
    { lat: 49.3958509, lng: 19.0170999 },
    { lat: 49.3962043, lng: 19.016008 },
    { lat: 49.3963043, lng: 19.0153396 },
    { lat: 49.3964334, lng: 19.0148141 },
    { lat: 49.396198, lng: 19.01423 },
    { lat: 49.3960183, lng: 19.0137456 },
    { lat: 49.3958312, lng: 19.0132621 },
    { lat: 49.3956524, lng: 19.0122671 },
    { lat: 49.3950939, lng: 19.0110644 },
    { lat: 49.394786, lng: 19.0100578 },
    { lat: 49.394825, lng: 19.0097745 },
    { lat: 49.3951759, lng: 19.0092721 },
    { lat: 49.3955575, lng: 19.0077368 },
    { lat: 49.3956971, lng: 19.0060216 },
    { lat: 49.3958026, lng: 19.0045222 },
    { lat: 49.3962812, lng: 19.0038698 },
    { lat: 49.3967087, lng: 19.0035894 },
    { lat: 49.3972246, lng: 19.0031287 },
    { lat: 49.3974913, lng: 19.0024729 },
    { lat: 49.3976566, lng: 19.0017009 },
    { lat: 49.3976764, lng: 19.0015671 },
    { lat: 49.3976911, lng: 19.0014689 },
    { lat: 49.3978915, lng: 19.0001347 },
    { lat: 49.3979039, lng: 18.9975404 },
    { lat: 49.3981298, lng: 18.9949633 },
    { lat: 49.398041, lng: 18.9924027 },
    { lat: 49.3982724, lng: 18.9902972 },
    { lat: 49.3983803, lng: 18.9896423 },
    { lat: 49.3979571, lng: 18.9889795 },
    { lat: 49.3976151, lng: 18.9884453 },
    { lat: 49.3969571, lng: 18.9873141 },
    { lat: 49.3968703, lng: 18.9867958 },
    { lat: 49.3967321, lng: 18.9859664 },
    { lat: 49.3964846, lng: 18.9848398 },
    { lat: 49.3961098, lng: 18.9835799 },
    { lat: 49.395806, lng: 18.9825105 },
    { lat: 49.3955196, lng: 18.9812255 },
    { lat: 49.395385, lng: 18.98062 },
    { lat: 49.3952215, lng: 18.9798538 },
    { lat: 49.3941516, lng: 18.9792551 },
    { lat: 49.3940439, lng: 18.9786322 },
    { lat: 49.3937412, lng: 18.9779931 },
    { lat: 49.3929105, lng: 18.9775218 },
    { lat: 49.392606, lng: 18.9771775 },
    { lat: 49.3916979, lng: 18.9765795 },
    { lat: 49.3905229, lng: 18.9753083 },
    { lat: 49.3903456, lng: 18.9753248 },
    { lat: 49.3901341, lng: 18.9750638 },
    { lat: 49.3898111, lng: 18.9749153 },
    { lat: 49.3895349, lng: 18.9743536 },
    { lat: 49.3890314, lng: 18.9739361 },
    { lat: 49.3884697, lng: 18.9728029 },
    { lat: 49.3874165, lng: 18.9727486 },
    { lat: 49.3868222, lng: 18.9728582 },
    { lat: 49.3856151, lng: 18.9729283 },
    { lat: 49.3850084, lng: 18.9727467 },
    { lat: 49.3830897, lng: 18.9726562 },
    { lat: 49.381032, lng: 18.9716804 },
    { lat: 49.3800286, lng: 18.9725306 },
    { lat: 49.3797714, lng: 18.9727956 },
    { lat: 49.3788021, lng: 18.9732036 },
    { lat: 49.3782097, lng: 18.9737569 },
    { lat: 49.3777179, lng: 18.9736627 },
    { lat: 49.37731, lng: 18.9739797 },
    { lat: 49.3769515, lng: 18.9744292 },
    { lat: 49.376417, lng: 18.9754174 },
    { lat: 49.3759803, lng: 18.9757009 },
    { lat: 49.3743626, lng: 18.97437 },
    { lat: 49.37358, lng: 18.9728365 },
    { lat: 49.3732436, lng: 18.9723742 },
    { lat: 49.373011, lng: 18.9716024 },
    { lat: 49.3726114, lng: 18.9709157 },
    { lat: 49.3717524, lng: 18.9703995 },
    { lat: 49.370997, lng: 18.9702397 },
    { lat: 49.3706313, lng: 18.9700935 },
    { lat: 49.3695582, lng: 18.969014 },
    { lat: 49.3693109, lng: 18.9686241 },
    { lat: 49.3683207, lng: 18.9681675 },
    { lat: 49.3674303, lng: 18.9679452 },
    { lat: 49.3667583, lng: 18.968031 },
    { lat: 49.3666714, lng: 18.9679653 },
    { lat: 49.3665471, lng: 18.9682284 },
    { lat: 49.3660121, lng: 18.9686201 },
    { lat: 49.3650671, lng: 18.969873 },
    { lat: 49.3648066, lng: 18.9703456 },
    { lat: 49.3645851, lng: 18.9710964 },
    { lat: 49.3645312, lng: 18.9716413 },
    { lat: 49.3639755, lng: 18.9727009 },
    { lat: 49.3637792, lng: 18.9729655 },
    { lat: 49.3634304, lng: 18.9736969 },
    { lat: 49.3629441, lng: 18.9741623 },
    { lat: 49.3624788, lng: 18.9743287 },
    { lat: 49.3617628, lng: 18.9742102 },
    { lat: 49.3611281, lng: 18.9742764 },
    { lat: 49.3605315, lng: 18.9742029 },
    { lat: 49.3598565, lng: 18.9743649 },
    { lat: 49.3596222, lng: 18.9744597 },
    { lat: 49.3594938, lng: 18.9745109 },
    { lat: 49.3594212, lng: 18.9745216 },
    { lat: 49.3593143, lng: 18.9744814 },
    { lat: 49.3588804, lng: 18.9742144 },
    { lat: 49.3585785, lng: 18.9743504 },
    { lat: 49.3583338, lng: 18.9742811 },
    { lat: 49.3581024, lng: 18.9742298 },
    { lat: 49.3579672, lng: 18.9744786 },
    { lat: 49.3577744, lng: 18.9744592 },
    { lat: 49.357598, lng: 18.9744709 },
    { lat: 49.357401, lng: 18.97454 },
    { lat: 49.3571779, lng: 18.9746565 },
    { lat: 49.3569587, lng: 18.9746468 },
    { lat: 49.3567338, lng: 18.9746136 },
    { lat: 49.3566611, lng: 18.9746489 },
    { lat: 49.3565173, lng: 18.9747909 },
    { lat: 49.3564072, lng: 18.9749795 },
    { lat: 49.3561465, lng: 18.9748593 },
    { lat: 49.3560211, lng: 18.9749435 },
    { lat: 49.3559253, lng: 18.9750592 },
    { lat: 49.3556649, lng: 18.975105 },
    { lat: 49.3554407, lng: 18.9750626 },
    { lat: 49.3553082, lng: 18.9750951 },
    { lat: 49.3551473, lng: 18.9752127 },
    { lat: 49.3549078, lng: 18.9751099 },
    { lat: 49.354818, lng: 18.9749856 },
    { lat: 49.3546457, lng: 18.9750785 },
    { lat: 49.3543773, lng: 18.9752322 },
    { lat: 49.3541916, lng: 18.9752609 },
    { lat: 49.3539948, lng: 18.9755197 },
    { lat: 49.3534944, lng: 18.975475 },
    { lat: 49.3534181, lng: 18.9757548 },
    { lat: 49.353383, lng: 18.9757503 },
    { lat: 49.3532569, lng: 18.975734 },
    { lat: 49.3530883, lng: 18.9758279 },
    { lat: 49.3529693, lng: 18.9757735 },
    { lat: 49.3529074, lng: 18.9756166 },
    { lat: 49.3528027, lng: 18.9755305 },
    { lat: 49.3526102, lng: 18.9756019 },
    { lat: 49.3524646, lng: 18.9754635 },
    { lat: 49.3523404, lng: 18.975485 },
    { lat: 49.3520571, lng: 18.9757005 },
    { lat: 49.351818, lng: 18.9756508 },
    { lat: 49.351615, lng: 18.9753155 },
    { lat: 49.3514966, lng: 18.9753377 },
    { lat: 49.3513946, lng: 18.9752354 },
    { lat: 49.3512568, lng: 18.975299 },
    { lat: 49.3511264, lng: 18.9755294 },
    { lat: 49.3510226, lng: 18.9756421 },
    { lat: 49.350929, lng: 18.9756394 },
    { lat: 49.3506512, lng: 18.9754272 },
    { lat: 49.3501862, lng: 18.9755725 },
    { lat: 49.3500563, lng: 18.9755443 },
    { lat: 49.3499391, lng: 18.975322 },
    { lat: 49.3497609, lng: 18.9752959 },
    { lat: 49.3496038, lng: 18.975357 },
    { lat: 49.349237, lng: 18.9753252 },
    { lat: 49.3489422, lng: 18.9752694 },
    { lat: 49.3488083, lng: 18.9752265 },
    { lat: 49.3486364, lng: 18.9751487 },
    { lat: 49.3485527, lng: 18.9751893 },
    { lat: 49.3482439, lng: 18.9753488 },
    { lat: 49.3481499, lng: 18.9751378 },
    { lat: 49.3481408, lng: 18.975115 },
    { lat: 49.3480225, lng: 18.9750927 },
    { lat: 49.3477846, lng: 18.9750462 },
    { lat: 49.3473522, lng: 18.9750991 },
    { lat: 49.3471971, lng: 18.9751111 },
    { lat: 49.3471381, lng: 18.9751267 },
    { lat: 49.3444008, lng: 18.9730523 },
    { lat: 49.3438079, lng: 18.9742647 },
    { lat: 49.3434867, lng: 18.9757337 },
    { lat: 49.3431587, lng: 18.9762484 },
    { lat: 49.343064, lng: 18.9772877 },
    { lat: 49.3433699, lng: 18.9799293 },
    { lat: 49.3429426, lng: 18.9799483 },
    { lat: 49.3413627, lng: 18.9808594 },
    { lat: 49.3413209, lng: 18.9808839 },
    { lat: 49.3403919, lng: 18.9810582 },
    { lat: 49.3384176, lng: 18.9812531 },
    { lat: 49.3381035, lng: 18.9812837 },
    { lat: 49.337242, lng: 18.9813688 },
    { lat: 49.3361281, lng: 18.9816406 },
    { lat: 49.335218, lng: 18.9811541 },
    { lat: 49.3343492, lng: 18.9812317 },
    { lat: 49.3333001, lng: 18.9811739 },
    { lat: 49.3319438, lng: 18.9810997 },
    { lat: 49.3306684, lng: 18.9811143 },
    { lat: 49.3291367, lng: 18.9810524 },
    { lat: 49.328663, lng: 18.9801006 },
    { lat: 49.328612, lng: 18.9790823 },
    { lat: 49.3274046, lng: 18.9797377 },
    { lat: 49.3278169, lng: 18.9825663 },
    { lat: 49.327745, lng: 18.982754 },
    { lat: 49.3283658, lng: 18.98502 },
    { lat: 49.3278628, lng: 18.9858723 },
    { lat: 49.326179, lng: 18.9903466 },
    { lat: 49.3264897, lng: 18.9917798 },
    { lat: 49.326048, lng: 18.9924144 },
    { lat: 49.3255416, lng: 18.9934703 },
    { lat: 49.3250326, lng: 18.9937507 },
    { lat: 49.3246707, lng: 18.9935031 },
    { lat: 49.3242212, lng: 18.9936663 },
    { lat: 49.3238192, lng: 18.9942857 },
    { lat: 49.3232414, lng: 18.9940016 },
    { lat: 49.3225694, lng: 18.9935489 },
    { lat: 49.3222512, lng: 18.9934965 },
    { lat: 49.3219313, lng: 18.9940442 },
    { lat: 49.3215177, lng: 18.9939787 },
    { lat: 49.3208897, lng: 18.9943108 },
    { lat: 49.3204414, lng: 18.9940048 },
    { lat: 49.3204502, lng: 18.9986046 },
    { lat: 49.319444, lng: 18.9985454 },
    { lat: 49.318537, lng: 18.9966827 },
    { lat: 49.3181695, lng: 18.9959119 },
    { lat: 49.3179154, lng: 18.9943481 },
    { lat: 49.3173917, lng: 18.9931609 },
    { lat: 49.3171433, lng: 18.9916174 },
    { lat: 49.3171422, lng: 18.9906036 },
    { lat: 49.316749, lng: 18.9898633 },
    { lat: 49.31679, lng: 18.9892861 },
    { lat: 49.315447, lng: 18.9875462 },
    { lat: 49.3152168, lng: 18.986594 },
    { lat: 49.3150344, lng: 18.9847046 },
    { lat: 49.314677, lng: 18.982259 },
    { lat: 49.3145, lng: 18.982276 },
    { lat: 49.314442, lng: 18.982282 },
    { lat: 49.313905, lng: 18.982338 },
    { lat: 49.31319, lng: 18.982518 },
    { lat: 49.312554, lng: 18.982688 },
    { lat: 49.311168, lng: 18.982513 },
    { lat: 49.31065, lng: 18.98246 },
    { lat: 49.310127, lng: 18.982985 },
    { lat: 49.309156, lng: 18.982859 },
    { lat: 49.309111, lng: 18.982881 },
    { lat: 49.308758, lng: 18.983234 },
    { lat: 49.308753, lng: 18.984369 },
    { lat: 49.308465, lng: 18.984417 },
    { lat: 49.308224, lng: 18.984742 },
    { lat: 49.308171, lng: 18.985699 },
    { lat: 49.307317, lng: 18.988608 },
    { lat: 49.307253, lng: 18.988829 },
    { lat: 49.307277, lng: 18.988913 },
    { lat: 49.307483, lng: 18.98963 },
    { lat: 49.307481, lng: 18.989713 },
    { lat: 49.307463, lng: 18.990267 },
    { lat: 49.307512, lng: 18.99067 },
    { lat: 49.307523, lng: 18.990674 },
    { lat: 49.307983, lng: 18.990849 },
    { lat: 49.308363, lng: 18.992606 },
    { lat: 49.308358, lng: 18.993176 },
    { lat: 49.308337, lng: 18.993648 },
    { lat: 49.308324, lng: 18.993932 },
    { lat: 49.308163, lng: 18.994358 },
    { lat: 49.30804, lng: 18.994935 },
    { lat: 49.30795, lng: 18.995962 },
    { lat: 49.307923, lng: 18.996265 },
    { lat: 49.308043, lng: 18.997003 },
    { lat: 49.308091, lng: 18.99752 },
    { lat: 49.308298, lng: 18.998174 },
    { lat: 49.30847, lng: 18.999118 },
    { lat: 49.308428, lng: 18.99936 },
    { lat: 49.308291, lng: 19.00038 },
    { lat: 49.308586, lng: 19.002886 },
    { lat: 49.308548, lng: 19.00294 },
    { lat: 49.307529, lng: 19.004386 },
    { lat: 49.308064, lng: 19.005091 },
    { lat: 49.308389, lng: 19.005844 },
    { lat: 49.308668, lng: 19.00649 },
    { lat: 49.308915, lng: 19.00706 },
    { lat: 49.308983, lng: 19.007432 },
    { lat: 49.30909, lng: 19.007965 },
    { lat: 49.309133, lng: 19.008161 },
    { lat: 49.309188, lng: 19.008165 },
    { lat: 49.309316, lng: 19.008177 },
    { lat: 49.30966, lng: 19.008207 },
    { lat: 49.309971, lng: 19.008034 },
    { lat: 49.310101, lng: 19.009052 },
    { lat: 49.31014, lng: 19.009366 },
    { lat: 49.311356, lng: 19.009676 },
    { lat: 49.312507, lng: 19.010799 },
    { lat: 49.312511, lng: 19.010896 },
    { lat: 49.31252, lng: 19.011171 },
    { lat: 49.312522, lng: 19.011244 },
    { lat: 49.312559, lng: 19.01231 },
    { lat: 49.312581, lng: 19.012907 },
    { lat: 49.312599, lng: 19.013415 },
    { lat: 49.312395, lng: 19.013572 },
    { lat: 49.312389, lng: 19.013509 },
    { lat: 49.312209, lng: 19.013403 },
    { lat: 49.311434, lng: 19.015059 },
    { lat: 49.311206, lng: 19.015506 },
    { lat: 49.311098, lng: 19.015717 },
    { lat: 49.31103, lng: 19.015851 },
    { lat: 49.311005, lng: 19.015898 },
    { lat: 49.310901, lng: 19.016104 },
    { lat: 49.310862, lng: 19.015614 },
    { lat: 49.310853, lng: 19.015501 },
    { lat: 49.310824, lng: 19.015128 },
    { lat: 49.310565, lng: 19.01387 },
    { lat: 49.30996, lng: 19.013842 },
    { lat: 49.309905, lng: 19.01384 },
    { lat: 49.308937, lng: 19.012432 },
    { lat: 49.308411, lng: 19.012463 },
    { lat: 49.308951, lng: 19.014913 },
    { lat: 49.308953, lng: 19.015654 },
    { lat: 49.308811, lng: 19.016409 },
    { lat: 49.308813, lng: 19.017412 },
    { lat: 49.308536, lng: 19.017914 },
    { lat: 49.308441, lng: 19.018087 },
    { lat: 49.308014, lng: 19.018334 },
    { lat: 49.307534, lng: 19.019282 },
    { lat: 49.307196, lng: 19.019538 },
    { lat: 49.30729, lng: 19.020948 },
    { lat: 49.306253, lng: 19.02176 },
    { lat: 49.306042, lng: 19.021925 },
    { lat: 49.305981, lng: 19.021972 },
    { lat: 49.305609, lng: 19.022264 },
    { lat: 49.305459, lng: 19.022381 },
    { lat: 49.304694, lng: 19.022394 },
    { lat: 49.305394, lng: 19.022853 },
    { lat: 49.305268, lng: 19.024741 },
    { lat: 49.305589, lng: 19.025188 },
    { lat: 49.305673, lng: 19.025718 },
    { lat: 49.305715, lng: 19.02598 },
    { lat: 49.305965, lng: 19.026198 },
    { lat: 49.305625, lng: 19.026962 },
    { lat: 49.30416, lng: 19.030254 },
    { lat: 49.304109, lng: 19.030367 },
    { lat: 49.304364, lng: 19.031395 },
    { lat: 49.304375, lng: 19.031442 },
    { lat: 49.304478, lng: 19.031856 },
    { lat: 49.304502, lng: 19.032777 },
    { lat: 49.305848, lng: 19.035658 },
    { lat: 49.30606, lng: 19.036113 },
    { lat: 49.306748, lng: 19.036068 },
    { lat: 49.307886, lng: 19.036993 },
    { lat: 49.307629, lng: 19.036148 },
    { lat: 49.307906, lng: 19.035937 },
    { lat: 49.307989, lng: 19.035872 },
    { lat: 49.308052, lng: 19.037051 },
    { lat: 49.309766, lng: 19.038463 },
    { lat: 49.308066, lng: 19.041073 },
    { lat: 49.306377, lng: 19.043672 },
    { lat: 49.306335, lng: 19.044056 },
    { lat: 49.305814, lng: 19.044077 },
    { lat: 49.305421, lng: 19.044093 },
    { lat: 49.305061, lng: 19.045022 },
    { lat: 49.305059, lng: 19.045028 },
    { lat: 49.305056, lng: 19.04503 },
    { lat: 49.304148, lng: 19.045524 },
    { lat: 49.304105, lng: 19.04525 },
    { lat: 49.304096, lng: 19.045186 },
    { lat: 49.303977, lng: 19.045122 },
    { lat: 49.30396, lng: 19.045147 },
    { lat: 49.303777, lng: 19.045396 },
    { lat: 49.303649, lng: 19.045252 },
    { lat: 49.303516, lng: 19.045271 },
    { lat: 49.303265, lng: 19.045305 },
    { lat: 49.303068, lng: 19.045332 },
    { lat: 49.302968, lng: 19.045346 },
    { lat: 49.302992, lng: 19.045856 },
    { lat: 49.303037, lng: 19.046758 },
    { lat: 49.301685, lng: 19.048546 },
    { lat: 49.302157, lng: 19.049214 },
    { lat: 49.302008, lng: 19.049408 },
    { lat: 49.302131, lng: 19.049805 },
    { lat: 49.302226, lng: 19.050116 },
    { lat: 49.302261, lng: 19.05017 },
    { lat: 49.302501, lng: 19.050531 },
    { lat: 49.302763, lng: 19.050928 },
    { lat: 49.302825, lng: 19.050992 },
    { lat: 49.302971, lng: 19.051144 },
    { lat: 49.303064, lng: 19.051241 },
    { lat: 49.303423, lng: 19.051615 },
    { lat: 49.303639, lng: 19.051841 },
    { lat: 49.303872, lng: 19.052344 },
    { lat: 49.303946, lng: 19.052505 },
    { lat: 49.304088, lng: 19.052837 },
    { lat: 49.304287, lng: 19.0533 },
    { lat: 49.304876, lng: 19.053993 },
    { lat: 49.304901, lng: 19.054167 },
    { lat: 49.304935, lng: 19.054397 },
    { lat: 49.304968, lng: 19.054629 },
    { lat: 49.305041, lng: 19.055131 },
    { lat: 49.305092, lng: 19.05548 },
    { lat: 49.305107, lng: 19.055591 },
    { lat: 49.304773, lng: 19.05573 },
    { lat: 49.304659, lng: 19.055777 },
    { lat: 49.304472, lng: 19.055854 },
    { lat: 49.304408, lng: 19.055881 },
    { lat: 49.304285, lng: 19.055932 },
    { lat: 49.304061, lng: 19.056025 },
    { lat: 49.303917, lng: 19.056086 },
    { lat: 49.303964, lng: 19.056771 },
    { lat: 49.303952, lng: 19.057025 },
    { lat: 49.303921, lng: 19.057694 },
    { lat: 49.303904, lng: 19.058033 },
    { lat: 49.303586, lng: 19.058149 },
    { lat: 49.303952, lng: 19.059554 },
    { lat: 49.304293, lng: 19.060503 },
    { lat: 49.304305, lng: 19.061205 },
    { lat: 49.304307, lng: 19.061309 },
    { lat: 49.304008, lng: 19.061283 },
    { lat: 49.303709, lng: 19.061457 },
    { lat: 49.303684, lng: 19.062825 },
    { lat: 49.303645, lng: 19.062872 },
    { lat: 49.303677, lng: 19.063041 },
    { lat: 49.303685, lng: 19.06363 },
    { lat: 49.303645, lng: 19.063651 },
    { lat: 49.303609, lng: 19.064374 },
    { lat: 49.303594, lng: 19.064401 },
    { lat: 49.303539, lng: 19.064494 },
    { lat: 49.303361, lng: 19.064799 },
    { lat: 49.303288, lng: 19.065057 },
    { lat: 49.303254, lng: 19.065181 },
    { lat: 49.303166, lng: 19.065696 },
    { lat: 49.303064, lng: 19.066304 },
    { lat: 49.302912, lng: 19.066267 },
    { lat: 49.302863, lng: 19.066256 },
    { lat: 49.302789, lng: 19.066238 },
    { lat: 49.302452, lng: 19.066157 },
    { lat: 49.302381, lng: 19.06614 },
    { lat: 49.301862, lng: 19.066134 },
    { lat: 49.301832, lng: 19.066134 },
    { lat: 49.301745, lng: 19.066133 },
    { lat: 49.301649, lng: 19.066132 },
    { lat: 49.301605, lng: 19.066131 },
    { lat: 49.301546, lng: 19.06613 },
    { lat: 49.3014548, lng: 19.065949 },
    { lat: 49.301353, lng: 19.066051 },
    { lat: 49.301174, lng: 19.065978 },
    { lat: 49.301144, lng: 19.065948 },
    { lat: 49.300813, lng: 19.065614 },
    { lat: 49.300635, lng: 19.065434 },
    { lat: 49.3004, lng: 19.065196 },
    { lat: 49.300265, lng: 19.06506 },
    { lat: 49.300156, lng: 19.064447 },
    { lat: 49.300128, lng: 19.064439 },
    { lat: 49.299979, lng: 19.064396 },
    { lat: 49.299798, lng: 19.064344 },
    { lat: 49.299689, lng: 19.064313 },
    { lat: 49.299639, lng: 19.064299 },
    { lat: 49.299451, lng: 19.064312 },
    { lat: 49.299169, lng: 19.064331 },
    { lat: 49.299001, lng: 19.064343 },
    { lat: 49.29891, lng: 19.064415 },
    { lat: 49.2986, lng: 19.064665 },
    { lat: 49.298595, lng: 19.06469 },
    { lat: 49.298585, lng: 19.064748 },
    { lat: 49.298476, lng: 19.065375 },
    { lat: 49.29836, lng: 19.066039 },
    { lat: 49.298379, lng: 19.066217 },
    { lat: 49.298395, lng: 19.066368 },
    { lat: 49.29841, lng: 19.066513 },
    { lat: 49.29842, lng: 19.066599 },
    { lat: 49.298456, lng: 19.066737 },
    { lat: 49.298491, lng: 19.06687 },
    { lat: 49.298595, lng: 19.06726 },
    { lat: 49.298675, lng: 19.067561 },
    { lat: 49.298994, lng: 19.068155 },
    { lat: 49.299169, lng: 19.068482 },
    { lat: 49.299359, lng: 19.068834 },
    { lat: 49.29936, lng: 19.06913 },
    { lat: 49.299542, lng: 19.069468 },
    { lat: 49.299763, lng: 19.06982 },
    { lat: 49.299781, lng: 19.070235 },
    { lat: 49.300029, lng: 19.070909 },
    { lat: 49.300159, lng: 19.07113 },
    { lat: 49.300459, lng: 19.071644 },
    { lat: 49.300701, lng: 19.071916 },
    { lat: 49.300807, lng: 19.071975 },
    { lat: 49.300966, lng: 19.072065 },
    { lat: 49.301743, lng: 19.07236 },
    { lat: 49.301797, lng: 19.072381 },
    { lat: 49.301796, lng: 19.07246 },
    { lat: 49.301796, lng: 19.072554 },
    { lat: 49.301795, lng: 19.072647 },
    { lat: 49.301795, lng: 19.072786 },
    { lat: 49.301794, lng: 19.073067 },
    { lat: 49.3017, lng: 19.0737 },
    { lat: 49.301696, lng: 19.073726 },
    { lat: 49.301696, lng: 19.074071 },
    { lat: 49.301695, lng: 19.074239 },
    { lat: 49.301695, lng: 19.074358 },
    { lat: 49.301694, lng: 19.074398 },
    { lat: 49.301684, lng: 19.074551 },
    { lat: 49.30166, lng: 19.074942 },
    { lat: 49.30166, lng: 19.075411 },
    { lat: 49.301675, lng: 19.075715 },
    { lat: 49.301714, lng: 19.076001 },
    { lat: 49.3018378, lng: 19.076364 },
    { lat: 49.30188, lng: 19.076573 },
    { lat: 49.301923, lng: 19.076827 },
    { lat: 49.301976, lng: 19.077394 },
    { lat: 49.302034, lng: 19.077898 },
    { lat: 49.302074, lng: 19.078041 },
    { lat: 49.3021647, lng: 19.0781199 },
    { lat: 49.302245, lng: 19.078357 },
    { lat: 49.3023, lng: 19.078472 },
    { lat: 49.302356, lng: 19.07859 },
    { lat: 49.302434, lng: 19.078685 },
    { lat: 49.302412, lng: 19.078807 },
    { lat: 49.302425, lng: 19.079037 },
    { lat: 49.302426, lng: 19.079064 },
    { lat: 49.302483, lng: 19.079394 },
    { lat: 49.302896, lng: 19.080046 },
    { lat: 49.303074, lng: 19.080429 },
    { lat: 49.303173, lng: 19.080867 },
    { lat: 49.303199, lng: 19.080922 },
    { lat: 49.303343, lng: 19.08131 },
    { lat: 49.303602, lng: 19.082382 },
    { lat: 49.303646, lng: 19.082634 },
    { lat: 49.303676, lng: 19.082908 },
    { lat: 49.303683, lng: 19.082938 },
    { lat: 49.303716, lng: 19.08309 },
    { lat: 49.30379, lng: 19.083302 },
    { lat: 49.303985, lng: 19.083879 },
    { lat: 49.304198, lng: 19.084318 },
    { lat: 49.304208, lng: 19.084548 },
    { lat: 49.304306, lng: 19.085021 },
    { lat: 49.30434, lng: 19.085363 },
    { lat: 49.304352, lng: 19.085689 },
    { lat: 49.304424, lng: 19.086088 },
    { lat: 49.304432, lng: 19.086129 },
    { lat: 49.304453, lng: 19.086231 },
    { lat: 49.304571, lng: 19.086565 },
    { lat: 49.304678, lng: 19.08685 },
    { lat: 49.304856, lng: 19.087325 },
    { lat: 49.305109, lng: 19.087969 },
    { lat: 49.305174, lng: 19.088098 },
    { lat: 49.305179, lng: 19.088149 },
    { lat: 49.305303, lng: 19.088759 },
    { lat: 49.305306, lng: 19.088774 },
    { lat: 49.305335, lng: 19.089275 },
    { lat: 49.305378, lng: 19.089942 },
    { lat: 49.305633, lng: 19.091164 },
    { lat: 49.305638, lng: 19.091247 },
    { lat: 49.305654, lng: 19.091254 },
    { lat: 49.305683, lng: 19.091282 },
    { lat: 49.305734, lng: 19.091609 },
    { lat: 49.305769, lng: 19.091799 },
    { lat: 49.305972, lng: 19.092999 },
    { lat: 49.305988, lng: 19.093091 },
    { lat: 49.306211, lng: 19.094557 },
    { lat: 49.306371, lng: 19.094469 },
    { lat: 49.306515, lng: 19.094383 },
    { lat: 49.306567, lng: 19.09436 },
    { lat: 49.306589, lng: 19.094348 },
    { lat: 49.306605, lng: 19.094338 },
    { lat: 49.306691, lng: 19.094287 },
    { lat: 49.306773, lng: 19.094238 },
    { lat: 49.306778, lng: 19.094235 },
    { lat: 49.306846, lng: 19.094174 },
    { lat: 49.306919, lng: 19.09411 },
    { lat: 49.306947, lng: 19.094086 },
    { lat: 49.307011, lng: 19.094021 },
    { lat: 49.307148, lng: 19.093887 },
    { lat: 49.307183, lng: 19.093851 },
    { lat: 49.307184, lng: 19.09383 },
    { lat: 49.307294, lng: 19.0936 },
    { lat: 49.307308, lng: 19.09357 },
    { lat: 49.307446, lng: 19.093493 },
    { lat: 49.307546, lng: 19.093444 },
    { lat: 49.307674, lng: 19.093739 },
    { lat: 49.307709, lng: 19.09402 },
    { lat: 49.307772, lng: 19.094228 },
    { lat: 49.307935, lng: 19.094053 },
    { lat: 49.308101, lng: 19.09429 },
    { lat: 49.308318, lng: 19.094596 },
    { lat: 49.308391, lng: 19.094653 },
    { lat: 49.308487, lng: 19.094476 },
    { lat: 49.308629, lng: 19.094946 },
    { lat: 49.308806, lng: 19.095427 },
    { lat: 49.308609, lng: 19.096159 },
    { lat: 49.308722, lng: 19.096909 },
    { lat: 49.30877, lng: 19.096784 },
    { lat: 49.308924, lng: 19.097027 },
    { lat: 49.308973, lng: 19.097102 },
    { lat: 49.308989, lng: 19.097381 },
    { lat: 49.309144, lng: 19.097654 },
    { lat: 49.309347, lng: 19.098017 },
    { lat: 49.309583, lng: 19.098282 },
    { lat: 49.309631, lng: 19.098338 },
    { lat: 49.309726, lng: 19.098444 },
    { lat: 49.309814, lng: 19.098383 },
    { lat: 49.310133, lng: 19.098127 },
    { lat: 49.309987, lng: 19.097213 },
    { lat: 49.310183, lng: 19.097179 },
    { lat: 49.311032, lng: 19.09729 },
    { lat: 49.311342, lng: 19.09745 },
    { lat: 49.311783, lng: 19.097688 },
    { lat: 49.311808, lng: 19.097702 },
    { lat: 49.311743, lng: 19.098086 },
    { lat: 49.311719, lng: 19.098362 },
    { lat: 49.311734, lng: 19.098705 },
    { lat: 49.311842, lng: 19.099271 },
    { lat: 49.311895, lng: 19.099649 },
    { lat: 49.311864, lng: 19.100532 },
    { lat: 49.311915, lng: 19.10144 },
    { lat: 49.311844, lng: 19.101777 },
    { lat: 49.311046, lng: 19.103488 },
    { lat: 49.311056, lng: 19.103538 },
    { lat: 49.31119, lng: 19.10393 },
    { lat: 49.311185, lng: 19.104224 },
    { lat: 49.311115, lng: 19.104654 },
    { lat: 49.311039, lng: 19.104744 },
    { lat: 49.310963, lng: 19.104744 },
    { lat: 49.310788, lng: 19.104716 },
    { lat: 49.310715, lng: 19.104774 },
    { lat: 49.310614, lng: 19.105154 },
    { lat: 49.310598, lng: 19.105328 },
    { lat: 49.310608, lng: 19.105358 },
    { lat: 49.310632, lng: 19.105463 },
    { lat: 49.310799, lng: 19.105729 },
    { lat: 49.310897, lng: 19.106095 },
    { lat: 49.31097, lng: 19.10638 },
    { lat: 49.311015, lng: 19.106625 },
    { lat: 49.310978, lng: 19.107252 },
    { lat: 49.311016, lng: 19.107651 },
    { lat: 49.311093, lng: 19.107898 },
    { lat: 49.311169, lng: 19.108266 },
    { lat: 49.311151, lng: 19.108706 },
    { lat: 49.311171, lng: 19.108886 },
    { lat: 49.311141, lng: 19.108871 },
    { lat: 49.311046, lng: 19.108804 },
    { lat: 49.309758, lng: 19.10801 },
    { lat: 49.309409, lng: 19.109887 },
    { lat: 49.309429, lng: 19.110122 },
    { lat: 49.309649, lng: 19.111837 },
    { lat: 49.309304, lng: 19.111954 },
    { lat: 49.309356, lng: 19.113248 },
    { lat: 49.308768, lng: 19.113675 },
    { lat: 49.30766, lng: 19.113383 },
    { lat: 49.307584, lng: 19.113633 },
    { lat: 49.307671, lng: 19.113965 },
    { lat: 49.307646, lng: 19.114157 },
    { lat: 49.307585, lng: 19.114516 },
    { lat: 49.307609, lng: 19.114699 },
    { lat: 49.307719, lng: 19.115096 },
    { lat: 49.307869, lng: 19.115476 },
    { lat: 49.307905, lng: 19.115924 },
    { lat: 49.308015, lng: 19.116382 },
    { lat: 49.308119, lng: 19.116705 },
    { lat: 49.308228, lng: 19.116919 },
    { lat: 49.30833, lng: 19.11711 },
    { lat: 49.308451, lng: 19.117439 },
    { lat: 49.308582, lng: 19.117852 },
    { lat: 49.308623, lng: 19.117958 },
    { lat: 49.308828, lng: 19.118511 },
    { lat: 49.308845, lng: 19.118519 },
    { lat: 49.309034, lng: 19.118811 },
    { lat: 49.309325, lng: 19.119157 },
    { lat: 49.309673, lng: 19.119373 },
    { lat: 49.310011, lng: 19.119609 },
    { lat: 49.310343, lng: 19.120099 },
    { lat: 49.310601, lng: 19.120313 },
    { lat: 49.31071, lng: 19.120417 },
    { lat: 49.310804, lng: 19.120496 },
    { lat: 49.311022, lng: 19.120821 },
    { lat: 49.311147, lng: 19.121013 },
    { lat: 49.311262, lng: 19.121108 },
    { lat: 49.311333, lng: 19.121198 },
    { lat: 49.311656, lng: 19.12148 },
    { lat: 49.311609, lng: 19.121598 },
    { lat: 49.310965, lng: 19.121838 },
    { lat: 49.310216, lng: 19.122118 },
    { lat: 49.309055, lng: 19.122551 },
    { lat: 49.307782, lng: 19.122947 },
    { lat: 49.307495, lng: 19.123618 },
    { lat: 49.307154, lng: 19.124658 },
    { lat: 49.307001, lng: 19.126487 },
    { lat: 49.307891, lng: 19.129892 },
    { lat: 49.3087, lng: 19.131099 },
    { lat: 49.309528, lng: 19.132333 },
    { lat: 49.30969, lng: 19.132564 },
    { lat: 49.310822, lng: 19.134228 },
    { lat: 49.311477, lng: 19.135244 },
    { lat: 49.312065, lng: 19.136173 },
    { lat: 49.312934, lng: 19.137467 },
    { lat: 49.313174, lng: 19.137815 },
    { lat: 49.313317, lng: 19.138027 },
    { lat: 49.313852, lng: 19.138839 },
    { lat: 49.31463, lng: 19.139991 },
    { lat: 49.315228, lng: 19.140888 },
    { lat: 49.315689, lng: 19.141572 },
    { lat: 49.316018, lng: 19.142062 },
    { lat: 49.316269, lng: 19.1424276 },
    { lat: 49.316629, lng: 19.142952 },
    { lat: 49.317756, lng: 19.14462 },
    { lat: 49.318224, lng: 19.145319 },
    { lat: 49.318738, lng: 19.146081 },
    { lat: 49.319541, lng: 19.147223 },
    { lat: 49.320013, lng: 19.147923 },
    { lat: 49.320623, lng: 19.148827 },
    { lat: 49.321292, lng: 19.14985 },
    { lat: 49.322013, lng: 19.150785 },
    { lat: 49.323392, lng: 19.152827 },
    { lat: 49.324846, lng: 19.1549 },
    { lat: 49.324928, lng: 19.15502 },
    { lat: 49.325275, lng: 19.155533 },
    { lat: 49.326017, lng: 19.156634 },
    { lat: 49.326623, lng: 19.157635 },
    { lat: 49.32735, lng: 19.158651 },
    { lat: 49.328069, lng: 19.1596 },
    { lat: 49.328501, lng: 19.160214 },
    { lat: 49.328529, lng: 19.160256 },
    { lat: 49.328724, lng: 19.160534 },
    { lat: 49.328971, lng: 19.15944 },
    { lat: 49.329001, lng: 19.159317 },
    { lat: 49.329033, lng: 19.159173 },
    { lat: 49.329191, lng: 19.15847 },
    { lat: 49.329431, lng: 19.157375 },
    { lat: 49.329569, lng: 19.15699 },
    { lat: 49.329696, lng: 19.156634 },
    { lat: 49.329795, lng: 19.156399 },
    { lat: 49.330173, lng: 19.155512 },
    { lat: 49.330204, lng: 19.155434 },
    { lat: 49.330282, lng: 19.154984 },
    { lat: 49.330285, lng: 19.15473 },
    { lat: 49.330287, lng: 19.15442 },
    { lat: 49.330296, lng: 19.153735 },
    { lat: 49.330297, lng: 19.153502 },
    { lat: 49.3303, lng: 19.153398 },
    { lat: 49.330304, lng: 19.152858 },
    { lat: 49.330305, lng: 19.152702 },
    { lat: 49.330307, lng: 19.152464 },
    { lat: 49.330358, lng: 19.15207 },
    { lat: 49.330441, lng: 19.151744 },
    { lat: 49.330636, lng: 19.150985 },
    { lat: 49.330678, lng: 19.150822 },
    { lat: 49.3307, lng: 19.15074 },
    { lat: 49.330725, lng: 19.150642 },
    { lat: 49.330923, lng: 19.149874 },
    { lat: 49.331467, lng: 19.14906 },
    { lat: 49.331557, lng: 19.148888 },
    { lat: 49.331837, lng: 19.14835 },
    { lat: 49.33209, lng: 19.147863 },
    { lat: 49.332091, lng: 19.147862 },
    { lat: 49.332102, lng: 19.14784 },
    { lat: 49.332174, lng: 19.147701 },
    { lat: 49.332209, lng: 19.147635 },
    { lat: 49.332452, lng: 19.147241 },
    { lat: 49.332702, lng: 19.146833 },
    { lat: 49.333338, lng: 19.146323 },
    { lat: 49.333364, lng: 19.146303 },
  ],
  Podvysoká: [
    { lat: 49.4019018, lng: 18.6862975 },
    { lat: 49.4023644, lng: 18.6860036 },
    { lat: 49.4025439, lng: 18.6859424 },
    { lat: 49.4029249, lng: 18.6860258 },
    { lat: 49.4031087, lng: 18.6861331 },
    { lat: 49.4035254, lng: 18.6859915 },
    { lat: 49.4038154, lng: 18.686253 },
    { lat: 49.4040645, lng: 18.68634 },
    { lat: 49.4044069, lng: 18.6861149 },
    { lat: 49.4044691, lng: 18.6860124 },
    { lat: 49.404579, lng: 18.6859764 },
    { lat: 49.4047141, lng: 18.6861687 },
    { lat: 49.405064, lng: 18.6859383 },
    { lat: 49.4050831, lng: 18.6858537 },
    { lat: 49.4055068, lng: 18.6856509 },
    { lat: 49.4055969, lng: 18.6856075 },
    { lat: 49.4056684, lng: 18.6855734 },
    { lat: 49.4057465, lng: 18.6855359 },
    { lat: 49.4059378, lng: 18.6854442 },
    { lat: 49.4059565, lng: 18.6854354 },
    { lat: 49.4059775, lng: 18.6854255 },
    { lat: 49.4060623, lng: 18.6853847 },
    { lat: 49.4061238, lng: 18.685355 },
    { lat: 49.4061667, lng: 18.6853345 },
    { lat: 49.4062734, lng: 18.6852833 },
    { lat: 49.4062976, lng: 18.685272 },
    { lat: 49.4063262, lng: 18.6852583 },
    { lat: 49.4063727, lng: 18.6852443 },
    { lat: 49.4064483, lng: 18.6852212 },
    { lat: 49.4064593, lng: 18.6852184 },
    { lat: 49.4065372, lng: 18.6851946 },
    { lat: 49.4066781, lng: 18.6851519 },
    { lat: 49.4068068, lng: 18.6851132 },
    { lat: 49.4086628, lng: 18.6845551 },
    { lat: 49.4095187, lng: 18.6842091 },
    { lat: 49.4101724, lng: 18.6839452 },
    { lat: 49.4106473, lng: 18.6836306 },
    { lat: 49.4117007, lng: 18.6828781 },
    { lat: 49.4125294, lng: 18.6824922 },
    { lat: 49.4129459, lng: 18.6823279 },
    { lat: 49.4130839, lng: 18.6822304 },
    { lat: 49.4132646, lng: 18.6821259 },
    { lat: 49.4139342, lng: 18.6818448 },
    { lat: 49.4142915, lng: 18.6818405 },
    { lat: 49.4149712, lng: 18.6818349 },
    { lat: 49.4156093, lng: 18.6816616 },
    { lat: 49.4158414, lng: 18.6815283 },
    { lat: 49.4160789, lng: 18.6813921 },
    { lat: 49.4161616, lng: 18.6813348 },
    { lat: 49.4162859, lng: 18.6812753 },
    { lat: 49.4162999, lng: 18.6812859 },
    { lat: 49.4163532, lng: 18.6812474 },
    { lat: 49.4163782, lng: 18.6812298 },
    { lat: 49.4164047, lng: 18.6812219 },
    { lat: 49.4164625, lng: 18.6812234 },
    { lat: 49.4165336, lng: 18.6812462 },
    { lat: 49.4165616, lng: 18.6812666 },
    { lat: 49.4166548, lng: 18.6813449 },
    { lat: 49.4166702, lng: 18.6813586 },
    { lat: 49.4166798, lng: 18.6813484 },
    { lat: 49.416713, lng: 18.6813135 },
    { lat: 49.4167206, lng: 18.6813057 },
    { lat: 49.416766, lng: 18.6812707 },
    { lat: 49.4168254, lng: 18.6812416 },
    { lat: 49.4170058, lng: 18.681198 },
    { lat: 49.4171004, lng: 18.6811769 },
    { lat: 49.4171782, lng: 18.6811731 },
    { lat: 49.417245, lng: 18.6811804 },
    { lat: 49.417372, lng: 18.6812144 },
    { lat: 49.4174589, lng: 18.6812373 },
    { lat: 49.4175488, lng: 18.6812523 },
    { lat: 49.4178275, lng: 18.6812987 },
    { lat: 49.4181917, lng: 18.6814084 },
    { lat: 49.4182976, lng: 18.6814261 },
    { lat: 49.4184017, lng: 18.6814342 },
    { lat: 49.4184791, lng: 18.6814269 },
    { lat: 49.4186158, lng: 18.681389 },
    { lat: 49.4188441, lng: 18.6813536 },
    { lat: 49.4188474, lng: 18.6813539 },
    { lat: 49.4188869, lng: 18.6813555 },
    { lat: 49.4188914, lng: 18.6813553 },
    { lat: 49.4190049, lng: 18.6813405 },
    { lat: 49.4190909, lng: 18.6813292 },
    { lat: 49.4191041, lng: 18.6813236 },
    { lat: 49.4191073, lng: 18.6813225 },
    { lat: 49.4191751, lng: 18.6813044 },
    { lat: 49.4191798, lng: 18.6813034 },
    { lat: 49.4193284, lng: 18.6812354 },
    { lat: 49.4193793, lng: 18.6812187 },
    { lat: 49.4194924, lng: 18.6811819 },
    { lat: 49.4194989, lng: 18.6800829 },
    { lat: 49.4195378, lng: 18.6792979 },
    { lat: 49.4195512, lng: 18.6792296 },
    { lat: 49.4195558, lng: 18.6791913 },
    { lat: 49.4195597, lng: 18.6791572 },
    { lat: 49.4196476, lng: 18.678472 },
    { lat: 49.4196909, lng: 18.678134 },
    { lat: 49.419675, lng: 18.6775757 },
    { lat: 49.4196646, lng: 18.6768435 },
    { lat: 49.4196225, lng: 18.6759117 },
    { lat: 49.4195693, lng: 18.6751152 },
    { lat: 49.4195201, lng: 18.674716 },
    { lat: 49.4193512, lng: 18.6741417 },
    { lat: 49.41926, lng: 18.673994 },
    { lat: 49.4190786, lng: 18.6738064 },
    { lat: 49.4187522, lng: 18.6735808 },
    { lat: 49.4180436, lng: 18.6732943 },
    { lat: 49.417661, lng: 18.6731647 },
    { lat: 49.4174489, lng: 18.6730929 },
    { lat: 49.4170192, lng: 18.6728965 },
    { lat: 49.416724, lng: 18.6726225 },
    { lat: 49.416331, lng: 18.6720558 },
    { lat: 49.4161, lng: 18.6716932 },
    { lat: 49.4157224, lng: 18.6708866 },
    { lat: 49.415751, lng: 18.6700719 },
    { lat: 49.4157608, lng: 18.6697927 },
    { lat: 49.4157706, lng: 18.6697629 },
    { lat: 49.4157907, lng: 18.6694781 },
    { lat: 49.4158042, lng: 18.6692879 },
    { lat: 49.4158149, lng: 18.6678391 },
    { lat: 49.4158157, lng: 18.6677306 },
    { lat: 49.4158132, lng: 18.6674904 },
    { lat: 49.4155448, lng: 18.6668275 },
    { lat: 49.4154334, lng: 18.6666178 },
    { lat: 49.4148478, lng: 18.6655182 },
    { lat: 49.4145976, lng: 18.6645775 },
    { lat: 49.4142927, lng: 18.6641419 },
    { lat: 49.4142365, lng: 18.6641014 },
    { lat: 49.4140433, lng: 18.6640536 },
    { lat: 49.4140791, lng: 18.6638992 },
    { lat: 49.4141086, lng: 18.6637739 },
    { lat: 49.4131223, lng: 18.6615988 },
    { lat: 49.4130579, lng: 18.661551 },
    { lat: 49.4128416, lng: 18.6613883 },
    { lat: 49.4127078, lng: 18.6610293 },
    { lat: 49.4125819, lng: 18.6606951 },
    { lat: 49.4124557, lng: 18.6603587 },
    { lat: 49.4123416, lng: 18.6600538 },
    { lat: 49.4123312, lng: 18.6600261 },
    { lat: 49.412276, lng: 18.6600695 },
    { lat: 49.4122524, lng: 18.6600913 },
    { lat: 49.4120728, lng: 18.6602569 },
    { lat: 49.4119836, lng: 18.6603625 },
    { lat: 49.4119557, lng: 18.6602196 },
    { lat: 49.4119584, lng: 18.6601845 },
    { lat: 49.4119522, lng: 18.660152 },
    { lat: 49.4118935, lng: 18.6599575 },
    { lat: 49.4118129, lng: 18.6596926 },
    { lat: 49.4117313, lng: 18.6594275 },
    { lat: 49.4116533, lng: 18.6591674 },
    { lat: 49.4115703, lng: 18.6588966 },
    { lat: 49.4115606, lng: 18.6588656 },
    { lat: 49.4113676, lng: 18.6589884 },
    { lat: 49.4113152, lng: 18.6588724 },
    { lat: 49.4113089, lng: 18.6588598 },
    { lat: 49.4112783, lng: 18.6587919 },
    { lat: 49.4112552, lng: 18.658739 },
    { lat: 49.4112252, lng: 18.6585544 },
    { lat: 49.411176, lng: 18.6582454 },
    { lat: 49.4111283, lng: 18.6580894 },
    { lat: 49.4111059, lng: 18.6580263 },
    { lat: 49.411094, lng: 18.6577573 },
    { lat: 49.4110717, lng: 18.6575902 },
    { lat: 49.4110376, lng: 18.6574126 },
    { lat: 49.4108763, lng: 18.6573301 },
    { lat: 49.4107675, lng: 18.657274 },
    { lat: 49.4107884, lng: 18.6571199 },
    { lat: 49.4108648, lng: 18.6565569 },
    { lat: 49.4110272, lng: 18.6552507 },
    { lat: 49.4110323, lng: 18.6550064 },
    { lat: 49.4110097, lng: 18.6546644 },
    { lat: 49.4108676, lng: 18.6539143 },
    { lat: 49.4107781, lng: 18.6535226 },
    { lat: 49.4105546, lng: 18.6527084 },
    { lat: 49.4104773, lng: 18.6524398 },
    { lat: 49.410151, lng: 18.6520283 },
    { lat: 49.4094427, lng: 18.6519715 },
    { lat: 49.4094084, lng: 18.6519688 },
    { lat: 49.4093723, lng: 18.6519659 },
    { lat: 49.4089768, lng: 18.6519342 },
    { lat: 49.408532, lng: 18.6516899 },
    { lat: 49.4080418, lng: 18.6512053 },
    { lat: 49.4079393, lng: 18.6510811 },
    { lat: 49.4078596, lng: 18.6506409 },
    { lat: 49.4078003, lng: 18.6501757 },
    { lat: 49.4077119, lng: 18.6493809 },
    { lat: 49.4077513, lng: 18.648655 },
    { lat: 49.4075973, lng: 18.647725 },
    { lat: 49.4074729, lng: 18.6467138 },
    { lat: 49.4073905, lng: 18.6460522 },
    { lat: 49.4073296, lng: 18.6453888 },
    { lat: 49.4072217, lng: 18.6449705 },
    { lat: 49.4069718, lng: 18.6442744 },
    { lat: 49.4067245, lng: 18.6438206 },
    { lat: 49.4066638, lng: 18.6437586 },
    { lat: 49.406708, lng: 18.6435543 },
    { lat: 49.4064532, lng: 18.6433379 },
    { lat: 49.4062191, lng: 18.6433716 },
    { lat: 49.4059371, lng: 18.6434936 },
    { lat: 49.4057177, lng: 18.6438681 },
    { lat: 49.4055138, lng: 18.6439582 },
    { lat: 49.4054649, lng: 18.6439266 },
    { lat: 49.4049311, lng: 18.6440244 },
    { lat: 49.4042901, lng: 18.6437187 },
    { lat: 49.4037824, lng: 18.6435022 },
    { lat: 49.4035974, lng: 18.6432033 },
    { lat: 49.4034774, lng: 18.6427877 },
    { lat: 49.403512, lng: 18.6419053 },
    { lat: 49.4038665, lng: 18.6409151 },
    { lat: 49.4036461, lng: 18.6408809 },
    { lat: 49.4035805, lng: 18.6408159 },
    { lat: 49.4034857, lng: 18.6407302 },
    { lat: 49.4034617, lng: 18.640785 },
    { lat: 49.4034366, lng: 18.6408026 },
    { lat: 49.4034123, lng: 18.6408125 },
    { lat: 49.4033831, lng: 18.6408136 },
    { lat: 49.4033834, lng: 18.6408201 },
    { lat: 49.4033538, lng: 18.6408136 },
    { lat: 49.403345, lng: 18.6409234 },
    { lat: 49.403333, lng: 18.6409339 },
    { lat: 49.4030275, lng: 18.6408046 },
    { lat: 49.4028826, lng: 18.6407338 },
    { lat: 49.4027619, lng: 18.6406051 },
    { lat: 49.4027076, lng: 18.6405336 },
    { lat: 49.4025819, lng: 18.6399098 },
    { lat: 49.4025378, lng: 18.6398222 },
    { lat: 49.4024424, lng: 18.6397428 },
    { lat: 49.4021131, lng: 18.6397008 },
    { lat: 49.401764, lng: 18.6396899 },
    { lat: 49.4017885, lng: 18.6395148 },
    { lat: 49.4015707, lng: 18.6395104 },
    { lat: 49.401406, lng: 18.6395511 },
    { lat: 49.4013945, lng: 18.6396105 },
    { lat: 49.4011712, lng: 18.6397403 },
    { lat: 49.4010387, lng: 18.639705 },
    { lat: 49.4009062, lng: 18.6396766 },
    { lat: 49.4007585, lng: 18.6397388 },
    { lat: 49.4006325, lng: 18.6398094 },
    { lat: 49.4005433, lng: 18.639784 },
    { lat: 49.4004521, lng: 18.6397442 },
    { lat: 49.400363, lng: 18.6397879 },
    { lat: 49.4001828, lng: 18.6397509 },
    { lat: 49.4000801, lng: 18.6397709 },
    { lat: 49.3999066, lng: 18.6398146 },
    { lat: 49.3997391, lng: 18.6398466 },
    { lat: 49.399554, lng: 18.6398222 },
    { lat: 49.3994138, lng: 18.6399196 },
    { lat: 49.3993057, lng: 18.6398588 },
    { lat: 49.3991432, lng: 18.6399224 },
    { lat: 49.3990882, lng: 18.6398605 },
    { lat: 49.3990291, lng: 18.6398964 },
    { lat: 49.3989436, lng: 18.6398571 },
    { lat: 49.3989015, lng: 18.6398696 },
    { lat: 49.3988684, lng: 18.6398197 },
    { lat: 49.3988009, lng: 18.6398263 },
    { lat: 49.3987118, lng: 18.6399326 },
    { lat: 49.3986274, lng: 18.6399326 },
    { lat: 49.3985437, lng: 18.639464 },
    { lat: 49.3985631, lng: 18.6393167 },
    { lat: 49.3986478, lng: 18.639211 },
    { lat: 49.398407, lng: 18.6390099 },
    { lat: 49.3983984, lng: 18.638932 },
    { lat: 49.3978185, lng: 18.6382452 },
    { lat: 49.3977488, lng: 18.638079 },
    { lat: 49.3977497, lng: 18.6379446 },
    { lat: 49.3977302, lng: 18.6379209 },
    { lat: 49.3976654, lng: 18.6378439 },
    { lat: 49.3973835, lng: 18.6375042 },
    { lat: 49.3972037, lng: 18.6373263 },
    { lat: 49.3970996, lng: 18.6371503 },
    { lat: 49.3969278, lng: 18.6370786 },
    { lat: 49.3968817, lng: 18.6368026 },
    { lat: 49.3968291, lng: 18.6366386 },
    { lat: 49.3967009, lng: 18.6363504 },
    { lat: 49.3965154, lng: 18.6359361 },
    { lat: 49.3964419, lng: 18.6357175 },
    { lat: 49.3963391, lng: 18.6354349 },
    { lat: 49.3962165, lng: 18.635272 },
    { lat: 49.3962153, lng: 18.6352711 },
    { lat: 49.3961995, lng: 18.6352484 },
    { lat: 49.3960662, lng: 18.6350532 },
    { lat: 49.3960004, lng: 18.634876 },
    { lat: 49.3958921, lng: 18.634875 },
    { lat: 49.3958369, lng: 18.6348333 },
    { lat: 49.3957107, lng: 18.6346857 },
    { lat: 49.3956331, lng: 18.6347106 },
    { lat: 49.395498, lng: 18.6348237 },
    { lat: 49.3954909, lng: 18.6348134 },
    { lat: 49.395476, lng: 18.634875 },
    { lat: 49.3954187, lng: 18.6350707 },
    { lat: 49.3953689, lng: 18.6352181 },
    { lat: 49.395283, lng: 18.6354254 },
    { lat: 49.3951602, lng: 18.6356817 },
    { lat: 49.3950308, lng: 18.6358571 },
    { lat: 49.3949866, lng: 18.6360889 },
    { lat: 49.3949529, lng: 18.6365196 },
    { lat: 49.3948986, lng: 18.6366635 },
    { lat: 49.3948607, lng: 18.6368388 },
    { lat: 49.3947751, lng: 18.6371648 },
    { lat: 49.3947437, lng: 18.6372092 },
    { lat: 49.3947094, lng: 18.6373286 },
    { lat: 49.3947047, lng: 18.6374106 },
    { lat: 49.3946796, lng: 18.6375293 },
    { lat: 49.3946497, lng: 18.6376698 },
    { lat: 49.3945823, lng: 18.6378882 },
    { lat: 49.3946129, lng: 18.6380187 },
    { lat: 49.3943623, lng: 18.6384593 },
    { lat: 49.3945075, lng: 18.6392649 },
    { lat: 49.3945745, lng: 18.6396773 },
    { lat: 49.3946093, lng: 18.6396972 },
    { lat: 49.3944371, lng: 18.6403413 },
    { lat: 49.3942658, lng: 18.6410458 },
    { lat: 49.3941618, lng: 18.6412781 },
    { lat: 49.3945438, lng: 18.6424018 },
    { lat: 49.3947028, lng: 18.6430623 },
    { lat: 49.3947305, lng: 18.6439705 },
    { lat: 49.3948683, lng: 18.6447441 },
    { lat: 49.394857, lng: 18.6453617 },
    { lat: 49.3949435, lng: 18.6456956 },
    { lat: 49.3949532, lng: 18.6474123 },
    { lat: 49.395053, lng: 18.6479154 },
    { lat: 49.3950711, lng: 18.6482789 },
    { lat: 49.3949061, lng: 18.6488062 },
    { lat: 49.394773, lng: 18.6491449 },
    { lat: 49.394669, lng: 18.6493551 },
    { lat: 49.3946797, lng: 18.6496199 },
    { lat: 49.3947459, lng: 18.6502956 },
    { lat: 49.3948134, lng: 18.6512097 },
    { lat: 49.3949329, lng: 18.6531553 },
    { lat: 49.3936624, lng: 18.6531828 },
    { lat: 49.3936524, lng: 18.6536561 },
    { lat: 49.3935031, lng: 18.6540291 },
    { lat: 49.3932609, lng: 18.6543328 },
    { lat: 49.393201, lng: 18.6551638 },
    { lat: 49.3932453, lng: 18.6556177 },
    { lat: 49.3931909, lng: 18.6560817 },
    { lat: 49.3930344, lng: 18.6565377 },
    { lat: 49.3929012, lng: 18.6568963 },
    { lat: 49.3928125, lng: 18.6575437 },
    { lat: 49.3926208, lng: 18.6586347 },
    { lat: 49.3925868, lng: 18.6587399 },
    { lat: 49.393019, lng: 18.6592982 },
    { lat: 49.3936899, lng: 18.6598783 },
    { lat: 49.3941319, lng: 18.6603048 },
    { lat: 49.3944693, lng: 18.6605649 },
    { lat: 49.3948915, lng: 18.6610206 },
    { lat: 49.3948953, lng: 18.6610911 },
    { lat: 49.3954354, lng: 18.6614347 },
    { lat: 49.3955099, lng: 18.6615674 },
    { lat: 49.3956465, lng: 18.6616743 },
    { lat: 49.3960192, lng: 18.6618766 },
    { lat: 49.3964033, lng: 18.6620404 },
    { lat: 49.3966174, lng: 18.6621215 },
    { lat: 49.3965306, lng: 18.6622944 },
    { lat: 49.3965081, lng: 18.6623392 },
    { lat: 49.3964903, lng: 18.6623646 },
    { lat: 49.3964086, lng: 18.6624637 },
    { lat: 49.3963053, lng: 18.662579 },
    { lat: 49.3960311, lng: 18.6626799 },
    { lat: 49.3955926, lng: 18.6626643 },
    { lat: 49.3953614, lng: 18.6626632 },
    { lat: 49.395146, lng: 18.6630443 },
    { lat: 49.3950335, lng: 18.6630724 },
    { lat: 49.3949133, lng: 18.6631676 },
    { lat: 49.3947847, lng: 18.6630188 },
    { lat: 49.3947139, lng: 18.663105 },
    { lat: 49.3946335, lng: 18.6633156 },
    { lat: 49.3945142, lng: 18.6632749 },
    { lat: 49.3941561, lng: 18.6636273 },
    { lat: 49.3940204, lng: 18.6637296 },
    { lat: 49.3938695, lng: 18.6637305 },
    { lat: 49.3937872, lng: 18.6638192 },
    { lat: 49.394017, lng: 18.6642614 },
    { lat: 49.394132, lng: 18.6645814 },
    { lat: 49.394361, lng: 18.6653976 },
    { lat: 49.3943998, lng: 18.6655326 },
    { lat: 49.3949174, lng: 18.6655753 },
    { lat: 49.3953364, lng: 18.665437 },
    { lat: 49.395398, lng: 18.6656882 },
    { lat: 49.3957906, lng: 18.6655861 },
    { lat: 49.3962356, lng: 18.6653637 },
    { lat: 49.3966444, lng: 18.6652646 },
    { lat: 49.3971836, lng: 18.664779 },
    { lat: 49.3977838, lng: 18.6646983 },
    { lat: 49.3979049, lng: 18.6647289 },
    { lat: 49.3980645, lng: 18.6647616 },
    { lat: 49.398077, lng: 18.6648274 },
    { lat: 49.3979602, lng: 18.6651775 },
    { lat: 49.3978631, lng: 18.6655168 },
    { lat: 49.397935, lng: 18.6656846 },
    { lat: 49.3980016, lng: 18.6658368 },
    { lat: 49.3993568, lng: 18.6666673 },
    { lat: 49.3994967, lng: 18.6667521 },
    { lat: 49.4, lng: 18.6671015 },
    { lat: 49.4000304, lng: 18.667122 },
    { lat: 49.4002457, lng: 18.6676964 },
    { lat: 49.4003256, lng: 18.6678462 },
    { lat: 49.400551, lng: 18.6682716 },
    { lat: 49.400772, lng: 18.6686987 },
    { lat: 49.4008138, lng: 18.6687428 },
    { lat: 49.4009369, lng: 18.6688735 },
    { lat: 49.4010564, lng: 18.669001 },
    { lat: 49.4011375, lng: 18.6690873 },
    { lat: 49.4015439, lng: 18.6693929 },
    { lat: 49.4016778, lng: 18.6696009 },
    { lat: 49.401743, lng: 18.669704 },
    { lat: 49.4018931, lng: 18.6699874 },
    { lat: 49.4019256, lng: 18.6700485 },
    { lat: 49.4020556, lng: 18.67048 },
    { lat: 49.4020623, lng: 18.6705005 },
    { lat: 49.4020797, lng: 18.6710915 },
    { lat: 49.4020963, lng: 18.6715981 },
    { lat: 49.4020269, lng: 18.6718866 },
    { lat: 49.4019448, lng: 18.6722306 },
    { lat: 49.4018723, lng: 18.6726987 },
    { lat: 49.401857, lng: 18.6728577 },
    { lat: 49.4018174, lng: 18.6732634 },
    { lat: 49.401849, lng: 18.6742049 },
    { lat: 49.4018683, lng: 18.6748271 },
    { lat: 49.4018763, lng: 18.6750463 },
    { lat: 49.4018826, lng: 18.6755705 },
    { lat: 49.4018557, lng: 18.6758765 },
    { lat: 49.4018349, lng: 18.6761238 },
    { lat: 49.4018611, lng: 18.6762987 },
    { lat: 49.401883, lng: 18.6764362 },
    { lat: 49.4019415, lng: 18.6768207 },
    { lat: 49.401943, lng: 18.6771902 },
    { lat: 49.4019444, lng: 18.6774547 },
    { lat: 49.401968, lng: 18.6779674 },
    { lat: 49.401974, lng: 18.6781039 },
    { lat: 49.401964, lng: 18.6783846 },
    { lat: 49.401951, lng: 18.6788019 },
    { lat: 49.4019382, lng: 18.67918 },
    { lat: 49.4019577, lng: 18.679379 },
    { lat: 49.4019919, lng: 18.6795576 },
    { lat: 49.4020143, lng: 18.6797449 },
    { lat: 49.402017, lng: 18.6799274 },
    { lat: 49.4019911, lng: 18.6801001 },
    { lat: 49.4019601, lng: 18.6801756 },
    { lat: 49.4019173, lng: 18.680283 },
    { lat: 49.4018982, lng: 18.6805855 },
    { lat: 49.4018436, lng: 18.6809398 },
    { lat: 49.4017449, lng: 18.6815744 },
    { lat: 49.4017539, lng: 18.6818492 },
    { lat: 49.4017577, lng: 18.6819657 },
    { lat: 49.4017753, lng: 18.6824719 },
    { lat: 49.4017942, lng: 18.6830485 },
    { lat: 49.4018168, lng: 18.683734 },
    { lat: 49.4018257, lng: 18.684012 },
    { lat: 49.4018353, lng: 18.6842792 },
    { lat: 49.4018447, lng: 18.6845658 },
    { lat: 49.4019018, lng: 18.6862975 },
  ],
};

export default UnitsČadca;
