const UnitsPúchov = {
  DolnéKočkovce: [
    { lat: 49.0906103, lng: 18.3130363 },
    { lat: 49.0893193, lng: 18.3138762 },
    { lat: 49.0889311, lng: 18.3141337 },
    { lat: 49.0885327, lng: 18.3144535 },
    { lat: 49.088323, lng: 18.3146366 },
    { lat: 49.0881255, lng: 18.3147985 },
    { lat: 49.087848, lng: 18.314998 },
    { lat: 49.0872523, lng: 18.3153202 },
    { lat: 49.08714, lng: 18.3153685 },
    { lat: 49.086438, lng: 18.3157277 },
    { lat: 49.0860385, lng: 18.3158639 },
    { lat: 49.086061, lng: 18.3159248 },
    { lat: 49.0861952, lng: 18.3162848 },
    { lat: 49.0862084, lng: 18.3163202 },
    { lat: 49.0862417, lng: 18.3164098 },
    { lat: 49.0862616, lng: 18.3164648 },
    { lat: 49.086332, lng: 18.3166536 },
    { lat: 49.0865355, lng: 18.3174856 },
    { lat: 49.0865371, lng: 18.3174944 },
    { lat: 49.0865416, lng: 18.317513 },
    { lat: 49.0865603, lng: 18.3175856 },
    { lat: 49.0865708, lng: 18.3176307 },
    { lat: 49.0865796, lng: 18.3176683 },
    { lat: 49.0867095, lng: 18.3181958 },
    { lat: 49.0867185, lng: 18.3182356 },
    { lat: 49.0867301, lng: 18.3182804 },
    { lat: 49.086748, lng: 18.3183564 },
    { lat: 49.086761, lng: 18.318409 },
    { lat: 49.0867668, lng: 18.3184315 },
    { lat: 49.0867712, lng: 18.3184507 },
    { lat: 49.0867785, lng: 18.3184802 },
    { lat: 49.0867813, lng: 18.3184884 },
    { lat: 49.0867886, lng: 18.3185202 },
    { lat: 49.0867902, lng: 18.3185285 },
    { lat: 49.0867964, lng: 18.3185577 },
    { lat: 49.0869781, lng: 18.3194853 },
    { lat: 49.0870652, lng: 18.3199318 },
    { lat: 49.0871962, lng: 18.3205979 },
    { lat: 49.0875075, lng: 18.322167 },
    { lat: 49.0876684, lng: 18.3229856 },
    { lat: 49.0879306, lng: 18.3243228 },
    { lat: 49.0879889, lng: 18.3246183 },
    { lat: 49.0880102, lng: 18.3247165 },
    { lat: 49.0880145, lng: 18.3247334 },
    { lat: 49.0880277, lng: 18.3248674 },
    { lat: 49.0880046, lng: 18.3249384 },
    { lat: 49.0879613, lng: 18.3249904 },
    { lat: 49.0877594, lng: 18.3250947 },
    { lat: 49.0877017, lng: 18.3251103 },
    { lat: 49.0875717, lng: 18.3251259 },
    { lat: 49.0874218, lng: 18.3251695 },
    { lat: 49.0873194, lng: 18.325253 },
    { lat: 49.0872917, lng: 18.3252847 },
    { lat: 49.0872788, lng: 18.3253558 },
    { lat: 49.0872586, lng: 18.3254178 },
    { lat: 49.087284, lng: 18.3258313 },
    { lat: 49.0872956, lng: 18.3261396 },
    { lat: 49.0873002, lng: 18.326281 },
    { lat: 49.0873061, lng: 18.326408 },
    { lat: 49.0873202, lng: 18.3265584 },
    { lat: 49.0873242, lng: 18.3266898 },
    { lat: 49.0873298, lng: 18.3267501 },
    { lat: 49.087361, lng: 18.3271268 },
    { lat: 49.0873838, lng: 18.3272711 },
    { lat: 49.0874074, lng: 18.3274318 },
    { lat: 49.0874239, lng: 18.3275657 },
    { lat: 49.0874694, lng: 18.3277315 },
    { lat: 49.0875141, lng: 18.3279672 },
    { lat: 49.0875532, lng: 18.3281394 },
    { lat: 49.0876227, lng: 18.3283948 },
    { lat: 49.0876803, lng: 18.3286572 },
    { lat: 49.0876918, lng: 18.3287033 },
    { lat: 49.0877481, lng: 18.3289623 },
    { lat: 49.0878135, lng: 18.329303 },
    { lat: 49.088038, lng: 18.3302721 },
    { lat: 49.0880856, lng: 18.3304574 },
    { lat: 49.0883076, lng: 18.3313802 },
    { lat: 49.088353, lng: 18.3316071 },
    { lat: 49.0885581, lng: 18.3324109 },
    { lat: 49.0886543, lng: 18.3327185 },
    { lat: 49.0886586, lng: 18.332756 },
    { lat: 49.0886723, lng: 18.3327575 },
    { lat: 49.0888605, lng: 18.3338039 },
    { lat: 49.088886, lng: 18.3338769 },
    { lat: 49.0889669, lng: 18.3341127 },
    { lat: 49.0890749, lng: 18.3344289 },
    { lat: 49.0891393, lng: 18.3346133 },
    { lat: 49.0892133, lng: 18.3348254 },
    { lat: 49.0894171, lng: 18.3356059 },
    { lat: 49.0894884, lng: 18.3367625 },
    { lat: 49.0894995, lng: 18.3368156 },
    { lat: 49.0896667, lng: 18.337688 },
    { lat: 49.0897076, lng: 18.3380182 },
    { lat: 49.0898984, lng: 18.3386259 },
    { lat: 49.0902438, lng: 18.3392364 },
    { lat: 49.0904397, lng: 18.339875 },
    { lat: 49.0905156, lng: 18.3402438 },
    { lat: 49.0905511, lng: 18.3404138 },
    { lat: 49.0905852, lng: 18.3405797 },
    { lat: 49.0906207, lng: 18.3406499 },
    { lat: 49.0909438, lng: 18.3412844 },
    { lat: 49.091134, lng: 18.3418215 },
    { lat: 49.091163, lng: 18.3419556 },
    { lat: 49.0911738, lng: 18.34201 },
    { lat: 49.0911862, lng: 18.3420694 },
    { lat: 49.0912355, lng: 18.3423057 },
    { lat: 49.09126, lng: 18.3424227 },
    { lat: 49.0912983, lng: 18.3426028 },
    { lat: 49.0913108, lng: 18.3426634 },
    { lat: 49.0913476, lng: 18.3427794 },
    { lat: 49.0913876, lng: 18.3429094 },
    { lat: 49.0914151, lng: 18.3429969 },
    { lat: 49.0914453, lng: 18.3430942 },
    { lat: 49.0915415, lng: 18.3434024 },
    { lat: 49.0916212, lng: 18.3436562 },
    { lat: 49.0918043, lng: 18.3444105 },
    { lat: 49.0918405, lng: 18.3450183 },
    { lat: 49.0918479, lng: 18.3451518 },
    { lat: 49.0919385, lng: 18.3455231 },
    { lat: 49.0922063, lng: 18.3477522 },
    { lat: 49.0923278, lng: 18.3483135 },
    { lat: 49.0926591, lng: 18.349843 },
    { lat: 49.0929956, lng: 18.3506587 },
    { lat: 49.0930101, lng: 18.3506968 },
    { lat: 49.0931883, lng: 18.3511626 },
    { lat: 49.0936466, lng: 18.3520155 },
    { lat: 49.0939999, lng: 18.3531322 },
    { lat: 49.0940013, lng: 18.3531359 },
    { lat: 49.0941748, lng: 18.3535789 },
    { lat: 49.0942011, lng: 18.3536439 },
    { lat: 49.0945245, lng: 18.3544666 },
    { lat: 49.0946574, lng: 18.3547875 },
    { lat: 49.0950054, lng: 18.3556271 },
    { lat: 49.0950563, lng: 18.3557507 },
    { lat: 49.0951656, lng: 18.3560123 },
    { lat: 49.0956187, lng: 18.3571194 },
    { lat: 49.0960041, lng: 18.3579195 },
    { lat: 49.0960664, lng: 18.3580481 },
    { lat: 49.0964257, lng: 18.3589679 },
    { lat: 49.0968718, lng: 18.3596447 },
    { lat: 49.0970589, lng: 18.3599272 },
    { lat: 49.0972434, lng: 18.3604236 },
    { lat: 49.0972925, lng: 18.3605558 },
    { lat: 49.0974598, lng: 18.3609457 },
    { lat: 49.0975337, lng: 18.361119 },
    { lat: 49.0976098, lng: 18.3612712 },
    { lat: 49.0977611, lng: 18.3615764 },
    { lat: 49.0979685, lng: 18.3618585 },
    { lat: 49.0981019, lng: 18.3620848 },
    { lat: 49.0981837, lng: 18.3622598 },
    { lat: 49.0982463, lng: 18.3623689 },
    { lat: 49.0983424, lng: 18.3625146 },
    { lat: 49.0984791, lng: 18.3627207 },
    { lat: 49.0986408, lng: 18.3630112 },
    { lat: 49.0988214, lng: 18.3633383 },
    { lat: 49.0991773, lng: 18.3639168 },
    { lat: 49.0994746, lng: 18.3644936 },
    { lat: 49.0997739, lng: 18.3654716 },
    { lat: 49.0997644, lng: 18.3655677 },
    { lat: 49.0997088, lng: 18.3660893 },
    { lat: 49.099716, lng: 18.3662993 },
    { lat: 49.099699, lng: 18.3663579 },
    { lat: 49.0996679, lng: 18.3667523 },
    { lat: 49.0996576, lng: 18.366889 },
    { lat: 49.0995211, lng: 18.3682088 },
    { lat: 49.0997374, lng: 18.3694618 },
    { lat: 49.099841, lng: 18.3698851 },
    { lat: 49.1001007, lng: 18.3709435 },
    { lat: 49.1001971, lng: 18.371541 },
    { lat: 49.1003253, lng: 18.3723258 },
    { lat: 49.10038, lng: 18.3724966 },
    { lat: 49.1003989, lng: 18.3725547 },
    { lat: 49.1006142, lng: 18.373221 },
    { lat: 49.1006824, lng: 18.3734335 },
    { lat: 49.1008827, lng: 18.3739518 },
    { lat: 49.101088, lng: 18.3744803 },
    { lat: 49.101142, lng: 18.3746197 },
    { lat: 49.1014057, lng: 18.3751815 },
    { lat: 49.1016016, lng: 18.3756013 },
    { lat: 49.1023075, lng: 18.3766541 },
    { lat: 49.1023629, lng: 18.3767359 },
    { lat: 49.1024158, lng: 18.3768155 },
    { lat: 49.1024848, lng: 18.3771249 },
    { lat: 49.1025492, lng: 18.37741 },
    { lat: 49.1025606, lng: 18.3774746 },
    { lat: 49.1029198, lng: 18.3794519 },
    { lat: 49.1029655, lng: 18.3797059 },
    { lat: 49.10298, lng: 18.3797865 },
    { lat: 49.1029929, lng: 18.3798558 },
    { lat: 49.103029, lng: 18.3800592 },
    { lat: 49.1032518, lng: 18.3814516 },
    { lat: 49.10342, lng: 18.3821651 },
    { lat: 49.10352, lng: 18.382802 },
    { lat: 49.1042717, lng: 18.3811896 },
    { lat: 49.1043416, lng: 18.3807003 },
    { lat: 49.1043484, lng: 18.3806584 },
    { lat: 49.1043574, lng: 18.3805996 },
    { lat: 49.1043162, lng: 18.3797747 },
    { lat: 49.1043139, lng: 18.3797332 },
    { lat: 49.1043055, lng: 18.3794999 },
    { lat: 49.1045795, lng: 18.3792745 },
    { lat: 49.1048927, lng: 18.3791251 },
    { lat: 49.1052027, lng: 18.3769883 },
    { lat: 49.1051672, lng: 18.3753747 },
    { lat: 49.1053332, lng: 18.374462 },
    { lat: 49.1055323, lng: 18.3723607 },
    { lat: 49.1057015, lng: 18.3717703 },
    { lat: 49.1061484, lng: 18.3705162 },
    { lat: 49.1072238, lng: 18.3696693 },
    { lat: 49.1087358, lng: 18.3651119 },
    { lat: 49.1088945, lng: 18.3612522 },
    { lat: 49.1088966, lng: 18.3612093 },
    { lat: 49.108897, lng: 18.361197 },
    { lat: 49.1088269, lng: 18.3607682 },
    { lat: 49.1088268, lng: 18.3607648 },
    { lat: 49.1088234, lng: 18.3607445 },
    { lat: 49.1088218, lng: 18.3607372 },
    { lat: 49.1077866, lng: 18.3543637 },
    { lat: 49.1064022, lng: 18.3478026 },
    { lat: 49.1066798, lng: 18.3477444 },
    { lat: 49.1065504, lng: 18.347387 },
    { lat: 49.1065405, lng: 18.3472095 },
    { lat: 49.1065269, lng: 18.3469663 },
    { lat: 49.1064856, lng: 18.3462041 },
    { lat: 49.1064806, lng: 18.3459745 },
    { lat: 49.1064437, lng: 18.3442828 },
    { lat: 49.1064434, lng: 18.3442777 },
    { lat: 49.1064377, lng: 18.3440926 },
    { lat: 49.1063972, lng: 18.3434285 },
    { lat: 49.1063925, lng: 18.3433441 },
    { lat: 49.1063537, lng: 18.3427107 },
    { lat: 49.1059661, lng: 18.3410233 },
    { lat: 49.1055321, lng: 18.3405018 },
    { lat: 49.1052503, lng: 18.3401633 },
    { lat: 49.1050134, lng: 18.3398795 },
    { lat: 49.1049969, lng: 18.3399088 },
    { lat: 49.1048076, lng: 18.3402352 },
    { lat: 49.1047689, lng: 18.3402303 },
    { lat: 49.1046986, lng: 18.3400807 },
    { lat: 49.1045924, lng: 18.3398755 },
    { lat: 49.1044471, lng: 18.339598 },
    { lat: 49.1043258, lng: 18.3391824 },
    { lat: 49.1042549, lng: 18.3388837 },
    { lat: 49.1041997, lng: 18.3386219 },
    { lat: 49.1040705, lng: 18.3383715 },
    { lat: 49.10402, lng: 18.3381516 },
    { lat: 49.1039602, lng: 18.3378896 },
    { lat: 49.1039231, lng: 18.3376295 },
    { lat: 49.1038484, lng: 18.3373432 },
    { lat: 49.1037545, lng: 18.3371363 },
    { lat: 49.103646, lng: 18.3369906 },
    { lat: 49.1035386, lng: 18.3368447 },
    { lat: 49.1035241, lng: 18.3367688 },
    { lat: 49.1034538, lng: 18.3363779 },
    { lat: 49.1034179, lng: 18.3361781 },
    { lat: 49.1033945, lng: 18.3360443 },
    { lat: 49.1032821, lng: 18.335405 },
    { lat: 49.1032818, lng: 18.3354003 },
    { lat: 49.1029673, lng: 18.3343342 },
    { lat: 49.1026443, lng: 18.3332374 },
    { lat: 49.1025245, lng: 18.3328303 },
    { lat: 49.1022198, lng: 18.3324041 },
    { lat: 49.1021385, lng: 18.3320616 },
    { lat: 49.1019663, lng: 18.331483 },
    { lat: 49.1019765, lng: 18.3312437 },
    { lat: 49.1018128, lng: 18.3308754 },
    { lat: 49.1017397, lng: 18.3306386 },
    { lat: 49.1016618, lng: 18.3303153 },
    { lat: 49.1015391, lng: 18.3300594 },
    { lat: 49.1014951, lng: 18.3299377 },
    { lat: 49.1014968, lng: 18.3296847 },
    { lat: 49.1013741, lng: 18.3296495 },
    { lat: 49.101279, lng: 18.3295425 },
    { lat: 49.1012306, lng: 18.3292422 },
    { lat: 49.1011675, lng: 18.3291228 },
    { lat: 49.1011052, lng: 18.3285334 },
    { lat: 49.101142, lng: 18.3281824 },
    { lat: 49.1010638, lng: 18.3280358 },
    { lat: 49.1009345, lng: 18.3279233 },
    { lat: 49.1008713, lng: 18.3278624 },
    { lat: 49.1005676, lng: 18.3276768 },
    { lat: 49.1003564, lng: 18.3275301 },
    { lat: 49.100166, lng: 18.3273357 },
    { lat: 49.1001484, lng: 18.3272822 },
    { lat: 49.1001217, lng: 18.3271255 },
    { lat: 49.1000775, lng: 18.3269624 },
    { lat: 49.1000527, lng: 18.3264378 },
    { lat: 49.0999957, lng: 18.3261035 },
    { lat: 49.1000018, lng: 18.3259719 },
    { lat: 49.1000215, lng: 18.3258597 },
    { lat: 49.1000105, lng: 18.325786 },
    { lat: 49.1000242, lng: 18.3257272 },
    { lat: 49.0999921, lng: 18.3255366 },
    { lat: 49.0999937, lng: 18.3253448 },
    { lat: 49.0999966, lng: 18.3251148 },
    { lat: 49.1, lng: 18.3250319 },
    { lat: 49.1000038, lng: 18.3249605 },
    { lat: 49.1000181, lng: 18.324773 },
    { lat: 49.1000058, lng: 18.3246544 },
    { lat: 49.1000053, lng: 18.3246252 },
    { lat: 49.100024, lng: 18.3243123 },
    { lat: 49.1000313, lng: 18.324082 },
    { lat: 49.1000061, lng: 18.3237922 },
    { lat: 49.1000057, lng: 18.3237858 },
    { lat: 49.1000056, lng: 18.3237833 },
    { lat: 49.0999957, lng: 18.3236866 },
    { lat: 49.0999956, lng: 18.3236845 },
    { lat: 49.0999952, lng: 18.3236777 },
    { lat: 49.0999569, lng: 18.3231758 },
    { lat: 49.0999468, lng: 18.3227735 },
    { lat: 49.0999525, lng: 18.3226935 },
    { lat: 49.0999609, lng: 18.3226428 },
    { lat: 49.0999855, lng: 18.3225188 },
    { lat: 49.1000554, lng: 18.3222958 },
    { lat: 49.1001203, lng: 18.3221659 },
    { lat: 49.1002766, lng: 18.3219758 },
    { lat: 49.1002777, lng: 18.3219744 },
    { lat: 49.1002851, lng: 18.3219654 },
    { lat: 49.1002747, lng: 18.3219426 },
    { lat: 49.1002711, lng: 18.3219362 },
    { lat: 49.1002416, lng: 18.3218744 },
    { lat: 49.1002391, lng: 18.3218682 },
    { lat: 49.1002379, lng: 18.3218671 },
    { lat: 49.1001998, lng: 18.3218125 },
    { lat: 49.0999491, lng: 18.3217071 },
    { lat: 49.0996333, lng: 18.3215689 },
    { lat: 49.0996368, lng: 18.3215486 },
    { lat: 49.0996519, lng: 18.3214365 },
    { lat: 49.0996589, lng: 18.3213809 },
    { lat: 49.0996659, lng: 18.3213245 },
    { lat: 49.0996715, lng: 18.3212843 },
    { lat: 49.0996761, lng: 18.3212447 },
    { lat: 49.0996827, lng: 18.3211816 },
    { lat: 49.0996546, lng: 18.3208012 },
    { lat: 49.0996385, lng: 18.3205749 },
    { lat: 49.0995167, lng: 18.3189236 },
    { lat: 49.0994656, lng: 18.3182314 },
    { lat: 49.0994469, lng: 18.3179795 },
    { lat: 49.099418, lng: 18.3175851 },
    { lat: 49.0994086, lng: 18.3174558 },
    { lat: 49.0993875, lng: 18.3174431 },
    { lat: 49.0993403, lng: 18.3174663 },
    { lat: 49.0993063, lng: 18.3174833 },
    { lat: 49.099202, lng: 18.3175347 },
    { lat: 49.0990406, lng: 18.3175123 },
    { lat: 49.0984054, lng: 18.317271 },
    { lat: 49.0983542, lng: 18.3172436 },
    { lat: 49.0981282, lng: 18.3171476 },
    { lat: 49.0977807, lng: 18.317035 },
    { lat: 49.0974222, lng: 18.3169384 },
    { lat: 49.097417, lng: 18.3169257 },
    { lat: 49.0972045, lng: 18.3164155 },
    { lat: 49.0971856, lng: 18.3163618 },
    { lat: 49.0971764, lng: 18.3163385 },
    { lat: 49.097155, lng: 18.3162781 },
    { lat: 49.097139, lng: 18.3162346 },
    { lat: 49.0971164, lng: 18.3161728 },
    { lat: 49.0970977, lng: 18.3161212 },
    { lat: 49.0970803, lng: 18.3160734 },
    { lat: 49.0970403, lng: 18.3159631 },
    { lat: 49.096863, lng: 18.3154781 },
    { lat: 49.0968444, lng: 18.3154266 },
    { lat: 49.0968356, lng: 18.3154026 },
    { lat: 49.0966164, lng: 18.3148003 },
    { lat: 49.0966098, lng: 18.3147835 },
    { lat: 49.0964431, lng: 18.3143258 },
    { lat: 49.0960259, lng: 18.3131831 },
    { lat: 49.0959192, lng: 18.3128908 },
    { lat: 49.0959186, lng: 18.3128585 },
    { lat: 49.0959152, lng: 18.3127388 },
    { lat: 49.0958902, lng: 18.3117307 },
    { lat: 49.0949785, lng: 18.3117087 },
    { lat: 49.0942362, lng: 18.3119908 },
    { lat: 49.0938855, lng: 18.3121344 },
    { lat: 49.0932598, lng: 18.3124631 },
    { lat: 49.0929188, lng: 18.3125377 },
    { lat: 49.0922208, lng: 18.3126868 },
    { lat: 49.0917709, lng: 18.3127694 },
    { lat: 49.0910817, lng: 18.3129127 },
    { lat: 49.0908834, lng: 18.3129977 },
    { lat: 49.0906103, lng: 18.3130363 },
  ],
  Visolaje: [
    { lat: 49.063656, lng: 18.396645 },
    { lat: 49.063773, lng: 18.397012 },
    { lat: 49.063859, lng: 18.397159 },
    { lat: 49.063897, lng: 18.397342 },
    { lat: 49.063906, lng: 18.397488 },
    { lat: 49.063957, lng: 18.397625 },
    { lat: 49.064052, lng: 18.397785 },
    { lat: 49.064106, lng: 18.397851 },
    { lat: 49.064196, lng: 18.397954 },
    { lat: 49.064335, lng: 18.398176 },
    { lat: 49.064402, lng: 18.398313 },
    { lat: 49.064466, lng: 18.398475 },
    { lat: 49.064601, lng: 18.398581 },
    { lat: 49.064705, lng: 18.398755 },
    { lat: 49.064828, lng: 18.398786 },
    { lat: 49.064918, lng: 18.398805 },
    { lat: 49.065037, lng: 18.399023 },
    { lat: 49.065136, lng: 18.398915 },
    { lat: 49.065371, lng: 18.398782 },
    { lat: 49.065573, lng: 18.39893 },
    { lat: 49.06564, lng: 18.399052 },
    { lat: 49.065667, lng: 18.399016 },
    { lat: 49.065681, lng: 18.399003 },
    { lat: 49.065707, lng: 18.398962 },
    { lat: 49.066279, lng: 18.398192 },
    { lat: 49.066698, lng: 18.397111 },
    { lat: 49.066406, lng: 18.396521 },
    { lat: 49.06631, lng: 18.396095 },
    { lat: 49.067109, lng: 18.395408 },
    { lat: 49.067886, lng: 18.394937 },
    { lat: 49.067951, lng: 18.394376 },
    { lat: 49.067366, lng: 18.392749 },
    { lat: 49.067662, lng: 18.392532 },
    { lat: 49.068007, lng: 18.391951 },
    { lat: 49.068023, lng: 18.392186 },
    { lat: 49.068053, lng: 18.392278 },
    { lat: 49.068233, lng: 18.392306 },
    { lat: 49.068341, lng: 18.392324 },
    { lat: 49.068384, lng: 18.392301 },
    { lat: 49.068489, lng: 18.392247 },
    { lat: 49.068576, lng: 18.392394 },
    { lat: 49.068582, lng: 18.392403 },
    { lat: 49.068671, lng: 18.392553 },
    { lat: 49.068647, lng: 18.39259 },
    { lat: 49.068604, lng: 18.392655 },
    { lat: 49.06868, lng: 18.392881 },
    { lat: 49.068715, lng: 18.392987 },
    { lat: 49.068758, lng: 18.393169 },
    { lat: 49.06883, lng: 18.393477 },
    { lat: 49.068975, lng: 18.393867 },
    { lat: 49.069048, lng: 18.393981 },
    { lat: 49.069098, lng: 18.39406 },
    { lat: 49.069313, lng: 18.394108 },
    { lat: 49.069347, lng: 18.394244 },
    { lat: 49.069418, lng: 18.394528 },
    { lat: 49.069452, lng: 18.394669 },
    { lat: 49.069542, lng: 18.394725 },
    { lat: 49.069552, lng: 18.394732 },
    { lat: 49.069601, lng: 18.394763 },
    { lat: 49.069642, lng: 18.394782 },
    { lat: 49.069684, lng: 18.394772 },
    { lat: 49.06982, lng: 18.394739 },
    { lat: 49.069856, lng: 18.394724 },
    { lat: 49.069927, lng: 18.394696 },
    { lat: 49.06993, lng: 18.394694 },
    { lat: 49.069974, lng: 18.394676 },
    { lat: 49.070106, lng: 18.394623 },
    { lat: 49.070313, lng: 18.394539 },
    { lat: 49.071313, lng: 18.39443 },
    { lat: 49.072508, lng: 18.394068 },
    { lat: 49.073021, lng: 18.393641 },
    { lat: 49.074408, lng: 18.392119 },
    { lat: 49.074851, lng: 18.391899 },
    { lat: 49.07593, lng: 18.39175 },
    { lat: 49.077092, lng: 18.39174 },
    { lat: 49.077574, lng: 18.391838 },
    { lat: 49.078131, lng: 18.39247 },
    { lat: 49.078503, lng: 18.391631 },
    { lat: 49.079245, lng: 18.39073 },
    { lat: 49.079841, lng: 18.390593 },
    { lat: 49.080027, lng: 18.390885 },
    { lat: 49.080171, lng: 18.390555 },
    { lat: 49.080372, lng: 18.390366 },
    { lat: 49.080713, lng: 18.390127 },
    { lat: 49.081532, lng: 18.390583 },
    { lat: 49.082304, lng: 18.391305 },
    { lat: 49.082499, lng: 18.391177 },
    { lat: 49.082719, lng: 18.390893 },
    { lat: 49.082812, lng: 18.390773 },
    { lat: 49.082953, lng: 18.390709 },
    { lat: 49.083618, lng: 18.390383 },
    { lat: 49.083669, lng: 18.390322 },
    { lat: 49.083818, lng: 18.390142 },
    { lat: 49.084213, lng: 18.389995 },
    { lat: 49.085528, lng: 18.389858 },
    { lat: 49.085578, lng: 18.389852 },
    { lat: 49.085636, lng: 18.389846 },
    { lat: 49.086975, lng: 18.389248 },
    { lat: 49.088034, lng: 18.389196 },
    { lat: 49.088864, lng: 18.389595 },
    { lat: 49.088968, lng: 18.389233 },
    { lat: 49.089043, lng: 18.389213 },
    { lat: 49.089378, lng: 18.38934 },
    { lat: 49.090053, lng: 18.389667 },
    { lat: 49.09047, lng: 18.389856 },
    { lat: 49.090663, lng: 18.38985 },
    { lat: 49.091723, lng: 18.389699 },
    { lat: 49.091903, lng: 18.389666 },
    { lat: 49.092443, lng: 18.38947 },
    { lat: 49.093122, lng: 18.38873 },
    { lat: 49.0912099, lng: 18.385262 },
    { lat: 49.0908284, lng: 18.3843007 },
    { lat: 49.0907329, lng: 18.3840429 },
    { lat: 49.0903223, lng: 18.3831697 },
    { lat: 49.090152, lng: 18.3828058 },
    { lat: 49.0888873, lng: 18.3806495 },
    { lat: 49.0885008, lng: 18.3791523 },
    { lat: 49.0883247, lng: 18.3785638 },
    { lat: 49.088108, lng: 18.3777689 },
    { lat: 49.0874862, lng: 18.3771569 },
    { lat: 49.0863922, lng: 18.3756163 },
    { lat: 49.0858342, lng: 18.3748312 },
    { lat: 49.0856532, lng: 18.3746609 },
    { lat: 49.0845303, lng: 18.3731721 },
    { lat: 49.0843271, lng: 18.3728848 },
    { lat: 49.0838224, lng: 18.3718022 },
    { lat: 49.083582, lng: 18.3713325 },
    { lat: 49.0836155, lng: 18.371287 },
    { lat: 49.0836421, lng: 18.371257 },
    { lat: 49.0833681, lng: 18.3709552 },
    { lat: 49.0824601, lng: 18.3701659 },
    { lat: 49.0823349, lng: 18.3700438 },
    { lat: 49.0822455, lng: 18.3697361 },
    { lat: 49.0821419, lng: 18.3694753 },
    { lat: 49.0820318, lng: 18.3691589 },
    { lat: 49.0816786, lng: 18.3686921 },
    { lat: 49.0816777, lng: 18.3681467 },
    { lat: 49.0815007, lng: 18.3680256 },
    { lat: 49.0814729, lng: 18.367833 },
    { lat: 49.0813335, lng: 18.3676977 },
    { lat: 49.0803515, lng: 18.3665934 },
    { lat: 49.080401, lng: 18.3653963 },
    { lat: 49.0804174, lng: 18.3649606 },
    { lat: 49.0804298, lng: 18.3649196 },
    { lat: 49.080607, lng: 18.3642939 },
    { lat: 49.0804458, lng: 18.3641551 },
    { lat: 49.0799277, lng: 18.3628313 },
    { lat: 49.0785954, lng: 18.3609878 },
    { lat: 49.0782436, lng: 18.3601803 },
    { lat: 49.0779496, lng: 18.3592776 },
    { lat: 49.0770032, lng: 18.3574154 },
    { lat: 49.0756549, lng: 18.3552266 },
    { lat: 49.0743605, lng: 18.3525283 },
    { lat: 49.0733107, lng: 18.3502922 },
    { lat: 49.072637, lng: 18.3511795 },
    { lat: 49.0722392, lng: 18.3515508 },
    { lat: 49.071871, lng: 18.351706 },
    { lat: 49.0715811, lng: 18.3514214 },
    { lat: 49.0705929, lng: 18.3519082 },
    { lat: 49.070258, lng: 18.3522771 },
    { lat: 49.0699176, lng: 18.3524413 },
    { lat: 49.069376, lng: 18.3529677 },
    { lat: 49.069058, lng: 18.3531726 },
    { lat: 49.0690934, lng: 18.3520057 },
    { lat: 49.0690772, lng: 18.3512301 },
    { lat: 49.0690722, lng: 18.3500478 },
    { lat: 49.0690484, lng: 18.3495409 },
    { lat: 49.0690058, lng: 18.3491623 },
    { lat: 49.0688725, lng: 18.3482102 },
    { lat: 49.068792, lng: 18.3470697 },
    { lat: 49.0687447, lng: 18.3457989 },
    { lat: 49.0687702, lng: 18.345549 },
    { lat: 49.0687972, lng: 18.3452839 },
    { lat: 49.0689979, lng: 18.3444144 },
    { lat: 49.069055, lng: 18.3440824 },
    { lat: 49.0691039, lng: 18.3437255 },
    { lat: 49.0691448, lng: 18.3434297 },
    { lat: 49.0691083, lng: 18.3434016 },
    { lat: 49.0690517, lng: 18.3433582 },
    { lat: 49.0690246, lng: 18.3433373 },
    { lat: 49.0689496, lng: 18.3433475 },
    { lat: 49.0688227, lng: 18.3433974 },
    { lat: 49.0678853, lng: 18.343763 },
    { lat: 49.066799, lng: 18.3441872 },
    { lat: 49.0666056, lng: 18.3442624 },
    { lat: 49.0665723, lng: 18.3443101 },
    { lat: 49.0665565, lng: 18.3443319 },
    { lat: 49.0665315, lng: 18.3443677 },
    { lat: 49.0664811, lng: 18.3444374 },
    { lat: 49.0659396, lng: 18.3460518 },
    { lat: 49.0652238, lng: 18.3482055 },
    { lat: 49.0644024, lng: 18.3507481 },
    { lat: 49.0643052, lng: 18.351048 },
    { lat: 49.06432, lng: 18.3525072 },
    { lat: 49.0642702, lng: 18.3525248 },
    { lat: 49.0642647, lng: 18.3525266 },
    { lat: 49.0631151, lng: 18.3529323 },
    { lat: 49.0630787, lng: 18.3529451 },
    { lat: 49.0630277, lng: 18.3529634 },
    { lat: 49.0629769, lng: 18.3529811 },
    { lat: 49.0629139, lng: 18.3530033 },
    { lat: 49.0628563, lng: 18.3530237 },
    { lat: 49.0628262, lng: 18.3530294 },
    { lat: 49.0627715, lng: 18.3530397 },
    { lat: 49.062662, lng: 18.3530603 },
    { lat: 49.0626074, lng: 18.353071 },
    { lat: 49.0625437, lng: 18.3530828 },
    { lat: 49.0624399, lng: 18.3531023 },
    { lat: 49.062326, lng: 18.3531238 },
    { lat: 49.0613626, lng: 18.3533062 },
    { lat: 49.0607339, lng: 18.3548176 },
    { lat: 49.0598378, lng: 18.356853 },
    { lat: 49.0596873, lng: 18.3578156 },
    { lat: 49.0595353, lng: 18.3583696 },
    { lat: 49.0591059, lng: 18.3598545 },
    { lat: 49.0589606, lng: 18.3601206 },
    { lat: 49.0587297, lng: 18.3604712 },
    { lat: 49.0583659, lng: 18.361495 },
    { lat: 49.057933, lng: 18.3626726 },
    { lat: 49.0576157, lng: 18.3636207 },
    { lat: 49.0569196, lng: 18.3643083 },
    { lat: 49.0567841, lng: 18.36453 },
    { lat: 49.0567034, lng: 18.3648023 },
    { lat: 49.0551817, lng: 18.3658168 },
    { lat: 49.054683, lng: 18.3662008 },
    { lat: 49.0546494, lng: 18.366226 },
    { lat: 49.0535936, lng: 18.3670401 },
    { lat: 49.0541026, lng: 18.3672256 },
    { lat: 49.0543008, lng: 18.3674415 },
    { lat: 49.0547719, lng: 18.3690356 },
    { lat: 49.0547125, lng: 18.3692648 },
    { lat: 49.0546593, lng: 18.3693411 },
    { lat: 49.0541335, lng: 18.3695023 },
    { lat: 49.0540895, lng: 18.3695234 },
    { lat: 49.0540212, lng: 18.3695932 },
    { lat: 49.0539687, lng: 18.3696849 },
    { lat: 49.0539581, lng: 18.3698378 },
    { lat: 49.0540768, lng: 18.3701872 },
    { lat: 49.0540583, lng: 18.3702785 },
    { lat: 49.0540112, lng: 18.3703867 },
    { lat: 49.0539532, lng: 18.3704602 },
    { lat: 49.0538802, lng: 18.3705057 },
    { lat: 49.0537954, lng: 18.3705202 },
    { lat: 49.0535021, lng: 18.3705836 },
    { lat: 49.0534221, lng: 18.3705834 },
    { lat: 49.0533536, lng: 18.3706725 },
    { lat: 49.0532075, lng: 18.3708445 },
    { lat: 49.0530679, lng: 18.3710117 },
    { lat: 49.0530361, lng: 18.3710683 },
    { lat: 49.0529861, lng: 18.371103 },
    { lat: 49.0528666, lng: 18.3711236 },
    { lat: 49.0527671, lng: 18.3711425 },
    { lat: 49.0526768, lng: 18.371202 },
    { lat: 49.052586, lng: 18.37127 },
    { lat: 49.0524183, lng: 18.3714623 },
    { lat: 49.0523042, lng: 18.3716212 },
    { lat: 49.0521765, lng: 18.3718385 },
    { lat: 49.0521398, lng: 18.3719711 },
    { lat: 49.0520427, lng: 18.3722535 },
    { lat: 49.051968, lng: 18.372452 },
    { lat: 49.051856, lng: 18.3727093 },
    { lat: 49.0517587, lng: 18.3729281 },
    { lat: 49.0516212, lng: 18.3732355 },
    { lat: 49.0514126, lng: 18.3738454 },
    { lat: 49.0511623, lng: 18.3742918 },
    { lat: 49.0510651, lng: 18.374494 },
    { lat: 49.0509447, lng: 18.3746605 },
    { lat: 49.0507917, lng: 18.3748286 },
    { lat: 49.0506453, lng: 18.3749363 },
    { lat: 49.0505359, lng: 18.3750185 },
    { lat: 49.0499305, lng: 18.3754106 },
    { lat: 49.0499101, lng: 18.3754914 },
    { lat: 49.0500556, lng: 18.3764844 },
    { lat: 49.0502238, lng: 18.3768307 },
    { lat: 49.0504985, lng: 18.3773289 },
    { lat: 49.05079, lng: 18.3777418 },
    { lat: 49.0512903, lng: 18.378443 },
    { lat: 49.0517038, lng: 18.3804614 },
    { lat: 49.0517485, lng: 18.3806799 },
    { lat: 49.051849, lng: 18.3815136 },
    { lat: 49.0518216, lng: 18.3823402 },
    { lat: 49.0520437, lng: 18.3854247 },
    { lat: 49.0521523, lng: 18.3856125 },
    { lat: 49.052, lng: 18.386411 },
    { lat: 49.052151, lng: 18.386472 },
    { lat: 49.052201, lng: 18.386504 },
    { lat: 49.052564, lng: 18.38689 },
    { lat: 49.05266, lng: 18.386992 },
    { lat: 49.052848, lng: 18.387078 },
    { lat: 49.053017, lng: 18.387368 },
    { lat: 49.05324, lng: 18.387466 },
    { lat: 49.053504, lng: 18.387774 },
    { lat: 49.053599, lng: 18.388037 },
    { lat: 49.053729, lng: 18.388205 },
    { lat: 49.053924, lng: 18.388195 },
    { lat: 49.054096, lng: 18.388271 },
    { lat: 49.054208, lng: 18.38832 },
    { lat: 49.054247, lng: 18.388337 },
    { lat: 49.054291, lng: 18.388356 },
    { lat: 49.054343, lng: 18.388379 },
    { lat: 49.054769, lng: 18.388353 },
    { lat: 49.054972, lng: 18.388362 },
    { lat: 49.055125, lng: 18.388463 },
    { lat: 49.055404, lng: 18.387955 },
    { lat: 49.055694, lng: 18.387562 },
    { lat: 49.056078, lng: 18.387325 },
    { lat: 49.056381, lng: 18.387136 },
    { lat: 49.056983, lng: 18.387223 },
    { lat: 49.057318, lng: 18.387113 },
    { lat: 49.057672, lng: 18.387043 },
    { lat: 49.057981, lng: 18.387086 },
    { lat: 49.058311, lng: 18.38726 },
    { lat: 49.058489, lng: 18.387244 },
    { lat: 49.058831, lng: 18.38711 },
    { lat: 49.059075, lng: 18.387164 },
    { lat: 49.059453, lng: 18.387886 },
    { lat: 49.059652, lng: 18.388194 },
    { lat: 49.059864, lng: 18.388363 },
    { lat: 49.060119, lng: 18.388671 },
    { lat: 49.060174, lng: 18.388735 },
    { lat: 49.060208, lng: 18.388764 },
    { lat: 49.060451, lng: 18.389107 },
    { lat: 49.060507, lng: 18.389161 },
    { lat: 49.060556, lng: 18.389207 },
    { lat: 49.060561, lng: 18.389215 },
    { lat: 49.060636, lng: 18.389282 },
    { lat: 49.06085, lng: 18.38939 },
    { lat: 49.061022, lng: 18.389476 },
    { lat: 49.060772, lng: 18.389808 },
    { lat: 49.060654, lng: 18.389831 },
    { lat: 49.060609, lng: 18.38984 },
    { lat: 49.060578, lng: 18.389848 },
    { lat: 49.060456, lng: 18.389886 },
    { lat: 49.060333, lng: 18.390119 },
    { lat: 49.060417, lng: 18.390363 },
    { lat: 49.060477, lng: 18.390539 },
    { lat: 49.060442, lng: 18.390877 },
    { lat: 49.059925, lng: 18.391967 },
    { lat: 49.059894, lng: 18.392148 },
    { lat: 49.05994, lng: 18.392252 },
    { lat: 49.060083, lng: 18.392395 },
    { lat: 49.060145, lng: 18.392388 },
    { lat: 49.060204, lng: 18.39235 },
    { lat: 49.060447, lng: 18.392268 },
    { lat: 49.060498, lng: 18.392284 },
    { lat: 49.060546, lng: 18.3923 },
    { lat: 49.060552, lng: 18.392301 },
    { lat: 49.060592, lng: 18.392314 },
    { lat: 49.060857, lng: 18.392398 },
    { lat: 49.061786, lng: 18.392929 },
    { lat: 49.062241, lng: 18.393525 },
    { lat: 49.062683, lng: 18.394421 },
    { lat: 49.062842, lng: 18.394931 },
    { lat: 49.063315, lng: 18.396082 },
    { lat: 49.063656, lng: 18.396645 },
  ],
  DolnáBreznica: [
    { lat: 49.0982352, lng: 18.2461158 },
    { lat: 49.0979445, lng: 18.2462021 },
    { lat: 49.097909, lng: 18.2462127 },
    { lat: 49.0954002, lng: 18.246957 },
    { lat: 49.0953491, lng: 18.2470294 },
    { lat: 49.0952937, lng: 18.2471072 },
    { lat: 49.0952528, lng: 18.2471644 },
    { lat: 49.0949235, lng: 18.2474255 },
    { lat: 49.0949004, lng: 18.2474174 },
    { lat: 49.0948677, lng: 18.2474152 },
    { lat: 49.0948556, lng: 18.2474013 },
    { lat: 49.0947934, lng: 18.2473796 },
    { lat: 49.094828, lng: 18.2468539 },
    { lat: 49.0949182, lng: 18.2465362 },
    { lat: 49.0949831, lng: 18.2463275 },
    { lat: 49.0950175, lng: 18.2456989 },
    { lat: 49.0950013, lng: 18.2456719 },
    { lat: 49.0948821, lng: 18.2455797 },
    { lat: 49.0948572, lng: 18.2453987 },
    { lat: 49.0947863, lng: 18.2450051 },
    { lat: 49.0948145, lng: 18.2448065 },
    { lat: 49.0946553, lng: 18.2438481 },
    { lat: 49.0944328, lng: 18.2432871 },
    { lat: 49.0944133, lng: 18.2426438 },
    { lat: 49.0943425, lng: 18.2424911 },
    { lat: 49.0941389, lng: 18.2424027 },
    { lat: 49.0939713, lng: 18.2420924 },
    { lat: 49.0937948, lng: 18.2420258 },
    { lat: 49.0936004, lng: 18.2417193 },
    { lat: 49.0932882, lng: 18.2416475 },
    { lat: 49.0931446, lng: 18.2415418 },
    { lat: 49.0930332, lng: 18.2412481 },
    { lat: 49.0928012, lng: 18.2409765 },
    { lat: 49.0924702, lng: 18.2407522 },
    { lat: 49.092276, lng: 18.2407301 },
    { lat: 49.0921584, lng: 18.2405643 },
    { lat: 49.091897, lng: 18.2405119 },
    { lat: 49.0916053, lng: 18.240641 },
    { lat: 49.0915376, lng: 18.2406596 },
    { lat: 49.0911877, lng: 18.2404583 },
    { lat: 49.0911796, lng: 18.2402785 },
    { lat: 49.0910705, lng: 18.2376578 },
    { lat: 49.0905644, lng: 18.2379444 },
    { lat: 49.0903458, lng: 18.2379688 },
    { lat: 49.0898688, lng: 18.2382339 },
    { lat: 49.089835, lng: 18.2382526 },
    { lat: 49.0895425, lng: 18.2385268 },
    { lat: 49.0894991, lng: 18.2385374 },
    { lat: 49.0890473, lng: 18.2392632 },
    { lat: 49.0882949, lng: 18.2395864 },
    { lat: 49.0882651, lng: 18.2396981 },
    { lat: 49.0873286, lng: 18.2396367 },
    { lat: 49.086675, lng: 18.2395104 },
    { lat: 49.086396, lng: 18.2394262 },
    { lat: 49.0858071, lng: 18.2395249 },
    { lat: 49.0850972, lng: 18.239423 },
    { lat: 49.0847768, lng: 18.2392241 },
    { lat: 49.0843879, lng: 18.2391306 },
    { lat: 49.0838637, lng: 18.2395368 },
    { lat: 49.0826999, lng: 18.2406203 },
    { lat: 49.0826302, lng: 18.2406853 },
    { lat: 49.0821901, lng: 18.2415204 },
    { lat: 49.0821515, lng: 18.2415934 },
    { lat: 49.0819891, lng: 18.2413772 },
    { lat: 49.0812169, lng: 18.2419279 },
    { lat: 49.080524, lng: 18.2422621 },
    { lat: 49.0801844, lng: 18.2419639 },
    { lat: 49.0799643, lng: 18.241345 },
    { lat: 49.0790092, lng: 18.2427292 },
    { lat: 49.0764174, lng: 18.2448535 },
    { lat: 49.0763676, lng: 18.244891 },
    { lat: 49.0750134, lng: 18.2452827 },
    { lat: 49.0744985, lng: 18.2456942 },
    { lat: 49.0741321, lng: 18.2459385 },
    { lat: 49.0740767, lng: 18.2459758 },
    { lat: 49.073682, lng: 18.2462389 },
    { lat: 49.0733136, lng: 18.2459094 },
    { lat: 49.073279, lng: 18.2458363 },
    { lat: 49.0720071, lng: 18.2445562 },
    { lat: 49.07067, lng: 18.2438605 },
    { lat: 49.0692791, lng: 18.2437237 },
    { lat: 49.0688027, lng: 18.2438392 },
    { lat: 49.0680871, lng: 18.2441117 },
    { lat: 49.0677971, lng: 18.2442117 },
    { lat: 49.0675944, lng: 18.2438209 },
    { lat: 49.0666877, lng: 18.2454796 },
    { lat: 49.0662857, lng: 18.2445988 },
    { lat: 49.0660652, lng: 18.2450076 },
    { lat: 49.0665286, lng: 18.2466139 },
    { lat: 49.066296, lng: 18.2473861 },
    { lat: 49.0656814, lng: 18.2494211 },
    { lat: 49.064897, lng: 18.2515482 },
    { lat: 49.0648481, lng: 18.2516794 },
    { lat: 49.0643469, lng: 18.2530092 },
    { lat: 49.064232, lng: 18.2534515 },
    { lat: 49.0638564, lng: 18.2542292 },
    { lat: 49.0626141, lng: 18.2551249 },
    { lat: 49.0629582, lng: 18.256437 },
    { lat: 49.0625384, lng: 18.2579114 },
    { lat: 49.0624403, lng: 18.2582524 },
    { lat: 49.0624294, lng: 18.2583177 },
    { lat: 49.0620256, lng: 18.260813 },
    { lat: 49.0623464, lng: 18.2612387 },
    { lat: 49.0636058, lng: 18.2623195 },
    { lat: 49.0643245, lng: 18.262937 },
    { lat: 49.0643565, lng: 18.2630045 },
    { lat: 49.0643495, lng: 18.2631789 },
    { lat: 49.0643521, lng: 18.2633858 },
    { lat: 49.064447, lng: 18.2637069 },
    { lat: 49.0645052, lng: 18.2638777 },
    { lat: 49.0645802, lng: 18.2641874 },
    { lat: 49.0646204, lng: 18.2643984 },
    { lat: 49.0646711, lng: 18.2647958 },
    { lat: 49.0646329, lng: 18.2648398 },
    { lat: 49.0647112, lng: 18.2651064 },
    { lat: 49.0647336, lng: 18.2653241 },
    { lat: 49.0647777, lng: 18.2655255 },
    { lat: 49.06476, lng: 18.2657297 },
    { lat: 49.0649053, lng: 18.2669246 },
    { lat: 49.0649754, lng: 18.2671472 },
    { lat: 49.0650079, lng: 18.2673009 },
    { lat: 49.0650609, lng: 18.2674807 },
    { lat: 49.0651465, lng: 18.2676778 },
    { lat: 49.0655477, lng: 18.2686277 },
    { lat: 49.0656242, lng: 18.2688033 },
    { lat: 49.0657255, lng: 18.2691991 },
    { lat: 49.0657271, lng: 18.2695055 },
    { lat: 49.0658036, lng: 18.2697826 },
    { lat: 49.065898, lng: 18.2700545 },
    { lat: 49.0659405, lng: 18.2702673 },
    { lat: 49.066039, lng: 18.2706124 },
    { lat: 49.0661074, lng: 18.2707679 },
    { lat: 49.0662057, lng: 18.2711289 },
    { lat: 49.0662118, lng: 18.2713166 },
    { lat: 49.0662272, lng: 18.2713096 },
    { lat: 49.066587, lng: 18.2711279 },
    { lat: 49.0665959, lng: 18.2711239 },
    { lat: 49.0666807, lng: 18.2715072 },
    { lat: 49.0667471, lng: 18.271905 },
    { lat: 49.066862, lng: 18.2725197 },
    { lat: 49.0669691, lng: 18.2727797 },
    { lat: 49.0670851, lng: 18.2729752 },
    { lat: 49.0673546, lng: 18.2733109 },
    { lat: 49.0673796, lng: 18.2733141 },
    { lat: 49.0673943, lng: 18.2733161 },
    { lat: 49.0678828, lng: 18.2733756 },
    { lat: 49.0678908, lng: 18.2733767 },
    { lat: 49.0680026, lng: 18.27346 },
    { lat: 49.0682189, lng: 18.2730132 },
    { lat: 49.0683203, lng: 18.2728305 },
    { lat: 49.0686024, lng: 18.2724325 },
    { lat: 49.0691736, lng: 18.2716383 },
    { lat: 49.0694681, lng: 18.2712606 },
    { lat: 49.069483, lng: 18.2712438 },
    { lat: 49.0703048, lng: 18.2724496 },
    { lat: 49.0703168, lng: 18.2724232 },
    { lat: 49.0705112, lng: 18.2720887 },
    { lat: 49.0709103, lng: 18.2714845 },
    { lat: 49.0719964, lng: 18.2729167 },
    { lat: 49.0720165, lng: 18.2731321 },
    { lat: 49.0720261, lng: 18.2732437 },
    { lat: 49.07206, lng: 18.2736214 },
    { lat: 49.0721769, lng: 18.2749129 },
    { lat: 49.0721893, lng: 18.2750525 },
    { lat: 49.0722183, lng: 18.2753684 },
    { lat: 49.0722217, lng: 18.2754076 },
    { lat: 49.0722462, lng: 18.27568 },
    { lat: 49.0723001, lng: 18.275737 },
    { lat: 49.0724488, lng: 18.2758943 },
    { lat: 49.0724813, lng: 18.2759283 },
    { lat: 49.0724998, lng: 18.2759569 },
    { lat: 49.0725394, lng: 18.2760199 },
    { lat: 49.072563, lng: 18.2760576 },
    { lat: 49.0722851, lng: 18.2765301 },
    { lat: 49.0723786, lng: 18.2764878 },
    { lat: 49.0725982, lng: 18.2765223 },
    { lat: 49.0728635, lng: 18.2766047 },
    { lat: 49.0728142, lng: 18.2767907 },
    { lat: 49.0727542, lng: 18.2770256 },
    { lat: 49.0728204, lng: 18.2772995 },
    { lat: 49.0730431, lng: 18.277266 },
    { lat: 49.0732506, lng: 18.2772461 },
    { lat: 49.0733934, lng: 18.2772172 },
    { lat: 49.0736645, lng: 18.2771031 },
    { lat: 49.0739885, lng: 18.2777855 },
    { lat: 49.0743206, lng: 18.2777925 },
    { lat: 49.0746456, lng: 18.2781341 },
    { lat: 49.0747579, lng: 18.2782654 },
    { lat: 49.0752601, lng: 18.2783673 },
    { lat: 49.075323, lng: 18.2783621 },
    { lat: 49.0755463, lng: 18.278402 },
    { lat: 49.0759873, lng: 18.2784782 },
    { lat: 49.0764207, lng: 18.2786402 },
    { lat: 49.0767755, lng: 18.2786706 },
    { lat: 49.076978, lng: 18.2785407 },
    { lat: 49.0772508, lng: 18.2784969 },
    { lat: 49.0779748, lng: 18.2783519 },
    { lat: 49.0782295, lng: 18.2779859 },
    { lat: 49.0783586, lng: 18.2775542 },
    { lat: 49.0784717, lng: 18.2773411 },
    { lat: 49.0785613, lng: 18.2770897 },
    { lat: 49.0786591, lng: 18.2769199 },
    { lat: 49.0786869, lng: 18.2768961 },
    { lat: 49.0786994, lng: 18.2769159 },
    { lat: 49.0789039, lng: 18.2767203 },
    { lat: 49.0790474, lng: 18.2766451 },
    { lat: 49.0796083, lng: 18.2765285 },
    { lat: 49.0799666, lng: 18.2764406 },
    { lat: 49.080759, lng: 18.276368 },
    { lat: 49.0818403, lng: 18.2763961 },
    { lat: 49.0829415, lng: 18.276437 },
    { lat: 49.0830541, lng: 18.276413 },
    { lat: 49.083161, lng: 18.276246 },
    { lat: 49.0833219, lng: 18.2756998 },
    { lat: 49.0834964, lng: 18.2753113 },
    { lat: 49.0837348, lng: 18.2747399 },
    { lat: 49.0837591, lng: 18.2747491 },
    { lat: 49.0838408, lng: 18.2747976 },
    { lat: 49.0839385, lng: 18.2747305 },
    { lat: 49.0840971, lng: 18.2746005 },
    { lat: 49.0844205, lng: 18.2745541 },
    { lat: 49.0846347, lng: 18.274453 },
    { lat: 49.0848463, lng: 18.274363 },
    { lat: 49.0850434, lng: 18.2741368 },
    { lat: 49.0851159, lng: 18.2741624 },
    { lat: 49.0854676, lng: 18.2743152 },
    { lat: 49.0857582, lng: 18.2744467 },
    { lat: 49.086491, lng: 18.2747778 },
    { lat: 49.0865188, lng: 18.27479 },
    { lat: 49.0869839, lng: 18.2749941 },
    { lat: 49.0871409, lng: 18.2749973 },
    { lat: 49.087269, lng: 18.2748898 },
    { lat: 49.0873815, lng: 18.2746839 },
    { lat: 49.0878705, lng: 18.2746524 },
    { lat: 49.0880466, lng: 18.2746747 },
    { lat: 49.0882401, lng: 18.274703 },
    { lat: 49.0883311, lng: 18.2747174 },
    { lat: 49.0883444, lng: 18.2747311 },
    { lat: 49.0886081, lng: 18.2740267 },
    { lat: 49.0890991, lng: 18.2727535 },
    { lat: 49.0891238, lng: 18.2726902 },
    { lat: 49.089294, lng: 18.272248 },
    { lat: 49.0904451, lng: 18.2682968 },
    { lat: 49.0904516, lng: 18.2681925 },
    { lat: 49.0930692, lng: 18.2675949 },
    { lat: 49.0931194, lng: 18.2675837 },
    { lat: 49.0937349, lng: 18.2677163 },
    { lat: 49.0937681, lng: 18.2677237 },
    { lat: 49.0948063, lng: 18.2679468 },
    { lat: 49.0961278, lng: 18.2682934 },
    { lat: 49.0982851, lng: 18.2687332 },
    { lat: 49.0997811, lng: 18.2691521 },
    { lat: 49.1011548, lng: 18.2693853 },
    { lat: 49.1018146, lng: 18.2698397 },
    { lat: 49.103205, lng: 18.2709276 },
    { lat: 49.1028576, lng: 18.2697701 },
    { lat: 49.1025736, lng: 18.2687339 },
    { lat: 49.1019095, lng: 18.2676847 },
    { lat: 49.1012772, lng: 18.2665381 },
    { lat: 49.1006739, lng: 18.2648452 },
    { lat: 49.1003842, lng: 18.2640916 },
    { lat: 49.1001368, lng: 18.2637279 },
    { lat: 49.0999698, lng: 18.2636461 },
    { lat: 49.099741, lng: 18.2636909 },
    { lat: 49.0995334, lng: 18.2639109 },
    { lat: 49.0992442, lng: 18.2641434 },
    { lat: 49.0987563, lng: 18.2644531 },
    { lat: 49.0985019, lng: 18.2645233 },
    { lat: 49.0980888, lng: 18.26462 },
    { lat: 49.0975675, lng: 18.2646786 },
    { lat: 49.0971275, lng: 18.2645202 },
    { lat: 49.0966889, lng: 18.264465 },
    { lat: 49.0964165, lng: 18.2644964 },
    { lat: 49.0964167, lng: 18.26402 },
    { lat: 49.0964198, lng: 18.2639768 },
    { lat: 49.0964195, lng: 18.2623553 },
    { lat: 49.0964177, lng: 18.2581324 },
    { lat: 49.0964161, lng: 18.2580635 },
    { lat: 49.0964822, lng: 18.2561022 },
    { lat: 49.0965373, lng: 18.2561383 },
    { lat: 49.0969524, lng: 18.2561173 },
    { lat: 49.0969986, lng: 18.2562556 },
    { lat: 49.097119, lng: 18.2562899 },
    { lat: 49.0973534, lng: 18.2561853 },
    { lat: 49.0978712, lng: 18.2558452 },
    { lat: 49.0982155, lng: 18.2555688 },
    { lat: 49.0988161, lng: 18.2552756 },
    { lat: 49.0987092, lng: 18.2549044 },
    { lat: 49.0985855, lng: 18.2544725 },
    { lat: 49.0985748, lng: 18.2544207 },
    { lat: 49.0985593, lng: 18.2543485 },
    { lat: 49.0985564, lng: 18.2543363 },
    { lat: 49.0981668, lng: 18.2529761 },
    { lat: 49.0981603, lng: 18.2528403 },
    { lat: 49.0981537, lng: 18.2527045 },
    { lat: 49.0981447, lng: 18.2524841 },
    { lat: 49.0981434, lng: 18.2523829 },
    { lat: 49.0981407, lng: 18.2517969 },
    { lat: 49.0979406, lng: 18.2513499 },
    { lat: 49.0981089, lng: 18.2483677 },
    { lat: 49.098112, lng: 18.2483029 },
    { lat: 49.0982352, lng: 18.2461158 },
  ],
  Horovce: [
    { lat: 49.0656814, lng: 18.2494211 },
    { lat: 49.0655841, lng: 18.2493765 },
    { lat: 49.0654347, lng: 18.248831 },
    { lat: 49.0652037, lng: 18.2440009 },
    { lat: 49.0651115, lng: 18.2436309 },
    { lat: 49.064852, lng: 18.2431134 },
    { lat: 49.0647493, lng: 18.2429531 },
    { lat: 49.064417, lng: 18.2424666 },
    { lat: 49.0643381, lng: 18.242507 },
    { lat: 49.063938, lng: 18.2422167 },
    { lat: 49.0629375, lng: 18.2405715 },
    { lat: 49.0623548, lng: 18.239808 },
    { lat: 49.0596928, lng: 18.2384895 },
    { lat: 49.0593086, lng: 18.2382991 },
    { lat: 49.0592861, lng: 18.2382194 },
    { lat: 49.0592651, lng: 18.2381293 },
    { lat: 49.059251, lng: 18.2381 },
    { lat: 49.059077, lng: 18.238357 },
    { lat: 49.058761, lng: 18.238797 },
    { lat: 49.058336, lng: 18.239422 },
    { lat: 49.058161, lng: 18.23971 },
    { lat: 49.057888, lng: 18.240166 },
    { lat: 49.05767, lng: 18.240489 },
    { lat: 49.057315, lng: 18.240975 },
    { lat: 49.057009, lng: 18.241485 },
    { lat: 49.056725, lng: 18.241558 },
    { lat: 49.056572, lng: 18.241762 },
    { lat: 49.056489, lng: 18.242031 },
    { lat: 49.05608, lng: 18.242324 },
    { lat: 49.056001, lng: 18.242381 },
    { lat: 49.055712, lng: 18.242486 },
    { lat: 49.055473, lng: 18.242527 },
    { lat: 49.054981, lng: 18.242556 },
    { lat: 49.054395, lng: 18.24271 },
    { lat: 49.053999, lng: 18.242819 },
    { lat: 49.053808, lng: 18.242871 },
    { lat: 49.053527, lng: 18.242964 },
    { lat: 49.053055, lng: 18.242982 },
    { lat: 49.05243, lng: 18.243316 },
    { lat: 49.052268, lng: 18.243421 },
    { lat: 49.051433, lng: 18.244218 },
    { lat: 49.051358, lng: 18.244402 },
    { lat: 49.051213, lng: 18.244793 },
    { lat: 49.051075, lng: 18.245031 },
    { lat: 49.050815, lng: 18.245224 },
    { lat: 49.050569, lng: 18.245357 },
    { lat: 49.050431, lng: 18.245343 },
    { lat: 49.050253, lng: 18.24536 },
    { lat: 49.049061, lng: 18.246139 },
    { lat: 49.048256, lng: 18.246161 },
    { lat: 49.04793, lng: 18.246227 },
    { lat: 49.047485, lng: 18.246327 },
    { lat: 49.047196, lng: 18.246342 },
    { lat: 49.046906, lng: 18.246441 },
    { lat: 49.046621, lng: 18.24661 },
    { lat: 49.045955, lng: 18.247072 },
    { lat: 49.045585, lng: 18.247328 },
    { lat: 49.045408, lng: 18.247578 },
    { lat: 49.045263, lng: 18.247775 },
    { lat: 49.045035, lng: 18.247883 },
    { lat: 49.04482, lng: 18.247977 },
    { lat: 49.044692, lng: 18.248109 },
    { lat: 49.044513, lng: 18.248354 },
    { lat: 49.044446, lng: 18.248462 },
    { lat: 49.044432, lng: 18.248474 },
    { lat: 49.044423, lng: 18.248493 },
    { lat: 49.044369, lng: 18.248607 },
    { lat: 49.044114, lng: 18.248799 },
    { lat: 49.044031, lng: 18.248936 },
    { lat: 49.043919, lng: 18.24916 },
    { lat: 49.04386, lng: 18.249325 },
    { lat: 49.043833, lng: 18.249489 },
    { lat: 49.043852, lng: 18.249681 },
    { lat: 49.043915, lng: 18.250117 },
    { lat: 49.043926, lng: 18.250456 },
    { lat: 49.043866, lng: 18.250849 },
    { lat: 49.043717, lng: 18.251259 },
    { lat: 49.04364, lng: 18.251466 },
    { lat: 49.043601, lng: 18.251593 },
    { lat: 49.04359, lng: 18.251629 },
    { lat: 49.043516, lng: 18.251866 },
    { lat: 49.043461, lng: 18.251959 },
    { lat: 49.043428, lng: 18.252025 },
    { lat: 49.043401, lng: 18.252093 },
    { lat: 49.043377, lng: 18.252168 },
    { lat: 49.043366, lng: 18.252238 },
    { lat: 49.043363, lng: 18.252254 },
    { lat: 49.043309, lng: 18.252494 },
    { lat: 49.043279, lng: 18.252631 },
    { lat: 49.043244, lng: 18.252784 },
    { lat: 49.043242, lng: 18.252794 },
    { lat: 49.04324, lng: 18.252802 },
    { lat: 49.043221, lng: 18.252909 },
    { lat: 49.043194, lng: 18.253009 },
    { lat: 49.043154, lng: 18.253101 },
    { lat: 49.043129, lng: 18.253152 },
    { lat: 49.043111, lng: 18.253188 },
    { lat: 49.043062, lng: 18.253269 },
    { lat: 49.042979, lng: 18.253392 },
    { lat: 49.043012, lng: 18.253443 },
    { lat: 49.042698, lng: 18.253942 },
    { lat: 49.0425, lng: 18.254257 },
    { lat: 49.042099, lng: 18.254911 },
    { lat: 49.041954, lng: 18.255187 },
    { lat: 49.041844, lng: 18.255477 },
    { lat: 49.041824, lng: 18.255526 },
    { lat: 49.041217, lng: 18.257035 },
    { lat: 49.040843, lng: 18.257773 },
    { lat: 49.040296, lng: 18.258707 },
    { lat: 49.040185, lng: 18.258789 },
    { lat: 49.039349, lng: 18.259944 },
    { lat: 49.038256, lng: 18.261474 },
    { lat: 49.037408, lng: 18.26265 },
    { lat: 49.037156, lng: 18.262911 },
    { lat: 49.036807, lng: 18.263393 },
    { lat: 49.036632, lng: 18.263635 },
    { lat: 49.03604, lng: 18.264513 },
    { lat: 49.036017, lng: 18.264549 },
    { lat: 49.035898, lng: 18.264725 },
    { lat: 49.035823, lng: 18.264857 },
    { lat: 49.035793, lng: 18.264909 },
    { lat: 49.035768, lng: 18.264954 },
    { lat: 49.035481, lng: 18.265457 },
    { lat: 49.034521, lng: 18.267149 },
    { lat: 49.034355, lng: 18.267443 },
    { lat: 49.034282, lng: 18.267493 },
    { lat: 49.034234, lng: 18.267527 },
    { lat: 49.0342267, lng: 18.2675321 },
    { lat: 49.03344, lng: 18.268082 },
    { lat: 49.036356, lng: 18.268153 },
    { lat: 49.037163, lng: 18.267943 },
    { lat: 49.037206, lng: 18.267931 },
    { lat: 49.03778, lng: 18.267782 },
    { lat: 49.038106, lng: 18.267615 },
    { lat: 49.038454, lng: 18.26806 },
    { lat: 49.038988, lng: 18.267094 },
    { lat: 49.039479, lng: 18.26688 },
    { lat: 49.040493, lng: 18.266437 },
    { lat: 49.042092, lng: 18.265539 },
    { lat: 49.043, lng: 18.265297 },
    { lat: 49.043041, lng: 18.265302 },
    { lat: 49.043486, lng: 18.26536 },
    { lat: 49.04374, lng: 18.265445 },
    { lat: 49.044018, lng: 18.265568 },
    { lat: 49.044248, lng: 18.265724 },
    { lat: 49.044646, lng: 18.266122 },
    { lat: 49.045715, lng: 18.266784 },
    { lat: 49.045816, lng: 18.26685 },
    { lat: 49.046894, lng: 18.267566 },
    { lat: 49.047158, lng: 18.267842 },
    { lat: 49.047597, lng: 18.268361 },
    { lat: 49.047982, lng: 18.269395 },
    { lat: 49.048316, lng: 18.269858 },
    { lat: 49.048406, lng: 18.27016 },
    { lat: 49.048499, lng: 18.270468 },
    { lat: 49.048831, lng: 18.271571 },
    { lat: 49.04897, lng: 18.272055 },
    { lat: 49.049032, lng: 18.272272 },
    { lat: 49.049693, lng: 18.274754 },
    { lat: 49.050023, lng: 18.275807 },
    { lat: 49.050272, lng: 18.2766 },
    { lat: 49.050776, lng: 18.278386 },
    { lat: 49.050792, lng: 18.278444 },
    { lat: 49.051349, lng: 18.280498 },
    { lat: 49.051573, lng: 18.281206 },
    { lat: 49.051694, lng: 18.28159 },
    { lat: 49.051918, lng: 18.282295 },
    { lat: 49.052468, lng: 18.284034 },
    { lat: 49.0529357, lng: 18.2833862 },
    { lat: 49.0532164, lng: 18.2830019 },
    { lat: 49.0533075, lng: 18.2828763 },
    { lat: 49.0533987, lng: 18.2827524 },
    { lat: 49.0539139, lng: 18.2820448 },
    { lat: 49.0545365, lng: 18.2811829 },
    { lat: 49.0546918, lng: 18.2809756 },
    { lat: 49.0547201, lng: 18.2809379 },
    { lat: 49.0549143, lng: 18.2806791 },
    { lat: 49.055124, lng: 18.2802975 },
    { lat: 49.0553357, lng: 18.2796713 },
    { lat: 49.0556301, lng: 18.2789497 },
    { lat: 49.0561318, lng: 18.2777042 },
    { lat: 49.0569465, lng: 18.2756867 },
    { lat: 49.0576529, lng: 18.2739508 },
    { lat: 49.0576744, lng: 18.2738919 },
    { lat: 49.0577125, lng: 18.2737887 },
    { lat: 49.057732, lng: 18.2737342 },
    { lat: 49.0577397, lng: 18.2737495 },
    { lat: 49.0581127, lng: 18.2729834 },
    { lat: 49.0583563, lng: 18.2725019 },
    { lat: 49.0585277, lng: 18.27216 },
    { lat: 49.0586134, lng: 18.2719811 },
    { lat: 49.0587554, lng: 18.271778 },
    { lat: 49.0591289, lng: 18.2709204 },
    { lat: 49.0591628, lng: 18.2706602 },
    { lat: 49.0592175, lng: 18.2704703 },
    { lat: 49.0592825, lng: 18.2703244 },
    { lat: 49.0601151, lng: 18.2686269 },
    { lat: 49.0602458, lng: 18.2687227 },
    { lat: 49.0604867, lng: 18.2682744 },
    { lat: 49.0605634, lng: 18.2682133 },
    { lat: 49.0605757, lng: 18.2682318 },
    { lat: 49.0609494, lng: 18.2675172 },
    { lat: 49.0611779, lng: 18.2669688 },
    { lat: 49.0613838, lng: 18.2664805 },
    { lat: 49.0614547, lng: 18.2662773 },
    { lat: 49.0617207, lng: 18.2651358 },
    { lat: 49.0618105, lng: 18.2648477 },
    { lat: 49.062007, lng: 18.2643325 },
    { lat: 49.0621595, lng: 18.2639349 },
    { lat: 49.0621168, lng: 18.2636622 },
    { lat: 49.0621356, lng: 18.2634333 },
    { lat: 49.0622461, lng: 18.262854 },
    { lat: 49.0622506, lng: 18.2627962 },
    { lat: 49.0622977, lng: 18.2623521 },
    { lat: 49.0623297, lng: 18.262059 },
    { lat: 49.0623892, lng: 18.2616954 },
    { lat: 49.0623464, lng: 18.2612387 },
    { lat: 49.0620256, lng: 18.260813 },
    { lat: 49.0624294, lng: 18.2583177 },
    { lat: 49.0624403, lng: 18.2582524 },
    { lat: 49.0625384, lng: 18.2579114 },
    { lat: 49.0629582, lng: 18.256437 },
    { lat: 49.0626141, lng: 18.2551249 },
    { lat: 49.0638564, lng: 18.2542292 },
    { lat: 49.064232, lng: 18.2534515 },
    { lat: 49.0643469, lng: 18.2530092 },
    { lat: 49.0648481, lng: 18.2516794 },
    { lat: 49.064897, lng: 18.2515482 },
    { lat: 49.0656814, lng: 18.2494211 },
  ],
  LednickéRovne: [
    { lat: 49.0958073, lng: 18.2860729 },
    { lat: 49.0958161, lng: 18.2860096 },
    { lat: 49.095972, lng: 18.2859534 },
    { lat: 49.0960568, lng: 18.285955 },
    { lat: 49.0961246, lng: 18.2859985 },
    { lat: 49.0963272, lng: 18.2859299 },
    { lat: 49.0963784, lng: 18.2859356 },
    { lat: 49.0965081, lng: 18.2858957 },
    { lat: 49.0965553, lng: 18.2857516 },
    { lat: 49.0965672, lng: 18.2856431 },
    { lat: 49.0966063, lng: 18.2855559 },
    { lat: 49.0966428, lng: 18.2854028 },
    { lat: 49.0967329, lng: 18.285242 },
    { lat: 49.0968861, lng: 18.2852362 },
    { lat: 49.0969265, lng: 18.2850911 },
    { lat: 49.097061, lng: 18.2850764 },
    { lat: 49.0972511, lng: 18.2846255 },
    { lat: 49.0973707, lng: 18.2844664 },
    { lat: 49.0974788, lng: 18.28446 },
    { lat: 49.0975322, lng: 18.2843099 },
    { lat: 49.0976631, lng: 18.2840469 },
    { lat: 49.097812, lng: 18.2838683 },
    { lat: 49.0978006, lng: 18.2837067 },
    { lat: 49.0978805, lng: 18.283643 },
    { lat: 49.0979044, lng: 18.2835453 },
    { lat: 49.0979666, lng: 18.2835676 },
    { lat: 49.0980248, lng: 18.283519 },
    { lat: 49.0980453, lng: 18.2834414 },
    { lat: 49.0982185, lng: 18.2833137 },
    { lat: 49.0982422, lng: 18.2832344 },
    { lat: 49.0982322, lng: 18.2831752 },
    { lat: 49.0982647, lng: 18.2831121 },
    { lat: 49.0983212, lng: 18.2830939 },
    { lat: 49.0983442, lng: 18.282961 },
    { lat: 49.0984292, lng: 18.2829088 },
    { lat: 49.0984002, lng: 18.2827328 },
    { lat: 49.0984865, lng: 18.2827047 },
    { lat: 49.0985485, lng: 18.2826639 },
    { lat: 49.0985853, lng: 18.2825543 },
    { lat: 49.0987031, lng: 18.2825218 },
    { lat: 49.098771, lng: 18.2824467 },
    { lat: 49.0988469, lng: 18.2824715 },
    { lat: 49.0988871, lng: 18.2824252 },
    { lat: 49.0989021, lng: 18.2823098 },
    { lat: 49.0988677, lng: 18.2822193 },
    { lat: 49.0988757, lng: 18.2822024 },
    { lat: 49.0989754, lng: 18.2821699 },
    { lat: 49.0990268, lng: 18.2820413 },
    { lat: 49.0990139, lng: 18.2819132 },
    { lat: 49.0989484, lng: 18.2817115 },
    { lat: 49.0988429, lng: 18.2815194 },
    { lat: 49.0988157, lng: 18.2814585 },
    { lat: 49.0988288, lng: 18.2812289 },
    { lat: 49.0988146, lng: 18.2810777 },
    { lat: 49.0988646, lng: 18.2809448 },
    { lat: 49.0988472, lng: 18.2807757 },
    { lat: 49.0988841, lng: 18.2805501 },
    { lat: 49.0989406, lng: 18.2804508 },
    { lat: 49.0990302, lng: 18.2802599 },
    { lat: 49.0990804, lng: 18.2800899 },
    { lat: 49.0991307, lng: 18.2800218 },
    { lat: 49.0991327, lng: 18.2798579 },
    { lat: 49.0991753, lng: 18.2797913 },
    { lat: 49.0991995, lng: 18.2797013 },
    { lat: 49.0991971, lng: 18.2795582 },
    { lat: 49.0992321, lng: 18.2794573 },
    { lat: 49.0991903, lng: 18.2793778 },
    { lat: 49.0991809, lng: 18.2793304 },
    { lat: 49.0992527, lng: 18.2791428 },
    { lat: 49.0992955, lng: 18.2789813 },
    { lat: 49.0993433, lng: 18.2788508 },
    { lat: 49.0993414, lng: 18.2787344 },
    { lat: 49.0993945, lng: 18.2784762 },
    { lat: 49.099438, lng: 18.2782275 },
    { lat: 49.099477, lng: 18.2780402 },
    { lat: 49.0995687, lng: 18.2778035 },
    { lat: 49.0996676, lng: 18.2776772 },
    { lat: 49.0998009, lng: 18.2774398 },
    { lat: 49.0998208, lng: 18.2774325 },
    { lat: 49.0998585, lng: 18.2772221 },
    { lat: 49.0998828, lng: 18.2772134 },
    { lat: 49.0999212, lng: 18.2771122 },
    { lat: 49.0999817, lng: 18.277087 },
    { lat: 49.1000552, lng: 18.2769692 },
    { lat: 49.1001953, lng: 18.2768529 },
    { lat: 49.1003167, lng: 18.2767246 },
    { lat: 49.1003527, lng: 18.2766017 },
    { lat: 49.1004131, lng: 18.2764731 },
    { lat: 49.1004296, lng: 18.276306 },
    { lat: 49.1005572, lng: 18.2762866 },
    { lat: 49.1006352, lng: 18.276249 },
    { lat: 49.1006581, lng: 18.2762154 },
    { lat: 49.1006758, lng: 18.2761703 },
    { lat: 49.1007397, lng: 18.2759626 },
    { lat: 49.1008303, lng: 18.2759693 },
    { lat: 49.1009302, lng: 18.2758587 },
    { lat: 49.1010487, lng: 18.2757211 },
    { lat: 49.1011212, lng: 18.2756248 },
    { lat: 49.1012775, lng: 18.2754965 },
    { lat: 49.1012733, lng: 18.2754818 },
    { lat: 49.1016524, lng: 18.2749814 },
    { lat: 49.1019592, lng: 18.2742411 },
    { lat: 49.1025945, lng: 18.272783 },
    { lat: 49.1028756, lng: 18.2723059 },
    { lat: 49.1032858, lng: 18.2711979 },
    { lat: 49.1032454, lng: 18.2710635 },
    { lat: 49.103205, lng: 18.2709276 },
    { lat: 49.1018146, lng: 18.2698397 },
    { lat: 49.1011548, lng: 18.2693853 },
    { lat: 49.0997811, lng: 18.2691521 },
    { lat: 49.0982851, lng: 18.2687332 },
    { lat: 49.0961278, lng: 18.2682934 },
    { lat: 49.0948063, lng: 18.2679468 },
    { lat: 49.0937681, lng: 18.2677237 },
    { lat: 49.0937349, lng: 18.2677163 },
    { lat: 49.0931194, lng: 18.2675837 },
    { lat: 49.0930692, lng: 18.2675949 },
    { lat: 49.0904516, lng: 18.2681925 },
    { lat: 49.0904451, lng: 18.2682968 },
    { lat: 49.089294, lng: 18.272248 },
    { lat: 49.0891238, lng: 18.2726902 },
    { lat: 49.0890991, lng: 18.2727535 },
    { lat: 49.0886081, lng: 18.2740267 },
    { lat: 49.0883444, lng: 18.2747311 },
    { lat: 49.0883311, lng: 18.2747174 },
    { lat: 49.0882401, lng: 18.274703 },
    { lat: 49.0880466, lng: 18.2746747 },
    { lat: 49.0878705, lng: 18.2746524 },
    { lat: 49.0873815, lng: 18.2746839 },
    { lat: 49.087269, lng: 18.2748898 },
    { lat: 49.0871409, lng: 18.2749973 },
    { lat: 49.0869839, lng: 18.2749941 },
    { lat: 49.0865188, lng: 18.27479 },
    { lat: 49.086491, lng: 18.2747778 },
    { lat: 49.0857582, lng: 18.2744467 },
    { lat: 49.0854676, lng: 18.2743152 },
    { lat: 49.0851159, lng: 18.2741624 },
    { lat: 49.0850434, lng: 18.2741368 },
    { lat: 49.0848463, lng: 18.274363 },
    { lat: 49.0846347, lng: 18.274453 },
    { lat: 49.0844205, lng: 18.2745541 },
    { lat: 49.0840971, lng: 18.2746005 },
    { lat: 49.0839385, lng: 18.2747305 },
    { lat: 49.0838408, lng: 18.2747976 },
    { lat: 49.0837591, lng: 18.2747491 },
    { lat: 49.0837348, lng: 18.2747399 },
    { lat: 49.0834964, lng: 18.2753113 },
    { lat: 49.0833219, lng: 18.2756998 },
    { lat: 49.083161, lng: 18.276246 },
    { lat: 49.0830541, lng: 18.276413 },
    { lat: 49.0829415, lng: 18.276437 },
    { lat: 49.0818403, lng: 18.2763961 },
    { lat: 49.080759, lng: 18.276368 },
    { lat: 49.0799666, lng: 18.2764406 },
    { lat: 49.0796083, lng: 18.2765285 },
    { lat: 49.0790474, lng: 18.2766451 },
    { lat: 49.0789039, lng: 18.2767203 },
    { lat: 49.0786994, lng: 18.2769159 },
    { lat: 49.0786869, lng: 18.2768961 },
    { lat: 49.0786591, lng: 18.2769199 },
    { lat: 49.0785613, lng: 18.2770897 },
    { lat: 49.0784717, lng: 18.2773411 },
    { lat: 49.0783586, lng: 18.2775542 },
    { lat: 49.0782295, lng: 18.2779859 },
    { lat: 49.0779748, lng: 18.2783519 },
    { lat: 49.0772508, lng: 18.2784969 },
    { lat: 49.076978, lng: 18.2785407 },
    { lat: 49.0767755, lng: 18.2786706 },
    { lat: 49.0764207, lng: 18.2786402 },
    { lat: 49.0759873, lng: 18.2784782 },
    { lat: 49.0755463, lng: 18.278402 },
    { lat: 49.075323, lng: 18.2783621 },
    { lat: 49.0752601, lng: 18.2783673 },
    { lat: 49.0747579, lng: 18.2782654 },
    { lat: 49.0746456, lng: 18.2781341 },
    { lat: 49.0743206, lng: 18.2777925 },
    { lat: 49.0739885, lng: 18.2777855 },
    { lat: 49.0736645, lng: 18.2771031 },
    { lat: 49.0733934, lng: 18.2772172 },
    { lat: 49.0732506, lng: 18.2772461 },
    { lat: 49.0730431, lng: 18.277266 },
    { lat: 49.0728204, lng: 18.2772995 },
    { lat: 49.0727542, lng: 18.2770256 },
    { lat: 49.0728142, lng: 18.2767907 },
    { lat: 49.0728635, lng: 18.2766047 },
    { lat: 49.0725982, lng: 18.2765223 },
    { lat: 49.0723786, lng: 18.2764878 },
    { lat: 49.0722851, lng: 18.2765301 },
    { lat: 49.072563, lng: 18.2760576 },
    { lat: 49.0725394, lng: 18.2760199 },
    { lat: 49.0724998, lng: 18.2759569 },
    { lat: 49.0724813, lng: 18.2759283 },
    { lat: 49.0724488, lng: 18.2758943 },
    { lat: 49.0723001, lng: 18.275737 },
    { lat: 49.0722462, lng: 18.27568 },
    { lat: 49.0722217, lng: 18.2754076 },
    { lat: 49.0722183, lng: 18.2753684 },
    { lat: 49.0721893, lng: 18.2750525 },
    { lat: 49.0721769, lng: 18.2749129 },
    { lat: 49.07206, lng: 18.2736214 },
    { lat: 49.0720261, lng: 18.2732437 },
    { lat: 49.0720165, lng: 18.2731321 },
    { lat: 49.0719964, lng: 18.2729167 },
    { lat: 49.0709103, lng: 18.2714845 },
    { lat: 49.0705112, lng: 18.2720887 },
    { lat: 49.0703168, lng: 18.2724232 },
    { lat: 49.0703048, lng: 18.2724496 },
    { lat: 49.069483, lng: 18.2712438 },
    { lat: 49.0694681, lng: 18.2712606 },
    { lat: 49.0691736, lng: 18.2716383 },
    { lat: 49.0686024, lng: 18.2724325 },
    { lat: 49.0683203, lng: 18.2728305 },
    { lat: 49.0682189, lng: 18.2730132 },
    { lat: 49.0680026, lng: 18.27346 },
    { lat: 49.0678908, lng: 18.2733767 },
    { lat: 49.0678828, lng: 18.2733756 },
    { lat: 49.0673943, lng: 18.2733161 },
    { lat: 49.0673796, lng: 18.2733141 },
    { lat: 49.0673546, lng: 18.2733109 },
    { lat: 49.0670851, lng: 18.2729752 },
    { lat: 49.0669691, lng: 18.2727797 },
    { lat: 49.066862, lng: 18.2725197 },
    { lat: 49.0667471, lng: 18.271905 },
    { lat: 49.0666807, lng: 18.2715072 },
    { lat: 49.0665959, lng: 18.2711239 },
    { lat: 49.066587, lng: 18.2711279 },
    { lat: 49.0662272, lng: 18.2713096 },
    { lat: 49.0662118, lng: 18.2713166 },
    { lat: 49.0662057, lng: 18.2711289 },
    { lat: 49.0661074, lng: 18.2707679 },
    { lat: 49.066039, lng: 18.2706124 },
    { lat: 49.0659405, lng: 18.2702673 },
    { lat: 49.065898, lng: 18.2700545 },
    { lat: 49.0658036, lng: 18.2697826 },
    { lat: 49.0657271, lng: 18.2695055 },
    { lat: 49.0657255, lng: 18.2691991 },
    { lat: 49.0656242, lng: 18.2688033 },
    { lat: 49.0655477, lng: 18.2686277 },
    { lat: 49.0651465, lng: 18.2676778 },
    { lat: 49.0650609, lng: 18.2674807 },
    { lat: 49.0650079, lng: 18.2673009 },
    { lat: 49.0649754, lng: 18.2671472 },
    { lat: 49.0649053, lng: 18.2669246 },
    { lat: 49.06476, lng: 18.2657297 },
    { lat: 49.0647777, lng: 18.2655255 },
    { lat: 49.0647336, lng: 18.2653241 },
    { lat: 49.0647112, lng: 18.2651064 },
    { lat: 49.0646329, lng: 18.2648398 },
    { lat: 49.0646711, lng: 18.2647958 },
    { lat: 49.0646204, lng: 18.2643984 },
    { lat: 49.0645802, lng: 18.2641874 },
    { lat: 49.0645052, lng: 18.2638777 },
    { lat: 49.064447, lng: 18.2637069 },
    { lat: 49.0643521, lng: 18.2633858 },
    { lat: 49.0643495, lng: 18.2631789 },
    { lat: 49.0643565, lng: 18.2630045 },
    { lat: 49.0643245, lng: 18.262937 },
    { lat: 49.0636058, lng: 18.2623195 },
    { lat: 49.0623464, lng: 18.2612387 },
    { lat: 49.0623892, lng: 18.2616954 },
    { lat: 49.0623297, lng: 18.262059 },
    { lat: 49.0622977, lng: 18.2623521 },
    { lat: 49.0622506, lng: 18.2627962 },
    { lat: 49.0622461, lng: 18.262854 },
    { lat: 49.0621356, lng: 18.2634333 },
    { lat: 49.0621168, lng: 18.2636622 },
    { lat: 49.0621595, lng: 18.2639349 },
    { lat: 49.062007, lng: 18.2643325 },
    { lat: 49.0618105, lng: 18.2648477 },
    { lat: 49.0617207, lng: 18.2651358 },
    { lat: 49.0614547, lng: 18.2662773 },
    { lat: 49.0613838, lng: 18.2664805 },
    { lat: 49.0611779, lng: 18.2669688 },
    { lat: 49.0609494, lng: 18.2675172 },
    { lat: 49.0605757, lng: 18.2682318 },
    { lat: 49.0605634, lng: 18.2682133 },
    { lat: 49.0604867, lng: 18.2682744 },
    { lat: 49.0602458, lng: 18.2687227 },
    { lat: 49.0601151, lng: 18.2686269 },
    { lat: 49.0592825, lng: 18.2703244 },
    { lat: 49.0592175, lng: 18.2704703 },
    { lat: 49.0591628, lng: 18.2706602 },
    { lat: 49.0591289, lng: 18.2709204 },
    { lat: 49.0587554, lng: 18.271778 },
    { lat: 49.0586134, lng: 18.2719811 },
    { lat: 49.0585277, lng: 18.27216 },
    { lat: 49.0583563, lng: 18.2725019 },
    { lat: 49.0581127, lng: 18.2729834 },
    { lat: 49.0577397, lng: 18.2737495 },
    { lat: 49.057732, lng: 18.2737342 },
    { lat: 49.0577125, lng: 18.2737887 },
    { lat: 49.0576744, lng: 18.2738919 },
    { lat: 49.0576529, lng: 18.2739508 },
    { lat: 49.0569465, lng: 18.2756867 },
    { lat: 49.0561318, lng: 18.2777042 },
    { lat: 49.0556301, lng: 18.2789497 },
    { lat: 49.0553357, lng: 18.2796713 },
    { lat: 49.055124, lng: 18.2802975 },
    { lat: 49.0549143, lng: 18.2806791 },
    { lat: 49.0547201, lng: 18.2809379 },
    { lat: 49.0546918, lng: 18.2809756 },
    { lat: 49.0545365, lng: 18.2811829 },
    { lat: 49.0539139, lng: 18.2820448 },
    { lat: 49.0533987, lng: 18.2827524 },
    { lat: 49.0533075, lng: 18.2828763 },
    { lat: 49.0532164, lng: 18.2830019 },
    { lat: 49.0529357, lng: 18.2833862 },
    { lat: 49.052468, lng: 18.284034 },
    { lat: 49.0527277, lng: 18.2846781 },
    { lat: 49.0527644, lng: 18.2847668 },
    { lat: 49.0529523, lng: 18.2852396 },
    { lat: 49.0533727, lng: 18.2862935 },
    { lat: 49.0540352, lng: 18.2875376 },
    { lat: 49.054047, lng: 18.2875469 },
    { lat: 49.0541852, lng: 18.2876574 },
    { lat: 49.0542809, lng: 18.287735 },
    { lat: 49.0544629, lng: 18.2878804 },
    { lat: 49.0552771, lng: 18.2885339 },
    { lat: 49.056002, lng: 18.2891242 },
    { lat: 49.0565491, lng: 18.2895631 },
    { lat: 49.0569306, lng: 18.289867 },
    { lat: 49.0572153, lng: 18.2900948 },
    { lat: 49.0576224, lng: 18.2902904 },
    { lat: 49.0577604, lng: 18.290357 },
    { lat: 49.0581209, lng: 18.2905276 },
    { lat: 49.0590356, lng: 18.2909448 },
    { lat: 49.0598202, lng: 18.2913138 },
    { lat: 49.0600988, lng: 18.2914336 },
    { lat: 49.0601787, lng: 18.2914687 },
    { lat: 49.0607962, lng: 18.2917358 },
    { lat: 49.0607503, lng: 18.2915202 },
    { lat: 49.0608694, lng: 18.2915536 },
    { lat: 49.0611068, lng: 18.2916208 },
    { lat: 49.0611217, lng: 18.2916646 },
    { lat: 49.0613042, lng: 18.2915206 },
    { lat: 49.0632694, lng: 18.2926808 },
    { lat: 49.0646121, lng: 18.2935855 },
    { lat: 49.0647565, lng: 18.2936856 },
    { lat: 49.0658898, lng: 18.2944161 },
    { lat: 49.0669003, lng: 18.295049 },
    { lat: 49.0671528, lng: 18.2951864 },
    { lat: 49.0672017, lng: 18.2952547 },
    { lat: 49.0672205, lng: 18.2952461 },
    { lat: 49.0672607, lng: 18.2951797 },
    { lat: 49.0672704, lng: 18.2951921 },
    { lat: 49.0673892, lng: 18.2954774 },
    { lat: 49.0675011, lng: 18.2957626 },
    { lat: 49.0676525, lng: 18.2961467 },
    { lat: 49.0677348, lng: 18.2965679 },
    { lat: 49.0678252, lng: 18.2974729 },
    { lat: 49.0666661, lng: 18.2988275 },
    { lat: 49.0666205, lng: 18.299201 },
    { lat: 49.0666103, lng: 18.29928 },
    { lat: 49.066579, lng: 18.2995262 },
    { lat: 49.0668869, lng: 18.3005267 },
    { lat: 49.0672266, lng: 18.3016318 },
    { lat: 49.0674851, lng: 18.3026169 },
    { lat: 49.0676059, lng: 18.3030815 },
    { lat: 49.0677897, lng: 18.3037821 },
    { lat: 49.0678154, lng: 18.3038381 },
    { lat: 49.067977, lng: 18.3042028 },
    { lat: 49.0686974, lng: 18.3037143 },
    { lat: 49.0692281, lng: 18.3034185 },
    { lat: 49.0697705, lng: 18.3032349 },
    { lat: 49.0703066, lng: 18.3031798 },
    { lat: 49.0707823, lng: 18.3032719 },
    { lat: 49.0711998, lng: 18.3033944 },
    { lat: 49.0716438, lng: 18.3036658 },
    { lat: 49.0721278, lng: 18.304087 },
    { lat: 49.0725127, lng: 18.304632 },
    { lat: 49.0729669, lng: 18.3054885 },
    { lat: 49.0731995, lng: 18.3059919 },
    { lat: 49.0732417, lng: 18.3060833 },
    { lat: 49.0732803, lng: 18.3061665 },
    { lat: 49.0737797, lng: 18.3071038 },
    { lat: 49.0741839, lng: 18.3076094 },
    { lat: 49.0750135, lng: 18.3085016 },
    { lat: 49.0756388, lng: 18.3090698 },
    { lat: 49.0761237, lng: 18.3094062 },
    { lat: 49.0768424, lng: 18.3098492 },
    { lat: 49.0776744, lng: 18.3102196 },
    { lat: 49.0783975, lng: 18.3108422 },
    { lat: 49.0788665, lng: 18.3111381 },
    { lat: 49.0792844, lng: 18.3114074 },
    { lat: 49.0804506, lng: 18.3122454 },
    { lat: 49.0814554, lng: 18.3133242 },
    { lat: 49.0818228, lng: 18.3137178 },
    { lat: 49.0825292, lng: 18.3141228 },
    { lat: 49.0828906, lng: 18.314293 },
    { lat: 49.0829788, lng: 18.3143345 },
    { lat: 49.0843959, lng: 18.315 },
    { lat: 49.0849703, lng: 18.3153247 },
    { lat: 49.0858815, lng: 18.3154398 },
    { lat: 49.0859386, lng: 18.3155925 },
    { lat: 49.0859839, lng: 18.3157173 },
    { lat: 49.0860385, lng: 18.3158639 },
    { lat: 49.086438, lng: 18.3157277 },
    { lat: 49.08714, lng: 18.3153685 },
    { lat: 49.0872523, lng: 18.3153202 },
    { lat: 49.087848, lng: 18.314998 },
    { lat: 49.0881255, lng: 18.3147985 },
    { lat: 49.088323, lng: 18.3146366 },
    { lat: 49.0885327, lng: 18.3144535 },
    { lat: 49.0889311, lng: 18.3141337 },
    { lat: 49.0893193, lng: 18.3138762 },
    { lat: 49.0906103, lng: 18.3130363 },
    { lat: 49.0905369, lng: 18.3125516 },
    { lat: 49.0905189, lng: 18.3124341 },
    { lat: 49.0905133, lng: 18.3123949 },
    { lat: 49.0904631, lng: 18.3120414 },
    { lat: 49.0904103, lng: 18.3116657 },
    { lat: 49.0903919, lng: 18.3115367 },
    { lat: 49.0906536, lng: 18.310472 },
    { lat: 49.0906665, lng: 18.3104218 },
    { lat: 49.0906853, lng: 18.3103722 },
    { lat: 49.0907196, lng: 18.31028 },
    { lat: 49.0907501, lng: 18.3101996 },
    { lat: 49.0907639, lng: 18.3101449 },
    { lat: 49.0914437, lng: 18.3086871 },
    { lat: 49.0918584, lng: 18.3073541 },
    { lat: 49.0915594, lng: 18.3056875 },
    { lat: 49.0911501, lng: 18.3052689 },
    { lat: 49.090876, lng: 18.3049885 },
    { lat: 49.0906543, lng: 18.3031304 },
    { lat: 49.0918697, lng: 18.3019524 },
    { lat: 49.0932486, lng: 18.300792 },
    { lat: 49.0929901, lng: 18.2999257 },
    { lat: 49.0935101, lng: 18.2994616 },
    { lat: 49.0933624, lng: 18.2979188 },
    { lat: 49.0929725, lng: 18.2967842 },
    { lat: 49.0930118, lng: 18.2945949 },
    { lat: 49.0930518, lng: 18.292679 },
    { lat: 49.0932288, lng: 18.2920545 },
    { lat: 49.0934022, lng: 18.2914287 },
    { lat: 49.0935803, lng: 18.2908039 },
    { lat: 49.0937586, lng: 18.2901827 },
    { lat: 49.0939402, lng: 18.28956 },
    { lat: 49.0943257, lng: 18.2884725 },
    { lat: 49.0946963, lng: 18.2875608 },
    { lat: 49.095389, lng: 18.2870196 },
    { lat: 49.0956912, lng: 18.2863364 },
    { lat: 49.0957092, lng: 18.286295 },
    { lat: 49.0958073, lng: 18.2860729 },
  ],
  Nimnica: [
    { lat: 49.139282, lng: 18.376354 },
    { lat: 49.139372, lng: 18.375277 },
    { lat: 49.139434, lng: 18.375048 },
    { lat: 49.139472, lng: 18.373785 },
    { lat: 49.139473, lng: 18.373724 },
    { lat: 49.139583, lng: 18.370248 },
    { lat: 49.139596, lng: 18.370029 },
    { lat: 49.139966, lng: 18.37005 },
    { lat: 49.140746, lng: 18.369856 },
    { lat: 49.141398, lng: 18.368693 },
    { lat: 49.142257, lng: 18.367323 },
    { lat: 49.142321, lng: 18.367213 },
    { lat: 49.142343, lng: 18.367183 },
    { lat: 49.142444, lng: 18.36749 },
    { lat: 49.1426, lng: 18.367476 },
    { lat: 49.143334, lng: 18.367141 },
    { lat: 49.143696, lng: 18.367224 },
    { lat: 49.145766, lng: 18.368066 },
    { lat: 49.14658, lng: 18.368021 },
    { lat: 49.147648, lng: 18.368203 },
    { lat: 49.148967, lng: 18.36846 },
    { lat: 49.149272, lng: 18.368647 },
    { lat: 49.150283, lng: 18.368442 },
    { lat: 49.150772, lng: 18.368358 },
    { lat: 49.150745, lng: 18.367972 },
    { lat: 49.150991, lng: 18.367983 },
    { lat: 49.151945, lng: 18.36812 },
    { lat: 49.151969, lng: 18.367732 },
    { lat: 49.151972, lng: 18.367655 },
    { lat: 49.152183, lng: 18.366884 },
    { lat: 49.152297, lng: 18.366666 },
    { lat: 49.152416, lng: 18.366849 },
    { lat: 49.152572, lng: 18.366413 },
    { lat: 49.152699, lng: 18.366468 },
    { lat: 49.153073, lng: 18.366492 },
    { lat: 49.153958, lng: 18.366182 },
    { lat: 49.154339, lng: 18.367123 },
    { lat: 49.154718, lng: 18.366974 },
    { lat: 49.154868, lng: 18.366852 },
    { lat: 49.155162, lng: 18.366573 },
    { lat: 49.155303, lng: 18.366437 },
    { lat: 49.155546, lng: 18.366217 },
    { lat: 49.155812, lng: 18.365984 },
    { lat: 49.156089, lng: 18.365773 },
    { lat: 49.156319, lng: 18.3657 },
    { lat: 49.156582, lng: 18.365573 },
    { lat: 49.156867, lng: 18.365455 },
    { lat: 49.157025, lng: 18.365352 },
    { lat: 49.157195, lng: 18.365152 },
    { lat: 49.157383, lng: 18.364938 },
    { lat: 49.157586, lng: 18.364853 },
    { lat: 49.157863, lng: 18.364529 },
    { lat: 49.158005, lng: 18.364347 },
    { lat: 49.158263, lng: 18.364072 },
    { lat: 49.158453, lng: 18.363828 },
    { lat: 49.158613, lng: 18.363588 },
    { lat: 49.158766, lng: 18.363431 },
    { lat: 49.159018, lng: 18.363282 },
    { lat: 49.159248, lng: 18.363115 },
    { lat: 49.15944, lng: 18.362912 },
    { lat: 49.159567, lng: 18.362719 },
    { lat: 49.159596, lng: 18.362638 },
    { lat: 49.159693, lng: 18.362435 },
    { lat: 49.159826, lng: 18.362095 },
    { lat: 49.159891, lng: 18.361801 },
    { lat: 49.159984, lng: 18.361246 },
    { lat: 49.16003, lng: 18.360924 },
    { lat: 49.160096, lng: 18.360522 },
    { lat: 49.159952, lng: 18.360312 },
    { lat: 49.159755, lng: 18.35996 },
    { lat: 49.160377, lng: 18.358686 },
    { lat: 49.160474, lng: 18.358512 },
    { lat: 49.160725, lng: 18.358117 },
    { lat: 49.161481, lng: 18.356896 },
    { lat: 49.161629, lng: 18.356683 },
    { lat: 49.161661, lng: 18.356601 },
    { lat: 49.161834, lng: 18.356357 },
    { lat: 49.161914, lng: 18.35625 },
    { lat: 49.164436, lng: 18.355679 },
    { lat: 49.165251, lng: 18.355502 },
    { lat: 49.166017, lng: 18.355705 },
    { lat: 49.166444, lng: 18.355944 },
    { lat: 49.166846, lng: 18.356273 },
    { lat: 49.168553, lng: 18.357532 },
    { lat: 49.16915, lng: 18.357566 },
    { lat: 49.169433, lng: 18.357768 },
    { lat: 49.17031, lng: 18.358085 },
    { lat: 49.170677, lng: 18.358055 },
    { lat: 49.170702, lng: 18.358073 },
    { lat: 49.170777, lng: 18.35783 },
    { lat: 49.171032, lng: 18.357541 },
    { lat: 49.1710082, lng: 18.3574843 },
    { lat: 49.1706312, lng: 18.3568117 },
    { lat: 49.1698863, lng: 18.3558121 },
    { lat: 49.1686113, lng: 18.3534946 },
    { lat: 49.1680825, lng: 18.3528922 },
    { lat: 49.1674133, lng: 18.3522012 },
    { lat: 49.1667204, lng: 18.3518311 },
    { lat: 49.1665699, lng: 18.3517188 },
    { lat: 49.1664791, lng: 18.3516482 },
    { lat: 49.1659751, lng: 18.3512291 },
    { lat: 49.1646121, lng: 18.3508014 },
    { lat: 49.1642638, lng: 18.3498522 },
    { lat: 49.1629623, lng: 18.3499387 },
    { lat: 49.162016, lng: 18.3495786 },
    { lat: 49.1621577, lng: 18.3491048 },
    { lat: 49.16218, lng: 18.3490594 },
    { lat: 49.1622796, lng: 18.3487008 },
    { lat: 49.1599049, lng: 18.3466964 },
    { lat: 49.1584831, lng: 18.3455351 },
    { lat: 49.158239, lng: 18.3453651 },
    { lat: 49.1580601, lng: 18.3451306 },
    { lat: 49.15787, lng: 18.3449389 },
    { lat: 49.1575452, lng: 18.3446772 },
    { lat: 49.1575218, lng: 18.3446625 },
    { lat: 49.1574157, lng: 18.3445811 },
    { lat: 49.1573278, lng: 18.3444206 },
    { lat: 49.1573452, lng: 18.3433158 },
    { lat: 49.1576911, lng: 18.3425998 },
    { lat: 49.1577278, lng: 18.3424285 },
    { lat: 49.1577277, lng: 18.3423873 },
    { lat: 49.157829, lng: 18.3418989 },
    { lat: 49.1578581, lng: 18.3416286 },
    { lat: 49.1579474, lng: 18.3409251 },
    { lat: 49.1579525, lng: 18.340856 },
    { lat: 49.1579978, lng: 18.3404536 },
    { lat: 49.1579882, lng: 18.340018 },
    { lat: 49.1579659, lng: 18.3399625 },
    { lat: 49.157936, lng: 18.3399329 },
    { lat: 49.1576609, lng: 18.3394704 },
    { lat: 49.1574456, lng: 18.33913 },
    { lat: 49.1570915, lng: 18.339234 },
    { lat: 49.1568202, lng: 18.3392223 },
    { lat: 49.1566851, lng: 18.3389672 },
    { lat: 49.1566038, lng: 18.3388626 },
    { lat: 49.1557556, lng: 18.3382389 },
    { lat: 49.1554296, lng: 18.3374706 },
    { lat: 49.1550477, lng: 18.3363292 },
    { lat: 49.1549578, lng: 18.3360729 },
    { lat: 49.1544093, lng: 18.3346133 },
    { lat: 49.1541092, lng: 18.3336653 },
    { lat: 49.1538881, lng: 18.3332208 },
    { lat: 49.1537482, lng: 18.3329358 },
    { lat: 49.1534041, lng: 18.3323527 },
    { lat: 49.1527785, lng: 18.3314713 },
    { lat: 49.1524832, lng: 18.3308911 },
    { lat: 49.1522284, lng: 18.3301276 },
    { lat: 49.1520967, lng: 18.3298686 },
    { lat: 49.151973, lng: 18.3296331 },
    { lat: 49.1515307, lng: 18.3296118 },
    { lat: 49.1515772, lng: 18.3300208 },
    { lat: 49.1517371, lng: 18.3311855 },
    { lat: 49.151612, lng: 18.3314708 },
    { lat: 49.1516577, lng: 18.3318853 },
    { lat: 49.1515132, lng: 18.3332388 },
    { lat: 49.1514696, lng: 18.3332439 },
    { lat: 49.1511233, lng: 18.3333058 },
    { lat: 49.1511378, lng: 18.3340701 },
    { lat: 49.1507922, lng: 18.3341647 },
    { lat: 49.1506557, lng: 18.3345062 },
    { lat: 49.15068, lng: 18.3349847 },
    { lat: 49.1508033, lng: 18.3359396 },
    { lat: 49.1508254, lng: 18.3359912 },
    { lat: 49.1510849, lng: 18.336599 },
    { lat: 49.1512249, lng: 18.3378123 },
    { lat: 49.1511041, lng: 18.3381934 },
    { lat: 49.1510824, lng: 18.3382917 },
    { lat: 49.1510341, lng: 18.3384975 },
    { lat: 49.1510063, lng: 18.3388872 },
    { lat: 49.1509426, lng: 18.3397684 },
    { lat: 49.15089, lng: 18.3397966 },
    { lat: 49.1508481, lng: 18.3398123 },
    { lat: 49.150838, lng: 18.3398136 },
    { lat: 49.1501673, lng: 18.3400451 },
    { lat: 49.1501512, lng: 18.3390302 },
    { lat: 49.1496558, lng: 18.3390891 },
    { lat: 49.1491121, lng: 18.3393122 },
    { lat: 49.1488025, lng: 18.3399697 },
    { lat: 49.1488015, lng: 18.3399719 },
    { lat: 49.148787, lng: 18.3399751 },
    { lat: 49.1487513, lng: 18.3399819 },
    { lat: 49.1482053, lng: 18.340084 },
    { lat: 49.1477086, lng: 18.3406021 },
    { lat: 49.1473237, lng: 18.3410038 },
    { lat: 49.1471254, lng: 18.3412107 },
    { lat: 49.1470668, lng: 18.341272 },
    { lat: 49.14656, lng: 18.341328 },
    { lat: 49.1465479, lng: 18.3414762 },
    { lat: 49.1465349, lng: 18.3416464 },
    { lat: 49.1457753, lng: 18.3423472 },
    { lat: 49.1457316, lng: 18.3424748 },
    { lat: 49.1454693, lng: 18.3432336 },
    { lat: 49.1448862, lng: 18.3434177 },
    { lat: 49.1447939, lng: 18.343381 },
    { lat: 49.1443364, lng: 18.343148 },
    { lat: 49.1443213, lng: 18.3431399 },
    { lat: 49.1440397, lng: 18.3430467 },
    { lat: 49.1440305, lng: 18.3430436 },
    { lat: 49.1437489, lng: 18.3429498 },
    { lat: 49.1433026, lng: 18.3439508 },
    { lat: 49.1432836, lng: 18.3439936 },
    { lat: 49.143091, lng: 18.3444441 },
    { lat: 49.1430412, lng: 18.3445619 },
    { lat: 49.1428072, lng: 18.3446139 },
    { lat: 49.1427893, lng: 18.3446773 },
    { lat: 49.1426704, lng: 18.3451144 },
    { lat: 49.1426214, lng: 18.3451847 },
    { lat: 49.1425419, lng: 18.3452983 },
    { lat: 49.1424823, lng: 18.3453824 },
    { lat: 49.1424541, lng: 18.3454222 },
    { lat: 49.1421648, lng: 18.3455763 },
    { lat: 49.1419509, lng: 18.3449972 },
    { lat: 49.1415967, lng: 18.344775 },
    { lat: 49.1414272, lng: 18.3446701 },
    { lat: 49.1413452, lng: 18.3445139 },
    { lat: 49.1413302, lng: 18.3444867 },
    { lat: 49.1413212, lng: 18.3444684 },
    { lat: 49.1409886, lng: 18.3443297 },
    { lat: 49.1409066, lng: 18.3442951 },
    { lat: 49.1408824, lng: 18.3442855 },
    { lat: 49.1401824, lng: 18.3448215 },
    { lat: 49.1401564, lng: 18.3448618 },
    { lat: 49.1401179, lng: 18.3449201 },
    { lat: 49.1400296, lng: 18.3450559 },
    { lat: 49.1399943, lng: 18.345112 },
    { lat: 49.1393522, lng: 18.3460579 },
    { lat: 49.1392862, lng: 18.3461291 },
    { lat: 49.1392301, lng: 18.3461962 },
    { lat: 49.1391984, lng: 18.3462338 },
    { lat: 49.1390672, lng: 18.3463908 },
    { lat: 49.1390302, lng: 18.3464349 },
    { lat: 49.1390207, lng: 18.3464469 },
    { lat: 49.1389837, lng: 18.3464906 },
    { lat: 49.138969, lng: 18.3465085 },
    { lat: 49.1389266, lng: 18.3465587 },
    { lat: 49.1388833, lng: 18.3466103 },
    { lat: 49.1388321, lng: 18.3466637 },
    { lat: 49.1384698, lng: 18.3470457 },
    { lat: 49.1385003, lng: 18.347168 },
    { lat: 49.1385252, lng: 18.3472911 },
    { lat: 49.1385845, lng: 18.3476273 },
    { lat: 49.1386156, lng: 18.3478391 },
    { lat: 49.1386557, lng: 18.3480932 },
    { lat: 49.1386961, lng: 18.3484542 },
    { lat: 49.1387079, lng: 18.3485649 },
    { lat: 49.1387453, lng: 18.3488724 },
    { lat: 49.138754, lng: 18.3492694 },
    { lat: 49.138756, lng: 18.349347 },
    { lat: 49.1387584, lng: 18.3495322 },
    { lat: 49.1387688, lng: 18.3500016 },
    { lat: 49.1387674, lng: 18.3500371 },
    { lat: 49.1387493, lng: 18.3502987 },
    { lat: 49.138705, lng: 18.3509212 },
    { lat: 49.1386826, lng: 18.3512679 },
    { lat: 49.1386291, lng: 18.3516044 },
    { lat: 49.1384589, lng: 18.3527416 },
    { lat: 49.138413, lng: 18.3528889 },
    { lat: 49.1382219, lng: 18.3535098 },
    { lat: 49.1381155, lng: 18.3538671 },
    { lat: 49.1378366, lng: 18.3546326 },
    { lat: 49.1377669, lng: 18.3547587 },
    { lat: 49.1371333, lng: 18.35594 },
    { lat: 49.1370334, lng: 18.3560934 },
    { lat: 49.1368876, lng: 18.3563105 },
    { lat: 49.1361681, lng: 18.3573663 },
    { lat: 49.135897, lng: 18.3577865 },
    { lat: 49.1357288, lng: 18.3580089 },
    { lat: 49.135278, lng: 18.3586242 },
    { lat: 49.1349438, lng: 18.3590847 },
    { lat: 49.1347948, lng: 18.3592659 },
    { lat: 49.1345578, lng: 18.3595486 },
    { lat: 49.1344387, lng: 18.3596973 },
    { lat: 49.1342261, lng: 18.359953 },
    { lat: 49.1339659, lng: 18.3602654 },
    { lat: 49.1338014, lng: 18.3604724 },
    { lat: 49.1333361, lng: 18.3610284 },
    { lat: 49.1330245, lng: 18.3613709 },
    { lat: 49.1326647, lng: 18.3617564 },
    { lat: 49.1323559, lng: 18.3620845 },
    { lat: 49.1318863, lng: 18.3625856 },
    { lat: 49.1317469, lng: 18.3627394 },
    { lat: 49.1316674, lng: 18.3628284 },
    { lat: 49.131449, lng: 18.3630421 },
    { lat: 49.1309047, lng: 18.3635793 },
    { lat: 49.1307035, lng: 18.3637737 },
    { lat: 49.1303529, lng: 18.3641026 },
    { lat: 49.1301932, lng: 18.3642535 },
    { lat: 49.1301447, lng: 18.3642932 },
    { lat: 49.1294575, lng: 18.3647989 },
    { lat: 49.1292875, lng: 18.3649257 },
    { lat: 49.1292361, lng: 18.3649761 },
    { lat: 49.1289618, lng: 18.3652368 },
    { lat: 49.1288696, lng: 18.3653222 },
    { lat: 49.1287132, lng: 18.3654734 },
    { lat: 49.1286393, lng: 18.365543 },
    { lat: 49.1283767, lng: 18.3657897 },
    { lat: 49.1282568, lng: 18.3659071 },
    { lat: 49.1281376, lng: 18.3660161 },
    { lat: 49.1280659, lng: 18.3660841 },
    { lat: 49.1281085, lng: 18.3662016 },
    { lat: 49.1276218, lng: 18.3663964 },
    { lat: 49.1270328, lng: 18.3666994 },
    { lat: 49.1265086, lng: 18.366969 },
    { lat: 49.1260812, lng: 18.3672154 },
    { lat: 49.1256446, lng: 18.3674985 },
    { lat: 49.1252149, lng: 18.3678266 },
    { lat: 49.1238298, lng: 18.3691093 },
    { lat: 49.1230825, lng: 18.3699524 },
    { lat: 49.1225663, lng: 18.3705689 },
    { lat: 49.1216628, lng: 18.3719313 },
    { lat: 49.1211782, lng: 18.3726305 },
    { lat: 49.1210344, lng: 18.3728854 },
    { lat: 49.1209191, lng: 18.3730821 },
    { lat: 49.1206946, lng: 18.3735097 },
    { lat: 49.1204093, lng: 18.3742229 },
    { lat: 49.1203126, lng: 18.3748595 },
    { lat: 49.120264, lng: 18.375527 },
    { lat: 49.1202986, lng: 18.375865 },
    { lat: 49.1204202, lng: 18.376798 },
    { lat: 49.1206828, lng: 18.3778699 },
    { lat: 49.121104, lng: 18.379077 },
    { lat: 49.121408, lng: 18.379624 },
    { lat: 49.121533, lng: 18.379777 },
    { lat: 49.121811, lng: 18.380179 },
    { lat: 49.121886, lng: 18.380291 },
    { lat: 49.122007, lng: 18.380392 },
    { lat: 49.122269, lng: 18.380801 },
    { lat: 49.122586, lng: 18.381213 },
    { lat: 49.122615, lng: 18.381273 },
    { lat: 49.122837, lng: 18.381504 },
    { lat: 49.122905, lng: 18.381558 },
    { lat: 49.123214, lng: 18.381836 },
    { lat: 49.123447, lng: 18.381987 },
    { lat: 49.123722, lng: 18.382132 },
    { lat: 49.124055, lng: 18.382251 },
    { lat: 49.124224, lng: 18.382399 },
    { lat: 49.124262, lng: 18.382425 },
    { lat: 49.124469, lng: 18.38241 },
    { lat: 49.124559, lng: 18.382396 },
    { lat: 49.124958, lng: 18.382189 },
    { lat: 49.125055, lng: 18.38205 },
    { lat: 49.125294, lng: 18.381942 },
    { lat: 49.125766, lng: 18.381737 },
    { lat: 49.126271, lng: 18.381455 },
    { lat: 49.126402, lng: 18.381386 },
    { lat: 49.126719, lng: 18.381201 },
    { lat: 49.127173, lng: 18.38093 },
    { lat: 49.128293, lng: 18.380287 },
    { lat: 49.128672, lng: 18.380096 },
    { lat: 49.12924, lng: 18.379745 },
    { lat: 49.129654, lng: 18.379536 },
    { lat: 49.130348, lng: 18.379157 },
    { lat: 49.130667, lng: 18.378867 },
    { lat: 49.130856, lng: 18.378771 },
    { lat: 49.131538, lng: 18.378343 },
    { lat: 49.132357, lng: 18.37787 },
    { lat: 49.132731, lng: 18.377785 },
    { lat: 49.132995, lng: 18.377626 },
    { lat: 49.133096, lng: 18.377563 },
    { lat: 49.133388, lng: 18.377347 },
    { lat: 49.133538, lng: 18.377279 },
    { lat: 49.134088, lng: 18.37706 },
    { lat: 49.134297, lng: 18.376984 },
    { lat: 49.134369, lng: 18.376996 },
    { lat: 49.134999, lng: 18.376734 },
    { lat: 49.135635, lng: 18.376536 },
    { lat: 49.136047, lng: 18.376458 },
    { lat: 49.136383, lng: 18.376435 },
    { lat: 49.13689, lng: 18.376398 },
    { lat: 49.137162, lng: 18.376372 },
    { lat: 49.137214, lng: 18.376418 },
    { lat: 49.137317, lng: 18.376414 },
    { lat: 49.137948, lng: 18.376344 },
    { lat: 49.138056, lng: 18.376276 },
    { lat: 49.138096, lng: 18.376314 },
    { lat: 49.139282, lng: 18.376354 },
  ],
  Streženice: [
    { lat: 49.0906103, lng: 18.3130363 },
    { lat: 49.0908834, lng: 18.3129977 },
    { lat: 49.0910817, lng: 18.3129127 },
    { lat: 49.0917709, lng: 18.3127694 },
    { lat: 49.0922208, lng: 18.3126868 },
    { lat: 49.0929188, lng: 18.3125377 },
    { lat: 49.0932598, lng: 18.3124631 },
    { lat: 49.0938855, lng: 18.3121344 },
    { lat: 49.0942362, lng: 18.3119908 },
    { lat: 49.0949785, lng: 18.3117087 },
    { lat: 49.0958902, lng: 18.3117307 },
    { lat: 49.0959152, lng: 18.3127388 },
    { lat: 49.0959186, lng: 18.3128585 },
    { lat: 49.0959192, lng: 18.3128908 },
    { lat: 49.0960259, lng: 18.3131831 },
    { lat: 49.0964431, lng: 18.3143258 },
    { lat: 49.0966098, lng: 18.3147835 },
    { lat: 49.0966164, lng: 18.3148003 },
    { lat: 49.0968356, lng: 18.3154026 },
    { lat: 49.0968444, lng: 18.3154266 },
    { lat: 49.096863, lng: 18.3154781 },
    { lat: 49.0970403, lng: 18.3159631 },
    { lat: 49.0970803, lng: 18.3160734 },
    { lat: 49.0970977, lng: 18.3161212 },
    { lat: 49.0971164, lng: 18.3161728 },
    { lat: 49.097139, lng: 18.3162346 },
    { lat: 49.097155, lng: 18.3162781 },
    { lat: 49.0971764, lng: 18.3163385 },
    { lat: 49.0971856, lng: 18.3163618 },
    { lat: 49.0972045, lng: 18.3164155 },
    { lat: 49.097417, lng: 18.3169257 },
    { lat: 49.0974222, lng: 18.3169384 },
    { lat: 49.0977807, lng: 18.317035 },
    { lat: 49.0981282, lng: 18.3171476 },
    { lat: 49.0983542, lng: 18.3172436 },
    { lat: 49.0984054, lng: 18.317271 },
    { lat: 49.0990406, lng: 18.3175123 },
    { lat: 49.099202, lng: 18.3175347 },
    { lat: 49.0993063, lng: 18.3174833 },
    { lat: 49.0993403, lng: 18.3174663 },
    { lat: 49.0993875, lng: 18.3174431 },
    { lat: 49.099419, lng: 18.317399 },
    { lat: 49.0994241, lng: 18.3173924 },
    { lat: 49.0994607, lng: 18.3173405 },
    { lat: 49.0994727, lng: 18.3172714 },
    { lat: 49.0994913, lng: 18.3171603 },
    { lat: 49.0994944, lng: 18.3171354 },
    { lat: 49.0994994, lng: 18.3170647 },
    { lat: 49.0995069, lng: 18.3169568 },
    { lat: 49.0995115, lng: 18.3168788 },
    { lat: 49.0995508, lng: 18.3164906 },
    { lat: 49.0996241, lng: 18.3160876 },
    { lat: 49.099647, lng: 18.3159924 },
    { lat: 49.0996488, lng: 18.3159854 },
    { lat: 49.0996644, lng: 18.3159214 },
    { lat: 49.0996864, lng: 18.3158314 },
    { lat: 49.0997367, lng: 18.315622 },
    { lat: 49.0999982, lng: 18.3149137 },
    { lat: 49.1002112, lng: 18.3143052 },
    { lat: 49.1002189, lng: 18.3142819 },
    { lat: 49.1002325, lng: 18.3142418 },
    { lat: 49.1002647, lng: 18.3141548 },
    { lat: 49.1003188, lng: 18.3140117 },
    { lat: 49.1003217, lng: 18.3140023 },
    { lat: 49.100351, lng: 18.3139229 },
    { lat: 49.1005225, lng: 18.3134795 },
    { lat: 49.1007117, lng: 18.3131526 },
    { lat: 49.1007612, lng: 18.3130697 },
    { lat: 49.1007634, lng: 18.3130678 },
    { lat: 49.1010034, lng: 18.3127404 },
    { lat: 49.1010872, lng: 18.3126273 },
    { lat: 49.1011229, lng: 18.3125782 },
    { lat: 49.1011617, lng: 18.3125661 },
    { lat: 49.1011689, lng: 18.3125531 },
    { lat: 49.1011822, lng: 18.3125294 },
    { lat: 49.1012265, lng: 18.3124554 },
    { lat: 49.1012318, lng: 18.3124484 },
    { lat: 49.1012576, lng: 18.3124051 },
    { lat: 49.1012896, lng: 18.3123509 },
    { lat: 49.101306, lng: 18.3123239 },
    { lat: 49.1012989, lng: 18.3121968 },
    { lat: 49.1012968, lng: 18.3121602 },
    { lat: 49.1012941, lng: 18.3121119 },
    { lat: 49.1012726, lng: 18.3117288 },
    { lat: 49.1012537, lng: 18.3113914 },
    { lat: 49.1012748, lng: 18.3113843 },
    { lat: 49.1013105, lng: 18.3113768 },
    { lat: 49.1013473, lng: 18.3113702 },
    { lat: 49.1014363, lng: 18.3113471 },
    { lat: 49.1015487, lng: 18.3113195 },
    { lat: 49.1016055, lng: 18.3113078 },
    { lat: 49.10165, lng: 18.3112948 },
    { lat: 49.1017535, lng: 18.3112687 },
    { lat: 49.10185, lng: 18.3112416 },
    { lat: 49.1019654, lng: 18.311208 },
    { lat: 49.1020732, lng: 18.3111778 },
    { lat: 49.1021486, lng: 18.3111529 },
    { lat: 49.102193, lng: 18.3111406 },
    { lat: 49.1023089, lng: 18.311114 },
    { lat: 49.1023839, lng: 18.3111046 },
    { lat: 49.1024699, lng: 18.3110902 },
    { lat: 49.1024977, lng: 18.3110813 },
    { lat: 49.1025166, lng: 18.3110776 },
    { lat: 49.102542, lng: 18.3110682 },
    { lat: 49.1026971, lng: 18.3110167 },
    { lat: 49.1028456, lng: 18.3109678 },
    { lat: 49.102881, lng: 18.3109571 },
    { lat: 49.1029542, lng: 18.3109346 },
    { lat: 49.1030085, lng: 18.3109151 },
    { lat: 49.1031847, lng: 18.3108391 },
    { lat: 49.1032839, lng: 18.3107977 },
    { lat: 49.1033201, lng: 18.3107793 },
    { lat: 49.1033925, lng: 18.3107629 },
    { lat: 49.1034628, lng: 18.3107507 },
    { lat: 49.1035446, lng: 18.3107397 },
    { lat: 49.1035624, lng: 18.3107339 },
    { lat: 49.1036573, lng: 18.3107174 },
    { lat: 49.1037322, lng: 18.3107041 },
    { lat: 49.1038393, lng: 18.3106837 },
    { lat: 49.103941, lng: 18.3106672 },
    { lat: 49.1039847, lng: 18.3106622 },
    { lat: 49.1040116, lng: 18.3106562 },
    { lat: 49.1040586, lng: 18.3106526 },
    { lat: 49.104165, lng: 18.310638 },
    { lat: 49.104183, lng: 18.3106361 },
    { lat: 49.1042659, lng: 18.310626 },
    { lat: 49.1044354, lng: 18.3106113 },
    { lat: 49.1045456, lng: 18.3106054 },
    { lat: 49.1046062, lng: 18.310599 },
    { lat: 49.10463, lng: 18.3106006 },
    { lat: 49.1047753, lng: 18.3105957 },
    { lat: 49.1048189, lng: 18.3105889 },
    { lat: 49.1048664, lng: 18.3105903 },
    { lat: 49.1048999, lng: 18.3105856 },
    { lat: 49.1049989, lng: 18.3105806 },
    { lat: 49.1050769, lng: 18.3105811 },
    { lat: 49.1052701, lng: 18.3105864 },
    { lat: 49.1053184, lng: 18.3105841 },
    { lat: 49.1053783, lng: 18.3105856 },
    { lat: 49.1054044, lng: 18.310588 },
    { lat: 49.1054676, lng: 18.3105891 },
    { lat: 49.1054858, lng: 18.3105922 },
    { lat: 49.1055061, lng: 18.3105917 },
    { lat: 49.1055687, lng: 18.3106017 },
    { lat: 49.1056373, lng: 18.3106173 },
    { lat: 49.1056566, lng: 18.3106203 },
    { lat: 49.1056922, lng: 18.3106303 },
    { lat: 49.1057748, lng: 18.3106552 },
    { lat: 49.1057923, lng: 18.3106662 },
    { lat: 49.1058596, lng: 18.3106778 },
    { lat: 49.1060024, lng: 18.310669 },
    { lat: 49.1060823, lng: 18.3106672 },
    { lat: 49.1061395, lng: 18.3106605 },
    { lat: 49.1062995, lng: 18.3106987 },
    { lat: 49.1064859, lng: 18.3107421 },
    { lat: 49.106543, lng: 18.3107545 },
    { lat: 49.1065592, lng: 18.3107614 },
    { lat: 49.1066278, lng: 18.3107762 },
    { lat: 49.1066679, lng: 18.3107872 },
    { lat: 49.1067057, lng: 18.3107987 },
    { lat: 49.1067576, lng: 18.3107975 },
    { lat: 49.1067755, lng: 18.3107965 },
    { lat: 49.1067938, lng: 18.3108 },
    { lat: 49.1068083, lng: 18.3107981 },
    { lat: 49.1069439, lng: 18.3108017 },
    { lat: 49.10697, lng: 18.3108037 },
    { lat: 49.107146, lng: 18.3108042 },
    { lat: 49.107312, lng: 18.3108071 },
    { lat: 49.1074557, lng: 18.3108356 },
    { lat: 49.107652, lng: 18.3108741 },
    { lat: 49.1077581, lng: 18.3108949 },
    { lat: 49.1079348, lng: 18.3109295 },
    { lat: 49.1080742, lng: 18.3109596 },
    { lat: 49.1081351, lng: 18.3109783 },
    { lat: 49.1082439, lng: 18.3110077 },
    { lat: 49.1083976, lng: 18.3110536 },
    { lat: 49.1085443, lng: 18.311095 },
    { lat: 49.108675, lng: 18.3111335 },
    { lat: 49.1087565, lng: 18.3111569 },
    { lat: 49.1087865, lng: 18.3111694 },
    { lat: 49.108892, lng: 18.311219 },
    { lat: 49.1090427, lng: 18.3112902 },
    { lat: 49.1091991, lng: 18.3113641 },
    { lat: 49.1093057, lng: 18.311414 },
    { lat: 49.1093222, lng: 18.3114266 },
    { lat: 49.1093718, lng: 18.3114659 },
    { lat: 49.1095016, lng: 18.3115686 },
    { lat: 49.109622, lng: 18.3116638 },
    { lat: 49.109694, lng: 18.31172 },
    { lat: 49.109707, lng: 18.3117309 },
    { lat: 49.1097188, lng: 18.3117396 },
    { lat: 49.1097496, lng: 18.3117648 },
    { lat: 49.1097579, lng: 18.3117723 },
    { lat: 49.109772, lng: 18.3117838 },
    { lat: 49.1097792, lng: 18.3117897 },
    { lat: 49.1099079, lng: 18.3118916 },
    { lat: 49.1101192, lng: 18.31206 },
    { lat: 49.1103094, lng: 18.3122117 },
    { lat: 49.1104675, lng: 18.3123359 },
    { lat: 49.1105258, lng: 18.3123881 },
    { lat: 49.1106697, lng: 18.312521 },
    { lat: 49.1108208, lng: 18.3126587 },
    { lat: 49.1109387, lng: 18.3127701 },
    { lat: 49.1111289, lng: 18.3129447 },
    { lat: 49.1112693, lng: 18.3130746 },
    { lat: 49.1117812, lng: 18.3135107 },
    { lat: 49.1119246, lng: 18.31303 },
    { lat: 49.1120983, lng: 18.3124456 },
    { lat: 49.112107, lng: 18.3124195 },
    { lat: 49.1121614, lng: 18.3122437 },
    { lat: 49.1121672, lng: 18.3122257 },
    { lat: 49.1121718, lng: 18.3122092 },
    { lat: 49.1121786, lng: 18.3121892 },
    { lat: 49.1121902, lng: 18.3121532 },
    { lat: 49.1122092, lng: 18.3120896 },
    { lat: 49.1122255, lng: 18.3120389 },
    { lat: 49.1122502, lng: 18.311957 },
    { lat: 49.1122841, lng: 18.3119367 },
    { lat: 49.112422, lng: 18.3118414 },
    { lat: 49.1134449, lng: 18.3111306 },
    { lat: 49.1134491, lng: 18.3111261 },
    { lat: 49.1134926, lng: 18.3110963 },
    { lat: 49.1135144, lng: 18.3110816 },
    { lat: 49.1136736, lng: 18.311166 },
    { lat: 49.1136899, lng: 18.3111747 },
    { lat: 49.1137063, lng: 18.3111837 },
    { lat: 49.1137341, lng: 18.3111988 },
    { lat: 49.1137388, lng: 18.3112009 },
    { lat: 49.113816, lng: 18.31115 },
    { lat: 49.1138214, lng: 18.3111464 },
    { lat: 49.1142752, lng: 18.3108484 },
    { lat: 49.1142936, lng: 18.3108364 },
    { lat: 49.1143384, lng: 18.3108076 },
    { lat: 49.1143557, lng: 18.3107958 },
    { lat: 49.1144167, lng: 18.310756 },
    { lat: 49.1144104, lng: 18.3107449 },
    { lat: 49.1144221, lng: 18.3107524 },
    { lat: 49.1144351, lng: 18.3107438 },
    { lat: 49.1144483, lng: 18.3107353 },
    { lat: 49.1144961, lng: 18.3107037 },
    { lat: 49.1146347, lng: 18.3106413 },
    { lat: 49.1146601, lng: 18.3106299 },
    { lat: 49.114719, lng: 18.310596 },
    { lat: 49.1148841, lng: 18.3105024 },
    { lat: 49.1152271, lng: 18.3103609 },
    { lat: 49.1153143, lng: 18.3103251 },
    { lat: 49.1162694, lng: 18.3099357 },
    { lat: 49.116297, lng: 18.309927 },
    { lat: 49.1162547, lng: 18.3096961 },
    { lat: 49.1161956, lng: 18.3093647 },
    { lat: 49.1161373, lng: 18.3093917 },
    { lat: 49.1160144, lng: 18.3088286 },
    { lat: 49.1160128, lng: 18.3088224 },
    { lat: 49.1159976, lng: 18.3087505 },
    { lat: 49.1159699, lng: 18.3086608 },
    { lat: 49.1159478, lng: 18.3085892 },
    { lat: 49.1159245, lng: 18.3085149 },
    { lat: 49.1158847, lng: 18.3083896 },
    { lat: 49.1158653, lng: 18.3083255 },
    { lat: 49.115774, lng: 18.3080233 },
    { lat: 49.1157519, lng: 18.3079523 },
    { lat: 49.1156881, lng: 18.3077402 },
    { lat: 49.115645, lng: 18.3075941 },
    { lat: 49.1155281, lng: 18.3072196 },
    { lat: 49.1154535, lng: 18.3069779 },
    { lat: 49.1153776, lng: 18.3067296 },
    { lat: 49.1152942, lng: 18.3064493 },
    { lat: 49.1152898, lng: 18.3064329 },
    { lat: 49.1152791, lng: 18.3063835 },
    { lat: 49.1153561, lng: 18.3063453 },
    { lat: 49.1154844, lng: 18.3062817 },
    { lat: 49.1155283, lng: 18.3062585 },
    { lat: 49.1155632, lng: 18.3062363 },
    { lat: 49.1155842, lng: 18.3061888 },
    { lat: 49.115628, lng: 18.3060255 },
    { lat: 49.1156447, lng: 18.3059614 },
    { lat: 49.1156377, lng: 18.3058578 },
    { lat: 49.1156357, lng: 18.3058221 },
    { lat: 49.1156365, lng: 18.3058159 },
    { lat: 49.115642, lng: 18.3057921 },
    { lat: 49.1156505, lng: 18.3057424 },
    { lat: 49.1156568, lng: 18.3057131 },
    { lat: 49.1156812, lng: 18.3055669 },
    { lat: 49.1156871, lng: 18.3052899 },
    { lat: 49.1157037, lng: 18.3051644 },
    { lat: 49.1157362, lng: 18.304837 },
    { lat: 49.1157754, lng: 18.3046321 },
    { lat: 49.1157699, lng: 18.3045138 },
    { lat: 49.1157999, lng: 18.304345 },
    { lat: 49.1157557, lng: 18.3041202 },
    { lat: 49.1157949, lng: 18.3040345 },
    { lat: 49.1158309, lng: 18.3039513 },
    { lat: 49.1157975, lng: 18.3038998 },
    { lat: 49.1157811, lng: 18.3036505 },
    { lat: 49.1157799, lng: 18.3034663 },
    { lat: 49.1158058, lng: 18.3032025 },
    { lat: 49.1158014, lng: 18.3031858 },
    { lat: 49.1157584, lng: 18.3030441 },
    { lat: 49.1157722, lng: 18.3030479 },
    { lat: 49.1157985, lng: 18.3030547 },
    { lat: 49.1158604, lng: 18.3030708 },
    { lat: 49.1158821, lng: 18.3030766 },
    { lat: 49.1158655, lng: 18.302943 },
    { lat: 49.1158532, lng: 18.3028222 },
    { lat: 49.1158417, lng: 18.3026972 },
    { lat: 49.1158327, lng: 18.3025781 },
    { lat: 49.1158435, lng: 18.3024503 },
    { lat: 49.1158489, lng: 18.302384 },
    { lat: 49.1158645, lng: 18.302201 },
    { lat: 49.1158874, lng: 18.3020859 },
    { lat: 49.1159126, lng: 18.3019706 },
    { lat: 49.1159548, lng: 18.3018378 },
    { lat: 49.1159956, lng: 18.3017022 },
    { lat: 49.1160608, lng: 18.3014786 },
    { lat: 49.1161182, lng: 18.3012137 },
    { lat: 49.1161733, lng: 18.3010101 },
    { lat: 49.1162417, lng: 18.3008006 },
    { lat: 49.1162953, lng: 18.3006699 },
    { lat: 49.1163499, lng: 18.3005389 },
    { lat: 49.1164255, lng: 18.3004188 },
    { lat: 49.1165012, lng: 18.3002987 },
    { lat: 49.1165748, lng: 18.3001638 },
    { lat: 49.1166669, lng: 18.3000164 },
    { lat: 49.1167501, lng: 18.2998903 },
    { lat: 49.1168344, lng: 18.2997642 },
    { lat: 49.1168163, lng: 18.2997426 },
    { lat: 49.1167497, lng: 18.2996816 },
    { lat: 49.1167352, lng: 18.2996469 },
    { lat: 49.1168366, lng: 18.2996045 },
    { lat: 49.1168309, lng: 18.2995426 },
    { lat: 49.1168011, lng: 18.2994129 },
    { lat: 49.1167511, lng: 18.2993063 },
    { lat: 49.1167611, lng: 18.2992629 },
    { lat: 49.1168604, lng: 18.2992636 },
    { lat: 49.1168744, lng: 18.2992301 },
    { lat: 49.1168644, lng: 18.299172 },
    { lat: 49.1168553, lng: 18.2990928 },
    { lat: 49.1168503, lng: 18.2990042 },
    { lat: 49.1168552, lng: 18.2989682 },
    { lat: 49.1168284, lng: 18.2988945 },
    { lat: 49.1168327, lng: 18.2988512 },
    { lat: 49.1168569, lng: 18.2987202 },
    { lat: 49.1169394, lng: 18.298703 },
    { lat: 49.1170039, lng: 18.2987448 },
    { lat: 49.1170284, lng: 18.2987395 },
    { lat: 49.1170357, lng: 18.2986868 },
    { lat: 49.1170179, lng: 18.2986115 },
    { lat: 49.1170151, lng: 18.298584 },
    { lat: 49.1170332, lng: 18.2985434 },
    { lat: 49.1170873, lng: 18.2985225 },
    { lat: 49.1170931, lng: 18.2984049 },
    { lat: 49.1171401, lng: 18.2983175 },
    { lat: 49.1171605, lng: 18.29826 },
    { lat: 49.1172196, lng: 18.298207 },
    { lat: 49.117229, lng: 18.2981534 },
    { lat: 49.1172195, lng: 18.2980455 },
    { lat: 49.1172875, lng: 18.2979086 },
    { lat: 49.1173027, lng: 18.2978794 },
    { lat: 49.1173649, lng: 18.2978234 },
    { lat: 49.1173727, lng: 18.2977806 },
    { lat: 49.117337, lng: 18.2977062 },
    { lat: 49.117319, lng: 18.2976879 },
    { lat: 49.1173131, lng: 18.2976406 },
    { lat: 49.1173482, lng: 18.297504 },
    { lat: 49.1174107, lng: 18.2974719 },
    { lat: 49.1174249, lng: 18.2973845 },
    { lat: 49.1173977, lng: 18.2973412 },
    { lat: 49.1174075, lng: 18.2972751 },
    { lat: 49.1175221, lng: 18.2972854 },
    { lat: 49.1175709, lng: 18.2972902 },
    { lat: 49.1176288, lng: 18.2972372 },
    { lat: 49.1176134, lng: 18.2971841 },
    { lat: 49.1175797, lng: 18.2971277 },
    { lat: 49.1175479, lng: 18.2970835 },
    { lat: 49.1175423, lng: 18.2970436 },
    { lat: 49.117589, lng: 18.2969523 },
    { lat: 49.1176425, lng: 18.29684 },
    { lat: 49.1175771, lng: 18.2967403 },
    { lat: 49.1175816, lng: 18.2966581 },
    { lat: 49.1176743, lng: 18.2965815 },
    { lat: 49.117709, lng: 18.2965374 },
    { lat: 49.1177726, lng: 18.2964837 },
    { lat: 49.1177777, lng: 18.2964357 },
    { lat: 49.117789, lng: 18.2963325 },
    { lat: 49.1178025, lng: 18.2962747 },
    { lat: 49.1179059, lng: 18.2961861 },
    { lat: 49.1179223, lng: 18.2961564 },
    { lat: 49.1179131, lng: 18.2961139 },
    { lat: 49.1178227, lng: 18.2960306 },
    { lat: 49.1177481, lng: 18.2959677 },
    { lat: 49.1177236, lng: 18.2959146 },
    { lat: 49.1176978, lng: 18.2959163 },
    { lat: 49.1176931, lng: 18.2959733 },
    { lat: 49.1176761, lng: 18.2960119 },
    { lat: 49.1176301, lng: 18.2959959 },
    { lat: 49.1176219, lng: 18.2959513 },
    { lat: 49.1176404, lng: 18.2958177 },
    { lat: 49.117658, lng: 18.2956689 },
    { lat: 49.11766, lng: 18.2956239 },
    { lat: 49.1176072, lng: 18.2955293 },
    { lat: 49.1176023, lng: 18.2955022 },
    { lat: 49.1176614, lng: 18.2953302 },
    { lat: 49.1176197, lng: 18.2952687 },
    { lat: 49.1176166, lng: 18.2952352 },
    { lat: 49.1177007, lng: 18.2951241 },
    { lat: 49.1176299, lng: 18.2949682 },
    { lat: 49.1177393, lng: 18.2948263 },
    { lat: 49.117733, lng: 18.2947572 },
    { lat: 49.1177006, lng: 18.2947423 },
    { lat: 49.1176651, lng: 18.2945925 },
    { lat: 49.117607, lng: 18.2944612 },
    { lat: 49.1175983, lng: 18.2944069 },
    { lat: 49.1175996, lng: 18.2943903 },
    { lat: 49.1176148, lng: 18.2943786 },
    { lat: 49.1176407, lng: 18.2943781 },
    { lat: 49.1176676, lng: 18.294376 },
    { lat: 49.1176829, lng: 18.2943469 },
    { lat: 49.117662, lng: 18.2942766 },
    { lat: 49.1176825, lng: 18.2941994 },
    { lat: 49.1177195, lng: 18.2941148 },
    { lat: 49.1177642, lng: 18.2939672 },
    { lat: 49.1178312, lng: 18.2938951 },
    { lat: 49.117838, lng: 18.2937736 },
    { lat: 49.1179304, lng: 18.2937518 },
    { lat: 49.1179582, lng: 18.2937244 },
    { lat: 49.1179576, lng: 18.2936742 },
    { lat: 49.1180362, lng: 18.2935489 },
    { lat: 49.1180605, lng: 18.2934776 },
    { lat: 49.1180012, lng: 18.2933676 },
    { lat: 49.1180208, lng: 18.2932934 },
    { lat: 49.1180426, lng: 18.2932588 },
    { lat: 49.1180709, lng: 18.2932818 },
    { lat: 49.1181103, lng: 18.293281 },
    { lat: 49.1181399, lng: 18.2932436 },
    { lat: 49.1181512, lng: 18.2932054 },
    { lat: 49.1181337, lng: 18.293154 },
    { lat: 49.1180736, lng: 18.2930691 },
    { lat: 49.1180624, lng: 18.2930297 },
    { lat: 49.1180821, lng: 18.2929385 },
    { lat: 49.1180415, lng: 18.2928581 },
    { lat: 49.118038, lng: 18.292818 },
    { lat: 49.1181051, lng: 18.2927864 },
    { lat: 49.1181504, lng: 18.2926496 },
    { lat: 49.1180873, lng: 18.2925701 },
    { lat: 49.1181008, lng: 18.2924473 },
    { lat: 49.1181558, lng: 18.2923836 },
    { lat: 49.1182446, lng: 18.292398 },
    { lat: 49.1183197, lng: 18.2923489 },
    { lat: 49.1183318, lng: 18.2923239 },
    { lat: 49.1183374, lng: 18.2921822 },
    { lat: 49.1183486, lng: 18.2921609 },
    { lat: 49.1184277, lng: 18.2921443 },
    { lat: 49.1184327, lng: 18.2921331 },
    { lat: 49.1184429, lng: 18.2920927 },
    { lat: 49.1184248, lng: 18.2919714 },
    { lat: 49.1185055, lng: 18.2919007 },
    { lat: 49.1185384, lng: 18.2917034 },
    { lat: 49.1185212, lng: 18.2914776 },
    { lat: 49.1184519, lng: 18.2913311 },
    { lat: 49.1184452, lng: 18.2912911 },
    { lat: 49.1184591, lng: 18.2912195 },
    { lat: 49.1185432, lng: 18.2912284 },
    { lat: 49.1186436, lng: 18.2910885 },
    { lat: 49.1186506, lng: 18.2910314 },
    { lat: 49.1186731, lng: 18.2908905 },
    { lat: 49.118663, lng: 18.2907902 },
    { lat: 49.1187667, lng: 18.2906895 },
    { lat: 49.118828, lng: 18.2905764 },
    { lat: 49.1189566, lng: 18.2904962 },
    { lat: 49.1190069, lng: 18.2904666 },
    { lat: 49.1190371, lng: 18.2904007 },
    { lat: 49.1191339, lng: 18.2902587 },
    { lat: 49.119164, lng: 18.2902514 },
    { lat: 49.1192743, lng: 18.2902868 },
    { lat: 49.1193626, lng: 18.2902508 },
    { lat: 49.119431, lng: 18.290165 },
    { lat: 49.1194852, lng: 18.290126 },
    { lat: 49.1195181, lng: 18.2900668 },
    { lat: 49.1195853, lng: 18.2898801 },
    { lat: 49.1197135, lng: 18.2897508 },
    { lat: 49.119785, lng: 18.2897368 },
    { lat: 49.1198777, lng: 18.2896816 },
    { lat: 49.1200043, lng: 18.289525 },
    { lat: 49.1201097, lng: 18.2893345 },
    { lat: 49.1202276, lng: 18.2892637 },
    { lat: 49.1202494, lng: 18.2892306 },
    { lat: 49.1203227, lng: 18.2892093 },
    { lat: 49.1205916, lng: 18.2889755 },
    { lat: 49.120655, lng: 18.2889188 },
    { lat: 49.1207047, lng: 18.2888199 },
    { lat: 49.1206862, lng: 18.2887703 },
    { lat: 49.1206924, lng: 18.288761 },
    { lat: 49.1207066, lng: 18.2887527 },
    { lat: 49.1206976, lng: 18.2887337 },
    { lat: 49.1206742, lng: 18.2886798 },
    { lat: 49.1207397, lng: 18.2884011 },
    { lat: 49.1204633, lng: 18.2881999 },
    { lat: 49.1202913, lng: 18.2880098 },
    { lat: 49.1204692, lng: 18.2875833 },
    { lat: 49.1205814, lng: 18.2871901 },
    { lat: 49.1206511, lng: 18.2869456 },
    { lat: 49.120874, lng: 18.2870174 },
    { lat: 49.1211552, lng: 18.2871233 },
    { lat: 49.1211833, lng: 18.2871217 },
    { lat: 49.1212708, lng: 18.2863696 },
    { lat: 49.1213069, lng: 18.285829 },
    { lat: 49.1213434, lng: 18.2858362 },
    { lat: 49.1215412, lng: 18.2860437 },
    { lat: 49.1217596, lng: 18.2862151 },
    { lat: 49.1219063, lng: 18.2863364 },
    { lat: 49.1219298, lng: 18.2863531 },
    { lat: 49.1219658, lng: 18.2863702 },
    { lat: 49.1220944, lng: 18.2861303 },
    { lat: 49.1221173, lng: 18.2861154 },
    { lat: 49.1221569, lng: 18.2860569 },
    { lat: 49.1221746, lng: 18.2860315 },
    { lat: 49.122237, lng: 18.2859391 },
    { lat: 49.1222587, lng: 18.2858628 },
    { lat: 49.1223547, lng: 18.2856838 },
    { lat: 49.1223576, lng: 18.2855954 },
    { lat: 49.1223621, lng: 18.2855145 },
    { lat: 49.1223726, lng: 18.2853591 },
    { lat: 49.1224191, lng: 18.2850829 },
    { lat: 49.1224036, lng: 18.2849491 },
    { lat: 49.122376, lng: 18.2845599 },
    { lat: 49.1223573, lng: 18.284288 },
    { lat: 49.1222484, lng: 18.2827127 },
    { lat: 49.1218565, lng: 18.2825874 },
    { lat: 49.1215769, lng: 18.2823481 },
    { lat: 49.1212747, lng: 18.2819716 },
    { lat: 49.1213535, lng: 18.281428 },
    { lat: 49.1212761, lng: 18.2809956 },
    { lat: 49.1219225, lng: 18.2801129 },
    { lat: 49.1226448, lng: 18.2802381 },
    { lat: 49.1227254, lng: 18.280167 },
    { lat: 49.1227896, lng: 18.2798038 },
    { lat: 49.1227396, lng: 18.2797396 },
    { lat: 49.12276, lng: 18.2794387 },
    { lat: 49.1228574, lng: 18.2792846 },
    { lat: 49.1228174, lng: 18.2791565 },
    { lat: 49.1228756, lng: 18.2788881 },
    { lat: 49.1228983, lng: 18.2786913 },
    { lat: 49.1230041, lng: 18.2783659 },
    { lat: 49.1229667, lng: 18.278061 },
    { lat: 49.1229455, lng: 18.2777854 },
    { lat: 49.1230087, lng: 18.2775071 },
    { lat: 49.1228475, lng: 18.2773684 },
    { lat: 49.1227205, lng: 18.2773184 },
    { lat: 49.1226472, lng: 18.2771184 },
    { lat: 49.1225717, lng: 18.2766382 },
    { lat: 49.1224953, lng: 18.2758653 },
    { lat: 49.1224534, lng: 18.2744613 },
    { lat: 49.1222182, lng: 18.2736711 },
    { lat: 49.1219365, lng: 18.2736165 },
    { lat: 49.121785, lng: 18.2735315 },
    { lat: 49.1216038, lng: 18.2724599 },
    { lat: 49.1215072, lng: 18.2722877 },
    { lat: 49.1215142, lng: 18.2720113 },
    { lat: 49.1213934, lng: 18.2714908 },
    { lat: 49.1211154, lng: 18.2714231 },
    { lat: 49.1211128, lng: 18.2709155 },
    { lat: 49.1212372, lng: 18.269882 },
    { lat: 49.1212426, lng: 18.2693188 },
    { lat: 49.1208945, lng: 18.2692292 },
    { lat: 49.1203771, lng: 18.2685557 },
    { lat: 49.1200497, lng: 18.2682935 },
    { lat: 49.1198126, lng: 18.2679291 },
    { lat: 49.1192414, lng: 18.2676838 },
    { lat: 49.118586, lng: 18.267685 },
    { lat: 49.1177329, lng: 18.268701 },
    { lat: 49.1169237, lng: 18.2692362 },
    { lat: 49.1158703, lng: 18.2695035 },
    { lat: 49.114161, lng: 18.2713629 },
    { lat: 49.1141335, lng: 18.2713962 },
    { lat: 49.1118834, lng: 18.2741479 },
    { lat: 49.1116463, lng: 18.2730251 },
    { lat: 49.1111328, lng: 18.2721624 },
    { lat: 49.1106043, lng: 18.2720728 },
    { lat: 49.1078106, lng: 18.273049 },
    { lat: 49.1067381, lng: 18.2738563 },
    { lat: 49.1058755, lng: 18.272786 },
    { lat: 49.1050226, lng: 18.272046 },
    { lat: 49.104307, lng: 18.2717224 },
    { lat: 49.1037515, lng: 18.2708758 },
    { lat: 49.1032858, lng: 18.2711979 },
    { lat: 49.1028756, lng: 18.2723059 },
    { lat: 49.1025945, lng: 18.272783 },
    { lat: 49.1019592, lng: 18.2742411 },
    { lat: 49.1016524, lng: 18.2749814 },
    { lat: 49.1012733, lng: 18.2754818 },
    { lat: 49.1012775, lng: 18.2754965 },
    { lat: 49.1011212, lng: 18.2756248 },
    { lat: 49.1010487, lng: 18.2757211 },
    { lat: 49.1009302, lng: 18.2758587 },
    { lat: 49.1008303, lng: 18.2759693 },
    { lat: 49.1007397, lng: 18.2759626 },
    { lat: 49.1006758, lng: 18.2761703 },
    { lat: 49.1006581, lng: 18.2762154 },
    { lat: 49.1006352, lng: 18.276249 },
    { lat: 49.1005572, lng: 18.2762866 },
    { lat: 49.1004296, lng: 18.276306 },
    { lat: 49.1004131, lng: 18.2764731 },
    { lat: 49.1003527, lng: 18.2766017 },
    { lat: 49.1003167, lng: 18.2767246 },
    { lat: 49.1001953, lng: 18.2768529 },
    { lat: 49.1000552, lng: 18.2769692 },
    { lat: 49.0999817, lng: 18.277087 },
    { lat: 49.0999212, lng: 18.2771122 },
    { lat: 49.0998828, lng: 18.2772134 },
    { lat: 49.0998585, lng: 18.2772221 },
    { lat: 49.0998208, lng: 18.2774325 },
    { lat: 49.0998009, lng: 18.2774398 },
    { lat: 49.0996676, lng: 18.2776772 },
    { lat: 49.0995687, lng: 18.2778035 },
    { lat: 49.099477, lng: 18.2780402 },
    { lat: 49.099438, lng: 18.2782275 },
    { lat: 49.0993945, lng: 18.2784762 },
    { lat: 49.0993414, lng: 18.2787344 },
    { lat: 49.0993433, lng: 18.2788508 },
    { lat: 49.0992955, lng: 18.2789813 },
    { lat: 49.0992527, lng: 18.2791428 },
    { lat: 49.0991809, lng: 18.2793304 },
    { lat: 49.0991903, lng: 18.2793778 },
    { lat: 49.0992321, lng: 18.2794573 },
    { lat: 49.0991971, lng: 18.2795582 },
    { lat: 49.0991995, lng: 18.2797013 },
    { lat: 49.0991753, lng: 18.2797913 },
    { lat: 49.0991327, lng: 18.2798579 },
    { lat: 49.0991307, lng: 18.2800218 },
    { lat: 49.0990804, lng: 18.2800899 },
    { lat: 49.0990302, lng: 18.2802599 },
    { lat: 49.0989406, lng: 18.2804508 },
    { lat: 49.0988841, lng: 18.2805501 },
    { lat: 49.0988472, lng: 18.2807757 },
    { lat: 49.0988646, lng: 18.2809448 },
    { lat: 49.0988146, lng: 18.2810777 },
    { lat: 49.0988288, lng: 18.2812289 },
    { lat: 49.0988157, lng: 18.2814585 },
    { lat: 49.0988429, lng: 18.2815194 },
    { lat: 49.0989484, lng: 18.2817115 },
    { lat: 49.0990139, lng: 18.2819132 },
    { lat: 49.0990268, lng: 18.2820413 },
    { lat: 49.0989754, lng: 18.2821699 },
    { lat: 49.0988757, lng: 18.2822024 },
    { lat: 49.0988677, lng: 18.2822193 },
    { lat: 49.0989021, lng: 18.2823098 },
    { lat: 49.0988871, lng: 18.2824252 },
    { lat: 49.0988469, lng: 18.2824715 },
    { lat: 49.098771, lng: 18.2824467 },
    { lat: 49.0987031, lng: 18.2825218 },
    { lat: 49.0985853, lng: 18.2825543 },
    { lat: 49.0985485, lng: 18.2826639 },
    { lat: 49.0984865, lng: 18.2827047 },
    { lat: 49.0984002, lng: 18.2827328 },
    { lat: 49.0984292, lng: 18.2829088 },
    { lat: 49.0983442, lng: 18.282961 },
    { lat: 49.0983212, lng: 18.2830939 },
    { lat: 49.0982647, lng: 18.2831121 },
    { lat: 49.0982322, lng: 18.2831752 },
    { lat: 49.0982422, lng: 18.2832344 },
    { lat: 49.0982185, lng: 18.2833137 },
    { lat: 49.0980453, lng: 18.2834414 },
    { lat: 49.0980248, lng: 18.283519 },
    { lat: 49.0979666, lng: 18.2835676 },
    { lat: 49.0979044, lng: 18.2835453 },
    { lat: 49.0978805, lng: 18.283643 },
    { lat: 49.0978006, lng: 18.2837067 },
    { lat: 49.097812, lng: 18.2838683 },
    { lat: 49.0976631, lng: 18.2840469 },
    { lat: 49.0975322, lng: 18.2843099 },
    { lat: 49.0974788, lng: 18.28446 },
    { lat: 49.0973707, lng: 18.2844664 },
    { lat: 49.0972511, lng: 18.2846255 },
    { lat: 49.097061, lng: 18.2850764 },
    { lat: 49.0969265, lng: 18.2850911 },
    { lat: 49.0968861, lng: 18.2852362 },
    { lat: 49.0967329, lng: 18.285242 },
    { lat: 49.0966428, lng: 18.2854028 },
    { lat: 49.0966063, lng: 18.2855559 },
    { lat: 49.0965672, lng: 18.2856431 },
    { lat: 49.0965553, lng: 18.2857516 },
    { lat: 49.0965081, lng: 18.2858957 },
    { lat: 49.0963784, lng: 18.2859356 },
    { lat: 49.0963272, lng: 18.2859299 },
    { lat: 49.0961246, lng: 18.2859985 },
    { lat: 49.0960568, lng: 18.285955 },
    { lat: 49.095972, lng: 18.2859534 },
    { lat: 49.0958161, lng: 18.2860096 },
    { lat: 49.0958073, lng: 18.2860729 },
    { lat: 49.0957092, lng: 18.286295 },
    { lat: 49.0956912, lng: 18.2863364 },
    { lat: 49.095389, lng: 18.2870196 },
    { lat: 49.0946963, lng: 18.2875608 },
    { lat: 49.0943257, lng: 18.2884725 },
    { lat: 49.0939402, lng: 18.28956 },
    { lat: 49.0937586, lng: 18.2901827 },
    { lat: 49.0935803, lng: 18.2908039 },
    { lat: 49.0934022, lng: 18.2914287 },
    { lat: 49.0932288, lng: 18.2920545 },
    { lat: 49.0930518, lng: 18.292679 },
    { lat: 49.0930118, lng: 18.2945949 },
    { lat: 49.0929725, lng: 18.2967842 },
    { lat: 49.0933624, lng: 18.2979188 },
    { lat: 49.0935101, lng: 18.2994616 },
    { lat: 49.0929901, lng: 18.2999257 },
    { lat: 49.0932486, lng: 18.300792 },
    { lat: 49.0918697, lng: 18.3019524 },
    { lat: 49.0906543, lng: 18.3031304 },
    { lat: 49.090876, lng: 18.3049885 },
    { lat: 49.0911501, lng: 18.3052689 },
    { lat: 49.0915594, lng: 18.3056875 },
    { lat: 49.0918584, lng: 18.3073541 },
    { lat: 49.0914437, lng: 18.3086871 },
    { lat: 49.0907639, lng: 18.3101449 },
    { lat: 49.0907501, lng: 18.3101996 },
    { lat: 49.0907196, lng: 18.31028 },
    { lat: 49.0906853, lng: 18.3103722 },
    { lat: 49.0906665, lng: 18.3104218 },
    { lat: 49.0906536, lng: 18.310472 },
    { lat: 49.0903919, lng: 18.3115367 },
    { lat: 49.0904103, lng: 18.3116657 },
    { lat: 49.0904631, lng: 18.3120414 },
    { lat: 49.0905133, lng: 18.3123949 },
    { lat: 49.0905189, lng: 18.3124341 },
    { lat: 49.0905369, lng: 18.3125516 },
    { lat: 49.0906103, lng: 18.3130363 },
  ],
  HornáBreznica: [
    { lat: 49.1236694, lng: 18.2692588 },
    { lat: 49.1235152, lng: 18.2685243 },
    { lat: 49.1234868, lng: 18.2679427 },
    { lat: 49.1235524, lng: 18.2679062 },
    { lat: 49.1235543, lng: 18.2678191 },
    { lat: 49.1233683, lng: 18.2676011 },
    { lat: 49.1235015, lng: 18.2671638 },
    { lat: 49.1231881, lng: 18.2666679 },
    { lat: 49.1229517, lng: 18.2663974 },
    { lat: 49.1229648, lng: 18.2660317 },
    { lat: 49.1232309, lng: 18.2656483 },
    { lat: 49.1231083, lng: 18.2652139 },
    { lat: 49.122596, lng: 18.2650144 },
    { lat: 49.1220804, lng: 18.264555 },
    { lat: 49.1220239, lng: 18.2642735 },
    { lat: 49.1220145, lng: 18.2642275 },
    { lat: 49.1219237, lng: 18.2637784 },
    { lat: 49.1218498, lng: 18.2621498 },
    { lat: 49.1229241, lng: 18.2617558 },
    { lat: 49.1232792, lng: 18.2614312 },
    { lat: 49.1249129, lng: 18.2599339 },
    { lat: 49.1251012, lng: 18.2592114 },
    { lat: 49.1263241, lng: 18.2585732 },
    { lat: 49.1273815, lng: 18.2577373 },
    { lat: 49.1282907, lng: 18.2571556 },
    { lat: 49.1284232, lng: 18.257044 },
    { lat: 49.128449, lng: 18.257024 },
    { lat: 49.1285202, lng: 18.2569664 },
    { lat: 49.1294237, lng: 18.2562434 },
    { lat: 49.1298566, lng: 18.255878 },
    { lat: 49.1312558, lng: 18.254905 },
    { lat: 49.1319664, lng: 18.2543799 },
    { lat: 49.1321697, lng: 18.254326 },
    { lat: 49.132213, lng: 18.2543129 },
    { lat: 49.132279, lng: 18.2542837 },
    { lat: 49.1326491, lng: 18.2540447 },
    { lat: 49.1338454, lng: 18.2525355 },
    { lat: 49.1338771, lng: 18.2524962 },
    { lat: 49.134172, lng: 18.2521251 },
    { lat: 49.134491, lng: 18.2511424 },
    { lat: 49.1358689, lng: 18.2504525 },
    { lat: 49.1359219, lng: 18.2504706 },
    { lat: 49.1360979, lng: 18.2505302 },
    { lat: 49.1379339, lng: 18.2511532 },
    { lat: 49.1384962, lng: 18.2507826 },
    { lat: 49.1389452, lng: 18.2507418 },
    { lat: 49.1394153, lng: 18.2506976 },
    { lat: 49.1405057, lng: 18.250844 },
    { lat: 49.1411855, lng: 18.2510332 },
    { lat: 49.141989, lng: 18.2514162 },
    { lat: 49.1422905, lng: 18.2515208 },
    { lat: 49.1423168, lng: 18.251526 },
    { lat: 49.142931, lng: 18.2517142 },
    { lat: 49.1437536, lng: 18.25096 },
    { lat: 49.1439373, lng: 18.2508542 },
    { lat: 49.1449625, lng: 18.2508884 },
    { lat: 49.1452682, lng: 18.2510459 },
    { lat: 49.1454001, lng: 18.2513657 },
    { lat: 49.1457283, lng: 18.2514404 },
    { lat: 49.146657, lng: 18.2512855 },
    { lat: 49.1471311, lng: 18.2512688 },
    { lat: 49.1480828, lng: 18.2508406 },
    { lat: 49.1484952, lng: 18.2507523 },
    { lat: 49.1488719, lng: 18.2504905 },
    { lat: 49.149451, lng: 18.2504556 },
    { lat: 49.1497123, lng: 18.2505081 },
    { lat: 49.149797, lng: 18.2504478 },
    { lat: 49.1500709, lng: 18.250264 },
    { lat: 49.1496701, lng: 18.2498821 },
    { lat: 49.1496109, lng: 18.2494528 },
    { lat: 49.1495549, lng: 18.2490218 },
    { lat: 49.1495461, lng: 18.2485279 },
    { lat: 49.1495446, lng: 18.248461 },
    { lat: 49.1495458, lng: 18.2484034 },
    { lat: 49.1495426, lng: 18.2483254 },
    { lat: 49.1492698, lng: 18.2470334 },
    { lat: 49.149449, lng: 18.2463529 },
    { lat: 49.1496978, lng: 18.2453871 },
    { lat: 49.1495191, lng: 18.2437258 },
    { lat: 49.1494961, lng: 18.2434207 },
    { lat: 49.149196, lng: 18.243061 },
    { lat: 49.149071, lng: 18.242847 },
    { lat: 49.1488045, lng: 18.2426215 },
    { lat: 49.1484853, lng: 18.2424833 },
    { lat: 49.148249, lng: 18.2419796 },
    { lat: 49.1478129, lng: 18.241807 },
    { lat: 49.1475935, lng: 18.2417211 },
    { lat: 49.1474274, lng: 18.241656 },
    { lat: 49.147323, lng: 18.2416048 },
    { lat: 49.1473021, lng: 18.2415948 },
    { lat: 49.1467759, lng: 18.2411098 },
    { lat: 49.1467161, lng: 18.2410906 },
    { lat: 49.1466598, lng: 18.2410512 },
    { lat: 49.1461646, lng: 18.2406949 },
    { lat: 49.1450062, lng: 18.2400641 },
    { lat: 49.1449243, lng: 18.2399133 },
    { lat: 49.1447339, lng: 18.2393996 },
    { lat: 49.1444699, lng: 18.2392606 },
    { lat: 49.1441601, lng: 18.2385718 },
    { lat: 49.1440337, lng: 18.2382917 },
    { lat: 49.1439349, lng: 18.2380431 },
    { lat: 49.143705, lng: 18.2376308 },
    { lat: 49.1432014, lng: 18.2389952 },
    { lat: 49.1430938, lng: 18.239208 },
    { lat: 49.142978, lng: 18.2390762 },
    { lat: 49.1421924, lng: 18.2381947 },
    { lat: 49.1421444, lng: 18.2377857 },
    { lat: 49.1422917, lng: 18.237299 },
    { lat: 49.1423663, lng: 18.2370825 },
    { lat: 49.1423074, lng: 18.2369997 },
    { lat: 49.1421445, lng: 18.2369726 },
    { lat: 49.1420773, lng: 18.236861 },
    { lat: 49.141967, lng: 18.2369651 },
    { lat: 49.1419022, lng: 18.2369762 },
    { lat: 49.141869, lng: 18.236868 },
    { lat: 49.141654, lng: 18.2366981 },
    { lat: 49.1414144, lng: 18.23665 },
    { lat: 49.1413229, lng: 18.2365896 },
    { lat: 49.1411592, lng: 18.2362878 },
    { lat: 49.1411531, lng: 18.2361197 },
    { lat: 49.1410587, lng: 18.2356716 },
    { lat: 49.1406165, lng: 18.2343587 },
    { lat: 49.1404723, lng: 18.2335882 },
    { lat: 49.1403004, lng: 18.2333029 },
    { lat: 49.1402058, lng: 18.2331668 },
    { lat: 49.1401818, lng: 18.2330816 },
    { lat: 49.1390793, lng: 18.2315679 },
    { lat: 49.1385043, lng: 18.2309969 },
    { lat: 49.1380128, lng: 18.2300895 },
    { lat: 49.1378734, lng: 18.2299421 },
    { lat: 49.1370424, lng: 18.2291548 },
    { lat: 49.136601, lng: 18.2288331 },
    { lat: 49.1359603, lng: 18.2283801 },
    { lat: 49.1357603, lng: 18.2287312 },
    { lat: 49.1355804, lng: 18.2290601 },
    { lat: 49.1355422, lng: 18.2291421 },
    { lat: 49.1350595, lng: 18.2277751 },
    { lat: 49.1347745, lng: 18.2273266 },
    { lat: 49.134371, lng: 18.2273535 },
    { lat: 49.1336397, lng: 18.227014 },
    { lat: 49.1332343, lng: 18.2276033 },
    { lat: 49.1328435, lng: 18.226958 },
    { lat: 49.1326457, lng: 18.2263189 },
    { lat: 49.132503, lng: 18.2259712 },
    { lat: 49.1318529, lng: 18.225548 },
    { lat: 49.1317774, lng: 18.2252535 },
    { lat: 49.1311219, lng: 18.2257905 },
    { lat: 49.1312569, lng: 18.2266978 },
    { lat: 49.131131, lng: 18.2274202 },
    { lat: 49.1310456, lng: 18.2279233 },
    { lat: 49.1310197, lng: 18.2279438 },
    { lat: 49.1303088, lng: 18.2283213 },
    { lat: 49.1302118, lng: 18.2285787 },
    { lat: 49.1305853, lng: 18.2290366 },
    { lat: 49.1300657, lng: 18.2298401 },
    { lat: 49.1297727, lng: 18.2296674 },
    { lat: 49.1295548, lng: 18.2300423 },
    { lat: 49.1294939, lng: 18.2301239 },
    { lat: 49.1288729, lng: 18.2312687 },
    { lat: 49.1287335, lng: 18.2312772 },
    { lat: 49.1286144, lng: 18.2312457 },
    { lat: 49.1284597, lng: 18.2311659 },
    { lat: 49.1283934, lng: 18.2311089 },
    { lat: 49.1281973, lng: 18.2309357 },
    { lat: 49.1281195, lng: 18.2303382 },
    { lat: 49.1279012, lng: 18.2302113 },
    { lat: 49.1277497, lng: 18.229986 },
    { lat: 49.1272181, lng: 18.2296061 },
    { lat: 49.1271148, lng: 18.2295351 },
    { lat: 49.1270183, lng: 18.2293664 },
    { lat: 49.1269551, lng: 18.2290672 },
    { lat: 49.1269015, lng: 18.2285786 },
    { lat: 49.1268731, lng: 18.2279414 },
    { lat: 49.1268188, lng: 18.2272041 },
    { lat: 49.1267364, lng: 18.2266283 },
    { lat: 49.1267265, lng: 18.2263936 },
    { lat: 49.1263249, lng: 18.2266537 },
    { lat: 49.1263148, lng: 18.2271699 },
    { lat: 49.1259965, lng: 18.2278618 },
    { lat: 49.1258113, lng: 18.2285357 },
    { lat: 49.1257211, lng: 18.2286168 },
    { lat: 49.1256374, lng: 18.2286914 },
    { lat: 49.1253645, lng: 18.2287739 },
    { lat: 49.1252103, lng: 18.2288203 },
    { lat: 49.1250684, lng: 18.2288632 },
    { lat: 49.1250162, lng: 18.2287007 },
    { lat: 49.1248301, lng: 18.2284234 },
    { lat: 49.1241053, lng: 18.2273424 },
    { lat: 49.123887, lng: 18.2270171 },
    { lat: 49.1237623, lng: 18.2270054 },
    { lat: 49.1237283, lng: 18.2270034 },
    { lat: 49.12336, lng: 18.226936 },
    { lat: 49.1227615, lng: 18.2271432 },
    { lat: 49.1220311, lng: 18.2269785 },
    { lat: 49.1212, lng: 18.2271463 },
    { lat: 49.1207093, lng: 18.2268083 },
    { lat: 49.119957, lng: 18.2265352 },
    { lat: 49.1194973, lng: 18.2264666 },
    { lat: 49.1193762, lng: 18.226201 },
    { lat: 49.1193658, lng: 18.2261797 },
    { lat: 49.1192216, lng: 18.2258659 },
    { lat: 49.1189808, lng: 18.2263331 },
    { lat: 49.1185542, lng: 18.2266304 },
    { lat: 49.1183923, lng: 18.2267987 },
    { lat: 49.1180019, lng: 18.2269779 },
    { lat: 49.1173488, lng: 18.2272781 },
    { lat: 49.1171012, lng: 18.2272681 },
    { lat: 49.1168281, lng: 18.2271679 },
    { lat: 49.1167469, lng: 18.2270488 },
    { lat: 49.1166232, lng: 18.2270957 },
    { lat: 49.1165839, lng: 18.2272587 },
    { lat: 49.1164093, lng: 18.2275828 },
    { lat: 49.1164022, lng: 18.2275956 },
    { lat: 49.1160443, lng: 18.2280077 },
    { lat: 49.1158034, lng: 18.2277211 },
    { lat: 49.115772, lng: 18.2276841 },
    { lat: 49.1151199, lng: 18.2270699 },
    { lat: 49.1150896, lng: 18.2270538 },
    { lat: 49.114808, lng: 18.2269011 },
    { lat: 49.1145232, lng: 18.2271706 },
    { lat: 49.114399, lng: 18.227288 },
    { lat: 49.1143962, lng: 18.2274177 },
    { lat: 49.1141484, lng: 18.2276652 },
    { lat: 49.113905, lng: 18.2279083 },
    { lat: 49.1137456, lng: 18.2280199 },
    { lat: 49.1134492, lng: 18.2282263 },
    { lat: 49.1132517, lng: 18.228284 },
    { lat: 49.113143, lng: 18.2283164 },
    { lat: 49.1132098, lng: 18.2290373 },
    { lat: 49.1132643, lng: 18.2295401 },
    { lat: 49.1134497, lng: 18.2299828 },
    { lat: 49.1130727, lng: 18.2304175 },
    { lat: 49.1129997, lng: 18.2305013 },
    { lat: 49.1128461, lng: 18.230678 },
    { lat: 49.112779, lng: 18.2307497 },
    { lat: 49.1121657, lng: 18.2303396 },
    { lat: 49.111822, lng: 18.2301105 },
    { lat: 49.1116497, lng: 18.2306518 },
    { lat: 49.1109468, lng: 18.2305309 },
    { lat: 49.1108268, lng: 18.2312418 },
    { lat: 49.1105754, lng: 18.2327354 },
    { lat: 49.1101469, lng: 18.2322411 },
    { lat: 49.1097196, lng: 18.2316722 },
    { lat: 49.1093419, lng: 18.2314976 },
    { lat: 49.1090362, lng: 18.2314176 },
    { lat: 49.1079246, lng: 18.231714 },
    { lat: 49.107397, lng: 18.2316 },
    { lat: 49.1066549, lng: 18.2320241 },
    { lat: 49.1063398, lng: 18.2313029 },
    { lat: 49.1062789, lng: 18.2313431 },
    { lat: 49.1051936, lng: 18.2332137 },
    { lat: 49.1051341, lng: 18.2331578 },
    { lat: 49.1050633, lng: 18.2333033 },
    { lat: 49.1049632, lng: 18.2334288 },
    { lat: 49.1049482, lng: 18.2339785 },
    { lat: 49.105078, lng: 18.2341177 },
    { lat: 49.1051208, lng: 18.2342381 },
    { lat: 49.1050985, lng: 18.234579 },
    { lat: 49.1051286, lng: 18.2349306 },
    { lat: 49.1051345, lng: 18.2351344 },
    { lat: 49.1048448, lng: 18.2352577 },
    { lat: 49.1047879, lng: 18.2352891 },
    { lat: 49.1047717, lng: 18.235162 },
    { lat: 49.1043221, lng: 18.2354902 },
    { lat: 49.1042961, lng: 18.2355094 },
    { lat: 49.1042414, lng: 18.2352822 },
    { lat: 49.104097, lng: 18.2347657 },
    { lat: 49.1038307, lng: 18.234229 },
    { lat: 49.1035629, lng: 18.2347576 },
    { lat: 49.1034779, lng: 18.2349101 },
    { lat: 49.1025518, lng: 18.2366043 },
    { lat: 49.1025451, lng: 18.2367226 },
    { lat: 49.1024433, lng: 18.2374555 },
    { lat: 49.1023835, lng: 18.2378529 },
    { lat: 49.1022903, lng: 18.2381983 },
    { lat: 49.1020687, lng: 18.2393833 },
    { lat: 49.1019969, lng: 18.2397687 },
    { lat: 49.1019444, lng: 18.2398771 },
    { lat: 49.101817, lng: 18.2398791 },
    { lat: 49.1016194, lng: 18.2400563 },
    { lat: 49.101433, lng: 18.2403506 },
    { lat: 49.101342, lng: 18.2404954 },
    { lat: 49.1009317, lng: 18.2410412 },
    { lat: 49.1008789, lng: 18.2410035 },
    { lat: 49.0999653, lng: 18.2428209 },
    { lat: 49.1001352, lng: 18.2431105 },
    { lat: 49.1002738, lng: 18.2433461 },
    { lat: 49.0992333, lng: 18.2442767 },
    { lat: 49.0982352, lng: 18.2461158 },
    { lat: 49.098112, lng: 18.2483029 },
    { lat: 49.0981089, lng: 18.2483677 },
    { lat: 49.0979406, lng: 18.2513499 },
    { lat: 49.0981407, lng: 18.2517969 },
    { lat: 49.0981434, lng: 18.2523829 },
    { lat: 49.0981447, lng: 18.2524841 },
    { lat: 49.0981537, lng: 18.2527045 },
    { lat: 49.0981603, lng: 18.2528403 },
    { lat: 49.0981668, lng: 18.2529761 },
    { lat: 49.0985564, lng: 18.2543363 },
    { lat: 49.0985593, lng: 18.2543485 },
    { lat: 49.0985748, lng: 18.2544207 },
    { lat: 49.0985855, lng: 18.2544725 },
    { lat: 49.0987092, lng: 18.2549044 },
    { lat: 49.0988161, lng: 18.2552756 },
    { lat: 49.0982155, lng: 18.2555688 },
    { lat: 49.0978712, lng: 18.2558452 },
    { lat: 49.0973534, lng: 18.2561853 },
    { lat: 49.097119, lng: 18.2562899 },
    { lat: 49.0969986, lng: 18.2562556 },
    { lat: 49.0969524, lng: 18.2561173 },
    { lat: 49.0965373, lng: 18.2561383 },
    { lat: 49.0964822, lng: 18.2561022 },
    { lat: 49.0964161, lng: 18.2580635 },
    { lat: 49.0964177, lng: 18.2581324 },
    { lat: 49.0964195, lng: 18.2623553 },
    { lat: 49.0964198, lng: 18.2639768 },
    { lat: 49.0964167, lng: 18.26402 },
    { lat: 49.0964165, lng: 18.2644964 },
    { lat: 49.0966889, lng: 18.264465 },
    { lat: 49.0971275, lng: 18.2645202 },
    { lat: 49.0975675, lng: 18.2646786 },
    { lat: 49.0980888, lng: 18.26462 },
    { lat: 49.0985019, lng: 18.2645233 },
    { lat: 49.0987563, lng: 18.2644531 },
    { lat: 49.0992442, lng: 18.2641434 },
    { lat: 49.0995334, lng: 18.2639109 },
    { lat: 49.099741, lng: 18.2636909 },
    { lat: 49.0999698, lng: 18.2636461 },
    { lat: 49.1001368, lng: 18.2637279 },
    { lat: 49.1003842, lng: 18.2640916 },
    { lat: 49.1006739, lng: 18.2648452 },
    { lat: 49.1012772, lng: 18.2665381 },
    { lat: 49.1019095, lng: 18.2676847 },
    { lat: 49.1025736, lng: 18.2687339 },
    { lat: 49.1028576, lng: 18.2697701 },
    { lat: 49.103205, lng: 18.2709276 },
    { lat: 49.1032454, lng: 18.2710635 },
    { lat: 49.1032858, lng: 18.2711979 },
    { lat: 49.1037515, lng: 18.2708758 },
    { lat: 49.104307, lng: 18.2717224 },
    { lat: 49.1050226, lng: 18.272046 },
    { lat: 49.1058755, lng: 18.272786 },
    { lat: 49.1067381, lng: 18.2738563 },
    { lat: 49.1078106, lng: 18.273049 },
    { lat: 49.1106043, lng: 18.2720728 },
    { lat: 49.1111328, lng: 18.2721624 },
    { lat: 49.1116463, lng: 18.2730251 },
    { lat: 49.1118834, lng: 18.2741479 },
    { lat: 49.1141335, lng: 18.2713962 },
    { lat: 49.114161, lng: 18.2713629 },
    { lat: 49.1158703, lng: 18.2695035 },
    { lat: 49.1169237, lng: 18.2692362 },
    { lat: 49.1177329, lng: 18.268701 },
    { lat: 49.118586, lng: 18.267685 },
    { lat: 49.1192414, lng: 18.2676838 },
    { lat: 49.1198126, lng: 18.2679291 },
    { lat: 49.1200497, lng: 18.2682935 },
    { lat: 49.1203771, lng: 18.2685557 },
    { lat: 49.1208945, lng: 18.2692292 },
    { lat: 49.1212426, lng: 18.2693188 },
    { lat: 49.1215222, lng: 18.2693541 },
    { lat: 49.1218368, lng: 18.2693114 },
    { lat: 49.1236694, lng: 18.2692588 },
  ],
  Kvašov: [
    { lat: 49.0890473, lng: 18.2392632 },
    { lat: 49.0870231, lng: 18.2382885 },
    { lat: 49.0869441, lng: 18.238209 },
    { lat: 49.0869046, lng: 18.2381692 },
    { lat: 49.0865731, lng: 18.2378361 },
    { lat: 49.0863571, lng: 18.236931 },
    { lat: 49.0861275, lng: 18.2363655 },
    { lat: 49.0858221, lng: 18.2351796 },
    { lat: 49.0867663, lng: 18.2342424 },
    { lat: 49.0868347, lng: 18.2341754 },
    { lat: 49.0873057, lng: 18.2337079 },
    { lat: 49.0880995, lng: 18.2327853 },
    { lat: 49.08847, lng: 18.2323548 },
    { lat: 49.0893493, lng: 18.231289 },
    { lat: 49.0904057, lng: 18.2311171 },
    { lat: 49.0904527, lng: 18.2311098 },
    { lat: 49.0910921, lng: 18.2310058 },
    { lat: 49.0914914, lng: 18.2297707 },
    { lat: 49.0915212, lng: 18.2296795 },
    { lat: 49.091548, lng: 18.2295957 },
    { lat: 49.0917689, lng: 18.2289164 },
    { lat: 49.0915413, lng: 18.2285846 },
    { lat: 49.0912044, lng: 18.2281149 },
    { lat: 49.0908744, lng: 18.2276702 },
    { lat: 49.0906696, lng: 18.2273811 },
    { lat: 49.090485, lng: 18.2271313 },
    { lat: 49.0899706, lng: 18.2264988 },
    { lat: 49.0899648, lng: 18.2264968 },
    { lat: 49.0899234, lng: 18.2264809 },
    { lat: 49.0897942, lng: 18.2264313 },
    { lat: 49.0896684, lng: 18.2263831 },
    { lat: 49.0893213, lng: 18.2263097 },
    { lat: 49.088963, lng: 18.2262183 },
    { lat: 49.0886145, lng: 18.2261627 },
    { lat: 49.0878869, lng: 18.226047 },
    { lat: 49.087372, lng: 18.2258997 },
    { lat: 49.0873141, lng: 18.2257744 },
    { lat: 49.0870463, lng: 18.2251937 },
    { lat: 49.0874841, lng: 18.2247181 },
    { lat: 49.0876531, lng: 18.2245344 },
    { lat: 49.0881154, lng: 18.2240328 },
    { lat: 49.0882957, lng: 18.2236521 },
    { lat: 49.0882786, lng: 18.2235482 },
    { lat: 49.0882665, lng: 18.2234756 },
    { lat: 49.0882732, lng: 18.2232771 },
    { lat: 49.0882779, lng: 18.2231609 },
    { lat: 49.0882821, lng: 18.2230372 },
    { lat: 49.0882946, lng: 18.2226803 },
    { lat: 49.0882969, lng: 18.2226217 },
    { lat: 49.0882888, lng: 18.2223014 },
    { lat: 49.0882887, lng: 18.2222993 },
    { lat: 49.0882798, lng: 18.2219853 },
    { lat: 49.0882779, lng: 18.2218905 },
    { lat: 49.0882748, lng: 18.2218175 },
    { lat: 49.0882735, lng: 18.221734 },
    { lat: 49.0882713, lng: 18.2216768 },
    { lat: 49.0882706, lng: 18.2216236 },
    { lat: 49.0882691, lng: 18.2215781 },
    { lat: 49.0882677, lng: 18.2215337 },
    { lat: 49.0882665, lng: 18.2214928 },
    { lat: 49.0882658, lng: 18.2214594 },
    { lat: 49.0882658, lng: 18.2214387 },
    { lat: 49.0882638, lng: 18.2213857 },
    { lat: 49.0882626, lng: 18.2213241 },
    { lat: 49.0882611, lng: 18.2212775 },
    { lat: 49.0882641, lng: 18.2212336 },
    { lat: 49.0882725, lng: 18.2211417 },
    { lat: 49.0882779, lng: 18.221078 },
    { lat: 49.088656, lng: 18.2197527 },
    { lat: 49.0884264, lng: 18.2194259 },
    { lat: 49.0885337, lng: 18.2190514 },
    { lat: 49.088595, lng: 18.2188381 },
    { lat: 49.0887411, lng: 18.2185908 },
    { lat: 49.0889388, lng: 18.2176843 },
    { lat: 49.088898, lng: 18.2170277 },
    { lat: 49.0888737, lng: 18.2161432 },
    { lat: 49.0888274, lng: 18.215034 },
    { lat: 49.0888416, lng: 18.2136793 },
    { lat: 49.0890064, lng: 18.2127896 },
    { lat: 49.0891564, lng: 18.2122712 },
    { lat: 49.0892842, lng: 18.2118435 },
    { lat: 49.08959, lng: 18.2114269 },
    { lat: 49.0899051, lng: 18.2109961 },
    { lat: 49.0899335, lng: 18.2106437 },
    { lat: 49.0901013, lng: 18.2104788 },
    { lat: 49.0901045, lng: 18.2097037 },
    { lat: 49.090225, lng: 18.2090462 },
    { lat: 49.0903504, lng: 18.2085739 },
    { lat: 49.0906603, lng: 18.2081115 },
    { lat: 49.0909893, lng: 18.2076286 },
    { lat: 49.0908897, lng: 18.2069304 },
    { lat: 49.091057, lng: 18.2061066 },
    { lat: 49.0910885, lng: 18.2056887 },
    { lat: 49.0910621, lng: 18.2054037 },
    { lat: 49.0911208, lng: 18.2049491 },
    { lat: 49.0913368, lng: 18.204465 },
    { lat: 49.0916951, lng: 18.2034257 },
    { lat: 49.091942, lng: 18.2023949 },
    { lat: 49.0920217, lng: 18.201695 },
    { lat: 49.0921617, lng: 18.2010842 },
    { lat: 49.092265, lng: 18.2006009 },
    { lat: 49.0924628, lng: 18.200132 },
    { lat: 49.0925532, lng: 18.1996798 },
    { lat: 49.0927572, lng: 18.199222 },
    { lat: 49.0929196, lng: 18.1989629 },
    { lat: 49.093018, lng: 18.1988094 },
    { lat: 49.0931496, lng: 18.1986044 },
    { lat: 49.0932156, lng: 18.1983181 },
    { lat: 49.093328, lng: 18.197824 },
    { lat: 49.093259, lng: 18.197841 },
    { lat: 49.093255, lng: 18.197842 },
    { lat: 49.093212, lng: 18.197852 },
    { lat: 49.093196, lng: 18.197857 },
    { lat: 49.092724, lng: 18.19797 },
    { lat: 49.091582, lng: 18.197107 },
    { lat: 49.091472, lng: 18.197213 },
    { lat: 49.090923, lng: 18.197555 },
    { lat: 49.090544, lng: 18.197908 },
    { lat: 49.090289, lng: 18.198129 },
    { lat: 49.09009, lng: 18.198397 },
    { lat: 49.08987, lng: 18.19859 },
    { lat: 49.089562, lng: 18.19847 },
    { lat: 49.089298, lng: 18.198928 },
    { lat: 49.08892, lng: 18.199145 },
    { lat: 49.088544, lng: 18.199276 },
    { lat: 49.088393, lng: 18.199366 },
    { lat: 49.087608, lng: 18.199618 },
    { lat: 49.087257, lng: 18.200462 },
    { lat: 49.086923, lng: 18.200912 },
    { lat: 49.086841, lng: 18.201022 },
    { lat: 49.086724, lng: 18.201179 },
    { lat: 49.086619, lng: 18.201321 },
    { lat: 49.086377, lng: 18.201944 },
    { lat: 49.08586, lng: 18.202961 },
    { lat: 49.085859, lng: 18.202969 },
    { lat: 49.085696, lng: 18.204061 },
    { lat: 49.085014, lng: 18.205183 },
    { lat: 49.084647, lng: 18.205811 },
    { lat: 49.083615, lng: 18.20691 },
    { lat: 49.082999, lng: 18.207081 },
    { lat: 49.081799, lng: 18.208211 },
    { lat: 49.081814, lng: 18.20828 },
    { lat: 49.081122, lng: 18.208417 },
    { lat: 49.080578, lng: 18.208398 },
    { lat: 49.08018, lng: 18.20866 },
    { lat: 49.079761, lng: 18.20911 },
    { lat: 49.079502, lng: 18.20947 },
    { lat: 49.079218, lng: 18.210018 },
    { lat: 49.079119, lng: 18.210193 },
    { lat: 49.078285, lng: 18.211667 },
    { lat: 49.077579, lng: 18.212239 },
    { lat: 49.075784, lng: 18.213599 },
    { lat: 49.075208, lng: 18.214214 },
    { lat: 49.075146, lng: 18.214279 },
    { lat: 49.07458, lng: 18.214831 },
    { lat: 49.074207, lng: 18.215324 },
    { lat: 49.074155, lng: 18.215393 },
    { lat: 49.073916, lng: 18.21555 },
    { lat: 49.073141, lng: 18.21586 },
    { lat: 49.072404, lng: 18.216103 },
    { lat: 49.071738, lng: 18.216203 },
    { lat: 49.071042, lng: 18.216467 },
    { lat: 49.070551, lng: 18.216504 },
    { lat: 49.069766, lng: 18.216585 },
    { lat: 49.069723, lng: 18.216589 },
    { lat: 49.069607, lng: 18.216601 },
    { lat: 49.069126, lng: 18.216611 },
    { lat: 49.068397, lng: 18.216467 },
    { lat: 49.06801, lng: 18.21646 },
    { lat: 49.06763, lng: 18.216584 },
    { lat: 49.067142, lng: 18.216827 },
    { lat: 49.066564, lng: 18.217218 },
    { lat: 49.066237, lng: 18.217305 },
    { lat: 49.065598, lng: 18.217142 },
    { lat: 49.065296, lng: 18.217119 },
    { lat: 49.0651, lng: 18.217201 },
    { lat: 49.064523, lng: 18.217568 },
    { lat: 49.064461, lng: 18.217607 },
    { lat: 49.063993, lng: 18.217995 },
    { lat: 49.063671, lng: 18.218174 },
    { lat: 49.063126, lng: 18.218345 },
    { lat: 49.062914, lng: 18.218412 },
    { lat: 49.062676, lng: 18.21835 },
    { lat: 49.062343, lng: 18.218378 },
    { lat: 49.062015, lng: 18.218528 },
    { lat: 49.061694, lng: 18.218786 },
    { lat: 49.061421, lng: 18.21979 },
    { lat: 49.061294, lng: 18.22 },
    { lat: 49.060934, lng: 18.220256 },
    { lat: 49.060473, lng: 18.220474 },
    { lat: 49.06027, lng: 18.220604 },
    { lat: 49.060022, lng: 18.220988 },
    { lat: 49.059984, lng: 18.221096 },
    { lat: 49.059958, lng: 18.221169 },
    { lat: 49.059944, lng: 18.221212 },
    { lat: 49.059441, lng: 18.222653 },
    { lat: 49.059137, lng: 18.223607 },
    { lat: 49.058949, lng: 18.22426 },
    { lat: 49.058819, lng: 18.225004 },
    { lat: 49.05872, lng: 18.225599 },
    { lat: 49.058615, lng: 18.226129 },
    { lat: 49.058049, lng: 18.227215 },
    { lat: 49.057859, lng: 18.227808 },
    { lat: 49.057642, lng: 18.228691 },
    { lat: 49.057464, lng: 18.229127 },
    { lat: 49.057098, lng: 18.229099 },
    { lat: 49.056716, lng: 18.229843 },
    { lat: 49.056525, lng: 18.230251 },
    { lat: 49.057187, lng: 18.231488 },
    { lat: 49.057818, lng: 18.232676 },
    { lat: 49.058356, lng: 18.232791 },
    { lat: 49.058814, lng: 18.234141 },
    { lat: 49.05927, lng: 18.234917 },
    { lat: 49.059765, lng: 18.236027 },
    { lat: 49.060075, lng: 18.237194 },
    { lat: 49.059486, lng: 18.237535 },
    { lat: 49.059178, lng: 18.237983 },
    { lat: 49.059213, lng: 18.238054 },
    { lat: 49.059251, lng: 18.2381 },
    { lat: 49.0592651, lng: 18.2381293 },
    { lat: 49.0592861, lng: 18.2382194 },
    { lat: 49.0593086, lng: 18.2382991 },
    { lat: 49.0596928, lng: 18.2384895 },
    { lat: 49.0623548, lng: 18.239808 },
    { lat: 49.0629375, lng: 18.2405715 },
    { lat: 49.063938, lng: 18.2422167 },
    { lat: 49.0643381, lng: 18.242507 },
    { lat: 49.064417, lng: 18.2424666 },
    { lat: 49.0647493, lng: 18.2429531 },
    { lat: 49.064852, lng: 18.2431134 },
    { lat: 49.0651115, lng: 18.2436309 },
    { lat: 49.0652037, lng: 18.2440009 },
    { lat: 49.0654347, lng: 18.248831 },
    { lat: 49.0655841, lng: 18.2493765 },
    { lat: 49.0656814, lng: 18.2494211 },
    { lat: 49.066296, lng: 18.2473861 },
    { lat: 49.0665286, lng: 18.2466139 },
    { lat: 49.0660652, lng: 18.2450076 },
    { lat: 49.0662857, lng: 18.2445988 },
    { lat: 49.0666877, lng: 18.2454796 },
    { lat: 49.0675944, lng: 18.2438209 },
    { lat: 49.0677971, lng: 18.2442117 },
    { lat: 49.0680871, lng: 18.2441117 },
    { lat: 49.0688027, lng: 18.2438392 },
    { lat: 49.0692791, lng: 18.2437237 },
    { lat: 49.07067, lng: 18.2438605 },
    { lat: 49.0720071, lng: 18.2445562 },
    { lat: 49.073279, lng: 18.2458363 },
    { lat: 49.0733136, lng: 18.2459094 },
    { lat: 49.073682, lng: 18.2462389 },
    { lat: 49.0740767, lng: 18.2459758 },
    { lat: 49.0741321, lng: 18.2459385 },
    { lat: 49.0744985, lng: 18.2456942 },
    { lat: 49.0750134, lng: 18.2452827 },
    { lat: 49.0763676, lng: 18.244891 },
    { lat: 49.0764174, lng: 18.2448535 },
    { lat: 49.0790092, lng: 18.2427292 },
    { lat: 49.0799643, lng: 18.241345 },
    { lat: 49.0801844, lng: 18.2419639 },
    { lat: 49.080524, lng: 18.2422621 },
    { lat: 49.0812169, lng: 18.2419279 },
    { lat: 49.0819891, lng: 18.2413772 },
    { lat: 49.0821515, lng: 18.2415934 },
    { lat: 49.0821901, lng: 18.2415204 },
    { lat: 49.0826302, lng: 18.2406853 },
    { lat: 49.0826999, lng: 18.2406203 },
    { lat: 49.0838637, lng: 18.2395368 },
    { lat: 49.0843879, lng: 18.2391306 },
    { lat: 49.0847768, lng: 18.2392241 },
    { lat: 49.0850972, lng: 18.239423 },
    { lat: 49.0858071, lng: 18.2395249 },
    { lat: 49.086396, lng: 18.2394262 },
    { lat: 49.086675, lng: 18.2395104 },
    { lat: 49.0873286, lng: 18.2396367 },
    { lat: 49.0882651, lng: 18.2396981 },
    { lat: 49.0882949, lng: 18.2395864 },
    { lat: 49.0890473, lng: 18.2392632 },
  ],
  Lednica: [
    { lat: 49.0890473, lng: 18.2392632 },
    { lat: 49.0894991, lng: 18.2385374 },
    { lat: 49.0895425, lng: 18.2385268 },
    { lat: 49.089835, lng: 18.2382526 },
    { lat: 49.0898688, lng: 18.2382339 },
    { lat: 49.0903458, lng: 18.2379688 },
    { lat: 49.0905644, lng: 18.2379444 },
    { lat: 49.0910705, lng: 18.2376578 },
    { lat: 49.0911796, lng: 18.2402785 },
    { lat: 49.0911877, lng: 18.2404583 },
    { lat: 49.0915376, lng: 18.2406596 },
    { lat: 49.0916053, lng: 18.240641 },
    { lat: 49.091897, lng: 18.2405119 },
    { lat: 49.0921584, lng: 18.2405643 },
    { lat: 49.092276, lng: 18.2407301 },
    { lat: 49.0924702, lng: 18.2407522 },
    { lat: 49.0928012, lng: 18.2409765 },
    { lat: 49.0930332, lng: 18.2412481 },
    { lat: 49.0931446, lng: 18.2415418 },
    { lat: 49.0932882, lng: 18.2416475 },
    { lat: 49.0936004, lng: 18.2417193 },
    { lat: 49.0937948, lng: 18.2420258 },
    { lat: 49.0939713, lng: 18.2420924 },
    { lat: 49.0941389, lng: 18.2424027 },
    { lat: 49.0943425, lng: 18.2424911 },
    { lat: 49.0944133, lng: 18.2426438 },
    { lat: 49.0944328, lng: 18.2432871 },
    { lat: 49.0946553, lng: 18.2438481 },
    { lat: 49.0948145, lng: 18.2448065 },
    { lat: 49.0947863, lng: 18.2450051 },
    { lat: 49.0948572, lng: 18.2453987 },
    { lat: 49.0948821, lng: 18.2455797 },
    { lat: 49.0950013, lng: 18.2456719 },
    { lat: 49.0950175, lng: 18.2456989 },
    { lat: 49.0949831, lng: 18.2463275 },
    { lat: 49.0949182, lng: 18.2465362 },
    { lat: 49.094828, lng: 18.2468539 },
    { lat: 49.0947934, lng: 18.2473796 },
    { lat: 49.0948556, lng: 18.2474013 },
    { lat: 49.0948677, lng: 18.2474152 },
    { lat: 49.0949004, lng: 18.2474174 },
    { lat: 49.0949235, lng: 18.2474255 },
    { lat: 49.0952528, lng: 18.2471644 },
    { lat: 49.0952937, lng: 18.2471072 },
    { lat: 49.0953491, lng: 18.2470294 },
    { lat: 49.0954002, lng: 18.246957 },
    { lat: 49.097909, lng: 18.2462127 },
    { lat: 49.0979445, lng: 18.2462021 },
    { lat: 49.0982352, lng: 18.2461158 },
    { lat: 49.0992333, lng: 18.2442767 },
    { lat: 49.1002738, lng: 18.2433461 },
    { lat: 49.1001352, lng: 18.2431105 },
    { lat: 49.0999653, lng: 18.2428209 },
    { lat: 49.1008789, lng: 18.2410035 },
    { lat: 49.1009317, lng: 18.2410412 },
    { lat: 49.101342, lng: 18.2404954 },
    { lat: 49.101433, lng: 18.2403506 },
    { lat: 49.1016194, lng: 18.2400563 },
    { lat: 49.101817, lng: 18.2398791 },
    { lat: 49.1019444, lng: 18.2398771 },
    { lat: 49.1019969, lng: 18.2397687 },
    { lat: 49.1020687, lng: 18.2393833 },
    { lat: 49.1022903, lng: 18.2381983 },
    { lat: 49.1023835, lng: 18.2378529 },
    { lat: 49.1024433, lng: 18.2374555 },
    { lat: 49.1025451, lng: 18.2367226 },
    { lat: 49.1025518, lng: 18.2366043 },
    { lat: 49.1034779, lng: 18.2349101 },
    { lat: 49.1035629, lng: 18.2347576 },
    { lat: 49.1038307, lng: 18.234229 },
    { lat: 49.104097, lng: 18.2347657 },
    { lat: 49.1042414, lng: 18.2352822 },
    { lat: 49.1042961, lng: 18.2355094 },
    { lat: 49.1043221, lng: 18.2354902 },
    { lat: 49.1047717, lng: 18.235162 },
    { lat: 49.1047879, lng: 18.2352891 },
    { lat: 49.1048448, lng: 18.2352577 },
    { lat: 49.1051345, lng: 18.2351344 },
    { lat: 49.1051286, lng: 18.2349306 },
    { lat: 49.1050985, lng: 18.234579 },
    { lat: 49.1051208, lng: 18.2342381 },
    { lat: 49.105078, lng: 18.2341177 },
    { lat: 49.1049482, lng: 18.2339785 },
    { lat: 49.1049632, lng: 18.2334288 },
    { lat: 49.1050633, lng: 18.2333033 },
    { lat: 49.1051341, lng: 18.2331578 },
    { lat: 49.1051936, lng: 18.2332137 },
    { lat: 49.1062789, lng: 18.2313431 },
    { lat: 49.1063398, lng: 18.2313029 },
    { lat: 49.1066549, lng: 18.2320241 },
    { lat: 49.107397, lng: 18.2316 },
    { lat: 49.1079246, lng: 18.231714 },
    { lat: 49.1090362, lng: 18.2314176 },
    { lat: 49.1093419, lng: 18.2314976 },
    { lat: 49.1097196, lng: 18.2316722 },
    { lat: 49.1101469, lng: 18.2322411 },
    { lat: 49.1105754, lng: 18.2327354 },
    { lat: 49.1108268, lng: 18.2312418 },
    { lat: 49.1109468, lng: 18.2305309 },
    { lat: 49.1116497, lng: 18.2306518 },
    { lat: 49.111822, lng: 18.2301105 },
    { lat: 49.1121657, lng: 18.2303396 },
    { lat: 49.112779, lng: 18.2307497 },
    { lat: 49.1128461, lng: 18.230678 },
    { lat: 49.1129997, lng: 18.2305013 },
    { lat: 49.1130727, lng: 18.2304175 },
    { lat: 49.1134497, lng: 18.2299828 },
    { lat: 49.1132643, lng: 18.2295401 },
    { lat: 49.1132098, lng: 18.2290373 },
    { lat: 49.113143, lng: 18.2283164 },
    { lat: 49.1132517, lng: 18.228284 },
    { lat: 49.1134492, lng: 18.2282263 },
    { lat: 49.1137456, lng: 18.2280199 },
    { lat: 49.113905, lng: 18.2279083 },
    { lat: 49.1141484, lng: 18.2276652 },
    { lat: 49.1143962, lng: 18.2274177 },
    { lat: 49.114399, lng: 18.227288 },
    { lat: 49.1145232, lng: 18.2271706 },
    { lat: 49.114808, lng: 18.2269011 },
    { lat: 49.1150896, lng: 18.2270538 },
    { lat: 49.1151199, lng: 18.2270699 },
    { lat: 49.115772, lng: 18.2276841 },
    { lat: 49.1158034, lng: 18.2277211 },
    { lat: 49.1160443, lng: 18.2280077 },
    { lat: 49.1164022, lng: 18.2275956 },
    { lat: 49.1164093, lng: 18.2275828 },
    { lat: 49.1165839, lng: 18.2272587 },
    { lat: 49.1166232, lng: 18.2270957 },
    { lat: 49.1167469, lng: 18.2270488 },
    { lat: 49.1168281, lng: 18.2271679 },
    { lat: 49.1171012, lng: 18.2272681 },
    { lat: 49.1173488, lng: 18.2272781 },
    { lat: 49.1180019, lng: 18.2269779 },
    { lat: 49.1183923, lng: 18.2267987 },
    { lat: 49.1185542, lng: 18.2266304 },
    { lat: 49.1189808, lng: 18.2263331 },
    { lat: 49.1192216, lng: 18.2258659 },
    { lat: 49.1193658, lng: 18.2261797 },
    { lat: 49.1193762, lng: 18.226201 },
    { lat: 49.1194973, lng: 18.2264666 },
    { lat: 49.119957, lng: 18.2265352 },
    { lat: 49.1207093, lng: 18.2268083 },
    { lat: 49.1212, lng: 18.2271463 },
    { lat: 49.1220311, lng: 18.2269785 },
    { lat: 49.1227615, lng: 18.2271432 },
    { lat: 49.12336, lng: 18.226936 },
    { lat: 49.1237283, lng: 18.2270034 },
    { lat: 49.1237623, lng: 18.2270054 },
    { lat: 49.123887, lng: 18.2270171 },
    { lat: 49.1241053, lng: 18.2273424 },
    { lat: 49.1248301, lng: 18.2284234 },
    { lat: 49.1250162, lng: 18.2287007 },
    { lat: 49.1250684, lng: 18.2288632 },
    { lat: 49.1252103, lng: 18.2288203 },
    { lat: 49.1253645, lng: 18.2287739 },
    { lat: 49.1256374, lng: 18.2286914 },
    { lat: 49.1257211, lng: 18.2286168 },
    { lat: 49.1258113, lng: 18.2285357 },
    { lat: 49.1259965, lng: 18.2278618 },
    { lat: 49.1263148, lng: 18.2271699 },
    { lat: 49.1263249, lng: 18.2266537 },
    { lat: 49.1267265, lng: 18.2263936 },
    { lat: 49.1267364, lng: 18.2266283 },
    { lat: 49.1268188, lng: 18.2272041 },
    { lat: 49.1268731, lng: 18.2279414 },
    { lat: 49.1269015, lng: 18.2285786 },
    { lat: 49.1269551, lng: 18.2290672 },
    { lat: 49.1270183, lng: 18.2293664 },
    { lat: 49.1271148, lng: 18.2295351 },
    { lat: 49.1272181, lng: 18.2296061 },
    { lat: 49.1277497, lng: 18.229986 },
    { lat: 49.1279012, lng: 18.2302113 },
    { lat: 49.1281195, lng: 18.2303382 },
    { lat: 49.1281973, lng: 18.2309357 },
    { lat: 49.1283934, lng: 18.2311089 },
    { lat: 49.1284597, lng: 18.2311659 },
    { lat: 49.1286144, lng: 18.2312457 },
    { lat: 49.1287335, lng: 18.2312772 },
    { lat: 49.1288729, lng: 18.2312687 },
    { lat: 49.1294939, lng: 18.2301239 },
    { lat: 49.1295548, lng: 18.2300423 },
    { lat: 49.1297727, lng: 18.2296674 },
    { lat: 49.1300657, lng: 18.2298401 },
    { lat: 49.1305853, lng: 18.2290366 },
    { lat: 49.1302118, lng: 18.2285787 },
    { lat: 49.1303088, lng: 18.2283213 },
    { lat: 49.1310197, lng: 18.2279438 },
    { lat: 49.1310456, lng: 18.2279233 },
    { lat: 49.131131, lng: 18.2274202 },
    { lat: 49.1312569, lng: 18.2266978 },
    { lat: 49.1311219, lng: 18.2257905 },
    { lat: 49.1306854, lng: 18.2258134 },
    { lat: 49.1305902, lng: 18.2252079 },
    { lat: 49.1304838, lng: 18.2249657 },
    { lat: 49.1304495, lng: 18.223924 },
    { lat: 49.1304204, lng: 18.22381 },
    { lat: 49.1303983, lng: 18.2235796 },
    { lat: 49.1302781, lng: 18.2233714 },
    { lat: 49.1301776, lng: 18.2226136 },
    { lat: 49.1302133, lng: 18.2225286 },
    { lat: 49.1303234, lng: 18.2222238 },
    { lat: 49.1308778, lng: 18.2221699 },
    { lat: 49.1310956, lng: 18.2223703 },
    { lat: 49.1313953, lng: 18.2224008 },
    { lat: 49.1319517, lng: 18.2223841 },
    { lat: 49.1319217, lng: 18.2214189 },
    { lat: 49.1318785, lng: 18.2209782 },
    { lat: 49.1318676, lng: 18.2204889 },
    { lat: 49.1315707, lng: 18.2204469 },
    { lat: 49.1316461, lng: 18.2197475 },
    { lat: 49.1315435, lng: 18.2197102 },
    { lat: 49.1313636, lng: 18.2184708 },
    { lat: 49.131323, lng: 18.2176184 },
    { lat: 49.1312283, lng: 18.2176185 },
    { lat: 49.1311221, lng: 18.2166448 },
    { lat: 49.1306168, lng: 18.2165489 },
    { lat: 49.1301804, lng: 18.216336 },
    { lat: 49.1301439, lng: 18.2163098 },
    { lat: 49.1298569, lng: 18.2166386 },
    { lat: 49.1300583, lng: 18.2160747 },
    { lat: 49.130222, lng: 18.2155612 },
    { lat: 49.1303947, lng: 18.2148474 },
    { lat: 49.1301781, lng: 18.2146289 },
    { lat: 49.129848, lng: 18.2138244 },
    { lat: 49.1297542, lng: 18.2133855 },
    { lat: 49.1296774, lng: 18.2129696 },
    { lat: 49.1298499, lng: 18.2120554 },
    { lat: 49.1295962, lng: 18.2119182 },
    { lat: 49.1296915, lng: 18.2103816 },
    { lat: 49.1295606, lng: 18.2102638 },
    { lat: 49.1295059, lng: 18.2093022 },
    { lat: 49.1290879, lng: 18.2092944 },
    { lat: 49.1287534, lng: 18.209026 },
    { lat: 49.1288251, lng: 18.2086229 },
    { lat: 49.1289007, lng: 18.2083054 },
    { lat: 49.1289009, lng: 18.2080715 },
    { lat: 49.1292409, lng: 18.208059 },
    { lat: 49.1288914, lng: 18.2072906 },
    { lat: 49.1285271, lng: 18.2064611 },
    { lat: 49.1285001, lng: 18.2064024 },
    { lat: 49.1284692, lng: 18.2063364 },
    { lat: 49.1281072, lng: 18.2055476 },
    { lat: 49.1283756, lng: 18.2053085 },
    { lat: 49.1284262, lng: 18.2051252 },
    { lat: 49.1284472, lng: 18.2045831 },
    { lat: 49.128337, lng: 18.2041149 },
    { lat: 49.1284744, lng: 18.2038327 },
    { lat: 49.1285214, lng: 18.2034117 },
    { lat: 49.128627, lng: 18.2031856 },
    { lat: 49.1287964, lng: 18.202615 },
    { lat: 49.1287159, lng: 18.2020153 },
    { lat: 49.1289873, lng: 18.2017882 },
    { lat: 49.129549, lng: 18.2006175 },
    { lat: 49.1297831, lng: 18.2003885 },
    { lat: 49.1302011, lng: 18.2000807 },
    { lat: 49.1304689, lng: 18.1999091 },
    { lat: 49.1309543, lng: 18.199598 },
    { lat: 49.1313928, lng: 18.199234 },
    { lat: 49.1318308, lng: 18.1990603 },
    { lat: 49.1322789, lng: 18.1985886 },
    { lat: 49.1328724, lng: 18.1981149 },
    { lat: 49.1327946, lng: 18.1976584 },
    { lat: 49.1327654, lng: 18.1971877 },
    { lat: 49.1327625, lng: 18.1966809 },
    { lat: 49.132752, lng: 18.1964197 },
    { lat: 49.132534, lng: 18.1961183 },
    { lat: 49.1325222, lng: 18.1959909 },
    { lat: 49.1327939, lng: 18.1956311 },
    { lat: 49.1328781, lng: 18.1952869 },
    { lat: 49.1328421, lng: 18.1951099 },
    { lat: 49.1330602, lng: 18.1944659 },
    { lat: 49.1332021, lng: 18.1941231 },
    { lat: 49.1331356, lng: 18.1937886 },
    { lat: 49.1332399, lng: 18.1933042 },
    { lat: 49.1333144, lng: 18.1929304 },
    { lat: 49.1331773, lng: 18.1922859 },
    { lat: 49.1333568, lng: 18.1923475 },
    { lat: 49.1334325, lng: 18.1922297 },
    { lat: 49.1337769, lng: 18.1926103 },
    { lat: 49.1341753, lng: 18.1927506 },
    { lat: 49.1346445, lng: 18.192332 },
    { lat: 49.134972, lng: 18.1921389 },
    { lat: 49.1351593, lng: 18.1917247 },
    { lat: 49.1352335, lng: 18.1915828 },
    { lat: 49.1352913, lng: 18.191469 },
    { lat: 49.1356166, lng: 18.1914133 },
    { lat: 49.1359811, lng: 18.1916517 },
    { lat: 49.1361561, lng: 18.1913965 },
    { lat: 49.1368124, lng: 18.1908572 },
    { lat: 49.1373271, lng: 18.1904048 },
    { lat: 49.1384936, lng: 18.1895927 },
    { lat: 49.1389901, lng: 18.1906421 },
    { lat: 49.1395737, lng: 18.1902509 },
    { lat: 49.1397349, lng: 18.1905239 },
    { lat: 49.1401703, lng: 18.1900665 },
    { lat: 49.1402926, lng: 18.1897187 },
    { lat: 49.1403235, lng: 18.1893517 },
    { lat: 49.1404193, lng: 18.1890729 },
    { lat: 49.1407029, lng: 18.1890794 },
    { lat: 49.1410381, lng: 18.1891012 },
    { lat: 49.1413688, lng: 18.1889838 },
    { lat: 49.1414142, lng: 18.1889482 },
    { lat: 49.1417465, lng: 18.1888605 },
    { lat: 49.1419542, lng: 18.1892582 },
    { lat: 49.1425055, lng: 18.1891704 },
    { lat: 49.1422729, lng: 18.1883969 },
    { lat: 49.1423855, lng: 18.1883157 },
    { lat: 49.1420819, lng: 18.1877183 },
    { lat: 49.141826, lng: 18.187803 },
    { lat: 49.1418323, lng: 18.1865511 },
    { lat: 49.1409472, lng: 18.1866212 },
    { lat: 49.1408933, lng: 18.1866255 },
    { lat: 49.1408603, lng: 18.1862273 },
    { lat: 49.1406857, lng: 18.185124 },
    { lat: 49.1403863, lng: 18.184368 },
    { lat: 49.1401541, lng: 18.1836026 },
    { lat: 49.1398867, lng: 18.1824782 },
    { lat: 49.1399065, lng: 18.1824093 },
    { lat: 49.1399335, lng: 18.1824277 },
    { lat: 49.1405035, lng: 18.1823347 },
    { lat: 49.1413606, lng: 18.182405 },
    { lat: 49.1415145, lng: 18.1816231 },
    { lat: 49.1413833, lng: 18.181499 },
    { lat: 49.141405, lng: 18.1813453 },
    { lat: 49.1414171, lng: 18.1812607 },
    { lat: 49.1414683, lng: 18.1809524 },
    { lat: 49.1411227, lng: 18.1806927 },
    { lat: 49.1411284, lng: 18.1804752 },
    { lat: 49.141132, lng: 18.1803788 },
    { lat: 49.141149, lng: 18.1800063 },
    { lat: 49.1408729, lng: 18.1797765 },
    { lat: 49.1406481, lng: 18.1795939 },
    { lat: 49.1408247, lng: 18.1791308 },
    { lat: 49.1409774, lng: 18.1789198 },
    { lat: 49.141103, lng: 18.1787471 },
    { lat: 49.1412609, lng: 18.1786299 },
    { lat: 49.1416555, lng: 18.1780107 },
    { lat: 49.1420757, lng: 18.1773692 },
    { lat: 49.1420072, lng: 18.176901 },
    { lat: 49.142002, lng: 18.1757855 },
    { lat: 49.1420073, lng: 18.1756018 },
    { lat: 49.1420087, lng: 18.1752124 },
    { lat: 49.1424603, lng: 18.1736999 },
    { lat: 49.142558, lng: 18.1731413 },
    { lat: 49.142586, lng: 18.172052 },
    { lat: 49.1424297, lng: 18.1713329 },
    { lat: 49.1423566, lng: 18.170903 },
    { lat: 49.1422259, lng: 18.1703942 },
    { lat: 49.142233, lng: 18.1699672 },
    { lat: 49.1423538, lng: 18.1693972 },
    { lat: 49.1425029, lng: 18.1689878 },
    { lat: 49.1425093, lng: 18.1677187 },
    { lat: 49.1425655, lng: 18.1672252 },
    { lat: 49.1426283, lng: 18.1665871 },
    { lat: 49.142645, lng: 18.1654597 },
    { lat: 49.1426082, lng: 18.1648587 },
    { lat: 49.1425445, lng: 18.1644163 },
    { lat: 49.1421295, lng: 18.1633964 },
    { lat: 49.1416935, lng: 18.1627987 },
    { lat: 49.1413783, lng: 18.162183 },
    { lat: 49.141282, lng: 18.1621167 },
    { lat: 49.1411467, lng: 18.16202 },
    { lat: 49.1406582, lng: 18.1617659 },
    { lat: 49.1405296, lng: 18.1616103 },
    { lat: 49.1402246, lng: 18.1608361 },
    { lat: 49.1400782, lng: 18.1598977 },
    { lat: 49.1401582, lng: 18.1592843 },
    { lat: 49.1402418, lng: 18.158932 },
    { lat: 49.140221, lng: 18.158729 },
    { lat: 49.1399074, lng: 18.1582978 },
    { lat: 49.1396061, lng: 18.1573345 },
    { lat: 49.1394189, lng: 18.1562536 },
    { lat: 49.1389947, lng: 18.1549968 },
    { lat: 49.1388898, lng: 18.1546843 },
    { lat: 49.1388441, lng: 18.1542992 },
    { lat: 49.1390115, lng: 18.1537179 },
    { lat: 49.1391086, lng: 18.1529732 },
    { lat: 49.1390816, lng: 18.1525611 },
    { lat: 49.1390446, lng: 18.1522487 },
    { lat: 49.1390299, lng: 18.1518977 },
    { lat: 49.1389395, lng: 18.1505198 },
    { lat: 49.139001, lng: 18.149279 },
    { lat: 49.138707, lng: 18.150314 },
    { lat: 49.138605, lng: 18.150921 },
    { lat: 49.137613, lng: 18.152635 },
    { lat: 49.1370298, lng: 18.1527424 },
    { lat: 49.136516, lng: 18.153048 },
    { lat: 49.136125, lng: 18.152883 },
    { lat: 49.135369, lng: 18.152509 },
    { lat: 49.134506, lng: 18.152237 },
    { lat: 49.133348, lng: 18.152103 },
    { lat: 49.131555, lng: 18.151773 },
    { lat: 49.131155, lng: 18.151813 },
    { lat: 49.130814, lng: 18.151951 },
    { lat: 49.130404, lng: 18.151905 },
    { lat: 49.130085, lng: 18.151794 },
    { lat: 49.1294084, lng: 18.1522018 },
    { lat: 49.128668, lng: 18.152444 },
    { lat: 49.128061, lng: 18.152989 },
    { lat: 49.126815, lng: 18.153236 },
    { lat: 49.125562, lng: 18.153288 },
    { lat: 49.123269, lng: 18.153451 },
    { lat: 49.122115, lng: 18.153402 },
    { lat: 49.121751, lng: 18.153136 },
    { lat: 49.121146, lng: 18.155058 },
    { lat: 49.121145, lng: 18.155099 },
    { lat: 49.121145, lng: 18.155106 },
    { lat: 49.119808, lng: 18.155094 },
    { lat: 49.119291, lng: 18.156106 },
    { lat: 49.118972, lng: 18.156923 },
    { lat: 49.118565, lng: 18.157698 },
    { lat: 49.118046, lng: 18.158459 },
    { lat: 49.117922, lng: 18.158984 },
    { lat: 49.117939, lng: 18.159717 },
    { lat: 49.118039, lng: 18.161255 },
    { lat: 49.11818, lng: 18.162008 },
    { lat: 49.118281, lng: 18.162473 },
    { lat: 49.118461, lng: 18.163189 },
    { lat: 49.118523, lng: 18.163442 },
    { lat: 49.118881, lng: 18.165105 },
    { lat: 49.119015, lng: 18.165999 },
    { lat: 49.119147, lng: 18.16722 },
    { lat: 49.119146, lng: 18.168167 },
    { lat: 49.119105, lng: 18.168224 },
    { lat: 49.118105, lng: 18.169347 },
    { lat: 49.117263, lng: 18.170275 },
    { lat: 49.116987, lng: 18.170521 },
    { lat: 49.116596, lng: 18.171127 },
    { lat: 49.116016, lng: 18.171818 },
    { lat: 49.115985, lng: 18.171875 },
    { lat: 49.114939, lng: 18.173651 },
    { lat: 49.11355, lng: 18.175701 },
    { lat: 49.112641, lng: 18.176612 },
    { lat: 49.112154, lng: 18.177551 },
    { lat: 49.112104, lng: 18.17765 },
    { lat: 49.112072, lng: 18.17768 },
    { lat: 49.111643, lng: 18.177886 },
    { lat: 49.111511, lng: 18.178024 },
    { lat: 49.111463, lng: 18.178066 },
    { lat: 49.111338, lng: 18.178172 },
    { lat: 49.110869, lng: 18.178676 },
    { lat: 49.110407, lng: 18.1792 },
    { lat: 49.1101, lng: 18.179619 },
    { lat: 49.109987, lng: 18.17964 },
    { lat: 49.10995, lng: 18.179639 },
    { lat: 49.109302, lng: 18.179712 },
    { lat: 49.108702, lng: 18.179894 },
    { lat: 49.107728, lng: 18.179963 },
    { lat: 49.107492, lng: 18.179977 },
    { lat: 49.10671, lng: 18.179941 },
    { lat: 49.105844, lng: 18.180064 },
    { lat: 49.105322, lng: 18.180248 },
    { lat: 49.10493, lng: 18.180675 },
    { lat: 49.104808, lng: 18.181074 },
    { lat: 49.10472, lng: 18.182128 },
    { lat: 49.104562, lng: 18.182767 },
    { lat: 49.104296, lng: 18.183263 },
    { lat: 49.10413, lng: 18.183539 },
    { lat: 49.103657, lng: 18.184171 },
    { lat: 49.103391, lng: 18.184808 },
    { lat: 49.103299, lng: 18.185468 },
    { lat: 49.103236, lng: 18.186234 },
    { lat: 49.10331, lng: 18.186718 },
    { lat: 49.103228, lng: 18.187318 },
    { lat: 49.103074, lng: 18.187706 },
    { lat: 49.102783, lng: 18.188341 },
    { lat: 49.102499, lng: 18.188574 },
    { lat: 49.102244, lng: 18.188868 },
    { lat: 49.10177, lng: 18.188947 },
    { lat: 49.101591, lng: 18.188874 },
    { lat: 49.100691, lng: 18.189136 },
    { lat: 49.100597, lng: 18.189212 },
    { lat: 49.100306, lng: 18.189317 },
    { lat: 49.100046, lng: 18.189823 },
    { lat: 49.099924, lng: 18.19011 },
    { lat: 49.099637, lng: 18.19052 },
    { lat: 49.099211, lng: 18.191772 },
    { lat: 49.099029, lng: 18.192363 },
    { lat: 49.098787, lng: 18.192842 },
    { lat: 49.098364, lng: 18.193328 },
    { lat: 49.097541, lng: 18.193824 },
    { lat: 49.096912, lng: 18.193902 },
    { lat: 49.096078, lng: 18.194278 },
    { lat: 49.095577, lng: 18.194625 },
    { lat: 49.095531, lng: 18.194747 },
    { lat: 49.095452, lng: 18.194925 },
    { lat: 49.095301, lng: 18.19545 },
    { lat: 49.095077, lng: 18.195922 },
    { lat: 49.094936, lng: 18.196496 },
    { lat: 49.094644, lng: 18.196888 },
    { lat: 49.094566, lng: 18.196933 },
    { lat: 49.094135, lng: 18.197046 },
    { lat: 49.093475, lng: 18.197552 },
    { lat: 49.093357, lng: 18.197761 },
    { lat: 49.093328, lng: 18.197824 },
    { lat: 49.0932156, lng: 18.1983181 },
    { lat: 49.0931496, lng: 18.1986044 },
    { lat: 49.093018, lng: 18.1988094 },
    { lat: 49.0929196, lng: 18.1989629 },
    { lat: 49.0927572, lng: 18.199222 },
    { lat: 49.0925532, lng: 18.1996798 },
    { lat: 49.0924628, lng: 18.200132 },
    { lat: 49.092265, lng: 18.2006009 },
    { lat: 49.0921617, lng: 18.2010842 },
    { lat: 49.0920217, lng: 18.201695 },
    { lat: 49.091942, lng: 18.2023949 },
    { lat: 49.0916951, lng: 18.2034257 },
    { lat: 49.0913368, lng: 18.204465 },
    { lat: 49.0911208, lng: 18.2049491 },
    { lat: 49.0910621, lng: 18.2054037 },
    { lat: 49.0910885, lng: 18.2056887 },
    { lat: 49.091057, lng: 18.2061066 },
    { lat: 49.0908897, lng: 18.2069304 },
    { lat: 49.0909893, lng: 18.2076286 },
    { lat: 49.0906603, lng: 18.2081115 },
    { lat: 49.0903504, lng: 18.2085739 },
    { lat: 49.090225, lng: 18.2090462 },
    { lat: 49.0901045, lng: 18.2097037 },
    { lat: 49.0901013, lng: 18.2104788 },
    { lat: 49.0899335, lng: 18.2106437 },
    { lat: 49.0899051, lng: 18.2109961 },
    { lat: 49.08959, lng: 18.2114269 },
    { lat: 49.0892842, lng: 18.2118435 },
    { lat: 49.0891564, lng: 18.2122712 },
    { lat: 49.0890064, lng: 18.2127896 },
    { lat: 49.0888416, lng: 18.2136793 },
    { lat: 49.0888274, lng: 18.215034 },
    { lat: 49.0888737, lng: 18.2161432 },
    { lat: 49.088898, lng: 18.2170277 },
    { lat: 49.0889388, lng: 18.2176843 },
    { lat: 49.0887411, lng: 18.2185908 },
    { lat: 49.088595, lng: 18.2188381 },
    { lat: 49.0885337, lng: 18.2190514 },
    { lat: 49.0884264, lng: 18.2194259 },
    { lat: 49.088656, lng: 18.2197527 },
    { lat: 49.0882779, lng: 18.221078 },
    { lat: 49.0882725, lng: 18.2211417 },
    { lat: 49.0882641, lng: 18.2212336 },
    { lat: 49.0882611, lng: 18.2212775 },
    { lat: 49.0882626, lng: 18.2213241 },
    { lat: 49.0882638, lng: 18.2213857 },
    { lat: 49.0882658, lng: 18.2214387 },
    { lat: 49.0882658, lng: 18.2214594 },
    { lat: 49.0882665, lng: 18.2214928 },
    { lat: 49.0882677, lng: 18.2215337 },
    { lat: 49.0882691, lng: 18.2215781 },
    { lat: 49.0882706, lng: 18.2216236 },
    { lat: 49.0882713, lng: 18.2216768 },
    { lat: 49.0882735, lng: 18.221734 },
    { lat: 49.0882748, lng: 18.2218175 },
    { lat: 49.0882779, lng: 18.2218905 },
    { lat: 49.0882798, lng: 18.2219853 },
    { lat: 49.0882887, lng: 18.2222993 },
    { lat: 49.0882888, lng: 18.2223014 },
    { lat: 49.0882969, lng: 18.2226217 },
    { lat: 49.0882946, lng: 18.2226803 },
    { lat: 49.0882821, lng: 18.2230372 },
    { lat: 49.0882779, lng: 18.2231609 },
    { lat: 49.0882732, lng: 18.2232771 },
    { lat: 49.0882665, lng: 18.2234756 },
    { lat: 49.0882786, lng: 18.2235482 },
    { lat: 49.0882957, lng: 18.2236521 },
    { lat: 49.0881154, lng: 18.2240328 },
    { lat: 49.0876531, lng: 18.2245344 },
    { lat: 49.0874841, lng: 18.2247181 },
    { lat: 49.0870463, lng: 18.2251937 },
    { lat: 49.0873141, lng: 18.2257744 },
    { lat: 49.087372, lng: 18.2258997 },
    { lat: 49.0878869, lng: 18.226047 },
    { lat: 49.0886145, lng: 18.2261627 },
    { lat: 49.088963, lng: 18.2262183 },
    { lat: 49.0893213, lng: 18.2263097 },
    { lat: 49.0896684, lng: 18.2263831 },
    { lat: 49.0897942, lng: 18.2264313 },
    { lat: 49.0899234, lng: 18.2264809 },
    { lat: 49.0899648, lng: 18.2264968 },
    { lat: 49.0899706, lng: 18.2264988 },
    { lat: 49.090485, lng: 18.2271313 },
    { lat: 49.0906696, lng: 18.2273811 },
    { lat: 49.0908744, lng: 18.2276702 },
    { lat: 49.0912044, lng: 18.2281149 },
    { lat: 49.0915413, lng: 18.2285846 },
    { lat: 49.0917689, lng: 18.2289164 },
    { lat: 49.091548, lng: 18.2295957 },
    { lat: 49.0915212, lng: 18.2296795 },
    { lat: 49.0914914, lng: 18.2297707 },
    { lat: 49.0910921, lng: 18.2310058 },
    { lat: 49.0904527, lng: 18.2311098 },
    { lat: 49.0904057, lng: 18.2311171 },
    { lat: 49.0893493, lng: 18.231289 },
    { lat: 49.08847, lng: 18.2323548 },
    { lat: 49.0880995, lng: 18.2327853 },
    { lat: 49.0873057, lng: 18.2337079 },
    { lat: 49.0868347, lng: 18.2341754 },
    { lat: 49.0867663, lng: 18.2342424 },
    { lat: 49.0858221, lng: 18.2351796 },
    { lat: 49.0861275, lng: 18.2363655 },
    { lat: 49.0863571, lng: 18.236931 },
    { lat: 49.0865731, lng: 18.2378361 },
    { lat: 49.0869046, lng: 18.2381692 },
    { lat: 49.0869441, lng: 18.238209 },
    { lat: 49.0870231, lng: 18.2382885 },
    { lat: 49.0890473, lng: 18.2392632 },
  ],
  LysápodMakytou: [
    { lat: 49.22062, lng: 18.1530972 },
    { lat: 49.2202769, lng: 18.1530123 },
    { lat: 49.2197, lng: 18.152731 },
    { lat: 49.219603, lng: 18.152667 },
    { lat: 49.219483, lng: 18.152702 },
    { lat: 49.219047, lng: 18.152851 },
    { lat: 49.218557, lng: 18.153198 },
    { lat: 49.218247, lng: 18.153425 },
    { lat: 49.21753, lng: 18.153928 },
    { lat: 49.217023, lng: 18.154369 },
    { lat: 49.216655, lng: 18.154607 },
    { lat: 49.216151, lng: 18.154911 },
    { lat: 49.21612, lng: 18.15473 },
    { lat: 49.216058, lng: 18.154574 },
    { lat: 49.216008, lng: 18.154419 },
    { lat: 49.215824, lng: 18.153969 },
    { lat: 49.215828, lng: 18.153844 },
    { lat: 49.215859, lng: 18.153718 },
    { lat: 49.215854, lng: 18.153568 },
    { lat: 49.215822, lng: 18.153443 },
    { lat: 49.215723, lng: 18.153392 },
    { lat: 49.215681, lng: 18.153246 },
    { lat: 49.215676, lng: 18.153092 },
    { lat: 49.215649, lng: 18.152986 },
    { lat: 49.215648, lng: 18.152725 },
    { lat: 49.215605, lng: 18.152664 },
    { lat: 49.215629, lng: 18.152511 },
    { lat: 49.215771, lng: 18.152446 },
    { lat: 49.215887, lng: 18.152484 },
    { lat: 49.215896, lng: 18.152357 },
    { lat: 49.215816, lng: 18.152199 },
    { lat: 49.21578, lng: 18.152174 },
    { lat: 49.215785, lng: 18.151942 },
    { lat: 49.21565, lng: 18.151803 },
    { lat: 49.215638, lng: 18.151691 },
    { lat: 49.215694, lng: 18.151509 },
    { lat: 49.215619, lng: 18.151099 },
    { lat: 49.215626, lng: 18.151037 },
    { lat: 49.215513, lng: 18.150892 },
    { lat: 49.21543, lng: 18.150723 },
    { lat: 49.215419, lng: 18.150603 },
    { lat: 49.21532, lng: 18.150372 },
    { lat: 49.215263, lng: 18.150322 },
    { lat: 49.215238, lng: 18.150249 },
    { lat: 49.215226, lng: 18.150199 },
    { lat: 49.21516, lng: 18.150177 },
    { lat: 49.215098, lng: 18.150034 },
    { lat: 49.215009, lng: 18.149929 },
    { lat: 49.214977, lng: 18.149789 },
    { lat: 49.214887, lng: 18.149559 },
    { lat: 49.214907, lng: 18.149449 },
    { lat: 49.214886, lng: 18.149345 },
    { lat: 49.214921, lng: 18.149246 },
    { lat: 49.214938, lng: 18.149122 },
    { lat: 49.214999, lng: 18.148827 },
    { lat: 49.215007, lng: 18.14872 },
    { lat: 49.214965, lng: 18.148493 },
    { lat: 49.215012, lng: 18.14829 },
    { lat: 49.214994, lng: 18.148168 },
    { lat: 49.21486, lng: 18.148077 },
    { lat: 49.214819, lng: 18.148117 },
    { lat: 49.214592, lng: 18.148168 },
    { lat: 49.214025, lng: 18.14837 },
    { lat: 49.213726, lng: 18.148555 },
    { lat: 49.213539, lng: 18.148592 },
    { lat: 49.213281, lng: 18.148635 },
    { lat: 49.212953, lng: 18.14861 },
    { lat: 49.212324, lng: 18.148652 },
    { lat: 49.212131, lng: 18.148669 },
    { lat: 49.21193, lng: 18.148649 },
    { lat: 49.2117, lng: 18.148616 },
    { lat: 49.211316, lng: 18.148562 },
    { lat: 49.210975, lng: 18.148521 },
    { lat: 49.210545, lng: 18.148496 },
    { lat: 49.210118, lng: 18.148616 },
    { lat: 49.209719, lng: 18.148704 },
    { lat: 49.209378, lng: 18.148735 },
    { lat: 49.20898, lng: 18.148816 },
    { lat: 49.208498, lng: 18.148836 },
    { lat: 49.207966, lng: 18.148856 },
    { lat: 49.207395, lng: 18.148897 },
    { lat: 49.206667, lng: 18.149009 },
    { lat: 49.205652, lng: 18.149297 },
    { lat: 49.205089, lng: 18.149381 },
    { lat: 49.205066, lng: 18.149367 },
    { lat: 49.205086, lng: 18.149243 },
    { lat: 49.205163, lng: 18.148948 },
    { lat: 49.205165, lng: 18.148881 },
    { lat: 49.205083, lng: 18.148786 },
    { lat: 49.205097, lng: 18.148709 },
    { lat: 49.205025, lng: 18.148633 },
    { lat: 49.204981, lng: 18.148642 },
    { lat: 49.204922, lng: 18.148565 },
    { lat: 49.204833, lng: 18.148269 },
    { lat: 49.204866, lng: 18.148182 },
    { lat: 49.204923, lng: 18.14818 },
    { lat: 49.204932, lng: 18.148092 },
    { lat: 49.204873, lng: 18.147929 },
    { lat: 49.204832, lng: 18.147862 },
    { lat: 49.204811, lng: 18.147715 },
    { lat: 49.204842, lng: 18.147525 },
    { lat: 49.204831, lng: 18.147344 },
    { lat: 49.20484, lng: 18.147304 },
    { lat: 49.204863, lng: 18.147231 },
    { lat: 49.20489, lng: 18.147141 },
    { lat: 49.204805, lng: 18.147077 },
    { lat: 49.204627, lng: 18.146944 },
    { lat: 49.204326, lng: 18.146715 },
    { lat: 49.203905, lng: 18.146513 },
    { lat: 49.20332, lng: 18.146142 },
    { lat: 49.20259, lng: 18.145674 },
    { lat: 49.202157, lng: 18.145424 },
    { lat: 49.20186, lng: 18.145201 },
    { lat: 49.201453, lng: 18.144951 },
    { lat: 49.201149, lng: 18.14473 },
    { lat: 49.200288, lng: 18.144164 },
    { lat: 49.200083, lng: 18.144059 },
    { lat: 49.199653, lng: 18.143867 },
    { lat: 49.199468, lng: 18.143839 },
    { lat: 49.199135, lng: 18.14379 },
    { lat: 49.198625, lng: 18.143296 },
    { lat: 49.19799, lng: 18.142939 },
    { lat: 49.197915, lng: 18.142894 },
    { lat: 49.197639, lng: 18.142727 },
    { lat: 49.197381, lng: 18.142604 },
    { lat: 49.197192, lng: 18.142498 },
    { lat: 49.196999, lng: 18.142391 },
    { lat: 49.196745, lng: 18.142051 },
    { lat: 49.196451, lng: 18.141664 },
    { lat: 49.196084, lng: 18.141357 },
    { lat: 49.195758, lng: 18.140962 },
    { lat: 49.19568, lng: 18.140833 },
    { lat: 49.195352, lng: 18.140242 },
    { lat: 49.19521, lng: 18.140033 },
    { lat: 49.195069, lng: 18.139825 },
    { lat: 49.194928, lng: 18.139566 },
    { lat: 49.194663, lng: 18.139038 },
    { lat: 49.194507, lng: 18.138781 },
    { lat: 49.19431, lng: 18.138513 },
    { lat: 49.193885, lng: 18.137855 },
    { lat: 49.193506, lng: 18.137193 },
    { lat: 49.193239, lng: 18.13674 },
    { lat: 49.192778, lng: 18.136277 },
    { lat: 49.192535, lng: 18.135903 },
    { lat: 49.192329, lng: 18.135611 },
    { lat: 49.192166, lng: 18.135384 },
    { lat: 49.191972, lng: 18.135111 },
    { lat: 49.191799, lng: 18.134754 },
    { lat: 49.191672, lng: 18.134558 },
    { lat: 49.19153, lng: 18.1342 },
    { lat: 49.191423, lng: 18.133963 },
    { lat: 49.191036, lng: 18.133612 },
    { lat: 49.190708, lng: 18.133244 },
    { lat: 49.190544, lng: 18.133012 },
    { lat: 49.190225, lng: 18.132625 },
    { lat: 49.189716, lng: 18.131737 },
    { lat: 49.189457, lng: 18.131277 },
    { lat: 49.188926, lng: 18.130889 },
    { lat: 49.1889256, lng: 18.1308891 },
    { lat: 49.188791, lng: 18.130844 },
    { lat: 49.188528, lng: 18.130814 },
    { lat: 49.188353, lng: 18.130816 },
    { lat: 49.188096, lng: 18.13082 },
    { lat: 49.187534, lng: 18.130807 },
    { lat: 49.187153, lng: 18.13083 },
    { lat: 49.186458, lng: 18.130892 },
    { lat: 49.186216, lng: 18.130913 },
    { lat: 49.185884, lng: 18.130941 },
    { lat: 49.185418, lng: 18.130966 },
    { lat: 49.185145, lng: 18.130977 },
    { lat: 49.184482, lng: 18.131013 },
    { lat: 49.183926, lng: 18.131053 },
    { lat: 49.183687, lng: 18.131055 },
    { lat: 49.183238, lng: 18.13113 },
    { lat: 49.183302, lng: 18.131307 },
    { lat: 49.182892, lng: 18.131457 },
    { lat: 49.182877, lng: 18.13133 },
    { lat: 49.182825, lng: 18.131178 },
    { lat: 49.182788, lng: 18.131113 },
    { lat: 49.182766, lng: 18.131064 },
    { lat: 49.182759, lng: 18.131021 },
    { lat: 49.182688, lng: 18.130941 },
    { lat: 49.182626, lng: 18.13091 },
    { lat: 49.182584, lng: 18.13095 },
    { lat: 49.182501, lng: 18.130995 },
    { lat: 49.182418, lng: 18.131039 },
    { lat: 49.182377, lng: 18.131058 },
    { lat: 49.182333, lng: 18.131078 },
    { lat: 49.182212, lng: 18.131149 },
    { lat: 49.182157, lng: 18.131205 },
    { lat: 49.182123, lng: 18.131261 },
    { lat: 49.182066, lng: 18.131277 },
    { lat: 49.182021, lng: 18.131219 },
    { lat: 49.181997, lng: 18.13113 },
    { lat: 49.182012, lng: 18.131068 },
    { lat: 49.182002, lng: 18.131033 },
    { lat: 49.181975, lng: 18.131017 },
    { lat: 49.181976, lng: 18.130944 },
    { lat: 49.182029, lng: 18.130869 },
    { lat: 49.182007, lng: 18.130821 },
    { lat: 49.181943, lng: 18.130831 },
    { lat: 49.181924, lng: 18.130867 },
    { lat: 49.181921, lng: 18.130923 },
    { lat: 49.181894, lng: 18.130928 },
    { lat: 49.181871, lng: 18.130915 },
    { lat: 49.18187, lng: 18.130949 },
    { lat: 49.181882, lng: 18.130976 },
    { lat: 49.181862, lng: 18.131023 },
    { lat: 49.181842, lng: 18.131112 },
    { lat: 49.181765, lng: 18.13118 },
    { lat: 49.181745, lng: 18.131132 },
    { lat: 49.181725, lng: 18.13113 },
    { lat: 49.181682, lng: 18.131152 },
    { lat: 49.181686, lng: 18.131216 },
    { lat: 49.181617, lng: 18.131201 },
    { lat: 49.181547, lng: 18.131133 },
    { lat: 49.181501, lng: 18.131103 },
    { lat: 49.181483, lng: 18.131114 },
    { lat: 49.181465, lng: 18.131097 },
    { lat: 49.181445, lng: 18.131109 },
    { lat: 49.18144, lng: 18.13116 },
    { lat: 49.181416, lng: 18.131181 },
    { lat: 49.181307, lng: 18.131125 },
    { lat: 49.181282, lng: 18.13113 },
    { lat: 49.181264, lng: 18.131161 },
    { lat: 49.181244, lng: 18.131214 },
    { lat: 49.181023, lng: 18.131275 },
    { lat: 49.180591, lng: 18.13119 },
    { lat: 49.180403, lng: 18.131152 },
    { lat: 49.180302, lng: 18.131134 },
    { lat: 49.180086, lng: 18.131091 },
    { lat: 49.1800118, lng: 18.1310781 },
    { lat: 49.1799704, lng: 18.1310716 },
    { lat: 49.179898, lng: 18.131055 },
    { lat: 49.179744, lng: 18.13099 },
    { lat: 49.1796357, lng: 18.1309425 },
    { lat: 49.1795699, lng: 18.1309109 },
    { lat: 49.179472, lng: 18.130864 },
    { lat: 49.179032, lng: 18.130709 },
    { lat: 49.178988, lng: 18.130694 },
    { lat: 49.178942, lng: 18.130727 },
    { lat: 49.178844, lng: 18.130722 },
    { lat: 49.178783, lng: 18.130709 },
    { lat: 49.178743, lng: 18.130666 },
    { lat: 49.178748, lng: 18.13054 },
    { lat: 49.178737, lng: 18.13052 },
    { lat: 49.178703, lng: 18.130524 },
    { lat: 49.178678, lng: 18.130511 },
    { lat: 49.178632, lng: 18.130546 },
    { lat: 49.178565, lng: 18.130565 },
    { lat: 49.178537, lng: 18.130557 },
    { lat: 49.178497, lng: 18.130499 },
    { lat: 49.178494, lng: 18.130446 },
    { lat: 49.178451, lng: 18.130417 },
    { lat: 49.178425, lng: 18.130426 },
    { lat: 49.17841, lng: 18.130397 },
    { lat: 49.178385, lng: 18.130401 },
    { lat: 49.17834, lng: 18.130434 },
    { lat: 49.17831, lng: 18.130401 },
    { lat: 49.178253, lng: 18.130386 },
    { lat: 49.178229, lng: 18.130341 },
    { lat: 49.178193, lng: 18.130329 },
    { lat: 49.178076, lng: 18.130375 },
    { lat: 49.178027, lng: 18.130413 },
    { lat: 49.177962, lng: 18.130452 },
    { lat: 49.177923, lng: 18.130458 },
    { lat: 49.177902, lng: 18.130433 },
    { lat: 49.177884, lng: 18.130393 },
    { lat: 49.177822, lng: 18.130385 },
    { lat: 49.177725, lng: 18.130358 },
    { lat: 49.177685, lng: 18.130317 },
    { lat: 49.177581, lng: 18.130224 },
    { lat: 49.177527, lng: 18.130224 },
    { lat: 49.177304, lng: 18.13015 },
    { lat: 49.177242, lng: 18.130141 },
    { lat: 49.17713, lng: 18.130176 },
    { lat: 49.177047, lng: 18.130166 },
    { lat: 49.176963, lng: 18.130225 },
    { lat: 49.176872, lng: 18.130241 },
    { lat: 49.176848, lng: 18.130216 },
    { lat: 49.176746, lng: 18.130245 },
    { lat: 49.176685, lng: 18.130256 },
    { lat: 49.176659, lng: 18.130289 },
    { lat: 49.176593, lng: 18.130298 },
    { lat: 49.176556, lng: 18.130326 },
    { lat: 49.176501, lng: 18.130323 },
    { lat: 49.176447, lng: 18.130339 },
    { lat: 49.176364, lng: 18.130387 },
    { lat: 49.176325, lng: 18.130418 },
    { lat: 49.176293, lng: 18.13047 },
    { lat: 49.176185, lng: 18.130509 },
    { lat: 49.176124, lng: 18.130495 },
    { lat: 49.176063, lng: 18.130482 },
    { lat: 49.176031, lng: 18.130484 },
    { lat: 49.176, lng: 18.130516 },
    { lat: 49.175947, lng: 18.130504 },
    { lat: 49.175893, lng: 18.130541 },
    { lat: 49.175851, lng: 18.13055 },
    { lat: 49.175829, lng: 18.13053 },
    { lat: 49.175742, lng: 18.130524 },
    { lat: 49.175656, lng: 18.130533 },
    { lat: 49.175574, lng: 18.130602 },
    { lat: 49.175486, lng: 18.130624 },
    { lat: 49.175466, lng: 18.130655 },
    { lat: 49.175358, lng: 18.130617 },
    { lat: 49.175341, lng: 18.130592 },
    { lat: 49.175274, lng: 18.130589 },
    { lat: 49.175237, lng: 18.1306 },
    { lat: 49.175205, lng: 18.130604 },
    { lat: 49.175107, lng: 18.130563 },
    { lat: 49.175015, lng: 18.130564 },
    { lat: 49.174938, lng: 18.130566 },
    { lat: 49.174853, lng: 18.130531 },
    { lat: 49.174808, lng: 18.130535 },
    { lat: 49.174776, lng: 18.130508 },
    { lat: 49.174717, lng: 18.130489 },
    { lat: 49.174626, lng: 18.130473 },
    { lat: 49.174598, lng: 18.130446 },
    { lat: 49.17444, lng: 18.130405 },
    { lat: 49.17431, lng: 18.130413 },
    { lat: 49.174247, lng: 18.130444 },
    { lat: 49.174227, lng: 18.130419 },
    { lat: 49.174105, lng: 18.130365 },
    { lat: 49.173825, lng: 18.130216 },
    { lat: 49.173485, lng: 18.130227 },
    { lat: 49.173132, lng: 18.13019 },
    { lat: 49.172519, lng: 18.130224 },
    { lat: 49.172222, lng: 18.1301 },
    { lat: 49.171797, lng: 18.130119 },
    { lat: 49.17117, lng: 18.130091 },
    { lat: 49.170207, lng: 18.130017 },
    { lat: 49.169332, lng: 18.129976 },
    { lat: 49.168904, lng: 18.129904 },
    { lat: 49.168448, lng: 18.129921 },
    { lat: 49.168179, lng: 18.130193 },
    { lat: 49.167894, lng: 18.130474 },
    { lat: 49.167232, lng: 18.131077 },
    { lat: 49.16666, lng: 18.131531 },
    { lat: 49.166288, lng: 18.131993 },
    { lat: 49.165876, lng: 18.132393 },
    { lat: 49.165203, lng: 18.133049 },
    { lat: 49.164453, lng: 18.133763 },
    { lat: 49.164015, lng: 18.134144 },
    { lat: 49.163759, lng: 18.134392 },
    { lat: 49.163518, lng: 18.134623 },
    { lat: 49.163185, lng: 18.134956 },
    { lat: 49.163129, lng: 18.135013 },
    { lat: 49.163032, lng: 18.13511 },
    { lat: 49.162539, lng: 18.135537 },
    { lat: 49.162212, lng: 18.135832 },
    { lat: 49.16199, lng: 18.136011 },
    { lat: 49.161687, lng: 18.136233 },
    { lat: 49.161144, lng: 18.136657 },
    { lat: 49.16102, lng: 18.136714 },
    { lat: 49.1610546, lng: 18.1369 },
    { lat: 49.1612788, lng: 18.1381883 },
    { lat: 49.1615334, lng: 18.1395536 },
    { lat: 49.1617023, lng: 18.14047 },
    { lat: 49.1618774, lng: 18.1415122 },
    { lat: 49.162106, lng: 18.1427221 },
    { lat: 49.1622542, lng: 18.1434744 },
    { lat: 49.1625758, lng: 18.1442784 },
    { lat: 49.1628906, lng: 18.1453573 },
    { lat: 49.1633552, lng: 18.1461212 },
    { lat: 49.1634099, lng: 18.1467754 },
    { lat: 49.1633685, lng: 18.1468618 },
    { lat: 49.1633545, lng: 18.146892 },
    { lat: 49.1630132, lng: 18.1476088 },
    { lat: 49.1630904, lng: 18.148069 },
    { lat: 49.1632152, lng: 18.1492602 },
    { lat: 49.16323, lng: 18.1499508 },
    { lat: 49.1635363, lng: 18.1506071 },
    { lat: 49.1637315, lng: 18.1507656 },
    { lat: 49.1639254, lng: 18.1512723 },
    { lat: 49.1641118, lng: 18.151708 },
    { lat: 49.1644092, lng: 18.1521698 },
    { lat: 49.1648506, lng: 18.152902 },
    { lat: 49.1650399, lng: 18.1536833 },
    { lat: 49.1654824, lng: 18.154631 },
    { lat: 49.1658327, lng: 18.1554454 },
    { lat: 49.1658354, lng: 18.1555513 },
    { lat: 49.1658615, lng: 18.1563212 },
    { lat: 49.1657949, lng: 18.1583071 },
    { lat: 49.1659437, lng: 18.158619 },
    { lat: 49.1660668, lng: 18.1589163 },
    { lat: 49.1662372, lng: 18.1593293 },
    { lat: 49.1664505, lng: 18.1598231 },
    { lat: 49.1665393, lng: 18.1602685 },
    { lat: 49.1666968, lng: 18.1611652 },
    { lat: 49.1669817, lng: 18.1619435 },
    { lat: 49.1673367, lng: 18.162921 },
    { lat: 49.1674785, lng: 18.1640365 },
    { lat: 49.1675316, lng: 18.1644506 },
    { lat: 49.167521, lng: 18.1645814 },
    { lat: 49.1675154, lng: 18.1646597 },
    { lat: 49.1675013, lng: 18.1648264 },
    { lat: 49.1671697, lng: 18.1650671 },
    { lat: 49.1670817, lng: 18.1652439 },
    { lat: 49.1671423, lng: 18.1659276 },
    { lat: 49.1673097, lng: 18.1669 },
    { lat: 49.1676519, lng: 18.1676338 },
    { lat: 49.1679574, lng: 18.1678262 },
    { lat: 49.1683652, lng: 18.1682666 },
    { lat: 49.1688341, lng: 18.1686142 },
    { lat: 49.1690287, lng: 18.1691738 },
    { lat: 49.1694009, lng: 18.1693291 },
    { lat: 49.1695297, lng: 18.1697077 },
    { lat: 49.169706, lng: 18.1699685 },
    { lat: 49.1699622, lng: 18.1702236 },
    { lat: 49.1704414, lng: 18.1705727 },
    { lat: 49.1708816, lng: 18.1712463 },
    { lat: 49.1712427, lng: 18.1716818 },
    { lat: 49.1715126, lng: 18.1721191 },
    { lat: 49.1717977, lng: 18.172486 },
    { lat: 49.1719507, lng: 18.1728536 },
    { lat: 49.1719804, lng: 18.1729185 },
    { lat: 49.1720234, lng: 18.1730194 },
    { lat: 49.172369, lng: 18.1741288 },
    { lat: 49.1725671, lng: 18.1745936 },
    { lat: 49.1727887, lng: 18.1753109 },
    { lat: 49.1728087, lng: 18.1761947 },
    { lat: 49.1725312, lng: 18.177281 },
    { lat: 49.1722959, lng: 18.177705 },
    { lat: 49.1720823, lng: 18.1782512 },
    { lat: 49.1717687, lng: 18.1788446 },
    { lat: 49.1713674, lng: 18.1796392 },
    { lat: 49.1710668, lng: 18.1801063 },
    { lat: 49.1709743, lng: 18.1803024 },
    { lat: 49.1709761, lng: 18.1807303 },
    { lat: 49.1709741, lng: 18.1811078 },
    { lat: 49.1709738, lng: 18.1811625 },
    { lat: 49.1709719, lng: 18.1814644 },
    { lat: 49.1712701, lng: 18.182378 },
    { lat: 49.1718784, lng: 18.1832103 },
    { lat: 49.1722281, lng: 18.1836647 },
    { lat: 49.172371, lng: 18.1841099 },
    { lat: 49.1726093, lng: 18.1856169 },
    { lat: 49.1726454, lng: 18.186053 },
    { lat: 49.1726615, lng: 18.1865705 },
    { lat: 49.1724501, lng: 18.1871548 },
    { lat: 49.1720847, lng: 18.1879876 },
    { lat: 49.17201, lng: 18.1884187 },
    { lat: 49.1718737, lng: 18.1888589 },
    { lat: 49.171763, lng: 18.189586 },
    { lat: 49.1715297, lng: 18.1905226 },
    { lat: 49.17153, lng: 18.1909618 },
    { lat: 49.1715292, lng: 18.1910054 },
    { lat: 49.1715294, lng: 18.191208 },
    { lat: 49.1715295, lng: 18.1912493 },
    { lat: 49.1715294, lng: 18.1913865 },
    { lat: 49.1715528, lng: 18.1923285 },
    { lat: 49.1716325, lng: 18.1931341 },
    { lat: 49.1719332, lng: 18.1940132 },
    { lat: 49.1722048, lng: 18.1945614 },
    { lat: 49.1723309, lng: 18.1946353 },
    { lat: 49.1723554, lng: 18.1946503 },
    { lat: 49.1725351, lng: 18.1947545 },
    { lat: 49.1731942, lng: 18.1949553 },
    { lat: 49.1735732, lng: 18.1949141 },
    { lat: 49.1736905, lng: 18.1948947 },
    { lat: 49.1737075, lng: 18.1948932 },
    { lat: 49.1739504, lng: 18.1948417 },
    { lat: 49.1739827, lng: 18.1948339 },
    { lat: 49.1740516, lng: 18.1948136 },
    { lat: 49.1742836, lng: 18.1947463 },
    { lat: 49.1746127, lng: 18.1946218 },
    { lat: 49.1747064, lng: 18.194584 },
    { lat: 49.174894, lng: 18.1945104 },
    { lat: 49.1750099, lng: 18.194464 },
    { lat: 49.1751015, lng: 18.1944295 },
    { lat: 49.1752684, lng: 18.1944461 },
    { lat: 49.1753104, lng: 18.1944508 },
    { lat: 49.1759886, lng: 18.1945321 },
    { lat: 49.1762879, lng: 18.1945375 },
    { lat: 49.1766092, lng: 18.1943942 },
    { lat: 49.1768457, lng: 18.194288 },
    { lat: 49.1772454, lng: 18.1942921 },
    { lat: 49.1776289, lng: 18.1942896 },
    { lat: 49.1777201, lng: 18.1943269 },
    { lat: 49.1783094, lng: 18.1945495 },
    { lat: 49.1787459, lng: 18.1944699 },
    { lat: 49.1788956, lng: 18.1945409 },
    { lat: 49.178991, lng: 18.1946707 },
    { lat: 49.1792743, lng: 18.1949889 },
    { lat: 49.1797569, lng: 18.1952984 },
    { lat: 49.1801322, lng: 18.1957272 },
    { lat: 49.1808516, lng: 18.1959372 },
    { lat: 49.1808845, lng: 18.1959602 },
    { lat: 49.1811109, lng: 18.1961131 },
    { lat: 49.1815826, lng: 18.1961525 },
    { lat: 49.1822445, lng: 18.196402 },
    { lat: 49.1823759, lng: 18.1964509 },
    { lat: 49.1824946, lng: 18.1969502 },
    { lat: 49.1826644, lng: 18.1969217 },
    { lat: 49.1827932, lng: 18.1969037 },
    { lat: 49.1830538, lng: 18.1970199 },
    { lat: 49.1834026, lng: 18.1972828 },
    { lat: 49.1835628, lng: 18.1974018 },
    { lat: 49.1836715, lng: 18.197861 },
    { lat: 49.1838002, lng: 18.1979235 },
    { lat: 49.183985, lng: 18.1980173 },
    { lat: 49.1841049, lng: 18.198794 },
    { lat: 49.1844961, lng: 18.1987882 },
    { lat: 49.1852905, lng: 18.1987503 },
    { lat: 49.1853141, lng: 18.1987494 },
    { lat: 49.1856287, lng: 18.1986279 },
    { lat: 49.1859047, lng: 18.1985217 },
    { lat: 49.1861112, lng: 18.198697 },
    { lat: 49.1861386, lng: 18.1987214 },
    { lat: 49.1861634, lng: 18.1987426 },
    { lat: 49.1861905, lng: 18.1987657 },
    { lat: 49.1863317, lng: 18.1988864 },
    { lat: 49.1866051, lng: 18.1991699 },
    { lat: 49.1866247, lng: 18.1991952 },
    { lat: 49.1869316, lng: 18.199494 },
    { lat: 49.1869202, lng: 18.2001444 },
    { lat: 49.1869199, lng: 18.2001755 },
    { lat: 49.1869508, lng: 18.2001667 },
    { lat: 49.1870153, lng: 18.2001487 },
    { lat: 49.187083, lng: 18.2001298 },
    { lat: 49.187237, lng: 18.200161 },
    { lat: 49.1875531, lng: 18.2003793 },
    { lat: 49.1876827, lng: 18.2004769 },
    { lat: 49.1878336, lng: 18.2003737 },
    { lat: 49.1879515, lng: 18.2002221 },
    { lat: 49.188036, lng: 18.200182 },
    { lat: 49.1882503, lng: 18.2003206 },
    { lat: 49.1886066, lng: 18.2003742 },
    { lat: 49.1888529, lng: 18.2004609 },
    { lat: 49.1891337, lng: 18.2003566 },
    { lat: 49.1892771, lng: 18.2003614 },
    { lat: 49.1897451, lng: 18.2004348 },
    { lat: 49.1898815, lng: 18.2006124 },
    { lat: 49.1900592, lng: 18.2007595 },
    { lat: 49.1905379, lng: 18.2013197 },
    { lat: 49.1905898, lng: 18.201438 },
    { lat: 49.1907692, lng: 18.2013792 },
    { lat: 49.1909106, lng: 18.2015037 },
    { lat: 49.1910583, lng: 18.2014433 },
    { lat: 49.191231, lng: 18.2013829 },
    { lat: 49.1913475, lng: 18.2014687 },
    { lat: 49.1914535, lng: 18.2014093 },
    { lat: 49.1915822, lng: 18.2014888 },
    { lat: 49.1916627, lng: 18.2015952 },
    { lat: 49.1918337, lng: 18.2016454 },
    { lat: 49.1919096, lng: 18.2017489 },
    { lat: 49.1920107, lng: 18.201781 },
    { lat: 49.1920088, lng: 18.2017886 },
    { lat: 49.1919995, lng: 18.2018411 },
    { lat: 49.1919702, lng: 18.2020046 },
    { lat: 49.1918896, lng: 18.2024695 },
    { lat: 49.1918558, lng: 18.2026687 },
    { lat: 49.1923337, lng: 18.2030931 },
    { lat: 49.1927487, lng: 18.2034887 },
    { lat: 49.1929987, lng: 18.2037559 },
    { lat: 49.1932998, lng: 18.204228 },
    { lat: 49.1933884, lng: 18.2043602 },
    { lat: 49.1935585, lng: 18.2045516 },
    { lat: 49.1936892, lng: 18.2046469 },
    { lat: 49.1938703, lng: 18.2046795 },
    { lat: 49.1939952, lng: 18.2047098 },
    { lat: 49.1943375, lng: 18.2046382 },
    { lat: 49.1946823, lng: 18.2044956 },
    { lat: 49.1948488, lng: 18.2044043 },
    { lat: 49.1949018, lng: 18.2044429 },
    { lat: 49.1949252, lng: 18.2044587 },
    { lat: 49.1952364, lng: 18.2046718 },
    { lat: 49.1952621, lng: 18.2046917 },
    { lat: 49.195291, lng: 18.2049994 },
    { lat: 49.195355, lng: 18.205231 },
    { lat: 49.1954354, lng: 18.205414 },
    { lat: 49.1955415, lng: 18.205633 },
    { lat: 49.1955582, lng: 18.2057271 },
    { lat: 49.1955769, lng: 18.205839 },
    { lat: 49.1955841, lng: 18.2059665 },
    { lat: 49.1955925, lng: 18.2060523 },
    { lat: 49.1956018, lng: 18.2061366 },
    { lat: 49.1956391, lng: 18.2064971 },
    { lat: 49.1956385, lng: 18.2068591 },
    { lat: 49.1956878, lng: 18.2072115 },
    { lat: 49.1957278, lng: 18.2073617 },
    { lat: 49.1957907, lng: 18.2077313 },
    { lat: 49.1959497, lng: 18.2087436 },
    { lat: 49.1959502, lng: 18.2088099 },
    { lat: 49.1959527, lng: 18.2091323 },
    { lat: 49.1959331, lng: 18.2094821 },
    { lat: 49.1959626, lng: 18.2096803 },
    { lat: 49.1959679, lng: 18.2097153 },
    { lat: 49.1960322, lng: 18.2099146 },
    { lat: 49.1961664, lng: 18.209615 },
    { lat: 49.1963422, lng: 18.2095344 },
    { lat: 49.1965962, lng: 18.2094349 },
    { lat: 49.1966214, lng: 18.2094247 },
    { lat: 49.1966938, lng: 18.2094258 },
    { lat: 49.1966997, lng: 18.2094297 },
    { lat: 49.19671, lng: 18.2094323 },
    { lat: 49.1967765, lng: 18.2094498 },
    { lat: 49.1968072, lng: 18.2094582 },
    { lat: 49.1968495, lng: 18.2094861 },
    { lat: 49.1968964, lng: 18.2095169 },
    { lat: 49.1969281, lng: 18.2095373 },
    { lat: 49.1969918, lng: 18.2095856 },
    { lat: 49.1970072, lng: 18.2094824 },
    { lat: 49.1970221, lng: 18.2093866 },
    { lat: 49.1970285, lng: 18.2093419 },
    { lat: 49.1970361, lng: 18.2092957 },
    { lat: 49.197111, lng: 18.2088055 },
    { lat: 49.1971349, lng: 18.2086509 },
    { lat: 49.1971126, lng: 18.2079023 },
    { lat: 49.1971076, lng: 18.2077356 },
    { lat: 49.1971647, lng: 18.2076672 },
    { lat: 49.1971933, lng: 18.2076324 },
    { lat: 49.197209, lng: 18.2076143 },
    { lat: 49.1972535, lng: 18.2075611 },
    { lat: 49.1972715, lng: 18.20754 },
    { lat: 49.1973717, lng: 18.2074199 },
    { lat: 49.1973771, lng: 18.207414 },
    { lat: 49.1975883, lng: 18.2071621 },
    { lat: 49.1976707, lng: 18.2070639 },
    { lat: 49.1978056, lng: 18.2069176 },
    { lat: 49.1985559, lng: 18.2061007 },
    { lat: 49.199128, lng: 18.2056091 },
    { lat: 49.1994966, lng: 18.2055009 },
    { lat: 49.1996389, lng: 18.2056836 },
    { lat: 49.1998122, lng: 18.2055964 },
    { lat: 49.2001184, lng: 18.2054425 },
    { lat: 49.200285, lng: 18.2053591 },
    { lat: 49.2005178, lng: 18.2054643 },
    { lat: 49.2007767, lng: 18.2051952 },
    { lat: 49.201085, lng: 18.2064495 },
    { lat: 49.2009353, lng: 18.2065947 },
    { lat: 49.2011354, lng: 18.2070576 },
    { lat: 49.2014291, lng: 18.2074035 },
    { lat: 49.2016297, lng: 18.2076533 },
    { lat: 49.2018287, lng: 18.2076024 },
    { lat: 49.2018557, lng: 18.2075821 },
    { lat: 49.2023013, lng: 18.2072441 },
    { lat: 49.2025536, lng: 18.2068957 },
    { lat: 49.20266, lng: 18.2067475 },
    { lat: 49.2031498, lng: 18.2063913 },
    { lat: 49.2034682, lng: 18.2057209 },
    { lat: 49.2036321, lng: 18.2054271 },
    { lat: 49.2037044, lng: 18.2052894 },
    { lat: 49.2038136, lng: 18.2050902 },
    { lat: 49.2037595, lng: 18.2045756 },
    { lat: 49.2037981, lng: 18.2039246 },
    { lat: 49.2039653, lng: 18.2040295 },
    { lat: 49.2042755, lng: 18.2040461 },
    { lat: 49.2045348, lng: 18.2040406 },
    { lat: 49.2048588, lng: 18.2032522 },
    { lat: 49.2050701, lng: 18.2029024 },
    { lat: 49.2053535, lng: 18.2026471 },
    { lat: 49.2055588, lng: 18.2025069 },
    { lat: 49.2057673, lng: 18.2023653 },
    { lat: 49.2060634, lng: 18.2021921 },
    { lat: 49.2060908, lng: 18.2021798 },
    { lat: 49.206374, lng: 18.201922 },
    { lat: 49.2067567, lng: 18.2016711 },
    { lat: 49.2069947, lng: 18.2013489 },
    { lat: 49.2071928, lng: 18.201203 },
    { lat: 49.2076509, lng: 18.2009075 },
    { lat: 49.2077557, lng: 18.200625 },
    { lat: 49.207983, lng: 18.2002792 },
    { lat: 49.2083441, lng: 18.1999593 },
    { lat: 49.2085107, lng: 18.1995979 },
    { lat: 49.2087891, lng: 18.1989026 },
    { lat: 49.2089239, lng: 18.1988318 },
    { lat: 49.2092186, lng: 18.1986769 },
    { lat: 49.2097576, lng: 18.1985504 },
    { lat: 49.2100459, lng: 18.198602 },
    { lat: 49.2103526, lng: 18.1985981 },
    { lat: 49.2105782, lng: 18.1987157 },
    { lat: 49.2108663, lng: 18.1984433 },
    { lat: 49.2111008, lng: 18.1982635 },
    { lat: 49.2113238, lng: 18.1979585 },
    { lat: 49.2113802, lng: 18.1978812 },
    { lat: 49.2115273, lng: 18.1972134 },
    { lat: 49.211639, lng: 18.1970163 },
    { lat: 49.2117165, lng: 18.1963757 },
    { lat: 49.2118859, lng: 18.1958848 },
    { lat: 49.2118787, lng: 18.1958376 },
    { lat: 49.21203, lng: 18.1956215 },
    { lat: 49.2120853, lng: 18.1955415 },
    { lat: 49.2123513, lng: 18.1951604 },
    { lat: 49.2123726, lng: 18.1951197 },
    { lat: 49.2124816, lng: 18.1949109 },
    { lat: 49.212759, lng: 18.194572 },
    { lat: 49.2130596, lng: 18.1942831 },
    { lat: 49.213393, lng: 18.1940193 },
    { lat: 49.2135302, lng: 18.1939116 },
    { lat: 49.213806, lng: 18.1937223 },
    { lat: 49.2139428, lng: 18.1936668 },
    { lat: 49.2141281, lng: 18.1935919 },
    { lat: 49.2143495, lng: 18.1933575 },
    { lat: 49.2147109, lng: 18.1930063 },
    { lat: 49.2151368, lng: 18.1926807 },
    { lat: 49.2153836, lng: 18.1924972 },
    { lat: 49.2155912, lng: 18.1922617 },
    { lat: 49.2157602, lng: 18.1919783 },
    { lat: 49.2159157, lng: 18.191499 },
    { lat: 49.2160556, lng: 18.1913405 },
    { lat: 49.216082, lng: 18.1913098 },
    { lat: 49.216117, lng: 18.1912697 },
    { lat: 49.2161747, lng: 18.191074 },
    { lat: 49.2163058, lng: 18.1902272 },
    { lat: 49.2163707, lng: 18.1899017 },
    { lat: 49.21645, lng: 18.1895136 },
    { lat: 49.2164994, lng: 18.1892681 },
    { lat: 49.2165457, lng: 18.1888559 },
    { lat: 49.2165806, lng: 18.1888349 },
    { lat: 49.216957, lng: 18.18861 },
    { lat: 49.2170933, lng: 18.1884886 },
    { lat: 49.2173663, lng: 18.1882459 },
    { lat: 49.2174435, lng: 18.1881772 },
    { lat: 49.2175146, lng: 18.188119 },
    { lat: 49.217766, lng: 18.1879159 },
    { lat: 49.2179852, lng: 18.1877634 },
    { lat: 49.2181351, lng: 18.1876593 },
    { lat: 49.2182412, lng: 18.1875853 },
    { lat: 49.2183455, lng: 18.1874738 },
    { lat: 49.2184806, lng: 18.1873297 },
    { lat: 49.2188136, lng: 18.186996 },
    { lat: 49.2192102, lng: 18.1865704 },
    { lat: 49.2193922, lng: 18.186222 },
    { lat: 49.2189945, lng: 18.1860555 },
    { lat: 49.2193085, lng: 18.1848132 },
    { lat: 49.2193352, lng: 18.1847093 },
    { lat: 49.2193508, lng: 18.1846466 },
    { lat: 49.2198667, lng: 18.1846305 },
    { lat: 49.2199798, lng: 18.1846154 },
    { lat: 49.2201132, lng: 18.1845983 },
    { lat: 49.2208645, lng: 18.1844987 },
    { lat: 49.221082, lng: 18.1845521 },
    { lat: 49.221385, lng: 18.1845444 },
    { lat: 49.2214279, lng: 18.1845434 },
    { lat: 49.221887, lng: 18.1845049 },
    { lat: 49.2218935, lng: 18.1845362 },
    { lat: 49.2225538, lng: 18.1841081 },
    { lat: 49.2229959, lng: 18.1838834 },
    { lat: 49.2234168, lng: 18.1836695 },
    { lat: 49.2235912, lng: 18.1835808 },
    { lat: 49.2239707, lng: 18.1834477 },
    { lat: 49.2243777, lng: 18.1833047 },
    { lat: 49.2248742, lng: 18.1830475 },
    { lat: 49.2249185, lng: 18.1830369 },
    { lat: 49.2252036, lng: 18.1829685 },
    { lat: 49.2252059, lng: 18.1829677 },
    { lat: 49.2252075, lng: 18.1829377 },
    { lat: 49.2251885, lng: 18.1823898 },
    { lat: 49.2251847, lng: 18.1822826 },
    { lat: 49.2251864, lng: 18.1822701 },
    { lat: 49.2251911, lng: 18.1821792 },
    { lat: 49.2251958, lng: 18.1821201 },
    { lat: 49.225206, lng: 18.1819628 },
    { lat: 49.2252177, lng: 18.1817748 },
    { lat: 49.2252268, lng: 18.1816359 },
    { lat: 49.2252348, lng: 18.1814987 },
    { lat: 49.2252421, lng: 18.1813909 },
    { lat: 49.2255197, lng: 18.1811343 },
    { lat: 49.2261366, lng: 18.1806343 },
    { lat: 49.2261654, lng: 18.1807025 },
    { lat: 49.2262033, lng: 18.1807957 },
    { lat: 49.226249, lng: 18.1809046 },
    { lat: 49.2262936, lng: 18.1810145 },
    { lat: 49.2262976, lng: 18.1810223 },
    { lat: 49.2267496, lng: 18.1808603 },
    { lat: 49.2270828, lng: 18.1804091 },
    { lat: 49.2271359, lng: 18.1805695 },
    { lat: 49.2271944, lng: 18.1807448 },
    { lat: 49.2273088, lng: 18.1810884 },
    { lat: 49.2273674, lng: 18.1812662 },
    { lat: 49.227373, lng: 18.1812804 },
    { lat: 49.2274041, lng: 18.1813335 },
    { lat: 49.2274168, lng: 18.1813563 },
    { lat: 49.2274281, lng: 18.1815164 },
    { lat: 49.2274368, lng: 18.1820042 },
    { lat: 49.2274659, lng: 18.1819808 },
    { lat: 49.2277519, lng: 18.1817515 },
    { lat: 49.2281893, lng: 18.1812124 },
    { lat: 49.2286293, lng: 18.1805421 },
    { lat: 49.2287148, lng: 18.1801581 },
    { lat: 49.2291719, lng: 18.1796303 },
    { lat: 49.2292101, lng: 18.1795862 },
    { lat: 49.2294828, lng: 18.179145 },
    { lat: 49.2298463, lng: 18.178774 },
    { lat: 49.2301758, lng: 18.1784381 },
    { lat: 49.2304745, lng: 18.1782153 },
    { lat: 49.230836, lng: 18.1779453 },
    { lat: 49.2310989, lng: 18.1779249 },
    { lat: 49.2313196, lng: 18.1776612 },
    { lat: 49.2314507, lng: 18.1775055 },
    { lat: 49.2316938, lng: 18.1772785 },
    { lat: 49.2321894, lng: 18.1767123 },
    { lat: 49.2323135, lng: 18.176571 },
    { lat: 49.2324478, lng: 18.1763155 },
    { lat: 49.2326743, lng: 18.1761738 },
    { lat: 49.2329467, lng: 18.1760041 },
    { lat: 49.2330437, lng: 18.1759437 },
    { lat: 49.2330993, lng: 18.1759114 },
    { lat: 49.2332612, lng: 18.1758196 },
    { lat: 49.2335799, lng: 18.1756112 },
    { lat: 49.2338561, lng: 18.1754892 },
    { lat: 49.2340709, lng: 18.1754577 },
    { lat: 49.2341337, lng: 18.1756505 },
    { lat: 49.2342869, lng: 18.176118 },
    { lat: 49.2343151, lng: 18.1761771 },
    { lat: 49.2344311, lng: 18.1764319 },
    { lat: 49.2345111, lng: 18.1766068 },
    { lat: 49.2346026, lng: 18.1768057 },
    { lat: 49.2346664, lng: 18.1769755 },
    { lat: 49.2347702, lng: 18.1772526 },
    { lat: 49.2350708, lng: 18.1780489 },
    { lat: 49.235193, lng: 18.1783717 },
    { lat: 49.2352682, lng: 18.1787023 },
    { lat: 49.2353131, lng: 18.1788928 },
    { lat: 49.2353167, lng: 18.1789382 },
    { lat: 49.2353296, lng: 18.1790655 },
    { lat: 49.2353528, lng: 18.1792154 },
    { lat: 49.235394, lng: 18.1794814 },
    { lat: 49.2354276, lng: 18.1796921 },
    { lat: 49.2354507, lng: 18.1798412 },
    { lat: 49.2354544, lng: 18.1798691 },
    { lat: 49.2354631, lng: 18.1799214 },
    { lat: 49.235756, lng: 18.1798914 },
    { lat: 49.2363912, lng: 18.1796539 },
    { lat: 49.2364, lng: 18.1796507 },
    { lat: 49.2366923, lng: 18.1794725 },
    { lat: 49.2370179, lng: 18.1792081 },
    { lat: 49.2371997, lng: 18.1791714 },
    { lat: 49.237278, lng: 18.1790801 },
    { lat: 49.2377051, lng: 18.1785768 },
    { lat: 49.2375791, lng: 18.1780518 },
    { lat: 49.2375483, lng: 18.1779262 },
    { lat: 49.2375393, lng: 18.1778886 },
    { lat: 49.2375336, lng: 18.1778679 },
    { lat: 49.2374325, lng: 18.1775165 },
    { lat: 49.2372998, lng: 18.1772305 },
    { lat: 49.2372561, lng: 18.1771356 },
    { lat: 49.23727, lng: 18.1766098 },
    { lat: 49.2373757, lng: 18.1756739 },
    { lat: 49.2374998, lng: 18.175161 },
    { lat: 49.237577, lng: 18.1749516 },
    { lat: 49.2376326, lng: 18.1747987 },
    { lat: 49.2377028, lng: 18.1746088 },
    { lat: 49.2380291, lng: 18.1746292 },
    { lat: 49.2381333, lng: 18.1746359 },
    { lat: 49.2384662, lng: 18.1746562 },
    { lat: 49.2386523, lng: 18.1743999 },
    { lat: 49.2390342, lng: 18.1738765 },
    { lat: 49.2393014, lng: 18.1736371 },
    { lat: 49.2395066, lng: 18.1735953 },
    { lat: 49.240029, lng: 18.1736914 },
    { lat: 49.2400323, lng: 18.1736919 },
    { lat: 49.2406605, lng: 18.1738808 },
    { lat: 49.2407826, lng: 18.1740451 },
    { lat: 49.2408133, lng: 18.1740858 },
    { lat: 49.2408595, lng: 18.1741244 },
    { lat: 49.2408869, lng: 18.1744679 },
    { lat: 49.2409012, lng: 18.1746596 },
    { lat: 49.2409221, lng: 18.1749269 },
    { lat: 49.2410799, lng: 18.1756555 },
    { lat: 49.2411126, lng: 18.1757532 },
    { lat: 49.2412351, lng: 18.1761173 },
    { lat: 49.2413108, lng: 18.1763578 },
    { lat: 49.2414291, lng: 18.1767895 },
    { lat: 49.241498, lng: 18.1770483 },
    { lat: 49.2415145, lng: 18.1771183 },
    { lat: 49.2415172, lng: 18.1771446 },
    { lat: 49.2416974, lng: 18.1769845 },
    { lat: 49.2424425, lng: 18.176696 },
    { lat: 49.2427287, lng: 18.1767261 },
    { lat: 49.2435319, lng: 18.1767437 },
    { lat: 49.2435437, lng: 18.1767133 },
    { lat: 49.2438306, lng: 18.1769347 },
    { lat: 49.2440377, lng: 18.1770062 },
    { lat: 49.2441993, lng: 18.1771268 },
    { lat: 49.2448253, lng: 18.1772041 },
    { lat: 49.2449817, lng: 18.1770786 },
    { lat: 49.2452416, lng: 18.1769683 },
    { lat: 49.2452721, lng: 18.1768098 },
    { lat: 49.2452914, lng: 18.1768293 },
    { lat: 49.2457349, lng: 18.1771261 },
    { lat: 49.2458093, lng: 18.1772085 },
    { lat: 49.2458818, lng: 18.1772884 },
    { lat: 49.2459744, lng: 18.1773916 },
    { lat: 49.2459229, lng: 18.1776318 },
    { lat: 49.2459988, lng: 18.1778354 },
    { lat: 49.2460708, lng: 18.1780319 },
    { lat: 49.246274, lng: 18.1783326 },
    { lat: 49.2463334, lng: 18.17842 },
    { lat: 49.2466174, lng: 18.1789084 },
    { lat: 49.2467175, lng: 18.1792414 },
    { lat: 49.2467273, lng: 18.1792727 },
    { lat: 49.2467342, lng: 18.1792948 },
    { lat: 49.2467424, lng: 18.1793226 },
    { lat: 49.2470381, lng: 18.1797557 },
    { lat: 49.2472337, lng: 18.1798438 },
    { lat: 49.2474303, lng: 18.1799254 },
    { lat: 49.2476269, lng: 18.1803074 },
    { lat: 49.2476481, lng: 18.1803027 },
    { lat: 49.2478865, lng: 18.1802504 },
    { lat: 49.2484221, lng: 18.1802849 },
    { lat: 49.2486623, lng: 18.1803002 },
    { lat: 49.2492726, lng: 18.1804956 },
    { lat: 49.2497371, lng: 18.1806057 },
    { lat: 49.2501264, lng: 18.1804518 },
    { lat: 49.2502776, lng: 18.1803916 },
    { lat: 49.2503835, lng: 18.1801122 },
    { lat: 49.2505209, lng: 18.1797526 },
    { lat: 49.2506618, lng: 18.1791935 },
    { lat: 49.2507732, lng: 18.1786766 },
    { lat: 49.2507912, lng: 18.178594 },
    { lat: 49.250851, lng: 18.1783199 },
    { lat: 49.2509431, lng: 18.1774811 },
    { lat: 49.2514652, lng: 18.1763888 },
    { lat: 49.2519064, lng: 18.1761953 },
    { lat: 49.25209, lng: 18.1757909 },
    { lat: 49.252158, lng: 18.1756402 },
    { lat: 49.2523287, lng: 18.1748367 },
    { lat: 49.2523878, lng: 18.1746053 },
    { lat: 49.2525947, lng: 18.1738396 },
    { lat: 49.2526272, lng: 18.1735817 },
    { lat: 49.2526665, lng: 18.1732633 },
    { lat: 49.2528093, lng: 18.1724435 },
    { lat: 49.2529723, lng: 18.1718829 },
    { lat: 49.253049, lng: 18.1717226 },
    { lat: 49.2533615, lng: 18.1710728 },
    { lat: 49.2536065, lng: 18.1704199 },
    { lat: 49.254075, lng: 18.1707226 },
    { lat: 49.2542726, lng: 18.1709625 },
    { lat: 49.2544621, lng: 18.1712751 },
    { lat: 49.2546564, lng: 18.1715958 },
    { lat: 49.2548757, lng: 18.1717603 },
    { lat: 49.2549444, lng: 18.1717947 },
    { lat: 49.2551196, lng: 18.1718826 },
    { lat: 49.2551615, lng: 18.1719041 },
    { lat: 49.2554893, lng: 18.1722537 },
    { lat: 49.2559847, lng: 18.1729239 },
    { lat: 49.2563685, lng: 18.1727105 },
    { lat: 49.2564889, lng: 18.1726442 },
    { lat: 49.25649, lng: 18.1726437 },
    { lat: 49.256534, lng: 18.1725866 },
    { lat: 49.2567183, lng: 18.1723516 },
    { lat: 49.2568808, lng: 18.1721954 },
    { lat: 49.2571075, lng: 18.1722531 },
    { lat: 49.2572739, lng: 18.172144 },
    { lat: 49.2573272, lng: 18.1721087 },
    { lat: 49.2573771, lng: 18.1718194 },
    { lat: 49.2573709, lng: 18.1713934 },
    { lat: 49.2575086, lng: 18.1709015 },
    { lat: 49.2575244, lng: 18.1708434 },
    { lat: 49.2576153, lng: 18.1705176 },
    { lat: 49.2576553, lng: 18.1698149 },
    { lat: 49.2576224, lng: 18.1682562 },
    { lat: 49.2577006, lng: 18.1674113 },
    { lat: 49.2577003, lng: 18.1670292 },
    { lat: 49.2578555, lng: 18.1660129 },
    { lat: 49.2579522, lng: 18.1657323 },
    { lat: 49.2579826, lng: 18.1653759 },
    { lat: 49.2580034, lng: 18.1651262 },
    { lat: 49.2579947, lng: 18.1650372 },
    { lat: 49.2579852, lng: 18.1648703 },
    { lat: 49.2579534, lng: 18.1647656 },
    { lat: 49.2578535, lng: 18.1644819 },
    { lat: 49.2578086, lng: 18.1642888 },
    { lat: 49.2577637, lng: 18.1638798 },
    { lat: 49.2577296, lng: 18.1633407 },
    { lat: 49.2577048, lng: 18.1627875 },
    { lat: 49.2577145, lng: 18.1624237 },
    { lat: 49.2576609, lng: 18.1620282 },
    { lat: 49.257075, lng: 18.161921 },
    { lat: 49.256821, lng: 18.161799 },
    { lat: 49.256433, lng: 18.161716 },
    { lat: 49.256204, lng: 18.161698 },
    { lat: 49.255433, lng: 18.161453 },
    { lat: 49.255082, lng: 18.161273 },
    { lat: 49.254306, lng: 18.160283 },
    { lat: 49.254179, lng: 18.159726 },
    { lat: 49.253891, lng: 18.158987 },
    { lat: 49.253566, lng: 18.157943 },
    { lat: 49.253096, lng: 18.156662 },
    { lat: 49.253027, lng: 18.155906 },
    { lat: 49.252992, lng: 18.155219 },
    { lat: 49.252885, lng: 18.154605 },
    { lat: 49.252656, lng: 18.153842 },
    { lat: 49.252254, lng: 18.152472 },
    { lat: 49.251997, lng: 18.152202 },
    { lat: 49.251483, lng: 18.151702 },
    { lat: 49.25114, lng: 18.151251 },
    { lat: 49.25017, lng: 18.149898 },
    { lat: 49.249985, lng: 18.149538 },
    { lat: 49.249392, lng: 18.148572 },
    { lat: 49.24899, lng: 18.147838 },
    { lat: 49.248749, lng: 18.147551 },
    { lat: 49.248421, lng: 18.147192 },
    { lat: 49.24793, lng: 18.146972 },
    { lat: 49.247307, lng: 18.146752 },
    { lat: 49.246517, lng: 18.14672 },
    { lat: 49.246251, lng: 18.146732 },
    { lat: 49.245913, lng: 18.146464 },
    { lat: 49.245307, lng: 18.146656 },
    { lat: 49.244917, lng: 18.146601 },
    { lat: 49.244614, lng: 18.146655 },
    { lat: 49.243971, lng: 18.146983 },
    { lat: 49.243649, lng: 18.147048 },
    { lat: 49.243227, lng: 18.147149 },
    { lat: 49.24258, lng: 18.147441 },
    { lat: 49.242096, lng: 18.14761 },
    { lat: 49.241702, lng: 18.147767 },
    { lat: 49.241096, lng: 18.147942 },
    { lat: 49.240736, lng: 18.147932 },
    { lat: 49.240413, lng: 18.148006 },
    { lat: 49.240081, lng: 18.148084 },
    { lat: 49.239709, lng: 18.148019 },
    { lat: 49.239405, lng: 18.147978 },
    { lat: 49.239003, lng: 18.147953 },
    { lat: 49.238475, lng: 18.147917 },
    { lat: 49.237933, lng: 18.147887 },
    { lat: 49.237645, lng: 18.147807 },
    { lat: 49.237368, lng: 18.147816 },
    { lat: 49.237037, lng: 18.147824 },
    { lat: 49.236494, lng: 18.147832 },
    { lat: 49.236192, lng: 18.147773 },
    { lat: 49.235925, lng: 18.147794 },
    { lat: 49.235625, lng: 18.147769 },
    { lat: 49.235515, lng: 18.147752 },
    { lat: 49.235452, lng: 18.147738 },
    { lat: 49.235306, lng: 18.14763 },
    { lat: 49.235096, lng: 18.147514 },
    { lat: 49.234851, lng: 18.14738 },
    { lat: 49.234448, lng: 18.147172 },
    { lat: 49.234067, lng: 18.146983 },
    { lat: 49.233845, lng: 18.146878 },
    { lat: 49.233514, lng: 18.146701 },
    { lat: 49.233268, lng: 18.146515 },
    { lat: 49.232788, lng: 18.146347 },
    { lat: 49.232635, lng: 18.146154 },
    { lat: 49.232357, lng: 18.146021 },
    { lat: 49.232106, lng: 18.145901 },
    { lat: 49.231884, lng: 18.145884 },
    { lat: 49.231434, lng: 18.145626 },
    { lat: 49.231231, lng: 18.145494 },
    { lat: 49.231024, lng: 18.145365 },
    { lat: 49.230825, lng: 18.144946 },
    { lat: 49.230696, lng: 18.144864 },
    { lat: 49.230274, lng: 18.144814 },
    { lat: 49.230173, lng: 18.144606 },
    { lat: 49.229848, lng: 18.144518 },
    { lat: 49.229289, lng: 18.144427 },
    { lat: 49.229235, lng: 18.144406 },
    { lat: 49.229206, lng: 18.144433 },
    { lat: 49.229243, lng: 18.144603 },
    { lat: 49.229221, lng: 18.144843 },
    { lat: 49.229255, lng: 18.145008 },
    { lat: 49.229235, lng: 18.145193 },
    { lat: 49.229278, lng: 18.145514 },
    { lat: 49.229313, lng: 18.145885 },
    { lat: 49.229218, lng: 18.145989 },
    { lat: 49.229228, lng: 18.146163 },
    { lat: 49.229193, lng: 18.146349 },
    { lat: 49.229205, lng: 18.146564 },
    { lat: 49.229208, lng: 18.146818 },
    { lat: 49.229267, lng: 18.147025 },
    { lat: 49.229259, lng: 18.147099 },
    { lat: 49.229283, lng: 18.14727 },
    { lat: 49.22928, lng: 18.147604 },
    { lat: 49.229258, lng: 18.147938 },
    { lat: 49.229263, lng: 18.148129 },
    { lat: 49.229262, lng: 18.148252 },
    { lat: 49.229318, lng: 18.148614 },
    { lat: 49.229348, lng: 18.14871 },
    { lat: 49.229411, lng: 18.148855 },
    { lat: 49.229423, lng: 18.149016 },
    { lat: 49.229433, lng: 18.149175 },
    { lat: 49.229498, lng: 18.149385 },
    { lat: 49.22947, lng: 18.149446 },
    { lat: 49.229366, lng: 18.149477 },
    { lat: 49.229352, lng: 18.149578 },
    { lat: 49.229417, lng: 18.149729 },
    { lat: 49.229388, lng: 18.149909 },
    { lat: 49.229337, lng: 18.150095 },
    { lat: 49.229383, lng: 18.150226 },
    { lat: 49.229467, lng: 18.15036 },
    { lat: 49.229459, lng: 18.150427 },
    { lat: 49.229396, lng: 18.15057 },
    { lat: 49.229449, lng: 18.15081 },
    { lat: 49.229502, lng: 18.150894 },
    { lat: 49.229462, lng: 18.15099 },
    { lat: 49.229501, lng: 18.151096 },
    { lat: 49.229561, lng: 18.151197 },
    { lat: 49.229691, lng: 18.151438 },
    { lat: 49.229844, lng: 18.151593 },
    { lat: 49.229905, lng: 18.15173 },
    { lat: 49.229933, lng: 18.151934 },
    { lat: 49.229948, lng: 18.152046 },
    { lat: 49.230017, lng: 18.152201 },
    { lat: 49.230102, lng: 18.152262 },
    { lat: 49.230169, lng: 18.152395 },
    { lat: 49.230172, lng: 18.152558 },
    { lat: 49.230185, lng: 18.152658 },
    { lat: 49.230237, lng: 18.152827 },
    { lat: 49.230265, lng: 18.152864 },
    { lat: 49.230264, lng: 18.152921 },
    { lat: 49.2303, lng: 18.152992 },
    { lat: 49.230348, lng: 18.153265 },
    { lat: 49.23033, lng: 18.153392 },
    { lat: 49.230223, lng: 18.153716 },
    { lat: 49.2301701, lng: 18.1537292 },
    { lat: 49.230127, lng: 18.15374 },
    { lat: 49.230033, lng: 18.15389 },
    { lat: 49.229949, lng: 18.154037 },
    { lat: 49.229963, lng: 18.154172 },
    { lat: 49.229963, lng: 18.154373 },
    { lat: 49.229922, lng: 18.154651 },
    { lat: 49.229914, lng: 18.154893 },
    { lat: 49.229854, lng: 18.15501 },
    { lat: 49.229841, lng: 18.155126 },
    { lat: 49.229934, lng: 18.155278 },
    { lat: 49.229913, lng: 18.155327 },
    { lat: 49.229879, lng: 18.155333 },
    { lat: 49.229854, lng: 18.155462 },
    { lat: 49.229705, lng: 18.155588 },
    { lat: 49.229629, lng: 18.155713 },
    { lat: 49.229704, lng: 18.155957 },
    { lat: 49.229691, lng: 18.156121 },
    { lat: 49.229581, lng: 18.156175 },
    { lat: 49.22954, lng: 18.156272 },
    { lat: 49.229468, lng: 18.156296 },
    { lat: 49.228743, lng: 18.155967 },
    { lat: 49.228427, lng: 18.155877 },
    { lat: 49.228298, lng: 18.155843 },
    { lat: 49.227981, lng: 18.155694 },
    { lat: 49.227664, lng: 18.155584 },
    { lat: 49.227296, lng: 18.155402 },
    { lat: 49.226737, lng: 18.155235 },
    { lat: 49.226412, lng: 18.155175 },
    { lat: 49.226161, lng: 18.155089 },
    { lat: 49.225786, lng: 18.154933 },
    { lat: 49.225543, lng: 18.154868 },
    { lat: 49.225317, lng: 18.154756 },
    { lat: 49.225028, lng: 18.154631 },
    { lat: 49.224912, lng: 18.154522 },
    { lat: 49.224751, lng: 18.15437 },
    { lat: 49.224406, lng: 18.154463 },
    { lat: 49.223282, lng: 18.154103 },
    { lat: 49.223073, lng: 18.154097 },
    { lat: 49.222907, lng: 18.154006 },
    { lat: 49.222463, lng: 18.153851 },
    { lat: 49.222258, lng: 18.153798 },
    { lat: 49.222032, lng: 18.153688 },
    { lat: 49.221854, lng: 18.15367 },
    { lat: 49.221784, lng: 18.15363 },
    { lat: 49.221497, lng: 18.153529 },
    { lat: 49.221084, lng: 18.153298 },
    { lat: 49.220943, lng: 18.153217 },
    { lat: 49.22062, lng: 18.1530972 },
  ],
  Lúky: [
    { lat: 49.1743591, lng: 18.2094414 },
    { lat: 49.1744362, lng: 18.2097058 },
    { lat: 49.1746769, lng: 18.2101893 },
    { lat: 49.1747715, lng: 18.2103455 },
    { lat: 49.17489, lng: 18.2105036 },
    { lat: 49.1751104, lng: 18.210708 },
    { lat: 49.1753125, lng: 18.2108907 },
    { lat: 49.1754368, lng: 18.2110317 },
    { lat: 49.1756596, lng: 18.2113576 },
    { lat: 49.1757586, lng: 18.2115124 },
    { lat: 49.1759734, lng: 18.2117364 },
    { lat: 49.1760385, lng: 18.2118515 },
    { lat: 49.1765183, lng: 18.2119569 },
    { lat: 49.1765866, lng: 18.2120444 },
    { lat: 49.1769749, lng: 18.2123637 },
    { lat: 49.1771141, lng: 18.2127897 },
    { lat: 49.1772343, lng: 18.2135544 },
    { lat: 49.1775783, lng: 18.2132123 },
    { lat: 49.1776071, lng: 18.2137008 },
    { lat: 49.1776864, lng: 18.2141037 },
    { lat: 49.1777497, lng: 18.2142652 },
    { lat: 49.1779134, lng: 18.2145039 },
    { lat: 49.177991, lng: 18.2146379 },
    { lat: 49.178016, lng: 18.2146819 },
    { lat: 49.178116, lng: 18.2149712 },
    { lat: 49.1782292, lng: 18.2148589 },
    { lat: 49.1784162, lng: 18.2150707 },
    { lat: 49.1785009, lng: 18.2151715 },
    { lat: 49.1786597, lng: 18.2153449 },
    { lat: 49.1787385, lng: 18.2154021 },
    { lat: 49.1790195, lng: 18.2156029 },
    { lat: 49.1790311, lng: 18.2158654 },
    { lat: 49.179036, lng: 18.2165485 },
    { lat: 49.1790375, lng: 18.2166924 },
    { lat: 49.1790483, lng: 18.2173197 },
    { lat: 49.1791029, lng: 18.2179646 },
    { lat: 49.1791335, lng: 18.2183215 },
    { lat: 49.1793609, lng: 18.218631 },
    { lat: 49.1795646, lng: 18.2188201 },
    { lat: 49.1797191, lng: 18.2191185 },
    { lat: 49.1797111, lng: 18.2191751 },
    { lat: 49.1796656, lng: 18.2195654 },
    { lat: 49.1796458, lng: 18.2197552 },
    { lat: 49.1796842, lng: 18.2202521 },
    { lat: 49.1795171, lng: 18.2207643 },
    { lat: 49.1791954, lng: 18.2213391 },
    { lat: 49.1790914, lng: 18.2215339 },
    { lat: 49.1789753, lng: 18.2218518 },
    { lat: 49.1788374, lng: 18.2217895 },
    { lat: 49.178781, lng: 18.2221291 },
    { lat: 49.1788152, lng: 18.2227699 },
    { lat: 49.1791154, lng: 18.2235864 },
    { lat: 49.1792501, lng: 18.2241691 },
    { lat: 49.1795912, lng: 18.2246336 },
    { lat: 49.1799045, lng: 18.2247666 },
    { lat: 49.179899, lng: 18.2248086 },
    { lat: 49.1798621, lng: 18.2250519 },
    { lat: 49.1798173, lng: 18.2252767 },
    { lat: 49.1795675, lng: 18.2255658 },
    { lat: 49.179711, lng: 18.2260077 },
    { lat: 49.1798338, lng: 18.2264217 },
    { lat: 49.1792647, lng: 18.2267533 },
    { lat: 49.1794414, lng: 18.2271631 },
    { lat: 49.1793676, lng: 18.2273333 },
    { lat: 49.1792369, lng: 18.227774 },
    { lat: 49.1792789, lng: 18.228018 },
    { lat: 49.1793699, lng: 18.2282303 },
    { lat: 49.1793896, lng: 18.2282771 },
    { lat: 49.1793648, lng: 18.2283183 },
    { lat: 49.1792901, lng: 18.2286311 },
    { lat: 49.1792195, lng: 18.22908 },
    { lat: 49.1793778, lng: 18.2293234 },
    { lat: 49.1794111, lng: 18.2293526 },
    { lat: 49.1795042, lng: 18.2294225 },
    { lat: 49.1795833, lng: 18.2294665 },
    { lat: 49.179704, lng: 18.2295437 },
    { lat: 49.1800149, lng: 18.2297342 },
    { lat: 49.1800751, lng: 18.2301181 },
    { lat: 49.1806194, lng: 18.2303465 },
    { lat: 49.1807555, lng: 18.2307566 },
    { lat: 49.1807909, lng: 18.2309827 },
    { lat: 49.1807968, lng: 18.2313862 },
    { lat: 49.1810087, lng: 18.2319974 },
    { lat: 49.1812416, lng: 18.2330612 },
    { lat: 49.1813932, lng: 18.2335472 },
    { lat: 49.1817686, lng: 18.2342774 },
    { lat: 49.1818228, lng: 18.2342389 },
    { lat: 49.1822298, lng: 18.2339928 },
    { lat: 49.1823444, lng: 18.2340453 },
    { lat: 49.1825712, lng: 18.2337287 },
    { lat: 49.1828129, lng: 18.233354 },
    { lat: 49.1828808, lng: 18.233277 },
    { lat: 49.1832092, lng: 18.2328997 },
    { lat: 49.18399, lng: 18.2336384 },
    { lat: 49.1847853, lng: 18.2343729 },
    { lat: 49.1855478, lng: 18.2350071 },
    { lat: 49.1857326, lng: 18.23526 },
    { lat: 49.1858646, lng: 18.2356403 },
    { lat: 49.1858859, lng: 18.2358368 },
    { lat: 49.1859156, lng: 18.236103 },
    { lat: 49.1860803, lng: 18.236201 },
    { lat: 49.1861583, lng: 18.2362432 },
    { lat: 49.1862678, lng: 18.2363062 },
    { lat: 49.1863753, lng: 18.2363677 },
    { lat: 49.186442, lng: 18.2363925 },
    { lat: 49.1864582, lng: 18.2363983 },
    { lat: 49.1865176, lng: 18.2364123 },
    { lat: 49.1864756, lng: 18.2368851 },
    { lat: 49.1865285, lng: 18.2383369 },
    { lat: 49.1865701, lng: 18.2390129 },
    { lat: 49.1866884, lng: 18.2394863 },
    { lat: 49.1866956, lng: 18.2395345 },
    { lat: 49.1867183, lng: 18.2396769 },
    { lat: 49.1867342, lng: 18.239778 },
    { lat: 49.1867039, lng: 18.2400395 },
    { lat: 49.1866859, lng: 18.2403567 },
    { lat: 49.1866571, lng: 18.240688 },
    { lat: 49.186634, lng: 18.2408765 },
    { lat: 49.1866091, lng: 18.2409761 },
    { lat: 49.1866895, lng: 18.2409592 },
    { lat: 49.1867483, lng: 18.2409624 },
    { lat: 49.1867597, lng: 18.2409633 },
    { lat: 49.186763, lng: 18.2409632 },
    { lat: 49.1868028, lng: 18.2409682 },
    { lat: 49.1868323, lng: 18.2409717 },
    { lat: 49.1868504, lng: 18.2409731 },
    { lat: 49.1868549, lng: 18.240974 },
    { lat: 49.1868606, lng: 18.2409743 },
    { lat: 49.1868732, lng: 18.2409754 },
    { lat: 49.1869038, lng: 18.2409782 },
    { lat: 49.186915, lng: 18.2409789 },
    { lat: 49.1870191, lng: 18.2409844 },
    { lat: 49.1873099, lng: 18.2412181 },
    { lat: 49.1874539, lng: 18.2413275 },
    { lat: 49.1875749, lng: 18.2413924 },
    { lat: 49.1879047, lng: 18.2414393 },
    { lat: 49.1880269, lng: 18.241467 },
    { lat: 49.1881559, lng: 18.2415328 },
    { lat: 49.1882924, lng: 18.2416517 },
    { lat: 49.1883269, lng: 18.2417052 },
    { lat: 49.1884499, lng: 18.2418816 },
    { lat: 49.1884951, lng: 18.2418244 },
    { lat: 49.188516, lng: 18.2417961 },
    { lat: 49.1885329, lng: 18.2417731 },
    { lat: 49.1885576, lng: 18.2417336 },
    { lat: 49.1885804, lng: 18.2416981 },
    { lat: 49.1886564, lng: 18.2417047 },
    { lat: 49.1887264, lng: 18.2417045 },
    { lat: 49.188783, lng: 18.2416687 },
    { lat: 49.1888082, lng: 18.2415949 },
    { lat: 49.1889167, lng: 18.2412772 },
    { lat: 49.1890065, lng: 18.2410136 },
    { lat: 49.1890595, lng: 18.2409145 },
    { lat: 49.1891632, lng: 18.2408505 },
    { lat: 49.1892715, lng: 18.2407717 },
    { lat: 49.1894354, lng: 18.2405961 },
    { lat: 49.1895497, lng: 18.2404024 },
    { lat: 49.189618, lng: 18.2403566 },
    { lat: 49.1903067, lng: 18.2400848 },
    { lat: 49.1907526, lng: 18.2398882 },
    { lat: 49.1910444, lng: 18.2397594 },
    { lat: 49.1912534, lng: 18.2396675 },
    { lat: 49.1913426, lng: 18.2396487 },
    { lat: 49.1916258, lng: 18.239589 },
    { lat: 49.1919291, lng: 18.2395257 },
    { lat: 49.1922601, lng: 18.2394563 },
    { lat: 49.1923048, lng: 18.2394842 },
    { lat: 49.1925363, lng: 18.2396279 },
    { lat: 49.1927276, lng: 18.2393404 },
    { lat: 49.1928057, lng: 18.2392241 },
    { lat: 49.193182, lng: 18.2392574 },
    { lat: 49.1935585, lng: 18.2392911 },
    { lat: 49.1935773, lng: 18.2394658 },
    { lat: 49.1940155, lng: 18.2394121 },
    { lat: 49.1943563, lng: 18.2395321 },
    { lat: 49.1944517, lng: 18.2396016 },
    { lat: 49.1946471, lng: 18.2397443 },
    { lat: 49.1949124, lng: 18.2399077 },
    { lat: 49.1959215, lng: 18.2402356 },
    { lat: 49.1959423, lng: 18.2402424 },
    { lat: 49.1960975, lng: 18.2402739 },
    { lat: 49.1964705, lng: 18.2405653 },
    { lat: 49.1967715, lng: 18.2407992 },
    { lat: 49.1968034, lng: 18.2408248 },
    { lat: 49.1968482, lng: 18.2408597 },
    { lat: 49.1972874, lng: 18.2407249 },
    { lat: 49.1975287, lng: 18.2406643 },
    { lat: 49.1975944, lng: 18.2406478 },
    { lat: 49.1976843, lng: 18.2406246 },
    { lat: 49.1976855, lng: 18.2406245 },
    { lat: 49.1978769, lng: 18.2405762 },
    { lat: 49.1985614, lng: 18.2402729 },
    { lat: 49.1986298, lng: 18.2402648 },
    { lat: 49.1987979, lng: 18.2402448 },
    { lat: 49.199272, lng: 18.2401873 },
    { lat: 49.1993845, lng: 18.2401445 },
    { lat: 49.1994541, lng: 18.2401177 },
    { lat: 49.1996154, lng: 18.240056 },
    { lat: 49.1997855, lng: 18.2399914 },
    { lat: 49.1998704, lng: 18.2399587 },
    { lat: 49.2002563, lng: 18.2397383 },
    { lat: 49.2004924, lng: 18.2396032 },
    { lat: 49.2005524, lng: 18.2395686 },
    { lat: 49.2007456, lng: 18.2395304 },
    { lat: 49.201002, lng: 18.2394794 },
    { lat: 49.2010236, lng: 18.2394809 },
    { lat: 49.2010848, lng: 18.2394847 },
    { lat: 49.2011707, lng: 18.2394902 },
    { lat: 49.2013055, lng: 18.2394986 },
    { lat: 49.2014085, lng: 18.2395054 },
    { lat: 49.2015874, lng: 18.2395166 },
    { lat: 49.2016927, lng: 18.2395229 },
    { lat: 49.2019737, lng: 18.2397626 },
    { lat: 49.2021541, lng: 18.2393409 },
    { lat: 49.2021697, lng: 18.2393004 },
    { lat: 49.2022298, lng: 18.2391635 },
    { lat: 49.2023104, lng: 18.2390116 },
    { lat: 49.2023703, lng: 18.2388969 },
    { lat: 49.2024107, lng: 18.2389127 },
    { lat: 49.2027408, lng: 18.2390427 },
    { lat: 49.2032434, lng: 18.2391936 },
    { lat: 49.2032906, lng: 18.2392082 },
    { lat: 49.2033838, lng: 18.2392596 },
    { lat: 49.2035284, lng: 18.2393404 },
    { lat: 49.2036785, lng: 18.2394238 },
    { lat: 49.2039032, lng: 18.2387705 },
    { lat: 49.2042971, lng: 18.2378351 },
    { lat: 49.2046912, lng: 18.2380015 },
    { lat: 49.2047386, lng: 18.2378992 },
    { lat: 49.2049783, lng: 18.2379108 },
    { lat: 49.2050725, lng: 18.2378419 },
    { lat: 49.205285, lng: 18.2376874 },
    { lat: 49.2054149, lng: 18.2379316 },
    { lat: 49.205538, lng: 18.2383677 },
    { lat: 49.2057275, lng: 18.2388885 },
    { lat: 49.206078, lng: 18.2388993 },
    { lat: 49.2062647, lng: 18.2385499 },
    { lat: 49.2063, lng: 18.2381389 },
    { lat: 49.2063601, lng: 18.2379257 },
    { lat: 49.2065015, lng: 18.2381521 },
    { lat: 49.2066876, lng: 18.2384494 },
    { lat: 49.2067808, lng: 18.2385206 },
    { lat: 49.2068884, lng: 18.2386473 },
    { lat: 49.2070866, lng: 18.2388788 },
    { lat: 49.2072624, lng: 18.2389143 },
    { lat: 49.2074323, lng: 18.2390667 },
    { lat: 49.2075486, lng: 18.2391672 },
    { lat: 49.2076537, lng: 18.2394106 },
    { lat: 49.2082122, lng: 18.2386947 },
    { lat: 49.2081992, lng: 18.2385235 },
    { lat: 49.2081436, lng: 18.2383977 },
    { lat: 49.2079916, lng: 18.238184 },
    { lat: 49.2079086, lng: 18.238093 },
    { lat: 49.2078242, lng: 18.2380597 },
    { lat: 49.2077631, lng: 18.2380358 },
    { lat: 49.2075017, lng: 18.237841 },
    { lat: 49.2074617, lng: 18.2378118 },
    { lat: 49.2068511, lng: 18.2370696 },
    { lat: 49.2067166, lng: 18.2369066 },
    { lat: 49.2063989, lng: 18.2366584 },
    { lat: 49.2063672, lng: 18.2363779 },
    { lat: 49.206363, lng: 18.2363411 },
    { lat: 49.20628, lng: 18.2356352 },
    { lat: 49.2062538, lng: 18.2354101 },
    { lat: 49.2062064, lng: 18.234732 },
    { lat: 49.2061386, lng: 18.2339522 },
    { lat: 49.2061116, lng: 18.2338564 },
    { lat: 49.2061047, lng: 18.2338344 },
    { lat: 49.2060962, lng: 18.2338012 },
    { lat: 49.2060057, lng: 18.2334803 },
    { lat: 49.2060044, lng: 18.2334779 },
    { lat: 49.2060032, lng: 18.2334747 },
    { lat: 49.2059916, lng: 18.2334315 },
    { lat: 49.2059564, lng: 18.2333076 },
    { lat: 49.2058504, lng: 18.2331679 },
    { lat: 49.2057285, lng: 18.2330079 },
    { lat: 49.2056323, lng: 18.2328826 },
    { lat: 49.2056274, lng: 18.2328762 },
    { lat: 49.205203, lng: 18.2327962 },
    { lat: 49.205202, lng: 18.2327574 },
    { lat: 49.2051746, lng: 18.2316774 },
    { lat: 49.2051673, lng: 18.2313697 },
    { lat: 49.2053032, lng: 18.2313182 },
    { lat: 49.205265, lng: 18.2309038 },
    { lat: 49.2051123, lng: 18.2305371 },
    { lat: 49.2050431, lng: 18.2303714 },
    { lat: 49.204938, lng: 18.22983 },
    { lat: 49.2048315, lng: 18.2293073 },
    { lat: 49.2043939, lng: 18.227557 },
    { lat: 49.2043924, lng: 18.2275508 },
    { lat: 49.2043916, lng: 18.2275363 },
    { lat: 49.2041853, lng: 18.227601 },
    { lat: 49.2040167, lng: 18.2276715 },
    { lat: 49.2040207, lng: 18.2276219 },
    { lat: 49.2037846, lng: 18.2274975 },
    { lat: 49.203595, lng: 18.2273763 },
    { lat: 49.2033906, lng: 18.2271963 },
    { lat: 49.2033975, lng: 18.2270583 },
    { lat: 49.2033653, lng: 18.2269655 },
    { lat: 49.2033095, lng: 18.2268996 },
    { lat: 49.2033042, lng: 18.2268228 },
    { lat: 49.2032073, lng: 18.2264488 },
    { lat: 49.2031968, lng: 18.2263639 },
    { lat: 49.2032304, lng: 18.2262798 },
    { lat: 49.2032598, lng: 18.2260814 },
    { lat: 49.2032427, lng: 18.2259597 },
    { lat: 49.2032607, lng: 18.22596 },
    { lat: 49.2032466, lng: 18.2257905 },
    { lat: 49.2032311, lng: 18.2257125 },
    { lat: 49.2031506, lng: 18.2254274 },
    { lat: 49.2031532, lng: 18.2252952 },
    { lat: 49.203114, lng: 18.225182 },
    { lat: 49.2031044, lng: 18.2249345 },
    { lat: 49.2031397, lng: 18.2248407 },
    { lat: 49.2031386, lng: 18.2246783 },
    { lat: 49.2031122, lng: 18.2245137 },
    { lat: 49.2030663, lng: 18.224221 },
    { lat: 49.2030218, lng: 18.2240746 },
    { lat: 49.2029894, lng: 18.2238819 },
    { lat: 49.2029497, lng: 18.2237991 },
    { lat: 49.2028821, lng: 18.2237589 },
    { lat: 49.202747, lng: 18.2236861 },
    { lat: 49.2026547, lng: 18.2234094 },
    { lat: 49.2025463, lng: 18.2232107 },
    { lat: 49.202479, lng: 18.2231963 },
    { lat: 49.2023408, lng: 18.2232065 },
    { lat: 49.2022957, lng: 18.2230907 },
    { lat: 49.2022555, lng: 18.223079 },
    { lat: 49.2021221, lng: 18.2227355 },
    { lat: 49.2017804, lng: 18.2223976 },
    { lat: 49.2016163, lng: 18.2221926 },
    { lat: 49.2014901, lng: 18.222293 },
    { lat: 49.2014297, lng: 18.2222465 },
    { lat: 49.2013449, lng: 18.2221229 },
    { lat: 49.2012887, lng: 18.2219853 },
    { lat: 49.2012756, lng: 18.2217752 },
    { lat: 49.2011925, lng: 18.2215818 },
    { lat: 49.2010343, lng: 18.2213606 },
    { lat: 49.2010067, lng: 18.2212503 },
    { lat: 49.2009789, lng: 18.2210384 },
    { lat: 49.2009503, lng: 18.2210304 },
    { lat: 49.2009378, lng: 18.2209733 },
    { lat: 49.2009287, lng: 18.2209287 },
    { lat: 49.2008052, lng: 18.2207621 },
    { lat: 49.2007507, lng: 18.2206394 },
    { lat: 49.2007329, lng: 18.2205625 },
    { lat: 49.2006399, lng: 18.2204534 },
    { lat: 49.2004709, lng: 18.2203001 },
    { lat: 49.200396, lng: 18.2201546 },
    { lat: 49.2003434, lng: 18.219902 },
    { lat: 49.2001256, lng: 18.2196808 },
    { lat: 49.2000739, lng: 18.2196283 },
    { lat: 49.1999353, lng: 18.2192326 },
    { lat: 49.1998863, lng: 18.2191429 },
    { lat: 49.1998759, lng: 18.2191023 },
    { lat: 49.1998686, lng: 18.2190726 },
    { lat: 49.199849, lng: 18.2190236 },
    { lat: 49.1998268, lng: 18.2189695 },
    { lat: 49.1996575, lng: 18.2190074 },
    { lat: 49.1995062, lng: 18.2190285 },
    { lat: 49.1992945, lng: 18.2189939 },
    { lat: 49.1989909, lng: 18.2188343 },
    { lat: 49.1986345, lng: 18.2185203 },
    { lat: 49.1986542, lng: 18.2184674 },
    { lat: 49.1986698, lng: 18.2184258 },
    { lat: 49.1987574, lng: 18.2180612 },
    { lat: 49.198782, lng: 18.2179582 },
    { lat: 49.198783, lng: 18.2179545 },
    { lat: 49.198796, lng: 18.2179051 },
    { lat: 49.1987966, lng: 18.2178989 },
    { lat: 49.198857, lng: 18.2176293 },
    { lat: 49.1989976, lng: 18.2170257 },
    { lat: 49.1991423, lng: 18.2163963 },
    { lat: 49.1991584, lng: 18.2163226 },
    { lat: 49.1992116, lng: 18.216065 },
    { lat: 49.1993303, lng: 18.2161315 },
    { lat: 49.1994331, lng: 18.2161893 },
    { lat: 49.199842, lng: 18.2164203 },
    { lat: 49.1998982, lng: 18.2162768 },
    { lat: 49.1999563, lng: 18.2161259 },
    { lat: 49.1998896, lng: 18.2158677 },
    { lat: 49.1997356, lng: 18.2154999 },
    { lat: 49.199637, lng: 18.2152925 },
    { lat: 49.1996205, lng: 18.2152388 },
    { lat: 49.1994447, lng: 18.2149292 },
    { lat: 49.1995115, lng: 18.2145309 },
    { lat: 49.1996076, lng: 18.2142371 },
    { lat: 49.1997648, lng: 18.2139085 },
    { lat: 49.2001951, lng: 18.2132592 },
    { lat: 49.2002397, lng: 18.2131914 },
    { lat: 49.2003766, lng: 18.212859 },
    { lat: 49.2004842, lng: 18.2126304 },
    { lat: 49.2007446, lng: 18.2123286 },
    { lat: 49.2009912, lng: 18.2120425 },
    { lat: 49.2012199, lng: 18.2116604 },
    { lat: 49.201289, lng: 18.2115456 },
    { lat: 49.2014738, lng: 18.2112821 },
    { lat: 49.2016127, lng: 18.2111255 },
    { lat: 49.2020757, lng: 18.2105778 },
    { lat: 49.2027344, lng: 18.2099374 },
    { lat: 49.2031143, lng: 18.2098909 },
    { lat: 49.2035292, lng: 18.2094116 },
    { lat: 49.2038495, lng: 18.2091336 },
    { lat: 49.204315, lng: 18.2088451 },
    { lat: 49.2047304, lng: 18.2086907 },
    { lat: 49.2047027, lng: 18.2083415 },
    { lat: 49.204357, lng: 18.208432 },
    { lat: 49.2043338, lng: 18.2082817 },
    { lat: 49.204286, lng: 18.2079788 },
    { lat: 49.204326, lng: 18.2077675 },
    { lat: 49.2045839, lng: 18.207642 },
    { lat: 49.2048364, lng: 18.2076371 },
    { lat: 49.2050485, lng: 18.2075168 },
    { lat: 49.2047464, lng: 18.2063132 },
    { lat: 49.2049886, lng: 18.2059899 },
    { lat: 49.2046413, lng: 18.2047442 },
    { lat: 49.2045348, lng: 18.2040406 },
    { lat: 49.2042755, lng: 18.2040461 },
    { lat: 49.2039653, lng: 18.2040295 },
    { lat: 49.2037981, lng: 18.2039246 },
    { lat: 49.2037595, lng: 18.2045756 },
    { lat: 49.2038136, lng: 18.2050902 },
    { lat: 49.2037044, lng: 18.2052894 },
    { lat: 49.2036321, lng: 18.2054271 },
    { lat: 49.2034682, lng: 18.2057209 },
    { lat: 49.2031498, lng: 18.2063913 },
    { lat: 49.20266, lng: 18.2067475 },
    { lat: 49.2025536, lng: 18.2068957 },
    { lat: 49.2023013, lng: 18.2072441 },
    { lat: 49.2018557, lng: 18.2075821 },
    { lat: 49.2018287, lng: 18.2076024 },
    { lat: 49.2016297, lng: 18.2076533 },
    { lat: 49.2014291, lng: 18.2074035 },
    { lat: 49.2011354, lng: 18.2070576 },
    { lat: 49.2009353, lng: 18.2065947 },
    { lat: 49.201085, lng: 18.2064495 },
    { lat: 49.2007767, lng: 18.2051952 },
    { lat: 49.2005178, lng: 18.2054643 },
    { lat: 49.200285, lng: 18.2053591 },
    { lat: 49.2001184, lng: 18.2054425 },
    { lat: 49.1998122, lng: 18.2055964 },
    { lat: 49.1996389, lng: 18.2056836 },
    { lat: 49.1994966, lng: 18.2055009 },
    { lat: 49.199128, lng: 18.2056091 },
    { lat: 49.1985559, lng: 18.2061007 },
    { lat: 49.1978056, lng: 18.2069176 },
    { lat: 49.1976707, lng: 18.2070639 },
    { lat: 49.1975883, lng: 18.2071621 },
    { lat: 49.1973771, lng: 18.207414 },
    { lat: 49.1973717, lng: 18.2074199 },
    { lat: 49.1972715, lng: 18.20754 },
    { lat: 49.1972535, lng: 18.2075611 },
    { lat: 49.197209, lng: 18.2076143 },
    { lat: 49.1971933, lng: 18.2076324 },
    { lat: 49.1971647, lng: 18.2076672 },
    { lat: 49.1971076, lng: 18.2077356 },
    { lat: 49.1971126, lng: 18.2079023 },
    { lat: 49.1971349, lng: 18.2086509 },
    { lat: 49.197111, lng: 18.2088055 },
    { lat: 49.1970361, lng: 18.2092957 },
    { lat: 49.1970285, lng: 18.2093419 },
    { lat: 49.1970221, lng: 18.2093866 },
    { lat: 49.1970072, lng: 18.2094824 },
    { lat: 49.1969918, lng: 18.2095856 },
    { lat: 49.1969281, lng: 18.2095373 },
    { lat: 49.1968964, lng: 18.2095169 },
    { lat: 49.1968495, lng: 18.2094861 },
    { lat: 49.1968072, lng: 18.2094582 },
    { lat: 49.1967765, lng: 18.2094498 },
    { lat: 49.19671, lng: 18.2094323 },
    { lat: 49.1966997, lng: 18.2094297 },
    { lat: 49.1966938, lng: 18.2094258 },
    { lat: 49.1966214, lng: 18.2094247 },
    { lat: 49.1965962, lng: 18.2094349 },
    { lat: 49.1963422, lng: 18.2095344 },
    { lat: 49.1961664, lng: 18.209615 },
    { lat: 49.1960322, lng: 18.2099146 },
    { lat: 49.1959679, lng: 18.2097153 },
    { lat: 49.1959626, lng: 18.2096803 },
    { lat: 49.1959331, lng: 18.2094821 },
    { lat: 49.1959527, lng: 18.2091323 },
    { lat: 49.1959502, lng: 18.2088099 },
    { lat: 49.1959497, lng: 18.2087436 },
    { lat: 49.1957907, lng: 18.2077313 },
    { lat: 49.1957278, lng: 18.2073617 },
    { lat: 49.1956878, lng: 18.2072115 },
    { lat: 49.1956385, lng: 18.2068591 },
    { lat: 49.1956391, lng: 18.2064971 },
    { lat: 49.1956018, lng: 18.2061366 },
    { lat: 49.1955925, lng: 18.2060523 },
    { lat: 49.1955841, lng: 18.2059665 },
    { lat: 49.1955769, lng: 18.205839 },
    { lat: 49.1955582, lng: 18.2057271 },
    { lat: 49.1955415, lng: 18.205633 },
    { lat: 49.1954354, lng: 18.205414 },
    { lat: 49.195355, lng: 18.205231 },
    { lat: 49.195291, lng: 18.2049994 },
    { lat: 49.1952621, lng: 18.2046917 },
    { lat: 49.1952364, lng: 18.2046718 },
    { lat: 49.1949252, lng: 18.2044587 },
    { lat: 49.1949018, lng: 18.2044429 },
    { lat: 49.1948488, lng: 18.2044043 },
    { lat: 49.1946823, lng: 18.2044956 },
    { lat: 49.1943375, lng: 18.2046382 },
    { lat: 49.1939952, lng: 18.2047098 },
    { lat: 49.1938703, lng: 18.2046795 },
    { lat: 49.1936892, lng: 18.2046469 },
    { lat: 49.1935585, lng: 18.2045516 },
    { lat: 49.1933884, lng: 18.2043602 },
    { lat: 49.1932998, lng: 18.204228 },
    { lat: 49.1929987, lng: 18.2037559 },
    { lat: 49.1927487, lng: 18.2034887 },
    { lat: 49.1923337, lng: 18.2030931 },
    { lat: 49.1918558, lng: 18.2026687 },
    { lat: 49.1918896, lng: 18.2024695 },
    { lat: 49.1919702, lng: 18.2020046 },
    { lat: 49.1919995, lng: 18.2018411 },
    { lat: 49.1920088, lng: 18.2017886 },
    { lat: 49.1920107, lng: 18.201781 },
    { lat: 49.1919096, lng: 18.2017489 },
    { lat: 49.1918337, lng: 18.2016454 },
    { lat: 49.1916627, lng: 18.2015952 },
    { lat: 49.1915822, lng: 18.2014888 },
    { lat: 49.1914535, lng: 18.2014093 },
    { lat: 49.1913475, lng: 18.2014687 },
    { lat: 49.191231, lng: 18.2013829 },
    { lat: 49.1910583, lng: 18.2014433 },
    { lat: 49.1909106, lng: 18.2015037 },
    { lat: 49.1907692, lng: 18.2013792 },
    { lat: 49.1905898, lng: 18.201438 },
    { lat: 49.1905379, lng: 18.2013197 },
    { lat: 49.1900592, lng: 18.2007595 },
    { lat: 49.1898815, lng: 18.2006124 },
    { lat: 49.1897451, lng: 18.2004348 },
    { lat: 49.1892771, lng: 18.2003614 },
    { lat: 49.1891337, lng: 18.2003566 },
    { lat: 49.1888529, lng: 18.2004609 },
    { lat: 49.1886066, lng: 18.2003742 },
    { lat: 49.1882503, lng: 18.2003206 },
    { lat: 49.188036, lng: 18.200182 },
    { lat: 49.1879515, lng: 18.2002221 },
    { lat: 49.1878336, lng: 18.2003737 },
    { lat: 49.1876827, lng: 18.2004769 },
    { lat: 49.1875531, lng: 18.2003793 },
    { lat: 49.187237, lng: 18.200161 },
    { lat: 49.187083, lng: 18.2001298 },
    { lat: 49.1870153, lng: 18.2001487 },
    { lat: 49.1869508, lng: 18.2001667 },
    { lat: 49.1869199, lng: 18.2001755 },
    { lat: 49.1869202, lng: 18.2001444 },
    { lat: 49.1869316, lng: 18.199494 },
    { lat: 49.1866247, lng: 18.1991952 },
    { lat: 49.1866051, lng: 18.1991699 },
    { lat: 49.1863317, lng: 18.1988864 },
    { lat: 49.1861905, lng: 18.1987657 },
    { lat: 49.1861634, lng: 18.1987426 },
    { lat: 49.1861386, lng: 18.1987214 },
    { lat: 49.1861112, lng: 18.198697 },
    { lat: 49.1859047, lng: 18.1985217 },
    { lat: 49.1856287, lng: 18.1986279 },
    { lat: 49.1853141, lng: 18.1987494 },
    { lat: 49.1852905, lng: 18.1987503 },
    { lat: 49.1844961, lng: 18.1987882 },
    { lat: 49.1841049, lng: 18.198794 },
    { lat: 49.183985, lng: 18.1980173 },
    { lat: 49.1838002, lng: 18.1979235 },
    { lat: 49.1836715, lng: 18.197861 },
    { lat: 49.1835628, lng: 18.1974018 },
    { lat: 49.1834026, lng: 18.1972828 },
    { lat: 49.1830538, lng: 18.1970199 },
    { lat: 49.1827932, lng: 18.1969037 },
    { lat: 49.1826644, lng: 18.1969217 },
    { lat: 49.1824946, lng: 18.1969502 },
    { lat: 49.1823759, lng: 18.1964509 },
    { lat: 49.1822445, lng: 18.196402 },
    { lat: 49.1815826, lng: 18.1961525 },
    { lat: 49.1811109, lng: 18.1961131 },
    { lat: 49.1808845, lng: 18.1959602 },
    { lat: 49.1808516, lng: 18.1959372 },
    { lat: 49.1801322, lng: 18.1957272 },
    { lat: 49.1797569, lng: 18.1952984 },
    { lat: 49.1792743, lng: 18.1949889 },
    { lat: 49.178991, lng: 18.1946707 },
    { lat: 49.1788956, lng: 18.1945409 },
    { lat: 49.1787459, lng: 18.1944699 },
    { lat: 49.1783094, lng: 18.1945495 },
    { lat: 49.1777201, lng: 18.1943269 },
    { lat: 49.1776289, lng: 18.1942896 },
    { lat: 49.1772454, lng: 18.1942921 },
    { lat: 49.1768457, lng: 18.194288 },
    { lat: 49.1766092, lng: 18.1943942 },
    { lat: 49.1762879, lng: 18.1945375 },
    { lat: 49.1759886, lng: 18.1945321 },
    { lat: 49.1753104, lng: 18.1944508 },
    { lat: 49.1752684, lng: 18.1944461 },
    { lat: 49.1751015, lng: 18.1944295 },
    { lat: 49.1750099, lng: 18.194464 },
    { lat: 49.174894, lng: 18.1945104 },
    { lat: 49.1747064, lng: 18.194584 },
    { lat: 49.1746127, lng: 18.1946218 },
    { lat: 49.1742836, lng: 18.1947463 },
    { lat: 49.1740516, lng: 18.1948136 },
    { lat: 49.1739827, lng: 18.1948339 },
    { lat: 49.1739504, lng: 18.1948417 },
    { lat: 49.1737075, lng: 18.1948932 },
    { lat: 49.1736905, lng: 18.1948947 },
    { lat: 49.1735732, lng: 18.1949141 },
    { lat: 49.1735461, lng: 18.195052 },
    { lat: 49.1734883, lng: 18.1955234 },
    { lat: 49.173479, lng: 18.1957168 },
    { lat: 49.1735521, lng: 18.1958105 },
    { lat: 49.1734713, lng: 18.1962527 },
    { lat: 49.1732016, lng: 18.1964705 },
    { lat: 49.1731847, lng: 18.1966302 },
    { lat: 49.1731857, lng: 18.1967272 },
    { lat: 49.1732101, lng: 18.1972123 },
    { lat: 49.1732233, lng: 18.197464 },
    { lat: 49.1732539, lng: 18.1977023 },
    { lat: 49.1732981, lng: 18.1980426 },
    { lat: 49.1733395, lng: 18.1983534 },
    { lat: 49.1735008, lng: 18.1990664 },
    { lat: 49.1735963, lng: 18.1995355 },
    { lat: 49.1736136, lng: 18.1999171 },
    { lat: 49.1735896, lng: 18.2000493 },
    { lat: 49.1735199, lng: 18.2004302 },
    { lat: 49.1736585, lng: 18.2005276 },
    { lat: 49.1735211, lng: 18.2010057 },
    { lat: 49.1736297, lng: 18.2011306 },
    { lat: 49.1735892, lng: 18.2017857 },
    { lat: 49.1736804, lng: 18.2018609 },
    { lat: 49.1737392, lng: 18.2022395 },
    { lat: 49.1736613, lng: 18.2024377 },
    { lat: 49.1734015, lng: 18.2023522 },
    { lat: 49.1733182, lng: 18.202377 },
    { lat: 49.1733384, lng: 18.202731 },
    { lat: 49.1734411, lng: 18.2028911 },
    { lat: 49.1733023, lng: 18.2036436 },
    { lat: 49.1731625, lng: 18.2040016 },
    { lat: 49.1731709, lng: 18.2044069 },
    { lat: 49.1735864, lng: 18.2053423 },
    { lat: 49.1737002, lng: 18.2054983 },
    { lat: 49.173873, lng: 18.2053816 },
    { lat: 49.1741526, lng: 18.2057142 },
    { lat: 49.1743516, lng: 18.2058613 },
    { lat: 49.1741253, lng: 18.2068394 },
    { lat: 49.1744783, lng: 18.2068779 },
    { lat: 49.1744473, lng: 18.207206 },
    { lat: 49.1743933, lng: 18.208238 },
    { lat: 49.1745648, lng: 18.2085159 },
    { lat: 49.1746512, lng: 18.2093012 },
    { lat: 49.1743888, lng: 18.2094297 },
    { lat: 49.1743591, lng: 18.2094414 },
  ],
  Mestečko: [
    { lat: 49.1920207, lng: 18.263326 },
    { lat: 49.1918871, lng: 18.2624739 },
    { lat: 49.1916555, lng: 18.2616707 },
    { lat: 49.1914101, lng: 18.260439 },
    { lat: 49.1911017, lng: 18.2600153 },
    { lat: 49.1907844, lng: 18.2594696 },
    { lat: 49.1904738, lng: 18.2590853 },
    { lat: 49.1898707, lng: 18.2588345 },
    { lat: 49.1895465, lng: 18.2587866 },
    { lat: 49.1895021, lng: 18.25878 },
    { lat: 49.1889061, lng: 18.2586925 },
    { lat: 49.1884475, lng: 18.2587043 },
    { lat: 49.187934, lng: 18.2586427 },
    { lat: 49.1873292, lng: 18.258679 },
    { lat: 49.1869599, lng: 18.2588542 },
    { lat: 49.1866016, lng: 18.2589399 },
    { lat: 49.1865331, lng: 18.2589671 },
    { lat: 49.1861977, lng: 18.2591024 },
    { lat: 49.1857797, lng: 18.2591524 },
    { lat: 49.1853187, lng: 18.2592412 },
    { lat: 49.1850072, lng: 18.2592962 },
    { lat: 49.184431, lng: 18.2595028 },
    { lat: 49.1838081, lng: 18.2597408 },
    { lat: 49.1837296, lng: 18.2597706 },
    { lat: 49.1832774, lng: 18.259872 },
    { lat: 49.1832049, lng: 18.259888 },
    { lat: 49.18301, lng: 18.2598922 },
    { lat: 49.1826257, lng: 18.2599001 },
    { lat: 49.1823002, lng: 18.2595104 },
    { lat: 49.1822477, lng: 18.2593013 },
    { lat: 49.1821253, lng: 18.2588093 },
    { lat: 49.1820769, lng: 18.2585938 },
    { lat: 49.1819477, lng: 18.2580035 },
    { lat: 49.181491, lng: 18.2579257 },
    { lat: 49.1813294, lng: 18.2579426 },
    { lat: 49.1811613, lng: 18.2579615 },
    { lat: 49.1809189, lng: 18.2580074 },
    { lat: 49.1807979, lng: 18.2579403 },
    { lat: 49.1807518, lng: 18.2579042 },
    { lat: 49.180727, lng: 18.2578851 },
    { lat: 49.1807172, lng: 18.2578518 },
    { lat: 49.1806724, lng: 18.2577174 },
    { lat: 49.1806639, lng: 18.2576867 },
    { lat: 49.1806743, lng: 18.2573114 },
    { lat: 49.1807353, lng: 18.2570917 },
    { lat: 49.1804894, lng: 18.2569343 },
    { lat: 49.1804603, lng: 18.2569163 },
    { lat: 49.1803095, lng: 18.2568238 },
    { lat: 49.1802359, lng: 18.2567985 },
    { lat: 49.1802171, lng: 18.2567865 },
    { lat: 49.1799577, lng: 18.2563697 },
    { lat: 49.1799498, lng: 18.2563088 },
    { lat: 49.1798934, lng: 18.256128 },
    { lat: 49.1798337, lng: 18.25597 },
    { lat: 49.1797834, lng: 18.2558381 },
    { lat: 49.1796907, lng: 18.2556961 },
    { lat: 49.17967, lng: 18.2556716 },
    { lat: 49.1796621, lng: 18.2556294 },
    { lat: 49.1796598, lng: 18.255608 },
    { lat: 49.1796443, lng: 18.2555147 },
    { lat: 49.1796018, lng: 18.2553223 },
    { lat: 49.1795267, lng: 18.2550913 },
    { lat: 49.1794027, lng: 18.2548915 },
    { lat: 49.1793243, lng: 18.2547617 },
    { lat: 49.1793018, lng: 18.2547229 },
    { lat: 49.1791971, lng: 18.2545881 },
    { lat: 49.1791579, lng: 18.2545543 },
    { lat: 49.1790594, lng: 18.254467 },
    { lat: 49.1789353, lng: 18.2543666 },
    { lat: 49.1788726, lng: 18.2543168 },
    { lat: 49.1788561, lng: 18.2543038 },
    { lat: 49.178765, lng: 18.2542301 },
    { lat: 49.1787107, lng: 18.254187 },
    { lat: 49.1785917, lng: 18.2540185 },
    { lat: 49.1785465, lng: 18.253957 },
    { lat: 49.1784116, lng: 18.253766 },
    { lat: 49.177948, lng: 18.2547065 },
    { lat: 49.1779461, lng: 18.2547114 },
    { lat: 49.1779256, lng: 18.2547489 },
    { lat: 49.17773, lng: 18.2551201 },
    { lat: 49.177627, lng: 18.2553131 },
    { lat: 49.1775822, lng: 18.2553986 },
    { lat: 49.1771662, lng: 18.2561832 },
    { lat: 49.1771476, lng: 18.2562138 },
    { lat: 49.1769774, lng: 18.2560176 },
    { lat: 49.1767983, lng: 18.2557842 },
    { lat: 49.1767075, lng: 18.2556542 },
    { lat: 49.1766367, lng: 18.2555997 },
    { lat: 49.1765756, lng: 18.2555773 },
    { lat: 49.1765619, lng: 18.2555717 },
    { lat: 49.1764985, lng: 18.255549 },
    { lat: 49.1764732, lng: 18.2555396 },
    { lat: 49.1764165, lng: 18.2555349 },
    { lat: 49.1764051, lng: 18.2555346 },
    { lat: 49.1763802, lng: 18.2555321 },
    { lat: 49.1763258, lng: 18.2555276 },
    { lat: 49.1762155, lng: 18.2555713 },
    { lat: 49.1761502, lng: 18.2555966 },
    { lat: 49.1761084, lng: 18.2556133 },
    { lat: 49.1759185, lng: 18.2556876 },
    { lat: 49.1757861, lng: 18.25574 },
    { lat: 49.1756736, lng: 18.2557844 },
    { lat: 49.1755223, lng: 18.2558439 },
    { lat: 49.1751603, lng: 18.2559867 },
    { lat: 49.1750466, lng: 18.2560312 },
    { lat: 49.1747663, lng: 18.2561416 },
    { lat: 49.1745952, lng: 18.2561883 },
    { lat: 49.174593, lng: 18.2561893 },
    { lat: 49.1740997, lng: 18.256345 },
    { lat: 49.1740931, lng: 18.2563471 },
    { lat: 49.1740754, lng: 18.2563529 },
    { lat: 49.1736675, lng: 18.2564813 },
    { lat: 49.1734838, lng: 18.2563068 },
    { lat: 49.1734159, lng: 18.256243 },
    { lat: 49.1735878, lng: 18.2556118 },
    { lat: 49.1732372, lng: 18.2555369 },
    { lat: 49.1731226, lng: 18.2554663 },
    { lat: 49.1730034, lng: 18.2553916 },
    { lat: 49.1729232, lng: 18.2557305 },
    { lat: 49.172824, lng: 18.255931 },
    { lat: 49.1727245, lng: 18.2560676 },
    { lat: 49.1726281, lng: 18.2561576 },
    { lat: 49.1725069, lng: 18.2562312 },
    { lat: 49.172492, lng: 18.2561838 },
    { lat: 49.1723222, lng: 18.255636 },
    { lat: 49.1724972, lng: 18.2551591 },
    { lat: 49.1725409, lng: 18.2546945 },
    { lat: 49.1722661, lng: 18.2547427 },
    { lat: 49.1717653, lng: 18.2548251 },
    { lat: 49.1713468, lng: 18.2546663 },
    { lat: 49.171199, lng: 18.2545065 },
    { lat: 49.1708849, lng: 18.2540798 },
    { lat: 49.1707365, lng: 18.2538484 },
    { lat: 49.1704241, lng: 18.2536929 },
    { lat: 49.1700031, lng: 18.2534699 },
    { lat: 49.1699677, lng: 18.2533044 },
    { lat: 49.1699343, lng: 18.2531505 },
    { lat: 49.1697692, lng: 18.2532443 },
    { lat: 49.1694334, lng: 18.2534313 },
    { lat: 49.1691271, lng: 18.2536222 },
    { lat: 49.1683639, lng: 18.2540519 },
    { lat: 49.1683226, lng: 18.2538978 },
    { lat: 49.1693913, lng: 18.2532459 },
    { lat: 49.1699462, lng: 18.2529239 },
    { lat: 49.1693366, lng: 18.2521799 },
    { lat: 49.168545, lng: 18.2518055 },
    { lat: 49.1684467, lng: 18.2518447 },
    { lat: 49.1680874, lng: 18.2519947 },
    { lat: 49.1677706, lng: 18.2519182 },
    { lat: 49.1676722, lng: 18.251895 },
    { lat: 49.167283, lng: 18.2516558 },
    { lat: 49.1670974, lng: 18.2516634 },
    { lat: 49.1667871, lng: 18.2514647 },
    { lat: 49.166113, lng: 18.2508569 },
    { lat: 49.1658043, lng: 18.2505255 },
    { lat: 49.1656196, lng: 18.250412 },
    { lat: 49.1655789, lng: 18.2503896 },
    { lat: 49.1650574, lng: 18.2500663 },
    { lat: 49.1648238, lng: 18.2505443 },
    { lat: 49.1648609, lng: 18.2506824 },
    { lat: 49.164881, lng: 18.2507574 },
    { lat: 49.1646448, lng: 18.2513696 },
    { lat: 49.1645975, lng: 18.2514914 },
    { lat: 49.1644413, lng: 18.2518811 },
    { lat: 49.1642972, lng: 18.2522442 },
    { lat: 49.16414, lng: 18.2526382 },
    { lat: 49.1640994, lng: 18.2527376 },
    { lat: 49.1644686, lng: 18.253062 },
    { lat: 49.1644086, lng: 18.2533362 },
    { lat: 49.164349, lng: 18.2536211 },
    { lat: 49.1640021, lng: 18.2536906 },
    { lat: 49.1638154, lng: 18.2536633 },
    { lat: 49.1636785, lng: 18.2535962 },
    { lat: 49.1634916, lng: 18.2534437 },
    { lat: 49.1631673, lng: 18.2530925 },
    { lat: 49.1630146, lng: 18.252846 },
    { lat: 49.1633799, lng: 18.2522633 },
    { lat: 49.1635522, lng: 18.2520002 },
    { lat: 49.1635179, lng: 18.2519113 },
    { lat: 49.1633007, lng: 18.2514024 },
    { lat: 49.1633231, lng: 18.2513601 },
    { lat: 49.1634897, lng: 18.2510552 },
    { lat: 49.1636016, lng: 18.250778 },
    { lat: 49.1636583, lng: 18.2506628 },
    { lat: 49.1637548, lng: 18.2504754 },
    { lat: 49.1641716, lng: 18.2497062 },
    { lat: 49.164128, lng: 18.2494329 },
    { lat: 49.1641001, lng: 18.2492394 },
    { lat: 49.1641455, lng: 18.2492035 },
    { lat: 49.1641884, lng: 18.2491659 },
    { lat: 49.1642337, lng: 18.2491274 },
    { lat: 49.1645116, lng: 18.2494326 },
    { lat: 49.1645717, lng: 18.2493405 },
    { lat: 49.1647127, lng: 18.2491202 },
    { lat: 49.1650562, lng: 18.2483502 },
    { lat: 49.1651104, lng: 18.2482297 },
    { lat: 49.1649857, lng: 18.2479396 },
    { lat: 49.1649803, lng: 18.2477467 },
    { lat: 49.1647936, lng: 18.2476163 },
    { lat: 49.1642431, lng: 18.2474598 },
    { lat: 49.1641198, lng: 18.2472743 },
    { lat: 49.1639732, lng: 18.2472936 },
    { lat: 49.1638937, lng: 18.2471061 },
    { lat: 49.163847, lng: 18.2471781 },
    { lat: 49.1636602, lng: 18.2474865 },
    { lat: 49.1634732, lng: 18.2479915 },
    { lat: 49.1632367, lng: 18.2476993 },
    { lat: 49.1629855, lng: 18.2473877 },
    { lat: 49.1628052, lng: 18.2471122 },
    { lat: 49.1627526, lng: 18.2469772 },
    { lat: 49.1627014, lng: 18.2468518 },
    { lat: 49.1625937, lng: 18.2465852 },
    { lat: 49.1622462, lng: 18.245925 },
    { lat: 49.1622647, lng: 18.2458144 },
    { lat: 49.162284, lng: 18.2456973 },
    { lat: 49.1623119, lng: 18.2455315 },
    { lat: 49.162247, lng: 18.2450442 },
    { lat: 49.1621391, lng: 18.2445933 },
    { lat: 49.1620966, lng: 18.2441998 },
    { lat: 49.1621609, lng: 18.2440803 },
    { lat: 49.1623356, lng: 18.2437594 },
    { lat: 49.162505, lng: 18.243322 },
    { lat: 49.162742, lng: 18.2431265 },
    { lat: 49.1629917, lng: 18.242972 },
    { lat: 49.1632319, lng: 18.2427502 },
    { lat: 49.1634154, lng: 18.2425449 },
    { lat: 49.1632198, lng: 18.2424572 },
    { lat: 49.162928, lng: 18.2423257 },
    { lat: 49.1623904, lng: 18.2419956 },
    { lat: 49.1623149, lng: 18.2419596 },
    { lat: 49.1623731, lng: 18.2417105 },
    { lat: 49.162504, lng: 18.2411136 },
    { lat: 49.1626797, lng: 18.2406904 },
    { lat: 49.1629243, lng: 18.2404066 },
    { lat: 49.1631253, lng: 18.2401105 },
    { lat: 49.1631597, lng: 18.2400416 },
    { lat: 49.1633819, lng: 18.2395613 },
    { lat: 49.1635018, lng: 18.2389512 },
    { lat: 49.163667, lng: 18.2386197 },
    { lat: 49.1632641, lng: 18.2383007 },
    { lat: 49.1631774, lng: 18.2381853 },
    { lat: 49.1626371, lng: 18.2374087 },
    { lat: 49.1622569, lng: 18.2368924 },
    { lat: 49.1618483, lng: 18.23635 },
    { lat: 49.1616603, lng: 18.2360997 },
    { lat: 49.1614541, lng: 18.235668 },
    { lat: 49.1607265, lng: 18.2365853 },
    { lat: 49.1606549, lng: 18.2361181 },
    { lat: 49.1605007, lng: 18.235945 },
    { lat: 49.1602213, lng: 18.2356347 },
    { lat: 49.1598912, lng: 18.2356816 },
    { lat: 49.1598526, lng: 18.2356579 },
    { lat: 49.159711, lng: 18.2355686 },
    { lat: 49.1596783, lng: 18.2355484 },
    { lat: 49.1593635, lng: 18.2353512 },
    { lat: 49.1591007, lng: 18.2351308 },
    { lat: 49.1587063, lng: 18.2350006 },
    { lat: 49.1584654, lng: 18.2354689 },
    { lat: 49.1581707, lng: 18.2358048 },
    { lat: 49.1576358, lng: 18.2361979 },
    { lat: 49.1569101, lng: 18.2372102 },
    { lat: 49.1565578, lng: 18.2379612 },
    { lat: 49.1563943, lng: 18.2385406 },
    { lat: 49.15605, lng: 18.2397715 },
    { lat: 49.1557547, lng: 18.2397383 },
    { lat: 49.1557152, lng: 18.2397377 },
    { lat: 49.1552376, lng: 18.2411855 },
    { lat: 49.1551918, lng: 18.2414643 },
    { lat: 49.1551252, lng: 18.241869 },
    { lat: 49.1548863, lng: 18.2425929 },
    { lat: 49.1549709, lng: 18.2436678 },
    { lat: 49.1554874, lng: 18.2434843 },
    { lat: 49.1555588, lng: 18.2433905 },
    { lat: 49.155839, lng: 18.2434371 },
    { lat: 49.1560683, lng: 18.2436012 },
    { lat: 49.1562114, lng: 18.2442752 },
    { lat: 49.1560757, lng: 18.2444298 },
    { lat: 49.1560784, lng: 18.2450351 },
    { lat: 49.1566222, lng: 18.2452121 },
    { lat: 49.1567308, lng: 18.2454984 },
    { lat: 49.1570211, lng: 18.2460813 },
    { lat: 49.1572945, lng: 18.2463888 },
    { lat: 49.1575921, lng: 18.2470198 },
    { lat: 49.1577635, lng: 18.2479368 },
    { lat: 49.1577095, lng: 18.248217 },
    { lat: 49.1575251, lng: 18.2491259 },
    { lat: 49.1570745, lng: 18.2491188 },
    { lat: 49.1570028, lng: 18.2491672 },
    { lat: 49.1569454, lng: 18.2492093 },
    { lat: 49.1565313, lng: 18.2495082 },
    { lat: 49.156304, lng: 18.2495592 },
    { lat: 49.1565132, lng: 18.251164 },
    { lat: 49.1564868, lng: 18.2515934 },
    { lat: 49.1564831, lng: 18.2516686 },
    { lat: 49.1564738, lng: 18.2517447 },
    { lat: 49.1569503, lng: 18.2547015 },
    { lat: 49.1569134, lng: 18.2551872 },
    { lat: 49.1567701, lng: 18.2556458 },
    { lat: 49.1564994, lng: 18.2560263 },
    { lat: 49.1564883, lng: 18.2560893 },
    { lat: 49.1565562, lng: 18.2569314 },
    { lat: 49.1565703, lng: 18.2580201 },
    { lat: 49.1564796, lng: 18.2591313 },
    { lat: 49.1564938, lng: 18.2604222 },
    { lat: 49.1564753, lng: 18.2618708 },
    { lat: 49.1565441, lng: 18.2627687 },
    { lat: 49.1567581, lng: 18.2632218 },
    { lat: 49.1567354, lng: 18.2641602 },
    { lat: 49.1573151, lng: 18.2651154 },
    { lat: 49.1575767, lng: 18.2650122 },
    { lat: 49.1579505, lng: 18.2647401 },
    { lat: 49.1583071, lng: 18.2646214 },
    { lat: 49.1585963, lng: 18.2649275 },
    { lat: 49.1587263, lng: 18.2651112 },
    { lat: 49.1587618, lng: 18.265163 },
    { lat: 49.1587569, lng: 18.2655738 },
    { lat: 49.159003, lng: 18.2661776 },
    { lat: 49.1590204, lng: 18.2662265 },
    { lat: 49.1590546, lng: 18.2663129 },
    { lat: 49.1591665, lng: 18.2662955 },
    { lat: 49.1594663, lng: 18.2662484 },
    { lat: 49.1596505, lng: 18.2661159 },
    { lat: 49.1596625, lng: 18.2661664 },
    { lat: 49.1599489, lng: 18.2670045 },
    { lat: 49.1600788, lng: 18.2675469 },
    { lat: 49.1600923, lng: 18.2676053 },
    { lat: 49.1601232, lng: 18.2677332 },
    { lat: 49.1602278, lng: 18.2681678 },
    { lat: 49.1603376, lng: 18.2686548 },
    { lat: 49.1606922, lng: 18.2694823 },
    { lat: 49.1610488, lng: 18.2700037 },
    { lat: 49.162671, lng: 18.2716403 },
    { lat: 49.1628472, lng: 18.2721638 },
    { lat: 49.1628797, lng: 18.2722606 },
    { lat: 49.162898, lng: 18.2723244 },
    { lat: 49.1629268, lng: 18.2724136 },
    { lat: 49.1629499, lng: 18.2724848 },
    { lat: 49.1629586, lng: 18.2725099 },
    { lat: 49.162966, lng: 18.2725314 },
    { lat: 49.1630072, lng: 18.2726623 },
    { lat: 49.1630496, lng: 18.2727932 },
    { lat: 49.1630754, lng: 18.2728698 },
    { lat: 49.1630868, lng: 18.2729338 },
    { lat: 49.1632172, lng: 18.2738039 },
    { lat: 49.1632261, lng: 18.2738608 },
    { lat: 49.1632341, lng: 18.2739242 },
    { lat: 49.1632471, lng: 18.274015 },
    { lat: 49.163262, lng: 18.2741185 },
    { lat: 49.1633475, lng: 18.274816 },
    { lat: 49.1633703, lng: 18.2749598 },
    { lat: 49.1633861, lng: 18.2750601 },
    { lat: 49.1633985, lng: 18.2751401 },
    { lat: 49.1634077, lng: 18.2752047 },
    { lat: 49.163427, lng: 18.2753248 },
    { lat: 49.1635068, lng: 18.2754394 },
    { lat: 49.163601, lng: 18.2755889 },
    { lat: 49.1638481, lng: 18.2760108 },
    { lat: 49.1641429, lng: 18.2763568 },
    { lat: 49.1641896, lng: 18.2763857 },
    { lat: 49.1645784, lng: 18.2766174 },
    { lat: 49.164992, lng: 18.2766912 },
    { lat: 49.1649808, lng: 18.2766314 },
    { lat: 49.1649738, lng: 18.2764884 },
    { lat: 49.1653263, lng: 18.2766768 },
    { lat: 49.1654182, lng: 18.2767255 },
    { lat: 49.1655543, lng: 18.2767987 },
    { lat: 49.1656009, lng: 18.2768236 },
    { lat: 49.165659, lng: 18.2768547 },
    { lat: 49.1657806, lng: 18.2769106 },
    { lat: 49.1659142, lng: 18.2769983 },
    { lat: 49.1660818, lng: 18.2770517 },
    { lat: 49.1666827, lng: 18.2772423 },
    { lat: 49.1671442, lng: 18.2772641 },
    { lat: 49.1676578, lng: 18.2772884 },
    { lat: 49.1684364, lng: 18.2769915 },
    { lat: 49.1684784, lng: 18.2769754 },
    { lat: 49.1687554, lng: 18.2768663 },
    { lat: 49.1690983, lng: 18.2767831 },
    { lat: 49.1698273, lng: 18.2761461 },
    { lat: 49.1701762, lng: 18.2759515 },
    { lat: 49.1704726, lng: 18.2757857 },
    { lat: 49.1705437, lng: 18.2757465 },
    { lat: 49.1708155, lng: 18.2755834 },
    { lat: 49.1710098, lng: 18.2754673 },
    { lat: 49.1713547, lng: 18.275302 },
    { lat: 49.1717036, lng: 18.2751878 },
    { lat: 49.1718277, lng: 18.275147 },
    { lat: 49.1722309, lng: 18.2750152 },
    { lat: 49.1722612, lng: 18.2750112 },
    { lat: 49.1730375, lng: 18.2748738 },
    { lat: 49.1732967, lng: 18.2748281 },
    { lat: 49.1734144, lng: 18.2748132 },
    { lat: 49.1736353, lng: 18.2746882 },
    { lat: 49.1739203, lng: 18.2744808 },
    { lat: 49.174331, lng: 18.274182 },
    { lat: 49.1754206, lng: 18.2737766 },
    { lat: 49.1757443, lng: 18.2736563 },
    { lat: 49.1761632, lng: 18.2735003 },
    { lat: 49.1765222, lng: 18.2730666 },
    { lat: 49.1767767, lng: 18.2727583 },
    { lat: 49.1774261, lng: 18.2711678 },
    { lat: 49.177438, lng: 18.2711374 },
    { lat: 49.1776071, lng: 18.2708145 },
    { lat: 49.1777551, lng: 18.2706178 },
    { lat: 49.1778045, lng: 18.2704732 },
    { lat: 49.177852, lng: 18.2704154 },
    { lat: 49.1780983, lng: 18.2699805 },
    { lat: 49.178788, lng: 18.268867 },
    { lat: 49.178956, lng: 18.2686051 },
    { lat: 49.179142, lng: 18.2684628 },
    { lat: 49.1791517, lng: 18.2684561 },
    { lat: 49.1795547, lng: 18.2684797 },
    { lat: 49.1796599, lng: 18.2685438 },
    { lat: 49.1805108, lng: 18.2690687 },
    { lat: 49.1805516, lng: 18.269092 },
    { lat: 49.1805328, lng: 18.2689799 },
    { lat: 49.1803483, lng: 18.2680074 },
    { lat: 49.1807149, lng: 18.2678641 },
    { lat: 49.1810006, lng: 18.2677523 },
    { lat: 49.1815053, lng: 18.2673167 },
    { lat: 49.1815324, lng: 18.2672966 },
    { lat: 49.1816205, lng: 18.2672385 },
    { lat: 49.1822105, lng: 18.2668569 },
    { lat: 49.1827383, lng: 18.268414 },
    { lat: 49.1828489, lng: 18.2689365 },
    { lat: 49.1829121, lng: 18.269238 },
    { lat: 49.183132, lng: 18.2702757 },
    { lat: 49.1834208, lng: 18.270295 },
    { lat: 49.1838705, lng: 18.2700665 },
    { lat: 49.1843029, lng: 18.2699728 },
    { lat: 49.1846265, lng: 18.2698267 },
    { lat: 49.1846328, lng: 18.2698793 },
    { lat: 49.1846471, lng: 18.2699127 },
    { lat: 49.1847021, lng: 18.2698896 },
    { lat: 49.1852874, lng: 18.2696448 },
    { lat: 49.1859818, lng: 18.2693546 },
    { lat: 49.1861534, lng: 18.2695364 },
    { lat: 49.1863351, lng: 18.2698565 },
    { lat: 49.1865057, lng: 18.2701814 },
    { lat: 49.1865206, lng: 18.2702056 },
    { lat: 49.186852, lng: 18.2711609 },
    { lat: 49.1868646, lng: 18.2711644 },
    { lat: 49.1870541, lng: 18.2712629 },
    { lat: 49.1874739, lng: 18.2714834 },
    { lat: 49.1874898, lng: 18.271468 },
    { lat: 49.1875226, lng: 18.2714094 },
    { lat: 49.1882749, lng: 18.2701639 },
    { lat: 49.1886772, lng: 18.2695952 },
    { lat: 49.1888255, lng: 18.2682421 },
    { lat: 49.1887026, lng: 18.2678856 },
    { lat: 49.1888858, lng: 18.2665526 },
    { lat: 49.1888671, lng: 18.2661002 },
    { lat: 49.1888618, lng: 18.2660259 },
    { lat: 49.1888507, lng: 18.2658077 },
    { lat: 49.1888806, lng: 18.2656225 },
    { lat: 49.1891444, lng: 18.2652573 },
    { lat: 49.1893207, lng: 18.265201 },
    { lat: 49.1896208, lng: 18.2653028 },
    { lat: 49.189729, lng: 18.2652801 },
    { lat: 49.1901259, lng: 18.2648738 },
    { lat: 49.1905034, lng: 18.2648255 },
    { lat: 49.191406, lng: 18.263928 },
    { lat: 49.1916052, lng: 18.2636201 },
    { lat: 49.1920207, lng: 18.263326 },
  ],
  Vydrná: [
    { lat: 49.233357, lng: 18.271427 },
    { lat: 49.236499, lng: 18.269253 },
    { lat: 49.237262, lng: 18.269048 },
    { lat: 49.23826, lng: 18.268497 },
    { lat: 49.2382723, lng: 18.2684227 },
    { lat: 49.2383442, lng: 18.2680165 },
    { lat: 49.2383395, lng: 18.2678915 },
    { lat: 49.2383242, lng: 18.2675023 },
    { lat: 49.2383203, lng: 18.2673907 },
    { lat: 49.2383072, lng: 18.2670574 },
    { lat: 49.2382999, lng: 18.2668485 },
    { lat: 49.2382962, lng: 18.2667617 },
    { lat: 49.2382881, lng: 18.2665594 },
    { lat: 49.2382617, lng: 18.2658724 },
    { lat: 49.2382318, lng: 18.2651187 },
    { lat: 49.2382891, lng: 18.2646764 },
    { lat: 49.2383688, lng: 18.2640471 },
    { lat: 49.2383923, lng: 18.2638642 },
    { lat: 49.2383987, lng: 18.2638186 },
    { lat: 49.2384616, lng: 18.2635128 },
    { lat: 49.2387138, lng: 18.2628411 },
    { lat: 49.2387326, lng: 18.2625944 },
    { lat: 49.2386741, lng: 18.2622198 },
    { lat: 49.2386013, lng: 18.2619264 },
    { lat: 49.2384741, lng: 18.2615331 },
    { lat: 49.2384679, lng: 18.2614219 },
    { lat: 49.2385463, lng: 18.2612104 },
    { lat: 49.2386287, lng: 18.2611707 },
    { lat: 49.2385983, lng: 18.2608332 },
    { lat: 49.2386343, lng: 18.2606706 },
    { lat: 49.2387914, lng: 18.2604964 },
    { lat: 49.2388467, lng: 18.260338 },
    { lat: 49.2388301, lng: 18.2602022 },
    { lat: 49.2388182, lng: 18.2598527 },
    { lat: 49.2387647, lng: 18.2594613 },
    { lat: 49.2387447, lng: 18.2593458 },
    { lat: 49.2387063, lng: 18.2591678 },
    { lat: 49.2383619, lng: 18.2584029 },
    { lat: 49.2379727, lng: 18.2579404 },
    { lat: 49.2378563, lng: 18.2577199 },
    { lat: 49.2377746, lng: 18.2576093 },
    { lat: 49.2375827, lng: 18.2572497 },
    { lat: 49.2375262, lng: 18.2571672 },
    { lat: 49.2374226, lng: 18.256931 },
    { lat: 49.237293, lng: 18.2568132 },
    { lat: 49.2371737, lng: 18.2566006 },
    { lat: 49.236894, lng: 18.2563774 },
    { lat: 49.2367238, lng: 18.2562438 },
    { lat: 49.2365905, lng: 18.2561195 },
    { lat: 49.2365075, lng: 18.2554905 },
    { lat: 49.2365758, lng: 18.2552807 },
    { lat: 49.2364464, lng: 18.2550449 },
    { lat: 49.2364814, lng: 18.2547048 },
    { lat: 49.2364146, lng: 18.2543641 },
    { lat: 49.2362748, lng: 18.2538424 },
    { lat: 49.236281, lng: 18.253634 },
    { lat: 49.2359902, lng: 18.2529397 },
    { lat: 49.235808, lng: 18.2526301 },
    { lat: 49.2357042, lng: 18.2521326 },
    { lat: 49.2357289, lng: 18.2519708 },
    { lat: 49.235641, lng: 18.2513527 },
    { lat: 49.2355463, lng: 18.2500727 },
    { lat: 49.2354393, lng: 18.249879 },
    { lat: 49.2354382, lng: 18.2498788 },
    { lat: 49.2354085, lng: 18.2498724 },
    { lat: 49.2353948, lng: 18.2498693 },
    { lat: 49.2352772, lng: 18.2498428 },
    { lat: 49.2352325, lng: 18.2498328 },
    { lat: 49.23518, lng: 18.2498206 },
    { lat: 49.2349335, lng: 18.2499888 },
    { lat: 49.2345718, lng: 18.2502366 },
    { lat: 49.2345786, lng: 18.249281 },
    { lat: 49.234489, lng: 18.2490729 },
    { lat: 49.2344526, lng: 18.2489878 },
    { lat: 49.2343018, lng: 18.2486342 },
    { lat: 49.234112, lng: 18.2481915 },
    { lat: 49.2340225, lng: 18.247983 },
    { lat: 49.2340183, lng: 18.247972 },
    { lat: 49.2340107, lng: 18.2479541 },
    { lat: 49.2340028, lng: 18.2479354 },
    { lat: 49.2339977, lng: 18.247926 },
    { lat: 49.2339822, lng: 18.2478894 },
    { lat: 49.2339536, lng: 18.2478224 },
    { lat: 49.2331886, lng: 18.2476218 },
    { lat: 49.2330304, lng: 18.2475802 },
    { lat: 49.2328473, lng: 18.2475321 },
    { lat: 49.2325537, lng: 18.2471895 },
    { lat: 49.2325477, lng: 18.2471835 },
    { lat: 49.2325345, lng: 18.2471679 },
    { lat: 49.2325234, lng: 18.2471311 },
    { lat: 49.2325166, lng: 18.2471103 },
    { lat: 49.2325041, lng: 18.247069 },
    { lat: 49.2324974, lng: 18.2470509 },
    { lat: 49.2323696, lng: 18.2466461 },
    { lat: 49.2323433, lng: 18.2459811 },
    { lat: 49.2323494, lng: 18.2456908 },
    { lat: 49.2323516, lng: 18.2455298 },
    { lat: 49.2323177, lng: 18.2455069 },
    { lat: 49.2322379, lng: 18.2454544 },
    { lat: 49.2321748, lng: 18.2454127 },
    { lat: 49.2321011, lng: 18.2452881 },
    { lat: 49.2319998, lng: 18.2451159 },
    { lat: 49.2317277, lng: 18.2446518 },
    { lat: 49.2316759, lng: 18.2445918 },
    { lat: 49.2316385, lng: 18.2445502 },
    { lat: 49.2316168, lng: 18.244526 },
    { lat: 49.2314133, lng: 18.2442968 },
    { lat: 49.2311057, lng: 18.2438493 },
    { lat: 49.2310173, lng: 18.2437189 },
    { lat: 49.2309872, lng: 18.2436471 },
    { lat: 49.2308893, lng: 18.2434123 },
    { lat: 49.230862, lng: 18.2433479 },
    { lat: 49.2307708, lng: 18.2431308 },
    { lat: 49.2306858, lng: 18.2429245 },
    { lat: 49.2298116, lng: 18.243386 },
    { lat: 49.2295071, lng: 18.24329 },
    { lat: 49.2292635, lng: 18.2432128 },
    { lat: 49.2289635, lng: 18.2431128 },
    { lat: 49.2287727, lng: 18.2430497 },
    { lat: 49.2286888, lng: 18.242783 },
    { lat: 49.2284432, lng: 18.2417549 },
    { lat: 49.2283145, lng: 18.2413142 },
    { lat: 49.2279436, lng: 18.2407184 },
    { lat: 49.2276249, lng: 18.240433 },
    { lat: 49.2269733, lng: 18.2400237 },
    { lat: 49.2263394, lng: 18.2396837 },
    { lat: 49.2258738, lng: 18.2394944 },
    { lat: 49.2256383, lng: 18.239398 },
    { lat: 49.2253885, lng: 18.2396515 },
    { lat: 49.2248745, lng: 18.2395594 },
    { lat: 49.2246434, lng: 18.2395448 },
    { lat: 49.2242754, lng: 18.2393406 },
    { lat: 49.2237912, lng: 18.2386974 },
    { lat: 49.2234057, lng: 18.2377473 },
    { lat: 49.2225728, lng: 18.2363275 },
    { lat: 49.2221421, lng: 18.2366716 },
    { lat: 49.2218402, lng: 18.2371598 },
    { lat: 49.2212114, lng: 18.2368147 },
    { lat: 49.2211719, lng: 18.2367932 },
    { lat: 49.2209787, lng: 18.2369475 },
    { lat: 49.2207446, lng: 18.237135 },
    { lat: 49.220613, lng: 18.2372816 },
    { lat: 49.2202479, lng: 18.2376893 },
    { lat: 49.2197736, lng: 18.2382193 },
    { lat: 49.2191834, lng: 18.2385563 },
    { lat: 49.2190458, lng: 18.2386354 },
    { lat: 49.2185942, lng: 18.2387105 },
    { lat: 49.2179034, lng: 18.2389243 },
    { lat: 49.2169069, lng: 18.2392207 },
    { lat: 49.2164555, lng: 18.2392988 },
    { lat: 49.2163573, lng: 18.2392179 },
    { lat: 49.216246, lng: 18.2387641 },
    { lat: 49.2163485, lng: 18.2383859 },
    { lat: 49.2164726, lng: 18.2381642 },
    { lat: 49.2163537, lng: 18.237955 },
    { lat: 49.2161392, lng: 18.2373582 },
    { lat: 49.2159542, lng: 18.2375184 },
    { lat: 49.2157066, lng: 18.2372883 },
    { lat: 49.2154368, lng: 18.2364682 },
    { lat: 49.2149953, lng: 18.2364615 },
    { lat: 49.2144779, lng: 18.2367322 },
    { lat: 49.2144798, lng: 18.2367453 },
    { lat: 49.2145909, lng: 18.2374918 },
    { lat: 49.2147452, lng: 18.2380239 },
    { lat: 49.2151813, lng: 18.2389915 },
    { lat: 49.215393, lng: 18.2390861 },
    { lat: 49.2157452, lng: 18.2391471 },
    { lat: 49.2159395, lng: 18.2391711 },
    { lat: 49.2160629, lng: 18.239655 },
    { lat: 49.2164549, lng: 18.239388 },
    { lat: 49.2169078, lng: 18.239274 },
    { lat: 49.2169251, lng: 18.2402405 },
    { lat: 49.2168119, lng: 18.2406707 },
    { lat: 49.2164386, lng: 18.24131 },
    { lat: 49.2157715, lng: 18.2425821 },
    { lat: 49.215186, lng: 18.243182 },
    { lat: 49.2146051, lng: 18.2434453 },
    { lat: 49.2141633, lng: 18.2436721 },
    { lat: 49.2140668, lng: 18.2437216 },
    { lat: 49.2140176, lng: 18.2437467 },
    { lat: 49.2139528, lng: 18.2437803 },
    { lat: 49.2133382, lng: 18.2441655 },
    { lat: 49.2128086, lng: 18.2443952 },
    { lat: 49.212363, lng: 18.2444173 },
    { lat: 49.2121171, lng: 18.2444957 },
    { lat: 49.212085, lng: 18.244506 },
    { lat: 49.2120351, lng: 18.244522 },
    { lat: 49.2112936, lng: 18.2446803 },
    { lat: 49.2109801, lng: 18.2449602 },
    { lat: 49.2109165, lng: 18.2449721 },
    { lat: 49.2109536, lng: 18.2443105 },
    { lat: 49.2111881, lng: 18.244189 },
    { lat: 49.2114702, lng: 18.2438729 },
    { lat: 49.2116112, lng: 18.2435106 },
    { lat: 49.2118292, lng: 18.2425997 },
    { lat: 49.2121046, lng: 18.2428373 },
    { lat: 49.2119297, lng: 18.2435577 },
    { lat: 49.2125845, lng: 18.2434474 },
    { lat: 49.212955, lng: 18.2432139 },
    { lat: 49.2131485, lng: 18.2432024 },
    { lat: 49.2139437, lng: 18.2430586 },
    { lat: 49.2141413, lng: 18.2429249 },
    { lat: 49.2140602, lng: 18.241749 },
    { lat: 49.2144514, lng: 18.2416434 },
    { lat: 49.214366, lng: 18.2412707 },
    { lat: 49.2142271, lng: 18.2403919 },
    { lat: 49.2140437, lng: 18.2401788 },
    { lat: 49.2138071, lng: 18.2401102 },
    { lat: 49.2135943, lng: 18.2399749 },
    { lat: 49.2133522, lng: 18.2397658 },
    { lat: 49.213066, lng: 18.239456 },
    { lat: 49.2130495, lng: 18.2395193 },
    { lat: 49.2129784, lng: 18.2397788 },
    { lat: 49.2129481, lng: 18.239999 },
    { lat: 49.2127773, lng: 18.2404107 },
    { lat: 49.2125904, lng: 18.2405586 },
    { lat: 49.2123131, lng: 18.240484 },
    { lat: 49.2119237, lng: 18.2404798 },
    { lat: 49.2115698, lng: 18.2405066 },
    { lat: 49.2114194, lng: 18.2405011 },
    { lat: 49.211061, lng: 18.2404881 },
    { lat: 49.2111479, lng: 18.2408671 },
    { lat: 49.2112973, lng: 18.2413727 },
    { lat: 49.2113866, lng: 18.2416761 },
    { lat: 49.2114669, lng: 18.2418591 },
    { lat: 49.2115446, lng: 18.2420362 },
    { lat: 49.211572, lng: 18.2420984 },
    { lat: 49.2116169, lng: 18.2423751 },
    { lat: 49.211804, lng: 18.2425729 },
    { lat: 49.2115839, lng: 18.243488 },
    { lat: 49.2114535, lng: 18.2438361 },
    { lat: 49.2113631, lng: 18.2432532 },
    { lat: 49.2112899, lng: 18.2430548 },
    { lat: 49.2112604, lng: 18.242993 },
    { lat: 49.2110827, lng: 18.2426279 },
    { lat: 49.2109986, lng: 18.2424523 },
    { lat: 49.2109132, lng: 18.2423816 },
    { lat: 49.2109573, lng: 18.2426634 },
    { lat: 49.2108267, lng: 18.2430083 },
    { lat: 49.2106629, lng: 18.2431628 },
    { lat: 49.2103545, lng: 18.243036 },
    { lat: 49.2095773, lng: 18.2427388 },
    { lat: 49.2096056, lng: 18.2424235 },
    { lat: 49.209294, lng: 18.2424186 },
    { lat: 49.2087776, lng: 18.2422643 },
    { lat: 49.2087061, lng: 18.2421581 },
    { lat: 49.20865, lng: 18.2420845 },
    { lat: 49.208605, lng: 18.2420258 },
    { lat: 49.2085111, lng: 18.24174 },
    { lat: 49.2083608, lng: 18.2414374 },
    { lat: 49.2081746, lng: 18.2412385 },
    { lat: 49.2080339, lng: 18.2411835 },
    { lat: 49.2079012, lng: 18.2411315 },
    { lat: 49.2077935, lng: 18.2410618 },
    { lat: 49.2076969, lng: 18.2409932 },
    { lat: 49.2074619, lng: 18.240609 },
    { lat: 49.2074421, lng: 18.2405759 },
    { lat: 49.2074313, lng: 18.2402262 },
    { lat: 49.2074255, lng: 18.2400612 },
    { lat: 49.2075235, lng: 18.2397805 },
    { lat: 49.2076456, lng: 18.2396064 },
    { lat: 49.2076531, lng: 18.2394219 },
    { lat: 49.2076537, lng: 18.2394106 },
    { lat: 49.2075486, lng: 18.2391672 },
    { lat: 49.2074323, lng: 18.2390667 },
    { lat: 49.2072624, lng: 18.2389143 },
    { lat: 49.2070866, lng: 18.2388788 },
    { lat: 49.2068884, lng: 18.2386473 },
    { lat: 49.2067808, lng: 18.2385206 },
    { lat: 49.2066876, lng: 18.2384494 },
    { lat: 49.2065015, lng: 18.2381521 },
    { lat: 49.2063601, lng: 18.2379257 },
    { lat: 49.2063, lng: 18.2381389 },
    { lat: 49.2062647, lng: 18.2385499 },
    { lat: 49.206078, lng: 18.2388993 },
    { lat: 49.2057275, lng: 18.2388885 },
    { lat: 49.205538, lng: 18.2383677 },
    { lat: 49.2054149, lng: 18.2379316 },
    { lat: 49.205285, lng: 18.2376874 },
    { lat: 49.2050725, lng: 18.2378419 },
    { lat: 49.2049783, lng: 18.2379108 },
    { lat: 49.2047386, lng: 18.2378992 },
    { lat: 49.2046912, lng: 18.2380015 },
    { lat: 49.2042971, lng: 18.2378351 },
    { lat: 49.2039032, lng: 18.2387705 },
    { lat: 49.2036785, lng: 18.2394238 },
    { lat: 49.2035284, lng: 18.2393404 },
    { lat: 49.2033838, lng: 18.2392596 },
    { lat: 49.2032906, lng: 18.2392082 },
    { lat: 49.2032434, lng: 18.2391936 },
    { lat: 49.2027408, lng: 18.2390427 },
    { lat: 49.2024107, lng: 18.2389127 },
    { lat: 49.2023703, lng: 18.2388969 },
    { lat: 49.2023104, lng: 18.2390116 },
    { lat: 49.2022298, lng: 18.2391635 },
    { lat: 49.2021697, lng: 18.2393004 },
    { lat: 49.2021541, lng: 18.2393409 },
    { lat: 49.2019737, lng: 18.2397626 },
    { lat: 49.2016927, lng: 18.2395229 },
    { lat: 49.2015874, lng: 18.2395166 },
    { lat: 49.2014085, lng: 18.2395054 },
    { lat: 49.2013055, lng: 18.2394986 },
    { lat: 49.2011707, lng: 18.2394902 },
    { lat: 49.2010848, lng: 18.2394847 },
    { lat: 49.2010236, lng: 18.2394809 },
    { lat: 49.201002, lng: 18.2394794 },
    { lat: 49.2007456, lng: 18.2395304 },
    { lat: 49.2005524, lng: 18.2395686 },
    { lat: 49.2004924, lng: 18.2396032 },
    { lat: 49.2002563, lng: 18.2397383 },
    { lat: 49.1998704, lng: 18.2399587 },
    { lat: 49.1997855, lng: 18.2399914 },
    { lat: 49.1996154, lng: 18.240056 },
    { lat: 49.1994541, lng: 18.2401177 },
    { lat: 49.1993845, lng: 18.2401445 },
    { lat: 49.199272, lng: 18.2401873 },
    { lat: 49.1987979, lng: 18.2402448 },
    { lat: 49.1986298, lng: 18.2402648 },
    { lat: 49.1985614, lng: 18.2402729 },
    { lat: 49.1978769, lng: 18.2405762 },
    { lat: 49.1976855, lng: 18.2406245 },
    { lat: 49.1976843, lng: 18.2406246 },
    { lat: 49.1975944, lng: 18.2406478 },
    { lat: 49.1975287, lng: 18.2406643 },
    { lat: 49.1972874, lng: 18.2407249 },
    { lat: 49.1968482, lng: 18.2408597 },
    { lat: 49.1968034, lng: 18.2408248 },
    { lat: 49.1967715, lng: 18.2407992 },
    { lat: 49.1964705, lng: 18.2405653 },
    { lat: 49.1960975, lng: 18.2402739 },
    { lat: 49.1959423, lng: 18.2402424 },
    { lat: 49.1959215, lng: 18.2402356 },
    { lat: 49.1949124, lng: 18.2399077 },
    { lat: 49.1946471, lng: 18.2397443 },
    { lat: 49.1944517, lng: 18.2396016 },
    { lat: 49.1943563, lng: 18.2395321 },
    { lat: 49.1940155, lng: 18.2394121 },
    { lat: 49.1935773, lng: 18.2394658 },
    { lat: 49.1935585, lng: 18.2392911 },
    { lat: 49.193182, lng: 18.2392574 },
    { lat: 49.1928057, lng: 18.2392241 },
    { lat: 49.1927276, lng: 18.2393404 },
    { lat: 49.1925363, lng: 18.2396279 },
    { lat: 49.1923048, lng: 18.2394842 },
    { lat: 49.1922601, lng: 18.2394563 },
    { lat: 49.1919291, lng: 18.2395257 },
    { lat: 49.1916258, lng: 18.239589 },
    { lat: 49.1913426, lng: 18.2396487 },
    { lat: 49.1912534, lng: 18.2396675 },
    { lat: 49.1910444, lng: 18.2397594 },
    { lat: 49.1907526, lng: 18.2398882 },
    { lat: 49.1903067, lng: 18.2400848 },
    { lat: 49.189618, lng: 18.2403566 },
    { lat: 49.1895497, lng: 18.2404024 },
    { lat: 49.1894354, lng: 18.2405961 },
    { lat: 49.1892715, lng: 18.2407717 },
    { lat: 49.1891632, lng: 18.2408505 },
    { lat: 49.1890595, lng: 18.2409145 },
    { lat: 49.1890065, lng: 18.2410136 },
    { lat: 49.1889167, lng: 18.2412772 },
    { lat: 49.1888082, lng: 18.2415949 },
    { lat: 49.188783, lng: 18.2416687 },
    { lat: 49.1887264, lng: 18.2417045 },
    { lat: 49.1886564, lng: 18.2417047 },
    { lat: 49.1885804, lng: 18.2416981 },
    { lat: 49.1885576, lng: 18.2417336 },
    { lat: 49.1885329, lng: 18.2417731 },
    { lat: 49.188516, lng: 18.2417961 },
    { lat: 49.1884951, lng: 18.2418244 },
    { lat: 49.1884499, lng: 18.2418816 },
    { lat: 49.1886072, lng: 18.2421713 },
    { lat: 49.1887146, lng: 18.2423146 },
    { lat: 49.1889098, lng: 18.2424714 },
    { lat: 49.1890586, lng: 18.2425906 },
    { lat: 49.189529, lng: 18.2428886 },
    { lat: 49.1896827, lng: 18.2430116 },
    { lat: 49.1898225, lng: 18.2431299 },
    { lat: 49.1901158, lng: 18.2434278 },
    { lat: 49.190395, lng: 18.2437957 },
    { lat: 49.1906182, lng: 18.2439925 },
    { lat: 49.1909011, lng: 18.2442085 },
    { lat: 49.1908932, lng: 18.2443058 },
    { lat: 49.1908223, lng: 18.2451888 },
    { lat: 49.1908109, lng: 18.2453422 },
    { lat: 49.1911897, lng: 18.246017 },
    { lat: 49.1911701, lng: 18.246647 },
    { lat: 49.1912525, lng: 18.2468467 },
    { lat: 49.191356, lng: 18.2469414 },
    { lat: 49.1915421, lng: 18.2476611 },
    { lat: 49.1918023, lng: 18.2477147 },
    { lat: 49.1918638, lng: 18.2480615 },
    { lat: 49.1922077, lng: 18.2482779 },
    { lat: 49.1921988, lng: 18.2492203 },
    { lat: 49.1921988, lng: 18.2492982 },
    { lat: 49.1918371, lng: 18.249245 },
    { lat: 49.1919404, lng: 18.250056 },
    { lat: 49.1921726, lng: 18.2503328 },
    { lat: 49.1924007, lng: 18.2508135 },
    { lat: 49.1927263, lng: 18.2513659 },
    { lat: 49.1928958, lng: 18.2516509 },
    { lat: 49.1929567, lng: 18.2521077 },
    { lat: 49.1929172, lng: 18.2529081 },
    { lat: 49.1927172, lng: 18.253339 },
    { lat: 49.1926878, lng: 18.2534006 },
    { lat: 49.1925057, lng: 18.2539095 },
    { lat: 49.1923243, lng: 18.2548121 },
    { lat: 49.1923072, lng: 18.2550705 },
    { lat: 49.1922773, lng: 18.2555199 },
    { lat: 49.1922825, lng: 18.2562688 },
    { lat: 49.1921927, lng: 18.2566559 },
    { lat: 49.1920969, lng: 18.2569768 },
    { lat: 49.1918799, lng: 18.2579493 },
    { lat: 49.1918488, lng: 18.2584396 },
    { lat: 49.1918147, lng: 18.2589964 },
    { lat: 49.1919025, lng: 18.2600324 },
    { lat: 49.191452, lng: 18.2604039 },
    { lat: 49.1914101, lng: 18.260439 },
    { lat: 49.1916555, lng: 18.2616707 },
    { lat: 49.1918871, lng: 18.2624739 },
    { lat: 49.1920207, lng: 18.263326 },
    { lat: 49.1921263, lng: 18.2632566 },
    { lat: 49.1924454, lng: 18.263113 },
    { lat: 49.1924565, lng: 18.2630297 },
    { lat: 49.1924808, lng: 18.26284 },
    { lat: 49.1925077, lng: 18.2627964 },
    { lat: 49.1926316, lng: 18.2625962 },
    { lat: 49.1928765, lng: 18.2625153 },
    { lat: 49.1929096, lng: 18.2625037 },
    { lat: 49.1930812, lng: 18.2624449 },
    { lat: 49.1935458, lng: 18.2625414 },
    { lat: 49.1938337, lng: 18.2630639 },
    { lat: 49.1940209, lng: 18.2633812 },
    { lat: 49.1940933, lng: 18.2635044 },
    { lat: 49.1941344, lng: 18.2635744 },
    { lat: 49.1942131, lng: 18.2636088 },
    { lat: 49.1944942, lng: 18.2637338 },
    { lat: 49.1947859, lng: 18.2638633 },
    { lat: 49.1953182, lng: 18.2640993 },
    { lat: 49.1953804, lng: 18.2644811 },
    { lat: 49.1952968, lng: 18.2645209 },
    { lat: 49.1953247, lng: 18.264714 },
    { lat: 49.1953735, lng: 18.2650595 },
    { lat: 49.1953829, lng: 18.2651254 },
    { lat: 49.1954016, lng: 18.2652564 },
    { lat: 49.195458, lng: 18.2654178 },
    { lat: 49.1954995, lng: 18.2655329 },
    { lat: 49.1957172, lng: 18.2656331 },
    { lat: 49.1960288, lng: 18.2655572 },
    { lat: 49.1961744, lng: 18.2656362 },
    { lat: 49.196386, lng: 18.2657505 },
    { lat: 49.1965339, lng: 18.2658314 },
    { lat: 49.1967548, lng: 18.2662073 },
    { lat: 49.196844, lng: 18.2663881 },
    { lat: 49.1969751, lng: 18.2666508 },
    { lat: 49.1970032, lng: 18.2667092 },
    { lat: 49.1971242, lng: 18.266955 },
    { lat: 49.197155, lng: 18.2671028 },
    { lat: 49.197195, lng: 18.2672935 },
    { lat: 49.1972791, lng: 18.2677022 },
    { lat: 49.1973112, lng: 18.2678524 },
    { lat: 49.1973386, lng: 18.2683978 },
    { lat: 49.1973414, lng: 18.2684673 },
    { lat: 49.1973489, lng: 18.2686212 },
    { lat: 49.1974043, lng: 18.2687251 },
    { lat: 49.1975644, lng: 18.2690237 },
    { lat: 49.1977593, lng: 18.2695566 },
    { lat: 49.1980436, lng: 18.2714986 },
    { lat: 49.198084, lng: 18.2717743 },
    { lat: 49.1981102, lng: 18.2718414 },
    { lat: 49.1983477, lng: 18.2724318 },
    { lat: 49.1984637, lng: 18.272685 },
    { lat: 49.1987489, lng: 18.2733042 },
    { lat: 49.1987593, lng: 18.2733088 },
    { lat: 49.1990694, lng: 18.2734458 },
    { lat: 49.1993065, lng: 18.2735507 },
    { lat: 49.1993958, lng: 18.2738514 },
    { lat: 49.1994864, lng: 18.2741577 },
    { lat: 49.1997254, lng: 18.2746571 },
    { lat: 49.1999612, lng: 18.2747777 },
    { lat: 49.2003109, lng: 18.2755591 },
    { lat: 49.2003985, lng: 18.2757917 },
    { lat: 49.2004201, lng: 18.2758516 },
    { lat: 49.200691, lng: 18.2765782 },
    { lat: 49.200723, lng: 18.2766633 },
    { lat: 49.2010015, lng: 18.276984 },
    { lat: 49.2010654, lng: 18.2770346 },
    { lat: 49.2010948, lng: 18.2770574 },
    { lat: 49.2015777, lng: 18.2774388 },
    { lat: 49.2022579, lng: 18.2779763 },
    { lat: 49.2024099, lng: 18.2783751 },
    { lat: 49.2024838, lng: 18.2786634 },
    { lat: 49.2027239, lng: 18.2792413 },
    { lat: 49.2029118, lng: 18.2798328 },
    { lat: 49.2031976, lng: 18.2804413 },
    { lat: 49.2034126, lng: 18.2806541 },
    { lat: 49.2038861, lng: 18.2809091 },
    { lat: 49.2044621, lng: 18.2810423 },
    { lat: 49.2048306, lng: 18.2808524 },
    { lat: 49.2050689, lng: 18.2804979 },
    { lat: 49.2052841, lng: 18.2803743 },
    { lat: 49.2056479, lng: 18.2799606 },
    { lat: 49.2061507, lng: 18.2795897 },
    { lat: 49.2062725, lng: 18.2795322 },
    { lat: 49.2065857, lng: 18.2793849 },
    { lat: 49.2066918, lng: 18.2793257 },
    { lat: 49.2070389, lng: 18.2789764 },
    { lat: 49.2071193, lng: 18.2788212 },
    { lat: 49.2072557, lng: 18.2785576 },
    { lat: 49.2074625, lng: 18.2780811 },
    { lat: 49.2076794, lng: 18.2772253 },
    { lat: 49.2078962, lng: 18.2767482 },
    { lat: 49.208011, lng: 18.2766234 },
    { lat: 49.2084681, lng: 18.2761253 },
    { lat: 49.2085526, lng: 18.2761829 },
    { lat: 49.2089855, lng: 18.2764762 },
    { lat: 49.2093055, lng: 18.2774756 },
    { lat: 49.2096068, lng: 18.2782584 },
    { lat: 49.2098343, lng: 18.2787731 },
    { lat: 49.2099378, lng: 18.2790075 },
    { lat: 49.2099701, lng: 18.2790808 },
    { lat: 49.2101121, lng: 18.2794793 },
    { lat: 49.2102349, lng: 18.2794939 },
    { lat: 49.2114046, lng: 18.2796298 },
    { lat: 49.2122373, lng: 18.2796142 },
    { lat: 49.212323, lng: 18.2796132 },
    { lat: 49.2127989, lng: 18.2795884 },
    { lat: 49.2129979, lng: 18.2795778 },
    { lat: 49.2131923, lng: 18.2794614 },
    { lat: 49.2134182, lng: 18.279325 },
    { lat: 49.2135622, lng: 18.2792382 },
    { lat: 49.2136528, lng: 18.2791836 },
    { lat: 49.2136638, lng: 18.2791769 },
    { lat: 49.213774, lng: 18.2791109 },
    { lat: 49.2138938, lng: 18.2790385 },
    { lat: 49.2139736, lng: 18.2789902 },
    { lat: 49.2140095, lng: 18.2789689 },
    { lat: 49.2140468, lng: 18.2789465 },
    { lat: 49.2140575, lng: 18.2789398 },
    { lat: 49.2147157, lng: 18.2784265 },
    { lat: 49.2147441, lng: 18.2783854 },
    { lat: 49.2153031, lng: 18.2775561 },
    { lat: 49.215634, lng: 18.2775214 },
    { lat: 49.2160265, lng: 18.2775228 },
    { lat: 49.2162814, lng: 18.277518 },
    { lat: 49.2163671, lng: 18.2775169 },
    { lat: 49.2163923, lng: 18.2775436 },
    { lat: 49.2169682, lng: 18.2781361 },
    { lat: 49.2171729, lng: 18.2783467 },
    { lat: 49.2174216, lng: 18.278994 },
    { lat: 49.2176828, lng: 18.2794046 },
    { lat: 49.2177679, lng: 18.2795566 },
    { lat: 49.2179513, lng: 18.2798864 },
    { lat: 49.2180306, lng: 18.2800148 },
    { lat: 49.2182614, lng: 18.2803867 },
    { lat: 49.2187296, lng: 18.2812919 },
    { lat: 49.2187998, lng: 18.2817533 },
    { lat: 49.218843, lng: 18.2820436 },
    { lat: 49.2188684, lng: 18.2822128 },
    { lat: 49.2188805, lng: 18.2822895 },
    { lat: 49.2189311, lng: 18.2826266 },
    { lat: 49.2190312, lng: 18.2829601 },
    { lat: 49.21904, lng: 18.283455 },
    { lat: 49.219167, lng: 18.284238 },
    { lat: 49.219155, lng: 18.284658 },
    { lat: 49.219011, lng: 18.28521 },
    { lat: 49.218974, lng: 18.285297 },
    { lat: 49.218844, lng: 18.285594 },
    { lat: 49.218606, lng: 18.286146 },
    { lat: 49.218437, lng: 18.286802 },
    { lat: 49.218484, lng: 18.287199 },
    { lat: 49.218702, lng: 18.288137 },
    { lat: 49.218945, lng: 18.288848 },
    { lat: 49.219176, lng: 18.28947 },
    { lat: 49.219226, lng: 18.289549 },
    { lat: 49.219252, lng: 18.28959 },
    { lat: 49.21935, lng: 18.289763 },
    { lat: 49.219513, lng: 18.290109 },
    { lat: 49.219601, lng: 18.290292 },
    { lat: 49.219643, lng: 18.290278 },
    { lat: 49.219881, lng: 18.290202 },
    { lat: 49.22042, lng: 18.289171 },
    { lat: 49.220726, lng: 18.288775 },
    { lat: 49.22103, lng: 18.288191 },
    { lat: 49.221167, lng: 18.287983 },
    { lat: 49.221202, lng: 18.287755 },
    { lat: 49.221309, lng: 18.287656 },
    { lat: 49.221443, lng: 18.287571 },
    { lat: 49.221554, lng: 18.287044 },
    { lat: 49.221671, lng: 18.286939 },
    { lat: 49.222073, lng: 18.286557 },
    { lat: 49.222453, lng: 18.286207 },
    { lat: 49.222715, lng: 18.285791 },
    { lat: 49.223116, lng: 18.285586 },
    { lat: 49.223656, lng: 18.285199 },
    { lat: 49.224119, lng: 18.284869 },
    { lat: 49.224554, lng: 18.284658 },
    { lat: 49.225136, lng: 18.28449 },
    { lat: 49.225622, lng: 18.284456 },
    { lat: 49.226062, lng: 18.284142 },
    { lat: 49.226579, lng: 18.283845 },
    { lat: 49.226651, lng: 18.283802 },
    { lat: 49.226713, lng: 18.28376 },
    { lat: 49.227121, lng: 18.283375 },
    { lat: 49.227774, lng: 18.282943 },
    { lat: 49.228321, lng: 18.282677 },
    { lat: 49.228375, lng: 18.282664 },
    { lat: 49.228816, lng: 18.282477 },
    { lat: 49.229314, lng: 18.281968 },
    { lat: 49.229628, lng: 18.281725 },
    { lat: 49.229814, lng: 18.28149 },
    { lat: 49.230345, lng: 18.281079 },
    { lat: 49.230651, lng: 18.280992 },
    { lat: 49.230714, lng: 18.280857 },
    { lat: 49.230694, lng: 18.280842 },
    { lat: 49.230875, lng: 18.280697 },
    { lat: 49.230936, lng: 18.280618 },
    { lat: 49.231226, lng: 18.280243 },
    { lat: 49.231614, lng: 18.279951 },
    { lat: 49.232154, lng: 18.279638 },
    { lat: 49.232133, lng: 18.279578 },
    { lat: 49.232078, lng: 18.279415 },
    { lat: 49.232163, lng: 18.279008 },
    { lat: 49.231932, lng: 18.278216 },
    { lat: 49.231648, lng: 18.277448 },
    { lat: 49.230953, lng: 18.275631 },
    { lat: 49.231525, lng: 18.274554 },
    { lat: 49.231844, lng: 18.273949 },
    { lat: 49.232401, lng: 18.272991 },
    { lat: 49.232942, lng: 18.272197 },
    { lat: 49.233357, lng: 18.271427 },
  ],
  Zubák: [
    { lat: 49.1549709, lng: 18.2436678 },
    { lat: 49.1548863, lng: 18.2425929 },
    { lat: 49.1551252, lng: 18.241869 },
    { lat: 49.1551918, lng: 18.2414643 },
    { lat: 49.1552376, lng: 18.2411855 },
    { lat: 49.1557152, lng: 18.2397377 },
    { lat: 49.1557547, lng: 18.2397383 },
    { lat: 49.15605, lng: 18.2397715 },
    { lat: 49.1563943, lng: 18.2385406 },
    { lat: 49.1565578, lng: 18.2379612 },
    { lat: 49.1569101, lng: 18.2372102 },
    { lat: 49.1576358, lng: 18.2361979 },
    { lat: 49.1581707, lng: 18.2358048 },
    { lat: 49.1584654, lng: 18.2354689 },
    { lat: 49.1587063, lng: 18.2350006 },
    { lat: 49.1587802, lng: 18.2341761 },
    { lat: 49.1587772, lng: 18.2341235 },
    { lat: 49.1587867, lng: 18.233196 },
    { lat: 49.1587386, lng: 18.2325281 },
    { lat: 49.1587558, lng: 18.2324724 },
    { lat: 49.1588259, lng: 18.232237 },
    { lat: 49.1590052, lng: 18.2322546 },
    { lat: 49.1591733, lng: 18.2318356 },
    { lat: 49.1592918, lng: 18.231201 },
    { lat: 49.1594428, lng: 18.2313746 },
    { lat: 49.1598554, lng: 18.2302758 },
    { lat: 49.1600875, lng: 18.2298324 },
    { lat: 49.1602428, lng: 18.2294493 },
    { lat: 49.1604704, lng: 18.2292641 },
    { lat: 49.1606359, lng: 18.2288013 },
    { lat: 49.161114, lng: 18.228458 },
    { lat: 49.161517, lng: 18.2282422 },
    { lat: 49.1616224, lng: 18.2278148 },
    { lat: 49.1618403, lng: 18.2278953 },
    { lat: 49.1618833, lng: 18.2275602 },
    { lat: 49.1619254, lng: 18.2273474 },
    { lat: 49.161957, lng: 18.2271695 },
    { lat: 49.1619708, lng: 18.2270935 },
    { lat: 49.1619951, lng: 18.2269851 },
    { lat: 49.1620172, lng: 18.2267988 },
    { lat: 49.1620598, lng: 18.2266758 },
    { lat: 49.1619172, lng: 18.2266469 },
    { lat: 49.1614378, lng: 18.2264706 },
    { lat: 49.1614851, lng: 18.2259939 },
    { lat: 49.1608942, lng: 18.2257377 },
    { lat: 49.1609761, lng: 18.2248361 },
    { lat: 49.1610171, lng: 18.2243476 },
    { lat: 49.1605226, lng: 18.2239235 },
    { lat: 49.1602767, lng: 18.2236476 },
    { lat: 49.1604333, lng: 18.2235422 },
    { lat: 49.1602367, lng: 18.2231806 },
    { lat: 49.1600943, lng: 18.2229377 },
    { lat: 49.159874, lng: 18.2215832 },
    { lat: 49.1596502, lng: 18.2207014 },
    { lat: 49.159565, lng: 18.2206721 },
    { lat: 49.1595088, lng: 18.2206559 },
    { lat: 49.1595268, lng: 18.2203175 },
    { lat: 49.1596146, lng: 18.2196384 },
    { lat: 49.1598396, lng: 18.2193295 },
    { lat: 49.160292, lng: 18.2190707 },
    { lat: 49.1607217, lng: 18.2186522 },
    { lat: 49.1605917, lng: 18.2175545 },
    { lat: 49.160038, lng: 18.2173602 },
    { lat: 49.1594935, lng: 18.2171715 },
    { lat: 49.1590307, lng: 18.2169875 },
    { lat: 49.1590964, lng: 18.2166357 },
    { lat: 49.1590693, lng: 18.2156053 },
    { lat: 49.159031, lng: 18.2140375 },
    { lat: 49.1591775, lng: 18.2137366 },
    { lat: 49.1595058, lng: 18.2131034 },
    { lat: 49.1596821, lng: 18.2127686 },
    { lat: 49.1599952, lng: 18.2117479 },
    { lat: 49.1602339, lng: 18.2110656 },
    { lat: 49.1606901, lng: 18.2108151 },
    { lat: 49.1607568, lng: 18.2097672 },
    { lat: 49.1609428, lng: 18.2088514 },
    { lat: 49.1610054, lng: 18.2086232 },
    { lat: 49.1612144, lng: 18.2078352 },
    { lat: 49.1613264, lng: 18.2065525 },
    { lat: 49.161444, lng: 18.2057636 },
    { lat: 49.1619082, lng: 18.2058319 },
    { lat: 49.1623847, lng: 18.2058768 },
    { lat: 49.162639, lng: 18.2059248 },
    { lat: 49.1628185, lng: 18.2050743 },
    { lat: 49.1628838, lng: 18.2047559 },
    { lat: 49.1621921, lng: 18.2037241 },
    { lat: 49.1622518, lng: 18.2035434 },
    { lat: 49.1623855, lng: 18.2033358 },
    { lat: 49.1623873, lng: 18.2028352 },
    { lat: 49.1624074, lng: 18.2022943 },
    { lat: 49.1630517, lng: 18.2011276 },
    { lat: 49.1630819, lng: 18.2010634 },
    { lat: 49.163229, lng: 18.2010915 },
    { lat: 49.1634029, lng: 18.2010737 },
    { lat: 49.1635822, lng: 18.2010121 },
    { lat: 49.1637859, lng: 18.2010621 },
    { lat: 49.1642967, lng: 18.2011368 },
    { lat: 49.1645752, lng: 18.2011129 },
    { lat: 49.1647741, lng: 18.2011183 },
    { lat: 49.1649352, lng: 18.2012134 },
    { lat: 49.1652811, lng: 18.2014228 },
    { lat: 49.1656541, lng: 18.2013368 },
    { lat: 49.1659196, lng: 18.200828 },
    { lat: 49.1662558, lng: 18.2002314 },
    { lat: 49.166871, lng: 18.1998609 },
    { lat: 49.1672381, lng: 18.1997089 },
    { lat: 49.1675525, lng: 18.1996449 },
    { lat: 49.1677573, lng: 18.1995938 },
    { lat: 49.168179, lng: 18.1993693 },
    { lat: 49.1687771, lng: 18.1989359 },
    { lat: 49.1691046, lng: 18.1989832 },
    { lat: 49.169362, lng: 18.1990446 },
    { lat: 49.1696147, lng: 18.1990034 },
    { lat: 49.170169, lng: 18.1986705 },
    { lat: 49.1705524, lng: 18.198528 },
    { lat: 49.1709679, lng: 18.1983365 },
    { lat: 49.1709681, lng: 18.1983404 },
    { lat: 49.1709967, lng: 18.1983469 },
    { lat: 49.1712458, lng: 18.1984034 },
    { lat: 49.1713448, lng: 18.1990307 },
    { lat: 49.1714697, lng: 18.1994186 },
    { lat: 49.1716125, lng: 18.1997085 },
    { lat: 49.1718305, lng: 18.2006215 },
    { lat: 49.1718478, lng: 18.2007685 },
    { lat: 49.171939, lng: 18.2015173 },
    { lat: 49.1721895, lng: 18.202272 },
    { lat: 49.1724307, lng: 18.2028004 },
    { lat: 49.1725022, lng: 18.2031252 },
    { lat: 49.1725513, lng: 18.2034933 },
    { lat: 49.1725868, lng: 18.2035009 },
    { lat: 49.1725395, lng: 18.2040969 },
    { lat: 49.1725715, lng: 18.2044196 },
    { lat: 49.1726079, lng: 18.2048024 },
    { lat: 49.1726787, lng: 18.2049755 },
    { lat: 49.173126, lng: 18.2058372 },
    { lat: 49.1732802, lng: 18.2061073 },
    { lat: 49.1734736, lng: 18.2064318 },
    { lat: 49.1737028, lng: 18.2068326 },
    { lat: 49.174037, lng: 18.2074693 },
    { lat: 49.1740951, lng: 18.2077391 },
    { lat: 49.1742333, lng: 18.208322 },
    { lat: 49.1742813, lng: 18.2085294 },
    { lat: 49.1742979, lng: 18.2089629 },
    { lat: 49.1743591, lng: 18.2094414 },
    { lat: 49.1743888, lng: 18.2094297 },
    { lat: 49.1746512, lng: 18.2093012 },
    { lat: 49.1745648, lng: 18.2085159 },
    { lat: 49.1743933, lng: 18.208238 },
    { lat: 49.1744473, lng: 18.207206 },
    { lat: 49.1744783, lng: 18.2068779 },
    { lat: 49.1741253, lng: 18.2068394 },
    { lat: 49.1743516, lng: 18.2058613 },
    { lat: 49.1741526, lng: 18.2057142 },
    { lat: 49.173873, lng: 18.2053816 },
    { lat: 49.1737002, lng: 18.2054983 },
    { lat: 49.1735864, lng: 18.2053423 },
    { lat: 49.1731709, lng: 18.2044069 },
    { lat: 49.1731625, lng: 18.2040016 },
    { lat: 49.1733023, lng: 18.2036436 },
    { lat: 49.1734411, lng: 18.2028911 },
    { lat: 49.1733384, lng: 18.202731 },
    { lat: 49.1733182, lng: 18.202377 },
    { lat: 49.1734015, lng: 18.2023522 },
    { lat: 49.1736613, lng: 18.2024377 },
    { lat: 49.1737392, lng: 18.2022395 },
    { lat: 49.1736804, lng: 18.2018609 },
    { lat: 49.1735892, lng: 18.2017857 },
    { lat: 49.1736297, lng: 18.2011306 },
    { lat: 49.1735211, lng: 18.2010057 },
    { lat: 49.1736585, lng: 18.2005276 },
    { lat: 49.1735199, lng: 18.2004302 },
    { lat: 49.1735896, lng: 18.2000493 },
    { lat: 49.1736136, lng: 18.1999171 },
    { lat: 49.1735963, lng: 18.1995355 },
    { lat: 49.1735008, lng: 18.1990664 },
    { lat: 49.1733395, lng: 18.1983534 },
    { lat: 49.1732981, lng: 18.1980426 },
    { lat: 49.1732539, lng: 18.1977023 },
    { lat: 49.1732233, lng: 18.197464 },
    { lat: 49.1732101, lng: 18.1972123 },
    { lat: 49.1731857, lng: 18.1967272 },
    { lat: 49.1731847, lng: 18.1966302 },
    { lat: 49.1732016, lng: 18.1964705 },
    { lat: 49.1734713, lng: 18.1962527 },
    { lat: 49.1735521, lng: 18.1958105 },
    { lat: 49.173479, lng: 18.1957168 },
    { lat: 49.1734883, lng: 18.1955234 },
    { lat: 49.1735461, lng: 18.195052 },
    { lat: 49.1735732, lng: 18.1949141 },
    { lat: 49.1731942, lng: 18.1949553 },
    { lat: 49.1725351, lng: 18.1947545 },
    { lat: 49.1723554, lng: 18.1946503 },
    { lat: 49.1723309, lng: 18.1946353 },
    { lat: 49.1722048, lng: 18.1945614 },
    { lat: 49.1719332, lng: 18.1940132 },
    { lat: 49.1716325, lng: 18.1931341 },
    { lat: 49.1715528, lng: 18.1923285 },
    { lat: 49.1715294, lng: 18.1913865 },
    { lat: 49.1715295, lng: 18.1912493 },
    { lat: 49.1715294, lng: 18.191208 },
    { lat: 49.1715292, lng: 18.1910054 },
    { lat: 49.17153, lng: 18.1909618 },
    { lat: 49.1715297, lng: 18.1905226 },
    { lat: 49.171763, lng: 18.189586 },
    { lat: 49.1718737, lng: 18.1888589 },
    { lat: 49.17201, lng: 18.1884187 },
    { lat: 49.1720847, lng: 18.1879876 },
    { lat: 49.1724501, lng: 18.1871548 },
    { lat: 49.1726615, lng: 18.1865705 },
    { lat: 49.1726454, lng: 18.186053 },
    { lat: 49.1726093, lng: 18.1856169 },
    { lat: 49.172371, lng: 18.1841099 },
    { lat: 49.1722281, lng: 18.1836647 },
    { lat: 49.1718784, lng: 18.1832103 },
    { lat: 49.1712701, lng: 18.182378 },
    { lat: 49.1709719, lng: 18.1814644 },
    { lat: 49.1709738, lng: 18.1811625 },
    { lat: 49.1709741, lng: 18.1811078 },
    { lat: 49.1709761, lng: 18.1807303 },
    { lat: 49.1709743, lng: 18.1803024 },
    { lat: 49.1710668, lng: 18.1801063 },
    { lat: 49.1713674, lng: 18.1796392 },
    { lat: 49.1717687, lng: 18.1788446 },
    { lat: 49.1720823, lng: 18.1782512 },
    { lat: 49.1722959, lng: 18.177705 },
    { lat: 49.1725312, lng: 18.177281 },
    { lat: 49.1728087, lng: 18.1761947 },
    { lat: 49.1727887, lng: 18.1753109 },
    { lat: 49.1725671, lng: 18.1745936 },
    { lat: 49.172369, lng: 18.1741288 },
    { lat: 49.1720234, lng: 18.1730194 },
    { lat: 49.1719804, lng: 18.1729185 },
    { lat: 49.1719507, lng: 18.1728536 },
    { lat: 49.1717977, lng: 18.172486 },
    { lat: 49.1715126, lng: 18.1721191 },
    { lat: 49.1712427, lng: 18.1716818 },
    { lat: 49.1708816, lng: 18.1712463 },
    { lat: 49.1704414, lng: 18.1705727 },
    { lat: 49.1699622, lng: 18.1702236 },
    { lat: 49.169706, lng: 18.1699685 },
    { lat: 49.1695297, lng: 18.1697077 },
    { lat: 49.1694009, lng: 18.1693291 },
    { lat: 49.1690287, lng: 18.1691738 },
    { lat: 49.1688341, lng: 18.1686142 },
    { lat: 49.1683652, lng: 18.1682666 },
    { lat: 49.1679574, lng: 18.1678262 },
    { lat: 49.1676519, lng: 18.1676338 },
    { lat: 49.1673097, lng: 18.1669 },
    { lat: 49.1671423, lng: 18.1659276 },
    { lat: 49.1670817, lng: 18.1652439 },
    { lat: 49.1671697, lng: 18.1650671 },
    { lat: 49.1675013, lng: 18.1648264 },
    { lat: 49.1675154, lng: 18.1646597 },
    { lat: 49.167521, lng: 18.1645814 },
    { lat: 49.1675316, lng: 18.1644506 },
    { lat: 49.1674785, lng: 18.1640365 },
    { lat: 49.1673367, lng: 18.162921 },
    { lat: 49.1669817, lng: 18.1619435 },
    { lat: 49.1666968, lng: 18.1611652 },
    { lat: 49.1665393, lng: 18.1602685 },
    { lat: 49.1664505, lng: 18.1598231 },
    { lat: 49.1662372, lng: 18.1593293 },
    { lat: 49.1660668, lng: 18.1589163 },
    { lat: 49.1659437, lng: 18.158619 },
    { lat: 49.1657949, lng: 18.1583071 },
    { lat: 49.1658615, lng: 18.1563212 },
    { lat: 49.1658354, lng: 18.1555513 },
    { lat: 49.1658327, lng: 18.1554454 },
    { lat: 49.1654824, lng: 18.154631 },
    { lat: 49.1650399, lng: 18.1536833 },
    { lat: 49.1648506, lng: 18.152902 },
    { lat: 49.1644092, lng: 18.1521698 },
    { lat: 49.1641118, lng: 18.151708 },
    { lat: 49.1639254, lng: 18.1512723 },
    { lat: 49.1637315, lng: 18.1507656 },
    { lat: 49.1635363, lng: 18.1506071 },
    { lat: 49.16323, lng: 18.1499508 },
    { lat: 49.1632152, lng: 18.1492602 },
    { lat: 49.1630904, lng: 18.148069 },
    { lat: 49.1630132, lng: 18.1476088 },
    { lat: 49.1633545, lng: 18.146892 },
    { lat: 49.1633685, lng: 18.1468618 },
    { lat: 49.1634099, lng: 18.1467754 },
    { lat: 49.1633552, lng: 18.1461212 },
    { lat: 49.1628906, lng: 18.1453573 },
    { lat: 49.1625758, lng: 18.1442784 },
    { lat: 49.1622542, lng: 18.1434744 },
    { lat: 49.162106, lng: 18.1427221 },
    { lat: 49.1618774, lng: 18.1415122 },
    { lat: 49.1617023, lng: 18.14047 },
    { lat: 49.1615334, lng: 18.1395536 },
    { lat: 49.1612788, lng: 18.1381883 },
    { lat: 49.1610546, lng: 18.1369 },
    { lat: 49.16102, lng: 18.136714 },
    { lat: 49.160921, lng: 18.136319 },
    { lat: 49.160802, lng: 18.135838 },
    { lat: 49.160391, lng: 18.134503 },
    { lat: 49.160057, lng: 18.13323 },
    { lat: 49.159779, lng: 18.132439 },
    { lat: 49.159576, lng: 18.132007 },
    { lat: 49.159453, lng: 18.13187 },
    { lat: 49.159168, lng: 18.131641 },
    { lat: 49.159058, lng: 18.131548 },
    { lat: 49.158912, lng: 18.131424 },
    { lat: 49.158607, lng: 18.131132 },
    { lat: 49.157834, lng: 18.130382 },
    { lat: 49.15749, lng: 18.130072 },
    { lat: 49.157315, lng: 18.129756 },
    { lat: 49.157049, lng: 18.129546 },
    { lat: 49.156787, lng: 18.129338 },
    { lat: 49.156313, lng: 18.128896 },
    { lat: 49.155732, lng: 18.128757 },
    { lat: 49.155396, lng: 18.128432 },
    { lat: 49.155277, lng: 18.128207 },
    { lat: 49.155203, lng: 18.127903 },
    { lat: 49.155088, lng: 18.126883 },
    { lat: 49.154989, lng: 18.126009 },
    { lat: 49.154922, lng: 18.125573 },
    { lat: 49.154756, lng: 18.125315 },
    { lat: 49.154141, lng: 18.124478 },
    { lat: 49.153952, lng: 18.123712 },
    { lat: 49.153956, lng: 18.12298 },
    { lat: 49.153867, lng: 18.12198 },
    { lat: 49.153731, lng: 18.121394 },
    { lat: 49.153358, lng: 18.120625 },
    { lat: 49.153208, lng: 18.120251 },
    { lat: 49.153087, lng: 18.120067 },
    { lat: 49.152987, lng: 18.119756 },
    { lat: 49.152894, lng: 18.119514 },
    { lat: 49.152744, lng: 18.11927 },
    { lat: 49.152754, lng: 18.11899 },
    { lat: 49.15276, lng: 18.118772 },
    { lat: 49.1527603, lng: 18.1187717 },
    { lat: 49.152621, lng: 18.118656 },
    { lat: 49.152341, lng: 18.118425 },
    { lat: 49.152168, lng: 18.118284 },
    { lat: 49.151627, lng: 18.117816 },
    { lat: 49.151113, lng: 18.117418 },
    { lat: 49.150915, lng: 18.117284 },
    { lat: 49.150783, lng: 18.117221 },
    { lat: 49.150475, lng: 18.117272 },
    { lat: 49.149712, lng: 18.117583 },
    { lat: 49.149524, lng: 18.117564 },
    { lat: 49.149077, lng: 18.117515 },
    { lat: 49.149023, lng: 18.117518 },
    { lat: 49.148888, lng: 18.117487 },
    { lat: 49.148795, lng: 18.117518 },
    { lat: 49.148664, lng: 18.117428 },
    { lat: 49.148537, lng: 18.117403 },
    { lat: 49.14843, lng: 18.117446 },
    { lat: 49.148308, lng: 18.11746 },
    { lat: 49.148205, lng: 18.117518 },
    { lat: 49.148052, lng: 18.117666 },
    { lat: 49.147876, lng: 18.117801 },
    { lat: 49.147749, lng: 18.117826 },
    { lat: 49.147697, lng: 18.117849 },
    { lat: 49.14758, lng: 18.117857 },
    { lat: 49.147514, lng: 18.117772 },
    { lat: 49.147329, lng: 18.117567 },
    { lat: 49.147137, lng: 18.117459 },
    { lat: 49.147106, lng: 18.117521 },
    { lat: 49.146905, lng: 18.117494 },
    { lat: 49.146762, lng: 18.117605 },
    { lat: 49.146704, lng: 18.117622 },
    { lat: 49.146612, lng: 18.1176 },
    { lat: 49.146534, lng: 18.117539 },
    { lat: 49.146469, lng: 18.117442 },
    { lat: 49.146319, lng: 18.117351 },
    { lat: 49.146125, lng: 18.117247 },
    { lat: 49.145885, lng: 18.117205 },
    { lat: 49.145733, lng: 18.117235 },
    { lat: 49.145712, lng: 18.117206 },
    { lat: 49.145697, lng: 18.117262 },
    { lat: 49.145673, lng: 18.117371 },
    { lat: 49.145658, lng: 18.117383 },
    { lat: 49.145448, lng: 18.1176 },
    { lat: 49.145355, lng: 18.11766 },
    { lat: 49.145221, lng: 18.117798 },
    { lat: 49.145093, lng: 18.117899 },
    { lat: 49.144828, lng: 18.118 },
    { lat: 49.144692, lng: 18.11802 },
    { lat: 49.144551, lng: 18.118029 },
    { lat: 49.14444, lng: 18.117997 },
    { lat: 49.144408, lng: 18.118018 },
    { lat: 49.144335, lng: 18.118138 },
    { lat: 49.144243, lng: 18.118144 },
    { lat: 49.144066, lng: 18.118233 },
    { lat: 49.143896, lng: 18.118299 },
    { lat: 49.143784, lng: 18.118411 },
    { lat: 49.143716, lng: 18.118421 },
    { lat: 49.143606, lng: 18.118361 },
    { lat: 49.143496, lng: 18.118406 },
    { lat: 49.143456, lng: 18.118356 },
    { lat: 49.14345, lng: 18.118399 },
    { lat: 49.143493, lng: 18.11875 },
    { lat: 49.143623, lng: 18.1191 },
    { lat: 49.143593, lng: 18.119451 },
    { lat: 49.143745, lng: 18.120189 },
    { lat: 49.143923, lng: 18.120563 },
    { lat: 49.144416, lng: 18.121441 },
    { lat: 49.144894, lng: 18.122289 },
    { lat: 49.145047, lng: 18.122896 },
    { lat: 49.145052, lng: 18.123187 },
    { lat: 49.144748, lng: 18.123787 },
    { lat: 49.144465, lng: 18.124867 },
    { lat: 49.144172, lng: 18.125988 },
    { lat: 49.14415, lng: 18.126391 },
    { lat: 49.144183, lng: 18.126795 },
    { lat: 49.14423, lng: 18.127149 },
    { lat: 49.144332, lng: 18.127993 },
    { lat: 49.144302, lng: 18.128511 },
    { lat: 49.144221, lng: 18.128914 },
    { lat: 49.144019, lng: 18.129472 },
    { lat: 49.143785, lng: 18.130041 },
    { lat: 49.143772, lng: 18.130241 },
    { lat: 49.143755, lng: 18.130922 },
    { lat: 49.143786, lng: 18.13119 },
    { lat: 49.143738, lng: 18.131785 },
    { lat: 49.143685, lng: 18.132454 },
    { lat: 49.143577, lng: 18.133168 },
    { lat: 49.143468, lng: 18.133805 },
    { lat: 49.143386, lng: 18.134283 },
    { lat: 49.143219, lng: 18.134745 },
    { lat: 49.142952, lng: 18.135266 },
    { lat: 49.142893, lng: 18.135495 },
    { lat: 49.142902, lng: 18.135992 },
    { lat: 49.14293, lng: 18.136371 },
    { lat: 49.14317, lng: 18.137502 },
    { lat: 49.143333, lng: 18.138449 },
    { lat: 49.14335, lng: 18.138929 },
    { lat: 49.143283, lng: 18.139387 },
    { lat: 49.142926, lng: 18.140728 },
    { lat: 49.1422239, lng: 18.1408756 },
    { lat: 49.142165, lng: 18.140888 },
    { lat: 49.141901, lng: 18.140877 },
    { lat: 49.14174, lng: 18.140912 },
    { lat: 49.141585, lng: 18.141052 },
    { lat: 49.14131, lng: 18.141519 },
    { lat: 49.141116, lng: 18.14185 },
    { lat: 49.140924, lng: 18.142061 },
    { lat: 49.139972, lng: 18.143442 },
    { lat: 49.139804, lng: 18.145054 },
    { lat: 49.139802, lng: 18.145457 },
    { lat: 49.139675, lng: 18.145968 },
    { lat: 49.139298, lng: 18.147992 },
    { lat: 49.139067, lng: 18.149045 },
    { lat: 49.139001, lng: 18.149279 },
    { lat: 49.1389395, lng: 18.1505198 },
    { lat: 49.1390299, lng: 18.1518977 },
    { lat: 49.1390446, lng: 18.1522487 },
    { lat: 49.1390816, lng: 18.1525611 },
    { lat: 49.1391086, lng: 18.1529732 },
    { lat: 49.1390115, lng: 18.1537179 },
    { lat: 49.1388441, lng: 18.1542992 },
    { lat: 49.1388898, lng: 18.1546843 },
    { lat: 49.1389947, lng: 18.1549968 },
    { lat: 49.1394189, lng: 18.1562536 },
    { lat: 49.1396061, lng: 18.1573345 },
    { lat: 49.1399074, lng: 18.1582978 },
    { lat: 49.140221, lng: 18.158729 },
    { lat: 49.1402418, lng: 18.158932 },
    { lat: 49.1401582, lng: 18.1592843 },
    { lat: 49.1400782, lng: 18.1598977 },
    { lat: 49.1402246, lng: 18.1608361 },
    { lat: 49.1405296, lng: 18.1616103 },
    { lat: 49.1406582, lng: 18.1617659 },
    { lat: 49.1411467, lng: 18.16202 },
    { lat: 49.141282, lng: 18.1621167 },
    { lat: 49.1413783, lng: 18.162183 },
    { lat: 49.1416935, lng: 18.1627987 },
    { lat: 49.1421295, lng: 18.1633964 },
    { lat: 49.1425445, lng: 18.1644163 },
    { lat: 49.1426082, lng: 18.1648587 },
    { lat: 49.142645, lng: 18.1654597 },
    { lat: 49.1426283, lng: 18.1665871 },
    { lat: 49.1425655, lng: 18.1672252 },
    { lat: 49.1425093, lng: 18.1677187 },
    { lat: 49.1425029, lng: 18.1689878 },
    { lat: 49.1423538, lng: 18.1693972 },
    { lat: 49.142233, lng: 18.1699672 },
    { lat: 49.1422259, lng: 18.1703942 },
    { lat: 49.1423566, lng: 18.170903 },
    { lat: 49.1424297, lng: 18.1713329 },
    { lat: 49.142586, lng: 18.172052 },
    { lat: 49.142558, lng: 18.1731413 },
    { lat: 49.1424603, lng: 18.1736999 },
    { lat: 49.1420087, lng: 18.1752124 },
    { lat: 49.1420073, lng: 18.1756018 },
    { lat: 49.142002, lng: 18.1757855 },
    { lat: 49.1420072, lng: 18.176901 },
    { lat: 49.1420757, lng: 18.1773692 },
    { lat: 49.1416555, lng: 18.1780107 },
    { lat: 49.1412609, lng: 18.1786299 },
    { lat: 49.141103, lng: 18.1787471 },
    { lat: 49.1409774, lng: 18.1789198 },
    { lat: 49.1408247, lng: 18.1791308 },
    { lat: 49.1406481, lng: 18.1795939 },
    { lat: 49.1408729, lng: 18.1797765 },
    { lat: 49.141149, lng: 18.1800063 },
    { lat: 49.141132, lng: 18.1803788 },
    { lat: 49.1411284, lng: 18.1804752 },
    { lat: 49.1411227, lng: 18.1806927 },
    { lat: 49.1414683, lng: 18.1809524 },
    { lat: 49.1414171, lng: 18.1812607 },
    { lat: 49.141405, lng: 18.1813453 },
    { lat: 49.1413833, lng: 18.181499 },
    { lat: 49.1415145, lng: 18.1816231 },
    { lat: 49.1413606, lng: 18.182405 },
    { lat: 49.1405035, lng: 18.1823347 },
    { lat: 49.1399335, lng: 18.1824277 },
    { lat: 49.1399065, lng: 18.1824093 },
    { lat: 49.1398867, lng: 18.1824782 },
    { lat: 49.1401541, lng: 18.1836026 },
    { lat: 49.1403863, lng: 18.184368 },
    { lat: 49.1406857, lng: 18.185124 },
    { lat: 49.1408603, lng: 18.1862273 },
    { lat: 49.1408933, lng: 18.1866255 },
    { lat: 49.1409472, lng: 18.1866212 },
    { lat: 49.1418323, lng: 18.1865511 },
    { lat: 49.141826, lng: 18.187803 },
    { lat: 49.1420819, lng: 18.1877183 },
    { lat: 49.1423855, lng: 18.1883157 },
    { lat: 49.1422729, lng: 18.1883969 },
    { lat: 49.1425055, lng: 18.1891704 },
    { lat: 49.1419542, lng: 18.1892582 },
    { lat: 49.1417465, lng: 18.1888605 },
    { lat: 49.1414142, lng: 18.1889482 },
    { lat: 49.1413688, lng: 18.1889838 },
    { lat: 49.1410381, lng: 18.1891012 },
    { lat: 49.1407029, lng: 18.1890794 },
    { lat: 49.1404193, lng: 18.1890729 },
    { lat: 49.1403235, lng: 18.1893517 },
    { lat: 49.1402926, lng: 18.1897187 },
    { lat: 49.1401703, lng: 18.1900665 },
    { lat: 49.1397349, lng: 18.1905239 },
    { lat: 49.1395737, lng: 18.1902509 },
    { lat: 49.1389901, lng: 18.1906421 },
    { lat: 49.1384936, lng: 18.1895927 },
    { lat: 49.1373271, lng: 18.1904048 },
    { lat: 49.1368124, lng: 18.1908572 },
    { lat: 49.1361561, lng: 18.1913965 },
    { lat: 49.1359811, lng: 18.1916517 },
    { lat: 49.1356166, lng: 18.1914133 },
    { lat: 49.1352913, lng: 18.191469 },
    { lat: 49.1352335, lng: 18.1915828 },
    { lat: 49.1351593, lng: 18.1917247 },
    { lat: 49.134972, lng: 18.1921389 },
    { lat: 49.1346445, lng: 18.192332 },
    { lat: 49.1341753, lng: 18.1927506 },
    { lat: 49.1337769, lng: 18.1926103 },
    { lat: 49.1334325, lng: 18.1922297 },
    { lat: 49.1333568, lng: 18.1923475 },
    { lat: 49.1331773, lng: 18.1922859 },
    { lat: 49.1333144, lng: 18.1929304 },
    { lat: 49.1332399, lng: 18.1933042 },
    { lat: 49.1331356, lng: 18.1937886 },
    { lat: 49.1332021, lng: 18.1941231 },
    { lat: 49.1330602, lng: 18.1944659 },
    { lat: 49.1328421, lng: 18.1951099 },
    { lat: 49.1328781, lng: 18.1952869 },
    { lat: 49.1327939, lng: 18.1956311 },
    { lat: 49.1325222, lng: 18.1959909 },
    { lat: 49.132534, lng: 18.1961183 },
    { lat: 49.132752, lng: 18.1964197 },
    { lat: 49.1327625, lng: 18.1966809 },
    { lat: 49.1327654, lng: 18.1971877 },
    { lat: 49.1327946, lng: 18.1976584 },
    { lat: 49.1328724, lng: 18.1981149 },
    { lat: 49.1322789, lng: 18.1985886 },
    { lat: 49.1318308, lng: 18.1990603 },
    { lat: 49.1313928, lng: 18.199234 },
    { lat: 49.1309543, lng: 18.199598 },
    { lat: 49.1304689, lng: 18.1999091 },
    { lat: 49.1302011, lng: 18.2000807 },
    { lat: 49.1297831, lng: 18.2003885 },
    { lat: 49.129549, lng: 18.2006175 },
    { lat: 49.1289873, lng: 18.2017882 },
    { lat: 49.1287159, lng: 18.2020153 },
    { lat: 49.1287964, lng: 18.202615 },
    { lat: 49.128627, lng: 18.2031856 },
    { lat: 49.1285214, lng: 18.2034117 },
    { lat: 49.1284744, lng: 18.2038327 },
    { lat: 49.128337, lng: 18.2041149 },
    { lat: 49.1284472, lng: 18.2045831 },
    { lat: 49.1284262, lng: 18.2051252 },
    { lat: 49.1283756, lng: 18.2053085 },
    { lat: 49.1281072, lng: 18.2055476 },
    { lat: 49.1284692, lng: 18.2063364 },
    { lat: 49.1285001, lng: 18.2064024 },
    { lat: 49.1285271, lng: 18.2064611 },
    { lat: 49.1288914, lng: 18.2072906 },
    { lat: 49.1292409, lng: 18.208059 },
    { lat: 49.1289009, lng: 18.2080715 },
    { lat: 49.1289007, lng: 18.2083054 },
    { lat: 49.1288251, lng: 18.2086229 },
    { lat: 49.1287534, lng: 18.209026 },
    { lat: 49.1290879, lng: 18.2092944 },
    { lat: 49.1295059, lng: 18.2093022 },
    { lat: 49.1295606, lng: 18.2102638 },
    { lat: 49.1296915, lng: 18.2103816 },
    { lat: 49.1295962, lng: 18.2119182 },
    { lat: 49.1298499, lng: 18.2120554 },
    { lat: 49.1296774, lng: 18.2129696 },
    { lat: 49.1297542, lng: 18.2133855 },
    { lat: 49.129848, lng: 18.2138244 },
    { lat: 49.1301781, lng: 18.2146289 },
    { lat: 49.1303947, lng: 18.2148474 },
    { lat: 49.130222, lng: 18.2155612 },
    { lat: 49.1300583, lng: 18.2160747 },
    { lat: 49.1298569, lng: 18.2166386 },
    { lat: 49.1301439, lng: 18.2163098 },
    { lat: 49.1301804, lng: 18.216336 },
    { lat: 49.1306168, lng: 18.2165489 },
    { lat: 49.1311221, lng: 18.2166448 },
    { lat: 49.1312283, lng: 18.2176185 },
    { lat: 49.131323, lng: 18.2176184 },
    { lat: 49.1313636, lng: 18.2184708 },
    { lat: 49.1315435, lng: 18.2197102 },
    { lat: 49.1316461, lng: 18.2197475 },
    { lat: 49.1315707, lng: 18.2204469 },
    { lat: 49.1318676, lng: 18.2204889 },
    { lat: 49.1318785, lng: 18.2209782 },
    { lat: 49.1319217, lng: 18.2214189 },
    { lat: 49.1319517, lng: 18.2223841 },
    { lat: 49.1313953, lng: 18.2224008 },
    { lat: 49.1310956, lng: 18.2223703 },
    { lat: 49.1308778, lng: 18.2221699 },
    { lat: 49.1303234, lng: 18.2222238 },
    { lat: 49.1302133, lng: 18.2225286 },
    { lat: 49.1301776, lng: 18.2226136 },
    { lat: 49.1302781, lng: 18.2233714 },
    { lat: 49.1303983, lng: 18.2235796 },
    { lat: 49.1304204, lng: 18.22381 },
    { lat: 49.1304495, lng: 18.223924 },
    { lat: 49.1304838, lng: 18.2249657 },
    { lat: 49.1305902, lng: 18.2252079 },
    { lat: 49.1306854, lng: 18.2258134 },
    { lat: 49.1311219, lng: 18.2257905 },
    { lat: 49.1317774, lng: 18.2252535 },
    { lat: 49.1318529, lng: 18.225548 },
    { lat: 49.132503, lng: 18.2259712 },
    { lat: 49.1326457, lng: 18.2263189 },
    { lat: 49.1328435, lng: 18.226958 },
    { lat: 49.1332343, lng: 18.2276033 },
    { lat: 49.1336397, lng: 18.227014 },
    { lat: 49.134371, lng: 18.2273535 },
    { lat: 49.1347745, lng: 18.2273266 },
    { lat: 49.1350595, lng: 18.2277751 },
    { lat: 49.1355422, lng: 18.2291421 },
    { lat: 49.1355804, lng: 18.2290601 },
    { lat: 49.1357603, lng: 18.2287312 },
    { lat: 49.1359603, lng: 18.2283801 },
    { lat: 49.136601, lng: 18.2288331 },
    { lat: 49.1370424, lng: 18.2291548 },
    { lat: 49.1378734, lng: 18.2299421 },
    { lat: 49.1380128, lng: 18.2300895 },
    { lat: 49.1385043, lng: 18.2309969 },
    { lat: 49.1390793, lng: 18.2315679 },
    { lat: 49.1401818, lng: 18.2330816 },
    { lat: 49.1402058, lng: 18.2331668 },
    { lat: 49.1403004, lng: 18.2333029 },
    { lat: 49.1404723, lng: 18.2335882 },
    { lat: 49.1406165, lng: 18.2343587 },
    { lat: 49.1410587, lng: 18.2356716 },
    { lat: 49.1411531, lng: 18.2361197 },
    { lat: 49.1411592, lng: 18.2362878 },
    { lat: 49.1413229, lng: 18.2365896 },
    { lat: 49.1414144, lng: 18.23665 },
    { lat: 49.141654, lng: 18.2366981 },
    { lat: 49.141869, lng: 18.236868 },
    { lat: 49.1419022, lng: 18.2369762 },
    { lat: 49.141967, lng: 18.2369651 },
    { lat: 49.1420773, lng: 18.236861 },
    { lat: 49.1421445, lng: 18.2369726 },
    { lat: 49.1423074, lng: 18.2369997 },
    { lat: 49.1423663, lng: 18.2370825 },
    { lat: 49.1422917, lng: 18.237299 },
    { lat: 49.1421444, lng: 18.2377857 },
    { lat: 49.1421924, lng: 18.2381947 },
    { lat: 49.142978, lng: 18.2390762 },
    { lat: 49.1430938, lng: 18.239208 },
    { lat: 49.1432014, lng: 18.2389952 },
    { lat: 49.143705, lng: 18.2376308 },
    { lat: 49.1439349, lng: 18.2380431 },
    { lat: 49.1440337, lng: 18.2382917 },
    { lat: 49.1441601, lng: 18.2385718 },
    { lat: 49.1444699, lng: 18.2392606 },
    { lat: 49.1447339, lng: 18.2393996 },
    { lat: 49.1449243, lng: 18.2399133 },
    { lat: 49.1450062, lng: 18.2400641 },
    { lat: 49.1461646, lng: 18.2406949 },
    { lat: 49.1466598, lng: 18.2410512 },
    { lat: 49.1467161, lng: 18.2410906 },
    { lat: 49.1467759, lng: 18.2411098 },
    { lat: 49.1473021, lng: 18.2415948 },
    { lat: 49.147323, lng: 18.2416048 },
    { lat: 49.1474274, lng: 18.241656 },
    { lat: 49.1475935, lng: 18.2417211 },
    { lat: 49.1478129, lng: 18.241807 },
    { lat: 49.148249, lng: 18.2419796 },
    { lat: 49.1484853, lng: 18.2424833 },
    { lat: 49.1488045, lng: 18.2426215 },
    { lat: 49.149071, lng: 18.242847 },
    { lat: 49.149196, lng: 18.243061 },
    { lat: 49.1494961, lng: 18.2434207 },
    { lat: 49.1495191, lng: 18.2437258 },
    { lat: 49.1496978, lng: 18.2453871 },
    { lat: 49.149449, lng: 18.2463529 },
    { lat: 49.1492698, lng: 18.2470334 },
    { lat: 49.1495426, lng: 18.2483254 },
    { lat: 49.1495458, lng: 18.2484034 },
    { lat: 49.1495446, lng: 18.248461 },
    { lat: 49.1495461, lng: 18.2485279 },
    { lat: 49.1495549, lng: 18.2490218 },
    { lat: 49.1496109, lng: 18.2494528 },
    { lat: 49.1496701, lng: 18.2498821 },
    { lat: 49.1500709, lng: 18.250264 },
    { lat: 49.1506595, lng: 18.2498603 },
    { lat: 49.1508748, lng: 18.2498956 },
    { lat: 49.1508981, lng: 18.2499084 },
    { lat: 49.1509357, lng: 18.2499155 },
    { lat: 49.1508723, lng: 18.2505504 },
    { lat: 49.1511424, lng: 18.250877 },
    { lat: 49.1512205, lng: 18.2508413 },
    { lat: 49.1512467, lng: 18.2508267 },
    { lat: 49.1513305, lng: 18.2507717 },
    { lat: 49.1514174, lng: 18.2508523 },
    { lat: 49.1517448, lng: 18.2496402 },
    { lat: 49.1523202, lng: 18.2486826 },
    { lat: 49.1527203, lng: 18.2480756 },
    { lat: 49.1528358, lng: 18.2478643 },
    { lat: 49.1527351, lng: 18.2470033 },
    { lat: 49.1529171, lng: 18.2466087 },
    { lat: 49.1525841, lng: 18.2464865 },
    { lat: 49.1525527, lng: 18.2463708 },
    { lat: 49.1526506, lng: 18.2460077 },
    { lat: 49.1524244, lng: 18.2456415 },
    { lat: 49.1525099, lng: 18.245456 },
    { lat: 49.1525292, lng: 18.2454191 },
    { lat: 49.1526657, lng: 18.24512 },
    { lat: 49.1529718, lng: 18.2448645 },
    { lat: 49.153161, lng: 18.2447992 },
    { lat: 49.1534299, lng: 18.2451239 },
    { lat: 49.1537201, lng: 18.2448514 },
    { lat: 49.1542493, lng: 18.2448917 },
    { lat: 49.1544857, lng: 18.2446624 },
    { lat: 49.1546297, lng: 18.2445739 },
    { lat: 49.1546648, lng: 18.2439997 },
    { lat: 49.1549709, lng: 18.2436678 },
  ],
  Záriečie: [
    { lat: 49.1914101, lng: 18.260439 },
    { lat: 49.191452, lng: 18.2604039 },
    { lat: 49.1919025, lng: 18.2600324 },
    { lat: 49.1918147, lng: 18.2589964 },
    { lat: 49.1918488, lng: 18.2584396 },
    { lat: 49.1918799, lng: 18.2579493 },
    { lat: 49.1920969, lng: 18.2569768 },
    { lat: 49.1921927, lng: 18.2566559 },
    { lat: 49.1922825, lng: 18.2562688 },
    { lat: 49.1922773, lng: 18.2555199 },
    { lat: 49.1923072, lng: 18.2550705 },
    { lat: 49.1923243, lng: 18.2548121 },
    { lat: 49.1925057, lng: 18.2539095 },
    { lat: 49.1926878, lng: 18.2534006 },
    { lat: 49.1927172, lng: 18.253339 },
    { lat: 49.1929172, lng: 18.2529081 },
    { lat: 49.1929567, lng: 18.2521077 },
    { lat: 49.1928958, lng: 18.2516509 },
    { lat: 49.1927263, lng: 18.2513659 },
    { lat: 49.1924007, lng: 18.2508135 },
    { lat: 49.1921726, lng: 18.2503328 },
    { lat: 49.1919404, lng: 18.250056 },
    { lat: 49.1918371, lng: 18.249245 },
    { lat: 49.1921988, lng: 18.2492982 },
    { lat: 49.1921988, lng: 18.2492203 },
    { lat: 49.1922077, lng: 18.2482779 },
    { lat: 49.1918638, lng: 18.2480615 },
    { lat: 49.1918023, lng: 18.2477147 },
    { lat: 49.1915421, lng: 18.2476611 },
    { lat: 49.191356, lng: 18.2469414 },
    { lat: 49.1912525, lng: 18.2468467 },
    { lat: 49.1911701, lng: 18.246647 },
    { lat: 49.1911897, lng: 18.246017 },
    { lat: 49.1908109, lng: 18.2453422 },
    { lat: 49.1908223, lng: 18.2451888 },
    { lat: 49.1908932, lng: 18.2443058 },
    { lat: 49.1909011, lng: 18.2442085 },
    { lat: 49.1906182, lng: 18.2439925 },
    { lat: 49.190395, lng: 18.2437957 },
    { lat: 49.1901158, lng: 18.2434278 },
    { lat: 49.1898225, lng: 18.2431299 },
    { lat: 49.1896827, lng: 18.2430116 },
    { lat: 49.189529, lng: 18.2428886 },
    { lat: 49.1890586, lng: 18.2425906 },
    { lat: 49.1889098, lng: 18.2424714 },
    { lat: 49.1887146, lng: 18.2423146 },
    { lat: 49.1886072, lng: 18.2421713 },
    { lat: 49.1884499, lng: 18.2418816 },
    { lat: 49.1883269, lng: 18.2417052 },
    { lat: 49.1882924, lng: 18.2416517 },
    { lat: 49.1881559, lng: 18.2415328 },
    { lat: 49.1880269, lng: 18.241467 },
    { lat: 49.1879047, lng: 18.2414393 },
    { lat: 49.1875749, lng: 18.2413924 },
    { lat: 49.1874539, lng: 18.2413275 },
    { lat: 49.1873099, lng: 18.2412181 },
    { lat: 49.1870191, lng: 18.2409844 },
    { lat: 49.186915, lng: 18.2409789 },
    { lat: 49.1869038, lng: 18.2409782 },
    { lat: 49.1868732, lng: 18.2409754 },
    { lat: 49.1868606, lng: 18.2409743 },
    { lat: 49.1868549, lng: 18.240974 },
    { lat: 49.1868504, lng: 18.2409731 },
    { lat: 49.1868323, lng: 18.2409717 },
    { lat: 49.1868028, lng: 18.2409682 },
    { lat: 49.186763, lng: 18.2409632 },
    { lat: 49.1867597, lng: 18.2409633 },
    { lat: 49.1867483, lng: 18.2409624 },
    { lat: 49.1866895, lng: 18.2409592 },
    { lat: 49.1866091, lng: 18.2409761 },
    { lat: 49.186634, lng: 18.2408765 },
    { lat: 49.1866571, lng: 18.240688 },
    { lat: 49.1866859, lng: 18.2403567 },
    { lat: 49.1867039, lng: 18.2400395 },
    { lat: 49.1867342, lng: 18.239778 },
    { lat: 49.1867183, lng: 18.2396769 },
    { lat: 49.1866956, lng: 18.2395345 },
    { lat: 49.1866884, lng: 18.2394863 },
    { lat: 49.1865701, lng: 18.2390129 },
    { lat: 49.1865285, lng: 18.2383369 },
    { lat: 49.1864756, lng: 18.2368851 },
    { lat: 49.1865176, lng: 18.2364123 },
    { lat: 49.1864582, lng: 18.2363983 },
    { lat: 49.186442, lng: 18.2363925 },
    { lat: 49.1863753, lng: 18.2363677 },
    { lat: 49.1862678, lng: 18.2363062 },
    { lat: 49.1861583, lng: 18.2362432 },
    { lat: 49.1860803, lng: 18.236201 },
    { lat: 49.1859156, lng: 18.236103 },
    { lat: 49.1858859, lng: 18.2358368 },
    { lat: 49.1858646, lng: 18.2356403 },
    { lat: 49.1857326, lng: 18.23526 },
    { lat: 49.1855478, lng: 18.2350071 },
    { lat: 49.1847853, lng: 18.2343729 },
    { lat: 49.18399, lng: 18.2336384 },
    { lat: 49.1832092, lng: 18.2328997 },
    { lat: 49.1828808, lng: 18.233277 },
    { lat: 49.1828129, lng: 18.233354 },
    { lat: 49.1825712, lng: 18.2337287 },
    { lat: 49.1823444, lng: 18.2340453 },
    { lat: 49.1822298, lng: 18.2339928 },
    { lat: 49.1818228, lng: 18.2342389 },
    { lat: 49.1817686, lng: 18.2342774 },
    { lat: 49.1813932, lng: 18.2335472 },
    { lat: 49.1812416, lng: 18.2330612 },
    { lat: 49.1810087, lng: 18.2319974 },
    { lat: 49.1807968, lng: 18.2313862 },
    { lat: 49.1807909, lng: 18.2309827 },
    { lat: 49.1807555, lng: 18.2307566 },
    { lat: 49.1806194, lng: 18.2303465 },
    { lat: 49.1800751, lng: 18.2301181 },
    { lat: 49.1800149, lng: 18.2297342 },
    { lat: 49.179704, lng: 18.2295437 },
    { lat: 49.1795833, lng: 18.2294665 },
    { lat: 49.1795042, lng: 18.2294225 },
    { lat: 49.1794111, lng: 18.2293526 },
    { lat: 49.1793778, lng: 18.2293234 },
    { lat: 49.1792195, lng: 18.22908 },
    { lat: 49.1792901, lng: 18.2286311 },
    { lat: 49.1793648, lng: 18.2283183 },
    { lat: 49.1793896, lng: 18.2282771 },
    { lat: 49.1793699, lng: 18.2282303 },
    { lat: 49.1792789, lng: 18.228018 },
    { lat: 49.1792369, lng: 18.227774 },
    { lat: 49.1793676, lng: 18.2273333 },
    { lat: 49.1794414, lng: 18.2271631 },
    { lat: 49.1792647, lng: 18.2267533 },
    { lat: 49.1798338, lng: 18.2264217 },
    { lat: 49.179711, lng: 18.2260077 },
    { lat: 49.1795675, lng: 18.2255658 },
    { lat: 49.1798173, lng: 18.2252767 },
    { lat: 49.1798621, lng: 18.2250519 },
    { lat: 49.179899, lng: 18.2248086 },
    { lat: 49.1799045, lng: 18.2247666 },
    { lat: 49.1795912, lng: 18.2246336 },
    { lat: 49.1792501, lng: 18.2241691 },
    { lat: 49.1791154, lng: 18.2235864 },
    { lat: 49.1788152, lng: 18.2227699 },
    { lat: 49.178781, lng: 18.2221291 },
    { lat: 49.1788374, lng: 18.2217895 },
    { lat: 49.1789753, lng: 18.2218518 },
    { lat: 49.1790914, lng: 18.2215339 },
    { lat: 49.1791954, lng: 18.2213391 },
    { lat: 49.1795171, lng: 18.2207643 },
    { lat: 49.1796842, lng: 18.2202521 },
    { lat: 49.1796458, lng: 18.2197552 },
    { lat: 49.1796656, lng: 18.2195654 },
    { lat: 49.1797111, lng: 18.2191751 },
    { lat: 49.1797191, lng: 18.2191185 },
    { lat: 49.1795646, lng: 18.2188201 },
    { lat: 49.1793609, lng: 18.218631 },
    { lat: 49.1791335, lng: 18.2183215 },
    { lat: 49.1791029, lng: 18.2179646 },
    { lat: 49.1790483, lng: 18.2173197 },
    { lat: 49.1790375, lng: 18.2166924 },
    { lat: 49.179036, lng: 18.2165485 },
    { lat: 49.1790311, lng: 18.2158654 },
    { lat: 49.1790195, lng: 18.2156029 },
    { lat: 49.1787385, lng: 18.2154021 },
    { lat: 49.1786597, lng: 18.2153449 },
    { lat: 49.1785009, lng: 18.2151715 },
    { lat: 49.1784162, lng: 18.2150707 },
    { lat: 49.1782292, lng: 18.2148589 },
    { lat: 49.178116, lng: 18.2149712 },
    { lat: 49.178016, lng: 18.2146819 },
    { lat: 49.177991, lng: 18.2146379 },
    { lat: 49.1779134, lng: 18.2145039 },
    { lat: 49.1777497, lng: 18.2142652 },
    { lat: 49.1776864, lng: 18.2141037 },
    { lat: 49.1776071, lng: 18.2137008 },
    { lat: 49.1775783, lng: 18.2132123 },
    { lat: 49.1772343, lng: 18.2135544 },
    { lat: 49.1771141, lng: 18.2127897 },
    { lat: 49.1769749, lng: 18.2123637 },
    { lat: 49.1765866, lng: 18.2120444 },
    { lat: 49.1765183, lng: 18.2119569 },
    { lat: 49.1760385, lng: 18.2118515 },
    { lat: 49.1759734, lng: 18.2117364 },
    { lat: 49.1757586, lng: 18.2115124 },
    { lat: 49.1756596, lng: 18.2113576 },
    { lat: 49.1754368, lng: 18.2110317 },
    { lat: 49.1753125, lng: 18.2108907 },
    { lat: 49.1751104, lng: 18.210708 },
    { lat: 49.17489, lng: 18.2105036 },
    { lat: 49.1747715, lng: 18.2103455 },
    { lat: 49.1746769, lng: 18.2101893 },
    { lat: 49.1744362, lng: 18.2097058 },
    { lat: 49.1743591, lng: 18.2094414 },
    { lat: 49.1742979, lng: 18.2089629 },
    { lat: 49.1742813, lng: 18.2085294 },
    { lat: 49.1742333, lng: 18.208322 },
    { lat: 49.1740951, lng: 18.2077391 },
    { lat: 49.174037, lng: 18.2074693 },
    { lat: 49.1737028, lng: 18.2068326 },
    { lat: 49.1734736, lng: 18.2064318 },
    { lat: 49.1732802, lng: 18.2061073 },
    { lat: 49.173126, lng: 18.2058372 },
    { lat: 49.1726787, lng: 18.2049755 },
    { lat: 49.1726079, lng: 18.2048024 },
    { lat: 49.1725715, lng: 18.2044196 },
    { lat: 49.1725395, lng: 18.2040969 },
    { lat: 49.1725868, lng: 18.2035009 },
    { lat: 49.1725513, lng: 18.2034933 },
    { lat: 49.1725022, lng: 18.2031252 },
    { lat: 49.1724307, lng: 18.2028004 },
    { lat: 49.1721895, lng: 18.202272 },
    { lat: 49.171939, lng: 18.2015173 },
    { lat: 49.1718478, lng: 18.2007685 },
    { lat: 49.1718305, lng: 18.2006215 },
    { lat: 49.1716125, lng: 18.1997085 },
    { lat: 49.1714697, lng: 18.1994186 },
    { lat: 49.1713448, lng: 18.1990307 },
    { lat: 49.1712458, lng: 18.1984034 },
    { lat: 49.1709967, lng: 18.1983469 },
    { lat: 49.1709681, lng: 18.1983404 },
    { lat: 49.1709679, lng: 18.1983365 },
    { lat: 49.1705524, lng: 18.198528 },
    { lat: 49.170169, lng: 18.1986705 },
    { lat: 49.1696147, lng: 18.1990034 },
    { lat: 49.169362, lng: 18.1990446 },
    { lat: 49.1691046, lng: 18.1989832 },
    { lat: 49.1687771, lng: 18.1989359 },
    { lat: 49.168179, lng: 18.1993693 },
    { lat: 49.1677573, lng: 18.1995938 },
    { lat: 49.1675525, lng: 18.1996449 },
    { lat: 49.1672381, lng: 18.1997089 },
    { lat: 49.166871, lng: 18.1998609 },
    { lat: 49.1662558, lng: 18.2002314 },
    { lat: 49.1659196, lng: 18.200828 },
    { lat: 49.1656541, lng: 18.2013368 },
    { lat: 49.1652811, lng: 18.2014228 },
    { lat: 49.1649352, lng: 18.2012134 },
    { lat: 49.1647741, lng: 18.2011183 },
    { lat: 49.1645752, lng: 18.2011129 },
    { lat: 49.1642967, lng: 18.2011368 },
    { lat: 49.1637859, lng: 18.2010621 },
    { lat: 49.1635822, lng: 18.2010121 },
    { lat: 49.1634029, lng: 18.2010737 },
    { lat: 49.163229, lng: 18.2010915 },
    { lat: 49.1630819, lng: 18.2010634 },
    { lat: 49.1630517, lng: 18.2011276 },
    { lat: 49.1624074, lng: 18.2022943 },
    { lat: 49.1623873, lng: 18.2028352 },
    { lat: 49.1623855, lng: 18.2033358 },
    { lat: 49.1622518, lng: 18.2035434 },
    { lat: 49.1621921, lng: 18.2037241 },
    { lat: 49.1628838, lng: 18.2047559 },
    { lat: 49.1628185, lng: 18.2050743 },
    { lat: 49.162639, lng: 18.2059248 },
    { lat: 49.1623847, lng: 18.2058768 },
    { lat: 49.1619082, lng: 18.2058319 },
    { lat: 49.161444, lng: 18.2057636 },
    { lat: 49.1613264, lng: 18.2065525 },
    { lat: 49.1612144, lng: 18.2078352 },
    { lat: 49.1610054, lng: 18.2086232 },
    { lat: 49.1609428, lng: 18.2088514 },
    { lat: 49.1607568, lng: 18.2097672 },
    { lat: 49.1606901, lng: 18.2108151 },
    { lat: 49.1602339, lng: 18.2110656 },
    { lat: 49.1599952, lng: 18.2117479 },
    { lat: 49.1596821, lng: 18.2127686 },
    { lat: 49.1595058, lng: 18.2131034 },
    { lat: 49.1591775, lng: 18.2137366 },
    { lat: 49.159031, lng: 18.2140375 },
    { lat: 49.1590693, lng: 18.2156053 },
    { lat: 49.1590964, lng: 18.2166357 },
    { lat: 49.1590307, lng: 18.2169875 },
    { lat: 49.1594935, lng: 18.2171715 },
    { lat: 49.160038, lng: 18.2173602 },
    { lat: 49.1605917, lng: 18.2175545 },
    { lat: 49.1607217, lng: 18.2186522 },
    { lat: 49.160292, lng: 18.2190707 },
    { lat: 49.1598396, lng: 18.2193295 },
    { lat: 49.1596146, lng: 18.2196384 },
    { lat: 49.1595268, lng: 18.2203175 },
    { lat: 49.1595088, lng: 18.2206559 },
    { lat: 49.159565, lng: 18.2206721 },
    { lat: 49.1596502, lng: 18.2207014 },
    { lat: 49.159874, lng: 18.2215832 },
    { lat: 49.1600943, lng: 18.2229377 },
    { lat: 49.1602367, lng: 18.2231806 },
    { lat: 49.1604333, lng: 18.2235422 },
    { lat: 49.1602767, lng: 18.2236476 },
    { lat: 49.1605226, lng: 18.2239235 },
    { lat: 49.1610171, lng: 18.2243476 },
    { lat: 49.1609761, lng: 18.2248361 },
    { lat: 49.1608942, lng: 18.2257377 },
    { lat: 49.1614851, lng: 18.2259939 },
    { lat: 49.1614378, lng: 18.2264706 },
    { lat: 49.1619172, lng: 18.2266469 },
    { lat: 49.1620598, lng: 18.2266758 },
    { lat: 49.1620172, lng: 18.2267988 },
    { lat: 49.1619951, lng: 18.2269851 },
    { lat: 49.1619708, lng: 18.2270935 },
    { lat: 49.161957, lng: 18.2271695 },
    { lat: 49.1619254, lng: 18.2273474 },
    { lat: 49.1618833, lng: 18.2275602 },
    { lat: 49.1618403, lng: 18.2278953 },
    { lat: 49.1616224, lng: 18.2278148 },
    { lat: 49.161517, lng: 18.2282422 },
    { lat: 49.161114, lng: 18.228458 },
    { lat: 49.1606359, lng: 18.2288013 },
    { lat: 49.1604704, lng: 18.2292641 },
    { lat: 49.1602428, lng: 18.2294493 },
    { lat: 49.1600875, lng: 18.2298324 },
    { lat: 49.1598554, lng: 18.2302758 },
    { lat: 49.1594428, lng: 18.2313746 },
    { lat: 49.1592918, lng: 18.231201 },
    { lat: 49.1591733, lng: 18.2318356 },
    { lat: 49.1590052, lng: 18.2322546 },
    { lat: 49.1588259, lng: 18.232237 },
    { lat: 49.1587558, lng: 18.2324724 },
    { lat: 49.1587386, lng: 18.2325281 },
    { lat: 49.1587867, lng: 18.233196 },
    { lat: 49.1587772, lng: 18.2341235 },
    { lat: 49.1587802, lng: 18.2341761 },
    { lat: 49.1587063, lng: 18.2350006 },
    { lat: 49.1591007, lng: 18.2351308 },
    { lat: 49.1593635, lng: 18.2353512 },
    { lat: 49.1596783, lng: 18.2355484 },
    { lat: 49.159711, lng: 18.2355686 },
    { lat: 49.1598526, lng: 18.2356579 },
    { lat: 49.1598912, lng: 18.2356816 },
    { lat: 49.1602213, lng: 18.2356347 },
    { lat: 49.1605007, lng: 18.235945 },
    { lat: 49.1606549, lng: 18.2361181 },
    { lat: 49.1607265, lng: 18.2365853 },
    { lat: 49.1614541, lng: 18.235668 },
    { lat: 49.1616603, lng: 18.2360997 },
    { lat: 49.1618483, lng: 18.23635 },
    { lat: 49.1622569, lng: 18.2368924 },
    { lat: 49.1626371, lng: 18.2374087 },
    { lat: 49.1631774, lng: 18.2381853 },
    { lat: 49.1632641, lng: 18.2383007 },
    { lat: 49.163667, lng: 18.2386197 },
    { lat: 49.1635018, lng: 18.2389512 },
    { lat: 49.1633819, lng: 18.2395613 },
    { lat: 49.1631597, lng: 18.2400416 },
    { lat: 49.1631253, lng: 18.2401105 },
    { lat: 49.1629243, lng: 18.2404066 },
    { lat: 49.1626797, lng: 18.2406904 },
    { lat: 49.162504, lng: 18.2411136 },
    { lat: 49.1623731, lng: 18.2417105 },
    { lat: 49.1623149, lng: 18.2419596 },
    { lat: 49.1623904, lng: 18.2419956 },
    { lat: 49.162928, lng: 18.2423257 },
    { lat: 49.1632198, lng: 18.2424572 },
    { lat: 49.1634154, lng: 18.2425449 },
    { lat: 49.1632319, lng: 18.2427502 },
    { lat: 49.1629917, lng: 18.242972 },
    { lat: 49.162742, lng: 18.2431265 },
    { lat: 49.162505, lng: 18.243322 },
    { lat: 49.1623356, lng: 18.2437594 },
    { lat: 49.1621609, lng: 18.2440803 },
    { lat: 49.1620966, lng: 18.2441998 },
    { lat: 49.1621391, lng: 18.2445933 },
    { lat: 49.162247, lng: 18.2450442 },
    { lat: 49.1623119, lng: 18.2455315 },
    { lat: 49.162284, lng: 18.2456973 },
    { lat: 49.1622647, lng: 18.2458144 },
    { lat: 49.1622462, lng: 18.245925 },
    { lat: 49.1625937, lng: 18.2465852 },
    { lat: 49.1627014, lng: 18.2468518 },
    { lat: 49.1627526, lng: 18.2469772 },
    { lat: 49.1628052, lng: 18.2471122 },
    { lat: 49.1629855, lng: 18.2473877 },
    { lat: 49.1632367, lng: 18.2476993 },
    { lat: 49.1634732, lng: 18.2479915 },
    { lat: 49.1636602, lng: 18.2474865 },
    { lat: 49.163847, lng: 18.2471781 },
    { lat: 49.1638937, lng: 18.2471061 },
    { lat: 49.1639732, lng: 18.2472936 },
    { lat: 49.1641198, lng: 18.2472743 },
    { lat: 49.1642431, lng: 18.2474598 },
    { lat: 49.1647936, lng: 18.2476163 },
    { lat: 49.1649803, lng: 18.2477467 },
    { lat: 49.1649857, lng: 18.2479396 },
    { lat: 49.1651104, lng: 18.2482297 },
    { lat: 49.1650562, lng: 18.2483502 },
    { lat: 49.1647127, lng: 18.2491202 },
    { lat: 49.1645717, lng: 18.2493405 },
    { lat: 49.1645116, lng: 18.2494326 },
    { lat: 49.1642337, lng: 18.2491274 },
    { lat: 49.1641884, lng: 18.2491659 },
    { lat: 49.1641455, lng: 18.2492035 },
    { lat: 49.1641001, lng: 18.2492394 },
    { lat: 49.164128, lng: 18.2494329 },
    { lat: 49.1641716, lng: 18.2497062 },
    { lat: 49.1637548, lng: 18.2504754 },
    { lat: 49.1636583, lng: 18.2506628 },
    { lat: 49.1636016, lng: 18.250778 },
    { lat: 49.1634897, lng: 18.2510552 },
    { lat: 49.1633231, lng: 18.2513601 },
    { lat: 49.1633007, lng: 18.2514024 },
    { lat: 49.1635179, lng: 18.2519113 },
    { lat: 49.1635522, lng: 18.2520002 },
    { lat: 49.1633799, lng: 18.2522633 },
    { lat: 49.1630146, lng: 18.252846 },
    { lat: 49.1631673, lng: 18.2530925 },
    { lat: 49.1634916, lng: 18.2534437 },
    { lat: 49.1636785, lng: 18.2535962 },
    { lat: 49.1638154, lng: 18.2536633 },
    { lat: 49.1640021, lng: 18.2536906 },
    { lat: 49.164349, lng: 18.2536211 },
    { lat: 49.1644086, lng: 18.2533362 },
    { lat: 49.1644686, lng: 18.253062 },
    { lat: 49.1640994, lng: 18.2527376 },
    { lat: 49.16414, lng: 18.2526382 },
    { lat: 49.1642972, lng: 18.2522442 },
    { lat: 49.1644413, lng: 18.2518811 },
    { lat: 49.1645975, lng: 18.2514914 },
    { lat: 49.1646448, lng: 18.2513696 },
    { lat: 49.164881, lng: 18.2507574 },
    { lat: 49.1648609, lng: 18.2506824 },
    { lat: 49.1648238, lng: 18.2505443 },
    { lat: 49.1650574, lng: 18.2500663 },
    { lat: 49.1655789, lng: 18.2503896 },
    { lat: 49.1656196, lng: 18.250412 },
    { lat: 49.1658043, lng: 18.2505255 },
    { lat: 49.166113, lng: 18.2508569 },
    { lat: 49.1667871, lng: 18.2514647 },
    { lat: 49.1670974, lng: 18.2516634 },
    { lat: 49.167283, lng: 18.2516558 },
    { lat: 49.1676722, lng: 18.251895 },
    { lat: 49.1677706, lng: 18.2519182 },
    { lat: 49.1680874, lng: 18.2519947 },
    { lat: 49.1684467, lng: 18.2518447 },
    { lat: 49.168545, lng: 18.2518055 },
    { lat: 49.1693366, lng: 18.2521799 },
    { lat: 49.1699462, lng: 18.2529239 },
    { lat: 49.1693913, lng: 18.2532459 },
    { lat: 49.1683226, lng: 18.2538978 },
    { lat: 49.1683639, lng: 18.2540519 },
    { lat: 49.1691271, lng: 18.2536222 },
    { lat: 49.1694334, lng: 18.2534313 },
    { lat: 49.1697692, lng: 18.2532443 },
    { lat: 49.1699343, lng: 18.2531505 },
    { lat: 49.1699677, lng: 18.2533044 },
    { lat: 49.1700031, lng: 18.2534699 },
    { lat: 49.1704241, lng: 18.2536929 },
    { lat: 49.1707365, lng: 18.2538484 },
    { lat: 49.1708849, lng: 18.2540798 },
    { lat: 49.171199, lng: 18.2545065 },
    { lat: 49.1713468, lng: 18.2546663 },
    { lat: 49.1717653, lng: 18.2548251 },
    { lat: 49.1722661, lng: 18.2547427 },
    { lat: 49.1725409, lng: 18.2546945 },
    { lat: 49.1724972, lng: 18.2551591 },
    { lat: 49.1723222, lng: 18.255636 },
    { lat: 49.172492, lng: 18.2561838 },
    { lat: 49.1725069, lng: 18.2562312 },
    { lat: 49.1726281, lng: 18.2561576 },
    { lat: 49.1727245, lng: 18.2560676 },
    { lat: 49.172824, lng: 18.255931 },
    { lat: 49.1729232, lng: 18.2557305 },
    { lat: 49.1730034, lng: 18.2553916 },
    { lat: 49.1731226, lng: 18.2554663 },
    { lat: 49.1732372, lng: 18.2555369 },
    { lat: 49.1735878, lng: 18.2556118 },
    { lat: 49.1734159, lng: 18.256243 },
    { lat: 49.1734838, lng: 18.2563068 },
    { lat: 49.1736675, lng: 18.2564813 },
    { lat: 49.1740754, lng: 18.2563529 },
    { lat: 49.1740931, lng: 18.2563471 },
    { lat: 49.1740997, lng: 18.256345 },
    { lat: 49.174593, lng: 18.2561893 },
    { lat: 49.1745952, lng: 18.2561883 },
    { lat: 49.1747663, lng: 18.2561416 },
    { lat: 49.1750466, lng: 18.2560312 },
    { lat: 49.1751603, lng: 18.2559867 },
    { lat: 49.1755223, lng: 18.2558439 },
    { lat: 49.1756736, lng: 18.2557844 },
    { lat: 49.1757861, lng: 18.25574 },
    { lat: 49.1759185, lng: 18.2556876 },
    { lat: 49.1761084, lng: 18.2556133 },
    { lat: 49.1761502, lng: 18.2555966 },
    { lat: 49.1762155, lng: 18.2555713 },
    { lat: 49.1763258, lng: 18.2555276 },
    { lat: 49.1763802, lng: 18.2555321 },
    { lat: 49.1764051, lng: 18.2555346 },
    { lat: 49.1764165, lng: 18.2555349 },
    { lat: 49.1764732, lng: 18.2555396 },
    { lat: 49.1764985, lng: 18.255549 },
    { lat: 49.1765619, lng: 18.2555717 },
    { lat: 49.1765756, lng: 18.2555773 },
    { lat: 49.1766367, lng: 18.2555997 },
    { lat: 49.1767075, lng: 18.2556542 },
    { lat: 49.1767983, lng: 18.2557842 },
    { lat: 49.1769774, lng: 18.2560176 },
    { lat: 49.1771476, lng: 18.2562138 },
    { lat: 49.1771662, lng: 18.2561832 },
    { lat: 49.1775822, lng: 18.2553986 },
    { lat: 49.177627, lng: 18.2553131 },
    { lat: 49.17773, lng: 18.2551201 },
    { lat: 49.1779256, lng: 18.2547489 },
    { lat: 49.1779461, lng: 18.2547114 },
    { lat: 49.177948, lng: 18.2547065 },
    { lat: 49.1784116, lng: 18.253766 },
    { lat: 49.1785465, lng: 18.253957 },
    { lat: 49.1785917, lng: 18.2540185 },
    { lat: 49.1787107, lng: 18.254187 },
    { lat: 49.178765, lng: 18.2542301 },
    { lat: 49.1788561, lng: 18.2543038 },
    { lat: 49.1788726, lng: 18.2543168 },
    { lat: 49.1789353, lng: 18.2543666 },
    { lat: 49.1790594, lng: 18.254467 },
    { lat: 49.1791579, lng: 18.2545543 },
    { lat: 49.1791971, lng: 18.2545881 },
    { lat: 49.1793018, lng: 18.2547229 },
    { lat: 49.1793243, lng: 18.2547617 },
    { lat: 49.1794027, lng: 18.2548915 },
    { lat: 49.1795267, lng: 18.2550913 },
    { lat: 49.1796018, lng: 18.2553223 },
    { lat: 49.1796443, lng: 18.2555147 },
    { lat: 49.1796598, lng: 18.255608 },
    { lat: 49.1796621, lng: 18.2556294 },
    { lat: 49.17967, lng: 18.2556716 },
    { lat: 49.1796907, lng: 18.2556961 },
    { lat: 49.1797834, lng: 18.2558381 },
    { lat: 49.1798337, lng: 18.25597 },
    { lat: 49.1798934, lng: 18.256128 },
    { lat: 49.1799498, lng: 18.2563088 },
    { lat: 49.1799577, lng: 18.2563697 },
    { lat: 49.1802171, lng: 18.2567865 },
    { lat: 49.1802359, lng: 18.2567985 },
    { lat: 49.1803095, lng: 18.2568238 },
    { lat: 49.1804603, lng: 18.2569163 },
    { lat: 49.1804894, lng: 18.2569343 },
    { lat: 49.1807353, lng: 18.2570917 },
    { lat: 49.1806743, lng: 18.2573114 },
    { lat: 49.1806639, lng: 18.2576867 },
    { lat: 49.1806724, lng: 18.2577174 },
    { lat: 49.1807172, lng: 18.2578518 },
    { lat: 49.180727, lng: 18.2578851 },
    { lat: 49.1807518, lng: 18.2579042 },
    { lat: 49.1807979, lng: 18.2579403 },
    { lat: 49.1809189, lng: 18.2580074 },
    { lat: 49.1811613, lng: 18.2579615 },
    { lat: 49.1813294, lng: 18.2579426 },
    { lat: 49.181491, lng: 18.2579257 },
    { lat: 49.1819477, lng: 18.2580035 },
    { lat: 49.1820769, lng: 18.2585938 },
    { lat: 49.1821253, lng: 18.2588093 },
    { lat: 49.1822477, lng: 18.2593013 },
    { lat: 49.1823002, lng: 18.2595104 },
    { lat: 49.1826257, lng: 18.2599001 },
    { lat: 49.18301, lng: 18.2598922 },
    { lat: 49.1832049, lng: 18.259888 },
    { lat: 49.1832774, lng: 18.259872 },
    { lat: 49.1837296, lng: 18.2597706 },
    { lat: 49.1838081, lng: 18.2597408 },
    { lat: 49.184431, lng: 18.2595028 },
    { lat: 49.1850072, lng: 18.2592962 },
    { lat: 49.1853187, lng: 18.2592412 },
    { lat: 49.1857797, lng: 18.2591524 },
    { lat: 49.1861977, lng: 18.2591024 },
    { lat: 49.1865331, lng: 18.2589671 },
    { lat: 49.1866016, lng: 18.2589399 },
    { lat: 49.1869599, lng: 18.2588542 },
    { lat: 49.1873292, lng: 18.258679 },
    { lat: 49.187934, lng: 18.2586427 },
    { lat: 49.1884475, lng: 18.2587043 },
    { lat: 49.1889061, lng: 18.2586925 },
    { lat: 49.1895021, lng: 18.25878 },
    { lat: 49.1895465, lng: 18.2587866 },
    { lat: 49.1898707, lng: 18.2588345 },
    { lat: 49.1904738, lng: 18.2590853 },
    { lat: 49.1907844, lng: 18.2594696 },
    { lat: 49.1911017, lng: 18.2600153 },
    { lat: 49.1914101, lng: 18.260439 },
  ],
  Mojtín: [
    { lat: 48.980464, lng: 18.373267 },
    { lat: 48.980438, lng: 18.37442 },
    { lat: 48.980436, lng: 18.375072 },
    { lat: 48.980295, lng: 18.375853 },
    { lat: 48.979506, lng: 18.377605 },
    { lat: 48.979543, lng: 18.37831 },
    { lat: 48.979452, lng: 18.378861 },
    { lat: 48.979557, lng: 18.379381 },
    { lat: 48.979472, lng: 18.380156 },
    { lat: 48.979464, lng: 18.380552 },
    { lat: 48.979338, lng: 18.38165 },
    { lat: 48.979217, lng: 18.382323 },
    { lat: 48.979097, lng: 18.382623 },
    { lat: 48.978959, lng: 18.382856 },
    { lat: 48.978306, lng: 18.384388 },
    { lat: 48.978263, lng: 18.384629 },
    { lat: 48.978103, lng: 18.385062 },
    { lat: 48.977845, lng: 18.385681 },
    { lat: 48.977631, lng: 18.386129 },
    { lat: 48.977031, lng: 18.387652 },
    { lat: 48.976881, lng: 18.388119 },
    { lat: 48.976697, lng: 18.390284 },
    { lat: 48.976546, lng: 18.390818 },
    { lat: 48.976543, lng: 18.391266 },
    { lat: 48.975931, lng: 18.392293 },
    { lat: 48.975088, lng: 18.392571 },
    { lat: 48.974973, lng: 18.393461 },
    { lat: 48.97429, lng: 18.394368 },
    { lat: 48.974126, lng: 18.394939 },
    { lat: 48.973552, lng: 18.395754 },
    { lat: 48.973459, lng: 18.396052 },
    { lat: 48.972656, lng: 18.396625 },
    { lat: 48.972259, lng: 18.39694 },
    { lat: 48.971883, lng: 18.39681 },
    { lat: 48.971925, lng: 18.397277 },
    { lat: 48.971571, lng: 18.398573 },
    { lat: 48.971497, lng: 18.398639 },
    { lat: 48.970994, lng: 18.399884 },
    { lat: 48.970839, lng: 18.400667 },
    { lat: 48.970877, lng: 18.401717 },
    { lat: 48.971249, lng: 18.403006 },
    { lat: 48.971405, lng: 18.403736 },
    { lat: 48.971485, lng: 18.404108 },
    { lat: 48.971443, lng: 18.404412 },
    { lat: 48.97191, lng: 18.404422 },
    { lat: 48.971874, lng: 18.405179 },
    { lat: 48.971631, lng: 18.406367 },
    { lat: 48.971428, lng: 18.407071 },
    { lat: 48.971369, lng: 18.407871 },
    { lat: 48.971544, lng: 18.408719 },
    { lat: 48.971605, lng: 18.40911 },
    { lat: 48.971694, lng: 18.409724 },
    { lat: 48.971764, lng: 18.410366 },
    { lat: 48.971867, lng: 18.412442 },
    { lat: 48.971562, lng: 18.413093 },
    { lat: 48.971331, lng: 18.414182 },
    { lat: 48.971846, lng: 18.415981 },
    { lat: 48.971914, lng: 18.416684 },
    { lat: 48.97209, lng: 18.417487 },
    { lat: 48.972204, lng: 18.418139 },
    { lat: 48.971947, lng: 18.419218 },
    { lat: 48.971579, lng: 18.422278 },
    { lat: 48.972182, lng: 18.423005 },
    { lat: 48.972457, lng: 18.42331 },
    { lat: 48.972864, lng: 18.423788 },
    { lat: 48.973236, lng: 18.424253 },
    { lat: 48.973281, lng: 18.424318 },
    { lat: 48.973473, lng: 18.424525 },
    { lat: 48.973864, lng: 18.425011 },
    { lat: 48.973957, lng: 18.425139 },
    { lat: 48.973989, lng: 18.425183 },
    { lat: 48.974145, lng: 18.425357 },
    { lat: 48.974852, lng: 18.426324 },
    { lat: 48.975137, lng: 18.426685 },
    { lat: 48.975162, lng: 18.426717 },
    { lat: 48.975398, lng: 18.427 },
    { lat: 48.975692, lng: 18.427392 },
    { lat: 48.975739, lng: 18.427451 },
    { lat: 48.97615, lng: 18.428054 },
    { lat: 48.976423, lng: 18.428409 },
    { lat: 48.976772, lng: 18.428933 },
    { lat: 48.977353, lng: 18.429793 },
    { lat: 48.977739, lng: 18.430465 },
    { lat: 48.978413, lng: 18.431525 },
    { lat: 48.978733, lng: 18.432028 },
    { lat: 48.981211, lng: 18.435938 },
    { lat: 48.981502, lng: 18.436398 },
    { lat: 48.981789, lng: 18.436873 },
    { lat: 48.982226, lng: 18.437509 },
    { lat: 48.982392, lng: 18.43701 },
    { lat: 48.982582, lng: 18.436536 },
    { lat: 48.982792, lng: 18.435902 },
    { lat: 48.983009, lng: 18.435394 },
    { lat: 48.983184, lng: 18.434994 },
    { lat: 48.983392, lng: 18.434288 },
    { lat: 48.983491, lng: 18.434031 },
    { lat: 48.983576, lng: 18.43381 },
    { lat: 48.98388, lng: 18.433361 },
    { lat: 48.984501, lng: 18.432448 },
    { lat: 48.984693, lng: 18.432376 },
    { lat: 48.98511, lng: 18.432065 },
    { lat: 48.985274, lng: 18.431827 },
    { lat: 48.985424, lng: 18.431691 },
    { lat: 48.985977, lng: 18.431325 },
    { lat: 48.986259, lng: 18.431242 },
    { lat: 48.986785, lng: 18.430803 },
    { lat: 48.987235, lng: 18.430414 },
    { lat: 48.987663, lng: 18.430022 },
    { lat: 48.987849, lng: 18.429676 },
    { lat: 48.988152, lng: 18.429075 },
    { lat: 48.988555, lng: 18.428348 },
    { lat: 48.988945, lng: 18.427474 },
    { lat: 48.989024, lng: 18.427035 },
    { lat: 48.988953, lng: 18.426632 },
    { lat: 48.989028, lng: 18.426197 },
    { lat: 48.989148, lng: 18.425778 },
    { lat: 48.989296, lng: 18.425444 },
    { lat: 48.989435, lng: 18.424921 },
    { lat: 48.989443, lng: 18.424558 },
    { lat: 48.989592, lng: 18.424073 },
    { lat: 48.989725, lng: 18.423605 },
    { lat: 48.989772, lng: 18.423013 },
    { lat: 48.989712, lng: 18.422796 },
    { lat: 48.989941, lng: 18.422084 },
    { lat: 48.990011, lng: 18.421686 },
    { lat: 48.990056, lng: 18.421246 },
    { lat: 48.990063, lng: 18.42107 },
    { lat: 48.99017, lng: 18.420887 },
    { lat: 48.990207, lng: 18.420793 },
    { lat: 48.990232, lng: 18.420779 },
    { lat: 48.990307, lng: 18.42069 },
    { lat: 48.990448, lng: 18.420556 },
    { lat: 48.990714, lng: 18.420303 },
    { lat: 48.991461, lng: 18.420216 },
    { lat: 48.992172, lng: 18.420229 },
    { lat: 48.992281, lng: 18.420209 },
    { lat: 48.992502, lng: 18.420097 },
    { lat: 48.99317, lng: 18.42008 },
    { lat: 48.993652, lng: 18.420115 },
    { lat: 48.994181, lng: 18.420191 },
    { lat: 48.994734, lng: 18.420375 },
    { lat: 48.994788, lng: 18.420393 },
    { lat: 48.994838, lng: 18.420411 },
    { lat: 48.99519, lng: 18.420513 },
    { lat: 48.995308, lng: 18.420585 },
    { lat: 48.995937, lng: 18.420988 },
    { lat: 48.996662, lng: 18.420949 },
    { lat: 48.997643, lng: 18.421169 },
    { lat: 48.997904, lng: 18.421134 },
    { lat: 48.998102, lng: 18.420971 },
    { lat: 48.998228, lng: 18.42077 },
    { lat: 48.998262, lng: 18.420735 },
    { lat: 48.998458, lng: 18.420259 },
    { lat: 48.998387, lng: 18.420205 },
    { lat: 48.998723, lng: 18.418686 },
    { lat: 48.998741, lng: 18.416538 },
    { lat: 48.998289, lng: 18.414974 },
    { lat: 48.998411, lng: 18.413535 },
    { lat: 48.997904, lng: 18.411327 },
    { lat: 48.999404, lng: 18.410875 },
    { lat: 49.000015, lng: 18.409753 },
    { lat: 49.001345, lng: 18.41185 },
    { lat: 49.004628, lng: 18.40378 },
    { lat: 49.004747, lng: 18.403211 },
    { lat: 49.005095, lng: 18.40252 },
    { lat: 49.006107, lng: 18.401544 },
    { lat: 49.006363, lng: 18.401281 },
    { lat: 49.006873, lng: 18.400933 },
    { lat: 49.008008, lng: 18.400168 },
    { lat: 49.00891, lng: 18.39947 },
    { lat: 49.0085473, lng: 18.3987346 },
    { lat: 49.0080117, lng: 18.397662 },
    { lat: 49.0077303, lng: 18.3965139 },
    { lat: 49.0078494, lng: 18.3946344 },
    { lat: 49.008385, lng: 18.3906464 },
    { lat: 49.0077085, lng: 18.3898813 },
    { lat: 49.0063307, lng: 18.3911941 },
    { lat: 49.005729, lng: 18.3899484 },
    { lat: 49.0051376, lng: 18.3894151 },
    { lat: 49.002177, lng: 18.3867468 },
    { lat: 49.0018322, lng: 18.3865721 },
    { lat: 49.0014116, lng: 18.3863487 },
    { lat: 49.0008296, lng: 18.3859265 },
    { lat: 49.0004356, lng: 18.3855549 },
    { lat: 49.0000738, lng: 18.3851959 },
    { lat: 48.9979939, lng: 18.3869896 },
    { lat: 48.9979809, lng: 18.3869579 },
    { lat: 48.9979629, lng: 18.3868377 },
    { lat: 48.9979398, lng: 18.386786 },
    { lat: 48.9976102, lng: 18.38717 },
    { lat: 48.9968004, lng: 18.3875976 },
    { lat: 48.9964906, lng: 18.3875242 },
    { lat: 48.9964769, lng: 18.387846 },
    { lat: 48.9961088, lng: 18.3882058 },
    { lat: 48.995586, lng: 18.3881975 },
    { lat: 48.9953133, lng: 18.3887505 },
    { lat: 48.9952405, lng: 18.3886373 },
    { lat: 48.9951342, lng: 18.3884706 },
    { lat: 48.9951046, lng: 18.3884263 },
    { lat: 48.9942315, lng: 18.3872497 },
    { lat: 48.9931847, lng: 18.3863365 },
    { lat: 48.9927308, lng: 18.3854985 },
    { lat: 48.9921427, lng: 18.3854719 },
    { lat: 48.9917821, lng: 18.385053 },
    { lat: 48.9916435, lng: 18.384588 },
    { lat: 48.9913517, lng: 18.3841263 },
    { lat: 48.9910078, lng: 18.3841114 },
    { lat: 48.9899991, lng: 18.3830734 },
    { lat: 48.9894575, lng: 18.3828687 },
    { lat: 48.989211, lng: 18.3827777 },
    { lat: 48.9883128, lng: 18.381476 },
    { lat: 48.9875796, lng: 18.3795293 },
    { lat: 48.98752, lng: 18.3794124 },
    { lat: 48.98683, lng: 18.378131 },
    { lat: 48.9848, lng: 18.37592 },
    { lat: 48.984756, lng: 18.375872 },
    { lat: 48.981947, lng: 18.372813 },
    { lat: 48.9815466, lng: 18.3728837 },
    { lat: 48.981488, lng: 18.372894 },
    { lat: 48.981093, lng: 18.372772 },
    { lat: 48.98058, lng: 18.373071 },
    { lat: 48.980544, lng: 18.373248 },
    { lat: 48.980464, lng: 18.373267 },
  ],
  Beluša: [
    { lat: 49.098122, lng: 18.396469 },
    { lat: 49.098127, lng: 18.39642 },
    { lat: 49.098333, lng: 18.396578 },
    { lat: 49.09866, lng: 18.396742 },
    { lat: 49.09886, lng: 18.396762 },
    { lat: 49.099103, lng: 18.396569 },
    { lat: 49.099147, lng: 18.396054 },
    { lat: 49.099096, lng: 18.395683 },
    { lat: 49.099021, lng: 18.395556 },
    { lat: 49.099048, lng: 18.395396 },
    { lat: 49.098959, lng: 18.395009 },
    { lat: 49.099046, lng: 18.394642 },
    { lat: 49.098987, lng: 18.394364 },
    { lat: 49.099026, lng: 18.39421 },
    { lat: 49.099142, lng: 18.394012 },
    { lat: 49.099418, lng: 18.39397 },
    { lat: 49.099893, lng: 18.393388 },
    { lat: 49.100372, lng: 18.392782 },
    { lat: 49.100755, lng: 18.39203 },
    { lat: 49.101022, lng: 18.391683 },
    { lat: 49.101198, lng: 18.391206 },
    { lat: 49.101322, lng: 18.390963 },
    { lat: 49.101478, lng: 18.390733 },
    { lat: 49.10153, lng: 18.390175 },
    { lat: 49.101699, lng: 18.389698 },
    { lat: 49.101883, lng: 18.389407 },
    { lat: 49.102038, lng: 18.388942 },
    { lat: 49.102159, lng: 18.388154 },
    { lat: 49.102274, lng: 18.386871 },
    { lat: 49.102181, lng: 18.386519 },
    { lat: 49.102149, lng: 18.386213 },
    { lat: 49.10203, lng: 18.385401 },
    { lat: 49.101915, lng: 18.38494 },
    { lat: 49.10179, lng: 18.384512 },
    { lat: 49.101929, lng: 18.384173 },
    { lat: 49.101956, lng: 18.383885 },
    { lat: 49.101894, lng: 18.383206 },
    { lat: 49.101878, lng: 18.382724 },
    { lat: 49.102026, lng: 18.382723 },
    { lat: 49.102604, lng: 18.382733 },
    { lat: 49.10321, lng: 18.382752 },
    { lat: 49.10352, lng: 18.382802 },
    { lat: 49.10342, lng: 18.3821651 },
    { lat: 49.1032518, lng: 18.3814516 },
    { lat: 49.103029, lng: 18.3800592 },
    { lat: 49.1029929, lng: 18.3798558 },
    { lat: 49.10298, lng: 18.3797865 },
    { lat: 49.1029655, lng: 18.3797059 },
    { lat: 49.1029198, lng: 18.3794519 },
    { lat: 49.1025606, lng: 18.3774746 },
    { lat: 49.1025492, lng: 18.37741 },
    { lat: 49.1024848, lng: 18.3771249 },
    { lat: 49.1024158, lng: 18.3768155 },
    { lat: 49.1023629, lng: 18.3767359 },
    { lat: 49.1023075, lng: 18.3766541 },
    { lat: 49.1016016, lng: 18.3756013 },
    { lat: 49.1014057, lng: 18.3751815 },
    { lat: 49.101142, lng: 18.3746197 },
    { lat: 49.101088, lng: 18.3744803 },
    { lat: 49.1008827, lng: 18.3739518 },
    { lat: 49.1006824, lng: 18.3734335 },
    { lat: 49.1006142, lng: 18.373221 },
    { lat: 49.1003989, lng: 18.3725547 },
    { lat: 49.10038, lng: 18.3724966 },
    { lat: 49.1003253, lng: 18.3723258 },
    { lat: 49.1001971, lng: 18.371541 },
    { lat: 49.1001007, lng: 18.3709435 },
    { lat: 49.099841, lng: 18.3698851 },
    { lat: 49.0997374, lng: 18.3694618 },
    { lat: 49.0995211, lng: 18.3682088 },
    { lat: 49.0996576, lng: 18.366889 },
    { lat: 49.0996679, lng: 18.3667523 },
    { lat: 49.099699, lng: 18.3663579 },
    { lat: 49.099716, lng: 18.3662993 },
    { lat: 49.0997088, lng: 18.3660893 },
    { lat: 49.0997644, lng: 18.3655677 },
    { lat: 49.0997739, lng: 18.3654716 },
    { lat: 49.0994746, lng: 18.3644936 },
    { lat: 49.0991773, lng: 18.3639168 },
    { lat: 49.0988214, lng: 18.3633383 },
    { lat: 49.0986408, lng: 18.3630112 },
    { lat: 49.0984791, lng: 18.3627207 },
    { lat: 49.0983424, lng: 18.3625146 },
    { lat: 49.0982463, lng: 18.3623689 },
    { lat: 49.0981837, lng: 18.3622598 },
    { lat: 49.0981019, lng: 18.3620848 },
    { lat: 49.0979685, lng: 18.3618585 },
    { lat: 49.0977611, lng: 18.3615764 },
    { lat: 49.0976098, lng: 18.3612712 },
    { lat: 49.0975337, lng: 18.361119 },
    { lat: 49.0974598, lng: 18.3609457 },
    { lat: 49.0972925, lng: 18.3605558 },
    { lat: 49.0972434, lng: 18.3604236 },
    { lat: 49.0970589, lng: 18.3599272 },
    { lat: 49.0968718, lng: 18.3596447 },
    { lat: 49.0964257, lng: 18.3589679 },
    { lat: 49.0960664, lng: 18.3580481 },
    { lat: 49.0960041, lng: 18.3579195 },
    { lat: 49.0956187, lng: 18.3571194 },
    { lat: 49.0951656, lng: 18.3560123 },
    { lat: 49.0950563, lng: 18.3557507 },
    { lat: 49.0950054, lng: 18.3556271 },
    { lat: 49.0946574, lng: 18.3547875 },
    { lat: 49.0945245, lng: 18.3544666 },
    { lat: 49.0942011, lng: 18.3536439 },
    { lat: 49.0941748, lng: 18.3535789 },
    { lat: 49.0940013, lng: 18.3531359 },
    { lat: 49.0939999, lng: 18.3531322 },
    { lat: 49.0936466, lng: 18.3520155 },
    { lat: 49.0931883, lng: 18.3511626 },
    { lat: 49.0930101, lng: 18.3506968 },
    { lat: 49.0929956, lng: 18.3506587 },
    { lat: 49.0926591, lng: 18.349843 },
    { lat: 49.0923278, lng: 18.3483135 },
    { lat: 49.0922063, lng: 18.3477522 },
    { lat: 49.0919385, lng: 18.3455231 },
    { lat: 49.0918479, lng: 18.3451518 },
    { lat: 49.0918405, lng: 18.3450183 },
    { lat: 49.0918043, lng: 18.3444105 },
    { lat: 49.0916212, lng: 18.3436562 },
    { lat: 49.0915415, lng: 18.3434024 },
    { lat: 49.0914453, lng: 18.3430942 },
    { lat: 49.0914151, lng: 18.3429969 },
    { lat: 49.0913876, lng: 18.3429094 },
    { lat: 49.0913476, lng: 18.3427794 },
    { lat: 49.0913108, lng: 18.3426634 },
    { lat: 49.0912983, lng: 18.3426028 },
    { lat: 49.09126, lng: 18.3424227 },
    { lat: 49.0912355, lng: 18.3423057 },
    { lat: 49.0911862, lng: 18.3420694 },
    { lat: 49.0911738, lng: 18.34201 },
    { lat: 49.091163, lng: 18.3419556 },
    { lat: 49.091134, lng: 18.3418215 },
    { lat: 49.0909438, lng: 18.3412844 },
    { lat: 49.0906207, lng: 18.3406499 },
    { lat: 49.0905852, lng: 18.3405797 },
    { lat: 49.0905511, lng: 18.3404138 },
    { lat: 49.0905156, lng: 18.3402438 },
    { lat: 49.0904397, lng: 18.339875 },
    { lat: 49.0902438, lng: 18.3392364 },
    { lat: 49.0898984, lng: 18.3386259 },
    { lat: 49.0897076, lng: 18.3380182 },
    { lat: 49.0896667, lng: 18.337688 },
    { lat: 49.0894995, lng: 18.3368156 },
    { lat: 49.0894884, lng: 18.3367625 },
    { lat: 49.0894171, lng: 18.3356059 },
    { lat: 49.0892133, lng: 18.3348254 },
    { lat: 49.0891393, lng: 18.3346133 },
    { lat: 49.0890749, lng: 18.3344289 },
    { lat: 49.0889669, lng: 18.3341127 },
    { lat: 49.088886, lng: 18.3338769 },
    { lat: 49.0888605, lng: 18.3338039 },
    { lat: 49.0886723, lng: 18.3327575 },
    { lat: 49.0886586, lng: 18.332756 },
    { lat: 49.0886543, lng: 18.3327185 },
    { lat: 49.0885581, lng: 18.3324109 },
    { lat: 49.088353, lng: 18.3316071 },
    { lat: 49.0883076, lng: 18.3313802 },
    { lat: 49.0880856, lng: 18.3304574 },
    { lat: 49.088038, lng: 18.3302721 },
    { lat: 49.0878135, lng: 18.329303 },
    { lat: 49.0877481, lng: 18.3289623 },
    { lat: 49.0876918, lng: 18.3287033 },
    { lat: 49.0876803, lng: 18.3286572 },
    { lat: 49.0876227, lng: 18.3283948 },
    { lat: 49.0875532, lng: 18.3281394 },
    { lat: 49.0875141, lng: 18.3279672 },
    { lat: 49.0874694, lng: 18.3277315 },
    { lat: 49.0874239, lng: 18.3275657 },
    { lat: 49.0874074, lng: 18.3274318 },
    { lat: 49.0873838, lng: 18.3272711 },
    { lat: 49.087361, lng: 18.3271268 },
    { lat: 49.0873298, lng: 18.3267501 },
    { lat: 49.0873242, lng: 18.3266898 },
    { lat: 49.0873202, lng: 18.3265584 },
    { lat: 49.0873061, lng: 18.326408 },
    { lat: 49.0873002, lng: 18.326281 },
    { lat: 49.0872956, lng: 18.3261396 },
    { lat: 49.087284, lng: 18.3258313 },
    { lat: 49.0872586, lng: 18.3254178 },
    { lat: 49.0872788, lng: 18.3253558 },
    { lat: 49.0872917, lng: 18.3252847 },
    { lat: 49.0873194, lng: 18.325253 },
    { lat: 49.0874218, lng: 18.3251695 },
    { lat: 49.0875717, lng: 18.3251259 },
    { lat: 49.0877017, lng: 18.3251103 },
    { lat: 49.0877594, lng: 18.3250947 },
    { lat: 49.0879613, lng: 18.3249904 },
    { lat: 49.0880046, lng: 18.3249384 },
    { lat: 49.0880277, lng: 18.3248674 },
    { lat: 49.0880145, lng: 18.3247334 },
    { lat: 49.0880102, lng: 18.3247165 },
    { lat: 49.0879889, lng: 18.3246183 },
    { lat: 49.0879306, lng: 18.3243228 },
    { lat: 49.0876684, lng: 18.3229856 },
    { lat: 49.0875075, lng: 18.322167 },
    { lat: 49.0871962, lng: 18.3205979 },
    { lat: 49.0870652, lng: 18.3199318 },
    { lat: 49.0869781, lng: 18.3194853 },
    { lat: 49.0867964, lng: 18.3185577 },
    { lat: 49.0867902, lng: 18.3185285 },
    { lat: 49.0867886, lng: 18.3185202 },
    { lat: 49.0867813, lng: 18.3184884 },
    { lat: 49.0867785, lng: 18.3184802 },
    { lat: 49.0867712, lng: 18.3184507 },
    { lat: 49.0867668, lng: 18.3184315 },
    { lat: 49.086761, lng: 18.318409 },
    { lat: 49.086748, lng: 18.3183564 },
    { lat: 49.0867301, lng: 18.3182804 },
    { lat: 49.0867185, lng: 18.3182356 },
    { lat: 49.0867095, lng: 18.3181958 },
    { lat: 49.0865796, lng: 18.3176683 },
    { lat: 49.0865708, lng: 18.3176307 },
    { lat: 49.0865603, lng: 18.3175856 },
    { lat: 49.0865416, lng: 18.317513 },
    { lat: 49.0865371, lng: 18.3174944 },
    { lat: 49.0865355, lng: 18.3174856 },
    { lat: 49.086332, lng: 18.3166536 },
    { lat: 49.0862616, lng: 18.3164648 },
    { lat: 49.0862417, lng: 18.3164098 },
    { lat: 49.0862084, lng: 18.3163202 },
    { lat: 49.0861952, lng: 18.3162848 },
    { lat: 49.086061, lng: 18.3159248 },
    { lat: 49.0860385, lng: 18.3158639 },
    { lat: 49.0859839, lng: 18.3157173 },
    { lat: 49.0859386, lng: 18.3155925 },
    { lat: 49.0858815, lng: 18.3154398 },
    { lat: 49.0849703, lng: 18.3153247 },
    { lat: 49.0843959, lng: 18.315 },
    { lat: 49.0829788, lng: 18.3143345 },
    { lat: 49.0828906, lng: 18.314293 },
    { lat: 49.0825292, lng: 18.3141228 },
    { lat: 49.0818228, lng: 18.3137178 },
    { lat: 49.0814554, lng: 18.3133242 },
    { lat: 49.0804506, lng: 18.3122454 },
    { lat: 49.0792844, lng: 18.3114074 },
    { lat: 49.0788665, lng: 18.3111381 },
    { lat: 49.0783975, lng: 18.3108422 },
    { lat: 49.0776744, lng: 18.3102196 },
    { lat: 49.0768424, lng: 18.3098492 },
    { lat: 49.0761237, lng: 18.3094062 },
    { lat: 49.0756388, lng: 18.3090698 },
    { lat: 49.0750135, lng: 18.3085016 },
    { lat: 49.0741839, lng: 18.3076094 },
    { lat: 49.0737797, lng: 18.3071038 },
    { lat: 49.0732803, lng: 18.3061665 },
    { lat: 49.0732417, lng: 18.3060833 },
    { lat: 49.0731995, lng: 18.3059919 },
    { lat: 49.0729669, lng: 18.3054885 },
    { lat: 49.0725127, lng: 18.304632 },
    { lat: 49.0721278, lng: 18.304087 },
    { lat: 49.0716438, lng: 18.3036658 },
    { lat: 49.0711998, lng: 18.3033944 },
    { lat: 49.0707823, lng: 18.3032719 },
    { lat: 49.0703066, lng: 18.3031798 },
    { lat: 49.0697705, lng: 18.3032349 },
    { lat: 49.0692281, lng: 18.3034185 },
    { lat: 49.0686974, lng: 18.3037143 },
    { lat: 49.067977, lng: 18.3042028 },
    { lat: 49.0678154, lng: 18.3038381 },
    { lat: 49.0677897, lng: 18.3037821 },
    { lat: 49.0676059, lng: 18.3030815 },
    { lat: 49.0674851, lng: 18.3026169 },
    { lat: 49.0672266, lng: 18.3016318 },
    { lat: 49.0668869, lng: 18.3005267 },
    { lat: 49.066579, lng: 18.2995262 },
    { lat: 49.0666103, lng: 18.29928 },
    { lat: 49.0666205, lng: 18.299201 },
    { lat: 49.0666661, lng: 18.2988275 },
    { lat: 49.0678252, lng: 18.2974729 },
    { lat: 49.0677348, lng: 18.2965679 },
    { lat: 49.0676525, lng: 18.2961467 },
    { lat: 49.0675011, lng: 18.2957626 },
    { lat: 49.0673892, lng: 18.2954774 },
    { lat: 49.0672704, lng: 18.2951921 },
    { lat: 49.0672607, lng: 18.2951797 },
    { lat: 49.0672205, lng: 18.2952461 },
    { lat: 49.0672017, lng: 18.2952547 },
    { lat: 49.0671528, lng: 18.2951864 },
    { lat: 49.0669003, lng: 18.295049 },
    { lat: 49.0658898, lng: 18.2944161 },
    { lat: 49.0647565, lng: 18.2936856 },
    { lat: 49.0646121, lng: 18.2935855 },
    { lat: 49.0632694, lng: 18.2926808 },
    { lat: 49.0613042, lng: 18.2915206 },
    { lat: 49.0611217, lng: 18.2916646 },
    { lat: 49.0611068, lng: 18.2916208 },
    { lat: 49.0608694, lng: 18.2915536 },
    { lat: 49.0607503, lng: 18.2915202 },
    { lat: 49.0607962, lng: 18.2917358 },
    { lat: 49.0601787, lng: 18.2914687 },
    { lat: 49.0600988, lng: 18.2914336 },
    { lat: 49.0598202, lng: 18.2913138 },
    { lat: 49.0590356, lng: 18.2909448 },
    { lat: 49.0581209, lng: 18.2905276 },
    { lat: 49.0577604, lng: 18.290357 },
    { lat: 49.0576224, lng: 18.2902904 },
    { lat: 49.0572153, lng: 18.2900948 },
    { lat: 49.0569306, lng: 18.289867 },
    { lat: 49.0565491, lng: 18.2895631 },
    { lat: 49.056002, lng: 18.2891242 },
    { lat: 49.0552771, lng: 18.2885339 },
    { lat: 49.0544629, lng: 18.2878804 },
    { lat: 49.0542809, lng: 18.287735 },
    { lat: 49.0541852, lng: 18.2876574 },
    { lat: 49.054047, lng: 18.2875469 },
    { lat: 49.0540352, lng: 18.2875376 },
    { lat: 49.0533727, lng: 18.2862935 },
    { lat: 49.0529523, lng: 18.2852396 },
    { lat: 49.0527644, lng: 18.2847668 },
    { lat: 49.0527277, lng: 18.2846781 },
    { lat: 49.052468, lng: 18.284034 },
    { lat: 49.052601, lng: 18.284657 },
    { lat: 49.052564, lng: 18.284764 },
    { lat: 49.051886, lng: 18.287151 },
    { lat: 49.051182, lng: 18.288935 },
    { lat: 49.051033, lng: 18.289055 },
    { lat: 49.050824, lng: 18.289222 },
    { lat: 49.050411, lng: 18.289554 },
    { lat: 49.049837, lng: 18.290015 },
    { lat: 49.049788, lng: 18.290053 },
    { lat: 49.049746, lng: 18.290087 },
    { lat: 49.049684, lng: 18.290138 },
    { lat: 49.048726, lng: 18.29247 },
    { lat: 49.050491, lng: 18.293664 },
    { lat: 49.049817, lng: 18.296004 },
    { lat: 49.049784, lng: 18.296119 },
    { lat: 49.049695, lng: 18.296429 },
    { lat: 49.049668, lng: 18.29651 },
    { lat: 49.049623, lng: 18.296516 },
    { lat: 49.049403, lng: 18.296489 },
    { lat: 49.049302, lng: 18.296425 },
    { lat: 49.048676, lng: 18.296204 },
    { lat: 49.048499, lng: 18.296139 },
    { lat: 49.048457, lng: 18.296101 },
    { lat: 49.048353, lng: 18.296387 },
    { lat: 49.04828, lng: 18.296586 },
    { lat: 49.048153, lng: 18.296936 },
    { lat: 49.048043, lng: 18.297242 },
    { lat: 49.047933, lng: 18.297586 },
    { lat: 49.046975, lng: 18.300584 },
    { lat: 49.046951, lng: 18.300624 },
    { lat: 49.046918, lng: 18.300688 },
    { lat: 49.046907, lng: 18.30075 },
    { lat: 49.04656, lng: 18.301595 },
    { lat: 49.046533, lng: 18.301663 },
    { lat: 49.046436, lng: 18.301847 },
    { lat: 49.046346, lng: 18.302104 },
    { lat: 49.046225, lng: 18.302451 },
    { lat: 49.046189, lng: 18.302533 },
    { lat: 49.046168, lng: 18.302583 },
    { lat: 49.046127, lng: 18.302678 },
    { lat: 49.046029, lng: 18.302907 },
    { lat: 49.045397, lng: 18.304375 },
    { lat: 49.045368, lng: 18.304458 },
    { lat: 49.045353, lng: 18.30449 },
    { lat: 49.045345, lng: 18.304537 },
    { lat: 49.044439, lng: 18.303699 },
    { lat: 49.044089, lng: 18.303302 },
    { lat: 49.043723, lng: 18.30294 },
    { lat: 49.043343, lng: 18.302493 },
    { lat: 49.043139, lng: 18.30222 },
    { lat: 49.042572, lng: 18.301588 },
    { lat: 49.042299, lng: 18.301349 },
    { lat: 49.04197, lng: 18.301019 },
    { lat: 49.041809, lng: 18.300789 },
    { lat: 49.041549, lng: 18.30057 },
    { lat: 49.041407, lng: 18.300452 },
    { lat: 49.041376, lng: 18.300513 },
    { lat: 49.041346, lng: 18.30057 },
    { lat: 49.041255, lng: 18.300735 },
    { lat: 49.039798, lng: 18.303043 },
    { lat: 49.0391, lng: 18.30415 },
    { lat: 49.038415, lng: 18.305032 },
    { lat: 49.037301, lng: 18.306526 },
    { lat: 49.03615, lng: 18.30807 },
    { lat: 49.035092, lng: 18.308815 },
    { lat: 49.034267, lng: 18.309587 },
    { lat: 49.034304, lng: 18.31005 },
    { lat: 49.034123, lng: 18.310456 },
    { lat: 49.03337, lng: 18.311207 },
    { lat: 49.032851, lng: 18.31163 },
    { lat: 49.032458, lng: 18.311827 },
    { lat: 49.031924, lng: 18.311979 },
    { lat: 49.0317, lng: 18.312244 },
    { lat: 49.031105, lng: 18.312626 },
    { lat: 49.030471, lng: 18.313125 },
    { lat: 49.029861, lng: 18.31358 },
    { lat: 49.029606, lng: 18.313857 },
    { lat: 49.029536, lng: 18.314156 },
    { lat: 49.029474, lng: 18.314477 },
    { lat: 49.029267, lng: 18.315075 },
    { lat: 49.029026, lng: 18.315444 },
    { lat: 49.028999, lng: 18.315545 },
    { lat: 49.028875, lng: 18.315926 },
    { lat: 49.02857, lng: 18.316865 },
    { lat: 49.027789, lng: 18.318182 },
    { lat: 49.026933, lng: 18.319582 },
    { lat: 49.026633, lng: 18.320256 },
    { lat: 49.02654, lng: 18.320363 },
    { lat: 49.023971, lng: 18.323359 },
    { lat: 49.023797, lng: 18.32357 },
    { lat: 49.023773, lng: 18.323599 },
    { lat: 49.023503, lng: 18.323928 },
    { lat: 49.022041, lng: 18.326035 },
    { lat: 49.021641, lng: 18.326611 },
    { lat: 49.018237, lng: 18.331038 },
    { lat: 49.014899, lng: 18.33538 },
    { lat: 49.014761, lng: 18.335559 },
    { lat: 49.015231, lng: 18.337294 },
    { lat: 49.015272, lng: 18.3395 },
    { lat: 49.015524, lng: 18.340675 },
    { lat: 49.015618, lng: 18.341119 },
    { lat: 49.015795, lng: 18.342781 },
    { lat: 49.015873, lng: 18.344157 },
    { lat: 49.015977, lng: 18.346016 },
    { lat: 49.015708, lng: 18.346112 },
    { lat: 49.015109, lng: 18.345775 },
    { lat: 49.01497, lng: 18.345697 },
    { lat: 49.014872, lng: 18.345642 },
    { lat: 49.014566, lng: 18.345324 },
    { lat: 49.014257, lng: 18.34644 },
    { lat: 49.014826, lng: 18.34672 },
    { lat: 49.014731, lng: 18.347143 },
    { lat: 49.014618, lng: 18.347594 },
    { lat: 49.014774, lng: 18.348885 },
    { lat: 49.014699, lng: 18.348873 },
    { lat: 49.01472, lng: 18.349452 },
    { lat: 49.014669, lng: 18.350151 },
    { lat: 49.013693, lng: 18.349625 },
    { lat: 49.012701, lng: 18.349091 },
    { lat: 49.012722, lng: 18.347868 },
    { lat: 49.012557, lng: 18.347666 },
    { lat: 49.012364, lng: 18.347618 },
    { lat: 49.011909, lng: 18.346345 },
    { lat: 49.011375, lng: 18.345997 },
    { lat: 49.010919, lng: 18.346263 },
    { lat: 49.010885, lng: 18.347219 },
    { lat: 49.010769, lng: 18.347463 },
    { lat: 49.010645, lng: 18.347786 },
    { lat: 49.010617, lng: 18.347857 },
    { lat: 49.0104, lng: 18.34876 },
    { lat: 49.00991, lng: 18.349147 },
    { lat: 49.009409, lng: 18.349941 },
    { lat: 49.00911, lng: 18.350709 },
    { lat: 49.009315, lng: 18.350957 },
    { lat: 49.00968, lng: 18.351329 },
    { lat: 49.010048, lng: 18.351761 },
    { lat: 49.010144, lng: 18.352052 },
    { lat: 49.010101, lng: 18.352326 },
    { lat: 49.010187, lng: 18.352794 },
    { lat: 49.010226, lng: 18.353251 },
    { lat: 49.01019, lng: 18.35327 },
    { lat: 49.010134, lng: 18.353299 },
    { lat: 49.0101, lng: 18.353478 },
    { lat: 49.009858, lng: 18.353379 },
    { lat: 49.010053, lng: 18.355104 },
    { lat: 49.010196, lng: 18.355791 },
    { lat: 49.009919, lng: 18.356241 },
    { lat: 49.009805, lng: 18.356298 },
    { lat: 49.009716, lng: 18.356579 },
    { lat: 49.009298, lng: 18.3567 },
    { lat: 49.008493, lng: 18.356933 },
    { lat: 49.008102, lng: 18.356069 },
    { lat: 49.007991, lng: 18.355084 },
    { lat: 49.007847, lng: 18.354124 },
    { lat: 49.007668, lng: 18.353634 },
    { lat: 49.006608, lng: 18.353865 },
    { lat: 49.006081, lng: 18.354535 },
    { lat: 49.006209, lng: 18.355043 },
    { lat: 49.005593, lng: 18.355908 },
    { lat: 49.005414, lng: 18.355978 },
    { lat: 49.006281, lng: 18.35877 },
    { lat: 49.006547, lng: 18.359627 },
    { lat: 49.00677, lng: 18.360933 },
    { lat: 49.007155, lng: 18.362526 },
    { lat: 49.007126, lng: 18.362844 },
    { lat: 49.006998, lng: 18.363321 },
    { lat: 49.00698, lng: 18.363371 },
    { lat: 49.00679, lng: 18.363648 },
    { lat: 49.006713, lng: 18.363843 },
    { lat: 49.006232, lng: 18.364725 },
    { lat: 49.006115, lng: 18.364538 },
    { lat: 49.006009, lng: 18.364311 },
    { lat: 49.005676, lng: 18.363768 },
    { lat: 49.005316, lng: 18.363246 },
    { lat: 49.004699, lng: 18.362764 },
    { lat: 49.004684, lng: 18.362821 },
    { lat: 49.00461, lng: 18.362968 },
    { lat: 49.004577, lng: 18.363068 },
    { lat: 49.004559, lng: 18.363175 },
    { lat: 49.004521, lng: 18.363286 },
    { lat: 49.004063, lng: 18.363781 },
    { lat: 49.003504, lng: 18.364745 },
    { lat: 49.001602, lng: 18.366294 },
    { lat: 49.000867, lng: 18.366987 },
    { lat: 49.000154, lng: 18.367826 },
    { lat: 49.000102, lng: 18.367901 },
    { lat: 48.999618, lng: 18.368219 },
    { lat: 48.997752, lng: 18.36933 },
    { lat: 48.996241, lng: 18.370572 },
    { lat: 48.99476, lng: 18.37179 },
    { lat: 48.993416, lng: 18.372896 },
    { lat: 48.991191, lng: 18.374664 },
    { lat: 48.990432, lng: 18.375267 },
    { lat: 48.990315, lng: 18.37536 },
    { lat: 48.98683, lng: 18.378131 },
    { lat: 48.98752, lng: 18.3794124 },
    { lat: 48.9875796, lng: 18.3795293 },
    { lat: 48.9883128, lng: 18.381476 },
    { lat: 48.989211, lng: 18.3827777 },
    { lat: 48.9894575, lng: 18.3828687 },
    { lat: 48.9899991, lng: 18.3830734 },
    { lat: 48.9910078, lng: 18.3841114 },
    { lat: 48.9913517, lng: 18.3841263 },
    { lat: 48.9916435, lng: 18.384588 },
    { lat: 48.9917821, lng: 18.385053 },
    { lat: 48.9921427, lng: 18.3854719 },
    { lat: 48.9927308, lng: 18.3854985 },
    { lat: 48.9931847, lng: 18.3863365 },
    { lat: 48.9942315, lng: 18.3872497 },
    { lat: 48.9951046, lng: 18.3884263 },
    { lat: 48.9951342, lng: 18.3884706 },
    { lat: 48.9952405, lng: 18.3886373 },
    { lat: 48.9953133, lng: 18.3887505 },
    { lat: 48.995586, lng: 18.3881975 },
    { lat: 48.9961088, lng: 18.3882058 },
    { lat: 48.9964769, lng: 18.387846 },
    { lat: 48.9964906, lng: 18.3875242 },
    { lat: 48.9968004, lng: 18.3875976 },
    { lat: 48.9976102, lng: 18.38717 },
    { lat: 48.9979398, lng: 18.386786 },
    { lat: 48.9979629, lng: 18.3868377 },
    { lat: 48.9979809, lng: 18.3869579 },
    { lat: 48.9979939, lng: 18.3869896 },
    { lat: 49.0000738, lng: 18.3851959 },
    { lat: 49.0004356, lng: 18.3855549 },
    { lat: 49.0008296, lng: 18.3859265 },
    { lat: 49.0014116, lng: 18.3863487 },
    { lat: 49.0018322, lng: 18.3865721 },
    { lat: 49.002177, lng: 18.3867468 },
    { lat: 49.0051376, lng: 18.3894151 },
    { lat: 49.005729, lng: 18.3899484 },
    { lat: 49.0063307, lng: 18.3911941 },
    { lat: 49.0077085, lng: 18.3898813 },
    { lat: 49.008385, lng: 18.3906464 },
    { lat: 49.0078494, lng: 18.3946344 },
    { lat: 49.0077303, lng: 18.3965139 },
    { lat: 49.0080117, lng: 18.397662 },
    { lat: 49.0085473, lng: 18.3987346 },
    { lat: 49.00891, lng: 18.39947 },
    { lat: 49.009372, lng: 18.40072 },
    { lat: 49.010837, lng: 18.402354 },
    { lat: 49.011827, lng: 18.402997 },
    { lat: 49.013326, lng: 18.401311 },
    { lat: 49.014828, lng: 18.401956 },
    { lat: 49.0176819, lng: 18.40182 },
    { lat: 49.018185, lng: 18.401796 },
    { lat: 49.019326, lng: 18.402403 },
    { lat: 49.020199, lng: 18.404227 },
    { lat: 49.020697, lng: 18.40447 },
    { lat: 49.021001, lng: 18.403871 },
    { lat: 49.021482, lng: 18.403203 },
    { lat: 49.022242, lng: 18.402538 },
    { lat: 49.022653, lng: 18.402077 },
    { lat: 49.022972, lng: 18.401938 },
    { lat: 49.023662, lng: 18.401333 },
    { lat: 49.024037, lng: 18.401086 },
    { lat: 49.024295, lng: 18.400821 },
    { lat: 49.025668, lng: 18.398691 },
    { lat: 49.026182, lng: 18.398063 },
    { lat: 49.02677, lng: 18.397274 },
    { lat: 49.027641, lng: 18.396173 },
    { lat: 49.028499, lng: 18.395192 },
    { lat: 49.0292, lng: 18.394226 },
    { lat: 49.029909, lng: 18.394029 },
    { lat: 49.030428, lng: 18.393635 },
    { lat: 49.031109, lng: 18.393313 },
    { lat: 49.031241, lng: 18.393313 },
    { lat: 49.032828, lng: 18.393302 },
    { lat: 49.033441, lng: 18.393463 },
    { lat: 49.034707, lng: 18.393776 },
    { lat: 49.035108, lng: 18.393883 },
    { lat: 49.036367, lng: 18.394221 },
    { lat: 49.037326, lng: 18.3919315 },
    { lat: 49.037394, lng: 18.391769 },
    { lat: 49.038508, lng: 18.390795 },
    { lat: 49.039383, lng: 18.389992 },
    { lat: 49.040004, lng: 18.389492 },
    { lat: 49.041154, lng: 18.388757 },
    { lat: 49.041817, lng: 18.388342 },
    { lat: 49.042144, lng: 18.388309 },
    { lat: 49.042721, lng: 18.387684 },
    { lat: 49.042933, lng: 18.387294 },
    { lat: 49.043324, lng: 18.38701 },
    { lat: 49.04388, lng: 18.386402 },
    { lat: 49.045158, lng: 18.385322 },
    { lat: 49.045639, lng: 18.384857 },
    { lat: 49.045934, lng: 18.384704 },
    { lat: 49.047318, lng: 18.383625 },
    { lat: 49.047862, lng: 18.384287 },
    { lat: 49.049703, lng: 18.386999 },
    { lat: 49.049818, lng: 18.386715 },
    { lat: 49.049924, lng: 18.386451 },
    { lat: 49.050139, lng: 18.386197 },
    { lat: 49.050307, lng: 18.386154 },
    { lat: 49.050533, lng: 18.386278 },
    { lat: 49.050799, lng: 18.38636 },
    { lat: 49.051034, lng: 18.386345 },
    { lat: 49.051156, lng: 18.386517 },
    { lat: 49.051714, lng: 18.386347 },
    { lat: 49.052, lng: 18.386411 },
    { lat: 49.0521523, lng: 18.3856125 },
    { lat: 49.0520437, lng: 18.3854247 },
    { lat: 49.0518216, lng: 18.3823402 },
    { lat: 49.051849, lng: 18.3815136 },
    { lat: 49.0517485, lng: 18.3806799 },
    { lat: 49.0517038, lng: 18.3804614 },
    { lat: 49.0512903, lng: 18.378443 },
    { lat: 49.05079, lng: 18.3777418 },
    { lat: 49.0504985, lng: 18.3773289 },
    { lat: 49.0502238, lng: 18.3768307 },
    { lat: 49.0500556, lng: 18.3764844 },
    { lat: 49.0499101, lng: 18.3754914 },
    { lat: 49.0499305, lng: 18.3754106 },
    { lat: 49.0505359, lng: 18.3750185 },
    { lat: 49.0506453, lng: 18.3749363 },
    { lat: 49.0507917, lng: 18.3748286 },
    { lat: 49.0509447, lng: 18.3746605 },
    { lat: 49.0510651, lng: 18.374494 },
    { lat: 49.0511623, lng: 18.3742918 },
    { lat: 49.0514126, lng: 18.3738454 },
    { lat: 49.0516212, lng: 18.3732355 },
    { lat: 49.0517587, lng: 18.3729281 },
    { lat: 49.051856, lng: 18.3727093 },
    { lat: 49.051968, lng: 18.372452 },
    { lat: 49.0520427, lng: 18.3722535 },
    { lat: 49.0521398, lng: 18.3719711 },
    { lat: 49.0521765, lng: 18.3718385 },
    { lat: 49.0523042, lng: 18.3716212 },
    { lat: 49.0524183, lng: 18.3714623 },
    { lat: 49.052586, lng: 18.37127 },
    { lat: 49.0526768, lng: 18.371202 },
    { lat: 49.0527671, lng: 18.3711425 },
    { lat: 49.0528666, lng: 18.3711236 },
    { lat: 49.0529861, lng: 18.371103 },
    { lat: 49.0530361, lng: 18.3710683 },
    { lat: 49.0530679, lng: 18.3710117 },
    { lat: 49.0532075, lng: 18.3708445 },
    { lat: 49.0533536, lng: 18.3706725 },
    { lat: 49.0534221, lng: 18.3705834 },
    { lat: 49.0535021, lng: 18.3705836 },
    { lat: 49.0537954, lng: 18.3705202 },
    { lat: 49.0538802, lng: 18.3705057 },
    { lat: 49.0539532, lng: 18.3704602 },
    { lat: 49.0540112, lng: 18.3703867 },
    { lat: 49.0540583, lng: 18.3702785 },
    { lat: 49.0540768, lng: 18.3701872 },
    { lat: 49.0539581, lng: 18.3698378 },
    { lat: 49.0539687, lng: 18.3696849 },
    { lat: 49.0540212, lng: 18.3695932 },
    { lat: 49.0540895, lng: 18.3695234 },
    { lat: 49.0541335, lng: 18.3695023 },
    { lat: 49.0546593, lng: 18.3693411 },
    { lat: 49.0547125, lng: 18.3692648 },
    { lat: 49.0547719, lng: 18.3690356 },
    { lat: 49.0543008, lng: 18.3674415 },
    { lat: 49.0541026, lng: 18.3672256 },
    { lat: 49.0535936, lng: 18.3670401 },
    { lat: 49.0546494, lng: 18.366226 },
    { lat: 49.054683, lng: 18.3662008 },
    { lat: 49.0551817, lng: 18.3658168 },
    { lat: 49.0567034, lng: 18.3648023 },
    { lat: 49.0567841, lng: 18.36453 },
    { lat: 49.0569196, lng: 18.3643083 },
    { lat: 49.0576157, lng: 18.3636207 },
    { lat: 49.057933, lng: 18.3626726 },
    { lat: 49.0583659, lng: 18.361495 },
    { lat: 49.0587297, lng: 18.3604712 },
    { lat: 49.0589606, lng: 18.3601206 },
    { lat: 49.0591059, lng: 18.3598545 },
    { lat: 49.0595353, lng: 18.3583696 },
    { lat: 49.0596873, lng: 18.3578156 },
    { lat: 49.0598378, lng: 18.356853 },
    { lat: 49.0607339, lng: 18.3548176 },
    { lat: 49.0613626, lng: 18.3533062 },
    { lat: 49.062326, lng: 18.3531238 },
    { lat: 49.0624399, lng: 18.3531023 },
    { lat: 49.0625437, lng: 18.3530828 },
    { lat: 49.0626074, lng: 18.353071 },
    { lat: 49.062662, lng: 18.3530603 },
    { lat: 49.0627715, lng: 18.3530397 },
    { lat: 49.0628262, lng: 18.3530294 },
    { lat: 49.0628563, lng: 18.3530237 },
    { lat: 49.0629139, lng: 18.3530033 },
    { lat: 49.0629769, lng: 18.3529811 },
    { lat: 49.0630277, lng: 18.3529634 },
    { lat: 49.0630787, lng: 18.3529451 },
    { lat: 49.0631151, lng: 18.3529323 },
    { lat: 49.0642647, lng: 18.3525266 },
    { lat: 49.0642702, lng: 18.3525248 },
    { lat: 49.06432, lng: 18.3525072 },
    { lat: 49.0643052, lng: 18.351048 },
    { lat: 49.0644024, lng: 18.3507481 },
    { lat: 49.0652238, lng: 18.3482055 },
    { lat: 49.0659396, lng: 18.3460518 },
    { lat: 49.0664811, lng: 18.3444374 },
    { lat: 49.0665315, lng: 18.3443677 },
    { lat: 49.0665565, lng: 18.3443319 },
    { lat: 49.0665723, lng: 18.3443101 },
    { lat: 49.0666056, lng: 18.3442624 },
    { lat: 49.066799, lng: 18.3441872 },
    { lat: 49.0678853, lng: 18.343763 },
    { lat: 49.0688227, lng: 18.3433974 },
    { lat: 49.0689496, lng: 18.3433475 },
    { lat: 49.0690246, lng: 18.3433373 },
    { lat: 49.0690517, lng: 18.3433582 },
    { lat: 49.0691083, lng: 18.3434016 },
    { lat: 49.0691448, lng: 18.3434297 },
    { lat: 49.0691039, lng: 18.3437255 },
    { lat: 49.069055, lng: 18.3440824 },
    { lat: 49.0689979, lng: 18.3444144 },
    { lat: 49.0687972, lng: 18.3452839 },
    { lat: 49.0687702, lng: 18.345549 },
    { lat: 49.0687447, lng: 18.3457989 },
    { lat: 49.068792, lng: 18.3470697 },
    { lat: 49.0688725, lng: 18.3482102 },
    { lat: 49.0690058, lng: 18.3491623 },
    { lat: 49.0690484, lng: 18.3495409 },
    { lat: 49.0690722, lng: 18.3500478 },
    { lat: 49.0690772, lng: 18.3512301 },
    { lat: 49.0690934, lng: 18.3520057 },
    { lat: 49.069058, lng: 18.3531726 },
    { lat: 49.069376, lng: 18.3529677 },
    { lat: 49.0699176, lng: 18.3524413 },
    { lat: 49.070258, lng: 18.3522771 },
    { lat: 49.0705929, lng: 18.3519082 },
    { lat: 49.0715811, lng: 18.3514214 },
    { lat: 49.071871, lng: 18.351706 },
    { lat: 49.0722392, lng: 18.3515508 },
    { lat: 49.072637, lng: 18.3511795 },
    { lat: 49.0733107, lng: 18.3502922 },
    { lat: 49.0743605, lng: 18.3525283 },
    { lat: 49.0756549, lng: 18.3552266 },
    { lat: 49.0770032, lng: 18.3574154 },
    { lat: 49.0779496, lng: 18.3592776 },
    { lat: 49.0782436, lng: 18.3601803 },
    { lat: 49.0785954, lng: 18.3609878 },
    { lat: 49.0799277, lng: 18.3628313 },
    { lat: 49.0804458, lng: 18.3641551 },
    { lat: 49.080607, lng: 18.3642939 },
    { lat: 49.0804298, lng: 18.3649196 },
    { lat: 49.0804174, lng: 18.3649606 },
    { lat: 49.080401, lng: 18.3653963 },
    { lat: 49.0803515, lng: 18.3665934 },
    { lat: 49.0813335, lng: 18.3676977 },
    { lat: 49.0814729, lng: 18.367833 },
    { lat: 49.0815007, lng: 18.3680256 },
    { lat: 49.0816777, lng: 18.3681467 },
    { lat: 49.0816786, lng: 18.3686921 },
    { lat: 49.0820318, lng: 18.3691589 },
    { lat: 49.0821419, lng: 18.3694753 },
    { lat: 49.0822455, lng: 18.3697361 },
    { lat: 49.0823349, lng: 18.3700438 },
    { lat: 49.0824601, lng: 18.3701659 },
    { lat: 49.0833681, lng: 18.3709552 },
    { lat: 49.0836421, lng: 18.371257 },
    { lat: 49.0836155, lng: 18.371287 },
    { lat: 49.083582, lng: 18.3713325 },
    { lat: 49.0838224, lng: 18.3718022 },
    { lat: 49.0843271, lng: 18.3728848 },
    { lat: 49.0845303, lng: 18.3731721 },
    { lat: 49.0856532, lng: 18.3746609 },
    { lat: 49.0858342, lng: 18.3748312 },
    { lat: 49.0863922, lng: 18.3756163 },
    { lat: 49.0874862, lng: 18.3771569 },
    { lat: 49.088108, lng: 18.3777689 },
    { lat: 49.0883247, lng: 18.3785638 },
    { lat: 49.0885008, lng: 18.3791523 },
    { lat: 49.0888873, lng: 18.3806495 },
    { lat: 49.090152, lng: 18.3828058 },
    { lat: 49.0903223, lng: 18.3831697 },
    { lat: 49.0907329, lng: 18.3840429 },
    { lat: 49.0908284, lng: 18.3843007 },
    { lat: 49.0912099, lng: 18.385262 },
    { lat: 49.093122, lng: 18.38873 },
    { lat: 49.092986, lng: 18.388986 },
    { lat: 49.09294, lng: 18.389071 },
    { lat: 49.092897, lng: 18.389153 },
    { lat: 49.092935, lng: 18.390022 },
    { lat: 49.092912, lng: 18.391478 },
    { lat: 49.093248, lng: 18.392158 },
    { lat: 49.093524, lng: 18.393437 },
    { lat: 49.093557, lng: 18.393602 },
    { lat: 49.093713, lng: 18.393584 },
    { lat: 49.093783, lng: 18.394624 },
    { lat: 49.09389, lng: 18.394656 },
    { lat: 49.094015, lng: 18.395548 },
    { lat: 49.094061, lng: 18.395779 },
    { lat: 49.094082, lng: 18.39586 },
    { lat: 49.094094, lng: 18.395934 },
    { lat: 49.094542, lng: 18.395872 },
    { lat: 49.09484, lng: 18.395809 },
    { lat: 49.095008, lng: 18.395696 },
    { lat: 49.095208, lng: 18.395428 },
    { lat: 49.095378, lng: 18.395344 },
    { lat: 49.095569, lng: 18.39508 },
    { lat: 49.095896, lng: 18.395146 },
    { lat: 49.096187, lng: 18.39526 },
    { lat: 49.096514, lng: 18.395308 },
    { lat: 49.09697, lng: 18.395565 },
    { lat: 49.097086, lng: 18.395879 },
    { lat: 49.09727, lng: 18.396032 },
    { lat: 49.098122, lng: 18.396469 },
  ],
  Dohňany: [
    { lat: 49.21904, lng: 18.283455 },
    { lat: 49.2190312, lng: 18.2829601 },
    { lat: 49.2189311, lng: 18.2826266 },
    { lat: 49.2188805, lng: 18.2822895 },
    { lat: 49.2188684, lng: 18.2822128 },
    { lat: 49.218843, lng: 18.2820436 },
    { lat: 49.2187998, lng: 18.2817533 },
    { lat: 49.2187296, lng: 18.2812919 },
    { lat: 49.2182614, lng: 18.2803867 },
    { lat: 49.2180306, lng: 18.2800148 },
    { lat: 49.2179513, lng: 18.2798864 },
    { lat: 49.2177679, lng: 18.2795566 },
    { lat: 49.2176828, lng: 18.2794046 },
    { lat: 49.2174216, lng: 18.278994 },
    { lat: 49.2171729, lng: 18.2783467 },
    { lat: 49.2169682, lng: 18.2781361 },
    { lat: 49.2163923, lng: 18.2775436 },
    { lat: 49.2163671, lng: 18.2775169 },
    { lat: 49.2162814, lng: 18.277518 },
    { lat: 49.2160265, lng: 18.2775228 },
    { lat: 49.215634, lng: 18.2775214 },
    { lat: 49.2153031, lng: 18.2775561 },
    { lat: 49.2147441, lng: 18.2783854 },
    { lat: 49.2147157, lng: 18.2784265 },
    { lat: 49.2140575, lng: 18.2789398 },
    { lat: 49.2140468, lng: 18.2789465 },
    { lat: 49.2140095, lng: 18.2789689 },
    { lat: 49.2139736, lng: 18.2789902 },
    { lat: 49.2138938, lng: 18.2790385 },
    { lat: 49.213774, lng: 18.2791109 },
    { lat: 49.2136638, lng: 18.2791769 },
    { lat: 49.2136528, lng: 18.2791836 },
    { lat: 49.2135622, lng: 18.2792382 },
    { lat: 49.2134182, lng: 18.279325 },
    { lat: 49.2131923, lng: 18.2794614 },
    { lat: 49.2129979, lng: 18.2795778 },
    { lat: 49.2127989, lng: 18.2795884 },
    { lat: 49.212323, lng: 18.2796132 },
    { lat: 49.2122373, lng: 18.2796142 },
    { lat: 49.2114046, lng: 18.2796298 },
    { lat: 49.2102349, lng: 18.2794939 },
    { lat: 49.2101121, lng: 18.2794793 },
    { lat: 49.2099701, lng: 18.2790808 },
    { lat: 49.2099378, lng: 18.2790075 },
    { lat: 49.2098343, lng: 18.2787731 },
    { lat: 49.2096068, lng: 18.2782584 },
    { lat: 49.2093055, lng: 18.2774756 },
    { lat: 49.2089855, lng: 18.2764762 },
    { lat: 49.2085526, lng: 18.2761829 },
    { lat: 49.2084681, lng: 18.2761253 },
    { lat: 49.208011, lng: 18.2766234 },
    { lat: 49.2078962, lng: 18.2767482 },
    { lat: 49.2076794, lng: 18.2772253 },
    { lat: 49.2074625, lng: 18.2780811 },
    { lat: 49.2072557, lng: 18.2785576 },
    { lat: 49.2071193, lng: 18.2788212 },
    { lat: 49.2070389, lng: 18.2789764 },
    { lat: 49.2066918, lng: 18.2793257 },
    { lat: 49.2065857, lng: 18.2793849 },
    { lat: 49.2062725, lng: 18.2795322 },
    { lat: 49.2061507, lng: 18.2795897 },
    { lat: 49.2056479, lng: 18.2799606 },
    { lat: 49.2052841, lng: 18.2803743 },
    { lat: 49.2050689, lng: 18.2804979 },
    { lat: 49.2048306, lng: 18.2808524 },
    { lat: 49.2044621, lng: 18.2810423 },
    { lat: 49.2038861, lng: 18.2809091 },
    { lat: 49.2034126, lng: 18.2806541 },
    { lat: 49.2031976, lng: 18.2804413 },
    { lat: 49.2029118, lng: 18.2798328 },
    { lat: 49.2027239, lng: 18.2792413 },
    { lat: 49.2024838, lng: 18.2786634 },
    { lat: 49.2024099, lng: 18.2783751 },
    { lat: 49.2022579, lng: 18.2779763 },
    { lat: 49.2015777, lng: 18.2774388 },
    { lat: 49.2010948, lng: 18.2770574 },
    { lat: 49.2010654, lng: 18.2770346 },
    { lat: 49.2010015, lng: 18.276984 },
    { lat: 49.200723, lng: 18.2766633 },
    { lat: 49.200691, lng: 18.2765782 },
    { lat: 49.2004201, lng: 18.2758516 },
    { lat: 49.2003985, lng: 18.2757917 },
    { lat: 49.2003109, lng: 18.2755591 },
    { lat: 49.1999612, lng: 18.2747777 },
    { lat: 49.1997254, lng: 18.2746571 },
    { lat: 49.1994864, lng: 18.2741577 },
    { lat: 49.1993958, lng: 18.2738514 },
    { lat: 49.1993065, lng: 18.2735507 },
    { lat: 49.1990694, lng: 18.2734458 },
    { lat: 49.1987593, lng: 18.2733088 },
    { lat: 49.1987489, lng: 18.2733042 },
    { lat: 49.1984637, lng: 18.272685 },
    { lat: 49.1983477, lng: 18.2724318 },
    { lat: 49.1981102, lng: 18.2718414 },
    { lat: 49.198084, lng: 18.2717743 },
    { lat: 49.1980436, lng: 18.2714986 },
    { lat: 49.1977593, lng: 18.2695566 },
    { lat: 49.1975644, lng: 18.2690237 },
    { lat: 49.1974043, lng: 18.2687251 },
    { lat: 49.1973489, lng: 18.2686212 },
    { lat: 49.1973414, lng: 18.2684673 },
    { lat: 49.1973386, lng: 18.2683978 },
    { lat: 49.1973112, lng: 18.2678524 },
    { lat: 49.1972791, lng: 18.2677022 },
    { lat: 49.197195, lng: 18.2672935 },
    { lat: 49.197155, lng: 18.2671028 },
    { lat: 49.1971242, lng: 18.266955 },
    { lat: 49.1970032, lng: 18.2667092 },
    { lat: 49.1969751, lng: 18.2666508 },
    { lat: 49.196844, lng: 18.2663881 },
    { lat: 49.1967548, lng: 18.2662073 },
    { lat: 49.1965339, lng: 18.2658314 },
    { lat: 49.196386, lng: 18.2657505 },
    { lat: 49.1961744, lng: 18.2656362 },
    { lat: 49.1960288, lng: 18.2655572 },
    { lat: 49.1957172, lng: 18.2656331 },
    { lat: 49.1954995, lng: 18.2655329 },
    { lat: 49.195458, lng: 18.2654178 },
    { lat: 49.1954016, lng: 18.2652564 },
    { lat: 49.1953829, lng: 18.2651254 },
    { lat: 49.1953735, lng: 18.2650595 },
    { lat: 49.1953247, lng: 18.264714 },
    { lat: 49.1952968, lng: 18.2645209 },
    { lat: 49.1953804, lng: 18.2644811 },
    { lat: 49.1953182, lng: 18.2640993 },
    { lat: 49.1947859, lng: 18.2638633 },
    { lat: 49.1944942, lng: 18.2637338 },
    { lat: 49.1942131, lng: 18.2636088 },
    { lat: 49.1941344, lng: 18.2635744 },
    { lat: 49.1940933, lng: 18.2635044 },
    { lat: 49.1940209, lng: 18.2633812 },
    { lat: 49.1938337, lng: 18.2630639 },
    { lat: 49.1935458, lng: 18.2625414 },
    { lat: 49.1930812, lng: 18.2624449 },
    { lat: 49.1929096, lng: 18.2625037 },
    { lat: 49.1928765, lng: 18.2625153 },
    { lat: 49.1926316, lng: 18.2625962 },
    { lat: 49.1925077, lng: 18.2627964 },
    { lat: 49.1924808, lng: 18.26284 },
    { lat: 49.1924565, lng: 18.2630297 },
    { lat: 49.1924454, lng: 18.263113 },
    { lat: 49.1921263, lng: 18.2632566 },
    { lat: 49.1920207, lng: 18.263326 },
    { lat: 49.1916052, lng: 18.2636201 },
    { lat: 49.191406, lng: 18.263928 },
    { lat: 49.1905034, lng: 18.2648255 },
    { lat: 49.1901259, lng: 18.2648738 },
    { lat: 49.189729, lng: 18.2652801 },
    { lat: 49.1896208, lng: 18.2653028 },
    { lat: 49.1893207, lng: 18.265201 },
    { lat: 49.1891444, lng: 18.2652573 },
    { lat: 49.1888806, lng: 18.2656225 },
    { lat: 49.1888507, lng: 18.2658077 },
    { lat: 49.1888618, lng: 18.2660259 },
    { lat: 49.1888671, lng: 18.2661002 },
    { lat: 49.1888858, lng: 18.2665526 },
    { lat: 49.1887026, lng: 18.2678856 },
    { lat: 49.1888255, lng: 18.2682421 },
    { lat: 49.1886772, lng: 18.2695952 },
    { lat: 49.1882749, lng: 18.2701639 },
    { lat: 49.1875226, lng: 18.2714094 },
    { lat: 49.1874898, lng: 18.271468 },
    { lat: 49.1874739, lng: 18.2714834 },
    { lat: 49.1870541, lng: 18.2712629 },
    { lat: 49.1868646, lng: 18.2711644 },
    { lat: 49.186852, lng: 18.2711609 },
    { lat: 49.1865206, lng: 18.2702056 },
    { lat: 49.1865057, lng: 18.2701814 },
    { lat: 49.1863351, lng: 18.2698565 },
    { lat: 49.1861534, lng: 18.2695364 },
    { lat: 49.1859818, lng: 18.2693546 },
    { lat: 49.1852874, lng: 18.2696448 },
    { lat: 49.1847021, lng: 18.2698896 },
    { lat: 49.1846471, lng: 18.2699127 },
    { lat: 49.1846328, lng: 18.2698793 },
    { lat: 49.1846265, lng: 18.2698267 },
    { lat: 49.1843029, lng: 18.2699728 },
    { lat: 49.1838705, lng: 18.2700665 },
    { lat: 49.1834208, lng: 18.270295 },
    { lat: 49.183132, lng: 18.2702757 },
    { lat: 49.1829121, lng: 18.269238 },
    { lat: 49.1828489, lng: 18.2689365 },
    { lat: 49.1827383, lng: 18.268414 },
    { lat: 49.1822105, lng: 18.2668569 },
    { lat: 49.1816205, lng: 18.2672385 },
    { lat: 49.1815324, lng: 18.2672966 },
    { lat: 49.1815053, lng: 18.2673167 },
    { lat: 49.1810006, lng: 18.2677523 },
    { lat: 49.1807149, lng: 18.2678641 },
    { lat: 49.1803483, lng: 18.2680074 },
    { lat: 49.1805328, lng: 18.2689799 },
    { lat: 49.1805516, lng: 18.269092 },
    { lat: 49.1805108, lng: 18.2690687 },
    { lat: 49.1796599, lng: 18.2685438 },
    { lat: 49.1795547, lng: 18.2684797 },
    { lat: 49.1791517, lng: 18.2684561 },
    { lat: 49.179142, lng: 18.2684628 },
    { lat: 49.178956, lng: 18.2686051 },
    { lat: 49.178788, lng: 18.268867 },
    { lat: 49.1780983, lng: 18.2699805 },
    { lat: 49.177852, lng: 18.2704154 },
    { lat: 49.1778045, lng: 18.2704732 },
    { lat: 49.1777551, lng: 18.2706178 },
    { lat: 49.1776071, lng: 18.2708145 },
    { lat: 49.177438, lng: 18.2711374 },
    { lat: 49.1774261, lng: 18.2711678 },
    { lat: 49.1767767, lng: 18.2727583 },
    { lat: 49.1765222, lng: 18.2730666 },
    { lat: 49.1761632, lng: 18.2735003 },
    { lat: 49.1757443, lng: 18.2736563 },
    { lat: 49.1754206, lng: 18.2737766 },
    { lat: 49.174331, lng: 18.274182 },
    { lat: 49.1739203, lng: 18.2744808 },
    { lat: 49.1736353, lng: 18.2746882 },
    { lat: 49.1734144, lng: 18.2748132 },
    { lat: 49.1732967, lng: 18.2748281 },
    { lat: 49.1730375, lng: 18.2748738 },
    { lat: 49.1722612, lng: 18.2750112 },
    { lat: 49.1722309, lng: 18.2750152 },
    { lat: 49.1718277, lng: 18.275147 },
    { lat: 49.1717036, lng: 18.2751878 },
    { lat: 49.1713547, lng: 18.275302 },
    { lat: 49.1710098, lng: 18.2754673 },
    { lat: 49.1708155, lng: 18.2755834 },
    { lat: 49.1705437, lng: 18.2757465 },
    { lat: 49.1704726, lng: 18.2757857 },
    { lat: 49.1701762, lng: 18.2759515 },
    { lat: 49.1698273, lng: 18.2761461 },
    { lat: 49.1690983, lng: 18.2767831 },
    { lat: 49.1687554, lng: 18.2768663 },
    { lat: 49.1684784, lng: 18.2769754 },
    { lat: 49.1684364, lng: 18.2769915 },
    { lat: 49.1676578, lng: 18.2772884 },
    { lat: 49.1671442, lng: 18.2772641 },
    { lat: 49.1666827, lng: 18.2772423 },
    { lat: 49.1660818, lng: 18.2770517 },
    { lat: 49.1659142, lng: 18.2769983 },
    { lat: 49.1657806, lng: 18.2769106 },
    { lat: 49.165659, lng: 18.2768547 },
    { lat: 49.1656009, lng: 18.2768236 },
    { lat: 49.1655543, lng: 18.2767987 },
    { lat: 49.1654182, lng: 18.2767255 },
    { lat: 49.1653263, lng: 18.2766768 },
    { lat: 49.1649738, lng: 18.2764884 },
    { lat: 49.1649808, lng: 18.2766314 },
    { lat: 49.164992, lng: 18.2766912 },
    { lat: 49.1645784, lng: 18.2766174 },
    { lat: 49.1641896, lng: 18.2763857 },
    { lat: 49.1641429, lng: 18.2763568 },
    { lat: 49.1638481, lng: 18.2760108 },
    { lat: 49.163601, lng: 18.2755889 },
    { lat: 49.1635068, lng: 18.2754394 },
    { lat: 49.163427, lng: 18.2753248 },
    { lat: 49.1634077, lng: 18.2752047 },
    { lat: 49.1633985, lng: 18.2751401 },
    { lat: 49.1633861, lng: 18.2750601 },
    { lat: 49.1633703, lng: 18.2749598 },
    { lat: 49.1633475, lng: 18.274816 },
    { lat: 49.163262, lng: 18.2741185 },
    { lat: 49.1632471, lng: 18.274015 },
    { lat: 49.1632341, lng: 18.2739242 },
    { lat: 49.1632261, lng: 18.2738608 },
    { lat: 49.1632172, lng: 18.2738039 },
    { lat: 49.1630868, lng: 18.2729338 },
    { lat: 49.1630754, lng: 18.2728698 },
    { lat: 49.1630496, lng: 18.2727932 },
    { lat: 49.1630072, lng: 18.2726623 },
    { lat: 49.162966, lng: 18.2725314 },
    { lat: 49.1629586, lng: 18.2725099 },
    { lat: 49.1629499, lng: 18.2724848 },
    { lat: 49.1629268, lng: 18.2724136 },
    { lat: 49.162898, lng: 18.2723244 },
    { lat: 49.1628797, lng: 18.2722606 },
    { lat: 49.1628472, lng: 18.2721638 },
    { lat: 49.162671, lng: 18.2716403 },
    { lat: 49.1610488, lng: 18.2700037 },
    { lat: 49.1606922, lng: 18.2694823 },
    { lat: 49.1603376, lng: 18.2686548 },
    { lat: 49.1602278, lng: 18.2681678 },
    { lat: 49.1601232, lng: 18.2677332 },
    { lat: 49.1600923, lng: 18.2676053 },
    { lat: 49.1600788, lng: 18.2675469 },
    { lat: 49.1599489, lng: 18.2670045 },
    { lat: 49.1596625, lng: 18.2661664 },
    { lat: 49.1596505, lng: 18.2661159 },
    { lat: 49.1594663, lng: 18.2662484 },
    { lat: 49.1591665, lng: 18.2662955 },
    { lat: 49.1590546, lng: 18.2663129 },
    { lat: 49.1590204, lng: 18.2662265 },
    { lat: 49.159003, lng: 18.2661776 },
    { lat: 49.1587569, lng: 18.2655738 },
    { lat: 49.1587618, lng: 18.265163 },
    { lat: 49.1587263, lng: 18.2651112 },
    { lat: 49.1585963, lng: 18.2649275 },
    { lat: 49.1583071, lng: 18.2646214 },
    { lat: 49.1579505, lng: 18.2647401 },
    { lat: 49.1575767, lng: 18.2650122 },
    { lat: 49.1573151, lng: 18.2651154 },
    { lat: 49.1567354, lng: 18.2641602 },
    { lat: 49.1567581, lng: 18.2632218 },
    { lat: 49.1565441, lng: 18.2627687 },
    { lat: 49.1564753, lng: 18.2618708 },
    { lat: 49.1564938, lng: 18.2604222 },
    { lat: 49.1564796, lng: 18.2591313 },
    { lat: 49.1565703, lng: 18.2580201 },
    { lat: 49.1565562, lng: 18.2569314 },
    { lat: 49.1564883, lng: 18.2560893 },
    { lat: 49.1564994, lng: 18.2560263 },
    { lat: 49.1567701, lng: 18.2556458 },
    { lat: 49.1569134, lng: 18.2551872 },
    { lat: 49.1569503, lng: 18.2547015 },
    { lat: 49.1564738, lng: 18.2517447 },
    { lat: 49.1564831, lng: 18.2516686 },
    { lat: 49.1564868, lng: 18.2515934 },
    { lat: 49.1565132, lng: 18.251164 },
    { lat: 49.156304, lng: 18.2495592 },
    { lat: 49.1565313, lng: 18.2495082 },
    { lat: 49.1569454, lng: 18.2492093 },
    { lat: 49.1570028, lng: 18.2491672 },
    { lat: 49.1570745, lng: 18.2491188 },
    { lat: 49.1575251, lng: 18.2491259 },
    { lat: 49.1577095, lng: 18.248217 },
    { lat: 49.1577635, lng: 18.2479368 },
    { lat: 49.1575921, lng: 18.2470198 },
    { lat: 49.1572945, lng: 18.2463888 },
    { lat: 49.1570211, lng: 18.2460813 },
    { lat: 49.1567308, lng: 18.2454984 },
    { lat: 49.1566222, lng: 18.2452121 },
    { lat: 49.1560784, lng: 18.2450351 },
    { lat: 49.1560757, lng: 18.2444298 },
    { lat: 49.1562114, lng: 18.2442752 },
    { lat: 49.1560683, lng: 18.2436012 },
    { lat: 49.155839, lng: 18.2434371 },
    { lat: 49.1555588, lng: 18.2433905 },
    { lat: 49.1554874, lng: 18.2434843 },
    { lat: 49.1549709, lng: 18.2436678 },
    { lat: 49.1546648, lng: 18.2439997 },
    { lat: 49.1546297, lng: 18.2445739 },
    { lat: 49.1544857, lng: 18.2446624 },
    { lat: 49.1542493, lng: 18.2448917 },
    { lat: 49.1537201, lng: 18.2448514 },
    { lat: 49.1534299, lng: 18.2451239 },
    { lat: 49.153161, lng: 18.2447992 },
    { lat: 49.1529718, lng: 18.2448645 },
    { lat: 49.1526657, lng: 18.24512 },
    { lat: 49.1525292, lng: 18.2454191 },
    { lat: 49.1525099, lng: 18.245456 },
    { lat: 49.1524244, lng: 18.2456415 },
    { lat: 49.1526506, lng: 18.2460077 },
    { lat: 49.1525527, lng: 18.2463708 },
    { lat: 49.1525841, lng: 18.2464865 },
    { lat: 49.1529171, lng: 18.2466087 },
    { lat: 49.1527351, lng: 18.2470033 },
    { lat: 49.1528358, lng: 18.2478643 },
    { lat: 49.1527203, lng: 18.2480756 },
    { lat: 49.1523202, lng: 18.2486826 },
    { lat: 49.1517448, lng: 18.2496402 },
    { lat: 49.1514174, lng: 18.2508523 },
    { lat: 49.1513305, lng: 18.2507717 },
    { lat: 49.1512467, lng: 18.2508267 },
    { lat: 49.1512205, lng: 18.2508413 },
    { lat: 49.1511424, lng: 18.250877 },
    { lat: 49.1508723, lng: 18.2505504 },
    { lat: 49.1509357, lng: 18.2499155 },
    { lat: 49.1508981, lng: 18.2499084 },
    { lat: 49.1508748, lng: 18.2498956 },
    { lat: 49.1506595, lng: 18.2498603 },
    { lat: 49.1500709, lng: 18.250264 },
    { lat: 49.149797, lng: 18.2504478 },
    { lat: 49.1497123, lng: 18.2505081 },
    { lat: 49.149451, lng: 18.2504556 },
    { lat: 49.1488719, lng: 18.2504905 },
    { lat: 49.1484952, lng: 18.2507523 },
    { lat: 49.1480828, lng: 18.2508406 },
    { lat: 49.1471311, lng: 18.2512688 },
    { lat: 49.146657, lng: 18.2512855 },
    { lat: 49.1457283, lng: 18.2514404 },
    { lat: 49.1454001, lng: 18.2513657 },
    { lat: 49.1452682, lng: 18.2510459 },
    { lat: 49.1449625, lng: 18.2508884 },
    { lat: 49.1439373, lng: 18.2508542 },
    { lat: 49.1437536, lng: 18.25096 },
    { lat: 49.142931, lng: 18.2517142 },
    { lat: 49.1423168, lng: 18.251526 },
    { lat: 49.1422905, lng: 18.2515208 },
    { lat: 49.141989, lng: 18.2514162 },
    { lat: 49.1411855, lng: 18.2510332 },
    { lat: 49.1405057, lng: 18.250844 },
    { lat: 49.1394153, lng: 18.2506976 },
    { lat: 49.1389452, lng: 18.2507418 },
    { lat: 49.1384962, lng: 18.2507826 },
    { lat: 49.1379339, lng: 18.2511532 },
    { lat: 49.1360979, lng: 18.2505302 },
    { lat: 49.1359219, lng: 18.2504706 },
    { lat: 49.1358689, lng: 18.2504525 },
    { lat: 49.134491, lng: 18.2511424 },
    { lat: 49.134172, lng: 18.2521251 },
    { lat: 49.1338771, lng: 18.2524962 },
    { lat: 49.1338454, lng: 18.2525355 },
    { lat: 49.1326491, lng: 18.2540447 },
    { lat: 49.132279, lng: 18.2542837 },
    { lat: 49.132213, lng: 18.2543129 },
    { lat: 49.1321697, lng: 18.254326 },
    { lat: 49.1319664, lng: 18.2543799 },
    { lat: 49.1312558, lng: 18.254905 },
    { lat: 49.1298566, lng: 18.255878 },
    { lat: 49.1294237, lng: 18.2562434 },
    { lat: 49.1285202, lng: 18.2569664 },
    { lat: 49.128449, lng: 18.257024 },
    { lat: 49.1284232, lng: 18.257044 },
    { lat: 49.1282907, lng: 18.2571556 },
    { lat: 49.1273815, lng: 18.2577373 },
    { lat: 49.1263241, lng: 18.2585732 },
    { lat: 49.1251012, lng: 18.2592114 },
    { lat: 49.1249129, lng: 18.2599339 },
    { lat: 49.1232792, lng: 18.2614312 },
    { lat: 49.1229241, lng: 18.2617558 },
    { lat: 49.1218498, lng: 18.2621498 },
    { lat: 49.1219237, lng: 18.2637784 },
    { lat: 49.1220145, lng: 18.2642275 },
    { lat: 49.1220239, lng: 18.2642735 },
    { lat: 49.1220804, lng: 18.264555 },
    { lat: 49.122596, lng: 18.2650144 },
    { lat: 49.1231083, lng: 18.2652139 },
    { lat: 49.1232309, lng: 18.2656483 },
    { lat: 49.1229648, lng: 18.2660317 },
    { lat: 49.1229517, lng: 18.2663974 },
    { lat: 49.1231881, lng: 18.2666679 },
    { lat: 49.1235015, lng: 18.2671638 },
    { lat: 49.1233683, lng: 18.2676011 },
    { lat: 49.1235543, lng: 18.2678191 },
    { lat: 49.1235524, lng: 18.2679062 },
    { lat: 49.1234868, lng: 18.2679427 },
    { lat: 49.1235152, lng: 18.2685243 },
    { lat: 49.1236694, lng: 18.2692588 },
    { lat: 49.1268713, lng: 18.2715553 },
    { lat: 49.1270515, lng: 18.2716113 },
    { lat: 49.1270606, lng: 18.271614 },
    { lat: 49.1271658, lng: 18.2716377 },
    { lat: 49.1276126, lng: 18.2715799 },
    { lat: 49.1276486, lng: 18.2715752 },
    { lat: 49.1276811, lng: 18.2715718 },
    { lat: 49.1278021, lng: 18.2715985 },
    { lat: 49.1280209, lng: 18.2716752 },
    { lat: 49.1281245, lng: 18.2717114 },
    { lat: 49.1283576, lng: 18.2718438 },
    { lat: 49.1286523, lng: 18.2720102 },
    { lat: 49.1287264, lng: 18.272043 },
    { lat: 49.1291107, lng: 18.2722137 },
    { lat: 49.1291778, lng: 18.2722431 },
    { lat: 49.1294932, lng: 18.272356 },
    { lat: 49.1295382, lng: 18.2723721 },
    { lat: 49.129545, lng: 18.2723746 },
    { lat: 49.1298551, lng: 18.2723298 },
    { lat: 49.1299055, lng: 18.2723254 },
    { lat: 49.1300196, lng: 18.272389 },
    { lat: 49.1303579, lng: 18.2729825 },
    { lat: 49.1308385, lng: 18.2736021 },
    { lat: 49.1308623, lng: 18.2736647 },
    { lat: 49.1309979, lng: 18.2740114 },
    { lat: 49.13106, lng: 18.2741701 },
    { lat: 49.1312998, lng: 18.2748225 },
    { lat: 49.131502, lng: 18.2751262 },
    { lat: 49.1318357, lng: 18.2753421 },
    { lat: 49.1323697, lng: 18.2758086 },
    { lat: 49.1324657, lng: 18.2758919 },
    { lat: 49.1324364, lng: 18.2762517 },
    { lat: 49.1322968, lng: 18.2767149 },
    { lat: 49.1319521, lng: 18.2770461 },
    { lat: 49.132069, lng: 18.2771388 },
    { lat: 49.1322382, lng: 18.2774381 },
    { lat: 49.1325037, lng: 18.2775056 },
    { lat: 49.1326543, lng: 18.2776738 },
    { lat: 49.1327867, lng: 18.277822 },
    { lat: 49.1330849, lng: 18.2778666 },
    { lat: 49.1332109, lng: 18.278081 },
    { lat: 49.1330795, lng: 18.2788741 },
    { lat: 49.1335008, lng: 18.2795221 },
    { lat: 49.133527, lng: 18.2795266 },
    { lat: 49.1338036, lng: 18.2800326 },
    { lat: 49.1336256, lng: 18.2809183 },
    { lat: 49.1338766, lng: 18.2810474 },
    { lat: 49.134037, lng: 18.2812505 },
    { lat: 49.1344948, lng: 18.2815455 },
    { lat: 49.134662, lng: 18.2817902 },
    { lat: 49.1349014, lng: 18.2822142 },
    { lat: 49.135302, lng: 18.2826573 },
    { lat: 49.1356088, lng: 18.2829779 },
    { lat: 49.1358846, lng: 18.2834064 },
    { lat: 49.1361947, lng: 18.2839252 },
    { lat: 49.1363386, lng: 18.2841568 },
    { lat: 49.136492, lng: 18.2845173 },
    { lat: 49.1365122, lng: 18.2845555 },
    { lat: 49.1366381, lng: 18.2847868 },
    { lat: 49.1367619, lng: 18.2848016 },
    { lat: 49.1370583, lng: 18.2848367 },
    { lat: 49.1371734, lng: 18.2849582 },
    { lat: 49.1372553, lng: 18.2850284 },
    { lat: 49.1376072, lng: 18.2853291 },
    { lat: 49.1377555, lng: 18.2855575 },
    { lat: 49.1378484, lng: 18.2857237 },
    { lat: 49.1378396, lng: 18.2858684 },
    { lat: 49.1378056, lng: 18.2860264 },
    { lat: 49.1378206, lng: 18.2862143 },
    { lat: 49.1378741, lng: 18.286301 },
    { lat: 49.1378803, lng: 18.2863115 },
    { lat: 49.1380814, lng: 18.2864151 },
    { lat: 49.1381391, lng: 18.2864784 },
    { lat: 49.1381011, lng: 18.2867662 },
    { lat: 49.1380994, lng: 18.2867772 },
    { lat: 49.138097, lng: 18.2868154 },
    { lat: 49.1380697, lng: 18.2874108 },
    { lat: 49.1380725, lng: 18.2874801 },
    { lat: 49.1380803, lng: 18.2876184 },
    { lat: 49.1380813, lng: 18.2876363 },
    { lat: 49.1380837, lng: 18.2876788 },
    { lat: 49.1381172, lng: 18.2878942 },
    { lat: 49.1382466, lng: 18.2884693 },
    { lat: 49.1383686, lng: 18.289074 },
    { lat: 49.1384031, lng: 18.2892475 },
    { lat: 49.1386041, lng: 18.2899731 },
    { lat: 49.1386609, lng: 18.2901579 },
    { lat: 49.138674, lng: 18.2902129 },
    { lat: 49.138686, lng: 18.290265 },
    { lat: 49.1386907, lng: 18.2902871 },
    { lat: 49.1386986, lng: 18.2903288 },
    { lat: 49.1389408, lng: 18.2915862 },
    { lat: 49.1389284, lng: 18.2916869 },
    { lat: 49.1389128, lng: 18.29181 },
    { lat: 49.1388674, lng: 18.2921849 },
    { lat: 49.1388513, lng: 18.2922818 },
    { lat: 49.1388362, lng: 18.2923746 },
    { lat: 49.1387956, lng: 18.2926757 },
    { lat: 49.1387553, lng: 18.2929415 },
    { lat: 49.1387496, lng: 18.2929797 },
    { lat: 49.1387469, lng: 18.2929934 },
    { lat: 49.1387455, lng: 18.293008 },
    { lat: 49.1386902, lng: 18.293409 },
    { lat: 49.1386854, lng: 18.2934457 },
    { lat: 49.138684, lng: 18.2934592 },
    { lat: 49.1386791, lng: 18.2934933 },
    { lat: 49.1386756, lng: 18.2936509 },
    { lat: 49.1386638, lng: 18.2941028 },
    { lat: 49.1386614, lng: 18.2941612 },
    { lat: 49.1386607, lng: 18.2942111 },
    { lat: 49.1386814, lng: 18.2944565 },
    { lat: 49.1385944, lng: 18.2945345 },
    { lat: 49.1385858, lng: 18.2945429 },
    { lat: 49.1385976, lng: 18.2946123 },
    { lat: 49.1387129, lng: 18.2948152 },
    { lat: 49.138771, lng: 18.2949684 },
    { lat: 49.1387995, lng: 18.2950735 },
    { lat: 49.1388037, lng: 18.295087 },
    { lat: 49.1388333, lng: 18.2951923 },
    { lat: 49.1388111, lng: 18.2952788 },
    { lat: 49.1388017, lng: 18.295313 },
    { lat: 49.1388125, lng: 18.2953438 },
    { lat: 49.138834, lng: 18.2954048 },
    { lat: 49.1388514, lng: 18.2954531 },
    { lat: 49.1389842, lng: 18.2953481 },
    { lat: 49.1390521, lng: 18.29523 },
    { lat: 49.1390832, lng: 18.2952033 },
    { lat: 49.1392415, lng: 18.2950682 },
    { lat: 49.1394766, lng: 18.2948341 },
    { lat: 49.1397204, lng: 18.2947752 },
    { lat: 49.1398119, lng: 18.2947779 },
    { lat: 49.1400705, lng: 18.2947623 },
    { lat: 49.1400974, lng: 18.2947604 },
    { lat: 49.1400824, lng: 18.2954962 },
    { lat: 49.1404213, lng: 18.2957654 },
    { lat: 49.1405979, lng: 18.2958377 },
    { lat: 49.1407938, lng: 18.2958905 },
    { lat: 49.140782, lng: 18.2959418 },
    { lat: 49.1407722, lng: 18.2959877 },
    { lat: 49.1407493, lng: 18.2961413 },
    { lat: 49.1407813, lng: 18.296171 },
    { lat: 49.1409444, lng: 18.2963216 },
    { lat: 49.1411413, lng: 18.2963128 },
    { lat: 49.1412185, lng: 18.2962206 },
    { lat: 49.1412373, lng: 18.2962545 },
    { lat: 49.1412889, lng: 18.296348 },
    { lat: 49.1415033, lng: 18.2964112 },
    { lat: 49.1418325, lng: 18.2964246 },
    { lat: 49.14202, lng: 18.29645 },
    { lat: 49.1421633, lng: 18.2964695 },
    { lat: 49.1423804, lng: 18.2964983 },
    { lat: 49.142426, lng: 18.2965276 },
    { lat: 49.1432956, lng: 18.2970853 },
    { lat: 49.1436584, lng: 18.2973181 },
    { lat: 49.1437204, lng: 18.2973581 },
    { lat: 49.1439357, lng: 18.2974961 },
    { lat: 49.1436885, lng: 18.2979745 },
    { lat: 49.1437391, lng: 18.298011 },
    { lat: 49.1439059, lng: 18.298189 },
    { lat: 49.1441023, lng: 18.2985133 },
    { lat: 49.1445344, lng: 18.2986135 },
    { lat: 49.1452031, lng: 18.2992904 },
    { lat: 49.1454244, lng: 18.2989732 },
    { lat: 49.1454629, lng: 18.298934 },
    { lat: 49.1457174, lng: 18.2985649 },
    { lat: 49.1457694, lng: 18.2985664 },
    { lat: 49.146138, lng: 18.2985999 },
    { lat: 49.1462371, lng: 18.298538 },
    { lat: 49.146608, lng: 18.2981301 },
    { lat: 49.1467418, lng: 18.2978413 },
    { lat: 49.1468478, lng: 18.2978993 },
    { lat: 49.1472827, lng: 18.298313 },
    { lat: 49.1474246, lng: 18.2984475 },
    { lat: 49.1474616, lng: 18.2984836 },
    { lat: 49.1475644, lng: 18.2985282 },
    { lat: 49.1477303, lng: 18.2986883 },
    { lat: 49.1478596, lng: 18.298721 },
    { lat: 49.1481324, lng: 18.2987175 },
    { lat: 49.1483615, lng: 18.2986373 },
    { lat: 49.1485018, lng: 18.2982037 },
    { lat: 49.1491102, lng: 18.2985908 },
    { lat: 49.1490765, lng: 18.2987748 },
    { lat: 49.1495033, lng: 18.2990413 },
    { lat: 49.149739, lng: 18.2992405 },
    { lat: 49.1502259, lng: 18.2992725 },
    { lat: 49.1505203, lng: 18.2993706 },
    { lat: 49.1505469, lng: 18.2993827 },
    { lat: 49.1509475, lng: 18.299907 },
    { lat: 49.1512964, lng: 18.3001733 },
    { lat: 49.1515839, lng: 18.3003109 },
    { lat: 49.1517866, lng: 18.3002845 },
    { lat: 49.1518495, lng: 18.2997576 },
    { lat: 49.1518539, lng: 18.2995356 },
    { lat: 49.1521971, lng: 18.2987943 },
    { lat: 49.152283, lng: 18.2985967 },
    { lat: 49.152317, lng: 18.2985191 },
    { lat: 49.152377, lng: 18.2983816 },
    { lat: 49.1526405, lng: 18.2986752 },
    { lat: 49.152964, lng: 18.2990523 },
    { lat: 49.1526218, lng: 18.2998886 },
    { lat: 49.1526554, lng: 18.3002457 },
    { lat: 49.1529075, lng: 18.3004972 },
    { lat: 49.153097, lng: 18.3006156 },
    { lat: 49.1531298, lng: 18.3006587 },
    { lat: 49.1533064, lng: 18.300989 },
    { lat: 49.1537008, lng: 18.3007397 },
    { lat: 49.1544669, lng: 18.2993959 },
    { lat: 49.154758, lng: 18.2998382 },
    { lat: 49.1547984, lng: 18.3000771 },
    { lat: 49.1546952, lng: 18.3004085 },
    { lat: 49.154526, lng: 18.3008501 },
    { lat: 49.1542054, lng: 18.3014906 },
    { lat: 49.1549322, lng: 18.3018153 },
    { lat: 49.1552375, lng: 18.3019506 },
    { lat: 49.1552619, lng: 18.3015991 },
    { lat: 49.155277, lng: 18.3011662 },
    { lat: 49.1555803, lng: 18.3005599 },
    { lat: 49.1557826, lng: 18.3005873 },
    { lat: 49.1563168, lng: 18.3003951 },
    { lat: 49.1565259, lng: 18.30026 },
    { lat: 49.156835, lng: 18.30006 },
    { lat: 49.1571733, lng: 18.2998966 },
    { lat: 49.1573293, lng: 18.2998216 },
    { lat: 49.1573636, lng: 18.3000314 },
    { lat: 49.1576138, lng: 18.3003484 },
    { lat: 49.1578128, lng: 18.3002948 },
    { lat: 49.1578195, lng: 18.3002931 },
    { lat: 49.1581145, lng: 18.3002232 },
    { lat: 49.1587312, lng: 18.2994117 },
    { lat: 49.158919, lng: 18.2992611 },
    { lat: 49.1592491, lng: 18.2989101 },
    { lat: 49.1593257, lng: 18.2988288 },
    { lat: 49.1595484, lng: 18.299016 },
    { lat: 49.1597204, lng: 18.2989259 },
    { lat: 49.1597906, lng: 18.2988892 },
    { lat: 49.159869, lng: 18.2991814 },
    { lat: 49.1600163, lng: 18.3000567 },
    { lat: 49.1600346, lng: 18.3008042 },
    { lat: 49.1600785, lng: 18.3008245 },
    { lat: 49.1602062, lng: 18.3008091 },
    { lat: 49.1603309, lng: 18.3002555 },
    { lat: 49.1604733, lng: 18.3002993 },
    { lat: 49.160471, lng: 18.3004996 },
    { lat: 49.1604747, lng: 18.3007263 },
    { lat: 49.1603453, lng: 18.3011148 },
    { lat: 49.1602441, lng: 18.3016844 },
    { lat: 49.160289, lng: 18.3017 },
    { lat: 49.1603888, lng: 18.301749 },
    { lat: 49.1604217, lng: 18.3018315 },
    { lat: 49.1603732, lng: 18.3018516 },
    { lat: 49.1603887, lng: 18.3018878 },
    { lat: 49.1604229, lng: 18.3018739 },
    { lat: 49.1605146, lng: 18.3019402 },
    { lat: 49.1602784, lng: 18.3027374 },
    { lat: 49.1605505, lng: 18.3026009 },
    { lat: 49.1606949, lng: 18.3028606 },
    { lat: 49.1607322, lng: 18.3030821 },
    { lat: 49.1607838, lng: 18.3032181 },
    { lat: 49.1608277, lng: 18.3033982 },
    { lat: 49.1609691, lng: 18.303888 },
    { lat: 49.1611253, lng: 18.3048233 },
    { lat: 49.1612394, lng: 18.3051674 },
    { lat: 49.1611389, lng: 18.305326 },
    { lat: 49.1610898, lng: 18.3054959 },
    { lat: 49.1607615, lng: 18.3059374 },
    { lat: 49.1606507, lng: 18.3062979 },
    { lat: 49.1605599, lng: 18.3072916 },
    { lat: 49.1606378, lng: 18.3073532 },
    { lat: 49.1608943, lng: 18.307783 },
    { lat: 49.1611647, lng: 18.3079975 },
    { lat: 49.1616772, lng: 18.3091708 },
    { lat: 49.1617264, lng: 18.3092455 },
    { lat: 49.1620846, lng: 18.3098787 },
    { lat: 49.1624742, lng: 18.3098861 },
    { lat: 49.1627043, lng: 18.3100045 },
    { lat: 49.1629327, lng: 18.309934 },
    { lat: 49.1630427, lng: 18.309301 },
    { lat: 49.1632678, lng: 18.3084054 },
    { lat: 49.1635804, lng: 18.3079842 },
    { lat: 49.1636893, lng: 18.3078732 },
    { lat: 49.1638751, lng: 18.3077462 },
    { lat: 49.1643085, lng: 18.3078119 },
    { lat: 49.164709, lng: 18.3080732 },
    { lat: 49.1647786, lng: 18.3082271 },
    { lat: 49.1646869, lng: 18.3085242 },
    { lat: 49.1646207, lng: 18.3090323 },
    { lat: 49.1646075, lng: 18.3091194 },
    { lat: 49.1645617, lng: 18.3098709 },
    { lat: 49.1650787, lng: 18.3132802 },
    { lat: 49.1655063, lng: 18.3132418 },
    { lat: 49.1656851, lng: 18.3132304 },
    { lat: 49.1669913, lng: 18.3156459 },
    { lat: 49.1670347, lng: 18.3162591 },
    { lat: 49.1679053, lng: 18.3182276 },
    { lat: 49.1687088, lng: 18.3186769 },
    { lat: 49.1689291, lng: 18.318742 },
    { lat: 49.1704551, lng: 18.3192603 },
    { lat: 49.1720203, lng: 18.3194683 },
    { lat: 49.1724148, lng: 18.3195637 },
    { lat: 49.1727644, lng: 18.3196232 },
    { lat: 49.1732812, lng: 18.3196648 },
    { lat: 49.1734466, lng: 18.3196555 },
    { lat: 49.1735091, lng: 18.3196449 },
    { lat: 49.1738009, lng: 18.3196168 },
    { lat: 49.174837, lng: 18.3195651 },
    { lat: 49.1753792, lng: 18.3196178 },
    { lat: 49.1754285, lng: 18.319611 },
    { lat: 49.1754762, lng: 18.3196578 },
    { lat: 49.1756943, lng: 18.3198862 },
    { lat: 49.1765499, lng: 18.3200561 },
    { lat: 49.1766881, lng: 18.3200258 },
    { lat: 49.1767565, lng: 18.3198377 },
    { lat: 49.176801, lng: 18.3198449 },
    { lat: 49.1790978, lng: 18.3198427 },
    { lat: 49.1798413, lng: 18.3202692 },
    { lat: 49.1809515, lng: 18.3206325 },
    { lat: 49.1813048, lng: 18.3205946 },
    { lat: 49.1817249, lng: 18.320805 },
    { lat: 49.1822923, lng: 18.3208451 },
    { lat: 49.1831668, lng: 18.3213936 },
    { lat: 49.183429, lng: 18.3213003 },
    { lat: 49.1834632, lng: 18.3212865 },
    { lat: 49.183994, lng: 18.3210555 },
    { lat: 49.1844625, lng: 18.3208592 },
    { lat: 49.1851903, lng: 18.3202975 },
    { lat: 49.1857848, lng: 18.3190051 },
    { lat: 49.1859558, lng: 18.3190172 },
    { lat: 49.1865295, lng: 18.3188464 },
    { lat: 49.1867879, lng: 18.3188478 },
    { lat: 49.1871114, lng: 18.3189025 },
    { lat: 49.1873679, lng: 18.3189501 },
    { lat: 49.1877645, lng: 18.3189208 },
    { lat: 49.1888198, lng: 18.317316 },
    { lat: 49.188832, lng: 18.3172937 },
    { lat: 49.1891165, lng: 18.3168716 },
    { lat: 49.1893185, lng: 18.3168341 },
    { lat: 49.1898857, lng: 18.3167085 },
    { lat: 49.190616, lng: 18.3166332 },
    { lat: 49.1916651, lng: 18.3171184 },
    { lat: 49.1924378, lng: 18.316775 },
    { lat: 49.1930738, lng: 18.3154955 },
    { lat: 49.1934243, lng: 18.3155527 },
    { lat: 49.1936936, lng: 18.3154862 },
    { lat: 49.1938723, lng: 18.3151897 },
    { lat: 49.194177, lng: 18.3150926 },
    { lat: 49.1945666, lng: 18.3139308 },
    { lat: 49.194752, lng: 18.3124029 },
    { lat: 49.1944734, lng: 18.3107342 },
    { lat: 49.1944774, lng: 18.3107252 },
    { lat: 49.1949302, lng: 18.3103506 },
    { lat: 49.1954488, lng: 18.3109669 },
    { lat: 49.1955274, lng: 18.3110607 },
    { lat: 49.1960385, lng: 18.3116641 },
    { lat: 49.1969504, lng: 18.3113926 },
    { lat: 49.1982659, lng: 18.311129 },
    { lat: 49.1993749, lng: 18.3109102 },
    { lat: 49.1998785, lng: 18.3091249 },
    { lat: 49.1998488, lng: 18.3084931 },
    { lat: 49.2001767, lng: 18.3061901 },
    { lat: 49.2002157, lng: 18.3059199 },
    { lat: 49.2011758, lng: 18.3054573 },
    { lat: 49.2012314, lng: 18.3054241 },
    { lat: 49.201325, lng: 18.3053649 },
    { lat: 49.2013936, lng: 18.3051154 },
    { lat: 49.2014826, lng: 18.305055 },
    { lat: 49.2018966, lng: 18.3047106 },
    { lat: 49.2018634, lng: 18.3041969 },
    { lat: 49.2021112, lng: 18.3035864 },
    { lat: 49.2032982, lng: 18.3021241 },
    { lat: 49.203741, lng: 18.3017105 },
    { lat: 49.2042161, lng: 18.3025194 },
    { lat: 49.204401, lng: 18.3029167 },
    { lat: 49.2048592, lng: 18.3022381 },
    { lat: 49.2053128, lng: 18.3018747 },
    { lat: 49.205672, lng: 18.3013404 },
    { lat: 49.2061454, lng: 18.3008274 },
    { lat: 49.2067654, lng: 18.3007213 },
    { lat: 49.2067886, lng: 18.3006912 },
    { lat: 49.2070016, lng: 18.3004466 },
    { lat: 49.2076627, lng: 18.3001634 },
    { lat: 49.2080781, lng: 18.2999861 },
    { lat: 49.2080964, lng: 18.2999922 },
    { lat: 49.2094642, lng: 18.3005016 },
    { lat: 49.2106219, lng: 18.3016154 },
    { lat: 49.2112955, lng: 18.3033252 },
    { lat: 49.2114649, lng: 18.3037534 },
    { lat: 49.2115086, lng: 18.3037477 },
    { lat: 49.2117038, lng: 18.3039066 },
    { lat: 49.2119444, lng: 18.3042943 },
    { lat: 49.2121023, lng: 18.3044382 },
    { lat: 49.2121514, lng: 18.3044865 },
    { lat: 49.2123905, lng: 18.3044848 },
    { lat: 49.2125433, lng: 18.3045528 },
    { lat: 49.2126148, lng: 18.3045801 },
    { lat: 49.2127975, lng: 18.3047016 },
    { lat: 49.2129823, lng: 18.3047594 },
    { lat: 49.2131111, lng: 18.3048429 },
    { lat: 49.2131678, lng: 18.3049694 },
    { lat: 49.213303, lng: 18.3051248 },
    { lat: 49.2133703, lng: 18.305179 },
    { lat: 49.2134659, lng: 18.3052556 },
    { lat: 49.2138307, lng: 18.3053835 },
    { lat: 49.2141277, lng: 18.3055492 },
    { lat: 49.2143186, lng: 18.3056565 },
    { lat: 49.2146851, lng: 18.3059156 },
    { lat: 49.2149392, lng: 18.3060585 },
    { lat: 49.2150529, lng: 18.3061346 },
    { lat: 49.2153608, lng: 18.3062545 },
    { lat: 49.2155244, lng: 18.3063185 },
    { lat: 49.2156626, lng: 18.3063885 },
    { lat: 49.2156769, lng: 18.3063989 },
    { lat: 49.2158095, lng: 18.3064909 },
    { lat: 49.2159277, lng: 18.3065877 },
    { lat: 49.215976, lng: 18.306681 },
    { lat: 49.216078, lng: 18.306501 },
    { lat: 49.21615, lng: 18.306146 },
    { lat: 49.216149, lng: 18.305851 },
    { lat: 49.216082, lng: 18.305452 },
    { lat: 49.216148, lng: 18.305134 },
    { lat: 49.216349, lng: 18.304902 },
    { lat: 49.216417, lng: 18.304701 },
    { lat: 49.216423, lng: 18.304293 },
    { lat: 49.216489, lng: 18.304034 },
    { lat: 49.216588, lng: 18.303752 },
    { lat: 49.216621, lng: 18.303598 },
    { lat: 49.216621, lng: 18.303243 },
    { lat: 49.216665, lng: 18.303063 },
    { lat: 49.216736, lng: 18.302848 },
    { lat: 49.216788, lng: 18.302691 },
    { lat: 49.216866, lng: 18.302446 },
    { lat: 49.216911, lng: 18.3023 },
    { lat: 49.216909, lng: 18.302257 },
    { lat: 49.216895, lng: 18.301918 },
    { lat: 49.216948, lng: 18.301741 },
    { lat: 49.217005, lng: 18.301647 },
    { lat: 49.217068, lng: 18.301545 },
    { lat: 49.217107, lng: 18.301504 },
    { lat: 49.217111, lng: 18.3012 },
    { lat: 49.217094, lng: 18.3011 },
    { lat: 49.217059, lng: 18.300892 },
    { lat: 49.217039, lng: 18.300761 },
    { lat: 49.217091, lng: 18.300646 },
    { lat: 49.217051, lng: 18.300365 },
    { lat: 49.216939, lng: 18.300169 },
    { lat: 49.216891, lng: 18.300087 },
    { lat: 49.216888, lng: 18.299942 },
    { lat: 49.216886, lng: 18.299845 },
    { lat: 49.216886, lng: 18.29984 },
    { lat: 49.216806, lng: 18.299464 },
    { lat: 49.216764, lng: 18.299376 },
    { lat: 49.216776, lng: 18.29931 },
    { lat: 49.216789, lng: 18.299131 },
    { lat: 49.216671, lng: 18.298975 },
    { lat: 49.21666, lng: 18.298882 },
    { lat: 49.216644, lng: 18.298728 },
    { lat: 49.216633, lng: 18.298633 },
    { lat: 49.216626, lng: 18.298563 },
    { lat: 49.21621, lng: 18.297287 },
    { lat: 49.21591, lng: 18.296199 },
    { lat: 49.215811, lng: 18.295927 },
    { lat: 49.215519, lng: 18.295124 },
    { lat: 49.21542, lng: 18.29478 },
    { lat: 49.215396, lng: 18.294673 },
    { lat: 49.214959, lng: 18.293825 },
    { lat: 49.214699, lng: 18.293339 },
    { lat: 49.21464, lng: 18.293232 },
    { lat: 49.214528, lng: 18.293076 },
    { lat: 49.214459, lng: 18.292866 },
    { lat: 49.214424, lng: 18.292776 },
    { lat: 49.21405, lng: 18.291887 },
    { lat: 49.213199, lng: 18.290282 },
    { lat: 49.213139, lng: 18.290165 },
    { lat: 49.21293, lng: 18.289773 },
    { lat: 49.212945, lng: 18.289748 },
    { lat: 49.212984, lng: 18.28969 },
    { lat: 49.213103, lng: 18.289518 },
    { lat: 49.213584, lng: 18.288764 },
    { lat: 49.214226, lng: 18.287761 },
    { lat: 49.214255, lng: 18.28771 },
    { lat: 49.214262, lng: 18.287697 },
    { lat: 49.214863, lng: 18.287444 },
    { lat: 49.215181, lng: 18.287309 },
    { lat: 49.215786, lng: 18.286699 },
    { lat: 49.216248, lng: 18.286178 },
    { lat: 49.216278, lng: 18.286145 },
    { lat: 49.216426, lng: 18.285991 },
    { lat: 49.216542, lng: 18.285865 },
    { lat: 49.216885, lng: 18.285522 },
    { lat: 49.217571, lng: 18.284835 },
    { lat: 49.217726, lng: 18.28468 },
    { lat: 49.217889, lng: 18.2845 },
    { lat: 49.218174, lng: 18.28413 },
    { lat: 49.218194, lng: 18.284104 },
    { lat: 49.218394, lng: 18.283844 },
    { lat: 49.218441, lng: 18.283776 },
    { lat: 49.218922, lng: 18.283084 },
    { lat: 49.21904, lng: 18.283455 },
  ],
  LazypodMakytou: [
    { lat: 49.2076537, lng: 18.2394106 },
    { lat: 49.2076531, lng: 18.2394219 },
    { lat: 49.2076456, lng: 18.2396064 },
    { lat: 49.2075235, lng: 18.2397805 },
    { lat: 49.2074255, lng: 18.2400612 },
    { lat: 49.2074313, lng: 18.2402262 },
    { lat: 49.2074421, lng: 18.2405759 },
    { lat: 49.2074619, lng: 18.240609 },
    { lat: 49.2076969, lng: 18.2409932 },
    { lat: 49.2077935, lng: 18.2410618 },
    { lat: 49.2079012, lng: 18.2411315 },
    { lat: 49.2080339, lng: 18.2411835 },
    { lat: 49.2081746, lng: 18.2412385 },
    { lat: 49.2083608, lng: 18.2414374 },
    { lat: 49.2085111, lng: 18.24174 },
    { lat: 49.208605, lng: 18.2420258 },
    { lat: 49.20865, lng: 18.2420845 },
    { lat: 49.2087061, lng: 18.2421581 },
    { lat: 49.2087776, lng: 18.2422643 },
    { lat: 49.209294, lng: 18.2424186 },
    { lat: 49.2096056, lng: 18.2424235 },
    { lat: 49.2095773, lng: 18.2427388 },
    { lat: 49.2103545, lng: 18.243036 },
    { lat: 49.2106629, lng: 18.2431628 },
    { lat: 49.2108267, lng: 18.2430083 },
    { lat: 49.2109573, lng: 18.2426634 },
    { lat: 49.2109132, lng: 18.2423816 },
    { lat: 49.2109986, lng: 18.2424523 },
    { lat: 49.2110827, lng: 18.2426279 },
    { lat: 49.2112604, lng: 18.242993 },
    { lat: 49.2112899, lng: 18.2430548 },
    { lat: 49.2113631, lng: 18.2432532 },
    { lat: 49.2114535, lng: 18.2438361 },
    { lat: 49.2115839, lng: 18.243488 },
    { lat: 49.211804, lng: 18.2425729 },
    { lat: 49.2116169, lng: 18.2423751 },
    { lat: 49.211572, lng: 18.2420984 },
    { lat: 49.2115446, lng: 18.2420362 },
    { lat: 49.2114669, lng: 18.2418591 },
    { lat: 49.2113866, lng: 18.2416761 },
    { lat: 49.2112973, lng: 18.2413727 },
    { lat: 49.2111479, lng: 18.2408671 },
    { lat: 49.211061, lng: 18.2404881 },
    { lat: 49.2114194, lng: 18.2405011 },
    { lat: 49.2115698, lng: 18.2405066 },
    { lat: 49.2119237, lng: 18.2404798 },
    { lat: 49.2123131, lng: 18.240484 },
    { lat: 49.2125904, lng: 18.2405586 },
    { lat: 49.2127773, lng: 18.2404107 },
    { lat: 49.2129481, lng: 18.239999 },
    { lat: 49.2129784, lng: 18.2397788 },
    { lat: 49.2130495, lng: 18.2395193 },
    { lat: 49.213066, lng: 18.239456 },
    { lat: 49.2133522, lng: 18.2397658 },
    { lat: 49.2135943, lng: 18.2399749 },
    { lat: 49.2138071, lng: 18.2401102 },
    { lat: 49.2140437, lng: 18.2401788 },
    { lat: 49.2142271, lng: 18.2403919 },
    { lat: 49.214366, lng: 18.2412707 },
    { lat: 49.2144514, lng: 18.2416434 },
    { lat: 49.2140602, lng: 18.241749 },
    { lat: 49.2141413, lng: 18.2429249 },
    { lat: 49.2139437, lng: 18.2430586 },
    { lat: 49.2131485, lng: 18.2432024 },
    { lat: 49.212955, lng: 18.2432139 },
    { lat: 49.2125845, lng: 18.2434474 },
    { lat: 49.2119297, lng: 18.2435577 },
    { lat: 49.2121046, lng: 18.2428373 },
    { lat: 49.2118292, lng: 18.2425997 },
    { lat: 49.2116112, lng: 18.2435106 },
    { lat: 49.2114702, lng: 18.2438729 },
    { lat: 49.2111881, lng: 18.244189 },
    { lat: 49.2109536, lng: 18.2443105 },
    { lat: 49.2109165, lng: 18.2449721 },
    { lat: 49.2109801, lng: 18.2449602 },
    { lat: 49.2112936, lng: 18.2446803 },
    { lat: 49.2120351, lng: 18.244522 },
    { lat: 49.212085, lng: 18.244506 },
    { lat: 49.2121171, lng: 18.2444957 },
    { lat: 49.212363, lng: 18.2444173 },
    { lat: 49.2128086, lng: 18.2443952 },
    { lat: 49.2133382, lng: 18.2441655 },
    { lat: 49.2139528, lng: 18.2437803 },
    { lat: 49.2140176, lng: 18.2437467 },
    { lat: 49.2140668, lng: 18.2437216 },
    { lat: 49.2141633, lng: 18.2436721 },
    { lat: 49.2146051, lng: 18.2434453 },
    { lat: 49.215186, lng: 18.243182 },
    { lat: 49.2157715, lng: 18.2425821 },
    { lat: 49.2164386, lng: 18.24131 },
    { lat: 49.2168119, lng: 18.2406707 },
    { lat: 49.2169251, lng: 18.2402405 },
    { lat: 49.2169078, lng: 18.239274 },
    { lat: 49.2164549, lng: 18.239388 },
    { lat: 49.2160629, lng: 18.239655 },
    { lat: 49.2159395, lng: 18.2391711 },
    { lat: 49.2157452, lng: 18.2391471 },
    { lat: 49.215393, lng: 18.2390861 },
    { lat: 49.2151813, lng: 18.2389915 },
    { lat: 49.2147452, lng: 18.2380239 },
    { lat: 49.2145909, lng: 18.2374918 },
    { lat: 49.2144798, lng: 18.2367453 },
    { lat: 49.2144779, lng: 18.2367322 },
    { lat: 49.2149953, lng: 18.2364615 },
    { lat: 49.2154368, lng: 18.2364682 },
    { lat: 49.2157066, lng: 18.2372883 },
    { lat: 49.2159542, lng: 18.2375184 },
    { lat: 49.2161392, lng: 18.2373582 },
    { lat: 49.2163537, lng: 18.237955 },
    { lat: 49.2164726, lng: 18.2381642 },
    { lat: 49.2163485, lng: 18.2383859 },
    { lat: 49.216246, lng: 18.2387641 },
    { lat: 49.2163573, lng: 18.2392179 },
    { lat: 49.2164555, lng: 18.2392988 },
    { lat: 49.2169069, lng: 18.2392207 },
    { lat: 49.2179034, lng: 18.2389243 },
    { lat: 49.2185942, lng: 18.2387105 },
    { lat: 49.2190458, lng: 18.2386354 },
    { lat: 49.2191834, lng: 18.2385563 },
    { lat: 49.2197736, lng: 18.2382193 },
    { lat: 49.2202479, lng: 18.2376893 },
    { lat: 49.220613, lng: 18.2372816 },
    { lat: 49.2207446, lng: 18.237135 },
    { lat: 49.2209787, lng: 18.2369475 },
    { lat: 49.2211719, lng: 18.2367932 },
    { lat: 49.2212114, lng: 18.2368147 },
    { lat: 49.2218402, lng: 18.2371598 },
    { lat: 49.2221421, lng: 18.2366716 },
    { lat: 49.2225728, lng: 18.2363275 },
    { lat: 49.2234057, lng: 18.2377473 },
    { lat: 49.2237912, lng: 18.2386974 },
    { lat: 49.2242754, lng: 18.2393406 },
    { lat: 49.2246434, lng: 18.2395448 },
    { lat: 49.2248745, lng: 18.2395594 },
    { lat: 49.2253885, lng: 18.2396515 },
    { lat: 49.2256383, lng: 18.239398 },
    { lat: 49.2258738, lng: 18.2394944 },
    { lat: 49.2263394, lng: 18.2396837 },
    { lat: 49.2269733, lng: 18.2400237 },
    { lat: 49.2276249, lng: 18.240433 },
    { lat: 49.2279436, lng: 18.2407184 },
    { lat: 49.2283145, lng: 18.2413142 },
    { lat: 49.2284432, lng: 18.2417549 },
    { lat: 49.2286888, lng: 18.242783 },
    { lat: 49.2287727, lng: 18.2430497 },
    { lat: 49.2289635, lng: 18.2431128 },
    { lat: 49.2292635, lng: 18.2432128 },
    { lat: 49.2295071, lng: 18.24329 },
    { lat: 49.2298116, lng: 18.243386 },
    { lat: 49.2306858, lng: 18.2429245 },
    { lat: 49.2307708, lng: 18.2431308 },
    { lat: 49.230862, lng: 18.2433479 },
    { lat: 49.2308893, lng: 18.2434123 },
    { lat: 49.2309872, lng: 18.2436471 },
    { lat: 49.2310173, lng: 18.2437189 },
    { lat: 49.2311057, lng: 18.2438493 },
    { lat: 49.2314133, lng: 18.2442968 },
    { lat: 49.2316168, lng: 18.244526 },
    { lat: 49.2316385, lng: 18.2445502 },
    { lat: 49.2316759, lng: 18.2445918 },
    { lat: 49.2317277, lng: 18.2446518 },
    { lat: 49.2319998, lng: 18.2451159 },
    { lat: 49.2321011, lng: 18.2452881 },
    { lat: 49.2321748, lng: 18.2454127 },
    { lat: 49.2322379, lng: 18.2454544 },
    { lat: 49.2323177, lng: 18.2455069 },
    { lat: 49.2323516, lng: 18.2455298 },
    { lat: 49.2323494, lng: 18.2456908 },
    { lat: 49.2323433, lng: 18.2459811 },
    { lat: 49.2323696, lng: 18.2466461 },
    { lat: 49.2324974, lng: 18.2470509 },
    { lat: 49.2325041, lng: 18.247069 },
    { lat: 49.2325166, lng: 18.2471103 },
    { lat: 49.2325234, lng: 18.2471311 },
    { lat: 49.2325345, lng: 18.2471679 },
    { lat: 49.2325477, lng: 18.2471835 },
    { lat: 49.2325537, lng: 18.2471895 },
    { lat: 49.2328473, lng: 18.2475321 },
    { lat: 49.2330304, lng: 18.2475802 },
    { lat: 49.2331886, lng: 18.2476218 },
    { lat: 49.2339536, lng: 18.2478224 },
    { lat: 49.2339822, lng: 18.2478894 },
    { lat: 49.2339977, lng: 18.247926 },
    { lat: 49.2340028, lng: 18.2479354 },
    { lat: 49.2340107, lng: 18.2479541 },
    { lat: 49.2340183, lng: 18.247972 },
    { lat: 49.2340225, lng: 18.247983 },
    { lat: 49.234112, lng: 18.2481915 },
    { lat: 49.2343018, lng: 18.2486342 },
    { lat: 49.2344526, lng: 18.2489878 },
    { lat: 49.234489, lng: 18.2490729 },
    { lat: 49.2345786, lng: 18.249281 },
    { lat: 49.2345718, lng: 18.2502366 },
    { lat: 49.2349335, lng: 18.2499888 },
    { lat: 49.23518, lng: 18.2498206 },
    { lat: 49.2352325, lng: 18.2498328 },
    { lat: 49.2352772, lng: 18.2498428 },
    { lat: 49.2353948, lng: 18.2498693 },
    { lat: 49.2354085, lng: 18.2498724 },
    { lat: 49.2354382, lng: 18.2498788 },
    { lat: 49.2354393, lng: 18.249879 },
    { lat: 49.2355463, lng: 18.2500727 },
    { lat: 49.235641, lng: 18.2513527 },
    { lat: 49.2357289, lng: 18.2519708 },
    { lat: 49.2357042, lng: 18.2521326 },
    { lat: 49.235808, lng: 18.2526301 },
    { lat: 49.2359902, lng: 18.2529397 },
    { lat: 49.236281, lng: 18.253634 },
    { lat: 49.2362748, lng: 18.2538424 },
    { lat: 49.2364146, lng: 18.2543641 },
    { lat: 49.2364814, lng: 18.2547048 },
    { lat: 49.2364464, lng: 18.2550449 },
    { lat: 49.2365758, lng: 18.2552807 },
    { lat: 49.2365075, lng: 18.2554905 },
    { lat: 49.2365905, lng: 18.2561195 },
    { lat: 49.2367238, lng: 18.2562438 },
    { lat: 49.236894, lng: 18.2563774 },
    { lat: 49.2371737, lng: 18.2566006 },
    { lat: 49.237293, lng: 18.2568132 },
    { lat: 49.2374226, lng: 18.256931 },
    { lat: 49.2375262, lng: 18.2571672 },
    { lat: 49.2375827, lng: 18.2572497 },
    { lat: 49.2377746, lng: 18.2576093 },
    { lat: 49.2378563, lng: 18.2577199 },
    { lat: 49.2379727, lng: 18.2579404 },
    { lat: 49.2383619, lng: 18.2584029 },
    { lat: 49.2387063, lng: 18.2591678 },
    { lat: 49.2387447, lng: 18.2593458 },
    { lat: 49.2387647, lng: 18.2594613 },
    { lat: 49.2388182, lng: 18.2598527 },
    { lat: 49.2388301, lng: 18.2602022 },
    { lat: 49.2388467, lng: 18.260338 },
    { lat: 49.2387914, lng: 18.2604964 },
    { lat: 49.2386343, lng: 18.2606706 },
    { lat: 49.2385983, lng: 18.2608332 },
    { lat: 49.2386287, lng: 18.2611707 },
    { lat: 49.2385463, lng: 18.2612104 },
    { lat: 49.2384679, lng: 18.2614219 },
    { lat: 49.2384741, lng: 18.2615331 },
    { lat: 49.2386013, lng: 18.2619264 },
    { lat: 49.2386741, lng: 18.2622198 },
    { lat: 49.2387326, lng: 18.2625944 },
    { lat: 49.2387138, lng: 18.2628411 },
    { lat: 49.2384616, lng: 18.2635128 },
    { lat: 49.2383987, lng: 18.2638186 },
    { lat: 49.2383923, lng: 18.2638642 },
    { lat: 49.2383688, lng: 18.2640471 },
    { lat: 49.2382891, lng: 18.2646764 },
    { lat: 49.2382318, lng: 18.2651187 },
    { lat: 49.2382617, lng: 18.2658724 },
    { lat: 49.2382881, lng: 18.2665594 },
    { lat: 49.2382962, lng: 18.2667617 },
    { lat: 49.2382999, lng: 18.2668485 },
    { lat: 49.2383072, lng: 18.2670574 },
    { lat: 49.2383203, lng: 18.2673907 },
    { lat: 49.2383242, lng: 18.2675023 },
    { lat: 49.2383395, lng: 18.2678915 },
    { lat: 49.2383442, lng: 18.2680165 },
    { lat: 49.2382723, lng: 18.2684227 },
    { lat: 49.23826, lng: 18.268497 },
    { lat: 49.238812, lng: 18.268398 },
    { lat: 49.238845, lng: 18.268393 },
    { lat: 49.238881, lng: 18.268386 },
    { lat: 49.240117, lng: 18.267896 },
    { lat: 49.240265, lng: 18.267838 },
    { lat: 49.240523, lng: 18.267703 },
    { lat: 49.241904, lng: 18.266988 },
    { lat: 49.243028, lng: 18.266406 },
    { lat: 49.243282, lng: 18.266274 },
    { lat: 49.24358, lng: 18.26612 },
    { lat: 49.2438, lng: 18.266062 },
    { lat: 49.243947, lng: 18.266023 },
    { lat: 49.244311, lng: 18.265948 },
    { lat: 49.244414, lng: 18.265927 },
    { lat: 49.24503, lng: 18.265987 },
    { lat: 49.245632, lng: 18.266046 },
    { lat: 49.246594, lng: 18.265778 },
    { lat: 49.246636, lng: 18.265766 },
    { lat: 49.247545, lng: 18.265513 },
    { lat: 49.248493, lng: 18.265249 },
    { lat: 49.249067, lng: 18.264755 },
    { lat: 49.249669, lng: 18.263064 },
    { lat: 49.249783, lng: 18.262969 },
    { lat: 49.250002, lng: 18.26296 },
    { lat: 49.251381, lng: 18.262239 },
    { lat: 49.251813, lng: 18.261981 },
    { lat: 49.252758, lng: 18.261416 },
    { lat: 49.253169, lng: 18.261171 },
    { lat: 49.253701, lng: 18.261106 },
    { lat: 49.253904, lng: 18.26114 },
    { lat: 49.254851, lng: 18.261309 },
    { lat: 49.255943, lng: 18.2616022 },
    { lat: 49.256888, lng: 18.261856 },
    { lat: 49.257043, lng: 18.261812 },
    { lat: 49.257301, lng: 18.26174 },
    { lat: 49.258359, lng: 18.261444 },
    { lat: 49.259111, lng: 18.261233 },
    { lat: 49.259288, lng: 18.261184 },
    { lat: 49.260156, lng: 18.261399 },
    { lat: 49.260489, lng: 18.26148 },
    { lat: 49.260608, lng: 18.26151 },
    { lat: 49.261207, lng: 18.262026 },
    { lat: 49.261515, lng: 18.262292 },
    { lat: 49.26155, lng: 18.262426 },
    { lat: 49.261806, lng: 18.26251 },
    { lat: 49.262189, lng: 18.262637 },
    { lat: 49.262408, lng: 18.262639 },
    { lat: 49.262541, lng: 18.262619 },
    { lat: 49.262731, lng: 18.26259 },
    { lat: 49.262903, lng: 18.262565 },
    { lat: 49.263, lng: 18.26255 },
    { lat: 49.263091, lng: 18.262537 },
    { lat: 49.263193, lng: 18.262479 },
    { lat: 49.263309, lng: 18.262413 },
    { lat: 49.263565, lng: 18.262268 },
    { lat: 49.263623, lng: 18.261197 },
    { lat: 49.263734, lng: 18.261006 },
    { lat: 49.263891, lng: 18.260738 },
    { lat: 49.263994, lng: 18.26056 },
    { lat: 49.264268, lng: 18.260256 },
    { lat: 49.264663, lng: 18.259802 },
    { lat: 49.264674, lng: 18.259789 },
    { lat: 49.265544, lng: 18.258564 },
    { lat: 49.265647, lng: 18.258417 },
    { lat: 49.265805, lng: 18.258194 },
    { lat: 49.266435, lng: 18.257081 },
    { lat: 49.267514, lng: 18.256082 },
    { lat: 49.267642, lng: 18.255737 },
    { lat: 49.267672, lng: 18.255561 },
    { lat: 49.267796, lng: 18.254823 },
    { lat: 49.267852, lng: 18.25437 },
    { lat: 49.268105, lng: 18.253733 },
    { lat: 49.267982, lng: 18.253163 },
    { lat: 49.268494, lng: 18.252183 },
    { lat: 49.268769, lng: 18.251938 },
    { lat: 49.26937, lng: 18.251403 },
    { lat: 49.2704873, lng: 18.2504078 },
    { lat: 49.271109, lng: 18.249854 },
    { lat: 49.272508, lng: 18.24911 },
    { lat: 49.272725, lng: 18.248861 },
    { lat: 49.272997, lng: 18.248862 },
    { lat: 49.273079, lng: 18.248711 },
    { lat: 49.273555, lng: 18.248391 },
    { lat: 49.274641, lng: 18.248121 },
    { lat: 49.275127, lng: 18.248941 },
    { lat: 49.2755313, lng: 18.2492133 },
    { lat: 49.275617, lng: 18.249271 },
    { lat: 49.2768, lng: 18.248679 },
    { lat: 49.277528, lng: 18.248565 },
    { lat: 49.278229, lng: 18.24831 },
    { lat: 49.278474, lng: 18.247867 },
    { lat: 49.278752, lng: 18.247744 },
    { lat: 49.278981, lng: 18.24764 },
    { lat: 49.279174, lng: 18.247669 },
    { lat: 49.27947, lng: 18.247888 },
    { lat: 49.279941, lng: 18.248483 },
    { lat: 49.280758, lng: 18.248213 },
    { lat: 49.28079, lng: 18.248203 },
    { lat: 49.281256, lng: 18.248049 },
    { lat: 49.281735, lng: 18.247679 },
    { lat: 49.282588, lng: 18.247402 },
    { lat: 49.283101, lng: 18.247536 },
    { lat: 49.283473, lng: 18.247487 },
    { lat: 49.283596, lng: 18.247523 },
    { lat: 49.283651, lng: 18.24754 },
    { lat: 49.283921, lng: 18.247621 },
    { lat: 49.285267, lng: 18.247342 },
    { lat: 49.285759, lng: 18.248181 },
    { lat: 49.285813, lng: 18.248271 },
    { lat: 49.286055, lng: 18.248399 },
    { lat: 49.287349, lng: 18.248141 },
    { lat: 49.287416, lng: 18.248148 },
    { lat: 49.289533, lng: 18.248382 },
    { lat: 49.290081, lng: 18.248337 },
    { lat: 49.290452, lng: 18.248512 },
    { lat: 49.29128, lng: 18.248577 },
    { lat: 49.29164, lng: 18.248518 },
    { lat: 49.29182, lng: 18.248696 },
    { lat: 49.292305, lng: 18.248819 },
    { lat: 49.292872, lng: 18.248793 },
    { lat: 49.293022, lng: 18.248725 },
    { lat: 49.293465, lng: 18.248523 },
    { lat: 49.293944, lng: 18.248426 },
    { lat: 49.293989, lng: 18.248409 },
    { lat: 49.294393, lng: 18.248251 },
    { lat: 49.2945347, lng: 18.248195 },
    { lat: 49.29474, lng: 18.248114 },
    { lat: 49.294708, lng: 18.247801 },
    { lat: 49.294684, lng: 18.2467787 },
    { lat: 49.294664, lng: 18.246029 },
    { lat: 49.294688, lng: 18.245494 },
    { lat: 49.294725, lng: 18.244655 },
    { lat: 49.2947, lng: 18.244161 },
    { lat: 49.294703, lng: 18.243353 },
    { lat: 49.294702, lng: 18.243286 },
    { lat: 49.294683, lng: 18.242735 },
    { lat: 49.294581, lng: 18.242402 },
    { lat: 49.294572, lng: 18.242366 },
    { lat: 49.294459, lng: 18.24157 },
    { lat: 49.294448, lng: 18.241192 },
    { lat: 49.294418, lng: 18.240986 },
    { lat: 49.294512, lng: 18.240926 },
    { lat: 49.294538, lng: 18.24091 },
    { lat: 49.294673, lng: 18.240886 },
    { lat: 49.294743, lng: 18.240738 },
    { lat: 49.294813, lng: 18.240588 },
    { lat: 49.294844, lng: 18.240521 },
    { lat: 49.294907, lng: 18.240393 },
    { lat: 49.295012, lng: 18.240178 },
    { lat: 49.295155, lng: 18.239887 },
    { lat: 49.295161, lng: 18.23988 },
    { lat: 49.295192, lng: 18.239838 },
    { lat: 49.295236, lng: 18.239782 },
    { lat: 49.295275, lng: 18.239479 },
    { lat: 49.295327, lng: 18.239304 },
    { lat: 49.295381, lng: 18.239124 },
    { lat: 49.295447, lng: 18.238904 },
    { lat: 49.295522, lng: 18.238656 },
    { lat: 49.295426, lng: 18.238098 },
    { lat: 49.295388, lng: 18.237947 },
    { lat: 49.295376, lng: 18.2379 },
    { lat: 49.295269, lng: 18.237462 },
    { lat: 49.295197, lng: 18.237033 },
    { lat: 49.295078, lng: 18.236332 },
    { lat: 49.295043, lng: 18.235776 },
    { lat: 49.295036, lng: 18.23567 },
    { lat: 49.29493, lng: 18.235295 },
    { lat: 49.294865, lng: 18.235062 },
    { lat: 49.294666, lng: 18.234442 },
    { lat: 49.2946207, lng: 18.2343011 },
    { lat: 49.294574, lng: 18.234156 },
    { lat: 49.294573, lng: 18.234133 },
    { lat: 49.294558, lng: 18.233973 },
    { lat: 49.294532, lng: 18.233721 },
    { lat: 49.294526, lng: 18.233655 },
    { lat: 49.294511, lng: 18.233506 },
    { lat: 49.294494, lng: 18.233473 },
    { lat: 49.294417, lng: 18.233322 },
    { lat: 49.294318, lng: 18.23313 },
    { lat: 49.294309, lng: 18.233111 },
    { lat: 49.294188, lng: 18.232606 },
    { lat: 49.294191, lng: 18.232068 },
    { lat: 49.294221, lng: 18.231955 },
    { lat: 49.294264, lng: 18.231791 },
    { lat: 49.294281, lng: 18.231723 },
    { lat: 49.294365, lng: 18.231462 },
    { lat: 49.294414, lng: 18.23131 },
    { lat: 49.2945, lng: 18.231178 },
    { lat: 49.29454, lng: 18.230991 },
    { lat: 49.294544, lng: 18.230971 },
    { lat: 49.294547, lng: 18.230957 },
    { lat: 49.294623, lng: 18.23064 },
    { lat: 49.294635, lng: 18.230586 },
    { lat: 49.294703, lng: 18.23009 },
    { lat: 49.294659, lng: 18.229803 },
    { lat: 49.294533, lng: 18.229452 },
    { lat: 49.294407, lng: 18.229097 },
    { lat: 49.294336, lng: 18.228899 },
    { lat: 49.29429, lng: 18.228705 },
    { lat: 49.294088, lng: 18.22785 },
    { lat: 49.294092, lng: 18.22766 },
    { lat: 49.294124, lng: 18.227389 },
    { lat: 49.29415, lng: 18.227172 },
    { lat: 49.294249, lng: 18.226923 },
    { lat: 49.294259, lng: 18.2269 },
    { lat: 49.294344, lng: 18.226686 },
    { lat: 49.294379, lng: 18.226597 },
    { lat: 49.294447, lng: 18.226306 },
    { lat: 49.294465, lng: 18.226236 },
    { lat: 49.29471, lng: 18.225643 },
    { lat: 49.294783, lng: 18.225533 },
    { lat: 49.294817, lng: 18.225481 },
    { lat: 49.294878, lng: 18.225269 },
    { lat: 49.294902, lng: 18.225187 },
    { lat: 49.294994, lng: 18.224871 },
    { lat: 49.295051, lng: 18.224672 },
    { lat: 49.295111, lng: 18.224463 },
    { lat: 49.295142, lng: 18.224357 },
    { lat: 49.295158, lng: 18.224268 },
    { lat: 49.295195, lng: 18.224051 },
    { lat: 49.295231, lng: 18.223847 },
    { lat: 49.295256, lng: 18.223736 },
    { lat: 49.295277, lng: 18.223644 },
    { lat: 49.295286, lng: 18.223605 },
    { lat: 49.295301, lng: 18.223309 },
    { lat: 49.295306, lng: 18.22322 },
    { lat: 49.295331, lng: 18.223023 },
    { lat: 49.295335, lng: 18.222993 },
    { lat: 49.295402, lng: 18.222481 },
    { lat: 49.295389, lng: 18.222021 },
    { lat: 49.295333, lng: 18.221744 },
    { lat: 49.295269, lng: 18.221777 },
    { lat: 49.295018, lng: 18.221249 },
    { lat: 49.294597, lng: 18.220645 },
    { lat: 49.294195, lng: 18.220237 },
    { lat: 49.293722, lng: 18.219823 },
    { lat: 49.293077, lng: 18.219346 },
    { lat: 49.292851, lng: 18.218273 },
    { lat: 49.29288, lng: 18.218124 },
    { lat: 49.292939, lng: 18.217222 },
    { lat: 49.292966, lng: 18.216723 },
    { lat: 49.29326, lng: 18.215929 },
    { lat: 49.293469, lng: 18.21537 },
    { lat: 49.293591, lng: 18.21496 },
    { lat: 49.293687, lng: 18.214652 },
    { lat: 49.293772, lng: 18.214354 },
    { lat: 49.29381, lng: 18.213969 },
    { lat: 49.293767, lng: 18.213384 },
    { lat: 49.293375, lng: 18.211999 },
    { lat: 49.293069, lng: 18.211425 },
    { lat: 49.292627, lng: 18.210753 },
    { lat: 49.292417, lng: 18.210395 },
    { lat: 49.292177, lng: 18.210183 },
    { lat: 49.291918, lng: 18.209749 },
    { lat: 49.291775, lng: 18.208905 },
    { lat: 49.291694, lng: 18.208446 },
    { lat: 49.291779, lng: 18.207952 },
    { lat: 49.291978, lng: 18.207549 },
    { lat: 49.292162, lng: 18.207229 },
    { lat: 49.292412, lng: 18.206927 },
    { lat: 49.292659, lng: 18.206588 },
    { lat: 49.292802, lng: 18.206357 },
    { lat: 49.293122, lng: 18.205739 },
    { lat: 49.293218, lng: 18.205305 },
    { lat: 49.2934, lng: 18.204703 },
    { lat: 49.293485, lng: 18.20424 },
    { lat: 49.293195, lng: 18.202763 },
    { lat: 49.293193, lng: 18.20275 },
    { lat: 49.293136, lng: 18.202113 },
    { lat: 49.292854, lng: 18.201601 },
    { lat: 49.292478, lng: 18.200891 },
    { lat: 49.292376, lng: 18.200696 },
    { lat: 49.291611, lng: 18.200191 },
    { lat: 49.291143, lng: 18.199989 },
    { lat: 49.290775, lng: 18.199405 },
    { lat: 49.290503, lng: 18.199187 },
    { lat: 49.290418, lng: 18.198913 },
    { lat: 49.290196, lng: 18.19844 },
    { lat: 49.290016, lng: 18.198111 },
    { lat: 49.2895477, lng: 18.1974646 },
    { lat: 49.289632, lng: 18.196429 },
    { lat: 49.289804, lng: 18.19437 },
    { lat: 49.289846, lng: 18.193865 },
    { lat: 49.289926, lng: 18.192819 },
    { lat: 49.289979, lng: 18.192313 },
    { lat: 49.290008, lng: 18.19204 },
    { lat: 49.289937, lng: 18.191739 },
    { lat: 49.28939, lng: 18.190738 },
    { lat: 49.2886, lng: 18.189292 },
    { lat: 49.288107, lng: 18.188386 },
    { lat: 49.287459, lng: 18.187187 },
    { lat: 49.287285, lng: 18.18604 },
    { lat: 49.28707, lng: 18.184527 },
    { lat: 49.2869962, lng: 18.1840259 },
    { lat: 49.286663, lng: 18.18422 },
    { lat: 49.286553, lng: 18.184252 },
    { lat: 49.286298, lng: 18.184333 },
    { lat: 49.286084, lng: 18.18432 },
    { lat: 49.285411, lng: 18.184297 },
    { lat: 49.285216, lng: 18.184281 },
    { lat: 49.285136, lng: 18.184317 },
    { lat: 49.284954, lng: 18.184418 },
    { lat: 49.28406, lng: 18.184942 },
    { lat: 49.283428, lng: 18.185216 },
    { lat: 49.283076, lng: 18.18529 },
    { lat: 49.282794, lng: 18.185356 },
    { lat: 49.282284, lng: 18.185588 },
    { lat: 49.282036, lng: 18.185667 },
    { lat: 49.281746, lng: 18.185729 },
    { lat: 49.280922, lng: 18.185945 },
    { lat: 49.280454, lng: 18.185861 },
    { lat: 49.280338, lng: 18.185801 },
    { lat: 49.280069, lng: 18.185555 },
    { lat: 49.27985, lng: 18.185174 },
    { lat: 49.279636, lng: 18.184723 },
    { lat: 49.279423, lng: 18.184612 },
    { lat: 49.279089, lng: 18.184645 },
    { lat: 49.278801, lng: 18.184666 },
    { lat: 49.278524, lng: 18.184586 },
    { lat: 49.278014, lng: 18.184627 },
    { lat: 49.277496, lng: 18.184582 },
    { lat: 49.277438, lng: 18.184574 },
    { lat: 49.277396, lng: 18.184568 },
    { lat: 49.277369, lng: 18.184564 },
    { lat: 49.277176, lng: 18.18452 },
    { lat: 49.276986, lng: 18.184478 },
    { lat: 49.276514, lng: 18.184317 },
    { lat: 49.276054, lng: 18.184143 },
    { lat: 49.275903, lng: 18.184152 },
    { lat: 49.27574, lng: 18.184178 },
    { lat: 49.275361, lng: 18.184236 },
    { lat: 49.274898, lng: 18.184351 },
    { lat: 49.2748405, lng: 18.1843792 },
    { lat: 49.274455, lng: 18.184568 },
    { lat: 49.274105, lng: 18.184578 },
    { lat: 49.273784, lng: 18.184589 },
    { lat: 49.273504, lng: 18.184576 },
    { lat: 49.273162, lng: 18.184597 },
    { lat: 49.272747, lng: 18.184737 },
    { lat: 49.272363, lng: 18.184806 },
    { lat: 49.271882, lng: 18.184857 },
    { lat: 49.27154, lng: 18.184837 },
    { lat: 49.271327, lng: 18.184822 },
    { lat: 49.271112, lng: 18.184813 },
    { lat: 49.270866, lng: 18.184812 },
    { lat: 49.270636, lng: 18.184781 },
    { lat: 49.270303, lng: 18.184518 },
    { lat: 49.269833, lng: 18.184264 },
    { lat: 49.269497, lng: 18.183927 },
    { lat: 49.269107, lng: 18.18347 },
    { lat: 49.26896, lng: 18.183168 },
    { lat: 49.268752, lng: 18.182724 },
    { lat: 49.268619, lng: 18.181977 },
    { lat: 49.268578, lng: 18.181593 },
    { lat: 49.268482, lng: 18.180964 },
    { lat: 49.268385, lng: 18.180335 },
    { lat: 49.268293, lng: 18.179885 },
    { lat: 49.268229, lng: 18.179642 },
    { lat: 49.268095, lng: 18.179258 },
    { lat: 49.268045, lng: 18.179111 },
    { lat: 49.267877, lng: 18.178809 },
    { lat: 49.267662, lng: 18.178585 },
    { lat: 49.267456, lng: 18.178401 },
    { lat: 49.267186, lng: 18.17817 },
    { lat: 49.266906, lng: 18.177977 },
    { lat: 49.266659, lng: 18.17788 },
    { lat: 49.26652, lng: 18.177837 },
    { lat: 49.266356, lng: 18.177787 },
    { lat: 49.265937, lng: 18.177777 },
    { lat: 49.265805, lng: 18.177524 },
    { lat: 49.26575, lng: 18.177411 },
    { lat: 49.265609, lng: 18.177297 },
    { lat: 49.265482, lng: 18.177192 },
    { lat: 49.265368, lng: 18.177077 },
    { lat: 49.265204, lng: 18.17682 },
    { lat: 49.265033, lng: 18.175897 },
    { lat: 49.264972, lng: 18.175546 },
    { lat: 49.264945, lng: 18.175282 },
    { lat: 49.264839, lng: 18.174517 },
    { lat: 49.264793, lng: 18.174091 },
    { lat: 49.264728, lng: 18.173758 },
    { lat: 49.264605, lng: 18.173465 },
    { lat: 49.264311, lng: 18.172841 },
    { lat: 49.264261, lng: 18.172745 },
    { lat: 49.264214, lng: 18.172654 },
    { lat: 49.264125, lng: 18.172553 },
    { lat: 49.263975, lng: 18.172421 },
    { lat: 49.263732, lng: 18.172206 },
    { lat: 49.263351, lng: 18.171927 },
    { lat: 49.262987, lng: 18.171744 },
    { lat: 49.262804, lng: 18.171283 },
    { lat: 49.262727, lng: 18.170884 },
    { lat: 49.262564, lng: 18.170242 },
    { lat: 49.262416, lng: 18.169646 },
    { lat: 49.262197, lng: 18.168953 },
    { lat: 49.262048, lng: 18.168485 },
    { lat: 49.261949, lng: 18.168089 },
    { lat: 49.261869, lng: 18.167613 },
    { lat: 49.261792, lng: 18.167166 },
    { lat: 49.261666, lng: 18.166712 },
    { lat: 49.261567, lng: 18.166223 },
    { lat: 49.26144, lng: 18.165343 },
    { lat: 49.261378, lng: 18.164944 },
    { lat: 49.261315, lng: 18.16455 },
    { lat: 49.261202, lng: 18.16385 },
    { lat: 49.261125, lng: 18.163608 },
    { lat: 49.2609256, lng: 18.1631656 },
    { lat: 49.2609219, lng: 18.1630863 },
    { lat: 49.2606656, lng: 18.1626942 },
    { lat: 49.26044, lng: 18.162412 },
    { lat: 49.260323, lng: 18.162384 },
    { lat: 49.260174, lng: 18.162349 },
    { lat: 49.259775, lng: 18.162215 },
    { lat: 49.259463, lng: 18.16215 },
    { lat: 49.259142, lng: 18.162059 },
    { lat: 49.258822, lng: 18.161922 },
    { lat: 49.258488, lng: 18.161903 },
    { lat: 49.258197, lng: 18.161897 },
    { lat: 49.2576609, lng: 18.1620282 },
    { lat: 49.2577145, lng: 18.1624237 },
    { lat: 49.2577048, lng: 18.1627875 },
    { lat: 49.2577296, lng: 18.1633407 },
    { lat: 49.2577637, lng: 18.1638798 },
    { lat: 49.2578086, lng: 18.1642888 },
    { lat: 49.2578535, lng: 18.1644819 },
    { lat: 49.2579534, lng: 18.1647656 },
    { lat: 49.2579852, lng: 18.1648703 },
    { lat: 49.2579947, lng: 18.1650372 },
    { lat: 49.2580034, lng: 18.1651262 },
    { lat: 49.2579826, lng: 18.1653759 },
    { lat: 49.2579522, lng: 18.1657323 },
    { lat: 49.2578555, lng: 18.1660129 },
    { lat: 49.2577003, lng: 18.1670292 },
    { lat: 49.2577006, lng: 18.1674113 },
    { lat: 49.2576224, lng: 18.1682562 },
    { lat: 49.2576553, lng: 18.1698149 },
    { lat: 49.2576153, lng: 18.1705176 },
    { lat: 49.2575244, lng: 18.1708434 },
    { lat: 49.2575086, lng: 18.1709015 },
    { lat: 49.2573709, lng: 18.1713934 },
    { lat: 49.2573771, lng: 18.1718194 },
    { lat: 49.2573272, lng: 18.1721087 },
    { lat: 49.2572739, lng: 18.172144 },
    { lat: 49.2571075, lng: 18.1722531 },
    { lat: 49.2568808, lng: 18.1721954 },
    { lat: 49.2567183, lng: 18.1723516 },
    { lat: 49.256534, lng: 18.1725866 },
    { lat: 49.25649, lng: 18.1726437 },
    { lat: 49.2564889, lng: 18.1726442 },
    { lat: 49.2563685, lng: 18.1727105 },
    { lat: 49.2559847, lng: 18.1729239 },
    { lat: 49.2554893, lng: 18.1722537 },
    { lat: 49.2551615, lng: 18.1719041 },
    { lat: 49.2551196, lng: 18.1718826 },
    { lat: 49.2549444, lng: 18.1717947 },
    { lat: 49.2548757, lng: 18.1717603 },
    { lat: 49.2546564, lng: 18.1715958 },
    { lat: 49.2544621, lng: 18.1712751 },
    { lat: 49.2542726, lng: 18.1709625 },
    { lat: 49.254075, lng: 18.1707226 },
    { lat: 49.2536065, lng: 18.1704199 },
    { lat: 49.2533615, lng: 18.1710728 },
    { lat: 49.253049, lng: 18.1717226 },
    { lat: 49.2529723, lng: 18.1718829 },
    { lat: 49.2528093, lng: 18.1724435 },
    { lat: 49.2526665, lng: 18.1732633 },
    { lat: 49.2526272, lng: 18.1735817 },
    { lat: 49.2525947, lng: 18.1738396 },
    { lat: 49.2523878, lng: 18.1746053 },
    { lat: 49.2523287, lng: 18.1748367 },
    { lat: 49.252158, lng: 18.1756402 },
    { lat: 49.25209, lng: 18.1757909 },
    { lat: 49.2519064, lng: 18.1761953 },
    { lat: 49.2514652, lng: 18.1763888 },
    { lat: 49.2509431, lng: 18.1774811 },
    { lat: 49.250851, lng: 18.1783199 },
    { lat: 49.2507912, lng: 18.178594 },
    { lat: 49.2507732, lng: 18.1786766 },
    { lat: 49.2506618, lng: 18.1791935 },
    { lat: 49.2505209, lng: 18.1797526 },
    { lat: 49.2503835, lng: 18.1801122 },
    { lat: 49.2502776, lng: 18.1803916 },
    { lat: 49.2501264, lng: 18.1804518 },
    { lat: 49.2497371, lng: 18.1806057 },
    { lat: 49.2492726, lng: 18.1804956 },
    { lat: 49.2486623, lng: 18.1803002 },
    { lat: 49.2484221, lng: 18.1802849 },
    { lat: 49.2478865, lng: 18.1802504 },
    { lat: 49.2476481, lng: 18.1803027 },
    { lat: 49.2476269, lng: 18.1803074 },
    { lat: 49.2474303, lng: 18.1799254 },
    { lat: 49.2472337, lng: 18.1798438 },
    { lat: 49.2470381, lng: 18.1797557 },
    { lat: 49.2467424, lng: 18.1793226 },
    { lat: 49.2467342, lng: 18.1792948 },
    { lat: 49.2467273, lng: 18.1792727 },
    { lat: 49.2467175, lng: 18.1792414 },
    { lat: 49.2466174, lng: 18.1789084 },
    { lat: 49.2463334, lng: 18.17842 },
    { lat: 49.246274, lng: 18.1783326 },
    { lat: 49.2460708, lng: 18.1780319 },
    { lat: 49.2459988, lng: 18.1778354 },
    { lat: 49.2459229, lng: 18.1776318 },
    { lat: 49.2459744, lng: 18.1773916 },
    { lat: 49.2458818, lng: 18.1772884 },
    { lat: 49.2458093, lng: 18.1772085 },
    { lat: 49.2457349, lng: 18.1771261 },
    { lat: 49.2452914, lng: 18.1768293 },
    { lat: 49.2452721, lng: 18.1768098 },
    { lat: 49.2452416, lng: 18.1769683 },
    { lat: 49.2449817, lng: 18.1770786 },
    { lat: 49.2448253, lng: 18.1772041 },
    { lat: 49.2441993, lng: 18.1771268 },
    { lat: 49.2440377, lng: 18.1770062 },
    { lat: 49.2438306, lng: 18.1769347 },
    { lat: 49.2435437, lng: 18.1767133 },
    { lat: 49.2435319, lng: 18.1767437 },
    { lat: 49.2427287, lng: 18.1767261 },
    { lat: 49.2424425, lng: 18.176696 },
    { lat: 49.2416974, lng: 18.1769845 },
    { lat: 49.2415172, lng: 18.1771446 },
    { lat: 49.2415145, lng: 18.1771183 },
    { lat: 49.241498, lng: 18.1770483 },
    { lat: 49.2414291, lng: 18.1767895 },
    { lat: 49.2413108, lng: 18.1763578 },
    { lat: 49.2412351, lng: 18.1761173 },
    { lat: 49.2411126, lng: 18.1757532 },
    { lat: 49.2410799, lng: 18.1756555 },
    { lat: 49.2409221, lng: 18.1749269 },
    { lat: 49.2409012, lng: 18.1746596 },
    { lat: 49.2408869, lng: 18.1744679 },
    { lat: 49.2408595, lng: 18.1741244 },
    { lat: 49.2408133, lng: 18.1740858 },
    { lat: 49.2407826, lng: 18.1740451 },
    { lat: 49.2406605, lng: 18.1738808 },
    { lat: 49.2400323, lng: 18.1736919 },
    { lat: 49.240029, lng: 18.1736914 },
    { lat: 49.2395066, lng: 18.1735953 },
    { lat: 49.2393014, lng: 18.1736371 },
    { lat: 49.2390342, lng: 18.1738765 },
    { lat: 49.2386523, lng: 18.1743999 },
    { lat: 49.2384662, lng: 18.1746562 },
    { lat: 49.2381333, lng: 18.1746359 },
    { lat: 49.2380291, lng: 18.1746292 },
    { lat: 49.2377028, lng: 18.1746088 },
    { lat: 49.2376326, lng: 18.1747987 },
    { lat: 49.237577, lng: 18.1749516 },
    { lat: 49.2374998, lng: 18.175161 },
    { lat: 49.2373757, lng: 18.1756739 },
    { lat: 49.23727, lng: 18.1766098 },
    { lat: 49.2372561, lng: 18.1771356 },
    { lat: 49.2372998, lng: 18.1772305 },
    { lat: 49.2374325, lng: 18.1775165 },
    { lat: 49.2375336, lng: 18.1778679 },
    { lat: 49.2375393, lng: 18.1778886 },
    { lat: 49.2375483, lng: 18.1779262 },
    { lat: 49.2375791, lng: 18.1780518 },
    { lat: 49.2377051, lng: 18.1785768 },
    { lat: 49.237278, lng: 18.1790801 },
    { lat: 49.2371997, lng: 18.1791714 },
    { lat: 49.2370179, lng: 18.1792081 },
    { lat: 49.2366923, lng: 18.1794725 },
    { lat: 49.2364, lng: 18.1796507 },
    { lat: 49.2363912, lng: 18.1796539 },
    { lat: 49.235756, lng: 18.1798914 },
    { lat: 49.2354631, lng: 18.1799214 },
    { lat: 49.2354544, lng: 18.1798691 },
    { lat: 49.2354507, lng: 18.1798412 },
    { lat: 49.2354276, lng: 18.1796921 },
    { lat: 49.235394, lng: 18.1794814 },
    { lat: 49.2353528, lng: 18.1792154 },
    { lat: 49.2353296, lng: 18.1790655 },
    { lat: 49.2353167, lng: 18.1789382 },
    { lat: 49.2353131, lng: 18.1788928 },
    { lat: 49.2352682, lng: 18.1787023 },
    { lat: 49.235193, lng: 18.1783717 },
    { lat: 49.2350708, lng: 18.1780489 },
    { lat: 49.2347702, lng: 18.1772526 },
    { lat: 49.2346664, lng: 18.1769755 },
    { lat: 49.2346026, lng: 18.1768057 },
    { lat: 49.2345111, lng: 18.1766068 },
    { lat: 49.2344311, lng: 18.1764319 },
    { lat: 49.2343151, lng: 18.1761771 },
    { lat: 49.2342869, lng: 18.176118 },
    { lat: 49.2341337, lng: 18.1756505 },
    { lat: 49.2340709, lng: 18.1754577 },
    { lat: 49.2338561, lng: 18.1754892 },
    { lat: 49.2335799, lng: 18.1756112 },
    { lat: 49.2332612, lng: 18.1758196 },
    { lat: 49.2330993, lng: 18.1759114 },
    { lat: 49.2330437, lng: 18.1759437 },
    { lat: 49.2329467, lng: 18.1760041 },
    { lat: 49.2326743, lng: 18.1761738 },
    { lat: 49.2324478, lng: 18.1763155 },
    { lat: 49.2323135, lng: 18.176571 },
    { lat: 49.2321894, lng: 18.1767123 },
    { lat: 49.2316938, lng: 18.1772785 },
    { lat: 49.2314507, lng: 18.1775055 },
    { lat: 49.2313196, lng: 18.1776612 },
    { lat: 49.2310989, lng: 18.1779249 },
    { lat: 49.230836, lng: 18.1779453 },
    { lat: 49.2304745, lng: 18.1782153 },
    { lat: 49.2301758, lng: 18.1784381 },
    { lat: 49.2298463, lng: 18.178774 },
    { lat: 49.2294828, lng: 18.179145 },
    { lat: 49.2292101, lng: 18.1795862 },
    { lat: 49.2291719, lng: 18.1796303 },
    { lat: 49.2287148, lng: 18.1801581 },
    { lat: 49.2286293, lng: 18.1805421 },
    { lat: 49.2281893, lng: 18.1812124 },
    { lat: 49.2277519, lng: 18.1817515 },
    { lat: 49.2274659, lng: 18.1819808 },
    { lat: 49.2274368, lng: 18.1820042 },
    { lat: 49.2274281, lng: 18.1815164 },
    { lat: 49.2274168, lng: 18.1813563 },
    { lat: 49.2274041, lng: 18.1813335 },
    { lat: 49.227373, lng: 18.1812804 },
    { lat: 49.2273674, lng: 18.1812662 },
    { lat: 49.2273088, lng: 18.1810884 },
    { lat: 49.2271944, lng: 18.1807448 },
    { lat: 49.2271359, lng: 18.1805695 },
    { lat: 49.2270828, lng: 18.1804091 },
    { lat: 49.2267496, lng: 18.1808603 },
    { lat: 49.2262976, lng: 18.1810223 },
    { lat: 49.2262936, lng: 18.1810145 },
    { lat: 49.226249, lng: 18.1809046 },
    { lat: 49.2262033, lng: 18.1807957 },
    { lat: 49.2261654, lng: 18.1807025 },
    { lat: 49.2261366, lng: 18.1806343 },
    { lat: 49.2255197, lng: 18.1811343 },
    { lat: 49.2252421, lng: 18.1813909 },
    { lat: 49.2252348, lng: 18.1814987 },
    { lat: 49.2252268, lng: 18.1816359 },
    { lat: 49.2252177, lng: 18.1817748 },
    { lat: 49.225206, lng: 18.1819628 },
    { lat: 49.2251958, lng: 18.1821201 },
    { lat: 49.2251911, lng: 18.1821792 },
    { lat: 49.2251864, lng: 18.1822701 },
    { lat: 49.2251847, lng: 18.1822826 },
    { lat: 49.2251885, lng: 18.1823898 },
    { lat: 49.2252075, lng: 18.1829377 },
    { lat: 49.2252059, lng: 18.1829677 },
    { lat: 49.2252036, lng: 18.1829685 },
    { lat: 49.2249185, lng: 18.1830369 },
    { lat: 49.2248742, lng: 18.1830475 },
    { lat: 49.2243777, lng: 18.1833047 },
    { lat: 49.2239707, lng: 18.1834477 },
    { lat: 49.2235912, lng: 18.1835808 },
    { lat: 49.2234168, lng: 18.1836695 },
    { lat: 49.2229959, lng: 18.1838834 },
    { lat: 49.2225538, lng: 18.1841081 },
    { lat: 49.2218935, lng: 18.1845362 },
    { lat: 49.221887, lng: 18.1845049 },
    { lat: 49.2214279, lng: 18.1845434 },
    { lat: 49.221385, lng: 18.1845444 },
    { lat: 49.221082, lng: 18.1845521 },
    { lat: 49.2208645, lng: 18.1844987 },
    { lat: 49.2201132, lng: 18.1845983 },
    { lat: 49.2199798, lng: 18.1846154 },
    { lat: 49.2198667, lng: 18.1846305 },
    { lat: 49.2193508, lng: 18.1846466 },
    { lat: 49.2193352, lng: 18.1847093 },
    { lat: 49.2193085, lng: 18.1848132 },
    { lat: 49.2189945, lng: 18.1860555 },
    { lat: 49.2193922, lng: 18.186222 },
    { lat: 49.2192102, lng: 18.1865704 },
    { lat: 49.2188136, lng: 18.186996 },
    { lat: 49.2184806, lng: 18.1873297 },
    { lat: 49.2183455, lng: 18.1874738 },
    { lat: 49.2182412, lng: 18.1875853 },
    { lat: 49.2181351, lng: 18.1876593 },
    { lat: 49.2179852, lng: 18.1877634 },
    { lat: 49.217766, lng: 18.1879159 },
    { lat: 49.2175146, lng: 18.188119 },
    { lat: 49.2174435, lng: 18.1881772 },
    { lat: 49.2173663, lng: 18.1882459 },
    { lat: 49.2170933, lng: 18.1884886 },
    { lat: 49.216957, lng: 18.18861 },
    { lat: 49.2165806, lng: 18.1888349 },
    { lat: 49.2165457, lng: 18.1888559 },
    { lat: 49.2164994, lng: 18.1892681 },
    { lat: 49.21645, lng: 18.1895136 },
    { lat: 49.2163707, lng: 18.1899017 },
    { lat: 49.2163058, lng: 18.1902272 },
    { lat: 49.2161747, lng: 18.191074 },
    { lat: 49.216117, lng: 18.1912697 },
    { lat: 49.216082, lng: 18.1913098 },
    { lat: 49.2160556, lng: 18.1913405 },
    { lat: 49.2159157, lng: 18.191499 },
    { lat: 49.2157602, lng: 18.1919783 },
    { lat: 49.2155912, lng: 18.1922617 },
    { lat: 49.2153836, lng: 18.1924972 },
    { lat: 49.2151368, lng: 18.1926807 },
    { lat: 49.2147109, lng: 18.1930063 },
    { lat: 49.2143495, lng: 18.1933575 },
    { lat: 49.2141281, lng: 18.1935919 },
    { lat: 49.2139428, lng: 18.1936668 },
    { lat: 49.213806, lng: 18.1937223 },
    { lat: 49.2135302, lng: 18.1939116 },
    { lat: 49.213393, lng: 18.1940193 },
    { lat: 49.2130596, lng: 18.1942831 },
    { lat: 49.212759, lng: 18.194572 },
    { lat: 49.2124816, lng: 18.1949109 },
    { lat: 49.2123726, lng: 18.1951197 },
    { lat: 49.2123513, lng: 18.1951604 },
    { lat: 49.2120853, lng: 18.1955415 },
    { lat: 49.21203, lng: 18.1956215 },
    { lat: 49.2118787, lng: 18.1958376 },
    { lat: 49.2118859, lng: 18.1958848 },
    { lat: 49.2117165, lng: 18.1963757 },
    { lat: 49.211639, lng: 18.1970163 },
    { lat: 49.2115273, lng: 18.1972134 },
    { lat: 49.2113802, lng: 18.1978812 },
    { lat: 49.2113238, lng: 18.1979585 },
    { lat: 49.2111008, lng: 18.1982635 },
    { lat: 49.2108663, lng: 18.1984433 },
    { lat: 49.2105782, lng: 18.1987157 },
    { lat: 49.2103526, lng: 18.1985981 },
    { lat: 49.2100459, lng: 18.198602 },
    { lat: 49.2097576, lng: 18.1985504 },
    { lat: 49.2092186, lng: 18.1986769 },
    { lat: 49.2089239, lng: 18.1988318 },
    { lat: 49.2087891, lng: 18.1989026 },
    { lat: 49.2085107, lng: 18.1995979 },
    { lat: 49.2083441, lng: 18.1999593 },
    { lat: 49.207983, lng: 18.2002792 },
    { lat: 49.2077557, lng: 18.200625 },
    { lat: 49.2076509, lng: 18.2009075 },
    { lat: 49.2071928, lng: 18.201203 },
    { lat: 49.2069947, lng: 18.2013489 },
    { lat: 49.2067567, lng: 18.2016711 },
    { lat: 49.206374, lng: 18.201922 },
    { lat: 49.2060908, lng: 18.2021798 },
    { lat: 49.2060634, lng: 18.2021921 },
    { lat: 49.2057673, lng: 18.2023653 },
    { lat: 49.2055588, lng: 18.2025069 },
    { lat: 49.2053535, lng: 18.2026471 },
    { lat: 49.2050701, lng: 18.2029024 },
    { lat: 49.2048588, lng: 18.2032522 },
    { lat: 49.2045348, lng: 18.2040406 },
    { lat: 49.2046413, lng: 18.2047442 },
    { lat: 49.2049886, lng: 18.2059899 },
    { lat: 49.2047464, lng: 18.2063132 },
    { lat: 49.2050485, lng: 18.2075168 },
    { lat: 49.2048364, lng: 18.2076371 },
    { lat: 49.2045839, lng: 18.207642 },
    { lat: 49.204326, lng: 18.2077675 },
    { lat: 49.204286, lng: 18.2079788 },
    { lat: 49.2043338, lng: 18.2082817 },
    { lat: 49.204357, lng: 18.208432 },
    { lat: 49.2047027, lng: 18.2083415 },
    { lat: 49.2047304, lng: 18.2086907 },
    { lat: 49.204315, lng: 18.2088451 },
    { lat: 49.2038495, lng: 18.2091336 },
    { lat: 49.2035292, lng: 18.2094116 },
    { lat: 49.2031143, lng: 18.2098909 },
    { lat: 49.2027344, lng: 18.2099374 },
    { lat: 49.2020757, lng: 18.2105778 },
    { lat: 49.2016127, lng: 18.2111255 },
    { lat: 49.2014738, lng: 18.2112821 },
    { lat: 49.201289, lng: 18.2115456 },
    { lat: 49.2012199, lng: 18.2116604 },
    { lat: 49.2009912, lng: 18.2120425 },
    { lat: 49.2007446, lng: 18.2123286 },
    { lat: 49.2004842, lng: 18.2126304 },
    { lat: 49.2003766, lng: 18.212859 },
    { lat: 49.2002397, lng: 18.2131914 },
    { lat: 49.2001951, lng: 18.2132592 },
    { lat: 49.1997648, lng: 18.2139085 },
    { lat: 49.1996076, lng: 18.2142371 },
    { lat: 49.1995115, lng: 18.2145309 },
    { lat: 49.1994447, lng: 18.2149292 },
    { lat: 49.1996205, lng: 18.2152388 },
    { lat: 49.199637, lng: 18.2152925 },
    { lat: 49.1997356, lng: 18.2154999 },
    { lat: 49.1998896, lng: 18.2158677 },
    { lat: 49.1999563, lng: 18.2161259 },
    { lat: 49.1998982, lng: 18.2162768 },
    { lat: 49.199842, lng: 18.2164203 },
    { lat: 49.1994331, lng: 18.2161893 },
    { lat: 49.1993303, lng: 18.2161315 },
    { lat: 49.1992116, lng: 18.216065 },
    { lat: 49.1991584, lng: 18.2163226 },
    { lat: 49.1991423, lng: 18.2163963 },
    { lat: 49.1989976, lng: 18.2170257 },
    { lat: 49.198857, lng: 18.2176293 },
    { lat: 49.1987966, lng: 18.2178989 },
    { lat: 49.198796, lng: 18.2179051 },
    { lat: 49.198783, lng: 18.2179545 },
    { lat: 49.198782, lng: 18.2179582 },
    { lat: 49.1987574, lng: 18.2180612 },
    { lat: 49.1986698, lng: 18.2184258 },
    { lat: 49.1986542, lng: 18.2184674 },
    { lat: 49.1986345, lng: 18.2185203 },
    { lat: 49.1989909, lng: 18.2188343 },
    { lat: 49.1992945, lng: 18.2189939 },
    { lat: 49.1995062, lng: 18.2190285 },
    { lat: 49.1996575, lng: 18.2190074 },
    { lat: 49.1998268, lng: 18.2189695 },
    { lat: 49.199849, lng: 18.2190236 },
    { lat: 49.1998686, lng: 18.2190726 },
    { lat: 49.1998759, lng: 18.2191023 },
    { lat: 49.1998863, lng: 18.2191429 },
    { lat: 49.1999353, lng: 18.2192326 },
    { lat: 49.2000739, lng: 18.2196283 },
    { lat: 49.2001256, lng: 18.2196808 },
    { lat: 49.2003434, lng: 18.219902 },
    { lat: 49.200396, lng: 18.2201546 },
    { lat: 49.2004709, lng: 18.2203001 },
    { lat: 49.2006399, lng: 18.2204534 },
    { lat: 49.2007329, lng: 18.2205625 },
    { lat: 49.2007507, lng: 18.2206394 },
    { lat: 49.2008052, lng: 18.2207621 },
    { lat: 49.2009287, lng: 18.2209287 },
    { lat: 49.2009378, lng: 18.2209733 },
    { lat: 49.2009503, lng: 18.2210304 },
    { lat: 49.2009789, lng: 18.2210384 },
    { lat: 49.2010067, lng: 18.2212503 },
    { lat: 49.2010343, lng: 18.2213606 },
    { lat: 49.2011925, lng: 18.2215818 },
    { lat: 49.2012756, lng: 18.2217752 },
    { lat: 49.2012887, lng: 18.2219853 },
    { lat: 49.2013449, lng: 18.2221229 },
    { lat: 49.2014297, lng: 18.2222465 },
    { lat: 49.2014901, lng: 18.222293 },
    { lat: 49.2016163, lng: 18.2221926 },
    { lat: 49.2017804, lng: 18.2223976 },
    { lat: 49.2021221, lng: 18.2227355 },
    { lat: 49.2022555, lng: 18.223079 },
    { lat: 49.2022957, lng: 18.2230907 },
    { lat: 49.2023408, lng: 18.2232065 },
    { lat: 49.202479, lng: 18.2231963 },
    { lat: 49.2025463, lng: 18.2232107 },
    { lat: 49.2026547, lng: 18.2234094 },
    { lat: 49.202747, lng: 18.2236861 },
    { lat: 49.2028821, lng: 18.2237589 },
    { lat: 49.2029497, lng: 18.2237991 },
    { lat: 49.2029894, lng: 18.2238819 },
    { lat: 49.2030218, lng: 18.2240746 },
    { lat: 49.2030663, lng: 18.224221 },
    { lat: 49.2031122, lng: 18.2245137 },
    { lat: 49.2031386, lng: 18.2246783 },
    { lat: 49.2031397, lng: 18.2248407 },
    { lat: 49.2031044, lng: 18.2249345 },
    { lat: 49.203114, lng: 18.225182 },
    { lat: 49.2031532, lng: 18.2252952 },
    { lat: 49.2031506, lng: 18.2254274 },
    { lat: 49.2032311, lng: 18.2257125 },
    { lat: 49.2032466, lng: 18.2257905 },
    { lat: 49.2032607, lng: 18.22596 },
    { lat: 49.2032427, lng: 18.2259597 },
    { lat: 49.2032598, lng: 18.2260814 },
    { lat: 49.2032304, lng: 18.2262798 },
    { lat: 49.2031968, lng: 18.2263639 },
    { lat: 49.2032073, lng: 18.2264488 },
    { lat: 49.2033042, lng: 18.2268228 },
    { lat: 49.2033095, lng: 18.2268996 },
    { lat: 49.2033653, lng: 18.2269655 },
    { lat: 49.2033975, lng: 18.2270583 },
    { lat: 49.2033906, lng: 18.2271963 },
    { lat: 49.203595, lng: 18.2273763 },
    { lat: 49.2037846, lng: 18.2274975 },
    { lat: 49.2040207, lng: 18.2276219 },
    { lat: 49.2040167, lng: 18.2276715 },
    { lat: 49.2041853, lng: 18.227601 },
    { lat: 49.2043916, lng: 18.2275363 },
    { lat: 49.2043924, lng: 18.2275508 },
    { lat: 49.2043939, lng: 18.227557 },
    { lat: 49.2048315, lng: 18.2293073 },
    { lat: 49.204938, lng: 18.22983 },
    { lat: 49.2050431, lng: 18.2303714 },
    { lat: 49.2051123, lng: 18.2305371 },
    { lat: 49.205265, lng: 18.2309038 },
    { lat: 49.2053032, lng: 18.2313182 },
    { lat: 49.2051673, lng: 18.2313697 },
    { lat: 49.2051746, lng: 18.2316774 },
    { lat: 49.205202, lng: 18.2327574 },
    { lat: 49.205203, lng: 18.2327962 },
    { lat: 49.2056274, lng: 18.2328762 },
    { lat: 49.2056323, lng: 18.2328826 },
    { lat: 49.2057285, lng: 18.2330079 },
    { lat: 49.2058504, lng: 18.2331679 },
    { lat: 49.2059564, lng: 18.2333076 },
    { lat: 49.2059916, lng: 18.2334315 },
    { lat: 49.2060032, lng: 18.2334747 },
    { lat: 49.2060044, lng: 18.2334779 },
    { lat: 49.2060057, lng: 18.2334803 },
    { lat: 49.2060962, lng: 18.2338012 },
    { lat: 49.2061047, lng: 18.2338344 },
    { lat: 49.2061116, lng: 18.2338564 },
    { lat: 49.2061386, lng: 18.2339522 },
    { lat: 49.2062064, lng: 18.234732 },
    { lat: 49.2062538, lng: 18.2354101 },
    { lat: 49.20628, lng: 18.2356352 },
    { lat: 49.206363, lng: 18.2363411 },
    { lat: 49.2063672, lng: 18.2363779 },
    { lat: 49.2063989, lng: 18.2366584 },
    { lat: 49.2067166, lng: 18.2369066 },
    { lat: 49.2068511, lng: 18.2370696 },
    { lat: 49.2074617, lng: 18.2378118 },
    { lat: 49.2075017, lng: 18.237841 },
    { lat: 49.2077631, lng: 18.2380358 },
    { lat: 49.2078242, lng: 18.2380597 },
    { lat: 49.2079086, lng: 18.238093 },
    { lat: 49.2079916, lng: 18.238184 },
    { lat: 49.2081436, lng: 18.2383977 },
    { lat: 49.2081992, lng: 18.2385235 },
    { lat: 49.2082122, lng: 18.2386947 },
    { lat: 49.2076537, lng: 18.2394106 },
  ],
  Púchov: [
    { lat: 49.215976, lng: 18.306681 },
    { lat: 49.2159277, lng: 18.3065877 },
    { lat: 49.2158095, lng: 18.3064909 },
    { lat: 49.2156769, lng: 18.3063989 },
    { lat: 49.2156626, lng: 18.3063885 },
    { lat: 49.2155244, lng: 18.3063185 },
    { lat: 49.2153608, lng: 18.3062545 },
    { lat: 49.2150529, lng: 18.3061346 },
    { lat: 49.2149392, lng: 18.3060585 },
    { lat: 49.2146851, lng: 18.3059156 },
    { lat: 49.2143186, lng: 18.3056565 },
    { lat: 49.2141277, lng: 18.3055492 },
    { lat: 49.2138307, lng: 18.3053835 },
    { lat: 49.2134659, lng: 18.3052556 },
    { lat: 49.2133703, lng: 18.305179 },
    { lat: 49.213303, lng: 18.3051248 },
    { lat: 49.2131678, lng: 18.3049694 },
    { lat: 49.2131111, lng: 18.3048429 },
    { lat: 49.2129823, lng: 18.3047594 },
    { lat: 49.2127975, lng: 18.3047016 },
    { lat: 49.2126148, lng: 18.3045801 },
    { lat: 49.2125433, lng: 18.3045528 },
    { lat: 49.2123905, lng: 18.3044848 },
    { lat: 49.2121514, lng: 18.3044865 },
    { lat: 49.2121023, lng: 18.3044382 },
    { lat: 49.2119444, lng: 18.3042943 },
    { lat: 49.2117038, lng: 18.3039066 },
    { lat: 49.2115086, lng: 18.3037477 },
    { lat: 49.2114649, lng: 18.3037534 },
    { lat: 49.2112955, lng: 18.3033252 },
    { lat: 49.2106219, lng: 18.3016154 },
    { lat: 49.2094642, lng: 18.3005016 },
    { lat: 49.2080964, lng: 18.2999922 },
    { lat: 49.2080781, lng: 18.2999861 },
    { lat: 49.2076627, lng: 18.3001634 },
    { lat: 49.2070016, lng: 18.3004466 },
    { lat: 49.2067886, lng: 18.3006912 },
    { lat: 49.2067654, lng: 18.3007213 },
    { lat: 49.2061454, lng: 18.3008274 },
    { lat: 49.205672, lng: 18.3013404 },
    { lat: 49.2053128, lng: 18.3018747 },
    { lat: 49.2048592, lng: 18.3022381 },
    { lat: 49.204401, lng: 18.3029167 },
    { lat: 49.2042161, lng: 18.3025194 },
    { lat: 49.203741, lng: 18.3017105 },
    { lat: 49.2032982, lng: 18.3021241 },
    { lat: 49.2021112, lng: 18.3035864 },
    { lat: 49.2018634, lng: 18.3041969 },
    { lat: 49.2018966, lng: 18.3047106 },
    { lat: 49.2014826, lng: 18.305055 },
    { lat: 49.2013936, lng: 18.3051154 },
    { lat: 49.201325, lng: 18.3053649 },
    { lat: 49.2012314, lng: 18.3054241 },
    { lat: 49.2011758, lng: 18.3054573 },
    { lat: 49.2002157, lng: 18.3059199 },
    { lat: 49.2001767, lng: 18.3061901 },
    { lat: 49.1998488, lng: 18.3084931 },
    { lat: 49.1998785, lng: 18.3091249 },
    { lat: 49.1993749, lng: 18.3109102 },
    { lat: 49.1982659, lng: 18.311129 },
    { lat: 49.1969504, lng: 18.3113926 },
    { lat: 49.1960385, lng: 18.3116641 },
    { lat: 49.1955274, lng: 18.3110607 },
    { lat: 49.1954488, lng: 18.3109669 },
    { lat: 49.1949302, lng: 18.3103506 },
    { lat: 49.1944774, lng: 18.3107252 },
    { lat: 49.1944734, lng: 18.3107342 },
    { lat: 49.194752, lng: 18.3124029 },
    { lat: 49.1945666, lng: 18.3139308 },
    { lat: 49.194177, lng: 18.3150926 },
    { lat: 49.1938723, lng: 18.3151897 },
    { lat: 49.1936936, lng: 18.3154862 },
    { lat: 49.1934243, lng: 18.3155527 },
    { lat: 49.1930738, lng: 18.3154955 },
    { lat: 49.1924378, lng: 18.316775 },
    { lat: 49.1916651, lng: 18.3171184 },
    { lat: 49.190616, lng: 18.3166332 },
    { lat: 49.1898857, lng: 18.3167085 },
    { lat: 49.1893185, lng: 18.3168341 },
    { lat: 49.1891165, lng: 18.3168716 },
    { lat: 49.188832, lng: 18.3172937 },
    { lat: 49.1888198, lng: 18.317316 },
    { lat: 49.1877645, lng: 18.3189208 },
    { lat: 49.1873679, lng: 18.3189501 },
    { lat: 49.1871114, lng: 18.3189025 },
    { lat: 49.1867879, lng: 18.3188478 },
    { lat: 49.1865295, lng: 18.3188464 },
    { lat: 49.1859558, lng: 18.3190172 },
    { lat: 49.1857848, lng: 18.3190051 },
    { lat: 49.1851903, lng: 18.3202975 },
    { lat: 49.1844625, lng: 18.3208592 },
    { lat: 49.183994, lng: 18.3210555 },
    { lat: 49.1834632, lng: 18.3212865 },
    { lat: 49.183429, lng: 18.3213003 },
    { lat: 49.1831668, lng: 18.3213936 },
    { lat: 49.1822923, lng: 18.3208451 },
    { lat: 49.1817249, lng: 18.320805 },
    { lat: 49.1813048, lng: 18.3205946 },
    { lat: 49.1809515, lng: 18.3206325 },
    { lat: 49.1798413, lng: 18.3202692 },
    { lat: 49.1790978, lng: 18.3198427 },
    { lat: 49.176801, lng: 18.3198449 },
    { lat: 49.1767565, lng: 18.3198377 },
    { lat: 49.1766881, lng: 18.3200258 },
    { lat: 49.1765499, lng: 18.3200561 },
    { lat: 49.1756943, lng: 18.3198862 },
    { lat: 49.1754762, lng: 18.3196578 },
    { lat: 49.1754285, lng: 18.319611 },
    { lat: 49.1753792, lng: 18.3196178 },
    { lat: 49.174837, lng: 18.3195651 },
    { lat: 49.1738009, lng: 18.3196168 },
    { lat: 49.1735091, lng: 18.3196449 },
    { lat: 49.1734466, lng: 18.3196555 },
    { lat: 49.1732812, lng: 18.3196648 },
    { lat: 49.1727644, lng: 18.3196232 },
    { lat: 49.1724148, lng: 18.3195637 },
    { lat: 49.1720203, lng: 18.3194683 },
    { lat: 49.1704551, lng: 18.3192603 },
    { lat: 49.1689291, lng: 18.318742 },
    { lat: 49.1687088, lng: 18.3186769 },
    { lat: 49.1679053, lng: 18.3182276 },
    { lat: 49.1670347, lng: 18.3162591 },
    { lat: 49.1669913, lng: 18.3156459 },
    { lat: 49.1656851, lng: 18.3132304 },
    { lat: 49.1655063, lng: 18.3132418 },
    { lat: 49.1650787, lng: 18.3132802 },
    { lat: 49.1645617, lng: 18.3098709 },
    { lat: 49.1646075, lng: 18.3091194 },
    { lat: 49.1646207, lng: 18.3090323 },
    { lat: 49.1646869, lng: 18.3085242 },
    { lat: 49.1647786, lng: 18.3082271 },
    { lat: 49.164709, lng: 18.3080732 },
    { lat: 49.1643085, lng: 18.3078119 },
    { lat: 49.1638751, lng: 18.3077462 },
    { lat: 49.1636893, lng: 18.3078732 },
    { lat: 49.1635804, lng: 18.3079842 },
    { lat: 49.1632678, lng: 18.3084054 },
    { lat: 49.1630427, lng: 18.309301 },
    { lat: 49.1629327, lng: 18.309934 },
    { lat: 49.1627043, lng: 18.3100045 },
    { lat: 49.1624742, lng: 18.3098861 },
    { lat: 49.1620846, lng: 18.3098787 },
    { lat: 49.1617264, lng: 18.3092455 },
    { lat: 49.1616772, lng: 18.3091708 },
    { lat: 49.1611647, lng: 18.3079975 },
    { lat: 49.1608943, lng: 18.307783 },
    { lat: 49.1606378, lng: 18.3073532 },
    { lat: 49.1605599, lng: 18.3072916 },
    { lat: 49.1606507, lng: 18.3062979 },
    { lat: 49.1607615, lng: 18.3059374 },
    { lat: 49.1610898, lng: 18.3054959 },
    { lat: 49.1611389, lng: 18.305326 },
    { lat: 49.1612394, lng: 18.3051674 },
    { lat: 49.1611253, lng: 18.3048233 },
    { lat: 49.1609691, lng: 18.303888 },
    { lat: 49.1608277, lng: 18.3033982 },
    { lat: 49.1607838, lng: 18.3032181 },
    { lat: 49.1607322, lng: 18.3030821 },
    { lat: 49.1606949, lng: 18.3028606 },
    { lat: 49.1605505, lng: 18.3026009 },
    { lat: 49.1602784, lng: 18.3027374 },
    { lat: 49.1605146, lng: 18.3019402 },
    { lat: 49.1604229, lng: 18.3018739 },
    { lat: 49.1603887, lng: 18.3018878 },
    { lat: 49.1603732, lng: 18.3018516 },
    { lat: 49.1604217, lng: 18.3018315 },
    { lat: 49.1603888, lng: 18.301749 },
    { lat: 49.160289, lng: 18.3017 },
    { lat: 49.1602441, lng: 18.3016844 },
    { lat: 49.1603453, lng: 18.3011148 },
    { lat: 49.1604747, lng: 18.3007263 },
    { lat: 49.160471, lng: 18.3004996 },
    { lat: 49.1604733, lng: 18.3002993 },
    { lat: 49.1603309, lng: 18.3002555 },
    { lat: 49.1602062, lng: 18.3008091 },
    { lat: 49.1600785, lng: 18.3008245 },
    { lat: 49.1600346, lng: 18.3008042 },
    { lat: 49.1600163, lng: 18.3000567 },
    { lat: 49.159869, lng: 18.2991814 },
    { lat: 49.1597906, lng: 18.2988892 },
    { lat: 49.1597204, lng: 18.2989259 },
    { lat: 49.1595484, lng: 18.299016 },
    { lat: 49.1593257, lng: 18.2988288 },
    { lat: 49.1592491, lng: 18.2989101 },
    { lat: 49.158919, lng: 18.2992611 },
    { lat: 49.1587312, lng: 18.2994117 },
    { lat: 49.1581145, lng: 18.3002232 },
    { lat: 49.1578195, lng: 18.3002931 },
    { lat: 49.1578128, lng: 18.3002948 },
    { lat: 49.1576138, lng: 18.3003484 },
    { lat: 49.1573636, lng: 18.3000314 },
    { lat: 49.1573293, lng: 18.2998216 },
    { lat: 49.1571733, lng: 18.2998966 },
    { lat: 49.156835, lng: 18.30006 },
    { lat: 49.1565259, lng: 18.30026 },
    { lat: 49.1563168, lng: 18.3003951 },
    { lat: 49.1557826, lng: 18.3005873 },
    { lat: 49.1555803, lng: 18.3005599 },
    { lat: 49.155277, lng: 18.3011662 },
    { lat: 49.1552619, lng: 18.3015991 },
    { lat: 49.1552375, lng: 18.3019506 },
    { lat: 49.1549322, lng: 18.3018153 },
    { lat: 49.1542054, lng: 18.3014906 },
    { lat: 49.154526, lng: 18.3008501 },
    { lat: 49.1546952, lng: 18.3004085 },
    { lat: 49.1547984, lng: 18.3000771 },
    { lat: 49.154758, lng: 18.2998382 },
    { lat: 49.1544669, lng: 18.2993959 },
    { lat: 49.1537008, lng: 18.3007397 },
    { lat: 49.1533064, lng: 18.300989 },
    { lat: 49.1531298, lng: 18.3006587 },
    { lat: 49.153097, lng: 18.3006156 },
    { lat: 49.1529075, lng: 18.3004972 },
    { lat: 49.1526554, lng: 18.3002457 },
    { lat: 49.1526218, lng: 18.2998886 },
    { lat: 49.152964, lng: 18.2990523 },
    { lat: 49.1526405, lng: 18.2986752 },
    { lat: 49.152377, lng: 18.2983816 },
    { lat: 49.152317, lng: 18.2985191 },
    { lat: 49.152283, lng: 18.2985967 },
    { lat: 49.1521971, lng: 18.2987943 },
    { lat: 49.1518539, lng: 18.2995356 },
    { lat: 49.1518495, lng: 18.2997576 },
    { lat: 49.1517866, lng: 18.3002845 },
    { lat: 49.1515839, lng: 18.3003109 },
    { lat: 49.1512964, lng: 18.3001733 },
    { lat: 49.1509475, lng: 18.299907 },
    { lat: 49.1505469, lng: 18.2993827 },
    { lat: 49.1505203, lng: 18.2993706 },
    { lat: 49.1502259, lng: 18.2992725 },
    { lat: 49.149739, lng: 18.2992405 },
    { lat: 49.1495033, lng: 18.2990413 },
    { lat: 49.1490765, lng: 18.2987748 },
    { lat: 49.1491102, lng: 18.2985908 },
    { lat: 49.1485018, lng: 18.2982037 },
    { lat: 49.1483615, lng: 18.2986373 },
    { lat: 49.1481324, lng: 18.2987175 },
    { lat: 49.1478596, lng: 18.298721 },
    { lat: 49.1477303, lng: 18.2986883 },
    { lat: 49.1475644, lng: 18.2985282 },
    { lat: 49.1474616, lng: 18.2984836 },
    { lat: 49.1474246, lng: 18.2984475 },
    { lat: 49.1472827, lng: 18.298313 },
    { lat: 49.1468478, lng: 18.2978993 },
    { lat: 49.1467418, lng: 18.2978413 },
    { lat: 49.146608, lng: 18.2981301 },
    { lat: 49.1462371, lng: 18.298538 },
    { lat: 49.146138, lng: 18.2985999 },
    { lat: 49.1457694, lng: 18.2985664 },
    { lat: 49.1457174, lng: 18.2985649 },
    { lat: 49.1454629, lng: 18.298934 },
    { lat: 49.1454244, lng: 18.2989732 },
    { lat: 49.1452031, lng: 18.2992904 },
    { lat: 49.1445344, lng: 18.2986135 },
    { lat: 49.1441023, lng: 18.2985133 },
    { lat: 49.1439059, lng: 18.298189 },
    { lat: 49.1437391, lng: 18.298011 },
    { lat: 49.1436885, lng: 18.2979745 },
    { lat: 49.1439357, lng: 18.2974961 },
    { lat: 49.1437204, lng: 18.2973581 },
    { lat: 49.1436584, lng: 18.2973181 },
    { lat: 49.1432956, lng: 18.2970853 },
    { lat: 49.142426, lng: 18.2965276 },
    { lat: 49.1423804, lng: 18.2964983 },
    { lat: 49.1421633, lng: 18.2964695 },
    { lat: 49.14202, lng: 18.29645 },
    { lat: 49.1418325, lng: 18.2964246 },
    { lat: 49.1415033, lng: 18.2964112 },
    { lat: 49.1412889, lng: 18.296348 },
    { lat: 49.1412373, lng: 18.2962545 },
    { lat: 49.1412185, lng: 18.2962206 },
    { lat: 49.1411413, lng: 18.2963128 },
    { lat: 49.1409444, lng: 18.2963216 },
    { lat: 49.1407813, lng: 18.296171 },
    { lat: 49.1407493, lng: 18.2961413 },
    { lat: 49.1407722, lng: 18.2959877 },
    { lat: 49.140782, lng: 18.2959418 },
    { lat: 49.1407938, lng: 18.2958905 },
    { lat: 49.1405979, lng: 18.2958377 },
    { lat: 49.1404213, lng: 18.2957654 },
    { lat: 49.1400824, lng: 18.2954962 },
    { lat: 49.1400974, lng: 18.2947604 },
    { lat: 49.1400705, lng: 18.2947623 },
    { lat: 49.1398119, lng: 18.2947779 },
    { lat: 49.1397204, lng: 18.2947752 },
    { lat: 49.1394766, lng: 18.2948341 },
    { lat: 49.1392415, lng: 18.2950682 },
    { lat: 49.1390832, lng: 18.2952033 },
    { lat: 49.1390521, lng: 18.29523 },
    { lat: 49.1389842, lng: 18.2953481 },
    { lat: 49.1388514, lng: 18.2954531 },
    { lat: 49.138834, lng: 18.2954048 },
    { lat: 49.1388125, lng: 18.2953438 },
    { lat: 49.1388017, lng: 18.295313 },
    { lat: 49.1388111, lng: 18.2952788 },
    { lat: 49.1388333, lng: 18.2951923 },
    { lat: 49.1388037, lng: 18.295087 },
    { lat: 49.1387995, lng: 18.2950735 },
    { lat: 49.138771, lng: 18.2949684 },
    { lat: 49.1387129, lng: 18.2948152 },
    { lat: 49.1385976, lng: 18.2946123 },
    { lat: 49.1385858, lng: 18.2945429 },
    { lat: 49.1385944, lng: 18.2945345 },
    { lat: 49.1386814, lng: 18.2944565 },
    { lat: 49.1386607, lng: 18.2942111 },
    { lat: 49.1386614, lng: 18.2941612 },
    { lat: 49.1386638, lng: 18.2941028 },
    { lat: 49.1386756, lng: 18.2936509 },
    { lat: 49.1386791, lng: 18.2934933 },
    { lat: 49.138684, lng: 18.2934592 },
    { lat: 49.1386854, lng: 18.2934457 },
    { lat: 49.1386902, lng: 18.293409 },
    { lat: 49.1387455, lng: 18.293008 },
    { lat: 49.1387469, lng: 18.2929934 },
    { lat: 49.1387496, lng: 18.2929797 },
    { lat: 49.1387553, lng: 18.2929415 },
    { lat: 49.1387956, lng: 18.2926757 },
    { lat: 49.1388362, lng: 18.2923746 },
    { lat: 49.1388513, lng: 18.2922818 },
    { lat: 49.1388674, lng: 18.2921849 },
    { lat: 49.1389128, lng: 18.29181 },
    { lat: 49.1389284, lng: 18.2916869 },
    { lat: 49.1389408, lng: 18.2915862 },
    { lat: 49.1386986, lng: 18.2903288 },
    { lat: 49.1386907, lng: 18.2902871 },
    { lat: 49.138686, lng: 18.290265 },
    { lat: 49.138674, lng: 18.2902129 },
    { lat: 49.1386609, lng: 18.2901579 },
    { lat: 49.1386041, lng: 18.2899731 },
    { lat: 49.1384031, lng: 18.2892475 },
    { lat: 49.1383686, lng: 18.289074 },
    { lat: 49.1382466, lng: 18.2884693 },
    { lat: 49.1381172, lng: 18.2878942 },
    { lat: 49.1380837, lng: 18.2876788 },
    { lat: 49.1380813, lng: 18.2876363 },
    { lat: 49.1380803, lng: 18.2876184 },
    { lat: 49.1380725, lng: 18.2874801 },
    { lat: 49.1380697, lng: 18.2874108 },
    { lat: 49.138097, lng: 18.2868154 },
    { lat: 49.1380994, lng: 18.2867772 },
    { lat: 49.1381011, lng: 18.2867662 },
    { lat: 49.1381391, lng: 18.2864784 },
    { lat: 49.1380814, lng: 18.2864151 },
    { lat: 49.1378803, lng: 18.2863115 },
    { lat: 49.1378741, lng: 18.286301 },
    { lat: 49.1378206, lng: 18.2862143 },
    { lat: 49.1378056, lng: 18.2860264 },
    { lat: 49.1378396, lng: 18.2858684 },
    { lat: 49.1378484, lng: 18.2857237 },
    { lat: 49.1377555, lng: 18.2855575 },
    { lat: 49.1376072, lng: 18.2853291 },
    { lat: 49.1372553, lng: 18.2850284 },
    { lat: 49.1371734, lng: 18.2849582 },
    { lat: 49.1370583, lng: 18.2848367 },
    { lat: 49.1367619, lng: 18.2848016 },
    { lat: 49.1366381, lng: 18.2847868 },
    { lat: 49.1365122, lng: 18.2845555 },
    { lat: 49.136492, lng: 18.2845173 },
    { lat: 49.1363386, lng: 18.2841568 },
    { lat: 49.1361947, lng: 18.2839252 },
    { lat: 49.1358846, lng: 18.2834064 },
    { lat: 49.1356088, lng: 18.2829779 },
    { lat: 49.135302, lng: 18.2826573 },
    { lat: 49.1349014, lng: 18.2822142 },
    { lat: 49.134662, lng: 18.2817902 },
    { lat: 49.1344948, lng: 18.2815455 },
    { lat: 49.134037, lng: 18.2812505 },
    { lat: 49.1338766, lng: 18.2810474 },
    { lat: 49.1336256, lng: 18.2809183 },
    { lat: 49.1338036, lng: 18.2800326 },
    { lat: 49.133527, lng: 18.2795266 },
    { lat: 49.1335008, lng: 18.2795221 },
    { lat: 49.1330795, lng: 18.2788741 },
    { lat: 49.1332109, lng: 18.278081 },
    { lat: 49.1330849, lng: 18.2778666 },
    { lat: 49.1327867, lng: 18.277822 },
    { lat: 49.1326543, lng: 18.2776738 },
    { lat: 49.1325037, lng: 18.2775056 },
    { lat: 49.1322382, lng: 18.2774381 },
    { lat: 49.132069, lng: 18.2771388 },
    { lat: 49.1319521, lng: 18.2770461 },
    { lat: 49.1322968, lng: 18.2767149 },
    { lat: 49.1324364, lng: 18.2762517 },
    { lat: 49.1324657, lng: 18.2758919 },
    { lat: 49.1323697, lng: 18.2758086 },
    { lat: 49.1318357, lng: 18.2753421 },
    { lat: 49.131502, lng: 18.2751262 },
    { lat: 49.1312998, lng: 18.2748225 },
    { lat: 49.13106, lng: 18.2741701 },
    { lat: 49.1309979, lng: 18.2740114 },
    { lat: 49.1308623, lng: 18.2736647 },
    { lat: 49.1308385, lng: 18.2736021 },
    { lat: 49.1303579, lng: 18.2729825 },
    { lat: 49.1300196, lng: 18.272389 },
    { lat: 49.1299055, lng: 18.2723254 },
    { lat: 49.1298551, lng: 18.2723298 },
    { lat: 49.129545, lng: 18.2723746 },
    { lat: 49.1295382, lng: 18.2723721 },
    { lat: 49.1294932, lng: 18.272356 },
    { lat: 49.1291778, lng: 18.2722431 },
    { lat: 49.1291107, lng: 18.2722137 },
    { lat: 49.1287264, lng: 18.272043 },
    { lat: 49.1286523, lng: 18.2720102 },
    { lat: 49.1283576, lng: 18.2718438 },
    { lat: 49.1281245, lng: 18.2717114 },
    { lat: 49.1280209, lng: 18.2716752 },
    { lat: 49.1278021, lng: 18.2715985 },
    { lat: 49.1276811, lng: 18.2715718 },
    { lat: 49.1276486, lng: 18.2715752 },
    { lat: 49.1276126, lng: 18.2715799 },
    { lat: 49.1271658, lng: 18.2716377 },
    { lat: 49.1270606, lng: 18.271614 },
    { lat: 49.1270515, lng: 18.2716113 },
    { lat: 49.1268713, lng: 18.2715553 },
    { lat: 49.1236694, lng: 18.2692588 },
    { lat: 49.1218368, lng: 18.2693114 },
    { lat: 49.1215222, lng: 18.2693541 },
    { lat: 49.1212426, lng: 18.2693188 },
    { lat: 49.1212372, lng: 18.269882 },
    { lat: 49.1211128, lng: 18.2709155 },
    { lat: 49.1211154, lng: 18.2714231 },
    { lat: 49.1213934, lng: 18.2714908 },
    { lat: 49.1215142, lng: 18.2720113 },
    { lat: 49.1215072, lng: 18.2722877 },
    { lat: 49.1216038, lng: 18.2724599 },
    { lat: 49.121785, lng: 18.2735315 },
    { lat: 49.1219365, lng: 18.2736165 },
    { lat: 49.1222182, lng: 18.2736711 },
    { lat: 49.1224534, lng: 18.2744613 },
    { lat: 49.1224953, lng: 18.2758653 },
    { lat: 49.1225717, lng: 18.2766382 },
    { lat: 49.1226472, lng: 18.2771184 },
    { lat: 49.1227205, lng: 18.2773184 },
    { lat: 49.1228475, lng: 18.2773684 },
    { lat: 49.1230087, lng: 18.2775071 },
    { lat: 49.1229455, lng: 18.2777854 },
    { lat: 49.1229667, lng: 18.278061 },
    { lat: 49.1230041, lng: 18.2783659 },
    { lat: 49.1228983, lng: 18.2786913 },
    { lat: 49.1228756, lng: 18.2788881 },
    { lat: 49.1228174, lng: 18.2791565 },
    { lat: 49.1228574, lng: 18.2792846 },
    { lat: 49.12276, lng: 18.2794387 },
    { lat: 49.1227396, lng: 18.2797396 },
    { lat: 49.1227896, lng: 18.2798038 },
    { lat: 49.1227254, lng: 18.280167 },
    { lat: 49.1226448, lng: 18.2802381 },
    { lat: 49.1219225, lng: 18.2801129 },
    { lat: 49.1212761, lng: 18.2809956 },
    { lat: 49.1213535, lng: 18.281428 },
    { lat: 49.1212747, lng: 18.2819716 },
    { lat: 49.1215769, lng: 18.2823481 },
    { lat: 49.1218565, lng: 18.2825874 },
    { lat: 49.1222484, lng: 18.2827127 },
    { lat: 49.1223573, lng: 18.284288 },
    { lat: 49.122376, lng: 18.2845599 },
    { lat: 49.1224036, lng: 18.2849491 },
    { lat: 49.1224191, lng: 18.2850829 },
    { lat: 49.1223726, lng: 18.2853591 },
    { lat: 49.1223621, lng: 18.2855145 },
    { lat: 49.1223576, lng: 18.2855954 },
    { lat: 49.1223547, lng: 18.2856838 },
    { lat: 49.1222587, lng: 18.2858628 },
    { lat: 49.122237, lng: 18.2859391 },
    { lat: 49.1221746, lng: 18.2860315 },
    { lat: 49.1221569, lng: 18.2860569 },
    { lat: 49.1221173, lng: 18.2861154 },
    { lat: 49.1220944, lng: 18.2861303 },
    { lat: 49.1219658, lng: 18.2863702 },
    { lat: 49.1219298, lng: 18.2863531 },
    { lat: 49.1219063, lng: 18.2863364 },
    { lat: 49.1217596, lng: 18.2862151 },
    { lat: 49.1215412, lng: 18.2860437 },
    { lat: 49.1213434, lng: 18.2858362 },
    { lat: 49.1213069, lng: 18.285829 },
    { lat: 49.1212708, lng: 18.2863696 },
    { lat: 49.1211833, lng: 18.2871217 },
    { lat: 49.1211552, lng: 18.2871233 },
    { lat: 49.120874, lng: 18.2870174 },
    { lat: 49.1206511, lng: 18.2869456 },
    { lat: 49.1205814, lng: 18.2871901 },
    { lat: 49.1204692, lng: 18.2875833 },
    { lat: 49.1202913, lng: 18.2880098 },
    { lat: 49.1204633, lng: 18.2881999 },
    { lat: 49.1207397, lng: 18.2884011 },
    { lat: 49.1206742, lng: 18.2886798 },
    { lat: 49.1206976, lng: 18.2887337 },
    { lat: 49.1207066, lng: 18.2887527 },
    { lat: 49.1206924, lng: 18.288761 },
    { lat: 49.1206862, lng: 18.2887703 },
    { lat: 49.1207047, lng: 18.2888199 },
    { lat: 49.120655, lng: 18.2889188 },
    { lat: 49.1205916, lng: 18.2889755 },
    { lat: 49.1203227, lng: 18.2892093 },
    { lat: 49.1202494, lng: 18.2892306 },
    { lat: 49.1202276, lng: 18.2892637 },
    { lat: 49.1201097, lng: 18.2893345 },
    { lat: 49.1200043, lng: 18.289525 },
    { lat: 49.1198777, lng: 18.2896816 },
    { lat: 49.119785, lng: 18.2897368 },
    { lat: 49.1197135, lng: 18.2897508 },
    { lat: 49.1195853, lng: 18.2898801 },
    { lat: 49.1195181, lng: 18.2900668 },
    { lat: 49.1194852, lng: 18.290126 },
    { lat: 49.119431, lng: 18.290165 },
    { lat: 49.1193626, lng: 18.2902508 },
    { lat: 49.1192743, lng: 18.2902868 },
    { lat: 49.119164, lng: 18.2902514 },
    { lat: 49.1191339, lng: 18.2902587 },
    { lat: 49.1190371, lng: 18.2904007 },
    { lat: 49.1190069, lng: 18.2904666 },
    { lat: 49.1189566, lng: 18.2904962 },
    { lat: 49.118828, lng: 18.2905764 },
    { lat: 49.1187667, lng: 18.2906895 },
    { lat: 49.118663, lng: 18.2907902 },
    { lat: 49.1186731, lng: 18.2908905 },
    { lat: 49.1186506, lng: 18.2910314 },
    { lat: 49.1186436, lng: 18.2910885 },
    { lat: 49.1185432, lng: 18.2912284 },
    { lat: 49.1184591, lng: 18.2912195 },
    { lat: 49.1184452, lng: 18.2912911 },
    { lat: 49.1184519, lng: 18.2913311 },
    { lat: 49.1185212, lng: 18.2914776 },
    { lat: 49.1185384, lng: 18.2917034 },
    { lat: 49.1185055, lng: 18.2919007 },
    { lat: 49.1184248, lng: 18.2919714 },
    { lat: 49.1184429, lng: 18.2920927 },
    { lat: 49.1184327, lng: 18.2921331 },
    { lat: 49.1184277, lng: 18.2921443 },
    { lat: 49.1183486, lng: 18.2921609 },
    { lat: 49.1183374, lng: 18.2921822 },
    { lat: 49.1183318, lng: 18.2923239 },
    { lat: 49.1183197, lng: 18.2923489 },
    { lat: 49.1182446, lng: 18.292398 },
    { lat: 49.1181558, lng: 18.2923836 },
    { lat: 49.1181008, lng: 18.2924473 },
    { lat: 49.1180873, lng: 18.2925701 },
    { lat: 49.1181504, lng: 18.2926496 },
    { lat: 49.1181051, lng: 18.2927864 },
    { lat: 49.118038, lng: 18.292818 },
    { lat: 49.1180415, lng: 18.2928581 },
    { lat: 49.1180821, lng: 18.2929385 },
    { lat: 49.1180624, lng: 18.2930297 },
    { lat: 49.1180736, lng: 18.2930691 },
    { lat: 49.1181337, lng: 18.293154 },
    { lat: 49.1181512, lng: 18.2932054 },
    { lat: 49.1181399, lng: 18.2932436 },
    { lat: 49.1181103, lng: 18.293281 },
    { lat: 49.1180709, lng: 18.2932818 },
    { lat: 49.1180426, lng: 18.2932588 },
    { lat: 49.1180208, lng: 18.2932934 },
    { lat: 49.1180012, lng: 18.2933676 },
    { lat: 49.1180605, lng: 18.2934776 },
    { lat: 49.1180362, lng: 18.2935489 },
    { lat: 49.1179576, lng: 18.2936742 },
    { lat: 49.1179582, lng: 18.2937244 },
    { lat: 49.1179304, lng: 18.2937518 },
    { lat: 49.117838, lng: 18.2937736 },
    { lat: 49.1178312, lng: 18.2938951 },
    { lat: 49.1177642, lng: 18.2939672 },
    { lat: 49.1177195, lng: 18.2941148 },
    { lat: 49.1176825, lng: 18.2941994 },
    { lat: 49.117662, lng: 18.2942766 },
    { lat: 49.1176829, lng: 18.2943469 },
    { lat: 49.1176676, lng: 18.294376 },
    { lat: 49.1176407, lng: 18.2943781 },
    { lat: 49.1176148, lng: 18.2943786 },
    { lat: 49.1175996, lng: 18.2943903 },
    { lat: 49.1175983, lng: 18.2944069 },
    { lat: 49.117607, lng: 18.2944612 },
    { lat: 49.1176651, lng: 18.2945925 },
    { lat: 49.1177006, lng: 18.2947423 },
    { lat: 49.117733, lng: 18.2947572 },
    { lat: 49.1177393, lng: 18.2948263 },
    { lat: 49.1176299, lng: 18.2949682 },
    { lat: 49.1177007, lng: 18.2951241 },
    { lat: 49.1176166, lng: 18.2952352 },
    { lat: 49.1176197, lng: 18.2952687 },
    { lat: 49.1176614, lng: 18.2953302 },
    { lat: 49.1176023, lng: 18.2955022 },
    { lat: 49.1176072, lng: 18.2955293 },
    { lat: 49.11766, lng: 18.2956239 },
    { lat: 49.117658, lng: 18.2956689 },
    { lat: 49.1176404, lng: 18.2958177 },
    { lat: 49.1176219, lng: 18.2959513 },
    { lat: 49.1176301, lng: 18.2959959 },
    { lat: 49.1176761, lng: 18.2960119 },
    { lat: 49.1176931, lng: 18.2959733 },
    { lat: 49.1176978, lng: 18.2959163 },
    { lat: 49.1177236, lng: 18.2959146 },
    { lat: 49.1177481, lng: 18.2959677 },
    { lat: 49.1178227, lng: 18.2960306 },
    { lat: 49.1179131, lng: 18.2961139 },
    { lat: 49.1179223, lng: 18.2961564 },
    { lat: 49.1179059, lng: 18.2961861 },
    { lat: 49.1178025, lng: 18.2962747 },
    { lat: 49.117789, lng: 18.2963325 },
    { lat: 49.1177777, lng: 18.2964357 },
    { lat: 49.1177726, lng: 18.2964837 },
    { lat: 49.117709, lng: 18.2965374 },
    { lat: 49.1176743, lng: 18.2965815 },
    { lat: 49.1175816, lng: 18.2966581 },
    { lat: 49.1175771, lng: 18.2967403 },
    { lat: 49.1176425, lng: 18.29684 },
    { lat: 49.117589, lng: 18.2969523 },
    { lat: 49.1175423, lng: 18.2970436 },
    { lat: 49.1175479, lng: 18.2970835 },
    { lat: 49.1175797, lng: 18.2971277 },
    { lat: 49.1176134, lng: 18.2971841 },
    { lat: 49.1176288, lng: 18.2972372 },
    { lat: 49.1175709, lng: 18.2972902 },
    { lat: 49.1175221, lng: 18.2972854 },
    { lat: 49.1174075, lng: 18.2972751 },
    { lat: 49.1173977, lng: 18.2973412 },
    { lat: 49.1174249, lng: 18.2973845 },
    { lat: 49.1174107, lng: 18.2974719 },
    { lat: 49.1173482, lng: 18.297504 },
    { lat: 49.1173131, lng: 18.2976406 },
    { lat: 49.117319, lng: 18.2976879 },
    { lat: 49.117337, lng: 18.2977062 },
    { lat: 49.1173727, lng: 18.2977806 },
    { lat: 49.1173649, lng: 18.2978234 },
    { lat: 49.1173027, lng: 18.2978794 },
    { lat: 49.1172875, lng: 18.2979086 },
    { lat: 49.1172195, lng: 18.2980455 },
    { lat: 49.117229, lng: 18.2981534 },
    { lat: 49.1172196, lng: 18.298207 },
    { lat: 49.1171605, lng: 18.29826 },
    { lat: 49.1171401, lng: 18.2983175 },
    { lat: 49.1170931, lng: 18.2984049 },
    { lat: 49.1170873, lng: 18.2985225 },
    { lat: 49.1170332, lng: 18.2985434 },
    { lat: 49.1170151, lng: 18.298584 },
    { lat: 49.1170179, lng: 18.2986115 },
    { lat: 49.1170357, lng: 18.2986868 },
    { lat: 49.1170284, lng: 18.2987395 },
    { lat: 49.1170039, lng: 18.2987448 },
    { lat: 49.1169394, lng: 18.298703 },
    { lat: 49.1168569, lng: 18.2987202 },
    { lat: 49.1168327, lng: 18.2988512 },
    { lat: 49.1168284, lng: 18.2988945 },
    { lat: 49.1168552, lng: 18.2989682 },
    { lat: 49.1168503, lng: 18.2990042 },
    { lat: 49.1168553, lng: 18.2990928 },
    { lat: 49.1168644, lng: 18.299172 },
    { lat: 49.1168744, lng: 18.2992301 },
    { lat: 49.1168604, lng: 18.2992636 },
    { lat: 49.1167611, lng: 18.2992629 },
    { lat: 49.1167511, lng: 18.2993063 },
    { lat: 49.1168011, lng: 18.2994129 },
    { lat: 49.1168309, lng: 18.2995426 },
    { lat: 49.1168366, lng: 18.2996045 },
    { lat: 49.1167352, lng: 18.2996469 },
    { lat: 49.1167497, lng: 18.2996816 },
    { lat: 49.1168163, lng: 18.2997426 },
    { lat: 49.1168344, lng: 18.2997642 },
    { lat: 49.1167501, lng: 18.2998903 },
    { lat: 49.1166669, lng: 18.3000164 },
    { lat: 49.1165748, lng: 18.3001638 },
    { lat: 49.1165012, lng: 18.3002987 },
    { lat: 49.1164255, lng: 18.3004188 },
    { lat: 49.1163499, lng: 18.3005389 },
    { lat: 49.1162953, lng: 18.3006699 },
    { lat: 49.1162417, lng: 18.3008006 },
    { lat: 49.1161733, lng: 18.3010101 },
    { lat: 49.1161182, lng: 18.3012137 },
    { lat: 49.1160608, lng: 18.3014786 },
    { lat: 49.1159956, lng: 18.3017022 },
    { lat: 49.1159548, lng: 18.3018378 },
    { lat: 49.1159126, lng: 18.3019706 },
    { lat: 49.1158874, lng: 18.3020859 },
    { lat: 49.1158645, lng: 18.302201 },
    { lat: 49.1158489, lng: 18.302384 },
    { lat: 49.1158435, lng: 18.3024503 },
    { lat: 49.1158327, lng: 18.3025781 },
    { lat: 49.1158417, lng: 18.3026972 },
    { lat: 49.1158532, lng: 18.3028222 },
    { lat: 49.1158655, lng: 18.302943 },
    { lat: 49.1158821, lng: 18.3030766 },
    { lat: 49.1158604, lng: 18.3030708 },
    { lat: 49.1157985, lng: 18.3030547 },
    { lat: 49.1157722, lng: 18.3030479 },
    { lat: 49.1157584, lng: 18.3030441 },
    { lat: 49.1158014, lng: 18.3031858 },
    { lat: 49.1158058, lng: 18.3032025 },
    { lat: 49.1157799, lng: 18.3034663 },
    { lat: 49.1157811, lng: 18.3036505 },
    { lat: 49.1157975, lng: 18.3038998 },
    { lat: 49.1158309, lng: 18.3039513 },
    { lat: 49.1157949, lng: 18.3040345 },
    { lat: 49.1157557, lng: 18.3041202 },
    { lat: 49.1157999, lng: 18.304345 },
    { lat: 49.1157699, lng: 18.3045138 },
    { lat: 49.1157754, lng: 18.3046321 },
    { lat: 49.1157362, lng: 18.304837 },
    { lat: 49.1157037, lng: 18.3051644 },
    { lat: 49.1156871, lng: 18.3052899 },
    { lat: 49.1156812, lng: 18.3055669 },
    { lat: 49.1156568, lng: 18.3057131 },
    { lat: 49.1156505, lng: 18.3057424 },
    { lat: 49.115642, lng: 18.3057921 },
    { lat: 49.1156365, lng: 18.3058159 },
    { lat: 49.1156357, lng: 18.3058221 },
    { lat: 49.1156377, lng: 18.3058578 },
    { lat: 49.1156447, lng: 18.3059614 },
    { lat: 49.115628, lng: 18.3060255 },
    { lat: 49.1155842, lng: 18.3061888 },
    { lat: 49.1155632, lng: 18.3062363 },
    { lat: 49.1155283, lng: 18.3062585 },
    { lat: 49.1154844, lng: 18.3062817 },
    { lat: 49.1153561, lng: 18.3063453 },
    { lat: 49.1152791, lng: 18.3063835 },
    { lat: 49.1152898, lng: 18.3064329 },
    { lat: 49.1152942, lng: 18.3064493 },
    { lat: 49.1153776, lng: 18.3067296 },
    { lat: 49.1154535, lng: 18.3069779 },
    { lat: 49.1155281, lng: 18.3072196 },
    { lat: 49.115645, lng: 18.3075941 },
    { lat: 49.1156881, lng: 18.3077402 },
    { lat: 49.1157519, lng: 18.3079523 },
    { lat: 49.115774, lng: 18.3080233 },
    { lat: 49.1158653, lng: 18.3083255 },
    { lat: 49.1158847, lng: 18.3083896 },
    { lat: 49.1159245, lng: 18.3085149 },
    { lat: 49.1159478, lng: 18.3085892 },
    { lat: 49.1159699, lng: 18.3086608 },
    { lat: 49.1159976, lng: 18.3087505 },
    { lat: 49.1160128, lng: 18.3088224 },
    { lat: 49.1160144, lng: 18.3088286 },
    { lat: 49.1161373, lng: 18.3093917 },
    { lat: 49.1161956, lng: 18.3093647 },
    { lat: 49.1162547, lng: 18.3096961 },
    { lat: 49.116297, lng: 18.309927 },
    { lat: 49.1162694, lng: 18.3099357 },
    { lat: 49.1153143, lng: 18.3103251 },
    { lat: 49.1152271, lng: 18.3103609 },
    { lat: 49.1148841, lng: 18.3105024 },
    { lat: 49.114719, lng: 18.310596 },
    { lat: 49.1146601, lng: 18.3106299 },
    { lat: 49.1146347, lng: 18.3106413 },
    { lat: 49.1144961, lng: 18.3107037 },
    { lat: 49.1144483, lng: 18.3107353 },
    { lat: 49.1144351, lng: 18.3107438 },
    { lat: 49.1144221, lng: 18.3107524 },
    { lat: 49.1144104, lng: 18.3107449 },
    { lat: 49.1144167, lng: 18.310756 },
    { lat: 49.1143557, lng: 18.3107958 },
    { lat: 49.1143384, lng: 18.3108076 },
    { lat: 49.1142936, lng: 18.3108364 },
    { lat: 49.1142752, lng: 18.3108484 },
    { lat: 49.1138214, lng: 18.3111464 },
    { lat: 49.113816, lng: 18.31115 },
    { lat: 49.1137388, lng: 18.3112009 },
    { lat: 49.1137341, lng: 18.3111988 },
    { lat: 49.1137063, lng: 18.3111837 },
    { lat: 49.1136899, lng: 18.3111747 },
    { lat: 49.1136736, lng: 18.311166 },
    { lat: 49.1135144, lng: 18.3110816 },
    { lat: 49.1134926, lng: 18.3110963 },
    { lat: 49.1134491, lng: 18.3111261 },
    { lat: 49.1134449, lng: 18.3111306 },
    { lat: 49.112422, lng: 18.3118414 },
    { lat: 49.1122841, lng: 18.3119367 },
    { lat: 49.1122502, lng: 18.311957 },
    { lat: 49.1122255, lng: 18.3120389 },
    { lat: 49.1122092, lng: 18.3120896 },
    { lat: 49.1121902, lng: 18.3121532 },
    { lat: 49.1121786, lng: 18.3121892 },
    { lat: 49.1121718, lng: 18.3122092 },
    { lat: 49.1121672, lng: 18.3122257 },
    { lat: 49.1121614, lng: 18.3122437 },
    { lat: 49.112107, lng: 18.3124195 },
    { lat: 49.1120983, lng: 18.3124456 },
    { lat: 49.1119246, lng: 18.31303 },
    { lat: 49.1117812, lng: 18.3135107 },
    { lat: 49.1112693, lng: 18.3130746 },
    { lat: 49.1111289, lng: 18.3129447 },
    { lat: 49.1109387, lng: 18.3127701 },
    { lat: 49.1108208, lng: 18.3126587 },
    { lat: 49.1106697, lng: 18.312521 },
    { lat: 49.1105258, lng: 18.3123881 },
    { lat: 49.1104675, lng: 18.3123359 },
    { lat: 49.1103094, lng: 18.3122117 },
    { lat: 49.1101192, lng: 18.31206 },
    { lat: 49.1099079, lng: 18.3118916 },
    { lat: 49.1097792, lng: 18.3117897 },
    { lat: 49.109772, lng: 18.3117838 },
    { lat: 49.1097579, lng: 18.3117723 },
    { lat: 49.1097496, lng: 18.3117648 },
    { lat: 49.1097188, lng: 18.3117396 },
    { lat: 49.109707, lng: 18.3117309 },
    { lat: 49.109694, lng: 18.31172 },
    { lat: 49.109622, lng: 18.3116638 },
    { lat: 49.1095016, lng: 18.3115686 },
    { lat: 49.1093718, lng: 18.3114659 },
    { lat: 49.1093222, lng: 18.3114266 },
    { lat: 49.1093057, lng: 18.311414 },
    { lat: 49.1091991, lng: 18.3113641 },
    { lat: 49.1090427, lng: 18.3112902 },
    { lat: 49.108892, lng: 18.311219 },
    { lat: 49.1087865, lng: 18.3111694 },
    { lat: 49.1087565, lng: 18.3111569 },
    { lat: 49.108675, lng: 18.3111335 },
    { lat: 49.1085443, lng: 18.311095 },
    { lat: 49.1083976, lng: 18.3110536 },
    { lat: 49.1082439, lng: 18.3110077 },
    { lat: 49.1081351, lng: 18.3109783 },
    { lat: 49.1080742, lng: 18.3109596 },
    { lat: 49.1079348, lng: 18.3109295 },
    { lat: 49.1077581, lng: 18.3108949 },
    { lat: 49.107652, lng: 18.3108741 },
    { lat: 49.1074557, lng: 18.3108356 },
    { lat: 49.107312, lng: 18.3108071 },
    { lat: 49.107146, lng: 18.3108042 },
    { lat: 49.10697, lng: 18.3108037 },
    { lat: 49.1069439, lng: 18.3108017 },
    { lat: 49.1068083, lng: 18.3107981 },
    { lat: 49.1067938, lng: 18.3108 },
    { lat: 49.1067755, lng: 18.3107965 },
    { lat: 49.1067576, lng: 18.3107975 },
    { lat: 49.1067057, lng: 18.3107987 },
    { lat: 49.1066679, lng: 18.3107872 },
    { lat: 49.1066278, lng: 18.3107762 },
    { lat: 49.1065592, lng: 18.3107614 },
    { lat: 49.106543, lng: 18.3107545 },
    { lat: 49.1064859, lng: 18.3107421 },
    { lat: 49.1062995, lng: 18.3106987 },
    { lat: 49.1061395, lng: 18.3106605 },
    { lat: 49.1060823, lng: 18.3106672 },
    { lat: 49.1060024, lng: 18.310669 },
    { lat: 49.1058596, lng: 18.3106778 },
    { lat: 49.1057923, lng: 18.3106662 },
    { lat: 49.1057748, lng: 18.3106552 },
    { lat: 49.1056922, lng: 18.3106303 },
    { lat: 49.1056566, lng: 18.3106203 },
    { lat: 49.1056373, lng: 18.3106173 },
    { lat: 49.1055687, lng: 18.3106017 },
    { lat: 49.1055061, lng: 18.3105917 },
    { lat: 49.1054858, lng: 18.3105922 },
    { lat: 49.1054676, lng: 18.3105891 },
    { lat: 49.1054044, lng: 18.310588 },
    { lat: 49.1053783, lng: 18.3105856 },
    { lat: 49.1053184, lng: 18.3105841 },
    { lat: 49.1052701, lng: 18.3105864 },
    { lat: 49.1050769, lng: 18.3105811 },
    { lat: 49.1049989, lng: 18.3105806 },
    { lat: 49.1048999, lng: 18.3105856 },
    { lat: 49.1048664, lng: 18.3105903 },
    { lat: 49.1048189, lng: 18.3105889 },
    { lat: 49.1047753, lng: 18.3105957 },
    { lat: 49.10463, lng: 18.3106006 },
    { lat: 49.1046062, lng: 18.310599 },
    { lat: 49.1045456, lng: 18.3106054 },
    { lat: 49.1044354, lng: 18.3106113 },
    { lat: 49.1042659, lng: 18.310626 },
    { lat: 49.104183, lng: 18.3106361 },
    { lat: 49.104165, lng: 18.310638 },
    { lat: 49.1040586, lng: 18.3106526 },
    { lat: 49.1040116, lng: 18.3106562 },
    { lat: 49.1039847, lng: 18.3106622 },
    { lat: 49.103941, lng: 18.3106672 },
    { lat: 49.1038393, lng: 18.3106837 },
    { lat: 49.1037322, lng: 18.3107041 },
    { lat: 49.1036573, lng: 18.3107174 },
    { lat: 49.1035624, lng: 18.3107339 },
    { lat: 49.1035446, lng: 18.3107397 },
    { lat: 49.1034628, lng: 18.3107507 },
    { lat: 49.1033925, lng: 18.3107629 },
    { lat: 49.1033201, lng: 18.3107793 },
    { lat: 49.1032839, lng: 18.3107977 },
    { lat: 49.1031847, lng: 18.3108391 },
    { lat: 49.1030085, lng: 18.3109151 },
    { lat: 49.1029542, lng: 18.3109346 },
    { lat: 49.102881, lng: 18.3109571 },
    { lat: 49.1028456, lng: 18.3109678 },
    { lat: 49.1026971, lng: 18.3110167 },
    { lat: 49.102542, lng: 18.3110682 },
    { lat: 49.1025166, lng: 18.3110776 },
    { lat: 49.1024977, lng: 18.3110813 },
    { lat: 49.1024699, lng: 18.3110902 },
    { lat: 49.1023839, lng: 18.3111046 },
    { lat: 49.1023089, lng: 18.311114 },
    { lat: 49.102193, lng: 18.3111406 },
    { lat: 49.1021486, lng: 18.3111529 },
    { lat: 49.1020732, lng: 18.3111778 },
    { lat: 49.1019654, lng: 18.311208 },
    { lat: 49.10185, lng: 18.3112416 },
    { lat: 49.1017535, lng: 18.3112687 },
    { lat: 49.10165, lng: 18.3112948 },
    { lat: 49.1016055, lng: 18.3113078 },
    { lat: 49.1015487, lng: 18.3113195 },
    { lat: 49.1014363, lng: 18.3113471 },
    { lat: 49.1013473, lng: 18.3113702 },
    { lat: 49.1013105, lng: 18.3113768 },
    { lat: 49.1012748, lng: 18.3113843 },
    { lat: 49.1012537, lng: 18.3113914 },
    { lat: 49.1012726, lng: 18.3117288 },
    { lat: 49.1012941, lng: 18.3121119 },
    { lat: 49.1012968, lng: 18.3121602 },
    { lat: 49.1012989, lng: 18.3121968 },
    { lat: 49.101306, lng: 18.3123239 },
    { lat: 49.1012896, lng: 18.3123509 },
    { lat: 49.1012576, lng: 18.3124051 },
    { lat: 49.1012318, lng: 18.3124484 },
    { lat: 49.1012265, lng: 18.3124554 },
    { lat: 49.1011822, lng: 18.3125294 },
    { lat: 49.1011689, lng: 18.3125531 },
    { lat: 49.1011617, lng: 18.3125661 },
    { lat: 49.1011229, lng: 18.3125782 },
    { lat: 49.1010872, lng: 18.3126273 },
    { lat: 49.1010034, lng: 18.3127404 },
    { lat: 49.1007634, lng: 18.3130678 },
    { lat: 49.1007612, lng: 18.3130697 },
    { lat: 49.1007117, lng: 18.3131526 },
    { lat: 49.1005225, lng: 18.3134795 },
    { lat: 49.100351, lng: 18.3139229 },
    { lat: 49.1003217, lng: 18.3140023 },
    { lat: 49.1003188, lng: 18.3140117 },
    { lat: 49.1002647, lng: 18.3141548 },
    { lat: 49.1002325, lng: 18.3142418 },
    { lat: 49.1002189, lng: 18.3142819 },
    { lat: 49.1002112, lng: 18.3143052 },
    { lat: 49.0999982, lng: 18.3149137 },
    { lat: 49.0997367, lng: 18.315622 },
    { lat: 49.0996864, lng: 18.3158314 },
    { lat: 49.0996644, lng: 18.3159214 },
    { lat: 49.0996488, lng: 18.3159854 },
    { lat: 49.099647, lng: 18.3159924 },
    { lat: 49.0996241, lng: 18.3160876 },
    { lat: 49.0995508, lng: 18.3164906 },
    { lat: 49.0995115, lng: 18.3168788 },
    { lat: 49.0995069, lng: 18.3169568 },
    { lat: 49.0994994, lng: 18.3170647 },
    { lat: 49.0994944, lng: 18.3171354 },
    { lat: 49.0994913, lng: 18.3171603 },
    { lat: 49.0994727, lng: 18.3172714 },
    { lat: 49.0994607, lng: 18.3173405 },
    { lat: 49.0994241, lng: 18.3173924 },
    { lat: 49.099419, lng: 18.317399 },
    { lat: 49.0993875, lng: 18.3174431 },
    { lat: 49.0994086, lng: 18.3174558 },
    { lat: 49.099418, lng: 18.3175851 },
    { lat: 49.0994469, lng: 18.3179795 },
    { lat: 49.0994656, lng: 18.3182314 },
    { lat: 49.0995167, lng: 18.3189236 },
    { lat: 49.0996385, lng: 18.3205749 },
    { lat: 49.0996546, lng: 18.3208012 },
    { lat: 49.0996827, lng: 18.3211816 },
    { lat: 49.0996761, lng: 18.3212447 },
    { lat: 49.0996715, lng: 18.3212843 },
    { lat: 49.0996659, lng: 18.3213245 },
    { lat: 49.0996589, lng: 18.3213809 },
    { lat: 49.0996519, lng: 18.3214365 },
    { lat: 49.0996368, lng: 18.3215486 },
    { lat: 49.0996333, lng: 18.3215689 },
    { lat: 49.0999491, lng: 18.3217071 },
    { lat: 49.1001998, lng: 18.3218125 },
    { lat: 49.1002379, lng: 18.3218671 },
    { lat: 49.1002391, lng: 18.3218682 },
    { lat: 49.1002416, lng: 18.3218744 },
    { lat: 49.1002711, lng: 18.3219362 },
    { lat: 49.1002747, lng: 18.3219426 },
    { lat: 49.1002851, lng: 18.3219654 },
    { lat: 49.1002777, lng: 18.3219744 },
    { lat: 49.1002766, lng: 18.3219758 },
    { lat: 49.1001203, lng: 18.3221659 },
    { lat: 49.1000554, lng: 18.3222958 },
    { lat: 49.0999855, lng: 18.3225188 },
    { lat: 49.0999609, lng: 18.3226428 },
    { lat: 49.0999525, lng: 18.3226935 },
    { lat: 49.0999468, lng: 18.3227735 },
    { lat: 49.0999569, lng: 18.3231758 },
    { lat: 49.0999952, lng: 18.3236777 },
    { lat: 49.0999956, lng: 18.3236845 },
    { lat: 49.0999957, lng: 18.3236866 },
    { lat: 49.1000056, lng: 18.3237833 },
    { lat: 49.1000057, lng: 18.3237858 },
    { lat: 49.1000061, lng: 18.3237922 },
    { lat: 49.1000313, lng: 18.324082 },
    { lat: 49.100024, lng: 18.3243123 },
    { lat: 49.1000053, lng: 18.3246252 },
    { lat: 49.1000058, lng: 18.3246544 },
    { lat: 49.1000181, lng: 18.324773 },
    { lat: 49.1000038, lng: 18.3249605 },
    { lat: 49.1, lng: 18.3250319 },
    { lat: 49.0999966, lng: 18.3251148 },
    { lat: 49.0999937, lng: 18.3253448 },
    { lat: 49.0999921, lng: 18.3255366 },
    { lat: 49.1000242, lng: 18.3257272 },
    { lat: 49.1000105, lng: 18.325786 },
    { lat: 49.1000215, lng: 18.3258597 },
    { lat: 49.1000018, lng: 18.3259719 },
    { lat: 49.0999957, lng: 18.3261035 },
    { lat: 49.1000527, lng: 18.3264378 },
    { lat: 49.1000775, lng: 18.3269624 },
    { lat: 49.1001217, lng: 18.3271255 },
    { lat: 49.1001484, lng: 18.3272822 },
    { lat: 49.100166, lng: 18.3273357 },
    { lat: 49.1003564, lng: 18.3275301 },
    { lat: 49.1005676, lng: 18.3276768 },
    { lat: 49.1008713, lng: 18.3278624 },
    { lat: 49.1009345, lng: 18.3279233 },
    { lat: 49.1010638, lng: 18.3280358 },
    { lat: 49.101142, lng: 18.3281824 },
    { lat: 49.1011052, lng: 18.3285334 },
    { lat: 49.1011675, lng: 18.3291228 },
    { lat: 49.1012306, lng: 18.3292422 },
    { lat: 49.101279, lng: 18.3295425 },
    { lat: 49.1013741, lng: 18.3296495 },
    { lat: 49.1014968, lng: 18.3296847 },
    { lat: 49.1014951, lng: 18.3299377 },
    { lat: 49.1015391, lng: 18.3300594 },
    { lat: 49.1016618, lng: 18.3303153 },
    { lat: 49.1017397, lng: 18.3306386 },
    { lat: 49.1018128, lng: 18.3308754 },
    { lat: 49.1019765, lng: 18.3312437 },
    { lat: 49.1019663, lng: 18.331483 },
    { lat: 49.1021385, lng: 18.3320616 },
    { lat: 49.1022198, lng: 18.3324041 },
    { lat: 49.1025245, lng: 18.3328303 },
    { lat: 49.1026443, lng: 18.3332374 },
    { lat: 49.1029673, lng: 18.3343342 },
    { lat: 49.1032818, lng: 18.3354003 },
    { lat: 49.1032821, lng: 18.335405 },
    { lat: 49.1033945, lng: 18.3360443 },
    { lat: 49.1034179, lng: 18.3361781 },
    { lat: 49.1034538, lng: 18.3363779 },
    { lat: 49.1035241, lng: 18.3367688 },
    { lat: 49.1035386, lng: 18.3368447 },
    { lat: 49.103646, lng: 18.3369906 },
    { lat: 49.1037545, lng: 18.3371363 },
    { lat: 49.1038484, lng: 18.3373432 },
    { lat: 49.1039231, lng: 18.3376295 },
    { lat: 49.1039602, lng: 18.3378896 },
    { lat: 49.10402, lng: 18.3381516 },
    { lat: 49.1040705, lng: 18.3383715 },
    { lat: 49.1041997, lng: 18.3386219 },
    { lat: 49.1042549, lng: 18.3388837 },
    { lat: 49.1043258, lng: 18.3391824 },
    { lat: 49.1044471, lng: 18.339598 },
    { lat: 49.1045924, lng: 18.3398755 },
    { lat: 49.1046986, lng: 18.3400807 },
    { lat: 49.1047689, lng: 18.3402303 },
    { lat: 49.1048076, lng: 18.3402352 },
    { lat: 49.1049969, lng: 18.3399088 },
    { lat: 49.1050134, lng: 18.3398795 },
    { lat: 49.1052503, lng: 18.3401633 },
    { lat: 49.1055321, lng: 18.3405018 },
    { lat: 49.1059661, lng: 18.3410233 },
    { lat: 49.1063537, lng: 18.3427107 },
    { lat: 49.1063925, lng: 18.3433441 },
    { lat: 49.1063972, lng: 18.3434285 },
    { lat: 49.1064377, lng: 18.3440926 },
    { lat: 49.1064434, lng: 18.3442777 },
    { lat: 49.1064437, lng: 18.3442828 },
    { lat: 49.1064806, lng: 18.3459745 },
    { lat: 49.1064856, lng: 18.3462041 },
    { lat: 49.1065269, lng: 18.3469663 },
    { lat: 49.1065405, lng: 18.3472095 },
    { lat: 49.1065504, lng: 18.347387 },
    { lat: 49.1066798, lng: 18.3477444 },
    { lat: 49.1064022, lng: 18.3478026 },
    { lat: 49.1077866, lng: 18.3543637 },
    { lat: 49.1088218, lng: 18.3607372 },
    { lat: 49.1088234, lng: 18.3607445 },
    { lat: 49.1088268, lng: 18.3607648 },
    { lat: 49.1088269, lng: 18.3607682 },
    { lat: 49.108897, lng: 18.361197 },
    { lat: 49.1088966, lng: 18.3612093 },
    { lat: 49.1088945, lng: 18.3612522 },
    { lat: 49.1087358, lng: 18.3651119 },
    { lat: 49.1072238, lng: 18.3696693 },
    { lat: 49.1061484, lng: 18.3705162 },
    { lat: 49.1057015, lng: 18.3717703 },
    { lat: 49.1055323, lng: 18.3723607 },
    { lat: 49.1053332, lng: 18.374462 },
    { lat: 49.1051672, lng: 18.3753747 },
    { lat: 49.1052027, lng: 18.3769883 },
    { lat: 49.1048927, lng: 18.3791251 },
    { lat: 49.1045795, lng: 18.3792745 },
    { lat: 49.1043055, lng: 18.3794999 },
    { lat: 49.1043139, lng: 18.3797332 },
    { lat: 49.1043162, lng: 18.3797747 },
    { lat: 49.1043574, lng: 18.3805996 },
    { lat: 49.1043484, lng: 18.3806584 },
    { lat: 49.1043416, lng: 18.3807003 },
    { lat: 49.1042717, lng: 18.3811896 },
    { lat: 49.10352, lng: 18.382802 },
    { lat: 49.103938, lng: 18.382897 },
    { lat: 49.104493, lng: 18.383659 },
    { lat: 49.104612, lng: 18.384064 },
    { lat: 49.104874, lng: 18.384538 },
    { lat: 49.10505, lng: 18.384782 },
    { lat: 49.105157, lng: 18.3853 },
    { lat: 49.105419, lng: 18.385658 },
    { lat: 49.105794, lng: 18.38589 },
    { lat: 49.105882, lng: 18.385995 },
    { lat: 49.105895, lng: 18.385972 },
    { lat: 49.106065, lng: 18.386157 },
    { lat: 49.106166, lng: 18.38617 },
    { lat: 49.106343, lng: 18.386095 },
    { lat: 49.106403, lng: 18.386069 },
    { lat: 49.106503, lng: 18.386083 },
    { lat: 49.106774, lng: 18.386227 },
    { lat: 49.107025, lng: 18.386206 },
    { lat: 49.107178, lng: 18.386117 },
    { lat: 49.107505, lng: 18.385956 },
    { lat: 49.107695, lng: 18.386043 },
    { lat: 49.107817, lng: 18.386223 },
    { lat: 49.108075, lng: 18.386762 },
    { lat: 49.108254, lng: 18.387033 },
    { lat: 49.108551, lng: 18.387168 },
    { lat: 49.10865, lng: 18.387466 },
    { lat: 49.108612, lng: 18.387824 },
    { lat: 49.1087, lng: 18.388427 },
    { lat: 49.108917, lng: 18.388793 },
    { lat: 49.109019, lng: 18.389084 },
    { lat: 49.109006, lng: 18.389091 },
    { lat: 49.109066, lng: 18.389403 },
    { lat: 49.108907, lng: 18.389859 },
    { lat: 49.108897, lng: 18.39003 },
    { lat: 49.108884, lng: 18.390037 },
    { lat: 49.109083, lng: 18.390681 },
    { lat: 49.109322, lng: 18.391433 },
    { lat: 49.10934, lng: 18.391877 },
    { lat: 49.109509, lng: 18.392345 },
    { lat: 49.10968, lng: 18.392692 },
    { lat: 49.109923, lng: 18.393403 },
    { lat: 49.11038, lng: 18.39411 },
    { lat: 49.110523, lng: 18.394365 },
    { lat: 49.110719, lng: 18.394981 },
    { lat: 49.11105, lng: 18.395456 },
    { lat: 49.111258, lng: 18.395952 },
    { lat: 49.111491, lng: 18.396714 },
    { lat: 49.111595, lng: 18.397008 },
    { lat: 49.111762, lng: 18.397295 },
    { lat: 49.112073, lng: 18.397827 },
    { lat: 49.112214, lng: 18.398083 },
    { lat: 49.112368, lng: 18.398182 },
    { lat: 49.112712, lng: 18.398372 },
    { lat: 49.113227, lng: 18.398401 },
    { lat: 49.113698, lng: 18.398683 },
    { lat: 49.113698, lng: 18.398664 },
    { lat: 49.113924, lng: 18.398781 },
    { lat: 49.113913, lng: 18.399267 },
    { lat: 49.113987, lng: 18.399294 },
    { lat: 49.11406, lng: 18.399209 },
    { lat: 49.114217, lng: 18.399025 },
    { lat: 49.114812, lng: 18.398163 },
    { lat: 49.115417, lng: 18.397589 },
    { lat: 49.115839, lng: 18.397066 },
    { lat: 49.11633, lng: 18.396894 },
    { lat: 49.116626, lng: 18.3973 },
    { lat: 49.117093, lng: 18.39701 },
    { lat: 49.117366, lng: 18.396941 },
    { lat: 49.118065, lng: 18.396911 },
    { lat: 49.118565, lng: 18.396918 },
    { lat: 49.11896, lng: 18.396962 },
    { lat: 49.11912, lng: 18.39689 },
    { lat: 49.119784, lng: 18.396568 },
    { lat: 49.120443, lng: 18.396271 },
    { lat: 49.120698, lng: 18.396094 },
    { lat: 49.12086, lng: 18.395881 },
    { lat: 49.121048, lng: 18.396006 },
    { lat: 49.121059, lng: 18.396019 },
    { lat: 49.121142, lng: 18.395792 },
    { lat: 49.121119, lng: 18.395769 },
    { lat: 49.121081, lng: 18.395226 },
    { lat: 49.121177, lng: 18.395027 },
    { lat: 49.121277, lng: 18.394504 },
    { lat: 49.121343, lng: 18.394271 },
    { lat: 49.121401, lng: 18.394056 },
    { lat: 49.121447, lng: 18.393889 },
    { lat: 49.121479, lng: 18.393273 },
    { lat: 49.121516, lng: 18.392524 },
    { lat: 49.121472, lng: 18.391773 },
    { lat: 49.121483, lng: 18.39135 },
    { lat: 49.121609, lng: 18.390749 },
    { lat: 49.121661, lng: 18.39027 },
    { lat: 49.121546, lng: 18.389849 },
    { lat: 49.121496, lng: 18.389656 },
    { lat: 49.121465, lng: 18.389535 },
    { lat: 49.121384, lng: 18.389234 },
    { lat: 49.12137, lng: 18.389184 },
    { lat: 49.121227, lng: 18.388891 },
    { lat: 49.121187, lng: 18.388465 },
    { lat: 49.121071, lng: 18.387598 },
    { lat: 49.120986, lng: 18.387053 },
    { lat: 49.12089, lng: 18.386819 },
    { lat: 49.120589, lng: 18.386319 },
    { lat: 49.120372, lng: 18.385679 },
    { lat: 49.120658, lng: 18.385169 },
    { lat: 49.120517, lng: 18.38433 },
    { lat: 49.120285, lng: 18.383747 },
    { lat: 49.120197, lng: 18.38358 },
    { lat: 49.120118, lng: 18.383297 },
    { lat: 49.120008, lng: 18.382987 },
    { lat: 49.1199, lng: 18.382787 },
    { lat: 49.119643, lng: 18.38242 },
    { lat: 49.119633, lng: 18.382403 },
    { lat: 49.119923, lng: 18.381971 },
    { lat: 49.120339, lng: 18.381487 },
    { lat: 49.120332, lng: 18.381472 },
    { lat: 49.1202, lng: 18.381192 },
    { lat: 49.120303, lng: 18.380828 },
    { lat: 49.120329, lng: 18.380815 },
    { lat: 49.120443, lng: 18.380836 },
    { lat: 49.120502, lng: 18.380758 },
    { lat: 49.120502, lng: 18.380668 },
    { lat: 49.1205, lng: 18.380589 },
    { lat: 49.120476, lng: 18.380501 },
    { lat: 49.120513, lng: 18.380367 },
    { lat: 49.12058, lng: 18.380342 },
    { lat: 49.120622, lng: 18.380381 },
    { lat: 49.120662, lng: 18.380344 },
    { lat: 49.120703, lng: 18.380229 },
    { lat: 49.120684, lng: 18.380159 },
    { lat: 49.120626, lng: 18.380095 },
    { lat: 49.120611, lng: 18.380037 },
    { lat: 49.120611, lng: 18.379844 },
    { lat: 49.120634, lng: 18.379726 },
    { lat: 49.120688, lng: 18.379575 },
    { lat: 49.121104, lng: 18.379077 },
    { lat: 49.1206828, lng: 18.3778699 },
    { lat: 49.1204202, lng: 18.376798 },
    { lat: 49.1202986, lng: 18.375865 },
    { lat: 49.120264, lng: 18.375527 },
    { lat: 49.1203126, lng: 18.3748595 },
    { lat: 49.1204093, lng: 18.3742229 },
    { lat: 49.1206946, lng: 18.3735097 },
    { lat: 49.1209191, lng: 18.3730821 },
    { lat: 49.1210344, lng: 18.3728854 },
    { lat: 49.1211782, lng: 18.3726305 },
    { lat: 49.1216628, lng: 18.3719313 },
    { lat: 49.1225663, lng: 18.3705689 },
    { lat: 49.1230825, lng: 18.3699524 },
    { lat: 49.1238298, lng: 18.3691093 },
    { lat: 49.1252149, lng: 18.3678266 },
    { lat: 49.1256446, lng: 18.3674985 },
    { lat: 49.1260812, lng: 18.3672154 },
    { lat: 49.1265086, lng: 18.366969 },
    { lat: 49.1270328, lng: 18.3666994 },
    { lat: 49.1276218, lng: 18.3663964 },
    { lat: 49.1281085, lng: 18.3662016 },
    { lat: 49.1280659, lng: 18.3660841 },
    { lat: 49.1281376, lng: 18.3660161 },
    { lat: 49.1282568, lng: 18.3659071 },
    { lat: 49.1283767, lng: 18.3657897 },
    { lat: 49.1286393, lng: 18.365543 },
    { lat: 49.1287132, lng: 18.3654734 },
    { lat: 49.1288696, lng: 18.3653222 },
    { lat: 49.1289618, lng: 18.3652368 },
    { lat: 49.1292361, lng: 18.3649761 },
    { lat: 49.1292875, lng: 18.3649257 },
    { lat: 49.1294575, lng: 18.3647989 },
    { lat: 49.1301447, lng: 18.3642932 },
    { lat: 49.1301932, lng: 18.3642535 },
    { lat: 49.1303529, lng: 18.3641026 },
    { lat: 49.1307035, lng: 18.3637737 },
    { lat: 49.1309047, lng: 18.3635793 },
    { lat: 49.131449, lng: 18.3630421 },
    { lat: 49.1316674, lng: 18.3628284 },
    { lat: 49.1317469, lng: 18.3627394 },
    { lat: 49.1318863, lng: 18.3625856 },
    { lat: 49.1323559, lng: 18.3620845 },
    { lat: 49.1326647, lng: 18.3617564 },
    { lat: 49.1330245, lng: 18.3613709 },
    { lat: 49.1333361, lng: 18.3610284 },
    { lat: 49.1338014, lng: 18.3604724 },
    { lat: 49.1339659, lng: 18.3602654 },
    { lat: 49.1342261, lng: 18.359953 },
    { lat: 49.1344387, lng: 18.3596973 },
    { lat: 49.1345578, lng: 18.3595486 },
    { lat: 49.1347948, lng: 18.3592659 },
    { lat: 49.1349438, lng: 18.3590847 },
    { lat: 49.135278, lng: 18.3586242 },
    { lat: 49.1357288, lng: 18.3580089 },
    { lat: 49.135897, lng: 18.3577865 },
    { lat: 49.1361681, lng: 18.3573663 },
    { lat: 49.1368876, lng: 18.3563105 },
    { lat: 49.1370334, lng: 18.3560934 },
    { lat: 49.1371333, lng: 18.35594 },
    { lat: 49.1377669, lng: 18.3547587 },
    { lat: 49.1378366, lng: 18.3546326 },
    { lat: 49.1381155, lng: 18.3538671 },
    { lat: 49.1382219, lng: 18.3535098 },
    { lat: 49.138413, lng: 18.3528889 },
    { lat: 49.1384589, lng: 18.3527416 },
    { lat: 49.1386291, lng: 18.3516044 },
    { lat: 49.1386826, lng: 18.3512679 },
    { lat: 49.138705, lng: 18.3509212 },
    { lat: 49.1387493, lng: 18.3502987 },
    { lat: 49.1387674, lng: 18.3500371 },
    { lat: 49.1387688, lng: 18.3500016 },
    { lat: 49.1387584, lng: 18.3495322 },
    { lat: 49.138756, lng: 18.349347 },
    { lat: 49.138754, lng: 18.3492694 },
    { lat: 49.1387453, lng: 18.3488724 },
    { lat: 49.1387079, lng: 18.3485649 },
    { lat: 49.1386961, lng: 18.3484542 },
    { lat: 49.1386557, lng: 18.3480932 },
    { lat: 49.1386156, lng: 18.3478391 },
    { lat: 49.1385845, lng: 18.3476273 },
    { lat: 49.1385252, lng: 18.3472911 },
    { lat: 49.1385003, lng: 18.347168 },
    { lat: 49.1384698, lng: 18.3470457 },
    { lat: 49.1388321, lng: 18.3466637 },
    { lat: 49.1388833, lng: 18.3466103 },
    { lat: 49.1389266, lng: 18.3465587 },
    { lat: 49.138969, lng: 18.3465085 },
    { lat: 49.1389837, lng: 18.3464906 },
    { lat: 49.1390207, lng: 18.3464469 },
    { lat: 49.1390302, lng: 18.3464349 },
    { lat: 49.1390672, lng: 18.3463908 },
    { lat: 49.1391984, lng: 18.3462338 },
    { lat: 49.1392301, lng: 18.3461962 },
    { lat: 49.1392862, lng: 18.3461291 },
    { lat: 49.1393522, lng: 18.3460579 },
    { lat: 49.1399943, lng: 18.345112 },
    { lat: 49.1400296, lng: 18.3450559 },
    { lat: 49.1401179, lng: 18.3449201 },
    { lat: 49.1401564, lng: 18.3448618 },
    { lat: 49.1401824, lng: 18.3448215 },
    { lat: 49.1408824, lng: 18.3442855 },
    { lat: 49.1409066, lng: 18.3442951 },
    { lat: 49.1409886, lng: 18.3443297 },
    { lat: 49.1413212, lng: 18.3444684 },
    { lat: 49.1413302, lng: 18.3444867 },
    { lat: 49.1413452, lng: 18.3445139 },
    { lat: 49.1414272, lng: 18.3446701 },
    { lat: 49.1415967, lng: 18.344775 },
    { lat: 49.1419509, lng: 18.3449972 },
    { lat: 49.1421648, lng: 18.3455763 },
    { lat: 49.1424541, lng: 18.3454222 },
    { lat: 49.1424823, lng: 18.3453824 },
    { lat: 49.1425419, lng: 18.3452983 },
    { lat: 49.1426214, lng: 18.3451847 },
    { lat: 49.1426704, lng: 18.3451144 },
    { lat: 49.1427893, lng: 18.3446773 },
    { lat: 49.1428072, lng: 18.3446139 },
    { lat: 49.1430412, lng: 18.3445619 },
    { lat: 49.143091, lng: 18.3444441 },
    { lat: 49.1432836, lng: 18.3439936 },
    { lat: 49.1433026, lng: 18.3439508 },
    { lat: 49.1437489, lng: 18.3429498 },
    { lat: 49.1440305, lng: 18.3430436 },
    { lat: 49.1440397, lng: 18.3430467 },
    { lat: 49.1443213, lng: 18.3431399 },
    { lat: 49.1443364, lng: 18.343148 },
    { lat: 49.1447939, lng: 18.343381 },
    { lat: 49.1448862, lng: 18.3434177 },
    { lat: 49.1454693, lng: 18.3432336 },
    { lat: 49.1457316, lng: 18.3424748 },
    { lat: 49.1457753, lng: 18.3423472 },
    { lat: 49.1465349, lng: 18.3416464 },
    { lat: 49.1465479, lng: 18.3414762 },
    { lat: 49.14656, lng: 18.341328 },
    { lat: 49.1470668, lng: 18.341272 },
    { lat: 49.1471254, lng: 18.3412107 },
    { lat: 49.1473237, lng: 18.3410038 },
    { lat: 49.1477086, lng: 18.3406021 },
    { lat: 49.1482053, lng: 18.340084 },
    { lat: 49.1487513, lng: 18.3399819 },
    { lat: 49.148787, lng: 18.3399751 },
    { lat: 49.1488015, lng: 18.3399719 },
    { lat: 49.1488025, lng: 18.3399697 },
    { lat: 49.1491121, lng: 18.3393122 },
    { lat: 49.1496558, lng: 18.3390891 },
    { lat: 49.1501512, lng: 18.3390302 },
    { lat: 49.1501673, lng: 18.3400451 },
    { lat: 49.150838, lng: 18.3398136 },
    { lat: 49.1508481, lng: 18.3398123 },
    { lat: 49.15089, lng: 18.3397966 },
    { lat: 49.1509426, lng: 18.3397684 },
    { lat: 49.1510063, lng: 18.3388872 },
    { lat: 49.1510341, lng: 18.3384975 },
    { lat: 49.1510824, lng: 18.3382917 },
    { lat: 49.1511041, lng: 18.3381934 },
    { lat: 49.1512249, lng: 18.3378123 },
    { lat: 49.1510849, lng: 18.336599 },
    { lat: 49.1508254, lng: 18.3359912 },
    { lat: 49.1508033, lng: 18.3359396 },
    { lat: 49.15068, lng: 18.3349847 },
    { lat: 49.1506557, lng: 18.3345062 },
    { lat: 49.1507922, lng: 18.3341647 },
    { lat: 49.1511378, lng: 18.3340701 },
    { lat: 49.1511233, lng: 18.3333058 },
    { lat: 49.1514696, lng: 18.3332439 },
    { lat: 49.1515132, lng: 18.3332388 },
    { lat: 49.1516577, lng: 18.3318853 },
    { lat: 49.151612, lng: 18.3314708 },
    { lat: 49.1517371, lng: 18.3311855 },
    { lat: 49.1515772, lng: 18.3300208 },
    { lat: 49.1515307, lng: 18.3296118 },
    { lat: 49.151973, lng: 18.3296331 },
    { lat: 49.1520967, lng: 18.3298686 },
    { lat: 49.1522284, lng: 18.3301276 },
    { lat: 49.1524832, lng: 18.3308911 },
    { lat: 49.1527785, lng: 18.3314713 },
    { lat: 49.1534041, lng: 18.3323527 },
    { lat: 49.1537482, lng: 18.3329358 },
    { lat: 49.1538881, lng: 18.3332208 },
    { lat: 49.1541092, lng: 18.3336653 },
    { lat: 49.1544093, lng: 18.3346133 },
    { lat: 49.1549578, lng: 18.3360729 },
    { lat: 49.1550477, lng: 18.3363292 },
    { lat: 49.1554296, lng: 18.3374706 },
    { lat: 49.1557556, lng: 18.3382389 },
    { lat: 49.1566038, lng: 18.3388626 },
    { lat: 49.1566851, lng: 18.3389672 },
    { lat: 49.1568202, lng: 18.3392223 },
    { lat: 49.1570915, lng: 18.339234 },
    { lat: 49.1574456, lng: 18.33913 },
    { lat: 49.1576609, lng: 18.3394704 },
    { lat: 49.157936, lng: 18.3399329 },
    { lat: 49.1579659, lng: 18.3399625 },
    { lat: 49.1579882, lng: 18.340018 },
    { lat: 49.1579978, lng: 18.3404536 },
    { lat: 49.1579525, lng: 18.340856 },
    { lat: 49.1579474, lng: 18.3409251 },
    { lat: 49.1578581, lng: 18.3416286 },
    { lat: 49.157829, lng: 18.3418989 },
    { lat: 49.1577277, lng: 18.3423873 },
    { lat: 49.1577278, lng: 18.3424285 },
    { lat: 49.1576911, lng: 18.3425998 },
    { lat: 49.1573452, lng: 18.3433158 },
    { lat: 49.1573278, lng: 18.3444206 },
    { lat: 49.1574157, lng: 18.3445811 },
    { lat: 49.1575218, lng: 18.3446625 },
    { lat: 49.1575452, lng: 18.3446772 },
    { lat: 49.15787, lng: 18.3449389 },
    { lat: 49.1580601, lng: 18.3451306 },
    { lat: 49.158239, lng: 18.3453651 },
    { lat: 49.1584831, lng: 18.3455351 },
    { lat: 49.1599049, lng: 18.3466964 },
    { lat: 49.1622796, lng: 18.3487008 },
    { lat: 49.16218, lng: 18.3490594 },
    { lat: 49.1621577, lng: 18.3491048 },
    { lat: 49.162016, lng: 18.3495786 },
    { lat: 49.1629623, lng: 18.3499387 },
    { lat: 49.1642638, lng: 18.3498522 },
    { lat: 49.1646121, lng: 18.3508014 },
    { lat: 49.1659751, lng: 18.3512291 },
    { lat: 49.1664791, lng: 18.3516482 },
    { lat: 49.1665699, lng: 18.3517188 },
    { lat: 49.1667204, lng: 18.3518311 },
    { lat: 49.1674133, lng: 18.3522012 },
    { lat: 49.1680825, lng: 18.3528922 },
    { lat: 49.1686113, lng: 18.3534946 },
    { lat: 49.1698863, lng: 18.3558121 },
    { lat: 49.1706312, lng: 18.3568117 },
    { lat: 49.1710082, lng: 18.3574843 },
    { lat: 49.171032, lng: 18.357541 },
    { lat: 49.171115, lng: 18.357453 },
    { lat: 49.171269, lng: 18.357324 },
    { lat: 49.171387, lng: 18.357225 },
    { lat: 49.171643, lng: 18.356864 },
    { lat: 49.171653, lng: 18.356836 },
    { lat: 49.171666, lng: 18.356802 },
    { lat: 49.172072, lng: 18.355713 },
    { lat: 49.172329, lng: 18.354798 },
    { lat: 49.172212, lng: 18.353845 },
    { lat: 49.172183, lng: 18.353194 },
    { lat: 49.172201, lng: 18.352933 },
    { lat: 49.172255, lng: 18.352633 },
    { lat: 49.172321, lng: 18.352419 },
    { lat: 49.172374, lng: 18.352189 },
    { lat: 49.172395, lng: 18.351988 },
    { lat: 49.172443, lng: 18.351579 },
    { lat: 49.172567, lng: 18.350831 },
    { lat: 49.172609, lng: 18.350496 },
    { lat: 49.172626, lng: 18.350425 },
    { lat: 49.172669, lng: 18.350247 },
    { lat: 49.172751, lng: 18.350021 },
    { lat: 49.172873, lng: 18.349808 },
    { lat: 49.172972, lng: 18.349623 },
    { lat: 49.173059, lng: 18.349402 },
    { lat: 49.173106, lng: 18.349119 },
    { lat: 49.173121, lng: 18.348959 },
    { lat: 49.173122, lng: 18.34895 },
    { lat: 49.17312, lng: 18.34891 },
    { lat: 49.173115, lng: 18.348737 },
    { lat: 49.173057, lng: 18.348323 },
    { lat: 49.172974, lng: 18.34806 },
    { lat: 49.172915, lng: 18.347835 },
    { lat: 49.172888, lng: 18.347607 },
    { lat: 49.172895, lng: 18.34725 },
    { lat: 49.173004, lng: 18.346516 },
    { lat: 49.17311, lng: 18.346088 },
    { lat: 49.173121, lng: 18.346044 },
    { lat: 49.17331, lng: 18.345759 },
    { lat: 49.173351, lng: 18.345218 },
    { lat: 49.173544, lng: 18.343998 },
    { lat: 49.173508, lng: 18.343421 },
    { lat: 49.173737, lng: 18.343094 },
    { lat: 49.173956, lng: 18.342836 },
    { lat: 49.174597, lng: 18.34235 },
    { lat: 49.174705, lng: 18.342092 },
    { lat: 49.175055, lng: 18.341649 },
    { lat: 49.175877, lng: 18.341034 },
    { lat: 49.175903, lng: 18.341 },
    { lat: 49.176139, lng: 18.340684 },
    { lat: 49.176919, lng: 18.340507 },
    { lat: 49.177441, lng: 18.340267 },
    { lat: 49.178347, lng: 18.339592 },
    { lat: 49.178988, lng: 18.338574 },
    { lat: 49.179029, lng: 18.338505 },
    { lat: 49.179236, lng: 18.337928 },
    { lat: 49.179424, lng: 18.336765 },
    { lat: 49.179427, lng: 18.336742 },
    { lat: 49.179434, lng: 18.336732 },
    { lat: 49.179601, lng: 18.336472 },
    { lat: 49.179586, lng: 18.33645 },
    { lat: 49.179839, lng: 18.336052 },
    { lat: 49.180045, lng: 18.33561 },
    { lat: 49.180063, lng: 18.335381 },
    { lat: 49.180067, lng: 18.33534 },
    { lat: 49.18007, lng: 18.335304 },
    { lat: 49.180121, lng: 18.335181 },
    { lat: 49.180803, lng: 18.33346 },
    { lat: 49.181126, lng: 18.332549 },
    { lat: 49.181143, lng: 18.332528 },
    { lat: 49.181475, lng: 18.332125 },
    { lat: 49.181743, lng: 18.331612 },
    { lat: 49.182233, lng: 18.330853 },
    { lat: 49.18155, lng: 18.329639 },
    { lat: 49.181505, lng: 18.32903 },
    { lat: 49.181361, lng: 18.328172 },
    { lat: 49.181397, lng: 18.32751 },
    { lat: 49.1814, lng: 18.327168 },
    { lat: 49.181557, lng: 18.327022 },
    { lat: 49.181592, lng: 18.326992 },
    { lat: 49.184133, lng: 18.325559 },
    { lat: 49.184244, lng: 18.325539 },
    { lat: 49.185067, lng: 18.325652 },
    { lat: 49.1858, lng: 18.325619 },
    { lat: 49.185841, lng: 18.325613 },
    { lat: 49.186603, lng: 18.325529 },
    { lat: 49.186921, lng: 18.325666 },
    { lat: 49.187328, lng: 18.325826 },
    { lat: 49.188051, lng: 18.32602 },
    { lat: 49.188589, lng: 18.32607 },
    { lat: 49.189192, lng: 18.325766 },
    { lat: 49.190479, lng: 18.325403 },
    { lat: 49.190502, lng: 18.325247 },
    { lat: 49.190579, lng: 18.325039 },
    { lat: 49.190522, lng: 18.324598 },
    { lat: 49.190938, lng: 18.323642 },
    { lat: 49.190968, lng: 18.323637 },
    { lat: 49.192703, lng: 18.32363 },
    { lat: 49.193393, lng: 18.323866 },
    { lat: 49.193967, lng: 18.32447 },
    { lat: 49.194381, lng: 18.324566 },
    { lat: 49.195315, lng: 18.324738 },
    { lat: 49.196026, lng: 18.324559 },
    { lat: 49.196152, lng: 18.324521 },
    { lat: 49.196983, lng: 18.32401 },
    { lat: 49.197116, lng: 18.323966 },
    { lat: 49.197224, lng: 18.323933 },
    { lat: 49.197821, lng: 18.32414 },
    { lat: 49.198166, lng: 18.324204 },
    { lat: 49.198959, lng: 18.323913 },
    { lat: 49.199317, lng: 18.323856 },
    { lat: 49.199982, lng: 18.324008 },
    { lat: 49.200023, lng: 18.32402 },
    { lat: 49.20105, lng: 18.324712 },
    { lat: 49.202281, lng: 18.3253 },
    { lat: 49.202921, lng: 18.325591 },
    { lat: 49.203318, lng: 18.325571 },
    { lat: 49.20339, lng: 18.325574 },
    { lat: 49.203856, lng: 18.326128 },
    { lat: 49.204482, lng: 18.326082 },
    { lat: 49.204646, lng: 18.326396 },
    { lat: 49.205551, lng: 18.326474 },
    { lat: 49.205925, lng: 18.325907 },
    { lat: 49.206205, lng: 18.32596 },
    { lat: 49.206417, lng: 18.32581 },
    { lat: 49.206535, lng: 18.325953 },
    { lat: 49.206719, lng: 18.326161 },
    { lat: 49.206986, lng: 18.325899 },
    { lat: 49.208034, lng: 18.325814 },
    { lat: 49.208765, lng: 18.326068 },
    { lat: 49.209006, lng: 18.326864 },
    { lat: 49.209706, lng: 18.327081 },
    { lat: 49.2098, lng: 18.327108 },
    { lat: 49.210955, lng: 18.328773 },
    { lat: 49.21103, lng: 18.328988 },
    { lat: 49.21112, lng: 18.329159 },
    { lat: 49.21124, lng: 18.329449 },
    { lat: 49.211369, lng: 18.329606 },
    { lat: 49.211541, lng: 18.329701 },
    { lat: 49.211965, lng: 18.329681 },
    { lat: 49.212062, lng: 18.329719 },
    { lat: 49.21225, lng: 18.329978 },
    { lat: 49.212346, lng: 18.330103 },
    { lat: 49.212769, lng: 18.330567 },
    { lat: 49.212906, lng: 18.330636 },
    { lat: 49.213139, lng: 18.330609 },
    { lat: 49.213311, lng: 18.330578 },
    { lat: 49.213591, lng: 18.330827 },
    { lat: 49.213668, lng: 18.330768 },
    { lat: 49.213838, lng: 18.330313 },
    { lat: 49.214294, lng: 18.328632 },
    { lat: 49.214702, lng: 18.327328 },
    { lat: 49.215191, lng: 18.325614 },
    { lat: 49.215479, lng: 18.32463 },
    { lat: 49.215765, lng: 18.323754 },
    { lat: 49.215862, lng: 18.323195 },
    { lat: 49.215866, lng: 18.322905 },
    { lat: 49.21581, lng: 18.322507 },
    { lat: 49.215713, lng: 18.321967 },
    { lat: 49.215678, lng: 18.321704 },
    { lat: 49.215677, lng: 18.321499 },
    { lat: 49.215596, lng: 18.321044 },
    { lat: 49.215536, lng: 18.320765 },
    { lat: 49.215519, lng: 18.320542 },
    { lat: 49.215455, lng: 18.319569 },
    { lat: 49.215496, lng: 18.319216 },
    { lat: 49.215574, lng: 18.319064 },
    { lat: 49.215625, lng: 18.318505 },
    { lat: 49.215662, lng: 18.318212 },
    { lat: 49.215831, lng: 18.317787 },
    { lat: 49.215941, lng: 18.317544 },
    { lat: 49.215985, lng: 18.317358 },
    { lat: 49.21603, lng: 18.31718 },
    { lat: 49.216053, lng: 18.316904 },
    { lat: 49.216075, lng: 18.316741 },
    { lat: 49.216078, lng: 18.316612 },
    { lat: 49.216084, lng: 18.316235 },
    { lat: 49.216082, lng: 18.315798 },
    { lat: 49.216081, lng: 18.315285 },
    { lat: 49.216093, lng: 18.315006 },
    { lat: 49.216102, lng: 18.314957 },
    { lat: 49.216195, lng: 18.31464 },
    { lat: 49.216267, lng: 18.314375 },
    { lat: 49.216166, lng: 18.313822 },
    { lat: 49.216157, lng: 18.313716 },
    { lat: 49.216083, lng: 18.313543 },
    { lat: 49.216045, lng: 18.313433 },
    { lat: 49.216154, lng: 18.313273 },
    { lat: 49.21626, lng: 18.31305 },
    { lat: 49.216258, lng: 18.312941 },
    { lat: 49.21623, lng: 18.311992 },
    { lat: 49.216189, lng: 18.311754 },
    { lat: 49.216208, lng: 18.311482 },
    { lat: 49.216226, lng: 18.311292 },
    { lat: 49.216227, lng: 18.311212 },
    { lat: 49.216426, lng: 18.310384 },
    { lat: 49.216463, lng: 18.310171 },
    { lat: 49.216446, lng: 18.310041 },
    { lat: 49.216409, lng: 18.309652 },
    { lat: 49.216363, lng: 18.309316 },
    { lat: 49.216306, lng: 18.309092 },
    { lat: 49.216215, lng: 18.308793 },
    { lat: 49.216121, lng: 18.308356 },
    { lat: 49.215948, lng: 18.307251 },
    { lat: 49.215935, lng: 18.306903 },
    { lat: 49.216008, lng: 18.30678 },
    { lat: 49.216105, lng: 18.306656 },
    { lat: 49.215976, lng: 18.306681 },
  ],
};

export default UnitsPúchov;
